const lexicon = {
	"&": ["AE3 N D"],
	"'em": ["AH0 M"],
	"'greeing": ["G R IY1 IH0 NG"],
	"'nay": ["N EY1"],
	"'scape": ["S K EY1 P"],
	"'tis": ["T IH1 Z"],
	"'tisn't": ["T IH1 Z AH0 N T"],
	"'twas": ["T W AH1 Z"],
	"'twas'nt": ["T W AH1 Z AH0 NT"],
	"'twasn't": ["T W AH1 S AH0 N T"],
	"'twere": ["T W ER1"],
	"'twill": ["T W IH1 L"],
	"'twould": ["T W UH1 D"],
	"'twouldn't": ["T W UH1 D AH0 N T"],
	"'ware": ["W EH1 R"],
	"1st": ["F ER1 S T"],
	"a": ["AH0"],
	"a'blossom": ["AH0 B L AA1 S AH0 M"],
	"a'blow": ["AH0 B L OW1"],
	"a'chase": ["AH0 CH EY1 S"],
	"a'chirrup": ["AH0 CH ER1 AH0 P"],
	"a'glow": ["AH0 G L OW1"],
	"a'lull": ["AH0 L AH1 L"],
	"a'self": ["AH0 S EH1 L F"],
	"abasement": ["AH0 B EY1 S M AH0 N T"],
	"abash": ["AH0 B AE1 SH"],
	"abasheth": ["AH0 B AE1 SH IH0 TH"],
	"abate": ["AH0 B EY1 T"],
	"abbey": ["AE1 B IY0"],
	"abbeys": ["AE1 B IY0 Z"],
	"abbot": ["AE1 B AH0 T"],
	"abdicate": ["AE1 B D IH0 K EY2 T"],
	"abdication": ["AE2 B D IH0 K EY1 SH AH0 N"],
	"abduct": ["AE0 B D AH1 K T"],
	"abeles": ["AH0 B EH1 L Z"],
	"abettors": ["AH0 B EH1 T ER0 Z"],
	"abhor": ["AE0 B HH AO1 R"],
	"abhorred": ["AH0 B HH AO1 R D"],
	"abide": ["AH0 B AY1 D"],
	"abiding": ["AH0 B AY1 D IH0 NG"],
	"ability": ["AH0 B IH1 L AH0 T IY2"],
	"abject": ["AE1 B JH EH2 K T", "AE0 B JH EH1 K T"],
	"abjure": ["AH0 B JH UH1 R"],
	"abjured": ["AH0 B JH UH1 R D"],
	"able": ["EY1 B AH0 L"],
	"abnegate": ["AE1 B N AH0 G EY2 T"],
	"abnegation": ["AE2 B N EH0 G EY1 SH AH0 N"],
	"abode": ["AH0 B OW1 D"],
	"abolish": ["AH0 B AA1 L IH0 SH"],
	"abolition": ["AE2 B AH0 L IH1 SH AH0 N"],
	"abound": ["AH0 B AW1 N D"],
	"about": ["AH0 B AW1 T"],
	"above": ["AH0 B AH1 V","AH0 B AH3 V"],
	"abreast": ["AH0 B R EH1 S T"],
	"abridge": ["AH0 B R IH1 JH"],
	"abroad": ["AH0 B R AO1 D","AH0 B R AA1 D"],
	"abrupt": ["AH0 B R AH1 P T"],
	"abscond": ["AH0 B S K AA1 N D"],
	"absconded": ["AE0 B S K AA1 N D IH0 D"],
	"absence": ["AE1 B S AH0 N S"],
	"absent": ["AE1 B S AH0 N T"],
	"absentee": ["AE2 B S AH0 N T IY1"],
	"absently": ["AE1 B S AH0 N T L IY3"],
	"absolute": ["AE1 B S AH0 L UW2 T"],
	"absolution": ["AE2 B S AH0 L UW1 SH AH0 N"],
	"absolve": ["AH0 B Z AA1 L V"],
	"absorb": ["AH0 B Z AO1 R B"],
	"abstain": ["AH0 B S T EY1 N"],
	"abstemious": ["AH0 B S T IY1 M IH0 AH2 S", "AH0 B S T IY1 M Y AH0 S"],
	"abstinence": ["AE1 B S T AH0 N AH3 N S"],
	"abstruse": ["AH0 B S T R UW1 S"],
	"abundant": ["AH0 B AH1 N D AH0 N T"],
	"abundantly": ["AH0 B AH1 N D AH0 N T L IY3"],
	"abus'd": ["AH0 B Y UW1 Z D"],
	"abuse": ["AH0 B Y UW1 S", "AH0 B Y UW1 Z"],
	"abused": ["AH0 B Y UW1 Z D"],
	"abysm": ["AH0 B IH1 Z M"],
	"abysmal": ["AH0 B IH1 Z M AH0 L"],
	"abyss": ["AH0 B IH1 S"],
	"acceded": ["AE0 K S IY1 D IH0 D"],
	"accent": ["AE1 K S EH2 N T"],
	"accented": ["AE1 K S EH0 N T IH0 D"],
	"accents": ["AE1 K S EH0 N T S"],
	"accept": ["AE0 K S EH1 P T"],
	"acceptation": ["AE2 K S EH0 P T EY1 SH AH0 N"],
	"access": ["AE1 K S EH2 S"],
	"accessible": ["AE0 K S EH1 S AH0 B AH0 L"],
	"accident": ["AE1 K S AH0 D AH3 N T"],
	"acclaim": ["AH0 K L EY1 M"],
	"acclimated": ["AE1 K L AH0 M EY2 T IH0 D"],
	"accommodate": ["AH0 K AA1 M AH0 D EY2 T"],
	"accompany": ["AH0 K AH1 M P AH0 N IY2"],
	"accompanying": ["AH0 K AH1 M P AH0 N IY0 IH0 NG", "AH0 K AH1 M P AH0 N IH3 NG"],
	"accomplish": ["AH0 K AA1 M P L IH0 SH"],
	"accord": ["AH0 K AO1 R D"],
	"according": ["AH0 K AO1 R D IH0 NG"],
	"accost": ["AH0 K AO1 S T"],
	"account": ["AH0 K AW1 N T"],
	"accredited": ["AH0 K R EH1 D IH0 T IH3 D"],
	"accrue": ["AH0 K R UW1"],
	"accumulate": ["AH0 K Y UW1 M Y AH0 L EY2 T"],
	"accurate": ["AE1 K Y ER0 AH0 T"],
	"accursed": ["AH2 K ER1 S T"],
	"accuse": ["AH0 K Y UW1 Z"],
	"accustomed": ["AH0 K AH1 S T AH0 M D"],
	"ache": ["EY1 K"],
	"achieve": ["AH0 CH IY1 V"],
	"achieving": ["AH0 CH IY1 V IH0 NG"],
	"achilles": ["AH0 K IH1 L IY0 Z"],
	"aching": ["EY1 K IH0 NG"],
	"acknowledged": ["AE0 K N AA1 L IH0 JH D"],
	"acknowledgement": ["AE0 K N AA1 L IH0 JH M AH0 N T"],
	"acolyte": ["AE1 K AH0 L AY2 T"],
	"acorn": ["EY1 K AO0 R N"],
	"acquaint": ["AH0 K W EY1 N T"],
	"acquaintance": ["AH0 K W EY1 N T AH0 N S"],
	"acquaintances": ["AH0 K W EY1 N T AH0 N S IH3 Z"],
	"acquiescence": ["AE2 K W IY0 EH1 S AH0 N S"],
	"acquire": ["AH0 K W AY1 ER0","AH0 K W AY1 R"],
	"acquired": ["AH0 K W AY1 ER0 D", "AH0 K W AY1 R D"],
	"acquisition": ["AE2 K W AH0 Z IH1 SH AH0 N"],
	"acquit": ["AH0 K W IH1 T"],
	"acquitted": ["AH0 K W IH1 T IH0 D"],
	"acre": ["EY1 K ER0"],
	"acrid": ["AE1 K R IH0 D"],
	"across": ["AH0 K R AO1 S"],
	"act": ["AE1 K T"],
	"action": ["AE1 K SH AH0 N"],
	"actor": ["AE1 K T ER0"],
	"actual": ["AE1 K SH UH0 AH3 L", "AE1 K SH W AH0 L"],
	"actualize": ["AE1 K SH UH0 AH0 L AY2 Z", "AE1 K SH W AH0 L AY2 Z"],
	"actually": ["AE1 K SH W AH0 L IY3", "AE1 K SH UH0 W AH0 L IY3"],
	"acute": ["AH0 K Y UW1 T"],
	"adam": ["AE1 D AH0 M"],
	"adamant": ["AE1 D AH0 M AH0 N T"],
	"adamantine": ["AE2 D AH0 M AE1 N T IY0 N"],
	"adamhood": ["AE1 D AH0 M HH UH2 D"],
	"add": ["AE1 D"],
	"addict": ["AH0 D IH1 K T", "AE1 D IH0 K T"],
	"addings": ["AE1 D IH0 N G Z"],
	"addition": ["AH0 D IH1 SH AH0 N"],
	"additional": ["AH0 D IH1 SH AH0 N AH0 L"],
	"address": ["AE1 D R EH2 S", "AH0 D R EH1 S"],
	"addressed": ["AH0 D R EH1 S T"],
	"addressing": ["AH0 D R EH1 S IH0 NG"],
	"adelaide": ["AE1 D AH0 L EY2 D"],
	"adelaide's": ["AE1 D AH0 L EY2 D Z"],
	"adept": ["AH0 D EH1 P T"],
	"adequate": ["AE1 D AH0 K W AH3 T"],
	"adhesion": ["AE0 D HH IY1 ZH AH0 N"],
	"adieu": ["AH0 D Y UW1"],
	"adjoin": ["AH0 JH OY1 N"],
	"adjourn": ["AH0 JH ER1 N"],
	"adjurations": ["AE2 D JH ER0 EY1 SH AH0 N Z"],
	"adjust": ["AH0 JH AH1 S T"],
	"adjusted": ["AH0 JH AH1 S T IH0 D"],
	"admetus": ["AE0 D M EY1 T UH0 S"],
	"admir'd": ["AH0 D M AY1 R D"],
	"admirabler": ["AE1 D M ER0 AH0 B L ER0"],
	"admiralty": ["AE1 D M ER0 AH0 L T IY3"],
	"admiration": ["AE2 D M ER0 EY1 SH AH0 N"],
	"admire": ["AE0 D M AY1 R","AE0 D M AY1 ER0 D"],
	"admired": ["AH0 D M AY1 R D", "AH0 D M AY1 ER0 D"],
	"admiring": ["AE0 D M AY1 R IH0 NG"],
	"admission": ["AE0 D M IH1 SH AH0 N"],
	"admit": ["AH0 D M IH1 T"],
	"admitted": ["AH0 D M IH1 T IH0 D"],
	"admonish": ["AE2 D M AA1 N IH0 SH"],
	"admonition": ["AE2 D M AH0 N IH1 SH AH0 N"],
	"ado": ["AH0 D UW1"],
	"adon'": ["AH0 D AA1 N"],
	"adopt": ["AH0 D AA1 P T"],
	"adore": ["AH0 D AO1 R"],
	"adoring": ["AH0 D AO1 R IH0 NG"],
	"adorn": ["AH0 D AO1 R N"],
	"adown": ["AH0 D AW1 N"],
	"adrift": ["AH0 D R IH1 F T"],
	"adroit": ["AH0 D R OY1 T"],
	"adroiter": ["AH0 D R OY1 T ER0"],
	"adult": ["AH0 D AH1 L T"],
	"adultery": ["AH0 D AH1 L T ER0 IY3"],
	"advance": ["AH0 D V AE1 N S"],
	"advancing": ["AE0 D V AE1 N S IH0 NG"],
	"advantage": ["AE0 D V AE1 N T AH0 JH"],
	"adventure": ["AE0 D V EH1 N CH UH0 R"],
	"adventurous": ["AE0 D V EH1 N CH ER0 AH3 S", "AE0 D V EH1 N CH R AH0 S"],
	"adversity": ["AE0 D V ER1 S IH0 T IY2"],
	"advert": ["AE0 D V ER1 T"],
	"advice": ["AE0 D V AY1 S"],
	"advise": ["AE0 D V AY1 Z"],
	"advocate": ["AE1 D V AH0 K AH2 T"],
	"adz": ["AE1 D Z"],
	"adze": ["AE1 D Z"],
	"aeneas": ["AH0 N IY1 AH0 S"],
	"aeolus": ["EH1 OW0 L UH0 S"],
	"aesthete": ["EH1 S TH IY2 T"],
	"aesthetics": ["EH0 S TH EH1 T IH0 K S"],
	"afar": ["AH0 F AA1 R"],
	"affair": ["AH0 F EH1 R"],
	"affect": ["AH0 F EH1 K T"],
	"affection": ["AH0 F EH1 K SH AH0 N"],
	"affidavit": ["AE2 F IH0 D EY1 V AH0 T"],
	"affirm": ["AH0 F ER1 M"],
	"afflict": ["AH0 F L IH1 K T"],
	"affliction": ["AH0 F L IH1 K SH AH0 N"],
	"affluence": ["AE1 F L UW0 AH3 N S"],
	"afford": ["AH0 F AO1 R D"],
	"affright": ["AH0 F R AY1 T"],
	"affront": ["AH0 F R AH1 N T"],
	"afield": ["AH0 F IY1 L D"],
	"aflame": ["AH0 F L EY1 M"],
	"afloat": ["AH0 F L OW1 T"],
	"afog": ["AH0 F AO1 G", "AH0 F AA1 G"],
	"afraid": ["AH0 F R EY1 D"],
	"afresh": ["AH0 F R EH1 SH"],
	"africa": ["AE1 F R IH0 K AH3"],
	"after": ["AE1 F T ER0"],
	"afternoon": ["AE2 F T ER0 N UW1 N"],
	"afterward": ["AE1 F T ER0 W ER3 D"],
	"again": ["AH0 G EH1 N", "AH0 G EY1 N"],
	"against": ["AH0 G EH1 N S T", "AH0 G EH3 N S T"],
	"agamemnon": ["AE2 G AH0 M EH1 M N AA2 N"],
	"agape": ["AH0 G EY1 P"],
	"age": ["EY1 JH"],
	"aged": ["EY1 JH D", "EY1 JH IH0 D"],
	"agent": ["EY1 JH AH0 N T"],
	"ages": ["EY1 JH AH0 Z"],
	"aggrandized": ["AH0 G R AE1 N D AY2 Z"],
	"aggrandizement": ["AE1 G R AH0 N D AY2 Z M AH0 N T"],
	"aggravate": ["AE1 G R AH0 V EY2 T"],
	"aghast": ["AH0 G AE1 S T"],
	"ago": ["AH0 G OW1"],
	"agone": ["AH0 G AA1 N"],
	"agonized": ["AE1 G AH0 N AY2 Z D"],
	"agonizing": ["AE1 G AH0 N AY2 Z IH0 NG"],
	"agony": ["AE1 G AH0 N IY2"],
	"agree": ["AH0 G R IY1"],
	"aground": ["AH0 G R AW1 N D"],
	"ah": ["AA1"],
	"ahead": ["AH0 HH EH1 D"],
	"aid": ["EY1 D"],
	"aidenn": ["EY2 D EH1 N"],
	"ailed": ["AY1 L D"],
	"ails": ["EY1 L Z"],
	"aim": ["EY1 M"],
	"air": ["EH1 R"],
	"airy": ["EH1 R IY0"],
	"aisle": ["AY1 L", "AY1 AH0 L"],
	"aisles": ["AY1 L Z", "AY1 AH0 L Z"],
	"ajar": ["AH0 JH AA1 R"],
	"akin": ["AH0 K IH1 N"],
	"alabaster": ["AE1 L AH0 B AE2 S T ER0"],
	"alack": ["AH0 L AE1 K"],
	"alacrity": ["AH0 L AE1 K R IH0 T IY3"],
	"aland": ["AH0 L AE1 N D"],
	"alarm": ["AH0 L AA1 R M"],
	"alas": ["AH0 L AE1 S"],
	"albaicin": ["AE0 L B EY1 IH0 S IH2 N"],
	"alban": ["AA1 L B AH0 N"],
	"albatross": ["AE1 L B AH0 T R AA2 S"],
	"albeit": ["AO0 L B IY1 IH0 T", "AO0 L B IY1 T"],
	"alberic's": ["AE1 L B EH0 R IH2 K S"],
	"albion": ["AE1 L B IY0 AA2 N","AE1 L B Y AH0 N"],
	"album": ["AE1 L B AH0 M"],
	"alchemy": ["AE1 L K AH0 M IY3"],
	"alcohol": ["AE1 L K AH0 HH AA2 L"],
	"alden": ["AO1 L D AH0 N"],
	"ale": ["EY1 L"],
	"alexander": ["AE2 L AH0 G Z AE1 N D ER0"],
	"alfonso": ["AE2 L F AA1 N S OW0"],
	"alfonso's": ["AE2 L F AA1 N S OW0 Z"],
	"algebra": ["AE1 L JH AH0 B R AH3"],
	"aliatar": ["AE1 L Y AH0 T AA2 R"],
	"alibi": ["AE1 L AH0 B AY2"],
	"alien": ["EY1 L IY0 AH3 N", "EY1 L Y AH0 N"],
	"alight": ["AH0 L AY1 T"],
	"alike": ["AH0 L AY1 K"],
	"aliment": ["AE1 L AH0 M AH0 N T"],
	"alive": ["AH0 L AY1 V"],
	"all": ["AO1 L"],
	"allaied": ["AH0 L EY1 D"],
	"allay": ["AH0 L EY1"],
	"allemaine": ["AO0 L AH0 M EY1 N"],
	"alleys": ["AE1 L IY0 Z"],
	"alliance": ["AH0 L AY1 AH0 N S"],
	"allied": ["AH0 L AY1 D"],
	"allot": ["AH0 L AA1 T"],
	"allotted": ["AH0 L AA1 T IH0 D"],
	"allow": ["AH0 L AW1"],
	"allude": ["AH0 L UW1 D"],
	"allure": ["AH0 L UH1 R"],
	"almanac": ["AO1 L M AH0 N AE2 K"],
	"almighty": ["AO0 L M AY1 T IY0"],
	"almost": ["AO1 L M OW2 S T"],
	"alms": ["AA1 M Z"],
	"aloft": ["AH0 L AO1 F T"],
	"alone": ["AH0 L OW1 N"],
	"along": ["AH0 L AO1 NG"],
	"alonso": ["AH0 L AA1 N S OW0"],
	"aloof": ["AH0 L UW1 F"],
	"aloud": ["AH0 L AW1 D"],
	"alphabet": ["AE1 L F AH0 B EH2 T"],
	"alpine": ["AE1 L P AY2 N"],
	"alps": ["AE1 L P S"],
	"already": ["AO0 L R EH1 D IY0"],
	"also": ["AO1 L S OW0"],
	"altar": ["AO1 L T ER0"],
	"altarstair": ["AO1 L T ER0 S T EH1 R"],
	"alter": ["AO1 L T ER0 D"],
	"alterations": ["AO2 L T ER0 EY1 SH AH0 N Z"],
	"alternate": ["AO1 L T ER0 N AH3 T", "AO1 L T ER0 N EY2 T"],
	"alternating": ["AO1 L T ER0 N EY2 T IH0 NG"],
	"alternative": ["AO0 L T ER1 N AH0 T IH3 V"],
	"although": ["AO2 L DH OW1"],
	"altitude": ["AE1 L T AH0 T UW2 D"],
	"altogether": ["AO2 L T AH0 G EH1 DH ER0"],
	"alway": ["AO2 L W EY1"],
	"always": ["AO1 L W EY2 Z"],
	"am": ["AE1 M","AE3 M"],
	"amain": ["AH0 M EY1 N"],
	"amassed": ["AH0 M AE1 S T"],
	"amaze": ["AH0 M EY1 Z"],
	"amazing": ["AH0 M EY1 Z IH0 NG"],
	"amber": ["AE1 M B ER0"],
	"ambiguously": ["AE0 M B IH1 G Y UH0 AH0 S L IY2"],
	"ambition": ["AE0 M B IH1 SH AH0 N"],
	"ambrosia": ["AE0 M B R OW1 ZH AH0"],
	"ambrosial": ["AE0 M B R OW1 ZH AH0 L"],
	"ambuscade": ["AE2 M AH2 S K EY1 D"],
	"ambush": ["AE1 M B UH2 SH"],
	"amen": ["AA0 M EH1 N"],
	"amenable": ["AH0 M IY1 N AH0 B AH3 L"],
	"amend": ["AH0 M EH1 N D"],
	"amends": ["AH0 M EH1 N D Z"],
	"amerce": ["AH0 M ER1 S"],
	"america": ["AH0 M EH1 R IH0 K AH3"],
	"amethyst": ["AE1 M IH0 TH IH3 S T"],
	"amherst": ["AE1 M ER0 S T"],
	"amiable": ["EY1 M Y AH0 B AH3 L", "EY1 M IY0 AH0 B AH0 L"],
	"amid": ["AH0 M IH1 D"],
	"amiss": ["AH0 M IH1 S"],
	"amities": ["AE1 M AH0 T IY2 Z"],
	"ammunition": ["AE2 M Y UW0 N IH1 SH AH0 N"],
	"among": ["AH0 M AH1 NG","AH0 M AH3 NG"],
	"amongst": ["AH0 M AH1 NG S T"],
	"amount": ["AH0 M AW1 N T"],
	"amphitrite": ["AE1 M F AH0 T R AY1 T"],
	"ample": ["AE1 M P AH0 L"],
	"ampler": ["AE1 M P L ER0"],
	"amplest": ["AE1 M P AH0 L IH0 S T", "AE1 M P L AH0 S T"],
	"amplitude": ["AE1 M P L AH0 T UW2 D"],
	"amply": ["AE1 M P L IY0"],
	"amulet": ["AE1 M Y AH0 L AH3 T"],
	"amuse": ["AH0 M Y UW1 Z"],
	"amycus": ["AE1 M IH0 K UH2 S"],
	"an": ["AE3 N"],
	"anakim": ["AE1 N AH0 K IH2 M"],
	"analysis": ["AH0 N AE1 L AH0 S AH0 S"],
	"ananias": ["AE2 N AH0 N AY1 AH0 S"],
	"anatomy": ["AH0 N AE1 T AH0 M IY2"],
	"ancestor": ["AE1 N S EH2 S T ER0"],
	"ancestry": ["AE1 N S EH0 S T R IY3"],
	"anchor": ["AE1 NG K ER0"],
	"anchors": ["AE1 NG K ER0 Z"],
	"ancient": ["EY1 N CH AH0 N T"],
	"ancle": ["AE1 NG K AH0 L"],
	"and": ["AE1 N D", "AE3 N D"],
	"andre's": ["AA1 N D R EY2 Z"],
	"andrew's": ["AE1 N D R UW2 Z"],
	"anear": ["AH0 N IY1 R"],
	"anemone": ["AH0 N EH1 M AH0 N IY3"],
	"anew": ["AH0 N UW1"],
	"angel": ["EY1 N JH AH0 L"],
	"angelo": ["AE1 N JH AH0 L OW2"],
	"angle": ["AE1 NG G AH0 L"],
	"anglo": ["AE1 NG G L OW0"],
	"anguish": ["AE1 NG G W IH0 SH"],
	"angular": ["AE1 NG G Y AH0 L ER2"],
	"anight": ["AH0 N AY1 T"],
	"animate": ["AE1 N AH0 M EY2 T"],
	"animus": ["AE1 N IH0 M AH2 S"],
	"annabel": ["AE1 N AH0 B EH3 L"],
	"anneal": ["AH0 N IY1 L"],
	"annihilate": ["AH0 N AY1 L EY3 T", "AH0 N AY1 AH0 L EY2 T"],
	"annihilated": ["AH0 N AY1 AH0 L EY2 T IH0 D","AH0 N AY1 L EY3 T IH0 D"],
	"annihilation": ["AH0 N AY2 AH0 L EY1 SH AH0 N"],
	"anniversary": ["AE2 N AH0 V ER1 S ER0 IY3"],
	"anno": ["AA1 N OW0"],
	"annoy": ["AH0 N OY1"],
	"annual": ["AE1 N Y W AH0 L"],
	"annuities": ["AH0 N UW1 IH0 T IY2 Z"],
	"annul": ["AH0 N AH1 L"],
	"annulled": ["AE1 N AH0 L D"],
	"anodyne": ["AE1 N AH0 D AY2 N"],
	"anodynes": ["AE2 N AH0 D AY1 N Z"],
	"anomaly": ["AH0 N AA1 M AH0 L IY3"],
	"anon": ["AE2 N AO1 N"],
	"anonymous": ["AH0 N AA1 N AH0 M AH3 S"],
	"another": ["AH0 N AH1 DH ER0"],
	"answer": ["AE1 N S ER0"],
	"answering": ["AE1 N S ER0 IH3 NG", "AE1 N S R IH0 NG"],
	"ant": ["AE1 N T"],
	"antedate": ["AE1 N T IY0 D EY2 T"],
	"antelope": ["AE1 N T AH0 L OW2 P"],
	"anterior": ["AE2 N T IY1 R IY0 ER3", "AE2 N T IY1 R Y ER0"],
	"anticipate": ["AE0 N T IH1 S IH0 P EY2 T"],
	"anticipating": ["AE0 N T IH1 S AH0 P EY2 T IH0 NG"],
	"anticipation": ["AE0 N T IH2 S AH0 P EY1 SH AH0 N"],
	"antidote": ["AE1 N T IH0 D OW2 T"],
	"antipodes": ["AE0 N T IH1 P AH0 D IY2 Z"],
	"antiquary": ["AE1 N T IH0 K W EH2 R IY0"],
	"antiquated": ["AE1 N T AH0 K W EY2 T IH0 D"],
	"antique": ["AE0 N T IY1 K", "AE1 N T IY2 K"],
	"antiquity": ["AE0 N T IH1 K W AH0 T IY3"],
	"antlers": ["AE1 N T L ER0 Z"],
	"antonia": ["AE0 N T OW1 N IY0 AH0"],
	"antonine": ["AE1 N T AH0 N AY2 N"],
	"anvil": ["AE1 N V AH0 L"],
	"anxiety": ["AE0 NG Z AY1 AH0 T IY3"],
	"anxious": ["AE1 NG K SH AH0 S"],
	"any": ["EH1 N IY0"],
	"anybody": ["EH1 N IY0 B AH2 D IY0"],
	"anyone": ["EH1 N IY0 W AH2 N"],
	"anything": ["EH1 N IY0 TH IH2 NG"],
	"anywhere": ["EH1 N IY0 W EH2 R"],
	"aonian": ["EY0 OW1 N Y AH0 N"],
	"aornus": ["EY0 AO1 R N AH0 S"],
	"apace": ["AH0 P EY1 S"],
	"apart": ["AH0 P AA1 R T"],
	"apartment": ["AH0 P AA1 R T M EH0 N T"],
	"ape": ["EY1 P"],
	"apiece": ["AH0 P IY1 S"],
	"apocalypse": ["AH0 P AA1 K AH0 L IH2 P S"],
	"apollo's": ["AH0 P AA1 L OW0 Z"],
	"apologize": ["AH0 P AA1 L AH0 JH AY2 Z"],
	"apology": ["AH0 P AA1 L AH0 JH IY3"],
	"apostacy": ["AH0 P AA1 S T AH0 S IY3"],
	"apostate": ["AH0 P AA1 S T EY0 T"],
	"apostle": ["AH0 P AA1 S AH0 L"],
	"apothecary's": ["AH0 P AA1 TH AH0 K EH2 R IY0 Z"],
	"appall": ["AH0 P AO1 L"],
	"apparatus": ["AE2 P ER0 AE1 T AH0 S"],
	"apparel": ["AH0 P EH1 R AH0 L"],
	"apparent": ["AH0 P EH1 R AH0 N T"],
	"apparition": ["AE2 P ER0 IH1 SH AH0 N"],
	"apparreled": ["AH0 P EH1 R AH0 L D"],
	"appeal": ["AH0 P IY1 L"],
	"appear": ["AH0 P IY1 R"],
	"appeas'd": ["AH0 P IY1 Z D"],
	"appease": ["AH0 P IY1 Z"],
	"appendix": ["AH0 P EH1 N D IH0 K S"],
	"appenine": ["AE1 P AH0 N AY2 N"],
	"appetite": ["AE1 P AH0 T AY2 T"],
	"applaud": ["AH0 P L AO1 D"],
	"applause": ["AH0 P L AO1 Z"],
	"apple": ["AE1 P AH0 L"],
	"applied": ["AH0 P L AY1 D"],
	"applies": ["AH0 P L AY1 Z"],
	"appointed": ["AH0 P OY1 N T IH0 D"],
	"appointment": ["AH0 P OY1 N T M AH0 N T"],
	"appreciation": ["AH0 P R IY2 SH IY0 EY1 SH AH0 N"],
	"apprehend": ["AE2 P R IH0 HH EH1 N D"],
	"apprehension": ["AE2 P R IH0 HH EH1 N SH AH0 N"],
	"apprehension's": ["AE2 P R IH0 HH EH1 N SH AH0 N Z"],
	"apprise": ["AH0 P R AY1 Z"],
	"approach": ["AH0 P R OW1 CH T"],
	"approvals": ["AH0 P R UW1 V AH0 L Z"],
	"approve": ["AH0 P R UW1 V"],
	"apricot": ["EY1 P R AH0 K AA2 T"],
	"april": ["EY1 P R AH0 L"],
	"apron": ["EY1 P R AH0 N"],
	"aptitude": ["AE1 P T IH0 T UW2 D"],
	"arabian": ["AH0 R EY1 B IY0 AH0 N"],
	"araby": ["AE1 R AH0 B IY3"],
	"aragon": ["EH1 R AH0 G AA2 N"],
	"ararat": ["EH1 R AH0 R AE2 T"],
	"arbitrate": ["AA1 R B AH0 T R EY2 T"],
	"arc": ["AA1 R K"],
	"arcade": ["AA0 R K EY1 D"],
	"arcady": ["AA1 R K AH0 D IY3"],
	"arch": ["AA1 R CH"],
	"archangel": ["AA2 R K EY1 N JH AH0 L"],
	"archer's": ["AA1 R CH ER0 Z"],
	"arching": ["AA1 R CH IH0 NG"],
	"architect": ["AA1 R K IH0 T EH2 K T"],
	"architrave": ["AA1 R K AH0 T R EY2 V"],
	"archness": ["AA1 R CH N AH0 S"],
	"arcs": ["AA1 R K S"],
	"arctic": ["AA1 R K T IH0 K"],
	"ardency": ["AA1 R D AH0 N S IY3"],
	"ardent": ["AA1 R D AH0 N T"],
	"arduous": ["AA1 R JH UW0 AH3 S"],
	"are": ["AA1 R"],
	"area": ["EH1 R IY0 AH0"],
	"argent": ["AA1 R JH IH0 N T"],
	"argentine": ["AA1 R JH AH0 N T AY2 N"],
	"argo": ["AA1 R G OW0"],
	"argos": ["AA1 R G OW0 S"],
	"argosies": ["AA1 R G AH0 S IY2 Z"],
	"argue": ["AA1 R G Y UW0"],
	"argument": ["AA1 R G Y AH0 M AH2 N T"],
	"arid": ["EH1 R IH0 D"],
	"aright": ["AH0 R AY1 T"],
	"arise": ["ER0 AY1 Z"],
	"arising": ["ER0 AY1 Z IH0 NG"],
	"aristocracy": ["EH2 R AH0 S T AA1 K R AH0 S IY3"],
	"aristotle": ["EH1 R AH0 S T AA2 T AH0 L"],
	"arithmetic": ["AH0 R IH1 TH M AH0 T IH3 K", "EH2 R IH0 TH M EH1 T IH0 K"],
	"ark": ["AA1 R K"],
	"arm": ["AA1 R M"],
	"armament": ["AA1 R M AH0 M AH2 N T"],
	"armies": ["AA1 R M IY0 Z"],
	"armor": ["AA1 R M ER0"],
	"army": ["AA1 R M IY0"],
	"arose": ["ER0 OW1 Z"],
	"around": ["ER0 AW1 N D"],
	"aroused": ["ER0 AW1 Z D"],
	"arous\u00e8d": ["AH0 R AW1 Z IH0 D"],
	"arragon": ["EH1 R AH0 G AA2 N"],
	"arrange": ["ER0 EY1 N JH"],
	"array": ["AH0 R EY1"],
	"arrest": ["ER0 EH1 S T"],
	"arrestless": ["AH0 R EH1 S T L AH0 S"],
	"arrived": ["ER0 AY1 V D"],
	"arrogance": ["EH1 R AH0 G AH0 N S"],
	"arrogant": ["EH1 R AH0 G AH3 N T"],
	"arrow": ["EH1 R OW0"],
	"art": ["AA1 R T"],
	"arterial": ["AA0 R T IY1 R IY0 AH0 L", "AA0 R T IY1 R Y AH0 L"],
	"artery": ["AA1 R T ER0 IY3"],
	"arthur": ["AA1 R TH ER0"],
	"article": ["AA1 R T AH0 K AH3 L"],
	"artifice": ["AA1 R T AH0 F IH3 S"],
	"artificial": ["AA2 R T AH0 F IH1 SH AH0 L"],
	"artillery": ["AA0 R T IH1 L ER0 IY3"],
	"artisan": ["AA1 R T AH0 Z AH3 N"],
	"artist": ["AA1 R T AH0 S T"],
	"as": ["AE1 Z"],
	"ascend": ["AH0 S EH1 N D"],
	"ascent": ["AH0 S EH1 N T"],
	"ascertain": ["AE2 S ER0 T EY1 N"],
	"ascetic": ["AH0 S EH1 T IH0 K"],
	"ashamed": ["AH0 SH EY1 M D"],
	"ashen": ["AE1 SH AH0 N"],
	"ashes": ["AE1 SH IH0 Z"],
	"ashine": ["AH0 SH AY1 N"],
	"ashore": ["AH0 SH AO1 R"],
	"asiatic": ["EY2 Z IY0 AE1 T IH0 K"],
	"aside": ["AH0 S AY1 D"],
	"ask": ["AE1 S K"],
	"askance": ["AH0 S K AE1 N S"],
	"askew": ["AH0 S K Y UW1"],
	"aslant": ["AH0 S L AE1 N T"],
	"asleep": ["AH0 S L IY1 P"],
	"asnort": ["AH0 S N AO1 R T"],
	"asp": ["AE1 S P"],
	"aspect": ["AE1 S P EH2 K T"],
	"asperity": ["AH0 S P EH1 R IH0 T IY3"],
	"asphodel": ["AE1 S F AH0 D EH2 L"],
	"aspiration": ["AE2 S P ER0 EY1 SH AH0 N"],
	"aspire": ["AH0 S P AY1 R", "AH0 S P AY1 ER0"],
	"aspires": ["AH0 S P AY1 ER0 Z", "AH0 S P AY1 R Z"],
	"asquat": ["S K W AA1 T"],
	"asquint": ["AH0 S K W IH1 N T"],
	"ass": ["AE1 S"],
	"assail": ["AH0 S EY1 L"],
	"assault": ["AH0 S AO1 L T"],
	"assay": ["AH0 S EY1"],
	"assemble": ["AH0 S EH1 M B AH0 L"],
	"assembling": ["AH0 S EH1 M B AH0 L IH3 NG", "AH0 S EH1 M B L IH0 NG"],
	"assembly": ["AH0 S EH1 M B L IY0"],
	"assent": ["AH0 S EH1 N T"],
	"assert": ["AH0 S ER1 T"],
	"assign": ["AH0 S AY1 N"],
	"assignable": ["AH0 S AY1 N AH0 B AH2 L"],
	"assimilated": ["AH0 S IH1 M AH0 L EY2 T IH0 D"],
	"assist": ["AH0 S IH1 S T"],
	"assistance": ["AH0 S IH1 S T AH0 N S"],
	"association": ["AH0 S OW2 S IY0 EY1 SH AH0 N"],
	"assort": ["AH0 S AO1 R T"],
	"assuage": ["AH0 S W EY1 JH"],
	"assume": ["AH0 S UW1 M"],
	"assuming": ["AH0 S UW1 M IH0 NG"],
	"assur'd": ["AH0 SH ER1 D"],
	"assure": ["AH0 SH UH1 R"],
	"assured": ["AH0 SH UH1 R D"],
	"assyrians": ["AH0 S IY1 R IY0 AH2 N Z"],
	"aster": ["AE1 S T ER0"],
	"astern": ["AH0 S T ER1 N"],
	"astir": ["AH0 S T ER1"],
	"astonished": ["AH0 S T AA1 N IH0 SH T"],
	"astonishment": ["AH0 S T AA1 N IH0 SH M AH2 N T"],
	"astray": ["AH0 S T R EY1"],
	"astringent": ["AH0 S T R IH1 N JH AH0 N T"],
	"astronomer": ["AH0 S T R AA1 N AH0 M ER3"],
	"astronomy": ["AH0 S T R AA1 N AH0 M IY3"],
	"asunder": ["AH0 S AH1 N D ER0"],
	"at": ["AE3 T"],
	"ate": ["EY1 T"],
	"atheists": ["EY1 TH IY0 AH3 S T S"],
	"athenian": ["AH0 TH IY1 N IY0 AH3 N", "AH0 TH IY1 N Y AH0 N"],
	"athirst": ["AH0 TH AH1 S T"],
	"athwart": ["AH0 TH W AO1 R T"],
	"atlanta": ["AE0 T L AE1 N T AH0"],
	"atmosphere": ["AE1 T M AH0 S F IH2 R"],
	"atom": ["AE1 T AH0 M"],
	"atomies": ["AE1 T AH0 M IY2 Z"],
	"atone": ["AH0 T OW1 N"],
	"atreus": ["EY1 T R Y UH0 S"],
	"atrides": ["AH0 T R AY1 D IY0 Z"],
	"atrocious": ["AH0 T R OW1 SH AH0 S"],
	"attach": ["AH0 T AE1 CH"],
	"attack": ["AH0 T AE1 K"],
	"attain": ["AH0 T EY1 N"],
	"attainments": ["AH0 T EY1 N M AH0 N T S"],
	"attainted": ["AH0 T EY1 N T IH0 D"],
	"attar": ["AE1 T ER0"],
	"attempt": ["AH0 T EH1 M P T"],
	"attend": ["AH0 T EH1 N D"],
	"attendance": ["AH0 T EH1 N D AH0 N S"],
	"attendants": ["AH0 T EH1 N D AH0 N T S"],
	"attendeth": ["AH0 T EH1 N D IH0 TH"],
	"attention": ["AH0 T EH1 N SH AH0 N"],
	"attest": ["AH0 T EH1 S T"],
	"attic": ["AE1 T IH0 K"],
	"attire": ["AH0 T AY1 ER0","AH0 T AY1 R"],
	"attired": ["AH0 T AY1 ER0 D","AH0 T AY1 R D"],
	"attitude": ["AE1 T AH0 T UW2 D"],
	"attorney": ["AH0 T ER1 N IY0"],
	"attract": ["AH0 T R AE1 K T"],
	"attracts": ["AH0 T R AE1 K T S"],
	"attributes": ["AE1 T R AH0 B Y UW2 T S"],
	"auburn": ["AA1 B ER0 N"],
	"auction": ["AA1 K SH AH0 N"],
	"audacious": ["AO0 D EY1 SH AH0 S"],
	"audible": ["AA1 D AH0 B AH3 L"],
	"audibler": ["AA1 D AH0 B AH0 L ER0", "AA1 D AH0 B L ER3"],
	"audience": ["AA1 D IY0 AH3 N S", "AA1 D Y AH0 N S"],
	"audiences": ["AA1 D IY0 AH0 N S AH0 Z", "AO1 D IY0 AH0 N S AH0 Z"],
	"auditorium": ["AO2 D AH0 T AO1 R IY0 AH0 M"],
	"aught": ["AO1 T"],
	"augment": ["AO0 G M EH1 N T"],
	"augur": ["AO1 G ER0"],
	"augury": ["AO1 G Y ER0 IY3"],
	"august": ["AO1 G AH0 S T"],
	"augustan": ["AA0 G AH1 S T AH0 N"],
	"auran": ["AO1 R AH0 N"],
	"aurora": ["ER0 AO1 R AH0"],
	"auroran": ["AO0 R AO1 R AH0 N"],
	"austere": ["AO0 S T IY1 R"],
	"austerity": ["AO2 S T EH1 R IH0 T IY0"],
	"authentic": ["AO0 TH EH1 N T IH0 K"],
	"authority": ["AH0 TH AO1 R AH0 T IY3"],
	"authorized": ["AO1 TH ER0 AY2 Z D"],
	"auto": ["AO1 T OW0"],
	"autocratic": ["AO2 T AH0 K R AE1 T IH0 K"],
	"automedon": ["AW0 T AA1 M IH0 D AH2 N"],
	"autumn": ["AO1 T AH0 M"],
	"autumns": ["AO1 T AH0 M Z"],
	"auxiliaries": ["AO0 G Z IH1 L IH0 ER0 IY2 Z"],
	"auxiliary": ["AA0 G Z IH1 L Y ER0 IY3"],
	"avail": ["AH0 V EY1 L"],
	"avalanch": ["AE1 V AH0 L AE2 N CH"],
	"avarice": ["AE1 V ER0 AH3 S"],
	"avaunt": ["AH0 V AO1 N T"],
	"avenger": ["AH0 V EH1 N JH ER0"],
	"avenging": ["AH0 V EH1 N JH IH0 NG"],
	"aver": ["AH0 V ER1"],
	"aversion": ["AH0 V ER1 ZH AH0 N"],
	"avert": ["AH0 V ER1 T"],
	"avocation": ["AE2 V AH0 K EY1 SH AH0 N"],
	"avoid": ["AH0 V OY1 D"],
	"avouch": ["AH0 V AW1 CH"],
	"avow": ["AH0 V AW1"],
	"await": ["AH0 W EY1 T"],
	"awake": ["AH0 W EY1 K"],
	"awaken": ["AH0 W EY1 K AH0 N"],
	"awaking": ["AH0 W EY1 K IH0 NG"],
	"awarded": ["AH0 W AO1 R D IH0 D"],
	"aware": ["AH0 W EH1 R"],
	"away": ["AH0 W EY1"],
	"awe": ["AO1"],
	"aweary": ["AH0 W IY1 R IY0"],
	"awed": ["AO1 D"],
	"awful": ["AO1 F AH0 L"],
	"awfully": ["AO1 F L IY0"],
	"awhile": ["AH0 W AY1 L"],
	"awkward": ["AA1 K W ER0 D"],
	"awoke": ["AH0 W OW1 K"],
	"awork": ["AH0 W AO1 R K"],
	"awry": ["ER0 AY1"],
	"axe": ["AE1 K S"],
	"axiom": ["AE1 K S IY0 AH3 M"],
	"axis": ["AE1 K S AH0 S"],
	"axle": ["AE1 K S AH0 L"],
	"ay": ["EY1"],
	"aye": ["AY1"],
	"ayre": ["EH1 R"],
	"azure": ["AE1 ZH ER0"],
	"azzo": ["AE1 Z OW0"],
	"b'ye": ["B AY1"],
	"babble": ["B AE1 B AH0 L"],
	"babbler": ["B AE1 B L ER0"],
	"babblers": ["B AE1 B L ER0 Z", "B AE1 B AH0 L ER3 Z"],
	"babes": ["B EY1 B Z"],
	"baby": ["B EY1 B IY0"],
	"babylon": ["B AE1 B AH0 L AA2 N"],
	"baccalaureate": ["B AE2 K AH0 L AO1 R IY0 AH0 T"],
	"bacchus": ["B AE1 K IH0 S"],
	"back": ["B AE1 K"],
	"backward": ["B AE1 K W ER0 D"],
	"bad": ["B AE1 D"],
	"bade": ["B EY1 D"],
	"baffle": ["B AE1 F AH0 L"],
	"baffling": ["B AE1 F AH0 L IH0 NG", "B AE1 F L IH0 NG"],
	"baggage": ["B AE1 G AH0 JH"],
	"bagpipes": ["B AE1 G P AY2 P S"],
	"bags": ["B AE1 G Z"],
	"bahamas": ["B AH0 HH AA1 M AH0 Z"],
	"bail": ["B EY1 L"],
	"bailiff": ["B EY1 L IH0 F"],
	"bait": ["B EY1 T"],
	"baize": ["B EY1 Z"],
	"bake": ["B EY1 K"],
	"balance": ["B AE1 L AH0 N S"],
	"balancing": ["B AE1 L AH0 N S IH0 NG"],
	"balcony": ["B AE1 L K AH0 N IY3"],
	"bald": ["B AO1 L D"],
	"bale": ["B EY1 L"],
	"bales": ["B EY1 L Z"],
	"ball": ["B AO1 L"],
	"ballad": ["B AE1 L AH0 D"],
	"ballet": ["B AE0 L EY1", "B AE1 L EY0"],
	"balloon": ["B AH0 L UW1 N"],
	"ballot": ["B AE1 L AH0 T"],
	"balm": ["B AA1 M"],
	"balmy": ["B AA1 M IY0"],
	"baltic": ["B AO1 L T IH0 K"],
	"baltimore": ["B AO1 L T AH0 M AO2 R"],
	"balustrade": ["B AE2 L AH0 S T R EY1 D"],
	"ban": ["B AE1 N"],
	"band": ["B AE1 N D"],
	"bandaged": ["B AE1 N D IH0 JH D"],
	"bandit": ["B AE1 N D IH0 T"],
	"bane": ["B EY1 N"],
	"banish": ["B AE1 N IH0 SH"],
	"banishment": ["B AE1 N IH0 SH M AH2 N T"],
	"banjo": ["B AE1 N JH OW2"],
	"bank": ["B AE1 NG K"],
	"banker": ["B AE1 NG K ER0"],
	"bankruptcy": ["B AE1 NG K R AH0 P T S IY3"],
	"banner": ["B AE1 N ER0"],
	"banquet": ["B AE1 NG K W IH0 T"],
	"baptism": ["B AE1 P T IH0 Z AH0 M"],
	"baptized": ["B AE0 P T AY1 Z D", "B AE1 P T AY2 Z D"],
	"baptizes": ["B AE1 P T AY0 Z IH0 Z", "B AE0 P T AY1 Z IH0 Z"],
	"bar": ["B AA1 R"],
	"barabbas": ["B AH0 R AE1 B AH0 S"],
	"barb": ["B AA1 R B"],
	"barbarism": ["B AA1 R B ER0 IH2 Z M"],
	"barbaross": ["B AA2 R AH0 B R AO1 S"],
	"barbarous": ["B AA1 R B ER0 AH3 S"],
	"barberry": ["B AA1 R B R IY0"],
	"bard": ["B AA1 R D"],
	"bare": ["B EH1 R"],
	"bared": ["B EH1 R D"],
	"barefeet": ["B EH1 R F IY2 T"],
	"barefoot": ["B EH1 R F UH2 T"],
	"barely": ["B EH1 R L IY0"],
	"barest": ["B EH1 R AH0 S T"],
	"bargemen": ["B AA1 R JH M EH2 N"],
	"bark": ["B AA1 R K"],
	"barley": ["B AA1 R L IY0"],
	"barn": ["B AA1 R N"],
	"baroness": ["B EH1 R AH0 N IH2 S"],
	"baronial": ["B AH0 R OW1 N Y AH0 L"],
	"barred": ["B AA1 R D"],
	"barrels": ["B AE1 R AH0 L Z"],
	"barrier": ["B AE1 R IY0 ER2"],
	"barter": ["B AA1 R T ER0"],
	"base": ["B EY1 S"],
	"baseball": ["B EY1 S B AO1 L"],
	"baseless": ["B EY1 S L AH0 S"],
	"basement": ["B EY1 S M AH0 N T"],
	"bashful": ["B AE1 SH F AH0 L"],
	"basin": ["B EY1 S IH0 N"],
	"basket": ["B AE1 S K AH0 T"],
	"bass": ["B AE1 S", "B EY1 S"],
	"bassoon": ["B AH0 S UW1 N"],
	"bat": ["B AE1 T"],
	"bathed": ["B EY1 DH D"],
	"bathes": ["B EY1 DH Z"],
	"battered": ["B AE1 T ER0 D"],
	"batteries": ["B AE1 T ER0 IY3 Z", "B AE1 T R IY0 Z"],
	"battle": ["B AE1 T AH0 L"],
	"battlement": ["B AE1 T AH0 L M AH2 N T"],
	"battlements": ["B AE1 T AH0 L M AH3 N T S"],
	"battling": ["B AE1 T AH0 L IH3 NG", "B AE1 T L IH0 NG"],
	"bauble": ["B AO1 B AH0 L"],
	"bay": ["B EY1"],
	"bayonet": ["B EY2 AH0 N EH1 T"],
	"bazaar": ["B AH0 Z AA1 R"],
	"be": ["B IY1"],
	"beach": ["B IY1 CH"],
	"bead": ["B IY1 D"],
	"beady": ["B IY1 D IY0"],
	"beak": ["B IY1 K"],
	"beam": ["B IY1 M"],
	"bear": ["B EH1 R"],
	"beard": ["B IY1 R D"],
	"bearer": ["B EH1 R ER0"],
	"bearings": ["B EH1 R IH0 NG Z"],
	"beast": ["B IY1 S T"],
	"beat": ["B IY1 T"],
	"beatified": ["B IY0 AE1 T AH0 F AY2 D"],
	"beatrice": ["B IY1 AH0 T R IH0 S", "B EY2 AH0 T R IY1 CH EY0"],
	"beatrix": ["B IY1 AH0 T R IH2 K S"],
	"beauteous": ["B Y UW1 T Y AH0 S", "B Y UW1 T IY0 AH3 S"],
	"beautified": ["B Y UW1 T IH0 F AY3 D"],
	"beautiful": ["B Y UW1 T AH0 F AH3 L"],
	"beautifully": ["B Y UW1 T AH0 F AH3 L IY0", "B Y UW1 T AH0 F L IY0"],
	"beauty": ["B Y UW1 T IY0"],
	"became": ["B IH0 K EY1 M"],
	"because": ["B IH0 K AO1 Z"],
	"beck": ["B EH1 K"],
	"beckoned": ["B EH1 K AH0 N D"],
	"beclouded": ["B IH0 K L AW1 D IH0 D"],
	"become": ["B IH0 K AH1 M"],
	"becoming": ["B IH0 K AH1 M IH0 NG"],
	"bed": ["B EH1 D"],
	"bedded": ["B EH1 D IH0 D"],
	"bedight": ["B IH0 D AY1 T"],
	"bee": ["B IY1"],
	"beech": ["B IY1 CH"],
	"been": ["B IY1 N"],
	"beer": ["B IY1 R"],
	"bees": ["B IY1 Z"],
	"beetle": ["B IY1 T AH0 L"],
	"befall": ["B IH0 F AO1 L"],
	"befel": ["B IH0 F EH1 L"],
	"befell": ["B IH0 F EH1 L"],
	"befit": ["B IH0 F IH1 T"],
	"beflecked": ["B IH0 F L EH1 K T"],
	"before": ["B IH0 F AO1 R"],
	"befriend": ["B IH0 F R EH1 N D"],
	"beg": ["B EH1 G"],
	"began": ["B IH0 G AE1 N"],
	"beggar": ["B EH1 G ER0"],
	"beggared": ["B EH1 G ER0 D"],
	"beggary": ["B EH0 G AA1 R IY2"],
	"begged": ["B EH1 G D"],
	"begging": ["B EH1 G IH0 NG"],
	"begin": ["B IH0 G IH1 N"],
	"beginning": ["B IH0 G IH1 N IH0 NG"],
	"beguil'd": ["B IH0 G AY1 L D"],
	"beguile": ["B IH0 G AY1 L"],
	"beguiled": ["B IH0 G AY1 L D"],
	"beguiling": ["B IH0 G AY1 L IH0 NG"],
	"begun": ["B IH0 G AH1 N"],
	"behavior": ["B IH0 HH EY1 V Y ER0"],
	"beheld": ["B IY0 HH EH1 L D"],
	"behest": ["B IH0 HH EH1 S T"],
	"behind": ["B IH0 HH AY1 N D"],
	"behold": ["B IH0 HH OW1 L D"],
	"behooveth": ["B IH0 HH UW1 V IH0 TH"],
	"being": ["B IY1 IH0 NG", "B IY1 N G"],
	"belated": ["B IH0 L EY1 T IH0 D"],
	"belied": ["B IH0 L AY1 D"],
	"belief": ["B IH0 L IY1 F"],
	"believ'd": ["B IH0 L IY1 V D"],
	"believe": ["B IH0 L IY1 V"],
	"believed": ["B IH0 L IY1 V D"],
	"belisarius": ["B EH2 L AH0 S EH1 R IH0 AH0 S"],
	"bell": ["B EH1 L"],
	"belle": ["B EH1 L"],
	"bellies": ["B EH1 L IY0 Z"],
	"bellows": ["B EH1 L OW0 Z"],
	"belong": ["B IH0 L AO1 NG"],
	"belov'd": ["B IH0 L AH1 V D"],
	"beloved": ["B IH0 L AH1 V D", "B IH0 L AH1 V IH0 D"],
	"belov\u00e8d": ["B IH0 L AH1 V IH0 D"],
	"belov\u00ebd": ["B IH0 L AH1 V IH0 D"],
	"below": ["B IH0 L OW1"],
	"belt": ["B EH1 L T"],
	"belvedere": ["B EH2 L V AH0 D IY1 R"],
	"bemoaning": ["B IH0 M OW1 N IH0 NG"],
	"bench": ["B EH1 N CH"],
	"bend": ["B EH1 N D"],
	"beneath": ["B IH0 N IY1 TH"],
	"benediction": ["B EH2 N AH0 D IH1 K SH AH0 N"],
	"benefactor": ["B EH1 N AH0 F AE2 K T ER0"],
	"benefit": ["B EH1 N AH0 F IH2 T"],
	"benevolence": ["B AH0 N EH1 V AH0 L AH0 N S"],
	"benighted": ["B IH0 N AY1 T IH0 D"],
	"benign": ["B IH0 N AY1 N"],
	"bent": ["B EH1 N T"],
	"benumbing": ["B IH0 N AH1 M IH0 NG"],
	"bequeath": ["B IH0 K W IY1 TH"],
	"bequest": ["B IH0 K W EH1 S T"],
	"bereave": ["B ER0 IY1 V"],
	"bereavement": ["B ER0 IY1 V M AH0 N T"],
	"bereft": ["B ER0 EH1 F T"],
	"bergamot": ["B AH1 G AH0 M AA0 T"],
	"bernardine": ["B ER0 N AA1 R D IY0 N"],
	"berries": ["B EH1 R IY0 Z"],
	"berry": ["B EH1 R IY0"],
	"berth": ["B ER1 TH"],
	"beryl": ["B EH1 R AH0 L"],
	"beseech": ["B IY0 S IY1 CH"],
	"beseem": ["B AH0 S IY1 M"],
	"beset": ["B IH0 S EH1 T"],
	"beside": ["B IH0 S AY1 D"],
	"besmeared": ["B IH0 S M IY1 R D"],
	"besought": ["B IH0 S AA1 T"],
	"bespeak": ["B IH0 S P IY1 K"],
	"bespoke": ["B UH0 S P OW1 K"],
	"bespread": ["B IH0 S P R EH1 D"],
	"besprent": ["B IH0 S P R EH1 N T"],
	"besprinkled": ["B IH0 S P R IH1 NG K AH0 L D"],
	"best": ["B EH1 S T"],
	"bestir": ["B IH0 S T ER1"],
	"bestow": ["B IH0 S T OW1"],
	"bestow'd": ["B IH0 S T OW1 D"],
	"bestow'st": ["B IH0 S T OW1 S T"],
	"bestrode": ["B IH0 S T R OW1 D"],
	"bestrown": ["B IH0 S T R OW1 N"],
	"bet": ["B EH1 T"],
	"bethink": ["B IH0 TH IH1 NG K"],
	"bethleem": ["B EH1 TH L AH0 HH EH2 M"],
	"betide": ["B IH0 T AY1 D"],
	"betides": ["B IH0 T AY1 D Z"],
	"betimes": ["B IH0 T AY1 M Z"],
	"betray": ["B IH0 T R EY1"],
	"betrothal": ["B IH0 T R OW1 TH AH0 L", "B IH0 T R AO1 TH AH0 L"],
	"better": ["B EH1 T ER0"],
	"between": ["B IH0 T W IY1 N"],
	"betwixt": ["B IY0 T W IH1 K S T"],
	"bevel": ["B EH1 V AH0 L"],
	"bewail": ["B IH0 W EY1 L"],
	"beware": ["B IH0 W EH1 R"],
	"bewildered": ["B IH0 W IH1 L D ER0 D"],
	"bewildering": ["B IH0 W IH1 L D ER0 IH0 NG", "B IH0 W IH1 L D R IH0 NG"],
	"bewray": ["B IH0 R EY1"],
	"beyond": ["B IY0 AA1 N D"],
	"bias": ["B AY1 AH0 S"],
	"bid": ["B IH1 D"],
	"bidding": ["B IH1 D IH0 NG"],
	"bide": ["B AY1 D"],
	"bier": ["B IY1 R"],
	"big": ["B IH1 G"],
	"biggest": ["B IH1 G IH0 S T"],
	"bile": ["B AY1 L"],
	"bilious": ["B IH1 L IY0 AH2 S"],
	"bill": ["B IH1 L"],
	"billow": ["B IH1 L OW0"],
	"bin": ["B IH1 N"],
	"bind": ["B AY1 N D"],
	"biography": ["B AY2 AA1 G R AH0 F IY3"],
	"birch": ["B ER1 CH"],
	"bird": ["B ER1 D"],
	"birdling": ["B ER1 D L IH0 NG"],
	"birth": ["B ER1 TH"],
	"birthday": ["B ER1 TH D EY2"],
	"biscayan": ["B IH0 S K AY1 AH0 N"],
	"bisect": ["B AY0 S EH1 K T"],
	"bit": ["B IH1 T"],
	"bitch": ["B IH1 CH"],
	"bite": ["B AY1 T"],
	"bitter": ["B IH1 T ER0"],
	"bitterer": ["B IH1 T ER0 ER3"],
	"bitterest": ["B IH1 T ER0 AH3 S T"],
	"bitterly": ["B IH1 T ER0 L IY3"],
	"bitterness": ["B IH1 T ER0 N AH2 S"],
	"black": ["B L AE1 K"],
	"blackbirds": ["B L AE1 K B ER0 D Z"],
	"blackly": ["B L AE1 K L IY0"],
	"blackness": ["B L AE1 K N AH0 S"],
	"blacksmith": ["B L AE1 K S M IH2 TH"],
	"blade": ["B L EY1 D"],
	"blame": ["B L EY1 M"],
	"blamed": ["B L EY1 M D"],
	"blanch": ["B L AE1 N CH"],
	"blanchingly": ["B L AE1 N CH IH0 NG L IY2"],
	"bland": ["B L AE1 N D"],
	"blank": ["B L AE1 NG K"],
	"blanket": ["B L AE1 NG K IH0 T"],
	"blanketless": ["B L AE1 NG K AH0 T L AH3 S"],
	"blare": ["B L EH1 R"],
	"blaspheme": ["B L AE0 S F IY1 M"],
	"blast": ["B L AE1 S T"],
	"blaze": ["B L EY1 Z"],
	"blazed": ["B L EY1 Z D"],
	"blazing": ["B L EY1 Z IH0 NG"],
	"bleak": ["B L IY1 K"],
	"bleat": ["B L IY1 T"],
	"bled": ["B L EH1 D"],
	"bleed": ["B L IY1 D"],
	"blemish": ["B L EH1 M IH0 SH"],
	"blend": ["B L EH1 N D"],
	"blent": ["B L EH1 N T"],
	"bless": ["B L EH1 S"],
	"blessed": ["B L EH1 S T", "B L EH1 S IH0 D"],
	"blessedness": ["B L EH1 S AH0 D N AH2 S"],
	"blest": ["B L EH1 S T"],
	"blew": ["B L UW1"],
	"blight": ["B L AY1 T"],
	"blind": ["B L AY1 N D"],
	"blindly": ["B L AY1 N D L IY0"],
	"blink": ["B L IH1 NG K"],
	"bliss": ["B L IH1 S"],
	"blister": ["B L IH1 S T ER0"],
	"blithe": ["B L AY1 DH"],
	"bloat": ["B L OW1 T"],
	"block": ["B L AA1 K"],
	"blonde": ["B L AA1 N D"],
	"blood": ["B L AH1 D"],
	"bloody": ["B L AH1 D IY0"],
	"bloom": ["B L UW1 M"],
	"blossom": ["B L AA1 S AH0 M"],
	"blossoming": ["B L AA1 S AH0 M IH2 NG", "B L AA1 S M IH0 NG"],
	"blot": ["B L AA1 T"],
	"blow": ["B L OW1"],
	"blown": ["B L OW1 N"],
	"blue": ["B L UW1"],
	"bluebell": ["B L UW1 B EH2 L"],
	"blunder": ["B L AH1 N D ER0"],
	"blunts": ["B L AH1 N T S"],
	"blur": ["B L ER1"],
	"blush": ["B L AH1 SH"],
	"boabdil": ["B OW1 AH0 B D IH2 L"],
	"boanerges": ["B OW1 AH0 N ER2 JH IH0 S"],
	"boar": ["B AO1 R"],
	"board": ["B AO1 R D"],
	"boast": ["B OW1 S T"],
	"boat": ["B OW1 T"],
	"bobadilo": ["B AA2 B AH0 D IH1 L OW0"],
	"bobolink": ["B AA1 B AH0 L IH2 NG K"],
	"bobolinks": ["B AA1 B AH0 L IH2 NG K S"],
	"boddice": ["B AA1 D IH0 S"],
	"bodies": ["B AA1 D IY0 Z"],
	"bodiless": ["B OW1 D IY0 L AH3 S"],
	"bodkin": ["B AA1 D K IH0 N"],
	"body": ["B AA1 D IY0"],
	"bog": ["B AO1 G", "B AA1 G"],
	"boggy": ["B AO1 G IY0"],
	"bohea": ["B OW0 HH IY1"],
	"boil": ["B OY1 L"],
	"bold": ["B OW1 L D"],
	"bole": ["B OW1 L"],
	"bolivian": ["B OW0 L IH1 V IY0 AH0 N", "B OW0 L IH1 V Y AH0 N"],
	"bolt": ["B OW1 L T"],
	"bolts": ["B OW1 L T S"],
	"bomb": ["B AA1 M", "B AO1 M"],
	"bond": ["B AA1 N D"],
	"bondage": ["B AA1 N D AH0 JH"],
	"bone": ["B OW1 N"],
	"boniface": ["B AA1 N AH0 F IH2 S"],
	"bonnet": ["B AA1 N IH0 T"],
	"bonnie": ["B AA1 N IY0"],
	"book": ["B UH1 K"],
	"boom": ["B UW1 M"],
	"boon": ["B UW1 N"],
	"boot": ["B UW1 T"],
	"booty": ["B UW1 T IY0"],
	"boquet": ["B OW0 K EY1"],
	"border": ["B AO1 R D ER0"],
	"bore": ["B AO1 R"],
	"borer": ["B AO1 R ER0"],
	"born": ["B AO1 R N"],
	"borne": ["B AO1 R N"],
	"borodale": ["B AO1 R AH0 D EY2 L"],
	"borough": ["B ER1 OW0"],
	"borrow": ["B AA1 R OW0"],
	"borrow'd": ["B AA1 R OW2 D"],
	"bosom": ["B UH1 Z AH0 M"],
	"bosomswell": ["B UH1 Z AH0 M S W EH2 L"],
	"both": ["B OW1 TH"],
	"bottle": ["B AA1 T AH0 L"],
	"bottom": ["B AA1 T AH0 M"],
	"bough": ["B AW1"],
	"bought": ["B AA1 T"],
	"boulders": ["B OW1 L D ER0 Z"],
	"bound": ["B AW1 N D"],
	"boundaries": ["B AW1 N D ER0 IY3 Z", "B AW1 N D R IY0 Z"],
	"boundary": ["B AW1 N D ER0 IY3"],
	"boundlessness": ["B AW1 N D L AH0 S N AH3 S"],
	"bounteous": ["B AW1 N T Y AH0 S", "B AW1 N T IY0 AH3 S"],
	"bountiful": ["B AW1 N T AH0 F UH3 L"],
	"bounty": ["B AW1 N T IY0"],
	"bourbon": ["B ER1 B AH0 N"],
	"bourn": ["B AO1 R N"],
	"bourne": ["B AO1 R N"],
	"bout": ["B AW1 T"],
	"bow": ["B AW1", "B OW1"],
	"bow'd": ["B AW1 D"],
	"bow'r": ["B AW1 R", "B AW1 ER0"],
	"bow'rs": ["B AW1 R Z", "B AW1 ER0 Z"],
	"bowed": ["B AW1 D", "B OW1 D"],
	"bowels": ["B AW1 AH0 L Z"],
	"bower": ["B AW1 ER0", "B AW1 R"],
	"bowers": ["B AW1 ER0 Z", "B AW1 R Z"],
	"bowing": ["B AW1 IH0 NG", "B OW1 IH0 NG"],
	"bowl": ["B OW1 L"],
	"bows": ["B AW1 Z", "B OW1 Z"],
	"box": ["B AA1 K S"],
	"boy": ["B OY1"],
	"boyish": ["B OY1 IH0 SH"],
	"braced": ["B R EY1 S T"],
	"bracken": ["B R AE1 K AH0 N"],
	"braes": ["B R EY1 Z"],
	"braid": ["B R EY1 D"],
	"braided": ["B R EY1 D IH0 D"],
	"brain": ["B R EY1 N"],
	"brake": ["B R EY1 K"],
	"bramble": ["B R AE1 M B AH0 L"],
	"branch": ["B R AE1 N CH"],
	"branches": ["B R AE1 N CH IH0 Z"],
	"brand": ["B R AE1 N D"],
	"bras": ["B R AE1 S"],
	"brass": ["B R AE1 S"],
	"brave": ["B R EY1 V"],
	"braver": ["B R EY1 V ER0"],
	"bravest": ["B R EY1 V AH0 S T"],
	"bravo": ["B R AA2 V OW1", "B R AA1 V OW2"],
	"bravoes": ["B R AA1 V OW0 S"],
	"brawl": ["B R AO1 L"],
	"braying": ["B R EY1 IH0 NG"],
	"brazen": ["B R EY1 Z AH0 N"],
	"brazil": ["B R AH0 Z IH1 L"],
	"brazilian": ["B R AH0 Z IH1 L Y AH0 N"],
	"breach": ["B R IY1 CH"],
	"bread": ["B R EH1 D"],
	"breadth": ["B R EH1 D TH"],
	"break": ["B R EY1 K"],
	"breakers": ["B R EY1 K ER0 Z"],
	"breakfast": ["B R EH1 K F AH0 S T"],
	"breakfasted": ["B R EH1 K F AH0 S T"],
	"breaking": ["B R EY1 K IH0 NG"],
	"breast": ["B R EH1 S T"],
	"breath": ["B R EH1 TH"],
	"breathe": ["B R IY1 DH"],
	"breathed": ["B R IY1 DH D"],
	"breathes": ["B R IY1 DH Z"],
	"breathing": ["B R IY1 DH IH0 NG"],
	"breathless": ["B R EH1 TH L AH0 S"],
	"bred": ["B R EH1 D"],
	"breech": ["B R IY1 CH"],
	"breed": ["B R IY1 D"],
	"breese": ["B R IY1 Z"],
	"breeze": ["B R IY1 Z"],
	"breezy": ["B R IY1 Z IY0"],
	"brenta": ["B R EH1 N T AH0"],
	"brethren": ["B R EH1 DH R AH0 N"],
	"brevity": ["B R EH1 V AH0 T IY3"],
	"brew": ["B R UW1"],
	"briar": ["B R AY1 ER0","B R AY1 R"],
	"bribe": ["B R AY1 B"],
	"brick": ["B R IH1 K"],
	"bridal": ["B R AY1 D AH0 L"],
	"bride": ["B R AY1 D"],
	"bridegroom": ["B R AY1 D G R UW2 M"],
	"bridge": ["B R IH1 JH"],
	"bridget": ["B R IH1 JH AH0 T"],
	"brief": ["B R IY1 F"],
	"briefly": ["B R IY1 F L IY0"],
	"brig": ["B R IH1 G"],
	"brigadier": ["B R IH2 G AH0 D IY1 R"],
	"brigand": ["B R IH1 G AH0 N D"],
	"bright": ["B R AY1 T"],
	"brilliancy": ["B R IH1 L Y AH0 N S IY3"],
	"brim": ["B R IH1 M"],
	"brimm'd": ["B R IH1 M D"],
	"brimming": ["B R IH1 M IH0 NG"],
	"brine": ["B R AY1 N"],
	"bring": ["B R IH1 NG"],
	"brink": ["B R IH1 NG K"],
	"briony": ["B R AY1 AH0 N IY3"],
	"brise\u00efs": ["B R IH0 S EY1 IH0 S"],
	"briskly": ["B R IH1 S K L IY0"],
	"britain": ["B R IH1 T AH0 N"],
	"british": ["B R IH1 T IH0 SH"],
	"brittle": ["B R IH1 T AH0 L"],
	"broach": ["B R OW1 CH"],
	"broad": ["B R AO1 D"],
	"brocade": ["B R OW0 K EY1 D"],
	"broideries": ["B R OY1 D ER0 IY2 Z"],
	"broidery": ["B R OY1 D ER0 IY3"],
	"broil": ["B R OY1 L"],
	"broke": ["B R OW1 K"],
	"broken": ["B R OW1 K AH0 N"],
	"brokenhanded": ["B R OW2 K AH0 N HH AE1 N D IH0 D"],
	"broker": ["B R OW1 K ER0"],
	"bronte": ["B R AA1 N T IY0"],
	"bronze": ["B R AA1 N Z"],
	"brooch": ["B R OW1 CH"],
	"brood": ["B R UW1 D"],
	"brook": ["B R UH1 K"],
	"brooklet": ["B R UH1 K L AH0 T"],
	"broom": ["B R UW1 M"],
	"broth": ["B R AO1 TH"],
	"brother": ["B R AH1 DH ER0"],
	"brotherhood": ["B R AH1 DH ER0 HH UH2 D"],
	"brotherkings": ["B R AH1 DH ER0 K IH2 NG Z"],
	"brought": ["B R AO1 T"],
	"brow": ["B R AW1"],
	"brown": ["B R AW1 N"],
	"bruise": ["B R UW1 Z"],
	"bruised": ["B R UW1 Z D"],
	"bruit": ["B R UW1 T"],
	"brute": ["B R UW1 T"],
	"bryant's": ["B R AY1 AH0 N T S"],
	"bubble": ["B AH1 B AH0 L"],
	"bubbled": ["B AH1 B AH0 L D"],
	"bucket": ["B AH1 K IH0 T"],
	"buckle": ["B AH1 K AH0 L"],
	"buckled": ["B AH1 K AH0 L D"],
	"bud": ["B AH1 D"],
	"budding": ["B AH1 D IH0 NG"],
	"buenos": ["B W EY1 N OW0 S"],
	"bugle": ["B Y UW1 G AH0 L"],
	"build": ["B IH1 L D"],
	"builder": ["B IH1 L D ER0"],
	"built": ["B IH1 L T"],
	"bulb": ["B AH1 L B"],
	"bulk": ["B AH1 L K"],
	"bull": ["B UH1 L"],
	"bullet": ["B UH1 L AH0 T"],
	"bulletin": ["B UH1 L AH0 T IH3 N"],
	"bullion": ["B UW1 L Y AH0 N"],
	"bumble": ["B AH1 M B AH0 L"],
	"bumblebee": ["B AH1 M B AH0 L B IY2"],
	"bun": ["B AH1 N"],
	"bundle": ["B AH1 N D AH0 L"],
	"bung": ["B AH1 NG"],
	"bunion": ["B AH1 N Y AH0 N"],
	"buoyantly": ["B OY1 AH0 N T L IY3"],
	"bur": ["B ER1"],
	"burden": ["B ER1 D AH0 N"],
	"burdock": ["B ER1 D AA0 K"],
	"burglar": ["B ER1 G L ER0"],
	"burgundy": ["B ER1 G AH0 N D IY2"],
	"burial": ["B EH1 R IY0 AH0 L", "B EH1 R Y AH0 L"],
	"buried": ["B EH1 R IY0 D"],
	"burlesque": ["B ER0 L EH1 S K"],
	"burn": ["B ER1 N"],
	"burnish": ["B ER1 N IH0 SH"],
	"burnt": ["B ER1 N T"],
	"burr": ["B ER1"],
	"burrowing": ["B ER1 OW0 IH3 NG"],
	"burst": ["B ER1 S T"],
	"burthen": ["B ER1 D AH0 N"],
	"bus": ["B AH1 S"],
	"bush": ["B UH1 SH"],
	"busier": ["B IH1 Z IY0 ER0", "B IH1 Z Y ER0"],
	"business": ["B IH1 Z N AH0 S"],
	"bust": ["B AH1 S T"],
	"bustle": ["B AH1 S AH0 L"],
	"bustling": ["B AH1 S L IH0 NG"],
	"busts": ["B AH1 S T S"],
	"busy": ["B IH1 Z IY0"],
	"but": ["B AH3 T"],
	"butchered": ["B UH1 CH ER0 D"],
	"butt": ["B AH1 T"],
	"butter": ["B AH1 T ER0"],
	"buttercup": ["B AH1 T ER0 K AH2 P"],
	"butterflies": ["B AH1 T ER0 F L AY2 Z"],
	"butterfly": ["B AH1 T ER0 F L AY2"],
	"button": ["B AH1 T AH0 N"],
	"buttonhole": ["B AH1 T AH0 N HH OW2 L"],
	"buttresschinks": ["B AH1 T R AH0 S CH IH2 NG K S"],
	"buy": ["B AY1"],
	"buzz": ["B AH1 Z"],
	"by": ["B AY3","B AY1"],
	"by't": ["B AY1 T"],
	"bye": ["B AY1"],
	"c": ["S IY1"],
	"c'd": ["K UH3 D"],
	"cabinets": ["K AE1 B AH0 N IH3 T S", "K AE1 B N IH0 T S"],
	"cadence": ["K EY1 D AH0 N S"],
	"cadences": ["K EY1 D AH0 N S IH2 Z"],
	"cadiz": ["K AH0 D IY1 Z"],
	"caesar": ["S IY1 Z ER0"],
	"cage": ["K EY1 JH"],
	"cajole": ["K AH0 JH OW1 L"],
	"cake": ["K EY1 K"],
	"calabar": ["K AE1 L AH0 B AA2 R"],
	"calamity": ["K AH0 L AE1 M AH0 T IY3"],
	"calculate": ["K AE1 L K Y AH0 L EY2 T"],
	"calculation": ["K AE2 L K Y AH0 L EY1 SH AH0 N"],
	"calderon": ["K AE2 L D ER0 OW1 N"],
	"caldrons": ["K AO1 L D R AH0 N Z"],
	"calibration": ["K AE2 L AH0 B R EY1 SH AH0 N"],
	"call": ["K AO1 L"],
	"call'd": ["K AO1 L D"],
	"callow": ["K AE1 L OW0"],
	"calm": ["K AA1 M"],
	"calumnies": ["K AE2 L AH2 M N IY1 S"],
	"calvaries": ["K AE1 L V ER0 IY0 Z"],
	"calvary": ["K AE1 L V ER0 IY3"],
	"calves": ["K AE1 V Z"],
	"calyx": ["K AE1 L IH0 K S"],
	"cambric": ["K AE1 M B R IH0 K"],
	"came": ["K EY1 M"],
	"camel's": ["K AE1 M AH0 L Z"],
	"camelot": ["K AE1 M AH0 L AA2 T"],
	"camest": ["K EY1 M S T"],
	"camp": ["K AE1 M P"],
	"campaign": ["K AE0 M P EY1 N"],
	"campfire": ["K AE1 M P F AY2 R"],
	"can": ["K AE1 N"],
	"can't": ["K AE1 N T"],
	"canaan": ["K EY1 N AH0 N"],
	"cancell'd": ["K AE1 N S AH0 L D"],
	"cancelled": ["K AE1 N S AH0 L D"],
	"candid": ["K AE1 N D IH0 D"],
	"candidate": ["K AE1 N D AH0 D EY2 T"],
	"candle": ["K AE1 N D AH0 L"],
	"candlelight": ["K AE1 N D AH0 L L AY2 T"],
	"cane": ["K EY1 N"],
	"cannon": ["K AE1 N AH0 N"],
	"cannot": ["K AE1 N AA0 T", "K AH0 N AA1 T"],
	"canoe": ["K AH0 N UW1"],
	"canopies": ["K AE1 N AH0 P IY2 Z"],
	"canopy": ["K AE1 N AH0 P IY3"],
	"canst": ["K AE1 N S T"],
	"cant": ["K AE1 N T"],
	"canto": ["K AE1 N T OW0"],
	"canvas": ["K AE1 N V AH0 S"],
	"cap": ["K AE1 P"],
	"capabilities": ["K EY2 P AH0 B IH1 L AH0 T IY3 Z"],
	"capacious": ["K AH0 P EY1 SH AH0 S"],
	"capacity": ["K AH0 P AE1 S AH0 T IY2"],
	"cape": ["K EY1 P"],
	"caper": ["K EY1 P ER0"],
	"capital": ["K AE1 P AH0 T AH3 L"],
	"capitol": ["K AE1 P AH0 T AH3 L"],
	"capped": ["K AE1 P T"],
	"caprice": ["K AH0 P R IY1 S"],
	"capsule": ["K AE1 P S AH0 L"],
	"captain": ["K AE1 P T AH0 N"],
	"caption": ["K AE1 P SH AH0 N"],
	"captivates": ["K AE1 P T IH0 V EY2 T S"],
	"captive": ["K AE1 P T IH0 V"],
	"captivity": ["K AE0 P T IH1 V AH0 T IY3"],
	"capuchin": ["K AH0 P UW1 CH IH0 N"],
	"car": ["K AA1 R"],
	"caravan": ["K EH1 R AH0 V AE2 N"],
	"carbon": ["K AA1 R B AH0 N"],
	"carbonates": ["K AA1 R B AH0 N EY2 T S"],
	"carcanet": ["K AA1 R K AH0 N EH2 T"],
	"care": ["K EH1 R"],
	"career": ["K ER0 IY1 R"],
	"careful": ["K EH1 R F AH0 L"],
	"careless": ["K EH1 R L AH0 S"],
	"caress": ["K ER0 EH1 S"],
	"cargo": ["K AA1 R G OW2"],
	"cargoes": ["K AA1 R G OW2 Z"],
	"carmine": ["K AA1 R M AH0 N"],
	"carnations": ["K AA0 R N EY1 SH AH0 N Z"],
	"carnival": ["K AA1 R N IH0 V AH3 L"],
	"carol": ["K AE1 R AH0 L"],
	"carolled": ["K AE1 R AH0 L D"],
	"carolling": ["K AE1 R AH0 L IH0 NG"],
	"carotid": ["K ER0 AA1 T IH0 D"],
	"carpenter": ["K AA1 R P AH0 N T ER0"],
	"carrara": ["K AH0 R AA1 R AH0"],
	"carriage": ["K AE1 R IH0 JH"],
	"carried": ["K EH1 R IY0 D"],
	"carries": ["K EH1 R IY0 Z"],
	"carry": ["K AE1 R IY0"],
	"cart": ["K AA1 R T"],
	"carthage": ["K AA1 R TH IH0 JH"],
	"carve": ["K AA1 R V"],
	"carven": ["K AA1 R V AH0 N"],
	"caryatides": ["K AE2 R IH0 AE1 T IH0 D IY2 Z"],
	"case": ["K EY1 S"],
	"casement": ["K EY1 S M AH0 N T"],
	"cash": ["K AE1 SH"],
	"cashmere": ["K AE0 ZH M IY1 R"],
	"cask": ["K AE1 S K"],
	"casket": ["K AE1 S K AH0 T"],
	"caspian": ["K AE1 S P Y AH0 N"],
	"casque": ["K AA1 S K"],
	"cast": ["K AE1 S T"],
	"caste": ["K AE1 S T"],
	"castile": ["K AE2 S T IY1 L"],
	"castle": ["K AE1 S AH0 L"],
	"casually": ["K AE1 ZH W AH0 L IY3", "K AE1 ZH UH0 AH0 L IY3"],
	"cat": ["K AE1 T"],
	"catacomb": ["K AE1 T AH0 K OW2 M"],
	"catalogue": ["K AE1 T AH0 L AO2 G"],
	"cataract": ["K AE1 T ER0 AE2 K T"],
	"catch": ["K AE1 CH"],
	"caterpillar": ["K AE1 T ER0 P IH2 L ER0"],
	"cathedral": ["K AH0 TH IY1 D R AH0 L"],
	"cato": ["K EY1 T OW0"],
	"cattle": ["K AE1 T AH0 L"],
	"caucus": ["K AO1 K AH0 S"],
	"caught": ["K AO1 T"],
	"cause": ["K AO1 Z"],
	"caused": ["K AO1 Z D"],
	"causeless": ["K AA1 Z L AH0 S"],
	"causes": ["K AO1 Z IH0 Z"],
	"cauterize": ["K AO1 T ER0 AY2 Z"],
	"caution": ["K AO1 SH AH0 N"],
	"cautious": ["K AO1 SH AH0 S"],
	"cavalcade": ["K AE1 V AH0 L K EY2 D"],
	"cavalier": ["K AE2 V AH0 L IY1 R"],
	"cavalry": ["K AE1 V AH0 L R IY2"],
	"cave": ["K EY1 V"],
	"cavern": ["K AE1 V ER0 N"],
	"cazzani": ["K AA0 T S AA1 N IY0"],
	"cease": ["S IY1 S"],
	"ceasing": ["S IY1 S IH0 NG"],
	"cedar": ["S IY1 D ER0"],
	"ceiling": ["S IY1 L IH0 NG"],
	"celebrated": ["S EH1 L AH0 B R EY2 T IH0 D"],
	"celerity": ["S AH0 L EH1 R AH0 T IY3"],
	"celestial": ["S AH0 L EH1 S CH AH0 L", "S AH0 L EH1 S T IY0 AH3 L"],
	"cell": ["S EH1 L"],
	"cellar": ["S EH1 L ER0"],
	"cello": ["CH EH1 L OW0"],
	"celt": ["S EH1 L T"],
	"cemetery": ["S EH1 M AH0 T EH2 R IY0"],
	"cenotaph": ["S EH1 N AH0 T AE2 F"],
	"censer": ["S EH1 N S ER0"],
	"cent": ["S EH1 N T"],
	"centigrade": ["S EH1 N T AH0 G R EY2 D"],
	"centipede": ["S EH1 N T IH0 P IY2 D"],
	"central": ["S EH1 N T R AH0 L"],
	"centre": ["S EH1 N T ER0"],
	"centuries": ["S EH1 N CH ER0 IY2 Z"],
	"century": ["S EH1 N CH ER0 IY2"],
	"cerement": ["S IY1 R AH0 M AH3 N T", "S IY1 R M AH0 N T"],
	"certain": ["S ER1 T AH0 N"],
	"certainties": ["S ER1 T AH0 N T IY3 Z"],
	"certainty": ["S ER1 T AH0 N T IY3"],
	"certes": ["S ER1 T AH0 S"],
	"certificate": ["S ER0 T IH1 F IH0 K AH2 T"],
	"certified": ["S ER1 T AH0 F AY2 D"],
	"certify": ["S ER1 T AH0 F AY2"],
	"chace": ["CH EY1 S"],
	"chaff": ["CH AE1 F"],
	"chafing": ["CH EY1 F IH0 NG"],
	"chagrin": ["SH AH0 G R IH1 N"],
	"chain": ["CH EY1 N"],
	"chair": ["CH EH1 R"],
	"chalice": ["CH AE1 L IH0 S"],
	"chalk": ["CH AO1 K"],
	"challenge": ["CH AE1 L AH0 N JH"],
	"challenging": ["CH AE1 L AH0 N JH IH3 NG"],
	"chamber": ["CH EY1 M B ER0"],
	"chameleon": ["K AH0 M IY1 L IY0 AH0 N"],
	"champagne": ["SH AE0 M P EY1 N", "SH AE1 M P EY2 N"],
	"champaign": ["SH AE0 M P EY1 N", "SH AE1 M P EY2 N"],
	"champion": ["CH AE1 M P IY0 AH0 N"],
	"chance": ["CH AE1 N S"],
	"chancel": ["CH AE1 N S AH0 L"],
	"chang'd": ["CH EY1 N JH D"],
	"change": ["CH EY1 N JH"],
	"changed": ["CH EY1 N JH D"],
	"changefully": ["CH EY1 N JH F UH0 L IY3"],
	"chant": ["CH AE1 N T"],
	"chanticleer": ["SH AE1 N T AH0 K L IY2 R"],
	"chaoses": ["K EY1 AA2 S IH0 Z"],
	"chapeaux": ["CH AE2 P OW1 Z"],
	"chapel": ["CH AE1 P AH0 L"],
	"chapped": ["CH AE1 P T"],
	"chapter": ["CH AE1 P T ER0"],
	"character": ["K EH1 R IH0 K T ER3"],
	"charactery": ["K EH1 R AH0 K T R IY2"],
	"charactry": ["K EH1 R AH0 K T R IY2"],
	"charcoal": ["CH AA1 R K OW2 L"],
	"charg'd": ["CH AA1 R JH D"],
	"charge": ["CH AA1 R JH"],
	"charged": ["CH AA1 R JH D"],
	"chariot": ["CH EH1 R IY0 AH0 T"],
	"charities": ["CH EH1 R AH0 T IY3 Z"],
	"charity": ["CH EH1 R IH0 T IY3"],
	"charlatan": ["SH AA1 R L AH0 T AH2 N"],
	"charlemagne": ["SH AA1 R L AH0 M EY2 N"],
	"charm": ["CH AA1 R M"],
	"charmingly": ["CH AA1 R M IH0 NG L IY3"],
	"chart": ["CH AA1 R T"],
	"charter'd": ["CH AA1 R T ER0D"],
	"chary": ["CH AA1 R IY0"],
	"chase": ["CH EY1 S"],
	"chased": ["CH EY1 S T"],
	"chasm": ["K AE1 Z AH0 M"],
	"chaste": ["CH EY1 S T"],
	"chasten": ["CH EY1 S AH0 N"],
	"chastise": ["CH AE0 S T AY1 Z"],
	"chastity": ["CH AE1 S T AH0 T IY3"],
	"chat": ["CH AE1 T"],
	"chatter": ["CH AE1 T ER0"],
	"chaunt": ["CH AO1 N T"],
	"cheat": ["CH IY1 T"],
	"check": ["CH EH1 K"],
	"cheek": ["CH IY1 K"],
	"cheer": ["CH IY1 R"],
	"cheerful": ["CH IY1 R F AH0 L"],
	"cheerily": ["CH IY1 R IH0 L IY3"],
	"chemical": ["K EH1 M IH0 K AH3 L"],
	"chemist": ["K EH1 M IH0 S T"],
	"cheops": ["K IY1 AA0 P S"],
	"cheque": ["CH EH1 K"],
	"cherish": ["CH EH1 R IH0 SH"],
	"cherries": ["CH EH1 R IY0 Z"],
	"cherubic": ["CH ER0 UW1 B IH0 K"],
	"cherubim": ["CH EH1 R AH0 B IH2 M"],
	"chest": ["CH EH1 S T"],
	"chick": ["CH IH1 K"],
	"chidden": ["CH IH1 D AH0 N"],
	"chide": ["CH AY1 D"],
	"chief": ["CH IY1 F"],
	"child": ["CH AY1 L D"],
	"childhood": ["CH AY1 L D HH UH2 D"],
	"childish": ["CH AY1 L D IH0 SH"],
	"childlike": ["CH AY1 L D L AY2 K"],
	"children": ["CH IH1 L D R AH0 N"],
	"chill": ["CH IH1 L"],
	"chilly": ["CH IH1 L IY0"],
	"chimborazo": ["CH IH2 M B AO0 R AA1 Z OW0"],
	"chime": ["CH AY1 M"],
	"chimney": ["CH IH1 M N IY0"],
	"chin": ["CH IH1 N"],
	"chink": ["CH IH1 NG K"],
	"chip": ["CH IH1 P"],
	"chirp": ["CH ER1 P"],
	"chirr'd": ["CH ER1 D"],
	"chisel": ["CH IH1 Z AH0 L"],
	"chiselled": ["CH IH1 Z AH0 L D"],
	"chivalry": ["SH IH1 V AH0 L R IY0"],
	"choice": ["CH OY1 S"],
	"choir": ["K W AY1 ER0"],
	"choke": ["CH OW1 K"],
	"choose": ["CH UW1 Z"],
	"choosing": ["CH UW1 Z IH0 NG"],
	"chorals": ["K AO1 R AH0 L Z"],
	"chords": ["K AO1 R D Z"],
	"chorister": ["K AO1 R IH0 S T ER2"],
	"chose": ["CH OW1 Z"],
	"chosen": ["CH OW1 Z AH0 N"],
	"chrism": ["K R IH1 S M"],
	"christ": ["K R AY1 S T"],
	"christabel": ["K R IH1 S T AH0 B EH2 L"],
	"christian": ["K R IH1 S CH IH0 N"],
	"christians": ["K R IH1 S CH IH0 N Z"],
	"christmas": ["K R IH1 S M AH0 S"],
	"christus": ["K R IY1 S T UH0 S"],
	"chronicle": ["K R AA1 N IH0 K AH2 L"],
	"chroniclers": ["K R AA1 N IH0 K L ER2 Z"],
	"chronology": ["K R AH0 N AA1 L AH0 JH IY3"],
	"chrysalis": ["K R IH1 S AH0 L IH3 S"],
	"chryseis": ["K R AY0 S EY1 IH0 S"],
	"chryses": ["K R AY1 S IY0 Z"],
	"chrysolite": ["K R IH1 S AH0 L AY2 T"],
	"chrysophras": ["K R IH1 S AH0 P R EY2 Z"],
	"chubby": ["CH AH1 B IY0"],
	"chuckle": ["CH AH1 K AH0 L"],
	"church": ["CH ER1 CH"],
	"churchyard": ["CH ER1 CH Y AA2 R D"],
	"churls": ["CH ER1 L Z"],
	"cigar": ["S IH0 G AA1 R"],
	"cimitar": ["S IH1 M IH0 T ER0"],
	"cinder": ["S IH1 N D ER0"],
	"cinderella's": ["S IH2 N D ER0 EH1 L AH0 Z"],
	"cinnamon": ["S IH1 N AH0 M AH3 N"],
	"cipher": ["S AY1 F ER0"],
	"circassian": ["S ER0 K AE1 S Y AH0 N"],
	"circassy": ["S ER0 K AE1 S IY0"],
	"circe": ["S AH1 S IY0"],
	"circle": ["S ER1 K AH0 L"],
	"circuit": ["S ER1 K IH0 T"],
	"circumference": ["S ER0 K AH1 M F ER0 AH3 N T S", "S ER0 K AH1 M F R AH0 N T S"],
	"circumscribe": ["S ER2 K AH0 M S K R AY1 B"],
	"circumscription": ["S ER2 K AH0 M S K R IH1 P SH AH0 N"],
	"circumspect": ["S ER1 K AH0 M S P EH2 K T"],
	"circumstance": ["S ER1 K AH0 M S T AE2 N S"],
	"circumvent": ["S ER2 K AH0 M V EH1 N T"],
	"citadel": ["S IH1 T AH0 D EH2 L"],
	"cite": ["S AY1 T"],
	"cited": ["S AY1 T IH0 D"],
	"cities": ["S IH1 T IY0 Z"],
	"citizen": ["S IH1 T AH0 Z IH3 N"],
	"city": ["S IH1 T IY0"],
	"civility": ["S AH0 V IH1 L AH0 T IY3"],
	"clack": ["K L AE1 K"],
	"clad": ["K L AE1 D"],
	"claim": ["K L EY1 M"],
	"clamber": ["K L AE1 M B ER0"],
	"clamor": ["K L AE1 M ER0"],
	"clan": ["K L AE1 N"],
	"clangs": ["K L AE1 NG Z"],
	"clap": ["K L AE1 P"],
	"clasp": ["K L AE1 S P"],
	"claspt": ["K L AE1 S P T"],
	"class": ["K L AE1 S"],
	"classify": ["K L AE1 S IH0 F AY2"],
	"clause": ["K L AO1 Z"],
	"claw": ["K L AO1"],
	"clay": ["K L EY1"],
	"clean": ["K L IY1 N"],
	"clear": ["K L IY1 R"],
	"cleaving": ["K L IY1 V IH0 NG"],
	"clef": ["K L EH1 F"],
	"cleft": ["K L EH1 F T"],
	"clematis": ["K L EH0 M AA1 T IH0 S"],
	"clemency": ["K L EH1 M AH0 N S IY2"],
	"cleopatra": ["K L IY2 OW0 P AE1 T R AH0"],
	"clergyman": ["K L ER1 JH IY0 M AH3 N"],
	"clerk": ["K L ER1 K"],
	"clever": ["K L EH1 V ER0"],
	"cleverest": ["K L EH1 V R AH0 S T"],
	"click": ["K L IH1 K"],
	"clients": ["K L AY1 AH0 N T S"],
	"clifts": ["K L IH1 F T S"],
	"climate": ["K L AY1 M IH0 T"],
	"climb": ["K L AY1 M"],
	"climb'd": ["K L AY1 MD"],
	"clime": ["K L AY1 M"],
	"cling": ["K L IH1 NG"],
	"clink": ["K L IH1 NG K"],
	"clipped": ["K L IH1 P T"],
	"cloak": ["K L OW1 K"],
	"clock": ["K L AA1 K"],
	"clod": ["K L AA1 D"],
	"clog": ["K L AA1 G"],
	"close": ["K L OW1 Z", "K L OW1 S"],
	"closed": ["K L OW1 Z D"],
	"closer": ["K L OW1 S ER0"],
	"closet": ["K L AA1 Z IH0 T"],
	"closing": ["K L OW1 Z IH0 NG"],
	"clothe": ["K L OW1 DH"],
	"clothed": ["K L OW1 DH D"],
	"cloud": ["K L AW1 D"],
	"cloudier": ["K L AW1 D IY0 ER0", "K L AW1 D Y ER0"],
	"cloudy": ["K L AW1 D IY0"],
	"clove": ["K L OW1 V"],
	"cloven": ["K L OW1 V AH0 N"],
	"clover": ["K L OW1 V ER0"],
	"clown": ["K L AW1 N"],
	"cloy": ["K L OY1"],
	"club": ["K L AH1 B"],
	"clue": ["K L UW1"],
	"clumsy": ["K L AH1 M Z IY0"],
	"clung": ["K L AH1 NG"],
	"cluster": ["K L AH1 S T ER0"],
	"clutch": ["K L AH1 CH"],
	"co": ["K OW1"],
	"coach": ["K OW1 CH"],
	"coal": ["K OW1 L"],
	"coast": ["K OW1 S T"],
	"coat": ["K OW1 T"],
	"coax": ["K OW1 K S"],
	"cobweb": ["K AA1 B W EH2 B"],
	"cobwebs": ["K AA1 B W EH2 B Z"],
	"cochineal": ["K AA1 K AH0 N IY2 L"],
	"cock": ["K AA1 K"],
	"cockade": ["K AA2 K EY1 D"],
	"cocoa": ["K OW1 K OW0"],
	"cocoon": ["K AH0 K UW1 N"],
	"coded": ["K OW1 D IH0 D"],
	"codicil": ["K AA1 D AH0 S IH2 L"],
	"coelebs": ["K W EY1 L EH0 B Z"],
	"coeur": ["K UW1 R"],
	"coffer": ["K AO1 F ER0"],
	"coffin": ["K AO1 F IH0 N"],
	"cog": ["K AO1 G"],
	"cohort": ["K OW1 HH AO2 R T"],
	"coil": ["K OY1 L"],
	"coin": ["K OY1 N"],
	"coinage": ["K OY1 N AH0 JH"],
	"cold": ["K OW1 L D"],
	"collect": ["K AH0 L EH1 K T"],
	"college": ["K AA1 L IH0 JH"],
	"colonnade": ["K AA2 L AH0 N EY1 D"],
	"colony": ["K AA1 L AH0 N IY3"],
	"color": ["K AH1 L ER0"],
	"colossal": ["K AH0 L AA1 S AH0 L"],
	"colour": ["K AH1 L ER0"],
	"columba": ["K OW2 L AH1 M B AH0"],
	"columbine": ["K AA1 L AH0 M B AY2 N"],
	"columbus": ["K AH0 L AH1 M B AH0 S"],
	"column": ["K AA1 L AH0 M"],
	"columnar": ["K AH0 L AH1 M N ER0"],
	"comb": ["K OW1 M"],
	"combin'd": ["K AH0 M B AY1 N D"],
	"combination": ["K AA2 M B AH0 N EY1 SH AH0 N"],
	"combine": ["K AH0 M B AY1 N"],
	"combined": ["K AH0 M B AY1 N D"],
	"come": ["K AH1 M"],
	"comer": ["K AH1 M ER0"],
	"comest": ["K AH1 M AH0 S T"],
	"comet": ["K AA1 M IH0 T"],
	"comfort": ["K AH1 M F ER0 T"],
	"coming": ["K AH1 M IH0 NG"],
	"comissary": ["K AA1 M IH0 S EH2 R IY0"],
	"comitatus": ["K AO2 M AH0 T EY1 T AH0 S"],
	"command": ["K AH0 M AE1 N D"],
	"commandment": ["K AH0 M AE1 N D M AH0 N T"],
	"commemorate": ["K AH0 M EH1 M ER0 EY2 T"],
	"commence": ["K AH0 M EH1 N S"],
	"commend": ["K AH0 M EH1 N D"],
	"comment": ["K AA1 M EH0 N T"],
	"commentator": ["K AA1 M AH0 N T EY2 T ER0"],
	"commerce": ["K AA1 M ER0 S"],
	"commit": ["K AH0 M IH1 T"],
	"common": ["K AA1 M AH0 N"],
	"commonplace": ["K AA1 M AH0 N P L EY2 S"],
	"commonwealth": ["K AA1 M AH0 N W EH2 L TH"],
	"commune": ["K AH0 M Y UW1 N"],
	"communicate": ["K AH0 M Y UW1 N AH0 K EY2 T"],
	"communion": ["K AH0 M Y UW1 N Y AH0 N"],
	"commuted": ["K AH0 M Y UW1 T IH0 D"],
	"compact": ["K AA1 M P AE0 K T", "K AH0 M P AE1 K T"],
	"companion": ["K AH0 M P AE1 N Y AH0 N"],
	"companionship": ["K AH0 M P AE1 N Y AH0 N SH IH2 P"],
	"company": ["K AH1 M P AH0 N IY3"],
	"comparative": ["K AH0 M P EH1 R AH0 T IH2 V"],
	"compare": ["K AH0 M P EH1 R"],
	"comparison": ["K AH0 M P EH1 R AH0 S AH3 N"],
	"compass": ["K AH1 M P AH0 S"],
	"compassion": ["K AH0 M P AE1 SH AH0 N"],
	"compeers": ["K AH0 M P IY1 R Z"],
	"compel": ["K AH0 M P EH1 L"],
	"compell": ["K AH0 M P EH1 L"],
	"compellant": ["K AH0 M P EH1 L AH0 N T"],
	"compensate": ["K AA1 M P AH0 N S EY2 T"],
	"compensation": ["K AA2 M P AH0 N S EY1 SH AH0 N"],
	"competeless": ["K AH0 M P IY1 T L AH0 S"],
	"competence": ['K AA1 M P AH0 T AH3 N S'],
	"competent": ["K AA1 M P AH0 T AH3 N T"],
	"competing": ["K AH0 M P IY1 T IH0 NG"],
	"competition": ["K AA2 M P AH0 T IH1 SH AH0 N"],
	"competitor": ["K AH0 M P EH1 T IH0 T ER0"],
	"compil'd": ["K AH0 M P AY1 L D"],
	"compile": ["K AH0 M P AY1 L"],
	"complain": ["K AH0 M P L EY1 N"],
	"complaint": ["K AH0 M P L EY1 N T"],
	"complete": ["K AH0 M P L IY1 T"],
	"completed": ["K AH0 M P L IY1 T IH0 D"],
	"complex": ["K AA1 M P L EH0 K S", "C AH0 M P L EH1 K S"],
	"complexion": ["K AH0 M P L EH1 K SH AH0 N"],
	"complicate": ["K AA1 M P L AH0 K EY2 T"],
	"complied": ["K AH0 M P L AY1 D"],
	"compliment": ["K AA1 M P L AH0 M AH3 N T"],
	"comply": ["K AH0 M P L AY1"],
	"compose": ["K AH0 M P OW1 Z"],
	"composed": ["K AH0 M P OW1 Z D"],
	"composite": ["K AH0 M P AA1 Z AH0 T"],
	"composure": ["K AH0 M P OW1 ZH ER0"],
	"compound": ["K AA1 M P AW0 N D", "K AH0 M P AW1 N D"],
	"comprehend": ["K AA2 M P R IY0 HH EH1 N D"],
	"comprehension": ["K AA2 M P R IY0 HH EH1 N SH AH0 N"],
	"compress": ["K AH0 M P R EH1 S"],
	"comprise": ["K AH0 M P R AY1 Z"],
	"comprising": ["K AH0 M P R AY1 Z IH0 NG"],
	"compromise": ["K AA1 M P R AH0 M AY2 Z"],
	"compunction": ["K AH0 M P AH1 NG K SH AH0 N"],
	"comrade": ["K AA1 M R AE2 D"],
	"con": ["K AA1 N"],
	"concave": ["K AA0 N K EY1 V", "K AA1 N K EY0 V"],
	"conceal": ["K AH0 N S IY1 L"],
	"concedes": ["K AH0 N S IY1 D Z"],
	"conceit": ["K AH0 N S IY1 T"],
	"conceive": ["K AH0 N S IY1 V"],
	"conceived": ["K AH0 N S IY1 V D"],
	"concentrate": ["K AA1 N S AH0 N T R EY2 T"],
	"conception": ["K AH0 N S EH1 P SH AH0 N"],
	"concern": ["K AH0 N S ER1 N"],
	"conclude": ["K AH0 N K L UW1 D"],
	"concluded": ["K AH0 N K L UW1 D IH0 D"],
	"conclusion": ["K AH0 N K L UW1 ZH AH0 N"],
	"concoction": ["K AH0 N K AA1 K SH AH0 N"],
	"concupiscent": ["K AA1 N K Y UW0 P IH1 S AH0 N T"],
	"condemn": ["K AH0 N D EH1 M"],
	"condense": ["K AH0 N D EH1 N S"],
	"condescend": ["K AA2 N D IH0 S EH1 N D"],
	"condition": ["K AH0 N D IH1 SH AH0 N"],
	"conduct": ["K AA1 N D AH0 K T", "K AA0 N D AH1 K T"],
	"conduct's": ["K AA1 N D AH0 K T S"],
	"cones": ["K OW1 N Z"],
	"confederates": ["K AH0 N F EH1 D ER0 AH3 T S"],
	"confer": ["K AH0 N F ER1"],
	"conference": ["K AA1 N F ER0 AH0 N S"],
	"conferred": ["K AH0 N F ER1 D"],
	"conferring": ["K AH0 N F ER1 IH0 NG"],
	"confess": ["K AH0 N F EH1 S"],
	"confessions": ["K AH0 N F EH1 SH AH0 N Z"],
	"confessor": ["K AA2 N F EH0 S AO1 R"],
	"confest": ["K AH0 N F EH1 S T"],
	"confide": ["K AH0 N F AY1 D"],
	"confidence": ["K AA1 N F AH0 D AH3 N S"],
	"confident": ["K AA1 N F AH0 D AH3 N T"],
	"confiding": ["K AH0 N F AY1 D IH0 NG"],
	"confin'd": ["K AH0 N F AY1 N D"],
	"confined": ["K AH0 N F AY1 N D"],
	"confirm": ["K AH0 N F ER1 M"],
	"conform": ["K AH0 N F AO1 R M"],
	"confound": ["K AA0 N F AW1 N D"],
	"confront": ["K AH0 N F R AH1 N T"],
	"confuse": ["K AH0 N F Y UW1 Z"],
	"confusion": ["K AH0 N F Y UW1 ZH AH0 N"],
	"congealed": ["K AH0 N JH IY1 L D"],
	"congenial": ["K AH0 N JH IY1 N Y AH0 L"],
	"congratulate": ["K AH0 N G R AE1 CH AH0 L EY2 T"],
	"congregation": ["K AA2 NG G R AH0 G EY1 SH AH0 N"],
	"conjecture": ["K AH0 N JH EH1 K SH ER0"],
	"conjecturing": ["K AH0 N JH EH1 K CH ER0 IH3 NG"],
	"conjugate": ["K AA1 N JH AH0 G EY2 T"],
	"conjuror": ["K AA1 N JH ER0 ER0"],
	"connect": ["K AH0 N EH1 K T"],
	"connection": ["K AH0 N EH1 K SH AH0 N"],
	"conned": ["K AA1 N D"],
	"connived": ["K AH0 N AY1 V D"],
	"conqu'ring": ["C AA1 NG K R IH0 NG"],
	"conquer": ["K AA1 NG K ER0"],
	"conquering": ["K AA1 NG K ER0 IH3 NG", "K AA1 NG K R IH0 NG"],
	"cons": ["K AA1 N Z"],
	"conscience": ["K AA1 N SH AH0 N S"],
	"conscientious": ["K AA2 N SH IY0 EH1 N SH AH0 S"],
	"conscious": ["K AA1 N SH AH0 S"],
	"consciousness": ["K AA1 N SH AH0 S N AH3 S"],
	"consecrate": ["K AA1 N S AH0 K R EY2 T"],
	"consecutive": ["K AH0 N S EH1 K Y AH0 T IH3 V"],
	"consented": ["K AH0 N S EH1 N T IH0 D"],
	"consequence": ["K AA1 N S AH0 K W AH3 N S"],
	"consider": ["K AH0 N S IH1 D ER0"],
	"considerations": ["K AH0 N S IH2 D ER0 EY1 SH AH0 N Z"],
	"consolation": ["K AA2 N S AH0 L EY1 SH AH0 N"],
	"console": ["K AH0 N S OW1 L"],
	"consolidates": ["K AH0 N S AA1 L IH0 D EY2 T S"],
	"conspicuous": ["K AH0 N S P IH1 K Y UW0 AH3 S"],
	"conspire": ["K AH0 N S P AY1 ER0", "K AH0 N S P AY1 R"],
	"constancy": ["K AA1 N S T AH0 N S IY3"],
	"constant": ["K AA1 N S T AH0 N T"],
	"constantly": ["K AA1 N S T AH0 N T L IY3"],
	"constellation": ["K AA2 N S T AH0 L EY1 SH AH0 N"],
	"constitute": ["K AA1 N S T AH0 T UW2 T"],
	"constrain": ["K AH0 N S T R EY1 N"],
	"construct": ["K AH0 N S T R AH1 K T", "K AA1 N S T R AH2 K T"],
	"construction": ["K AH0 N S T R AH1 K SH AH0 N"],
	"consular": ["K AA1 N S AH0 L ER0"],
	"consuls": ["K AA1 N S AH0 L Z"],
	"consume": ["K AH0 N S UW1 M"],
	"consumed": ["K AH0 N S UW1 M D"],
	"consummate": ["K AA1 N S AH0 M AH0 T", "K AA1 N S AH0 M EY2 T"],
	"consummated": ["K AA1 N S AH0 M EY2 T IH0 D"],
	"consummation": ["K AA2 N S AH0 M EY1 SH AH0 N"],
	"contagion": ["K AH0 N T EY1 JH AH0 N"],
	"contagious": ["K AH0 N T EY1 JH AH0 S"],
	"contain": ["K AH0 N T EY1 N"],
	"contemn": ["K AH0 N T EH1 M"],
	"contemplate": ["K AA1 N T AH0 M P L EY2 T"],
	"contemplated": ["K AA1 N T AH0 M P L EY2 T IH0 D"],
	"contemplating": ["K AA1 N T AH0 M P L EY2 T IH0 NG"],
	"contemplation": ["K AA2 N T AH0 M P L EY1 SH AH0 N"],
	"contempt": ["K AH0 N T EH1 M P T"],
	"contend": ["K AH0 N T EH1 N D"],
	"content": ["K AA1 N T EH0 N T", "K AH0 N T EH1 N T"],
	"contented": ["K AH0 N T EH1 N T IH0 D"],
	"contenteder": ["K AH0 N T EH1 N T AH0 D ER0"],
	"contenteth": ["K AH0 N T EH1 N T IH0 TH"],
	"contentment": ["K AH0 N T EH1 N T M AH0 N T"],
	"contents": ["K AA1 N T EH0 N T S", "K AH0 N T EH1 N T S"],
	"contested": ["K AH0 N T EH1 S T IH0 D"],
	"continent": ["K AA1 N T AH0 N IH0 AH0 N T"],
	"continual": ["K AH0 N T IH1 N Y UW0 AH0 L", "K AH0 N T IH1 N W AH0 L"],
	"continually": ["K AH0 N T IH1 N Y UW0 AH0 L IY3", "K AH0 N T IH1 N Y UW0 L IY3"],
	"continue": ["K AH0 N T IH1 N Y UW0"],
	"continued": ["K AH0 N T IH1 N Y UW0 D"],
	"continuing": ["K AH0 N T IH1 N Y UW0 IH2 NG"],
	"contra": ["K AA1 N T R AH0"],
	"contract": ["K AA1 N T R AE2 K T", "K AH0 N T R AE1 K T"],
	"contracted": ["K AH0 N T R AE1 K T IH0 D"],
	"contrarious": ["K AH0 N T R EH1 R Y AH0 S"],
	"contrary": ["K AA1 N T R EH0 R IY0"],
	"contrast": ["K AA1 N T R AE0 S T", "K AH0 N T R AE1 S T"],
	"contrition": ["K AH0 N T R IH1 SH AH0 N"],
	"contrive": ["K AH0 N T R AY1 V"],
	"contrived": ["K AH0 N T R AY1 V D"],
	"contriving": ["K AH0 N T R AY1 V IH0 NG"],
	"control": ["K AH0 N T R OW1 L"],
	"controlled": ["K AH0 N T R OW1 L D"],
	"controlling": ["K AH0 N T R OW1 L IH0 NG"],
	"controvertible": ["K AA2 N T R AH0 V ER1 T IH0 B AH3 L"],
	"contumely": ["K AA1 N T Y UW0 M L IY3"],
	"convalescence": ["K AA2 N V AH0 L EH1 S AH0 N S"],
	"convalescent": ["K AA2 N V AH0 L EH1 S AH0 N T"],
	"convenient": ["K AH0 N V IY1 N Y AH0 N T"],
	"convent": ["K AA1 N V EH2 N T"],
	"converges": ["K AH0 N V ER1 JH AH0 Z"],
	"conversation": ["K AA2 N V ER0 S EY1 SH AH0 N"],
	"converse": ["K AH0 N V ER1 S", "K AA1 N V ER0 S"],
	"conversion": ["K AH0 N V ER1 ZH AH0 N"],
	"convert": ["K AA1 N V ER0 T", "K AH0 N V ER1 T"],
	"convertest": ["K AH0 N V ER1 T IH0 S T"],
	"convex": ["K AA2 N V EH1 K S", "K AA1 N V EH2 K S"],
	"convey": ["K AH0 N V EY1"],
	"convict": ["K AH0 N V IH1 K T", "K AA1 N V IH0 K T"],
	"conviction": ["K AH0 N V IH1 K SH AH0 N"],
	"convicts": ["K AH0 N V IH1 K T S", "K AA1 N V IH0 K T S"],
	"convince": ["K AH0 N V IH1 N S"],
	"convinced": ["K AH0 N V IH1 N S T"],
	"convincing": ["K AH0 N V IH1 N S IH0 NG"],
	"convulsion": ["K AH0 N V AH1 L SH AH0 N"],
	"convulsive": ["K AH0 N V AH1 L S IH0 V"],
	"cool": ["K UW1 L"],
	"coolly": ["K UW1 L IY0"],
	"cope": ["K OW1 P"],
	"copied": ["K AA1 P IY0 D"],
	"copy": ["K AA1 P IY0"],
	"coral": ["K AO1 R AH0 L"],
	"cord": ["K AO1 R D"],
	"cordial": ["K AO1 R JH AH0 L"],
	"cordiality": ["K AO0 R JH IY0 AE1 L AH0 T IY2"],
	"cordially": ["K AO1 R JH AH0 L IY3"],
	"cordillera": ["K AO1 R D IH0 L EH2 R AH0"],
	"core": ["K AO1 R"],
	"corinthians": ["K ER0 IH1 N TH Y AH0 N Z"],
	"cormorant": ["K AO1 R M ER0 AH3 N T", "K AO1 R M R AH0 N T"],
	"corn": ["K AO1 R N"],
	"cornelian": ["K AO0 R N IY1 L Y AH0 N"],
	"corner": ["K AO1 R N ER0"],
	"cornets": ["K AO1 R N IH0 T S"],
	"corniani": ["K AO0 R N Y AA1 N IY0"],
	"coronal": ["K AO1 R AH0 N AH3 L", "K ER0 OW1 N AH0 L"],
	"coronation": ["K AO2 R AH0 N EY1 SH AH0 N"],
	"coroner": ["K AO1 R AH0 N ER0"],
	"coronets": ["K AO2 R AH0 N EH1 T S"],
	"corporeal": ["K AO0 R P AO1 R Y AH0 L"],
	"corpse": ["K AO1 R P S"],
	"correct": ["K ER0 EH1 K T"],
	"correction": ["K ER0 EH1 K SH AH0 N"],
	"corrector": ["K ER0 EH1 K T ER0"],
	"corridors": ["K AO1 R IH0 D AO2 R Z"],
	"corruption": ["K ER0 AH1 P SH AH0 N"],
	"corse": ["K AO1 R S"],
	"corydon": ["K AO1 R IH0 D AA2 N"],
	"cost": ["K AA1 S T"],
	"costlier": ["K AO1 S T L IY0 ER0"],
	"costume": ["K AA1 S T UW2 M"],
	"cot": ["K AA1 T"],
	"cote": ["K OW1 T"],
	"cotemporaneous": ["C OW3 T EH2 M P AH0 R EY1 N Y AH0 S"],
	"coterie": ["K OW1 T ER0 IY3"],
	"cottage": ["K AA1 T IH0 JH"],
	"couch": ["K AW1 CH"],
	"couch\u00e8d": ["C AW1 CH IH0 D"],
	"could": ["K UH1 D","K UH3 D"],
	"couldn't": ["K UH1 D AH0 N T"],
	"councils": ["K AW1 N S AH0 L Z"],
	"counsel": ["K AW1 N S AH0 L"],
	"count": ["K AW1 N T"],
	"countenance": ["K AW1 N T AH0 N AE3 N S"],
	"counteract": ["K AW2 N T ER0 AE1 K T"],
	"counterchecks": ["K AW1 N T ER0 CH EH2 K S"],
	"counterfeit": ["K AW1 N T ER0 F IH2 T"],
	"countermand": ["K AW2 N T ER0 M AE1 N D"],
	"counterpart": ["K AW1 N T ER0 P AA2 R T"],
	"counterpass": ["K AW1 N T ER0 P AE2 S"],
	"counties": ["K AW1 N T IY0 Z"],
	"countries": ["K AH1 N T R IY0 Z"],
	"country": ["K AH1 N T R IY0"],
	"countrymen": ["K AH1 N T R IY0 M IH2 N"],
	"couple": ["K AH1 P AH0 L"],
	"couplet": ["K AH1 P L AH0 T"],
	"courage": ["K ER1 IH0 JH"],
	"courier": ["K ER1 IY0 ER2"],
	"course": ["K AO1 R S"],
	"court": ["K AO1 R T"],
	"courteous": ["K ER1 T Y AH0 S", "K ER1 T IY0 AH3 S"],
	"courtesies": ["K ER1 T AH0 S IY3 Z"],
	"courtesy": ["K ER1 T AH0 S IY3"],
	"courtier": ["K AO1 R T Y ER0", "K AO1 R T IY0 ER0"],
	"courtiers": ["K AO1 R T Y ER0 Z", "K AO1 R T IY0 ER3 Z"],
	"courtship": ["K AO1 R CH IH2 P"],
	"cove": ["K OW1 V"],
	"covenant": ["K AH1 V AH0 N AH2 N T"],
	"cover": ["K AH1 V ER0"],
	"covered": ["K AH1 V ER0 D"],
	"covert": ["K OW1 V ER0 T"],
	"coverture": ["K AH1 V ER0 CH ER0"],
	"covet": ["K AH1 V AH0 T"],
	"coveting": ["C AH1 V AH0 T IH3 NG"],
	"cow": ["K AW1"],
	"coward": ["K AW1 ER0 D"],
	"cower": ["K AW1 ER0"],
	"cowslip": ["K AW1 S L IH2 P"],
	"coy": ["K OY1"],
	"crack": ["K R AE1 K"],
	"crackling": ["K R AE1 K L IH0 NG"],
	"cradle": ["K R EY1 D AH0 L"],
	"craft": ["K R AE1 F T"],
	"crag": ["K R AE1 G"],
	"crake": ["K R EY1 K"],
	"cram": ["K R AE1 M"],
	"cramp": ["K R AE1 M P"],
	"crane": ["K R EY1 N"],
	"cranny": ["K R AE1 N IY0"],
	"crape": ["K R EY1 P"],
	"crash": ["K R AE1 SH"],
	"crashe's": ["K R AE1 SH AH0 Z"],
	"cravat": ["K R AH0 V AE1 T"],
	"crave": ["K R EY1 V"],
	"craved": ["K R EY1 V D"],
	"craven": ["K R EY1 V AH0 N"],
	"crawl": ["K R AO1 L"],
	"crayon": ["K R EY1 AA0 N"],
	"crayons": ["K R EY1 AA2 N Z"],
	"crazes": ["K R EY1 Z AH0 Z"],
	"creak": ["K R IY1 K"],
	"cream": ["K R IY1 M"],
	"crease": ["K R IY1 S"],
	"creases": ["K R IY1 S IH0 Z"],
	"create": ["K R IY0 EY1 T"],
	"created": ["K R IY0 EY1 T IH0 D"],
	"creation": ["K R IY0 EY1 SH AH0 N"],
	"creator": ["K R IY0 EY1 T ER0"],
	"creature": ["K R IY1 CH ER0"],
	"credibility": ["K R EH2 D AH0 B IH1 L IH0 T IY2"],
	"credit": ["K R EH1 D AH0 T"],
	"creditor": ["K R EH1 D IH0 T ER3"],
	"credulous": ["K R EH1 JH AH0 L AH0 S"],
	"creed": ["K R IY1 D"],
	"creep": ["K R IY1 P"],
	"crept": ["K R EH1 P T"],
	"crescent": ["K R EH1 S AH0 N T"],
	"cress": ["K R EH1 S"],
	"crest": ["K R EH1 S T"],
	"crete": ["K R IY1 T"],
	"crew": ["K R UW1"],
	"crews": ["K R UW1 Z"],
	"cribs": ["K R IH1 B Z"],
	"cricket": ["K R IH1 K IH0 T"],
	"cried": ["K R AY1 D"],
	"cries": ["K R AY1 Z"],
	"crillon": ["K R IY1 AA0 N"],
	"crime": ["K R AY1 M"],
	"crimson": ["K R IH1 M Z AH0 N"],
	"crisis": ["K R AY1 S AH0 S"],
	"crisp": ["K R IH1 S P"],
	"criterion": ["K R AY0 T IY1 R IY0 AH3 N", "K R AY0 T IY1 R Y AH0 N"],
	"critic": ["K R IH1 T IH0 K"],
	"criticize": ["K R IH1 T IH0 S AY2 Z"],
	"crocodile": ["K R AA1 K AH0 D AY2 L"],
	"crocus": ["K R OW1 K AH0 S"],
	"croft": ["K R AO1 F T"],
	"crooked": ["K R UH1 K IH0 D"],
	"croon": ["K R UW1 N"],
	"crop": ["K R AA1 P"],
	"cross": ["K R AO1 S"],
	"crost": ["K R AA1 S T"],
	"crouch": ["K R AW1 CH"],
	"crow": ["K R OW1"],
	"crowd": ["K R AW1 D"],
	"crown": ["K R AW1 N"],
	"crucified": ["K R UW1 S AH0 F AY2 D"],
	"crucifix": ["K R UW1 S AH0 F IH2 K S"],
	"crucifixal": ["K R UW2 S IH0 F IH1 K S AH0 L"],
	"crucifixion": ["K R UW2 S IH0 F IH1 K SH AH0 N"],
	"crucify": ["K R UW1 S IH0 F AY2"],
	"crude": ["K R UW1 D"],
	"cruel": ["K R UW1 AH0 L", "K R UW1 L"],
	"cruelty": ["K R UW1 L T IY0"],
	"cruising": ["K R UW1 Z IH0 NG"],
	"crumb": ["K R AH1 M"],
	"crumbling": ["K R AH1 M B AH0 L IH3 NG", "K R AH1 M B L IH0 NG"],
	"crusade": ["K R UW0 S EY1 D"],
	"crush": ["K R AH1 SH"],
	"crust": ["K R AH1 S T"],
	"crutch": ["K R AH1 CH"],
	"cruz": ["K R UW1 Z"],
	"cry": ["K R AY1"],
	"crypt": ["K R IH1 P T"],
	"crystal": ["K R IH1 S T AH0 L"],
	"crystalline": ["K R IH1 S T AH0 L AY2 N"],
	"cube": ["K Y UW1 B"],
	"cubit": ["K Y UW1 B IH0 T"],
	"cuckolds": ["K AH1 K AH0 L D Z"],
	"cuckoo": ["K UW1 K UW2"],
	"cuckoosong": ["K UW1 KUW0 S AA1 NG"],
	"cue": ["K Y UW1"],
	"cull": ["K AH1 L"],
	"culminate": ["K AH1 L M AH0 N EY2 T"],
	"culpabler": ["K AH1 L P AH0 B L ER3"],
	"culprit": ["K AH1 L P R IH0 T"],
	"cultivate": ["K AH1 L T AH0 V EY2 T"],
	"cumbrous": ["K AH1 M B R AH0 S"],
	"cunning": ["K AH1 N IH0 NG"],
	"cup": ["K AH1 P"],
	"cupboards": ["K AH1 B ER0 D Z"],
	"cupid": ["K Y UW1 P IH0 D"],
	"cupidity": ["K Y UW0 P IH1 D IH0 T IY3"],
	"cur'd": ["K Y UH1 R D"],
	"curb": ["K ER1 B"],
	"curd": ["K ER1 D"],
	"curdle": ["K ER1 D AH0 L"],
	"cure": ["K Y UH1 R"],
	"curiosity": ["K Y UH2 R IY0 AA1 S AH0 T IY3"],
	"curious": ["K Y UH1 R IY0 AH3 S", "K Y UH1 R Y AH0 S"],
	"curiously": ["K Y UH1 R IY0 AH0 S L IY3", "K Y UH1 R Y AH0 S L IY3"],
	"curl": ["K ER1 L"],
	"curly": ["K ER1 L IY0"],
	"currency": ["K ER1 AH0 N S IY3"],
	"current": ["K ER1 AH0 N T"],
	"currer": ["K ER1 ER0"],
	"curricle": ["K ER1 AH0 K AH3 L"],
	"curse": ["K ER1 S"],
	"curses": ["K ER1 S IH0 Z"],
	"curtail": ["K ER0 T EY1 L"],
	"curtain": ["K ER1 T AH0 N"],
	"curve": ["K ER1 V"],
	"curv\u00ebd": ["K ER1 V IH0 D"],
	"cushion": ["K UH1 SH AH0 N"],
	"custom": ["K AH1 S T AH0 M"],
	"customary": ["K AH1 S T AH0 M EH2 R IY0"],
	"cut": ["K AH1 T"],
	"cuticle": ["K Y UW1 T AH0 K AH3 L"],
	"cutter": ["K AH1 T ER0"],
	"cyclades": ["S AY0 K L EY1 D IY2 Z"],
	"cycle": ["S AY1 K AH0 L"],
	"cyclopes": ["S AY1 K L AO2 P S"],
	"cyclops": ["S AY1 K L AO2 P S"],
	"cypher": ["S AY1 F ER0"],
	"cypress": ["S AY1 P R AH0 S"],
	"cyprus": ["S AY1 P R AH0 S"],
	"cytherea": ["S IH2 TH ER0 IY1 AH0"],
	"czar": ["Z AA1 R"],
	"da": ["D AA3"],
	"dad": ["D AE1 D"],
	"daddy": ["D AE1 D IY0"],
	"daffodil": ["D AE1 F AH0 D IH2 L"],
	"dagger": ["D AE1 G ER0"],
	"daily": ["D EY1 L IY0"],
	"daintier": ["D EY1 N T Y ER0", "D EY1 N T IY0 ER3"],
	"daintily": ["D EY1 N T AH0 L IY3"],
	"dainty": ["D EY1 N T IY0"],
	"daisies": ["D EY1 Z IY0 Z"],
	"daisy": ["D EY1 Z IY0"],
	"dale": ["D EY1 L"],
	"dam": ["D AE1 M"],
	"damask": ["D AE1 M AH0 S K"],
	"dame": ["D EY1 M"],
	"damn": ["D AE1 M"],
	"damn\u00e8d": ["D AE1 M N IH0 D"],
	"damp": ["D AE1 M P"],
	"damsel": ["D AE1 M Z AH0 L"],
	"dance": ["D AE1 N S"],
	"danced": ["D AE1 N S T"],
	"dancer": ["D AE1 N S ER0"],
	"dancing": ["D AE1 N S IH0 NG"],
	"dandelion's": ["D AE1 N D AH0 L AY2 AH0 N Z"],
	"dane": ["D EY1 N"],
	"danger": ["D EY1 N JH ER0"],
	"dangerous": ["D EY1 N JH ER0 AH0 S"],
	"dangle": ["D AE1 N G AH0 L"],
	"dangled": ["D AE1 NG G AH0 L D"],
	"dank": ["D AE1 NG K"],
	"dante": ["D AA1 N T EY0"],
	"dare": ["D EH1 R"],
	"dared": ["D EH1 R D"],
	"dark": ["D AA1 R K"],
	"darken": ["D AA1 R K AH0 N"],
	"darkening": ["D AA1 R K AH0 N IH3 NG", "D AA1 R K N IH0 NG"],
	"darkness": ["D AA1 R K N AH0 S"],
	"darling": ["D AA1 R L IH0 NG"],
	"dart": ["D AA1 R T"],
	"dash": ["D AE1 SH"],
	"date": ["D EY1 T"],
	"daughter": ["D AO1 T ER0"],
	"dauntless": ["D AO1 N T L AH0 S"],
	"david": ["D EY1 V IH0 D"],
	"daw": ["D AO1"],
	"dawn": ["D AO1 N"],
	"day": ["D EY1"],
	"daybreak": ["D EY1 B R EY2 K"],
	"daylight": ["D EY1 L AY2 T"],
	"daytime": ["D EY1 T AY2 M"],
	"dazed": ["D EY1 Z D"],
	"dazzled": ["D AE1 Z AH0 L D"],
	"dazzling": ["D AE1 Z AH0 L IH3 NG", "D AE1 Z L IH0 NG"],
	"dead": ["D EH1 D"],
	"deaden": ["D EH1 D AH0 N"],
	"deaf": ["D EH1 F"],
	"deal": ["D IY1 L"],
	"dealt": ["D EH1 L T"],
	"dear": ["D IY1 R"],
	"dearth": ["D ER1 TH"],
	"death": ["D EH1 TH"],
	"deathbed": ["D EH1 TH B EH2 D"],
	"deathlessness": ["D EH1 TH L AH0 S N AH2 S"],
	"deathweights": ["D EH1 TH W EY2 T S"],
	"debar": ["D IH0 B AA1 R"],
	"debase": ["D AH0 B EY1 S"],
	"debate": ["D AH0 B EY1 T"],
	"debated": ["D AH0 B EY1 T IH0 D"],
	"debating": ["D AH0 B EY1 T IH0 NG"],
	"debauch": ["D AH0 B AA1 CH"],
	"debauchee": ["D IH2 B AA0 CH IY1"],
	"debility": ["D AH0 B IH1 L AH0 T IY3"],
	"debonnaire": ["D EH2 B AH0 N EH1 R"],
	"debt": ["D EH1 T"],
	"decade": ["D EH1 K EY0 D", "D EH0 K EY1 D"],
	"decades": ["D EH1 K EY0 D Z"],
	"decaied": ["D IH0 K EY1 D"],
	"decalogues": ["D EH1 K AH0 L AA2 G Z"],
	"decay": ["D IH0 K EY1"],
	"decease": ["D IH0 S IY1 S"],
	"deceit": ["D AH0 S IY1 T"],
	"deceiv'd": ["D IH0 S IY1 V D"],
	"deceive": ["D IH0 S IY1 V"],
	"deceivest": ["D IH0 S IY1 V IH2 S T"],
	"december": ["D IH0 S EH1 M B ER0"],
	"decide": ["D IH2 S AY1 D"],
	"decimal": ["D EH1 S AH0 M AH2 L"],
	"decision": ["D IH0 S IH1 ZH AH0 N"],
	"deck": ["D EH1 K"],
	"declare": ["D IH0 K L EH1 R"],
	"declared": ["D IH0 K L EH1 R D"],
	"decline": ["D IH0 K L AY1 N"],
	"declined": ["D IH0 K L AY1 N D"],
	"declining": ["D IH0 K L AY1 N IH0 NG"],
	"decomposition": ["D IY0 K AA2 M P AH0 Z IH1 SH AH0 N"],
	"decoy": ["D IY1 K OY0"],
	"decrease": ["D IH0 K R IY1 S"],
	"decreased": ["D IH0 K R IY1 S T"],
	"decree": ["D IH0 K R IY1"],
	"decreed": ["D IH0 K R IY1 D"],
	"dedicated": ["D EH1 D AH0 K EY0 T AH3 D"],
	"deed": ["D IY1 D"],
	"deem": ["D IY1 M"],
	"deep": ["D IY1 P"],
	"deepen": ["D IY1 P AH0 N"],
	"deer": ["D IY1 R"],
	"defac'd": ["D IH0 F EY1 S T"],
	"deface": ["D IH0 F EY1 S"],
	"defaced": ["D IH0 F EY1 S T"],
	"defeat": ["D IH0 F IY1 T"],
	"defect": ["D IY1 F EH0 K T"],
	"defence": ["D IH0 F EH1 N S"],
	"defend": ["D IH0 F EH1 N D"],
	"defense": ["D IH0 F EH1 N S"],
	"defensible": ["D IH0 F EH1 N S AH0 B AH0 L"],
	"defer": ["D IH0 F ER1"],
	"deficiency": ["D IH0 F IH1 SH AH0 N S IY3"],
	"defied": ["D IH0 F AY1 D"],
	"defies": ["D IH0 F AY1 Z"],
	"defile": ["D IH0 F AY1 L"],
	"defiled": ["D IH0 F AY1 L D"],
	"defiling": ["D IH0 F AY1 L IH0 NG"],
	"define": ["D IH0 F AY1 N"],
	"defined": ["D IH0 F AY1 N D"],
	"definite": ["D EH1 F AH0 N IH2 T"],
	"definition": ["D EH2 F AH0 N IH1 SH AH0 N"],
	"deformed": ["D IH0 F AO1 R M D", "D IY2 F AO1 R M D"],
	"deformity": ["D IH0 F AO1 R M AH0 T IY3"],
	"defraud": ["D IH0 F R AO1 D"],
	"deft": ["D EH1 F T"],
	"defy": ["D IH0 F AY1"],
	"degrade": ["D IH0 G R EY1 D"],
	"degree": ["D IH0 G R IY1"],
	"deified": ["D IY1 AH0 F AY2 D"],
	"deign": ["D EY1 N"],
	"deities": ["D IY1 AH0 T IY3 Z"],
	"deity": ["D IY1 AH0 T IY3"],
	"deject": ["D IH0 JH EH1 K T"],
	"delay": ["D IH0 L EY1"],
	"delectable": ["D IH0 L EH1 K T AH0 B AH2 L"],
	"deliberate": ["D IH0 L IH1 B ER0 AH3 T", "D IH0 L IH1 B R AH0 T", "D IH0 L IH1 B ER0 EY2 T"],
	"delicate": ["D EH1 L AH0 K AH2 T"],
	"delicious": ["D IH0 L IH1 SH AH0 S"],
	"delight": ["D IH0 L AY1 T"],
	"delineation": ["D IH0 L IH2 N IY0 EY1 SH AH0 N"],
	"delinquent": ["D AH0 L IH1 NG K W AH0 N T"],
	"delirious": ["D IH0 L IY1 R Y AH0 S"],
	"delirium": ["D IH0 L IY1 R IY0 AH3 M"],
	"deliver": ["D AH0 L IH1 V ER0"],
	"deliverance": ["D IH0 L IH1 V ER0 AH2 N S"],
	"delivery": ["D AH0 L IH1 V ER0 IY3"],
	"dell": ["D EH1 L"],
	"deluge": ["D EH1 L Y UW0 JH"],
	"delvèd": ["D EH1 L V IH0 D"],
	"demand": ["D IH0 M AE1 N D"],
	"demean": ["D AH0 M IY1 N"],
	"demeanor": ["D AH0 M IY1 N ER0"],
	"demeanour": ["D AH0 M IY1 N ER0"],
	"demijohn": ["D EH1 M IY0 JH AA2 N"],
	"democrat": ["D EH1 M AH0 K R AE2 T"],
	"democratic": ["D EH2 M AH0 K R AE1 T IH0 K"],
	"demolish": ["D IH0 M AA1 L IH0 SH"],
	"demons": ["D IY1 M AH0 N Z"],
	"demonstration": ["D EH2 M AH0 N S T R EY1 SH AH0 N"],
	"demur": ["D AH0 M ER1"],
	"demure": ["D IH0 M Y UH1 R"],
	"demurer": ["D AH0 M Y UH1 R ER0"],
	"demurral": ["D AH0 M ER1 AH0 L"],
	"demurred": ["D IH0 M Y UH1 R D"],
	"den": ["D EH1 N"],
	"denial": ["D IH0 N AY1 AH0 L"],
	"denied": ["D IH0 N AY1 D"],
	"denies": ["D IH0 N AY1 Z"],
	"denizen": ["D EH1 N AH0 Z AH0 N"],
	"denominated": ["D IH0 N AA1 M AH0 N EY2 T IH0 D"],
	"denomination": ["D IH0 N AO2 M AH0 N EY1 SH AH0 N"],
	"denote": ["D IH0 N OW1 T"],
	"dense": ["D EH1 N S"],
	"denser": ["D EH1 N S ER0"],
	"deny": ["D IH0 N AY1"],
	"depart": ["D IH0 P AA1 R T"],
	"department": ["D IH0 P AA1 R T M AH0 N T"],
	"departure": ["D IH0 P AA1 R CH ER0"],
	"depend": ["D IH0 P EH1 N D"],
	"dependencies": ["D IH0 P EH1 N D AH0 N S IY3 Z"],
	"deplore": ["D IH0 P L AO1 R"],
	"deplored": ["D IH0 P L AO1 R D"],
	"deploy": ["D IH0 P L OY1"],
	"deportments": ["D AH0 P AO1 R T M AH0 N T S"],
	"depose": ["D IH0 P OW1 Z"],
	"deposed": ["D IH0 P OW1 Z D"],
	"deposit": ["D AH0 P AA1 Z IH0 T"],
	"deposition": ["D EH2 P AH0 Z IH1 SH AH0 N"],
	"deprive": ["D IH0 P R AY1 V"],
	"deprived": ["D IH0 P R AY1 V D"],
	"depth": ["D EH1 P TH"],
	"deputy": ["D EH1 P Y AH0 T IY3"],
	"deride": ["D IH0 R AY1 D"],
	"derision": ["D ER0 IH1 ZH AH0 N"],
	"derive": ["D ER0 AY1 V"],
	"desart": ["D EH1 Z ER0 T"],
	"descend": ["D IH0 S EH1 N D"],
	"descent": ["D IH0 S EH1 N T"],
	"describe": ["D IH0 S K R AY1 B"],
	"descried": ["D IH0 S K R AY1 D"],
	"descry": ["D EH0 S K R AY1"],
	"desert": ["D EH1 Z ER0 T", "D IH0 Z ER1 T"],
	"deserts": ["D EH1 Z ER0 T S", "D IH0 Z ER1 T S"],
	"deserve": ["D IH0 Z ER1 V"],
	"deserved": ["D IH0 Z ER1 V D"],
	"deserving": ["D IH0 Z ER1 V IH0 NG"],
	"design": ["D IH0 Z AY1 N"],
	"designate": ["D EH1 Z AH0 G N EY2 T"],
	"designated": ["D EH1 Z IH0 G N EY2 T IH0 D"],
	"desire": ["D IH0 Z AY1 R", "D IH0 Z AY1 ER0"],
	"desired": ["D IH0 Z AY1 R D", "D IH0 Z AY1 ER0 D"],
	"desires": ["D IH0 Z AY1 ER0 Z", "D IH0 Z AY1 R Z"],
	"desk": ["D EH1 S K"],
	"desolate": ["D EH1 S AH0 L AH2 T"],
	"desolation": ["D EH2 S AH0 L EY1 SH AH0 N"],
	"despair": ["D IH0 S P EH1 R"],
	"despatch": ["D EH0 S P AE1 CH"],
	"desperate": ["D EH1 S P R IH0 T"],
	"despis'd": ["D IH0 S P AY1 Z D"],
	"despise": ["D IH0 S P AY1 Z"],
	"despised": ["D IH0 S P AY1 Z D"],
	"despising": ["D IH0 S P AY1 Z IH0 NG"],
	"despite": ["D IH0 S P AY1 T"],
	"despoiled": ["D EH0 S P OY1 L D"],
	"despond": ["D IH0 S P AA1 N D"],
	"despot": ["D EH1 S P AH0 T"],
	"destined": ["D EH1 S T IH0 N D"],
	"destinies": ["D EH1 S T AH0 N IY2 Z"],
	"destiny": ["D EH1 S T AH0 N IY3"],
	"destitution": ["D EH1 S T AH0 T UW2 SH AH0 N"],
	"destroy": ["D IH0 S T R OY1"],
	"destruction": ["D IH0 S T R AH1 K SH AH0 N"],
	"detached": ["D IH0 T AE1 CH T"],
	"detachment": ["D IH0 T AE1 CH M AH0 N T"],
	"detain": ["D IH0 T EY1 N"],
	"detect": ["D IH0 T EH1 K T"],
	"detention": ["D IH0 T EH1 N SH AH0 N"],
	"deter": ["D IH0 T ER1"],
	"determinate": ["D IH0 T ER1 M AH0 N EY2 T"],
	"determination": ["D IH0 T ER2 M AH0 N EY1 SH AH0 N"],
	"determine": ["D IH0 T ER1 M AH0 N"],
	"deterred": ["D AH0 T ER1 D"],
	"detest": ["D IH0 T EH1 S T"],
	"develop": ["D IH0 V EH1 L AH0 P"],
	"develope": ["D IH0 V EH1 L AH0 P"],
	"development": ["D IH0 V EH1 L AH0 P M AH2 N T"],
	"device": ["D IH0 V AY1 S"],
	"devil": ["D EH1 V AH0 L"],
	"devilish": ["D EH1 V AH0 L IH3 SH", "D EH1 V L IH0 SH"],
	"devis'd": ["D IH0 V AY1 Z D"],
	"devise": ["D IH0 V AY1 Z"],
	"devised": ["D IH0 V AY1 Z D"],
	"devon": ["D EH1 V AH0 N"],
	"devote": ["D IH0 V OW1 T"],
	"devotee": ["D EH2 V AH0 T IY1"],
	"devotion": ["D IH0 V OW1 SH AH0 N"],
	"devour": ["D IH0 V AW1 ER0", "D IH0 V AW1 R"],
	"devout": ["D IH0 V AW1 T"],
	"dew": ["D Y UW1"],
	"dewy": ["D Y UW1 IY0"],
	"diadem": ["D AY1 AH0 D EH2 M"],
	"dial": ["D AY1 AH0 L", "D AY1 L"],
	"dialect": ["D AY1 AH0 L EH2 K T"],
	"dialogue": ["D AY1 AH0 L AA2 G"],
	"diameters": ["D AY0 AE1 M AH0 T ER3 Z"],
	"diamond": ["D AY1 M AH0 N D", "D AY1 AH0 M AH3 N D"],
	"dian's": ["D AY1 AH0 N Z"],
	"diana": ["D AY2 AE1 N AH0"],
	"diaphanous": ["D AY0 AE1 F AH0 N AH2 S"],
	"dice": ["D AY1 S"],
	"did": ["D IH1 D"],
	"didn't": ["D IH1 D AH0 N T"],
	"die": ["D AY1"],
	"died": ["D AY1 D"],
	"dieman's": ["D IY1 M AH0 N Z"],
	"diet": ["D AY1 AH0 T"],
	"differ": ["D IH1 F ER0"],
	"difference": ["D IH1 F ER0 AH3 N S", "D IH1 F R AH0 N S"],
	"different": ["D IH1 F ER0 AH3 N T", "D IH1 F R AH0 N T"],
	"differently": ["D IH1 F R AH0 N T L IY3", "D IH1 F ER0 AH0 N T L IY3"],
	"difficult": ["D IH1 F AH0 K AH3 L T"],
	"diffuse": ["D IH0 F Y UW1 S"],
	"diffused": ["D IH0 F Y UW1 Z D"],
	"dig": ["D IH1 G"],
	"digest": ["D AY0 JH EH1 S T"],
	"digestion": ["D AY0 JH EH1 S CH AH0 N"],
	"digger": ["D IH1 G ER0"],
	"dight": ["D AY1 T"],
	"dignified": ["D IH1 G N AH0 F AY2 D"],
	"dignity": ["D IH1 G N AH0 T IY3"],
	"dikes": ["D AY1 K S"],
	"dilapidation": ["D AH0 L AE2 P AH0 D EY1 SH AH0 N"],
	"dilated": ["D AY2 L EY1 T IH0 D"],
	"diligence": ["D IH1 L AH0 JH AH3 N S"],
	"diligent": ["D IH1 L IH0 JH AH3 N T"],
	"dilute": ["D AY0 L UW1 T"],
	"dim": ["D IH1 M"],
	"diminished": ["D AH0 M IH1 N IH0 SH T"],
	"diminution": ["D IH2 M AH0 N UW1 SH AH0 N"],
	"dimities": ["D IH1 M AH0 T IY3 Z"],
	"dimity": ["D IH1 M AH0 T IY2"],
	"dimm'd": ["D IH1 M D"],
	"dimmer": ["D IH1 M ER0"],
	"dimple": ["D IH1 M P AH0 L"],
	"din": ["D IH1 N"],
	"dine": ["D AY1 N"],
	"dingle": ["D IH1 NG G AH0 L"],
	"dining": ["D AY1 N IH0 NG"],
	"dinna": ["D IH1 N AH0"],
	"dinner": ["D IH1 N ER0"],
	"diomede": ["D AY1 AH0 M EH2 D"],
	"dip": ["D IH1 P"],
	"dipped": ["D IH1 P T"],
	"dipping": ["D IH1 P IH0 NG"],
	"dire": ["D AY1 R", "D AY1 ER0"],
	"direct": ["D IH0 R EH1 K T"],
	"directed": ["D ER0 EH1 K T AH0 D"],
	"director": ["D ER0 EH1 K T ER0"],
	"directs": ["D ER0 EH1 K T S"],
	"direful": ["D AY1 R F UH0 L"],
	"dirk": ["D ER1 K"],
	"dirt": ["D ER1 T"],
	"dirty": ["D ER1 T IY0"],
	"disabled": ["D IH0 S EY1 B AH0 L D"],
	"disallow": ["D IH2 S AH0 L AW1"],
	"disappear": ["D IH2 S AH0 P IY1 R"],
	"disappoint": ["D IH2 S AH0 P OY1 N T"],
	"disarm": ["D IH0 S AA1 R M"],
	"disaster": ["D IH0 Z AE1 S T ER0"],
	"disband": ["D IH0 S B AE1 N D"],
	"disburse": ["D IH0 S B ER1 S"],
	"disc": ["D IH1 S K"],
	"discard": ["D IH0 S K AA1 R D", "D IH1 S K AA2 R D"],
	"discarded": ["D IH0 S K AA1 R D IH0 D"],
	"discern": ["D IH0 S ER1 N"],
	"discern\u00e8d": ["D IH0 S ER1 N IH0 D"],
	"discipline": ["D IH1 S AH0 P L AH3 N"],
	"disclaim": ["D IH0 S K L EY1 M"],
	"disclose": ["D IH0 S K L OW1 Z"],
	"disclosed": ["D IH0 S K L OW1 Z D"],
	"discloser": ["D IH0 S K L OW1 Z ER0"],
	"disclosing": ["D IH2 S K L OW1 Z IH0 NG"],
	"disclosure": ["D IH0 S K L OW1 ZH ER0"],
	"discomfit": ["D IH3 S K AH1 M F IH0 T"],
	"discompose": ["D IH2 S K AH0 M P OW1 Z"],
	"disconsolate": ["D IH0 S K AA1 N S AH0 L AH0 T"],
	"discontent": ["D IH2 S K AH0 N T EH1 N T"],
	"discount": ["D IH0 S K AW1 N T", "D IH1 S K AW0 N T"],
	"discourage": ["D IH2 S K ER1 AH0 JH"],
	"discourse": ["D IH1 S K AO0 R S"],
	"discover": ["D IH0 S K AH1 V ER0"],
	"discovery": ["D IH0 S K AH1 V ER0 IY3", "D IH0 S K AH1 V R IY0"],
	"discreetness": ["D IH0 S K R IY1 T N AH0 S"],
	"discretion": ["D IH0 S K R EH1 SH AH0 N"],
	"discuss": ["D IH0 S K AH1 S"],
	"disdain": ["D IH0 S D EY1 N"],
	"disease": ["D IH0 Z IY1 Z"],
	"diseased": ["D IH0 Z IY1 Z D"],
	"disembodies": ["D IH2 S EH0 M B AA1 D IY0 Z"],
	"disenthral": ["D IH2 S IH0 N TH R AO1 L"],
	"disenthralled": ["D IH0 S IH0 N TH R AO1 L D"],
	"disentwine": ["D IH2 S EH0 N T W AY1 N"],
	"disfigured": ["D IH2 S F IH1 G Y UH0 R D"],
	"disgrac'd": ["D IH0 S G R EY1 S T"],
	"disgrace": ["D IH0 S G R EY1 S"],
	"disguise": ["D IH0 S G AY1 Z"],
	"disguised": ["D IH0 S G AY1 Z D"],
	"disgust": ["D IH0 S G AH1 S T"],
	"dish": ["D IH1 SH"],
	"dishonor": ["D IH0 S AA1 N ER0"],
	"dishonour": ["D IH0 S AA1 N ER0"],
	"disintegrated": ["D IH0 S IH1 N T AH0 G R EY2 T AH0 D"],
	"dismal": ["D IH1 Z M AH0 L"],
	"dismay": ["D IH0 S M EY1"],
	"dismiss": ["D IH0 S M IH1 S"],
	"disobedience": ["D IH2 S OW0 B IY1 D Y AH0 N S", "D IH2 S OW0 B IY1 D IY0 AH3 N S"],
	"disobey": ["D IH2 S AH0 B EY1"],
	"disorder": ["D IH0 S AO1 R D ER0"],
	"disparage": ["D IH0 S P EH1 R IH0 JH"],
	"dispart": ["D IH0 S P AA1 R T"],
	"dispatch": ["D IH0 S P AE1 CH"],
	"dispel": ["D IH0 S P EH1 L"],
	"dispelled": ["D IH0 S P EH1 L D"],
	"dispense": ["D IH0 S P EH1 N S"],
	"dispensed": ["D IH0 S P EH1 N S T"],
	"disperse": ["D IH0 S P ER1 S"],
	"display": ["D IH0 S P L EY1", "D IH1 S P L EY0"],
	"dispose": ["D IH0 S P OW1 Z"],
	"disposed": ["D IH0 S P OW1 Z D"],
	"disposers": ["D IH0 S P OW1 Z ER0 Z"],
	"disposing": ["D IH0 S P OW1 Z IH0 NG"],
	"dispread": ["D IH0 S P R EH1 D"],
	"dispute": ["D IH0 S P Y UW1 T"],
	"dissect": ["D AY2 S EH1 K T", "D IH0 S EH1 K T"],
	"dissection": ["D AY0 S EH1 K SH AH0 N"],
	"dissembling": ["D IH2 S EH1 M B L IH0 NG"],
	"disseminating": ["D AH0 S EH1 M AH0 N EY2 T"],
	"dissent": ["D IH0 S EH1 N T"],
	"dissheath": ["D IH0 S SH IY1 DH"],
	"dissipation": ["D IH2 S IH0 P EY1 SH AH0 N"],
	"dissolution": ["D IH2 S AH0 L UW1 SH AH0 N"],
	"dissolve": ["D IH0 Z AA1 L V"],
	"dissolved": ["D IH0 Z AA1 L V D"],
	"dissolving": ["D IH0 Z AA1 L V IH0 NG"],
	"dissuade": ["D IH0 S W EY1 D"],
	"distain": ["D IH0 S D EY1 N"],
	"distance": ["D IH1 S T AH0 N S"],
	"distant": ["D IH1 S T AH0 N T"],
	"distil": ["D IH0 S T IH1 L"],
	"distill": ["D IH0 S T IH1 L"],
	"distill'd": ["D IH0 S T IH1 L D"],
	"distilled": ["D IH0 S T IH1 L D"],
	"distinct": ["D IH0 S T IH1 NG K T"],
	"distinguish": ["D IH0 S T IH1 NG G W IH0 SH"],
	"distort": ["D IH0 S T AO1 R T"],
	"distract": ["D IH0 S T R AE1 K T"],
	"distraction": ["D IH0 S T R AE1 K SH AH0 N"],
	"distrain": ["D IH0 S T R EY1 N"],
	"distraught": ["D IH0 S T R AO1 T"],
	"distress": ["D IH0 S T R EH1 S"],
	"distrest": ["D IH0 S T R EH1 S T"],
	"distribution": ["D IH2 S T R AH0 B Y UW1 SH AH0 N"],
	"distrusting": ["D IH2 S T R AH1 S T IH0 NG"],
	"disturb": ["D IH0 S T ER1 B"],
	"ditch": ["D IH1 CH"],
	"ditcher": ["D IH1 CH AH0"],
	"ditties": ["D IH1 T IY0 Z"],
	"ditty": ["D IH1 T IY0"],
	"dive": ["D AY1 V"],
	"diver": ["D AY1 V ER0"],
	"diver's": ["D AY1 V ER0 Z"],
	"diverging": ["D AY0 V ER1 JH IH0 NG"],
	"divers": ["D AY1 V ER0 S"],
	"diverse": ["D IH0 V ER1 S"],
	"diversified": ["D IH0 V ER1 S AH0 F AY2 D"],
	"diversify": ["D AY0 V ER1 S AH0 F AY2"],
	"divert": ["D IH0 V ER1 T"],
	"dives": ["D AY1 V Z"],
	"divest": ["D AY0 V EH1 S T"],
	"divide": ["D IH0 V AY1 D"],
	"divided": ["D IH0 V AY1 D IH0 D"],
	"dividend": ["D IH1 V IH0 D EH2 N D"],
	"divine": ["D IH0 V AY1 N"],
	"diviner": ["D IH0 V AY1 N ER0"],
	"divinest": ["D IH0 V AY1 N AH0 S T"],
	"diving": ["D AY1 V IH0 NG"],
	"divining": ["D AH0 V AY1 N IH0 NG"],
	"divinity": ["D IH0 V IH1 N AH0 T IY3"],
	"division": ["D AH0 V IH1 ZH AH0 N"],
	"divorce": ["D IH0 V AO1 R S"],
	"divorced": ["D IH0 V AO1 R S T"],
	"dizzy": ["D IH1 Z IY0"],
	"do": ["D UW1"],
	"doat": ["D OW1 T"],
	"docile": ["D AA1 S AH0 L"],
	"doctor": ["D AA1 K T ER0"],
	"doctors": ["D AA1 K T ER0 Z"],
	"dodge": ["D AA1 JH"],
	"doe": ["D OW1"],
	"doe's": ["D OW1 Z"],
	"does": ["D AH1 Z"],
	"doesn't": ["D AH1 Z AH0 N T"],
	"doff": ["D AO1 F"],
	"dog": ["D AO1 G"],
	"doge": ["D OW1 JH"],
	"doing": ["D UW1 IH0 NG"],
	"dole": ["D OW1 L"],
	"doll": ["D AA1 L"],
	"dollar": ["D AA1 L ER0"],
	"dollie": ["D AA1 L IY0"],
	"domain": ["D OW0 M EY1 N"],
	"dome": ["D OW1 M"],
	"domination": ["D AA2 M AH0 N EY1 SH AH0 N"],
	"domingo": ["D AH0 M IH1 NG G OW0"],
	"dominie": ["D AA1 M IH0 N IY2"],
	"dominion": ["D AH0 M IH1 N Y AH0 N"],
	"don": ["D AA1 N"],
	"don't": ["D OW1 N T"],
	"done": ["D AH1 N"],
	"donna": ["D AA1 N AH0"],
	"donor": ["D OW1 N ER0"],
	"doom": ["D UW1 M"],
	"door": ["D AO1 R"],
	"doorways": ["D AO1 R W EY2 Z"],
	"dormant": ["D AO1 R M AH0 N T"],
	"dose": ["D OW1 S"],
	"dost": ["D AA1 S T"],
	"dot": ["D AA1 T"],
	"dote": ["D OW1 T"],
	"doted": ["D OW1 T AH0 D"],
	"doth": ["D AO1 TH"],
	"doting": ["D OW1 T IH0 NG"],
	"dotted": ["D AA1 T IH0 D"],
	"double": ["D AH1 B AH0 L"],
	"doublet": ["D AH1 B L IH0 T"],
	"doubly": ["D AH1 B L IY0"],
	"doubt": ["D AW1 T"],
	"doubtful": ["D AW1 T F AH0 L"],
	"dove": ["D AH1 V", "D OW1 V"],
	"dovelike": ["D AH1 V L AY0 K"],
	"dower": ["D AW1 ER0", "D AW1 R"],
	"dower's": ["D AW1 R Z", "D AW1 ER0 Z"],
	"dowered": ["D AW1 R D", "D AW1 ER0 D"],
	"dowerless": ["D AW1 R L AH0 S", "D AW1 ER0 L AH3 S"],
	"down": ["D AW1 N"],
	"downiest": ["D AW1 N IY0 IH0 S T", "D AW1 N Y IH0 S T"],
	"downtown": ["D AW2 N T AW1 N"],
	"dowry": ["D AW1 R IY0"],
	"doze": ["D OW1 Z"],
	"dozed": ["D OW1 Z D"],
	"dozen": ["D AH1 Z AH0 N"],
	"dr": ["D AA1 K T ER0"],
	"drab": ["D R AE1 B"],
	"drag": ["D R AE1 G"],
	"dragged": ["D R AE1 G D"],
	"dragnet": ["D R AE1 G N EH0 T"],
	"dragon": ["D R AE1 G AH0 N"],
	"drain": ["D R EY1 N"],
	"dram": ["D R AE1 M"],
	"drama": ["D R AA1 M AH0"],
	"drank": ["D R AE1 NG K"],
	"draughts": ["D R AE1 F T S"],
	"drave": ["D R EY1 V"],
	"draw": ["D R AO1"],
	"drawer": ["D R AO1 R"],
	"drawn": ["D R AO1 N"],
	"dread": ["D R EH1 D"],
	"dreadful": ["D R EH1 D F AH0 L"],
	"dream": ["D R IY1 M"],
	"dreamy": ["D R IY1 M IY0"],
	"drear": ["D R IY1 R"],
	"dreary": ["D R IY1 R IY0"],
	"dreg": ["D R EH1 G"],
	"drench": ["D R EH1 N CH"],
	"dress": ["D R EH1 S"],
	"drest": ["D R EH1 S T"],
	"drew": ["D R UW1"],
	"dried": ["D R AY1 D"],
	"drift": ["D R IH1 F T"],
	"drill": ["D R IH1 L"],
	"drink": ["D R IH1 NG K"],
	"drip": ["D R IH1 P"],
	"dripping": ["D R IH1 P IH0 NG"],
	"driv'n": ["D R IH1 V N", "D R IH1 V AH0 N"],
	"drive": ["D R AY1 V"],
	"driven": ["D R IH1 V AH0 N", "D R IH1 V N"],
	"driver": ["D R AY1 V ER0"],
	"drizzle": ["D R IH1 Z AH0 L"],
	"droll": ["D R OW1 L"],
	"droop": ["D R UW1 P"],
	"droopingly": ["D R UW1 P IH0 NG L IY3"],
	"drop": ["D R AA1 P"],
	"dropped": ["D R AA1 P T"],
	"dropping": ["D R AA1 P IH0 NG"],
	"dropt": ["D R AA1 P T"],
	"dross": ["D R AO1 S"],
	"drought": ["D R AW1 T"],
	"drouth": ["D R UW1 TH"],
	"drouthy": ["D R UW1 TH IY0"],
	"drove": ["D R OW1 V"],
	"drown": ["D R AW1 N"],
	"drowse": ["D R AW1 S"],
	"drowsiest": ["D R AW1 S IY1 S T"],
	"drowsiness": ["D R AW1 Z IY0 N AH3 S"],
	"drowsing": ["D R AW1 Z IH0 NG"],
	"drowsy": ["D R AW1 Z IY0"],
	"drug": ["D R AH1 G"],
	"druid": ["D R UW1 IH0 D"],
	"drum": ["D R AH1 M"],
	"drummer": ["D R AH1 M ER0"],
	"drunk": ["D R AH1 NG K"],
	"drunkard": ["D R AH1 NG K ER0 D"],
	"drunken": ["D R AH1 NG K AH0 N"],
	"drunkenness": ["D R AH1 NG K AH0 N N AH2 S"],
	"dry": ["D R AY1"],
	"dryad": ["D R AY1 AE2 D"],
	"dryope": ["D R AY1 AH0 P IY3"],
	"ducal": ["D UW1 K AH0 L"],
	"ducat": ["D AH1 K AH0 T"],
	"duchess": ["D AH1 CH AH0 S"],
	"duck": ["D AH1 K"],
	"dud": ["D AH1 D"],
	"due": ["D UW1"],
	"dug": ["D AH1 G"],
	"duke": ["D UW1 K"],
	"dull": ["D AH1 L"],
	"dulness": ["D AH1 L N AH0 S"],
	"duly": ["D UW1 L IY0"],
	"dumb": ["D AH1 M"],
	"dumourier": ["D AH0 M Y ER1 IH0 ER0"],
	"dump": ["D AH1 M P"],
	"dun": ["D AH1 N"],
	"duncan": ["D AH1 NG K AH0 N"],
	"dunce": ["D AH1 N S"],
	"dungeon": ["D AH1 N JH AH0 N"],
	"duplicate": ["D UW1 P L AH0 K AH3 T", "D UW1 P L AH0 K EY2 T"],
	"durabler": ["D ER1 AH0 B L ER3"],
	"during": ["D UH1 R IH0 NG"],
	"dusk": ["D AH1 S K"],
	"dusky": ["D AH1 S K IY0"],
	"dust": ["D AH1 S T"],
	"dustiest": ["D AH1 S T Y AH0 S T"],
	"dusty": ["D AH1 S T IY0"],
	"duties": ["D UW1 T IY0 Z"],
	"duty": ["D UW1 T IY0"],
	"dwarf": ["D W AO1 R F"],
	"dwell": ["D W EH1 L"],
	"dwelt": ["D W EH1 L T"],
	"dwindled": ["D W IH1 N D AH0 L D"],
	"dy'd": ["D AY1 D"],
	"dye": ["D AY1"],
	"dying": ["D AY1 IH0 NG"],
	"dyke": ["D AY1 K"],
	"dynasties": ["D AY1 N AH0 S T IY2 Z"],
	"dynasty": ["D AY1 N AH0 S T IY3"],
	"e'en": ["IY1 N"],
	"e'er": ["IY1 R"],
	"each": ["IY1 CH"],
	"eager": ["IY1 G ER0"],
	"eagle": ["IY1 G AH0 L"],
	"ear": ["IY1 R"],
	"earl": ["ER1 L"],
	"earldom": ["ER1 L D AH0 M"],
	"earlier": ["ER1 L IY0 ER0", "ER1 L Y ER0"],
	"earliest": ["ER1 L IY0 AH3 S T", "ER1 L Y AH0 S T"],
	"early": ["ER1 L IY0"],
	"earn": ["ER1 N"],
	"earnestly": ["ER1 N AH0 S T L IY3"],
	"earring": ["IY1 R IH0 NG"],
	"earth": ["ER1 TH"],
	"earthen": ["ER1 TH AH0 N"],
	"earthquake": ["ER1 TH K W EY2 K"],
	"ease": ["IY1 Z"],
	"eased": ["IY1 Z D"],
	"easel": ["IY1 Z AH0 L"],
	"easeful": ["IY1 Z F AH0 L"],
	"easier": ["IY1 Z IY0 ER0"],
	"easiest": ["IY1 Z IY0 IH3 S T", "IY1 Z Y IH0 S T"],
	"easily": ["IY1 Z AH0 L IY3"],
	"easing": ["IY1 Z IH0 NG"],
	"east": ["IY1 S T"],
	"eastern": ["IY1 S T ER0 N"],
	"eastward": ["IY1 S T W ER0 D"],
	"easy": ["IY1 Z IY0"],
	"eat": ["IY1 T"],
	"eaves": ["IY1 V Z"],
	"ebb": ["EH1 B"],
	"ebbing": ["EH1 B IH0 NG"],
	"ebon": ["EH1 B AH0 N"],
	"eccentric": ["IH0 K S EH1 N T R IH0 K"],
	"eccentricity": ["EH2 K S AH0 N T R IH1 S AH0 T IY3"],
	"ecelin": ["EH1 S AH0 L IH2 N"],
	"echo": ["EH1 K OW0"],
	"echoes": ["EH1 K OW0 Z"],
	"eclipse": ["IH0 K L IH1 P S"],
	"economical": ["EH2 K AH0 N AA1 M IH0 K AH3 L"],
	"economy": ["IH0 K AA1 N AH0 M IY3"],
	"ecstasy": ["EH1 K S T AH0 S IY3"],
	"ecstatic": ["IH0 K S T AE1 T IH0 K"],
	"eddied": ["EH1 D IY0 D"],
	"eden": ["IY1 D AH0 N"],
	"edge": ["EH1 JH"],
	"edged": ["EH1 JH D"],
	"edgeworth": ["EH1 JH W ER0 TH"],
	"edifice": ["EH1 D AH0 F AH0 S"],
	"edition": ["EH0 D IH1 SH AH0 N"],
	"editor": ["EH1 D AH0 T ER3", "EH1 D IH0 T ER0"],
	"education": ["EH2 JH AH0 K EY1 SH AH0 N"],
	"edward": ["EH1 D W ER0 D"],
	"efface": ["IH0 F EY1 S"],
	"effacing": ["IH0 F EY1 S IH0 NG"],
	"effect": ["IH0 F EH1 K T"],
	"effectively": ["IH0 F EH1 K T IH0 V L IY3"],
	"efflorescence": ["EH2 F L AO0 R EH1 S AH0 N S"],
	"effort": ["EH1 F ER0 T"],
	"effrontery": ["IH0 F R AH1 N T ER0 IY3"],
	"egg": ["EH1 G"],
	"eginhard": ["EH1 G IH0 N HH AA2 R D"],
	"eglamor": ["EH1 G L AH0 M AO2 R"],
	"eglantine": ["EH1 G L AH0 N T AY2 N"],
	"egypt": ["IY1 JH AH0 P T"],
	"egyptian": ["IH0 JH IH1 P SH AH0 N"],
	"eider": ["AY1 D ER0"],
	"eied": ["AY1 D"],
	"eight": ["EY1 T"],
	"either": ["IY1 DH ER0", "AY1 DH ER0"],
	"eking": ["IY1 K IH0 NG"],
	"elastic": ["IH0 L AE1 S T IH0 K"],
	"elasticity": ["IY2 L AE0 S T IH1 S AH0 T IY2"],
	"elate": ["IH0 L EY1 T"],
	"elbow": ["EH1 L B OW2"],
	"eld": ["EH1 L D"],
	"eleanor": ["EH1 L AH0 N AO2 R"],
	"elect": ["IH0 L EH1 K T"],
	"election": ["IH0 L EH1 K SH AH0 N"],
	"electra": ["IH0 L EH1 K T R AH0"],
	"electra's": ["IH0 L EH1 K T R AH0 Z"],
	"electric": ["IH0 L EH1 K T R IH0 K"],
	"elegant": ["EH1 L AH0 G AH3 N T"],
	"elegy": ["EH1 L AH0 JH IY2"],
	"element": ["EH1 L AH0 M AH2 N T"],
	"elemental": ["EH2 L AH0 M EH1 N T AH0 L"],
	"elephant": ["EH1 L AH0 F AH0 N T"],
	"eleven": ["IH0 L EH1 V AH0 N"],
	"elf": ["EH1 L F"],
	"elfin": ["EH1 L F IH0 N"],
	"eli": ["IY1 L AY0"],
	"eligible": ["EH1 L AH0 JH AH0 B AH2 L"],
	"eligibly": ["EH1 L AH0 JH AH0 B L IY3", "EH1 L AH0 G AH3 B L IY0"],
	"elijah": ["EH0 L AY1 JH AH0"],
	"elisha": ["AH0 L AY1 SH AH0"],
	"ellipse": ["IH0 L IH1 P S"],
	"elm": ["EH1 L M"],
	"eloquence": ["EH1 L AH0 K W EH2 N S"],
	"eloquent": ["EH1 L AH0 K W AH3 N T"],
	"els": ["EH1 L Z"],
	"else": ["EH1 L S"],
	"elsewhere": ["EH1 L S W EH2 R"],
	"elude": ["IH0 L UW1 D"],
	"elves": ["EH1 L V Z"],
	"elysian": ["IH0 L IH1 Z IH0 AH0 N"],
	"elysium": ["IH0 L IH1 Z IY0 AH0 M"],
	"em": ["EH1 M"],
	"emaciate": ["IH0 M EY1 SH IY0 EY2 T"],
	"embalm": ["EH0 M B AA1 M"],
	"embark": ["EH0 M B AA1 R K"],
	"embarrass": ["IH0 M B AE1 R AH0 S"],
	"embarrassment": ["IH0 M B EH1 R AH0 S M AH2 N T"],
	"embassage": ["EH1 M B AH0 S IH2 JH"],
	"embassy": ["EH1 M B AH0 S IY3"],
	"embellish": ["IH0 M B EH1 L IH0 SH"],
	"ember": ["EH1 M B ER0"],
	"emblem": ["EH1 M B L AH0 M"],
	"embodied": ["IH0 M B AA1 D IY0 D"],
	"embossed": ["IH0 M B AO1 S T"],
	"embower": ["EH0 M B AW1 ER0"],
	"embrac'd": ["EH0 M B R EY1 S T"],
	"embrace": ["EH0 M B R EY1 S"],
	"embraced": ["EH0 M B R EY1 S T"],
	"embroider": ["EH0 M B R OY1 D ER0"],
	"embrued": ["IH0 M B R UW1 D"],
	"embued": ["IH0 M B Y UW1 D"],
	"emerald": ["EH1 M R AH0 L D", "EH1 M ER0 AH3 L D"],
	"emeralds": ["EH1 M R AH0 L D Z", "EH1 M ER0 AH3 L D Z"],
	"emerge": ["IH0 M ER1 JH"],
	"emergency": ["IH0 M ER1 JH AH0 N S IY3"],
	"emetic": ["IH0 M EH1 T IH0 K"],
	"emigrant": ["EH1 M AH0 G R AH3 N T"],
	"emigrant's": ["EH1 M AH0 G R AH3 N T S"],
	"emigrate": ["EH1 M AH0 G R EY2 T"],
	"eminence": ["EH1 M AH0 N AH2 N S", "EH1 M N AH0 N S"],
	"eminent": ["EH1 M AH0 N AH3 N T","EH1 M N AH0 N T"],
	"emit": ["IH0 M IH1 T"],
	"emotion": ["IH0 M OW1 SH AH0 N"],
	"emperor": ["EH1 M P ER0 ER0"],
	"empery": ["EH1 M P ER0 IY2"],
	"emphatic": ["EH0 M F AE1 T IH0 K"],
	"empire": ["EH1 M P AY0 R"],
	"employ": ["EH0 M P L OY1"],
	"emptied": ["EH1 M P T IY0 D"],
	"emptiness": ["EH1 M P T IY0 N AH2 S"],
	"empty": ["EH1 M P T IY0"],
	"emulate": ["EH1 M Y AH0 L EY2 T"],
	"enable": ["EH0 N EY1 B AH0 L"],
	"enabling": ["EH0 N EY1 B AH0 L IH3 NG", "EH0 N EY1 B AH0 L IH3 NG"],
	"enact": ["IH0 N AE1 K T"],
	"enamel": ["IH0 N AE1 M AH0 L"],
	"enamelled": ["IH0 N AE1 M AH0 L D"],
	"enamor": ["EH0 N AE1 M ER0"],
	"enamour": ["EH0 N AE1 M ER0"],
	"encamp": ["IH0 N K AE1 M P"],
	"enchant": ["EH0 N CH AE1 N T"],
	"enchantment": ["EH0 N CH AE1 N T M AH0 N T"],
	"enclasp\u00ebd": ["EH0 N K L AE1 S P T"],
	"enclose": ["IH0 N K L OW1 Z"],
	"encore": ["AA1 N K AO2 R"],
	"encounter": ["IH0 N K AW1 N T ER0"],
	"encourage": ["EH0 N K ER1 IH0 JH"],
	"encouragement": ["EH0 N K ER1 IH0 JH M AH2 N T"],
	"encroach": ["IH0 N K R OW1 CH"],
	"encumber": ["EH0 N K AH1 M B ER0"],
	"end": ["EH1 N D"],
	"endear": ["EH0 N D IY1 R"],
	"endeavor": ["IH0 N D EH1 V ER0"],
	"endeavoring": ["IH0 N D EH1 V ER0 IH3 NG", "IH0 N D EH1 V R IH0 NG"],
	"endorse": ["EH0 N D AO1 R S"],
	"endorsement": ["EH0 N D AO1 R S M AH0 N T"],
	"endow": ["EH0 N D AW1"],
	"endowal": ["EH0 N D AW1 AH0 L"],
	"endued": ["EH0 N D UW1 D"],
	"endur'd": ["EH0 N D Y UH1 R D"],
	"endure": ["EH0 N D Y UH1 R"],
	"endured": ["EH0 N D Y UH1 R D", "IH0 N D UH1 R D"],
	"endymion": ["EH0 N D IH1 M IH0 AH2 N"],
	"enemies": ["EH1 N AH0 M IY3 Z"],
	"enemy": ["EH1 N AH0 M IY3"],
	"energies": ["EH1 N ER0 JH IY2 Z"],
	"enfeeble": ["EH2 N F IY1 B AH0 L"],
	"enfold": ["IH0 N F OW1 L D"],
	"enforce": ["EH0 N F AO1 R S"],
	"enfranchised": ["EH0 N F R AE1 N CH AY2 Z D"],
	"engage": ["EH0 N G EY1 JH"],
	"engaged": ["EH0 N G EY1 JH D"],
	"engine": ["EH1 N JH AH0 N"],
	"england": ["EH1 NG G L AH0 N D"],
	"english": ["IH1 NG G L IH0 SH"],
	"engraved": ["IH0 N G R EY1 V D"],
	"engross": ["IH0 N G R OW1 S"],
	"enhance": ["EH0 N HH AE1 N S"],
	"enjoin": ["EH2 N JH OY1 N"],
	"enjoy": ["EH2 N JH OY1"],
	"enlarg'd": ["IH0 N L AA1 R JH D"],
	"enlarge": ["EH2 N L AA1 R JH"],
	"enlarged": ["EH2 N L AA1 R JH D"],
	"enlighten": ["EH2 N L AY1 T AH0 N"],
	"enlist": ["EH2 N L IH1 S T"],
	"enmity": ["EH1 N M IH0 T IY3"],
	"eno": ["IH0 N AH1 F"],
	"eno'": ["IH0 N AH1 F"],
	"enormous": ["IH0 N AO1 R M AH0 S"],
	"enough": ["IH0 N AH1 F"],
	"enow": ["IH0 N AW1"],
	"enquire": ["IH0 N K W AY1 ER0"],
	"enrich": ["EH0 N R IH1 CH"],
	"enrobe": ["IH0 N R OW1 B"],
	"enrol": ["IH0 N R OW1 L"],
	"enroll": ["IH0 N R OW1 L"],
	"enrough": ["EH0 N R AH1 F"],
	"enshaded": ["EH0 N SH EY1 D IH0 D"],
	"enshrine": ["EH0 N SH R AY1 N"],
	"enshrined": ["EH0 N SH R AY1 N D"],
	"ensigns": ["EH1 N S AY0 N S"],
	"enslave": ["IH0 N S L EY1 V"],
	"ensue": ["IH0 N S UW1"],
	"ensued": ["IH0 N S UW1 D"],
	"ensured": ["EH0 N SH UH1 R D"],
	"entail": ["EH2 N T EY1 L"],
	"enter": ["EH1 N T ER0"],
	"entering": ["EH1 N T ER0 IH3 NG", "EH1 N T R IH0 NG"],
	"enterprise": ["EH1 N T ER0 P R AY2 Z"],
	"entertain": ["EH2 N T ER0 T EY1 N"],
	"enthrall": ["EH0 N TH R AO1 L"],
	"enthusiasm": ["IH0 N TH UW1 Z IY0 AE2 Z AH0 M"],
	"entice": ["IH0 N T AY1 S"],
	"enticed": ["IH0 N T AY1 S T"],
	"entire": ["IH0 N T AY1 R", "IH0 N T AY1 ER0"],
	"entirely": ["IH0 N T AY1 ER0 L IY3", "IH0 N T AY1 R L IY0"],
	"entitle": ["EH0 N T AY1 T AH0 L"],
	"entitled": ["EH0 N T AY1 T AH0 L D"],
	"entomb": ["EH0 N T UW1 M"],
	"entrail": ["EH1 N T R AH0 L"],
	"entrance": ["EH1 N T R AH0 N S"],
	"entreat": ["IH0 N T R IY1 T"],
	"entrust": ["EH2 N T R AH1 S T"],
	"entry": ["EH1 N T R IY0"],
	"entwine": ["EH0 N T W AY1 N"],
	"entwined": ["EH0 N T W AY1 N D"],
	"envelope": ["EH1 N V AH0 L OW2 P", "EH0 N V EH1 L AH0 P"],
	"envelopes": ["EH1 N V AH0 L OW2 P S", "EH0 N V EH1 L AH0 P S"],
	"envied": ["EH1 N V IY0 D"],
	"envoy": ["AA1 N V OY0"],
	"envy": ["EH1 N V IY0"],
	"enwrapped": ["IH0 N R AE1 P T"],
	"epauletted": ["EH2 P AA0 L EH1 T IH0 D"],
	"epicure": ["EH1 P IH0 K Y UH2 R"],
	"epicurus": ["EH2 P IH0 K Y ER1 AH0 S"],
	"epigea": ["EH2 P IH0 JH IY1 AH0"],
	"epigraph": ["EH1 P AH0 G R AE2 F"],
	"episode": ["EH1 P AH0 S OW2 D"],
	"epitaph": ["EH1 P AH0 T AE2 F"],
	"epitome": ["IH0 P IH1 T AH0 M IY3"],
	"equal": ["IY1 K W AH0 L"],
	"equall": ["IY1 K W AH0 L"],
	"equation": ["IH0 K W EY1 ZH AH0 N"],
	"equator": ["IH0 K W EY1 T ER0"],
	"equip": ["IH0 K W IH1 P"],
	"equipage": ["EH1 K W AH0 P IH0 JH"],
	"equity": ["EH1 K W AH0 T IY3"],
	"erasing": ["IH0 R EY1 S IH0 NG"],
	"ere": ["EH1 R"],
	"erebus": ["EH1 R AH0 B AH2 S"],
	"erect": ["IH0 R EH1 K T"],
	"erewhile": ["EH0 R W AY1 L"],
	"ermine": ["ER1 M AH0 N"],
	"err": ["EH1 R"],
	"errand": ["EH1 R AH0 N D"],
	"error": ["EH1 R ER0"],
	"erst": ["ER1 S T"],
	"erudite": ["EH1 R AH0 D AY2 T"],
	"escapade": ["EH1 S K AH0 P EY2 D"],
	"escape": ["IH0 S K EY1 P"],
	"escaped": ["IH0 S K EY1 P T"],
	"escaping": ["IH0 S K EY1 P IH0 NG"],
	"escort": ["EH0 S K AO1 R T"],
	"escutscheon": ["EH0 S K AH1 T CH AH0 N"],
	"espied": ["IH0 S P AY1 D"],
	"espies": ["IH0 S P AY1 Z"],
	"espoused": ["IH0 S P AW1 Z D"],
	"espy": ["IH0 S P AY1"],
	"essay": ["EH0 S EY1", "EH1 S EY0"],
	"essayed": ["EH1 S EY0 D"],
	"essence": ["EH1 S AH0 N S"],
	"essential": ["EH0 S EH1 N SH AH0 L"],
	"establish": ["IH0 S T AE1 B L IH0 SH"],
	"estate": ["IH0 S T EY1 T"],
	"este": ["EH1 S T EY0"],
	"esteem": ["AH0 S T IY1 M"],
	"estian": ["EH1 S T Y AH0 N"],
	"estimable": ["EH1 S T AH0 M AH0 B AH0 L"],
	"estimate": ["EH1 S T AH0 M AH0 T", "EH1 S T AH0 M EY2 T"],
	"estimates": ["EH1 S T AH0 M AH0 T S", "EH1 S T AH0 M EY2 T S"],
	"estimating": ["EH1 S T AH0 M EY2 T IH0 NG"],
	"estrange": ["EH0 S T R EY1 N JH"],
	"etchings": ["EH1 CH IH0 NG Z"],
	"eternal": ["IH0 T ER1 N AH0 L"],
	"eternally": ["IH0 T ER1 N AH0 L IY3"],
	"eterne": ["IH0 T ER1 N"],
	"eternities": ["IH0 T ER1 N IH0 T IY3 S"],
	"eternity": ["IH0 T ER1 N AH0 T IY3"],
	"ether": ["IY1 TH ER0"],
	"ethereal": ["IH0 TH IY1 R IY0 AH0 L"],
	"ethereality": ["IH0 TH IH2 R IH0 AE1 L IH0 T IY2"],
	"etherial": ["IH0 TH IY1 R Y AH0 L"],
	"ethiop": ["IY1 TH IY0 OW2 P"],
	"etruscan": ["IH0 T R AH1 S K AH0 N"],
	"eucharist": ["Y UW1 K ER0 AH0 S T"],
	"eufemia's": ["Y UW0 F IY1 M Y AH0 Z"],
	"ev'ry": ["EH1 V R IY0"],
	"evades": ["IH0 V EY1 D Z"],
	"evangelist": ["IH0 V AE1 N JH AH0 L IH2 S T"],
	"eve": ["IY1 V"],
	"even": ["IY1 V IH0 N", "IY1 N"],
	"evening": ["IY1 V N IH0 NG"],
	"event": ["IH0 V EH1 N T"],
	"eventide": ["IY1 V AH0 N T AY2 D"],
	"eventual": ["IH0 V EH1 N CH UW0 AH3 L", "IH0 V EH1 N CH W AH0 L"],
	"eventually": ["IH0 V EH1 N CH AH0 W AH0 L IY3", "IH0 V EH1 N SH AH0 L IY3"],
	"ever": ["EH1 V ER0"],
	"everlasting": ["EH2 V ER0 L AE1 S T IH0 NG"],
	"evermore": ["EH1 V ER0 M AO2 R"],
	"every": ["EH1 V ER0 IY3", "EH1 V R IY0"],
	"everyday": ["EH1 V R IY0 D EY1"],
	"everything": ["EH1 V R IY0 TH IH2 NG"],
	"everywhere": ["EH1 V R IY0 W EH2 R"],
	"eves": ["IY1 V Z"],
	"evidence": ["EH1 V AH0 D AH3 N S"],
	"evident": ["EH1 V AH0 D AH2 N T"],
	"evil": ["IY1 V AH0 L"],
	"evince": ["IH0 V IH1 N S"],
	"evoke": ["IH0 V OW1 K"],
	"ewe": ["Y UW1"],
	"exact": ["IH0 G Z AE1 K T"],
	"exalt": ["IH0 G Z AO1 L T"],
	"example": ["IH0 G Z AE1 M P AH0 L"],
	"exanimate": ["EH0 G Z AE1 N AH0 M EY2 T"],
	"exceed": ["IH0 K S IY1 D"],
	"exceedingly": ["IH0 K S IY1 D IH0 NG L IY3"],
	"excel": ["IH0 K S EH1 L"],
	"excellence": ["EH1 K S AH0 L AH3 N S"],
	"excellent": ["EH1 K S AH0 L AH3 N T"],
	"excelling": ["EH0 K S EH1 L IH0 NG"],
	"except": ["IH0 K S EH1 P T"],
	"exception": ["IH0 K S EH1 P SH AH0 N"],
	"excess": ["EH1 K S EH2 S"],
	"exchang'd": ["IH0 K S CH EY1 N JH D"],
	"exchange": ["IH0 K S CH EY1 N JH"],
	"excite": ["IH0 K S AY1 T"],
	"excited": ["IH0 K S AY1 T AH0 D"],
	"exclaim": ["IH0 K S K L EY1 M"],
	"exclude": ["IH0 K S K L UW1 D"],
	"exclusion": ["IH0 K S K L UW1 ZH AH0 N"],
	"exclusive": ["IH0 K S K L UW1 S IH0 V"],
	"excursion": ["IH0 K S K ER1 ZH AH0 N"],
	"excuse": ["IH0 K S K Y UW1 S"],
	"execute": ["EH1 K S AH0 K Y UW2 T"],
	"executive": ["IH0 G Z EH1 K Y AH0 T IH3 V"],
	"executors": ["IH0 G Z EH1 K Y AH0 T ER0 Z"],
	"exempt": ["IH0 G Z EH1 M P T"],
	"exercise": ["EH1 K S ER0 S AY2 Z"],
	"exert": ["IH0 G Z ER1 T"],
	"exertion": ["IH0 G Z ER1 SH AH0 N"],
	"exeter": ["EH1 K S IH0 T ER3"],
	"exhalation": ["EH2 K S AH0 L EY1 SH AH0 N"],
	"exhale": ["EH0 K S HH EY1 L", "EH1 K S HH EY2 L"],
	"exhaustion": ["IH0 G Z AO1 S CH AH0 N"],
	"exhibit": ["IH0 G Z IH1 B IH0 T"],
	"exhibition": ["EH2 K S AH0 B IH1 SH AH0 N"],
	"exhilirate": ["EH0 G Z IH1 L ER0 EY2 T"],
	"exhiliration": ["IH0 G Z IH2 L ER0 EY1 SH AH0 N"],
	"exhort": ["IH0 G Z AO1 R T"],
	"exil'd": ["EH1 K S AY2 L D"],
	"exile": ["EH1 G Z AY2 L"],
	"exiled": ["EH1 K S AY2 L D"],
	"exist": ["IH0 G Z IH1 S T"],
	"existence": ["EH0 G Z IH1 S T AH0 N S"],
	"exorbitant": ["IH0 G Z AO1 R B IH0 T AH3 N T"],
	"exorcised": ["EH1 K S ER0 S AY2 Z D"],
	"expand": ["IH0 K S P AE1 N D"],
	"expanse": ["IH0 K S P AE1 N S"],
	"expect": ["IH0 K S P EH1 K T"],
	"expectant": ["IH0 K S P EH1 K T AH0 N T"],
	"expectation": ["EH2 K S P EH0 K T EY1 SH AH0 N"],
	"expedient": ["IH0 K S P IY1 D IY0 AH3 N T", "IH0 K S P IY1 D Y AH0 N T"],
	"expedients": ["IH0 K S P IY1 D IH0 AH2 N T S", "IH0 K S P IY1 D Y AH2 N T S"],
	"expedition": ["EH2 K S P AH0 D IH1 SH AH0 N"],
	"expel": ["IH0 K S P EH1 L"],
	"expelled": ["IH0 K S P EH1 L D"],
	"expend": ["IH0 K S P EH1 N D"],
	"expense": ["IH0 K S P EH1 N S"],
	"expensive": ["IH0 K S P EH1 N S IH0 V"],
	"experience": ["IH0 K S P IY1 R IY0 AH3 N S", "IH0 K S P IY1 R Y AH0 N S"],
	"experienced": ["IH0 K S P IY1 R IY0 AH3 N S T", "IH0 K S P IY1 R Y AH0 N S T"],
	"experiment": ["IH0 K S P EH1 R AH0 M AH3 N T"],
	"expert": ["EH1 K S P ER0 T"],
	"expiate": ["EH1 K S P IY0 EY2 T"],
	"expire": ["IH0 K S P AY1 R", "IH0 K S P AY1 ER0"],
	"expired": ["IH0 K S P AY1 R D", "IH0 K S P AY1 ER0 D"],
	"expiring": ["IH0 K S P AY1 R IH0 NG", "IH0 K S P AY1 ER0 IH0 NG"],
	"explain": ["IH0 K S P L EY1 N"],
	"exploit": ["EH0 K S P L OY1 T"],
	"exploits": ["EH1 K S P L OY2 T S"],
	"explore": ["IH0 K S P L AO1 R"],
	"explored": ["IH0 K S P L AO1 R D"],
	"exploring": ["IH0 K S P L AO1 R IH0 NG"],
	"exponent": ["EH1 K S P OW2 N AH0 N T", "IH2 K S P OW1 N AH0 N T"],
	"export": ["EH1 K S P AO0 R T"],
	"expose": ["IH0 K S P OW1 Z"],
	"exposed": ["IH0 K S P OW1 Z D"],
	"expound": ["IH0 K S P AW1 N D"],
	"express": ["IH0 K S P R EH1 S"],
	"expression": ["IH0 K S P R EH1 SH AH0 N"],
	"exprest": ["IH0 K S P R EH1 S T"],
	"exquisite": ["EH1 K S K W IH0 Z IH3 T"],
	"exstasy": ["EH1 K S T AH0 S IY3"],
	"extant": ["IH0 K S T AE1 N T", "EH1 K S T AH0 N T"],
	"extasy": ["EH1 K S T AH0 S IY2"],
	"extatic": ["EH0 K S T AE1 T IH0 K"],
	"extemporizing": ["EH2 K S T EH1 M P ER0 AY2 Z IH0 NG"],
	"extend": ["IH0 K S T EH1 N D"],
	"extense": ["EH0 K S T EH1 N S"],
	"exterminate": ["IH0 K S T ER1 M IH0 N EY2 T"],
	"external": ["IH0 K S T ER1 N AH0 L"],
	"externally": ["IH0 K S T ER1 N AH0 L IY3"],
	"extinction": ["IH0 K S T IH1 NG K SH AH0 N"],
	"extinguish": ["IH0 K S T IH1 NG G W IH0 SH"],
	"extol": ["IH0 K S T OW1 L"],
	"extort": ["EH0 K S T AO1 R T"],
	"extravagance": ["EH0 K S T R AE1 V AH0 G AH3 N S"],
	"extravagant": ["EH0 K S T R AE1 V AH0 G AH3 N T"],
	"extreme": ["EH0 K S T R IY1 M"],
	"extremest": ["EH0 K S T R IY1 M IH0 S T"],
	"extremity": ["EH0 K S T R EH1 M AH0 T IY3"],
	"extrinsic": ["IH0 K S T R IH1 N Z IH0 K"],
	"exult": ["IH0 G Z AH1 L T"],
	"exultant": ["IH0 G Z AH1 L T AH0 N T"],
	"exultation": ["EH1 K S UH0 L T EY2 SH AH0 N"],
	"ey'd": ["AY1 D"],
	"eye": ["AY1"],
	"eyeball": ["AY1 B AO2 L"],
	"eyelids": ["AY1 L IH2 D Z"],
	"eyraco": ["EH1 R AH0 K OW2"],
	"fables": ["F EY1 B AH0 L Z"],
	"fabric": ["F AE1 B R IH0 K"],
	"fabulous": ["F AE1 B Y AH0 L AH0 S"],
	"face": ["F EY1 S"],
	"faced": ["F EY1 S T"],
	"faces": ["F EY1 S IH0 Z"],
	"facilitate": ["F AH0 S IH1 L IH0 T EY2 T"],
	"facing": ["F EY1 S IH0 NG"],
	"fact": ["F AE1 K T"],
	"factories": ["F AE1 K T ER0 IY3 Z"],
	"faculties": ["F AE1 K AH0 L T IY2 Z"],
	"fade": ["F EY1 D"],
	"faded": ["F EY1 D IH0 D"],
	"fading": ["F EY1 D IH0 NG"],
	"faery": ["F EH1 R IY0"],
	"fagot": ["F AE1 G AH0 T"],
	"fahrenheit": ["F EH1 R AH0 N HH AY2 T"],
	"fail": ["F EY1 L"],
	"failure": ["F EY1 L Y ER0"],
	"fain": ["F EY1 N"],
	"faint": ["F EY1 N T"],
	"fair": ["F EH1 R"],
	"fairy": ["F EH1 R IY0"],
	"faith": ["F EY1 TH"],
	"faithful": ["F EY1 TH F AH0 L"],
	"faithfully": ["F EY1 TH F AH0 L IY3"],
	"falconry": ["F AE1 L K AH0 N R IY3"],
	"fall": ["F AO1 L"],
	"fallacy": ["F AE1 L AH0 S IY3"],
	"fallen": ["F AA1 L AH0 N"],
	"false": ["F AO1 L S"],
	"falsehood": ["F AO1 L S HH UH2 D"],
	"falter": ["F AO1 L T ER0"],
	"faltering": ["F AO1 L T ER0 IH3 NG", "F AO1 L T R IH0 NG"],
	"fame": ["F EY1 M"],
	"famed": ["F EY1 M D"],
	"familiar": ["F AH0 M IH1 L Y ER0"],
	"family": ["F AE1 M AH0 L IY3"],
	"famine": ["F AE1 M AH0 N"],
	"famish": ["F AE1 M IH0 SH"],
	"famous": ["F EY1 M AH0 S"],
	"fan": ["F AE1 N"],
	"fancied": ["F AE1 N S IY0 D"],
	"fancies": ["F AE1 N S IY0 Z"],
	"fanciest": ["F AE1 N S IY0 AH3 S T", "F AE1 N S Y AH0 S T"],
	"fancy": ["F AE1 N S IY0"],
	"fane": ["F EY1 N"],
	"fang": ["F AE1 NG"],
	"fanned": ["F AE1 N D"],
	"fantasies": ["F AE1 N T AH0 S IY2 Z"],
	"fantastic": ["F AE0 N T AE1 S T IH0 K"],
	"fantasy": ["F AE1 N T AH0 S IY3"],
	"far": ["F AA1 R"],
	"farce": ["F AA1 R S"],
	"fardels": ["F AA1 R D AH0 L Z"],
	"fare": ["F EH1 R"],
	"fared": ["F EH1 R D"],
	"farewel": ["F EH2 R W EH1 L"],
	"farewell": ["F EH2 R W EH1 L","F EH1 R W EH2 L"],
	"farm": ["F AA1 R M"],
	"farmer": ["F AA1 R M ER0"],
	"farther": ["F AA1 R DH ER0"],
	"farthest": ["F AA1 R DH IH0 S T"],
	"farthings": ["F AA1 R DH IH0 NG Z"],
	"fascinate": ["F AE1 S IH0 N EY2 T"],
	"fashion": ["F AE1 SH AH0 N"],
	"fashionable": ["F AE1 SH AH0 N AH0 B AH0 L", "F AE1 SH N AH0 B AH0 L"],
	"fast": ["F AE1 S T"],
	"fasten": ["F AE1 S AH0 N"],
	"fastening": ["F AE1 S N IH0 NG", "F AE1 S AH0 N IH3 NG"],
	"fastenings": ["F AE1 S AH0 N IH3 NG Z", "F AE1 S N IH0 NG Z"],
	"fastidious": ["F AE0 S T IH1 D IY0 AH0 S", "F AE0 S T IH1 D Y AH0 S"],
	"fat": ["F AE1 T"],
	"fate": ["F EY1 T"],
	"fateful": ["F EY1 T F UH0 L"],
	"father": ["F AA1 DH ER0"],
	"fathom": ["F AE1 DH AH0 M"],
	"fatigue": ["F AH0 T IY1 G"],
	"fatiguèd": ["F AH0 T IY1 G IH0 D"],
	"fault": ["F AO1 L T"],
	"fav'rite": ["F EY1 V R IH0 T"],
	"favor": ["F EY1 V ER0"],
	"favorite": ["F EY1 V R IH0 T", "F EY1 V ER0 IH3 T"],
	"favour": ["F EY1 V ER0"],
	"favourite": ["F EY1 V R IH0 T", "F EY1 V ER0 IH3 T"],
	"fay": ["F EY1"],
	"fayette": ["F EY1 EH1 T"],
	"fazzioli": ["F AE2 Z IY0 AA1 L IY0"],
	"fe": ["F EY1"],
	"fear": ["F IY1 R"],
	"fearful": ["F IY1 R F AH0 L"],
	"fearfully": ["F IY1 R F AH0 L IY3"],
	"feasible": ["F IY1 Z AH0 B AH3 L"],
	"feast": ["F IY1 S T"],
	"feat": ["F IY1 T"],
	"feather": ["F EH1 DH ER0"],
	"feature": ["F IY1 CH ER0"],
	"february": ["F EH1 B Y AH0 W EH2 R IY0"],
	"fed": ["F EH1 D"],
	"federates": ["F EH1 D ER0 EY2 T S"],
	"fee": ["F IY1"],
	"feeble": ["F IY1 B AH0 L"],
	"feebly": ["F IY1 B L IY0"],
	"feed": ["F IY1 D"],
	"feel": ["F IY1 L"],
	"feeling": ["F IY1 L IH0 NG"],
	"feelings": ["F IY1 L IH0 NG Z"],
	"feer": ["F IY1 R"],
	"feet": ["F IY1 T"],
	"feign": ["F EY1 N"],
	"feinagle": ["F AH0 N EY1 G AH0 L"],
	"felicity": ["F IH0 L IH1 S AH0 T IY2"],
	"fell": ["F EH1 L"],
	"fellies": ["F EH1 L IY0 Z"],
	"fellow": ["F EH1 L OW0"],
	"fellowhope": ["F EH1 L OW0 H OW1 P"],
	"fellowship": ["F EH1 L OW0 SH IH2 P"],
	"felon": ["F EH1 L AH0 N"],
	"felt": ["F EH1 L T"],
	"female": ["F IY1 M EY2 L"],
	"feminine": ["F EH1 M AH0 N AH2 N"],
	"fence": ["F EH1 N S"],
	"fenced": ["F EH1 N S T"],
	"fend": ["F EH1 N D"],
	"fenny": ["F EH1 N IY0"],
	"ferdinand": ["F ER1 D IH0 N AE2 N D"],
	"fere": ["F IY1 R"],
	"ferrara": ["F ER0 AA1 R AH0"],
	"fertile": ["F ER1 T AH0 L"],
	"fertility": ["F ER0 T IH1 L AH0 T IY2"],
	"fervid": ["F ER1 V IH0 D"],
	"fest": ["F EH1 S T"],
	"festal": ["F EH1 S T AH0 L"],
	"festival": ["F EH1 S T AH0 V AH2 L"],
	"fetch": ["F EH1 CH"],
	"fete": ["F EY1 T"],
	"fetter": ["F EH1 T ER0"],
	"feud": ["F Y UW1 D"],
	"feudal": ["F Y UW1 D AH0 L"],
	"fever": ["F IY1 V ER0"],
	"few": ["F Y UW1"],
	"fez": ["F EH1 Z"],
	"fickle": ["F IH1 K AH0 L"],
	"ficklest": ["F IH1 K L AH0 S T"],
	"fiction": ["F IH1 K SH AH0 N"],
	"fictitious": ["F IH0 K T IH1 SH AH0 S"],
	"fiddles": ["F IH1 D AH0 L Z"],
	"fidelity": ["F AH0 D EH1 L AH0 T IY3"],
	"fief": ["F IY1 F"],
	"field": ["F IY1 L D"],
	"fiend": ["F IY1 N D"],
	"fierce": ["F IY1 R S"],
	"fiery": ["F AY1 ER0 IY3", "F AY1 R IY0"],
	"fife": ["F AY1 F"],
	"fifteen": ["F IH0 F T IY1 N", "F IH1 F T IY2 N"],
	"fifth": ["F IH1 F TH"],
	"fifty": ["F IH1 F T IY0"],
	"fight": ["F AY1 T"],
	"figment": ["F IH1 G M IH0 N T"],
	"figur'd": ["F IH1 G Y UH0 R D"],
	"figure": ["F IH1 G Y UH0 R"],
	"fil'd": ["F AY1 L D"],
	"filament": ["F IH1 L AH0 M AH3 N T"],
	"filaments": ["F IH1 L AH0 M EH2 N T S"],
	"filamot": ["F IH1 L IH0 M AA0 T"],
	"filched": ["F IH1 L CH T"],
	"file": ["F AY1 L"],
	"fill": ["F IH1 L"],
	"fillet": ["F AH0 L EY1"],
	"filleted": ["F IH1 L AH0 T IH2 D"],
	"film": ["F IH1 L M"],
	"final": ["F AY1 N AH0 L"],
	"finallest": ["F AY1 N AH0 L IH3 S T"],
	"finally": ["F AY1 N AH0 L IY3"],
	"financier": ["F AY2 N AE0 N S IY1 R"],
	"finch": ["F IH1 N CH"],
	"find": ["F AY1 N D"],
	"fine": ["F AY1 N"],
	"finer": ["F AY1 N ER0"],
	"finest": ["F AY1 N AH0 S T"],
	"finger": ["F IH1 NG G ER0"],
	"fingering": ["F IH1 NG G ER0 IH2 NG", "F IH1 NG G R IH0 NG"],
	"fingertips": ["F IH1 NG G ER0 T IH2 P S"],
	"finish": ["F IH1 N IH0 SH"],
	"finite": ["F AY1 N AY2 T"],
	"finitude": ["F IH1 N AH0 T UW2 D"],
	"finity": ["F IH1 N IH0 T IY3"],
	"fir": ["F ER1"],
	"fire": ["F AY1 ER0", "F AY1 R"],
	"fire's": ["F AY1 ER0 Z", "F AY1 R Z"],
	"fired": ["F AY1 ER0 D", "F AY1 R D"],
	"fireplace": ["F AY1 ER0 P L EY2 S"],
	"fires": ["F AY1 ER0 Z", "F AY1 R Z"],
	"fireside": ["F AY1 ER0 S AY2 D", "F AY1 R S AY2 D"],
	"firm": ["F ER1 M"],
	"firmament": ["F ER1 M AH0 M EH2 N T"],
	"firmamental": ["F ER2 M AH0 M EH1 N T AH0 L"],
	"firmaments": ["F ER1 M AH0 M EH2 N T S"],
	"first": ["F ER1 S T"],
	"fish": ["F IH1 SH"],
	"fishy": ["F IH1 SH IY0"],
	"fisherman": ["F IH1 SH ER0 M AH0 N"],
	"fissure": ["F IH1 SH ER0"],
	"fist": ["F IH1 S T"],
	"fit": ["F IH1 T"],
	"fitfully": ["F IH1 T F AH0 L IY3"],
	"fitted": ["F IH1 T IH0 D"],
	"fitter": ["F IH1 T ER0"],
	"fitting": ["F IH1 T IH0 NG"],
	"five": ["F AY1 V"],
	"fix": ["F IH1 K S"],
	"fix\u00e8d": ["F IH1 K S IH0 D"],
	"flag": ["F L AE1 G"],
	"flagged": ["F L AE1 G D"],
	"flagons": ["F L AE1 G AH0 N Z"],
	"flail": ["F L EY1 L"],
	"flake": ["F L EY1 K"],
	"flame": ["F L EY1 M"],
	"flaming": ["F L EY1 M IH0 NG"],
	"flamingo": ["F L AH0 M IH1 NG G OW0"],
	"flank": ["F L AE1 NG K"],
	"flare": ["F L EH1 R"],
	"flash": ["F L AE1 SH"],
	"flat": ["F L AE1 T"],
	"flatt'ry": ["F L AE1 T R IY0"],
	"flatter": ["F L AE1 T ER0"],
	"flattery": ["F L AE1 T ER0 IY3"],
	"flavor": ["F L EY1 V ER0"],
	"flaw": ["F L AO1"],
	"flax": ["F L AE1 K S"],
	"fled": ["F L EH1 D"],
	"flee": ["F L IY1"],
	"fleece": ["F L IY1 S"],
	"fleet": ["F L IY1 T"],
	"flesh": ["F L EH1 SH"],
	"flew": ["F L UW1"],
	"flicker": ["F L IH1 K ER0"],
	"flickering": ["F L IH1 K ER0 IH3 NG", "F L IH1 K R IH0 NG"],
	"flies": ["F L AY1 Z"],
	"flight": ["F L AY1 T"],
	"flinch": ["F L IH1 N CH"],
	"fling": ["F L IH1 NG"],
	"flint": ["F L IH1 N T"],
	"flirt": ["F L ER1 T"],
	"flit": ["F L IH1 T"],
	"flitted": ["F L IH1 T IH0 D"],
	"flitting": ["F L IH1 T IH0 NG"],
	"float": ["F L OW1 T"],
	"flock": ["F L AA1 K"],
	"flood": ["F L AH1 D"],
	"floor": ["F L AO1 R"],
	"florencetown": ["F L AO1 R AH0 N S T AW1 N"],
	"florentine": ["F L AO1 R AH0 N T IY2 N"],
	"floss": ["F L AA1 S"],
	"flourish": ["F L ER1 IH0 SH"],
	"flow": ["F L OW1"],
	"flow'r": ["F L AW1 R"],
	"flow'ry": ["F L AW1 R IY0"],
	"flower": ["F L AW1 R", "F L AW1 ER0"],
	"floweret": ["F L AW1 ER0 IH2 T"],
	"flowers": ["F L AW1 ER0 Z", "F L AW1 R Z"],
	"flown": ["F L OW1 N"],
	"fluctuating": ["F L AH1 K CH UH0 W EY2 T IH0 NG"],
	"fluent": ["F L UW1 AH0 N T"],
	"fluff": ["F L AH1 F"],
	"flung": ["F L AH1 NG"],
	"flurriedly": ["F L ER1 IY0 D L IY2"],
	"flush": ["F L AH1 SH"],
	"flute": ["F L UW1 T"],
	"flutter": ["F L AH1 T ER0"],
	"fluttering": ["F L AH1 T ER0 IH3 NG", "F L AH1 T R IH0 NG"],
	"fly": ["F L AY1"],
	"foam": ["F OW1 M"],
	"focus": ["F OW1 K AH0 S"],
	"foe": ["F OW1"],
	"fog": ["F AO1 G", "F AA1 G"],
	"foil": ["F OY1 L"],
	"fold": ["F OW1 L D"],
	"folk": ["F OW1 K"],
	"follow": ["F AA1 L OW0"],
	"following": ["F AA1 L OW0 IH0 NG", "F AA1 L W IH0 NG"],
	"folly": ["F AA1 L IY0"],
	"fond": ["F AA1 N D"],
	"font": ["F AA1 N T"],
	"food": ["F UW1 D"],
	"fool": ["F UW1 L"],
	"foolish": ["F UW1 L IH0 SH"],
	"foot": ["F UH1 T"],
	"football": ["F UH1 T B AO2 L"],
	"footfall": ["F UH1 T F AO2 L"],
	"footman": ["F UH1 T M AH0 N"],
	"footmen": ["F UW1 T M EH2 N"],
	"footprint": ["F UH1 T P R IH2 N T"],
	"footstep": ["F UH1 T S T EH2 P"],
	"footwork": ["F UH1 T W ER2 K"],
	"for": ["F AO3 R"],
	"forbade": ["F AO0 R B EY1 D"],
	"forbear": ["F AO0 R B EH1 R"],
	"forbid": ["F ER0 B IH1 D"],
	"forbidden": ["F ER0 R B IH1 D AH0 N"],
	"forbore": ["F AO2 R B AO1 R"],
	"forc'd": ["F AO1 R S T"],
	"force": ["F AO1 R S"],
	"forced": ["F AO1 R S T"],
	"ford": ["F AO1 R D"],
	"forebodes": ["F AO0 R B OW1 D Z"],
	"foredoom": ["F AO0 R D UW1 M"],
	"forefather": ["F AO1 R F AA2 DH ER0"],
	"forefinger": ["F AO1 R F IH2 NG G ER0"],
	"foregone": ["F AO1 R G AO1 N"],
	"forehead": ["F AO1 R HH EH2 D"],
	"foreign": ["F AO1 R AH0 N"],
	"foreigner": ["F AO1 R AH0 N ER3"],
	"foreignhood": ["F AO1 R AH0 N HH UH2 D"],
	"foremost": ["F AO1 R M OW2 S T"],
	"forerun": ["F AO0 R AH1 N"],
	"foresaw": ["F AO2 R S AO1"],
	"foresee": ["F AO0 R S IY1"],
	"foreseen": ["F AO2 R S IY1 N"],
	"foreshew": ["F AO0 R SH OW1"],
	"foreshow": ["F AO0 R SH OW1"],
	"forest": ["F AO1 R AH0 S T"],
	"forestall": ["F AO2 R S T AO1 L"],
	"foretaste": ["F AO1 R T EY0 S T"],
	"foretold": ["F AO0 R T OW1 L D"],
	"forever": ["F ER0 EH1 V ER0"],
	"forevermore": ["F AO0 R EH2 V ER0 M AO1 R"],
	"forfeit": ["F AO1 R F IH0 T"],
	"forg'd": ["F AO1 R JH D"],
	"forge": ["F AO1 R JH"],
	"forged": ["F AO1 R JH D"],
	"forget": ["F AO0 R G EH1 T"],
	"forgetful": ["F AO0 R G EH1 T F AH0 L"],
	"forgetting": ["F AO0 R G EH1 T IH0 NG"],
	"forgive": ["F AO0 R G IH1 V"],
	"forgiven": ["F AO0 R G IH1 V AH0 N"],
	"forgiveness": ["F AO0 R G IH1 V N AH0 S"],
	"forgo": ["F AO0 R G OW1"],
	"forgot": ["F AO0 R G AA1 T"],
	"forgotten": ["F AO0 R G AA1 T AH0 N"],
	"forlorn": ["F ER0 L AO1 R N"],
	"forlornest": ["F AO0 R LAO1 R N AH0 S T"],
	"form": ["F AO1 R M"],
	"formal": ["F AO1 R M AH0 L"],
	"formerly": ["F AO1 R M ER0 L IY3"],
	"formula": ["F AO1 R M Y AH0 L AH0"],
	"forsake": ["F AO0 R S EY1 K"],
	"forsaken": ["F AO0 R S EY1 K AH0 N"],
	"forsook": ["F AO0 R S UH1 K"],
	"forsooth": ["F AO0 R S UW1 TH"],
	"forsworn": ["F AO2 R S W AO1 R N"],
	"fort": ["F AO1 R T"],
	"forth": ["F AO1 R TH"],
	"fortify": ["F AO1 R T IH0 F AY2"],
	"fortitude": ["F AO1 R T IH0 T UW2 D"],
	"fortress": ["F AO1 R T R AH0 S"],
	"fortunate": ["F AO1 R CH AH0 N AH0 T"],
	"fortune": ["F AO1 R CH AH0 N"],
	"forty": ["F AO1 R T IY0"],
	"forum": ["F AO1 R AH0 M"],
	"forward": ["F AO1 R W ER0 D"],
	"forwarder": ["F AO1 R W ER0 D ER2"],
	"fought": ["F AO1 T"],
	"foul": ["F AW1 L"],
	"found": ["F AW1 N D"],
	"foundations": ["F AW0 N D EY1 SH AH0 N Z"],
	"fount": ["F AW1 N T"],
	"fountain": ["F AW1 N T AH0 N"],
	"four": ["F AO1 R"],
	"fourscore": ["F AO0 S K AO1"],
	"fourteen": ["F AO1 R T IY1 N"],
	"fourth": ["F AO1 R TH"],
	"fowl": ["F AW1 L"],
	"fox": ["F AA1 K S"],
	"foxglove": ["F AA1 K S G L AH3 V"],
	"fraction": ["F R AE1 K SH AH0 N"],
	"fracture": ["F R AE1 K CH ER0"],
	"fractured": ["F R AE1 K CH ER0 D"],
	"fragrant": ["F R EY1 G R AH0 N T"],
	"frail": ["F R EY1 L"],
	"frame": ["F R EY1 M"],
	"framed": ["F R EY1 M D"],
	"france": ["F R AE1 N S"],
	"francisco": ["F R AE2 N S IH1 S K OW0"],
	"frank": ["F R AE1 NG K"],
	"frankfort": ["F R AE1 NG K F ER0 T"],
	"frantic": ["F R AE1 N T IH0 K"],
	"fraud": ["F R AO1 D"],
	"fraught": ["F R AO1 T"],
	"fray": ["F R EY1"],
	"freak": ["F R IY1 K"],
	"freckled": ["F R EH1 K AH0 L D"],
	"free": ["F R IY1"],
	"freedom": ["F R IY1 D AH0 M"],
	"freeze": ["F R IY1 Z"],
	"freezing": ["F R IY1 Z IH0 NG"],
	"freight": ["F R EY1 T"],
	"french": ["F R EH1 N CH"],
	"frenzied": ["F R EH1 N Z IY0 D"],
	"frequent": ["F R IY1 K W AH0 N T"],
	"fresh": ["F R EH1 SH"],
	"freshen": ["F R EH1 SH AH0 N"],
	"freshet": ["F R EH1 SH EH0 T"],
	"fret": ["F R EH1 T"],
	"fretful": ["F R EH1 T F AH0 L"],
	"fretted": ["F R EH1 T IH0 D"],
	"friar": ["F R AY1 ER0"],
	"friedrich": ["F R IY1 D R IH0 K"],
	"friend": ["F R EH1 N D"],
	"friendship": ["F R EH1 N D SH IH0 P"],
	"frigate": ["F R IH1 G AH0 T"],
	"fright": ["F R AY1 T"],
	"frightened": ["F R AY1 T AH0 N D"],
	"frightening": ["F R AY1 T AH0 N IH3 NG", "F R AY1 T N IH0 NG"],
	"frigid": ["F R IH1 JH AH0 D"],
	"frill": ["F R IH1 L"],
	"fringe": ["F R IH1 N JH"],
	"frisk": ["F R IH1 S K"],
	"fritters": ["F R IH1 T ER0 Z"],
	"fro": ["F R OW1"],
	"frock": ["F R AA1 K"],
	"frog": ["F R AO1 G", "F R AA1 G"],
	"frolic": ["F R AA1 L IH0 K"],
	"from": ["F R AH3 M"],
	"front": ["F R AH1 N T"],
	"frontier": ["F R AH0 N T IY1 R"],
	"frost": ["F R AO1 S T"],
	"frown": ["F R AW1 N"],
	"froze": ["F R OW1 Z"],
	"frozen": ["F R OW1 Z AH0 N"],
	"fructified": ["F R AH1 K T IH0 F AY2 D"],
	"frugal": ["F R UW1 G AH0 L"],
	"fruit": ["F R UW1 T"],
	"fruitage": ["F R UW1 T IH0 JH"],
	"fruition": ["F R UW0 IH1 SH AH0 N"],
	"fruitlesser": ["F R UW1 L AH0 S ER3"],
	"frustrate": ["F R AH1 S T R EY2 T"],
	"fry": ["F R AY1"],
	"fuel": ["F Y UW1 AH0 L"],
	"fulfil": ["F UH0 L F IH1 L"],
	"fulfill": ["F UH0 L F IH1 L"],
	"full": ["F UH1 L"],
	"fullfraught": ["F UH3 L F R AA1 T"],
	"fulness": ["F UH1 L N AH0 S"],
	"fumble": ["F AH1 M B AH0 L"],
	"fumbled": ["F AH1 M B AH0 L D"],
	"fun": ["F AH1 N"],
	"function": ["F AH1 NG K SH AH0 N"],
	"fundamental": ["F AH2 N D AH0 M EH1 N T AH0 L"],
	"funeral": ["F Y UW1 N ER0 AH3 L"],
	"funnel": ["F AH1 N AH0 L"],
	"funny": ["F AH1 N IY0"],
	"fur": ["F ER1"],
	"furbelows": ["F AH1 B IH0 L OW0 Z"],
	"furious": ["F Y UH1 R IY0 AH3 S", "F Y UH1 R Y AH0 S"],
	"furl": ["F ER1 L"],
	"furlong": ["F ER1 L AA2 NG"],
	"furnace": ["F ER1 N AH0 S"],
	"furness": ["F ER1 N IH0 S"],
	"furnish": ["F ER1 N IH0 SH"],
	"furniture": ["F ER1 N IH0 CH ER3"],
	"furrow": ["F ER1 OW0"],
	"further": ["F ER1 DH ER0"],
	"furthest": ["F ER1 TH AH0 S T"],
	"fury": ["F Y UH1 R IY0"],
	"furze": ["F ER1 Z"],
	"fuse": ["F Y UW1 Z"],
	"fustian": ["F AH1 S T IY2 AH0 N", "F AH1 S T Y AH0 N"],
	"futile": ["F Y UW1 T AH0 L"],
	"future": ["F Y UW1 CH ER0"],
	"futurity": ["F Y UW0 T Y UH1 ER0 IH0 T IY2"],
	"fuzz": ["F AH1 Z"],
	"gables": ["G EY1 B AH0 L Z"],
	"gabriel": ["G EY1 B R IY0 AH0 L", "G EY1 B R Y AH0 L"],
	"gabriel's": ["G EY1 B R IY0 AH0 L Z", "G EY1 B R Y AH0 L Z"],
	"gag": ["G AE1 G"],
	"gages": ["G EY1 JH AH0 Z"],
	"gaiety": ["G EY1 AH0 T IY2"],
	"gaily": ["G EY1 L IY0"],
	"gain": ["G EY1 N"],
	"gainsay": ["G EY1 N S EY2"],
	"gainst": ["G EH3 N S T"],
	"gait": ["G EY1 T"],
	"gala": ["G AE1 L AH0"],
	"galaxies": ["G AE1 L AH0 K S IY3 Z"],
	"galaxy": ["G AE1 L AH0 K S IY3"],
	"gale": ["G EY1 L"],
	"gall": ["G AO1 L"],
	"gallant": ["G AE1 L AH0 N T"],
	"galleries": ["G AE1 L ER0 IY3 Z"],
	"gallery": ["G AE1 L ER0 IY3"],
	"galleys": ["G AE1 L IY0 Z"],
	"gallop": ["G AE1 L AH0 P"],
	"gamboge": ["G AE2 M  B OW1 JH"],
	"gambol": ["G AE1 M B AH0 L"],
	"gambolled": ["G AE1 M B AH0 L D"],
	"game": ["G EY1 M"],
	"gammut": ["G AE1 M AH0 T"],
	"ganymed": ["G AE1 N IH0 M EH2 D"],
	"gap": ["G AE1 P"],
	"gapes": ["G EY1 P S"],
	"garb": ["G AA1 R B"],
	"garden": ["G AA1 R D AH0 N"],
	"garland": ["G AA1 R L AH0 N D"],
	"garlanded": ["G AA1 R L AH0 N D IH2 D"],
	"garment": ["G AA1 R M AH0 N T"],
	"garner": ["G AA1 R N ER0"],
	"garret": ["G EH1 R IH0 T"],
	"garrison": ["G EH1 R AH0 S AH3 N"],
	"gash": ["G AE1 SH"],
	"gasp": ["G AE1 S P"],
	"gat": ["G AE1 T"],
	"gate": ["G EY1 T"],
	"gather": ["G AE1 DH ER0"],
	"gathering": ["G AE1 DH ER0 IH3 NG", "G AE1 DH R IH0 NG"],
	"gaudy": ["G AO1 D IY0"],
	"gaunt": ["G AO1 N T"],
	"gauze": ["G AO1 Z"],
	"gave": ["G EY1 V"],
	"gay": ["G EY1"],
	"gaz'd": ["G EY1 Z D"],
	"gaze": ["G EY1 Z"],
	"gazed": ["G EY1 Z D"],
	"gazelle": ["G AH0 Z EH1 L"],
	"gazer": ["G EY1 Z ER0"],
	"gazeth": ["G EY1 Z IH0 TH"],
	"gazettes": ["G AH0 Z EH1 T S"],
	"gazing": ["G EY1 Z IH0 NG"],
	"gear": ["G IY1 R"],
	"geese": ["G IY1 S"],
	"gem": ["JH EH1 M"],
	"gen'ral": ["JH EH1 N R AH0 L"],
	"general": ["JH EH1 N ER0 AH3 L", "JH EH1 N R AH0 L"],
	"generation": ["JH EH2 N ER0 EY1 SH AH0 N"],
	"generations": ["JH EH2 N ER0 EY1 SH AH0 N Z"],
	"generic": ["JH AH0 N EH1 R IH0 K"],
	"genial": ["JH IY1 N Y AH0 L", "JH IY1 N IY0 AH3 L"],
	"genius": ["JH IY1 N Y AH0 S"],
	"genoa": ["JH EH1 N OW0 AH0"],
	"gentian": ["JH EH1 N SH AH0 N"],
	"gentians": ["JH EH1 N SH AH0 N Z"],
	"gentile": ["JH EH1 N T AY2 L"],
	"gentle": ["JH EH1 N T AH0 L"],
	"gentlefolk": ["JH EH1 N T AH0 L F OW2 K"],
	"gentleman": ["JH EH1 N T AH0 L M AH0 N"],
	"gentlemen": ["JH EH1 N T AH0 L M EH3 N"],
	"gentlewomen": ["JH EH1 N T AH0 L W IH2 M AH0 N"],
	"gently": ["JH EH1 N T L IY0"],
	"gentry": ["JH EH1 N T R IY0"],
	"geography": ["JH IY0 AA1 G R AH0 F IY3"],
	"geometric": ["JH IY2 AH0 M EH1 T R IH0 K"],
	"george": ["JH AO1 R JH"],
	"geraldine": ["JH EH2 R AH0 L D AY1 N"],
	"geranium": ["JH ER0 EY1 N IY0 AH0 M", "JH ER0 EY1 N Y AH0 M"],
	"germ": ["JH ER1 M"],
	"germany": ["JH ER1 M AH0 N IY3"],
	"gesture": ["JH EH1 S CH ER0"],
	"get": ["G EH1 T"],
	"gethsemane": ["G EH0 TH S EH1 M AH0 N IY2"],
	"getting": ["G EH1 T IH0 NG"],
	"ghastly": ["G AE1 S T L IY0"],
	"ghibellin": ["G IH1 B IH0 L IH2 N"],
	"ghost": ["G OW1 S T"],
	"giant": ["JH AY1 AH0 N T"],
	"gianture": ["JH AY1 AH0 N CH ER3"],
	"giaours": ["JH AW1 ER0 Z"],
	"gibraltar": ["JH IH0 B R AO1 L T ER0"],
	"giddy": ["G IH1 D IY0"],
	"gift": ["G IH1 F T"],
	"gig": ["G IH1 G"],
	"gigantic": ["JH AY0 G AE1 N T IH0 K"],
	"giggling": ["G IH1 G AH0 L IH0 NG", "G IH1 G L IH0 NG"],
	"gild": ["G IH1 L D"],
	"gill": ["G IH1 L"],
	"gilt": ["G IH1 L T"],
	"gipsey": ["JH IH1 P S IY0"],
	"gird": ["G ER1 D"],
	"girl": ["G ER1 L"],
	"girlhood's": ["G ER1 L HH UH2 D Z"],
	"girt": ["G ER1 T"],
	"girth": ["G ER1 TH"],
	"giv'n": ["G IH0 V N"],
	"give": ["G IH1 V"],
	"given": ["G IH1 V AH0 N", "G IH1 V N"],
	"giver": ["G IH1 V ER0"],
	"giving": ["G IH1 V IH0 NG"],
	"glacier": ["G L EY1 SH ER0"],
	"glad": ["G L AE1 D"],
	"gladdened": ["G L AE1 D AH0 N D"],
	"glade": ["G L EY1 D"],
	"glance": ["G L AE1 N S"],
	"glanced": ["G L AE1 N S T"],
	"glancing": ["G L AE1 N S IH0 NG"],
	"glare": ["G L EH1 R"],
	"glass": ["G L AE1 S"],
	"glaze": ["G L EY1 Z"],
	"glazed": ["G L EY1 Z D"],
	"gleam": ["G L IY1 M"],
	"glean": ["G L IY1 N"],
	"glee": ["G L IY1"],
	"glen": ["G L EH1 N"],
	"glens": ["G L EH1 N Z"],
	"glide": ["G L AY1 D"],
	"gliding": ["G L AY1 D IH0 NG"],
	"glimm'ring": ["G L IH1 M R IH0 NG"],
	"glimmer": ["G L IH1 M ER0"],
	"glimmering": ["G L IH1 M ER0 IH3 NG", "G L IH1 M R IH0 NG"],
	"glimpse": ["G L IH1 M P S"],
	"glint": ["G L IH1 N T"],
	"glisten": ["G L IH1 S AH0 N"],
	"glistening": ["G L IH1 S AH0 N IH3 NG", "G L IH1 S N IH0 NG"],
	"glitter": ["G L IH1 T ER0"],
	"glittering": ["G L IH1 T ER0 IH3 NG", "G L IH1 T R IH0 NG"],
	"gloam": ["G L OW1 M"],
	"globe": ["G L OW1 B"],
	"globed": ["G L OW1 B D"],
	"gloom": ["G L UW1 M"],
	"gloomy": ["G L UW1 M IY0"],
	"glories": ["G L AO1 R IY0 Z"],
	"glorified": ["G L AO1 R AH0 F AY2 D"],
	"glorifies": ["G L AO1 R AH0 F AY2 Z"],
	"glorious": ["G L AO1 R IY0 AH3 S", "G L AO1 R Y AH0 S"],
	"gloriously": ["G L AO1 R Y AH0 S L IY3", "G L AO1 R IY0 AH3 S L IY0"],
	"glory": ["G L AO1 R IY0"],
	"gloss": ["G L AO1 S"],
	"glove": ["G L AH1 V"],
	"glow": ["G L OW1"],
	"gnat": ["N AE1 T"],
	"gnaw": ["N AO1"],
	"gnome": ["N OW1 M"],
	"go": ["G OW1"],
	"goal": ["G OW1 L"],
	"goalless": ["G OW1 L AH0 S"],
	"goblet": ["G AA1 B L AH0 T"],
	"goblin": ["G AA1 B L IH0 N"],
	"god": ["G AA1 D"],
	"goddess": ["G AA1 D AH0 S"],
	"goer": ["G OW1 ER0"],
	"goes": ["G OW1 Z"],
	"golconda": ["G AA0 L K AA1 N D AH0"],
	"gold": ["G OW1 L D"],
	"golden": ["G OW1 L D AH0 N"],
	"goliah": ["G AH0 L AY1 AH0"],
	"gondolier": ["G AA2 N D AH0 L IY1 R"],
	"gone": ["G AO1 N"],
	"gonna": ["G AH1 N AH0"],
	"good": ["G UH1 D"],
	"goodliest": ["G UH1 D L Y IH0 S T", "G UH1 D L IY0 IH3 S T"],
	"goodliness": ["G UW1 D L AY1 N EH2 S"],
	"goodnight": ["G UH2 D N AY1 T"],
	"goose": ["G UW1 S"],
	"gore": ["G AO1 R"],
	"goslings": ["G AA1 S L IH0 NG Z"],
	"gossamer": ["G AA1 S AH0 M ER3"],
	"gossameres": ["G AA1 S AH0 M IY2 R Z"],
	"got": ["G AA1 T"],
	"gourmand": ["G UH2 R M AA1 N D"],
	"govern": ["G AH1 V ER0 N"],
	"government": ["G AH1 V ER0 M AH2 N T"],
	"gowans": ["G AW1 AH0 N Z"],
	"gown": ["G AW1 N"],
	"grace": ["G R EY1 S"],
	"graced": ["G R EY1 S T"],
	"gracefullest": ["G R EY1 S F AH0 L EH2 S T"],
	"gracious": ["G R EY1 SH AH0 S"],
	"grade": ["G R EY1 D"],
	"gradual": ["G R AE1 JH UW0 AH0 L"],
	"gradually": ["G R AE1 JH UW0 AH0 L IY2"],
	"grain": ["G R EY1 N"],
	"granada": ["G R AH0 N AA1 D AH0"],
	"grand": ["G R AE1 N D"],
	"grandame's": ["G R AE2 N D EY1 M Z"],
	"grandeur": ["G R AE1 N D Y UH0 R"],
	"grandfather": ["G R AE1 N D F AA2 DH ER0"],
	"grandmama": ["G R AE1 N D M AH0 M AA2"],
	"grandmamma": ["G R AE1 N D M AH0 M AA3"],
	"grandsire": ["G R AE1 N D S AY2 R", "G R AE1 N D S AY2 ER0"],
	"grange": ["G R EY1 N JH"],
	"granicus": ["G R AE1 N IH0 K UH2 S"],
	"granite": ["G R AE1 N IH0 T"],
	"granitic": ["G R AE0 N IH1 T IH0 K"],
	"grant": ["G R AE1 N T"],
	"grape": ["G R EY1 P"],
	"graphic": ["G R AE1 F IH0 K"],
	"grapple": ["G R AE1 P AH0 L"],
	"grappled": ["G R AE1 P AH0 L D"],
	"grasp": ["G R AE1 S P"],
	"grass": ["G R AE1 S"],
	"grassy": ["G R AE1 S IY0"],
	"grate": ["G R EY1 T"],
	"gratis": ["G R AE1 T AH0 S"],
	"gratitude": ["G R AE1 T AH0 T UW2 D"],
	"grav'd": ["G R EY1 V D"],
	"grave": ["G R EY1 V"],
	"gravedamps": ["G R EY1 V D AE2 M P S"],
	"graven": ["G R EY1 V AH0 N"],
	"graveyard": ["G R EY1 V Y AA2 R D"],
	"gravitates": ["G R AE1 V IH0 T EY2 T S"],
	"gravitations": ["G R AE2 V IH0 T EY1 SH AH0 N Z"],
	"gravity": ["G R AE1 V AH0 T IY3"],
	"gray": ["G R EY1"],
	"grazing": ["G R EY1 Z IH0 NG"],
	"great": ["G R EY1 T"],
	"greaves": ["G R IY1 V Z"],
	"grecian": ["G R IY1 SH AH0 N"],
	"greece": ["G R IY1 S"],
	"greedy": ["G R IY1 D IY0"],
	"greeing": ["G R IY1 IH0 NG"],
	"greek": ["G R IY1 K"],
	"green": ["G R IY1 N"],
	"greenery": ["G R IY1 N ER0 IY3", "G R IY1 N R IY0"],
	"greet": ["G R IY1 T"],
	"grew": ["G R UW1"],
	"grey": ["G R EY1"],
	"griding": ["G R AY1 D IH0 NG"],
	"grief": ["G R IY1 F"],
	"grievances": ["G R IY1 V AH0 N S AH3 Z"],
	"grieve": ["G R IY1 V"],
	"grieved": ["G R IY1 V D"],
	"grieving": ["G R IY1 V IH0 NG"],
	"grim": ["G R IH1 M"],
	"grimed": ["G R AY1 M D"],
	"grin": ["G R IH1 N"],
	"grind": ["G R AY1 N D"],
	"grinning": ["G R IH1 N IH0 NG"],
	"groan": ["G R OW1 N"],
	"groom": ["G R UW1 M"],
	"groove": ["G R UW1 V"],
	"grope": ["G R OW1 P"],
	"groping": ["G R OW1 P IH0 NG"],
	"gross": ["G R OW1 S"],
	"grot": ["G R AA1 T"],
	"grotesque": ["G R OW0 T EH1 S K"],
	"ground": ["G R AW1 N D"],
	"group": ["G R UW1 P"],
	"grove": ["G R OW1 V"],
	"grow": ["G R OW1"],
	"growl": ["G R AW1 L"],
	"grown": ["G R OW1 N"],
	"growth": ["G R OW1 TH"],
	"grudge": ["G R AH1 JH"],
	"grumbled": ["G R AH1 M B AH0 L D"],
	"grunt": ["G R AH1 N T"],
	"guadalquivir": ["G W AA2 D AH0 L K IH0 V IY1 R"],
	"guage": ["G EY1 JH"],
	"guaging": ["G EY1 JH IH0 NG"],
	"guaranty": ["G EH2 R AH0 N T IY1"],
	"guard": ["G AA1 R D"],
	"guardian": ["G AA1 R D Y AH0 N", "G AA1 R D IY0 AH3 N"],
	"guelf": ["G W EH1 L F"],
	"guess": ["G EH1 S"],
	"guest": ["G EH1 S T"],
	"guide": ["G AY1 D"],
	"guided": ["G AY1 D IH0 D"],
	"guiding": ["G AY1 D IH0 NG"],
	"guido": ["G W IY1 D OW0"],
	"guidone": ["G IY0 D OW1 N EH0"],
	"guild": ["G IH1 L D"],
	"guile": ["G AY1 L"],
	"guillotine": ["G IY1 Y AH0 T IY2 N"],
	"guilt": ["G IH1 L T"],
	"guilty": ["G IH1 L T IY0"],
	"guinea": ["G IH1 N IY0"],
	"guineas": ["G IH1 N IY0 Z"],
	"guise": ["G AY1 Z"],
	"gules": ["G UW1 L Z"],
	"gulf": ["G AH1 L F"],
	"gulistan": ["G UW1 L IH0 S T AE2 N"],
	"gulped": ["G AH1 L P T"],
	"gulphing": ["G UH1 L F IH0 NG"],
	"gum": ["G AH1 M"],
	"gun": ["G AH1 N"],
	"gunnery": ["G AH1 N ER0 IY3"],
	"gurgle": ["G ER1 G AH0 L"],
	"gurney": ["G ER1 N IY0"],
	"gush": ["G AH1 SH"],
	"gust": ["G AH1 S T"],
	"guy": ["G AY1"],
	"gyas": ["G AY1 AH0 S"],
	"gymnast": ["JH IH1 M N AH0 S T"],
	"habiliment": ["HH AH0 B IY1 L AH0 M AH2 N T"],
	"habit": ["HH AE1 B AH0 T"],
	"habitation": ["HH AE2 B IH0 T EY1 SH AH0 N"],
	"had": ["HH AE1 D"],
	"hades": ["HH EY1 D IY0 Z"],
	"hadst": ["H AE1 D S T"],
	"haidee": ["HH EY2 D IY1"],
	"hail": ["HH EY1 L"],
	"hair": ["HH EH1 R"],
	"hairy": ["HH EH1 R IY0"],
	"halcyon": ["HH AE1 L S IY0 AH0 N"],
	"hale": ["HH EY1 L"],
	"half": ["HH AE1 F"],
	"halfeffaced": ["H AE2 F IH0 F EY1 S T"],
	"halfgerminating": ["HH AE2 JH ER1 M AH0 N EY2 T IH0 NG"],
	"halifax": ["HH AE1 L IH0 F AE2 K S"],
	"hall": ["HH AO1 L"],
	"hallelujah": ["HH AE2 L AH0 L UW1 Y AH0"],
	"hallelujahs": ["HH AE2 L AH0 L UW1 Y AH0 Z"],
	"halloo'd": ["HH AH0 L UW1 D"],
	"halloos": ["HH AH0 L UW1 Z"],
	"hallowed": ["HH AE1 L OW0 D"],
	"hallowing": ["HH AE1 L OW0 IH3 NG"],
	"halo": ["HH EY1 L OW0"],
	"halt": ["HH AO1 L T"],
	"halves": ["HH AE1 V Z"],
	"hamadryad": ["H AA1 M AH0 D R AY2 AE0 D"],
	"hame": ["H EY1 M"],
	"hamlet": ["HH AE1 M L IH0 T"],
	"hammer": ["HH AE1 M ER0"],
	"hammock's": ["HH AE1 M AH0 K S"],
	"hand": ["HH AE1 N D"],
	"handful": ["HH AE1 N D F AH0 L"],
	"handkerchief": ["HH AE1 NG K ER0 CH IH2 F"],
	"handle": ["HH AE1 N D AH0 L"],
	"handling": ["HH AE1 N D L IH0 NG", "HH AE1 N D AH0 L IH3 NG"],
	"handsome": ["HH AE1 N S AH0 M"],
	"hang": ["HH AE1 NG"],
	"hankering": ["HH AE1 NG K ER0 IH2 NG", "HH AE1 NG K R IH0 NG"],
	"hannah": ["HH AE1 N AH0"],
	"haply": ["H AE1 P L IY0"],
	"happen": ["HH AE1 P AH0 N"],
	"happening": ["HH AE1 P AH0 N IH3 NG", "HH AE1 P N IH0 NG"],
	"happier": ["HH AE1 P IY0 ER0", "HH AE1 P Y ER0"],
	"happily": ["HH AE1 P AH0 L IY3"],
	"happiness": ["HH AE1 P IY0 N AH3 S"],
	"happy": ["HH AE1 P IY0"],
	"haranguer": ["HH AH0 R AE1 NG"],
	"harass": ["H AH0 R AE1 S", "H ER1 AH0 S"],
	"harbor": ["HH AA1 R B ER0"],
	"hard": ["HH AA1 R D"],
	"hardest": ["HH AA1 R D AH0 S T"],
	"hardihood": ["HH AA1 R D IH0 HH UH2 D"],
	"hardly": ["HH AA1 R D L IY0"],
	"hardships": ["HH AA1 R D SH IH0 P S"],
	"hare": ["HH EH1 R"],
	"hark": ["HH AA1 R K"],
	"harken": ["HH AA1 R K AH0 N"],
	"harm": ["HH AA1 R M"],
	"harmony": ["HH AA1 R M AH0 N IY3"],
	"harrassed": ["H AH0 R AE1 S T"],
	"harrison": ["HH EH1 R AH0 S AH3 N"],
	"harrow": ["HH EH1 R OW0"],
	"harry": ["HH EH1 R IY0"],
	"harsh": ["HH AA1 R SH"],
	"harvest": ["HH AA1 R V IH0 S T"],
	"has": ["HH AE1 Z"],
	"hast": ["HH AE1 S T"],
	"haste": ["HH EY1 S T"],
	"hasten": ["H EY1 S AH0 N"],
	"hastily": ["HH EY1 S T AH0 L IY3"],
	"hasting": ["HH EY1 S T IH0 NG"],
	"hat": ["HH AE1 T"],
	"hatch": ["HH AE1 CH"],
	"hate": ["HH EY1 T"],
	"hateful": ["HH EY1 T F AH0 L"],
	"hath": ["HH AE3 TH", "HH AE2 TH"],
	"haughtily": ["HH AO1 T IH0 L IY3"],
	"haughty": ["HH AO1 T IY0"],
	"haul": ["HH A01 L"],
	"haunt": ["HH AO1 N T"],
	"have": ["HH AE1 V"],
	"haven": ["HH EY1 V AH0 N"],
	"haven't": ["HH AE1 V AH0 N T"],
	"having": ["HH AE1 V IH0 NG"],
	"haw": ["HH AO1"],
	"hawk": ["HH AO1 K"],
	"hawke": ["HH AO1 K"],
	"haworth": ["HH AE1 W ER0 TH"],
	"hay": ["HH EY1"],
	"hazard": ["HH AE1 Z ER0 D"],
	"haze": ["HH EY1 Z"],
	"hazel": ["HH EY1 Z AH0 L"],
	"he": ["HH IY3","HH IY2"],
	"he'd": ["HH IY1 D"],
	"he'll": ["HH IY1 L"],
	"head": ["HH EH1 D"],
	"headlands": ["H EH1 D L AE3 N D Z"],
	"heal": ["HH IY1 L"],
	"health": ["HH EH1 L TH"],
	"heap": ["HH IY1 P"],
	"heaped": ["HH IY1 P T"],
	"hear": ["HH IY1 R"],
	"heard": ["HH ER1 D"],
	"hearken": ["HH AA1 R K AH0 N"],
	"hearkening": ["HH AA1 R K AH0 N IH3 NG", "HH AA1 R K N IH0 NG"],
	"heart": ["HH AA1 R T"],
	"heartache": ["HH AA1 R T EY2 K"],
	"heartbreak": ["HH AA1 R T B R EY2 K"],
	"hearth": ["HH AA1 R TH"],
	"hearthstone": ["HH AA1 R TH S T OW2 N"],
	"hearty": ["HH AA1 R T IY0"],
	"heat": ["HH IY1 T"],
	"heath": ["HH IY1 TH"],
	"heather": ["HH EH1 DH ER0"],
	"heatherbell": ["HH EH1 TH ER0 B EH2 L"],
	"heav'n": ["HH EH1 V N"],
	"heav'nly": ["HH EH1 V N L IY0"],
	"heave": ["HH IY1 V"],
	"heaved": ["HH IY1 V D"],
	"heaven": ["HH EH1 V AH0 N", "HH EH1 V N"],
	"heaven's": ["HH EH1 V AH0 N Z", "HH EH1 V N Z"],
	"heavenly": ["HH EH1 V AH0 N L IY2", "HH EH1 V N L IY0"],
	"heavens": ["HH EH1 V AH0 N Z", "HH EH1 V N Z"],
	"heavenward": ["HH EH1 V AH0 N W AH0 D"],
	"heavier": ["HH EH1 V IY0 ER0"],
	"heavily": ["HH EH1 V AH0 L IY3"],
	"heaviness": ["HH EH1 V IH0 N AH2 S"],
	"heaving": ["HH IY1 V IH0 NG"],
	"heavy": ["HH EH1 V IY0"],
	"hectic": ["HH EH1 K T IH0 K"],
	"hedge": ["HH EH1 JH"],
	"heed": ["HH IY1 D"],
	"heel": ["HH IY1 L"],
	"heft": ["HH EH1 F T"],
	"height": ["HH AY1 T"],
	"heir": ["EH1 R"],
	"heiress": ["EH1 R AH0 S"],
	"held": ["HH EH1 L D"],
	"helicon": ["HH EH1 L IH0 K AA2 N"],
	"helios": ["HH IY1 L IY0 AA2 S"],
	"hell": ["HH EH1 L"],
	"hellespont": ["HH EH1 L IH0 S P AA2 N T"],
	"hellpains": ["HH EH1 L P EY2 N Z"],
	"helm": ["HH EH1 L M"],
	"helmet": ["HH EH1 L M AH0 T"],
	"help": ["HH EH1 L P"],
	"hem": ["HH EH1 M"],
	"hemisphere": ["HH EH1 M IH0 S F IH2 R"],
	"hemispheres": ["HH EH1 M IH0 S F IH2 R Z"],
	"hemlock": ["HH EH1 M L AA2 K"],
	"hempen": ["HH EH1 M P AH0 N"],
	"hence": ["HH EH1 N S"],
	"henceforth": ["HH EH1 N S F AO0 R TH"],
	"henceforward": ["H EH2 N S F AO1 R W ER0 D"],
	"her": ["HH ER3"],
	"heraldry": ["HH EH1 R AH0 L D R IY0"],
	"herb": ["ER1 B"],
	"herd": ["HH ER1 D"],
	"here": ["HH IY1 R"],
	"hereafter": ["HH IY0 R AE1 F T ER0"],
	"herein": ["H IY2 R IH1 N"],
	"heresies": ["HH EH1 R AH0 S IY3 Z"],
	"heresy": ["HH EH1 R AH0 S IY3"],
	"heretic": ["HH EH1 R AH0 T IH2 K"],
	"hereupon": ["HH IH2 R AH0 P AA1 N"],
	"heritage": ["HH EH1 R AH0 T AH3 JH"],
	"hermetic": ["HH ER0 M EH1 T IH0 K"],
	"hermit": ["HH ER1 M IH0 T"],
	"hermitage": ["HH ER1 M AH0 T AH2 JH"],
	"hermitess": ["HH ER1 M IH0 T IH2 S"],
	"hero": ["HH IY1 R OW0"],
	"heroine": ["HH EH1 R OW0 IH2 N"],
	"hers": ["HH ER1 Z"],
	"herschel": ["HH ER1 SH AH0 L"],
	"herself": ["HH ER0 S EH1 L F"],
	"hesitate": ["HH EH1 Z AH0 T EY2 T"],
	"hesitating": ["HH EH1 Z AH0 T EY2 T IH0 NG"],
	"hesperian": ["HH EH0 S P EH1 R Y AH0 N"],
	"hesperides": ["H EH0 S P EH1 R IH D IY2 Z"],
	"hew": ["HH Y UW1"],
	"hewn": ["HH Y UW1 N"],
	"hey": ["HH EY1"],
	"hiatus": ["HH AY0 EY1 T AH0 S"],
	"hiawatha": ["HH AY2 AH0 W AA1 TH AH0"],
	"hibernal": ["H AY0 B ER1 N AH0 L"],
	"hiccups": ["HH IH1 K AH0 P S"],
	"hid": ["HH IH1 D"],
	"hidden": ["HH IH1 D AH0 N"],
	"hide": ["HH AY1 D"],
	"hideous": ["HH IH1 D Y AH0 S", "HH IH1 D IY0 AH3 S"],
	"hiding": ["HH AY1 D IH0 NG"],
	"hie": ["HH AY1"],
	"high": ["HH AY1"],
	"highland": ["HH AY1 L AH0 N D"],
	"hight": ["HH AY1 T"],
	"hildebrand": ["HH IH1 L D IH0 B R AE2 N D"],
	"hill": ["HH IH1 L"],
	"hillcat": ["H IH1 L K AE2 T"],
	"hillocks": ["HH IH1 L AH0 K S"],
	"hillside": ["HH IH1 L S AY2 D"],
	"hilltop": ["HH IH1 L T AA2 P"],
	"him": ["HH IH3 M"],
	"himmaleh": ["H IH1 M AH0 L EY2"],
	"himself": ["HH IH0 M S EH1 L F"],
	"hind": ["HH AY1 N D"],
	"hinder": ["HH IH1 N D ER0"],
	"hindered": ["HH IH1 N D ER0 D"],
	"hindereth": ["HH IH1 N D ER0 AH3 TH", "HH IH1 N D R AH0 TH"],
	"hindering": ["HH IH1 N D ER0 IH3 NG", "HH IH1 N D R IH0 NG"],
	"hindrance": ["HH IH1 N D R AH0 N S"],
	"hinds": ["HH AY1 N D Z"],
	"hinge": ["HH IH1 N JH"],
	"hint": ["HH IH1 N T"],
	"hip": ["HH IH1 P"],
	"hippocrene": ["HH IH1 P AH0 K R IY2 N"],
	"hire": ["HH AY1 ER0", "HH AY1 R"],
	"hired": ["HH AY1 ER0 D", "H AY1 R D"],
	"his": ["HH IH3 Z"],
	"hiss": ["HH IH1 S"],
	"hist": ["HH IH1 S T"],
	"hist'ry": ["HH IH1 S T R IY0"],
	"histories": ["HH IH1 S T ER0 IY3 Z", "HH IH1 S T R IY0 Z"],
	"history": ["HH IH1 S T ER0 IY3", "HH IH1 S T R IY0"],
	"hit": ["HH IH1 T"],
	"hitherto": ["HH IH2 DH ER0 T UW1"],
	"hive": ["HH AY1 V"],
	"ho": ["HH OW1"],
	"hoar": ["HH AO1 R"],
	"hoard": ["HH AO1 R D"],
	"hoarfrost": ["H AO2 R F R AA1 S T"],
	"hoary": ["HH AO1 R IY0"],
	"hock": ["HH AA1 K"],
	"hoe": ["HH OW1"],
	"hoist": ["HH OY1 S T"],
	"hold": ["HH OW1 L D"],
	"holiday": ["HH AA1 L AH0 D EY2"],
	"holiness": ["HH OW1 L IY0 N AH3 S"],
	"holland": ["HH AA1 L AH0 N D"],
	"hollow": ["HH AA1 L OW0"],
	"holy": ["HH OW1 L IY0"],
	"home": ["HH OW1 M"],
	"homelessness": ["HH OW1 M L AH0 S N AH3 S"],
	"homelier": ["H OW1 M L IY0 ER0", "H OW1 M L Y ER0"],
	"homeliest": ["H OW1 M L IY0 IH3 S T", "H OW1 M L Y IH0 S T"],
	"homely": ["HH OW1 M L IY0"],
	"homer": ["HH OW1 M ER0"],
	"homesick": ["HH OW1 M S IH2 K"],
	"homestead": ["HH OW1 M S T EH2 D"],
	"hometalk": ["H OW1 M T AO2 K"],
	"homily": ["HH AA1 M AH0 L IY3"],
	"homogeneous": ["HH OW2 M AH0 JH IY1 N IY0 AH3 S", "HH OW2 M AH0 JH IY1 N Y AH0 S", "HH AH0 M AA1 JH AH0 N AH3 S"],
	"honest": ["AA1 N AH0 S T"],
	"honesty": ["AA1 N AH0 S T IY3"],
	"honey": ["HH AH1 N IY0"],
	"honeymoon": ["HH AH1 N IY0 M UW2 N"],
	"honor": ["AA1 N ER0"],
	"honorable": ["AA1 N ER0 AH0 B AH0 L"],
	"honorius": ["AO0 N AO1 R Y UH0 S"],
	"honorless": ["AA1 N ER0 L AH3 S"],
	"honour": ["AA1 N ER0"],
	"honourable": ["AA1 N ER0 AH3 B AH0 L"],
	"hood": ["HH UH1 D"],
	"hoof": ["HH UH1 F"],
	"hook": ["HH UH1 K"],
	"hoot": ["HH UW1 T"],
	"hope": ["HH OW1 P"],
	"hoped": ["HH OW1 P T"],
	"hopefully": ["HH OW1 P F AH0 L IY2"],
	"hopelessly": ["H OW1 P L AH0 S L IY3"],
	"hopesick": ["H OW1 P S IH3 K"],
	"hoping": ["HH OW1 P IH0 NG"],
	"hopped": ["HH AA1 P T"],
	"hopper": ["HH AA1 P ER0"],
	"horizon": ["HH ER0 AY1 Z AH0 N"],
	"horizonround": ["HH AO0 R AY1 Z AH0 N R AW2 N D"],
	"horizons": ["HH ER0 AY1 Z AH0 N Z"],
	"horn": ["HH AO1 R N"],
	"horrid": ["HH AO1 R IH0 D"],
	"horror": ["HH AO1 R ER0"],
	"horrour": ["HH AO1 R ER0"],
	"horse": ["HH AO1 R S"],
	"horsemen": ["HH AO1 R S M AH0 N"],
	"hospitable": ["HH AA2 S P IH1 T AH0 B AH3 L"],
	"hospitality": ["HH AA2 S P AH0 T AE1 L AH0 T IY2"],
	"host": ["HH OW1 S T"],
	"hostiler": ["HH AA1 S T L ER0", "HH AA1 S T AH0 L ER3", "HH AA1 S T AY2 L ER0"],
	"hot": ["HH AA1 T"],
	"hottest": ["HH AA1 T IH0 S T"],
	"hound": ["HH AW1 N D"],
	"hour": ["AW1 ER0", "AW1 R"],
	"hours": ["AW1 ER0 Z", "AW1 R Z"],
	"house": ["HH AW1 S"],
	"household": ["H AW1 S H OW2 L D"],
	"households": ["HH AW1 S HH OW2 L D Z"],
	"housewife": ["HH AW1 S W AY2 F"],
	"housewife's": ["HH AW1 S W AY2 F S"],
	"housewives": ["HH AW1 S W AY2 V Z"],
	"hovel": ["HH AH1 V AH0 L"],
	"hover": ["HH AH1 V ER0"],
	"hovering": ["HH AH1 V ER0 IH2 NG", "HH AH1 V R IH0 NG"],
	"how": ["HH AW1"],
	"howe": ["HH AW1"],
	"however": ["HH AW2 EH1 V ER0"],
	"howled": ["HH AW1 L D"],
	"howling": ["HH AW1 L IH0 NG"],
	"hue": ["HH Y UW1"],
	"hug": ["HH AH1 G"],
	"huge": ["HH Y UW1 JH"],
	"hum": ["HH AH1 M"],
	"human": ["HH Y UW1 M AH0 N"],
	"humanity": ["HH Y UW0 M AE1 N IH0 T IY3"],
	"humble": ["HH AH1 M B AH0 L"],
	"humbler": ["HH AH1 M B L ER0"],
	"humbly": ["HH AH1 M B L IY0"],
	"humiles": ["HH UW0 M IY1 L EH0 S"],
	"humility": ["HH Y UW0 M IH1 L IH0 T IY3"],
	"hummed": ["HH AH1 M D"],
	"humming": ["HH AH1 M IH0 NG"],
	"hun": ["HH AH1 N"],
	"hunch": ["H AH1 N CH"],
	"hundred": ["HH AH1 N D R IH0 D"],
	"hundredfold": ["HH AH1 N D R AH0 D F OW2 L D"],
	"hung": ["HH AH1 NG"],
	"hunger": ["HH AH1 NG G ER0"],
	"hungrily": ["HH AH1 NG G R AH0 L IY3"],
	"hungry": ["HH AH1 NG G R IY0"],
	"hunt": ["HH AH1 N T"],
	"hunter": ["HH AH1 N T ER0"],
	"hurdle": ["HH ER1 D AH0 L"],
	"hurl": ["HH ER1 L"],
	"hurricane": ["HH ER1 AH0 K EY2 N"],
	"hurried": ["HH ER1 IY0 D"],
	"hurries": ["HH ER1 IY0 Z"],
	"hurry": ["HH ER1 IY0"],
	"hurt": ["HH ER1 T"],
	"husband": ["HH AH1 Z B AH0 N D"],
	"husbandry": ["HH AH1 Z B AH0 N D R IY3"],
	"hush": ["HH AH1 SH"],
	"hush\u00e8d": ["H AH2 SH IH0 D"],
	"hut": ["HH AH1 T"],
	"hyacinth": ["HH AY1 AH0 S IH2 N TH"],
	"hybla": ["H AY1 B L AH0"],
	"hymn": ["HH IH1 M"],
	"hyperbolic": ["HH AY2 P ER0 B AA1 L IH0 K"],
	"hypocrisy": ["HH IH0 P AA1 K R AH0 S IY3"],
	"hypocrite": ["HH IH1 P AH0 K R IH2 T"],
	"hysterical": ["HH IH0 S T EH1 R IH0 K AH2 L"],
	"i": ["AY1"],
	"i'd": ["AY3 D"],
	"i'll": ["AY1 L"],
	"i'm": ["AY1 M"],
	"i've": ["AY1 V"],
	"ice": ["AY1 S"],
	"icestorm": ["AY1 S S T AO2 R M"],
	"icestorms": ["AY1 S S T AO2 R M Z"],
	"icicle": ["AY1 S IH0 K AH0 L"],
	"icicles": ["AY1 S IH0 K AH0 L Z"],
	"ida": ["AY1 D AH0"],
	"idea": ["AY0 D IY1 AH0"],
	"ideal": ["AY0 D IY1 L"],
	"identified": ["AY0 D EH1 N T IH0 F AY2 D"],
	"identify": ["AY0 D EH1 N T IH0 F AY2"],
	"identity": ["AY0 D EH1 N T IH0 T IY2"],
	"idle": ["AY1 D AH0 L"],
	"idleness": ["AY1 D AH0 L N AH3 S"],
	"idolatry": ["AY0 D AA1 L AH0 T R IY3"],
	"idolize": ["AY1 D AH0 L AY2 Z"],
	"idyl": ["IH1 D IH0 L"],
	"if": ["IH1 F"],
	"ignite": ["IH2 G N AY1 T"],
	"ignoble": ["IH2 G N OW1 B AH0 L"],
	"ignominy": ["IH1 G N OW0 M IH2 N IY0"],
	"ignorance": ["IH1 G N ER0 AH3 N S"],
	"ignorant": ["IH1 G N ER0 AH3 N T"],
	"ignore": ["IH2 G N AO1 R"],
	"ilium": ["IH1 L Y AH0 M"],
	"ill": ["IH1 L"],
	"illegible": ["IH2 L EH1 JH AH0 B AH2 L"],
	"illegitimate": ["IH2 L IH0 JH IH1 T AH0 M IH3 T"],
	"illness": ["IH1 L N AH0 S"],
	"illocality": ["IH2 L OW0 K AE1 L IH0 T IY3"],
	"illuminate": ["IH2 L UW1 M IH0 N EY2 T"],
	"illusive": ["IH2 L UW1 S IH0 V"],
	"illustration": ["IH2 L AH0 S T R EY1 SH AH0 N"],
	"image": ["IH1 M IH0 JH"],
	"imagery": ["IH1 M AH0 JH R IY3"],
	"imagined": ["IH2 M AE1 JH AH0 N D"],
	"imagining": ["IH0 M AE1 JH IH0 N IH2 NG"],
	"imbibe": ["IH0 M B AY1 B"],
	"imbower": ["IH0 M B AW1 ER0", "IH0 M B AW1 R"],
	"imitate": ["IH1 M AH0 T EY2 T"],
	"imitating": ["IH1 M AH0 T EY2 T IH0 NG"],
	"imitation": ["IH2 M AH0 T EY1 SH AH0 N"],
	"immediate": ["IH2 M IY1 D IY0 IH0 T", "IH2 M IY1 D Y IH0 T"],
	"immediately": ["IH2 M IY1 D IY2 AH0 T L IY0", "IH2 M EY1 D Y AH0 T L IY3"],
	"immense": ["IH2 M EH1 N S"],
	"immensity": ["IH0 M EH1 N S IH0 T IY2"],
	"immerse": ["IH2 M ER1 S"],
	"immersion": ["IH2 M ER1 ZH AH0 N"],
	"imminent": ["IH1 M AH0 N AH3 N T"],
	"immortal": ["IH3 M AO1 R T AH0 L"],
	"immortality": ["IH2 M AO0 R T AE1 L IH0 T IY3"],
	"imogen": ["IH1 M AH0 G AH2 N"],
	"imp": ["IH1 M P"],
	"impair": ["IH0 M P EH1 R"],
	"impaled": ["IH2 M P EY1 L D"],
	"impalpable": ["IH2 M P AE1 L P AH0 B AH3 L"],
	"impanelled": ["IH2 M P AE1 N AH0 L D"],
	"impannelled": ["IH0 M P AE1 N AH0 L D"],
	"impart": ["IH3 M P AA1 R T"],
	"impartial": ["IH2 M P AA1 R SH AH0 L"],
	"impassioned": ["IH2 M P AE1 SH AH0 N D"],
	"impasted": ["IH3 M P EY1 S T IH0 D"],
	"impatience": ["IH2 M P EY1 SH AH0 N S"],
	"impatient": ["IH2 M P EY1 SH AH0 N T"],
	"impearled": ["IH0 M P ER1 L D"],
	"impediment": ["IH3 M P EH1 D IH0 M AH0 N T"],
	"impel": ["IH2 M P EH1 L"],
	"impelled": ["IH2 M P EH1 L D"],
	"imperator": ["IH2 M P ER0 AA1 T AO2 R"],
	"imperceptible": ["IH2 M P ER0 S EH1 P T AH0 B AH3 L"],
	"imperceptibly": ["IH2 M P ER0 S EH1 P T AH0 B L IY3"],
	"imperfect": ["IH2 M P ER1 F IH0 K T"],
	"imperfection": ["IH2 M P ER0 F EH1 K SH AH0 N"],
	"imperial": ["IH2 M P IY1 R IY0 AH0 L"],
	"impertinence": ["IH2 M P ER1 T AH0 N AH3 N S"],
	"impetuous": ["IH2 M P EH1 CH W AH0 S", "IH2 M P EH1 CH UH0 AH3 S"],
	"impetus": ["IH1 M P AH0 T AH3 S"],
	"impiety": ["IH0 M P AY1 IH0 T IY3"],
	"implement": ["IH1 M P L AH0 M AH3 N T"],
	"implies": ["IH2 M P L AY1 Z"],
	"implore": ["IH2 M P L AO1 R"],
	"implored": ["IH2 M P L AO1 R D"],
	"imploring": ["IH2 M P L AO1 R IH0 NG"],
	"imply": ["IH2 M P L AY1"],
	"import": ["IH2 M P AO1 R T", "IH1 M P AO2 R T"],
	"importunate": ["IH0 M P AO1 T Y UH0 N AH2 T"],
	"importune": ["IH2 M P AO0 R T UW1 N"],
	"importuned": ["IH2 M P AO0 R T Y UW1 N D"],
	"importunity": ["IH2 M P AO0 R T UW1 N IH0 T IY3"],
	"imposing": ["IH2 M P OW1 Z IH0 NG"],
	"impossibility": ["IH2 M P AO2 S IH0 B IH1 L IH0 T IY3"],
	"impossible": ["IH2 M P AA1 S AH0 B AH0 L"],
	"imposture": ["IH0 M P AA1 S CH ER0"],
	"impotence": ["IH1 M P AH0 T AH2 N S"],
	"impotent": ["IH1 M P AH0 T AH2 N T"],
	"impound": ["IH2 M P AW1 N D"],
	"impoverish": ["IH2 M P AA1 V R IH0 SH"],
	"impregnability": ["IH3 P R EH2 G N AH0 B IH1 L AH0 T IY2"],
	"imprisonment": ["IH2 M P R IH1 Z AH0 N M AH3 N T"],
	"improbable": ["IH2 M P R AA1 B AH0 B AH0 L"],
	"imprudent": ["IH2 M P R UW1 D AH0 N T"],
	"impudent": ["IH1 M P Y AH0 D EH3 N T"],
	"impure": ["IH2 M P Y UH1 R"],
	"impute": ["IH2 M P Y UW1 T"],
	"in": ["IH3 N"],
	"inactive": ["IH2 N AE1 K T IH0 V"],
	"inadequate": ["IH2 N AE1 D IH0 K W AH3 T"],
	"inanimate": ["IH2 N AE1 N AH0 M AH0 T"],
	"inanition": ["IH2 N AH0 N IH1 SH AH0 N"],
	"inaudible": ["IH2 N AO1 D AH0 B AH3 L"],
	"incandescent": ["IH2 N K AH0 N D EH1 S AH0 N T"],
	"incapable": ["IH2 N K EY1 P AH0 B AH3 L"],
	"incarnadined": ["IH0 N K AA1 R N AH0 D AY2 N D"],
	"incense": ["IH2 N S EH1 N S", "IH1 N S EH2 N S"],
	"incertainty": ["IH0 N S ER1 T AH0 N T IY3"],
	"incessantly": ["IH2 N S EH1 S AH0 N T L IY3"],
	"inch": ["IH1 N CH"],
	"incident": ["IH2 N S AH0 D AH3 N T"],
	"incision": ["IH2 N S IH1 ZH AH0 N"],
	"incisive": ["IH2 N S AY1 S IH0 V"],
	"incite": ["IH2 N S AY1 T"],
	"incivility": ["IH2 N S IH0 V IH1 L IH0 T IY3"],
	"incline": ["IH2 N K L AY1 N", "IH1 N K L AY0 N"],
	"inclined": ["IH2 N K L AY1 N D"],
	"inclose": ["IH0 N K L OW1 Z"],
	"include": ["IH2 N K L UW1 D"],
	"included": ["IH2 N K L UW1 D IH0 D"],
	"incognito": ["IH2 N K AO0 G N IY1 T OW0"],
	"income": ["IH1 N K AH2 M"],
	"incomparable": ["IH2 N K AA1 M P ER0 AH0 B AH0 L"],
	"incomplete": ["IH2 N K AH0 M P L IY1 T"],
	"inconceivably": ["IH2 N K AH0 N S IY1 V AH0 B L IY3"],
	"incongruity": ["IH2 NG K AO0 NG R UW1 IH0 T IY2"],
	"inconspicuous": ["IH2 NG K AA1 N S P IH0 K W AH0 S"],
	"increas'd": ["IH0 N K R IY1 S T"],
	"increase": ["IH2 N K R IY1 S", "IH1 N K R IY2 S"],
	"increased": ["IH2 N K R IY1 S T"],
	"increasing": ["IH2 N K R IY1 S IH0 NG"],
	"incredulous": ["IH2 N K R EH1 JH AH0 L AH3 S"],
	"incurred": ["IH2 N K ER1 D"],
	"indebted": ["IH2 N D EH1 T IH0 D"],
	"indeed": ["IH2 N D IY1 D"],
	"indefinite": ["IH2 N D EH1 F AH0 N AH0 T"],
	"independant": ["IH2 N D AH0 P EH1 N D AH0 N T"],
	"independent": ["IH2 N D IH0 P EH1 N D AH0 N T"],
	"index": ["IH1 N D EH0 K S"],
	"india": ["IH1 N D IY0 AH0"],
	"indiaman": ["IH1 N D IY0 AH0 M AE2 N", "IH1 N D Y AH0 M AE2 N"],
	"indian": ["IH1 N D IY0 AH3 N", "IH1 N D Y AH0 N"],
	"indicate": ["IH1 N D IH0 K EY2 T"],
	"indicating": ["IH1 N D AH0 K EY2 T IH0 NG"],
	"indifference": ["IH2 N D IH1 F ER0 AH0 N S", "IH2 N D IH1 F R AH0 N S"],
	"indifferent": ["IH2 N D IH1 F R AH0 N T", "IH2 N D IH1 F ER0 AH0 N T"],
	"indigence": ["IH1 N D AH0 G EH2 N S"],
	"indigest": ["IH2 N D AY0 JH EH1 S T"],
	"indignant": ["IH2 N D IH1 G N AH0 N T"],
	"indignation": ["IH2 N D IH0 G N EY1 SH AH0 N"],
	"indiscreet": ["IH2 N D IH0 S K R IY1 T"],
	"indispensable": ["IH2 N D IH0 S P EH1 N S AH0 B AH0 L"],
	"indistinct": ["IH2 N D IH0 S T IH1 NG K T"],
	"indistinctness": ["IH2 N D IH0 S T IH1 NG K T N AH0 S"],
	"individual": ["IH2 N D AH0 V IH1 JH AH0 W AH0 L"],
	"indolence": ["IH1 N D AH0 L AH2 N S"],
	"induce": ["IH2 N D UW1 S"],
	"induced": ["IH2 N D UW1 S T"],
	"indulgent": ["IH3 N D AH1 L JH AH0 N T"],
	"industries": ["IH1 N D AH0 S T R IY2 Z"],
	"industrious": ["IH2 N D AH1 S T R Y AH0 S", "IH2 N D AH1 S T R IY0 AH3 S"],
	"industriousness": ["IH2 N D AH1 S T R IY0 AH0 S N AH3 S", "IH2 N D AH1 S T R Y AH0 S N AH3 S"],
	"industry": ["IH1 N D AH0 S T R IY3"],
	"inebriate": ["IH2 N EH1 B R IY0 EY2 T"],
	"inert": ["IH2 N ER1 T"],
	"inevitable": ["IH2 N EH1 V IH0 T AH3 B AH0 L"],
	"inez": ["AY1 N EH2 Z"],
	"infamy": ["IH1 N F AH0 M IY3"],
	"infancy": ["IH1 N F AH0 N S IY0"],
	"infant": ["IH1 N F AH0 N T"],
	"infantile": ["IH1 N F AH0 N T AY2 L"],
	"infantine": ["IH1 N F AH0 N T AY2 N"],
	"infantry": ["IH1 N F AH0 N T R IY3"],
	"infect": ["IH2 N F EH1 K T"],
	"infection": ["IH2 N F EH1 K SH AH0 N"],
	"infer": ["IH2 N F ER1"],
	"inference": ["IH1 N F ER0 AH3 N S"],
	"inferior": ["IH0 N F IY1 R Y ER0", "IH2 N F IY1 R IY0 ER3"],
	"inferiour": ["IH0 N F IY1 R Y ER0", "IH2 N F IY1 R IY0 ER3"],
	"inferred": ["IH2 N F ER1 D"],
	"infest": ["IH2 N F EH1 S T"],
	"infidel": ["IH1 N F IH0 D EH2 L"],
	"infinite": ["IH1 N F AH0 N IH3 T"],
	"infiniteness": ["IH1 N F IH0 N IH0 T N AH3 S"],
	"infiniter": ["IH1 N F IH0 N IH0 T ER0"],
	"infinitude": ["IH2 N F IH1 N IH0 T Y UW2 D"],
	"infinity": ["IH2 N F IH1 N IH0 T IY3"],
	"infirmity": ["IH0 N F ER1 M IH0 T IY3"],
	"inflection": ["IH2 N F L EH1 K SH AH0 N"],
	"inflict": ["IH2 N F L IH1 K T"],
	"infliction": ["IH2 N F L IH1 K SH AH0 N"],
	"influence": ["IH1 N F L UW0 AH2 N S"],
	"influences": ["IH1 N F L UW2 AH0 N S IH3 Z"],
	"infold": ["IH0 N F OW1 L D"],
	"inform": ["IH2 N F AO1 R M"],
	"infrequent": ["IH2 N F R IY1 K W AH0 N T"],
	"ingenuous": ["IH2 N JH EH1 N Y UW0 AH3 S", "IH2 N JH EH1 N Y W AH0 S"],
	"inglorious": ["IH2 N G L AO1 R IY0 AH3 S", "IH2 N G L AO1 R Y AH0 S"],
	"ingot": ["IH1 NG G AH0 T"],
	"ingredient": ["IH2 N G R IY1 D Y AH0 N T", "IH2 N G R IY1 D IY0 AH2 N T"],
	"inhabit": ["IH2 N HH AE1 B IH0 T"],
	"inhabitant": ["IH2 N HH AE1 B AH0 T AH3 N T"],
	"inhearse": ["IH0 N HH ER1 S"],
	"inhere": ["IH2 N HH IY1 R"],
	"inhered": ["IH0 N HH IY1 R D"],
	"inherent": ["IH0 N HH IY1 R AH0 N T"],
	"inherit": ["IH2 N HH EH1 R AH0 T"],
	"inheritance": ["IH2 N HH EH1 R AH0 T AH0 N S"],
	"inheriting": ["IH2 N HH EH1 R AH0 T IH3 NG"],
	"inheritor": ["IH2 N HH EH1 R AH0 T ER2"],
	"iniquity": ["IH2 N IH1 K W IH0 T IY3"],
	"initial": ["IH2 N IH1 SH AH0 L"],
	"injur'd": ["IH1 N JH ER0 D"],
	"injure": ["IH1 N JH ER0"],
	"injured": ["IH1 N JH ER0 D"],
	"injuries": ["IH1 N JH ER0 IY2 Z"],
	"injury": ["IH1 N JH ER0 IY3"],
	"ink": ["IH1 NG K"],
	"inlaid": ["IH1 N L EY2 D"],
	"inland": ["IH1 N L AE2 N D"],
	"inmate": ["IH1 N M EY2 T"],
	"inn": ["IH1 N"],
	"innocence": ["IH1 N AH0 S AH3 N S"],
	"innocent": ["IH1 N AH0 S AH3 N T"],
	"innuendo": ["IH2 N Y UW0 EH1 N D OW0"],
	"innumerable": ["IH2 N UW1 M R AH0 B AH2 L"],
	"inquest": ["IH1 NG K W EH2 S T"],
	"inquire": ["IH3 N K W AY1 R"],
	"inquired": ["IH3 N K W AY1 ER0 D"],
	"inquisitor": ["IH3 N K W IH1 Z AH0 T ER3"],
	"insanity": ["IH2 N S AE1 N IH0 T IY3"],
	"inscribe": ["IH2 N S K R AY1 B"],
	"inscription": ["IH3 N S K R IH1 P SH AH0 N"],
	"inscrutable": ["IH2 N S K R UW1 T AH0 B AH2 L"],
	"insect": ["IH1 N S EH2 K T"],
	"insecure": ["IH1 N S AH0 K Y ER3"],
	"insensate": ["IH0 N S EH1 N S EY0 T"],
	"insert": ["IH2 N S ER1 T"],
	"insight": ["IH1 N S AY2 T"],
	"insincere": ["IH2 N S IH0 N S IY1 R"],
	"insipid": ["IH2 N S IH1 P IH0 D"],
	"insolence": ["IH1 N S AH0 L AH2 N S"],
	"inspect": ["IH2 N S P EH1 K T"],
	"inspection": ["IH2 N S P EH1 K SH AH0 N"],
	"insphere": ["IH0 N S F IY1 R"],
	"inspiration": ["IH2 N S P ER0 EY1 SH AH0 N"],
	"inspire": ["IH2 N S P AY1 R", "IH2 N S P AY1 ER0"],
	"inspired": ["IH2 N S P AY1 R D", "IH2 N S P AY1 ER0 D"],
	"inspiriting": ["IH2 N S P IY1 R AH0 T IH3 NG"],
	"instability": ["IH2 N S T AH0 B IH1 L IH0 T IY2"],
	"instance": ["IH1 N S T AH0 N S"],
	"instant": ["IH1 N S T AH0 N T"],
	"instead": ["IH2 N S T EH1 D"],
	"instinct": ["IH1 N S T IH0 NG K T", "IH0 N S T IH1 NG K T"],
	"institute": ["IH1 N S T AH0 T UW2 T"],
	"institution": ["IH2 N S T IH0 T UW1 SH AH0 N"],
	"institutional": ["IH2 N S T IH0 T UW1 SH AH0 N AH0 L"],
	"instruct": ["IH2 N S T R AH1 K T"],
	"instruction": ["IH2 N S T R AH1 K SH AH0 N"],
	"instrument": ["IH1 N S T R AH0 M AH0 N T"],
	"insufficiencies": ["IH2 N S AH0 F IH1 SH AH0 N S IY2 Z"],
	"insufficient": ["IH2 N S AH0 F IH1 SH AH0 N T"],
	"insult": ["IH2 N S UH1 L T", "IH1 N S UH0 L T"],
	"insure": ["IH2 N SH UH1 R"],
	"intact": ["IH2 N T AE1 K T"],
	"integral": ["IH1 N T AH0 G R AH3 L", "IH2 N T EH1 G R AH0 L"],
	"intellect": ["IH1 N T AH0 L EH2 K T"],
	"intellectual": ["IH2 N T AH0 L EH1 K CH UW0 AH2 L"],
	"intelligence": ["IH2 N T EH1 L AH0 JH AH0 N S"],
	"intend": ["IH2 N T EH1 N D"],
	"intense": ["IH2 N T EH1 N S"],
	"intensified": ["IH0 N T EH1 N S AH0 F AY2 D"],
	"intent": ["IH2 N T EH1 N T"],
	"intention": ["IH2 N T EH1 N SH AH0 N"],
	"intentions": ["IH2 N T EH1 N CH AH0 N Z"],
	"intents": ["IH0 N T EH1 N T S"],
	"inter": ["IH2 N T ER1"],
	"interbreathed": ["IH2 N T ER0 B R IY1 DH D"],
	"interbring": ["IH2 N T ER0 B R IH1 NG"],
	"intercede": ["IH2 N T ER0 S IY1 D"],
	"interchange": ["IH2 N T ER0 CH EY1 N JH"],
	"interdict": ["IH1 N T ER0 D IH2 K T"],
	"interest": ["IH1 N T R AH0 S T", "IH1 N T ER0 AH3 S T"],
	"interests": ["IH1 N T R AH0 S T S", "IH1 N T ER0 AH3 S T S"],
	"interfere": ["IH2 N T ER0 F IY1 R"],
	"interflowings": ["IH2 N T ER0 F L OW1 IH0 NG Z"],
	"interfuse": ["IH2 N T ER0 F Y UW1 Z"],
	"interior": ["IH2 N T IY1 R IY0 ER3"],
	"interknit": ["IH2 N T ER0 N IH1 T"],
	"interlace": ["IH2 N T ER0 L EY1 S"],
	"interlocutor": ["IH2 N T ER0 L AA1 K Y AH0 T ER3"],
	"intermission": ["IH2 N T ER0 M IH1 SH AH0 N"],
	"intermit": ["IH2 N T ER0 M IH1 T"],
	"intermitted": ["IH2 N T ER0 M IH1 T IH0 D"],
	"intermittent": ["IH2 N T ER0 M IH1 T AH0 N T"],
	"intermix": ["IH2 N T ER0 M IH1 K S"],
	"internal": ["IH2 N T ER1 N AH0 L"],
	"interpose": ["IH2 N T ER0 P OW1 Z"],
	"interpret": ["IH2 N T ER1 P R AH0 T"],
	"interrogate": ["IH2 N T EH1 R AH0 G EY2 T"],
	"interrupt": ["IH2 N T ER0 AH1 P T"],
	"interruption": ["IH2 N T ER0 AH1 P SH AH0 N"],
	"interspersed": ["IH2 N T ER0 S P ER1 S T"],
	"interspersion": ["IH2 N T ER0 S P ER1 ZH AH0 N"],
	"interval": ["IH1 N T ER0 V AH3 L"],
	"intervene": ["IH2 N T ER0 V IY1 N"],
	"intervened": ["IH2 N T ER0 V IY1 N D"],
	"interview": ["IH1 N T ER0 V Y UW2"],
	"interwoven": ["IH2 N T ER0 W OW1 V AH0 N"],
	"intimate": ["IH1 N T AH0 M AH2 T", "IH1 N T AH0 M EY2 T"],
	"into": ["IH1 N T UW0", "IH0 N T UW1"],
	"intoxicate": ["IH2 N T AA1 K S IH0 K EY2 T"],
	"intoxication": ["IH2 N T AA2 K S AH0 K EY1 SH AH0 N"],
	"intreat": ["IH0 N T R IY1 T"],
	"intricate": ["IH1 N T R IH0 K AH3 T"],
	"intrigue": ["IH1 N T R IY0 G", "IH0 N T R IY1 G"],
	"intrinsic": ["IH2 N T R IH1 N S IH0 K"],
	"introduce": ["IH2 N T R AH0 D UW1 S"],
	"introduced": ["IH2 N T R AH0 D UW1 S T"],
	"introduction": ["IH2 N T R AH0 D AH1 K SH AH0 N"],
	"intrude": ["IH2 N T R UW1 D"],
	"intuition": ["IH2 N T UW0 IH1 SH AH0 N"],
	"inured": ["IH2 N Y UH1 R D"],
	"inurned": ["IH0 N ER1 N D"],
	"invade": ["IH2 N V EY1 D"],
	"invent": ["IH2 N V EH1 N T"],
	"invention": ["IH2 N V EH1 N SH AH0 N"],
	"invest": ["IH2 N V EH1 S T"],
	"investigated": ["IH2 N V EH1 S T AH0 G EY2 T IH0 D"],
	"investigating": ["IH2 N V EH1 S T IH0 G EY2 T IH0 NG"],
	"invigorate": ["IH3 N V IH1 G ER0 EY2 T"],
	"invincible": ["IH2 N V IH1 N S AH0 B AH0 L"],
	"inviolable": ["IH2 N V AY1 AH0 L AH0 B AH0 L"],
	"invisibility": ["IH2 N V IH2 Z AH0 B IH1 L AH0 T IY3"],
	"invisible": ["IH2 N V IH1 Z AH0 B AH3 L"],
	"invisibly": ['IH2 N V IH1 Z AH0 B L IY3'],
	"invitation": ["IH2 N V IH0 T EY1 SH AH0 N"],
	"invite": ["IH2 N V AY1 T"],
	"invited": ["IH2 N V AY1 T AH0 D"],
	"inviting": ["IH2 N V AY1 T IH0 NG"],
	"invocate": ["IH2 N V AH0 K EY1 T"],
	"invoke": ["IH2 N V OW1 K"],
	"involve": ["IH2 N V AA1 L V"],
	"involved": ["IH2 N V AA1 L V D"],
	"invulnerable": ["IH2 N V AH1 L N ER0 AH0 B AH2 L"],
	"inward": ["IH1 N W ER0 D"],
	"io": ["AY1 OW0"],
	"iodine": ["AY1 AH0 D AY2 N"],
	"ire": ["AY1 R", "AY1 ER0"],
	"iris": ["AY1 R IH0 S"],
	"iron": ["AY1 ER0 N"],
	"irresolute": ["IH0 R EH2 Z AH0 L UW1 T"],
	"irresponsive": ["IH2 R IH0 S P AA1 N S IH0 V"],
	"irritated": ["IY1 R AH0 T EY2 T IH0 D"],
	"is": ["IH1 Z"],
	"ishmael": ["IH1 SH M EY0 L"],
	"island": ["AY1 L AH0 N D"],
	"isle": ["AY1 L"],
	"isn't": ["IH1 Z AH0 N T"],
	"isolate": ["AY1 S AH0 L EY2 T"],
	"israel": ["IH1 Z R EY0 L", "IH1 Z R IY0 AH0 L"],
	"issue": ["IH1 SH Y UW0"],
	"it": ["IH3 T","IH1 T"],
	"it's": ["IH1 T S"],
	"italic": ["AY0 T AE1 L IH0 K"],
	"italicized": ["IH0 T AE1 L AH0 S AY2 Z D"],
	"italy": ["IH1 T AH0 L IY3"],
	"iterance": ["IH1 T ER0 AH3 N S"],
	"its": ["IH1 T S"],
	"itself": ["IH2 T S EH1 L F"],
	"ivory": ["AY1 V ER0 IY3", "AY1 V R IY0"],
	"ivy": ["AY1 V IY0"],
	"jacket": ["JH AE1 K IH0 T"],
	"jacob": ["JH EY1 K AH0 B"],
	"jade": ["JH EY1 D"],
	"jag": ["JH AE1 G"],
	"jagged": ["JH AE1 G IH0 D"],
	"jail": ["JH EY1 L"],
	"james": ["JH EY1 M Z"],
	"january": ["JH AE1 N Y UW0 EH2 R IY0"],
	"jar": ["JH AA1 R"],
	"jargoning": ["JH AA1 R G AH0 N IH2 NG"],
	"jason": ["JH EY1 S AH0 N"],
	"jasper": ["JH AE1 S P ER0"],
	"jaw": ["JH AO1"],
	"jay": ["JH EY1"],
	"jealous": ["JH EH1 L AH0 S"],
	"jealousy": ["JH EH1 L AH0 S IY3"],
	"jeer": ["JH IY1 R"],
	"jehovah": ["JH AH0 HH OW1 V AH0"],
	"jelly": ["JH EH1 L IY0"],
	"jenny": ["JH EH1 N IY0"],
	"jeopardize": ["JH EH1 P ER0 D AY2 Z"],
	"jeopardy": ["JH EH1 P ER0 D IY3"],
	"jerusalem": ["JH ER0 UW1 S AH0 L AH3 M"],
	"jervis": ["JH ER1 V IH0 S"],
	"jessamines": ["JH EH1 S AH0 M IH2 N"],
	"jest": ["JH EH1 S T"],
	"jesus": ["JH IY1 Z AH0 S"],
	"jew": ["JH UW1"],
	"jewel": ["JH UW1 AH0 L", "JH UW1 L"],
	"jewell'd": ["JH UW1 AH0 L D", "JH UW1 L D"],
	"jewels": ["JH UW1 AH0 L Z", "JH UW1 L Z"],
	"jib": ["JH IH1 B"],
	"jig": ["JH IH1 G"],
	"job": ["JH AA1 B", "JH OW1 B"],
	"job's": ["JH AA1 B Z", "JH OW1 B Z"],
	"jocoser": ["JH AO2 K OW1 S ER0"],
	"jocund": ["J AA1 K AH0 N D"],
	"jog": ["JH AA1 G"],
	"jogged": ["JH AA1 G D"],
	"joggle": ["JH AA1 G AH0 L"],
	"jogjog": ["JH AA1 G JH AA1 G"],
	"john": ["JH AA1 N"],
	"join": ["JH OY1 N"],
	"joint": ["JH OY1 N T"],
	"jollity": ["JH AA1 L IH0 T IY3"],
	"jolly": ["JH AA1 L IY0"],
	"jolt": ["JH OW1 L T"],
	"jonah": ["JH OW1 N AH0"],
	"jonathan": ["JH AA1 N AH0 TH AH2 N"],
	"jordan": ["JH AO1 R D AH0 N"],
	"jose's": ["HH OW2 Z EY1 Z"],
	"jostle": ["JH AA1 S AH0 L"],
	"jot": ["JH AA1 T"],
	"journey": ["JH ER1 N IY0"],
	"jove": ["JH OW1 V"],
	"joy": ["JH OY1"],
	"joyful": ["JH OY1 F AH0 L"],
	"juan": ["H W AA1 N", "JH UW1 W AH0 N"],
	"juan's": ["H W AA1 N Z", "JH UW1 AH0 N Z"],
	"jubilee": ["JH UW1 B AH0 L IY2", "JH UW2 B AH0 L IY1"],
	"judea": ["JH UW0 D IY1 AH0"],
	"judge": ["JH AH1 JH"],
	"judged": ["JH AH1 JH D"],
	"judgment": ["JH AH1 JH M AH0 N T"],
	"judicious": ["JH UW0 D IH1 SH AH0 S"],
	"juggler": ["JH AH1 G L ER0"],
	"jugular": ["JH UW1 G Y AH0 L ER3"],
	"juice": ["JH UW1 S"],
	"juicy": ["JH UW1 S IY0"],
	"julep": ["JH UW1 L AH0 P"],
	"julia": ["JH UW1 L Y AH0", "JH UW1 L IY0 AH3"],
	"juliet": ["JH UW2 L IY0 EH1 T", "JH UW1 L Y EH0 T"],
	"july": ["JH UH0 L AY1"],
	"jumbled": ["JH AH1 M B AH0 L D"],
	"jump": ["JH AH1 M P"],
	"junction": ["JH AH1 NG K SH AH0 N"],
	"june": ["JH UW1 N"],
	"junior": ["JH UW1 N Y ER0"],
	"juno": ["JH UW1 N OW0"],
	"just": ["JH AH1 S T"],
	"justice": ["JH AH1 S T IH0 S"],
	"justified": ["JH AH1 S T AH0 F AY2 D"],
	"justify": ["JH AH1 S T AH0 F AY2"],
	"jut": ["JH AH1 T"],
	"juventa": ["Y UW0 V EH1 N T AH0"],
	"j\u00f3se": ["HH OW1 Z EY0"],
	"kaiser": ["K AY1 Z ER0"],
	"kaleidoscope": ["K AH0 L AY1 D AH0 S K OW2 P"],
	"keel": ["K IY1 L"],
	"keen": ["K IY1 N"],
	"keep": ["K IY1 P"],
	"keepsake": ["K IY1 P S EY2 K"],
	"ken": ["K EH1 N"],
	"kenned": ["K EH1 N D"],
	"kennel": ["K EH1 N AH0 L"],
	"kept": ["K EH1 P T"],
	"kerchief": ["K ER1 CH AH0 F"],
	"kernel": ["K ER1 N AH0 L"],
	"key": ["K IY1"],
	"kick": ["K IH1 K"],
	"kid": ["K IH1 D"],
	"kidd": ["K IH1 D"],
	"kidderminster": ["K IH1 D ER0 M IH2 N S T ER0"],
	"kill": ["K IH1 L"],
	"kin": ["K IH1 N"],
	"kind": ["K AY1 N D"],
	"kindle": ["K IH1 N D AH0 L"],
	"kindred": ["K IH1 N D R IH0 D"],
	"kine": ["K AY1 N"],
	"king": ["K IH1 NG"],
	"kingdom": ["K IH1 NG D AH0 M"],
	"kinsman": ["K IH1 N Z M AE2 N"],
	"kinsmanship": ["K IH1 N S M AH0 N SH IH2 P"],
	"kinsmen": ["K IH1 N Z M EH0 N"],
	"kirk": ["K ER1 K"],
	"kirtle": ["K ER1 T AH0 L"],
	"kiss": ["K IH1 S"],
	"kist": ["K IH1 S T"],
	"kitchen": ["K IH1 CH AH0 N"],
	"knead": ["N IY1 D"],
	"knee": ["N IY1"],
	"kneel": ["N IY1 L"],
	"knell": ["N EH1 L"],
	"knelt": ["N EH1 L T"],
	"knew": ["N UW1"],
	"knife": ["N AY1 F"],
	"knight": ["N AY1 T"],
	"knit": ["N IH1 T"],
	"knitting": ["N IH1 T IH0 NG"],
	"knock": ["N AA1 K"],
	"knoll": ["N OW1 L"],
	"knot": ["N AA1 T"],
	"know": ["N OW1"],
	"knowledge": ["N AA1 L IH0 JH"],
	"known": ["N OW1 N"],
	"labor": ["L EY1 B ER0"],
	"labour": ["L EY1 B ER0"],
	"labyrinthine": ["L AE2 B ER0 IH1 N TH IY2 N"],
	"lace": ["L EY1 S"],
	"lacerating": ["L AE1 S ER0 EY2 T IH0 NG"],
	"lack": ["L AE1 K"],
	"laconic": ["L AH0 K AA1 N IH0 K"],
	"lad": ["L AE1 D"],
	"ladder": ["L AE1 D ER0"],
	"laden": ["L EY1 D AH0 N"],
	"ladies": ["L EY1 D IY0 Z"],
	"lady": ["L EY1 D IY0"],
	"lafayette": ["L AA2 F IY0 EH1 T"],
	"lag": ["L AE1 G"],
	"lagging": ["L AE1 G IH0 NG"],
	"laid": ["L EY1 D"],
	"lain": ["L EY1 N"],
	"lair": ["L EH1 R"],
	"lake": ["L EY1 K"],
	"lamb": ["L AE1 M"],
	"lame": ["L EY1 M"],
	"lament": ["L AH0 M EH1 N T"],
	"lamentation": ["L AE2 M AH0 N T EY1 SH AH0 N"],
	"lamp": ["L AE1 M P"],
	"lamplight": ["L AE1 M P L AY2 T"],
	"lance": ["L AE1 N S"],
	"lancelot": ["L AE1 N S AH0 L AA2 T", "L AE1 N S L AA2 T"],
	"land": ["L AE1 N D"],
	"landings": ["L AE1 N D IH0 NG Z"],
	"landlord": ["L AE1 N D L AO2 R D"],
	"landlubber": ["L AE1 N D L AH2 B ER0"],
	"landscape": ["L AE1 N D S K EY2 P"],
	"landslip": ["L AE1 N D S L IH0 P"],
	"lane": ["L EY1 N"],
	"language": ["L AE1 NG G W IH0 JH"],
	"languid": ["L AE1 NG G W IH0 D"],
	"languish": ["L AE1 NG G W IH0 SH"],
	"languishment": ["L AE1 NG G W IH0 SH M AH2 N T"],
	"languor": ["L AE1 NG G ER0"],
	"lank": ["L AE1 NG K"],
	"lap": ["L AE1 P"],
	"lapland": ["L AE1 P L AE2 N D"],
	"lapped": ["L AE1 P T"],
	"lapse": ["L AE1 P S"],
	"lapwing": ["L AE1 P W IH0 NG"],
	"larceny": ["L AA1 R S AH0 N IY3"],
	"larch": ["L AA1 R CH"],
	"larder": ["L AA1 R D ER0"],
	"large": ["L AA1 R JH"],
	"larger": ["L AA1 R JH ER0"],
	"largess": ["L AA2 R JH EH1 S"],
	"largesse": ["L AA2 R JH EH1 S"],
	"largest": ["L AA1 R JH IH0 S T"],
	"lark": ["L AA1 R K"],
	"lash": ["L AE1 SH"],
	"last": ["L AE1 S T"],
	"latch": ["L AE1 CH"],
	"late": ["L EY1 T"],
	"latent": ["L EY1 T AH0 N T"],
	"later": ["L EY1 T ER0"],
	"latest": ["L EY1 T AH0 S T"],
	"latian": ["L EY1 SH AH0 N"],
	"latitude": ["L AE1 T AH0 T UW2 D"],
	"latium": ["L EY1 SH UH0 M"],
	"latmian": ["L AE1 T M IY0 AE2 N"],
	"latona": ["L AH0 T OW1 N AH0"],
	"latter": ["L AE1 T ER0"],
	"lattice": ["L AE1 T AH0 S"],
	"laugh": ["L AE1 F"],
	"laughter": ["L AE1 F T ER0"],
	"launch": ["L AO1 N CH"],
	"laureate": ["L AO1 R IY0 AH3 T", "L AO1 R Y AH0 T"],
	"laureates": ["L AO1 R IY0 AH0 T S", "L AO1 R Y AH0 T S"],
	"laurel": ["L AO1 R AH0 L"],
	"lave": ["L EY1 V"],
	"laving": ["L EY1 V IH0 NG"],
	"lavinian": ["L AH0 V IH1 N IY0 AH3 N", "L AH0 V IH1 N Y AH0 N"],
	"law": ["L AO1"],
	"lawful": ["L AO1 F UH0 L"],
	"lawn": ["L AO1 N"],
	"lay": ["L EY1"],
	"le": ["L UH0"],
	"lea": ["L IY1"],
	"lead": ["L EH1 D", "L IY1 D"],
	"leaden": ["L EH1 D AH0 N"],
	"leader": ["L IY1 D ER0"],
	"leads": ["L IY1 D Z"],
	"leaf": ["L IY1 F"],
	"leaflets": ["L IY1 F L AH0 T S"],
	"league": ["L IY1 G"],
	"leak": ["L IY1 K"],
	"lean": ["L IY1 N"],
	"leant": ["L IY1 N T"],
	"leap": ["L IY1 P"],
	"leaped": ["L EH1 P T", "L IY1 P T"],
	"leapt": ["L EH1 P T"],
	"lear": ["L IY1 R"],
	"learn": ["L ER1 N"],
	"learned": ["L ER1 N D", "L ER1 N IH0 D"],
	"learnt": ["L ER1 N T"],
	"lease": ["L IY1 S"],
	"least": ["L IY1 S T"],
	"leather": ["L EH1 DH ER0"],
	"leave": ["L IY1 V"],
	"leaven": ["L EH1 V AH0 N"],
	"leaving": ["L IY1 V IH0 NG"],
	"lecture": ["L EH1 K CH ER0"],
	"led": ["L EH1 D"],
	"ledge": ["L EH1 JH"],
	"lee": ["L IY1"],
	"leech": ["L IY1 CH"],
	"left": ["L EH1 F T"],
	"leg": ["L EH1 G"],
	"legacies": ["L EH1 G AH0 S IY0 Z"],
	"legacy": ["L EH1 G AH0 S IY0"],
	"legend": ["L EH1 JH AH0 N D"],
	"leghorn": ["L EH1 G HH AO0 R N"],
	"legible": ["L EH1 JH IH0 B AH3 L"],
	"legion": ["L IY1 JH AH0 N"],
	"legitimate": ["L AH0 JH IH1 T AH0 M AH3 T"],
	"leisure": ["L EH1 ZH ER0", "L IY1 ZH ER0"],
	"lend": ["L EH1 N D"],
	"length": ["L EH1 NG K TH"],
	"lengthening": ["L EH1 NG TH AH0 N IH3 NG", "L EH1 NG TH N IH0 NG"],
	"lenore": ["L AH0 N AO1 R"],
	"lens": ["L EH1 N Z"],
	"lent": ["L EH1 N T"],
	"leoline": ["L IY1 AH0 L AY2 N"],
	"leontodon": ["L EY0 AA1 N T AH0 D AA2 N"],
	"leopard": ["L EH1 P ER0 D"],
	"leprosy": ["L EH1 P R AH0 S IY2"],
	"less": ["L EH1 S"],
	"lesson": ["L EH1 S AH0 N"],
	"lest": ["L EH1 S T"],
	"let": ["L EH1 T"],
	"lethargic": ["L AH0 TH AA1 R JH IH0 K"],
	"lethargy": ["L EH1 TH ER0 JH IY2"],
	"lethe": ["L IY1 TH IY0"],
	"letter": ["L EH1 T ER0"],
	"letting": ["L EH1 T IH0 NG"],
	"lettres": ["L EH1 T R AH0 Z"],
	"lettuce": ["L EH1 T AH0 S"],
	"levant": ["L AH0 V AE1 N T"],
	"levante": ["L AH0 V AE1 N T IY0"],
	"level": ["L EH1 V AH0 L"],
	"levelling": ["L EH1 V AH0 L IH2 NG"],
	"lever": ["L EH1 V ER0", "L IY1 V ER0"],
	"leviathan": ["L AH0 V AY1 AH0 TH AH0 N"],
	"lewd": ["L UW1 D"],
	"lexicon": ["L EH1 K S IH0 K AA2 N"],
	"libation": ["L AY2 B EY1 SH AH0 N"],
	"liberal": ["L IH1 B ER2 AH0 L"],
	"liberty": ["L IH1 B ER0 T IY2"],
	"library": ["L AY1 B R EH0 R IY0"],
	"libyan": ["L IH1 B Y AH0 N"],
	"licence": ["L AY1 S AH0 N S"],
	"license": ["L AY1 S AH0 N S"],
	"lick": ["L IH1 K"],
	"lid": ["L IH1 D"],
	"lie": ["L AY1"],
	"liege": ["L IY1 JH"],
	"lies": ["L AY1 Z"],
	"life": ["L AY1 F"],
	"lifetime": ["L AY1 F T AY2 M"],
	"lift": ["L IH1 F T"],
	"ligeia": ["L AY2 JH IH1 AH0"],
	"light": ["L AY1 T"],
	"lighten": ["L AY1 T AH0 N"],
	"lightning": ["L AY1 T N IH0 NG"],
	"like": ["L AY1 K"],
	"liking": ["L AY1 K IH0 NG"],
	"lilies": ["L IH1 L IY0 Z"],
	"lily": ["L IH1 L IY0"],
	"lilypetal": ["L IH1 L IY0 P EH1 T AH0 L"],
	"limb": ["L IH1 M"],
	"lime": ["L AY1 M"],
	"limit": ["L IH1 M AH0 T"],
	"limp": ["L IH1 M P"],
	"lincoln": ["L IH1 NG K AH0 N"],
	"line": ["L AY1 N"],
	"lineal": ["L IH1 N IY0 AH0 L"],
	"lined": ["L AY1 N D"],
	"linger": ["L IH1 NG G ER0"],
	"lining": ["L AY1 N IH0 NG"],
	"link": ["L IH1 NG K"],
	"linnet": ["L IH1 N IH0 T"],
	"lion": ["L AY1 AH0 N"],
	"lip": ["L IH1 P"],
	"lipstick": ["L IH1 P S T IH2 K"],
	"liquid": ["L IH1 K W IH0 D"],
	"liquor": ["L IH1 K ER0"],
	"lisp": ["L IH1 S P"],
	"lispers": ["L IH1 S P ER0 Z"],
	"list": ["L IH1 S T"],
	"list'ning": ["L IH1 S N IH0 NG"],
	"listen": ["L IH1 S AH0 N"],
	"listening": ["L IH1 S AH0 N IH3 NG", "L IH1 S N IH0 NG"],
	"listlessness": ["L IH1 S T L AH0 S N AH2 S"],
	"lit": ["L IH1 T"],
	"litigant": ["L IH1 T AH0 G AH3 N T"],
	"littered": ["L IH1 T ER0 D"],
	"little": ["L IH1 T AH0 L"],
	"liturgies": ["L IH1 T AH0 JH IY2 Z"],
	"live": ["L IH1 V", "L AY1 V"],
	"lived": ["L IH1 V D"],
	"livelong": ["L IH1 V L AA2 NG"],
	"liver": ["L IH1 V ER0"],
	"livery": ["L IH1 V ER0 IY3"],
	"lives": ["L IH1 V Z", "L AY1 V Z"],
	"livid": ["L IH1 V IH0 D"],
	"living": ["L IH1 V IH0 NG"],
	"lo": ["L OW1"],
	"loach": ["L OW1 CH"],
	"load": ["L OW1 D"],
	"loaden": ["L OW1 D AH0 N"],
	"loaf": ["L OW1 F"],
	"loan": ["L OW1 N"],
	"loathe": ["L OW1 DH"],
	"loathed": ["L OW1 DH D"],
	"loaves": ["L OW1 V Z"],
	"locality": ["L OW0 K AE1 L AH0 T IY2"],
	"location": ["L OW0 K EY1 SH AH0 N"],
	"lock": ["L AA1 K"],
	"lodge": ["L AA1 JH"],
	"lodged": ["L AA1 JH D"],
	"lodging": ["L AA1 JH"],
	"lodgment": ["L AA1 JH M AH0 N T"],
	"lofty": ["L AO1 F T IY0"],
	"log": ["L AO1 G"],
	"logarithm": ["L AO1 G AH0 R IH2 TH AH0 M", "L AO1 G AH0 R IH2 TH M"],
	"logician": ["L OW0 JH IH1 SH AH0 N"],
	"loiter": ["L OY1 T ER0"],
	"loll": ["L AA1 L"],
	"lombard": ["L AA1 M B AA0 R D"],
	"lombardy": ["L AA1 M B ER0 D IY3"],
	"london": ["L AH1 N D AH0 N"],
	"lone": ["L OW1 N"],
	"lonelier": ["L OW1 N L IY0 ER0", "L OW1 N L Y ER0"],
	"loneliness": ["L OW1 N L IY0 N AH3 S"],
	"lonely": ["L OW1 N L IY0"],
	"lonesome": ["L OW1 N S AH0 M"],
	"long": ["L AO1 NG"],
	"longcheated": ["L AA2 NG CH IY1 T IH0 D"],
	"longevity": ["L AO0 N JH EH1 V AH0 T IY3"],
	"longings": ["L AO1 NG IH0 NG Z"],
	"look": ["L UH1 K"],
	"loom": ["L UW1 M"],
	"loon": ["L UW1 N"],
	"loop": ["L UW1 P"],
	"loose": ["L UW1 S"],
	"loosed": ["L UW1 S T"],
	"lope": ["L OW1 P"],
	"lord": ["L AO1 R D"],
	"lordliest": ["L AO1 D L IY0 IH0 S T"],
	"lore": ["L AO1 R"],
	"loria": ["L AO1 R IY0 AH2"],
	"lorn": ["L AO1 R N"],
	"lose": ["L UW1 Z"],
	"losing": ["L UW1 Z IH0 NG"],
	"loss": ["L AO1 S"],
	"lost": ["L AO1 S T"],
	"lot": ["L AA1 T"],
	"lote": ["L OW1 T"],
	"loth": ["L OW1 TH"],
	"loud": ["L AW1 D"],
	"louis": ["L UW1 IH0 S"],
	"louse": ["L AW1 S"],
	"lov'd": ["L AH1 V D"],
	"love": ["L AH1 V"],
	"loved": ["L AH1 V D"],
	"loveliest": ["L AH1 V L IY0 IH2 S T"],
	"loveliness": ["L AH1 V L IY0 N AH2 S"],
	"lovelorn": ["L AH1 V L AO2 R N"],
	"lovely": ["L AH1 V L IY0"],
	"lover": ["L AH1 V ER0"],
	"lovest": ["L AH1 V AH0 S T"],
	"loving": ["L AH1 V IH0 NG"],
	"low": ["L OW1"],
	"lower": ["L OW1 ER0"],
	"lowering": ["L OW1 ER0 IH2 NG", "L OW1 R IH0 NG"],
	"lowest": ["L OW1 AH0 S T"],
	"lowlier": ["L OW1 L IY0 ER3", "L OW1 L Y ER0"],
	"lowly": ["L OW1 L IY0"],
	"luck": ["L AH1 K"],
	"luckily": ["L AH1 K AH0 L IY3"],
	"lull": ["L AH1 L"],
	"lullabies": ["L AH1 L AH0 B AY2 Z"],
	"lullaby": ["L AH1 L AH0 B AY2"],
	"lumberjack": ["L AH1 M B ER0 JH AE2 K"],
	"lump": ["L AH1 M P"],
	"lunacy": ["L UW1 N AH0 S IY3"],
	"luncheon": ["L AH1 N CH AH0 N"],
	"lung": ["L AH1 NG"],
	"lure": ["L UH1 R"],
	"lurk": ["L ER1 K"],
	"lust": ["L AH1 S T"],
	"lustre": ["L AH1 S T ER0"],
	"lustrous": ["L AH1 S T R AH0 S"],
	"lute": ["L UW1 T"],
	"luxuriantly": ["L AH0 G ZH UH1 R Y AH0 N T L IY3"],
	"luxuries": ["L AH1 G ZH ER0 IY3 Z"],
	"luxurious": ["L AH0 G ZH ER1 IY0 AH3 S", "L AH0 G ZH ER1 Y AH0 S"],
	"luxury": ["L AH1 G ZH ER0 IY3", "L AH1 G ZH R IY0"],
	"lybian": ["L IH1 B IY0 AH3 N", "L LIH1 B Y AH0 N"],
	"lycean": ["L AY0 S IY1 AH0 N"],
	"lycus": ["L AY1 K UH0 S"],
	"lying": ["L AY1 IH0 NG"],
	"lyons": ["L AY1 AH0 N Z"],
	"lyre": ["L AY1 R"],
	"macassar": ["M AH0 K AE1 S ER0"],
	"mace": ["M EY1 S"],
	"macedon": ["M AE2 S IH0 D AA1 N"],
	"machine": ["M AH0 SH IY1 N"],
	"machinery": ["M AH0 SH IY1 N ER0 IY3"],
	"mad": ["M AE1 D"],
	"madam": ["M AE1 D AH0 M"],
	"madder": ["M AE1 D ER0"],
	"maddest": ["M AE1 D IH0 S T"],
	"made": ["M EY1 D"],
	"madest": ["M AE1 D IH0 S T"],
	"madonna": ["M AH0 D AA1 N AH0"],
	"maelstrom": ["M EY1 L S T R AH0 M"],
	"magic": ["M AE1 JH IH0 K"],
	"magician": ["M AH0 JH IH1 SH AH0 N"],
	"magistrate": ["M AE1 JH AH0 S T R EY2 T"],
	"magnanimity": ["M AE2 G N AH0 N IH1 M AH0 T IY2"],
	"magnanimous": ["M AE0 G N AE1 N AH0 M AH2 S"],
	"magnetize": ["M AE1 G N AH0 T AY2 Z"],
	"magnificat": ["M AE0 G N IH1 F IH0 K AE0 T"],
	"magnificence": ["M AE0 G N IH1 F IH0 S AH2 N S"],
	"magnificent": ["M AE0 G N IH1 F AH0 S AH2 N T"],
	"magnified": ["M AE1 G N AH0 F AY2 D"],
	"magnifies": ["M AE1 G N AH0 F AY2 Z"],
	"magnitude": ["M AE1 G N AH0 T UW2 D"],
	"maid": ["M EY1 D"],
	"maiden": ["M EY1 D AH0 N"],
	"maidenhood": ["M EY1 D AH0 N HH UH2 D"],
	"mail": ["M EY1 L"],
	"maim": ["M EY1 M"],
	"main": ["M EY1 N"],
	"maintain": ["M EY2 N T EY1 N"],
	"maise": ["M EY1 Z"],
	"maize": ["M EY1 Z"],
	"majestic": ["M AH0 JH EH1 S T IH0 K"],
	"majesty": ["M AE1 JH AH0 S T IY2"],
	"majority": ["M AH0 JH AO1 R AH0 T IY3"],
	"mak'st": ["M EY1 K S T"],
	"make": ["M EY1 K"],
	"maker": ["M EY1 K ER0"],
	"maketh": ["M EY1 K AH0 TH"],
	"making": ["M EY1 K IH0 NG"],
	"malady": ["M AE1 L AH0 D IY3"],
	"malay": ["M AH0 L EY1"],
	"malice": ["M AE1 L AH0 S"],
	"mall": ["M AO1 L"],
	"mallory": ["M AE1 L ER0 IY3"],
	"mamma": ["M AA1 M AH0"],
	"man": ["M AE1 N"],
	"man's": ["M AE1 N Z"],
	"manacle": ["M AE1 N AH0 K AH3 L"],
	"mandolin": ["M AE1 N D AH0 L IH2 N"],
	"mane": ["M EY1 N"],
	"mangle": ["M AE1 NG G AH0 L"],
	"manifold": ["M AE1 N AH0 F OW2 L D"],
	"mankind": ["M AE1 N K AY1 N D"],
	"manner": ["M AE1 N ER0"],
	"mansion": ["M AE1 N SH AH0 N"],
	"mantel": ["M AE1 N T AH0 L"],
	"mantled": ["M AE1 N T AH0 L D"],
	"many": ["M EH1 N IY0", "M EH1 N Y"],
	"manzanilla": ["M AE2 N Z AH0 N IH1 L AH0"],
	"maple": ["M EY1 P AH0 L"],
	"mar": ["M AA1 R"],
	"marathon": ["M EH1 R AH0 TH AA2 N"],
	"marauder": ["M ER0 AO1 D ER0"],
	"marauding": ["M AH0 R AO1 D IH0 NG"],
	"marble": ["M AA1 R B AH0 L"],
	"march": ["M AA1 R CH"],
	"mare": ["M EH1 R"],
	"marge": ["M AA1 R JH"],
	"maria": ["M ER0 IY1 AH0"],
	"marigold": ["M EH1 R AH0 G OW2 L D"],
	"mariner": ["M EH1 R AH0 N ER2"],
	"mark": ["M AA1 R K"],
	"market": ["M AA1 R K AH0 T", "M AA1 R K IH0 T"],
	"marketplace": ["M AA1 R K IH0 T P L EY2 S"],
	"marksman": ["M AA1 R K S M AH0 N"],
	"marl": ["M AA1 R L"],
	"marostica": ["M AH0 R AO1 S T AH0 K AH2"],
	"marred": ["M AA1 R D"],
	"marriage": ["M EH1 R IH0 JH"],
	"married": ["M EH1 R IY0 D"],
	"marrow": ["M EH1 R OW0"],
	"marry": ["M AE1 R IY0", "M EH1 R IY0"],
	"mars": ["M AA1 R Z"],
	"marseilles": ["M AA0 R S EY1"],
	"mart": ["M AA1 R T"],
	"martial": ["M AA1 R SH AH0 L"],
	"martyr": ["M AA1 R T ER0"],
	"marvel": ["M AA1 R V AH0 L"],
	"marvelled": ["M AA1 R V AH0 L D"],
	"mary": ["M EH1 R IY0"],
	"maryland": ["M EH1 R AH0 L AH0 N D"],
	"mask": ["M AE1 S K"],
	"mason": ["M EY1 S AH0 N"],
	"masonry": ["M EY1 S AH0 N R IY3"],
	"masquerade": ["M AE2 S K ER0 EY1 D"],
	"mass": ["M AE1 S"],
	"mast": ["M AE1 S T"],
	"master": ["M AE1 S T ER0"],
	"masterdom": ["M AA1 S T ER0 D AH2 M"],
	"masterhands": ["M AE1 S T ER0 HH AE1 N D Z"],
	"mastery": ["M AE1 S T ER0 IY3", "M AE1 S T R IY0"],
	"mat": ["M AE1 T"],
	"match": ["M AE1 CH"],
	"mate": ["M EY1 T"],
	"material": ["M AH0 T IY1 R IY0 AH3 L", "M AH0 T IY1 R Y AH0 L"],
	"mathematical": ["M AE2 TH AH0 M AE1 T IH0 K AH3 L", "M AE2 TH M AE1 T IH0 K AH3 L"],
	"mathematics": ["M AE2 TH AH0 M AE1 T IH0 K S"],
	"matins": ["M AE1 T IH0 N Z"],
	"matter": ["M AE1 T ER0"],
	"mattress": ["M AE1 T R AH0 S"],
	"matured": ["M AH0 T Y UH1 R D"],
	"maturer": ["M AH0 CH UH1 R ER0"],
	"maturing": ["M AH0 CH UH1 R IH0 NG"],
	"maturity": ["M AH0 CH UH1 R AH0 T IY3"],
	"maud": ["M AO1 D"],
	"mausoleum": ["M AO1 Z AH0 L IY2 AH0 M"],
	"maw": ["M AO1"],
	"maws": ["M AO1 Z"],
	"maxim": ["M AE1 K S AH0 M"],
	"may": ["M EY1"],
	"may'st": ["M EY1 S T"],
	"maybe": ["M EY1 B IY0"],
	"mayest": ["M EY1 IH0 S T"],
	"mayhem": ["M EY1 HH EH2 M"],
	"maying": ["M EY1 IH0 NG"],
	"mayor": ["M EY1 ER0"],
	"mazarin": ["M AE1 Z AH0 R IH2 N"],
	"mazy": ["M EY1 Z IY0"],
	"me": ["M IY1"],
	"mead": ["M IY1 D"],
	"meadow": ["M EH1 D OW2"],
	"meagre": ["M IY1 G ER0"],
	"meal": ["M IY1 L"],
	"mean": ["M IY1 N"],
	"meanings": ["M IY1 N IH0 NG Z"],
	"meant": ["M EH1 N T"],
	"meanwhile": ["M IY1 N W AY2 L"],
	"measure": ["M EH1 ZH ER0"],
	"measuring": ["M EH1 ZH ER0 IH0 NG", "M EH1 ZH R IH0 NG"],
	"meat": ["M IY1 T"],
	"mechanic": ["M AH0 K AE1 N IH0 K"],
	"mechanically": ["M AH0 K AE1 N IH0 K L IY3"],
	"mechlin": ["M EH1 K L IH0 N"],
	"medea": ["M AH0 D IY1 AH0"],
	"medias": ["M IY1 D IY0 AH3 Z","M IY1 D Y AH0 S"],
	"mediating": ["M IY1 D IY0 EY2 T IH0 NG"],
	"medicated": ["M EH1 D IH0 K EY2 T IH0 D"],
	"medicinal": ["M AH0 D IH1 S AH0 N AH2 L"],
	"medicine": ["M EH1 D AH0 S IH0 N"],
	"meditate": ["M EH1 D AH0 T EY2 T"],
	"meditation": ["M EH2 D AH0 T EY1 SH AH0 N"],
	"mediterranean": ["M EH2 D IH0 T ER0 EY1 N IY0 AH0 N", "M EH2 D IH0 T ER0 EY1 N Y AH0 N"],
	"meed": ["M IY1 D"],
	"meek": ["M IY1 K"],
	"meet": ["M IY1 T"],
	"melancholy": ["M EH1 L AH0 N K AA2 L IY0"],
	"mellow": ["M EH1 L OW0"],
	"melodious": ["M AH0 L OW1 D Y AH0 S","M AH0 L OW1 D IY0 AH3 S"],
	"melodist": ["M EH1 L AH0 D IH2 S T"],
	"melody": ["M EH1 L AH0 D IY2"],
	"melt": ["M EH1 L T"],
	"mem'ry": ["M EH1 M R IY0"],
	"member": ["M EH1 M B ER0"],
	"memnon": ["M EH1 M N AA2 N"],
	"memorial": ["M EH0 M AO1 R IY0 AH0 L", "M EH0 M AO1 R Y AH0 L"],
	"memories": ["M EH1 M ER0 IY3 Z", "M EH1 M R IY0 Z"],
	"memory": ["M EH1 M ER0 IY2", "M EH1 M R IY0"],
	"men": ["M EH1 N"],
	"menagerie": ["M AH0 N AE1 JH ER0 IY3"],
	"mend": ["M EH1 N D"],
	"mended": ["M EH1 N D IH0 D"],
	"mente": ["M EH1 N T"],
	"mention": ["M EH1 N SH AH0 N"],
	"merchandize": ["M ER1 CH AH0 N D AY2 Z"],
	"merchant": ["M ER1 CH AH0 N T"],
	"merchantman": ["M ER1 CH AH0 N T M AH2 N"],
	"merchantmen": ["M ER1 CH AH0 N T M IH0 N"],
	"mercies": ["M ER1 S IY2 Z"],
	"merciful": ["M ER1 S AH0 F UH3 L"],
	"mercury": ["M ER1 K Y ER0 IY3"],
	"mercy": ["M ER1 S IY0"],
	"mere": ["M IY1 R"],
	"meridian": ["M ER0 IH1 D IY0 AH3 N", "M ER0 IH1 D Y AH0 N"],
	"merino": ["M ER0 IY1 N OW0"],
	"merit": ["M EH1 R IH0 T"],
	"merlin": ["M ER1 L IH0 N"],
	"mermaid": ["M ER1 M EY2 D"],
	"merrier": ["M EH1 R IY0 ER0"],
	"merrily": ["M EH1 R AH0 L IY3"],
	"merriment": ["M EH1 R IY0 M EH2 N T"],
	"merry": ["M EH1 R IY0"],
	"mesh": ["M EH1 SH"],
	"mesmeric": ["M EH0 Z M EH1 R IH0 K"],
	"mess": ["M EH1 S"],
	"message": ["M EH1 S IH0 JH"],
	"messenger": ["M EH1 S AH0 N JH ER0"],
	"met": ["M EH1 T"],
	"metal": ["M EH1 T AH0 L"],
	"metallic": ["M AH0 T AE1 L IH0 K"],
	"metaphysician": ["M EH2 T AH0 F IH0 Z IH1 SH AH0 N"],
	"meted": ["M IY1 T IH0 D"],
	"meteor": ["M IY1 T IY0 ER3"],
	"methinks": ["M IY0 TH IY1 NG K S"],
	"method": ["M EH1 TH IH0 D"],
	"metre": ["M IY1 T ER0"],
	"metropolis": ["M AH0 T R AA1 P AH0 L AH0 S"],
	"mice": ["M AY1 S"],
	"microscope": ["M AY1 K R AH0 S K OW2 P"],
	"midas": ["M AY1 D AH0 S"],
	"middle": ["M IH1 D AH0 L"],
	"midge": ["M IH1 JH"],
	"midnight": ["M IH1 D N AY2 T"],
	"midst": ["M IH1 D S T"],
	"midsummer": ["M IH1 D S AH2 M ER0", "M IH2 D S AH1 M ER0"],
	"midway": ["M IH1 D W EY2"],
	"mien": ["M IY1 N"],
	"might": ["M AY1 T"],
	"mightier": ["M AY1 T Y ER0", "M AY1 T IY0 ER3"],
	"mightiest": ["M AY1 T IY0 AH0 S T", "M AY1 T Y IH0 S T"],
	"mighty": ["M AY1 T IY0"],
	"milan": ["M AH0 L AA1 N"],
	"mild": ["M AY1 L D"],
	"mildew": ["M IH1 L D UW2"],
	"mile": ["M AY1 L"],
	"miles": ["M AY1 L Z", "M AY1 AH0 L Z"],
	"milk": ["M IH1 L K"],
	"milky": ["M IH1 L K IY0"],
	"mill": ["M IH1 L"],
	"milliner": ["M IH1 L IH0 N ER3"],
	"million": ["M IH1 L Y AH0 N"],
	"millionaire": ["M IH2 L Y AH0 N EH1 R"],
	"milton": ["M IH1 L T AH0 N"],
	"mime": ["M AY1 M"],
	"minaret": ["M IH2 N ER0 EH1 T"],
	"mind": ["M AY1 N D"],
	"mine": ["M AY1 N"],
	"minerva": ["M IH0 N ER1 V AH0"],
	"mingle": ["M IH1 NG G AH0 L"],
	"mingled": ["M IH1 NG G AH0 L D"],
	"miniature": ["M IH1 N IY0 AH0 CH UH2 R", "M IH1 N Y AH0 CH UH2 R"],
	"minions": ["M IH1 N Y AH0 N Z"],
	"minister": ["M IH1 N IH0 S T ER3"],
	"ministering": ["M IH1 N IH0 S T R IH2 NG", "M IH1 N IH0 S T ER2 IH0 NG"],
	"ministrant": ["M IH1 N IH0 S T R AH2 N T"],
	"ministry": ["M IH1 N IH0 S T R IY3"],
	"minnow": ["M IH1 N OW0"],
	"minor": ["M AY1 N ER0"],
	"minority": ["M AY0 N AO1 R AH0 T IY3"],
	"minotaur": ["M IH1 N AH0 T AO2 R"],
	"minstrelsy": ["M IH1 N S T R AH0 L S IY3"],
	"mint": ["M IH1 N T"],
	"minuet": ["M IH2 N Y UW0 EH1 T"],
	"minute": ["M IH1 N AH0 T", "M AY0 N UW1 T"],
	"minuter": ["M AY1 N Y UW0 T ER3"],
	"minutest": ["M AY0 N UW1 T AH0 S T"],
	"mirabeau": ["M IY1 R AH0 B OW2"],
	"miracle": ["M IY1 R AH0 K AH3 L"],
	"miraculous": ["M ER0 AE1 K Y AH0 L AH2 S"],
	"miraculously": ["M ER0 AE1 K Y AH0 L AH0 S L IY2"],
	"mirage": ["M ER0 AA1 ZH"],
	"miramoline": ["M IY1 R AH0 M AH0 L AY2 N"],
	"mire": ["M AY1 R"],
	"mirror": ["M IY1 R ER0"],
	"mirth": ["M ER1 TH"],
	"miscarried": ["M IH0 S K AE1 R IY0 D"],
	"miscellaneous": ["M IH2 S AH0 L EY1 N IY0 AH0 S", "M IH2 S AH0 L EY1 N Y AH0 S"],
	"mischance": ["M IH0 S CH AA1 N S"],
	"mischief": ["M IH1 S CH AH0 F"],
	"miscreed": ["M IH2 S K R IY1 D"],
	"misdeed": ["M IH2 S D IY1 D"],
	"misdoubt": ["M IH0 S D AW1 T"],
	"miser": ["M AY1 Z ER0"],
	"miseries": ["M IH1 Z ER0 IY2 Z"],
	"misery": ["M IH1 Z ER0 IY3"],
	"misfortune": ["M IH2 S F AO1 R CH AH0 N", "M IH2 S F AO1 R CH UW0 N"],
	"mishap": ["M IH1 S HH AE2 P"],
	"misletoe": ["M IH1 S AH0 L T OW2"],
	"misnamed": ["M IH0 S N EY1 M D"],
	"misnomer": ["M IH0 S N OW1 M ER0"],
	"misplac'd": ["M IH0 S P L EY1 S T"],
	"miss": ["M IH1 S"],
	"missal": ["M IH1 S AH0 L"],
	"mission": ["M IH1 SH AH0 N"],
	"missionary": ["M IH1 SH AH0 N EH2 R IY0"],
	"missum": ["M IH1 S AH0 M"],
	"mist": ["M IH1 S T"],
	"mistake": ["M IH0 S T EY1 K"],
	"mistaken": ["M IH0 S T EY1 K AH0 N"],
	"mistaking": ["M IH0 S T EY1 K IH0 NG"],
	"mistletoe": ["M IH1 S AH0 L T OW2"],
	"mistress": ["M IH1 S T R AH0 S"],
	"misunderstand": ["M IH2 S AH0 N D ER0 S T AE1 N D"],
	"mitred": ["M AY1 T ER0 D"],
	"mix": ["M IH1 K S"],
	"moan": ["M OW1 N"],
	"moat": ["M OW1 T"],
	"mock": ["M AA1 K"],
	"mockery": ["M AA1 K ER0 IY3"],
	"model": ["M AA1 D AH0 L"],
	"modern": ["M AA1 D ER0 N"],
	"modest": ["M AA1 D AH0 S T"],
	"modesties": ["M AA1 D AH0 S T IY3 Z"],
	"modesty": ["M AA1 D AH0 S T IY3"],
	"modred": ["M OW1 D R IH0 D"],
	"moil": ["M OY1 L"],
	"mold": ["M OW1 L D"],
	"mole": ["M OW1 L"],
	"mollify": ["M AA1 L AH0 F AY2"],
	"molten": ["M OW1 L T AH0 N"],
	"moment": ["M OW1 M AH0 N T"],
	"momentum": ["M OW0 M EH1 N T AH0 M"],
	"monarch": ["M AA1 N AA2 R K"],
	"monarchy": ["M AA1 N AA0 R K IY3"],
	"moncada": ["M OW0 N K AE1 D AH0"],
	"money": ["M AH1 N IY0"],
	"monk": ["M AH1 NG K"],
	"monkey": ["M AH1 NG K IY0"],
	"monologue": ["M AA1 N AH0 L AA2 G"],
	"monotony": ["M AH0 N AA1 T AH0 N IY3"],
	"monstrous": ["M AA1 N S T R AH0 S"],
	"month": ["M AH1 N TH"],
	"monument": ["M AA1 N Y UW0 M AH2 N T"],
	"monumental": ["M AA2 N Y UW0 M EH1 N T AH0 L"],
	"moo": ["M UW1"],
	"mood": ["M UW1 D"],
	"moon": ["M UW1 N"],
	"moonlight": ["M UW1 N L AY2 T"],
	"moonshine": ["M UW1 N SH AY2 N"],
	"moor": ["M UH1 R"],
	"moore": ["M UH1 R"],
	"mooring": ["M UH1 R IH0 NG"],
	"moorish": ["M UH1 R IH0 SH"],
	"moral": ["M AO1 R AH0 L"],
	"moralities": ["M AO0 R AE1 L AH0 T IY2 Z"],
	"morality": ["M ER0 AE1 L AH0 T IY3"],
	"morass": ["M ER0 AE1 S"],
	"more": ["M AO1 R"],
	"moreau": ["M ER0 OW1"],
	"morn": ["M AO1 R N"],
	"morning": ["M AO1 R N IH0 NG"],
	"mornington": ["M AO1 R N IH0 NG T AH2 N"],
	"morose": ["M ER0 OW1 S"],
	"morpheus": ["M AO1 R F IY2 AH0 S"],
	"morrow": ["M AA1 R OW0"],
	"morsel": ["M AO1 R S AH0 L"],
	"mortal": ["M AO1 R T AH0 L"],
	"mortality": ["M AO0 R T AE1 L AH0 T IY3"],
	"mortised": ["M AO1 R T IH0 S"],
	"mosaic": ["M OW0 Z EY1 IH0 K"],
	"moses": ["M OW1 Z IH0 S"],
	"moss": ["M AO1 S"],
	"most": ["M OW1 S T"],
	"mote": ["M OW1 T"],
	"moth": ["M AO1 TH"],
	"mother": ["M AH1 DH ER0"],
	"mothermaid": ["M AH1 DH ER0 M EY1 D"],
	"motion": ["M OW1 SH AH0 N"],
	"motley": ["M AA1 T L IY0"],
	"motril": ["M OW1 T R IH0 L"],
	"mould": ["M OW1 L D"],
	"moulded": ["M OW1 L D IH0 D"],
	"moulder": ["M OW1 L D ER0"],
	"mouldering": ["M OW1 L D ER0 IH3 NG", "M OW1 L D R IH0 NG"],
	"mound": ["M AW1 N D"],
	"mount": ["M AW1 N T"],
	"mountain": ["M AW1 N T AH0 N"],
	"mountainbars": ["M AW1 N T AH0 N B AA2 R Z"],
	"mourn": ["M AO1 R N"],
	"mourners": ["M AO1 R N ER0 Z"],
	"mournful": ["M AO1 R N F AH0 L"],
	"mournfully": ["M AO1 R N F UH0 L IY3"],
	"mourning": ["M AO1 R N IH0 NG"],
	"mouse": ["M AW1 S"],
	"mouth": ["M AW1 TH"],
	"mouthpiece": ["M AW1 TH P IY2 S"],
	"mouths": ["M AW1 DH Z"],
	"mouthy": ["M AW1 TH IY0"],
	"move": ["M UW1 V"],
	"moved": ["M UW1 V D"],
	"movements": ["M UW1 V M AH0 N T S"],
	"moving": ["M UW1 V IH0 NG"],
	"mow": ["M OW1"],
	"mowers": ["M OW1 ER0 Z"],
	"mozart": ["M OW1 T S AA2 R T"],
	"mr": ["M IH1 S T ER0"],
	"mrs": ["M IH1 S IH0 Z"],
	"much": ["M AH1 CH"],
	"mud": ["M AH1 D"],
	"muffet": ["M AH1 F IH0 T"],
	"muffle": ["M AH1 F AH0 L"],
	"muffled": ["M AH1 F AH0 L D"],
	"multifold": ["M AH1 L T IY0 F OW2 L D"],
	"multiplied": ["M AH1 L T IH0 P L AY2 D"],
	"multiply": ["M AH1 L T AH0 P L AY2"],
	"multitude": ["M AH1 L T AH0 T UW2 D"],
	"munificence": ["M Y UW0 N IH1 F AH0 S IH2 N S"],
	"murder": ["M ER1 D ER0"],
	"murkily": ["M ER1 K AH0 L IY3"],
	"murmur": ["M ER1 M ER0"],
	"murmuring": ["M ER1 M ER0 IH3 NG", "M ER1 M R IH0 NG"],
	"murmuringly": ["M ER1 M R IH0 NG L IY3"],
	"murmurous": ["M ER1 M R AH0 S","M ER1 M ER0 AH3 S"],
	"muscle": ["M AH1 S AH0 L"],
	"muscular": ["M AH1 S K Y AH0 L AH3 R"],
	"muse": ["M Y UW1 Z"],
	"mused": ["M Y UW1 Z D"],
	"mushroom": ["M AH1 SH R UW0 M"],
	"music": ["M Y UW1 Z IH0 K"],
	"musical": ["M Y UW1 Z IH0 K AH2 L"],
	"musician": ["M Y UW0 Z IH1 SH AH0 N"],
	"musing": ["M Y UW1 Z IH0 NG"],
	"musket": ["M AH1 S K IH0 T"],
	"muslin": ["M AH1 Z L AH0 N"],
	"mussulmans": ["M AH1 S AH0 L M AH2 N Z"],
	"must": ["M AH1 S T"],
	"mustered": ["M AH1 S T ER0 D"],
	"mute": ["M Y UW1 T"],
	"mutt'ring": ["M AH1 T R IH0 NG"],
	"mutter": ["M AH1 T ER0"],
	"mutton": ["M AH1 T AH0 N"],
	"mutual": ["M Y UW1 CH W AH0 L"],
	"my": ["M AY3"],
	"myrtle": ["M ER1 T AH0 L"],
	"myself": ["M AY3 S EH1 L F"],
	"mysteries": ["M IH1 S T ER0 IY3 Z"],
	"mysterious": ["M IH0 S T IY1 R IY0 AH0 S", "M IH0 S T IY1 R Y AH0 S"],
	"mystery": ["M IH1 S T ER0 IY2", "M IH1 S T R IY0"],
	"mystic": ["M IH1 S T IH0 K"],
	"mystified": ["M IH1 S T AH0 F AY2 D"],
	"mythology": ["M AH0 TH AA1 L AH0 JH IY3"],
	"n": ["EH1 N"],
	"naiad": ["N AY1 AE0 D"],
	"nail": ["N EY1 L"],
	"naked": ["N EY1 K IH0 D"],
	"name": ["N EY1 M"],
	"named": ["N EY1 M D"],
	"nap": ["N AE1 P"],
	"nape": ["N EY1 P"],
	"naples": ["N EY1 P AH0 L Z"],
	"napoleon": ["N AH0 P OW1 L IY0 AH3 N", "N AH0 P OW1 L Y AH0 N"],
	"napping": ["N AE1 P IH0 NG"],
	"narrate": ["N EH1 R EY0 T"],
	"narrating": ["N EH1 R EY0 T IH2 NG"],
	"narration": ["N EH0 R EY1 SH AH0 N"],
	"narrative": ["N AE1 R AH0 T IH2 V"],
	"narrow": ["N EH1 R OW0"],
	"nation": ["N EY1 SH AH0 N"],
	"native": ["N EY1 T IH0 V"],
	"natural": ["N AE1 CH ER0 AH3 L", "N AE1 CH R AH0 L"],
	"nature": ["N EY1 CH ER0"],
	"natures": ["N EY1 CH ER0 Z"],
	"naught": ["N AO1 T"],
	"naughty": ["N AO1 T IY0"],
	"naval": ["N EY1 V AH0 L"],
	"nave": ["N EY1 V"],
	"navies": ["N EY1 V IY0 Z"],
	"navigable": ["N AE1 V AH0 G AH3 B AH0 L"],
	"navigation": ["N AE2 V AH0 G EY1 SH AH0 N"],
	"nay": ["N EY1"],
	"nazareth": ["N AE1 Z AH0 R IH3 TH"],
	"nazarite": ["N AE1 Z ER0 AY2 T"],
	"ne'er": ["N EH1 R"],
	"near": ["N IY1 R"],
	"neat": ["N IY1 T"],
	"neath": ["N IY3 TH"],
	"necessary": ["N EH1 S AH0 S EH2 R IY0"],
	"necessity": ["N AH0 S EH1 S AH0 T IY2"],
	"neck": ["N EH1 K"],
	"necklace": ["N EH1 K L AH0 S"],
	"necks": ["N EH1 K S"],
	"necromancer": ["N EH1 K R AH0 M AE2 N S ER0"],
	"necromancy": ["N EH1 K R AH0 M AE2 N S IY0"],
	"nectar": ["N EH1 K T ER0"],
	"nectareous": ["N EH2 K T EH1 R IY0 AH3 S", "N EH2 K T EH1 R Y AH0 S"],
	"nectars": ["N EH1 K T ER0 Z"],
	"need": ["N IY1 D"],
	"needle": ["N IY1 D AH0 L"],
	"negative": ["N EH1 G AH0 T IH2 V"],
	"neglect": ["N IH0 G L EH1 K T"],
	"negligence": ["N EH1 G L AH0 JH AH2 N S"],
	"negotiate": ["N AH0 G OW1 SH IY0 EY2 T", "N AH0 G OW1 SH Y EY0 T"],
	"neigh": ["N EY1"],
	"neighbor": ["N EY1 B ER0"],
	"neighbour": ["N EY1 B ER0"],
	"neighbouring": ["N EY1 B R IH0 NG", "N EY1 B ER0 IH3 NG"],
	"neighbourhood": ["N EY1 B ER0 HH UH2 D"],
	"neighs": ["N EY1 Z"],
	"neither": ["N IY1 DH ER0", "N AY1 DH ER0"],
	"nelson": ["N EH1 L S AH0 N"],
	"nepenthe": ["N EH2 P EH1 N TH IY0"],
	"nerve": ["N ER1 V"],
	"nervous": ["N ER1 V AH0 S"],
	"nesace": ["N IY0 S AA1 K IY0"],
	"nescessity": ["N AH0 S EH1 S IH0 T IY3"],
	"nest": ["N EH1 S T"],
	"nestled": ["N EH1 S AH0 L D"],
	"net": ["N EH1 T"],
	"nether": ["N EH1 DH ER0"],
	"neuter": ["N UW1 T ER0"],
	"neutral": ["N UW1 T R AH0 L"],
	"neutrality": ["N UW0 T R AE1 L AH0 T IY3"],
	"neutralized": ["N UW1 T R AH0 L AY2 Z D"],
	"never": ["N EH1 V ER0"],
	"nevermore": ["N EH1 V ER0 M AO2 R"],
	"new": ["N UW1"],
	"newspaper": ["N UW1 Z P EY2 P ER0"],
	"newborn": ["N UW1 B AO0 R N"],
	"next": ["N EH1 K S T"],
	"nibble": ["N IH1 B AH0 L"],
	"nice": ["N AY1 S"],
	"niche": ["N IH1 CH"],
	"nicodemus": ["N IH2 K AH0 D IY1 M UH0 S"],
	"nicolo": ["N IH1 K AH0 L OW2"],
	"niece": ["N IY1 S"],
	"niggard": ["N IH1 G ER0 D"],
	"nigh": ["N AY1"],
	"nigher": ["N AY1 ER0"],
	"night": ["N AY1 T"],
	"night's": ["N AY1 T S"],
	"nightfall": ["N AY1 T F AO2 L"],
	"nightgowns": ["N AY1 T G AW4 N Z"],
	"nightingale": ["N AY1 T IH0 NG G EY2 L"],
	"nightingales": ["N AY1 T IH0 NG G EY2 L Z"],
	"nights": ["N AY1 T S"],
	"nile": ["N AY1 L"],
	"nimble": ["N IH1 M B AH0 L"],
	"nine": ["N AY1 N"],
	"nineveh": ["N IH1 N AH0 V AH0"],
	"niobe": ["N AY1 AH0 B IY3"],
	"nip": ["N IH1 P"],
	"no": ["N OW1"],
	"noah": ["N OW1 AH0"],
	"noble": ["N OW1 B AH0 L"],
	"nobler": ["N OW1 B L ER0"],
	"noblest": ["N OW1 B L IH0 S T"],
	"nobody": ["N OW1 B AA2 D IY2"],
	"nod": ["N AA1 D"],
	"nodded": ["N AA1 D IH0 D"],
	"nodding": ["N AA1 D IH0 NG"],
	"noddy": ["N AA1 D IY0"],
	"noise": ["N OY1 Z"],
	"noisy": ["N OY1 Z IY0"],
	"nominated": ["N AA1 M AH0 N EY2 T IH0 D"],
	"nonchalant": ["N AA2 N SH AH0 L AA1 N T"],
	"none": ["N AH1 N"],
	"nook": ["N UH1 K"],
	"noon": ["N UW1 N"],
	"noonday": ["N UW2 N D EY2"],
	"noonsun": ["N UW2 N S AH2 N"],
	"noontide": ["N UW1 N T AY0 D"],
	"nor": ["N AO1 R"],
	"norseman": ["N AO1 R S M AH0 N"],
	"north": ["N AO1 R TH"],
	"northerly": ["N AO1 R DH ER0 L IY3"],
	"northern": ["N AO1 R DH ER0 N"],
	"northward": ["N AO1 R TH W ER0 D"],
	"norwegian": ["N AO2 R W IY1 JH AH0 N"],
	"nos": ["N AA1 S"],
	"nose": ["N OW1 Z"],
	"nosegay": ["N OW1 Z G EY2"],
	"nostril": ["N AA1 S T R IH0 L"],
	"not": ["N AA1 T"],
	"notch": ["N AA1 CH"],
	"note": ["N OW1 T"],
	"noted": ["N OW1 T IH0 D"],
	"nothing": ["N AH1 TH IH0 NG"],
	"notice": ["N OW1 T IH0 S"],
	"noticed": ["N OW1 T AH0 S T"],
	"noticing": ["N OW1 T IH0 S IH3 NG"],
	"notion": ["N OW1 SH AH0 N"],
	"notoriety": ["N OW2 T ER0 AY1 AH0 T IY2"],
	"notwithstanding": ["N AA1 T W IH0 TH S T AE2 N D IH0 NG"],
	"nought": ["N AO1 T"],
	"novel": ["N AA1 V AH0 L"],
	"november": ["N OW3 V EH1 M B ER0"],
	"novice": ["N AA1 V AH0 S"],
	"now": ["N AW1"],
	"nowhere": ["N OW1 W EH2 R"],
	"nude": ["N UW1 D"],
	"null": ["N AH1 L"],
	"nullified": ["N AH1 L AH0 F AY2 D"],
	"nullity": ["N AH1 L IH0 T IY3"],
	"numb": ["N AH1 M"],
	"number": ["N AH1 M B ER0"],
	"numeral": ["N UW1 M ER0 AL3", "N UW1 M R AH0 L"],
	"numerous": ["N UW1 M ER0 AH3 S", "N UW1 M R AH0 S"],
	"nun": ["N AH1 N"],
	"nunez": ["N UW1 N EH0 Z"],
	"nunnery": ["N AH1 N ER0 IY3"],
	"nurse": ["N ER1 S"],
	"nursed": ["N ER1 S T"],
	"nurst": ["N ER1 S T"],
	"nut": ["N AH1 T"],
	"nutrition": ["N UW0 T R IH1 SH AH0 N"],
	"nymph": ["N IH1 M F"],
	"o": ["OW3","OW2"],
	"o'": ["AH0"],
	"o'clock": ["AH0 K L AA1 K"],
	"o'er": ["AO1 R"],
	"o'ercast": ["AO2 R K AE1 S T"],
	"o'erflow": ["AO0 R F L OW1"],
	"o'ergloss": ["AO0 R G L AO1 S"],
	"o'ergrown": ["AO0 R G R OW1 N"],
	"o'erhead": ["AO2 R HH EH1 D"],
	"o'erleap": ["AO2 R L IY1 P"],
	"o'erlook": ["AO2 R L UH1 K"],
	"o'erpast": ["AO0 R P AE1 S T"],
	"o'erspread": ["AO0 R S P R EH1 D"],
	"o'erstep": ["AO2 R S T EH1 P"],
	"o'erthrown": ["AO0 R TH R OW1 N"],
	"o'ertook": ["AO2 R T UH1 K"],
	"o'erturn": ["AO0 R T ER1 N"],
	"o'erworn": ["AO2 R W AO1 R N"],
	"o'reilly": ["OW0 R AY1 L IY0"],
	"oak": ["OW1 K"],
	"oar": ["AO1 R"],
	"oath": ["OW1 TH"],
	"oaths": ["OW1 DH Z"],
	"obedience": ["OW0 B IY1 D IY0 AH3 N S", "OW0 B IY1 D Y AH0 N S"],
	"obeisance": ["OW0 B EY1 S AH0 N S"],
	"obelisk": ["AA1 B AH0 L IH2 S K"],
	"obey": ["OW0 B EY1"],
	"obits": ["OW1 B IH0 T S"],
	"object": ["AA1 B JH EH0 K T", "AH0 B JH EH1 K T"],
	"object's": ["AA1 B JH EH0 K T S"],
	"obligations": ["AA2 B L AH0 G EY1 SH AH0 N Z"],
	"obliged": ["AH0 B L AY1 JH D"],
	"obliging": ["AH0 B L AY1 JH IH0 NG"],
	"oblique": ["AH0 B L IY1 K"],
	"obliterate": ["AH0 B L IH1 T ER0 EY2 T"],
	"oblivion": ["AH0 B L IH1 V IY0 AH3 N", "AH0 B L IH1 V Y AH0 N"],
	"obscene": ["AH0 B S IY1 N"],
	"obscure": ["AH0 B S K Y UH1 R", "AA1 B S K Y UH0 R"],
	"obscured": ["AH0 B S K Y UH1 R D"],
	"obscuring": ["AH0 B S K Y UH1 R IH0 NG"],
	"obscurity": ["AH0 B S K Y UH1 R AH0 T IY3"],
	"obsequies": ["AA1 B S IH0 K W IH0 Z"],
	"observation": ["AA2 B Z ER0 V EY1 SH AH0 N"],
	"observe": ["AH0 B Z ER1 V"],
	"observed": ["AH0 B Z ER1 V D"],
	"observing": ["AH0 B Z ER1 V IH0 NG"],
	"obstacle": ["AA1 B S T AH0 K AH2 L"],
	"obtain": ["AH0 B T EY1 N"],
	"obviate": ["AA1 B V IY0 EY2 T"],
	"obviated": ["AA1 B V IY0 EY2 T IH0 D"],
	"obvious": ["AA1 B V IY0 AH3 S", "AA1 B V Y AH0 S"],
	"occasion": ["AH0 K EY1 ZH AH0 N"],
	"occasional": ["AH0 K EY1 ZH AH0 N AH0 L"],
	"occasionally": ["AH0 K EY1 ZH AH0 N AH0 L IY3", "AH0 K EY1 ZH N AH0 L IY3"],
	"occident": ["AA1 K S AH0 D EH2 N T"],
	"occidental": ["AA1 K S AH0 D EH2 N T AH0 L"],
	"occupant": ["AA1 K Y AH0 P AH3 N T"],
	"occupation": ["AA2 K Y AH0 P EY1 SH AH0 N"],
	"occupied": ["AA1 K Y AH0 P AY2 D"],
	"occupies": ["AA1 K Y UH0 P AY2 Z"],
	"occupy": ["AA1 K Y UH0 P AY2"],
	"occupying": ["AA1 K Y AH0 P AY2 IH0 NG"],
	"occur": ["AH0 K ER1"],
	"occurred": ["AH0 K ER1 D"],
	"ocean": ["OW1 SH AH0 N"],
	"octave": ["AA1 K T AH0 V"],
	"october": ["AA0 K T OW1 B ER0"],
	"odd": ["AA1 D"],
	"oddity": ["AA1 D AH0 T IY3"],
	"ode": ["OW1 D"],
	"odor": ["OW1 D ER0"],
	"odoriferous": ["OW1 D ER0 IH3 F ER0 AH3 S", "OW1 D ER0 IH3 F R AH0 S"],
	"odorous": ["OW1 D ER0 AH3 S", "OW1 D R AH0 S"],
	"odour": ["OW1 D ER0"],
	"odyssey": ["AA1 D IH0 S IY2"],
	"of": ["AH3 V"],
	"off": ["AO1 F"],
	"off'rings": ["AA1 F R IH0 NG Z"],
	"offence": ["AH0 F EH1 N S"],
	"offend": ["AH0 F EH1 N D"],
	"offense": ["AH0 F EH1 N S"],
	"offer": ["AO1 F ER0"],
	"offering": ["AO1 F ER0 IH3 NG", "AO1 F R IH0 NG"],
	"offerings": ["AO1 F ER0 IH2 NG Z", "AO1 F R IH0 NG Z"],
	"office": ["AO1 F IH0 S"],
	"officious": ["OW0 F IY1 SH AH0 S"],
	"oft": ["AO1 F T"],
	"ofttimes": ["AO1 F T T AY2 M Z"],
	"often": ["AO1 F T AH0 N"],
	"oh": ["OW1"],
	"oil": ["OY1 L"],
	"oil\u00e8d": ["OY1 L IH0 D"],
	"old": ["OW1 L D"],
	"oliero": ["AO0 L Y EH1 R OW0"],
	"olivet": ["AA1 L IH0 V EH2 T"],
	"omen": ["OW1 M AH0 N"],
	"ominous": ["AA1 M AH0 N AH0 S"],
	"omission": ["OW0 M IH1 SH AH0 N"],
	"omit": ["OW0 M IH1 T"],
	"omitted": ["OW0 M IH1 T IH0 D"],
	"omnifold": ["AA0 M N IH1 F OW0 L D"],
	"omnipotence": ["AA0 M N IH1 P AH0 T AH2 N S"],
	"omnipotent": ["AA0 M N IH1 P AH0 T AH3 N T"],
	"omnipresence": ["AA2 M N IH0 P R EH1 Z AH0 N S"],
	"on": ["AA3 N"],
	"once": ["W AH1 N S"],
	"one": ["W AH1 N"],
	"only": ["OW1 N L IY0"],
	"onset": ["AA1 N S EH2 T"],
	"onward": ["AO1 N W ER0 D"],
	"onyx": ["AA1 N IH0 K S"],
	"ooze": ["UW1 Z"],
	"opal": ["OW1 P AH0 L"],
	"opaquest": ["OW0 P EY1 K IH0 S T"],
	"open": ["OW1 P AH0 N"],
	"opening": ["OW1 P AH0 N IH3 NG", "OW1 P N IH0 NG"],
	"opera": ["AA1 P R AH0"],
	"opinion": ["AH0 P IH1 N Y AH0 N"],
	"opon": ["AH0 P AA1 N"],
	"opportunities": ["AA2 P ER0 T UW1 N AH0 T IY3 Z"],
	"opportunity": ["AA2 P ER0 T UW1 N AH0 T IY3"],
	"oppose": ["AH0 P OW1 Z"],
	"opposing": ["AH0 P OW1 Z IH0 NG"],
	"opposite": ["AA1 P AH0 Z AH0 T", "AA1 P Z AH0 T"],
	"oppositely": ["AA1 P AH0 S IH0 T L IY2"],
	"oppress": ["AH0 P R EH1 S"],
	"oppressor": ["OW0 P R EH1 S ER0"],
	"opprest": ["AH0 P R EH1 S T"],
	"option": ["AA1 P SH AH0 N"],
	"optional": ["AA1 P SH AH0 N AH0 L"],
	"optizan": ["AA1 P T IH0 Z AE2 N"],
	"opulence": ["AA1 P Y AH0 L AH3 N S"],
	"or": ["AO1 R"],
	"oracle": ["AO1 R AH0 K AH3 L"],
	"orange": ["AO1 R AH0 N JH"],
	"orb": ["AO1 R B"],
	"orbit": ["AO1 R B AH0 T"],
	"orchard": ["AO1 R CH ER0 D"],
	"orchestra": ["AO1 R K AH0 S T R AH0"],
	"orchis": ["AO1 R K IH0 S"],
	"ordain": ["AO0 R D EY1 N"],
	"order": ["AO1 R D ER0"],
	"ordering": ["AO1 R D ER0 IH2 NG", "AO1 R D R IH0 NG"],
	"ordinary": ["AO1 R D AH0 N EH2 R IY0"],
	"ordination": ["AO2 R D AH0 N EY1 SH AH0 N"],
	"ordnance": ["AO1 R D N AH0 N S"],
	"ore": ["AO1 R"],
	"oreb": ["AO1 R EH2 B"],
	"organdy": ["AO1 R G AH0 N D IY2"],
	"organized": ["AO1 R G AH0 N AY2 Z D"],
	"organs": ["AO1 R G AH0 N Z"],
	"orient": ["AO1 R IY0 EH2 N T", "AO1 R Y EH0 N T"],
	"orient's": ["AO1 R IY0 EH2 N T S", "AO1 R Y EH0 N T S"],
	"oriental": ["AO2 R IY0 EH1 N T AH0 L"],
	"origin": ["AO1 R AH0 JH IH3 N"],
	"original": ["ER0 IH1 JH AH0 N AH2 L"],
	"orioles": ["AO1 R IY0 OW2 L Z"],
	"orleans": ["AO1 R L IY0 AH3 N Z", "AO2 R L IY1 N Z"],
	"ornament": ["AO1 R N AH0 M AH3 N T"],
	"orontes": ["AO0 R AA1 N T EY1 Z"],
	"orthography": ["AO0 R TH AA1 G R AH0 F IY3"],
	"osier": ["OW1 ZH ER0"],
	"osprey": ["AO1 S P R IY0"],
	"ostensible": ["AA0 S T EH1 N S AH0 B AH3 L"],
	"ostensibly": ["AA0 S T EH1 N S AH0 B L IY3"],
	"other": ["AH1 DH ER0"],
	"otherwhere": ["AH1 DH ER0 W EH2 R"],
	"otherwise": ["AH1 DH ER0 W AY2 Z"],
	"ought": ["AO1 T"],
	"ounce": ["AW1 N S"],
	"our": ["AW3 R","AW1 ER0", ],
	"our's": ["AW1 R Z", "AW1 ER0 Z"],
	"ours": ["AW1 R Z", "AW1 ER0 Z"],
	"ourself": ["AW3 ER0 S EH1 L F", "AW0 R S EH1 L F"],
	"ourselves": ["AW3 ER0 S EH1 L V Z", "AW0 R S EH1 L V Z"],
	"out": ["AW1 T"],
	"outbrave": ["AW2 T B R EY1 V"],
	"outburst": ["AW1 T B ER2 S T"],
	"outcast": ["AW1 T K AE2 S T"],
	"outdoor": ["AW1 T D AO2 R"],
	"outer": ["AW1 T ER0"],
	"outgrow": ["AW2 T G R OW1"],
	"outgrown": ["AW2 T G R OW1 N"],
	"outlast": ["AW0 T L AE1 S T"],
	"outlaw": ["AW1 T L AO2"],
	"outline": ["AW1 T L AY2 N"],
	"outlived": ["AW2 T L IH1 V D"],
	"outnumber": ["AW0 T N AH1 M B ER0"],
	"outpour": ["AW2 T P AO1 R"],
	"outrageous": ["AW0 T R EY1 JH AH0 S"],
	"outran": ["AW0 T R AE1 N"],
	"outraught": ["AW2 T R AO1 T"],
	"outreached": ["AW1 T R IY0 CH T"],
	"outright": ["AW1 T R AY1 T"],
	"outrun": ["AW0 T R AH1 N"],
	"outside": ["AW1 T S AY1 D"],
	"outspread": ["AW0 T S P R EH1 D"],
	"outstaggering": ["AW2 T S T AE1 G R IH0 NG", "AW2 T S T AE1 G ER0 IH3 NG"],
	"outstretch": ["AW0 T S T R EH1 CH"],
	"outstripped": ["AW2 T S T R IH1 P T"],
	"outvie": ["AW2 T V AY1"],
	"outvisions": ["AW2 T V IH1 ZH AH0 N S"],
	"outward": ["AW1 T W ER0 D"],
	"outweigh": ["AW1 T W EY2"],
	"outworn": ["AW2 T W AO1 R N"],
	"oval": ["OW1 V AH0 L"],
	"over": ["OW1 V ER0"],
	"overbold": ["OW2 V ER0 B OW1 L D"],
	"overborne": ["OW2 V ER0 B AO1 R N"],
	"overbranch": ["OW2 V ER0 B R AE1 N CH"],
	"overcame": ["OW1 V ER0 K EY1 M"],
	"overcast": ["OW1 V ER0 K AE2 S T"],
	"overcoat": ["OW1 V ER0 K OW2 T"],
	"overcome": ["OW1 V ER0 K AH2 M"],
	"overcomest": ["OW2 V ER0 K AH1 M AH0 S T"],
	"overflow": ["OW1 V ER0 F L OW2", "OW2 V ER0 F L OW1"],
	"overflowed": ["OW2 V ER0 F L OW1 D"],
	"overflows": ["OW2 V ER0 F L OW1 Z"],
	"overgone": ["OW2 V ER0 G AA1 N"],
	"overgrown": ["OW2 V ER0 G R OW1 N"],
	"overgrowth": ["OW1 V ER0 G R OW2 TH"],
	"overhead": ["OW1 V ER0 HH EH1 D"],
	"overhear": ["OW1 V ER0 HH IY1 R"],
	"overheard": ["OW1 V ER0 HH ER1 D"],
	"overjoyed": ["OW2 V ER0 JH OY1 D"],
	"overlay": ["OW1 V ER0 L EY2"],
	"overlean": ["OW2 V ER0 L IY1 N"],
	"overlook": ["OW1 V ER0 L UH2 K", "OW2 V ER0 L UH1 K"],
	"overlooked": ["OW1 V ER0 L UH2 K T"],
	"overmuch": ["OW2 V ER0 M AH1 CH"],
	"overpassed": ["OW1 V ER0 P AE0 S T"],
	"overpast": ["OW2 V ER0 P AE1 S T"],
	"overpay": ["OW2 V ER0 P EY1"],
	"overset": ["OW2 V ER0 S EH1 T"],
	"overshadoweth": ["OW2 V ER0 SH AE1 D OW0 W EH2 TH"],
	"oversleep": ["OW2 V ER0 S L IY1 P"],
	"oversmooth": ["OW2 V ER0 S M UW1 DH"],
	"overtake": ["OW2 V ER0 T EY1 K"],
	"overtaking": ["OW2 V ER0 T EY1 K IH0 NG"],
	"overteem": ["OW2 V ER0 T IY1 M"],
	"overthrow": ["OW1 V ER0 TH R OW2"],
	"overthrown": ["OW2 V ER0 TH R OW1 N"],
	"overthwart": ["OW2 V ER0 TH W AO1 R T"],
	"overturn": ["OW1 V ER0 T ER2 N"],
	"overwhelm": ["OW2 V ER0 W EH1 L M"],
	"overworn": ["OW2 V ER0 W AO1 R N"],
	"overwove": ["OW2 V ER0 W OW1 V"],
	"overwrought": ["OW2 V ER0 R AO1 T"],
	"overzealous": ["OW2 V ER0 Z EH1 L AH0 S"],
	"ovid": ["AA1 V IH0 D"],
	"ow'st": ["OW1 S T"],
	"owe": ["OW1"],
	"owed": ["OW1 D"],
	"owl": ["AW1 L"],
	"owlets": ["AW1 L EH0 T S"],
	"own": ["OW1 N"],
	"ox": ["AA1 K S"],
	"oxen": ["AA1 K S AH0 N"],
	"oxygen": ["AA1 K S AH0 JH AH3 N"],
	"ozymandias":["AA2 Z AH0 M AE1 N D Y AH0 S"],
	"pace": ["P EY1 S"],
	"pack": ["P AE1 K"],
	"pad": ["P AE1 D"],
	"paean": ["P IY1 AH0 N"],
	"pagan": ["P EY1 G AH0 N"],
	"page": ["P EY1 JH"],
	"pageant": ["P AE1 JH AH0 N T"],
	"pageantries": ["P AE1 JH AH0 N TR IY2 Z"],
	"pageantry": ["P AE1 JH AH0 N T R IY2"],
	"paid": ["P EY1 D"],
	"pail": ["P EY1 L"],
	"pain": ["P EY1 N"],
	"painful": ["P EY1 N F AH0 L"],
	"paint": ["P EY1 N T"],
	"painter": ["P EY1 N T ER0"],
	"pair": ["P EH1 R"],
	"palace": ["P AE1 L AH0 S"],
	"palacefloor": ["P AE1 L AH0 S F L AO1 R"],
	"paladin": ["P AE1 L AH0 D IH2 N"],
	"palate": ["P AE1 L AH0 T"],
	"pale": ["P EY1 L"],
	"paled": ["P EY1 L D"],
	"paler": ["P EY1 L ER0"],
	"paling": ["P EY1 L IH0 NG"],
	"pall": ["P AO1 L"],
	"pallas": ["P AE1 L AH0 S"],
	"palliate": ["P AE1 L IY0 EY2 T", "P AE1 L Y EY0 T"],
	"pallid": ["P AE1 L IH0 D"],
	"palm": ["P AA1 M"],
	"palms": ["P AA1 M Z", "P AA1 L M Z"],
	"palpitating": ["P AE1 L P AH0 T EY2 T IH0 NG"],
	"palsied": ["P AO1 L Z IY0 D"],
	"paltry": ["P AO1 L T R IY0"],
	"pampas": ["P AE1 M P AH0 Z"],
	"pan": ["P AE1 N"],
	"panca": ["P AE1 NG K AH0"],
	"pane": ["P EY1 N"],
	"panel": ["P AE1 N AH0 L"],
	"pang": ["P AE1 NG"],
	"panoply": ["P AE1 N AA0 P L IY3"],
	"pant": ["P AE1 N T"],
	"pantomime": ["P AE1 N T AH0 M AY2 M"],
	"papa": ["P AA1 P AH2"],
	"paper": ["P EY1 P ER0"],
	"parable": ["P EH1 R AH0 B AH2 L"],
	"parade": ["P ER0 EY1 D"],
	"paradise": ["P EH1 R AH0 D AY2 S"],
	"paradox": ["P EH1 R AH0 D AA2 K S"],
	"paragon": ["P EH1 R AH0 G AA2 N"],
	"paragraph": ["P EH1 R AH0 G R AE2 F"],
	"parallel": ["P EH1 R AH0 L EH2 L"],
	"paralysis": ["P ER0 AE1 L AH0 S AH0 S"],
	"paralyze": ["P EH1 R AH0 L AY2 Z"],
	"paralyzing": ["P EH1 R AH0 L AY2 Z IH0 NG"],
	"paramour": ["P EH1 R AH0 M AO2 R"],
	"parasite": ["P EH1 R AH0 S AY2 T"],
	"parasol": ["P EH1 R AH0 S AA2 L"],
	"parcel": ["P AA1 R S AH0 L"],
	"parch": ["P AA1 R CH"],
	"pard": ["P AA1 R D"],
	"pardon": ["P AA1 R D AH0 N"],
	"pare": ["P EH1 R"],
	"parent": ["P EH1 R AH0 N T"],
	"parian": ["P EH1 R Y AH0 N", "P EH1 R IY0 AH0 N"],
	"paris": ["P EH1 R IH0 S"],
	"parish": ["P AE1 R IH0 SH"],
	"park": ["P AA1 R K"],
	"parley": ["P AA1 R L IY0"],
	"parliament": ["P AA1 R L AH0 M AH2 N T"],
	"parlor": ["P AA1 R L ER0"],
	"part": ["P AA1 R T"],
	"partake": ["P AA0 R T EY1 K"],
	"partaken": ["P AA0 R T EY1 K AH0 N"],
	"parthenon": ["P AA1 R TH AH0 N AA2 N"],
	"partial": ["P AA1 R SH AH0 L"],
	"particle": ["P AA1 R T IH0 K AH3 L"],
	"particular": ["P ER0 T IH1 K Y AH0 L ER2"],
	"parties": ["P AA1 R T IY0 Z"],
	"partition": ["P AA0 R T IH1 SH AH0 N"],
	"partook": ["P AA0 R T UH1 K"],
	"partout": ["P AA0 T UW1"],
	"partridge": ["P AA1 R T R IH0 JH"],
	"party": ["P AA1 R T IY0"],
	"pass": ["P AE1 S"],
	"passage": ["P AE1 S IH0 JH"],
	"passion": ["P AE1 SH AH0 N"],
	"passive": ["P AE1 S IH0 V"],
	"passiver": ["P AE1 S IH0 V ER3"],
	"past": ["P AE1 S T"],
	"pastoral": ["P AE0 S T AO1 R AH0 L", "P AE1 S T ER0 AH3 L"],
	"pastime": ["P AE1 S T AY2 M"],
	"pasture": ["P AE1 S CH ER0"],
	"patch": ["P AE1 CH"],
	"patchwork": ["P AE1 CH W ER2 K"],
	"patent": ["P AE1 T AH0 N T"],
	"path": ["P AE1 TH"],
	"pathetic": ["P AH0 TH EH1 T IH0 K"],
	"pathos": ["P EY1 TH AA0 S"],
	"patience": ["P EY1 SH AH0 N S"],
	"patient": ["P EY1 SH AH0 N T"],
	"patriarch": ["P EY1 T R IY0 AA2 R K"],
	"patriot": ["P EY1 T R IY0 IH0 T", "P EY1 T R Y IH0 T"],
	"patriotic": ["P EY2 T R IY0 AA1 T IH0 K"],
	"patron": ["P EY1 T R AH0 N"],
	"pattern": ["P AE1 T ER0 N"],
	"paul": ["P AO1 L"],
	"pauper": ["P AO1 P ER0"],
	"pause": ["P AO1 Z"],
	"paused": ["P AO1 Z D"],
	"pausing": ["P AO1 Z IH0 NG"],
	"pave": ["P EY1 V"],
	"pavilion": ["P AH0 V IH1 L Y AH0 N"],
	"pavis": ["P AE1 V IH0 S"],
	"paw": ["P AO1"],
	"pay": ["P EY1"],
	"pea": ["P IY1"],
	"peace": ["P IY1 S"],
	"peaceable": ["P IY1 S AH0 B AH3 L"],
	"peacefully": ["P IY1 S F AH0 L IY3"],
	"peacefulness": ["P IY1 S F AH0 L N AH2 S"],
	"peach": ["P IY1 CH"],
	"peacock": ["P IY1 K AA0 K"],
	"peak": ["P IY1 K"],
	"peal": ["P IY1 L"],
	"pear": ["P EH1 R"],
	"pearl": ["P ER1 L"],
	"peasant": ["P EH1 Z AH0 N T"],
	"peasantry": ["P EH1 Z AH0 N T R IY3"],
	"pebble": ["P EH1 B AH0 L"],
	"peculiar": ["P AH0 K Y UW1 L Y ER0"],
	"pedantic": ["P AH0 D AE1 N T IH0 K"],
	"peddler": ["P EH1 D L ER0", "P EH1 D AH0 L ER3"],
	"pedestal": ["P EH1 D AH0 S T AH2 L"],
	"pedigree": ["P EH1 D AH0 G R IY0"],
	"pedrillo": ["P EH0 D R IH1 L OW0"],
	"peep": ["P IY1 P"],
	"peer": ["P IY1 R"],
	"peevish": ["P IY1 V IH0 SH"],
	"peleus": ["P EH1 L Y UH0 S"],
	"pelf": ["P EH1 L F"],
	"pelisse": ["P IH0 L IY1 S"],
	"pellet": ["P EH1 L AH0 T"],
	"pelteth": ["P EH1 L T IH0 TH"],
	"pen": ["P EH1 N"],
	"penance": ["P EH1 N AH0 N S"],
	"pence": ["P EH1 N S"],
	"pencil": ["P EH1 N S AH0 L"],
	"pendulous": ["P EH1 N JH AH0 L AH3 S"],
	"pendulum": ["P EH1 N JH AH0 L AH3 M"],
	"peniel": ["P AH0 N IY1 AH0 L"],
	"peninsula": ["P AH0 N IH1 N S AH0 L AH0"],
	"penitence": ["P EH1 N IH0 T AH0 N S"],
	"penitent": ["P EH1 N IH0 T EH2 N T"],
	"penniless": ["P EH1 N IY0 L AH3 S"],
	"pens": ["P EH1 N Z"],
	"pensive": ["P EH1 N S IH0 V"],
	"pent": ["P EH1 N T"],
	"pentapolin": ["P EH0 N T AE2 P AH0 L IH1 N"],
	"pentateuchal": ["P EH1 N T AH0 T Y UW2 K AH0 L"],
	"penurious": ["P EH0 N Y UW1 R IY0 AH3 S", "P EH0 N Y UW1 R Y AH0 S"],
	"penury": ["P EH1 N Y UH0 R IY3"],
	"peona": ["P IY0 OW1 N AH0"],
	"people": ["P IY1 P AH0 L"],
	"peradventure": ["P ER2 AE0 D V EH1 N CH ER0"],
	"perceiv'd": ["P ER0 S IY1 V D"],
	"perceive": ["P ER0 S IY1 V"],
	"perceived": ["P ER0 S IY1 V D"],
	"perceiving": ["P ER0 S IY1 V IH0 NG"],
	"perceptible": ["P ER0 S EH1 P T AH0 B AH0 L"],
	"perception": ["P ER0 S EH1 P SH AH0 N"],
	"perch": ["P ER1 CH"],
	"perchance": ["P ER0 CH AE1 N S"],
	"perdition": ["P ER0 D IH1 SH AH0 N"],
	"perennial": ["P ER0 EH1 N IY0 AH0 L", "P ER0 EH1 N Y AH0 L"],
	"perfect": ["P ER1 F IH2 K T", "P ER0 F EH1 K T"],
	"perfected": ["P ER0 F EH1 K T IH0 D"],
	"perfecter": ["P ER0 F EH1 K T ER0", "P ER1 F IH0 K T ER3"],
	"perfection": ["P ER0 F EH1 K SH AH0 N"],
	"perfectness": ["P ER1 F AH0 K T N AH3 S"],
	"perfidiously": ["P ER0 F IH1 D IH0 AH0 S L IY2"],
	"perfidy": ["P ER1 F IH0 D IY3"],
	"performed": ["P ER0 F AO1 R M D"],
	"perfume": ["P ER0 F Y UW1 M", "P ER1 F Y UW0 M"],
	"perfumes": ["P ER0 F Y UW1 M Z"],
	"perhaps": ["P ER0 HH AE1 P S"],
	"perihelion": ["P EH2 R AH0 HH EH1 L IY0 AH0 N"],
	"peril": ["P EH1 R AH0 L"],
	"period": ["P IY1 R IY0 AH3 D", "P IY1 R Y AH0 D"],
	"periods": ["P IY1 R IY0 AH0 D Z", "P IY1 R Y AH0 D Z"],
	"periphrasis": ["P EH0 R IH1 F R AH0 S IH3 S"],
	"perish": ["P EH1 R IH0 SH"],
	"perjured": ["P ER1 JH ER0 D"],
	"perjury": ["P ER1 JH ER0 IY2"],
	"permanence": ["P ER1 M AH0 N AH2 N S"],
	"permission": ["P ER0 M IH1 SH AH0 N"],
	"permit": ["P ER0 M IH1 T", "P ER1 M IH2 T"],
	"permits": ["P ER0 M IH1 T S", "P ER1 M IH2 T S"],
	"permitted": ["P ER0 M IH1 T IH0 D"],
	"perpetual": ["P ER0 P EH1 CH UW0 AH0 L", "P ER0 P EH1 CH W AH0 L"],
	"perplex": ["P ER0 P L EH1 K S"],
	"perplexedly": ["P ER0 P L EH1 K S AH0 D L IY2"],
	"perplexity": ["P ER0 P L EH1 K S AH0 T IY3"],
	"persecute": ["P ER1 S AH0 K Y UW2 T"],
	"persepolis": ["P ER0 S EH1 P AH0 L AH2 S"],
	"persevered": ["P ER2 S AH0 V IY1 R D"],
	"persian": ["P ER1 ZH AH0 N"],
	"person": ["P ER1 S AH0 N"],
	"personal": ["P ER1 S IH0 N AH0 L"],
	"personification": ["P ER0 S AA2 N AH0 F AH0 K EY1 SH AH0 N"],
	"persuade": ["P ER0 S W EY1 D"],
	"persuaded": ["P ER0 S W EY1 D IH0 D"],
	"persuading": ["P ER0 S W EY1 D IH0 NG"],
	"persuasion": ["P ER0 S W EY1 ZH AH0 N"],
	"pert": ["P ER1 T"],
	"perturb": ["P ER0 T ER1 B"],
	"perturbation": ["P ER3 T ER0 B EY1 SH AH0 N"],
	"peru": ["P ER0 UW1"],
	"peruke": ["P AH0 R UW1 K"],
	"perusal": ["P ER0 UW1 Z AH0 L"],
	"peruse": ["P ER0 UW1 Z"],
	"pervade": ["P ER0 V EY1 D"],
	"perverse": ["P ER0 V ER1 S"],
	"pest": ["P EH1 S T"],
	"petal": ["P EH1 T AH0 L"],
	"peter": ["P IY1 T ER0"],
	"petite": ["P AH0 T IY1 T"],
	"petition": ["P AH0 T IH1 SH AH0 N"],
	"petname": ["P EH1 T N EY2 M"],
	"petty": ["P EH1 T IY2"],
	"pew": ["P Y UW1"],
	"phaeton": ["F EY1 T AH0 N"],
	"phantasm": ["F AE2 N T AE1 Z AH0 M"],
	"phantasy": ["F AE1 N T AH0 S IY3"],
	"phantom": ["F AE1 N T AH0 M"],
	"pharmacy": ["F AA1 R M AH0 S IY3"],
	"phase": ["F EY1 Z"],
	"phebe": ["F IY1 B IY0"],
	"phebes": ["F IY1 B Z"],
	"phemius": ["F IY1 M IH0 AH2 S"],
	"pherae": ["F EH1 R AY0"],
	"phial": ["F AY1 AH0 L"],
	"philosopher": ["F AH0 L AA1 S AH0 F ER0"],
	"philosophy": ["F AH0 L AA1 S AH0 F IY2"],
	"phlegmatic": ["F L EH0 G M AE1 T IH0 K"],
	"phoebus": ["F IY1 B UH0 S"],
	"phoenix": ["F IY1 N IH0 K S"],
	"phosphor": ["F AA1 S F AO2 R"],
	"phosphoric": ["F AA0 S F AO1 R IH0 K"],
	"phosphorus": ["F AA1 S F ER0 AH3 S"],
	"phrase": ["F R EY1 Z"],
	"phraseless": ["F R EY1 S L AH0 S"],
	"physic": ["F IH1 Z IH0 K"],
	"physicians": ["F AH0 Z IH1 SH AH0 N Z"],
	"physiognomy": ["F IY0 Z IY0 AA1 G N AH0 M IY2"],
	"piano": ["P IY0 AE1 N OW0", "P IY0 AE1 N AH0"],
	"piastres": ["P IH0 AE1 S T AH0 Z"],
	"pick": ["P IH1 K"],
	"pictorial": ["P IH0 K T AO1 R IY0 AH0 L", "P IH0 K T AO1 R Y AH0 L"],
	"picture": ["P IH1 K CH ER0"],
	"picturesque": ["P IH2 K CH ER0 EH1 S K"],
	"piece": ["P IY1 S"],
	"pieced": ["P IY1 S T"],
	"piecemeal": ["P IY1 S M IY2 L"],
	"pier": ["P IY1 R"],
	"pierce": ["P IY1 R S"],
	"pierced": ["P IY1 R S T"],
	"piercing": ["P IY1 R S IH0 NG"],
	"piercingly": ["P IY1 R S IH0 NG L IY3"],
	"piers": ["P IY1 R Z"],
	"piety": ["P AY1 AH0 T IY3"],
	"pigeon": ["P IH1 JH AH0 N"],
	"pight": ["P AY1 T"],
	"pike": ["P AY1 K"],
	"pilate": ["P AY1 L AH2 T"],
	"pile": ["P AY1 L"],
	"piled": ["P AY1 L D"],
	"pilfered": ["P IH1 L F ER0 D"],
	"pilgrim": ["P IH1 L G R AH0 M"],
	"pilgrimage": ["P IH1 L G R AH0 M AH2 JH"],
	"pillow": ["P IH1 L OW0"],
	"pilot": ["P AY1 L AH0 T"],
	"pin": ["P IH1 N"],
	"pinch": ["P IH1 N CH"],
	"pindar": ["P IH1 N D AA0 R"],
	"pine": ["P AY1 N"],
	"pined": ["P AY1 N D"],
	"pining": ["P AY1 N IH0 NG"],
	"pinion": ["P IH1 N Y AH0 N"],
	"pink": ["P IH1 NG K"],
	"pinnace": ["P IH1 N IH0 S"],
	"pinned": ["P IH1 N D"],
	"pioneer": ["P AY2 AH0 N IY1 R"],
	"pious": ["P AY1 AH0 S"],
	"pipe": ["P AY1 P"],
	"piping": ["P AY1 P IH0 NG"],
	"piracy": ["P AY1 R AH0 S IY3"],
	"pisan": ["P IY1 Z AH0 N"],
	"pit": ["P IH1 T"],
	"pitch": ["P IH1 CH"],
	"pith": ["P IH1 TH"],
	"pitied": ["P IH1 T IY0 D"],
	"pitiful": ["P IH1 T AH0 F AH3 L"],
	"pittance": ["P IH1 T AH0 N S"],
	"pity": ["P IH1 T IY0"],
	"pitying": ["P IH1 T IY0 IH3 NG", "P IH1 T Y IH0 NG"],
	"pizarro": ["P IH0 Z AA1 R OW0"],
	"plac'd": ["P L EY1 S T"],
	"place": ["P L EY1 S"],
	"placed": ["P L EY1 S T"],
	"placid": ["P L AE1 S IH0 D"],
	"placing": ["P L EY1 S IH0 NG"],
	"plague": ["P L EY1 G"],
	"plaid": ["P L AE1 D"],
	"plain": ["P L EY1 N"],
	"plan": ["P L AE1 N"],
	"plane": ["P L EY1 N"],
	"planet": ["P L AE1 N AH0 T"],
	"planetary": ["P L AE1 N AH0 T EH2 R IY0"],
	"plank": ["P L AE1 NG K"],
	"planned": ["P L AE1 N D"],
	"plant": ["P L AE1 N T"],
	"plash": ["P L AE1 SH"],
	"plate": ["P L EY1 T"],
	"plated": ["P L EY1 T IH0 D"],
	"platoon": ["P L AH0 T UW1 N"],
	"plaudit": ["P L AO1 D IH0 T"],
	"plausibility": ["P L AO2 Z IH0 B IH1 L IH0 T IY2"],
	"plausible": ["P L AO1 Z AH0 B AH3 L"],
	"plausibly": ["P L AO1 Z AH0 B L IY3"],
	"play": ["P L EY1"],
	"playfellow": ["P L EY1 F EH2 L OW0"],
	"playmate": ["P L EY1 M EY2 T"],
	"plaything": ["P L EY1 TH IH2 NG"],
	"plea": ["P L IY1"],
	"pleached": ["P L IY1 CH T"],
	"plead": ["P L IY1 D"],
	"pleadings": ["P L IY1 D IH0 NG Z"],
	"pleas'd": ["P L IY1 Z D"],
	"pleasant": ["P L EH1 Z AH0 N T"],
	"please": ["P L IY1 Z"],
	"pleasing": ["P L IY1 Z IH0 NG"],
	"pleasure": ["P L EH1 ZH ER0"],
	"pledge": ["P L EH1 JH"],
	"pledged": ["P L EH1 JH D"],
	"pleiad": ["P L IY1 AH0 D"],
	"plenitude": ["P L EH1 N IH0 T Y UW2 D"],
	"plenteous": ["P L EH1 N CH AH0 S"],
	"plenty": ["P L EH1 N T IY0"],
	"plied": ["P L AY1 D"],
	"plies": ["P L AY1 Z"],
	"plight": ["P L AY1 T"],
	"plot": ["P L AA1 T"],
	"plough": ["P L AW1"],
	"plover": ["P L AH1 V ER0"],
	"plow": ["P L AW1"],
	"pluck": ["P L AH1 K"],
	"plum": ["P L AH1 M"],
	"plumb": ["P L AH1 M"],
	"plume": ["P L UW1 M"],
	"plumed": ["P L UW1 M D"],
	"plump": ["P L AH1 M P"],
	"plunder": ["P L AH1 N D ER0"],
	"plunge": ["P L AH1 N JH"],
	"plural": ["P L UH1 R AH0 L"],
	"plus": ["P L AH1 S"],
	"plush": ["P L AH1 SH"],
	"pluto's": ["P L UW1 T OW0 Z"],
	"ply": ["P L AY1"],
	"plying": ["P L AY1 IH0 NG"],
	"pocahontas": ["P OW2 K AH0 HH AA1 N T AH0 S"],
	"pocket": ["P AA1 K IH0 T"],
	"pod": ["P AA1 D"],
	"poem": ["P OW1 AH0 M"],
	"poesy": ["P OW1 IH0 S IY3"],
	"poet": ["P OW1 IH0 T"],
	"poetry": ["P OW1 AH0 T R IY3"],
	"poignancy": ["P OY1 N Y AH0 N S IY3"],
	"poignant": ["P OY1 N Y AH0 N T"],
	"poinards": ["P OY1 N AA0 R D Z"],
	"point": ["P OY1 N T"],
	"pointers": ["P OY1 N T ER0 Z"],
	"poise": ["P OY1 Z"],
	"poised": ["P OY1 Z D"],
	"poison": ["P OY1 Z AH0 N"],
	"poize": ["P OY1 Z"],
	"polar": ["P OW1 L ER0"],
	"pole": ["P OW1 L"],
	"polesine": ["P OW1 L AH0 S AY2 N"],
	"police": ["P AH0 L IY1 S"],
	"polite": ["P AH0 L AY1 T"],
	"politic": ["P AA1 L AH0 T IH2 K"],
	"poll": ["P OW1 L"],
	"pollen": ["P AA1 L AH0 N"],
	"pollution": ["P AH0 L UW1 SH AH0 N"],
	"polydore": ["P AA1 L IH0 D AO2 R"],
	"polypheme": ["P AA1 L IY0 F IY2 M"],
	"pomp": ["P AA1 M P"],
	"pompadour": ["P AA1 M P AH0 D AO3 R"],
	"pompeii": ["P AA2 M P EY1", "P AA2 M P EY1 IY0"],
	"pompilius": ["P AH0 M P IH1 L IH0 AH2 S"],
	"pomposity": ["P AA2 M P AA1 S IH0 T IY2"],
	"pond": ["P AA1 N D"],
	"pond'ring": ["P AA1 N D R IH0 NG"],
	"ponder": ["P AA1 N D ER0"],
	"pondering": ["P AA1 N D ER0 IH3 NG", "P AA1 N D R IH0 NG"],
	"ponderous": ["P AA1 N D ER0 AH3 S"],
	"pontiff": ["P AA1 N T IH0 F"],
	"pontoon": ["P AA0 N T UW1 N"],
	"pool": ["P UW1 L"],
	"poor": ["P UW1 R"],
	"pope": ["P OW1 P"],
	"popocatapel": ["P OW2 P AH0 K AA2 T AH0 P EH1 L"],
	"poppy": ["P AA1 P IY0"],
	"populace": ["P AA1 P Y AH0 L AH0 S"],
	"popular": ["P AA1 P Y AH0 L ER0"],
	"populates": ["P AA1 P Y UH0 L EY2 T S"],
	"population": ["P AA2 P Y AH0 L EY1 SH AH0 N"],
	"populous": ["P AA1 P Y AH0 L AH2 S"],
	"porcelain": ["P AO1 R S AH0 L AH3 N"],
	"porch": ["P AO1 R CH"],
	"pored": ["P AO1 R D"],
	"porous": ["P AO1 R AH0 S"],
	"porpoise": ["P AO1 R P AH0 S"],
	"port": ["P AO1 R T"],
	"portable": ["P AO1 R T AH0 B AH3 L"],
	"portend": ["P AO0 R T EH1 N D"],
	"portent": ["P AO1 R T EH0 N T"],
	"portentous": ["P AO0 R T EH1 N T AH0 S"],
	"portico": ["P AO1 R T AH0 K OW2"],
	"portion": ["P AO1 R SH AH0 N"],
	"portly": ["P AO1 R T L IY0"],
	"portmanteau": ["P AO1 R T M AA0 N T OW2"],
	"portrait": ["P AO1 R T R IH0 T"],
	"pose": ["P OW1 Z"],
	"position": ["P AH0 Z IH1 SH AH0 N"],
	"positive": ["P AA1 Z AH0 T IH2 V"],
	"possess": ["P AH0 Z EH1 S"],
	"possession": ["P AH0 Z EH1 SH AH0 N"],
	"possibility": ["P AA2 S AH0 B IH1 L AH0 T IY2"],
	"possible": ["P AA1 S AH0 B AH0 L"],
	"possibler": ["P AA1 S AH0 B L ER0"],
	"possibly": ["P AA1 S AH0 B L IY3"],
	"possum": ["P AA1 S AH0 M"],
	"post": ["P OW1 S T"],
	"posterior": ["P AA0 S T IY1 R IY0 ER3", "P AA0 S T IY1 R Y ER0"],
	"posterity": ["P AA0 S T EH1 R AH0 T IY3"],
	"postpone": ["P OW2 S T P OW1 N"],
	"posture": ["P AA1 S CH ER0"],
	"posy": ["P OW1 Z IY0"],
	"pot": ["P AA1 T"],
	"potatoes": ["P AH0 T EY1 T OW0 Z"],
	"potent": ["P OW1 T AH0 N T"],
	"potentes": ["P AO0 T EH1 N T EH0 S"],
	"potential": ["P AH0 T EH1 N SH AH0 L"],
	"potion": ["P OW1 SH AH0 N"],
	"potomac": ["P AH0 T OW1 M AH0 K"],
	"pounce": ["P AW1 N S"],
	"pound": ["P AW1 N D"],
	"pour": ["P AO1 R"],
	"poverty": ["P AA1 V ER0 T IY2"],
	"pow'r": ["P AW1 R", "P AW1 ER0"],
	"pow'rs": ["P AW1 R Z", "P AW1 ER0 Z"],
	"powder": ["P AW1 D ER0"],
	"power": ["P AW1 R", "P AW1 ER0"],
	"powerful": ["P AW1 ER0 F AH3 L"],
	"powerless": ["P AW1 R L AH0 S", "P AW1 ER0 L AH3 S"],
	"powers": ["P AW1 R Z", "P AW1 ER0 Z"],
	"pox": ["P AA1 K S"],
	"practise": ["P R AE1 K T IH0 S"],
	"practised": ["P R AE1 K T IH0 S T"],
	"practising": ["P R AE1 K T IH0 S IH0 NG"],
	"prairie": ["P R EH1 R IY0"],
	"praise": ["P R EY1 Z"],
	"praised": ["P R EY1 Z D"],
	"praising": ["P R EY1 Z IH0 NG"],
	"prance": ["P R AE1 N S"],
	"prank": ["P R AE1 NG K"],
	"prate": ["P R EY1 T"],
	"pray": ["P R EY1"],
	"pray'r": ["P R EH1 R"],
	"pray'rs": ["P R EH1 R Z"],
	"prayer": ["P R EH1 R", "P R EY1 ER0"],
	"prayers": ["P R EH1 R Z"],
	"preach": ["P R IY1 CH"],
	"preachers": ["P R IY1 CH ER0 Z"],
	"preappointed": ["P R IY2 AH0 P OY1 N T IH0 D"],
	"precarious": ["P R IY0 K EH1 R IY0 AH0 S"],
	"precede": ["P R IH0 S IY1 D"],
	"preceded": ["P R IY0 S IY1 D IH0 D"],
	"preceding": ["P R IY0 S IY1 D IH0 NG"],
	"precinct": ["P R IY1 S IH2 NG K T"],
	"precious": ["P R EH1 SH AH0 S"],
	"precipice": ["P R EH1 S AH0 P AH3 S"],
	"precipitate": ["P R IH0 S IH1 P IH0 T EY2 T"],
	"precipitation": ["P R IH0 S IH2 P IH0 T EY1 SH AH0 N"],
	"precise": ["P R IH0 S AY1 S"],
	"precision": ["P R IY0 S IH1 ZH AH0 N"],
	"preclude": ["P R IH0 K L UW1 D"],
	"precocious": ["P R IH0 K OW1 SH AH0 S"],
	"preconcerted": ["P R IY2 K AH0 N S ER1 T IH0 D"],
	"predestined": ["P R IY2 D EH1 S T AH0 N D"],
	"predict": ["P R IH0 D IH1 K T"],
	"prediluvian": ["P R IY2 D AH0 L UW1 V Y AH0 N"],
	"preface": ["P R EH1 F AH0 S"],
	"prefer": ["P R AH0 F ER1"],
	"preferment": ["P R AH0 F ER1 M AH0 N T"],
	"preferred": ["P R IY0 F ER1 D"],
	"preferring": ["P R IH0 F ER1 IH0 NG"],
	"prefiguring": ["P R IY2 F IH1 G Y ER0 IH2 NG"],
	"pregnant": ["P R EH1 G N AH0 N T"],
	"prepar'd": ["P R IH0 P EH1 R D"],
	"preparation": ["P R EH2 P ER0 EY1 SH AH0 N"],
	"prepare": ["P R IY0 P EH1 R"],
	"prepared": ["P R IY0 P EH1 R D"],
	"presage": ["P R EH1 S IH0 JH"],
	"prescience": ["P R EH1 SH IY0 AH3 N S", "P R EH SH AH0 N S"],
	"prescrib'd": ["P R AH0 S K R AY1 B D"],
	"presence": ["P R EH1 Z AH0 N S"],
	"present": ["P R EH1 Z AH0 N T", "P R IY0 Z EH1 N T"],
	"presentiments": ["P R IH0 Z EH1 N T IH0 M AH2 N T S"],
	"presently": ["P R EH1 Z AH0 N T L IY3"],
	"presents": ["P R EH1 Z AH0 N T S", "P R IY0 Z EH1 N T S"],
	"preservative": ["P R IY0 Z ER1 V AH0 T IH2 V"],
	"preserved": ["P R IH0 Z ER1 V D"],
	"preserving": ["P R AH0 Z ER1 V IH0 NG"],
	"preside": ["P R IH0 Z AY1 D"],
	"press": ["P R EH1 S"],
	"prest": ["P R EH1 S T"],
	"presume": ["P R IH0 Z UW1 M"],
	"presumed": ["P R IH0 Z UW1 M D"],
	"presuming": ["P R IH0 Z UW1 M IH0 NG"],
	"presumption": ["P R IH0 Z AH1 M P SH AH0 N"],
	"presumptuous": ["P R IH0 Z AH1 M P CH AH0 W AH3 S"],
	"pretence": ["P R IY1 T EH0 N S"],
	"pretend": ["P R IY0 T EH1 N D"],
	"pretense": ["P R IY0 T EH1 N S"],
	"pretty": ["P R IH1 T IY0"],
	"prevail": ["P R IH0 V EY1 L"],
	"prevalent": ["P R EH1 V AH0 L AH3 N T"],
	"prevent": ["P R IH0 V EH1 N T"],
	"previous": ["P R IY1 V IY0 AH0 S"],
	"prey": ["P R EY1"],
	"preyest": ["P R EY1 IH0 S T", "P R EY1 S T"],
	"priam": ["P R IY1 AH0 M"],
	"price": ["P R AY1 S"],
	"prick": ["P R IH1 K"],
	"pricklier": ["P R IH1 K L IY0 ER3", "P R IH1 K L Y ER0"],
	"prickly": ["P R IH1 K L IY0"],
	"pride": ["P R AY1 D"],
	"prided": ["P R AY1 D IH0 D"],
	"priest": ["P R IY1 S T"],
	"priestess": ["P R IY1 S T AH0 S"],
	"prig": ["P R IH1 G"],
	"prim": ["P R IH1 M"],
	"prima": ["P R IY1 M AH0"],
	"prime": ["P R AY1 M"],
	"primer": ["P R AY1 M ER0"],
	"primeval": ["P R AY0 M IY1 V AH0 L"],
	"primrose": ["P R IH1 M R OW2 Z"],
	"prince": ["P R IH1 N S"],
	"principal": ["P R IH1 N S AH0 P AH2 L"],
	"principle": ["P R IH1 N S AH0 P AH3 L"],
	"print": ["P R IH1 N T"],
	"prints": ["P R IH1 N T S"],
	"priscilla": ["P R IH0 S IH1 L AH0"],
	"prism": ["P R IH1 Z AH0 M"],
	"prison": ["P R IH1 Z AH0 N"],
	"prisoner": ["P R IH1 Z AH0 N ER0", "P R IH1 Z N ER0"],
	"prisonwall": ["P R IH1 Z AH0 N W AO1 L"],
	"prithee": ["P R IH1 DH IY0"],
	"privacy": ["P R AY1 V AH0 S IY3"],
	"private": ["P R AY1 V IH0 T"],
	"privilege": ["P R IH1 V L IH0 JH", "P R IH1 V AH0 L IH3 JH"],
	"prize": ["P R AY1 Z"],
	"prized": ["P R AY1 Z D"],
	"probability": ["P R AA2 B AH0 B IH1 L AH0 T IY2"],
	"probable": ["P R AA1 B AH0 B AH0 L"],
	"probe": ["P R OW1 B"],
	"probing": ["P R OW1 B IH0 NG"],
	"probity": ["P R OW1 B AH0 T IY3"],
	"problem": ["P R AA1 B L AH0 M"],
	"proceed": ["P R OW0 S IY1 D"],
	"proceedings": ["P R OW0 S IY1 D IH0 NG Z"],
	"proceeds": ["P R AH0 S IY1 D Z", "P R OW1 S IY0 D Z"],
	"process": ["P R AA1 S EH0 S"],
	"procession": ["P R OW0 S EH1 SH AH0 N"],
	"proclaim": ["P R OW0 K L EY1 M"],
	"procure": ["P R OW0 K Y UH1 R"],
	"prodigal": ["P R AA1 D IH0 G AH0 L"],
	"prodigious": ["P R AH0 D IH1 JH AH0 S"],
	"prodigy": ["P R AA1 D AH0 JH IY3"],
	"produce": ["P R AH0 D UW1 S", "P R OW1 D UW0 S"],
	"produced": ["P R AH0 D UW1 S T"],
	"produces": ["P R AH0 D UW1 S IH0 Z"],
	"producing": ["P R AH0 D UW1 S IH0 NG"],
	"product": ["P R AA1 D AH0 K T"],
	"production": ["P R AH0 D AH1 K SH AH0 N"],
	"profane": ["P R OW0 F EY1 N"],
	"profaned": ["P R OW0 F EY1 N D"],
	"profess": ["P R AH0 F EH1 S"],
	"proffered": ["P R AA1 F ER0 D"],
	"profile": ["P R OW1 F AY2 L"],
	"profound": ["P R OW0 F AW1 N D"],
	"profuse": ["P R AH0 F Y UW1 S"],
	"profusion": ["P R AH0 F Y UW1 ZH AH0 N"],
	"progenitor": ["P R OW2 JH EH1 N IH0 T ER3"],
	"progeny": ["P R AA1 JH AH0 N IY3"],
	"prognosticate": ["P R AA2 G N AA1 S T AH0 K EY2 T"],
	"programme": ["P R OW1 G R AE2 M"],
	"progress": ["P R AA1 G R EH0 S", "P R AH0 G R EH1 S"],
	"project": ["P R AH0 JH EH1 K T", "P R AA1 JH EH0 K T"],
	"projects": ["P R AH0 JH EH1 K T S", "P R AA1 JH EH0 K T"],
	"prologue": ["P R OW1 L AA0 G"],
	"prolong": ["P R OW0 L AA1 NG"],
	"promis'd": ["P R AA1 M AH0 S T"],
	"promise": ["P R AA1 M AH0 S"],
	"promised": ["P R AA1 M AH0 S T"],
	"promontory": ["P R AA1 M AH0 N T AO2 R IY0"],
	"promote": ["P R AH0 M OW1 T"],
	"promoted": ["P R AH0 M OW1 T IH0 D"],
	"prompt": ["P R AA1 M P T"],
	"prompter's": ["P R AA1 M P T ER0 Z"],
	"prone": ["P R OW1 N"],
	"prong": ["P R AO1 NG"],
	"pronounced": ["P R AH0 N AW1 N S T"],
	"proof": ["P R UW1 F"],
	"prop": ["P R AA1 P"],
	"proper": ["P R AA1 P ER0"],
	"properties": ["P R AA1 P ER0 T IY3 Z"],
	"property": ["P R AA1 P ER0 T IY3"],
	"prophecies": ["P R AA1 F AH0 S IY2 Z"],
	"prophecy": ["P R AA1 F AH0 S IY3"],
	"prophesy": ["P R AA1 F IH0 S AY2"],
	"prophet": ["P R AA1 F IH0 T"],
	"propitiate": ["P R AH0 P IH1 SH IY0 EY2 T"],
	"propitious": ["P R AH0 P IH1 SH AH0 S"],
	"proportion": ["P R AH0 P AO1 R SH AH0 N"],
	"propose": ["P R AH0 P OW1 Z"],
	"proposed": ["P R AH0 P OW1 Z D"],
	"propound": ["P R AH0 P AW1 N D"],
	"propped": ["P R AA1 P T"],
	"propping": ["P R AA1 P IH0 NG"],
	"prosaic": ["P R OW0 Z EY1 IH0 K"],
	"prose": ["P R OW1 Z"],
	"prospect": ["P R AA1 S P EH2 K T"],
	"prospective": ["P R AH0 S P EH1 K T IH0 V"],
	"prosperity": ["P R AA0 S P EH1 R AH0 T IY3"],
	"prosy": ["P R OW1 Z IY0"],
	"protect": ["P R AH0 T EH1 K T"],
	"protection": ["P R AH0 T EH1 K SH AH0 N"],
	"protest": ["P R OW1 T EH2 S T", "P R AH0 T EH1 S T"],
	"proteus": ["P R OW1 T Y AH0 S"],
	"protract": ["P R OW0 T R AE1 K T"],
	"proud": ["P R AW1 D"],
	"prov'd": ["P R UW1 V D"],
	"prove": ["P R UW1 V"],
	"proved": ["P R UW1 V D"],
	"provençal": ["P R AA2 V AA0 N S AA1 L", "P R AH0 V AA1 N S AH0 L"],
	"provence": ["P R AH0 V AH1 N S"],
	"provide": ["P R AH0 V AY1 D"],
	"provided": ["P R AH0 V AY1 D IH0 D"],
	"providence": ["P R AA1 V AH0 D AH3 N S"],
	"province": ["P R AA1 V IH0 N S"],
	"provinces": ["P R AA1 V AH0 N S AH0 Z"],
	"provincial": ["P R AH0 V IH1 N SH AH0 L"],
	"provincially": ["P R AH0 V IH1 N SH AH0 L IY3"],
	"provision": ["P R AH0 V IH1 ZH AH0 N"],
	"provok'd": ["P R AH0 V OW1 K T"],
	"provoke": ["P R AH0 V OW1 K"],
	"provoked": ["P R AH0 V OW1 K T"],
	"prow": ["P R AW1"],
	"prowess": ["P R AW1 AH0 S"],
	"prowling": ["P R AW1 L IH0 NG"],
	"prudent": ["P R UW1 D AH0 N T"],
	"pry": ["P R AY1"],
	"psalm": ["S AA1 L M", "S AA1 M"],
	"psychoanalyst": ["S AY2 K OW0 AE1 N AH0 L AH2 S T"],
	"pub": ["P AH1 B"],
	"puberty": ["P Y UW1 B ER0 T IY2"],
	"public": ["P AH1 B L IH0 K"],
	"publication": ["P AH2 B L IH0 K EY1 SH AH0 N"],
	"publicity": ["P AH0 B L IH1 S AH0 T IY3"],
	"publish": ["P AH1 B L IH0 SH"],
	"puck": ["P AH1 K"],
	"puff": ["P AH1 F"],
	"pull": ["P UH1 L"],
	"pulpit": ["P UH1 L P IH0 T"],
	"pulpy": ["P AH1 L P IY0"],
	"pulse": ["P AH1 L S"],
	"pump": ["P AH1 M P"],
	"pumpkin": ["P AH1 M P K IH0 N"],
	"puncheon": ["P AH1 N CH AH0 N"],
	"punctual": ["P AH1 NG K CH W AH0 L", "P AH1 NG K CH UW0 AH3 L"],
	"punctuate": ["P AH1 NG CH UH0 EY2 T", "P AH1 NG CH W EY0 T"],
	"pungent": ["P AH1 N JH AH0 N T"],
	"punish": ["P AH1 N IH0 SH"],
	"punishment": ["P AH1 N IH0 SH M AH2 N T"],
	"puppet": ["P AH1 P IH0 T"],
	"purchase": ["P ER1 CH AH0 S"],
	"purchaser": ["P ER1 CH AH0 S ER3"],
	"purchasing": ["P ER1 CH AH0 S IH2 NG"],
	"pure": ["P Y UH1 R"],
	"purely": ["P Y UH1 R L IY0"],
	"purer": ["P Y UH1 R ER0"],
	"purest": ["P Y UH1 R AH0 S T"],
	"purge": ["P ER1 JH"],
	"purification": ["P Y UH2 R IH0 F IH0 K EY1 SH AH0 N"],
	"purify": ["P Y UH1 R AH0 F AY2"],
	"purity": ["P Y UH1 R AH0 T IY3"],
	"purple": ["P ER1 P AH0 L"],
	"purplish": ["P ER1 P L IH0 SH"],
	"purply": ["P ER1 P L IY0"],
	"purpose": ["P ER1 P AH0 S"],
	"purposed": ["P ER1 P AH0 S T"],
	"purpureal": ["P ER0 P ER1 Y AH0 L"],
	"purr": ["P ER1"],
	"purse": ["P ER1 S"],
	"pursue": ["P ER0 S UW1"],
	"pursued": ["P ER0 S UW1 D"],
	"pursuing": ["P ER0 S UW1 IH0 NG"],
	"pursuit": ["P ER0 S UW1 T"],
	"purveyor": ["P ER0 V EY1 ER0"],
	"push": ["P UH1 SH"],
	"pussy": ["P UH1 S IY0"],
	"put": ["P UH1 T"],
	"putteth": ["P UH1 T IH0 TH"],
	"putting": ["P UH1 T IH0 NG", "P AH1 T IH0 NG"],
	"puzzle": ["P AH1 Z AH0 L"],
	"puzzled": ["P AH1 Z AH0 L D"],
	"puzzlest": ["P AH1 Z L IH0 S T"],
	"puzzling": ["P AH1 Z AH0 L IH3 NG", "P AH1 Z L IH0 NG"],
	"pyramid": ["P IY1 R AH0 M IH2 D"],
	"pyramidal": ["P IY2 R AH0 M IH1 D AH0 L"],
	"pyre": ["P AY1 ER0", "P AY1 R"],
	"pyrrhenees": ["P IH2 R AH0 N IY1 Z"],
	"pyrrhus": ["P IY1 R UH0 S"],
	"python": ["P AY1 TH AA0 N"],
	"quaff": ["K W AA1 F"],
	"quaffed": ["K W AA1 F AH0 D"],
	"quail": ["K W EY1 L"],
	"quaint": ["K W EY1 N T"],
	"quake": ["K W EY1 K"],
	"qualified": ["K W AA1 L AH0 F AY2 D"],
	"qualify": ["K W AA1 L AH0 F AY2"],
	"qualities": ["K W AA1 L AH0 T IY2 Z"],
	"quality": ["K W AA1 L AH0 T IY2"],
	"qualm": ["K W AA1 M"],
	"quantity": ["K W AA1 N T AH0 T IY3"],
	"quarrel": ["K W AO1 R AH0 L"],
	"quarrelling": ["K W AA1 R EH0 L IH3 NG"],
	"quarries": ["K W AO1 R IY0 Z"],
	"quarry": ["K W AO1 R IY0"],
	"quarter": ["K W AO1 R T ER0"],
	"quarterly": ["K W AO1 R T ER0 L IY3"],
	"quartz": ["K W AO1 R T S"],
	"quavering": ["K W EY1 V R IH0 NG", "K W EY1 V ER0 IH3 NG"],
	"quay": ["K IY1"],
	"queen": ["K W IY1 N"],
	"quell": ["K W EH1 L"],
	"quench": ["K W EH1 N CH"],
	"quest": ["K W EH1 S T"],
	"question": ["K W EH1 S CH AH0 N"],
	"quibbled": ["K W IH1 B AH0 L D"],
	"quick": ["K W IH1 K"],
	"quicken": ["K W IH1 K AH0 N"],
	"quickening": ["K W IH1 K AH0 N IH3 NG", "K W IH1 K N IH0 NG"],
	"quiet": ["K W AY1 IH0 T"],
	"quietly": ["K W AY1 AH0 T L IY3"],
	"quietude": ["K W AY1 IH0 T Y UW2 D"],
	"quietus": ["K W AY0 IY1 D AH0 S"],
	"quill": ["K W IH1 L"],
	"quintessence": ["K W IH0 N T EH1 S AH0 N S"],
	"quire": ["K W AY1 R","K W AY1 ER0"],
	"quired": ["K W AY1 R D","K W AY1 ER0 D"],
	"quit": ["K W IH1 T"],
	"quite": ["K W AY1 T"],
	"quiver": ["K W IH1 V ER0"],
	"quivering": ["K W IH1 V R IH0 NG", "K W IH1 V ER0 IH3 NG"],
	"quoits": ["K OY1 T S"],
	"quote": ["K W OW1 T"],
	"quoted": ["K W OW1 T AH0 D"],
	"quoth": ["K W OW1 TH"],
	"r": ["AA1 R"],
	"rabbi": ["R AE1 B AY0"],
	"rabbit": ["R AE1 B IH0 T"],
	"race": ["R EY1 S"],
	"rack": ["R AE1 K"],
	"racked": ["R AE1 K T"],
	"radiant": ["R EY1 D IY2 AH0 N T", "R EY1 D Y AH0 N T"],
	"raft": ["R AE1 F T"],
	"rag": ["R AE1 G"],
	"rag'd": ["R EY1 JH D"],
	"rage": ["R EY1 JH"],
	"raged": ["R EY1 JH D"],
	"ragged": ["R AE1 G IH0 D"],
	"rail": ["R EY1 L"],
	"raiment": ["R EY1 M AH0 N T"],
	"rain": ["R EY1 N"],
	"rainbow": ["R EY1 N B OW2"],
	"rais'd": ["R EY1 S T"],
	"raise": ["R EY1 Z"],
	"raised": ["R EY1 Z D"],
	"raising": ["R EY1 Z IH0 NG"],
	"rallies": ["R AE1 L IY0 Z"],
	"rallying": ["R AE1 L IY0 IH0 NG"],
	"ramble": ["R AE1 M B AH0 L"],
	"rambled": ["R AE1 M B AH0 L D"],
	"rambles": ["R AE1 M B AH0 L Z"],
	"rampant": ["R AE1 M P AH0 N T"],
	"ran": ["R AE1 N"],
	"random": ["R AE1 N D AH0 M"],
	"rang": ["R AE1 NG"],
	"rang'd": ["R EY1 N JH D"],
	"range": ["R EY1 N JH"],
	"ranged": ["R EY1 N JH D"],
	"ranger": ["R EY1 N JH ER0"],
	"rank": ["R AE1 NG K"],
	"ransack": ["R AE1 N S AE2 K"],
	"rap": ["R AE1 P"],
	"rapid": ["R AE1 P IH0 D"],
	"rapping": ["R AE1 P IH0 NG"],
	"rapt": ["R AE1 P T"],
	"rapture": ["R AE1 P CH ER0"],
	"rare": ["R EH1 R"],
	"rarities": ["R EH1 R IH0 T IY2 Z"],
	"rascal": ["R AE1 S K AH0 L"],
	"rash": ["R AE1 SH"],
	"rat": ["R AE1 T"],
	"rate": ["R EY1 T"],
	"rather": ["R AE1 DH ER0"],
	"ratified": ["R AE1 T AH0 F AY2 D"],
	"ratio": ["R EY1 SH IY0 OW2"],
	"rattle": ["R AE1 T AH0 L"],
	"raught": ["R AO1 T"],
	"ravage": ["R AE1 V IH0 JH"],
	"rave": ["R EY1 V"],
	"ravelled": ["R AE1 V AH0 L D"],
	"ravelling": ["R AE1 V L IH0 NG"],
	"raven": ["R EY1 V AH0 N"],
	"ravine": ["R AH0 V IY1 N"],
	"raving": ["R EY1 V IH0 NG"],
	"ravish": ["R AE1 V IH0 SH"],
	"raw": ["R AA1"],
	"ray": ["R EY1"],
	"raz'd": ["R EY1 Z D"],
	"reach": ["R IY1 CH"],
	"reachers": ["R IY1 CH AH0 Z"],
	"read": ["R EH1 D", "R IY1 D"],
	"readings": ["R IY1 D IH0 NG Z"],
	"ready": ["R EH1 D IY0"],
	"real": ["R IY1 L"],
	"realities": ["R IY2 AE1 L AH0 T IY2 Z"],
	"reality": ["R IY2 AE1 L AH0 T IY3"],
	"realized": ["R IY1 AH0 L AY2 Z D"],
	"really": ["R IH1 L IY0"],
	"realm": ["R EH1 L M"],
	"reap": ["R IY1 P"],
	"reapers": ["R IY1 P ER0 Z"],
	"reappearing": ["R IY2 AH0 P IY1 R IH0 NG"],
	"rear": ["R IY1 R"],
	"reason": ["R IY1 Z AH0 N"],
	"reassure": ["R IY2 AH0 SH UH1 R"],
	"rebel": ["R EH1 B AH0 L"],
	"rebelled": ["R IH0 B EH1 L D"],
	"rebellious": ["R IH0 B EH1 L Y AH0 S"],
	"rebels": ["R EH1 B AH0 L Z", "R IH0 B EH1 L Z"],
	"rebound": ["R IY0 B AW1 N D"],
	"rebounds": ["R IY1 B AW2 N D Z"],
	"rebuilt": ["R IY0 B IH1 L T"],
	"rebuke": ["R IY0 B Y UW1 K"],
	"rebut": ["R IH0 B AH1 T"],
	"recall": ["R IH0 K AO1 L", "R IY1 K AO2 L"],
	"recant": ["R IY0 K AE1 N T"],
	"recede": ["R IH0 S IY1 D"],
	"receding": ["R IY0 S IY1 D IH0 NG"],
	"receipt": ["R IH0 S IY1 T"],
	"receive": ["R IH0 S IY1 V"],
	"received": ["R IH0 S IY1 V D"],
	"receivest": ["R IH0 S IY1 V IH2 S T"],
	"recent": ["R IY1 S AH0 N T"],
	"receptacle": ["R IH0 S EH1 P T IH0 K AH3 L"],
	"reception": ["R IH0 S EH1 P SH AH0 N"],
	"recess": ["R IH0 S EH1 S", "R IY1 S EH0 S"],
	"recitation": ["R EH2 S AH0 T EY1 SH AH0 N"],
	"recite": ["R AH0 S AY1 T"],
	"recklessly": ["R EH1 K L AH0 S L IY3"],
	"reckon": ["R EH1 K AH0 N"],
	"reckoning": ["R EH1 K AH0 N IH3 NG", "R EH1 K N IH0 NG"],
	"reclaim": ["R IY0 K L EY1 M"],
	"recline": ["R IH0 K L AY1 N"],
	"reclined": ["R IH0 K L AY1 N D"],
	"reclining": ["R IH0 K L AY1 N IH0 NG"],
	"recluse": ["R IH0 K L UW1 S"],
	"recognition": ["R EH2 K AH0 G N IH1 SH AH0 N"],
	"recognize": ["R EH1 K AH0 G N AY2 Z"],
	"recognized": ["R EH1 K AH0 G N AY2 Z D"],
	"recognizing": ["R EH1 K AH0 G N AY2 Z IH0 NG"],
	"recoil": ["R IY0 K OY1 L"],
	"recollect": ["R EH2 K AH0 L EH1 K T"],
	"recollection": ["R EH2 K AH0 L EH1 K SH AH0 N"],
	"recommenced": ["R IY2 K AH0 M EH1 N S T"],
	"recommend": ["R EH2 K AH0 M EH1 N D"],
	"recompense": ["R EH1 K AH0 M P EH2 N S"],
	"reconcile": ["R EH1 K AH0 N S AY2 L"],
	"reconciled": ["R EH1 K AH0 N S AY2 L D"],
	"reconciliation": ["R EH2 K AH0 N S IH2 L IY0 EY1 SH AH0 N"],
	"record": ["R IH0 K AO1 R D", "R EH1 K ER0 D"],
	"recorded": ["R IH0 K AO1 R D IH0 D"],
	"recordless": ["R IH0 K AO1 R D L AH0 S"],
	"records": ["R AH0 K AO1 R D Z"],
	"recourse": ["R IY1 K AO0 R S"],
	"recovered": ["R AH0 K AH1 V ER0 D"],
	"recross": ["R IY0 K R AO1 S"],
	"recruit": ["R AH0 K R UW1 T"],
	"rectified": ["R EH1 K IH0 F AY2 D"],
	"rectitude": ["R EH1 K T IH0 T UW2 D"],
	"recur": ["R IH0 K ER1"],
	"red": ["R EH1 D"],
	"redbreast": ["R EH1 D B R EH2 S T"],
	"redder": ["R EH1 D ER0"],
	"reddest": ["R EH1 D IH0 S T"],
	"redeem": ["R IH0 D IY1 M"],
	"redemption": ["R IH0 D EH1 M P SH AH0 N"],
	"redignified": ["R IY0 D IH1 G N AH0 F AY2 D"],
	"redress": ["R IH0 D R EH1 S"],
	"reduce": ["R IH0 D UW1 S"],
	"reduced": ["R IH0 D UW1 S T"],
	"reed": ["R IY1 D"],
	"reef": ["R IY1 F"],
	"reek": ["R IY1 K"],
	"reeking": ["R IY1 K IH0 NG"],
	"reel": ["R IY1 L"],
	"refer": ["R AH0 F ER1"],
	"refine": ["R IH0 F AY1 N"],
	"refit": ["R IY0 F IH1 T"],
	"reflect": ["R IH0 F L EH1 K T"],
	"reflecting": ["R IH0 F L EH1 K T IH0 NG"],
	"reflex": ["R IY1 F L EH2 K S"],
	"reform": ["R IH0 F AO1 R M"],
	"reformation": ["R EH2 F ER0 M EY1 SH AH0 N"],
	"refract": ["R IH0 F R AE1 K T"],
	"refrain": ["R IH0 F R EY1 N"],
	"refresh": ["R IH0 F R EH1 SH"],
	"refreshfully": ["R IH0 F R EH1 SH F UH0 L IY3"],
	"refund": ["R IY1 F AH2 N D", "R IH0 F AH1 N D"],
	"refuse": ["R EH1 F Y UW2 Z", "R IH0 F Y UW1 Z"],
	"refusest": ["R IH0 F Y UW1 Z IH2 S T"],
	"refute": ["R IH0 F Y UW1 T"],
	"regain": ["R IY0 G EY1 N"],
	"regal": ["R IY1 G AH0 L"],
	"regality": ["R IH0 G AE1 L IH0 T IY3"],
	"regally": ["R IY1 G AH0 L IY3"],
	"regard": ["R IH0 G AA1 R D"],
	"regimen": ["R EH1 JH AH0 M AH0 N"],
	"regiments": ["R EH1 JH AH0 M AH0 N T S"],
	"region": ["R IY1 JH AH0 N"],
	"register": ["R EH1 JH IH0 S T ER2"],
	"regret": ["R IH0 G R EH1 T"],
	"regularity": ["R EH2 G Y AH0 L EH1 R AH0 T IY2"],
	"regulate": ["R EH1 G Y AH0 L EY2 T"],
	"rehearsal": ["R IH0 HH ER1 S AH0 L"],
	"rehearse": ["R IY0 HH ER1 S"],
	"reign": ["R EY1 N"],
	"reimburse": ["R IY2 IH0 M B ER1 S"],
	"rein": ["R EY1 N"],
	"reinforced": ["R IY2 IH0 N F AO1 R S T"],
	"reinvest": ["R IY2 IH0 N V EH1 S T"],
	"reject": ["R IH0 JH EH1 K T", "R IY1 JH EH0 K T"],
	"rejoice": ["R IH0 JH OY1 S"],
	"rejoin": ["R IY0 JH OY1 N"],
	"relapse": ["R IY1 L AE2 P S", "R AH0 L AE1 P S"],
	"relate": ["R IH0 L EY1 T"],
	"relation": ["R IY0 L EY1 SH AH0 N"],
	"relationship": ["R IY0 L EY1 SH AH0 N SH IH2 P"],
	"relative": ["R EH1 L AH0 T IH2 V"],
	"release": ["R IY0 L IY1 S"],
	"released": ["R IY0 L IY1 S T"],
	"releasing": ["R IY0 L IY1 S IH0 NG"],
	"relent": ["R IH0 L EH1 N T"],
	"relevancy": ["R EH1 L AH0 V AH2 N S IY0"],
	"relief": ["R IH0 L IY1 F"],
	"relies": ["R IH0 L AY1 Z"],
	"reliev'd": ["R IH0 L IY1 V D"],
	"relieve": ["R IH0 L IY1 V"],
	"relieved": ["R IH0 L IY1 V D"],
	"religion": ["R IH0 L IH1 JH AH0 N"],
	"relinquish": ["R IH0 L IH1 NG K W IH0 SH"],
	"relish": ["R EH1 L IH0 SH"],
	"reluctant": ["R IH0 L AH1 K T AH0 N T"],
	"rely": ["R IH0 L AY1"],
	"remain": ["R IH0 M EY1 N"],
	"remand": ["R IH0 M AE1 N D"],
	"remark": ["R IH0 M AA1 R K"],
	"remedy": ["R EH1 M AH0 D IY3"],
	"remember": ["R IH0 M EH1 M B ER0"],
	"remembering": ["R IH0 M EH1 M B ER0 IH3 NG", "R IH0 M EH1 M B R IH0 NG"],
	"remembrance": ["R IY0 M EH1 M B R AH0 N S"],
	"remind": ["R IY0 M AY1 N D"],
	"remit": ["R IY0 M IH1 T"],
	"remnant": ["R EH1 M N AH0 N T"],
	"remorse": ["R IH0 M AO1 R S"],
	"remote": ["R IH0 M OW1 T"],
	"remoter": ["R AH0 M OW1 T ER0"],
	"remotest": ["R IH0 M OW1 T AH0 S T"],
	"remov'd": ["R IY0 M UW1 V D"],
	"remove": ["R IY0 M UW1 V"],
	"removed": ["R IY0 M UW1 V D"],
	"remover": ["R IY0 M UW1V ER0"],
	"removing": ["R IH0 M UW1 V IH0 NG"],
	"rend": ["R EH1 N D"],
	"render": ["R EH1 N D ER0"],
	"renew": ["R IH0 N UW1"],
	"renewable": ["R IY0 N UW1 AH0 B AH0 L"],
	"renounce": ["R IH0 N AW1 N S"],
	"renounced": ["R IH0 N AW1 N S T"],
	"renown": ["R IH0 N AW1 N"],
	"rent": ["R EH1 N T"],
	"renunciation": ["R IH0 N AH2 N S IY0 EY1 SH AH0 N"],
	"renunciative": ["R IH0 N AH1 N S Y AH0 T IH3 V"],
	"reorganizes": ["R IY2 AO1 R G AH0 N AY2 Z IH0 Z"],
	"repair": ["R IH0 P EH1 R"],
	"repay": ["R IY0 P EY1"],
	"repeal": ["R IH0 P IY1 L"],
	"repealless": ["R IH0 P IY1 L L AH0 S"],
	"repeat": ["R IH0 P IY1 T"],
	"repeaters": ["R IH0 P IY1 T ER0 Z"],
	"repel": ["R IH0 P EH1 L"],
	"repent": ["R IH0 P EH1 N T"],
	"repentance": ["R IH0 P EH1 N T AH0 N S"],
	"repine": ["R IH0 P AY1 N"],
	"repined": ["R IH0 P AY1 N D"],
	"replace": ["R IY2 P L EY1 S"],
	"replenished": ["R IY0 P L EH1 N IH0 SH T"],
	"replied": ["R IH0 P L AY1 D"],
	"replies": ["R IH0 P L AY1 Z"],
	"reply": ["R IH0 P L AY1"],
	"replying": ["R IH0 P L AY1 IH0 NG"],
	"report": ["R IH0 P AO1 R T"],
	"repose": ["R IY0 P OW1 Z"],
	"reposed": ["R IH0 P OW1 Z D"],
	"reposing": ["R IH0 P OW1 Z IH0 NG"],
	"representative": ["R EH2 P R AH0 Z EH1 N T AH0 T IH3 V"],
	"repress": ["R IY0 P R EH1 S"],
	"repression": ["R IY0 P R EH1 SH AH0 N"],
	"reprieve": ["R IY0 P R IY1 V"],
	"reproduce": ["R IY2 P R AH0 D UW1 S"],
	"reproduced": ["R IY2 P R AH0 D UW1 S T"],
	"reproval": ["R IH0 P R UW1 V AH0 L"],
	"reprove": ["R IH0 P R UW1 V"],
	"reproving": ["R IH0 P R UW1 V IH0 NG"],
	"repudiate": ["R IY0 P Y UW1 D IY0 EY2 T"],
	"repugnant": ["R IH0 P AH1 G N AH0 N T"],
	"repute": ["R IY0 P Y UW1 T"],
	"request": ["R IH0 K W EH1 S T"],
	"requiem": ["R EH1 K W IY0 AH3 M", "R EH1 K W Y AH0 M"],
	"requir'd": ["R IH0 K W AY1 R D", "R IH0 K W AY1 ER0 D"],
	"require": ["R IY0 K W AY1 ER0", "R IY0 K W AY1 R"],
	"required": ["R IY0 K W AY1 ER0 D", "R IY0 K W AY1 R D"],
	"requirement": ["R IH0 K W AY1 R M AH0 N T", "R IH0 K W AY1 ER0 M AH3 N T"],
	"requires": ["R IY0 K W AY1 ER0 Z", "R IY0 K W AY1 R Z"],
	"requisite": ["R EH1 K W AH0 Z AH2 T"],
	"requite": ["R IH0 K W AY1 T"],
	"res": ["R EY1 S", "R IY1 Z"],
	"rescind": ["R AH0 S IH1 N D"],
	"rescued": ["R EH1 S K Y UW0 D"],
	"resemblance": ["R IH0 Z EH1 M B L AH0 N S"],
	"resemble": ["R IH0 Z EH1 M B AH0 L"],
	"resembling": ["R IH0 Z EH1 M B AH0 L IH3 NG", "R IH0 Z EH1 M B L IH0 NG"],
	"resentment": ["R IH0 Z EH1 N T M AH0 N T"],
	"reserve": ["R IH0 Z ER1 V"],
	"reserved": ["R IH0 Z ER1 V D"],
	"reserving": ["R IH0 Z ER1 V IH0 NG"],
	"reside": ["R IH0 Z AY1 D"],
	"residence": ["R EH1 Z IH0 D AH3 N S"],
	"residue": ["R EH1 Z AH0 D UW2"],
	"resighing": ["R IY0 S AY1 IH0 NG"],
	"resign": ["R IH0 Z AY1 N"],
	"resist": ["R IH0 Z IH1 S T"],
	"resistance": ["R IH0 Z IH1 S T AH0 N S"],
	"resistlessly": ["R IH0 Z IH1 S T L AH0 S L IY3"],
	"resituated": ["R IY2 S IH1 CH UH0 EY2 T IH0 D"],
	"resoluter": ["R EH1 Z AH0 L UW2 T ER0"],
	"resolution": ["R EH2 Z AH0 L UW1 SH AH0 N"],
	"resolve": ["R IY0 Z AA1 L V"],
	"resolved": ["R IY0 Z AA1 L V D"],
	"resort": ["R IH0 Z AO1 R T"],
	"resound": ["R IY2 S AW1 N D"],
	"resource": ["R IY1 S AO0 R S"],
	"resown": ["R IY0 S OW1 N"],
	"respect": ["R IH0 S P EH1 K T"],
	"respectful": ["R IH0 S P EH1 K T F AH0 L"],
	"respite": ["R EH1 S P IH0 T"],
	"respond": ["R IH0 S P AA1 N D"],
	"response": ["R IH0 S P AA1 N S"],
	"responsibility": ["R IH0 S P AA2 N S AH0 B IH1 L AH0 T IY3"],
	"responsively": ["R IH0 S P AA1 N S IH0 V L IY3"],
	"rest": ["R EH1 S T"],
	"restitution": ["R EH2 S T IH0 T UW1 SH AH0 N"],
	"restlessness": ["R EH1 S T L AH0 S N AH2 S"],
	"restore": ["R IH0 S T AO1 R"],
	"restored": ["R IH0 S T AO1 R D"],
	"restrain": ["R IY0 S T R EY1 N"],
	"restraint": ["R IH0 S T R EY1 N T"],
	"restrict": ["R IY0 S T R IH1 K T"],
	"result": ["R IH0 Z AH1 L T"],
	"resume": ["R IH0 Z UW1 M", "R EH1 Z AH0 M EY2"],
	"resumed": ["R IH0 Z UW1 M D"],
	"resumption": ["R IH0 Z AH1 M P SH AH0 N"],
	"resurrection": ["R EH2 Z ER0 EH1 K SH AH0 N"],
	"retail": ["R IY1 T EY2 L"],
	"retain": ["R IH0 T EY1 N"],
	"retaken": ["R IY0 T EY1 K AH0 N"],
	"retard": ["R IH0 T AA1 R D", "R IY1 T AA2 R D"],
	"retarded": ["R IH0 T AA1 R D IH0 D"],
	"retching": ["R EH1 CH IH0 NG"],
	"reticence": ["R EH1 T IH0 S AH3 N S"],
	"retina": ["R EH1 T IH0 N AH0", "R EH1 T N AH0"],
	"retinue": ["R EH1 T AH0 N UW2"],
	"retire": ["R IH0 T AY1 R", "R IH0 T AY1 ER0"],
	"retired": ["R IH0 T AY1 R D", "R IY0 T AY1 ER0 D", "R IY0 T AY1 R D"],
	"retires": ["R IH0 T AY1 R Z", "R IH0 T AY1 ER0 Z"],
	"retort": ["R IH0 T AO1 R T"],
	"retract": ["R IY0 T R AE1 K T"],
	"retreat": ["R IY0 T R IY1 T"],
	"retreating": ["R IY0 T R IY1 T IH0 NG"],
	"retrieve": ["R IH0 T R IY1 V"],
	"retrogade": ["R EH1 T R OW0 G EY1 D"],
	"retrograde": ["R EH1 T R OW0 G R EY2 D"],
	"retrospect": ["R EH1 T R OW0 S P EH2 K T"],
	"retrospection": ["R EH2 T R AH0 S P EH1 K SH AH0 N"],
	"return": ["R IH0 T ER1 N"],
	"reveal": ["R IH0 V IY1 L"],
	"revealings": ["R IH0 V IY1 L IH0 NG Z"],
	"reveille": ["R EH1 V AH0 L IY2"],
	"revel": ["R EH1 V AH0 L"],
	"revelation": ["R EH2 V AH0 L EY1 SH AH0 N"],
	"revenge": ["R AH0 V EH1 N JH"],
	"revere": ["R IH0 V IY1 R"],
	"reverence": ["R EH1 V R AH0 N S", "R EH1 V ER0 AH3 N S"],
	"reverend": ["R EH1 V ER0 A30 N D", "R EH1 V R AH0 N D"],
	"reverent": ["R EH1 V ER0 AH0 N T", "R EH1 V R AH0 N T"],
	"reverential": ["R EH2 V ER0 EH1 N SH AH0 L"],
	"reverently": ["R EH1 V R AH0 N T L IY3", "R EH1 V ER0 AH0 N T L IY3"],
	"reverie": ["R EH1 V ER0 IY3"],
	"revering": ["R IH0 V IY1 R IH0 NG"],
	"revers'd": ["R IH0 V ER1 S T"],
	"reverse": ["R IH0 V ER1 S"],
	"reversed": ["R IH0 V ER1 S T"],
	"revert": ["R IH0 V ER1 T"],
	"review": ["R IY2 V Y UW1"],
	"reviled": ["R IY0 V AY1 L D"],
	"revisit": ["R IY0 V IH1 Z IH0 T"],
	"reviv'd": ["R IH0 V AY1 V D"],
	"revive": ["R IH0 V AY1 V"],
	"revived": ["R IH0 V AY1 V D"],
	"revolution": ["R EH2 V AH0 L UW1 SH AH0 N"],
	"revolve": ["R IY0 V AA1 L V"],
	"revolver": ["R IH0 V AA1 L V ER0"],
	"rewalk": ["R IY2 W AO1 K"],
	"reward": ["R IH0 W AO1 R D"],
	"rhine": ["R AY1 N"],
	"rhinoceros": ["R AY0 N AA1 S ER0 AH3 S"],
	"rhododendron": ["R OW2 D AH0 D EH1 N D R AH0 N"],
	"rhone": ["R OW1 N"],
	"rhyme": ["R AY1 M"],
	"rhythm": ["R IH1 DH AH0 M"],
	"rialto": ["R IY0 AA1 L T OW0"],
	"ribaldry": ["R IH1 B AH0 L D R IY2"],
	"ribbon": ["R IH1 B AH0 N"],
	"rich": ["R IH1 CH"],
	"richard": ["R IH1 CH ER0 D"],
	"rid": ["R IH1 D"],
	"riddle": ["R IH1 D AH0 L"],
	"ride": ["R AY1 D"],
	"rider": ["R AY1 D ER0"],
	"ridge": ["R IH1 JH"],
	"riding": ["R AY1 D IH0 NG"],
	"rife": ["R AY1 F"],
	"rifle": ["R AY1 F AH0 L"],
	"rift": ["R IH1 F T"],
	"right": ["R AY1 T"],
	"righteousness": ["R AY1 CH AH0 S N AH2 S"],
	"rigmarole": ["R IH1 G M AH0 R OW2 L"],
	"rigor": ["R IH1 G ER0"],
	"rigour": ["R IH1 G ER0"],
	"rill": ["R IH1 L"],
	"rim": ["R IH1 M"],
	"rime": ["R AY1 M"],
	"rind": ["R AY1 N D"],
	"ring": ["R IH1 NG"],
	"ringlets": ["R IH1 NG L EH0 T S"],
	"riot": ["R AY1 AH0 T"],
	"rip": ["R IH1 P"],
	"ripe": ["R AY1 P"],
	"ripened": ["R AY1 P AH0 N D"],
	"ripening": ["R AY1 P AH0 N IH3 NG", "R AY1 P N IH0 NG"],
	"ripest": ["R AY1 P AH0 S T"],
	"ripple": ["R IH1 P AH0 L"],
	"rise": ["R AY1 Z"],
	"risen": ["R IH1 Z AH0 N"],
	"rising": ["R AY1 Z IH0 NG"],
	"risk": ["R IH1 S K"],
	"ritardando": ["R IY2 T AA0 R D AA1 N D OW0"],
	"rite": ["R AY1 T"],
	"ritual": ["R IH1 CH UW0 AH0 L"],
	"rival": ["R AY1 V AH0 L"],
	"riven": ["R IH1 V AH0 N"],
	"river": ["R IH1 V ER0"],
	"rivet": ["R IH1 V IH0 T"],
	"rivulet": ["R IH0 V UW1 L AH0 T"],
	"road": ["R OW1 D"],
	"roam": ["R OW1 M"],
	"roan": ["R OW1 N"],
	"roar": ["R AO1 R"],
	"roast": ["R OW1 S T"],
	"rob": ["R AA1 B"],
	"robbed": ["R AA1 B D"],
	"robber": ["R AA1 B ER0"],
	"robberies": ["R AA1 B ER0 IY3 Z"],
	"robe": ["R OW1 B"],
	"robin": ["R AA1 B IH0 N"],
	"rock": ["R AA1 K"],
	"rockdoves": ["R AA1 K D AH2 V Z"],
	"rocket": ["R AA1 K AH0 T"],
	"rod": ["R AA1 D"],
	"rode": ["R OW1 D"],
	"roe": ["R OW1"],
	"rogue": ["R OW1 G"],
	"roland": ["R OW1 L AH0 N D"],
	"roll": ["R OW1 L"],
	"romaic": ["R OW0 M EY1 IH0 K"],
	"roman": ["R OW1 M AH0 N"],
	"romance": ["R OW0 M AE1 N S", "R OW1 M AE0 N S"],
	"romano": ["R OW0 M AA1 N OW2"],
	"rome": ["R OW1 M"],
	"romeo": ["R OW1 M IY0 OW2", "R OW1 M Y OW0"],
	"romilly": ["R AA1 M IH0 L IY3"],
	"rood": ["R UW1 D"],
	"roof": ["R UH1 F"],
	"rook": ["R UH1 K"],
	"room": ["R UW1 M"],
	"roomy": ["R UW1 M IY0"],
	"root": ["R UW1 T"],
	"rope": ["R OW1 P"],
	"rosary": ["R OW1 Z ER0 IY2"],
	"rose": ["R OW1 Z"],
	"rosemary": ["R OW1 Z M EH2 R IY0"],
	"rosier": ["R OW1 Z IY0 ER0", "R OW1 Z Y ER0"],
	"rosy": ["R OW1 Z IY0"],
	"rotation": ["R OW0 T EY1 SH AH0 N"],
	"rotten": ["R AA1 T AH0 N"],
	"rouge": ["R UW1 ZH"],
	"rough": ["R AH1 F"],
	"round": ["R AW1 N D"],
	"rouse": ["R AW1 S"],
	"roused": ["R AW1 Z D"],
	"rousing": ["R AW1 Z IH0 NG"],
	"rout": ["R AW1 T"],
	"route": ["R UW1 T", "R AW1 T"],
	"routes": ["R UW1 T S", "R AW1 T S"],
	"routine": ["R UW2 T IY1 N"],
	"rove": ["R OW1 V"],
	"roved": ["R OW1 V D"],
	"row": ["R OW1"],
	"rowans": ["R AW1 AH0 N Z"],
	"rowed": ["R OW1 D"],
	"royal": ["R OY1 AH0 L"],
	"royally": ["R OY1 AH0 L IY3"],
	"royalty": ["R OY1 AH0 L T IY2"],
	"rub": ["R AH1 B"],
	"rubbed": ["R AH1 B D"],
	"rubber": ["R AH1 B ER0"],
	"rubies": ["R UW1 B IY0 Z"],
	"ruby": ["R UW1 B IY0"],
	"ruddier": ["R AH1 D Y ER0", "R AH1 D IY0 ER3"],
	"ruddy": ["R AH1 D IY0"],
	"rude": ["R UW1 D"],
	"rudiment": ["R UW1 D IH0 M AH3 N T"],
	"rue": ["R UW1"],
	"ruffle": ["R AH1 F AH0 L"],
	"ruffled": ["R AH1 F AH0 L D"],
	"rugged": ["R AH1 G IH0 D"],
	"ruin": ["R UW1 IH0 N"],
	"ruinate": ["R UW1 IH0 N AH2 T"],
	"ruinous": ["R UW1 AH0 N AH2 S"],
	"rule": ["R UW1 L"],
	"ruled": ["R UW1 L D"],
	"rum": ["R AH1 M"],
	"rumble": ["R AH1 M B AH0 L"],
	"rumbled": ["R AH1 M B AH0 L D"],
	"ruminate": ["R UW1 M IH0 N EY2 T"],
	"rummaging": ["R AH1 M IH0 JH IH2 NG"],
	"rumor": ["R UW1 M ER0"],
	"run": ["R AH1 N"],
	"rune": ["R UW1 N"],
	"rung": ["R AH1 NG"],
	"running": ["R AH1 N IH0 NG"],
	"rural": ["R ER1 AH0 L"],
	"rush": ["R AH1 SH"],
	"russet": ["R AH1 S AH0 T"],
	"rust": ["R AH1 S T"],
	"rustic": ["R AH1 S T IH0 K"],
	"rustle": ["R AH1 S AH0 L"],
	"rustling": ["R AH1 S L IH0 NG"],
	"ruth": ["R UW1 TH"],
	"ruthless": ["R UW1 TH L AH0 S"],
	"rye": ["R AY1"],
	"s": ["EH1 S"],
	"sabacthini": ["S AH0 B AA1 K TH IH0 N IY2"],
	"sabbath": ["S AE1 B AH0 TH"],
	"saber": ["S EY1 B ER0"],
	"sable": ["S EY1 B AH0 L"],
	"sabre": ["S EY1 B ER0"],
	"sacrament": ["S AE1 K R AH0 M AH2 N T"],
	"sacred": ["S EY1 K R IH0 D"],
	"sacrifice": ["S AE1 K R AH0 F AY2 S"],
	"sacrificial": ["S AE2 K R AH0 F IH1 SH AH0 L"],
	"sacristan": ["S AE1 K R IH0 S T AH2 N"],
	"sacristy": ["S AE1 K R AH0 S T IY2"],
	"sad": ["S AE1 D"],
	"saddle": ["S AE1 D AH0 L"],
	"sadness": ["S AE1 D N AH0 S"],
	"safe": ["S EY1 F"],
	"safer": ["S EY1 F ER0"],
	"safety": ["S EY1 F T IY0"],
	"sagacious": ["S AH0 G EY1 SH AH0 S"],
	"sagacity": ["S AH0 G AE1 S AH0 T IY2"],
	"sage": ["S EY1 JH"],
	"sagittary": ["S AE1 JH AH0 T EH2 R IY0"],
	"said": ["S EH1 D"],
	"sail": ["S EY1 L"],
	"sailor": ["S EY1 L ER0"],
	"saint": ["S EY1 N T"],
	"saith": ["S EY1 TH"],
	"sake": ["S EY1 K"],
	"salamanca": ["S AE2 L AH0 M AE1 NG K AH0"],
	"salary": ["S AE1 L ER0 IY3"],
	"sale": ["S EY1 L"],
	"salinguerra": ["S AE1 L IH0 N G EH2 R AH0"],
	"sally": ["S AE1 L IY0"],
	"salt": ["S AO1 L T"],
	"salubrious": ["S AH0 L UW1 B R IY0 AH3 S", "S AH0 L UW1 B R Y AH0 S"],
	"salute": ["S AH0 L UW1 T"],
	"saluting": ["S AH0 L UW1 T IH0 NG"],
	"salvation": ["S AE0 L V EY1 SH AH0 N"],
	"samarcand": ["S AE1 M AA0 R K AE2 N D"],
	"same": ["S EY1 M"],
	"samian": ["S AE1 M Y AH0 N"],
	"sample": ["S AE1 M P AH0 L"],
	"samuel": ["S AE1 M Y UW0 L"],
	"sanctified": ["S AE1 NG K T IH0 F AY2 D"],
	"sanctifies": ["S AE1 N K T AH0 F AY2 Z"],
	"sanctify": ["S AE1 NG K T AH0 F AY2"],
	"sanctity": ["S AE1 NG K T IH0 T IY3"],
	"sanctuary": ["S AE1 NG K CH UW0 EH0 R IY2"],
	"sand": ["S AE1 N D"],
	"sandal": ["S AE1 N D AH0 L"],
	"sane": ["S EY1 N"],
	"sang": ["S AE1 NG"],
	"sanity": ["S AE1 N AH0 T IY3"],
	"sank": ["S AE1 NG K"],
	"sapling": ["S AE1 P L IH0 NG"],
	"sapped": ["S AE1 P T"],
	"sapphire": ["S AE1 F AY0 ER0", "S AE1 F AY0 R"],
	"sapphirine": ["S AE1 F IH0 R AY2 N"],
	"sardanapalus": ["S AA2 R D AH0 N AE1 P AH0 L AH2 S"],
	"sat": ["S AE1 T"],
	"satan": ["S EY1 T AH0 N"],
	"sate": ["S EY1 T"],
	"sated": ["S EY1 T IH0 D"],
	"satellites": ["S AE1 T AH0 L AY2 T S"],
	"satiate": ["S EY1 SH IH0 EY0 T"],
	"satiety": ["S AH0 T AY1 AH0 T IY2"],
	"satin": ["S AE1 T AH0 N"],
	"satire": ["S AE1 T AY2 ER0"],
	"satirist": ["S AE1 T ER0 IH3 S T"],
	"satisfaction": ["S AE2 T AH0 S F AE1 K SH AH0 N"],
	"satisfied": ["S AE1 T IH0 S F AY2 D"],
	"satisfies": ["S AE1 T IH0 S F AY2 Z"],
	"satisfy": ["S AE1 T IH0 S F AY2"],
	"saturated": ["S AE1 CH ER0 EY2 T IH0 D"],
	"saturn": ["S AE1 T ER0 N"],
	"satyr": ["S EY1 T ER0"],
	"saucy": ["S AO1 S IY0"],
	"savage": ["S AE1 V IH0 JH"],
	"savagely": ["S AE1 V IH0 JH L IY3"],
	"savans": ["S AE1 V AA0 N Z"],
	"save": ["S EY1 V"],
	"saved": ["S EY1 V D"],
	"saving": ["S EY1 V IH0 NG"],
	"savior": ["S EY1 V Y ER0"],
	"savor": ["S EY1 V ER0"],
	"savory": ["S EY1 V ER0 IY3"],
	"savour": ["S EY1 V ER0"],
	"saw": ["S AO1"],
	"saxon": ["S AE1 K S AH0 N"],
	"say": ["S EY1"],
	"sayings": ["S EY1 IH0 NG Z"],
	"says": ["S EH1 Z"],
	"scaffold": ["S K AE1 F AH0 L D"],
	"scald": ["S K AO1 L D"],
	"scale": ["S K EY1 L"],
	"scan": ["S K AE1 N"],
	"scandal": ["S K AE1 N D AH0 L"],
	"scanned": ["S K AE1 N D"],
	"scant": ["S K AE1 N T"],
	"scantily": ["S K AE1 N T AH0 L IY3"],
	"scanty": ["S K AE1 N T IY0"],
	"scaping": ["S K EY1 P IH0 NG"],
	"scar": ["S K AA1 R"],
	"scarce": ["S K EH1 R S"],
	"scare": ["S K EH1 R"],
	"scared": ["S K EH1 R D"],
	"scarf": ["S K AA1 R F"],
	"scarlet": ["S K AA1 R L IH0 T"],
	"scathe": ["S K EY1 DH"],
	"scatter": ["S K AE1 T ER0"],
	"scene": ["S IY1 N"],
	"scenery": ["S IY1 N ER0 IY3"],
	"scepter": ["S EH1 P T ER0"],
	"sceptic": ["S K EH1 P T IH0 K"],
	"sceptre": ["S EH1 P T ER0"],
	"scheme": ["S K IY1 M"],
	"scholar": ["S K AA1 L ER0"],
	"scholastic": ["S K AH0 L AE1 S T IH0 K"],
	"school": ["S K UW1 L"],
	"schoolboy": ["S K UW1 L B OY2"],
	"schoolmates": ["S K UW1 L M EY2 T S"],
	"science": ["S AY1 AH0 N S"],
	"scio": ["SH IY1 OW0"],
	"scion": ["S AY1 AH0 N"],
	"scoff": ["S K AO1 F"],
	"scoffingly": ["S K AA1 F IH0 NG L IY3"],
	"scold": ["S K OW1 L D"],
	"scoop": ["S K UW1 P"],
	"scope": ["S K OW1 P"],
	"scorch": ["S K AO1 R CH"],
	"score": ["S K AO1 R"],
	"scorn": ["S K AO1 R N"],
	"scornfully": ["S K AO1 N F UH0 L IY3"],
	"scout": ["S K AW1 T"],
	"scowled": ["S K AW1 L D"],
	"scrap": ["S K R AE1 P"],
	"scrape": ["S K R EY1 P"],
	"scream": ["S K R IY1 M"],
	"screech": ["S K R IY1 CH"],
	"screen": ["S K R IY1 N"],
	"screw": ["S K R UW1"],
	"scritch": ["S K R IH1 CH"],
	"scroll": ["S K R OW1 L"],
	"scruple": ["S K R UW1 P AH0 L"],
	"scrupulous": ["S K R UW1 P Y AH0 L AH3 S"],
	"scrutinize": ["S K R UW1 T IH0 N AY2 Z"],
	"scrutiny": ["S K R UW1 T AH0 N IY2"],
	"scud": ["S K AH1 D"],
	"scuffle": ["S K AH1 F AH0 L"],
	"sculptor": ["S K AH1 L P T ER0"],
	"sculptured": ["S K AH1 L P CH ER0 D"],
	"scum": ["S K AH1 M"],
	"scuttle": ["S K AH1 T AH0 L"],
	"sdein": ["S D EY1 N"],
	"sea": ["S IY1"],
	"seagod": ["S IY1 G AA2 D"],
	"seal": ["S IY1 L"],
	"seam": ["S IY1 M"],
	"seamen": ["S IY1 M AH0 N"],
	"sear": ["S IY1 R"],
	"search": ["S ER1 CH"],
	"season": ["S IY1 Z AH0 N"],
	"seat": ["S IY1 T"],
	"second": ["S EH1 K AH0 N D"],
	"secrecy": ["S IY1 K R AH0 S IY3"],
	"secret": ["S IY1 K R IH0 T"],
	"secure": ["S IH0 K Y UH1 R"],
	"secured": ["S IH0 K Y UH1 R D"],
	"security": ["S IH0 K Y UH1 R AH0 T IY3"],
	"sedate": ["S IH0 D EY1 T"],
	"sedge": ["S EH1 JH"],
	"seditious": ["S IH0 D IH1 SH AH0 S"],
	"seduce": ["S AH0 D UW1 S"],
	"seductive": ["S AH0 D AH1 K T IH0 V"],
	"sedulous": ["S EH1 JH AH0 L AH3 S"],
	"see": ["S IY1"],
	"seed": ["S IY1 D"],
	"seek": ["S IY1 K"],
	"seem": ["S IY1 M"],
	"seen": ["S IY1 N"],
	"seer": ["S IY1 R","S IY1 ER0"],
	"seesaw": ["S IY1 S AO2"],
	"seeth": ["S IY1 DH"],
	"segment": ["S EH1 G M AH0 N T"],
	"seine": ["S EY1 N"],
	"seiz'd": ["S IY1 Z D"],
	"seizable": ["S IY1 Z AH0 B AH3 L"],
	"seize": ["S IY1 Z"],
	"seized": ["S IY1 Z D"],
	"seldom": ["S EH1 L D AH0 M"],
	"select": ["S AH0 L EH1 K T"],
	"self": ["S EH1 L F"],
	"sell": ["S EH1 L"],
	"seminary": ["S EH1 M AH0 N EH2 R IY2"],
	"senate": ["S EH1 N AH0 T"],
	"send": ["S EH1 N D"],
	"sensation": ["S EH0 N S EY1 SH AH0 N"],
	"sense": ["S EH1 N S"],
	"sensible": ["S EH1 N S AH0 B AH0 L"],
	"sensual": ["S EH1 N SH UW0 AH3 L", "S EH1 N SH W AH0 L"],
	"sent": ["S EH1 N T"],
	"sentence": ["S EH1 N T AH0 N S"],
	"sentiment": ["S EH1 N T AH0 M AH0 N T"],
	"sentimental": ["S EH2 N T AH0 M EH1 N T AH0 L"],
	"sentinel": ["S EH1 N T AH0 N AH2 L"],
	"sep'rate": ["S EH1 P R IH0 T"],
	"separate": ["S EH1 P ER0 EY2 T", "S EH1 P ER0 IH0 T", "S EH1 P R AH0 T"],
	"separation": ["S EH2 P ER0 EY1 SH AH0 N"],
	"september": ["S EH0 P T EH1 M B ER0"],
	"sepulchral": ["S EH0 P UH1 L K R AH0 L"],
	"sepulchre": ["S EH1 P AH0 L K ER3"],
	"sequel": ["S IY1 K W AH0 L"],
	"sequence": ["S IY1 K W AH0 N S"],
	"sequent": ["S IY1 K W AH0 N T"],
	"sequester": ["S IH0 K W EH1 S T ER0"],
	"seraph": ["S EH1 R AH0 F"],
	"seraphic": ["S ER0 AE1 F IH0 K"],
	"seraphim": ["S EH1 R AH0 F IH2 M"],
	"sere": ["S IY1 R"],
	"serene": ["S ER0 IY1 N"],
	"serener": ["S ER0 IY1 N ER0"],
	"series": ["S IY1 R IY0 Z"],
	"serious": ["S IY1 R IY0 AH3 S", "S IY1 R Y AH0 S"],
	"sermon": ["S ER1 M AH0 N"],
	"serpent": ["S ER1 P AH0 N T"],
	"serpentine": ["S ER1 P AH0 N T AY2 N"],
	"serpentry": ["S ER1 P AH0 N T R IY3"],
	"servant": ["S ER1 V AH0 N T"],
	"serve": ["S ER1 V"],
	"served": ["S ER1 V D"],
	"service": ["S ER1 V IH0 S"],
	"session": ["S EH1 SH AH0 N"],
	"set": ["S EH1 T"],
	"setter": ["S EH1 T ER0"],
	"setting": ["S EH1 T IH0 NG"],
	"settle": ["S EH1 T AH0 L"],
	"settled": ["S EH1 T AH0 L D"],
	"settlement": ["S EH1 T AH0 L M AH2 N T"],
	"settler": ["S EH1 T AH0 L ER0", "S EH1 T L ER0"],
	"sev'n": ["S EH1 V N"],
	"seven": ["S EH1 V AH0 N"],
	"seventeen": ["S EH2 V AH0 N T IY1 N"],
	"seventeenth": ["S EH2 V AH0 N T IY1 N TH"],
	"sever": ["S EH1 V ER0"],
	"several": ["S EH1 V R AH0 L", "S EH1 V ER0 AH0 L"],
	"severe": ["S AH0 V IY1 R"],
	"severed": ["S EH1 V ER0 D"],
	"seville": ["S AH0 V IH1 L"],
	"sevres": ["S EH1 V R AH0"],
	"sew": ["S OW1"],
	"sewn": ["S OW1 N"],
	"sex": ["S EH1 K S"],
	"sexton": ["S EH1 K S T AH0 N"],
	"sh'd": ["SH UH3 D"],
	"shade": ["SH EY1 D"],
	"shadow": ["SH AE1 D OW0"],
	"shady": ["SH EY1 D IY0"],
	"shaft": ["SH AE1 F T"],
	"shaggier": ["SH AE1 G Y ER0"],
	"shake": ["SH EY1 K"],
	"shaken": ["SH EY1 K AH0 N"],
	"shakespeare": ["SH EY1 K S P IY2 R"],
	"shakesper": ["SH EY1 K S P IY2 R"],
	"shaking": ["SH EY1 K IH0 NG"],
	"shall": ["SH AE1 L"],
	"shalott": ["SH AH0 L AA1 T"],
	"shalt": ["SH AE1 L T"],
	"sham": ["SH AE1 M"],
	"shame": ["SH EY1 M"],
	"shamed": ["SH EY1 M D"],
	"shaming": ["SH EY1 M IH0 NG"],
	"shan't": ["SH AE1 N T"],
	"shank": ["SH AE1 NG K"],
	"shape": ["SH EY1 P"],
	"shaped": ["SH EY1 P T"],
	"shard": ["SH AA1 R D"],
	"share": ["SH EH1 R"],
	"shared": ["SH EH1 R D"],
	"shark": ["SH AA1 R K"],
	"sharp": ["SH AA1 R P"],
	"sharpen": ["SH AA1 R P AH0 N"],
	"shatter": ["SH AE1 T ER0"],
	"shattering": ["SH AE1 T ER0 IH3 NG", "SH AE1 T R IH0 NG"],
	"shaven": ["SH EY1 V AH0 N"],
	"shaw": ["SH AO1"],
	"shawl": ["SH AO1 L"],
	"she": ["SH IY3"],
	"she'd": ["SH IY1 D"],
	"sheaf": ["SH IY1 F"],
	"shear": ["SH IY1 R"],
	"sheathe": ["SH IY1 DH"],
	"sheaves": ["SH IY1 V Z"],
	"shed": ["SH EH1 D"],
	"sheen": ["SH IY1 N"],
	"sheep": ["SH IY1 P"],
	"sheepbell": ["SH IY1 P B EH2 L"],
	"sheer": ["SH IY1 R"],
	"sheet": ["SH IY1 T"],
	"sheilings": ["SH IY1 L IH0 NG Z"],
	"shelf": ["SH EH1 L F"],
	"shell": ["SH EH1 L"],
	"shelter": ["SH EH1 L T ER0"],
	"shelves": ["SH EH1 L V Z"],
	"shepherd": ["SH EH1 P ER0 D"],
	"sherry": ["SH EH1 R IY0"],
	"shew": ["SH UW1"],
	"shewn": ["SH OW1 N"],
	"shews": ["SH OW1 Z"],
	"shield": ["SH IY1 L D"],
	"shift": ["SH IH1 F T"],
	"shilling": ["SH IH1 L IH0 NG"],
	"shine": ["SH AY1 N"],
	"shining": ["SH AY1 N IH0 NG"],
	"ship": ["SH IH1 P"],
	"shipwrecked": ["SH IH1 P R EH2 K T"],
	"shiver": ["SH IH1 V ER0"],
	"shiverers": ["SH IH1 V ER0 ER3 Z", "SH IH1 V R ER0 Z"],
	"shivering": ["SH IH1 V ER0 IH0 NG", "SH IH1 V R IH0 NG"],
	"shoal": ["SH OW1 L"],
	"shock": ["SH AA1 K"],
	"shod": ["SH AA1 D"],
	"shoe": ["SH UW1"],
	"shone": ["SH OW1 N"],
	"shook": ["SH UH1 K"],
	"shoot": ["SH UW1 T"],
	"shop": ["SH AA1 P"],
	"shore": ["SH AO1 R"],
	"shorn": ["SH AO1 R N"],
	"short": ["SH AO1 R T"],
	"shorten": ["SH AO1 R T AH0 N"],
	"shot": ["SH AA1 T"],
	"should": ["SH UH1 D"],
	"shoulder": ["SH OW1 L D ER0"],
	"shouldn't": ["SH UH1 D AH0 N T"],
	"shout": ["SH AW1 T"],
	"shove": ["SH AH1 V"],
	"show": ["SH OW1"],
	"shower": ["SH AW1 ER0"],
	"shown": ["SH OW1 N"],
	"shrank": ["SH R AE1 NG K"],
	"shred": ["SH R EH1 D"],
	"shrewd": ["SH R UW1 D"],
	"shriek": ["SH R IY1 K"],
	"shrill": ["SH R IH1 L"],
	"shrine": ["SH R AY1 N"],
	"shrined": ["SH R AY1 N D"],
	"shrink": ["SH R IH1 NG K"],
	"shrivelled": ["SH R IH1 V AH0 L D"],
	"shriven": ["SH R AY1 V AH0 N"],
	"shroud": ["SH R AW1 D"],
	"shudder": ["SH AH1 D ER0"],
	"shuffled": ["SH AH1 F AH0 L D"],
	"shun": ["SH AH1 N"],
	"shunning": ["SH AH1 N IH0 NG"],
	"shut": ["SH AH1 T"],
	"shutter": ["SH AH1 T ER0"],
	"shutting": ["SH AH1 T IH0 NG"],
	"shuttle": ["SH AH1 T AH0 L"],
	"shy": ["SH AY1"],
	"shylock": ["SH AY1 L AA2 K"],
	"shyly": ["SH AY1 L IY0"],
	"sicilian": ["S IH0 S IH1 L Y AH0 N"],
	"sicily": ["S IH1 S AH0 L IY3"],
	"sick": ["S IH1 K"],
	"sicken": ["S IH1 K AH0 N"],
	"sicklied": ["S IH1 K L IY0 D"],
	"side": ["S AY1 D"],
	"sided": ["S AY1 D IH0 D"],
	"sidewise": ["S AY1 D W AY2 Z"],
	"sidney": ["S IH1 D N IY0"],
	"sidney's": ["S IH1 D N IY0 Z"],
	"siena": ["S IY0 EH1 N AH0"],
	"sieves": ["S IH1 V Z"],
	"sigh": ["S AY1"],
	"sight": ["S AY1 T"],
	"sign": ["S AY1 N"],
	"signal": ["S IH1 G N AH0 L"],
	"signalize": ["S IH1 G N AH0 L AY2 Z"],
	"significance": ["S IH0 G N IH1 F IH0 K AH3 N S"],
	"significant": ["S IH0 G N IH1 F IH0 K AH3 N T"],
	"signified": ["S IH1 G N AH0 F AY2 D"],
	"signify": ["S IH1 G N IH0 F AY2"],
	"signior": ["S IH0 N Y AO1 R"],
	"signor": ["S IY1 N Y AO0 R"],
	"silas": ["S AY1 L AH0 S"],
	"silence": ["S AY1 L AH0 N S"],
	"silent": ["S AY1 L AH0 N T"],
	"silk": ["S IH1 L K"],
	"silken": ["S IH1 L K AH0 N"],
	"sill": ["S IH1 L"],
	"silly": ["S IH1 L IY0"],
	"siloa": ["S AY1 L OW2"],
	"silver": ["S IH1 L V ER0"],
	"similar": ["S IH1 M AH0 L ER3"],
	"simile": ["S IH1 M AH0 L IY3"],
	"simple": ["S IH1 M P AH0 L"],
	"simplicity": ["S IH0 M P L IH1 S AH0 T IY3"],
	"simply": ["S IH1 M P L IY0"],
	"simulate": ["S IH1 M Y AH0 L EY2 T"],
	"simultaneous": ["S AY2 M AH0 L T EY1 N IY0 AH0 S", "S AY2 M AH0 L T EY1 N Y AH0 S"],
	"simultaneously": ["S AY2 M AH0 L T EY1 N IY0 AH0 S L IY3", "S AY2 M AH0 L T EY1 N Y AH0 S L IY3"],
	"sin": ["S IH1 N"],
	"sinai": ["S AY1 N AY2"],
	"since": ["S IH1 N S"],
	"sincere": ["S IH0 N S IY1 R"],
	"sincerity": ["S IH0 N S EH1 R IH0 T IY3"],
	"sinew": ["S IH1 N Y UW0"],
	"sing": ["S IH1 NG"],
	"singe": ["S IH1 N JH"],
	"singer": ["S IH1 NG ER0"],
	"single": ["S IH1 NG G AH0 L"],
	"sink": ["S IH1 NG K"],
	"sinning": ["S IH1 N IH0 NG"],
	"sion": ["S AY1 AA0 N"],
	"sip": ["S IH1 P"],
	"sipped": ["S IH1 P T"],
	"sir": ["S ER1"],
	"sire": ["S AY1 R", "S AY1 ER0"],
	"siren": ["S AY1 R AH0 N"],
	"sirocco": ["S ER0 AA1 K OW0"],
	"sirvent": ["S ER1 V EH0 N T"],
	"sister": ["S IH1 S T ER0"],
	"sit": ["S IH1 T"],
	"site": ["S AY1 T"],
	"sitting": ["S IH1 T IH0 NG"],
	"situates": ["S IH1 CH UW0 EY2 T S"],
	"situation": ["S IH2 CH UW0 EY1 SH AH0 N"],
	"six": ["S IH1 K S"],
	"sixteen": ["S IH0 K S T IY1 N", "S IH1 K S T IY2 N"],
	"sixty": ["S IH1 K S T IY0"],
	"size": ["S AY1 Z"],
	"skater": ["S K EY1 T ER0"],
	"skein": ["S K EY1 N"],
	"skies": ["S K AY1 Z"],
	"skill": ["S K IH1 L"],
	"skillful": ["S K IH1 L F AH0 L"],
	"skim": ["S K IH1 M"],
	"skimmed": ["S K IH1 M D"],
	"skin": ["S K IH1 N"],
	"skip": ["S K IH1 P"],
	"skirmish": ["S K ER1 M IH0 SH"],
	"skirt": ["S K ER1 T"],
	"skittish": ["S K IH1 T IH0 SH"],
	"sky": ["S K AY1"],
	"slack": ["S L AE1 K"],
	"slacken": ["S L AE1 K AH0 N"],
	"slain": ["S L EY1 N"],
	"slake": ["S L EY1 K"],
	"slam": ["S L AE1 M"],
	"slant": ["S L AE1 N T"],
	"slash": ["S L AE1 SH"],
	"slate": ["S L EY1 T"],
	"slaughter": ["S L AO1 T ER0"],
	"slave": ["S L EY1 V"],
	"slay": ["S L EY1"],
	"sled": ["S L EH1 D"],
	"sledge": ["S L EH1 JH"],
	"sleep": ["S L IY1 P"],
	"sleepers": ["S L IY1 P ER0 Z"],
	"sleepy": ["S L IY1 P IY0"],
	"sleet": ["S L IY1 T"],
	"sleeve": ["S L IY1 V"],
	"slender": ["S L EH1 N D ER0"],
	"slept": ["S L EH1 P T"],
	"slew": ["S L UW1"],
	"slid": ["S L IH1 D"],
	"slide": ["S L AY1 D"],
	"slight": ["S L AY1 T"],
	"slim": ["S L IH1 M"],
	"slime": ["S L AY1 M"],
	"sling": ["S L IH1 NG"],
	"slink": ["S L IH1 NG K"],
	"slip": ["S L IH1 P"],
	"slipped": ["S L IH1 P T"],
	"slipper": ["S L IH1 P ER0"],
	"slipping": ["S L IH1 P IH0 NG"],
	"slipt": ["S L IH1 P T"],
	"slit": ["S L IH1 T"],
	"sliver": ["S L IH1 V ER0"],
	"slope": ["S L OW1 P"],
	"sloth": ["S L OW1 TH"],
	"slough": ["S L AH1 F"],
	"slow": ["S L OW1"],
	"sluice": ["S L UW1 S"],
	"slumber": ["S L AH1 M B ER0"],
	"slumbereth": ["S L AH1 M B ER0 IH0 TH", "S L AH1 M B R IH0 TH"],
	"slung": ["S L AH1 NG"],
	"sly": ["S L AY1"],
	"small": ["S M AO1 L"],
	"smart": ["S M AA1 R T"],
	"smear": ["S M IY1 R"],
	"smell": ["S M EH1 L"],
	"smil'd": ["S M AY1 L D"],
	"smile": ["S M AY1 L"],
	"smiled": ["S M AY1 L D"],
	"smiling": ["S M AY1 L IH0 NG"],
	"smite": ["S M AY1 T"],
	"smith": ["S M IH1 TH"],
	"smitten": ["S M IH1 T AH0 N"],
	"smoke": ["S M OW1 K"],
	"smoked": ["S M OW1 K T"],
	"smooth": ["S M UW1 DH"],
	"smote": ["S M OW1 T"],
	"smother": ["S M AH1 DH ER0"],
	"smouldering": ["S M OW1 L D ER0 IH3 NG", "S M OW1 L D R IH0 NG"],
	"smutch": ["S M AH1 CH"],
	"snake": ["S N EY1 K"],
	"snap": ["S N AE1 P"],
	"snapt": ["S N AE1 P T"],
	"snare": ["S N EH1 R"],
	"snarling": ["S N AA1 R L IH0 NG"],
	"snatch": ["S N AE1 CH"],
	"sneer": ["S N IY1 R"],
	"snort": ["S N AO1 R T"],
	"snow": ["S N OW1"],
	"snowy": ["S N OW1 IY0"],
	"snuff": ["S N AH1 F"],
	"snug": ["S N AH1 G"],
	"so": ["S OW3"],
	"soar": ["S AO1 R"],
	"sob": ["S AA1 B"],
	"sobb'd": ["S AA1 B D"],
	"sobbed": ["S AA1 B D"],
	"sobbing": ["S AA1 B IH0 NG"],
	"sober": ["S OW1 B ER0"],
	"sobriety": ["S AH0 B R AY1 AH0 T IY3"],
	"soccer": ["S AA1 K ER0"],
	"social": ["S OW1 SH AH0 L"],
	"society": ["S AH0 S AY1 AH0 T IY2"],
	"sock": ["S AA1 K"],
	"socket": ["S AA1 K AH0 T"],
	"sod": ["S AA1 D"],
	"soe'er": ["S OW0 EH1 R"],
	"sofa": ["S OW1 F AH0"],
	"soft": ["S AO1 F T","S AA1 F T"],
	"soil": ["S OY1 L"],
	"solace": ["S AA1 L AH0 S"],
	"solar": ["S OW1 L ER0"],
	"sold": ["S OW1 L D"],
	"soldered": ["S AA1 D ER0 D"],
	"soldier": ["S OW1 L JH ER0"],
	"sole": ["S OW1 L"],
	"solemn": ["S AA1 L AH0 M"],
	"solemnities": ["S AH0 L EH1 M N IH0 T IY2 Z"],
	"solemnity": ["S AH0 L EH1 M N AH0 T IY3"],
	"solemnizes": ["S AO1 L EH0 M N AY2 Z IH0 Z"],
	"solemnly": ["S AO1 L AH0 M L IY3"],
	"solid": ["S AA1 L IH0 D"],
	"solitary": ["S AA1 L AH0 T EH2 R IY0"],
	"solitude": ["S AA1 L AH0 T UW2 D"],
	"solstice": ["S AO1 L S T IH0 S"],
	"solve": ["S AA1 L V"],
	"sombre": ["S AA1 M B ER0"],
	"some": ["S AH1 M"],
	"somebody": ["S AH1 M B AH0 D IY0"],
	"somehow": ["S AH1 M HH AW2"],
	"someone": ["S AH1 M W AH2 N"],
	"something": ["S AH1 M TH IH0 NG"],
	"sometimes": ["S AH1 M T AY2 M Z"],
	"somewhat": ["S AH1 M W AH2 T"],
	"somewhere": ["S AH1 M W EH2 R"],
	"son": ["S AH1 N"],
	"song": ["S AO1 NG"],
	"songbird": ["S AO1 NG B ER2 D"],
	"songcraft": ["S AA1 NG K R AE2 F T"],
	"sonnet": ["S AA1 N IH0 T"],
	"soon": ["S UW1 N"],
	"soot": ["S UH1 T"],
	"sooth": ["S UW1 TH"],
	"soothe": ["S UW1 DH"],
	"soothest": ["S UW1 DH IH0 S T"],
	"sooty": ["S UW1 T IY0"],
	"sophistries": ["S AA1 F AH0 S T R IY2 Z"],
	"sorcerer": ["S AO1 R S R ER0", "S AO1 R S ER0 ER3"],
	"sorcery": ["S AO1 R S ER0 IY3", "S AO1 R S R IY0"],
	"sordello": ["S AO0 R D EH1 L OW0"],
	"sordid": ["S AO1 R D IH0 D"],
	"sore": ["S AO1 R"],
	"sorer": ["S AO1 R ER0"],
	"sorest": ["S AO1 R AH0 S T"],
	"sorrow": ["S AA1 R OW0"],
	"sorrowful": ["S AA1 R OW0 F UH3 L"],
	"sorry": ["S AA1 R IY0"],
	"sort": ["S AO1 R T"],
	"soto": ["S OW1 T OW0"],
	"sought": ["S AO1 T"],
	"soul": ["S OW1 L"],
	"sound": ["S AW1 N D"],
	"sour": ["S AW1 R", "S AW1 ER0"],
	"source": ["S AO1 R S"],
	"south": ["S AW1 TH"],
	"southern": ["S AH1 DH ER0 N"],
	"southey": ["S AW1 DH IY0"],
	"southward": ["S AW1 TH W ER0 D"],
	"souvenir": ["S UW2 V AH0 N IY1 R"],
	"sov'reign": ["S AA1 V R AH0 N"],
	"sovereign": ["S AA1 V R AH0 N"],
	"sovereignties": ["S AA1 V R AH0 N T IY2 Z"],
	"sovereignty": ["S AA1 V R AH0 N T IY3"],
	"sovranty": ["S AA1 V R AH0 N T IY2"],
	"sovreign": ["S AA1 V R AH0 N"],
	"sovreignties": ["S AA1 V R AH0 N T IY2 Z"],
	"sow": ["S AW1", "S OW1"],
	"sowed": ["S OW1 D"],
	"sowing": ["S OW1 IH0 NG"],
	"sown": ["S OW1 N"],
	"space": ["S P EY1 S"],
	"spacious": ["S P EY1 SH AH0 S"],
	"spade": ["S P EY1 D"],
	"spain": ["S P EY1 N"],
	"spake": ["S P EY1 K"],
	"span": ["S P AE1 N"],
	"spangle": ["S P AE1 NG G AH0 L"],
	"spangled": ["S P AE1 NG G AH0 L D"],
	"spaniel": ["S P AE1 N Y AH0 L"],
	"spanish": ["S P AE1 N IH0 SH"],
	"spann'd": ["S P AE1 N D"],
	"spar": ["S P AA1 R"],
	"spare": ["S P EH1 R"],
	"spared": ["S P EH1 R D"],
	"spark": ["S P AA1 R K"],
	"sparkle": ["S P AA1 R K AH0 L"],
	"sparkling": ["S P AA1 R K L IH0 NG", "S P AA1 R K AH0 L IH0 NG"],
	"sparrow": ["S P EH1 R OW0"],
	"speak": ["S P IY1 K"],
	"spear": ["S P IY1 R"],
	"special": ["S P EH1 SH AH0 L"],
	"species": ["S P IY1 SH IY0 Z"],
	"specific": ["S P AH0 S IH1 F IH0 K"],
	"specify": ["S P EH1 S AH0 F AY2"],
	"specious": ["S P IY1 SH AH0 S"],
	"speck": ["S P EH1 K"],
	"spectacle": ["S P EH1 K T AH0 K AH3 L"],
	"spectral": ["S P EH1 K T R AH0 L"],
	"spectre": ["S P EH1 K T ER0"],
	"speculate": ["S P EH1 K Y UH0 L EY2 T"],
	"speculation": ["S P EH2 K Y AH0 L EY1 SH AH0 N"],
	"sped": ["S P EH1 D"],
	"speech": ["S P IY1 CH"],
	"speed": ["S P IY1 D"],
	"spell": ["S P EH1 L"],
	"spend": ["S P EH1 N D"],
	"spent": ["S P EH1 N T"],
	"sphere": ["S F IY1 R"],
	"spheric": ["S F IY1 R IH0 K"],
	"spherical": ["S F EH1 R IH0 K AH2 L"],
	"spher\u00e8d": ["S F IY1 R IH0 D"],
	"spice": ["S P AY1 S"],
	"spiciest": ["S P AY1 S IY0 IH3 S T", "S P AY1 S Y IH0 S T"],
	"spicy": ["S P AY1 S IY0"],
	"spider": ["S P AY1 D ER0"],
	"spied": ["S P AY1 D"],
	"spies": ["S P AY1 Z"],
	"spill": ["S P IH1 L"],
	"spilt": ["S P IH1 L T"],
	"spin": ["S P IH1 N"],
	"spinning": ["S P IH1 N IH0 NG"],
	"spire": ["S P AY1 R", "S P AY1 ER0"],
	"spires": ["S P AY1 R Z", "S P AY1 ER0 Z"],
	"spirit": ["S P IY1 R IH0 T", "S P IY1 R T"],
	"spirits": ["S P IY1 R IH0 T", "S P IY1 R T S"],
	"spiritual": ["S P IY1 R IH0 CH UW2 AH0 L"],
	"spite": ["S P AY1 T"],
	"splash": ["S P L AE1 SH"],
	"spleen": ["S P L IY1 N"],
	"splendid": ["S P L EH1 N D IH0 D"],
	"splendor": ["S P L EH1 N D ER0"],
	"splendour": ["S P L EH1 ND ER0"],
	"splinter": ["S P L IH1 N T ER0"],
	"split": ["S P L IH1 T"],
	"splitting": ["S P L IH1 T IH0 NG"],
	"spoil": ["S P OY1 L"],
	"spoke": ["S P OW1 K"],
	"spoken": ["S P OW1 K AH0 N"],
	"spontaneity": ["S P AA2 N T AH0 N EY1 IH0 T IY3"],
	"spool": ["S P UW1 L"],
	"spoon": ["S P UW1 N"],
	"sport": ["S P AO1 R T"],
	"sportsman": ["S P AO1 R T S M AH0 N"],
	"spot": ["S P AA1 T"],
	"spotted": ["S P AA1 T IH0 D"],
	"spouse": ["S P AW1 S"],
	"sprang": ["S P R AE1 NG"],
	"sprawl": ["S P R AO1 L"],
	"spray": ["S P R EY1"],
	"spread": ["S P R EH1 D"],
	"spright": ["S P R AY1 T"],
	"spring": ["S P R IH1 NG"],
	"springtime": ["S P R IH1 NG T AY2 M"],
	"sprinkled": ["S P R IH1 NG K AH0 L D"],
	"sprite": ["S P R AY1 T"],
	"sprung": ["S P R AH1 NG"],
	"spume": ["S P Y UW1 M"],
	"spun": ["S P AH1 N"],
	"spur": ["S P ER1"],
	"spurn": ["S P ER1 N"],
	"spy": ["S P AY1"],
	"squall": ["S K W AO1 L"],
	"squander": ["S K W AA1 N D ER0"],
	"squarcialupe": ["S K W AA2 R CH AH0 L UW1 P"],
	"square": ["S K W EH1 R"],
	"squared": ["S K W EH1 R D"],
	"squar\u00e8d": ["S K W EH1 R IH0 D"],
	"squeal": ["S K W IY1 L"],
	"squeeze": ["S K W IY1 Z"],
	"squire": ["S K W AY1 R"],
	"squirrel": ["S K W ER1 AH0 L"],
	"st": ["S EY1 N T"],
	"stab": ["S T AE1 B"],
	"stable": ["S T EY1 B AH0 L"],
	"staff": ["S T AE1 F"],
	"stag": ["S T AE1 G"],
	"stage": ["S T EY1 JH"],
	"stagger": ["S T AE1 G ER0"],
	"staggering": ["S T AE1 G ER0 IH2 NG", "S T AE1 G R IH0 NG"],
	"stagings": ["S T EY1 JH IH0 NG Z"],
	"staid": ["S T EY1 D"],
	"stain": ["S T EY1 N"],
	"stair": ["S T EH1 R"],
	"stairway": ["S T EH1 R W EY0"],
	"stake": ["S T EY1 K"],
	"staking": ["S T EY1 K IH0 NG"],
	"stalactite": ["S T AE1 L AH0 K T AY2 T"],
	"stale": ["S T EY1 L"],
	"stalk": ["S T AO1 K"],
	"stall": ["S T AO1 L"],
	"stammer": ["S T AE1 M ER0"],
	"stamp": ["S T AE1 M P"],
	"stampt": ["S T AE1 M P T"],
	"stand": ["S T AE1 N D"],
	"standard": ["S T AE1 N D ER0 D"],
	"stank": ["S T AE1 NG K"],
	"stanza": ["S T AE1 N Z AH0"],
	"staple": ["S T EY1 P AH0 L"],
	"star": ["S T AA1 R"],
	"stare": ["S T EH1 R"],
	"stared": ["S T EH1 R D"],
	"stares": ["S T EH1 R Z"],
	"stark": ["S T AA1 R K"],
	"starlit": ["S T AA1 R L IH0 T"],
	"starred": ["S T AA1 R D"],
	"starry": ["S T AA1 R IY2"],
	"start": ["S T AA1 R T"],
	"startle": ["S T AA1 R T AH0 L"],
	"startled": ["S T AA1 R T AH0 L D"],
	"starve": ["S T AA1 R V"],
	"starved": ["S T AA1 R V D"],
	"starving": ["S T AA1 R V IH0 NG"],
	"state": ["S T EY1 T"],
	"stated": ["S T EY1 T IH0 D"],
	"statelier": ["S T EY1 T L IY1 R", "S T EY1 T L IY0 ER3"],
	"stateliest": ["S T EY1 T L IY0 S T", "S T EY1 T L IY0 IH3 S T"],
	"statement": ["S T EY1 T M AH0 N T"],
	"station": ["S T EY1 SH AH0 N"],
	"statuary": ["S T AE1 CH UW0 EH2 R IY0"],
	"statue": ["S T AE1 CH UW0"],
	"stature": ["S T AE1 CH ER0"],
	"staunch": ["S T AO1 N CH"],
	"stay": ["S T EY1"],
	"stead": ["S T EH1 D"],
	"steadfast": ["S T EH1 D F AE2 S T"],
	"steadfastness": ["S T EH1 D F AE2 S T N AH2 S"],
	"steadily": ["S T EH1 D AH0 L IY3"],
	"steady": ["S T EH1 D IY0"],
	"steak": ["S T EY1 K"],
	"steal": ["S T IY1 L"],
	"stealth": ["S T EH1 L TH"],
	"stealthily": ["S T IY1 L TH IH0 L IY2"],
	"stealthy": ["S T EH1 L TH IY0"],
	"steam": ["S T IY1 M"],
	"steed": ["S T IY1 D"],
	"steel": ["S T IY1 L"],
	"steep": ["S T IY1 P"],
	"steeple": ["S T IY1 P AH0 L"],
	"steer": ["S T IY1 R"],
	"stell": ["S T EH1 L"],
	"stem": ["S T EH1 M"],
	"stemmed": ["S T EH1 M D"],
	"step": ["S T EH1 P"],
	"stepped": ["S T EH1 P T"],
	"stepping": ["S T EH1 P IH0 NG"],
	"stept": ["S T EH1 P T"],
	"sterile": ["S T EH1 R AH0 L"],
	"sterility": ["S T ER0 IH1 L IH0 T IY3"],
	"stern": ["S T ER1 N"],
	"stick": ["S T IH1 K"],
	"stiff": ["S T IH1 F"],
	"stiffen": ["S T IH1 F AH0 N"],
	"stile": ["S T AY1 L"],
	"still": ["S T IH1 L"],
	"stimulant": ["S T IH1 M Y UH0 L AH3 N T"],
	"stimulate": ["S T IH1 M Y AH0 L EY2 T"],
	"stimulated": ["S T IH1 M Y AH0 L EY2 T IH0 D"],
	"stimulus": ["S T IH1 M Y AH0 L AH3 S"],
	"sting": ["S T IH1 NG"],
	"stint": ["S T IH1 N T"],
	"stipulate": ["S T IH1 P Y AH0 L EY2 T"],
	"stipulus": ["S T IH1 P Y AH0 L AH3 S"],
	"stir": ["S T ER1", "S T AH1 R"],
	"stirr'd": ["S T ER1 D"],
	"stirred": ["S T ER1 D"],
	"stirring": ["S T ER1 IH0 NG"],
	"stirrings": ["S T ER1 IH0 NG Z"],
	"stitch": ["S T IH1 CH"],
	"stock": ["S T AA1 K"],
	"stocking": ["S T AA1 K IH0 NG"],
	"stole": ["S T OW1 L"],
	"stolid": ["S T AA1 L IH0 D"],
	"stone": ["S T OW1 N"],
	"stood": ["S T UH1 D"],
	"stooges": ["S T UW1 JH IH0 Z"],
	"stool": ["S T UW1 L"],
	"stoop": ["S T UW1 P"],
	"stop": ["S T AA1 P"],
	"stopped": ["S T AA1 P T"],
	"store": ["S T AO1 R"],
	"stored": ["S T AO1 R D"],
	"stories": ["S T AO1 R IY0 Z"],
	"storm": ["S T AO1 R M"],
	"story": ["S T AO1 R IY0"],
	"stout": ["S T AW1 T"],
	"stow": ["S T OW1"],
	"straddle": ["S T R AE1 D AH0 L"],
	"straggled": ["S T R AE1 G AH0 L D"],
	"straggling": ["S T R AE1 G L IH0 NG"],
	"straight": ["S T R EY1 T"],
	"straighten": ["S T R EY1 T AH0 N"],
	"straightway": ["S T R EY2 T W EY1"],
	"strain": ["S T R EY1 N"],
	"strait": ["S T R EY1 T"],
	"strand": ["S T R AE1 N D"],
	"strange": ["S T R EY1 N JH"],
	"stranger": ["S T R EY1 N JH ER0"],
	"stranger'd": ["S T R EY1 N JH ER0 D"],
	"strangest": ["S T R EY1 N JH IH0 S T"],
	"strangles": ["S T R AE1 NG G AH0 L Z"],
	"strapped": ["S T R AE1 P T"],
	"stratagems": ["S T R AE1 T AH0 JH AH2 M Z"],
	"strategem": ["S T R AE1 T AH0 JH AH3 M"],
	"strategy": ["S T R AE1 T AH0 JH IY2"],
	"straw": ["S T R AO1"],
	"strawberries": ["S T R AO1 B EH2 R IY0 Z"],
	"stray": ["S T R EY1"],
	"streak": ["S T R IY1 K"],
	"stream": ["S T R IY1 M"],
	"street": ["S T R IY1 T"],
	"strength": ["S T R EH1 NG K TH"],
	"strengthen": ["S T R EH1 NG TH AH0 N"],
	"strengthening": ["S T R EH1 NG TH AH0 N IH2 NG", "S T R EH1 NG TH N IH0 NG"],
	"stress": ["S T R EH1 S"],
	"stretch": ["S T R EH1 CH"],
	"strew": ["S T R UW1"],
	"strewn": ["S T R UW1 N"],
	"strict": ["S T R IH1 K T"],
	"stride": ["S T R AY1 D"],
	"striding": ["S T R AY1 D IH0 NG"],
	"strife": ["S T R AY1 F"],
	"strike": ["S T R AY1 K"],
	"striking": ["S T R AY1 K IH0 NG"],
	"string": ["S T R IH1 NG"],
	"strip": ["S T R IH1 P"],
	"stripe": ["S T R AY1 P"],
	"stripling": ["S T R IH1 P L IH0 NG"],
	"stripped": ["S T R IH1 P T"],
	"strive": ["S T R AY1 V"],
	"striven": ["S T R IH1 V AH0 N"],
	"striving": ["S T R AY1 V IH0 NG"],
	"stroke": ["S T R OW1 K"],
	"stroked": ["S T R OW1 K T"],
	"stroll": ["S T R OW1 L"],
	"strong": ["S T R AO1 NG"],
	"strophades": ["S T R OW1 F AH0 D IY2 Z"],
	"strove": ["S T R OW1 V"],
	"strown": ["S T R OW1 N"],
	"struck": ["S T R AH1 K"],
	"struggle": ["S T R AH1 G AH0 L"],
	"struggled": ["S T R AH1 G AH0 L D"],
	"struggling": ["S T R AH1 G AH0 L IH3 NG", "S T R AH1 G L IH0 NG"],
	"strumpet": ["S T R AH1 M P IH0 T"],
	"strung": ["S T R AH1 NG"],
	"strut": ["S T R AH1 T"],
	"stubble": ["S T AH1 B AH0 L"],
	"stubborn": ["S T AH1 B ER0 N"],
	"stuck": ["S T AH1 K"],
	"stud": ["S T AH1 D"],
	"student": ["S T UW1 D AH0 N T"],
	"studied": ["S T AH1 D IY0 D"],
	"studious": ["S T UW1 JH AH0 S"],
	"study": ["S T AH1 D IY0"],
	"stuff": ["S T AH1 F"],
	"stumbled": ["S T AH1 M B AH0 L D"],
	"stumbling": ["S T AH1 M B AH0 L IH3 NG", "S T AH1 M B L IH0 NG"],
	"stump": ["S T AH1 M P"],
	"stun": ["S T AH1 N"],
	"stung": ["S T AH1 NG"],
	"stupefied": ["S T Y UW1 P IH0 F AY2 D"],
	"stupendous": ["S T UW0 P EH1 N D AH0 S"],
	"stupid": ["S T UW1 P IH0 D"],
	"stupidity": ["S T UW0 P IH1 D IH0 T IY2"],
	"stupor": ["S T Y UW1 P ER0"],
	"sturdy": ["S T ER1 D IY0"],
	"stygian": ["S T IH1 JH IY0 AH0 N"],
	"style": ["S T AY1 L"],
	"styled": ["S T AY1 L D"],
	"subdivide": ["S AH2 B D AH0 V AY1 D"],
	"subdu'd": ["S AH0 B D Y UW1 D"],
	"subdue": ["S AH0 B D UW1"],
	"subdued": ["S AH0 B D UW1 D"],
	"subdueth": ["S AH0 B D Y UW1 IH0 TH"],
	"subject": ["S AH1 B JH IH0 K T", "S AH0 B JH EH1 K T"],
	"subjection": ["S AH0 B JH EH1 K SH AH0 N"],
	"subjects": ["S AH1 B JH EH0 K T S", "S AH0 B JH EH1 K T S"],
	"subjugated": ["S AH1 B JH AH0 G EY2 T IH0 D"],
	"subjugating": ["S AH1 B JH AH0 G EY2 T IH0 NG"],
	"sublime": ["S AH0 B L AY1 M"],
	"sublimer": ["S AH0 B L AY1 M ER0"],
	"sublimest": ["S AH0 B L AY1 M IH0 S T"],
	"sublimity": ["S AH0 B L IH1 M AH0 T IY2"],
	"submission": ["S AH0 B M IH1 SH AH0 N"],
	"submit": ["S AH0 B M IH1 T"],
	"subordinate": ["S AH0 B AO1 R D AH0 N EY2 T"],
	"subscribe": ["S AH0 B S K R AY1 B"],
	"subsequent": ["S AH1 B S AH0 K W AH2 N T"],
	"subservient": ["S AH0 B S ER1 V IY0 AH2 N T"],
	"subside": ["S AH0 B S AY1 D"],
	"subsiding": ["S AH0 B S AY1 D IH0 NG"],
	"subsist": ["S AH0 B S IH1 S T"],
	"substance": ["S AH1 B S T AH0 N S"],
	"substantial": ["S AH0 B S T AE1 N CH AH0 L"],
	"substitute": ["S AH1 B S T AH0 T UW2 T"],
	"subterranean": ["S AH2 B T ER0 EY1 N Y AH0 N", "S AH2 B T ER0 EY1 N IY0 AH3 N"],
	"subtle": ["S AH1 T AH0 L"],
	"subtleties": ["S AH1 T AH0 L T IY2 Z"],
	"subtraction": ["S AH0 B T R AE1 K SH AH0 N"],
	"suburb": ["S AH1 B ER0 B"],
	"succeed": ["S AH0 K S IY1 D"],
	"success": ["S AH0 K S EH1 S"],
	"successful": ["S AH0 K S EH1 S F UH0 L"],
	"succession": ["S AH0 K S EH1 SH AH0 N"],
	"successor": ["S AH0 K S EH1 S ER0"],
	"succour": ["S AH1 K ER0"],
	"succumb": ["S AH0 K AH1 M"],
	"such": ["S AH1 CH"],
	"suckle": ["S AH1 K AH0 L"],
	"suckled": ["S AH1 K AH0 L D"],
	"suction": ["S AH1 K SH AH0 N"],
	"sudden": ["S AH1 D AH0 N"],
	"sue": ["S UW1"],
	"sued": ["S UW1 D"],
	"suffer": ["S AH1 F ER0"],
	"suffering": ["S AH1 F ER0 IH3 NG", "S AH1 F R IH0 NG"],
	"suffic'd": ["S AH0 F AY1 S T"],
	"suffice": ["S AH0 F AY1 S"],
	"sufficed": ["S AH0 F AY1 S T"],
	"sufficeth": ["S AH0 F AY1 S IH0 TH"],
	"sufficiencies": ["S AH0 F IH1 SH AH0 N S IY2 Z"],
	"sufficiency": ["S AH0 F IH1 SH AH0 N S IY3"],
	"sufficient": ["S AH0 F IH1 SH AH0 N T"],
	"sufficing": ["S AH0 F AY1 S IH0 NG"],
	"suffuse": ["S AH0 F Y UW1 Z"],
	"suffused": ["S AH0 F Y UW1 Z D"],
	"suggest": ["S AH0 JH EH1 S T"],
	"suing": ["S UW1 IH0 NG"],
	"suit": ["S UW1 T"],
	"suitor": ["S UW1 T ER0"],
	"sulk": ["S AH1 L K"],
	"sultry": ["S AH1 L T R IY0"],
	"sum": ["S AH1 M"],
	"summer": ["S AH1 M ER0"],
	"summit": ["S AH1 M IH0 T"],
	"summon": ["S AH1 M AH0 N"],
	"sumptuous": ["S AH1 M P CH W AH0 S", "S AH1 M P CH UW0 AH3 S"],
	"sun": ["S AH1 N"],
	"sunburnt": ["S AH1 N B ER2 N T"],
	"sunday": ["S AH1 N D EY2"],
	"sunder": ["S AH1 N D ER0"],
	"sundered": ["S AH1 N D ER0 D"],
	"sundown": ["S AH1 N D AW2 N"],
	"sundry": ["S AH1 N D R IY0"],
	"sung": ["S AH1 NG"],
	"sunk": ["S AH1 NG K"],
	"sunlight": ["S AH1 N L AY2 T"],
	"sunny": ["S AH1 N IY0"],
	"sunrise": ["S AH1 N R AY2 Z"],
	"sunset": ["S AH1 N S EH0 T"],
	"sunshine": ["S AH1 N SH AY2 N"],
	"sup": ["S AH1 P"],
	"supercilious": ["S UW2 P ER0 S IH1 L IY0 AH3 S", "S UW2 P ER0 S IH1 L Y AH0 S"],
	"superfine": ["S UW2 P ER0 F AY1 N"],
	"superfluous": ["S UW0 P ER1 F L W AH0 S", "S UW0 P ER1 F L UW0 AH3 S"],
	"superhuman": ["S UW2 P ER0 HH Y UW1 M AH0 N"],
	"superior": ["S UW0 P IY1 R Y ER0"],
	"superiority": ["S AH0 P IY1 R IY0 AO2 R IH0 T IY3"],
	"superlative": ["S UH0 P ER1 L AH0 T IH3 V"],
	"supersede": ["S UW2 P ER0 S IY1 D"],
	"superseding": ["S UW2 P ER0 S IY1 D IH0 NG"],
	"superstition": ["S UW2 P ER0 S T IH1 SH AH0 N"],
	"superstitious": ["S UW2 P ER0 S T IH1 SH AH0 S"],
	"supine": ["S AH0 P AY1 N"],
	"supper": ["S AH1 P ER0"],
	"supplant": ["S AH0 P L AE1 N T"],
	"supple": ["S AH1 P AH0 L"],
	"supplicate": ["S AH1 P L IH0 K EY2 T"],
	"supplied": ["S AH0 P L AY1 D"],
	"supplies": ["S AH0 P L AY1 Z"],
	"supply": ["S AH0 P L AY1"],
	"support": ["S AH0 P AO1 R T"],
	"suppose": ["S AH0 P OW1 Z"],
	"supposed": ["S AH0 P OW1 Z D"],
	"supposition": ["S AH2 P AH0 Z IH1 SH AH0 N"],
	"suppress": ["S AH0 P R EH1 S"],
	"suppression": ["S AH0 P R EH1 SH AH0 N"],
	"supreme": ["S AH0 P R IY1 M"],
	"supremer": ["S UH0 P R IY1 M ER0"],
	"supremest": ["S AH0 P R IY1 M IH0 S T"],
	"surcease": ["S ER0 S IY1 S"],
	"sure": ["SH UH1 R"],
	"surely": ["SH UH1 R L IY0"],
	"surer": ["SH UH1 R ER0"],
	"sureties": ["SH ER1 AH0 T IY3 Z"],
	"surf": ["S ER1 F"],
	"surface": ["S ER1 F AH0 S"],
	"surfeit": ["S ER1 F IH0 T"],
	"surge": ["S ER1 JH"],
	"surgeon": ["S ER1 JH AH0 N"],
	"surgery": ["S ER1 JH ER0 IY3"],
	"surly": ["S ER1 L IY0"],
	"surmise": ["S ER0 M AY1 Z"],
	"surmising": ["S ER0 M AY1 Z IH0 NG"],
	"surmount": ["S ER0 M AW1 N T"],
	"surpass": ["S ER0 P AE1 S"],
	"surplice": ["S ER1 P L IH0 S"],
	"surprise": ["S ER0 P R AY1 Z"],
	"surprised": ["S ER0 P R AY1 Z D"],
	"surrender": ["S ER0 EH1 N D ER0"],
	"surrendered": ["S ER0 EH1 N D ER0 D"],
	"surreptitious": ["S ER2 AH0 P T IH1 SH AH0 S"],
	"surrogate": ["S ER1 AH0 G AH3 T"],
	"surround": ["S ER0 AW1 N D"],
	"survey": ["S ER0 V EY1"],
	"survive": ["S ER0 V AY1 V"],
	"survivors": ["S ER0 V AY1 V ER0 Z"],
	"susceptive": ["S AH0 S EH1 P T IH0 V"],
	"suspect": ["S AH0 S P EH1 K T", "S AH1 S P EH2 K T"],
	"suspected": ["S AH0 S P EH1 K T IH0 D"],
	"suspecting": ["S AH0 S P EH1 K T IH0 NG"],
	"suspend": ["S AH0 S P EH1 N D"],
	"suspense": ["S AH0 S P EH1 N S"],
	"suspicion": ["S AH0 S P IH1 SH AH0 N"],
	"suspicious": ["S AH0 S P IH1 SH AH0 S"],
	"sustain": ["S AH0 S T EY1 N"],
	"sustenance": ["S AH1 S T AH0 N AH3 N S"],
	"suzerain": ["S UW1 Z ER0 EY2 N"],
	"swagger": ["S W AE1 G ER0"],
	"swain": ["S W EY1 N"],
	"swallow": ["S W AA1 L OW0"],
	"swam": ["S W AE1 M"],
	"swamp": ["S W AO1 M P"],
	"swan": ["S W AA1 N"],
	"swang": ["S W AE1 NG"],
	"sward": ["S W AO1 R D"],
	"swarm": ["S W AO1 R M"],
	"sway": ["S W EY1"],
	"swear": ["S W EH1 R"],
	"sweat": ["S W EH1 T"],
	"sweep": ["S W IY1 P"],
	"sweet": ["S W IY1 T"],
	"swell": ["S W EH1 L"],
	"swept": ["S W EH1 P T"],
	"swerve": ["S W ER1 V"],
	"swerved": ["S W ER1 V D"],
	"swerving": ["S W ER1 V IH0 NG"],
	"swift": ["S W IH1 F T"],
	"swim": ["S W IH1 M"],
	"swimming": ["S W IH1 M IH0 NG"],
	"swindled": ["S W IH1 N D AH0 L D"],
	"swindler": ["S W IH1 N D L ER0"],
	"swine": ["S W AY1 N"],
	"swing": ["S W IH1 NG"],
	"swirl": ["S W ER1 L"],
	"swish": ["S W IH1 SH"],
	"swiss": ["S W IH1 S"],
	"swollen": ["S W OW1 L AH0 N"],
	"swoon": ["S W UW1 N"],
	"swoop": ["S W UW1 P"],
	"sword": ["S AO1 R D"],
	"swore": ["S W AO1 R"],
	"sworn": ["S W AO1 R N"],
	"swound": ["S W AW1 N D"],
	"swung": ["S W AH1 NG"],
	"sycamore": ["S IH1 K AH0 M AO2 R"],
	"syllable": ["S IH1 L AH0 B AH3 L"],
	"symbol": ["S IH1 M B AH0 L"],
	"symmetry": ["S IH1 M AH0 T R IY2"],
	"sympathies": ["S IH1 M P AH0 TH IY2 Z"],
	"sympathiz'd": ["S IH1 M P AH0 TH AY2 Z D"],
	"sympathy": ["S IH1 M P AH0 TH IY3"],
	"symptom": ["S IH1 M P T AH0 M"],
	"syndicate": ["S IH1 N D IH0 K AH0 T"],
	"synod": ["S IH1 N AH0 D"],
	"synonyme": ["S IH1 N AH0 N IH2 M"],
	"syphilis": ["S IH1 F AH0 L IH2 S"],
	"system": ["S IH1 S T AH0 M"],
	"t": ["T IY1"],
	"t'other": ["T AH1 DH ER0"],
	"ta'en": ["T EY1 N"],
	"tabernacle": ["T AE1 B ER0 N AE2 K AH0 L"],
	"table": ["T EY1 B AH0 L"],
	"tableau": ["T AH0 B L OW1"],
	"taciturn": ["T AE1 S IH0 T ER2 N"],
	"taciturnity": ["T AE2 S IH0 T ER1 N IH0 T IY2"],
	"tack": ["T AE1 K"],
	"tact": ["T AE1 K T"],
	"tactic": ["T AE1 K T IH0 K"],
	"tagliafer": ["T AA2 L IH0 AH0 F EH1 R"],
	"tail": ["T EY1 L"],
	"taint": ["T EY1 N T"],
	"take": ["T EY1 K"],
	"taken": ["T EY1 K AH0 N"],
	"taking": ["T EY1 K IH0 NG"],
	"tale": ["T EY1 L"],
	"talent": ["T AE1 L AH0 N T"],
	"talismanic": ["T AE1 L IH0 S M AE2 N IH0 K"],
	"talk": ["T AO1 K"],
	"tall": ["T AO1 L"],
	"tamarind": ["T AE1 M AH0 R IH2 N D", "T AE1 M R IH0 N D"],
	"tamborin": ["T AE2 M B ER0 IY1 N"],
	"tamborine": ["T AE2 M B ER0 IY1 N"],
	"tame": ["T EY1 M"],
	"tamed": ["T EY1 M D"],
	"tamper": ["T AE1 M P ER0"],
	"tan": ["T AE1 N"],
	"tangle": ["T AE1 NG G AH0 L"],
	"tangled": ["T AE1 NG G AH0 L D"],
	"tank": ["T AE1 NG K"],
	"tankard": ["T AE1 NG K ER0 D"],
	"tantalizing": ["T AE1 N T AH0 L AY2 Z IH0 NG"],
	"taper": ["T EY1 P ER0"],
	"tapestry": ["T AE1 P AH0 S T R IY3"],
	"tapped": ["T AE1 P T"],
	"tapping": ["T AE1 P IH0 NG"],
	"tar": ["T AA1 R"],
	"tardy": ["T AA1 R D IY0"],
	"tare": ["T EH1 R"],
	"targe": ["T AA1 R JH"],
	"target": ["T AA1 R G AH0 T"],
	"tarried": ["T EH1 R IY0"],
	"tarry": ["T EH1 R IY0"],
	"tartar": ["T AA1 R T ER0"],
	"task": ["T AE1 S K"],
	"tassel": ["T AE1 S AH0 L"],
	"taste": ["T EY1 S T"],
	"tasted": ["T EY1 S T IH0 D"],
	"tasting": ["T EY1 S T IH0 NG"],
	"tatter": ["T AE1 T ER0"],
	"taught": ["T AO1 T"],
	"taunt": ["T AO1 N T"],
	"taurello": ["T AW0 R EH1 L OW0"],
	"tavern": ["T AE1 V ER0 N"],
	"tax": ["T AE1 K S"],
	"tea": ["T IY1"],
	"teach": ["T IY1 CH"],
	"teachers": ["T IY1 CH ER0 Z"],
	"teacup": ["T IY1 K AH0 P"],
	"team": ["T IY1 M"],
	"tear": ["T EH1 R", "T IY1 R"],
	"tearful": ["T IY1 R F AH0 L"],
	"tears": ["T IY1 R Z", "T EH1 R Z"],
	"tease": ["T IY1 Z"],
	"teaze": ["T IY1 Z"],
	"teazed": ["T IY1 Z D"],
	"teazing": ["T IY1 Z IH0 NG"],
	"technicality": ["T EH1 K N AH0 K AE2 L IH0 T IY3"],
	"tedious": ["T IY1 D IY0 AH3 S", "T IY1 D Y AH0 S"],
	"teem": ["T IY1 M"],
	"teeth": ["T IY1 TH"],
	"telegram": ["T EH1 L AH0 G R AE2 M"],
	"telegraphic": ["T EH2 L AH0 G R AE1 F IH0 K"],
	"telescope": ["T EH1 L AH0 S K OW2 P"],
	"telescopic": ["T EH2 L AH0 S K AO1 P IH0 K"],
	"tell": ["T EH1 L"],
	"teller": ["T EH1 L ER0"],
	"temerity": ["T AH0 M EH1 R IH0 T IY3"],
	"tempe": ["T EH1 M P IY0"],
	"temper": ["T EH1 M P ER0"],
	"temperance": ["T EH1 M P ER0 AH0 N S"],
	"temperate": ["T EH1 M P R AH0 T", "T EH1 M P ER0 AH3 T"],
	"temperature": ["T EH1 M P R AH0 CH ER3", "T EH1 M P ER0 AH0 CH ER0"],
	"tempest": ["T EH1 M P AH0 S T"],
	"temple": ["T EH1 M P AH0 L"],
	"tempt": ["T EH1 M P T"],
	"temptation": ["T EH0 M T EY1 SH AH0 N"],
	"ten": ["T EH1 N"],
	"tenant": ["T EH1 N AH0 N T"],
	"tend": ["T EH1 N D"],
	"tendency": ["T EH1 N D AH0 N S IY3"],
	"tender": ["T EH1 N D ER0"],
	"tenement": ["T EH1 N AH0 M AH2 N T"],
	"teneriffe": ["T EH1 N ER0 IH2 F"],
	"tenet": ["T EH1 N AH0 T"],
	"tenfold": ["T EH1 N F OW2 L D"],
	"tent": ["T EH1 N T"],
	"tenure": ["T EH1 N Y ER0"],
	"tepid": ["T EH1 P IH0 D"],
	"terebinth": ["T EH1 R AH0 B IH3 N TH"],
	"term": ["T ER1 M"],
	"terminable": ["T ER1 M AH0 N AH0 B AH0 L"],
	"terminate": ["T ER1 M AH0 N EY2 T"],
	"terrace": ["T EH1 R AH0 S"],
	"terrestrial": ["T ER0 EH1 S T R IY0 AH0 L", "T ER0 EH1 S T R Y AH0 L"],
	"terrible": ["T EH1 R AH0 B AH2 L"],
	"terribler": ["T EH1 R AH0 B L ER3"],
	"territories": ["T EH1 R AH0 T AO2 R IY0 Z"],
	"territory": ["T EH1 R IH0 T AO2 R IY0", "T EH1 R IH0 T R IY3"],
	"terror": ["T EH1 R ER0"],
	"tertian": ["T ER1 SH AH0 N"],
	"test": ["T EH1 S T"],
	"testified": ["T EH1 S T AH0 F AY2 D"],
	"testifies": ["T EH1 S T AH0 F AY2 Z"],
	"testify": ["T EH1 S T AH0 F AY2"],
	"text": ["T EH1 K S T"],
	"th'": ["DH"],
	"thames": ["T EH1 M Z"],
	"than": ["DH AE1 N"],
	"thank": ["TH AE1 NG K"],
	"thankful": ["TH AE1 NG K F AH0 L"],
	"thanksgiving": ["TH AE2 NG K S G IH1 V IH0 NG"],
	"thas": ["T AE1 Z"],
	"that": ["DH AE2 T"],
	"thatch": ["TH AE1 CH"],
	"thaw": ["TH AO1"],
	"the": ["DH AH3"],
	"theater": ["TH IY1 AH0 T ER0"],
	"theatre": ["TH IY1 AH0 T ER3"],
	"thee": ["DH IY3"],
	"theft": ["TH EH1 F T"],
	"their": ["DH EH3 R"],
	"theirs": ["DH EH1 R Z"],
	"them": ["DH EH3 M"],
	"theme": ["TH IY1 M"],
	"themself": ["DH EH0 M S EH1 L F"],
	"themselves": ["DH EH0 M S EH1 L V Z"],
	"then": ["DH EH1 N"],
	"thence": ["DH EH1 N S"],
	"thenceforward": ["DH EH2 N S F AO1 R W ER0 D"],
	"theocritus": ["TH IY0 AA1 K R IH0 T UH2 S"],
	"theology": ["TH IY0 AA1 L AH0 JH IY3"],
	"theories": ["TH IY1 R IY0 Z", "TH IY1 ER0 IY3 Z"],
	"there": ["DH EH1 R"],
	"there'll": ["DH EH1 R AH0 L"],
	"thereabout": ["DH EH2 R AH0 B AW1 T"],
	"thereat": ["DH EH0 R AE1 T"],
	"thereby": ["DH EH1 R B AY1"],
	"therefore": ["DH EH1 R F AO0 R"],
	"therefrom": ["TH EH2 R F R AH1 M"],
	"therein": ["DH EH2 R IH1 N"],
	"thereon": ["DH EH0 R AO1 N"],
	"thereto": ["DH EH2 R T UW1"],
	"therewith": ["DH EH2 R W IH1 TH"],
	"these": ["DH IY1 Z"],
	"thessaly": ["TH EH1 S AH0 L IY3"],
	"thew": ["TH Y UW1"],
	"they": ["DH EY3"],
	"they'd": ["DH EY1 D"],
	"they'll": ["DH EY1 L"],
	"they're": ["DH EH1 R"],
	"thick": ["TH IH1 K"],
	"thicket": ["TH IH1 K AH0 T"],
	"thief": ["TH IY1 F"],
	"thigh": ["TH AY1"],
	"thill": ["TH IY1 L", "TH IH1 L"],
	"thimble": ["TH IH1 M B AH0 L"],
	"thin": ["TH IH1 N"],
	"thine": ["DH AY1 N"],
	"thing": ["TH IH1 NG"],
	"think": ["TH IH1 NG K"],
	"thinned": ["TH IH1 N D"],
	"third": ["TH ER1 D"],
	"thirst": ["TH ER1 S T"],
	"thirsty": ["TH ER1 S T IY0"],
	"thirty": ["TH ER1 T IY2"],
	"this": ["DH IH1 S"],
	"tho": ["DH OW1"],
	"tho'": ["DH OW1"],
	"tho'soever": ["TH OW2 S OW0 EH1 V ER0"],
	"thomas": ["T AA1 M AH0 S"],
	"thomson": ["T AA1 M S AH0 N"],
	"thong": ["TH AA1 NG"],
	"thorn": ["TH AO1 R N"],
	"those": ["DH OW1 Z"],
	"thou": ["DH AW2"],
	"thou'rt": ["DH AW1 R T"],
	"though": ["DH OW1"],
	"thought": ["TH AO1 T"],
	"thoughtfully": ["TH AO1 T F AH0 L IY2"],
	"thoughtfulness": ["TH AO1 T F UH0 L N AH3 S"],
	"thousand": ["TH AW1 Z AH0 N D"],
	"thrall": ["TH R AO1 L"],
	"thread": ["TH R EH1 D"],
	"threat": ["TH R EH1 T"],
	"threaten": ["TH R EH1 T AH0 N"],
	"three": ["TH R IY1"],
	"threescore": ["TH R IY2 S K AO1 R"],
	"thresh": ["TH R EH1 SH"],
	"threshold": ["TH R EH1 SH OW2 L D"],
	"threw": ["TH R UW1"],
	"thrice": ["TH R AY1 S"],
	"thrifty": ["TH R IH1 F T IY0"],
	"thrill": ["TH R IH1 L"],
	"thrive": ["TH R AY1 V"],
	"thriving": ["TH R AY1 V IH0 NG"],
	"thro": ["TH R UW3"],
	"thro'": ["TH R UW3"],
	"throat": ["TH R OW1 T"],
	"throb": ["TH R AA1 B"],
	"throe": ["TH R OW1"],
	"throne": ["TH R OW1 N"],
	"throng": ["TH R AO1 NG"],
	"through": ["TH R UW3"],
	"throughout": ["TH R UW0 AW1 T"],
	"throw": ["TH R OW1"],
	"thrown": ["TH R OW1 N"],
	"thrum": ["TH R AH1 M"],
	"thrush": ["TH R AH1 SH"],
	"thrust": ["TH R AH1 S T"],
	"thumb": ["TH AH1 M"],
	"thump": ["TH AH1 M P"],
	"thunder": ["TH AH1 N D ER0"],
	"thunderbolt": ["TH AH1 N D ER0 B AO2 L T"],
	"thus": ["DH AH1 S"],
	"thwart": ["TH W AO1 R T"],
	"thy": ["DH AY3"],
	"thyme": ["TH AY1 M"],
	"thyself": ["DH AY2 S EH1 L F"],
	"tiber": ["T AY1 B ER0"],
	"tick": ["T IH1 K"],
	"ticket": ["T IH1 K IH0 T"],
	"tickle": ["T IH1 K AH0 L"],
	"tide": ["T AY1 D"],
	"tiding": ["T AY1 D IH0 NG"],
	"tidy": ["T AY1 D IY0"],
	"tie": ["T AY1"],
	"tied": ["T AY1 D"],
	"ties": ["T AY1 Z"],
	"tiger": ["T AY1 G ER0"],
	"tight": ["T AY1 T"],
	"tighten": ["T AY1 T AH0 N"],
	"tile": ["T AY1 L"],
	"till": ["T IH1 L"],
	"tilt": ["T IH1 L T"],
	"tim": ["T IH1 M"],
	"timber": ["T IH1 M B ER0"],
	"timbrel": ["T IH1 M B R AH0 L"],
	"time": ["T AY1 M"],
	"timeline": ["T AY1 M L AY0 N"],
	"timepiece": ["T AY1 M P IY2 S"],
	"timid": ["T IH1 M IH0 D"],
	"tincture": ["T IH1 NG K CH ER0"],
	"tinder": ["T IH1 N D ER0"],
	"tinge": ["T IH1 N JH"],
	"tingle": ["T IH1 NG G AH0 L"],
	"tinkle": ["T IH1 NG K AH0 L"],
	"tint": ["T IH1 N T"],
	"tiny": ["T AY1 N IY0"],
	"tip": ["T IH1 P"],
	"tipping": ["T IH1 P IH0 NG"],
	"tippler": ["T IH1 P L ER0"],
	"tiptoe": ["T IH1 P T OW2"],
	"tir'd": ["T AY1 R D"],
	"tire": ["T AY1 ER0", "T AY1 R"],
	"tired": ["T AY1 R D", "T AY1 ER0 D"],
	"tiresome": ["T AY1 ER0 S AH3 M", "T AY1 R S AH0 M"],
	"tis": ["T IH1 Z"],
	"tissue": ["T IH1 SH Y UW0"],
	"tita": ["T IY1 T AH0"],
	"titanic": ["T AY0 T AE1 N IH0 K"],
	"tithe": ["T AY1 DH"],
	"title": ["T AY1 T AH0 L"],
	"titled": ["T AY1 T AH0 L D"],
	"tittle": ["T IH1 T AH0 L"],
	"tiverton": ["T IH1 V ER0 T AH2 N"],
	"to": ["T UW3"],
	"to't": ["T UW1 T"],
	"today": ["T AH0 D EY1"],
	"toddle": ["T AA1 D AH0 L"],
	"toe": ["T OW1"],
	"together": ["T AH0 G EH1 DH ER0"],
	"toil": ["T OY1 L"],
	"toilette": ["T OY1 L EH0 T", "T W AA0 L EH1 T"],
	"toilsome": ["T OY1 L S AH0 M"],
	"toise": ["T OY1 Z"],
	"token": ["T OW1 K AH0 N"],
	"told": ["T OW1 L D"],
	"tolerate": ["T AO1 L ER0 EY2 T"],
	"toll": ["T OW1 L"],
	"tolling": ["T OW1 L IH0 NG"],
	"tomato": ["T AH0 M EY1 T OW2", "T AH0 M AA1 T OW2"],
	"tomb": ["T UW1 M"],
	"tome": ["T OW1 M"],
	"tomorrow": ["T AH0 M AA1 R OW2"],
	"ton": ["T AH1 N"],
	"tone": ["T OW1 N"],
	"tongue": ["T AH1 NG"],
	"tongued": ["T AH1 NG D"],
	"tonight": ["T AH0 N AY1 T"],
	"too": ["T UW1"],
	"took": ["T UH1 K"],
	"tool": ["T UW1 L"],
	"tooth": ["T UW1 TH"],
	"top": ["T AA1 P"],
	"topaz": ["T OW1 P AE2 Z"],
	"topography": ["T AH0 P AA1 G R AH0 F IY3"],
	"topt": ["T AA1 P T"],
	"torch": ["T AO1 R CH"],
	"tore": ["T AO1 R"],
	"torment": ["T AO1 R M EH2 N T", "T AO0 R M EH1 N T"],
	"torn": ["T AO1 R N"],
	"torrid": ["T AO1 R IH0 D"],
	"tortoise": ["T AO1 R T AH0 S"],
	"torture": ["T AO1 R CH ER0"],
	"toss": ["T AO1 S"],
	"tost": ["T AA1 S T"],
	"total": ["T OW1 T AH0 L"],
	"touch": ["T AH1 CH"],
	"tough": ["T AH1 F"],
	"tour": ["T UH1 R"],
	"tourist": ["T UH1 R IH0 S T"],
	"tournament": ["T UH1 R N AH0 M EH2 N T"],
	"tow'r": ["T AW1 R", "T AW1 ER0"],
	"toward": ["T AH0 W AO1 R D", "T AO1 R D"],
	"towards": ["T AH0 W AO1 R D Z", "T AO1 R D Z"],
	"tower": ["T AW1 ER0", "T AW1 R"],
	"tower'd": ["T AW1 ER0 D", "T AW1 R D"],
	"towering": ["T AW1 ER0 IH3 NG", "T AW1 R IH0 NG"],
	"towers": ["T AW1 ER0 Z", "T AW1 R Z"],
	"towertop": ["T AW1 R T AA3 P", "T AW1 ER0 T AA2 P"],
	"town": ["T AW1 N"],
	"toxin": ["T AA1 K S AH0 N"],
	"toy": ["T OY1"],
	"trace": ["T R EY1 S"],
	"traced": ["T R EY1 S T"],
	"tracing": ["T R EY1 S IH0 NG"],
	"track": ["T R AE1 K"],
	"tract": ["T R AE1 K T"],
	"trade": ["T R EY1 D"],
	"tradition": ["T R AH0 D IH1 SH AH0 N"],
	"traditional": ["T R AH0 D IH1 SH AH0 N AH0 L"],
	"trafalgar": ["T R AH0 F AE1 L G ER0"],
	"traffic": ["T R AE1 F IH0 K"],
	"tragedy": ["T R AE1 JH AH0 D IY3"],
	"trail": ["T R EY1 L"],
	"train": ["T R EY1 N"],
	"trait": ["T R EY1 T"],
	"traitor": ["T R EY1 T ER0"],
	"trammelled": ["T R AE1 M AH0 L D"],
	"tramp": ["T R AE1 M P"],
	"trample": ["T R AE1 M P AH0 L"],
	"trance": ["T R AE1 N S"],
	"tranquil": ["T R AE1 NG K W IH0 L"],
	"tranquility": ["T R AE0 NG K W IH1 L IH0 T IY3"],
	"tranquiller": ["T R AE1 NG K W AH0 L"],
	"tranquillity": ["T R AE0 NG K W IH1 L IH0 T IY3"],
	"transaction": ["T R AE0 N Z AE1 K SH AH0 N"],
	"transatlantic": ["T R AE2 N Z AH0 T L AE1 N T IH0 K"],
	"transcend": ["T R AE0 N S EH1 N D"],
	"transept": ["T R AE1 N Z EH0 P T"],
	"transfer": ["T R AE0 N S F ER1"],
	"transferr'd": ["T R AE0 N S F ER1 D"],
	"transfigured": ["T R AE0 N Z F IH1 G Y UH0 R D"],
	"transfix": ["T R AE0 N S F IH1 K S"],
	"transgress": ["T R AE0 N Z G R EH1 S"],
	"transgression": ["T R AE0 N Z G R EH1 SH AH0 N"],
	"transient": ["T R AE1 N ZH AH0 N T"],
	"transit": ["T R AE1 N S IH0 T"],
	"transitive": ["T R AE1 N S IH0 T IH3 V"],
	"translate": ["T R AE0 N Z L EY1 T"],
	"translated": ["T R AE0 N Z L EY1 T IH0 D", "T R AE1 N Z L EY2 T IH0 D"],
	"translation": ["T R AE0 N Z L EY1 SH AH0 N"],
	"transmigration": ["T R AE0 N Z M AY0 G R EY1 SH AH0 N"],
	"transmuted": ["T R AE2 N Z M Y UW1 T IH0 D"],
	"transmuting": ["T R AE0 N Z M Y UW1 T IH0 NG"],
	"transpierce": ["T R AE0 N S P IY1 R S"],
	"transport": ["T R AE0 N S P AO1 R T", "T R AE1 N S P AO0 R T"],
	"transported": ["T R AE0 N S P AO1 R T IH0 D", "T R AE1 N S P AO2 R T IH0 D"],
	"transporting": ["T R AE0 N S P AO1 R T IH0 NG"],
	"transubstantiate": ["T R AE2 N S AH0 B S T AE1 N SH IH0 EY2 T"],
	"trap": ["T R AE1 P"],
	"travel": ["T R AE1 V AH0 L"],
	"traveler": ["T R AE1 V AH0 L ER3", "T R AE1 V L ER0"],
	"travelled": ["T R AE1 V AH0 L D"],
	"traveller": ["T R AE1 V AH0 L ER3", "T R AE1 V L ER0"],
	"travellers": ["T R AE1 V AH0 L ER3 Z", "T R AE1 V L ER0 Z"],
	"travelling": ["T R AE1 V AH0 L IH3 NG", "T R AE1 V L IH0 NG"],
	"traverse": ["T R AE1 V ER0 S", "T R AH0 V ER1 S"],
	"traversed": ["T R AE1 V ER0 S T", "T R AH0 V ER1 S T"],
	"traverses": ["T R AH0 V ER1 S IH0 Z"],
	"traversing": ["T R AH0 V ER1 S IH0 NG"],
	"treacheries": ["T R EH1 CH ER0 IY2 Z"],
	"tread": ["T R EH1 D"],
	"treason": ["T R IY1 Z AH0 N"],
	"treasure": ["T R EH1 ZH ER0"],
	"treat": ["T R IY1 T"],
	"treble": ["T R EH1 B AH0 L"],
	"tree": ["T R IY1"],
	"trellis": ["T R EH1 L IH0 S"],
	"tremble": ["T R EH1 M B AH0 L"],
	"trembling": ["T R EH1 M B AH0 L IH3 NG", "T R EH1 M B L IH0 NG"],
	"tremendous": ["T R AH0 M EH1 N D AH0 S"],
	"tremor": ["T R EH1 M ER0"],
	"tremulous": ["T R EH1 M Y AH0 L AH3 S"],
	"trench": ["T R EH1 N CH"],
	"trent": ["T R EH1 N T"],
	"trepidation": ["T R EH2 P IH0 D EY1 SH AH0 N"],
	"tress": ["T R EH1 S"],
	"trevisan": ["T R EH1 V AH0 S AE2 N"],
	"trial": ["T R AY1 AH0 L"],
	"tribe": ["T R AY1 B"],
	"tribunal": ["T R AY2 B Y UW1 N AH0 L"],
	"trice": ["T R AY1 S"],
	"trick": ["T R IH1 K"],
	"trickle": ["T R IH1 K AH0 L"],
	"tried": ["T R AY1 D"],
	"tries": ["T R AY1 Z"],
	"trifle": ["T R AY1 F AH0 L"],
	"trifling": ["T R AY1 F L IH0 NG"],
	"trigger": ["T R IH1 G ER0"],
	"trill": ["T R IH1 L"],
	"trim": ["T R IH1 M"],
	"trimm'd": ["T R IH1 M D"],
	"trimmed": ["T R IH1 M D"],
	"trimmer": ["T R IH1 M ER0"],
	"trinidada": ["T R IH2 N IH0 D AE1 D AH0"],
	"trinket": ["T R IH1 NG K IH0 T"],
	"trip": ["T R IH1 P"],
	"triple": ["T R IH1 P AH0 L"],
	"tripped": ["T R IH1 P T"],
	"tripping": ["T R IH1 P IH0 NG"],
	"trite": ["T R AY1 T"],
	"triton": ["T R AY1 T AH0 N"],
	"triumph": ["T R AY1 AH0 M F"],
	"triumphant": ["T R AY0 AH1 M F AH0 N T"],
	"trivial": ["T R IH1 V IY0 AH3 L", "T R IH1 V Y AH0 L"],
	"trod": ["T R AA1 D"],
	"trodden": ["T R AA1 D AH0 N"],
	"trode": ["T R OW1 D"],
	"trojan": ["T R OW1 JH AH0 N"],
	"troop": ["T R UW1 P"],
	"trophies": ["T R OW1 F IY0 Z"],
	"trophy": ["T R OW1 F IY0"],
	"tropic": ["T R AA1 P IH0 K"],
	"troth": ["T R AO1 TH"],
	"trotwood": ["T R AA1 T W UH2 D"],
	"troubadour": ["T R UW1 B AH0 D AO2 R"],
	"trouble": ["T R AH1 B AH0 L"],
	"troubled": ["T R AH1 B AH0 L D"],
	"troubling": ["T R AH1 B L IH0 NG"],
	"troupe": ["T R UW1 P"],
	"trow": ["T R OW1"],
	"trowsers": ["T R AW1 Z ER0 Z"],
	"troy": ["T R OY1"],
	"trudge": ["T R AH1 JH"],
	"trudging": ["T R AH1 JH IH0 NG"],
	"true": ["T R UW1"],
	"truffled": ["T R AH1 F AH0 L D"],
	"truly": ["T R UW1 L IY0"],
	"trumpet": ["T R AH1 M P IH0 T"],
	"trundle": ["T R AH1 N D AH0 L"],
	"trunk": ["T R AH1 NG K"],
	"trust": ["T R AH1 S T"],
	"truth": ["T R UW1 TH"],
	"try": ["T R AY1"],
	"tryermaine": ["T R AY1 ER0 M EY2 N"],
	"trying": ["T R AY1 IH0 NG"],
	"tub": ["T AH1 B"],
	"tuck": ["T AH1 K"],
	"tuffet": ["T AH1 F IH0 T"],
	"tuft": ["T AH1 F T"],
	"tug": ["T AH1 G"],
	"tugging": ["T AH1 G IH0 NG"],
	"tulle": ["T AH1 L"],
	"tumble": ["T AH1 M B AH0 L"],
	"tumult": ["T UW1 M AH0 L T"],
	"tumultuous": ["T AH0 M UH1 L CH W AH0 S", "T AH0 M EH1 L CH UH0 AH3 S"],
	"tune": ["T UW1 N"],
	"tunnel": ["T AH1 N AH0 L"],
	"turban": ["T ER1 B AH0 N"],
	"turbulence": ["T ER1 B Y AH0 L AH3 N S"],
	"turbulent": ["T ER1 B Y AH0 L AH3 N T"],
	"turf": ["T ER1 F"],
	"turk": ["T ER1 K"],
	"turmoils": ["T ER1 M OY0 L Z"],
	"turn": ["T ER1 N"],
	"turnpike": ["T ER1 N P AY2 K"],
	"turret": ["T ER1 AH0 T"],
	"turtle": ["T ER1 T AH0 L"],
	"tussle": ["T AH1 S AH0 L"],
	"tutor": ["T UW1 T ER0"],
	"twain": ["T W EY1 N"],
	"twang": ["T W AE1 NG"],
	"twas": ["T W AH1 Z"],
	"tweedle": ["T W IY1 D AH0 L"],
	"twelve": ["T W EH1 L V"],
	"twenty": ["T W EH1 N T IY0"],
	"twere": ["T W ER1"],
	"twice": ["T W AY1 S"],
	"twig": ["T W IH1 G"],
	"twilight": ["T W AY1 L AY2 T"],
	"twill": ["T W IH1 L"],
	"twin": ["T W IH1 N"],
	"twine": ["T W AY1 N"],
	"twinkle": ["T W IH1 NG K AH0 L"],
	"twinkled": ["T W IH1 NG K AH0 L D"],
	"twinkling": ["T W IH1 NG K L IH0 NG"],
	"twirl": ["T W ER1 L"],
	"twist": ["T W IH1 S T"],
	"twitch": ["T W IH1 CH"],
	"twitching": ["T W IH1 CH IH0 NG"],
	"twitt'ring": ["T W IH1 T R IH0 NG"],
	"twixt": ["T W IH1 K S T"],
	"two": ["T UW1"],
	"type": ["T AY1 P"],
	"tyrannies": ["T IY1 R AH0 N IY3 Z"],
	"tyrannous": ["T IY1 R AH0 N AH0 S"],
	"tyranny": ["T IY1 R AH0 N IY3"],
	"tyrant": ["T AY1 R AH0 N T"],
	"tyre": ["T AY1 R"],
	"tyrian": ["T IY1 R Y AH0 N"],
	"ucalegon": ["UW0 K AE1 L AH0 G AA2 N"],
	"ultimate": ["AH1 L T AH0 M AH3 T"],
	"umber": ["AH1 M B ER0"],
	"umbrageous": ["AH0 M B R EY1 JH AH0 S"],
	"umpire": ["AH1 M P AY2 ER0"],
	"unaccomplished": ["AH2 N AH0 K AA1 M P L IH0 SH T"],
	"unaccustomed": ["AH2 N AH0 K AH1 S T AH0 M D"],
	"unacknowledged": ["AH2 N AE0 K N AA1 L IH0 JH D"],
	"unafraid": ["AH2 N AH0 F R EY1 D"],
	"unallied": ["AH2 N AH0 L AY1 D"],
	"unaltered": ["AH0 N AO1 L T ER0 D"],
	"unannointed": ["AH2 N AH0 N OY1 N T EH0 D"],
	"unapproached": ["AH2 N AH0 P R OW1 CH T "],
	"unarmed": ["AH2 N AA1 R M D"],
	"unattempted": ["AH2 N AH0 T EH1 M P T IH0 D"],
	"unaverred": ["AH2 N AH0 V ER1 D"],
	"unavoidable": ["AH2 N AH0 V OY1 D AH0 B AH3 L"],
	"unaware": ["AH2 N AH0 W EH1 R"],
	"unawares": ["AH2 N AH0 W EH1 R Z"],
	"unbeguiled": ["AH2 N B IH0 G AY1 L D"],
	"unbereft": ["AH2 N B IH0 R EH1 F T"],
	"unbind": ["AH2 N B AY1 N D"],
	"unblest": ["AH2 N B L EH1 S T"],
	"unblown": ["AH2 N B L OW1 N"],
	"unblushing": ["AH2 N B L AH1 SH IH0 NG"],
	"unborn": ["AH1 N B AO1 R N"],
	"unbound": ["AH2 N B AW1 N D"],
	"unbraiding": ["AH2 N B REY1 D IH0 NG"],
	"unbred": ["AH2 N B R EH1 D"],
	"unbroken": ["AH0 N B R OW1 K AH0 N"],
	"unburied": ["AH0 N B UH1 R IY0 D"],
	"uncertain": ["AH0 N S ER1 T AH0 N"],
	"unchallenged": ["AH0 N CH AE1 L IH0 N JH D"],
	"uncivil": ["AH0 N S IH1 V AH0 L"],
	"unclaimed": ["AH2 N K L EY1 M D"],
	"unclasp": ["AH0 N K L AA1 S P"],
	"unclean": ["AH0 N K L IY1 N"],
	"unclose": ["AH2 N K L OW1 Z"],
	"unclosed": ["AH0 N K L OW1 Z D"],
	"uncommon": ["AH0 N K AA1 M AH0 N"],
	"unconcern": ["AH2 N K AH0 N S ER1 N"],
	"unconfin'd": ["AH2 N K AH0 N F AY1 N D"],
	"unconfined": ["AH2 N K AH0 N F AY1 N D"],
	"uncongeal": ["AH2 N K AH0 N JH IY1 L"],
	"unconjectured": ["AH2 N K AH0 N JH EH1 K CH ER0 D"],
	"unconscious": ["AH2 N K AA1 N SH AH0 S"],
	"unconspicuous": ["AH2 N K AH0 N S P IH1 K Y UW0 AH3 S"],
	"uncouth": ["AH2 N K UW1 TH", "AH1 N K UW2 TH"],
	"uncramped": ["AH0 N K R AE1 M P T"],
	"uncrampt": ["AH2 N K R AE1 M P T"],
	"undaunted": ["AH0 N D AO1 N T IH0 D"],
	"undecaying": ["AH2 N D AH0 K EY1 IH0 NG"],
	"undefeated": ["AH2 N D IH0 F IY1 T IH0 D"],
	"undefiled": ["AH2 N D AH0 F AY1 L D"],
	"under": ["AH1 N D ER0"],
	"underfoot": ["AH2 N D ER0 F UH1 T"],
	"undergo": ["AH2 N D ER0 G OW1"],
	"underground": ["AH1 N D ER0 G R AW2 N D"],
	"undermine": ["UH1 N D ER0 M AY2 N"],
	"undermining": ["AH2 N D ER0 M AY1 N IH0 NG"],
	"underneath": ["AH2 N D ER0 N IY1 TH"],
	"understand": ["AH2 N D ER0 S T AE1 N D"],
	"understood": ["AH2 N D ER0 S T UH1 D"],
	"undertake": ["AH2 N D ER0 T EY1 K"],
	"undertaking": ["AH1 N D ER0 T EY2 K IH0 NG"],
	"undertone": ["AH1 N D ER0 T OW2 N"],
	"underwent": ["AH2 N D ER0 W EH1 N T"],
	"undid": ["AH2 N D IH1 D"],
	"undim": ["AH2 N D IH1 M"],
	"undimmed": ["AH2 N D IH1 M D"],
	"undiscovered": ["AH2 N D IH0 S K AH1 V ER0 D"],
	"undisputed": ["AH2 N D IH0 S P Y UW1 T IH0 D"],
	"undistinguished": ["AH2 N D IH0 S T IH1 NG G W IH0 SH T"],
	"undivine": ["AH2 N D IH0 V AY1 N"],
	"undo": ["AH0 N D UW1"],
	"undoeth": ["AH0 N D UW1 IH0 TH"],
	"undone": ["AH0 N D AH1 N"],
	"undrained": ["AH2 N D R EY1 N D"],
	"undress": ["AH0 N D R EH1 S"],
	"undrest": ["AH2 N D R EH1 S T"],
	"unelected": ["AH2 N IH0 L EH1 K T IH0 D"],
	"unequal": ["AH0 N IY1 K W AH0 L"],
	"unerudite": ["AH0 N EH1 R AH0 D AY2 T"],
	"unestranged": ["AH0 N IH0 S T R EY1 N JH D"],
	"uneven": ["AH0 N IY1 V AH0 N"],
	"unexampled": ["AH2 N IH0 G Z AE1 M P AH0 L D"],
	"unexpected": ["AH2 N IH0 K S P EH1 K T IH0 D"],
	"unexpectedly": ["AH2 N IH0 K S P EH1 K T IH0 D L IY3"],
	"unexpress'd": ["AH2 N IH0 K S P R EH1 S T"],
	"unfather'd": ["AH0 N F AA1 DH ER0 D"],
	"unfearingly": ["AH0 N F IY1 R IH0 NG L IY3"],
	"unfit": ["AH0 N F IH1 T"],
	"unfitted": ["AH2 N F IH1 T IH0 D"],
	"unfold": ["AH0 N F OW1 L D"],
	"unfolded": ["AH0 N F OW1 L D IH0 D"],
	"unforgiven": ["AH2 N F AO0 R G IH1 V AH0 N"],
	"unfortunate": ["AH0 N F AO1 R CH AH0 N AH3 T", "AH0 N F AO1 R CH UW0 N AH0 T"],
	"unfrequented": ["AH2 N F R IY1 K W AH0 N T IH3 D"],
	"unfulfilled": ["AH2 N F UH0 L F IH1 L D"],
	"unfurl": ["AH0 N F ER1 L"],
	"unfurnished": ["AH2 N F ER1 N IH0 SH T"],
	"ungained": ["AH2 N G EY1 N D"],
	"ungainly": ["AH0 N G EY1 N L IY0"],
	"ungenerous": ["AH2 N JH EH1 N ER0 AH3 S"],
	"ungracious": ["AH2 N G R EY1 SH AH0 S"],
	"ungrateful": ["AH0 N G R EY1 T F AH0 L"],
	"unhailed": ["AH0 N HH EY1 L D"],
	"unhappy": ["AH0 N HH AE1 P IY0"],
	"unheard": ["AH0 N HH ER1 D"],
	"unhelped": ["AH2 N HH EH1 L P T"],
	"unhonour'd": ["AH2 N AA1 N ER0 D"],
	"unhook": ["AH0 N HH UH1 K"],
	"unhoped": ["AH0 N HH OW1 P T"],
	"uniforms": ["Y UW1 N AH0 F AO2 R M Z"],
	"unintelligible": ["AH2 N IH0 N T EH1 L AH0 JH AH0 B AH2 L"],
	"union": ["Y UW1 N Y AH0 N"],
	"unique": ["Y UW0 N IY1 K"],
	"unit": ["Y UW1 N IH0 T"],
	"unite": ["Y UW0 N AY1 T"],
	"united": ["Y UW0 N AY1 T IH0 D"],
	"unity": ["Y UW1 N AH0 T IY3"],
	"universe": ["Y UW1 N AH0 V ER2 S"],
	"unjaded": ["AH2 N JH EY1 D IH0 D"],
	"unjust": ["AH0 N JH AH1 S T"],
	"unkind": ["AH0 N K AY1 N D"],
	"unknowingly": ["AH2 N N OW1 IH0 NG L IY3"],
	"unknown": ["AH0 N N OW1 N"],
	"unlace": ["AH2 N L EY1 S"],
	"unladen": ["AH2 N L EY1 D AH0 N"],
	"unlearn": ["AH2 N L ER1 N"],
	"unless": ["AH0 N L EH1 S"],
	"unletter'd": ["AH2 N L EH1 T ER0 D"],
	"unlifted": ["AH0 N L IH1 F T IH0 D"],
	"unlike": ["AH0 N L AY1 K"],
	"unlock": ["AH0 N L AA1 K"],
	"unmating": ["AH2 N M EY1 T IH0 NG"],
	"unmentioned": ["AH2 N M EH1 N CH AH0 N D"],
	"unmew": ["AH2 N M Y UW1"],
	"unmindful": ["AH0 N M AY1 N D F UH0 L"],
	"unministered": ["AH0 N M IH1 N AH0 S T ER2 D"],
	"unmoved": ["AH0 N M UW1 V D"],
	"unnerved": ["AH0 N N ER1 V D"],
	"unnoticed": ["AH2 N N OW1 T IH0 S T"],
	"unnumber'd": ["AH2 N N AH1 M B ER0 D"],
	"unnumbered": ["AH2 N N AH1 M B ER0 D"],
	"unobserved": ["AH0 N AH0 B Z AH1 V D"],
	"unobtained": ["AH2 N AH0 B T EY1 N D"],
	"unobtrusive": ["AH2 N AH0 B T R UW1 S IH0 V"],
	"unowned": ["AH0 N OW1 N D"],
	"unpacked": ["AH2 N P AE1 K T"],
	"unpen": ["AH2 N P EH1 N"],
	"unperceived": ["AH2 N P ER0 S IY1 V D"],
	"unplausible": ["AH2 N P L AO1 Z AH0 B AH3 L"],
	"unpleasantly": ["AH0 N P L EH1 Z AH0 N T L IY3"],
	"unprepared": ["AH2 N P R IY0 P EH1 R D"],
	"unpretending": ["AH2 N P R IY0 T EH1 N D IH0 NG"],
	"unprovident": ["AH2 N P R AA1 V IH0 D AH2 N T"],
	"unpuzzled": ["AH2 N P AH1 Z AH0 L D"],
	"unquiet": ["AH2 N K W AY1 IH0 T"],
	"unquietly": ["AH0 N K W AY1 AH0 T L IY3"],
	"unrealized": ["AH2 N R IY1 AH0 L AY2 Z D", "AH2 N R IY1 L AY0 Z D"],
	"unrecorded": ["AH2 N R IH0 K AO1 R D IH0 D"],
	"unrelenting": ["AH2 N R IY0 L EH1 N T IH0 NG"],
	"unrelieved": ["AH2 N R IY0 L IY1 V D"],
	"unreluctantly": ["UN2 R IH0 L AH1 K T AH0 N T L IY3"],
	"unremembered": ["AH2 N R IH0 M EH1 M B ER0 D"],
	"unremitting": ["AH2 N R IH0 M IH1 T IH0 NG"],
	"unrespected": ["AH2 N R IH0 S P EH1 K T IH2 D"],
	"unrest": ["AH0 N R EH1 S T"],
	"unreveal'd": ["AH2 N R IH0 V IY1 L D"],
	"unrevealed": ["AH2 N R IY0 V IY1 L D"],
	"unroll": ["AH2 N R OW1 L"],
	"unrolled": ["AH2 N R OW1 L D"],
	"unsaid": ["AH0 N S EH1 D"],
	"unscalable": ["AH2 N S K EY1 L AH0 B AH2 L"],
	"unscrutinized": ["AH0 N S K R UW1 T AH0 N AY2 Z D"],
	"unseals": ["AH2 N S IY1 L Z"],
	"unseams": ["AH0 N S IY1 M Z"],
	"unseen": ["AH0 N S IY1 N"],
	"unset": ["AH0 N S EH1 T"],
	"unshaded": ["AH2 N SH EY1 D IH0 D"],
	"unsheathed": ["AH0 N SH IY1 DH D"],
	"unshriven": ["AH3 N SH R IH1 V AH0 N"],
	"unskilled": ["AH0 N S K IH1 L D"],
	"unsmooth": ["AH0 N S M UW1 DH"],
	"unsown": ["AH2 N S OW1 N"],
	"unsparingly": ["AH0 N S P EH1 R IH0 NG L IY3"],
	"unspeakable": ["AH2 N S P IY1 K AH0 B AH2 L"],
	"unspoken": ["AH0 N S P OW1 K AH0 N"],
	"unstained": ["AH0 N S T EY1 N D"],
	"unsteady": ["AH2 N S T EH1 D IY0"],
	"unstrung": ["AH0 N S T R IH1 NG"],
	"unsubdued": ["AH0 N S AH0 B D Y UW1 D"],
	"unsubstantial": ["AH2 N S AH0 B S T AE1 N SH AH0 L"],
	"unsuspected": ["AH2 N S AH0 S P EH1 K T IH0 D"],
	"unsuspecting": ["AH2 N S AH0 S P EH1 K T IH0 NG"],
	"unsuspended": ["AH2 N S AH0 S P EH1 N D IH0 D"],
	"unsustained": ["AH2 N S AH0 S T EY1 N D"],
	"unsweet": ["AH0 N S W IY1 T"],
	"unthinking": ["AH2 N TH IH1 NG K IH0 NG"],
	"untie": ["AH0 N T AY1"],
	"untied": ["AH0 N T AY1 D"],
	"unties": ["AH0 N T AY1 Z"],
	"until": ["AH0 N T IH1 L"],
	"untimely": ["AH0 N T AY1 M L IY0"],
	"unto": ["AH1 N T UW0"],
	"untold": ["AH0 N T OW1 L D"],
	"untorn": ["AH0 N T AO1 R N"],
	"untost": ["AH0 N T AA1 S T"],
	"untouched": ["AH0 N T AH1 CH T"],
	"untravelled": ["AH2 N T R AE1 V AH0 L D"],
	"untraversed": ["AH2 N T R AE1 V ER0 S T"],
	"untrimm'd": ["AH0 N T R IH1 M D"],
	"untrue": ["AH0 N T R UW1"],
	"untutor'd": ["AH2 N T UW1 T ER0 D"],
	"untwist": ["AH2 N T W IH1 S T"],
	"unused": ["AH0 N Y UW1 Z D"],
	"unvarying": ["AH2 N V EH1 R IY0 IH3 NG"],
	"unveiled": ["AH0 N V EY1 L D"],
	"unvisited": ["AH2 N V IH1 Z AH0 T IH3 D"],
	"unwearièd": ["AH2 N W IY1 R IY0 EH2 D"],
	"unweariedly": ["AH0 N W IY1 R IY0 D L IY3"],
	"unwilling": ["AH2 N W IH1 L IH0 NG"],
	"unwind": ["AH0 N W AY1 N D"],
	"unwise": ["AH0 N W AY1 Z"],
	"unwon": ["AH0 N W AH1 N"],
	"unworn": ["AH2 N W AO1 R N"],
	"unworried": ["AH0 N W ER1 IY0 D"],
	"unworthy": ["AH0 N W ER1 DH IY0"],
	"unwound": ["AH0 N W AW1 N D"],
	"unwrought": ["AH0 N R AO1 T"],
	"up": ["AH1 P"],
	"upborne": ["AH2 P B AO1 R N"],
	"upbraid": ["AH2 P B R EY1 D"],
	"upcast": ["AH2 P K AE1 S T"],
	"upfurl'd": ["AH2 P F ER1 L D"],
	"upfurled": ["AH2 P F ER1 L D"],
	"upheld": ["AH0 P HH EH1 L D"],
	"uphold": ["AH0 P HH OW1 L D"],
	"upland": ["AH1 P L AH0 N D"],
	"uplighted": ["AH0 P L AY1 T IH0 D"],
	"upon": ["AH0 P AA1 N"],
	"upper": ["AH1 P ER0"],
	"uppermost": ["AH1 P ER0 M OW2 S T"],
	"upraise": ["AH2 P R EY1 Z"],
	"uprear": ["AH0 P R IY1 R"],
	"upright": ["AH0 P R AY1 T"],
	"uprist": ["AH2 P R IH1 S T"],
	"uproar": ["AH1 P R AO2 R"],
	"uprose": ["AH2 P R OW1 Z"],
	"upset": ["AH0 P S EH1 T"],
	"upspring": ["AH2 P S P R IH1 NG"],
	"upstart": ["AH1 P S T AA2 R T"],
	"upswell": ["AH1 P S W EH2 L"],
	"upthrown": ["AH1 P TH R OW0"],
	"upthrust": ["AH2 P TH R AH1 S T"],
	"uptook": ["AH2 P T UH1 K"],
	"upturn": ["AH1 P T ER2 N"],
	"uptying": ["AH2 P T AY1 IH0 NG"],
	"upward": ["AH1 P W ER0 D"],
	"urbane": ["ER0 B EY1 N"],
	"urchin": ["ER1 CH AH0 N"],
	"urge": ["ER1 JH"],
	"urged": ["ER1 JH D"],
	"urgent": ["ER1 JH AH0 N T"],
	"uriel": ["Y UH1 R IY0 AH3 L", "Y UH1 R Y AH0 L"],
	"urn": ["ER1 N"],
	"us": ["AH3 S"],
	"us'd": ["Y UW1 Z D"],
	"use": ["Y UW1 S", "Y UW1 Z"],
	"used": ["Y UW1 Z D"],
	"useful": ["Y UW1 S F AH0 L"],
	"useless": ["Y UW1 S L AH0 S"],
	"uses": ["Y UW1 S IH0 Z", "Y UW1 Z IH0 Z"],
	"usest": ["Y UW1 S IH0 S T"],
	"usher": ["AH1 SH ER0"],
	"using": ["Y UW1 Z IH0 NG"],
	"usual": ["Y UW1 ZH W AH0 L", "Y UW1 ZH UW0 AH3 L"],
	"usurp": ["Y UW2 S ER1 P"],
	"usurping": ["Y UH0 Z ER1 P IH0 NG"],
	"usury": ["Y UW1 ZH ER0 IY3"],
	"utmost": ["AH1 T M OW3 S T"],
	"utter": ["AH1 T ER0"],
	"utterance": ["AH1 T ER0 AH3 N S"],
	"utterest": ["AH1 T ER0 IH3 S T", "AH1 T R IH0 S T"],
	"uttermost": ["AH1 T ER0 M OW2 S T"],
	"vacant": ["V EY1 K AH0 N T"],
	"vacation": ["V EY0 K EY1 SH AH0 N"],
	"vacuum": ["V AE1 K Y UW0 M", "V AE1 K Y UW0 AH3"],
	"vagrant": ["V EY1 G R AH0 N T"],
	"vague": ["V EY1 G"],
	"vail": ["V EY1 L"],
	"vain": ["V EY1 N"],
	"vale": ["V EY1 L"],
	"valladolid": ["V AE0 L AH0 D AH0 L IY1 D"],
	"valley": ["V AE1 L IY0"],
	"valorous": ["V AE1 L ER0 AH2 S"],
	"value": ["V AE1 L Y UW0"],
	"valve": ["V AE1 L V"],
	"vamp": ["V AE1 M P"],
	"van": ["V AE1 N"],
	"vandal": ["V AE1 N D AH0 L"],
	"vane": ["V EY1 N"],
	"vanish": ["V AE1 N IH0 SH"],
	"vanity": ["V AE1 N AH0 T IY3"],
	"vanquish": ["V AE1 NG K W IH0 SH"],
	"vapour": ["V EY1 P ER0"],
	"varied": ["V EH1 R IY0 D"],
	"varies": ["V EH1 R IY0 Z"],
	"variety": ["V ER0 AY1 AH0 T IY3"],
	"various": ["V EH1 R IY0 AH3 S", "V EH1 R Y AH0 S"],
	"vascillating": ["V AE1 S AH0 L EY2 T IH0 NG"],
	"vase": ["V EY1 S", "V AA1 Z"],
	"vassal": ["V AE1 S AH0 L"],
	"vassalage": ["V AE1 S AH0 L IH2 JH"],
	"vast": ["V AE1 S T"],
	"vat": ["V AE1 T"],
	"vault": ["V AO1 L T"],
	"vaunt": ["V AA1 N T"],
	"veer": ["V IY1 R"],
	"vegetation": ["V EH2 JH AH0 T EY1 SH AH0 N"],
	"vehement": ["V IY1 AH0 M AH0 N T", "V AH0 HH IY1 M AH0 N T"],
	"vehicle": ["V IY1 HH IH0 K AH3 L"],
	"veil": ["V EY1 L"],
	"vein": ["V EY1 N"],
	"vellum": ["V EH1 L AH0 M"],
	"velvet": ["V EH1 L V AH0 T"],
	"venerable": ["V EH1 N ER0 AH0 B AH2 L"],
	"venetian": ["V AH0 N IY1 SH AH0 N"],
	"vengeance": ["V EH1 N JH AH0 N S"],
	"vent": ["V EH1 N T"],
	"venture": ["V EH1 N CH ER0"],
	"venus": ["V IY1 N AH0 S"],
	"vera": ["V EH1 R AH0"],
	"verdant": ["V ER1 D AH0 N T"],
	"verdict": ["V ER1 D IH0 K T"],
	"verdure": ["V ER1 Y ER0"],
	"verdurous": ["V ER1 JH R AH0 S", "V ER1 JH ER0 AH3 S"],
	"vere": ["V IY1 R"],
	"verge": ["V ER1 JH"],
	"verily": ["V EH1 R IH0 L IY3"],
	"vermicelli": ["V ER2 M IH0 CH EH1 L IY0"],
	"vermilion": ["V ER0 M IH1 L Y AH0 N"],
	"vermillion": ["V ER0 M IH1 L Y AH0 N"],
	"vernal": ["V ER1 N AH0 L"],
	"verona": ["V ER0 OW1 N AH0"],
	"veronese": ["V EH2 R AH0 N IY1 Z"],
	"verriere": ["V EH1 R IY0 EH2 R"],
	"verse": ["V ER1 S"],
	"versed": ["V ER1 S T"],
	"vertical": ["V ER1 T AH0 K AH3 L"],
	"very": ["V EH1 R IY0"],
	"vest": ["V EH1 S T"],
	"vesta": ["V EH1 S T AH0"],
	"vestal": ["V EH1 S T AH0 L"],
	"vestige": ["V EH1 S T IH0 JH"],
	"vestment": ["V EH1 S T M EH0 N T"],
	"vests": ["V EH1 S T S"],
	"vesture": ["V EH1 S CH ER0"],
	"vesuvian": ["V AH0 S UW1 V IY0 AH0 N", "V AH0 S UW1 V Y AH0 N"],
	"veto": ["V IY1 T OW2"],
	"vevay": ["V AH0 V EY1"],
	"vex": ["V EH1 K S"],
	"vexed": ["V EH1 K S T"],
	"vext": ["V EH1 K S T"],
	"vibrant": ["V AY1 B R AH0 N T"],
	"vibrating": ["V AY1 B R EY0 T IH0 NG"],
	"vicarious": ["V AY0 K EH1 R IY0 AH0 S", "V AY0 K EH1 R Y AH0 S"],
	"vicariously": ["V AY0 K EH1 R IY0 AH0 S L IY3", "V AY0 K EH1 R Y AH0 S L IY3"],
	"vice": ["V AY1 S"],
	"vicinity": ["V IH0 S IH1 N IH0 T IY3"],
	"vicious": ["V IH1 SH AH0 S"],
	"vicissitude": ["V IH0 S IH1 S IH0 T UW2 D"],
	"victim": ["V IH1 K T AH0 M"],
	"victor": ["V IH1 K T ER0"],
	"victorious": ["V IH0 K T AO1 R IY0 AH3 S", "V IH0 K T AO1 R Y AH0 S"],
	"victory": ["V IH1 K T ER0 IY2", "V IH1 K T R IY0"],
	"victual": ["V IH1 T AH0 L"],
	"vie": ["V AY1"],
	"view": ["V Y UW1"],
	"vigil": ["V IH1 JH AH0 L"],
	"vigorous": ["V IH1 G ER0 AH3 S"],
	"vigour": ["V IH1 G ER0"],
	"vile": ["V AY1 L"],
	"village": ["V IH1 L AH0 JH"],
	"villages": ["V IH1 L AH0 JH IH3 Z"],
	"villain": ["V IH1 L AH0 N"],
	"vindicate": ["V IH1 N D AH0 K EY2 T"],
	"vindicating": ["V IH1 N D AH0 K EY2 T IH0 NG"],
	"vine": ["V AY1 N"],
	"vineyard": ["V IH1 N Y ER0 D"],
	"vintage": ["V IH1N T AH0 JH"],
	"vintager": ["V IH1 N T AH0 JH ER2"],
	"viol": ["V AY1 AO0 L"],
	"violate": ["V AY1 AH0 L EY0 T"],
	"violence": ["V AY1 L AH0 N S", "V AY1 AH0 L AH3 N S"],
	"violent": ["V AY1 L AH0 N T", "V AY1 AH0 L AH3 N T"],
	"violet": ["V AY1 AH0 L IH0 T", "V AY1 L IH0 T"],
	"violets": ["V AY1 AH0 L AH3 T S", "V AY1 L AH0 T S"],
	"violin": ["V AY2 AH0 L IH1 N"],
	"virgin": ["V ER1 JH IH0 N"],
	"virtue": ["V ER1 CH UW0"],
	"virtuous": ["V ER1 CH UW0 AH0 S"],
	"virulence": ["V IY1 R AH0 L AH0 N S"],
	"visage": ["V IH1 Z IH0 JH"],
	"visible": ["V IH1 Z AH0 B AH3 L"],
	"vision": ["V IH1 ZH AH0 N"],
	"visit": ["V IH1 Z IH0 T"],
	"visitor": ["V IH1 Z IH0 T ER0"],
	"vita": ["V IY1 T AH0"],
	"vital": ["V AY1 T AH0 L"],
	"vitality": ["V AY0 T AE1 L AH0 T IY3"],
	"vitalize": ["V AY1 T AH0 L AY2 Z"],
	"vitallest": ["V AY1 T AH0 L IH3 S T"],
	"vivid": ["V IH1 V IH0 D"],
	"vocation": ["V OW0 K EY1 SH AH0 N"],
	"voice": ["V OY1 S"],
	"voicelessness": ["V OY1 S L AH0 S N EH2 S"],
	"void": ["V OY1 D"],
	"volcanic": ["V AA0 L K AE1 N IH0 K"],
	"volcano": ["V AA0 L K EY1 N OW0"],
	"volcanoes": ["V AA0 L K EY1 N OW0 Z"],
	"volley": ["V AA1 L IY0"],
	"volume": ["V AA1 L Y UW0 M"],
	"votaries": ["V OW1 T ER0 IY2 Z"],
	"vote": ["V OW1 T"],
	"voted": ["V OW1 T IH0 D"],
	"vouch": ["V AW1 CH"],
	"vous": ["V UW1"],
	"vow": ["V AW1"],
	"vulture": ["V AH1 L CH ER0"],
	"w'd": ["W UH3 D"],
	"waddle": ["W AA1 D AH0 L"],
	"wade": ["W EY1 D"],
	"wading": ["W EY1 D IH0 NG"],
	"waft": ["W AA1 F T"],
	"wag": ["W AE1 G"],
	"wage": ["W EY1 JH"],
	"waged": ["W EY1 JH D"],
	"wagon": ["W AE1 G AH0 N"],
	"wail": ["W EY1 L"],
	"wain": ["W EY1 N"],
	"wainscot": ["W EY1 N S K OW2 T", "W EY1 N S K AH0 T"],
	"waist": ["W EY1 S T"],
	"wait": ["W EY1 T"],
	"wake": ["W EY1 K"],
	"waken": ["W EY1 K AH0 N"],
	"waking": ["W EY1 K IH0 NG"],
	"walk": ["W AO1 K"],
	"wall": ["W AO1 L"],
	"waltzed": ["W AA1 L T S T"],
	"wampum": ["W AA1 M P AH0 M"],
	"wan": ["W AA1 N"],
	"wand": ["W AA1 N D"],
	"wand'ring": ["W AA1 N D R IH0 NG"],
	"wander": ["W AA1 N D ER0"],
	"wandering": ["W AA1 N D R IH0 NG", "W AA1 N D ER0 IH3 NG"],
	"wanderings": ["W AA1 N D R IH0 NG Z", "W AA1 N D ER0 IH3 NG Z"],
	"wane": ["W EY1 N"],
	"waning": ["W EY1 N IH0 NG"],
	"want": ["W AO1 N T"],
	"wanton": ["W AO1 N T AH0 N"],
	"war": ["W AO1 R"],
	"ward": ["W AO1 R D"],
	"warden": ["W AO1 R D AH0 N"],
	"wardrobe": ["W AO1 R D R OW0 B"],
	"ware": ["W EH1 R"],
	"warm": ["W AO1 R M"],
	"warmth": ["W AO1 R M TH"],
	"warn": ["W AO1 R N"],
	"warp": ["W AO1 R P"],
	"warrant": ["W AO1 R AH0 N T"],
	"warrest": ["W AO1 R IH0 S T"],
	"warrior": ["W AO1 R Y ER0"],
	"wary": ["W EH1 R IY0"],
	"was": ["W AH3 Z"],
	"was't": ["W AH1 S T"],
	"wash": ["W AA1 SH"],
	"wasn't": ["W AA1 Z AH0 N T"],
	"wast": ["W AH3 S T"],
	"waste": ["W EY1 S T"],
	"wasting": ["W EY1 S T IH0 NG"],
	"wat'ry": ["W AO1 T R IY0"],
	"watch": ["W AA1 CH"],
	"water": ["W AO1 T ER0"],
	"waterfall": ["W AO1 T ER0 F AO2 L"],
	"waterloo": ["W AO1 T ER0 L UW2"],
	"wave": ["W EY1 V"],
	"waved": ["W EY1 V D"],
	"wavering": ["W EY1 V ER0 IH3 NG", "W EY1 V R IH0 NG"],
	"waving": ["W EY1 V IH0 NG"],
	"wax": ["W AE1 K S"],
	"waxen": ["W AE1 K S AH0 N"],
	"way": ["W EY1"],
	"wayfaring": ["W EY1 F EH2 R IH0 NG"],
	"wayside": ["W EY1 S AY2 D"],
	"wayward": ["W EY1 W ER0 D"],
	"wayworn": ["W EY1 W AO2 R N"],
	"we": ["W IY3"],
	"we'll": ["W IY1 L"],
	"we're": ["W IY1 R"],
	"we've": ["W IY1 V"],
	"weak": ["W IY1 K"],
	"weal": ["W IY1 L"],
	"wealth": ["W EH1 L TH"],
	"wealthier": ["W EH1 L TH IY0 ER3", "W EH1 L TH Y ER0"],
	"wean": ["W IY1 N"],
	"wear": ["W EH1 R"],
	"wearer's": ["W EH1 R ER0 Z"],
	"weariest": ["W IY1 R IY0 IH3 S T"],
	"weariness": ["W IY1 R IY0 N AH3 S"],
	"wearisome": ["W IY1 R IY0 S AH0 M"],
	"weary": ["W IY1 R IY0"],
	"weather": ["W EH1 DH ER0"],
	"weathercock": ["W EH1 DH ER0 K AA2 K"],
	"weave": ["W IY1 V"],
	"weaver": ["W IY1 V ER0"],
	"weaveth": ["W IY1 V IH0 TH"],
	"web": ["W EH1 B"],
	"wed": ["W EH1 D"],
	"wedge": ["W EH1 JH"],
	"wedged": ["W EH1 JH D"],
	"wedlock": ["W EH1 D L AA2 K"],
	"wednesday": ["W EH1 N Z D EY2"],
	"weed": ["W IY1 D"],
	"week": ["W IY1 K"],
	"ween": ["W IY1 N"],
	"weep": ["W IY1 P"],
	"weigh": ["W EY1"],
	"weight": ["W EY1 T"],
	"welcome": ["W EH1 L K AH0 M"],
	"welcoming": ["W EH1 L K AH0 M IH2 NG"],
	"weld": ["W EH1 L D"],
	"well": ["W EH1 L"],
	"wench": ["W EH1 N CH"],
	"went": ["W EH1 N T"],
	"wept": ["W EH1 P T"],
	"were": ["W ER1"],
	"wert": ["W ER1 T"],
	"west": ["W EH1 S T"],
	"westerly": ["W EH1 S T ER0 L IY3"],
	"western": ["W EH1 S T ER0 N"],
	"westminster": ["W EH2 S T M IH1 N S T ER0"],
	"wet": ["W EH1 T"],
	"wetting": ["W EH1 T IH0 NG"],
	"whale": ["W EY1 L"],
	"wharf": ["W AO1 R F"],
	"what": ["W AH1 T"],
	"whate'er": ["W AH0 T EH1 R"],
	"whatever": ["W AH2 T EH1 V ER0"],
	"whatsoe'er": ["W AA0 T S OW0 EH1 R"],
	"whatsoever": ["W AH2 T S OW0 EH1 V ER0"],
	"wheat": ["W IY1 T"],
	"wheel": ["W IY1 L"],
	"whelp": ["W EH1 L P"],
	"when": ["W EH1 N"],
	"whence": ["W EH1 N S"],
	"whene'er": ["W EH0 N EH1 R"],
	"wheneer": ["W EH0 N EH1 R"],
	"where": ["W EH1 R"],
	"whereat": ["W EH2 R AE1 T"],
	"whereby": ["W EH0 R B AY1"],
	"where'er": ["W EH0 EH1 R"],
	"wherefor": ["W EH1 R F AO0 R"],
	"wherefore": ["W EH1 R F AO0 R"],
	"wherefrom": ["W EH0 R F R AH1 M"],
	"wherein": ["W EH0 R IH1 N"],
	"whereof": ["W EH0 R AH1 V"],
	"wheresoe'er": ["W EH2 R S OW0 EH1 R"],
	"whereupon": ["W EH1 R AH0 P AA1 N"],
	"wherever": ["W EH0 R EH1 V ER0"],
	"wherewith": ["W EH2 R W IH1 TH"],
	"whet": ["W EH1 T"],
	"whether": ["W EH1 DH ER0"],
	"whetting": ["W EH1 T IH0 NG"],
	"whey": ["W EY1"],
	"which": ["W IH1 CH"],
	"whiff": ["W IH1 F"],
	"while": ["W AY1 L"],
	"whim": ["W IH1 M"],
	"whimper": ["W IH1 M P ER0"],
	"whip": ["W IH1 P"],
	"whipp'd": ["W IH1 P T"],
	"whippoorwill": ["W IH1 P ER0 W IH2 L"],
	"whippowil": ["W IH1 P AH0 W IH2 L"],
	"whirl": ["W ER1 L"],
	"whirr": ["W ER1"],
	"whisper": ["W IH1 S P ER0"],
	"whisperingly": ["W IH1 S P R IH0 NG L IY3", "W IH1 S P ER0 IH0 NG L IY3"],
	"whisperings": ["W IH1 S P ER0 IH3 NG Z", "W IH1 S P R IH0 NG Z"],
	"whistle": ["W IH1 S AH0 L"],
	"whit": ["W IH1 T"],
	"white": ["W AY1 T"],
	"whither": ["W IH1 DH ER0"],
	"whittle": ["W IH1 T AH0 L"],
	"whizzing": ["W IH1 Z IH0 NG"],
	"who": ["HH UW1"],
	"who'll": ["HH UW1 L"],
	"whoever": ["HH UW3 EH1 V ER0"],
	"whole": ["HH OW1 L"],
	"wholesome": ["HH OW1 L S AH0 M"],
	"wholly": ["HH OW1 L IY0"],
	"whom": ["HH UW1 M"],
	"whoo": ["W UW1"],
	"whose": ["HH UW1 Z"],
	"whoso": ["W UW1 S OW2"],
	"whosoever": ["HH UW2 S OW0 EH1 V ER0"],
	"why": ["W AY1"],
	"wick": ["W IH1 K"],
	"wicked": ["W IH1 K AH0 D"],
	"wide": ["W AY1 D"],
	"widen": ["W AY1 D AH0 N"],
	"widest": ["W AY1 D AH0 S T"],
	"widow": ["W IH1 D OW0"],
	"width": ["W IH1 D TH"],
	"wield": ["W IY1 L D"],
	"wife": ["W AY1 F"],
	"wight": ["W AY1 T"],
	"wild": ["W AY1 L D"],
	"wilder": ["W AY1 L D ER0"],
	"wilderness": ["W IH1 L D ER0 N AH3 S"],
	"wile": ["W AY1 L"],
	"wilful": ["W IH1 L F UH0 L"],
	"wiling": ["W AY1 L IH0 NG"],
	"will": ["W IH1 L"],
	"willfully": ["W IH1 L F AH0 L IY3"],
	"william": ["W IH1 L Y AH0 M"],
	"wilt": ["W IH1 L T"],
	"win": ["W IH1 N"],
	"wince": ["W IH1 N S"],
	"wind": ["W AY1 N D", "W IH1 N D"],
	"winding": ["W AY1 N D IH0 NG"],
	"window": ["W IH1 N D OW0"],
	"winds": ["W IH1 N D Z", "W AY1 ND Z"],
	"windy": ["W IH1 N D IY0", "W AY1 N D IY0"],
	"wine": ["W AY1 N"],
	"wing": ["W IH1 NG"],
	"winged": ["W IH1 NG D", "W IH1 NG IH0 D"],
	"wingèd": ["W IH1 NG IH0 D"],
	"wink": ["W IH1 NG K"],
	"winner": ["W IH1 N ER0"],
	"winter": ["W IH1 N T ER0"],
	"wipe": ["W AY1 P"],
	"wiping": ["W AY1 P IH0 NG"],
	"wire": ["W AY1 ER0", "W AY1 R"],
	"wiry": ["W IY1 R IY0"],
	"wis": ["W IH1 S"],
	"wisdom": ["W IH1 Z D AH0 M"],
	"wise": ["W AY1 Z"],
	"wiser": ["W AY1 Z ER0"],
	"wisest": ["W AY1 Z AH0 S T"],
	"wish": ["W IH1 SH"],
	"wishfulness": ["W IH1 SH F UH0 L N AH3 S"],
	"wist": ["W IH1 S T"],
	"wit": ["W IH1 T"],
	"witch": ["W IH1 CH"],
	"witchcraft": ["W IH1 CH K R AE2 F T"],
	"witches'": ["W IH1 CH IH0 Z"],
	"with": ["W IH3 TH"],
	"withal": ["W IH0 TH AO1 L"],
	"withdraw": ["W IH0 TH D R AO1"],
	"withdrawn": ["W IH0 TH D R AO1 N"],
	"withdrew": ["W IH0 TH D R UW1"],
	"witheld": ["W IH2 TH HH EH1 L D"],
	"wither": ["W IH1 DH ER0"],
	"withered": ["W IH1 DH ER0 D"],
	"withes": ["W IH1 DH Z"],
	"withheld": ["W IH0 TH HH EH1 L D"],
	"withhold": ["W IH0 TH HH OW1 L D"],
	"within": ["W IH0 TH IH1 N"],
	"withold": ["W IH2 TH HH OW1 L D"],
	"witholden": ["W IH0 TH HH OW1 L D AH0 N"],
	"without": ["W IH0 TH AW1 T"],
	"withstand": ["W IH0 TH S T AE1 N D"],
	"withstood": ["W IH0 TH S T UH1 D"],
	"witness": ["W IH1 T N AH0 S"],
	"wived": ["W AY1 V D"],
	"wives": ["W AY1 V Z"],
	"wizard": ["W IH1 Z ER0 D"],
	"wo": ["W OW1"],
	"woe": ["W OW1"],
	"woes": ["W OW1 Z"],
	"woke": ["W OW1 K"],
	"wold": ["W OW1 L D"],
	"wolf": ["W UH1 L F"],
	"woman": ["W UH1 M AH0 N"],
	"womanhood": ["W UH1 M AH0 N HH UH2 D"],
	"womankind": ["W UH1 M AH0 N K AY2 N D"],
	"womb": ["W UW1 M"],
	"women": ["W IH1 M AH0 N"],
	"won": ["W AH1 N"],
	"won't": ["W OW1 N T"],
	"wond'ring": ["W AH1 N D R IH0 NG"],
	"wonder": ["W AH1 N D ER0"],
	"wonderful": ["W AH1 N D ER0 F AH2 L"],
	"wondering": ["W AH1 N D R IH0 NG", "W AH1 N D ER0 IH3 NG"],
	"wonderment": ["W AH1 N D ER0 M AH2 N T"],
	"wondrous": ["W AH1 N D R AH0 S"],
	"wont": ["W OW1 N T"],
	"woo": ["W UW1"],
	"wood": ["W UH1 D"],
	"woodbine": ["W UH1 D B AY2 N"],
	"wooden": ["W UH1 D AH0 N"],
	"woodlander": ["W UH1 D L AE2 N D ER0"],
	"wooed": ["W UW1 D"],
	"woof": ["W UW1 F"],
	"wool": ["W UH1 L"],
	"word": ["W ER1 D"],
	"wore": ["W AO1 R"],
	"work": ["W ER1 K"],
	"worked": ["W ER1 K T"],
	"workman": ["W ER1 K M AH0 N"],
	"workmanship": ["W ER1 K M AH0 N SH IH2 P"],
	"workmen": ["W ER1 K M AH0 N"],
	"world": ["W ER1 L D"],
	"worldling": ["W ER1 L D L IH0 NG"],
	"worm": ["W ER1 M"],
	"worn": ["W AO1 R N"],
	"worried": ["W ER1 IY0 D"],
	"worse": ["W ER1 S"],
	"worship": ["W ER1 SH IH0 P"],
	"worshipped": ["W ER1 SH IH0 P T"],
	"worshipping": ["W ER1 SH AH0 P IH3 NG"],
	"worst": ["W ER1 S T"],
	"worth": ["W ER1 TH"],
	"worthier": ["W ER1 DH IY0 ER0"],
	"worthies": ["W ER1 DH IY0 Z"],
	"worthiest": ["W ER1 DH IY0 AH3 S T", "W ER1 DH Y AH0 S T"],
	"worthily": ["W ER1 TH IH0 L IY3"],
	"worthiness": ["W ER1 DH IY0 N IH0 S"],
	"worthy": ["W ER1 DH IY0"],
	"would": ["W UH1 D"],
	"wouldn't": ["W UH1 D AH0 N T"],
	"wouldst": ["W UH1 D S T"],
	"wound": ["W AW1 N D", "W UW1 N D"],
	"wounds": ["W UW1 N D Z"],
	"wove": ["W OW1 V"],
	"woven": ["W OW1 V AH0 N"],
	"wrack": ["R AE1 K"],
	"wrangler": ["R AE1 NG G L ER0"],
	"wrap": ["R AE1 P"],
	"wrapped": ["R AE1 P T"],
	"wrath": ["R AE1 TH"],
	"wreak": ["R IY1 K"],
	"wreath": ["R IY1 TH"],
	"wreathe": ["R IY1 DH"],
	"wreathed": ["R IY1 DH D", "R IY1 TH IH0 D"],
	"wreathing": ["R IY1 DH IH0 NG"],
	"wreck": ["R EH1 K"],
	"wren": ["R EH1 N"],
	"wrench": ["R EH1 N CH"],
	"wrest": ["R EH1 S T"],
	"wrestle": ["R EH1 S AH0 L"],
	"wrestled": ["R EH1 S AH0 L D"],
	"wrestlers": ["R EH1 S L ER0 Z"],
	"wretch": ["R EH1 CH"],
	"wretched": ["R EH1 CH IH0 D"],
	"wring": ["R IH1 neglect"],
	"wrinkle": ["R IH1 NG K AH0 L"],
	"wrinkled": ["R IH1 NG K AH0 L D"],
	"wrist": ["R IH1 S T"],
	"writ": ["R IH1 T"],
	"write": ["R AY1 T"],
	"writhe": ["R AY1 DH"],
	"writhen": ["R IH1 DH AH0 N"],
	"written": ["R IH1 T AH0 N"],
	"wrong": ["R AO1 NG"],
	"wrote": ["R OW1 T"],
	"wroth": ["R AO1 TH"],
	"wrought": ["R AO1 T"],
	"wrung": ["R AH1 NG"],
	"wye": ["W AY1"],
	"wyndermere": ["W IH1 N D ER0 M IH2 R"],
	"yap": ["Y AE1 P"],
	"yard": ["Y AA1 R D"],
	"yawn": ["Y AO1 N"],
	"yclept": ["IH0 K L EH1 P T"],
	"ye": ["Y IY1"],
	"year": ["Y IY1 R"],
	"yearn": ["Y ER1 N"],
	"yearnings": ["Y ER1 N IH0 NG Z"],
	"yell": ["Y EH1 L"],
	"yellow": ["Y EH1 L OW0"],
	"yellower": ["Y EH1 L OW0 ER0", "Y EH1 L W ER0"],
	"yellowing": ["Y EH1 L W IH0 NG"],
	"yelp": ["Y EH1 L P"],
	"yes": ["Y EH1 S"],
	"yesterday": ["Y EH1 S T ER0 D EY2"],
	"yestermorn": ["Y EH1 S T ER0 M AO2 R N"],
	"yesternight": ["Y EH1 S T ER0 N AY2 T"],
	"yet": ["Y EH3 T"],
	"yew": ["Y UW1"],
	"yield": ["Y IY1 L D"],
	"yo": ["Y OW1"],
	"yoke": ["Y OW1 K"],
	"yoked": ["Y OW1 K T"],
	"yonder": ["Y AA1 N D ER0"],
	"yore": ["Y AO1 R"],
	"yorkshire": ["Y AO1 R K SH ER0"],
	"you": ["Y UW1"],
	"you'": ["Y UW1"],
	"you'd": ["Y UW1 D"],
	"you'll": ["Y UW1 L"],
	"you're": ["Y UW1 R"],
	"you've": ["Y UW1 V"],
	"young": ["Y AH1 NG"],
	"your": ["Y AO1 R"],
	"your's": ["Y AO1 R Z"],
	"yours": ["Y UH1 R Z", "Y AO1 R Z", "Y ER1 Z"],
	"yourself": ["Y ER0 S EH1 L F"],
	"yourselves": ["Y UH0 R S EH1 L V Z"],
	"youth": ["Y UW1 TH"],
	"yule": ["Y UW1 L"],
	"zante": ["Z AE1 N T IY0"],
	"zeal": ["Z IY1 L"],
	"zealous": ["Z EH1 L AH0 S"],
	"zenith": ["Z IY1 N IH0 TH"],
	"zenobia": ["Z AH0 N OW1 B IY0 AH2"],
	"zephyr": ["Z EH1 F ER0"],
	"zero": ["Z IY1 R OW0"],
	"zigzag": ["Z IH1 G Z AE0 G"],
	"zinzebar": ["Z IH1 N Z EH0 B AA2 R"],
	"zodiac": ["Z OW1 D IY0 AE2 K"],
	"zone": ["Z OW1 N"],
};

export default lexicon;