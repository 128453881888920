const cmupd = {
  "'bout": ['B AW1 T'],
  "'cause": ['K AH0 Z'],
  "'course": ['K AO1 R S'],
  "'cuse": ['K Y UW1 Z'],
  "'em": ['AH0 M'],
  "'frisco": ['F R IH1 S K OW0'],
  "'gain": ['G EH1 N'],
  "'kay": ['K EY1'],
  "'m": ['AH0 M'],
  "'n": ['AH0 N'],
  "'round": ['R AW1 N D'],
  "'s": ['EH1 S'],
  "'til": ['T IH1 L'],
  "'tis": ['T IH1 Z'],
  "'twas": ['T W AH1 Z'],
  "a": ['AH0', 'EY1'],
  "a's": ['EY1 Z'],
  "a.": ['EY1'],
  "a.'s": ['EY1 Z'],
  "a.d.": ['EY2 D IY1'],
  "a.m.": ['EY2 EH1 M'],
  "a.s": ['EY1 Z'],
  "aaa": ['T R IH2 P AH0 L EY1'],
  "aaberg": ['AA1 B ER0 G'],
  "aachen": ['AA1 K AH0 N'],
  "aachener": ['AA1 K AH0 N ER0'],
  "aaker": ['AA1 K ER0'],
  "aalborg": ['AO1 L B AO0 R G', 'AA1 L B AO0 R G'],
  "aalburg": ['AE1 L B ER0 G'],
  "aalen": ['AE1 L AH0 N', 'AA1 L AH0 N'],
  "aaliyah": ['AA2 L IY1 AA2'],
  "aalseth": ['AA1 L S EH0 TH'],
  "aalsmeer": ['AA1 L S M IH0 R'],
  "aalto": ['AA1 L T OW2'],
  "aamodt": ['AA1 M AH0 T'],
  "aancor": ['AA1 N K AO2 R'],
  "aardema": ['AA0 R D EH1 M AH0'],
  "aardvark": ['AA1 R D V AA2 R K'],
  "aardvarks": ['AA1 R D V AA2 R K S'],
  "aargh": ['AA1 R G'],
  "aarhus": ['AA2 HH UW1 S'],
  "aaron": ['EH1 R AH0 N'],
  "aaron's": ['EH1 R AH0 N Z'],
  "aarons": ['EH1 R AH0 N Z'],
  "aaronson": ['EH1 R AH0 N S AH0 N', 'AA1 R AH0 N S AH0 N'],
  "aaronson's": ['EH1 R AH0 N S AH0 N Z', 'AA1 R AH0 N S AH0 N Z'],
  "aarti": ['AA1 R T IY2'],
  "aase": ['AA1 S'],
  "aasen": ['AA1 S AH0 N'],
  "ab": ['AE1 B', 'EY1 B IY1'],
  "aba": ['EY2 B IY2 EY1'],
  "ababa": ['AH0 B AA1 B AH0', 'AA1 B AH0 B AH0'],
  "abacha": ['AE1 B AH0 K AH0'],
  "aback": ['AH0 B AE1 K'],
  "abaco": ['AE1 B AH0 K OW2'],
  "abacus": ['AE1 B AH0 K AH0 S'],
  "abad": ['AH0 B AA1 D'],
  "abadaka": ['AH0 B AE1 D AH0 K AH0'],
  "abadi": ['AH0 B AE1 D IY0'],
  "abadie": ['AH0 B AE1 D IY0'],
  "abair": ['AH0 B EH1 R'],
  "abalkin": ['AH0 B AA1 L K IH0 N'],
  "abalone": ['AE2 B AH0 L OW1 N IY0'],
  "abalones": ['AE2 B AH0 L OW1 N IY0 Z'],
  "abalos": ['AA0 B AA1 L OW0 Z'],
  "abandon": ['AH0 B AE1 N D AH0 N'],
  "abandoned": ['AH0 B AE1 N D AH0 N D'],
  "abandoning": ['AH0 B AE1 N D AH0 N IH0 NG'],
  "abandonment": ['AH0 B AE1 N D AH0 N M AH0 N T'],
  "abandonments": ['AH0 B AE1 N D AH0 N M AH0 N T S'],
  "abandons": ['AH0 B AE1 N D AH0 N Z'],
  "abanto": ['AH0 B AE1 N T OW0'],
  "abarca": ['AH0 B AA1 R K AH0'],
  "abare": ['AA0 B AA1 R IY0'],
  "abascal": ['AE1 B AH0 S K AH0 L'],
  "abash": ['AH0 B AE1 SH'],
  "abashed": ['AH0 B AE1 SH T'],
  "abasia": ['AH0 B EY1 ZH Y AH0'],
  "abate": ['AH0 B EY1 T'],
  "abated": ['AH0 B EY1 T IH0 D'],
  "abatement": ['AH0 B EY1 T M AH0 N T'],
  "abatements": ['AH0 B EY1 T M AH0 N T S'],
  "abates": ['AH0 B EY1 T S'],
  "abating": ['AH0 B EY1 T IH0 NG'],
  "abattoir": ['AE2 B AH0 T W AA1 R'],
  "abba": ['AE1 B AH0'],
  "abbado": ['AH0 B AA1 D OW0'],
  "abbas": ['AH0 B AA1 S'],
  "abbasi": ['AA0 B AA1 S IY0'],
  "abbate": ['AA1 B EY0 T'],
  "abbatiello": ['AA0 B AA0 T IY0 EH1 L OW0'],
  "abbe": ['AE1 B IY0', 'AE0 B EY1'],
  "abbenhaus": ['AE1 B AH0 N HH AW2 S'],
  "abbett": ['AH0 B EH1 T'],
  "abbeville": ['AE1 B V IH0 L'],
  "abbey": ['AE1 B IY0'],
  "abbey's": ['AE1 B IY0 Z'],
  "abbie": ['AE1 B IY0'],
  "abbitt": ['AE1 B IH0 T'],
  "abbot": ['AE1 B AH0 T'],
  "abbotstown": ['AE1 B AH0 T S T AW1 N'],
  "abbott": ['AE1 B AH0 T'],
  "abbott's": ['AE1 B AH0 T S'],
  "abbottstown": ['AE1 B AH0 T S T AW1 N'],
  "abboud": ['AH0 B UW1 D', 'AH0 B AW1 D'],
  "abbreviate": ['AH0 B R IY1 V IY0 EY2 T'],
  "abbreviated": ['AH0 B R IY1 V IY0 EY2 T IH0 D'],
  "abbreviates": ['AH0 B R IY1 V IY0 EY2 T S'],
  "abbreviating": ['AH0 B R IY1 V IY0 EY2 T IH0 NG'],
  "abbreviation": ['AH0 B R IY2 V IY0 EY1 SH AH0 N'],
  "abbreviations": ['AH0 B R IY2 V IY0 EY1 SH AH0 N Z'],
  "abbruzzese": ['AA0 B R UW0 T S EY1 Z IY0'],
  "abbs": ['AE1 B Z'],
  "abby": ['AE1 B IY0'],
  "abc": ['EY1 B IY2 S IY2'],
  "abc's": ['EY1 B IY2 S IY2 Z'],
  "abco": ['AE1 B K OW0'],
  "abcotek": ['AE1 B K OW0 T EH2 K'],
  "abcs": ['EY1 B IY2 S IY2 Z'],
  "abd": ['EY2 B IY2 D IY1'],
  "abdalla": ['AE2 B D AE1 L AH0'],
  "abdallah": ['AE2 B D AE1 L AH0'],
  "abdel": ['AE1 B D EH2 L'],
  "abdella": ['AE2 B D EH1 L AH0'],
  "abdicate": ['AE1 B D AH0 K EY2 T'],
  "abdicated": ['AE1 B D AH0 K EY2 T IH0 D'],
  "abdicates": ['AE1 B D AH0 K EY2 T S'],
  "abdicating": ['AE1 B D IH0 K EY2 T IH0 NG'],
  "abdication": ['AE2 B D IH0 K EY1 SH AH0 N'],
  "abdnor": ['AE1 B D N ER0'],
  "abdo": ['AE1 B D OW0'],
  "abdollah": ['AE2 B D AA1 L AH0'],
  "abdomen": ['AE0 B D OW1 M AH0 N', 'AE1 B D AH0 M AH0 N'],
  "abdominal": ['AE0 B D AA1 M AH0 N AH0 L', 'AH0 B D AA1 M AH0 N AH0 L'],
  "abduct": ['AE0 B D AH1 K T'],
  "abducted": ['AE0 B D AH1 K T IH0 D', 'AH0 B D AH1 K T IH0 D'],
  "abductee": ['AE0 B D AH2 K T IY1'],
  "abductees": ['AE0 B D AH2 K T IY1 Z'],
  "abducting": ['AE0 B D AH1 K T IH0 NG', 'AH0 B D AH1 K T IH0 NG'],
  "abduction": ['AE0 B D AH1 K SH AH0 N', 'AH0 B D AH1 K SH AH0 N'],
  "abductions": ['AE0 B D AH1 K SH AH0 N Z', 'AH0 B D AH1 K SH AH0 N Z'],
  "abductor": ['AE0 B D AH1 K T ER0', 'AH0 B D AH1 K T ER0'],
  "abductors": ['AE0 B D AH1 K T ER0 Z', 'AH0 B D AH1 K T ER0 Z'],
  "abducts": ['AE0 B D AH1 K T S'],
  "abdul": ['AE0 B D UW1 L'],
  "abdulaziz": ['AE0 B D UW2 L AH0 Z IY1 Z'],
  "abdulla": ['AA0 B D UW1 L AH0'],
  "abdullah": ['AE2 B D AH1 L AH0'],
  "abe": ['EY1 B'],
  "abed": ['AH0 B EH1 D'],
  "abedi": ['AH0 B EH1 D IY0'],
  "abee": ['AH0 B IY1'],
  "abel": ['EY1 B AH0 L'],
  "abela": ['AA0 B EH1 L AH0'],
  "abelard": ['AE1 B IH0 L ER0 D'],
  "abele": ['AH0 B EH1 L'],
  "abeles": ['AH0 B EH1 L Z', 'EY1 B AH0 L IY2 Z'],
  "abell": ['EY1 B AH0 L'],
  "abella": ['AH0 B EH1 L AH0'],
  "abeln": ['AE1 B IH0 L N'],
  "abelow": ['AE1 B AH0 L OW0'],
  "abels": ['EY1 B AH0 L Z'],
  "abelson": ['AE1 B IH0 L S AH0 N'],
  "abend": ['AE1 B EH0 N D', 'AH0 B EH1 N D'],
  "abendroth": ['AE1 B IH0 N D R AO0 TH'],
  "aber": ['EY1 B ER0'],
  "abercrombie": ['AE2 B ER0 K R AA1 M B IY0'],
  "aberdeen": ['AE1 B ER0 D IY2 N'],
  "aberford": ['EY1 B ER0 F ER0 D'],
  "aberg": ['AE1 B ER0 G'],
  "aberle": ['AE1 B ER0 AH0 L', 'AE1 B ER0 L'],
  "abermin": ['AE1 B ER0 M IH0 N'],
  "abernathy": ['AE1 B ER0 N AE2 TH IY0'],
  "abernethy": ['AE1 B ER0 N EH2 TH IY0'],
  "aberrant": ['AE0 B EH1 R AH0 N T'],
  "aberration": ['AE2 B ER0 EY1 SH AH0 N'],
  "aberrational": ['AE2 B ER0 EY1 SH AH0 N AH0 L'],
  "aberrations": ['AE2 B ER0 EY1 SH AH0 N Z'],
  "abert": ['AE1 B ER0 T'],
  "abet": ['AH0 B EH1 T'],
  "abetted": ['AH0 B EH1 T IH0 D'],
  "abetting": ['AH0 B EH1 T IH0 NG'],
  "abex": ['EY1 B EH0 K S'],
  "abeyance": ['AH0 B EY1 AH0 N S'],
  "abeyta": ['AA0 B EY1 T AH0'],
  "abhor": ['AE0 B HH AO1 R'],
  "abhorred": ['AH0 B HH AO1 R D'],
  "abhorrence": ['AH0 B HH AO1 R AH0 N S'],
  "abhorrent": ['AE0 B HH AO1 R AH0 N T'],
  "abhors": ['AH0 B HH AO1 R Z'],
  "abiam": ['EY1 B IY0 AH0 M'],
  "abiam's": ['EY1 B IY0 AH0 M Z'],
  "abid": ['EY1 B IH0 D'],
  "abide": ['AH0 B AY1 D'],
  "abided": ['AH0 B AY1 D IH0 D'],
  "abides": ['AH0 B AY1 D Z'],
  "abiding": ['AH0 B AY1 D IH0 NG'],
  "abidjan": ['AE0 B IH0 JH AA1 N'],
  "abie": ['AE1 B IY0'],
  "abigail": ['AE1 B AH0 G EY2 L'],
  "abila": ['AA0 B IY1 L AH0'],
  "abilene": ['AE1 B IH0 L IY2 N'],
  "abilities": ['AH0 B IH1 L AH0 T IY2 Z'],
  "ability": ['AH0 B IH1 L AH0 T IY2'],
  "abimael": ['AE2 B IH0 M EY1 L'],
  "abimaels": ['AE2 B IH0 M EY1 L Z'],
  "abingdon": ['AE1 B IH0 NG D AH0 N'],
  "abington": ['AE1 B IH0 NG T AH0 N'],
  "abio": ['AA1 B IY0 OW0'],
  "abiola": ['AA2 B IY0 OW1 L AH0'],
  "abiola's": ['AA2 B IY0 OW1 L AH0 Z'],
  "abiomed": ['EY0 B IY1 AH0 M EH0 D'],
  "abiquiu": ['AH0 B IH1 K Y UW0'],
  "abitibi": ['AE2 B IH0 T IY1 B IY0'],
  "abitz": ['AE1 B IH0 T S'],
  "abject": ['AE1 B JH EH0 K T'],
  "abkhazia": ['AE0 B K AA1 Z Y AH0', 'AE0 B K AE1 Z Y AH0'],
  "abkhazian": ['AE0 B K AA1 Z IY0 AH0 N', 'AE0 B K AE1 Z IY0 AH0 N', 'AE0 B K AA1 Z Y AH0 N', 'AE0 B K AE1 Z Y AH0 N'],
  "abkhazians": ['AE0 B K AA1 Z IY0 AH0 N Z', 'AE0 B K AE1 Z IY0 AH0 N Z'],
  "ablate": ['AH2 B L EY1 T'],
  "ablation": ['AH2 B L EY1 SH AH0 N'],
  "ablaze": ['AH0 B L EY1 Z'],
  "able": ['EY1 B AH0 L'],
  "able-bodied": ['EY1 B AH0 L B AA1 D IY0 D'],
  "abled": ['EY1 B AH0 L D'],
  "abler": ['EY1 B AH0 L ER0', 'EY1 B L ER0'],
  "ables": ['EY1 B AH0 L Z'],
  "ablest": ['EY1 B AH0 L S T', 'EY1 B L AH0 S T'],
  "abloom": ['AH0 B L UW1 M'],
  "ablution": ['AH0 B L UW1 SH AH0 N'],
  "ablutions": ['AH0 B L UW1 SH AH0 N Z'],
  "ably": ['EY1 B L IY0'],
  "abnegation": ['AE2 B N EH0 G EY1 SH AH0 N'],
  "abner": ['AE1 B N ER0'],
  "abney": ['AE1 B N IY0'],
  "abnormal": ['AE0 B N AO1 R M AH0 L'],
  "abnormalities": ['AE2 B N AO0 R M AE1 L AH0 T IY0 Z'],
  "abnormality": ['AE2 B N AO0 R M AE1 L AH0 T IY0'],
  "abnormally": ['AE0 B N AO1 R M AH0 L IY0'],
  "abo": ['AA1 B OW0'],
  "abo's": ['AA1 B OW0 Z'],
  "aboard": ['AH0 B AO1 R D'],
  "abode": ['AH0 B OW1 D'],
  "abohalima": ['AE0 B AH0 HH AH0 L IY1 M AH0'],
  "abolish": ['AH0 B AA1 L IH0 SH'],
  "abolished": ['AH0 B AA1 L IH0 SH T'],
  "abolishes": ['AH0 B AA1 L IH0 SH IH0 Z'],
  "abolishing": ['AH0 B AA1 L IH0 SH IH0 NG'],
  "abolition": ['AE2 B AH0 L IH1 SH AH0 N'],
  "abolitionism": ['AE2 B AH0 L IH1 SH AH0 N IH2 Z AH0 M'],
  "abolitionist": ['AE2 B AH0 L IH1 SH AH0 N AH0 S T'],
  "abolitionists": ['AE2 B AH0 L IH1 SH AH0 N AH0 S T S'],
  "abominable": ['AH0 B AA1 M AH0 N AH0 B AH0 L'],
  "abomination": ['AH0 B AA2 M AH0 N EY1 SH AH0 N'],
  "abominations": ['AH0 B AA2 M AH0 N EY1 SH AH0 N Z'],
  "abood": ['AH0 B UW1 D'],
  "aboodi": ['AH0 B UW1 D IY0'],
  "abor": ['AH0 B AO1 R'],
  "aboriginal": ['AE2 B ER0 IH1 JH AH0 N AH0 L'],
  "aborigine": ['AE2 B ER0 IH1 JH AH0 N IY0'],
  "aborigines": ['AE2 B ER0 IH1 JH AH0 N IY0 Z'],
  "aborn": ['AH0 B AO1 R N'],
  "abort": ['AH0 B AO1 R T'],
  "aborted": ['AH0 B AO1 R T IH0 D'],
  "abortifacient": ['AH0 B AO2 R T AH0 F EY1 SH AH0 N T'],
  "abortifacients": ['AH0 B AO2 R T AH0 F EY1 SH AH0 N T S'],
  "aborting": ['AH0 B AO1 R T IH0 NG'],
  "abortion": ['AH0 B AO1 R SH AH0 N'],
  "abortionist": ['AH0 B AO1 R SH AH0 N IH0 S T'],
  "abortionists": ['AH0 B AO1 R SH AH0 N IH0 S T S'],
  "abortions": ['AH0 B AO1 R SH AH0 N Z'],
  "abortive": ['AH0 B AO1 R T IH0 V'],
  "aborts": ['AH0 B AO1 R T S'],
  "abott": ['AH0 B AA1 T'],
  "abou": ['AH0 B UW1'],
  "aboud": ['AA0 B UW1 D'],
  "abouhalima": ['AA2 B UW0 HH AA0 L IY1 M AH0'],
  "abouhalima's": ['AA2 B UW0 HH AA0 L IY1 M AH0 Z'],
  "abound": ['AH0 B AW1 N D'],
  "abounded": ['AH0 B AW1 N D IH0 D'],
  "abounding": ['AH0 B AW1 N D IH0 NG'],
  "abounds": ['AH0 B AW1 N D Z'],
  "about": ['AH0 B AW1 T'],
  "about's": ['AH0 B AW1 T S'],
  "above": ['AH0 B AH1 V'],
  "above's": ['AH0 B AH1 V Z'],
  "aboveboard": ['AH0 B AH1 V B AO2 R D'],
  "abplanalp": ['AE1 B P L AH0 N AE0 L P'],
  "abra": ['AA1 B R AH0'],
  "abracadabra": ['AE2 B R AH0 K AH0 D AE1 B R AH0'],
  "abrade": ['AE0 B R EY1 D'],
  "abraded": ['AE0 B R EY1 D IH0 D'],
  "abrades": ['AE0 B R EY1 D Z'],
  "abraham": ['EY1 B R AH0 HH AE2 M'],
  "abrahamian": ['AE2 B R AH0 HH EY1 M IY0 AH0 N'],
  "abrahams": ['EY1 B R AH0 HH AE2 M Z'],
  "abrahamsen": ['AE0 B R AH0 HH AE1 M S AH0 N'],
  "abrahamson": ['AH0 B R AE1 HH AH0 M S AH0 N'],
  "abram": ['AH0 B R AE1 M'],
  "abram's": ['EY1 B R AH0 M Z'],
  "abramczyk": ['AA1 B R AH0 M CH IH0 K'],
  "abramo": ['AA0 B R AA1 M OW0'],
  "abramovitz": ['AH0 B R AA1 M AH0 V IH0 T S'],
  "abramowicz": ['AH0 B R AA1 M AH0 V IH0 CH'],
  "abramowitz": ['AH0 B R AA1 M AH0 W IH0 T S'],
  "abrams": ['EY1 B R AH0 M Z'],
  "abrams's": ['EY1 B R AH0 M Z IH0 Z'],
  "abramson": ['EY1 B R AH0 M S AH0 N'],
  "abrasion": ['AH0 B R EY1 ZH AH0 N'],
  "abrasions": ['AH0 B R EY1 ZH AH0 N Z'],
  "abrasive": ['AH0 B R EY1 S IH0 V'],
  "abrasives": ['AH0 B R EY1 S IH0 V Z'],
  "abraxa": ['AH0 B R AE1 K S AH0'],
  "abraxa's": ['AH0 B R AE1 K S AH0 Z'],
  "abraxas": ['AH0 B R AE1 K S AH0 Z'],
  "abreast": ['AH0 B R EH1 S T'],
  "abrego": ['AA0 B R EH1 G OW0'],
  "abreu": ['AH0 B R UW1'],
  "abridge": ['AH0 B R IH1 JH'],
  "abridged": ['AH0 B R IH1 JH D'],
  "abridgement": ['AH0 B R IH1 JH M AH0 N T'],
  "abridges": ['AH0 B R IH1 JH AH0 Z'],
  "abridging": ['AH0 B R IH1 JH IH0 NG'],
  "abril": ['AH0 B R IH1 L'],
  "abroad": ['AH0 B R AO1 D'],
  "abrogate": ['AE1 B R AH0 G EY2 T'],
  "abrogated": ['AE1 B R AH0 G EY2 T IH0 D'],
  "abrogating": ['AE1 B R AH0 G EY2 T IH0 NG'],
  "abrogation": ['AE2 B R AH0 G EY1 SH AH0 N'],
  "abrol": ['AH0 B R OW1 L'],
  "abron": ['AH0 B R AA1 N'],
  "abrupt": ['AH0 B R AH1 P T'],
  "abruptly": ['AH0 B R AH1 P T L IY0'],
  "abruptness": ['AH0 B R AH1 P T N AH0 S'],
  "abrutyn": ['EY1 B R UW0 T IH0 N'],
  "abruzzese": ['AA0 B R UW0 T S EY1 Z IY0'],
  "abruzzo": ['AA0 B R UW1 Z OW0'],
  "abs": ['EY1 B IY1 EH1 S', 'AE1 B Z'],
  "absalom": ['AE1 B S AH0 L AH0 M'],
  "absaraka": ['AE0 B S AA1 R AH0 K AH0'],
  "abscam": ['AE1 B S K AE0 M'],
  "abscess": ['AE1 B S EH2 S'],
  "abscond": ['AE0 B S K AA1 N D'],
  "absconded": ['AE0 B S K AA1 N D IH0 D'],
  "absconding": ['AE0 B S K AA1 N D IH0 NG'],
  "absconds": ['AE0 B S K AA1 N D Z'],
  "absecon": ['AE1 B S AH0 K AO0 N'],
  "absence": ['AE1 B S AH0 N S'],
  "absences": ['AE1 B S AH0 N S IH0 Z'],
  "absent": ['AE1 B S AH0 N T'],
  "absentee": ['AE2 B S AH0 N T IY1'],
  "absenteeism": ['AE2 B S AH0 N T IY1 IH0 Z AH0 M'],
  "absentees": ['AE2 B S AH0 N T IY1 Z'],
  "absentia": ['AE0 B S EH1 N SH AH0'],
  "absher": ['AE1 B SH ER0'],
  "abshier": ['AE1 B SH IY0 ER0'],
  "abshire": ['AE1 B SH AY2 R'],
  "absinthe": ['AE1 B S IH0 N TH'],
  "abso": ['AE1 B S OW0'],
  "absolom": ['AE1 B S AH0 L AH0 M'],
  "absolut": ['AE2 B S AH0 L UW1 T'],
  "absolut's": ['AE2 B S AH0 L UW1 T S'],
  "absolute": ['AE1 B S AH0 L UW2 T'],
  "absolutely": ['AE2 B S AH0 L UW1 T L IY0'],
  "absoluteness": ['AE1 B S AH0 L UW2 T N AH0 S'],
  "absolutes": ['AE1 B S AH0 L UW2 T S'],
  "absolution": ['AE2 B S AH0 L UW1 SH AH0 N'],
  "absolutism": ['AE1 B S AH0 L UW2 T IH2 Z AH0 M'],
  "absolutist": ['AE0 B S AH0 L UW1 T IH0 S T'],
  "absolve": ['AH0 B Z AA1 L V', 'AE0 B Z AA1 L V'],
  "absolved": ['AH0 B Z AA1 L V D', 'AE0 B Z AA1 L V D'],
  "absolves": ['AH0 B Z AA1 L V Z', 'AE0 B Z AA1 L V Z'],
  "absolving": ['AH0 B Z AA1 L V IH0 NG', 'AE0 B Z AA1 L V IH0 NG'],
  "absorb": ['AH0 B Z AO1 R B'],
  "absorbed": ['AH0 B Z AO1 R B D'],
  "absorbency": ['AH0 B Z AO1 R B AH0 N S IY0'],
  "absorbent": ['AH0 B Z AO1 R B AH0 N T'],
  "absorber": ['AH0 B Z AO1 R B ER0'],
  "absorbers": ['AH0 B Z AO1 R B ER0 Z'],
  "absorbing": ['AH0 B Z AO1 R B IH0 NG'],
  "absorbs": ['AH0 B Z AO1 R B Z'],
  "absorption": ['AH0 B Z AO1 R P SH AH0 N', 'AH0 B S AO1 R P SH AH0 N'],
  "abstain": ['AH0 B S T EY1 N', 'AE0 B S T EY1 N'],
  "abstained": ['AH0 B S T EY1 N D', 'AE0 B S T EY1 N D'],
  "abstaining": ['AH0 B S T EY1 N IH0 NG', 'AE0 B S T EY1 N IH0 NG'],
  "abstention": ['AH0 B S T EH1 N CH AH0 N', 'AE0 B S T EH1 N CH AH0 N'],
  "abstentions": ['AH0 B S T EH1 N CH AH0 N Z', 'AE0 B S T EH1 N CH AH0 N Z'],
  "abstinence": ['AE1 B S T AH0 N AH0 N S'],
  "abstinent": ['AE1 B S T AH0 N AH0 N T'],
  "abston": ['AE1 B S T AH0 N'],
  "abstract": ['AE0 B S T R AE1 K T', 'AE1 B S T R AE2 K T'],
  "abstracted": ['AE1 B S T R AE2 K T IH0 D'],
  "abstraction": ['AE0 B S T R AE1 K SH AH0 N'],
  "abstractions": ['AE0 B S T R AE1 K SH AH0 N Z'],
  "abstracts": ['AE1 B S T R AE0 K T S'],
  "abstruse": ['AH0 B S T R UW1 S'],
  "absurd": ['AH0 B S ER1 D'],
  "absurdist": ['AH0 B S ER1 D IH0 S T'],
  "absurdities": ['AH0 B S ER1 D AH0 T IY0 Z'],
  "absurdity": ['AH0 B S ER1 D AH0 T IY0'],
  "absurdly": ['AH0 B S ER1 D L IY0'],
  "abt": ['AE1 B T', 'EY1 B IY1 T IY1'],
  "abts": ['AE1 B T S', 'EY1 B IY1 T IY1 Z', 'EY1 B IY1 T IY1 EH1 S'],
  "abu": ['AE1 B UW0'],
  "abudrahm": ['AH0 B AH1 D R AH0 M'],
  "abuellah": ['AH0 B W EH1 L AH0'],
  "abuellah's": ['AH0 B W EH1 L AH0 Z'],
  "abuladze": ['AE2 B Y UW0 L AE1 D Z IY0'],
  "abundance": ['AH0 B AH1 N D AH0 N S'],
  "abundant": ['AH0 B AH1 N D AH0 N T'],
  "abundantly": ['AH0 B AH1 N D AH0 N T L IY0'],
  "aburto": ['AH0 B UH1 R T OW2'],
  "aburto's": ['AH0 B UH1 R T OW2 Z'],
  "abuse": ['AH0 B Y UW1 S', 'AH0 B Y UW1 Z'],
  "abused": ['AH0 B Y UW1 Z D'],
  "abuser": ['AH0 B Y UW1 Z ER0'],
  "abusers": ['AH0 B Y UW1 Z ER0 Z'],
  "abuses": ['AH0 B Y UW1 S IH0 Z', 'AH0 B Y UW1 Z IH0 Z'],
  "abusing": ['AH0 B Y UW1 Z IH0 NG'],
  "abusive": ['AH0 B Y UW1 S IH0 V'],
  "abut": ['AH0 B AH1 T'],
  "abuts": ['AH0 B AH1 T S'],
  "abutted": ['AH0 B AH1 T IH0 D'],
  "abutting": ['AH0 B AH1 T IH0 NG'],
  "abuzz": ['AH0 B AH1 Z'],
  "abysmal": ['AH0 B IH1 Z M AH0 L'],
  "abysmally": ['AH0 B IH1 Z M AH0 L IY0'],
  "abyss": ['AH0 B IH1 S'],
  "abyssinia": ['AE0 B S IH1 N IY2 AH0'],
  "abyssinian": ['AE0 B S IH1 N IY2 AH0 N'],
  "abzug": ['AE1 B Z AH2 G', 'AE1 B Z UH2 G'],
  "ac": ['EY1 S IY1'],
  "aca": ['AE1 K AH0'],
  "acacia": ['AH0 K EY1 SH AH0'],
  "academe": ['AE1 K AH0 D IY2 M'],
  "academia": ['AE2 K AH0 D IY1 M IY0 AH0'],
  "academic": ['AE2 K AH0 D EH1 M IH0 K'],
  "academically": ['AE2 K AH0 D EH1 M IH0 K L IY0'],
  "academician": ['AE2 K AH0 D AH0 M IH1 SH AH0 N'],
  "academicians": ['AE2 K AH0 D AH0 M IH1 SH AH0 N Z', 'AH0 K AE2 D AH0 M IH1 SH AH0 N Z'],
  "academics": ['AE2 K AH0 D EH1 M IH0 K S'],
  "academies": ['AH0 K AE1 D AH0 M IY0 Z'],
  "academy": ['AH0 K AE1 D AH0 M IY0'],
  "academy's": ['AH0 K AE1 D AH0 M IY0 Z'],
  "acadia": ['AH0 K EY1 D IY0 AH0'],
  "acaena": ['AH0 S IY1 N AH0'],
  "acampo": ['AH0 K AA1 M P OW0'],
  "acampora": ['AH0 K AE1 M P ER0 AH0'],
  "acampsia": ['AH0 K AE1 M P S Y AH0'],
  "acantha": ['AA0 K AA1 N DH AH0'],
  "acapulco": ['AE2 K AH0 P UH1 L K OW0'],
  "acary": ['AE1 K ER0 IY0'],
  "acc": ['AY2 S IY2 S IY1'],
  "accardi": ['AA0 K AA1 R D IY0'],
  "accardo": ['AA0 K AA1 R D OW0'],
  "accede": ['AE0 K S IY1 D'],
  "acceded": ['AE0 K S IY1 D IH0 D'],
  "accedes": ['AE0 K S IY1 D Z'],
  "acceding": ['AE0 K S IY1 D IH0 NG'],
  "accel": ['AH0 K S EH1 L'],
  "accelerant": ['AE0 K S EH1 L ER0 AH0 N T'],
  "accelerants": ['AE0 K S EH1 L ER0 AH0 N T S'],
  "accelerate": ['AE0 K S EH1 L ER0 EY2 T'],
  "accelerated": ['AE0 K S EH1 L ER0 EY2 T IH0 D'],
  "accelerates": ['AE0 K S EH1 L ER0 EY2 T S'],
  "accelerating": ['AE0 K S EH1 L ER0 EY2 T IH0 NG'],
  "acceleration": ['AE2 K S EH2 L ER0 EY1 SH AH0 N'],
  "accelerator": ['AE0 K S EH1 L ER0 EY2 T ER0'],
  "accelerators": ['AE0 K S EH1 L ER0 EY2 T ER0 Z'],
  "accelerometer": ['AE0 K S EH2 L ER0 AA1 M AH0 T ER0'],
  "accelerometers": ['AE0 K S EH2 L ER0 AA1 M AH0 T ER0 Z'],
  "accent": ['AH0 K S EH1 N T', 'AE1 K S EH2 N T'],
  "accented": ['AE1 K S EH0 N T IH0 D'],
  "accenting": ['AE1 K S EH0 N T IH0 NG'],
  "accents": ['AE1 K S EH0 N T S'],
  "accentuate": ['AE0 K S EH1 N CH UW0 EY0 T'],
  "accentuated": ['AE0 K S EH1 N CH UW0 EY2 T IH0 D'],
  "accentuates": ['AE0 K S EH1 N CH UW0 EY2 T S'],
  "accentuating": ['AE0 K S EH1 N CH UW0 EY2 T IH0 NG'],
  "accenture": ['AE0 K S EH1 N CH ER0'],
  "accept": ['AE0 K S EH1 P T', 'AH0 K S EH1 P T'],
  "acceptability": ['AH0 K S EH2 P T AH0 B IH1 L AH0 T IY0'],
  "acceptable": ['AE0 K S EH1 P T AH0 B AH0 L', 'AH0 K S EH1 P T AH0 B AH0 L'],
  "acceptably": ['AE0 K S EH1 P T AH0 B L IY0', 'AH0 K S EH1 P T AH0 B L IY0'],
  "acceptance": ['AE0 K S EH1 P T AH0 N S', 'AH0 K S EH1 P T AH0 N S'],
  "acceptances": ['AE0 K S EH1 P T AH0 N S IH0 Z'],
  "accepted": ['AE0 K S EH1 P T IH0 D', 'AH0 K S EH1 P T IH0 D'],
  "accepting": ['AE0 K S EH1 P T IH0 NG', 'AH0 K S EH1 P T IH0 NG'],
  "accepts": ['AE0 K S EH1 P T S'],
  "access": ['AE1 K S EH2 S'],
  "accessed": ['AE1 K S EH2 S T'],
  "accessibility": ['AE2 K S EH0 S AH0 B IH1 L IH0 T IY0'],
  "accessible": ['AE0 K S EH1 S AH0 B AH0 L'],
  "accessing": ['AE1 K S EH2 S IH0 NG'],
  "accession": ['AH0 K S EH1 SH AH0 N'],
  "accessories": ['AE0 K S EH1 S ER0 IY0 Z'],
  "accessorize": ['AE0 K S EH1 S ER0 AY2 Z'],
  "accessorized": ['AE0 K S EH1 S ER0 AY2 Z D'],
  "accessory": ['AE0 K S EH1 S ER0 IY0'],
  "accetta": ['AA0 CH EH1 T AH0'],
  "accident": ['AE1 K S AH0 D AH0 N T'],
  "accident's": ['AE1 K S AH0 D AH0 N T S'],
  "accidental": ['AE2 K S AH0 D EH1 N T AH0 L', 'AE2 K S AH0 D EH1 N AH0 L'],
  "accidentally": ['AE2 K S AH0 D EH1 N T AH0 L IY0', 'AE2 K S AH0 D EH1 N AH0 L IY0'],
  "accidently": ['AE1 K S AH0 D AH0 N T L IY0'],
  "accidents": ['AE1 K S AH0 D AH0 N T S'],
  "accion": ['AE1 CH IY0 AH0 N'],
  "accival": ['AE1 S IH0 V AA2 L'],
  "acclaim": ['AH0 K L EY1 M'],
  "acclaim's": ['AH0 K L EY1 M Z'],
  "acclaimed": ['AH0 K L EY1 M D'],
  "acclaiming": ['AH0 K L EY1 M IH0 NG'],
  "acclaims": ['AH0 K L EY1 M Z'],
  "acclamation": ['AE2 K L AH0 M EY1 SH AH0 N'],
  "acclimate": ['AE1 K L AH0 M EY2 T'],
  "acclimated": ['AE1 K L AH0 M EY2 T IH0 D'],
  "acclimation": ['AE2 K L AH0 M EY1 SH AH0 N'],
  "acco": ['AE1 K OW0'],
  "accokeek": ['AE1 K OW0 K IY0 K'],
  "accola": ['AA0 K OW1 L AH0'],
  "accolade": ['AE1 K AH0 L EY2 D'],
  "accolades": ['AE1 K AH0 L EY2 D Z'],
  "accomando": ['AA0 K OW0 M AA1 N D OW0'],
  "accommodate": ['AH0 K AA1 M AH0 D EY2 T'],
  "accommodated": ['AH0 K AA1 M AH0 D EY2 T IH0 D'],
  "accommodates": ['AH0 K AA1 M AH0 D EY2 T S'],
  "accommodating": ['AH0 K AA1 M AH0 D EY2 T IH0 NG'],
  "accommodation": ['AH0 K AA2 M AH0 D EY1 SH AH0 N'],
  "accommodations": ['AH0 K AA2 M AH0 D EY1 SH AH0 N Z'],
  "accommodative": ['AH0 K AA1 M AH0 D EY2 T IH0 V'],
  "accompanied": ['AH0 K AH1 M P AH0 N IY0 D'],
  "accompanies": ['AH0 K AH1 M P AH0 N IY0 Z'],
  "accompaniment": ['AH0 K AH1 M P N IH0 M AH0 N T', 'AH0 K AH1 M P N IY0 M AH0 N T'],
  "accompaniments": ['AH0 K AH1 M P N IH0 M AH0 N T S', 'AH0 K AH1 M P N IY0 M AH0 N T S'],
  "accompanist": ['AH0 K AH1 M P AH0 N AH0 S T'],
  "accompany": ['AH0 K AH1 M P AH0 N IY0'],
  "accompanying": ['AH0 K AH1 M P AH0 N IY0 IH0 NG'],
  "accompli": ['AA2 K AA1 M P L IY0', 'AH0 K AA1 M P L IY0'],
  "accomplice": ['AH0 K AA1 M P L AH0 S'],
  "accomplices": ['AH0 K AA1 M P L AH0 S AH0 Z'],
  "accomplish": ['AH0 K AA1 M P L IH0 SH'],
  "accomplished": ['AH0 K AA1 M P L IH0 SH T'],
  "accomplishes": ['AH0 K AA1 M P L IH0 SH IH0 Z'],
  "accomplishing": ['AH0 K AA1 M P L IH0 SH IH0 NG'],
  "accomplishment": ['AH0 K AA1 M P L IH0 SH M AH0 N T'],
  "accomplishments": ['AH0 K AA1 M P L IH0 SH M AH0 N T S'],
  "accor": ['AE1 K AO2 R'],
  "accor's": ['AE1 K ER0 Z'],
  "accord": ['AH0 K AO1 R D'],
  "accord's": ['AH0 K AO1 R D Z'],
  "accordance": ['AH0 K AO1 R D AH0 N S'],
  "accorded": ['AH0 K AO1 R D IH0 D'],
  "according": ['AH0 K AO1 R D IH0 NG'],
  "accordingly": ['AH0 K AO1 R D IH0 NG L IY0'],
  "accordion": ['AH0 K AO1 R D IY0 AH0 N'],
  "accordions": ['AH0 K AO1 R D IY0 AH0 N Z'],
  "accords": ['AH0 K AO1 R D Z'],
  "accost": ['AH0 K AO1 S T'],
  "accosted": ['AH0 K AA1 S T IH0 D'],
  "accosting": ['AH0 K AA1 S T IH0 NG'],
  "account": ['AH0 K AW1 N T'],
  "account's": ['AH0 K AW1 N T S'],
  "accountability": ['AH0 K AW1 N T AH0 B IH2 L IH0 T IY0', 'AH0 K AW1 N AH0 B IH0 L IH0 T IY0'],
  "accountable": ['AH0 K AW1 N T AH0 B AH0 L', 'AH0 K AW1 N AH0 B AH0 L'],
  "accountancy": ['AH0 K AW1 N T AH0 N S IY0'],
  "accountant": ['AH0 K AW1 N T AH0 N T'],
  "accountant's": ['AH0 K AW1 N T AH0 N T S'],
  "accountants": ['AH0 K AW1 N T AH0 N T S'],
  "accountants'": ['AH0 K AW1 N T AH0 N T S'],
  "accounted": ['AH0 K AW1 N T IH0 D', 'AH0 K AW1 N AH0 D'],
  "accountemp": ['AH0 K AW1 N T EH2 M P'],
  "accountemps": ['AH0 K AW1 N T EH2 M P S'],
  "accounting": ['AH0 K AW1 N T IH0 NG', 'AH0 K AW1 N IH0 NG'],
  "accounts": ['AH0 K AW1 N T S'],
  "accouterment": ['AH0 K UW1 T ER0 M AH0 N T'],
  "accouterments": ['AH0 K UW1 T ER0 M AH0 N T S'],
  "accoutrement": ['AH0 K UW1 T ER0 M AH0 N T'],
  "accoutrements": ['AH0 K UW1 T ER0 M AH0 N T S'],
  "accra": ['AA1 K R AA2'],
  "accredit": ['AH0 K R EH2 D AH0 T'],
  "accreditation": ['AH0 K R EH2 D AH0 T EY1 SH AH0 N'],
  "accreditations": ['AH0 K R EH2 D AH0 T EY1 SH AH0 N Z'],
  "accredited": ['AH0 K R EH1 D IH0 T IH0 D'],
  "accrediting": ['AH0 K R EH1 D AH0 T IH0 NG'],
  "accreted": ['AH0 K R IY1 T IH0 D'],
  "accretion": ['AH0 K R IY1 SH AH0 N'],
  "accrual": ['AH0 K R UW1 AH0 L'],
  "accruals": ['AH0 K R UW1 AH0 L Z'],
  "accrue": ['AH0 K R UW1'],
  "accrued": ['AH0 K R UW1 D'],
  "accrues": ['AH0 K R UW1 Z'],
  "accruing": ['AH0 K R UW1 IH0 NG'],
  "accu": ['AE1 K Y UW0'],
  "accuhealth": ['AE1 K Y UW0 HH EH2 L TH'],
  "accumulate": ['AH0 K Y UW1 M Y AH0 L EY2 T'],
  "accumulated": ['AH0 K Y UW1 M Y AH0 L EY2 T IH0 D'],
  "accumulates": ['AH0 K Y UW1 M Y AH0 L EY2 T S'],
  "accumulating": ['AH0 K Y UW1 M Y AH0 L EY2 T IH0 NG'],
  "accumulation": ['AH0 K Y UW2 M Y AH0 L EY1 SH AH0 N'],
  "accumulations": ['AH0 K Y UW2 M Y AH0 L EY1 SH AH0 N Z'],
  "accumulative": ['AH0 K Y UW1 M Y AH0 L EY2 T IH0 V'],
  "accumulatively": ['AH0 K Y UW1 M Y AH0 L EY2 T IH0 V L IY0', 'AH0 K Y UW1 M Y AH0 L AH0 T IH2 V L IY0'],
  "accumulator": ['AH0 K Y UW1 M Y AH0 L EY2 T ER0'],
  "accumulators": ['AH0 K Y UW1 M Y AH0 L EY2 T ER0 Z'],
  "accuracies": ['AE1 K Y ER0 AH0 S IY0 Z'],
  "accuracy": ['AE1 K Y ER0 AH0 S IY0'],
  "accurate": ['AE1 K Y ER0 AH0 T'],
  "accurately": ['AE1 K Y ER0 AH0 T L IY0'],
  "accuray": ['AE1 K Y ER0 EY2'],
  "accuray's": ['AE1 K Y ER0 EY2 Z'],
  "accuride": ['AE1 K Y ER0 AY2 D'],
  "accursed": ['AE2 K ER1 S T'],
  "accurso": ['AA0 K UH1 R S OW0'],
  "accusation": ['AE2 K Y AH0 Z EY1 SH AH0 N', 'AE2 K Y UW0 Z EY1 SH AH0 N'],
  "accusations": ['AE2 K Y AH0 Z EY1 SH AH0 N Z', 'AE2 K Y UW0 Z EY1 SH AH0 N Z'],
  "accusative": ['AH0 K Y UW1 Z AH0 T IH0 V'],
  "accusatory": ['AH0 K Y UW1 Z AH0 T AO2 R IY0'],
  "accuse": ['AH0 K Y UW1 Z'],
  "accused": ['AH0 K Y UW1 Z D'],
  "accuser": ['AH0 K Y UW1 Z ER0'],
  "accusers": ['AH0 K Y UW1 Z ER0 Z'],
  "accuses": ['AH0 K Y UW1 Z IH0 Z'],
  "accusing": ['AH0 K Y UW1 Z IH0 NG'],
  "accusingly": ['AH0 K Y UW1 Z IH0 NG L IY0'],
  "accustom": ['AH0 K AH1 S T AH0 M'],
  "accustomed": ['AH0 K AH1 S T AH0 M D'],
  "accutane": ['AE1 K Y UW0 T EY2 N'],
  "ace": ['EY1 S'],
  "acecomm": ['EY1 S K AA0 M'],
  "aced": ['EY1 S T'],
  "acer": ['EY1 S ER0'],
  "acerbic": ['AH0 S EH1 R B IH0 K'],
  "acero": ['AH0 S EH1 R OW0', 'AH0 S Y EH1 R OW0', 'AH0 TH EH1 R OW0'],
  "acerra": ['AH0 S EH1 R AH0'],
  "aces": ['EY1 S IH0 Z'],
  "acetaminophen": ['AH0 S IY2 T AH0 M IH1 N AH0 F AH0 N'],
  "acetate": ['AE1 S AH0 T EY2 T'],
  "acetic": ['AH0 S EH1 T IH0 K', 'AH0 S IY1 T IH0 K'],
  "aceto": ['AA0 S EH1 T OW0'],
  "acetochlor": ['AA0 S EH1 T OW0 K L AO2 R'],
  "acetone": ['AE1 S AH0 T OW2 N'],
  "acetosyringone": ['AH0 S EH2 T OW0 S IH1 R IH0 NG G AO0 N'],
  "acetylcholine": ['AH0 S EH2 T AH0 L K OW1 L IY0 N', 'AH0 S IY2 T AH0 L K OW1 L IY0 N'],
  "acetylene": ['AH0 S EH1 T AH0 L IY2 N'],
  "acevedo": ['AE0 S AH0 V EY1 D OW0'],
  "aceves": ['AA0 S EY1 V EH0 S'],
  "acey": ['EY1 S IY0'],
  "ach": ['EY1 S IY1 EY1 CH'],
  "achaean": ['AH0 CH IY1 AH0 N'],
  "achatz": ['AE1 K AH0 T S'],
  "ache": ['EY1 K'],
  "achebe": ['AA0 CH EY1 B IY0'],
  "ached": ['EY1 K T'],
  "achee": ['AH0 CH IY1'],
  "achenbach": ['AE1 K IH0 N B AA0 K'],
  "achenbaum": ['AE1 K AH0 N B AW2 M'],
  "achene": ['AH0 K IY1 N'],
  "achenes": ['AH0 K IY1 N Z'],
  "aches": ['EY1 K S'],
  "acheson": ['AE1 CH AH0 S AH0 N'],
  "acheson's": ['AE1 CH AH0 S AH0 N Z'],
  "achesons": ['AE1 CH AH0 S AH0 N Z'],
  "achey": ['AE1 CH IY0'],
  "achieva": ['AH0 CH IY1 V AH0'],
  "achievable": ['AH0 CH IY1 V AH0 B AH0 L'],
  "achieve": ['AH0 CH IY1 V'],
  "achieved": ['AH0 CH IY1 V D'],
  "achievement": ['AH0 CH IY1 V M AH0 N T'],
  "achievements": ['AH0 CH IY1 V M AH0 N T S'],
  "achiever": ['AH0 CH IY1 V ER0'],
  "achievers": ['AH0 CH IY1 V ER0 Z'],
  "achieves": ['AH0 CH IY1 V Z'],
  "achieving": ['AH0 CH IY1 V IH0 NG'],
  "achill": ['AE1 K IH0 L'],
  "achill's": ['AE1 K IH0 L Z'],
  "achille": ['AH0 K IH1 L IY0'],
  "achilles": ['AH0 K IH1 L IY0 Z'],
  "achilles'": ['AH0 K IH1 L IY0 Z'],
  "aching": ['EY1 K IH0 NG'],
  "achingly": ['EY1 K IH0 NG L IY0'],
  "achmed": ['AA1 HH M EH0 D'],
  "achoa": ['AH0 CH OW1 AH0'],
  "achoa's": ['AH0 CH OW1 AH0 Z'],
  "achoo": ['AA2 CH UW1'],
  "achor": ['EY1 K ER0'],
  "achord": ['AE1 K AO0 R D'],
  "achorn": ['AE1 K ER0 N'],
  "achtenberg": ['AE1 K T EH0 N B ER0 G'],
  "achterberg": ['AE1 K T ER0 B ER0 G'],
  "achy": ['EY1 K IY0'],
  "acid": ['AE1 S AH0 D'],
  "acidic": ['AH0 S IH1 D IH0 K'],
  "acidification": ['AH0 S IH2 D AH0 F AH0 K EY1 SH AH0 N'],
  "acidified": ['AH0 S IH1 D AH0 F AY2 D'],
  "acidifies": ['AH0 S IH1 D AH0 F AY2 Z'],
  "acidify": ['AH0 S IH1 D AH0 F AY2'],
  "acidity": ['AH0 S IH1 D AH0 T IY0'],
  "acidly": ['AE1 S AH0 D L IY0'],
  "acidosis": ['AE2 S AH0 D OW1 S AH0 S'],
  "acids": ['AE1 S AH0 D Z'],
  "aciduria": ['AE2 S AH0 D UH1 R IY0 AH0'],
  "acierno": ['AA0 S IH1 R N OW0'],
  "ack": ['AE1 K'],
  "acker": ['AE1 K ER0'],
  "acker's": ['AE1 K ER0 Z'],
  "ackerley": ['AE1 K ER0 L IY0'],
  "ackerly": ['AE1 K ER0 L IY0'],
  "ackerman": ['AE1 K ER0 M AH0 N'],
  "ackermann": ['AE1 K ER0 M AH0 N'],
  "ackermanville": ['AE1 K ER0 M AH0 N V IH0 L'],
  "ackerson": ['AE1 K ER0 S AH0 N'],
  "ackert": ['AE1 K ER0 T'],
  "ackhouse": ['AE1 K HH AW2 S'],
  "ackland": ['AE1 K L AH0 N D'],
  "ackles": ['AE1 K AH0 L Z'],
  "ackley": ['AE1 K L IY0'],
  "acklin": ['AE1 K L IH0 N'],
  "ackman": ['AE1 K M AH0 N'],
  "acknowledge": ['AE0 K N AA1 L IH0 JH'],
  "acknowledgeable": ['AE0 K N AA1 L IH0 JH AH0 B AH0 L'],
  "acknowledged": ['AE0 K N AA1 L IH0 JH D'],
  "acknowledgement": ['AE0 K N AA1 L IH0 JH M AH0 N T'],
  "acknowledgements": ['AE0 K N AA1 L IH0 JH M AH0 N T S'],
  "acknowledges": ['AE0 K N AA1 L IH0 JH IH0 Z'],
  "acknowledging": ['AE0 K N AA1 L IH0 JH IH0 NG'],
  "acknowledgment": ['AE0 K N AA1 L IH0 JH M AH0 N T'],
  "acknowledgments": ['AE0 K N AA1 L IH0 JH M AH0 N T S'],
  "ackroyd": ['AE1 K R OY2 D'],
  "ackroyd's": ['AE1 K R OY2 D Z'],
  "acm": ['EY2 S IY2 EH1 M'],
  "acmat": ['AE1 K M AE0 T'],
  "acmat's": ['AE1 K M AE0 T S'],
  "acme": ['AE1 K M IY0'],
  "acme's": ['AE1 K M IY0 Z'],
  "acne": ['AE1 K N IY0'],
  "acocella": ['AA0 K OW0 CH EH1 L AH0'],
  "acoff": ['AE1 K AO0 F'],
  "acog": ['AH0 K AO1 G'],
  "acolyte": ['AE1 K AH0 L AY2 T'],
  "acolytes": ['AE1 K AH0 L AY2 T S'],
  "acomb": ['EY1 K AH0 M', 'Y AE1 K AH0 M'],
  "acord": ['AH0 K AO1 R D'],
  "acordia": ['AH0 K AO1 R D IY0 AH0'],
  "acorn": ['EY1 K AO0 R N'],
  "acorn's": ['EY1 K AO0 R N Z'],
  "acorns": ['EY1 K AO0 R N Z'],
  "acosta": ['AH0 K AO1 S T AH0'],
  "acott": ['EY1 K AO0 T'],
  "acoustic": ['AH0 K UW1 S T IH0 K'],
  "acoustical": ['AH0 K UW1 S T IH0 K AH0 L'],
  "acoustically": ['AH0 K UW1 S T IH0 K L IY0'],
  "acoustics": ['AH0 K UW1 S T IH0 K S'],
  "acquaint": ['AH0 K W EY1 N T'],
  "acquaintance": ['AH0 K W EY1 N T AH0 N S'],
  "acquaintances": ['AH0 K W EY1 N T AH0 N S IH0 Z'],
  "acquaintanceship": ['AH0 K W EY1 N T AH0 N S SH IH0 P'],
  "acquainted": ['AH0 K W EY1 N T IH0 D', 'AH0 K W EY1 N IH0 D'],
  "acquaviva": ['AA0 K W AA0 V IY1 V AH0'],
  "acquiesce": ['AE2 K W IY0 EH1 S'],
  "acquiesced": ['AE2 K W IY0 EH1 S T'],
  "acquiescence": ['AE2 K W IY0 EH1 S AH0 N S'],
  "acquiescent": ['AE2 K W IY0 EH1 S AH0 N T'],
  "acquiescing": ['AE2 K W IY0 EH1 S IH0 NG'],
  "acquire": ['AH0 K W AY1 ER0'],
  "acquired": ['AH0 K W AY1 ER0 D'],
  "acquirer": ['AH0 K W AY1 ER0 ER0'],
  "acquirer's": ['AH0 K W AY1 ER0 ER0 Z'],
  "acquirers": ['AH0 K W AY1 ER0 ER0 Z'],
  "acquires": ['AH0 K W AY1 ER0 Z'],
  "acquiring": ['AH0 K W AY1 R IH0 NG', 'AH0 K W AY1 ER0 IH0 NG'],
  "acquisition": ['AE2 K W AH0 Z IH1 SH AH0 N'],
  "acquisition's": ['AE2 K W AH0 Z IH1 SH AH0 N Z'],
  "acquisitions": ['AE2 K W AH0 Z IH1 SH AH0 N Z'],
  "acquisitive": ['AH0 K W IH1 Z AH0 T IH0 V'],
  "acquit": ['AH0 K W IH1 T'],
  "acquitaine": ['AE1 K W IH0 T EY2 N'],
  "acquits": ['AH0 K W IH1 T S'],
  "acquittal": ['AH0 K W IH1 T AH0 L'],
  "acquittals": ['AH0 K W IH1 T AH0 L Z'],
  "acquitted": ['AH0 K W IH1 T IH0 D'],
  "acquitting": ['AH0 K W IH1 T IH0 NG'],
  "acre": ['EY1 K ER0'],
  "acreage": ['EY1 K ER0 IH0 JH', 'EY1 K R AH0 JH'],
  "acree": ['AH0 K R IY1'],
  "acres": ['EY1 K ER0 Z'],
  "acrey": ['AE1 K R IY0'],
  "acri": ['AA1 K R IY0'],
  "acrid": ['AE1 K R IH0 D'],
  "acrimonious": ['AE2 K R AH0 M OW1 N IY0 AH0 S'],
  "acrimony": ['AE1 K R IH0 M OW2 N IY0'],
  "acro": ['AE1 K R OW0'],
  "acrobat": ['AE1 K R AH0 B AE2 T'],
  "acrobatic": ['AE2 K R AH0 B AE1 T IH0 K'],
  "acrobatics": ['AE2 K R AH0 B AE1 T IH0 K S'],
  "acrobats": ['AE1 K R AH0 B AE2 T S'],
  "acrolein": ['AE1 K R OW0 L IY2 N'],
  "acronym": ['AE1 K R AH0 N IH0 M'],
  "acronyms": ['AE1 K R AH0 N IH0 M Z'],
  "acropolis": ['AH0 K R AA1 P AH0 L AH0 S'],
  "across": ['AH0 K R AO1 S'],
  "acrylic": ['AH0 K R IH1 L IH0 K'],
  "acrylics": ['AH0 K R IH1 L IH0 K S'],
  "act": ['AE1 K T'],
  "act's": ['AE1 K T S'],
  "acta": ['AE1 K T AA0'],
  "actava": ['AE2 K T AA1 V AH0'],
  "actava's": ['AE2 K T AA1 V AH0 Z'],
  "actavas": ['AE2 K T AA1 V AH0 Z'],
  "acted": ['AE1 K T IH0 D'],
  "actel": ['AE1 K T EH0 L'],
  "actigall": ['AE1 K T IH0 G AO0 L'],
  "actin": ['AE1 K T AH0 N'],
  "acting": ['AE1 K T IH0 NG'],
  "actinide": ['AE1 K T IH0 N AY2 D'],
  "actinidia": ['AE2 K T IH0 N IH1 D IY0 AH0'],
  "actinomycosis": ['AE2 K T IH2 N OW0 M AY2 K OW1 S IH0 S'],
  "action": ['AE1 K SH AH0 N'],
  "action's": ['AE1 K SH AH0 N Z'],
  "actionable": ['AE1 K SH AH0 N AH0 B AH0 L'],
  "actions": ['AE1 K SH AH0 N Z'],
  "activase": ['AE1 K T IH0 V EY2 Z'],
  "activate": ['AE1 K T AH0 V EY2 T'],
  "activated": ['AE1 K T AH0 V EY2 T IH0 D'],
  "activates": ['AE1 K T AH0 V EY2 T S'],
  "activating": ['AE1 K T AH0 V EY2 T IH0 NG'],
  "activation": ['AE2 K T AH0 V EY1 SH AH0 N'],
  "activator": ['AE1 K T AH0 V EY2 T ER0'],
  "active": ['AE1 K T IH0 V'],
  "active's": ['AE1 K T IH0 V Z'],
  "actively": ['AE1 K T IH0 V L IY0'],
  "actives": ['AE1 K T IH0 V Z'],
  "activision": ['AE1 K T IH0 V IH2 ZH AH0 N'],
  "activism": ['AE1 K T IH0 V IH2 Z AH0 M'],
  "activist": ['AE1 K T AH0 V AH0 S T', 'AE1 K T IH0 V IH0 S T'],
  "activists": ['AE1 K T AH0 V AH0 S T S', 'AE1 K T IH0 V IH0 S T S'],
  "activists'": ['AE1 K T IH0 V IH0 S T S'],
  "activities": ['AE0 K T IH1 V AH0 T IY0 Z', 'AE0 K T IH1 V IH0 T IY0 Z'],
  "activity": ['AE0 K T IH1 V AH0 T IY0', 'AE0 K T IH1 V IH0 T IY0'],
  "actmedia": ['AE0 K T M IY1 D IY0 AH0'],
  "actodine": ['AE1 K T OW0 D AY2 N'],
  "acton": ['AE1 K T AH0 N'],
  "actor": ['AE1 K T ER0'],
  "actor's": ['AE1 K T ER0 Z'],
  "actors": ['AE1 K T ER0 Z'],
  "actors'": ['AE1 K T ER0 Z'],
  "actress": ['AE1 K T R AH0 S'],
  "actress's": ['AE1 K T R AH0 S IH0 Z'],
  "actresses": ['AE1 K T R AH0 S IH0 Z'],
  "acts": ['AE1 K T S', 'AE1 K S'],
  "actual": ['AE1 K CH AH0 W AH0 L', 'AE1 K SH AH0 L'],
  "actuality": ['AE2 K CH UW0 AE1 L AH0 T IY2'],
  "actualize": ['AE1 K CH UW2 AH0 L AY2 Z'],
  "actually": ['AE1 K CH UW2 AH0 L IY0', 'AE1 K CH L IY0', 'AE1 K SH AH0 L IY0'],
  "actuarial": ['AE2 K CH UW2 EH1 R IY0 AH0 L'],
  "actuaries": ['AE1 K CH UW2 EH1 R IY2 Z'],
  "actuary": ['AE1 K CH UW0 EH1 R IY2'],
  "actuate": ['AE1 K CH UW2 EY1 T'],
  "actuator": ['AE1 K T Y UW0 EY2 T ER0', 'AE1 K CH UW0 EY2 T ER0'],
  "actuators": ['AE1 K T Y UW0 EY2 T ER0 Z', 'AE1 K CH UW0 EY2 T ER0 Z'],
  "actus": ['AE1 K T AH0 S'],
  "acuff": ['AH0 K AH1 F'],
  "acuity": ['AH0 K Y UW1 AH0 T IY0'],
  "acumen": ['AH0 K Y UW1 M AH0 N'],
  "acuna": ['AA0 K UW1 N AH0'],
  "acupuncture": ['AE1 K Y UW0 P AH2 NG K CH ER0'],
  "acura": ['AE1 K Y ER0 AH0'],
  "acura's": ['AE1 K Y ER0 AH0 Z'],
  "acuras": ['AE1 K Y ER0 AH0 Z'],
  "acuson": ['AE2 K Y UW1 S AH0 N'],
  "acustar": ['AE1 K Y UW0 S T AA2 R'],
  "acusyst": ['AE1 K Y UW0 S IH0 S T'],
  "acute": ['AH0 K Y UW1 T'],
  "acutely": ['AH0 K Y UW1 T L IY0'],
  "acuteness": ['AH0 K Y UW1 T N AH0 S'],
  "acyclovir": ['AH0 S IH1 K L OW0 V IH2 R'],
  "ad": ['AE1 D'],
  "ad's": ['AE1 D Z'],
  "ad-hoc": ['AE1 D HH AA1 K'],
  "ad-lib": ['AE1 D L IH1 B'],
  "ad-nauseam": ['AE1 D N AO1 Z IY0 AA0 M'],
  "ad-nauseum": ['AE1 D N AO1 Z IY0 AH0 M'],
  "ada": ['EY1 D AH0'],
  "ada's": ['EY1 D AH0 Z'],
  "adabel": ['AE1 D AH0 B EH0 L'],
  "adabelle": ['AE1 D AH0 B AH0 L'],
  "adachi": ['AA0 D AA1 K IY0'],
  "adage": ['AE1 D AH0 JH', 'AE1 D IH0 JH'],
  "adagio": ['AH0 D AA1 ZH IY0 OW2'],
  "adah": ['AE1 D AA0'],
  "adair": ['AH0 D EH1 R'],
  "adaire": ['AA0 D EH1 R'],
  "adak": ['AH0 D AE1 K'],
  "adalah": ['AA0 D AA1 L AH0'],
  "adalia": ['AA0 D AA1 L IY0 AH0'],
  "adam": ['AE1 D AH0 M'],
  "adam's": ['AE1 D AH0 M Z'],
  "adamant": ['AE1 D AH0 M AH0 N T'],
  "adamantly": ['AE1 D AH0 M AH0 N T L IY0'],
  "adamcik": ['AA1 D AH0 M CH IH0 K'],
  "adamczak": ['AA1 D AH0 M CH AE0 K'],
  "adamczyk": ['AA1 D AH0 M CH IH0 K'],
  "adame": ['AA0 D AA1 M IY0'],
  "adamec": ['AH0 D AA1 M IH0 K'],
  "adamek": ['AH0 D AA1 M EH0 K'],
  "adames": ['AH0 D EY1 M Z'],
  "adami": ['AA0 D AA1 M IY0'],
  "adamik": ['AH0 D AA1 M IH0 K'],
  "adamina": ['AA0 D AA0 M IY1 N AH0'],
  "adamkus": ['AE1 D AH0 M K AH0 S'],
  "adamo": ['AA0 D AA1 M OW0'],
  "adamowicz": ['AH0 D AA1 M AH0 V IH0 CH'],
  "adams": ['AE1 D AH0 M Z'],
  "adams'": ['AE1 D AH0 M Z'],
  "adams's": ['AE1 D AH0 M Z IH0 Z'],
  "adamski": ['AH0 D AE1 M S K IY2'],
  "adamson": ['AE1 D AH0 M S AH0 N'],
  "adamstown": ['AE1 D AH0 M S T AW2 N'],
  "adan": ['EY1 D AH0 N'],
  "adapso": ['AH0 D AE1 P S OW0'],
  "adapt": ['AH0 D AE1 P T'],
  "adaptability": ['AH0 D AE2 P T AH0 B IH1 L AH0 T IY0'],
  "adaptable": ['AH0 D AE1 P T AH0 B AH0 L'],
  "adaptaplex": ['AH0 D AE1 P T AH0 P L EH2 K S'],
  "adaptation": ['AE2 D AH0 P T EY1 SH AH0 N'],
  "adaptations": ['AE2 D AE0 P T EY1 SH AH0 N Z', 'AE2 D AH0 P T EY1 SH AH0 N Z'],
  "adaptec": ['AH0 D AE1 P T EH2 K'],
  "adaptec's": ['AH0 D AE1 P T EH2 K S'],
  "adapted": ['AH0 D AE1 P T IH0 D'],
  "adapter": ['AH0 D AE1 P T ER0'],
  "adapters": ['AH0 D AE1 P T ER0 Z'],
  "adapting": ['AH0 D AE1 P T IH0 NG'],
  "adaptive": ['AH0 D AE1 P T IH0 V'],
  "adaptor": ['AH0 D AE1 P T ER0'],
  "adaptors": ['AH0 D AE1 P T ER0 Z'],
  "adapts": ['AH0 D AE1 P T S'],
  "adar": ['AH0 D AA1 R'],
  "adarand": ['AE1 D AH0 R AE2 N D'],
  "adare": ['AH0 D EH1 R'],
  "adas": ['EY1 D AH0 Z'],
  "aday": ['AH0 D EY1'],
  "adaza": ['AH0 D AA1 Z AH0'],
  "adblock": ['AE1 D B L AA2 K'],
  "adcock": ['AH0 D K AA1 K'],
  "adcox": ['AH0 D K AA1 K S'],
  "add": ['AE1 D'],
  "addair": ['AH0 D EH1 R'],
  "addams": ['AE1 D AH0 M Z'],
  "added": ['AE1 D AH0 D', 'AE1 D IH0 D'],
  "addendum": ['AH0 D EH1 N D AH0 M'],
  "addendums": ['AH0 D EH1 N D AH0 M Z'],
  "addeo": ['AA1 D IY0 OW0'],
  "adder": ['AE1 D ER0'],
  "adderley": ['AH0 D ER1 L IY0'],
  "addicks": ['AE1 D IH0 K S'],
  "addict": ['AH0 D IH1 K T', 'AE1 D IH2 K T'],
  "addicted": ['AH0 D IH1 K T IH0 D'],
  "addicting": ['AH0 D IH1 K T IH0 NG'],
  "addiction": ['AH0 D IH1 K SH AH0 N'],
  "addictions": ['AH0 D IH1 K SH AH0 N Z'],
  "addictive": ['AH0 D IH1 K T IH0 V'],
  "addicts": ['AH0 D IH1 K T S', 'AE1 D IH2 K T S'],
  "addidas": ['AH0 D IY1 D AH0 S'],
  "addidas'": ['AH0 D IY1 D AH0 S'],
  "addidas's": ['AH0 D IY1 D AH0 S IH0 Z'],
  "addidases": ['AH0 D IY1 D AH0 S IH0 Z'],
  "addie": ['AE1 D IY0'],
  "adding": ['AE1 D IH0 NG'],
  "addington": ['AE1 D IH0 NG T AH0 N'],
  "addis": ['AA1 D IH0 S'],
  "addis-ababa": ['AA1 D IH0 S AH0 B AA1 B AA0', 'AA1 D IY0 S AH0 B AA1 B AA0'],
  "addison": ['AE1 D AH0 S AH0 N', 'AE1 D IH0 S AH0 N'],
  "addison's": ['AE1 D IH0 S AH0 N Z'],
  "addition": ['AH0 D IH1 SH AH0 N'],
  "additional": ['AH0 D IH1 SH AH0 N AH0 L', 'AH0 D IH1 SH N AH0 L'],
  "additionally": ['AH0 D IH1 SH AH0 N AH2 L IY0', 'AH0 D IH1 SH N AH0 L IY0'],
  "additions": ['AH0 D IH1 SH AH0 N Z'],
  "additive": ['AE1 D AH0 T IH0 V', 'AE1 D IH0 T IH0 V'],
  "additives": ['AE1 D AH0 T IH0 V Z', 'AE1 D IH0 T IH0 V Z'],
  "addle": ['AE1 D AH0 L'],
  "addled": ['AE1 D AH0 L D'],
  "addleman": ['AE1 D AH0 L M AH0 N'],
  "address": ['AE1 D R EH2 S', 'AH0 D R EH1 S'],
  "addressable": ['AH0 D R EH1 S AH0 B AH0 L'],
  "addressed": ['AH0 D R EH1 S T'],
  "addressee": ['AE2 D R EH0 S IY1'],
  "addresses": ['AE1 D R EH1 S IH0 Z', 'AH0 D R EH1 S IH0 Z'],
  "addressing": ['AH0 D R EH1 S IH0 NG'],
  "adds": ['AE1 D Z'],
  "adduci": ['AA0 D UW1 CH IY0'],
  "adduct": ['AE1 D AH0 K T'],
  "addwest": ['AE2 D W EH1 S T'],
  "addy": ['AE1 D IY0'],
  "addyston": ['AE1 D IY0 S T AH0 N'],
  "ade": ['EY1 D'],
  "adee": ['AH0 D IY1'],
  "adel": ['AH0 D EH1 L'],
  "adela": ['AH0 D EH1 L AH0'],
  "adelaar": ['AE1 D AH0 L AA2 R'],
  "adelaide": ['AE1 D AH0 L EY2 D'],
  "adelanto": ['AE0 D AH0 L AA1 N T OW0'],
  "adelbert": ['AH0 D EH1 L B ER0 T'],
  "adele": ['AH0 D EH1 L'],
  "adele's": ['AH0 D EH1 L Z'],
  "adeline": ['AE1 D AH0 L AY2 N'],
  "adelizzi": ['AE2 D AH0 L IY1 Z IY0'],
  "adell": ['AH0 D EH1 L'],
  "adell's": ['AH0 D EH1 L Z'],
  "adelle": ['AH0 D EH1 L'],
  "adelman": ['AE1 D AH0 L M AH0 N', 'EH1 D AH0 L M AH0 N'],
  "adelmann": ['AE1 D AH0 L M AH0 N'],
  "adelpha": ['AH0 D EH1 L F AH0'],
  "adelphi": ['AH0 D EH1 L F IY0'],
  "adelphia": ['AH0 D EH1 L F IY0 AH0'],
  "adelphia's": ['AH0 D EH1 L F IY0 AH0 Z'],
  "adelsberger": ['AE1 D IH0 L Z B ER0 G ER0'],
  "adelson": ['AE1 D AH0 L S AH0 N'],
  "adelstein": ['AE1 D AH0 L S T AY0 N', 'AE1 D AH0 L S T IY0 N'],
  "aden": ['EY1 D AH0 N'],
  "adena": ['AE1 D IH0 N AH0'],
  "adenauer": ['EY1 D AH0 N AW2 R', 'AE1 D AH0 N AW2 R'],
  "adenine": ['AE1 D AH0 N IY2 N'],
  "adenoid": ['AE1 D AH0 N OY2 D'],
  "adenoids": ['AE1 D AH0 N OY2 D Z'],
  "adenoscan": ['AH0 D EH1 N AH0 S K AE2 N'],
  "adenosine": ['AH0 D EH1 N AH0 S IY2 N'],
  "adenovirus": ['AH0 D EH1 N AH0 V AY2 R AH0 S'],
  "adept": ['AH0 D EH1 P T'],
  "adequacy": ['AE1 D AH0 K W AH0 S IY0'],
  "adequate": ['AE1 D AH0 K W AH0 T', 'AE1 D AH0 K W EY2 T'],
  "adequately": ['AE1 D AH0 K W AH0 T L IY0', 'AE1 D AH0 K W IH0 T L IY0'],
  "ader": ['EY1 D ER0'],
  "aderhold": ['AE1 D ER0 HH OW0 L D'],
  "aderholt": ['AE1 D ER0 HH OW0 L T'],
  "aderman": ['AE1 D ER0 M AH0 N'],
  "ades": ['EY1 D Z'],
  "adey": ['EY1 D IY0'],
  "adger": ['AE1 JH ER0'],
  "adham": ['AE1 D HH AE0 M'],
  "adhd": ['EY2 D IY2 EY2 CH D IY1'],
  "adhere": ['AH0 D HH IH1 R'],
  "adhered": ['AE0 D HH IH1 R D'],
  "adherence": ['AH0 D HH IH1 R AH0 N S'],
  "adherent": ['AH0 D HH IH1 R AH0 N T'],
  "adherents": ['AE0 D HH IH1 R AH0 N T S'],
  "adheres": ['AH0 D HH IH1 R Z'],
  "adhering": ['AH0 D HH IH1 R IH0 NG'],
  "adhesion": ['AE0 D HH IY1 ZH AH0 N'],
  "adhesive": ['AE0 D HH IY1 S IH0 V', 'AH0 D HH IY1 S IH0 V'],
  "adhesives": ['AE0 D HH IY1 S IH0 V Z', 'AH0 D HH IY1 S IH0 V Z'],
  "adia": ['AA1 D IY0 AH0'],
  "adid": ['AH0 D IH1 D'],
  "adidas": ['AH0 D IY1 D AH0 S'],
  "adidas's": ['AH0 D IY1 D AH0 S IH0 Z'],
  "adieu": ['AH0 D UW1'],
  "adin": ['AH0 D IH1 N'],
  "adina": ['AA0 D IY1 N AH0'],
  "adine": ['AA0 D IY1 N IY0'],
  "adinolfi": ['AA0 D IY0 N OW1 L F IY0'],
  "adios": ['AA2 D IY0 OW1 S'],
  "adipose": ['AE1 D AH0 P OW2 S'],
  "adirondack": ['AE2 D ER0 AA1 N D AE0 K'],
  "adisq": ['AH0 D IH1 S K', 'EY1 D IH0 S K'],
  "adjacent": ['AH0 JH EY1 S AH0 N T'],
  "adjani": ['AE0 D JH AA1 N IY0'],
  "adjective": ['AE1 JH IH0 K T IH0 V'],
  "adjectives": ['AE1 JH IH0 K T IH0 V Z'],
  "adjoin": ['AH0 JH OY1 N'],
  "adjoining": ['AH0 JH OY1 N IH0 NG'],
  "adjoins": ['AH0 JH OY1 N Z'],
  "adjourn": ['AH0 JH ER1 N'],
  "adjourned": ['AH0 JH ER1 N D'],
  "adjourning": ['AH0 JH ER1 N IH0 NG'],
  "adjournment": ['AH0 JH ER1 N M AH0 N T'],
  "adjourns": ['AH0 JH ER1 N Z'],
  "adjudge": ['AH0 JH AH1 JH'],
  "adjudged": ['AH0 JH AH1 JH D'],
  "adjudicate": ['AH0 JH UW1 D IH0 K EY2 T'],
  "adjudicated": ['AH0 JH UW1 D AH0 K EY2 T IH0 D'],
  "adjudicating": ['AH0 JH UW1 D IH0 K EY2 T IH0 NG'],
  "adjudication": ['AH0 JH UW2 D AH0 K EY1 SH AH0 N'],
  "adjunct": ['AE1 JH AH2 NG K T'],
  "adjuncts": ['AE1 JH AH2 NG K T S'],
  "adjust": ['AH0 JH AH1 S T'],
  "adjustable": ['AH0 JH AH1 S T AH0 B AH0 L'],
  "adjustables": ['AH0 JH AH1 S T AH0 B AH0 L Z'],
  "adjusted": ['AH0 JH AH1 S T IH0 D'],
  "adjuster": ['AH0 JH AH1 S T ER0'],
  "adjusters": ['AH0 JH AH1 S T ER0 Z'],
  "adjusting": ['AH0 JH AH1 S T IH0 NG'],
  "adjustment": ['AH0 JH AH1 S T M AH0 N T'],
  "adjustments": ['AH0 JH AH1 S T M AH0 N T S'],
  "adjusts": ['AH0 JH AH1 S T S'],
  "adjutant": ['AE1 JH AH0 T AH0 N T'],
  "adkins": ['AE1 D K IH0 N Z'],
  "adkinson": ['AE1 D K IH0 N S AH0 N'],
  "adkison": ['AE1 D K IH0 S AH0 N'],
  "adkisson": ['AE1 D K IH0 S AH0 N'],
  "adl-tabataba": ['AA2 D AH0 L T AA2 B AH0 T AA1 B AH0'],
  "adl-tabatabai": ['AA2 D AH0 L T AA2 B AH0 T AH2 B AY1'],
  "adlai": ['AA0 D L AA1 IY0'],
  "adler": ['AE1 D L ER0'],
  "adler's": ['AE1 D L ER0 Z'],
  "adlers": ['AE1 D L ER0 Z'],
  "adley": ['AE1 D L IY0'],
  "adlon": ['AE1 D L AA0 N'],
  "adlong": ['AE1 D L AO0 NG'],
  "adman": ['AE1 D M AH0 N'],
  "admen": ['AE1 D M AH0 N'],
  "admin": ['AE1 D M IH0 N'],
  "administer": ['AH0 D M IH1 N AH0 S T ER0'],
  "administered": ['AH0 D M IH1 N AH0 S T ER0 D'],
  "administering": ['AE0 D M IH1 N IH0 S T ER2 IH0 NG'],
  "administers": ['AE0 D M IH1 N IH0 S T ER0 Z'],
  "administrate": ['AE0 D M IH1 N IH0 S T R EY2 T'],
  "administrating": ['AH0 D M IH1 N AH0 S T R EY2 T IH0 NG'],
  "administration": ['AE0 D M IH2 N IH0 S T R EY1 SH AH0 N'],
  "administration's": ['AE0 D M IH2 N IH0 S T R EY1 SH AH0 N Z'],
  "administrations": ['AE0 D M IH2 N IH0 S T R EY1 SH AH0 N Z'],
  "administrations'": ['AE0 D M IH2 N IH0 S T R EY1 SH AH0 N Z'],
  "administrative": ['AH0 D M IH1 N AH0 S T R EY2 T IH0 V'],
  "administratively": ['AE0 D M IH2 N AH0 S T R EY1 T IH0 V L IY0'],
  "administrator": ['AH0 D M IH1 N AH0 S T R EY2 T ER0'],
  "administrator's": ['AE0 D M IH1 N IH0 S T R EY2 T ER0 Z'],
  "administrators": ['AE0 D M IH1 N IH0 S T R EY2 T ER0 Z'],
  "administrators'": ['AE0 D M IH1 N AH0 S T R EY2 T ER0 Z'],
  "admins": ['AH0 D M IH1 N Z', 'AE1 D M IH0 N Z'],
  "admira": ['AE0 D M AY1 R AH0'],
  "admira's": ['AE0 D M AY1 R AH0 Z'],
  "admirable": ['AE1 D M ER0 AH0 B AH0 L', 'AE1 D M R AH0 B AH0 L'],
  "admirably": ['AE1 D M ER0 AH0 B L IY0'],
  "admiral": ['AE1 D M ER0 AH0 L'],
  "admiral's": ['AE1 D M ER0 AH0 L Z'],
  "admirals": ['AE1 D M ER0 AH0 L Z'],
  "admiralty": ['AE1 D M ER0 AH0 L T IY0'],
  "admiration": ['AE2 D M ER0 EY1 SH AH0 N'],
  "admirations": ['AE2 D M ER0 EY1 SH AH0 N Z'],
  "admire": ['AE0 D M AY1 R'],
  "admired": ['AH0 D M AY1 ER0 D'],
  "admirer": ['AE0 D M AY1 R ER0'],
  "admirers": ['AH0 D M AY1 R ER0 Z'],
  "admires": ['AE0 D M AY1 R Z'],
  "admiring": ['AE0 D M AY1 R IH0 NG'],
  "admiringly": ['AE0 D M AY1 R IH0 NG L IY0'],
  "admissibility": ['AH0 D M IH2 S AH0 B IH1 L AH0 T IY0'],
  "admissible": ['AH0 D M IH1 S AH0 B AH0 L'],
  "admission": ['AE0 D M IH1 SH AH0 N', 'AH0 D M IH1 SH AH0 N'],
  "admissions": ['AE0 D M IH1 SH AH0 N Z', 'AH0 D M IH1 SH AH0 N Z'],
  "admit": ['AH0 D M IH1 T'],
  "admits": ['AH0 D M IH1 T S'],
  "admittance": ['AH0 D M IH1 T AH0 N S'],
  "admitted": ['AH0 D M IH1 T IH0 D'],
  "admittedly": ['AE0 D M IH1 T IH0 D L IY0'],
  "admitting": ['AE0 D M IH1 T IH0 NG', 'AH0 D M IH1 T IH0 NG'],
  "admonish": ['AE0 D M AA1 N IH0 SH'],
  "admonished": ['AH0 D M AA1 N IH0 SH T'],
  "admonishes": ['AE0 D M AA1 N IH0 SH IH0 Z'],
  "admonishing": ['AE0 D M AA1 N IH0 SH IH0 NG'],
  "admonishment": ['AE0 D M AA1 N IH0 SH M EH0 N T'],
  "admonition": ['AE2 D M AH0 N IH1 SH AH0 N'],
  "admonitions": ['AE2 D M AH0 N IH1 SH AH0 N Z'],
  "admonitory": ['AE2 D M AO1 N IH0 T AO2 R IY0'],
  "adnan": ['AE1 D N AH0 N'],
  "adney": ['AE1 D N IY0'],
  "ado": ['AH0 D UW1'],
  "adobe": ['AH0 D OW1 B IY0'],
  "adobe's": ['AH0 D OW1 B IY0 Z'],
  "adolescence": ['AE2 D AH0 L EH1 S AH0 N S', 'AE2 D OW0 L EH1 S AH0 N S'],
  "adolescent": ['AE2 D AH0 L EH1 S AH0 N T', 'AE2 D OW0 L EH1 S AH0 N T'],
  "adolescents": ['AE2 D AH0 L EH1 S AH0 N T S', 'AE2 D OW0 L EH1 S AH0 N T S'],
  "adolf": ['EY1 D AA0 L F'],
  "adolf's": ['EY1 D AA0 L F S'],
  "adolfo": ['AH0 D AA1 L F OW2'],
  "adolph": ['EY1 D AO0 L F'],
  "adolpha": ['AA0 D OW1 L F AH0'],
  "adolphson": ['AE1 D OW0 L F S AH0 N'],
  "adolphus": ['AH0 D AO1 L F AH0 S'],
  "adon": ['AA0 D AO1 N'],
  "adonia": ['AA0 D OW1 N IY0 AH0'],
  "adonis": ['AH0 D AA1 N AH0 S'],
  "adopt": ['AH0 D AA1 P T'],
  "adoptable": ['AH0 D AA1 P T AH0 B AH0 L'],
  "adopted": ['AH0 D AA1 P T IH0 D'],
  "adoptee": ['AH0 D AA1 P T IY1'],
  "adoptees": ['AH0 D AA1 P T IY1 Z'],
  "adopter": ['AH0 D AA1 P T ER0'],
  "adopters": ['AH0 D AA1 P T ER0 Z'],
  "adopting": ['AH0 D AA1 P T IH0 NG'],
  "adoption": ['AH0 D AA1 P SH AH0 N'],
  "adoptions": ['AH0 D AA1 P SH AH0 N Z'],
  "adoptive": ['AH0 D AA1 P T IH0 V'],
  "adopts": ['AH0 D AA1 P T S'],
  "ador": ['AA0 D AO1 R'],
  "adora": ['AA0 D AO1 R AH0'],
  "adorabelle": ['AE1 D ER0 AH0 B AH0 L'],
  "adorable": ['AH0 D AO1 R AH0 B AH0 L'],
  "adoration": ['AE2 D ER0 EY1 SH AH0 N'],
  "adore": ['AH0 D AO1 R'],
  "adored": ['AH0 D AO1 R D'],
  "adoree": ['AE0 D ER0 IY1'],
  "adorees": ['AE0 D ER0 IY1 Z'],
  "adores": ['AH0 D AO1 R Z'],
  "adoring": ['AH0 D AO1 R IH0 NG'],
  "adorn": ['AH0 D AO1 R N'],
  "adorna": ['AA0 D AO1 R N AH0'],
  "adorned": ['AH0 D AO1 R N D'],
  "adorning": ['AH0 D AO1 R N IH0 NG'],
  "adornment": ['AH0 D AO1 R N M AH0 N T'],
  "adorno": ['AA0 D AO1 R N OW0'],
  "adorns": ['AH0 D AO1 R N Z'],
  "adra": ['EY1 D R AH0'],
  "adragna": ['AA0 D R AA1 G N AH0'],
  "adrda": ['EY1 D ER0 D AH0'],
  "adrea": ['AA1 D R IY0 AH0'],
  "adrenal": ['AH0 D R IY1 N AH0 L'],
  "adrenalin": ['AH0 D R EH1 N AH0 L IH0 N'],
  "adrenaline": ['AH0 D R EH1 N AH0 L AH0 N'],
  "adrenergic": ['AH0 D R EH0 N EH1 R JH IH0 K'],
  "adria": ['AA1 D R IY0 AH0'],
  "adrian": ['EY1 D R IY0 AH0 N'],
  "adriana": ['EY2 D R IY0 AE1 N AH0'],
  "adriance": ['AA0 D R IY1 AH0 N S'],
  "adriano": ['AA0 D R IY0 AA1 N OW0'],
  "adriatic": ['EY2 D R IY0 AE1 T IH0 K'],
  "adriel": ['AH0 D R IY1 L'],
  "adrienne": ['AA0 D R IY0 EH1 N'],
  "adrift": ['AH0 D R IH1 F T'],
  "adroit": ['AH0 D R OY1 T'],
  "adroitly": ['AH0 D R OY1 T L IY0'],
  "ads": ['AE1 D Z'],
  "ads'": ['AE1 D Z'],
  "adsit": ['AE1 D S IH0 T'],
  "adsl": ['EY2 D IY2 EH2 S EH1 L'],
  "adsorption": ['AH0 D S AO1 R P SH AH0 N'],
  "adss": ['AE1 D S', 'EY1 D IY1 EH1 S EH1 S'],
  "adstar": ['AE1 D S T AA0 R'],
  "adsteam": ['AE1 D S T IY0 M'],
  "adtec": ['AE1 D T EH2 K'],
  "adulate": ['AE1 JH AH0 L EY2 T'],
  "adulation": ['AE2 JH AH0 L EY1 SH AH0 N'],
  "aduliadae": ['AH0 D UW2 L IY0 AA1 D EY0'],
  "adult": ['AH0 D AH1 L T', 'AE1 D AH0 L T'],
  "adulterate": ['AH0 D AH1 L T ER0 EY2 T'],
  "adulterated": ['AH0 D AH1 L T ER0 EY2 T IH0 D'],
  "adulterates": ['AH0 D AH1 L T ER0 EY2 T S'],
  "adulteration": ['AH0 D AH2 L T ER0 EY1 SH AH0 N'],
  "adulterer": ['AH0 D AH1 L T ER0 ER0'],
  "adulterers": ['AH0 D AH1 L T ER0 ER0 Z'],
  "adulterous": ['AH0 D AH1 L T ER0 AH0 S'],
  "adultery": ['AH0 D AH1 L T ER0 IY0'],
  "adulthood": ['AH0 D AH1 L T HH UH2 D'],
  "adults": ['AH0 D AH1 L T S', 'AE1 D AH0 L T S'],
  "adults'": ['AH0 D AH1 L T S', 'AE1 D AH0 L T S'],
  "adusdur": ['AE1 D AH0 S D ER0'],
  "advacare": ['AE1 D V AH0 K EH2 R'],
  "advalue": ['AE1 D V AH0 L UW2'],
  "advance": ['AH0 D V AE1 N S'],
  "advance's": ['AH0 D V AE1 N S AH0 Z', 'AH0 D V AE1 N S IH0 Z'],
  "advanced": ['AH0 D V AE1 N S T'],
  "advancement": ['AH0 D V AE1 N S M AH0 N T'],
  "advancements": ['AH0 D V AE1 N S M AH0 N T S'],
  "advancer": ['AH0 D V AE1 N S ER0'],
  "advancers": ['AH0 D V AE1 N S ER0 Z'],
  "advances": ['AH0 D V AE1 N S AH0 Z', 'AH0 D V AE1 N S IH0 Z'],
  "advancing": ['AH0 D V AE1 N S IH0 NG'],
  "advani": ['AE0 D V AA1 N IY0'],
  "advanta": ['AE0 D V AE1 N T AH0', 'AH0 D V AE1 N T AH0'],
  "advanta's": ['AE0 D V AE1 N T AH0 Z', 'AH0 D V AE1 N T AH0 Z'],
  "advantage": ['AE0 D V AE1 N T IH0 JH'],
  "advantaged": ['AE0 D V AE1 N T IH0 JH D'],
  "advantageous": ['AE2 D V AH0 N T EY1 JH AH0 S'],
  "advantages": ['AE0 D V AE1 N T IH0 JH IH0 Z'],
  "advantest": ['AE0 D V AE1 N T AH0 S T'],
  "advection": ['AE0 D V EH1 K SH AH0 N'],
  "advent": ['AE1 D V EH2 N T'],
  "adventist": ['AE1 D V EH2 N T IH0 S T'],
  "adventists": ['AE1 D V EH2 N T IH0 S T S'],
  "adventure": ['AE0 D V EH1 N CH ER0', 'AH0 D V EH1 N CH ER0'],
  "adventurer": ['AE0 D V EH1 N CH ER0 ER0', 'AH0 D V EH1 N CH ER0 ER0'],
  "adventurers": ['AE0 D V EH1 N CH ER0 ER0 Z', 'AH0 D V EH1 N CH ER0 ER0 Z'],
  "adventures": ['AE0 D V EH1 N CH ER0 Z'],
  "adventuresome": ['AE0 D V EH1 N CH ER0 S AH0 M'],
  "adventurism": ['AE0 D V EH1 N CH ER0 IH2 Z AH0 M'],
  "adventurous": ['AE0 D V EH1 N CH ER0 AH0 S', 'AH0 D V EH1 N CH ER0 AH0 S'],
  "adverb": ['AE1 D V ER0 B'],
  "adverbial": ['AE0 D V ER1 B IY0 AH0 L'],
  "adverbs": ['AE1 D V ER0 B Z'],
  "adversarial": ['AE2 D V ER0 S EH1 R IY0 AH0 L'],
  "adversaries": ['AE1 D V ER0 S EH2 R IY0 Z'],
  "adversary": ['AE1 D V ER0 S EH2 R IY0'],
  "adverse": ['AE0 D V ER1 S', 'AE1 D V ER2 S', 'AH0 D V ER1 S'],
  "adversely": ['AE0 D V ER1 S L IY0'],
  "adversity": ['AE0 D V ER1 S IH0 T IY2', 'AH0 D V ER1 S IH0 T IY0'],
  "advert": ['AE1 D V ER0 T'],
  "advertise": ['AE1 D V ER0 T AY2 Z'],
  "advertised": ['AE1 D V ER0 T AY2 Z D', 'AE2 D V ER0 T AY1 Z D'],
  "advertisement": ['AE0 D V ER1 T AH0 Z M AH0 N T', 'AE2 D V ER0 T AY1 Z M AH0 N T'],
  "advertisements": ['AE1 D V ER0 T AY2 Z M AH0 N T S'],
  "advertiser": ['AE1 D V ER0 T AY2 Z ER0'],
  "advertiser's": ['AE1 D V ER0 T AY2 Z ER0 Z'],
  "advertisers": ['AE1 D V ER0 T AY2 Z ER0 Z'],
  "advertisers'": ['AE1 D V ER2 T AY2 Z ER0 Z'],
  "advertises": ['AE1 D V ER0 T AY2 Z IH0 Z'],
  "advertising": ['AE1 D V ER0 T AY2 Z IH0 NG'],
  "advertising's": ['AE1 D V ER0 T AY2 Z IH0 NG Z'],
  "advertorial": ['AE2 D V ER0 T AO1 R IY0 AH0 L'],
  "advertorials": ['AE2 D V ER0 T AO1 R IY0 AH0 L Z'],
  "advest": ['AE1 D V EH0 S T'],
  "advice": ['AE0 D V AY1 S', 'AH0 D V AY1 S'],
  "advil": ['AE1 D V IH2 L'],
  "advil's": ['AE1 D V IH2 L Z'],
  "advisability": ['AE2 D V AY2 Z AH0 B IH1 L IH0 T IY0'],
  "advisable": ['AH0 D V AY1 Z AH0 B AH0 L'],
  "advise": ['AE0 D V AY1 Z', 'AH0 D V AY1 Z'],
  "advised": ['AE0 D V AY1 Z D', 'AH0 D V AY1 Z D'],
  "advisedly": ['AE0 D V AY1 Z AH0 D L IY0', 'AH0 D V AY1 Z AH0 D L IY0'],
  "advisement": ['AH0 D V AY1 Z M AH0 N T'],
  "adviser": ['AE0 D V AY1 Z ER0'],
  "adviser's": ['AH0 D V AY1 Z ER0 Z'],
  "advisers": ['AE0 D V AY1 Z ER0 Z'],
  "advisers'": ['AE2 D V AY1 Z ER0 Z'],
  "advises": ['AE0 D V AY1 Z IH0 Z'],
  "advising": ['AE0 D V AY1 Z IH0 NG'],
  "advisor": ['AE0 D V AY1 Z ER0'],
  "advisories": ['AH0 D V AY1 Z ER0 IY0 Z'],
  "advisors": ['AE0 D V AY1 Z ER0 Z'],
  "advisory": ['AE0 D V AY1 Z ER0 IY0'],
  "advo": ['AE1 D V OW0'],
  "advocacy": ['AE1 D V AH0 K AH0 S IY0'],
  "advocate": ['AE1 D V AH0 K AH0 T', 'AE1 D V AH0 K EY2 T'],
  "advocate's": ['AE1 D V AH0 K AH0 T S'],
  "advocated": ['AE1 D V AH0 K EY2 T IH0 D'],
  "advocates": ['AE1 D V AH0 K AH0 T S', 'AE1 D V AH0 K EY2 T S'],
  "advocating": ['AE1 D V AH0 K EY2 T IH0 NG'],
  "advocation": ['AE2 D V AH0 K EY1 SH AH0 N'],
  "adweek": ['AE1 D W IY0 K'],
  "adwell": ['AH0 D W EH1 L'],
  "adwords": ['AE1 D W ER2 D Z'],
  "ady": ['EY1 D IY0'],
  "adz": ['AE1 D Z'],
  "ae": ['EY1'],
  "aegean": ['IH0 JH IY1 AH0 N'],
  "aegerter": ['EH1 G ER0 T ER0'],
  "aegis": ['IY1 JH AH0 S'],
  "aegon": ['EY1 G AA0 N'],
  "aeltus": ['AE1 L T AH0 S'],
  "aeneas": ['AE1 N IY0 AH0 S'],
  "aeneid": ['AH0 N IY1 IH0 D'],
  "aeolus": ['EH1 OW0 L UH0 S'],
  "aequitron": ['EY1 K W IH0 T R AA0 N'],
  "aer": ['EH1 R', 'EY1 IY1 AA1 R'],
  "aerial": ['EH1 R IY0 AH0 L'],
  "aerials": ['EH1 R IY0 AH0 L Z'],
  "aerie": ['EH1 R IY0'],
  "aerien": ['EH1 R IY0 AH0 N'],
  "aeriens": ['EH1 R IY0 AH0 N Z'],
  "aeritalia": ['EH2 R IH0 T AE1 L Y AH0'],
  "aero": ['EH1 R OW0'],
  "aerobatic": ['EH2 R AH0 B AE1 T IH0 K'],
  "aerobatics": ['EH2 R AH0 B AE1 T IH0 K S'],
  "aerobic": ['EH0 R OW1 B IH0 K'],
  "aerobically": ['EH0 R OW1 B IH0 K L IY0'],
  "aerobics": ['ER0 OW1 B IH0 K S'],
  "aerodrome": ['EH1 R AH0 D R OW2 M'],
  "aerodromes": ['EH1 R AH0 D R OW2 M Z'],
  "aerodynamic": ['EH2 R OW0 D AY0 N AE1 M IH0 K'],
  "aerodynamically": ['EH2 R OW0 D AY0 N AE1 M IH0 K L IY0'],
  "aerodynamicist": ['EH2 R OW0 D AY0 N AE1 M IH0 S IH0 S T'],
  "aerodynamicists": ['EH2 R OW0 D AY0 N AE1 M IH0 S IH0 S T S'],
  "aerodynamics": ['EH2 R OW0 D AY0 N AE1 M IH0 K S'],
  "aerodyne": ['EH1 R AH0 D AY2 N'],
  "aerodyne's": ['EH1 R AH0 D AY2 N Z'],
  "aeroflot": ['EH1 R OW0 F L AA2 T'],
  "aeroflot's": ['EH1 R OW0 F L AA2 T S'],
  "aerogenosa": ['EH1 R AH0 JH AH0 N OW2 S AH0'],
  "aerojet": ['EH1 R OW0 JH EH2 T'],
  "aerojets": ['EH1 R OW0 JH EH2 T S'],
  "aerolift": ['EH1 R OW0 L IH2 F T'],
  "aerolifts": ['EH1 R OW0 L IH2 F T S'],
  "aerolineas": ['EH2 R OW0 L IH1 N IY0 AH0 S'],
  "aeromar": ['EH1 R AH0 M AA0 R', 'EH1 R OW0 M AA0 R'],
  "aeromexico": ['EH2 R OW0 M EH1 K S IH0 K OW2'],
  "aeromexico's": ['EH2 R OW0 M EH1 K S IH0 K OW2 Z'],
  "aeronautic": ['EH2 R OW0 N AA1 T AH0 K'],
  "aeronautical": ['EH2 R OW0 N AA1 T AH0 K AH0 L', 'EH2 R AH0 N AA1 T AH0 K AH0 L'],
  "aeronauticas": ['EH2 R OW0 N AO1 T IH0 K AH0 S'],
  "aeronautics": ['EH2 R AH0 N AO1 T IH0 K S'],
  "aeroperu": ['EH2 R OW0 P EY0 R UW1'],
  "aeroperu's": ['EH2 R OW0 P EY0 R UW1 Z'],
  "aeroquip": ['EH1 R AH0 K W IH2 P'],
  "aeroscience": ['EH2 R OW0 S AY1 AH0 S'],
  "aerosmith": ['EH1 R OW0 S M IH2 TH'],
  "aerosmith's": ['EH1 R OW0 S M IH2 TH S'],
  "aerosol": ['EH1 R AH0 S AA2 L'],
  "aerosols": ['EH1 R AH0 S AA2 L Z'],
  "aerospace": ['EH1 R OW0 S P EY2 S'],
  "aerospace's": ['EH1 R OW0 S P EY2 S IH0 Z'],
  "aerospatiale": ['EH2 R OW0 S P AA2 S IY0 AA1 L'],
  "aerospatiale's": ['EH2 R OW0 S P AA2 S IY0 AA1 L Z'],
  "aerostar": ['EH1 R OW0 S T AA2 R'],
  "aerostar's": ['EH1 R OW0 S T AA2 R Z'],
  "aerostars": ['EH1 R OW0 S T AA2 R Z'],
  "aerostat": ['EH1 R OW0 S T AE2 T'],
  "aerostat's": ['EH1 R OW0 S T AE2 T S'],
  "aerostats": ['EH1 R OW0 S T AE2 T S'],
  "aerotech": ['EH1 R OW0 T EH2 K'],
  "aerovias": ['EH0 R OW0 V IY1 AH0 Z'],
  "aerts": ['EH1 R T S'],
  "aeschelus": ['EH1 S K AH0 L AH0 S'],
  "aeschliman": ['EH1 SH L IY0 M AH0 N'],
  "aesop": ['IY1 S AA2 P'],
  "aesop's": ['IY1 S AA2 P S'],
  "aesthenopia": ['AE0 S TH AH0 N AO1 P Y AH0'],
  "aesthete": ['EH1 S TH IY0 T'],
  "aesthete's": ['EH1 S TH IY0 T S'],
  "aesthetes": ['EH1 S TH IY0 T S'],
  "aesthetic": ['EH0 S TH EH1 T IH0 K'],
  "aesthetically": ['EH0 S TH EH1 T IH0 K L IY0'],
  "aesthetics": ['EH0 S TH EH1 T IH0 K S'],
  "aetna": ['EH1 T N AH0'],
  "aetna's": ['EH1 T N AH0 Z'],
  "afanasyev": ['AE2 F AH0 N EY1 S IY0 EH0 V'],
  "afar": ['AH0 F AA1 R'],
  "affable": ['AE1 F AH0 B AH0 L'],
  "affably": ['AE1 F AH0 B L IY0'],
  "affair": ['AH0 F EH1 R'],
  "affair's": ['AH0 F EH1 R Z'],
  "affairs": ['AH0 F EH1 R Z'],
  "affect": ['AH0 F EH1 K T'],
  "affectation": ['AE2 F EH0 K T EY1 SH AH0 N'],
  "affected": ['AH0 F EH1 K T IH0 D'],
  "affecting": ['AH0 F EH1 K T IH0 NG'],
  "affection": ['AH0 F EH1 K SH AH0 N'],
  "affectionate": ['AH0 F EH1 K SH AH0 N AH0 T', 'AH0 F EH1 K SH AH0 N IH0 T'],
  "affectionately": ['AH0 F EH1 K SH AH0 N AH0 T L IY0'],
  "affections": ['AH0 F EH1 K SH AH0 N Z'],
  "affective": ['AH0 F EH1 K T IH0 V'],
  "affectively": ['AH0 F EH1 K T IH0 V L IY0'],
  "affects": ['AH0 F EH1 K T S'],
  "affeldt": ['AE1 F IH0 L T'],
  "afferent": ['AE1 F ER0 AH0 N T'],
  "affiant": ['AE1 F IY0 AH0 N T'],
  "affidavit": ['AE2 F AH0 D EY1 V AH0 T'],
  "affidavits": ['AE2 F IH0 D EY1 V IH0 T S'],
  "affiliate": ['AH0 F IH1 L IY0 EY2 T', 'AH0 F IH1 L IY0 AH0 T'],
  "affiliate's": ['AH0 F IH1 L IY0 EY2 T S'],
  "affiliated": ['AH0 F IH1 L IY0 EY2 T IH0 D'],
  "affiliated's": ['AH0 F IH1 L IY0 EY2 T IH0 D Z'],
  "affiliates": ['AH0 F IH1 L IY0 AH0 T S', 'AH0 F IH1 L IY0 EY2 T S'],
  "affiliates'": ['AH0 F IH1 L IY0 IH0 T S'],
  "affiliating": ['AH0 F IH1 L IY0 EY2 T IH0 NG'],
  "affiliation": ['AH0 F IH2 L IY0 EY1 SH AH0 N'],
  "affiliations": ['AH0 F IH2 L IY0 EY1 SH AH0 N Z'],
  "affine": ['AH0 F AY1 N'],
  "affined": ['AH0 F AY1 N D'],
  "affines": ['AH0 F AY1 N Z'],
  "affining": ['AH0 F AY1 N IH0 NG'],
  "affinities": ['AH0 F IH1 N AH0 T IY0 Z'],
  "affinity": ['AH0 F IH1 N AH0 T IY0', 'AH0 F IH1 N IH0 T IY0'],
  "affirm": ['AH0 F ER1 M'],
  "affirmation": ['AE2 F ER0 M EY1 SH AH0 N'],
  "affirmations": ['AE2 F ER0 M EY1 SH AH0 N Z'],
  "affirmative": ['AH0 F ER1 M AH0 T IH0 V'],
  "affirmatively": ['AH0 F ER1 M AH0 T IH0 V L IY0'],
  "affirmed": ['AH0 F ER1 M D'],
  "affirming": ['AH0 F ER1 M IH0 NG'],
  "affirms": ['AH0 F ER1 M Z'],
  "affix": ['AE1 F IH0 K S', 'AH0 F IH1 K S'],
  "affixed": ['AH0 F IH1 K S T'],
  "affixes": ['AE1 F IH0 K S IH0 Z', 'AH0 F IH1 K S IH0 Z'],
  "affixing": ['AH0 F IH1 K S IH0 NG'],
  "affleck": ['AE1 F L IH0 K'],
  "afflerbach": ['AE1 F L ER0 B AA2 K'],
  "afflict": ['AH0 F L IH1 K T'],
  "afflicted": ['AH0 F L IH1 K T IH0 D'],
  "afflicting": ['AH0 F L IH1 K T IH0 NG'],
  "affliction": ['AH0 F L IH1 K SH AH0 N'],
  "afflictions": ['AH0 F L IH1 K SH AH0 N Z'],
  "afflicts": ['AH0 F L IH1 K T S'],
  "afflik": ['AE2 F L IH1 K'],
  "affluence": ['AE1 F L UW0 AH0 N S'],
  "affluent": ['AE1 F L UW0 AH0 N T'],
  "affolter": ['AE1 F OW0 L T ER0'],
  "afford": ['AH0 F AO1 R D'],
  "affordability": ['AH0 F AO2 R D AH0 B IH1 L AH0 T IY0'],
  "affordable": ['AH0 F AO1 R D AH0 B AH0 L'],
  "affordably": ['AH0 F AO1 R D AH0 B L IY0'],
  "afforded": ['AH0 F AO1 R D AH0 D'],
  "affording": ['AH0 F AO1 R D IH0 NG'],
  "affords": ['AH0 F AO1 R D Z'],
  "affricate": ['AE1 F R AH0 K AH0 T'],
  "affricates": ['AE1 F R AH0 K AH0 T S'],
  "affrication": ['AE2 F R AH0 K EY1 SH AH0 N'],
  "affront": ['AH0 F R AH1 N T'],
  "affronted": ['AH0 F R AH1 N T IH0 D'],
  "affronting": ['AH0 F R AH1 N T IH0 NG'],
  "affronts": ['AH0 F R AH1 N T S'],
  "affymax": ['AE1 F IY0 M AE2 K S'],
  "afghan": ['AE1 F G AE2 N'],
  "afghani": ['AE0 F G AA1 N IY0'],
  "afghani's": ['AE0 F G AE1 N IY0 Z'],
  "afghanis": ['AE0 F G AE1 N IY0 Z'],
  "afghanistan": ['AE0 F G AE1 N AH0 S T AE2 N'],
  "afghanistan's": ['AE0 F G AE1 N AH0 S T AE2 N Z'],
  "afghanistans": ['AE0 F G AE1 N AH0 S T AE2 N Z'],
  "afghans": ['AE1 F G AE2 N Z'],
  "afheldt": ['AE1 F EH2 L T'],
  "aficionado": ['AH0 F IY2 SH Y AH0 N AA1 D OW2'],
  "aficionados": ['AH0 F IH2 SH AH0 N AA1 D OW0 Z'],
  "afield": ['AH0 F IY1 L D'],
  "afire": ['AH0 F AY1 R'],
  "afl": ['EY2 EH2 F EH1 L'],
  "aflame": ['AH0 F L EY1 M'],
  "aflatoxin": ['AE2 F L AH0 T AA1 K S IH0 N'],
  "afloat": ['AH0 F L OW1 T'],
  "aflutter": ['AH0 F L AH1 T ER0'],
  "afmed": ['AE1 F M EH0 D'],
  "afonso": ['AH0 F AA1 N S OW0'],
  "afoot": ['AH0 F UH1 T'],
  "aforementioned": ['AH0 F AO1 R M EH2 N SH AH0 N D'],
  "aforesaid": ['AH0 F AO1 R S EH2 D'],
  "aforethought": ['AH0 F AO1 R TH AA2 T'],
  "afoul": ['AH0 F AW1 L'],
  "afraid": ['AH0 F R EY1 D'],
  "afresh": ['AH0 F R EH1 SH'],
  "africa": ['AE1 F R AH0 K AA0', 'AE1 F R IH0 K AH0', 'AE1 F ER0 K AH0'],
  "africa's": ['AE1 F R AH0 K AH0 Z', 'AE1 F R IH0 K AH0 Z'],
  "african": ['AE1 F R AH0 K AH0 N', 'AE1 F R IH0 K AH0 N'],
  "african-american": ['AE1 F R AH0 K AH0 N AH0 M EH1 R AH0 K AH0 N'],
  "african-americans": ['AE1 F R IH0 K AH0 N AH0 M EH1 R IH0 K AH0 N Z'],
  "africanist": ['AE1 F R IH0 K AH0 N IH0 S T'],
  "africanist's": ['AE1 F R IH0 K AA0 N IH0 S T S'],
  "africanists": ['AE1 F R IH0 K AA0 N IH0 S T S'],
  "africanize": ['AE1 F R AH0 K AH0 N AY2 Z'],
  "africanized": ['AE1 F R AH0 K AH0 N AY2 Z D'],
  "africans": ['AE1 F R AH0 K AH0 N Z', 'AE1 F R IH0 K AH0 N Z'],
  "afridi": ['AH0 F R IY1 D IY0'],
  "afrikaans": ['AE2 F R AH0 K AA1 N Z'],
  "afrikaner": ['AE2 F R AH0 K AA1 N ER0'],
  "afrikanerdom": ['AE2 F R AH0 K AA1 N ER0 D AH0 M'],
  "afrikaners": ['AE2 F R IH0 K AA1 N ER0 Z'],
  "afro": ['AE1 F R OW0'],
  "afroamerican": ['AE1 F R OW0 AH0 M EH1 R AH0 K AH0 N'],
  "afroamericans": ['AE1 F R OW0 AH0 M EH1 R AH0 K AH0 N Z'],
  "afrocentric": ['AE1 F R OW0 S EH1 N T R IH0 K'],
  "afsane": ['AA0 F S AA1 N EY2'],
  "afsane's": ['AA0 F S AA1 N EY2 Z'],
  "afshar": ['AE1 F SH ER0'],
  "afshar's": ['AE1 F SH ER0 Z'],
  "aft": ['AE1 F T'],
  "after": ['AE1 F T ER0'],
  "after-room": ['AE1 F T ER0 R UW2 M'],
  "afterall": ['AE1 F T ER0 AA2 L'],
  "afterburner": ['AE1 F T ER0 B ER2 N ER0'],
  "afterburners": ['AE1 F T ER0 B ER2 N ER0 Z'],
  "aftereffect": ['AE1 F T ER0 AH0 F EH2 K T'],
  "aftereffects": ['AE1 F T ER0 AH0 F EH2 K T S'],
  "afterglow": ['AE1 F T ER0 G L OW2'],
  "afterglows": ['AE1 F T ER0 G L OW2 Z'],
  "afterhours": ['AE1 F T ER0 HH AW2 ER0 Z'],
  "afterimage": ['AE1 F T ER0 IH2 M IH0 JH'],
  "afterimages": ['AE1 F T ER0 IH2 M IH0 JH AH0 Z'],
  "afterlife": ['AE1 F T ER0 L AY2 F'],
  "afterlives": ['AE1 F T ER0 L AY2 V Z'],
  "aftermarket": ['AE1 F T ER0 M AA2 R K IH0 T'],
  "aftermarkets": ['AE1 F T ER0 M AA2 R K IH0 T S'],
  "aftermath": ['AE1 F T ER0 M AE2 TH'],
  "aftermaths": ['AE1 F T ER0 M AE2 TH S'],
  "afternoon": ['AE2 F T ER0 N UW1 N'],
  "afternoon's": ['AE2 F T ER0 N UW1 N Z'],
  "afternoons": ['AE2 F T ER0 N UW1 N Z'],
  "aftershave": ['AE2 F T ER0 SH EY1 V'],
  "aftershaves": ['AE2 F T ER0 SH EY1 V Z'],
  "aftershock": ['AE1 F T ER0 SH AA2 K'],
  "aftershocks": ['AE1 F T ER0 SH AA2 K S'],
  "aftertaste": ['AE1 F T ER0 T EY2 S T'],
  "aftertastes": ['AE1 F T ER0 T EY2 S T S'],
  "aftertax": ['AE1 F T ER0 T AE2 K S'],
  "afterthought": ['AE1 F T ER0 TH AA2 T', 'AE1 F T ER0 TH AO2 T'],
  "afterthoughts": ['AE1 F T ER0 TH AA2 T S', 'AE1 F T ER0 TH AO2 T S'],
  "afterward": ['AE1 F T ER0 W ER0 D'],
  "afterwards": ['AE1 F T ER0 W ER0 D Z'],
  "afula": ['AH0 F UW1 L AH0'],
  "afula's": ['AH0 F UW1 L AH0 Z'],
  "ag": ['AE1 G', 'EY1 G IY1'],
  "ag's": ['AE1 G Z'],
  "aga": ['AA1 G AH0'],
  "agache": ['AE1 G AE0 CH'],
  "agache's": ['AE1 G AE0 CH AH0 Z'],
  "agaches": ['AE1 G AE0 CH AH0 Z'],
  "again": ['AH0 G EH1 N', 'AH0 G EY1 N'],
  "against": ['AH0 G EH1 N S T', 'AH0 G EY1 N S T'],
  "agamemnon": ['AE2 G AH0 M EH1 M N AA2 N'],
  "agamemnon's": ['AE2 G AH0 M EH1 M N AA2 N Z'],
  "agan": ['EY1 G AH0 N'],
  "agana": ['AH0 G AA1 N AH0'],
  "aganbegyan": ['AE2 G AH0 N B EH1 G Y AH0 N'],
  "agans": ['AA1 G AA0 N Z'],
  "agape": ['AH0 G EY1 P'],
  "agar": ['EY1 G ER0'],
  "agard": ['AE1 G ER0 D'],
  "agarwal": ['AA1 G AA0 R W AA0 L'],
  "agasp": ['AH0 G AE1 S P'],
  "agassi": ['AE1 G AH0 S IY0'],
  "agassiz": ['AH0 G AE1 S IH0 Z'],
  "agate": ['AE1 G AH0 T'],
  "agates": ['AE1 G AH0 T S'],
  "agatha": ['AE1 G AH0 TH AH0'],
  "agawam": ['AE1 G AH0 W AA2 M'],
  "agco": ['AE1 G K OW2'],
  "age": ['EY1 JH'],
  "age's": ['EY1 JH IH0 Z'],
  "aged": ['EY1 JH D', 'EY1 JH IH0 D'],
  "agee": ['EY1 JH IY1'],
  "agee's": ['EY1 JH IY0 Z'],
  "ageless": ['EY1 JH L AH0 S'],
  "agematsu": ['AE1 G AH0 M AE1 T S UW0'],
  "agence": ['AE1 JH AH0 N S'],
  "agencies": ['EY1 JH AH0 N S IY0 Z'],
  "agencies'": ['EY1 JH AH0 N S IY0 Z'],
  "agency": ['EY1 JH AH0 N S IY0'],
  "agency's": ['EY1 JH AH0 N S IY0 Z'],
  "agenda": ['AH0 JH EH1 N D AH0'],
  "agendas": ['AH0 JH EH1 N D AH0 Z'],
  "agent": ['EY1 JH AH0 N T'],
  "agent's": ['EY1 JH AH0 N T S'],
  "agents": ['EY1 JH AH0 N T S'],
  "agents'": ['EY1 JH AH0 N T S'],
  "ager": ['EY1 JH ER0'],
  "ageratum": ['AH0 JH EH1 R AH0 T AH0 M'],
  "ageratums": ['AH0 JH EH1 R AH0 T AH0 M Z'],
  "agers": ['EY1 JH ER0 Z'],
  "agers'": ['EY1 JH ER0 Z'],
  "agerton": ['EY1 G ER0 T AH0 N'],
  "ages": ['EY1 JH AH0 Z', 'EY1 JH IH0 Z'],
  "agfa": ['AE1 G F AH0'],
  "aggarwal": ['AH0 G AA1 R W AH0 L'],
  "aggie": ['AE1 G IY0'],
  "aggies": ['AE1 G IY0 Z'],
  "agglomerate": ['AH0 G L AA1 M ER0 EY2 T'],
  "agglomeration": ['AH0 G L AA2 M ER0 EY1 SH AH0 N'],
  "agglutinate": ['AH0 G L UW1 T IH0 N EY2 T'],
  "aggrandize": ['AH0 G R AE1 N D AY2 Z'],
  "aggrandizement": ['AE1 G R AH0 N D AY2 Z M AH0 N T', 'AH0 G R AE1 N D AY2 Z M AH0 N T'],
  "aggrandizing": ['AE1 G R AH0 N D AY2 Z IH0 NG', 'AH0 G R AE1 N D AY2 Z IH0 NG'],
  "aggravate": ['AE1 G R AH0 V EY2 T'],
  "aggravated": ['AE1 G R AH0 V EY2 T IH0 D'],
  "aggravates": ['AE1 G R AH0 V EY2 T S'],
  "aggravating": ['AE1 G R AH0 V EY2 T IH0 NG'],
  "aggravation": ['AE2 G R AH0 V EY1 SH AH0 N'],
  "aggregate": ['AE1 G R AH0 G AH0 T', 'AE1 G R AH0 G IH0 T', 'AE1 G R AH0 G EY0 T'],
  "aggregated": ['AE1 G R AH0 G EY2 T IH0 D'],
  "aggregates": ['AE1 G R AH0 G IH0 T S', 'AE1 G R AH0 G EY2 T S'],
  "aggregation": ['AE2 G R AH0 G EY1 SH AH0 N'],
  "aggress": ['AH0 G R EH1 S'],
  "aggression": ['AH0 G R EH1 SH AH0 N'],
  "aggressions": ['AH0 G R EH1 SH AH0 N Z'],
  "aggressive": ['AH0 G R EH1 S IH0 V'],
  "aggressively": ['AH0 G R EH1 S IH0 V L IY0'],
  "aggressiveness": ['AH0 G R EH1 S IH0 V N AH0 S'],
  "aggressivity": ['AH0 G R EH0 S IH1 V IH0 T IY0'],
  "aggressor": ['AH0 G R EH1 S ER0'],
  "aggressors": ['AH0 G R EH1 S ER0 Z'],
  "aggrey": ['AE0 G R EY1'],
  "aggrieve": ['AH0 G R IY1 V'],
  "aggrieved": ['AH0 G R IY1 V D'],
  "aggy": ['AE1 G IY0'],
  "aghast": ['AH0 G AE1 S T'],
  "aghazadeh": ['AE2 G AH0 Z AA1 D EH2'],
  "agie": ['AE1 G IY0'],
  "agildo": ['AH0 G IH1 L D OW0'],
  "agile": ['AE1 JH AH0 L'],
  "agilis": ['AH0 JH IH1 L AH0 S'],
  "agility": ['AH0 JH IH1 L AH0 T IY0'],
  "agin": ['AA0 JH IY1 N'],
  "agincourt": ['AE1 JH AH0 N K AO2 R T'],
  "aging": ['EY1 JH IH0 NG'],
  "agins": ['EY1 G IH0 N Z'],
  "agip": ['EY1 G IH0 P'],
  "agitate": ['AE1 JH AH0 T EY2 T'],
  "agitated": ['AE1 JH AH0 T EY2 T IH0 D'],
  "agitating": ['AE1 JH AH0 T EY2 T IH0 NG'],
  "agitation": ['AE2 JH AH0 T EY1 SH AH0 N'],
  "agitator": ['AE1 JH AH0 T EY2 T ER0'],
  "agitators": ['AE1 JH IH0 T EY2 T ER0 Z'],
  "agitprop": ['AE1 JH AH0 T P R AO2 P'],
  "agius": ['EY1 JH IY0 IH0 S'],
  "agleam": ['AH0 G L IY1 M'],
  "agler": ['AE1 G L ER0'],
  "aglitter": ['AH0 G L IH1 T ER0'],
  "aglo": ['AH0 G L OW1'],
  "aglow": ['AH0 G L OW1'],
  "agna": ['AE1 G N AA0'],
  "agne": ['AE1 G N IH0'],
  "agnella": ['AE2 G N EH1 L AA0'],
  "agnelli": ['AE1 G N EH2 L IY0'],
  "agnelli's": ['AE1 G N EH2 L IY0 Z'],
  "agnellis": ['AE1 G N EH2 L IY0 Z'],
  "agnello": ['AE2 G N EH1 L OW0'],
  "agner": ['AE1 G N ER0'],
  "agnes": ['AE1 G N IH0 S'],
  "agneta": ['AA0 G N EH1 T AH0'],
  "agnew": ['AE1 G N UW0', 'AE1 G N Y UW0'],
  "agnico": ['AE1 G N IH0 K OW2'],
  "agnor": ['AE1 G N ER0'],
  "agnos": ['AE1 G N OW0 S'],
  "agnosio": ['AE0 G N OW1 S IY0 OW0'],
  "agnostic": ['AE0 G N AA1 S T IH0 K'],
  "agnostically": ['AE0 G N AA1 S T IH0 K L IY2'],
  "agnostics": ['AE0 G N AA1 S T IH0 K S'],
  "ago": ['AH0 G OW1'],
  "agog": ['AH0 G AA1 G'],
  "agoglia": ['AH0 G AA1 G L IY0 AH0'],
  "agonies": ['AE1 G AH0 N IY0 Z'],
  "agonist": ['AE1 G AH0 N IH0 S T'],
  "agonists": ['AE1 G AH0 N IH0 S T S'],
  "agonize": ['AE1 G AH0 N AY2 Z'],
  "agonized": ['AE1 G AH0 N AY2 Z D'],
  "agonizes": ['AE1 G AH0 N AY2 Z IH0 Z'],
  "agonizing": ['AE1 G AH0 N AY0 Z IH0 NG'],
  "agonizingly": ['AE1 G AH0 N AY2 Z IH0 NG L IY2'],
  "agony": ['AE1 G AH0 N IY0'],
  "agora": ['AE1 G ER0 AH0'],
  "agoraphobia": ['AH0 G AO2 R AH0 F OW1 B IY0 AH0'],
  "agosta": ['AA0 G OW1 S T AH0'],
  "agostinelli": ['AA0 G OW0 S T IY0 N EH1 L IY0'],
  "agostini": ['AA0 G OW0 S T IY1 N IY0'],
  "agostino": ['AA0 G AO0 S T IY1 N OW0'],
  "agosto": ['AA0 G OW1 S T OW0'],
  "agoura": ['AH0 G UW1 R AH0'],
  "agouron": ['AH0 G UW1 R AA0 N'],
  "agra": ['AE1 G R AH0'],
  "agrarian": ['AH0 G R EH1 R IY0 AH0 N'],
  "agrarianism": ['AH0 G R EH1 R IY0 AH0 N IH2 Z AH0 M'],
  "agrawal": ['AH0 G R AE1 W AH0 L'],
  "agree": ['AH0 G R IY1'],
  "agreeable": ['AH0 G R IY1 AH0 B AH0 L'],
  "agreed": ['AH0 G R IY1 D'],
  "agreeing": ['AH0 G R IY1 IH0 NG'],
  "agreement": ['AH0 G R IY1 M AH0 N T'],
  "agreement's": ['AH0 G R IY1 M AH0 N T S'],
  "agreements": ['AH0 G R IY1 M AH0 N T S'],
  "agrees": ['AH0 G R IY1 Z'],
  "agregable": ['AH0 G R EH1 JH AH0 B AH0 L'],
  "agregious": ['AH0 G R IY1 JH IY0 AH0 S'],
  "agresource": ['AA1 G R EH0 S AO2 R S', 'AE1 G R AH0 S AO2 R S'],
  "agresta": ['AA0 G R EH1 S T AH0'],
  "agresti": ['AA0 G R EH1 S T IY0'],
  "agrexco": ['AA0 G R EH1 K S K OW0'],
  "agri": ['AE1 G R IY0'],
  "agribank": ['AE1 G R IH0 B AE2 NG K'],
  "agribusiness": ['AE1 G R AH0 B IH2 Z N AH0 S'],
  "agrico": ['AH0 G R IY1 K OW0'],
  "agricola": ['AE2 G R IH0 K OW1 L AH0'],
  "agricole": ['AE1 G R IH0 K OW2 L'],
  "agricultural": ['AE2 G R AH0 K AH1 L CH ER0 AH0 L', 'AE2 G R IH0 K AH1 L CH ER0 AH0 L'],
  "agriculturalist": ['AE2 G R AH0 K AH1 L CH ER0 AH0 L AH0 S T'],
  "agriculturally": ['AE2 G R IH0 K AH1 L CH ER0 AH0 L IY0', 'AE2 G R IH0 K AH1 L CH R AH0 L IY0'],
  "agriculture": ['AE1 G R IH0 K AH2 L CH ER0'],
  "agriculture's": ['AE1 G R IH0 K AH2 L CH ER0 Z'],
  "agrifuel": ['AE1 G R AH0 F Y UW2 L'],
  "agrifuels": ['AE1 G R AH0 F Y UW2 L Z'],
  "agrigenetics": ['AE2 G R AH0 JH AH0 N EH1 T IH0 K S'],
  "agrippa": ['AH0 G R IH1 P AH0'],
  "agrivisor": ['AE1 G R AH0 V AY2 Z ER0'],
  "agro": ['AE1 G R OW0'],
  "agrochemical": ['AE2 G R OW0 K EH1 M AH0 K AH0 L'],
  "agrochemicals": ['AE2 G R OW0 K EH1 M IH0 K AH0 L Z'],
  "agroindustrial": ['AE2 G R OW0 IH0 D AH1 S T R IY0 AH0 L'],
  "agrokomerc": ['AE1 G R AH0 K OW0 M ER2 K'],
  "agronomist": ['AH0 G R AA1 N AH0 M IH0 S T'],
  "agronomists": ['AH0 G R AA1 N AH0 M IH0 S T S'],
  "agronomy": ['AH0 G R AA1 N AH0 M IH2'],
  "agrosiand": ['AH0 G R OW1 S IY0 AH0 N D'],
  "aground": ['AH0 G R AW1 N D'],
  "agrusa": ['AA0 G R UW1 S AH0'],
  "agua": ['AA1 G W AH0'],
  "aguacate": ['AE1 G W AH0 K EY2 T'],
  "aguadilla": ['AA2 G W AH0 D IH1 L AH0'],
  "aguado": ['AA0 G W AA1 D OW0'],
  "aguanga": ['AH0 G W AA1 N G AH0'],
  "aguascalientes": ['AA2 G W AH0 S K AE0 L Y EH1 N T EH0 S'],
  "aguayo": ['AA0 G W EY1 OW0'],
  "agudelo": ['AA0 G UW0 D EY1 L OW0'],
  "ague": ['EY1 G Y UW2'],
  "aguero": ['AA0 G EH1 R OW0'],
  "aguiar": ['AA1 G W IY0 ER0'],
  "aguila": ['AA0 G W IY1 L AH0'],
  "aguilar": ['AE1 G AH0 L AA0 R'],
  "aguilera": ['AA0 G W IY0 L EH1 R AH0'],
  "aguillar": ['AE1 G AH0 L AA0 R'],
  "aguillard": ['AE1 G IH0 L ER0 D'],
  "aguillon": ['AA0 G W IY0 L AO1 N'],
  "aguilo": ['AH0 G W IY1 L OW0'],
  "aguinaga": ['AA0 G UW0 IY0 N AA1 G AH0'],
  "aguirra": ['AH0 G W IH1 R AH0'],
  "aguirra's": ['AH0 G W IH1 R AH0 Z'],
  "aguirre": ['AA0 G W IH1 R EY0', 'AH0 G W IH1 R EY0'],
  "aguirre's": ['AA0 G W IH1 R EY0 Z', 'AH0 G W IH1 R EY0 Z'],
  "agustin": ['AH0 G AO1 S T IH0 N'],
  "ah": ['AA1'],
  "aha": ['AA2 HH AA1'],
  "ahab": ['EY1 HH AE2 B'],
  "ahah": ['AA1 HH AA0'],
  "aharon": ['AE1 HH ER0 AA0 N'],
  "ahart": ['AH0 HH AA1 R T'],
  "ahasuerus": ['AH0 HH AE2 SH AH0 W EH1 R AH0 S'],
  "ahaulsie": ['AH0 HH AA1 L S IY0'],
  "ahead": ['AH0 HH EH1 D'],
  "ahearn": ['AH0 HH ER1 N'],
  "aherin": ['AA1 ER0 IH0 N'],
  "ahern": ['AH0 HH ER1 N'],
  "aherne": ['AH0 HH ER1 N'],
  "ahh": ['AA1'],
  "ahl": ['AA1 L'],
  "ahlberg": ['AA1 L B ER0 G'],
  "ahlborn": ['AA1 L B ER0 N'],
  "ahlen": ['AH0 L EY1 N', 'AA1 L AH0 N'],
  "ahlers": ['AA1 L ER0 Z'],
  "ahles": ['EY1 AH0 L Z'],
  "ahlf": ['AA1 L F'],
  "ahlgren": ['AA1 L G R EH0 N'],
  "ahlgrim": ['AA1 L G R IH0 M'],
  "ahlin": ['AA1 L IH0 N'],
  "ahlman": ['AA1 L M AH0 N'],
  "ahlquist": ['AA1 L K W IH2 S T'],
  "ahlquist's": ['AA1 L K W IH2 S T S'],
  "ahlquists": ['AA1 L K W IH2 S T S'],
  "ahlstrand": ['AA1 L S T R AH0 N D'],
  "ahlstrom": ['AA1 L S T R AH0 M'],
  "ahluwalia": ['AH0 L UW0 AA1 L Y AH0'],
  "ahmad": ['AA1 M AA0 D'],
  "ahmadi": ['AA0 M AA1 D IY0'],
  "ahmann": ['AA1 M AH0 N'],
  "ahmanson": ['AA1 M AH0 N S AH0 N'],
  "ahmanson's": ['AA1 M AH0 N S AH0 N Z'],
  "ahmansons": ['AA1 M AH0 N S AH0 N Z'],
  "ahmed": ['AA1 M AH0 D', 'AA1 M EH0 D'],
  "ahmeek": ['AH1 M IY0 K'],
  "ahmoudi": ['AA0 M UW1 D IY0'],
  "ahn": ['AE1 N'],
  "ahnell": ['AA0 N EH1 L'],
  "ahner": ['AA1 N ER0'],
  "aho": ['AA1 HH OW0'],
  "ahola": ['AE1 HH AH0 L AH0'],
  "ahold": ['AH0 HH OW1 L D'],
  "ahold's": ['AH0 HH OW1 L D Z'],
  "aholds": ['AH0 HH OW1 L D Z'],
  "ahonen": ['AH0 HH OW1 N AH0 N'],
  "ahoskie": ['AH0 HH AO1 S K IY0'],
  "ahoy": ['AH0 HH OY1'],
  "ahr": ['AA1 R'],
  "ahranat": ['AH0 R AA1 N AH0 T'],
  "ahren": ['AA1 R AH0 N'],
  "ahrendt": ['AA1 R IH0 N T'],
  "ahrens": ['AA1 R IH0 N Z'],
  "ahs": ['AA1 Z'],
  "ahsahka": ['AA0 S AA1 K AH0'],
  "ahuja": ['AA0 HH UW1 Y AH0'],
  "ahumada": ['AA0 Y UW0 M AA1 D AH0'],
  "ahwahnee": ['AH0 W AA1 N IY0'],
  "ai": ['AY1', 'EY1 AY1'],
  "aichele": ['AY1 K AH0 L'],
  "aicher": ['AY1 K ER0'],
  "aichi": ['AA0 IY1 CH IY0'],
  "aickin": ['EY1 K IH0 N'],
  "aid": ['EY1 D'],
  "aid's": ['EY1 D Z'],
  "aida": ['AY0 IY1 D AH0'],
  "aidan": ['EY1 D AA0 N'],
  "aide": ['EY1 D'],
  "aide's": ['EY1 D Z'],
  "aided": ['EY1 D AH0 D', 'EY1 D IH0 D'],
  "aiden": ['EY2 D EH1 N'],
  "aidenn": ['EY2 D EH1 N'],
  "aides": ['EY1 D Z'],
  "aides'": ['EY1 D Z'],
  "aidid": ['AY2 D IY1 D'],
  "aidid's": ['AY2 D IY1 D Z'],
  "aiding": ['EY1 D IH0 NG'],
  "aids": ['EY1 D Z'],
  "aiello": ['AY2 EH1 L OW0'],
  "aiesha": ['AY1 SH AH0'],
  "aigner": ['EY1 N ER0', 'EH0 N Y EY1', 'EY1 K N ER0'],
  "aiguebelle": ['AY1 G AH0 B EH2 L'],
  "aiken": ['EY1 K IH0 N'],
  "aikens": ['EY1 K IH0 N Z'],
  "aikey": ['EY1 K IY0'],
  "aikin": ['EY1 K IH0 N'],
  "aikins": ['AY1 K IH0 N Z'],
  "aikman": ['EY1 K M AH0 N'],
  "aikoff": ['AY1 K AO2 F'],
  "ail": ['EY1 L'],
  "ailee": ['EY1 L IY1'],
  "aileen": ['AY0 L IY1 N'],
  "ailene": ['AY0 L IY1 N'],
  "aileron": ['EY1 L ER0 AA2 N'],
  "ailerons": ['EY1 L ER0 AA2 N Z'],
  "ailes": ['AY1 L Z', 'EY1 L Z'],
  "ailey": ['EY1 L IY0'],
  "ailing": ['EY1 L IH0 NG'],
  "ailment": ['EY1 L M AH0 N T'],
  "ailments": ['EY1 L M AH0 N T S'],
  "ailor": ['EY1 L ER0'],
  "ails": ['EY1 L Z'],
  "ailsa": ['EY1 L S AH0'],
  "ailurophile": ['EY0 L UH1 R AH0 F AY0 L', 'EY0 L UH1 R AH0 F AY0 AH0 L'],
  "aim": ['EY1 M'],
  "aim's": ['EY1 M Z'],
  "aimal": ['AY1 M AH0 L'],
  "aiman": ['AY1 M AH0 N'],
  "aime": ['EY1 M'],
  "aimed": ['EY1 M D'],
  "aimee": ['EY1 M IY1'],
  "aimetti": ['EY0 M EH1 T IY0'],
  "aiming": ['EY1 M IH0 NG'],
  "aimless": ['EY1 M L AH0 S'],
  "aimlessly": ['EY1 M L AH0 S L IY0'],
  "aimone": ['EY1 M OW2 N'],
  "aims": ['EY1 M Z'],
  "ain't": ['EY1 N T'],
  "aine": ['EY1 N'],
  "ainge": ['EY1 NG', 'EY1 N JH'],
  "ainley": ['EY1 N L IY0'],
  "ainsberg": ['EY1 N Z B ER0 G'],
  "ainsley": ['EY1 N S L IY0'],
  "ainslie": ['EY1 N Z L IY0'],
  "ainsworth": ['EY1 N S W ER0 TH'],
  "ainsworth's": ['EY1 N Z W ER0 TH S'],
  "ainu": ['EY1 N UW2'],
  "aipac": ['AY1 P AE2 K'],
  "aipac's": ['AY1 P AE2 K S'],
  "air": ['EH1 R'],
  "air's": ['EH1 R Z'],
  "air-conditioning": ['EH1 R K AH0 N D IH2 SH AH0 N IH0 NG'],
  "air-force": ['EH1 R F AO0 R S'],
  "airbag": ['EH1 R B AE2 G'],
  "airbags": ['EH1 R B AE2 G Z'],
  "airbase": ['EH1 R B EY2 S'],
  "airbases": ['EH1 R B EY2 S IH0 S'],
  "airboat": ['EH1 R B OW0 T'],
  "airboats": ['EH1 R B OW0 T S'],
  "airborne": ['EH1 R B AO2 R N'],
  "airborne's": ['EH1 R B AO2 R N Z'],
  "airbus": ['EH1 R B AH0 S'],
  "airbus's": ['EH1 R B AH0 S IH0 Z'],
  "aircal": ['EH1 R K AA0 L'],
  "aircal's": ['EH1 R K AE2 L Z'],
  "airco": ['EH1 R K OW0'],
  "aircoa": ['EH2 R K OW1 AH0'],
  "aircondition": ['EH1 R K AH0 N D IH2 SH AH0 N'],
  "airconditioned": ['EH1 R K AH0 N D IH2 SH AH0 N D'],
  "airconditioner": ['EH1 R K AH0 N D IH2 SH AH0 N ER0'],
  "airconditioning": ['EH1 R K AH0 N D IH2 SH AH0 N IH0 NG'],
  "airconditions": ['EH1 R K AH0 N D IH2 SH AH0 N Z'],
  "aircraft": ['EH1 R K R AE2 F T'],
  "aircraft's": ['EH1 R K R AE2 F T S', 'EH1 R K R AE2 F S'],
  "aircrafts": ['EH1 R K R AE2 F T S', 'EH1 R K R AE2 F S'],
  "aircrew": ['EH1 R K R UW2'],
  "aird": ['EH1 R D'],
  "airdrop": ['EH1 R D R AA0 P'],
  "airdrops": ['EH1 R D R AA0 P S'],
  "aired": ['EH1 R D'],
  "airedale": ['EH1 R D EY2 L'],
  "airedales": ['EH1 R D EY2 L Z'],
  "aires": ['EH1 R IY0 Z'],
  "airey": ['EH1 R IY0'],
  "airfare": ['EH1 R F EH2 R'],
  "airfares": ['EH1 R F EH2 R Z'],
  "airfield": ['EH1 R F IY2 L D'],
  "airfields": ['EH1 R F IY2 L D Z'],
  "airflow": ['EH1 R F L OW0'],
  "airflows": ['EH1 R F L OW0 Z'],
  "airfoil": ['EH1 R F OY2 L'],
  "airfoils": ['EH1 R F OY2 L Z'],
  "airfone": ['EH1 R F OW2 N'],
  "airfones": ['EH1 R F OW2 N Z'],
  "airforce": ['EH1 R F AO0 R S'],
  "airforces": ['EH1 R F AO0 R S IH0 Z'],
  "airframe": ['EH1 R F R EY2 M'],
  "airframes": ['EH1 R F R EY2 M Z'],
  "airfreight": ['EH1 R F R EY2 T'],
  "airgas": ['EH1 R G AE2 S'],
  "airglow": ['EH1 R G L OW2'],
  "airhart": ['EH1 R HH AA0 R T'],
  "airhead": ['EH1 R HH EH2 D'],
  "airheadz": ['EH1 R HH EH2 D Z'],
  "airing": ['EH1 R IH0 NG'],
  "airington": ['EH1 R IH0 NG T AH0 N'],
  "airless": ['EH1 R L AH0 S'],
  "airlia": ['EH1 R L IY0 AH0'],
  "airlie": ['EH1 R L IY0'],
  "airlift": ['EH1 R L IH2 F T'],
  "airlifted": ['EH1 R L IH2 F T IH0 D'],
  "airlifter": ['EH1 R L IH2 F T ER0'],
  "airlifters": ['EH1 R L IH2 F T ER0 Z'],
  "airlifting": ['EH1 R L IH2 F T IH0 NG'],
  "airlifts": ['EH1 R L IH2 F T S'],
  "airline": ['EH1 R L AY2 N'],
  "airline's": ['EH1 R L AY2 N Z'],
  "airliner": ['EH1 R L AY2 N ER0'],
  "airliner's": ['EH1 R L AY2 N ER0 Z'],
  "airliners": ['EH1 R L AY2 N ER0 Z'],
  "airlines": ['EH1 R L AY2 N Z'],
  "airlines'": ['EH1 R L AY2 N Z'],
  "airlink": ['EH1 R L IH2 NG K'],
  "airlinks": ['EH1 R L IH2 NG K S'],
  "airlock": ['EH1 R L AO2 K'],
  "airlocks": ['EH1 R L AO2 K S'],
  "airmail": ['EH1 R M EY2 L'],
  "airman": ['EH1 R M AH0 N'],
  "airman's": ['EH1 R M AH0 N Z'],
  "airmen": ['EH1 R M EH2 N'],
  "airmotive": ['EH2 R M OW1 T IH0 V'],
  "airplane": ['EH1 R P L EY2 N'],
  "airplane's": ['EH1 R P L EY0 N Z'],
  "airplanes": ['EH1 R P L EY0 N Z'],
  "airport": ['EH1 R P AO2 R T'],
  "airport's": ['EH1 R P AO2 R T S'],
  "airporter": ['EH1 R P AO2 R T ER0'],
  "airporters": ['EH1 R P AO2 R T ER0 Z'],
  "airports": ['EH1 R P AO2 R T S'],
  "airpower": ['EH1 R P AW2 ER0'],
  "airs": ['EH1 R Z'],
  "airshare": ['EH1 R SH EH0 R'],
  "airship": ['EH1 R SH IH2 P'],
  "airships": ['EH1 R SH IH2 P S'],
  "airspace": ['EH1 R S P EY2 S'],
  "airspaces": ['EH1 R S P EY2 S IH0 Z'],
  "airspeed": ['EH1 R S P IY2 D'],
  "airstrike": ['EH1 R S T R AY2 K'],
  "airstrikes": ['EH1 R S T R AY2 K S'],
  "airstrip": ['EH1 R S T R IH2 P'],
  "airstrips": ['EH1 R S T R IH2 P S'],
  "airtight": ['EH1 R T AY2 T'],
  "airtime": ['EH1 R T AY2 M'],
  "airtouch": ['EH1 R T AH2 CH'],
  "airtran": ['EH1 R T R AE2 N'],
  "airwave": ['EH1 R W EY2 V'],
  "airwaves": ['EH1 R W EY2 V Z'],
  "airway": ['EH1 R W EY2'],
  "airway's": ['EH1 R W EY2 Z'],
  "airways": ['EH1 R W EY2 Z'],
  "airways'": ['EH1 R W EY2 Z'],
  "airways's": ['EH1 R W EY2 Z IH0 Z'],
  "airwing": ['EH1 R W IH0 NG'],
  "airwings": ['EH1 R W IH0 NG Z'],
  "airworthiness": ['EH1 R W ER2 DH IY0 N AH0 S'],
  "airworthy": ['EH1 R W ER2 DH IY0'],
  "airy": ['EH1 R IY0'],
  "ais": ['AY1 Z'],
  "aisa": ['AY0 IY1 S AH0'],
  "aisha": ['AY1 SH AH0'],
  "aisle": ['AY1 L', 'AY1 AH0 L'],
  "aisles": ['AY1 L Z', 'AY1 AH0 L Z'],
  "aisling": ['EY1 S L IH0 NG', 'AE1 SH L IH0 NG'],
  "aislinn": ['EY1 S L IH0 N', 'AE1 SH L IH0 NG'],
  "aist": ['EY1 S T'],
  "aitchison": ['EY1 CH IH0 S AH0 N'],
  "aithne": ['EY1 TH N'],
  "aitken": ['AY1 T K AH0 N'],
  "aiton": ['AA0 IY1 T OW0 N'],
  "aiwa": ['AY1 W AH0'],
  "aix": ['EH1 K S', 'EY1 K S'],
  "aix-en-provence": ['EY1 K S AA0 N P R AH0 V AO1 N S', 'EH1 K S AA0 N P R AH0 V AO1 N S'],
  "aix-la-chapelle": ['EY1 K S L AA2 SH AH0 P EH1 L', 'EH1 K S L AA2 SH AH0 P EH1 L'],
  "aja": ['AY1 AH0'],
  "ajaj": ['AH0 JH AA1 JH'],
  "ajaj's": ['AH0 JH AA1 JH IH0 Z'],
  "ajami": ['EY2 JH AA1 M IY0'],
  "ajar": ['AH0 JH AA1 R'],
  "ajax": ['EY1 JH AE2 K S'],
  "ajax's": ['EY1 JH AE2 K S AH0 Z', 'EY1 JH AE2 K S IH0 Z'],
  "ajay": ['EH1 JH EY0'],
  "ajinomoto": ['AH0 JH IH2 N AH0 M OW1 T OW0'],
  "ajito": ['AH0 JH IY1 T OW0', 'AH0 HH IY1 T OW0'],
  "aka": ['AA1 K AH0', 'EY1 K EY1 EY1'],
  "akai": ['AH0 K AY1'],
  "akaka": ['AH0 K AA1 K AH0'],
  "akali": ['AH0 K AA1 L IY0'],
  "akamine": ['AE1 K AH0 M AY2 N'],
  "akana": ['AA0 K AA1 N AH0'],
  "akao": ['AH0 K EY1 OW0', 'AH0 K AA1 W'],
  "akard": ['AE1 K ER0 D'],
  "akashi": ['AH0 K AA1 SH IY0'],
  "akashi's": ['AH0 K AA1 SH IY0 Z'],
  "akbar": ['AE1 K B ER0', 'AA1 K B AA2 R'],
  "akbash": ['AE1 K B AE0 SH'],
  "akc": ['AE1 K'],
  "ake": ['EY1 K'],
  "akebono": ['AE2 K IY0 B OW1 N OW0'],
  "akel": ['AH0 K EH1 L'],
  "akella": ['AH0 K EH1 L AH0'],
  "aken": ['EY1 K AH0 N'],
  "akens": ['EY1 K AH0 N Z'],
  "aker": ['AE1 K ER0', 'EY1 K ER0'],
  "akerley": ['AH0 K ER1 L IY0'],
  "akerman": ['AE1 K ER0 M AH0 N'],
  "akers": ['EY1 K ER0 Z'],
  "akers's": ['EY1 K ER0 Z IH0 Z'],
  "akerson": ['AE1 K ER0 S AH0 N'],
  "akey": ['AH0 K IY1'],
  "akhtar": ['AE1 K T ER0'],
  "aki": ['AA1 K IY0'],
  "akiba": ['AH0 K IY1 B AH0'],
  "akihito": ['AA2 K IY0 HH IY1 T OW2'],
  "akiko": ['AH0 K IY1 K OW0'],
  "akimbo": ['AH0 K IH1 M B OW2'],
  "akin": ['AH0 K IH1 N', 'EY1 K IH0 N'],
  "akins": ['AH0 K IH1 N Z', 'EY1 K IH0 N Z'],
  "akio": ['AA1 K IY0 OW0'],
  "akira": ['AH0 K IY1 R AH0'],
  "akita": ['AH0 K IY1 T AH0'],
  "akita's": ['AH0 K IY1 T AH0 Z'],
  "akiva": ['AH0 K IY1 V AH0'],
  "akiyama": ['AA0 K IY0 Y AA1 M AH0'],
  "akkadian": ['AH0 K EY1 D IY0 AH0 N'],
  "akkerman": ['AE1 K ER0 M AH0 N'],
  "akre": ['AE1 K ER0'],
  "akridge": ['AH0 K R IH1 JH'],
  "akron": ['AE1 K R AH0 N'],
  "aksamit": ['AE1 K S AH0 M IH0 T'],
  "aksler": ['AE1 K S L ER0'],
  "akst": ['AE1 K S T'],
  "akyanama": ['AE2 K Y AA1 N AA0 M AH0'],
  "akzo": ['AE1 K Z OW0'],
  "akzo's": ['AE1 K Z OW0 Z'],
  "akzos": ['AE1 K Z OW0 Z'],
  "al": ['AE1 L'],
  "al's": ['AE1 L Z'],
  "al-amein": ['AE1 L AH0 M EY2 N'],
  "al-qaeda": ['AA2 L K AY1 D AA0'],
  "al.": ['AE1 L', 'AE2 L AH0 B AE1 M AH0'],
  "ala": ['AE1 L AH0', 'EY1 L AH0'],
  "alabama": ['AE2 L AH0 B AE1 M AH0'],
  "alabama's": ['AE2 L AH0 B AE1 M AH0 Z'],
  "alabaman": ['AE2 L AH0 B AE1 M AH0 N'],
  "alabamans": ['AE2 L AH0 B AE1 M AH0 N Z'],
  "alabaster": ['AE1 L AH0 B AE2 S T ER0'],
  "alachlor": ['AH0 L AE1 K L ER0'],
  "alachua": ['AH0 L AE1 CH UW0 AH0'],
  "alacrity": ['AH0 L AE1 K R AH0 T IY0'],
  "aladdin": ['AE0 L AE1 D IH0 N'],
  "aladeen": ['AE0 L AH0 D IY1 N'],
  "alafi": ['AH0 L AA1 F IY0'],
  "alagappan": ['AA0 L AH0 G AA1 P AH0 N'],
  "alagem": ['AE1 L AH0 JH EH0 M'],
  "alagna": ['AA0 L AA1 G N AH0'],
  "alagoas": ['AE1 L AH0 G OW2 Z'],
  "alai": ['AH0 L AY1'],
  "alaimo": ['AH0 L EY1 M OW0'],
  "alain": ['AH0 L EY1 N'],
  "alaine": ['AH0 L EY1 N'],
  "alair": ['AH0 L EH1 R'],
  "alam": ['AH0 L AE1 M'],
  "alamance": ['AE2 L AH0 M AE1 N S'],
  "alamco": ['AH0 L AE1 M K OW0'],
  "alameda": ['AE2 L AH0 M IY1 D AH0'],
  "alamein": ['AE1 L AH0 M EY2 N'],
  "alamillo": ['AE2 L AH0 M IH1 L OW0'],
  "alamito": ['AE2 L AH0 M IY1 T OW0'],
  "alamitos": ['AE2 L AH0 M IY1 T OW0 S'],
  "alamo": ['AE1 L AH0 M OW0'],
  "alamodome": ['AE1 L AH0 M OW0 D OW2 M'],
  "alamos": ['AE1 L AH0 M OW0 Z'],
  "alamosa": ['AE2 L AH0 M OW1 S AH0'],
  "alamoud": ['AE1 L AH0 M UW2 D'],
  "alamoudi": ['AA2 L AA0 M UW1 D IY0', 'AE2 L AA0 M UW1 D IY0'],
  "alan": ['AE1 L AH0 N'],
  "alan's": ['AE1 L AH0 N Z'],
  "alana": ['AA0 L AE1 N AH0'],
  "alanco": ['AH0 L AE1 N K OW0'],
  "aland": ['AE1 L AH0 N D'],
  "alane": ['AH0 L EY1 N'],
  "alanis": ['AA0 L AA1 N IH0 S'],
  "alaniz": ['AE1 L AH0 N IH0 Z'],
  "alann": ['AE1 L AE0 N'],
  "alanna": ['AA0 L AA1 N AH0'],
  "alar": ['EY1 L AA2 R'],
  "alarcon": ['AH0 L AA1 R K AA2 N'],
  "alard": ['AH0 L AA1 R D'],
  "alaric": ['AE1 L ER0 IH0 K'],
  "alarica": ['AA0 L AA0 R IY1 K AH0'],
  "alarice": ['AA0 L AA1 R IH0 S'],
  "alarid": ['AH0 L EH1 R IH0 D'],
  "alarie": ['AH0 L EH1 R IY0'],
  "alarm": ['AH0 L AA1 R M'],
  "alarm's": ['AH0 L AA1 R M Z'],
  "alarmed": ['AH0 L AA1 R M D'],
  "alarming": ['AH0 L AA1 R M IH0 NG'],
  "alarmingly": ['AH0 L AA1 R M IH0 NG L IY0'],
  "alarmist": ['AH0 L AA1 R M AH0 S T', 'AH0 L AA1 R M IH0 S T'],
  "alarmists": ['AH0 L AA1 R M IH0 S T S'],
  "alarms": ['AH0 L AA1 R M Z'],
  "alaron": ['AH0 L EH1 R AH0 N'],
  "alas": ['AH0 L AE1 S'],
  "alaska": ['AH0 L AE1 S K AH0'],
  "alaska's": ['AH0 L AE1 S K AH0 Z'],
  "alaskamen": ['AH0 L AE1 S K AH0 M AH0 N'],
  "alaskan": ['AH0 L AE1 S K AH0 N'],
  "alaskans": ['AH0 L AE1 S K AH0 N Z'],
  "alastair": ['AE1 L AH0 S T EH2 R'],
  "alaster": ['AE1 L AH0 S T ER0'],
  "alatas": ['AH0 L AA1 T AH0 S'],
  "alatorre": ['AA0 L AA0 T AO1 R IY0'],
  "alayne": ['AH0 L EY1 N'],
  "alba": ['AE1 L B AH0'],
  "albach": ['AE1 L B AA0 K'],
  "albacore": ['AE1 L B AH0 K AO2 R'],
  "alban": ['AA1 L B AH0 N'],
  "albanese": ['AA0 L B AA0 N EY1 Z IY0'],
  "albani": ['AE0 L B AA1 N IY0'],
  "albani's": ['AE0 L B AA1 N IY0 Z'],
  "albania": ['AE0 L B EY1 N IY0 AH0'],
  "albania's": ['AE0 L B EY1 N IY0 AH0 Z'],
  "albanian": ['AE0 L B EY1 N IY0 AH0 N'],
  "albanians": ['AE0 L B EY1 N IY0 AH0 N Z'],
  "albank": ['AA0 L B AE1 NG K'],
  "albano": ['AA0 L B AA1 N OW0'],
  "albans": ['AE1 L B AE0 N Z'],
  "albany": ['AO1 L B AH0 N IY0'],
  "albany's": ['AO1 L B AH0 N IY0 Z'],
  "albarado": ['AA0 L B AA0 R AA1 D OW0'],
  "albarran": ['AE2 L B AE1 R AH0 N'],
  "albashaun": ['AE1 L B AH0 SH AA2 N'],
  "albatross": ['AE1 L B AH0 T R AA2 S'],
  "albatrosses": ['AE1 L B AH0 T R AA2 S IH0 Z'],
  "albaugh": ['AH0 L B AO1'],
  "albea": ['AE1 L B IY0 AH0'],
  "albee": ['AH0 L B IY1'],
  "albeit": ['AO0 L B IY1 IH0 T'],
  "albemarle": ['AE1 L B AH0 M AA2 R L'],
  "alben": ['AO1 L B AH0 N'],
  "albendazole": ['AA2 L B AH0 N D AH0 Z OW1 L IY0'],
  "alber": ['AE1 L B ER0'],
  "alberding": ['AE1 L B ER0 D IH0 NG'],
  "alberg": ['AE1 L B ER0 G'],
  "albergo": ['AA0 L B EH1 R G OW0'],
  "alberico": ['AA0 L B ER0 IY1 K OW0'],
  "albern": ['AE1 L B ER0 N'],
  "albers": ['AO1 L B ER0 Z'],
  "alberson": ['AE1 L B ER0 S AH0 N'],
  "albert": ['AE1 L B ER0 T'],
  "albert's": ['AE1 L B ER0 T S'],
  "alberta": ['AE0 L B ER1 T AH0'],
  "alberthal": ['AE1 L B ER0 TH AO2 L'],
  "alberti": ['AA0 L B EH1 R T IY0'],
  "albertina": ['AA0 L B ER0 T IY1 N AH0'],
  "albertine": ['AE1 L B ER0 T IY2 N'],
  "albertini": ['AA0 L B ER0 T IY1 N IY0'],
  "alberto": ['AE0 L B ER1 T OW0'],
  "alberts": ['AE1 L B ER0 T S'],
  "albertsen": ['AE1 L B ER0 T S AH0 N'],
  "albertson": ['AE1 L B ER0 T S AH0 N'],
  "albertson's": ['AE1 L B ER0 T S AH0 N Z'],
  "albertus": ['AE0 L B ER1 T AH0 S'],
  "albertville": ['AE1 L B ER0 T V IH2 L'],
  "alberty": ['AH0 L B ER1 T IY0'],
  "albie": ['AO1 L B IY0'],
  "albin": ['AE1 L B IH0 N'],
  "albina": ['AA0 L B IY1 N AH0'],
  "albini": ['AA0 L B IY1 N IY0'],
  "albinia": ['AA0 L B IY1 N IY0 AH0'],
  "albino": ['AE0 L B AY1 N OW2'],
  "albion": ['AE1 L B IY0 AH0 N'],
  "albiric": ['AA0 L B AY1 R IH0 K'],
  "albo": ['AE1 L B OW0'],
  "albrecht": ['AO1 L B R EH2 K T'],
  "albright": ['AO1 L B R AY2 T'],
  "albright's": ['AO1 L B R AY2 T S'],
  "albrightsville": ['AO1 L B R AY2 T S V IH0 L'],
  "albritton": ['AE1 L B R IH0 T AA0 N'],
  "albro": ['AE1 L B R OW0'],
  "albukhari": ['AA0 L B Y UW0 K AA1 R IY0'],
  "album": ['AE1 L B AH0 M'],
  "album's": ['AE1 L B AH0 M Z'],
  "albumin": ['AE0 L B Y UW1 M AH0 N'],
  "albums": ['AE1 L B AH0 M Z'],
  "albunex": ['AE2 L B Y UW1 N EH0 K S'],
  "albuquerque": ['AE1 L B AH0 K ER0 K IY0'],
  "albury": ['AE1 L B EH0 R IY0'],
  "albus": ['AE1 L B AH0 S'],
  "albuterol": ['AE2 L B Y UW1 T ER0 AO0 L'],
  "alcala": ['AA0 L K AA1 L AH0'],
  "alcan": ['AE1 L K AE2 N'],
  "alcan's": ['AE1 L K AE2 N Z'],
  "alcantar": ['AE2 L K AE1 N T ER0'],
  "alcantara": ['AA0 L K AA0 N T AA1 R AH0'],
  "alcaraz": ['AA0 L K AA1 R AA0 Z'],
  "alcasa": ['AE0 L K AA1 S AH0'],
  "alcatel": ['AE1 L K AH0 T EH2 L'],
  "alcatel's": ['AE1 L K AH0 T EH2 L Z'],
  "alcatraz": ['AE2 L K AH0 T R AE1 Z', 'AE1 L K AH0 T R AE2 Z'],
  "alcazar": ['AA1 L K AH0 Z AA2 R'],
  "alcee": ['AE1 L S IY0'],
  "alcester": ['AA1 L CH EH0 S T ER0'],
  "alchemically": ['AE0 L K EH1 M AH0 K L IY0'],
  "alchemist": ['AE1 L CH AH0 M IH0 S T', 'AA1 L K AH0 M IH0 S T'],
  "alchemy": ['AE1 L K AH0 M IY0'],
  "alcide": ['AE1 L S AY2 D'],
  "alcids": ['AE1 L S IH0 D Z'],
  "alcina": ['AA0 L CH IY1 N AH0'],
  "alco": ['AE1 L K OW0'],
  "alco's": ['AE1 L K OW0 Z'],
  "alcoa": ['AE1 L K OW0 AH0'],
  "alcoa's": ['AE0 L K OW1 AH0 Z'],
  "alcocer": ['AH0 L K OW1 S ER0'],
  "alcock": ['AH0 L K AA1 K'],
  "alcohol": ['AE1 L K AH0 HH AA2 L'],
  "alcohol-drenched": ['AE1 L K AH0 HH AA2 L D R EH1 N CH T'],
  "alcoholic": ['AE2 L K AH0 HH AA1 L IH0 K'],
  "alcoholics": ['AE2 L K AH0 HH AA1 L IH0 K S'],
  "alcoholism": ['AE1 L K AH0 HH AO2 L IH2 Z AH0 M'],
  "alcohols": ['AE1 L K AH0 HH AA2 L Z'],
  "alcon": ['AH0 L K AA1 N'],
  "alcorn": ['AA0 L K AO1 R N'],
  "alcorta": ['AA0 L K AO1 R T AH0'],
  "alcott": ['AE1 L K AA2 T'],
  "alcove": ['AE1 L K OW2 V'],
  "alcoves": ['AE1 L K OW2 V Z'],
  "alda": ['AA1 L D AH0'],
  "aldaco": ['AA0 L D AA1 K OW0'],
  "aldama": ['AA0 L D AA1 M AH0'],
  "aldana": ['AA0 L D AE1 N AH0'],
  "aldape": ['AA0 L D AA1 P EY0'],
  "alday": ['AE1 L D EY0'],
  "aldebaran": ['AE0 L D EH1 B ER0 AH0 N'],
  "aldeburgh": ['AE1 L D B ER2 G'],
  "alden": ['AA1 L D AH0 N'],
  "aldenville": ['AA1 L D AH0 N V IH0 L'],
  "alder": ['AO1 L D ER0'],
  "aldercy": ['AH0 L D ER1 K IY0'],
  "alderete": ['AE1 L D ER0 IY0 T'],
  "alderfer": ['AE1 L D ER0 F ER0'],
  "alderidge": ['AA1 L D ER0 IH0 JH', 'AA1 L D R IH0 JH'],
  "alderman": ['AO1 L D ER0 M AH0 N', 'AE1 L D ER0 M AH0 N'],
  "aldermen": ['AO1 L D ER0 M IH0 N'],
  "alderson": ['AO1 L D ER0 S AH0 N', 'AE1 L D ER0 S AH0 N'],
  "alderton": ['AO1 L D ER0 T AH0 N'],
  "aldi": ['AA1 L D IY0'],
  "aldicarb": ['AO1 L D IH0 K AA2 R B'],
  "aldila": ['AE2 L D IH1 L AH0'],
  "aldin": ['AA0 L D IY1 N'],
  "aldinger": ['AO1 L D IH0 NG ER0'],
  "aldis": ['AA1 L D IH0 S'],
  "aldo": ['AA1 L D OW0'],
  "aldora": ['AA0 L D AO1 R AH0'],
  "aldous": ['AA1 L D AH0 S'],
  "aldred": ['AE1 L D ER0 D', 'AO1 L D R EH0 D'],
  "aldredge": ['AO1 L D R EH0 JH'],
  "aldrete": ['AO1 L D R IY0 T'],
  "aldric": ['AE1 L D R IH0 K', 'AO1 L D R IH0 K'],
  "aldrich": ['AO1 L D R IH0 CH'],
  "aldrich's": ['AO1 L D R IH0 CH AH0 Z'],
  "aldridge": ['AO1 L D R IH0 JH'],
  "aldrin": ['AO1 L D R IH0 N'],
  "aldrin's": ['AO1 L D R IH0 N Z'],
  "aldus": ['AA1 L D IH0 S'],
  "aldus's": ['AA1 L D IH0 S IH0 Z'],
  "aldwin": ['AO1 L D W IH0 N'],
  "aldwyn": ['AO1 L D W IH0 N'],
  "aldys": ['AA1 L D IY0 Z'],
  "ale": ['EY1 L'],
  "aleatory": ['EY1 L IY0 AH0 T AO2 R IY0'],
  "alec": ['AE1 L IH0 K'],
  "aleda": ['AA0 L EY1 D AH0'],
  "aledo": ['AH0 L IY1 D OW0'],
  "aleen": ['AH0 L IY1 N'],
  "alegre": ['AA0 L EH1 G R IY0'],
  "alegrett": ['AE1 L AH0 G R AH0 T'],
  "alegria": ['AH0 L EH1 G R IY0 AH0'],
  "alehouse": ['EY1 L HH AW2 S'],
  "aleichem": ['AH0 L EH1 HH EH0 M', 'AH0 L EY1 HH EH0 M'],
  "alejandre": ['AA0 L EY0 Y AA1 N D R EY0'],
  "alejandro": ['AA0 L EY0 Y AA1 N D R OW0', 'AA0 L EY0 HH AA1 N D R OW0'],
  "alejo": ['AA0 L EY1 Y OW0'],
  "alejos": ['AA0 L EY1 Y OW0 Z'],
  "aleksander": ['AE2 L AH0 K S AE1 N D ER0'],
  "aleksandr": ['AE2 L AH0 K S AE1 N D ER0'],
  "alem": ['AH0 L EH1 M'],
  "aleman": ['EY1 L M AH0 N'],
  "alena": ['AA0 L EY1 N AH0'],
  "alendrin": ['AH0 L EH1 N D R IH0 N'],
  "alene": ['AH0 L IY1 N'],
  "alenia": ['AH0 L IY1 N IY0 AH0'],
  "aleo": ['AA1 L IY0 OW0'],
  "aleph": ['AA1 L AH0 F'],
  "aleria": ['AH0 L IY1 R IY0 AH0'],
  "aleron": ['AA0 L EH0 R AO1 N'],
  "alert": ['AH0 L ER1 T'],
  "alerted": ['AH0 L ER1 T IH0 D'],
  "alerting": ['AH0 L ER1 T IH0 NG'],
  "alertly": ['AH0 L ER1 T L IY0'],
  "alertness": ['AH0 L ER1 T N AH0 S'],
  "alerts": ['AH0 L ER1 T S'],
  "ales": ['EY1 L Z'],
  "aleshire": ['AA0 L EY0 SH IH1 R EY0'],
  "alesi": ['AA0 L EH1 S IY0'],
  "alessandra": ['AE2 L EH0 S AE1 N D R AA0'],
  "alessandra's": ['AE2 L EH0 S AE1 N D R AH0 Z'],
  "alessandrini": ['AA2 L EH0 S AA0 N D R IY1 N IY2'],
  "alessandro": ['AA0 L EY0 Z AA1 N D R OW0', 'AA0 L AH0 S AE1 N D R OW0'],
  "alessi": ['AH0 L EH1 S IY0', 'AH0 L EY1 S IY0'],
  "alessio": ['AH0 L EH1 S IY0 OW0'],
  "aleta": ['AA0 L EH1 T AH0'],
  "aletha": ['AH0 L IY1 TH AH0'],
  "alethea": ['AE2 L AH0 TH IY1 AH0'],
  "aletti": ['AH0 L EH1 T IY0'],
  "aleutian": ['AH0 L UW1 SH AH0 N'],
  "aleutians": ['AH0 L UW1 SH AH0 N Z'],
  "aleve": ['AH0 L IY1 V'],
  "alewife": ['EY1 L W AY2 F'],
  "alewine": ['EY1 L W AY2 N'],
  "alewives": ['EY1 L W AY2 V Z'],
  "alex": ['AE1 L AH0 K S'],
  "alex's": ['AE1 L AH0 K S IH0 Z'],
  "alexa": ['AH0 L EH1 K S AH0'],
  "alexander": ['AE2 L AH0 G Z AE1 N D ER0', 'AE2 L IH0 G Z AE1 N D ER0'],
  "alexander's": ['AE2 L AH0 G Z AE1 N D ER0 Z', 'AE2 L IH0 G Z AE1 N D ER0 Z'],
  "alexanders": ['AE2 L IH0 G Z AE1 N D ER0 Z'],
  "alexandra": ['AE2 L EH0 G Z AE1 N D R AH0', 'AE2 L IH0 G Z AE1 N D R AH0'],
  "alexandre": ['AE0 L IH0 K S AA1 N D ER0'],
  "alexandria": ['AE2 L AH0 G Z AE1 N D R IY0 AH0'],
  "alexandrine": ['AE2 L AH0 G Z AE1 N D R IY0 N'],
  "alexandrines": ['AE2 L AH0 G Z AE1 N D R IY0 N Z'],
  "alexandrov": ['AE2 L AH0 K S AE1 N D R AH0 V', 'AE2 L AH0 G Z AE1 N D R AH0 V'],
  "alexei": ['AH0 L EH1 K S EY2'],
  "alexi": ['AH0 L EH1 K S IY0'],
  "alexi's": ['AH0 L EH1 K S IY0 Z'],
  "alexia": ['AH0 L EH1 K S IY0 AH0'],
  "alexine": ['AH0 L EH1 K S AY0 N'],
  "alexis": ['AH0 L EH1 K S IH0 S'],
  "alexopoulos": ['AE0 L IH0 G Z AA1 P AH0 L IH0 S'],
  "alexs": ['AE1 L AH0 K S'],
  "alexy": ['AH0 L EH1 K S IY0'],
  "aley": ['EY1 L IY0'],
  "alf": ['AE1 L F'],
  "alfa": ['AE1 L F AH0'],
  "alfa's": ['AE1 L F AH0 Z'],
  "alfalfa": ['AE2 L F AE1 L F AH0'],
  "alfano": ['AA0 L F AA1 N OW0'],
  "alfareda": ['AE2 L F AH0 R EH1 D AH0'],
  "alfaro": ['AA0 L F AA1 R OW0'],
  "alfavilli": ['AE2 L F AH0 V IH1 L IY0'],
  "alfie": ['AE1 L F IY0'],
  "alfieri": ['AA0 L F IH1 R IY0'],
  "alfiero": ['AE2 L F IY0 EH1 R OW0'],
  "alfin": ['AE1 L F IH0 N'],
  "alfin's": ['AE1 L F IH0 N Z'],
  "alfono": ['AE0 L F OW1 N OW0'],
  "alfons": ['AA1 L F OW0 N Z'],
  "alfonse": ['AE1 L F AA0 N S', 'AE1 L F AO0 N S'],
  "alfonsin": ['AE2 L F AA1 N S IH0 N'],
  "alfonsin's": ['AE2 L F AA1 N S IH0 N Z'],
  "alfonsine": ['AA0 L F OW0 N S IY1 N IY0'],
  "alfonso": ['AE2 L F AA1 N S OW0'],
  "alfonzo": ['AE2 L F AA1 N Z OW0'],
  "alford": ['AE1 L F ER0 D'],
  "alfre": ['AE1 L F ER0', 'AE1 L F R IY2'],
  "alfred": ['AE1 L F R AH0 D', 'AE1 L F R IH0 D'],
  "alfredo": ['AE2 L F R EY1 D OW0'],
  "alfredson": ['AE1 L F R IH0 D S AH0 N'],
  "alfredsson": ['AE1 L F R IH0 D S AH0 N'],
  "alfrey": ['AE1 L F R IY0'],
  "algae": ['AE1 L JH IY0'],
  "algal": ['AE1 L G AH0 L'],
  "algamaa": ['AE0 L G AA1 M AH0'],
  "algar": ['AA0 L G AA1 R'],
  "algarin": ['AE1 L G ER0 IH0 N'],
  "algata": ['AE0 L G AA1 T AH0'],
  "algebra": ['AE1 L JH AH0 B R AH0'],
  "algebraic": ['AE2 L JH AH0 B R EY1 IH0 K'],
  "algebraically": ['AE2 L JH AH0 B R EY1 IH0 K L IY0'],
  "algemene": ['AE1 L G AH0 M IY2 N'],
  "algeo": ['AE1 L JH IY0 OW0'],
  "alger": ['AE1 L JH ER0'],
  "algeria": ['AE0 L JH IH1 R IY0 AH0'],
  "algeria's": ['AE0 L JH IY1 R IY0 AH0 Z'],
  "algerian": ['AE0 L JH IH1 R IY0 AH0 N'],
  "algerians": ['AE0 L JH IY1 R IY0 AH0 N Z'],
  "algernon": ['AE1 L JH ER0 N AA0 N'],
  "algie": ['AO1 L G IY0'],
  "algiers": ['AE0 L JH IH1 R Z'],
  "algodones": ['AA2 G AH0 D OW1 N AH0 Z'],
  "algol": ['AE1 L G AA0 L'],
  "algom": ['AE1 L G AH0 M'],
  "algoma": ['AE0 L G OW1 M AH0'],
  "algonquian": ['AE0 L G AA1 NG K IY0 AH0 N'],
  "algonquin": ['AE0 L G AA1 NG K W IH0 N'],
  "algorithm": ['AE1 L G ER0 IH2 DH AH0 M'],
  "algorithmic": ['AE1 L G ER0 IH2 DH AH0 M IH0 K'],
  "algorithms": ['AE1 L G ER0 IH2 DH AH0 M Z'],
  "alguire": ['AA0 L G W IH1 R EY0'],
  "algy": ['AE1 L JH IY0'],
  "alhadeff": ['AE1 L AH0 D EH0 F'],
  "alhambra": ['AE0 L HH AE1 M B R AH0'],
  "alhausie": ['AE0 L HH AW1 S IY0'],
  "ali": ['AA1 L IY0'],
  "ali's": ['AA1 L IY0 Z'],
  "ali-reza": ['AA1 L IY0 R EH1 Z AH0'],
  "aliano": ['AA0 L IY0 AA1 N OW0'],
  "alianza": ['AE2 L IY0 AE1 N Z AH0'],
  "alias": ['EY1 L IY0 AH0 S'],
  "aliases": ['EY1 L IY0 AH0 S IH0 Z'],
  "aliberti": ['AA0 L IY0 B EH1 R T IY0'],
  "alibi": ['AE1 L AH0 B AY2'],
  "alibis": ['AE1 L AH0 B AY2 Z'],
  "alibrandi": ['AE2 L IH0 B R AE1 N D IY0'],
  "alice": ['AE1 L AH0 S', 'AE1 L IH0 S'],
  "alice's": ['AE1 L AH0 S AH0 Z'],
  "alicea": ['AH0 L IH1 S IY0 AH0'],
  "alices": ['AE1 L AH0 S AH0 Z'],
  "alicia": ['AH0 L IH1 SH AH0'],
  "alicia's": ['AH0 L IH1 SH AH0 Z'],
  "alida": ['AA0 L IY1 D AH0'],
  "alie": ['AE1 L IY0'],
  "alien": ['EY1 L IY0 AH0 N'],
  "alienate": ['EY1 L Y AH0 N EY2 T'],
  "alienated": ['EY1 L IY0 AH0 N EY2 T IH0 D'],
  "alienates": ['EY1 L IY0 AH0 N EY2 T S'],
  "alienating": ['EY1 L IY0 AH0 N EY2 T IH0 NG'],
  "alienation": ['EY2 L IY0 AH0 N EY1 SH AH0 N'],
  "aliens": ['EY1 L IY0 AH0 N Z'],
  "aliff": ['AE1 L IH0 F'],
  "alig": ['AE1 L IH0 G'],
  "alight": ['AH0 L AY1 T'],
  "align": ['AH0 L AY1 N'],
  "aligned": ['AH0 L AY1 N D'],
  "aligning": ['AH0 L AY1 N IH0 NG'],
  "alignment": ['AH0 L AY1 N M AH0 N T'],
  "alignments": ['AH0 L AY1 N M AH0 N T S'],
  "aligns": ['AH0 L AY1 N Z'],
  "alihan": ['AE1 L IH0 HH AE2 N'],
  "alija": ['AH0 L AY1 JH AH0'],
  "alija's": ['AH0 L AY1 JH AH0 Z'],
  "alike": ['AH0 L AY1 K'],
  "alikes": ['AH0 L AY1 K S'],
  "alima": ['AA0 L IY1 M AH0'],
  "alimenies": ['AE1 L IH0 M EH2 N IY0 Z'],
  "alimentary": ['AE2 L AH0 M EH1 N T ER0 IY0'],
  "alimony": ['AE1 L AH0 M OW2 N IY0'],
  "alina": ['AH0 L IY1 N AH0'],
  "aline": ['AH0 L AY1 N'],
  "alines": ['AH0 L AY1 N Z'],
  "alioto": ['AA0 L IY0 OW1 T OW0'],
  "aliquippa": ['AE2 L AH0 K W IH1 P AH0'],
  "aliquippa's": ['AE2 L AH0 K W IH1 P AH0 Z'],
  "aliquippas": ['AE2 L AH0 K W IH1 P AH0 Z'],
  "alire": ['AA0 L IH1 R EY0'],
  "alirio": ['AH0 L IH1 R IY0 OW0'],
  "alisa": ['AH0 L IY1 S AH0'],
  "alisha": ['AA0 L IY1 SH AH0'],
  "alisky": ['AH0 L IH1 S K IY0'],
  "alislamiyya": ['AE2 L AH0 S L AH0 M IY1 AH0'],
  "alison": ['AE1 L IH0 S AH0 N'],
  "alissa": ['AH0 L IH1 S AH0'],
  "alistair": ['AE1 L IH0 S T EH2 R'],
  "alister": ['AE1 L IH0 S T ER0'],
  "alita": ['AA0 L IY1 T AH0'],
  "alitalia": ['AE2 L IH0 T EY1 L IY0 AH0', 'AE2 L IH0 T AE1 L IY0 AH0'],
  "alitalia's": ['AE2 L IH0 T EY1 L IY0 AH0 Z', 'AE2 L IH0 T AE1 L IY0 AH0 Z'],
  "alithia": ['AH0 L IH1 TH IY0 AH0'],
  "alito": ['AH0 L IY1 T OW0'],
  "alive": ['AH0 L AY1 V'],
  "alix": ['AE1 L IH0 K S'],
  "aliyah": ['AH0 L IY1 AH0'],
  "alizac": ['AE1 L IH0 Z AE0 K'],
  "alka": ['AE1 L K AH0'],
  "alkahest": ['AE1 L K AH0 HH EH2 S T'],
  "alkali": ['AE1 L K AH0 L AY2'],
  "alkalies": ['AE1 L K AH0 L AY2 Z'],
  "alkaline": ['AE1 L K AH0 L AY2 N'],
  "alkalinity": ['AE2 L K AH0 L IH1 N AH0 T IY0'],
  "alkaloid": ['AE1 L K AH0 L OY2 D'],
  "alkaloidal": ['AE0 L K AH0 L OY1 D AH0 L'],
  "alkaloids": ['AE1 L K AH0 L OY2 D Z'],
  "alkanes": ['AE1 L K EY2 N Z'],
  "alkema": ['AE1 L K IH0 M AH0'],
  "alkene": ['AE1 L K IY2 N'],
  "alkenes": ['AE1 L K IY2 N Z'],
  "alkermes": ['AA0 L K ER1 M IY0 Z'],
  "alkire": ['AH0 L K AY1 R'],
  "all": ['AO1 L'],
  "all's": ['AO1 L Z'],
  "all-out": ['AO1 L AW1 T'],
  "all-purpose": ['AO1 L P ER1 P AH0 S'],
  "all-star": ['AO2 L S T AA1 R'],
  "all-time": ['AO2 L T AY1 M'],
  "alla": ['AA1 L AH0'],
  "allah": ['AA1 L AH0'],
  "allahabad": ['AH0 L AA1 HH AH0 B AA2 D'],
  "allain": ['AH0 L EY1 N'],
  "allaire": ['AA0 L EH1 R'],
  "allais": ['AH0 L EY1', 'EY1 L IY0 AH0 S'],
  "allamuchy": ['AA2 L AH0 M UW1 CH IY0'],
  "allan": ['AE1 L AH0 N'],
  "allante": ['AE2 L AA1 N T EY0'],
  "allar": ['AH0 L AA1 R'],
  "allard": ['AE1 L ER0 D'],
  "allardt": ['AE1 L ER0 T'],
  "allay": ['AH0 L EY1'],
  "allayed": ['AH0 L EY1 D'],
  "allaying": ['AH0 L EY1 IH0 NG'],
  "allays": ['AH0 L EY1 Z'],
  "allbaugh": ['AH0 L B AO1'],
  "allbee": ['AO1 L B IY2'],
  "allbright": ['AO1 L B R AY2 T'],
  "allbritten": ['AE1 L B R IH0 T AH0 N'],
  "allbritton": ['AE1 L B R IH0 T AA0 N'],
  "allcock": ['AO1 L K AA2 K'],
  "allcorn": ['AH0 L K AO1 R N'],
  "allday": ['AO1 L D EY2'],
  "allderdice": ['AA1 L D ER0 D AY2 S', 'AE1 L D ER0 D AY2 S'],
  "alldredge": ['AH0 L D R EH1 JH'],
  "allebach": ['AE1 L IH0 B AA0 K', 'AE1 L B AA0 K'],
  "alleco": ['AE2 L EH1 K OW0'],
  "allee": ['AH0 L IY1'],
  "allegation": ['AE2 L AH0 G EY1 SH AH0 N'],
  "allegations": ['AE2 L AH0 G EY1 SH AH0 N Z'],
  "allege": ['AH0 L EH1 JH'],
  "alleged": ['AH0 L EH1 JH D'],
  "allegedly": ['AH0 L EH1 JH AH0 D L IY0'],
  "alleges": ['AH0 L EH1 JH AH0 Z', 'AH0 L EH1 JH IH0 Z'],
  "alleghany": ['AE1 L AH0 G EY2 N IY0'],
  "alleghenies": ['AE2 L AH0 G EY1 N IY0 Z', 'AE1 L AH0 G EH2 N IY0 Z'],
  "allegheny": ['AE2 L AH0 G EY1 N IY0', 'AE1 L AH0 G EH2 N IY0'],
  "allegheny's": ['AE2 L AH0 G EY1 N IY0 Z', 'AE1 L AH0 G EH2 N IY0 Z'],
  "allegiance": ['AH0 L IY1 JH AH0 N S'],
  "allegiances": ['AE2 L IY1 JH IY0 AE2 N S IH0 Z', 'AH0 L IY1 JH AH0 N S IH0 Z'],
  "alleging": ['AH0 L EH1 JH IH0 NG'],
  "allegis": ['AE2 L EY1 JH IH0 S'],
  "allegis'": ['AE2 L EY1 JH IH0 S'],
  "allegis's": ['AE2 L EY1 JH IH0 S IH0 Z'],
  "allegorical": ['AE2 L AH0 G AO1 R AH0 K AH0 L'],
  "allegories": ['AE1 L AH0 G AO2 R IY0 Z'],
  "allegory": ['AE1 L AH0 G AO2 R IY0'],
  "allegra": ['AA0 L EH1 G R AH0'],
  "allegretti": ['AA0 L EH0 G R EH1 T IY0'],
  "allegro": ['AH0 L EH1 G R OW2'],
  "allele": ['AH0 L EH1 L IY0'],
  "alleles": ['AH0 L EH1 L IY0 Z'],
  "allelic": ['AH0 L EH1 L IH0 K'],
  "alleluia": ['AE2 L EH0 L UW1 Y AA0'],
  "alleluja": ['AE2 L EH0 L UW1 Y AA0'],
  "alleman": ['EY1 L M AH0 N'],
  "allemand": ['AE1 L IH0 M AH0 N D'],
  "allen": ['AE1 L AH0 N'],
  "allen's": ['AE1 L AH0 N Z'],
  "allenbaugh": ['AH0 L EH1 N B AO0'],
  "allenby": ['AE1 L AH0 N B IY0'],
  "allenby's": ['AE1 L AH0 N B IY0 Z'],
  "allendale": ['AE1 L AH0 N D EY2 L'],
  "allende": ['AA2 Y EH1 N D EY0'],
  "allender": ['AA0 L Y EH1 N D EY0 ER0', 'AA0 L EH1 N D ER0'],
  "allendorf": ['AE1 L IH0 N D AO0 R F'],
  "allene": ['AE1 L IY2 N'],
  "allenhurst": ['AE1 L AH0 N HH ER2 S T'],
  "allens": ['AE1 L AH0 N Z'],
  "allensbach": ['AE1 L AH0 N Z B AA2 K'],
  "allensworth": ['AE1 L AH0 N Z W ER0 TH'],
  "allenton": ['AE1 L AH0 N T AH0 N'],
  "allentown": ['AE1 L AH0 N T AW2 N'],
  "allenwood": ['AE1 L AH0 N W UH2 D'],
  "aller": ['AO1 L ER0'],
  "allergan": ['AE1 L ER0 JH AH0 N'],
  "allergen": ['AE1 L ER0 JH AH0 N'],
  "allergenic": ['AE1 L ER0 JH AH0 N IH0 K'],
  "allergens": ['AE1 L ER0 JH AH0 N Z'],
  "allergic": ['AH0 L ER1 JH IH0 K'],
  "allergies": ['AE1 L ER0 JH IY0 Z'],
  "allergist": ['AE1 L ER0 JH AH0 S T'],
  "allergist's": ['AE1 L ER0 JH AH0 S T S'],
  "allergists": ['AE1 L ER0 JH AH0 S T S'],
  "allergy": ['AE1 L ER0 JH IY0'],
  "allers": ['AO1 L ER0 Z'],
  "allert": ['AE1 L ER0 T'],
  "allerton": ['AE1 L ER0 T AH0 N'],
  "alles": ['EY1 L Z'],
  "alleva": ['AA0 L EY1 V AH0'],
  "alleviate": ['AH0 L IY1 V IY0 EY2 T'],
  "alleviated": ['AH0 L IY1 V IY0 EY2 T IH0 D'],
  "alleviates": ['AH0 L IY1 V IY0 EY0 T S'],
  "alleviating": ['AH0 L IY1 V IY0 EY2 T IH0 NG'],
  "alleviation": ['AH0 L IY2 V IY0 EY1 SH AH0 N'],
  "alley": ['AE1 L IY0'],
  "alley's": ['AE1 L IY0 Z'],
  "alleyne": ['AE1 L EY2 N'],
  "alleyoop": ['AE0 L IY0 UW1 P'],
  "alleys": ['AE1 L IY0 Z'],
  "alleyway": ['AE1 L IY0 W EY2'],
  "alleyways": ['AE1 L IY0 W EY2 Z'],
  "allgaier": ['AE1 L G AY0 ER0'],
  "allgeier": ['AE1 L G AY0 ER0'],
  "allgemeine": ['AO1 L G AH0 M AY2 N'],
  "allgemeines": ['AO1 L G AH0 M AY2 N Z'],
  "allgeyer": ['AE1 L G IY0 ER0'],
  "allgood": ['AO1 L G UH2 D'],
  "alli": ['AE1 L IY0'],
  "alliance": ['AH0 L AY1 AH0 N S'],
  "alliance's": ['AH0 L AY1 AH0 N S IH0 Z'],
  "alliances": ['AH0 L AY1 AH0 N S AH0 Z', 'AH0 L AY1 AH0 N S IH0 Z'],
  "alliant": ['AH0 L AY1 AH0 N T'],
  "alliant's": ['AH0 L AY1 AH0 N T S'],
  "allianz": ['AE1 L IY0 AH0 N Z'],
  "allianz's": ['AE1 L IY0 AH0 N Z IH0 Z'],
  "allick": ['AE1 L IH0 K'],
  "allie": ['AE1 L IY0'],
  "allied": ['AH0 L AY1 D', 'AE1 L AY2 D'],
  "allied's": ['AE1 L AY2 D Z'],
  "alliedsignal": ['AE1 L AY2 D S IH1 G N AH0 L'],
  "alliedsignal's": ['AE1 L AY2 D S IH1 G N AH0 L Z'],
  "allies": ['AE1 L AY0 Z', 'AH0 L AY1 Z'],
  "allies'": ['AE1 L AY0 Z'],
  "alligator": ['AE1 L AH0 G EY2 T ER0'],
  "alligators": ['AE1 L AH0 G EY2 T ER0 Z'],
  "alligood": ['AE1 L IH0 G UH2 D'],
  "allin": ['AH0 L IH1 N'],
  "allinder": ['AE1 L IH0 N D ER0'],
  "alling": ['AO1 L IH0 NG'],
  "allinger": ['AO1 L IH0 NG ER0'],
  "allingham": ['AO1 L IH0 NG HH AE2 M'],
  "allington": ['AO1 L IH0 NG T AH0 N'],
  "allinson": ['AE1 L IH0 N S AH0 N'],
  "allis": ['AE1 L IH0 S'],
  "allison": ['AE1 L AH0 S AH0 N', 'AE1 L IH0 S AH0 N'],
  "allison's": ['AE1 L IH0 S AH0 N Z'],
  "allister": ['AO1 L IH0 S T ER0', 'AE1 L IH0 S T ER0'],
  "alliston": ['AE1 L IH0 S T AA0 N'],
  "alliteration": ['AH0 L IH1 T ER0 EY2 SH AH0 N'],
  "alliterative": ['AH0 L IH1 T ER0 AH0 T IH0 V', 'AH0 L IH1 T ER0 EY2 T IH0 V'],
  "allman": ['AO1 L M AH0 N'],
  "allmendinger": ['AE1 L M EH0 N D IH0 NG ER0'],
  "allmon": ['AO1 L M AH0 N'],
  "allmond": ['AH0 L M AA1 N D'],
  "allnet": ['AO1 L N EH2 T'],
  "allnutt": ['AH0 L N AH1 T'],
  "allocate": ['AE1 L AH0 K EY2 T'],
  "allocated": ['AE1 L AH0 K EY2 T IH0 D'],
  "allocates": ['AE1 L AH0 K EY2 T S'],
  "allocating": ['AE1 L AH0 K EY2 T IH0 NG'],
  "allocation": ['AE2 L AH0 K EY1 SH AH0 N'],
  "allocations": ['AE2 L AH0 K EY1 SH AH0 N Z'],
  "allocator": ['AE1 L AH0 K EY2 T ER0'],
  "allocators": ['AE1 L AH0 K EY2 T ER0 Z'],
  "allocca": ['AE2 L AA1 K AH0'],
  "allocco": ['AE2 L AA1 K OW0'],
  "allograph": ['AE1 L AH0 G R AE2 F'],
  "allographs": ['AE1 L AH0 G R AE2 F S'],
  "allomorph": ['AE1 L AH0 M AO0 R F'],
  "allomorphs": ['AE1 L AH0 M AO0 R F S'],
  "allophone": ['AE1 L AH0 F OW2 N'],
  "allophones": ['AE1 L AH0 F OW2 N Z'],
  "allophonic": ['AE2 L AH0 F AA1 N IH0 K'],
  "allor": ['AO1 L ER0'],
  "allot": ['AH0 L AA1 T'],
  "alloted": ['AH0 L AA1 T IH0 D'],
  "allotment": ['AH0 L AA1 T M AH0 N T'],
  "allotments": ['AH0 L AA1 T M AH0 N T S'],
  "allotrope": ['AE1 L AH0 T R OW2 P'],
  "allotropes": ['AE1 L AH0 T R OW2 P S'],
  "allots": ['AH0 L AA1 T S'],
  "allotted": ['AH0 L AA1 T IH0 D'],
  "allotting": ['AH0 L AA1 T IH0 NG'],
  "allow": ['AH0 L AW1'],
  "allowable": ['AH0 L AW1 AH0 B AH0 L'],
  "allowance": ['AH0 L AW1 AH0 N S'],
  "allowances": ['AH0 L AW1 AH0 N S IH0 Z'],
  "alloway": ['AE1 L OW0 W EY2'],
  "allowed": ['AH0 L AW1 D'],
  "allowing": ['AH0 L AW1 IH0 NG'],
  "allows": ['AH0 L AW1 Z'],
  "alloy": ['AE1 L OY2'],
  "alloys": ['AE1 L OY2 Z'],
  "allphin": ['AH0 L F IH1 N'],
  "allport": ['AO1 L P AO2 R T'],
  "allred": ['AE1 L R IH0 D'],
  "allright": ['AA0 L R AY1 T'],
  "alls": ['AO1 L Z'],
  "allsbrook": ['AO1 L Z B R UH2 K', 'AO1 L T S B R UH2 K'],
  "allset": ['AO0 L S EH1 T'],
  "allshouse": ['AO1 L Z HH AW2 S', 'AO1 L T S HH AW2 S'],
  "allsop": ['AO1 L S AA2 P'],
  "allsopp": ['AO1 L S AA2 P'],
  "allspice": ['AO1 L S P AY2 S'],
  "allstate": ['AO1 L S T EY2 T'],
  "allstate's": ['AO1 L S T EY2 T S'],
  "allston": ['AO1 L S T AH0 N'],
  "allsup": ['AE1 L S AH0 P'],
  "alltel": ['AO1 L T EH2 L'],
  "alltime": ['AO1 L T AY2 M'],
  "allton": ['AO1 L T AH0 N'],
  "alltop": ['AO1 L T AA2 P'],
  "allude": ['AH0 L UW1 D'],
  "alluded": ['AH0 L UW1 D IH0 D'],
  "alludes": ['AH0 L UW1 D Z'],
  "alluding": ['AH0 L UW1 D IH0 NG'],
  "allum": ['AE1 L AH0 M'],
  "allums": ['AE1 L AH0 M Z'],
  "allure": ['AH0 L UH1 R'],
  "allured": ['AH0 L UH1 R D'],
  "alluring": ['AH0 L UH1 R IH0 NG'],
  "allusion": ['AH0 L UW1 ZH AH0 N'],
  "allusions": ['AH0 L UW1 ZH AH0 N Z'],
  "allusive": ['AH0 L UW1 S IH0 V'],
  "alluvial": ['AE2 L UW1 V IY0 AH0 L'],
  "alluvium": ['AE2 L UW1 V IY0 AH0 M'],
  "allwaste": ['AO1 L W EY2 S T'],
  "allweiss": ['AA1 L W IY2 S'],
  "allweiss's": ['AA1 L W IY2 S IH0 Z'],
  "ally": ['AE1 L AY0', 'AH0 L AY1'],
  "ally's": ['AH0 L AY1 Z'],
  "allying": ['AE1 L AY0 IH0 NG', 'AH0 L AY1 IH0 NG'],
  "allyn": ['AE1 L IH0 N'],
  "allys": ['AE1 L AY0 Z'],
  "allyson": ['AE1 L IH0 S AH0 N'],
  "alm": ['AA1 L M'],
  "alma": ['AA1 L M AH0'],
  "almada": ['AA0 L M AA1 D AH0'],
  "almadani": ['AA2 L M AH0 D AA1 N IY0'],
  "almaden": ['AA1 L M AH0 D EH2 N'],
  "almaguer": ['AA0 L M AA0 G W EH1 R'],
  "almal": ['AA1 L M AH0 L'],
  "alman": ['AE1 L M AH0 N'],
  "almanac": ['AA1 L M AH0 N AE2 K'],
  "almand": ['AE1 L M AH0 N D'],
  "almanza": ['AA0 L M AA1 N Z AH0'],
  "almanzar": ['AA0 L M AA0 N Z AA1 R'],
  "almaraz": ['AA0 L M AA1 R AA0 Z'],
  "almas": ['AE1 L M AH0 Z'],
  "almasy": ['AE1 L M AH0 S IY0'],
  "almaty": ['AA2 M AA1 T IY2'],
  "almay": ['AA1 L M EY0'],
  "almazan": ['AA0 L M AA0 Z AA1 N'],
  "almeda": ['AA0 L M EY1 D AH0'],
  "almeida": ['AA0 L M IY1 D AH0'],
  "almelund": ['AA0 L M AH0 L AH1 N D'],
  "almendarez": ['AA0 L M EY0 N D AA1 R EH0 Z'],
  "almer": ['AO1 L M ER0'],
  "almeria": ['AA0 L M ER1 IY0 AH0'],
  "almgren": ['AE1 L M G R EH0 N'],
  "almighty": ['AO0 L M AY1 T IY0'],
  "almira": ['AA0 L M IH1 R AH0'],
  "almo": ['AA1 L M OW0'],
  "almodovar": ['AA2 L M OW0 D OW0 V AA1 R'],
  "almon": ['AA1 L M AH0 N'],
  "almond": ['AA1 M AH0 N D'],
  "almonds": ['AA1 L M AH0 N D Z'],
  "almoner": ['AA1 L M AH0 N ER0'],
  "almonte": ['AA0 L M OW1 N T EY0'],
  "almos": ['AA1 L M OW0 S'],
  "almost": ['AO1 L M OW2 S T'],
  "almquist": ['AE1 L M K W IH0 S T'],
  "alms": ['AA1 L M Z', 'AA1 M Z'],
  "almy": ['AO1 M IY0'],
  "almys": ['AE1 L M IY0 Z'],
  "alodie": ['AH0 L AA1 D IY0'],
  "aloe": ['AE1 L OW2'],
  "aloft": ['AH0 L AO1 F T'],
  "aloha": ['AH0 L OW1 HH AA0'],
  "aloi": ['AA1 L OY0'],
  "aloia": ['AA0 L OW1 Y AH0'],
  "alois": ['AA0 L OY1 S'],
  "aloisa": ['AA0 L OY1 S AH0'],
  "aloisi": ['AA0 L OY1 S IY0'],
  "aloisia": ['AA0 L OY1 S IY0 AH0'],
  "aloisio": ['AA0 L OY1 S IY0 OW0'],
  "aloka": ['AH0 L OW1 K AH0'],
  "alomar": ['AE1 L AH0 M AA2 R'],
  "alon": ['AH0 L AA1 N'],
  "alone": ['AH0 L OW1 N'],
  "along": ['AH0 L AO1 NG'],
  "alonge": ['AE1 L AH0 N JH'],
  "alongi": ['AA0 L OW1 NG G IY0'],
  "alongs": ['AH0 L AO1 NG Z'],
  "alongside": ['AH0 L AO1 NG S AY1 D'],
  "aloni": ['AH0 L OW1 N IY0'],
  "alonso": ['AH0 L AA1 N S OW0'],
  "alonza": ['AH0 L AA1 N Z AH0'],
  "alonzo": ['AH0 L AA1 N Z OW0'],
  "aloof": ['AH0 L UW1 F'],
  "aloofness": ['AH0 L UW1 F N AH0 S'],
  "alot": ['AH0 L AA1 T'],
  "aloud": ['AH0 L AW1 D'],
  "aloys": ['AH0 L OY1 Z'],
  "aloyse": ['AH0 L OY1 S'],
  "aloysia": ['AA0 L OY1 S IY0 AH0'],
  "aloysius": ['AE2 L OW0 IH1 SH IH0 S'],
  "alpa": ['AE1 L P AH0'],
  "alpaca": ['AE0 L P AE1 K AH0'],
  "alpaugh": ['AH0 L P AO1'],
  "alper": ['AE1 L P ER0'],
  "alperin": ['AE1 L P ER0 IH2 N'],
  "alpern": ['AH0 L P ER1 N'],
  "alpers": ['AE1 L P ER0 Z'],
  "alpert": ['AE1 L P ER0 T'],
  "alpex": ['AE1 L P EH0 K S'],
  "alpha": ['AE1 L F AH0'],
  "alpha's": ['AE1 L F AH0 Z'],
  "alphabet": ['AE1 L F AH0 B EH2 T'],
  "alphabetic": ['AE2 L F AH0 B EH1 T IH0 K'],
  "alphabetical": ['AE2 L F AH0 B EH1 T IH0 K AH0 L'],
  "alphabetically": ['AE2 L F AH0 B EH1 T IH0 K L IY0'],
  "alphabetization": ['AE2 L F AH0 B EH2 T AH0 Z EY1 SH AH0 N'],
  "alphabetize": ['AE1 L F AH0 B AH0 T AY2 Z'],
  "alphabetized": ['AE1 L F AH0 B AH0 T AY2 Z D'],
  "alphabetizes": ['AE1 L F AH0 B AH0 T AY2 Z IH0 Z'],
  "alphabetizing": ['AE1 L F AH0 B AH0 T AY2 Z IH0 NG'],
  "alphagraphics": ['AE0 L F AH0 G R AE1 F IH0 K S'],
  "alphametric": ['AE1 L F AH0 M EH2 T R IH0 K'],
  "alphametrics": ['AE1 L F AH0 M EH2 T R IH0 K S'],
  "alphandery": ['AE2 L F AE1 D ER0 IY0'],
  "alphanumeric": ['AE2 L F AH0 N UW0 M EH1 R IH0 K'],
  "alphaphotographic": ['AE2 L F AH0 F OW2 T AH0 G R AE1 F IH0 K'],
  "alpharel": ['AE1 L F ER0 EH2 L'],
  "alpharetta": ['AE2 L F ER0 EH1 T AH0'],
  "alphin": ['AE1 L F IH0 N'],
  "alphonse": ['AE0 L F AA1 N Z'],
  "alphonsine": ['AH0 L F AA1 N S IY0 N'],
  "alphonso": ['AE0 L F AA1 N S OW0'],
  "alpin": ['AH0 L P IH1 N'],
  "alpine": ['AE1 L P AY2 N'],
  "alpirez": ['AE1 L P IH0 R EH0 Z'],
  "alpo": ['AE1 L P OW0'],
  "alps": ['AE1 L P S'],
  "alquist": ['AE1 L K W IH0 S T'],
  "alread": ['AA1 L R IY0 D'],
  "already": ['AO0 L R EH1 D IY0', 'AO0 R EH1 D IY0'],
  "alred": ['AO1 L R IH0 D'],
  "alright": ['AO2 L R AY1 T'],
  "alrighty": ['AO2 L R AY1 T IY0'],
  "alrosa": ['AA0 L R OW1 S AH0', 'AA0 L R OW1 Z AH0'],
  "alroy": ['AH0 L R OY1'],
  "als": ['AE1 L Z'],
  "alsace": ['AE0 L S AA1 S', 'AE0 L S AE1 S'],
  "alsatian": ['AE0 L S EY1 SH AH0 N'],
  "alsbrook": ['AE1 L Z B R UH0 K'],
  "alsbrook's": ['AE1 L Z B R UH0 K S'],
  "alsbrooks": ['AE1 L Z B R UH0 K S'],
  "alsbrooks'": ['AE1 L Z B R UH0 K S'],
  "alsbury": ['AO1 L Z B EH0 R IY0', 'AE1 L Z B EH0 R IY0'],
  "alsdorf": ['AO1 L Z D AO0 R F', 'AE1 L Z D AO0 R F'],
  "alsgaard": ['AA1 L Z G AA0 R D'],
  "alshelhah": ['AA0 L SH EH1 L HH AH0'],
  "alsip": ['AE1 L S IH0 P'],
  "also": ['AO1 L S OW0'],
  "alsobrook": ['AE1 L S AH0 B R UH0 K'],
  "alsobrooks": ['AE1 L S AH0 B R UH0 K S'],
  "alsop": ['AE1 L S AA0 P'],
  "alspach": ['AE1 L S P AH0 K'],
  "alspaugh": ['AH0 L S P AO1'],
  "alsthom": ['AE1 L S TH AH0 M'],
  "alston": ['AO1 L S T AH0 N'],
  "alsup": ['AE1 L S AH0 P'],
  "alt": ['AA1 L T'],
  "alta": ['AA1 L T AH0'],
  "altadena": ['AA2 L T AH0 D IY1 N AH0'],
  "altai": ['AE0 L T AY1'],
  "altaic": ['AE0 L T EY1 IH0 K'],
  "altair": ['AA0 L T EH1 R'],
  "altamira": ['AA2 L T AA0 M IH1 R AH0'],
  "altamirano": ['AA2 L T AA0 M IH0 R AA1 N OW0'],
  "altamont": ['AA1 L T AH0 M AO2 N T'],
  "altamuro": ['AO2 L T AH0 M UH1 R OW0'],
  "altar": ['AO1 L T ER0'],
  "altarpiece": ['AO1 L T ER0 P IY2 S'],
  "altars": ['AO1 L T ER0 Z'],
  "altavista": ['AO2 L T AH0 V IH1 S T AH0'],
  "altay": ['AO1 L T AY0'],
  "altemose": ['AE1 L T IH0 M OW0 S'],
  "altemus": ['AE1 L T IH0 M IH0 S'],
  "altenburg": ['AO1 L T AH0 N B ER0 G'],
  "altenhofen": ['AE1 L T IH0 N HH AH0 F AH0 N'],
  "alteon": ['AA1 L T IY0 AO0 N'],
  "alter": ['AO1 L T ER0'],
  "altera": ['AO2 L T EH1 R AH0'],
  "alteration": ['AO2 L T ER0 EY1 SH AH0 N'],
  "alterations": ['AO2 L T ER0 EY1 SH AH0 N Z'],
  "altercation": ['AA2 L T ER0 K EY1 SH AH0 N'],
  "altercations": ['AA2 L T ER0 K EY1 SH AH0 N Z'],
  "altered": ['AO1 L T ER0 D'],
  "altergott": ['AE1 L T ER0 G AH0 T'],
  "altering": ['AO1 L T ER0 IH0 NG'],
  "alterman": ['AO1 L T ER0 M AH0 N'],
  "alternacare": ['AO0 L T ER1 N AH0 K EH2 R'],
  "alternate": ['AO1 L T ER0 N AH0 T', 'AO1 L T ER0 N EY2 T'],
  "alternated": ['AO1 L T ER0 N EY2 T IH0 D'],
  "alternately": ['AO1 L T ER0 N AH0 T L IY0'],
  "alternates": ['AO1 L T ER0 N EY2 T S'],
  "alternating": ['AO1 L T ER0 N EY2 T IH0 NG'],
  "alternation": ['AO1 L T ER0 N EY2 SH AH0 N'],
  "alternative": ['AO0 L T ER1 N AH0 T IH0 V'],
  "alternatively": ['AO0 L T ER1 N AH0 T IH0 V L IY0'],
  "alternatives": ['AO0 L T ER1 N AH0 T IH0 V Z'],
  "alternator": ['AO1 L T ER0 N EY2 T ER0'],
  "alters": ['AO1 L T ER0 Z'],
  "altfest": ['AA1 L T F EH2 S T'],
  "althaus": ['AE1 L T HH AW0 S'],
  "althaver": ['AE2 L TH EY1 V ER0'],
  "althea": ['AE0 L TH IY1 AH0'],
  "altherr": ['AA1 L TH ER0'],
  "althoff": ['AE1 L T HH AO0 F'],
  "althorp": ['AE1 L TH AO0 R P', 'AA1 L TH AO0 R P'],
  "although": ['AO2 L DH OW1'],
  "althouse": ['AO1 L T HH AW2 S'],
  "altice": ['AA1 L T IH0 S'],
  "altier": ['AO1 L T IY0 ER0'],
  "altieri": ['AA0 L T IH1 R IY0'],
  "altima": ['AA1 L T IH2 M AH0'],
  "altima's": ['AA1 L T IH2 M AH0 Z'],
  "altimeter": ['AE0 L T IH1 M AH0 T ER0'],
  "altimeters": ['AE0 L T IH1 M AH0 T ER0 Z'],
  "altimetry": ['AE0 L T IH1 M AH0 T R IY0'],
  "altimorano": ['AA2 L T IY2 M AO2 R AA1 N OW0'],
  "altiplano": ['AE2 L T AH0 P L AA1 N OW2'],
  "altitude": ['AE1 L T AH0 T UW2 D'],
  "altitudes": ['AE1 L T IH0 T UW2 D Z'],
  "altizer": ['AE1 L T AY0 Z ER0'],
  "altland": ['AE1 L T L AH0 N D'],
  "altman": ['AO1 L T M AH0 N'],
  "altman's": ['AO1 L T M AH0 N Z'],
  "altmann": ['AO1 L T M AH0 N'],
  "altmeyer": ['AE1 L T M AY0 ER0', 'AA1 L T M AY0 ER0'],
  "alto": ['AE1 L T OW0'],
  "altobelli": ['AA0 L T OW0 B EH1 L IY0'],
  "altogether": ['AO2 L T AH0 G EH1 DH ER0'],
  "altoid": ['AA1 L T OY0 D'],
  "altom": ['AH0 L T AA1 M'],
  "altomare": ['AA0 L T OW0 M AA1 R IY0'],
  "alton": ['AO1 L T AH0 N'],
  "altoona": ['AE2 L T UW1 N AH0'],
  "altos": ['AE1 L T OW0 Z', 'AO1 L T OW2 S'],
  "altra": ['AA1 L T R AH0'],
  "altron": ['AO1 L T R AA0 N'],
  "altruism": ['AE1 L T R UW0 IH2 Z AH0 M'],
  "altruistic": ['AO2 L T R UW0 IH1 S T IH0 K'],
  "altschiller": ['AO1 L CH IH0 L ER0'],
  "altschul": ['AE1 L CH AH0 L'],
  "altschuler": ['AE1 L CH Y UW0 L ER0', 'AE1 L CH UW0 L ER0'],
  "altshuler": ['AE1 L CH Y UW0 L ER0', 'AE1 L CH UW0 L ER0'],
  "altucher": ['AE0 L T AH1 K ER0'],
  "alturas": ['AA0 L T UH1 R AH0 S'],
  "altus": ['AE1 L T AH0 S'],
  "altvater": ['AE1 L T V AH0 T ER0'],
  "altzheimer": ['AA1 L T S HH AY2 M ER0'],
  "altzheimer's": ['AA1 L T S HH AY2 M ER0 Z'],
  "alu": ['AA1 L UW0'],
  "alum": ['AE1 L AH0 M', 'AH0 L AH1 M'],
  "alumax": ['AE1 L UW0 M AE0 K S'],
  "alumbaugh": ['AH0 L AH1 M B AO0'],
  "alumina": ['AH0 L UW1 M AH0 N AH0'],
  "aluminio": ['AE2 L UW0 M IY1 N IY0 OW0'],
  "aluminium": ['AH0 L UW1 M IH0 N AH0 M', 'AE2 L Y UW1 M IH0 N AH0 M'],
  "aluminize": ['AH0 L UW1 M AH0 N AY2 Z'],
  "aluminized": ['AH0 L UW1 M AH0 N AY2 Z D'],
  "aluminosilicate": ['AH0 L UW2 M AH0 N OW0 S IH1 L AH0 K EY2 T'],
  "aluminum": ['AH0 L UW1 M AH0 N AH0 M'],
  "aluminum's": ['AH0 L UW1 M AH0 N AH0 M Z'],
  "alumna": ['AH0 L AH1 M N AH0'],
  "alumnae": ['AH0 L AH1 M N EY2'],
  "alumni": ['AH0 L AH1 M N AY2'],
  "alumnus": ['AH0 L AH1 M N AH0 S'],
  "alums": ['AE1 L AH0 M Z'],
  "alun": ['EY1 L AH0 N'],
  "alura": ['AA0 L UH1 R AH0'],
  "alusuisse": ['AE2 L Y UW0 S W IH1 S'],
  "alva": ['AE1 L V AH0'],
  "alvah": ['AE0 L V AA1'],
  "alvan": ['AE1 L V AH0 N'],
  "alvarado": ['AE2 L V ER0 AA1 D OW0'],
  "alvardo": ['AA0 L V AA1 R D OW0'],
  "alvare": ['AE2 L V EY1 R', 'AE2 L V AA1 R'],
  "alvarenga": ['AA0 L V AA0 R EH1 NG G AH0'],
  "alvares": ['AA0 L V AA1 R EH0 S'],
  "alvarez": ['AE1 L V ER0 EH2 Z'],
  "alvarez's": ['AE1 L V ER0 EH2 Z IH0 Z'],
  "alvaro": ['AH0 L V AA1 R OW0'],
  "alvear": ['AA0 L V IH1 R'],
  "alveolar": ['AE0 L V IY1 AH0 L ER0'],
  "alveoli": ['AE0 L V IY1 AH0 L AY2'],
  "alverez": ['AA0 L V EH1 R EH0 Z'],
  "alvero": ['AE0 L V EH1 R OW0'],
  "alverson": ['AA1 L V ER0 S AH0 N'],
  "alverton": ['AA1 L V ER0 T AH0 N'],
  "alves": ['AA1 L V EH0 S'],
  "alvey": ['AE1 L V IY0'],
  "alvidrez": ['AA0 L V IY1 D R EH0 Z'],
  "alvin": ['AE1 L V IH0 N'],
  "alvina": ['AE0 L V AY1 N AH0'],
  "alvino": ['AA0 L V IY1 N OW0'],
  "alvis": ['AA1 L V IH0 S'],
  "alvita": ['AA0 L V IY1 T AH0'],
  "alvite": ['AE1 L V AY2 T'],
  "alvord": ['AE0 L V AO1 R D'],
  "alwaleed": ['AE2 L W AH0 L IY1 D', 'AA2 L W AA0 L IY1 D'],
  "alward": ['AE0 L W ER1 D'],
  "always": ['AO1 L W EY2 Z', 'AO1 L W IY0 Z'],
  "alwin": ['AE1 L W IH0 N'],
  "alwine": ['AE1 L W AY2 N'],
  "alwood": ['AE1 L W UH0 D'],
  "alwyn": ['AE1 L W IH0 N'],
  "aly": ['EY1 L IY0'],
  "alyce": ['AE1 L IH0 S'],
  "alyea": ['AE0 L IY1 AH0'],
  "alyeska": ['AE0 L IY0 EH1 S K AH0'],
  "alyeska's": ['AE2 L Y EH1 S K AH0 Z'],
  "alys": ['AE1 L IY0 Z'],
  "alysheba": ['AE2 L IH0 SH IY1 B AH0'],
  "alysia": ['AH0 L IH1 S IY0 AH0'],
  "alyssa": ['AH0 L IH1 S AH0'],
  "alza": ['AE1 L Z AH0'],
  "alza's": ['AE1 L Z AH0 Z'],
  "alzado": ['AA0 L Z AE1 D OW0', 'AA0 L Z AA1 D OW0'],
  "alzena": ['AA0 L Z EH1 N AH0'],
  "alzene": ['AA0 L Z IY1 N'],
  "alzheimer": ['AE1 L Z HH AY2 M ER0', 'AA1 L T S HH AY2 M ER0'],
  "alzheimer's": ['AE1 L Z HH AY2 M ER0 Z', 'AA1 T S Z HH AY2 M ER0 Z'],
  "alzona": ['AE2 L Z OW1 N AH0'],
  "am": ['AE1 M', 'EY1 EH1 M'],
  "am's": ['AE1 M Z', 'EY1 EH1 M Z'],
  "ama": ['EY2 EH2 M EY1'],
  "amabel": ['AE1 M AH0 B EH2 L'],
  "amabelle": ['AE1 M AH0 B AH0 L'],
  "amabile": ['AA0 M AA1 B AH0 L'],
  "amacher": ['AE1 M AH0 K ER0'],
  "amacker": ['AE1 M AH0 K ER0'],
  "amadea": ['AA0 M AA1 D IY0 AH0'],
  "amadeo": ['AA0 M AA1 D IY0 OW0'],
  "amadeus": ['AE2 M AH0 D EY1 AH0 S'],
  "amado": ['AA0 M AA1 D OW0'],
  "amadon": ['AA0 M AA0 D AO1 N'],
  "amador": ['AE1 M AH0 D AO2 R'],
  "amadou": ['AE1 M AH0 D UW2'],
  "amagansett": ['AH0 M AE1 G AH0 N S EH2 T'],
  "amaker": ['AE1 M EY0 K ER0'],
  "amakudari": ['AE2 M AH0 K Y UW0 D AA1 R IY0'],
  "amal": ['AH0 M AA1 L'],
  "amal's": ['AH0 M AA1 L Z'],
  "amalea": ['AE2 M AH0 L IY1 AH0'],
  "amalfitano": ['AA0 M AA0 L F IY0 T AA1 N OW0'],
  "amalgam": ['AH0 M AE1 L G AH0 M'],
  "amalgamate": ['AH0 M AE1 L G AH0 M EY2 T'],
  "amalgamated": ['AH0 M AE1 L G AH0 M EY2 T IH0 D'],
  "amalgamated's": ['AH0 M AE1 L G AH0 M EY2 T IH0 D Z'],
  "amalgamates": ['AH0 M AE1 L G AH0 M EY2 T S'],
  "amalgamating": ['AH0 M AE1 L G AH0 M EY2 T IH0 NG'],
  "amalgamation": ['AH0 M AE2 L G AH0 M EY1 SH AH0 N'],
  "amalgams": ['AH0 M AE1 L G AH0 M Z'],
  "amalia": ['AH0 M AA1 L Y AH0'],
  "amalie": ['AE1 M AH0 L IY0'],
  "amalya": ['AH0 M AA1 L Y AH0'],
  "aman": ['AA1 M AH0 N'],
  "amana": ['AH0 M AE1 N AH0'],
  "amanda": ['AH0 M AE1 N D AH0'],
  "amanda's": ['AH0 M AE1 N D AH0 Z'],
  "amandas": ['AH0 M AE1 N D AH0 Z'],
  "amandime": ['AE1 M AH0 N D AY2 M'],
  "amann": ['AE1 M AH0 N'],
  "amano": ['AH0 M AA1 N OW0'],
  "amanpour": ['AA2 M AA2 N P UH1 R'],
  "amanpour's": ['AA2 M AA2 N P UH1 R Z'],
  "amante": ['AA0 M AA1 N T IY0'],
  "amar": ['AH0 M AA1 R'],
  "amar's": ['AH0 M AA1 R Z'],
  "amara": ['AA0 M AA1 R AH0'],
  "amaral": ['AA0 M AA0 R AE1 L'],
  "amarante": ['AA0 M AA0 R AA1 N T IY0'],
  "amaranth": ['AE1 M ER0 AE2 N TH'],
  "amaretto": ['AE0 M AH0 R EH1 T OW0'],
  "amargosa": ['AH0 M AA0 R G OW1 S AH0'],
  "amari": ['AA0 M AA1 R IY0'],
  "amarilla": ['AE2 M ER0 IH1 L AH0'],
  "amarillo": ['AE2 M ER0 IH1 L OW0'],
  "amarillo's": ['AE2 M ER0 IH1 L OW0 Z'],
  "amarillos": ['AE2 M ER0 IH1 L OW0 Z'],
  "amarin": ['AE1 M ER0 IH0 N'],
  "amaris": ['AE1 M ER0 IH0 S'],
  "amaro": ['AA0 M AA1 R OW0'],
  "amaryllis": ['AE2 M ER0 IH1 L AH0 S', 'AE2 M ER0 IH1 L IH0 S'],
  "amaryllises": ['AE2 M ER0 IH1 L AH0 S AH0 Z'],
  "amasa": ['AE1 M AH0 S AH0'],
  "amason": ['AE1 M AH0 S AH0 N'],
  "amass": ['AH0 M AE1 S'],
  "amassed": ['AH0 M AE1 S T'],
  "amasses": ['AH0 M AE1 S IH0 Z'],
  "amassing": ['AH0 M AE1 S IH0 NG'],
  "amateur": ['AE1 M AH0 T ER2', 'AE1 M AH0 CH ER2'],
  "amateurish": ['AE1 M AH0 CH ER2 IH0 SH'],
  "amateurism": ['AE1 M AH0 CH ER2 IH0 Z AH0 M'],
  "amateurs": ['AE1 M AH0 T ER2 Z', 'AE1 M AH0 CH ER2 Z'],
  "amati": ['AH0 M AA1 T IY0'],
  "amatil": ['AE1 M AH0 T IH0 L'],
  "amato": ['AA0 M AA1 T OW2'],
  "amato's": ['AA0 M AA1 T OW2 Z'],
  "amauligak": ['AH0 M AW1 L IH0 G AE0 K'],
  "amax": ['EY1 M AE2 K S'],
  "amax's": ['EY1 M AE2 K S IH0 Z'],
  "amaya": ['AA0 M AA1 Y AH0'],
  "amaze": ['AH0 M EY1 Z'],
  "amazed": ['AH0 M EY1 Z D'],
  "amazement": ['AH0 M EY1 Z M AH0 N T'],
  "amazes": ['AH0 M EY1 Z IH0 Z'],
  "amazing": ['AH0 M EY1 Z IH0 NG'],
  "amazingly": ['AH0 M EY1 Z IH0 NG L IY0'],
  "amazon": ['AE1 M AH0 Z AA2 N'],
  "amazon's": ['AE1 M AH0 Z AA2 N Z'],
  "amazonia": ['AE2 M AH0 Z OW1 N IY0 AH0'],
  "amazonian": ['AE2 M AH0 Z OW1 N IY0 AH0 N'],
  "amazonians": ['AE2 M AH0 Z OW1 N IY0 AH0 N Z'],
  "amazons": ['AE1 M AH0 Z AA2 N Z'],
  "ambac": ['AE1 M B AE0 K'],
  "ambase": ['AE1 M B EY2 S'],
  "ambassador": ['AE0 M B AE1 S AH0 D ER0'],
  "ambassador's": ['AE0 M B AE1 S AH0 D ER0 Z'],
  "ambassadorial": ['AE0 M B AE2 S AH0 D AO1 R IY0 AH0 L'],
  "ambassadors": ['AE0 M B AE1 S AH0 D ER0 Z'],
  "ambassadorship": ['AE0 M B AE1 S AH0 D ER0 SH IH2 P'],
  "ambassadorships": ['AE0 M B AE1 S AH0 D ER0 SH IH2 P S'],
  "ambassadress": ['AE0 M B AE1 S AH0 D R AH0 S'],
  "amber": ['AE1 M B ER0'],
  "amber's": ['AE1 M B ER0 Z'],
  "amberg": ['AE1 M B ER0 G'],
  "amberger": ['AE1 M B ER0 G ER0'],
  "ambergris": ['AE1 M B ER0 G R IH0 S'],
  "ambers": ['AE1 M B ER0 Z'],
  "amberson": ['AE1 M B ER0 S AH0 N'],
  "ambery": ['AE1 M B ER0 IY0'],
  "ambiance": ['AE1 M B IY0 AH0 N S'],
  "ambidextrous": ['AE2 M B AH0 D EH1 K S T R AH0 S', 'AE2 M B IH0 D EH1 K S T R AH0 S'],
  "ambien": ['AE1 M B IY0 EH2 N'],
  "ambience": ['AE1 M B IY0 AH0 N S'],
  "ambient": ['AE1 M B IY0 AH0 N T'],
  "ambiguities": ['AE0 M B AH0 G Y UW1 AH0 T IY0 Z'],
  "ambiguity": ['AE2 M B IH0 G Y UW1 AH0 T IY0'],
  "ambiguous": ['AE0 M B IH1 G Y UW0 AH0 S'],
  "ambisone": ['AE1 M B AH0 S OW2 N'],
  "ambition": ['AE0 M B IH1 SH AH0 N'],
  "ambitions": ['AE0 M B IH1 SH AH0 N Z'],
  "ambitious": ['AE0 M B IH1 SH AH0 S'],
  "ambitiously": ['AE0 M B IH1 SH AH0 S L IY0'],
  "ambivalence": ['AE0 M B IH1 V AH0 L AH0 N S'],
  "ambivalent": ['AE0 M B IH1 V AH0 L AH0 N T'],
  "amble": ['AE1 M B AH0 L'],
  "ambled": ['AE1 M B AH0 L D'],
  "ambler": ['AE1 M B L ER0'],
  "ambles": ['AE1 M B AH0 L Z'],
  "amblin": ['AE1 M B L IH0 N'],
  "ambling": ['AE1 M B AH0 L IH0 NG', 'AE1 M B L IH0 NG'],
  "amborn": ['AH0 M B AO1 R N'],
  "amboy": ['AE1 M B OY0'],
  "ambra": ['AE1 M B R AH0'],
  "ambriano": ['AE2 M B R IY0 AA1 N OW0'],
  "ambrit": ['AE1 M B R IH0 T'],
  "ambriz": ['AE1 M B R IH0 Z'],
  "ambrogio": ['AE2 M B R OW1 JH IY0 OW0'],
  "ambrose": ['AE1 M B R OW2 Z'],
  "ambrosia": ['AE0 M B R OW1 ZH AH0'],
  "ambrosial": ['AE0 M B R OW1 ZH AH0 L'],
  "ambrosian": ['AE0 M B R OW1 Z AH0 N'],
  "ambrosiano": ['AE0 M B R OW2 S IY0 AA1 N OW0'],
  "ambrosine": ['AA0 M B R OW0 S IY1 N IY0'],
  "ambrosini": ['AA0 M B R OW0 S IY1 N IY0'],
  "ambrosino": ['AA0 M B R OW0 S IY1 N OW0'],
  "ambrosio": ['AE2 M B R OW1 S IY0 OW0'],
  "ambrosius": ['AE1 M B R AH0 S IY0 IH0 S'],
  "ambs": ['AE1 M Z'],
  "ambuehl": ['AE1 M B UH0 L'],
  "ambulance": ['AE1 M B Y AH0 L AH0 N S'],
  "ambulances": ['AE1 M B Y AH0 L AH0 N S AH0 Z', 'AE1 M B Y AH0 L AH0 N S IH0 Z'],
  "ambulate": ['AE1 M B Y AH0 L EY2 T'],
  "ambulator": ['AE1 M B Y AH0 L EY2 T ER0'],
  "ambulatory": ['AE1 M B Y AH0 L AH0 T AO2 R IY0'],
  "amburgey": ['AE1 M B ER0 G IY0'],
  "amburn": ['AH0 M B ER1 N'],
  "ambush": ['AE1 M B UH2 SH'],
  "ambushed": ['AE1 M B UH2 SH T'],
  "ambushes": ['AE1 M B UH0 SH IH0 Z'],
  "ambushing": ['AE1 M B UH2 SH IH0 NG'],
  "amc": ['EY1 EH2 M S IY1'],
  "amca": ['AE1 M K AH0'],
  "amcast": ['AE1 M K AE2 S T'],
  "amcole": ['AE1 M K OW2 L'],
  "amcor": ['AE1 M K AO2 R'],
  "amcore": ['AE1 M K AO2 R'],
  "amd": ['EY1 EH2 M D IY1'],
  "amdahl": ['AE1 M D AA2 L'],
  "amdahl's": ['AE1 M D AA2 L Z'],
  "amdec": ['AE1 M D EH2 K'],
  "amdek": ['AE1 M D EH0 K'],
  "amdur": ['AE1 M D ER0'],
  "amdura": ['AE0 M D UH1 R AH0'],
  "ame": ['EY1 M'],
  "ameche": ['AH0 M IY1 CH IY0'],
  "amedco": ['AH0 M EH1 D K OW0'],
  "amedee": ['AE1 M IH0 D IY0'],
  "ameen": ['AH0 M IY1 N'],
  "amelia": ['AH0 M IY1 L Y AH0'],
  "amelie": ['AA2 M EH0 L IY1'],
  "amelinda": ['AA2 M EH0 L IY1 N D AH0'],
  "ameline": ['AA2 M EH0 L IY1 N IY0'],
  "amelio": ['AH0 M IY1 L IY0 OW0'],
  "ameliorate": ['AH0 M IY1 L Y ER0 EY2 T'],
  "ameliorated": ['AH0 M IY1 L Y ER0 EY2 T IH0 D'],
  "amelioration": ['AH0 M IY2 L Y ER0 EY1 SH AH0 N'],
  "amelita": ['AA0 M EH0 L IY1 T AH0'],
  "amell": ['AA0 M EY1 L'],
  "amen": ['EY0 M EH1 N', 'AA0 M EH1 N'],
  "amenable": ['AH0 M EH1 N AH0 B AH0 L', 'AH0 M IY1 N AH0 B AH0 L'],
  "amenaces": ['AH0 M EH1 N AH0 S AH0 Z'],
  "amend": ['AH0 M EH1 N D'],
  "amendable": ['AH0 M EH1 N D AH0 B AH0 L'],
  "amended": ['AH0 M EH1 N D IH0 D'],
  "amending": ['AH0 M EH1 N D IH0 NG'],
  "amendment": ['AH0 M EH1 N D M AH0 N T'],
  "amendment's": ['AH0 M EH1 N D M AH0 N T S'],
  "amendments": ['AH0 M EH1 N D M AH0 N T S'],
  "amendola": ['AA0 M EH0 N D OW1 L AH0'],
  "amends": ['AH0 M EH1 N D Z'],
  "amenities": ['AH0 M EH1 N AH0 T IY0 Z', 'AH0 M EH1 N IH0 T IY0 Z'],
  "amenity": ['AH0 M EH1 N AH0 T IY0'],
  "ament": ['AE1 M IH0 N T'],
  "amenta": ['AH0 M EH1 N T AH0'],
  "amer": ['EY1 M ER0'],
  "amerada": ['AE2 M EH0 R AA1 D AH0', 'AE2 M ER0 AA1 D AH0'],
  "amerco": ['AH0 M EH1 R K OW0'],
  "amerford": ['EY1 M ER0 F ER0 D'],
  "ameri": ['AH0 M EH1 R IY0'],
  "ameribanc": ['AH0 M EH1 R IH0 B AE2 NG K'],
  "america": ['AH0 M EH1 R AH0 K AH0', 'AH0 M EH1 R IH0 K AH0'],
  "america's": ['AH0 M EH1 R AH0 K AH0 Z', 'AH0 M EH1 R IH0 K AH0 Z'],
  "american": ['AH0 M EH1 R AH0 K AH0 N', 'AH0 M EH1 R IH0 K AH0 N'],
  "american's": ['AH0 M EH1 R IH0 K AH0 N Z'],
  "americana": ['AH0 M EH2 R AH0 K AE1 N AH0'],
  "americana's": ['AH0 M EH2 R AH0 K AE1 N AH0 Z'],
  "americanas": ['AH0 M EH2 R AH0 K AE1 N AH0 Z'],
  "americanism": ['AH0 M EH1 R IH0 K AH0 N IH2 Z AH0 M'],
  "americanization": ['AH0 M EH2 R AH0 K AH0 N AH0 Z EY1 SH AH0 N'],
  "americanize": ['AH0 M EH1 R AH0 K AH0 N AY2 Z'],
  "americanized": ['AH0 M EH1 R IH0 K AH0 N AY2 Z D'],
  "americano": ['AH0 M EH2 R IH0 K AA1 N OW0'],
  "americanos": ['AH0 M EH2 R IH0 K AA1 N OW0 Z'],
  "americans": ['AH0 M EH1 R AH0 K AH0 N Z', 'AH0 M EH1 R IH0 K AH0 N Z'],
  "americans'": ['AH0 M EH1 R IH0 K AH0 N Z'],
  "americar": ['AH0 M EH1 R IH0 K AA2 R'],
  "americare": ['AH0 M EH1 R IH0 K EH2 R'],
  "americares": ['AH0 M EH1 R IH0 K EH2 R Z'],
  "americas": ['AH0 M EH1 R AH0 K AH0 Z', 'AH0 M EH1 R IH0 K AH0 Z'],
  "americas'": ['AH0 M EH1 R IH0 K AH2 Z'],
  "americium": ['AH0 M EH0 R IH1 S IY2 AH0 M'],
  "americo": ['AH0 M ER1 AH0 K OW0'],
  "americold": ['AH0 M EH1 R IH0 K OW2 L D'],
  "americorp": ['AH0 M EH1 R IH0 K AO2 R', 'AH0 M EH1 R IH0 K AO2 R P'],
  "americorps": ['AH0 M EH1 R IH0 K AO2 R'],
  "americus": ['AH0 M EH1 R IH0 K AH0 S'],
  "ameridata": ['AH0 M EH1 R AH0 D AE2 T AH0'],
  "amerifirst": ['AH0 M EH1 R IH0 F ER0 S T'],
  "amerifirst's": ['AH0 M EH1 R IH0 F ER0 S T S'],
  "amerigas": ['AH0 M EH1 R IH0 G AE2 S'],
  "amerigo": ['AA0 M ER0 IY1 G OW0'],
  "amerihost": ['AH0 M EH1 R AH0 HH OW2 S T'],
  "amerika": ['AH0 M EH1 R IH0 K AH0'],
  "amerindian": ['AE2 M ER0 IH1 N D IY0 AH0 N'],
  "amerine": ['AA0 M ER0 IY1 N IY0'],
  "ameritech": ['AH0 M EH1 R IH0 T EH2 K'],
  "ameritech's": ['AH0 M EH1 R IH0 T EH2 K S'],
  "ameritrust": ['AH0 M EH1 R IH0 T R AH2 S T'],
  "amerman": ['AE1 M ER0 M AH0 N'],
  "amero": ['AA0 M EH1 R OW0'],
  "ameron": ['AE1 M ER0 AA0 N'],
  "ameron's": ['AE1 M ER0 AA0 N Z'],
  "amerongen": ['AE2 M ER0 AO1 N JH AH0 N'],
  "amersham": ['AE1 M ER0 SH AE2 M'],
  "amerson": ['AE1 M ER0 S AH0 N'],
  "amery": ['AE1 M ER0 IY0'],
  "ames": ['EY1 M Z'],
  "ames'": ['EY1 M Z'],
  "ames's": ['EY1 M Z IH0 Z'],
  "amesville": ['EY1 M Z V IH0 L'],
  "ametek": ['AE1 M AH0 T EH2 K'],
  "ametek's": ['AE1 M AH0 T EH2 K S'],
  "amethyst": ['AE1 M IH0 TH IH0 S T'],
  "amev": ['AE1 M EH0 V'],
  "amex": ['AE1 M EH2 K S'],
  "amex's": ['AE1 M EH0 K S IH0 Z'],
  "amexco": ['AH0 M EH1 K S K OW0'],
  "amexco's": ['AH0 M EH1 K S K OW0 Z'],
  "amey": ['EY1 M IY0'],
  "amezcua": ['AH0 M EH1 Z K Y UW0 AH0'],
  "amezquita": ['AA0 M EH0 Z K W IY1 T AH0'],
  "amfac": ['AE1 M F AE0 K'],
  "amfesco": ['AE0 M F EH1 S K OW0'],
  "amgen": ['AE1 M JH EH0 N'],
  "amgen's": ['AE1 M JH EH0 N Z'],
  "amharic": ['AA0 M HH AA1 R IH0 K'],
  "amherst": ['AE1 M ER0 S T'],
  "amherstdale": ['AE1 M ER0 S T D EY2 L'],
  "amhoist": ['AE0 M HH OY1 S T'],
  "ami": ['AA1 M IY0'],
  "amiability": ['EY2 M IY0 AH0 B IH1 L AH0 T IY0'],
  "amiable": ['EY1 M IY0 AH0 B AH0 L'],
  "amiably": ['EY1 M IY0 AH0 B L IY0'],
  "amicable": ['AE1 M IH0 K AH0 B AH0 L'],
  "amicably": ['AE1 M IH0 K AH0 B L IY0'],
  "amick": ['AE1 M IH0 K'],
  "amico": ['AA0 M IY1 K OW0'],
  "amicone": ['AE1 M IH0 K OW2 N'],
  "amicus": ['AH0 M IY1 K AH0 S'],
  "amid": ['AH0 M IH1 D'],
  "amide": ['EY1 M AY2 D'],
  "amides": ['EY1 M AY2 D Z'],
  "amidi": ['AA0 M IY1 D IY0'],
  "amidon": ['AE1 M IH0 D AA0 N'],
  "amidships": ['AH0 M IH1 D SH IH0 P S'],
  "amidst": ['AH0 M IH1 D S T'],
  "amie": ['AE1 M IY0'],
  "amiga": ['AH0 M IY1 G AH0'],
  "amigo": ['AH0 M IY1 G OW2'],
  "amigos": ['AH0 M IY1 G OW2 Z'],
  "amilia": ['AA0 M IY1 L IY0 AH0'],
  "amin": ['AA0 M IY1 N'],
  "amine": ['EY2 M IY1 N'],
  "amino": ['AH0 M IY1 N OW0'],
  "aminta": ['AH0 M IH1 N T AH0'],
  "amiot": ['EY1 M IY0 AH0 T'],
  "amiprilose": ['AH0 M IH1 P R AH0 L OW2 S'],
  "amir": ['AH0 M IH1 R', 'AA0 M IH1 R'],
  "amir's": ['AH0 M IH1 R Z', 'AA0 M IH1 R Z'],
  "amiram": ['AE1 M ER0 AE0 M'],
  "amiran": ['AE1 M IH0 R AH0 N'],
  "amirault": ['AE1 M AY0 R AW0 L T'],
  "amirav": ['AE1 M IH0 R AE2 V'],
  "amiri": ['AA0 M IH1 R IY0'],
  "amis": ['AE1 M IH0 S'],
  "amish": ['AA1 M IH0 SH', 'EY1 M IH0 SH'],
  "amison": ['AE1 M IH0 S AH0 N'],
  "amiss": ['AH0 M IH1 S'],
  "amistad": ['AE1 M AH0 S T AE2 D'],
  "amit": ['AA2 M IY1 T'],
  "amitabha": ['AH0 M IY2 T AA1 B AH0'],
  "amitai": ['AE1 M IH0 T AY2'],
  "amity": ['AE1 M IH0 T IY0'],
  "amityville": ['AE1 M IH0 T IY0 V IH2 L'],
  "amman": ['AE1 M AH0 N', 'AH0 M AA1 N'],
  "ammann": ['AE1 M AH0 N'],
  "ammeen": ['AH0 M IY1 N'],
  "ammerman": ['AE1 M ER0 M AH0 N'],
  "ammeter": ['AE1 M IY2 T ER0'],
  "ammeters": ['AE1 M IY2 T ER0 Z'],
  "ammirati": ['AA0 M IH0 R AA1 T IY0'],
  "ammo": ['AE1 M OW2'],
  "ammon": ['AE1 M AH0 N'],
  "ammonia": ['AH0 M OW1 N Y AH0'],
  "ammonite": ['AE1 M AH0 N AY2 T'],
  "ammonites": ['AE1 M AH0 N AY2 T S'],
  "ammonium": ['AH0 M OW1 N IY0 AH0 M'],
  "ammons": ['AE1 M AH0 N Z'],
  "ammunition": ['AE2 M Y AH0 N IH1 SH AH0 N'],
  "ammunitions": ['AE2 M Y AH0 N IH1 SH AH0 N Z'],
  "amnesia": ['AE0 M N IY1 ZH AH0'],
  "amnesiac": ['AE0 M N IY1 Z IY0 AE2 K'],
  "amnesties": ['AE1 M N AH0 S T IY0 Z'],
  "amnesty": ['AE1 M N AH0 S T IY0'],
  "amnio": ['AE1 M N IY0 OW0'],
  "amniocentesis": ['AE2 M N IY0 OW0 S EH2 N T IY1 S IH0 S'],
  "amniotic": ['AE1 M N IY0 AO0 T IH0 K'],
  "amo": ['AA1 M OW0'],
  "amoco": ['AE1 M AH0 K OW0'],
  "amoco's": ['AE1 M AH0 K OW0 Z'],
  "amoeba": ['AH0 M IY1 B AH0'],
  "amoebas": ['AH0 M IY1 B AH0 Z'],
  "amoebic": ['AH0 M IY1 B IH0 K'],
  "amok": ['AH0 M AH1 K'],
  "amon": ['AA0 M AO1 N'],
  "among": ['AH0 M AH1 NG'],
  "amongst": ['AH0 M AH1 NG S T'],
  "amoolya": ['AH0 M UW1 L Y AH0'],
  "amor": ['AE1 M ER0'],
  "amoral": ['EY0 M AO1 R AH0 L'],
  "amore": ['AA1 M AO0 R'],
  "amorette": ['AE1 M ER0 EH2 T'],
  "amorist": ['AE1 M ER0 AH0 S T'],
  "amorita": ['AA0 M AO0 R IY1 T AH0'],
  "amorosi": ['AA0 M AO0 R OW1 S IY0'],
  "amoroso": ['AA0 M AO0 R OW1 S OW0'],
  "amorous": ['AE1 M ER0 AH0 S'],
  "amorphous": ['AH0 M AO1 R F AH0 S'],
  "amortization": ['AE2 M ER0 T IH0 Z EY1 SH AH0 N'],
  "amortize": ['AE1 M ER0 T AY2 Z'],
  "amortized": ['AE1 M ER0 T AY2 Z D'],
  "amortizing": ['AE1 M ER0 T AY2 Z IH0 NG'],
  "amoruso": ['AE2 M ER0 UW1 S OW0'],
  "amory": ['EY1 M ER0 IY0'],
  "amos": ['EY1 M AH0 S'],
  "amos's": ['EY1 M AH0 S IH0 Z'],
  "amoskeag": ['AE1 M AH0 S K EY2 G'],
  "amoskeag's": ['AE1 M AH0 S K EY2 G Z'],
  "amoss": ['AH0 M AO1 S'],
  "amount": ['AH0 M AW1 N T'],
  "amounted": ['AH0 M AW1 N T IH0 D', 'AH0 M AW1 N IH0 D'],
  "amounting": ['AH0 M AW1 N T IH0 NG', 'AH0 M AW1 N IH0 NG'],
  "amounts": ['AH0 M AW1 N T S'],
  "amour": ['AA2 M UW1 R'],
  "amoxicillin": ['AH0 M AO1 K S AH0 S IH2 L IH0 N'],
  "amp": ['AE1 M P'],
  "ampad": ['AE1 M P AE0 D'],
  "ampal": ['AE1 M P AH0 L'],
  "amparan": ['AE1 M P ER0 AH0 N'],
  "ampato": ['AA2 M P AA1 T OW0'],
  "ampco": ['AE1 M P K OW0'],
  "amperage": ['AE1 M P ER2 IH0 JH'],
  "ampere": ['AE1 M P ER2'],
  "amperes": ['AE1 M P ER2 Z'],
  "ampersand": ['AE1 M P ER0 S AE2 N D'],
  "ampex": ['AE1 M P EH2 K S'],
  "amphenol": ['AE1 M F AH0 N AO0 L'],
  "amphetamine": ['AE0 M F EH1 T AH0 M IY2 N'],
  "amphetamines": ['AE0 M F EH1 T AH0 M IY2 N Z'],
  "amphibian": ['AE0 M F IH1 B IY0 AH0 N'],
  "amphibians": ['AE0 M F IH1 B IY0 AH0 N Z'],
  "amphibious": ['AE0 M F IH1 B IY0 AH0 S'],
  "amphibole": ['AE1 M F AH0 B OW2 L', 'AE1 M F IH0 B OW2 L'],
  "amphitheater": ['AE1 M F AH0 TH IY0 EY1 T ER0'],
  "amphitheaters": ['AE1 M F AH0 TH IY0 EY1 T ER0 Z'],
  "amphitheatre": ['AE1 M F AH0 TH IY0 EY1 T ER0'],
  "amphophilic": ['AE2 M F AH0 F IH1 L IH0 K'],
  "amphora": ['AE1 M F ER0 AH0'],
  "amphorae": ['AE1 M F ER0 IY2'],
  "ample": ['AE1 M P AH0 L'],
  "amplicons": ['AE1 M P L IH0 K AO0 N Z'],
  "amplification": ['AE2 M P L AH0 F AH0 K EY1 SH AH0 N'],
  "amplifications": ['AE2 M P L AH0 F AH0 K EY1 SH AH0 N Z'],
  "amplified": ['AE1 M P L AH0 F AY2 D'],
  "amplifier": ['AE1 M P L AH0 F AY2 ER0'],
  "amplifiers": ['AE1 M P L AH0 F AY2 ER0 Z'],
  "amplifies": ['AE1 M P L AH0 F AY2 Z'],
  "amplify": ['AE1 M P L AH0 F AY2'],
  "amplifying": ['AE1 M P L AH0 F AY2 IH0 NG'],
  "ampligen": ['AE1 M P L IH0 JH EH0 N'],
  "amplitude": ['AE1 M P L AH0 T UW2 D'],
  "amplocore": ['AE1 M P L AH0 K AO2 R'],
  "amply": ['AE1 M P L IY0'],
  "ampol": ['AE1 M P AO0 L'],
  "amputate": ['AE1 M P Y AH0 T EY2 T'],
  "amputated": ['AE1 M P Y AH0 T EY2 T IH0 D'],
  "amputation": ['AE2 M P Y AH0 T EY1 SH AH0 N'],
  "amputations": ['AE2 M P Y UW0 T EY1 SH AH0 N Z'],
  "amputee": ['AE1 M P Y AH0 T IY1'],
  "amputees": ['AE1 M P Y AH0 T IY1 Z'],
  "amr": ['AA1 M ER0'],
  "amraam": ['AE0 M R AA1 M'],
  "amre": ['AE1 M R AH0'],
  "amrein": ['AE1 M R AY0 N'],
  "amrep": ['AE1 M R EH0 P'],
  "amrhein": ['AE1 M ER0 HH AY2 N'],
  "amrine": ['AE1 M R IY0 N'],
  "amritsar": ['AE0 M R IH1 T S ER0', 'AE1 M R IH0 T S AA2 R'],
  "amro": ['AE1 M R OW0'],
  "ams": ['AE1 M Z'],
  "amsbaugh": ['AE1 M Z B AO2'],
  "amsco": ['AE1 M S K OW0'],
  "amsden": ['AE1 M Z D AH0 N'],
  "amsler": ['AE1 M Z L ER0'],
  "amsouth": ['AE1 M S AW2 TH'],
  "amspacher": ['AE1 M S P AH0 K ER0'],
  "amstar": ['AE1 M S T AA2 R'],
  "amster": ['AE1 M S T ER0'],
  "amsterdam": ['AE1 M S T ER0 D AE2 M'],
  "amsterdam's": ['AE1 M S T ER0 D AE2 M Z'],
  "amstrad": ['AE1 M S T R AE2 D'],
  "amstutz": ['AE1 M S T AH0 T S'],
  "amtech": ['AE1 M T EH2 K'],
  "amtrack": ['AE1 M T R AE2 K'],
  "amtrak": ['AE1 M T R AE0 K'],
  "amtrak's": ['AE1 M T R AE2 K S'],
  "amuck": ['AH0 M AH1 K'],
  "amulet": ['AE1 M Y AH0 L AH0 T'],
  "amulets": ['AE1 M Y AH0 L AH0 T S'],
  "amundsen": ['EY1 M AH0 N D S AH0 N', 'AA1 M AH0 N D S AH0 N'],
  "amundson": ['AE1 M AH0 N D S AH0 N'],
  "amuse": ['AH0 M Y UW1 Z'],
  "amused": ['AH0 M Y UW1 Z D'],
  "amusement": ['AH0 M Y UW1 Z M AH0 N T'],
  "amusements": ['AH0 M Y UW1 Z M AH0 N T S'],
  "amusements'": ['AH0 M Y UW1 Z M AH0 N T S'],
  "amuses": ['AH0 M Y UW1 Z IH0 Z'],
  "amusing": ['AH0 M Y UW1 Z IH0 NG'],
  "amusingly": ['AH0 M Y UW1 Z IH0 NG L IY0'],
  "amvest": ['AE1 M V EH0 S T'],
  "amvestor": ['AE2 M V EH1 S T ER0'],
  "amvestors": ['AE2 M V EH1 S T ER0 Z'],
  "amway": ['AE1 M W EY2'],
  "amy": ['EY1 M IY0'],
  "amy's": ['EY1 M IY0 Z'],
  "amygdala": ['AE2 M IH0 G D AA1 L AH0'],
  "amylin": ['AE1 M IH0 L IH2 N'],
  "amyloid": ['AE1 M IH0 L OY2 D'],
  "amyotrophic": ['AE2 M AY0 AH0 T R OW1 F IH0 K', 'AE2 M IY0 AH0 T R OW1 F IH0 K'],
  "amyx": ['AE1 M IH0 K S'],
  "an": ['AE1 N', 'AH0 N'],
  "ana": ['AA1 N AH0', 'AE1 N AH0'],
  "anabaptist": ['AE2 N AH0 B AE1 P T AH0 S T'],
  "anable": ['EY1 N AH0 B AH0 L'],
  "anabolic": ['AE2 N AH0 B AA1 L IH0 K'],
  "anac": ['AE1 N AE0 K'],
  "anachronism": ['AH0 N AE1 K R AH0 N IH2 Z AH0 M'],
  "anachronisms": ['AH0 N AE1 K R AH0 N IH2 Z AH0 M Z'],
  "anachronistic": ['AH0 N AE2 K R AH0 N IH1 S T IH0 K'],
  "anacin": ['AE1 N AH0 S IH0 N'],
  "anacker": ['AE1 N AH0 K ER0'],
  "anacomp": ['AE1 N AH0 K AA2 M P'],
  "anaconda": ['AE2 N AH0 K AA1 N D AH0'],
  "anacortes": ['AE2 N AH0 K AO1 R T AH0 S'],
  "anacostia": ['AE2 N AH0 K AA1 S T IY0 AH0'],
  "anacostia's": ['AE2 N AH0 K AA1 S T IY0 AH0 Z'],
  "anadarko": ['AE2 N AH0 D AA1 R K OW0'],
  "anadarko's": ['AE2 N AH0 D AA1 R K OW0 Z'],
  "anaerobe": ['AE1 N ER0 OW2 B'],
  "anaerobes": ['AE1 N ER0 OW2 B Z'],
  "anaerobic": ['AE2 N ER0 OW1 B IH0 K'],
  "anaesthesia": ['AE2 N AH0 S TH IY1 ZH AH0'],
  "anaesthesiologist": ['AE2 N AH0 S TH IY2 Z IY0 AA1 L AH0 JH IH0 S T'],
  "anaesthesiologists": ['AE2 N AH0 S TH IY2 Z IY0 AA1 L AH0 JH IH0 S T S'],
  "anaesthesiology": ['AE2 N AH0 S TH IY0 Z IY0 AA1 L AH0 JH IY0'],
  "anaesthetic": ['AE2 N AH0 S TH EH1 T IH0 K'],
  "anaesthetics": ['AE2 N AH0 S TH EH1 T IH0 K S'],
  "anaesthetist": ['AH0 N EH1 S TH EH0 T IH0 S T'],
  "anafranil": ['AH0 N AE1 F R AH0 N IH2 L'],
  "anagnos": ['AA0 N AA1 G N OW0 Z'],
  "anagram": ['AE1 N AH0 G R AE2 M'],
  "anaheim": ['AE1 N AH0 HH AY2 M'],
  "anakin": ['AE1 N AA0 K IH2 N'],
  "anal": ['EY1 N AH0 L'],
  "analgesic": ['AE2 N AH0 L JH IY1 S IH0 K'],
  "analgesics": ['AE2 N AH0 L JH IY1 Z IH0 K S'],
  "analog": ['AE1 N AH0 L AO2 G'],
  "analogic": ['AE2 N AH0 L AA1 JH IH0 K'],
  "analogies": ['AH0 N AE1 L AH0 JH IY0 Z'],
  "analogous": ['AH0 N AE1 L AH0 G AH0 S'],
  "analogue": ['AE1 N AH0 L AO2 G'],
  "analogy": ['AH0 N AE1 L AH0 JH IY0'],
  "analysand": ['AE0 N AE1 L IH0 Z AE2 N D'],
  "analyses": ['AH0 N AE1 L AH0 S IY2 Z'],
  "analysis": ['AH0 N AE1 L AH0 S AH0 S', 'AH0 N AE1 L IH0 S IH0 S'],
  "analyst": ['AE1 N AH0 L IH0 S T'],
  "analyst's": ['AE1 N AH0 L IH0 S T S'],
  "analysts": ['AE1 N AH0 L IH0 S T S'],
  "analysts'": ['AE1 N AH0 L IH0 S T S'],
  "analytic": ['AE2 N AH0 L IH1 T IH0 K'],
  "analytical": ['AE2 N AH0 L IH1 T IH0 K AH0 L'],
  "analytically": ['AE2 N AH0 L IH1 T IH0 K L IY0'],
  "analyticity": ['AE2 N AH0 L AH0 T IH1 S AH0 T IY0'],
  "analytics": ['AE2 N AH0 L IH1 T IH0 K S'],
  "analyzable": ['AE1 N AH0 L AY2 Z AH0 B AH0 L'],
  "analyzand": ['AE2 N AA1 L IY2 Z AA0 N D'],
  "analyze": ['AE1 N AH0 L AY2 Z'],
  "analyzed": ['AE1 N AH0 L AY2 Z D'],
  "analyzer": ['AE1 N AH0 L AY2 Z ER0'],
  "analyzers": ['AE1 N AH0 L AY2 Z ER0 Z'],
  "analyzes": ['AE1 N AH0 L AY2 Z IH0 Z'],
  "analyzing": ['AE1 N AH0 L AY2 Z IH0 NG'],
  "anamaria": ['AE2 N AH0 M AH0 R IY1 AH0'],
  "anand": ['AE1 N AH0 N D'],
  "anandale": ['AE1 N AH0 N D EY2 L'],
  "anandeep": ['AA2 N AA0 N D IY1 P'],
  "anania": ['AA0 N AA1 N IY0 AH0'],
  "anantha": ['AH0 N AE1 N TH AH0'],
  "anaphora": ['AH0 N AE1 F ER0 AH0'],
  "anaphylaxis": ['AE2 N AH0 F AH0 L AE1 K S IH0 S'],
  "anaplasia": ['AE2 N AH0 P L EY1 ZH Y AH0'],
  "anaplastic": ['AE2 N AH0 P L AE1 S T IH0 K'],
  "anarchic": ['AE0 N AA1 R K IH0 K'],
  "anarchical": ['AE0 N AA1 R K IH0 K AH0 L'],
  "anarchist": ['AE1 N ER0 K IH2 S T'],
  "anarchists": ['AE1 N ER0 K IH2 S T S'],
  "anarchy": ['AE1 N ER0 K IY2'],
  "anaren": ['AE1 N ER0 AH0 N'],
  "anas": ['AE1 N AH0 S'],
  "anasazi": ['AE2 N AH0 S AE1 Z IY0', 'AE2 N AH0 S AA1 Z IY0'],
  "anasquan": ['AE1 N AH0 S K W AA0 N'],
  "anast": ['AA1 N AA0 S T'],
  "anastas": ['AE1 N AH0 S T AH0 Z'],
  "anastasi": ['AA0 N AA0 S T AA1 S IY0'],
  "anastasia": ['AE0 N AH0 S T EY1 ZH AH0'],
  "anastasio": ['AA0 N AA0 S T AA1 S IY0 OW0', 'AE2 N AH0 S T AA1 S IY0 OW0'],
  "anastos": ['AE1 N AH0 S T OW0 Z'],
  "anathema": ['AH0 N AE1 TH AH0 M AH0'],
  "anatola": ['AA0 N AA0 T OW1 L AH0'],
  "anatole": ['AE1 N AH0 T OW2 L'],
  "anatoli": ['AE2 N AH0 T OW1 L IY0'],
  "anatolia": ['AE2 N AH0 T OW1 L IY0 AH0'],
  "anatolian": ['AE2 N AH0 T OW1 L IY0 AH0 N'],
  "anatoly": ['AE2 N AH0 T OW1 L IY0'],
  "anatomic": ['AE2 N AH0 T AA1 M IH0 K'],
  "anatomical": ['AE2 N AH0 T AA1 M AH0 K AH0 L', 'AE2 N AH0 T AA1 M IH0 K AH0 L'],
  "anatomically": ['AE2 N AH0 T AA1 M AH0 K L IY0'],
  "anatomist": ['AH0 N AE1 T AH0 M AH0 S T', 'AH0 N AE1 T AH0 M IH0 S T'],
  "anatomy": ['AH0 N AE1 T AH0 M IY0'],
  "anawalt": ['AE2 N AH0 W AA1 L T'],
  "anaya": ['AA0 N AA1 Y AH0'],
  "anbari": ['AE0 N B AA1 R IY0'],
  "ancel": ['AH0 N S EH1 L'],
  "ancell": ['AA0 N S EY1 L'],
  "ancestor": ['AE1 N S EH2 S T ER0'],
  "ancestor's": ['AE1 N S EH2 S T ER0 Z'],
  "ancestors": ['AE1 N S EH2 S T ER0 Z'],
  "ancestors'": ['AE1 N S EH2 S T ER0 Z'],
  "ancestral": ['AE0 N S EH1 S T R AH0 L'],
  "ancestry": ['AE1 N S EH0 S T R IY0'],
  "ancheta": ['AA0 N K EH1 T AH0'],
  "ancho": ['AE1 N CH OW0'],
  "anchondo": ['AA0 N K OW1 N D OW0'],
  "anchor": ['AE1 NG K ER0'],
  "anchor's": ['AE1 NG K ER0 Z'],
  "anchorage": ['AE1 NG K ER0 AH0 JH', 'AE1 NG K R IH0 JH'],
  "anchored": ['AE1 NG K ER0 D'],
  "anchoring": ['AE1 NG K ER0 IH0 NG'],
  "anchorman": ['AE1 NG K ER0 M AE2 N', 'AE1 NG K ER0 M AH0 N'],
  "anchormen": ['AE1 NG K ER0 M EH1 N'],
  "anchors": ['AE1 NG K ER0 Z'],
  "anchovies": ['AE0 N CH OW1 V IY0 Z', 'AE1 N CH OW2 V IY0 Z'],
  "anchovy": ['AE0 N CH OW1 V IY0', 'AE1 N CH OW2 V IY0'],
  "ancient": ['EY1 N CH AH0 N T', 'EY1 N SH AH0 N T'],
  "ancients": ['EY1 N CH AH0 N T S', 'EY1 N SH AH0 N T S'],
  "ancillary": ['AE1 N S AH0 L EH2 R IY0'],
  "ancira": ['AA0 N CH IH1 R AH0'],
  "ancona": ['AA0 N K OW1 N AH0'],
  "ancrum": ['AH0 N K R AH1 M'],
  "anctil": ['AE1 NG K T IH0 L'],
  "and": ['AH0 N D', 'AE1 N D'],
  "andal": ['AE1 N D AH0 L'],
  "andalusia": ['AE2 N D AH0 L UW1 ZH AH0'],
  "andalusian": ['AE2 N D AH0 L UW1 SH AH0 N'],
  "andante": ['AA0 N D AA1 N T EY0'],
  "andantino": ['AA2 N D AA2 N T IY1 N OW0'],
  "andean": ['AE1 N D IY0 AH0 N'],
  "andel": ['AE1 N D AH0 L'],
  "ander": ['AE1 N D ER0'],
  "andera": ['AE1 N D ER0 AH0'],
  "anderberg": ['AE1 N D ER0 B ER0 G'],
  "anderegg": ['AE1 N D ER0 IH0 G'],
  "anderle": ['AE1 N D ER0 AH0 L'],
  "anderlini": ['AE2 N D ER0 L IY1 N IY0'],
  "anderman": ['AE1 N D ER0 M AH0 N'],
  "anders": ['AE1 N D ER0 Z'],
  "andersen": ['AE1 N D ER0 S AH0 N'],
  "andersen's": ['AE1 N D ER0 S AH0 N Z'],
  "anderson": ['AE1 N D ER0 S AH0 N'],
  "anderson's": ['AE1 N D ER0 S AH0 N Z'],
  "andersons": ['AE1 N D ER0 S AH0 N Z'],
  "andersonville": ['AE1 N D ER0 S AH0 N V IH0 L'],
  "andersson": ['AE1 N D ER0 S AH0 N'],
  "andert": ['AE1 N D ER0 T'],
  "anderton": ['AE1 N D ER0 T AH0 N'],
  "andes": ['AE1 N D IY0 Z'],
  "andesite": ['AE1 N D IH0 S AY2 T'],
  "andi": ['AE1 N D IY0'],
  "andie": ['AE1 N D IY0'],
  "anding": ['AE1 N D IH0 NG'],
  "andino": ['AA0 N D IY1 N OW0'],
  "andiron": ['AE1 N D AY2 ER0 N'],
  "andis": ['AE1 N D IH0 S'],
  "andler": ['AE1 N D L ER0'],
  "ando": ['AE1 N D OW0'],
  "andolina": ['AA0 N D OW0 L IY1 N AH0'],
  "andonian": ['AE2 N D OW1 N IY0 AH0 N'],
  "andoras": ['AE0 N D AO1 R AH0 Z'],
  "andorra": ['AE0 N D AO1 R AH0'],
  "andover": ['AE1 N D OW0 V ER0'],
  "andrada": ['AA0 N D R AA1 D AH0'],
  "andrade": ['AE1 N D R EY2 D'],
  "andrae": ['AA1 N D R AY0'],
  "andraki": ['AE0 N D AE1 K IY0'],
  "andras": ['AA1 N D R AH0 S'],
  "andre": ['AA1 N D R EY2'],
  "andrea": ['AE1 N D R IY2 AH0', 'AA2 N D R EY1 AH0'],
  "andrea's": ['AE1 N D R IY2 AH0 Z', 'AA2 N D R EY1 AH0 Z'],
  "andreae": ['AA0 N D R EY1 AA0'],
  "andreana": ['AE2 N D R IY2 AA1 N AA0'],
  "andreani": ['AE2 N D R IY2 AA1 N IY0'],
  "andreano": ['AE2 N D R IY2 AA1 N OW0'],
  "andreas": ['AA0 N D R EY1 AH0 S'],
  "andreasen": ['AE1 N D R IY0 S AH0 N'],
  "andreason": ['AE2 N D R IY1 S AH0 N'],
  "andreassen": ['AE1 N D R AH0 S AH0 N'],
  "andree": ['AH0 N D R IY1'],
  "andreen": ['AH0 N D R IY1 N'],
  "andrei": ['AE1 N D R EY2'],
  "andreini": ['AA0 N D R EY0 IY1 N IY0'],
  "andrej": ['AA1 N D R EY0'],
  "andren": ['AE1 N D ER0 AH0 N'],
  "andreoli": ['AA0 N D R EY0 OW1 L IY0'],
  "andreoni": ['AA0 N D R EY0 OW1 N IY0'],
  "andreotti": ['AA0 N D R IY0 AA1 T IY0', 'AE2 N D R IY0 AA1 D IY0'],
  "andreozzi": ['AA0 N D R IY0 AA1 T S IY0'],
  "andrepont": ['AA0 N D R EY1 P OW0 N T'],
  "andres": ['AA1 N D R EY0 Z', 'AE1 N D R EY0 Z'],
  "andresen": ['AE0 N D R IY1 S AH0 N'],
  "andreski": ['AE0 N D R EH1 S K IY0'],
  "andress": ['AA1 N D R EH0 S'],
  "andretti": ['AE2 N D R EH1 T IY0'],
  "andretti's": ['AE2 N D R EH1 T IY0 Z'],
  "andreu": ['AE1 N D R UW0'],
  "andrew": ['AE1 N D R UW0'],
  "andrew's": ['AE1 N D R UW2 Z'],
  "andrews": ['AE1 N D R UW2 Z'],
  "andrews'": ['AE1 N D R UW2 Z'],
  "andrey": ['AE1 N D R IY0'],
  "andrezak": ['AE1 N D R AH0 Z AE0 K'],
  "andria": ['AE1 N D R IY0 AH0'],
  "andriana": ['AA0 N D R IY0 AE1 N AH0'],
  "andriano": ['AA0 N D R IY0 AA1 N OW0'],
  "andric": ['AE1 N D R IH0 K'],
  "andrich": ['AE1 N D R IH0 K'],
  "andrick": ['AE1 N D R IH0 K'],
  "andries": ['AH0 N D R IY1 Z'],
  "andriessen": ['AE2 N D R IY1 S AH0 N'],
  "andringa": ['AA0 N D R IY1 NG G AH0'],
  "andriola": ['AA0 N D R IY0 OW1 L AH0'],
  "andrist": ['AE1 N D R IH0 S T'],
  "androgynous": ['AE0 N D R AO1 JH AH0 N AH0 S'],
  "androgyny": ['AE0 N D R AO1 JH AH0 N IY0'],
  "android": ['AE1 N D R OY2 D'],
  "androids": ['AE1 N D R OY2 D Z'],
  "andromeda": ['AE0 N D R AA1 M AH0 D AH0'],
  "andropov": ['AE0 N D R AA1 P AA2 V'],
  "andros": ['AE1 N D R AA0 S'],
  "andrus": ['AE1 N D R AH0 S'],
  "andruskevich": ['AE2 N D R AH0 S EH1 V IH0 CH'],
  "andry": ['AE1 N D R IY0'],
  "andrzej": ['AA1 N D R EY2'],
  "andrzejewski": ['AH0 N JH EY0 EH1 F S K IY0'],
  "ands": ['AE1 N D Z'],
  "andujar": ['AA0 N D UW0 Y AA1 R'],
  "andy": ['AE1 N D IY0'],
  "andy's": ['AE1 N D IY0 Z'],
  "ane": ['EY1 N', 'AE1 N'],
  "anecdotal": ['AE2 N AH0 K D OW1 T AH0 L', 'AE2 N IH0 K D OW1 T AH0 L'],
  "anecdotally": ['AE2 N AH0 K D OW1 T AH0 L IY0', 'AE2 N IH0 K D OW1 T AH0 L IY0'],
  "anecdote": ['AE1 N AH0 K D OW2 T'],
  "anecdotes": ['AE1 N AH0 K D OW2 T S', 'AE1 N IH0 K D OW2 T S'],
  "anello": ['AH0 N EH1 L OW0'],
  "anemia": ['AH0 N IY1 M IY0 AH0'],
  "anemias": ['AH0 N IY1 M IY0 AH0 Z'],
  "anemic": ['AH0 N IY1 M IH0 K'],
  "anemometer": ['AE2 N AH0 M AA1 M AH0 T ER0'],
  "anemone": ['AE1 N IH0 M OW2 N', 'AH0 N EH1 M AH0 N IY0'],
  "anencephalic": ['AE0 N EH2 N S AH0 F AE1 L IH0 K', 'AE0 N IH0 N S EH1 F AH0 L IH0 K'],
  "anencephaly": ['AE0 N IH0 N S EH1 F AH0 L IY0'],
  "anestachio": ['AE2 N IH0 S T AE1 CH IY0 OW0'],
  "anesthesia": ['AE2 N IH0 S TH IY1 ZH AH0'],
  "anesthesiologist": ['AE2 N AH0 S TH IY2 Z IY0 AA1 L AH0 JH AH0 S T'],
  "anesthesiologists": ['AE2 N AH0 S TH IY2 Z IY0 AA1 L AH0 JH AH0 S T S'],
  "anesthesiology": ['AE2 N AH0 S TH IY0 Z IY0 AA1 L AH0 JH IY0'],
  "anesthetic": ['AE2 N AH0 S TH EH1 T IH0 K'],
  "anesthetics": ['AE2 N AH0 S TH EH1 T IH0 K S'],
  "anesthetist": ['AH0 N EH1 S TH AH0 T AH0 S T'],
  "anette": ['AH0 N EH1 T'],
  "aneurism": ['AE1 N Y UH0 R IH2 Z AH0 M', 'AE1 N Y UH0 R IH2 Z M'],
  "aneurysm": ['AE1 N Y UH0 R IH2 Z AH0 M', 'AE1 N Y UH0 R IH2 Z M'],
  "anew": ['AH0 N UW1', 'AH0 N Y UW1'],
  "anfal": ['EY1 EH1 N EH1 F EY1 EH1 L', 'AE1 N F AA0 L'],
  "anfinson": ['AE1 N F IH0 N S AH0 N'],
  "ang": ['AE1 NG'],
  "ange": ['EY1 N JH'],
  "angel": ['EY1 N JH AH0 L'],
  "angel's": ['EY1 N JH AH0 L Z'],
  "angela": ['AE1 N JH AH0 L AH0'],
  "angela's": ['AE1 N JH AH0 L AH0 Z'],
  "angeleno": ['AE2 N JH AH0 L EH1 N OW0', 'AE2 N JH AH0 L IY1 N OW0'],
  "angelenos": ['AE2 N JH AH0 L EH1 N OW0 Z', 'AE2 N JH AH0 L IY1 N OW0 Z'],
  "angeles": ['AE1 N JH AH0 L IH0 S'],
  "angeles'": ['AE1 N JH AH0 L IY2 Z'],
  "angeles's": ['AE1 N JH AH0 L IH0 S IH0 Z'],
  "angeletti": ['AA0 NG G EH0 L EH1 T IY0'],
  "angelfish": ['EY1 N JH AH0 L F IH2 SH'],
  "angeli": ['AA0 NG G EH1 L IY0'],
  "angelic": ['AE2 N JH EH1 L IH0 K'],
  "angelica": ['AE0 N JH EH1 L IH0 K AH0', 'AE1 N JH AH0 L IY2 K AH0'],
  "angelically": ['AE0 N JH EH1 L IH0 K L IY0'],
  "angelicas": ['AE0 N JH EH1 L IH0 K AH0 Z'],
  "angelico": ['AA0 NG G EH0 L IY1 K OW0'],
  "angelika": ['AE0 N JH EH1 L IH2 K AH0'],
  "angelillo": ['AA0 NG G EH0 L IH1 L OW0'],
  "angelina": ['AE0 N JH EH0 L IY1 N AH0'],
  "angeline": ['EY1 NG G IH0 L AY0 N', 'AE1 N JH AH0 L IY0 N'],
  "angelini": ['AA0 NG G EH0 L IY1 N IY0'],
  "angelino": ['AE2 N JH AH0 L IY1 N OW0', 'AA2 NG G EH0 L IY1 N OW0'],
  "angelino's": ['AE2 N JH AH0 L IY1 N OW0 Z', 'AA2 NG G EH0 L IY1 N OW0 Z'],
  "angelinos": ['AE2 N JH AH0 L IY1 N OW0 Z', 'AE2 NG G AH0 L IY1 N OW0 Z'],
  "angelique": ['AE2 N JH EH0 L IY1 K'],
  "angelita": ['AA0 NG G EH0 L IY1 T AH0'],
  "angell": ['EY1 N JH AH0 L'],
  "angelle": ['EY0 NG G EH1 L'],
  "angello": ['AE2 N JH EH1 L OW0'],
  "angelo": ['AE1 N JH AH0 L OW2'],
  "angelo's": ['AE1 N JH AH0 L OW2 Z'],
  "angeloff": ['EY1 NG G IH0 L AO0 F', 'AE1 N JH IH0 L AO0 F'],
  "angelone": ['AA0 NG G EH0 L OW1 N IY0'],
  "angeloni": ['AA0 NG G EH0 L OW1 N IY0'],
  "angelos": ['AE1 N JH AH0 L OW2 Z'],
  "angelou": ['AE1 N JH AH0 L UW2'],
  "angeloz": ['AE1 N JH AH0 L OW2 Z'],
  "angels": ['EY1 N JH AH0 L Z'],
  "angels'": ['EY1 N JH AH0 L Z'],
  "angelucci": ['AA0 NG G EH0 L UW1 CH IY0'],
  "anger": ['AE1 NG G ER0'],
  "angered": ['AE1 NG G ER0 D'],
  "angerer": ['AE1 NG G ER0 ER0'],
  "angering": ['AE1 NG G ER0 IH0 NG'],
  "angerman": ['AE1 NG ER0 M AH0 N'],
  "angermeier": ['EY1 NG G ER0 M AY0 ER0'],
  "angers": ['AE1 NG G ER0 Z'],
  "angert": ['EY1 NG G ER0 T'],
  "angevine": ['EY1 NG G IH0 V AY0 N'],
  "angi": ['AE1 N JH IY0'],
  "angie": ['AE1 N JH IY0'],
  "angier": ['AE1 N JH IY0 ER0'],
  "angina": ['AE0 N JH AY1 N AH0'],
  "angio": ['AE1 N JH IY2 OW0'],
  "angiogenesis": ['AE2 N JH IY0 OW0 JH EH1 N AH0 S AH0 S'],
  "angiogram": ['AE1 N JH IY2 OW0 G R AE2 M'],
  "angiomedic": ['AE2 N JH IY2 OW0 M EH1 D IH0 K'],
  "angiomedics": ['AE2 N JH IY2 OW0 M EH1 D IH0 K S'],
  "angioplasty": ['AE2 N JH IY2 AH0 P L AE1 S T IY0'],
  "angiotensin": ['AE2 N JH IY2 OW0 T EH1 N S IH0 N'],
  "angle": ['AE1 NG G AH0 L'],
  "angled": ['AE1 NG G AH0 L D'],
  "anglemyer": ['AE1 NG G AH0 L M AY2 ER0'],
  "anglen": ['AE1 NG G AH0 L AH0 N'],
  "angler": ['AE1 NG G L ER0'],
  "anglers": ['AE1 NG G L ER0 Z'],
  "angles": ['AE1 NG G AH0 L Z'],
  "angleton": ['AE1 NG G AH0 L T AH0 N'],
  "angley": ['AE1 NG G L IY0'],
  "anglia": ['AE1 NG G L IY0 AH0'],
  "anglican": ['AE1 NG G L AH0 K AH0 N'],
  "anglicans": ['AE1 NG G L IH0 K AH0 N Z'],
  "anglicize": ['AE1 NG L IH0 S AY2 Z'],
  "anglicized": ['AE1 NG L IH0 S AY2 Z D'],
  "anglim": ['AE1 NG G L IH0 M'],
  "anglin": ['AE1 NG G L IH0 N'],
  "angling": ['AE1 NG G L IH0 NG'],
  "anglo": ['AE1 NG G L OW0'],
  "anglo-catholicism": ['AE1 NG G L OW0 K AH0 TH AO1 L AH0 S IH2 Z AH0 M'],
  "anglophile": ['AE1 N G L AH0 F AY2 L'],
  "anglophone": ['AE1 N G L AH0 F OW2 N'],
  "anglophones": ['AE1 N G L AH0 F OW2 N Z'],
  "anglos": ['AE1 NG G L OW0 Z', 'AE1 NG G L OW0 S'],
  "angola": ['AE0 NG G OW1 L AH0'],
  "angola's": ['AE0 NG G OW1 L AH0 Z'],
  "angolan": ['AE1 NG G OW0 L AH0 N'],
  "angolans": ['AE1 NG G OW0 L AH0 N Z'],
  "angora": ['AE0 NG G AO1 R AH0'],
  "angoras": ['AE0 NG G AO1 R AH0 Z'],
  "angotti": ['AA0 NG G OW1 T IY0'],
  "angove": ['AA0 NG G OW1 V IY0'],
  "angrier": ['AE1 NG G R IY0 ER0'],
  "angriest": ['AE1 NG G R IY0 AH0 S T'],
  "angrily": ['AE1 NG G R AH0 L IY0'],
  "angriness": ['AE1 NG G R IY0 N EH2 S'],
  "angry": ['AE1 NG G R IY0'],
  "angst": ['AA1 NG K S T'],
  "angstadt": ['AE1 NG SH T AE0 T', 'AE1 NG S T AE0 T'],
  "angstrom": ['AE1 NG S T R AH0 M'],
  "angstroms": ['AE1 NG S T R AH0 M Z'],
  "anguiano": ['AA0 NG G IY0 AA1 N OW0'],
  "anguilla": ['AE2 NG W IH1 L AH0'],
  "anguish": ['AE1 NG G W IH0 SH'],
  "anguished": ['AE1 NG G W IH0 SH T'],
  "anguishing": ['AE1 NG G W IH0 SH IH0 NG'],
  "angular": ['AE1 NG G Y AH0 L ER0'],
  "angulate": ['AE1 NG G Y UW0 L EY2 T'],
  "angulated": ['AE1 NG G Y UW0 L EY2 T IH0 D'],
  "angulo": ['AA0 NG G UW1 L OW0'],
  "angus": ['AE1 NG G AH0 S'],
  "angy": ['AE1 N JH IY0'],
  "anhalt": ['AE1 N HH AH0 L T'],
  "anheuser": ['AE1 N HH AY2 Z ER0'],
  "anheuser's": ['AE2 N HH Y UW1 Z ER0 Z'],
  "anhydride": ['AE0 N HH AY1 D R IH0 D'],
  "aniak": ['AE1 N IY0 AE0 K'],
  "anibal": ['AE1 N IH0 B AH0 L'],
  "anikst": ['AE1 N IH0 K S T'],
  "aniline": ['AE1 N AH0 L IY2 N'],
  "animal": ['AE1 N AH0 M AH0 L'],
  "animal's": ['AE1 N AH0 M AH0 L Z'],
  "animals": ['AE1 N AH0 M AH0 L Z'],
  "animals'": ['AE1 N AH0 M AH0 L Z'],
  "animate": ['AE1 N AH0 M AH0 T', 'AE1 N AH0 M EY2 T'],
  "animated": ['AE1 N AH0 M EY2 T IH0 D'],
  "animates": ['AE1 N AH0 M AH0 T S', 'AE1 N AH0 M EY2 T S'],
  "animating": ['AE2 N AH0 M EY1 T IH0 NG G'],
  "animation": ['AE2 N AH0 M EY1 SH AH0 N'],
  "animations": ['AE2 N AH0 M EY1 SH AH0 N Z'],
  "animator": ['AE1 N AH0 M EY2 T ER0'],
  "animators": ['AE1 N AH0 M EY2 T ER0 Z'],
  "anime": ['AE2 N IH0 M EY1'],
  "animism": ['AE1 N AH0 M IH2 Z AH0 M'],
  "animist": ['AE1 N AH0 M AH0 S T'],
  "animists": ['AE1 N AH0 M AH0 S T S'],
  "animosities": ['AE2 N AH0 M AA1 S AH0 T IY0 Z'],
  "animosity": ['AE2 N AH0 M AA1 S AH0 T IY0'],
  "animous": ['AE1 N IH0 M AH0 S'],
  "animus": ['AE1 N IH0 M AH0 S'],
  "aninat": ['AE1 N IH0 N AE0 T'],
  "anion": ['AE1 N AY2 AO0 N'],
  "anions": ['AE1 N AY2 AO0 N Z'],
  "anisa": ['AE0 N IY1 S AH0'],
  "anise": ['AE1 N AH0 S'],
  "aniseed": ['AE1 N AH0 S IY2 D'],
  "anisette": ['AE2 N AH0 S EH1 T'],
  "aniston": ['AE1 N AH0 S T AH0 N'],
  "anita": ['AH0 N IY1 T AH0'],
  "anitec": ['AE1 N IH0 T EH2 K'],
  "anitole": ['AE1 N IH0 T OW0 L'],
  "anixter": ['AE1 N IH0 K S T ER0'],
  "anja": ['AA1 N JH AH0'],
  "anjelica": ['AE0 N JH EH1 L AH0 K AH0'],
  "ankara": ['AE1 NG K ER0 AH0'],
  "ankara's": ['AE1 NG K ER0 AH0 Z'],
  "ankeney": ['AH0 NG K EH1 N IY0'],
  "ankeny": ['AH0 NG K IY1 N IY0'],
  "anker": ['AE1 NG K ER0'],
  "ankerium": ['AE0 NG K ER1 IY0 AH0 M'],
  "ankh": ['AE1 N K'],
  "ankita": ['AE2 N K IY1 T AH0'],
  "anklam": ['AE1 NG K L AH0 M'],
  "ankle": ['AE1 NG K AH0 L'],
  "anklebone": ['AE1 NG K AH0 L B OW2 N'],
  "ankles": ['AE1 NG K AH0 L Z'],
  "anklet": ['AE1 NG K L IH0 T'],
  "anklets": ['AE1 NG K L AH0 T S'],
  "ankney": ['AE1 NG K N IY0'],
  "ankrom": ['AE1 NG K R AH0 M'],
  "ankrum": ['AE1 NG K R AH0 M'],
  "ann": ['AE1 N'],
  "ann's": ['AE1 N Z'],
  "anna": ['AE1 N AH0'],
  "anna's": ['AE1 N AH0 Z'],
  "annabel": ['AE1 N AH0 B EH2 L'],
  "annabella": ['AE2 N AH0 B EH1 L AH0'],
  "annabelle": ['AE1 N AH0 B AH0 L'],
  "annabeth": ['AE1 N AH0 B EH2 TH'],
  "annable": ['AE1 N AH0 B AH0 L'],
  "annal": ['AE1 N AH0 L'],
  "annals": ['AE1 N AH0 L Z'],
  "annamese": ['AE2 N AH0 M IY1 Z'],
  "annan": ['AE1 N AH0 N'],
  "annandale": ['AE1 N AH0 N D EY2 L'],
  "annapolis": ['AH0 N AE1 P AH0 L IH0 S'],
  "annapolis's": ['AH0 N AE1 P AH0 L IH0 S IH0 Z'],
  "annas": ['AE1 N AH0 Z'],
  "annaud": ['AH0 N OW1 D'],
  "anne": ['AE1 N'],
  "anne's": ['AE1 N Z'],
  "anneal": ['AH0 N IY1 L'],
  "annealing": ['AH0 N IY1 L IH0 NG'],
  "annelid": ['AE1 N AH0 L IH0 D'],
  "annelids": ['AE1 N AH0 L IH0 D Z'],
  "annen": ['AE1 N AH0 N'],
  "annenberg": ['AE1 N AH0 N B ER0 G'],
  "annese": ['AA0 N EY1 Z IY0'],
  "annett": ['AH0 N EH1 T'],
  "annette": ['AH0 N EH1 T'],
  "annex": ['AE1 N EH2 K S', 'AH0 N EH1 K S'],
  "annexation": ['AE2 N EH0 K S EY1 SH AH0 N'],
  "annexed": ['AE1 N EH0 K S T', 'AH0 N EH1 K S T'],
  "annexes": ['AE1 N EH2 K S IH0 Z'],
  "annexing": ['AH0 N EH1 K S IH0 NG'],
  "annick": ['AE1 N IH0 K'],
  "annie": ['AE1 N IY0'],
  "annie's": ['AE1 N IY2 Z'],
  "annihilate": ['AH0 N AY1 AH0 L EY2 T'],
  "annihilated": ['AH0 N AY1 AH0 L EY2 T IH0 D'],
  "annihilating": ['AH0 N AY1 AH0 L EY2 T IH0 NG'],
  "annihilation": ['AH0 N AY2 AH0 L EY1 SH AH0 N'],
  "annis": ['AE1 N IY0 Z'],
  "annissa": ['AE0 N IY1 S AH0'],
  "anniston": ['AE1 N AH0 S T AH0 N'],
  "anniversaries": ['AE2 N AH0 V ER1 S ER0 IY0 Z'],
  "anniversary": ['AE2 N AH0 V ER1 S ER0 IY0'],
  "anno": ['AE1 N OW0'],
  "annotate": ['AE1 N AH0 T EY2 T'],
  "annotated": ['AE2 N AH0 T EY1 T IH0 D', 'AE1 N AH0 T EY2 T AH0 D'],
  "annotates": ['AE2 N AH0 T EY1 T S', 'AE1 N AH0 T EY2 T S'],
  "annotating": ['AE2 N AH0 T EY1 T IH0 NG', 'AE1 N AH0 T EY2 T IH0 NG'],
  "annotation": ['AE2 N AH0 T EY1 SH AH0 N'],
  "annotations": ['AE2 N AH0 T EY1 SH AH0 N Z'],
  "annotator": ['AE2 N AH0 T EY1 T ER0'],
  "annotators": ['AE2 N AH0 T EY1 T ER0 Z'],
  "announce": ['AH0 N AW1 N S'],
  "announced": ['AH0 N AW1 N S T'],
  "announcement": ['AH0 N AW1 N S M AH0 N T', 'AH0 N AW1 N S M EH0 N T'],
  "announcements": ['AH0 N AW1 N S M AH0 N T S'],
  "announcer": ['AH0 N AW1 N S ER0'],
  "announcers": ['AH0 N AW1 N S ER0 Z'],
  "announces": ['AH0 N AW1 N S IH0 Z'],
  "announcing": ['AH0 N AW1 N S IH0 NG'],
  "announcment": ['AH0 N AW1 N S M AH0 N T'],
  "annoy": ['AH0 N OY1'],
  "annoyance": ['AH0 N OY1 AH0 N S'],
  "annoyances": ['AH0 N OY1 AH0 N S IH0 Z'],
  "annoyed": ['AH0 N OY1 D'],
  "annoying": ['AH0 N OY1 IH0 NG'],
  "annoys": ['AH0 N OY1 Z'],
  "annual": ['AE1 N Y UW0 AH0 L'],
  "annualize": ['AE1 N Y UW0 W AH0 L AY2 Z'],
  "annualized": ['AE1 N Y UW0 W AH0 L AY2 Z D'],
  "annually": ['AE1 N Y UW0 AH0 L IY0'],
  "annuals": ['AE1 N UW0 AH0 L Z'],
  "annuit": ['AH0 N UW1 IH0 T'],
  "annuities": ['AH0 N UW1 IH0 T IY0 Z'],
  "annuity": ['AH0 N UW1 AH0 T IY0', 'AH0 N UW1 IH0 T IY0', 'AH0 N Y UW1 IH0 T IY0'],
  "annul": ['AE1 N AH0 L'],
  "annular": ['AE1 N Y AH0 L ER0'],
  "annulled": ['AE1 N AH0 L D'],
  "annulment": ['AE1 N AH0 L M AH0 N T'],
  "annum": ['AE1 N AH0 M'],
  "annunciata": ['AA0 N UW0 N CH AA1 T AH0'],
  "annunziata": ['AA0 N UW0 N Z IY2 AA1 T AH0'],
  "annunziato": ['AA0 N UW0 N Z IY2 AA1 T OW0'],
  "annunzio": ['AH0 N AH1 N Z IY0 OW0', 'AH0 N UW1 N Z IY0 OW0'],
  "anny": ['AE1 N IY0'],
  "ano": ['AE1 N OW0', 'AE1 N Y OW0'],
  "anode": ['AE1 N OW2 D'],
  "anodes": ['AE1 N OW2 D Z'],
  "anoint": ['AH0 N OY1 N T'],
  "anointed": ['AH0 N OY1 N T IH0 D', 'AH0 N OY1 N AH0 D', 'AH0 N OY1 N IH0 D'],
  "anomalies": ['AH0 N AA1 M AH0 L IY0 Z'],
  "anomalous": ['AH0 N AA1 M AH0 L AH0 S'],
  "anomaly": ['AH0 N AA1 M AH0 L IY0'],
  "anomie": ['AE1 N AH0 M IY0'],
  "anon": ['AE2 N AO1 N'],
  "anona": ['AA0 N OW1 N AH0'],
  "anonymity": ['AE2 N AH0 N IH1 M IH0 T IY0'],
  "anonymize": ['AH0 N AA1 N AH0 M AY2 Z'],
  "anonymized": ['AH0 N AA1 N AH0 M AY2 Z D'],
  "anonymous": ['AH0 N AA1 N AH0 M AH0 S'],
  "anonymously": ['AH0 N AA1 N AH0 M AH0 S L IY0'],
  "anopheles": ['AA0 N AO1 F IH0 L IY2 Z'],
  "anora": ['AA0 N AO1 R AH0'],
  "anorak": ['AE1 N ER0 AE2 K'],
  "anorexia": ['AE2 N ER0 EH1 K S IY0 AH0'],
  "anorexic": ['AE2 N ER0 EH1 K S IH0 K'],
  "anorexics": ['AE2 N ER0 EH1 K S IH0 K S'],
  "anorthite": ['AE0 N AO1 R TH AY2 T'],
  "another": ['AH0 N AH1 DH ER0'],
  "another's": ['AH0 N AH1 DH ER0 Z'],
  "anova": ['AH0 N OW1 V AA0'],
  "anreder": ['AE1 N R EH2 D ER0'],
  "anrig": ['AE1 N R IH0 G'],
  "ansa": ['AE1 N S AH0'],
  "ansa's": ['AE1 N S AH0 Z'],
  "ansaid": ['AE1 N S EY2 D'],
  "ansaldo": ['AE0 N S AA1 L D OW0'],
  "ansang": ['AE1 N S AE0 NG'],
  "ansari": ['AA0 N S AA1 R IY0'],
  "ansbacher": ['AE1 N Z B AA2 K ER0'],
  "anschel": ['AE1 N SH AH0 L'],
  "anschluss": ['AE1 N SH L AH0 S', 'AA1 N SH L UW0 S'],
  "anschutz": ['AE1 N SH AH0 T S'],
  "anscom": ['AH0 N S K AA1 M'],
  "anscomb": ['AH0 N S K AA1 M'],
  "anse": ['AE1 N S'],
  "ansel": ['AH0 N S EH1 L'],
  "ansell": ['AE1 N S EH2 L'],
  "anselm": ['AE1 N S EH0 L M'],
  "anselma": ['AA0 N S EH1 L M AH0'],
  "anselmi": ['AA0 N S EH1 L M IY0'],
  "anselmo": ['AA0 N S EH1 L M OW0'],
  "ansett": ['AE1 N S EH2 T'],
  "ansgar": ['AE1 N S G AA0 R'],
  "ansgar's": ['AE1 N S G AA0 R Z'],
  "anshan": ['AE1 N SH AH0 N'],
  "ansi": ['AE2 N S IY1'],
  "ansin": ['AE1 N S IH0 N'],
  "ansley": ['AE1 N S L IY0'],
  "ansohn": ['AE1 N S AH0 N'],
  "anson": ['AE1 N S AH0 N'],
  "ansonia": ['AE0 N S OW1 N IY0 AH0'],
  "anspach": ['AE1 N S P AA2 K'],
  "anspaugh": ['AH0 N S P AO1'],
  "anstead": ['AE1 N S T EH2 D'],
  "anstett": ['AH0 N S T EH1 T'],
  "anstey": ['AE1 N S T IY0'],
  "anstice": ['AA1 N S T IH0 S'],
  "anstine": ['AA0 N S T IY1 N IY0'],
  "anstiss": ['AE1 N S T IH0 S'],
  "answer": ['AE1 N S ER0'],
  "answer's": ['AE1 N S ER0 Z'],
  "answerable": ['AE1 N S ER0 AH0 B AH0 L'],
  "answered": ['AE1 N S ER0 D'],
  "answering": ['AE1 N S ER0 IH0 NG'],
  "answers": ['AE1 N S ER0 Z'],
  "ant": ['AE1 N T'],
  "antacid": ['AE0 N T AE1 S AH0 D'],
  "antacids": ['AE0 N T AE1 S AH0 D Z'],
  "antagonism": ['AE0 N T AE1 G AH0 N IH2 Z AH0 M'],
  "antagonisms": ['AE0 N T AE1 G AH0 N IH2 Z AH0 M Z'],
  "antagonist": ['AE0 N T AE1 G AH0 N AH0 S T'],
  "antagonistic": ['AE0 N T AE2 G AH0 N IH1 S T IH0 K'],
  "antagonists": ['AE0 N T AE1 G AH0 N AH0 S T S'],
  "antagonize": ['AE0 N T AE1 G AH0 N AY2 Z'],
  "antagonized": ['AE0 N T AE1 G AH0 N AY2 Z D'],
  "antagonizing": ['AE0 N T AE1 G AH0 N AY2 Z IH0 NG'],
  "antal": ['AE1 N T AH0 L'],
  "antar": ['AE1 N T ER0'],
  "antar's": ['AE1 N T ER0 Z'],
  "antarctic": ['AE0 N T AA1 R K T IH0 K', 'AE0 N AA1 R T IH0 K'],
  "antarctica": ['AE2 N T AA1 R K T IH0 K AH0', 'AE2 N AA1 R T IH0 K AH0'],
  "antarctica's": ['AE2 N T AA1 R K T IH0 K AH0 Z'],
  "antares": ['AE0 N T EH1 R IY0 Z'],
  "antaya": ['AA0 N T EY1 AH0'],
  "antczak": ['AE1 N T CH AE0 K'],
  "ante": ['AE1 N T IY0'],
  "anteater": ['AE1 N T IY2 T ER0'],
  "anteaters": ['AE1 N T IY2 T ER0 Z'],
  "antebellum": ['AE2 N T IH0 B EH1 L AH0 M'],
  "antec": ['AE1 N T EH2 K'],
  "antecedent": ['AE2 N T EH1 S AH0 D AH0 N T', 'AE2 N T IH0 S IY1 D AH0 N T'],
  "antecedents": ['AE2 N T IH0 S IY1 D AH0 N T S', 'AE2 N T EH1 S AH0 D AH0 N T S'],
  "antechamber": ['AE1 N T EH0 CH EY2 M B ER0'],
  "anted": ['AE1 N T IH0 D', 'AE1 N T IY0 D'],
  "antell": ['AE0 N T EH1 L'],
  "antelope": ['AE1 N T AH0 L OW2 P'],
  "antemeridian": ['AE2 N T AH0 M EH0 R IH1 D IY0 AH0 N'],
  "antemortem": ['AE0 N T AH0 M AO1 R T AH0 M'],
  "antenna": ['AE0 N T EH1 N AH0'],
  "antenna's": ['AE0 N T EH1 N AH0 Z'],
  "antennae": ['AE0 N T EH1 N IY0'],
  "antennas": ['AE0 N T EH1 N AH0 Z'],
  "antenne": ['AE0 N T EH1 N'],
  "antenucci": ['AE0 N T IH0 N UW1 CH IY0'],
  "anterior": ['AE0 N T IH1 R IY0 ER0'],
  "anteriormost": ['AE0 N T IH1 R IY0 ER0 M OW2 S T'],
  "antes": ['AE1 N T IY0 Z'],
  "anthea": ['AE1 N TH IY0 AH0'],
  "anthem": ['AE1 N TH AH0 M'],
  "anthems": ['AE1 N TH AH0 M Z'],
  "anthes": ['AE1 N DH AH0 Z'],
  "anthiel": ['AE1 N TH IY0 AH0 L'],
  "anthill": ['AE1 N T HH IH2 L'],
  "anthills": ['AE1 N T HH IH2 L Z'],
  "anthis": ['AE1 N TH IH0 S'],
  "anthologies": ['AE0 N TH AA1 L AH0 JH IY0 Z'],
  "anthologist": ['AE0 N TH AO1 L AH0 G IH2 S T'],
  "anthologists": ['AE0 N TH AO1 L AH0 G IH2 S T S'],
  "anthology": ['AE0 N TH AA1 L AH0 JH IY0'],
  "anthon": ['AE1 N TH AH0 N'],
  "anthony": ['AE1 N TH AH0 N IY0'],
  "anthony's": ['AE1 N TH AH0 N IY0 Z'],
  "anthracite": ['AE1 N TH R AH0 S AY2 T'],
  "anthrax": ['AE1 N TH R AE0 K S'],
  "anthrobot": ['AE1 N TH R OW0 B AH2 T'],
  "anthropocentric": ['AE2 N TH R AH0 P AH0 S EH1 N T R IH0 K'],
  "anthropogenic": ['AE2 N TH R AH0 P AH0 JH EH1 N IH0 K'],
  "anthropological": ['AE2 N TH R AH0 P AH0 L AA1 JH AH0 K AH0 L'],
  "anthropologist": ['AE2 N TH R AH0 P AA1 L AH0 JH AH0 S T', 'AE2 N TH R AH0 P AA1 L AH0 JH IH0 S T'],
  "anthropologists": ['AE2 N TH R AH0 P AA1 L AH0 JH AH0 S T S', 'AE2 N TH R AH0 P AA1 L AH0 JH IH0 S T S'],
  "anthropology": ['AE2 N TH R AH0 P AA1 L AH0 JH IY0'],
  "anthropomorphic": ['AE2 N TH R AH0 P AH0 M AO1 R F IH0 K'],
  "anthropomorphism": ['AE2 N TH R AH0 P AH0 M AO1 R F IH0 Z AH0 M'],
  "anti": ['AE1 N T IY2', 'AE1 N T AY2'],
  "anti-catholicism": ['AE1 N T IY0 K AH0 TH AO1 L AH0 S IH2 Z AH0 M', 'AE1 N T AY0 K AH0 TH AO1 L AH0 S IH2 Z AH0 M'],
  "anti-doping": ['AE2 N T IY0 D OW1 P IH0 NG'],
  "anti-federalist": ['AE2 N T IY0 F EH1 D R AH0 L IH0 S T'],
  "anti-federalists": ['AE2 N T IY0 F EH1 D R AH0 L AH0 S T S'],
  "anti-infective": ['AE1 N T IY0 IH0 N F EH1 K T IH0 V'],
  "anti-war": ['AE2 N T IY0 W AO1 R'],
  "antiabortion": ['AE1 N T IY0 AH0 B AO1 R SH AH0 N', 'AE1 N T AY0 AH0 B AO1 R SH AH0 N'],
  "antiaircraft": ['AE2 N T IY0 EH1 R K R AE2 F T', 'AE2 N T AY0 EH1 R K R AE2 F T'],
  "antibacterial": ['AE2 N T IY0 B AE0 K T IH1 R IY0 AH0 L'],
  "antiballistic": ['AE2 N T IY0 B AH0 L IH1 S T IH0 K'],
  "antibiotic": ['AE2 N T IY0 B AY0 AA1 T IH0 K', 'AE2 N T AY0 B AY0 AA1 T IH0 K'],
  "antibioticos": ['AE2 N T IY0 B AY0 AA1 T IH0 K OW0 S'],
  "antibiotics": ['AE2 N T IY0 B AY0 AA1 T IH0 K S', 'AE2 N T AY0 B AY0 AA1 T IH0 K S'],
  "antibodies": ['AE1 N T IH0 B AA2 D IY0 Z', 'AE1 N T IY0 B AA2 D IY0 Z', 'AE1 N IH0 B AA2 D IY0 Z'],
  "antibody": ['AE1 N T IH0 B AA2 D IY0', 'AE1 N T IY0 B AA2 D IY0'],
  "antic": ['AE1 N T IH0 K'],
  "anticancer": ['AE2 N T AY2 K AE1 N S ER0', 'AE2 N T IY2 K AE1 N S ER0'],
  "anticipate": ['AE0 N T IH1 S AH0 P EY2 T'],
  "anticipated": ['AE0 N T IH1 S AH0 P EY2 T IH0 D'],
  "anticipates": ['AE0 N T IH1 S AH0 P EY2 T S'],
  "anticipating": ['AE0 N T IH1 S AH0 P EY2 T IH0 NG'],
  "anticipation": ['AE0 N T IH2 S AH0 P EY1 SH AH0 N', 'AE0 N T IH0 S AH0 P EY1 SH AH0 N'],
  "anticipations": ['AE0 N T IH2 S AH0 P EY1 SH AH0 N Z', 'AE0 N T IH0 S AH0 P EY1 SH AH0 N Z'],
  "anticipatory": ['AE0 N T IH1 S AH0 P AH0 T AO2 R IY0'],
  "anticlimactic": ['AE2 N T IY0 K L AY0 M AE1 K T IH0 K'],
  "anticline": ['AE1 N T IH0 K L AY2 N'],
  "antico": ['AA0 N T IY1 K OW0'],
  "anticommunist": ['AE2 N T IY0 K AA1 M Y AH0 N IH0 S T'],
  "anticompetitive": ['AE2 N T IH0 K AH0 M P EH1 T IH0 T IH0 V'],
  "anticorruption": ['AE2 N T AY2 K ER0 AH1 P SH AH0 N', 'AE2 N T IY2 K ER0 AH1 P SH AH0 N'],
  "anticrime": ['AE1 N T IY0 K R AY1 M', 'AE1 N T AY0 K R AY1 M'],
  "antics": ['AE1 N T IH0 K S'],
  "anticyclone": ['AE2 N T IY0 S AY1 K L OW2 N'],
  "anticyclones": ['AE2 N T IY0 S AY1 K L OW2 N Z'],
  "antidepressant": ['AE2 N T IY0 D IH0 P R EH1 S AH0 N T'],
  "antidepressants": ['AE2 N T AY2 D IH0 P R EH1 S AH0 N T S', 'AE2 N T IY2 D IH0 P R EH1 S AH0 N T S'],
  "antidiscrimination": ['AE2 N T IY0 D IH0 S K R IH2 M AH0 N EY1 SH AH0 N', 'AE2 N T AY0 D IH0 S K R IH2 M AH0 N EY1 SH AH0 N'],
  "antidisestablishmentarianism": ['AE2 N T AY0 D IH2 S AH0 S T AE2 B L IH0 SH M AH0 N T EH1 R IY0 AH0 N IH2 Z AH0 M'],
  "antidote": ['AE1 N T IH0 D OW2 T', 'AE1 N IH0 D OW2 T'],
  "antidrug": ['AE2 N T IH0 D R AH1 G'],
  "antidumping": ['AE2 N T IY0 D AH1 M P IH0 NG', 'AE2 N T AY0 D AH1 M P IH0 NG'],
  "antietam": ['AE2 N T IY1 T AH0 M'],
  "antifraud": ['AE1 N T IY0 F R AA2 D', 'AE1 N T AY0 F R AA2 D'],
  "antifreeze": ['AE1 N T IY0 F R IY2 Z'],
  "antifungal": ['AE2 N T AY2 F AH1 NG G AH0 L', 'AE2 N T IY2 F AH1 NG G AH0 L'],
  "antigay": ['AE2 N T AY2 G EY1', 'AE2 N T IY2 G EY1'],
  "antigen": ['AE1 N T AH0 JH AH0 N'],
  "antigens": ['AE1 N T IH0 JH AH0 N Z'],
  "antigone": ['AE0 T IH1 G AH0 N IY0'],
  "antigone's": ['AE0 N T IH1 G AH0 N IY2 Z'],
  "antigones": ['AE0 N T IH1 G AH0 N IY2 Z'],
  "antigovernment": ['AE2 N T IY0 G AH1 V ER0 M AH0 N T', 'AE2 N T AY0 G AH1 V ER0 M AH0 N T'],
  "antigua": ['AE0 N T IY1 G W AH0'],
  "antihistamine": ['AE2 N T IY0 HH IH1 S T AH0 M AH0 N'],
  "antihistamines": ['AE2 N T IY0 HH IH1 S T AH0 M AH0 N Z'],
  "antiknock": ['AE2 N T IY0 N AA1 K'],
  "antill": ['AE0 N T IH1 L'],
  "antilla": ['AA0 N T IH1 L AH0'],
  "antilles": ['AE0 N T IH1 L IY0 Z'],
  "antilock": ['AE1 N T IY0 L AA1 K', 'AE1 N T AY0 L AA1 K'],
  "antimatter": ['AE0 T AY0 M AE1 T ER0'],
  "antimissile": ['AE2 N T AY2 M IH1 S AH0 L'],
  "antinomy": ['AE0 T IH1 N OW0 M IY2'],
  "antioch": ['AE1 N T IY0 AA2 K'],
  "antiochus": ['AE0 N T AY1 AH0 K AH0 S'],
  "antioxidant": ['AE2 N T IY0 AA1 K S AH0 D AH0 N T'],
  "antioxidants": ['AE2 N T IY0 AA1 K S AH0 D AH0 N T S'],
  "antipasto": ['AE2 N T IY0 P AA1 S T OW0'],
  "antipathies": ['AE0 N T IH1 P AH0 TH IY0 Z'],
  "antipathy": ['AE0 N T IH1 P AH0 TH IY0'],
  "antipersonnel": ['AE2 N T IY0 P ER0 S AH0 N EH1 L'],
  "antiphon": ['AE1 N T AH0 F AA2 N', 'AE1 N T IH0 F AA2 N'],
  "antiphons": ['AE1 N T AH0 F AA2 N Z', 'AE1 N T IH0 F AA2 N Z'],
  "antipodal": ['AE0 N T IH1 P AH0 D AH0 L'],
  "antipode": ['AE2 N T IH0 P OW2 D'],
  "antipodes": ['AE2 N T IH0 P OW2 D IY0 Z'],
  "antipoverty": ['AE2 N T AY0 P AA1 V ER0 T IY0', 'AE2 N T IY0 P AA1 V ER0 T IY0'],
  "antiquate": ['AE1 N T AH0 K W EY2 T'],
  "antiquated": ['AE1 N T AH0 K W EY2 T IH0 D'],
  "antique": ['AE0 N T IY1 K'],
  "antiques": ['AE0 N T IY1 K S'],
  "antiquities": ['AE0 N T IH1 K W AH0 T IY0 Z'],
  "antiquity": ['AE0 N T IH1 K W AH0 T IY0'],
  "antireformer": ['AE2 N T IY0 R IH0 F AO1 R M ER0', 'AE2 N T AY0 R IH0 F AO1 R M ER0'],
  "antireformers": ['AE2 N T IY0 R IH0 F AO1 R M ER0 Z', 'AE2 N T AY0 R IH0 F AO1 R M ER0 Z'],
  "antis": ['AE1 N T AY0 Z'],
  "antisense": ['AE1 N T IY0 S EH2 N S', 'AE1 N T AY0 S EH2 N S'],
  "antiseptic": ['AE2 N T AH0 S EH1 P T IH0 K'],
  "antismoking": ['AE1 N T IY0 S M OW1 K IH0 NG', 'AE1 N T AY0 S M OW1 K IH0 NG'],
  "antisocial": ['AE2 N T IH0 S OW1 SH AH0 L', 'AE2 N T AY0 S OW1 SH AH0 L'],
  "antisubmarine": ['AE2 N T IH0 S AH1 B M ER0 IY2 N', 'AE2 N T AY0 S AH1 B M ER0 IY2 N'],
  "antitakeover": ['AE2 N T IY0 T EY1 K OW2 V ER0'],
  "antitank": ['AE2 N T IY0 T AE1 NG K'],
  "antitax": ['AE2 N T AY2 T AE1 K S', 'AE2 N T IY2 T AE1 K S'],
  "antitheft": ['AE2 N T AY2 TH EH1 F T', 'AE2 N T IY2 TH EH1 F T'],
  "antithesis": ['AE0 N T IH1 TH AH0 S AH0 S'],
  "antithetical": ['AE2 N T AH0 TH EH1 T IH0 K AH0 L'],
  "antitoxin": ['AE2 N T IY0 T AA1 K S AH0 N'],
  "antitoxins": ['AE2 N T IY0 T AA1 K S AH0 N Z'],
  "antitrust": ['AE2 N T AY0 T R AH1 S T'],
  "antiviral": ['AE2 N T IY0 V AY1 R AH0 L'],
  "antivirus": ['AE2 N T IY0 V AY1 R AH0 S'],
  "antiwar": ['AE2 N T AY0 W AO1 R', 'AE2 N T IY0 W AO1 R'],
  "antkowiak": ['AH0 N T K AW1 IY0 AE0 K'],
  "antle": ['AE1 N T AH0 L'],
  "antler": ['AE1 N T L ER0'],
  "antlered": ['AE1 N T L ER0 D'],
  "antlers": ['AE1 N T L ER0 Z'],
  "antley": ['AE1 N T L IY0'],
  "antoine": ['AA0 N T W AA1 N'],
  "antoinette": ['AE2 N T W AH0 N EH1 T'],
  "antol": ['AA0 N T AO1 L'],
  "antolik": ['AE1 N T AH0 L IH0 K'],
  "antolini": ['AE2 N T OW0 L IY1 N IY0'],
  "anton": ['AE1 N T AO2 N'],
  "antonacci": ['AA0 N T OW0 N AA1 CH IY0'],
  "antone": ['AA0 N T OW1 N IY0'],
  "antonelli": ['AA0 N T OW0 N EH1 L IY0'],
  "antonellis": ['AE0 N T AH0 N EH1 L IH0 S'],
  "antonetti": ['AA0 N T OW0 N EH1 T IY0'],
  "antoni": ['AA0 N T OW1 N IY0'],
  "antonia": ['AE0 N T OW1 N IY0 AH0'],
  "antonin": ['AE1 N T AH0 N IH0 N'],
  "antonini": ['AA0 N T OW0 N IY1 N IY0'],
  "antonini's": ['AA0 N T OW0 N IY1 N IY0 Z'],
  "antonino": ['AE2 N T OW0 N IY1 N OW0'],
  "antonio": ['AE0 N T OW1 N IY2 OW0'],
  "antonio's": ['AE0 N T OW1 N IY2 OW2 Z'],
  "antonios": ['AE0 N T OW1 N IY2 OW2 Z'],
  "antoniou": ['AA0 N T OW0 N IY1 UW0'],
  "antoniu": ['AE2 N T OW1 N IY0 UW0'],
  "antoniu's": ['AE0 N T OW1 N IY0 UW0 Z'],
  "antonius": ['AE0 N T OW1 N IY0 AH0 S'],
  "antonopoulos": ['AE0 N T AH0 N AA1 P AH0 L IH0 S'],
  "antonovich": ['AE2 N T AA1 N AH0 V IH0 CH'],
  "antonsen": ['AH0 N T AA1 N S AH0 N'],
  "antonson": ['AE1 N T AH0 N S AH0 N'],
  "antonucci": ['AA0 N T OW0 N UW1 CH IY0'],
  "antony": ['AE1 N T AH0 N IY0'],
  "antoon": ['AE2 N T UW1 N'],
  "antos": ['AA1 N T OW0 Z'],
  "antosh": ['AH0 N T AA1 SH'],
  "antril": ['AE1 N T R IH0 L'],
  "antrim": ['AE1 N T R IH0 M'],
  "antrobus": ['AE1 N T R AH0 B IH0 S'],
  "antroscope": ['AE1 N T R AH0 S K OW2 P'],
  "ants": ['AE1 N T S'],
  "antsy": ['AE1 N T S IY0'],
  "anttila": ['AA0 N T IY1 L AH0'],
  "antuna": ['AA0 N T UW1 N AH0'],
  "antunes": ['AA0 N T UW1 N EH0 S'],
  "antunez": ['AA0 N T UW1 N EH0 Z'],
  "antwerp": ['AE1 N T W ER0 P'],
  "antwine": ['AE1 N T W AY2 N'],
  "anubis": ['AH0 N UW1 B IH0 S'],
  "anuradhapura": ['AA2 N UW0 R AA1 D AH0 P UW2 R AA0'],
  "anus": ['EY1 N AH0 S'],
  "anvil": ['AE1 N V AH0 L'],
  "anwar": ['AE1 N W AA0 R', 'AA1 N W AA0 R'],
  "anway": ['AH0 N W EY1'],
  "anwell": ['AH0 N W EH1 L'],
  "anwyl": ['AE1 N W IH0 L'],
  "anwyll": ['AE1 N W IH0 L'],
  "anxieties": ['AE0 NG Z AY1 AH0 T IY0 Z'],
  "anxiety": ['AE0 NG Z AY1 AH0 T IY0'],
  "anxious": ['AE1 NG K SH AH0 S', 'AE1 NG SH AH0 S'],
  "anxiously": ['AE1 NG K SH AH0 S L IY0'],
  "any": ['EH1 N IY0'],
  "anybody": ['EH1 N IY0 B AH0 D IY0'],
  "anybody's": ['EH1 N IY0 B AH0 D IY0 Z'],
  "anyhow": ['EH1 N IY0 HH AW2'],
  "anymore": ['EH2 N IY0 M AO1 R'],
  "anyon": ['EH1 N IY0 AA0 N'],
  "anyone": ['EH1 N IY0 W AH2 N', 'EH1 N IY0 W AH0 N'],
  "anyone'll": ['EH1 N IY0 W AH2 N AH0 L'],
  "anyone's": ['EH1 N IY0 W AH2 N Z'],
  "anyplace": ['EH1 N IY0 P L EY2 S'],
  "anything": ['EH1 N IY0 TH IH2 NG'],
  "anything's": ['EH1 N IY0 TH IH2 NG Z'],
  "anytime": ['EH1 N IY0 T AY2 M'],
  "anytime'll": ['EH1 N IY0 T AY2 M AH0 L'],
  "anytime's": ['EH1 N IY0 T AY2 M Z'],
  "anyway": ['EH1 N IY0 W EY2'],
  "anyways": ['EH1 N IY0 W EY2 Z'],
  "anywhere": ['EH1 N IY0 W EH2 R', 'EH1 N IY0 HH W EH2 R'],
  "anza": ['AE1 N Z AH0'],
  "anzac": ['AE1 N Z AE0 K'],
  "anzaldua": ['AA0 N Z AA0 L D UW1 AH0'],
  "anzalone": ['AE1 N Z AH0 L OW2 N'],
  "anzelmo": ['AA0 N Z EH1 L M OW0'],
  "anzio": ['AE1 N Z IY2 OW0'],
  "anzus": ['AE1 N Z AH0 S'],
  "aoi": ['AW1 IY0'],
  "aoki": ['EY0 OW1 K IY0'],
  "aol": ['EY1 OW1 EH1 L', 'AH0 M ER1 IH0 K AH0 AA1 N L AY2 N'],
  "aon": ['EY1 OW0 N'],
  "aorta": ['EY0 AO1 R T AH0'],
  "aortic": ['EY0 AO1 R T IH0 K'],
  "aoshima": ['AW2 SH IY1 M AH0', 'EY2 OW0 SH IY1 M AH0'],
  "aoun": ['AW1 AH0 N', 'AW2 UW1 N'],
  "aoun's": ['AW1 AH0 N Z', 'AW2 UW1 N Z'],
  "aouzou": ['AW2 Y UW1 Z UW0'],
  "aoyama": ['AW2 Y AA1 M AH0', 'EY2 OW0 Y AA1 M AH0'],
  "ap": ['EY2 P IY1'],
  "apace": ['AH0 P EY1 S'],
  "apache": ['AH0 P AE1 CH IY0'],
  "apache's": ['AH0 P AE1 CH IY0 Z'],
  "apaches": ['AH0 P AE1 CH IY0 Z'],
  "apacs": ['EY1 P AE0 K S'],
  "apalachicola": ['AE2 P AH0 L AE2 CH AH0 K OW1 L AH0'],
  "apalachicola's": ['AE2 P AH0 L AE2 CH AH0 K OW1 L AH0 Z'],
  "aparicio": ['AE2 P ER0 IH1 S IY0 OW0'],
  "apart": ['AH0 P AA1 R T'],
  "apartheid": ['AH0 P AA1 R T AY2 T', 'AH0 P AA1 R T AY2 D'],
  "apartheid's": ['AH0 P AA1 R T AY2 T S', 'AH0 P AA1 R T AY2 D Z'],
  "apartment": ['AH0 P AA1 R T M AH0 N T'],
  "apartment's": ['AH0 P AA1 R T M AH0 N T S'],
  "apartments": ['AH0 P AA1 R T M AH0 N T S'],
  "apathetic": ['AE2 P AH0 TH EH1 T IH0 K'],
  "apathy": ['AE1 P AH0 TH IY0'],
  "apatite": ['AE1 P AH0 T AY2 T'],
  "apatites": ['AE1 P AH0 T AY2 T S'],
  "apc": ['EY1 P IY1 S IY1'],
  "apc's": ['EY1 P IY1 S IY1 Z'],
  "apcar": ['AE1 P K AA0 R', 'AE1 P G AA0 R'],
  "ape": ['EY1 P'],
  "apec": ['EY1 P EH2 K'],
  "apec's": ['EY1 P EH2 K S'],
  "apel": ['AA0 P EH1 L'],
  "apelike": ['EY1 P L AY2 K'],
  "apennine": ['AE1 P AH0 N IY2 N'],
  "apercu": ['AE1 P ER0 S UW2'],
  "aperitif": ['AH0 P EH2 R AH0 T IY1 F'],
  "aperture": ['AE1 P ER0 CH ER0'],
  "apes": ['EY1 P S'],
  "apex": ['EY1 P EH2 K S'],
  "apex's": ['EY1 P EH2 K S IH0 Z'],
  "apfel": ['AE1 P F AH0 L'],
  "apfelbaum": ['AE1 P F AH0 L B AW2 M'],
  "apgar": ['AE1 P G ER0'],
  "aphasia": ['AH0 F EY1 ZH AH0'],
  "aphid": ['AE1 F AH0 D', 'EY1 F AH0 D'],
  "aphids": ['AE1 F IH0 D Z', 'EY1 F AH0 D Z'],
  "aphorism": ['AE1 F ER0 IH2 Z AH0 M'],
  "aphorisms": ['AE1 F ER0 IH2 Z AH0 M Z'],
  "aphrodisiac": ['AE2 F R OW0 D IY1 Z IY0 AE0 K'],
  "aphrodite": ['AE2 F R AH0 D AY1 T IY0'],
  "aphrodite's": ['AE2 F R AH0 D AY1 T IY0 Z'],
  "aphrodites": ['AE2 F R AH0 D AY1 T IY0 Z'],
  "api": ['EY2 P IY2 AY1'],
  "apia": ['AA1 P IH0 AA2'],
  "apical": ['AE1 P IH0 K AH0 L'],
  "apicella": ['AE2 P IH0 S EH1 L AH0'],
  "apiece": ['AH0 P IY1 S'],
  "aping": ['EY1 P IH0 NG'],
  "aplenty": ['AH0 P L EH1 N T IY0'],
  "aplin": ['AE1 P L IH0 N'],
  "aplomb": ['AH0 P L AA1 M'],
  "apnea": ['AE1 P N IY0 AH0'],
  "apo": ['EY2 P IY2 OW1'],
  "apocalypse": ['AH0 P AA1 K AH0 L IH2 P S'],
  "apocalyptic": ['AH0 P AA2 K AH0 L IH1 P T IH0 K'],
  "apocryphal": ['AH0 P AA1 K R AH0 F AH0 L'],
  "apodaca": ['AA0 P OW0 D AA1 K AH0'],
  "apogee": ['AE1 P AH0 JH IY2'],
  "apolitical": ['EY2 P AH0 L IH1 T IH0 K AH0 L'],
  "apolline": ['AE1 P AH0 L AY2 N'],
  "apollinian": ['AE2 P AH0 L IH1 N IY0 AH0 N'],
  "apollo": ['AH0 P AA1 L OW0'],
  "apollo's": ['AH0 P AA1 L OW0 Z'],
  "apollonian": ['AE2 P AH0 L OW1 N IY0 AH0 N'],
  "apologetic": ['AH0 P AA2 L AH0 JH EH1 T IH0 K'],
  "apologetically": ['AH0 P AA2 L AH0 JH EH1 T IH0 K L IY2'],
  "apologies": ['AH0 P AA1 L AH0 JH IY2 Z'],
  "apologise": ['AH0 P AA1 L AH0 JH AY2 Z'],
  "apologised": ['AH0 P AA1 L AH0 JH AY2 Z D'],
  "apologises": ['AH0 P AA1 L AH0 JH AY2 Z IH0 Z'],
  "apologising": ['AH0 P AA1 L AH0 JH AY2 Z IH0 NG'],
  "apologist": ['AH0 P AA1 L AH0 JH AH0 S T'],
  "apologists": ['AH0 P AA1 L AH0 JH AH0 S T S'],
  "apologize": ['AH0 P AA1 L AH0 JH AY2 Z'],
  "apologized": ['AH0 P AA1 L AH0 JH AY2 Z D'],
  "apologizes": ['AH0 P AA1 L AH0 JH AY2 Z IH0 Z'],
  "apologizing": ['AH0 P AA1 L AH0 JH AY2 Z IH0 NG'],
  "apology": ['AH0 P AA1 L AH0 JH IY2'],
  "aponte": ['AH0 P AA1 N T IY0'],
  "apopa": ['AH0 P OW1 P AH0'],
  "apopka": ['AH0 P AO1 P K AH0'],
  "apoplectic": ['AE2 P AH0 P L EH1 K T IH0 K'],
  "apoplexy": ['AE1 P AH0 P L EH2 K S IY0'],
  "apoptosis": ['AE2 P AO0 P T OW1 S IH0 Z'],
  "apostate": ['AH0 P AO1 S T EY0 T'],
  "apostle": ['AH0 P AA1 S AH0 L'],
  "apostles": ['AH0 P AA1 S AH0 L Z'],
  "apostol": ['AE1 P AH0 S T AH0 L'],
  "apostolic": ['AE2 P AH0 S T AO1 L IH0 K'],
  "apostolopoul": ['AH0 P AA2 S T OW0 L OW0 P UW1 L'],
  "apostrophe": ['AH0 P AA1 S T R AH0 F IY2'],
  "apostrophes": ['AH0 P AA1 S T R AH0 F IY2 Z'],
  "apothecary": ['AH0 P AA1 TH AH0 K EH2 R IY0'],
  "apotheosis": ['AH0 P AA2 TH IY0 OW1 S AH0 S'],
  "app": ['AE1 P'],
  "appalachia": ['AE2 P AH0 L AE1 CH IY0 AH0'],
  "appalachian": ['AE2 P AH0 L EY1 CH AH0 N', 'AE2 P AH0 L EY1 SH AH0 N', 'AE2 P AH0 L AE1 CH AH0 N'],
  "appalachians": ['AE2 P AH0 L EY1 CH AH0 N Z', 'AE2 P AH0 L EY1 SH AH0 N Z', 'AE1 P AH0 L AE1 CH AH0 N Z'],
  "appall": ['AH0 P AO1 L'],
  "appalled": ['AH0 P AO1 L D'],
  "appalling": ['AH0 P AO1 L IH0 NG'],
  "appallingly": ['AH0 P AO1 L IH0 NG L IY0'],
  "appalls": ['AH0 P AO1 L Z'],
  "apparatchik": ['AE2 P ER0 AE1 CH IH0 K'],
  "apparatchiks": ['AE2 P ER0 AE1 CH IH0 K S'],
  "apparatus": ['AE2 P ER0 AE1 T AH0 S'],
  "apparatuses": ['AE2 P ER0 AE1 T AH0 S AH0 Z'],
  "apparel": ['AH0 P AE1 R AH0 L', 'AH0 P EH1 R AH0 L'],
  "apparent": ['AH0 P EH1 R AH0 N T'],
  "apparently": ['AH0 P EH1 R AH0 N T L IY0'],
  "apparition": ['AE2 P ER0 IH1 SH AH0 N'],
  "apparitions": ['AE2 P ER0 IH1 SH AH0 N Z'],
  "appeal": ['AH0 P IY1 L'],
  "appealable": ['AH0 P IY1 L AH0 B AH0 L'],
  "appealed": ['AH0 P IY1 L D'],
  "appealing": ['AH0 P IY1 L IH0 NG'],
  "appealingly": ['AH0 P IY1 L IH0 NG L IY0'],
  "appeals": ['AH0 P IY1 L Z'],
  "appear": ['AH0 P IH1 R'],
  "appearance": ['AH0 P IH1 R AH0 N S'],
  "appearances": ['AH0 P IH1 R AH0 N S AH0 Z'],
  "appeared": ['AH0 P IH1 R D'],
  "appearing": ['AH0 P IH1 R IH0 NG'],
  "appears": ['AH0 P IH1 R Z'],
  "appease": ['AH0 P IY1 Z'],
  "appeased": ['AH0 P IY1 Z D'],
  "appeasement": ['AH0 P IY1 Z M AH0 N T'],
  "appeasing": ['AH0 P IY1 Z IH0 NG'],
  "appel": ['AE1 P AH0 L'],
  "appelbaum": ['AE1 P AH0 L B AW2 M'],
  "appelhans": ['AE1 P IH0 L HH AH0 N Z'],
  "appell": ['AE1 P AH0 L'],
  "appellant": ['AH0 P EH1 L IH0 N T'],
  "appellants": ['AH0 P EH1 L IH0 N T S'],
  "appellate": ['AH0 P EH1 L IH0 T', 'AH0 P EH1 L EY2 T'],
  "appellation": ['AE2 P AH0 L EY1 SH AH0 N'],
  "appellations": ['AE2 P AH0 L EY1 SH AH0 N Z'],
  "appelman": ['AE1 P AH0 L M AH0 N'],
  "appelt": ['AE1 P IH0 L T'],
  "append": ['AH0 P EH1 N D'],
  "appendage": ['AH0 P EH1 N D IH0 JH'],
  "appendages": ['AH0 P EH1 N D IH0 JH IH0 Z'],
  "appendectomies": ['AE2 P AH0 N D EH1 K T AH0 M IY0 Z'],
  "appendectomy": ['AE2 P IH0 N D EH1 K T AH0 M IY0'],
  "appended": ['AH0 P EH1 N D IH0 D'],
  "appendices": ['AH0 P EH1 N D IH0 S IY2 Z'],
  "appendicitis": ['AH0 P EH2 N D IH2 S AY1 T IH0 Z'],
  "appendix": ['AH0 P EH1 N D IH0 K S'],
  "appendixes": ['AH0 P EH1 N D IH0 K S IH0 Z'],
  "appenzeller": ['AE1 P IH0 N Z AH0 L ER0'],
  "apperson": ['AE1 P ER0 S AH0 N'],
  "appert": ['AE1 P ER0 T'],
  "appetite": ['AE1 P AH0 T AY2 T'],
  "appetites": ['AE1 P AH0 T AY2 T S'],
  "appetito": ['AE2 P AH0 T IY1 T OW2'],
  "appetizer": ['AE1 P AH0 T AY2 Z ER0'],
  "appetizers": ['AE1 P AH0 T AY2 Z ER0 Z'],
  "appetizing": ['AE1 P IH0 T AY2 Z IH0 NG'],
  "appia": ['AE1 P IY0 AH0'],
  "appian": ['AE1 P IY0 AH0 N'],
  "applaud": ['AH0 P L AO1 D'],
  "applauded": ['AH0 P L AO1 D IH0 D'],
  "applauding": ['AH0 P L AO1 D IH0 NG'],
  "applauds": ['AH0 P L AO1 D Z'],
  "applause": ['AH0 P L AO1 Z'],
  "apple": ['AE1 P AH0 L'],
  "apple's": ['AE1 P AH0 L Z'],
  "appleans": ['AH0 P L IY1 N Z'],
  "applebaum": ['AE1 P AH0 L B AW2 M'],
  "applebee": ['AE1 P AH0 L B IY2'],
  "applebee's": ['AE1 P AH0 L B IY2 Z'],
  "appleberry": ['AE1 P AH0 L B EH2 R IY0'],
  "appleby": ['AE1 P AH0 L B IY2'],
  "applegarth": ['AE1 P L AH0 G AA2 R TH'],
  "applegate": ['AE1 P AH0 L G EY2 T'],
  "appleman": ['AE1 P AH0 L M AH0 N'],
  "applequist": ['AE1 P AH0 L K W IH0 S T'],
  "apples": ['AE1 P AH0 L Z'],
  "applesauce": ['AE1 P AH0 L S AO2 S'],
  "appleseed": ['AE1 P AH0 L S IY2 D'],
  "appleshare": ['AE1 P AH0 L SH EH2 R'],
  "applet": ['AE1 P L AH0 T'],
  "appleton": ['AE1 P AH0 L T AH0 N'],
  "applewhite": ['AE1 P AH0 L W AY2 T'],
  "appleyard": ['AE1 P AH0 L Y AA2 R D'],
  "appliance": ['AH0 P L AY1 AH0 N S'],
  "appliances": ['AH0 P L AY1 AH0 N S AH0 Z', 'AH0 P L AY1 AH0 N S IH0 Z'],
  "applicability": ['AE2 P L AH0 K AH0 B IH1 L AH0 T IY0'],
  "applicable": ['AE1 P L AH0 K AH0 B AH0 L'],
  "applicant": ['AE1 P L IH0 K AH0 N T'],
  "applicant's": ['AE1 P L IH0 K AH0 N T S'],
  "applicants": ['AE1 P L IH0 K AH0 N T S'],
  "applicants'": ['AE1 P L AH0 K AH0 N T S'],
  "application": ['AE2 P L AH0 K EY1 SH AH0 N'],
  "applications": ['AE2 P L AH0 K EY1 SH AH0 N Z'],
  "applicator": ['AE1 P L AH0 K EY2 T ER0'],
  "applicators": ['AE1 P L AH0 K EY2 T ER0 Z'],
  "applied": ['AH0 P L AY1 D'],
  "applies": ['AH0 P L AY1 Z'],
  "applin": ['AE1 P L IH0 N'],
  "appling": ['AE1 P AH0 L IH0 NG', 'AE1 P L IH0 NG'],
  "apply": ['AH0 P L AY1'],
  "applying": ['AH0 P L AY1 IH0 NG'],
  "appoint": ['AH0 P OY1 N T'],
  "appointed": ['AH0 P OY1 N T IH0 D'],
  "appointee": ['AH0 P OY0 N T IY1', 'AH0 P OY1 N T IY0'],
  "appointees": ['AH0 P OY0 N T IY1 Z', 'AH0 P OY1 N T IY0 Z'],
  "appointing": ['AH0 P OY1 N T IH0 NG'],
  "appointive": ['AH0 P OY1 N T IH0 V'],
  "appointment": ['AH0 P OY1 N T M AH0 N T'],
  "appointments": ['AH0 P OY1 N T M AH0 N T S'],
  "appoints": ['AH0 P OY1 N T S'],
  "apportion": ['AH0 P AO1 R SH AH0 N'],
  "apportioned": ['AH0 P AO1 R SH AH0 N D'],
  "apportioning": ['AH0 P AO1 R SH AH0 N IH0 NG'],
  "apportionment": ['AH0 P AO1 R SH AH0 N M AH0 N T'],
  "appraisal": ['AH0 P R EY1 Z AH0 L'],
  "appraisals": ['AH0 P R EY1 Z AH0 L Z'],
  "appraise": ['AH0 P R EY1 Z'],
  "appraised": ['AH0 P R EY1 Z D'],
  "appraiser": ['AH0 P R EY1 Z ER0'],
  "appraisers": ['AH0 P R EY1 Z ER0 Z'],
  "appraises": ['AH0 P R EY1 Z IH0 Z'],
  "appraising": ['AH0 P R EY1 Z IH0 NG'],
  "appreciable": ['AH0 P R IY1 SH AH0 B AH0 L'],
  "appreciably": ['AH0 P R IY1 SH AH0 B L IY0'],
  "appreciate": ['AH0 P R IY1 SH IY0 EY2 T'],
  "appreciated": ['AH0 P R IY1 SH IY0 EY2 T IH0 D'],
  "appreciates": ['AH0 P R IY1 SH IY0 EY2 T S'],
  "appreciating": ['AH0 P R IY1 SH IY0 EY2 T IH0 NG'],
  "appreciation": ['AH0 P R IY2 SH IY0 EY1 SH AH0 N'],
  "appreciative": ['AH0 P R IY1 SH IY0 EY2 T IH0 V', 'AH0 P R IY1 SH Y AH0 T IH0 V'],
  "appreciatively": ['AH0 P R IY1 SH IY0 EY2 T IH0 V L IY0', 'AH0 P R IY1 SH Y AH0 T IH0 V L IY0'],
  "apprehend": ['AE2 P R IH0 HH EH1 N D'],
  "apprehended": ['AE2 P R IH0 HH EH1 N D IH0 D'],
  "apprehending": ['AE2 P R IH0 HH EH1 N D IH0 NG'],
  "apprehension": ['AE2 P R IH0 HH EH1 N SH AH0 N'],
  "apprehensions": ['AE2 P R IH0 HH EH1 N SH AH0 N Z'],
  "apprehensive": ['AE2 P R IH0 HH EH1 N S IH0 V', 'AE2 P R IY0 HH EH1 N S IH0 V'],
  "apprentice": ['AH0 P R EH1 N T AH0 S', 'AH0 P R EH1 N T IH0 S'],
  "apprenticed": ['AH0 P R EH1 N T AH0 S T'],
  "apprentices": ['AH0 P R EH1 N T AH0 S IH0 Z'],
  "apprenticeship": ['AH0 P R EH1 N T AH0 S SH IH2 P'],
  "apprenticeships": ['AH0 P R EH1 N T AH0 S SH IH2 P S'],
  "apprise": ['AH0 P R AY1 Z'],
  "apprised": ['AH0 P R AY1 Z D'],
  "apprises": ['AH0 P R AY1 Z IH0 Z'],
  "appro": ['AE1 P R OW0'],
  "approach": ['AH0 P R OW1 CH'],
  "approachable": ['AH0 P R OW1 CH AH0 B AH0 L'],
  "approached": ['AH0 P R OW1 CH T'],
  "approaches": ['AH0 P R OW1 CH AH0 Z', 'AH0 P R OW1 CH IH0 Z'],
  "approaching": ['AH0 P R OW1 CH IH0 NG'],
  "approbation": ['AE2 P R AH0 B EY1 SH AH0 N'],
  "appropriate": ['AH0 P R OW1 P R IY0 AH0 T', 'AH0 P R OW1 P R IY0 EY2 T'],
  "appropriated": ['AH0 P R OW1 P R IY0 EY2 T IH0 D'],
  "appropriately": ['AH0 P R OW1 P R IY0 IH0 T L IY0'],
  "appropriateness": ['AH0 P R OW1 P R IY0 AH0 T N AH0 S'],
  "appropriates": ['AH0 P R OW1 P R IY0 EY2 T S'],
  "appropriating": ['AH0 P R OW1 P R IY0 EY2 T IH0 NG'],
  "appropriation": ['AH0 P R OW2 P R IY0 EY1 SH AH0 N'],
  "appropriations": ['AH0 P R OW2 P R IY0 EY1 SH AH0 N Z'],
  "appropriator": ['AH0 P R OW1 P R IY0 EY2 T ER0'],
  "appropriators": ['AH0 P R OW1 P R IY0 EY2 T ER0 Z'],
  "approvable": ['AH0 P R UW1 V AH0 B AH0 L'],
  "approval": ['AH0 P R UW1 V AH0 L'],
  "approvals": ['AH0 P R UW1 V AH0 L Z'],
  "approve": ['AH0 P R UW1 V'],
  "approved": ['AH0 P R UW1 V D'],
  "approves": ['AH0 P R UW1 V Z'],
  "approving": ['AH0 P R UW1 V IH0 NG'],
  "approvingly": ['AH0 P R UW1 V IH0 NG L IY0'],
  "approximate": ['AH0 P R AA1 K S AH0 M AH0 T', 'AH0 P R AA1 K S AH0 M EY2 T'],
  "approximated": ['AH0 P R AA1 K S AH0 M EY2 T IH0 D'],
  "approximately": ['AH0 P R AA1 K S AH0 M AH0 T L IY0'],
  "approximates": ['AH0 P R AA1 K S AH0 M AH0 T S', 'AH0 P R AA1 K S AH0 M EY2 T S'],
  "approximating": ['AH0 P R AA1 K S IH0 M EY2 T IH0 NG'],
  "approximation": ['AH0 P R AA2 K S AH0 M EY1 SH AH0 N'],
  "approximations": ['AH0 P R AA2 K S AH0 M EY1 SH AH0 N Z'],
  "apps": ['AE1 P S'],
  "apra": ['EY1 P R AH0'],
  "aprea": ['AA1 P R IY0 AH0'],
  "apricot": ['EY1 P R AH0 K AA2 T'],
  "apricots": ['AE1 P R AH0 K AA2 T S'],
  "april": ['EY1 P R AH0 L'],
  "april's": ['EY1 P R AH0 L Z'],
  "aprile": ['AA1 P R AH0 L'],
  "apriori": ['AH0 P R IY0 AO1 R IY0'],
  "aprodiva": ['AE2 P R AO0 D IY1 V AH0'],
  "apron": ['EY1 P R AH0 N'],
  "aprons": ['EY1 P R AH0 N Z'],
  "apropos": ['AE2 P R AH0 P OW1'],
  "apsell": ['AE1 P S AH0 L'],
  "apsey": ['AE1 P S IY0'],
  "apso": ['AE1 P S OW0'],
  "apstar": ['AE1 P S T AA2 R'],
  "apt": ['AE1 P T'],
  "apter": ['AE1 P T ER0'],
  "aptitude": ['AE1 P T AH0 T UW2 D'],
  "aptitudes": ['AE1 P T AH0 T UW2 D Z'],
  "aptiva": ['AE2 P T IY1 V AH0'],
  "aptly": ['AE1 P T L IY0'],
  "apuzzo": ['AA0 P UW1 Z OW0'],
  "aqaba": ['AH0 K AA1 B AH0'],
  "aqazadeh": ['AE2 K AH0 Z AA1 D EH2'],
  "aqua": ['AE1 K W AH0', 'AA1 K W AH0'],
  "aquaculture": ['AE1 K W AH0 K AH2 L CH ER0'],
  "aqualung": ['AE1 K W AH0 L AH0 NG'],
  "aquamarine": ['AA2 K W AH0 M ER0 IY1 N'],
  "aquanaut": ['AA1 K W AH0 N AA2 T'],
  "aquanauts": ['AA1 K W AH0 N AA2 T S'],
  "aquarist": ['AH0 K W EH1 R IH0 S T'],
  "aquarists": ['AH0 K W EH1 R IH0 S T S'],
  "aquarium": ['AH0 K W EH1 R IY0 AH0 M'],
  "aquariums": ['AH0 K W EH1 R IY0 AH0 M Z'],
  "aquarius": ['AH0 K W EH1 R IY0 AH0 S'],
  "aquatic": ['AH0 K W AA1 T IH0 K', 'AH0 K W AE1 T IH0 K'],
  "aqueduct": ['AE1 K W AH0 D AH2 K T'],
  "aqueducts": ['AE1 K W AH0 D AH2 K T S'],
  "aqueous": ['EY1 K W IY0 AH0 S'],
  "aquifer": ['AE1 K W AH0 F ER0'],
  "aquifers": ['AE1 K W AH0 F ER0 Z'],
  "aquila": ['AE1 K W AH0 L AH0'],
  "aquilar": ['AA0 K W IY0 L AA1 R'],
  "aquilina": ['AA0 K W IY0 L IY1 N AH0'],
  "aquilino": ['AA0 K W IY0 L IY1 N OW0'],
  "aquinas": ['AH0 K W AY1 N AH0 S'],
  "aquino": ['AH0 K IY1 N OW0'],
  "aquino's": ['AH0 K IY1 N OW0 Z'],
  "aquirre": ['AE1 K W ER0'],
  "aquitaine": ['AE2 K W IH0 T EY1 N'],
  "ar": ['AA1 R'],
  "ara": ['AE1 R AH0'],
  "arab": ['AE1 R AH0 B', 'EY1 R AH0 B'],
  "arabe": ['AA0 R AA1 B EY0'],
  "arabella": ['AE2 R AH0 B EH1 L AH0'],
  "arabia": ['AH0 R EY1 B IY0 AH0'],
  "arabia's": ['AH0 R EY1 B IY0 AH0 Z'],
  "arabian": ['AH0 R EY1 B IY0 AH0 N'],
  "arabians": ['AH0 R EY1 B IY0 AH0 N Z'],
  "arabic": ['AE1 R AH0 B IH0 K', 'EH1 R AH0 B IH0 K'],
  "arabica": ['ER0 AE1 B IH0 K AH0'],
  "arabichon": ['AH0 R AE1 B IH0 SH AO2 N'],
  "arabichon's": ['AH0 R AE1 B IH0 SH AO2 N Z'],
  "arabicon": ['AH0 R AE1 B AH0 K AO2 N'],
  "arabie": ['AE1 R AH0 B IY0'],
  "arable": ['AE1 R AH0 B AH0 L'],
  "arabs": ['AE1 R AH0 B Z', 'EY1 R AH0 B Z'],
  "arabs'": ['AE1 R AH0 B Z'],
  "araby": ['AE1 R AH0 B IY2'],
  "arachnid": ['AH0 R AE1 K N AH0 D', 'ER0 AE1 K N IH0 D'],
  "arachnids": ['ER0 AE1 K N AH0 D Z', 'ER0 AE1 K N IH0 D Z'],
  "arafat": ['EH1 R AH0 F AE0 T'],
  "arafat's": ['AA1 R AH0 F AA2 T S', 'EH1 R AH0 F AE0 T S'],
  "aragon": ['EH1 R AH0 G AA2 N'],
  "aragona": ['AA0 R AA0 G OW1 N AH0'],
  "arai": ['AA0 R AA1 IY0'],
  "araiza": ['ER0 EY1 Z AH0'],
  "arakaki": ['AA0 R AA0 K AA1 K IY0'],
  "arakawa": ['AA2 R AA0 K AA1 W AH0'],
  "arakelian": ['AE0 R AH0 K EH1 L Y AH0 N'],
  "araki": ['AA0 R AA1 K IY0'],
  "aral": ['AA1 R AA0 L'],
  "aramaic": ['AA2 R AA0 M EH1 Y IH0 K'],
  "arambula": ['AA0 R AA0 M B UW1 L AH0'],
  "aramco": ['ER0 AE1 M K OW0'],
  "aramid": ['EH1 R AH0 M IH0 D'],
  "araminta": ['AE2 R AH0 M IH1 N T AH0'],
  "aramis": ['EH1 R AH0 M IH0 S'],
  "aramony": ['AA1 R AH0 M OW2 N IY0', 'EH1 R AH0 M OW2 N IY0'],
  "aran": ['EH1 R AH0 N'],
  "arana": ['AA0 R AE1 N AH0'],
  "aranda": ['ER0 AE1 N D AH0'],
  "arango": ['AA0 R AA1 NG G OW0'],
  "aransas": ['ER0 AE1 N S AH0 S'],
  "arant": ['AA1 R AH0 N T'],
  "arantxa": ['AA1 R AE2 T S AA0'],
  "arantza": ['AH0 R AA1 N T Z AH0'],
  "aranyos": ['ER0 AA1 N Y OW2 S'],
  "arapaho": ['ER0 AE1 P AH0 HH OW2'],
  "arapahoe": ['ER0 AE1 P AH0 HH OW2'],
  "ararat": ['AE1 R AH0 R AA2 T'],
  "araskog": ['ER0 AE1 S AA0 G', 'ER0 AE1 S K AA0 G'],
  "arata": ['AA0 R AA1 T AH0'],
  "arau": ['AH0 R AW1'],
  "araujo": ['AA0 R AW1 Y OW0'],
  "aravind": ['AA1 R V AH0 N D'],
  "arawak": ['AA1 R AA0 W AA2 K'],
  "araya": ['ER0 AY1 AH0'],
  "arb": ['AA1 R B'],
  "arba": ['AA1 R B AH0'],
  "arbat": ['AA1 R B AE0 T'],
  "arbaugh": ['AA1 R B AO2'],
  "arbed": ['AA1 R B IH0 D'],
  "arbeiter": ['AA1 R B AY0 T ER0'],
  "arbel": ['AA1 R B AH0 L'],
  "arbella": ['AA2 R B EH1 L AH0'],
  "arbenz": ['AA1 R B EH0 N Z'],
  "arbiter": ['AA1 R B IH0 T ER0'],
  "arbiters": ['AA1 R B AH0 T ER0 Z'],
  "arbitrage": ['AA1 R B IH0 T R AA2 ZH'],
  "arbitrager": ['AA1 R B IH0 T R AA2 ZH ER0'],
  "arbitragers": ['AA1 R B IH0 T R AA2 ZH ER0 Z'],
  "arbitragers'": ['AA1 R B IH0 T R AA2 ZH ER0 Z'],
  "arbitrageur": ['AA1 R B IH0 T R AA2 ZH ER0'],
  "arbitrageurs": ['AA2 R B AH0 T R AA2 ZH ER1 Z'],
  "arbitraging": ['AA1 R B AH0 T R AA2 JH IH0 NG'],
  "arbitrarily": ['AA1 R B IH0 T R EH2 R AH0 L IY0'],
  "arbitrariness": ['AA1 R B AH0 T R EH2 R IY0 N AH0 S'],
  "arbitrary": ['AA1 R B AH0 T R EH2 R IY0', 'AA1 R B IH0 T R EH2 R IY0'],
  "arbitrate": ['AA1 R B AH0 T R EY2 T'],
  "arbitrated": ['AA1 R B AH0 T R EY2 T IH0 D'],
  "arbitrates": ['AA1 R B IH0 T R EY2 T S'],
  "arbitrating": ['AA1 R B AH0 T R EY2 T IH0 NG'],
  "arbitration": ['AA2 R B IH0 T R EY1 SH AH0 N'],
  "arbitrations": ['AA2 R B IH0 T R EY1 SH AH0 N Z'],
  "arbitrator": ['AA1 R B IH0 T R EY2 T ER0'],
  "arbitrator's": ['AA1 R B AH0 T R EY2 T ER0 Z'],
  "arbitrators": ['AA1 R B IH0 T R EY2 T ER0 Z'],
  "arbitrators'": ['AA1 R B AH0 T R EY2 T ER0 Z'],
  "arbitron": ['AA1 R B AH0 T R AA2 N', 'AA1 R B AH0 T R AH0 N'],
  "arbo": ['AA1 R B OW0'],
  "arbogast": ['AA0 R B OW1 G AA0 S T'],
  "arboleda": ['AA0 R B OW0 L EH1 D AH0'],
  "arbor": ['AA1 R B ER0'],
  "arboreal": ['AA0 R B AO1 R IY0 AH0 L'],
  "arboretum": ['AA2 R B ER0 IY1 T AH0 M'],
  "arborville": ['AA1 R B ER0 V IH0 L'],
  "arbothnott": ['AA2 R B AO1 TH N AO0 T'],
  "arbour": ['AA1 R B ER0'],
  "arbs": ['AA1 R B Z'],
  "arbuckle": ['AA1 R B AH0 K AH0 L'],
  "arbuckles": ['AA1 R B AH0 K AH0 L Z'],
  "arbuthnot": ['AA0 R B AH1 TH N AH0 T'],
  "arby": ['AA1 R B IY0'],
  "arby's": ['AA1 R B IY0 Z'],
  "arc": ['AA1 R K'],
  "arcade": ['AA0 R K EY1 D'],
  "arcades": ['AA0 R K EY1 D Z'],
  "arcadia": ['AA0 R K EY1 D IY0 AH0'],
  "arcadian": ['AA2 R K EY1 D IY0 AH0 N'],
  "arcadians": ['AA2 R K EY1 D IY0 AH0 N Z'],
  "arcana": ['AA0 R K AE1 N AH0'],
  "arcand": ['AA1 R K AH0 N D'],
  "arcane": ['AA2 R K EY1 N'],
  "arcanum": ['AA1 R K AH0 N AH0 M'],
  "arcanum's": ['AA1 R K AH0 N AH0 M Z'],
  "arcaro": ['AA0 R K AA1 R OW0'],
  "arcata": ['AA0 R K AA1 T AH0'],
  "arce": ['AA1 R S'],
  "arcement": ['AA0 R S EY1 M AH0 N T'],
  "arceneaux": ['AA1 R S IH0 N OW0'],
  "arceo": ['AA1 R S IY0 OW0'],
  "arch": ['AA1 R CH'],
  "archaeological": ['AA2 R K IY0 AH0 L AA1 JH IH0 K AH0 L'],
  "archaeologist": ['AA2 R K IY0 AA1 L AH0 JH IH0 S T'],
  "archaeologists": ['AA2 R K IY0 AA1 L AH0 JH IH0 S T S'],
  "archaeology": ['AA2 R K IY0 AA1 L AH0 JH IY0'],
  "archaic": ['AA0 R K EY1 IH0 K'],
  "archambault": ['AA1 R SH AH0 M B OW0'],
  "archambeau": ['AA1 R SH AH0 M B OW2'],
  "archambeault": ['AA1 R SH AH0 M B OW0'],
  "archangel": ['AA2 R K EY1 N JH AH0 L'],
  "archangels": ['AA2 R K EY1 N JH AH0 L Z'],
  "archard": ['AA0 R SH AA1 R D'],
  "archbishop": ['AA1 R CH B IH1 SH AH0 P'],
  "archbold": ['AA1 R CH B OW2 L D'],
  "archdiocese": ['AA1 R CH D AY1 AH0 S AH0 S', 'AA1 R CH D AY1 AH0 S IY0 Z'],
  "archduke": ['AA1 R CH D UW2 K'],
  "arched": ['AA1 R CH T'],
  "archenemy": ['AA1 R CH EH1 N AH0 M IY0'],
  "archeological": ['AA2 R K IY0 AH0 L AA1 JH IH0 K AH0 L'],
  "archeology": ['AA2 R K IY0 AA1 L AH0 JH IY0'],
  "archer": ['AA1 R CH ER0'],
  "archer's": ['AA1 R CH ER0 Z'],
  "archerd": ['AA1 R K ER0 D'],
  "archers": ['AA1 R CH ER0 Z'],
  "archery": ['AA1 R CH ER0 IY0'],
  "arches": ['AA1 R CH AH0 Z', 'AA1 R CH IH0 Z'],
  "archetypal": ['AA1 R K T AY1 P AH0 L'],
  "archetype": ['AA1 R K IH0 T AY2 P'],
  "archetypes": ['AA1 R K IH0 T AY2 P S'],
  "archey": ['AA1 R K IY0'],
  "archibald": ['AA1 R CH AH0 B AO2 L D'],
  "archibeque": ['AA1 R SH IH0 B IY0 K'],
  "archie": ['AA1 R CH IY0'],
  "archimedes": ['AA2 R K AH0 M IY1 D IY0 Z'],
  "arching": ['AA1 R CH IH0 NG'],
  "archipelago": ['AA2 R K AH0 P EH1 L AH0 G OW2', 'AA2 R CH AH0 P AH0 L EY1 G OW2'],
  "archippus": ['AE2 R K IH1 P AH0 S'],
  "architect": ['AA1 R K AH0 T EH2 K T'],
  "architect's": ['AA1 R K AH0 T EH2 K T S'],
  "architects": ['AA1 R K AH0 T EH2 K T S'],
  "architectural": ['AA2 R K AH0 T EH1 K CH ER0 AH0 L'],
  "architecturally": ['AA2 R K IH0 T EH1 K CH ER0 AH0 L IY0', 'AA2 R K IH0 T EH1 K CH R AH0 L IY0'],
  "architecture": ['AA1 R K AH0 T EH2 K CH ER0'],
  "architecture's": ['AA1 R K AH0 T EH2 K CH ER0 Z'],
  "architectures": ['AA1 R K AH0 T EH2 K CH ER0 Z'],
  "architrave": ['AA1 R K AH0 T R EY2 V'],
  "architraves": ['AA1 R K AH0 T R EY2 V Z'],
  "archival": ['AA0 R K AY1 V AH0 L'],
  "archive": ['AA1 R K AY2 V'],
  "archived": ['AA1 R K AY2 V D'],
  "archives": ['AA1 R K AY2 V Z'],
  "archivist": ['AA1 R K AH0 V IH0 S T', 'AA1 R K AY0 V IH0 S T'],
  "archivists": ['AA1 R K AH0 V IH0 S T S', 'AA1 R K AY0 V IH0 S T S'],
  "archly": ['AA1 R CH L IY0'],
  "archrival": ['AA1 R CH R AY2 V AH0 L'],
  "archrivals": ['AA1 R CH R AY2 V AH0 L Z'],
  "archuleta": ['AA0 R K UW0 L EH1 T AH0'],
  "archuletta": ['AA0 R K UW0 L EH1 T AH0'],
  "archway": ['AA1 R CH W EY2'],
  "arcidiacono": ['AA0 R CH IY0 D IY2 AA0 K OW1 N OW2'],
  "arcieri": ['AA0 R CH IH1 R IY0'],
  "arciniega": ['AA0 R CH IY0 N IY1 G AH0'],
  "arco": ['AA1 R K OW0'],
  "arco's": ['AA1 R K OW0 Z'],
  "arcola": ['AA0 R K OW1 L AH0'],
  "arcos": ['AA1 R K OW0 Z'],
  "arcosanti": ['AA2 R K AH0 S AE1 N T IY0'],
  "arcs": ['AA1 R K S'],
  "arctic": ['AA1 R K T IH0 K', 'AA1 R T IH0 K'],
  "arcturus": ['AA2 R K T UH1 R AH0 S', 'AA2 R K T ER1 AH0 S'],
  "arcuri": ['AA0 R K UH1 R IY0'],
  "ard": ['AA1 R D'],
  "arda": ['AA1 R D AH0'],
  "ardath": ['AA1 R D AH0 TH'],
  "ardeen": ['AA0 R D IY1 N'],
  "ardelia": ['AA0 R D EH1 L IY0 AH0'],
  "ardella": ['AA2 R D EH1 L AH0'],
  "ardelle": ['AA0 R D EH1 L'],
  "arden": ['AA1 R D AH0 N'],
  "arden's": ['AA1 R D AH0 N Z'],
  "ardene": ['AA1 R D IY2 N'],
  "ardent": ['AA1 R D AH0 N T'],
  "ardently": ['AA1 R D AH0 N T L IY0'],
  "ardin": ['AA1 R D IH0 N'],
  "ardine": ['AA0 R D IY1 N IY0'],
  "ardis": ['AA1 R D IH0 S'],
  "ardith": ['AA1 R D IH0 TH'],
  "ardito": ['AA0 R D IY1 T OW0'],
  "ardley": ['AA1 R D L IY0'],
  "ardmore": ['AA1 R D M AO2 R'],
  "ardoin": ['AA0 R D OY1 N'],
  "ardolino": ['AA0 R D OW0 L IY1 N OW0'],
  "ardolph": ['AA1 R D OW2 L F'],
  "ardor": ['AA1 R D ER0'],
  "ardoyne": ['AA1 R D OY0 N'],
  "ardra": ['AA1 R D R AH0'],
  "ardrey": ['AA1 R D R IY0'],
  "ardshiel": ['AA1 R D SH IY2 L'],
  "ardsley": ['AA1 R D Z L IY0'],
  "arduini": ['AA0 R D UW0 IY1 N IY0'],
  "arduino": ['AA1 R D W IY1 N OW0'],
  "arduous": ['AA1 R JH UW0 AH0 S'],
  "are": ['AA1 R', 'ER0'],
  "area": ['EH1 R IY0 AH0'],
  "area's": ['EH1 R IY0 AH0 Z'],
  "areas": ['EH1 R IY0 AH0 Z'],
  "arebalo": ['AA0 R EH0 B AA1 L OW0'],
  "arechiga": ['AA0 R EH0 K IY1 G AH0'],
  "arehart": ['AE1 R IH0 HH AA0 R T'],
  "arel": ['AA0 R EH1 L'],
  "arellanes": ['AE1 R IH0 L EY2 N Z'],
  "arellano": ['AA0 R EH0 L AA1 N OW0'],
  "aren": ['AA1 R AH0 N'],
  "aren't": ['AA1 R AH0 N T', 'AA1 R N T'],
  "arena": ['ER0 IY1 N AH0'],
  "arena's": ['ER0 IY1 N AH0 Z'],
  "arenaball": ['ER0 IY1 N AH0 B AO2 L'],
  "arenas": ['ER0 IY1 N AH0 Z'],
  "arencibia": ['AA0 R EH0 N CH IY1 B IY0 AH0'],
  "arend": ['ER0 EH1 N D'],
  "arends": ['ER0 EH1 N D Z'],
  "arendsee": ['ER0 EH1 N D S IY2'],
  "arendt": ['EH1 R IH0 N T'],
  "arenella": ['AA2 R AH0 N EH1 L AH0'],
  "arenella's": ['AA2 R AH0 N EH1 L AH0 Z'],
  "arens": ['EH1 R AH0 N Z', 'AA1 R AH0 N Z'],
  "arenson": ['AE1 R IH0 N S AH0 N'],
  "arent": ['AA1 R AH0 N T'],
  "arentz": ['AE1 R IH0 N T S'],
  "arenz": ['AA1 R EY0 N Z'],
  "arequipa": ['AA2 R EH0 K IY1 P AA0'],
  "ares": ['AA1 R Z'],
  "areta": ['AA0 R EH1 T AH0'],
  "aretha": ['ER0 IY1 TH AH0'],
  "aretina": ['AA0 R EH0 T IY1 N AH0'],
  "aretsky": ['EH0 R EH1 T S K IY1'],
  "aretta": ['AA0 R EH1 T AH0'],
  "arevalo": ['AA0 R EY0 V AA1 L OW0'],
  "arey": ['AE1 R IY0'],
  "argabright": ['AA1 R G AH0 B R AY2 T'],
  "argall": ['AA1 R G AH0 L'],
  "arganbright": ['AA0 R G AE1 N B R AY0 T'],
  "argenbright": ['AA1 R G IH0 N B R AY0 T'],
  "argent": ['AA1 R JH IH0 N T'],
  "argenta": ['AA2 R JH EH1 N T AH0'],
  "argentieri": ['AA0 R JH EH0 N T IH1 R IY0'],
  "argentina": ['AA2 R JH AH0 N T IY1 N AH0'],
  "argentina's": ['AA2 R JH AH0 N T IY1 N AH0 Z'],
  "argentinas": ['AA2 R JH AH0 N T IY1 N AH0 Z'],
  "argentine": ['AA1 R JH AH0 N T IY2 N'],
  "argentines": ['AA1 R JH AH0 N T IY2 N Z'],
  "argentinian": ['AA2 R JH AH0 N T IH1 N IY0 AH0 N'],
  "argentinians": ['AA2 R JH AH0 N T IH1 N IY0 AH0 N Z'],
  "argento": ['AA2 R JH EH1 N T OW0'],
  "argh": ['AA1 R G'],
  "argiro": ['AA0 R JH IH1 R OW0'],
  "argo": ['AA1 R G OW0'],
  "argo's": ['AA1 R G OW0 Z'],
  "argon": ['AA1 R G AA2 N'],
  "argonaut": ['AA1 R G AH0 N AO2 T'],
  "argonaut's": ['AA1 R G AH0 N AO2 T S'],
  "argonauts": ['AA1 R G AH0 N AO2 T S'],
  "argonne": ['AA1 R G AA2 N', 'AA1 R G OW2 N'],
  "argosy": ['AA1 R G AH0 S IY0'],
  "argosystem": ['AA1 R G OW0 S IH2 S T AH0 M'],
  "argosystems": ['AA1 R G OW0 S IH2 S T AH0 M Z'],
  "argot": ['AA1 R G AH0 T'],
  "arguable": ['AA1 R G Y UW0 AH0 B AH0 L'],
  "arguably": ['AA1 R G Y UW0 AH0 B L IY0'],
  "argue": ['AA1 R G Y UW0'],
  "argued": ['AA1 R G Y UW0 D'],
  "arguelles": ['AA0 R G EY1 L EH0 S'],
  "arguello": ['AA0 R G EH1 L OW0'],
  "argues": ['AA1 R G Y UW0 Z'],
  "argueta": ['AA0 R G EY1 T AH0'],
  "arguijo": ['AA0 R G W IY1 Y OW0'],
  "arguing": ['AA1 R G Y UW0 IH0 NG'],
  "argument": ['AA1 R G Y AH0 M AH0 N T'],
  "argument's": ['AA1 R G Y AH0 M AH0 N T S'],
  "argumentation": ['AA2 R G Y AH0 M EH0 N T EY1 SH AH0 N'],
  "argumentative": ['AA2 R G Y AH0 M EH1 N T AH0 T IH0 V'],
  "arguments": ['AA1 R G Y AH0 M AH0 N T S'],
  "argus": ['AA1 R G AH0 S'],
  "argyle": ['AA1 R G AY2 L'],
  "argyll": ['AA0 R G AY1 L'],
  "argyropoulos": ['AA2 R JH IH0 R AA1 P OW0 L AH0 S'],
  "ari": ['AA1 R IY0'],
  "aria": ['AA1 R IY0 AH0'],
  "ariadne": ['EH2 R IY0 AE1 D N IY0'],
  "arial": ['EH1 R IY2 AH0 L'],
  "arian": ['AE1 R IY0 AH0 N'],
  "ariana": ['AA0 R IY0 AE1 N AH0'],
  "ariane": ['EH2 R IY0 AE1 N'],
  "arianespace": ['EH2 R IY0 AE1 N S P EY1 S'],
  "arianism": ['AE1 R IY0 AH0 N IH2 Z AH0 M'],
  "arianna": ['AA0 R IY0 AE1 N AH0'],
  "arianna's": ['AA0 R IY0 AE1 N AH0 Z'],
  "arias": ['AA1 R IY0 AH0 Z'],
  "arias's": ['AA1 R IY0 AH0 S IH0 Z'],
  "aric": ['AE1 R IH0 K'],
  "arico": ['AA0 R IY1 K OW0'],
  "arid": ['AE1 R AH0 D', 'EH1 R AH0 D'],
  "arida": ['AH0 R IY1 D AH0'],
  "arie": ['EH1 R IY0'],
  "ariel": ['EH1 R IY0 AH0 L'],
  "ariela": ['AA0 R IY0 EH1 L AH0'],
  "ariella": ['AA0 R IY0 EH1 L AH0'],
  "aries": ['EH1 R IY0 Z'],
  "arington": ['AA1 R IH0 NG T AH0 N'],
  "ariola": ['AA0 R IY0 OW1 L AH0'],
  "aris": ['ER0 IY1 S'],
  "arise": ['ER0 AY1 Z'],
  "arisen": ['ER0 IH1 Z AH0 N'],
  "arises": ['ER0 AY1 Z AH0 Z', 'ER0 AY1 Z IH0 Z'],
  "arising": ['ER0 AY1 Z IH0 NG'],
  "arison": ['EH1 R IH0 S AH0 N'],
  "arispe": ['AA0 R IY1 S P IY0'],
  "arista": ['ER0 IH1 S T AH0'],
  "aristech": ['EH1 R IH0 S T EH2 K'],
  "aristede": ['AA2 R IH0 S T IY1 D'],
  "aristede's": ['AA2 R IH0 S T IY1 D Z'],
  "aristide": ['AA2 R IH0 S T IY1 D'],
  "aristide's": ['AA2 R IH0 S T IY1 D Z'],
  "aristocracy": ['EH2 R AH0 S T AA1 K R AH0 S IY0'],
  "aristocrat": ['ER0 IH1 S T AH0 K R AE2 T'],
  "aristocratic": ['ER0 IH2 S T AH0 K R AE1 T IH0 K'],
  "aristocrats": ['ER0 IH1 S T AH0 K R AE2 T S'],
  "aristophanes": ['AE2 R AH0 S T AO1 F AH0 N IY0 Z'],
  "aristotelian": ['ER0 IH2 S T AH0 T IY1 L IY0 AH0 N'],
  "aristotle": ['EH1 R AH0 S T AA2 T AH0 L'],
  "aristotle's": ['EH1 R AH0 S T AA2 T AH0 L Z'],
  "arithmetic": ['EH2 R IH0 TH M EH1 T IH0 K', 'ER0 IH1 TH M AH0 T IH2 K'],
  "ariza": ['AA0 R IY1 Z AH0'],
  "arizmendi": ['AA0 R IY0 Z M EH1 N D IY0'],
  "arizona": ['EH2 R IH0 Z OW1 N AH0'],
  "arizona's": ['EH2 R IH0 Z OW1 N AH0 Z'],
  "arizonan": ['ER0 IH1 Z OW0 N AH0 N', 'ER0 IH0 Z OW1 N AH0 N'],
  "arizonans": ['ER0 IH1 Z OW0 N AH0 N Z', 'ER0 IH0 Z OW1 N AH0 N Z'],
  "arjo": ['AA1 R JH OW0', 'AA1 R Y OW0'],
  "arjun": ['AA1 R JH AH0 N'],
  "ark": ['AA1 R K'],
  "arkadelphia": ['AA2 R K AH0 D EH1 L F IY0 AH0'],
  "arkadi": ['AA1 R K AH0 D IY0'],
  "arkady": ['AA2 R K EY1 D IY0'],
  "arkansan": ['AA2 R K AE1 N Z AH0 N'],
  "arkansans": ['AA2 R K AE1 N Z AH0 N Z'],
  "arkansas": ['AA1 R K AH0 N S AA2'],
  "arkansas'": ['AA1 R K AH0 N S AA2 Z'],
  "arkansas's": ['AA1 R K AH0 N S AA2 Z'],
  "arkarow": ['AA1 R K AH0 R OW0'],
  "arkin": ['AA1 R K IH0 N'],
  "arkin's": ['AA1 R K IH0 N Z'],
  "arkla": ['AA1 R K L AH0'],
  "arkla's": ['AA1 R K L AH0 Z'],
  "arko": ['AA1 R K OW0'],
  "arkose": ['AA0 R K OW1 S'],
  "arkwright": ['AA1 R K R AY2 T'],
  "arlana": ['AA0 R L AE1 N AH0'],
  "arlauskas": ['AA0 R L AO1 S K AH0 S'],
  "arledge": ['AA1 R L IH0 JH'],
  "arleen": ['AA0 R L IY1 N'],
  "arlen": ['AA1 R L AH0 N'],
  "arlena": ['AA1 R L IH0 N AH0'],
  "arlene": ['AA1 R L IY2 N'],
  "arlene's": ['AA1 R L IY2 N Z'],
  "arleta": ['AA1 R L IH0 T AH0'],
  "arlette": ['AA2 R L EH1 T'],
  "arley": ['AA1 R L IY0'],
  "arlie": ['AA1 R L IY0'],
  "arlin": ['AA1 R L IH0 N'],
  "arlinda": ['AA2 R L IH1 N D AH0'],
  "arline": ['AA1 R L AY2 N'],
  "arlington": ['AA1 R L IH0 NG T AH0 N'],
  "arlington's": ['AA1 R L IH0 NG T AH0 N Z'],
  "arlt": ['AA1 R L T'],
  "arlyne": ['AA1 R L AY2 N'],
  "arm": ['AA1 R M'],
  "arm's": ['AA1 R M Z'],
  "armacost": ['AA1 R M AH0 K AO2 S T'],
  "armacost's": ['AA1 R M AH0 K AO2 S T S'],
  "armada": ['AA0 R M AA1 D AH0'],
  "armadillo": ['AA2 R M AH0 D IH1 L OW0'],
  "armadillos": ['AA2 R M AH0 D IH1 L OW0 Z'],
  "armageddon": ['AA2 R M AH0 G EH1 D AH0 N'],
  "armagost": ['AA0 R M AA1 G OW0 S T'],
  "armament": ['AA1 R M AH0 M AH0 N T'],
  "armaments": ['AA1 R M AH0 M AH0 N T S'],
  "arman": ['AA1 R M AH0 N'],
  "armand": ['AA1 R M AH0 N D', 'AA2 R M AA1 N D'],
  "armando": ['AA0 R M AA1 N D OW0'],
  "armandt": ['AA1 R M AE0 N T'],
  "armani": ['AA2 R M AA1 N IY0'],
  "armantrout": ['AA1 R M AH0 N T R AW2 T'],
  "armaro": ['AA2 R M AA1 R OW0'],
  "armas": ['AA1 R M AH0 Z'],
  "armato": ['AA0 R M AA1 T OW0'],
  "armband": ['AA1 R M B AE2 N D'],
  "armbands": ['AA1 R M B AE2 N D Z'],
  "armbrister": ['AA1 R M B ER0 IH0 S T ER0', 'AA1 R M B R IH0 S T ER0'],
  "armbrust": ['AA1 R M B R AH0 S T'],
  "armbruster": ['AA1 R M B R AH0 S T ER0', 'AA1 R M B R UW0 S T ER0'],
  "armchair": ['AA1 R M CH EH2 R'],
  "armchairs": ['AA1 R M CH EH2 R Z'],
  "armco": ['AA1 R M K OW2'],
  "armco's": ['AA1 R M K OW0 Z'],
  "armed": ['AA1 R M D'],
  "armel": ['AA0 R M EH1 L'],
  "armellino": ['AA2 R M EH0 L IY1 N OW0'],
  "armen": ['AA1 R M AH0 N'],
  "armendarez": ['AA0 R M EY0 N D AA1 R EH0 Z'],
  "armendariz": ['AA0 R M EY0 N D AA1 R IY0 Z'],
  "armenia": ['AA0 R M IY1 N IY0 AH0'],
  "armenia's": ['AA0 R M IY1 N IY0 AH0 Z'],
  "armenian": ['AA0 R M IY1 N IY0 AH0 N'],
  "armenians": ['AA0 R M IY1 N IY0 AH0 N Z'],
  "arment": ['AA1 R M AH0 N T'],
  "armenta": ['AA0 R M EH1 N T AH0'],
  "armentor": ['AA0 R M AH0 N T AO1 R'],
  "armentrout": ['AA1 R M AH0 N T R AW2 T'],
  "armer": ['AA1 R M ER0'],
  "armes": ['AA1 R M Z'],
  "armested": ['AA1 R M IH0 S T EH2 D'],
  "armetta": ['AA0 R M EH1 T AH0'],
  "armey": ['AA1 R M IY0'],
  "armey's": ['AA1 R M IY0 Z'],
  "armfield": ['AA1 R M F IY2 L D'],
  "armida": ['AA0 R M IY1 D AH0'],
  "armies": ['AA1 R M IY0 Z'],
  "armiger": ['AA1 R M IH0 G ER0'],
  "armijo": ['AA0 R M IY1 Y OW0'],
  "armilla": ['AA0 R M IH1 L AH0'],
  "armin": ['AA1 R M AH0 N'],
  "armina": ['AA0 R M IY1 N AH0'],
  "armine": ['AA0 R M IY1 N IY0'],
  "arming": ['AA1 R M IH0 NG'],
  "armington": ['AA1 R M IH0 NG T AH0 N'],
  "arminie": ['AA1 R M IH0 N IY0'],
  "armistead": ['AA1 R M IH0 S T EH2 D'],
  "armistice": ['AA1 R M AH0 S T AH0 S'],
  "armitage": ['AA1 R M IH0 T IH0 JH'],
  "armoire": ['AA1 M W AA0 R'],
  "armoires": ['AA1 M W AA0 R Z'],
  "armold": ['AA1 R M OW2 L D'],
  "armon": ['AA1 R M AH0 N'],
  "armond": ['AA1 R M AH0 N D'],
  "armonk": ['AA1 R M AH0 NG K'],
  "armor": ['AA1 R M ER0'],
  "armored": ['AA1 R M ER0 D'],
  "armories": ['AA1 R M ER0 IY0 Z'],
  "armory": ['AA1 R M ER0 IY0'],
  "armour": ['AA1 R M ER0'],
  "armpit": ['AA1 R M P IH2 T'],
  "armpits": ['AA1 R M P IH2 T S'],
  "armrest": ['AA1 R M R EH2 S T'],
  "armrests": ['AA1 R M R EH2 S T S'],
  "arms": ['AA1 R M Z'],
  "armstead": ['AA1 R M S T EH2 D'],
  "armstrad": ['AA1 R M S T R AE2 D'],
  "armstrong": ['AA1 R M S T R AA2 NG', 'AA1 R M S T R AO2 NG'],
  "armstrong's": ['AA1 R M S T R AO2 NG Z'],
  "armtec": ['AA1 R M T EH2 K'],
  "armtek": ['AA1 R M T EH2 K'],
  "armtek's": ['AA1 R M T EH2 K S'],
  "armwood": ['AA1 R M W UH2 D'],
  "army": ['AA1 R M IY0'],
  "army's": ['AA1 R M IY0 Z'],
  "arn": ['AA1 R N'],
  "arnal": ['AA1 R N AH0 L'],
  "arnalda": ['AA0 R N AA1 L D AH0'],
  "arnaldo": ['AA0 R N AA1 L D OW0'],
  "arnall": ['AA1 R N AH0 L'],
  "arnatt": ['AA0 R N AE1 T'],
  "arnaud": ['AA0 R N OW1'],
  "arnault": ['AA2 R N OW1'],
  "arnaz": ['AA2 R N EH1 Z'],
  "arndt": ['AA1 R N T'],
  "arne": ['AA1 R N'],
  "arnell": ['AA1 R N AH0 L'],
  "arnelle": ['AA2 R N EH1 L'],
  "arnelle's": ['AA2 R N EH1 L Z'],
  "arner": ['AA1 R N ER0'],
  "arnesen": ['AA0 R N IY1 Z AH0 N'],
  "arneson": ['AA1 R N IH0 S AH0 N'],
  "arnett": ['AA0 R N EH1 T'],
  "arnette": ['AA0 R N EH1 T'],
  "arney": ['AA1 R N IY0'],
  "arnhold": ['AA1 R N HH OW2 L D'],
  "arnholt": ['AA1 R N HH OW2 L T'],
  "arnie": ['AA1 R N IY0'],
  "arnn": ['AA1 R N'],
  "arno": ['AA1 R N OW0'],
  "arnold": ['AA1 R N AH0 L D'],
  "arnold's": ['AA1 R N AH0 L D Z'],
  "arnoldi": ['AA0 R N OW1 L D IY0'],
  "arnolds": ['AA1 R N AH0 L D Z'],
  "arnoldy": ['AA1 R N OW0 L D IY0'],
  "arnolphe": ['AA0 R N AA1 L F IY0'],
  "arnone": ['AA0 R N OW1 N IY0'],
  "arnot": ['AA1 R N AH0 T'],
  "arnott": ['AA1 R N AA0 T'],
  "arnotts": ['AA1 R N AA0 T S'],
  "arnow": ['AA1 R N OW0'],
  "arnox": ['AA1 R N AA0 K S'],
  "arns": ['AA1 R N Z'],
  "arnst": ['AA1 R N S T'],
  "arnstein": ['AA1 R N S T AY0 N', 'AA1 R N S T IY0 N'],
  "arntson": ['AA1 R N T S AH0 N'],
  "arntz": ['AA1 R N T S'],
  "arntzen": ['AA1 R N T Z AH0 N'],
  "arnulfo": ['AA0 R N AH1 L F OW0'],
  "arnwine": ['AA1 R N W AY2 N'],
  "arnzen": ['AA1 R N Z AH0 N'],
  "aro": ['AA1 R OW0'],
  "arocha": ['AA0 R OW1 K AH0'],
  "arocho": ['AA0 R OW1 K OW0'],
  "aroma": ['ER0 OW1 M AH0'],
  "aromas": ['ER0 OW1 M AH0 Z'],
  "aromatherapy": ['AH0 R OW2 M AH0 TH ER1 AH0 P IY0'],
  "aromatic": ['EH2 R AH0 M AE1 T IH0 K'],
  "aron": ['EH1 R AH0 N'],
  "arone": ['ER0 OW1 N'],
  "aronhalt": ['AE1 R AH0 N HH AH0 L T'],
  "aronie": ['ER0 OW1 N IY0'],
  "aronoff": ['EH1 R AH0 N AO0 F'],
  "aronow": ['ER0 OW1 N OW0'],
  "aronowitz": ['ER0 AA1 N AH0 W IH0 T S'],
  "arons": ['AA1 R OW0 N Z'],
  "aronson": ['EH1 R AH0 N S AH0 N'],
  "aronstein": ['EH1 R AH0 N S T IY2 N', 'EH1 R AH0 N S T AY2 N'],
  "arora": ['AA0 R AO1 R AA0'],
  "aros": ['AA1 R OW0 Z'],
  "arose": ['ER0 OW1 Z'],
  "around": ['ER0 AW1 N D', 'ER0 AW1 N'],
  "arousal": ['ER0 AW1 Z AH0 L'],
  "arouse": ['ER0 AW1 Z'],
  "aroused": ['ER0 AW1 Z D'],
  "arouses": ['ER0 AW1 Z IH0 Z'],
  "arousing": ['ER0 AW1 Z IH0 NG'],
  "arp": ['AA1 R P'],
  "arpa": ['AA1 R P AH0'],
  "arpanet": ['AA1 R P AH0 N EH2 T'],
  "arpin": ['AA1 R P IH0 N'],
  "arpino": ['AA0 R P IY1 N OW0'],
  "arps": ['AA1 R P S'],
  "arquette": ['AA0 R K EH1 T'],
  "arquilla": ['AA2 R K W IH1 L AH0'],
  "arraign": ['ER0 EY1 N'],
  "arraigned": ['ER0 EY1 N D'],
  "arraignment": ['ER0 EY1 N M AH0 N T'],
  "arraignments": ['ER0 EY1 N M AH0 N T S'],
  "arrange": ['ER0 EY1 N JH'],
  "arranged": ['ER0 EY1 N JH D'],
  "arrangement": ['ER0 EY1 N JH M AH0 N T'],
  "arrangements": ['ER0 EY1 N JH M AH0 N T S'],
  "arranger": ['ER0 EY1 N JH ER0'],
  "arranges": ['ER0 EY1 N JH IH0 Z'],
  "arranging": ['ER0 EY1 N JH IH0 NG'],
  "arrant": ['AE1 R AH0 N T'],
  "arrants": ['AE1 R AH0 N T S'],
  "arrasmith": ['EH1 R AH0 S M IH2 TH'],
  "array": ['ER0 EY1'],
  "arrayed": ['ER0 EY1 D'],
  "arraying": ['ER0 EY1 IH0 NG'],
  "arrays": ['ER0 EY1 Z'],
  "arrearage": ['ER0 IY1 R IH0 JH'],
  "arrearages": ['ER0 IH1 R IH0 JH IH0 Z'],
  "arrears": ['ER0 IH1 R Z'],
  "arredondo": ['AA0 R EH0 D OW1 N D OW0'],
  "arreguin": ['AA0 R EY0 G W IY1 N'],
  "arrendondo": ['AA0 R EH0 N D OW1 N D OW0'],
  "arreola": ['AA0 R IY0 AA1 L AH0'],
  "arrest": ['ER0 EH1 S T'],
  "arrested": ['ER0 EH1 S T IH0 D'],
  "arresting": ['ER0 EH1 S T IH0 NG'],
  "arrests": ['ER0 EH1 S T S'],
  "arrhythmia": ['ER0 IH1 DH M IY0 AH0'],
  "arrhythmias": ['ER0 IH1 DH M IY0 AH0 Z'],
  "arriaga": ['AA2 R IY0 AA1 G AH0'],
  "arrick": ['AE1 R IH0 K'],
  "arrieta": ['AA0 R IY1 T AH0'],
  "arrigo": ['AA2 R IY1 G OW0'],
  "arrigoni": ['AA0 R IY0 G OW1 N IY0'],
  "arrington": ['EH1 R IH0 NG T AH0 N'],
  "arriola": ['AA2 R IY0 OW1 L AH0'],
  "arriortua": ['AA2 R IY0 AO0 R T UW1 AH0'],
  "arris": ['AE1 R AH0 S'],
  "arrival": ['ER0 AY1 V AH0 L'],
  "arrivals": ['ER0 AY1 V AH0 L Z'],
  "arrive": ['ER0 AY1 V'],
  "arrived": ['ER0 AY1 V D'],
  "arrives": ['ER0 AY1 V Z'],
  "arriving": ['ER0 AY1 V IH0 NG'],
  "arrogance": ['EH1 R AH0 G AH0 N S'],
  "arrogant": ['EH1 R AH0 G AH0 N T'],
  "arrogantly": ['EH1 R AH0 G AH0 N T L IY0'],
  "arrogate": ['AE1 R OW0 G EY2 T'],
  "arrow": ['AE1 R OW0', 'EH1 R OW0'],
  "arrow's": ['AE1 R OW0 Z', 'EH1 R OW0 Z'],
  "arrowhead": ['AE1 R OW0 HH EH2 D', 'EH1 R OW0 HH EH2 D'],
  "arrowheads": ['AE1 R OW0 HH EH2 D Z', 'EH1 R OW0 HH EH2 D Z'],
  "arrowood": ['AE1 R OW0 W UH2 D'],
  "arrowroot": ['EH1 R OW0 R UW2 T'],
  "arrows": ['AE1 R OW0 Z', 'EH1 R OW0 Z'],
  "arrowsmith": ['AE1 R OW0 S M IH2 TH', 'EH1 R OW0 S M IH2 TH'],
  "arrowsmith's": ['AE1 R OW0 S M IH2 TH S', 'EH1 R OW0 S M IH2 TH S'],
  "arroyo": ['ER0 OY1 OW0'],
  "arroyo's": ['ER0 OY1 OW0 Z'],
  "arruda": ['AA0 R UW1 D AH0'],
  "arrupe": ['AE1 R UW2 P'],
  "arsdale": ['AA1 R Z D EY2 L'],
  "arse": ['AA1 R S'],
  "arsehole": ['AA1 R S HH OW2 L'],
  "arsenal": ['AA1 R S AH0 N AH0 L'],
  "arsenals": ['AA1 R S AH0 N AH0 L Z'],
  "arsenault": ['AA1 R S IH0 N OW0'],
  "arseneau": ['AA1 R S IH0 N OW0'],
  "arseneault": ['AA1 R S IH0 N OW0'],
  "arsenic": ['AA1 R S AH0 N IH0 K'],
  "arsenide": ['AA1 R S AH0 N AY2 D'],
  "arsenio": ['AA2 R S IY1 N IY0 OW0'],
  "arses": ['AA1 R S IH0 Z'],
  "arshia": ['AA1 R SH Y AH0'],
  "arslanian": ['AA2 R S L EY1 N IY0 AH0 N'],
  "arson": ['AA1 R S AH0 N'],
  "arsonist": ['AA1 R S AH0 N AH0 S T', 'AA1 R S AH0 N IH0 S T'],
  "arsonists": ['AA1 R S AH0 N AH0 S T S', 'AA1 R S AH0 N IH0 S T S'],
  "arsons": ['AA1 R S AH0 N Z'],
  "arsov": ['AA1 R S AH0 V'],
  "art": ['AA1 R T'],
  "art's": ['AA1 R T S'],
  "artale": ['AA0 R T AA1 L IY0'],
  "artcarve": ['AA1 R T K AA2 R V'],
  "artcarved": ['AA1 R T K AA2 R V D'],
  "arteaga": ['AA2 R T IY1 G AH0'],
  "artemas": ['AA1 R T IH0 M AH0 Z'],
  "artemus": ['AA1 R T AH0 M AH0 S'],
  "arter": ['AA1 R T ER0'],
  "arterberry": ['AA1 R T ER0 B EH2 R IY0'],
  "arterburn": ['AA1 R T ER0 B ER2 N'],
  "arterial": ['AA0 R T IH1 R IY0 AH0 L'],
  "arteries": ['AA1 R T ER0 IY0 Z'],
  "arteriosclerosis": ['AA0 R T IH2 R IY0 OW0 S K L ER0 OW1 S AH0 S'],
  "artery": ['AA1 R T ER0 IY0'],
  "artes": ['AA1 R T IY0 Z'],
  "artesian": ['AA2 R T IY1 ZH AH0 N'],
  "artful": ['AA1 R T F AH0 L'],
  "artfully": ['AA1 R T F AH0 L IY0'],
  "arth": ['AA1 R TH'],
  "arthotec": ['AA1 R TH AH0 T EH0 K'],
  "arthritic": ['AA0 R TH R IH1 T IH0 K'],
  "arthritis": ['AA0 R TH R AY1 T AH0 S', 'AA0 R TH R AY1 T IH0 S'],
  "arthropod": ['AA1 R TH R AH0 P AA2 D'],
  "arthropods": ['AA1 R TH R AH0 P AA2 D Z'],
  "arthur": ['AA1 R TH ER0'],
  "arthur's": ['AA1 R TH ER0 Z'],
  "arthurian": ['AA0 R TH UH1 R IY0 AH0 N'],
  "arthurs": ['AA1 R TH ER0 Z'],
  "artichoke": ['AA1 R T AH0 CH OW2 K', 'AA1 R T IH0 CH OW2 K'],
  "artichokes": ['AA1 R T IH0 CH OW2 K S'],
  "article": ['AA1 R T AH0 K AH0 L', 'AA1 R T IH0 K AH0 L'],
  "article's": ['AA1 R T IH0 K AH0 L Z'],
  "articles": ['AA1 R T AH0 K AH0 L Z', 'AA1 R T IH0 K AH0 L Z'],
  "articular": ['AA0 R T IH1 K Y AH0 L ER2'],
  "articulate": ['AA0 R T IH1 K Y AH0 L EY2 T', 'AA0 R T IH1 K Y AH0 L AH0 T'],
  "articulated": ['AA0 R T IH1 K Y AH0 L EY2 T IH0 D'],
  "articulates": ['AA0 R T IH1 K Y AH0 L AH0 T S'],
  "articulating": ['AA0 R T IH1 K Y AH0 L EY2 T IH0 NG'],
  "articulation": ['AA2 R T IH0 K Y AH0 L EY1 SH AH0 N'],
  "articulatory": ['AA0 R T IH1 K Y AH0 L AH0 T AO2 R IY0'],
  "artie": ['AA1 R T IY0'],
  "artifact": ['AA1 R T AH0 F AE2 K T'],
  "artifacts": ['AA1 R T AH0 F AE2 K T S'],
  "artifice": ['AA1 R T AH0 F IH0 S'],
  "artificial": ['AA2 R T AH0 F IH1 SH AH0 L'],
  "artificially": ['AA2 R T AH0 F IH1 SH AH0 L IY0'],
  "artillery": ['AA0 R T IH1 L ER0 IY0'],
  "artino": ['AA0 R T IY1 N OW0'],
  "artiodactyl": ['AA2 R T IY0 OW0 D AE1 K T AH0 L'],
  "artiodactyls": ['AA2 R T IY0 OW0 D AE1 K T AH0 L Z'],
  "artis": ['AA1 R T IH0 S'],
  "artisan": ['AA1 R T AH0 Z AH0 N'],
  "artisanal": ['AA2 R T IH1 Z AH0 N AH0 L'],
  "artisans": ['AA1 R T AH0 Z AH0 N Z'],
  "artist": ['AA1 R T AH0 S T', 'AA1 R T IH0 S T'],
  "artist's": ['AA1 R T AH0 S T S', 'AA1 R T IH0 S T S'],
  "artistic": ['AA0 R T IH1 S T IH0 K'],
  "artistically": ['AA0 R T IH1 S T IH0 K L IY0'],
  "artistry": ['AA1 R T IH0 S T R IY0'],
  "artists": ['AA1 R T IH0 S T S'],
  "artists'": ['AA1 R T IH0 S T S'],
  "artley": ['AA1 R T L IY0'],
  "artman": ['AA1 R T M AH0 N'],
  "artois": ['AA0 R T W AA1'],
  "arton": ['AA1 R T AA2 N'],
  "artra": ['AA1 R T R AH0'],
  "artra's": ['AA1 R T R AH0 Z'],
  "artrip": ['AA1 R T R IH0 P'],
  "arts": ['AA1 R T S'],
  "artsy": ['AA1 R T S IY1'],
  "artur": ['AA1 R T UH0 R'],
  "arturo": ['AA0 R T UH1 R OW0'],
  "artus": ['AA1 R T AH0 S'],
  "artwork": ['AA1 R T W ER2 K'],
  "artworks": ['AA1 R T W ER2 K S'],
  "arty": ['AA1 R T IY0'],
  "arty's": ['AA1 R T IY0 Z'],
  "artz": ['AA1 R T S'],
  "artzt": ['AA1 R T S T'],
  "aruba": ['ER0 UW1 B AH0'],
  "arugula": ['AA2 R UW1 G UW0 L AH0'],
  "arum": ['EH1 R AH0 M'],
  "arunachalam": ['AA0 R UW2 N AH0 CH AA1 L AH0 M'],
  "arundel": ['EH1 R AH0 N D AH0 L'],
  "arup": ['ER0 UW1 P'],
  "arva": ['AA1 R V AH0'],
  "arvad": ['AA0 R V AE1 D'],
  "arval": ['AA1 R V AH0 L'],
  "arvanitis": ['AA0 R V AH0 N AY1 T IH0 S'],
  "arvay": ['AA1 R V EY0'],
  "arve": ['AA1 R V'],
  "arvel": ['AA0 R V EH1 L'],
  "arvey": ['AA1 R V IY0'],
  "arvid": ['AA1 R V IH0 D'],
  "arvida": ['AA0 R V IY1 D AH0'],
  "arvida's": ['AA0 R V IY1 D AH0 Z'],
  "arvidson": ['AA1 R V IH0 D S AH0 N'],
  "arvin": ['AA1 R V IH0 N'],
  "arvin's": ['AA1 R V IH0 N Z'],
  "arvind": ['AA1 R V IH0 N D'],
  "arvizu": ['AA0 R V IY1 Z UW0'],
  "arwen": ['AA1 R W AH0 N'],
  "arwood": ['AA1 R W UH2 D'],
  "arx": ['AA1 R K S'],
  "ary": ['EH1 R IY0'],
  "aryan": ['AA1 R IY0 AH0 N'],
  "arzama": ['AA0 R Z AA1 M AH0'],
  "arzamas": ['AA0 R Z AA1 M AH0 Z'],
  "arzate": ['AA1 R Z EY2 T'],
  "arzola": ['AA0 R Z OW1 L AH0'],
  "arzt": ['AA1 R Z T'],
  "as": ['AE1 Z', 'EH1 Z'],
  "asa": ['AA1 S AH0'],
  "asaf": ['AA0 S AA1 F'],
  "asahan": ['AE1 S AH0 HH AE0 N'],
  "asahara": ['AE0 S AH0 HH AE1 R AH0'],
  "asahi": ['AH0 S AA1 HH IY0'],
  "asamera": ['AE2 S AH0 M EH1 R AH0'],
  "asap": ['EY1 EH1 S EY1 P IY1', 'EY1 S AE2 P'],
  "asarco": ['AH0 S AA1 R K OW0'],
  "asarco's": ['AH0 S AA1 R K OW0 Z'],
  "asaro": ['AA0 S AA1 R OW0'],
  "asat": ['AE1 Z AE0 T'],
  "asato": ['AA0 S AA1 T OW0'],
  "asay": ['AH0 S EY1'],
  "asbell": ['AE1 S B EH0 L'],
  "asberry": ['AE1 S B EH0 R IY0'],
  "asbestec": ['AE2 S B EH1 S T EH0 K'],
  "asbestos": ['AE0 S B EH1 S T AH0 S'],
  "asbestosis": ['AE2 S B EH2 S T OW1 S AH0 S', 'AE2 S B EH2 S T OW1 S IH0 S'],
  "asbill": ['AH0 S B IH1 L'],
  "asbridge": ['AH0 S B R IH1 JH'],
  "asbury": ['AE1 Z B EH2 R IY0'],
  "asby": ['AE1 S B IY0'],
  "ascap": ['AE1 S K AE2 P'],
  "ascencio": ['AA0 S CH EH1 N CH IY0 OW0'],
  "ascend": ['AH0 S EH1 N D'],
  "ascendance": ['AH0 S EH1 N D AH0 N S'],
  "ascendancy": ['AH0 S EH1 N D AH0 N S IY0'],
  "ascendant": ['AH0 S EH1 N D AH0 N T'],
  "ascended": ['AH0 S EH1 N D IH0 D'],
  "ascendency": ['AH0 S EH1 N D AH0 N S IY0'],
  "ascending": ['AH0 S EH1 N D IH0 NG'],
  "ascends": ['AH0 S EH1 N D Z'],
  "ascension": ['AH0 S EH1 N SH AH0 N'],
  "ascent": ['AH0 S EH1 N T'],
  "ascertain": ['AE2 S ER0 T EY1 N'],
  "ascertained": ['AE2 S ER0 T EY1 N D'],
  "ascertaining": ['AE2 S ER0 T EY1 N IH0 NG'],
  "ascetic": ['AH0 S EH1 T IH0 K'],
  "asch": ['AE1 SH'],
  "asche": ['AE1 SH'],
  "aschenbach": ['AE1 SH IH0 N B AA0 K'],
  "aschenbrenner": ['AE1 SH IH0 N B R IH0 N ER0'],
  "ascher": ['AE1 SH ER0'],
  "aschoff": ['AE1 S K HH AO0 F'],
  "ascii": ['AE1 S K IY0'],
  "asclad": ['AE1 S K L AE0 D'],
  "ascorbic": ['AH0 S K AO1 R B IH0 K'],
  "ascot": ['AE1 S K AA2 T'],
  "ascott": ['AH0 S K AA1 T'],
  "ascribe": ['AH0 S K R AY1 B'],
  "ascribed": ['AH0 S K R AY1 B D'],
  "ascribes": ['AH0 S K R AY1 B Z'],
  "asda": ['AE1 S D AH0'],
  "asea": ['AH0 Z IY1 AH0', 'EY1 EH1 S IY1 EY1'],
  "asean": ['AH0 Z IY1 AH0 N', 'EY1 EH1 S IY1 EY1 EH1 N', 'AE2 Z EY1 AH0 N'],
  "aselma": ['AH0 S EH1 L M AH0'],
  "aseltine": ['AA0 S EH0 L T IY1 N IY0'],
  "asencio": ['AH0 S EH1 N S IY0 OW0'],
  "aseritis": ['AH0 S EH1 R IH0 T IH0 S'],
  "asexual": ['EY0 S EH1 K S Y UW0 AH0 L'],
  "asgard": ['AA1 S G AA2 R D'],
  "ash": ['AE1 SH'],
  "asha": ['AA1 SH AH0'],
  "ashaji": ['AH0 SH AA1 JH IY0'],
  "ashaji's": ['AH0 SH AA1 JH IY0 Z'],
  "ashame": ['AH0 SH EY1 M'],
  "ashamed": ['AH0 SH EY1 M D'],
  "asharah": ['AE1 SH AA0 R AA2'],
  "ashare": ['AE1 SH EH2 R'],
  "ashbaugh": ['AH0 SH B AO1', 'AE1 SH B AO2'],
  "ashbrook": ['AE1 SH B R UH2 K'],
  "ashburn": ['AE1 SH B ER0 N'],
  "ashbury": ['AE1 SH B EH0 R IY0'],
  "ashby": ['AE1 SH B IY0'],
  "ashcraft": ['AE1 SH K R AE2 F T'],
  "ashcreek": ['AE2 SH K R IY1 K'],
  "ashcroft": ['AE1 SH K R AO2 F T'],
  "ashdown": ['AE1 SH D AW2 N'],
  "ashe": ['AE1 SH'],
  "ashe's": ['AE1 SH AH0 Z'],
  "asheboro": ['AE1 SH B AO0 R OW0'],
  "ashen": ['AE1 SH AH0 N'],
  "ashenberg": ['AE1 SH AH0 N B ER0 G'],
  "ashenfelter": ['AE1 SH AH0 N F EH2 L T ER0'],
  "asher": ['AE1 SH ER0'],
  "ashes": ['AE1 SH AH0 Z', 'AE1 SH IH0 Z'],
  "asheville": ['AE1 SH V IH2 L'],
  "ashey": ['AE1 SH IY0'],
  "ashford": ['AE1 SH F ER0 D'],
  "ashis": ['AH0 SH IY1 Z'],
  "ashish": ['AH0 SH IY1 SH'],
  "ashkenazi": ['AE2 SH K AH0 N AA1 Z IY0'],
  "ashkenazis": ['AE2 SH K AH0 N AA1 Z IY0 Z'],
  "ashland": ['AE1 SH L AH0 N D'],
  "ashland's": ['AE1 SH L AH0 N D Z'],
  "ashley": ['AE1 SH L IY0'],
  "ashley's": ['AE1 SH L IY0 Z'],
  "ashlin": ['AE1 SH L IH0 N'],
  "ashline": ['AE1 SH L AY2 N'],
  "ashlock": ['AE1 SH L AA2 K'],
  "ashman": ['AE1 SH M AH0 N'],
  "ashmead": ['AE1 SH M IY2 D'],
  "ashmore": ['AE1 SH M AO0 R'],
  "ashok": ['AE1 SH AA0 K'],
  "ashore": ['AH0 SH AO1 R'],
  "ashram": ['AE1 SH R AA0 M'],
  "ashrawi": ['AE0 SH R AA1 W IY0'],
  "ashtec": ['AE1 SH T EH0 K'],
  "ashtec's": ['AE1 SH T EH0 K S'],
  "ashton": ['AE1 SH T AH0 N'],
  "ashton's": ['AE1 SH T AH0 N Z'],
  "ashtray": ['AE1 SH T R EY2'],
  "ashtrays": ['AE1 SH T R EY2 Z'],
  "ashur": ['AE1 SH ER0'],
  "ashurst": ['AE1 SH ER0 S T'],
  "ashville": ['AE1 SH V IH2 L'],
  "ashwell": ['AE1 SH W EH2 L'],
  "ashwood": ['AE1 SH W UH2 D'],
  "ashworth": ['AE1 SH W ER2 TH'],
  "ashy": ['AE1 SH IY0'],
  "asia": ['EY1 ZH AH0'],
  "asia's": ['EY1 ZH AH0 Z'],
  "asiain": ['EY1 Z IY0 EY2 N'],
  "asiamerica": ['EY2 S IY0 AH0 M EH1 R IH0 K AH0'],
  "asian": ['EY1 ZH AH0 N'],
  "asians": ['EY1 ZH AH0 N Z'],
  "asiatic": ['EY2 ZH IY0 AE1 T IH0 K'],
  "asiaweek": ['EY1 ZH AH0 W IY2 K'],
  "asic": ['AE1 Z IH0 K'],
  "asics": ['AE1 Z IH0 K S'],
  "aside": ['AH0 S AY1 D'],
  "asides": ['AH0 S AY1 D Z'],
  "asiel": ['AE1 Z IY0 AH0 L'],
  "asimov": ['AE1 S IH0 M AA0 V', 'AE1 Z IH0 M AA0 V'],
  "asimov's": ['AE1 S IH0 M AA0 V Z', 'AE1 Z IH0 M AA0 V Z'],
  "asimow": ['AE1 S IH0 M OW0'],
  "asinine": ['AE1 S AH0 N AY2 N'],
  "ask": ['AE1 S K'],
  "aska": ['AE1 S K AH0'],
  "askance": ['AH0 S K AE1 N S'],
  "asked": ['AE1 S K T', 'AE1 S T'],
  "asker": ['AE1 S K ER0'],
  "asker's": ['AE1 S K ER0 Z'],
  "askers": ['AE1 S K ER0 Z'],
  "askers'": ['AE1 S K ER0 Z'],
  "askers's": ['AE1 S K ER0 Z IH0 Z'],
  "askew": ['AH0 S K Y UW1'],
  "askey": ['AH0 S K IY1'],
  "askin": ['AH0 S K IH1 N'],
  "askin's": ['AE1 S K IH2 N Z'],
  "asking": ['AE1 S K IH0 NG'],
  "askington": ['AE1 S K IH0 NG T AH0 N'],
  "askins": ['AH0 S K IH1 N Z'],
  "asko": ['AE1 S K OW0'],
  "askoldov": ['AH0 S K OW1 L D AA0 V'],
  "askoldov's": ['AH0 S K OW1 L D AA0 V Z'],
  "askren": ['AE1 S K ER0 AH0 N'],
  "asks": ['AE1 S K S'],
  "aslan": ['AH0 S L AH1 N'],
  "aslanian": ['AH0 S L EY1 N IY0 AH0 N'],
  "asleep": ['AH0 S L IY1 P'],
  "asleson": ['AE1 S L IH0 S AH0 N', 'AE1 S IH0 L S AH0 N'],
  "aslin": ['AH0 S L IH1 N'],
  "asman": ['AE1 S M AH0 N'],
  "asmara": ['AE2 Z M AA1 R AH0'],
  "asmaras": ['AE2 Z M AA1 R AH0 Z'],
  "asmodeus": ['AE2 Z M AH0 D EY1 AH0 S'],
  "asmus": ['AH0 Z M UW1 S'],
  "asmussen": ['AH0 Z M AH1 S AH0 N'],
  "asner": ['AE1 S N ER0'],
  "asp": ['AE1 S P'],
  "asparagus": ['AH0 S P EH1 R AH0 G AH0 S'],
  "aspartame": ['AE1 S P ER0 T EY2 M'],
  "aspect": ['AE1 S P EH2 K T'],
  "aspects": ['AE1 S P EH2 K T S'],
  "aspen": ['AE1 S P AH0 N'],
  "aspen's": ['AE1 S P AH0 N Z'],
  "aspens": ['AE1 S P AH0 N Z'],
  "asper": ['AE1 S P ER0'],
  "asperity": ['AH0 S P EH1 R IH0 T IY0'],
  "aspersion": ['AH0 S P ER1 ZH AH0 N'],
  "aspersions": ['AH0 S P ER1 ZH AH0 N Z'],
  "asphalt": ['AE1 S F AO2 L T'],
  "asphyxiate": ['AE0 S F IH1 K S IY0 EY2 T'],
  "asphyxiated": ['AE0 S F IH1 K S IY0 EY2 T IH0 D'],
  "asphyxiates": ['AE0 S F IH1 K S IY0 EY2 T S'],
  "asphyxiating": ['AE0 S F IH1 K S IY0 EY2 T IH0 NG'],
  "aspin": ['AE1 S P IH0 N'],
  "aspin's": ['AE1 S P IH0 N Z'],
  "aspinall": ['AE1 S P IH0 N AO0 L'],
  "aspinwall": ['AE1 S P IH0 N W AO0 L'],
  "aspirant": ['AE1 S P ER0 AH0 N T', 'AH0 S P AY1 R AH0 N T'],
  "aspirants": ['AE1 S P ER0 AH0 N T S', 'AH0 S P AY1 R AH0 N T S', 'AE1 S P ER0 AH0 N S', 'AH0 S P AY1 R AH0 N S'],
  "aspirate": ['AE1 S P ER0 EY2 T'],
  "aspirated": ['AE1 S P ER0 EY2 T IH0 D'],
  "aspirates": ['AE1 S P ER0 EY2 T S'],
  "aspiration": ['AE2 S P ER0 EY1 SH AH0 N'],
  "aspirations": ['AE2 S P ER0 EY1 SH AH0 N Z'],
  "aspire": ['AH0 S P AY1 R'],
  "aspired": ['AH0 S P AY1 R D'],
  "aspires": ['AH0 S P AY1 ER0 Z'],
  "aspirin": ['AE1 S P R IH0 N'],
  "aspirin's": ['AE1 S P R AH0 N Z'],
  "aspiring": ['AH0 S P AY1 R IH0 NG'],
  "asplund": ['AE1 S P L AH0 N D'],
  "aspnes": ['AE1 S P N EH0 Z'],
  "asquith": ['AE1 S K W IH0 TH'],
  "ass": ['AE1 S'],
  "assad": ['AH0 S AA1 D'],
  "assad's": ['AH0 S AA1 D Z'],
  "assaf": ['AH0 S AE1 F'],
  "assail": ['AH0 S EY1 L'],
  "assailant": ['AH0 S EY1 L AH0 N T'],
  "assailant's": ['AH0 S EY1 L AH0 N T S'],
  "assailants": ['AH0 S EY1 L AH0 N T S'],
  "assailed": ['AH0 S EY1 L D'],
  "assailing": ['AH0 S EY1 L IH0 NG'],
  "assails": ['AH0 S EY1 L Z'],
  "assam": ['AA2 S AA1 M'],
  "assante": ['AA0 S AA1 N T IY0'],
  "assassin": ['AH0 S AE1 S AH0 N'],
  "assassin's": ['AH0 S AE1 S AH0 N Z'],
  "assassinate": ['AH0 S AE1 S AH0 N EY2 T'],
  "assassinated": ['AH0 S AE1 S AH0 N EY2 T IH0 D'],
  "assassinating": ['AH0 S AE1 S AH0 N EY2 T IH0 NG'],
  "assassination": ['AH0 S AE2 S AH0 N EY1 SH AH0 N'],
  "assassinations": ['AH0 S AE2 S AH0 N EY1 SH AH0 N Z'],
  "assassins": ['AH0 S AE1 S AH0 N Z'],
  "assault": ['AH0 S AO1 L T'],
  "assaulted": ['AH0 S AO1 L T IH0 D'],
  "assaulting": ['AH0 S AO1 L T IH0 NG'],
  "assaultive": ['AH0 S AO1 L T IH0 V'],
  "assaults": ['AH0 S AO1 L T S'],
  "assay": ['AE1 S IY0'],
  "assayer": ['AE0 S EY1 ER0'],
  "assays": ['AE1 S EY2 Z'],
  "assed": ['AE1 S T'],
  "asselin": ['AE1 S IH0 L IH0 N'],
  "asselstine": ['AE1 S AH0 L S T AY2 N'],
  "assemblage": ['AH0 S EH1 M B L AH0 JH', 'AH0 S EH1 M B L IH0 JH'],
  "assemble": ['AH0 S EH1 M B AH0 L'],
  "assembled": ['AH0 S EH1 M B AH0 L D'],
  "assembler": ['AH0 S EH1 M B L ER0'],
  "assemblers": ['AH0 S EH1 M B L ER0 Z'],
  "assembles": ['AH0 S EH1 M B AH0 L Z'],
  "assemblies": ['AH0 S EH1 M B L IY0 Z'],
  "assembling": ['AH0 S EH1 M B AH0 L IH0 NG', 'AH0 S EH1 M B L IH0 NG'],
  "assembly": ['AH0 S EH1 M B L IY0'],
  "assembly's": ['AH0 S EH1 M B L IY0 Z'],
  "assemblyman": ['AH0 S EH1 M B L IY0 M AE2 N', 'AH0 S EH1 M B L IY0 M AH0 N'],
  "assemblymen": ['AH0 S EH1 M B L IY0 M IH0 N'],
  "assemblywoman": ['AH0 S EH1 M B L IY0 W UH2 M AH0 N'],
  "assent": ['AH0 S EH1 N T'],
  "assert": ['AH0 S ER1 T'],
  "asserted": ['AH0 S ER1 T IH0 D'],
  "assertedly": ['AH0 S ER1 T IH0 D L IY0'],
  "asserting": ['AH0 S ER1 T IH0 NG'],
  "assertion": ['AH0 S ER1 SH AH0 N'],
  "assertions": ['AH0 S ER1 SH AH0 N Z'],
  "assertive": ['AH0 S ER1 T IH0 V'],
  "assertively": ['AH0 S ER1 T IH0 V L IY0'],
  "assertiveness": ['AH0 S ER1 T IH0 V N AH0 S'],
  "asserts": ['AH0 S ER1 T S'],
  "asses": ['AE1 S AH0 Z'],
  "assess": ['AH0 S EH1 S'],
  "assessed": ['AH0 S EH1 S T'],
  "assesses": ['AH0 S EH1 S IH0 Z'],
  "assessing": ['AH0 S EH1 S IH0 NG'],
  "assessment": ['AH0 S EH1 S M AH0 N T'],
  "assessments": ['AH0 S EH1 S M AH0 N T S'],
  "assessor": ['AH0 S EH1 S ER0'],
  "assessors": ['AH0 S EH1 S ER0 Z'],
  "asset": ['AE1 S EH2 T'],
  "assets": ['AE1 S EH2 T S'],
  "assets'": ['AE1 S EH0 T S'],
  "asshole": ['AE1 S HH OW0 L'],
  "assholes": ['AE1 S HH OW0 L Z'],
  "assicurazioni": ['AH0 S IY2 K ER0 AE2 Z IY0 OW1 N IY0'],
  "assiduous": ['AH0 S IH1 D W AH0 S'],
  "assiduously": ['AH0 S IH1 D W AH0 S L IY0'],
  "assign": ['AH0 S AY1 N'],
  "assigned": ['AH0 S AY1 N D'],
  "assigning": ['AH0 S AY1 N IH0 NG'],
  "assignment": ['AH0 S AY1 N M AH0 N T'],
  "assignment's": ['AH0 S AY1 N M AH0 N T S'],
  "assignments": ['AH0 S AY1 N M AH0 N T S'],
  "assigns": ['AH0 S AY1 N Z'],
  "assimilate": ['AH0 S IH1 M AH0 L EY2 T'],
  "assimilated": ['AH0 S IH1 M AH0 L EY2 T IH0 D'],
  "assimilating": ['AH0 S IH1 M AH0 L EY2 T IH0 NG'],
  "assimilation": ['AH0 S IH2 M AH0 L EY1 SH AH0 N'],
  "assira": ['AH0 S IY1 R AH0'],
  "assisi": ['AH0 S IY1 S IY0'],
  "assisi's": ['AH0 S IY1 S IY0 Z'],
  "assist": ['AH0 S IH1 S T'],
  "assistance": ['AH0 S IH1 S T AH0 N S'],
  "assistant": ['AH0 S IH1 S T AH0 N T'],
  "assistants": ['AH0 S IH1 S T AH0 N T S'],
  "assisted": ['AH0 S IH1 S T IH0 D'],
  "assisting": ['AH0 S IH1 S T IH0 NG'],
  "assists": ['AH0 S IH1 S T S'],
  "assocation": ['AE2 S AH0 K EY1 SH AH0 N'],
  "associate": ['AH0 S OW1 S IY0 AH0 T', 'AH0 S OW1 S IY0 EY2 T', 'AH0 S OW1 SH IY0 AH0 T', 'AH0 S OW1 SH IY0 EY2 T'],
  "associate's": ['AH0 S OW1 S IY0 AH0 T S', 'AH0 S OW1 SH IY0 AH0 T S'],
  "associated": ['AH0 S OW1 S IY0 EY2 T IH0 D', 'AH0 S OW1 SH IY0 EY2 T IH0 D'],
  "associates": ['AH0 S OW1 S IY0 AH0 T S', 'AH0 S OW1 S IY0 EY2 T S', 'AH0 S OW1 SH IY0 AH0 T S', 'AH0 S OW1 SH IY0 EY2 T S'],
  "associates'": ['AH0 S OW1 SH IY0 AH0 T S', 'AH0 S OW1 S IY0 AH0 T S'],
  "associating": ['AH0 S OW1 S IY0 EY2 T IH0 NG'],
  "association": ['AH0 S OW2 S IY0 EY1 SH AH0 N', 'AH0 S OW2 SH IY0 EY1 SH AH0 N'],
  "association's": ['AH0 S OW2 SH IY0 EY1 SH AH0 N Z'],
  "associations": ['AH0 S OW2 S IY0 EY1 SH AH0 N Z', 'AH0 S OW2 SH IY0 EY1 SH AH0 N Z'],
  "associative": ['AH0 S OW1 SH AH0 T IH2 V'],
  "associes": ['AE1 S AH0 S IY0 Z'],
  "assort": ['AH0 S AO1 R T'],
  "assorted": ['AH0 S AO1 R T IH0 D'],
  "assortment": ['AH0 S AO1 R T M AH0 N T'],
  "assuage": ['AH0 S W EY1 JH'],
  "assuaged": ['AH0 S W EY1 JH D'],
  "assubel": ['AE1 S AH0 B EH2 L'],
  "assume": ['AH0 S UW1 M'],
  "assumed": ['AH0 S UW1 M D'],
  "assumes": ['AH0 S UW1 M Z'],
  "assuming": ['AH0 S UW1 M IH0 NG'],
  "assumption": ['AH0 S AH1 M P SH AH0 N'],
  "assumptions": ['AH0 S AH1 M P SH AH0 N Z'],
  "assurance": ['AH0 SH UH1 R AH0 N S'],
  "assurances": ['AH0 SH UH1 R AH0 N S IH0 Z', 'AH0 SH UH1 R AH0 N T S IH0 Z'],
  "assuras": ['AH0 SH UH1 R AH0 S'],
  "assure": ['AH0 SH UH1 R'],
  "assured": ['AH0 SH UH1 R D'],
  "assuredly": ['AH0 SH UH1 R AH0 D L IY0'],
  "assures": ['AH0 SH UH1 R Z'],
  "assuring": ['AH0 SH UH1 R IH0 NG'],
  "assyria": ['AH0 S IH1 R IY0 AH0'],
  "assyrian": ['AH0 S IH1 R IY0 AH0 N'],
  "assyrians": ['AH0 S IH1 R IY0 AH0 N Z'],
  "ast": ['AE1 S T'],
  "asta": ['AA1 S T AH0'],
  "astaire": ['AH0 S T EH1 R'],
  "aster": ['AE1 S T ER0'],
  "asterisk": ['AE1 S T ER0 IH0 S K'],
  "asteroid": ['AE1 S T ER0 OY2 D'],
  "asteroid's": ['AE1 S T ER0 OY2 D Z'],
  "asteroids": ['AE1 S T ER0 OY2 D Z'],
  "asters": ['AE1 S T ER0 Z'],
  "asthma": ['AE1 Z M AH0'],
  "asthmatic": ['AE0 Z M AE1 T IH0 K'],
  "asthmatics": ['EH0 S TH M EH1 T IH0 K S'],
  "astigmatism": ['AH0 S T IH1 G M AH0 T IH2 Z AH0 M'],
  "astilbe": ['AH0 S T IH1 B IY2'],
  "astin": ['AH0 S T IH1 N'],
  "astle": ['AE1 S AH0 L'],
  "astley": ['AE1 S T L IY0'],
  "aston": ['AE1 S T AH0 N'],
  "astonish": ['AH0 S T AA1 N IH0 SH'],
  "astonished": ['AH0 S T AA1 N IH0 SH T'],
  "astonishing": ['AH0 S T AA1 N IH0 SH IH0 NG'],
  "astonishingly": ['AH0 S T AA1 N IH0 SH IH0 NG L IY0'],
  "astonishment": ['AH0 S T AA1 N IH0 SH M AH0 N T'],
  "astor": ['AE1 S T ER0'],
  "astor's": ['AE1 S T ER0 Z'],
  "astore": ['AE1 S T AO0 R', 'AE0 S T AO1 R IY0'],
  "astorga": ['AA0 S T AO1 R G AH0'],
  "astoria": ['AE2 S T AO1 R IY0 AH0'],
  "astorino": ['AA0 S T AO0 R IY1 N OW0'],
  "astound": ['AH0 S T AW1 N D'],
  "astounded": ['AH0 S T AW1 N D IH0 D'],
  "astounding": ['AH0 S T AW1 N D IH0 NG'],
  "astoundingly": ['AH0 S T AW1 N D IH0 NG L IY0'],
  "astounds": ['AH0 S T AW1 N D Z'],
  "astra": ['AE1 S T R AH0'],
  "astra's": ['AE1 S T R AH0 Z'],
  "astraddle": ['AH0 S T R AE1 D AH0 L'],
  "astrakhan": ['AE1 S T R AH0 K AA0 N'],
  "astral": ['AE1 S T R AH0 L'],
  "astray": ['AH0 S T R EY1'],
  "astred": ['AE1 S T ER0 D'],
  "astrid": ['AE1 S T R IH0 D'],
  "astride": ['AH0 S T R AY1 D'],
  "astringent": ['AH0 S T R IH1 N JH AH0 N T'],
  "astringents": ['AH0 S T R IH1 N JH AH0 N T S'],
  "astro": ['AE1 S T R OW0'],
  "astrodome": ['AE1 S T R AH0 D OW2 M'],
  "astrogeology": ['AE2 S T R AH0 JH IY2 AA1 L AH0 JH IY0'],
  "astrologer": ['AH0 S T R AA1 L AH0 JH ER0'],
  "astrologers": ['AH0 S T R AA1 L AH0 JH ER0 Z'],
  "astrological": ['AE2 S T R AH0 L AA1 JH IH0 K AH0 L'],
  "astrology": ['AH0 S T R AA1 L AH0 JH IY0'],
  "astronaut": ['AE1 S T R AH0 N AA2 T'],
  "astronaut's": ['AE1 S T R AH0 N AA2 T S'],
  "astronautic": ['AE2 S T R AH0 N AA1 T IH0 K'],
  "astronautical": ['AE2 S T R AH0 N AA1 T IH0 K AH0 L'],
  "astronautics": ['AE2 S T R AH0 N AA1 T IH0 K S'],
  "astronauts": ['AE1 S T R AH0 N AO2 T S'],
  "astronauts'": ['AE1 S T R AH0 N AO2 T S'],
  "astronomer": ['AH0 S T R AA1 N AH0 M ER0'],
  "astronomers": ['AH0 S T R AA1 N AH0 M ER0 Z'],
  "astronomical": ['AE2 S T R AH0 N AA1 M IH0 K AH0 L'],
  "astronomically": ['AE2 S T R AH0 N AA1 M IH0 K L IY0'],
  "astronomy": ['AH0 S T R AA1 N AH0 M IY0'],
  "astrophotography": ['AE2 S T R OW0 F AH0 T AA1 G R AH0 F IY0'],
  "astrophysicist": ['AE2 S T R OW0 F IH1 S IH0 S IH0 S T'],
  "astrophysics": ['AE2 S T R OW0 F IH1 Z IH0 K S'],
  "astros": ['AE1 S T R OW0 S'],
  "astrotech": ['AE1 S T R OW0 T EH2 K'],
  "astroturf": ['AE1 S T R OW0 T ER2 F'],
  "astute": ['AH0 S T UW1 T'],
  "astutely": ['AH0 S T UW1 T L IY0'],
  "astuteness": ['AH0 S T UW1 T N AH0 S'],
  "asuncion": ['AH0 S AH1 N SH AH0 N'],
  "asunder": ['AH0 S AH1 N D ER0'],
  "asus": ['EY1 S AH0 S'],
  "aswin": ['AH0 S W IH1 N'],
  "asylum": ['AH0 S AY1 L AH0 M'],
  "asymmetric": ['EY2 S AH0 M EH1 T R IH0 K'],
  "asymmetrical": ['EY2 S AH0 M EH1 T R IH0 K AH0 L'],
  "asymmetries": ['EY2 S IH1 M AH0 T R IY0 Z'],
  "asymmetry": ['EY2 S IH1 M AH0 T R IY0'],
  "asymptomatic": ['EY2 S IH2 M P T AH0 M AE1 T IH0 K'],
  "asymptote": ['AE1 S AH0 M T OW2 T', 'AE1 S IH0 M P T OW2 T'],
  "asymptotes": ['AE1 S AH0 M T OW2 T S', 'AE1 S IH0 M P T OW2 T S'],
  "asymptotic": ['AE1 S IH0 M P T AO2 T IH0 K'],
  "asynchronous": ['EY1 S IH1 NG K R AH0 N AH0 S'],
  "at": ['AE1 T'],
  "at-bat": ['AE1 T B AE1 T'],
  "at-bats": ['AE1 T B AE1 T S'],
  "ata": ['AA1 T AH0'],
  "atacama": ['AA1 T AA0 K AA2 M AA0'],
  "atalanta": ['AE2 T AH0 L AE1 N T AH0'],
  "atalaya": ['AA0 T AA0 L EY1 AH0'],
  "atalia": ['AA0 T AA1 L IY0 AH0'],
  "atalie": ['AE1 T AH0 L IY0'],
  "atamian": ['AH0 T EY1 M IY0 AH0 N'],
  "atari": ['AH0 T AA1 R IY0'],
  "atari's": ['AH0 T AA1 R IY0 Z'],
  "ataturk": ['AE1 T AH0 T ER2 K'],
  "ataturk's": ['AE1 T AH0 T ER2 K S'],
  "atavism": ['AE1 T AH0 V IH0 Z AH0 M'],
  "atavistic": ['AE1 T AH0 V IH0 S T IH0 K'],
  "ataxia": ['EY1 T AE1 K S IY0 AH0'],
  "ataxia's": ['EY1 T AE1 K S IY0 AH0 Z'],
  "atcheson": ['AE1 CH IH0 S AH0 N'],
  "atchinson": ['AE1 CH IH0 N S AH0 N'],
  "atchison": ['AE1 CH IH0 S AH0 N'],
  "atchley": ['AE1 CH L IY0'],
  "atco": ['AE1 T K OW0'],
  "atcor": ['AE1 T K AO0 R'],
  "ate": ['EY1 T'],
  "atek": ['EY1 T EH2 K'],
  "aten": ['EY1 T AH0 N'],
  "atencio": ['AH0 T EH1 N S IY0 OW0'],
  "ater": ['EY1 T ER0'],
  "ates": ['EY1 T S'],
  "atha": ['AE1 TH AH0'],
  "athalia": ['EY0 TH AE1 L IY0 AH0'],
  "athanas": ['EY0 TH AE1 N AH0 Z'],
  "athanassiou": ['AA2 TH AH0 N AH0 S IY1 UW0'],
  "athans": ['AE1 TH AH0 N Z'],
  "athas": ['EY1 DH AH0 Z'],
  "athearn": ['EY1 TH ER0 N'],
  "atheism": ['AH0 TH AY1 S AH0 M', 'EY1 TH IY0 IH2 Z AH0 M'],
  "atheist": ['EY1 TH IY0 AH0 S T'],
  "atheistic": ['EY2 TH IY0 IH1 S T IH0 K'],
  "atheists": ['EY1 TH IY0 AH0 S T S'],
  "athena": ['AH0 TH IY1 N AH0'],
  "athenaeum": ['AE2 TH AH0 N IY1 AH0 M'],
  "athenaios": ['AE2 TH AH0 N AY1 OW0 S'],
  "athene": ['AE2 TH IY1 N'],
  "atheneum": ['AE1 TH AH0 N UW0 M'],
  "athenian": ['AH0 TH IY1 N IY0 AH0 N'],
  "athenians": ['AH0 TH IY1 N IY0 AH0 N Z'],
  "athens": ['AE1 TH AH0 N Z'],
  "atherosclerosis": ['AE2 TH ER0 OW0 S K L ER0 OW1 S IH0 S'],
  "atherton": ['AE1 TH ER0 T AH0 N'],
  "athey": ['AE1 TH IY0'],
  "athie": ['EY1 TH IY0'],
  "athlete": ['AE1 TH L IY2 T'],
  "athlete's": ['AE1 TH L IY2 T S'],
  "athletes": ['AE1 TH L IY2 T S'],
  "athletes'": ['AE1 TH L IY2 T S'],
  "athletic": ['AE0 TH L EH1 T IH0 K'],
  "athletically": ['AE0 TH L EH1 T IH0 K L IY0'],
  "athleticism": ['AE0 TH L EH1 T IH0 S IH2 Z M'],
  "athletics": ['AE0 TH L EH1 T IH0 K S'],
  "athlone": ['AE0 TH L OW1 N'],
  "athwart": ['AH0 TH W AO1 R T'],
  "ati": ['EY2 T IY2 AY1'],
  "atico": ['AE1 T IH0 K OW2'],
  "atienza": ['AA0 T IY1 N Z AH0'],
  "atilano": ['AA0 T IY0 L AA1 N OW0'],
  "atither": ['AH0 T IH1 DH ER0'],
  "atkerson": ['AE1 T K ER0 S AH0 N'],
  "atkin": ['AH0 T K IH1 N'],
  "atkins": ['AE1 T K IH0 N Z'],
  "atkins's": ['AE1 T K IH0 N Z IH0 Z'],
  "atkinson": ['AE1 T K AH0 N S AH0 N', 'AE1 T K IH0 N S AH0 N'],
  "atkinson's": ['AE1 T K AH0 N S AH0 N Z', 'AE1 T K IH0 N S AH0 N Z'],
  "atkison": ['AE1 T K IH0 S AH0 N'],
  "atkisson": ['AE1 T K IH0 S AH0 N'],
  "atla": ['AE1 T L AH0'],
  "atlan": ['AE1 T L AH0 N'],
  "atlanta": ['AE0 T L AE1 N T AH0', 'AH0 T L AE1 N T AH0'],
  "atlanta's": ['AE0 T L AE1 N T AH0 Z'],
  "atlantan": ['AE2 T L AE1 N T AH0 N'],
  "atlantans": ['AE2 T L AE1 N T AH0 N Z'],
  "atlantic": ['AH0 T L AE1 N T IH0 K', 'AH0 T L AE1 N IH0 K'],
  "atlantic's": ['AH0 T L AE1 N T IH0 K S', 'AH0 T L AE1 N IH0 K S'],
  "atlantica": ['AE2 T L AE1 N T IH0 K AH0'],
  "atlantico": ['AE2 T L AE1 N T IH0 K OW0'],
  "atlantis": ['AE0 T L AE1 N T IH0 S', 'AE0 T L AE1 N IH0 S'],
  "atlantis'": ['AE0 T L AE1 N T IH0 S', 'AE0 T L AE1 N T IH0 S IH0 Z'],
  "atlantis's": ['AE0 T L AE1 N T IH0 S IH0 Z'],
  "atlas": ['AE1 T L AH0 S'],
  "atlas's": ['AE1 T L AH0 S IH0 Z'],
  "atlases": ['AE0 T L EY1 S IH0 Z', 'AE1 T L AH0 S IH0 Z'],
  "atler": ['AE1 T L ER0'],
  "atley": ['AE1 T L IY0'],
  "atm": ['EY1 T IY2 EH1 M'],
  "atman": ['AE1 T M AH0 N'],
  "atmel": ['AE1 T M AH0 L'],
  "atmosphere": ['AE1 T M AH0 S F IH2 R'],
  "atmospheric": ['AE2 T M AH0 S F EH1 R IH0 K'],
  "atmospherics": ['AE2 T M AH0 S F EH1 R IH0 K S'],
  "atnip": ['AE1 T N IH0 P'],
  "atocha": ['AH0 T AA1 CH AH0'],
  "atoll": ['AE1 T AA2 L', 'AE1 T AO2 L', 'AH0 T OW1 L'],
  "atom": ['AE1 T AH0 M'],
  "atomic": ['AH0 T AA1 M IH0 K'],
  "atomizer": ['AE1 T AH0 M AY2 Z ER0'],
  "atoms": ['AE1 T AH0 M Z'],
  "aton": ['AH0 T AA1 N'],
  "atonal": ['EY0 T OW1 N AH0 L'],
  "atone": ['AH0 T OW1 N'],
  "atonement": ['AH0 T OW1 N M AH0 N T'],
  "atop": ['AH0 T AA1 P'],
  "ator": ['AA1 T AO0 R'],
  "atorino": ['AE2 T ER0 IY1 N OW0'],
  "atp": ['EY1 T IY1 P IY1'],
  "atra": ['EY1 T R AH0'],
  "atria": ['EY1 T R IY0 AH0'],
  "atrium": ['EY1 T R IY0 AH0 M'],
  "atriums": ['EY1 T R IY0 AH0 M Z'],
  "atrocious": ['AH0 T R OW1 SH AH0 S'],
  "atrocities": ['AH0 T R AA1 S AH0 T IY0 Z'],
  "atrocity": ['AH0 T R AA1 S AH0 T IY0'],
  "atrophied": ['AE1 T R AH0 F IY0 D'],
  "atrophies": ['AE1 T R AH0 F IY0 Z'],
  "atrophy": ['AE1 T R AH0 F IY0'],
  "atsushi": ['AA0 T S UW1 SH IY2'],
  "atta": ['AE1 T AH0'],
  "attaboy": ['AE1 T AH0 B OY2'],
  "attaboys": ['AE1 T AH0 B OY2 Z'],
  "attach": ['AH0 T AE1 CH'],
  "attache": ['AE2 T AH0 SH EY1'],
  "attached": ['AH0 T AE1 CH T'],
  "attaches": ['AH0 T AE1 CH IH0 Z'],
  "attaching": ['AH0 T AE1 CH IH0 NG'],
  "attachment": ['AH0 T AE1 CH M AH0 N T'],
  "attachments": ['AH0 T AE1 CH M AH0 N T S'],
  "attack": ['AH0 T AE1 K'],
  "attacked": ['AH0 T AE1 K T'],
  "attacker": ['AH0 T AE1 K ER0'],
  "attackers": ['AH0 T AE1 K ER0 Z'],
  "attacking": ['AH0 T AE1 K IH0 NG'],
  "attacks": ['AH0 T AE1 K S'],
  "attain": ['AH0 T EY1 N'],
  "attainable": ['AH0 T EY1 N AH0 B AH0 L'],
  "attainder": ['AH0 T EY1 N D ER0'],
  "attained": ['AH0 T EY1 N D'],
  "attaining": ['AH0 T EY1 N IH0 NG'],
  "attainment": ['AH0 T EY1 N M AH0 N T'],
  "attains": ['AH0 T EY1 N Z'],
  "attali": ['AH0 T AA1 L IY0'],
  "attalla": ['AH0 T AE1 L AH0'],
  "attanasio": ['AA0 T AA0 N AA1 S IY0 OW0'],
  "attar": ['AE1 T ER0'],
  "attard": ['AE1 T ER0 D'],
  "attardo": ['AA0 T AA1 R D OW0'],
  "attaway": ['AE1 T AH0 W EY0'],
  "atteberry": ['AE1 T B EH0 R IY0'],
  "attebery": ['AH0 T EH1 B ER0 IY0'],
  "attebury": ['AE1 T B EH0 R IY0'],
  "attempt": ['AH0 T EH1 M P T'],
  "attempted": ['AH0 T EH1 M P T IH0 D'],
  "attempting": ['AH0 T EH1 M P T IH0 NG'],
  "attempts": ['AH0 T EH1 M P T S', 'AH0 T EH1 M P S'],
  "attenborough": ['AE1 T AH2 N B ER0 OW0'],
  "attend": ['AH0 T EH1 N D'],
  "attendance": ['AH0 T EH1 N D AH0 N S'],
  "attendant": ['AH0 T EH1 N D AH0 N T'],
  "attendant's": ['AH0 T EH1 N D AH0 N T S'],
  "attendants": ['AH0 T EH1 N D AH0 N T S'],
  "attendants'": ['AH0 T EH1 N D AH0 N T S'],
  "attended": ['AH0 T EH1 N D IH0 D'],
  "attendee": ['AH0 T EH1 N D IY1'],
  "attendees": ['AH0 T EH1 N D IY1 Z'],
  "attending": ['AH0 T EH1 N D IH0 NG'],
  "attends": ['AH0 T EH1 N D Z'],
  "attention": ['AH0 T EH1 N SH AH0 N'],
  "attentions": ['AH0 T EH1 N SH AH0 N Z'],
  "attentive": ['AH0 T EH1 N T IH0 V'],
  "attentively": ['AH0 T EH1 N T IH0 V L IY0'],
  "attentiveness": ['AH0 T EH1 N T IH0 V N AH0 S'],
  "attenuate": ['AH0 T EH1 N Y UW0 EY2 T'],
  "attenuated": ['AH0 T EH1 N Y UW0 EY2 T IH0 D'],
  "attenuates": ['AH0 T EH1 N Y UW0 EY2 T S'],
  "attenuation": ['AH0 T EH2 N Y UW0 EY1 SH AH0 N'],
  "atterberry": ['AE1 T ER0 B EH0 R IY0'],
  "atterbury": ['AE1 T ER0 B EH2 R IY0'],
  "attermann": ['AE1 T ER0 M AH0 N'],
  "attest": ['AH0 T EH1 S T'],
  "attested": ['AH0 T EH1 S T IH0 D'],
  "attesting": ['AH0 T EH1 S T IH0 NG'],
  "attests": ['AH0 T EH1 S T S', 'AH0 T EH1 S'],
  "attic": ['AE1 T IH0 K'],
  "attica": ['AE1 T IH0 K AH0'],
  "attics": ['AE1 T IH0 K S'],
  "atticus": ['AE1 T IH0 K AH0 S'],
  "attie": ['AE1 T IY0'],
  "attila": ['AH0 T IH1 L AH0'],
  "attila's": ['AH0 T IH1 L AH0 Z'],
  "attire": ['AH0 T AY1 ER0'],
  "attired": ['AH0 T AY1 R D'],
  "attitude": ['AE1 T AH0 T UW2 D'],
  "attitudes": ['AE1 T AH0 T UW2 D Z'],
  "attitudinal": ['AE2 T AH0 T UW1 D AH0 N AH0 L'],
  "attkisson": ['AE1 T K IH0 S AH0 N'],
  "attleboro": ['AE1 T AH0 L B ER0 OW0'],
  "attlee": ['AE1 T L IY0'],
  "attorney": ['AH0 T ER1 N IY0'],
  "attorney's": ['AH0 T ER1 N IY0 Z'],
  "attorneys": ['AH0 T ER1 N IY0 Z'],
  "attorneys'": ['AH0 T ER1 N IY0 Z'],
  "attract": ['AH0 T R AE1 K T'],
  "attracted": ['AH0 T R AE1 K T IH0 D'],
  "attracting": ['AH0 T R AE1 K T IH0 NG'],
  "attraction": ['AH0 T R AE1 K SH AH0 N'],
  "attractions": ['AH0 T R AE1 K SH AH0 N Z'],
  "attractive": ['AH0 T R AE1 K T IH0 V'],
  "attractively": ['AH0 T R AE1 K T IH0 V L IY0'],
  "attractiveness": ['AH0 T R AE1 K T IH0 V N AH0 S'],
  "attracts": ['AH0 T R AE1 K T S'],
  "attributable": ['AH0 T R IH1 B Y AH0 T AH0 B AH0 L'],
  "attribute": ['AE1 T R AH0 B Y UW2 T', 'AH0 T R IH1 B Y UW2 T'],
  "attributed": ['AH0 T R IH1 B Y AH0 T IH0 D'],
  "attributes": ['AE1 T R AH0 B Y UW2 T S', 'AH0 T R IH1 B Y UW2 T S'],
  "attributing": ['AH0 T R IH1 B Y AH0 T IH0 NG'],
  "attribution": ['AE2 T R IH0 B Y UW1 SH AH0 N'],
  "attridge": ['AH0 T R IH1 JH'],
  "attrition": ['AH0 T R IH1 SH AH0 N'],
  "attune": ['AH0 T UW1 N'],
  "attuned": ['AH0 T UW1 N D'],
  "attwood": ['AE1 T W UH2 D'],
  "attwoods": ['AE1 T W UH2 D Z'],
  "atv": ['EY2 T IY2 V IY1'],
  "atwater": ['AE1 T W AO0 T ER0'],
  "atwell": ['AH0 T W EH1 L'],
  "atwood": ['AE1 T W UH2 D'],
  "atworth": ['AE1 T W ER0 TH'],
  "atx": ['EY1 T IY1 EH1 K S'],
  "atypia": ['EY2 T IH1 P IY2 AH0'],
  "atypic": ['EY2 T IH1 P IH0 K'],
  "atypical": ['EY2 T IH1 P IH0 K AH0 L'],
  "atz": ['AE1 T S'],
  "au": ['OW1'],
  "aube": ['AO1 B'],
  "aubel": ['AW1 B AH0 L'],
  "auber": ['AO1 B ER0'],
  "aubergine": ['AO1 B ER0 JH IY2 N'],
  "aubergines": ['AO1 B ER0 JH IY2 N Z'],
  "auberry": ['AO1 B EH2 R IY0'],
  "aubert": ['AO1 B ER0 T'],
  "aubin": ['AO1 B IH0 N'],
  "auble": ['AO1 B AH0 L'],
  "aubrey": ['AO1 B R IY0'],
  "aubry": ['AO1 B R IY0'],
  "aubuchon": ['OW1 B AH0 SH AA0 N'],
  "auburn": ['AA1 B ER0 N'],
  "auch": ['AO1 CH'],
  "auchter": ['AW1 K T ER0'],
  "auckland": ['AA1 K L AH0 N D'],
  "auclair": ['OW0 K L EH1 R'],
  "aucoin": ['OW0 K OY1 N'],
  "aucott": ['AO1 K AA2 T'],
  "auction": ['AA1 K SH AH0 N', 'AO1 K SH AH0 N'],
  "auction's": ['AO1 K SH AH0 N Z'],
  "auctioned": ['AO1 K SH AH0 N D'],
  "auctioneer": ['AA2 K SH AH0 N IH1 R'],
  "auctioneering": ['AO2 K SH AH0 N IH1 R IH0 NG'],
  "auctioneers": ['AO1 K SH AH0 N IH1 R Z'],
  "auctioning": ['AO1 K SH AH0 N IH0 NG'],
  "auctions": ['AA1 K SH AH0 N Z', 'AO1 K SH AH0 N Z'],
  "aud": ['AO1 D'],
  "audacious": ['AA0 D EY1 SH AH0 S'],
  "audacity": ['AA0 D AE1 S AH0 T IY0'],
  "audas": ['OW0 D AA1 Z'],
  "auden": ['AO1 D AH0 N'],
  "auderburn": ['AO1 D ER0 B ER0 N'],
  "audet": ['OW0 D EH1 T'],
  "audette": ['OW0 D EH1 T'],
  "audi": ['AO1 D IY0', 'AW1 D IY0'],
  "audi's": ['AO1 D IY0 Z', 'AW1 D IY0 Z'],
  "audia": ['AO1 D IY0 AH0'],
  "audible": ['AA1 D AH0 B AH0 L'],
  "audibles": ['AA1 D AH0 B AH0 L Z'],
  "audibly": ['AA1 D AH0 B L IY0'],
  "audience": ['AA1 D IY0 AH0 N S', 'AO1 D IY0 AH0 N S'],
  "audience's": ['AA1 D IY0 AH0 N S AH0 Z', 'AO1 D IY0 AH0 N S AH0 Z'],
  "audiences": ['AA1 D IY0 AH0 N S AH0 Z', 'AO1 D IY0 AH0 N S AH0 Z'],
  "audino": ['AO2 D IY1 N OW0'],
  "audio": ['AA1 D IY0 OW2'],
  "audiophile": ['AO2 D IY1 OW0 F IY2 L'],
  "audiophiles": ['AO2 D IY1 OW0 F IY2 L Z'],
  "audiotape": ['AO1 D IY0 OW0 T EY2 P'],
  "audiotapes": ['AO1 D IY0 OW0 T EY2 P S'],
  "audiotex": ['AO1 D IY0 OW0 T EH2 K S'],
  "audiotronic": ['AO2 D IY0 OW0 T R AA1 N IH0 K'],
  "audiotronics": ['AO2 D IY0 OW0 T R AA1 N IH0 K S'],
  "audiovisual": ['AO2 D IY0 OW0 V IH1 ZH UW0 AH0 L'],
  "audiovox": ['AO1 D IY0 OW0 V AA2 K S'],
  "audis": ['AO1 D IH0 S', 'AW1 D IY0 Z'],
  "audit": ['AO1 D IH0 T'],
  "audited": ['AO1 D IH0 T IH0 D'],
  "auditing": ['AA1 D AH0 T IH0 NG'],
  "audition": ['AA0 D IH1 SH AH0 N'],
  "auditioned": ['AO2 D IH1 SH AH0 N D'],
  "auditioning": ['AA0 D IH1 SH AH0 N IH0 NG'],
  "auditions": ['AO2 D IH1 SH AH0 N Z'],
  "auditor": ['AO1 D IH0 T ER0'],
  "auditor's": ['AO1 D IH0 T ER0 Z'],
  "auditorium": ['AO2 D AH0 T AO1 R IY0 AH0 M'],
  "auditoriums": ['AO2 D IH0 T AO1 R IY0 AH0 M Z'],
  "auditors": ['AA1 D AH0 T ER0 Z'],
  "auditors'": ['AO1 D IH0 T ER0 Z'],
  "auditory": ['AO1 D IH0 T AO2 R IY0'],
  "audits": ['AA1 D AH0 T S'],
  "audran": ['AO1 D R AH0 N'],
  "audrey": ['AO1 D R IY0'],
  "audric": ['AO1 D R IH0 K'],
  "audrie": ['AO1 D ER0 IY0'],
  "audris": ['OW0 D R IY1 S'],
  "audry": ['AO1 D R IY0'],
  "audubon": ['AO1 D AH0 B AA0 N'],
  "audwin": ['AO1 D W IH0 N'],
  "aue": ['AW1'],
  "auel": ['AW1 AH0 L'],
  "auen": ['AW1 AH0 N'],
  "auer": ['AW1 ER0'],
  "auerbach": ['AW1 ER0 B AA0 K'],
  "auerback": ['AW1 R B AE2 K'],
  "aufderheide": ['AW1 F D ER0 HH AY0 D'],
  "aufhauser": ['AO1 F HH AW2 Z ER0'],
  "aufiero": ['OW0 F IH1 R OW0'],
  "aug": ['AO1 G AH0 S T'],
  "augat": ['AO1 G AE0 T'],
  "auge": ['AO1 JH'],
  "augello": ['AO0 JH EH1 L OW0'],
  "augenstein": ['AW1 G AH0 N S T AY0 N', 'AW1 G AH0 N S T IY0 N'],
  "auger": ['AO1 G ER0'],
  "augers": ['AO1 G ER0 Z'],
  "aughenbaugh": ['AO0 EH1 N B AO0'],
  "aught": ['AO1 T'],
  "augment": ['AO0 G M EH1 N T'],
  "augmentable": ['AA0 G M EH1 N T AH0 B AH0 L'],
  "augmentation": ['AA2 G M EH0 N T EY1 SH AH0 N'],
  "augmented": ['AA0 G M EH1 N T IH0 D'],
  "augmenting": ['AO1 G M EH2 N T IH0 NG'],
  "augsburg": ['AO1 G Z B ER0 G'],
  "augsburger": ['AW1 G Z B ER0 G ER0', 'AO1 G Z B ER0 G ER0'],
  "augspurger": ['AW1 G S P ER0 G ER0', 'AO1 G S P ER0 G ER0'],
  "augur": ['AO1 G ER0'],
  "auguring": ['AO1 G ER0 IH0 NG'],
  "augurs": ['AO1 G ER0 Z'],
  "august": ['AA1 G AH0 S T', 'AO1 G AH0 S T'],
  "august's": ['AO1 G AH0 S T S'],
  "augusta": ['AH0 G AH1 S T AH0'],
  "augusta's": ['AH0 G AH1 S T AH0 Z'],
  "auguste": ['AO1 G AH0 S T'],
  "augustin": ['AW0 G UW0 S T IY1 N'],
  "augustine": ['AA1 G AH0 S T IY2 N', 'AO1 G AH0 S T IY2 N'],
  "augustinian": ['AA2 G AH0 S T IH1 N IY0 AH0 N'],
  "augustinians": ['AA2 G AH0 S T IH1 N IY0 AH0 N Z'],
  "augusto": ['AO2 G AH1 S T OW0'],
  "augustson": ['AO1 G AH0 S T S AH0 N', 'AO1 G AH0 S AH0 N'],
  "augustus": ['AA0 G AH1 S T AH0 S', 'AH0 G AH1 S T AH0 S'],
  "augustyn": ['AW0 G AH1 S T IH0 N'],
  "augustyniak": ['AW0 G AH0 S T IH1 N IY0 AE0 K'],
  "aujourd'hui": ['OW1 ZH AO0 R D W IY1'],
  "aujourd'hui's": ['OW1 ZH AO0 R D W IY1 Z'],
  "auker": ['AO1 K ER0'],
  "aukerman": ['AW1 K ER0 M AH0 N'],
  "aul": ['AO1 L'],
  "aulana": ['AW2 L AA1 N AH0'],
  "aulander": ['OW1 L AE0 N D ER0'],
  "auld": ['AO1 L D'],
  "aulds": ['AO1 L D Z'],
  "auletta": ['AO0 L EH1 T AH0'],
  "aull": ['AO1 L'],
  "ault": ['AO1 L T'],
  "aultman": ['AO1 L T M AH0 N'],
  "auman": ['AO1 M AH0 N'],
  "aumann": ['AO1 M AH0 N'],
  "aument": ['AW1 M AH0 N T'],
  "aumiller": ['AW1 M AH0 L ER0'],
  "aune": ['AO1 N'],
  "aung": ['AO1 NG'],
  "aungier": ['EY1 N JH ER0'],
  "aungst": ['AW1 NG G S T'],
  "aunt": ['AE1 N T', 'AO1 N T'],
  "aunt's": ['AE1 N T S', 'AO1 N T S'],
  "auntie": ['AE1 N T IY0', 'AO1 N T IY0'],
  "aunts": ['AE1 N T S', 'AO1 N T S'],
  "aunts'": ['AE1 N T S', 'AO1 N T S'],
  "aupperle": ['AW1 P ER0 L IY0'],
  "aura": ['AO1 R AH0'],
  "aural": ['AO1 R AH0 L'],
  "aurand": ['AO1 R AH0 N D'],
  "aurea": ['AW0 R EY1 AA0'],
  "aurelio": ['AW0 R EY1 L IY0 OW0'],
  "aureole": ['AA1 R IY0 OW0 L'],
  "aureus": ['AO1 R EH2 AH0 S'],
  "auria": ['AO1 R IY0 AH0'],
  "aurich": ['AW1 R IH0 K'],
  "auriemma": ['AO0 R IY1 M AH0'],
  "aurora": ['ER0 AO1 R AH0'],
  "auroral": ['ER0 AO1 R AH0 L'],
  "aurum": ['AO1 R AH0 M'],
  "aus": ['AW1 Z'],
  "ausbrooks": ['AW1 S B R UH0 K S'],
  "ausburn": ['AW1 S B ER0 N'],
  "auschwitz": ['AO1 SH W IH0 T S', 'AW1 SH W IH0 T S'],
  "ausherman": ['AW1 SH ER0 M AH0 N'],
  "ausimont": ['AW1 S IH0 M AA2 N T'],
  "auslander": ['AW1 S L AH0 N D ER0'],
  "ausley": ['AO1 S L IY0'],
  "ausman": ['AW1 S M AH0 N'],
  "ausmus": ['AW1 S M IH0 S'],
  "auspey": ['AW1 S P IY0'],
  "auspice": ['AO1 S P IH0 S'],
  "auspices": ['AO1 S P IH0 S IH0 Z'],
  "auspicious": ['AA0 S P IH1 SH AH0 S'],
  "aussie": ['AO1 S IY0'],
  "aust": ['AO1 S T'],
  "austad": ['AW1 S T AH0 D'],
  "austat": ['OW1 S T AE0 T'],
  "austell": ['AW1 S T AH0 L'],
  "austen": ['AO1 S T IH0 N'],
  "austen's": ['AO1 S T IH0 N Z'],
  "auster": ['AO1 S T ER0'],
  "austere": ['AO0 S T IH1 R'],
  "austerely": ['AO2 S T IH1 R L IY0'],
  "austerity": ['AO2 S T EH1 R IH0 T IY0'],
  "austill": ['AW1 S T AH0 L'],
  "austin": ['AO1 S T AH0 N'],
  "austin's": ['AO1 S T AH0 N Z'],
  "austine": ['AO1 S T IY0 N'],
  "auston": ['AO1 S T AH0 N'],
  "austral": ['AO1 S T R AH0 L'],
  "australasia": ['AO2 S T R AH0 L EY1 ZH AH0'],
  "australia": ['AO0 S T R EY1 L Y AH0'],
  "australia's": ['AO0 S T R EY1 L Y AH0 Z'],
  "australian": ['AO0 S T R EY1 L Y AH0 N'],
  "australian's": ['AO0 S T R EY1 L Y AH0 N Z'],
  "australians": ['AO0 S T R EY1 L Y AH0 N Z'],
  "australopithecine": ['AO0 S T R EY2 L OW0 P IH1 TH AH0 S AY2 N'],
  "australopithecus": ['AO0 S T R EY2 L OW0 P IH1 TH AH0 K AH0 S'],
  "australs": ['AO1 S T R AH0 L Z'],
  "austrasia": ['AO0 S T R EY1 ZH AH0'],
  "austria": ['AO1 S T R IY0 AH0'],
  "austria's": ['AO1 S T R IY0 AH0 Z'],
  "austrian": ['AO1 S T R IY0 AH0 N'],
  "austrians": ['AO1 S T R IY0 AH0 N Z'],
  "austro": ['AO1 S T R OW0'],
  "austroasiatic": ['AO2 S T R OW2 EY2 ZH IY0 AE1 T IH0 K'],
  "austron": ['AO1 S T R AH0 N'],
  "auten": ['AW1 T AH0 N'],
  "autenrieth": ['AW1 T IH0 N R IY0 IH0 TH', 'AW1 T IH0 N R IY0 TH'],
  "autery": ['OW1 T ER0 IY0'],
  "auth": ['AO1 TH'],
  "authement": ['AW1 TH M AH0 N T'],
  "authentic": ['AH0 TH EH1 N T IH0 K', 'AO0 TH EH1 N T IH0 K', 'AO0 TH EH1 N IH0 K', 'AH0 TH EH1 N IH0 K'],
  "authentically": ['AO2 TH EH1 N T IH0 K L IY0', 'AO2 TH EH1 N IH0 K L IY0'],
  "authenticate": ['AO0 TH EH1 N T AH0 K EY2 T'],
  "authenticated": ['AO0 TH EH1 N T AH0 K EY2 T IH0 D'],
  "authenticates": ['AO0 TH EH1 N T AH0 K EY2 T S'],
  "authenticating": ['AO0 TH EH1 N T AH0 K EY2 T IH0 NG'],
  "authentication": ['AO0 TH EH2 N T AH0 K EY1 SH AH0 N'],
  "authentications": ['AO0 TH EH2 N T AH0 K EY1 SH AH0 N Z'],
  "authenticity": ['AO2 TH AH0 N T IH1 S IH0 T IY0'],
  "auther": ['AO1 TH ER0'],
  "authier": ['AO1 TH IY0 ER0'],
  "author": ['AO1 TH ER0'],
  "author's": ['AO1 TH ER0 Z'],
  "authored": ['AO1 TH ER0 D'],
  "authoring": ['AO1 TH ER0 IH0 NG'],
  "authoritarian": ['AH0 TH AO2 R AH0 T EH1 R IY0 AH0 N'],
  "authoritarianism": ['AH0 TH AO2 R AH0 T EH1 R IY0 AH0 N IH2 Z AH0 M'],
  "authoritative": ['AH0 TH AO1 R AH0 T EY2 T IH0 V'],
  "authoritatively": ['AH0 TH AO2 R AH0 T EY1 T IH0 V L IY0'],
  "authorities": ['AH0 TH AO1 R AH0 T IY0 Z'],
  "authorities'": ['AH0 TH AO1 R IH0 T IY0 Z'],
  "authority": ['AH0 TH AO1 R AH0 T IY0'],
  "authority's": ['AH0 TH AO1 R IH0 T IY0 Z'],
  "authorization": ['AO2 TH ER0 AH0 Z EY1 SH AH0 N'],
  "authorizations": ['AO2 TH ER0 AH0 Z EY1 SH AH0 N Z'],
  "authorize": ['AO1 TH ER0 AY2 Z'],
  "authorized": ['AO1 TH ER0 AY2 Z D'],
  "authorizes": ['AO1 TH ER0 AY2 Z IH0 Z'],
  "authorizing": ['AO1 TH ER0 AY2 Z IH0 NG'],
  "authors": ['AO1 TH ER0 Z'],
  "authors'": ['AO1 TH ER0 Z'],
  "authorship": ['AO1 TH ER0 SH IH2 P'],
  "autism": ['AO1 T IH2 Z AH0 M'],
  "autistic": ['AO0 T IH1 S T IH0 K'],
  "auto": ['AO1 T OW0'],
  "auto's": ['AO1 T OW0 Z'],
  "autobahn": ['AO1 T OW0 B AA2 N'],
  "autobiographer": ['AO2 T AH0 B AY0 AA1 G R AH0 F ER0'],
  "autobiographical": ['AO2 T AH0 B AY2 AH0 G R AE1 F IH0 K AH0 L'],
  "autobiographies": ['AO2 T AH0 B AY0 AA1 G R AH0 F IY0 Z'],
  "autobiography": ['AO2 T AH0 B AY0 AA1 G R AH0 F IY0'],
  "autobon": ['AA1 T AH0 B AA2 N'],
  "autobot": ['AO2 T AH0 B AA1 T'],
  "autobots": ['AO2 T AH0 B AA1 T S'],
  "autoclave": ['AO1 T OW0 K L EY2 V'],
  "autocracy": ['AO0 T AA1 K R AH0 S IY0'],
  "autocrat": ['AO1 T AH0 K R AE2 T'],
  "autocratic": ['AO2 T AH0 K R AE1 T IH0 K'],
  "autodesk": ['AO1 T OW0 D EH2 S K'],
  "autodie": ['AO1 T OW0 D AY2'],
  "autoeurope": ['AO2 T OW0 Y UH1 R AH0 P'],
  "autofact": ['AO1 T OW0 F AE2 K T'],
  "autofacts": ['AO1 T OW0 F AE2 K T S'],
  "autograph": ['AO1 T AH0 G R AE2 F'],
  "autographed": ['AO1 T AH0 G R AE2 F T'],
  "autographs": ['AO1 T AH0 G R AE2 F S'],
  "autoimmune": ['AO2 T OY1 M Y UW0 N'],
  "autolatina": ['AO2 T OW2 L AH0 T IY1 N AH0'],
  "automaker": ['AO1 T OW0 M EY2 K ER0'],
  "automaker's": ['AO1 T OW0 M EY2 K ER0 Z'],
  "automakers": ['AO1 T OW0 M EY2 K ER0 Z'],
  "automakers'": ['AO1 T OW0 M EY2 K ER0 Z'],
  "automap": ['AO1 T OW0 M AE2 P'],
  "automata": ['AO0 T AA1 M AH0 T AH0'],
  "automate": ['AO1 T AH0 M EY2 T'],
  "automated": ['AO1 T AH0 M EY2 T IH0 D'],
  "automates": ['AO1 T AH0 M EY2 T S'],
  "automatic": ['AO2 T AH0 M AE1 T IH0 K', 'AO2 T OW0 M AE1 T IH0 K'],
  "automatically": ['AO2 T AH0 M AE1 T IH0 K L IY0', 'AO2 T OW0 M AE1 T IH0 K L IY0'],
  "automatics": ['AO2 T AH0 M AE1 T IH0 K S'],
  "automating": ['AO1 T AH0 M EY2 T IH0 NG'],
  "automation": ['AO0 T AH0 M EY1 SH AH0 N'],
  "automatix": ['AO2 T OW0 M EY1 T IH0 K S'],
  "automaton": ['AO0 T AA1 M AH0 T AA2 N'],
  "automobile": ['AO1 T AH0 M OW0 B IY2 L', 'AO2 T AH0 M OW0 B IY1 L'],
  "automobiles": ['AO1 T AH0 M OW0 B IY2 L Z', 'AO2 T AH0 M OW0 B IY1 L Z'],
  "automobili": ['AO2 T OW0 M OW0 B IY1 L IY0'],
  "automotive": ['AO2 T AH0 M OW1 T IH0 V'],
  "automotive's": ['AO2 T AH0 M OW1 T IH0 V Z'],
  "automoviles": ['AO2 T OW0 M OW0 V IY1 L EH0 Z'],
  "auton": ['AO1 T AH0 N'],
  "autonomic": ['AO2 T AH0 N AA1 M IH0 K'],
  "autonomous": ['AO0 T AA1 N AH0 M AH0 S'],
  "autonomously": ['AO2 T AA1 N OW0 M AH0 S L IY0'],
  "autonomy": ['AO0 T AA1 N AH0 M IY0', 'AH0 T AA1 N AH0 M IY0'],
  "autopacific": ['AO2 T OW0 P AH0 S IH1 F IH0 K'],
  "autopart": ['AO1 T OW0 P AA2 R T'],
  "autoparts": ['AO1 T OW0 P AA2 R T S'],
  "autopilot": ['AO1 T OW0 P AY2 L AH0 T'],
  "autopsied": ['AO1 T AA2 P S IY0 D'],
  "autopsies": ['AO1 T AA2 P S IY0 Z'],
  "autopsy": ['AO1 T AA2 P S IY0'],
  "autorad": ['AO1 T OW0 R AE2 D'],
  "autoradiograph": ['AA0 T OW0 R EY1 D IY0 OW0 G R AE0 F'],
  "autorads": ['AO1 T OW0 R AE2 D Z'],
  "autos": ['AO1 T OW0 Z'],
  "autospa": ['AO1 T OW1 S P AH0'],
  "autotote": ['AO1 T OW0 T OW2 T'],
  "autotrophic": ['AO2 T AH0 T R OW1 F IH0 K'],
  "autoworker": ['AO1 T OW0 W ER0 K ER0'],
  "autoworkers": ['AO1 T OW0 W ER0 K ER0 Z'],
  "autrey": ['AO1 T R IY0'],
  "autry": ['AO1 T R IY0'],
  "autumn": ['AO1 T AH0 M'],
  "autumn's": ['AO1 T AH0 M Z'],
  "autumnal": ['AO0 T AH1 M N AH0 L'],
  "auvil": ['OW0 V IH1 L'],
  "aux": ['OW1'],
  "auxier": ['AO1 K S IY0 ER0'],
  "auxiliary": ['AA0 G Z IH1 L Y ER0 IY0', 'AA0 G Z IH1 L IY0 EH0 R IY0'],
  "auxton": ['AO1 K S T AH0 N'],
  "auyeung": ['AW1 Y UW2 NG'],
  "auzenne": ['AO1 Z IH0 N'],
  "av": ['EY2 V IY1'],
  "ava": ['EY1 V AH0'],
  "avail": ['AH0 V EY1 L'],
  "availabilities": ['AH0 V EY2 L AH0 B IH1 L IH0 T IY0 Z'],
  "availability": ['AH0 V EY2 L AH0 B IH1 L AH0 T IY0', 'AH0 V EY2 L AH0 B IH1 L IH0 T IY0'],
  "available": ['AH0 V EY1 L AH0 B AH0 L'],
  "availed": ['AH0 V EY1 L D'],
  "avakian": ['AH0 V EY1 K IY0 AH0 N'],
  "avalanche": ['AE1 V AH0 L AE2 N CH'],
  "avalanches": ['AE1 V AH0 L AE2 N CH IH0 Z'],
  "avallone": ['AE1 V AH0 L OW2 N'],
  "avalon": ['AE1 V AH0 L AA2 N'],
  "avalon's": ['AE1 V AH0 L AA2 N Z'],
  "avalos": ['AA0 V AA1 L OW0 Z'],
  "avana": ['AH0 V AE1 N AH0'],
  "avana's": ['AH0 V AE1 N AH0 Z'],
  "avanex": ['AA1 V AH0 N EH0 K S'],
  "avant": ['AH0 V AA1 N T'],
  "avant-garde": ['AH0 V AA1 N T G AA1 R D', 'AH0 V AA1 N G AA1 R D'],
  "avantek": ['AH0 V AA1 N T EH0 K'],
  "avanti": ['AH0 V AA1 N T IY0'],
  "avants": ['AA0 V AO1 N T S'],
  "avarice": ['AE1 V ER0 AH0 S'],
  "avasso": ['AH0 V AA1 S OW2'],
  "avatar": ['AE1 V AH0 T AA2 R'],
  "avatars": ['AE1 V AH0 T AA2 R Z'],
  "avco": ['AE1 V K OW0'],
  "avdel": ['AE1 V D EH2 L'],
  "ave": ['AA1 V EY2'],
  "avedisian": ['AE0 V IH0 D IH1 ZH IH0 N'],
  "avedon": ['AE1 V EH0 D AA0 N'],
  "avelar": ['AA0 V EY0 L AA1 R'],
  "aveline": ['AA0 V EH0 L IY1 N IY0'],
  "avella": ['AH0 V EH1 L AH0'],
  "avellino": ['AE2 V AH0 L IY1 N OW0'],
  "avellino's": ['AE2 V AH0 L IY1 N OW0 Z'],
  "avemco": ['AH0 V EH1 M K OW0'],
  "avemco's": ['AH0 V EH1 M K OW0 Z'],
  "aven": ['AA0 V EY1 N'],
  "avena": ['AA0 V EY1 N AH0'],
  "avenall": ['AA0 V EY0 N AA1 L'],
  "avendano": ['AA0 V EH0 N D AA1 N OW0'],
  "avenel": ['AA0 V EY0 N EH1 L'],
  "avenell": ['AA0 V EY0 N EY1 L'],
  "avenge": ['AH0 V EH1 N JH'],
  "avenged": ['AH0 V EH1 N JH D'],
  "avenger": ['AH0 V EH1 N JH ER0'],
  "avenging": ['AH0 V EH1 N JH IH0 NG'],
  "aveni": ['AA0 V EH1 N IY0'],
  "avent": ['AA1 V AH0 N T'],
  "aventura": ['AH0 V EH1 N CH ER0 AA2', 'AE2 V AH0 N CH UH1 R AH0'],
  "avenue": ['AE1 V AH0 N UW2'],
  "avenue's": ['AE1 V AH0 N UW2 Z'],
  "avenues": ['AE1 V AH0 N UW2 Z'],
  "aver": ['EY1 V ER0'],
  "avera": ['AA0 V EH1 R AH0'],
  "average": ['AE1 V ER0 IH0 JH', 'AE1 V R AH0 JH', 'AE1 V R IH0 JH'],
  "average's": ['AE1 V R IH0 JH IH0 Z'],
  "averaged": ['AE1 V R AH0 JH D', 'AE1 V R IH0 JH D'],
  "averages": ['AE1 V R IH0 JH IH0 Z'],
  "averaging": ['AE1 V R IH0 JH IH0 NG'],
  "averbeck": ['EY1 V ER0 B EH2 K'],
  "averell": ['AA0 V EH0 R EY1 L'],
  "averett": ['AE1 V ER0 EH0 T'],
  "averette": ['AE1 V ER0 EH2 T'],
  "averil": ['EY1 V ER0 IH0 L', 'EY1 V R IH0 L'],
  "averill": ['AA0 V EH0 R IY1 L'],
  "averitt": ['AE1 V ER0 IH0 T'],
  "averof": ['AE1 V ER0 AA2 F'],
  "avers": ['EY1 V ER0 Z'],
  "aversa": ['AA0 V EH1 R S AH0'],
  "aversano": ['AA0 V ER0 S AA1 N OW0'],
  "averse": ['AH0 V ER1 S'],
  "aversion": ['AH0 V ER1 ZH AH0 N'],
  "aversions": ['AH0 V ER1 ZH AH0 N Z'],
  "aversive": ['AH0 V ER1 S AH0 V'],
  "avert": ['AH0 V ER1 T'],
  "averted": ['AH0 V ER1 T IH0 D'],
  "averting": ['AH0 V ER1 T IH0 NG'],
  "averts": ['AH0 V ER1 T S'],
  "avery": ['EY1 V ER0 IY0'],
  "avery's": ['EY1 V ER0 IY0 Z'],
  "averyl": ['AE1 V ER0 IH0 L'],
  "avey": ['EY1 V IY0'],
  "avi": ['AA1 V IY2', 'EY1 V IY0'],
  "avia": ['AA1 V IY0 AH0'],
  "aviacion": ['EY2 V IY0 EY1 SH AH0 N'],
  "aviall": ['EY1 V IY0 AH0 L'],
  "avian": ['EY1 V IY0 AH0 N'],
  "aviano": ['AA2 V IY0 AA1 N OW0'],
  "aviano's": ['AA2 V IY0 AA1 N OW0 Z'],
  "aviaries": ['EY1 V IY0 EH2 R IY0 Z'],
  "aviary": ['EY1 V IY0 EH2 R IY0'],
  "aviation": ['EY2 V IY0 EY1 SH AH0 N'],
  "aviation's": ['EY2 V IY0 EY1 SH AH0 N Z'],
  "aviator": ['EY1 V IY0 EY2 T ER0'],
  "aviators": ['EY1 V IY0 EY0 T ER0 Z'],
  "aviazione": ['AE2 V IY0 AE2 Z IY0 OW1 N IY0'],
  "avice": ['AA1 V AY0 S'],
  "avid": ['AE1 V AH0 D', 'AE1 V IH0 D'],
  "avidly": ['AE1 V AH0 D L IY0'],
  "avignon": ['AE1 V IH0 N Y AO2 N'],
  "avila": ['AH0 V IH1 L AH0'],
  "aviles": ['AA0 V IY1 L EH0 S'],
  "avilez": ['AA0 V IY1 L EH0 Z'],
  "avilla": ['AH0 V IH1 L AH0'],
  "avina": ['AA0 V IY1 N AH0'],
  "avinger": ['EY1 V IH0 NG ER0'],
  "avionic": ['EY2 V IY0 AA1 N IH0 K'],
  "avionics": ['EY2 V IY0 AA1 N IH0 K S'],
  "avions": ['EY1 V IY0 AH0 N Z'],
  "avis": ['EY1 V IH0 S'],
  "avitabile": ['AA0 V IY0 T AA1 B AH0 L'],
  "avital": ['AH0 V IY1 T AH0 L'],
  "avitia": ['AA0 V IY1 SH AH0'],
  "aviv": ['AA0 V IY1 V'],
  "aviv's": ['AA0 V IY1 V Z'],
  "avmark": ['AE1 V M AA2 R K'],
  "avner": ['AE1 V N ER0'],
  "avnet": ['AE1 V N EH2 T'],
  "avocado": ['AE2 V AH0 K AA1 D OW0'],
  "avocados": ['AE2 V AH0 K AA1 D OW0 Z'],
  "avocation": ['AE2 V AH0 K EY1 SH AH0 N'],
  "avocet": ['AE1 V AH0 S EH2 T'],
  "avodon": ['AE1 V AH0 D AA0 N'],
  "avoid": ['AH0 V OY1 D'],
  "avoidable": ['AH0 V OY1 D AH0 B AH0 L'],
  "avoidance": ['AH0 V OY1 D AH0 N S'],
  "avoided": ['AH0 V OY1 D IH0 D'],
  "avoiding": ['AH0 V OY1 D IH0 NG'],
  "avoids": ['AH0 V OY1 D Z'],
  "avolio": ['AH0 V OW1 L IY0 OW0'],
  "avon": ['EY1 V AA0 N'],
  "avon's": ['AE1 V AH0 N Z'],
  "avondale": ['AE1 V AH0 N D EY2 L'],
  "avow": ['AH0 V AW1'],
  "avowed": ['AH0 V AW1 D'],
  "avowedly": ['AH0 V AW1 AH0 D L IY0'],
  "avraham": ['EY1 V R AH0 HH AE0 M', 'AA1 V R AH0 HH AA0 M'],
  "avram": ['EY1 V R AH0 M'],
  "avrett": ['AE1 V R EH0 T'],
  "avril": ['AE1 V R IH0 L', 'EY1 V R AH0 L'],
  "avrim": ['AA0 V R IY1 M'],
  "avtex": ['AE1 V T EH2 K S'],
  "avtex's": ['AE1 V T EH2 K S IH0 Z'],
  "avtovaz": ['AE1 V T OW2 V AE2 Z'],
  "avuncular": ['AH0 V AH1 NG K Y AH0 L ER0'],
  "aw": ['AO1'],
  "awacs": ['EY1 W AE2 K S'],
  "awad": ['AH0 W AA1 D'],
  "await": ['AH0 W EY1 T'],
  "awaited": ['AH0 W EY1 T IH0 D'],
  "awaiting": ['AH0 W EY1 T IH0 NG'],
  "awaits": ['AH0 W EY1 T S'],
  "awake": ['AH0 W EY1 K'],
  "awaken": ['AH0 W EY1 K AH0 N'],
  "awakened": ['AH0 W EY1 K AH0 N D'],
  "awakening": ['AH0 W EY1 K AH0 N IH0 NG'],
  "awakens": ['AH0 W EY1 K AH0 N Z'],
  "awalt": ['AA1 V AH0 L T'],
  "awan": ['EY1 W AH0 N'],
  "award": ['AH0 W AO1 R D'],
  "awarded": ['AH0 W AO1 R D IH0 D'],
  "awarding": ['AH0 W AO1 R D IH0 NG'],
  "awards": ['AH0 W AO1 R D Z'],
  "aware": ['AH0 W EH1 R'],
  "awareness": ['AH0 W EH1 R N AH0 S'],
  "awash": ['AH0 W AA1 SH'],
  "away": ['AH0 W EY1'],
  "aways": ['EY1 W EY2 Z'],
  "awb": ['AA1 W B', 'EY1 D AH1 B AH0 L Y UW1 B IY1', 'EY1 D AH1 B AH0 Y UW1 B IY1'],
  "awbrey": ['AO1 B R IY0'],
  "awe": ['AA1', 'AO1'],
  "awed": ['AO1 D'],
  "aweida": ['AH0 W EY1 D AH0', 'AH0 W AY1 D AH0'],
  "awesome": ['AA1 S AH0 M', 'AO1 S AH0 M'],
  "awesomely": ['AA1 S AH0 M L IY0', 'AO1 S AH0 M L IY0'],
  "awesomeness": ['AO1 S AH0 M N EH2 S'],
  "awestruck": ['AA1 S T R AH2 K'],
  "awful": ['AA1 F AH0 L', 'AO1 F AH0 L'],
  "awfully": ['AA1 F L IY0', 'AO1 F AH0 L IY0'],
  "awfulness": ['AO1 F AH0 L N AH0 S'],
  "awhile": ['AH0 W AY1 L'],
  "awkward": ['AA1 K W ER0 D', 'AO1 K W ER0 D'],
  "awkwardly": ['AO1 K W ER0 D L IY0'],
  "awkwardness": ['AO1 K W ER0 D N AH0 S'],
  "awning": ['AA1 N IH0 NG'],
  "awoke": ['AH0 W OW1 K'],
  "awol": ['EY1 W AO2 L'],
  "awry": ['ER0 AY1'],
  "awtrey": ['AO1 T R IY0'],
  "ax": ['AE1 K S'],
  "axa": ['AE1 K S AH0'],
  "axa's": ['AE1 K S AH0 Z'],
  "axe": ['AE1 K S'],
  "axed": ['AE1 K S T'],
  "axel": ['AE1 K S AH0 L'],
  "axelrad": ['AE0 K S EH1 L R AH0 D'],
  "axelrod": ['AE1 K S AH0 L R AA2 D'],
  "axelsen": ['AE0 K S EH1 L S AH0 N'],
  "axelson": ['AE1 K S IH0 L S AH0 N'],
  "axes": ['AE1 K S IH2 Z', 'AE1 K S IY2 Z'],
  "axford": ['AE0 K S F AO1 R D'],
  "axial": ['AE1 K S IY0 AH0 L'],
  "axid": ['AE1 K S IH0 D'],
  "axilrod": ['AE1 K S IH0 L R AA2 D'],
  "axiom": ['AE1 K S IY0 AH0 M'],
  "axiomatic": ['AE2 K S IY0 AH0 M AE1 T IH0 K'],
  "axioms": ['AE1 K S IY0 AH0 M Z'],
  "axis": ['AE1 K S AH0 S'],
  "axle": ['AE1 K S AH0 L'],
  "axles": ['AE1 K S AH0 L Z'],
  "axley": ['AE1 K S L IY0'],
  "axline": ['AE1 K S L AY2 N'],
  "axlon": ['AE1 K S L AA0 N'],
  "axon": ['AE1 K S AA2 N'],
  "axons": ['AE1 K S AA2 N Z'],
  "axsom": ['AE1 K S AH0 M'],
  "axt": ['AE1 K S T'],
  "axtell": ['AE0 K S T EH1 L'],
  "axtman": ['AE1 K S T M AH0 N'],
  "axton": ['AE1 K S T AH0 N'],
  "ay": ['EY1', 'AY1'],
  "ayacucho": ['AY2 AH0 K AH1 CH OW0'],
  "ayako": ['AH0 Y AA1 K OW0'],
  "ayala": ['AH0 Y AA1 L AH0'],
  "ayars": ['EY1 ER0 Z'],
  "ayatollah": ['AY2 AH0 T OW1 L AH0'],
  "ayatollah's": ['AY2 AH0 T OW1 L AH0 Z'],
  "ayatollahs": ['AY2 AH0 T AA1 L AA0 Z'],
  "aybar": ['EY1 B ER0'],
  "aycock": ['EY1 K AH0 K'],
  "aydar": ['AY1 D AA2 R', 'EY1 D AA2 R'],
  "aydelott": ['EY1 D IH0 L AA0 T'],
  "aydelotte": ['EY1 D AH0 L AA2 T'],
  "aydin": ['EY1 D IH0 N'],
  "aydt": ['EY1 T'],
  "aye": ['AY1'],
  "ayende": ['AH0 Y EH1 N D IY0', 'AH0 Y EH1 N D EY0'],
  "ayer": ['AY1 ER0', 'EY1 ER0'],
  "ayer's": ['EH1 R Z', 'EY1 R Z'],
  "ayers": ['AY1 ER0 Z', 'EY1 ER0 Z'],
  "ayerst": ['EY1 ER0 S T', 'EH1 R AH0 S T'],
  "ayes": ['AY1 Z'],
  "ayesha": ['AY1 S AA0'],
  "aygen": ['AY1 G AH0 N', 'EY1 G AH0 N'],
  "aykroyd": ['EY1 K R OY2 D', 'AY1 K R OY2 D'],
  "ayler": ['EY1 L ER0'],
  "ayles": ['EY1 L Z'],
  "aylesbury": ['EY1 L Z B ER0 IY0'],
  "aylesbury's": ['EY1 L Z B ER0 IY0 Z'],
  "aylesworth": ['EY1 L Z W ER0 TH', 'EY1 AH0 L Z W ER0 TH'],
  "ayling": ['EY1 L IH0 NG'],
  "aylmer": ['EY1 L M ER0'],
  "aylor": ['EY1 L ER0'],
  "aylsworth": ['EY1 L S W ER0 TH'],
  "aylward": ['EY1 L W ER0 D'],
  "aylwin": ['EY1 L W IH0 N', 'AY1 L W IH2 N'],
  "aylworth": ['EY1 L W ER0 TH'],
  "aymond": ['EY1 M AH0 N D'],
  "ayn": ['EY1 N'],
  "aynes": ['EY1 N Z'],
  "ayo": ['EY1 OW0'],
  "ayodhya": ['AY2 AA1 D Y AH0'],
  "ayon": ['EY0 AO1 N'],
  "ayotte": ['EY0 AO1 T'],
  "ayoub": ['EY1 AW0 B'],
  "ayr": ['EY1 R'],
  "ayre": ['EH1 R'],
  "ayres": ['EH1 R Z'],
  "ayrshire": ['EY1 ER0 SH AY2 ER0', 'EY1 ER0 SH ER0'],
  "ayscue": ['EY1 S K Y UW0'],
  "aytes": ['EY1 T S'],
  "ayuso": ['AY0 UW1 S OW0'],
  "ayyad": ['AY2 AA1 D', 'AY2 AE1 D'],
  "ayyad's": ['AY2 AA1 D Z', 'AY2 AE1 D Z'],
  "ayyash": ['AY1 Y AE2 SH'],
  "azactam": ['AH0 Z AE1 K T AE0 M'],
  "azalea": ['AH0 Z EY1 L Y AH0'],
  "azaleas": ['AH0 Z EY1 L Y AH0 Z'],
  "azalia": ['AA0 Z AA1 L IY0 AH0'],
  "azar": ['AA0 Z AA1 R'],
  "azarow": ['AE1 Z ER0 OW0'],
  "azbell": ['AE1 Z B EH0 L'],
  "azbill": ['AA0 Z B IY1 L'],
  "azcarraga": ['AE0 Z K AA2 R AA1 G AH0'],
  "azcona": ['AE0 Z K OW1 N AH0'],
  "azcuenaga": ['AE0 Z K W EY2 N AA1 G AH0'],
  "azelia": ['AA0 Z EH1 L IY0 AH0'],
  "azerbaijan": ['AA2 Z ER0 B AY0 JH AA1 N', 'AE2 Z ER0 B AY0 JH AA1 N'],
  "azerbaijan's": ['AA2 Z ER0 B AY0 JH AA1 N Z', 'AE2 Z ER0 B AY0 JH AA1 N Z'],
  "azerbaijani": ['AA2 Z ER0 B AY0 JH AA1 N IY0', 'AE2 Z ER0 B AY0 JH AA1 N IY0'],
  "azerbaijanis": ['AA2 Z ER0 B AY0 JH AA1 N IY0 Z', 'AE2 Z ER0 B AY0 JH AA1 N IY0 Z'],
  "azeri": ['AH0 Z EH1 R IY0'],
  "azevedo": ['AA0 Z EY0 V EY1 D OW0'],
  "azhar": ['AH0 Z AA1 R'],
  "azhar's": ['AH0 Z AA1 R Z'],
  "azides": ['AE1 Z AY2 D Z'],
  "azidothymidine": ['AH0 Z IH2 D AH0 TH AY1 M AH0 D IY2 N', 'AH0 Z IH2 D AH0 TH AY1 M AH0 D AY2 N', 'EY0 Z AY2 D AH0 TH AY1 M AH0 D IY2 N', 'EY0 Z AY2 D AH0 TH AY1 M AH0 D AY2 N'],
  "azinger": ['EY1 Z IH0 NG ER0'],
  "aziz": ['AH0 Z IY1 Z'],
  "azmeer": ['AE1 Z M IH2 R'],
  "azoff": ['AE1 Z AO0 F'],
  "azores": ['AH0 Z AO1 R Z'],
  "azpurua": ['AE0 Z P Y UH1 R UW0 AH0'],
  "aztar": ['AE1 Z T ER0'],
  "aztec": ['AE1 Z T EH2 K'],
  "azteca": ['AE2 Z T EH1 K AH0'],
  "aztecs": ['AE1 Z T EH2 K S'],
  "azuka": ['AH0 Z UW1 K AH0'],
  "azura": ['AH0 Z UH1 R AH0'],
  "azure": ['AE1 ZH ER0'],
  "azzara": ['AA0 T S AA1 R AH0'],
  "azzarello": ['AA0 T S AA0 R EH1 L OW0'],
  "azzaro": ['AA0 T S AA1 R OW0'],
  "azzato": ['AH0 Z AA1 T OW0'],
  "azzopardi": ['AA0 T S OW0 P AA1 R D IY0'],
  "b": ['B IY1'],
  "b'gosh": ['B AH0 G AO1 SH'],
  "b'nai": ['B AH0 N EY1'],
  "b'rith": ['B R IH1 TH'],
  "b's": ['B IY1 Z'],
  "b-j": ['B IY1 JH EY1'],
  "b-j's": ['B IY1 JH EY1 Z'],
  "b.": ['B IY1'],
  "b.'s": ['B IY1 Z'],
  "b.c.": ['B IY2 S IY1'],
  "b.s": ['B IY1 Z'],
  "ba": ['B IY2 EY1', 'B AA1'],
  "ba'ath": ['B AA1 TH', 'B AH0 AA1 TH'],
  "ba'athism": ['B AA1 TH IH0 Z M'],
  "ba'athist": ['B AA1 TH IH0 S T'],
  "ba'athists": ['B AA1 TH IH0 S T S'],
  "baa": ['B IY2 EY2 EY1'],
  "baab": ['B AA1 B'],
  "baack": ['B AA1 K'],
  "baade": ['B AA1 D'],
  "baalbek": ['B AA1 L B EH0 K'],
  "baalbeq": ['B AA1 L B EH0 K'],
  "baar": ['B AA1 R'],
  "baars": ['B AA1 R Z'],
  "baas": ['B AA1 Z'],
  "baasch": ['B AA1 SH'],
  "baatz": ['B AA1 T S'],
  "bab": ['B AE1 B'],
  "baba": ['B AH1 B AH0', 'B AA1 B AH0'],
  "baba's": ['B AH1 B AH0 Z', 'B AA1 B AH0 Z'],
  "babangida": ['B AH0 B AA1 NG G IH0 D AH0', 'B AH0 B AE1 NG G IH0 D AH0'],
  "babar": ['B AA2 B AA1 R'],
  "babar's": ['B AA2 B AA1 R Z'],
  "babb": ['B AE1 B'],
  "babbage": ['B AE1 B IH0 JH'],
  "babbage's": ['B AE1 B IH0 JH IH0 Z'],
  "babbington": ['B AE1 B IH0 N G T AH0 N'],
  "babbio": ['B AE1 B IY0 OW0'],
  "babbit": ['B AE1 B IH0 T'],
  "babbit's": ['B AE1 B IH0 T S'],
  "babbitt": ['B AE1 B IH0 T'],
  "babbitt's": ['B AE1 B AH0 T S'],
  "babbitts": ['B AE1 B AH0 T S'],
  "babble": ['B AE1 B AH0 L'],
  "babbled": ['B AE1 B AH0 L D'],
  "babbler": ['B AE1 B L ER0'],
  "babblers": ['B AE1 B L ER0 Z'],
  "babbling": ['B AE1 B AH0 L IH0 NG', 'B AE1 B L IH0 NG'],
  "babbs": ['B AE1 B Z'],
  "babcock": ['B AE1 B K AO0 K'],
  "babe": ['B EY1 B'],
  "babel": ['B AE1 B AH0 L'],
  "baber": ['B EY1 B ER0'],
  "babers": ['B EY1 B ER0 Z'],
  "babes": ['B EY1 B Z'],
  "babette": ['B AH0 B EH1 T'],
  "babiak": ['B AA1 B IY0 AE0 K'],
  "babiarz": ['B AH0 B IY1 ER0 Z'],
  "babic": ['B AA1 B IH0 K'],
  "babich": ['B AE1 B IH0 CH'],
  "babies": ['B EY1 B IY0 Z'],
  "babies'": ['B EY1 B IY0 Z'],
  "babik": ['B AA1 B IH0 K'],
  "babin": ['B AE1 B IH0 N'],
  "babineau": ['B AE1 B IH0 N OW2'],
  "babineaux": ['B AE1 B IH0 N OW2'],
  "babington": ['B AE1 B IH0 NG T AH0 N'],
  "babino": ['B AA0 B IY1 N OW0'],
  "babinski": ['B AH0 B IH1 N S K IY0'],
  "babish": ['B AE1 B IH0 SH'],
  "babita": ['B AA0 B IY1 T AH0'],
  "babka": ['B AE1 B K AH0'],
  "babler": ['B EY1 B AH0 L ER0'],
  "baboon": ['B AH0 B UW1 N'],
  "baboon's": ['B AE0 B UW1 N Z', 'B AH0 B UW1 N Z'],
  "baboons": ['B AE0 B UW1 N Z', 'B AH0 B UW1 N Z'],
  "babs": ['B AE1 B Z'],
  "babson": ['B AE1 B S AH0 N'],
  "babu": ['B AA0 B UW1'],
  "babula": ['B AA0 B UW1 L AH0'],
  "babushka": ['B AH0 B UH1 SH K AH0'],
  "babushkas": ['B AH0 B UH1 SH K AH0 Z'],
  "baby": ['B EY1 B IY0'],
  "baby's": ['B EY1 B IY0 Z'],
  "baby-sit": ['B EY1 B IY0 S IH2 T'],
  "baby-sitter": ['B EY1 B IY0 S IH2 T ER0'],
  "baby-sitters": ['B EY1 B IY0 S IH2 T ER0 Z'],
  "babyak": ['B AE1 B IY0 AE0 K'],
  "babyhood": ['B EY1 B IY0 HH UH2 D'],
  "babyish": ['B EY1 B IY0 IH0 SH'],
  "babylon": ['B AE1 B AH0 L AA2 N'],
  "babylonian": ['B AE2 B AH0 L OW1 N IY0 AH0 N'],
  "babylonians": ['B AE2 B AH0 L OW1 N IY0 AH0 N Z'],
  "babysat": ['B EY1 B IY0 S AE2 T'],
  "babysit": ['B EY1 B IY0 S IH0 T'],
  "babysits": ['B EY1 B IY0 S IH0 T S'],
  "babysitter": ['B EY1 B IY0 S IH2 T ER0'],
  "babysitters": ['B EY1 B IY0 S IH2 T ER0 Z'],
  "babysitting": ['B EY1 B IY0 S IH2 T IH0 NG'],
  "baca": ['B AE1 K AH0'],
  "bacall": ['B AH0 K AO1 L'],
  "bacardi": ['B AH0 K AA1 R D IY0'],
  "baccalaureate": ['B AE2 K AH0 L AO1 R IY0 AH0 T'],
  "baccarat": ['B AA2 K ER0 AA1'],
  "baccari": ['B AA0 K AA1 R IY0'],
  "bacchanal": ['B AH0 K EY1 N AH0 L', 'B AE1 K IH0 N AH0 L'],
  "bacchanalia": ['B AE2 K AH0 N EY1 L Y AH0'],
  "bacchi": ['B AE1 K IY0'],
  "bacchus": ['B AE1 K IH0 S'],
  "bacci": ['B AA1 CH IY0'],
  "baccus": ['B AE1 K AH0 S'],
  "bach": ['B AA1 K'],
  "bacha": ['B AE1 CH AH0'],
  "bachailian": ['B AH0 K EY1 L IY0 AH0 N'],
  "bachand": ['B AE1 CH AH0 N D'],
  "bachar": ['B AA1 K ER0'],
  "bacharach": ['B AE1 K ER0 AE0 K'],
  "bache": ['B AE1 CH', 'B EY1 CH'],
  "bache's": ['B AE1 CH IH0 Z', 'B EY1 CH IH0 Z'],
  "bachelder": ['B AA1 K EH2 L D ER0'],
  "bacheller": ['B AA1 K AH0 L ER0'],
  "bachelor": ['B AE1 CH AH0 L ER0', 'B AE1 CH L ER0'],
  "bachelor's": ['B AE1 CH AH0 L ER0 Z', 'B AE1 CH L ER0 Z'],
  "bachelors": ['B AE1 CH L ER0 Z'],
  "bacher": ['B AA1 K ER0'],
  "bachera": ['B AA1 K ER0 AH0'],
  "bachert": ['B AE1 CH ER0 T'],
  "bachler": ['B AE1 K L ER0'],
  "bachman": ['B AA1 K M AH0 N'],
  "bachmann": ['B AA1 K M AH0 N'],
  "bachmeier": ['B AA1 K M AY0 ER0'],
  "bachner": ['B AA1 K N ER0'],
  "bachrach": ['B AA1 K R AH0 K'],
  "bachtel": ['B AE1 K T AH0 L'],
  "bachtell": ['B AE1 K T AH0 L'],
  "bachus": ['B AE1 CH AH0 S'],
  "bacigalupi": ['B AA0 CH IY0 G AA0 L UW1 P IY0'],
  "bacigalupo": ['B AA0 CH IY0 G AA0 L UW1 P OW0'],
  "bacik": ['B AA1 CH IH0 K'],
  "bacillus": ['B AH0 S IH1 L AH0 S'],
  "bacino": ['B AA0 CH IY1 N OW0'],
  "back": ['B AE1 K'],
  "back's": ['B AE1 K S'],
  "back-up": ['B AE1 K AH2 P'],
  "backache": ['B AE1 K EY2 K'],
  "backaches": ['B AE1 K EY2 K S'],
  "backbite": ['B AE1 K B AY2 T'],
  "backbiting": ['B AE1 K B AY2 T IH0 NG'],
  "backboard": ['B AE1 K B AO2 R D'],
  "backboards": ['B AE1 K B AO2 R D Z'],
  "backbone": ['B AE1 K B OW2 N'],
  "backbones": ['B AE1 K B OW2 N Z'],
  "backbreaking": ['B AE1 K B R EY2 K IH0 NG'],
  "backdate": ['B AE1 K D EY2 T'],
  "backdated": ['B AE1 K D EY2 T IH0 D'],
  "backdates": ['B AE1 K D EY2 T S'],
  "backdating": ['B AE1 K D EY2 T IH0 NG'],
  "backdoor": ['B AE1 K D AO2 R'],
  "backdoors": ['B AE1 K D AO2 R Z'],
  "backdrop": ['B AE1 K D R AA2 P'],
  "backdrops": ['B AE1 K D R AA2 P S'],
  "backe": ['B AE1 K'],
  "backed": ['B AE1 K T'],
  "backend": ['B AE2 K EH1 N D'],
  "backends": ['B AE2 K EH1 N D Z'],
  "backer": ['B AE1 K ER0'],
  "backer's": ['B AE1 K ER0 Z'],
  "backers": ['B AE1 K ER0 Z'],
  "backes": ['B AE1 K S'],
  "backfield": ['B AE1 K F IY2 L D'],
  "backfields": ['B AE1 K F IY2 L D Z'],
  "backfire": ['B AE1 K F AY2 R'],
  "backfired": ['B AE1 K F AY2 ER0 D'],
  "backfires": ['B AE1 K F AY2 ER0 Z'],
  "backfiring": ['B AE1 K F AY2 ER0 IH0 NG'],
  "backgammon": ['B AE1 K G AE2 M AH0 N'],
  "background": ['B AE1 K G R AW2 N D'],
  "backgrounds": ['B AE1 K G R AW2 N D Z'],
  "backhand": ['B AE1 K HH AE2 N D'],
  "backhanded": ['B AE1 K HH AE2 N D IH0 D'],
  "backhaus": ['B AE1 K HH AW2 S'],
  "backhoe": ['B AE1 K HH OW2'],
  "backhoes": ['B AE1 K HH OW2 Z'],
  "backing": ['B AE1 K IH0 NG'],
  "backlash": ['B AE1 K L AE2 SH'],
  "backlashes": ['B AE1 K L AE2 SH AH0 Z'],
  "backless": ['B AE1 K L AH0 S'],
  "backlight": ['B AE1 K L AY2 T'],
  "backlights": ['B AE1 K L AY2 T S'],
  "backlist": ['B AE1 K L IH2 S T'],
  "backlisted": ['B AE1 K L IH2 S T IH0 D'],
  "backlog": ['B AE1 K L AA2 G', 'B AE1 K L AO2 G'],
  "backlogged": ['B AE1 K L AA2 G D'],
  "backlogs": ['B AE1 K L AO2 G Z'],
  "backlund": ['B AE1 K L AH0 N D'],
  "backman": ['B AE1 K M AH0 N'],
  "backpack": ['B AE1 K P AE2 K'],
  "backpacker": ['B AE1 K P AE2 K ER0'],
  "backpacking": ['B AE1 K P AE2 K IH0 NG'],
  "backpacks": ['B AE1 K P AE2 K S'],
  "backpedal": ['B AE1 K P EH2 D AH0 L'],
  "backpedaled": ['B AE1 K P EH2 D AH0 L D'],
  "backpedaling": ['B AE1 K P EH2 D AH0 L IH0 NG', 'B AE1 K P EH2 D L IH0 NG'],
  "backpedals": ['B AE1 K P EH2 D AH0 L Z'],
  "backrest": ['B AE1 K R EH2 S T'],
  "backrests": ['B AE1 K R EH2 S T S'],
  "backroom": ['B AE1 K R UW2 M'],
  "backrooms": ['B AE1 K R UW2 M Z'],
  "backs": ['B AE1 K S'],
  "backsaw": ['B AE1 K S AA2', 'B AE1 K S AO2'],
  "backsaws": ['B AE1 K S AA2 Z', 'B AE1 K S AO2 Z'],
  "backseat": ['B AE0 K S IY1 T'],
  "backseats": ['B AE0 K S IY1 T S'],
  "backset": ['B AE1 K S EH2 T'],
  "backside": ['B AE1 K S AY2 D'],
  "backsides": ['B AE1 K S AY2 D Z'],
  "backslap": ['B AE1 K S L AE2 P'],
  "backslapping": ['B AE1 K S L AE2 P IH0 NG'],
  "backslash": ['B AE1 K S L AE2 SH'],
  "backslashes": ['B AE1 K S L AE2 SH AH0 Z'],
  "backslide": ['B AE1 K S L AY2 D'],
  "backsliding": ['B AE1 K S L AY2 D IH0 NG'],
  "backspin": ['B AE1 K S P IH0 N'],
  "backstage": ['B AE1 K S T EY1 JH'],
  "backstairs": ['B AE1 K S T EH2 R Z'],
  "backstitch": ['B AE1 K S T IH0 CH'],
  "backstitches": ['B AE1 K S T IH0 CH AH0 Z'],
  "backstop": ['B AE1 K S T AA2 P'],
  "backstreet": ['B AE1 K S T IY2 T'],
  "backstreets": ['B AE1 K S T IY2 T S'],
  "backstroke": ['B AE1 K S T R OW2 K'],
  "backstrom": ['B AE1 K S T R AH0 M'],
  "backtrack": ['B AE1 K T R AE2 K'],
  "backtracked": ['B AE1 K T R AE2 K T'],
  "backtracking": ['B AE1 K T R AE2 K IH0 NG'],
  "backtracks": ['B AE1 K T R AE2 K S'],
  "backup": ['B AE1 K AH2 P'],
  "backups": ['B AE1 K AH2 P S'],
  "backus": ['B AE1 K AH0 S'],
  "backward": ['B AE1 K W ER0 D'],
  "backwardation": ['B AE2 K W ER0 D EY1 SH AH0 N'],
  "backwardness": ['B AE1 K W ER0 D N AH0 S'],
  "backwards": ['B AE1 K W ER0 D Z'],
  "backwash": ['B AE1 K W AA2 SH'],
  "backwater": ['B AE1 K W AO2 T ER0'],
  "backwaters": ['B AE1 K W AO2 T ER0 Z'],
  "backwoods": ['B AE1 K W UH1 D Z'],
  "backwoodsman": ['B AE1 K W UH1 D Z M AH0 N'],
  "backyard": ['B AE1 K Y AA2 R D'],
  "backyards": ['B AE1 K Y AA2 R D Z'],
  "bacon": ['B EY1 K AH0 N'],
  "bacon's": ['B EY1 K AH0 N Z'],
  "bacorn": ['B AE1 K ER0 N'],
  "bacot": ['B AE1 K AH0 T', 'B EY1 K AE0 T'],
  "bacote": ['B AH0 K OW1 T'],
  "bacteria": ['B AE0 K T IH1 R IY0 AH0'],
  "bacteria's": ['B AE0 K T IH1 R IY0 AH0 Z'],
  "bacterial": ['B AE0 K T IH1 R IY0 AH0 L'],
  "bacteriology": ['B AE2 K T IH0 R IY2 AA1 L AH0 JH IY0'],
  "bacterium": ['B AE0 K T IH1 R IY0 AH0 M'],
  "bacus": ['B AE1 K IH0 S'],
  "bad": ['B AE1 D'],
  "bad-ass": ['B AE1 D AE2 S'],
  "bada": ['B AA1 D AH0'],
  "badal": ['B EY1 D AH0 L'],
  "badalamenti": ['B AA0 D AA0 L AA0 M EH1 N T IY0'],
  "badami": ['B AA0 D AA1 M IY0'],
  "badass": ['B AE1 D AE2 S'],
  "badavatz": ['B AE1 D AH0 V AE2 T S'],
  "badders": ['B AE1 D ER0 Z'],
  "bade": ['B EY1 D'],
  "badeah": ['B AH0 D IY1 AH0'],
  "badeau": ['B AH0 D OW1'],
  "badeaux": ['B AH0 D OW1'],
  "badelain": ['B AE1 D AH0 L EY2 N'],
  "baden": ['B EY1 D AH0 N'],
  "baden's": ['B AA1 D AH0 N Z'],
  "bader": ['B AE1 D ER0', 'B EY1 D ER0'],
  "badertscher": ['B AE1 D ER0 CH ER0'],
  "badge": ['B AE1 JH'],
  "badger": ['B AE1 JH ER0'],
  "badger's": ['B AE1 JH ER0 Z'],
  "badgered": ['B AE1 JH ER0 D'],
  "badgering": ['B AE1 JH ER0 IH0 NG'],
  "badgers": ['B AE1 JH ER0 Z'],
  "badges": ['B AE1 JH IH0 Z'],
  "badgett": ['B AE1 JH IH0 T'],
  "badgley": ['B AE1 JH L IY0'],
  "badia": ['B AA1 D IY0 AH0'],
  "badillo": ['B AH0 D IH1 L OW0'],
  "badland": ['B AE1 D L AE2 N D'],
  "badlands": ['B AE1 D L AE2 N D Z'],
  "badley": ['B AE1 D L IY0'],
  "badly": ['B AE1 D L IY0'],
  "badman": ['B AE1 D M AH0 N'],
  "badminton": ['B AE1 D M IH2 N T AH0 N'],
  "badmintons": ['B AE1 D M IH2 N T AH0 N Z'],
  "badness": ['B AE1 D N AH0 S'],
  "badolato": ['B AA0 D OW0 L AA1 T OW0'],
  "badour": ['B AH0 D UH1 R'],
  "badu": ['B AH0 D UW1'],
  "badura": ['B AA0 D UH1 R AH0'],
  "bady": ['B EY1 D IY0'],
  "bae": ['B AY1'],
  "baechle": ['B EH1 K AH0 L'],
  "baecker": ['B EH1 K ER0'],
  "baedeker": ['B EY1 D AH0 K ER0'],
  "baedeker's": ['B EY1 D AH0 K ER0 Z'],
  "baehr": ['B IY1 R', 'B EH1 R'],
  "baek": ['B IY1 K', 'B EH1 K'],
  "baer": ['B EH1 R'],
  "baerwald": ['B EH1 R W AO0 L D'],
  "baeten": ['B EH1 T AH0 N'],
  "baetjer": ['B EH1 T JH ER0'],
  "baetz": ['B IY1 T S'],
  "baez": ['B AY0 EH1 Z'],
  "baeza": ['B IY1 Z AH0'],
  "baffa": ['B AE1 F AH0'],
  "baffle": ['B AE1 F AH0 L'],
  "baffled": ['B AE1 F AH0 L D'],
  "baffles": ['B AE1 F AH0 L Z'],
  "baffling": ['B AE1 F AH0 L IH0 NG', 'B AE1 F L IH0 NG'],
  "bafokeng": ['B AE1 F OW0 K EH2 NG'],
  "bag": ['B AE1 G'],
  "bagan": ['B EY1 G AH0 N'],
  "bagby": ['B AE1 G B IY0'],
  "bagdad": ['B AE1 G D AE0 D'],
  "bagdasarian": ['B AE2 G D AH0 S EH1 R IY0 AH0 N'],
  "bagdikian": ['B AE2 G D IH1 K IY0 AH0 N'],
  "bagdon": ['B AE1 G D AH0 N'],
  "bagdonas": ['B AE1 G D AH0 N AH0 Z'],
  "bagel": ['B EY1 G AH0 L'],
  "bageland": ['B EY1 G AH0 L AE0 N D'],
  "bagels": ['B EY1 G AH0 L Z'],
  "bagent": ['B EY1 JH AH0 N T'],
  "bagful": ['B AE1 G F UH2 L'],
  "bagg": ['B AE1 G'],
  "baggage": ['B AE1 G AH0 JH', 'B AE1 G IH0 JH'],
  "bagge": ['B AE1 G'],
  "bagged": ['B AE1 G D'],
  "baggerly": ['B AE1 G ER0 L IY0'],
  "baggett": ['B AE1 G IH0 T'],
  "baggie": ['B AE1 G IY0'],
  "bagginess": ['B AE1 G IY0 N AH0 S'],
  "bagging": ['B AE1 G IH0 NG'],
  "baggio": ['B AA1 JH IY0 OW0'],
  "baggio's": ['B AA1 JH IY0 OW0 Z'],
  "baggott": ['B AE1 G AH0 T'],
  "baggs": ['B AE1 G Z'],
  "baggy": ['B AE1 G IY0'],
  "baghdad": ['B AE1 G D AE0 D'],
  "baghdad's": ['B AE1 G D AE0 D Z'],
  "baghli": ['B AE1 G L IY0'],
  "baginski": ['B AH0 G IH1 N S K IY0'],
  "bagley": ['B AE1 G L IY0'],
  "baglio": ['B AE1 G L IY0 OW0'],
  "bagman": ['B AE1 G M AH0 N'],
  "bagnall": ['B AE1 G N AH0 L'],
  "bagnato": ['B AA0 G N AA1 T OW0'],
  "bagnell": ['B AE1 G N AH0 L'],
  "bagpipe": ['B AE1 G P AY2 P'],
  "bagpiper": ['B AE1 G P AY2 P ER0'],
  "bagpipes": ['B AE1 G P AY2 P S'],
  "bags": ['B AE1 G Z'],
  "bagsby": ['B AE1 G Z B IY0'],
  "bagshaw": ['B AE1 G SH AO2'],
  "baguette": ['B AE2 G EH1 T'],
  "bagwell": ['B AE1 G W EH2 L'],
  "bah": ['B AA1'],
  "baha'is": ['B AA1 HH AA0 IH2 Z'],
  "baham": ['B AA1 AH0 M'],
  "bahama": ['B AH0 HH AA1 M AH0'],
  "bahamas": ['B AH0 HH AA1 M AH0 Z'],
  "bahamian": ['B AH0 HH EY1 M IY0 AH0 N'],
  "bahan": ['B AE1 HH AH0 N'],
  "bahe": ['B EY1 HH'],
  "bahena": ['B AE1 HH IH0 N AH0'],
  "bahia": ['B AA1 HH IY0 AH0'],
  "bahir": ['B AH0 HH IH1 R'],
  "bahl": ['B AA1 L'],
  "bahler": ['B AA1 L ER0'],
  "bahls": ['B AA1 L Z'],
  "bahm": ['B AE1 M'],
  "bahman": ['B AA1 M AH0 N'],
  "bahn": ['B AE1 N'],
  "bahner": ['B AA1 N ER0'],
  "bahnhof": ['B AA2 N HH AO1 F'],
  "bahnsen": ['B AA1 N S AH0 N'],
  "bahr": ['B EH1 R', 'B AA1 R'],
  "bahrain": ['B AA0 R EY1 N', 'B AY0 R EY1 N'],
  "bahrain's": ['B AA0 R EY1 N Z', 'B AY0 R EY1 N Z'],
  "bahraini": ['B ER0 EY1 N IY0', 'B AY0 R AA1 N IY0', 'B AA0 R EY1 N IY0'],
  "bahrainian": ['B ER0 EY1 N IY0 AH0 N', 'B AY0 R AA1 N IY0 AH0 N', 'B AA0 R EY1 N IY0 AH0 N'],
  "bahrainis": ['B ER0 EY1 N IY0 Z'],
  "bahre": ['B AA1 R'],
  "bahrenburg": ['B AA1 R AH0 N B ER0 G'],
  "baht": ['B AA1 T'],
  "baidoa": ['B AY2 D OW1 AH0'],
  "baidoa's": ['B AY2 D OW1 AH0 Z'],
  "baidoan": ['B AY2 D OW1 AH0 N'],
  "baidoan's": ['B AY2 D OW1 AH0 N Z'],
  "baidoans": ['B AY2 D OW1 AH0 N Z'],
  "baidoas": ['B AY2 D OW1 AH0 Z'],
  "baie": ['B AY1 IY0'],
  "baier": ['B EY1 ER0'],
  "baig": ['B EY1 G'],
  "baikonur": ['B AY1 K AH0 N AO2 R'],
  "bail": ['B EY1 L'],
  "baila": ['B AY1 L AH0'],
  "bailard": ['B EY1 L ER0 D'],
  "bailby": ['B EY1 L B IY0'],
  "baile": ['B EY1 L'],
  "bailed": ['B EY1 L D'],
  "bailer": ['B EY1 L ER0'],
  "bailes": ['B EY1 L Z'],
  "bailey": ['B EY1 L IY0'],
  "bailey's": ['B EY1 L IY0 Z'],
  "baileys": ['B EY1 L IY0 Z'],
  "bailie": ['B EY1 L IY0'],
  "bailiff": ['B EY1 L AH0 F', 'B EY1 L IH0 F'],
  "bailiffs": ['B EY1 L AH0 F S'],
  "bailin": ['B EY1 L IH0 N'],
  "bailing": ['B EY1 L IH0 NG'],
  "bailiwick": ['B EY1 L IH0 W IH2 K'],
  "baillargeon": ['B AH0 L AA1 R JH IH0 N'],
  "baillie": ['B EY1 L IY0'],
  "bailly": ['B EY1 L IY0'],
  "bailon": ['B EY1 L AH0 N'],
  "bailor": ['B EY1 L ER0'],
  "bailout": ['B EY1 L AW2 T'],
  "bailouts": ['B EY1 L AW2 T S'],
  "bails": ['B EY1 L Z'],
  "baily": ['B EY1 L IY0'],
  "bailyn": ['B EY1 L IH0 N'],
  "baim": ['B EY1 M'],
  "bain": ['B EY1 N'],
  "bain's": ['B EY1 N Z'],
  "bainbridge": ['B EY1 N B R IH0 JH'],
  "bainbridge's": ['B EY1 N B R IH0 JH AH0 Z'],
  "baine": ['B EY1 N'],
  "bainerd": ['B EY1 N ER0 D'],
  "baines": ['B EY1 N Z'],
  "bains": ['B EY1 N Z'],
  "bainter": ['B EY1 N T ER0'],
  "bainton": ['B EY1 N T AH0 N'],
  "baio": ['B AA1 IY0 OW0'],
  "bair": ['B EH1 R'],
  "baird": ['B EH1 R D'],
  "baird's": ['B EH1 R D Z'],
  "bairnco": ['B EH1 R N K OW0'],
  "baisch": ['B AY1 SH'],
  "baisden": ['B EY1 S D AH0 N'],
  "baise": ['B EY1 Z'],
  "baisley": ['B EY1 Z L IY0'],
  "bait": ['B EY1 T'],
  "baited": ['B EY1 T IH0 D'],
  "baiting": ['B EY1 T IH0 NG'],
  "baits": ['B EY1 T S'],
  "baity": ['B EY1 T IY0'],
  "baiul": ['B AY2 UW1 L'],
  "baiul's": ['B AY2 UW1 L Z'],
  "baize": ['B EY1 Z'],
  "baja": ['B AA1 HH AA2'],
  "bajarin": ['B AE1 JH ER0 IH0 N'],
  "bajema": ['B AA0 Y EY1 M AH0'],
  "bajorek": ['B AE1 JH ER0 EH0 K'],
  "bak": ['B AE1 K'],
  "baka": ['B AA1 K AH0'],
  "bakalar": ['B AE1 K AH0 L ER0'],
  "bake": ['B EY1 K'],
  "bake-off": ['B EY1 K AO1 F'],
  "bake-offs": ['B EY1 K AO1 F S'],
  "baked": ['B EY1 K T'],
  "bakelman": ['B EY1 K AH0 L M AH0 N'],
  "bakeman": ['B EY1 K M AH0 N'],
  "baker": ['B EY1 K ER0'],
  "baker's": ['B EY1 K ER0 Z'],
  "bakeries": ['B EY1 K ER0 IY0 Z'],
  "bakers": ['B EY1 K ER0 Z'],
  "bakersfield": ['B EY1 K ER0 Z F IY2 L D'],
  "bakery": ['B EY1 K ER0 IY0'],
  "bakes": ['B EY1 K S'],
  "bakeware": ['B EY1 K W EH2 R'],
  "bakewell": ['B EY1 K W EH2 L'],
  "bakey": ['B EY1 K IY0'],
  "bakey's": ['B EY1 K IY0 Z'],
  "baking": ['B EY1 K IH0 NG'],
  "bakke": ['B AE1 K'],
  "bakken": ['B AE1 K AH0 N'],
  "bakker": ['B AE1 K ER0', 'B EY1 K ER0'],
  "bakker's": ['B AE1 K ER0 Z', 'B EY1 K ER0 Z'],
  "bakkers": ['B AE1 K ER0 Z', 'B EY1 K ER0 Z'],
  "bakley": ['B AE1 K L IY0'],
  "bakos": ['B AA1 K OW0 Z'],
  "bakowski": ['B AH0 K AO1 F S K IY0'],
  "bakrewali": ['B AE0 K R AH0 W AA1 L IY0'],
  "bakrie": ['B AE1 K R AY0'],
  "bakshi": ['B AE1 K SH IY0'],
  "bakst": ['B AE1 K S T'],
  "baku": ['B AA0 K UW1'],
  "bakula": ['B AH0 K UW1 L AH0'],
  "bal": ['B AE1 L'],
  "bala": ['B AA1 L AH0'],
  "balaban": ['B AA0 L AA0 B AA1 N'],
  "balaclava": ['B AA2 L AA0 K L AA1 V AA0'],
  "balaclavas": ['B AA2 L AA0 K L AA1 V AA0 Z'],
  "balaguer": ['B AE1 L AH0 G ER0'],
  "balak": ['B AA2 L AH0 K'],
  "balakumar": ['B AA2 L AH0 K UW0 M AA1 R'],
  "balan": ['B EY1 L AH0 N'],
  "balance": ['B AE1 L AH0 N S'],
  "balanced": ['B AE1 L AH0 N S T'],
  "balancer": ['B AE1 L AH0 N S ER0'],
  "balances": ['B AE1 L AH0 N S AH0 Z', 'B AE1 L AH0 N S IH0 Z'],
  "balanchine": ['B AE1 L AH0 N CH IY2 N'],
  "balanchine's": ['B AE1 L AH0 N CH IY2 N Z'],
  "balancing": ['B AE1 L AH0 N S IH0 NG'],
  "balart": ['B AE1 L AA0 R T'],
  "balas": ['B AE1 L AH0 S'],
  "balash": ['B AE1 L AH0 SH'],
  "balata": ['B AE1 L AH0 T AH0'],
  "balazs": ['B AO1 L AH0 SH'],
  "balbach": ['B AE1 L B AA0 K'],
  "balbi": ['B AE1 L B IY0'],
  "balbina": ['B AA0 L B IY1 N AH0'],
  "balbo": ['B AE1 L B OW0'],
  "balboa": ['B AA0 L B OW1 AH0'],
  "balboni": ['B AA0 L B OW1 N IY0'],
  "balbriggan": ['B AE0 L B R IH1 G AH0 N'],
  "balbriggans": ['B AE0 L B R IH1 G AH0 N Z'],
  "balcer": ['B EY1 L S ER0'],
  "balcerowicz": ['B AE0 L S EH1 R AH0 W IH0 T S'],
  "balcerzak": ['B AH0 L CH ER1 Z AH0 K'],
  "balch": ['B AE1 L CH'],
  "balchunas": ['B AE1 L K UW0 N AH0 Z'],
  "balcom": ['B AE1 L K AH0 M'],
  "balconies": ['B AE1 L K AH0 N IY0 Z'],
  "balcony": ['B AE1 L K AH0 N IY0'],
  "balcor": ['B AE1 L K AO0 R'],
  "bald": ['B AO1 L D'],
  "baldassare": ['B AA0 L D AA0 S AA1 R IY0'],
  "baldassari": ['B AA0 L D AA0 S AA1 R IY0'],
  "baldassarre": ['B AO0 L D AH0 S AA1 R IY0'],
  "baldauf": ['B AE1 L D AW0 F'],
  "baldelli": ['B AA0 L D EH1 L IY0'],
  "baldemar": ['B AA0 L D EY0 M AA1 R'],
  "balder": ['B AO1 L D ER0'],
  "balderas": ['B AE1 L D ER0 AH0 Z'],
  "balderdash": ['B AO1 L D ER0 D AE2 SH'],
  "balderrama": ['B AA0 L D EH0 R AA1 M AH0'],
  "balderson": ['B AE1 L D ER0 S AH0 N'],
  "balderston": ['B AE1 L D ER0 S T AH0 N'],
  "baldev": ['B AA1 L D EH2 V'],
  "baldez": ['B AA2 L D EH1 Z'],
  "baldi": ['B AA1 L D IY0'],
  "balding": ['B AO1 L D IH0 NG'],
  "baldinger": ['B AO1 L D IH0 NG ER0'],
  "baldini": ['B AA0 L D IY1 N IY0'],
  "baldini's": ['B AA0 L D IY1 N IY0 Z'],
  "baldino": ['B AA0 L D IY1 N OW0'],
  "baldly": ['B AO1 L D L IY0'],
  "baldner": ['B AE1 L D N ER0'],
  "baldness": ['B AO1 L D N AH0 S'],
  "baldo": ['B AA1 L D OW0'],
  "baldock": ['B AE1 L D AH0 K'],
  "baldonado": ['B AA0 L D OW0 N AA1 D OW0'],
  "baldoni": ['B AA0 L D OW1 N IY0'],
  "baldor": ['B AO1 L D ER0'],
  "baldree": ['B AH0 L D R IY1'],
  "baldric": ['B AE1 L D R IH0 K'],
  "baldridge": ['B AE1 L D R IH0 JH'],
  "baldrige": ['B AO1 L D R IH0 JH'],
  "baldry": ['B AO1 L D R IY0'],
  "balducci": ['B AA0 L D UW1 CH IY0'],
  "baldur": ['B AA0 L D UH1 R'],
  "baldus": ['B AA1 L D IH0 S'],
  "baldwin": ['B AO1 L D W AH0 N', 'B AO1 L D W IH0 N'],
  "baldwin's": ['B AO1 L D W AH0 N Z'],
  "baldyga": ['B AA1 L D IY0 G AH0'],
  "bale": ['B EY1 L'],
  "baleen": ['B AH0 L IY1 N'],
  "baleful": ['B EY1 L F AH0 L'],
  "balentine": ['B AA0 L EH0 N T IY1 N IY0'],
  "baler": ['B EY1 L ER0'],
  "bales": ['B EY1 L Z'],
  "balestra": ['B AH0 L EH1 S T R AH0'],
  "balestrieri": ['B AA0 L EH0 S T R IH1 R IY0'],
  "baley": ['B EY1 L IY0'],
  "balfanz": ['B AA1 L F AA0 N Z'],
  "balfe": ['B EY1 L F'],
  "balfour": ['B AE1 L F AO0 R'],
  "bali": ['B AA1 L IY0'],
  "balian": ['B EY1 L IY0 AH0 N'],
  "balicki": ['B AH0 L IH1 K IY0'],
  "balik": ['B AA1 L IH0 K'],
  "balilan": ['B AH0 L IH1 L AH0 N'],
  "baliles": ['B AH0 L IY1 L EH0 Z'],
  "balin": ['B AE1 L IH0 N'],
  "balinese": ['B AE2 L IH0 N IY1 Z'],
  "baling": ['B EY1 L IH0 NG'],
  "balinski": ['B AH0 L IH1 N S K IY0'],
  "balint": ['B AA1 L IY0 N T'],
  "balis": ['B AA1 L IY0 Z'],
  "balistreri": ['B AA0 L IY0 S T R EH1 R IY0'],
  "balk": ['B AO1 K', 'B AA1 L K'],
  "balkan": ['B AO1 L K AH0 N'],
  "balkanization": ['B AO2 L K AH0 N IH0 Z EY1 SH AH0 N'],
  "balkanize": ['B AO1 L K AH0 N AY2 Z'],
  "balkanized": ['B AO1 L K AH0 N AY2 Z D'],
  "balkans": ['B AO1 L K AH0 N Z'],
  "balkcom": ['B AE1 L K AH0 M'],
  "balke": ['B EY1 L K'],
  "balked": ['B AO1 K T'],
  "balkin": ['B AE1 L K IH0 N'],
  "balking": ['B AO1 K IH0 NG'],
  "balko": ['B AE1 L K OW0'],
  "balks": ['B AO1 K S'],
  "balky": ['B AO1 K IY0'],
  "ball": ['B AO1 L'],
  "ball's": ['B AO1 L Z'],
  "balla": ['B AE1 L AH0'],
  "ballad": ['B AE1 L AH0 D'],
  "ballade": ['B AH0 L AA1 D'],
  "ballads": ['B AE1 L AH0 D Z'],
  "balladur": ['B AE1 L AH0 D ER0'],
  "balladur's": ['B AE1 L AH0 D ER0 Z'],
  "ballance": ['B AA1 L AH0 N S'],
  "ballantine": ['B AE1 L AH0 N T AY2 N'],
  "ballantrae": ['B AE1 L AH0 N T R EY2'],
  "ballantyne": ['B AH0 L AE1 N T AY0 N'],
  "ballard": ['B AE1 L ER0 D'],
  "ballard's": ['B AE1 L ER0 D Z'],
  "ballas": ['B AE1 L AH0 Z'],
  "ballast": ['B AE1 L AH0 S T'],
  "ballasts": ['B AE1 L AH0 S T S', 'B AE1 L AH0 S'],
  "ballclub": ['B AO1 L K L AH0 B'],
  "ballcock": ['B AO1 L K AA1 K'],
  "ballcocks": ['B AO1 L K AA1 K S'],
  "balle": ['B EY1 L'],
  "balled": ['B AO1 L D'],
  "ballengee": ['B AH0 L EH1 NG G IY0'],
  "ballenger": ['B AE1 L IH0 N JH ER0'],
  "ballentine": ['B AA0 L EH0 N T IY1 N IY0'],
  "baller": ['B AO1 L ER0'],
  "ballerina": ['B AE2 L ER0 IY1 N AH0'],
  "ballerinas": ['B AE2 L ER0 IY1 N AH0 Z'],
  "balles": ['B EY1 L Z'],
  "ballester": ['B AE1 L IH0 S T ER0'],
  "ballesteros": ['B AE1 L IH0 S T ER0 OW0 Z', 'B AH0 L EH1 S T ER0 OW0 S'],
  "ballet": ['B AE0 L EY1'],
  "ballet's": ['B AE0 L EY1 Z'],
  "ballets": ['B AE0 L EY1 Z'],
  "balletto": ['B AE2 L EH1 T OW0'],
  "ballew": ['B AE1 L UW0'],
  "ballgame": ['B AO0 L G EY1 M'],
  "balli": ['B AE1 L IY0'],
  "balliet": ['B AE1 L IY0 IH0 T'],
  "balliett": ['B AE1 L IY2 T'],
  "ballin": ['B AE1 L IH0 N'],
  "balling": ['B AO1 L IH0 NG'],
  "ballinger": ['B AO1 L IH2 N JH ER0'],
  "ballistic": ['B AH0 L IH1 S T IH0 K'],
  "ballistics": ['B AH0 L IH1 S T IH0 K S'],
  "balliwick": ['B AE1 L AH0 W IH2 K'],
  "ballman": ['B AO1 L M AH0 N'],
  "ballmer": ['B AE1 L M ER0'],
  "ballo": ['B AE1 L OW0'],
  "ballon": ['B AE1 L AH0 N'],
  "balloon": ['B AH0 L UW1 N'],
  "ballooned": ['B AH0 L UW1 N D'],
  "ballooning": ['B AH0 L UW1 N IH0 NG'],
  "balloonist": ['B AH0 L UW1 N IH0 S T'],
  "balloonists": ['B AH0 L UW1 N IH0 S T S'],
  "balloons": ['B AH0 L UW1 N Z'],
  "ballor": ['B AO1 L ER0'],
  "ballot": ['B AE1 L AH0 T'],
  "balloting": ['B AE1 L AH0 T IH0 NG'],
  "ballots": ['B AE1 L AH0 T S'],
  "ballou": ['B AH0 L UW1'],
  "ballow": ['B AE1 L OW0'],
  "ballowe": ['B AE1 L AW0'],
  "ballpark": ['B AO1 L P AA2 R K'],
  "ballparks": ['B AO1 L P AA2 R K S'],
  "ballplayer": ['B AO1 L P L EY2 ER0'],
  "ballplayers": ['B AO0 L P L EY1 ER0 Z'],
  "ballpoint": ['B AO1 L P OY0 N T'],
  "ballpoints": ['B AO1 L P OY0 N T S'],
  "ballroom": ['B AO1 L R UW2 M'],
  "ballrooms": ['B AO1 L R UW2 M Z'],
  "balls": ['B AO1 L Z'],
  "ballweg": ['B AE1 L W IH0 G'],
  "ballwig": ['B AA1 L W IH0 G'],
  "ballwig's": ['B AA1 L W IH0 G Z'],
  "bally": ['B AE1 L IY0', 'B EY1 L IY0'],
  "bally's": ['B AE1 L IY0 Z', 'B EY1 L IY0 Z'],
  "ballyhoo": ['B AE1 L IY0 HH UW1'],
  "ballyhooed": ['B AE1 L IY0 HH UW1 D'],
  "balm": ['B AA1 M', 'B AA1 L M'],
  "balmer": ['B AA1 M ER0'],
  "balmes": ['B AA1 L M EH0 S'],
  "balmoral": ['B AE0 L M AO1 R AH0 L'],
  "balmorals": ['B AE0 L M AO1 R AH0 L Z'],
  "balms": ['B AA1 M Z', 'B AA1 L M Z'],
  "balmy": ['B AA1 M IY0'],
  "balodia": ['B AH0 L OW1 D IY0 AH0'],
  "balog": ['B AE1 L AO0 G'],
  "baloga": ['B AA0 L OW1 G AH0'],
  "balogh": ['B AE1 L OW0'],
  "balon": ['B AA0 L AO1 N'],
  "baloney": ['B AH0 L OW1 N IY0'],
  "balow": ['B AE1 L OW0'],
  "balsa": ['B AO1 L S AH0'],
  "balsam": ['B AO1 L S AH0 M'],
  "balsamic": ['B AA2 L S AA1 M IH0 K'],
  "balsamo": ['B AA0 L S AA1 M OW0'],
  "balsbaugh": ['B AO1 L Z B AO2'],
  "balser": ['B EY1 L S ER0'],
  "balsiger": ['B AE1 L S IH0 G ER0'],
  "balsley": ['B AE1 L S L IY0'],
  "balster": ['B AE1 L S T ER0'],
  "balt's": ['B AO1 L T S'],
  "baltazar": ['B AA0 L T AA0 Z AA1 R'],
  "balter": ['B AO1 L T ER0'],
  "baltes": ['B EY1 L T S'],
  "balthasar": ['B AE1 L TH AH0 S ER0'],
  "balthaser": ['B AE1 L TH AH0 S ER0'],
  "balthazar": ['B AE1 L TH AH0 Z ER0'],
  "balthazor": ['B AA0 L TH AA0 Z AO1 R'],
  "balthrop": ['B AE1 L TH R AH0 P'],
  "baltic": ['B AO1 L T IH0 K'],
  "baltica": ['B AE1 L T IH0 K AH0'],
  "baltics": ['B AO1 L T IH0 K S'],
  "baltierra": ['B AA0 L T IH1 R AH0'],
  "baltimore": ['B AO1 L T AH0 M AO2 R'],
  "baltimore's": ['B AO1 L T AH0 M AO2 R Z'],
  "balto": ['B AA1 L T OW0'],
  "baltodano": ['B AO2 L T OW0 D AA1 N OW0'],
  "baltsa": ['B AO1 L T S AH0'],
  "baltz": ['B AE1 L T S'],
  "baltzell": ['B AE1 L T Z AH0 L'],
  "baltzer": ['B AE1 L T Z ER0'],
  "baluchi": ['B AH0 L UW1 CH IY0'],
  "baluja": ['B AH0 L UW1 JH AH0'],
  "balukas": ['B AH0 L UW1 K AH0 Z'],
  "balyeat": ['B AE2 L IY0 AE1 T'],
  "balz": ['B AO1 L Z'],
  "balzac": ['B AA0 L Z AE1 K'],
  "balzano": ['B AA0 L Z AA1 N OW0'],
  "balzarini": ['B AA0 L Z AA0 R IY1 N IY0'],
  "balzer": ['B EY1 L Z ER0'],
  "bam": ['B AE1 M'],
  "bamako": ['B AE1 M AH0 K OW0'],
  "bamba": ['B AE1 M B AH0'],
  "bambach": ['B AE1 M B AA2 K'],
  "bambenek": ['B AE0 M B EH1 N EH0 K'],
  "bamber": ['B AE1 M B ER0'],
  "bamberg": ['B AE1 M B ER0 G'],
  "bamberger": ['B AE1 M B ER0 G ER0'],
  "bambi": ['B AE1 M B IY0'],
  "bambino": ['B AE2 M B IY1 N OW0'],
  "bambinos": ['B AE2 M B IY1 N OW0 Z'],
  "bamboo": ['B AE0 M B UW1'],
  "bambrick": ['B AE1 M B R IH0 K'],
  "bamburg": ['B AE1 M B ER0 G'],
  "bame": ['B EY1 M'],
  "bamford": ['B AE1 M F ER0 D'],
  "bamut": ['B AE1 M AH0 T'],
  "ban": ['B AE1 N'],
  "ban's": ['B AE1 N Z'],
  "banacci": ['B AH0 N AA1 CH IY0'],
  "banach": ['B AE1 N AH0 K'],
  "banal": ['B AH0 N AA1 L'],
  "banalities": ['B AH0 N AE1 L IH0 T IY0 Z'],
  "banality": ['B AH0 N AE1 L IH0 T IY0'],
  "banamex": ['B AE1 N AH0 M EH2 K S'],
  "banana": ['B AH0 N AE1 N AH0'],
  "bananas": ['B AH0 N AE1 N AH0 Z'],
  "banas": ['B AE1 N AH0 Z'],
  "banasiak": ['B AH0 N AA1 S IY0 AE0 K'],
  "banaszak": ['B AH0 N AA1 SH AH0 K'],
  "banbury": ['B AE1 N B EH2 R IY0'],
  "banc": ['B AE1 NG K'],
  "banca": ['B AE1 NG K AH0', 'B AA1 NG K AH0'],
  "bancaire": ['B AE0 N K EH1 R'],
  "bancario": ['B AE0 N K EH1 R IY0 OW0'],
  "banco": ['B AE1 NG K OW0'],
  "bancoklahoma": ['B AE0 NG K AA2 K L AH0 HH OW1 M AH0'],
  "bancomer": ['B AE1 NG K AH0 M ER0'],
  "bancor": ['B AE1 N K AO2 R'],
  "bancorp": ['B AE1 NG K AO0 R P', 'B AE1 N K AO0 R P'],
  "bancorp's": ['B AE1 NG K AO0 R P S', 'B AE1 N K AO0 R P S'],
  "bancorporation": ['B AE1 N K AO2 R P ER0 EY0 SH AH0 N'],
  "bancroft": ['B AE1 NG K R AO0 F T'],
  "bancroft's": ['B AE1 N K R AO2 F T S'],
  "bancserve": ['B AE1 N K S ER0 V'],
  "bancshares": ['B AE1 NG K SH EH0 R Z'],
  "bancshares'": ['B AE0 NG K SH EH1 R Z'],
  "banctec": ['B AE1 NG K T EH2 K'],
  "banctexas": ['B AE0 NG K T EH1 K S AH0 S'],
  "band": ['B AE1 N D'],
  "band's": ['B AE1 N D Z'],
  "banda": ['B AE1 N D AH0'],
  "bandag": ['B AE1 N D AE2 G'],
  "bandage": ['B AE1 N D IH0 JH'],
  "bandaged": ['B AE1 N D AH0 JH D'],
  "bandages": ['B AE1 N D AH0 JH AH0 Z', 'B AE1 N D IH0 JH IH0 Z'],
  "bandai": ['B AE2 N D AY1'],
  "bandaid": ['B AE1 N D EY0 D'],
  "bandana": ['B AE2 N D AE1 N AH0'],
  "bandanas": ['B AE2 N D AE1 N AH0 Z'],
  "bandar": ['B AE1 N D AA0 R'],
  "banded": ['B AE1 N D IH0 D'],
  "bandel": ['B AE1 N D AH0 L'],
  "bandemer": ['B AE1 N D IY0 M ER0'],
  "bander": ['B AE1 N D ER0'],
  "banderas": ['B AE0 N D ER1 AH0 S', 'B AA0 N D ER0 AH1 S'],
  "bandicoot": ['B AE1 N D IH0 K UW2 T', 'B AE1 N D IY0 K UW2 T'],
  "bandicoots": ['B AE1 N D IH0 K UW2 T S', 'B AE1 N D IY0 K UW2 T S'],
  "bandied": ['B AE1 N D IY0 D'],
  "banding": ['B AE1 N D IH0 NG'],
  "bandit": ['B AE1 N D AH0 T'],
  "banditry": ['B AE1 N D AH0 T R IY0'],
  "bandits": ['B AE1 N D AH0 T S'],
  "bandleader": ['B AE1 N D L IY0 D ER0'],
  "bandleaders": ['B AE1 N D L IY0 D ER0 Z'],
  "bandolier": ['B AE2 N D AH0 L IH1 R'],
  "bandoliers": ['B AE2 N D AH0 L IH1 R Z'],
  "bandow": ['B AE1 N D AW2'],
  "bandow's": ['B AE1 N D AW2 Z'],
  "bands": ['B AE1 N D Z'],
  "bandshell": ['B AE0 N D SH EH1 L'],
  "bandstand": ['B AE1 N D S T AE2 N D'],
  "bandt": ['B AE1 N T'],
  "banducci": ['B AA0 N D UW1 CH IY0'],
  "bandwagon": ['B AE1 N D W AE2 G AH0 N'],
  "bandwidth": ['B AE1 N D W IH0 D TH'],
  "bandy": ['B AE1 N D IY0'],
  "bane": ['B EY1 N'],
  "banegas": ['B AE1 N IH0 G AH0 Z'],
  "banerjee": ['B AH0 N ER1 JH IY0'],
  "banes": ['B EY1 N Z'],
  "banesto": ['B AH0 N EH1 S T OW0'],
  "banesto's": ['B AH0 N EH1 S T OW0 Z'],
  "banet": ['B AE1 N IH0 T'],
  "baney": ['B EY1 N IY0'],
  "banff": ['B AE1 N F'],
  "banfield": ['B AE1 N F IY2 L D'],
  "banford": ['B AE1 N F ER0 D'],
  "bang": ['B AE1 NG'],
  "bangala": ['B AA0 N G AA1 L AH0'],
  "bangalor": ['B AE1 NG G AH0 L AO2 R'],
  "bangalore": ['B AE1 NG G AH0 L AO2 R'],
  "bangalore's": ['B AE1 NG G AH0 L AO2 R Z'],
  "bange": ['B AE1 N JH'],
  "banged": ['B AE1 NG D'],
  "bangee": ['B AE1 N JH IY0'],
  "bangemann": ['B AE1 NG G AH0 M AH0 N'],
  "banger": ['B AE1 NG ER0'],
  "bangers": ['B AE1 NG ER0 Z'],
  "bangert": ['B EY1 NG G ER0 T'],
  "bangerter": ['B EY1 NG G ER0 T ER0'],
  "banghart": ['B AE1 NG HH AA2 R T'],
  "banging": ['B AE1 NG IH0 NG'],
  "bangish": ['B AE1 NG IH0 SH'],
  "bangkok": ['B AE0 NG K AA1 K', 'B AE1 NG K AA0 K'],
  "bangkok's": ['B AE1 NG K AA0 K S'],
  "bangla": ['B AE1 NG L AA2'],
  "bangladesh": ['B AE1 NG L AH0 D EH2 SH'],
  "bangladesh's": ['B AE1 NG L AH0 D EH2 SH IH0 Z'],
  "bangladeshi": ['B AE1 NG L AH0 D EH2 SH IY0'],
  "bangladeshi's": ['B AE1 NG L AH0 D EH2 SH IY0 Z'],
  "bangladeshis": ['B AE1 NG L AH0 D EH2 SH IY0 Z'],
  "bangle": ['B AE1 NG G AH0 L'],
  "bangles": ['B AE1 NG G AH0 L Z'],
  "bango": ['B AA1 NG G OW0'],
  "bangor": ['B AE1 NG G ER0', 'B AE1 NG G AO2 R'],
  "bangs": ['B AE1 NG Z'],
  "banh": ['B AE1 N'],
  "bania": ['B AA1 N IY0 AH0'],
  "banick": ['B AE1 N IH0 K'],
  "banik": ['B AE1 N IH0 K'],
  "banis": ['B AE1 N IH0 S'],
  "banish": ['B AE1 N IH0 SH'],
  "banished": ['B AE1 N IH0 SH T'],
  "banishing": ['B AE1 N IH0 SH IH0 NG'],
  "banishment": ['B AE1 N IH0 SH M AH0 N T'],
  "banister": ['B AE1 N IH0 S T ER0'],
  "banja": ['B AA1 N Y AH0'],
  "banjo": ['B AE1 N JH OW2'],
  "bank": ['B AE1 NG K'],
  "bank's": ['B AE1 NG K S'],
  "bankable": ['B AE1 NG K AH0 B AH0 L'],
  "bankamerica": ['B AE2 NG K AH0 M EH1 R IH0 K AH0'],
  "bankamerica's": ['B AE2 NG K AH0 M EH1 R IH0 K AH0 Z'],
  "bankatlantic": ['B AE2 NG K AH0 T L AE1 N T IH0 K'],
  "bankbook": ['B AE1 NG K B UH2 K'],
  "bankcard": ['B AE1 NG K AA2 R D'],
  "bankcards": ['B AE1 NG K AA2 R D Z'],
  "bankcorp": ['B AE1 NG K AO2 R P'],
  "banke": ['B AE1 NG K'],
  "bankeast": ['B AE2 NG K IY1 S T'],
  "banked": ['B AE1 NG K T'],
  "banken": ['B AE1 NG K AH0 N'],
  "banker": ['B AE1 NG K ER0'],
  "banker's": ['B AE1 NG K ER0 Z'],
  "bankers": ['B AE1 NG K ER0 Z'],
  "bankers'": ['B AE1 NG K ER0 Z'],
  "bankert": ['B AE1 NG K ER0 T'],
  "bankes": ['B AE1 NG K S'],
  "bankey": ['B AE1 N K IY2'],
  "bankhead": ['B AE1 NG K HH EH2 D'],
  "bankholding": ['B AE1 NG K HH OW2 L D IH0 NG'],
  "banking": ['B AE1 NG K IH0 NG'],
  "banking's": ['B AE1 NG K IH0 NG Z'],
  "banknote": ['B AE1 NG K N OW2 T'],
  "banknotes": ['B AE1 NG K N OW2 T S'],
  "banko": ['B AE1 NG K OW0'],
  "bankowski": ['B AH0 NG K AO1 F S K IY0'],
  "bankroll": ['B AE1 NG K R OW2 L'],
  "bankrolled": ['B AE1 NG K R OW2 L D'],
  "bankrolling": ['B AE1 NG K R OW2 L IH0 NG'],
  "bankrolls": ['B AE1 NG K R OW2 L Z'],
  "bankrupcty": ['B AE1 NG K R AH0 P T S IY0'],
  "bankrupt": ['B AE1 NG K R AH0 P T'],
  "bankruptcies": ['B AE1 NG K R AH0 P T S IY0 Z'],
  "bankruptcy": ['B AE1 NG K R AH0 P S IY0', 'B AE1 NG K R AH0 P T S IY0'],
  "bankruptcy's": ['B AE1 NG K R AH0 P S IY0 Z'],
  "bankrupted": ['B AE1 NG K R AH0 P T IH0 D'],
  "bankrupting": ['B AE1 NG K R AH2 P T IH0 NG'],
  "banks": ['B AE1 NG K S'],
  "banks'": ['B AE1 NG K S'],
  "banks's": ['B AE1 NG K S IH0 Z'],
  "bankshare": ['B AE1 NG K SH EH2 R'],
  "bankshares": ['B AE1 NG K SH EH2 R Z'],
  "bankson": ['B AE1 NG K S AH0 N'],
  "bankston": ['B AE1 NG K S T AH0 N'],
  "bankverein": ['B AE1 NG K V ER0 AY2 N'],
  "bankvermont": ['B AE1 NG K V ER0 M AA1 N T'],
  "bankworcester": ['B AE1 NG K W ER1 CH EH2 S T ER0', 'B AE1 NG K W UW1 S T ER0'],
  "bann": ['B AE1 N'],
  "bannan": ['B AE1 N AH0 N'],
  "banned": ['B AE1 N D'],
  "banner": ['B AE1 N ER0'],
  "banner's": ['B AE1 N ER0 Z'],
  "bannerman": ['B AE1 N ER0 M AH0 N'],
  "banners": ['B AE1 N ER0 Z'],
  "bannick": ['B AE1 N IH0 K'],
  "banning": ['B AE1 N IH0 NG'],
  "bannings": ['B AE1 N IH0 NG Z'],
  "bannister": ['B AE1 N AH0 S T ER0', 'B AE1 N IH0 S T ER0'],
  "bannon": ['B AE1 N AH0 N'],
  "banos": ['B AA1 N OW0 Z'],
  "banoun": ['B AH0 N UW1 N'],
  "banpais": ['B AE2 N P EY1'],
  "banponce": ['B AE1 N P AA0 N S'],
  "banque": ['B AE1 NG K'],
  "banquet": ['B AE1 NG K W AH0 T'],
  "banquets": ['B AE1 NG K W AH0 T S'],
  "banquo's": ['B AE1 NG K W OW0 Z'],
  "bans": ['B AE1 N Z'],
  "bansal": ['B AA0 N S AA1 L'],
  "banshee": ['B AE0 N SH IY1', 'B AE1 N SH IY0'],
  "banta": ['B AE1 N T AH0'],
  "bantam": ['B AE1 N T AH0 M'],
  "bantam's": ['B AE1 N T AH0 M Z'],
  "bantams": ['B AE1 N T AH0 M Z'],
  "banter": ['B AE1 N T ER0'],
  "bantered": ['B AE1 N T ER0 D'],
  "bantering": ['B AE1 N T ER0 IH0 NG'],
  "bantle": ['B AE1 N T AH0 L'],
  "banton": ['B AE1 N T AH0 N'],
  "bantu": ['B AE1 N T UW0'],
  "bantz": ['B AE1 N T S'],
  "banuelos": ['B AA0 N W EH1 L OW0 Z'],
  "banville": ['B AA1 N V IH0 L'],
  "banwart": ['B AE1 N W AO2 R T'],
  "banxquote": ['B AE1 NG K S K W OW2 T'],
  "banya": ['B AA1 N Y AH0', 'B AE1 N Y AH0'],
  "banyaluca": ['B AE1 N Y AH0 L UW2 K AH0'],
  "banyan": ['B AE1 N Y AH0 N'],
  "banyas": ['B AA1 N Y AH0 Z', 'B AE1 N Y AH0 Z'],
  "banyu": ['B AA1 N Y UW2'],
  "banzhaf": ['B AE1 N Z HH AH0 F'],
  "bao": ['B AW1'],
  "baoguang": ['B AA1 AO0 G W AE2 NG'],
  "bapley": ['B AE1 P L IY0'],
  "bapna": ['B AA1 P N AH0'],
  "baptism": ['B AE1 P T IH0 Z AH0 M'],
  "baptismal": ['B AE0 P T IH1 Z M AH0 L'],
  "baptisms": ['B AE1 P T IH2 Z AH0 M Z'],
  "baptist": ['B AE1 P T AH0 S T', 'B AE1 P T IH0 S T'],
  "baptista": ['B AE2 P T IH1 S T AH0'],
  "baptiste": ['B AH0 P T IH1 S T'],
  "baptistery": ['B AE1 P T AH0 S T R IY0'],
  "baptists": ['B AE1 P T AH0 S T S', 'B AE1 P T AH0 S'],
  "baptize": ['B AE0 P T AY1 Z'],
  "baptized": ['B AE0 P T AY1 Z D', 'B AE1 P T AY2 Z D'],
  "bar": ['B AA1 R'],
  "bar's": ['B AA1 R Z'],
  "bar-mitzvah": ['B AA1 R M IH1 T S V AH0'],
  "bara": ['B AA1 R AH0'],
  "barabar": ['B EH1 R AH0 B AA0 R'],
  "barach": ['B AH0 R AA1 K', 'B ER0 AA1 K'],
  "barack": ['B AA1 R AH0 K', 'B AE0 R AA1 K'],
  "baraff": ['B AA0 R AA1 F'],
  "baragi": ['B AH0 R AA1 G IY0'],
  "barahona": ['B AE2 R AH0 HH OW1 N AH0'],
  "barajas": ['B AA0 R AA1 Y AA0 Z'],
  "barak": ['B AA1 R AH0 K'],
  "barakat": ['B AA1 R AH0 K AA2 T'],
  "baram": ['B EH1 R AE0 M'],
  "baran": ['B AA0 R AA1 N'],
  "baranek": ['B AE1 R AH0 N IH0 K'],
  "baranoski": ['B ER0 AH0 N AW1 S K IY0'],
  "baranowski": ['B ER0 AH0 N AO1 F S K IY0'],
  "baranski": ['B ER0 AE1 N S K IY0'],
  "barany": ['B ER0 AO1 N IY0'],
  "barasch": ['B AE1 R AH0 SH'],
  "barash": ['B AE1 R AH0 SH'],
  "barath": ['B AE1 R AH0 TH'],
  "baratta": ['B AA0 R AA1 T AH0'],
  "barb": ['B AA1 R B'],
  "barb's": ['B AA1 R B Z'],
  "barba": ['B AA1 R B AH0'],
  "barbados": ['B AA0 R B EY1 D OW0 S'],
  "barbagallo": ['B AA2 R B AH0 G AE1 L OW0'],
  "barbakow": ['B AA1 R B AH0 K AW2'],
  "barbanel": ['B AA1 R B AH0 N AH0 L'],
  "barbano": ['B AA0 R B AA1 N OW0'],
  "barbara": ['B AA1 R B ER0 AH0', 'B AA1 R B R AH0'],
  "barbara's": ['B AA1 R B ER0 AH0 Z'],
  "barbaree": ['B AA1 R B ER0 IY1'],
  "barbarian": ['B AA0 R B EH1 R IY0 AH0 N'],
  "barbarians": ['B AA0 R B EH1 R IY0 AH0 N Z'],
  "barbaric": ['B AA0 R B AE1 R IH0 K', 'B AA0 R B EH1 R IH0 K'],
  "barbarino": ['B AA0 R B AA0 R IY1 N OW0'],
  "barbarism": ['B AA1 R B ER0 IH2 Z AH0 M'],
  "barbarity": ['B AA0 R B AE1 R AH0 T IY0', 'B AA0 R B EH1 R AH0 T IY0'],
  "barbaro": ['B AA0 R B AA1 R OW0'],
  "barbarous": ['B AA1 R B ER0 AH0 S'],
  "barbary": ['B AA1 R B ER0 IY0'],
  "barbash": ['B AA1 R B AE2 SH'],
  "barbato": ['B AA0 R B AA1 T OW0'],
  "barbe": ['B AA1 R B'],
  "barbeau": ['B AA0 R B OW1'],
  "barbecue": ['B AA1 R B IH0 K Y UW2'],
  "barbecue's": ['B AA1 R B IH0 K Y UW2 Z'],
  "barbecued": ['B AA1 R B IH0 K Y UW2 D'],
  "barbecueing": ['B AA1 R B IH0 K Y UW2 IH0 NG'],
  "barbecues": ['B AA1 R B IH0 K Y UW2 Z'],
  "barbecuing": ['B AA1 R B IH0 K Y UW2 IH0 NG'],
  "barbed": ['B AA1 R B D'],
  "barbed-wire": ['B AA1 R B D W AY1 R'],
  "barbee": ['B AA1 R B IY1'],
  "barbel": ['B AA1 R B EH2 L'],
  "barbell": ['B AA1 R B EH2 L'],
  "barbella": ['B AA2 R B EH1 L AH0'],
  "barbells": ['B AA1 R B EH2 L Z'],
  "barbels": ['B AA1 R B AH0 L Z'],
  "barbeque": ['B AA1 R B IH0 K Y UW2'],
  "barbequed": ['B AA1 R B IH0 K Y UW2 D'],
  "barbequeing": ['B AA1 R B IH0 K Y UW2 IH0 NG'],
  "barbeques": ['B AA1 R B IH0 K Y UW2 Z'],
  "barber": ['B AA1 R B ER0'],
  "barber's": ['B AA1 R B ER0 Z'],
  "barbera": ['B AA0 R B EH1 R AH0'],
  "barberi": ['B AA0 R B EH1 R IY0'],
  "barberio": ['B AA2 R B IY1 R IY0 OW0'],
  "barberis": ['B AA1 R B ER0 IH0 S'],
  "barbero": ['B AA0 R B EH1 R OW0'],
  "barbers": ['B AA1 R B ER0 Z'],
  "barbershop": ['B AA1 R B ER0 SH AA2 P'],
  "barbette": ['B AA0 R B EH1 T'],
  "barbian": ['B AA1 R B IY0 AH0 N'],
  "barbie": ['B AA1 R B IY0'],
  "barbie's": ['B AA1 R B IY0 Z'],
  "barbier": ['B AA1 R B IY0 ER0'],
  "barbieri": ['B AA0 R B IH1 R IY0'],
  "barbieri's": ['B AA0 R B IH1 R IY0 Z'],
  "barbies": ['B AA1 R B IY0 Z'],
  "barbin": ['B AA1 R B IH0 N'],
  "barbiturate": ['B AA0 R B IH1 CH ER0 AH0 T'],
  "barbiturates": ['B AA0 R B IH1 CH ER0 AH0 T S'],
  "barbo": ['B AA1 R B OW0'],
  "barbone": ['B AA1 R B OW2 N'],
  "barbosa": ['B AA0 R B OW1 S AH0'],
  "barbour": ['B AA1 R B ER0'],
  "barbour's": ['B AA1 R B ER0 Z'],
  "barboursville": ['B AA1 B ER0 Z V IH0 L'],
  "barboza": ['B AA0 R B OW1 Z AH0'],
  "barbra": ['B AA1 R B R AH0'],
  "barbre": ['B AA1 R B ER0'],
  "barbs": ['B AA1 R B Z'],
  "barbuda": ['B AA0 R B UW1 D AH0'],
  "barby": ['B AA1 R B IY0'],
  "barca": ['B AA1 R K AH0'],
  "barcella": ['B AA2 R S EH1 L AH0'],
  "barcellos": ['B AA0 R S EH1 L OW0 Z'],
  "barcelo": ['B AA0 R CH EH1 L OW0'],
  "barcelona": ['B AA2 R S IH0 L OW1 N AH0'],
  "barcenas": ['B AA1 R S IH0 N AH0 Z'],
  "barch": ['B AA1 R K'],
  "barchefsky": ['B AA0 CH EH1 F S K IY0'],
  "barcia": ['B AA1 R CH AH0'],
  "barclay": ['B AA1 R K L EY2', 'B AA1 R K L IY2'],
  "barclay's": ['B AA1 R K L IY0 Z', 'B AA1 R K L EY0 Z'],
  "barclays": ['B AA1 R K L IY0 Z', 'B AA1 R K L EY0 Z'],
  "barclays'": ['B AA1 R K L IY0 Z', 'B AA1 R K L EY0 Z'],
  "barclays's": ['B AA1 R K L IY2 Z IH0 Z', 'B AA1 R K L EY2 Z IH0 Z'],
  "barclift": ['B AA1 R K L IH0 F T'],
  "barco": ['B AA1 R K OW0'],
  "barco's": ['B AA1 R K OW0 Z'],
  "barcomb": ['B AA1 R K AH0 M'],
  "barcroft": ['B AA1 R K R AO2 F T'],
  "barcus": ['B AA1 R K AH0 S'],
  "barczak": ['B AA1 R CH AE0 K'],
  "bard": ['B AA1 R D'],
  "bard's": ['B AA1 R D Z'],
  "bardell": ['B AA0 R D EH1 L'],
  "barden": ['B AA1 R D AH0 N'],
  "bardera": ['B AA2 R D EH1 R AH0'],
  "bardin": ['B AA1 R D IH0 N'],
  "bardo": ['B AA1 R D OW0'],
  "bardolf": ['B AA1 R D OW2 L F'],
  "bardolph": ['B AA1 R D AA0 L F'],
  "bardon": ['B AA0 R D AO1 N'],
  "bardrick": ['B AA1 R D R IH0 K'],
  "bardsley": ['B AA1 R D S L IY0'],
  "bardulf": ['B AA1 R D AH0 L F'],
  "bardulph": ['B AA1 R D AH0 L F'],
  "bardwell": ['B AA1 R D W EH2 L'],
  "bare": ['B EH1 R'],
  "bared": ['B EH1 R D'],
  "barefield": ['B AE1 R IH0 F IY0 L D', 'B AE1 R F IY0 L D'],
  "barefoot": ['B EH1 R F UH2 T'],
  "bareis": ['B AE1 R AY0 Z'],
  "barela": ['B AA0 R EH1 L AH0'],
  "barely": ['B EH1 R L IY0'],
  "barenboim": ['B EH1 R AH0 N B OY2 M'],
  "barenboim's": ['B EH1 R AH0 N B OY2 M Z'],
  "barens": ['B EH1 R AH0 N Z'],
  "barentine": ['B AA0 R EH0 N T IY1 N IY0', 'B EH1 R AH0 N T IY1 N', 'B EH1 R AH0 N T AY1 N'],
  "barents": ['B EH1 R AH0 N T S'],
  "bares": ['B EH1 R Z'],
  "barest": ['B EH1 R AH0 S T'],
  "barey": ['B EH1 R IY0'],
  "barf": ['B AA1 R F'],
  "barfield": ['B AA1 R F IY2 L D'],
  "barfknecht": ['B AA1 R F K N EH0 K T', 'B AA1 R F N EH0 K T'],
  "barfoot": ['B AA1 R F UH2 T'],
  "barfuss": ['B AA1 R F AH2 S'],
  "barg": ['B AA1 R G'],
  "barga": ['B AA1 R G AH0'],
  "bargain": ['B AA1 R G AH0 N', 'B AA1 R G IH0 N'],
  "bargained": ['B AA1 R G AH0 N D'],
  "bargainer": ['B AA1 R G IH0 N ER0'],
  "bargainers": ['B AA1 R G IH0 N ER0 Z'],
  "bargaining": ['B AA1 R G IH0 N IH0 NG'],
  "bargains": ['B AA1 R G AH0 N Z', 'B AA1 R G IH0 N Z'],
  "barganier": ['B AA1 R G AH0 N IY0 ER0'],
  "bargar": ['B AA0 R G AA1 R'],
  "bargas": ['B AA1 R G AH0 Z'],
  "barge": ['B AA1 R JH'],
  "barged": ['B AA1 R JH D'],
  "barger": ['B AA1 R JH ER0'],
  "bargeron": ['B AA1 R G ER0 AH0 N'],
  "barges": ['B AA1 R JH AH0 Z', 'B AA1 R JH IH0 Z'],
  "barging": ['B AA1 R JH IH0 NG'],
  "bargman": ['B AA1 R G M AH0 N'],
  "bargmann": ['B AA1 R G M AH0 N'],
  "bargo": ['B AA1 R G OW2'],
  "barham": ['B AA1 R HH AH0 M'],
  "barhorst": ['B AA1 R HH AO0 R S T'],
  "bari": ['B AA1 R IY0'],
  "bari's": ['B AA1 R IY0 Z'],
  "baribeau": ['B AE1 R IH0 B OW0'],
  "barich": ['B AE1 R IH0 K'],
  "baril": ['B EH1 R AH0 L'],
  "barile": ['B AA1 R AH0 L'],
  "barillari": ['B EH2 R IH0 L EH1 R IY0'],
  "barillari's": ['B EH2 R IH0 L EH1 R IY0 Z'],
  "barillo": ['B ER0 IH1 L OW0'],
  "barinco": ['B ER0 IH1 NG K OW2'],
  "baring": ['B EH1 R IH0 NG'],
  "baring's": ['B EH1 R IH0 NG Z'],
  "baringer": ['B EH1 R IH0 NG ER0'],
  "barings": ['B EH1 R IH0 NG Z'],
  "barings'": ['B EH1 R IH0 NG Z'],
  "baris": ['B AA1 R IY0 Z'],
  "barish": ['B EH1 R IH0 SH'],
  "barista": ['B AA0 R IY1 S T AH0'],
  "baristas": ['B AA0 R IY1 S T AH0 Z'],
  "barite": ['B EH1 R AY0 T'],
  "barites": ['B EH1 R AY0 T S'],
  "baritone": ['B EH1 R AH0 T OW2 N'],
  "baritones": ['B EH1 R AH0 T OW2 N Z'],
  "barium": ['B EH1 R IY0 AH0 M'],
  "bark": ['B AA1 R K'],
  "barkai": ['B AA0 R K AY1'],
  "barkalow": ['B AA1 R K AH0 L OW2'],
  "barkan": ['B AA1 R K AH0 N'],
  "barkdoll": ['B AA1 R K D AH0 L'],
  "barkdull": ['B AA1 R K D AH0 L'],
  "barke": ['B AA1 R K'],
  "barked": ['B AA1 R K T'],
  "barkeley": ['B AA1 R K L IY0'],
  "barker": ['B AA1 R K ER0'],
  "barkers": ['B AA1 R K ER0 Z'],
  "barkes": ['B AA1 R K S'],
  "barkett": ['B AA1 R K IH0 T'],
  "barkey": ['B AA1 R K IY2'],
  "barkhurst": ['B AA1 R K HH ER0 S T'],
  "barkin": ['B AA1 R K IH0 N'],
  "barking": ['B AA1 R K IH0 NG'],
  "barkley": ['B AA1 R K L IY0'],
  "barklow": ['B AA1 R K L OW2'],
  "barkman": ['B AA1 R K M AH0 N'],
  "barko": ['B AA1 R K OW0'],
  "barkocy": ['B AA1 R K AH0 S IY0'],
  "barkow": ['B AA1 R K OW0'],
  "barks": ['B AA1 R K S'],
  "barksdale": ['B AA1 R K S D EY2 L'],
  "barkshire": ['B AA1 R K SH AY2 R'],
  "barkus": ['B AA1 R K AH0 S'],
  "barlage": ['B AA1 R L IH0 JH'],
  "barlett": ['B AA1 R L IH0 T'],
  "barletta": ['B AA0 R L EH1 T AH0'],
  "barlettesville": ['B AA1 R L AH0 T S V IH2 L'],
  "barley": ['B AA1 R L IY0'],
  "barloon": ['B AA0 R L UW1 N'],
  "barlow": ['B AA1 R L OW2'],
  "barlowe": ['B AA1 R L OW2'],
  "barman": ['B AA1 R M AH0 N'],
  "barmore": ['B AA1 R M AO0 R'],
  "barn": ['B AA1 R N'],
  "barna": ['B AA1 R N AH0'],
  "barnabas": ['B AA1 R N AH0 B AH0 S'],
  "barnaby": ['B AA1 R N AH0 B IY0'],
  "barnaby's": ['B AA1 R N AH0 B IY0 Z'],
  "barnacle": ['B AA1 R N AH0 K AH0 L'],
  "barnacles": ['B AA1 R N AH0 K AH0 L Z'],
  "barnard": ['B AA1 R N AA0 R D'],
  "barnard's": ['B AA1 R N AA0 R D Z'],
  "barnards": ['B AA1 R N ER0 D Z'],
  "barnas": ['B AA1 R N AH0 Z'],
  "barndt": ['B AA1 R N T'],
  "barnell": ['B AA1 R N AH0 L'],
  "barner": ['B AA1 R N ER0'],
  "barnes": ['B AA1 R N Z'],
  "barnet": ['B AA1 R N IH0 T'],
  "barnett": ['B AA0 R N EH1 T'],
  "barnett's": ['B AA0 R N EH1 T S'],
  "barnette": ['B AA1 R N EH1 T'],
  "barnevik": ['B AA0 R N EH1 V IH0 K'],
  "barney": ['B AA1 R N IY0'],
  "barney's": ['B AA1 R N IY0 Z'],
  "barneys": ['B AA1 R N IY0 Z'],
  "barnfield": ['B AA1 R N F IY2 L D'],
  "barnhard": ['B AA1 R N HH AA2 R D'],
  "barnhardt": ['B AA1 R N HH AA2 R T'],
  "barnhart": ['B AA1 R N HH AA2 R T'],
  "barnhill": ['B AA1 R N HH IH2 L'],
  "barnhouse": ['B AA1 R N HH AW2 S'],
  "barnick": ['B AA1 R N IH0 K'],
  "barnicle": ['B AA1 R N IH0 K AH0 L'],
  "barnish": ['B AA1 R N IH0 SH'],
  "barno": ['B AA1 R N OW0'],
  "barnowski": ['B AA0 R N AW1 S K IY0'],
  "barns": ['B AA1 R N Z'],
  "barnstorm": ['B AA1 R N S T AO2 R M'],
  "barnstorming": ['B AA1 R N S T AO2 R M IH0 NG'],
  "barnum": ['B AA1 R N AH0 M'],
  "barnwell": ['B AA1 R N W EH2 L'],
  "barny": ['B AA1 R N IY0'],
  "barnyard": ['B AA1 R N Y AA2 R D'],
  "baro": ['B AA1 R OW0'],
  "baroid": ['B ER0 OY1 D'],
  "barometer": ['B ER0 AA1 M IH0 T ER0'],
  "barometers": ['B ER0 AA1 M IH0 T ER0 Z'],
  "barometric": ['B AE2 R AH0 M EH1 T R IH0 K'],
  "baron": ['B AE1 R AH0 N', 'B EH1 R AH0 N'],
  "baron's": ['B AE1 R AH0 N Z', 'B EH1 R AH0 N Z'],
  "barone": ['B ER0 OW1 N'],
  "barone's": ['B ER0 OW1 N Z'],
  "baroness": ['B EH1 R AH0 N IH0 S'],
  "baronet": ['B EH1 R AH0 N AH0 T', 'B EH2 R AH0 N EH1 T'],
  "baronets": ['B EH1 R AH0 N AH0 T S', 'B EH2 R AH0 N EH1 T S'],
  "baroni": ['B AA0 R OW1 N IY0'],
  "barons": ['B AE1 R AH0 N Z', 'B EH1 R AH0 N Z'],
  "baroody": ['B ER0 UW1 D IY0'],
  "baroque": ['B ER0 OW1 K'],
  "baros": ['B AA1 R OW0 Z'],
  "barovic": ['B EH1 R AH0 V IH0 K'],
  "barovsky": ['B ER0 AA1 V S K IY0'],
  "barr": ['B AA1 R'],
  "barr's": ['B AA1 R Z'],
  "barra": ['B AA1 R AH0'],
  "barrack": ['B AE1 R AH0 K', 'B EH1 R AH0 K'],
  "barracks": ['B AE1 R AH0 K S', 'B EH1 R AH0 K S'],
  "barraclough": ['B AE1 R AH0 K L AW0'],
  "barraco": ['B AA0 R AA1 K OW0'],
  "barracuda": ['B EH2 R AH0 K UW1 D AH0'],
  "barradino": ['B EH2 R AH0 D IY1 N OW0'],
  "barragan": ['B EH1 R AH0 G AH0 N'],
  "barrage": ['B ER0 AA1 ZH'],
  "barraged": ['B ER0 AA1 ZH D'],
  "barrages": ['B ER0 AA1 ZH IH0 Z'],
  "barranco": ['B AA0 R AA1 N K OW0'],
  "barras": ['B AE1 R AH0 Z'],
  "barrasso": ['B AA2 R AA1 S OW0'],
  "barratt": ['B AE1 R AH0 T'],
  "barraza": ['B AA2 R AA1 Z AH0'],
  "barre": ['B EH1 R IY0', 'B AA1 R'],
  "barreca": ['B AA2 R EH1 K AH0'],
  "barred": ['B AA1 R D'],
  "barreda": ['B AA0 R EY1 D AH0'],
  "barreiro": ['B AA0 R EH1 R OW0'],
  "barrel": ['B AE1 R AH0 L', 'B EH1 R AH0 L'],
  "barreled": ['B AE1 R AH0 L D', 'B EH1 R AH0 L D'],
  "barreling": ['B AE1 R AH0 L IH0 NG', 'B EH1 R AH0 L IH0 NG'],
  "barrell": ['B AA0 R EY1 L'],
  "barrels": ['B AE1 R AH0 L Z', 'B EH1 R AH0 L Z'],
  "barren": ['B AE1 R AH0 N', 'B EH1 R AH0 N'],
  "barrens": ['B AE1 R AH0 N Z'],
  "barrentine": ['B AE1 R AH0 N T AY2 N'],
  "barrera": ['B AA2 R EH1 R AH0'],
  "barreras": ['B AA0 R EH1 R AA0 Z'],
  "barres": ['B AA1 R Z'],
  "barresi": ['B AA2 R EH1 S IY0'],
  "barret": ['B AE1 R IH0 T'],
  "barreto": ['B AA0 R EH1 T OW0'],
  "barrett": ['B AE1 R IH0 T', 'B EH1 R IH0 T'],
  "barretta": ['B AA0 R EH1 T AH0'],
  "barrette": ['B ER0 EH1 T'],
  "barretto": ['B AA0 R EH1 T OW0'],
  "barricada": ['B AA2 R IH0 K AA1 D AH0'],
  "barricade": ['B AE1 R AH0 K EY2 D', 'B EH1 R AH0 K EY2 D'],
  "barricaded": ['B AE1 R AH0 K EY2 D IH0 D', 'B EH1 R AH0 K EY2 D IH0 D'],
  "barricades": ['B AE1 R AH0 K EY2 D Z', 'B EH1 R AH0 K EY2 D Z'],
  "barrick": ['B AE1 R IH0 K', 'B EH1 R IH0 K'],
  "barrick's": ['B AE1 R IH0 K S', 'B EH1 R IH0 K S'],
  "barricklow": ['B AE1 R IH0 K L AW0'],
  "barrickman": ['B AE1 R IH0 K M AH0 N'],
  "barrie": ['B AE1 R IY0', 'B EH1 R IY0'],
  "barrientes": ['B ER0 IY1 N T S'],
  "barrientez": ['B AA0 R IY1 N T EH0 Z'],
  "barrientos": ['B AA0 R IY1 N T OW0 Z'],
  "barrier": ['B AE1 R IY0 ER0', 'B EH1 R IY0 ER0'],
  "barriere": ['B AA0 R IH1 R IY0'],
  "barriers": ['B AE1 R IY0 ER0 Z', 'B EH1 R IY0 ER0 Z'],
  "barriga": ['B AA2 R IY1 G AH0'],
  "barriger": ['B AE1 R IH0 G ER0'],
  "barrilleaux": ['B AE1 R IH0 L OW2'],
  "barrineau": ['B AE1 R IH0 N OW2'],
  "barring": ['B AA1 R IH0 NG'],
  "barringer": ['B AE1 R IH0 NG ER0'],
  "barrings": ['B AA1 R IH0 NG Z'],
  "barrington": ['B AE1 R IH0 NG T AH0 N', 'B EH1 R IH0 NG T AH0 N'],
  "barrio": ['B AA1 R IY0 OW0'],
  "barrios": ['B AA0 R IY1 OW0 Z', 'B EH0 R IY1 OW0 Z'],
  "barris": ['B AE1 R IH0 S', 'B EH1 R IH0 S'],
  "barrish": ['B AE1 R IH0 SH'],
  "barrister": ['B AE1 R IH0 S T ER0', 'B EH1 R IH0 S T ER0'],
  "barristers": ['B AE1 R IH0 S T ER0 Z', 'B EH1 R IH0 S T ER0 Z'],
  "barritt": ['B ER0 IH1 T'],
  "barro": ['B AA1 R OW0'],
  "barron": ['B AE1 R AH0 N'],
  "barron's": ['B AE1 R AH0 N Z'],
  "barrone": ['B ER0 OW1 N'],
  "barroom": ['B AA1 R R UW2 M'],
  "barros": ['B AA1 R OW0 Z'],
  "barroso": ['B AA2 R OW1 S OW0'],
  "barrow": ['B AE1 R OW0', 'B EH1 R OW0'],
  "barrowman": ['B AE1 R OW0 M AH0 N', 'B EH1 R OW0 M AH0 N'],
  "barrows": ['B AE1 R OW0 Z', 'B EH1 R OW0 Z'],
  "barrs": ['B AA1 R Z'],
  "barrus": ['B AE1 R AH0 S'],
  "barry": ['B AE1 R IY0', 'B EH1 R IY0'],
  "barry's": ['B AE1 R IY0 Z', 'B EH1 R IY0 Z'],
  "barrymore": ['B AE1 R IY0 M AO2 R', 'B EH1 R IY0 M AO2 R'],
  "bars": ['B AA1 R Z'],
  "barsamian": ['B AA2 R S EY1 M IY0 AH0 N'],
  "barsanti": ['B AA0 R S AA1 N T IY0'],
  "barsch": ['B AA1 R SH'],
  "barschel": ['B AA1 R SH AH0 L'],
  "barsh": ['B AA1 R SH'],
  "barshefsky": ['B AA2 R SH EH1 F S K IY0'],
  "barsky": ['B AA1 R S K IY0'],
  "barsness": ['B AA1 R Z N AH0 S'],
  "barson": ['B AA1 R S AH0 N'],
  "barsotti": ['B AA0 R S OW1 T IY0'],
  "barstad": ['B AA1 R S T AH0 D'],
  "barstow": ['B AA1 R S T OW2'],
  "barszcz": ['B AA1 R SH CH'],
  "bart": ['B AA1 R T'],
  "bart's": ['B AA1 R T S'],
  "barta": ['B AA1 R T AH0'],
  "bartak": ['B AA1 R T AH0 K'],
  "bartee": ['B AA1 R T IY2'],
  "bartek": ['B AA1 R T EH2 K'],
  "bartel": ['B AA1 R T AH0 L'],
  "bartell": ['B AA0 R T EY1 L'],
  "bartels": ['B AA1 R T AH0 L Z'],
  "bartelson": ['B AA1 R T IH0 L S AH0 N'],
  "bartelt": ['B AA1 R T IH0 L T'],
  "barten": ['B AA1 R T AH0 N'],
  "bartender": ['B AA1 R T EH2 N D ER0'],
  "bartenders": ['B AA1 R T EH2 N D ER0 Z'],
  "barter": ['B AA1 R T ER0'],
  "bartered": ['B AA1 R T ER0 D'],
  "bartering": ['B AA1 R T ER0 IH0 NG'],
  "barth": ['B AA1 R TH'],
  "bartha": ['B AA1 R TH AH0'],
  "barthel": ['B AA1 R TH AH0 L'],
  "barthelemy": ['B AA2 R TH EH1 L AH0 M IY0'],
  "barthelme": ['B AA1 R TH AH0 L M'],
  "barthelme's": ['B AA1 R TH AH0 L M Z'],
  "barthes": ['B AA1 R TH S'],
  "barthold": ['B AA1 R TH OW2 L D'],
  "bartholdy": ['B AA0 R TH OW1 L D IY0'],
  "bartholomay": ['B AA0 R TH AH0 L AA1 M EY0'],
  "bartholomew": ['B AA0 R TH AA1 L AH0 M Y UW2'],
  "bartholow": ['B AA1 R TH AH0 L OW0'],
  "barthram": ['B AA1 R TH R AE2 M'],
  "bartko": ['B AA1 R T K OW0'],
  "bartkowiak": ['B ER0 T K AW1 IY0 AE0 K'],
  "bartkowski": ['B ER0 T K AO1 F S K IY0'],
  "bartkus": ['B AA1 R T K IH0 S'],
  "bartl": ['B AA1 R T AH0 L'],
  "bartle": ['B AA1 R T AH0 L'],
  "bartlebaugh": ['B AA1 R T AH0 L B AO0'],
  "bartles": ['B AA1 R T AH0 L Z'],
  "bartleson": ['B AA1 R T AH0 L S AH0 N'],
  "bartlestein": ['B AA1 R T AH0 L S T IY2 N', 'B AA1 R T AH0 L S T AY2 N'],
  "bartlesville": ['B AA1 R T AH0 L Z V IH2 L'],
  "bartlett": ['B AA1 R T L IH0 T'],
  "bartlett's": ['B AA1 R T L AH0 T S'],
  "bartley": ['B AA1 R T L IY0'],
  "bartling": ['B AA1 R T L IH0 NG'],
  "bartlow": ['B AA1 R T L OW0'],
  "bartman": ['B AA1 R T M AH0 N'],
  "bartmess": ['B AA1 R T M IH0 S'],
  "bartnick": ['B AA1 R T N IH0 K'],
  "bartnicki": ['B ER0 T N IH1 T S K IY0'],
  "bartnik": ['B AA1 R T N IH0 K'],
  "barto": ['B AA1 R T OW0'],
  "bartok": ['B AA1 R T AH0 K'],
  "bartol": ['B AA1 R T AH0 L'],
  "bartoletti": ['B AA0 R T OW0 L EH1 T IY0'],
  "bartoli": ['B AA0 R T OW1 L IY0'],
  "bartolini": ['B AA0 R T OW0 L IY1 N IY0'],
  "bartolo": ['B AA0 R T OW1 L OW0'],
  "bartolomei": ['B AA0 R T OW0 L OW0 M EY1 IY0'],
  "bartolomeo": ['B AA0 R T AO0 L AH0 M EY1 OW0'],
  "bartolucci": ['B AA0 R T OW0 L UW1 CH IY0'],
  "barton": ['B AA1 R T AH0 N'],
  "barton's": ['B AA1 R T AH0 N Z'],
  "bartone": ['B AA0 R T OW1 N IY0'],
  "bartoo": ['B AA1 R CH UW0'],
  "bartos": ['B AA1 R T OW0 Z'],
  "bartosch": ['B AA1 R T AO0 SH'],
  "bartosh": ['B AA1 R T AH0 SH'],
  "bartosiewicz": ['B ER0 T AA1 S AH0 V IH0 CH'],
  "bartosik": ['B ER0 T OW1 S IH0 K'],
  "bartoszek": ['B ER0 T AA1 SH EH0 K'],
  "bartow": ['B AA1 R T OW2'],
  "bartram": ['B AA1 R T R AE2 M'],
  "barts": ['B AA1 R T S'],
  "bartsch": ['B AA1 R CH'],
  "bartunek": ['B AA1 R T AH0 N IH0 K'],
  "bartus": ['B AA1 R T AH0 S'],
  "bartz": ['B AA1 R T S'],
  "baruch": ['B AA2 R UW1 K'],
  "barwick": ['B AA1 R W IH2 K'],
  "baryshnikov": ['B ER0 IH1 SH N IH0 K AA2 V'],
  "baryshnikov's": ['B ER0 IH1 SH N IH0 K AA2 V Z'],
  "barz": ['B AA1 R Z'],
  "bas-relief": ['B AA1 R IY0 L IY1 F', 'B AA1 S R IY0 L IY1 F'],
  "bas-reliefs": ['B AA1 R IY0 L IY1 F S', 'B AA1 S R IY0 L IY1 F S'],
  "basa": ['B AA1 S AH0'],
  "basal": ['B EY1 S AH0 L'],
  "basaldua": ['B AA0 S AA0 L D UW1 AH0'],
  "basalt": ['B AH0 S AO1 L T'],
  "basalts": ['B AH0 S AO1 L T S'],
  "basam": ['B AH0 S AA1 M'],
  "basara": ['B AH0 S EH1 R AH0'],
  "basara's": ['B AH0 S EH1 R AH0 Z'],
  "basayev": ['B AE1 S AA0 Y EH2 V', 'B AE1 S AA0 Y EH2 F'],
  "bascay": ['B AE1 S K EY0'],
  "basch": ['B AE1 SH'],
  "basco": ['B AA1 S K OW0'],
  "bascom": ['B AE1 S K AH0 M'],
  "basden": ['B AE1 S D AH0 N'],
  "base": ['B EY1 S'],
  "base's": ['B EY1 S AH0 Z'],
  "baseball": ['B EY1 S B AO1 L'],
  "baseball's": ['B EY1 S B AO1 L Z'],
  "baseballs": ['B EY1 S B AO1 L Z'],
  "baseboard": ['B EY1 S B AO2 R D'],
  "baseboards": ['B EY1 S B AO2 R D Z'],
  "based": ['B EY1 S T'],
  "basehore": ['B AE1 S IH0 HH AO0 R', 'B EY1 S HH AO0 R'],
  "basel": ['B AA1 Z AH0 L'],
  "baseless": ['B EY1 S L AH0 S'],
  "baseline": ['B EY1 S L AY2 N'],
  "baselines": ['B EY1 S L AY2 N Z'],
  "baseman": ['B EY1 S M AH0 N'],
  "basement": ['B EY1 S M AH0 N T'],
  "basements": ['B EY1 S M AH0 N T S'],
  "basenji": ['B AH0 S EH1 N JH IY0'],
  "baser": ['B EY1 S ER0'],
  "bases": ['B EY1 S AH0 Z', 'B EY1 S IH0 Z', 'B EY1 S IY0 Z'],
  "basey": ['B EY1 S IY0'],
  "basford": ['B AE1 S F ER0 D'],
  "basgall": ['B AE1 S G AH0 L'],
  "bash": ['B AE1 SH'],
  "basha": ['B AA1 SH AH0'],
  "basham": ['B AE1 SH AH0 M'],
  "bashar": ['B AH0 SH AA1 R'],
  "bashaw": ['B AH0 SH AO1'],
  "bashed": ['B AE1 SH T'],
  "basher": ['B AE1 SH ER0'],
  "bashers": ['B AE1 SH ER0 Z'],
  "bashes": ['B AE1 SH IH0 Z'],
  "bashford": ['B AE1 SH F ER0 D'],
  "bashful": ['B AE1 SH F AH0 L'],
  "bashfulness": ['B AE1 SH F AH0 L N EH2 S'],
  "bashing": ['B AE1 SH IH0 NG'],
  "bashir": ['B AH0 SH IH1 R'],
  "bashor": ['B AE1 SH ER0'],
  "bashore": ['B AE1 SH ER0'],
  "basi": ['B AA1 S IY0', 'B EY1 S IY0'],
  "basic": ['B EY1 S IH0 K'],
  "basic's": ['B EY1 S IH0 K S'],
  "basically": ['B EY1 S IH0 K L IY0'],
  "basich": ['B AA1 S IH0 HH'],
  "basics": ['B EY1 S IH0 K S'],
  "basie": ['B EY1 Z IY0'],
  "basil": ['B AE1 Z AH0 L'],
  "basile": ['B AA1 S AH0 L'],
  "basilia": ['B AA0 S IY1 L IY0 AH0'],
  "basilica": ['B AH0 S IH1 L IH0 K AH0', 'B AH0 Z IH1 L AH0 K AH0'],
  "basilio": ['B AA0 S IY1 L IY0 OW0'],
  "basim": ['B AA0 S IY1 M'],
  "basim's": ['B AA0 S IY1 M Z'],
  "basin": ['B EY1 S AH0 N'],
  "basing": ['B EY1 S IH0 NG'],
  "basinger": ['B EY1 S IH0 NG ER0'],
  "basins": ['B EY1 S AH0 N Z'],
  "basinski": ['B AH0 S IH1 N S K IY0'],
  "basis": ['B EY1 S AH0 S', 'B EY1 S IH0 S'],
  "basista": ['B AH0 S IH1 S T AH0'],
  "basix": ['B EY1 S IH0 K S'],
  "bask": ['B AE1 S K'],
  "basked": ['B AE1 S K T'],
  "baskerville": ['B AE1 S K ER0 V IH2 L'],
  "basket": ['B AE1 S K AH0 T'],
  "basketball": ['B AE1 S K AH0 T B AO2 L'],
  "basketball's": ['B AE1 S K AH0 T B AO2 L Z'],
  "basketballs": ['B AE1 S K AH0 T B AO2 L Z'],
  "basketmaker": ['B AE1 S K AH0 T M EY2 K ER0'],
  "basketmaking": ['B AE1 S K AH0 T M EY2 K IH0 NG'],
  "basketry": ['B AE1 S K AH0 T R IY0'],
  "baskets": ['B AE1 S K AH0 T S'],
  "baskett": ['B AE1 S K IH0 T'],
  "baskette": ['B AH0 S K EH1 T'],
  "baskin": ['B AE1 S K IH0 N'],
  "basking": ['B AE1 S K IH0 NG'],
  "baskins": ['B AE1 S K IH0 N Z'],
  "basks": ['B AE1 S K S'],
  "basle": ['B AE1 S AH0 L'],
  "basler": ['B EY1 Z AH0 L ER0'],
  "basner": ['B AE1 S N ER0'],
  "basnett": ['B AE1 S N IH0 T'],
  "basnight": ['B AE1 Z N AY2 T'],
  "bason": ['B AE1 S AH0 N'],
  "basophilia": ['B EY2 S AO0 F IH1 L IY2 AH0'],
  "basophilic": ['B EY2 S AO0 F IH1 L IH0 K'],
  "basore": ['B AA0 S AO1 R IY0'],
  "basque": ['B AE1 S K'],
  "basques": ['B AE1 S K S'],
  "basquez": ['B AA0 S K W EH1 Z'],
  "basra": ['B AA1 S R AA0'],
  "bass": ['B AE1 S', 'B EY1 S'],
  "bass's": ['B EY1 S IH0 Z', 'B AE1 S IH0 Z'],
  "bassa": ['B AE1 S AH0'],
  "bassam": ['B AE1 S AH0 M'],
  "basse": ['B AE1 S'],
  "basses": ['B AE1 S IH0 Z', 'B EY1 S IH0 Z'],
  "basset": ['B AE1 S IH0 T'],
  "bassett": ['B AE1 S IH0 T'],
  "bassette": ['B AH0 S EH1 T'],
  "bassetti": ['B AA0 S EH1 T IY0'],
  "bassford": ['B AE1 S F ER0 D'],
  "bassham": ['B AE1 SH AH0 M'],
  "bassi": ['B AE1 S IY0'],
  "bassin": ['B AE1 S IH0 N'],
  "bassinger": ['B AE1 S IH0 N JH ER0'],
  "bassir": ['B AH0 S IH1 R'],
  "bassir's": ['B AH0 S IH1 R Z'],
  "bassist": ['B EY1 S IH0 S T'],
  "bassists": ['B EY1 S IH0 S T S'],
  "bassler": ['B AE1 S L ER0'],
  "bassman": ['B AE1 S M AH0 N'],
  "basso": ['B AE1 S OW0'],
  "bassoon": ['B AH0 S UW1 N'],
  "bassoonist": ['B AH0 S UW1 N IH0 S T'],
  "bast": ['B AE1 S T'],
  "basta": ['B AE1 S T AH0'],
  "bastarache": ['B AA0 S T AA1 R EY0 K'],
  "bastard": ['B AE1 S T ER0 D'],
  "bastard's": ['B AE1 S T ER0 D Z'],
  "bastards": ['B AE1 S T ER0 D Z'],
  "baste": ['B EY1 S T'],
  "basted": ['B EY1 S T AH0 D'],
  "bastedo": ['B AA0 S T EY1 D OW0'],
  "basten": ['B EY1 S T AH0 N'],
  "bastian": ['B AE1 S CH IH0 N'],
  "bastien": ['B AE1 S T AH0 N'],
  "bastille": ['B AE1 S T IH0 L', 'B AE2 S T IH1 L'],
  "bastin": ['B AE1 S T IH0 N'],
  "basting": ['B EY1 S T IH0 NG'],
  "bastion": ['B AE1 S CH AH0 N'],
  "bastions": ['B AE1 S CH AH0 N Z'],
  "bastogne": ['B AE2 S T OW1 N'],
  "bastogne's": ['B AE2 S T OW1 N Z'],
  "baston": ['B AE1 S T AH0 N'],
  "bastone": ['B AA1 S T OW0 N'],
  "bastow": ['B AE1 S T OW0'],
  "bastyr": ['B EY1 S T IH0 R'],
  "basu": ['B AA1 S UW0'],
  "basulto": ['B AH0 S AH1 L T OW0'],
  "basurto": ['B AA0 S UH1 R T OW0'],
  "baswell": ['B AE1 S W EH0 L'],
  "basye": ['B AE1 S AY0'],
  "bat": ['B AE1 T'],
  "bataan": ['B AH0 T AA1 N'],
  "batan": ['B EY1 T AH0 N'],
  "batat": ['B AH0 T AA1 T'],
  "batavia": ['B AH0 T EY1 V IY0 AH0', 'B AH0 T AE1 V IY0 AH0'],
  "batboy": ['B AE1 T B OY0'],
  "batboys": ['B AE1 T B OY0 Z'],
  "batch": ['B AE1 CH'],
  "batchelder": ['B AE1 CH EH2 L D ER0'],
  "batcheller": ['B AE1 CH AH0 L ER0'],
  "batchelor": ['B AE1 CH AH0 L ER0'],
  "batches": ['B AE1 CH AH0 Z', 'B AE1 CH IH0 Z'],
  "batdorf": ['B AE1 T D AO0 R F'],
  "bate": ['B EY1 T'],
  "bated": ['B EY1 T IH0 D'],
  "bateman": ['B EY1 T M AH0 N'],
  "bates": ['B EY1 T S'],
  "bates'": ['B EY1 T S'],
  "bateson": ['B AE1 T IH0 S AH0 N', 'B EY1 T S AH0 N', 'B AE1 T S AH0 N'],
  "batesville": ['B EY1 T S V IH2 L'],
  "batey": ['B EY1 T IY0'],
  "bath": ['B AE1 TH'],
  "bathe": ['B EY1 DH'],
  "bathed": ['B EY1 DH D'],
  "bather": ['B EY1 DH ER0', 'B AE1 DH ER0'],
  "bathers": ['B EY1 DH ER0 Z', 'B AE1 DH ER0 Z'],
  "bathes": ['B EY1 DH Z'],
  "bathgate": ['B AE1 TH G EY2 T'],
  "bathing": ['B EY1 DH IH0 NG'],
  "bathke": ['B AE1 TH K'],
  "batholith": ['B AE1 TH AH0 L IH0 TH'],
  "batholiths": ['B AE1 TH AH0 L IH0 TH S'],
  "bathrick": ['B AE1 TH R IH0 K'],
  "bathrobe": ['B AE1 TH R OW2 B'],
  "bathrobes": ['B AE1 TH R OW2 B Z'],
  "bathroom": ['B AE1 TH R UW2 M'],
  "bathrooms": ['B AE1 TH R UW2 M Z', 'B AA1 TH R UW2 M Z'],
  "baths": ['B AE1 TH S'],
  "bathsheba": ['B AE0 TH SH IY1 B AH0'],
  "bathtub": ['B AE1 TH T AH0 B'],
  "bathtubs": ['B AE1 TH T AH2 B Z'],
  "bathurst": ['B AE1 TH ER0 S T'],
  "bathurst's": ['B AE1 TH ER0 S T S'],
  "batibot": ['B AE1 T IH0 B AA0 T'],
  "batie": ['B EY1 T IY0'],
  "batignolles": ['B AE2 T IH0 N Y OW1 L AH0 S'],
  "batik": ['B AH0 T IY1 K'],
  "batiks": ['B AE1 T IH0 K S', 'B AH0 T IY1 K S'],
  "batista": ['B AH0 T IH1 S T AA0'],
  "batista's": ['B AH0 T IH1 S T AA0 Z'],
  "batiste": ['B AH0 T IH1 S T AH0'],
  "batley": ['B AE1 T L IY0'],
  "batman": ['B AE1 T M AE2 N'],
  "batogowski": ['B AE2 T OW0 G AW1 S K IY0'],
  "baton": ['B AH0 T AA1 N', 'B AE1 T AA2 N', 'B AE1 T AH0 N'],
  "baton-rouge": ['B AE1 T AH0 N R UW1 JH'],
  "baton-rouge's": ['B AE1 T AH0 N R UW1 JH IH0 Z'],
  "batons": ['B AE0 T AA1 N Z'],
  "bator": ['B AA0 T AO1 R'],
  "batra": ['B AE1 T R AH0'],
  "batres": ['B EY1 T ER0 Z'],
  "bats": ['B AE1 T S'],
  "batson": ['B AE1 T S AH0 N'],
  "batson's": ['B AE1 T S AH0 N Z'],
  "batt": ['B AE1 T'],
  "battaglia": ['B AA0 T AA1 G L IY0 AH0'],
  "battaglini": ['B AA0 T AA0 G L IY1 N IY0'],
  "battalion": ['B AH0 T AE1 L Y AH0 N'],
  "battalions": ['B AH0 T AE1 L Y AH0 N Z'],
  "batte": ['B AE1 T'],
  "batted": ['B AE1 T IH0 D'],
  "battelle": ['B AH0 T EH1 L'],
  "battelle's": ['B AH0 T EH1 L Z'],
  "batten": ['B AE1 T AH0 N'],
  "battenberg": ['B AE1 T AH0 N B ER2 G'],
  "battenfield": ['B AE1 T AH0 N F IY2 L D'],
  "battening": ['B AE1 T AH0 N IH0 NG'],
  "batter": ['B AE1 T ER0'],
  "battered": ['B AE1 T ER0 D'],
  "batterer": ['B AE1 T ER0 ER0'],
  "batterers": ['B AE1 T ER0 ER0 Z'],
  "batteries": ['B AE1 T ER0 IY0 Z'],
  "battering": ['B AE1 T ER0 IH0 NG'],
  "batterman": ['B AE1 T ER0 M AH0 N'],
  "batters": ['B AE1 T ER0 Z'],
  "battersby": ['B AE1 T ER0 S B IY0'],
  "battersea": ['B AE1 T ER0 S IY2'],
  "batterson": ['B AE1 T ER0 S AH0 N'],
  "battery": ['B AE1 T ER0 IY0'],
  "batterymarch": ['B AH0 T EH1 R IY0 M AA0 R CH'],
  "battey": ['B AE1 T IY0'],
  "battiato": ['B AA0 T IY0 AA1 T OW0'],
  "batticaloa": ['B AE2 T IH0 K AH0 L OW1 AH0'],
  "battin": ['B AE1 T IH0 N'],
  "batting": ['B AE1 T IH0 NG'],
  "battipaglia": ['B AE2 T IH0 P AE1 G L IY0 AH0'],
  "battis": ['B AE1 T IH0 S'],
  "battison": ['B AE1 T IH0 S AH0 N'],
  "battista": ['B AH0 T IY1 S T AH0'],
  "battiste": ['B AA0 T IY1 S T IY0'],
  "battisti": ['B AA0 T IY1 S T IY0'],
  "battistoni": ['B AA0 T IY0 S T OW1 N IY0'],
  "battle": ['B AE1 T AH0 L'],
  "battle's": ['B AE1 T AH0 L Z'],
  "battled": ['B AE1 T AH0 L D'],
  "battlefield": ['B AE1 T AH0 L F IY2 L D'],
  "battlefields": ['B AE1 T AH0 L F IY2 L D Z'],
  "battlefront": ['B AE1 T AH0 L F R AH2 N T'],
  "battleground": ['B AE1 T AH0 L G R AW2 N D'],
  "battlegrounds": ['B AE1 T AH0 L G R AW2 N D Z'],
  "battlement": ['B AE1 T AH0 L M AH0 N T', 'B AE1 T AH0 L M EH0 N T'],
  "battlements": ['B AE1 T AH0 L M AH0 N T S'],
  "battles": ['B AE1 T AH0 L Z'],
  "battleship": ['B AE1 T AH0 L SH IH2 P'],
  "battleships": ['B AE1 T AH0 L SH IH2 P S'],
  "battling": ['B AE1 T AH0 L IH0 NG', 'B AE1 T L IH0 NG'],
  "batton": ['B AE1 T AH0 N'],
  "batts": ['B AE1 T S'],
  "batty": ['B AE1 T IY0'],
  "batuigas": ['B AE2 CH UW0 IY1 G AH0 S'],
  "batus": ['B AE1 T AH0 S', 'B AO1 T AH0 S'],
  "baty": ['B EY1 T IY0'],
  "batz": ['B AE1 T S'],
  "batzel": ['B AE1 T Z AH0 L'],
  "batzer": ['B EY1 T Z ER0'],
  "bauble": ['B AO1 B AH0 L'],
  "baubles": ['B AO1 B AH0 L Z'],
  "baublitz": ['B AW1 B L IH0 T S'],
  "bauch": ['B AO1 CH'],
  "baucom": ['B OW0 K AA1 M'],
  "baucum": ['B OW0 K AH1 M'],
  "baucus": ['B AA1 K AH0 S', 'B AE1 K AH0 S'],
  "baud": ['B AO1 D'],
  "baudendistel": ['B AW1 D IH0 N D IH0 S T AH0 L'],
  "bauder": ['B AW1 D ER0'],
  "baudoin": ['B OW2 D OY1 N'],
  "baudouin": ['B AO0 D UW1 IH0 N'],
  "bauer": ['B AW1 ER0'],
  "bauerle": ['B AW1 ER0 AH0 L'],
  "bauerlein": ['B AW1 ER0 L AY0 N'],
  "bauermeister": ['B AW1 ER0 M AY0 S T ER0'],
  "bauernfeind": ['B AW1 ER0 N F AY0 N D'],
  "bauers": ['B AW1 ER0 Z'],
  "baugh": ['B AO1'],
  "baughan": ['B AO1 AH0 N'],
  "baugher": ['B AO1 ER0'],
  "baughman": ['B AO1 M AH0 N'],
  "baughn": ['B AO1 N'],
  "bauguess": ['B AW1 G IH0 S'],
  "baugus": ['B AO1 G AH0 S'],
  "bauknecht": ['B AW1 K N IH0 K T'],
  "bauknight": ['B AO1 K N AY2 T'],
  "baum": ['B AO1 M', 'B AW1 M'],
  "bauman": ['B AW1 M AH0 N'],
  "baumann": ['B AW1 M AH0 N'],
  "baumbach": ['B AW1 M B AA2 K'],
  "baumberger": ['B AW1 M B ER0 G ER0', 'B AA1 M B ER0 G ER0'],
  "baumeister": ['B AW1 M AY2 S T ER0'],
  "baumel": ['B AW1 M AH0 L'],
  "baumer": ['B AW1 M ER0'],
  "baumert": ['B AW1 M ER0 T'],
  "baumgard": ['B AW1 M G ER0 D', 'B AA1 M G ER0 D'],
  "baumgardner": ['B AW1 M G AA2 R D N ER0', 'B AA1 M G AA2 R D N ER0'],
  "baumgardt": ['B AW1 M G AA2 R T', 'B AA1 M G AA2 R T'],
  "baumgarner": ['B AW1 M G AA2 R N ER0', 'B AA1 M G AA2 R N ER0'],
  "baumgart": ['B AW1 M G AA2 R T', 'B AA1 M G AA2 R T'],
  "baumgarten": ['B AW1 M G AA0 R T AH0 N', 'B AA1 M G AA0 R T AH0 N'],
  "baumgartner": ['B AW1 M G AA2 R T N ER0', 'B AA1 M G AA2 R T N ER0'],
  "baumhardt": ['B AW1 M HH AA2 R T', 'B AA1 M HH AA2 R T'],
  "baumhauer": ['B AA1 M HH AW0 ER0', 'B AW1 M HH AW0 ER0'],
  "baumholder": ['B AA1 M HH AO2 L D ER0', 'B AW1 M HH AO2 L D ER0'],
  "baumler": ['B AW1 M AH0 L ER0', 'B AA1 M AH0 L ER0', 'B AA1 M L ER0'],
  "baumstark": ['B AW1 M S T AA2 R K', 'B AA1 M S T AA2 R K'],
  "baun": ['B AO1 N'],
  "baur": ['B AO1 R', 'B AW1 ER0'],
  "baus": ['B AO1 Z'],
  "bausch": ['B AW1 SH'],
  "bauserman": ['B AW1 S ER0 M AH0 N'],
  "bausman": ['B AW1 S M AH0 N'],
  "bautch": ['B AW1 CH'],
  "bautista": ['B AO0 T IY1 S T AH0'],
  "bautz": ['B AO1 T S'],
  "bautzer": ['B AW1 T S ER0'],
  "bauwens": ['B AW1 W AH0 N Z'],
  "bauxite": ['B AO1 K S AY0 T'],
  "bauza": ['B AW1 Z AH0'],
  "bava": ['B AA1 V AH0'],
  "bavadra": ['B AH0 V AE1 D R AH0'],
  "bavaria": ['B AH0 V EH1 R IY0 AH0'],
  "bavaria's": ['B AH0 V EH1 R IY0 AH0 Z'],
  "bavarian": ['B AH0 V EH1 R IY0 AH0 N'],
  "bavaro": ['B AA0 V AA1 R OW0'],
  "baver": ['B EY1 V ER0'],
  "bavis": ['B EY1 V IH0 S'],
  "bawd": ['B AO1 D'],
  "bawden": ['B AO1 D AH0 N'],
  "bawdy": ['B AO1 D IY0'],
  "bawer": ['B AO1 ER0'],
  "bawl": ['B AO1 L'],
  "bawled": ['B AO1 L D'],
  "bawls": ['B AO1 L Z'],
  "bax": ['B AE1 K S'],
  "baxa": ['B AE1 K S AH0'],
  "baxendale": ['B AE1 K S AH0 N D EY0 L'],
  "baxley": ['B AE1 K S L IY0'],
  "baxt": ['B AE1 K S T'],
  "baxter": ['B AE1 K S T ER0'],
  "baxter's": ['B AE1 K S T ER0 Z'],
  "bay": ['B EY1'],
  "bay's": ['B EY1 Z'],
  "bay-area": ['B EY2 EH1 R IY0 AH0'],
  "bayanjou": ['B AY0 AE1 N JH UW0'],
  "bayar": ['B EY1 ER0'],
  "bayard": ['B EY1 ER0 D'],
  "baybank": ['B EY1 B AE2 NG K'],
  "baybank's": ['B EY1 B AE2 NG K S'],
  "baybanks": ['B EY1 B AE2 NG K S'],
  "baydhabo": ['B EY0 D AA1 B OW0'],
  "baye": ['B EY1'],
  "bayed": ['B EY1 D'],
  "bayer": ['B EY1 ER0'],
  "bayer's": ['B EY1 ER0 Z'],
  "bayerische": ['B EY2 ER0 IY1 SH'],
  "bayers": ['B EY1 ER0 Z'],
  "bayes": ['B EY1 Z'],
  "bayesian": ['B EY1 ZH IH0 N'],
  "bayh": ['B EY1'],
  "baying": ['B EY1 IH0 NG'],
  "bayle": ['B EY1 L'],
  "baylee": ['B EY1 L IY0'],
  "baylen": ['B EY1 L AH0 N'],
  "bayles": ['B EY1 L Z'],
  "bayless": ['B EY1 L IH0 S'],
  "bayley": ['B EY1 L IY0'],
  "bayliff": ['B EY1 L IH0 F'],
  "bayliner": ['B EY1 L AY2 N ER0'],
  "baylis": ['B EY1 L IH0 S'],
  "bayliss": ['B EY1 L IH0 S'],
  "baylon": ['B EY1 L AA0 N'],
  "baylor": ['B EY1 L ER0'],
  "bayly": ['B EY1 L IY0'],
  "bayman": ['B EY1 M AH0 N'],
  "baynard": ['B EY1 N ER0 D'],
  "bayne": ['B EY1 N'],
  "baynes": ['B EY1 N Z'],
  "baynham": ['B EY1 N HH AH0 M'],
  "bayog": ['B EY1 AA2 G'],
  "bayonet": ['B EY1 AH0 N EH2 T'],
  "bayonets": ['B EY1 AH0 N EH2 T S'],
  "bayonne": ['B EY2 Y OW1 N'],
  "bayou": ['B AY1 UW0'],
  "bayous": ['B AY1 UW0 Z'],
  "bayouth": ['B AY1 UW0 TH'],
  "bayreuth": ['B EY1 R OY2 TH'],
  "bays": ['B EY1 Z'],
  "bayse": ['B EY1 Z'],
  "bayside": ['B EY1 S AY2 D'],
  "baysinger": ['B EY1 S IH0 N JH ER0'],
  "baytown": ['B EY1 T AW2 N'],
  "baytree": ['B EY1 T R IY2'],
  "baytree's": ['B EY1 T R IY2 Z'],
  "bayuk": ['B EY1 AH0 K'],
  "bayul": ['B EY1 AH0 L'],
  "bayus": ['B EY1 AH0 S'],
  "baywatch": ['B EY1 W AA0 CH'],
  "baz": ['B AE1 Z'],
  "bazaar": ['B AH0 Z AA1 R'],
  "bazaars": ['B AH0 Z AA1 R Z'],
  "bazan": ['B EY1 Z AH0 N'],
  "bazar": ['B AH0 Z AA1 R'],
  "baze": ['B EY1 Z'],
  "bazemore": ['B AA1 Z M AO0 R'],
  "bazhan": ['B AE1 Z AH0 N'],
  "bazile": ['B AA1 Z AY0 L', 'B AA1 Z IY0 L'],
  "bazinet": ['B AE1 Z IH0 N EH0 T'],
  "bazooka": ['B AH0 Z UW1 K AH0'],
  "bazookas": ['B AH0 Z UW1 K AH0 Z'],
  "bazzano": ['B AA0 T S AA1 N OW0'],
  "bazzell": ['B AE1 Z AH0 L'],
  "bazzle": ['B AE1 Z AH0 L'],
  "bbc": ['B IY2 B IY0 S IY1'],
  "bbc's": ['B IY2 B IY0 S IY1 S'],
  "bbq": ['B IY1 B IY0 K Y UW2', 'B AA1 R B IH0 K Y UW2'],
  "bc": ['B IY2 S IY1'],
  "be": ['B IY1', 'B IY0'],
  "be's": ['B IY1 Z'],
  "bea": ['B IY1'],
  "beaber": ['B IY1 B ER0'],
  "beabout": ['B AH0 B UW1 T'],
  "beach": ['B IY1 CH'],
  "beach's": ['B IY1 CH AH0 Z'],
  "beacham": ['B AH0 SH AE1 M', 'B IY1 CH AH0 M'],
  "beachball": ['B IY1 CH B AA2 L'],
  "beachballs": ['B IY1 CH B AA2 L Z'],
  "beachcomber": ['B IY1 CH K OW2 M ER0'],
  "beachcombers": ['B IY1 CH K OW2 M ER0 Z'],
  "beached": ['B IY1 CH T'],
  "beachem": ['B IY1 K IH0 M'],
  "beacher": ['B IY1 CH ER0'],
  "beaches": ['B IY1 CH AH0 Z', 'B IY1 CH IH0 Z'],
  "beachfront": ['B IY1 CH F R AA0 N T'],
  "beachfronts": ['B IY1 CH F R AA0 N T S'],
  "beachhead": ['B IY1 CH HH EH2 D'],
  "beachheads": ['B IY1 CH HH EH2 D Z'],
  "beaching": ['B IY1 CH IH0 NG'],
  "beachler": ['B IY1 K AH0 L ER0', 'B IY1 CH L ER0'],
  "beachside": ['B IY1 CH S AY2 D'],
  "beachum": ['B IY1 CH AH0 M'],
  "beachy": ['B IY1 CH IY0'],
  "beacom": ['B IY1 K AH0 M'],
  "beacon": ['B IY1 K AH0 N'],
  "beacons": ['B IY1 K AH0 N Z'],
  "bead": ['B IY1 D'],
  "beaded": ['B IY1 D IH0 D'],
  "beading": ['B IY1 D IH0 NG'],
  "beadle": ['B IY1 D AH0 L'],
  "beadles": ['B IY1 D AH0 L Z'],
  "beadling": ['B IY1 D L IH0 NG'],
  "beads": ['B IY1 D Z'],
  "beadwork": ['B IY1 D W ER2 K'],
  "beady": ['B IY1 D IY0'],
  "beagan": ['B AH0 G AE1 N'],
  "beagen": ['B IY1 G AH0 N'],
  "beagle": ['B IY1 G AH0 L'],
  "beagle's": ['B IY1 G AH0 L Z'],
  "beagles": ['B IY1 G AH0 L Z'],
  "beagley": ['B IY1 G L IY0'],
  "beahan": ['B IY1 HH AH0 N'],
  "beahm": ['B IY1 M'],
  "beaird": ['B IH1 R D'],
  "beak": ['B IY1 K'],
  "beaker": ['B IY1 K ER0'],
  "beakley": ['B IY1 K L IY0'],
  "beaklike": ['B IY1 K L AY2 K'],
  "beakman": ['B IY1 K M AH0 N'],
  "beakman's": ['B IY1 K M AH0 N Z'],
  "beaks": ['B IY1 K S'],
  "beal": ['B IY1 L'],
  "beale": ['B IY1 L'],
  "bealer": ['B IY1 L ER0'],
  "beales": ['B IY1 L Z'],
  "bealeton": ['B IY1 L T AH0 N'],
  "beall": ['B IY1 L'],
  "beals": ['B IY1 L Z'],
  "beam": ['B IY1 M'],
  "beam's": ['B IY1 M Z'],
  "beaman": ['B IY1 M AH0 N'],
  "beamed": ['B IY1 M D'],
  "beamer": ['B IY1 M ER0'],
  "beaming": ['B IY1 M IH0 NG'],
  "beamish": ['B IY1 M IH0 SH'],
  "beamon": ['B IY1 M AH0 N'],
  "beams": ['B IY1 M Z'],
  "bean": ['B IY1 N'],
  "bean's": ['B IY1 N Z'],
  "beanblossom": ['B IY1 N B L AH0 S AH0 M'],
  "beane": ['B IY1 N'],
  "beans": ['B IY1 N Z'],
  "beantown": ['B IY1 N T AW2 N'],
  "bear": ['B EH1 R'],
  "bear's": ['B EH1 R Z'],
  "beara": ['B EH1 R AH0'],
  "bearable": ['B EH1 R AH0 B AH0 L'],
  "bearably": ['B EH1 R AH0 B L IY0'],
  "bearce": ['B ER1 S'],
  "beard": ['B IH1 R D'],
  "bearded": ['B IH1 R D AH0 D'],
  "bearden": ['B IH1 R D AH0 N'],
  "beardmore": ['B IH1 R D M AO2 R'],
  "beards": ['B IH1 R D Z'],
  "beardslee": ['B IH1 R D Z L IY0'],
  "beardsley": ['B IH1 R D Z L IY0'],
  "beardstown": ['B IH1 R D S T AW2 N'],
  "beare": ['B IH1 R'],
  "bearer": ['B EH1 R ER0'],
  "bearers": ['B EH1 R ER0 Z'],
  "bearfield": ['B ER1 F IY0 L D'],
  "bearing": ['B EH1 R IH0 NG'],
  "bearings": ['B EH1 R IH0 NG Z'],
  "bearish": ['B EH1 R IH0 SH'],
  "bearishly": ['B EH1 R IH0 SH L IY0'],
  "bearishness": ['B EH1 R IH0 SH N AH0 S'],
  "bearman": ['B EH1 R M AH0 N'],
  "bears": ['B EH1 R Z'],
  "bears'": ['B EH1 R Z'],
  "bearse": ['B ER1 S'],
  "bearss": ['B ER1 S'],
  "bearup": ['B EH1 R AH0 P'],
  "beary": ['B IH1 R IY0'],
  "beas": ['B IY1 Z'],
  "beasley": ['B IY1 Z L IY0'],
  "beason": ['B IY1 Z AH0 N'],
  "beast": ['B IY1 S T'],
  "beastie": ['B IY1 S T IY0'],
  "beastly": ['B IY1 S T L IY2'],
  "beaston": ['B IY1 S T AH0 N'],
  "beasts": ['B IY1 S T S'],
  "beat": ['B IY1 T'],
  "beata": ['B IY0 AE1 T AH0'],
  "beaten": ['B IY1 T AH0 N'],
  "beater": ['B IY1 T ER0'],
  "beaters": ['B IY1 T ER0 Z'],
  "beatie": ['B IY1 T IY0'],
  "beatified": ['B IY0 AE1 T AH0 F AY2 D'],
  "beatify": ['B IY0 AE1 T AH0 F AY2'],
  "beating": ['B IY1 T IH0 NG'],
  "beatings": ['B IY1 T IH0 NG Z'],
  "beatle": ['B IY1 T AH0 L'],
  "beatles": ['B IY1 T AH0 L Z'],
  "beatles'": ['B IY1 T AH0 L Z'],
  "beatnik": ['B IY1 T N IH0 K'],
  "beatniks": ['B IY1 T N IH0 K S'],
  "beato": ['B IY0 AE1 T OW0'],
  "beaton": ['B IY1 T AH0 N'],
  "beatrice": ['B IY1 AH0 T R AH0 S', 'B IY1 AH0 T R IH0 S', 'B IY1 T R AH0 S', 'B IY1 T R IH0 S'],
  "beatrice's": ['B IY1 AH0 T R IH0 S IH0 Z', 'B IY1 T R IH0 S IH0 Z'],
  "beatrix": ['B IY1 T R IH0 K S', 'B IY1 AH0 T R IH0 K S'],
  "beats": ['B IY1 T S'],
  "beatson": ['B IY1 T S AH0 N'],
  "beattie": ['B IY1 T IY0'],
  "beatty": ['B IY1 T IY0', 'B EY1 T IY0'],
  "beaty": ['B IY1 T IY0'],
  "beau": ['B OW1'],
  "beaubien": ['B OW0 B Y EH1 N'],
  "beaucage": ['B OW1 K EY2 JH'],
  "beauce": ['B OW1 S'],
  "beauchaine": ['B OW2 SH EY1 N'],
  "beauchamp": ['B OW1 SH AA2 M P'],
  "beauchemin": ['B OW1 SH IH0 M AE0 N'],
  "beauchene": ['B OW2 SH EY1 N'],
  "beauchesne": ['B OW0 SH EH1 N'],
  "beaucoup": ['B OW2 K UW1'],
  "beaudet": ['B OW0 D EH1 T'],
  "beaudette": ['B OW2 D EH1 T'],
  "beaudin": ['B OW0 D AE1 N'],
  "beaudoin": ['B OW0 D OY1 N'],
  "beaudreau": ['B OW2 D R OW1'],
  "beaudry": ['B OW0 D R IY1'],
  "beauford": ['B OW0 F AO1 R D'],
  "beaufort": ['B OW1 F ER0 T'],
  "beaufrere": ['B OW2 F R EH1 R'],
  "beaujolais": ['B OW1 JH OW0 L EY2'],
  "beaulac": ['B OW0 L AE1 K'],
  "beaulieu": ['B OW2 L Y UH1'],
  "beaumier": ['B OW1 M IY0 ER0'],
  "beaumont": ['B OW1 M AO0 N T'],
  "beaupre": ['B OW0 P R EY1'],
  "beauregard": ['B OW1 R IH0 G AA2 R D', 'B AO1 R IH0 G AA2 R D'],
  "beausoleil": ['B OW1 S AH0 L EY0'],
  "beautician": ['B Y UW1 T IH2 SH AH0 N'],
  "beauties": ['B Y UW1 T IY0 Z'],
  "beautiful": ['B Y UW1 T AH0 F AH0 L'],
  "beautifullest": ['B Y UW1 T AH0 F AH0 L AH0 S T', 'B Y UW1 T AH0 F L AH0 S T'],
  "beautifully": ['B Y UW1 T AH0 F L IY0'],
  "beautify": ['B Y UW1 T IH0 F AY2'],
  "beauty": ['B Y UW1 T IY0'],
  "beauvais": ['B OW0 V EY1'],
  "beaux": ['B OW1'],
  "beavan": ['B EH1 V AH0 N'],
  "beaven": ['B IY1 V AH0 N'],
  "beaver": ['B IY1 V ER0'],
  "beavers": ['B IY1 V ER0 Z'],
  "beaverson": ['B IY1 V ER0 S AH0 N'],
  "beaverton": ['B IY1 V ER0 T AH0 N'],
  "beavin": ['B IY1 V IH0 N'],
  "beavis": ['B IY1 V AH0 S'],
  "beazer": ['B IY1 Z ER0'],
  "beazley": ['B IY1 Z L IY2'],
  "bebb": ['B EH1 B'],
  "bebber": ['B EH1 B ER0'],
  "bebbits": ['B EH1 B AH0 T S'],
  "bebe": ['B IY1 B IY0'],
  "bebe's": ['B IY1 B IY0 Z'],
  "bebear": ['B AH0 B IH1 R'],
  "bebeau": ['B IH0 B OW1'],
  "bebee": ['B EH1 B IY0'],
  "beber": ['B IY1 B ER0'],
  "bebitch": ['B EH1 B IH0 CH'],
  "bebop": ['B IY1 B AA2 P'],
  "bebout": ['B IH0 B UW1 T'],
  "bec": ['B EH1 K'],
  "becalm": ['B IH0 K AA1 M'],
  "becalmed": ['B IH0 K AA1 M D'],
  "became": ['B IH0 K EY1 M', 'B IY0 K EY1 M'],
  "because": ['B IH0 K AO1 Z', 'B IH0 K AH1 Z', 'B IH0 K AA1 Z', 'B IH0 K AH0 Z'],
  "becca": ['B EH1 K AA0'],
  "becerra": ['B EH0 CH EH1 R AA0'],
  "becerril": ['B EY0 S EH0 R IY1 L'],
  "bechard": ['B EH1 K ER0 D'],
  "becher": ['B EH1 K ER0'],
  "becherer": ['B EH1 K ER0 ER0'],
  "bechler": ['B EH1 K L ER0'],
  "becht": ['B EH1 K T'],
  "bechtel": ['B EH2 K T EH1 L'],
  "bechtelsville": ['B EH0 K T EH1 L Z V IH0 L'],
  "bechthold": ['B EH1 K TH OW0 L D'],
  "bechtle": ['B EH1 CH T AH0 L'],
  "bechtol": ['B EH1 K T AH0 L'],
  "bechtold": ['B EH1 K T OW2 L D'],
  "beck": ['B EH1 K'],
  "beck's": ['B EH1 K S'],
  "becka": ['B EH1 S K AH0'],
  "beckel": ['B EH1 K EH0 L'],
  "becker": ['B EH1 K ER0'],
  "becker's": ['B EH1 K ER0 Z'],
  "beckerman": ['B EH1 K ER0 M AH0 N'],
  "beckers": ['B EH1 K ER0 Z'],
  "beckert": ['B EH1 K ER0 T'],
  "becket": ['B EH1 K AH0 T'],
  "beckett": ['B EH1 K IH0 T'],
  "beckford": ['B EH1 K F AO0 R D'],
  "beckham": ['B EH1 K HH AE2 M'],
  "becki": ['B EH1 K IY0'],
  "beckie": ['B EH1 K IY0'],
  "beckius": ['B EH1 K IY0 IH0 S'],
  "beckler": ['B EH1 K L ER0'],
  "beckles": ['B EH1 K AH0 L Z'],
  "beckley": ['B EH1 K L IY0'],
  "becklund": ['B EH1 K L AH0 N D'],
  "beckman": ['B EH1 K M AH0 N'],
  "beckmann": ['B EH1 K M AH0 N'],
  "beckmeyer": ['B EH1 K M AY0 ER0'],
  "becknell": ['B EH1 K N EH2 L'],
  "beckner": ['B EH1 K N ER0'],
  "beckom": ['B EH1 K AA0 M'],
  "beckon": ['B EH1 K AH0 N'],
  "beckoned": ['B EH1 K AH0 N D'],
  "beckoning": ['B EH1 K AH0 N IH0 NG'],
  "beckons": ['B EH1 K AH0 N Z'],
  "becks": ['B EH1 K S'],
  "beckstead": ['B EH1 K S T EH2 D'],
  "beckstrand": ['B EH1 K S T R AE2 N D'],
  "beckstrom": ['B EH1 K S T R AA0 M'],
  "beckum": ['B EH1 K AH0 M'],
  "beckwith": ['B EH1 K W IH2 TH'],
  "beckwith's": ['B EH1 K W IH2 TH S'],
  "beckwitt": ['B EH1 K W IH2 T'],
  "beckworth": ['B EH1 K W ER2 TH'],
  "beckworth's": ['B EH1 K W ER2 TH S'],
  "becky": ['B EH1 K IY0'],
  "becnel": ['B EH1 K N AH0 L'],
  "beco": ['B IY1 K OW0'],
  "become": ['B IH0 K AH1 M'],
  "becomes": ['B IH0 K AH1 M Z'],
  "becoming": ['B IH0 K AH1 M IH0 NG'],
  "becor": ['B EH1 K AO2 R'],
  "becor's": ['B EH1 K AO2 R Z'],
  "becraft": ['B IY1 K R AE0 F T'],
  "becton": ['B EH1 K T AH0 N'],
  "becvar": ['B EH1 K V ER0'],
  "bed": ['B EH1 D'],
  "beda": ['B EY1 D AH0'],
  "bedard": ['B IH0 D AA1 R D'],
  "bedbug": ['B EH1 D B AH0 G'],
  "bedbugs": ['B EH1 D B AH0 G Z'],
  "bedchamber": ['B EH1 D CH EY2 M B ER0'],
  "bedded": ['B EH1 D IH0 D'],
  "bedding": ['B EH1 D IH0 NG'],
  "beddingfield": ['B EH1 D IH0 NG F IY2 L D'],
  "beddoe": ['B EH1 D OW0'],
  "beddors": ['B EH1 D ER0 Z'],
  "beddow": ['B EH1 D OW0'],
  "bede": ['B IY1 D'],
  "bedeck": ['B IH0 D EH1 K'],
  "bedecked": ['B IH0 D EH1 K T'],
  "bedel": ['B IH0 D EH1 L'],
  "bedell": ['B EH1 D AH0 L'],
  "bedenbaugh": ['B EH1 D IH0 N B AW0'],
  "bedevil": ['B IH0 D EH1 V AH0 L'],
  "bedeviled": ['B IH0 D EH1 V AH0 L D'],
  "bedfellow": ['B EH1 D F EH2 L OW0'],
  "bedfellows": ['B EH1 D F EH2 L OW0 Z'],
  "bedford": ['B EH1 D F ER0 D'],
  "bedgood": ['B EH1 D G UH2 D'],
  "bedient": ['B IY1 D Y IH0 N T'],
  "bedinger": ['B EH1 D IH0 NG ER0'],
  "bedingfield": ['B EH1 D IH0 NG F IY2 L D'],
  "bedizen": ['B IH2 D IY1 Z AH0 N'],
  "bedke": ['B EH1 D K IY0'],
  "bedlam": ['B EH1 D L AH0 M'],
  "bedlington": ['B EH1 D L IH0 NG T AH0 N'],
  "bedminster": ['B EH1 D M IH0 N S T ER0'],
  "bednar": ['B EH1 D N ER0'],
  "bednarczyk": ['B EH1 D N ER0 CH IH0 K'],
  "bednarek": ['B EH1 D N AA0 R EH0 K'],
  "bednarik": ['B EH1 D N AA0 R IH0 K'],
  "bednarski": ['B EH1 D N AA0 R S K IY0'],
  "bednarz": ['B EH1 D N AA0 R Z'],
  "bedner": ['B EH1 D N ER0'],
  "bednorz": ['B EH1 D N AO0 R Z'],
  "bedolla": ['B EH0 D OW1 L AH0'],
  "bedore": ['B EH1 D AO2 R'],
  "bedouin": ['B EH1 D OW0 AH0 N', 'B EH1 D UW0 IH2 N', 'B EH1 D AH0 W AH0 N'],
  "bedouins": ['B EH1 D OW0 AH0 N Z', 'B EH1 D UW0 IH2 N Z', 'B EH1 D AH0 W AH0 N Z'],
  "bedoya": ['B EY0 D OY1 AH0'],
  "bedpan": ['B EH1 D P AE2 N'],
  "bedpans": ['B EH1 D P AE2 N Z'],
  "bedraggle": ['B IH0 D R AE1 G AH0 L'],
  "bedraggled": ['B IH0 D R AE1 G AH0 L D'],
  "bedridden": ['B EH1 D R IH2 D AH0 N'],
  "bedrock": ['B EH1 D R AA2 K'],
  "bedroom": ['B EH1 D R UW2 M'],
  "bedrooms": ['B EH1 D R UW2 M Z'],
  "bedrosian": ['B EH0 D R AA1 ZH IH0 N', 'B AH0 D R OW1 Z IY0 AH0 N'],
  "beds": ['B EH1 D Z'],
  "bedsaul": ['B EH1 D S AO0 L'],
  "bedside": ['B EH1 D S AY2 D'],
  "bedsole": ['B EH1 D S OW2 L'],
  "bedspread": ['B EH1 D S P R EH2 D'],
  "bedstraw": ['B EH1 D S T R AO2'],
  "bedtime": ['B EH1 D T AY2 M'],
  "bedwell": ['B EH1 D W EH2 L'],
  "bee": ['B IY1'],
  "bee's": ['B IY1 Z'],
  "beeba's": ['B IY1 B AH0 Z'],
  "beebe": ['B IY1 B IY2'],
  "beebe's": ['B IY1 B IY2 Z'],
  "beebes": ['B IY1 B IY2 Z'],
  "beebower": ['B IY1 B OW2 ER0', 'B IY1 B AW2 ER0'],
  "beeby": ['B IY1 B IY0'],
  "beech": ['B IY1 CH'],
  "beecham": ['B IY1 CH AH0 M'],
  "beecham's": ['B IY1 CH AH0 M Z'],
  "beechcraft": ['B IY1 CH K R AE2 F T'],
  "beecher": ['B IY1 CH ER0'],
  "beechler": ['B IY1 CH L ER0'],
  "beechwood": ['B IY1 CH W UH2 D'],
  "beechy": ['B IY1 CH IY0'],
  "beeck": ['B IY1 K'],
  "beecroft": ['B IY1 K R AO2 F T'],
  "beede": ['B IY1 D'],
  "beedie": ['B IY1 D IY0'],
  "beedle": ['B IY1 D AH0 L'],
  "beedy": ['B IY1 D IY0'],
  "beef": ['B IY1 F'],
  "beefeater": ['B IY1 F IY2 T ER0'],
  "beefed": ['B IY1 F T'],
  "beeferman": ['B IY1 F ER0 M AH0 N'],
  "beefing": ['B IY1 F IH0 NG'],
  "beefs": ['B IY1 F S'],
  "beefsteak": ['B IY1 F S T EY2 K'],
  "beefy": ['B IY1 F IY0'],
  "beeghly": ['B IY1 G L IY0'],
  "beegle": ['B IY1 G AH0 L'],
  "beehive": ['B IY1 HH AY2 V'],
  "beehler": ['B IY1 L ER0'],
  "beek": ['B IY1 K'],
  "beekeeper": ['B IY1 K IY2 P ER0'],
  "beekeepers": ['B IY1 K IY2 P ER0 Z'],
  "beekeeping": ['B IY1 K IY2 P IH0 NG'],
  "beeker": ['B IY1 K ER0'],
  "beekman": ['B IY1 K M AH0 N'],
  "beeks": ['B IY1 K S'],
  "beel": ['B IY1 L'],
  "beeler": ['B IY1 L ER0'],
  "beem": ['B IY1 M'],
  "beeman": ['B IY1 M AH0 N'],
  "beemer": ['B IY1 M ER0'],
  "been": ['B IH1 N', 'B AH0 N', 'B IH0 N'],
  "beene": ['B IY1 N'],
  "beeney": ['B IY1 N IY0'],
  "beenken": ['B IY1 NG K AH0 N'],
  "beens": ['B IH1 N Z'],
  "beep": ['B IY1 P'],
  "beeped": ['B IY1 P T'],
  "beeper": ['B IY1 P ER0'],
  "beepers": ['B IY1 P ER0 Z'],
  "beeping": ['B IY1 P IH0 NG'],
  "beeps": ['B IY1 P S'],
  "beer": ['B IH1 R'],
  "beer's": ['B IY1 R Z'],
  "beerbower": ['B IH1 R B OW0 ER0'],
  "beerman": ['B IH1 R M AH0 N'],
  "beers": ['B IH1 R Z'],
  "beers'": ['B IH1 R Z'],
  "beers's": ['B IH1 R Z IH0 Z'],
  "beery": ['B IH1 R IY0'],
  "bees": ['B IY1 Z'],
  "beese": ['B IY1 Z'],
  "beesley": ['B IY1 Z L IY0'],
  "beeson": ['B IY1 Z AH0 N'],
  "beet": ['B IY1 T'],
  "beethoven": ['B EY1 T OW0 V AH0 N'],
  "beethoven's": ['B EY1 T OW0 V AH0 N Z'],
  "beethovenian": ['B EY2 T OW0 V IY1 N IY0 AH0 N'],
  "beetle": ['B IY1 T AH0 L'],
  "beetlejuice": ['B IY1 T AH0 L JH UW2 S'],
  "beetles": ['B IY1 T AH0 L Z'],
  "beeton": ['B IY1 T AH0 N'],
  "beets": ['B IY1 T S'],
  "beever": ['B IY1 V ER0'],
  "beevers": ['B IY1 V ER0 Z'],
  "beezley": ['B IY1 Z L IY0'],
  "befall": ['B IH0 F AO1 L'],
  "befallen": ['B AH0 F AA1 L AH0 N'],
  "befalls": ['B IH0 F AO1 L Z'],
  "befalto": ['B AH0 F AA1 L T OW0'],
  "befell": ['B IH0 F EH1 L'],
  "beffa": ['B IH2 F AA1'],
  "befit": ['B IH0 F IH1 T'],
  "befits": ['B IH0 F IH1 T S'],
  "befitting": ['B IH0 F IH1 T IH0 NG'],
  "before": ['B IH0 F AO1 R', 'B IY2 F AO1 R'],
  "beforehand": ['B IH0 F AO1 R HH AE2 N D'],
  "befort": ['B EH1 F ER0 T'],
  "befriend": ['B IH0 F R EH1 N D'],
  "befriended": ['B IH0 F R EH1 N D IH0 D'],
  "befriending": ['B IH0 F R EH1 N D IH0 NG'],
  "befriends": ['B IH0 F R EH1 N D Z'],
  "befuddle": ['B IH0 F AH1 D AH0 L'],
  "befuddled": ['B IH0 F AH1 D AH0 L D'],
  "befuddles": ['B IH0 F AH1 D AH0 L Z'],
  "beg": ['B EH1 G'],
  "begajah": ['B EH0 G AA1 JH AH0'],
  "begala": ['B EH0 G AA1 L AH0'],
  "begala's": ['B EH0 G AA1 L AH0 Z'],
  "began": ['B IH0 G AE1 N', 'B IY0 G AE1 N'],
  "begat": ['B IH0 G AE1 T'],
  "begay": ['B EH1 G EY0'],
  "begel": ['B EY1 G AH0 L'],
  "begelman": ['B EY1 G AH0 L M AH0 N'],
  "begeman": ['B IY1 G M AH0 N'],
  "beger": ['B EH1 G ER0'],
  "beget": ['B IH0 G EH1 T'],
  "begets": ['B IH0 G EH1 T S'],
  "begetting": ['B IH0 G EH1 T IH0 NG'],
  "begg": ['B EH1 G'],
  "beggar": ['B EH1 G ER0'],
  "beggars": ['B EH1 G ER0 Z'],
  "begged": ['B EH1 G D'],
  "begging": ['B EH1 G IH0 NG'],
  "beggs": ['B EH1 G Z'],
  "beghin": ['B EH1 G IH0 N'],
  "begin": ['B IH0 G IH1 N'],
  "begin's": ['B IH0 G IH1 N Z'],
  "beginner": ['B IH0 G IH1 N ER0'],
  "beginner's": ['B IH0 G IH1 N ER0 Z'],
  "beginners": ['B IH0 G IH1 N ER0 Z'],
  "beginning": ['B IH0 G IH1 N IH0 NG'],
  "beginnings": ['B IH0 G IH1 N IH0 NG Z'],
  "begins": ['B IH0 G IH1 N Z'],
  "begleiter": ['B EH1 G L AY2 T ER0'],
  "begleiter's": ['B EH1 G L AY2 T ER0 Z'],
  "begley": ['B EH1 G L IY0'],
  "begnaud": ['B IH0 G N OW1'],
  "begnoche": ['B EH0 G N OW1 K IY0'],
  "begonia": ['B IH0 G OW1 N Y AH0'],
  "begonias": ['B IH0 G OW1 N Y AH0 Z'],
  "begot": ['B IY0 G AO1 T'],
  "begotten": ['B IY0 G AO1 T IH0 N'],
  "begrudge": ['B IH0 G R AH1 JH'],
  "begs": ['B EH1 G Z'],
  "begue": ['B EY1 G'],
  "beguelin": ['B EY1 G AH0 L IH0 N'],
  "beguile": ['B IH0 G AY1 L'],
  "beguiled": ['B IH0 G AY1 L D'],
  "beguiling": ['B IH0 G AY1 L IH0 NG'],
  "begun": ['B IH0 G AH1 N', 'B EY1 G AH0 N'],
  "behalf": ['B IH0 HH AE1 F'],
  "behan": ['B EH1 HH AH0 N'],
  "behar": ['B EH1 HH ER0'],
  "behave": ['B IH0 HH EY1 V'],
  "behaved": ['B IH0 HH EY1 V D'],
  "behaves": ['B IH0 HH EY1 V Z'],
  "behaving": ['B IH0 HH EY1 V IH0 NG'],
  "behavior": ['B IH0 HH EY1 V Y ER0'],
  "behavioral": ['B IH0 HH EY1 V Y ER0 AH0 L', 'B IY0 HH EY1 V Y ER0 AH0 L'],
  "behaviorally": ['B IY0 HH EY1 V Y ER0 AH0 L IY0'],
  "behaviorist": ['B IH0 HH EY1 V Y ER0 IH0 S T'],
  "behaviorists": ['B IH0 HH EY1 V Y ER0 IH0 S T S'],
  "behaviors": ['B IH0 HH EY1 V Y ER0 Z'],
  "behaviour": ['B IH0 HH EY1 V Y ER0'],
  "behaviours": ['B IH0 HH EY1 V Y ER0 Z'],
  "behead": ['B IH0 HH EH1 D', 'B IY0 HH EH1 D'],
  "beheaded": ['B IH0 HH EH1 D IH0 D'],
  "beheading": ['B IH0 HH EH1 D IH0 NG'],
  "beheadings": ['B IH0 HH EH1 D IH0 NG Z'],
  "beheld": ['B IY0 HH EH1 L D'],
  "beheler": ['B EH1 HH AH0 L ER0'],
  "behemoth": ['B AH0 HH IY1 M AH0 TH', 'B IY1 HH AH0 M AH0 TH'],
  "behemoths": ['B IH0 HH IY1 M AH0 TH S'],
  "behest": ['B IH0 HH EH1 S T'],
  "behind": ['B IH0 HH AY1 N D'],
  "behinds": ['B IH0 HH AY1 N D Z'],
  "behl": ['B EH1 L'],
  "behle": ['B EH1 HH AH0 L'],
  "behler": ['B EH1 L ER0'],
  "behling": ['B EH1 L IH0 NG'],
  "behlke": ['B EH1 L K'],
  "behm": ['B EH1 M'],
  "behmer": ['B EH1 M ER0'],
  "behn": ['B EH1 N'],
  "behne": ['B EH1 N'],
  "behner": ['B EH1 N ER0'],
  "behney": ['B EH1 N IY0'],
  "behning": ['B EH1 N IH0 NG'],
  "behnke": ['B EH1 NG K'],
  "behnken": ['B EH1 NG K AH0 N'],
  "behof": ['B IY1 HH AA0 F'],
  "behoff": ['B IY1 HH AA0 F'],
  "behold": ['B IH0 HH OW1 L D'],
  "beholden": ['B IH0 HH OW1 L D AH0 N'],
  "beholder": ['B IY0 HH OW1 L D ER0'],
  "beholding": ['B IH0 HH OW1 L D IH0 NG'],
  "behoove": ['B IH0 HH UW1 V'],
  "behooves": ['B IH0 HH UW1 V Z'],
  "behr": ['B EH1 R'],
  "behrend": ['B EH1 R EH0 N D'],
  "behrends": ['B EH1 R EH0 N D Z'],
  "behrendt": ['B EH1 R IH0 N T'],
  "behrens": ['B EH1 R AH0 N Z'],
  "behring": ['B EH1 R IH0 NG'],
  "behringer": ['B EH1 R IH0 NG ER0'],
  "behringwerke": ['B EH1 R IH2 NG W ER0 K'],
  "behrle": ['B EH1 R AH0 L'],
  "behrman": ['B EH1 R M AH0 N'],
  "behrmann": ['B EH1 R M AH0 N'],
  "behrns": ['B EH1 R N Z'],
  "behunin": ['B EH1 HH AH0 N IH0 N'],
  "behymer": ['B EH1 HH AY2 M ER0'],
  "beidaihe": ['B AY2 D EY1 HH IY0'],
  "beidleman": ['B AY1 D AH0 L M AH0 N'],
  "beidler": ['B AY1 D AH0 L ER0', 'B AY1 D L ER0'],
  "beier": ['B AY1 ER0'],
  "beierle": ['B AY1 ER0 AH0 L'],
  "beiersdorf": ['B AY1 R Z D AO2 R F'],
  "beige": ['B EY1 ZH'],
  "beige's": ['B EY1 ZH AH0 Z'],
  "beigel": ['B AY1 G AH0 L'],
  "beiges": ['B EY1 ZH AH0 Z'],
  "beighley": ['B EY1 G L IY0'],
  "beightol": ['B EY1 T AH0 L'],
  "beijer": ['B AY1 R'],
  "beijing": ['B EY2 ZH IH1 NG'],
  "beijing's": ['B EY2 ZH IH1 NG Z'],
  "beil": ['B IY1 L'],
  "beilenson": ['B AY1 L AH0 N S AH0 N'],
  "beiler": ['B AY1 L ER0'],
  "beilfuss": ['B AY1 L F AH0 S'],
  "beilin": ['B EH1 L IH0 N', 'B EY1 L IH0 N'],
  "beilke": ['B IY1 L K'],
  "beilman": ['B AY1 L M AH0 N'],
  "beim": ['B AY1 M'],
  "bein": ['B IY1 N'],
  "bein'": ['B IY1 IH0 N'],
  "beine": ['B IY1 N'],
  "being": ['B IY1 IH0 NG'],
  "being's": ['B IY1 IH0 NG Z'],
  "beings": ['B IY1 IH0 NG Z'],
  "beiny": ['B AY1 N IY0'],
  "beira": ['B EY1 R AH0'],
  "beirne": ['B IH1 R N'],
  "beirut": ['B EY0 R UW1 T'],
  "beirut's": ['B EY0 R UW1 T S'],
  "beisel": ['B AY1 S AH0 L'],
  "beiser": ['B AY1 S ER0'],
  "beisner": ['B AY1 S N ER0'],
  "beissel": ['B AY1 S AH0 L'],
  "beiswenger": ['B AY1 S W IH0 N JH ER0'],
  "beit": ['B EY1 T', 'B AY1 T'],
  "beitel": ['B AY1 T AH0 L'],
  "beiter": ['B AY1 T ER0'],
  "beitler": ['B AY1 T AH0 L ER0', 'B AY1 T L ER0'],
  "beitz": ['B IY1 T S'],
  "beitzel": ['B AY1 T Z AH0 L'],
  "bejar": ['B EY0 Y AA1 R'],
  "bejarano": ['B EY0 Y AA0 R AA1 N OW0'],
  "beji": ['B AE1 ZH IY2'],
  "bekaa": ['B EH0 K AA1', 'B AH0 K AA1'],
  "bekaert": ['B AH0 K EH1 R T'],
  "beker": ['B EH1 K ER0'],
  "beker's": ['B EH1 K ER0 Z'],
  "bekins": ['B IY1 K IH0 N Z'],
  "bekker": ['B EH1 K ER0'],
  "bekki": ['B EH1 K IY0'],
  "bel": ['B EH1 L'],
  "bel'c": ['B EH1 L K'],
  "bela": ['B EH1 L AH0'],
  "belabor": ['B IH0 L EY1 B ER0'],
  "belabored": ['B IH0 L EY1 B ER0 D'],
  "belabores": ['B IH0 L EY1 B ER0 Z'],
  "belaboring": ['B IH0 L EY1 B ER0 IH0 NG'],
  "belabors": ['B IH0 L EY1 B ER0 Z'],
  "beladur": ['B EH2 L AH0 D UH1 R'],
  "beladur's": ['B EH2 L AH0 D UH1 R Z'],
  "belafonte": ['B EH2 L AH0 F AA1 N T IY0'],
  "belair": ['B IH0 L EH1 R', 'B EH0 L EH1 R'],
  "belaire": ['B AH0 L EH1 R'],
  "belak": ['B EH1 L AH0 K'],
  "beland": ['B EH1 L AH0 N D'],
  "belanger": ['B EH1 L AH0 NG ER0'],
  "belanoff": ['B EH1 L AH0 N AO0 F'],
  "belarus": ['B EH0 L AA1 R AH0 S'],
  "belasco": ['B EH0 L AA1 S K OW0'],
  "belate": ['B IH0 L EY1 T'],
  "belated": ['B IH0 L EY1 T IH0 D'],
  "belatedly": ['B IH0 L EY1 T AH0 D L IY0'],
  "belcastro": ['B EH0 L K AE1 S T R OW0'],
  "belch": ['B EH1 L CH'],
  "belched": ['B EH1 L CH T'],
  "belcher": ['B EH1 L CH ER0'],
  "belching": ['B EH1 L CH IH0 NG'],
  "belcourt": ['B EH1 L K AO2 R T'],
  "belden": ['B EH1 L D AH0 N'],
  "beldin": ['B EH1 L D IH0 N'],
  "belding": ['B EH1 L D IH0 NG'],
  "beldock": ['B EH1 L D AA2 K'],
  "beldon": ['B EH1 L D AH0 N'],
  "beleaguer": ['B IH0 L IY1 G ER0'],
  "beleaguered": ['B IH0 L IY1 G ER0 D'],
  "beleaguering": ['B IH0 L IY1 G ER0 IH0 NG'],
  "belefiville": ['B AH0 L EH1 F AH0 V IH2 L'],
  "belem": ['B EH2 L EH1 M'],
  "belen": ['B EH1 L AH0 N'],
  "belet": ['B EH1 L AH0 T'],
  "belew": ['B EH1 L UW0'],
  "belfast": ['B EH1 L F AE2 S T'],
  "belfer": ['B EH1 L F ER0'],
  "belfield": ['B EH1 L F IY2 L D'],
  "belfiore": ['B EH0 L F IY0 AO1 R IY0'],
  "belflower": ['B EH1 L F L AW2 ER0'],
  "belford": ['B EH1 L F ER0 D'],
  "belfry": ['B EH1 L F R IY0'],
  "belgacom": ['B EH1 L G AH0 K AA0 M', 'B EH1 L JH AH0 K AA0 M'],
  "belgard": ['B EH0 L G AA1 R D'],
  "belgarde": ['B EH0 L G AA1 R D IY0'],
  "belge": ['B EH1 L JH IY0'],
  "belger": ['B EH1 L G ER0'],
  "belgian": ['B EH1 L JH AH0 N'],
  "belgians": ['B EH1 L JH AH0 N Z'],
  "belgique": ['B EH0 L ZH IY1 K'],
  "belgique's": ['B EH0 L JH IY1 K S'],
  "belgium": ['B EH1 L JH AH0 M'],
  "belgium's": ['B EH1 L JH AH0 M Z'],
  "belgo": ['B EH1 L G OW2'],
  "belgrade": ['B EH1 L G R EY0 D', 'B EH1 L G R AA2 D'],
  "belgrade's": ['B EH1 L G R EY0 D Z', 'B EH1 L G R AA2 D Z'],
  "belgrave": ['B EH1 L G R EY2 V'],
  "beli": ['B EH1 L IY0'],
  "belich": ['B EH1 L IH0 K'],
  "belie": ['B IH0 L AY1'],
  "belied": ['B IH0 L AY1 D'],
  "belief": ['B IH0 L IY1 F'],
  "beliefs": ['B IH0 L IY1 F S'],
  "belier": ['B EH1 L Y ER0'],
  "belies": ['B IH0 L AY1 Z'],
  "believability": ['B AH0 L IY2 V AH0 B IH1 L IH0 T IY0'],
  "believable": ['B AH0 L IY1 V AH0 B AH0 L'],
  "believe": ['B IH0 L IY1 V'],
  "believed": ['B IH0 L IY1 V D'],
  "believer": ['B AH0 L IY1 V ER0'],
  "believers": ['B AH0 L IY1 V ER0 Z'],
  "believes": ['B IH0 L IY1 V Z'],
  "believing": ['B IH0 L IY1 V IH0 NG'],
  "beligerence": ['B AH0 L IH1 JH ER0 AH0 N S'],
  "beligerent": ['B AH0 L IH1 JH ER0 AH0 N T'],
  "belin": ['B EH1 L IH0 N'],
  "belinda": ['B AH0 L IH1 N D AH0'],
  "belinsky": ['B IH0 L IH1 N S K IY0'],
  "belisle": ['B EH0 L AY1 AH0 L'],
  "belittle": ['B IH0 L IH1 T AH0 L'],
  "belittled": ['B IH0 L IH1 T AH0 L D'],
  "belittles": ['B IH0 L IH1 T AH0 L Z'],
  "belittling": ['B IH0 L IH1 T AH0 L IH0 NG', 'B IH0 L IH1 T L IH0 NG'],
  "belitz": ['B EH1 L IH0 T S'],
  "beliveau": ['B EH1 L IH0 V OW2'],
  "belize": ['B EH0 L IY1 Z'],
  "belk": ['B EH1 L K'],
  "belka": ['B EH1 L K AH0'],
  "belkacem": ['B EH1 L K AH0 S AH0 M'],
  "belke": ['B EH1 L K'],
  "belkin": ['B EH1 L K IH0 N'],
  "belknap": ['B EH1 L N AE0 P'],
  "belko": ['B EH1 L K OW0'],
  "bell": ['B EH1 L'],
  "bell's": ['B EH1 L Z'],
  "bella": ['B EH1 L AH0'],
  "bellah": ['B EH1 L AH0'],
  "bellamy": ['B EH1 L AH0 M IY0'],
  "bellanca": ['B EH0 L AA1 N K AH0'],
  "belland": ['B EH1 L AH0 N D'],
  "bellanger": ['B EH1 L AE2 NG G ER0'],
  "bellante": ['B EH0 L AA1 N T IY0'],
  "bellantoni": ['B EH0 L AA0 N T OW1 N IY0'],
  "bellar": ['B EH1 L ER0'],
  "bellard": ['B IH0 L AA1 R D'],
  "bellas": ['B EH1 L AH0 Z'],
  "bellavance": ['B EH0 L AA1 V AH0 N S'],
  "bellavia": ['B EH0 L AA1 V IY0 AH0'],
  "bellavista": ['B EH2 L AH0 V IH1 S T AH0'],
  "bellboy": ['B EH1 L B OY2'],
  "bellcore": ['B EH1 L K AO2 R'],
  "bellcore's": ['B EH1 L K AO2 R Z'],
  "belle": ['B EH1 L'],
  "belleau": ['B IH0 L OW1'],
  "bellefeuille": ['B EH2 L AH0 F IY0 UW1 L'],
  "bellemare": ['B EH2 L AH0 M EH1 R'],
  "beller": ['B EH1 L ER0'],
  "bellerose": ['B EH1 L ER0 AH0 Z'],
  "belles": ['B EH1 L Z'],
  "belleville": ['B AH0 L V IH1 L'],
  "bellevue": ['B EH1 L V Y UW2'],
  "bellew": ['B IH0 L UW1'],
  "bellflower": ['B EH1 L F L AW2 ER0'],
  "bellflowers": ['B EH1 L F L AW2 ER0 Z'],
  "bellhop": ['B EH1 L HH AA2 P'],
  "bellhops": ['B EH1 L HH AA2 P S'],
  "belli": ['B EH1 L IY0'],
  "bellicose": ['B EH1 L AH0 K OW2 S'],
  "bellied": ['B EH1 L IY0 D'],
  "bellies": ['B EH1 L IY0 Z'],
  "belligerence": ['B AH0 L IH1 JH ER0 AH0 N S'],
  "belligerent": ['B AH0 L IH1 JH ER0 AH0 N T'],
  "belligerents": ['B AH0 L IH1 JH ER0 AH0 N T S'],
  "bellin": ['B EH1 L IH0 N'],
  "bellina": ['B EH0 L IY1 N AH0'],
  "belling": ['B EH1 L IH0 NG'],
  "bellinger": ['B EH1 L IH0 NG ER0'],
  "bellingham": ['B EH1 L IH0 NG HH AE2 M'],
  "bellinghausen": ['B EH1 L IH0 NG HH AW2 Z AH0 N'],
  "bellini": ['B EH0 L IY1 N IY0'],
  "bellini's": ['B EH0 L IY1 N IY0 Z'],
  "bellino": ['B EH0 L IY1 N OW0'],
  "bellis": ['B EH1 L IH0 S'],
  "bellissimo": ['B EH0 L IY0 S IY1 M OW0'],
  "belliveau": ['B EH1 L IH0 V OW2'],
  "bellizzi": ['B EH0 L IY1 T S IY0'],
  "bellm": ['B EH1 L M'],
  "bellman": ['B EH1 L M AH0 N'],
  "bellmon": ['B EH1 L M AH0 N'],
  "bellmore": ['B EH1 L M AO0 R'],
  "bello": ['B EH1 L OW0'],
  "bellomo": ['B EH0 L OW1 M OW0'],
  "bellomy": ['B EH1 L AH0 M IY0'],
  "bellon": ['B EH1 L AH0 N'],
  "bellone": ['B EH0 L OW1 N IY0'],
  "bellotti": ['B EH0 L OW1 T IY0'],
  "bellow": ['B EH1 L OW0'],
  "bellow's": ['B EH1 L OW0 Z'],
  "bellowed": ['B EH1 L OW0 D'],
  "bellowing": ['B EH1 L OW0 IH0 NG'],
  "bellows": ['B EH1 L OW0 Z'],
  "bellrose": ['B EH1 L R OW2 Z'],
  "bells": ['B EH1 L Z'],
  "bells'": ['B EH1 L Z'],
  "bellsouth": ['B EH1 L S AW2 TH'],
  "bellsouth's": ['B EH1 L S AW2 TH S'],
  "bellucci": ['B EH0 L UW1 CH IY0'],
  "bellum": ['B EH1 L AH0 M'],
  "belluomini": ['B EH2 L W OW0 M IY1 N IY0'],
  "bellville": ['B EH1 L V IH2 L'],
  "bellwether": ['B EH1 L W EH2 DH ER0'],
  "bellwethers": ['B EH1 L W EH2 DH ER0 Z'],
  "bellwood": ['B EH1 L W UH2 D'],
  "belly": ['B EH1 L IY0'],
  "bellyache": ['B EH1 L IY0 EY2 K'],
  "bellyaching": ['B EH1 L IY2 EY1 K IH2 NG'],
  "belman": ['B EH1 L M AH0 N'],
  "belmont": ['B EH1 L M AA2 N T'],
  "belmonte": ['B EH0 L M AA1 N T IY0'],
  "belmore": ['B EH1 L M AO0 R'],
  "belnap": ['B EH1 L N AE2 P'],
  "belnick": ['B EH1 L N IH0 K'],
  "belo": ['B EH1 L OW0'],
  "beloff": ['B EH1 L AO2 F'],
  "beloit": ['B IH0 L OY1 T'],
  "belong": ['B IH0 L AO1 NG'],
  "belonged": ['B IH0 L AO1 NG D'],
  "belongia": ['B EH0 L OW1 N JH AH0'],
  "belonging": ['B IH0 L AO1 NG IH0 NG'],
  "belongings": ['B IH0 L AO1 NG IH0 NG Z'],
  "belongs": ['B IH0 L AO1 NG Z'],
  "belote": ['B EH0 L OW1 T IY0'],
  "belous": ['B EH1 L AH0 S'],
  "belov": ['B EH1 L AA0 V'],
  "belove": ['B IH0 L AH1 V'],
  "beloved": ['B IH0 L AH1 V D', 'B IH0 L AH1 V AH0 D'],
  "below": ['B IH0 L OW1', 'B IY0 L OW1'],
  "bels": ['B EH1 L Z'],
  "belser": ['B EH1 L S ER0'],
  "belshaw": ['B EH1 L SH AO2'],
  "belshe": ['B EH1 L SH'],
  "belsito": ['B EH0 L S IY1 T OW0'],
  "belsky": ['B EH1 L S K IY0'],
  "belson": ['B EH1 L S AH0 N'],
  "belt": ['B EH1 L T'],
  "belt's": ['B EH1 L T S'],
  "belted": ['B EH1 L T AH0 D', 'B EH1 L T IH0 D'],
  "belter": ['B EH1 L T ER0'],
  "belth": ['B EH1 L TH'],
  "belting": ['B EH1 L T IH0 NG'],
  "belton": ['B EH1 L T AH0 N'],
  "beltram": ['B EH1 L T R AE2 M'],
  "beltran": ['B EH1 L T R AH0 N'],
  "belts": ['B EH1 L T S'],
  "beltsville": ['B EH1 L T S V IH2 L'],
  "beltway": ['B EH1 L T W EY2'],
  "beltz": ['B EH1 L T S'],
  "belue": ['B EH1 L Y UW0'],
  "beluga": ['B IH0 L UW1 G AH0'],
  "belushi": ['B EH0 L UW1 SH IY0'],
  "belva": ['B EY1 L V AH0'],
  "belveal": ['B EH1 L V AH0 L'],
  "belvedere": ['B EH2 L V AH0 D IH1 R', 'B EH2 L V IH0 D IH1 R'],
  "belvia": ['B EH1 L V IY0 AH0'],
  "belvidere": ['B EH1 L V IH0 D IH2 R'],
  "belville": ['B EH1 L V IH2 L'],
  "belvin": ['B EH1 L V IH0 N'],
  "belvoir": ['B EH0 L V W AA1 R'],
  "bely": ['B AH0 L AY1'],
  "belyea": ['B EH0 L IY1 AH0'],
  "belyeu": ['B EH2 L IY0 UW1'],
  "belying": ['B IH0 L AY1 IH0 NG'],
  "belz": ['B EH1 L Z'],
  "belzberg": ['B EH1 L T S B ER0 G'],
  "belzbergs": ['B EH1 L T S B ER0 G Z'],
  "belzbergs'": ['B EH1 L Z B ER0 G Z'],
  "belzer": ['B EH1 L Z ER0'],
  "bem": ['B EH1 M'],
  "beman": ['B IY1 M AH0 N'],
  "bembenek": ['B EH1 M B IH0 N AH0 K'],
  "bembry": ['B EH1 M B R IY0'],
  "bement": ['B IY1 M AH0 N T'],
  "bemidji": ['B AH0 M IH1 D JH IY0'],
  "bemis": ['B IY1 M AH0 S'],
  "bemiss": ['B EH1 M IH0 S'],
  "bemoan": ['B IH0 M OW1 N'],
  "bemoaned": ['B IH0 M OW1 N D'],
  "bemoaning": ['B IH0 M OW1 N IH0 NG'],
  "bemoans": ['B IH0 M OW1 N Z'],
  "bemuse": ['B IH0 M Y UW1 Z'],
  "bemused": ['B IH0 M Y UW1 Z D'],
  "bemusement": ['B EH1 M Y UW0 S M AH0 N T'],
  "ben": ['B EH1 N'],
  "ben's": ['B EH1 N Z'],
  "bena": ['B EH1 N AH0'],
  "benackova": ['B EH2 N AH0 K OW1 V AH0'],
  "benak": ['B EH1 N AH0 K'],
  "benami": ['B EH2 N AH0 M IY1'],
  "benanty": ['B EH0 N AA1 N T IY0'],
  "benard": ['B IH0 N AA1 R D'],
  "benassi": ['B EH0 N AA1 S IY0'],
  "benasuli": ['B EH2 N AH0 S UW1 L IY0'],
  "benatar": ['B EH1 N AH0 T ER0'],
  "benavente": ['B EH0 N AA0 V EH1 N T IY0'],
  "benavides": ['B EY0 N AA0 V IY1 D EH0 S'],
  "benavidez": ['B EY0 N AA0 V IY1 D EH0 Z'],
  "benazir": ['B EH1 N AH0 Z IH2 R'],
  "benbow": ['B EH1 N B OW0'],
  "benbrook": ['B EH1 N B R UH2 K'],
  "bence": ['B EH1 N S'],
  "bench": ['B EH1 N CH'],
  "bencher": ['B EH1 N CH ER0'],
  "benchers": ['B EH1 N CH ER0 Z'],
  "benches": ['B EH1 N CH IH0 Z'],
  "benchley": ['B EH1 N CH L IY0'],
  "benchmark": ['B EH1 N CH M AA2 R K'],
  "benchmark's": ['B EH1 N CH M AA2 R K S'],
  "benchmarks": ['B EH1 N CH M AA2 R K S'],
  "bencivenga": ['B EH0 N CH IY0 V EH1 NG G AH0'],
  "bencomo": ['B EH0 N K OW1 M OW0'],
  "bencsik": ['B EH1 NG K S IH0 K'],
  "bend": ['B EH1 N D'],
  "benda": ['B EH1 N D AH0'],
  "bendall": ['B EH1 N D AH0 L'],
  "bendectin": ['B EH0 N D EH1 K T IH0 N'],
  "bended": ['B EH1 N D IH0 D'],
  "bendel": ['B EH1 N D AH0 L', 'B EH2 N D EH1 L'],
  "bendele": ['B EH1 N D AH0 L'],
  "bender": ['B EH1 N D ER0'],
  "bender's": ['B EH1 N D ER0 Z'],
  "benders": ['B EH1 N D ER0 Z'],
  "bendick": ['B EH1 N D IH0 K'],
  "bendickson": ['B EH1 N D IH0 K S AH0 N'],
  "bendig": ['B EH1 N D IH0 G'],
  "bending": ['B EH1 N D IH0 NG'],
  "bendix": ['B EH1 N D IH0 K S'],
  "bendixen": ['B IH0 N D IH1 K S AH0 N'],
  "bendler": ['B EH1 N D L ER0'],
  "bendorf": ['B EH1 N D AO0 R F'],
  "bends": ['B EH1 N D Z'],
  "bendt": ['B EH1 N T'],
  "bendure": ['B EY0 N D UH1 R EY0'],
  "bendy": ['B EH1 N D IY0'],
  "bene": ['B EH1 N AH0'],
  "beneath": ['B IH0 N IY1 TH'],
  "benecke": ['B EH1 N AH0 K'],
  "benedek": ['B EH1 N AH0 D IH0 K'],
  "benedetti": ['B EH2 N AH0 D EH1 T IY0'],
  "benedetti's": ['B EH2 N AH0 D EH1 T IY0 Z'],
  "benedetto": ['B IH0 N AH0 D EH1 T OW0'],
  "benedick": ['B EH1 N AH0 D IH0 K'],
  "benedict": ['B EH1 N AH0 D IH2 K T'],
  "benedicta": ['B EH1 N AH0 D IY0 K T AH0'],
  "benedictine": ['B EH2 N AH0 D IH1 K T IY0 N'],
  "benedictine's": ['B EH2 N AH0 D IH1 K T IY0 N Z'],
  "benedictines": ['B EH2 N AH0 D IH1 K T IY0 N Z'],
  "benediction": ['B EH2 N AH0 D IH1 K SH AH0 N'],
  "benedikt": ['B EH1 N AH0 D IH0 K T'],
  "benedix": ['B EH1 N AH0 D IH0 K S'],
  "benefactor": ['B EH1 N AH0 F AE2 K T ER0'],
  "benefactor's": ['B EH1 N AH0 F AE2 K T ER0 Z'],
  "benefactors": ['B EH1 N AH0 F AE2 K T ER0 Z'],
  "beneficence": ['B AH0 N EH1 F AH0 S AH0 N S'],
  "beneficent": ['B EH2 N AH0 F IH1 SH AH0 N T'],
  "beneficial": ['B EH2 N AH0 F IH1 SH AH0 L'],
  "beneficial's": ['B EH2 N AH0 F IH1 SH AH0 L Z'],
  "beneficially": ['B EH2 N AH0 F IH1 SH AH0 L IY0'],
  "beneficiaries": ['B EH2 N AH0 F IH1 SH IY0 EH2 R IY0 Z'],
  "beneficiary": ['B EH2 N AH0 F IH1 SH IY0 EH2 R IY0'],
  "beneficiary's": ['B EH2 N AH0 F IH1 SH IY0 EH2 R IY0 Z'],
  "benefiel": ['B EH1 N AH0 F IY0 L'],
  "benefield": ['B EH1 N AH0 F IY0 L D'],
  "benefit": ['B EH1 N AH0 F IH0 T'],
  "benefited": ['B EH1 N AH0 F IH2 T IH0 D'],
  "benefiting": ['B EH1 N AH0 F IH0 T IH0 NG'],
  "benefits": ['B EH1 N AH0 F IH0 T S'],
  "benefitted": ['B EH1 N AH0 F IH0 T IH0 D'],
  "benefitting": ['B EH1 N AH0 F IH0 T IH0 NG'],
  "beneke": ['B EH1 N AH0 K'],
  "benel": ['B EH1 N AH0 L'],
  "benelux": ['B EH1 N AH0 L AH0 K S'],
  "benenati": ['B IH0 N AH0 N AA1 T IY0'],
  "benequity": ['B EH2 N EH1 K W AH0 T IY0'],
  "benes": ['B EH1 N IY0 S'],
  "benesch": ['B EH1 N AH0 SH'],
  "benesh": ['B EH1 N AH0 SH'],
  "benet": ['B EH1 N AH0 T', 'B AH0 N EY1'],
  "benet's": ['B EH1 N AH0 T S', 'B AH0 N EY1 Z'],
  "benetti": ['B EH0 N EH1 T IY0'],
  "benetton": ['B EH1 N AH0 T AH0 N', 'B EH1 N AH0 T AO0 N'],
  "benetton's": ['B EH1 N AH0 T AH0 N Z', 'B EH1 N AH0 T AO0 N Z'],
  "benevento": ['B EH1 N AH0 V EY0 N T OW0'],
  "benevides": ['B EH1 N AH0 V IY0 D EH0 S'],
  "benevolence": ['B AH0 N EH1 V AH0 L AH0 N S'],
  "benevolences": ['B AH0 N EH1 V AH0 L AH0 N S IH0 Z'],
  "benevolent": ['B AH0 N EH1 V AH0 L AH0 N T'],
  "benezra": ['B EH1 N AH0 Z R AH0'],
  "benfer": ['B EH1 N F ER0'],
  "benfield": ['B EH1 N F IY0 L D'],
  "benford": ['B EH1 N F ER0 D'],
  "bengal": ['B EH1 NG G AH0 L'],
  "bengali": ['B EH0 NG G AA1 L IY0'],
  "bengalis": ['B EH0 NG G AA1 L IY0 Z'],
  "bengals": ['B EH1 NG G AH0 L Z'],
  "benge": ['B EH1 N JH'],
  "bengel": ['B EH1 NG G AH0 L'],
  "bengoechea": ['B EH2 NG G OW0 EH0 CH IY1 AH0', 'B EH2 NG G OW0 CH IY1 AH0'],
  "bengston": ['B EH1 NG G S T AH0 N'],
  "bengt": ['B EH1 NG K T'],
  "bengtson": ['B EH1 NG T S AH0 N'],
  "benguet": ['B EH1 NG G AH0 T'],
  "benham": ['B EH1 N HH AH0 M'],
  "benhamou": ['B EH2 N HH AH0 M UW1'],
  "beni": ['B EH1 N IY0'],
  "benight": ['B IY0 N AY1 T'],
  "benighted": ['B IH0 N AY1 T IH0 D'],
  "benighting": ['B IY0 N AY1 T IH0 NG'],
  "benights": ['B IY0 N AY1 T S'],
  "benign": ['B IH0 N AY1 N'],
  "benigna": ['B EH0 N IY1 G N AH0'],
  "benignly": ['B AH0 N AY1 N L IY0'],
  "benigno": ['B EH2 N IY1 N Y OW0', 'B EH1 N IH0 G N OW0'],
  "benihana": ['B EH2 N IH0 HH AA1 N AH0', 'B EH2 N IY0 HH AA1 N AH0'],
  "benin": ['B IY1 N IH0 N'],
  "beninati": ['B EH0 N IY0 N AA1 T IY0'],
  "benincasa": ['B EH0 N IY0 N K AA1 S AH0'],
  "bening": ['B EH1 N IH0 NG'],
  "benish": ['B EH1 N IH0 SH'],
  "benita": ['B AH0 N IY1 T AH0'],
  "benites": ['B EH1 N AY0 T S'],
  "benitez": ['B EY0 N IY1 T EH0 Z'],
  "benito": ['B EH0 N IY1 T OW0', 'B AH0 N IY1 T OW0', 'B IH0 N IY1 T OW2'],
  "benja": ['B EH1 N JH AH0'],
  "benjamin": ['B EH1 N JH AH0 M AH0 N'],
  "benjamin's": ['B EH1 N JH AH0 M AH0 N Z'],
  "benjimen": ['B EH1 N JH AH0 M IH0 N'],
  "benjy": ['B EH1 N JH IY0'],
  "benke": ['B EH1 NG K'],
  "benker": ['B EH1 NG K ER0'],
  "benkert": ['B EH1 NG K ER0 T'],
  "benko": ['B EH1 NG K OW0'],
  "benlate": ['B EH1 N L EY2 T'],
  "benlox": ['B EH1 N L AA0 K S'],
  "benn": ['B EH1 N'],
  "bennardo": ['B AH0 N AA1 R D OW0'],
  "benne": ['B EH1 N'],
  "bennefield": ['B EH1 N IH0 F IY0 L D'],
  "benner": ['B EH1 N ER0'],
  "bennet": ['B EH1 N IH0 T'],
  "bennett": ['B EH1 N AH0 T', 'B EH1 N IH0 T'],
  "bennett's": ['B EH1 N AH0 T S'],
  "bennette": ['B IH0 N EH1 T'],
  "bennetts": ['B EH1 N IH0 T S'],
  "benney": ['B EH1 N IY0'],
  "bennick": ['B EH1 N IH0 K'],
  "bennie": ['B EH1 N IY0'],
  "bennigan": ['B EH1 N IH0 G AH0 N'],
  "bennigan's": ['B EH1 N IH0 G AH0 N Z'],
  "benning": ['B EH1 N IH0 NG'],
  "benninger": ['B EH1 N IH0 NG ER0'],
  "benningfield": ['B EH1 N IH0 NG F IY0 L D'],
  "benninghoff": ['B EH1 N IH0 NG HH AO2 F'],
  "bennington": ['B EH1 N IH0 NG T AH0 N'],
  "bennink": ['B EH1 N IH0 NG K'],
  "bennion": ['B EH1 N Y AH0 N'],
  "bennis": ['B EH1 N IH0 S'],
  "bennison": ['B EH1 N IH0 S AH0 N'],
  "bennitt": ['B EH1 N IH0 T'],
  "benno": ['B EH1 N OW0'],
  "benny": ['B EH1 N IY0'],
  "beno": ['B EY1 N OW0'],
  "benoist": ['B AH0 N OY1 S T'],
  "benoit": ['B AH0 N OY1 T'],
  "benoni": ['B EH0 N OW1 N IY0'],
  "benotti": ['B AH0 N AO1 T IY0'],
  "benowitz": ['B EH1 N AH0 W IH0 T S'],
  "benoy": ['B EH1 N OY0'],
  "bens": ['B EH1 N Z'],
  "bensalem": ['B EH2 N S EY1 L AH0 M'],
  "bensch": ['B EH1 N SH'],
  "benscoter": ['B EH1 N S K AH0 T ER0'],
  "bensel": ['B EH1 N S AH0 L'],
  "bensen": ['B EH1 N S AH0 N'],
  "bensenyore": ['B EH0 N S IY0 N Y AO1 R', 'B EH2 N S IY0 N Y AO1 R IY0'],
  "benshoof": ['B EH1 N SH UH0 F'],
  "bensing": ['B EH1 N S IH0 NG'],
  "bensinger": ['B EH1 N S IH0 N JH ER0'],
  "benskin": ['B EH1 N S K IH0 N'],
  "bensley": ['B EH1 N S L IY0'],
  "bensman": ['B EH1 N S M AH0 N'],
  "benson": ['B EH1 N S AH0 N'],
  "benson's": ['B EH1 N S AH0 N Z'],
  "bensonhurst": ['B EH1 N S AH0 N HH ER0 S T'],
  "benstock": ['B EH1 N S T AA2 K'],
  "bent": ['B EH1 N T'],
  "bente": ['B EH1 N T'],
  "benten": ['B EH1 T IH0 N'],
  "benter": ['B EH1 N T ER0'],
  "benthall": ['B EH1 N TH AH0 L'],
  "bentivegna": ['B EH0 N T IY0 V EH1 G N AH0'],
  "bentler": ['B EH1 N T L ER0'],
  "bentley": ['B EH1 N T L IY0'],
  "bentley's": ['B EH1 N T L IY0 Z'],
  "bently": ['B EH1 N T L IY0'],
  "bento": ['B EH1 N T OW0'],
  "benton": ['B EH1 N T AH0 N'],
  "bentonite": ['B EH1 N T AH0 N AY2 T'],
  "bentonville": ['B EH1 N T AH0 N V IH2 L'],
  "bentsen": ['B EH1 N T S AH0 N'],
  "bentsen's": ['B EH1 N T S AH0 N Z'],
  "bentson": ['B EH1 N T S AH0 N'],
  "bentz": ['B EH1 N T S'],
  "bentzel": ['B EH1 N T Z AH0 L'],
  "bentzen": ['B EH1 N T Z AH0 N'],
  "benveniste": ['B EH0 N V EH0 N IY1 S T IY0'],
  "benvenuti": ['B EH0 N V EH0 N UW1 T IY0'],
  "benvenuto": ['B EH0 N V EH0 N UW1 T OW0'],
  "benware": ['B EH1 N W EH0 R'],
  "benway": ['B EH1 N W EY2'],
  "benyamin": ['B EH2 N Y AH0 M IY1 N'],
  "benyo": ['B EY1 N Y OW0'],
  "benz": ['B EH1 N Z', 'B AE1 N Z'],
  "benz's": ['B EH1 N Z IH0 Z'],
  "benzel": ['B EH1 N Z AH0 L'],
  "benzene": ['B EH0 N Z IY1 N', 'B EH1 N Z IY0 N'],
  "benzes": ['B EH1 N Z IH0 Z'],
  "benzie": ['B EH1 N Z IY0'],
  "benziger": ['B EH1 N Z IH0 G ER0'],
  "benzine": ['B EH1 N Z IY0 N'],
  "benzing": ['B EH1 N Z IH0 NG'],
  "benzinger": ['B EH1 N Z IH0 NG ER0'],
  "benzodiazepine": ['B EH2 N Z OW0 D IY0 AE1 Z AH0 P IY0 N'],
  "beougher": ['B AW1 F ER0'],
  "beowulf": ['B EY1 AH0 W UH2 L F'],
  "bequeath": ['B IH0 K W IY1 TH'],
  "bequeathed": ['B AH0 K W IY1 TH T'],
  "bequest": ['B IH0 K W EH1 S T'],
  "bequests": ['B IH0 K W EH1 S T S'],
  "bequette": ['B IH0 K EH1 T'],
  "bera": ['B EH1 R AH0'],
  "beradino": ['B ER2 AA0 D IY1 N OW0'],
  "beran": ['B EH1 R AH0 N'],
  "berandino": ['B EH2 R AH0 N D IY1 N OW2'],
  "beranek": ['B EH1 R AH0 N IH0 K'],
  "berard": ['B ER0 AA1 R D'],
  "berardi": ['B ER0 AA1 R D IY0'],
  "berardinelli": ['B ER0 AA0 R D IY0 N EH1 L IY0'],
  "berardino": ['B ER0 AA0 R D IY1 N OW0'],
  "berardo": ['B ER0 AA1 R D OW0'],
  "berarducci": ['B ER0 AA0 R D UW1 CH IY0'],
  "berate": ['B IH0 R EY1 T'],
  "berated": ['B IH0 R EY1 T IH0 D'],
  "berating": ['B IH0 R EY1 T IH0 NG'],
  "berber": ['B ER1 B ER0'],
  "berberian": ['B ER0 B IH1 R IY0 AH0 N'],
  "berberich": ['B ER1 B ER0 IH0 K'],
  "berbick": ['B ER1 B IH0 K'],
  "bercaw": ['B ER1 K AO0'],
  "berch": ['B ER1 K'],
  "berchenall": ['B ER1 K AH0 N AA2 L'],
  "berchtesgaden": ['B ER1 K T AH0 S G AA2 D AH0 N'],
  "berchtold": ['B ER1 K T OW0 L D'],
  "bercier": ['B ER1 K IY0 ER0'],
  "bercor": ['B ER1 K AO2 R'],
  "berdahl": ['B ER1 D AA0 L'],
  "berdan": ['B ER1 D AH0 N'],
  "berdine": ['B ER0 D IY1 N IY0'],
  "bere": ['B IH1 R'],
  "berea": ['B ER0 IY1 AH0'],
  "bereave": ['B ER0 IY1 V'],
  "bereaved": ['B ER0 IY1 V D'],
  "bereavement": ['B ER0 IY1 V M AH0 N T'],
  "bereft": ['B ER0 EH1 F T'],
  "beregovoy": ['B ER0 EH1 G AH0 V OY2', 'B EH2 R AH0 G OW1 V OY2', 'B EH2 R EH1 G AH0 V OY2'],
  "berend": ['B EH1 R EH0 N D'],
  "berends": ['B EH1 R EH0 N D Z'],
  "berendt": ['B EH1 R IH0 N T'],
  "berendzen": ['B EH1 R IH0 N D Z AH0 N'],
  "berens": ['B IH1 R AH0 N Z'],
  "berenson": ['B EH1 R IH0 N S AH0 N'],
  "berent": ['B EH1 R AH0 N T'],
  "beres": ['B IY1 R Z'],
  "beresford": ['B IH1 R Z F ER0 D'],
  "beret": ['B EH1 R AH0 T', 'B EH1 R EY0 T'],
  "berets": ['B EH1 R AH0 T S', 'B EH1 R EY0 Z'],
  "beretta": ['B ER0 EH1 T AH0'],
  "berettas": ['B ER0 EH1 T AH0 S'],
  "berezine": ['B IH1 R AH0 Z IY0 N', 'B IH1 R AH0 Z AY0 N'],
  "berg": ['B ER1 G'],
  "berg's": ['B ER1 G Z'],
  "bergamini": ['B ER0 G AA0 M IY1 N IY0'],
  "bergamo": ['B ER0 G AA1 M OW0'],
  "bergan": ['B ER1 G AH0 N'],
  "bergdahl": ['B ER1 G D AA0 L'],
  "bergdoll": ['B ER1 G D AA2 L'],
  "bergdorf": ['B ER1 G D AO2 R F'],
  "berge": ['B ER1 JH'],
  "bergeman": ['B ER1 G M AH0 N'],
  "bergemann": ['B ER1 G M AH0 N'],
  "bergen": ['B ER1 G AH0 N'],
  "bergendahl": ['B ER1 G EH0 N D AA0 L'],
  "bergenfield": ['B ER1 G AH0 N F IY2 L D'],
  "bergenthal": ['B ER1 G AH0 N TH AA2 L'],
  "berger": ['B ER1 G ER0'],
  "berger's": ['B ER1 G ER0 Z'],
  "bergerman": ['B ER1 G ER0 M AH0 N'],
  "bergeron": ['B ER1 G ER0 AO0 N'],
  "bergerson": ['B ER1 G ER0 S AH0 N'],
  "berges": ['B ER1 JH IH0 Z'],
  "bergeson": ['B ER1 G IH0 S AH0 N'],
  "berget": ['B ER1 G EH0 T'],
  "bergevin": ['B ER1 G EH0 V IH0 N'],
  "bergey": ['B ER1 JH IY0'],
  "bergfeld": ['B ER1 G F EH0 L D'],
  "berggren": ['B ER1 G R EH0 N'],
  "bergh": ['B ER1 G'],
  "berghof": ['B ER1 G HH AO0 F'],
  "berghoff": ['B ER1 G HH AO0 F'],
  "berghuis": ['B ER1 G HH UW0 IH0 Z'],
  "bergin": ['B ER1 G IH2 N'],
  "bergland": ['B ER1 G L AE2 N D'],
  "bergling": ['B ER1 G L IH0 NG'],
  "berglund": ['B ER1 G L AH0 N D'],
  "bergman": ['B ER1 G M AH0 N'],
  "bergmann": ['B ER1 G M AH0 N'],
  "bergner": ['B ER1 G N ER0'],
  "bergquist": ['B ER1 G K W IH0 S T'],
  "bergren": ['B ER1 G R EH0 N'],
  "bergs": ['B ER1 G Z'],
  "bergschneider": ['B ER1 G SH N AY0 D ER0'],
  "bergsma": ['B EH1 R G Z M AH0'],
  "bergstedt": ['B ER1 G S T EH0 T'],
  "bergstein": ['B ER1 G S T AY2 N', 'B ER1 G S T IY2 N'],
  "bergsten": ['B ER1 G S AH0 N'],
  "bergstrand": ['B ER1 G S T R AE2 N D'],
  "bergstrausser": ['B ER1 G S T R AW2 S ER0'],
  "bergstrausser's": ['B ER1 G S T R AW2 S ER0 Z'],
  "bergstresser": ['B ER1 G S T R EH2 S ER0'],
  "bergstrom": ['B ER1 G S T R AA0 M'],
  "bergthold": ['B ER1 G TH OW2 L D'],
  "bergum": ['B ER1 G AH0 M'],
  "berhow": ['B ER1 HH OW0'],
  "beria": ['B EH1 R IY0 AH0'],
  "bering": ['B EH1 R IH0 NG'],
  "beringer": ['B EH1 R IH0 NG ER0'],
  "berisford": ['B EH1 R IH0 S F ER0 D'],
  "berish": ['B ER1 IH0 SH'],
  "berjaya": ['B ER0 JH AY1 AH0'],
  "berk": ['B ER1 K'],
  "berka": ['B ER1 K AH0'],
  "berke": ['B ER1 K'],
  "berkebile": ['B ER1 K IH0 B AH0 L'],
  "berkel": ['B ER1 K AH0 L'],
  "berkeley": ['B ER1 K L IY0'],
  "berkeley's": ['B ER1 K L IY0 Z'],
  "berkelman": ['B ER1 K AH0 L M AH0 N'],
  "berkemeier": ['B ER1 K IH0 M AY0 ER0'],
  "berken": ['B ER1 K AH0 N'],
  "berkery": ['B ER1 K ER0 IY0'],
  "berkes": ['B ER1 K S'],
  "berkey": ['B ER1 K IY0'],
  "berkheimer": ['B ER1 K HH AY0 M ER0'],
  "berkland": ['B ER1 K L AH0 N D'],
  "berklee": ['B ER1 K L IY0'],
  "berkley": ['B ER1 K L IY0'],
  "berkline": ['B ER1 K L AY2 N'],
  "berkman": ['B ER1 K M AH0 N'],
  "berko": ['B ER1 K OW0'],
  "berkoff": ['B ER1 K AO0 F'],
  "berkovitz": ['B ER1 K AH0 V IH0 T S'],
  "berkowitz": ['B ER1 K AH0 W IH0 T S'],
  "berkshire": ['B ER1 K SH ER0', 'B ER1 K SH AY2 R'],
  "berkshires": ['B ER1 K SH IH2 R Z', 'B ER1 K SH AY2 R Z'],
  "berkson": ['B ER1 K S AH0 N'],
  "berkstresser": ['B ER1 K S T R IH0 S ER0'],
  "berlack": ['B ER1 L AE0 K'],
  "berland": ['B ER1 L AH0 N D'],
  "berlanga": ['B ER0 L AA1 NG G AH0'],
  "berlascone": ['B EH2 R L AH0 S K OW1 N IY0'],
  "berlascone's": ['B EH2 R L AH0 S K OW1 N IY0 Z'],
  "berle": ['B ER1 L'],
  "berle's": ['B ER1 L Z'],
  "berles": ['B ER1 L Z'],
  "berles'": ['B ER1 L Z'],
  "berlet": ['B ER2 L EH1 T'],
  "berlet's": ['B ER1 L EH1 T S'],
  "berlex": ['B ER1 L EH2 K S'],
  "berlin": ['B ER0 L IH1 N'],
  "berlin's": ['B ER0 L IH1 N Z'],
  "berliner": ['B ER0 L IH1 N ER0', 'B ER0 L AY1 N ER0'],
  "berliner's": ['B ER0 L IH1 N ER0 Z', 'B ER0 L AY1 N ER0 Z'],
  "berliners": ['B ER0 L IH1 N ER0 Z', 'B ER0 L AY1 N ER0 Z'],
  "berling": ['B ER1 L IH0 NG'],
  "berlinger": ['B ER1 L IH0 NG ER0'],
  "berlioz": ['B ER1 L IY0 OW0 Z'],
  "berlitz": ['B ER0 L IH1 T S'],
  "berlottes": ['B ER0 L AA1 T S'],
  "berls": ['B ER1 L Z'],
  "berlusconi": ['B ER2 L AH0 S K OW1 N IY0'],
  "berlusconi's": ['B ER2 L AH0 S K OW1 N IY0 Z'],
  "berm": ['B ER1 M'],
  "berman": ['B ER1 M AH0 N'],
  "berman's": ['B ER1 M AH0 N Z'],
  "bermans": ['B ER1 M AH0 N Z'],
  "bermea": ['B EH1 R M IY0 AH0'],
  "bermel": ['B ER1 M AH0 L'],
  "berms": ['B ER1 M Z'],
  "bermuda": ['B ER0 M Y UW1 D AH0'],
  "bermudas": ['B ER0 M Y UW1 D AH0 Z'],
  "bermudes": ['B ER0 M Y UW1 D Z'],
  "bermudez": ['B ER0 M Y UW1 D EH2 Z', 'B ER2 M Y UW1 D EH2 Z'],
  "bern": ['B ER1 N'],
  "berna": ['B EH1 R N AH0'],
  "bernabe": ['B ER1 N AH0 B'],
  "bernabei": ['B ER1 N AH0 B AY0'],
  "bernacki": ['B ER0 N AA1 T S K IY0'],
  "bernadene": ['B ER1 N AH0 D IY0 N'],
  "bernadette": ['B ER0 N AH0 D EH1 T'],
  "bernadin": ['B ER1 N AH0 D IH0 N'],
  "bernadine": ['B ER0 N AH0 D IY1 N'],
  "bernadino": ['B ER2 N AH0 D IY1 N OW0'],
  "bernal": ['B ER1 N AH0 L'],
  "bernama": ['B ER0 N AA1 M AH0'],
  "bernard": ['B ER0 N AA1 R D', 'B ER1 N ER0 D'],
  "bernard's": ['B ER0 N AA1 R D Z'],
  "bernardi": ['B ER0 N AA1 R D IY0'],
  "bernardin": ['B ER1 N AA0 R D IY0 N'],
  "bernardini": ['B ER0 N AA0 R D IY1 N IY0'],
  "bernardino": ['B ER0 N AH0 D IY1 N OW0', 'B ER2 N AA0 R D IY1 N OW0'],
  "bernardo": ['B ER0 N AA1 R D OW0'],
  "bernardo's": ['B ER0 N AA1 R D OW0 Z'],
  "bernards": ['B ER0 N AA1 R D Z'],
  "bernardy": ['B ER0 N AA1 R D IY0'],
  "bernas": ['B ER1 N AH0 Z'],
  "bernasconi": ['B ER0 N AA0 S K OW1 N IY0'],
  "bernat": ['B ER1 N AH0 T'],
  "bernath": ['B ER1 N AH0 TH'],
  "bernauer": ['B ER1 N AW0 ER0'],
  "bernay": ['B ER0 N EY1'],
  "bernbach": ['B ER1 N B AA2 K'],
  "bernd": ['B ER1 N T'],
  "berndt": ['B ER1 N T'],
  "berne": ['B ER1 N'],
  "berneice": ['B ER0 N AY1 S'],
  "berner": ['B ER1 N ER0'],
  "bernet": ['B ER0 N EH1 T'],
  "bernett": ['B ER1 N IH0 T'],
  "berney": ['B ER1 N IY0'],
  "bernhagen": ['B ER1 N HH AH0 G AH0 N'],
  "bernhard": ['B ER1 N HH AA0 R D'],
  "bernhardt": ['B ER1 N HH AA0 R T'],
  "bernhart": ['B ER1 N HH AA0 R T'],
  "bernheim": ['B ER1 N HH AY0 M'],
  "bernheimer": ['B ER1 N HH AY0 M ER0'],
  "berni": ['B EH1 R N IY0'],
  "bernia": ['B EH1 R N IY0 AH0'],
  "bernice": ['B ER0 N IY1 S'],
  "bernick": ['B ER1 N IH0 K'],
  "bernie": ['B ER1 N IY0'],
  "bernie's": ['B ER1 N IY0 Z'],
  "bernier": ['B ER1 N IY0 ER0'],
  "berning": ['B ER1 N IH0 NG'],
  "berninger": ['B ER1 N IH0 NG ER0'],
  "bernita": ['B ER0 N IY1 T AH0'],
  "bernoulli": ['B ER0 N UW1 L IY0'],
  "berns": ['B ER1 N Z'],
  "bernsen": ['B ER1 N S AH0 N'],
  "bernson": ['B ER1 N S AH0 N'],
  "bernstein": ['B ER1 N S T AY0 N', 'B ER1 N S T IY0 N'],
  "bernstein's": ['B ER1 N S T IY2 N Z', 'B ER1 N S T AY2 N Z'],
  "bernsteins": ['B ER1 N S T AY0 N Z', 'B ER1 N S T IY0 N Z'],
  "bernt": ['B ER1 N T'],
  "berntsen": ['B ER1 N T S AH0 N'],
  "berntson": ['B ER1 N T S AH0 N'],
  "berny": ['B ER1 N IY0'],
  "bero": ['B EH1 R OW0'],
  "beron": ['B EH1 R AH0 N'],
  "berquist": ['B ER1 K W IH0 S T'],
  "berra": ['B EH1 R AH0'],
  "berra's": ['B EH1 R AH0 Z'],
  "berrard": ['B EH2 R AA1 R D', 'B ER0 AA1 R D'],
  "berres": ['B EH1 R Z'],
  "berreth": ['B EH1 R IH0 TH'],
  "berrett": ['B EH1 R IH0 T'],
  "berrey": ['B EH1 R IY0'],
  "berri": ['B EH1 R IY0'],
  "berrian": ['B EH1 R IY0 AH0 N'],
  "berridge": ['B EH1 R IH0 JH'],
  "berrie": ['B EH1 R IY0'],
  "berrien": ['B EH1 R IY0 AH0 N'],
  "berrier": ['B EH1 R IY0 ER0'],
  "berries": ['B EH1 R IY0 Z'],
  "berrigan": ['B EH1 R IH0 G AH0 N'],
  "berrill": ['B EH1 R AH0 L'],
  "berringer": ['B EH1 R IH0 NG ER0'],
  "berrios": ['B EH0 R IY1 OW0 Z'],
  "berris": ['B EH1 R IY0 Z'],
  "berrong": ['B EH1 R AO0 NG'],
  "berry": ['B EH1 R IY0'],
  "berry's": ['B EH1 R IY0 Z'],
  "berryhill": ['B EH1 R IY0 HH IH2 L'],
  "berrylike": ['B EH1 R IY0 L AY2 K'],
  "berryman": ['B EH1 R IY0 M AH0 N'],
  "bersch": ['B ER1 SH'],
  "berserk": ['B ER0 S ER1 K'],
  "bershad": ['B ER0 SH AA1 D'],
  "berson": ['B ER1 S AH0 N'],
  "berst": ['B ER1 S T'],
  "berstein": ['B ER1 S T IY0 N', 'B ER1 S T AY0 N'],
  "bert": ['B ER1 T'],
  "berta": ['B ER1 T AH0'],
  "bertch": ['B ER1 CH'],
  "berte": ['B ER1 T'],
  "bertelli": ['B ER0 T EH1 L IY0'],
  "bertels": ['B ER1 T AH0 L Z'],
  "bertelsen": ['B ER1 T IH0 L S AH0 N'],
  "bertelsmann": ['B ER1 T AH0 L Z M AH0 N'],
  "bertelsmann's": ['B ER1 T AH0 L Z M AH0 N Z'],
  "bertelson": ['B ER1 T IH0 L S AH0 N'],
  "berth": ['B ER1 TH'],
  "bertha": ['B ER1 TH AH0'],
  "berthelot": ['B ER1 TH AH0 L AA0 T'],
  "berthelsen": ['B ER1 TH AH0 L S AH0 N'],
  "berthiaume": ['B ER2 TH IY0 UW1 M IY0'],
  "berthold": ['B ER1 TH OW2 L D'],
  "bertholf": ['B ER1 TH OW0 L F'],
  "berths": ['B ER1 TH S', 'B ER1 DH Z'],
  "berti": ['B EH1 R T IY0'],
  "bertie": ['B ER1 T IY0'],
  "bertil": ['B ER1 T IH0 L'],
  "bertilde": ['B ER1 T IH0 L D'],
  "bertin": ['B ER1 T IH0 N'],
  "bertini": ['B ER0 T IY1 N IY0'],
  "bertino": ['B ER0 T IY1 N OW0'],
  "bertinotti": ['B ER0 T IH0 N AO1 T IY0'],
  "bertke": ['B ER1 T K IY0'],
  "bertling": ['B ER1 T AH0 L IH0 NG', 'B ER1 T L IH0 NG'],
  "bertold": ['B ER1 T OW0 L D'],
  "bertoldi": ['B ER0 T OW1 L D IY0'],
  "bertoli": ['B ER0 T OW1 L IY0'],
  "bertolini": ['B ER0 T OW0 L IY1 N IY0'],
  "bertolino": ['B ER0 T OW0 L IY1 N OW0'],
  "bertolotti": ['B ER0 T OW0 L OW1 T IY0'],
  "bertolucci": ['B ER2 T OW0 L UW1 CH IY0'],
  "berton": ['B ER1 T AH0 N'],
  "bertone": ['B ER0 T OW1 N IY0'],
  "bertoni": ['B ER0 T OW1 N IY0'],
  "bertrade": ['B ER1 T R AH0 D'],
  "bertram": ['B ER1 T R AH0 M'],
  "bertran": ['B ER1 T R AH0 N'],
  "bertrand": ['B ER1 T R AH0 N D'],
  "bertsch": ['B ER1 CH'],
  "bertsche": ['B ER1 CH'],
  "bertucci": ['B ER0 T UW1 CH IY0'],
  "bertucci's": ['B ER0 T UW1 CH IY0 Z'],
  "berty": ['B ER1 T IY0'],
  "bertz": ['B ER1 T S'],
  "berube": ['B EH1 R UW0 B'],
  "berudi": ['B AH0 R UW1 D IY0'],
  "berumen": ['B IH1 R UW0 M EH0 N'],
  "berwald": ['B ER1 W AO0 L D'],
  "berwanger": ['B ER1 W AO0 NG ER0'],
  "berwick": ['B ER1 W IH0 K'],
  "berwyn": ['B ER1 W IH0 N'],
  "beryl": ['B EH1 R AH0 L'],
  "beryllium": ['B ER0 IH1 L IY0 AH0 M'],
  "berzin": ['B ER1 Z IH0 N'],
  "berzins": ['B ER1 Z IH0 N Z'],
  "bes": ['B IY1 Z'],
  "besancon": ['B IH0 S AE1 N K AH0 N'],
  "besant": ['B EH1 Z AH0 N T'],
  "besaw": ['B IY1 S AO0'],
  "besch": ['B EH1 SH'],
  "beschloss": ['B EH1 SH L AO2 S'],
  "beschloss'": ['B EH1 SH L AO2 S'],
  "beschloss's": ['B EH1 SH L AO2 S IH0 S'],
  "besecker": ['B EH1 S IH0 K ER0'],
  "beseech": ['B IY0 S IY1 CH'],
  "beseeched": ['B IY0 S IY1 CH T'],
  "beseeching": ['B IY0 S IY1 CH IH0 NG'],
  "beseiged": ['B IH0 S IY1 JH D'],
  "besemer": ['B EH1 S IY0 M ER0'],
  "beset": ['B IH0 S EH1 T'],
  "besetting": ['B IH0 S EH1 T IH0 NG'],
  "beshara": ['B IH0 SH AA1 R AH0'],
  "besharov": ['B EH1 SH ER0 AA0 V'],
  "beshears": ['B EH1 SH IY0 R Z'],
  "beshloss": ['B EH1 SH L AO0 S'],
  "beshore": ['B EH1 SH ER0'],
  "beside": ['B IH0 S AY1 D', 'B IY2 S AY1 D'],
  "besides": ['B IH0 S AY1 D Z', 'B IY2 S AY1 D Z'],
  "besiege": ['B IH0 S IY1 JH'],
  "besieged": ['B IH0 S IY1 JH D'],
  "besieging": ['B IH0 S IY1 JH IH0 NG'],
  "beske": ['B EH1 S K'],
  "besler": ['B EH1 S AH0 L ER0', 'B EH1 S L ER0'],
  "besner": ['B EH1 S N ER0'],
  "besner's": ['B EH1 S N ER0 Z'],
  "besotted": ['B IH0 S AO1 T IH0 D'],
  "bespeak": ['B IH0 S P IY1 K'],
  "bespeaks": ['B IH0 S P IY1 K S'],
  "bespectacle": ['B IH0 S P EH1 K T AH0 K AH0 L'],
  "bespectacled": ['B IH0 S P EH1 K T AH0 K AH0 L D'],
  "bespoke": ['B UH0 S P OW1 K'],
  "bess": ['B EH1 S'],
  "besse": ['B EH1 S'],
  "bessemer": ['B EH1 S AH0 M ER0'],
  "bessent": ['B EH1 S AH0 N T'],
  "besser": ['B EH1 S ER0'],
  "bessert": ['B EH1 S ER0 T'],
  "bessette": ['B IH0 S EH1 T'],
  "bessey": ['B EH1 S IY0'],
  "bessie": ['B EH1 S IY0'],
  "bessinger": ['B EH1 S IH0 N JH ER0'],
  "bessire": ['B EH0 S IH1 R IY0'],
  "bessler": ['B EH1 S L ER0'],
  "besso": ['B EH1 S OW0'],
  "besson": ['B EH1 S AH0 N'],
  "bessy": ['B EH1 S IY0'],
  "best": ['B EH1 S T'],
  "best's": ['B EH1 S T S'],
  "best-western": ['B EH1 S T W EH1 S T ER0 N'],
  "beste": ['B EH1 S T'],
  "bested": ['B EH1 S T IH0 D'],
  "bester": ['B EH1 S T ER0'],
  "bestial": ['B EH1 S CH AH0 L'],
  "bestiality": ['B EH0 S CH AE1 L IH0 T IY0'],
  "besting": ['B EH1 S T IH0 NG'],
  "bestow": ['B IH0 S T OW1'],
  "bestowed": ['B IH0 S T OW1 D'],
  "bestowing": ['B IH0 S T OW1 IH0 NG'],
  "bestows": ['B IH0 S T OW1 Z'],
  "bestrode": ['B IH0 S T R OW1 D'],
  "bestseller": ['B EH1 S T S EH1 L ER0', 'B EH1 S EH1 L ER0'],
  "bestsellers": ['B EH1 S T S EH1 L ER0 Z', 'B EH1 S EH1 L ER0 Z'],
  "bestselling": ['B EH1 S T S EH1 L IH0 NG', 'B EH1 S EH1 L IH0 NG'],
  "bestul": ['B EH1 S T AH0 L'],
  "bestwick": ['B EH1 S T W IH2 K'],
  "beswick": ['B EH1 S W IH0 K'],
  "bet": ['B EH1 T'],
  "bet's": ['B EH1 T S'],
  "beta": ['B EY1 T AH0'],
  "betabia": ['B AH0 T EY1 B IY0 AH0', 'B AH0 T AE1 B IY0 AH0'],
  "betake": ['B IY0 T EY1 K'],
  "betakeren": ['B EH2 T AH0 K EH1 R AH0 N'],
  "betamax": ['B EY1 T AH0 M AE0 K S'],
  "betancourt": ['B EH1 T AH0 N K AO0 R T'],
  "betancur": ['B AH0 T AE1 NG K ER0'],
  "betar": ['B EH1 T AA0 R'],
  "betas": ['B EY1 T AH0 Z'],
  "betaseron": ['B EY2 T AH0 S EH1 R AA2 N'],
  "betavon": ['B EH1 T AH0 V AA0 N'],
  "betcha": ['B EH1 CH AH0'],
  "betcher": ['B EH1 CH ER0'],
  "betel": ['B EH1 T AH0 L'],
  "betelgeuse": ['B EH1 T AH0 L G UH0 Z'],
  "beteta": ['B EH0 T EY1 T AH0'],
  "beth": ['B EH1 TH'],
  "beth's": ['B EH1 TH S'],
  "bethann": ['B EH1 TH AE2 N'],
  "bethanne": ['B EH1 TH AE2 N'],
  "bethany": ['B EH1 TH AH0 N IY0'],
  "bethard": ['B EH1 TH ER0 D'],
  "bethards": ['B EH1 TH ER0 D Z'],
  "bethea": ['B EH1 DH IY0 AH0'],
  "bethel": ['B EH1 TH AH0 L', 'B EH1 TH EH2 L'],
  "bethell": ['B EH1 TH AH0 L'],
  "bethesda": ['B AH0 TH EH1 Z D AH0'],
  "bethke": ['B EH1 TH K'],
  "bethlehem": ['B EH1 TH L IH0 HH EH2 M'],
  "bethlehem's": ['B EH1 TH L IH0 HH EH2 M Z'],
  "bethpage": ['B EH2 TH P EY1 JH'],
  "bethune": ['B AH0 TH Y UW1 N'],
  "betide": ['B IH0 T AY1 D'],
  "betke": ['B EH1 T K IY0'],
  "betker": ['B EH1 T K ER0'],
  "betley": ['B EH1 T L IY0'],
  "betook": ['B EH1 T UH2 K'],
  "betray": ['B IH0 T R EY1'],
  "betrayal": ['B IH0 T R EY1 AH0 L'],
  "betrayals": ['B IH0 T R EY1 AH0 L Z'],
  "betrayed": ['B IH0 T R EY1 D'],
  "betraying": ['B IH0 T R EY1 IH0 NG'],
  "betrays": ['B IH0 T R EY1 Z'],
  "bets": ['B EH1 T S'],
  "betsch": ['B EH1 CH'],
  "betschart": ['B EH1 T S HH AA2 R T', 'B EH1 CH AA2 R T'],
  "betsey": ['B EH1 T S IY0'],
  "betsill": ['B EH1 T S AH0 L'],
  "betsy": ['B EH1 T S IY0'],
  "betsy's": ['B EH1 T S IY0 Z'],
  "bett": ['B EH1 T'],
  "betta": ['B EH1 T AH0'],
  "bettcher": ['B EH1 T CH ER0'],
  "bette": ['B EH1 T IY0'],
  "betten": ['B EH1 T AH0 N'],
  "bettenberg": ['B EH1 T AH0 N B ER0 G'],
  "bettencourt": ['B EH1 T IH0 N K AO0 R T'],
  "bettendorf": ['B EH1 T IH0 N D AO0 R F'],
  "bettenhausen": ['B EH1 T IH0 N HH AW0 Z AH0 N'],
  "better": ['B EH1 T ER0'],
  "bettered": ['B EH1 T ER0 D'],
  "betteridge": ['B EH1 T ER0 IH0 JH'],
  "bettering": ['B EH1 T ER0 IH0 NG'],
  "betterment": ['B EH1 T ER0 M AH0 N T'],
  "betters": ['B EH1 T ER0 Z'],
  "betterton": ['B EH1 T ER0 T AH0 N'],
  "bettes": ['B EH1 T IY0 Z'],
  "betti": ['B EH1 T IY0'],
  "bettin": ['B EH1 T IH0 N'],
  "bettina": ['B AH0 T IY1 N AH0'],
  "betting": ['B EH1 T IH0 NG'],
  "bettinger": ['B EH1 T IH0 NG ER0'],
  "bettini": ['B EH0 T IY1 N IY0'],
  "bettino": ['B EH0 T IY1 N OW0'],
  "bettis": ['B EH1 T IH0 S'],
  "bettman": ['B EH1 T M AH0 N'],
  "bettman's": ['B EH1 T M AH0 N Z'],
  "bettner": ['B EH1 T N ER0'],
  "betton": ['B EH1 T AH0 N'],
  "bettor": ['B EH1 T ER0'],
  "bettors": ['B EH1 T ER0 Z'],
  "betts": ['B EH1 T S'],
  "betty": ['B EH1 T IY0'],
  "betty's": ['B EH1 T IY0 Z'],
  "between": ['B IH0 T W IY1 N', 'B IY0 T W IY1 N'],
  "betweens": ['B IH0 T W IY1 N Z', 'B IY0 T W IY1 N Z'],
  "betwixt": ['B IY0 T W IH1 K S T'],
  "betz": ['B EH1 T S'],
  "betzer": ['B EH1 T Z ER0'],
  "betzler": ['B EH1 T S L ER0'],
  "betzold": ['B EH1 T Z OW0 L D'],
  "beu": ['B UW1'],
  "beucler": ['B OY1 K AH0 L ER0', 'B OY1 K L ER0'],
  "beukema": ['B UW0 K IY1 M AH0'],
  "beula": ['B UW1 L AH0'],
  "beulah": ['B Y UW1 L AH0'],
  "beumer": ['B IY1 AH0 M ER0'],
  "beury": ['B ER1 IY0'],
  "beury's": ['B ER1 IY0 Z'],
  "beutel": ['B Y UW0 T EH1 L'],
  "beuthin": ['B Y UW1 TH IH0 N'],
  "beutler": ['B OY1 T AH0 L ER0', 'B OY1 T L ER0'],
  "beuys": ['B Y UW1 IY0 Z'],
  "bev": ['B EH1 V'],
  "bevacqua": ['B EH0 V AA1 K W AH0'],
  "bevalaqua": ['B EH0 V AH0 L AA1 K AH0'],
  "bevan": ['B EH1 V AH0 N'],
  "bevans": ['B EH1 V AH0 N Z'],
  "bevaqua": ['B EH0 V AA1 K AH0'],
  "bevard": ['B IH0 V AA1 R D'],
  "bevel": ['B EH1 V AH0 L'],
  "bevelled": ['B EH1 V AH0 L D'],
  "beven": ['B EH1 V AH0 N'],
  "bevens": ['B IY1 V AH0 N Z'],
  "bever": ['B EH1 V ER0'],
  "beverage": ['B EH1 V ER0 IH0 JH', 'B EH1 V R IH0 JH'],
  "beverages": ['B EH1 V R IH0 JH IH0 Z'],
  "beveridge": ['B EH1 V ER0 IH0 JH'],
  "beverley": ['B EH1 V ER0 L IY0'],
  "beverlin": ['B EH1 V ER0 L IH0 N'],
  "beverly": ['B EH1 V ER0 L IY0'],
  "beverly's": ['B EH1 V ER0 L IY0 Z'],
  "bevers": ['B EH1 V ER0 Z'],
  "bevier": ['B EH1 V IY0 ER0'],
  "bevil": ['B EH1 V AH0 L'],
  "bevilacqua": ['B EH0 V IY0 L AA1 K W AH0'],
  "bevill": ['B EH1 V AH0 L'],
  "beville": ['B IY1 V IH0 L'],
  "bevin": ['B EH1 V IH0 N'],
  "bevington": ['B EH1 V IH0 NG T AH0 N'],
  "bevins": ['B EH1 V IH0 N Z'],
  "bevis": ['B EH1 V IH0 S'],
  "bevmark": ['B EH1 V M AA2 R K'],
  "bevy": ['B EH1 V IY0'],
  "beware": ['B IH0 W EH1 R'],
  "bewilder": ['B IH0 W IH1 L D ER0'],
  "bewildered": ['B IH0 W IH1 L D ER0 D'],
  "bewildering": ['B IH0 W IH1 L D ER0 IH0 NG'],
  "bewilderment": ['B IH0 W IH1 L D ER0 M AH0 N T'],
  "bewilders": ['B IH0 W IH1 L D ER0 Z'],
  "bewitch": ['B IH0 W IH1 CH'],
  "bewitched": ['B IH0 W IH1 CH T'],
  "bewley": ['B Y UW1 L IY0'],
  "bexley": ['B EH1 K S L IY0'],
  "bey": ['B EY1'],
  "beyer": ['B EY1 ER0'],
  "beyerle": ['B AY1 R AH0 L'],
  "beyerlein": ['B AY1 R L AY0 N'],
  "beyers": ['B EY1 ER0 Z'],
  "beyersdorf": ['B AY1 R S D AO0 R F'],
  "beyl": ['B EY1 L'],
  "beymer": ['B EY1 M ER0'],
  "beynon": ['B EY1 N AH0 N'],
  "beyonce": ['B IH0 Y AO2 N S EY1'],
  "beyond": ['B IH0 AA1 N D', 'B IY2 AO1 N D', 'B IH0 AO1 N D'],
  "beytout": ['B EY1 T AW2 T'],
  "bezaire": ['B AH0 Z EH1 R'],
  "bezanson": ['B EH1 Z AH0 N S AH0 N'],
  "bezdek": ['B EH1 Z D IH0 K'],
  "bezek": ['B EH1 Z EH0 K'],
  "bezner": ['B EH1 Z N ER0'],
  "bezold": ['B EH1 Z OW0 L D'],
  "bhagwan": ['B AA1 G W AA0 N'],
  "bhakta": ['B AA1 K T AH0'],
  "bhangra": ['B AA1 NG G R AH0'],
  "bharat": ['B AA1 R AA2 T'],
  "bharata": ['B AA2 R AA1 T AH0'],
  "bharath": ['B AA2 R AA1 T'],
  "bhatia": ['B AA1 SH AH0', 'B AA1 T Y AH0'],
  "bhatt": ['B AE1 T', 'B AA1 T'],
  "bhattacharjy": ['B AA2 T AH0 CH AA1 R JH IY0'],
  "bhatti": ['B AA1 T IY0', 'B AA1 T IY2'],
  "bhiksha": ['B IH1 K SH AA2', 'B IY1 K SH AA2'],
  "bhirud": ['B IH1 R AH0 D'],
  "bhopal": ['B OW0 P AA1 L'],
  "bhosle": ['B AO1 S AH0 L'],
  "bhutan": ['B UW2 T AE1 N', 'B AH0 T AA1 N'],
  "bhutto": ['B UW1 T OW0'],
  "bhutto's": ['B UW1 T OW0 Z'],
  "bi": ['B AY1'],
  "bi-daily": ['B AY0 D EY1 L IY0'],
  "bi-polar": ['B AY0 P OW1 L ER0'],
  "bi-weekly": ['B AY0 W IY1 K L IY0'],
  "biaggi": ['B IY0 AE1 JH IY0'],
  "biaggini": ['B IY2 AH0 G IY1 N IY0'],
  "biagi": ['B IY0 AA1 JH IY0'],
  "biagini": ['B IY0 AH0 JH IY1 N IY0'],
  "biagioni": ['B IY0 AA2 JH IY0 OW1 N IY0'],
  "bialas": ['B IY0 AA1 L AH0 S'],
  "bialecki": ['B IY0 AH0 L EH1 K IY0'],
  "bialek": ['B IY0 AA1 L EH2 K'],
  "bialik": ['B IY0 AA1 L IH2 K'],
  "bialkin": ['B IY0 AA1 L K AH0 N'],
  "bialkowski": ['B IY0 AH0 L K AW1 S K IY0'],
  "bialy": ['B IY0 AA1 L IY0'],
  "biamby": ['B IY0 AA1 M B IY0'],
  "biamby's": ['B IY0 AA1 M B IY0 Z'],
  "biamonte": ['B IY0 AH0 M AO1 N T IY0'],
  "biana": ['B IY0 AA1 N AH0'],
  "bianca": ['B IY0 AA1 NG K AH0'],
  "bianchi": ['B IY0 AA1 N CH IY0'],
  "bianchini": ['B IY0 AA0 N CH IY1 N IY0'],
  "bianco": ['B IY0 AA1 NG K OW0'],
  "bianconi": ['B IY0 AA0 NG K OW1 N IY0'],
  "bianculli": ['B IY0 AA0 NG K UW1 L IY0'],
  "biannual": ['B AY0 AE1 N UW0 AH0 L'],
  "bias": ['B AY1 AH0 S'],
  "biased": ['B AY1 AH0 S T'],
  "biases": ['B AY1 AH0 S IH0 Z'],
  "biasi": ['B IY0 AA1 S IY0'],
  "biaxial": ['B AY0 AE1 K S IY0 AH0 L'],
  "biaz": ['B IY1 AE0 Z'],
  "bib": ['B IH1 B'],
  "biba": ['B IY1 B AH0'],
  "bibb": ['B IH1 B'],
  "bibbee": ['B IH1 B IY1'],
  "bibbins": ['B IH1 B IH0 N Z'],
  "bibbo": ['B IY1 B OW0'],
  "bibbs": ['B IH1 B Z'],
  "bibby": ['B IH1 B IY0'],
  "bibeau": ['B IH0 B OW1'],
  "bibeault": ['B IH0 B OW1'],
  "bibee": ['B IH0 B IY1'],
  "biber": ['B AY1 B ER0'],
  "bibi": ['B IH0 B IY1', 'B IY2 B IY1'],
  "bibi's": ['B IH0 B IY1 Z', 'B IY2 B IY1 Z'],
  "bible": ['B AY1 B AH0 L'],
  "bible's": ['B AY1 B AH0 L Z'],
  "bibler": ['B AY1 B AH0 L ER0', 'B AY1 B L ER0'],
  "bibles": ['B AY1 B AH0 L Z'],
  "biblical": ['B IH1 B L AH0 K AH0 L', 'B IH1 B L IH0 K AH0 L'],
  "bibliographic": ['B IH2 B L IY0 AA1 G R AA2 F IH0 K'],
  "bibliographical": ['B IH2 B L IY0 AA0 G R AA1 F IH0 K AH0 L'],
  "bibliographies": ['B IH2 B L IY0 AA1 G R AH0 F IY0 Z'],
  "bibliography": ['B IH2 B L IY0 AA1 G R AH0 F IY0'],
  "bibs": ['B IH1 B Z'],
  "biby": ['B AY1 B IY0'],
  "bic": ['B IH1 K'],
  "bicarbonate": ['B AY0 K AA1 R B AH0 N AH0 T'],
  "bice": ['B AY1 S'],
  "bicentenary": ['B AY0 S EH1 N T IH0 N EH2 R IY0'],
  "bicentennial": ['B AY2 S EH0 N T EH1 N IY0 AH0 L'],
  "bicep": ['B AY1 S EH2 P'],
  "biceps": ['B AY1 S EH2 P S'],
  "bichler": ['B IH1 K AH0 L ER0', 'B IH1 K L ER0'],
  "bichon": ['B IY2 SH AO1 N'],
  "bichsel": ['B IH1 K S AH0 L'],
  "bick": ['B IH1 K'],
  "bickel": ['B IH1 K AH0 L'],
  "bickell": ['B IH1 K AH0 L'],
  "bicker": ['B IH1 K ER0'],
  "bickered": ['B IH1 K ER0 D'],
  "bickering": ['B IH1 K ER0 IH0 NG'],
  "bickers": ['B IH1 K ER0 Z'],
  "bickerstaff": ['B IH1 K ER0 S T AE2 F'],
  "bickert": ['B IH1 K ER0 T'],
  "bickerton": ['B IH1 K ER0 T AH0 N'],
  "bickett": ['B IH1 K IH0 T'],
  "bickford": ['B IH1 K F ER0 D'],
  "bickham": ['B IH1 K HH AH0 M'],
  "bickhart": ['B IH1 K HH AA2 R T'],
  "bicking": ['B IH1 K IH0 NG'],
  "bickle": ['B IH1 K AH0 L'],
  "bickler": ['B IH1 K L ER0'],
  "bickley": ['B IH1 K L IY0'],
  "bickmore": ['B IH1 K M AO0 R'],
  "bicknell": ['B IH1 K N AH0 L'],
  "bickner": ['B IH1 K N ER0'],
  "bicksler": ['B IH1 K S L ER0'],
  "bickwit": ['B IH1 K W IH0 T'],
  "bicoastal": ['B IH0 K OW1 S T AH0 L'],
  "bicuspid": ['B AY0 K AH1 S P AH0 D'],
  "bicuspids": ['B AY0 K AH1 S P AH0 D Z'],
  "bicycle": ['B AY1 S IH0 K AH0 L'],
  "bicycled": ['B AY1 S IH0 K AH0 L D'],
  "bicycles": ['B AY1 S IH0 K AH0 L Z'],
  "bicycling": ['B AY1 S IH2 K AH0 L IH0 NG', 'B AY1 S IH2 K L IH0 NG'],
  "bicyclist": ['B AY1 S IH2 K L IH0 S T'],
  "bicyclists": ['B AY1 S IH2 K L IH0 S T S', 'B AY1 S IH2 K L IH0 S'],
  "bid": ['B IH1 D'],
  "bid's": ['B IH1 D Z'],
  "bidco": ['B IH1 D K OW0'],
  "bidcos": ['B IH1 D K OW0 S'],
  "biddable": ['B IH1 D AH0 B AH0 L'],
  "bidden": ['B IH1 D IH0 N'],
  "bidder": ['B IH1 D ER0'],
  "bidder's": ['B IH1 D ER0 Z'],
  "bidders": ['B IH1 D ER0 Z'],
  "biddie": ['B IH1 D IY0'],
  "bidding": ['B IH1 D IH0 NG'],
  "biddinger": ['B IH1 D IH0 NG ER0'],
  "biddison": ['B IH1 D IH0 S AH0 N'],
  "biddix": ['B IH1 D IH0 K S'],
  "biddle": ['B IH1 D AH0 L'],
  "biddle's": ['B IH1 D AH0 L Z'],
  "biddy": ['B IH1 D IY0'],
  "bide": ['B AY1 D'],
  "biden": ['B AY1 D AH0 N'],
  "biderman": ['B AY1 D ER0 M AH0 N'],
  "bidgood": ['B IH1 D G UH2 D'],
  "biding": ['B AY1 D IH0 NG'],
  "bidinger": ['B AY1 D IH0 NG ER0'],
  "bidirectional": ['B IH1 D ER0 EH1 K SH AH0 N AH0 L'],
  "bidlack": ['B IH1 D L AE2 K'],
  "bidlo": ['B IH1 D L OW0'],
  "bidlo's": ['B IH1 D L OW0 Z'],
  "bids": ['B IH1 D Z'],
  "bidwell": ['B IH1 D W EH2 L'],
  "bidwill": ['B IH1 D W IH2 L'],
  "biebel": ['B IY1 B AH0 L'],
  "bieber": ['B IY1 B ER0'],
  "bieda": ['B IY1 D AH0'],
  "biederman": ['B IY1 D ER0 M AH0 N'],
  "biedermann": ['B AY1 D ER0 M AH0 N'],
  "biedermeier": ['B IY1 D ER0 M AY2 R'],
  "biedrzycki": ['B IH0 JH IH1 T S K IY0'],
  "biegel": ['B IY1 G AH0 L'],
  "biegler": ['B IY1 G AH0 L ER0', 'B IY1 G L ER0'],
  "biehl": ['B IY1 L'],
  "biehle": ['B IY1 HH AH0 L'],
  "biehler": ['B IY1 L ER0'],
  "biehn": ['B IY1 N'],
  "bieker": ['B IY1 K ER0'],
  "biel": ['B IY1 L'],
  "biela": ['B IY1 L AH0'],
  "bielak": ['B IY1 L AH0 K'],
  "bielanski": ['B IY0 L AE1 N S K IY0'],
  "bielat": ['B IY1 L AH0 T'],
  "bielawski": ['B IY0 L AA1 F S K IY0'],
  "bielby": ['B IY1 L B IY0'],
  "bielecki": ['B IY0 L EH1 T S K IY0', 'B AY0 L EH1 K IY0'],
  "bielefeld": ['B IY1 L IH0 F EH0 L D'],
  "bielefeldt": ['B IY1 L IH0 F IH0 L T'],
  "bielen": ['B IY1 L AH0 N'],
  "bielenberg": ['B IY1 L AH0 N B ER0 G'],
  "bieler": ['B IY1 L ER0'],
  "bielicki": ['B IH0 L IH1 T S K IY0'],
  "bielinski": ['B IH0 L IH1 N S K IY0'],
  "bielke": ['B IY1 L K'],
  "bielski": ['B IY1 L S K IY0'],
  "bien": ['B IY1 N'],
  "bieniek": ['B IH1 N IY0 EH0 K'],
  "bienkowski": ['B IH0 NG K AO1 F S K IY0'],
  "biennale": ['B IY0 EH1 N EY2 L'],
  "biennial": ['B AY0 EH1 N IY0 AH0 L'],
  "bienstock": ['B IY1 N S T AA2 K'],
  "bienvenu": ['B AH0 N V EH1 N UW0'],
  "bienvenue": ['B AH0 N V EY1 N W EH0'],
  "bier": ['B IY1 R'],
  "bierbarror": ['B IH1 R B AA2 R ER0'],
  "bierbauer": ['B IH1 R B AW0 ER0'],
  "bierbauer's": ['B IH1 R B AW0 ER0 Z'],
  "bierbaum": ['B IH1 R B AW0 M'],
  "bierbusse": ['B IH1 R B AH0 S'],
  "bierce": ['B IH1 R S'],
  "bierer": ['B IH1 R ER0'],
  "bieri": ['B IH1 R IY0'],
  "bierlein": ['B IH1 R L AY0 N'],
  "bierley": ['B IH1 R L IY0'],
  "bierly": ['B IH1 R L IY0'],
  "bierma": ['B IH1 R M AH0'],
  "bierman": ['B IH1 R M AH0 N'],
  "biermann": ['B IH1 R M AH0 N'],
  "biernacki": ['B IH0 R N AA1 T S K IY0'],
  "biernat": ['B IH0 R N AE1 T'],
  "biers": ['B IY1 R Z'],
  "bierwirth": ['B IH1 R W ER0 TH'],
  "biery": ['B IH1 R IY0'],
  "bies": ['B AY1 Z'],
  "biesecker": ['B IY1 S IH0 K ER0'],
  "bieser": ['B IY1 S ER0'],
  "bietz": ['B IY1 T S'],
  "biev": ['B IY1 V'],
  "biev's": ['B IY1 V Z'],
  "biever": ['B IY1 V ER0'],
  "bifano": ['B IH0 F AA1 N OW0'],
  "biff": ['B IH1 F'],
  "biffle": ['B IH1 F AH0 L'],
  "bifida": ['B IH1 F AH0 D AH0'],
  "bifocal": ['B AY1 F OW0 K AH0 L'],
  "bifocals": ['B AY1 F OW0 K AH0 L Z'],
  "bifulco": ['B IH0 F UW1 L K OW0'],
  "bifurcate": ['B IH1 F ER0 K EY2 T', 'B AY1 F ER0 K EY2 T'],
  "bifurcated": ['B IH1 F ER0 K EY2 T IH0 D', 'B AY1 F ER0 K EY2 T IH0 D'],
  "bifurcation": ['B IH2 F ER0 K EY1 SH AH0 N', 'B AY2 F ER0 K EY1 SH AH0 N'],
  "big": ['B IH1 G'],
  "big-ass": ['B IH1 G AE2 S'],
  "big-eyed": ['B IH1 G AY2 D'],
  "big-face": ['B IH1 G F EY2 S'],
  "big-time": ['B IH1 G T AY2 M'],
  "bigan": ['B IH1 G AH0 N'],
  "bigbee": ['B IH1 G B IY2'],
  "bigbie": ['B IH1 G B IY0'],
  "bigby": ['B IH1 G B IY0'],
  "bigelow": ['B IH1 G AH0 L OW2'],
  "bigelow's": ['B IH1 G AH0 L OW2 Z'],
  "bigeyes": ['B IH1 G AY2 Z'],
  "bigfoot": ['B IH1 G F UH2 T'],
  "bigford": ['B IH1 G F ER0 D'],
  "biggar": ['B IH1 G ER0'],
  "bigger": ['B IH1 G ER0'],
  "biggers": ['B IH1 G ER0 Z'],
  "biggerstaff": ['B IH1 G ER0 S T AE2 F'],
  "biggest": ['B IH1 G AH0 S T'],
  "biggie": ['B IH1 G IY0'],
  "biggies": ['B IH1 G IY0 Z'],
  "biggins": ['B IH1 G IH0 N Z'],
  "biggio": ['B IY1 JH IY0 OW0'],
  "biggs": ['B IH1 G Z'],
  "bigham": ['B AY1 G HH AH0 M'],
  "bighearted": ['B IH2 G HH AA1 R T IH0 D'],
  "bighorn": ['B IH1 G HH AO2 R N'],
  "bighorns": ['B IH1 G HH AO2 R N Z'],
  "bight": ['B AY1 T'],
  "bigler": ['B AY1 G AH0 L ER0', 'B AY1 G L ER0', 'B IH1 G L ER0'],
  "bigley": ['B IH1 G L IY0'],
  "biglin": ['B IH1 G L IH0 N'],
  "biglow": ['B IH1 G L OW0'],
  "bignell": ['B IH0 G N EH1 L'],
  "bigness": ['B IH1 G N AH0 S'],
  "bigos": ['B IY1 G OW0 Z'],
  "bigot": ['B IH1 G AH0 T'],
  "bigoted": ['B IH1 G AH0 T IH0 D'],
  "bigotry": ['B IH1 G AH0 T R IY0'],
  "bigots": ['B IH1 G AH0 T S'],
  "bigram": ['B AY1 G R AE0 M'],
  "bigs": ['B IH1 G Z'],
  "bigsby": ['B IH1 G Z B IY0'],
  "bigtime": ['B IH1 G T AY0 M'],
  "bigwig": ['B IH1 G W IH2 G'],
  "bigwigs": ['B IH1 G W IH2 G Z'],
  "bigwood": ['B IH1 G W UH2 D'],
  "bihac": ['B IY1 HH AA2 CH'],
  "bihac's": ['B IY1 HH AA2 CH IH0 Z'],
  "bihari": ['B IH0 HH AA1 R IY0', 'B IY0 HH AA1 R IY0'],
  "bihl": ['B IH1 L'],
  "bihm": ['B IH1 M'],
  "bihn": ['B IH1 N'],
  "bijac": ['B AY1 JH AE0 K', 'B IY1 JH AE0 K'],
  "bijan": ['B IH1 JH AH0 N'],
  "bijeljina": ['B IH0 JH EH1 L JH IY0 N AH0'],
  "bijur": ['B IY0 ZH UH1 R'],
  "bike": ['B AY1 K'],
  "biked": ['B AY1 K T'],
  "biker": ['B AY1 K ER0'],
  "bikers": ['B AY1 K ER0 Z'],
  "bikes": ['B AY1 K S'],
  "bikin": ['B IH1 K IH0 N'],
  "biking": ['B AY1 K IH0 NG'],
  "bikini": ['B IH0 K IY1 N IY0'],
  "bikinis": ['B AH0 K IY1 N IY0 Z'],
  "biko": ['B IY1 K OW0'],
  "biko's": ['B IY1 K OW0 Z'],
  "bil": ['B IH1 L'],
  "bila": ['B IY1 L AH0'],
  "bilateral": ['B AY0 L AE1 T ER0 AH0 L'],
  "bilaterally": ['B AY0 L AE1 T ER0 AH0 L IY0'],
  "bilbao": ['B IH0 L B AW1'],
  "bilbao's": ['B IH0 L B AW1 Z'],
  "bilbaoan": ['B IH0 L B OW1 AH0 N'],
  "bilbaoans": ['B IH0 L B OW1 AH0 N Z'],
  "bilbo": ['B IH1 L B OW2'],
  "bilbrey": ['B IH1 L B R IY0'],
  "bilbro": ['B IY1 L B R OW0'],
  "bilby": ['B IH1 L B IY0'],
  "bild": ['B IH1 L D'],
  "bilderback": ['B AY1 L D ER0 B AE0 K'],
  "bildner": ['B IH1 L D N ER0'],
  "bildt": ['B IH1 L T'],
  "bildt's": ['B IH1 L T S'],
  "bile": ['B AY1 L'],
  "bilek": ['B IH1 L EH0 K'],
  "bilello": ['B IH0 L EH1 L OW0'],
  "biles": ['B AY1 L Z'],
  "bilger": ['B IH1 L G ER0'],
  "bilicki": ['B IH0 L IH1 T S K IY0'],
  "bilings": ['B AY1 L IH0 NG Z'],
  "bilingual": ['B AY0 L IH1 NG G W AH0 L'],
  "bilingualism": ['B AY0 L IH1 NG G W AH0 L IH2 Z AH0 M'],
  "bilinski": ['B IH0 L IH1 N S K IY0'],
  "bilious": ['B IH1 L IY0 AH0 S'],
  "bilirakis": ['B IH0 L IH1 R AH0 K IH0 S'],
  "biljana": ['B IY0 L JH AA1 N AA2'],
  "bilk": ['B IH1 L K'],
  "bilka": ['B IH1 L K AH0'],
  "bilked": ['B IH1 L K T'],
  "bilking": ['B IH1 L K IH0 NG'],
  "bilko": ['B IH1 L K OW0'],
  "bill": ['B IH1 L'],
  "bill's": ['B IH1 L Z'],
  "billable": ['B IH1 L AH0 B AH0 L'],
  "billancourt": ['B IH1 L AH0 N K AO2 R T'],
  "billard": ['B IH0 L AA1 R D'],
  "billboard": ['B IH1 L B AO2 R D'],
  "billboard's": ['B IH1 L B AO2 R D Z'],
  "billboards": ['B IH1 L B AO2 R D Z'],
  "bille": ['B AY1 L'],
  "billed": ['B IH1 L D'],
  "biller": ['B IH1 L ER0'],
  "billerica": ['B IH2 L ER0 IY1 K AH0'],
  "billes": ['B IH1 L Z'],
  "billet": ['B IH1 L AH0 T', 'B IH1 L IH0 T'],
  "billeter": ['B IH1 L IY0 T ER0'],
  "billets": ['B IH1 L AH0 T S'],
  "billett": ['B IH1 L IH0 T'],
  "billfold": ['B IH1 L F OW2 L D'],
  "billiad": ['B IH1 L IY0 AH0 D'],
  "billiard": ['B IH1 L Y ER0 D'],
  "billiards": ['B IH1 L Y ER0 D Z'],
  "billick": ['B IH1 L IH0 K'],
  "billie": ['B IH1 L IY0'],
  "billig": ['B IH1 L IH0 G'],
  "billing": ['B IH1 L IH0 NG'],
  "billinger": ['B IH1 L IH0 NG ER0'],
  "billingham": ['B IH1 L IH0 NG HH AE2 M'],
  "billings": ['B IH1 L IH0 NG Z'],
  "billingslea": ['B IH1 L IH0 NG Z L IY0'],
  "billingsley": ['B IH1 L IH0 NG Z L IY0'],
  "billingsly": ['B IH1 L IH0 NG Z L IY0'],
  "billington": ['B IH1 L IH0 NG T AH0 N'],
  "billion": ['B IH1 L Y AH0 N'],
  "billionaire": ['B IH2 L Y AH0 N EH1 R'],
  "billionaires": ['B IH2 L Y AH0 N EH1 R Z'],
  "billions": ['B IH1 L Y AH0 N Z'],
  "billionth": ['B IH1 L Y AH0 N TH'],
  "billionths": ['B IH1 L Y AH0 N TH S'],
  "billiot": ['B IH1 L IY0 AA0 T'],
  "billips": ['B IH1 L IH0 P S'],
  "billiter": ['B IH1 L IY0 T ER0'],
  "billiton": ['B IH1 L IH0 T AH0 N'],
  "billman": ['B IH1 L M AH0 N'],
  "billmeyer": ['B IH1 L M AY0 ER0'],
  "billon": ['B IH1 L AH0 N'],
  "billow": ['B IH1 L OW0'],
  "billowed": ['B IH1 L OW0 D'],
  "billowing": ['B IH1 L OW0 IH0 NG'],
  "billows": ['B IH1 L OW0 Z'],
  "bills": ['B IH1 L Z'],
  "bills'": ['B IH1 L Z'],
  "billup": ['B IH1 L AH0 P'],
  "billups": ['B IH1 L AH0 P S'],
  "billy": ['B IH1 L IY0'],
  "billy's": ['B IH1 L IY0 Z'],
  "bilodeau": ['B IH1 L AH0 D OW0'],
  "bilotta": ['B IH0 L OW1 T AH0'],
  "bilotti": ['B IH0 L AA1 T IY0'],
  "bilow": ['B IH1 L OW0'],
  "biloxi": ['B AH0 L AH1 K S IY0'],
  "biloxi's": ['B AH0 L AH1 K S IY0 Z'],
  "bilski": ['B IH1 L S K IY0'],
  "bilsky": ['B IH1 L S K IY0'],
  "bilson": ['B IH1 L S AH0 N'],
  "biltmore": ['B IH1 L T M AO2 R'],
  "bilton": ['B IH1 L T AH0 N'],
  "biltz": ['B IH1 L T S'],
  "bily": ['B IH1 L IY0'],
  "bilyeu": ['B IH1 L IY0 UW0'],
  "bilyk": ['B IH1 L IH0 K'],
  "bilzerian": ['B IH0 L Z EH1 R IY0 AH0 N'],
  "bilzerian's": ['B IH0 L Z EH1 R IY0 AH0 N Z'],
  "bima": ['B IY1 M AH0'],
  "bimbo": ['B IH1 M B OW0'],
  "bimbos": ['B IH1 M B OW0 S'],
  "bimini": ['B IH1 M AH0 N IY0', 'B IH0 M IY1 N IY0'],
  "bimodal": ['B AY2 M OW1 D AH0 L'],
  "bimonthly": ['B AY0 M AH1 N TH L IY0'],
  "bin": ['B IH1 N'],
  "bina": ['B IY1 N AH0'],
  "binaries": ['B AY1 N AH0 R IY2 Z'],
  "binary": ['B AY1 N ER0 IY0'],
  "binational": ['B AY0 N AE1 SH AH0 N AH0 L'],
  "binchy": ['B IH1 N CH IY0'],
  "bind": ['B AY1 N D'],
  "bindel": ['B IH1 N D AH0 L'],
  "binder": ['B AY1 N D ER0'],
  "binders": ['B AY1 N D ER0 Z'],
  "binding": ['B AY1 N D IH0 NG'],
  "bindings": ['B AY1 N D IH0 NG Z'],
  "bindle": ['B IH1 N D AH0 L'],
  "bindles": ['B IH1 N D AH0 L Z'],
  "bindley": ['B IH1 N D L IY0'],
  "binds": ['B AY1 N D Z'],
  "binegar": ['B IH1 N IH0 G ER0'],
  "bines": ['B AY1 N Z'],
  "binette": ['B IH0 N EH1 T'],
  "binetti": ['B IH0 N EH1 T IY0'],
  "binfield": ['B IH1 N F IY2 L D'],
  "binford": ['B IH1 N F ER0 D'],
  "bing": ['B IH1 NG'],
  "binga": ['B IY1 NG G AH0'],
  "bingaman": ['B IH1 NG AH0 M AH0 N'],
  "binge": ['B IH1 N JH'],
  "binged": ['B IH1 N JH D'],
  "bingel": ['B IH1 NG G AH0 L'],
  "bingenheimer": ['B IH1 NG G IH0 N HH AY0 M ER0'],
  "binger": ['B IH1 NG ER0'],
  "binges": ['B IH1 N JH IH0 Z'],
  "bingham": ['B IH1 NG AH0 M'],
  "binghamton": ['B IH1 NG AH0 M T AH0 N'],
  "binging": ['B IH1 NG G IH0 NG'],
  "bingle": ['B IH1 NG G AH0 L'],
  "bingley": ['B IH1 NG L IY0'],
  "bingman": ['B IH1 NG M AH0 N'],
  "bingo": ['B IH1 NG G OW0'],
  "binion": ['B IH1 N Y AH0 N'],
  "bink": ['B IH1 NG K'],
  "binkley": ['B IH1 NG K L IY0'],
  "binkowski": ['B IH0 NG K AO1 F S K IY0'],
  "binn": ['B IH1 N'],
  "binner": ['B IH1 N ER0'],
  "binney": ['B IH1 N IY0'],
  "binnie": ['B IH1 N IY0'],
  "binning": ['B IH1 N IH0 NG'],
  "binns": ['B IH1 N Z'],
  "binocular": ['B AH0 N AA1 K Y AH0 L ER0'],
  "binoculars": ['B AH0 N AA1 K Y AH0 L ER0 Z'],
  "binomial": ['B AY0 N OW1 M IY0 AH0 L'],
  "bins": ['B IH1 N Z'],
  "binstock": ['B IH1 N S T AA2 K'],
  "bintz": ['B IH1 N T S'],
  "binz": ['B IH1 N Z'],
  "bio": ['B AY2 OW1'],
  "bio's": ['B AY2 OW1 Z'],
  "biochem": ['B AY1 AH0 CH AH0 M'],
  "biochemical": ['B AY2 OW0 K EH1 M AH0 K AH0 L', 'B AY2 OW0 K EH1 M IH0 K AH0 L'],
  "biochemist": ['B AY2 OW0 K EH1 M AH0 S T'],
  "biochemistry": ['B AY2 OW0 K EH1 M AH0 S T R IY0'],
  "biocine": ['B AY1 AH0 S IY2 N'],
  "biocontrol": ['B AY2 AH0 K AA1 N T R AA0 L'],
  "biocraft": ['B AY1 OW0 K R AE2 F T'],
  "biodegradable": ['B AY2 OW0 D AH0 G R EY1 D AH0 B AH0 L'],
  "biodiverse": ['B AY2 OW0 D AY0 V ER1 S'],
  "biodiversity": ['B AY2 OW0 D AY0 V ER1 S AH0 T IY0'],
  "bioengineer": ['B AY2 OW0 EH2 N JH AH0 N IH1 R'],
  "bioengineered": ['B AY2 OW0 EH2 N JH AH0 N IH1 R D'],
  "bioengineering": ['B AY2 OW0 EH2 N JH AH0 N IH1 R IH0 NG'],
  "bioethics": ['B AY2 OW0 EH1 TH IH0 K S'],
  "biofeedback": ['B AY0 OW0 F IY1 D B AE2 K'],
  "bioflavonoid": ['B AY2 OW0 F L EY1 V AH0 N OY0 D'],
  "bioflavonoids": ['B AY2 OW0 F L EY1 V AH0 N OY0 D Z'],
  "biogen": ['B AY1 OW0 JH EH2 N'],
  "biogen's": ['B AY1 OW0 JH EH2 N Z'],
  "biographer": ['B AY0 AA1 G R AH0 F ER0'],
  "biographers": ['B AY0 AA1 G R AH0 F ER0 Z'],
  "biographical": ['B AY2 AH0 G R AE1 F IH0 K AH0 L'],
  "biographies": ['B AY0 AA1 G R AH0 F IY0 Z'],
  "biography": ['B AY0 AA1 G R AH0 F IY0'],
  "biohazard": ['B AY2 OW0 HH AE1 Z ER0 D'],
  "biohazards": ['B AY2 OW0 HH AE1 Z ER0 D Z'],
  "biologic": ['B AY2 AH0 L AA1 JH IH0 K'],
  "biological": ['B AY2 AH0 L AA1 JH IH0 K AH0 L'],
  "biologically": ['B AY0 AH0 L AA1 JH IH0 K L IY0'],
  "biologicals": ['B AY0 AH0 L AA1 JH IH0 K AH0 L Z'],
  "biologics": ['B AY0 AH0 L AA1 JH IH0 K S'],
  "biologist": ['B AY0 AA1 L AH0 JH IH0 S T'],
  "biologists": ['B AY0 AA1 L AH0 JH IH0 S T S'],
  "biology": ['B AY0 AA1 L AH0 JH IY0'],
  "biology's": ['B AY0 AA1 L AH0 JH IY0 Z'],
  "biomass": ['B AY1 AH0 M AE0 S'],
  "biomaterial": ['B AY2 OW0 M AH0 T IH1 R IY0 AH0 L'],
  "biomaterials": ['B AY2 OW0 M AH0 T IH1 R IY0 AH0 L Z'],
  "biome": ['B AY1 OW2 M'],
  "biomed": ['B AY2 OW0 M EH1 D'],
  "biomed's": ['B AY2 OW0 M EH1 D Z'],
  "biomedical": ['B AY2 OW0 M EH1 D IH0 K AH0 L'],
  "biomedicals": ['B AY2 OW0 M EH1 D IH0 K AH0 L Z'],
  "biomes": ['B AY2 OW1 M Z'],
  "biomet": ['B AY1 OW0 M EH0 T'],
  "biometric": ['B AY2 AH0 M EH1 T R AH0 K'],
  "biondi": ['B IY0 AA1 N D IY0'],
  "biondo": ['B IY0 OW1 N D OW0'],
  "biondolillo": ['B IY0 OW0 N D OW0 L IH1 L OW0'],
  "bionetic": ['B AY2 OW0 N EH1 T IH0 K'],
  "bionetics": ['B AY2 OW0 N EH1 T IH0 K S'],
  "bionic": ['B AY2 AO1 N IH0 K'],
  "biopharm": ['B AY1 AH0 F AA0 R M'],
  "biopharmaceutical": ['B AY2 OW0 F AA2 R M AH0 S UW1 T IH0 K AH0 L'],
  "biopharmacy": ['B AY2 OW0 F AA1 R M AH0 S IY0'],
  "biophysics": ['B AY2 OW0 F IH1 S IH0 K S'],
  "biopic": ['B AY1 OW0 P IH2 K'],
  "biopsies": ['B AY1 AA0 P S IY0 Z'],
  "biopsy": ['B AY1 AA0 P S IY0'],
  "bios": ['B AY1 OW0 S'],
  "biosafety": ['B AY2 OW0 S EY1 F T IY0'],
  "bioscience": ['B AY2 OW0 S AY1 AH0 N S'],
  "biosciences": ['B AY0 AO1 S AY0 EH2 N S IH0 Z'],
  "biosensor": ['B AY2 OW0 S EH1 N S ER0'],
  "biosensors": ['B AY2 OW0 S EH1 N S ER0 Z'],
  "biosis": ['B IY0 OW1 Z IH0 S', 'B IY0 OW1 S IH2 S'],
  "biosphere": ['B AY1 OW0 S F IH2 R'],
  "biosphere's": ['B AY1 OW0 S F IH2 R Z'],
  "biospheres": ['B AY1 OW0 S F IH2 R Z'],
  "biospherian": ['B AY2 OW0 S F IH1 R IY0 AH0 N'],
  "biospherians": ['B AY2 OW0 S F IH1 R IY0 AH0 N Z'],
  "biostatistician": ['B AY2 OW0 S T AA0 T IH0 S T IH1 SH AH0 N'],
  "biostatistics": ['B AY2 OW0 S T AA0 T IH1 S T IH2 K S'],
  "biosynthesis": ['B AY2 OW0 S IH1 N TH EH0 S IH0 S'],
  "biosys": ['B AY1 OW0 S IH0 S'],
  "biosystem": ['B AY1 OW0 S IH2 S T AH0 M'],
  "biosystems": ['B AY1 OW0 S IH2 S T AH0 M Z'],
  "biotech": ['B AY1 OW0 T EH2 K'],
  "biotechnica": ['B AY2 OW0 T EH1 K N IH0 K AH0'],
  "biotechnica's": ['B AY2 OW0 T EH1 K N IH0 K AH0 Z'],
  "biotechnological": ['B AY2 OW0 T EH2 K N AH0 L AA1 JH IH0 K AH0 L'],
  "biotechnologies": ['B AY2 OW0 T EH2 K N AA1 L AH0 JH IY0 Z'],
  "biotechnology": ['B AY2 OW0 T EH2 K N AA1 L AH0 JH IY0'],
  "biotechnology's": ['B AY2 OW0 T EH2 K N AA1 L AH0 JH IY0 Z'],
  "biotechs": ['B AY1 OW0 T EH2 K S'],
  "biotherapeutic": ['B AY2 OW0 TH EH2 R AH0 P Y UW1 T IH0 K'],
  "biotherapeutics": ['B AY2 OW0 TH EH2 R AH0 P Y UW1 T IH0 K S'],
  "biotin": ['B AY1 AH0 T AH0 N'],
  "biotite": ['B AY1 AH0 T AY2 T'],
  "biovest": ['B AY1 OW0 V AH0 S T'],
  "bip": ['B IH1 P'],
  "bipartisan": ['B AY0 P AA1 R T IH0 Z AH0 N', 'B AY0 P AA1 R T IH0 S AH0 N'],
  "bipartisanship": ['B AY0 P AA1 R T AH0 Z AH0 N SH IH2 P', 'B AY0 P AA1 R T AH0 S AH0 N SH IH2 P'],
  "biphenyl": ['B IH1 F AH0 N AH0 L'],
  "biphenyls": ['B IH1 F AH0 N AH0 L Z'],
  "biplane": ['B AY1 P L EY2 N'],
  "bipolar": ['B AY0 P OW1 L ER0'],
  "bippus": ['B IH1 P AH0 S'],
  "bir": ['B ER1'],
  "biracial": ['B AY0 R EY1 SH AH0 L'],
  "birch": ['B ER1 CH'],
  "birch's": ['B ER1 CH IH0 Z'],
  "birchall": ['B ER1 K AH0 L'],
  "birchard": ['B ER1 K ER0 D'],
  "bircher": ['B ER1 CH ER0'],
  "birchett": ['B ER1 CH IH0 T'],
  "birchfield": ['B ER1 CH F IY2 L D'],
  "birchler": ['B ER1 K AH0 L ER0', 'B ER1 K L ER0'],
  "birchmeier": ['B ER1 K M AY0 ER0'],
  "birckhead": ['B ER1 K HH EH0 D'],
  "bird": ['B ER1 D'],
  "bird's": ['B ER1 D Z'],
  "birdbrain": ['B ER1 D B R EY2 N'],
  "birdcage": ['B ER1 D K EY0 JH'],
  "birddog": ['B ER1 D D AW2 G', 'B ER1 D AW2 G'],
  "birden": ['B ER1 D AH0 N'],
  "birder": ['B ER1 D ER0'],
  "birders": ['B ER1 D ER0 Z'],
  "birdfeather": ['B ER1 D F EH1 DH ER0'],
  "birdfeather's": ['B ER1 D F EH1 DH ER0 Z'],
  "birdfeathers": ['B ER1 D F EH1 DH ER0 Z'],
  "birdfeeder": ['B ER1 D F IY1 D ER0'],
  "birdfeeder's": ['B ER1 D F IY1 D ER0 Z'],
  "birdfeeders": ['B ER1 D F IY1 D ER0 Z'],
  "birdfinder": ['B ER1 D F AY2 N D ER0'],
  "birdhouse": ['B ER1 D HH AW0 S'],
  "birdhouses": ['B ER1 D HH AW0 S IH0 Z'],
  "birdie": ['B ER1 D IY0'],
  "birdied": ['B ER1 D IY0 D'],
  "birdies": ['B ER1 D IY0 Z'],
  "birdlife": ['B ER1 D L AY2 F'],
  "birdlike": ['B ER1 D L AY2 K'],
  "birdman": ['B ER1 D M AE0 N'],
  "birds": ['B ER1 D Z'],
  "birds'": ['B ER1 D Z'],
  "birdsall": ['B ER1 D Z AO2 L'],
  "birdseed": ['B ER1 D S IY2 D'],
  "birdsell": ['B ER1 D S AH0 L'],
  "birdseye": ['B ER1 D Z AY0'],
  "birdsong": ['B ER1 D S AO2 NG'],
  "birdwatcher": ['B ER1 D W AA2 CH ER0'],
  "birdwatchers": ['B ER1 D W AA2 CH ER0 Z'],
  "birdwatching": ['B ER1 D W AA2 CH IH0 NG'],
  "birdwell": ['B ER1 D W EH2 L'],
  "birdy": ['B ER1 D IY0'],
  "bireme": ['B AY1 R IY2 M'],
  "biremes": ['B AY1 R IY2 M Z'],
  "birenbaum": ['B AY1 R AH0 N B AW0 M'],
  "bires": ['B AY1 R Z'],
  "birge": ['B ER1 JH'],
  "birinyi": ['B IH0 R IY1 N Y IY0'],
  "birk": ['B ER1 K'],
  "birkedal": ['B ER1 K AH0 D AA2 L'],
  "birkel": ['B ER1 K AH0 L'],
  "birkeland": ['B ER1 K L AH0 N D'],
  "birkenau": ['B ER1 K AH0 N AW0'],
  "birkes": ['B ER1 K S'],
  "birkett": ['B ER1 K IH0 T'],
  "birkey": ['B ER1 K IY0'],
  "birkhead": ['B ER1 K HH EH0 D'],
  "birkhimer": ['B ER1 K HH IH0 M ER0'],
  "birkhoff": ['B ER1 K AO0 F'],
  "birkholz": ['B ER1 K HH OW0 L Z'],
  "birkland": ['B ER1 K L AH0 N D'],
  "birkner": ['B ER1 K N ER0'],
  "birks": ['B ER1 K S'],
  "birky": ['B ER1 K IY0'],
  "birle": ['B ER1 L'],
  "birley": ['B ER1 L IY0'],
  "birman": ['B ER1 M AH0 N'],
  "birmid": ['B ER1 M IH0 D'],
  "birmid's": ['B ER1 M IH0 D Z'],
  "birmingham": ['B ER1 M IH0 NG HH AE2 M'],
  "birnbaum": ['B ER1 N B AW0 M'],
  "birney": ['B ER1 N IY0'],
  "birnie": ['B ER1 N IY0'],
  "biro": ['B IH1 R OW0'],
  "biron": ['B AY1 R AH0 N'],
  "biros": ['B AY1 R OW0 Z'],
  "birr": ['B ER1'],
  "birren": ['B ER1 AH0 N'],
  "birt": ['B ER1 T'],
  "birtcher": ['B ER1 CH ER0'],
  "birth": ['B ER1 TH'],
  "birthday": ['B ER1 TH D EY2'],
  "birthdays": ['B ER1 TH D EY2 Z'],
  "birthing": ['B ER1 TH IH0 NG'],
  "birthmark": ['B ER1 TH M AA2 R K'],
  "birthmarks": ['B ER1 TH M AA2 R K S'],
  "birthplace": ['B ER1 TH P L EY2 S'],
  "birthplaces": ['B ER1 TH P L EY2 S IH0 Z'],
  "birthrate": ['B ER1 TH R EY2 T'],
  "birthrates": ['B ER1 TH R EY2 T S'],
  "birthright": ['B ER1 TH R AY2 T'],
  "birthrights": ['B ER1 TH R AY2 T S'],
  "births": ['B ER1 TH S'],
  "birtle": ['B ER1 T AH0 L'],
  "birtley": ['B ER1 T L IY0'],
  "birtley's": ['B ER1 T L IY0 Z'],
  "bis": ['B IH1 S'],
  "bisaillon": ['B AY1 S AH0 L AA0 N'],
  "bisbee": ['B IH1 S B IY0'],
  "bisbing": ['B IH1 S B IH0 NG'],
  "biscardi": ['B IH0 S K AA1 R D IY0'],
  "biscayne": ['B IH0 S K EY1 N'],
  "bisceglia": ['B IH0 S CH EH1 G L IY0 AH0'],
  "bisch": ['B IH1 SH'],
  "bischel": ['B IH1 SH AH0 L'],
  "bischof": ['B IH1 SH AH0 F'],
  "bischofberger": ['B IH1 SH AO0 F B ER0 G ER0'],
  "bischoff": ['B IH1 S K HH AO0 F'],
  "biscoe": ['B IH0 S K OW1'],
  "biscotti": ['B IH0 S K AO1 T IY0'],
  "biscuit": ['B IH1 S K AH0 T'],
  "biscuits": ['B IH1 S K AH0 T S'],
  "bise": ['B AY1 Z'],
  "bisek": ['B IH1 S IH0 K'],
  "bisel": ['B IH1 S AH0 L'],
  "biser": ['B AY1 Z ER0'],
  "bisesi": ['B IH0 S EH1 S IY0'],
  "bisexual": ['B AY2 S EH1 K SH UW0 AH0 L'],
  "bisexuality": ['B AY2 S EH0 K SH UW0 AE1 L AH0 T IY0'],
  "bisexuals": ['B AY2 S EH1 K SH UW0 AH0 L Z'],
  "bish": ['B IH1 SH'],
  "bisher": ['B IH1 SH ER0'],
  "bishoff": ['B IH1 S HH AO0 F'],
  "bishop": ['B IH1 SH AH0 P'],
  "bishop's": ['B IH1 SH AH0 P S'],
  "bishoprics": ['B IH1 SH AH0 P R IH0 K S'],
  "bishops": ['B IH1 SH AH0 P S'],
  "bishops'": ['B IH1 SH AA0 P S'],
  "bishopsgate": ['B IH1 SH AH0 P S G EY2 T'],
  "bisiani": ['B IH0 S IY0 AA1 N IY0'],
  "bisig": ['B IH1 S IH0 G'],
  "bisignano": ['B IH0 S IY0 G N AA1 N OW0'],
  "biskup": ['B IH1 S K AH0 P'],
  "bismarck": ['B IH1 Z M AA2 R K'],
  "bismarck's": ['B IH1 Z M AA2 R K S'],
  "bismark": ['B IH1 Z M AA2 R K'],
  "bismuth": ['B IH1 Z M AH0 TH'],
  "bison": ['B AY1 S AH0 N'],
  "bisping": ['B IH1 S P IH0 NG'],
  "bisque": ['B IH1 S K'],
  "bisquit": ['B IH1 S K IH0 T'],
  "biss": ['B IH1 S'],
  "bissell": ['B IH1 S AH0 L'],
  "bissen": ['B IH1 S AH0 N'],
  "bisset": ['B IH1 S IH0 T'],
  "bissett": ['B IH1 S IH0 T'],
  "bissette": ['B IH0 S EH1 T'],
  "bissey": ['B IH1 S IY0'],
  "bissinger": ['B IH1 S IH0 N JH ER0'],
  "bisso": ['B IY1 S OW0'],
  "bisson": ['B IH1 S AH0 N'],
  "bissonette": ['B IH1 S AH0 N EH0 T'],
  "bissonnette": ['B IH1 S AH0 N EH2 T'],
  "bistline": ['B IH1 S T L AY2 N'],
  "bistodeau": ['B IH1 S T AH0 D OW0'],
  "bistro": ['B IH1 S T R OW0'],
  "bisuteki": ['B IY2 S UW0 T EY1 K IY0'],
  "bisuteki's": ['B IY2 S UW0 T EY1 K IY0 Z'],
  "bit": ['B IH1 T'],
  "bitar": ['B IH1 T ER0'],
  "bitch": ['B IH1 CH'],
  "bitches": ['B IH1 CH IH0 Z'],
  "bitchiness": ['B IH1 CH IY0 N AH0 S'],
  "bitching": ['B IH1 CH IH0 NG'],
  "bitchy": ['B IH1 CH IY0'],
  "bitcoin": ['B IH1 T K OY1 N'],
  "bitcoins": ['B IH1 T K OY1 N Z'],
  "bite": ['B AY1 T'],
  "biter": ['B AY1 T ER0'],
  "bites": ['B AY1 T S'],
  "bitesize": ['B IH1 T S AY2 Z'],
  "bither": ['B IH1 DH ER0'],
  "biting": ['B AY1 T IH0 NG'],
  "bitler": ['B AY1 T AH0 L ER0', 'B AY1 T L ER0'],
  "bitner": ['B IH1 T N ER0'],
  "bitney": ['B IH1 T N IY0'],
  "bits": ['B IH1 T S'],
  "bitsy": ['B IH1 T S IY0'],
  "bittel": ['B IH1 T AH0 L'],
  "bitten": ['B IH1 T AH0 N'],
  "bittenbender": ['B IH1 T IH0 N B EH2 N D ER0'],
  "bitter": ['B IH1 T ER0'],
  "bitterest": ['B IH1 T ER0 AH0 S T'],
  "bitterly": ['B IH1 T ER0 L IY0'],
  "bitterman": ['B IH1 T ER0 M AH0 N'],
  "bitterman's": ['B IH1 T ER0 M AH0 N Z'],
  "bittermann": ['B IH1 T ER0 M AH0 N'],
  "bittermann's": ['B IH1 T ER0 M AH0 N Z'],
  "bitterness": ['B IH1 T ER0 N AH0 S'],
  "bitterroot": ['B IH1 T ER0 R UW2 T'],
  "bitters": ['B IH1 T ER0 Z'],
  "bittersweet": ['B IH1 T ER0 S W IY2 T'],
  "bittick": ['B IH1 T IH0 K'],
  "bitting": ['B IH1 T IH0 NG'],
  "bittinger": ['B IH1 T IH0 NG ER0'],
  "bittle": ['B IH1 T AH0 L'],
  "bittman": ['B IH1 T M AH0 N'],
  "bittner": ['B IH1 T N ER0'],
  "bitton": ['B IH1 T AH0 N'],
  "bitty": ['B IH1 T IY0'],
  "bitumen": ['B IH2 T UW1 M AH0 N', 'B AY2 T UW1 M AH0 N'],
  "bituminous": ['B IH0 T UW1 M AH0 N AH0 S'],
  "bitz": ['B IH1 T S'],
  "bitzer": ['B IH1 T Z ER0'],
  "bivalve": ['B AY1 V AE2 L V'],
  "bivalves": ['B AY1 V AE2 L V Z'],
  "biven": ['B AY1 V AH0 N'],
  "bivens": ['B AY1 V AH0 N Z'],
  "biviano": ['B IY2 V IY0 AA1 N OW0'],
  "bivin": ['B IH1 V IH0 N'],
  "bivins": ['B IH1 V IH0 N Z'],
  "bivona": ['B IH0 V OW1 N AH0'],
  "bivouac": ['B IH1 V W AE0 K'],
  "biweekly": ['B AY0 W IY1 K L IY0'],
  "bix": ['B IH1 K S'],
  "bixby": ['B IH1 K S B IY0'],
  "bixel": ['B IH1 K S AH0 L'],
  "bixler": ['B IH1 K S L ER0'],
  "biz": ['B IH1 Z'],
  "bizango": ['B IH0 Z AE1 NG G OW0'],
  "bizarre": ['B AH0 Z AA1 R', 'B IH0 Z AA1 R'],
  "bizet": ['B IH0 Z EH1 T'],
  "bizmart": ['B IH1 Z M AA2 R T'],
  "bizrate": ['B IH1 Z EY2 T'],
  "bizub": ['B IH1 Z AH0 B'],
  "bizzaro": ['B IH0 Z AA1 R OW0'],
  "bizzell": ['B IH1 Z AH0 L'],
  "bizzy": ['B IH1 Z IY0'],
  "bjelasnica": ['B Y EH0 L AE1 S N IH0 K AH0'],
  "bjelland": ['B Y EH1 L AH0 N D'],
  "bjerke": ['B Y ER1 K'],
  "bjoern": ['B Y AO1 R N'],
  "bjorge": ['B Y AO1 R G'],
  "bjork": ['B Y AO1 R K'],
  "bjorklund": ['B Y AO1 R K L AH0 N D'],
  "bjorkman": ['B Y AO1 R K M AH0 N'],
  "bjorn": ['B Y AO1 R N'],
  "bjornstad": ['B Y AO1 R N S T AH0 D'],
  "blab": ['B L AE1 B'],
  "blabbed": ['B L AE1 B D'],
  "blabber": ['B L AE1 B ER0'],
  "blabbers": ['B L AE1 B ER0 Z'],
  "blachly": ['B L AA1 CH L IY0', 'B L AA1 K L IY0'],
  "black": ['B L AE1 K'],
  "black's": ['B L AE1 K S'],
  "blackard": ['B L AE1 K ER0 D'],
  "blackball": ['B L AE1 K B AO2 L'],
  "blackberries": ['B L AE1 K B EH2 R IY0 Z'],
  "blackberry": ['B L AE1 K B EH2 R IY0'],
  "blackbird": ['B L AE1 K B ER0 D'],
  "blackbirds": ['B L AE1 K B ER0 D Z'],
  "blackboard": ['B L AE1 K B AO2 R D'],
  "blackboards": ['B L AE1 K B AO2 R D Z'],
  "blackburn": ['B L AE1 K B ER0 N'],
  "blacked": ['B L AE1 K T'],
  "blacken": ['B L AE1 K AH0 N'],
  "blackened": ['B L AE1 K AH0 N D'],
  "blackening": ['B L AE1 K AH0 N IH0 NG', 'B L AE1 K N IH0 NG'],
  "blackens": ['B L AE1 K AH0 N Z'],
  "blacker": ['B L AE1 K ER0'],
  "blackerby": ['B L AE1 K ER0 B IY0'],
  "blackest": ['B L AE1 K AH0 S T'],
  "blacketer": ['B L AE1 K IY0 T ER0'],
  "blackett": ['B L AE1 K IH0 T'],
  "blackfoot": ['B L AE1 K F UH2 T'],
  "blackford": ['B L AE1 K F ER0 D'],
  "blackham": ['B L AE1 K HH AH0 M'],
  "blackhawk": ['B L AE1 K HH AO2 K'],
  "blackhawk's": ['B L AE1 K HH AO2 K S'],
  "blackhawks": ['B L AE1 K HH AO2 K S'],
  "blackheath": ['B L AE1 K HH IY2 TH'],
  "blackhurst": ['B L AE1 K HH ER0 S T'],
  "blackie": ['B L AE1 K IY0'],
  "blackjack": ['B L AE1 K JH AE2 K'],
  "blackledge": ['B L AE1 K L EH2 JH'],
  "blackley": ['B L AE1 K L IY0'],
  "blacklist": ['B L AE1 K L IH2 S T'],
  "blacklisted": ['B L AE1 K L IH2 S T IH0 D'],
  "blacklisting": ['B L AE1 K L IH2 S T IH0 NG'],
  "blacklock": ['B L AE1 K L AA2 K'],
  "blackmail": ['B L AE1 K M EY2 L'],
  "blackmailed": ['B L AE1 K M EY2 L D'],
  "blackmailing": ['B L AE1 K M EY2 L IH0 NG'],
  "blackman": ['B L AE1 K M AH0 N'],
  "blackmer": ['B L AE1 K M ER0'],
  "blackmon": ['B L AE1 K M AH0 N'],
  "blackmore": ['B L AE1 K M AO0 R'],
  "blackmun": ['B L AE1 K M AH0 N'],
  "blackmun's": ['B L AE1 K M AH0 N Z'],
  "blackness": ['B L AE1 K N AH0 S'],
  "blackout": ['B L AE1 K AW2 T'],
  "blackouts": ['B L AE1 K AW2 T S'],
  "blackpool": ['B L AE1 K P UW2 L'],
  "blackrock": ['B L AE1 K R AA2 K'],
  "blacks": ['B L AE1 K S'],
  "blacks'": ['B L AE1 K S'],
  "blacksburg": ['B L AE1 K S B ER0 G'],
  "blackshear": ['B L AE1 K SH IH0 R'],
  "blacksher": ['B L AE1 K SH ER0'],
  "blackshire": ['B L AE1 K SH AY2 R'],
  "blacksmith": ['B L AE1 K S M IH2 TH'],
  "blackson": ['B L AE1 K S AH0 N'],
  "blackstock": ['B L AE1 K S T AA2 K'],
  "blackston": ['B L AE1 K S T AH0 N'],
  "blackstone": ['B L AE1 K S T OW2 N'],
  "blackstone's": ['B L AE1 K S T OW2 N Z'],
  "blacktop": ['B L AE1 K T AA2 P'],
  "blackwelder": ['B L AE1 K W EH2 L D ER0'],
  "blackwell": ['B L AE1 K W EH2 L'],
  "blackwood": ['B L AE1 K W UH2 D'],
  "bladder": ['B L AE1 D ER0'],
  "bladders": ['B L AE1 D ER0 Z'],
  "blade": ['B L EY1 D'],
  "bladed": ['B L EY1 D IH0 D'],
  "bladen": ['B L EY1 D AH0 N'],
  "blades": ['B L EY1 D Z'],
  "blading": ['B L EY1 D IH0 NG'],
  "bladow": ['B L AE1 D OW0'],
  "blaese": ['B L EY1 Z'],
  "blaesing": ['B L EH1 S IH0 NG'],
  "blagden": ['B L AE1 G D AH0 N'],
  "blagg": ['B L AE1 G'],
  "blah": ['B L AA1'],
  "blaha": ['B L AA1 HH AH0'],
  "blahnik": ['B L AA1 N IH0 K'],
  "blahut": ['B L AE1 HH AH0 T'],
  "blaich": ['B L EY1 CH'],
  "blaikie": ['B L EY1 K IY0'],
  "blain": ['B L EY1 N'],
  "blaine": ['B L EY1 N'],
  "blair": ['B L EH1 R'],
  "blair's": ['B L EH1 R Z'],
  "blais": ['B L EH1 Z'],
  "blaisdell": ['B L EY1 S D AH0 L'],
  "blaise": ['B L EY1 Z'],
  "blaiser": ['B L EY1 Z ER0'],
  "blaize": ['B L EY1 Z'],
  "blake": ['B L EY1 K'],
  "blake's": ['B L EY1 K S'],
  "blakeley": ['B L EY1 K L IY0'],
  "blakeley's": ['B L EY1 K L IY0 Z'],
  "blakely": ['B L EY1 K L IY0'],
  "blakely's": ['B L EY1 K L IY0 Z'],
  "blakeman": ['B L EY1 K M AH0 N'],
  "blakemore": ['B L EY1 K M AO0 R'],
  "blakeney": ['B L EY1 K N IY0'],
  "blakeney's": ['B L EY1 K N IY0 Z'],
  "blakenham": ['B L EY1 K AH0 N HH AE2 M'],
  "blakenship": ['B L EY1 K AH0 N SH IH0 P'],
  "blaker": ['B L EY1 K ER0'],
  "blakes": ['B L EY1 K S'],
  "blakeslee": ['B L EY1 K S L IY0'],
  "blakesley": ['B L EY1 K S L IY0'],
  "blakey": ['B L EY1 K IY0'],
  "blakley": ['B L AE1 K L IY0'],
  "blakney": ['B L AE1 K N IY0'],
  "blalack": ['B L AE1 L AH0 K'],
  "blalock": ['B L AE1 L AA0 K'],
  "blame": ['B L EY1 M'],
  "blamed": ['B L EY1 M D'],
  "blameless": ['B L EY1 M L AH0 S'],
  "blames": ['B L EY1 M Z'],
  "blamestrorm": ['B L EY1 M S T AO2 R M'],
  "blaming": ['B L EY1 M IH0 NG'],
  "blampied": ['B L AE1 M P IY0 D'],
  "blan": ['B L AE1 N'],
  "blanc": ['B L AE1 NG K'],
  "blanca": ['B L AA1 NG K AH0'],
  "blancett": ['B L AE1 N S IH0 T'],
  "blanch": ['B L AE1 N CH'],
  "blanchard": ['B L AE1 N CH ER0 D'],
  "blanchard's": ['B L AE1 N CH ER0 D Z'],
  "blanchards": ['B L AE1 N CH ER0 D Z'],
  "blanche": ['B L AE1 N CH'],
  "blanchet": ['B L AE1 N K IH0 T'],
  "blanchett": ['B L AE1 N CH IH0 T'],
  "blanchette": ['B L AH0 N SH EH1 T'],
  "blanchfield": ['B L AE1 N CH F IY2 L D'],
  "blanck": ['B L AE1 NG K'],
  "blancmange": ['B L AH0 M AA1 N JH'],
  "blanco": ['B L AE1 NG K OW0'],
  "bland": ['B L AE1 N D'],
  "blanda": ['B L AE1 N D AH0'],
  "blander": ['B L AE1 N D ER0'],
  "blandford": ['B L AE1 N D F AO0 R D'],
  "blandin": ['B L AE1 N D IH0 N'],
  "blanding": ['B L AE1 N D IH0 NG'],
  "blandino": ['B L AA0 N D IY1 N OW0'],
  "blandishment": ['B L AE1 N D IH0 SH M AH0 N T'],
  "blandishments": ['B L AE1 N D IH0 SH M AH0 N T S'],
  "blandly": ['B L AE1 N D L IY0'],
  "blandness": ['B L AE1 N D N AH0 S'],
  "blando": ['B L AE1 N D OW0'],
  "blandon": ['B L AE1 N D IH0 N'],
  "blane": ['B L EY1 N'],
  "blaney": ['B L EY1 N IY0'],
  "blanford": ['B L AE1 N F ER0 D'],
  "blank": ['B L AE1 NG K'],
  "blanke": ['B L AE1 NG K'],
  "blanked": ['B L AE1 NG K T'],
  "blanken": ['B L AE1 NG K AH0 N'],
  "blankenbaker": ['B L AE1 NG K AH0 N B EY2 K ER0'],
  "blankenbeckler": ['B L AE1 NG K AH0 N B EH2 K L ER0'],
  "blankenburg": ['B L AE1 NG K AH0 N B ER0 G'],
  "blankenhorn": ['B L AE1 NG K IH0 N HH ER0 N'],
  "blankenship": ['B L AE1 NG K AH0 N SH IH2 P'],
  "blanket": ['B L AE1 NG K AH0 T', 'B L AE1 NG K IH0 T'],
  "blanketed": ['B L AE1 NG K AH0 T IH0 D'],
  "blanketing": ['B L AE1 NG K AH0 T IH0 NG'],
  "blankets": ['B L AE1 NG K AH0 T S'],
  "blankie": ['B L AE1 NG K IY2'],
  "blankies": ['B L AE1 NG K IY2 Z'],
  "blanking": ['B L AE1 NG K IH0 NG'],
  "blankinship": ['B L AE1 NG K IH0 N SH IH0 P'],
  "blankley": ['B L AE1 NG K L IY0'],
  "blankly": ['B L AE1 NG K L IY0'],
  "blankly's": ['B L AE1 NG K L IY0 Z'],
  "blanks": ['B L AE1 NG K S'],
  "blanky": ['B L AE1 NG K IY2'],
  "blann": ['B L AE1 N'],
  "blanquita": ['B L AA0 N K IY1 T AH0'],
  "blansett": ['B L AE1 N S IH0 T'],
  "blanton": ['B L AE1 N T AH0 N'],
  "blare": ['B L EH1 R'],
  "blared": ['B L EH1 R D'],
  "blares": ['B L EH1 R Z'],
  "blaring": ['B L EH1 R IH0 NG'],
  "blas": ['B L AA1 S'],
  "blaschke": ['B L AE1 SH K'],
  "blasco": ['B L AA1 S K OW0'],
  "blasdel": ['B L AE1 S D AH0 L'],
  "blasdell": ['B L AE1 S D AH0 L'],
  "blase": ['B L EY1 Z'],
  "blaser": ['B L EY1 Z ER0'],
  "blasi": ['B L EY1 Z IY0'],
  "blasia": ['B L AA1 S IY0 AH0'],
  "blasier": ['B L EY1 Z IY0 ER0', 'B L EY1 ZH ER0'],
  "blasier's": ['B L EY1 Z IY0 ER0 Z', 'B L EY1 ZH ER0 Z'],
  "blasing": ['B L EY1 Z IH0 NG'],
  "blasingame": ['B L AA0 S IH0 NG G AA1 M IY0'],
  "blasini": ['B L AH0 S IY1 N IY0'],
  "blasio": ['B L AE1 Z IY0 OW2'],
  "blasius": ['B L EY1 S IY0 IH0 S'],
  "blaske": ['B L EY1 S K'],
  "blasko": ['B L AA1 S K OW0'],
  "blasphemous": ['B L AE1 S F AH0 M AH0 S'],
  "blasphemy": ['B L AE1 S F AH0 M IY0'],
  "blass": ['B L AE1 S'],
  "blassie": ['B L AE1 S IY0'],
  "blassie's": ['B L AE1 S IY0 Z'],
  "blassingame": ['B L AA0 S IH0 NG G AA1 M IY0'],
  "blast": ['B L AE1 S T'],
  "blastdown": ['B L AE1 S T D AW2 N'],
  "blasted": ['B L AE1 S T AH0 D', 'B L AE1 S T IH0 D'],
  "blaster": ['B L AE1 S T ER0'],
  "blastfurnace": ['B L AE1 S T F ER2 N AH0 S'],
  "blasting": ['B L AE1 S T IH0 NG'],
  "blastoderm": ['B L AE1 S T AH0 D ER0 M'],
  "blastoff": ['B L AE1 S T AO2 F'],
  "blastoma": ['B L AE2 S T OW1 M AH0'],
  "blasts": ['B L AE1 S T S'],
  "blaszak": ['B L AA1 SH AH0 K'],
  "blaszczyk": ['B L AA1 SH CH IH0 K'],
  "blatant": ['B L EY1 T AH0 N T'],
  "blatantly": ['B L EY1 T AH0 N T L IY0'],
  "blatchford": ['B L AE1 CH F ER0 D'],
  "blatchley": ['B L AE1 CH L IY0'],
  "blather": ['B L AE1 DH ER0'],
  "blatnik": ['B L AE1 T N IH0 K'],
  "blatt": ['B L AE1 T'],
  "blatter": ['B L AE1 T ER0'],
  "blattner": ['B L AE1 T N ER0'],
  "blatz": ['B L AE1 T S'],
  "blau": ['B L AW1'],
  "blauch": ['B L AO1 CH'],
  "blauer": ['B L AW1 R'],
  "blaum": ['B L AO1 M'],
  "blauser": ['B L AW1 S ER0'],
  "blaustein": ['B L AW1 S T AY0 N', 'B L AW1 S T IY0 N'],
  "blauvelt": ['B L AW1 V IH0 L T'],
  "blay": ['B L EY1'],
  "blaydes": ['B L EY1 D Z'],
  "blaydon": ['B L EY1 D AH0 N'],
  "blaylock": ['B L EY1 L AH0 K'],
  "blayne": ['B L EY1 N'],
  "blayney": ['B L EY1 N IY0'],
  "blayze": ['B L EY1 Z'],
  "blaze": ['B L EY1 Z'],
  "blazed": ['B L EY1 Z D'],
  "blazejewski": ['B L AH0 Z EY0 EH1 F S K IY0'],
  "blazek": ['B L AA1 Z EH0 K'],
  "blazer": ['B L EY1 Z ER0'],
  "blazers": ['B L EY1 Z ER0 Z'],
  "blazes": ['B L EY1 Z IH0 Z'],
  "blazier": ['B L EY1 Z IY0 ER0'],
  "blazina": ['B L AA0 Z IY1 N AH0'],
  "blazing": ['B L EY1 Z IH0 NG'],
  "blea": ['B L IY1'],
  "bleach": ['B L IY1 CH'],
  "bleached": ['B L IY1 CH T'],
  "bleacher": ['B L IY1 CH ER0'],
  "bleachers": ['B L IY1 CH ER0 Z'],
  "bleaching": ['B L IY1 CH IH0 NG'],
  "bleak": ['B L IY1 K'],
  "bleaker": ['B L IY1 K ER0'],
  "bleakest": ['B L IY1 K AH0 S T'],
  "bleakley": ['B L IY1 K L IY0'],
  "bleakness": ['B L IY1 K N AH0 S'],
  "bleakney": ['B L IY1 K N IY0'],
  "bleam": ['B L IY1 M'],
  "bleary": ['B L IH1 R IY0'],
  "bleau": ['B L OW1'],
  "blech": ['B L EH1 K'],
  "blech's": ['B L EH1 K S'],
  "blecha": ['B L EH1 CH AH0'],
  "blecher": ['B L EH1 K ER0'],
  "blechley": ['B L EH1 K L IY0'],
  "blechman": ['B L EH1 K M AH0 N'],
  "bleck": ['B L EH1 K'],
  "blecker": ['B L EH1 K ER0'],
  "bled": ['B L EH1 D'],
  "bledsoe": ['B L EH1 D S OW0'],
  "bleecker": ['B L IY1 K ER0'],
  "bleed": ['B L IY1 D'],
  "bleeding": ['B L IY1 D IH0 NG'],
  "bleeds": ['B L IY1 D Z'],
  "bleeker": ['B L IY1 K ER0'],
  "bleep": ['B L IY1 P'],
  "bleeping": ['B L IY1 P IH0 NG'],
  "blegen": ['B L EH1 G AH0 N'],
  "blehm": ['B L EH1 M'],
  "bleich": ['B L AY1 K'],
  "bleicher": ['B L AY1 K ER0'],
  "bleier": ['B L AY1 ER0'],
  "bleil": ['B L IY1 L'],
  "bleiler": ['B L AY1 L ER0'],
  "bleilers": ['B L AY1 L ER0 Z'],
  "blelloch": ['B L EH1 L AA0 K'],
  "blemish": ['B L EH1 M IH0 SH'],
  "blemished": ['B L EH1 M IH0 SH T'],
  "blemishes": ['B L EH1 M IH0 SH IH0 Z'],
  "blend": ['B L EH1 N D'],
  "blenda": ['B L EH1 N D AH0'],
  "blendax": ['B L EH1 N D AE2 K S'],
  "blended": ['B L EH1 N D IH0 D'],
  "blender": ['B L EH1 N D ER0'],
  "blenders": ['B L EH1 N D ER0 Z'],
  "blending": ['B L EH1 N D IH0 NG'],
  "blends": ['B L EH1 N D Z'],
  "blenheim": ['B L EH1 N HH AY2 M'],
  "blepharisma": ['B L EH2 F ER0 IH1 Z M AH0'],
  "bleser": ['B L IY1 Z ER0'],
  "bless": ['B L EH1 S'],
  "blessed": ['B L EH1 S T'],
  "blessedly": ['B L EH1 S AH0 D L IY0'],
  "blessedness": ['B L EH1 S AH0 D N AH0 S'],
  "blesses": ['B L EH1 S IH0 Z'],
  "blessing": ['B L EH1 S IH0 NG'],
  "blessinger": ['B L EH1 S IH0 NG ER0'],
  "blessings": ['B L EH1 S IH0 NG Z'],
  "blessington": ['B L EH1 S IH0 NG T AH0 N'],
  "blest": ['B L EH1 S T'],
  "blethen": ['B L EH1 TH AH0 N'],
  "bleu": ['B L UW1'],
  "blevens": ['B L IY1 V AH0 N Z'],
  "blevins": ['B L EH1 V IH0 N Z'],
  "blew": ['B L UW1'],
  "blewett": ['B L UW1 IH0 T'],
  "blewitt": ['B L UW1 IH0 T'],
  "bley": ['B L EY1'],
  "blick": ['B L IH1 K'],
  "blickenstaff": ['B L IH1 K IH0 N S T AH0 F'],
  "blida": ['B L AY1 D AH0'],
  "bligh": ['B L AY1'],
  "blight": ['B L AY1 T'],
  "blighted": ['B L AY1 T IH0 D'],
  "bliley": ['B L AY1 L IY0'],
  "blimp": ['B L IH1 M P'],
  "blimps": ['B L IH1 M P S'],
  "blincoe": ['B L IH0 N K OW1'],
  "blind": ['B L AY1 N D'],
  "blinded": ['B L AY1 N D IH0 D'],
  "blinder": ['B L AY1 N D ER0'],
  "blinder's": ['B L AY1 N D ER0 Z'],
  "blinders": ['B L AY1 N D ER0 Z'],
  "blindfold": ['B L AY1 N D F OW2 L D'],
  "blindfolded": ['B L AY1 N D F OW2 L D IH0 D'],
  "blinding": ['B L AY1 N D IH0 NG'],
  "blindly": ['B L AY1 N D L IY0'],
  "blindness": ['B L AY1 N D N AH0 S'],
  "blinds": ['B L AY1 N D Z'],
  "blindside": ['B L AY1 N D S AY2 D'],
  "blindsided": ['B L AY1 N D S AY2 D IH0 D'],
  "blink": ['B L IH1 NG K'],
  "blinked": ['B L IH1 NG K T'],
  "blinken": ['B L IH1 NG K AH0 N'],
  "blinking": ['B L IH1 NG K IH0 NG'],
  "blinks": ['B L IH1 NG K S'],
  "blinn": ['B L IH1 N'],
  "blip": ['B L IH1 P'],
  "blips": ['B L IH1 P S'],
  "bliscoll": ['B L IH1 S K AO0 L'],
  "blish": ['B L IH1 SH'],
  "bliss": ['B L IH1 S'],
  "bliss'": ['B L IH1 S'],
  "blissett": ['B L IH1 S IH0 T'],
  "blissful": ['B L IH1 S F AH0 L'],
  "blissfully": ['B L IH1 S F AH0 L IY0'],
  "blister": ['B L IH1 S T ER0'],
  "blistered": ['B L IH1 S T ER0 D'],
  "blistering": ['B L IH1 S T ER0 IH0 NG'],
  "blisters": ['B L IH1 S T ER0 Z'],
  "blitch": ['B L IH1 CH'],
  "blithe": ['B L AY1 DH'],
  "blithely": ['B L AY1 TH L IY0'],
  "blitstein": ['B L IH1 T S T IY0 N', 'B L IH1 T S T AY0 N'],
  "blitz": ['B L IH1 T S'],
  "blitzed": ['B L IH1 T S T'],
  "blitzen": ['B L IH1 T S AH0 N'],
  "blitzer": ['B L IH1 T S ER0'],
  "blitzer's": ['B L IH1 T S ER0 Z'],
  "blitzes": ['B L IH1 T S IH0 Z'],
  "blitzkrieg": ['B L IH1 T S K R IY2 G'],
  "bliven": ['B L AY1 V AH0 N'],
  "blixt": ['B L IH1 K S T'],
  "blizard": ['B L IH1 Z ER0 D'],
  "blizzard": ['B L IH1 Z ER0 D'],
  "blizzards": ['B L IH1 Z ER0 D Z'],
  "bloat": ['B L OW1 T'],
  "bloated": ['B L OW1 T IH0 D'],
  "bloatedness": ['B L OW1 T IH0 D N AH0 S'],
  "bloating": ['B L OW1 T IH0 NG'],
  "bloats": ['B L OW1 T S'],
  "blob": ['B L AA1 B'],
  "blobby": ['B L AA1 B IY0'],
  "blobs": ['B L AA1 B Z'],
  "bloc": ['B L AA1 K'],
  "bloc's": ['B L AA1 K S'],
  "bloch": ['B L AA1 K'],
  "bloch's": ['B L AA1 K S'],
  "blocher": ['B L AA1 K ER0'],
  "block": ['B L AA1 K'],
  "block's": ['B L AA1 K S'],
  "blockade": ['B L AA2 K EY1 D'],
  "blockaded": ['B L AA2 K EY1 D IH0 D'],
  "blockader": ['B L AA2 K EY1 D ER0'],
  "blockaders": ['B L AA2 K EY1 D ER0 Z'],
  "blockades": ['B L AA2 K EY1 D Z'],
  "blockading": ['B L AA2 K EY1 D IH0 NG'],
  "blockage": ['B L AA1 K IH0 JH'],
  "blockages": ['B L AA1 K IH0 JH IH0 Z'],
  "blockbuster": ['B L AA1 K B AH2 S T ER0'],
  "blockbuster's": ['B L AA1 K B AH2 S T ER0 Z'],
  "blockbusters": ['B L AA1 K B AH2 S T ER0 Z'],
  "blockchain": ['B L AA1 K CH EY1 N'],
  "blocked": ['B L AA1 K T'],
  "blocker": ['B L AA1 K ER0'],
  "blockers": ['B L AA1 K ER0 Z'],
  "blockhead": ['B L AA1 K HH EH2 D'],
  "blockheads": ['B L AA1 K HH EH2 D Z'],
  "blockhouse": ['B L AA1 K HH AW2 S'],
  "blockhouses": ['B L AA1 K HH AW2 S AH0 Z'],
  "blocking": ['B L AA1 K IH0 NG'],
  "blocks": ['B L AA1 K S'],
  "blocs": ['B L AA1 K S'],
  "blodgett": ['B L AA1 JH IH0 T'],
  "bloedel": ['B L OW1 D AH0 L'],
  "bloedorn": ['B L OW1 D ER0 N'],
  "bloem": ['B L OW1 M'],
  "bloemer": ['B L OW1 M ER0'],
  "bloemker": ['B L OW1 M K ER0'],
  "blog": ['B L AO1 G'],
  "blogger": ['B L AO1 G ER0'],
  "bloggers": ['B L AO1 G ER0 Z'],
  "blogging": ['B L AO1 G IH0 NG'],
  "blogosphere": ['B L AO1 G AH0 S F IH2 R', 'B L AO1 G AO0 S F IY1 R'],
  "blogs": ['B L AO1 G Z'],
  "blohm": ['B L OW1 M'],
  "blok": ['B L AA1 K'],
  "bloke": ['B L OW1 K'],
  "blokes": ['B L OW1 K S'],
  "blom": ['B L AA1 M'],
  "blomberg": ['B L AA1 M B ER0 G'],
  "blome": ['B L OW1 M'],
  "blomgren": ['B L AA1 M G R EH0 N'],
  "blomkvist": ['B L AA1 M K W IH2 S T'],
  "blomquist": ['B L AA1 M K W IH2 S T'],
  "blomstrom": ['B L AA1 M S T R AH0 M'],
  "blond": ['B L AA1 N D'],
  "blonde": ['B L AA1 N D'],
  "blonde's": ['B L AA1 N D Z'],
  "blondell": ['B L AA1 N D AH0 L'],
  "blonder": ['B L AA1 N D ER0'],
  "blondes": ['B L AA1 N D Z'],
  "blondest": ['B L AA1 N D AH0 S T'],
  "blondie": ['B L AA1 N D IY0'],
  "blondin": ['B L AA1 N D IH0 N'],
  "blonds": ['B L AA1 N D Z'],
  "blood": ['B L AH1 D'],
  "blood's": ['B L AH1 D Z'],
  "bloodbath": ['B L AH1 D B AE2 TH'],
  "blooded": ['B L AH1 D IH0 D'],
  "bloodgood": ['B L AH1 D G UH2 D'],
  "bloodhound": ['B L AH1 D HH AW0 N D'],
  "bloodhounds": ['B L AH1 D HH AW0 N D Z', 'B L AH1 D HH AW0 N Z'],
  "bloodied": ['B L AH1 D IY0 D'],
  "bloodier": ['B L AH1 D IY0 ER0'],
  "bloodiest": ['B L AH1 D IY0 AH0 S T'],
  "bloodless": ['B L AH1 D L AH0 S'],
  "bloodletting": ['B L AH1 D L EH2 T IH0 NG'],
  "bloodline": ['B L AH1 D L AY2 N'],
  "bloodlines": ['B L AH1 D L AY2 N Z'],
  "bloods": ['B L AH1 D Z'],
  "bloodshed": ['B L AH1 D SH EH2 D'],
  "bloodstain": ['B L AH1 D S T EY2 N'],
  "bloodstained": ['B L AH1 D S T EY2 N D'],
  "bloodstains": ['B L AH1 D S T EY2 N Z'],
  "bloodstone": ['B L AH1 D S T OW2 N'],
  "bloodstream": ['B L AH1 D S T R IY2 M'],
  "bloodsucker": ['B L AH1 D S AH2 K ER0'],
  "bloodsuckers": ['B L AH1 D S AH2 K ER0 Z'],
  "bloodsucking": ['B L AH1 D S AH2 K IH0 NG'],
  "bloodsworth": ['B L AH1 D Z W ER2 TH'],
  "bloodthirsty": ['B L AH1 D TH ER2 S T IY0'],
  "bloodworth": ['B L AH1 D W ER2 TH'],
  "bloody": ['B L AH1 D IY0'],
  "bloom": ['B L UW1 M'],
  "bloom's": ['B L UW1 M Z'],
  "bloomberg": ['B L UW1 M B ER0 G'],
  "bloomberg's": ['B L UW1 M B ER0 G Z'],
  "bloomed": ['B L UW1 M D'],
  "bloomer": ['B L UW1 M ER0'],
  "bloomers": ['B L UW1 M ER0 Z'],
  "bloomfield": ['B L UW1 M F IY2 L D'],
  "blooming": ['B L UW1 M IH0 NG'],
  "bloomingdale": ['B L UW1 M IH0 NG D EY2 L'],
  "bloomingdale's": ['B L UW1 M IH0 NG D EY2 L Z'],
  "bloomingdales": ['B L UW1 M IH0 NG D EY2 L Z'],
  "bloomington": ['B L UW1 M IH0 NG T AH0 N'],
  "bloomquist": ['B L UW1 M K W IH2 S T'],
  "blooms": ['B L UW1 M Z'],
  "blooper": ['B L UW1 P ER0'],
  "bloopers": ['B L UW1 P ER0 Z'],
  "bloor": ['B L UH1 R'],
  "blose": ['B L OW1 Z'],
  "bloss": ['B L AO1 S'],
  "blosser": ['B L AO1 S ER0'],
  "blossom": ['B L AA1 S AH0 M'],
  "blossomed": ['B L AA1 S AH0 M D'],
  "blossoming": ['B L AA1 S AH0 M IH0 NG'],
  "blossoms": ['B L AA1 S AH0 M Z'],
  "blot": ['B L AA1 T'],
  "blotch": ['B L AA1 T CH'],
  "blotched": ['B L AA1 T CH T'],
  "blotches": ['B L AA1 T CH AH0 Z'],
  "blotchier": ['B L AA1 T CH IY0 ER0'],
  "blotchiest": ['B L AA1 T CH IY0 AH0 S T'],
  "blotching": ['B L AA1 T CH IH0 NG'],
  "blotchy": ['B L AA1 T CH IY0'],
  "blotnick": ['B L AA1 T N IH0 K'],
  "blots": ['B L AA1 T S'],
  "blotted": ['B L AA1 T AH0 D'],
  "blotter": ['B L AA1 T ER0'],
  "blotting": ['B L AA1 T IH0 NG'],
  "blouch": ['B L AW1 CH'],
  "blough": ['B L AW1'],
  "blouin": ['B L W IY1 N'],
  "blount": ['B L AW1 N T'],
  "blouse": ['B L AW1 S'],
  "blouses": ['B L AW1 S AH0 Z', 'B L AW1 S IH0 Z'],
  "blow": ['B L OW1'],
  "blowdried": ['B L OW1 D R AY2 D'],
  "blowdries": ['B L OW1 D R AY2 Z'],
  "blowdry": ['B L OW1 D R AY2'],
  "blowdryer": ['B L OW1 D R AY2 ER0'],
  "blowdryers": ['B L OW1 D R AY2 ER0 Z'],
  "blowdrying": ['B L OW1 D R AY2 IH0 NG'],
  "blowe": ['B L OW1'],
  "blowed": ['B L OW1 D'],
  "blower": ['B L OW1 ER0'],
  "blowers": ['B L OW1 ER0 Z'],
  "blowfish": ['B L OW1 F IH0 SH'],
  "blowgun": ['B L OW1 G AH2 N'],
  "blowguns": ['B L OW1 G AH2 N Z'],
  "blowhard": ['B L OW1 HH AA2 R D'],
  "blowhards": ['B L OW1 HH AA2 R D Z'],
  "blowhole": ['B L OW1 HH OW2 L'],
  "blowholes": ['B L OW1 HH OW2 L Z'],
  "blowier": ['B L OW1 IY0 ER0'],
  "blowiest": ['B L OW1 IY0 AH0 S T'],
  "blowing": ['B L OW1 IH0 NG'],
  "blowjob": ['B L OW1 JH AA2 B'],
  "blowjobs": ['B L OW1 JH AA2 B Z'],
  "blown": ['B L OW1 N'],
  "blowout": ['B L OW1 AW2 T'],
  "blowouts": ['B L OW1 AW2 T S'],
  "blowpipe": ['B L OW1 P AY2 P'],
  "blowpipes": ['B L OW1 P AY2 P S'],
  "blows": ['B L OW1 Z'],
  "blowtorch": ['B L OW1 T AO2 R CH'],
  "blowtorches": ['B L OW1 T AO2 R CH AH0 Z'],
  "blowup": ['B L OW1 AH2 P'],
  "blowy": ['B L OW1 IY0'],
  "bloxham": ['B L AA1 K S AH0 M'],
  "bloxom": ['B L AA1 K S AH0 M'],
  "bloxsom": ['B L AA1 K S AH0 M'],
  "bloyd": ['B L OY1 D'],
  "bloyer": ['B L OY1 ER0'],
  "blubaugh": ['B L AH1 B AO0'],
  "blubber": ['B L AH1 B ER0'],
  "bludgeon": ['B L AH1 JH AH0 N'],
  "bludgeoned": ['B L AH1 JH AH0 N D'],
  "bludgeoning": ['B L AH1 JH AH0 N IH0 NG'],
  "blue": ['B L UW1'],
  "blue's": ['B L UW1 Z'],
  "bluebeard": ['B L UW1 B IY2 R D'],
  "bluebell": ['B L UW1 B EH2 L'],
  "bluebells": ['B L UW1 B EH2 L Z'],
  "blueberries": ['B L UW1 B EH2 R IY0 Z'],
  "blueberry": ['B L UW1 B EH2 R IY0'],
  "bluebird": ['B L UW1 B ER2 D'],
  "bluebirds": ['B L UW1 B ER2 D Z'],
  "blueblood": ['B L UW1 B L AH2 D'],
  "blueblooded": ['B L UW1 B L AH2 D AH0 D'],
  "bluebloods": ['B L UW1 B L AH2 D Z'],
  "bluebonnet": ['B L UW1 B AA2 N AH0 T'],
  "bluebonnets": ['B L UW1 B AA2 N AH0 T S'],
  "bluebottle": ['B L UW1 B AO2 T AH0 L'],
  "bluebottles": ['B L UW1 B AO2 T AH0 L Z'],
  "bluechip": ['B L UW1 CH IH2 P'],
  "bluecollar": ['B L UW1 K AO2 L ER0'],
  "blued": ['B L UW1 D'],
  "blueeyed": ['B L UW1 AY0 D'],
  "bluefield": ['B L UW1 F IY2 L D'],
  "bluegrass": ['B L UW1 G R AE2 S'],
  "blueing": ['B L UW1 IH0 NG'],
  "blueish": ['B L UW1 IH0 SH'],
  "bluejacket": ['B L UW1 JH AE2 K IH0 T'],
  "bluejay": ['B L UW1 JH EY2'],
  "bluejeans": ['B L UW1 JH IY0 N Z'],
  "bluelaw": ['B L UW1 L AA2'],
  "bluemel": ['B L UH1 M AH0 L'],
  "blueness": ['B L UW1 N AH0 S'],
  "bluepencil": ['B L UW1 P EH2 N S AH0 L'],
  "bluepencilled": ['B L UW1 P EH2 N S AH0 L D'],
  "bluepencilling": ['B L UW1 P EH2 N S AH0 L IH0 NG'],
  "bluepencils": ['B L UW1 P EH2 N S AH0 L Z'],
  "blueprint": ['B L UW1 P R IH2 N T'],
  "blueprints": ['B L UW1 P R IH2 N T S'],
  "bluer": ['B L UW1 ER0'],
  "blues": ['B L UW1 Z'],
  "blues'": ['B L UW1 Z'],
  "bluest": ['B L UW1 AH0 S T'],
  "bluestein": ['B L UH1 S T AY0 N', 'B L UH1 S T IY0 N'],
  "bluestine": ['B L UW1 S T AY2 N'],
  "bluestone": ['B L UW1 S T OW2 N'],
  "bluesy": ['B L UW1 Z IY0', 'B L UW1 AH0 S IY0'],
  "bluetooth": ['B L UW1 T UW2 TH'],
  "bluett": ['B L UW1 T'],
  "bluey": ['B L UW1 IY0'],
  "bluff": ['B L AH1 F'],
  "bluffed": ['B L AH1 F T'],
  "bluffer": ['B L AH1 F ER0'],
  "bluffing": ['B L AH1 F IH0 NG'],
  "bluffs": ['B L AH1 F S'],
  "bluford": ['B L UW1 F ER0 D'],
  "bluhdorn": ['B L AH1 D AO2 R N'],
  "bluhm": ['B L AH1 M'],
  "bluing": ['B L UW1 IH0 NG'],
  "bluish": ['B L UW1 IH0 SH'],
  "bluitt": ['B L UW1 T'],
  "blum": ['B L UW1 M'],
  "blumberg": ['B L AH1 M B ER0 G'],
  "blume": ['B L UW1 M'],
  "blumenberg": ['B L UW1 M EH0 N B ER0 G'],
  "blumenfeld": ['B L UW1 M IH0 N F EH0 L D'],
  "blumenschein": ['B L AH1 M IH0 N SH AY0 N'],
  "blumenshine": ['B L AH1 M IH0 N SH AY0 N'],
  "blumenstein": ['B L UW1 M EH0 N S T AY0 N', 'B L UW1 M EH0 N S T IY0 N'],
  "blumenstock": ['B L UW1 M EH0 N S T AA0 K'],
  "blumenthal": ['B L UW1 M AH0 N TH AO2 L'],
  "blumer": ['B L UW1 M ER0'],
  "blumquist": ['B L AH1 M K W IH0 S T'],
  "blumstein": ['B L AH1 M S T AY0 N', 'B L AH1 M S T IY0 N'],
  "blunck": ['B L AH1 NG K'],
  "blundall": ['B L AH1 N D AH0 L'],
  "blundell": ['B L AH1 N D AH0 L'],
  "blunder": ['B L AH1 N D ER0'],
  "blundered": ['B L AH1 N D ER0 D'],
  "blundering": ['B L AH1 N D ER0 IH0 NG'],
  "blunders": ['B L AH1 N D ER0 Z'],
  "blunk": ['B L AH1 NG K'],
  "blunt": ['B L AH1 N T'],
  "blunted": ['B L AH1 N T AH0 D', 'B L AH1 N T IH0 D'],
  "blunter": ['B L AH1 N T ER0'],
  "bluntest": ['B L AH1 N T AH0 S T'],
  "blunting": ['B L AH1 N T IH0 NG'],
  "bluntly": ['B L AH1 N T L IY0'],
  "bluntness": ['B L AH1 N T N AH0 S'],
  "blunts": ['B L AH1 N T S'],
  "blur": ['B L ER1'],
  "blurb": ['B L ER1 B'],
  "blurbs": ['B L ER1 B Z'],
  "blurred": ['B L ER1 D'],
  "blurring": ['B L ER1 IH0 NG'],
  "blurry": ['B L ER1 IY0'],
  "blurs": ['B L ER1 Z'],
  "blurt": ['B L ER1 T'],
  "blurted": ['B L ER1 T IH0 D'],
  "blurton": ['B L ER1 T AH0 N'],
  "blurts": ['B L ER1 T S'],
  "blush": ['B L AH1 SH'],
  "blushed": ['B L AH1 SH T'],
  "blushes": ['B L AH1 SH AH0 Z', 'B L AH1 SH IH0 Z'],
  "blushing": ['B L AH1 SH IH0 NG'],
  "blust": ['B L AH1 S T'],
  "bluster": ['B L AH1 S T ER0'],
  "blustering": ['B L AH1 S T ER0 IH0 NG'],
  "blustery": ['B L AH1 S T ER0 IY0'],
  "bluth": ['B L UW1 TH'],
  "blvd": ['B UH1 L AH0 V AA2 R D'],
  "bly": ['B L AY1'],
  "blye": ['B L AY1'],
  "blyler": ['B L AY1 L ER0'],
  "blystone": ['B L AY1 S T OW2 N'],
  "blyth": ['B L IH1 TH'],
  "blythe": ['B L AY1 DH'],
  "bmw": ['B IY1 EH2 M D AH1 B AH0 L Y UW0'],
  "bo": ['B OW1'],
  "bo-shek": ['B OW1 SH EH1 K'],
  "boa": ['B OW1 AH0'],
  "boak": ['B OW1 K'],
  "boake": ['B OW1 K'],
  "boal": ['B OW1 L'],
  "boals": ['B OW1 L Z'],
  "boan": ['B OW1 N'],
  "boar": ['B AO1 R'],
  "board": ['B AO1 R D'],
  "board's": ['B AO1 R D Z'],
  "boarda": ['B AO1 R D AH0'],
  "boardbent": ['B AO1 R D B EH2 N T'],
  "boarded": ['B AO1 R D AH0 D', 'B AO1 R D IH0 D'],
  "boarder": ['B AO1 R D ER0'],
  "boarders": ['B AO1 R D ER0 Z'],
  "boardgame": ['B AO1 R D G EY2 M'],
  "boardgames": ['B AO1 R D G EY2 M Z'],
  "boarding": ['B AO1 R D IH0 NG'],
  "boardinghouse": ['B AO1 R D IH0 NG HH AW2 S'],
  "boardinghouses": ['B AO1 R D IH0 NG HH AW2 S IH0 Z'],
  "boardingpass": ['B AO1 R D IH0 N G P AE2 S'],
  "boardingpasses": ['B AO1 R D IH0 N G P AE2 S IH0 Z'],
  "boardings": ['B AO1 R D IH0 NG Z'],
  "boardman": ['B AO1 R D M AH0 N'],
  "boardroom": ['B AO1 R D R UW2 M'],
  "boardrooms": ['B AO1 R D R UW2 M Z'],
  "boards": ['B AO1 R D Z'],
  "boardwalk": ['B AO1 R D W AO2 K'],
  "boardwalks": ['B AO1 R D W AO2 K S'],
  "boarman": ['B AO1 R M AH0 N'],
  "boart": ['B AO1 R T'],
  "boas": ['B OW1 AH0 Z'],
  "boase": ['B OW1 Z'],
  "boast": ['B OW1 S T'],
  "boasted": ['B OW1 S T AH0 D', 'B OW1 S T IH0 D'],
  "boastful": ['B OW1 S T F AH0 L'],
  "boasting": ['B OW1 S T IH0 NG'],
  "boasts": ['B OW1 S T S'],
  "boat": ['B OW1 T'],
  "boat's": ['B OW1 T S'],
  "boated": ['B OW1 T IH0 D'],
  "boaters": ['B OW1 T ER0 Z'],
  "boathouse": ['B OW1 T HH AW1 S'],
  "boating": ['B OW1 T IH0 NG'],
  "boatlift": ['B OW1 T L IH2 F T'],
  "boatlift's": ['B OW1 T L IH2 F T S'],
  "boatlifts": ['B OW1 T L IH2 F T S'],
  "boatload": ['B OW1 T L OW2 D'],
  "boatloads": ['B OW1 T L OW2 D Z'],
  "boatman": ['B OW1 T M AH0 N'],
  "boatmen's": ['B OW1 T M EH0 N Z'],
  "boatner": ['B OW1 T N ER0'],
  "boatright": ['B OW1 T R AY2 T'],
  "boats": ['B OW1 T S'],
  "boatswain": ['B OW1 T S W EY0 N'],
  "boatwright": ['B OW1 T R AY2 T'],
  "boatyard": ['B OW1 T Y AA2 R D'],
  "boaz": ['B OW1 AE0 Z'],
  "bob": ['B AA1 B'],
  "bob's": ['B AA1 B Z'],
  "boback": ['B OW1 B AE2 K'],
  "bobadilla": ['B OW0 B AA0 D IH1 L AH0'],
  "bobak": ['B OW1 B AH0 K'],
  "bobb": ['B AA1 B'],
  "bobber": ['B AA1 B ER0'],
  "bobbett": ['B AA1 B IH0 T'],
  "bobbette": ['B AA1 B EH1 T'],
  "bobbi": ['B AA1 B IY0'],
  "bobbie": ['B AA1 B IY0'],
  "bobbin": ['B AA1 B AH0 N'],
  "bobbing": ['B AA1 B IH0 NG'],
  "bobbinger": ['B AA1 B IH0 NG ER0'],
  "bobbinger's": ['B AA1 B IH0 NG ER0 Z'],
  "bobbitt": ['B AA1 B IH0 T'],
  "bobbitt's": ['B AA1 B IH0 T S'],
  "bobbitts": ['B AA1 B IH0 T S'],
  "bobble": ['B AO1 B AH0 L'],
  "bobbled": ['B AO1 B AH0 L D'],
  "bobbles": ['B AO1 B AH0 L Z'],
  "bobbling": ['B AO1 B L IH0 NG'],
  "bobbo": ['B AA1 B OW0'],
  "bobby": ['B AA1 B IY0'],
  "bobby's": ['B AA1 B IY0 Z'],
  "bobcat": ['B AA1 B K AE2 T'],
  "bobcats": ['B AA1 B K AE2 T Z'],
  "bobe": ['B OW1 B'],
  "bobeck": ['B OW1 B EH2 K'],
  "bobek": ['B OW1 B IH0 K'],
  "bober": ['B AA1 B ER0'],
  "boberg": ['B OW1 B ER0 G'],
  "bobick": ['B AA1 B IH0 K'],
  "bobier": ['B OW1 B IY0 ER0'],
  "bobinski": ['B AH0 B IH1 N S K IY0'],
  "boblitt": ['B AH0 B L IH1 T'],
  "bobo": ['B OW1 B OW0'],
  "bobolas": ['B OW1 B OW0 L AH0 S'],
  "bobrow": ['B AA1 B R OW2'],
  "bobrowski": ['B AH0 B R AO1 F S K IY0'],
  "bobsled": ['B AA1 B S L EH2 D'],
  "bobst": ['B AA1 B S T'],
  "bobzien": ['B AA1 B Z IY0 N'],
  "boca": ['B OW1 K AH0'],
  "bocanegra": ['B OW2 K AH0 N EH1 G R AH0'],
  "bocce": ['B OW1 CH IY0', 'B OW1 K AH0'],
  "bocchino": ['B OW2 K IY1 N OW0'],
  "boccia": ['B OW1 CH AH0'],
  "boccio": ['B OW1 CH IY0 OW0'],
  "boccuzzi": ['B OW0 K UW1 T S IY0'],
  "bocek": ['B OW1 CH EH2 K'],
  "boch": ['B AA1 K'],
  "bocharov": ['B AA1 CH ER0 AA0 V'],
  "bochco": ['B AA1 CH K OW0'],
  "boche": ['B AA1 CH'],
  "bochenek": ['B AA1 K IH0 N IH0 K'],
  "boches": ['B AA1 CH IH0 Z'],
  "bochicchio": ['B OW0 K IY1 K IY0 OW0'],
  "bochner": ['B AA1 K N ER0'],
  "bochram": ['B AA1 K R AH0 M'],
  "bocian": ['B OW1 SH AH0 N'],
  "bock": ['B AA1 K'],
  "bockelman": ['B AA1 K AH0 L M AH0 N'],
  "bockius": ['B AA1 K IY0 AH0 S'],
  "bockman": ['B AA1 K M AH0 N'],
  "bockus": ['B AA1 K AH0 S'],
  "bocock": ['B AA1 K AH0 K'],
  "bocook": ['B AA1 K UH0 K'],
  "bocuse": ['B OW0 K Y UW1 Z'],
  "boda": ['B OW1 D AH0'],
  "bodamer": ['B AA1 D AH0 M ER0'],
  "bodanis": ['B OW0 D AA1 N IH0 S'],
  "bodden": ['B AA1 D AH0 N'],
  "boddie": ['B AA1 D IY0'],
  "boddington": ['B AA1 D IH0 NG T AH0 N'],
  "boddy": ['B AA1 D IY0'],
  "bode": ['B OW1 D'],
  "bodega": ['B OW0 D EY1 G AH0'],
  "bodegas": ['B OW0 D EY1 G AH0 S'],
  "bodeguita": ['B OW2 D EH0 G IY1 D AH0', 'B OW2 D AH0 G IY1 D AH0'],
  "bodell": ['B AH0 D EH1 L'],
  "boden": ['B OW1 D AH0 N'],
  "bodenhamer": ['B AA1 D IH0 N HH AH0 M ER0', 'B OW1 D IH0 N HH AH0 M ER0'],
  "bodenheimer": ['B AA1 D IH0 N HH AY0 M ER0'],
  "bodenstein": ['B OW1 D AH0 N S T AY1 N', 'B OW1 D AH0 N S T IY1 N'],
  "bodensteiner": ['B OW1 D AH0 N S T AY1 N ER0'],
  "bodes": ['B OW1 D Z'],
  "bodey": ['B OW1 D IY0'],
  "bodhisattvas": ['B OW0 D IH0 S AA1 T V AH0 S'],
  "bodi": ['B OW1 D IY0'],
  "bodice": ['B AA1 D IH0 S'],
  "bodices": ['B AA1 D IH0 S IH0 Z'],
  "bodie": ['B OW1 D IY0'],
  "bodied": ['B AA1 D IY0 D'],
  "bodies": ['B AA1 D IY0 Z'],
  "bodikova": ['B AA2 D IH0 K OW1 V AH0'],
  "bodily": ['B AA1 D AH0 L IY0'],
  "bodin": ['B OW1 D IH0 N'],
  "bodine": ['B OW0 D IY1 N IY0'],
  "bodkin": ['B AA1 D K IH0 N'],
  "bodkins": ['B AA1 D K IH0 N Z'],
  "bodle": ['B OW1 D AH0 L'],
  "bodley": ['B AA1 D L IY0'],
  "bodman": ['B AA1 D M AH0 N'],
  "bodmer": ['B AA1 D M ER0'],
  "bodnar": ['B AH0 D N AA1 R'],
  "bodner": ['B AA1 D N ER0'],
  "bodo": ['B OW1 D OW0'],
  "bodwell": ['B AA1 D W EH2 L'],
  "body": ['B AA1 D IY0'],
  "body's": ['B AA1 D IY0 Z'],
  "bodyguard": ['B AA1 D IY0 G AA2 R D'],
  "bodyguards": ['B AA1 D IY0 G AA2 R D Z'],
  "bodziak": ['B AO1 D Z IY0 AE0 K'],
  "bodziak's": ['B AO1 D Z IY0 AE0 K S'],
  "boe": ['B OW1'],
  "boeck": ['B OW1 K'],
  "boeckel": ['B OW1 K AH0 L'],
  "boecker": ['B OW1 K ER0'],
  "boeckman": ['B OW1 K M AH0 N'],
  "boeckmann": ['B OW1 K M AH0 N'],
  "boedecker": ['B OW1 D IH0 K ER0'],
  "boedeker": ['B OW1 D IH0 K ER0'],
  "boeder": ['B OW1 D ER0'],
  "boeding": ['B OW1 D IH0 NG'],
  "boege": ['B OW1 JH'],
  "boeger": ['B OW1 G ER0'],
  "boeh": ['B OW1'],
  "boehl": ['B OW1 L'],
  "boehle": ['B OW1 HH AH0 L'],
  "boehler": ['B OW1 L ER0'],
  "boehlert": ['B OW1 L ER0 T'],
  "boehlke": ['B OW1 L K'],
  "boehm": ['B OW1 M', 'B EY1 M'],
  "boehme": ['B OW1 M', 'B EY1 M'],
  "boehmer": ['B OW1 M ER0'],
  "boehmke": ['B OW1 M K IY0'],
  "boehne": ['B OW1 N'],
  "boehner": ['B OW1 N ER0'],
  "boehning": ['B OW1 N IH0 NG'],
  "boehnke": ['B OW1 NG K'],
  "boehnlein": ['B OW1 N L AY2 N'],
  "boehringer": ['B AO1 R IH0 NG ER0'],
  "boeing": ['B OW1 IH0 NG'],
  "boeing's": ['B OW1 IH0 NG Z'],
  "boeke": ['B OW1 K'],
  "boeker": ['B OW1 K ER0'],
  "boelens": ['B OW1 L AH0 N Z'],
  "boelkow": ['B OW1 L K AW0'],
  "boelman": ['B OW1 L M AH0 N'],
  "boelter": ['B OW1 L T ER0'],
  "boen": ['B OW1 N'],
  "boenig": ['B OW1 N IH0 G'],
  "boening": ['B AA1 AH0 N IH0 NG'],
  "boepple": ['B OW1 P AH0 L'],
  "boer": ['B AO1 R'],
  "boerger": ['B AO1 R G ER0'],
  "boerman": ['B AO1 R M AH0 N'],
  "boerner": ['B AO1 R N ER0'],
  "boers": ['B AO1 R Z'],
  "boersma": ['B AO1 R S M AH0'],
  "boes": ['B OW1 Z'],
  "boesak": ['B OW1 Z AE0 K'],
  "boesch": ['B OW1 SH'],
  "boeschenstein": ['B OW1 SH AH0 N S T IY2 N', 'B OW1 SH AH0 N S T AY2 N'],
  "boese": ['B OW1 S'],
  "boesel": ['B OW1 S AH0 L'],
  "boesen": ['B OW1 S AH0 N'],
  "boesky": ['B OW1 S K IY0'],
  "boesky's": ['B OW1 S K IY0 Z'],
  "boeskys": ['B OW1 S K IY0 Z'],
  "boetcher": ['B OW1 CH ER0'],
  "boettcher": ['B OW1 CH ER0'],
  "boettger": ['B OW1 T G ER0'],
  "boettner": ['B OW1 T N ER0'],
  "boeve": ['B OW1 V'],
  "boff": ['B AO1 F'],
  "boffa": ['B AA1 F AH0'],
  "boffo": ['B AO1 F OW0'],
  "bofors": ['B OW1 F ER0 Z'],
  "bofors's": ['B OW1 F ER0 Z IH0 Z'],
  "bog": ['B AA1 G', 'B AO1 G'],
  "bogacki": ['B AH0 G AA1 T S K IY0'],
  "bogacz": ['B AA1 G AH0 CH'],
  "bogan": ['B OW1 G AH0 N'],
  "bogar": ['B AH0 G AA1 R'],
  "bogard": ['B AA1 G ER0 D'],
  "bogardus": ['B AA1 G AA0 R D IH0 S'],
  "bogart": ['B OW1 G AA2 R T'],
  "bogdan": ['B AA1 G D AH0 N'],
  "bogdanoff": ['B AA1 G D AH0 N AO0 F'],
  "bogdanowicz": ['B AH0 G D AE1 N AH0 W IH2 CH'],
  "bogdanski": ['B AH0 G D AE1 N S K IY0'],
  "bogden": ['B AA1 G D AH0 N'],
  "bogdon": ['B AA1 G D AH0 N'],
  "boge": ['B OW1 JH'],
  "bogelsong": ['B AA1 G AH0 L S AO0 NG'],
  "bogen": ['B AA1 G AH0 N'],
  "bogenschutz": ['B AA1 G IH0 N SH AH0 T S'],
  "boger": ['B OW1 G ER0'],
  "bogert": ['B AA1 G ER0 T'],
  "bogey": ['B OW1 G IY0'],
  "bogeyed": ['B OW1 G IY0 D'],
  "bogeyman": ['B OW1 G IY0 M AE2 N', 'B UH1 G IY0 M AE2 N'],
  "bogeymen": ['B OW1 G IY0 M IH2 N', 'B UH1 G IY0 M IH2 N'],
  "boggan": ['B AA1 G AH0 N'],
  "bogged": ['B AA1 G D', 'B AO1 G D'],
  "boggess": ['B AA1 G IH0 S'],
  "bogging": ['B AO1 G IH0 NG'],
  "boggio": ['B OW1 JH IY0 OW0'],
  "boggle": ['B AA1 G AH0 L'],
  "boggled": ['B AA1 G AH0 L D'],
  "boggles": ['B AA1 G AH0 L Z'],
  "boggling": ['B AA1 G AH0 L IH0 NG', 'B AA1 G L IH0 NG'],
  "boggs": ['B AO1 G Z'],
  "boggus": ['B AA1 G AH0 S'],
  "boghosian": ['B AH0 G OW1 ZH IH0 N'],
  "bogie": ['B UH1 G IY0'],
  "bogin": ['B OW1 JH IH0 N'],
  "bogle": ['B OW1 G AH0 L'],
  "bognar": ['B AH0 G N AA1 R'],
  "bogner": ['B AA1 G N ER0'],
  "bogor": ['B OW1 G AO2 R'],
  "bogosian": ['B AH0 G AA1 ZH IH0 N'],
  "bogota": ['B OW2 G AH0 T AA1'],
  "bogs": ['B AA1 G Z', 'B AO1 G Z'],
  "bogucki": ['B AH0 G AH1 T S K IY0'],
  "bogue": ['B OW1 G'],
  "bogus": ['B OW1 G AH0 S'],
  "boguslavskaya": ['B OW0 G AH2 S L AH0 V S K AY1 AH0'],
  "bogusz": ['B AA1 G AH0 SH'],
  "bohac": ['B OW1 HH AH0 K'],
  "bohall": ['B AA1 HH AH0 L'],
  "bohan": ['B OW1 HH AH0 N'],
  "bohanan": ['B AA1 HH AH0 N AE0 N'],
  "bohannan": ['B AH0 HH AE1 N AH0 N'],
  "bohannon": ['B AA1 HH AH0 N AA0 N'],
  "bohanon": ['B AA1 HH AH0 N AA0 N'],
  "bohart": ['B AA1 HH AA0 R T'],
  "boheme": ['B OW0 HH EY1 M EY0'],
  "bohemia": ['B OW0 HH IY1 M IY0 AH0'],
  "bohemian": ['B OW0 HH IY1 M IY0 AH0 N'],
  "bohen": ['B OW1 AH0 N'],
  "bohl": ['B OW1 L'],
  "bohland": ['B OW1 L AH0 N D'],
  "bohlander": ['B OW1 L AH0 N D ER0'],
  "bohle": ['B OW1 HH AH0 L'],
  "bohlen": ['B OW1 L AH0 N'],
  "bohler": ['B OW1 L ER0'],
  "bohlin": ['B OW1 L IH0 N'],
  "bohling": ['B OW1 L IH0 NG'],
  "bohlinger": ['B OW1 L IH0 NG ER0'],
  "bohlke": ['B AA1 K', 'B OW1 L K'],
  "bohlken": ['B OW1 L K AH0 N'],
  "bohlman": ['B OW1 L M AH0 N'],
  "bohlmann": ['B OW1 L M AH0 N'],
  "bohm": ['B AA1 M'],
  "bohman": ['B OW1 M AH0 N'],
  "bohmer": ['B OW1 M ER0'],
  "bohn": ['B OW1 N'],
  "bohne": ['B OW1 N'],
  "bohnen": ['B OW1 N AH0 N'],
  "bohnenkamp": ['B OW1 N IH0 N K AE0 M P'],
  "bohner": ['B OW1 N ER0'],
  "bohnert": ['B OW1 N ER0 T'],
  "bohnet": ['B AA1 N IH0 T'],
  "bohnhoff": ['B OW1 N HH AO2 F'],
  "bohning": ['B AA1 N IH0 NG'],
  "bohnomie": ['B AO1 N AO0 M IY2'],
  "bohnsack": ['B OW1 N S AH0 K'],
  "bohon": ['B OW1 HH AH0 N'],
  "bohr": ['B AO1 R'],
  "bohren": ['B AO1 R AH0 N'],
  "bohrer": ['B AO1 R ER0'],
  "boice": ['B OY1 S'],
  "boies": ['B OY1 Z'],
  "boik": ['B OY1 K'],
  "boike": ['B OY1 K'],
  "boil": ['B OY1 L'],
  "boilard": ['B OY0 L AA1 R D'],
  "boileau": ['B OY2 L OW1'],
  "boiled": ['B OY1 L D'],
  "boiler": ['B OY1 L ER0'],
  "boilermaker": ['B OY1 L ER0 M EY2 K ER0'],
  "boilerplate": ['B OY1 L ER0 P L EY2 T'],
  "boilerplates": ['B OY1 L ER0 P L EY2 T S'],
  "boilers": ['B OY1 L ER0 Z'],
  "boiling": ['B OY1 L IH0 NG'],
  "boils": ['B OY1 L Z'],
  "boink": ['B OY1 N K'],
  "boipatong": ['B OY1 P AH0 T AO0 NG'],
  "bois": ['B W AA1'],
  "boisclair": ['B W AA0 K L EH1 R'],
  "boise": ['B OY1 Z IY0', 'B OY1 S IY0'],
  "boise's": ['B OY1 Z IY0 Z', 'B OY1 S IY0 Z'],
  "boisen": ['B OY1 S AH0 N'],
  "boisi": ['B OY1 S IY0'],
  "boisjoly": ['B OY1 S JH AH0 L IY0'],
  "boisseau": ['B W AA2 S OW1'],
  "boissonneault": ['B W AA1 S AH0 N OW0'],
  "boisterous": ['B OY1 S T ER0 AH0 S', 'B OY1 S T R AH0 S'],
  "boisvert": ['B W AA0 V ER1 T'],
  "boitano": ['B OY2 T AA1 N OW0'],
  "boitnott": ['B OY0 T N AA1 T'],
  "boivin": ['B OY0 V AE1 N'],
  "bojangles": ['B OW0 JH AE1 NG G AH0 L Z'],
  "bojangles'": ['B OW0 JH AE1 NG G AH0 L Z'],
  "bojanowski": ['B AH0 Y AH0 N AO1 F S K IY0'],
  "bojarski": ['B AH0 Y AA1 R S K IY0'],
  "bojenka": ['B AH0 JH EH1 NG K AH0'],
  "bojorquez": ['B OW0 Y AO1 R K W EH0 Z'],
  "bok": ['B AA1 K'],
  "bokassa": ['B AH0 K AA1 S AH0'],
  "boker": ['B OW1 K ER0'],
  "bokor": ['B OW1 K ER0'],
  "boksen": ['B AA1 K S AH0 N'],
  "bol": ['B AA1 L'],
  "bola": ['B OW1 L AH0'],
  "bolam": ['B OW1 L AH0 M'],
  "bolan": ['B OW1 L AH0 N'],
  "bolancik": ['B AH0 L AE1 N S AH0 K'],
  "boland": ['B OW1 L AH0 N D'],
  "bolander": ['B AA1 L AH0 N D ER0'],
  "bolanger": ['B OW1 L AH0 N JH ER0'],
  "bolanos": ['B OW0 L AA1 N OW0 Z'],
  "bolar": ['B OW1 L ER0'],
  "bolar's": ['B OW1 L ER0 Z'],
  "bolch": ['B OW1 L CH'],
  "bolcom": ['B OW1 L K AH0 M'],
  "bold": ['B OW1 L D'],
  "bolda": ['B OW1 L D AH0'],
  "bolden": ['B OW1 L D AH0 N'],
  "bolder": ['B OW1 L D ER0'],
  "boldest": ['B OW1 L D IH0 S T'],
  "boldface": ['B OW1 L D F EY2 S'],
  "boldin": ['B OW1 L D IH0 N'],
  "bolding": ['B OW1 L D IH0 NG'],
  "boldly": ['B OW1 L D L IY0'],
  "boldman": ['B OW1 L D M AH0 N'],
  "boldness": ['B OW1 L D N AH0 S'],
  "boldon": ['B OW1 L D AH0 N'],
  "bolds": ['B OW1 L D Z'],
  "boldt": ['B OW1 L T'],
  "bolduc": ['B OW1 L D AH0 K'],
  "bolduc's": ['B OW1 L D AH0 K S'],
  "bole": ['B OW1 L'],
  "bolek": ['B OW1 L IH0 K'],
  "bolen": ['B OW1 L AH0 N'],
  "bolenbaugh": ['B AH0 L EH1 N B AO0'],
  "bolender": ['B AA1 L EH0 N D ER0'],
  "boler": ['B OW1 L ER0'],
  "bolerjack": ['B AA1 L ER0 JH AH0 K'],
  "bolero": ['B OW0 L EH1 R OW0'],
  "boles": ['B OW1 L Z'],
  "boleware": ['B OW1 L W EH0 R'],
  "boley": ['B OW1 L IY0'],
  "boleyn": ['B OW0 L IH1 N'],
  "bolf": ['B OW1 L F'],
  "bolger": ['B OW1 L JH ER0'],
  "bolger's": ['B OW1 L JH ER0 Z'],
  "bolich": ['B AA1 L IH0 K'],
  "bolick": ['B AA1 L IH0 K'],
  "boliden": ['B OW1 L IH0 D AH0 N'],
  "bolin": ['B OW1 L IH0 N'],
  "boline": ['B AA1 L AY0 N'],
  "boling": ['B OW1 L IH0 NG'],
  "bolinger": ['B OW1 L IH0 NG ER0'],
  "bolio": ['B OW1 L IY0 OW0'],
  "bolitho": ['B OW0 L IH1 TH AH0'],
  "bolivar": ['B AA1 L AH0 V ER0'],
  "bolivars": ['B AA1 L AH0 V ER0 Z'],
  "bolivia": ['B AH0 L IH1 V IY0 AH0', 'B OW0 L IH1 V IY0 AH0'],
  "bolivia's": ['B AH0 L IH1 V IY0 AH0 Z'],
  "bolivian": ['B OW0 L IH1 V IY0 AH0 N', 'B AH0 L IH1 V IY0 AH0 N'],
  "bolivians": ['B OW0 L IH1 V IY0 AH0 N Z', 'B AH0 L IH1 V IY0 AH0 N Z'],
  "bolker": ['B OW1 L K ER0'],
  "bolkow": ['B OW1 L K AW0'],
  "boll": ['B OW1 L'],
  "bolla": ['B AA1 L AH0'],
  "bolland": ['B AA1 L AH0 N D'],
  "bollapragada": ['B OW0 L AA2 P R AH0 G AA1 D AH0'],
  "bollard": ['B AA1 L ER0 D'],
  "bolle": ['B AA1 L'],
  "bollen": ['B AA1 L AH0 N'],
  "bollenbach": ['B AA1 L IH0 N B AA0 K'],
  "bollenbacher": ['B AA1 L IH0 N B AA0 K ER0'],
  "boller": ['B AA1 L ER0'],
  "bolles": ['B OW1 L Z'],
  "bollettieri": ['B AH0 L EH2 T IY0 EH1 R IY0'],
  "bollier": ['B AA1 L IY0 ER0'],
  "bollig": ['B AA1 L IH0 G'],
  "bolliger": ['B AA1 L IH0 G ER0'],
  "bollin": ['B AA1 L IH0 N'],
  "bolling": ['B OW1 L IH0 NG'],
  "bollinger": ['B AA1 L IH0 NG ER0'],
  "bollman": ['B AA1 L M AH0 N'],
  "bollmann": ['B AA1 L M AH0 N'],
  "bollocks": ['B AO1 L AO0 K S'],
  "bollore": ['B AA1 L AO0 R'],
  "bolls": ['B OW1 L Z'],
  "bolly": ['B AO1 L IY2'],
  "bollywood": ['B AA1 L IY2 W UH0 D', 'B AO1 L IH0 W UH2 D'],
  "bollywood's": ['B AO1 L IH0 W UH2 D Z'],
  "bologna": ['B AH0 L OW1 N IY0'],
  "bolognese": ['B OW0 L OW0 G N EY1 Z IY0'],
  "bolognesi": ['B AA2 L AA0 G N EH1 S IY0'],
  "bolon": ['B OW1 L AH0 N'],
  "bolotin": ['B AA1 L AH0 T IH0 N'],
  "bolsa": ['B OW1 L S AH0'],
  "bolsa's": ['B OW1 L S AH0 Z'],
  "bolser": ['B OW1 L S ER0'],
  "bolshevik": ['B OW1 L SH AH0 V IH2 K'],
  "bolsheviks": ['B OW1 L SH AH0 V IH2 K S'],
  "bolshevism": ['B OW1 L SH AH0 V IH2 Z AH0 M'],
  "bolshevist": ['B OW1 L SH IH0 V IH2 S T'],
  "bolshoi": ['B OW0 L SH OY1'],
  "bolshoi's": ['B OW0 L SH OY1 Z'],
  "bolson": ['B OW1 L S AH0 N'],
  "bolstad": ['B OW1 L S T AH0 D'],
  "bolster": ['B OW1 L S T ER0'],
  "bolstered": ['B OW1 L S T ER0 D'],
  "bolstering": ['B OW1 L S T ER0 IH0 NG'],
  "bolsters": ['B OW1 L S T ER0 Z'],
  "bolt": ['B OW1 L T'],
  "bolte": ['B OW1 L T'],
  "bolted": ['B OW1 L T AH0 D', 'B OW1 L T IH0 D'],
  "bolten": ['B OW1 L T AH0 N'],
  "bolter": ['B OW1 L T ER0'],
  "bolthouse": ['B OW1 L T HH AW2 S'],
  "bolting": ['B OW1 L T IH0 NG'],
  "bolton": ['B OW1 L T AH0 N'],
  "bolts": ['B OW1 L T S'],
  "boltz": ['B OW1 L T S'],
  "boltzmann's": ['B OW1 L T S M AH0 N Z'],
  "boltzmanns": ['B OW1 L T S M AH0 N Z'],
  "bolus": ['B OW1 L AH0 S'],
  "bolyard": ['B AH0 L Y AA1 R D'],
  "bolz": ['B OW1 L Z'],
  "bom": ['B AA1 M'],
  "bomag": ['B OW1 M AE0 G'],
  "boman": ['B OW1 M AH0 N'],
  "bomar": ['B AH0 M AA1 R'],
  "bomb": ['B AA1 M', 'B AO1 M'],
  "bomb's": ['B AA1 M Z'],
  "bomba": ['B AA1 M B AH0'],
  "bombard": ['B AA0 M B AA1 R D'],
  "bombarded": ['B AA0 M B AA1 R D IH0 D'],
  "bombardier": ['B AA2 M B ER0 D IH1 R'],
  "bombardiers": ['B AA2 M B ER0 D IH1 R Z'],
  "bombarding": ['B AA0 M B AA1 R D IH0 NG'],
  "bombardment": ['B AA0 M B AA1 R D M AH0 N T'],
  "bombardments": ['B AA0 M B AA1 R D M AH0 N T S'],
  "bombast": ['B AA1 M B AE0 S T'],
  "bombastic": ['B AA0 M B AE1 S T IH0 K'],
  "bombay": ['B AA0 M B EY1'],
  "bombay's": ['B AA0 M B EY1 Z'],
  "bombeck": ['B AA1 M B EH2 K'],
  "bombed": ['B AA1 M D'],
  "bomber": ['B AA1 M ER0'],
  "bomber's": ['B AA1 M ER0 Z'],
  "bomberg": ['B AA1 M B ER0 G'],
  "bomberger": ['B AA1 M B ER0 G ER0'],
  "bombers": ['B AA1 M ER0 Z'],
  "bombing": ['B AA1 M IH0 NG'],
  "bombings": ['B AA1 M IH0 NG Z'],
  "bombmaker": ['B AA1 M M EY0 K ER0'],
  "bombmaking": ['B AA1 M M EY0 K IH0 NG'],
  "bombs": ['B AA1 M Z'],
  "bombshell": ['B AA1 M SH EH2 L'],
  "bombshells": ['B AA1 M SH EH2 L Z'],
  "bomer": ['B OW1 M ER0'],
  "bomgardner": ['B AA1 M G AA2 R D N ER0'],
  "bommarito": ['B AA2 M ER0 IY1 T OW0'],
  "bommer": ['B AA1 M ER0'],
  "bon": ['B AA1 N'],
  "bona": ['B OW1 N AH0'],
  "bonacci": ['B OW0 N AA1 CH IY0'],
  "bonadio": ['B OW0 N AA1 D IY0 OW0'],
  "bonadonna": ['B AA2 N AH0 D AA1 N AH0'],
  "bonaduce": ['B AA2 N AH0 D UW1 CH IY0', 'B AA1 N AH0 D UW0 S'],
  "bonafide": ['B AA1 N AH0 F AY2 D', 'B OW1 N AH0 F AY2 D'],
  "bonanni": ['B OW0 N AA1 N IY0'],
  "bonanno": ['B OW0 N AA1 N OW0'],
  "bonanza": ['B AH0 N AE1 N Z AH0'],
  "bonaparte": ['B OW1 N AH0 P AA2 R T'],
  "bonaparte's": ['B OW1 N AH0 P AA2 R T S'],
  "bonar": ['B OW1 N ER0'],
  "bonasera": ['B OW0 N AA0 S EH1 R AH0'],
  "bonaventura": ['B OW0 N AA0 V EH0 N T UH1 R AH0'],
  "bonaventure": ['B AA1 N AH0 V EH2 N CH ER0'],
  "bonavia": ['B AA2 N EY1 V IY0 AH0'],
  "bonavita": ['B OW0 N AA0 V IY1 T AH0'],
  "bonawitz": ['B AA1 N AH0 W IH0 T S'],
  "bonczek": ['B AA1 N CH EH0 K'],
  "bond": ['B AA1 N D'],
  "bond's": ['B AA1 N D Z'],
  "bondage": ['B AA1 N D IH0 JH'],
  "bondar": ['B AH0 N D AA1 R'],
  "bonde": ['B AA1 N D'],
  "bonded": ['B AA1 N D AH0 D', 'B AA1 N D IH0 D'],
  "bonderman": ['B AA1 N D ER0 M AH0 N'],
  "bondholder": ['B AA1 N D HH OW2 L D ER0'],
  "bondholders": ['B AA1 N D HH OW2 L D ER0 Z'],
  "bondholders'": ['B AA1 N D HH OW2 L D ER0 Z'],
  "bondi": ['B AA1 N D IY0'],
  "bonding": ['B AA1 N D IH0 NG'],
  "bondra": ['B AA1 N D R AH0'],
  "bonds": ['B AA1 N D Z'],
  "bonds'": ['B AA1 N D Z'],
  "bondt": ['B AA1 N T'],
  "bondurant": ['B OW0 N D UH1 R AH0 N T'],
  "bondy": ['B AA1 N D IY0'],
  "bone": ['B OW1 N'],
  "bonebrake": ['B OW1 N B R EY2 K'],
  "bonecrusher": ['B OW1 N K R AH2 SH ER0'],
  "bonecutter": ['B OW1 N K AH2 T ER0'],
  "boned": ['B OW1 N D'],
  "boneless": ['B OW1 N L AH0 S'],
  "bonelli": ['B OW0 N EH1 L IY0'],
  "bonello": ['B OW0 N EH1 L OW0'],
  "bonenberger": ['B OW1 N AH0 N B ER0 G ER0'],
  "bonenfant": ['B OW1 N F AH0 N T'],
  "boner": ['B OW1 N ER0'],
  "bones": ['B OW1 N Z'],
  "bonesteel": ['B OW1 N S T IY2 L'],
  "bonet": ['B OW1 N T'],
  "bonetti": ['B OW0 N EH1 T IY0'],
  "boney": ['B OW1 N IY0'],
  "bonfanti": ['B OW0 N F AA1 N T IY0'],
  "bonfield": ['B AA1 N F IY2 L D'],
  "bonfiglio": ['B AA2 N F IH1 G L IY0 OW0'],
  "bonfire": ['B AA1 N F AY2 ER0'],
  "bonfires": ['B AA1 N F AY2 ER0 Z'],
  "bong": ['B AA1 NG'],
  "bongard": ['B AH0 NG G AA1 R D'],
  "bongarten": ['B AA1 NG G AA2 R T AH0 N'],
  "bongers": ['B AO1 NG ER0 Z'],
  "bongiorno": ['B OW0 N JH AO1 R N OW0'],
  "bongiovanni": ['B OW0 N JH OW0 V AA1 N IY0'],
  "bongjin": ['B AO1 NG JH IH1 N'],
  "bongo": ['B AA1 NG G OW2'],
  "bongos": ['B AA1 NG G OW2 Z'],
  "bonham": ['B AA1 N HH AH0 M'],
  "boni": ['B OW1 N IY0'],
  "bonica": ['B AA1 N IH0 K AH0'],
  "bonier": ['B AA1 N Y ER0'],
  "bonifacio": ['B AA2 N IH0 F EY1 S IY0 OW0'],
  "bonifas": ['B AA1 N IH0 F AH0 Z'],
  "bonifay": ['B AA1 N IH0 F EY2'],
  "bonifield": ['B AA1 N IH0 F IY2 L D'],
  "bonilla": ['B AH0 N IH1 L AH0'],
  "bonin": ['B OW1 N IH0 N'],
  "bonine": ['B OW0 N IY1 N IY0'],
  "boning": ['B OW1 N IH0 NG'],
  "bonini": ['B OW0 N IY1 N IY0'],
  "bonino": ['B OW0 N IY1 N OW0'],
  "bonior": ['B OW1 N IY0 ER0', 'B OW2 N W AA1 R'],
  "bonita": ['B OW0 N IY1 T AH0'],
  "bonito": ['B AH0 N IY1 T OW0'],
  "bonjour": ['B AH0 N ZH UH1 R'],
  "bonk": ['B AA1 NG K'],
  "bonker": ['B AO1 NG K ER0'],
  "bonkers": ['B AA1 NG K ER0 Z'],
  "bonkowski": ['B AH0 NG K AO1 F S K IY0'],
  "bonn": ['B AA1 N'],
  "bonn's": ['B AA1 N Z'],
  "bonne": ['B AA1 N'],
  "bonneau": ['B AH0 N OW1'],
  "bonnell": ['B AA1 N AH0 L'],
  "bonnema": ['B AA1 N IH0 M AH0'],
  "bonner": ['B AO1 N ER0'],
  "bonnes": ['B AO1 N Z'],
  "bonnet": ['B AA1 N AH0 T'],
  "bonnett": ['B AA1 N IH0 T'],
  "bonnette": ['B AH0 N EH1 T'],
  "bonneville": ['B AA1 N AH0 V IH2 L'],
  "bonnevilles": ['B AA1 N AH0 V IH2 L Z'],
  "bonney": ['B AA1 N IY0'],
  "bonnibel": ['B AA1 N IH0 B EH0 L'],
  "bonnibelle": ['B AA1 N IH0 B EH0 L'],
  "bonnie": ['B AA1 N IY0'],
  "bonnie's": ['B AA1 N IY0 Z'],
  "bonnin": ['B AA1 N IH0 N'],
  "bonnor": ['B AA1 N ER0'],
  "bonny": ['B AA1 N IY0'],
  "bono": ['B OW1 N OW0'],
  "bono's": ['B OW1 N OW0 Z'],
  "bonobo": ['B AA0 N AA1 B OW0', 'B AA1 N AH0 B AH0'],
  "bonobos": ['B AA0 N AA1 B OW0 Z', 'B AA1 N AH0 B AH0 Z'],
  "bonomo": ['B AA1 N AH0 M OW0'],
  "bons": ['B AA1 N S'],
  "bonsai": ['B AA0 N S AY1'],
  "bonsall": ['B OW0 N S AA1 L'],
  "bonser": ['B AA1 N S ER0'],
  "bonsignore": ['B OW0 N S IY0 G N AO1 R IY0'],
  "bonta": ['B AA1 N T AH0'],
  "bonte": ['B AA1 N T'],
  "bontempo": ['B AA2 N T EH1 M P OW0'],
  "bontrager": ['B AA1 N T R EY2 G ER0'],
  "bonum": ['B OW1 N AH0 M'],
  "bonura": ['B OW0 N UH1 R AH0'],
  "bonus": ['B OW1 N AH0 S'],
  "bonuses": ['B OW1 N AH0 S IH0 Z'],
  "bonvillain": ['B AA1 N V IH0 L EY2 N'],
  "bonville": ['B OW1 N V IH0 L'],
  "bonwell": ['B AA1 N W EH2 L'],
  "bonwit": ['B AA1 N W IH0 T'],
  "bonwit's": ['B AA1 N W IH0 T S'],
  "bony": ['B OW1 N IY0'],
  "bonzo": ['B AA1 N Z OW0'],
  "boo": ['B UW1'],
  "boob": ['B UW1 B'],
  "boobs": ['B UW1 B Z'],
  "booby": ['B UW1 B IY0'],
  "boock": ['B UW1 K'],
  "boodle": ['B UW1 D AH0 L'],
  "boodles": ['B UW1 D AH0 L Z'],
  "boody": ['B UW1 D IY0'],
  "booe": ['B AA1 OW0'],
  "booed": ['B UW1 D'],
  "booee": ['B UW1 IY0'],
  "boogie": ['B UW1 G IY0', 'B UH1 G IY0'],
  "boogie-woogie": ['B UW1 G IY0 W UW1 G IY0', 'B UH1 G IY0 W UH1 G IY0'],
  "booher": ['B UW1 ER0'],
  "booing": ['B UW1 IH0 NG'],
  "book": ['B UH1 K'],
  "book's": ['B UH1 K S'],
  "bookbinder": ['B UH1 K B AY2 N D ER0'],
  "bookbinders": ['B UH1 K B AY2 N D ER0 Z'],
  "bookcase": ['B UH1 K K EY2 S'],
  "bookcases": ['B UH1 K K EY2 S IH0 Z'],
  "bookcellar": ['B UH1 K S EH1 L ER0'],
  "bookcellars": ['B UH1 K S EH1 L ER0 Z'],
  "booked": ['B UH1 K T'],
  "bookend": ['B UH1 K EH2 N D'],
  "bookends": ['B UH1 K EH2 N D Z'],
  "booker": ['B UH1 K ER0'],
  "booker's": ['B UH1 K ER0 Z'],
  "bookers": ['B UH1 K ER0 Z'],
  "bookie": ['B UH1 K IY0'],
  "bookies": ['B UH1 K IY0 Z'],
  "bookin": ['B UH1 K IH0 N'],
  "booking": ['B UH1 K IH0 NG'],
  "bookings": ['B UH1 K IH0 NG Z'],
  "bookish": ['B UH1 K IH0 SH'],
  "bookkeeper": ['B UH1 K K IY2 P ER0'],
  "bookkeepers": ['B UH1 K K IY2 P ER0 Z'],
  "bookkeeping": ['B UH1 K K IY2 P IH0 NG'],
  "booklet": ['B UH1 K L IH0 T'],
  "booklets": ['B UH1 K L IH0 T S'],
  "bookmaker": ['B UH1 K M EY0 K ER0'],
  "bookmakers": ['B UH1 K M EY0 K ER0 Z'],
  "bookmaking": ['B UH1 K M EY2 K IH0 NG'],
  "bookman": ['B UH1 K M AH0 N'],
  "bookmark": ['B UH1 K M AA0 R K'],
  "bookmarks": ['B UH1 K M AA0 R K S'],
  "bookmobile": ['B UH1 K M OW0 B IY2 L'],
  "bookmobiles": ['B UH1 K M OW0 B IY2 L Z'],
  "booknote": ['B UH1 K N OW2 T'],
  "booknotes": ['B UH1 K N OW2 T S'],
  "bookout": ['B UH1 K AW2 T'],
  "books": ['B UH1 K S'],
  "books'": ['B UH1 K S'],
  "bookseller": ['B UH1 K S EH2 L ER0'],
  "booksellers": ['B UH1 K S EH2 L ER0 Z'],
  "bookshelf": ['B UH1 K SH EH2 L F'],
  "bookshelves": ['B UH1 K SH EH2 L V Z'],
  "bookshop": ['B UH1 K SH AA2 P'],
  "bookshops": ['B UH1 K SH AA2 P S'],
  "booksmith": ['B UH1 K S M IH2 TH'],
  "bookstand": ['B UH1 K S T AE0 N D'],
  "bookstands": ['B UH1 K S T AE0 N D Z'],
  "bookstop": ['B UH1 K S T AA2 P'],
  "bookstops": ['B UH1 K S T AA2 P S'],
  "bookstore": ['B UH1 K S T AO2 R'],
  "bookstores": ['B UH1 K S T AO2 R Z'],
  "bookwalter": ['B UH1 K W AH0 L T ER0'],
  "bookworm": ['B UH1 K W ER2 M'],
  "boole": ['B UW1 L'],
  "boolean": ['B UW0 L IY1 AH0 N'],
  "boom": ['B UW1 M'],
  "boom's": ['B UW1 M Z'],
  "boombox": ['B UH1 M B AA2 K S'],
  "boomboxes": ['B UH1 M B AA2 K S IH0 Z'],
  "boomed": ['B UW1 M D'],
  "boomer": ['B UW1 M ER0'],
  "boomerang": ['B UW1 M ER0 AE2 NG'],
  "boomeranged": ['B UW1 M ER0 AE2 NG D'],
  "boomers": ['B UW1 M ER0 Z'],
  "boomers'": ['B UW1 M ER0 Z'],
  "boomershine": ['B UW1 M ER0 SH AY2 N'],
  "boomhower": ['B UW1 M HH OW0 ER0'],
  "booming": ['B UW1 M IH0 NG'],
  "boomlet": ['B UW1 M L AH0 T'],
  "booms": ['B UW1 M Z'],
  "boomsma": ['B UW1 M Z M AH0'],
  "boomtown": ['B UW1 M T AW2 N'],
  "boon": ['B UW1 N'],
  "boondocks": ['B UW1 N D AA2 K S'],
  "boondoggle": ['B UW2 N D AA1 G AH0 L'],
  "boondoggles": ['B UW2 N D AA1 G AH0 L Z'],
  "boone": ['B UW1 N'],
  "boons": ['B UW1 N Z'],
  "boonstra": ['B UW1 N S T R AH0'],
  "boonton": ['B UW1 N T AH0 N'],
  "boonville": ['B UW1 N V IH0 L'],
  "boop": ['B UW1 P'],
  "boor": ['B UH1 R'],
  "booras": ['B UH1 R AH0 Z'],
  "boord": ['B UH1 R D'],
  "boorda": ['B UH1 R D AH0'],
  "boorda's": ['B UH1 R D AH0 Z'],
  "boorish": ['B UH1 R IH0 SH'],
  "boorishness": ['B UH1 R IH0 SH N AH0 S'],
  "boorman": ['B UH1 R M AH0 N'],
  "boors": ['B UH1 R Z'],
  "boorstin": ['B UW1 R S T IH0 N'],
  "boortz": ['B AO1 R T S'],
  "boos": ['B UW1 Z'],
  "boose": ['B UW1 S'],
  "boost": ['B UW1 S T'],
  "boosted": ['B UW1 S T IH0 D'],
  "booster": ['B UW1 S T ER0'],
  "booster's": ['B UW1 S T ER0 Z'],
  "boosterism": ['B UW1 S T ER0 IH2 Z AH0 M'],
  "boosters": ['B UW1 S T ER0 Z'],
  "boosting": ['B UW1 S T IH0 NG'],
  "boosts": ['B UW1 S T S'],
  "boot": ['B UW1 T'],
  "boote": ['B UW1 T'],
  "booted": ['B UW1 T IH0 D'],
  "booten": ['B UW1 T AH0 N'],
  "booth": ['B UW1 TH'],
  "boothby": ['B UW1 TH B IY0'],
  "boothe": ['B UW1 DH'],
  "boothman": ['B UW1 TH M AH0 N'],
  "boothroyd": ['B UW1 TH R OY2 D'],
  "booths": ['B UW1 TH S'],
  "bootie": ['B UW1 T IY0'],
  "booties": ['B UW1 T IY0 Z'],
  "booting": ['B UW1 T IH0 NG'],
  "bootleg": ['B UW1 T L EH2 G'],
  "bootlegger": ['B UW1 T L EH2 G ER0'],
  "bootleggers": ['B UW1 T L EH2 G ER0 Z'],
  "bootlegging": ['B UW1 T L EH2 G IH0 NG'],
  "bootlegs": ['B UW1 T L EH2 G Z'],
  "booton": ['B UW1 T AH0 N'],
  "boots": ['B UW1 T S'],
  "bootstrap": ['B UW1 T S T R AE2 P'],
  "bootstraps": ['B UW1 T S T R AE2 P S'],
  "booty": ['B UW1 T IY0'],
  "bootz": ['B UW1 T S'],
  "booz": ['B UW1 Z'],
  "booze": ['B UW1 Z'],
  "boozer": ['B UW1 Z ER0'],
  "boozier": ['B UW1 Z IY0 ER0'],
  "boozing": ['B UW1 Z IH0 NG'],
  "boozy": ['B UW1 Z IY0'],
  "bop": ['B AA1 P'],
  "bopeep": ['B OW0 P IY1 P'],
  "bopera": ['B OW0 P EH1 R AH0'],
  "bopha": ['B OW1 F AH0'],
  "bophuthatswana": ['B OW2 F UW0 TH AA0 T S W AA1 N AH0'],
  "bopp": ['B AA1 P'],
  "bopper": ['B AA1 P ER0'],
  "boppers": ['B AA1 P ER0 Z'],
  "boquist": ['B AA1 K W IH0 S T'],
  "bora": ['B AO1 R AH0'],
  "borah": ['B AO1 R AH0'],
  "borak": ['B AO1 R AH0 K'],
  "boral": ['B AO1 R AH0 L'],
  "borawski": ['B ER0 AA1 F S K IY0'],
  "borax": ['B AO1 R AE2 K S'],
  "borba": ['B AO1 R B AH0'],
  "borchard": ['B ER0 SH AA1 R D'],
  "borchardt": ['B ER0 SH AA1 R D T'],
  "borchelt": ['B AO1 R K IH0 L T'],
  "borcherding": ['B AO1 R K ER0 D IH0 NG'],
  "borchers": ['B AO1 R K ER0 Z'],
  "borchert": ['B AO1 R K ER0 T'],
  "borck": ['B AO1 R K'],
  "borda": ['B AO1 R D AH0'],
  "borda's": ['B AO1 R D AH0 Z'],
  "bordallo": ['B AO0 R D AE1 L OW0'],
  "bordas": ['B AO1 R D AH0 Z'],
  "bordeau": ['B ER0 D OW1'],
  "bordeaux": ['B AO0 R D OW1'],
  "bordello": ['B AO0 R D EH1 L OW2'],
  "bordelon": ['B AO1 R D IH0 L AA0 N'],
  "borden": ['B AO1 R D AH0 N'],
  "borden's": ['B AO1 R D AH0 N Z'],
  "bordenave": ['B AO1 R D EH0 N AA2 V', 'B AO0 R D EH0 N AA1 V'],
  "border": ['B AO1 R D ER0'],
  "border's": ['B AO1 R D ER0 Z'],
  "bordered": ['B AO1 R D ER0 D'],
  "bordering": ['B AO1 R D ER0 IH0 NG'],
  "borderline": ['B AO1 R D ER0 L AY2 N'],
  "borders": ['B AO1 R D ER0 Z'],
  "bordes": ['B AO1 R D Z'],
  "bordman": ['B AO1 R D M AH0 N'],
  "bordner": ['B AO1 R D N ER0'],
  "bordonaro": ['B AO0 R D OW0 N AA1 R OW0'],
  "bordwell": ['B AO1 R D W EH0 L'],
  "bore": ['B AO1 R'],
  "borealis": ['B AO2 R IY0 AE1 L AH0 S'],
  "bored": ['B AO1 R D'],
  "boredom": ['B AO1 R D AH0 M'],
  "borek": ['B AO1 R IH0 K'],
  "borel": ['B AO1 R AH0 L'],
  "borell": ['B AO1 R AH0 L'],
  "borella": ['B AO0 R EH1 L AH0'],
  "borelli": ['B AO0 R EH1 L IY0'],
  "borello": ['B AO0 R EH1 L OW0'],
  "boren": ['B AO1 R AH0 N'],
  "boren's": ['B AO1 R AH0 N Z'],
  "borenstein": ['B AO1 R AH0 N S T AY2 N', 'B AO1 R AH0 N S T IY2 N'],
  "borer": ['B AO1 R ER0'],
  "bores": ['B AO1 R Z'],
  "borg": ['B AO1 R G'],
  "borge": ['B AO1 R JH'],
  "borgelt": ['B AO1 R G IH0 L T'],
  "borgen": ['B AO1 R G AH0 N'],
  "borger": ['B AO1 R G ER0'],
  "borgerding": ['B AO1 R G ER0 D IH0 NG'],
  "borgert": ['B AO1 R G ER0 T'],
  "borges": ['B AO1 R G EY0 S'],
  "borgeson": ['B AO1 R G IH0 S AH0 N'],
  "borgess": ['B AO1 R G IH0 S'],
  "borghi": ['B AO1 R G IY0'],
  "borgia": ['B AO1 R JH AH0'],
  "borgman": ['B AO1 R G M AH0 N'],
  "borgmann": ['B AO1 R G M AH0 N'],
  "borgmeyer": ['B AO1 R G M AY0 ER0'],
  "borgstrom": ['B AO1 R G S T R AH0 M'],
  "borgwardt": ['B AO1 R G W AO2 R T'],
  "boric": ['B AO1 R IH0 K'],
  "borin": ['B AO1 R IH0 N'],
  "boring": ['B AO1 R IH0 NG'],
  "boris": ['B AO1 R IH0 S'],
  "boris'": ['B AO1 R IH0 S'],
  "boris's": ['B AO1 R IH0 S IH0 Z'],
  "borja": ['B AO1 R Y AH0'],
  "borjas": ['B AO1 R Y AH0 Z'],
  "bork": ['B AO1 R K'],
  "bork's": ['B AO1 R K S'],
  "borkenhagen": ['B AO1 R K IH0 N HH AA2 G AH0 N', 'B AO1 R K IH0 N HH EY2 G AH0 N'],
  "borkowski": ['B ER0 K AO1 F S K IY0'],
  "borland": ['B AO1 R L AH0 N D'],
  "borland's": ['B AO1 R L AH0 N D Z'],
  "borman": ['B AO1 R M AH0 N'],
  "borman's": ['B AO1 R M AH0 N Z'],
  "bormann": ['B AO1 R M AH0 N'],
  "born": ['B AO1 R N'],
  "born's": ['B AO1 R N Z'],
  "borne": ['B AO1 R N'],
  "borneman": ['B AO1 R N M AH0 N'],
  "bornemann": ['B AO1 R N M AH0 N'],
  "borneo": ['B AO1 R N IY0 OW2'],
  "borner": ['B AO1 R N ER0'],
  "bornholdt": ['B AO1 R N HH OW0 L T'],
  "bornhorst": ['B AO1 R N HH AO0 R S T'],
  "bornman": ['B AO1 R N M AH0 N'],
  "borns": ['B AO1 R N Z'],
  "bornstein": ['B AO1 R N S T AY1 N', 'B AO1 R N S T IY1 N'],
  "borntrager": ['B AO1 R N T R EY0 G ER0'],
  "boro": ['B ER1 OW0'],
  "borocce": ['B AH0 R OW1 CH IY0'],
  "boroff": ['B AO1 R AO0 F'],
  "boroian": ['B AO0 R OY1 AH0 N'],
  "boron": ['B AO1 R AA2 N'],
  "boros": ['B ER1 OW0 Z'],
  "borosage": ['B AO1 R AH0 S IH0 JH'],
  "boroski": ['B ER0 AW1 S K IY0'],
  "borough": ['B ER1 OW2'],
  "borough's": ['B ER1 OW2 Z'],
  "boroughs": ['B ER1 OW2 Z'],
  "borowiak": ['B ER0 AW1 IY0 AE0 K'],
  "borowicz": ['B ER1 OW0 V IH0 CH'],
  "borowiec": ['B ER0 AW1 IY0 K'],
  "borowski": ['B ER0 AO1 F S K IY0'],
  "borowsky": ['B ER0 AW1 S K IY0'],
  "borowy": ['B ER0 AW1 IY0'],
  "borquez": ['B AO0 R K W EH1 Z'],
  "borras": ['B AO1 R AH0 Z'],
  "borre": ['B AO1 R'],
  "borrego": ['B AO0 R EH1 G OW0'],
  "borrell": ['B AO0 R EY1 L'],
  "borrelli": ['B AO0 R EH1 L IY0'],
  "borrello": ['B AO2 R EH1 L OW0'],
  "borrero": ['B AO0 R EH1 R OW0'],
  "borris": ['B AO1 R IH0 S'],
  "borroff": ['B AO1 R AO0 F'],
  "borror": ['B AO1 ER0 R'],
  "borrow": ['B AA1 R OW2'],
  "borrowed": ['B AA1 R OW2 D'],
  "borrower": ['B AA1 R OW0 ER0'],
  "borrower's": ['B AA1 R OW0 ER0 Z'],
  "borrowers": ['B AA1 R OW0 ER0 Z'],
  "borrowers'": ['B AA1 R OW0 ER0 Z'],
  "borrowing": ['B AA1 R OW0 IH0 NG'],
  "borrowings": ['B AA1 R OW0 IH0 NG Z'],
  "borrowman": ['B AA1 R OW0 M AH0 N'],
  "borrows": ['B AA1 R OW0 Z'],
  "borruso": ['B AO2 R UW1 S OW0'],
  "bors": ['B AO1 R Z'],
  "borsch": ['B AO1 R SH'],
  "borscht": ['B AO1 R SH T'],
  "borse": ['B AO1 R S'],
  "borseth": ['B AO1 R S IH0 TH'],
  "borski": ['B AO1 R S K IY0'],
  "borsod": ['B AO2 R S AO1 D'],
  "borsodi": ['B AO2 R S AO1 D IY2'],
  "borst": ['B AO1 R S T'],
  "borsuk": ['B AO1 R S AH0 K'],
  "bort": ['B AO1 R T'],
  "bortel": ['B AO1 R T EH2 L'],
  "borten": ['B AO1 R T AH0 N'],
  "borth": ['B AO1 R TH'],
  "borthwick": ['B AO1 R TH W IH0 K'],
  "bortle": ['B AO1 R T AH0 L'],
  "bortner": ['B AO1 R T N ER0'],
  "bortnick": ['B AO1 R T N IH0 K'],
  "borton": ['B AO1 R T AH0 N'],
  "bortz": ['B AO1 R T S'],
  "boruch": ['B AO1 R AH0 K'],
  "borucki": ['B ER0 AH1 T S K IY0'],
  "boruff": ['B AO1 R AH0 F'],
  "borum": ['B AO1 R AH0 M'],
  "borunda": ['B AO0 R UW1 N D AH0'],
  "borup": ['B AO1 R AH0 P'],
  "borys": ['B AO1 R IY0 Z'],
  "bos": ['B AA1 S'],
  "bosak": ['B OW1 S AH0 K'],
  "bosarge": ['B AA1 S AA0 R G'],
  "boscarino": ['B OW0 S K AA0 R IY1 N OW0'],
  "bosch": ['B AO1 SH'],
  "boschee": ['B AO1 SH IY0'],
  "boschen": ['B AO1 SH AH0 N'],
  "boschert": ['B AO1 SH ER0 T'],
  "boschwitz": ['B AO1 SH W IH0 T S'],
  "boscia": ['B OW1 S CH AH0'],
  "bosco": ['B AO1 S K OW0'],
  "bose": ['B OW1 Z'],
  "boseman": ['B OW1 S M AH0 N'],
  "bosendorfer": ['B OW1 Z AH0 N D AO2 R F ER0'],
  "boser": ['B OW1 Z ER0'],
  "bosh": ['B AA1 SH'],
  "boshart": ['B AA1 SH AA0 R T'],
  "boshears": ['B AA1 SH IH0 R Z'],
  "boshell": ['B AA1 SH AH0 L'],
  "bosher": ['B AA1 SH ER0'],
  "boshers": ['B AA1 SH ER0 Z'],
  "boskin": ['B AO1 S K IH0 N'],
  "bosko": ['B OW1 S K OW0'],
  "boskovich": ['B AA1 S K AH0 V IH0 CH'],
  "boslego": ['B AO2 S L EY1 G OW0'],
  "bosler": ['B AA1 S AH0 L ER0', 'B AA1 Z L ER0'],
  "bosley": ['B AA1 Z L IY0'],
  "bosma": ['B OW1 S M AH0'],
  "bosman": ['B AA1 S M AH0 N'],
  "bosnia": ['B AA1 Z N IY0 AH0'],
  "bosnia's": ['B AA1 Z N IY0 AH0 Z'],
  "bosnian": ['B AA1 Z N IY0 AH0 N'],
  "bosnian's": ['B AA1 Z N IY0 AH0 N Z'],
  "bosnians": ['B AA1 Z N IY0 AH0 N Z'],
  "bosnias": ['B AA1 Z N IY0 AH0 Z'],
  "boso": ['B OW1 S OW0'],
  "bosom": ['B UH1 Z AH0 M'],
  "bosqi": ['B AA1 S K IY0'],
  "bosquez": ['B OW0 S K W EH1 Z'],
  "boss": ['B AA1 S', 'B AO1 S'],
  "boss'": ['B AO1 S'],
  "boss's": ['B AO1 S IH0 Z'],
  "bossard": ['B AH0 S AA1 R D'],
  "bossart": ['B AH0 S AA1 R T'],
  "bosse": ['B AA1 S'],
  "bossed": ['B AA1 S T'],
  "bossen": ['B AA1 S AH0 N'],
  "bosserman": ['B AO1 S ER0 M AH0 N'],
  "bossert": ['B AA1 S ER0 T'],
  "bosses": ['B AO1 S IH0 Z'],
  "bosses'": ['B AO1 S IH0 Z'],
  "bosshardt": ['B AO1 S HH AA2 R T'],
  "bosshart": ['B AO1 S HH AA2 R T'],
  "bossi": ['B OW1 S IY0'],
  "bossidy": ['B AO1 S IH0 D IY0'],
  "bossie": ['B AO1 S IY0'],
  "bossier": ['B AO1 S IY0 ER0'],
  "bossler": ['B AA1 S AH0 L ER0', 'B AA1 S L ER0'],
  "bossman": ['B AO1 S M AH0 N'],
  "bosso": ['B OW1 S OW0'],
  "bossy": ['B AO1 S IY0'],
  "bost": ['B AA1 S T'],
  "bostelman": ['B AA1 S T AH0 L M AH0 N'],
  "boster": ['B AA1 S T ER0'],
  "bostian": ['B AA1 S CH IH0 N'],
  "bostic": ['B AA1 S T IH0 K'],
  "bostick": ['B OW1 S T IH0 K'],
  "bostock": ['B OW1 S T AA2 K'],
  "boston": ['B AA1 S T AH0 N', 'B AO1 S T AH0 N'],
  "boston's": ['B AO1 S T AH0 N Z'],
  "bostonian": ['B AO2 S T OW1 N IY0 AH0 N'],
  "bostonians": ['B AA1 S T OW0 N IY0 AH0 N Z'],
  "bostra": ['B AA1 S T R AH0'],
  "bostrom": ['B AA1 S T R AH0 M'],
  "bostwick": ['B AA1 S T W IH0 K'],
  "boswell": ['B AA1 Z W EH0 L'],
  "bosworth": ['B AO1 Z W ER0 TH'],
  "bot": ['B AO1 T'],
  "botanic": ['B AH0 T AE1 N IH0 K'],
  "botanical": ['B AH0 T AE1 N IH0 K AH0 L'],
  "botanically": ['B AH0 T AE1 N AH0 K AH0 L IY0', 'B AH0 T AE1 N AH0 K L IY0'],
  "botanist": ['B AA1 T AH0 N IH0 S T'],
  "botanists": ['B AA1 T AH0 N IH0 S T S'],
  "botany": ['B AA1 T AH0 N IY0'],
  "botch": ['B AA1 CH'],
  "botched": ['B AA1 CH T'],
  "boteler": ['B AA1 T AH0 L ER0'],
  "botelho": ['B OW0 T EH1 L OW0'],
  "botello": ['B OW0 T EH1 L OW0'],
  "botero": ['B OW0 T EH1 R OW0'],
  "botfly": ['B AA1 T F L AY2'],
  "both": ['B OW1 TH'],
  "botha": ['B AA1 TH AH0', 'B OW1 T AH0', 'B OW1 T AH2'],
  "botha's": ['B AA1 TH AH0 Z', 'B OW1 T AH2 Z', 'B OW1 T AH0 Z'],
  "botham": ['B AA1 TH AH0 M'],
  "botham's": ['B AA1 TH AH0 M Z'],
  "bothe": ['B OW1 DH'],
  "bothell": ['B AA1 TH AH0 L'],
  "bother": ['B AA1 DH ER0'],
  "bothered": ['B AA1 DH ER0 D'],
  "bothering": ['B AA1 DH ER0 IH0 NG'],
  "bothers": ['B AA1 DH ER0 Z'],
  "bothersome": ['B AA1 DH ER0 S AH0 M'],
  "bothun": ['B AA1 TH AH0 N'],
  "bothwell": ['B AA1 TH W EH2 L'],
  "botin": ['B AA1 T IH0 N'],
  "botkin": ['B AA1 T K IH0 N'],
  "botkins": ['B AA1 T K IH0 N Z'],
  "botner": ['B AA1 T N ER0'],
  "botolf": ['B AA1 T OW0 L F'],
  "boton": ['B OW1 T AH0 N'],
  "botos": ['B OW1 T OW0 Z'],
  "botox": ['B OW1 T AO2 K S'],
  "botrytis": ['B AH0 T R IH1 T IH0 S'],
  "bots": ['B AO1 T S'],
  "botsford": ['B AA1 T S F ER0 D'],
  "botshabelo": ['B AA2 CH AH0 B EH1 L OW0'],
  "botswana": ['B AA0 T S W AA1 N AH0'],
  "bott": ['B AA1 T'],
  "botta": ['B AA1 T AH0'],
  "bottari": ['B OW0 T AA1 R IY0'],
  "bottcher": ['B AA1 T CH ER0'],
  "bottecelli": ['B AO2 T AH0 CH EH1 L IY0'],
  "bottel": ['B AA1 T AH0 L'],
  "botten": ['B AA1 T AH0 N'],
  "bottenfield": ['B AH0 T EH1 N F IY0 L D'],
  "bottger": ['B AA1 T G ER0'],
  "botting": ['B AA1 T IH0 NG'],
  "bottini": ['B OW0 T IY1 N IY0'],
  "bottino": ['B OW0 T IY1 N OW0'],
  "bottle": ['B AA1 T AH0 L'],
  "bottled": ['B AA1 T AH0 L D'],
  "bottleneck": ['B AA1 T AH0 L N EH2 K'],
  "bottlenecks": ['B AA1 T AH0 L N EH2 K S'],
  "bottler": ['B AA1 T L ER0'],
  "bottlers": ['B AA1 T L ER0 Z'],
  "bottlers'": ['B AA1 T L ER0 Z'],
  "bottles": ['B AA1 T AH0 L Z'],
  "bottling": ['B AA1 T AH0 L IH0 NG', 'B AA1 T L IH0 NG'],
  "bottom": ['B AA1 T AH0 M'],
  "bottom's": ['B AA1 T AH0 M Z'],
  "bottom-up": ['B AA1 T AH0 M AH2 P'],
  "bottomed": ['B AA1 T AH0 M D'],
  "bottomfish": ['B AA1 T AH0 M F IH2 SH'],
  "bottoming": ['B AA1 T AH0 M IH0 NG'],
  "bottomless": ['B AA1 T AH0 M L AH0 S'],
  "bottomley": ['B AA1 T AH0 M L IY0'],
  "bottoms": ['B AA1 T AH0 M Z'],
  "bottone": ['B OW0 T OW1 N IY0'],
  "bottorf": ['B AA1 T ER0 F'],
  "bottorff": ['B AA1 T ER0 F'],
  "bottrell": ['B AA1 T R AH0 L'],
  "botts": ['B AA1 T S'],
  "botulism": ['B AA1 CH UW0 L IH2 Z AH0 M'],
  "botvinick": ['B AO1 T V IH0 N IH0 K'],
  "botz": ['B AA1 T S'],
  "bouch": ['B AW1 CH'],
  "bouchard": ['B UW0 SH AA1 R D'],
  "bouche": ['B AW1 CH'],
  "boucher": ['B UW1 SH AH0', 'B AW1 CH ER0', 'B AO1 CH ER0'],
  "bouchey": ['B UW0 SH IY1'],
  "bouchie": ['B AW1 CH IY0'],
  "bouchillon": ['B AW1 CH IH0 L AA0 N'],
  "bouck": ['B OW1 K'],
  "boudin": ['B UW1 D IH0 N'],
  "boudoin": ['B UW0 D OY1 N'],
  "boudoir": ['B UW1 D OY2 R'],
  "boudreau": ['B UW2 D R OW1'],
  "boudreaux": ['B UW2 D R OW1'],
  "bouffard": ['B UW0 F AA1 R D'],
  "bougainville": ['B UW1 G IH0 N V IH2 L'],
  "bougainvillea": ['B UW2 G EY2 N V IH1 L IY0 AH0'],
  "bough": ['B AW1'],
  "boughan": ['B AW1 AH0 N'],
  "bougher": ['B AW1 ER0'],
  "boughman": ['B AW1 M AH0 N'],
  "boughner": ['B AW1 N ER0'],
  "boughs": ['B AW1 Z'],
  "bought": ['B AA1 T', 'B AO1 T'],
  "boughten": ['B AO1 T AH0 N'],
  "boughter": ['B AO1 T ER0'],
  "boughton": ['B AW1 T AH0 N'],
  "bougie": ['B UW1 ZH IY2'],
  "bouie": ['B UW0 IY1'],
  "bouillabaise": ['B UW1 L Y AH0 B EY2 S'],
  "bouillon": ['B UW2 W IH1 L AH0 N'],
  "bouknight": ['B AW1 K N AY0 T'],
  "boulais": ['B UW0 L EY1'],
  "boulalas": ['B UW1 L AH0 L AH0 S'],
  "boulanger": ['B AW1 L AH0 NG ER0'],
  "boulangerie": ['B UW2 L AE1 NG G ER0 IY0'],
  "boulay": ['B UW0 L EY1'],
  "boulden": ['B UH1 D AH0 N'],
  "boulder": ['B OW1 L D ER0'],
  "boulders": ['B OW1 L D ER0 Z'],
  "boulding": ['B OW1 L D IH2 NG'],
  "boule": ['B UW1 L'],
  "bouler": ['B AW1 L ER0'],
  "boulet": ['B UW0 L EH1 T'],
  "boulette": ['B UW2 L EH1 T'],
  "boulevard": ['B UH1 L AH0 V AA2 R D'],
  "boulevards": ['B UH1 L AH0 V AA2 R D Z'],
  "bouley": ['B UW0 L IY1'],
  "boulez": ['B UW1 L EH2 Z'],
  "boulier": ['B UW1 L IY0 ER0'],
  "boullion": ['B UW1 L Y AH0 N'],
  "boulos": ['B UW0 L OW1 Z'],
  "boulter": ['B OW1 L T ER0'],
  "boultinghouse": ['B AW1 L T IH0 NG HH AW2 S'],
  "boulton": ['B AW1 L T AH0 N'],
  "boulware": ['B AW1 L W EH0 R'],
  "bouma": ['B OW1 M AH0'],
  "bouman": ['B UW0 M AE1 N'],
  "bounce": ['B AW1 N S'],
  "bounced": ['B AW1 N S T'],
  "bouncer": ['B AW1 N S ER0'],
  "bouncers": ['B AW1 N S ER0 Z'],
  "bounces": ['B AW1 N S IH0 Z'],
  "bounciness": ['B AW1 N S IY0 N AH0 S'],
  "bouncing": ['B AW1 N S IH0 NG'],
  "bouncy": ['B AW1 N S IY0'],
  "bound": ['B AW1 N D'],
  "boundaries": ['B AW1 N D ER0 IY0 Z', 'B AW1 N D R IY0 Z'],
  "boundary": ['B AW1 N D ER0 IY0', 'B AW1 N D R IY0'],
  "bounded": ['B AW1 N D AH0 D', 'B AW1 N D IH0 D'],
  "bounder": ['B AW1 N D ER0'],
  "bounding": ['B AW1 N D IH0 NG'],
  "boundless": ['B AW1 N D L AH0 S'],
  "bounds": ['B AW1 N D Z'],
  "boundy": ['B AW1 N D IY0'],
  "bounties": ['B AW1 N T IY0 Z'],
  "bountiful": ['B AW1 N T IH0 F AH0 L', 'B AW1 N IH0 F AH0 L'],
  "bounty": ['B AW1 N T IY0'],
  "bouquet": ['B UW0 K EY1'],
  "bouquets": ['B OW0 K EY1 Z', 'B UW0 K EY1 Z'],
  "bour": ['B AW1 R'],
  "bourassa": ['B UH0 R AA1 S AH0'],
  "bourbeau": ['B UH0 R B OW1'],
  "bourbon": ['B ER1 B AH0 N'],
  "bourbons": ['B ER1 B AH0 N Z'],
  "bourcier": ['B AW1 R K IY0 ER0'],
  "bourdeau": ['B UH0 R D OW1'],
  "bourg": ['B AO1 R G'],
  "bourgault": ['B UH0 R G OW1'],
  "bourgeois": ['B UH0 R ZH W AA1', 'B UH1 R ZH W AA0'],
  "bourgeoisie": ['B UH2 R ZH W AA2 Z IY1'],
  "bourget": ['B UH0 R ZH EH1 T'],
  "bourgoin": ['B UH0 R G OY1 N'],
  "bourguiba": ['B AO0 R G W IY1 B AH0'],
  "bourguignon": ['B UH2 R G IY0 N Y OW1 N'],
  "bourke": ['B ER1 K'],
  "bourland": ['B UH0 R L AE1 N D'],
  "bourn": ['B AO1 R N'],
  "bourne": ['B AO1 R N'],
  "bournewood": ['B AO1 R N W UH2 D'],
  "bournias": ['B AO1 R N IY0 AH0 S'],
  "bournonville": ['B AO1 R N AH0 N V IH2 L'],
  "bourque": ['B UH1 R K'],
  "bourquin": ['B UH0 R K W AE1 N'],
  "bourret": ['B UH0 R EH1 T'],
  "bourse": ['B AO1 R S'],
  "bourse's": ['B AO1 R S IH0 Z'],
  "bourses": ['B AO1 R S IH0 Z'],
  "boursicot": ['B UW1 R S IH0 K AO2 T', 'B AH1 R S AH0 K AO0 T'],
  "bouse": ['B AW1 S'],
  "bouska": ['B UW1 S K AH0'],
  "bousman": ['B AH0 S M AE1 N'],
  "bousquet": ['B UW0 S K EH1 T'],
  "boussac": ['B UW1 S AE0 K'],
  "boustany": ['B UW1 S T AH0 N IY0'],
  "bout": ['B AW1 T'],
  "boutell": ['B UW0 T EH1 L'],
  "boutelle": ['B UW2 T EH1 L'],
  "bouthillier": ['B AW1 TH AH0 L IY0 ER0'],
  "boutilier": ['B AW1 T AH0 L IY0 ER0'],
  "boutin": ['B UW0 T AE1 N'],
  "boutique": ['B UW0 T IY1 K'],
  "boutiques": ['B UW0 T IY1 K S'],
  "bouton": ['B AW1 T AH0 N'],
  "boutros": ['B UW1 T R OW2 S'],
  "bouts": ['B AW1 T S'],
  "boutte": ['B UW1 T'],
  "boutwell": ['B AW1 T W EH2 L'],
  "bouvier": ['B UW2 V IY0 EY1'],
  "bouwens": ['B AW1 AH0 N Z'],
  "bouwkamp": ['B AW1 K AE2 M P'],
  "bouwman": ['B AW1 M AH0 N'],
  "bouwsma": ['B UW1 Z M AH0'],
  "bouyer": ['B OY1 ER0'],
  "bouygues": ['B OY1 ZH EY1', 'B OY1 G EH1 Z'],
  "bouza": ['B UW1 Z AH0'],
  "bova": ['B OW1 V AH0'],
  "bovard": ['B AH0 V AA1 R D'],
  "bove": ['B OW1 V'],
  "bovee": ['B AH1 V IY0'],
  "boven": ['B AH1 V AH0 N'],
  "bovenzi": ['B OW0 V EH1 N Z IY0'],
  "boveri": ['B OW0 V EH1 R IY0'],
  "bovespa": ['B OW1 V S P AA0'],
  "bovey": ['B OW1 V IY0'],
  "bovik": ['B OW1 V IH0 K'],
  "bovin": ['B OW1 V IH0 N'],
  "bovina": ['B OW0 V IY1 N AH0'],
  "bovine": ['B OW1 V AY2 N'],
  "bovino": ['B OW0 V IY1 N OW0'],
  "bow": ['B AW1', 'B OW1'],
  "bowar": ['B OW1 ER0'],
  "boward": ['B OW1 ER0 D'],
  "bowater": ['B AW1 AH2 T ER0', 'B OW1 AO1 T ER0', 'B OW1 W AO1 T ER0'],
  "bowcan": ['B OW1 K AH0 N'],
  "bowden": ['B OW1 D AH0 N', 'B AW1 D AH0 N'],
  "bowdish": ['B OW1 D IH0 SH'],
  "bowditch": ['B OW1 D IH0 CH'],
  "bowdle": ['B OW1 D AH0 L'],
  "bowdoin": ['B OW0 D OY1 N'],
  "bowe": ['B OW1'],
  "bowed": ['B AW1 D', 'B OW1 D'],
  "bowel": ['B AW1 AH0 L'],
  "bowell": ['B AA1 W EH0 L'],
  "bowels": ['B AW1 AH0 L Z', 'B AW1 L Z'],
  "bowen": ['B OW1 AH0 N'],
  "bowens": ['B OW1 AH0 N Z'],
  "bower": ['B AW1 ER0'],
  "bowerman": ['B OW1 ER0 M AH0 N'],
  "bowermaster": ['B OW1 ER0 M AE0 S T ER0'],
  "bowers": ['B AW1 ER0 Z'],
  "bowersock": ['B OW0 ER1 S AH0 K'],
  "bowersox": ['B OW0 ER1 S AA0 K S'],
  "bowery": ['B AW1 ER0 IY0'],
  "bowery's": ['B AW1 ER0 IY0 Z'],
  "bowes": ['B OW1 Z'],
  "bowick": ['B OW1 IH0 K'],
  "bowie": ['B OW1 IY0'],
  "bowing": ['B OW1 IH0 NG', 'B AW1 IH0 NG'],
  "bowker": ['B OW1 K ER0'],
  "bowl": ['B OW1 L'],
  "bowland": ['B OW1 L AH0 N D'],
  "bowlby": ['B OW1 L B IY0'],
  "bowlds": ['B OW1 L D Z'],
  "bowled": ['B OW1 L D'],
  "bowlen": ['B OW1 L AH0 N'],
  "bowler": ['B OW1 L ER0'],
  "bowlers": ['B OW1 L ER0 Z'],
  "bowles": ['B OW1 L Z'],
  "bowley": ['B OW1 L IY0'],
  "bowlin": ['B OW1 L IH0 N'],
  "bowline": ['B OW1 L AY0 N'],
  "bowling": ['B OW1 L IH0 NG'],
  "bowling's": ['B OW1 L IH0 NG Z'],
  "bowls": ['B OW1 L Z'],
  "bowlus": ['B OW1 L AH0 S'],
  "bowman": ['B OW1 M AH0 N'],
  "bowman's": ['B OW1 M AH0 N Z'],
  "bowmen": ['B OW1 M AH0 N'],
  "bowmer": ['B OW1 M ER0'],
  "bown": ['B OW1 N'],
  "bownds": ['B OW1 N D Z'],
  "bowne": ['B OW1 N'],
  "bowring": ['B OW1 R IH0 NG'],
  "bowron": ['B OW1 R AH0 N', 'B OW1 R AA2 N'],
  "bows": ['B AW1 Z', 'B OW1 Z'],
  "bowse": ['B OW1 S'],
  "bowsed": ['B AW1 Z D'],
  "bowser": ['B OW1 Z ER0'],
  "bowses": ['B AW1 Z IH0 Z'],
  "bowsher": ['B OW1 SH ER0'],
  "bowsing": ['B AW1 Z IH0 NG'],
  "bowyer": ['B OW1 Y ER0'],
  "box": ['B AA1 K S'],
  "boxberger": ['B AA1 K S B ER0 G ER0'],
  "boxcar": ['B AA1 K S K AA2 R'],
  "boxcars": ['B AA1 K S K AA2 R Z'],
  "boxed": ['B AA1 K S T'],
  "boxell": ['B AA1 K S AH0 L'],
  "boxer": ['B AA1 K S ER0'],
  "boxer's": ['B AA1 K S ER0 Z'],
  "boxers": ['B AA1 K S ER0 Z'],
  "boxes": ['B AA1 K S AH0 Z', 'B AA1 K S IH0 Z'],
  "boxing": ['B AA1 K S IH0 NG'],
  "boxing's": ['B AA1 K S IH0 NG Z'],
  "boxley": ['B AA1 K S L IY0'],
  "boxwell": ['B AA1 K S W EH2 L'],
  "boxwood": ['B AA1 K S W UH2 D'],
  "boxx": ['B AA1 K S'],
  "boxy": ['B AA1 K S IY0'],
  "boy": ['B OY1'],
  "boy's": ['B OY1 Z'],
  "boy-ar-dee": ['B OY1 AA1 R D IY1'],
  "boy-scout": ['B OY1 S K AW0 T'],
  "boyack": ['B OY1 AH0 K'],
  "boyajian": ['B OY0 AE1 JH IY0 AH0 N'],
  "boyan": ['B OY1 AA0 N'],
  "boyar": ['B OW0 Y AA1 R'],
  "boyce": ['B OY1 S'],
  "boycott": ['B OY1 K AA2 T'],
  "boycotted": ['B OY1 K AA2 T IH0 D'],
  "boycotting": ['B OY1 K AA2 T IH0 NG'],
  "boycotts": ['B OY1 K AA2 T S'],
  "boyd": ['B OY1 D'],
  "boyd's": ['B OY1 D Z'],
  "boyd-crotty": ['B OY1 D K R AA1 T IY0'],
  "boyde": ['B OY1 D'],
  "boyden": ['B OY1 D AH0 N'],
  "boydston": ['B OY1 D S T AH0 N'],
  "boydstun": ['B OY1 D S T AH0 N'],
  "boye": ['B OY1'],
  "boyea": ['B OY1 IY0 AH0'],
  "boyer": ['B OY1 ER0'],
  "boyers": ['B OY1 ER0 Z'],
  "boyertown": ['B OY1 ER0 T AW0 N'],
  "boyes": ['B OY1 Z'],
  "boyett": ['B OY1 IH0 T'],
  "boyette": ['B OY1 EH1 T'],
  "boyfriend": ['B OY1 F R EH2 N D'],
  "boyfriend's": ['B OY1 F R EH2 N D Z'],
  "boyfriends": ['B OY1 F R EH2 N D Z'],
  "boyhood": ['B OY1 HH UH2 D'],
  "boyington": ['B OY1 IH0 NG T AH0 N'],
  "boyish": ['B OY1 IH0 SH'],
  "boykin": ['B OY1 K IH0 N'],
  "boykins": ['B OY1 K IH0 N Z'],
  "boyko": ['B OY1 K OW0'],
  "boylan": ['B OY1 L AH0 N'],
  "boyland": ['B OY1 L AH0 N D'],
  "boyle": ['B OY1 L'],
  "boylen": ['B OY1 L AH0 N'],
  "boyles": ['B OY1 L Z'],
  "boyleston": ['B OY1 L S T AH0 N'],
  "boyleston's": ['B OY1 L S T AH0 N Z'],
  "boylls": ['B OY1 L Z'],
  "boylston": ['B OY1 L S T AH0 N'],
  "boylston's": ['B OY1 L S T AH0 N Z'],
  "boyne": ['B OY1 N'],
  "boynton": ['B OY1 N T AH0 N'],
  "boys": ['B OY1 Z'],
  "boys'": ['B OY1 Z'],
  "boysel": ['B OY1 S AH0 L'],
  "boysen": ['B OY1 S AH0 N'],
  "boyson": ['B OY1 Z AH0 N'],
  "boyt": ['B OY1 T'],
  "boyte": ['B OY1 T'],
  "boyter": ['B OY1 T ER0'],
  "boytoy": ['B OY1 T OY2'],
  "boytoys": ['B OY1 T OY2 Z'],
  "boyum": ['B OY0 AH1 M'],
  "boyz": ['B OY1 Z'],
  "boza": ['B OW1 Z AH0'],
  "bozard": ['B AH0 Z AA1 R D'],
  "bozarth": ['B AA1 Z ER0 TH'],
  "boze": ['B OW1 Z'],
  "bozek": ['B OW1 Z EH0 K'],
  "bozell": ['B OW0 Z EH1 L'],
  "bozell's": ['B OW0 Z EH1 L Z'],
  "bozeman": ['B OW1 Z M AH0 N'],
  "bozian": ['B OW1 Z IY0 AH0 N'],
  "bozic": ['B AA1 Z IH0 K'],
  "bozich": ['B AA1 Z IH0 HH'],
  "bozman": ['B AA1 Z M AH0 N'],
  "bozo": ['B OW1 Z OW2'],
  "bozos": ['B OW1 Z OW2 Z'],
  "boztepe": ['B AA0 Z T EH1 P', 'B OW0 Z T EH1 P', 'B AA0 Z T EH1 P IY0', 'B OW0 Z T EH1 P IY0'],
  "bozza": ['B AA1 Z AH0'],
  "bozzi": ['B AA1 Z IY0'],
  "bozzo": ['B AA1 Z OW0'],
  "bp": ['B IY2 P IY1'],
  "bra": ['B R AA1'],
  "braaksma": ['B R AA1 K S M AH0'],
  "braasch": ['B R AA1 SH'],
  "braaten": ['B R AA1 EY0 T AH0 N'],
  "braatz": ['B R AA1 T S'],
  "brabant": ['B R AA1 B AH0 N T'],
  "brabec": ['B R AA1 B IH0 K'],
  "brabender": ['B R AE1 B EH0 N D ER0'],
  "brabham": ['B R AE1 B HH AH0 M'],
  "brabson": ['B R AE1 B S AH0 N'],
  "brac": ['B R AE1 K'],
  "bracamonte": ['B R AE2 K AH0 M AA1 N T IY0'],
  "bracamonte's": ['B R AE2 K AH0 M AA1 N T IY0 Z'],
  "bracamontes": ['B R AE2 K AH0 M AA1 N T IY0 Z'],
  "bracci": ['B R AA1 CH IY0'],
  "bracco": ['B R AE1 K OW0'],
  "brace": ['B R EY1 S'],
  "brace's": ['B R EY1 S IH0 Z'],
  "braced": ['B R EY1 S T'],
  "bracelet": ['B R EY1 S L AH0 T'],
  "bracelets": ['B R EY1 S L IH0 T S'],
  "bracer": ['B R EY1 S ER0'],
  "bracero": ['B R AA0 CH EH1 R OW0'],
  "braces": ['B R EY1 S AH0 Z', 'B R EY1 S IH0 Z'],
  "bracewell": ['B R EY1 S W EH2 L'],
  "bracey": ['B R EY1 S IY0'],
  "brach": ['B R AE1 CH'],
  "bracher": ['B R AE1 K ER0'],
  "bracher's": ['B R AE1 K ER0 Z'],
  "brachfeld": ['B R AA1 K F EH2 L D'],
  "brachia": ['B R EY1 K IY2 AH0', 'B R AE1 K IY2 AH0'],
  "brachii": ['B R EY1 K IY0 AY2', 'B R EY1 K IY0 IY2'],
  "brachiopod": ['B R EY1 K IY0 AH0 P AA2 D'],
  "brachiopods": ['B R EY1 K IY0 AH0 P AA2 D Z'],
  "bracht": ['B R AE1 K T'],
  "bracing": ['B R EY1 S IH0 NG'],
  "bracingly": ['B R EY1 S IH0 NG G L IY0'],
  "brack": ['B R AE1 K'],
  "brackbill": ['B R AE1 K B AH0 L'],
  "brackeen": ['B R AH0 K IY1 N'],
  "bracken": ['B R AE1 K AH0 N'],
  "brackenbury": ['B R AE1 K AH0 N B EH2 R IY0'],
  "brackens": ['B R AE1 K AH0 N Z'],
  "bracker": ['B R AE1 K ER0'],
  "bracket": ['B R AE1 K IH0 T'],
  "brackets": ['B R AE1 K AH0 T S', 'B R AE1 K IH0 T S'],
  "brackett": ['B R AE1 K IH0 T'],
  "brackin": ['B R AE1 K IH0 N'],
  "brackins": ['B R AE1 K IH0 N Z'],
  "brackish": ['B R AE1 K IH0 SH'],
  "brackman": ['B R AE1 K M AH0 N'],
  "bracknell": ['B R AE0 K N EH1 L'],
  "brackney": ['B R AE1 K N IY0'],
  "bracy": ['B R EY1 S IY0'],
  "brad": ['B R AE1 D'],
  "brad's": ['B R AE1 D Z'],
  "bradberry": ['B R AE1 D B EH2 R IY0'],
  "bradburn": ['B R AE1 D B ER2 N'],
  "bradbury": ['B R AE1 D B EH2 R IY0'],
  "bradco": ['B R AE1 D K OW0'],
  "braddock": ['B R AE1 D AH0 K'],
  "braddy": ['B R AE1 D IY0'],
  "bradeen": ['B R AH0 D IY1 N'],
  "brademas": ['B R AH0 D EY1 M AH0 S'],
  "braden": ['B R EY1 D AH0 N'],
  "bradenton": ['B R AE1 D AH0 N T AH0 N'],
  "brader": ['B R AE1 D ER0'],
  "bradfield": ['B R AE1 D F IY0 L D'],
  "bradford": ['B R AE1 D F ER0 D'],
  "bradford's": ['B R AE1 D F ER0 D Z'],
  "bradfords": ['B R AE1 D F ER0 D Z'],
  "bradham": ['B R AE1 D HH AH0 M'],
  "bradish": ['B R AE1 D IH0 SH'],
  "bradlee": ['B R AE1 D L IY2'],
  "bradlees": ['B R AE1 D L IY2 Z'],
  "bradley": ['B R AE1 D L IY0'],
  "bradley's": ['B R AE1 D L IY0 Z'],
  "bradleys": ['B R AE1 D L IY0 Z'],
  "bradner": ['B R AE1 D N ER0'],
  "bradney": ['B R AE1 D N IY0'],
  "bradshaw": ['B R AE1 D SH AO2'],
  "bradsher": ['B R AE1 D SH ER0'],
  "bradstreet": ['B R AE1 D S T R IY2 T'],
  "bradstreet's": ['B R AE1 D S T R IY2 T S'],
  "bradt": ['B R AE1 D T'],
  "bradtke": ['B R AE1 D K IY0'],
  "bradtmiller": ['B R AE1 T M IH2 L ER0'],
  "bradway": ['B R AE1 D W EY2'],
  "bradwell": ['B R AE1 D W EH2 L'],
  "brady": ['B R EY1 D IY0'],
  "brady's": ['B R EY1 D IY0 Z'],
  "bradycardia": ['B R AE2 D AH0 K AA1 R D IY0 AH0', 'B R AE2 D IH0 K AA1 R D IY0 AH0'],
  "bradykinin": ['B R AH0 D IH1 K IH0 N IH0 N'],
  "bradys": ['B R EY1 D IY0 Z'],
  "brae": ['B R EY1'],
  "braendstroem": ['B R AE1 N D S T R OW0 M'],
  "braer": ['B R EY1 R'],
  "braff": ['B R AE1 F'],
  "brafford": ['B R AE1 F ER0 D'],
  "brag": ['B R AE1 G'],
  "braga": ['B R AA1 G AH0'],
  "bragan": ['B R EY1 G AH0 N'],
  "braganza": ['B R AH0 G AA1 N Z AH0', 'B R AH0 G AE1 N Z AH0'],
  "bragdon": ['B R AE1 G D AH0 N'],
  "brager": ['B R EY1 G ER0'],
  "bragg": ['B R AE1 G'],
  "braggadocio": ['B R AE2 G AH0 D OW1 SH IY0 OW2'],
  "bragged": ['B R AE1 G D'],
  "bragger": ['B R AE1 G ER0'],
  "braggers": ['B R AE1 G ER0 Z'],
  "bragging": ['B R AE1 G IH0 NG'],
  "braggiotti": ['B R AE2 G IY0 AO1 T IY0'],
  "braggs": ['B R AE1 G Z'],
  "brags": ['B R AE1 G Z'],
  "braham": ['B R AE1 HH AH0 M'],
  "brahm": ['B R AA1 M'],
  "brahman": ['B R AA1 M AH0 N'],
  "brahmin": ['B R AA1 M IH0 N'],
  "brahms": ['B R AA1 M Z'],
  "brahms's": ['B R AA1 M Z IH0 Z'],
  "braid": ['B R EY1 D'],
  "braided": ['B R EY1 D IH0 D'],
  "braiding": ['B R EY1 D IH0 NG'],
  "braids": ['B R EY1 D Z'],
  "braidwood": ['B R EY1 D W UH2 D'],
  "brailey": ['B R EY1 L IY0'],
  "braille": ['B R EY1 L'],
  "brailles": ['B R EY1 L Z'],
  "brailsford": ['B R EY1 L S F ER0 D'],
  "brain": ['B R EY1 N'],
  "brain's": ['B R EY1 N Z'],
  "brainard": ['B R EY1 N ER0 D'],
  "brainchild": ['B R EY1 N CH AY2 L D'],
  "braindead": ['B R EY1 N D EH2 D'],
  "brained": ['B R EY1 N D'],
  "brainer": ['B R EY1 N ER0'],
  "brainerd": ['B R EY1 N ER0 D'],
  "brainless": ['B R EY1 N L IH0 S'],
  "brainpower": ['B R EY1 N P AW2 ER0'],
  "brains": ['B R EY1 N Z'],
  "brainstorm": ['B R EY1 N S T AO2 R M'],
  "brainstormed": ['B R EY1 N S T AO2 R M D'],
  "brainstorming": ['B R EY1 N S T AO2 R M IH0 NG'],
  "brainstorms": ['B R EY1 N S T AO2 R M Z'],
  "braintree": ['B R EY1 N T R IY2'],
  "brainwash": ['B R EY1 N W AA2 SH'],
  "brainwashed": ['B R EY1 N W AA2 SH T'],
  "brainwashes": ['B R EY1 N W AA2 SH AH0 Z'],
  "brainwashing": ['B R EY1 N W AA2 SH IH0 NG'],
  "brainy": ['B R EY1 N IY0'],
  "braise": ['B R EY1 Z'],
  "braised": ['B R EY1 Z D'],
  "braithwaite": ['B R EY1 TH W EY2 T'],
  "brajdas": ['B R AY1 D AH0 S'],
  "brajovic": ['B R AA1 JH OW0 V IH0 CH'],
  "brake": ['B R EY1 K'],
  "brakebill": ['B R EY1 K B IH2 L'],
  "braked": ['B R EY1 K T'],
  "brakefield": ['B R EY1 K F IY2 L D'],
  "brakeman": ['B R EY1 K M AH0 N'],
  "brakemen": ['B R EY1 K M EH0 N'],
  "braker": ['B R EY1 K ER0'],
  "brakes": ['B R EY1 K S'],
  "braking": ['B R EY1 K IH0 NG'],
  "brakke": ['B R AE1 K'],
  "braley": ['B R AE1 L IY0'],
  "bralley": ['B R AE1 L IY0'],
  "brallier": ['B R AE1 L IY0 ER0'],
  "bralorne": ['B R AE1 L AO0 R N'],
  "braly": ['B R AA1 L IY0'],
  "bram": ['B R AE1 M'],
  "bramah": ['B R AA1 M AH0'],
  "bramalea": ['B R AE2 M AH0 L IY1 AH0'],
  "bramalea's": ['B R AE1 M AH0 L IY2 Z'],
  "braman": ['B R EY1 M AH0 N'],
  "brambila": ['B R AA0 M B IY1 L AH0'],
  "bramble": ['B R AE1 M B AH0 L'],
  "brambles": ['B R AE1 M B AH0 L Z'],
  "bramblett": ['B R AE1 M B L IH0 T'],
  "brame": ['B R EY1 M'],
  "bramel": ['B R AE1 M AH0 L'],
  "bramer": ['B R EY1 M ER0'],
  "bramhall": ['B R AE1 M HH AH0 L'],
  "bramlage": ['B R AE1 M L IH0 JH'],
  "bramlet": ['B R AE1 M L IH0 T'],
  "bramlett": ['B R AE1 M L IH0 T'],
  "bramlette": ['B R AE2 M L EH1 T'],
  "bramley": ['B R AE1 M L IY0'],
  "brammeier": ['B R AE1 M AY0 ER0'],
  "brammer": ['B R AE1 M ER0'],
  "brampton": ['B R AE1 M P T AH0 N'],
  "brams": ['B R AE1 M Z'],
  "bramson": ['B R AE1 M S AH0 N'],
  "bramwell": ['B R AE1 M W EH2 L'],
  "bran": ['B R AE1 N'],
  "branagan": ['B R AE1 N AH0 G AE0 N'],
  "branagh": ['B R AE1 N AH0 G'],
  "branam": ['B R AE1 N AH0 M'],
  "branaman": ['B R AE1 N AH0 M AH0 N'],
  "branan": ['B R EY1 N AH0 N'],
  "branca": ['B R AE1 NG K AH0'],
  "brancaccio": ['B R AA0 N K AA1 CH IY0 OW0'],
  "brancato": ['B R AA0 N K AA1 T OW0'],
  "branch": ['B R AE1 N CH'],
  "branch's": ['B R AE1 N CH IH0 Z'],
  "branche": ['B R AE1 N CH'],
  "brancheau": ['B R AH0 N SH OW1'],
  "branched": ['B R AE1 N CH T'],
  "branches": ['B R AE1 N CH AH0 Z', 'B R AE1 N CH IH0 Z'],
  "branching": ['B R AE1 N CH IH0 NG'],
  "branchlet": ['B R AE1 N CH L AH0 T'],
  "branchlets": ['B R AE1 N CH L AH0 T S'],
  "branco": ['B R AE1 NG K OW0'],
  "brand": ['B R AE1 N D'],
  "brand's": ['B R AE1 N D Z'],
  "brand-new": ['B R AE1 N D N UW2'],
  "branda": ['B R AE1 N D AH0'],
  "brandau": ['B R AE1 N D AW0'],
  "brande": ['B R AE1 N D'],
  "brandeberry": ['B R AE1 N D B EH0 R IY0'],
  "branded": ['B R AE1 N D IH0 D'],
  "brandeis": ['B R AE1 N D AY0 S'],
  "brandel": ['B R AE1 N D AH0 L'],
  "branden": ['B R AE1 N D AH0 N'],
  "brandenberg": ['B R AE1 N D AH0 N B ER0 G'],
  "brandenberger": ['B R AE1 N D AH0 N B ER0 G ER0'],
  "brandenburg": ['B R AE1 N D AH0 N B ER0 G'],
  "brandenburger": ['B R AE1 N D AH0 N B ER0 G ER0'],
  "brandenstein": ['B R AE1 N D EH0 N S T AY2 N', 'B R AE1 N D EH0 N S T IY2 N'],
  "brander": ['B R AE1 N D ER0'],
  "brandes": ['B R AE1 N D Z'],
  "brandford": ['B R AE1 N D F ER0 D'],
  "brandhorst": ['B R AE1 N D HH AO0 R S T'],
  "brandi": ['B R AE1 N D IY0'],
  "branding": ['B R AE1 N D IH0 NG'],
  "brandis": ['B R AE1 N D IH0 S'],
  "brandish": ['B R AE1 N D IH0 SH'],
  "brandished": ['B R AE1 N D IH0 SH T'],
  "brandishes": ['B R AE1 N D IH0 SH IH0 Z'],
  "brandishing": ['B R AE1 N D IH0 SH IH0 NG'],
  "brandl": ['B R AE1 N D AH0 L'],
  "brandle": ['B R AE1 N D AH0 L'],
  "brandname": ['B R AE1 N D N EY2 M'],
  "brandner": ['B R AE1 N D N ER0'],
  "brandnew": ['B R AE1 N D N UW2'],
  "brando": ['B R AE1 N D OW0'],
  "brando's": ['B R AE1 N D OW0 Z'],
  "brandon": ['B R AE1 N D AH0 N'],
  "brandon's": ['B R AE1 N D AH0 N Z'],
  "brandow": ['B R AE1 N D AW2'],
  "brands": ['B R AE1 N D Z'],
  "brands'": ['B R AE1 N D Z'],
  "brands's": ['B R AE1 N D Z IH0 Z'],
  "brandstetter": ['B R AE1 N D S T IH0 T ER0'],
  "brandt": ['B R AE1 N T'],
  "brandtner": ['B R AE1 N T N ER0'],
  "brandvold": ['B R AE1 N D V OW2 L D'],
  "brandwein": ['B R AE1 N D W AY2 N'],
  "brandy": ['B R AE1 N D IY0'],
  "brandywine": ['B R AE1 N D IY0 W AY2 N'],
  "braner": ['B R EY1 N ER0'],
  "branford": ['B R AE1 N F ER0 D'],
  "branham": ['B R AE1 N HH AH0 M'],
  "brani": ['B R AE1 N IY0'],
  "braniff": ['B R AE1 N IH0 F'],
  "braniff's": ['B R AE1 N IH0 F S'],
  "branigan": ['B R AE1 N IH0 G AH0 N'],
  "branillo": ['B R AH0 N IH1 L OW0'],
  "branin": ['B R AE1 N IH0 N'],
  "branislov": ['B R AE1 N IH0 S L AA2 V'],
  "branitzky": ['B R AH0 N IH1 T S K IY2'],
  "brank": ['B R AE1 NG K'],
  "brank's": ['B R AE1 NG K S'],
  "branki": ['B R AE1 NG K IY0'],
  "branko": ['B R AE1 NG K OW0'],
  "branks": ['B R AE1 NG K S'],
  "brann": ['B R AE1 N'],
  "branna": ['B R AE1 N AH0'],
  "brannam": ['B R AE1 N AH0 M'],
  "brannan": ['B R AE1 N AH0 N'],
  "brannen": ['B R AE1 N AH0 N'],
  "branner": ['B R AE1 N ER0'],
  "brannick": ['B R AE1 N IH0 K'],
  "brannigan": ['B R AE1 N IH0 G AH0 N'],
  "branning": ['B R AE1 N IH0 NG'],
  "brannock": ['B R AE1 N AH0 K'],
  "brannon": ['B R AE1 N AH0 N'],
  "brannum": ['B R AE1 N AH0 M'],
  "branon": ['B R AE1 N AH0 N'],
  "branscom": ['B R AE1 N S K AH0 M'],
  "branscomb": ['B R AE1 N S K AH0 M'],
  "branscome": ['B R AE1 N Z K AH2 M'],
  "branscum": ['B R AE1 N S K AH0 M'],
  "bransfield": ['B R AE1 N Z F IY2 L D'],
  "bransford": ['B R AE1 N S F ER0 D'],
  "bransom": ['B R AE1 N S AH0 M'],
  "branson": ['B R AE1 N S AH0 N'],
  "branson's": ['B R AE1 N S AH2 N Z'],
  "branstad": ['B R AE1 N S T AE2 D'],
  "branstetter": ['B R AE1 N S T IH0 T ER0'],
  "brant": ['B R AE1 N T'],
  "brantley": ['B R AE1 N T L IY0'],
  "brantly": ['B R AE1 N T L IY0'],
  "brantner": ['B R AE1 N T N ER0'],
  "branton": ['B R AE1 N T AH0 N'],
  "branum": ['B R AE1 N AH0 M'],
  "branyon": ['B R AE1 N Y AH0 N'],
  "branz": ['B R AE1 N Z'],
  "braque": ['B R AE1 K'],
  "bras": ['B R AE1 S', 'B R AA1 S'],
  "brascade": ['B R AH0 S K EY1 D'],
  "brascan": ['B R AE1 S K AH0 N'],
  "brasch": ['B R AE1 SH'],
  "brasco": ['B R AE1 S K OW0'],
  "brase": ['B R EY1 Z'],
  "brasel": ['B R AE1 S AH0 L'],
  "braselton": ['B R AH0 S EH1 L T AH0 N'],
  "brasfield": ['B R AE1 S F IY0 L D'],
  "brash": ['B R AE1 SH'],
  "brashear": ['B R AE1 SH IY0 R'],
  "brashears": ['B R AE1 SH IY0 R Z'],
  "brasher": ['B R AE1 SH ER0'],
  "brashers": ['B R AE1 SH ER0 Z'],
  "brashier": ['B R AE1 SH IY0 ER0'],
  "brashness": ['B R AE1 SH N AH0 S'],
  "brasil": ['B R AE1 S AH0 L', 'B R AH0 S IY1 L'],
  "brasilia": ['B R AH0 Z IH1 L Y AH0', 'B R AH0 S IH1 L Y AH0'],
  "brasington": ['B R AE1 S IH0 NG T AH0 N'],
  "brasow": ['B R AE1 S OW0', 'B R AE1 Z OW0'],
  "brass": ['B R AE1 S'],
  "brassard": ['B R AE1 S ER0 D'],
  "brasseaux": ['B R AH0 S OW1'],
  "brassell": ['B R AE1 S AH0 L'],
  "brasserie": ['B R AE1 S ER0 IY0'],
  "brasses": ['B R AE1 S IH0 Z'],
  "brasseur": ['B R AE1 S ER2'],
  "brasseux": ['B R AA1 S UH2'],
  "brassfield": ['B R AE1 S F IY2 L D'],
  "brasso": ['B R AE1 S OW0'],
  "brasso's": ['B R AE1 S OW0 Z'],
  "brassy": ['B R AE1 S IY0'],
  "braswell": ['B R AE1 S W EH0 L'],
  "brat": ['B R AE1 T'],
  "bratcher": ['B R AE1 CH ER0'],
  "braton": ['B R AE1 T IH0 N'],
  "brats": ['B R AE1 T S'],
  "bratsch": ['B R AE1 CH'],
  "bratt": ['B R AE1 T'],
  "brattain": ['B R AH0 T EY1 N'],
  "brattaslava": ['B R AA2 T AH0 S L AA1 V AH0'],
  "brattaslava's": ['B R AA2 T AH0 S L AA1 V AH0 Z'],
  "bratten": ['B R AE1 T AH0 N'],
  "brattin": ['B R AE1 T IH0 N'],
  "brattle": ['B R AE1 T AH0 L'],
  "bratton": ['B R AE1 T AH0 N'],
  "bratwurst": ['B R AE1 T W ER0 S T'],
  "bratz": ['B R AE1 T S'],
  "brau": ['B R AW1'],
  "brauch": ['B R AO1 CH'],
  "braucher": ['B R AO1 CH ER0'],
  "brauchli": ['B R AO1 CH L IY0'],
  "braud": ['B R AO1 D'],
  "braude": ['B R AO1 D'],
  "brauer": ['B R AW1 ER0'],
  "braughton": ['B R AO1 T AH0 N'],
  "brauhau": ['B R AA1 HH AA2'],
  "brault": ['B R AO1 L T'],
  "braun": ['B R AO1 N'],
  "braun's": ['B R AO1 N Z'],
  "braund": ['B R AO1 N D'],
  "braune": ['B R AO1 N'],
  "brauner": ['B R AO1 N ER0'],
  "brauns": ['B R AO1 N Z'],
  "braunschweig": ['B R AW1 N SH W AY0 G'],
  "braunstein": ['B R AO1 N S T IY2 N', 'B R AO1 N S T AY2 N'],
  "braunwald": ['B R AO1 N W AO2 L D'],
  "brause": ['B R AO1 Z'],
  "brautigam": ['B R OW1 T IH0 G AH0 M'],
  "brautigams": ['B R AO1 T IH0 G AE0 M Z'],
  "bravado": ['B R AH0 V AA1 D OW0'],
  "brave": ['B R EY1 V'],
  "braved": ['B R EY1 V D'],
  "braveheart": ['B R EY1 V HH AA0 R T'],
  "bravely": ['B R EY1 V L IY0'],
  "braver": ['B R EY1 V ER0'],
  "braverman": ['B R EY1 V ER0 M AH0 N'],
  "bravery": ['B R EY1 V ER0 IY0'],
  "braves": ['B R EY1 V Z'],
  "braves'": ['B R EY1 V Z'],
  "bravest": ['B R EY1 V AH0 S T'],
  "braving": ['B R EY1 V IH0 NG'],
  "bravo": ['B R AA1 V OW0'],
  "bravura": ['B R AH0 V Y UH1 R AH0'],
  "brawer": ['B R AO1 ER0'],
  "brawl": ['B R AO1 L'],
  "brawley": ['B R AO1 L IY0'],
  "brawling": ['B R AO1 L IH0 NG'],
  "brawls": ['B R AO1 L Z'],
  "brawn": ['B R AO1 N'],
  "brawner": ['B R AO1 N ER0'],
  "brawny": ['B R AO1 N IY0'],
  "braxton": ['B R AE1 K S T AH0 N'],
  "bray": ['B R EY1'],
  "brayboy": ['B R EY1 B OY2'],
  "brayer": ['B R EY1 ER0'],
  "brayfield": ['B R EY1 F IY2 L D'],
  "brayman": ['B R EY1 M AH0 N'],
  "brayton": ['B R EY1 T AH0 N'],
  "braz": ['B R AE1 Z'],
  "brazda": ['B R AE1 Z D AH0'],
  "brazeal": ['B R AH0 Z IY1 L'],
  "brazeau": ['B R AH0 Z OW1'],
  "brazee": ['B R AE1 Z IY0'],
  "brazel": ['B R AE1 Z AH0 L'],
  "brazell": ['B R AE1 Z AH0 L'],
  "brazelton": ['B R AH0 Z EH1 L T AH0 N'],
  "brazen": ['B R EY1 Z AH0 N'],
  "brazenly": ['B R EY1 Z AH0 N L IY0'],
  "brazenness": ['B R EY1 Z AH0 N AH0 S'],
  "braziel": ['B R AH0 Z IY1 L'],
  "brazier": ['B R EY1 Z IY0 ER0'],
  "braziers": ['B R EY1 ZH ER0 Z'],
  "brazil": ['B R AH0 Z IH1 L'],
  "brazil's": ['B R AH0 Z IH1 L Z'],
  "brazile": ['B R AA1 Z AY0 L'],
  "brazilian": ['B R AH0 Z IH1 L Y AH0 N'],
  "brazilians": ['B R AH0 Z IH1 L Y AH0 N Z'],
  "brazill": ['B R AE1 Z AH0 L'],
  "brazos": ['B R AA1 Z OW0 S'],
  "brazzaville": ['B R AE1 Z AH0 V IH0 L'],
  "brazzaville's": ['B R AE1 Z AH0 V IH0 L Z'],
  "brazzel": ['B R AE1 Z AH0 L'],
  "brazzell": ['B R AE1 Z AH0 L'],
  "brca": ['B R IH1 K AH0'],
  "brcko": ['B ER1 CH K OW0'],
  "brcko's": ['B ER1 CH K OW0 Z'],
  "brea": ['B R IY1'],
  "breach": ['B R IY1 CH'],
  "breached": ['B R IY1 CH T'],
  "breaches": ['B R IY1 CH IH0 Z'],
  "breaching": ['B R IY1 CH IH0 NG'],
  "bread": ['B R EH1 D'],
  "breadbasket": ['B R EH1 D B AE2 S K AH0 T'],
  "breadbox": ['B R EH1 D B AA2 K S'],
  "breadcrumb": ['B R EH1 D K R AH1 M'],
  "breaded": ['B R EH1 D IH0 D'],
  "breadfruit": ['B R EH1 D F R UW2 T'],
  "breading": ['B R EH1 D IH0 NG'],
  "breads": ['B R EH1 D Z'],
  "breadth": ['B R EH1 D TH'],
  "breadwinner": ['B R EH1 D W IH2 N ER0'],
  "breadwinners": ['B R EH1 D W IH2 N ER0 Z'],
  "bready": ['B R EH1 D IY0'],
  "break": ['B R EY1 K'],
  "break-in": ['B R EY1 K IH2 N'],
  "breakable": ['B R EY1 K AH0 B AH0 L'],
  "breakage": ['B R EY1 K IH0 JH'],
  "breakaway": ['B R EY1 K AH0 W EY2'],
  "breakdown": ['B R EY1 K D AW2 N'],
  "breakdowns": ['B R EY1 K D AW2 N Z'],
  "breaker": ['B R EY1 K ER0'],
  "breakers": ['B R EY1 K ER0 Z'],
  "breakeven": ['B R EY1 K IY2 V AH0 N'],
  "breakey": ['B R EY1 K IY0'],
  "breakfast": ['B R EH1 K F AH0 S T'],
  "breakfasts": ['B R EH1 K F AH0 S T S'],
  "breakfield": ['B R EY1 K F IY2 L D'],
  "breaking": ['B R EY1 K IH0 NG'],
  "breakmate": ['B R EY1 K M EY2 T'],
  "breakneck": ['B R EY1 K N EH2 K'],
  "breakout": ['B R EY1 K AW2 T'],
  "breakouts": ['B R EY1 K AW2 T S'],
  "breaks": ['B R EY1 K S'],
  "breakthrough": ['B R EY1 K TH R UW2'],
  "breakthroughs": ['B R EY1 K TH R UW2 Z'],
  "breakup": ['B R EY1 K AH2 P'],
  "breakups": ['B R EY1 K AH0 P S'],
  "breakwater": ['B R EY1 K W AO2 T ER0'],
  "breaky": ['B R EY1 K IY0'],
  "bream": ['B R IY1 M'],
  "brean": ['B R IY1 N'],
  "brearley": ['B R ER1 L IY0'],
  "breast": ['B R EH1 S T'],
  "breasted": ['B R EH1 S T AH0 D', 'B R EH1 S T IH0 D'],
  "breastfed": ['B R EH1 S T F EH2 D'],
  "breastfeed": ['B R EH1 S T F IY0 D'],
  "breastfeeding": ['B R EH1 S T F IY0 D IH0 NG'],
  "breastfeeding's": ['B R EH1 S T F IY0 D IH0 NG Z'],
  "breastfeeds": ['B R EH1 S T F IY0 D Z'],
  "breasting": ['B R EH1 S T IH0 NG'],
  "breastplate": ['B R EH1 S T P L EY2 T'],
  "breasts": ['B R EH1 S T S'],
  "breath": ['B R EH1 TH'],
  "breathe": ['B R IY1 DH'],
  "breathed": ['B R IY1 DH D'],
  "breather": ['B R IY1 DH ER0'],
  "breathes": ['B R IY1 DH Z'],
  "breathing": ['B R IY1 DH IH0 NG'],
  "breathless": ['B R EH1 TH L AH0 S'],
  "breathlessly": ['B R EH1 TH L AH0 S L IY0'],
  "breaths": ['B R EH1 TH S'],
  "breathtaking": ['B R EH1 TH T EY2 K IH0 NG'],
  "breathtakingly": ['B R EH1 TH T EY2 K IH0 NG L IY0'],
  "breathy": ['B R EH1 TH IY0'],
  "breau": ['B R OW1'],
  "breault": ['B R OW1'],
  "breaux": ['B R OW1', 'B R UW1'],
  "breazeale": ['B R IY1 Z IY2 L'],
  "brebach": ['B R EH1 B AH0 K'],
  "brechbill": ['B R EH1 K B AH0 L'],
  "brecheen": ['B R EH1 K IY0 N'],
  "brecheisen": ['B R EH1 K AY0 S AH0 N'],
  "brecher": ['B R EH1 K ER0'],
  "brecht": ['B R EH1 K T'],
  "brechtel": ['B R EH1 K T AH0 L'],
  "breck": ['B R EH1 K'],
  "breckenridge": ['B R EH1 K AH0 N R IH2 JH'],
  "brecker": ['B R EH1 K ER0'],
  "breco": ['B R EH1 K OW0'],
  "bred": ['B R EH1 D'],
  "breda": ['B R EY1 D AH0'],
  "brede": ['B R IY1 D'],
  "breden": ['B R IY1 D AH0 N'],
  "bredeson": ['B R EH1 D IH0 S AH0 N'],
  "bree": ['B R IY1'],
  "breece": ['B R IY1 S'],
  "breech": ['B R IY1 CH'],
  "breeches": ['B R IY1 CH IH0 Z'],
  "breeching": ['B R IY1 CH IH0 NG'],
  "breed": ['B R IY1 D'],
  "breed's": ['B R IY1 D Z'],
  "breeden": ['B R IY1 D AH0 N'],
  "breeden's": ['B R IY1 D AH0 N Z'],
  "breeder": ['B R IY1 D ER0'],
  "breeders": ['B R IY1 D ER0 Z'],
  "breeders'": ['B R IY1 D ER0 Z'],
  "breeding": ['B R IY1 D IH0 NG'],
  "breedlove": ['B R IY1 D L AH2 V'],
  "breeds": ['B R IY1 D Z'],
  "breeland": ['B R IY1 L AH0 N D'],
  "breen": ['B R IY1 N'],
  "brees": ['B R IY1 Z'],
  "breese": ['B R IY1 Z'],
  "breeze": ['B R IY1 Z'],
  "breezed": ['B R IY1 Z D'],
  "breezes": ['B R IY1 Z IH0 Z'],
  "breezeway": ['B R IY1 Z W EY2'],
  "breezy": ['B R IY1 Z IY0'],
  "brege": ['B R IY1 JH'],
  "breger": ['B R IY1 G ER0'],
  "breglio": ['B R EH1 G L IY0 OW0'],
  "bregman": ['B R EH1 G M AH0 N'],
  "breguet": ['B R IY1 G AH0 T'],
  "brehm": ['B R EH1 M'],
  "brehmer": ['B R EH1 M ER0'],
  "breidenbach": ['B R AY1 D IH0 N B AA0 K'],
  "breidenstein": ['B R AY1 D AH0 N S T AY0 N', 'B R AY1 D AH0 N S T IY0 N'],
  "breier": ['B R AY1 ER0'],
  "breighner": ['B R EY1 N ER0'],
  "breighton": ['B R AY1 T AH0 N'],
  "breiner": ['B R AY1 N ER0'],
  "breining": ['B R AY1 N IH0 NG'],
  "breininger": ['B R AY1 N IH0 NG ER0'],
  "breisch": ['B R AY1 SH'],
  "breit": ['B R IY1 T'],
  "breitbach": ['B R AY1 T B AA2 K'],
  "breitbarth": ['B R AY1 T B AA2 R TH'],
  "breitenbach": ['B R AY1 T IH0 N B AA0 K'],
  "breitenstein": ['B R AY1 T AH0 N S T AY0 N', 'B R AY1 T AH0 N S T IY0 N'],
  "breithaupt": ['B R AY1 TH AW0 P T'],
  "breitkreutz": ['B R AY1 T K R OY2 T S'],
  "breitling": ['B R AY1 T AH0 L IH0 NG', 'B R AY1 T L IH0 NG'],
  "breitman": ['B R AY1 T M AH0 N'],
  "breitschwerdt": ['B R AY1 CH W ER0 T'],
  "breitweiser": ['B R AY1 T W AY2 S ER0'],
  "breitzman": ['B R AY1 T S M AH0 N'],
  "brekke": ['B R EH1 K'],
  "breland": ['B R EH1 L AH0 N D'],
  "brelsford": ['B R EH1 L S F ER0 D'],
  "brem": ['B R EH1 M'],
  "bremen": ['B R EH1 M AH0 N'],
  "bremer": ['B R IY1 M ER0'],
  "bremmer": ['B R EH1 M ER0'],
  "bremner": ['B R EH1 M N ER0'],
  "brems": ['B R EH1 M Z'],
  "bren": ['B R EH1 N'],
  "brenda": ['B R EH1 N D AH0'],
  "brenda's": ['B R EH1 N D AH0 Z'],
  "brendan": ['B R EH1 N D AH0 N'],
  "brendel": ['B R EH1 N D AH0 L'],
  "brenden": ['B R EH1 N D AH0 N'],
  "brender": ['B R EH1 N D ER0'],
  "brendle": ['B R EH1 N D AH0 L'],
  "brendlinger": ['B R EH1 N D AH0 L IH0 NG ER0', 'B R EH1 N D L IH0 NG ER0'],
  "brendon": ['B R EH1 N D AH0 N'],
  "brendor": ['B R EH1 N D ER0'],
  "brendsel": ['B R EH1 N D S AH0 L'],
  "breneman": ['B R IY1 N M AH0 N'],
  "brener": ['B R IY1 N ER0'],
  "brenes": ['B R IY1 N Z'],
  "brengle": ['B R IH1 NG AH0 L'],
  "brenizer": ['B R EH1 N AY0 Z ER0'],
  "brenn": ['B R EH1 N'],
  "brenna": ['B R EH1 N AH0'],
  "brennan": ['B R EH1 N AH0 N'],
  "brennan's": ['B R EH1 N AH0 N Z'],
  "brennans": ['B R EH1 N AH0 N Z'],
  "brennecke": ['B R EH1 N IH0 K'],
  "brenneke": ['B R EH1 N AH0 K IY0'],
  "brenneman": ['B R EH1 N M AH0 N'],
  "brennen": ['B R EH1 N AH0 N'],
  "brenner": ['B R EH1 N ER0'],
  "brenning": ['B R EH1 N IH0 NG'],
  "brensinger": ['B R EH1 N S IH0 NG ER0'],
  "brent": ['B R EH1 N T'],
  "brentano": ['B R EH2 N T AA1 N OW0'],
  "brentanos": ['B R EH2 N T AA1 N OW0 Z'],
  "brentlinger": ['B R EH1 N T AH0 L IH0 NG ER0', 'B R EH1 N T L IH0 NG ER0'],
  "brenton": ['B R EH1 N T AH0 N'],
  "brents": ['B R EH1 N T S'],
  "brentwood": ['B R EH1 N T W UH2 D'],
  "breon": ['B R IY1 AH0 N'],
  "bresca": ['B R EH1 S K AH0'],
  "brescia": ['B R EH1 S CH AH0'],
  "bresee": ['B R IH0 S IY1'],
  "bresette": ['B R IH0 S EH1 T'],
  "breshears": ['B R EH1 SH IY0 R Z'],
  "breslaw": ['B R EH1 S L AA0'],
  "bresler": ['B R EH1 S L ER0'],
  "breslin": ['B R EH1 S L IH0 N'],
  "breslow": ['B R EH1 S L OW0'],
  "bresnahan": ['B R EH1 S N AH0 HH AE0 N'],
  "bresnan": ['B R EH1 S N AH0 N'],
  "bresnick": ['B R EH1 S N IH0 K'],
  "bress": ['B R EH1 S'],
  "bresse": ['B R EH1 S'],
  "bresseau": ['B R EH1 S OW0'],
  "bresser": ['B R EH1 S ER0'],
  "bresser's": ['B R EH1 S ER0 Z'],
  "bressette": ['B R EH2 S EH1 T'],
  "bressler": ['B R EH1 S L ER0'],
  "bressman": ['B R EH1 S M AH0 N'],
  "bresson": ['B R EH1 S AH0 N'],
  "brest": ['B R EH1 S T'],
  "brester": ['B R EH1 S T ER0'],
  "bret": ['B R EH1 T'],
  "breth": ['B R EH1 TH'],
  "brethauer": ['B R EH1 TH AW0 ER0'],
  "brethen": ['B R IY1 TH AH0 N'],
  "brethren": ['B R EH1 DH R AH0 N'],
  "breton": ['B R EH1 T AH0 N'],
  "bretschneider": ['B R EH1 CH N AY0 D ER0'],
  "brett": ['B R EH1 T'],
  "bretthauer": ['B R EH1 TH AW0 ER0'],
  "bretton": ['B R EH1 T AH0 N'],
  "bretts": ['B R EH1 T S'],
  "brettschneider": ['B R EH1 CH N AY2 D ER0', 'B R EH1 T S N AY2 D ER0'],
  "bretz": ['B R EH1 T S'],
  "breuer": ['B R UW1 ER0'],
  "breunig": ['B R UW1 N IH0 G'],
  "breuninger": ['B R UW1 N IH0 NG ER0'],
  "brevard": ['B R EH1 V ER0 D'],
  "brevets": ['B R AH0 V EH1 T S'],
  "brevig": ['B R EH1 V IH0 G'],
  "brevik": ['B R EH1 V IH0 K'],
  "brevity": ['B R EH1 V AH0 T IY0'],
  "brew": ['B R UW1'],
  "brewbaker": ['B R UW1 B EY2 K ER0'],
  "brewed": ['B R UW1 D'],
  "brewer": ['B R UW1 ER0'],
  "brewer's": ['B R UW1 ER0 Z'],
  "breweries": ['B R UW1 ER0 IY0 Z', 'B R UW1 R IY0 Z'],
  "brewers": ['B R UW1 ER0 Z'],
  "brewers'": ['B R UW1 ER0 Z'],
  "brewery": ['B R UW1 ER0 IY0'],
  "brewing": ['B R UW1 IH0 NG'],
  "brewington": ['B R UW1 IH0 NG T AH0 N'],
  "brewry": ['B R UW1 R IY0'],
  "brews": ['B R UW1 Z'],
  "brewster": ['B R UW1 S T ER0'],
  "brewton": ['B R UW1 T AH0 N'],
  "brey": ['B R EY1'],
  "breyer": ['B R EY1 ER0'],
  "breyer's": ['B R EY1 ER0 Z'],
  "breyfogle": ['B R EY1 F OW2 G AH0 L'],
  "breza": ['B R EH1 Z AH0'],
  "brezhnev": ['B R EH1 Z N AH0 V', 'B R EH1 Z N EH0 F'],
  "brezhnev's": ['B R EH1 Z N AH0 V Z', 'B R EH1 Z N EH0 F S'],
  "brezina": ['B R EH0 Z IY1 N AH0'],
  "brezinski": ['B R IH0 Z IH1 N S K IY0'],
  "bria": ['B R IY1 AH0'],
  "brian": ['B R AY1 AH0 N'],
  "brian's": ['B R AY1 AH0 N Z'],
  "briana": ['B R IY0 AE1 N AH0', 'B R IY0 AA1 N AH0'],
  "briana's": ['B R IY0 AE1 N AH0 Z', 'B R IY0 AA1 N AH0 Z'],
  "briancon": ['B R AY1 AH0 N S AH0 N'],
  "briand": ['B R AY1 AH0 N D'],
  "brianna": ['B R IY0 AE1 N AH0'],
  "brianna's": ['B R IY0 AE1 N AH0 Z'],
  "briant": ['B R AY1 AH0 N T'],
  "briar": ['B R AY1 ER0'],
  "briarcliff": ['B R AY1 R K L IH2 F'],
  "bribe": ['B R AY1 B'],
  "bribed": ['B R AY1 B D'],
  "bribery": ['B R AY1 B ER0 IY0'],
  "bribes": ['B R AY1 B Z'],
  "bribing": ['B R AY1 B IH0 NG'],
  "bric": ['B R IH1 K'],
  "briccetti": ['B R IH0 CH EH1 T IY0'],
  "bricco": ['B R IH1 K OW0'],
  "brice": ['B R AY1 S'],
  "briceno": ['B R IY0 CH EH1 N OW0'],
  "brick": ['B R IH1 K'],
  "brickbat": ['B R IH1 K B AE2 T'],
  "brickbats": ['B R IH1 K B AE2 T S'],
  "brickel": ['B R IH1 K AH0 L'],
  "brickell": ['B R IH1 K AH0 L'],
  "bricker": ['B R IH1 K ER0'],
  "brickey": ['B R IH1 K IY0'],
  "brickhouse": ['B R IH1 K HH AW2 S'],
  "bricklayer": ['B R IH1 K L EY2 ER0'],
  "bricklayers": ['B R IH1 K L EY2 ER0 Z'],
  "brickle": ['B R IH1 K AH0 L'],
  "brickler": ['B R IH1 K L ER0'],
  "brickley": ['B R IH1 K L IY0'],
  "bricklin": ['B R IH1 K L IH0 N'],
  "bricklin's": ['B R IH1 K L IH0 N Z'],
  "brickman": ['B R IH1 K M AH0 N'],
  "brickner": ['B R IH1 K N ER0'],
  "bricks": ['B R IH1 K S'],
  "brickyard": ['B R IH1 K Y AA2 R D'],
  "bricom": ['B R IH1 K AH0 M'],
  "bridal": ['B R AY1 D AH0 L'],
  "bride": ['B R AY1 D'],
  "bride's": ['B R AY1 D Z'],
  "brideau": ['B R IH0 D OW1'],
  "bridegroom": ['B R AY1 D G R UW2 M'],
  "bridegroom's": ['B R AY1 D G R UW2 M Z'],
  "briden": ['B R AY1 D AH0 N'],
  "bridenbaugh": ['B R IH1 D IH0 N B AW0'],
  "bridenstine": ['B R IH1 D IH0 N S T IY0 N'],
  "brides": ['B R AY1 D Z'],
  "bridesburg": ['B R AY1 D Z B AH0 R G'],
  "bridesmaid": ['B R AY1 D Z M EY2 D'],
  "bridesmaid's": ['B R AY1 D Z M EY2 D Z'],
  "bridesmaids": ['B R AY1 D Z M EY2 D Z'],
  "bridesmaids'": ['B R AY1 D Z M EY2 D Z'],
  "bridge": ['B R IH1 JH'],
  "bridge's": ['B R IH1 JH IH0 Z'],
  "bridged": ['B R IH1 JH D'],
  "bridgeford": ['B R IH1 JH F AO0 R D'],
  "bridgeforth": ['B R IH1 JH F AO2 R TH'],
  "bridgehead": ['B R IH1 JH HH EH2 D'],
  "bridgeman": ['B R IH1 JH M AH0 N'],
  "bridgeport": ['B R IH1 JH P AO2 R T'],
  "bridger": ['B R IH1 JH ER0'],
  "bridgers": ['B R IH1 JH ER0 Z'],
  "bridges": ['B R IH1 JH AH0 Z', 'B R IH1 JH IH0 Z'],
  "bridgestone": ['B R IH1 JH S T OW2 N'],
  "bridgestone's": ['B R IH1 JH S T OW2 N Z'],
  "bridget": ['B R IH1 JH AH0 T', 'B R IH1 JH IH0 T'],
  "bridget's": ['B R IH1 JH AH0 T S'],
  "bridgeton": ['B R IH1 JH T AH0 N'],
  "bridgetown": ['B R IH1 JH T AW2 N'],
  "bridgett": ['B R IH1 JH IH0 T'],
  "bridgette": ['B R IH1 JH IH0 T', 'B R IH2 JH IY1 T'],
  "bridgewater": ['B R IH1 JH W AO2 T ER0'],
  "bridgham": ['B R IH1 JH HH AH0 M'],
  "bridging": ['B R IH1 JH IH0 NG'],
  "bridgman": ['B R IH1 JH M AH0 N'],
  "bridie": ['B R IH1 D IY0'],
  "bridle": ['B R AY1 D AH0 L'],
  "bridled": ['B R AY1 D AH0 L D'],
  "bridwell": ['B R IH1 D W EH2 L'],
  "brie": ['B R IY1'],
  "brieant": ['B R IY1 AH0 N T'],
  "brief": ['B R IY1 F'],
  "briefcase": ['B R IY1 F K EY2 S'],
  "briefcases": ['B R IY1 F K EY2 S IH0 Z'],
  "briefed": ['B R IY1 F T'],
  "briefer": ['B R IY1 F ER0'],
  "briefers": ['B R IY1 F ER0 Z'],
  "briefest": ['B R IY1 F AH0 S T'],
  "briefing": ['B R IY1 F IH0 NG'],
  "briefings": ['B R IY1 F IH0 NG Z'],
  "briefly": ['B R IY1 F L IY0'],
  "briefs": ['B R IY1 F S'],
  "briegel": ['B R IY1 G AH0 L'],
  "brieger": ['B R IY1 G ER0'],
  "briel": ['B R IY1 L'],
  "brien": ['B R AY1 IH0 N'],
  "brienza": ['B R IY1 N Z AH0'],
  "brier": ['B R AY1 ER0'],
  "briere": ['B R IH1 R'],
  "brierley": ['B R AY1 ER0 L IY0'],
  "brierly": ['B R AY1 ER0 L IY0'],
  "briese": ['B R IY1 Z'],
  "brietzke": ['B R IY1 T S K IY0'],
  "brig": ['B R IH1 G'],
  "brigade": ['B R AH0 G EY1 D', 'B R IH0 G EY1 D'],
  "brigadeer": ['B R IH2 G AH0 D IH1 R'],
  "brigades": ['B R IH0 G EY1 D Z'],
  "brigadier": ['B R IH2 G AH0 D IH1 R'],
  "brigance": ['B R IH1 G AH0 N S'],
  "brigandi": ['B R IH0 G AE1 N D IY0'],
  "brigante": ['B R IY0 G AA1 N T IY0'],
  "briganti": ['B R IH0 G AE1 N T IY0'],
  "brigantine": ['B R IH2 G AH0 N T IY1 N'],
  "briggs": ['B R IH1 G Z'],
  "briggstone": ['B R IH1 G S T OW0 N'],
  "brigham": ['B R IH1 G AH0 M'],
  "brigham's": ['B R IH1 G AH0 M Z'],
  "brighams": ['B R IH1 G AH0 M Z'],
  "bright": ['B R AY1 T'],
  "bright's": ['B R AY1 T S'],
  "brightbill": ['B R AY1 T B IH2 L'],
  "brighten": ['B R AY1 T AH0 N'],
  "brightened": ['B R AY1 T AH0 N D'],
  "brightening": ['B R AY1 T AH0 N IH0 NG', 'B R AY1 T N IH0 NG'],
  "brightens": ['B R AY1 T AH0 N Z'],
  "brighter": ['B R AY1 T ER0'],
  "brightest": ['B R AY1 T AH0 S T'],
  "brightly": ['B R AY1 T L IY0'],
  "brightman": ['B R AY1 T M AH0 N'],
  "brightness": ['B R AY1 T N AH0 S'],
  "brighton": ['B R AY1 T AH0 N'],
  "brightwell": ['B R AY1 T W EH2 L'],
  "brigitte": ['B R IH1 JH IH0 T'],
  "brigman": ['B R IH1 G M AH0 N'],
  "brignac": ['B R IH1 G N AH0 K'],
  "brigner": ['B R AY1 G N ER0'],
  "brigode": ['B R IH0 G OW1 D AH0'],
  "briguglio": ['B R IH0 G AH1 G L IY0 OW0'],
  "briles": ['B R AY1 L Z'],
  "briley": ['B R IH1 L IY0'],
  "brill": ['B R IH1 L'],
  "brill's": ['B R IH1 L Z'],
  "brillhart": ['B R IH1 L HH AA2 R T'],
  "brilliance": ['B R IH1 L Y AH0 N S'],
  "brilliant": ['B R IH1 L Y AH0 N T'],
  "brilliantly": ['B R IH1 L Y AH0 N T L IY0'],
  "brillstein": ['B R IH1 L S T IY2 N', 'B R IH1 L S T AY2 N'],
  "brim": ['B R IH1 M'],
  "brimberry": ['B R IH1 M B EH2 R IY0'],
  "brimelow": ['B R IH1 M AH0 L OW0'],
  "brimer": ['B R AY1 M ER0'],
  "brimhall": ['B R IH1 M HH AO2 L'],
  "brimm": ['B R IH1 M'],
  "brimmed": ['B R IH1 M D'],
  "brimmer": ['B R IH1 M ER0'],
  "brimming": ['B R IH1 M IH0 NG'],
  "brimstone": ['B R IH1 M S T OW0 N'],
  "brin": ['B R IH1 N'],
  "brindel": ['B R IH1 N D EH2 L'],
  "brindle": ['B R IH1 N D AH0 L'],
  "brindley": ['B R IH1 N D L IY0'],
  "brine": ['B R AY1 N'],
  "brinegar": ['B R IH1 N IH0 G ER0'],
  "briner": ['B R AY1 N ER0'],
  "brines": ['B R AY1 N Z'],
  "briney": ['B R IH1 N IY0'],
  "bring": ['B R IH1 NG'],
  "bringhurst": ['B R IH1 NG HH ER0 S T'],
  "bringing": ['B R IH1 NG IH0 NG'],
  "bringle": ['B R IH1 NG G AH0 L'],
  "bringman": ['B R IH1 NG M AH0 N'],
  "brings": ['B R IH1 NG Z'],
  "brington": ['B R IH1 NG T AH0 N'],
  "brining": ['B R AY1 N IH0 NG'],
  "brink": ['B R IH1 NG K'],
  "brink's": ['B R IH1 NG K S'],
  "brinker": ['B R IH1 NG K ER0'],
  "brinkerhoff": ['B R IH1 NG K ER0 HH AO2 F'],
  "brinkley": ['B R IH1 NG K L IY0'],
  "brinkley's": ['B R IH1 NG K L IY0 Z'],
  "brinkly": ['B R IH1 NG K L IY0'],
  "brinkman": ['B R IH1 NG K M AH0 N'],
  "brinkmann": ['B R IH1 NG K M AH0 N'],
  "brinkmann's": ['B R IH1 NG K M AH0 N Z'],
  "brinkmanship": ['B R IH1 NG K M AH0 N SH IH2 P'],
  "brinkmeier": ['B R IH1 NG K M AY0 ER0'],
  "brinkmeyer": ['B R IH1 NG K M AY0 ER0'],
  "brinks": ['B R IH1 NG K S'],
  "brinksmanship": ['B R IH1 NG K S M AH0 N SH IH2 P'],
  "brinlee": ['B R IH1 N L IY0'],
  "brinley": ['B R IH1 N L IY0'],
  "brinn": ['B R IH1 N'],
  "brinner": ['B R IH1 N ER0'],
  "brinser": ['B R IH1 N S ER0'],
  "brinsfield": ['B R IH1 N S F IY0 L D'],
  "brinson": ['B R IH1 N S AH0 N'],
  "brintec": ['B R IH1 N T EH2 K'],
  "brinton": ['B R IH1 N T AH0 N'],
  "brio": ['B R AY1 OW0'],
  "brioche": ['B R IY2 OW1 SH', 'B R IY1 AA0 SH'],
  "briody": ['B R AY1 AH0 D IY0'],
  "brion": ['B R AY1 AH0 N'],
  "briones": ['B R IY0 OW1 N EH0 S'],
  "briony": ['B R AY1 AH0 N IY2'],
  "briony's": ['B R AY1 AH0 N IY2 Z'],
  "briquemont": ['B R IH1 K M AO0 N T'],
  "brisbane": ['B R IH1 Z B EY2 N'],
  "brisbin": ['B R IH1 Z B IH0 N'],
  "brisbois": ['B R IH0 Z B W AA1', 'B R IH1 Z B W AA2'],
  "brisbon": ['B R IH1 Z B AH0 N'],
  "brisburg": ['B R IH1 S B ER0 G'],
  "brisco": ['B R IY1 S K OW0'],
  "briscoe": ['B R IH1 S K OW0'],
  "brisendine": ['B R IH1 S IH0 N D AY2 N'],
  "briseno": ['B R IY0 S EH1 N OW0'],
  "briseno's": ['B R IY0 S EH1 N OW0 Z'],
  "brisk": ['B R IH1 S K'],
  "brisker": ['B R IH1 S K ER0'],
  "brisket": ['B R IH1 S K AH0 T'],
  "briskey": ['B R IH1 S K IY0'],
  "briski": ['B R IH1 S K IY0'],
  "briskin": ['B R IH1 S K IH0 N'],
  "briskly": ['B R IH1 S K L IY0'],
  "brisky": ['B R IH1 S K IY0'],
  "brislin": ['B R IH1 S L IH0 N'],
  "brison": ['B R IH1 S AH0 N'],
  "brissette": ['B R IH0 S EH1 T'],
  "brissey": ['B R IH1 S IY0'],
  "brisson": ['B R IH1 S AH0 N'],
  "brister": ['B R IH1 S T ER0'],
  "bristle": ['B R IH1 S AH0 L'],
  "bristled": ['B R IH1 S AH0 L D'],
  "bristles": ['B R IH1 S AH0 L Z'],
  "bristling": ['B R IH1 S AH0 L IH0 NG', 'B R IH1 S L IH0 NG'],
  "bristol": ['B R IH1 S T AH0 L'],
  "bristol's": ['B R IH1 S T AH0 L Z'],
  "bristow": ['B R IH1 S T OW0'],
  "brit": ['B R IH1 T'],
  "brita": ['B R IY1 T AH0'],
  "britain": ['B R IH1 T AH0 N'],
  "britain's": ['B R IH1 T AH0 N Z'],
  "britains": ['B R IH1 T AH0 N Z'],
  "britannia": ['B R IH0 T AE1 N IY0 AH0'],
  "britannica": ['B R IH0 T AE1 N IH0 K AH0'],
  "britcher": ['B R IH1 CH ER0'],
  "britches": ['B R IH1 CH AH0 Z'],
  "brite": ['B R AY1 T'],
  "britian": ['B R IH1 SH AH0 N'],
  "british": ['B R IH1 T IH0 SH'],
  "britnell": ['B R IH1 T N AH0 L'],
  "britney": ['B R IY1 T N IY2'],
  "britney's": ['B R IH1 T N IY2 Z'],
  "brito": ['B R IY1 T OW0'],
  "britoil": ['B R IH0 T OY1 L'],
  "briton": ['B R IH1 T AH0 N'],
  "britons": ['B R IH1 T AH0 N Z'],
  "britons'": ['B R IH1 T AH0 N Z'],
  "brits": ['B R IH1 T S'],
  "britsch": ['B R IH1 CH'],
  "britt": ['B R IH1 T'],
  "brittain": ['B R IH1 T AH0 N'],
  "brittan": ['B R IH1 T AH0 N'],
  "brittania": ['B R IH0 T AE1 N IY0 AH0', 'B R IH0 T AE1 N Y AH0'],
  "brittany": ['B R IH1 T AH0 N IY0'],
  "britten": ['B R IH1 T AH0 N'],
  "brittenham": ['B R IH1 T IH0 N HH AH0 M'],
  "brittian": ['B R IH1 T IY0 AH0 N'],
  "brittin": ['B R IH1 T IH0 N'],
  "brittingham": ['B R IH1 T IH0 NG HH AE0 M'],
  "brittle": ['B R IH1 T AH0 L'],
  "brittler": ['B R IH1 T L ER0'],
  "brittlest": ['B R IH1 T L IH0 S T'],
  "britton": ['B R IH1 T AH0 N'],
  "britts": ['B R IH1 T S'],
  "britz": ['B R IH1 T S'],
  "brix": ['B R IH1 K S'],
  "brixey": ['B R IH1 K S IY0'],
  "brixius": ['B R AY1 K S IY0 IH0 S'],
  "brizendine": ['B R IY0 Z EH0 N D IY1 N IY0'],
  "brizill": ['B R IH0 Z IH1 L'],
  "brizola": ['B R IH0 Z OW1 L AH0'],
  "brizzi": ['B R IH1 Z IY0'],
  "brizzolara": ['B R IY0 T S OW0 L AA1 R AH0'],
  "bro": ['B R OW1'],
  "bro's": ['B R OW1 Z'],
  "broach": ['B R OW1 CH'],
  "broached": ['B R OW1 CH T'],
  "broaches": ['B R OW1 CH IH0 Z'],
  "broaching": ['B R OW1 CH IH0 NG'],
  "broad": ['B R AO1 D'],
  "broad's": ['B R AO1 D Z'],
  "broadacre": ['B R AO1 D EY0 K ER0'],
  "broadaway": ['B R AO1 D AH0 W EY2'],
  "broadband": ['B R AO1 D B AE2 N D'],
  "broadbase": ['B R AO1 D B EY2 S'],
  "broadbased": ['B R AO1 D B EY2 S T'],
  "broadbeach": ['B R AO1 D B IY2 CH'],
  "broadbent": ['B R AO1 D B EH2 N T'],
  "broadcast": ['B R AO1 D K AE2 S T'],
  "broadcaster": ['B R AO1 D K AE2 S T ER0'],
  "broadcaster's": ['B R AO1 D K AE2 S T ER0 Z'],
  "broadcasters": ['B R AO1 D K AE2 S T ER0 Z'],
  "broadcasters'": ['B R AO1 D K AE2 S T ER0 Z'],
  "broadcasting": ['B R AO1 D K AE2 S T IH0 NG'],
  "broadcasting's": ['B R AO1 D K AE2 S T IH0 NG Z'],
  "broadcasts": ['B R AO1 D K AE2 S T S'],
  "broaddus": ['B R AO1 D IH0 S'],
  "broaden": ['B R AO1 D AH0 N'],
  "broadened": ['B R AO1 D AH0 N D'],
  "broadening": ['B R AO1 D AH0 N IH0 NG', 'B R AO1 D N IH0 NG'],
  "broadens": ['B R AO1 D AH0 N Z'],
  "broader": ['B R AO1 D ER0'],
  "broadest": ['B R AO1 D IH0 S T'],
  "broadhead": ['B R AO1 D HH EH2 D'],
  "broadhurst": ['B R AO1 D HH ER0 S T'],
  "broadie": ['B R AO1 D IY0'],
  "broadley": ['B R AO1 D L IY0'],
  "broadly": ['B R AO1 D L IY0'],
  "broadnax": ['B R AO1 D N AE0 K S'],
  "broadpfoot": ['B R AO1 D F UH0 T'],
  "broadrick": ['B R AO1 D R IH0 K'],
  "broadside": ['B R AO1 D S AY2 D'],
  "broadsided": ['B R AO1 D S AY2 D IH0 D'],
  "broadstreet": ['B R AO1 D S T R IY2 T'],
  "broadsword": ['B R AO1 D S AO2 R D'],
  "broadus": ['B R OW1 D AH0 S'],
  "broadview": ['B R AO1 D V Y UW2'],
  "broadway": ['B R AO1 D W EY2'],
  "broadway's": ['B R AO1 D W EY2 Z'],
  "broadwell": ['B R AO1 D W EH2 L'],
  "broady": ['B R AO1 D IY0'],
  "brobdingnagian": ['B R AO2 B D IH0 G N AE1 G IY0 AH0 N'],
  "brobdingnagians": ['B R AO2 B D IH0 G N AE1 G IY0 AH0 N Z'],
  "brobeck": ['B R OW1 B EH2 K'],
  "broberg": ['B R OW1 B ER0 G'],
  "brobst": ['B R AA1 B S T'],
  "broc": ['B R AA1 K'],
  "brocade": ['B R OW0 K EY1 D'],
  "brocades": ['B R OW0 K EY1 D Z'],
  "brocato": ['B R OW0 K AA1 T OW0'],
  "brocco": ['B R AA1 K OW0'],
  "broccoli": ['B R AA1 K AH0 L IY0', 'B R AA1 K L IY0'],
  "broce": ['B R OW1 S'],
  "brochu": ['B R OW1 K UW0'],
  "brochure": ['B R OW0 SH UH1 R'],
  "brochures": ['B R OW0 SH UH1 R Z'],
  "brocious": ['B R AH0 SH IY1 S'],
  "brock": ['B R AA1 K'],
  "brock's": ['B R AA1 K S'],
  "brockbank": ['B R AA1 K B AH0 NG K'],
  "brockel": ['B R AA1 K AH0 L'],
  "brocker": ['B R AA1 K ER0'],
  "brockert": ['B R AA1 K ER0 T'],
  "brockett": ['B R AA1 K IH0 T'],
  "brockhaus": ['B R AA1 K HH AW2 S'],
  "brockhoff": ['B R AA1 K HH AO2 F'],
  "brockhouse": ['B R AA1 K HH AW2 S'],
  "brockie": ['B R AA1 K IY0'],
  "brockington": ['B R AA1 K IH0 NG T AH0 N'],
  "brocklehurst": ['B R AA1 K AH0 L HH ER0 S T'],
  "brockley": ['B R AA1 K L IY0'],
  "brocklin": ['B R AA1 K L IH0 N'],
  "brocklin's": ['B R AA1 K L IH0 N Z'],
  "brockman": ['B R AA1 K M AH0 N'],
  "brockmann": ['B R AA1 K M AH0 N'],
  "brockmeier": ['B R AA1 K M AY0 ER0'],
  "brockmeyer": ['B R AA1 K M AY0 ER0'],
  "brockner": ['B R AA1 K N ER0'],
  "brocksmith": ['B R AA1 K S M IH2 TH'],
  "brockton": ['B R AA1 K T AH0 N'],
  "brockway": ['B R AA1 K W EY2'],
  "brockway's": ['B R AA1 K W EY2 Z'],
  "brockwell": ['B R AA1 K W EH2 L'],
  "brod": ['B R AA1 D'],
  "broda": ['B R OW1 D AH0'],
  "brodbeck": ['B R AA1 D B EH2 K'],
  "brode": ['B R OW1 D'],
  "broden": ['B R OW1 D AH0 N'],
  "broder": ['B R OW1 D ER0'],
  "broder's": ['B R OW1 D ER0 Z'],
  "broderbund": ['B R OW1 T ER0 B AH0 N D'],
  "broderick": ['B R AA1 D ER0 IH0 K', 'B R AA1 D R IH0 K'],
  "broderick's": ['B R AA1 D ER0 IH0 K Z', 'B R AA1 D R IH0 K Z'],
  "brodersen": ['B R AA1 D ER0 S AH0 N'],
  "brodersohn": ['B R OW1 T ER0 S AH0 N'],
  "broderson": ['B R AA1 D ER0 S AH0 N'],
  "brodeur": ['B R AA1 D ER0'],
  "brodhead": ['B R AA1 D HH EH2 D'],
  "brodie": ['B R OW1 T IY0'],
  "brodin": ['B R OW1 D IH0 N'],
  "brodkin": ['B R AA1 D K IH0 N'],
  "brodman": ['B R AA1 D M AH0 N'],
  "brodnax": ['B R AA1 D N AE0 K S'],
  "brodowski": ['B R AH0 D AO1 F S K IY0'],
  "brodrick": ['B R AA1 D R IH0 K'],
  "brodry": ['B R AA1 D R IY0'],
  "brodsky": ['B R AA1 D S K IY0'],
  "brodt": ['B R AA1 D T'],
  "brody": ['B R OW1 D IY0'],
  "brody's": ['B R OW1 D IY0 Z'],
  "brodzinski": ['B R AH0 JH IH1 N S K IY0'],
  "broe": ['B R OW1'],
  "broecker": ['B R OW1 K ER0'],
  "broeker": ['B R OW1 K ER0'],
  "broer": ['B R OW1 ER0'],
  "broerman": ['B R OW1 ER0 M AH0 N'],
  "broers": ['B R OW1 ER0 Z'],
  "brogan": ['B R OW1 G AH0 N'],
  "brogden": ['B R AA1 G D AH0 N'],
  "brogdon": ['B R AA1 G D AH0 N'],
  "brogna": ['B R OW1 G N AH0'],
  "broich": ['B R OY1 CH'],
  "broil": ['B R OY1 L'],
  "broiled": ['B R OY1 L D'],
  "broiler": ['B R OY1 L ER0'],
  "broilers": ['B R OY1 L ER0 Z'],
  "broiling": ['B R OY1 L IH0 NG'],
  "broin": ['B R OY1 N'],
  "brok": ['B R AA1 K'],
  "brokaw": ['B R OW1 K AO0'],
  "broke": ['B R OW1 K'],
  "broken": ['B R OW1 K AH0 N'],
  "broken-tooth": ['B R OW1 K AH0 N T UW2 TH'],
  "broken-wind": ['B R OW1 K AH0 N W IH1 N D'],
  "broken-winded": ['B R OW1 K AH0 N W IH1 N D IH0 D'],
  "broker": ['B R OW1 K ER0'],
  "broker's": ['B R OW1 K ER0 Z'],
  "brokerage": ['B R OW1 K ER0 IH0 JH', 'B R OW1 K R IH0 JH'],
  "brokerage's": ['B R OW1 K ER0 IH0 JH IH0 Z', 'B R OW1 K R IH0 JH IH0 Z'],
  "brokerages": ['B R OW1 K ER0 IH0 JH IH0 Z', 'B R OW1 K R IH0 JH IH0 Z'],
  "brokerages'": ['B R OW1 K ER0 IH0 JH IH0 Z', 'B R OW1 K R IH0 JH IH0 Z'],
  "brokered": ['B R OW1 K ER0 D'],
  "brokering": ['B R OW1 K ER0 IH0 NG'],
  "brokers": ['B R OW1 K ER0 Z'],
  "brokers'": ['B R OW1 K ER0 Z'],
  "broking": ['B R OW1 K IH0 NG'],
  "brokini": ['B R AH0 K IY1 N IY0'],
  "brolin": ['B R OW1 L IH0 N'],
  "brolly": ['B R AA1 L IY0'],
  "brom": ['B R AA1 M'],
  "broman": ['B R OW1 M AH0 N'],
  "bromberg": ['B R AA1 M B ER0 G'],
  "bromfield": ['B R AA1 M F IY2 L D'],
  "bromfield's": ['B R AA1 M F IY2 L D Z'],
  "bromide": ['B R OW1 M AY2 D'],
  "bromides": ['B R OW1 M AY2 D Z'],
  "bromine": ['B R OW1 M IY2 N'],
  "bromley": ['B R AA1 M L IY0'],
  "bromm": ['B R AA1 M'],
  "brommer": ['B R AA1 M ER0'],
  "bromont": ['B R OW1 M AA2 N T'],
  "brompheril": ['B R AA1 M F EH0 R IH0 L'],
  "bromwell": ['B R AA1 M W EH2 L'],
  "bromwich": ['B R AA1 M W IH0 CH'],
  "bronaugh": ['B R AA1 N AO0'],
  "bronc": ['B R AA1 NG K'],
  "bronchial": ['B R AA1 N CH IY0 AH0 L'],
  "bronchitis": ['B R AA0 NG K AY1 T AH0 S'],
  "bronchoscope": ['B R AA1 N K AH0 S K OW1 P'],
  "bronco": ['B R AA1 NG K OW0'],
  "broncos": ['B R AA1 NG K OW0 Z'],
  "bronder": ['B R AA1 N D ER0'],
  "broner": ['B R OW1 N ER0'],
  "bronfman": ['B R AA1 N F M AH0 N'],
  "bronfman's": ['B R AA1 N F M AH0 N Z'],
  "bronfmans": ['B R AA1 N F M AH0 N Z'],
  "bronk": ['B R AA1 NG K'],
  "bronkema": ['B R AH0 NG K IY1 M AH0'],
  "bronn": ['B R AA1 N'],
  "bronner": ['B R AA1 N ER0'],
  "brons": ['B R AA1 N Z'],
  "bronson": ['B R AA1 N S AH0 N'],
  "bronstein": ['B R AA1 N S T IY2 N', 'B R AA1 N S T AY2 N'],
  "bronston": ['B R AA1 N S T AH0 N'],
  "bronte": ['B R AA1 N T IY0'],
  "bronte's": ['B R AA1 N T IY0 Z'],
  "brontosaurus": ['B R AO2 N T AH0 S AO1 R AH0 S', 'B R AO2 N AH0 S AO1 R AH0 S'],
  "bronwen": ['B R AO1 N W IH0 N'],
  "bronwyn": ['B R AA1 N W IH0 N'],
  "bronx": ['B R AA1 NG K S'],
  "brony": ['B R OW1 N IY2'],
  "bronze": ['B R AA1 N Z'],
  "bronzed": ['B R AA1 N Z D'],
  "bronzen": ['B R AA1 N Z AH0 N'],
  "bronzes": ['B R AA1 N Z AH0 Z', 'B R AA1 N Z IH0 Z'],
  "brooch": ['B R UW1 CH', 'B R OW1 CH'],
  "brood": ['B R UW1 D'],
  "brooded": ['B R UW1 D IH0 D'],
  "brooding": ['B R UW1 D IH0 NG'],
  "broody": ['B R UW1 D IY0'],
  "brook": ['B R UH1 K'],
  "brook's": ['B R UH1 K S'],
  "brookbank": ['B R UH1 K B AE2 NG K'],
  "brooke": ['B R UH1 K'],
  "brooke's": ['B R UH1 K S'],
  "brookehill": ['B R UH1 K HH IH2 L'],
  "brooken": ['B R UH1 K AH0 N'],
  "brookens": ['B R UH1 K AH0 N Z'],
  "brooker": ['B R UH1 K ER0'],
  "brookes": ['B R UH1 K S'],
  "brookfield": ['B R UH1 K F IY2 L D'],
  "brookhart": ['B R UW1 K HH AA0 R T'],
  "brookhaven": ['B R UH1 K HH EY2 V AH0 N'],
  "brookhurst": ['B R UH1 K HH ER2 S T'],
  "brooking": ['B R UH1 K IH0 NG'],
  "brookings": ['B R UH1 K IH0 NG Z'],
  "brookins": ['B R UW1 K IH0 N Z'],
  "brookline": ['B R UH1 K L AY2 N'],
  "brooklyn": ['B R UH1 K L AH0 N', 'B R UH1 K L IH0 N'],
  "brooklyn's": ['B R UH1 K L AH0 N Z', 'B R UH1 K L IH0 N Z'],
  "brookman": ['B R UH1 K M AH0 N'],
  "brookner": ['B R UH1 K N ER0'],
  "brookner's": ['B R UH1 K N ER0 Z'],
  "brookover": ['B R UH1 K OW2 V ER0'],
  "brooks": ['B R UH1 K S'],
  "brooks'": ['B R UH1 K S'],
  "brooks's": ['B R UH1 K S IH0 Z', 'B R UH1 K S'],
  "brookshier": ['B R UW1 K SH IY0 ER0'],
  "brookshire": ['B R UW1 K SH AY0 R'],
  "brookside": ['B R UH1 K S AY2 D'],
  "brookstone": ['B R UH1 K S T OW2 N'],
  "brooksville": ['B R UH1 K S V IH0 L'],
  "brooksville's": ['B R UH1 K S V IH0 L Z'],
  "broom": ['B R UW1 M'],
  "broomall": ['B R UW1 M AH0 L'],
  "broome": ['B R UW1 M'],
  "broomfield": ['B R UW1 M F IY2 L D'],
  "brooms": ['B R UW1 M Z'],
  "broomstick": ['B R UW1 M S T IH2 K'],
  "broomsticks": ['B R UW1 M S T IH2 K S'],
  "brophy": ['B R OW1 F IY0'],
  "brosch": ['B R AO1 SH'],
  "brose": ['B R OW1 Z'],
  "brosh": ['B R AA1 SH'],
  "broshears": ['B R AA1 SH IH0 R Z'],
  "brosious": ['B R OW1 Z IY0 AH0 S'],
  "brosius": ['B R OW1 S IY0 IH0 S'],
  "broski": ['B R AW1 S K IY0'],
  "brosky": ['B R AA1 S K IY0'],
  "brosnahan": ['B R AA1 S N AH0 HH AE0 N'],
  "brosnan": ['B R AA1 S N AH0 N'],
  "bross": ['B R AO1 S'],
  "brossard": ['B R AH0 S AA1 R D'],
  "brossart": ['B R AA1 S AA0 R T'],
  "brosseau": ['B R AH0 S OW1'],
  "brosser": ['B R AA1 S ER0'],
  "brossette": ['B R AH0 S EH1 T'],
  "brossman": ['B R AO1 S M AH0 N'],
  "brost": ['B R AA1 S T'],
  "brostrom": ['B R AA1 S T R AH0 M'],
  "brosz": ['B R AA1 SH'],
  "broten": ['B R OW1 T AH0 N'],
  "broth": ['B R AO1 TH'],
  "brothel": ['B R AA1 TH AH0 L'],
  "brothels": ['B R AA1 TH AH0 L Z'],
  "brother": ['B R AH1 DH ER0'],
  "brother's": ['B R AH1 DH ER0 Z'],
  "brother-in-law": ['B R AH1 DH ER0 IH0 N L AO2'],
  "brotherhood": ['B R AH1 DH ER0 HH UH2 D'],
  "brotherly": ['B R AH1 DH ER0 L IY0'],
  "brothers": ['B R AH1 DH ER0 Z'],
  "brothers'": ['B R AH1 DH ER0 Z'],
  "brothers's": ['B R AH1 DH ER0 Z IH0 Z'],
  "brothers-in-law": ['B R AH1 DH ER0 Z IH0 N L AO2'],
  "brotherson": ['B R AH1 DH ER0 S AH0 N'],
  "brotherton": ['B R AH1 DH ER0 T AH0 N'],
  "broths": ['B R AO1 TH S'],
  "brotman": ['B R AA1 T M AH0 N'],
  "brott": ['B R AA1 T'],
  "brotzman": ['B R AA1 T S M AH0 N'],
  "broucek": ['B R UW1 CH EH0 K'],
  "broudy": ['B R AW1 D IY0'],
  "brough": ['B R AW1'],
  "brougham": ['B R UW1 G AH0 M'],
  "brougher": ['B R AW1 ER0'],
  "broughman": ['B R AW1 M AH0 N'],
  "brought": ['B R AO1 T'],
  "broughton": ['B R AO1 T AH0 N'],
  "brouhaha": ['B R UW1 HH AA0 HH AA2'],
  "brouhard": ['B R AA1 UW0 ER0 D'],
  "brouillard": ['B R W IY0 L AA1 R D'],
  "brouillet": ['B R W IY0 L EH1 T'],
  "brouillette": ['B R W IY0 L EH1 T'],
  "broun": ['B R UW1 N'],
  "brountas": ['B R AW1 N T AH0 S'],
  "brouse": ['B R AW1 S'],
  "broussard": ['B R UW0 S AA1 R D'],
  "brousseau": ['B R UW2 S OW1'],
  "brousset": ['B R UW1 S EH0 T'],
  "brouwer": ['B R AW1 W ER0'],
  "brow": ['B R AW1'],
  "broward": ['B R AW1 ER0 D'],
  "browbeat": ['B R AW1 B IY2 T'],
  "browder": ['B R AW1 D ER0'],
  "browe": ['B R OW1'],
  "browed": ['B R AW1 D'],
  "brower": ['B R AW1 ER0'],
  "brown": ['B R AW1 N'],
  "brown's": ['B R AW1 N Z'],
  "brownback": ['B R AW1 N B AE2 K'],
  "browne": ['B R AW1 N'],
  "browned": ['B R AW1 N D'],
  "brownell": ['B R AW0 N EH1 L'],
  "browner": ['B R AW1 N ER0'],
  "brownest": ['B R AW1 N IH0 S T'],
  "brownfield": ['B R AW1 N F IY2 L D'],
  "brownfields": ['B R AW1 N F IY2 L D Z'],
  "brownie": ['B R AW1 N IY0'],
  "brownies": ['B R AW1 N IY0 Z'],
  "browning": ['B R AW1 N IH0 NG'],
  "browning's": ['B R AW1 N IH0 NG Z'],
  "brownish": ['B R AW1 N IH0 SH'],
  "brownlee": ['B R AW1 N L IY0'],
  "brownley": ['B R AW1 N L IY0'],
  "brownlie": ['B R AW1 N L IY0'],
  "brownlow": ['B R AW1 N L OW2'],
  "brownmiller": ['B R AW1 N M IH2 L ER0'],
  "brownout": ['B R AW1 N AW2 T'],
  "brownouts": ['B R AW1 N AW2 T S'],
  "brownrigg": ['B R AW1 N R IH0 G'],
  "browns": ['B R AW1 N Z'],
  "browns'": ['B R AW1 N Z'],
  "brownson": ['B R AW1 N S AH0 N'],
  "brownstein": ['B R AW1 N S T AY2 N', 'B R AW1 N S T IY2 N'],
  "brownstone": ['B R AW1 N S T OW2 N'],
  "brownsville": ['B R AW1 N Z V IH0 L'],
  "brows": ['B R AW1 Z'],
  "browse": ['B R AW1 Z'],
  "browsed": ['B R AW1 Z D'],
  "browser": ['B R AW1 Z ER0'],
  "browsers": ['B R AW1 Z ER0 Z'],
  "browsing": ['B R AW1 Z IH0 NG'],
  "brox": ['B R AA1 K S'],
  "broxson": ['B R AA1 K S AH0 N'],
  "broxterman": ['B R AA1 K S T ER0 M AH0 N'],
  "broxton": ['B R AA1 K S T AH0 N'],
  "broy": ['B R OY1'],
  "broyard": ['B R OY1 ER0 D'],
  "broyhill": ['B R OY1 HH IH2 L'],
  "broyles": ['B R OY1 L Z'],
  "broz": ['B R AA1 Z'],
  "brozek": ['B R OW1 Z EH0 K'],
  "brozman": ['B R AA1 Z M AH0 N'],
  "brozovich": ['B R AA1 Z AH0 V IH0 CH'],
  "brozowski": ['B R AH0 Z AO1 F S K IY0'],
  "brubaker": ['B R AH1 B AH0 K ER0'],
  "brubeck": ['B R UW1 B EH2 K'],
  "brucato": ['B R UW0 K AA1 T OW0'],
  "bruce": ['B R UW1 S'],
  "bruce's": ['B R UW1 S AH0 Z'],
  "bruch": ['B R AH1 CH'],
  "bruchhausen": ['B R UW1 K HH AW2 Z AH0 N'],
  "brucie": ['B R AH1 K IY0'],
  "brucite": ['B R UW1 S AY2 T'],
  "bruck": ['B R AH1 K'],
  "bruck's": ['B R AH1 K S'],
  "brucker": ['B R AH1 K ER0'],
  "bruckheimer": ['B R AH1 K HH AY2 M ER0'],
  "bruckman": ['B R AH1 K M AH0 N'],
  "bruckner": ['B R AH1 K N ER0'],
  "brucks": ['B R AH1 K S'],
  "bruder": ['B R UW1 D ER0'],
  "brue": ['B R UW1'],
  "bruecher": ['B R UW1 CH ER0'],
  "brueck": ['B R UW1 K'],
  "brueckner": ['B R UH1 K N ER0'],
  "bruegge": ['B R UW1 G AH0'],
  "brueggeman": ['B R UW1 G M AH0 N'],
  "brueggemann": ['B R UW1 G M AH0 N'],
  "brueggen": ['B R UW1 G AH0 N'],
  "bruegger": ['B R UW1 G ER0'],
  "bruegger's": ['B R UW1 G ER0 Z'],
  "bruehl": ['B R UW1 L'],
  "bruella": ['B R UW2 EH1 L AH0'],
  "bruemmer": ['B R UW1 M ER0'],
  "bruen": ['B R UW1 N'],
  "bruening": ['B R UW1 N IH0 NG'],
  "bruer": ['B R UW1 ER0'],
  "bruff": ['B R AH1 F'],
  "bruford": ['B R UW1 F ER0 D'],
  "bruges": ['B R UW1 JH IH0 Z', 'B R UW1 ZH'],
  "bruggeman": ['B R AH1 G M AH0 N'],
  "brugger": ['B R AH1 G ER0'],
  "brugh": ['B R AH1'],
  "brugman": ['B R AH1 G M AH0 N'],
  "bruha": ['B R UW1 HH AH0'],
  "bruhl": ['B R AH1 L'],
  "bruhn": ['B R AH1 N'],
  "bruin": ['B R UW1 IH0 N'],
  "bruin's": ['B R UW1 IH0 N Z'],
  "bruington": ['B R UW1 IH0 NG T AH0 N'],
  "bruins": ['B R UW1 IH0 N Z'],
  "bruinsma": ['B R UW0 IH1 N S M AH0'],
  "bruise": ['B R UW1 Z'],
  "bruised": ['B R UW1 Z D'],
  "bruises": ['B R UW1 Z AH0 Z', 'B R UW1 Z IH0 Z'],
  "bruising": ['B R UW1 Z IH0 NG'],
  "brule": ['B R UW1 L'],
  "bruley": ['B R UW1 L IY0'],
  "brum": ['B R AH1 M'],
  "brumbach": ['B R AH1 M B AA2 K'],
  "brumback": ['B R AH1 M B AE2 K'],
  "brumbaugh": ['B R AH1 M B AO2'],
  "brumbelow": ['B R AH1 M B IH0 L OW0'],
  "brumer": ['B R UW1 M ER0'],
  "brumett": ['B R AH1 M IH0 T'],
  "brumfield": ['B R AH1 M F IY2 L D'],
  "brumit": ['B R UW1 M IH0 T'],
  "brumitt": ['B R UW1 M IH0 T'],
  "brumley": ['B R AH1 M L IY0'],
  "brumlow": ['B R AH1 M L OW0'],
  "brumm": ['B R AH1 M'],
  "brummel": ['B R AH1 M AH0 L'],
  "brummell": ['B R AH1 M AH0 L'],
  "brummer": ['B R AH1 M ER0'],
  "brummet": ['B R AH1 M IH0 T'],
  "brummett": ['B R AH1 M IH0 T'],
  "brummitt": ['B R AH1 M IH0 T'],
  "brummond": ['B R AH1 M AH0 N D'],
  "brun": ['B R AH1 N'],
  "bruna": ['B R UW1 N AH0'],
  "brunch": ['B R AH1 N CH'],
  "brunches": ['B R AH1 N CH IH0 Z'],
  "bruncor": ['B R AH1 N K AO2 R'],
  "brundage": ['B R AH1 N D IH0 JH'],
  "brundidge": ['B R AH1 N D IH0 JH'],
  "brundige": ['B R AH1 N D IH0 G'],
  "brundtland": ['B R AH1 N T L AH0 N D'],
  "brune": ['B R UW1 N'],
  "bruneau": ['B R AH0 N OW1'],
  "brunei": ['B R UW0 N AY1'],
  "brunell": ['B R AH1 N AH0 L'],
  "brunella": ['B R UW2 N EH1 L AH0'],
  "brunelle": ['B R AH0 N EH1 L'],
  "brunelli": ['B R UW0 N EH1 L IY0'],
  "bruner": ['B R UW1 N ER0'],
  "brunet": ['B R UW0 N EH1 T'],
  "brunett": ['B R AH1 N IH0 T'],
  "brunetta": ['B R UW0 N EH1 T AH0'],
  "brunette": ['B R UW0 N EH1 T'],
  "brunettes": ['B R UW0 N EH1 T S'],
  "brunetti": ['B R UW0 N EH1 T IY0'],
  "brunetto": ['B R UW0 N EH1 T OW0'],
  "bruney": ['B R UW1 N IY0'],
  "brungard": ['B R AH1 NG G ER0 D'],
  "brungardt": ['B R AH1 NG G AA0 R T'],
  "brunger": ['B R AH1 NG ER0'],
  "brunhilda": ['B R UW0 N HH IY1 L D AH0'],
  "bruni": ['B R UW1 N IY0'],
  "brunick": ['B R UW1 N IH0 K'],
  "bruning": ['B R UW1 N IH0 NG'],
  "brunjes": ['B R AH0 N ZH IY1 Z'],
  "brunk": ['B R AH1 NG K'],
  "brunke": ['B R AH1 NG K'],
  "brunken": ['B R AH1 NG K AH0 N'],
  "brunker": ['B R AH1 NG K ER0'],
  "brunkhorst": ['B R AH1 NG K HH AO0 R S T'],
  "brunkow": ['B R AH1 NG K OW0'],
  "brunn": ['B R AH1 N'],
  "brunner": ['B R AH1 N ER0'],
  "brunnhilde": ['B R AH1 N HH IH0 L D'],
  "bruno": ['B R UW1 N OW0'],
  "bruno's": ['B R UW1 N OW0 Z'],
  "bruns": ['B R AH1 N Z'],
  "brunskill": ['B R AH1 N S K IH2 L'],
  "brunsman": ['B R AH1 N S M AH0 N'],
  "brunson": ['B R AH1 N S AH0 N'],
  "brunsvold": ['B R AH1 N Z V OW2 L D'],
  "brunswick": ['B R AH1 N Z W IH0 K'],
  "brunswick's": ['B R AH1 N Z W IH0 K S'],
  "brunswig": ['B R AH1 N S W IH0 G'],
  "brunswig's": ['B R AH1 N S W IH0 G Z'],
  "brunt": ['B R AH1 N T'],
  "bruntjen": ['B R AH1 N T JH EH2 N'],
  "brunton": ['B R AH1 N T AH0 N'],
  "brunty": ['B R AH1 N T IY0'],
  "bruntz": ['B R AH1 N T S'],
  "brus": ['B R AH1 S'],
  "brusca": ['B R AH1 S K AH0'],
  "bruschi": ['B R UW1 S K IY0'],
  "brusco": ['B R UW1 S K OW0'],
  "bruse": ['B R UW1 Z'],
  "brusett": ['B R UH0 S EH1 T', 'B R UW2 S EH1 T'],
  "brusette": ['B R UW2 S EH1 T'],
  "brush": ['B R AH1 SH'],
  "brushaber": ['B R AH1 SH AH0 B ER0'],
  "brushed": ['B R AH1 SH T'],
  "brushes": ['B R AH1 SH IH0 Z'],
  "brushfire": ['B R AH1 SH F AY2 R'],
  "brushfires": ['B R AH1 SH F AY2 R Z'],
  "brushing": ['B R AH1 SH IH0 NG'],
  "brushwork": ['B R AH1 SH W ER2 K'],
  "brushy": ['B R AH1 SH IY0'],
  "bruske": ['B R AH1 S K'],
  "bruski": ['B R AH1 S K IY0'],
  "brusky": ['B R AH1 S K IY0'],
  "bruso": ['B R UW1 S OW0'],
  "brusque": ['B R AH1 S K'],
  "brusquely": ['B R AH1 S K L IY0'],
  "bruss": ['B R AH1 S'],
  "brusseau": ['B R AH0 S OW1'],
  "brussel": ['B R AH1 S AH0 L'],
  "brusselmans": ['B R AH1 S AH0 L M AH0 N Z'],
  "brussels": ['B R AH1 S AH0 L Z'],
  "brust": ['B R AH1 S T'],
  "bruster": ['B R AH1 S T ER0'],
  "brustoloni": ['B R UW1 S T OW0 L OW1 N IY0'],
  "brut": ['B R UW1 T'],
  "brutal": ['B R UW1 T AH0 L'],
  "brutalities": ['B R UW0 T AE1 L AH0 T IY0 Z'],
  "brutality": ['B R UW0 T AE1 L AH0 T IY0', 'B R UW0 T AE1 L IH0 T IY0'],
  "brutalization": ['B R UW1 T AH0 L AH0 Z EY2 SH AH0 N'],
  "brutalize": ['B R UW1 T AH0 L AY2 Z'],
  "brutalized": ['B R UW1 T AH0 L AY2 Z D'],
  "brutalizes": ['B R UW1 T AH0 L AY2 Z IH0 Z'],
  "brutalizing": ['B R UW1 T AH0 L AY2 Z IH0 NG'],
  "brutally": ['B R UW1 T AH0 L IY0'],
  "brute": ['B R UW1 T'],
  "bruting": ['B R UW1 T IH0 NG'],
  "brutish": ['B R UW1 T IH0 SH'],
  "bruton": ['B R UW1 T AH0 N'],
  "brutsche": ['B R AH1 CH IY0'],
  "brutus": ['B R UW1 T AH0 S'],
  "bruun": ['B R UW1 N'],
  "bruxelles": ['B R AH0 K S EH1 L AH0 S'],
  "bruyette": ['B R UW0 EH1 T'],
  "bruynes": ['B R UW1 IH0 N Z'],
  "bruzzese": ['B R UW0 T S EY1 Z IY0'],
  "bryan": ['B R AY1 AH0 N'],
  "bryan's": ['B R AY1 AH0 N Z'],
  "bryans": ['B R AY1 AH0 N Z'],
  "bryans'": ['B R AY1 AH0 N Z'],
  "bryant": ['B R AY1 AH0 N T'],
  "bryars": ['B R AY1 ER0 Z'],
  "bryce": ['B R AY1 S'],
  "bryden": ['B R AY1 D AH0 N'],
  "brydges": ['B R IH1 JH IH0 Z'],
  "brydie": ['B R IH1 D IY0'],
  "brydon": ['B R IH1 D AH0 N'],
  "brye": ['B R AY1'],
  "bryen": ['B R AY1 AH0 N'],
  "bryer": ['B R AY1 ER0'],
  "bryk": ['B R IH1 K'],
  "brymer": ['B R AY1 M ER0'],
  "bryn": ['B R IH1 N'],
  "bryna": ['B R IH1 N AH0'],
  "bryne": ['B R AY1 N'],
  "bryner": ['B R AY1 N ER0'],
  "bryngelson": ['B R IH1 NG G IH0 L S AH0 N'],
  "bryon": ['B R AY1 AH0 N'],
  "brys": ['B R IH1 S'],
  "bryson": ['B R AY1 S AH0 N'],
  "brzeska": ['B R EH1 Z K AA2'],
  "brzezinski": ['B R IH0 Z IH1 N S K IY0'],
  "brzoska": ['B R OW1 S K AH0'],
  "brzozowski": ['B R AH0 Z AO1 F S K IY0'],
  "brzycki": ['B R IH1 T S K IY0'],
  "bs": ['B IY2 EH1 S'],
  "bt": ['B IY1 T IY1'],
  "bta": ['B IY1 T IY1 EY1'],
  "bua": ['B Y UW1 AH0', 'B IY1 Y UW1 EY1'],
  "bub": ['B AH1 B'],
  "bubar": ['B UW1 B ER0'],
  "bubb": ['B AH1 B'],
  "bubba": ['B AH1 B AH0'],
  "bubble": ['B AH1 B AH0 L'],
  "bubbled": ['B AH1 B AH0 L D'],
  "bubbles": ['B AH1 B AH0 L Z'],
  "bubbling": ['B AH1 B AH0 L IH0 NG', 'B AH1 B L IH0 NG'],
  "bubbly": ['B AH1 B L IY0', 'B AH1 B AH0 L IY0'],
  "bubeck": ['B UW1 B EH0 K'],
  "bubel": ['B UW1 B AH0 L'],
  "buber": ['B UW1 B ER2'],
  "bubier": ['B Y UW1 B IY0 ER0'],
  "bubinga": ['B AH0 B IH1 NG G AH0'],
  "bubka": ['B AH1 B K AH0'],
  "bublitz": ['B AH1 B L IH0 T S'],
  "buboltz": ['B Y UW1 B OW2 L T S'],
  "bubolz": ['B Y UW1 B OW2 L Z'],
  "bubonic": ['B Y UW0 B AA1 N IH0 K'],
  "buc": ['B AH1 K'],
  "bucaro": ['B UW0 K AA1 R OW0'],
  "bucase": ['B Y UW1 K EY2 S'],
  "buccal": ['B Y UW1 K AH0 L'],
  "buccaneer": ['B AH2 K AH0 N IY1 R'],
  "buccaneers": ['B AH2 K AH0 N IY1 R Z'],
  "buccellato": ['B UW0 CH EH0 L AA1 T OW0'],
  "buccheri": ['B UW0 K EH1 R IY0'],
  "bucci": ['B UW1 CH IY0'],
  "bucciarelli": ['B UW0 CH ER0 EH1 L IY0'],
  "buccieri": ['B UW0 CH IH1 R IY0'],
  "buccino": ['B UW0 CH IY1 N OW0'],
  "bucco": ['B UW1 K OW0'],
  "buccola": ['B UW0 K OW1 L AH0'],
  "bucek": ['B UW1 CH EH0 K'],
  "bucey": ['B Y UW1 S IY0'],
  "buch": ['B AH1 CH'],
  "buchalter": ['B AH1 K AH0 L T ER0'],
  "buchan": ['B AH1 K AH0 N'],
  "buchanan": ['B Y UW0 K AE1 N AH0 N'],
  "buchanan's": ['B Y UW0 K AE1 N AH0 N Z'],
  "buchananism": ['B Y UW0 K AE1 N AH0 N IH2 Z AH0 M'],
  "buchanans": ['B Y UW0 K AE1 N AH0 N Z'],
  "buchannan": ['B Y UW0 K AE1 N AH0 N'],
  "buchanon": ['B Y UW0 K AE1 N AH0 N'],
  "bucharest": ['B Y UW1 K ER0 EH2 S T', 'B UW1 K ER0 EH2 S T'],
  "buchberger": ['B AH1 K B ER0 G ER0'],
  "buchbinder": ['B AH1 K B IH2 N D ER0', 'B UH1 K B AY2 N D ER0'],
  "buche": ['B AH1 CH'],
  "buchen": ['B AH1 K AH0 N'],
  "buchenwald": ['B Y UW1 K EH0 N W AA2 L D'],
  "bucher": ['B AH1 K ER0'],
  "buchert": ['B AH1 CH ER0 T'],
  "buchheit": ['B AH1 K HH AY0 T'],
  "buchholtz": ['B AH1 K HH OW0 L T S'],
  "buchholz": ['B AH1 K HH OW0 L Z'],
  "buchi": ['B AH1 CH IY0', 'B UW1 CH IY0'],
  "buchi's": ['B AH1 CH IY0 Z', 'B UW1 CH IY0 Z'],
  "buchinger": ['B AH1 K IH0 N JH ER0'],
  "buchko": ['B AH1 CH K OW0'],
  "buchler": ['B AH1 K AH0 L ER0', 'B AH1 K L ER0'],
  "buchman": ['B AH1 K M AH0 N'],
  "buchmann": ['B AH1 K M AH0 N'],
  "buchmiller": ['B AH1 K M AH0 L ER0', 'B UH1 K M AH0 L ER0'],
  "buchner": ['B AH1 K N ER0'],
  "bucholtz": ['B AH1 K OW0 L T S', 'B UH1 K OW0 L T S'],
  "bucholz": ['B AH1 K OW0 L Z'],
  "buchs": ['B AH1 K S'],
  "buchsbaum": ['B AH1 K S B AW0 M'],
  "buchta": ['B AH1 CH T AH0'],
  "buchter": ['B AH1 K T ER0'],
  "buchwald": ['B AH1 K W AO0 L D'],
  "buck": ['B AH1 K'],
  "buck's": ['B AH1 K S'],
  "buckalew": ['B AH1 K AH0 L UW0'],
  "buckbee": ['B AH1 K B IY2'],
  "bucked": ['B AH1 K T'],
  "buckel": ['B AH1 K AH0 L'],
  "buckelew": ['B AH1 K IH0 L UW0'],
  "buckels": ['B AH1 K AH0 L Z'],
  "bucket": ['B AH1 K AH0 T', 'B AH1 K IH0 T'],
  "buckets": ['B AH1 K AH0 T S'],
  "buckey": ['B AH1 K IY0'],
  "buckeye": ['B AH1 K AY2'],
  "buckeyes": ['B AH1 K AY2 Z'],
  "buckhantz": ['B AH1 K HH AE2 N T S'],
  "buckholtz": ['B AH1 K HH OW2 L T S'],
  "buckholz": ['B AH1 K HH OW0 L Z'],
  "buckhorn": ['B AH1 K HH AO2 R N'],
  "bucking": ['B AH1 K IH0 NG'],
  "buckingham": ['B AH1 K IH0 NG HH AE2 M'],
  "buckland": ['B AH1 K L AH0 N D'],
  "buckle": ['B AH1 K AH0 L'],
  "buckled": ['B AH1 K AH0 L D'],
  "buckler": ['B AH1 K AH0 L ER0', 'B AH1 K L ER0'],
  "buckles": ['B AH1 K AH0 L Z'],
  "bucklew": ['B AH1 K L UW0'],
  "buckley": ['B AH1 K L IY0'],
  "bucklin": ['B AH1 K L IH0 N'],
  "buckling": ['B AH1 K L IH0 NG'],
  "buckman": ['B AH1 K M AH0 N'],
  "buckmaster": ['B AH1 K M AE2 S T ER0'],
  "buckminster": ['B AH1 K M IH2 N S T ER0'],
  "bucknam": ['B AH1 K N AH0 M'],
  "bucknell": ['B AH2 K N EH1 L'],
  "buckner": ['B AH1 K N ER0'],
  "bucko": ['B AH1 K OW0'],
  "bucks": ['B AH1 K S'],
  "buckshot": ['B AH1 K SH AA2 T'],
  "buckskin": ['B AH1 K S K IH2 N'],
  "buckskins": ['B AH1 K S K IH2 N Z'],
  "buckstein": ['B AH1 K S T IY2 N'],
  "buckthorn": ['B AH1 K TH AO2 R N'],
  "buckwalter": ['B AH1 K W AH0 L T ER0'],
  "buckwheat": ['B AH1 K W IY2 T'],
  "bucky": ['B AH1 K IY0'],
  "bucoba": ['B Y UW0 K OW1 B AH0'],
  "bucolic": ['B Y UW0 K AA1 L IH0 K'],
  "bucs": ['B AH1 K S'],
  "bucs'": ['B AH1 K S'],
  "bucy": ['B Y UW1 S IY0'],
  "buczek": ['B AH1 CH EH0 K'],
  "buczkowski": ['B AH0 CH K AO1 F S K IY0'],
  "buczynski": ['B AH0 CH IH1 N S K IY0'],
  "bud": ['B AH1 D'],
  "bud's": ['B AH1 D Z'],
  "bud-test": ['B AH1 D T EH1 S T'],
  "budai": ['B UW0 D AA1 IY0'],
  "budapest": ['B UW1 D AH0 P EH2 S T', 'B UW1 D AH0 P EH2 SH T'],
  "budapest's": ['B UW1 D AH0 P EH2 S T S', 'B UW1 D AH0 P EH2 SH T S'],
  "buday": ['B UW1 D EY0'],
  "budd": ['B AH1 D'],
  "budde": ['B AH1 D'],
  "budden": ['B AH1 D AH0 N'],
  "buddenbrooks": ['B AH1 D AH0 N B R UH2 K S'],
  "buddenhagen": ['B AH1 D IH0 N HH AH0 G AH0 N'],
  "buddha": ['B UW1 D AH0'],
  "buddha's": ['B UW1 D AH0 Z'],
  "buddhism": ['B UW1 D IH0 Z AH0 M'],
  "buddhist": ['B UW1 D AH0 S T'],
  "buddhists": ['B UW1 D AH0 S T S'],
  "buddie": ['B AH1 D IY0'],
  "buddier": ['B AH1 D IY0 ER0'],
  "buddiers": ['B AH1 D IY0 ER0 Z'],
  "buddies": ['B AH1 D IY0 Z'],
  "buddin": ['B AH1 D IH0 N'],
  "budding": ['B AH1 D IH0 NG'],
  "buddy": ['B AH1 D IY0'],
  "buddy's": ['B AH1 D IY0 Z'],
  "buder": ['B Y UW1 D ER0'],
  "budge": ['B AH1 JH'],
  "budged": ['B AH1 JH D'],
  "budget": ['B AH1 JH IH0 T'],
  "budget's": ['B AH1 JH IH0 T S'],
  "budgetary": ['B AH1 JH IH0 T EH2 R IY0'],
  "budgeted": ['B AH1 JH IH0 T AH0 D', 'B AH1 JH IH0 T IH0 D'],
  "budgeteer": ['B AH2 JH IH0 T IH1 R'],
  "budgeteers": ['B AH2 JH IH0 T IH1 R Z'],
  "budgeting": ['B AH1 JH IH0 T IH0 NG'],
  "budgets": ['B AH1 JH IH0 T S'],
  "budging": ['B AH1 JH IH0 NG'],
  "budick": ['B AH1 D IH0 K'],
  "budick's": ['B AH1 D IH0 K S'],
  "budiman": ['B Y UW1 T IH0 M AH0 N', 'B AH0 D IH1 M AH0 N'],
  "budin": ['B UW1 D IH0 N'],
  "budinger": ['B Y UW1 D IH0 NG ER0'],
  "budka": ['B AH1 D K AH0'],
  "budke": ['B AH1 D K IY0'],
  "budlong": ['B AH1 D L AO2 NG'],
  "budner": ['B AH1 D N ER0'],
  "budney": ['B AH1 D N IY0'],
  "budnick": ['B AH1 D N IH0 K'],
  "budnik": ['B AH1 D N IH0 K'],
  "budny": ['B AH1 D N IY0'],
  "budreau": ['B AH0 D R OW1'],
  "budrow": ['B AH1 D R OW2'],
  "buds": ['B AH1 D Z'],
  "budson": ['B AH1 D S AH0 N'],
  "budvar": ['B AH1 D V AA2 R', 'B UH1 D V AA2 R'],
  "budweiser": ['B AH1 D W AY0 Z ER0'],
  "budyonnovsk": ['B UW1 D Y AH0 N AA2 V S K'],
  "budz": ['B AH1 D Z'],
  "budzinski": ['B AH0 JH IH1 N S K IY0'],
  "budzyn": ['B AH1 D Z IH0 N'],
  "budzynski": ['B AH0 JH IH1 N S K IY0'],
  "bue": ['B W EH1'],
  "bueche": ['B UW1 CH'],
  "buechel": ['B Y UW1 K AH0 L'],
  "buechele": ['B Y UW1 K AH0 L'],
  "buechler": ['B Y UW1 K AH0 L ER0', 'B Y UW1 K L ER0'],
  "buechner": ['B Y UW1 K N ER0'],
  "buege": ['B UW1 JH'],
  "buegler": ['B Y UW1 G L ER0'],
  "buehl": ['B Y UW1 L'],
  "buehler": ['B Y UW1 L ER0'],
  "buehner": ['B Y UW1 N ER0'],
  "buehrer": ['B Y UW1 HH ER0', 'B Y UW1 ER0'],
  "buehring": ['B Y UW1 R IH0 NG'],
  "buehrle": ['B Y UW1 R AH0 L'],
  "bueker": ['B Y UW1 K ER0'],
  "buel": ['B Y UW1 L'],
  "buell": ['B Y UW1 AH0 L'],
  "buelow": ['B UW1 L OW0'],
  "buena": ['B UW1 N AH0'],
  "buendia": ['B UW1 N D IY0 AH0'],
  "buenger": ['B Y UW1 N JH ER0'],
  "buening": ['B W EH1 N IH0 NG'],
  "bueno": ['B W EY1 N OW0'],
  "buenos": ['B W EY1 N OW0 S', 'B W EY1 N AH0 S'],
  "buenos-aires": ['B W EY2 N OW0 S EY1 R IY0 S', 'B W EY1 N AH0 S EY1 R IY0 S', 'B W EY2 N OW0 S EY1 R', 'B W EY1 N AH0 S EH1 R'],
  "buenrostro": ['B W EH0 N R OW1 S T R OW0'],
  "buentello": ['B UW0 N T EH1 L OW0'],
  "buer": ['B UW1 ER0'],
  "buerge": ['B Y UW1 R JH'],
  "buerger": ['B Y UW1 R G ER0'],
  "buerkle": ['B Y UW1 R K AH0 L'],
  "buerry": ['B EH1 R IY0'],
  "buescher": ['B Y UW1 SH ER0'],
  "buesing": ['B Y UW1 S IH0 NG'],
  "bueter": ['B Y UW1 T ER0'],
  "buetow": ['B UW1 T OW0'],
  "buettner": ['B Y UW1 T N ER0'],
  "bufano": ['B UW0 F AA1 N OW0'],
  "bufe": ['B Y UW1 F'],
  "bufete": ['B Y UW2 F IY1 T'],
  "buff": ['B AH1 F'],
  "buff's": ['B AH1 F S'],
  "buffa": ['B AH1 F AH0'],
  "buffalo": ['B AH1 F AH0 L OW2'],
  "buffalo's": ['B AH1 F AH0 L OW2 Z'],
  "buffalos": ['B AH1 F AH0 L OW2 Z'],
  "buffer": ['B AH1 F ER0'],
  "buffered": ['B AH1 F ER0 D'],
  "bufferin": ['B AH1 F ER0 IH0 N'],
  "buffering": ['B AH1 F ER0 IH0 NG'],
  "buffers": ['B AH1 F ER0 Z'],
  "buffet": ['B AH1 F AH0 T', 'B AH0 F EY1'],
  "buffeted": ['B AH0 F EY1 D', 'B AH1 F IH2 T IH0 D'],
  "buffeting": ['B AH0 F EY1 IH0 NG', 'B AH1 F AH0 T IH0 NG'],
  "buffets": ['B AH1 F AH0 T S', 'B AH0 F EY1 Z'],
  "buffett": ['B AH1 F IH0 T'],
  "buffett's": ['B AH1 F AH0 T S'],
  "buffin": ['B AH1 F IH0 N'],
  "buffington": ['B AH1 F IH0 NG T AH0 N'],
  "buffkin": ['B AH1 F K IH0 N'],
  "buffo": ['B UW1 F OW0'],
  "buffone": ['B UW0 F OW1 N IY0'],
  "buffoon": ['B AH0 F UW1 N'],
  "bufford": ['B AH1 F ER0 D'],
  "buffs": ['B AH1 F S'],
  "buffton": ['B AH1 F T AH0 N'],
  "buffum": ['B AH1 F AH0 M'],
  "buffy": ['B AH1 F IY0'],
  "bufkin": ['B AH1 F K IH0 N'],
  "buford": ['B Y UW1 F ER0 D'],
  "bug": ['B AH1 G'],
  "buga": ['B Y UW1 G AH0', 'B IY1 Y UW1 JH IY1 EY1'],
  "bugaboo": ['B AH1 G AH0 B UW2'],
  "bugai": ['B Y UW1 G AY0'],
  "bugai's": ['B Y UW1 G AY0 Z'],
  "bugaj": ['B UW1 G AH0 JH'],
  "bugarin": ['B Y UW1 G ER0 IH0 N'],
  "bugatti": ['B AH0 G AA1 T IY0'],
  "bugay": ['B Y UW1 G EY0'],
  "bugbee": ['B AH1 G B IY2'],
  "bugeye": ['B AH1 G AY2'],
  "bugeyed": ['B AH1 G AY2 D'],
  "bugg": ['B AH1 G'],
  "bugge": ['B AH1 G'],
  "bugged": ['B AH1 G D'],
  "bugger": ['B AH1 G ER0'],
  "buggers": ['B AH1 G ER0 Z'],
  "buggies": ['B AH1 G IY0 Z'],
  "bugging": ['B AH1 G IH0 NG'],
  "buggs": ['B AH1 G Z'],
  "buggy": ['B AH1 G IY0'],
  "bugh": ['B Y UW1 G'],
  "bugher": ['B Y UW1 G ER0'],
  "bugle": ['B Y UW1 G AH0 L'],
  "bugles": ['B Y UW1 G AH0 L Z'],
  "bugling": ['B Y UW1 G AH0 L IH0 NG', 'B Y UW1 G L IH0 NG'],
  "bugliosi": ['B UW0 G L IY0 OW1 S IY0'],
  "bugliosi's": ['B UW0 G L IY0 OW1 S IY0 Z'],
  "bugojno": ['B UW0 G OW1 ZH N OW0'],
  "bugs": ['B AH1 G Z'],
  "bugsy": ['B AH1 G Z IY0'],
  "buhl": ['B Y UW1 L'],
  "buhler": ['B UW1 L ER0'],
  "buhman": ['B AH1 M AH0 N'],
  "buhr": ['B Y UH1 R'],
  "buhrman": ['B UH1 R M AH0 N'],
  "buhrow": ['B UH1 R OW0'],
  "bui": ['B IH1', 'B W IY1'],
  "buice": ['B IH1 S', 'B W IY1 S'],
  "buick": ['B Y UW1 IH0 K'],
  "buick's": ['B Y UW1 IH0 K S'],
  "buicks": ['B Y UW1 IH0 K S'],
  "buie": ['B UW0 IY1', 'B W IY1'],
  "buikema": ['B IH0 K EY1 M AH0', 'B W IH0 K EY1 M AH0'],
  "buil": ['B Y UW1 AH0 L'],
  "build": ['B IH1 L D'],
  "build-up": ['B IH1 L D AH2 P'],
  "buildable": ['B IH1 L D AH0 B AH0 L'],
  "builder": ['B IH1 L D ER0'],
  "builder's": ['B IH1 L D ER0 Z'],
  "builders": ['B IH1 L D ER0 Z'],
  "builders'": ['B IH1 L D ER0 Z'],
  "building": ['B IH1 L D IH0 NG'],
  "building's": ['B IH1 L D IH0 NG Z'],
  "buildings": ['B IH1 L D IH0 NG Z'],
  "builds": ['B IH1 L D Z'],
  "buildup": ['B IH1 L D AH2 P'],
  "buildups": ['B IH1 L D AH2 P S'],
  "built": ['B IH1 L T'],
  "built-in": ['B IH1 L T IH2 N'],
  "builtin": ['B IH1 L T IH2 N'],
  "buis": ['B IH1 Z'],
  "buisson": ['B W IY2 S AA1 N'],
  "buist": ['B UW1 IH0 S T'],
  "buitoni": ['B Y UW0 T OW1 N IY0'],
  "buitrago": ['B IH0 T R AA1 G OW0'],
  "buitron": ['B IH1 T R AH0 N'],
  "bujak": ['B UW1 Y AH0 K'],
  "bujumbura": ['B UW0 JH AH0 M B UH1 R AH0'],
  "bukar": ['B Y UW1 K AA0 R'],
  "bukavu": ['B UW0 K AA1 V UW2'],
  "bukavu's": ['B UW0 K AA1 V UW2 Z'],
  "buker": ['B Y UW1 K ER0'],
  "bukharin": ['B AH1 K HH ER0 IH0 N'],
  "bukkake": ['B UW2 K AA1 K EH2'],
  "bukovina": ['B UW2 K AO0 V IH1 N AA0'],
  "bukovsky": ['B UW0 K AA1 V S K IY0'],
  "bukowski": ['B Y UW0 K AO1 F S K IY0'],
  "bula": ['B Y UW1 L AH0'],
  "bulat": ['B UW1 L AH0 T'],
  "bulb": ['B AH1 L B'],
  "bulbous": ['B AH1 L B AH0 S'],
  "bulbs": ['B AH1 L B Z'],
  "bulemia": ['B AH0 L IY1 M IY0 AH0'],
  "bulemic": ['B AH0 L IY1 M IH0 K'],
  "bulen": ['B AH1 L AH0 N'],
  "bulent": ['B Y UW1 L AH0 N T'],
  "buley": ['B Y UW1 L IY0'],
  "bulfinch": ['B UH1 L F IH2 N CH'],
  "bulfinch's": ['B UH1 L F IH2 N CH IH0 Z'],
  "bulgaria": ['B AH0 L G EH1 R IY0 AH0'],
  "bulgaria's": ['B AH0 L G EH1 R IY0 AH0 Z'],
  "bulgarian": ['B AH0 L G EH1 R IY0 AH0 N'],
  "bulgarians": ['B AH0 L G EH1 R IY0 AH0 N Z'],
  "bulge": ['B AH1 L JH'],
  "bulged": ['B AH1 L JH D'],
  "bulger": ['B AH1 L G ER0'],
  "bulges": ['B AH1 L JH IH0 Z'],
  "bulging": ['B AH1 L JH IH0 NG'],
  "bulgrin": ['B UH1 L G R IH0 N'],
  "bulimia": ['B Y UW0 L IH1 M IY0 AH0'],
  "bulimic": ['B Y UW0 L IH1 M IH0 K', 'B UW0 L IH1 M IH0 K'],
  "bulin": ['B Y UW1 L IH0 N'],
  "bulk": ['B AH1 L K'],
  "bulkeley": ['B UH1 L K AH0 L IY0'],
  "bulkhead": ['B AH1 L K HH EH2 D'],
  "bulkheads": ['B AH1 L K HH EH2 D Z'],
  "bulkier": ['B AH1 L K IY0 ER0'],
  "bulkley": ['B AH1 L K L IY0'],
  "bulky": ['B AH1 L K IY0'],
  "bull": ['B UH1 L'],
  "bull's": ['B UH1 L Z'],
  "bulla": ['B UH1 L AH0'],
  "bullard": ['B UH1 L ER0 D'],
  "bulldog": ['B UH1 L D AO2 G'],
  "bulldogs": ['B UH1 L D AO2 G Z'],
  "bulldoze": ['B UH1 L D OW2 Z'],
  "bulldozed": ['B UH1 L D OW2 Z D'],
  "bulldozer": ['B UH1 L D OW2 Z ER0'],
  "bulldozers": ['B UH1 L D OW2 Z ER0 Z'],
  "bulldozes": ['B UH1 L D OW2 Z IH0 Z'],
  "bulldozing": ['B UH1 L D OW2 Z IH0 NG'],
  "bullen": ['B UH1 L AH0 N'],
  "buller": ['B UH1 L ER0'],
  "bullet": ['B UH1 L AH0 T'],
  "bulletin": ['B UH1 L IH0 T AH0 N'],
  "bulletins": ['B UH1 L AH0 T AH0 N Z'],
  "bulletproof": ['B UH1 L AH0 T P R UW2 F'],
  "bullets": ['B UH1 L AH0 T S'],
  "bullfight": ['B UH1 L F AY2 T'],
  "bullfighter": ['B UH1 L F AY2 T ER0'],
  "bullfighting": ['B UH1 L F AY2 T IH0 NG'],
  "bullfights": ['B UH1 L F AY2 T Z'],
  "bullfrog": ['B UH1 L F R AO2 G'],
  "bullfrogs": ['B UH1 L F R AO2 G Z'],
  "bullhead": ['B UH1 L HH EH2 D'],
  "bullheads": ['B UH1 L HH EH2 D Z'],
  "bullhorn": ['B UH1 L HH AO2 R N'],
  "bullhorns": ['B UH1 L HH AO2 R N Z'],
  "bullied": ['B UH1 L IY0 D'],
  "bullies": ['B UH1 L IY0 Z'],
  "bullinger": ['B UH1 L IH0 NG ER0'],
  "bullington": ['B UH1 L IH0 NG T AH0 N'],
  "bullins": ['B UH1 L IH0 N Z'],
  "bullion": ['B UH1 L Y AH0 N'],
  "bullis": ['B UH1 L IH0 S'],
  "bullish": ['B UH1 L IH0 SH'],
  "bullishly": ['B UH1 L IH0 SH L IY0'],
  "bullishness": ['B UH1 L IH0 SH N AH0 S'],
  "bullitt": ['B UW1 L IH0 T'],
  "bullman": ['B UH1 L M AH0 N'],
  "bulloch": ['B UH1 L AH0 K'],
  "bullock": ['B UH1 L AH0 K'],
  "bullock's": ['B UH1 L AH0 K S'],
  "bullocks": ['B UH1 L AH0 K S'],
  "bullpen": ['B UH1 L P EH2 N'],
  "bulls": ['B UH1 L Z'],
  "bulls'": ['B UH1 L Z'],
  "bullseye": ['B UW1 L Z AY0'],
  "bullshit": ['B UH1 L SH IH2 T'],
  "bullshitter": ['B UH1 L SH IH2 T ER0'],
  "bullshitting": ['B UH1 L SH IH2 T IH0 NG'],
  "bulluck": ['B UH1 L AH0 K'],
  "bullwinkle": ['B UH1 L W IH2 NG K AH0 L'],
  "bully": ['B UH1 L IY0'],
  "bullying": ['B UH1 L IY0 IH0 NG'],
  "bulman": ['B AH1 L M AH0 N'],
  "bulmer": ['B AH1 L M ER0'],
  "bulova": ['B Y UW0 L OW1 V AH0'],
  "bulow": ['B Y UW1 L OW0'],
  "bulrush": ['B UH1 L R AH0 SH'],
  "bulson": ['B UH1 L S AH0 N'],
  "bult": ['B AH1 L T'],
  "bultema": ['B UW0 L T EH1 M AH0'],
  "bulthuis": ['B AH1 L DH UW0 IH0 Z'],
  "bultman": ['B UH1 L T M AH0 N'],
  "bulwark": ['B UH1 L W ER0 K'],
  "bulwinkle": ['B UH1 L W IH2 NG K AH0 L'],
  "bum": ['B AH1 M'],
  "bumann": ['B Y UW1 M AH0 N'],
  "bumbalough": ['B AH1 M B AH0 L AW0'],
  "bumbarger": ['B AH1 M B AA2 R G ER0'],
  "bumbaugh": ['B AH1 M B AO2'],
  "bumble": ['B AH1 M B AH0 L'],
  "bumblebee": ['B AH1 M B AH0 L B IY2'],
  "bumblebees": ['B AH1 M B AH0 L B IY2 Z'],
  "bumbling": ['B AH1 M B AH0 L IH0 NG', 'B AH1 M B L IH0 NG'],
  "bumbly": ['B AH1 M B L IY0'],
  "bumgardner": ['B AH1 M G AA2 R D N ER0'],
  "bumgarner": ['B AH1 M G AA2 R N ER0'],
  "bumiputra": ['B UW2 M IY0 P Y UW1 T R AH0'],
  "bummed": ['B AH1 M D'],
  "bummer": ['B AH1 M ER0'],
  "bump": ['B AH1 M P'],
  "bumpas": ['B AH1 M P AH0 Z'],
  "bumpass": ['B AH1 M P AE2 S'],
  "bumped": ['B AH1 M P T'],
  "bumper": ['B AH1 M P ER0'],
  "bumpers": ['B AH1 M P ER0 Z'],
  "bumpersticker": ['B AH1 M P ER0 S T IH0 K ER0'],
  "bumperstickers": ['B AH1 M P ER0 S T IH0 K ER0 Z'],
  "bumping": ['B AH1 M P IH0 NG'],
  "bumpings": ['B AH1 M P IH0 NG Z'],
  "bumpkin": ['B AH1 M P K IH0 N'],
  "bumps": ['B AH1 M P S'],
  "bumpus": ['B AH1 M P AH0 S'],
  "bumpy": ['B AH1 M P IY0'],
  "bums": ['B AH1 M Z'],
  "bumstead": ['B AH1 M S T EH2 D'],
  "bun": ['B AH1 N'],
  "bunce": ['B AH1 N S'],
  "bunch": ['B AH1 N CH'],
  "bunche": ['B AH1 N CH'],
  "bunched": ['B AH1 N CH T'],
  "bunches": ['B AH1 N CH AH0 Z', 'B AH1 N CH IH0 Z'],
  "bunching": ['B AH1 N CH IH0 NG'],
  "bunchy": ['B AH1 N CH IY0'],
  "bund": ['B AH1 N D'],
  "bunda": ['B AH1 N D AH0'],
  "bunde": ['B AH1 N D'],
  "bundesbank": ['B UH1 N D IH0 S B AE2 NG K', 'B AA1 N D IH0 S B AE2 NG K', 'B UH1 N D IH0 S B AA2 NG K'],
  "bundesbank's": ['B UH1 N D IH0 S B AE2 NG K S'],
  "bundespost": ['B UH1 N D IH0 S P OW2 S T'],
  "bundespost's": ['B UH1 N D IH0 S P OW2 S T S'],
  "bundesrat": ['B UH1 N D IH0 S R AE2 T'],
  "bundestag": ['B AH1 N D AH0 S T AE2 G'],
  "bundeswehr": ['B AH1 N D AH0 S W EH2 R'],
  "bundick": ['B AH1 N D IH0 K'],
  "bundle": ['B AH1 N D AH0 L'],
  "bundled": ['B AH1 N D AH0 L D'],
  "bundles": ['B AH1 N D AH0 L Z'],
  "bundling": ['B AH1 N D AH0 L IH0 NG', 'B AH1 N D L IH0 NG'],
  "bundren": ['B AH1 N D ER0 AH0 N'],
  "bundrick": ['B AH1 N D R IH0 K'],
  "bunds": ['B AH1 N D Z'],
  "bundschuh": ['B AH1 N D SH UW0'],
  "bundy": ['B AH1 N D IY0'],
  "bundy's": ['B AH1 N D IY0 Z'],
  "bundys": ['B AH1 N D IY0 Z'],
  "bung": ['B AH1 NG'],
  "bungalow": ['B AH1 NG G AH0 L OW2'],
  "bungalows": ['B AH1 NG G AH0 L OW2 Z'],
  "bungard": ['B AH1 NG G ER0 D'],
  "bunge": ['B AH1 N JH'],
  "bungee": ['B AH1 N JH IY0'],
  "bunger": ['B AH1 NG ER0'],
  "bungert": ['B AH1 NG G ER0 T'],
  "bungey": ['B AH1 N JH IY0'],
  "bungle": ['B AH1 NG G AH0 L'],
  "bungled": ['B AH1 NG G AH0 L D'],
  "bungler": ['B AH1 NG G L ER0'],
  "bunglers": ['B AH1 NG G L ER0 Z'],
  "bungling": ['B AH1 NG G AH0 L IH0 NG', 'B AH1 NG G L IH0 NG'],
  "bunk": ['B AH1 NG K'],
  "bunke": ['B AH1 NG K'],
  "bunker": ['B AH1 NG K ER0'],
  "bunkers": ['B AH1 NG K ER0 Z'],
  "bunkley": ['B AH1 NG K L IY0'],
  "bunks": ['B AH1 NG K S'],
  "bunn": ['B AH1 N'],
  "bunnell": ['B AH1 N AH0 L'],
  "bunner": ['B AH1 N ER0'],
  "bunney": ['B AH1 N IY0'],
  "bunni": ['B UW1 N IY0'],
  "bunnie": ['B AH1 N IY0'],
  "bunnies": ['B AH1 N IY0 Z'],
  "bunning": ['B AH1 N IH0 NG'],
  "bunny": ['B AH1 N IY0'],
  "bunol": ['B Y UW1 N OW0 L'],
  "bunowski": ['B UW2 N AW1 S K IY0'],
  "buns": ['B AH1 N Z'],
  "bunt": ['B AH1 N T'],
  "bunt's": ['B AH1 N T S'],
  "buntain": ['B AH0 N T EY1 N'],
  "bunte": ['B AH1 N T'],
  "bunten": ['B AH1 N T AH0 N'],
  "buntin": ['B AH1 N T IH0 N'],
  "bunting": ['B AH1 N T IH0 NG'],
  "bunton": ['B AH1 N T AH0 N'],
  "buntrock": ['B AH1 N T R AA2 K'],
  "buntstrock": ['B AH1 N T S T R AA2 K'],
  "buntyn": ['B AH1 N T IH0 N'],
  "buntz": ['B AH1 N T S'],
  "bunyan": ['B AH1 N Y AH0 N'],
  "bunyard": ['B AH0 N Y AA1 R D'],
  "bunzl": ['B AH1 N Z AH0 L'],
  "buol": ['B OW1 L'],
  "buonanno": ['B W OW0 N AA1 N OW0'],
  "buonicontis": ['B W AA2 N IH0 K AA1 N T IY0 Z'],
  "buono": ['B W OW1 N OW0'],
  "buonocore": ['B W OW0 N OW0 K AO1 R IY0'],
  "buonomo": ['B W OW0 N OW1 M OW0'],
  "buoy": ['B UW1 IY0'],
  "buoyancy": ['B OY1 AH0 N S IY0'],
  "buoyant": ['B OY1 AH0 N T'],
  "buoyed": ['B UW1 IY0 D'],
  "buoying": ['B OY1 IH0 NG'],
  "buoys": ['B UW1 IY0 Z'],
  "bupkus": ['B AH1 P K AH0 S'],
  "bupp": ['B AH1 P'],
  "buprenorphine": ['B Y UW1 P R AH0 N ER0 F IY2 N'],
  "bur": ['B ER1'],
  "burack": ['B Y UW1 R AH0 K'],
  "burak": ['B Y UW1 R AH0 K'],
  "buran": ['B Y UW1 R AH0 N'],
  "burandt": ['B Y UW1 R AH0 N T'],
  "buras": ['B Y UW1 R AH0 Z'],
  "burba": ['B ER1 B AH0'],
  "burbach": ['B ER1 B AA0 K'],
  "burback": ['B ER1 B AE0 K'],
  "burbage": ['B ER1 B IH0 JH'],
  "burbank": ['B ER1 B AE2 NG K'],
  "burbank's": ['B ER1 B AE0 NG K S'],
  "burbidge": ['B ER1 B IH0 JH'],
  "burble": ['B ER1 B AH0 L'],
  "burbled": ['B ER1 B AH0 L D'],
  "burbles": ['B ER1 B AH0 L Z'],
  "burbling": ['B ER1 B L IH0 NG'],
  "burbridge": ['B ER1 B R IH0 JH'],
  "burby": ['B ER1 B IY0'],
  "burch": ['B ER1 CH'],
  "burcham": ['B ER0 SH AE1 M'],
  "burchard": ['B ER0 SH AA1 R D'],
  "burchell": ['B ER1 K AH0 L'],
  "burcher": ['B ER1 CH ER0'],
  "burchett": ['B ER1 CH IH0 T'],
  "burchette": ['B ER0 SH EH1 T'],
  "burchfield": ['B ER1 CH F IY0 L D'],
  "burchill": ['B ER1 CH IH0 L'],
  "burciaga": ['B UH0 R S IY0 AA1 G AH0'],
  "burck": ['B ER1 K'],
  "burckhard": ['B ER1 K HH ER0 D'],
  "burckhardt": ['B ER1 K HH AA0 R T'],
  "burd": ['B ER1 D'],
  "burda": ['B UH1 R D AH0'],
  "burdell": ['B ER0 D EH1 L'],
  "burden": ['B ER1 D AH0 N'],
  "burdened": ['B ER1 D AH0 N D'],
  "burdening": ['B ER1 D AH0 N IH0 NG'],
  "burdens": ['B ER1 D AH0 N Z'],
  "burdensome": ['B ER1 D AH0 N S AH0 M'],
  "burdett": ['B ER1 D IH0 T'],
  "burdette": ['B ER0 D EH1 T'],
  "burdge": ['B ER1 JH'],
  "burdi": ['B UH1 R D IY0'],
  "burdick": ['B ER1 D IH0 K'],
  "burdin": ['B ER1 D IH0 N'],
  "burdine": ['B ER0 D IY1 N', 'B ER0 D AY1 N'],
  "burdine's": ['B ER0 D IY1 N Z', 'B ER0 D AY1 N Z'],
  "burdines": ['B ER0 D IY1 N Z', 'B ER0 D AY1 N Z'],
  "burditt": ['B ER0 D IH1 T'],
  "burdo": ['B UH1 R D OW0'],
  "burdon": ['B ER1 D AH0 N'],
  "burdsall": ['B ER1 D S AH0 L'],
  "bureau": ['B Y UH1 R OW0'],
  "bureau's": ['B Y UH1 R OW0 Z'],
  "bureaucracies": ['B Y UH0 R AA1 K R AH0 S IY0 Z'],
  "bureaucracy": ['B Y UH0 R AA1 K R AH0 S IY0'],
  "bureaucracy's": ['B Y UH0 R AA1 K R AH0 S IY0 Z'],
  "bureaucrat": ['B Y UH1 R AH0 K R AE2 T', 'B Y UH1 R OW0 K R AE2 T'],
  "bureaucratese": ['B Y UH1 R AH0 K R AE0 T IY2 Z'],
  "bureaucratic": ['B Y UH2 R AH0 K R AE1 T IH0 K'],
  "bureaucrats": ['B Y UH1 R AH0 K R AE2 T S', 'B Y UH1 R OW0 K R AE2 T S'],
  "bureaus": ['B Y UH1 R OW0 Z'],
  "burek": ['B EH1 R IH0 K'],
  "burel": ['B EH1 R AH0 L'],
  "burell": ['B EH1 R AH0 L'],
  "buren": ['B Y UH1 R AH0 N'],
  "bures": ['B EH1 R Z', 'B EH1 R IH0 Z'],
  "buresh": ['B EH1 R IH0 SH'],
  "burfeind": ['B ER1 F AY0 N D'],
  "burfield": ['B ER1 F IY0 L D'],
  "burford": ['B ER1 F ER0 D'],
  "burg": ['B ER1 G'],
  "burgamy": ['B ER1 G AH0 M IY0'],
  "burgan": ['B ER1 G AE0 N'],
  "burgard": ['B ER1 G ER0 D'],
  "burgdorf": ['B ER1 G D AO0 R F'],
  "burge": ['B ER1 G'],
  "burgee": ['B ER1 G IY0'],
  "burgener": ['B ER1 G IY0 N ER0'],
  "burgeon": ['B ER1 JH AH0 N'],
  "burgeoned": ['B ER1 JH AH0 N D'],
  "burgeoning": ['B ER1 JH AH0 N IH0 NG'],
  "burger": ['B ER1 G ER0'],
  "burger's": ['B ER1 G ER0 Z'],
  "burgers": ['B ER1 G ER0 Z'],
  "burgert": ['B ER1 G ER0 T'],
  "burges": ['B ER1 G Z'],
  "burgeson": ['B ER1 G IH0 S AH0 N'],
  "burgess": ['B ER1 JH AH0 S'],
  "burget": ['B ER1 G EH0 T'],
  "burgett": ['B ER1 JH IH0 T'],
  "burggraf": ['B ER1 G R AH0 F'],
  "burghardt": ['B ER1 G AA0 R T'],
  "burghart": ['B ER1 G HH AA0 R T'],
  "burgher": ['B ER1 G ER0'],
  "burghley": ['B ER1 G L IY0'],
  "burgin": ['B ER1 G IH0 N'],
  "burgio": ['B ER1 G IY0 OW0'],
  "burglar": ['B ER1 G L ER0'],
  "burglaries": ['B ER1 G L ER0 IY0 Z'],
  "burglarize": ['B ER1 G L ER0 AY2 Z'],
  "burglarized": ['B ER1 G L ER0 AY2 Z D'],
  "burglars": ['B ER1 G L ER0 Z'],
  "burglary": ['B ER1 G L ER0 IY0'],
  "burgling": ['B ER1 G L IH0 NG'],
  "burgman": ['B ER1 G M AH0 N'],
  "burgmaster": ['B ER1 G M AE2 S T ER0'],
  "burgner": ['B ER1 G N ER0'],
  "burgo": ['B ER1 G OW0'],
  "burgomaster": ['B ER1 G AH0 M AE2 S T ER0'],
  "burgomaster's": ['B ER1 G AH0 M AE2 S T ER0 Z'],
  "burgomasters": ['B ER1 G AH0 M AE2 S T ER0 Z'],
  "burgoon": ['B ER1 G UW0 N'],
  "burgos": ['B ER1 G OW0 Z'],
  "burgoyne": ['B ER0 G OY1 N'],
  "burgoyne's": ['B ER0 G OY1 N Z'],
  "burgundian": ['B ER0 G AH1 N D IY0 AH0 N'],
  "burgundians": ['B ER0 G AH1 N D IY0 AH0 N Z'],
  "burgundies": ['B ER1 G AH0 N D IY0 Z'],
  "burgundy": ['B ER1 G AH0 N D IY0'],
  "burgundy's": ['B ER1 G AH0 N D IY0 Z'],
  "burgy": ['B ER1 JH IY0'],
  "burham": ['B ER1 HH AH0 M'],
  "burhans": ['B ER1 HH AH0 N Z'],
  "buri": ['B UH1 R IY0'],
  "burial": ['B EH1 R IY0 AH0 L'],
  "burials": ['B EH1 R IY0 AH0 L Z'],
  "burian": ['B Y UH1 R IY0 AH0 N'],
  "burich": ['B EH1 R IH0 K'],
  "buried": ['B EH1 R IY0 D'],
  "buries": ['B EH1 R IY0 Z'],
  "burk": ['B ER1 K'],
  "burkard": ['B ER1 K ER0 D'],
  "burkart": ['B ER1 K AA0 R T'],
  "burke": ['B ER1 K'],
  "burke's": ['B ER1 K S'],
  "burkeen": ['B ER0 K IY1 N'],
  "burkel": ['B ER1 K AH0 L'],
  "burkemper": ['B ER1 K IH0 M P ER0'],
  "burkert": ['B ER1 K ER0 T'],
  "burkes": ['B ER1 K S'],
  "burket": ['B ER1 K IH0 T'],
  "burkett": ['B ER1 K IH0 T'],
  "burkey": ['B ER1 K IY0'],
  "burkhalter": ['B ER1 K HH AH0 L T ER0'],
  "burkhammer": ['B ER1 K HH AH0 M ER0'],
  "burkhard": ['B ER1 K HH ER0 D'],
  "burkhardt": ['B ER1 K HH AA0 R T'],
  "burkhart": ['B ER1 K HH AA0 R T'],
  "burkhead": ['B ER1 K HH EH0 D'],
  "burkholder": ['B ER1 K HH OW0 L D ER0'],
  "burkina": ['B ER0 K IY1 N AH0'],
  "burkina's": ['B ER0 K IY1 N AH0 Z'],
  "burkina-faso": ['B ER0 K IY2 N AH0 F AA1 S OW0'],
  "burkina-faso's": ['B ER0 K IY2 N AH0 F AA1 S OW0 Z'],
  "burkins": ['B ER1 K IH0 N Z'],
  "burkitt": ['B ER1 K IH0 T'],
  "burkland": ['B ER1 K L AH0 N D'],
  "burkle": ['B ER1 K AH0 L'],
  "burkley": ['B ER1 K L IY0'],
  "burklow": ['B ER1 K L AW0'],
  "burklund": ['B ER1 K L AH0 N D'],
  "burkman": ['B ER1 K M AH0 N'],
  "burks": ['B ER1 K S'],
  "burkus": ['B ER1 K AH0 S'],
  "burl": ['B ER1 L'],
  "burland": ['B ER1 L AH0 N D'],
  "burlap": ['B ER1 L AE2 P'],
  "burlapped": ['B ER1 L AE2 P T'],
  "burlatsky": ['B ER0 L AE1 T S K IY0'],
  "burleigh": ['B ER1 L AH0'],
  "burleson": ['B ER1 L IH0 S AH0 N', 'B ER1 L S AH0 N'],
  "burlesque": ['B ER0 L EH1 S K'],
  "burlew": ['B ER1 L UW0'],
  "burley": ['B ER1 L IY0'],
  "burling": ['B ER1 L IH0 NG'],
  "burlingame": ['B ER1 L IH0 NG G EY2 M'],
  "burlingham": ['B ER1 L IH0 NG HH AE2 M'],
  "burlington": ['B ER1 L IH0 NG T AH0 N'],
  "burlington's": ['B ER1 L IH0 NG T AH0 N Z'],
  "burlison": ['B ER1 L IH0 S AH0 N'],
  "burly": ['B ER1 L IY0'],
  "burma": ['B ER1 M AH0'],
  "burma's": ['B ER1 M AH0 Z'],
  "burmah": ['B ER1 M AH0'],
  "burman": ['B ER1 M AH0 N'],
  "burmans": ['B ER1 M AH0 N Z'],
  "burmaster": ['B ER1 M AE0 S T ER0'],
  "burmeister": ['B ER1 M AY0 S T ER0'],
  "burmese": ['B ER0 M IY1 Z'],
  "burmester": ['B ER1 M IH0 S T ER0'],
  "burn": ['B ER1 N'],
  "burnable": ['B ER1 N AH0 B AH0 L'],
  "burnaby": ['B ER1 N AH0 B IY0'],
  "burnam": ['B ER1 N AH0 M'],
  "burnap": ['B ER1 N AH0 P'],
  "burnard": ['B ER0 N AA1 R D'],
  "burndy": ['B ER1 N D IY0'],
  "burne": ['B ER1 N'],
  "burned": ['B ER1 N D'],
  "burnell": ['B ER1 N AH0 L'],
  "burner": ['B ER1 N ER0'],
  "burners": ['B ER1 N ER0 Z'],
  "burnes": ['B ER1 N Z'],
  "burness": ['B ER1 N AH0 S'],
  "burnet": ['B ER1 N IH0 T'],
  "burnett": ['B ER0 N EH1 T'],
  "burnett's": ['B ER0 N EH1 T S'],
  "burnette": ['B ER1 N EH1 T'],
  "burney": ['B ER1 N IY0'],
  "burnham": ['B ER1 N AH0 M', 'B ER1 N HH AE0 M'],
  "burnham's": ['B ER1 N AH0 M Z', 'B ER1 N HH AE0 M Z'],
  "burning": ['B ER1 N IH0 NG'],
  "burningham": ['B ER1 N IH0 NG HH AE2 M'],
  "burnings": ['B ER1 N IH0 NG Z'],
  "burnish": ['B ER1 N IH0 SH'],
  "burnished": ['B ER1 N IH0 SH T'],
  "burnley": ['B ER1 N L IY0'],
  "burnley's": ['B ER1 N L IY0 Z'],
  "burnout": ['B ER1 N AW2 T'],
  "burns": ['B ER1 N Z'],
  "burns'": ['B ER1 N Z'],
  "burns's": ['B ER1 N Z IH0 Z'],
  "burnsed": ['B ER1 N Z D'],
  "burnside": ['B ER1 N S AY2 D'],
  "burnside's": ['B ER1 N S AY2 D Z'],
  "burnstein": ['B ER1 N S T AY2 N', 'B ER1 N S T IY2 N'],
  "burnsworth": ['B ER1 N Z W ER2 TH'],
  "burnt": ['B ER1 N T'],
  "burnup": ['B ER1 N AH2 P'],
  "burnworth": ['B ER1 N W ER2 TH'],
  "buroker": ['B Y UW1 R AH0 K ER0'],
  "burow": ['B Y UH1 R OW0'],
  "burp": ['B ER1 P'],
  "burpee": ['B ER1 P IY0'],
  "burpees": ['B ER1 P IY0 Z'],
  "burping": ['B ER1 P IH0 NG'],
  "burpo": ['B UH1 R P OW0'],
  "burr": ['B ER1'],
  "burrage": ['B ER1 IH0 JH'],
  "burrell": ['B ER0 EH1 L'],
  "burrell's": ['B ER0 EH1 L Z'],
  "burrer": ['B ER1 ER0'],
  "burres": ['B ER1 Z'],
  "burress": ['B ER1 AH0 S'],
  "burri": ['B UH1 R IY0'],
  "burridge": ['B AO1 R IH0 JH'],
  "burrier": ['B ER1 IY0 ER0'],
  "burright": ['B AO1 R AY0 T'],
  "burrill": ['B AO1 R AH0 L'],
  "burrington": ['B ER1 IH0 NG T AH0 N'],
  "burris": ['B ER1 IH0 S'],
  "burriss": ['B ER1 IH0 S IH0 Z'],
  "burrito": ['B ER0 IY1 T OW0'],
  "burritos": ['B ER0 IY1 T OW0 S'],
  "burritt": ['B ER1 IH0 T'],
  "burro": ['B ER1 OW0'],
  "burrola": ['B UH0 R OW1 L AH0'],
  "burros": ['B ER1 OW0 Z'],
  "burrough": ['B ER1 OW0'],
  "burroughs": ['B AH1 R OW0 Z', 'B ER1 OW0 Z'],
  "burrous": ['B ER1 AH0 S'],
  "burrow": ['B ER1 OW0'],
  "burrower": ['B ER1 OW0 ER0'],
  "burrowers": ['B ER1 OW0 ER0 Z'],
  "burrowes": ['B ER1 OW2 Z'],
  "burrowing": ['B ER1 OW0 IH0 NG'],
  "burrows": ['B ER1 OW0 Z'],
  "burrus": ['B UH1 R AH0 S'],
  "burruss": ['B UH1 R AH0 S'],
  "burry": ['B ER1 IY0'],
  "bursch": ['B ER1 SH'],
  "burse": ['B ER1 S'],
  "bursey": ['B ER1 S IY0'],
  "bursley": ['B ER1 S L IY0'],
  "burson": ['B ER1 S AH0 N'],
  "burst": ['B ER1 S T'],
  "burstein": ['B ER1 S T AY0 N', 'B ER1 S T IY0 N'],
  "bursting": ['B ER1 S T IH0 NG'],
  "burston": ['B ER1 S T AH0 N'],
  "bursts": ['B ER1 S T S'],
  "burt": ['B ER1 T'],
  "burtch": ['B ER1 CH'],
  "burtis": ['B ER1 T IH0 S'],
  "burtner": ['B ER1 T N ER0'],
  "burtness": ['B ER1 T N IH0 S'],
  "burtnett": ['B ER1 T N IH0 T'],
  "burton": ['B ER1 T AH0 N'],
  "burton's": ['B ER1 T AH0 N Z'],
  "burts": ['B ER1 T S'],
  "burtt": ['B ER1 T'],
  "burundi": ['B ER0 AH1 N D IY0'],
  "burundi's": ['B ER0 AH1 N D IY0 Z'],
  "burwell": ['B ER1 W EH0 L'],
  "burwick": ['B ER1 W IH0 K'],
  "bury": ['B EH1 R IY0'],
  "bury's": ['B EH1 R IY0 Z'],
  "burying": ['B EH1 R IY0 IH0 NG'],
  "burzynski": ['B ER0 Z IH1 N S K IY0'],
  "bus": ['B AH1 S'],
  "busa": ['B Y UW1 S AH0'],
  "busalacchi": ['B UW0 S AA0 L AA1 K IY0'],
  "busam": ['B IH1 S AH0 M'],
  "busbee": ['B AH1 S B IY2'],
  "busbey": ['B AH1 S B IY0'],
  "busbin": ['B AH1 S B IH0 N'],
  "busboom": ['B AH1 S B UW2 M'],
  "busboy": ['B AH1 S B OY2'],
  "busboys": ['B AH1 S B OY2 Z'],
  "busby": ['B AH1 Z B IY0'],
  "buscaglia": ['B UW0 S K AA1 G L IY0 AH0'],
  "buscemi": ['B UW0 S CH EH1 M IY0'],
  "busch": ['B UH1 SH'],
  "busch's": ['B UH1 SH IH0 Z'],
  "buschbaum": ['B UH1 SH B AW2 M'],
  "busche": ['B AH1 SH'],
  "buscher": ['B UW1 SH ER0'],
  "buschman": ['B AH1 SH M AH0 N'],
  "buschmann": ['B AH1 SH M AH0 N'],
  "buse": ['B Y UW1 Z'],
  "bused": ['B AH1 S T'],
  "buseman": ['B IH1 S M AH0 N'],
  "busenbark": ['B IH1 S IH0 N B AA0 R K'],
  "buser": ['B IH1 S ER0'],
  "buses": ['B AH1 S IH0 Z'],
  "busey": ['B Y UW1 Z IY0', 'B AH1 S IY0'],
  "bush": ['B UH1 SH'],
  "bush's": ['B UH1 SH IH0 Z'],
  "busha": ['B AH1 SH AH0'],
  "bushard": ['B UH1 SH ER0 D'],
  "bushart": ['B UH1 SH AA0 R T'],
  "bushaw": ['B UH1 SH AO0'],
  "bushby": ['B UH1 SH B IY0'],
  "bushee": ['B UH1 SH IY1'],
  "bushel": ['B UH1 SH AH0 L'],
  "bushell": ['B UH1 SH AH0 L'],
  "bushels": ['B UH1 SH AH0 L Z'],
  "busher": ['B UH1 SH ER0'],
  "bushes": ['B UH1 SH AH0 Z'],
  "bushey": ['B UH1 SH IY0'],
  "bushings": ['B UH1 SH IH0 NG Z'],
  "bushkin": ['B UH1 SH K IH2 N'],
  "bushman": ['B UH1 SH M AH0 N'],
  "bushmen": ['B UH1 SH M EH0 N'],
  "bushnell": ['B UH1 SH N AH0 L'],
  "bushong": ['B UH1 SH AO0 NG'],
  "bushway": ['B UH1 SH W EY2'],
  "bushwhack": ['B UH1 SH W AE2 K'],
  "bushwhacked": ['B UH1 SH W AE2 K T'],
  "bushwhacking": ['B UH1 SH W AE2 K IH0 NG'],
  "bushwhacks": ['B UH1 SH W AE2 K S'],
  "bushy": ['B UH1 SH IY0'],
  "busic": ['B AH1 S IH0 K'],
  "busick": ['B IH1 S IH0 K'],
  "busied": ['B IH1 Z IY0 D'],
  "busier": ['B IH1 Z IY0 ER0'],
  "busiest": ['B IH1 Z IY0 AH0 S T'],
  "busily": ['B IH1 Z AH0 L IY0'],
  "business": ['B IH1 Z N AH0 S', 'B IH1 Z N IH0 S'],
  "business'": ['B IH1 Z N IH0 S', 'B IH1 Z N AH0 S'],
  "business's": ['B IH1 Z N IH0 S IH0 Z'],
  "businesses": ['B IH1 Z N AH0 S AH0 Z', 'B IH1 Z N IH0 S IH0 Z'],
  "businesses'": ['B IH1 Z N EH2 S IH0 Z'],
  "businessland": ['B IH1 Z N IH0 S L AE2 N D'],
  "businesslike": ['B IH1 Z N IH0 S L AY2 K'],
  "businessman": ['B IH1 Z N AH0 S M AE2 N', 'B IH1 Z N IH0 S M AE2 N'],
  "businessman's": ['B IH1 Z N IH0 S M AE2 N Z'],
  "businessmen": ['B IH1 Z N IH0 S M EH2 N'],
  "businesspeople": ['B IH1 Z N AH0 S P IY1 P AH0 L'],
  "businessperson": ['B IH1 Z N AH0 S P ER1 S AH0 N'],
  "businessphone": ['B IH1 Z N AH0 S F OW2 N'],
  "businessphones": ['B IH1 Z N AH0 S F OW2 N Z'],
  "businesswoman": ['B IH1 Z N IH0 S W UH2 M AH0 N'],
  "businesswomen": ['B IH1 Z N AH0 S W OW1 M AH0 N'],
  "busing": ['B AH1 S IH0 NG'],
  "busk": ['B AH1 S K'],
  "buske": ['B AH1 S K'],
  "busker": ['B AH1 S K ER0'],
  "busker's": ['B AH1 S K ER0 Z'],
  "buskey": ['B AH1 S K IY2'],
  "buskirk": ['B AH1 S K ER0 K'],
  "buslease": ['B AH1 S L IY2 S'],
  "busler": ['B AH1 S AH0 L ER0', 'B AH1 S L ER0'],
  "busload": ['B AH0 S L OW1 D'],
  "busloads": ['B AH0 S L OW1 D Z'],
  "buspar": ['B AH1 S P ER0'],
  "buss": ['B AH1 S'],
  "bussa": ['B UW1 S AH0'],
  "bussard": ['B AH1 S ER0 D'],
  "busse": ['B AH1 S'],
  "bussed": ['B AH1 S T'],
  "bussell": ['B AH1 S AH0 L'],
  "bussen": ['B AH1 S AH0 N'],
  "busser": ['B AH1 S ER0'],
  "bussert": ['B AH1 S ER0 T'],
  "busses": ['B AH1 S AH0 Z'],
  "bussey": ['B AH1 S IY0'],
  "bussi": ['B AH1 S IY0', 'B Y UW1 S IY0'],
  "bussie": ['B AH1 S IY0', 'B Y UW1 S IY0'],
  "bussiere": ['B AH1 S IY0 EH0 R'],
  "bussing": ['B AH1 S IH0 NG'],
  "bussinger": ['B AH1 S IH0 N JH ER0'],
  "bussman": ['B AH1 S M AH0 N'],
  "bust": ['B AH1 S T'],
  "busta": ['B AH1 S T AH0'],
  "bustamante": ['B UW2 S T AH0 M AA1 N T IY0'],
  "bustard": ['B AH1 S T ER0 D'],
  "busted": ['B AH1 S T IH0 D'],
  "buster": ['B AH1 S T ER0'],
  "busters": ['B AH1 S T ER0 Z'],
  "busti": ['B AH1 S T IY0'],
  "bustier": ['B AH1 S T Y ER0'],
  "bustillo": ['B UW0 S T IH1 L OW0'],
  "bustillos": ['B AH1 S T AY0 L OW0 Z'],
  "bustin": ['B AH1 S T IH0 N'],
  "busting": ['B AH1 S T IH0 NG'],
  "bustle": ['B AH1 S AH0 L'],
  "bustling": ['B AH1 S AH0 L IH0 NG', 'B AH1 S L IH0 NG'],
  "busto": ['B AH1 S T OW0'],
  "bustos": ['B AH1 S T OW0 Z'],
  "busts": ['B AH1 S T S'],
  "busty": ['B AH1 S T IY0'],
  "busulaki": ['B Y UW1 S AH0 L AE2 K IY0'],
  "buswell": ['B AH1 S W EH2 L'],
  "busy": ['B IH1 Z IY0'],
  "but": ['B AH1 T'],
  "but's": ['B AH1 T S'],
  "butala": ['B UW0 T AA1 L AH0'],
  "butan": ['B UW1 T AA2 N'],
  "butane": ['B Y UW0 T EY1 N', 'B Y UW1 T EY0 N'],
  "butare": ['B UW0 T AA1 R IY0', 'B Y UW0 T AA1 R IY0'],
  "butare's": ['B UW0 T AA1 R IY0 Z', 'B Y UW0 T AA1 R IY0 Z'],
  "butch": ['B UH1 CH'],
  "butchart": ['B UH1 CH ER0 T'],
  "butcher": ['B UH1 CH ER0'],
  "butcher's": ['B UH1 CH ER0 Z'],
  "butchered": ['B UH1 CH ER0 D'],
  "butchering": ['B UH1 CH ER0 IH0 NG'],
  "butchers": ['B UH1 CH ER0 Z'],
  "butchery": ['B UH1 CH ER0 IY0'],
  "butchko": ['B AH1 CH K OW0', 'B UH1 CH K OW0'],
  "bute": ['B Y UW1 T'],
  "buteau": ['B Y UW0 T OW1'],
  "buteco": ['B UW2 T EH1 K OW0'],
  "butenhoff": ['B Y UW1 T IH0 N HH AO0 F'],
  "butera": ['B UW0 T EH1 R AH0'],
  "buterbaugh": ['B Y UW1 T ER0 B AW0'],
  "buth": ['B UW1 TH'],
  "buthan": ['B UW2 T AA1 N'],
  "buthelezi": ['B UW2 T AH0 L EY1 Z IY0'],
  "buthelezi's": ['B UW2 T AH0 L EY1 Z IY0 Z'],
  "butka": ['B AH1 T K AH0'],
  "butkiewicz": ['B AH1 T K AO0 W IH0 CH'],
  "butkovich": ['B AH1 T K AO2 V IH0 CH'],
  "butkus": ['B AH1 T K IH0 S'],
  "butler": ['B AH1 T L ER0'],
  "butler's": ['B AH1 T L ER0 Z'],
  "butlers": ['B AH1 T L ER0 Z'],
  "butman": ['B AH1 T M AH0 N'],
  "butner": ['B AH1 T N ER0'],
  "butorac": ['B Y UW0 T AO1 R AH0 K'],
  "butrick": ['B AH1 T R IH0 K'],
  "butros": ['B UW1 T R OW2 S', 'B UW1 T R AH0 S'],
  "buts": ['B AH1 T S'],
  "butsch": ['B AH1 CH'],
  "butson": ['B AH1 T S AH0 N'],
  "butt": ['B AH1 T'],
  "buttacavoli": ['B UW0 T AA0 K AA0 V OW1 L IY0'],
  "buttafuoco": ['B UW0 T AH0 F W OW1 K OW0'],
  "buttafuoco's": ['B UW0 T AH0 F W OW1 K OW0 Z'],
  "buttars": ['B AH1 T ER0 Z'],
  "butte": ['B Y UW1 T'],
  "butter": ['B AH1 T ER0'],
  "butterball": ['B AH1 T ER0 B AO2 L'],
  "butterballs": ['B AH1 T ER0 B AO2 L Z'],
  "butterbaugh": ['B AH1 T ER0 B AW0'],
  "buttercup": ['B AH1 T ER0 K AH2 P'],
  "buttercups": ['B AH1 T ER0 K AH2 P S'],
  "buttered": ['B AH1 T ER0 D'],
  "butterfat": ['B AH1 T ER0 F AE2 T'],
  "butterfield": ['B AH1 T ER0 F IY0 L D'],
  "butterfields": ['B AH1 T ER0 F IY0 L D Z'],
  "butterflies": ['B AH1 T ER0 F L AY2 Z'],
  "butterfly": ['B AH1 T ER0 F L AY2'],
  "butterfly's": ['B AH1 T ER0 F L AY2 Z'],
  "butterick": ['B AH1 T ER0 IH0 K'],
  "butterick's": ['B AH1 T ER0 IH0 K S'],
  "buttering": ['B AH1 T ER0 IH0 NG'],
  "buttermilk": ['B AH1 T ER0 M IH2 L K'],
  "buttermore": ['B AH1 T ER0 M AO0 R'],
  "butters": ['B AH1 T ER0 Z'],
  "butterscotch": ['B AH1 T ER0 S K AA2 CH'],
  "butterworth": ['B AH1 T ER0 W ER2 TH'],
  "buttery": ['B AH1 T ER0 IY0'],
  "butthead": ['B AH1 T HH EH2 D'],
  "butting": ['B AH1 T IH0 NG'],
  "buttitta": ['B UW0 T IY1 T AH0'],
  "buttke": ['B AH1 T K IY0'],
  "buttler": ['B AH1 T L ER0'],
  "buttner": ['B AH1 T N ER0'],
  "buttock": ['B AH1 T AH0 K'],
  "buttocks": ['B AH1 T AH0 K S'],
  "button": ['B AH1 T AH0 N'],
  "buttoned": ['B AH1 T AH0 N D'],
  "buttonhole": ['B AH1 T AH0 N HH OW2 L'],
  "buttonholed": ['B AH1 T AH0 N HH OW2 L D'],
  "buttonholes": ['B AH1 T AH0 N HH OW2 L Z'],
  "buttons": ['B AH1 T AH0 N Z'],
  "buttonville": ['B AH1 T AH0 N V IH2 L'],
  "buttram": ['B AH1 T R AE2 M'],
  "buttress": ['B AH1 T R AH0 S'],
  "buttressed": ['B AH1 T R AH0 S T'],
  "buttresses": ['B AH1 T R AH0 S AH0 Z', 'B AH1 T R AH0 S IH0 Z'],
  "buttressing": ['B AH1 T R AH0 S IH0 NG'],
  "buttrey": ['B AH1 T R IY0'],
  "buttrick": ['B AH1 T R IH0 K'],
  "buttrum": ['B AH1 T R AH0 M'],
  "buttry": ['B AH1 T R IY0'],
  "butts": ['B AH1 T S'],
  "butulesi": ['B UW2 T AH0 L EY1 Z IY0'],
  "butulesi's": ['B UW2 T AH0 L EY1 Z IY0 Z'],
  "butyl": ['B Y UW1 T AH0 L'],
  "butz": ['B AH1 T S'],
  "butzberger": ['B AH1 T S B ER0 G ER0'],
  "butzen": ['B AH1 T S AH0 N'],
  "butzer": ['B AH1 T S ER0'],
  "butzin": ['B AH1 T S AH0 N'],
  "buus": ['B UW1 Z'],
  "buxbaum": ['B AH1 K S B AW0 M'],
  "buxom": ['B AH1 K S AH0 M'],
  "buxton": ['B AH1 K S T AH0 N'],
  "buy": ['B AY1'],
  "buy's": ['B AY1 Z'],
  "buyback": ['B AY1 B AE2 K'],
  "buybacks": ['B AY1 B AE2 K S'],
  "buyer": ['B AY1 ER0'],
  "buyer's": ['B AY1 ER0 Z'],
  "buyers": ['B AY1 ER0 Z'],
  "buyers'": ['B AY1 ER0 Z'],
  "buying": ['B AY1 IH0 NG'],
  "buyout": ['B AY1 AW2 T'],
  "buyouts": ['B AY1 AW2 T S'],
  "buys": ['B AY1 Z'],
  "buysse": ['B AY1 S'],
  "buza": ['B Y UW1 Z AH0'],
  "buzan": ['B Y UW1 Z AH0 N'],
  "buzard": ['B Y UW0 Z AA1 R D'],
  "buzbee": ['B AH1 Z B IY2'],
  "buzby": ['B AH1 Z B IY0'],
  "buzek": ['B UW1 Z EH0 K'],
  "buzz": ['B AH1 Z'],
  "buzzard": ['B AH1 Z ER0 D'],
  "buzzard's": ['B AH1 Z ER0 D Z'],
  "buzzards": ['B AH1 Z ER0 D Z'],
  "buzze": ['B AH1 Z', 'B Y UW1 Z IY0'],
  "buzzed": ['B AH1 Z D'],
  "buzzell": ['B AH0 Z EH1 L'],
  "buzzelli": ['B UW0 T S EH1 L IY0'],
  "buzzer": ['B AH1 Z ER0'],
  "buzzes": ['B AH1 Z IH0 Z'],
  "buzzetta": ['B Y UW0 Z EH1 T AH0'],
  "buzzfeed": ['B AH1 Z F IY1 D'],
  "buzzing": ['B AH1 Z IH0 NG'],
  "buzzword": ['B AH1 Z W ER0 D'],
  "buzzwords": ['B AH1 Z W ER0 D Z'],
  "buzzy": ['B AH1 Z IY0'],
  "buzzy's": ['B AH1 Z IY0 Z'],
  "by": ['B AY1'],
  "byam": ['B AY1 AH0 M'],
  "byard": ['B Y AA1 R D', 'B AY1 ER0 D'],
  "byars": ['B AY1 ER0 Z'],
  "byas": ['B AY1 AH0 S'],
  "byassee": ['B IY0 AA1 S IY0'],
  "bybee": ['B AY1 B IY2'],
  "bye": ['B AY1'],
  "bye-bye": ['B AY1 B AY1'],
  "byelorussia": ['B AY2 AH0 L OW0 R AH1 SH AH0', 'B EH1 L OW0 R AH1 SH AH0', 'B AY2 EH1 L OW0 R AH1 SH AH0'],
  "byer": ['B AY1 ER0'],
  "byerlein": ['B AY1 R L AY2 N'],
  "byerley": ['B AY1 ER0 L IY0'],
  "byerly": ['B AY1 ER0 L IY0'],
  "byers": ['B AY1 ER0 Z'],
  "byers'": ['B AY1 ER0 Z'],
  "byfield": ['B AY1 F IY2 L D'],
  "byford": ['B IH1 F ER0 D'],
  "bygone": ['B AY1 G AO2 N'],
  "bygones": ['B AY1 G AO2 N Z'],
  "byham": ['B AY1 AH0 M'],
  "byington": ['B AY1 IH0 NG T AH0 N'],
  "byker": ['B AY1 K ER0'],
  "bykowski": ['B IH0 K AO1 F S K IY0'],
  "byland": ['B AY1 L AH0 N D'],
  "bylaw": ['B AY1 L AO2'],
  "bylaws": ['B AY1 L AO2 Z'],
  "byler": ['B AY1 L ER0'],
  "byles": ['B AY1 L Z'],
  "byline": ['B AY1 L AY2 N'],
  "bylines": ['B AY1 L IY2 N Z'],
  "bylsma": ['B IH1 L S M AH0'],
  "bylund": ['B IH1 L AH0 N D'],
  "byner": ['B AY1 N ER0'],
  "bynes": ['B AY1 N Z'],
  "bynoe": ['B IH1 N OW0'],
  "bynum": ['B IH1 N AH0 M'],
  "bypass": ['B AY1 P AE2 S'],
  "bypassed": ['B AY1 P AE2 S T'],
  "bypasses": ['B AY1 P AE2 S IH0 Z'],
  "bypassing": ['B AY1 P AE2 S IH0 NG'],
  "byproduct": ['B AY1 P R AA0 D AH0 K T'],
  "byproducts": ['B AY1 P R AO2 D AH0 K T S'],
  "byram": ['B IH1 R AH0 M'],
  "byrd": ['B ER1 D'],
  "byrd's": ['B ER1 D Z'],
  "byrer": ['B AY1 R ER0'],
  "byrge": ['B AY1 R JH'],
  "byrle": ['B AY1 R AH0 L'],
  "byrn": ['B IH1 R N'],
  "byrne": ['B ER1 N'],
  "byrne's": ['B ER1 N Z'],
  "byrnes": ['B ER1 N Z'],
  "byrns": ['B IH1 R N Z'],
  "byrom": ['B AY1 R AH0 M'],
  "byron": ['B AY1 R AH0 N'],
  "byron's": ['B AY1 R AH0 N Z'],
  "byrum": ['B IH1 R AH0 M'],
  "bystander": ['B AY1 S T AE2 N D ER0'],
  "bystanders": ['B AY1 S T AE2 N D ER0 Z'],
  "bystrom": ['B IH1 S T R AH0 M'],
  "byte": ['B AY1 T'],
  "bytes": ['B AY1 T S'],
  "byu": ['B IY1 W AY1 Y UW1'],
  "byun": ['B Y AH1 N'],
  "byus": ['B AY1 AH0 S'],
  "bywater": ['B AY1 W AO2 T ER0'],
  "byway": ['B AY1 W EY2'],
  "byways": ['B AY1 W EY2 Z'],
  "byword": ['B AY1 W ER2 D'],
  "byzantine": ['B IH1 Z AH0 N T AY2 N', 'B IH1 Z AH0 N T IY2 N'],
  "byzantium": ['B AH0 Z AE1 N T IY0 AH0 M'],
  "c": ['S IY1'],
  "c'est": ['S EH1 S T', 'S EY1'],
  "c'mon": ['K AH0 M AA1 N'],
  "c's": ['S IY1 Z'],
  "c-code": ['S IY1 K OW1 D'],
  "c-codes": ['S IY1 K OW1 D Z'],
  "c-span": ['S IY1 S P AE2 N'],
  "c-span's": ['S IY1 S P AE2 N Z'],
  "c.": ['S IY1'],
  "c.'s": ['S IY1 Z'],
  "c.d.s": ['S IY1 D IY1 Z'],
  "c.o.d.": ['S IY1 OW1 D IY1'],
  "c.s": ['S IY1 Z'],
  "ca": ['K AH1', 'S IY1 EY1', 'K AA1'],
  "caan": ['K AA1 N'],
  "cab": ['K AE1 B'],
  "cab's": ['K AE1 B Z'],
  "caba": ['K AA1 B AH0'],
  "cabal": ['K AH0 B AA1 L'],
  "caballero": ['K AE2 B AH0 Y EH1 R OW0'],
  "caban": ['K EY1 B AH0 N'],
  "cabana": ['K AH0 B AE1 N AH0'],
  "cabanas": ['K AH0 B AE1 N AH0 Z'],
  "cabaniss": ['K AE1 B AH0 N IH0 S'],
  "cabaret": ['K AE2 B ER0 EY1'],
  "cabarets": ['K AE2 B ER0 EY1 Z'],
  "cabbage": ['K AE1 B AH0 JH', 'K AE1 B IH0 JH'],
  "cabbages": ['K AE1 B IH0 JH IH0 Z'],
  "cabbie": ['K AE1 B IY0'],
  "cabbies": ['K AE1 B IY0 Z'],
  "cabby": ['K AE1 B IY0'],
  "cabdriver": ['K AE1 B D R AY2 V ER0'],
  "cabdrivers": ['K AE1 B D R AY2 V ER0 Z'],
  "cabe": ['K EY1 B'],
  "cabela": ['K AA2 B EH1 L AH0'],
  "cabela's": ['K AA2 B EH1 L AH0'],
  "cabell": ['K AA0 B EH1 L'],
  "cabello": ['K AH0 B EH1 L OW0'],
  "cabernet": ['K AE2 B ER0 N EY1'],
  "cabernets": ['K AE2 B ER0 N EH1 T S', 'K AE2 B ER0 N EY1 Z'],
  "cabey": ['K EY1 B IY0'],
  "cabezas": ['K AH0 B EY1 Z AH0 Z'],
  "cabin": ['K AE1 B AH0 N'],
  "cabinda": ['K AH0 B IH1 N D AH0'],
  "cabiness": ['K EY1 B IY0 N IH0 S'],
  "cabinet": ['K AE1 B AH0 N AH0 T', 'K AE1 B N AH0 T'],
  "cabinet's": ['K AE1 B N AH0 T S'],
  "cabinetry": ['K AE1 B N AH0 T R IY0'],
  "cabinets": ['K AE1 B AH0 N AH0 T S', 'K AE1 B N AH0 T S'],
  "cabins": ['K AE1 B AH0 N Z'],
  "cable": ['K EY1 B AH0 L'],
  "cable's": ['K EY1 B AH0 L Z'],
  "cablec": ['K AE1 B L AH0 K', 'K EY1 B AH0 L S IY1', 'K EY1 B L EH0 K'],
  "cablecomm": ['K EY1 B AH0 L K AA2 M'],
  "cablecomms": ['K EY1 B AH0 L K AA2 M Z'],
  "cabled": ['K EY1 B AH0 L D'],
  "cablegram": ['K EY1 B AH0 L G R AE2 M'],
  "cableone": ['K EY1 B AH0 L W AH2 N'],
  "cabler": ['K EY1 B AH0 L ER0', 'K EY1 B L ER0'],
  "cables": ['K EY1 B AH0 L Z'],
  "cablesystem": ['K EY1 B AH0 L S IH2 S T AH0 M'],
  "cablesystems": ['K EY1 B AH0 L S IH2 S T AH0 M Z'],
  "cabletron": ['K EY1 B AH0 L T R AA2 N'],
  "cabletron's": ['K EY1 B AH0 L T R AA2 N Z'],
  "cablevision": ['K EY1 B AH0 L V IH2 ZH AH0 N'],
  "cablevision's": ['K EY1 B AH0 L V IH2 ZH AH0 N Z'],
  "cabo": ['K AE0 B OW1', 'S IY1 EY1 B IY1 OW1'],
  "cabok": ['AE1 B OW0'],
  "caboodle": ['K AH0 B UW1 D AH0 L'],
  "caboose": ['K AH0 B UW1 S'],
  "cabooses": ['K AH0 B UW1 S IH0 Z'],
  "cabot": ['K AE1 B AH0 T'],
  "cabot's": ['K AE1 B AH0 T S'],
  "cabotage": ['K AE1 B AH0 T IH0 JH'],
  "cabral": ['K AE1 B R AH0 L'],
  "cabrales": ['K AA0 B R AA1 L EH0 S'],
  "cabrall": ['K AH0 B R AA1 L'],
  "cabrall's": ['K AH0 B R AA1 L Z'],
  "cabranes": ['K AH0 B R EY1 N Z'],
  "cabrera": ['K AA0 B R EH1 R AH0'],
  "cabrini": ['K AH0 B R IY1 N IY0'],
  "cabrini's": ['K AH0 B R IY1 N IY0 Z'],
  "cabriolet": ['K AE2 B R IY0 OW0 L EY1', 'K AE2 B R IY0 OW0 L EH1 T'],
  "cabs": ['K AE1 B Z'],
  "cac": ['K AE1 K', 'S IY1 EY1 S IY1'],
  "cac's": ['K AE1 K S'],
  "cacace": ['K AE1 K AH0 S'],
  "cacaci": ['K AH0 K AA1 S IY0'],
  "cacao": ['K AH0 K EY1 OW0'],
  "caccamise": ['K AA0 K AA1 M IH0 S'],
  "caccamo": ['K AA0 K AA1 M OW0'],
  "caccavale": ['K AA0 K AH0 V AA1 L IY0'],
  "caccia": ['K AA1 CH AH0'],
  "cacciatore": ['K AA0 CH AH0 T AO1 R IY0'],
  "cacciola": ['K AA0 K CH OW1 L AH0'],
  "caceres": ['K AA0 S EH1 R EH0 S'],
  "cache": ['K AE1 SH', 'K AE0 SH EY1'],
  "cached": ['K AE1 SH T'],
  "caches": ['K AE1 SH IH0 Z', 'K AE0 SH EY1 Z'],
  "cachet": ['K AE1 SH EY0'],
  "caching": ['K AE1 SH IH0 NG'],
  "cacho": ['K AE1 CH OW0'],
  "cacioppo": ['K AA0 CH OW1 P OW0'],
  "cacique": ['K AH0 S IY1 K'],
  "cackle": ['K AE1 K AH0 L'],
  "cackling": ['K AE1 K AH0 L IH0 NG', 'K AE1 K L IH0 NG'],
  "cacld": ['K AE1 K AH0 L D', 'S IY1 EY1 S IY1 EH1 L D IY1'],
  "cacophony": ['K AE0 K AA1 F AH0 N IY0'],
  "cacti": ['K AE1 K T AY0', 'K AE1 K T IY0'],
  "cactus": ['K AE1 K T AH0 S'],
  "cad": ['K AE1 D'],
  "cada": ['K AA1 D AH0'],
  "cadam": ['K AE1 D AH0 M'],
  "cadaver": ['K AH0 D AE1 V ER0'],
  "cadavers": ['K AH0 D AE1 V ER0 Z'],
  "cadbury": ['K AE1 D B EH2 R IY0'],
  "cadbury's": ['K AE1 D B EH2 R IY0 Z'],
  "cadby": ['K AE1 D B IY0'],
  "caddell": ['K AE1 D AH0 L'],
  "cadden": ['K AE1 D AH0 N'],
  "caddick": ['K AE1 D IH0 K'],
  "caddie": ['K AE1 D IY0'],
  "caddie's": ['K AE1 D IY0 Z'],
  "caddied": ['K AE1 D IY0 D'],
  "caddies": ['K AE1 D IY0 Z'],
  "caddock": ['K AE1 D AH0 K'],
  "caddy": ['K AE1 D IY0'],
  "caddying": ['K AE1 D IY0 IH0 NG'],
  "caddyshack": ['K AE1 D IY0 SH AE2 K'],
  "cade": ['K EY1 D'],
  "cadell": ['K AA0 D EY1 L'],
  "cadena": ['K AH0 D IY1 N AH0'],
  "cadence": ['K EY1 D AH0 N S'],
  "cadences": ['K EY1 D AH0 N S IH0 Z'],
  "cadenhead": ['K EY1 D AH0 N HH EH2 D'],
  "cadet": ['K AH0 D EH1 T'],
  "cadets": ['K AH0 D EH1 T S'],
  "cadge": ['K AE1 JH'],
  "cadiddlehopper": ['K AH0 D IH1 D AH0 L HH AO2 P ER0'],
  "cadieux": ['K AE1 D IY0 OW0'],
  "cadillac": ['K AE1 D AH0 L AE2 K'],
  "cadillac's": ['K AE1 D AH0 L AE2 K S'],
  "cadillacs": ['K AE1 D AH0 L AE2 K S'],
  "cadiz": ['K AH0 D IY1 Z'],
  "cadle": ['K EY1 D AH0 L'],
  "cadman": ['K AE1 D M AH0 N'],
  "cadmium": ['K AE1 D M IY0 AH0 M'],
  "cadmus": ['K AE1 D M AH0 S'],
  "cadnetix": ['K AE2 D N EH1 T IH0 K S'],
  "cadogan": ['K AA0 D OW0 G AA1 N'],
  "cadorette": ['K AE1 D ER0 EH0 T'],
  "cadotte": ['K AH0 D AO1 T'],
  "cadre": ['K AE1 D R IY0'],
  "cadres": ['K AE1 D R IY0 Z'],
  "cadrone": ['K AH0 D R OW1 N', 'K AH0 D R OW1 N IY0'],
  "cads": ['K AE1 D S'],
  "cadwalader": ['K AE2 D W AO1 L AH0 D ER0'],
  "cadwalader's": ['K AE2 D W AO1 L AH0 D ER0 Z'],
  "cadwallader": ['K AE2 D W AO1 L AH0 D ER0'],
  "cadwell": ['K AE1 D W EH2 L'],
  "cady": ['K EY1 D IY0'],
  "caen": ['K AE1 N', 'K AA1 N'],
  "caen's": ['K AE1 N Z', 'K AA1 N Z'],
  "caesar": ['S IY1 Z ER0'],
  "caesar's": ['S IY1 Z ER0 Z'],
  "caesarea": ['K EY2 S ER0 IY1 AH0'],
  "caesarean": ['K EY1 S ER0 IY2 N'],
  "caesareans": ['K EY1 S ER0 IY2 N Z'],
  "caesars": ['S IY1 Z ER0 Z'],
  "caesars'": ['S IY1 Z ER0 Z'],
  "caetano": ['K AH0 T AA1 N OW0'],
  "cafarella": ['K AA0 F AA0 R EH1 L AH0'],
  "cafarelli": ['K AA0 F AA0 R EH1 L IY0'],
  "cafaro": ['K AA0 F AA1 R OW0'],
  "cafe": ['K AH0 F EY1', 'K AE0 F EY1'],
  "cafes": ['K AE2 F EY1 Z'],
  "cafeteria": ['K AE2 F AH0 T IH1 R IY0 AH0'],
  "cafeterias": ['K AE2 F AH0 T IH1 R IY0 AH0 Z'],
  "cafetizer": ['K AE1 F IH0 T AY2 Z ER0'],
  "caffee": ['K AE1 F IY0'],
  "caffeinate": ['K AE1 F IH0 N EY2 T'],
  "caffeinated": ['K AE1 F IH0 N EY2 T AH0 D'],
  "caffeine": ['K AE0 F IY1 N'],
  "cafferty": ['K AE1 F ER0 T IY0'],
  "caffery": ['K AE1 F ER0 IY0'],
  "caffey": ['K AE1 F IY0'],
  "caffrey": ['K AE1 F R IY0'],
  "cafiero": ['K AE2 F IY0 EH1 R OW0'],
  "caftan": ['K AE1 F T AE2 N'],
  "caftans": ['K AE1 F T AE2 N Z'],
  "cagan": ['K EY1 G AH0 N'],
  "cage": ['K EY1 JH'],
  "cage's": ['K EY1 JH IH0 Z'],
  "caged": ['K EY1 JH D'],
  "cages": ['K EY1 JH IH0 Z'],
  "cagey": ['K EY1 JH IY0'],
  "caggiano": ['K AA0 JH IY0 AA1 N OW0'],
  "cagle": ['K EY1 G AH0 L'],
  "cagley": ['K AE1 G L IY0'],
  "cagliari": ['K AE2 G L IY0 AA1 R IY0'],
  "cagney": ['K AE1 G N IY0'],
  "caguas": ['K AA1 G W AA2 S'],
  "cahalan": ['K AE1 HH AH0 L AH0 N'],
  "cahall": ['K AE1 HH AH0 L'],
  "cahan": ['K AE1 HH AH0 N'],
  "cahasa": ['K AH0 HH AA1 S AH0'],
  "cahill": ['K EY1 HH IH2 L'],
  "cahn": ['K AE1 N'],
  "cahners": ['K AA1 N ER0 Z'],
  "cahoon": ['K AH0 HH UW1 N'],
  "cahoot": ['K AH0 HH UW1 T'],
  "cahoots": ['K AH0 HH UW1 T S'],
  "cahora": ['K AH0 HH AO1 R AH0'],
  "cahouet": ['K AH0 HH UW1 T'],
  "cai": ['K AY1'],
  "caiazzo": ['K AA0 Y AA1 Z OW0'],
  "caicedo": ['K AH0 CH EY1 D OW0'],
  "cail": ['K EY1 L'],
  "caillebotte": ['K AY0 B AO1 T'],
  "caillier": ['K EY1 L IY0 ER0'],
  "caillouet": ['K EY2 L UW1 T'],
  "cain": ['K EY1 N'],
  "cain's": ['K EY1 N Z'],
  "caine": ['K EY1 N'],
  "caines": ['K EY1 N Z'],
  "caiola": ['K AY1 OW0 L AH0'],
  "caire": ['K EH1 R'],
  "cairn": ['K EH1 R N'],
  "cairnes": ['K EH1 R N Z'],
  "cairns": ['K EH1 R N Z'],
  "cairo": ['K AY1 R OW0'],
  "cairo's": ['K AY1 R OW0 Z'],
  "caison": ['K EY1 Z AH0 N'],
  "caisse": ['K EY1 S'],
  "caissie": ['K EY1 S IY0'],
  "caisson": ['K EY1 S AH0 N', 'K EH1 S AH0 N'],
  "caissons": ['K EY1 S AH0 N Z', 'K EH1 S AH0 N Z'],
  "caitlin": ['K EY1 T L IH0 N', 'K AE1 T L IY0 N'],
  "caito": ['K EY1 T OW0'],
  "cajole": ['K AH0 JH OW1 L'],
  "cajoled": ['K AH0 JH OW1 L D'],
  "cajoling": ['K AH0 JH OW1 L IH0 NG'],
  "cajun": ['K EY1 JH AH0 N'],
  "cajuns": ['K EY1 JH AH0 N Z'],
  "cake": ['K EY1 K'],
  "cake's": ['K EY1 K S'],
  "caked": ['K EY1 K T'],
  "cakes": ['K EY1 K S'],
  "cal": ['K AE1 L'],
  "cala": ['K AA1 L AH0'],
  "calabasas": ['K AE2 L AH0 B AA1 S AH0 S'],
  "calabrese": ['K AA0 L AA0 B R EY1 Z IY0'],
  "calabria": ['K AH0 L AE1 B R IY0 AH0'],
  "calabro": ['K AH0 L AE1 B R OW0'],
  "caladiums": ['K AH0 L EY1 D IY0 AH0 M Z'],
  "calaf": ['K AE1 L AH0 F'],
  "calahan": ['K AE1 L AH0 HH AE0 N'],
  "calais": ['K AH0 L EY1'],
  "calamander": ['K AE1 L AH0 M AE2 N D ER0'],
  "calamari": ['K AA0 L AA0 M AA1 R IY0'],
  "calame": ['K AA0 L AA1 M IY0'],
  "calamia": ['K AH0 L EY1 M IY0 AH0'],
  "calamine": ['K AE1 L AH0 M AY2 N'],
  "calamine's": ['K AE1 L AH0 M AY2 N Z'],
  "calamities": ['K AH0 L AE1 M AH0 T IY0 Z'],
  "calamitous": ['K AH0 L AE1 M AH0 T AH0 S'],
  "calamity": ['K AH0 L AE1 M AH0 T IY0', 'K AH0 L AE1 M IH0 T IY0'],
  "calan": ['K EY1 L AH0 N'],
  "calandra": ['K AH0 L AE1 N D R AH0'],
  "calandro": ['K AH0 L AE1 N D R OW0'],
  "calantha": ['K AH0 L AE1 N TH AH0'],
  "calarco": ['K AH0 L AA1 R K OW0'],
  "calaveras": ['K AE0 L AH0 V EH1 R AH0 Z'],
  "calaway": ['K AA1 L AH0 W EY0'],
  "calbert": ['K AE1 L B ER0 T'],
  "calbos": ['K AA1 L B OW0 S'],
  "calcagni": ['K AA0 L K AA1 G N IY0'],
  "calcagno": ['K AA0 L K AA1 G N OW0'],
  "calcaterra": ['K AE2 L K AH0 T EH1 R AH0'],
  "calcified": ['K AE1 L S AH0 F AY2 D'],
  "calcify": ['K AE1 L S AH0 F AY2'],
  "calcite": ['K AE1 L S AY2 T'],
  "calcium": ['K AE1 L S IY0 AH0 M'],
  "calcomp": ['K AO1 L K AA2 M P'],
  "calcote": ['K AA0 L K OW1 T IY0'],
  "calculate": ['K AE1 L K Y AH0 L EY2 T'],
  "calculated": ['K AE1 L K Y AH0 L EY2 T IH0 D'],
  "calculates": ['K AE1 L K Y AH0 L EY2 T S'],
  "calculating": ['K AE1 L K Y AH0 L EY2 T IH0 NG'],
  "calculation": ['K AE2 L K Y AH0 L EY1 SH AH0 N'],
  "calculations": ['K AE2 L K Y AH0 L EY1 SH AH0 N Z'],
  "calculator": ['K AE1 L K Y AH0 L EY2 T ER0'],
  "calculators": ['K AE1 L K Y AH0 L EY2 T ER0 Z'],
  "calculus": ['K AE1 L K Y AH0 L AH0 S'],
  "calcutt": ['K AE1 L K AH0 T'],
  "calcutta": ['K AE2 L K AH1 T AH0'],
  "caldeira": ['K AA0 L D EH1 R AH0'],
  "calder": ['K AO1 L D ER0'],
  "caldera": ['K AE2 L D EH1 R AH0', 'K AO2 L D EH1 R AH0'],
  "calderaro": ['K AA0 L D ER0 AA1 R OW0'],
  "calderon": ['K AO1 L D ER0 AA0 N', 'K AE2 L D ER0 OW1 N'],
  "calderone": ['K AA0 L D ER0 OW1 N IY0'],
  "calderwood": ['K AO1 L D ER0 W UH0 D'],
  "caldor": ['K AE1 L D AO0 R'],
  "caldrello": ['K AA2 L D R EH1 L OW0'],
  "caldwell": ['K AO1 L D W EH0 L'],
  "caldwell's": ['K AO1 L D W EH0 L Z'],
  "cale": ['K EY1 L'],
  "caleb": ['K EY1 L AH0 B'],
  "calebs": ['K EY1 L AH0 B Z'],
  "caleca": ['K AA0 L EH1 K AH0'],
  "caledonia": ['K AE2 L AH0 D OW1 N IY0 AH0'],
  "caledonian": ['K AE2 L IH0 D OW1 N IY0 AH0 N'],
  "caledonian's": ['K AE2 L IH0 D OW1 N IY0 AH0 N Z'],
  "calef": ['K AE1 L IH0 F'],
  "calemburg": ['K AE1 L AH0 M B ER0 G'],
  "calendar": ['K AE1 L AH0 N D ER0'],
  "calendars": ['K AE1 L AH0 N D ER0 Z'],
  "calender": ['K AE1 L AH0 N D ER0'],
  "calendula": ['K AH0 L EH1 JH AH0 L AH0'],
  "caler": ['K EY1 L ER0'],
  "calero": ['K AH0 L EH1 R OW0'],
  "cales": ['K EY1 L Z'],
  "caley": ['K EY1 L IY0'],
  "calf": ['K AE1 F'],
  "calf's": ['K AE1 F S'],
  "calfed": ['K AE1 F T', 'K AE1 L F EH2 D'],
  "calfed's": ['K AE1 L F EH2 D Z'],
  "calfee": ['K AE1 L F IY0'],
  "calgary": ['K AE1 L G ER0 IY0'],
  "calgene": ['K AE1 L JH IY2 N'],
  "calgene's": ['K AE1 L JH IY2 N Z'],
  "calgon": ['K AE1 L G AO0 N'],
  "calgroup": ['K AE1 L G R UW2 P'],
  "calhoon": ['K AE0 L HH UW1 N'],
  "calhoun": ['K AE0 L HH UW1 N'],
  "cali": ['K AA1 L IY0', 'K AE1 L IY0'],
  "calia": ['K AA1 L IY0 AH0'],
  "caliber": ['K AE1 L AH0 B ER0'],
  "calibrate": ['K AE1 L AH0 B R EY2 T'],
  "calibrated": ['K AE1 L AH0 B R EY2 T AH0 D'],
  "calibration": ['K AE2 L AH0 B R EY1 SH AH0 N'],
  "calico": ['K AE1 L AH0 K OW2'],
  "calida": ['K AA0 L IY1 D AH0'],
  "caliendo": ['K AA0 L IY0 EH1 N D OW0'],
  "calif": ['K AE1 L IH0 F'],
  "califano": ['K AA0 L IY0 F AA1 N OW0'],
  "califf": ['K AE1 L IH0 F'],
  "california": ['K AE2 L AH0 F AO1 R N Y AH0'],
  "california's": ['K AE2 L IH0 F AO1 R N Y AH0 Z'],
  "californian": ['K AE2 L IH0 F AO1 R N Y AH0 N'],
  "californian's": ['K AE2 L IH0 F AO1 R N Y AH0 N Z'],
  "californians": ['K AE2 L IH0 F AO1 R N Y AH0 N Z'],
  "caligiuri": ['K AE2 L AH0 JH IH1 R IY0'],
  "calill": ['K AH0 L IH1 L'],
  "calills": ['K AH0 L IH1 L Z'],
  "caliper": ['K AE1 L AH0 P ER0'],
  "calipers": ['K AE1 L AH0 P ER0 Z'],
  "caliph": ['K AE1 L AH0 F'],
  "caliri": ['K AA0 L IH1 R IY0'],
  "calise": ['K AA1 L AY0 Z'],
  "calista": ['K AH0 L IH1 S T AH0'],
  "calisthenic": ['K AE2 L AH0 S TH EH1 N IH0 K'],
  "calisthenics": ['K AE2 L AH0 S TH EH1 N IH0 K S'],
  "calite": ['K AE1 L AY2 T', 'K EY1 L AY2 T'],
  "calix": ['K AE1 L IH0 K S', 'K EY1 L AH0 K S'],
  "calk": ['K AO1 K'],
  "calkin": ['K AE1 L K IH0 N'],
  "calkins": ['K AE1 L K IH0 N Z'],
  "call": ['K AO1 L'],
  "call's": ['K AO1 L Z', 'K AA1 L Z'],
  "calla": ['K AE1 L AH0'],
  "callable": ['K AO1 L AH0 B AH0 L'],
  "callace": ['K AE1 L AH0 S'],
  "callaghan": ['K AE1 L AH0 HH AE0 N'],
  "callaham": ['K AE1 L AH0 HH AE2 M'],
  "callahan": ['K AE1 L AH0 HH AE2 N'],
  "callahan's": ['K AE1 L AH0 HH AE2 N Z'],
  "callais": ['K AH0 L EY1'],
  "callan": ['K AE1 L AH0 N'],
  "callanan": ['K AE1 L AH0 N AH0 N'],
  "calland": ['K AE1 L AH0 N D'],
  "callander": ['K AE1 L AH0 N D ER0'],
  "callari": ['K AA0 L AA1 R IY0'],
  "callas": ['K AE1 L AH0 Z'],
  "callaway": ['K AE1 L AH0 W EY0'],
  "callaway's": ['K AO1 L AH0 W EY2 Z'],
  "callback": ['K AO1 L B AE2 K'],
  "calle": ['K EY1 L'],
  "callebs": ['K EY1 L EH2 B Z'],
  "called": ['K AO1 L D'],
  "callegari": ['K AA0 L EH0 G AA1 R IY0'],
  "calleja": ['K AA0 L EY1 Y AH0'],
  "callejas": ['K AA0 L EY1 Y AA0 Z'],
  "callen": ['K AE1 L AH0 N'],
  "callender": ['K AH0 L EH1 N D ER0'],
  "callens": ['K AO1 L AH0 N Z'],
  "caller": ['K AO1 L ER0'],
  "caller's": ['K AO1 L ER0 Z'],
  "callers": ['K AO1 L ER0 Z'],
  "callery": ['K AE1 L ER0 IY0'],
  "calles": ['K EY1 L Z'],
  "calley": ['K AE1 L IY0'],
  "callicoat": ['K AE1 L IH0 K OW2 T'],
  "callicott": ['K AE1 L IH0 K AA0 T'],
  "callicutt": ['K AE1 L IH0 K AH0 T'],
  "callie": ['K AO1 L IY0'],
  "callier": ['K AE1 L IY0 ER0'],
  "callies": ['K AE1 L IY0 Z'],
  "calligan": ['K AE1 L IH0 G AH0 N'],
  "calligrapher": ['K AH0 L IH1 G R AH0 F ER0'],
  "calligraphic": ['K AE2 L AH0 G R AE1 F IH0 K'],
  "calligraphy": ['K AH0 L IH1 G R AH0 F IY0'],
  "callihan": ['K AE1 L IH0 HH AE0 N'],
  "callinan": ['K AE1 L IH0 N AH0 N'],
  "calling": ['K AO1 L IH0 NG'],
  "calliope": ['K AH0 L AY1 AH0 P IY2'],
  "calliopes": ['K AH0 L AY1 AH0 P IY2 Z'],
  "callis": ['K AE1 L IH0 S'],
  "callison": ['K AE1 L IH0 S AH0 N'],
  "callister": ['K AO1 L IH0 S T ER0'],
  "callous": ['K AE1 L AH0 S'],
  "calloused": ['K AE1 L AH0 S T'],
  "callously": ['K AE1 L AH0 S L IY0'],
  "callousness": ['K AE1 L AH0 S N AH0 S'],
  "callow": ['K AE1 L OW0'],
  "calloway": ['K AE1 L OW0 W EY2'],
  "calls": ['K AO1 L Z'],
  "callula": ['K AE1 L UW0 L AH0'],
  "callum": ['K AE1 L AH0 M'],
  "callus": ['K AE1 L AH0 S'],
  "cally": ['K AE1 L IY0'],
  "calm": ['K AA1 M', 'K AA1 L M'],
  "calma": ['K AA1 L M AH0'],
  "calmaquip": ['K AE1 L M AH0 K W IH2 P'],
  "calmar": ['K AE1 L M AA0 R'],
  "calmark": ['K AA1 L M AA2 R K'],
  "calmart": ['K AA1 L M AA2 R T', 'K AE1 L M AA2 R T'],
  "calmart's": ['K AA1 L M AA2 R T S', 'K AE1 L M AA2 R T S'],
  "calmat": ['K AE1 L M AE0 T'],
  "calmat's": ['K AE1 L M AE0 T S'],
  "calmed": ['K AA1 M D', 'K AA1 L M D'],
  "calmer": ['K AA1 M ER0', 'K AA1 L M ER0'],
  "calmes": ['K AA1 L M EH0 S'],
  "calmest": ['K AA1 M AH0 S T', 'K AA1 L M AH0 S T'],
  "calming": ['K AA1 M IH0 NG', 'K AA1 L M IH0 NG'],
  "calmly": ['K AA1 M L IY0', 'K AA1 L M L IY0'],
  "calmness": ['K AA1 M N AH0 S', 'K AA1 L M N AH0 S'],
  "calms": ['K AA1 M Z', 'K AA1 L M Z'],
  "calnan": ['K AE1 L N AH0 N'],
  "calny": ['K AE1 L N IY0'],
  "calo": ['K AA1 L OW0'],
  "calogero": ['K AA0 L OW0 JH EH1 R OW0'],
  "calor": ['K AE1 L ER0'],
  "caloric": ['K AH0 L AO1 R IH0 K'],
  "calorie": ['K AE1 L ER0 IY0'],
  "calories": ['K AE1 L ER0 IY0 Z'],
  "caloway": ['K AE1 L OW0 W EY2'],
  "calpers": ['K AE1 L P ER0 Z'],
  "calpers's": ['K AE1 L P ER0 Z IH0 Z'],
  "calpis": ['K AE1 L P IH0 S'],
  "caltabiano": ['K AA0 L T AA0 B IY0 AA1 N OW0'],
  "caltagirone": ['K AA0 L T AA0 JH IH0 R OW1 N IY0'],
  "caltex": ['K AH1 L T EH1 K S'],
  "caltha": ['K AE1 L DH AH0'],
  "calton": ['K AE1 L T AH0 N'],
  "caltrain": ['K AE1 L T R EY2 N'],
  "caltrans": ['K AE1 L T R AE2 N Z'],
  "calumet": ['K AE2 L Y AH0 M EH1 T'],
  "calumny": ['K AE1 L AH0 M N IY0'],
  "calutzi": ['K AH0 L UW1 T Z IY0'],
  "caluzu": ['K AH0 L UW1 Z UW0'],
  "caluzzi": ['K AH0 L UW1 Z IY0'],
  "calvani": ['K AO2 L V AA1 N IY0'],
  "calvano": ['K AA0 L V AA1 N OW0'],
  "calvaries": ['K AE1 L V ER0 IY0 Z'],
  "calvary": ['K AE1 L V ER0 IY0'],
  "calveras": ['K AE0 L V EH1 R AH0 S'],
  "calverley": ['K AE1 L V ER0 L IY0'],
  "calvert": ['K AE1 L V ER0 T'],
  "calvery": ['K AE1 L V ER0 IY0'],
  "calves": ['K AE1 V Z'],
  "calvet": ['K AE1 L V AH0 T'],
  "calvey": ['K AE0 L V EY1'],
  "calvi": ['K AA1 L V IY0'],
  "calvillo": ['K AA0 L V IH1 L OW0'],
  "calvin": ['K AE1 L V AH0 N', 'K AE1 L V IH0 N'],
  "calvin's": ['K AE1 L V AH0 N Z', 'K AE1 L V IH0 N Z'],
  "calvina": ['K AA0 L V IY1 N AH0'],
  "calving": ['K AE1 V IH0 NG'],
  "calvinist": ['K AE1 L V AH0 N AH0 S T', 'K AE1 L V IH0 N IH0 S T'],
  "calvino": ['K AO2 L V IY1 N OW0'],
  "calvo": ['K AA1 L V OW0'],
  "calypso": ['K AH0 L IH1 P S OW2'],
  "calypsos": ['K AH0 L IH1 P S OW2 Z'],
  "calyx": ['K AE1 L IH0 K S'],
  "calzada": ['K AA0 L Z AA1 D AH0'],
  "cam": ['K AE1 M'],
  "cam's": ['K AE1 M Z'],
  "cama": ['K AA1 M AH0', 'S IY1 EY1 EH1 M EY1'],
  "camacho": ['K AH0 M AA1 CH OW0'],
  "camaguey": ['K AE1 M AH0 G EY0', 'K AE1 M AH0 G W EY0'],
  "caman": ['K EY1 M AH0 N'],
  "camara": ['K AA0 M AA1 R AH0'],
  "camaraderie": ['K AA2 M ER0 AA1 D ER0 IY0'],
  "camarata": ['K AA0 M AA0 R AA1 T AH0'],
  "camarena": ['K AA0 M AA0 R EH1 N AH0', 'K AA2 M ER0 EY1 N AH0'],
  "camargo": ['K AA0 M AA1 R G OW0'],
  "camarillo": ['K AA0 M AA0 R IH1 L OW0'],
  "camaro": ['K AH0 M EH1 R OW0'],
  "camaros": ['K AH0 M AA1 R OW0 S'],
  "cambell": ['K AE1 M B AH0 L'],
  "camber": ['K AE1 M B ER0'],
  "cambex": ['K AE1 M B AH0 K S'],
  "cambior": ['K AE1 M B IY0 ER0'],
  "cambodia": ['K AE2 M B OW1 D IY0 AH0'],
  "cambodia's": ['K AE2 M B OW1 D IY0 AH0 Z'],
  "cambodian": ['K AE2 M B OW1 D IY0 AH0 N'],
  "cambodians": ['K AE2 M B OW1 D IY0 AH0 N Z'],
  "cambra": ['K AE1 M B R AH0'],
  "cambre": ['K AE1 M B ER0'],
  "cambria": ['K AE1 M B R IY0 AH0'],
  "cambrian": ['K AE1 M B R IY0 AH0 N'],
  "cambrian's": ['K AE1 M B R IY0 AH0 N Z'],
  "cambridge": ['K EY1 M B R IH0 JH'],
  "cambridgeport": ['K EY1 M B R IH2 JH P AO2 R T'],
  "cambridgeside": ['K EY1 M B R IH2 JH S AY2 D'],
  "cambron": ['K AE1 M B R AH0 N'],
  "camburn": ['K AE1 M B ER2 N'],
  "camby": ['K AE1 M B IY0'],
  "camby's": ['K AE1 M B IY0 Z'],
  "camco": ['K AE1 M K OW0'],
  "camcorder": ['K AE1 M K AO2 R D ER0'],
  "camcorders": ['K AE1 M K AO2 R D ER0 Z'],
  "camden": ['K AE1 M D AH0 N'],
  "camden's": ['K AE1 M D AH0 N Z'],
  "camdessus": ['K AE0 M D EH1 S AH0 S'],
  "came": ['K EY1 M'],
  "cameco": ['K AE1 M IH0 K OW0'],
  "camejo": ['K AA0 M EY1 Y OW0'],
  "camel": ['K AE1 M AH0 L'],
  "camel's": ['K AE1 M AH0 L Z'],
  "camelot": ['K AE1 M AH0 L AA2 T'],
  "camels": ['K AE1 M AH0 L Z'],
  "camembert": ['K AE1 M AH0 M B EH0 R'],
  "cameo": ['K AE1 M IY0 OW2'],
  "cameos": ['K AE1 M IY0 OW2 Z'],
  "camera": ['K AE1 M ER0 AH0', 'K AE1 M R AH0'],
  "camera's": ['K AE1 M R AH0 Z'],
  "cameraman": ['K AE1 M ER0 AH0 M AH0 N'],
  "cameramen": ['K AE1 M ER0 AH0 M EH0 N'],
  "cameras": ['K AE1 M ER0 AH0 Z', 'K AE1 M R AH0 Z'],
  "camerer": ['K AE1 M ER0 ER0'],
  "camero": ['K AH0 M EH1 R OW0'],
  "cameron": ['K AE1 M ER0 AH0 N'],
  "cameron's": ['K AE1 M ER0 AH0 N Z'],
  "camerons": ['K AE1 M ER0 AH0 N Z'],
  "cameroon": ['K AE1 M ER0 UW2 N'],
  "camfield": ['K AE1 M F IY2 L D'],
  "camhi": ['K AE1 M HH IY0'],
  "camilla": ['K AH0 M IH1 L AH0'],
  "camille": ['K AH0 M IY1 L'],
  "camilleri": ['K AA0 M IY0 L EH1 R IY0'],
  "camilli": ['K AH0 M IH1 L IY0'],
  "camillo": ['K AH0 M IH1 L OW0'],
  "caminiti": ['K AA0 M IY0 N IY1 T IY0'],
  "camino": ['K AH0 M IY1 N OW0'],
  "camire": ['K AA0 M IH1 R IY0'],
  "camisole": ['K AE1 M IH0 S OW0 L'],
  "camm": ['K AE1 M'],
  "cammack": ['K AE1 M AH0 K'],
  "cammarano": ['K AA0 M AA0 R AA1 N OW0'],
  "cammarata": ['K AA0 M AA0 R AA1 T AH0'],
  "cammarota": ['K AA0 M AA0 R OW1 T AH0'],
  "cammer": ['K AE1 M ER0'],
  "cammermeyer": ['K AE1 M ER0 M AY2 ER0'],
  "cammie": ['K AE1 M IY0'],
  "cammisa": ['K AH0 M IY1 S AH0'],
  "cammon": ['K AE1 M AH0 N'],
  "cammy": ['K AE1 M IY0'],
  "camomile": ['K AE2 M AH0 M AY1 L', 'K AE2 M AH0 M IY1 L'],
  "camorra": ['K AH0 M AO1 R AH0'],
  "camouflage": ['K AE1 M AH0 F L AA2 ZH'],
  "camouflaged": ['K AE1 M AH0 F L AA2 ZH D'],
  "camouflaging": ['K AE1 M AH0 F L AA2 ZH IH0 NG'],
  "camp": ['K AE1 M P'],
  "camp's": ['K AE1 M P S'],
  "campa": ['K AE1 M P AH0'],
  "campaign": ['K AE0 M P EY1 N'],
  "campaign's": ['K AE0 M P EY1 N Z'],
  "campaigned": ['K AE0 M P EY1 N D'],
  "campaigner": ['K AE0 M P EY1 N ER0'],
  "campaigners": ['K AE0 M P EY1 N ER0 Z'],
  "campaigning": ['K AE0 M P EY1 N IH0 NG'],
  "campaigns": ['K AE0 M P EY1 N Z'],
  "campana": ['K AA0 M P AE1 N AH0'],
  "campanale": ['K AA0 M P AA0 N AA1 L IY0'],
  "campanaro": ['K AA0 M P AA0 N AA1 R OW0'],
  "campanella": ['K AE2 M P AH0 N EH1 L AH0'],
  "campanelli": ['K AA0 M P AA0 N EH1 L IY0'],
  "campanis": ['K AE2 M P AA1 N IH0 S'],
  "campau": ['K AA1 M P AW0'],
  "campbell": ['K AE1 M B AH0 L'],
  "campbell's": ['K AE1 M B AH0 L Z'],
  "campbells": ['K AE1 M B AH0 L Z'],
  "campeau": ['K AE0 M P OW1'],
  "campeau's": ['K AE0 M P OW1 Z'],
  "camped": ['K AE1 M P T'],
  "campen": ['K AE1 M P AH0 N'],
  "camper": ['K AE1 M P ER0'],
  "campers": ['K AE1 M P ER0 Z'],
  "campesinos": ['K AE2 M P EH0 S IY1 N OW0 S'],
  "campfield": ['K AE1 M P F IY2 L D'],
  "campfire": ['K AE1 M P F AY2 ER0'],
  "campfires": ['K AE1 M P F AY2 ER0 Z'],
  "campground": ['K AE1 M P G R AW2 N D'],
  "campgrounds": ['K AE1 M P G R AW2 N D Z'],
  "campi": ['K AE1 M P IY0'],
  "camping": ['K AE1 M P IH0 NG'],
  "campion": ['K AE1 M P IY0 AH0 N'],
  "campione": ['K AA0 M P IY0 OW1 N IY0'],
  "campise": ['K AA1 M P AY0 Z'],
  "campisi": ['K AA0 M P IY1 S IY0'],
  "campo": ['K AE1 M P OW0'],
  "campobasso": ['K AA0 M P OW0 B AA1 S OW0'],
  "campofrio": ['K AE2 M P AO1 F R IY0 OW0'],
  "campoli": ['K AA0 M P OW1 L IY0'],
  "campopiano": ['K AA0 M P OW0 P IY0 AA1 N OW0'],
  "campos": ['K AE1 M P OW0 Z'],
  "camps": ['K AE1 M P S'],
  "camps'": ['K AE1 M P S'],
  "campsite": ['K AE1 M P S AY2 T'],
  "campsites": ['K AE1 M P S AY2 T S'],
  "campton": ['K AE1 M P T AH0 N'],
  "campus": ['K AE1 M P AH0 S'],
  "campuses": ['K AE1 M P AH0 S AH0 Z', 'K AE1 M P AH0 S IH0 Z'],
  "campuzano": ['K AA0 M P UW0 Z AA1 N OW0'],
  "campy": ['K AE1 M P IY0'],
  "camro": ['K AE1 M R OW0'],
  "camry": ['K AE1 M R IY0'],
  "camrys": ['K AE1 M R IY0 Z'],
  "cams": ['K AE1 M Z'],
  "camshaft": ['K AE1 M SH AE2 F T'],
  "camshafts": ['K AE1 M SH AE2 F T S'],
  "camus": ['K AE1 M IH0 S'],
  "camuso": ['K AA0 M UW1 S OW0'],
  "can": ['K AE1 N', 'K AH0 N'],
  "can's": ['K AE1 N Z'],
  "can't": ['K AE1 N T'],
  "cana": ['K AE1 N AH0'],
  "canaan": ['K EY1 N AH0 N'],
  "canaanite": ['K EY1 N AH0 N AY2 T'],
  "canace": ['K AA0 N AA1 CH IY0'],
  "canada": ['K AE1 N AH0 D AH0'],
  "canada's": ['K AE1 N AH0 D AH0 Z'],
  "canadair": ['K AE2 N AH0 D EH1 R'],
  "canaday": ['K AE1 N AH0 D EY2'],
  "canadian": ['K AH0 N EY1 D IY0 AH0 N'],
  "canadian's": ['K AH0 N EY1 D IY0 AH0 N Z'],
  "canadians": ['K AH0 N EY1 D IY0 AH0 N Z'],
  "canadians'": ['K AH0 N EY1 D IY0 AH0 N Z'],
  "canadienne": ['K AH0 N AE1 D IY0 EH2 N'],
  "canady": ['K AH0 N AA1 D IY0'],
  "canal": ['K AH0 N AE1 L'],
  "canal's": ['K AH0 N AE1 L Z'],
  "canale": ['K AA0 N AA1 L IY0'],
  "canales": ['K AE1 N AH0 L Z'],
  "canals": ['K AH0 N AE1 L Z'],
  "canam": ['K AE1 N AH0 M'],
  "canan": ['K EY1 N AH0 N'],
  "canandaigua": ['K AE2 N AH0 N D EY1 G W AH0'],
  "cananea": ['K AE2 N AH0 N IY1 AH0'],
  "canape": ['K AA1 N AH0 P EY2'],
  "canapes": ['K AA1 N AH0 P EY2 Z'],
  "canard": ['K AH0 N AA1 R D'],
  "canariensis": ['K AH0 N EH2 R IY0 EH1 N S AH0 S'],
  "canaries": ['K AH0 N EH1 R IY0 Z'],
  "canary": ['K AH0 N EH1 R IY0'],
  "canas": ['K AE1 N AH0 Z'],
  "canasta": ['K AH0 N AE1 S T AH0'],
  "canavan": ['K AE1 N AH0 V AE2 N'],
  "canaveral": ['K AH0 N AE1 V ER0 AH0 L', 'K AH0 N AE1 V R AH0 L'],
  "canberra": ['K AE2 N B EH1 R AH0'],
  "canby": ['K AE1 N B IY0'],
  "cancan": ['K AE1 N K AE2 N'],
  "cancel": ['K AE1 N S AH0 L'],
  "canceled": ['K AE1 N S AH0 L D'],
  "canceling": ['K AE1 N S AH0 L IH0 NG', 'K AE1 N S L IH0 NG'],
  "cancellation": ['K AE2 N S AH0 L EY1 SH AH0 N'],
  "cancellations": ['K AE2 N S AH0 L EY1 SH AH0 N Z'],
  "cancelled": ['K AE1 N S AH0 L D'],
  "cancelling": ['K AE1 N S AH0 L IH0 NG', 'K AE1 N S L IH0 NG'],
  "cancels": ['K AE1 N S AH0 L Z'],
  "cancer": ['K AE1 N S ER0'],
  "cancer's": ['K AE1 N S ER0 Z'],
  "cancerous": ['K AE1 N S ER0 AH0 S'],
  "cancerphobia": ['K AE2 N S ER0 F OW1 B IY0 AH0'],
  "cancers": ['K AE1 N S ER0 Z'],
  "canchola": ['K AA0 N K OW1 L AH0'],
  "cancienne": ['K AA0 N CH IY1 EH0 N'],
  "cancilla": ['K AA0 N CH IH1 L AH0'],
  "cancino": ['K AA0 N CH IY1 N OW0'],
  "cancio": ['K AE1 N S IY0 OW0'],
  "cancom": ['K AE1 NG K AH0 M'],
  "cancro": ['K AA1 N K R OW0'],
  "cancun": ['K AE1 NG K AH0 N', 'K AA2 NG K UW1 N'],
  "candace": ['K AE1 N D AH0 S'],
  "candee": ['K AE1 N D IY1'],
  "candela": ['K AE2 N D EH1 L AH0'],
  "candelabra": ['K AE2 N D AH0 L AA1 B R AH0'],
  "candelaria": ['K AA0 N D EH0 L AA1 R IY0 AH0'],
  "candelario": ['K AA0 N D EH0 L AA1 R IY0 OW0'],
  "candella": ['K AA0 N D EH1 L AH0'],
  "candellin": ['K AE1 N D AH0 L IH0 N'],
  "candia": ['K AE1 N D IY0 AH0'],
  "candice": ['K AE1 N D IH0 S'],
  "candice's": ['K AE1 N D IH0 S IH0 Z'],
  "candid": ['K AE1 N D AH0 D', 'K AE1 N D IH0 D'],
  "candida": ['K AE1 N D IH0 D AH0'],
  "candidacies": ['K AE1 N D AH0 D AH0 S IY0 Z'],
  "candidacy": ['K AE1 N D IH0 D AH0 S IY0'],
  "candidate": ['K AE1 N D AH0 D EY0 T', 'K AE1 N AH0 D IH0 T'],
  "candidate's": ['K AE1 N D AH0 D EY0 T S'],
  "candidates": ['K AE1 N D AH0 D EY0 T S', 'K AE1 N AH0 D IH0 T S'],
  "candidates'": ['K AE1 N D AH0 D EY0 T S'],
  "candidly": ['K AE1 N D IH0 D L IY0'],
  "candido": ['K AE0 N D IY1 D OW0'],
  "candie": ['K AE1 N D IY0'],
  "candied": ['K AE1 N D IY0 D'],
  "candies": ['K AE1 N D IY0 Z'],
  "candilin": ['K AE1 N D IH0 L IH0 N'],
  "candiotti": ['K AE2 N D IY0 AA1 T IY0'],
  "candiotti's": ['K AE2 N D IY0 AA1 T IY0 Z'],
  "candle": ['K AE1 N D AH0 L'],
  "candlelight": ['K AE1 N D AH0 L L AY2 T'],
  "candlemaker": ['K AE1 N D AH0 L M EY2 K ER0'],
  "candler": ['K AE1 N D AH0 L ER0', 'K AE1 N D L ER0'],
  "candles": ['K AE1 N D AH0 L Z'],
  "candlestick": ['K AE1 N D AH0 L S T IH2 K'],
  "candlesticks": ['K AE1 N D AH0 L S T IH2 K S'],
  "candlish": ['K AE1 N D L IH0 SH'],
  "candor": ['K AE1 N D ER0'],
  "candy": ['K AE1 N D IY0'],
  "candy's": ['K AE1 N D IY0 Z'],
  "candyman": ['K AE1 N D IY0 M AE0 N'],
  "cane": ['K EY1 N'],
  "cane-grass": ['K EY1 N G R AE2 S'],
  "caned": ['K EY1 N D'],
  "canedo": ['K AA0 N EY1 D OW0'],
  "canedy": ['K AH0 N IY1 D IY0'],
  "canelo": ['K AH0 N EH1 L OW0'],
  "canepa": ['K AA0 N EH1 P AH0'],
  "canes": ['K EY1 N Z'],
  "canevari": ['K AA0 N EH0 V AA1 R IY0'],
  "canez": ['K AA0 N EH1 Z'],
  "canfield": ['K AE1 N F IY2 L D'],
  "canfor": ['K AE1 N F ER0'],
  "canfor's": ['K AE1 N F ER0 Z'],
  "cangelosi": ['K AA0 NG G EH0 L OW1 S IY0'],
  "cangemi": ['K AA0 NG G EH1 M IY0'],
  "cangialosi": ['K AA0 N JH AH0 L OW1 S IY0'],
  "cangiano": ['K AA0 NG G IY0 AA1 N OW0'],
  "canham": ['K AE1 N HH AH0 M'],
  "canida": ['K AA0 N IY1 D AH0'],
  "caniglia": ['K AH0 N IH1 G L IY0 AH0'],
  "canilles": ['K AH0 N IH1 L IY0 Z'],
  "canin": ['K EY1 N IH0 N'],
  "canine": ['K EY1 N AY2 N'],
  "canines": ['K EY1 N AY2 N Z'],
  "caning": ['K EY1 N IH0 NG'],
  "canings": ['K EY1 N IH0 NG Z'],
  "canino": ['K AA0 N IY1 N OW0'],
  "canion": ['K AE1 N Y AH0 N'],
  "canipe": ['K AA0 N IY1 P IY0'],
  "canister": ['K AE1 N AH0 S T ER0', 'K AE1 N IH0 S T ER0'],
  "canisters": ['K AE1 N AH0 S T ER0 Z', 'K AE1 N IH0 S T ER0 Z'],
  "canker": ['K AE1 NG K ER0'],
  "cankers": ['K AE1 NG K ER0 Z'],
  "cann": ['K AE1 N'],
  "cannabis": ['K AE1 N AH0 B AH0 S'],
  "cannaday": ['K AE1 N AH0 D EY2'],
  "cannady": ['K AE1 N AH0 D IY0'],
  "cannan": ['K AE1 N AH0 N'],
  "cannata": ['K AA0 N AA1 T AH0'],
  "cannavino": ['K AE2 N AH0 V IY1 N OW0'],
  "cannavo": ['K AA0 N AA1 V OW0'],
  "canned": ['K AE1 N D'],
  "cannedy": ['K AE1 N IH0 D IY0'],
  "cannell": ['K AE1 N AH0 L'],
  "cannella": ['K AA0 N EH1 L AH0'],
  "cannelloni": ['K AE2 N AH0 L OW1 N IY0'],
  "cannelton": ['K AE1 N AH0 L T AH0 N'],
  "canner": ['K AE1 N ER0'],
  "canneries": ['K AE1 N ER0 IY0 Z'],
  "cannery": ['K AE1 N ER0 IY0'],
  "cannes": ['K AE1 N Z', 'K AE1 N'],
  "canney": ['K AE1 N IY0'],
  "cannibal": ['K AE1 N AH0 B AH0 L'],
  "cannibalism": ['K AE1 N AH0 B AH0 L IH2 Z AH0 M'],
  "cannibalization": ['K AE2 N AH0 B AH0 L IH0 Z EY1 SH AH0 N'],
  "cannibalize": ['K AE1 N AH0 B AH0 L AY2 Z'],
  "cannibalizing": ['K AE1 N AH0 B AH0 L AY2 Z IH0 NG'],
  "cannibals": ['K AE1 N AH0 B AH0 L Z'],
  "canniff": ['K AE1 N IH0 F'],
  "canning": ['K AE1 N IH0 NG'],
  "cannister": ['K AE1 N IH0 S T ER0'],
  "cannisters": ['K AE1 N IH0 S T ER0 Z'],
  "cannistraro": ['K AE2 N IH0 S T R AA1 R OW0'],
  "cannizzaro": ['K AA0 N IY0 T S AA1 R OW0'],
  "cannizzo": ['K AA0 N IY1 Z OW0'],
  "cannock": ['K AE1 N AH0 K'],
  "cannon": ['K AE1 N AH0 N'],
  "cannon's": ['K AE1 N AH0 N Z'],
  "cannonball": ['K AE1 N AH0 N B AO2 L'],
  "cannondale": ['K AE1 N AH0 N D EY2 L'],
  "cannone": ['K AA0 N OW1 N IY0'],
  "cannons": ['K AE1 N AH0 N Z'],
  "cannonsburg": ['K AE1 N AH0 N Z B ER0 G'],
  "cannot": ['K AE1 N AA0 T', 'K AH0 N AA1 T'],
  "canny": ['K AE1 N IY0'],
  "cano": ['K AA1 N OW0'],
  "canoe": ['K AH0 N UW1'],
  "canoed": ['K AH0 N UW1 D'],
  "canoeing": ['K AH0 N UW1 IH0 NG'],
  "canoeist": ['K AH0 N UW1 AH0 S T'],
  "canoes": ['K AH0 N UW1 Z'],
  "canoga": ['K AH0 N OW1 G AH0'],
  "canola": ['K AH0 N OW1 L AH0'],
  "canon": ['K AE1 N AH0 N'],
  "canon's": ['K AE1 N AH0 N Z'],
  "canonic": ['K AH0 N AA1 N AH0 K'],
  "canonical": ['K AH0 N AA1 N AH0 K AH0 L'],
  "canonico": ['K AA0 N OW0 N IY1 K OW0'],
  "canonie": ['K AE1 N AH0 N IY0'],
  "canonization": ['K AE2 N AH0 N AH0 Z EY1 SH AH0 N'],
  "canonize": ['K AE1 N AH0 N AY2 Z'],
  "canonized": ['K AE1 N AH0 N AY2 Z D'],
  "canons": ['K AE1 N AH0 N Z'],
  "canopy": ['K AE1 N AH0 P IY0'],
  "canosa": ['K AH0 N OW1 S AH0'],
  "canova": ['K AA0 N OW1 V AH0'],
  "canoy": ['K AE1 N OY0'],
  "canrad": ['K AE1 N R AE0 D'],
  "canron": ['K AE1 N R AH0 N'],
  "cans": ['K AE1 N Z'],
  "canseco": ['K AE2 N S EH1 K OW0'],
  "cansler": ['K AE1 N S AH0 L ER0', 'K AE1 N S L ER0'],
  "canso": ['K AE1 N S OW0'],
  "canson": ['K AE1 N S AH0 N'],
  "canstar": ['K AE1 N S T AA2 R'],
  "cant": ['K AE1 N T'],
  "cantaloupe": ['K AE1 N T AH0 L OW2 P'],
  "cantaloupes": ['K AE1 N T AH0 L OW2 P S'],
  "cantalupo": ['K AE2 N T AH0 L UW1 P OW0'],
  "cantankerous": ['K AE0 N T AE1 NG K ER0 AH0 S'],
  "cantara": ['K AA0 N T AA1 R AH0'],
  "cantata": ['K AE2 N T AA1 T AH0'],
  "canteen": ['K AE0 N T IY1 N'],
  "canteens": ['K AE0 N T IY1 N Z'],
  "cantel": ['K AE1 N T EH2 L'],
  "canter": ['K AE1 N T ER0'],
  "canterbury": ['K AE1 N T ER0 B EH2 R IY0'],
  "canterbury's": ['K AE1 N T ER0 B EH2 R IY0 Z', 'K AE1 N ER0 B EH2 R IY0 Z'],
  "cantero": ['K AA0 N T EH1 R OW0'],
  "canterra": ['K AA2 N T EH1 R AH0'],
  "cantey": ['K AE1 N T IY0'],
  "cantin": ['K AA0 N T IY1 N'],
  "cantina": ['K AE2 N T IY1 N AH0'],
  "cantle": ['K AE1 N T AH0 L'],
  "cantley": ['K AE1 N T L IY0'],
  "cantlin": ['K AE1 N T L IH0 N'],
  "cantlon": ['K AE1 N T L AH0 N'],
  "canto": ['K AE1 N T OW0'],
  "canton": ['K AE1 N T AH0 N'],
  "cantonal": ['K AE1 N T AH0 N AH0 L'],
  "cantone": ['K AA0 N T OW1 N IY0'],
  "cantonese": ['K AE2 N T AH0 N IY1 Z'],
  "cantons": ['K AE1 N T AH0 N Z'],
  "cantor": ['K AE1 N T ER0'],
  "cantor's": ['K AE1 N T ER0 Z'],
  "cantore": ['K AE1 N T AO2 R'],
  "cantors": ['K AE1 N T ER0 Z'],
  "cantrall": ['K AE1 N T R AH0 L'],
  "cantrell": ['K AE0 N T R EH1 L'],
  "cantrelle": ['K AH0 N T R EH1 L'],
  "cantu": ['K AE1 N T UW0'],
  "cantv": ['K AE1 N T IY1 V IY1'],
  "cantwell": ['K AE1 N T W EH2 L'],
  "canty": ['K AE1 N T IY0'],
  "canuck": ['K EY1 N AH0 K', 'K AH0 N AH1 K'],
  "canucks": ['K EY1 N AH0 K S', 'K AH0 N AH1 K S'],
  "canup": ['K AE1 N AH2 P'],
  "canupp": ['K AE1 N AH0 P'],
  "canvas": ['K AE1 N V AH0 S'],
  "canvases": ['K AE1 N V AH0 S IH0 Z'],
  "canvass": ['K AE1 N V AH0 S'],
  "canvassed": ['K AE1 N V AH0 S T'],
  "canvassers": ['K AE1 N V AH0 S ER0 Z'],
  "canvasses": ['K AE1 N V AH0 S IH0 Z'],
  "canvassing": ['K AE1 N V AH0 S IH0 NG'],
  "canwest": ['K AE1 N W EH2 S T'],
  "canyon": ['K AE1 N Y AH0 N'],
  "canyon's": ['K AE1 N Y AH0 N Z'],
  "canyons": ['K AE1 N Y AH0 N Z'],
  "canzano": ['K AA0 N Z AA1 N OW0'],
  "canzoneri": ['K AA0 N Z OW0 N EH1 R IY0'],
  "cao": ['K AW1'],
  "caouette": ['K AE2 W EH1 T'],
  "cap": ['K AE1 P'],
  "cap's": ['K AE1 P S'],
  "capabilities": ['K EY2 P AH0 B IH1 L AH0 T IY0 Z'],
  "capability": ['K EY2 P AH0 B IH1 L AH0 T IY0'],
  "capable": ['K EY1 P AH0 B AH0 L'],
  "capacious": ['K AH0 P EY1 SH AH0 S'],
  "capacitance": ['K AH0 P AE1 S AH0 T AH0 N S'],
  "capacities": ['K AH0 P AE1 S AH0 T IY0 Z', 'K AH0 P AE1 S IH0 T IY0 Z'],
  "capacitor": ['K AH0 P AE1 S AH0 T ER0'],
  "capacitors": ['K AH0 P AE1 S AH0 T ER0 Z', 'K AH0 P AE1 S IH0 T ER0 Z'],
  "capacity": ['K AH0 P AE1 S AH0 T IY0', 'K AH0 P AE1 S IH0 T IY0'],
  "capalbo": ['K AH0 P AE1 L B OW0'],
  "capaldi": ['K AA0 P AA1 L D IY0'],
  "capaldo": ['K AA0 P AA1 L D OW0'],
  "capano": ['K AA0 P AA1 N OW0'],
  "capasso": ['K AA0 P AA1 S OW0'],
  "capcha": ['K AA1 P CH AH0'],
  "capcom": ['K AE1 P K AH0 M'],
  "cape": ['K EY1 P'],
  "cape-town": ['K EY1 P T AW2 N'],
  "capece": ['K AH0 P IY1 S'],
  "capeci": ['K AH0 P EH1 CH IY0', 'K AH0 P IY1 CH IY0'],
  "caped": ['K EY1 P T'],
  "capehart": ['K EY1 P HH AA2 R T'],
  "capek": ['K AE1 P IH0 K'],
  "capel": ['K EY1 P AH0 L'],
  "capel's": ['K AE1 P AH0 L Z'],
  "capell": ['K AA0 P EY1 L'],
  "capella": ['K AH0 P EH1 L AH0'],
  "capelle": ['K AH0 P EH1 L'],
  "capelli": ['K AH0 P EH1 L IY0'],
  "capello": ['K AH0 P EH1 L OW0'],
  "capen": ['K EY1 P AH0 N'],
  "caper": ['K EY1 P ER0'],
  "capernaum": ['K AH0 P ER1 N IY0 AH0 M'],
  "capers": ['K EY1 P ER0 Z'],
  "caperton": ['K EY1 P ER0 T AH0 N'],
  "capes": ['K EY1 P S'],
  "capetillo": ['K AA0 P EH0 T IH1 L OW0'],
  "capetown": ['K EY1 P T AW2 N'],
  "capillaries": ['K AE1 P AH0 L EH2 R IY0 Z'],
  "capillary": ['K AE1 P AH0 L EH2 R IY0'],
  "capistrano": ['K AE2 P IH0 S T R AA1 N OW0'],
  "capita": ['K AE1 P IH0 T AH0'],
  "capital": ['K AE1 P AH0 T AH0 L', 'K AE1 P IH0 T AH0 L'],
  "capital's": ['K AE1 P IH0 T AH0 L Z'],
  "capitalism": ['K AE1 P IH0 T AH0 L IH2 Z AH0 M'],
  "capitalism's": ['K AE1 P AH0 T AH0 L IH2 Z AH0 M Z'],
  "capitalist": ['K AE1 P AH0 T AH0 L IH0 S T'],
  "capitalistic": ['K AE2 P IH0 T AH0 L IH1 S T IH0 K'],
  "capitalists": ['K AE1 P AH0 T AH0 L IH0 S T S'],
  "capitalization": ['K AE2 P IH0 T AH0 L IH0 Z EY1 SH AH0 N'],
  "capitalizations": ['K AE2 P IH0 T AH0 L IH0 Z EY1 SH AH0 N Z'],
  "capitalize": ['K AE1 P AH0 T AH0 L AY2 Z'],
  "capitalized": ['K AE1 P IH0 T AH0 L AY2 Z D'],
  "capitalizes": ['K AE1 P AH0 T AH0 L AY2 Z IH0 Z'],
  "capitalizing": ['K AE1 P IH0 T AH0 L AY2 Z IH0 NG'],
  "capitals": ['K AE1 P AH0 T AH0 L Z', 'K AE1 P IH0 T AH0 L Z'],
  "capitan": ['K AE1 P IH0 T AH0 N'],
  "capitano": ['K AA0 P IY0 T AA1 N OW0'],
  "capito": ['K AA0 P IY1 T OW0'],
  "capitol": ['K AE1 P IH0 T AH0 L'],
  "capitol's": ['K AE1 P IH0 T AH0 L Z'],
  "capitoline": ['K AE1 P IH0 T OW2 L AY2 N'],
  "capitols": ['K AE1 P IH0 T AH0 L Z'],
  "capitulate": ['K AH0 P IH1 CH UW0 L IH0 T', 'K AH0 P IH1 CH UW0 L EY0 T'],
  "capitulated": ['K AH0 P IH1 CH AH0 L EY2 T IH0 D'],
  "capitulation": ['K AH0 P IH2 CH AH0 L EY1 SH AH0 N'],
  "capizzi": ['K AA0 P IY1 T S IY0'],
  "caplan": ['K AE1 P L AH0 N'],
  "caple": ['K EY1 P AH0 L'],
  "caples": ['K EY1 P AH0 L Z'],
  "capley": ['K AE1 P L IY0'],
  "caplin": ['K AE1 P L IH0 N'],
  "caplinger": ['K EY1 P AH0 L IH0 NG ER0', 'K EY1 P L IH0 NG ER0', 'K AE1 P L IH0 N JH ER0'],
  "capo": ['K AA1 P OW0'],
  "capobianco": ['K AA0 P OW0 B IY0 AA1 N K OW0'],
  "capon": ['K EY1 P AA2 N'],
  "capone": ['K AH0 P OW1 N'],
  "caponi": ['K AA0 P OW1 N IY0'],
  "caponigro": ['K AA0 P OW0 N IY1 G R OW0'],
  "caporale": ['K AA0 P AO0 R AA1 L IY0'],
  "caporaso": ['K AA0 P AO0 R AA1 S OW0'],
  "capote": ['K AH0 P OW1 T'],
  "capote's": ['K AH0 P OW1 T S'],
  "capoten": ['K AH0 P OW1 T AH0 N'],
  "capozzi": ['K AH0 P AA1 Z IY0'],
  "capozzoli": ['K AA0 P OW0 T S OW1 L IY0'],
  "capp": ['K AE1 P'],
  "cappa": ['K AE1 P AH0'],
  "cappadona": ['K AA0 P AA0 D OW1 N AH0'],
  "capparelli": ['K AA0 P AA0 R EH1 L IY0'],
  "capped": ['K AE1 P T'],
  "cappel": ['K AE1 P AH0 L'],
  "cappella": ['K AA0 P EH1 L AH0'],
  "cappelletti": ['K AA0 P EH0 L EH1 T IY0'],
  "cappelli": ['K AA0 P EH1 L IY0'],
  "cappello": ['K AA0 P EH1 L OW0'],
  "capper": ['K AE1 P ER0'],
  "cappetta": ['K AA0 P EH1 T AH0'],
  "cappiello": ['K AA0 P IY0 EH1 L OW0'],
  "capping": ['K AE1 P IH0 NG'],
  "cappo": ['K AE1 P OW0'],
  "capps": ['K AE1 P S'],
  "cappuccino": ['K AE2 P UW2 CH IY1 N OW0'],
  "cappuccio": ['K AA0 P UW1 CH IY0 OW0'],
  "cappy": ['K AE1 P IY0'],
  "cappy's": ['K AE1 P IY0 Z'],
  "capra": ['K AE1 P R AH0'],
  "capraro": ['K AA0 P R AA1 R OW0'],
  "caprese": ['K AA2 P R EY1 S EY0'],
  "capri": ['K AE1 P R IY0', 'K AH0 P R IY1'],
  "capri's": ['K AE1 P R IY0 Z', 'K AH0 P R IY1 Z'],
  "capriati": ['K AE2 P R IY0 AA1 T IY0'],
  "capriati's": ['K AE2 P R IY0 AA1 T IY0 Z'],
  "caprica": ['K AE1 P R IH0 K AH0'],
  "caprice": ['K AH0 P R IY1 S'],
  "caprices": ['K AH0 P R IY1 S AH0 Z'],
  "capricious": ['K AH0 P R IH1 SH AH0 S'],
  "capriciously": ['K AH0 P R IH1 SH IH0 S L IY0'],
  "capricorn": ['K AE1 P R AH0 K AO0 R N'],
  "caprio": ['K AE1 P R IY0 OW0'],
  "capriotti": ['K AA0 P R IY0 OW1 T IY0'],
  "capron": ['K AE1 P R AH0 N'],
  "caps": ['K AE1 P S'],
  "capsaicin": ['K AE2 P S EY1 S AH0 N'],
  "capshaw": ['K AE1 P SH AO2'],
  "capsize": ['K AE1 P S AY2 Z'],
  "capsized": ['K AE1 P S AY2 Z D'],
  "capstan": ['K AE1 P S T AH0 N'],
  "capstick": ['K AE1 P S T IH2 K'],
  "capstone": ['K AE1 P S T OW2 N'],
  "capsule": ['K AE1 P S AH0 L'],
  "capsules": ['K AE1 P S AH0 L Z'],
  "captain": ['K AE1 P T AH0 N'],
  "captain's": ['K AE1 P T AH0 N Z'],
  "captaining": ['K AE1 P T AH0 N IH0 NG'],
  "captains": ['K AE1 P T AH0 N Z'],
  "caption": ['K AE1 P SH AH0 N'],
  "captioned": ['K AE1 P SH AH0 N D'],
  "captioning": ['K AE1 P SH AH0 N IH0 NG'],
  "captions": ['K AE1 P SH AH0 N Z'],
  "captivate": ['K AE1 P T IH0 V EY2 T'],
  "captivated": ['K AE1 P T IH0 V EY2 T IH0 D'],
  "captivates": ['K AE1 P T IH0 V EY2 T S'],
  "captivating": ['K AE1 P T IH0 V EY2 T IH0 NG'],
  "captive": ['K AE1 P T IH0 V'],
  "captives": ['K AE1 P T IH0 V Z'],
  "captivity": ['K AE0 P T IH1 V AH0 T IY0'],
  "captor": ['K AE1 P T ER0'],
  "captors": ['K AE1 P T ER0 Z'],
  "capture": ['K AE1 P CH ER0'],
  "captured": ['K AE1 P CH ER0 D'],
  "captures": ['K AE1 P CH ER0 Z'],
  "capturing": ['K AE1 P CH ER0 IH0 NG'],
  "capua": ['K AE1 P Y UW0 AH0'],
  "capuano": ['K AA0 P UW0 AA1 N OW0'],
  "capulet": ['K AE1 P UW0 L EH2 T'],
  "caput": ['K AH0 P UH1 T'],
  "caputi": ['K AA0 P UW1 T IY0'],
  "caputo": ['K AA0 P UW1 T OW0'],
  "capwell": ['K AE1 P W EH2 L'],
  "caq": ['K AE1 K'],
  "caq's": ['K AE1 K S'],
  "car": ['K AA1 R'],
  "car's": ['K AA1 R Z'],
  "cara": ['K EH1 R AH0'],
  "cara's": ['K EH1 R AH0 Z'],
  "cara'veras": ['K AA2 R AH0 V EH1 R AH0 Z'],
  "carabajal": ['K ER0 AE1 B AH0 JH AH0 L'],
  "caraballo": ['K AE2 R AH0 B AE1 L OW0'],
  "carabello": ['K AE2 R AH0 B EH1 L OW0'],
  "caracara": ['K AA2 R AH0 K AA1 R AH0'],
  "caracas": ['K ER0 AA1 K AH0 S'],
  "caracci": ['K AA0 R AA1 CH IY0'],
  "caracciolo": ['K AA0 R AA0 K CH OW1 L OW0'],
  "caradine": ['K AA0 R AA0 D IY1 N IY0'],
  "caradonna": ['K AA0 R AA0 D OW1 N AH0'],
  "carafate": ['K EH1 R AH0 F EY2 T'],
  "carafe": ['K AH0 R AE1 F'],
  "caraher": ['K AE1 R AH0 HH ER0'],
  "caraker": ['K AE1 R AH0 K ER0'],
  "caramandi": ['K AE2 R AH0 M AA1 N D IY0'],
  "caramel": ['K EH1 R AH0 M AH0 L'],
  "caramelize": ['K EH1 R AH0 M AH0 L AY2 Z'],
  "caramelized": ['K EH1 R AH0 M AH0 L AY2 Z D'],
  "carangelo": ['K AA0 R AA0 NG G EH1 L OW0'],
  "carano": ['K AA0 R AA1 N OW0'],
  "caras": ['K AA1 R AH0 Z'],
  "carat": ['K EH1 R AH0 T'],
  "carats": ['K EH1 R AH0 T S'],
  "carava": ['K AE1 R AH0 V AH0'],
  "caravan": ['K AE1 R AH0 V AE2 N', 'K EH1 R AH0 V AE2 N'],
  "caravans": ['K AE1 R AH0 V AE2 N Z', 'K EH1 R AH0 V AE2 N Z'],
  "caravel": ['K EH1 R AH0 V EH2 L'],
  "caravella": ['K AE2 R AH0 V EH1 L AH0'],
  "caravelle": ['K EH1 R AH0 V EH2 L'],
  "caravello": ['K AE2 R AH0 V EH1 L OW0'],
  "caraveo": ['K AA0 R AA1 V IY0 OW0'],
  "carawan": ['K AE1 R AH0 W AE0 N'],
  "caraway": ['K AE1 R AH0 W EY2', 'K EH1 R AH0 W EY2'],
  "carb": ['K AA1 R B'],
  "carbajal": ['K AA0 R B AA0 Y AE1 L'],
  "carballo": ['K AA0 R B AA1 L OW0'],
  "carbary": ['K AA1 R B EH0 R IY0'],
  "carbaugh": ['K AA1 R B AO2'],
  "carberry": ['K AA1 R B EH2 R IY0'],
  "carberry's": ['K AA1 R B EH2 R IY0 Z'],
  "carbide": ['K AA1 R B AY2 D'],
  "carbide's": ['K AA1 R B AY2 D Z'],
  "carbin": ['K AA1 R B IH0 N'],
  "carbine": ['K AA1 R B AY2 N'],
  "carbo": ['K AA1 R B OW0'],
  "carbohydrate": ['K AA2 R B OW0 HH AY1 D R EY2 T', 'K AA2 R B OW0 HH AY1 D R AH0 T'],
  "carbohydrates": ['K AA2 R B OW0 HH AY1 D R EY0 T S'],
  "carbon": ['K AA1 R B AH0 N'],
  "carbonara": ['K AA0 R B OW0 N AA1 R AH0'],
  "carbonaro": ['K AA0 R B OW0 N AA1 R OW0'],
  "carbonate": ['K AA1 R B AH0 N EY2 T'],
  "carbonated": ['K AA1 R B AH0 N EY2 T IH0 D'],
  "carbonates": ['K AA1 R B AH0 N EY2 T S'],
  "carbondale": ['K AA1 R B AH0 N D EY2 L'],
  "carbone": ['K AA1 R B OW2 N'],
  "carbonell": ['K AA2 R B AH0 N EH1 L'],
  "carbonetto": ['K AA2 R B OW0 N EH1 T OW0'],
  "carboni": ['K AA0 R B OW1 N IY0'],
  "carbonic": ['K AA0 R B AO1 N IH0 K'],
  "carboniferous": ['K AA2 R B AH0 N IH1 F ER0 AH0 S'],
  "carbonize": ['K AA1 R B AH0 N AY2 Z'],
  "carbonized": ['K AA1 R B AH0 N AY2 Z D'],
  "carbonneau": ['K AA1 R B AH0 N OW2'],
  "carbons": ['K AA1 R B AH0 N Z'],
  "carborundum": ['K AA2 R B ER0 AH1 N D AH0 M'],
  "carboxylic": ['K AA0 R B AO0 K S IH1 L IH0 K'],
  "carboy": ['K AA1 R B OY2'],
  "carbs": ['K AA1 R B Z'],
  "carburete": ['K AA1 R B Y ER0 EH2 T'],
  "carbureted": ['K AA1 R B Y ER0 EH2 T IH0 D'],
  "carburetion": ['K AA2 R B Y ER0 IY1 SH AH0 N'],
  "carburetor": ['K AA1 R B ER0 EY2 T ER0'],
  "carburetors": ['K AA1 R B ER0 EY2 T ER0 Z'],
  "carby": ['K AA1 R B IY2'],
  "carcass": ['K AA1 R K AH0 S'],
  "carcasses": ['K AA1 R K AH0 S IH0 Z'],
  "carcinogen": ['K AA0 R S IH1 N AH0 JH AH0 N'],
  "carcinogenic": ['K AA2 R S AH0 N OW0 JH EH1 N IH0 K'],
  "carcinogens": ['K AA0 R S IH1 N AH0 JH AH0 N Z'],
  "carcinoma": ['K AA2 R S AH0 N OW1 M AH0'],
  "carcione": ['K AA0 R CH OW1 N IY0'],
  "card": ['K AA1 R D'],
  "card's": ['K AA1 R D Z'],
  "carda": ['K AA1 R D AH0'],
  "cardamom": ['K AA1 R D AH0 M AH0 M'],
  "cardarelli": ['K AA0 R D AA0 R EH1 L IY0'],
  "cardassian": ['K AH0 R D AA1 S IY0 AH0 N'],
  "cardassians": ['K AH0 R D AA1 S IY0 AH0 N Z'],
  "cardboard": ['K AA1 R D B AO2 R D'],
  "cardboards": ['K AA1 R D B AO2 R D Z'],
  "carded": ['K AA1 R D IH0 D'],
  "cardell": ['K AA0 R D EY1 L'],
  "cardella": ['K AA2 R D EH1 L AH0'],
  "cardello": ['K AA2 R D EH1 L OW0'],
  "carden": ['K AA1 R D AH0 N'],
  "cardenas": ['K AA0 R D IY1 N AH0 S'],
  "cardenas's": ['K AA0 R D IY1 N AH0 S IH0 Z'],
  "carder": ['K AA1 R D ER0'],
  "cardholder": ['K AA1 R D HH OW2 L D ER0'],
  "cardholders": ['K AA1 R D HH OW2 L D ER0 Z'],
  "cardia": ['K AA1 R D IY0 AH0'],
  "cardiac": ['K AA1 R D IY0 AE2 K'],
  "cardiel": ['K AA1 R D IY2 L'],
  "cardiff": ['K AA1 R D IH0 F'],
  "cardigan": ['K AA1 R D IH0 G AH0 N'],
  "cardigans": ['K AA1 R D IH0 G AH0 N Z'],
  "cardiges": ['K AA1 R D IH0 JH IH0 Z'],
  "cardillo": ['K AA2 R D IH1 L OW0'],
  "cardin": ['K AA1 R D IH0 N'],
  "cardinal": ['K AA1 R D AH0 N AH0 L', 'K AA1 R D IH0 N AH0 L'],
  "cardinal's": ['K AA1 R D AH0 N AH0 L Z'],
  "cardinale": ['K AA0 R D IY0 N AA1 L IY0'],
  "cardinali": ['K AA0 R D IY0 N AA1 L IY2'],
  "cardinals": ['K AA1 R D AH0 N AH0 L Z'],
  "cardinals'": ['K AA1 R D IH0 N AH0 L Z'],
  "cardio": ['K AA1 R D IY0 OW0'],
  "cardiologist": ['K AA2 R D IY0 AA1 L AH0 JH IH0 S T'],
  "cardiologists": ['K AA2 R D IY0 AA1 L AH0 JH IH0 S T S'],
  "cardiology": ['K AA2 R D IY0 AA1 L AH0 JH IY0'],
  "cardiomyopathy": ['K AA2 R D IY0 OW0 M AY0 AA1 P AH0 TH IY0'],
  "cardiopulmonary": ['K AA2 R D IY0 OW0 P UH1 L M AH0 N EH2 R IY0'],
  "cardiovascular": ['K AA2 R D IY0 OW0 V AE1 S K Y AH0 L ER0'],
  "cardis": ['K AA1 R D IH0 S'],
  "cardiss": ['K AA1 R D IH0 S'],
  "cardizem": ['K AA2 R D IH0 Z EH1 M'],
  "cardoen": ['K AA1 R D OW2 AH0 N'],
  "cardon": ['K AA0 R D AO1 N'],
  "cardona": ['K AA0 R D OW1 N AH0'],
  "cardone": ['K AA0 R D OW1 N IY0'],
  "cardoni": ['K AA0 R D OW1 N IY0'],
  "cardoon": ['K AA0 R D UW1 N'],
  "cardosa": ['K AA0 R D OW1 S AH0'],
  "cardosi": ['K AA0 R D OW1 S IY0'],
  "cardoso": ['K AA0 R D OW1 S OW0'],
  "cardoso's": ['K AA0 R D OW1 S OW0 Z'],
  "cardoza": ['K AA0 R D OW1 Z AH0'],
  "cardozo": ['K AA0 R D OW1 Z OW0'],
  "cards": ['K AA1 R D Z'],
  "cards'": ['K AA1 R D Z'],
  "carducci": ['K AA0 R D UW1 CH IY0'],
  "cardwell": ['K AA1 R D W EH2 L'],
  "cardy": ['K AA1 R D IY0'],
  "care": ['K EH1 R'],
  "care's": ['K EH1 R Z'],
  "cared": ['K EH1 R D'],
  "careen": ['K ER0 IY1 N'],
  "careened": ['K ER0 IY1 N D'],
  "careening": ['K ER0 IY1 N IH0 NG'],
  "careens": ['K ER0 IY1 N Z'],
  "career": ['K ER0 IH1 R'],
  "careercom": ['K ER0 IH1 R K AA0 M'],
  "careering": ['K ER0 IH1 R IH0 NG'],
  "careerism": ['K ER0 IY1 R IH0 Z AH0 M'],
  "careerist": ['K ER0 IH1 R IH0 S T'],
  "careerists": ['K ER0 IH1 R IH0 S T S'],
  "careers": ['K ER0 IH1 R Z'],
  "carefree": ['K EH1 R F R IY2'],
  "careful": ['K EH1 R F AH0 L'],
  "carefully": ['K EH1 R F AH0 L IY0'],
  "carefulness": ['K EH1 R F AH0 L N EH2 S'],
  "caregiver": ['K EH1 R G IH2 V ER0'],
  "caregivers": ['K EH1 R G IH2 V ER0 Z'],
  "caregiving": ['K EH1 R G IH2 V IH0 NG'],
  "carel": ['K AA0 R EH1 L'],
  "careless": ['K EH1 R L AH0 S'],
  "carelessly": ['K EH1 R L AH0 S L IY0'],
  "carelessness": ['K EH1 R L AH0 S N AH0 S'],
  "carella": ['K AA0 R EH1 L AA0'],
  "carelli": ['K AA0 R EH1 L IY0'],
  "carello": ['K EH2 R EH1 L OW0'],
  "carelock": ['K AE1 R IH0 L AA0 K', 'K AE1 R L AA0 K'],
  "caremark": ['K EH1 R M AA2 R K'],
  "caremark's": ['K EH1 R M AA2 R K S'],
  "carena": ['K EH2 R IY1 N AA0'],
  "careplus": ['K EH1 R P L AH1 S'],
  "carer": ['K EH1 R ER0'],
  "cares": ['K EH1 R Z'],
  "caress": ['K ER0 EH1 S'],
  "caresse": ['K AA1 R EH0 S'],
  "caressed": ['K ER0 EH1 S T'],
  "caresses": ['K ER0 EH1 S IH0 Z'],
  "caressing": ['K ER0 EH1 S IH0 NG'],
  "caretaker": ['K EH1 R T EY2 K ER0'],
  "caretakers": ['K EH1 R T EY2 K ER0 Z'],
  "careunit": ['K EH1 R Y UW2 N IH0 T'],
  "careunits": ['K EH1 R Y UW2 N IH0 T S'],
  "carew": ['K AE1 R UW0'],
  "carey": ['K EH1 R IY0'],
  "carey's": ['K EH1 R IY0 Z'],
  "carfagno": ['K AA0 R F AA1 G N OW0'],
  "carfora": ['K AA0 R F AO1 R AA0'],
  "cargal": ['K AA0 R G AE1 L'],
  "cargile": ['K AA1 R JH AH0 L'],
  "cargill": ['K AA1 R G IH2 L'],
  "cargo": ['K AA1 R G OW2'],
  "cargo's": ['K AA1 R G OW2 Z'],
  "cargoes": ['K AA1 R G OW2 Z'],
  "cargonoff": ['K AA1 R G AH0 N AO2 F'],
  "cargos": ['K AA1 R G OW2 Z'],
  "carhart": ['K AA1 R HH AA2 R T'],
  "caribbean": ['K ER0 IH1 B IY0 AH0 N', 'K EH2 R IH0 B IY1 AH0 N'],
  "caribe": ['K EH2 R IY1 B'],
  "caribou": ['K EH1 R IH0 B UW2'],
  "caricature": ['K EH1 R AH0 K AH0 CH ER0'],
  "caricatured": ['K ER0 IH1 K AH0 CH ER0 D'],
  "caricatures": ['K ER0 IH1 K AH0 CH ER0 Z'],
  "caricaturist": ['K EH1 R AH0 K AH0 CH ER0 AH0 S T'],
  "carico": ['K AA0 R IY1 K OW0'],
  "caricom": ['K EH1 R IH0 K AA2 M'],
  "caricom's": ['K EH1 R IH0 K AA2 M Z'],
  "caridad": ['K AE1 R AH0 D AE2 D'],
  "caridi": ['K AA0 R IY1 D IY0'],
  "carie": ['K EH1 R IY0'],
  "cariello": ['K AA0 R IY0 EH1 L OW0'],
  "carignan": ['K AE1 R IH0 G N AH0 N'],
  "cariker": ['K AE1 R IH0 K ER0'],
  "carilla": ['K AA0 R IH1 L AA0'],
  "carillo": ['K AA0 R IH1 L OW0'],
  "carillon": ['K EH1 R AH0 L AA2 N'],
  "carilou": ['K EH2 R AH0 L UW1'],
  "carin": ['K AE1 R IH0 N'],
  "carine": ['K AA0 R IY1 N IY0'],
  "caring": ['K EH1 R IH0 NG'],
  "carini": ['K AA0 R IY1 N IY0'],
  "carinii": ['K EH2 R IY1 N IY0'],
  "carino": ['K AA0 R IY1 N OW0'],
  "carioca": ['K EH2 R IY0 OW1 K AH0'],
  "caris": ['K AE1 R IH0 S'],
  "carisbrook": ['K EH1 R IH0 S B R UH2 K'],
  "carissa": ['K AA0 R IY1 S AH0'],
  "carita": ['K AA0 R IY1 T AH0'],
  "carithers": ['K AE1 R IH0 DH ER0 Z'],
  "carjack": ['K AA1 R JH AE2 K'],
  "carjacked": ['K AA1 R JH AE2 K T'],
  "carjacker": ['K AA1 R JH AE2 K ER0'],
  "carjacker's": ['K AA1 R JH AE2 K ER0 Z'],
  "carjackers": ['K AA1 R JH AE2 K ER0 Z'],
  "carjacking": ['K AA1 R JH AE2 K IH0 NG'],
  "carjackings": ['K AA1 R JH AE2 K IH0 NG Z'],
  "carjacks": ['K AA1 R JH AE2 K S'],
  "carkhuff": ['K AA1 R K HH AH0 F'],
  "carl": ['K AA1 R L'],
  "carl's": ['K AA1 R L Z'],
  "carla": ['K AA1 R L AA0'],
  "carla's": ['K AA1 R L AH0 Z'],
  "carlan": ['K AA1 R L AH0 N'],
  "carland": ['K AA1 R L AH0 N D'],
  "carlberg": ['K AA1 R L B ER0 G'],
  "carle": ['K AA1 R AH0 L'],
  "carleen": ['K AA0 R L IY1 N'],
  "carlen": ['K AA1 R L AH0 N'],
  "carlene": ['K AA1 R L IY2 N'],
  "carles": ['K AA1 R L Z'],
  "carleton": ['K AA1 R L T AH0 N'],
  "carley": ['K AA1 R L IY0'],
  "carli": ['K AA1 R L IY0'],
  "carlile": ['K AA1 R L AY2 L'],
  "carlin": ['K AA1 R L IH0 N'],
  "carlin's": ['K AA1 R L IH0 N Z'],
  "carliner": ['K AA1 R L AY2 N ER0'],
  "carling": ['K AA1 R L IH0 NG'],
  "carling's": ['K AA1 R L IH0 NG Z'],
  "carlini": ['K AA0 R L IY1 N IY0'],
  "carlino": ['K AA0 R L IY1 N OW0'],
  "carlisi": ['K AA0 R L IY1 S IY0'],
  "carlisle": ['K AA1 R L AY2 L'],
  "carlita": ['K AA0 R L IY1 T AA0'],
  "carlito": ['K AA0 R L IY1 T OW0'],
  "carlito's": ['K AA0 R L IY1 T OW0 Z'],
  "carll": ['K AA1 R L'],
  "carlo": ['K AA1 R L OW0'],
  "carload": ['K AA1 R L OW2 D'],
  "carloading": ['K AA1 R L OW2 D IH0 NG'],
  "carloadings": ['K AA1 R L OW2 D IH0 NG Z'],
  "carloads": ['K AA1 R L OW2 D Z'],
  "carlock": ['K AA1 R L AA2 K'],
  "carlon": ['K AA1 R L AH0 N'],
  "carlone": ['K AA0 R L OW1 N IY0'],
  "carloni": ['K AA0 R L OW1 N IY0'],
  "carlos": ['K AA1 R L OW0 S'],
  "carlotta": ['K AA0 R L AO1 T AA0'],
  "carlough": ['K AA1 R L OW0'],
  "carlow": ['K AA1 R L OW2'],
  "carls": ['K AA1 R L Z'],
  "carlsbad": ['K AA1 R L S B AE0 D'],
  "carlsberg": ['K AA1 R L Z B ER0 G'],
  "carlsen": ['K AA1 R L S AH0 N'],
  "carlson": ['K AA1 R L S AH0 N'],
  "carlson's": ['K AA1 R L S AH0 N Z'],
  "carlsson": ['K AA1 R L S AH0 N'],
  "carlstadt": ['K AA1 R L S T AE2 T'],
  "carlston": ['K AA1 R L S T AH0 N'],
  "carlstrom": ['K AA1 R L S T R AH0 M'],
  "carlton": ['K AA1 R L T AH0 N'],
  "carlton's": ['K AA1 R L T AH0 N Z'],
  "carlucci": ['K AA0 R L UW1 CH IY0'],
  "carly": ['K AA1 R L IY0'],
  "carlyle": ['K AA1 R L AY2 L'],
  "carlyle's": ['K AA0 R L AY1 L Z'],
  "carlyon": ['K AA1 R L IY0 AA0 N'],
  "carlzon": ['K AA1 R L Z AA0 N'],
  "carma": ['K AA1 R M AA0'],
  "carmack": ['K AA1 R M AH0 K'],
  "carmaker": ['K AA1 R M EY2 K ER0'],
  "carmaker's": ['K AA1 R M EY2 K ER0 Z'],
  "carmakers": ['K AA1 R M EY2 K ER0 Z'],
  "carmakers'": ['K AA1 R M EY2 K ER0 Z'],
  "carman": ['K AA1 R M AH0 N'],
  "carmany": ['K AA1 R M AH0 N IY2'],
  "carmean": ['K AA1 R M IY2 AH0 N'],
  "carmel": ['K AA0 R M EH1 L', 'K AA1 R M AH0 L'],
  "carmela": ['K AA0 R M EH1 L AA0'],
  "carmelita": ['K AA0 R M AH0 L IY1 T AA0'],
  "carmelite": ['K AA1 R M AH0 L AY2 T'],
  "carmelo": ['K AA0 R M EH1 L OW0'],
  "carmen": ['K AA1 R M AH0 N'],
  "carmer": ['K AA1 R M ER0'],
  "carmical": ['K AA1 R M IH0 K AH0 L'],
  "carmichael": ['K AA1 R M AY2 K AH0 L'],
  "carmickle": ['K AA1 R M IH0 K AH0 L'],
  "carmie": ['K AA1 R M IY2'],
  "carmike": ['K AA1 R M AY2 K'],
  "carmine": ['K AA1 R M AH0 N'],
  "carmita": ['K AA0 R M IY1 T AA0'],
  "carmody": ['K AA1 R M AH0 D IY0'],
  "carmon": ['K AA1 R M AH0 N'],
  "carmona": ['K AA0 R M OW1 N AA0'],
  "carmony": ['K AA1 R M OW0 N IY2'],
  "carmouche": ['K AA0 R M UW1 SH'],
  "carmoy": ['K AA1 R M OY0'],
  "carmy": ['K AA1 R M IY0'],
  "carn": ['K AA1 R N'],
  "carnage": ['K AA1 R N IH0 JH'],
  "carnahan": ['K AA1 R N AH0 HH AE0 N'],
  "carnahan's": ['K AA1 R N AH0 HH AE0 N Z'],
  "carnal": ['K AA1 R N AH0 L'],
  "carnathan": ['K AA1 R N AH0 TH AE0 N'],
  "carnation": ['K AA0 R N EY1 SH AH0 N'],
  "carnations": ['K AA0 R N EY1 SH AH0 N Z'],
  "carnauba": ['K AA0 R N AO1 B AA0'],
  "carnaud": ['K AA0 R N AO1'],
  "carne": ['K AA1 R N'],
  "carneal": ['K AA1 R N AH0 L'],
  "carnegie": ['K AA1 R N AH0 G IY0', 'K AA2 R N EY1 G IY0'],
  "carnegie's": ['K AA1 R N AH0 G IY0 Z', 'K AA2 R N EY1 G IY0 Z'],
  "carnegie-mellon": ['K AA1 R N AH0 G IY0 M EH1 L AH0 N', 'K AA2 R N EY1 G IY0 M EH1 L AH0 N'],
  "carnegies": ['K AA1 R N AH0 G IY0 Z', 'K AA2 R N EY1 G IY0 Z'],
  "carneiro": ['K AA0 R N EH1 R OW0'],
  "carnell": ['K AA1 R N AH0 L'],
  "carner": ['K AA1 R N ER0'],
  "carnes": ['K AA1 R N Z'],
  "carnett": ['K AA1 R N AH0 T'],
  "carnevale": ['K AA0 R N EY0 V AA1 L EY0'],
  "carney": ['K AA1 R N IY0'],
  "carnicero": ['K AA2 R N IH0 S EH1 R OW0'],
  "carnine": ['K AA0 R N IY1 N IY0'],
  "carnival": ['K AA1 R N AH0 V AH0 L'],
  "carnival's": ['K AA1 R N AH0 V AH0 L Z'],
  "carnivals": ['K AA1 R N AH0 V AH0 L Z'],
  "carnivore": ['K AA1 R N IH0 V AO2 R'],
  "carnivores": ['K AA1 R N AH0 V AO2 R Z'],
  "carnivorous": ['K AA0 R N IH1 V ER0 AH0 S'],
  "carnley": ['K AA1 R N L IY0'],
  "carns": ['K AA1 R N Z'],
  "caro": ['K AA1 R OW0'],
  "carob": ['K EH1 R AH0 B'],
  "caroche": ['K ER0 OW1 CH'],
  "carol": ['K AE1 R AH0 L', 'K EH1 R AH0 L'],
  "carol's": ['K EH1 R AH0 L Z', 'K AE1 R AH0 L Z'],
  "carolan": ['K EH1 R AH0 L AE0 N'],
  "carolco": ['K ER0 AA1 L K OW0'],
  "carolco's": ['K EH1 R AH0 L K OW2 Z', 'K ER0 EH1 L K OW2 Z'],
  "carole": ['K AE1 R AH0 L', 'K EH1 R AH0 L'],
  "carolina": ['K EH2 R AH0 L AY1 N AH0'],
  "carolina's": ['K EH2 R AH0 L AY1 N AH0 Z'],
  "carolinas": ['K EH2 R AH0 L AY1 N AH0 Z'],
  "caroline": ['K EH1 R AH0 L AY2 N'],
  "caroline's": ['K EH1 R AH0 L AY2 N Z'],
  "carolingian": ['K EH2 R AH0 L IH1 N JH IY0 AH0 N'],
  "carolinian": ['K EH2 R OW0 L IH1 N IY0 AH0 N', 'K EH2 R AH0 L IH1 N IY0 AH0 N'],
  "carolinians": ['K EH2 R AH0 L IH1 N IY0 AH0 N Z'],
  "carollan": ['K ER0 AA1 L AH0 N'],
  "carollo": ['K ER0 AA1 L OW0'],
  "carols": ['K EH1 R AH0 L Z'],
  "carolus": ['K EH1 R AH0 L AH0 S'],
  "carolyn": ['K EH1 R AH0 L IH0 N'],
  "carolyne": ['K EH1 R AH0 L IH0 N', 'K EH1 R AH0 L AY0 N'],
  "caron": ['K AA0 R AO1 N'],
  "carone": ['K ER0 OW1 N'],
  "caronna": ['K ER0 AA1 N AH0'],
  "carosella": ['K AA0 R OW0 S EH1 L AH0'],
  "caroselli": ['K AA0 R OW0 S EH1 L IY0'],
  "carosi": ['K ER0 OW1 S IY0'],
  "carota": ['K ER0 OW1 T AH0'],
  "carotene": ['K EH1 R AH0 T IY2 N'],
  "carotenes": ['K EH1 R AH0 T IY2 N Z'],
  "carotenuto": ['K AA0 R OW0 T EH0 N UW1 T OW0'],
  "carothers": ['K AE1 R AH0 DH ER0 Z'],
  "carotid": ['K ER0 AA1 T IH0 D'],
  "carouse": ['K ER0 AW1 Z'],
  "carousel": ['K EH1 R AH0 S EH2 L'],
  "carousing": ['K ER0 AW1 Z IH0 NG'],
  "carow": ['K AE1 R OW0'],
  "carozza": ['K ER0 AA1 Z AH0'],
  "carp": ['K AA1 R P'],
  "carpal": ['K AA1 R P AH0 L'],
  "carpe-diem": ['K AA1 R P AH0 D IY1 AH0 M'],
  "carpencic": ['K AA0 R P EH1 N CH IH0 K'],
  "carpencic's": ['K AA0 R P EH1 N CH IH0 K S'],
  "carpenito": ['K AA0 R P EH0 N IY1 T OW0'],
  "carpenter": ['K AA1 R P AH0 N T ER0'],
  "carpenter's": ['K AA1 R P AH0 N T ER0 Z'],
  "carpenters": ['K AA1 R P AH0 N T ER0 Z'],
  "carpentier": ['K AA1 R P AH0 N T IY0 ER0'],
  "carpentieri": ['K AA0 R P EH0 N T IH1 R IY0'],
  "carpentry": ['K AA1 R P AH0 N T R IY0'],
  "carper": ['K AA1 R P ER0'],
  "carpet": ['K AA1 R P AH0 T'],
  "carpetbagger": ['K AA1 R P AH0 T B AE2 G ER0'],
  "carpetbaggers": ['K AA1 R P AH0 T B AE2 G ER0 Z'],
  "carpeted": ['K AA1 R P AH0 T IH0 D'],
  "carpeting": ['K AA1 R P AH0 T IH0 NG'],
  "carpets": ['K AA1 R P AH0 T S'],
  "carpinelli": ['K AA0 R P IY0 N EH1 L IY0'],
  "carping": ['K AA1 R P IH0 NG'],
  "carpino": ['K AA0 R P IY1 N OW0'],
  "carpio": ['K AA1 R P IY0 OW0'],
  "carpool": ['K AA1 R P UW2 L'],
  "carport": ['K AA1 R P AO2 R T'],
  "carports": ['K AA1 R P AO2 R T S'],
  "carr": ['K AA1 R'],
  "carr's": ['K AA1 R Z'],
  "carra": ['K AA1 R AH0'],
  "carragher": ['K AE1 R AH0 G ER0'],
  "carraher": ['K AE1 R AH0 HH ER0'],
  "carranco": ['K AA0 R AA1 N K OW0'],
  "carrano": ['K AA2 R AA1 N OW0'],
  "carranza": ['K AA0 R AA1 N Z AH0'],
  "carrara": ['K AA2 R AA1 R AH0'],
  "carras": ['K AE1 R AH0 Z'],
  "carrasco": ['K AA0 R AA1 S K OW0'],
  "carrasquel": ['K EH2 R AH0 S K EH1 L'],
  "carrasquillo": ['K EH2 R AH0 S K IH1 L OW0'],
  "carraway": ['K AE1 R AH0 W EY2'],
  "carrawell": ['K EH1 R AH0 W EH0 L'],
  "carre": ['K AA1 R', 'K AA2 R EY1'],
  "carreira": ['K AA0 R EH1 R AA0'],
  "carreiro": ['K AA0 R EH1 R OW0'],
  "carreker": ['K EH1 R IH0 K ER0'],
  "carrel": ['K AE1 R AH0 L'],
  "carrell": ['K AA0 R EY1 L'],
  "carreno": ['K AA0 R EH1 N OW2'],
  "carreon": ['K AA0 R EY0 AO1 N'],
  "carrera": ['K AA0 R EH1 R AA2'],
  "carreras": ['K AA0 R EH1 R AA2 Z'],
  "carrere": ['K AA0 R EH1 R EY0'],
  "carrero": ['K AA2 R EH1 R OW0'],
  "carretta": ['K AA0 R EH1 T AH0'],
  "carrey": ['K EH1 R IY0'],
  "carrey's": ['K AE1 R IY0 Z'],
  "carriage": ['K AE1 R IH0 JH', 'K EH1 R AH0 JH'],
  "carriages": ['K AE1 R IH0 JH IH0 Z', 'K EH1 R AH0 JH AH0 Z'],
  "carrian": ['K EH1 R IY0 AH0 N'],
  "carrian's": ['K AE1 R IY0 AH0 N Z'],
  "carribean": ['K ER0 IH1 B IY2 AH0 N'],
  "carrick": ['K EH1 R IH0 K'],
  "carrico": ['K AA0 R IY1 K OW0'],
  "carrie": ['K EH1 R IY0'],
  "carried": ['K AE1 R IY0 D', 'K EH1 R IY0 D'],
  "carrier": ['K AE1 R IY0 ER0', 'K EH1 R IY0 ER0'],
  "carrier's": ['K AE1 R IY0 ER0 Z', 'K EH1 R IY0 ER0 Z'],
  "carriere": ['K AA0 R IH1 R IY0'],
  "carriero": ['K AA0 R IH1 R OW0'],
  "carriers": ['K AE1 R IY0 ER0 Z', 'K EH1 R IY0 ER0 Z'],
  "carriers'": ['K EH1 R IY0 ER0 Z'],
  "carriers's": ['K AE1 R IY0 ER0 Z IH0 Z'],
  "carries": ['K AE1 R IY0 Z', 'K EH1 R IY0 Z'],
  "carrig": ['K AE1 R IH0 G'],
  "carrigan": ['K AE1 R IH0 G AH0 N'],
  "carriger": ['K AE1 R IH0 JH ER0'],
  "carrigg": ['K AE1 R IH0 G'],
  "carriker": ['K AE1 R IH0 K ER0'],
  "carrillo": ['K ER0 IH1 L OW0'],
  "carringer": ['K AE1 R IH0 NG ER0'],
  "carrington": ['K EH1 R IH0 NG T AH0 N'],
  "carrington's": ['K EH1 R IH0 NG T AH0 N Z'],
  "carrino": ['K AA2 R IY1 N OW0'],
  "carrion": ['K EH1 R IY0 AH0 N'],
  "carris": ['K AE1 R IH0 S'],
  "carrisyn": ['K AE1 R IH0 S IH0 N'],
  "carrithers": ['K AE1 R IH0 DH ER0 Z'],
  "carriveau": ['K AE1 R IH0 V OW2'],
  "carrizales": ['K AA0 R IY0 Z AA1 L EH0 S'],
  "carro": ['K AA1 R OW0'],
  "carrol": ['K AE1 R AH0 L'],
  "carroll": ['K AE1 R AH0 L', 'K EH1 R AH0 L'],
  "carroll's": ['K AE1 R AH0 L Z', 'K EH1 R AH0 L Z'],
  "carrollton": ['K EH1 R AH0 L T AH0 N'],
  "carron": ['K AE1 R AH0 N'],
  "carrot": ['K AE1 R AH0 T', 'K EH1 R AH0 T'],
  "carrothers": ['K AE1 R AH0 DH ER0 Z'],
  "carrots": ['K AE1 R AH0 T S', 'K EH1 R AH0 T S'],
  "carrousel": ['K EH1 R AH0 S EH2 L'],
  "carrow": ['K AE1 R OW0'],
  "carrozza": ['K AA0 R OW1 Z AH0'],
  "carrubba": ['K AA2 R UW1 B AH0'],
  "carruth": ['K AE1 R UW0 TH'],
  "carruthers": ['K ER0 AH1 DH ER0 Z'],
  "carry": ['K AE1 R IY0', 'K EH1 R IY0'],
  "carryanne": ['K AE1 R IY0 AE1 N'],
  "carryforward": ['K EH1 R IY0 F AO2 R W ER0 D'],
  "carryforwards": ['K EH1 R IY0 F AO2 R W ER0 D Z'],
  "carrying": ['K AE1 R IY0 IH0 NG', 'K EH1 R IY0 IH0 NG'],
  "carryover": ['K EH1 R Y OW2 V ER0'],
  "cars": ['K AA1 R Z', 'K AA1 Z'],
  "cars'": ['K AA1 R Z'],
  "carse": ['K AA1 R S'],
  "carsey": ['K AA1 R S IY0'],
  "carsick": ['K AA1 R S IH0 K'],
  "carson": ['K AA1 R S AH0 N', 'K AA1 R Z AH0 N'],
  "carson's": ['K AA1 R S AH0 N Z'],
  "carstarphen": ['K AA0 R S T AA1 R F AH0 N'],
  "carsten": ['K AA1 R S T AH0 N'],
  "carstens": ['K AA1 R S T AH0 N Z'],
  "carstensen": ['K AA0 R S T EH1 N S AH0 N'],
  "carswell": ['K AA1 R S W EH2 L'],
  "cart": ['K AA1 R T'],
  "carta": ['K AA1 R T AH0'],
  "cartagena": ['K AA2 R T AH0 JH IY1 N AH0'],
  "cartaya": ['K AA2 R T AY1 AH0'],
  "carte": ['K AA1 R T'],
  "carted": ['K AA1 R T IH0 D'],
  "cartee": ['K AA1 R T IY1'],
  "cartel": ['K AA0 R T EH1 L'],
  "cartel's": ['K AA0 R T EH1 L Z'],
  "cartels": ['K AA0 R T EH1 L Z'],
  "carter": ['K AA1 R T ER0'],
  "carter's": ['K AA1 R T ER0 Z'],
  "cartera": ['K AA2 R T EH1 R AH0'],
  "carteret": ['K AA1 R T ER0 IH0 T'],
  "carteret's": ['K AA2 R T ER0 EH1 T S'],
  "carters": ['K AA1 R T ER0 Z'],
  "cartersville": ['K AA1 R T ER0 Z V IH2 L'],
  "cartesian": ['K AA0 R T IY1 ZH AH0 N'],
  "carthage": ['K AA1 R TH AH0 JH', 'K AA1 R TH IH0 JH'],
  "carthaginian": ['K AA2 R TH AH0 JH IH1 N IY0 AH0 N'],
  "cartier": ['K AA1 R T IY0 ER0'],
  "cartilage": ['K AA1 R T AH0 L AH0 JH', 'K AA1 R T AH0 L IH0 JH'],
  "carting": ['K AA1 R T IH0 NG'],
  "cartland": ['K AA1 R T L AH0 N D'],
  "cartledge": ['K AA1 R T L EH2 JH'],
  "cartlidge": ['K AA1 R T L IH0 JH'],
  "cartmell": ['K AA0 R T M EY1 L'],
  "cartmill": ['K AA1 R T M IH2 L'],
  "cartner": ['K AA1 R T N ER0'],
  "carton": ['K AA1 R T AH0 N'],
  "cartoneros": ['K AA2 R T OW2 N EH1 R OW0 S'],
  "cartonnage": ['K AA1 R T AH0 N AH0 JH'],
  "cartons": ['K AA1 R T AH0 N Z'],
  "cartoon": ['K AA0 R T UW1 N'],
  "cartooning": ['K AA0 R T UW1 N IH0 NG'],
  "cartoonist": ['K AA0 R T UW1 N AH0 S T'],
  "cartoonists": ['K AA0 R T UW1 N IH0 S T S'],
  "cartoons": ['K AA0 R T UW1 N Z'],
  "cartrette": ['K AA2 R T R EH1 T'],
  "cartridge": ['K AA1 R T R AH0 JH', 'K AA1 R T R IH0 JH'],
  "cartridges": ['K AA1 R T R AH0 JH AH0 Z'],
  "carts": ['K AA1 R T S'],
  "cartusciello": ['K AA0 R T UW2 S IY0 EH1 L OW0'],
  "cartwheel": ['K AA1 R T W IY2 L'],
  "cartwheels": ['K AA1 R T W IY2 L Z'],
  "cartwright": ['K AA1 R T R AY2 T'],
  "carty": ['K AA1 R T IY0'],
  "caruana": ['K AA0 R UW0 AE1 N AH0'],
  "carucci": ['K AA0 R UW1 CH IY0'],
  "caruso": ['K ER0 UW1 S OW0'],
  "carusone": ['K AA0 R UW0 S OW1 N IY0'],
  "caruth": ['K AE1 R UW0 TH'],
  "caruthers": ['K ER0 AH1 DH ER0 Z'],
  "carvajal": ['K AA0 R V AA0 Y AE1 L'],
  "carvalho": ['K AA0 R V AA1 L HH OW0'],
  "carve": ['K AA1 R V'],
  "carved": ['K AA1 R V D'],
  "carvel": ['K AA0 R V EH1 L'],
  "carvell": ['K AA0 R V EY1 L', 'K AA0 R V EH1 L'],
  "carver": ['K AA1 R V ER0'],
  "carver's": ['K AA1 R V ER0 Z'],
  "carvers": ['K AA1 R V ER0 Z'],
  "carves": ['K AA1 R V Z'],
  "carvey": ['K AA1 R V IY0'],
  "carville": ['K AA1 R V IH2 L'],
  "carville's": ['K AA1 R V IH2 L Z'],
  "carvin": ['K AA1 R V IH0 N'],
  "carving": ['K AA1 R V IH0 NG'],
  "carvings": ['K AA1 R V IH0 NG Z'],
  "carwell": ['K AA1 R W EH2 L'],
  "carwile": ['K AA1 R W AY2 L'],
  "cary": ['K EH1 R IY0'],
  "caryl": ['K EH1 R AH0 L'],
  "caryn": ['K AA1 R IH0 N'],
  "cas": ['K AE1 S'],
  "casa": ['K AA1 S AH0'],
  "casablanca": ['K AE2 S AH0 B L AE1 NG K AH0'],
  "casad": ['K AE1 S AH0 D'],
  "casada": ['K AA0 S AA1 D AA0'],
  "casado": ['K AA0 S AA1 D OW0'],
  "casados": ['K AA0 S AA1 D OW0 Z'],
  "casady": ['K AH0 S AA1 D IY2'],
  "casagrande": ['K AA0 S AA1 G R AE0 N D IY0'],
  "casal": ['K AA0 S AA1 L'],
  "casale": ['K AA0 S AA1 L IY0'],
  "casali": ['K AA0 S AA1 L IY0'],
  "casalino": ['K AA0 S AA0 L IY1 N OW0'],
  "casals": ['K AA0 S AA1 L Z', 'K AH0 S AA1 L Z'],
  "casamento": ['K AE2 S AH0 M EH1 N T OW0'],
  "casanova": ['K AE2 S AH0 N OW1 V AA0'],
  "casares": ['K AA0 S AA1 R EH0 S'],
  "casarez": ['K AA0 S AA1 R EH0 Z'],
  "casas": ['K AA1 S AH0 Z'],
  "casassa": ['K AA0 S AA1 S AH0'],
  "casaus": ['K AE1 S AO0 Z'],
  "casavant": ['K AE1 S AH0 V AH0 N T'],
  "casazza": ['K AA0 S AA1 T S AH0'],
  "casbah": ['K AE1 S B AH0'],
  "cascade": ['K AE0 S K EY1 D'],
  "cascade's": ['K AE0 S K EY1 D Z'],
  "cascaded": ['K AE0 S K EY1 D IH0 D'],
  "cascades": ['K AE0 S K EY1 D Z'],
  "cascades'": ['K AE0 S K EY1 D Z'],
  "cascading": ['K AE0 S K EY1 D IH0 NG'],
  "casciano": ['K AA0 S CH IY0 AA1 N OW0'],
  "casciato": ['K AA0 S CH AA1 T OW0'],
  "cascio": ['K AE1 S IY0 OW0'],
  "casco": ['K AE1 S K OW0'],
  "cascone": ['K AA0 S K OW1 N IY0'],
  "casdorph": ['K AE1 S D ER0 F'],
  "case": ['K EY1 S'],
  "case's": ['K EY1 S IH0 Z'],
  "casebeer": ['K AE0 S IH0 B IH1 R', 'K EY0 S B IH1 R'],
  "casebier": ['K AE1 S IH0 B IY0 ER0'],
  "casebolt": ['K EY1 S B OW2 L T'],
  "casein": ['K EY0 S IY1 N'],
  "casella": ['K AH0 S EH1 L AH0'],
  "caselli": ['K AH0 S EH1 L IY0'],
  "caseload": ['K EY1 S L OW2 D'],
  "caseloads": ['K AE1 S AH0 L OW2 D Z'],
  "casera": ['K AH0 S EH1 R AH0'],
  "caserta": ['K AA0 S EH1 R T AH0'],
  "cases": ['K EY1 S AH0 Z', 'K EY1 S IH0 Z'],
  "casesa": ['K AH0 S EH1 S AH0'],
  "casework": ['K EY1 S W ER2 K'],
  "caseworker": ['K EY1 S W ER2 K ER0'],
  "caseworkers": ['K EY1 S W ER2 K ER0 Z'],
  "casey": ['K EY1 S IY0'],
  "casey's": ['K EY1 S IY0 Z'],
  "caseze": ['K AH0 S IY1 Z IY0'],
  "caseze's": ['K AH0 S IY1 Z IY0 Z'],
  "casgrain": ['K AE1 S G R EY2 N'],
  "cash": ['K AE1 SH'],
  "cashatt": ['K AE1 SH AH0 T'],
  "cashdollar": ['K AE1 SH D AA2 L ER0'],
  "cashed": ['K AE1 SH T'],
  "cashen": ['K AE1 SH AH0 N'],
  "casher": ['K AE1 SH ER0'],
  "cashes": ['K AE1 SH IH0 Z'],
  "cashew": ['K AE1 SH UW2'],
  "cashews": ['K AE1 SH UW2 Z'],
  "cashflow": ['K AE1 SH F L OW2'],
  "cashflows": ['K AE1 SH F L OW2 Z'],
  "cashier": ['K AE0 SH IH1 R'],
  "cashier's": ['K AE0 SH IH1 R Z'],
  "cashiered": ['K AE0 SH IH1 R D'],
  "cashiers": ['K AE2 SH IH1 R Z'],
  "cashin": ['K AE1 SH IH0 N'],
  "cashing": ['K AE1 SH IH0 NG'],
  "cashion": ['K AA0 SH IY1 N'],
  "cashless": ['K AE1 SH L AH0 S'],
  "cashman": ['K AE1 SH M AH0 N'],
  "cashmere": ['K AE1 ZH M IH0 R'],
  "cashmeres": ['K AE1 ZH M IH0 R Z'],
  "cashmore": ['K AE1 SH M AO0 R'],
  "cashon": ['K AE1 SH AH0 N'],
  "cashway": ['K AE1 SH W EY2'],
  "cashways": ['K AE1 SH W EY2 Z'],
  "cashwell": ['K AE1 SH W EH2 L'],
  "casiano": ['K AA0 S IY0 AA1 N OW0'],
  "casias": ['K AA0 S IY1 AH0 Z'],
  "casillas": ['K AA0 S IH1 L AH0 Z'],
  "casillo": ['K AH0 S IH1 L OW0'],
  "casimir": ['K AE1 S IH0 M IY2 R'],
  "casimiro": ['K AA0 S IY0 M IH1 R OW0'],
  "casing": ['K EY1 S IH0 NG'],
  "casings": ['K EY1 S IH0 NG Z'],
  "casino": ['K AH0 S IY1 N OW0'],
  "casino's": ['K AH0 S IY1 N OW0 Z'],
  "casinos": ['K AH0 S IY1 N OW0 Z'],
  "casio": ['K AE1 S IY0 OW0'],
  "cask": ['K AE1 S K'],
  "casket": ['K AE1 S K AH0 T'],
  "caskets": ['K AE1 S K AH0 T S'],
  "caskey": ['K AE1 S K IY0'],
  "casks": ['K AE1 S K S'],
  "casler": ['K AE1 S AH0 L ER0', 'K AE1 S L ER0'],
  "caslin": ['K AE1 S L IH0 N'],
  "casner": ['K AE1 S N ER0'],
  "caso": ['K AA1 S OW0'],
  "casola": ['K AA0 S OW1 L AH0'],
  "cason": ['K AE1 S AH0 N'],
  "caspar": ['K AE1 S P ER0', 'K AE1 S P AA2 R'],
  "casper": ['K AE1 S P ER0'],
  "casper's": ['K AE1 S P ER0 Z'],
  "caspers": ['K AE1 S P ER0 Z'],
  "caspersen": ['K AE1 S P ER0 S AH0 N'],
  "casperson": ['K AE1 S P ER0 S AH0 N'],
  "caspian": ['K AE1 S P IY0 AH0 N'],
  "cass": ['K AE1 S'],
  "cassada": ['K AA0 S AA1 D AH0'],
  "cassaday": ['K AE1 S AH0 D EY2'],
  "cassady": ['K AE1 S AH0 D IY0'],
  "cassandra": ['K AH0 S AE1 N D R AH0'],
  "cassandras": ['K AH0 S AE1 N D R AH0 S'],
  "cassani": ['K AA0 S AA1 N IY0'],
  "cassano": ['K AA0 S AA1 N OW0'],
  "cassar": ['K AE1 S ER0'],
  "cassara": ['K AA0 S AA1 R AH0'],
  "cassarino": ['K AA0 S AA0 R IY1 N OW0'],
  "cassaro": ['K AA0 S AA1 R OW0'],
  "cassata": ['K AA0 S AA1 T AH0'],
  "cassatt": ['K AE1 S AH0 T'],
  "cassavetes": ['K AE2 S AH0 V IY1 T IY0 Z'],
  "casseb": ['K AE1 S AH0 B'],
  "cassedy": ['K AE1 S IH0 D IY0'],
  "cassel": ['K AE1 S AH0 L'],
  "casselberry": ['K AE1 S AH0 L B EH2 R IY0'],
  "cassell": ['K AE1 S EH0 L'],
  "cassella": ['K AA0 S EH1 L AH0'],
  "cassells": ['K AE1 S AH0 L Z'],
  "casselman": ['K AE1 S AH0 L M AH0 N'],
  "cassels": ['K AE1 S AH0 L Z'],
  "cassens": ['K AE1 S AH0 N Z'],
  "casserly": ['K AE1 S ER0 L IY0'],
  "casserole": ['K AE1 S ER0 OW2 L'],
  "cassese": ['K AA0 S EY1 Z IY0'],
  "cassetta": ['K AA0 S EH1 T AH0'],
  "cassette": ['K AH0 S EH1 T'],
  "cassettes": ['K AH0 S EH1 T S'],
  "cassetty": ['K AE1 S IH0 T IY0'],
  "cassia": ['K AE1 SH IY0 AH0'],
  "cassiday": ['K AE1 S IY0 D EY0'],
  "cassidy": ['K AE1 S IH0 D IY0'],
  "cassidy's": ['K AE1 S IH0 D IY0 Z'],
  "cassie": ['K AE1 S IY0'],
  "cassin": ['K AE1 S IH0 N'],
  "cassinelli": ['K AA0 S IY0 N EH1 L IY0'],
  "cassini": ['K AH0 S IY1 N IY0'],
  "cassini's": ['K AH0 S IY1 N IY0 Z'],
  "cassino": ['K AH0 S IY1 N OW0'],
  "cassiopeia": ['K AH2 S IY0 AH0 P IY1 AH0'],
  "cassis": ['K AE1 S IH0 S'],
  "cassity": ['K AE1 S IH0 T IY0'],
  "casso": ['K AE1 S OW0'],
  "cassock": ['K AE1 S AH0 K'],
  "casson": ['K AE1 S AH0 N'],
  "cassone": ['K AA0 S OW1 N IY0'],
  "cassoni": ['K AH0 S OW1 N IY0'],
  "cassoni's": ['K AH0 S OW1 N IY0 Z'],
  "cassowary": ['K AE1 S AH0 W EH2 R IY0'],
  "casstevens": ['K AE2 S T IY1 V AH0 N Z'],
  "cast": ['K AE1 S T'],
  "casta": ['K AE1 S T AH0'],
  "castagna": ['K AA0 S T AA1 G N AH0'],
  "castagno": ['K AA0 S T AA1 G N OW0'],
  "castagnola": ['K AA0 S T AA0 G N OW1 L AH0'],
  "castaldi": ['K AA0 S T AA1 L D IY0'],
  "castaldo": ['K AA0 S T AA1 L D OW0'],
  "castaneda": ['K AA0 S T AA0 N EH1 D AH0'],
  "castano": ['K AA0 S T AA1 N OW0'],
  "castanon": ['K AE1 S T AH0 N AA0 N'],
  "castaway": ['K AE1 S T AH0 W EY2'],
  "castaways": ['K AE1 S T AH0 W EY2 Z'],
  "caste": ['K AE1 S T'],
  "casteel": ['K EY1 S T IY0 L'],
  "casteen": ['K AH0 S T IY1 N'],
  "castel": ['K EY1 S T AH0 L'],
  "castell": ['K EY1 S T AH0 L'],
  "castellana": ['K AA0 S T EH0 L AE1 N AH0'],
  "castellani": ['K AA0 S T EH0 L AA1 N IY0'],
  "castellano": ['K AA0 S T EH0 L AA1 N OW0'],
  "castellanos": ['K AA0 S T EY0 L AA1 N OW0 Z'],
  "castellaw": ['K EY1 S T IH0 L AO0'],
  "castelli": ['K AA0 S T EH1 L IY0'],
  "castello": ['K AH0 S T EH1 L OW0'],
  "castellon": ['K EY1 S T IH0 L AA0 N'],
  "castellucci": ['K AA0 S T EH0 L UW1 CH IY0'],
  "castelluccio": ['K AA0 S T EH0 L UW1 CH IY0 OW0'],
  "castellvi": ['K AH0 S T EH1 L V IY0'],
  "castelo": ['K AA0 S T EH1 L OW0'],
  "casten": ['K AE1 S T AH0 N'],
  "caster": ['K AE1 S T ER0'],
  "casterline": ['K AE1 S T ER0 L AY2 N'],
  "casters": ['K AE1 S T ER0 Z'],
  "castes": ['K AE1 S T S'],
  "castigate": ['K AE1 S T AH0 G EY2 T'],
  "castigated": ['K AE1 S T AH0 G EY2 T IH0 D'],
  "castigates": ['K AE1 S T AH0 G EY2 T S'],
  "castigating": ['K AE1 S T AH0 G EY2 T IH0 NG'],
  "castiglia": ['K AA0 S T IY1 G L IY0 AH0'],
  "castiglione": ['K AA0 S T IY0 G L IY0 OW1 N IY0'],
  "castile": ['K AE2 S T AY1 L'],
  "castilla": ['K AA0 S T IH1 L AH0'],
  "castille": ['K AE2 S T IH1 L'],
  "castilleja": ['K AA0 S T IY0 L EY1 Y AH0'],
  "castillo": ['K AE2 S T IY1 OW0', 'K AE2 S T IH1 L OW0'],
  "castillon": ['K AE1 S T IH0 L AA0 N'],
  "castine": ['K AA0 S T IY1 N IY0'],
  "casting": ['K AE1 S T IH0 NG'],
  "castings": ['K AE1 S T IH0 NG Z'],
  "castio": ['K AE0 S T IY1 OW0', 'K AE1 S T Y OW0'],
  "castle": ['K AE1 S AH0 L'],
  "castle's": ['K AE1 S AH0 L Z'],
  "castleberry": ['K AE1 S AH0 L B EH2 R IY0'],
  "castleman": ['K AE1 S AH0 L M AH0 N'],
  "castles": ['K AE1 S AH0 L Z'],
  "castner": ['K AE1 S T N ER0'],
  "casto": ['K AE1 S T OW0'],
  "castoff": ['K AE1 S T AO2 F'],
  "castoffs": ['K AE1 S T AO2 F S'],
  "caston": ['K AE1 S T AH0 N'],
  "castonguay": ['K AH0 S T AA1 NG G EY0'],
  "castor": ['K AE1 S T ER0'],
  "castorena": ['K AA1 S T AO0 R N AH0'],
  "castoro": ['K AA0 S T AO1 R OW0'],
  "castpart": ['K AE1 S T P AA2 R T'],
  "castparts": ['K AE1 S T P AA2 R T S'],
  "castrate": ['K AE1 S T R EY2 T'],
  "castrated": ['K AE1 S T R EY2 T IH0 D'],
  "castrates": ['K AE1 S T R EY2 T S'],
  "castrating": ['K AE1 S T R EY2 T IH0 NG'],
  "castration": ['K AE2 S T R EY1 SH AH0 N'],
  "castrations": ['K AE2 S T R EY1 SH AH0 N Z'],
  "castrejon": ['K AE1 S T R IH0 JH AA0 N'],
  "castrillon": ['K AH0 S T R IH1 L Y AH0 N'],
  "castro": ['K AE1 S T R OW0'],
  "castro's": ['K AE1 S T R OW0 Z'],
  "castrol": ['K AE1 S T R AA0 L'],
  "castronova": ['K AE0 S T R OW0 N OW1 V AH0'],
  "castronovo": ['K AE0 S T R OW0 N OW1 V OW0'],
  "castrucci": ['K AH0 S T R UW1 CH IY0'],
  "castruita": ['K AA0 S T R UW1 T AH0'],
  "casts": ['K AE1 S T S'],
  "casual": ['K AE1 ZH AH0 W AH0 L', 'K AE1 ZH W AH0 L'],
  "casually": ['K AE1 ZH AH0 W AH0 L IY0', 'K AE1 ZH W AH0 L IY0'],
  "casualness": ['K AE1 ZH AH0 W AH0 L N EH0 S', 'K AE1 ZH W AH0 L N EH0 S'],
  "casuals": ['K AE1 ZH AH0 W AH0 L Z'],
  "casualties": ['K AE1 ZH AH0 W AH0 L T IY0 Z', 'K AE1 ZH W AH0 L T IY0 Z', 'K AE1 ZH AH0 L T IY0 Z'],
  "casualty": ['K AE1 ZH AH0 W AH0 L T IY0', 'K AE1 ZH W AH0 L T IY0', 'K AE1 ZH AH0 L T IY0'],
  "caswell": ['K AE1 Z W EH2 L'],
  "cat": ['K AE1 T'],
  "cat's": ['K AE1 T S'],
  "cat-o-nine-tails": ['K AE1 T OW0 N AY2 N T EY2 L Z'],
  "cataclysm": ['K AE1 T AH0 K L IH2 S AH0 M'],
  "cataclysmic": ['K AE2 T AH0 K L IH1 Z M IH0 K'],
  "catacomb": ['K AE1 T AH0 K OW2 M'],
  "catacombs": ['K AE1 T AH0 K OW2 M Z'],
  "catacosinos": ['K AH0 T AE2 K AH0 S IY1 N OW0 S'],
  "catacosinos's": ['K AH0 T AE2 K AH0 S IY1 N AH0 S IH0 Z'],
  "catain": ['K AE1 T IH0 N', 'K AH0 T EY1 N'],
  "catala": ['K AE1 T AA0 L AA2 N'],
  "catalan": ['K AE1 T AH0 L AH0 N'],
  "catalano": ['K AA2 T AA0 L AA1 N OW2'],
  "catalanotto": ['K AA2 T AA0 L AA0 N OW1 T OW0'],
  "catalans": ['K AE1 T AH0 L AA2 N Z'],
  "cataldi": ['K AA0 T AA1 L D IY0'],
  "cataldo": ['K AA0 T AA1 L D OW0'],
  "catalfamo": ['K AA0 T AA0 L F AA1 M OW0'],
  "catalina": ['K AE2 T AH0 L IY1 N AH0'],
  "catalog": ['K AE1 T AH0 L AO0 G'],
  "cataloged": ['K AE1 T AH0 L AO0 G D'],
  "cataloger": ['K AE1 T AH0 L AO2 G ER0'],
  "catalogers": ['K AE1 T AH0 L AO2 G ER0 Z'],
  "cataloging": ['K AE1 T AH0 L AA0 G IH0 NG'],
  "catalogs": ['K AE1 T AH0 L AA0 G Z', 'K AE1 T AH0 L AO0 G Z'],
  "catalogue": ['K AE1 T AH0 L AO2 G'],
  "catalogued": ['K AE1 T AH0 L AO2 G D'],
  "catalogues": ['K AE1 T AH0 L AO2 G Z'],
  "cataloguing": ['K AE1 T AH0 L AO2 G IH0 NG'],
  "catalonia": ['K AE2 T AH0 L OW1 N Y AH0'],
  "catalonian": ['K AE2 T AH0 L OW1 N Y AH0 N'],
  "catalyst": ['K AE1 T AH0 L AH0 S T', 'K AE1 T AH0 L IH0 S T'],
  "catalyst's": ['K AE1 T AH0 L IH2 S T S'],
  "catalysts": ['K AE1 T AH0 L AH0 S T S', 'K AE1 T AH0 L IH0 S T S'],
  "catalytic": ['K AE2 T AH0 L IH1 T IH0 K'],
  "catalyze": ['K AE1 T AH0 L AY2 Z'],
  "catalyzed": ['K AE1 T AH0 L AY2 Z D'],
  "catamaran": ['K AE2 T AH0 M ER0 AE1 N'],
  "catamarans": ['K AH0 T AE1 M ER2 AH0 N Z'],
  "catamount": ['K AE1 T AH0 M AW2 N T'],
  "catanese": ['K AA0 T AA0 N EY1 Z IY0'],
  "catania": ['K AH0 T EY1 N Y AH0'],
  "catano": ['K AA0 T AA1 N OW0'],
  "catanzarite": ['K AH0 T AE1 N Z ER0 AY2 T'],
  "catanzaro": ['K AA0 T AA0 N Z AA1 R OW0'],
  "catapano": ['K AA0 T AA0 P AA1 N OW0'],
  "catapult": ['K AE1 T AH0 P AH2 L T'],
  "catapulted": ['K AE1 T AH0 P AH2 L T IH0 D'],
  "catapulting": ['K AE1 T AH0 P AH2 L T IH0 NG'],
  "catapults": ['K AE1 T AH0 P AH0 L T S'],
  "cataract": ['K AE1 T ER0 AE0 K T'],
  "cataracts": ['K AE1 T ER0 AE2 K T S'],
  "catastrophe": ['K AH0 T AE1 S T R AH0 F IY0'],
  "catastrophes": ['K AH0 T AE1 S T R AH0 F IY0 Z'],
  "catastrophic": ['K AE2 T AH0 S T R AA1 F IH0 K'],
  "catatonic": ['K AE2 T AH0 T AA1 N IH0 K'],
  "catawba": ['K AH0 T AO1 B AH0'],
  "catbird": ['K AE1 T B ER2 D'],
  "catcall": ['K AE1 T K AO2 L'],
  "catcalls": ['K AE1 T K AO2 L Z'],
  "catch": ['K AE1 CH'],
  "catchacan": ['K AE1 CH AH0 K AA2 N'],
  "catchall": ['K AE1 CH AO2 L'],
  "catcher": ['K AE1 CH ER2'],
  "catcher's": ['K AE1 CH ER2 Z'],
  "catchers": ['K AE1 CH ER2 Z'],
  "catches": ['K AE1 CH AH0 Z', 'K AE1 CH IH0 Z'],
  "catching": ['K AE1 CH IH0 NG'],
  "catchings": ['K AE1 CH IH0 NG Z'],
  "catchment": ['K AE1 CH M AH0 N T'],
  "catchup": ['K AE1 CH AH0 P'],
  "catchword": ['K AE1 CH W ER2 D'],
  "catchy": ['K AE1 CH IY2'],
  "cate": ['K EY1 T'],
  "catechism": ['K AE1 T AH0 K IH2 Z AH0 M'],
  "categorical": ['K AE2 T AH0 G AA1 R IH0 K AH0 L', 'K AE2 T AH0 G AO1 R IH0 K AH0 L'],
  "categorically": ['K AE2 T AH0 G AA1 R IH0 K L IY0', 'K AE2 T AH0 G AO1 R IH0 K L IY0'],
  "categories": ['K AE1 T AH0 G AO2 R IY0 Z'],
  "categorization": ['K AE2 T AH0 G ER0 IH0 Z EY1 SH AH0 N'],
  "categorize": ['K AE1 T AH0 G ER0 AY2 Z'],
  "categorized": ['K AE1 T AH0 G ER0 AY2 Z D'],
  "categorizes": ['K AE1 T AH0 G ER0 AY2 Z IH0 Z'],
  "categorizing": ['K AE1 T AH0 G ER0 AY2 Z IH0 NG'],
  "category": ['K AE1 T AH0 G AO2 R IY0'],
  "catelli": ['K AH0 T EH1 L IY0'],
  "catena": ['K AH0 T IY1 N AH0'],
  "cater": ['K EY1 T ER0'],
  "catered": ['K EY1 T ER0 D'],
  "caterer": ['K EY1 T ER0 ER0'],
  "caterers": ['K EY1 T ER0 ER0 Z'],
  "catering": ['K EY1 T ER0 IH0 NG'],
  "caterings": ['K AE1 T ER0 IH0 NG Z'],
  "caterino": ['K AA0 T ER0 IY1 N OW0'],
  "caterpillar": ['K AE1 T AH0 P IH2 L ER0', 'K AE1 T ER0 P IH2 L ER0'],
  "caterpillar's": ['K AE1 T ER0 P IH2 L ER0 Z'],
  "caterpillars": ['K AE1 T AH0 P IH2 L ER0 Z'],
  "caterpiller": ['K AE1 T AH0 P IH2 L ER0', 'K AE1 T ER0 P IH2 L ER0'],
  "caterpiller's": ['K AE1 T AH0 P IH2 L ER0 Z', 'K AE1 T ER0 P IH2 L ER0 Z'],
  "caters": ['K EY1 T ER0 Z'],
  "caterwaul": ['K AE1 T ER0 W AA0 L'],
  "cates": ['K EY1 T S'],
  "catfish": ['K AE1 T F IH2 SH'],
  "catharine": ['K AE1 TH R IH0 N'],
  "catharines": ['K AE1 TH R IH0 N Z', 'K AE1 TH ER0 IH0 N Z'],
  "catharsis": ['K AH0 TH AA1 R S AH0 S'],
  "cathartic": ['K AH0 TH AA1 R T IH0 K'],
  "cathay": ['K AE0 TH EY1'],
  "cathcart": ['K AE1 TH K AA0 R T'],
  "cathedral": ['K AH0 TH IY1 D R AH0 L'],
  "cathedrals": ['K AH0 TH IY1 D R AH0 L Z'],
  "cathell": ['K AE1 TH AH0 L'],
  "cather": ['K AE1 DH ER0'],
  "cather's": ['K AE1 DH ER0 Z'],
  "catherina": ['K AA0 TH ER0 IY1 N AH0'],
  "catherine": ['K AE1 TH ER0 AH0 N', 'K AE1 TH ER0 IH0 N', 'K AE1 TH R IH0 N'],
  "catherine's": ['K AE1 TH R IH0 N Z'],
  "catherines": ['K AE1 TH R IH0 N Z'],
  "catherman": ['K AE1 DH ER0 M AH0 N'],
  "cathers": ['K AE1 DH ER0 Z'],
  "catherwood": ['K AE1 DH ER0 W UH2 D'],
  "catheter": ['K AE1 TH AH0 T ER0'],
  "catheters": ['K AE1 TH AH0 T ER0 Z'],
  "cathey": ['K AE1 DH IY0'],
  "cathie": ['K AE1 TH IY0'],
  "cathleen": ['K AE2 TH L IY1 N'],
  "cathmor": ['K AE1 TH AH0 M ER0'],
  "cathode": ['K AE1 TH OW2 D'],
  "cathodes": ['K AE1 TH OW2 D Z'],
  "catholic": ['K AE1 TH L IH0 K'],
  "catholicism": ['K AH0 TH AO1 L AH0 S IH2 Z AH0 M'],
  "catholics": ['K AE1 TH L IH0 K S'],
  "cathy": ['K AE1 TH IY0'],
  "cathy's": ['K AE1 TH IY0 Z'],
  "catija": ['K AH0 T IY1 JH AH0'],
  "catino": ['K AA0 T IY1 N OW0'],
  "cation": ['K AE1 T AY2 AH0 N'],
  "catkins": ['K AE1 T K AH0 N Z'],
  "catledge": ['K AE1 T L IH0 JH'],
  "catlett": ['K AE1 T L IH0 T'],
  "catlike": ['K AE1 T L AY2 K'],
  "catlin": ['K AE1 T L IH0 N'],
  "catnap": ['K AE1 T N AE2 P'],
  "catnip": ['K AE1 T N IH0 P'],
  "catniss": ['K AE1 T N IH2 S'],
  "cato": ['K EY1 T OW0'],
  "catoe": ['K AE1 T OW0'],
  "catolica": ['K AH0 T OW1 L IH0 K AH0'],
  "caton": ['K AE1 T AH0 N'],
  "catone": ['K AH0 T OW1 N'],
  "catrambone": ['K AE1 T R AE2 M B OW2 N'],
  "catrett": ['K AE1 T R IH0 T'],
  "catron": ['K AE1 T R AH0 N'],
  "cats": ['K AE1 T S'],
  "catskill": ['K AE1 T S K IH2 L'],
  "catskills": ['K AE1 T S K IH2 L Z'],
  "catsup": ['K EH1 CH AH0 P'],
  "catt": ['K AE1 T'],
  "cattanach": ['K AE1 T AH0 N AE0 CH'],
  "cattaneo": ['K AA0 T AA1 N IY0 OW0'],
  "cattani": ['K AA0 T AA1 N IY0'],
  "cattell": ['K AH0 T EH1 L'],
  "catterall": ['K AE1 T ER0 AO2 L'],
  "catterson": ['K AE1 T ER0 S AH0 N'],
  "catterton": ['K AE1 T ER0 T AH0 N'],
  "cattle": ['K AE1 T AH0 L'],
  "cattlemen": ['K AE1 T AH0 L M AH0 N', 'K AE1 T AH0 L M IH0 N'],
  "cattlemen's": ['K AE1 T AH0 L M AH0 N Z'],
  "catto": ['K AE1 T OW0'],
  "cattolica": ['K AH0 T OW1 L IH0 K AH0'],
  "catton": ['K AE1 T AH0 N'],
  "catty": ['K AE1 T IY0'],
  "catwalk": ['K AE1 T W AA2 K', 'K AE1 T W AO2 K'],
  "catwoman": ['K AE1 T W UH2 M AH0 N'],
  "cau": ['K AW1', 'K OW1'],
  "cauble": ['K AO1 B AH0 L'],
  "caucasian": ['K AO0 K EY1 ZH AH0 N'],
  "caucasians": ['K AO0 K EY1 ZH AH0 N Z'],
  "caucasus": ['K AO1 K AH0 S AH0 S'],
  "caucus": ['K AO1 K AH0 S', 'K AA1 K AH0 S'],
  "caucus's": ['K AO1 K AH0 S IH0 Z'],
  "caucuses": ['K AO1 K AH0 S IH0 Z'],
  "caudal": ['K AA1 D AH0 L', 'K AO1 D AH0 L'],
  "caudell": ['K OW0 D EH1 L'],
  "caudill": ['K AO1 D AH0 L'],
  "caudillo": ['K AO2 D IH1 L OW0'],
  "caudle": ['K AO1 D AH0 L'],
  "cauffman": ['K AO1 F M AH0 N'],
  "caufield": ['K OW1 F IY0 L D'],
  "caughey": ['K AO1 IY0'],
  "caughlin": ['K AO1 L IH0 N'],
  "caughman": ['K AO1 M AH0 N'],
  "caughron": ['K AO1 R AH0 N'],
  "caught": ['K AA1 T', 'K AO1 T'],
  "caul": ['K AA1 L', 'K AO1 L'],
  "caulder": ['K AO1 L D ER0'],
  "cauldron": ['K AA1 L D R AH0 N', 'K AO1 L D R AH0 N'],
  "cauley": ['K AO1 L IY0'],
  "caulfield": ['K AO1 L F IY2 L D'],
  "cauliflower": ['K AA1 L AH0 F L AW2 ER0'],
  "caulk": ['K AA1 K', 'K AO1 K'],
  "caulking": ['K AO1 K IH0 NG'],
  "caulkins": ['K AO1 L K IH0 N Z'],
  "causal": ['K AO1 Z AH0 L'],
  "causalities": ['K AO2 Z AE1 L IH0 T IY0 Z'],
  "causality": ['K AO2 Z AA1 L IH0 T IY0'],
  "causation": ['K AO2 Z EY1 SH AH0 N'],
  "causative": ['K AA1 Z AH0 T IH0 V', 'K AO1 Z AH0 T IH0 V'],
  "causby": ['K AO1 Z B IY0'],
  "cause": ['K AA1 Z', 'K AO1 Z'],
  "caused": ['K AA1 Z D', 'K AO1 Z D'],
  "causer": ['K AO1 Z ER0'],
  "causes": ['K AA1 Z AH0 Z', 'K AO1 Z IH0 Z'],
  "causeway": ['K AA1 Z W EY2', 'K AO1 Z W EY2'],
  "causeways": ['K AO1 Z W EY2 Z'],
  "causey": ['K AO1 Z IY0'],
  "causing": ['K AA1 Z IH0 NG', 'K AO1 Z IH0 NG'],
  "caustic": ['K AA1 S T IH0 K', 'K AO1 S T IH0 K'],
  "cauterization": ['K AO2 T ER0 IH0 Z EY1 SH AH0 N'],
  "cauterize": ['K AO1 T ER0 AY0 Z'],
  "cauterized": ['K AO1 T ER0 AY0 Z D'],
  "cauterizes": ['K AO1 T ER0 AY0 Z IH0 Z'],
  "cauterizing": ['K AO1 T ER0 AY0 Z IH0 NG'],
  "cauthen": ['K AO1 TH AH0 N'],
  "cauthon": ['K AO1 TH AH0 N'],
  "cauthorn": ['K AO1 TH ER0 N'],
  "caution": ['K AA1 SH AH0 N', 'K AO1 SH AH0 N'],
  "cautionary": ['K AO1 SH AH0 N EH2 R IY0'],
  "cautioned": ['K AA1 SH AH0 N D', 'K AO1 SH AH0 N D'],
  "cautioning": ['K AO1 SH AH0 N IH0 NG'],
  "cautions": ['K AO1 SH AH0 N Z'],
  "cautious": ['K AO1 SH AH0 S'],
  "cautiously": ['K AO1 SH AH0 S L IY0'],
  "cautiousness": ['K AO1 SH AH0 S N AH0 S'],
  "cava": ['K AA1 V AH0'],
  "cavaco": ['K AE1 V AH0 K OW0', 'K AH0 V AA1 K OW0'],
  "cavagnaro": ['K AA0 V AA0 G N AA1 R OW0'],
  "cavalcade": ['K AE1 V AH0 L K EY2 D'],
  "cavalier": ['K AE2 V AH0 L IH1 R', 'K AE1 V AH0 L IY0 R'],
  "cavaliere": ['K AA0 V AA0 L IH1 R IY0'],
  "cavalieri": ['K AA0 V AA0 L IH1 R IY0'],
  "cavalierly": ['K AE2 V AH0 L IH1 R L IY0'],
  "cavaliers": ['K AE2 V AH0 L IH1 R Z'],
  "cavallaro": ['K AA0 V AA0 L AA1 R OW0'],
  "cavallero": ['K AA0 V AA0 L EH1 R OW0'],
  "cavalli": ['K AH0 V AE1 L IY0'],
  "cavalli-sfor": ['K AH0 V AE2 L IY0 S F AO1 R'],
  "cavalli-sforza": ['K AH0 V AE2 L IY0 S F AO1 R Z AA2'],
  "cavallo": ['K AH0 V AE1 L OW0'],
  "cavalry": ['K AE1 V AH0 L R IY0'],
  "cavan": ['K EY1 V AH0 N'],
  "cavanagh": ['K AE1 V AH0 N AE0 G', 'K AE1 V AH0 N AA0'],
  "cavanah": ['K AE1 V AH0 N AH0'],
  "cavanaugh": ['K AE1 V AH0 N AO0'],
  "cavaness": ['K AA1 V AH0 N IH0 S'],
  "cavataio": ['K AA0 V AA0 T AA1 IY0 OW0'],
  "cavazos": ['K AA0 V AA1 Z OW0 Z', 'K AE1 V AH0 Z OW2 S'],
  "cave": ['K EY1 V'],
  "cave's": ['K EY1 V Z'],
  "caveat": ['K EY1 V IY0 AE2 T'],
  "caveats": ['K EY1 V IY0 AE2 T S'],
  "caved": ['K EY1 V D'],
  "cavell": ['K AH0 V EH1 L'],
  "caveman": ['K EY1 V M AE2 N'],
  "caven": ['K EY1 V AH0 N'],
  "cavenaugh": ['K AE1 V IH0 N AO0'],
  "cavender": ['K AH0 V EH1 N D ER0'],
  "cavendish": ['K AE1 V AH0 N D IH0 SH'],
  "caver": ['K EY1 V ER0'],
  "caverly": ['K EY1 V ER0 L IY0'],
  "cavern": ['K AE1 V ER0 N'],
  "cavernous": ['K AE1 V ER0 N AH0 S'],
  "caverns": ['K AE1 V ER0 N Z'],
  "cavers": ['K EY1 V ER0 Z'],
  "caves": ['K EY1 V Z'],
  "cavett": ['K AE1 V IH0 T'],
  "cavey": ['K EY1 V IY0'],
  "caviar": ['K AE1 V IY0 AA2 R'],
  "caviar's": ['K AE1 V IY0 AA2 R Z'],
  "cavin": ['K AE1 V IH0 N'],
  "caviness": ['K EY1 V IY0 N IH0 S'],
  "caving": ['K EY1 V IH0 NG'],
  "cavins": ['K AE1 V IH0 N Z'],
  "cavities": ['K AE1 V IH0 T IY0 Z'],
  "cavitt": ['K AE1 V IH0 T'],
  "cavity": ['K AE1 V AH0 T IY0'],
  "cavness": ['K AE1 V N IH0 S'],
  "cavort": ['K AH0 V AO1 R T'],
  "cavorting": ['K AH0 V AO1 R T IH0 NG'],
  "caw": ['K AO1'],
  "cawley": ['K AO1 L IY0'],
  "cawood": ['K AA1 W UH0 D'],
  "cawsl": ['K AO1 S AH0 L'],
  "cawthon": ['K AO1 TH AH0 N'],
  "cawthorn": ['K AO1 TH ER0 N'],
  "cawthorne": ['K AO1 TH ER0 N'],
  "caxton": ['K AE1 K S T AH0 N'],
  "cay": ['K EY1'],
  "caya": ['K EY1 AH0'],
  "cayce": ['K EY1 S'],
  "cayenne": ['K AY2 EH1 N', 'K EY2 EH1 N'],
  "cayer": ['K EY1 ER0'],
  "cayes": ['K EY1 Z'],
  "caylin": ['K EY1 L IH0 N'],
  "caylor": ['K EY1 L ER0'],
  "cayman": ['K EY1 M AH0 N'],
  "caymans": ['K EY1 M AH0 N Z'],
  "cayne": ['K EY1 N'],
  "cayson": ['K EY1 S AH0 N'],
  "cayton": ['K EY1 T AH0 N'],
  "cayuses": ['K AY1 UW2 S AH0 Z'],
  "caywood": ['K EY1 W UH2 D'],
  "cazares": ['K AA0 Z AA1 R EH0 S'],
  "cazenove": ['K AE1 Z AH0 N OW2 V'],
  "cazier": ['K EY1 Z IY0 ER0'],
  "cb": ['S IY2 B IY1'],
  "cbc": ['S IY2 B IY2 S IY1'],
  "cbs": ['S IY2 B IY2 EH1 S'],
  "cc": ['S IY2 S IY1'],
  "ccd": ['S IY2 S IY2 D IY1'],
  "ccs": ['S IY1 S IY1 EH1 S', 'S IY1 S IY1 Z'],
  "cctv": ['S IY1 S IY2 T IY1 V IY2'],
  "cd": ['S IY2 D IY1'],
  "cdc": ['S IY2 D IY2 S IY1'],
  "cdebaca": ['S IY0 D IH0 B AA1 K AH0'],
  "cdrom": ['S IY1 D IY1 R AA1 M'],
  "cdroms": ['S IY1 D IY1 R AA1 M Z'],
  "cds": ['S IY2 D IY1 Z'],
  "ce": ['S IY2 IY1'],
  "cea": ['S IY2 IY2 EY1', 'S IY1 AH0'],
  "ceara": ['S IY1 R AH0'],
  "cearley": ['S ER1 L IY0'],
  "ceasar": ['S AH0 S AA1 R'],
  "cease": ['S IY1 S'],
  "cease-fire": ['S IY1 S F AY1 ER0'],
  "ceased": ['S IY1 S T'],
  "ceasefire": ['S IY1 S F AY1 ER0'],
  "ceasefires": ['S IY1 S F AY1 ER0 Z'],
  "ceaseless": ['S IY1 S L IH0 S'],
  "ceaselessly": ['S IY1 Z L AH0 S L IY0'],
  "ceaser": ['S IY1 S ER0'],
  "ceases": ['S IY1 S IH0 Z'],
  "ceasing": ['S IY1 S IH0 NG'],
  "ceaucescu": ['CH AW0 CH EH1 S K Y UW0'],
  "ceausescu": ['CH AW0 CH EH1 S K Y UW0'],
  "ceausescu's": ['CH AW0 CH EH1 S K Y UW0 Z'],
  "ceballos": ['S EY0 B AA1 L OW0 Z'],
  "cebu": ['S IY0 B UW1'],
  "cebu's": ['S IY0 B UW1 Z'],
  "cebula": ['CH EH0 B UW1 L AH0'],
  "cebulski": ['CH IH0 B AH1 L S K IY0'],
  "cecala": ['CH EH0 K AA1 L AH0'],
  "ceccarelli": ['CH EH0 K ER0 EH1 L IY0'],
  "cecchi": ['S EH1 K IY0'],
  "cecchini": ['CH EH0 K IY1 N IY0'],
  "cecconi": ['CH EH0 K OW1 N IY0'],
  "cece": ['S IY1 S'],
  "cecelia": ['S IH0 S IY1 L Y AH0'],
  "cecere": ['CH EH0 CH EH1 R IY0'],
  "cech": ['S EH1 K'],
  "ceci": ['S EH1 S IY0'],
  "cecil": ['S IY1 S AH0 L'],
  "cecil's": ['S IY1 S AH0 L Z'],
  "cecile": ['S IH0 S IY1 L'],
  "cecilia": ['S IH0 S IY1 L Y AH0'],
  "cecin": ['S EH1 S IH0 N'],
  "ceco": ['S IY1 K OW0'],
  "cecola": ['S EH0 K OW1 L AH0'],
  "cecos": ['S IY1 K OW0 S'],
  "cedar": ['S IY1 D ER0'],
  "cedar-rapids": ['S IY1 D ER0 R AE1 P IH0 D Z', 'S IY1 D ER0 AE1 P IH0 D Z'],
  "cedars": ['S IY1 D ER0 Z'],
  "cede": ['S IY1 D'],
  "ceded": ['S IY1 D AH0 D', 'S IY1 D IH0 D'],
  "cedeno": ['CH EH0 D EH1 N OW0'],
  "ceder": ['S IY1 D ER0'],
  "cederberg": ['S IY1 D ER0 B ER0 G'],
  "cedergren": ['S IY1 D ER0 G R EH0 N'],
  "cederholm": ['S IY1 D ER0 HH OW0 M'],
  "cederquist": ['S EH1 D ER0 K W IH0 S T', 'S IY1 D ER0 K W IH0 S T'],
  "cedes": ['S IY1 D Z'],
  "cedillo": ['CH EH0 D IH1 L OW0'],
  "ceding": ['S IY1 D IH0 NG'],
  "cedras": ['S EY1 D R AA2 S', 'S EY1 D R AH0 S'],
  "cedras'": ['S EY1 D R AA2 S', 'S EY1 D R AH0 S'],
  "cedras's": ['S EY1 D R AA2 S IH0 S', 'S EY1 D R AH0 S IH0 S'],
  "cedric": ['S EH1 D R IH0 K', 'S IY1 D R IH0 K'],
  "cedrone": ['S EY0 D R OW1 N EY0'],
  "cees": ['S IY1 Z'],
  "cefalo": ['CH EH0 F AA1 L OW0'],
  "cefalu": ['CH EH0 F AA1 L UW0'],
  "cegielski": ['CH IH0 G IY1 L S K IY0'],
  "ceiling": ['S IY1 L IH0 NG'],
  "ceilings": ['S IY1 L IH0 NG Z'],
  "ceja": ['S EY1 Y AH0'],
  "cejka": ['CH EY1 K AH0'],
  "cel": ['S EH1 L', 'S IY1 IY1 EH1 L'],
  "cela": ['S EH1 L AH0'],
  "celadon": ['S EH1 L AH0 D AA2 N'],
  "celandine": ['S EH1 L AH0 N D AY2 N'],
  "celanese": ['S EH1 L AH0 N IY2 Z'],
  "celani": ['CH EH0 L AA1 N IY0'],
  "celano": ['CH EH0 L AA1 N OW0'],
  "celaya": ['S EY0 L EY1 AH0'],
  "cele": ['S IY1 L'],
  "celeb": ['S AH0 L EH1 B'],
  "celebate": ['S EH1 L AH0 B AH0 T'],
  "celebrant": ['S EH1 L AH0 B R AH0 N T'],
  "celebrants": ['S EH1 L AH0 B R AH0 N T S'],
  "celebrate": ['S EH1 L AH0 B R EY2 T'],
  "celebrated": ['S EH1 L AH0 B R EY2 T IH0 D'],
  "celebrates": ['S EH1 L AH0 B R EY2 T S'],
  "celebrating": ['S EH1 L AH0 B R EY2 T IH0 NG'],
  "celebration": ['S EH2 L AH0 B R EY1 SH AH0 N'],
  "celebrations": ['S EH2 L AH0 B R EY1 SH AH0 N Z'],
  "celebratory": ['S AH0 L EH1 B R AH0 T AO2 R IY0'],
  "celebre": ['S EH1 L AH0 B R AH0'],
  "celebrities": ['S AH0 L EH1 B R IH0 T IY0 Z'],
  "celebrity": ['S AH0 L EH1 B R IH0 T IY0'],
  "celebrity's": ['S AH0 L EH1 B R IH0 T IY0 Z'],
  "celebs": ['S AH0 L EH1 B Z'],
  "celena": ['CH EH0 L EH1 N AH0'],
  "celene": ['CH EH1 L IY0 N'],
  "celentano": ['CH EH0 L EH0 N T AA1 N OW0'],
  "celerity": ['S AH0 L EH1 R AH0 T IY0'],
  "celeron": ['S EH1 L ER0 AA0 N'],
  "celery": ['S EH1 L ER0 IY0'],
  "celesta": ['S IH0 L EH1 S T AH0'],
  "celeste": ['S AH0 L EH1 S T'],
  "celestial": ['S AH0 L EH1 S CH AH0 L'],
  "celestin": ['S EH1 L IH0 S T IH0 N'],
  "celestina": ['CH EH0 L EH0 S T IY1 N AH0'],
  "celestine": ['CH EH0 L EH0 S T IY1 N IY0'],
  "celestino": ['CH EH0 L EH0 S T IY1 N OW0'],
  "celestory": ['S AH0 L EH1 S T ER0 IY0'],
  "celia": ['S IY1 L Y AH0'],
  "celibacy": ['S EH1 L AH0 B AH0 S IY0'],
  "celibate": ['S EH1 L IH0 B AH0 T'],
  "celica": ['S EH1 L IH0 K AH0'],
  "celie": ['S EH1 L IY0'],
  "celimene": ['S EH1 L IH0 M IY2 N'],
  "celina": ['S AH0 L IY1 N AH0'],
  "celinda": ['CH EH0 L IY1 N D AH0'],
  "celine": ['S AH0 L IY1 N'],
  "celio": ['S IY1 L IY0 OW0'],
  "celis": ['S EH1 L IH0 S'],
  "celium": ['S IY1 L IY2 AH0 M'],
  "cell": ['S EH1 L'],
  "cell's": ['S EH1 L Z'],
  "cella": ['S EH1 L AH0'],
  "cellar": ['S EH1 L ER0'],
  "cellars": ['S EH1 L ER0 Z'],
  "celled": ['S EH1 L D'],
  "celli": ['CH EH1 L IY0'],
  "cellini": ['CH EH0 L IY1 N IY0'],
  "cellio": ['CH EH1 L IY0 OW0'],
  "cellist": ['CH EH1 L AH0 S T'],
  "cellmark": ['S EH1 L M AA2 R K'],
  "cellmark's": ['S EH1 L M AA2 R K S'],
  "cellnet": ['S EH1 L N EH2 T'],
  "cello": ['CH EH1 L OW0'],
  "cellophane": ['S EH1 L AH0 F EY2 N'],
  "cellphone": ['S EH1 L F OW0 N'],
  "cellphones": ['S EH1 L F OW2 N Z'],
  "cellpro": ['S EH1 L P R OW0'],
  "cells": ['S EH1 L Z'],
  "cells'": ['S EH1 L Z'],
  "cellstar": ['S EH1 L S T AA2 R'],
  "cellucci": ['CH EH0 L UW1 CH IY0'],
  "cellular": ['S EH1 L Y AH0 L ER0'],
  "cellular's": ['S EH1 L Y AH0 L ER0 Z'],
  "celluloid": ['S EH1 L AH0 L OY2 D'],
  "cellulosa": ['S EH2 L UW0 L OW1 S AH0'],
  "cellulose": ['S EH1 L Y AH0 L OW2 S'],
  "celmer": ['S EH1 L M ER0'],
  "celnik": ['S EH1 L N IH0 K'],
  "celo": ['S EH1 L OW0', 'S IY1 L OW0', 'S IY1 IY1 EH1 L OW1'],
  "celona": ['CH EH0 L OW1 N AH0'],
  "celosia": ['CH EH0 L OW1 S IY0 AH0'],
  "cels": ['S EH1 L Z'],
  "celsius": ['S EH1 L S IY0 AH0 S'],
  "celso": ['S EH1 L S OW0'],
  "celt": ['S EH1 L T', 'K EH1 L T'],
  "celtic": ['S EH1 L T IH0 K', 'K EH1 L T IH0 K'],
  "celtics": ['S EH1 L T IH0 K S'],
  "celtics'": ['S EH1 L T IH0 K S'],
  "celts": ['S EH1 L T S', 'K EH1 L T S'],
  "cement": ['S AH0 M EH1 N T', 'S IH0 M EH1 N T'],
  "cemented": ['S AH0 M EH1 N T IH0 D', 'S IH0 M EH1 N T IH0 D'],
  "cementing": ['S IH0 M EH1 N T IH0 NG'],
  "cementos": ['S EH0 M EH1 N T OW0 S'],
  "cemeteries": ['S EH1 M AH0 T EH2 R IY0 Z'],
  "cemetery": ['S EH1 M AH0 T EH2 R IY0', 'S EH1 M IH0 T EH2 R IY0'],
  "cemex": ['K EH1 M EH2 K S'],
  "cemp": ['S EH1 M P'],
  "cencall": ['S EH1 N S EH2 L'],
  "cenci": ['CH EH1 N CH IY0'],
  "cencor": ['S EH1 N K AO2 R'],
  "cendejas": ['S EY0 N D EY1 Y AA0 Z'],
  "cenergy": ['S EH1 N ER0 JH IY0'],
  "ceniceros": ['S EY0 N IY0 S EH1 R OW0 Z'],
  "cenith": ['S EH1 N IH0 TH'],
  "cenith's": ['S EH1 N IH0 TH S'],
  "cenozoic": ['S IY2 N AH0 Z OW1 IH0 K'],
  "censer": ['S EH1 N S ER0'],
  "censor": ['S EH1 N S ER0'],
  "censored": ['S EH1 N S ER0 D'],
  "censoring": ['S EH1 N S ER0 IH0 NG'],
  "censors": ['S EH1 N S ER0 Z'],
  "censorship": ['S EH1 N S ER0 SH IH2 P'],
  "censure": ['S EH1 N SH ER0'],
  "censured": ['S EH1 N SH ER0 D'],
  "census": ['S EH1 N S AH0 S'],
  "censuses": ['S EH1 N S AH0 S IH0 Z'],
  "cent": ['S EH1 N T'],
  "centanni": ['CH EH0 N T AA1 N IY0', 'S EH0 N T AA1 N IY0'],
  "centaur": ['S EH1 N T AO2 R'],
  "centaur's": ['S EH1 N T AO2 R Z'],
  "centauri": ['S EH0 N T AA1 R IY0'],
  "centaurs": ['S EH1 N T AO2 R Z'],
  "centavos": ['S EH0 N T AA1 V OW2 S'],
  "centel": ['S EH1 N T EH2 L'],
  "centel's": ['S EH1 N T EH2 L Z'],
  "centenarian": ['S EH2 N T AH0 N EH1 R IY0 AH0 N'],
  "centenarians": ['S EH2 N T AH0 N EH1 R IY0 AH0 N Z'],
  "centenary": ['S EH1 N T AH0 N EH2 R IY0'],
  "centennial": ['S EH0 N T EH1 N IY0 AH0 L'],
  "centennial's": ['S EH0 N T EH1 N IY0 AH0 L Z'],
  "centeno": ['CH EH0 N T EH1 N OW0', 'S EH0 N T EH1 N OW0'],
  "center": ['S EH1 N T ER0', 'S EH1 N ER0'],
  "center's": ['S EH1 N T ER0 Z', 'S EH1 N ER0 Z'],
  "centerbanc": ['S EH1 N T ER0 B AE0 NG K'],
  "centerbank": ['S EH1 N T ER0 B AE2 NG K'],
  "centered": ['S EH1 N T ER0 D'],
  "centerfielder": ['S EH1 N T ER0 F IY2 L D ER0'],
  "centerfold": ['S EH1 N T ER0 F OW2 L D'],
  "centering": ['S EH1 N T ER0 IH0 NG'],
  "centerior": ['S EH2 N T IH1 R IY0 ER0'],
  "centerpiece": ['S EH1 N T ER0 P IY2 S'],
  "centerre": ['S EH1 N T ER0'],
  "centerre's": ['S EH1 N T ER0 Z'],
  "centers": ['S EH1 N T ER0 Z', 'S EH1 N ER0 Z'],
  "centers'": ['S EH1 N T ER0 Z', 'S EH1 N ER0 Z'],
  "centerville": ['S EH1 N T ER0 V IH0 L'],
  "centex": ['S EH1 N T EH2 K S'],
  "centigrade": ['S EH1 N T AH0 G R EY2 D'],
  "centigram": ['S EH1 N T AH0 G R AE2 M'],
  "centime": ['S EH1 N T AY2 M'],
  "centimes": ['S EH1 N T AY2 M Z'],
  "centimeter": ['S EH1 N T AH0 M IY2 T ER0'],
  "centimeters": ['S EH1 N T AH0 M IY2 T ER0 Z'],
  "centimetre": ['S EH1 N T AH0 M IY2 T ER0'],
  "centimetres": ['S EH1 N T AH0 M IY2 T ER0 Z'],
  "centipede": ['S EH1 N T IH0 P IY2 D'],
  "centner": ['S EH1 N T N ER0'],
  "centocor": ['S EH1 N T AH0 K AO2 R'],
  "centocor's": ['S EH1 N T AH0 K AO2 R Z'],
  "centofanti": ['CH EH0 N T OW0 F AA1 N T IY0'],
  "centola": ['CH EH0 N T OW1 L AH0'],
  "centoxin": ['S EH2 N T AA1 K S IH0 N'],
  "central": ['S EH1 N T R AH0 L'],
  "central's": ['S EH1 N T R AH0 L Z'],
  "centrale": ['S EH0 N T R AA1 L'],
  "centralia": ['S EH0 N T R EY1 L IY0 AH0'],
  "centralism": ['S EH1 N T R AH0 L IH2 Z AH0 M'],
  "centralists": ['S EH1 N T R AH0 L IH0 S T S'],
  "centrality": ['S EH0 N T AE1 L IH0 T IY0'],
  "centralization": ['S EH2 N T R AH0 L IH0 Z EY1 SH AH0 N'],
  "centralize": ['S EH1 N T R AH0 L AY2 Z'],
  "centralized": ['S EH1 N T R AH0 L AY2 Z D'],
  "centralizing": ['S EH1 N T R AH0 L AY2 Z IH0 NG'],
  "centrally": ['S EH1 N T R AH0 L IY0'],
  "centram": ['S EH1 N T R AE2 M'],
  "centre": ['S EH1 N T ER0'],
  "centrella": ['S EH2 N T R EH1 L AH0'],
  "centres": ['S EH1 N T ER0 Z'],
  "centrex": ['S EH1 N T R AH0 K S'],
  "centrifugal": ['S EH1 N T R IH0 F Y UW2 G AH0 L'],
  "centrifuge": ['S EH1 N T R AH0 F Y UW2 JH'],
  "centrifuges": ['S EH1 N T R AH0 F Y UW2 JH IH0 Z'],
  "centrist": ['S EH1 N T R IH0 S T'],
  "centrists": ['S EH1 N T R IH0 S T S'],
  "centro": ['S EH1 N T R OW0'],
  "centromin": ['S EH1 N T R AH0 M IH0 N'],
  "centronics": ['S EH2 N T R AA1 N IH0 K S'],
  "centrust": ['S EH1 N T R AH2 S T'],
  "centrust's": ['S EH1 N T R AH2 S T S'],
  "cents": ['S EH1 N T S', 'S EH1 N S'],
  "centum": ['K EH1 N T AH0 M'],
  "centuri": ['S EH0 N T UH1 R IY0'],
  "centuries": ['S EH1 N CH ER0 IY0 Z'],
  "centurion": ['S EH0 N T UH1 R IY0 AH0 N'],
  "century": ['S EH1 N CH ER0 IY0'],
  "century's": ['S EH1 N CH ER0 IY0 Z'],
  "cenvill": ['S EH1 N V IH0 L'],
  "ceo": ['S IY1 IY2 OW1'],
  "cep": ['S EH1 P'],
  "cepeda": ['S EY0 P EY1 D AH0'],
  "cepero": ['S EY0 P EH1 R OW0'],
  "cephalon": ['S EH1 F AH0 L AA2 N'],
  "cephalopod": ['S EH1 F AH0 L AH0 P AA2 D'],
  "cephalosporin": ['S EH2 F AH0 L AO1 S P AO2 R IH0 N'],
  "cephas": ['S EH1 F AH0 Z'],
  "cephus": ['S EH1 F AH0 S'],
  "cera": ['S EH1 R AH0'],
  "ceraceous": ['S ER0 EY1 SH AH0 S'],
  "ceradyne": ['S EH1 R AH0 D AY2 N'],
  "cerami": ['CH ER0 AA1 M IY0'],
  "ceramic": ['S ER0 AE1 M IH0 K'],
  "ceramics": ['S ER0 AE1 M IH0 K S'],
  "cerankosky": ['S EH2 R AH0 NG K AO1 S K IY2'],
  "cerasoli": ['CH ER0 AA0 S OW1 L IY0'],
  "ceravolo": ['CH ER0 AA0 V OW1 L OW0'],
  "cerbone": ['CH ER1 B OW0 N'],
  "cercone": ['CH ER0 K OW1 N IY0'],
  "cerda": ['CH EH1 R D AH0'],
  "cereal": ['S IH1 R IY0 AH0 L'],
  "cereals": ['S IH1 R IY0 AH0 L Z'],
  "cerebral": ['S EH1 R AH0 B R AH0 L', 'S ER0 IY1 B R AH0 L'],
  "cerebrally": ['S ER0 IY1 B R AH0 L IY0'],
  "ceredase": ['S EH1 R AH0 D EY2 Z'],
  "cereghino": ['CH ER0 EH0 G IY1 N OW0'],
  "cerelia": ['CH ER0 EH1 L IY0 AH0'],
  "ceremonial": ['S EH2 R AH0 M OW1 N IY0 AH0 L'],
  "ceremonies": ['S EH1 R AH0 M OW2 N IY0 Z'],
  "ceremony": ['S EH1 R AH0 M OW2 N IY0'],
  "cereno": ['S ER0 EY1 N OW0'],
  "ceres": ['S IH1 R IY0 Z'],
  "cerezo": ['S EH2 R EY1 Z OW0', 'S ER0 EY1 Z OW0'],
  "cerf": ['S ER1 F'],
  "ceridian": ['S ER0 IH1 D IY0 AH0 N'],
  "cerino": ['CH ER0 IY1 N OW0'],
  "cerio": ['CH EH1 R IY0 OW0'],
  "cerise": ['S ER0 IY1 S'],
  "cermak": ['CH ER1 M AH0 K'],
  "cern": ['S ER1 N'],
  "cerna": ['CH EH1 R N AH0'],
  "cerney": ['S ER1 N IY0'],
  "cerniglia": ['CH ER2 N IY1 G L IY0 AA0'],
  "cernuda": ['S ER0 N UW1 D AH0'],
  "cernuda's": ['S ER0 N UW1 D AH0 Z'],
  "cerny": ['S ER1 N IY0'],
  "cero": ['S EH1 R OW2'],
  "cerone": ['CH ER0 OW1 N IY0'],
  "cerra": ['S EH1 R AH0'],
  "cerrato": ['CH ER0 AA1 T OW0'],
  "cerreta": ['CH ER0 EH1 T AH0'],
  "cerrito": ['CH ER0 IY1 T OW0'],
  "cerritos": ['S EH0 R IY1 T OW0 S'],
  "cerro": ['S EH1 R OW0'],
  "cerrone": ['CH ER0 OW1 N IY0'],
  "cerruti": ['CH ER0 UW1 T IY0', 'S ER0 UW1 T IY0'],
  "cerska": ['K ER1 S K AH0', 'S ER1 S K AH0'],
  "cert": ['S ER1 T'],
  "certain": ['S ER1 T AH0 N'],
  "certainly": ['S ER1 T AH0 N L IY0'],
  "certainteed": ['S ER1 T AH0 N T IY2 D'],
  "certainties": ['S ER1 T AH0 N T IY0 Z'],
  "certainty": ['S ER1 T AH0 N T IY0'],
  "certificate": ['S ER0 T IH1 F IH0 K AH0 T'],
  "certificates": ['S ER0 T IH1 F IH0 K AH0 T S'],
  "certification": ['S ER2 T AH0 F AH0 K EY1 SH AH0 N'],
  "certifications": ['S ER2 T AH0 F AH0 K EY1 SH AH0 N Z'],
  "certified": ['S ER1 T AH0 F AY2 D'],
  "certified's": ['S ER1 T AH0 F AY2 D Z'],
  "certifies": ['S ER1 T AH0 F AY2 Z'],
  "certify": ['S ER1 T AH0 F AY2'],
  "certifying": ['S ER1 T AH0 F AY2 IH0 NG'],
  "certitude": ['S ER1 T AH0 T UW2 D'],
  "certo": ['CH EH1 R T OW0'],
  "certron": ['S ER1 T R AA0 N'],
  "cerulli": ['CH ER0 UW1 L IY0'],
  "cerullo": ['CH ER0 UW1 L OW0'],
  "cerus": ['S EH1 R AH0 S'],
  "cerutti": ['CH ER0 UW1 T IY0'],
  "cervantes": ['S EH0 R V AA1 N T EH0 S'],
  "cervantez": ['S EH0 R V AA1 N T EH0 Z'],
  "cerveceria": ['S ER2 V AH0 S IH1 R IY0 AH0'],
  "cervenka": ['S EH0 R V EY1 NG K AH0'],
  "cerveny": ['CH ER0 V IY1 N IY0'],
  "cervera": ['CH ER0 V EH1 R AH0'],
  "cervesato": ['S EH2 R V EH0 S AA1 T OW2'],
  "cerveza": ['S ER2 V EY1 Z AH0', 'S EH2 R V EY1 Z AH0'],
  "cervi": ['CH EH1 R V IY0'],
  "cervical": ['S ER1 V AH0 K AH0 L', 'S ER1 V IH0 K AH0 L'],
  "cervini": ['CH ER0 V IY1 N IY0'],
  "cervix": ['S ER1 V IH0 K S'],
  "cervone": ['CH ER0 V OW1 N IY0'],
  "ceryl": ['S EH1 R AH0 L'],
  "cesar": ['S IY1 Z ER0'],
  "cesare": ['CH EY0 Z AA1 R EY0'],
  "cesario": ['CH EH0 S AA1 R IY0 OW0'],
  "cesaro": ['CH EH0 S AA1 R OW0'],
  "cesarz": ['S EY1 S AA0 R Z'],
  "cesena": ['CH EH0 S EH1 N AH0'],
  "cesium": ['S IY1 Z IY0 AH0 M'],
  "cespedes": ['S EY0 S P EY1 D EH0 S'],
  "cessation": ['S EH2 S EY1 SH AH0 N'],
  "cessna": ['S EH1 S N AH0', 'S EH1 Z N AH0'],
  "cessna's": ['S EH1 S N AH0 Z', 'S EH1 Z N AH0 Z'],
  "cesspool": ['S EH1 S P UW2 L'],
  "cestaro": ['CH EH0 S T AA1 R OW0'],
  "ceta": ['S EH1 T AH0', 'S IY1 IY1 T IY1 EY1'],
  "cetacean": ['S IH0 T EY1 SH AH0 N', 'S IY0 T EY1 SH AH0 N'],
  "cetec": ['S IY1 T EH2 K'],
  "cetera": ['S EH1 T ER0 AH0'],
  "cetus": ['S IY1 T AH0 S'],
  "cetus's": ['S IY1 T AH0 S IH0 Z'],
  "cevallos": ['S EY0 V AA1 L OW0 Z'],
  "cevaxs": ['S EH1 V AE0 K S IH0 Z'],
  "ceylon": ['S IH0 L AA1 N', 'S IY0 L AA1 N'],
  "cezanne": ['S EH2 Z AE1 N'],
  "cezanne's": ['S EH2 Z AE1 N Z'],
  "cfo": ['S IY1 EH2 F OW1'],
  "cgi": ['S IY2 G IY2 AY1'],
  "cha": ['CH AA1'],
  "cha-chas": ['CH AA1 CH AA2 Z'],
  "chablis": ['SH AH0 B L IY1'],
  "chabon": ['CH EY1 B AH0 N'],
  "chabot": ['SH AH0 B OW1'],
  "chacabuco": ['CH AE2 K AH0 B Y UW1 K OW0'],
  "chace": ['CH EY1 S'],
  "chachere": ['SH AH0 SH IH1 R'],
  "chachi": ['CH AA1 CH IY0'],
  "chacin": ['SH EY1 S IH0 N'],
  "chacko": ['CH AE1 K OW0'],
  "chacon": ['CH AE1 K AH0 N'],
  "chad": ['CH AE1 D'],
  "chad's": ['CH AE1 D Z'],
  "chadbourne": ['SH AH0 D B UH1 R N'],
  "chadd": ['CH AE1 D'],
  "chadderdon": ['CH AH0 D ER1 D AH0 N'],
  "chaddock": ['CH AE1 D AH0 K'],
  "chadel": ['CH AE1 D AH0 L'],
  "chadian": ['CH EY1 D IY0 AH0 N'],
  "chadick": ['CH AE1 D IH0 K'],
  "chadli": ['CH AE1 D L IY0'],
  "chadron": ['CH AE1 D R AH0 N'],
  "chadwell": ['CH AE1 D W EH2 L'],
  "chadwick": ['CH AE1 D W IH0 K'],
  "chadwick's": ['CH AE1 D W IH0 K S'],
  "chae": ['CH AY1'],
  "chaebol": ['CH EY1 B AH0 L'],
  "chafe": ['CH EY1 F'],
  "chafed": ['CH EY1 F T'],
  "chafee": ['CH AE1 F IY0', 'CH EY1 F IY0'],
  "chafee's": ['CH EY1 F IY0 Z', 'CH AE1 F IY0 Z'],
  "chafes": ['CH EY1 F S'],
  "chafete": ['CH AH0 F IY1 T'],
  "chaff": ['CH AE1 F'],
  "chaffee": ['CH AE1 F IY0'],
  "chaffin": ['CH AE1 F IH0 N'],
  "chaffins": ['CH AE1 F IH0 N Z'],
  "chaffy": ['CH AE1 F IY0'],
  "chafin": ['CH AE1 F IH0 N'],
  "chafing": ['CH EY1 F IH0 NG'],
  "chagall": ['CH AH0 G AA1 L', 'CH AH0 G AE1 L'],
  "chagnon": ['CH AE1 G N AH0 N'],
  "chagrin": ['SH AH0 G R IH1 N'],
  "chagrined": ['SH AH0 G R IH1 N D'],
  "chai": ['CH AY1'],
  "chaidez": ['CH AA0 IY1 D EH0 Z'],
  "chaiken": ['CH EY1 K AH0 N'],
  "chaikin": ['CH EY1 K IH0 N'],
  "chaim": ['HH AY1 IH0 M'],
  "chain": ['CH EY1 N'],
  "chain's": ['CH EY1 N Z'],
  "chained": ['CH EY1 N D'],
  "chaining": ['CH EY1 N IH0 NG'],
  "chains": ['CH EY1 N Z'],
  "chains'": ['CH EY1 N Z'],
  "chainsaw": ['CH EY1 N S AO2'],
  "chainsaws": ['CH EY1 N S AO2 Z'],
  "chair": ['CH EH1 R'],
  "chaired": ['CH EH1 R D'],
  "chaires": ['SH EH1 R Z'],
  "chairez": ['CH AA0 IH1 R EH0 Z'],
  "chairing": ['CH EH1 R IH0 NG'],
  "chairman": ['CH EH1 R M AH0 N'],
  "chairman's": ['CH EH1 R M AH0 N Z'],
  "chairmanship": ['CH EH1 R M AH0 N SH IH2 P'],
  "chairmanships": ['CH EH1 R M AH0 N SH IH2 P S'],
  "chairmen": ['CH EH1 R M IH0 N'],
  "chairpeople": ['CH EH1 R P IY2 P AH0 L'],
  "chairperson": ['CH EH1 R P ER2 S AH0 N'],
  "chairs": ['CH EH1 R Z'],
  "chairwoman": ['CH EH1 R W UH2 M AH0 N'],
  "chairwomen": ['CH EH1 R W IH2 M EH0 N'],
  "chaise": ['SH EY1 Z'],
  "chaisson": ['CH EY1 S AH0 N'],
  "chait": ['CH EY1 T'],
  "chajet": ['CH AE1 JH AH0 T'],
  "chalabi": ['CH AH0 L AA1 B IY0'],
  "chalasani": ['CH AE2 L AH0 S AE1 N IY0'],
  "chalcedony": ['CH AE1 L S AH0 D OW2 N IY0'],
  "chalet": ['SH AE1 L EY2', 'SH AH0 L EY1'],
  "chalets": ['SH AH0 L EY1 Z', 'SH AE1 L EY2 Z'],
  "chalfant": ['CH AE1 F AH0 N T', 'CH AE1 F AA0 N T'],
  "chalfin": ['CH AE1 L F IH0 N'],
  "chalice": ['CH AE1 L IH0 S'],
  "chalifoux": ['SH AE1 L IH0 F UW0'],
  "chalk": ['CH AA1 K', 'CH AO1 K'],
  "chalked": ['CH AO1 K T'],
  "chalker": ['CH AO1 K ER0'],
  "chalking": ['CH AO1 K IH0 NG'],
  "chalkley": ['CH AE1 L K L IY0'],
  "chalks": ['CH AO1 K S'],
  "challender": ['CH AH0 L EH1 N D ER0'],
  "challenge": ['CH AE1 L AH0 N JH'],
  "challenged": ['CH AE1 L AH0 N JH D'],
  "challenger": ['CH AE1 L AH0 N JH ER0', 'CH AE1 L IH0 N JH ER0'],
  "challenger's": ['CH AE1 L AH0 N JH ER0 Z'],
  "challengers": ['CH AE1 L AH0 N JH ER0 Z'],
  "challengery": ['CH AE1 L AH0 N JH ER0 IY0'],
  "challenges": ['CH AE1 L AH0 N JH IH0 Z'],
  "challenging": ['CH AE1 L AH0 N JH IH0 NG'],
  "challis": ['SH AE1 L IY0'],
  "chalmers": ['CH AA1 M ER0 Z'],
  "chalmette": ['SH AE0 L M EH1 T'],
  "chaloupka": ['CH AH0 L UW1 P K AH0'],
  "chaloux": ['SH AH0 L UW1'],
  "chalsty": ['CH AE1 L S T IY0'],
  "chalupa": ['CH AH0 L UW1 P AH0'],
  "cham": ['CH AE1 M'],
  "chamber": ['CH EY1 M B ER0'],
  "chamber's": ['CH EY1 M B ER0 Z'],
  "chambered": ['CH EY1 M B ER0 D'],
  "chamberlain": ['CH EY1 M B ER0 L AH0 N', 'CH EY1 M B ER0 L IH0 N'],
  "chamberlain's": ['CH EY1 M B ER0 L AH0 N Z'],
  "chamberland": ['CH AE1 M B ER0 L AH0 N D'],
  "chamberlayne": ['CH EY1 M B ER0 L EY2 N'],
  "chamberlin": ['CH EY1 M B ER0 L IH0 N'],
  "chambers": ['CH EY1 M B ER0 Z'],
  "chambers'": ['CH EY1 M B ER0 Z'],
  "chambers's": ['CH EY1 M B ER0 Z IH0 Z'],
  "chamblee": ['CH AE1 M B L IY0'],
  "chambless": ['SH AH0 M B L IY1 S'],
  "chamblin": ['CH AE1 M B L IH0 N'],
  "chambliss": ['CH AE1 M B L IH0 S'],
  "chambon": ['CH AE1 M B AH0 N'],
  "chameleon": ['K AH0 M IY1 L IY0 AH0 N'],
  "chamlee": ['CH AE1 M L IY0'],
  "chamlong": ['CH AE1 M L AO2 NG'],
  "chamness": ['CH AE1 M N IH0 S'],
  "chamomile": ['K AE2 M AH0 M AY1 L', 'K AE2 M AH0 M IY1 L'],
  "chamonix": ['CH AE1 M AH0 N IH0 K S', 'SH AE0 M OW0 N IY0'],
  "chamorro": ['CH AH0 M AO1 R OW0', 'CH OW0 M AO1 R OW0'],
  "chamorro's": ['CH AH0 M AO1 R OW0 Z'],
  "champ": ['CH AE1 M P'],
  "champa": ['K AA1 M P AH0'],
  "champagne": ['SH AE0 M P EY1 N'],
  "champagnes": ['SH AE0 M P EY1 N Z'],
  "champaign": ['CH AE0 M P EY1 N'],
  "champaign-urbana": ['CH AE0 M P EY1 N ER0 B AE1 N AH0'],
  "champeau": ['SH AE0 M P OW1'],
  "champine": ['CH AE1 M P AY2 N'],
  "champion": ['CH AE1 M P IY0 AH0 N'],
  "champion's": ['CH AE1 M P IY0 AH0 N Z'],
  "championed": ['CH AE1 M P IY0 AH0 N D'],
  "championing": ['CH AE1 M P IY0 AH0 N IH0 NG'],
  "champions": ['CH AE1 M P IY0 AH0 N Z'],
  "championship": ['CH AE1 M P IY0 AH0 N SH IH2 P'],
  "championship's": ['CH AE1 M P IY0 AH0 N SH IH2 P S'],
  "championships": ['CH AE1 M P IY0 AH0 N SH IH2 P S'],
  "champlain": ['SH AE0 M P L EY1 N'],
  "champlin": ['CH AE1 M P L IH0 N'],
  "champney": ['CH AE1 M P N IY0'],
  "champoux": ['SH AE0 M P UW1'],
  "champs": ['CH AE1 M P S'],
  "chan": ['CH AE1 N'],
  "chan's": ['CH AE1 N Z'],
  "chana": ['CH AE1 N AH0'],
  "chance": ['CH AE1 N S'],
  "chanced": ['CH AE1 N S T'],
  "chancellor": ['CH AE1 N S AH0 L ER0', 'CH AE1 N S L ER0'],
  "chancellor's": ['CH AE1 N S AH0 L ER0 Z'],
  "chancellors": ['CH AE1 N S AH0 L ER0 Z'],
  "chancery": ['CH AE1 N S ER0 IY0'],
  "chances": ['CH AE1 N S AH0 Z', 'CH AE1 N S IH0 Z'],
  "chancey": ['CH AE1 N S IY0'],
  "chancy": ['CH AE1 N S IY0'],
  "chand": ['CH AE1 N D'],
  "chanda": ['CH AE1 N D AH0'],
  "chandelier": ['SH AE0 N D AH0 L IH1 R'],
  "chandeliers": ['SH AE2 N D AH0 L IH1 Z'],
  "chandler": ['CH AE1 N D L ER0'],
  "chandler's": ['CH AE1 N D L ER0 Z'],
  "chandley": ['CH AE1 N D L IY0'],
  "chandon": ['CH AE1 N D IH0 N', 'SH AE2 N D AA1 N'],
  "chandra": ['CH AE1 N D R AH0'],
  "chandrika": ['CH AA0 N D R IY1 K AH0', 'SH AA0 N D R IY1 K AH0'],
  "chandross": ['CH AE0 N D R AO1 S'],
  "chanel": ['SH AH0 N EH1 L'],
  "chaney": ['CH EY1 N IY0'],
  "chang": ['CH AE1 NG'],
  "chang-hsin": ['CH AA1 NG SH IH1 N'],
  "chang-ming": ['CH AA1 NG M IH1 NG', 'CH AE1 NG M IH1 NG'],
  "changchun": ['CH AA1 NG CH UH1 N'],
  "change": ['CH EY1 N JH'],
  "changeable": ['CH EY1 N JH AH0 B AH0 L'],
  "changed": ['CH EY1 N JH D'],
  "changeover": ['CH EY1 N JH OW2 V ER0'],
  "changeovers": ['CH EY1 N JH OW2 V ER0 Z'],
  "changer": ['CH EY1 N JH ER0'],
  "changers": ['CH EY1 N JH ER0 Z'],
  "changes": ['CH EY1 N JH AH0 Z', 'CH EY1 N JH IH0 Z'],
  "changing": ['CH EY1 N JH IH0 NG'],
  "changsho": ['CH AE1 NG SH OW1'],
  "chanin": ['CH AE1 N IH0 N'],
  "chanishiva": ['SH AA0 N IH0 SH IY1 V AH0'],
  "chanley": ['CH AE1 N L IY0'],
  "channel": ['CH AE1 N AH0 L'],
  "channel's": ['CH AE1 N AH0 L Z'],
  "channeled": ['CH AE1 N AH0 L D'],
  "channeling": ['CH AE1 N AH0 L IH0 NG', 'CH AE1 N L IH0 NG'],
  "channell": ['CH AE1 N AH0 L'],
  "channell's": ['SH AH0 N EH1 L Z'],
  "channels": ['CH AE1 N AH0 L Z'],
  "channing": ['CH AE1 N IH0 NG'],
  "channon": ['CH AE1 N AH0 N'],
  "chanos": ['CH AA1 N OW0 S'],
  "chanson": ['SH AA1 N S AO2 N'],
  "chant": ['CH AE1 N T'],
  "chantal": ['CH AE1 N T AH0 L'],
  "chanted": ['CH AE1 N T IH0 D'],
  "chantilly": ['SH AE2 N T IH1 L IY0'],
  "chanting": ['CH AE1 N T IH0 NG'],
  "chants": ['CH AE1 N T S'],
  "chanukah": ['HH AA1 N AH0 K AH0'],
  "chany": ['CH EY1 N IY0'],
  "chao": ['CH AW1'],
  "chaos": ['K EY1 AA0 S'],
  "chaotic": ['K EY0 AA1 T IH0 K'],
  "chap": ['CH AE1 P'],
  "chapa": ['CH AA1 P AH0'],
  "chaparral": ['SH AE2 P ER0 AE1 L'],
  "chaparro": ['K AA0 P AA1 R OW0'],
  "chapas": ['CH AA1 P AH0 S'],
  "chapdelaine": ['SH AE1 P D IH0 L EY0 N'],
  "chapek": ['CH AE1 P IH0 K'],
  "chapel": ['CH AE1 P AH0 L'],
  "chapel's": ['CH AE1 P AH0 L Z'],
  "chapell": ['SH AH0 P EH1 L'],
  "chapelle": ['SH AH0 P EH1 L'],
  "chaperone": ['SH AE1 P ER0 OW2 N'],
  "chaperones": ['SH AE1 P ER0 OW2 N Z'],
  "chaperoning": ['SH AE1 P ER0 OW2 N IH0 NG'],
  "chapin": ['SH AH0 P AE1 N'],
  "chaplain": ['CH AE1 P L AH0 N'],
  "chaplains": ['CH AE1 P L AH0 N Z'],
  "chaplan": ['CH AE1 P L AH0 N'],
  "chaplin": ['CH AE1 P L AH0 N', 'CH AE1 P L IH0 N'],
  "chaplin's": ['CH AE1 P L IH0 N Z'],
  "chapman": ['CH AE1 P M AH0 N'],
  "chapman's": ['CH AE1 P M AH0 N Z'],
  "chapnick": ['CH AE1 P N IH0 K'],
  "chapoton": ['CH AE1 P OW0 T AA2 N'],
  "chapp": ['CH AE1 P'],
  "chappaquiddick": ['CH AE2 P AH0 K W IH1 D IH0 K'],
  "chappel": ['CH AE1 P AH0 L'],
  "chappelear": ['SH AE1 P IH0 L ER0'],
  "chappell": ['CH AE1 P AH0 L'],
  "chappelle": ['SH AH0 P EH1 L'],
  "chappie": ['CH AE1 P IY0'],
  "chapple": ['CH AE1 P AH0 L'],
  "chappuis": ['SH AE1 P UW0 IH0 Z'],
  "chaps": ['CH AE1 P S'],
  "chapter": ['CH AE1 P T ER0'],
  "chapters": ['CH AE1 P T ER0 Z'],
  "chaput": ['CH AE1 P AH0 T'],
  "char": ['CH AA1 R'],
  "chara": ['CH AA1 R AH0'],
  "character": ['K EH1 R IH0 K T ER0'],
  "character's": ['K EH1 R IH0 K T ER0 Z'],
  "characteristic": ['K EH2 R AH0 K T ER0 IH1 S T IH0 K'],
  "characteristically": ['K EH2 R AH0 K T ER0 IH1 S T IH0 K L IY0'],
  "characteristics": ['K EH2 R AH0 K T ER0 IH1 S T IH0 K S'],
  "characterization": ['K EH2 R AH0 K T ER0 IH0 Z EY1 SH AH0 N'],
  "characterizations": ['K EH2 R AH0 K T ER0 IH0 Z EY1 SH AH0 N Z'],
  "characterize": ['K EH1 R AH0 K T ER0 AY2 Z'],
  "characterized": ['K EH1 R AH0 K T ER0 AY2 Z D'],
  "characterizes": ['K EH1 R AH0 K T ER0 AY2 Z AH0 Z'],
  "characterizing": ['K EH1 R AH0 K T ER0 AY2 Z IH0 NG'],
  "characters": ['K AE1 R AH0 K T ER0 Z', 'K EH1 R AH0 K T ER0 Z'],
  "characters'": ['CH EH1 R AH0 K T ER0 Z'],
  "charade": ['SH ER0 EY1 D'],
  "charades": ['SH ER0 EY1 D Z'],
  "charalambos": ['CH AA2 R AH0 L AA1 M B OW0 S'],
  "charasse": ['CH EH1 R AE0 S'],
  "charboneau": ['SH AA1 R B AH0 N OW0'],
  "charbonneau": ['SH AA1 R B AH0 N OW2'],
  "charbonnet": ['SH AA1 R B AH0 N IH0 T', 'SH AA1 R B AH0 N EY0'],
  "charcoal": ['CH AA1 R K OW2 L'],
  "charcoals": ['CH AA1 R K OW2 L Z'],
  "charcuterie": ['SH AA1 R K UH0 T R IY2'],
  "chard": ['CH AA1 R D'],
  "chardonnay": ['CH AA0 R D AA1 N EY0'],
  "chardonnays": ['CH AA0 R D AA1 N EY0 Z'],
  "charen": ['CH AA1 R AH0 N'],
  "charen's": ['CH AA1 R AH0 N Z'],
  "charest": ['SH AA0 R EH1 S T'],
  "charette": ['SH ER0 EH1 T'],
  "charge": ['CH AA1 R JH'],
  "chargeable": ['CH AA1 R JH AH0 B AH0 L'],
  "chargeback": ['CH AA1 R JH B AE2 K'],
  "chargebacks": ['CH AA1 R JH B AE2 K S'],
  "charged": ['CH AA1 R JH D'],
  "charger": ['CH AA1 R JH ER0'],
  "chargers": ['CH AA1 R JH ER0 Z'],
  "charges": ['CH AA1 R JH AH0 Z', 'CH AA1 R JH IH0 Z'],
  "chargeurs": ['CH AA0 R G Y UW1 R Z'],
  "charging": ['CH AA1 R JH IH0 NG'],
  "chargit": ['CH AA1 R JH IH0 T'],
  "chargois": ['SH AA0 R G W AA1'],
  "chariot": ['CH EH1 R IY0 AH0 T'],
  "chariots": ['CH EH1 R IY0 AH0 T S'],
  "charisma": ['K ER0 IH1 Z M AH0'],
  "charismatic": ['K EH0 R IH0 Z M AE1 T IH0 K'],
  "charismatics": ['K EH2 R IH0 Z M AE1 T IH0 K S'],
  "charissa": ['K AA0 R IY1 S AH0'],
  "charita": ['K AA0 R IY1 T AH0'],
  "charitable": ['CH AE1 R AH0 T AH0 B AH0 L', 'CH EH1 R AH0 T AH0 B AH0 L'],
  "charitably": ['CH EH1 R IH0 T AH0 B L IY0'],
  "charities": ['CH EH1 R AH0 T IY0 Z', 'CH EH1 R IH0 T IY0 Z'],
  "charities'": ['CH EH1 R IH0 T IY0 Z'],
  "charity": ['CH EH1 R IH0 T IY0'],
  "charity's": ['CH EH1 R AH0 T IY0 Z'],
  "charla": ['CH AA1 R L AH0'],
  "charland": ['CH AA1 R L AH0 N D'],
  "charlatan": ['SH AA1 R L AH0 T AH0 N'],
  "charlatans": ['SH AA1 R L AH0 T AH0 N Z'],
  "charlayne": ['SH AA0 R L EY1 N'],
  "charle": ['CH AA1 R L'],
  "charlebois": ['SH AA1 R L IH0 B W AA0'],
  "charleen": ['CH AA0 R L IY1 N'],
  "charlemagne": ['SH AA1 R L AH0 M EY2 N'],
  "charlene": ['SH AA0 R L IY1 N'],
  "charles": ['CH AA1 R L Z', 'CH AA1 R AH0 L Z'],
  "charles'": ['CH AA1 R L Z', 'CH AA1 R AH0 L Z'],
  "charles's": ['CH AA1 R L Z IH0 Z'],
  "charleston": ['CH AA1 R L S T AH0 N', 'CH AA1 R AH0 L S T AH0 N'],
  "charleston's": ['CH AA1 R L S T AH0 N Z', 'CH AA1 R AH0 L S T AH0 N Z'],
  "charlestown": ['CH AA1 R L S T AW2 N', 'CH AA1 R AH0 L S T AW2 N'],
  "charlesworth": ['CH AA1 R AH0 L S W ER0 TH', 'CH AA1 R AH0 L Z W ER0 TH'],
  "charlet": ['CH AA1 R L IH0 T'],
  "charlevoix": ['SH AA1 R L AH0 V W AH0'],
  "charley": ['CH AA1 R L IY0'],
  "charley's": ['CH AA1 R L IY0 Z'],
  "charlie": ['CH AA1 R L IY0'],
  "charlie's": ['CH AA1 R L IY0 Z'],
  "charlier": ['CH AA1 R L IY0 ER0'],
  "charline": ['SH AA0 R L IY1 N'],
  "charlize": ['SH AA2 R L IY1 Z'],
  "charlot": ['CH AA1 R L AH0 T'],
  "charlotte": ['SH AA1 R L AH0 T'],
  "charlotte's": ['SH AA1 R L AH0 T S'],
  "charlottesville": ['SH AA1 R L AH0 T S V IH2 L'],
  "charlottetown": ['SH AA1 R L AH0 T T AW2 N'],
  "charlotteville": ['SH AA1 R L AH0 T V IH2 L'],
  "charls": ['CH AA1 R L Z'],
  "charlson": ['CH AA1 R L S AH0 N'],
  "charlton": ['CH AA1 R L T AH0 N'],
  "charm": ['CH AA1 R M'],
  "charmain": ['SH AA0 R M EY1 N'],
  "charmaine": ['SH AA0 R M EY1 N'],
  "charmed": ['CH AA1 R M D'],
  "charmer": ['CH AA1 R M ER0'],
  "charmers": ['CH AA1 R M ER0 Z'],
  "charmian": ['CH AA1 R M IY0 AH0 N'],
  "charming": ['CH AA1 R M IH0 NG'],
  "charmingly": ['CH AA1 R M IH0 NG L IY0'],
  "charmion": ['CH AA1 R M IY0 AH0 N'],
  "charms": ['CH AA1 R M Z'],
  "charney": ['CH AA1 R N IY0'],
  "charnley": ['CH AA1 R N L IY0'],
  "charnock": ['CH AA1 R N AH0 K'],
  "charon": ['K EH1 R AH0 N'],
  "charpentier": ['SH AA2 R P AH0 N T IH1 R'],
  "charpie": ['CH AA1 R P IY0'],
  "charred": ['CH AA1 R D'],
  "charren": ['CH EH1 R AH0 N'],
  "charrette": ['SH AH0 R EH1 T'],
  "charrier": ['CH AE1 R IY0 ER0'],
  "charring": ['CH AA1 R IH0 NG'],
  "charron": ['CH EH1 R AH0 N', 'K EH1 R AH0 N'],
  "charry": ['CH AE1 R IY0'],
  "chart": ['CH AA1 R T'],
  "charted": ['CH AA1 R T AH0 D', 'CH AA1 R T IH0 D'],
  "charter": ['CH AA1 R T ER0'],
  "charter's": ['CH AA1 R T ER0 Z'],
  "chartered": ['CH AA1 R T ER0 D'],
  "charterhouse": ['CH AA1 R T ER0 HH AW2 S'],
  "chartering": ['CH AA1 R T ER0 IH0 NG'],
  "charters": ['CH AA1 R T ER0 Z'],
  "chartier": ['CH AA1 R T IY0 ER0'],
  "charting": ['CH AA1 R T IH0 NG'],
  "chartist": ['CH AA1 R T IH0 S T'],
  "chartists": ['CH AA1 R T IH0 S T S'],
  "chartrand": ['CH AA1 R T R AH0 N D'],
  "chartres": ['CH AA1 R T R IY0 Z'],
  "chartreuse": ['SH AA0 R T R UW1 Z', 'SH AA0 R T R UW1 S'],
  "charts": ['CH AA1 R T S'],
  "chartwell": ['CH AA1 R T W EH2 L'],
  "charvat": ['CH AA1 R V AH0 T'],
  "charwoman": ['CH AA1 R W UH0 M AH0 N'],
  "charwomen": ['CH AA1 R W IH0 M AH0 N'],
  "chary": ['CH AA1 R IY0'],
  "charyl": ['CH AE1 R AH0 L'],
  "chas": ['CH AA1 Z'],
  "chase": ['CH EY1 S'],
  "chase's": ['CH EY1 S IH0 Z'],
  "chased": ['CH EY1 S T'],
  "chasen": ['CH EY1 S AH0 N'],
  "chaser": ['CH EY1 S ER0'],
  "chasers": ['CH EY1 S ER0 Z'],
  "chases": ['CH EY1 S AH0 Z', 'CH EY1 S IH0 Z'],
  "chasin": ['CH AE1 S IH0 N'],
  "chasing": ['CH EY1 S IH0 NG'],
  "chaska": ['CH AE1 S K AH0'],
  "chasm": ['K AE1 Z AH0 M'],
  "chason": ['CH AE1 S AH0 N'],
  "chasse": ['CH AE1 S'],
  "chassis": ['CH AE1 S IY0'],
  "chastain": ['SH AH0 S T EY1 N'],
  "chaste": ['CH EY1 S T'],
  "chasteen": ['CH AH0 S T IY1 N'],
  "chasten": ['CH EY1 S AH0 N'],
  "chastened": ['CH EY1 S AH0 N D'],
  "chastise": ['CH AE0 S T AY1 Z'],
  "chastised": ['CH AE0 S T AY1 Z D'],
  "chastises": ['CH AE0 S T AY1 Z IH0 Z'],
  "chastising": ['CH AE0 S T AY1 Z IH0 NG'],
  "chastity": ['CH AE1 S T AH0 T IY0'],
  "chasuble": ['CH AA1 S UW2 B AH0 L'],
  "chat": ['CH AE1 T'],
  "chataqua": ['SH AH0 T AA1 K W AH0'],
  "chateau": ['SH AE0 T OW1'],
  "chateaux": ['SH AH0 T OW1'],
  "chatfield": ['CH AE1 T F IY2 L D'],
  "chatham": ['CH AE1 T AH0 M'],
  "chatichai": ['CH AE1 T IH0 CH AY2'],
  "chatichai's": ['CH AE1 T IH2 K AY0 Z', 'CH AE1 T IH0 CH AY2 Z'],
  "chatihachi": ['CH AE2 T IH0 HH AA1 CH IY0'],
  "chatihachi's": ['CH AE2 T IH0 HH AA1 CH IY0 Z'],
  "chatman": ['CH AE1 T M AH0 N'],
  "chatmon": ['CH AE1 T M AH0 N'],
  "chato": ['CH EY1 T OW0'],
  "chatroom": ['CH AE1 T R UW2 M'],
  "chats": ['CH AE1 T S'],
  "chatswood": ['CH AE1 T S W UH2 D'],
  "chatsworth": ['CH AE1 T S W ER2 TH'],
  "chattahoochee": ['CH AE2 T AH0 HH UW1 CH IY0'],
  "chattahoochee's": ['CH AE2 T AH0 HH UW1 CH IY0 Z'],
  "chattanooga": ['CH AE2 T AH0 N UW1 G AH0'],
  "chattanooga's": ['CH AE2 T AH0 N UW1 G AH0 Z'],
  "chattanuga": ['CH AE2 T AH0 N UW1 G AH0'],
  "chatted": ['CH AE1 T AH0 D', 'CH AE1 T IH0 D'],
  "chattel": ['CH AE1 T AH0 L'],
  "chatter": ['CH AE1 T ER0'],
  "chattering": ['CH AE1 T ER0 IH0 NG'],
  "chatterjee": ['CH AH0 T ER1 JH IY0'],
  "chatterton": ['CH AE1 T ER0 T AH0 N'],
  "chatterton's": ['CH AE1 T ER0 T AH0 N Z'],
  "chattin": ['CH AE1 T IH0 N'],
  "chatting": ['CH AE1 T IH0 NG'],
  "chatty": ['CH AE1 T IY0'],
  "chatwal": ['CH AE1 T W AO2 L'],
  "chatwin": ['CH AE1 T W IH0 N'],
  "chatz": ['CH AE1 T S'],
  "chatz'": ['CH AE1 T S'],
  "chatz's": ['CH AE1 T S IH0 Z'],
  "chau": ['SH OW1'],
  "chaucer": ['CH AO1 S ER0'],
  "chaucer's": ['CH AO1 S ER0 Z'],
  "chaudhry": ['CH AO1 D R IY0'],
  "chaudoin": ['SH OW0 D OY1 N'],
  "chauffeur": ['SH OW0 F ER1', 'SH OW1 F ER0'],
  "chauffeur's": ['SH OW0 F ER1 Z', 'SH OW1 F ER0 Z'],
  "chauffeured": ['SH OW0 F ER1 D', 'SH OW1 F ER0 D'],
  "chauffeurs": ['SH OW0 F ER1 Z', 'SH OW1 F ER0 Z'],
  "chaumet": ['CH AO1 M IH0 T'],
  "chaunce": ['CH AO1 N S'],
  "chauncey": ['CH AO1 N S IY0'],
  "chauncy": ['CH AO1 N S IY0'],
  "chaus": ['CH AW1 S'],
  "chausse": ['CH AW1 S'],
  "chaussee": ['CH AW1 S IY0'],
  "chautauqua": ['SH AH0 T AO1 K W AH0'],
  "chautauquan": ['SH AH0 T AO1 K W AH0 N'],
  "chautauquans": ['SH AH0 T AO1 K W AH0 N Z'],
  "chauvin": ['SH OW0 V AE1 N'],
  "chauvinism": ['SH OW1 V AH0 N IH2 Z AH0 M'],
  "chauvinist": ['SH OW1 V AH0 N AH0 S T'],
  "chauvinistic": ['CH OW2 V AH0 N IH1 S T IH0 K'],
  "chauvinists": ['SH OW1 V AH0 N AH0 S T S'],
  "chavalit": ['CH AH0 V AA1 L IH0 T'],
  "chavarria": ['CH AH0 V AE1 R IY0 AH0'],
  "chavers": ['CH EY1 V ER0 Z'],
  "chaves": ['CH EY1 V Z'],
  "chavez": ['SH AA1 V EH0 Z', 'CH AE1 V EH2 Z', 'SH AH0 V EH1 Z'],
  "chavin": ['CH EY1 V IH0 N'],
  "chavira": ['K AA0 V IH1 R AH0'],
  "chavis": ['CH AE1 V IH0 S', 'CH EY1 V IH0 S'],
  "chavis'": ['CH AE1 V IH0 S', 'CH EY1 V IH0 S'],
  "chavitz": ['CH AE1 V IH0 T S'],
  "chavous": ['SH AH0 V AO1 S'],
  "chaw": ['CH AO1'],
  "chawla": ['CH AO1 L AH0'],
  "chayon": ['CH EY1 AH0 N'],
  "chaz": ['CH AE1 Z'],
  "chazanoff": ['CH AE1 Z AH0 N AO0 F'],
  "chazen": ['CH EY1 Z AH0 N'],
  "chazov": ['CH AE1 Z AA0 V'],
  "chazz": ['CH AE1 Z', 'CH AA1 Z'],
  "che": ['CH EY1'],
  "che's": ['CH EY1 Z'],
  "chea": ['CH IY1'],
  "cheadle": ['CH IY1 D AH0 L'],
  "cheane": ['CH IY1 N'],
  "cheane's": ['CH IY1 N Z'],
  "cheaney": ['CH IY1 N IY0'],
  "cheap": ['CH IY1 P'],
  "cheapen": ['CH IY1 P AH0 N'],
  "cheapened": ['CH IY1 P AH0 N D'],
  "cheapening": ['CH IY1 P AH0 N IH0 NG'],
  "cheapens": ['CH IY1 P AH0 N Z'],
  "cheaper": ['CH IY1 P ER0'],
  "cheapest": ['CH IY1 P AH0 S T'],
  "cheaply": ['CH IY1 P L IY0'],
  "cheapness": ['CH IY1 P N AH0 S'],
  "cheapo": ['CH IY1 P OW2'],
  "cheapskate": ['CH IY1 P S K EY2 T'],
  "cheat": ['CH IY1 T'],
  "cheated": ['CH IY1 T AH0 D', 'CH IY1 T IH0 D'],
  "cheater": ['CH IY1 T ER0'],
  "cheaters": ['CH IY1 T ER0 Z'],
  "cheatham": ['CH IY1 T AH0 M'],
  "cheating": ['CH IY1 T IH0 NG'],
  "cheats": ['CH IY1 T S'],
  "cheatum": ['CH IY1 T AH0 M'],
  "cheatwood": ['CH IY1 T W UH2 D'],
  "chebrikov": ['CH EH1 B R IH0 K AA2 V'],
  "chebyshev": ['CH EH2 B IH0 SH EH1 V'],
  "checchi": ['CH EH1 K IY0'],
  "chechen": ['CH EH1 CH IH0 N'],
  "chechen's": ['CH EH1 CH IH0 N Z'],
  "chechens": ['CH EH1 CH IH0 N Z'],
  "chechnya": ['CH EH1 CH N IY0 AH0'],
  "chechnya's": ['CH EH1 CH N IY0 AH0 Z'],
  "chechnyan": ['CH EH1 CH N IY0 AH0 N'],
  "chechnyan's": ['CH EH1 CH N IY0 AH0 N Z'],
  "chechnyans": ['CH EH1 CH N IY0 AH0 N Z'],
  "check": ['CH EH1 K'],
  "check-up": ['CH EH1 K AH2 P'],
  "checkbook": ['CH EH1 K B UH2 K'],
  "checkbooks": ['CH EH1 K B UH2 K S'],
  "checked": ['CH EH1 K T'],
  "checker": ['CH EH1 K ER0'],
  "checkerboard": ['CH EH1 K ER0 B AO2 R D'],
  "checkerboarding": ['CH EH1 K ER0 B AO2 R D IH0 NG'],
  "checkerboards": ['CH EH1 K ER0 B AO2 R D Z'],
  "checkered": ['CH EH1 K ER0 D'],
  "checkers": ['CH EH1 K ER0 Z'],
  "checkerspot": ['CH EH1 K ER0 S P AA2 T'],
  "checketts": ['CH EH1 K IH0 T S'],
  "checking": ['CH EH1 K IH0 NG'],
  "checklist": ['CH EH1 K L IH2 S T'],
  "checklists": ['CH EH1 K L IH2 S T S'],
  "checkmate": ['CH EH1 K M EY2 T'],
  "checkmates": ['CH EH1 K M EY2 T S'],
  "checkoff": ['CH EH1 K AO2 F'],
  "checkout": ['CH EH1 K AW2 T'],
  "checkouts": ['CH EH1 K AW2 T S'],
  "checkpoint": ['CH EH1 K P OY2 N T'],
  "checkpoints": ['CH EH1 K P OY2 N T S'],
  "checks": ['CH EH1 K S'],
  "checkup": ['CH EH1 K AH2 P'],
  "checkups": ['CH EH1 K AH2 P S'],
  "cheddar": ['CH EH1 D ER0'],
  "cheddars": ['CH EH1 D ER0 Z'],
  "chedester": ['CH EH1 D IH0 S T ER0'],
  "chee": ['CH IY1'],
  "cheech": ['CH IY1 CH'],
  "cheek": ['CH IY1 K'],
  "cheekbone": ['CH IY1 K B OW2 N'],
  "cheekbones": ['CH IY1 K B OW2 N Z'],
  "cheeked": ['CH IY1 K T'],
  "cheeks": ['CH IY1 K S'],
  "cheektowaga": ['CH IY2 K T AH0 W AA1 G AH0'],
  "cheeky": ['CH IY1 K IY0'],
  "cheely": ['CH IY1 L IY0'],
  "cheer": ['CH IH1 R'],
  "cheered": ['CH IH1 R D'],
  "cheerful": ['CH IH1 R F AH0 L'],
  "cheerfully": ['CH IH1 R F AH0 L IY0', 'CH IH1 R F L IY0'],
  "cheerfulness": ['CH IH1 R F AH0 L N AH0 S'],
  "cheerier": ['CH IH1 R IY0 ER0'],
  "cheeriest": ['CH IH1 R IY0 AH0 S T'],
  "cheering": ['CH IH1 R IH0 NG'],
  "cheerio": ['CH IH1 R IY0 OW0'],
  "cheerios": ['CH IH1 R IY0 OW0 S'],
  "cheerleader": ['CH IH1 R L IY2 D ER0'],
  "cheerleaders": ['CH IH1 R L IY2 D ER0 Z'],
  "cheerleading": ['CH IH1 R L IY2 D IH0 NG'],
  "cheers": ['CH IH1 R Z'],
  "cheery": ['CH IH1 R IY0'],
  "cheese": ['CH IY1 Z'],
  "cheeseburger": ['CH IY1 Z B ER0 G ER0'],
  "cheeseburgers": ['CH IY1 Z B ER0 G ER0 Z'],
  "cheesecake": ['CH IY1 Z K EY2 K'],
  "cheeseman": ['CH IY1 Z M AH0 N'],
  "cheeser": ['CH IY1 Z ER0'],
  "cheeses": ['CH IY1 Z IH0 Z'],
  "cheesier": ['CH IY1 Z IY0 ER0'],
  "cheesiest": ['CH IY1 Z IY0 IH0 S T'],
  "cheesman": ['CH IY1 Z M AH0 N'],
  "cheesy": ['CH IY1 Z IY0'],
  "cheetah": ['CH IY1 T AH0'],
  "cheetahs": ['CH IY1 T AH0 Z'],
  "cheetal": ['CH IY1 T AH0 L'],
  "cheetan": ['CH IY1 T AH0 N'],
  "cheetham": ['CH IY1 TH AH0 M'],
  "cheever": ['CH IY1 V ER0'],
  "chef": ['SH EH1 F'],
  "chef's": ['SH EH1 F S'],
  "chefitz": ['CH EH1 F IH0 T S'],
  "chefs": ['SH EH1 F S'],
  "cheil": ['CH AY1 L'],
  "cheimi": ['CH EY1 M IY0'],
  "chek": ['CH EH1 K'],
  "chekhov": ['CH EH1 K AA0 V'],
  "chekhov's": ['CH EH1 K AA0 V Z'],
  "chelan": ['CH EH1 L AH0 N'],
  "chelette": ['SH IH0 L EH1 T'],
  "chelf": ['CH EH1 L F'],
  "chell": ['CH EH1 L'],
  "chellis": ['CH EH1 L IH0 S'],
  "chelmsford": ['CH EH1 L M S F ER0 D'],
  "chelsea": ['CH EH1 L S IY0'],
  "chelsea's": ['CH EH1 L S IY0 Z'],
  "chelyabinsk": ['CH EH0 L Y AH0 B IH1 N S K'],
  "chem": ['K EH1 M'],
  "chema": ['CH EH1 M AA0'],
  "chemania": ['CH AH0 M EY1 N Y AH0'],
  "chemclear": ['CH EH1 M K L IH2 R'],
  "chemdesign": ['CH EH1 M D AH0 Z AY2 N'],
  "chemed": ['K EH1 M EH0 D', 'K EH1 M D'],
  "chemel": ['K EH1 M EH0 L'],
  "chemerinsky": ['CH EH2 M ER0 IH1 N S K IY0'],
  "chemetron": ['CH EH1 M AH0 T R AA0 N'],
  "chemfix": ['CH EH1 M F IH0 K S'],
  "chemi": ['K EH1 M IY0'],
  "chemical": ['K EH1 M AH0 K AH0 L', 'K EH1 M IH0 K AH0 L'],
  "chemical's": ['K EH1 M IH0 K AH0 L Z'],
  "chemically": ['K EH1 M AH0 K L IY0'],
  "chemicals": ['K EH1 M IH0 K AH0 L Z'],
  "chemicals'": ['CH EH1 M AH0 K AH0 L Z'],
  "chemie": ['CH EH1 M IY0'],
  "chemins": ['CH EH1 M IH0 N Z', 'SH AH0 M IH1 N Z'],
  "chemise": ['SH AH0 M IY1 Z'],
  "chemist": ['K EH1 M IH0 S T'],
  "chemistry": ['K EH1 M AH0 S T R IY0', 'K EH1 M IH0 S T R IY0'],
  "chemists": ['K EH1 M AH0 S T S', 'K EH1 M IH0 S T S'],
  "chemlawn": ['K EH1 M L AO2 N'],
  "chemlawn's": ['K EH1 M L AO2 N Z'],
  "chemo": ['K IY1 M OW0'],
  "chemotherapy": ['K IY2 M OW0 TH EH1 R AH0 P IY0'],
  "chemyr": ['K EH1 M IH0 R'],
  "chen": ['CH EH1 N'],
  "chen's": ['CH EH1 N Z'],
  "chenard": ['CH EH1 N ER0 D'],
  "chenault": ['SH IH0 N OW1'],
  "chenette": ['SH IH0 N EH1 T'],
  "chenevert": ['CH EH1 N IH0 V ER0 T'],
  "cheney": ['CH EY1 N IY0'],
  "cheney's": ['CH EY1 N IY0 Z'],
  "cheng": ['CH EH1 NG'],
  "cheng-chung": ['CH EH1 NG CH UH1 NG'],
  "cheng-hua": ['CH EH1 NG HH W AA1'],
  "chengdu": ['CH EH2 NG D UW1'],
  "chengxiang": ['CH EH1 NG SH Y AA1 NG'],
  "chenier": ['CH EH1 N Y ER0'],
  "chennai": ['CH EH1 N AY2'],
  "chennault": ['CH EH1 N AO0 L T'],
  "chenoweth": ['CH EH1 N AH0 W EH0 TH'],
  "chenowitz": ['CH EH1 N AH0 W IH0 T S'],
  "chenxiang": ['CH EH1 N SH Y AA1 NG'],
  "cheong": ['CH IY1 AO0 NG'],
  "cheque": ['CH EH1 K'],
  "cheques": ['CH EH1 K S'],
  "cher": ['SH EH1 R'],
  "cheramie": ['CH EH1 R AH0 M IY2'],
  "cherbourg": ['SH EH1 R B UW0 R G'],
  "cheri": ['SH EH1 R IY0'],
  "cherico": ['K ER0 IY1 K OW0', 'CH ER0 IY1 K OW0'],
  "cherie": ['SH EH1 R IY0'],
  "cherish": ['CH EH1 R IH0 SH'],
  "cherished": ['CH EH1 R IH0 SH T'],
  "cherishes": ['CH EH1 R IH0 SH IH0 Z'],
  "cherishing": ['CH EH1 R IH0 SH IH0 NG'],
  "chermak": ['CH ER1 M AH0 K'],
  "chern": ['CH ER1 N'],
  "cherne": ['CH ER1 N'],
  "chernenko": ['CH ER0 N EH1 NG K OW0'],
  "cherney": ['CH ER1 N IY0'],
  "chernick": ['CH ER1 N IH0 K'],
  "chernin": ['CH ER1 N IH0 N'],
  "chernobyl": ['CH ER0 N OW1 B AH0 L'],
  "chernobyl's": ['CH ER0 N OW1 B AH0 L Z'],
  "chernoff": ['K ER1 N AO0 F'],
  "chernomyrdin": ['CH EH2 R N OW0 M IH1 R D AH0 N'],
  "chernomyrdin's": ['CH EH2 R N OW0 M IH1 R D AH0 N Z'],
  "chernow": ['CH ER1 N OW0'],
  "cherny": ['CH ER1 N IY0'],
  "cherokee": ['CH EH1 R AH0 K IY2'],
  "cherokee's": ['CH EH1 R AH0 K IY2 Z'],
  "cherokees": ['CH EH1 R AH0 K IY2 Z'],
  "cherrapunji": ['CH EH2 R AH0 P AH1 N JH IY0'],
  "cherrier": ['CH EH1 R IY0 ER0'],
  "cherries": ['CH EH1 R IY0 Z'],
  "cherrington": ['CH EH1 R IH0 NG T AH0 N'],
  "cherry": ['CH EH1 R IY0'],
  "cherry's": ['CH EH1 R IY0 Z'],
  "cherrystone": ['CH EH1 R IY0 S T OW2 N'],
  "cherrystones": ['CH EH1 R IY0 S T OW2 N Z'],
  "chert": ['CH ER1 T'],
  "chertkow": ['CH ER1 T K AW0'],
  "chertoff": ['CH ER1 T AA0 F'],
  "cherts": ['CH ER1 T S'],
  "cherub": ['CH EH1 R AH0 B'],
  "cherubic": ['CH ER0 UW1 B IH0 K'],
  "cherubini": ['K ER0 UW0 B IY1 N IY0'],
  "cherubs": ['CH EH1 R AH0 B Z'],
  "cherumirdan": ['CH EH2 R AH0 M IH1 R D AH0 N'],
  "cherumirdan's": ['CH EH2 R AH0 M IH1 R D AH0 N Z'],
  "chervenak": ['CH ER1 V IH0 N AE0 K'],
  "chery": ['CH EH1 R IY0'],
  "cheryl": ['SH EH1 R AH0 L'],
  "ches": ['CH EH1 S'],
  "chesapeake": ['CH EH1 S AH0 P IY2 K'],
  "chesbro": ['K EH1 S B R OW0'],
  "chesebro": ['K EH0 S EH1 B R OW0'],
  "chesebrough": ['CH EH1 S AH0 B R UW0'],
  "chesher": ['CH EH1 SH ER0'],
  "cheshier": ['CH EH1 SH IY0 ER0'],
  "cheshire": ['CH EH1 SH ER0'],
  "chesler": ['CH EH1 S AH0 L ER0', 'CH EH1 S L ER0'],
  "chesley": ['CH EH1 S L IY0'],
  "cheslock": ['CH EH1 S L AH0 K'],
  "chesney": ['CH EH1 S N IY0'],
  "chesnut": ['CH EH1 S N AH2 T'],
  "chesnutt": ['SH IH0 S N AH1 T', 'CH EH0 S N AH1 T'],
  "chess": ['CH EH1 S'],
  "chessboard": ['CH EH1 S B AO2 R D'],
  "chessboards": ['CH EH1 S B AO2 R D Z'],
  "chesser": ['CH EH1 S ER0'],
  "chessher": ['CH EH1 SH ER0'],
  "chesshir": ['SH IH0 SH ER1'],
  "chessman": ['CH EH1 S M AH0 N'],
  "chesson": ['CH EH1 S AH0 N'],
  "chest": ['CH EH1 S T'],
  "chestang": ['CH EH1 S T AH0 NG'],
  "chested": ['CH EH1 S T AH0 D', 'CH EH1 S T IH0 D'],
  "chester": ['CH EH1 S T ER0'],
  "chesterfield": ['CH EH1 S T ER0 F IY2 L D'],
  "chesterman": ['CH EH1 S T ER0 M AH0 N'],
  "chesters": ['CH EH1 S T ER0 Z'],
  "chesterson": ['CH EH1 S T ER0 S AH0 N'],
  "chesterton": ['CH EH1 S T ER0 T AH0 N'],
  "chestman": ['CH EH1 S T M AH0 N'],
  "chestnut": ['CH EH1 S N AH2 T', 'CH EH1 S T N AH2 T'],
  "chestnuts": ['CH EH1 S N AH0 T S'],
  "chestnutt": ['CH EH1 S T N AH0 T'],
  "cheston": ['CH EH1 S T AH0 N'],
  "chests": ['CH EH1 S T S'],
  "chet": ['CH EH1 T'],
  "chetniks": ['CH EH1 T N IH0 K S'],
  "chetwin": ['CH EH1 T W IH0 N'],
  "cheung": ['CH Y UW1 NG'],
  "cheuse": ['CH UW1 S'],
  "cheuvront": ['SH UW0 V R AA1 N T'],
  "chevalier": ['SH EH2 V AH0 L IH1 R'],
  "cheverly": ['CH EH1 V ER0 L IY0'],
  "cheves": ['CH IY1 V Z'],
  "chevette": ['SH AH0 V EH1 T'],
  "chevies": ['CH EH1 V IY0 Z'],
  "chevik": ['CH EH1 V IH0 K'],
  "cheviot": ['SH EH1 V IY0 AH0 T'],
  "cheviots": ['CH IY1 V IY0 AH0 T S'],
  "chevis": ['CH EH1 V IH0 S'],
  "chevrette": ['SH IH0 V R EH1 T'],
  "chevrier": ['CH EH1 V ER0 IY0 ER0'],
  "chevrolet": ['SH EH2 V R AH0 L EY1', 'SH EH2 V R OW0 L EY1'],
  "chevrolet's": ['SH EH2 V R AH0 L EY1 Z', 'SH EH2 V R OW0 L EY1 Z'],
  "chevrolets": ['SH EH2 V R AH0 L EY1 Z', 'SH EH2 V R OW0 L EY1 Z'],
  "chevron": ['SH EH1 V R AH0 N', 'SH EH1 V R AA0 N'],
  "chevron's": ['SH EH1 V R AH0 N Z', 'SH EH1 V R AA0 N Z'],
  "chevy": ['SH EH1 V IY2', 'CH EH1 V IY2'],
  "chevy's": ['SH EH1 V IY2 Z', 'CH EH1 V IY2 Z'],
  "chevys": ['SH EH1 V IY2 S', 'CH EH1 V IY2 S'],
  "chew": ['CH UW1'],
  "chewed": ['CH UW1 D'],
  "chewer": ['CH UW1 ER0'],
  "chewers": ['CH UW1 ER0 Z'],
  "chewing": ['CH UW1 IH0 NG'],
  "chewning": ['CH UW1 N IH0 NG'],
  "chews": ['CH UW1 Z'],
  "chewy": ['CH UW1 IY0'],
  "cheyenne": ['SH AY0 EH1 N'],
  "cheyenne's": ['SH AY0 EH1 N Z'],
  "cheyennes": ['SH AY0 EH1 N Z'],
  "cheyne": ['CH EY1 N'],
  "cheyney": ['CH EY1 N IY2'],
  "chez": ['SH EY1', 'CH EH1 Z'],
  "chi": ['K AY1'],
  "chi's": ['K AY1 Z'],
  "chia": ['CH IY1 AH0'],
  "chianese": ['K IY0 AA0 N EY1 Z IY0'],
  "chiang": ['CH AE1 NG'],
  "chiang's": ['CH AE1 NG Z'],
  "chianti": ['CH IY0 AE1 N T IY0'],
  "chiaoscurist": ['K IY1 AA0 AO2 S K UW2 R IH0 S T'],
  "chiaoscuro": ['K IY1 AA0 AO2 S K UW2 R OW0'],
  "chiapas": ['CH IY0 AA1 P AH0 S'],
  "chiapas'": ['CH IY0 AA1 P AH0 Z'],
  "chiapparone": ['CH IY0 AE1 P ER0 OW2 N'],
  "chiappetta": ['K IY0 AA0 P EH1 T AH0'],
  "chiappone": ['K IY0 AA0 P OW1 N IY0'],
  "chiara": ['K Y AA1 R AH0'],
  "chiaramonte": ['K IY0 AA0 R AA0 M OW1 N T IY0'],
  "chiarella": ['K IY0 AA0 R EH1 L AH0'],
  "chiarelli": ['K IY0 AA0 R EH1 L IY0'],
  "chiarello": ['K IY0 AA0 R EH1 L OW0'],
  "chiarenza": ['K IY0 AA0 R EH1 N Z AH0'],
  "chiarnim": ['K IY0 AA1 R N IH2 M'],
  "chiaro": ['K IY0 AA1 R OW0'],
  "chiasso": ['CH IY0 AA1 S OW0'],
  "chiasson": ['CH IY0 AA1 S AH0 N'],
  "chiat": ['CH IY1 AE0 T'],
  "chiavetta": ['K IY0 AA0 V EH1 T AH0'],
  "chiba": ['CH IY1 B AH0'],
  "chic": ['SH IY1 K'],
  "chicago": ['SH AH0 K AA1 G OW2'],
  "chicago's": ['SH AH0 K AA1 G OW2 Z'],
  "chicagoan": ['CH IH1 K AH0 G OW2 N'],
  "chicagoans": ['SH AH0 K AA1 G OW2 AH0 N Z'],
  "chicanery": ['SH IH0 K EY1 N ER0 IY0'],
  "chicano": ['CH IH0 K AA1 N OW0'],
  "chicanos": ['CH IH0 K AA1 N OW0 Z'],
  "chichauha": ['CH IY2 CH AW1 AH0'],
  "chichauha's": ['CH IY2 CH AW1 AH0 Z'],
  "chichester": ['CH IH1 CH EH0 S T ER0'],
  "chichi": ['CH IY1 CH IY0'],
  "chick": ['CH IH1 K'],
  "chick's": ['CH IH1 K S'],
  "chickasaw": ['CH IH1 K AH0 S AO2'],
  "chickasaws": ['CH IH1 K AH0 S AO2 Z'],
  "chicked": ['CH IH0 K T'],
  "chicken": ['CH IH1 K AH0 N'],
  "chicken's": ['CH IH1 K AH0 N Z'],
  "chickened": ['CH IH1 K AH0 N D'],
  "chickening": ['CH IH1 K AH0 N IH0 NG'],
  "chickens": ['CH IH1 K AH0 N Z'],
  "chickens'": ['CH IH1 K AH0 N Z'],
  "chickering": ['CH IH1 K ER0 IH0 NG'],
  "chicks": ['CH IH1 K S'],
  "chico": ['CH IY1 K OW2'],
  "chico's": ['CH IY1 K OW2 Z'],
  "chicoine": ['CH IH0 K OY1 N'],
  "chicots": ['CH IH1 K AH0 T S'],
  "chide": ['CH AY1 D'],
  "chided": ['CH AY1 D IH0 D'],
  "chides": ['CH AY1 D Z'],
  "chidester": ['CH IH1 D IH0 S T ER0'],
  "chideya": ['CH IH0 D IY1 Y AH0', 'CH IH0 D EY1 Y AH0'],
  "chiding": ['CH AY1 D IH0 NG'],
  "chidsey": ['CH IH1 D Z IY0'],
  "chief": ['CH IY1 F'],
  "chief's": ['CH IY1 F S'],
  "chiefdom": ['CH IY1 F D AH0 M'],
  "chieffo": ['K IY1 F OW0'],
  "chiefly": ['CH IY1 F L IY0'],
  "chiefs": ['CH IY1 F S'],
  "chiefs'": ['CH IY1 F S'],
  "chieftain": ['CH IY1 F T AH0 N'],
  "chieftain's": ['CH IY1 F T AH0 N Z'],
  "chieftains": ['CH IY1 F T AH0 N Z'],
  "chien": ['CH EH1 N'],
  "chiengmai": ['CH EH1 NG M AY1'],
  "chiesa": ['K IY1 S AH0'],
  "chiffon": ['SH IH0 F AA1 N'],
  "chiffre": ['SH IY1 F R AH0'],
  "chiggers": ['CH IH1 G ER0 Z'],
  "chigney": ['CH IH1 G N IY0'],
  "chihuahua": ['CH AH0 W AA1 W AA2', 'CH IY2 W AA1 W AH0'],
  "chikane": ['CH IH0 K AA1 N EY0'],
  "chikatilo": ['CH IH2 K AH0 T IH1 L OW2'],
  "chikos": ['CH IY1 K OW2 S'],
  "chilblain": ['CH IH1 L B L EY2 N'],
  "chilblains": ['CH IH1 L B L EY2 N Z'],
  "chilcoat": ['CH IH1 L K OW2 T'],
  "chilcote": ['CH IH1 L K OW2 T'],
  "chilcott": ['CH IH1 L K AH0 T'],
  "chilcutt": ['CH IH1 L K AH0 T'],
  "child": ['CH AY1 L D'],
  "child's": ['CH AY1 L D Z'],
  "childbearing": ['CH AY1 L D B EH2 R IH0 NG'],
  "childbirth": ['CH AY1 L D B ER2 TH'],
  "childcare": ['CH AY1 L D K EH2 R'],
  "childcraft": ['CH AY1 L D K R AE2 F T'],
  "childe": ['CH IH1 L D'],
  "childener": ['CH IH1 L D N ER0', 'CH IH1 L D IH0 N ER0'],
  "childener's": ['CH IH1 L D N ER0 Z', 'CH IH1 L D IH0 N ER0 Z'],
  "childers": ['CH IH1 L D ER0 Z'],
  "childhood": ['CH AY1 L D HH UH2 D'],
  "childhoods": ['CH AY1 L D HH UH2 D Z'],
  "childish": ['CH AY1 L D IH0 SH'],
  "childless": ['CH AY1 L D L AH0 S'],
  "childlike": ['CH AY1 L D L AY2 K'],
  "childminder": ['CH AY1 L D M AY2 N D ER0'],
  "childraising": ['CH AY1 L D R EY2 Z IH0 NG'],
  "childree": ['CH AY0 L D R IY1'],
  "children": ['CH IH1 L D R AH0 N'],
  "children's": ['CH IH1 L D R AH0 N Z'],
  "childrens": ['CH IH1 L D R AH0 N Z'],
  "childrens'": ['CH IH1 L D R AH0 N Z'],
  "childres": ['CH AY1 L D ER0 Z'],
  "childress": ['CH IH1 L D R IH0 S'],
  "childrey": ['CH IH1 L D R IY0'],
  "childs": ['CH AY1 L D Z'],
  "chile": ['CH IH1 L IY0'],
  "chile's": ['CH IH1 L IY0 Z'],
  "chilean": ['CH IH1 L IY0 AH0 N'],
  "chileans": ['CH IH1 L IY0 AH0 N Z'],
  "chiles": ['CH AY1 L Z', 'CH IH1 L IY0 Z'],
  "chiles's": ['CH IH1 L IY0 Z IH0 Z'],
  "chili": ['CH IH1 L IY0'],
  "chili's": ['CH IH1 L IY0 Z'],
  "chilies": ['CH IH1 L IY0 Z'],
  "chilis": ['CH IH1 L IY0 Z'],
  "chill": ['CH IH1 L'],
  "chilled": ['CH IH1 L D'],
  "chillemi": ['K IY0 L EH1 M IY0'],
  "chiller": ['CH IH1 L ER0'],
  "chillicothe": ['CH IH1 L IH0 K AO0 TH'],
  "chillier": ['CH IH1 L IY0 ER0'],
  "chillies": ['CH IH1 L IY0 Z'],
  "chilliest": ['CH IH1 L IY0 AH0 S T'],
  "chilling": ['CH IH1 L IH0 NG'],
  "chillingly": ['CH IH1 L IH0 NG L IY0'],
  "chills": ['CH IH1 L Z'],
  "chilly": ['CH IH1 L IY0'],
  "chilmark": ['CH IH1 L M AA2 R K'],
  "chilson": ['CH IH1 L S AH0 N'],
  "chilton": ['CH IH1 L T AH0 N'],
  "chimayo": ['CH IH0 M AY1 OW0'],
  "chime": ['CH AY1 M'],
  "chimed": ['CH AY1 M D'],
  "chimenti": ['CH IH0 M EH1 N T IY0'],
  "chimento": ['CH IH0 M EH1 N T OW0'],
  "chimera": ['CH IH0 M EH1 R AH0'],
  "chimerical": ['K IH0 M EH1 R AH0 K AH0 L'],
  "chimerine": ['CH IH1 M ER0 IY2 N'],
  "chimes": ['CH AY1 M Z'],
  "chimicles": ['CH IH1 M IH0 K AH0 L Z'],
  "chimie": ['CH IH1 M IY0'],
  "chimney": ['CH IH1 M N IY0'],
  "chimneys": ['CH IH1 M N IY0 Z'],
  "chimp": ['CH IH1 M P'],
  "chimpanzee": ['CH IH0 M P AE1 N Z IY0'],
  "chimpanzees": ['CH IH0 M P AE1 N Z IY0 Z'],
  "chimps": ['CH IH1 M P S'],
  "chimurenga": ['CH IH2 M ER0 EH1 N G AH0'],
  "chin": ['CH IH1 N'],
  "china": ['CH AY1 N AH0'],
  "china's": ['CH AY1 N AH0 Z'],
  "chinatown": ['CH AY1 N AH0 T AW2 N'],
  "chinchilla": ['CH IH0 N CH IH1 L AH0'],
  "chinen": ['CH IH1 N AH0 N'],
  "chinese": ['CH AY0 N IY1 Z'],
  "chinese-englishman": ['CH AY0 N IY2 Z IH1 NG G L IH0 SH M AH0 N'],
  "ching": ['CH IH1 NG'],
  "chinh": ['CH IH1 N'],
  "chink": ['CH IH1 NG K'],
  "chinks": ['CH IH1 NG K S'],
  "chinn": ['CH IH1 N'],
  "chinn's": ['CH IH1 N Z'],
  "chinnici": ['K IY0 N IY1 CH IY0'],
  "chinnock": ['CH IH1 N AH0 K'],
  "chino": ['CH IY1 N OW0'],
  "chinook": ['SH IH0 N UH1 K', 'CH IH2 N UH1 K'],
  "chinooks": ['CH IH0 N UH1 K S', 'SH IH2 N UH1 K S'],
  "chinoy": ['CH IH1 N OY2'],
  "chintung": ['CH IH1 N T AH0 NG'],
  "chintzy": ['CH IH1 N T S IY0'],
  "chiodo": ['K IY0 OW1 D OW2'],
  "chip": ['CH IH1 P'],
  "chip's": ['CH IH1 P S'],
  "chip-in": ['CH IH1 P IH2 N'],
  "chipboard": ['CH IH1 P B AO2 R D'],
  "chipcom": ['CH IH1 P K AA2 M'],
  "chipcom's": ['CH IH1 P K AA2 M Z'],
  "chipello": ['CH IH0 P EH1 L OW0'],
  "chipetas": ['CH IH0 P IY1 T AH0 Z'],
  "chipita": ['CH AH0 P IY1 T AA0'],
  "chipley": ['CH IH1 P L IY0'],
  "chipmaker": ['CH IH1 P M EY2 K ER0'],
  "chipmakers": ['CH IH1 P M EY2 K ER0 Z'],
  "chipman": ['CH IH1 P M AH0 N'],
  "chipmunk": ['CH IH1 P M AH0 NG K'],
  "chipmunks": ['CH IH1 P M AH0 NG K S'],
  "chipote": ['CH IH0 P OW1 T'],
  "chipotle": ['CH IH0 P OW1 T L IY2'],
  "chipped": ['CH IH1 P T'],
  "chippendale": ['CH IH1 P AH0 D EY2 L'],
  "chippendales": ['CH IH1 P AH0 D EY2 L'],
  "chipper": ['CH IH1 P ER0'],
  "chippewa": ['CH IH1 P AH0 W AA2'],
  "chipping": ['CH IH1 P IH0 NG'],
  "chipps": ['CH IH1 P S'],
  "chippy": ['CH IH1 P IY0'],
  "chips": ['CH IH1 P S'],
  "chipsoft": ['CH IH1 P S AO2 F T'],
  "chiquita": ['K IH0 K W IY1 T AH0', 'CH IH0 K W IY1 T AH0', 'CH IH0 K IY1 T AH0'],
  "chirac": ['SH IH0 R AE1 K'],
  "chirac's": ['SH IH0 R AE1 K S'],
  "chirco": ['K IH1 R K OW0'],
  "chirico": ['K IH0 R IY1 K OW0'],
  "chiron": ['CH AY1 R AH0 N'],
  "chiron's": ['CH AY1 R AH0 N Z'],
  "chiropractic": ['K AY2 R OW0 P R AE1 K T IH0 K'],
  "chiropractor": ['K AY1 R AH0 P R AE2 K T ER0'],
  "chiropractor's": ['K AY1 R OW0 P R AE2 K T ER0 Z'],
  "chiropractors": ['K AY1 R AH0 P R AE2 K T ER0 Z'],
  "chirp": ['CH ER1 P'],
  "chirping": ['CH ER1 P IH0 NG'],
  "chirps": ['CH ER1 P S'],
  "chirpy": ['CH ER1 P IY0'],
  "chisam": ['CH IH1 S AH0 M'],
  "chisel": ['CH IH1 Z AH0 L'],
  "chiseled": ['CH IH1 Z AH0 L D'],
  "chiseling": ['CH IH1 Z AH0 L IH0 NG', 'CH IH1 Z L IH0 NG'],
  "chisels": ['CH IH1 Z AH0 L Z'],
  "chisenhall": ['CH IH0 S EH1 N HH AH0 L'],
  "chisholm": ['CH IH1 Z AH0 M'],
  "chisler": ['CH IH1 S AH0 L ER0', 'CH IH1 S L ER0', 'CH IH1 Z L ER0'],
  "chism": ['CH IH1 Z AH0 M'],
  "chisman": ['CH IH1 S M AH0 N'],
  "chismar": ['CH IH1 Z M ER0'],
  "chisolm": ['CH IH1 S OW0 M'],
  "chisom": ['CH IH1 S AH0 M'],
  "chissano": ['CH IH0 S AA1 N OW0'],
  "chisum": ['CH IH1 Z AH0 M'],
  "chiswick": ['CH IH1 Z W IH0 K'],
  "chit": ['CH IH1 T'],
  "chita": ['CH IY1 T AH0'],
  "chitchat": ['CH IH1 T CH AE2 T'],
  "chitinous": ['K AY1 T AH0 N AH0 S'],
  "chitlin": ['CH IH1 T L IH0 N'],
  "chitlins": ['CH IH1 T L IH0 N Z'],
  "chitra": ['CH IH1 T R AH0'],
  "chitra's": ['CH IH1 T R AH0 Z'],
  "chitrao": ['CH IH1 T R AW0'],
  "chits": ['CH IH1 T S'],
  "chittenden": ['CH IH1 T AH0 N D AH0 N'],
  "chitter": ['CH IH1 T ER0'],
  "chitterchatter": ['CH IH1 T ER0 CH AE1 T ER0'],
  "chitterlings": ['CH IH1 T ER0 L IH0 NG Z'],
  "chittick": ['CH IH1 T IH0 K'],
  "chittum": ['CH IH1 T AH0 M'],
  "chitty": ['CH IH1 T IY0'],
  "chitwood": ['CH IH1 T W UH2 D'],
  "chiu": ['CH UW1'],
  "chiusano": ['K IY0 UW0 S AA1 N OW0'],
  "chivalry": ['SH IH1 V AH0 L R IY0'],
  "chivas": ['CH IY1 V AH0 S', 'SH IY1 V AH0 S'],
  "chivers": ['CH AY1 V ER0 Z'],
  "chives": ['CH AY1 V Z'],
  "chiyoda": ['CH IH0 Y OW1 D AH0'],
  "chizek": ['CH IH1 Z EH0 K'],
  "chizmar": ['CH IH1 Z M ER0'],
  "chlamydia": ['K L AE0 M AY1 D IY0 AH0', 'K L AE0 M IH1 D IY0 AH0'],
  "chlebowski": ['CH L IH0 B AO1 F S K IY0', 'K L IH0 B AO1 F S K IY0'],
  "chloe": ['K L OW1 IY0'],
  "chloe's": ['K L OW1 IY0 Z'],
  "chlorate": ['K L AO1 R EY0 T'],
  "chlordane": ['K L AO1 R D EY2 N'],
  "chloric": ['K L AO1 R IH0 K'],
  "chloride": ['K L AO1 R AY0 D'],
  "chlorinate": ['K L AO1 R AH0 N EY2 T'],
  "chlorinated": ['K L AO1 R AH0 N EY2 T AH0 D'],
  "chlorinating": ['K L AO1 R AH0 N EY2 T IH0 NG'],
  "chlorine": ['K L AO1 R IY0 N'],
  "chloris": ['K L AO1 R IH0 S'],
  "chlorofluorocarbon": ['K L AO0 R OW0 F L AO2 R OW0 K AA1 R B AA0 N'],
  "chlorofluorocarbons": ['K L AO1 R OW0 F L AO1 R OW0 K AA1 R B AA0 N Z'],
  "chloroform": ['K L AO1 R AH0 F AO2 R M'],
  "chlorophyll": ['K L AO1 R AH0 F IH0 L'],
  "chloroplast": ['K L AO1 R AH0 P L AE2 S T'],
  "chloroplasts": ['K L AO1 R AH0 P L AE2 S T S'],
  "chloroprene": ['K L AO1 R AH0 P R IY2 N'],
  "chmiel": ['CH AH0 M IY1 L'],
  "chmielewski": ['CH AH0 M AH0 L EH1 F S K IY0', 'CH AH0 M AH0 L UW1 S K IY0'],
  "chmura": ['CH AH0 M UH1 R AH0'],
  "cho": ['CH OW1'],
  "choat": ['CH OW1 T'],
  "choate": ['CH OW1 T'],
  "chock": ['CH AA1 K'],
  "chocolat": ['CH AA1 K L AH0 T'],
  "chocolate": ['CH AO1 K L AH0 T'],
  "chocolates": ['CH AO1 K L AH0 T S'],
  "chocolatology": ['CH AA2 K L AH0 T AA1 L AH0 JH IY0'],
  "chodorow": ['CH OW1 D ER0 OW0'],
  "choe": ['CH OW1'],
  "choi": ['CH OY1'],
  "choice": ['CH OY1 S'],
  "choicer": ['CH OY1 S ER0'],
  "choicers": ['CH OY1 S ER0 Z'],
  "choices": ['CH OY1 S AH0 Z', 'CH OY1 S IH0 Z'],
  "choicest": ['CH OY1 S AH0 S T'],
  "choiniere": ['SH OY1 N IY0 EH0 R'],
  "choinski": ['CH OY1 N S K IY0'],
  "choir": ['K W AY1 ER0'],
  "choirs": ['K W AY1 R Z'],
  "chojnacki": ['CH AH0 Y N AA1 T S K IY0'],
  "chojnowski": ['CH AH0 Y N AO1 F S K IY0'],
  "chok": ['CH AA1 K'],
  "choke": ['CH OW1 K'],
  "choked": ['CH OW1 K T'],
  "chokehold": ['CH OW1 K HH OW2 L D'],
  "chokes": ['CH OW1 K S'],
  "choking": ['CH OW1 K IH0 NG'],
  "cholera": ['K AA1 L ER0 AH0'],
  "choleric": ['K AA1 L ER0 IH0 K'],
  "cholesterol": ['K AH0 L EH1 S T ER0 AO2 L', 'K AH0 L EH1 S T ER0 AH0 L'],
  "cholestyramine": ['K OW0 L EH1 S T IH0 R AH0 M AY2 N'],
  "cholet": ['CH OW1 L AH0 T'],
  "cholewa": ['CH AH0 L UW1 AH0'],
  "cholla": ['CH AA1 L AH0'],
  "choma": ['CH OW1 M AH0'],
  "chombiono": ['CH AA0 M B IY0 OW1 N OW0'],
  "chomp": ['CH AA1 M P'],
  "chomping": ['CH AA1 M P IH0 NG'],
  "chomsky": ['CH AA1 M S K IY2'],
  "chon": ['CH AA1 N'],
  "chong": ['CH AO1 NG'],
  "chongqing": ['CH AO1 NG K IH1 NG'],
  "chonko": ['CH AA1 NG K OW0'],
  "chontales": ['SH AA2 N T EY1 L Z'],
  "choo": ['CH UW1'],
  "chook": ['CH UH1 K', 'CH UW1 K'],
  "choon": ['CH UW1 N'],
  "choong": ['CH UW1 NG'],
  "choose": ['CH UW1 Z'],
  "chooses": ['CH UW1 Z AH0 Z', 'CH UW1 Z IH0 Z'],
  "choosing": ['CH UW1 Z IH0 NG'],
  "choosy": ['CH UW1 Z IY0'],
  "chop": ['CH AA1 P'],
  "chop-chop": ['CH AA1 P CH AA1 P'],
  "chop-suey": ['CH AA1 P S UW1 IY0'],
  "choper": ['CH OW1 P ER0'],
  "chopin": ['SH OW1 P AE0 N'],
  "choplin": ['CH AA1 P L IH0 N'],
  "chopp": ['CH AA1 P'],
  "chopped": ['CH AA1 P T'],
  "chopper": ['CH AA1 P ER0'],
  "chopper's": ['CH AA1 P ER0 Z'],
  "choppers": ['CH AA1 P ER0 Z'],
  "chopping": ['CH AA1 P IH0 NG'],
  "choppy": ['CH AA1 P IY0'],
  "chopra": ['CH AA1 P R AH0'],
  "chops": ['CH AA1 P S'],
  "chopstick": ['CH AA1 P S T IH2 K'],
  "chopsticks": ['CH AA1 P S T IH2 K S'],
  "chopsuey": ['CH AA1 P S UW1 IY0'],
  "choquette": ['SH AH0 K EH1 T'],
  "choral": ['K AO1 R AH0 L'],
  "chorale": ['K ER0 AE1 L'],
  "chorals": ['K AO1 R AH0 L Z'],
  "chorba": ['K AO1 R B AH0'],
  "chord": ['K AO1 R D'],
  "chordates": ['K AO1 R D EY2 T S'],
  "chords": ['K AO1 R D Z'],
  "chore": ['CH AO1 R'],
  "choreograph": ['K AO1 R IY0 AH0 G R AE2 F'],
  "choreographed": ['K AO1 R IY0 AH0 G R AE2 F T'],
  "choreographer": ['K AO2 R IY0 AA1 G R AH0 F ER0'],
  "choreographer's": ['K AO2 R IY0 AA1 G R AH0 F ER0 Z'],
  "choreographers": ['K AO2 R IY0 AA1 G R AH0 F ER0 Z'],
  "choreographic": ['K AO2 R IY0 AH0 G R AE1 F IH0 K'],
  "choreographing": ['K AO2 R IY0 AA1 G R AH0 F IH0 NG', 'K AO1 R IY0 AH0 G R AE2 F IH0 NG'],
  "choreography": ['K AO2 R IY0 AA1 G R AH0 F IY0'],
  "chores": ['CH AO1 R Z'],
  "choric": ['K AO1 R IH0 K'],
  "chorney": ['CH AO1 R N IY0'],
  "chortle": ['CH AO1 R T AH0 L'],
  "chortled": ['CH AO1 R T AH0 L D'],
  "chortles": ['CH AO1 R T AH0 L Z'],
  "chortling": ['CH AO1 R T AH0 L IH0 NG', 'CH AO1 R T L IH0 NG'],
  "chorus": ['K AO1 R AH0 S'],
  "choruses": ['K AO1 R AH0 S IH0 Z'],
  "chose": ['CH OW1 Z'],
  "chosen": ['CH OW1 Z AH0 N'],
  "chosun": ['CH OW1 Z AH0 N'],
  "chotilla": ['CH AH0 T IH1 L AH0'],
  "chou": ['CH UW1'],
  "chouinard": ['SH W IY0 N AA1 R D'],
  "chovan": ['CH OW1 V AH0 N'],
  "chovanec": ['CH AH0 V AE1 N IH0 K'],
  "chow": ['CH AW1'],
  "chowder": ['CH AW1 D ER0'],
  "chowdhury": ['CH AW1 D UW0 R IY0'],
  "chowning": ['CH AW1 N IH0 NG'],
  "chows": ['CH AW1 Z'],
  "choy": ['CH OY1'],
  "choyce": ['CH OY1 S'],
  "chrest": ['K R EH1 S T'],
  "chrestman": ['K R EH1 S T M AH0 N'],
  "chretien": ['K R IH0 T Y EH1 N'],
  "chriboniko": ['CH R IY2 B OW0 N IY1 K OW0', 'CH R IH2 B AH0 N IY1 K OW0'],
  "chriboniko's": ['CH R IY2 B OW0 N IY1 K OW0 Z', 'CH R IH2 B AH0 N IY1 K OW0 Z'],
  "chriptosporidium": ['K R IH2 P T OW0 S P AO0 R IH1 D IY0 AH0 M'],
  "chris": ['K R IH1 S'],
  "chris'": ['K R IH1 S'],
  "chrisco": ['K R IY1 S K OW0'],
  "chriscoe": ['K R IH1 S K OW0'],
  "chrisman": ['K R IH1 S M AH0 N'],
  "chrismer": ['K ER1 IH0 Z AH0 M ER0', 'K R IH1 S M ER0'],
  "chrismon": ['K R IH1 Z M AH0 N'],
  "chrisp": ['K R IH1 S P'],
  "chriss": ['K R IH1 S'],
  "chrissie": ['K R IH1 S IY0'],
  "chrissy": ['K R IH1 S IY0'],
  "christ": ['K R AY1 S T'],
  "christ's": ['K R AY1 S T S'],
  "christa": ['K R IH1 S T AH0'],
  "christabelle": ['K R IH1 S T AH0 B EH2 L'],
  "christakos": ['K R IH2 S T AA1 K OW0 Z'],
  "christal": ['K R IH1 S T AH0 L'],
  "christchurch": ['K R AY1 S T CH ER0 CH'],
  "christel": ['K R IH1 S T AH0 L'],
  "christen": ['K R IH1 S AH0 N'],
  "christenberry": ['K R IH1 S AH0 N B EH2 R IY0'],
  "christenbury": ['K R IH1 S AH0 N B EH2 R IY0'],
  "christendom": ['K R IH1 S AH0 N D AH0 M'],
  "christened": ['K R IH1 S AH0 N D'],
  "christening": ['K R IH1 S AH0 N IH0 NG', 'K R IH1 S N IH0 NG'],
  "christensen": ['K R IH1 S T AH0 N S AH0 N'],
  "christenson": ['K R IH1 S T IH0 N S AH0 N'],
  "christeson": ['K R IH1 S T IH0 S AH0 N'],
  "christi": ['K R IH1 S T IY0'],
  "christiaan": ['K R IH1 S T IY0 AA2 N'],
  "christian": ['K R IH1 S CH AH0 N', 'K R IH1 S CH IH0 N'],
  "christiana": ['K R IH2 S T IY0 AE1 N AH0'],
  "christiane": ['K R IH0 S T IY0 AA1 N'],
  "christianity": ['K R IH2 S CH IY0 AE1 N IH0 T IY0'],
  "christianity's": ['K R IH2 S CH IY0 AE1 N IH0 T IY0 Z'],
  "christianization": ['K R IH2 S CH AH0 N AH0 Z EY1 SH AH0 N'],
  "christianize": ['K R IH1 S CH AH0 N AY2 Z'],
  "christianized": ['K R IH1 S CH AH0 N AY2 Z D'],
  "christianna": ['K R IH2 S T IY0 AE1 N AH0'],
  "christianne": ['K R IH0 S T IY0 AA1 N'],
  "christiano": ['K R IY0 S T IY0 AA1 N OW0'],
  "christians": ['K R IH1 S CH AH0 N Z', 'K R IH1 S CH IH0 N Z'],
  "christiansen": ['K R IH1 S CH AH0 N S AH0 N'],
  "christianson": ['K R IH1 S CH AH0 N S AH0 N'],
  "christiansted": ['K R IH1 S CH AH0 N S T EH2 D'],
  "christic": ['K R IH1 S T IH0 K'],
  "christie": ['K R IH1 S T IY0'],
  "christie's": ['K R IH1 S T IY0 Z'],
  "christies": ['K R IH1 S T IY0 Z'],
  "christina": ['K R IH0 S T IY1 N AH0'],
  "christine": ['K R IH0 S T IY1 N'],
  "christine's": ['K R IH0 S T IY1 N Z'],
  "christison": ['K R IH1 S T IH0 S AH0 N'],
  "christlieb": ['K R IH1 S T L IY2 B'],
  "christman": ['K R IH1 S T M AH0 N'],
  "christman's": ['K R IH1 S T M AH0 N Z'],
  "christmann": ['K R IH1 S T M AH0 N'],
  "christmas": ['K R IH1 S M AH0 S'],
  "christmas'": ['K R IH1 S M AH0 S'],
  "christmases": ['K R IH1 S M AH0 S IH0 Z'],
  "christmastime": ['K R IH1 S T M AH0 S T AY2 M'],
  "christner": ['K R IH1 S T N ER0'],
  "christo": ['K R IH1 S T OW0'],
  "christoff": ['K R IH1 S T AO0 F'],
  "christoffel": ['K R IH1 S T AH0 F EH0 L'],
  "christoffersen": ['K R IH0 S T AH0 F ER1 S AH0 N', 'K R IH0 S T AA1 F ER0 S AH0 N'],
  "christofferson": ['K R IH0 S T AA1 F ER0 S AH0 N'],
  "christon": ['K R IH1 S T AH0 N'],
  "christoph": ['K R IH1 S T AO0 F'],
  "christophe": ['K R IH0 S T AO1 F'],
  "christophel": ['K R IH1 S T AH0 F EH0 L'],
  "christopher": ['K R IH1 S T AH0 F ER0'],
  "christopher's": ['K R IH1 S T AH0 F ER0 Z'],
  "christophersen": ['K R IH0 S T AA1 F ER2 S AH0 N'],
  "christopherson": ['K R IH0 S T AA1 F ER2 S AH0 N'],
  "christopoulos": ['K R IH0 S T AA1 P AH0 L IH0 S'],
  "christy": ['K R IH1 S T IY0'],
  "christy's": ['K R IH1 S T IY0 Z'],
  "chriswell": ['K R IH1 S W EH2 L'],
  "chrobak": ['K R OW1 B AH0 K'],
  "chromakalim": ['K R OW2 M AH0 K AA2 L IY1 M'],
  "chromalloy": ['K R OW0 M AE1 L OY0'],
  "chromatogram": ['K R OW0 M AE1 T AH0 G R AE0 M'],
  "chromatograms": ['K R OW0 M AE1 T AH0 G R AE0 M Z'],
  "chromatography": ['K R OW0 M AH0 T AA1 G R AH0 F IY0'],
  "chrome": ['K R OW1 M'],
  "chromecast": ['K R OW1 M K AE2 S T'],
  "chrominance": ['K R OW1 M AH0 N AH0 N S'],
  "chromium": ['K R OW1 M IY0 AH0 M'],
  "chromosomal": ['K R OW1 M AH0 S OW2 M AH0 L'],
  "chromosome": ['K R OW1 M AH0 S OW2 M', 'K R OW1 M AH0 Z OW2 M'],
  "chromosomes": ['K R OW1 M AH0 Z OW2 M Z', 'K R OW1 M AH0 S OW2 M Z'],
  "chronar": ['K R AA1 N ER0'],
  "chronic": ['K R AA1 N IH0 K'],
  "chronically": ['K R AA1 N IH0 K AH0 L IY0', 'K R AA1 N IH0 K L IY0'],
  "chronicle": ['K R AA1 N IH0 K AH0 L'],
  "chronicle's": ['K R AA1 N IH0 K AH0 L Z'],
  "chronicled": ['K R AA1 N IH0 K AH0 L D'],
  "chronicler": ['K R AA1 N IH0 K L ER0'],
  "chroniclers": ['K R AA1 N IH0 K L ER0 Z'],
  "chronicles": ['K R AA1 N IH0 K AH0 L Z'],
  "chronicling": ['K R AA1 N IH0 K L IH0 NG'],
  "chronis": ['K R OW1 N IH0 S'],
  "chronister": ['K R AA1 N IH0 S T ER0'],
  "chronological": ['K R AA2 N AH0 L AA1 JH IH0 K AH0 L'],
  "chronologically": ['K R AA2 N AH0 L AA1 JH IH0 K L IY0'],
  "chronologies": ['K R AH0 N AA1 L AH0 JH IY0 Z'],
  "chronology": ['K R AH0 N AA1 L AH0 JH IY0'],
  "chronowitz": ['K R AA1 N AH0 W IH0 T S'],
  "chrostowski": ['K R AH0 S T AO1 F S K IY0'],
  "chrusciel": ['K R AH1 S IY2 L'],
  "chrysalis": ['K R IH1 S AH0 L IH0 S'],
  "chrysanthemum": ['K R IH0 S AE1 N TH AH0 M AH0 M'],
  "chrysanthemums": ['K R IH0 S AE1 N TH AH0 M AH0 M Z'],
  "chryseis": ['K R IH1 S AH0 Z'],
  "chrysler": ['K R AY1 S L ER0'],
  "chrysler's": ['K R AY1 S L ER0 Z'],
  "chryslers": ['K R AY1 S L ER0 Z'],
  "chryst": ['K R IH1 S T'],
  "chrystal": ['K R IH1 S T AH0 L'],
  "chrzan": ['CH ER1 Z AE2 N'],
  "chrzanowski": ['CH ER2 Z AH0 N AO1 F S K IY0'],
  "chseing": ['CH EY1 NG'],
  "chu": ['CH UW1'],
  "chua": ['K UW1 AH0', 'K W AA1'],
  "chuah": ['CH UW1 AA0'],
  "chuan": ['CH UW2 AA1 N'],
  "chuang": ['CH AE1 NG', 'CH W AA1 NG'],
  "chuba": ['CH UW1 B AH0'],
  "chubais": ['CH UW2 B AY1'],
  "chubb": ['CH AH1 B'],
  "chubb's": ['CH AH1 B Z'],
  "chubbuck": ['CH AH1 B AH0 K'],
  "chubby": ['CH AH1 B IY0'],
  "chubu": ['CH UW1 B UW0'],
  "chuck": ['CH AH1 K'],
  "chuck's": ['CH AH1 K S'],
  "chuck-a-luck": ['CH AH1 K AH0 L AH2 K'],
  "chucked": ['CH AH1 K T'],
  "chuckie": ['CH AH1 K IY0'],
  "chucking": ['CH AH1 K IH0 NG'],
  "chuckle": ['CH AH1 K AH0 L'],
  "chuckled": ['CH AH1 K AH0 L D'],
  "chuckles": ['CH AH1 K AH0 L Z'],
  "chuckling": ['CH AH1 K L IH0 NG'],
  "chudler": ['CH AH1 D L ER0'],
  "chudy": ['CH UW1 D IY0'],
  "chudzik": ['CH AH1 D Z IH0 K'],
  "chudzinski": ['CH AH0 JH IH1 N S K IY2'],
  "chug": ['CH AH1 G'],
  "chugai": ['CH UW0 G AY1'],
  "chugged": ['CH AH1 G D'],
  "chugging": ['CH AH1 G IH0 NG'],
  "chugoku": ['CH UW0 G OW1 K UW2'],
  "chui": ['K UW1 IH0'],
  "chujitsuya": ['CH UW2 JH IY0 T S UW2 Y AA0'],
  "chukchi": ['CH UW1 K CH IY0'],
  "chul": ['CH AH1 L'],
  "chula": ['CH UW1 L AH0'],
  "chum": ['CH AH1 M'],
  "chumbley": ['CH AH1 M B L IY0'],
  "chumley": ['CH AH1 M L IY0'],
  "chummy": ['CH AH1 M IY0'],
  "chumney": ['CH AH1 M N IY0'],
  "chump": ['CH AH1 M P'],
  "chums": ['CH AH1 M Z'],
  "chun": ['CH AH1 N'],
  "chun's": ['CH AH1 N Z'],
  "chung": ['CH AH1 NG'],
  "chung's": ['CH AH1 NG Z'],
  "chunk": ['CH AH1 NG K'],
  "chunks": ['CH AH1 NG K S'],
  "chunky": ['CH AH1 NG K IY0'],
  "chunn": ['CH AH1 N'],
  "chunnel": ['CH AH1 N AH0 L'],
  "chupp": ['CH AH1 P'],
  "chura": ['CH UH1 R AH0'],
  "church": ['CH ER1 CH'],
  "church's": ['CH ER1 CH AH0 Z'],
  "churches": ['CH ER1 CH IH0 Z'],
  "churches'": ['CH ER1 CH IH0 Z'],
  "churchgoer": ['CH ER1 CH G OW2 ER0'],
  "churchgoers": ['CH ER1 CH G OW2 ER0 Z'],
  "churchgoing": ['CH ER1 CH G OW2 IH0 NG'],
  "churchhouse": ['CH ER1 CH HH AW2 S'],
  "churchill": ['CH ER1 CH IH0 L', 'CH ER1 CH HH IH0 L'],
  "churchill's": ['CH ER1 CH IH0 L Z', 'CH ER1 CH HH IH0 L Z'],
  "churchman": ['CH ER1 CH M AH0 N'],
  "churchmen": ['CH ER1 CH M AH0 N'],
  "churchwell": ['CH ER1 CH W EH2 L'],
  "churchyard": ['CH ER1 CH Y AA2 R D'],
  "churilla": ['CH ER0 IH1 L AH0'],
  "churkin": ['CH ER1 K AH0 N'],
  "churkin's": ['CH ER1 K AH0 N Z'],
  "churlish": ['CH ER1 L IH0 SH'],
  "churn": ['CH ER1 N'],
  "churned": ['CH ER1 N D'],
  "churning": ['CH ER1 N IH0 NG'],
  "churns": ['CH ER1 N Z'],
  "churry": ['CH ER1 IY0'],
  "chuse": ['CH Y UW1 Z', 'CH UW1 Z'],
  "chusmir": ['CH UH0 S M IH1 R'],
  "chustz": ['CH AH1 S T S'],
  "chute": ['SH UW1 T'],
  "chutes": ['SH UW1 T S'],
  "chutney": ['CH AH1 T N IY0'],
  "chutzpah": ['CH AH1 T S P AA2', 'HH UH1 T S P AA2'],
  "chykatka": ['CH IY0 K AA1 T K AH0'],
  "chynoweth": ['CH IH1 N AW0 EH0 TH'],
  "chyron": ['CH AY1 R AH0 N'],
  "chyron's": ['CH AY1 R AH0 N Z'],
  "cia": ['S IY1 AY1 EY1'],
  "ciaccia": ['CH IY0 AH0 CH IY1 AH0', 'S IY0 AH0 S IY1 AH0'],
  "ciaccio": ['CH AO1 CH IY0 OW0'],
  "cialis": ['S IY2 AA1 L AH0 S'],
  "ciampa": ['CH AO1 M P AH0'],
  "ciampi": ['CH AO1 M P IY0'],
  "cian": ['SH IY1 N'],
  "cianci": ['CH AO1 N CH IY0'],
  "ciancio": ['CH AO1 N CH IY0 OW0'],
  "cianciola": ['CH AO1 N CH OW0 L AH0'],
  "cianciolo": ['CH AO1 N CH OW0 L OW0'],
  "cianciulli": ['CH AO1 N CH UW0 L IY0'],
  "ciani": ['CH AO1 N IY0'],
  "ciano": ['CH IY0 AA1 N OW0'],
  "ciao": ['CH AW1'],
  "ciaobella": ['CH AW2 B EH1 L AH0'],
  "ciaramella": ['CH ER0 AA0 M EH1 L AH0'],
  "ciaramitaro": ['CH ER1 AA0 M IY0 T AA2 R OW2'],
  "ciaravino": ['CH ER0 AA0 V IY1 N OW0'],
  "ciardi": ['CH ER1 D IY0'],
  "ciarlo": ['CH ER1 L OW0'],
  "ciavarella": ['CH AH0 V AA0 R EH1 L AA2'],
  "ciba": ['S IY1 B AH0', 'S AY1 B AH0'],
  "ciba's": ['S IY1 B AH0 Z', 'S AY1 B AH0 Z'],
  "ciborowski": ['CH IH0 B ER0 AO1 F S K IY2'],
  "cibro": ['S IH1 B R OW0'],
  "cibula": ['CH IY0 B UW1 L AA0'],
  "cicada": ['S AH0 K EY1 D AH0'],
  "cicadas": ['S IH0 K EY1 D AH0 Z'],
  "cicala": ['S IH0 K AA1 L AH0'],
  "cicalese": ['CH IY0 K AA0 L EY1 Z IY0'],
  "ciccarelli": ['CH IY0 K ER0 EH1 L IY2'],
  "ciccarello": ['CH IY0 K ER0 EH1 L OW2'],
  "ciccarone": ['S IH1 K ER0 OW2 N'],
  "cicco": ['S IH1 K OW0'],
  "ciccone": ['CH IY0 K OW1 N IY2'],
  "cicely": ['S IH1 S AH0 L IY0'],
  "cicero": ['S IH1 S ER0 OW2'],
  "cicerone": ['S IH1 S ER0 OW2 N'],
  "cichocki": ['S IH0 CH AA1 K IY2'],
  "cichon": ['S IH1 CH AH0 N'],
  "cichowski": ['CH IH0 HH AO1 F S K IY2'],
  "cichy": ['S IH1 CH IY0'],
  "cicily": ['CH IH1 CH AH0 L IY0'],
  "cicio": ['S IH1 S IY0 OW0'],
  "cicippio": ['S IH0 S IH1 P IY0 OW0'],
  "cid": ['S IH1 D'],
  "cider": ['S AY1 D ER0'],
  "cie": ['S IY1', 'S IY1 AY1 IY1'],
  "ciel": ['S IY1 L'],
  "ciera": ['S IY1 R AA0'],
  "cieri": ['S IY1 R IY0'],
  "ciesielski": ['CH EH0 S IY1 L S K IY2'],
  "ciesla": ['CH EH1 S L AH0'],
  "cieslak": ['CH EH1 S L AH0 K'],
  "cieslewicz": ['CH EH1 S L IH0 V IH0 CH'],
  "cieslik": ['CH EH1 S L IH0 K'],
  "cieslinski": ['CH EH0 S L IH1 N S K IY2'],
  "cifelli": ['S IH0 F EH1 L IY2'],
  "cifra": ['S IH1 F R AA0'],
  "cifuentes": ['S IY0 F W EH1 N T EH0 S'],
  "ciga": ['S IY1 G AA0'],
  "cigar": ['S IH0 G AA1 R'],
  "cigarette": ['S IH2 G ER0 EH1 T'],
  "cigarette's": ['S IH2 G ER0 EH1 T S'],
  "cigarettes": ['S IH2 G ER0 EH1 T S'],
  "cigars": ['S IH0 G AA1 R Z'],
  "cigna": ['S IH1 G N AA2'],
  "cigna's": ['S IH1 G N AH0 Z'],
  "cihak": ['S IH1 HH AH0 K'],
  "cihlar": ['S IH1 L ER2'],
  "cilag": ['S IH1 L AE2 G'],
  "cilantro": ['S IH0 L AE1 N T R OW2'],
  "cilcorp": ['S IH1 L K AO0 R P'],
  "cilento": ['S IH0 L EH1 N T OW2'],
  "ciliates": ['S IH1 L IY0 AH0 T S'],
  "ciliberto": ['CH IY0 L IY0 B EH1 R T OW2'],
  "cilicia": ['S IH0 L IH1 SH AH0'],
  "ciller": ['S IH1 L ER0'],
  "cilley": ['S IH1 L IY0'],
  "cillo": ['S IH1 L OW0'],
  "cilluffo": ['S IH0 L UW1 F OW0'],
  "cilva": ['S IH1 L V AH0'],
  "cima": ['CH IY1 M AH0'],
  "cimaglia": ['S IH0 M AE1 G L IY0 AH0'],
  "cimarron": ['S IH1 M ER0 AA2 N'],
  "ciments": ['S IH0 M EH1 N T S'],
  "ciminero": ['S IY2 M IH0 N EH1 R OW0'],
  "cimini": ['CH IY0 M IY1 N IY0'],
  "cimino": ['CH IY0 M IY1 N OW0'],
  "cimmino": ['CH IY0 M IY1 N OW0'],
  "cimo": ['CH IY1 M OW0'],
  "cimorelli": ['CH IY0 M AO0 R EH1 L IY0'],
  "cina": ['CH IY1 N AH0'],
  "cinch": ['S IH1 N CH'],
  "cinched": ['S IH1 N CH T'],
  "cincinnati": ['S IH2 N S AH0 N AE1 T IY0'],
  "cincinnati's": ['S IH2 N S IH0 N AE1 T IY0 Z'],
  "cinco": ['S IH1 NG K OW0'],
  "cincotta": ['CH IY0 N K OW1 T AH0'],
  "cinder": ['S IH1 N D ER0'],
  "cinderella": ['S IH2 N D ER0 EH1 L AH0'],
  "cinders": ['S IH1 N D ER0 Z'],
  "cindie": ['S AY1 N D IY0'],
  "cindric": ['S IH1 N D R IH0 K'],
  "cindy": ['S IH1 N D IY0'],
  "cindy's": ['S IH1 N D IY0 Z'],
  "cinelli": ['S IH0 N EH1 L IY0'],
  "cinema": ['S IH1 N AH0 M AH0'],
  "cinema's": ['S IH1 N AH0 M AH0 Z'],
  "cinemark": ['S IH1 N AH0 M AA2 K'],
  "cinemas": ['S IH1 N AH0 M AH0 Z'],
  "cinematic": ['S IH2 N AH0 M AE1 T IH0 K'],
  "cinematographer": ['S IH2 N IH0 M AH0 T AA1 G R AH0 F ER0'],
  "cinematography": ['S IH2 N IH0 M AH0 T AA1 G R AH0 F IY0'],
  "cinemax": ['S IH1 N AH0 M AE0 K S'],
  "cineplex": ['S IH1 N AH0 P L EH2 K S'],
  "cineplex's": ['S IH1 N AH0 P L EH2 K S IH0 Z'],
  "cinergy": ['S IH1 N ER0 JH IY0'],
  "cingular": ['S IH2 N G UW0 L ER2'],
  "cini": ['CH IY1 N IY0'],
  "cinnabar": ['S IH1 N AH0 B AA2 R'],
  "cinnabon": ['S IH1 N AH0 B AO2 N'],
  "cinnaminson": ['S IH1 N AH0 M IH0 N S AH0 N'],
  "cinnamon": ['S IH1 N AH0 M AH0 N'],
  "cinnamonson": ['S IH1 N AH0 M AH0 N S AH0 N'],
  "cino": ['CH IY1 N OW0'],
  "cinq": ['S IH1 NG K'],
  "cinque": ['S IH1 NG K'],
  "cinquemani": ['CH IY0 N K W EH0 M AA1 N IY0'],
  "cinram": ['S IH1 N R AE0 M'],
  "cinthie": ['S IH1 N TH IY0'],
  "cintron": ['S IH1 N T R AH0 N'],
  "cio": ['S IY2 AY2 OW1'],
  "ciocca": ['CH OW1 K AH0'],
  "cioffi": ['CH IY0 OW1 F IY0'],
  "ciolek": ['CH IY0 OW1 L EH0 K'],
  "ciolino": ['CH OW0 L IY1 N OW0'],
  "ciotti": ['CH OW1 T IY0'],
  "cipher": ['S AY1 F ER0'],
  "cipolla": ['S IH0 P AA1 L AH0'],
  "cipollone": ['S IH2 P AH0 L OW1 N', 'S IH2 P AH0 L OW1 N IY0'],
  "cipri": ['S IH1 P R IY0'],
  "cipriani": ['CH IY0 P R IY0 AA1 N IY0'],
  "cipriano": ['CH IY0 P R IY0 AA1 N OW0'],
  "cira": ['S ER1 AH0'],
  "ciraulo": ['S ER0 AO1 L OW0'],
  "circa": ['S ER1 K AH0'],
  "circadian": ['S ER0 K EY1 D IY0 AH0 N'],
  "circle": ['S ER1 K AH0 L'],
  "circle's": ['S ER1 K AH0 L Z'],
  "circled": ['S ER1 K AH0 L D'],
  "circles": ['S ER1 K AH0 L Z'],
  "circling": ['S ER1 K AH0 L IH0 NG', 'S ER1 K L IH0 NG'],
  "circon": ['S ER1 K AA0 N'],
  "circuit": ['S ER1 K AH0 T'],
  "circuit's": ['S ER1 K AH0 T S'],
  "circuited": ['S ER1 K AH0 T IH0 D'],
  "circuitous": ['S ER0 K Y UW1 IH0 T AH0 S'],
  "circuitry": ['S ER1 K AH0 T R IY0'],
  "circuits": ['S ER1 K AH0 T S'],
  "circular": ['S ER1 K Y AH0 L ER0'],
  "circularize": ['S ER1 K Y AH0 L ER0 AY2 Z'],
  "circularizing": ['S ER1 K Y AH0 L ER0 AY2 Z IH0 NG'],
  "circularly": ['S ER1 K Y AH0 L ER0 L IY0'],
  "circulars": ['S ER1 K Y AH0 L ER0 Z'],
  "circulate": ['S ER1 K Y AH0 L EY2 T'],
  "circulated": ['S ER1 K Y AH0 L EY2 T IH0 D'],
  "circulates": ['S ER1 K Y AH0 L EY2 T S'],
  "circulating": ['S ER1 K Y AH0 L EY2 T IH0 NG'],
  "circulation": ['S ER1 K Y AH0 L EY2 SH AH0 N'],
  "circulations": ['S ER2 K Y AH0 L EY1 SH AH0 N Z'],
  "circulatory": ['S ER1 K Y AH0 L AH0 T AO2 R IY0'],
  "circumcise": ['S ER1 K AH0 M S AY2 Z'],
  "circumcised": ['S ER1 K AH0 M S AY2 Z D'],
  "circumcision": ['S ER2 K AH0 M S IH1 ZH AH0 N'],
  "circumference": ['S ER2 K AH1 M F R AH0 N S'],
  "circumnavigate": ['S ER2 K AH0 M N AE1 V AH0 G EY2 T'],
  "circumscribe": ['S ER2 K AH0 M S K R AY1 B'],
  "circumscribed": ['S ER2 K AH0 M S K R AY1 B D'],
  "circumspect": ['S ER1 K AH0 M S P EH2 K T'],
  "circumspection": ['S ER2 K AH0 M S P EH1 K SH AH0 N'],
  "circumstance": ['S ER1 K AH0 M S T AE2 N S'],
  "circumstances": ['S ER1 K AH0 M S T AE2 N S AH0 Z', 'S ER1 K AH0 M S T AE2 N S IH0 Z'],
  "circumstantial": ['S ER2 K AH0 M S T AE1 N SH AH0 L'],
  "circumstantially": ['S ER2 K AH0 M S T AE1 N SH AH0 L IY2'],
  "circumvene": ['S ER2 K AH0 M V IY1 N'],
  "circumvent": ['S ER2 K AH0 M V EH1 N T'],
  "circumvented": ['S ER2 K AH0 M V EH1 N T IH0 D'],
  "circumventing": ['S ER2 K AH0 M V EH1 N T IH0 NG'],
  "circumvention": ['S ER2 K AH0 M V EH1 N CH AH0 N'],
  "circumvents": ['S ER2 K AH0 M V EH1 N T S'],
  "circus": ['S ER1 K AH0 S'],
  "circus's": ['S ER1 K AH0 S IH0 Z'],
  "circuses": ['S ER1 K AH0 S AH0 Z'],
  "cirelli": ['S IH0 R EH1 L IY2'],
  "ciresi": ['S ER0 EH1 S IY0'],
  "ciriaco": ['S IH2 R IY0 AA1 K OW2'],
  "ciriello": ['S ER2 IY0 EH1 L OW2'],
  "cirigliano": ['S ER2 IY0 G L IY0 AA1 N OW2'],
  "cirillo": ['S IH0 R IH1 L OW2'],
  "cirincione": ['S ER0 IY0 N CH OW1 N IY2'],
  "cirino": ['S ER0 IY1 N OW2'],
  "cirkin": ['S ER1 K IH0 N'],
  "cirone": ['S IH0 R OW1 N'],
  "ciros": ['S IH1 R OW2 Z'],
  "cirque": ['S ER1 K'],
  "cirrhosis": ['S ER0 OW1 S AH0 S'],
  "cirrincione": ['S ER0 R IY0 N CH OW1 N IY2'],
  "cirrus": ['S IH1 R AH0 S'],
  "cisar": ['S IH0 S AA1 R'],
  "cisco": ['S IH1 S K OW2'],
  "cisco's": ['S IH1 S K OW2 Z'],
  "cisek": ['CH IH1 S EH0 K'],
  "cisewski": ['CH IH0 S EH1 F S K IY2'],
  "ciskei": ['S IH0 S K EY1'],
  "cisler": ['S IH1 S AH0 L ER0', 'S IH1 S L ER0'],
  "cislo": ['CH IY1 S L OW0'],
  "cisneros": ['S IH0 S N EH1 R OW2 S'],
  "cisney": ['S IH1 Z N IY0'],
  "cissell": ['S IH1 S AH0 L'],
  "cissie": ['S IH1 S IY0'],
  "cissna": ['S IH1 S N AH0'],
  "cist": ['S IH1 S T'],
  "cistercian": ['S IH0 S T ER1 SH AH0 N'],
  "cistern": ['S IH1 S T ER0 N'],
  "cisterns": ['S IH1 S T ER0 N Z'],
  "ciszek": ['CH IH1 SH EH0 K'],
  "ciszewski": ['CH IH0 SH EH1 F S K IY2'],
  "cit": ['S IY2 AY2 T IY1'],
  "citadel": ['S IH1 T AH0 D EH2 L'],
  "citadel's": ['S IH1 T AH0 D EH2 L Z'],
  "citation": ['S AY0 T EY1 SH AH0 N'],
  "citations": ['S AY0 T EY1 SH AH0 N Z'],
  "cite": ['S AY1 T'],
  "cited": ['S AY1 T AH0 D', 'S AY1 T IH0 D'],
  "cites": ['S AY1 T S'],
  "citgo": ['S IH1 T G OW0'],
  "citi": ['S IH1 T IY0'],
  "citibank": ['S IH1 T IY0 B AE2 NG K'],
  "citibank's": ['S IH1 T IY0 B AE2 NG K S'],
  "citic": ['S IH1 T IH0 K'],
  "citicorp": ['S IH1 T IY0 K AO2 R P'],
  "citicorp's": ['S IH1 T IY0 K AO2 R P S'],
  "citicorps": ['S IH1 T IY0 K AO2 R P S'],
  "citicorps'": ['S IH1 T IY0 K AO2 R P S'],
  "cities": ['S IH1 T IY0 Z'],
  "cities'": ['S IH1 T IY0 Z'],
  "citing": ['S AY1 T IH0 NG'],
  "citisteel": ['S IH1 T IY0 S T IY2 L'],
  "citizen": ['S IH1 T AH0 Z AH0 N', 'S IH1 T IH0 Z AH0 N'],
  "citizen's": ['S IH1 T AH0 Z AH0 N Z'],
  "citizenry": ['S IH1 T IH0 Z AH0 N R IY0'],
  "citizenry's": ['S IH1 T IH0 Z AH0 N R IY0 Z'],
  "citizens": ['S IH1 T AH0 Z AH0 N Z', 'S IH1 T IH0 Z AH0 N Z'],
  "citizens'": ['S IH1 T IH0 Z AH0 N Z'],
  "citizenship": ['S IH1 T IH0 Z AH0 N SH IH2 P'],
  "citrano": ['CH IY0 T R AA1 N OW0'],
  "citric": ['S IH1 T R IH0 K'],
  "citrin": ['S IH1 T R IH0 N'],
  "citrine": ['S IH2 T R IY1 N'],
  "citrix": ['S IH1 T R IH0 K S'],
  "citro": ['S IH1 T R OW0'],
  "citroen": ['S IH1 T R OW0 N'],
  "citron": ['S IH1 T R AH0 N', 'S IH1 T R AH0 N Z'],
  "citron's": ['S IH1 T R AH0 N Z', 'S IH1 T R AA0 N Z'],
  "citronella": ['S IH2 T R AA0 N EH1 L AH0'],
  "citrosuco": ['S IH2 T R AH0 S UW1 K OW0'],
  "citrucel": ['S IH1 T R AH0 S EH2 L'],
  "citrucel's": ['S IH1 T R AH0 S EH2 L Z'],
  "citrus": ['S IH1 T R AH0 S'],
  "citrus's": ['S IH1 T R AH0 S AH0 Z', 'S IH1 T R AH0 S IH0 Z'],
  "cittadino": ['CH IY0 T AA0 D IY1 N OW0'],
  "city": ['S IH1 T IY0'],
  "city's": ['S IH1 T IY0 Z'],
  "cityfed": ['S IH1 T IY0 F EH2 D'],
  "cityplace": ['S IH1 T IY0 P L EY2 S'],
  "citysearch": ['S IH1 T IY0 S ER2 CH'],
  "cityside": ['S IH1 T IY0 S AY2 D'],
  "citytrust": ['S IH1 T IY0 T R AH2 S T'],
  "citywide": ['S IH1 T IY0 W AY2 D'],
  "ciucci": ['CH UW1 CH IY0'],
  "ciudad": ['S IY2 UW0 D AE1 D'],
  "ciulla": ['CH UW1 L AH0'],
  "ciullo": ['CH UW1 L OW0'],
  "civet": ['S IH1 V AH0 T'],
  "civic": ['S IH1 V IH0 K'],
  "civics": ['S IH1 V IH0 K S'],
  "civil": ['S IH1 V AH0 L'],
  "civiletti": ['S IY2 V IH0 L EH1 T IY0'],
  "civilian": ['S AH0 V IH1 L Y AH0 N'],
  "civilians": ['S AH0 V IH1 L Y AH0 N Z'],
  "civility": ['S AH0 V IH1 L AH0 T IY0'],
  "civilization": ['S IH2 V AH0 L IH0 Z EY1 SH AH0 N'],
  "civilizations": ['S IH2 V AH0 L IH0 Z EY1 SH AH0 N Z'],
  "civilize": ['S IH1 V AH0 L AY2 Z'],
  "civilized": ['S IH1 V AH0 L AY2 Z D'],
  "civilly": ['S IH1 V IH0 L IY0'],
  "civitello": ['CH IY0 V IY0 T EH1 L OW0'],
  "cizek": ['CH IH1 Z EH0 K'],
  "cizik": ['S IY1 Z IH0 K'],
  "cizneros": ['S IH2 Z N EH1 R OW0 S'],
  "claar": ['K L AA1 R'],
  "claassen": ['K L AA1 S AH0 N'],
  "clabaugh": ['K L AE1 B AO0'],
  "clabir": ['K L AE1 B IH0 R'],
  "clabo": ['K L AA1 B OW0'],
  "claborn": ['K L AE1 B ER0 N'],
  "clabough": ['K L AE1 B AW0'],
  "clack": ['K L AE1 K'],
  "clackamas": ['K L AE1 K AH0 M AH0 S'],
  "clad": ['K L AE1 D'],
  "claddagh": ['K L AE1 D AH2'],
  "cladification": ['K L AE2 D AH0 F AH0 K EY1 SH AH0 N'],
  "claes": ['K L EY1 Z'],
  "claeys": ['K L EY1 Z'],
  "claffey": ['K L AE1 F IY0'],
  "claflin": ['K L AE1 F L IH0 N'],
  "clagett": ['K L AE1 JH IH0 T'],
  "clagg": ['K L AE1 G'],
  "claggett": ['K L AE1 G IH0 T'],
  "clague": ['K L AA1 G'],
  "claiborn": ['K L EY1 B ER0 N'],
  "claiborne": ['K L EY1 B ER0 N'],
  "claiborne's": ['K L EY1 B AO0 R N Z', 'K L EY1 B ER0 N Z'],
  "claim": ['K L EY1 M'],
  "claimant": ['K L EY1 M AH0 N T'],
  "claimants": ['K L EY1 M AH0 N T S'],
  "claimants'": ['K L EY1 M AH0 N T S'],
  "claimed": ['K L EY1 M D'],
  "claiming": ['K L EY1 M IH0 NG'],
  "claims": ['K L EY1 M Z'],
  "clair": ['K L EH1 R'],
  "claire": ['K L EH1 R'],
  "claire's": ['K L EH1 R Z'],
  "clairmont": ['K L EH1 R M AH0 N T'],
  "clairol": ['K L EH1 R AA0 L'],
  "clairson": ['K L EH1 R S AH0 N'],
  "clairvoyance": ['K L EH0 R V OY1 AH0 N S'],
  "clairvoyant": ['K L EH0 R V OY1 AH0 N T'],
  "clam": ['K L AE1 M'],
  "clamber": ['K L AE1 M B ER0'],
  "clambered": ['K L AE1 M B ER0 D'],
  "clamen": ['K L EY1 M AH0 N'],
  "clammed": ['K L AE1 M D'],
  "clammy": ['K L AE1 M IY0'],
  "clamor": ['K L AE1 M ER0'],
  "clamored": ['K L AE1 M ER0 D'],
  "clamoring": ['K L AE1 M ER0 IH0 NG'],
  "clamour": ['K L AE1 M ER0'],
  "clamp": ['K L AE1 M P'],
  "clampdown": ['K L AE1 M P D AW2 N'],
  "clamped": ['K L AE1 M P T'],
  "clampett": ['K L AE1 M P AH0 T'],
  "clamping": ['K L AE1 M P IH0 NG'],
  "clampitt": ['K L AH0 M P IH1 T'],
  "clamps": ['K L AE1 M P S'],
  "clams": ['K L AE1 M Z'],
  "clamshell": ['K L AE1 M SH EH2 L'],
  "clan": ['K L AE1 N'],
  "clancey": ['K L AE1 N S IY0'],
  "clancy": ['K L AE1 N S IY0'],
  "clancy's": ['K L AE1 N S IY0 Z'],
  "clandestine": ['K L AE0 N D EH1 S T IH0 N'],
  "clandestinely": ['K L AE0 N D EH1 S T AH0 N L IY0'],
  "clang": ['K L AE1 NG'],
  "clanging": ['K L AE1 NG IH0 NG'],
  "clanin": ['K L AE1 N IH0 N'],
  "clank": ['K L AE1 NG K'],
  "clanking": ['K L AE1 NG K IH0 NG'],
  "clannish": ['K L AE1 N IH0 SH'],
  "clans": ['K L AE1 N Z'],
  "clanton": ['K L AE1 N T AH0 N'],
  "clap": ['K L AE1 P'],
  "clapboard": ['K L AE1 P B AO2 R D'],
  "clapboards": ['K L AE1 P B AO2 R D Z'],
  "clapham": ['K L AE1 F AH0 M'],
  "clapman": ['K L AE1 P M AH0 N'],
  "clapp": ['K L AE1 P'],
  "clapped": ['K L AE1 P T'],
  "clapper": ['K L AE1 P ER0'],
  "clapping": ['K L AE1 P IH0 NG'],
  "claps": ['K L AE1 P S'],
  "clapsaddle": ['K L AE1 P S AE2 D AH0 L'],
  "clapton": ['K L AE1 P T AH0 N'],
  "clapton's": ['K L AE1 P T AH0 N Z'],
  "clar": ['K L AA1 R'],
  "clara": ['K L AE1 R AH0', 'K L EH1 R AH0'],
  "clara's": ['K L AE1 R AH0 Z', 'K L EH1 R AH0 Z'],
  "clarabelle": ['K L AE1 R AH0 B AH0 L', 'K L AE1 R AH0 B EH2 L'],
  "claramae": ['K L AA0 R AA1 M AY0'],
  "claran": ['K L EH1 R AH0 N'],
  "clarcor": ['K L AA1 R K AO2 R'],
  "clardy": ['K L AA1 R D IY0'],
  "clare": ['K L EH1 R'],
  "claremont": ['K L EH1 R M AA2 N T'],
  "claren": ['K L AE1 R AH0 N'],
  "clarence": ['K L EH1 R AH0 N S'],
  "clarendon": ['K L EH1 R AH0 N D AH0 N'],
  "claresta": ['K L AA0 R EH1 S T AH0'],
  "clarette": ['K L ER0 EH1 T'],
  "clarey": ['K L AE1 R IY0'],
  "claribel": ['K L EH1 R AH0 B EH2 L'],
  "clarice": ['K L ER0 IY1 S'],
  "clarida": ['K L AA0 R IY1 D AH0'],
  "claridge": ['K L EH1 R IH0 JH'],
  "claridges": ['K L EH1 R IH0 JH IH0 Z'],
  "claridon": ['K L EH1 R AH0 D AA0 N'],
  "clarification": ['K L EH2 R AH0 F AH0 K EY1 SH AH0 N'],
  "clarifications": ['K L EH2 R IH0 F IH0 K EY1 SH AH0 N Z'],
  "clarified": ['K L EH1 R AH0 F AY2 D'],
  "clarifies": ['K L EH1 R AH0 F AY2 Z'],
  "clarify": ['K L EH1 R AH0 F AY2'],
  "clarifying": ['K L EH1 R AH0 F AY2 IH0 NG'],
  "clarimond": ['K L AE1 R IH0 M AH0 N D'],
  "clarinda": ['K L ER0 IH1 N D AH0'],
  "clarine": ['K L EH1 R IY0 N'],
  "clarinet": ['K L EH2 R AH0 N EH1 T'],
  "clarinetist": ['K L EH2 R AH0 N EH1 T IH0 S T'],
  "clarinetists": ['K L EH2 R AH0 N EH1 T IH0 S T S'],
  "clarins": ['K L EH1 R IH0 N Z'],
  "clarion": ['K L EH1 R IY0 AH0 N'],
  "claris": ['K L EH1 R IH0 S'],
  "clarissa": ['K L ER0 IH1 S AH0'],
  "clarisse": ['K L AE1 R IH0 S', 'K L AH0 R IY1 S'],
  "clarita": ['K L AA0 R IY1 T AH0'],
  "claritin": ['K L EH1 R IH0 T IH0 N'],
  "clarity": ['K L EH1 R AH0 T IY0', 'K L EH1 R IH0 T IY0'],
  "clark": ['K L AA1 R K'],
  "clark's": ['K L AA1 R K S'],
  "clarke": ['K L AA1 R K'],
  "clarke's": ['K L AA1 R K S'],
  "clarken": ['K L AA1 R K EH0 N'],
  "clarkin": ['K L AA1 R K IH0 N'],
  "clarks": ['K L AA1 R K S'],
  "clarksburg": ['K L AA1 R K S B ER0 G'],
  "clarkson": ['K L AA1 R K S AH0 N'],
  "clarkston": ['K L AA1 R K S T AH0 N'],
  "clarksville": ['K L AA1 R K S V IH2 L'],
  "claro": ['K L AA1 R OW0'],
  "clarostat": ['K L EH1 R AH0 S T AE2 T'],
  "clarridge": ['K L AE1 R IH0 JH'],
  "clarrisse": ['K L AE1 R IH0 S'],
  "clary": ['K L EH1 R IY0'],
  "clasby": ['K L AE1 S B IY0'],
  "clasen": ['K L EY1 S AH0 N'],
  "clash": ['K L AE1 SH'],
  "clashed": ['K L AE1 SH T'],
  "clashes": ['K L AE1 SH IH0 Z'],
  "clashing": ['K L AE1 SH IH0 NG'],
  "clason": ['K L AE1 S AH0 N'],
  "clasp": ['K L AE1 S P'],
  "clasped": ['K L AE1 S P T'],
  "class": ['K L AE1 S'],
  "class's": ['K L AE1 S IH0 Z'],
  "classaction": ['K L AE1 S AE1 K SH AH0 N'],
  "classactions": ['K L AE1 S AE1 K SH AH0 N Z'],
  "classed": ['K L AE1 S T'],
  "classen": ['K L AE1 S AH0 N'],
  "classes": ['K L AE1 S AH0 Z', 'K L AE1 S IH0 Z'],
  "classic": ['K L AE1 S IH0 K'],
  "classical": ['K L AE1 S IH0 K AH0 L'],
  "classically": ['K L AE1 S IH0 K L IY0'],
  "classicism": ['K L AE1 S IH0 S IH2 Z AH0 M'],
  "classicist": ['K L AE1 S AH0 S AH0 S T'],
  "classics": ['K L AE1 S IH0 K S'],
  "classier": ['K L AE1 S IY0 ER0'],
  "classifiable": ['K L AE1 S AH0 F AY2 AH0 B AH0 L'],
  "classification": ['K L AE2 S AH0 F AH0 K EY1 SH AH0 N'],
  "classifications": ['K L AE2 S AH0 F AH0 K EY1 SH AH0 N Z'],
  "classified": ['K L AE1 S AH0 F AY2 D'],
  "classifieds": ['K L AE1 S AH0 F AY2 D Z'],
  "classifies": ['K L AE1 S AH0 F AY2 Z'],
  "classify": ['K L AE1 S AH0 F AY2'],
  "classifying": ['K L AE1 S AH0 F AY2 IH0 NG'],
  "classing": ['K L AE1 S IH0 NG'],
  "classless": ['K L AE1 S L AH0 S'],
  "classman": ['K L AE1 S M AH0 N'],
  "classmate": ['K L AE1 S M EY2 T'],
  "classmates": ['K L AE1 S M EY2 T S'],
  "classmen": ['K L AE1 S M EH0 N'],
  "classon": ['K L AE1 S AH0 N'],
  "classroom": ['K L AE1 S R UW2 M'],
  "classrooms": ['K L AE1 S R UW2 M Z'],
  "classy": ['K L AE1 S IY0'],
  "clatter": ['K L AE1 T ER0'],
  "clattering": ['K L AE1 T ER0 IH0 NG'],
  "claud": ['K L AO1 D'],
  "claude": ['K L AO1 D'],
  "claudet": ['K L AO0 D EH1 T'],
  "claudette": ['K L OW0 D EH1 T'],
  "claudia": ['K L AO1 D IY0 AH0'],
  "claudian": ['K L AO1 D IY0 AH0 N'],
  "claudie": ['K L AO1 D IY0'],
  "claudina": ['K L AO1 D IH0 N AH0', 'K L AO0 D IY1 N AH0'],
  "claudine": ['K L AO0 D IY1 N'],
  "claudio": ['K L AO1 D IY0 OW2'],
  "claudius": ['K L AO1 D IY0 AH0 S'],
  "claudson": ['K L AO1 D S AH0 N'],
  "claunch": ['K L AO1 N CH'],
  "claus": ['K L AO1 Z'],
  "claus'": ['K L AO1 Z'],
  "clause": ['K L AO1 Z'],
  "clausell": ['K L AO1 Z AH0 L'],
  "clausen": ['K L AW1 S AH0 N'],
  "clauser": ['K L AO1 Z ER0'],
  "clauses": ['K L AO1 Z AH0 Z', 'K L AO1 Z IH0 Z'],
  "clausing": ['K L AO1 Z IH0 NG'],
  "clauson": ['K L AO1 Z AH0 N'],
  "clauss": ['K L AO1 S'],
  "claussen": ['K L AO1 Z S AH0 N'],
  "claustrophobia": ['K L AO2 S T R AH0 F OW1 B IY0 AH0'],
  "claustrophobic": ['K L AO2 S T R AH0 F OW1 B IH0 K'],
  "clavette": ['K L AH0 V EH1 T'],
  "clavichord": ['K L AE1 V AH0 K AO2 R D'],
  "clavicle": ['K L AE1 V AH0 K AH0 L', 'K L AE1 V IH0 K AH0 L'],
  "clavin": ['K L AE1 V IH0 N'],
  "claw": ['K L AO1'],
  "clawed": ['K L AO1 D'],
  "clawing": ['K L AO1 IH0 NG'],
  "claws": ['K L AO1 Z'],
  "clawson": ['K L AO1 S AH0 N'],
  "claxon": ['K L AE1 K S AH0 N'],
  "claxton": ['K L AE1 K S T AH0 N'],
  "clay": ['K L EY1'],
  "claybaugh": ['K L EY1 B AO2'],
  "clayborn": ['K L EY1 B ER0 N'],
  "clayborne": ['K L EY1 B ER0 N'],
  "claybourne": ['K L EY1 B ER0 N'],
  "claybrook": ['K L EY1 B R UH2 K'],
  "claybrooks": ['K L EY1 B R UH2 K S'],
  "clayburn": ['K L EY1 B ER2 N'],
  "claycomb": ['K L EY1 K AH0 M'],
  "clayey": ['K L EY1 IY0'],
  "clayman": ['K L EY1 M AH0 N'],
  "claymation": ['K L EY1 M EY2 SH AH0 N'],
  "claymont": ['K L EY1 M AA2 N T'],
  "claymore": ['K L EY1 M AO2 R'],
  "claypool": ['K L EY1 P UW2 L'],
  "claypoole": ['K L EY1 P UW2 L'],
  "clays": ['K L EY1 Z'],
  "clayson": ['K L EY1 Z AH0 N'],
  "clayton": ['K L EY1 T AH0 N'],
  "clayton's": ['K L EY1 T AH0 N Z'],
  "claytor": ['K L EY1 T ER0'],
  "claywell": ['K L EY1 W EH2 L'],
  "cleah": ['K IY1 AH0'],
  "clean": ['K L IY1 N'],
  "cleaned": ['K L IY1 N D'],
  "cleaner": ['K L IY1 N ER0'],
  "cleaners": ['K L IY1 N ER0 Z'],
  "cleanest": ['K L IY1 N AH0 S T'],
  "cleaning": ['K L IY1 N IH0 NG'],
  "cleanliness": ['K L EH1 N L IY0 N IH0 S'],
  "cleanly": ['K L IY1 N L IY0'],
  "cleanness": ['K L IY1 N IH0 S'],
  "cleans": ['K L IY1 N Z'],
  "cleanse": ['K L EH1 N Z'],
  "cleansed": ['K L EH1 N Z D'],
  "cleanser": ['K L EH1 N Z ER0'],
  "cleansers": ['K L EH1 N Z ER0 Z'],
  "cleansing": ['K L EH1 N Z IH0 NG'],
  "cleantha": ['K L IY1 N TH AH0'],
  "cleanup": ['K L IY1 N AH2 P'],
  "cleanups": ['K L IY1 N AH2 P S'],
  "clear": ['K L IH1 R'],
  "clear-cut": ['K L IH1 R K AH2 T'],
  "clearance": ['K L IH1 R AH0 N S'],
  "clearances": ['K L IH1 R AH0 N S IH0 Z'],
  "clearcut": ['K L IH1 R K AH2 T'],
  "clearcuts": ['K L IH1 R K AH2 T S'],
  "clearcutting": ['K L IH1 R K AH2 T IH0 NG'],
  "cleared": ['K L IH1 R D'],
  "clearer": ['K L IH1 R ER0'],
  "clearest": ['K L IH1 R IH0 S T'],
  "clearing": ['K L IH1 R IH0 NG'],
  "clearinghouse": ['K L IH1 R IH0 NG HH AW2 S'],
  "clearinghouses": ['K L IY1 R IH0 NG HH AW2 S IH0 Z'],
  "clearly": ['K L IH1 R L IY0'],
  "clearman": ['K L IH1 R M AH0 N'],
  "clearness": ['K L IH1 R N AH0 S'],
  "clears": ['K L IH1 R Z'],
  "clearwater": ['K L IH1 R W AO2 T ER0'],
  "cleary": ['K L IH1 R IY0'],
  "cleat": ['K L IY1 T'],
  "cleats": ['K L IY1 T S'],
  "cleavage": ['K L IY1 V AH0 JH', 'K L IY1 V IH0 JH'],
  "cleave": ['K L IY1 V'],
  "cleaveland": ['K L IY1 V L AH0 N D'],
  "cleavenger": ['K L IY1 V IH0 N JH ER0'],
  "cleaver": ['K L IY1 V ER0'],
  "cleaves": ['K L IY1 V Z'],
  "cleckler": ['K L EH1 K L ER0'],
  "cleckley": ['K L EH1 K L IY0'],
  "cleckner": ['K L EH1 K N ER0'],
  "cleek": ['K L IY1 K'],
  "cleere": ['K L IH1 R'],
  "cleese": ['K L IY1 S'],
  "cleeton": ['K L IY1 T AH0 N'],
  "clef": ['K L EH1 F'],
  "cleft": ['K L EH1 F T'],
  "clegg": ['K L EH1 G'],
  "cleghorn": ['K L EH1 G HH ER0 N'],
  "cleland": ['K L EH1 L AH0 N D'],
  "clell": ['K L EH1 L'],
  "clelland": ['K L EH1 L AH0 N D'],
  "clem": ['K L EH1 M'],
  "clemans": ['K L EH1 M AH0 N Z'],
  "clematis": ['K L EH1 M AH0 T IH0 S', 'K L AH0 M AE1 T IH0 S'],
  "clemen": ['K L EH1 M AH0 N'],
  "clemence": ['K L EH1 M AH0 N S'],
  "clemency": ['K L EH1 M AH0 N S IY0'],
  "clemens": ['K L EH1 M AH0 N Z'],
  "clemensen": ['K L EH1 M AH0 N S AH0 N'],
  "clemenson": ['K L EH1 M IH0 N S AH0 N'],
  "clement": ['K L EH1 M AH0 N T'],
  "clemente": ['K L AH0 M EH1 N T EY0', 'K L AH0 M EH1 N T IY0'],
  "clemente's": ['K L AH0 M EH1 N T EY0 Z', 'K L AH0 M EH1 N T IY0 Z'],
  "clementes": ['K L AH0 M EH1 N T EY0 Z', 'K L AH0 M EH1 N T IY0 Z'],
  "clementi": ['K L EY0 M EY1 N T IY0'],
  "clementia": ['K L EY0 M EY1 N SH AH0'],
  "clementine": ['K L EH1 M AH0 N T AY2 N', 'K L EH1 M AH0 N T IY2 N'],
  "clements": ['K L EH1 M AH0 N T S'],
  "clementson": ['K L EH1 M IH0 N T S AH0 N'],
  "clementz": ['K L EH1 M IH0 N T S'],
  "clemmer": ['K L EH1 M ER0'],
  "clemmie": ['K L EH1 M IY0'],
  "clemmons": ['K L EH1 M AH0 N Z'],
  "clemmy": ['K L EH1 M IY0'],
  "clemo": ['K L EY1 M OW0'],
  "clemons": ['K L EH1 M AH0 N Z'],
  "clemson": ['K L EH1 M S AH0 N'],
  "clench": ['K L EH1 N CH'],
  "clenched": ['K L EH1 N CH T'],
  "clencher": ['K L EH1 N CH ER0'],
  "clenches": ['K L EH1 N CH AH0 Z'],
  "clendaniel": ['K L EH1 N D AH0 N IY2 L'],
  "clendenen": ['K L EH1 N D AH0 N AH0 N'],
  "clendenin": ['K L EH1 N D IH0 N IH0 N'],
  "clendening": ['K L EH1 N D AH0 N IH0 NG'],
  "clendenning": ['K L EH2 N D EH1 N IH0 NG'],
  "clenney": ['K L EH1 N IY0'],
  "clenwar": ['K L EH1 N W ER0'],
  "cleo": ['K L IY1 OW0'],
  "cleopatra": ['K L IY2 AH0 P AE1 T R AH0'],
  "cleopatra's": ['K L IY2 AH0 P AE1 T R AH0 Z'],
  "clephane": ['K L EH1 F EY2 N'],
  "clepper": ['K L EH1 P ER0'],
  "clerc": ['K L ER1 K'],
  "clercq": ['K L ER1 K'],
  "clergy": ['K L ER1 JH IY0'],
  "clergyman": ['K L ER1 JH IY0 M AH0 N', 'K L ER1 JH IY0 M AE2 N'],
  "clergymen": ['K L ER1 JH IY0 M IH0 N', 'K L ER1 JH IY0 M EH2 N'],
  "cleric": ['K L EH1 R IH0 K'],
  "clerical": ['K L EH1 R AH0 K AH0 L', 'K L EH1 R IH0 K AH0 L'],
  "clerics": ['K L EH1 R IH0 K S'],
  "clerissa": ['K L ER0 IY1 S AH0'],
  "clerk": ['K L ER1 K'],
  "clerk's": ['K L ER1 K S'],
  "clerkin": ['K L ER1 K IH0 N'],
  "clerks": ['K L ER1 K S'],
  "clerks'": ['K L ER1 K S'],
  "clermont": ['K L EH1 R M AA2 N T'],
  "cleva": ['K L IY1 V AH0'],
  "cleve": ['K L IY1 V'],
  "cleveland": ['K L IY1 V L AH0 N D'],
  "cleveland's": ['K L IY1 V L AH0 N D Z'],
  "clevelander": ['K L IY1 V L AH0 N D ER0'],
  "clevelanders": ['K L IY1 V L AH0 N D ER0 Z'],
  "cleven": ['K L IY1 V AH0 N'],
  "clevenger": ['K L EH1 V IH0 N JH ER0'],
  "clever": ['K L EH1 V ER0'],
  "cleverer": ['K L EH1 V AH0 R ER2'],
  "cleverly": ['K L EH1 V ER0 L IY0'],
  "cleverness": ['K L EH1 V ER0 N AH0 S'],
  "clevetrust": ['K L IY1 V T R AH1 S T'],
  "clevie": ['K L IY1 V IY0'],
  "clevinger": ['K L EH1 V IH0 NG ER0'],
  "clevite": ['K L AH0 V AY1 T'],
  "clevite's": ['K L AH0 V AY1 T S'],
  "clewell": ['K L EH1 W EH0 L'],
  "clewis": ['K L UW1 IH0 S'],
  "clewiston": ['K L UW1 AH0 S T AH0 N'],
  "clews": ['K L UW1 Z'],
  "cliantha": ['K L IY0 AE1 N TH AH0'],
  "cliburn": ['K L AY1 B ER0 N'],
  "cliche": ['K L IY0 SH EY1'],
  "cliched": ['K L IY2 SH EY1 D'],
  "cliches": ['K L IY0 SH EY1 Z'],
  "click": ['K L IH1 K'],
  "clickbait": ['K L IH1 K B EY2 T'],
  "clicked": ['K L IH1 K T'],
  "clicker": ['K L IH1 K ER0'],
  "clicking": ['K L IH1 K IH0 NG'],
  "clickner": ['K L IH1 K N ER0'],
  "clicks": ['K L IH1 K S'],
  "client": ['K L AY1 AH0 N T'],
  "client's": ['K L AY1 AH0 N T S'],
  "clientele": ['K L AY2 AH0 N T EH1 L'],
  "clients": ['K L AY1 AH0 N T S'],
  "clients'": ['K L AY1 AH0 N T S'],
  "cliett": ['K L IY1 T'],
  "clif": ['K L IH1 F'],
  "cliff": ['K L IH1 F'],
  "cliff's": ['K L IH1 F S'],
  "cliffe": ['K L IH1 F'],
  "cliffhanger": ['K L IH1 F HH AE2 NG ER0'],
  "cliffhangers": ['K L IH1 F HH AE2 NG ER0 Z'],
  "clifford": ['K L IH1 F ER0 D'],
  "clifford's": ['K L IH1 F ER0 D Z'],
  "cliffs": ['K L IH1 F S'],
  "cliffs'": ['K L IH1 F S'],
  "clift": ['K L IH1 F T'],
  "clifton": ['K L IH1 F T AH0 N'],
  "cliggott": ['K L IH1 G AH0 T'],
  "clim": ['K L IH1 M'],
  "climaco": ['K L IH1 M AH0 K OW0'],
  "climactic": ['K L AY0 M AE1 K T IH0 K'],
  "climate": ['K L AY1 M AH0 T', 'K L AY1 M IH0 T'],
  "climates": ['K L AY1 M AH0 T S', 'K L AY1 M IH0 T S'],
  "climatic": ['K L AY0 M AE1 T IH0 K'],
  "climatologist": ['K L IH2 M AH0 T AA1 L AH0 JH IH0 S T', 'K L AY2 M AH0 T AA1 L AH0 JH IH0 S T'],
  "climatologists": ['K L IH2 M AH0 T AA1 L AH0 JH IH0 S T S', 'K L AY2 M AH0 T AA1 L AH0 JH IH0 S T S', 'K L IH2 M AH0 T AA1 L AH0 JH IH0 S', 'K L AY2 M AH0 T AA1 L AH0 JH IH0 S'],
  "climax": ['K L AY1 M AE2 K S'],
  "climaxed": ['K L AY1 M AE2 K S T'],
  "climaxes": ['K L IH1 M AE0 K S IH0 Z', 'K L AY1 M AE0 K S IH0 Z'],
  "climb": ['K L AY1 M'],
  "climbed": ['K L AY1 M D'],
  "climber": ['K L AY1 M ER0'],
  "climbers": ['K L AY1 M ER0 Z'],
  "climbers'": ['K L AY1 M ER0 Z'],
  "climbing": ['K L AY1 M IH0 NG'],
  "climbs": ['K L AY1 M Z'],
  "climer": ['K L AY1 M ER0'],
  "climes": ['K L AY1 M Z'],
  "clinard": ['K L IH1 N ER0 D'],
  "clinch": ['K L IH1 N CH'],
  "clinched": ['K L IH1 N CH T'],
  "clincher": ['K L IH1 N CH ER0'],
  "clinches": ['K L IH1 N CH AH0 Z', 'K L IH1 N CH IH0 Z'],
  "clinching": ['K L IH1 N CH IH0 NG'],
  "cline": ['K L AY1 N'],
  "clines": ['K L AY1 N Z'],
  "clines's": ['K L AY1 N Z IH0 Z'],
  "cling": ['K L IH1 NG'],
  "clingan": ['K L IH1 NG G AH0 N'],
  "clingenpeel": ['K L IH0 NG G EH1 N P IY0 L'],
  "clinger": ['K L IH1 NG ER0'],
  "clinger's": ['K L IH1 NG ER0 Z'],
  "clingerman": ['K L IH1 NG ER0 M AH0 N'],
  "clinging": ['K L IH1 NG IH0 NG'],
  "clingman": ['K L IH1 NG M AH0 N'],
  "clings": ['K L IH1 NG Z'],
  "clini": ['K L IY1 N IY0'],
  "clinic": ['K L IH1 N IH0 K'],
  "clinic's": ['K L IH1 N IH0 K S'],
  "clinical": ['K L IH1 N AH0 K AH0 L', 'K L IH1 N IH0 K AH0 L'],
  "clinical's": ['K L IH1 N IH0 K AH0 L Z'],
  "clinically": ['K L IH1 N IH0 K AH0 L IY0', 'K L IH1 N IH0 K L IY0'],
  "clinician": ['K L IH0 N IH1 SH AH0 N'],
  "clinicians": ['K L IH0 N IH1 SH AH0 N Z'],
  "clinics": ['K L IH1 N IH0 K S'],
  "clinique": ['K L AH0 N IY1 K'],
  "clink": ['K L IH1 NG K'],
  "clinkenbeard": ['K L IH0 NG K EH1 N B ER0 D'],
  "clinkscale": ['K L IH1 NG K S K EY2 L'],
  "clinkscales": ['K L IH1 NG K S K EY2 L Z'],
  "clint": ['K L IH1 N T'],
  "clinton": ['K L IH1 N T AH0 N'],
  "clinton's": ['K L IH1 N T AH0 N Z'],
  "clintonite": ['K L IH1 N T AH0 N AY2 T'],
  "clintonites": ['K L IH1 N T AH0 N AY2 T S'],
  "clintonomics": ['K L IH2 N T AH0 N AA1 M IH0 K S'],
  "clintons": ['K L IH1 N T AH0 N Z'],
  "clintons'": ['K L IH1 N T AH0 N Z'],
  "clio": ['K L IY1 OW0'],
  "clip": ['K L IH1 P'],
  "clipboard": ['K L IH1 P B AO2 R D'],
  "clippard": ['K L IH1 P ER0 D'],
  "clipped": ['K L IH1 P T'],
  "clipper": ['K L IH1 P ER0'],
  "clippers": ['K L IH1 P ER0 Z'],
  "clipping": ['K L IH1 P IH0 NG'],
  "clippinger": ['K L IH1 P IH0 NG ER0'],
  "clippings": ['K L IH1 P IH0 NG Z'],
  "clips": ['K L IH1 P S'],
  "clique": ['K L IY1 K'],
  "cliques": ['K L IH1 K S'],
  "clites": ['K L AY1 T S'],
  "clitoris": ['K L AY0 T AO1 R IH0 S'],
  "clive": ['K L AY1 V'],
  "cliver": ['K L AY1 V ER0'],
  "clo": ['S IY2 EH2 L OW1'],
  "cloak": ['K L OW1 K'],
  "cloaked": ['K L OW1 K T'],
  "cloaking": ['K L OW1 K IH0 NG'],
  "cloakroom": ['K L OW1 K R UW2 M'],
  "clobber": ['K L AA1 B ER0'],
  "clobbered": ['K L AA1 B ER0 D'],
  "clobbering": ['K L AA1 B ER0 IH0 NG'],
  "clock": ['K L AA1 K'],
  "clock's": ['K L AA1 K S'],
  "clocked": ['K L AA1 K T'],
  "clocker": ['K L AA1 K ER0'],
  "clockers": ['K L AA1 K ER0 Z'],
  "clocking": ['K L AA1 K IH0 NG'],
  "clocks": ['K L AA1 K S'],
  "clockwise": ['K L AA1 K W AY2 Z'],
  "clockwork": ['K L AA1 K W ER2 K'],
  "clodfelter": ['K L AA1 D F EH2 L T ER0'],
  "clodoveo": ['K L OW0 D OW1 V IY0 OW0'],
  "cloe": ['K L OW1'],
  "cloer": ['K L OW1 ER0'],
  "cloey": ['K L OW1 IY0'],
  "clog": ['K L AA1 G'],
  "clogged": ['K L AO1 G D'],
  "clogging": ['K L AA1 G IH0 NG', 'K L AO1 G IH0 NG'],
  "clogs": ['K L AA1 G Z'],
  "clogston": ['K L AA1 G S T AH0 N'],
  "cloherty": ['K L AA1 HH ER0 T IY0'],
  "clohessy": ['K L AA1 HH IH0 S IY0'],
  "cloister": ['K L OY1 S T ER0'],
  "cloistered": ['K L OY1 S T ER0 D'],
  "cloisters": ['K L OY1 S T ER0 Z'],
  "cloke": ['K L OW1 K'],
  "clomipramine": ['K L OW1 M IH0 P R AH0 M AY2 N'],
  "clonch": ['K L AA1 N CH'],
  "clone": ['K L OW1 N'],
  "cloned": ['K L OW1 N D'],
  "clones": ['K L OW1 N Z'],
  "clones'": ['K L OW1 N Z'],
  "clonidine": ['K L AA1 N IH0 D IY2 N'],
  "cloning": ['K L OW1 N IH0 NG'],
  "cloninger": ['K L OW1 N IH0 NG ER0'],
  "clonts": ['K L AA1 N T S'],
  "clontz": ['K L AA1 N T S'],
  "cloonan": ['K L UW1 N AH0 N'],
  "clooney": ['K L UW1 N IY0'],
  "clootie": ['K L UW1 T IY0'],
  "clopper": ['K L AA1 P ER0'],
  "clopton": ['K L AA1 P T AH0 N'],
  "clore": ['K L AO1 R'],
  "clore's": ['K L AO1 R Z'],
  "clorinda": ['K L AO0 R IY1 N D AH0'],
  "clorox": ['K L AO1 R AA0 K S'],
  "clos": ['K L AA1 S'],
  "close": ['K L OW1 S', 'K L OW1 Z'],
  "close-up": ['K L OW1 S AH2 P'],
  "closed": ['K L OW1 Z D'],
  "closedown": ['K L OW1 Z D AW2 N'],
  "closedowns": ['K L OW1 Z D AW2 N Z'],
  "closely": ['K L OW1 S L IY0'],
  "closeness": ['K L OW1 S N IH0 S'],
  "closer": ['K L OW1 S ER0', 'K L OW1 Z ER0'],
  "closes": ['K L OW1 Z IH0 Z'],
  "closest": ['K L OW1 S AH0 S T'],
  "closet": ['K L AA1 Z AH0 T'],
  "closeted": ['K L AA1 Z AH0 T IH0 D'],
  "closets": ['K L AA1 Z AH0 T S'],
  "closeup": ['K L OW1 S AH1 P'],
  "closeups": ['K L OW1 S AH1 P S'],
  "closing": ['K L OW1 Z IH0 NG'],
  "closings": ['K L OW1 Z IH0 NG Z'],
  "closs": ['K L AO1 S'],
  "closser": ['K L AO1 S ER0'],
  "closson": ['K L AA1 S AH0 N'],
  "closure": ['K L OW1 ZH ER0'],
  "closures": ['K L OW1 ZH ER0 Z'],
  "clot": ['K L AA1 T'],
  "clotfelter": ['K L AA1 T F EH2 L T ER0'],
  "cloth": ['K L AO1 TH'],
  "clothe": ['K L OW1 DH'],
  "clothed": ['K L OW1 DH D'],
  "clothes": ['K L OW1 DH Z', 'K L OW1 Z'],
  "clotheshorse": ['K L OW1 Z HH AO2 R S'],
  "clothestime": ['K L OW1 DH Z T AY1 M'],
  "clothier": ['K L OW1 DH Y ER0'],
  "clothiers": ['K L OW1 DH Y ER0 Z'],
  "clothilda": ['K L AH0 TH IH1 L D AH0'],
  "clothilde": ['K L AA1 TH IH0 L D'],
  "clothing": ['K L OW1 DH IH0 NG'],
  "cloths": ['K L AO1 TH S'],
  "clotilda": ['K L AH0 T IH1 L D AH0'],
  "clots": ['K L AA1 T S'],
  "clott": ['K L AA1 T'],
  "clotted": ['K L AA1 T AH0 D', 'K L AA1 T IH0 D'],
  "clotting": ['K L AA1 T IH0 NG'],
  "cloture": ['K L OW1 CH ER0'],
  "clouatre": ['K L AW1 AH0 T R'],
  "cloud": ['K L AW1 D'],
  "cloudburst": ['K L AW1 D B ER2 S T'],
  "clouded": ['K L AW1 D IH0 D'],
  "cloudiness": ['K L AW1 D IY0 N IH0 S'],
  "clouding": ['K L AW1 D IH0 NG'],
  "cloudless": ['K L AW1 D L AH0 S'],
  "clouds": ['K L AW1 D Z'],
  "cloudy": ['K L AW1 D IY0'],
  "clough": ['K L AW1'],
  "clougherty": ['K L AW1 ER0 T IY0'],
  "clouse": ['K L AW1 S'],
  "clouser": ['K L AW1 S ER0'],
  "clout": ['K L AW1 T'],
  "clouthier": ['K L AW1 TH IY0 ER0', 'K L OW1 TH IY0 ER0', 'K L OW1 DH IY0 ER0'],
  "cloutier": ['K L AW1 T IY0 ER0'],
  "clove": ['K L OW1 V'],
  "clover": ['K L OW1 V ER0'],
  "cloverleaf": ['K L OW1 V ER0 L IY2 F'],
  "cloves": ['K L OW1 V Z'],
  "clovis": ['K L OW1 V IH0 S'],
  "clow": ['K L OW1'],
  "cloward": ['K L OW1 W ER0 D'],
  "clowdus": ['K L AW1 D IH0 S'],
  "clower": ['K L AW1 ER0'],
  "clowers": ['K L AW1 ER0 Z'],
  "clowes": ['K L AW1 Z'],
  "clown": ['K L AW1 N'],
  "clowney": ['K L AW1 N IY0'],
  "clowning": ['K L AW1 N IH0 NG'],
  "clowns": ['K L AW1 N Z'],
  "cloy": ['K L OY1'],
  "cloyd": ['K L OY1 D'],
  "cloying": ['K L OY1 IH0 NG'],
  "clozapine": ['K L OW1 Z AH0 P AY2 N'],
  "club": ['K L AH1 B'],
  "club's": ['K L AH1 B Z'],
  "clubb": ['K L AH1 B'],
  "clubbed": ['K L AH1 B D'],
  "clubbing": ['K L AH1 B IH0 NG'],
  "clubby": ['K L AH1 B IY0'],
  "clubhouse": ['K L AH1 B HH AW2 S'],
  "clubhouses": ['K L AH1 B HH AW2 S IH0 Z'],
  "clubs": ['K L AH1 B Z'],
  "clucas": ['K L UW1 K AH0 Z'],
  "cluck": ['K L AH1 K'],
  "cluckey": ['K L AH1 K IY0'],
  "clucking": ['K L AH1 K IH0 NG'],
  "clucks": ['K L AH1 K S'],
  "clue": ['K L UW1'],
  "clued": ['K L UW1 D'],
  "clueless": ['K L UW1 L AH0 S'],
  "clues": ['K L UW1 Z'],
  "cluett": ['K L UW1 IH0 T'],
  "cluff": ['K L AH1 F'],
  "clugston": ['K L AH1 G S T AH0 N'],
  "cluj": ['K L UW1 JH', 'S IY1 EH1 L Y UW1 JH EY1'],
  "clukey": ['K L UW1 K IY0'],
  "clum": ['K L AH1 M'],
  "clump": ['K L AH1 M P'],
  "clumping": ['K L AH1 M P IH0 NG'],
  "clumps": ['K L AH1 M P S'],
  "clumpy": ['K L AH1 M P IY0'],
  "clumsily": ['K L AH1 M S AH0 L IY0'],
  "clumsiness": ['K L AH1 M Z IY0 N AH0 S'],
  "clumsy": ['K L AH1 M Z IY0'],
  "clune": ['K L UW1 N'],
  "clung": ['K L AH1 NG'],
  "clunk": ['K L AH1 NG K'],
  "clunker": ['K L AH1 NG K ER0'],
  "clunkers": ['K L AH1 NG K ER0 Z'],
  "clunks": ['K L AH1 NG K S'],
  "clunky": ['K L AH1 NG K IY0'],
  "cluny": ['K L UW1 N IY0'],
  "cluster": ['K L AH1 S T ER0'],
  "clustered": ['K L AH1 S T ER0 D'],
  "clustering": ['K L AH1 S T ER0 IH0 NG'],
  "clusters": ['K L AH1 S T ER0 Z'],
  "clutch": ['K L AH1 CH'],
  "clutched": ['K L AH1 CH T'],
  "clutches": ['K L AH1 CH AH0 Z', 'K L AH1 CH IH0 Z'],
  "clutching": ['K L AH1 CH IH0 NG'],
  "clute": ['K L UW1 T'],
  "clutter": ['K L AH1 T ER0'],
  "cluttered": ['K L AH1 T ER0 D'],
  "cluttering": ['K L AH1 T ER0 IH0 NG'],
  "clutts": ['K L AH1 T S'],
  "clyatt": ['K L AY1 AH0 T'],
  "clyburn": ['K L IH1 B ER0 N'],
  "clyde": ['K L AY1 D'],
  "clydesdale": ['K L AY1 D Z D EY2 L'],
  "clymene": ['K L IH0 M IY1 N'],
  "clymer": ['K L AY1 M ER0'],
  "clyne": ['K L AY1 N'],
  "clyte": ['K L AY1 T'],
  "clytie": ['K L IH1 T IY0'],
  "clyve": ['K L AY1 V'],
  "cmos": ['S IY1 M OW0 S', 'S IY1 EH1 M OW1 EH1 S'],
  "cmu": ['S IY1 EH1 M Y UW1'],
  "cmudict": ['S IY2 EH2 M Y UW1 D IH2 K T'],
  "cmx": ['K AH0 M EH1 K S'],
  "cnbc's": ['S IY1 EH1 N B IY1 S IY1 Z'],
  "cnet": ['S IY1 N EH2 T'],
  "cnn": ['S IY1 EH1 N EH1 N'],
  "cnn's": ['S IY1 EH1 N EH1 N Z'],
  "cnn.com": ['S IY1 EH1 N EH1 N D AA1 T K AA1 M'],
  "cnnfn": ['S IY1 EH1 N EH1 N EH1 F EH1 N'],
  "co": ['K OW1'],
  "co-founder": ['K OW1 F AW1 N D ER0'],
  "co-leader": ['K OW0 L IY1 D ER0'],
  "co-leaders": ['K OW0 L IY1 D ER0 Z'],
  "co-op": ['K OW1 AA2 P'],
  "co-operative": ['K OW2 AA1 P ER2 AH0 T IH0 V', 'K OW2 AA1 P R AH0 T IH0 V'],
  "co-opt": ['K OW0 AA1 P T'],
  "co-opted": ['K OW0 AA1 P T AH0 D'],
  "co-owner": ['K OW1 OW2 N ER0'],
  "co-wife": ['K OW1 W AY1 F'],
  "co-worker": ['K OW1 W ER1 K ER0'],
  "co-workers": ['K OW1 W ER1 K ER0 Z'],
  "co.": ['K OW1', 'K AH1 P AH0 N IY0'],
  "coach": ['K OW1 CH'],
  "coach's": ['K OW1 CH IH0 Z'],
  "coached": ['K OW1 CH T'],
  "coaches": ['K OW1 CH IH0 Z'],
  "coaching": ['K OW1 CH IH0 NG'],
  "coachman": ['K OW1 CH M AH0 N'],
  "coad": ['K OW1 D'],
  "coady": ['K OW1 D IY0'],
  "coagulate": ['K OW0 AE1 G Y AH0 L EY2 T'],
  "coagulating": ['K OW0 AE1 G Y AH0 L EY2 T IH0 NG'],
  "coagulation": ['K OW0 AE1 G Y AH0 L EY1 SH AH0 N'],
  "coakley": ['K OW1 K L IY0'],
  "coal": ['K OW1 L'],
  "coal's": ['K OW1 L Z'],
  "coale": ['K OW1 L'],
  "coalesce": ['K OW2 AH0 L EH1 S'],
  "coalesced": ['K OW2 AH0 L EH1 S T'],
  "coalescence": ['K OW2 AH0 L EH1 S AH0 N S'],
  "coalesces": ['K OW2 AH0 L EH1 S IH0 Z'],
  "coalescing": ['K OW2 AH0 L EH1 S IH0 NG'],
  "coalition": ['K OW2 AH0 L IH1 SH AH0 N'],
  "coalition's": ['K OW2 AH0 L IH1 SH AH0 N Z'],
  "coalitions": ['K OW2 AH0 L IH1 SH AH0 N Z'],
  "coals": ['K OW1 L Z'],
  "coalson": ['K OW1 L S AH0 N'],
  "coan": ['K OW1 N'],
  "coar": ['K AO1 R'],
  "coarse": ['K AO1 R S'],
  "coarsening": ['K AO1 R S IH0 N IH0 NG'],
  "coarser": ['K AO1 R S ER0'],
  "coarticulate": ['K OW2 AA0 R T IH1 K Y UW0 L EY0 T'],
  "coarticulated": ['K OW2 AA0 R T IH1 K Y UW0 L EY0 T AH0 D'],
  "coarticulates": ['K OW2 AA0 R T IH1 K Y UW0 L EY0 T S'],
  "coarticulating": ['K OW2 AA0 R T IH1 K Y UW0 L EY0 T IH0 NG'],
  "coarticulation": ['K OW2 AA0 R T IH1 K Y UW0 L EY0 SH AH0 N'],
  "coast": ['K OW1 S T'],
  "coast's": ['K OW1 S T S'],
  "coastal": ['K OW1 S T AH0 L'],
  "coastal's": ['K OW1 S T AH0 L Z'],
  "coastamerica": ['K OW2 S T AH0 M EH1 R IH0 K AH0'],
  "coastamerica's": ['K OW2 S T AH0 M EH1 R IH0 K AH0 Z'],
  "coasted": ['K OW1 S T IH0 D'],
  "coaster": ['K OW1 S T ER0'],
  "coasters": ['K OW1 S T ER0 Z'],
  "coasting": ['K OW1 S T IH0 NG'],
  "coastline": ['K OW1 S T L AY2 N'],
  "coastlines": ['K OW1 S T L AY2 N Z'],
  "coasts": ['K OW1 S T S'],
  "coat": ['K OW1 T'],
  "coat's": ['K OW1 T S'],
  "coate": ['K OW1 EY1 T'],
  "coated": ['K OW1 T AH0 D', 'K OW1 T IH0 D'],
  "coates": ['K OW1 EY1 T S'],
  "coatesville": ['K OW1 T S V IH2 L'],
  "coating": ['K OW1 T IH0 NG'],
  "coatings": ['K OW1 T IH0 NG Z'],
  "coatney": ['K OW1 T N IY0'],
  "coats": ['K OW1 T S'],
  "coattail": ['K OW1 T T EY2 L'],
  "coattails": ['K OW1 T T EY2 L Z'],
  "coauthor": ['K OW1 AA1 TH ER0'],
  "coauthored": ['K OW1 AA1 TH ER0 D'],
  "coauthoring": ['K OW1 AA1 TH ER0 IH0 NG'],
  "coauthors": ['K OW1 AA1 TH ER0 Z'],
  "coax": ['K OW1 K S'],
  "coaxed": ['K OW1 K S T'],
  "coaxes": ['K OW1 K S IH0 Z'],
  "coaxial": ['K OW1 AE1 K S IY0 AH0 L'],
  "coaxing": ['K OW1 K S IH0 NG'],
  "coaxum": ['K OW1 K S AH0 M'],
  "cob": ['K AA1 B'],
  "cobain": ['K OW1 B EY2 N'],
  "cobain's": ['K OW1 B EY2 N Z'],
  "cobaine": ['K OW1 B EY2 N'],
  "cobalt": ['K OW1 B AO2 L T'],
  "cobaugh": ['K AA1 B AO0'],
  "cobb": ['K AA1 B'],
  "cobbett": ['K AA1 B IH0 T'],
  "cobbins": ['K AA1 B IH0 N Z'],
  "cobble": ['K AA1 B AH0 L'],
  "cobbled": ['K AA1 B AH0 L D'],
  "cobbler": ['K AA1 B L ER0'],
  "cobbler's": ['K AA1 B L ER0 Z'],
  "cobblers": ['K AA1 B L ER0 Z'],
  "cobblestone": ['K AA1 B AH0 L S T OW2 N'],
  "cobblestones": ['K AA1 B AH0 L S T OW2 N Z'],
  "cobbs": ['K AA1 B Z'],
  "cobe": ['K OW1 B'],
  "coben": ['K OW1 B AH0 N'],
  "cobepa": ['K OW0 B EY1 P AH0'],
  "coberly": ['K OW1 B ER0 L IY0'],
  "cobern": ['K AA1 B ER0 N'],
  "cobert": ['K AA1 B ER0 T'],
  "cobey": ['K OW1 B IY0'],
  "cobia": ['K OW1 B IY0 AH0'],
  "cobian": ['K OW1 B IY0 AH0 N'],
  "cobin": ['K OW1 B IH0 N'],
  "coble": ['K OW1 B AH0 L'],
  "cobleigh": ['K AA1 B L AH0'],
  "coblentz": ['K AA1 B L IH0 N T S'],
  "cobler": ['K OW1 B AH0 L ER0', 'K OW1 B L ER0'],
  "cobliner": ['K AA1 B L AY0 N ER0'],
  "cobo": ['K OW1 B OW0'],
  "cobol": ['K OW1 B AO0 L'],
  "cobos": ['K OW1 B OW0 Z'],
  "cobra": ['K OW1 B R AH0'],
  "cobras": ['K OW1 B R AH0 Z'],
  "cobre": ['K AA1 B R AH0'],
  "cobrin": ['K AA1 B R IH0 N'],
  "cobs": ['K AA1 B Z'],
  "coburn": ['K OW1 B ER0 N'],
  "cobweb": ['K AA1 B W EH2 B'],
  "cobwebs": ['K AA1 B W EH2 B Z'],
  "coby": ['K OW1 B IY0'],
  "coca": ['K OW1 K AH0'],
  "coca-cola": ['K OW2 K AH0 K OW1 L AA0'],
  "cocaine": ['K OW0 K EY1 N'],
  "cocanino": ['K OW2 K AH0 N IY1 N OW0'],
  "cocanougher": ['K AA1 K AH0 N AH2 F ER0'],
  "cocca": ['K OW1 K AH0'],
  "coccaro": ['K OW0 K AA1 R OW0'],
  "cocchi": ['K OW1 K IY0'],
  "coccia": ['K OW1 CH AH0'],
  "cocco": ['K OW1 K OW0'],
  "coccus": ['K AA1 K AH0 S'],
  "cochairman": ['K OW1 CH EH2 R M AH0 N'],
  "cochenour": ['K AA1 SH IH0 N UH0 R'],
  "cochin": ['K OW1 CH IH0 N'],
  "cochlea": ['K AA1 K L IY0 AH0'],
  "cochlear": ['K AA1 K L IY0 ER0'],
  "cochles": ['K AA1 K AH0 L Z'],
  "cochran": ['K AA1 K R AH0 N'],
  "cochran's": ['K AA1 K R AH0 N Z'],
  "cochrane": ['K AA1 K R AH0 N'],
  "cochranton": ['K AA1 K R AH0 N T AH0 N'],
  "cock": ['K AA1 K'],
  "cockamamie": ['K AO2 K AH0 M EY1 M IY0'],
  "cockatoo": ['K AA1 K AH0 T UW2'],
  "cockatoos": ['K AA1 K AH0 T UW2 Z'],
  "cockburn": ['K AA1 K B ER2 N'],
  "cockburn's": ['K OW1 B ER0 N Z', 'K AA1 K B ER2 N Z'],
  "cocke": ['K OW1 K'],
  "cocked": ['K AA1 K T', 'K AO1 K T'],
  "cocker": ['K AA1 K ER0'],
  "cockerell": ['K AA1 K ER0 EH2 L'],
  "cockerham": ['K AA1 K ER0 HH AE2 M'],
  "cockerill": ['K AA1 K ER0 IH2 L'],
  "cockey": ['K AA1 K IY0'],
  "cockeyed": ['K AA1 K AY2 D'],
  "cockfield": ['K AA1 K F IY2 L D'],
  "cockiness": ['K AA1 K IY0 N AH0 S'],
  "cocking": ['K AA1 K IH0 NG'],
  "cockle": ['K AA1 K AH0 L'],
  "cockles": ['K AA1 K AH0 L Z'],
  "cocklin": ['K AA1 K L IH0 N'],
  "cockman": ['K AA1 K M AH0 N'],
  "cockney": ['K AA1 K N IY0'],
  "cockpit": ['K AA1 K P IH2 T'],
  "cockpits": ['K AA1 K P IH2 T S'],
  "cockran": ['K AA1 K R AH0 N'],
  "cockrell": ['K AA1 K R AH0 L'],
  "cockrill": ['K AA1 K R AH0 L'],
  "cockroach": ['K AA1 K R OW2 CH'],
  "cockroaches": ['K AA1 K R OW2 CH IH0 Z'],
  "cockroft": ['K AA1 K R AH0 F T'],
  "cockrum": ['K AA1 K R AH0 M'],
  "cocks": ['K AA1 K S'],
  "cocksucker": ['K AA1 K S AH2 K ER0'],
  "cocktail": ['K AA1 K T EY2 L'],
  "cocktails": ['K AA1 K T EY2 L Z'],
  "cockwell": ['K AA1 K W EH2 L'],
  "cockwell's": ['K AA1 K W EH2 L Z'],
  "cocky": ['K AA1 K IY0'],
  "coco": ['K OW1 K OW2'],
  "cocoa": ['K OW1 K OW0'],
  "cocoanuts": ['K OW1 K OW0 N AH2 T S'],
  "cocom": ['K OW1 K AA1 M'],
  "coconino": ['K OW2 K AH0 N IY1 N OW0'],
  "coconut": ['K OW1 K AH0 N AH2 T'],
  "coconuts": ['K OW1 K AH0 N AH2 T S'],
  "cocoon": ['K AH0 K UW1 N'],
  "cocooning": ['K AH0 K UW1 N IH0 NG'],
  "cocoons": ['K AH0 K UW1 N Z'],
  "cocos": ['K OW1 K OW2 Z'],
  "cocozza": ['K OW0 K OW1 T S AH0'],
  "cocuzza": ['K OW0 K UW1 T S AH0'],
  "cod": ['K AA1 D', 'S IY1 OW1 D IY1'],
  "coda": ['K OW1 D AH0'],
  "codag": ['K OW1 D AE1 G'],
  "coday": ['K OW1 D EY1'],
  "codd": ['K AA1 D'],
  "codding": ['K AA1 D IH0 NG'],
  "coddington": ['K AA1 D IH0 NG T AH0 N'],
  "coddle": ['K AA1 D AH0 L'],
  "coddled": ['K AA1 D AH0 L D'],
  "coddling": ['K AA1 D AH0 L IH0 NG', 'K AA1 D L IH0 NG'],
  "code": ['K OW1 D'],
  "code's": ['K OW1 D Z'],
  "codebase": ['K OW1 D B EY2 S'],
  "codebreaker": ['K OW1 D B R EY2 K ER0'],
  "codebreakers": ['K OW1 D B R EY2 K ER0 Z'],
  "coded": ['K OW1 D IH0 D'],
  "codelco": ['K OW0 D EH1 L K OW0'],
  "coder": ['K OW1 D ER0'],
  "coderre": ['K AH0 D EH1 R'],
  "codes": ['K OW1 D Z'],
  "codesa": ['K OW0 D EH1 S AH0'],
  "codification": ['K AA2 D AH0 F AH0 K EY1 SH AH0 N'],
  "codified": ['K AA1 D AH0 F AY2 D'],
  "codifies": ['K OW1 D AH0 F AY2 Z'],
  "codify": ['K OW1 D AH0 F AY2'],
  "codifying": ['K OW1 D AH0 F AY2 IH0 NG'],
  "coding": ['K OW1 D IH0 NG'],
  "codispoti": ['K OW0 D IY0 S P OW1 T IY0'],
  "codlin": ['K AA1 D L IH0 N'],
  "codner": ['K AA1 D N ER0'],
  "codrescu": ['K AH0 D R EH1 S K Y UW2'],
  "codrescu's": ['K AH0 D R EH1 S K Y UW2 Z'],
  "cody": ['K OW1 D IY0'],
  "coe": ['K OW1'],
  "coeburn": ['K OW1 B ER0 N'],
  "coed": ['K OW1 EH2 D', 'K OW1 D'],
  "coeds": ['K OW1 EH2 D Z'],
  "coeducational": ['K OW1 EH1 JH AH0 K EY1 SH AH0 N AH0 L'],
  "coefficient": ['K OW2 AH0 F IH1 SH AH0 N T'],
  "coefficients": ['K OW2 AH0 F IH1 SH AH0 N T S'],
  "coehlo": ['K OW1 L OW0'],
  "coelacanth": ['S IY1 L AH0 K AE2 N TH'],
  "coelho": ['K OW2 EH1 L OW0'],
  "coello": ['K OW2 EH1 L OW0'],
  "coen": ['K OW1 IH0 N'],
  "coenen": ['K OW0 IY1 N AH0 N'],
  "coenzyme": ['K OW0 EH1 N Z AY0 M'],
  "coequal": ['K OW0 IY1 K W AH0 L'],
  "coerce": ['K OW0 ER1 S'],
  "coerced": ['K OW0 ER1 S T'],
  "coercing": ['K OW0 ER1 S IH0 NG'],
  "coercion": ['K OW0 ER1 SH AH0 N'],
  "coercive": ['K OW0 ER1 S IH0 V'],
  "coeur": ['K UW1 R'],
  "coexist": ['K OW2 AH0 G Z IH1 S T'],
  "coexisted": ['K OW2 AH0 G Z IH1 S T AH0 D'],
  "coexistence": ['K OW2 IH0 G Z IH1 S T AH0 N S'],
  "coexisting": ['K OW2 IH0 G Z IH1 S T IH0 NG'],
  "coey": ['K OW1 IY0'],
  "cofer": ['K OW1 F ER1'],
  "coffaro": ['K OW0 F AA1 R OW0'],
  "coffee": ['K AA1 F IY0', 'K AO1 F IY0'],
  "coffee's": ['K AA1 F IY0 Z', 'K AO1 F IY0 Z'],
  "coffeehouse": ['K AO1 F IY0 HH AW2 S'],
  "coffeehouses": ['K AO1 F IY0 HH AW2 S IH0 Z'],
  "coffeen": ['K AH0 F IY1 N'],
  "coffees": ['K AO1 F IY0 Z'],
  "coffel": ['K AA1 F AH0 L'],
  "coffelt": ['K AA1 F IH0 L T'],
  "coffer": ['K AO1 F ER0'],
  "coffers": ['K AA1 F ER0 Z', 'K AO1 F ER0 Z'],
  "coffey": ['K AA1 F IY0'],
  "coffield": ['K AA1 F IY0 L D'],
  "coffin": ['K AO1 F IH0 N'],
  "coffing": ['K AO1 F IH0 NG'],
  "coffins": ['K AO1 F IH0 N Z'],
  "cofflin": ['K AO1 F L IH0 N'],
  "coffman": ['K AO1 F M AH0 N'],
  "cofide": ['K OW2 F AY1 D'],
  "cofield": ['K OW1 F IY1 L D'],
  "cofounder": ['K OW1 F AW1 N D ER0'],
  "cog": ['K AO1 G'],
  "cogan": ['K OW1 G AH0 N'],
  "cogar": ['K OW1 G ER0'],
  "cogbill": ['K AA1 G B IH2 L'],
  "cogburn": ['K AA1 G B ER2 N'],
  "cogdell": ['K AA1 G D AH0 L'],
  "cogdill": ['K AA1 G D AH0 L'],
  "cogeco": ['K OW2 JH EH1 K OW0'],
  "cogema": ['K OW1 G M AA0'],
  "cogency": ['K OW1 JH AH0 N S IY0'],
  "cogenerate": ['K OW1 JH EH1 N ER0 EY2 T'],
  "cogenerated": ['K OW1 JH EH1 N ER0 EY2 T IH0 D'],
  "cogeneration": ['K OW1 JH EH1 N ER0 EY1 SH AH0 N'],
  "cogenerator": ['K OW0 JH EH1 N ER0 EY2 T ER0'],
  "cogenerators": ['K OW0 JH EH1 N ER0 EY2 T ER0 Z'],
  "cogent": ['K OW1 JH AH0 N T'],
  "coger": ['K OW1 JH ER0'],
  "coggeshall": ['K AA1 G IH0 SH AO0 L'],
  "coggin": ['K AA1 G IH0 N'],
  "coggins": ['K AA1 G IH0 N Z'],
  "coghill": ['K AA1 G HH IH2 L'],
  "coghlan": ['K AA1 G L AH0 N'],
  "cogitate": ['K AA1 JH IH0 T EY2 T'],
  "cogitation": ['K AA2 JH IH0 T EY1 SH AH0 N'],
  "cogley": ['K AA1 G L IY0'],
  "coglianese": ['K OW0 G L IY0 AH0 N EY1 Z IY0'],
  "cogliano": ['K OW0 G L IY0 AA1 N OW0'],
  "cognac": ['K OW1 N Y AE2 K', 'K AA1 N Y AE2 K'],
  "cognetics": ['K AA2 G N EH1 T IH0 K S'],
  "cognex": ['K AA1 G N EH0 K S'],
  "cognition": ['K AA0 G N IH1 SH AH0 N'],
  "cognitive": ['K AA1 G N IH0 T IH0 V'],
  "cognizance": ['K AA1 G N AH0 Z AH0 N S'],
  "cognizant": ['K AA1 G N AH0 Z AH0 N T'],
  "cognoscenti": ['K AA2 G N AO0 SH EH1 N T IY2'],
  "cogswell": ['K AA1 G S W EH2 L'],
  "cohabit": ['K OW0 HH AE1 B IH0 T'],
  "cohabitation": ['K OW0 HH AE2 B AH0 T EY1 SH AH0 N'],
  "cohabiting": ['K OW0 HH AE1 B IH0 T IH0 NG'],
  "cohan": ['K OW1 HH AH0 N'],
  "cohasset": ['K OW0 HH AE1 S AH0 T'],
  "cohea": ['K AA1 HH IY0 AH0'],
  "cohee": ['K AA1 HH IY0'],
  "cohen": ['K OW1 AH0 N'],
  "cohen's": ['K OW1 AH0 N Z'],
  "cohenour": ['K AH0 HH EH1 N ER0'],
  "cohentannoudji": ['K OW2 AH0 N T AH0 N UW1 JH IY0'],
  "cohere": ['K OW0 HH IH1 R'],
  "cohered": ['K OW0 HH IH1 R D'],
  "coherence": ['K OW0 HH IH1 R AH0 N S'],
  "coherent": ['K OW0 HH IH1 R AH0 N T'],
  "coherently": ['K OW0 HH IY1 R AH0 N T L IY0'],
  "coheres": ['K OW0 HH IH1 R Z'],
  "cohesion": ['K OW0 HH IY1 ZH AH0 N'],
  "cohesive": ['K OW0 HH IY1 S IH0 V'],
  "cohesively": ['K OW0 HH IY1 S IH0 V L IY0'],
  "cohesiveness": ['K OW0 HH IY1 S IH0 V N AH0 S'],
  "cohiba": ['K OW0 HH IY1 B AH0'],
  "cohick": ['K AA1 HH IH0 K'],
  "cohill": ['K OW1 HH IH1 L'],
  "cohn": ['K OW1 N'],
  "coho": ['K OW1 HH OW0'],
  "cohoon": ['K AH0 HH UW1 N'],
  "cohort": ['K OW1 HH AO0 R T'],
  "cohorts": ['K OW1 HH AO0 R T S'],
  "cohost": ['K OW1 HH OW2 S T'],
  "cohosts": ['K OW1 HH OW2 S T S'],
  "cohr": ['K AO1 R'],
  "cohron": ['K AA1 R AH0 N'],
  "cohrs": ['K AO1 R Z'],
  "coia": ['K OW1 Y AH0'],
  "coif": ['K W AA1 F'],
  "coiffe": ['K OY1 F'],
  "coiffed": ['K OY1 F T'],
  "coil": ['K OY1 L'],
  "coile": ['K OY1 L'],
  "coiled": ['K OY1 L D'],
  "coiling": ['K OY1 L IH2 NG'],
  "coils": ['K OY1 L Z'],
  "coin": ['K OY1 N'],
  "coin's": ['K OY1 N Z'],
  "coinage": ['K OY1 N IH0 JH'],
  "coincide": ['K OW2 IH0 N S AY1 D'],
  "coincided": ['K OW2 AH0 N S AY1 D AH0 D'],
  "coincidence": ['K OW0 IH1 N S IH0 D AH0 N S'],
  "coincidences": ['K OW0 IH1 N S AH0 D AH0 N S IH0 Z'],
  "coincident": ['K OW0 IH1 N S AH0 D AH0 N T'],
  "coincidental": ['K OW0 IH2 N S AH0 D EH1 N T AH0 L'],
  "coincidentally": ['K OW0 IH2 N S IH0 D EH1 N T AH0 L IY0', 'K OW0 IH2 N S IH0 D EH1 N AH0 L IY0'],
  "coincides": ['K OW2 IH0 N S AY1 D Z'],
  "coinciding": ['K OW2 AH0 N S AY1 D IH0 NG'],
  "coined": ['K OY1 N D'],
  "coiner": ['K OY1 N ER0'],
  "coining": ['K OY1 N IH0 NG'],
  "coins": ['K OY1 N Z'],
  "coinsurance": ['K OW2 IH0 N SH ER1 AH0 N S'],
  "cointreau": ['K AO2 N T R OW1'],
  "coipa": ['K OY1 P AH0'],
  "coiro": ['K OY1 R OW0'],
  "coit": ['K OY1 T'],
  "coitsville": ['K OY1 T S V IH0 L'],
  "coitus": ['K OY1 T AH0 S'],
  "cojimar": ['K OW1 JH IH0 M AA2 R'],
  "cojimar's": ['K OW1 JH IH0 M AA2 R Z'],
  "cojuangco": ['K OW0 W AA1 NG K OW0', 'K OW0 JH W AE1 NG K OW0'],
  "coke": ['K OW1 K'],
  "coke's": ['K OW1 K S'],
  "coker": ['K OW1 K ER0'],
  "cokes": ['K OW1 K S'],
  "cokie": ['K OW1 K IY0'],
  "cokie's": ['K OW1 K IY0 Z'],
  "coking": ['K OW1 K IH0 NG'],
  "cokley": ['K AA1 K L IY0'],
  "cola": ['K OW1 L AH0'],
  "cola's": ['K OW1 L AH0 Z'],
  "colab": ['K OW1 L AE1 B'],
  "colabella": ['K OW2 L AH0 B EH1 L AH0'],
  "colaianni": ['K OW0 L AA0 Y AA1 N IY0'],
  "colaizzi": ['K OW2 L EY1 Z IY0'],
  "colaluca": ['K OW2 L AH0 L UW1 K AH0'],
  "colan": ['K OW1 L AH0 N'],
  "colander": ['K AA1 L AH0 N D ER0'],
  "colanders": ['K AA1 L AH0 N D ER0 Z'],
  "colangelo": ['K OW0 L AA0 NG G EH1 L OW0'],
  "colantonio": ['K OW0 L AA0 N T OW1 N IY0 OW0'],
  "colantuono": ['K OW0 L AA0 N T W OW1 N OW0'],
  "colao": ['K OW1 L AW0'],
  "colarusso": ['K OW0 L AA0 R UW1 S OW0'],
  "colas": ['K OW1 L AH0 S'],
  "colasanti": ['K OW2 L AH0 S AE1 N T IY0'],
  "colasurdo": ['K OW0 L AA0 S UH1 R D OW0'],
  "colavito": ['K OW0 L AA0 V IY1 T OW0'],
  "colaw": ['K OW1 L AO1'],
  "colbath": ['K OW1 L B AH0 TH'],
  "colbaugh": ['K OW1 L B AO2'],
  "colbeck": ['K AA1 L B EH0 K'],
  "colberg": ['K AA1 L B ER0 G'],
  "colbern": ['K OW1 L B ER0 N'],
  "colbert": ['K OW1 L B ER0 T'],
  "colborn": ['K OW1 L B AO0 R N'],
  "colborne": ['K OW1 L B AO0 R N'],
  "colburn": ['K OW1 L B ER0 N'],
  "colby": ['K OW1 L B IY0'],
  "colby's": ['K OW1 L B IY0 Z'],
  "colbys": ['K OW1 L B IY0 Z'],
  "colchicine": ['K AO1 L CH AH0 S IY0 N'],
  "colclasure": ['K OW0 L K L AA1 ZH ER0'],
  "colclough": ['K OW1 L K L AW0'],
  "colcord": ['K OW1 L K ER0 D'],
  "cold": ['K OW1 L D'],
  "cold-bay": ['K OW1 L D B EY2'],
  "cold-blood": ['K OW1 L D B L AH1 D'],
  "cold-blooded": ['K OW1 L D B L AH1 D AH0 D'],
  "colden": ['K OW1 L D AH0 N'],
  "colder": ['K OW1 L D ER0'],
  "coldest": ['K OW1 L D AH0 S T'],
  "coldiron": ['K OW1 L D ER0 AA0 N'],
  "coldly": ['K OW1 L D L IY0'],
  "coldness": ['K OW1 L D N AH0 S'],
  "coldren": ['K OW1 L D ER0 AH0 N'],
  "colds": ['K OW1 L D Z'],
  "coldwater": ['K OW1 L D W AO2 T ER0'],
  "coldwell": ['K OW1 L D W EH2 L'],
  "cole": ['K OW1 L'],
  "cole's": ['K OW1 L Z'],
  "colebank": ['K OW1 L B AE2 NG K'],
  "colebreath": ['K OW1 L B R EH0 TH'],
  "colebrook": ['K OW1 L B R UH2 K'],
  "coleco": ['K OW2 L EH1 K OW0'],
  "coleco's": ['K OW2 L EH1 K OW0 Z'],
  "colee": ['K OW1 L IY1'],
  "coleen": ['K AO0 L IY1 N'],
  "colegrove": ['K OW1 L G R OW2 V'],
  "colella": ['K OW2 L EH1 L AH0'],
  "colello": ['K OW2 L EH1 L OW0'],
  "coleman": ['K OW1 L M AH0 N'],
  "coleman's": ['K OW1 L M AH0 N Z'],
  "colen": ['K OW1 L AH0 N'],
  "coler": ['K OW1 L ER0'],
  "coleridge": ['K OW1 L R IH0 JH'],
  "coles": ['K OW1 L Z'],
  "coleslaw": ['K OW1 L S L AA2'],
  "coleson": ['K AA1 L IH0 S AH0 N', 'K OW1 L S AH0 N'],
  "colestipol": ['K OW1 L S T IH2 P AA2 L'],
  "colestock": ['K OW1 L S T AA2 K'],
  "coletta": ['K OW0 L EH1 T AH0'],
  "colette": ['K OW1 L EH1 T'],
  "coletti": ['K OW0 L EH1 T IY0'],
  "coleus": ['K OW1 L IY0 AH0 S'],
  "coleville": ['K OW1 L V IH2 L'],
  "coley": ['K OW1 L IY0'],
  "colfer": ['K OW1 L F ER0'],
  "colford": ['K OW1 L F ER0 D'],
  "colgan": ['K OW1 L G AH0 N'],
  "colgate": ['K OW1 L G EY0 T'],
  "colgate's": ['K OW1 L G EY0 T S'],
  "colgin": ['K OW1 L JH IH0 N'],
  "colglazier": ['K OW1 L G L AH0 Z IY0 ER0'],
  "colgrove": ['K OW1 L G R AH0 V'],
  "coli": ['K OW1 L IY0'],
  "colicchio": ['K OW2 L IH1 K IY0 OW0'],
  "colier": ['K OW1 L IY0 ER0'],
  "coliform": ['K OW1 L AH0 F AO2 R M', 'K AO1 L AH0 F AO2 R M'],
  "coliforms": ['K OW1 L AH0 F AO2 R M Z', 'K AO1 L AH0 F AO2 R M Z'],
  "colin": ['K OW1 L IH0 N'],
  "colina": ['K OW0 L IY1 N AH0'],
  "colinas": ['K OW0 L IY1 N AH0 S'],
  "coline": ['K OW0 L IY1 N IY0'],
  "colino": ['K OW0 L IY1 N OW0'],
  "colis": ['K OW1 L IH0 S'],
  "coliseum": ['K AA2 L AH0 S IY1 AH0 M'],
  "coll": ['K AA1 L'],
  "colla": ['K OW1 L AH0'],
  "collaborate": ['K AH0 L AE1 B ER0 EY2 T'],
  "collaborated": ['K AH0 L AE1 B ER0 EY2 T IH0 D'],
  "collaborating": ['K AH0 L AE1 B ER0 EY2 T IH0 NG'],
  "collaboration": ['K AH0 L AE2 B ER0 EY1 SH AH0 N'],
  "collaborationist": ['K AH0 L AE2 B ER0 EY1 SH AH0 N IH0 S T'],
  "collaborations": ['K AA2 L AH0 B ER0 EY1 SH AH0 N Z'],
  "collaborative": ['K AH0 L AE1 B ER0 EY2 T IH0 V', 'K AH0 L AE1 B R AH0 T IH0 V'],
  "collaborator": ['K AH0 L AE1 B ER0 EY2 T ER0'],
  "collaborators": ['K AH0 L AE1 B ER0 EY2 T ER0 Z'],
  "collado": ['K OW0 L AA1 D OW0'],
  "collage": ['K AH0 L AA1 ZH'],
  "collagen": ['K AA1 L AH0 G AH0 N'],
  "collages": ['K AH0 L AA1 ZH IH0 Z'],
  "collamore": ['K OW0 L AA1 M AO0 R'],
  "collapse": ['K AH0 L AE1 P S'],
  "collapsed": ['K AH0 L AE1 P S T'],
  "collapses": ['K AH0 L AE1 P S IH0 Z'],
  "collapsible": ['K AH0 L AE1 P S AH0 B AH0 L'],
  "collapsing": ['K AH0 L AE1 P S IH0 NG'],
  "collar": ['K AA1 L ER0'],
  "collarbone": ['K AA1 L ER0 B OW2 N'],
  "collard": ['K AA1 L ER0 D'],
  "collards": ['K AA1 L ER0 D Z'],
  "collared": ['K AA1 L ER0 D'],
  "collars": ['K AA1 L ER0 Z'],
  "collate": ['K AH0 L EY1 T'],
  "collateral": ['K AH0 L AE1 T ER0 AH0 L'],
  "collateralize": ['K AH0 L AE1 T ER0 AH0 L AY2 Z'],
  "collateralized": ['K AH0 L AE1 T ER0 AH0 L AY2 Z D'],
  "collazo": ['K OW0 L AA1 Z OW0'],
  "colle": ['K OW1 L'],
  "colleague": ['K AA1 L IY0 G'],
  "colleague's": ['K AA1 L IY0 G Z'],
  "colleagues": ['K AA1 L IY0 G Z'],
  "colleagues'": ['K AA1 L IY0 G Z'],
  "collect": ['K AH0 L EH1 K T'],
  "collected": ['K AH0 L EH1 K T AH0 D'],
  "collectibility": ['K AH0 L EH2 K T IH0 B IH1 L IH0 T IY0'],
  "collectible": ['K AH0 L EH1 K T AH0 B AH0 L'],
  "collectibles": ['K AH0 L EH1 K T AH0 B AH0 L Z'],
  "collecting": ['K AH0 L EH1 K T IH0 NG'],
  "collection": ['K AH0 L EH1 K SH AH0 N'],
  "collections": ['K AH0 L EH1 K SH AH0 N Z'],
  "collective": ['K AH0 L EH1 K T IH0 V'],
  "collectively": ['K AH0 L EH1 K T IH0 V L IY0'],
  "collectives": ['K AH0 L EH1 K T IH0 V Z'],
  "collectivism": ['K AH0 L EH1 K T IH0 V IH2 Z AH0 M'],
  "collectivist": ['K AH0 L EH1 K T IH0 V IH0 S T'],
  "collectivization": ['K AH0 L EH2 K T IH0 V IH0 Z EY1 SH AH0 N'],
  "collectivize": ['K AH0 L EH1 K T IH0 V AY2 Z'],
  "collectivized": ['K AH0 L EH1 K T IH0 V AY2 Z D'],
  "collector": ['K AH0 L EH1 K T ER0'],
  "collector's": ['K AH0 L EH1 K T ER0 Z'],
  "collectors": ['K AH0 L EH1 K T ER0 Z'],
  "collectors'": ['K AH0 L EH1 K T ER0 Z'],
  "collects": ['K AH0 L EH1 K T S'],
  "colledge": ['K AA1 L IH0 JH'],
  "colleen": ['K AA2 L IY1 N'],
  "college": ['K AA1 L IH0 JH'],
  "college's": ['K AA1 L IH0 JH IH0 Z'],
  "colleges": ['K AA1 L IH0 JH IH0 Z'],
  "colleges'": ['K AA1 L IH0 JH IH0 Z'],
  "collegeville": ['K AA1 L AH0 JH V IH0 L'],
  "collegial": ['K AH0 L IY1 JH IY0 AH0 L'],
  "collegiality": ['K AH0 L IY2 JH IY0 AE1 L IH0 T IY0'],
  "collegian": ['K AH0 L IY1 JH AH0 N'],
  "collegians": ['K AH0 L IY1 JH AH0 N Z'],
  "collegiate": ['K AH0 L IY1 JH IH0 T'],
  "collen": ['K AA1 L AH0 N'],
  "collender": ['K AA1 L AH0 N D ER0'],
  "coller": ['K AA1 L ER0'],
  "colleran": ['K AA1 L ER0 AE0 N'],
  "collet": ['K AA1 L IH0 T'],
  "collett": ['K AA1 L IH0 T'],
  "colletta": ['K OW0 L EH1 T AH0'],
  "collette": ['K AH0 L EH1 T'],
  "colletti": ['K OW0 L EH1 T IY0'],
  "colleville": ['K OW1 L V IH0 L'],
  "colleville's": ['K OW1 L V IH0 L Z'],
  "colley": ['K AA1 L IY0'],
  "colli": ['K OW1 L IY0'],
  "collick": ['K AA1 L IH0 K'],
  "collide": ['K AH0 L AY1 D'],
  "collided": ['K AH0 L AY1 D IH0 D'],
  "collider": ['K AH0 L AY1 D ER0'],
  "collides": ['K AH0 L AY1 D Z'],
  "colliding": ['K AH0 L AY1 D IH0 NG'],
  "collie": ['K AA1 L IY0'],
  "collier": ['K AA1 L Y ER0'],
  "collier's": ['K AA1 L Y ER0 Z'],
  "colliers": ['K AA1 L Y ER0 Z'],
  "collies": ['K AA1 L IY0 Z'],
  "colligan": ['K AA1 L IH0 G AE0 N'],
  "collignon": ['K AH0 L IH1 G N AH0 N'],
  "collin": ['K AA1 L IH0 N'],
  "colling": ['K AA1 L IH0 NG'],
  "collinge": ['K AA1 L IH0 N JH'],
  "collings": ['K AA1 L IH0 NG Z'],
  "collingsworth": ['K AH0 L IH1 NG Z W ER0 TH'],
  "collingwood": ['K AA1 L IH0 NG W UH2 D'],
  "collins": ['K AA1 L IH0 N Z'],
  "collins'": ['K AA1 L IH0 N Z'],
  "collins's": ['K AA1 L IH0 N Z IH0 Z', 'K AA1 L IH0 N Z'],
  "collinson": ['K AA1 L IH0 N S AH0 N'],
  "collinsworth": ['K AH0 L IH1 N S W ER0 TH'],
  "collis": ['K AA1 L IH0 S'],
  "collision": ['K AH0 L IH1 ZH AH0 N'],
  "collisional": ['K AH0 L IH1 ZH AH0 N AH0 L'],
  "collisions": ['K AH0 L IH1 ZH AH0 N Z'],
  "collison": ['K AA1 L IH0 S AH0 N'],
  "collister": ['K AA1 L IH0 S T ER0'],
  "colliver": ['K AA1 L IH0 V ER0'],
  "collman": ['K AA1 L M AH0 N'],
  "collocation": ['K AA0 L AH0 K EY1 SH AH0 N'],
  "collodion": ['K AH0 L OW1 D IY0 AH0 N'],
  "colloid": ['K AA1 L OY0 D'],
  "colloidal": ['K AH0 L OY1 D AH0 L'],
  "collom": ['K AA1 L AH0 M'],
  "collomb": ['K AA1 L AA0 M'],
  "collopy": ['K AH0 L OW1 P IY0'],
  "colloquial": ['K AH0 L OW1 K W IY0 AH0 L'],
  "colloquium": ['K AH0 L OW1 K W IY0 AH0 M'],
  "colloquy": ['K AA1 L AH0 K W IY0'],
  "collor": ['K AA1 L ER0', 'K AO1 L ER0'],
  "collor's": ['K AA1 L ER0 Z'],
  "collosio": ['K AH0 L OW1 S IY0 OW0'],
  "collosio's": ['K AH0 L OW1 S IY0 OW0 Z'],
  "collude": ['K AH0 L UW1 D'],
  "colluded": ['K AH0 L UW1 D IH0 D'],
  "colluding": ['K AH0 L UW1 D IH0 NG'],
  "collum": ['K AA1 L AH0 M'],
  "collums": ['K AA1 L AH0 M Z'],
  "collura": ['K AA1 L UH0 R AH0'],
  "collusion": ['K AH0 L UW1 ZH AH0 N'],
  "collusive": ['K AH0 L UW1 S IH0 V'],
  "collver": ['K AA1 L V ER0'],
  "colly": ['K AA1 L IY0'],
  "collyer": ['K AA1 L IY0 ER0'],
  "colm": ['K OW1 L M'],
  "colman": ['K OW1 L M AH0 N'],
  "colmenero": ['K OW0 L M EY0 N EH1 R OW0'],
  "colmer": ['K OW1 M ER0'],
  "colo": ['K OW1 L OW0'],
  "colodny": ['K AH0 L AA1 D N IY0'],
  "cologne": ['K AH0 L OW1 N'],
  "coloma": ['K OW2 L OW1 M AH0'],
  "colomb": ['K AA1 L AH0 M'],
  "colombari": ['K AA2 L AA0 M B AA1 R IY0'],
  "colombe": ['K OW0 L OW1 M B IY0'],
  "colombia": ['K AH0 L AH1 M B IY0 AH0'],
  "colombia's": ['K AH0 L AH1 M B IY0 AH0 Z'],
  "colombian": ['K AH0 L AH1 M B IY0 AH0 N'],
  "colombians": ['K AH0 L AH1 M B IY0 AH0 N Z'],
  "colombo": ['K AH0 L AH1 M B OW0'],
  "colombo's": ['K AH0 L AH1 M B OW0 Z'],
  "colon": ['K OW1 L AH0 N'],
  "colonel": ['K ER1 N AH0 L'],
  "colonel's": ['K ER1 N AH0 L Z'],
  "colonels": ['K ER1 N AH0 L Z'],
  "colonia": ['K AH0 L OW1 N IY0 AH0'],
  "colonial": ['K AH0 L OW1 N IY0 AH0 L'],
  "colonial's": ['K AH0 L OW1 N IY0 AH0 L Z'],
  "colonialism": ['K AH0 L OW1 N IY0 AH0 L IH2 Z AH0 M'],
  "colonialist": ['K AH0 L OW1 N IY0 AH0 L IH0 S T'],
  "colonialists": ['K AH0 L OW1 N IY0 AH0 L IH0 S T S'],
  "colonials": ['K AH0 L OW1 N IY0 AH0 L Z'],
  "colonies": ['K AA1 L AH0 N IY0 Z'],
  "colonist": ['K AA1 L AH0 N IH0 S T'],
  "colonists": ['K AA1 L AH0 N IH0 S T S'],
  "colonization": ['K AA2 L AH0 N IH0 Z EY1 SH AH0 N'],
  "colonize": ['K AA1 L AH0 N AY2 Z'],
  "colonized": ['K AA1 L AH0 N AY2 Z D'],
  "colonizer": ['K AA1 L AH0 N AY2 Z ER0'],
  "colonizers": ['K AA1 L AH0 N AY2 Z ER0 Z'],
  "colonna": ['K OW0 L OW1 N AH0'],
  "colonnade": ['K AA2 L AH0 N EY1 D'],
  "colonus": ['K AH0 L OW1 N AH0 S'],
  "colony": ['K AA1 L AH0 N IY0'],
  "colony's": ['K AA1 L AH0 N IY0 Z'],
  "colopy": ['K AH0 L OW1 P IY0'],
  "color": ['K AH1 L ER0', 'K AO1 L ER0'],
  "coloradan": ['K AA2 L ER0 AA1 D AH0 N'],
  "coloradans": ['K AA2 L ER0 AA1 D AH0 N Z'],
  "colorado": ['K AA2 L ER0 AA1 D OW0', 'K AA2 L ER0 AE1 D OW0'],
  "colorado's": ['K AA2 L ER0 AA1 D OW0 Z', 'K AA2 L ER0 AE1 D OW0 Z'],
  "coloration": ['K AH2 L ER0 EY1 SH AH0 N'],
  "coloratura": ['K AH0 L ER0 AH0 T UH1 R AH0'],
  "colorblind": ['K AH1 L ER0 B L AY2 N D'],
  "colorcraft": ['K AH1 L ER0 K R AE2 F T'],
  "colorectal": ['K OW2 L OW0 R EH1 K T AH0 L'],
  "colored": ['K AH1 L ER0 D'],
  "coloreds": ['K AA1 L ER0 AH0 D Z'],
  "colorfast": ['K AH1 L ER0 F AE2 S T'],
  "colorful": ['K AH1 L ER0 F AH0 L'],
  "colorfully": ['K AH1 L ER0 F L IY0'],
  "coloring": ['K AH1 L ER0 IH0 NG'],
  "coloristic": ['K AH2 L ER0 IH1 S T IH0 K'],
  "colorization": ['K AH2 L ER0 AH0 Z EY1 SH AH0 N'],
  "colorize": ['K AH1 L ER0 AY2 Z'],
  "colorized": ['K AH1 L ER0 AY2 Z D'],
  "colorizing": ['K AH1 L ER0 AY2 Z IH0 NG'],
  "colorless": ['K AH1 L ER0 L AH0 S'],
  "colorocs": ['K AH1 L ER0 AA2 K S'],
  "coloroll": ['K AH1 L ER0 OW2 L'],
  "colors": ['K AH1 L ER0 Z'],
  "colorwatch": ['K AH1 L ER0 W AA2 CH'],
  "colosi": ['K AH0 L OW1 S IY0'],
  "colosimo": ['K OW0 L OW0 S IY1 M OW0'],
  "colosio": ['K AH0 L OW1 S IY0 OW0'],
  "colosio's": ['K AH0 L OW1 S IY0 OW0 Z'],
  "colossal": ['K AH0 L AA1 S AH0 L'],
  "colossally": ['K AH0 L AA1 S AH0 L IY2'],
  "colosseum": ['K AA2 L AH0 S IY1 AH0 M'],
  "colossus": ['K AH0 L AA1 S AH0 S'],
  "colour": ['K AH1 L ER0'],
  "coloured": ['K AH1 L ER0 D'],
  "colouring": ['K AH1 L ER0 R IH2 NG'],
  "colours": ['K AH1 L ER0 Z'],
  "colpepper": ['K AH1 L P EH2 P ER0'],
  "colpitts": ['K OW1 L P IH0 T S'],
  "colquitt": ['K OW1 L K W IH0 T'],
  "colson": ['K OW1 L S AH0 N'],
  "colston": ['K OW1 L S T AH0 N'],
  "colstrip": ['K OW1 L S T R IH0 P'],
  "colt": ['K OW1 L T'],
  "colt's": ['K OW1 L T S'],
  "coltec": ['K OW1 L T EH2 K'],
  "colter": ['K OW1 L T ER0'],
  "coltharp": ['K OW1 L TH AA0 R P'],
  "coltie": ['K OW1 L T IY0'],
  "colton": ['K OW1 L T AH0 N'],
  "coltrain": ['K OW1 L T R EY2 N'],
  "coltrane": ['K OW1 L T R AH0 N', 'K OW1 L T R EY0 N'],
  "coltrane's": ['K OW1 L T R AH0 N Z', 'K OW1 L T R EY0 N Z'],
  "coltrin": ['K OW1 L T R IH0 N'],
  "colts": ['K OW1 L T S'],
  "coltsfoot": ['K OW1 L T S F UH2 T'],
  "colucci": ['K OW0 L UW1 CH IY0'],
  "coluccio": ['K OW0 L UW1 CH IY0 OW0'],
  "columba": ['K OW2 L AH1 M B AH0'],
  "columbia": ['K AH0 L AH1 M B IY0 AH0'],
  "columbia's": ['K AH0 L AH1 M B IY0 AH0 Z'],
  "columbian": ['K OW2 L AH1 M B IY0 AH0 N'],
  "columbians": ['K OW2 L AH1 M B IY0 AH0 N Z'],
  "columbine": ['K AA1 L AH0 M B AY2 N'],
  "columbines": ['K AA1 L AH0 M B AY2 N Z'],
  "columbo": ['K OW2 L AH1 M B OW0'],
  "columbus": ['K AH0 L AH1 M B AH0 S'],
  "columbus'": ['K AH0 L AH1 M B AH0 S'],
  "columbus's": ['K AH0 L AH1 M B AH0 S IH0 Z'],
  "column": ['K AA1 L AH0 M'],
  "columned": ['K AA1 L AH0 M D'],
  "columnist": ['K AA1 L AH0 M N AH0 S T'],
  "columnists": ['K AA1 L AH0 M N AH0 S T S'],
  "columns": ['K AA1 L AH0 M Z'],
  "colunga": ['K OW0 L UW1 NG G AH0'],
  "colussy": ['K AH0 L UW1 S IY0'],
  "colvard": ['K AA1 L V ER0 D'],
  "colver": ['K OW1 L V ER0'],
  "colvert": ['K AA1 L V ER0 T'],
  "colville": ['K AA1 L V IH0 L'],
  "colvin": ['K OW1 L V IH0 N'],
  "colwell": ['K OW1 L W EH2 L'],
  "colyer": ['K OW1 L IY0 ER0'],
  "com": ['K AA1 M'],
  "com's": ['K AA1 M Z'],
  "coma": ['K OW1 M AH0'],
  "comair": ['K AA1 M EH1 R'],
  "coman": ['K OW1 M AH0 N'],
  "comanche": ['K AH0 M AE1 N CH IY0'],
  "comanches": ['K AH0 M AE1 N CH IY0 Z'],
  "comandante": ['K OW2 M AH0 N D AA1 N T EY0'],
  "comandantes": ['K OW2 M AH0 N D AA1 N T EH0 Z'],
  "comandeer": ['K AA0 M AH0 N D IY1 R'],
  "comandeered": ['K AA0 M AH0 N D IY1 R D'],
  "comandeers": ['K AA0 M AH0 N D IY1 R Z'],
  "comarco": ['K OW0 M AA1 R K OW0'],
  "comas": ['K OW1 M AH0 Z'],
  "comatose": ['K OW1 M AH0 T OW2 S'],
  "comb": ['K OW1 M'],
  "combat": ['K AA1 M B AE0 T', 'K AH0 M B AE1 T'],
  "combatant": ['K AH0 M B AE1 T AH0 N T'],
  "combatants": ['K AH0 M B AE1 T AH0 N T S'],
  "combating": ['K AH0 M B AE1 T IH0 NG'],
  "combative": ['K AH0 M B AE1 T IH0 V', 'K AA2 M B AE1 T IH2 V'],
  "combativeness": ['K AH0 M B AE1 T IH0 V N AH0 S'],
  "combats": ['K AH0 M B AE1 T S'],
  "combatting": ['K AH0 M B AE1 T IH0 NG'],
  "combe": ['K OW1 M'],
  "combed": ['K OW1 M D'],
  "combee": ['K AA1 M B IY2'],
  "comber": ['K OW1 M ER0'],
  "combes": ['K OW1 M Z'],
  "combest": ['K OW1 M IH0 S T'],
  "combinability": ['K AH0 M B AY2 N AH0 B IH1 L AH0 T IY0', 'K AH2 M B IH0 N AH0 B IH1 L AH0 T IY0'],
  "combinable": ['K AH0 M B AY1 N AH0 B AH0 L'],
  "combination": ['K AA2 M B AH0 N EY1 SH AH0 N'],
  "combinations": ['K AA2 M B AH0 N EY1 SH AH0 N Z'],
  "combine": ['K AA1 M B AY0 N', 'K AH0 M B AY1 N'],
  "combined": ['K AH0 M B AY1 N D'],
  "combines": ['K AH0 M B AY1 N Z'],
  "combing": ['K OW1 M IH0 NG'],
  "combining": ['K AH0 M B AY1 N IH0 NG'],
  "combo": ['K AA1 M B OW2'],
  "combs": ['K OW1 M Z'],
  "combust": ['K AH0 M B AH1 S T'],
  "combustable": ['K AH0 M B AH1 S T AH0 B AH0 L'],
  "combustion": ['K AH0 M B AH1 S CH AH0 N'],
  "comby": ['K AA1 M B IY0'],
  "comcast": ['K AA1 M K AE2 S T'],
  "comcast's": ['K AA1 M K AE2 S T S'],
  "comdata": ['K AA1 M D AE2 D AH0', 'K AA1 M D EY2 D AH0'],
  "comden": ['K AA1 M D IH0 N'],
  "comdex": ['K AA1 M D AH0 K S'],
  "comdisco": ['K AA2 M D IH1 S K OW0'],
  "come": ['K AH1 M'],
  "come-on": ['K AH1 M AA1 N'],
  "come-ons": ['K AH1 M AA1 N Z'],
  "comeau": ['K AH0 M OW1'],
  "comeaux": ['K AH0 M OW1'],
  "comeback": ['K AH1 M B AE2 K'],
  "comebacks": ['K AH1 M B AE2 K S'],
  "comecon": ['K AA1 M AH0 K AA2 N'],
  "comedian": ['K AH0 M IY1 D IY0 AH0 N'],
  "comedian's": ['K AH0 M IY1 D IY0 AH0 N Z'],
  "comedians": ['K AH0 M IY1 D IY0 AH0 N Z'],
  "comedic": ['K AH0 M IY1 D IH0 K'],
  "comedienne": ['K AH0 M IY2 D IY0 EH1 N'],
  "comedies": ['K AA1 M AH0 D IY0 Z'],
  "comedown": ['K AH1 M D AW2 N'],
  "comedy": ['K AA1 M AH0 D IY0'],
  "comedy's": ['K AA1 M AH0 D IY0 Z'],
  "comegys": ['K AA1 M IH0 JH IY0 Z'],
  "comella": ['K OW0 M EH1 L AH0'],
  "comely": ['K AH1 M L IY0'],
  "comer": ['K AH1 M ER0'],
  "comerford": ['K AH0 M ER1 F ER0 D'],
  "comerica": ['K AH0 M EH1 R IH0 K AH0'],
  "comers": ['K AH1 M ER0 Z'],
  "comes": ['K AH1 M Z'],
  "comet": ['K AA1 M AH0 T'],
  "comet's": ['K AA1 M AH0 T S'],
  "cometary": ['K AA1 M AH0 T EH2 R IY0'],
  "cometh": ['K AH1 M IH0 TH'],
  "cometra": ['K OW0 M EH1 T R AH0'],
  "comets": ['K AA1 M AH0 T S'],
  "comeuppance": ['K AH2 M AH1 P AH0 N S'],
  "comex": ['K AA1 M EH2 K S'],
  "comex's": ['K AA1 M EH2 K S IH0 Z'],
  "comfed": ['K AA1 M F EH2 D'],
  "comfinance": ['K AA1 M F IH0 N AH0 N S'],
  "comfort": ['K AH1 M F ER0 T'],
  "comfortable": ['K AH1 M F ER0 T AH0 B AH0 L'],
  "comfortably": ['K AH1 M F ER0 T AH0 B L IY0'],
  "comforted": ['K AH1 M F ER0 T IH0 D'],
  "comforter": ['K AH1 M F ER0 T ER0'],
  "comforters": ['K AH1 M F ER0 T ER0 Z'],
  "comforting": ['K AH1 M F ER0 T IH0 NG'],
  "comforts": ['K AH1 M F ER0 T S'],
  "comfrey": ['K AH1 M F R IY0'],
  "comfy": ['K AH1 M F IY0'],
  "comic": ['K AA1 M IH0 K'],
  "comical": ['K AA1 M IH0 K AH0 L'],
  "comically": ['K AA1 M IH0 K AH0 L IY0', 'K AA1 M IH0 K L IY0'],
  "comicopia": ['K AA2 M IH0 K OW1 P IY0 AH0'],
  "comics": ['K AA1 M IH0 K S'],
  "comin'": ['K AH1 M IH0 N'],
  "cominco": ['K OW0 M IH1 NG K OW0'],
  "coming": ['K AH1 M IH0 NG'],
  "comings": ['K AH1 M IH0 NG Z'],
  "comino": ['K AH0 M IY1 N OW0'],
  "comins": ['K OW1 M IH0 N Z'],
  "cominsky": ['K AH0 M IH1 N S K IY0'],
  "comintern": ['K AO1 M IY0 N T ER2 N'],
  "comiskey": ['K OW1 M IH0 S K IY1'],
  "comitatus": ['K AO0 M AH0 T EY1 T AH0 S'],
  "comito": ['K OW0 M IY1 T OW0'],
  "comity": ['K OW1 M IH0 T IY0'],
  "comley": ['K AA1 M L IY0'],
  "comly": ['K AA1 M L IY0'],
  "comm": ['K AA1 M'],
  "comma": ['K AA1 M AH0'],
  "commack": ['K AA1 M AH0 K'],
  "command": ['K AH0 M AE1 N D'],
  "command's": ['K AH0 M AE1 N D Z'],
  "commandant": ['K AA2 M AH0 N D AA1 N T'],
  "commanded": ['K AH0 M AE1 N D IH0 D'],
  "commandeer": ['K AA2 M AH0 N D IH1 R'],
  "commandeered": ['K AA2 M AH0 N D IH1 R D'],
  "commander": ['K AH0 M AE1 N D ER0'],
  "commander's": ['K AH0 M AE1 N D ER0 Z'],
  "commanders": ['K AH0 M AE1 N D ER0 Z'],
  "commanding": ['K AH0 M AE1 N D IH0 NG'],
  "commandment": ['K AH0 M AE1 N D M AH0 N T'],
  "commandments": ['K AH0 M AE1 N D M AH0 N T S'],
  "commando": ['K AH0 M AE1 N D OW2'],
  "commandos": ['K AH0 M AE1 N D OW2 Z'],
  "commands": ['K AH0 M AE1 N D Z'],
  "commas": ['K AA1 M AH0 Z'],
  "commemorate": ['K AH0 M EH1 M ER0 EY2 T'],
  "commemorated": ['K AH0 M EH1 M ER0 EY2 T IH0 D'],
  "commemorates": ['K AH0 M EH1 M ER0 EY2 T S'],
  "commemorating": ['K AH0 M EH1 M ER0 EY2 T IH0 NG'],
  "commemoration": ['K AH0 M EH2 M ER0 EY1 SH AH0 N'],
  "commemorations": ['K AH0 M EH2 M ER0 EY1 SH AH0 N Z'],
  "commemorative": ['K AH0 M EH1 M R AH0 T IH0 V', 'K AH0 M EH1 M ER0 EY2 T IH0 V'],
  "commence": ['K AH0 M EH1 N S'],
  "commenced": ['K AH0 M EH1 N S T'],
  "commencement": ['K AH0 M EH1 N S M AH0 N T'],
  "commences": ['K AH0 M EH1 N S AH0 Z'],
  "commencing": ['K AH0 M EH1 N S IH0 NG'],
  "commend": ['K AH0 M EH1 N D'],
  "commendable": ['K AH0 M EH1 N D AH0 B AH0 L'],
  "commendation": ['K AA2 M AH0 N D EY1 SH AH0 N'],
  "commended": ['K AH0 M EH1 N D IH0 D'],
  "commending": ['K AH0 M EH1 N D IH0 NG'],
  "commends": ['K AH0 M EH1 N D Z'],
  "commensurate": ['K AH0 M EH1 N S ER0 AH0 T', 'K AH0 M EH1 N S ER0 IH0 T'],
  "commensurately": ['K AH0 M EH1 N S ER0 AH0 T L IY0', 'K AH0 M EH1 N SH ER0 AH0 T L IY0'],
  "comment": ['K AA1 M EH0 N T'],
  "commentariat": ['K AA2 M AH0 N T AE1 R IY2 AH0 T'],
  "commentaries": ['K AA1 M AH0 N T EH2 R IY0 Z'],
  "commentary": ['K AA1 M AH0 N T EH2 R IY0'],
  "commentator": ['K AA1 M AH0 N T EY2 T ER0'],
  "commentator's": ['K AA1 M AH0 N T EY2 T ER0 Z'],
  "commentators": ['K AA1 M AH0 N T EY2 T ER0 Z'],
  "commented": ['K AA1 M EH0 N T AH0 D'],
  "commenter": ['K AA1 M EH0 N T ER0'],
  "commenters": ['K AA1 M EH0 N T ER0 Z'],
  "commenting": ['K AA1 M EH0 N T IH0 NG'],
  "comments": ['K AA1 M EH0 N T S'],
  "commerce": ['K AA1 M ER0 S'],
  "commerce's": ['K AA1 M ER0 S IH0 Z'],
  "commercebancorp": ['K AA2 M ER0 S B AE1 N K AO2 R P'],
  "commercial": ['K AH0 M ER1 SH AH0 L'],
  "commercial's": ['K AH0 M ER1 SH AH0 L Z'],
  "commerciale": ['K AH0 M ER2 S IY0 AE1 L'],
  "commerciale's": ['K AH0 M ER2 S IY0 AE1 L Z', 'K OW0 M EH2 R S IY0 AE1 L EY2 Z'],
  "commercialism": ['K AH0 M ER1 SH AH0 L IH2 Z AH0 M'],
  "commercialization": ['K AH0 M ER2 SH AH0 L IH0 Z EY1 SH AH0 N'],
  "commercialize": ['K AH0 M ER1 SH AH0 L AY2 Z'],
  "commercialized": ['K AH0 M ER1 SH AH0 L AY2 Z D'],
  "commercializing": ['K AH0 M ER1 SH AH0 L AY2 Z IH0 NG'],
  "commercially": ['K AH0 M ER1 SH AH0 L IY0'],
  "commercials": ['K AH0 M ER1 SH AH0 L Z'],
  "commerford": ['K AA1 M ER0 F ER0 D'],
  "commerical": ['K AH0 M ER1 SH AH0 L'],
  "commerzbank": ['K AA1 M ER0 Z B AE2 NG K'],
  "commerzbank's": ['K AA1 M ER0 Z B AE1 NG K S'],
  "commie": ['K AA1 M IY0'],
  "commies": ['K AA1 M IY0 Z'],
  "commingle": ['K AH0 M IH1 NG G AH0 L', 'K OW0 M IH1 NG G AH0 L'],
  "commingled": ['K AA0 M IH1 NG G AH0 L D', 'K OW0 M IH1 NG G AH0 L D'],
  "commingling": ['K AA0 M IH1 NG G AH0 L IH0 NG', 'K OW0 M IH1 NG G L IH0 NG'],
  "commins": ['K AA1 M IH0 N Z'],
  "commiserate": ['K AH0 M IH1 S ER0 EY2 T'],
  "commiserates": ['K AH0 M IH1 S ER0 EY2 T S'],
  "commiserating": ['K AH0 M IH1 S ER0 EY2 T IH0 NG'],
  "commish": ['K AH0 M IH1 SH'],
  "commision": ['K AH0 M IH1 Z AH0 N', 'K AH0 M IH1 SH AH0 N'],
  "commissar": ['K AA1 M AH0 S AA2 R'],
  "commissaries": ['K AA1 M AH0 S EH2 R IY0 Z'],
  "commissars": ['K AA1 M IH0 S AA0 Z'],
  "commissary": ['K AA1 M AH0 S EH2 R IY0'],
  "commission": ['K AH0 M IH1 SH AH0 N'],
  "commission's": ['K AH0 M IH1 SH AH0 N Z'],
  "commissioned": ['K AH0 M IH1 SH AH0 N D'],
  "commissioner": ['K AH0 M IH1 SH AH0 N ER0'],
  "commissioner's": ['K AH0 M IH1 SH AH0 N ER0 Z'],
  "commissioners": ['K AH0 M IH1 SH AH0 N ER0 Z'],
  "commissioning": ['K AH0 M IH1 SH AH0 N IH0 NG'],
  "commissions": ['K AH0 M IH1 SH AH0 N Z'],
  "commisso": ['K OW0 M IY1 S OW0'],
  "commit": ['K AH0 M IH1 T'],
  "commitee": ['K AA0 M IH1 T IY0', 'K AH0 M IH1 T IY0'],
  "commitment": ['K AH0 M IH1 T M AH0 N T'],
  "commitments": ['K AH0 M IH1 T M AH0 N T S'],
  "commits": ['K AH0 M IH1 T S'],
  "committal": ['K AH0 M IH1 T AH0 L'],
  "committed": ['K AH0 M IH1 T IH0 D'],
  "committee": ['K AH0 M IH1 T IY0'],
  "committee's": ['K AH0 M IH1 T IY0 Z'],
  "committeeman": ['K AH0 M IH1 T IY0 M AH0 N'],
  "committees": ['K AH0 M IH1 T IY0 Z'],
  "committees'": ['K AH0 M IH1 T IY0 Z'],
  "committing": ['K AH0 M IH1 T IH0 NG'],
  "commode": ['K AH0 M OW1 D'],
  "commodious": ['K AH0 M OW1 D IY0 AH0 S'],
  "commodities": ['K AH0 M AA1 D AH0 T IY0 Z'],
  "commodity": ['K AH0 M AA1 D AH0 T IY0'],
  "commodity's": ['K AH0 M AA1 D AH0 T IY0 Z'],
  "commodore": ['K AA1 M AH0 D AO2 R'],
  "commodore's": ['K AA1 M AH0 D AO2 R Z'],
  "common": ['K AA1 M AH0 N'],
  "commonalities": ['K AA2 M AH0 N AE1 L AH0 T IY0 Z'],
  "commonality": ['K AA2 M AH0 N AE1 L AH0 T IY0'],
  "commoner": ['K AA1 M AH0 N ER0'],
  "commoners": ['K AA1 M AH0 N ER0 Z'],
  "commonly": ['K AA1 M AH0 N L IY0'],
  "commonplace": ['K AA1 M AH0 N P L EY2 S'],
  "commons": ['K AA1 M AH0 N Z'],
  "commonsense": ['K AA2 M AH0 N S EH1 N S'],
  "commonsensical": ['K AA2 M AH0 N S EH1 N S IH0 K AH0 L'],
  "commonwealth": ['K AA1 M AH0 N W EH2 L TH'],
  "commonwealth's": ['K AA1 M AH0 N W EH2 L TH S'],
  "commotion": ['K AH0 M OW1 SH AH0 N'],
  "communal": ['K AH0 M Y UW1 N AH0 L'],
  "commune": ['K AA1 M Y UW0 N', 'K AH0 M Y UW1 N'],
  "communes": ['K AA1 M Y UW0 N Z', 'K AH0 M Y UW1 N Z'],
  "communicable": ['K AH0 M Y UW1 N AH0 K AH0 B AH0 L'],
  "communicate": ['K AH0 M Y UW1 N AH0 K EY2 T'],
  "communicated": ['K AH0 M Y UW1 N AH0 K EY2 T IH0 D'],
  "communicates": ['K AH0 M Y UW1 N IH0 K EY2 T S'],
  "communicating": ['K AH0 M Y UW1 N AH0 K EY2 T IH0 NG'],
  "communication": ['K AH0 M Y UW2 N AH0 K EY1 SH AH0 N'],
  "communication's": ['K AH0 M Y UW2 N IH0 K EY1 SH AH0 N Z'],
  "communications": ['K AH0 M Y UW2 N AH0 K EY1 SH AH0 N Z'],
  "communications'": ['K AH0 M Y UW2 N AH0 K EY1 SH AH0 N Z'],
  "communicative": ['K AH0 M Y UW1 N AH0 K AH0 T IH0 V'],
  "communicator": ['K AH0 M Y UW1 N AH0 K EY2 T ER0'],
  "communicators": ['K AH0 M Y UW1 N AH0 K EY0 T ER0 Z'],
  "communing": ['K AH0 M Y UW1 N IH0 NG'],
  "communion": ['K AH0 M Y UW1 N Y AH0 N'],
  "communique": ['K AH0 M Y UW1 N AH0 K EY2', 'K AH0 M Y UW2 N AH0 K EY1'],
  "communiques": ['K AH0 M Y UW2 N IH0 K EY1 Z'],
  "communism": ['K AA1 M Y AH0 N IH2 Z AH0 M'],
  "communism's": ['K AA1 M Y AH0 N IH2 Z AH0 M Z'],
  "communist": ['K AA1 M Y AH0 N AH0 S T'],
  "communist's": ['K AA1 M Y AH0 N AH0 S T S'],
  "communists": ['K AA1 M Y AH0 N AH0 S T S'],
  "communists'": ['K AA1 M Y UW0 N IH0 S T S'],
  "communities": ['K AH0 M Y UW1 N AH0 T IY0 Z', 'K AH0 M Y UW1 N IH0 T IY0 Z'],
  "community": ['K AH0 M Y UW1 N AH0 T IY0', 'K AH0 M Y UW1 N IH0 T IY0'],
  "community's": ['K AH0 M Y UW1 N IH0 T IY0 Z'],
  "communitywide": ['K AH0 M Y UW1 N IH0 T IY0 W AY2 D'],
  "communization": ['K AA2 M Y AH0 N AH0 Z EY1 SH AH0 N'],
  "communize": ['K AA1 M Y AH0 N AY2 Z'],
  "commutate": ['K AA2 M Y AH0 T EY1 T'],
  "commutation": ['K AA2 M Y AH0 T EY1 SH AH0 N'],
  "commute": ['K AH0 M Y UW1 T'],
  "commuted": ['K AH0 M Y UW1 T IH0 D'],
  "commuter": ['K AH0 M Y UW1 T ER0'],
  "commuters": ['K AH0 M Y UW1 T ER0 Z'],
  "commutes": ['K AH0 M Y UW1 T S'],
  "commuting": ['K AH0 M Y UW1 T IH0 NG'],
  "como": ['K OW1 M OW0'],
  "comoros": ['K AO0 M AO1 R OW2 S'],
  "comp": ['K AA1 M P'],
  "compact": ['K AA1 M P AE0 K T', 'K AH0 M P AE1 K T'],
  "compacted": ['K AH0 M P AE1 K T IH0 D'],
  "compactness": ['K AH0 M P AE1 K T N AH0 S'],
  "compactor": ['K AH0 M P AE1 K T ER0'],
  "compacts": ['K AH0 M P AE1 K T S', 'K AA1 M P AE0 K T S'],
  "compagnie": ['K AH1 M P AH0 N IY0'],
  "companero": ['K AA2 M P AH0 N Y EH1 R OW2'],
  "companhia": ['K AH0 M P AE1 N HH IY0 AA2'],
  "compania": ['K AH0 M P EY1 N IY2 AA0'],
  "companies": ['K AH1 M P AH0 N IY2 Z'],
  "companies'": ['K AH1 M P AH0 N IY2 Z'],
  "companies's": ['K AH1 M P AH0 N IY0 Z IH0 Z'],
  "companion": ['K AH0 M P AE1 N Y AH0 N'],
  "companions": ['K AH0 M P AE1 N Y AH0 N Z'],
  "companionship": ['K AH0 M P AE1 N Y AH0 N SH IH2 P'],
  "companionway": ['K AH0 M P AE1 N Y AH0 N W EY2'],
  "company": ['K AH1 M P AH0 N IY2'],
  "company'll": ['K AH1 M P AH0 N IY0 AH0 L'],
  "company's": ['K AH1 M P AH0 N IY0 Z'],
  "companywide": ['K AH1 M P AH0 N IY0 W AY2 D'],
  "compaore": ['K AA2 M P AH0 AO1 R IY0'],
  "compaq": ['K AA1 M P AE2 K'],
  "compaq's": ['K AA1 M P AE2 K S'],
  "comparability": ['K AA2 M P ER0 AH0 B IH1 L IH0 T IY2'],
  "comparable": ['K AA1 M P ER0 AH0 B AH0 L', 'K AH0 M P EH1 R AH0 B AH0 L', 'K AH0 M P R AH0 B AH0 L'],
  "comparably": ['K AA1 M P ER0 AH0 B L IY0', 'K AA1 M P R AH0 B L IY0'],
  "comparative": ['K AH0 M P EH1 R AH0 T IH0 V'],
  "comparatively": ['K AH0 M P EH1 R AH0 T IH0 V L IY0'],
  "comparator": ['K AH0 M P ER1 AH0 T ER0'],
  "compare": ['K AH0 M P EH1 R'],
  "compared": ['K AH0 M P EH1 R D'],
  "compares": ['K AH0 M P EH1 R Z'],
  "comparing": ['K AH0 M P EH1 R IH0 NG'],
  "comparison": ['K AH0 M P EH1 R AH0 S AH0 N'],
  "comparisons": ['K AH0 M P EH1 R AH0 S AH0 N Z'],
  "compartment": ['K AH0 M P AA1 R T M AH0 N T'],
  "compartmental": ['K AH0 M P AA2 R T M EH1 N T AH0 L'],
  "compartmentalize": ['K AH0 M P AA2 R T M EH1 N T AH0 L AY2 Z'],
  "compartmentalized": ['K AA2 M P AA0 R T M EH1 N T AH0 L AY0 Z D'],
  "compartments": ['K AH0 M P AA1 R T M AH0 N T S'],
  "compas": ['K AA1 M P AH0 S'],
  "compass": ['K AH1 M P AH0 S'],
  "compassion": ['K AH0 M P AE1 SH AH0 N'],
  "compassionate": ['K AH0 M P AE1 SH AH0 N AH0 T'],
  "compassionately": ['K AH0 M P AE1 SH AH0 N AH0 T L IY2'],
  "compatibility": ['K AH0 M P AE2 T AH0 B IH1 L AH0 T IY2'],
  "compatible": ['K AH0 M P AE1 T AH0 B AH0 L'],
  "compatibles": ['K AH0 M P AE1 T IH0 B AH0 L Z'],
  "compatriot": ['K AH0 M P EY1 T R IY0 AH0 T'],
  "compatriots": ['K AH0 M P EY1 T R IY0 AH0 T S'],
  "compean": ['K AA1 M P AH0 N'],
  "compeau": ['K AH0 M P OW1'],
  "compel": ['K AH0 M P EH1 L'],
  "compelled": ['K AH0 M P EH1 L D'],
  "compelling": ['K AH0 M P EH1 L IH0 NG'],
  "compellingly": ['K AH0 M P EH1 L IH0 NG L IY0'],
  "compels": ['K AH0 M P EH1 L Z'],
  "compendium": ['K AH0 M P EH1 N D IY0 AH0 M'],
  "compensable": ['K AH0 M P EH1 N S AH0 B AH0 L'],
  "compensate": ['K AA1 M P AH0 N S EY2 T'],
  "compensated": ['K AA1 M P AH0 N S EY2 T AH0 D'],
  "compensates": ['K AA1 M P AH0 N S EY2 T S'],
  "compensating": ['K AA1 M P AH0 N S EY2 T IH0 NG'],
  "compensation": ['K AA2 M P AH0 N S EY1 SH AH0 N'],
  "compensations": ['K AA2 M P AH0 N S EY1 SH AH0 N Z'],
  "compensatory": ['K AH0 M P EH1 N S AH0 T AO2 R IY0'],
  "compete": ['K AH0 M P IY1 T'],
  "competed": ['K AH0 M P IY1 T IH0 D'],
  "competence": ['K AA1 M P AH0 T IH0 N S'],
  "competencies": ['K AA1 M P AH0 T IH0 N S IY0 Z'],
  "competency": ['K AA1 M P AH0 T IH0 N S IY0'],
  "competent": ['K AA1 M P AH0 T IH0 N T'],
  "competently": ['K AA1 M P AH0 T IH0 N T L IY0'],
  "competes": ['K AH0 M P IY1 T S'],
  "competing": ['K AH0 M P IY1 T IH0 NG'],
  "competition": ['K AA2 M P AH0 T IH1 SH AH0 N'],
  "competition's": ['K AA2 M P AH0 T IH1 SH AH0 N Z'],
  "competitions": ['K AA2 M P AH0 T IH1 SH AH0 N Z'],
  "competitive": ['K AH0 M P EH1 T AH0 T IH0 V', 'K AH0 M P EH1 T IH0 T IH0 V'],
  "competitively": ['K AH0 M P EH1 T IH0 T IH0 V L IY0'],
  "competitiveness": ['K AH0 M P EH1 T IH0 T IH0 V N IH0 S'],
  "competitor": ['K AH0 M P EH1 T AH0 T ER0', 'K AH0 M P EH1 T IH0 T ER0'],
  "competitor's": ['K AH0 M P EH1 T AH0 T ER0 Z'],
  "competitors": ['K AH0 M P EH1 T AH0 T ER0 Z', 'K AH0 M P EH1 T IH0 T ER0 Z'],
  "competitors'": ['K AH0 M P EH1 T IH0 T ER0 Z'],
  "compher": ['K AA1 M F ER0'],
  "compilation": ['K AA2 M P AH0 L EY1 SH AH0 N'],
  "compilations": ['K AA2 M P AH0 L EY1 SH AH0 N Z'],
  "compile": ['K AH0 M P AY1 L'],
  "compiled": ['K AH0 M P AY1 L D'],
  "compiler": ['K AH0 M P AY1 L ER0'],
  "compilers": ['K AH0 M P AY1 L ER0 Z'],
  "compiles": ['K AH0 M P AY1 L Z'],
  "compiling": ['K AH0 M P AY1 L IH0 NG'],
  "complacency": ['K AH0 M P L EY1 S AH0 N S IY0'],
  "complacent": ['K AH0 M P L EY1 S AH0 N T'],
  "complacently": ['K AH0 M P L EY1 S AH0 N T L IY0'],
  "complain": ['K AH0 M P L EY1 N'],
  "complainant": ['K AH0 M P L EY1 N AH0 N T'],
  "complainant's": ['K AH0 M P L EY1 N AH0 N T S'],
  "complainants": ['K AH0 M P L EY1 N AH0 N T S'],
  "complained": ['K AH0 M P L EY1 N D'],
  "complainer": ['K AH0 M P L EY1 N ER0'],
  "complainers": ['K AA1 M P L EY2 N ER0 Z'],
  "complaining": ['K AH0 M P L EY1 N IH0 NG'],
  "complains": ['K AH0 M P L EY1 N Z'],
  "complaint": ['K AH0 M P L EY1 N T'],
  "complaints": ['K AH0 M P L EY1 N T S'],
  "complaisant": ['K AH0 M P L EY1 S AH0 N T'],
  "complection": ['K AH0 M P L EH1 K SH AH0 N'],
  "complections": ['K AH0 M P L EH1 K SH AH0 N Z'],
  "complement": ['K AA1 M P L AH0 M AH0 N T'],
  "complementary": ['K AA2 M P L AH0 M EH1 N T R IY0', 'K AA2 M P L AH0 M EH1 N CH IY0'],
  "complemented": ['K AA1 M P L AH0 M EH2 N T IH0 D'],
  "complementing": ['K AA1 M P L AH0 M EH2 N T IH0 NG'],
  "complements": ['K AA1 M P L AH0 M AH0 N T S'],
  "complete": ['K AH0 M P L IY1 T'],
  "completed": ['K AH0 M P L IY1 T AH0 D', 'K AH0 M P L IY1 T IH0 D'],
  "completely": ['K AH0 M P L IY1 T L IY0'],
  "completeness": ['K AH0 M P L IY1 T N AH0 S'],
  "completes": ['K AH0 M P L IY1 T S'],
  "completing": ['K AH0 M P L IY1 T IH0 NG'],
  "completion": ['K AH0 M P L IY1 SH AH0 N'],
  "completions": ['K AH0 M P L IY1 SH AH0 N Z'],
  "complex": ['K AA1 M P L EH0 K S', 'K AH0 M P L EH1 K S'],
  "complexes": ['K AA1 M P L EH0 K S AH0 Z'],
  "complexion": ['K AH0 M P L EH1 K SH AH0 N'],
  "complexions": ['K AH0 M P L EH1 K SH AH0 N Z'],
  "complexities": ['K AH0 M P L EH1 K S IH0 T IY0 Z'],
  "complexity": ['K AH0 M P L EH1 K S AH0 T IY0', 'K AH0 M P L EH1 K S IH0 T IY0'],
  "compliance": ['K AH0 M P L AY1 AH0 N S'],
  "compliant": ['K AH0 M P L AY1 AH0 N T'],
  "complicate": ['K AA1 M P L AH0 K EY2 T'],
  "complicated": ['K AA1 M P L AH0 K EY2 T AH0 D'],
  "complicates": ['K AA1 M P L IH0 K EY2 T S'],
  "complicating": ['K AA1 M P L AH0 K EY2 T IH0 NG'],
  "complication": ['K AA2 M P L AH0 K EY1 SH AH0 N'],
  "complications": ['K AA2 M P L AH0 K EY1 SH AH0 N Z'],
  "complicit": ['K AH0 M P L IH1 S AH0 T'],
  "complicities": ['K AH0 M P L IH1 S IH0 T IY0 Z'],
  "complicity": ['K AH0 M P L IH1 S AH0 T IY0', 'K AH0 M P L IH1 S IH0 T IY0'],
  "complied": ['K AH0 M P L AY1 D'],
  "complies": ['K AH0 M P L AY1 Z'],
  "compliment": ['K AA1 M P L AH0 M EH0 N T'],
  "complimentary": ['K AA2 M P L AH0 M EH1 N T ER0 IY0'],
  "complimented": ['K AA1 M P L AH0 M EH2 N T IH0 D'],
  "complimenting": ['K AA1 M P L AH0 M EH2 N T IH0 NG'],
  "compliments": ['K AA1 M P L AH0 M EH0 N T S'],
  "comply": ['K AH0 M P L AY1'],
  "complying": ['K AH0 M P L AY1 IH0 NG'],
  "compo": ['K AA1 M P OW2'],
  "component": ['K AH0 M P OW1 N AH0 N T'],
  "components": ['K AH0 M P OW1 N AH0 N T S'],
  "comport": ['K AH0 M P AO1 R T'],
  "comported": ['K AH0 M P AO1 R T AH0 D'],
  "comportment": ['K AH0 M P AO1 R T M AH0 N T'],
  "compose": ['K AH0 M P OW1 Z'],
  "composed": ['K AH0 M P OW1 Z D'],
  "composer": ['K AH0 M P OW1 Z ER0'],
  "composer's": ['K AH0 M P OW1 Z ER0 Z'],
  "composers": ['K AH0 M P OW1 Z ER0 Z'],
  "composes": ['K AH0 M P OW1 Z IH0 Z'],
  "composing": ['K AH0 M P OW1 Z IH0 NG'],
  "composite": ['K AH0 M P AA1 Z AH0 T', 'K AA0 M P AA1 Z AH0 T'],
  "composite's": ['K AH0 M P AA1 Z AH0 T S', 'K AA0 M P AA1 Z AH0 T S'],
  "composites": ['K AH0 M P AA1 Z AH0 T S', 'K AA0 M P AA1 Z AH0 T S'],
  "composition": ['K AA2 M P AH0 Z IH1 SH AH0 N'],
  "compositional": ['K AA2 M P AH0 Z IH1 SH AH0 N AH0 L'],
  "compositions": ['K AA2 M P AH0 Z IH1 SH AH0 N Z'],
  "compost": ['K AA1 M P OW0 S T'],
  "compostela": ['K AO2 M P AO0 S T EH1 L AH0'],
  "composting": ['K AA1 M P OW2 S T IH0 NG'],
  "composts": ['K AA1 M P OW0 S T S'],
  "composure": ['K AH0 M P OW1 ZH ER0'],
  "compote": ['K AA1 M P OW0 T'],
  "compound": ['K AA1 M P AW0 N D', 'K AH0 M P AW1 N D'],
  "compounded": ['K AH0 M P AW1 N D AH0 D', 'K AH0 M P AW1 N D IH0 D'],
  "compounding": ['K AH0 M P AW1 N D IH0 NG'],
  "compounds": ['K AA1 M P AW0 N D Z', 'K AH0 M P AW1 N D Z'],
  "comprehend": ['K AA2 M P R IY0 HH EH1 N D'],
  "comprehended": ['K AA2 M P R IH0 HH EH1 N D IH0 D'],
  "comprehending": ['K AA2 M P R IH0 HH EH1 N D IH0 NG'],
  "comprehends": ['K AA2 M P R IY0 HH EH1 N D Z'],
  "comprehensibility": ['K AA2 M P R IY0 HH EH2 N S AH0 B IH1 L AH0 T IY0'],
  "comprehensible": ['K AA2 M P R IY0 HH EH1 N S AH0 B AH0 L'],
  "comprehension": ['K AA2 M P R IY0 HH EH1 N SH AH0 N'],
  "comprehensive": ['K AA2 M P R IY0 HH EH1 N S IH0 V'],
  "comprehensively": ['K AA2 M P R IH0 HH EH1 N S IH0 V L IY0'],
  "compress": ['K AA1 M P R EH0 S', 'K AH0 M P R EH1 S'],
  "compressed": ['K AH0 M P R EH1 S T'],
  "compresses": ['K AA1 M P R EH0 S AH0 Z', 'K AH0 M P R EH1 S AH0 Z', 'K AH0 M P R EH1 S IH0 Z'],
  "compressing": ['K AH0 M P R EH1 S IH0 NG'],
  "compression": ['K AH0 M P R EH1 SH AH0 N'],
  "compressor": ['K AH0 M P R EH1 S ER0'],
  "compressors": ['K AH0 M P R EH1 S ER0 Z'],
  "comprint": ['K AA1 M P R IH2 N T'],
  "comprise": ['K AH0 M P R AY1 Z'],
  "comprised": ['K AH0 M P R AY1 Z D'],
  "comprises": ['K AH0 M P R AY1 Z AH0 Z', 'K AH0 M P R AY1 Z IH0 Z'],
  "comprising": ['K AH0 M P R AY1 Z IH0 NG'],
  "compromise": ['K AA1 M P R AH0 M AY2 Z'],
  "compromised": ['K AA1 M P R AH0 M AY2 Z D'],
  "compromiser": ['K AA1 M P R AH0 M AY2 Z ER0'],
  "compromises": ['K AA1 M P R AH0 M AY2 Z IH0 Z'],
  "compromising": ['K AA1 M P R AH0 M AY2 Z IH0 NG'],
  "compston": ['K AA1 M P S T AH0 N'],
  "comptek": ['K AA1 M P T EH2 K'],
  "compton": ['K AA1 M P T AH0 N'],
  "compton's": ['K AA1 M P T AH0 N Z'],
  "comptroller": ['K AH0 M T R OW1 L ER0', 'K AA1 M T R OW2 L ER0'],
  "comptroller's": ['K AH0 M T R OW1 L ER0 Z', 'K AA1 M T R OW2 L ER0 Z'],
  "comptronix": ['K AA2 M P T R AA1 N IH2 K S'],
  "compu": ['K AA1 M P Y UW0'],
  "compuadd": ['K AA1 M P Y UW0 AE2 D'],
  "compuchem": ['K AA1 M P Y UW0 K EH2 M'],
  "compudyne": ['K AA1 M P Y UW0 D AY2 N'],
  "compufund": ['K AA1 M P Y UW0 F AH2 N D'],
  "compugraphic": ['K AA2 M P Y UW0 G R AE1 F IH0 K'],
  "compulsion": ['K AH0 M P AH1 L SH AH0 N'],
  "compulsions": ['K AH0 M P UH1 L SH AH0 N Z'],
  "compulsive": ['K AH0 M P AH1 L S IH0 V'],
  "compulsively": ['K AH0 M P AH1 L S IH0 V L IY0'],
  "compulsory": ['K AH0 M P AH1 L S ER0 IY0'],
  "compumat": ['K AA1 M P Y UW0 M AE2 T'],
  "compunction": ['K AH0 M P AH1 NG K SH AH0 N'],
  "compus": ['K AA1 M P Y UW0 Z'],
  "compusa": ['K AA1 M P Y UW1 EH1 S EY1'],
  "compusa's": ['K AA1 M P Y UW1 EH1 S EY1 Z'],
  "compuserve": ['K AA1 M P Y UW0 S ER0 V'],
  "compuserve's": ['K AA1 M P Y UW0 S ER0 V Z'],
  "computalog": ['K AA1 M P Y UW0 T AE2 L AO0 G'],
  "computation": ['K AA2 M P Y AH0 T EY1 SH AH0 N'],
  "computational": ['K AA2 M P Y UW0 T EY1 SH AH0 N AH0 L'],
  "computations": ['K AA2 M P Y UW0 T EY1 SH AH0 N Z'],
  "compute": ['K AH0 M P Y UW1 T'],
  "computed": ['K AH0 M P Y UW1 T AH0 D', 'K AH0 M P Y UW1 T IH0 D'],
  "computer": ['K AH0 M P Y UW1 T ER0'],
  "computer's": ['K AH0 M P Y UW1 T ER0 Z'],
  "computer-generated": ['K AH0 M P Y UW1 T ER0 JH EH1 N ER0 EY2 T AH0 D'],
  "computercraft": ['K AH0 M P Y UW1 T ER0 K R AE2 F T'],
  "computerization": ['K AH0 M P Y UW2 T ER0 IH0 Z EY1 SH AH0 N'],
  "computerize": ['K AH0 M P Y UW1 T ER0 AY2 Z'],
  "computerized": ['K AH0 M P Y UW1 T ER0 AY2 Z D'],
  "computerizing": ['K AH0 M P Y UW1 T ER0 AY2 Z IH0 NG'],
  "computerland": ['K AH0 M P Y UW1 T ER0 L AE2 N D'],
  "computerland's": ['K AH0 M P Y UW1 T ER0 L AE2 N D Z'],
  "computerlike": ['K AH0 M P Y UW1 T ER0 L AY2 K'],
  "computers": ['K AH0 M P Y UW1 T ER0 Z'],
  "computers'": ['K AH0 M P Y UW1 T ER0 Z'],
  "computervision": ['K AH0 M P Y UW1 T ER0 V IH2 ZH AH0 N'],
  "computerworld": ['K AH0 M P Y UW1 T ER0 W ER2 L D'],
  "computes": ['K AH0 M P Y UW1 T S'],
  "computing": ['K AH0 M P Y UW1 T IH0 NG'],
  "computrac": ['K AA1 M P Y UW0 T R AE2 K'],
  "compuware": ['K AA1 M P Y UW0 W EH2 R'],
  "comrade": ['K AA1 M R AE2 D'],
  "comrades": ['K AA1 M R AE2 D Z'],
  "comrie": ['K AA1 M ER0 IY0'],
  "coms": ['K AA1 M Z'],
  "comsat": ['K AA1 M S AE0 T'],
  "comsat's": ['K AA1 M S AE0 T S'],
  "comstock": ['K AA1 M S T AA2 K'],
  "comtois": ['K AH0 M T W AA1'],
  "comtrex": ['K AA1 M T R EH2 K S'],
  "comunale": ['K OW0 M UW0 N AA1 L IY0'],
  "con": ['K AA1 N'],
  "cona": ['K OW1 N AH0'],
  "conable": ['K OW1 N AH0 B AH0 L'],
  "conable's": ['K OW1 N AH0 B AH0 L Z'],
  "conagra": ['K AA2 N AE1 G R AH0'],
  "conagra's": ['K AA2 N AE1 G R AH0 Z'],
  "conahan": ['K AA1 N AH0 HH AE0 N'],
  "conair": ['K AA1 N EH2 R'],
  "conal": ['K OW1 N AH0 L'],
  "conan": ['K OW1 N AH0 N'],
  "conant": ['K OW1 N AH0 N T'],
  "conant-pablos": ['K OW1 N AH0 N T P AA1 B L OW0 S'],
  "conard": ['K AA1 N ER0 D'],
  "conasupo": ['K AA2 N AH0 S UW1 P OW0'],
  "conatser": ['K AA1 N AH0 T S ER0'],
  "conaty": ['K AA1 N AH0 T IY0'],
  "conaway": ['K AA1 N AH0 W EY0'],
  "conboy": ['K AA1 N B OY0'],
  "conca": ['K AA1 NG K AH0'],
  "concannon": ['K AH0 N K AE1 N AH0 N'],
  "concatenate": ['K AH0 N K AE1 T AH0 N EY2 T'],
  "concatenated": ['K AH0 N K AE1 T AH0 N EY2 T AH0 D'],
  "concatenates": ['K AH0 N K AE1 T AH0 N EY2 T S'],
  "concatenating": ['K AH0 N K AE1 T AH0 N EY2 T IH0 NG'],
  "concatenation": ['K AH0 N K AE2 T AH0 N EY1 SH AH0 N'],
  "concave": ['K AA0 N K EY1 V', 'K AA1 N K EY0 V'],
  "concavity": ['K AA0 N K AA1 V AH0 T IY2'],
  "conceal": ['K AH0 N S IY1 L'],
  "concealed": ['K AH0 N S IY1 L D'],
  "concealing": ['K AH0 N S IY1 L IH0 NG'],
  "concealment": ['K AH0 N S IY1 L M AH0 N T'],
  "conceals": ['K AH0 N S IY1 L Z'],
  "concede": ['K AH0 N S IY1 D'],
  "conceded": ['K AH0 N S IY1 D IH0 D'],
  "concedes": ['K AH0 N S IY1 D Z'],
  "conceding": ['K AH0 N S IY1 D IH0 NG'],
  "conceit": ['K AH0 N S IY1 T'],
  "conceited": ['K AH0 N S IY1 T AH0 D'],
  "conceivable": ['K AH0 N S IY1 V AH0 B AH0 L'],
  "conceivably": ['K AH0 N S IY1 V AH0 B L IY0'],
  "conceive": ['K AH0 N S IY1 V'],
  "conceived": ['K AH0 N S IY1 V D'],
  "conceiving": ['K AH0 N S IY1 V IH0 NG'],
  "concentrate": ['K AA1 N S AH0 N T R EY2 T'],
  "concentrated": ['K AA1 N S AH0 N T R EY2 T IH0 D'],
  "concentrates": ['K AA1 N S AH0 N T R EY2 T S'],
  "concentrating": ['K AA1 N S AH0 N T R EY2 T IH0 NG'],
  "concentration": ['K AA2 N S AH0 N T R EY1 SH AH0 N'],
  "concentrations": ['K AA2 N S AH0 N T R EY1 SH AH0 N Z'],
  "concentric": ['K AH0 N S EH1 N T R IH0 K'],
  "concepcion": ['K AH0 N S EH2 P S IY0 OW1 N'],
  "concept": ['K AA1 N S EH0 P T'],
  "conception": ['K AH0 N S EH1 P SH AH0 N'],
  "conceptions": ['K AH0 N S EH1 P SH AH0 N Z'],
  "concepts": ['K AA1 N S EH0 P T S', 'K AA1 N S EH0 P S'],
  "conceptual": ['K AH0 N S EH1 P CH UW0 AH0 L'],
  "conceptualization": ['K AH0 N S EH1 P CH W AH0 L IH0 Z EY2 SH AH0 N'],
  "conceptualize": ['K AH0 N S EH1 P CH W AH0 L AY2 Z'],
  "conceptualizes": ['K AH0 N S EH1 P CH W AH0 L AY2 Z IH0 Z'],
  "conceptually": ['K AH0 N S EH1 P CH UW0 AH0 L IY0'],
  "concern": ['K AH0 N S ER1 N'],
  "concern's": ['K AH0 N S ER1 N Z'],
  "concerned": ['K AH0 N S ER1 N D'],
  "concerning": ['K AH0 N S ER1 N IH0 NG'],
  "concerns": ['K AH0 N S ER1 N Z'],
  "concerns'": ['K AH0 N S ER1 N Z'],
  "concert": ['K AA1 N S ER0 T', 'K AH0 N S ER1 T'],
  "concerted": ['K AH0 N S ER1 T AH0 D', 'K AH0 N S ER1 T IH0 D'],
  "concerti": ['K AH0 N CH EH1 R T IY0'],
  "concertina": ['K AA0 N S ER0 T IY1 N AH0'],
  "concertmaster": ['K AA1 N S ER0 T M AE2 S T ER0'],
  "concerto": ['K AH0 N CH EH1 R T OW0'],
  "concertos": ['K AH0 N CH EH1 R T OW0 Z'],
  "concerts": ['K AA1 N S ER0 T S', 'K AH0 N S ER1 T S'],
  "concession": ['K AH0 N S EH1 SH AH0 N'],
  "concessionaire": ['K AH0 N S EH2 SH AH0 N EH1 R'],
  "concessional": ['K AH0 N S EH1 SH AH0 N AH0 L'],
  "concessionary": ['K AH0 N S EH1 SH AH0 N EH2 R IY0'],
  "concessions": ['K AH0 N S EH1 SH AH0 N Z'],
  "conch": ['K AA1 N CH', 'K AA1 NG K'],
  "concha": ['K AA1 N CH AH0'],
  "conchemco": ['K AA2 N CH EH1 M K OW0'],
  "conchita": ['K AH0 N CH IY1 T AH0'],
  "conchoid": ['K AA1 N CH OY0 D'],
  "concierge": ['K AA2 N S IY0 EH1 R ZH'],
  "conciliation": ['K AH0 N S IH2 L IY0 EY1 SH AH0 N'],
  "conciliator": ['K AH0 N S IH1 L IY0 EY2 T ER0'],
  "conciliator's": ['K AH0 N S IH1 L IY0 EY2 T ER0 Z'],
  "conciliatory": ['K AH0 N S IH1 L IY2 AH0 T AO2 R IY0', 'K AH0 N S IH1 L Y AH0 T AO2 R IY0'],
  "concise": ['K AH0 N S AY1 S'],
  "concisely": ['K AH0 N S AY1 S L IY0'],
  "conclave": ['K AA1 N K L EY2 V'],
  "conclude": ['K AH0 N K L UW1 D'],
  "concluded": ['K AH0 N K L UW1 D AH0 D', 'K AH0 N K L UW1 D IH0 D'],
  "concludes": ['K AH0 N K L UW1 D Z'],
  "concluding": ['K AH0 N K L UW1 D IH0 NG'],
  "conclusion": ['K AH0 N K L UW1 ZH AH0 N'],
  "conclusions": ['K AH0 N K L UW1 ZH AH0 N Z'],
  "conclusive": ['K AH0 N K L UW1 S IH0 V'],
  "conclusively": ['K AH0 N K L UW1 S IH0 V L IY0'],
  "concoct": ['K AH0 N K AA1 K T'],
  "concocted": ['K AH0 N K AA1 K T AH0 D'],
  "concocting": ['K AH0 N K AA1 K T IH0 NG'],
  "concoction": ['K AH0 N K AA1 K SH AH0 N'],
  "concoctions": ['K AH0 N K AA1 K SH AH0 N Z'],
  "concomitant": ['K AA2 N K AA1 M AH0 T AH0 N T', 'K AA2 N K AH0 M IH1 T AH0 N T'],
  "concomitantly": ['K AA2 N K AA1 M AH0 T AH0 N T L IY0', 'K AA2 N K AH0 M IH1 T AH0 N T L IY0'],
  "concord": ['K AA1 N K AO2 R D', 'K AA1 N K ER0 D'],
  "concord's": ['K AA1 N K AO2 R D Z', 'K AA1 N K ER0 D Z'],
  "concorde": ['K AA1 N K AO2 R D'],
  "concourse": ['K AA1 N K AO2 R S'],
  "concourses": ['K AA1 N K AO2 R S IH0 Z'],
  "concrete": ['K AH0 N K R IY1 T', 'K AA1 N K R IY0 T'],
  "concretely": ['K AA1 N K R IY2 T L IY0'],
  "concubinage": ['K AA0 N K Y UW1 B AH0 N AH0 JH'],
  "concubine": ['K AA1 N K Y AH0 B AY2 N'],
  "concubines": ['K AA1 N K Y AH0 B AY2 N Z'],
  "concur": ['K AH0 N K ER1'],
  "concurred": ['K AH0 N K ER1 D'],
  "concurrence": ['K AH0 N K ER1 AH0 N S'],
  "concurrent": ['K AH0 N K ER1 AH0 N T'],
  "concurrently": ['K AH0 N K ER1 AH0 N T L IY0'],
  "concurring": ['K AH0 N K ER1 IH0 NG'],
  "concurs": ['K AH0 N K ER1 Z'],
  "concussion": ['K AH0 N K AH1 SH AH0 N'],
  "concussions": ['K AH0 N K AH1 SH AH0 N Z'],
  "conde": ['K AA1 N D'],
  "condello": ['K AH0 N D EH1 L OW0'],
  "condemn": ['K AH0 N D EH1 M'],
  "condemnation": ['K AA2 N D AH0 M N EY1 SH AH0 N'],
  "condemnations": ['K AA2 N D AH0 M N EY1 SH AH0 N Z'],
  "condemned": ['K AH0 N D EH1 M D'],
  "condemning": ['K AH0 N D EH1 M IH0 NG'],
  "condemns": ['K AH0 N D EH1 M Z'],
  "condensate": ['K AA1 N D AH0 N S EY2 T'],
  "condensates": ['K AA1 N D AH0 N S EY2 T S'],
  "condensation": ['K AA2 N D AH0 N S EY1 SH AH0 N'],
  "condense": ['K AH0 N D EH1 N S'],
  "condensed": ['K AH0 N D EH1 N S T'],
  "condenser": ['K AH0 N D EH1 N S ER0'],
  "condensing": ['K AH0 N D EH1 N S IH0 NG'],
  "conder": ['K AA1 N D ER0'],
  "condescend": ['K AA2 N D IH0 S EH1 N D'],
  "condescended": ['K AA2 N D IH0 S EH1 N D IH0 D'],
  "condescending": ['K AA2 N D IH0 S EH1 N D IH0 NG'],
  "condescension": ['K AA2 N D AH0 S EH1 N SH AH0 N'],
  "condie": ['K AA1 N D IY0'],
  "condiment": ['K AA1 N D AH0 M AH0 N T'],
  "condiments": ['K AA1 N D AH0 M AH0 N T S'],
  "condit": ['K AA1 N D IH0 T'],
  "condition": ['K AH0 N D IH1 SH AH0 N'],
  "conditional": ['K AH0 N D IH1 SH AH0 N AH0 L'],
  "conditionality": ['K AH0 N D IH2 SH AH0 N AE1 L IH0 T IY0'],
  "conditionally": ['K AH0 N D IH1 SH AH0 N AH0 L IY0', 'K AH0 N D IH1 SH N AH0 L IY0'],
  "conditioned": ['K AH0 N D IH1 SH AH0 N D'],
  "conditioner": ['K AH0 N D IH1 SH AH0 N ER0'],
  "conditioners": ['K AH0 N D IH1 SH AH0 N ER0 Z'],
  "conditioning": ['K AH0 N D IH1 SH AH0 N IH0 NG'],
  "conditions": ['K AH0 N D IH1 SH AH0 N Z'],
  "conditt": ['K AA1 N D IH0 T'],
  "condo": ['K AA1 N D OW0'],
  "condolence": ['K AH0 N D OW1 L AH0 N S'],
  "condolences": ['K AH0 N D OW1 L AH0 N S AH0 Z'],
  "condom": ['K AA1 N D AH0 M'],
  "condominium": ['K AA2 N D AH0 M IH1 N IY0 AH0 M'],
  "condominiums": ['K AA2 N D AH0 M IH1 N IY0 AH0 M Z'],
  "condoms": ['K AA1 N D AH0 M Z'],
  "condon": ['K AA1 N D AH0 N'],
  "condone": ['K AH0 N D OW1 N'],
  "condoned": ['K AH0 N D OW1 N D'],
  "condones": ['K AH0 N D OW1 N Z'],
  "condoning": ['K AH0 N D OW1 N IH0 NG'],
  "condor": ['K AA1 N D ER0'],
  "condors": ['K AA1 N D ER0 Z'],
  "condos": ['K AA1 N D OW0 Z'],
  "condra": ['K AA1 N D R AH0'],
  "condracky": ['K AA2 N D R AE1 K IY0'],
  "condracky's": ['K AA2 N D R AE1 K IY0 Z'],
  "condray": ['K AA1 N D R EY0'],
  "condren": ['K AA1 N D ER0 AH0 N'],
  "condrey": ['K AA1 N D R IY0'],
  "condron": ['K AA1 N D R AH0 N'],
  "condry": ['K AA1 N D ER0 IY0'],
  "conducive": ['K AH0 N D UW1 S IH0 V'],
  "conduct": ['K AA1 N D AH0 K T', 'K AA0 N D AH1 K T'],
  "conducted": ['K AH0 N D AH1 K T AH0 D'],
  "conducting": ['K AH0 N D AH1 K T IH0 NG'],
  "conduction": ['K AH0 N D AH1 K SH AH0 N'],
  "conductive": ['K AH0 N D AH1 K T IH0 V'],
  "conductivity": ['K AA2 N D AH2 K T IH1 V AH0 T IY0'],
  "conductor": ['K AH0 N D AH1 K T ER0'],
  "conductors": ['K AH0 N D AH1 K T ER0 Z'],
  "conducts": ['K AH0 N D AH1 K T S'],
  "conduit": ['K AA1 N D UW0 IH0 T', 'K AA1 N JH UW0 IH0 T', 'K AA1 N D W IH0 T'],
  "conduits": ['K AA1 N D UW0 AH0 T S', 'K AA1 N D W AH0 T S'],
  "cone": ['K OW1 N'],
  "cone's": ['K OW1 N Z'],
  "coneflower": ['K OW1 N F L AW2 ER0'],
  "conehead": ['K OW1 N HH EH0 D'],
  "coneheads": ['K OW1 N HH EH0 D Z'],
  "conely": ['K OW1 N L IY0'],
  "conerly": ['K OW1 N ER0 L IY0'],
  "conery": ['K OW1 N ER0 IY0'],
  "cones": ['K OW1 N Z'],
  "conestoga": ['K AA2 N AH0 S T OW1 G AH0'],
  "coney": ['K OW1 N IY0'],
  "confabulation": ['K AH0 N F AE2 B Y AH0 L EY1 SH AH0 N'],
  "confair": ['K AA1 N F EH2 R'],
  "confect": ['K AH0 N F EH1 K T'],
  "confection": ['K AH0 N F EH1 K SH AH0 N'],
  "confectionaries": ['K AH0 N F EH1 K SH AH0 N EH2 R IY0 Z'],
  "confectionary": ['K AH0 N F EH1 K SH AH0 N EH2 R IY0'],
  "confectioner": ['K AH0 N F EH1 K SH AH0 N ER0'],
  "confectioners": ['K AH0 N F EH1 K SH AH0 N ER0 Z'],
  "confectionery": ['K AH0 N F EH1 K SH AH0 N EH2 R IY0'],
  "confections": ['K AH0 N F EH1 K SH AH0 N Z'],
  "confederacy": ['K AH0 N F EH1 D ER0 AH0 S IY0', 'K AH0 N F EH1 D R AH0 S IY0'],
  "confederacy's": ['K AH0 N F EH1 D ER0 AH0 S IY0 Z', 'K AH0 N F EH1 D R AH0 S IY0 Z'],
  "confederate": ['K AH0 N F EH1 D ER0 AH0 T', 'K AH0 N F EH1 D ER0 EY2 T'],
  "confederates": ['K AH0 N F EH1 D ER0 AH0 T S'],
  "confederation": ['K AH0 N F EH2 D ER0 EY1 SH AH0 N'],
  "confer": ['K AH0 N F ER1'],
  "conferee": ['K AA2 N F ER0 IY1'],
  "conferees": ['K AA2 N F ER0 IY1 Z'],
  "conference": ['K AA1 N F ER0 AH0 N S', 'K AA1 N F R AH0 N S'],
  "conference's": ['K AA1 N F ER0 AH0 N S IH0 Z', 'K AA1 N F R AH0 N S IH0 Z'],
  "conferences": ['K AA1 N F ER0 AH0 N S AH0 Z', 'K AA1 N F R AH0 N S AH0 Z'],
  "conferencing": ['K AA1 N F R AH0 N S IH0 NG'],
  "conferred": ['K AH0 N F ER1 D'],
  "conferring": ['K AH0 N F ER1 IH0 NG'],
  "confers": ['K AH0 N F ER1 Z'],
  "confess": ['K AH0 N F EH1 S'],
  "confessed": ['K AH0 N F EH1 S T'],
  "confesses": ['K AH0 N F EH1 S IH0 Z'],
  "confessing": ['K AH0 N F EH1 S IH0 NG'],
  "confession": ['K AH0 N F EH1 SH AH0 N'],
  "confessional": ['K AH0 N F EH1 SH AH0 N AH0 L'],
  "confessionals": ['K AH0 N F EH1 SH AH0 N AH0 L Z'],
  "confessions": ['K AH0 N F EH1 SH AH0 N Z'],
  "confetti": ['K AH0 N F EH1 T IY0'],
  "confidant": ['K AA1 N F AH0 D AA2 N T'],
  "confidante": ['K AA1 N F AH0 D AE2 N T'],
  "confidants": ['K AA1 N F AH0 D AE2 N T S'],
  "confide": ['K AH0 N F AY1 D'],
  "confided": ['K AH0 N F AY1 D IH0 D'],
  "confidence": ['K AA1 N F AH0 D AH0 N S'],
  "confidence's": ['K AA1 N F AH0 D AH0 N S AH0 Z'],
  "confidences": ['K AA1 N F AH0 D AH0 N S IH0 Z'],
  "confident": ['K AA1 N F AH0 D AH0 N T'],
  "confidential": ['K AA2 N F AH0 D EH1 N SH AH0 L'],
  "confidentiality": ['K AA2 N F AH0 D EH2 N SH IY0 AE1 L AH0 T IY0', 'K AA2 N F AH0 D EH2 N CH IY0 AE1 L AH0 T IY0'],
  "confidentially": ['K AA2 N F AH0 D EH1 N SH AH0 L IY0'],
  "confidently": ['K AA1 N F AH0 D AH0 N T L IY0'],
  "confides": ['K AH0 N F AY1 D Z'],
  "confiding": ['K AH0 N F AY1 D IH0 NG'],
  "configuration": ['K AH0 N F IH2 G Y ER0 EY1 SH AH0 N'],
  "configurations": ['K AH0 N F IH2 G Y ER0 EY1 SH AH0 N Z'],
  "configure": ['K AH0 N F IH1 G Y ER0'],
  "configured": ['K AH0 N F IH1 G Y ER0 D'],
  "configuring": ['K AH0 N F IH1 G Y ER0 IH0 NG'],
  "confindustria": ['K AA2 N F IH0 N D AH1 S T R IY0 AH0'],
  "confine": ['K AH0 N F AY1 N'],
  "confined": ['K AH0 N F AY1 N D'],
  "confinement": ['K AH0 N F AY1 N M AH0 N T'],
  "confines": ['K AA1 N F AY2 N Z', 'K AH0 N F AY1 N Z'],
  "confining": ['K AH0 N F AY1 N IH0 NG'],
  "confirm": ['K AH0 N F ER1 M'],
  "confirmable": ['K AH0 N F ER1 M AH0 B AH0 L'],
  "confirmation": ['K AA2 N F ER0 M EY1 SH AH0 N'],
  "confirmations": ['K AA2 N F ER0 M EY1 SH AH0 N Z'],
  "confirmatory": ['K AH0 N F ER1 M AH0 T AO2 R IY0'],
  "confirmed": ['K AH0 N F ER1 M D'],
  "confirming": ['K AH0 N F ER1 M IH0 NG'],
  "confirms": ['K AH0 N F ER1 M Z'],
  "confiscate": ['K AA1 N F AH0 S K EY2 T'],
  "confiscated": ['K AA1 N F AH0 S K EY2 T AH0 D'],
  "confiscating": ['K AA1 N F AH0 S K EY2 T IH0 NG'],
  "confiscation": ['K AA2 N F AH0 S K EY1 SH AH0 N'],
  "confiscatory": ['K AH0 N F IH1 S K AH0 T AO2 R IY0'],
  "confiterias": ['K AA2 N F IH0 T IH1 R IY0 AH0 Z'],
  "conflagration": ['K AA2 N F L AH0 G R EY1 SH AH0 N'],
  "conflate": ['K AH0 N F L EY1 T'],
  "conflates": ['K AH0 N F L EY1 T S'],
  "conflation": ['K AH0 N F L EY1 SH AH0 N'],
  "conflict": ['K AA1 N F L IH0 K T', 'K AH0 N F L IH1 K T'],
  "conflicted": ['K AH0 N F L IH1 K T IH0 D'],
  "conflicting": ['K AH0 N F L IH1 K T IH0 NG'],
  "conflicts": ['K AH0 N F L IH1 K T S', 'K AA1 N F L IH0 K T S', 'K AH0 N F L IH1 K S', 'K AA1 N F L IH0 K S'],
  "confluence": ['K AA1 N F L UW0 AH0 N S'],
  "confluent": ['K AA0 N F L UW1 AH0 N T'],
  "conform": ['K AH0 N F AO1 R M'],
  "conformance": ['K AH0 N F AO1 R M AH0 N S'],
  "conformational": ['K AA2 N F ER0 M EY1 SH AH0 N AH0 L'],
  "conformed": ['K AH0 N F AO1 R M D'],
  "conforming": ['K AH0 N F AO1 R M IH0 NG'],
  "conformist": ['K AH0 N F AO1 R M IH0 S T'],
  "conformists": ['K AH0 N F AO1 R M AH0 S T S'],
  "conformity": ['K AH0 N F AO1 R M AH0 T IY0'],
  "conforms": ['K AH0 N F AO1 R M Z'],
  "conforti": ['K AA0 N F AO1 R T IY0'],
  "confound": ['K AA0 N F AW1 N D', 'K AA1 N F AW2 N D', 'K AH0 N F AW1 N D'],
  "confounded": ['K AH0 N F AW1 N D IH0 D'],
  "confounding": ['K AH0 N F AW1 N D IH0 NG'],
  "confounds": ['K AH0 N F AW1 N D Z'],
  "confrere": ['K AA1 N F R EH0 R'],
  "confreres": ['K AA1 N F R EH0 R Z'],
  "confront": ['K AH0 N F R AH1 N T'],
  "confrontation": ['K AA2 N F R AH0 N T EY1 SH AH0 N'],
  "confrontational": ['K AA2 N F R AH0 N T EY1 SH AH0 N AH0 L'],
  "confrontations": ['K AA2 N F R AH0 N T EY1 SH AH0 N Z'],
  "confronted": ['K AH0 N F R AH1 N T AH0 D', 'K AH0 N F R AH1 N T IH0 D'],
  "confronting": ['K AH0 N F R AH1 N T IH0 NG'],
  "confronts": ['K AH0 N F R AH1 N T S'],
  "confucian": ['K AH0 N F Y UW1 SH AH0 N'],
  "confucianism": ['K AH0 N F Y UW1 SH AH0 N IH2 Z AH0 M'],
  "confucius": ['K AH0 N F Y UW1 SH AH0 S'],
  "confuse": ['K AH0 N F Y UW1 Z'],
  "confused": ['K AH0 N F Y UW1 Z D'],
  "confuses": ['K AH0 N F Y UW1 Z IH0 Z'],
  "confusing": ['K AH0 N F Y UW1 Z IH0 NG'],
  "confusingly": ['K AH0 N F Y UW1 Z IH0 NG L IY0'],
  "confusion": ['K AH0 N F Y UW1 ZH AH0 N'],
  "confusions": ['K AH0 N F Y UW1 ZH AH0 N Z'],
  "cong": ['K AO1 NG'],
  "conga": ['K AO1 NG G AH0'],
  "congdon": ['K AA1 NG D AH0 N'],
  "congeal": ['K AH0 N JH IY1 L'],
  "congealed": ['K AH0 N JH IY1 L D'],
  "congenial": ['K AH0 N JH IY1 N Y AH0 L'],
  "congeniality": ['K AH0 N JH IY2 N IY0 AE1 L AH0 T IY0'],
  "congenital": ['K AH0 N JH EH1 N AH0 T AH0 L'],
  "conger": ['K AO1 NG ER0'],
  "congeries": ['K AO1 NG JH AH0 R IY2 Z'],
  "congest": ['K AH0 N JH EH1 S T'],
  "congested": ['K AH0 N JH EH1 S T AH0 D', 'K AH0 N JH EH1 S T IH0 D'],
  "congestion": ['K AH0 N JH EH1 S CH AH0 N'],
  "congestive": ['K AH0 N JH EH1 S T IH0 V'],
  "congleton": ['K AA1 NG G AH0 L T AA0 N'],
  "conglomerate": ['K AH0 N G L AA1 M ER0 AH0 T'],
  "conglomerate's": ['K AH0 N G L AA1 M ER0 AH0 T S'],
  "conglomerates": ['K AH0 N G L AA1 M ER0 AH0 T S'],
  "conglomeration": ['K AH0 N G L AA2 M ER0 EY1 SH AH0 N'],
  "congo": ['K AA1 NG G OW0'],
  "congo's": ['K AA1 NG G OW0 Z'],
  "congolese": ['K AA2 N G AH0 L IY1 Z'],
  "congrats": ['K AH0 N G R AE1 T S'],
  "congratulate": ['K AH0 N G R AE1 CH AH0 L EY2 T'],
  "congratulated": ['K AH0 N G R AE1 CH AH0 L EY2 T IH0 D'],
  "congratulating": ['K AH0 N G R AE1 CH AH0 L EY2 T IH0 NG'],
  "congratulation": ['K AH0 N G R AE2 CH AH0 L EY1 SH AH0 N'],
  "congratulations": ['K AH0 N G R AE2 CH AH0 L EY1 SH AH0 N Z'],
  "congratulatory": ['K AH0 N G R AE1 CH AH0 L AH0 T AO2 R IY0'],
  "congregant": ['K AA1 NG G R AH0 G AH0 N T'],
  "congregants": ['K AA1 NG G R AH0 G AH0 N T S'],
  "congregate": ['K AA1 NG G R AH0 G EY2 T'],
  "congregated": ['K AA1 NG G R IH0 G EY2 T IH0 D'],
  "congregation": ['K AA2 NG G R AH0 G EY1 SH AH0 N'],
  "congregation's": ['K AA2 NG G R AH0 G EY1 SH AH0 N Z'],
  "congregational": ['K AA2 NG G R AH0 G EY1 SH AH0 N AH0 L'],
  "congregations": ['K AA2 NG G R AH0 G EY1 SH AH0 N Z'],
  "congress": ['K AA1 NG G R AH0 S'],
  "congress'": ['K AA1 N G R AH0 S IH0 Z', 'K AA1 NG G R AH0 S'],
  "congress's": ['K AA1 NG G R AH0 S IH0 Z'],
  "congresses": ['K AA1 NG G R AH0 S IH0 Z'],
  "congressional": ['K AH0 N G R EH1 SH AH0 N AH0 L'],
  "congressionally": ['K AH0 N G R EH1 SH AH0 N AH0 L IY0', 'K AH0 N G R EH1 SH N AH0 L IY0'],
  "congressman": ['K AA1 NG G R AH0 S M AH0 N'],
  "congressman's": ['K AA1 NG G R AH0 S M AH0 N Z'],
  "congressmen": ['K AA1 NG G R AH0 S M IH0 N'],
  "congresspeople": ['K AA1 NG G R AH0 S P IY2 P AH0 L'],
  "congressperson": ['K AA1 NG G R AH0 S P ER2 S AH0 N'],
  "congresspersons": ['K AA1 NG G R AH0 S P ER2 S AH0 N Z'],
  "congresswoman": ['K AA1 NG G R AH0 S W UH2 M AH0 N'],
  "congresswoman's": ['K AA1 NG G R AH0 S W UH2 M AH0 N Z'],
  "congresswomen": ['K AA1 NG G R AH0 S W IH2 M IH0 N'],
  "congrove": ['K AA1 NG G R AH0 V'],
  "congruence": ['K AO1 N G R UW0 AH0 N S'],
  "congruent": ['K AO1 N G R UW0 EH2 N T'],
  "congruity": ['K AH0 N G R UW1 AH0 T IY0'],
  "conic": ['K AA1 N IH0 K', 'K OW1 N IH0 K'],
  "conical": ['K AA1 N IH0 K AH0 L', 'K OW1 N IH0 K AH0 L'],
  "conics": ['K AA1 N IH0 K S', 'K OW1 N IH0 K S'],
  "conifer": ['K AA1 N AH0 F ER0'],
  "conifer's": ['K AA1 N AH0 F ER0 Z'],
  "coniferous": ['K AH0 N IH1 F ER0 AH0 S'],
  "conifers": ['K AA1 N AH0 F ER0 Z'],
  "conigliaro": ['K AH0 N IH2 G L IY0 AA1 R OW0'],
  "coniglio": ['K AH0 N IH1 G L IY0 OW0'],
  "coniston": ['K AA1 N AH0 S T AH0 N'],
  "conjecture": ['K AH0 N JH EH1 K CH ER0', 'K AH0 N JH EH1 K SH ER0'],
  "conjectures": ['K AH0 N JH EH1 K CH ER0 Z', 'K AH0 N JH EH1 K SH ER0 Z'],
  "conjoin": ['K AA2 N JH OY1 N'],
  "conjoined": ['K AA2 N JH OY1 N D'],
  "conjugal": ['K AA1 N JH AH0 G AH0 L'],
  "conjugate": ['K AA2 N JH AH0 G EY1 T', 'K AA1 N JH AH0 G EY2 T'],
  "conjugated": ['K AA2 N JH AH0 G EY1 T IH0 D', 'K AA1 N JH AH0 G EY2 T IH0 D'],
  "conjugates": ['K AA2 N JH AH0 G EY1 T S', 'K AA1 N JH AH0 G EY2 T S'],
  "conjugation": ['K AA2 N JH AH0 G EY1 SH AH0 N'],
  "conjugations": ['K AA2 N JH AH0 G EY1 SH AH0 N Z'],
  "conjul": ['K AA1 N JH AH0 L'],
  "conjunction": ['K AH0 N JH AH1 NG K SH AH0 N'],
  "conjunctions": ['K AH0 N JH AH1 NG K SH AH0 N Z'],
  "conjunctiva": ['K AA2 N JH AH0 NG K T AY1 V AH0'],
  "conjure": ['K AA1 N JH ER0'],
  "conjured": ['K AA1 N JH ER0 D'],
  "conjurer": ['K AA1 N JH AH0 R AH2 R'],
  "conjures": ['K AA1 N JH ER0 Z'],
  "conjuring": ['K AA1 N JH ER0 IH0 NG'],
  "conjuror": ['K AA1 N JH ER0 ER0'],
  "conk": ['K AA1 NG K'],
  "conkel": ['K AA1 NG K AH0 L'],
  "conkey": ['K AA1 N K IY0'],
  "conkin": ['K AA1 NG K IH0 N'],
  "conkle": ['K AA1 NG K AH0 L'],
  "conklin": ['K AA1 NG K L IH0 N'],
  "conkling": ['K AA1 NG K L IH0 NG'],
  "conkright": ['K AA1 NG K R AY2 T'],
  "conlan": ['K AA1 N L AH0 N'],
  "conlee": ['K AA1 N L IY0'],
  "conley": ['K AA1 N L IY0'],
  "conlin": ['K AA1 N L IH0 N'],
  "conlon": ['K AA1 N L AH0 N'],
  "conlow": ['K AA1 N L OW0'],
  "conly": ['K AA1 N L IY0'],
  "conn": ['K AA1 N'],
  "conn.": ['K AA1 N', 'K AH0 N EH1 T AH0 K AH0 T'],
  "connally": ['K AA1 N AH0 L IY0'],
  "connally's": ['K AA1 N AH0 L IY0 Z'],
  "connan": ['K AA1 N AH0 N'],
  "connaught": ['K AA1 N AO0 T'],
  "connaught's": ['K AA1 N AO0 T S'],
  "connaughton": ['K AA1 N AO0 T AA0 N'],
  "connaway": ['K AA1 N AH0 W EY2'],
  "connect": ['K AH0 N EH1 K T'],
  "connected": ['K AH0 N EH1 K T IH0 D'],
  "connecter": ['K AH0 N EH1 K T ER0'],
  "connecticut": ['K AH0 N EH1 T AH0 K AH0 T'],
  "connecticut's": ['K AH0 N EH1 T AH0 K AH0 T S'],
  "connecting": ['K AH0 N EH1 K T IH0 NG'],
  "connection": ['K AH0 N EH1 K SH AH0 N'],
  "connections": ['K AH0 N EH1 K SH AH0 N Z'],
  "connective": ['K AH0 N EH1 K T IH0 V'],
  "connectivity": ['K AH0 N EH0 K T IH1 V IH0 T IY0'],
  "connector": ['K AH0 N EH1 K T ER0'],
  "connectors": ['K AH0 N EH1 K T ER0 Z'],
  "connects": ['K AH0 N EH1 K T S'],
  "conned": ['K AA1 N D'],
  "conneely": ['K AH0 N IY1 L IY0'],
  "connell": ['K AA1 N AH0 L'],
  "connelley": ['K AA1 N IH0 L IY0'],
  "connelly": ['K AA1 N AH0 L IY0'],
  "connelly's": ['K AA1 N AH0 L IY0 Z'],
  "connely": ['K AA1 N L IY0'],
  "conner": ['K AA1 N ER0'],
  "conner's": ['K AA1 N ER0 Z'],
  "connerly": ['K AA1 N ER0 L IY0'],
  "conners": ['K AA1 N ER0 Z'],
  "connery": ['K AA1 N ER0 IY0'],
  "connery's": ['K AA1 N ER0 IY0 Z'],
  "connett": ['K AA1 N IH0 T'],
  "connexion": ['K AH0 N EH1 K SH AH0 N'],
  "connexions": ['K AH0 N EH1 K SH AH0 N Z'],
  "connick": ['K AA1 N IH0 K'],
  "connie": ['K AO1 N IY0'],
  "connie's": ['K AO1 N IY0 Z'],
  "conniff": ['K AA1 N IH0 F'],
  "conning": ['K AA1 N IH0 NG'],
  "conniption": ['K AH0 N IH1 P SH AH0 N'],
  "connivance": ['K AH0 N AY1 V AH0 N S'],
  "connive": ['K AH0 N AY1 V'],
  "conniving": ['K AH0 N AY1 V IH0 NG'],
  "connoisseur": ['K AA2 N AH0 S ER1'],
  "connoisseurs": ['K AA2 N AH0 S ER1 Z'],
  "connole": ['K AA1 N AH0 L'],
  "connolly": ['K AO1 N AH0 L IY0'],
  "connon": ['K AA1 N AH0 N'],
  "connor": ['K AA1 N ER0'],
  "connors": ['K AA1 N ER0 Z'],
  "connotation": ['K AA2 N AH0 T EY1 SH AH0 N'],
  "connotational": ['K AA2 N AH0 T EY1 SH AH0 N AH0 L'],
  "connotations": ['K AA2 N AH0 T EY1 SH AH0 N Z'],
  "connote": ['K AH0 N OW1 T'],
  "connotes": ['K AH0 N OW1 T S'],
  "conny": ['K AA1 N IY0'],
  "conoco": ['K AA1 N AH0 K OW0', 'K AH0 N AA1 K OW0'],
  "conoco's": ['K AA1 N AH0 K OW0 Z'],
  "conolly": ['K AA1 N OW0 L IY0'],
  "conoly": ['K AA1 N OW0 L IY0'],
  "conover": ['K AA1 N AH0 V ER0'],
  "conquer": ['K AA1 NG K ER0'],
  "conquered": ['K AA1 NG K ER0 D'],
  "conquering": ['K AA1 NG K ER0 IH0 NG'],
  "conqueror": ['K AA1 NG K ER0 ER0'],
  "conqueror's": ['K AA1 NG K ER0 ER0 Z'],
  "conquerors": ['K AA1 NG K ER0 ER0 Z'],
  "conquers": ['K AA1 NG K ER0 Z'],
  "conquest": ['K AA1 NG K W EH0 S T'],
  "conquest's": ['K AA1 NG K W EH0 S T S'],
  "conquests": ['K AA1 N K W EH2 S T S'],
  "conrac": ['K AA1 N R AE0 K'],
  "conrad": ['K AA1 N R AE0 D'],
  "conrad's": ['K AA1 N R AE0 D Z'],
  "conrades": ['K AH0 N R EY1 D Z'],
  "conradi": ['K AA0 N R AA1 D IY0'],
  "conradine": ['K AA1 N R AH0 D AY2 N'],
  "conrads": ['K AA1 N R AE0 D Z'],
  "conradt": ['K AA1 N R AE0 T'],
  "conrady": ['K AH0 N R AA1 D IY0'],
  "conrail": ['K AA1 N R EY2 L'],
  "conrail's": ['K AA1 N R EY2 L Z'],
  "conran": ['K AA1 N R AH0 N'],
  "conrath": ['K AA1 N R AH0 TH'],
  "conrey": ['K AA1 N R IY0'],
  "conroe": ['K AA1 N R OW0'],
  "conrow": ['K AA1 N R OW0'],
  "conroy": ['K AO1 N R OY0'],
  "conry": ['K AA1 N R IY0'],
  "cons": ['K AA1 N Z'],
  "consalvo": ['K AA0 N S AA1 L V OW0'],
  "conscience": ['K AA1 N SH AH0 N S'],
  "consciences": ['K AA1 N CH IH0 N S IH0 Z'],
  "conscientious": ['K AA2 N SH IY0 EH1 N SH AH0 S'],
  "conscientiously": ['K AA2 N CH IY0 EH1 N CH AH0 S L IY0'],
  "conscious": ['K AA1 N SH AH0 S'],
  "consciously": ['K AA1 N SH AH0 S L IY0'],
  "consciousness": ['K AA1 N SH AH0 S N AH0 S'],
  "conscript": ['K AA1 N S K R IH2 P T', 'K AH0 N S K R IH1 P T'],
  "conscripted": ['K AH0 N S K R IH1 P T IH0 D'],
  "conscription": ['K AH0 N S K R IH1 P SH AH0 N'],
  "conscripts": ['K AA1 N S K R IH0 P T S'],
  "conseco": ['K AA0 N S EY1 K OW0', 'K AH0 N S EY1 K OW0'],
  "conseco's": ['K AA0 N S EY1 K OW0 Z', 'K AH0 N S EY1 K OW0 Z'],
  "consecrate": ['K AA1 N S AH0 K R EY2 T'],
  "consecrated": ['K AA1 N S AH0 K R EY2 T IH0 D'],
  "consecration": ['K AA2 N S AH0 K R EY1 SH AH0 N'],
  "consecrations": ['K AA2 N S AH0 K R EY1 SH AH0 N Z'],
  "consecutive": ['K AH0 N S EH1 K Y AH0 T IH0 V'],
  "consecutively": ['K AH0 N S EH1 K Y AH0 T IH0 V L IY0'],
  "consensual": ['K AH0 N S EH1 N S UW0 AH0 L', 'K AH0 N S EH1 N SH UW0 AH0 L'],
  "consensus": ['K AH0 N S EH1 N S AH0 S'],
  "consent": ['K AH0 N S EH1 N T'],
  "consented": ['K AH0 N S EH1 N T IH0 D', 'K AH0 N S EH1 N IH0 D'],
  "consenting": ['K AH0 N S EH1 N T IH0 NG', 'K AH0 N S EH1 N IH0 NG'],
  "consentino": ['K AA0 N S EH0 N T IY1 N OW0'],
  "consents": ['K AH0 N S EH1 N T S'],
  "consequence": ['K AA1 N S AH0 K W AH0 N S'],
  "consequences": ['K AA1 N S AH0 K W EH2 N S AH0 Z'],
  "consequent": ['K AA1 N S AH0 K W AH0 N T'],
  "consequential": ['K AA2 N S AH0 K W EH1 N SH AH0 L'],
  "consequently": ['K AA1 N S AH0 K W AH0 N T L IY0', 'K AA1 N S AH0 K W EH2 N T L IY0'],
  "conser": ['K AA1 N S ER0'],
  "conservancy": ['K AH0 N S ER1 V AH0 N S IY0'],
  "conservancy's": ['K AH0 N S ER1 V AH0 N S IY0 Z'],
  "conservation": ['K AA2 N S ER0 V EY1 SH AH0 N'],
  "conservationist": ['K AA2 N S ER0 V EY1 SH AH0 N IH0 S T'],
  "conservationists": ['K AA2 N S ER0 V EY1 SH AH0 N IH0 S T S'],
  "conservatism": ['K AH0 N S ER1 V AH0 T IH2 Z AH0 M'],
  "conservative": ['K AH0 N S ER1 V AH0 T IH0 V'],
  "conservatively": ['K AH0 N S ER1 V AH0 T IH0 V L IY0'],
  "conservatives": ['K AH0 N S ER1 V AH0 T IH0 V Z'],
  "conservatives'": ['K AH0 N S ER1 V AH0 T IH0 V Z'],
  "conservativism": ['K AH0 N S ER1 V AH0 T IH0 V IH2 Z AH0 M'],
  "conservator": ['K AH0 N S ER1 V AH0 T ER0'],
  "conservatories": ['K AH0 N S ER1 V AH0 T AO2 R IY0 Z'],
  "conservators": ['K AH0 N S ER1 V AH0 T ER0 Z'],
  "conservatorship": ['K AH0 N S ER1 V AH0 T ER0 SH IH2 P'],
  "conservatory": ['K AH0 N S ER1 V AH0 T AO0 R IY0'],
  "conserve": ['K AH0 N S ER1 V'],
  "conserved": ['K AH0 N S ER1 V D'],
  "conserving": ['K AH0 N S ER1 V IH0 NG'],
  "conshohocken": ['K AA2 N SH AH0 HH AA1 K AH0 N'],
  "consider": ['K AH0 N S IH1 D ER0'],
  "considerable": ['K AH0 N S IH1 D ER0 AH0 B AH0 L'],
  "considerably": ['K AH0 N S IH1 D ER0 AH0 B L IY0'],
  "considerate": ['K AH0 N S IH1 D ER0 AH0 T'],
  "consideration": ['K AH0 N S IH2 D ER0 EY1 SH AH0 N'],
  "considerations": ['K AH0 N S IH2 D ER0 EY1 SH AH0 N Z'],
  "considered": ['K AH0 N S IH1 D ER0 D'],
  "considering": ['K AH0 N S IH1 D ER0 IH0 NG'],
  "considers": ['K AH0 N S IH1 D ER0 Z'],
  "considine": ['K AA1 N S IH0 D AY2 N'],
  "consiglio": ['K AA0 N S IY1 G L IY0 OW0'],
  "consign": ['K AH0 N S AY1 N'],
  "consigned": ['K AH0 N S AY1 N D'],
  "consignment": ['K AH0 N S AY1 N M AH0 N T'],
  "consis": ['K AA1 N S IH0 S'],
  "consist": ['K AH0 N S IH1 S T'],
  "consisted": ['K AH0 N S IH1 S T AH0 D', 'K AH0 N S IH1 S T IH0 D'],
  "consistence": ['K AH0 N S IH1 S T AH0 N S'],
  "consistency": ['K AH0 N S IH1 S T AH0 N S IY0'],
  "consistent": ['K AH0 N S IH1 S T AH0 N T'],
  "consistently": ['K AH0 N S IH1 S T AH0 N T L IY0'],
  "consisting": ['K AH0 N S IH1 S T IH0 NG'],
  "consists": ['K AH0 N S IH1 S T S'],
  "consob": ['K AA1 N S AA0 B'],
  "consol": ['K AA1 N S AA0 L'],
  "consol's": ['K AA1 N S AA0 L Z'],
  "consolable": ['K AH0 N S OW1 L AH0 B AH0 L'],
  "consolably": ['K AH0 N S OW1 L AH0 B L IY0'],
  "consolata": ['K AA0 N S OW0 L AA1 T AH0'],
  "consolation": ['K AA2 N S AH0 L EY1 SH AH0 N'],
  "consolations": ['K AA2 N S AH0 L EY1 SH AH0 N Z'],
  "console": ['K AA1 N S OW0 L', 'K AH0 N S OW1 L'],
  "consoled": ['K AH0 N S OW1 L D'],
  "consoles": ['K AH0 N S OW1 L Z'],
  "consoli": ['K AA0 N S OW1 L IY0'],
  "consolidate": ['K AH0 N S AA1 L IH0 D EY2 T'],
  "consolidated": ['K AH0 N S AA1 L AH0 D EY2 T AH0 D'],
  "consolidated's": ['K AH0 N S AA1 L IH0 D EY2 T IH0 D Z'],
  "consolidates": ['K AH0 N S AA1 L IH0 D EY2 T S'],
  "consolidating": ['K AH0 N S AA1 L AH0 D EY2 T IH0 NG'],
  "consolidation": ['K AH0 N S AA2 L AH0 D EY1 SH AH0 N'],
  "consolidations": ['K AH0 N S AA2 L IH0 D EY1 SH AH0 N Z'],
  "consolidator": ['K AH0 N S AA1 L IH0 D EY2 T ER0'],
  "consolidators": ['K AH0 N S AA1 L IH0 D EY2 T ER0 Z'],
  "consoling": ['K AH0 N S OW1 L IH0 NG'],
  "consolo": ['K AA0 N S OW1 L OW0'],
  "consonant": ['K AA1 N S AH0 N AH0 N T'],
  "consonantal": ['K AA2 N S AH0 N AA1 N T AH0 L', 'K AA2 N S AH0 N AA1 N AH0 L'],
  "consonants": ['K AA1 N S AH0 N AH0 N T S'],
  "consort": ['K AH0 N S AO1 R T'],
  "consortia": ['K AH0 N S AO1 R SH AH0'],
  "consorting": ['K AH0 N S AO1 R T IH0 NG'],
  "consortium": ['K AH0 N S AO1 R SH IY0 AH0 M', 'K AH0 N S AO1 R SH Y AH0 M'],
  "consortium's": ['K AH0 N S AO1 R SH IY0 AH0 M Z', 'K AH0 N S AO1 R SH Y AH0 M Z'],
  "consortiums": ['K AH0 N S AO1 R SH AH0 M Z', 'K AH0 N S AO1 R SH Y AH0 M Z'],
  "conspicuous": ['K AH0 N S P IH1 K Y UW0 AH0 S'],
  "conspicuously": ['K AH0 N S P IH1 K Y UW0 AH0 S L IY0'],
  "conspiracies": ['K AH0 N S P IH1 R AH0 S IY0 Z'],
  "conspiracy": ['K AH0 N S P IH1 R AH0 S IY0'],
  "conspirator": ['K AH0 N S P IH1 R AH0 T ER0'],
  "conspiratorial": ['K AH0 N S P IH2 R AH0 T AO1 R IY0 AH0 L'],
  "conspiratorially": ['K AH0 N S P IH2 R AH0 T AO1 R IY0 AH0 L IY0', 'K AH0 N S P IH2 R AH0 T AO1 R Y AH0 L IY0'],
  "conspirators": ['K AH0 N S P IH1 R AH0 T ER0 Z'],
  "conspire": ['K AH0 N S P AY1 ER0'],
  "conspired": ['K AH0 N S P AY1 ER0 D'],
  "conspiring": ['K AH0 N S P AY1 R IH0 NG'],
  "constable": ['K AA1 N S T AH0 B AH0 L'],
  "constables": ['K AA1 N S T AH0 B AH0 L Z'],
  "constabulary": ['K AH0 N S T AE1 B Y AH0 L EH2 R IY0'],
  "constance": ['K AA1 N S T AH0 N S'],
  "constancy": ['K AA1 N S T AH0 N S IY0'],
  "constant": ['K AA1 N S T AH0 N T'],
  "constant's": ['K AA1 N S T AH0 N T S'],
  "constanta": ['K AA0 N S T AA1 N T AH0'],
  "constantin": ['K AH0 N S T AE1 N T IH0 N'],
  "constantina": ['K AA0 N S T AA0 N T IY1 N AH0'],
  "constantine": ['K AA1 N S T AH0 N T IY2 N', 'K AA1 N S T AH0 N T AY2 N'],
  "constantinides": ['K AA0 N S T AH0 N T AH0 N IY1 D AH0 S', 'K AA2 N S T AE0 N T AH0 N IY1 D IY0 Z'],
  "constantino": ['K AA2 N S T AH0 N T IY1 N OW0'],
  "constantinople": ['K AA2 N S T AE0 N T AH0 N OW1 P AH0 L'],
  "constantinos": ['K AA2 N S T AH0 N T IY1 N OW0 S'],
  "constantly": ['K AA1 N S T AH0 N T L IY0'],
  "constants": ['K AA1 N S T AH0 N T S'],
  "constar": ['K AA1 N S T AA2 R'],
  "constellation": ['K AA2 N S T AH0 L EY1 SH AH0 N'],
  "constellation's": ['K AA2 N S T AH0 L EY1 SH AH0 N Z'],
  "constellations": ['K AA2 N S T AH0 L EY1 SH AH0 N Z'],
  "consternation": ['K AA2 N S T ER0 N EY1 SH AH0 N'],
  "constipate": ['K AA1 N S T AH0 P EY2 T'],
  "constipated": ['K AA1 N S T AH0 P EY2 T AH0 D'],
  "constipates": ['K AA1 N S T AH0 P EY2 T S'],
  "constipation": ['K AA2 N S T AH0 P EY1 SH AH0 N'],
  "constituencies": ['K AH0 N S T IH1 CH UW0 AH0 N S IY0 Z'],
  "constituency": ['K AH0 N S T IH1 CH UW0 AH0 N S IY0'],
  "constituent": ['K AH0 N S T IH1 CH UW0 AH0 N T'],
  "constituents": ['K AH0 N S T IH1 CH UW0 AH0 N T S'],
  "constituents'": ['K AH0 N S T IH1 CH UW0 AH0 N T S'],
  "constitute": ['K AA1 N S T AH0 T UW2 T'],
  "constituted": ['K AA1 N S T AH0 T UW2 T AH0 D'],
  "constitutes": ['K AA1 N S T AH0 T UW2 T S'],
  "constituting": ['K AA1 N S T AH0 T UW2 T IH0 NG'],
  "constitution": ['K AA2 N S T AH0 T UW1 SH AH0 N'],
  "constitution's": ['K AA2 N S T IH0 T UW1 SH AH0 N Z'],
  "constitutional": ['K AA2 N S T AH0 T UW1 SH AH0 N AH0 L'],
  "constitutionality": ['K AA2 N S T IH0 T UW2 SH AH0 N AE1 L IH0 T IY0'],
  "constitutionally": ['K AA2 N S T AH0 T UW1 SH AH0 N AH0 L IY0'],
  "constitutionist": ['K AA2 N S T AH0 T UW1 SH AH0 N IH0 S T'],
  "constitutionists": ['K AA2 N S T AH0 T UW1 SH AH0 N IH0 S T S'],
  "constitutions": ['K AA2 N S T IH0 T UW1 SH AH0 N Z'],
  "constitutive": ['K AA2 N S T IH1 T UW0 T IH2 V'],
  "constrain": ['K AH0 N S T R EY1 N'],
  "constrained": ['K AH0 N S T R EY1 N D'],
  "constraining": ['K AH0 N S T R EY1 N IH0 NG'],
  "constrains": ['K AH0 N S T R EY1 N Z'],
  "constraint": ['K AH0 N S T R EY1 N T'],
  "constraints": ['K AH0 N S T R EY1 N T S'],
  "constrict": ['K AH0 N S T R IH1 K T'],
  "constricted": ['K AH0 N S T R IH1 K T AH0 D'],
  "constricting": ['K AH0 N S T R IH1 K T IH0 NG'],
  "constriction": ['K AH0 N S T R IH1 K SH AH0 N'],
  "constrictions": ['K AH0 N S T R IH1 K SH AH0 N Z'],
  "constrictor": ['K AH0 N S T R IH1 K T ER0'],
  "constrictors": ['K AH0 N S T R IH1 K T ER0 Z'],
  "construcciones": ['K AH0 N S T R UW1 CH IY0 OW2 N EY0 Z'],
  "construct": ['K AH0 N S T R AH1 K T', 'K AA1 N S T R AH0 K T'],
  "constructed": ['K AH0 N S T R AH1 K T AH0 D', 'K AH0 N S T R AH1 K T IH0 D'],
  "constructing": ['K AH0 N S T R AH1 K T IH0 NG'],
  "construction": ['K AH0 N S T R AH1 K SH AH0 N'],
  "constructionist": ['K AH0 N S T R AH1 K SH AH0 N IH0 S T'],
  "constructions": ['K AH0 N S T R AH1 K SH AH0 N Z'],
  "constructive": ['K AH0 N S T R AH1 K T IH0 V'],
  "constructively": ['K AH0 N S T R AH1 K T IH0 V L IY0'],
  "constructor": ['K AH0 N S T R AH1 K T ER0'],
  "constructors": ['K AH0 N S T R AH1 K T ER0 Z'],
  "constructs": ['K AH0 N S T R AH1 K T S', 'K AA1 N S T R AH0 K T S'],
  "construe": ['K AH0 N S T R UW1'],
  "construed": ['K AH0 N S T R UW1 D'],
  "consuela": ['K AH0 N S W EY1 L AH0'],
  "consul": ['K AA1 N S AH0 L'],
  "consular": ['K AA1 N S AH0 L ER0'],
  "consulate": ['K AA1 N S AH0 L AH0 T'],
  "consulates": ['K AA1 N S AH0 L AH0 T S'],
  "consuls": ['K AA1 N S AH0 L Z'],
  "consulship": ['K AA1 N S AH0 L SH IH2 P'],
  "consult": ['K AH0 N S AH1 L T'],
  "consultancy": ['K AH0 N S AH1 L T AH0 N S IY0'],
  "consultant": ['K AH0 N S AH1 L T AH0 N T'],
  "consultant's": ['K AH0 N S AH1 L T AH0 N T S'],
  "consultants": ['K AH0 N S AH1 L T AH0 N T S'],
  "consultants'": ['K AH0 N S AH1 L T AH2 N T S'],
  "consultation": ['K AA2 N S AH0 L T EY1 SH AH0 N'],
  "consultations": ['K AA2 N S AH0 L T EY1 SH AH0 N Z'],
  "consultative": ['K AH0 N S AH1 L T AH0 T IH0 V'],
  "consulted": ['K AH0 N S AH1 L T AH0 D', 'K AH0 N S AH1 L T IH0 D'],
  "consulting": ['K AH0 N S AH1 L T IH0 NG'],
  "consults": ['K AH0 N S AH1 L T S'],
  "consumable": ['K AH0 N S UW1 M AH0 B AH0 L'],
  "consume": ['K AH0 N S UW1 M'],
  "consumed": ['K AH0 N S UW1 M D'],
  "consumer": ['K AH0 N S UW1 M ER0'],
  "consumer's": ['K AH0 N S UW1 M ER0 Z'],
  "consumerism": ['K AH0 N S UW1 M ER0 IH2 Z AH0 M'],
  "consumerist": ['K AH0 N S UW1 M ER0 IH0 S T'],
  "consumerists": ['K AH0 N S UW1 M ER0 IH0 S T S'],
  "consumers": ['K AH0 N S UW1 M ER0 Z'],
  "consumers'": ['K AH0 N S UW1 M ER0 Z'],
  "consumes": ['K AH0 N S UW1 M Z'],
  "consuming": ['K AH0 N S UW1 M IH0 NG'],
  "consummate": ['K AA1 N S AH0 M AH0 T', 'K AA1 N S AH0 M EY2 T'],
  "consummated": ['K AA1 N S AH0 M EY2 T AH0 D'],
  "consummates": ['K AA1 N S AH0 M EY2 T S'],
  "consummating": ['K AA1 N S AH0 M EY2 T IH0 NG'],
  "consummation": ['K AA2 N S AH0 M EY1 SH AH0 N'],
  "consumption": ['K AH0 N S AH1 M P SH AH0 N', 'K AH0 N S AH1 M SH AH0 N'],
  "contac": ['K AA1 N T AE0 K'],
  "contact": ['K AA1 N T AE2 K T'],
  "contacted": ['K AA1 N T AE2 K T IH0 D'],
  "contacting": ['K AA1 N T AE2 K T IH0 NG'],
  "contactless": ['K AA1 N T AE0 K T L EH2 S'],
  "contacts": ['K AA1 N T AE2 K T S', 'K AA1 N T AE2 K S'],
  "contadora": ['K AA2 N T AH0 D AO1 R AH0'],
  "contagion": ['K AH0 N T EY1 JH AH0 N'],
  "contagious": ['K AH0 N T EY1 JH AH0 S'],
  "contagiousness": ['K AH0 N T EY1 JH AH0 S N AH0 S'],
  "contain": ['K AH0 N T EY1 N'],
  "contained": ['K AH0 N T EY1 N D'],
  "container": ['K AH0 N T EY1 N ER0'],
  "container's": ['K AH0 N T EY1 N ER0 Z'],
  "containerboard": ['K AH0 N T EY1 N ER0 B AO2 R D'],
  "containerize": ['K AH0 N T EY1 N ER0 AY2 Z'],
  "containerized": ['K AH0 N T EY1 N ER0 AY2 Z D'],
  "containers": ['K AH0 N T EY1 N ER0 Z'],
  "containers'": ['K AH0 N T EY1 N ER0 Z'],
  "containing": ['K AH0 N T EY1 N IH0 NG'],
  "containment": ['K AH0 N T EY1 N M AH0 N T'],
  "contains": ['K AH0 N T EY1 N Z'],
  "contaminant": ['K AH0 N T AE1 M AH0 N AH0 N T'],
  "contaminants": ['K AH0 N T AE1 M AH0 N AH0 N T S'],
  "contaminate": ['K AH0 N T AE1 M AH0 N EY2 T'],
  "contaminated": ['K AH0 N T AE1 M AH0 N EY2 T IH0 D'],
  "contaminates": ['K AH0 N T AE1 M AH0 N EY2 T S'],
  "contaminating": ['K AH0 N T AE1 M AH0 N EY2 T IH0 NG'],
  "contamination": ['K AH0 N T AE2 M AH0 N EY1 SH AH0 N'],
  "contant": ['K AA1 N T AH0 N T'],
  "conte": ['K AO1 N T', 'K AO1 N T EY0'],
  "contel": ['K AA1 N T EH2 L'],
  "contemn": ['K AH2 N T EH1 M'],
  "contemplate": ['K AA1 N T AH0 M P L EY2 T'],
  "contemplated": ['K AA1 N T AH0 M P L EY2 T IH0 D'],
  "contemplates": ['K AA1 N T AH0 M P L EY2 T S'],
  "contemplating": ['K AA1 N T AH0 M P L EY2 T IH0 NG'],
  "contemplation": ['K AA2 N T AH0 M P L EY1 SH AH0 N'],
  "contemplative": ['K AH0 N T EH1 M P L AH0 T IH0 V'],
  "contempo": ['K AA2 N T EH1 M P OW0'],
  "contemporaneous": ['K AH0 N T EH2 M P ER0 EY1 N IY0 AH0 S'],
  "contemporaneously": ['K AH0 N T EH2 M P ER0 EY1 N IY0 AH0 S L IY0'],
  "contemporaries": ['K AH0 N T EH1 M P ER0 EH2 R IY0 Z'],
  "contemporary": ['K AH0 N T EH1 M P ER0 EH2 R IY0'],
  "contempt": ['K AH0 N T EH1 M P T'],
  "contemptible": ['K AH0 N T EH1 M P T AH0 B AH0 L'],
  "contemptuous": ['K AH0 N T EH1 M P CH UW0 AH0 S'],
  "contemptuously": ['K AH0 N T EH1 M P CH W AH0 S L IY0'],
  "contend": ['K AH0 N T EH1 N D'],
  "contended": ['K AH0 N T EH1 N D IH0 D'],
  "contender": ['K AH0 N T EH1 N D ER0'],
  "contenders": ['K AH0 N T EH1 N D ER0 Z'],
  "contending": ['K AH0 N T EH1 N D IH0 NG'],
  "contends": ['K AH0 N T EH1 N D Z', 'K AH0 N T EH1 N Z'],
  "content": ['K AA1 N T EH0 N T', 'K AH0 N T EH1 N T'],
  "contented": ['K AH0 N T EH1 N T AH0 D', 'K AH0 N T EH1 N T IH0 D'],
  "contentedly": ['K AH0 N T EH1 N T AH0 D L IY0'],
  "contention": ['K AH0 N T EH1 N SH AH0 N'],
  "contentioned": ['K AH0 N T EH1 N SH AH0 N D'],
  "contentions": ['K AH0 N T EH1 N SH AH0 N Z'],
  "contentious": ['K AH0 N T EH1 N SH AH0 S'],
  "contentiousness": ['K AH0 N T EH1 N SH AH0 S N AH0 S'],
  "contentment": ['K AH0 N T EH1 N T M AH0 N T'],
  "contento": ['K AH0 N T EH1 N T OW0'],
  "contents": ['K AA1 N T EH0 N T S', 'K AH0 N T EH1 N T S'],
  "contest": ['K AA1 N T EH0 S T', 'K AH0 N T EH1 S T'],
  "contest's": ['K AA1 N T EH0 S T S'],
  "contestable": ['K AH0 N T EH1 S T AH0 B AH0 L'],
  "contestant": ['K AH0 N T EH1 S T AH0 N T'],
  "contestants": ['K AH0 N T EH1 S T AH0 N T S'],
  "contested": ['K AH0 N T EH1 S T AH0 D'],
  "contesting": ['K AH0 N T EH1 S T IH0 NG'],
  "contests": ['K AA1 N T EH0 S T S', 'K AH0 N T EH1 S T S', 'K AA1 N T EH0 S', 'K AH0 N T EH1 S'],
  "context": ['K AA1 N T EH0 K S T'],
  "contexts": ['K AA1 N T EH2 K S T S'],
  "contextual": ['K AA2 N T EH1 K S CH UW2 AH0 L'],
  "conti": ['K AA1 N T IY0'],
  "contibel": ['K AA1 N T IH0 B AH0 L'],
  "conticommodity": ['K AA2 N T IH0 K AH0 M AA1 D IH0 T IY0'],
  "contiguous": ['K AH0 N T IH1 G Y UW0 AH0 S'],
  "continent": ['K AA1 N T AH0 N AH0 N T'],
  "continent's": ['K AA1 N T AH0 N AH0 N T S'],
  "continental": ['K AA2 N T AH0 N EH1 N T AH0 L', 'K AA2 N T AH0 N EH1 N AH0 L'],
  "continental's": ['K AA2 N T AH0 N EH1 N T AH0 L Z'],
  "continentally": ['K AA2 N T AH0 N EH1 N T AH0 L IY0', 'K AA2 N T AH0 N EH1 N AH0 L IY0'],
  "continentals": ['K AA2 N T AH0 N EH1 N T AH0 L Z', 'K AA2 N T AH0 N EH1 N AH0 L Z'],
  "continents": ['K AA1 N T AH0 N AH0 N T S'],
  "contingencies": ['K AH0 N T IH1 N JH AH0 N S IY0 Z'],
  "contingency": ['K AH0 N T IH1 N JH AH0 N S IY0'],
  "contingent": ['K AH0 N T IH1 N JH AH0 N T'],
  "contingents": ['K AH0 N T IH1 N JH AH0 N T S'],
  "contini": ['K AH0 N T IY1 N IY0'],
  "contino": ['K AA0 N T IY1 N OW0'],
  "continual": ['K AH0 N T IH1 N Y UW0 AH0 L'],
  "continually": ['K AH0 N T IH1 N Y UW0 AH0 L IY0', 'K AH0 N T IH1 N Y UW0 L IY0'],
  "continuance": ['K AH0 N T IH1 N Y UW0 AH0 N S'],
  "continuances": ['K AH0 N T IH1 N Y UW0 AH0 N S IH0 Z'],
  "continuation": ['K AH0 N T IH2 N Y UW0 EY1 SH AH0 N'],
  "continue": ['K AH0 N T IH1 N Y UW0'],
  "continued": ['K AH0 N T IH1 N Y UW0 D'],
  "continues": ['K AH0 N T IH1 N Y UW0 Z'],
  "continuing": ['K AH0 N T IH1 N Y UW0 IH0 NG'],
  "continuity": ['K AA2 N T AH0 N UW1 AH0 T IY0'],
  "continuous": ['K AH0 N T IH1 N Y UW0 AH0 S'],
  "continuously": ['K AH0 N T IH1 N Y UW0 AH0 S L IY0'],
  "continuum": ['K AH0 N T IH1 N Y UW0 AH0 M'],
  "contois": ['K AH0 N T W AA1'],
  "contort": ['K AH0 N T AO1 R T'],
  "contorted": ['K AH0 N T AO1 R T AH0 D'],
  "contortion": ['K AH0 N T AO1 R SH AH0 N'],
  "contortionist": ['K AH0 N T AO1 R SH AH0 N AH0 S T'],
  "contortions": ['K AH0 N T AO1 R SH AH0 N Z'],
  "contorts": ['K AH0 N T AO1 R T S'],
  "contos": ['K AA1 N T OW0 Z'],
  "contour": ['K AA1 N T UH2 R'],
  "contoured": ['K AA1 N T UH2 R D'],
  "contours": ['K AA1 N T UH2 R Z'],
  "contra": ['K AA1 N T R AH0'],
  "contraband": ['K AA1 N T R AH0 B AE2 N D'],
  "contrabassoon": ['K AA1 N T R AH0 B AE0 S UW1 N'],
  "contraception": ['K AA2 N T R AH0 S EH1 P SH AH0 N'],
  "contraceptive": ['K AA2 N T R AH0 S EH1 P T IH0 V'],
  "contraceptives": ['K AA2 N T R AH0 S EH1 P T IH0 V Z'],
  "contract": ['K AA1 N T R AE2 K T', 'K AH0 N T R AE1 K T'],
  "contract's": ['K AA1 N T R AE2 K T S'],
  "contracted": ['K AA1 N T R AE0 K T AH0 D'],
  "contracting": ['K AA1 N T R AE0 K T IH0 NG'],
  "contraction": ['K AH0 N T R AE1 K SH AH0 N'],
  "contractionary": ['K AH0 N T R AE1 K SH AH0 N EH0 R IY0'],
  "contractions": ['K AH0 N T R AE1 K SH AH0 N Z'],
  "contractor": ['K AA1 N T R AE2 K T ER0'],
  "contractor's": ['K AA1 N T R AE2 K T ER0 Z'],
  "contractors": ['K AA1 N T R AE2 K T ER0 Z'],
  "contractors'": ['K AH0 N T R AE1 K T ER0 Z'],
  "contracts": ['K AA1 N T R AE2 K T S', 'K AH0 N T R AE1 K T S'],
  "contractual": ['K AH0 N T R AE1 K CH UW0 AH0 L'],
  "contractually": ['K AH0 N T R AE1 K CH UW0 AH0 L IY0'],
  "contradict": ['K AA2 N T R AH0 D IH1 K T'],
  "contradicted": ['K AA2 N T R AH0 D IH1 K T AH0 D'],
  "contradicting": ['K AA2 N T R AH0 D IH1 K T IH0 NG'],
  "contradiction": ['K AA2 N T R AH0 D IH1 K SH AH0 N'],
  "contradictions": ['K AA2 N T R AH0 D IH1 K SH AH0 N Z'],
  "contradictorily": ['K AA2 N T R AH0 D IH1 K T ER0 AH0 L IY0'],
  "contradictory": ['K AA2 N T R AH0 D IH1 K T ER0 IY0'],
  "contradicts": ['K AA2 N T R AH0 D IH1 K T S'],
  "contran": ['K AA1 N T R AE2 N'],
  "contraption": ['K AH0 N T R AE1 P SH AH0 N'],
  "contraptions": ['K AH0 N T R AE1 P SH AH0 N Z'],
  "contrapunction": ['K AH2 N T R AE0 P UH1 K CH AH0 N'],
  "contrapunctual": ['K AH2 N T R AE0 P UH1 K CH UW2 AH0 L'],
  "contrarian": ['K AA2 N T R EH1 R IY0 AH0 N'],
  "contrarians": ['K AH0 N T R EH1 R IY0 AH0 N Z'],
  "contrariness": ['K AA1 N T R EH0 R IY0 N AH0 S'],
  "contrary": ['K AA1 N T R EH0 R IY0', 'K AH0 N T R EH1 R IY0'],
  "contras": ['K AA1 N T R AH0 Z', 'K AO1 N T R AH0 Z'],
  "contras'": ['K AA1 N T R AH0 Z'],
  "contrast": ['K AA1 N T R AE0 S T', 'K AH0 N T R AE1 S T'],
  "contrasted": ['K AH0 N T R AE1 S T AH0 D'],
  "contrasting": ['K AH0 N T R AE1 S T IH0 NG'],
  "contrasts": ['K AA1 N T R AE0 S T S', 'K AH0 N T R AE1 S T S', 'K AA1 N T R AE0 S', 'K AH0 N T R AE1 S'],
  "contravene": ['K AA1 N T R AH0 V IY2 N'],
  "contravention": ['K AA2 N T R AH0 V EH1 N CH AH0 N'],
  "contreras": ['K AA0 N T R EH1 R AA0 Z'],
  "contretemps": ['K AA1 N T R AH0 T EH2 M P S'],
  "contribute": ['K AH0 N T R IH1 B Y UW0 T'],
  "contributed": ['K AH0 N T R IH1 B Y UW0 T IH0 D'],
  "contributes": ['K AH0 N T R IH1 B Y UW0 T S'],
  "contributing": ['K AH0 N T R IH1 B Y UW0 T IH0 NG'],
  "contribution": ['K AA2 N T R AH0 B Y UW1 SH AH0 N'],
  "contributions": ['K AA2 N T R AH0 B Y UW1 SH AH0 N Z'],
  "contributor": ['K AH0 N T R IH1 B Y AH0 T ER0'],
  "contributors": ['K AH0 N T R IH1 B Y AH0 T ER0 Z'],
  "contributory": ['K AH0 N T R IH1 B Y AH0 T AO2 R IY0'],
  "contrite": ['K AH0 N T R AY1 T'],
  "contrition": ['K AH0 N T R IH1 SH AH0 N'],
  "contrivance": ['K AH0 N T R AY1 V AH0 N S'],
  "contrivances": ['K AH0 N T R AY1 V AH0 N S IH0 Z'],
  "contrive": ['K AH0 N T R AY1 V'],
  "contrived": ['K AH0 N T R AY1 V D'],
  "control": ['K AH0 N T R OW1 L'],
  "control's": ['K AH0 N T R OW1 L Z'],
  "controladora": ['K AH0 N T R OW2 L AH0 D AO1 R AH0'],
  "controllable": ['K AH0 N T R OW1 L AH0 B AH0 L'],
  "controlled": ['K AH0 N T R OW1 L D'],
  "controller": ['K AH0 N T R OW1 L ER0'],
  "controller's": ['K AH0 N T R OW1 L ER0 Z'],
  "controllers": ['K AH0 N T R OW1 L ER0 Z'],
  "controllers'": ['K AH0 N T R AA1 L ER0 Z'],
  "controlling": ['K AH0 N T R OW1 L IH0 NG'],
  "controls": ['K AH0 N T R OW1 L Z'],
  "controls'": ['K AA1 N T R AA0 L Z'],
  "controversial": ['K AA2 N T R AH0 V ER1 SH AH0 L'],
  "controversies": ['K AA1 N T R AH0 V ER2 S IY0 Z'],
  "controversy": ['K AA1 N T R AH0 V ER2 S IY0'],
  "contura": ['K AA2 N T UH1 R AH0'],
  "contusion": ['K AH0 N T UW1 ZH AH0 N'],
  "contusions": ['K AH0 N T UW1 ZH AH0 N Z'],
  "conundrum": ['K AH0 N AH1 N D R AH0 M'],
  "conus": ['K OW1 N AH0 S'],
  "convair": ['K AA0 N V EH1 R'],
  "convalesce": ['K AA2 N V AH0 L EH1 S'],
  "convalescence": ['K AA2 N V AH0 L EH1 S AH0 N S'],
  "convalescent": ['K AA2 N V AH0 L EH1 S AH0 N T'],
  "convection": ['K AH0 N V EH1 K SH AH0 N'],
  "convective": ['K AH0 N V EH1 K T IH0 V'],
  "convene": ['K AH0 N V IY1 N'],
  "convened": ['K AH0 N V IY1 N D'],
  "convenes": ['K AH0 N V IY1 N Z'],
  "convenience": ['K AH0 N V IY1 N Y AH0 N S'],
  "conveniences": ['K AH0 N V IY1 N Y AH0 N S IH0 Z'],
  "convenient": ['K AH0 N V IY1 N Y AH0 N T'],
  "conveniently": ['K AH0 N V IY1 N Y AH0 N T L IY0'],
  "convening": ['K AH0 N V IY1 N IH0 NG'],
  "convent": ['K AA1 N V AH0 N T', 'K AA1 N V EH2 N T'],
  "convention": ['K AH0 N V EH1 N SH AH0 N'],
  "convention's": ['K AH0 N V EH1 N SH AH0 N Z'],
  "conventional": ['K AH0 N V EH1 N SH AH0 N AH0 L'],
  "conventionally": ['K AH0 N V EH1 N SH AH0 N AH0 L IY0'],
  "conventioneer": ['K AH0 N V EH2 N SH AH0 N IH1 R'],
  "conventioneers": ['K AH0 N V EH2 N SH AH0 N IH1 R Z'],
  "conventions": ['K AH0 N V EH1 N SH AH0 N Z'],
  "convents": ['K AA1 N V AH0 N T S', 'K AA1 N V EH2 N T S'],
  "converge": ['K AH0 N V ER1 JH'],
  "converged": ['K AH0 N V ER1 JH D'],
  "convergence": ['K AH0 N V ER1 JH AH0 N S'],
  "convergent": ['K AH0 N V ER1 JH AH0 N T'],
  "converging": ['K AH0 N V ER1 JH IH0 NG'],
  "conversant": ['K AH0 N V ER1 S AH0 N T'],
  "conversation": ['K AA2 N V ER0 S EY1 SH AH0 N'],
  "conversational": ['K AA2 N V ER0 S EY1 SH AH0 N AH0 L'],
  "conversationalist": ['K AA2 N V ER0 S EY1 SH AH0 N AH0 L AH0 S T'],
  "conversations": ['K AA2 N V ER0 S EY1 SH AH0 N Z'],
  "converse": ['K AA1 N V ER0 S', 'K AH0 N V ER1 S'],
  "conversed": ['K AH0 N V ER1 S T'],
  "conversely": ['K AA1 N V ER0 S L IY0'],
  "converses": ['K AA1 N V ER0 S AH0 Z', 'K AH0 N V ER1 S AH0 Z'],
  "conversing": ['K AH0 N V ER1 S IH0 NG'],
  "conversion": ['K AH0 N V ER1 ZH AH0 N'],
  "conversion's": ['K AH0 N V ER1 ZH AH0 N Z'],
  "conversions": ['K AH0 N V ER1 ZH AH0 N Z'],
  "convert": ['K AA1 N V ER0 T', 'K AH0 N V ER1 T'],
  "converted": ['K AH0 N V ER1 T IH0 D'],
  "converter": ['K AH0 N V ER1 T ER0'],
  "converters": ['K AH0 N V ER1 T ER0 Z'],
  "convertibility": ['K AA2 N V ER0 T IH0 B IH1 L IH0 T IY0'],
  "convertible": ['K AH0 N V ER1 T AH0 B AH0 L'],
  "convertibles": ['K AH0 N V ER1 T AH0 B AH0 L Z'],
  "converting": ['K AH0 N V ER1 T IH0 NG'],
  "converts": ['K AA1 N V ER0 T S', 'K AH0 N V ER1 T S'],
  "convery": ['K AA1 N V ER0 IY0'],
  "convex": ['K AH0 N V EH1 K S', 'K AA1 N V EH2 K S'],
  "convey": ['K AH0 N V EY1'],
  "conveyance": ['K AH0 N V EY1 AH0 N S'],
  "conveyed": ['K AH0 N V EY1 D'],
  "conveyer": ['K AH0 N V EY1 ER0'],
  "conveying": ['K AH0 N V EY1 IH0 NG'],
  "conveyor": ['K AH0 N V EY1 ER0'],
  "conveys": ['K AH0 N V EY1 Z'],
  "convict": ['K AA1 N V IH0 K T', 'K AH0 N V IH1 K T'],
  "convict's": ['K AA1 N V IH0 K T S'],
  "convicted": ['K AH0 N V IH1 K T AH0 D'],
  "convicting": ['K AH0 N V IH1 K T IH0 NG'],
  "conviction": ['K AH0 N V IH1 K SH AH0 N'],
  "convictions": ['K AH0 N V IH1 K SH AH0 N Z'],
  "convicts": ['K AA1 N V IH0 K T S', 'K AH0 N V IH1 K T S'],
  "conville": ['K AA1 N V IH0 L'],
  "convince": ['K AH0 N V IH1 N S'],
  "convinced": ['K AH0 N V IH1 N S T'],
  "convinces": ['K AH0 N V IH1 N S IH0 Z'],
  "convincing": ['K AH0 N V IH1 N S IH0 NG'],
  "convincingly": ['K AH0 N V IH1 N S IH0 NG L IY0'],
  "convivial": ['K AH0 N V IH1 V IY0 AH0 L'],
  "convocation": ['K AA2 N V AH0 K EY1 SH AH0 N'],
  "convoke": ['K AA0 N V OW1 K'],
  "convoked": ['K AA0 N V OW1 K T'],
  "convolute": ['K AA1 N V AH0 L UW2 T'],
  "convoluted": ['K AA1 N V AH0 L UW2 T AH0 D'],
  "convolution": ['K AA1 N V AH0 L UW2 SH AH0 N'],
  "convoy": ['K AA1 N V OY2'],
  "convoys": ['K AA1 N V OY2 Z'],
  "convulsant": ['K AH0 V AH1 L S AH0 N T'],
  "convulsants": ['K AH0 N V AH1 L S AH0 N T S'],
  "convulsion": ['K AH0 N V AH1 L SH AH0 N'],
  "convulsions": ['K AH0 N V AH1 L SH AH0 N Z'],
  "convulsive": ['K AH0 N V AH1 L S IH0 V'],
  "conway": ['K AA1 N W EY2'],
  "conwell": ['K AA1 N W EH2 L'],
  "conyer": ['K AA1 N Y ER0'],
  "conyers": ['K AA1 N Y ER0 Z'],
  "coo": ['K UW1'],
  "coochie": ['K UW1 CH IY0'],
  "coody": ['K UW1 D IY0'],
  "cooed": ['K UW1 D'],
  "coogan": ['K UW1 G AH0 N'],
  "coogle": ['K UW1 G AH0 L'],
  "coogler": ['K UW1 G AH0 L ER0', 'K UW1 G L ER0'],
  "cooing": ['K UW1 IH0 NG'],
  "cook": ['K UH1 K'],
  "cook's": ['K UH1 K S'],
  "cookbook": ['K UH1 K B UH2 K'],
  "cookbook's": ['K UH1 K B UH2 K S'],
  "cookbooks": ['K UH1 K B UH2 K S'],
  "cooke": ['K UH1 K'],
  "cooked": ['K UH1 K T'],
  "cooker": ['K UH1 K ER0'],
  "cookers": ['K UH1 K ER0 Z'],
  "cookie": ['K UH1 K IY0'],
  "cookies": ['K UH1 K IY0 Z'],
  "cookin'": ['K UH1 K IH0 N'],
  "cooking": ['K UH1 K IH0 NG'],
  "cookingham": ['K UH1 K IH0 NG HH AE0 M'],
  "cookman": ['K UH1 K M AH0 N'],
  "cookout": ['K UH1 K AW2 T'],
  "cookouts": ['K UH1 K AW2 T S'],
  "cooks": ['K UH1 K S'],
  "cooksey": ['K UH1 K S IY0'],
  "cookson": ['K UH1 K S AH0 N'],
  "cookston": ['K UH1 K S T AH0 N'],
  "cookware": ['K UH1 K W EH2 R'],
  "cool": ['K UW1 L'],
  "coolant": ['K UW1 L AH0 N T'],
  "coolants": ['K UW1 L AH0 N T S'],
  "coolbaugh": ['K UW1 L B AO2'],
  "coole": ['K UW1 L'],
  "cooled": ['K UW1 L D'],
  "cooler": ['K UW1 L ER0'],
  "coolers": ['K UW1 L ER0 Z'],
  "coolest": ['K UW1 L AH0 S T'],
  "cooley": ['K UW1 L IY0'],
  "cooley's": ['K UW1 L IY0 Z'],
  "coolidge": ['K UW1 L IH0 JH'],
  "coolidge's": ['K UW1 L IH0 JH AH0 Z'],
  "cooling": ['K UW1 L IH0 NG'],
  "coolio": ['K UW1 L IY2 OW0', 'K UW1 L Y OW0'],
  "coolly": ['K UW1 L IY0'],
  "coolman": ['K UW1 L M AH0 N'],
  "coolness": ['K UW1 L N AH0 S'],
  "cools": ['K UW1 L Z'],
  "coombe": ['K UW1 M B', 'K UW1 M'],
  "coombes": ['K UW1 M B Z', 'K UW1 M Z'],
  "coombs": ['K UW1 M Z'],
  "coomer": ['K UW1 M ER0'],
  "coomes": ['K UW1 M Z'],
  "coon": ['K UW1 N'],
  "coonan": ['K UW1 N AH0 N'],
  "coonce": ['K UW1 N S'],
  "coone": ['K UW1 N'],
  "cooner": ['K UW1 N ER0'],
  "coones": ['K UW1 N Z'],
  "cooney": ['K UW1 N IY0'],
  "coonradt": ['K UW1 N R AE0 T'],
  "coonrod": ['K UW1 N R AA2 D'],
  "coons": ['K UW1 N Z'],
  "coontz": ['K UW1 N T S'],
  "coop": ['K UW1 P'],
  "cooped": ['K UW1 P T'],
  "cooper": ['K UW1 P ER0'],
  "cooper's": ['K UW1 P ER0 Z'],
  "cooperage": ['K UW1 P ER0 AH0 JH'],
  "cooperate": ['K OW0 AA1 P ER0 EY2 T', 'K W AA1 P ER0 EY2 T'],
  "cooperated": ['K OW0 AA1 P ER0 EY2 T AH0 D', 'K W AA1 P ER0 EY2 T AH0 D'],
  "cooperates": ['K OW0 AA1 P ER0 EY2 T S'],
  "cooperating": ['K OW0 AA1 P ER0 EY2 T IH0 NG'],
  "cooperation": ['K OW0 AA2 P ER0 EY1 SH AH0 N', 'K W AA2 P ER0 EY1 SH AH0 N', 'K W AO2 P ER0 EY1 SH AH0 N'],
  "cooperations": ['K OW0 AA2 P ER0 EY1 SH AH0 N Z', 'K W AA2 P ER0 EY1 SH AH0 N Z'],
  "cooperative": ['K OW0 AA1 P ER0 EY2 T IH0 V'],
  "cooperatively": ['K OW0 AA1 P R AH0 T IH0 V L IY0'],
  "cooperatives": ['K OW0 AA1 P R AH0 T IH0 V Z'],
  "cooperider": ['K UW1 P ER0 AY0 D ER0'],
  "cooperman": ['K UW1 P ER0 M AE2 N'],
  "cooperrider": ['K UW1 P ER0 AY0 D ER0'],
  "coopers": ['K UW1 P ER0 Z'],
  "coopersmith": ['K UW1 P ER0 S M IH2 TH'],
  "cooperstein": ['K UW1 P ER0 S T AY2 N', 'K UW1 P ER0 S T IY2 N'],
  "cooperstown": ['K UW1 P ER0 Z T AW2 N'],
  "coopervision": ['K UW1 P ER0 V IH2 ZH AH0 N'],
  "coopman": ['K UW1 P M AH0 N'],
  "cooprider": ['K UW1 P R AY2 D ER0'],
  "coordinate": ['K OW0 AO1 R D AH0 N AH0 T', 'K OW0 AO1 R D AH0 N EY2 T'],
  "coordinated": ['K OW0 AO1 R D AH0 N EY0 T IH0 D'],
  "coordinates": ['K OW0 AO1 R D AH0 N AH0 T S', 'K OW0 AO1 R D AH0 N EY2 T S'],
  "coordinating": ['K OW0 AO1 R D AH0 N EY2 T IH0 NG'],
  "coordination": ['K OW0 AO2 R D AH0 N EY1 SH AH0 N'],
  "coordinator": ['K OW0 AO1 R D AH0 N EY2 T ER0'],
  "coordinators": ['K OW0 AO1 R D AH0 N EY2 T ER0 Z'],
  "coors": ['K UW1 R Z'],
  "coors's": ['K UW1 R Z IH0 Z'],
  "coos": ['K UW1 S'],
  "coosa": ['K UW1 S AH0'],
  "coot": ['K UW1 T'],
  "coote": ['K UW1 T'],
  "cooter": ['K UW1 T ER0'],
  "coots": ['K UW1 T S'],
  "coover": ['K UW1 V ER0'],
  "coovick": ['K UW1 V IH0 K'],
  "cop": ['K AA1 P'],
  "copa": ['K OW1 P AH0'],
  "copacabana": ['K OW2 P AH0 K AH0 B AE1 N AH0'],
  "copartner": ['K OW1 P AA2 R T N ER0'],
  "copartners": ['K OW1 P AA2 R T N ER0 Z'],
  "copas": ['K OW1 P AH0 Z'],
  "copayment": ['K OW1 P EY1 M AH0 N T'],
  "copayments": ['K OW1 P EY1 M AH0 N T S'],
  "copco": ['K AA1 P K OW0'],
  "cope": ['K OW1 P'],
  "coped": ['K OW1 P T'],
  "copelan": ['K AA1 P IH0 L AE0 N'],
  "copeland": ['K OW1 P L AH0 N D'],
  "copeland's": ['K OW1 P L AH0 N D Z'],
  "copelco": ['K OW2 P EH1 L K OW0'],
  "copelin": ['K AA1 P IH0 L IH0 N'],
  "copeman": ['K OW1 P M AH0 N'],
  "copen": ['K OW1 P AH0 N'],
  "copenhagen": ['K OW1 P AH0 N HH EY2 G AH0 N', 'K OW1 P AH0 N HH AA2 G AH0 N'],
  "copenhagen's": ['K OW1 P AH0 N HH EY2 G AH0 N Z', 'K OW1 P AH0 N HH AA2 G AH0 N Z'],
  "copenhaver": ['K AH0 P EH1 N HH AH0 V ER0'],
  "copernican": ['K AH0 P ER1 N AH0 K AH0 N'],
  "copernicus": ['K AH0 P ER1 N AH0 K AH0 S'],
  "copes": ['K OW1 P S'],
  "copetas": ['K AH0 P EY1 T AH0 S', 'K AH0 P IY1 T AH0 S'],
  "copher": ['K AA1 F ER0'],
  "copiague": ['K OW1 P IY0 AE0 G'],
  "copied": ['K AA1 P IY0 D'],
  "copier": ['K AA1 P IY0 ER0'],
  "copiers": ['K AA1 P IY0 ER0 Z'],
  "copies": ['K AA1 P IY0 Z'],
  "copilot": ['K OW1 P AY0 L AH0 T'],
  "copilots": ['K OW1 P AY0 L AH0 T S'],
  "coping": ['K OW1 P IH0 NG'],
  "copious": ['K OW1 P IY0 AH0 S'],
  "copiously": ['K OW1 P Y AH0 S L IY0'],
  "coplan": ['K AA1 P L AH0 N'],
  "copland": ['K AA1 P L AH0 N D'],
  "coplen": ['K AA1 P AH0 L AH0 N'],
  "copley": ['K AA1 P L IY0'],
  "copley's": ['K AA1 P L IY0 Z'],
  "copleys": ['K AA1 P L IY0 Z'],
  "coplin": ['K AA1 P L IH0 N'],
  "copolla": ['K OW0 P OW1 L AH0'],
  "copolla's": ['K OW0 P OW1 L AH0 Z'],
  "copp": ['K AA1 P'],
  "coppa": ['K OW1 P AH0'],
  "coppage": ['K AA1 P IH0 JH'],
  "copped": ['K AA1 P T'],
  "coppedge": ['K AA1 P IH0 JH'],
  "coppee": ['K AA1 P IY1'],
  "coppel": ['K AA1 P AH0 L'],
  "coppens": ['K AA1 P AH0 N Z'],
  "copper": ['K AA1 P ER0'],
  "copper's": ['K AA1 P ER0 Z'],
  "copper-green": ['K AA1 P ER0 G R IY2 N'],
  "copperfield": ['K AA1 P ER0 F IY2 L D'],
  "copperfield's": ['K AA1 P ER0 F IY2 L D Z'],
  "copperfields": ['K AA1 P ER0 F IY2 L D Z'],
  "copperfields'": ['K AA1 P ER0 F IY2 L D Z'],
  "copperhead": ['K AA1 P ER0 HH EH2 D'],
  "copperheads": ['K AA1 P ER0 HH EH2 D Z'],
  "copperman": ['K AA1 P ER0 M AH0 N'],
  "coppernoll": ['K AH0 P ER1 N OW0 L'],
  "coppers": ['K AA1 P ER0 Z'],
  "coppersmith": ['K AA1 P ER0 S M IH2 TH'],
  "copperweld": ['K AA1 P ER0 W EH2 L D'],
  "coppery": ['K AA1 P ER0 IY0'],
  "coppess": ['K AA1 P AH0 S'],
  "coppin": ['K AA1 P IH0 N'],
  "copping": ['K AA1 P IH0 NG'],
  "coppinger": ['K AA1 P IH0 NG ER0'],
  "coppins": ['K AA1 P IH0 N Z'],
  "copple": ['K AA1 P AH0 L'],
  "coppock": ['K AA1 P AH0 K'],
  "coppola": ['K OW0 P OW1 L AH0'],
  "coppola's": ['K OW0 P OW1 L AH0 Z'],
  "coppolino": ['K OW0 P OW0 L IY1 N OW0'],
  "copps": ['K AA1 P S'],
  "copra": ['K OW1 P R AH0'],
  "coprocessor": ['K OW1 P R AA1 S EH0 S ER0'],
  "coprocessors": ['K OW1 P R AA1 S EH0 S ER0 Z'],
  "cops": ['K AA1 P S'],
  "copsey": ['K AA1 P S IY0'],
  "coptic": ['K AA1 P T IH0 K'],
  "copulate": ['K AA1 P Y AH0 L EY2 T'],
  "copus": ['K OW1 P AH0 S'],
  "copy": ['K AA1 P IY0'],
  "copycat": ['K AA1 P IY0 K AE2 T'],
  "copycats": ['K AA1 P IY0 K AE2 T S'],
  "copycatting": ['K AA1 P IY0 K AE2 T IH0 NG'],
  "copycode": ['K AA1 P IY0 K OW2 D'],
  "copycodes": ['K AA1 P IY0 K OW2 D Z'],
  "copying": ['K AA1 P IY0 IH0 NG'],
  "copyreader": ['K AA1 P IY0 R IY2 D ER0'],
  "copyreaders": ['K AA1 P IY0 R IY2 D ER0 Z'],
  "copyright": ['K AA1 P IY0 R AY2 T'],
  "copyrightable": ['K AA1 P IY0 R AY2 T AH0 B AH0 L'],
  "copyrighted": ['K AA1 P IY0 R AY2 T IH0 D'],
  "copyrights": ['K AA1 P IY0 R AY2 T S'],
  "copytele": ['K AA1 P IY0 T EH2 L AH0'],
  "copywrite": ['K AA1 P IY0 R AY2 T'],
  "copywrited": ['K AA1 P IY0 R AY2 T IH0 D'],
  "copywriter": ['K AA1 P IY0 R AY2 T ER0'],
  "copywriters": ['K AA1 P IY0 R AY2 T ER0 Z'],
  "cor": ['K AO1 R'],
  "cora": ['K AO1 R AH0'],
  "cora's": ['K AO1 R AH0 Z'],
  "corabelle": ['K AO1 R AH0 B AH0 L', 'K AO1 R AH0 B EH2 L'],
  "coracle": ['K AO1 R AH0 K AH0 L'],
  "coral": ['K AO1 R AH0 L'],
  "coralie": ['K AO1 R AH0 L IY0'],
  "coraline": ['K AO1 R AH0 L AY2 N'],
  "corallo": ['K AO2 R AE1 L OW0'],
  "corals": ['K AO1 R AH0 L Z'],
  "coram": ['K AO1 R AE2 M'],
  "corath": ['K AO1 R AE1 TH'],
  "corazon": ['K AO1 R AH0 Z OW2 N'],
  "corban": ['K AO1 R B AH0 N'],
  "corbeil": ['K AO1 R B AY2 L'],
  "corbel": ['K AO1 R B AH0 L'],
  "corbell": ['K AO1 R B EH0 L'],
  "corbelled": ['K AO1 R B AH0 L D'],
  "corbello": ['K ER0 B EH1 L OW0'],
  "corbet": ['K AO1 R B IH0 T'],
  "corbett": ['K AO1 R B IH0 T'],
  "corbin": ['K AO1 R B IH0 N'],
  "corbit": ['K AO1 R B IH0 T'],
  "corbitt": ['K AO1 R B IH0 T'],
  "corbo": ['K AO1 R B OW0'],
  "corbridge": ['K AO1 R B R IH0 JH'],
  "corbu": ['K AO2 R B UW1'],
  "corbusier": ['K AO2 R B Y UW1 S Y ER0'],
  "corby": ['K AO1 R B IY0'],
  "corcap": ['K AO1 R K AE2 P'],
  "corcoran": ['K AO1 R K ER0 AH0 N'],
  "cord": ['K AO1 R D'],
  "corda": ['K AO1 R D AH0'],
  "cordage": ['K AO1 R D AH0 JH'],
  "cordaro": ['K ER0 D AA1 R OW0'],
  "cordasco": ['K ER0 D AA1 S K OW0'],
  "corday": ['K AO1 R D EY2'],
  "corded": ['K AO1 R D AH0 D'],
  "cordeiro": ['K ER0 D EH1 R OW0'],
  "cordelia": ['K AO2 R D IY1 L IY2 AH0'],
  "cordell": ['K AO2 R D EH1 L'],
  "corder": ['K AO1 R D ER0'],
  "cordero": ['K ER0 D EH1 R OW0'],
  "cordes": ['K AO1 R D Z'],
  "cordesman": ['K AO1 R D AH0 S M AH0 N'],
  "cordial": ['K AO1 R JH AH0 L'],
  "cordially": ['K AO1 R JH AH0 L IY0'],
  "cordials": ['K AO1 R JH AH0 L Z'],
  "cordiant": ['K AO1 R D IY0 AH0 N T'],
  "cordiant's": ['K AO1 R D IY0 AH0 N T S'],
  "cordie": ['K AO1 R D IY0'],
  "cordier": ['K AO1 R D IY0 ER0'],
  "cordill": ['K AO1 R D AH0 L'],
  "cording": ['K AO1 R D IH0 NG'],
  "cordis": ['K AO1 R D IH0 S'],
  "cordle": ['K AO1 R D AH0 L'],
  "cordless": ['K AO1 R D L AH0 S'],
  "cordner": ['K AO1 R D N ER0'],
  "cordoba": ['K AO2 R D OW1 B AH0'],
  "cordoba's": ['K AO2 R D OW1 B AH0 Z'],
  "cordobas": ['K AO2 R D OW1 B AH0 Z'],
  "cordon": ['K AO1 R D AH0 N'],
  "cordone": ['K AO1 R D AH0 N'],
  "cordoned": ['K AO1 R D AH0 N D'],
  "cordova": ['K AO2 R D OW1 V AH0'],
  "cordover": ['K AO1 R D OW2 V ER0'],
  "cordovez": ['K AO2 R D OW1 V EH0 Z'],
  "cordray": ['K AO1 R D R EY2'],
  "cordrey": ['K AO1 R D R IY0'],
  "cordry": ['K AO1 R D ER0 IY0'],
  "cords": ['K AO1 R D Z'],
  "cordts": ['K AO1 R D T S'],
  "corduroy": ['K AO1 R D ER0 OY2'],
  "cordy": ['K AO1 R D IY0'],
  "core": ['K AO1 R'],
  "corea": ['K AO0 R IY1 AH0'],
  "corel": ['K AO2 R EH1 L'],
  "corell": ['K AO2 R EH1 L'],
  "corella": ['K ER0 EH1 L AH0'],
  "coren": ['K AO1 R AH0 N'],
  "cores": ['K AO1 R Z'],
  "corespondent": ['K AO2 R AH0 S P AA1 N D AH0 N T'],
  "corestate": ['K AO1 R S T EY2 T'],
  "corestates": ['K AO1 R S T EY2 T S'],
  "corestates'": ['K AO1 R AH0 S T EY2 T S'],
  "coretech": ['K AO1 R T EH2 K'],
  "coretta": ['K AO0 R EH1 T AH0'],
  "corette": ['K AO1 R EH1 T'],
  "corexit": ['K AO0 R EH1 K S IH0 T S'],
  "corey": ['K AO1 R IY0'],
  "corgi": ['K AO1 R G IY0'],
  "coria": ['K AO1 R IY0 AH0'],
  "coriander": ['K AO2 R IY0 AE1 N D ER0'],
  "coriell": ['K AO1 R IY0 L'],
  "corier": ['K AO1 R IY0 ER0'],
  "corigliano": ['K ER0 IY0 G L IY0 AA1 N OW0'],
  "corina": ['K AO2 R IY1 N AH0'],
  "corinn": ['K AO1 R IH0 N'],
  "corinn's": ['K AO1 R IH0 N Z'],
  "corinna": ['K AO2 R IH1 N AH0'],
  "corinne": ['K ER0 IY1 N'],
  "corinth": ['K AO1 R AH0 N TH'],
  "corinthian": ['K ER0 IH1 N TH IY0 AH0 N'],
  "corinthians": ['K ER0 IH1 N TH IY0 AH0 N Z'],
  "corinto": ['K AO2 R IH1 N T OW0'],
  "corio": ['K AO1 R IY0 OW0'],
  "corisa": ['K ER0 IY1 S AH0'],
  "corissa": ['K AO2 R IH1 S AH0'],
  "cork": ['K AO1 R K'],
  "corken": ['K AO1 R K AH0 N'],
  "corker": ['K AO1 R K ER0'],
  "corkern": ['K AO1 R K ER0 N'],
  "corkery": ['K AO1 R K ER0 IY0'],
  "corkill": ['K AO1 R K IH2 L'],
  "corking": ['K AO1 R K IH0 NG'],
  "corkins": ['K AO1 R K IH0 N Z'],
  "corkran": ['K AO1 R K R AH0 N'],
  "corks": ['K AO1 R K S'],
  "corkscrew": ['K AO1 R K S K R UW2'],
  "corkum": ['K AO1 R K AH0 M'],
  "corkwood": ['K AO1 R K W UH2 D'],
  "corky": ['K AO1 R K IY0'],
  "corl": ['K AO1 R L'],
  "corle": ['K AO1 R AH0 L'],
  "corleone": ['K AO1 R L IY0 OW2 N', 'K AO1 R L IY0 OW2 N IY0'],
  "corless": ['K AO1 R L IH0 S'],
  "corlett": ['K AO1 R L IH0 T'],
  "corlew": ['K AO1 R L UW0'],
  "corley": ['K AO1 R L IY0'],
  "corliss": ['K AO1 R L IH0 S'],
  "corm": ['K AO1 R M'],
  "cormac": ['K AO1 R M AH0 K'],
  "cormack": ['K AO1 R M AH0 K'],
  "corman": ['K AO1 R M AH0 N'],
  "cormany": ['K AO1 R M AH0 N IY0'],
  "cormen": ['K AO1 R M AH0 N', 'K AO1 R M IH0 N'],
  "cormican": ['K AO1 R M IH0 K AH0 N'],
  "cormick": ['K AO1 R M IH0 K'],
  "cormier": ['K AO1 R M IY0 ER0'],
  "cormorant": ['K AO1 R M ER0 AH0 N T'],
  "cormorants": ['K AO1 R M ER0 AH0 N T S'],
  "corn": ['K AO1 R N'],
  "corna": ['K AO1 R N AH0'],
  "cornacchia": ['K ER0 N AA1 K IY0 AH0'],
  "cornall": ['K AO1 R N AH0 L'],
  "cornblume": ['K AO1 R N B L UW2 M'],
  "cornbread": ['K AO1 R N B R EH2 D'],
  "corncob": ['K AO1 R N K AA2 B'],
  "corncrib": ['K AO1 R N K R IH2 B'],
  "corne": ['K AO1 R N'],
  "cornea": ['K AO1 R N IY0 AH0'],
  "corneal": ['K AO2 R N IY1 L'],
  "corneas": ['K AO1 R N IY0 AH0 Z'],
  "cornejo": ['K ER0 N EY1 Y OW0'],
  "cornel": ['K AO1 R N AH0 L'],
  "cornela": ['K ER0 N EH1 L AH0'],
  "cornelia": ['K AO0 R N IY1 L Y AH0'],
  "cornelio": ['K ER0 N EH1 L IY0 OW0'],
  "cornelious": ['K AO1 R N IH0 L IY0 IH0 S', 'K AO0 R N IY1 L Y IH0 S'],
  "cornelison": ['K ER0 N EH1 L IH0 S AH0 N'],
  "cornelius": ['K AO0 R N IY1 L Y AH0 S'],
  "corneliuson": ['K AO2 R N IH0 L IY0 UW1 S AH0 N'],
  "cornell": ['K AO0 R N EH1 L'],
  "cornell's": ['K AO0 R N EH1 L Z'],
  "cornelle": ['K ER0 N EH1 L'],
  "corner": ['K AO1 R N ER0'],
  "cornerback": ['K AO1 R N ER0 B AE2 K'],
  "cornered": ['K AO1 R N ER0 D'],
  "cornering": ['K AO1 R N ER0 IH0 NG'],
  "corners": ['K AO1 R N ER0 Z'],
  "cornerstone": ['K AO1 R N ER0 S T OW2 N'],
  "cornerstones": ['K AO1 R N ER0 S T OW2 N Z'],
  "cornet": ['K AO0 R N EH1 T'],
  "cornett": ['K AO1 R N IH0 T'],
  "cornette": ['K ER0 N EH1 T'],
  "cornettist": ['K AO0 R N EH1 T AH0 S T'],
  "corney": ['K AO1 R N IY0'],
  "cornfeld": ['K AO1 R N F EH2 L D'],
  "cornfield": ['K AO1 R N F IY2 L D'],
  "cornfields": ['K AO1 R N F IY1 L D Z'],
  "cornflower": ['K AO1 R N F L AW2 ER0'],
  "cornflowers": ['K AO1 R N F L AW2 ER0 Z'],
  "cornforth": ['K AO1 R N F ER0 TH'],
  "cornhusk": ['K AO1 R N HH AH2 S K'],
  "cornhusking": ['K AO1 R N HH AH2 S K IH0 NG'],
  "cornhusks": ['K AO1 R N HH AH2 S K S'],
  "cornia": ['K AO1 R N IY0 AH0'],
  "corniche": ['K AO1 R N IH0 SH'],
  "cornick": ['K AO1 R N IH0 K'],
  "cornie": ['K AO1 R N IY0'],
  "corniel": ['K AO2 R N IY1 L'],
  "corniel's": ['K AO2 R N IY1 L Z'],
  "corning": ['K AO1 R N IH0 NG'],
  "corning's": ['K AO1 R N IH0 NG Z'],
  "cornish": ['K AO1 R N IH0 SH'],
  "cornman": ['K AO1 R N M AH0 N'],
  "cornmeal": ['K AO1 R N M IY2 L'],
  "corns": ['K AO1 R N Z'],
  "cornstalk": ['K AO1 R N S T AO2 K'],
  "cornstalks": ['K AO1 R N S T AO2 K S'],
  "cornstarch": ['K AO1 R N S T AA2 R CH'],
  "cornucopia": ['K AO2 R N AH0 K OW1 P IY0 AH0'],
  "cornwall": ['K AO1 R N W AO0 L'],
  "cornwall's": ['K AO1 R N W AO0 L Z'],
  "cornwallis": ['K AO0 R N W AO1 L AH0 S'],
  "cornwell": ['K AO1 R N W EH2 L'],
  "corny": ['K AO1 R N IY0'],
  "corolla": ['K ER0 AA1 L AH0'],
  "corollaries": ['K AO1 R AH0 L EH2 R IY0 Z'],
  "corollary": ['K AO1 R AH0 L EH2 R IY0'],
  "corologis": ['K ER0 R AA1 L AH0 JH IH0 S'],
  "corona": ['K ER0 OW1 N AH0'],
  "corona's": ['K ER0 OW1 N AH0 Z'],
  "coronado": ['K ER0 OW0 N AA1 D OW0'],
  "coronal": ['K ER0 OW1 N AH0 L'],
  "coronary": ['K AO1 R AH0 N EH2 R IY0'],
  "coronation": ['K AO2 R AH0 N EY1 SH AH0 N'],
  "coroner": ['K AO1 R AH0 N ER0'],
  "coroner's": ['K AO1 R AH0 N ER0 Z'],
  "coroners": ['K AO1 R AH0 N ER0 Z'],
  "coronet": ['K AO1 R AH0 N EH1 T'],
  "coronets": ['K AO1 R AH0 N EH1 T S'],
  "corp": ['K AO1 R P', 'K AO1 R P ER0 EY1 SH AH0 N'],
  "corp's": ['K AO1 R P S', 'K AO1 R P ER0 EY1 SH AH0 N Z'],
  "corp.": ['K AO1 R P', 'K AO1 R P ER0 EY1 SH AH0 N'],
  "corp.'s": ['K AO1 R P S', 'K AO1 R P ER0 EY1 SH AH0 N Z'],
  "corpening": ['K AO1 R P AH0 N IH0 NG'],
  "corpora": ['K AO1 R P ER0 AH0'],
  "corporacion": ['K AO2 R P ER0 AA2 S IY0 OW1 N'],
  "corporal": ['K AO1 R P ER0 AH0 L', 'K AO1 R P R AH0 L'],
  "corporate": ['K AO1 R P ER0 AH0 T', 'K AO1 R P R AH0 T'],
  "corporates": ['K AO1 R P ER0 AH0 T S'],
  "corporatewatch": ['K AO1 R P R AH0 T W AA2 CH'],
  "corporatewide": ['K AO1 R P ER0 IH0 T W AY2 D'],
  "corporation": ['K AO2 R P ER0 EY1 SH AH0 N'],
  "corporation's": ['K AO2 R P ER0 EY1 SH AH0 N Z'],
  "corporations": ['K AO2 R P ER0 EY1 SH AH0 N Z'],
  "corporations'": ['K AO2 R P ER0 EY1 SH AH0 N Z'],
  "corporatism": ['K AO1 R P ER0 AH0 T IH2 Z AH0 M'],
  "corporatist": ['K AO1 R P ER0 AH0 T IH0 S T'],
  "corps": ['K AO1 R', 'K AO1 R Z'],
  "corps'": ['K AO1 R Z'],
  "corpse": ['K AO1 R P S'],
  "corpses": ['K AO1 R P S AH0 Z', 'K AO1 R P S IH0 Z'],
  "corpsman": ['K AO1 R M AH0 N'],
  "corpulant": ['K AO1 R P Y AH0 L AH0 N T'],
  "corpus": ['K AO1 R P AH0 S'],
  "corpus-christi": ['K AO1 R P AH0 S K R IH1 S T IY0'],
  "corpuz": ['K AO1 R P UW0 Z'],
  "corr": ['K AO1 R'],
  "corradi": ['K ER0 AA1 D IY0'],
  "corradino": ['K ER0 AA0 D IY1 N OW0'],
  "corrado": ['K ER0 AA1 D OW0'],
  "corral": ['K ER0 AE1 L'],
  "corrales": ['K ER0 AA1 L EH0 S'],
  "corralled": ['K ER0 AE1 L D'],
  "corralling": ['K ER0 AE1 L IH0 NG'],
  "corrao": ['K AO1 R AW0'],
  "correa": ['K AO1 R IY0 AH0'],
  "correale": ['K AO1 R IY0 AH0 L'],
  "correct": ['K ER0 EH1 K T'],
  "corrected": ['K ER0 EH1 K T AH0 D', 'K ER0 EH1 K T IH0 D'],
  "correcting": ['K ER0 EH1 K T IH0 NG'],
  "correction": ['K ER0 EH1 K SH AH0 N'],
  "correctional": ['K ER0 EH1 K SH AH0 N AH0 L'],
  "corrections": ['K ER0 EH1 K SH AH0 N Z'],
  "corrective": ['K ER0 EH1 K T IH0 V'],
  "correctly": ['K ER0 EH1 K T L IY0'],
  "correctness": ['K ER0 EH1 K T N AH0 S'],
  "corrects": ['K ER0 EH1 K T S'],
  "corregidor": ['K ER0 EH1 G AH0 D AO0 R'],
  "correia": ['K ER0 EY1 IY0 AH0'],
  "correira": ['K ER0 EH1 R AH0'],
  "correlate": ['K AO1 R AH0 L EY2 T', 'K AO1 R AH0 L AH0 T'],
  "correlated": ['K AO1 R AH0 L EY2 T AH0 D'],
  "correlates": ['K AO1 R AH0 L AH0 T S', 'K AO1 R AH0 L EY0 T S'],
  "correlating": ['K AO1 R AH0 L EY0 T IH0 NG'],
  "correlation": ['K AO2 R AH0 L EY1 SH AH0 N'],
  "correlations": ['K AO2 R AH0 L EY1 SH AH0 N Z'],
  "correll": ['K ER0 EY1 L'],
  "correne": ['K AO1 R IY0 N'],
  "corrente": ['K ER0 EH1 N T IY0'],
  "correnti": ['K ER0 EH1 N T IY0'],
  "correspond": ['K AO2 R AH0 S P AA1 N D'],
  "corresponded": ['K AO2 R AH0 S P AA1 N D IH0 D'],
  "correspondence": ['K AO2 R AH0 S P AA1 N D AH0 N S'],
  "correspondent": ['K AO2 R AH0 S P AA1 N D AH0 N T'],
  "correspondents": ['K AO2 R AH0 S P AA1 N D AH0 N T S'],
  "correspondents'": ['K AO2 R AH0 S P AA1 N D AH0 N T S'],
  "corresponding": ['K AO2 R AH0 S P AA1 N D IH0 NG'],
  "correspondingly": ['K AO2 R AH0 S P AA1 N D IH0 NG L IY0'],
  "corresponds": ['K AO2 R AH0 S P AA1 N D Z'],
  "corrib": ['K AO1 R IH0 B'],
  "corrick": ['K AO1 R IH0 K'],
  "corridor": ['K AO1 R AH0 D ER0', 'K AO1 R IH0 D ER0'],
  "corridors": ['K AO1 R IH0 D ER0 Z', 'K AO1 R AH0 D ER0 Z'],
  "corrie": ['K AO1 R IY0'],
  "corriere": ['K AO2 R Y EH1 R'],
  "corrigan": ['K AO1 R IH0 G AH0 N'],
  "corrigan's": ['K AO1 R IH0 G AH0 N Z'],
  "corrigible": ['K AO1 R AH0 JH AH0 B AH0 L'],
  "corriher": ['K AO1 R IH0 HH ER0'],
  "corrin": ['K AO1 R IH0 N'],
  "corrina": ['K ER0 IY1 N AH0'],
  "corrington": ['K AO1 R IH0 NG T AH0 N'],
  "corriveau": ['K AO1 R IH0 V OW0'],
  "corroborate": ['K ER0 AA1 B ER0 EY2 T'],
  "corroborated": ['K ER0 AA1 B ER0 EY2 T IH0 D'],
  "corroborates": ['K ER0 AO1 B ER0 EY2 T S'],
  "corroborating": ['K ER0 AA1 B ER0 EY2 T IH0 NG'],
  "corroboration": ['K ER0 AO2 B ER0 EY1 SH AH0 N'],
  "corroborative": ['K ER0 AA1 B ER0 AH0 T IH2 V', 'K ER0 AA1 B R AH0 T IH2 V'],
  "corrode": ['K ER0 OW1 D'],
  "corroded": ['K ER0 OW1 D IH0 D'],
  "corrodes": ['K ER0 OW1 D Z'],
  "corron": ['K ER0 AO1 N'],
  "corroon": ['K AO0 R UW1 N'],
  "corrosion": ['K ER0 OW1 ZH AH0 N'],
  "corrosive": ['K ER0 OW1 S IH0 V'],
  "corrow": ['K AO1 R OW0'],
  "corrugate": ['K AO1 R AH0 G EY2 T'],
  "corrugated": ['K AO1 R AH0 G EY2 T IH0 D'],
  "corrupt": ['K ER0 AH1 P T'],
  "corrupted": ['K ER0 AH1 P T IH0 D'],
  "corruptible": ['K ER0 AH1 P T IH0 B AH0 L'],
  "corrupting": ['K ER0 AH1 P T IH0 NG'],
  "corruption": ['K ER0 AH1 P SH AH0 N'],
  "corruptions": ['K ER0 AH1 P SH AH0 N Z'],
  "corruptive": ['K ER0 AH1 P T IH0 V'],
  "corruptly": ['K ER0 AH1 P T L IY0'],
  "corrupts": ['K ER0 AH1 P T S'],
  "corry": ['K AO1 R IY0'],
  "corsa": ['K AO1 S AH0'],
  "corsage": ['K AO0 R S AA1 ZH'],
  "corsair": ['K AO1 R S EH0 R'],
  "corsaro": ['K ER0 S AA1 R OW0'],
  "corse": ['K AO1 R S'],
  "corsello": ['K ER0 S EH1 L OW0'],
  "corsentino": ['K ER0 S EH0 N T IY1 N OW0'],
  "corser": ['K AO1 R S ER0'],
  "corset": ['K AO1 R S AH0 T'],
  "corsetti": ['K ER0 S EH1 T IY0'],
  "corsi": ['K AO1 R S IY0'],
  "corsica": ['K AO1 R S IH0 K AH0'],
  "corsican": ['K AO1 R S AH0 K AH0 N'],
  "corsicas": ['K AO1 R S IH0 K AH0 Z'],
  "corsiglia": ['K ER0 S IY1 G L IY0 AH0'],
  "corsini": ['K ER0 S IY1 N IY0'],
  "corso": ['K AO1 R S OW0'],
  "corson": ['K AO1 R S AH0 N'],
  "cort": ['K AO1 R T'],
  "corte": ['K AO1 R T'],
  "cortege": ['K AO0 R T EH1 ZH'],
  "corteland": ['K AO1 R T L AH0 N D'],
  "cortelyou": ['K AO1 R T EH0 L Y UW2'],
  "corten": ['K AO1 R T EH0 N'],
  "cortens": ['K AO1 R T EH0 N Z'],
  "cortens'": ['K AO1 R T EH0 N Z'],
  "corter": ['K AO1 R T ER0'],
  "cortes": ['K AO2 R T EH1 Z'],
  "cortese": ['K ER0 T EY1 Z IY0'],
  "cortesi": ['K ER0 T EH1 S IY0'],
  "cortex": ['K AO1 R T EH0 K S'],
  "cortez": ['K AO0 R T EH1 Z'],
  "corteze": ['K AO0 R T EH1 Z'],
  "corti": ['K AO1 R T IY0'],
  "cortical": ['K AO1 R T AH0 K AH0 L'],
  "corticosteroid": ['K AO2 R T IH0 K OW0 S T EH1 R OY2 D'],
  "corticosteroids": ['K AO2 R T IH0 K OW0 S T EH1 R OY2 D Z'],
  "cortin": ['K AO1 R T IH0 N'],
  "cortina": ['K ER0 T IY1 N AH0'],
  "cortinas": ['K AO1 R T IY0 N AH0 Z'],
  "cortine": ['K AO0 R T IY1 N'],
  "cortines": ['K AO0 R T IY1 N Z'],
  "cortisol": ['K AO1 R T IH0 S AO2 L'],
  "cortisone": ['K AO1 R T AH0 Z OW2 N'],
  "cortland": ['K AO1 R T L AH0 N D'],
  "cortner": ['K AO1 R T N ER0'],
  "cortopassi": ['K ER0 T OW0 P AA1 S IY0'],
  "cortright": ['K AO1 R T R AY0 T'],
  "corum": ['K AO1 R AH0 M'],
  "corundum": ['K ER0 AH1 N D AH0 M'],
  "corva": ['K AO1 R V AH0'],
  "corvallis": ['K AO2 R V AE1 L IH0 S'],
  "corvette": ['K AO0 R V EH1 T'],
  "corvettes": ['K AO2 R V EH1 T S'],
  "corvin": ['K AO1 R V IH0 N'],
  "corvino": ['K AO2 R V IY1 N OW0'],
  "corvo": ['K AO1 R V OW0'],
  "corvus": ['K AO1 R V AH0 S'],
  "corwin": ['K AO1 R W IH2 N'],
  "cory": ['K AO1 R IY2'],
  "corzine": ['K ER0 Z IY1 N IY0'],
  "corzo": ['K AO1 R Z OW0'],
  "cos": ['K AO1 S'],
  "cosa": ['K OW1 S AA0'],
  "cosatu": ['K AH0 S AA1 T UW2'],
  "cosatu's": ['K AH0 S AA1 T UW2 Z'],
  "cosby": ['K AO1 Z B IY0'],
  "cosby's": ['K AO1 Z B IY0 Z'],
  "coscia": ['K OW1 S CH AA0'],
  "cose": ['K OW1 Z'],
  "cosell": ['K OW0 S EH1 L'],
  "cosens": ['K OW1 S AH0 N Z'],
  "cosentino": ['K OW2 S EH0 N T IY1 N OW0'],
  "cosenza": ['K OW2 S EH1 N Z AH0'],
  "coseratz": ['K OW1 Z ER0 AE2 T S'],
  "cosette": ['K AH0 S EH1 T'],
  "cosey": ['K OW1 Z IY0'],
  "cosgriff": ['K AA1 S G R IH0 F'],
  "cosgrove": ['K AA1 S G R AH0 V'],
  "cosi": ['K OW1 S IY0'],
  "cosic": ['K OW1 S IH0 K', 'K OW1 Z IH0 K', 'K AA1 S IH0 K'],
  "cosima": ['K OW0 S IY1 M AH0'],
  "cosio": ['K OW1 S IY0 OW0'],
  "coslett": ['K AA1 S L IH0 T'],
  "cosma": ['K OW1 Z M AH0'],
  "cosmair": ['K AO1 Z M EH2 R'],
  "cosman": ['K AA1 S M AH0 N'],
  "cosme": ['K OW1 Z M'],
  "cosmetic": ['K AA0 Z M EH1 T IH0 K'],
  "cosmetically": ['K AO2 Z M EH1 T IH0 K L IY0'],
  "cosmetics": ['K AA0 Z M EH1 T IH0 K S'],
  "cosmetology": ['K AA2 Z M AH0 T AA1 L AH0 JH IY0'],
  "cosmic": ['K AA1 Z M IH0 K'],
  "cosmo": ['K AO1 Z M OW0'],
  "cosmo's": ['K AA1 Z M OW0 Z'],
  "cosmodrome": ['K AO1 Z M AH0 D R OW2 M'],
  "cosmology": ['K AO0 Z M AO1 L AH0 JH IY0'],
  "cosmonaut": ['K AO1 Z M AH0 N AO2 T'],
  "cosmonauts": ['K AO1 Z M AH0 N AO2 T S'],
  "cosmonauts'": ['K AO1 Z M AH0 N AO2 T S'],
  "cosmopolitan": ['K AA2 Z M AH0 P AA1 L AH0 T AH0 N'],
  "cosmopulos": ['K AO2 Z M AH0 P Y UW1 L OW0 S'],
  "cosmos": ['K AA1 Z M OW0 S'],
  "cosner": ['K AA1 S N ER0'],
  "cosper": ['K AA1 S P ER0'],
  "cosponsor": ['K OW2 S P AO1 N S ER0'],
  "cosponsors": ['K OW2 S P AO1 N S ER0 Z'],
  "coss": ['K AO1 S'],
  "cossa": ['K AO1 S AH0'],
  "cossack": ['K AO1 S AH0 K'],
  "cossacks": ['K AA1 S AE0 K S'],
  "cossairt": ['K AA1 S ER0 T'],
  "cossat": ['K AA1 S AE0 T'],
  "cosset": ['K AA1 S AH0 T'],
  "cossette": ['K AH0 S EH1 T'],
  "cossey": ['K AA1 S IY0'],
  "cossiga": ['K AO2 S IY1 G AH0'],
  "cossin": ['K AA1 S IH0 N'],
  "cossman": ['K AO1 S M AH0 N'],
  "cost": ['K AA1 S T', 'K AO1 S T'],
  "costa": ['K AO1 S T AA0'],
  "costa-rica": ['K AO1 S T AH0 R IY1 K AH0'],
  "costabile": ['K AO1 S T AH0 B AY2 L'],
  "costain": ['K OW2 S T EY1 N'],
  "costales": ['K AA1 S T AH0 L Z'],
  "costano": ['K OW0 S T AA1 N OW0'],
  "costantini": ['K OW0 S T AA0 N T IY1 N IY0'],
  "costantino": ['K OW0 S T AA0 N T IY1 N OW0'],
  "costanza": ['K OW0 S T AA1 N Z AH0'],
  "costanzo": ['K OW0 S T AA1 N Z OW0'],
  "costar": ['K OW1 S T AA0 R'],
  "costars": ['K OW1 S T AA0 R Z'],
  "costas": ['K AO1 S T AH0 Z'],
  "costco": ['K AO1 S T K OW0'],
  "costcutting": ['K AO1 S T K AH2 T IH0 NG'],
  "coste": ['K OW1 S T'],
  "costed": ['K AA1 S T IH0 D'],
  "costeira": ['K OW2 S T IY0 EH1 R AH0'],
  "costella": ['K AO2 S T EH1 L AH0'],
  "costello": ['K AO2 S T EH1 L OW0'],
  "costello's": ['K AO2 S T EH1 L OW0 Z'],
  "costen": ['K AO1 S T AH0 N'],
  "coster": ['K AO1 S T ER0'],
  "costigan": ['K AA1 S T IH0 G AE0 N'],
  "costilla": ['K OW0 S T IH1 L AH0'],
  "costilow": ['K AA1 S T IH0 L OW0'],
  "costin": ['K AA1 S T IH0 N'],
  "costing": ['K AO1 S T IH0 NG'],
  "costley": ['K AA1 S T L IY0'],
  "costlier": ['K AO1 S T L IY0 ER0'],
  "costliest": ['K AO1 S T L IY0 IH0 S T'],
  "costlow": ['K AO1 S T L OW2'],
  "costly": ['K AA1 S T L IY0', 'K AO1 S T L IY0'],
  "costner": ['K AA1 S T N ER0'],
  "costner's": ['K AA1 S T N ER0 Z'],
  "coston": ['K AA1 S T AH0 N'],
  "costra": ['K AA1 S T R AH0'],
  "costs": ['K AA1 S T S', 'K AO1 S T S'],
  "costume": ['K AA0 S T UW1 M', 'K AA1 S T UW0 M'],
  "costumed": ['K AO1 S T UW2 M D'],
  "costumer": ['K AA1 S T UW0 M ER0'],
  "costumers": ['K AA1 S T UW0 M ER0 Z'],
  "costumes": ['K AA0 S T UW1 M Z', 'K AA1 S T UW0 M Z'],
  "cosurtuh": ['K AH0 S ER1 T AH0'],
  "cosy": ['K OW1 Z IY0'],
  "cot": ['K AA1 T'],
  "cota": ['K OW1 T AH0'],
  "cote": ['K OW1 T'],
  "cotelle": ['K OW2 T EH1 L'],
  "coterie": ['K OW1 T ER0 IY0'],
  "cotes": ['K OW1 T S'],
  "cotham": ['K AA1 TH AH0 M'],
  "cothern": ['K AH1 DH ER0 N'],
  "cothran": ['K AA1 TH R AH0 N'],
  "cothren": ['K AA1 TH ER0 AH0 N'],
  "cothron": ['K AA1 TH R AH0 N'],
  "cotija": ['K OW0 T IY1 JH AH0'],
  "cotilla": ['K AH0 T IH1 L AH0'],
  "cotler": ['K OW1 T AH0 L ER0', 'K AA1 T L ER0'],
  "cotman": ['K AA1 T M AH0 N'],
  "cotner": ['K AA1 T N ER0'],
  "cotney": ['K AA1 T N IY0'],
  "cotnoir": ['K AH0 T N W AA1 R'],
  "coto": ['K OW1 T OW0'],
  "cotret": ['K AA1 T R AH0 T'],
  "cotrone": ['K OW0 T R OW1 N IY0'],
  "cotroneo": ['K OW0 T R OW1 N IY0 OW0'],
  "cots": ['K AA1 T S'],
  "cott": ['K AA1 T'],
  "cotta": ['K AA1 T AH0'],
  "cottage": ['K AA1 T AH0 JH', 'K AA1 T IH0 JH'],
  "cottages": ['K AA1 T IH0 JH IH0 Z'],
  "cottam": ['K AA1 T AH0 M'],
  "cottee": ['K OW0 T IY1'],
  "cotten": ['K AA1 T AH0 N'],
  "cotter": ['K AA1 T ER0'],
  "cotterill": ['K AA1 T ER0 IH0 L'],
  "cotterman": ['K AA1 T ER0 M AH0 N'],
  "cottier": ['K AA1 T IY0 ER0'],
  "cottingham": ['K AA1 T IH0 NG HH AE2 M'],
  "cottle": ['K AA1 T AH0 L'],
  "cottman": ['K AA1 T M AH0 N'],
  "cotto": ['K OW1 T OW0'],
  "cottom": ['K AA1 T AH0 M'],
  "cotton": ['K AA1 T AH0 N', 'K AO1 T AH0 N'],
  "cotton's": ['K AA1 T AH0 N Z'],
  "cottone": ['K OW0 T OW1 N IY0'],
  "cottoned": ['K AA1 T AH0 N D'],
  "cottongin": ['K AH0 T AA1 NG JH IH0 N'],
  "cottonmouth": ['K AA1 T AH0 N M AW2 TH'],
  "cottons": ['K AA1 T AH0 N Z'],
  "cottonseed": ['K AA1 T AH0 N S IY2 D'],
  "cottonwood": ['K AA1 T AH0 N W UH2 D'],
  "cottonwoods": ['K AA1 T AH0 N W UH2 D Z'],
  "cottrell": ['K AA2 T R EH1 L'],
  "cottrill": ['K AA1 T R AH0 L'],
  "cotty": ['K AA1 T IY0'],
  "cotugno": ['K OW0 T UW1 G N OW0'],
  "coty": ['K OW1 T IY0'],
  "cotyledon": ['K AO1 T AH0 L IY2 D AH0 N'],
  "cou": ['K UW1'],
  "couch": ['K AW1 CH'],
  "couched": ['K AW1 CH T'],
  "couches": ['K AW1 CH IH0 Z'],
  "couchette": ['K UW2 SH EH1 T'],
  "couchettes": ['K UW2 SH EH1 T S'],
  "couching": ['K AW1 CH IH0 NG'],
  "couchman": ['K UW0 SH M AE1 N'],
  "coudersport": ['K AW1 D ER0 Z P AO2 R T'],
  "coudert": ['K UW1 D ER0 T'],
  "couey": ['K UW0 IY1'],
  "coufal": ['K UW0 F AE1 L'],
  "cougar": ['K UW1 G ER0'],
  "cougars": ['K UW1 G ER0 Z'],
  "cough": ['K AA1 F', 'K AO1 F'],
  "coughed": ['K AO1 F T'],
  "coughenour": ['K AO0 F EH1 N ER0'],
  "coughing": ['K AA1 F IH0 NG', 'K AO1 F IH0 NG'],
  "coughlan": ['K AO1 G L AH0 N'],
  "coughlin": ['K AO1 G L IH0 N'],
  "coughran": ['K AO1 G R AH0 N'],
  "coughs": ['K AO1 F S'],
  "couillard": ['K W IY0 L AA1 R D'],
  "could": ['K UH1 D'],
  "could've": ['K UH1 D AH0 V'],
  "couldn't": ['K UH1 D AH0 N T', 'K UH1 D AH0 N'],
  "coulee": ['K UW1 L IY0'],
  "coull": ['K AW1 L'],
  "coulombe": ['K AW0 L OW1 M B IY0'],
  "coulon": ['K AW1 L AH0 N'],
  "coulson": ['K AW1 L S AH0 N'],
  "coulston": ['K AW1 L S T AH0 N'],
  "coultas": ['K UW0 L T AA1 Z'],
  "coulter": ['K OW1 L T ER0'],
  "coulthard": ['K UW0 L TH AA1 R D'],
  "counce": ['K AW1 N S'],
  "council": ['K AW1 N S AH0 L'],
  "council's": ['K AW1 N S AH0 L Z'],
  "councilman": ['K AW1 N S AH0 L M AH0 N'],
  "councilmen": ['K AW1 N S AH0 L M EH1 N'],
  "councilor": ['K AW1 N S AH0 L ER0', 'K AW1 N S L ER0'],
  "councilors": ['K AW1 N S AH0 L ER0 Z', 'K AW1 N S L ER0 Z'],
  "councils": ['K AW1 N S AH0 L Z'],
  "councilwoman": ['K AW1 N S AH0 L W UH2 M AH0 N'],
  "councilwomen": ['K AW1 N S AH0 L W IH2 M AH0 N'],
  "counihan": ['K AW1 N IH0 HH AE0 N'],
  "counsel": ['K AW1 N S AH0 L'],
  "counsel's": ['K AW1 N S AH0 L Z'],
  "counseled": ['K AW1 N S AH0 L D'],
  "counseling": ['K AW1 N S AH0 L IH0 NG', 'K AW1 N S L IH0 NG'],
  "counsell": ['K AW1 N S AH0 L'],
  "counsellor": ['K AW1 N S AH0 L ER0', 'K AW1 N S L ER0'],
  "counsellors": ['K AW1 N S AH0 L ER0 Z', 'K AW1 N S L ER0 Z'],
  "counselman": ['K AW1 N S AH0 L M AH0 N'],
  "counselor": ['K AW1 N S AH0 L ER0'],
  "counselors": ['K AW1 N S AH0 L ER0 Z'],
  "counsels": ['K AW1 N S AH0 L Z'],
  "count": ['K AW1 N T'],
  "countable": ['K AW1 N T AH0 B AH0 L'],
  "countdown": ['K AW1 N T D AW2 N'],
  "countdowns": ['K AW1 N T D AW2 N Z'],
  "counted": ['K AW1 N T IH0 D', 'K AW1 N IH0 D', 'K AW1 N AH0 D'],
  "countenance": ['K AW1 N T AH0 N AH0 N S'],
  "countenanced": ['K AW1 N T AH0 N AH0 N S T'],
  "countenances": ['K AW1 N T AH0 N AH0 N S IH0 Z'],
  "counter": ['K AW1 N T ER0'],
  "counteract": ['K AW1 N T ER0 AE2 K T'],
  "counteracted": ['K AW1 N T ER0 AE2 K T IH0 D'],
  "counteracting": ['K AW2 N T ER0 AE1 K T IH0 NG'],
  "counterattack": ['K AW1 N T ER0 AH0 T AE2 K'],
  "counterattacked": ['K AW2 N T ER0 AH0 T AE1 K T'],
  "counterattacking": ['K AW1 N T ER0 AH0 T AE2 K IH0 NG'],
  "counterattacks": ['K AW1 N T ER0 AH0 T AE2 K S'],
  "counterbalance": ['K AW1 N T ER0 B AE2 L AH0 N S'],
  "counterbalanced": ['K AW2 N T ER0 B AE1 L AH0 N S T'],
  "counterbalances": ['K AW1 N T ER0 B AE2 L AH0 N S IH0 Z'],
  "counterbalancing": ['K AW1 N T ER0 B AE2 L AH0 N S IH0 NG'],
  "counterbid": ['K AW2 N T ER0 B IH1 D'],
  "counterbids": ['K AW2 N T ER0 B IH1 D Z'],
  "counterchallenge": ['K AW1 N T ER0 CH AE2 L AH0 N JH'],
  "counterchallenges": ['K AW1 N T ER0 CH AE2 L AH0 N JH AH0 Z'],
  "countercharge": ['K AW1 N T ER0 CH AA2 R JH'],
  "countercharges": ['K AW1 N T ER0 CH AA2 R JH IH0 Z'],
  "counterclaim": ['K AW1 N T ER0 K L EY2 M'],
  "counterclaims": ['K AW1 N T ER0 K L EY2 M Z'],
  "counterclockwise": ['K AW2 N T ER0 K L AO1 K W AY0 Z', 'K AW2 N ER0 K L AO1 K W AY0 Z'],
  "countercultural": ['K AW2 N T ER0 K AH1 L CH ER0 AH0 L'],
  "counterculture": ['K AW1 N T ER0 K AH2 L CH ER0', 'K AW1 N ER0 K AH2 L CH ER0'],
  "countered": ['K AW1 N T ER0 D'],
  "counterespionage": ['K AW2 N T ER0 EH1 S P IY0 AH0 N AA0 JH'],
  "counterfeit": ['K AW1 N T ER0 F IH2 T'],
  "counterfeited": ['K AW1 N T ER0 F IH2 T IH0 D'],
  "counterfeiter": ['K AW1 N T ER0 F IH2 T ER0'],
  "counterfeiters": ['K AW1 N T ER0 F IH2 T ER0 Z'],
  "counterfeiting": ['K AW1 N T ER0 F IH2 T IH0 NG'],
  "counterfeits": ['K AW1 N T ER0 F IH2 T S'],
  "counterforce": ['K AW1 N T ER0 F AO2 R S'],
  "counterforces": ['K AW1 N T ER0 F AO2 R S IH0 Z'],
  "countering": ['K AW1 N T ER0 IH0 NG'],
  "counterinsurgencies": ['K AW2 N T ER0 IH0 N S ER1 JH AH0 N S IY0 Z'],
  "counterinsurgency": ['K AW2 N T ER0 IH0 N S ER1 JH AH0 N S IY0'],
  "counterintelligence": ['K AW2 N T ER0 IH0 N T EH1 L IH0 JH AH0 N S'],
  "counterlife": ['K AW1 N T ER0 L AY2 F'],
  "counterman": ['K AW1 N T ER0 M AE2 N'],
  "countermeasure": ['K AW1 N T ER0 M EH2 ZH ER0'],
  "countermeasures": ['K AW1 N T ER0 M EH2 ZH ER0 Z'],
  "countermove": ['K AW1 N T ER0 M UW2 V'],
  "countermoves": ['K AW1 N T ER0 M UW2 V Z'],
  "counteroffensive": ['K AW2 N T ER0 AO0 F EH1 N S IH0 V'],
  "counteroffensives": ['K AW2 N T ER0 AO0 F EH1 N S IH0 V Z'],
  "counteroffer": ['K AW1 N T ER0 AO2 F ER0'],
  "counteroffers": ['K AW1 N T ER0 AO2 F ER0 Z'],
  "counterpart": ['K AW1 N T ER0 P AA2 R T'],
  "counterparts": ['K AW1 N T ER0 P AA2 R T S'],
  "counterparty": ['K AW1 N T ER0 P AA2 R T IY0'],
  "counterpoint": ['K AW1 N T ER0 P OY2 N T'],
  "counterpoints": ['K AW1 N T ER0 P OY2 N T S'],
  "counterproductive": ['K AW1 N T ER0 P R AH0 D AH2 K T IH0 V'],
  "counterproposal": ['K AW1 N T ER0 P R AH0 P OW2 Z AH0 L'],
  "counterproposals": ['K AW1 N T ER0 P R AH0 P OW2 Z AH0 L Z'],
  "counterpunch": ['K AW1 N T ER0 P AH2 N CH'],
  "counterrevolt": ['K AW1 N T ER0 R IY0 V OW2 L T'],
  "counterrevolution": ['K AW2 N T ER0 R EH0 V AH0 L UW1 SH AH0 N'],
  "counterrevolutionary": ['K AW2 N T ER0 R EH0 V AH0 L UW1 SH AH0 N EH2 R IY0'],
  "counters": ['K AW1 N T ER0 Z'],
  "counterstrike": ['K AW1 N T ER0 S T R AY2 K'],
  "countersue": ['K AW1 N T ER0 S UW2'],
  "countersued": ['K AW1 N T ER0 S UW2 D'],
  "countersuing": ['K AW1 N T ER0 S UW2 IH0 NG'],
  "countersuit": ['K AW1 N T ER0 S UW2 T'],
  "countertenor": ['K AW1 N T ER0 T EH2 N ER0'],
  "counterterrorism": ['K AW1 N T ER0 T EH2 R ER0 IH2 Z AH0 M'],
  "counterterrorist": ['K AW2 N T ER0 T EH1 R ER0 IH0 S T'],
  "counterterrorists": ['K AW2 N T ER0 T EH1 R ER0 IH0 S T S'],
  "countertop": ['K AW1 N T ER0 T AA2 P'],
  "countertops": ['K AW1 N T ER0 T AA2 P S'],
  "countertrade": ['K AW1 N T ER0 T R EY2 D'],
  "countervail": ['K AW1 N T ER0 V EY2 L'],
  "countervailing": ['K AW1 N T ER0 V EY2 L IH0 NG'],
  "counterweight": ['K AW1 N T ER0 W EY2 T'],
  "counterweights": ['K AW1 N T ER0 W EY2 T S'],
  "countess": ['K AW1 N T AH0 S'],
  "countesses": ['K AW1 N T AH0 S IH0 Z'],
  "counties": ['K AW1 N T IY0 Z', 'K AW1 N IY0 Z'],
  "counting": ['K AW1 N T IH0 NG', 'K AW1 N IH0 NG'],
  "countless": ['K AW1 N T L AH0 S'],
  "countries": ['K AH1 N T R IY0 Z'],
  "countries'": ['K AH1 N T R IY0 Z'],
  "country": ['K AH1 N T R IY0'],
  "country's": ['K AH1 N T R IY0 Z'],
  "countryfolk": ['K AH1 N T R IY0 F OW2 K'],
  "countryman": ['K AH1 N T R IY0 M AH0 N'],
  "countrymen": ['K AH1 N T R IY0 M IH0 N'],
  "countryside": ['K AH1 N T R IY0 S AY2 D'],
  "countrywide": ['K AH1 N T R IY0 W AY2 D'],
  "counts": ['K AW1 N T S'],
  "county": ['K AW1 N T IY0', 'K AW1 N IY0'],
  "county's": ['K AW1 N T IY0 Z', 'K AW1 N IY0 Z'],
  "coup": ['K UW1'],
  "coupe": ['K UW1 P'],
  "couper": ['K UW1 ER0'],
  "coupes": ['K UW1 P S'],
  "coupland": ['K UW1 P L AH0 N D'],
  "couple": ['K AH1 P AH0 L'],
  "couple's": ['K AH1 P AH0 L Z'],
  "coupled": ['K AH1 P AH0 L D'],
  "coupler": ['K AH1 P L ER0'],
  "couplers": ['K AH1 P L ER0 Z'],
  "couples": ['K AH1 P AH0 L Z'],
  "coupley": ['K AH1 P L IY0'],
  "coupling": ['K AH1 P L IH0 NG'],
  "couplings": ['K AH1 P L IH0 NG Z'],
  "coupon": ['K UW1 P AO2 N', 'K Y UW1 P AO2 N'],
  "couponing": ['K UW1 P AA0 N IH0 NG', 'K Y UW1 P AA0 N IH0 NG'],
  "couponite": ['K UW1 P AA0 N AY0 T', 'K Y UW1 P AA0 N AY0 T'],
  "couponites": ['K UW1 P AA0 N AY0 T S', 'K Y UW1 P AA0 N AY0 T S'],
  "coupons": ['K UW1 P AO2 N Z', 'K Y UW1 P AO2 N Z'],
  "coups": ['K UW1 Z', 'K UW1'],
  "cour": ['K UH1 R'],
  "courage": ['K ER1 AH0 JH', 'K ER1 IH0 JH'],
  "courageous": ['K ER0 EY1 JH AH0 S'],
  "courageously": ['K ER0 EY1 JH AH0 S L IY0'],
  "courant": ['K UH1 R AH0 N T'],
  "courchaine": ['K UH0 R SH EY1 N'],
  "courchesne": ['K UH0 R SH EH1 N'],
  "couric": ['K AO1 R IH0 K'],
  "courier": ['K ER1 IY0 ER0'],
  "courier's": ['K ER1 IY0 ER0 Z'],
  "couriers": ['K ER1 IY0 ER0 Z'],
  "courington": ['K AO1 R IH0 NG T AH0 N'],
  "cournoyer": ['K AO1 R N OY0 ER0'],
  "course": ['K AO1 R S'],
  "course's": ['K AO1 R S IH0 Z'],
  "coursen": ['K AO1 R S AH0 N'],
  "courser": ['K AO1 R S ER0'],
  "courses": ['K AO1 R S AH0 Z', 'K AO1 R S IH0 Z'],
  "coursey": ['K AO1 R S IY0'],
  "coursing": ['K AO1 R S IH0 NG'],
  "courson": ['K AO1 R S AH0 N'],
  "court": ['K AO1 R T'],
  "court's": ['K AO1 R T S'],
  "court-martial": ['K AO1 R T M AA2 R SH AH0 L'],
  "courtade": ['K AO1 R T EY0 D'],
  "courtaulds": ['K AO1 R T AO2 L D Z'],
  "courteau": ['K ER1 T OW0'],
  "courted": ['K AO1 R T IH0 D'],
  "courtemanche": ['K AO2 R T M AE1 N SH', 'K AO2 R T M AA1 N SH'],
  "courtenay": ['K ER1 T AH0 N EY0', 'K AO1 R T N EY0'],
  "courteous": ['K ER1 T IY0 AH0 S'],
  "courter": ['K AO1 R T ER0'],
  "courter's": ['K AO1 R T ER0 Z'],
  "courtesies": ['K ER1 T AH0 S IY0 Z'],
  "courtesy": ['K ER1 T AH0 S IY0'],
  "courthouse": ['K AO1 R T HH AW2 S'],
  "courthouses": ['K AO1 R T HH AW2 S IH0 Z'],
  "courtier": ['K AO1 R T IY0 ER0'],
  "courtiers": ['K AO1 R T IY0 ER0 Z'],
  "courting": ['K AO1 R T IH0 NG'],
  "courtis": ['K AO1 R T IH0 S'],
  "courtland": ['K AO1 R T L AE0 N D'],
  "courtly": ['K AO1 R T L IY0'],
  "courtney": ['K AO1 R T N IY0'],
  "courtois": ['K AO1 R T W AA0'],
  "courtright": ['K AO1 R T R AY2 T'],
  "courtroom": ['K AO1 R T R UW2 M'],
  "courtrooms": ['K AO1 R T R UW2 M Z'],
  "courts": ['K AO1 R T S'],
  "courts'": ['K AO1 R T S'],
  "courtship": ['K AO1 R CH IH2 P'],
  "courtwright": ['K AO1 R T R AY2 T'],
  "courtyard": ['K AO1 R T Y AA2 R D'],
  "courtyards": ['K AO1 R T Y AA2 R D Z'],
  "courville": ['K UH0 R V IH1 L'],
  "coury": ['K AO1 R IY0'],
  "cousar": ['K UW0 S AA1 R'],
  "couse": ['K AW1 S'],
  "cousens": ['K UW1 S AH0 N Z', 'K AW1 S AH0 N Z'],
  "couser": ['K AW1 S ER0'],
  "cousin": ['K AH1 Z AH0 N'],
  "cousin's": ['K AH1 Z AH0 N Z'],
  "cousineau": ['K UW1 S IH0 N OW2'],
  "cousino": ['K AW0 S IY1 N OW0'],
  "cousins": ['K AH1 Z AH0 N Z'],
  "cousteau": ['K UW2 S T OW1'],
  "cousteau's": ['K UW2 S T OW1 Z'],
  "cousy": ['K UW1 Z IY0'],
  "coutant": ['K UW0 T AO1 N T'],
  "coutee": ['K UW0 T IY1'],
  "couto": ['K AW1 T OW0'],
  "couts": ['K AW1 T S'],
  "coutts": ['K AW1 T S'],
  "coutu": ['K UW0 CH UW1'],
  "couture": ['K UW0 T UH1 R'],
  "couturier": ['K UW0 T UH1 R IY0 ER0'],
  "couvillion": ['K UW0 V IY0 L Y AO1 N'],
  "couvillon": ['K UW0 V IY0 L AO1 N'],
  "couzens": ['K UW1 Z AH0 N Z'],
  "coval": ['K OW0 V AA1 L'],
  "covalent": ['K OW0 V AA1 L AH0 N T'],
  "covalt": ['K OW1 V AA0 L T'],
  "covariance": ['K OW2 V AA1 R IY2 AH0 N S'],
  "covaries": ['K OW2 V AA1 R IY2 Z'],
  "covarrubias": ['K OW0 V AA0 R UW0 B IY1 AH0 Z'],
  "covas": ['K OW1 V AH0 S'],
  "covatta": ['K OW0 V AA1 T AH0'],
  "covault": ['K OW1 V AO1 L T'],
  "cove": ['K OW1 V'],
  "covel": ['K OW1 V AH0 L'],
  "covell": ['K AA1 V AH0 L'],
  "covelli": ['K OW2 V EH1 L IY0'],
  "covello": ['K OW2 V EH1 L OW0'],
  "coven": ['K AH1 V AH0 N', 'K OW1 V AH0 N'],
  "covenant": ['K AH1 V AH0 N AH0 N T'],
  "covenanter": ['K AH1 V AH0 N AH0 N T ER0'],
  "covenanters": ['K AH1 V AH0 N AH0 N T ER0 Z'],
  "covenants": ['K AH1 V AH0 N AH0 N T S'],
  "coveney": ['K AA1 V IH0 N IY0'],
  "covent": ['K AH1 V AH0 N T'],
  "coventry": ['K AH1 V AH0 N T R IY0'],
  "cover": ['K AH1 V ER0'],
  "cover-up": ['K AH1 V ER0 AH2 P'],
  "coverage": ['K AH1 V ER0 AH0 JH', 'K AH1 V ER0 IH0 JH', 'K AH1 V R IH0 JH'],
  "coverages": ['K AH1 V ER0 AH0 JH IH0 Z', 'K AH1 V ER0 IH0 JH IH0 Z', 'K AH1 V R IH0 JH IH0 Z'],
  "coverall": ['K AH1 V ER0 AO2 L'],
  "coveralls": ['K AH1 V ER0 AO2 L Z'],
  "coverdale": ['K AH1 V ER0 D EY2 L'],
  "coverdell": ['K AH1 V ER0 D EH2 L'],
  "covered": ['K AH1 V ER0 D'],
  "covering": ['K AH1 V ER0 IH0 NG', 'K AH1 V R IH0 NG'],
  "coverings": ['K AH1 V ER0 IH0 NG Z'],
  "covers": ['K AH1 V ER0 Z'],
  "coverstone": ['K AH1 V ER0 S T OW2 N'],
  "covert": ['K OW1 V ER0 T'],
  "covertly": ['K OW0 V ER1 T L IY0'],
  "coverup": ['K AH1 V ER0 AH2 P'],
  "coverups": ['K AH1 V ER0 AH2 P S'],
  "coves": ['K OW1 V Z'],
  "covet": ['K AH1 V AH0 T'],
  "coveted": ['K AH1 V AH0 T IH0 D'],
  "covets": ['K AH1 V AH0 T S'],
  "covey": ['K AH1 V IY0'],
  "covia": ['K OW1 V IY0 AH0'],
  "coviello": ['K OW2 V IY0 EH1 L OW0'],
  "covill": ['K AA1 V AH0 L'],
  "coville": ['K OW1 V IH2 L'],
  "covin": ['K OW1 V IH0 N'],
  "covina": ['K OW0 V IY1 N AH0'],
  "covington": ['K AH1 V IH0 NG T AH0 N'],
  "covino": ['K OW0 V IY1 N OW0'],
  "covitz": ['K OW1 V IH0 T S'],
  "covy": ['K AH1 V IY0'],
  "cow": ['K AW1'],
  "cow's": ['K AW1 Z'],
  "cowabunga": ['K AW2 AH0 B AH1 N G AH0'],
  "cowan": ['K AW1 AH0 N'],
  "cowans": ['K AW1 AH0 N Z'],
  "coward": ['K AW1 ER0 D'],
  "coward's": ['K AW1 ER0 D Z'],
  "cowardice": ['K AW1 ER0 D AH0 S'],
  "cowardly": ['K AW1 ER0 D L IY0'],
  "cowards": ['K AW1 ER0 D Z'],
  "cowart": ['K AW1 AA0 R T'],
  "cowbell": ['K AW1 B EH2 L'],
  "cowbells": ['K AW1 B EH2 L Z'],
  "cowboy": ['K AW1 B OY2'],
  "cowboy's": ['K AW1 B OY2 Z'],
  "cowboys": ['K AW1 B OY2 Z'],
  "cowboys'": ['K AW1 B OY2 Z'],
  "cowden": ['K AW1 D AH0 N'],
  "cowdery": ['K AW1 D ER0 IY0'],
  "cowdrey": ['K AW1 D R IY0'],
  "cowed": ['K AW1 D'],
  "coweda": ['K AH0 W IY1 D AH0'],
  "cowell": ['K AA1 W EH0 L'],
  "cowen": ['K AW1 AH0 N', 'K OW1 AH0 N'],
  "cowens": ['K AW1 AH0 N Z', 'K OW1 AH0 N Z'],
  "cower": ['K AW1 ER0'],
  "cowering": ['K AW1 ER0 IH0 NG'],
  "cowger": ['K AW1 JH ER0'],
  "cowgill": ['K AW1 G IH2 L'],
  "cowgirl": ['K AW1 G ER2 L'],
  "cowgirls": ['K AW1 G ER2 L Z'],
  "cowher": ['K AA1 W ER0'],
  "cowherd": ['K AW1 HH ER2 D'],
  "cowie": ['K AW1 IY0'],
  "cowin": ['K AW1 IH0 N'],
  "cowing": ['K AW1 IH0 NG'],
  "cowl": ['K AW1 L'],
  "cowles": ['K AW1 AH0 L Z'],
  "cowley": ['K AW1 L IY0'],
  "cowlick": ['K AW1 L IH0 K'],
  "cowlicks": ['K AW1 L IH0 K S'],
  "cowling": ['K AW1 L IH0 NG'],
  "cowling's": ['K AW1 L IH0 NG Z'],
  "cowlings": ['K AW1 L IH0 NG Z'],
  "cowlings'": ['K AW1 L IH0 NG Z'],
  "cowlings's": ['K AW1 L IH0 NG Z IH0 Z'],
  "cowman": ['K AW1 M AH0 N'],
  "coworker": ['K OW1 W ER1 K ER0'],
  "coworkers": ['K OW1 W ER1 K ER0 Z'],
  "cowper": ['K AW1 P ER0', 'K UW1 P ER0'],
  "cowperthwaite": ['K AW1 P ER0 TH W EY2 T'],
  "cowries": ['K AW1 R IY0 Z'],
  "cows": ['K AW1 Z'],
  "cowser": ['K AW1 Z ER0'],
  "cowsert": ['K AW1 S ER0 T'],
  "cowslip": ['K AW1 S L IH0 P'],
  "cox": ['K AA1 K S'],
  "cox's": ['K AA1 K S IH0 Z'],
  "coxe": ['K AA1 K S'],
  "coxen": ['K AA1 K S AH0 N'],
  "coxon": ['K AA1 K S AH0 N'],
  "coxwell": ['K AA1 K S W EH2 L'],
  "coy": ['K OY1'],
  "coye": ['K OY1'],
  "coyer": ['K OY1 ER0'],
  "coykendall": ['K OY0 K EH1 N D AH0 L'],
  "coyle": ['K OY1 L'],
  "coyly": ['K OY1 L IY0'],
  "coym": ['K OY1 M'],
  "coyne": ['K OY1 N'],
  "coyner": ['K OY1 N ER0'],
  "coyote": ['K AY0 OW1 T IY0', 'K AY1 OW0 T'],
  "coyotes": ['K AY0 OW1 T IY0 S', 'K AY1 OW0 T S'],
  "coz": ['K AA1 Z'],
  "cozad": ['K OW1 Z AH0 D'],
  "cozart": ['K AA1 Z AA0 R T'],
  "cozby": ['K AA1 Z B IY0'],
  "cozier": ['K OW1 Z IY0 ER0'],
  "cozine": ['K OW0 Z IY1 N IY0'],
  "coziness": ['K OW1 Z IY0 N AH0 S'],
  "cozman": ['K OW1 Z M AH0 N'],
  "cozy": ['K OW1 Z IY0'],
  "cozying": ['K OW1 Z IY0 IH0 NG'],
  "cozza": ['K OW1 T S AH0'],
  "cozzens": ['K AA1 Z AH0 N Z'],
  "cozzi": ['K OW1 T S IY0'],
  "cozzolino": ['K OW0 T S OW0 L IY1 N OW0'],
  "cps": ['S IY1 P IY2 EH1 S'],
  "cpu": ['S IY2 P IY2 Y UW1'],
  "cr.": ['K R EH1 S AH0 N T'],
  "crab": ['K R AE1 B'],
  "crabb": ['K R AE1 B'],
  "crabbe": ['K R AE1 B'],
  "crabbed": ['K R AE1 B D'],
  "crabbs": ['K R AE1 B Z'],
  "crabby": ['K R AE1 B IY0'],
  "crabeater": ['K R AE1 B IY2 T ER0'],
  "crabill": ['K R AE1 B AH0 L'],
  "crable": ['K R EY1 B AH0 L'],
  "crabmeat": ['K R AE1 B M IY2 T'],
  "crabs": ['K R AE1 B Z'],
  "crabtree": ['K R AE1 B T R IY2'],
  "cracchiolo": ['K R AA0 K IY0 OW1 L OW0'],
  "crace": ['K R EY1 S'],
  "crack": ['K R AE1 K'],
  "crackdown": ['K R AE1 K D AW2 N'],
  "crackdowns": ['K R AE1 K D AW2 N Z'],
  "cracked": ['K R AE1 K T'],
  "crackel": ['K R AE1 K AH0 L'],
  "cracker": ['K R AE1 K ER0'],
  "crackerjack": ['K R AE1 K ER0 JH AE2 K'],
  "crackers": ['K R AE1 K ER0 Z'],
  "crackhead": ['K R AE1 K HH EH2 D'],
  "crackheads": ['K R AE1 K HH EH2 D Z'],
  "cracking": ['K R AE1 K IH0 NG'],
  "crackle": ['K R AE1 K AH0 L'],
  "crackled": ['K R AE1 K AH0 L D'],
  "crackles": ['K R AE1 K AH0 L Z'],
  "crackling": ['K R AE1 K L IH0 NG'],
  "crackpot": ['K R AE1 K P AA2 T'],
  "crackpots": ['K R AE1 K P AA2 T S'],
  "cracks": ['K R AE1 K S'],
  "cracraft": ['K R AA1 K R AE0 F T'],
  "craddock": ['K R AE1 D AH0 K'],
  "crader": ['K R EY1 D ER0'],
  "cradic": ['K R AE1 D IH0 K'],
  "cradle": ['K R EY1 D AH0 L'],
  "cradles": ['K R EY1 D AH0 L Z'],
  "cradling": ['K R EY1 D AH0 L IH0 NG', 'K R EY1 D L IH0 NG'],
  "cradock": ['K R AE1 D AH0 K'],
  "crady": ['K R EY1 D IY0'],
  "craft": ['K R AE1 F T'],
  "craft's": ['K R AE1 F T S'],
  "crafted": ['K R AE1 F T IH0 D'],
  "crafting": ['K R AE1 F T IH0 NG'],
  "crafton": ['K R AE1 F T AH0 N'],
  "crafts": ['K R AE1 F T S', 'K R AE1 F S'],
  "craftsman": ['K R AE1 F T S M AH0 N', 'K R AE1 F S M AH0 N'],
  "craftsmanship": ['K R AE1 F T S M AH0 N SH IH2 P', 'K R AE1 F S M AH0 N SH IH2 P'],
  "craftsmen": ['K R AE1 F T S M EH0 N', 'K R AE1 F S M EH0 N'],
  "craftspeople": ['K R AE1 F T S P IY2 P AH0 L', 'K R AE1 F S P IY2 P AH0 L'],
  "crafty": ['K R AE1 F T IY0'],
  "crag": ['K R AE1 G'],
  "crager": ['K R EY1 JH ER0'],
  "cragg": ['K R AE1 G'],
  "craggs": ['K R AE1 G Z'],
  "craggy": ['K R AE1 G IY0'],
  "craghead": ['K R AE1 G HH EH2 D'],
  "cragin": ['K R AE1 JH IH0 N'],
  "cragle": ['K R EY1 G AH0 L'],
  "crago": ['K R AA1 G OW0'],
  "crags": ['K R AE1 G Z'],
  "cragun": ['K R AE1 G AH0 N'],
  "craib": ['K R EY1 B'],
  "craig": ['K R EY1 G'],
  "craig's": ['K R EY1 G Z'],
  "craighead": ['K R EY1 G HH EH2 D'],
  "craigie": ['K R EY1 G IY0'],
  "craigo": ['K R EY1 G OW0'],
  "craigslist": ['K R EY1 G Z L IH2 S T'],
  "crail": ['K R EY1 L'],
  "crain": ['K R EY1 N'],
  "crain's": ['K R EY1 N Z'],
  "craine": ['K R EY1 N'],
  "craker": ['K R EY1 K ER0'],
  "crall": ['K R AO1 L'],
  "cram": ['K R AE1 M'],
  "cramblit": ['K R AE1 M B L IH0 T'],
  "cramer": ['K R EY1 M ER0'],
  "cramer's": ['K R EY1 M ER0 Z'],
  "crames": ['K R EY1 M Z'],
  "crammed": ['K R AE1 M D'],
  "crammer": ['K R AE1 M ER0'],
  "cramming": ['K R AE1 M IH0 NG'],
  "cramp": ['K R AE1 M P'],
  "cramped": ['K R AE1 M P T'],
  "cramping": ['K R AE1 M P IH0 NG'],
  "crampon": ['K R AE1 M P AO0 N'],
  "crampons": ['K R AE1 M P AO0 N Z'],
  "cramps": ['K R AE1 M P S'],
  "crampton": ['K R AE1 M P T AH0 N'],
  "crams": ['K R AE1 M Z'],
  "cramton": ['K R AE1 M T AH0 N'],
  "cranberries": ['K R AE1 N B EH2 R IY0 Z'],
  "cranberry": ['K R AE1 N B EH2 R IY0'],
  "crance": ['K R AE1 N S'],
  "crandall": ['K R AE1 N D AH0 L'],
  "crandall's": ['K R AE1 N D AH0 L Z'],
  "crandell": ['K R AE1 N D AH0 L'],
  "crane": ['K R EY1 N'],
  "crane's": ['K R EY1 N Z'],
  "craned": ['K R EY1 N D'],
  "craner": ['K R EY1 N ER0'],
  "cranes": ['K R EY1 N Z'],
  "cranesbill": ['K R EY1 N Z B IH2 L'],
  "cranesbills": ['K R EY1 N Z B IH2 L Z'],
  "craney": ['K R EY1 N IY0'],
  "cranfield": ['K R AE1 N F IY2 L D'],
  "cranfill": ['K R AE1 N F AH0 L'],
  "cranford": ['K R AE1 N F ER0 D'],
  "cranial": ['K R EY1 N Y AH0 L'],
  "cranium": ['K R EY1 N IY2 AH0 M'],
  "crank": ['K R AE1 NG K'],
  "cranked": ['K R AE1 NG K T'],
  "cranking": ['K R AE1 NG K IH0 NG'],
  "cranks": ['K R AE1 NG K S'],
  "crankshaft": ['K R AE1 NG K SH AE2 F T'],
  "crankshafts": ['K R AE1 NG K SH AE2 F T S'],
  "cranky": ['K R AE1 NG K IY0'],
  "cranley": ['K R AE1 N L IY0'],
  "cranmer": ['K R AE1 N M ER0'],
  "cranmore": ['K R AA1 N M AO0 R'],
  "crannell": ['K R AE1 N AH0 L'],
  "cranney": ['K R AE1 N IY0'],
  "crannies": ['K R AE1 N IY0 Z'],
  "cranny": ['K R AE1 N IY0'],
  "cranor": ['K R EY1 N ER0'],
  "crans": ['K R AE1 N Z'],
  "cranshaw": ['K R AE1 N SH AO2'],
  "cranston": ['K R AE1 N S T AH0 N'],
  "cranston's": ['K R AE1 N S T AH0 N Z'],
  "crap": ['K R AE1 P'],
  "crapo": ['K R AA1 P OW2'],
  "crappie": ['K R AE1 P IY2'],
  "crapps": ['K R AE1 P S'],
  "crappy": ['K R AE1 P IY2'],
  "craps": ['K R AE1 P S'],
  "crapser": ['K R AE1 P S ER0'],
  "crapshoot": ['K R AE1 P SH UW2 T'],
  "crary": ['K ER1 EH0 R IY2'],
  "crase": ['K R EY1 Z'],
  "crash": ['K R AE1 SH'],
  "crash's": ['K R AE1 SH IH0 Z'],
  "crashed": ['K R AE1 SH T'],
  "crashes": ['K R AE1 SH IH0 Z'],
  "crashing": ['K R AE1 SH IH0 NG'],
  "crasner": ['K R AE1 Z N ER0'],
  "crasnianski": ['K R AE2 S N IY0 AE1 N S K IY0'],
  "crass": ['K R AE1 S'],
  "crassweller": ['K R AE1 S W EH2 L ER0'],
  "crassweller's": ['K R AE1 S W EH2 L ER0 Z'],
  "crate": ['K R EY1 T'],
  "crater": ['K R EY1 T ER0'],
  "cratered": ['K R EY1 T ER0 D'],
  "craters": ['K R EY1 T ER0 Z'],
  "crates": ['K R EY1 T S'],
  "cratia": ['K R EY1 SH AH0'],
  "crating": ['K R EY1 T IH0 NG'],
  "craton": ['K R AE1 T AH0 N'],
  "cratty": ['K R AE1 T IY0'],
  "craugh": ['K R AO1'],
  "craun": ['K R AO1 N'],
  "cravath": ['K R AE1 V AH0 TH'],
  "crave": ['K R EY1 V'],
  "craved": ['K R EY1 V D'],
  "craven": ['K R EY1 V AH0 N'],
  "craven's": ['K R EY1 V AH0 N Z'],
  "cravens": ['K R EY1 V AH0 N Z'],
  "craver": ['K R EY1 V ER0'],
  "craves": ['K R EY1 V Z'],
  "cravey": ['K R EY1 V IY0'],
  "cravin": ['K R EY1 V IH0 N'],
  "craving": ['K R EY1 V IH0 NG'],
  "cravings": ['K R EY1 V IH0 NG Z'],
  "craw": ['K R AO1'],
  "crawfish": ['K R AO1 F IH2 SH'],
  "crawford": ['K R AO1 F ER0 D'],
  "crawford's": ['K R AO1 F ER0 D Z'],
  "crawfordsville": ['K R AO1 F ER0 D Z V IH2 L'],
  "crawl": ['K R AO1 L'],
  "crawled": ['K R AO1 L D'],
  "crawler": ['K R AO1 L ER0'],
  "crawley": ['K R AO1 L IY0'],
  "crawling": ['K R AO1 L IH0 NG'],
  "crawls": ['K R AO1 L Z'],
  "crawly": ['K R AO1 L IY0'],
  "crawmer": ['K R AO1 M ER0'],
  "crawshaw": ['K R AO1 SH AO2'],
  "craxi": ['K R AE1 K S IY0'],
  "cray": ['K R EY1'],
  "cray's": ['K R EY1 Z'],
  "craycraft": ['K R EY1 K R AE2 F T'],
  "crayfish": ['K R EY1 F IH0 SH'],
  "crayne": ['K R EY1 N'],
  "crayon": ['K R EY1 AA2 N'],
  "crayons": ['K R EY1 AA2 N Z'],
  "crays": ['K R EY1 Z'],
  "crayton": ['K R EY1 T AH0 N'],
  "craze": ['K R EY1 Z'],
  "crazed": ['K R EY1 Z D'],
  "crazier": ['K R EY1 Z IY0 ER0'],
  "crazies": ['K R EY1 Z IY0 Z'],
  "craziest": ['K R EY1 Z IY0 AH0 S T'],
  "crazily": ['K R EY1 Z AH0 L IY0'],
  "craziness": ['K R EY1 Z IY0 N AH0 S'],
  "crazy": ['K R EY1 Z IY0'],
  "crea": ['K R IY1'],
  "creach": ['K R IY1 CH'],
  "creager": ['K R IY1 IH0 JH ER0'],
  "creagh": ['K R IY1 G'],
  "creak": ['K R IY1 K'],
  "creaked": ['K R IY1 K T'],
  "creaking": ['K R IY1 K IH0 NG'],
  "creaky": ['K R IY1 K IY0'],
  "creal": ['K R IY1 L'],
  "cream": ['K R IY1 M'],
  "creamed": ['K R IY1 M D'],
  "creamer": ['K R IY1 M ER0'],
  "creamery": ['K R IY1 M ER0 IY0'],
  "creamier": ['K R IY1 M IY0 ER0'],
  "creamiest": ['K R IY1 M IY0 IH0 S T'],
  "creams": ['K R IY1 M Z'],
  "creamy": ['K R IY1 M IY0'],
  "crean": ['K R IY1 N'],
  "crear": ['K R IH1 R'],
  "crease": ['K R IY1 S'],
  "creases": ['K R IY1 S IH0 Z'],
  "creasey": ['K R IY1 S IY0'],
  "creasman": ['K R IY1 Z M AH0 N'],
  "creason": ['K R IY1 S AH0 N'],
  "creasy": ['K R IY1 S IY0'],
  "create": ['K R IY0 EY1 T'],
  "create-a-book": ['K R IY0 EY2 T AH0 B UH1 K'],
  "created": ['K R IY0 EY1 T AH0 D', 'K R IY0 EY1 T IH0 D'],
  "creates": ['K R IY0 EY1 T S'],
  "creath": ['K R EH1 TH'],
  "creating": ['K R IY0 EY1 T IH0 NG'],
  "creation": ['K R IY0 EY1 SH AH0 N'],
  "creationism": ['K R IY0 EY1 SH AH0 N IH2 Z AH0 M'],
  "creations": ['K R IY0 EY1 SH AH0 N Z'],
  "creative": ['K R IY0 EY1 T IH0 V'],
  "creatively": ['K R IY0 EY1 T IH0 V L IY0'],
  "creativeness": ['K R IY0 EY1 T IH0 V N AH0 S'],
  "creativity": ['K R IY2 EY0 T IH1 V AH0 T IY0'],
  "creatologist": ['K R IY0 EY2 T AO1 L AH0 JH IH0 S T'],
  "creatologists": ['K R IY0 EY2 T AO1 L AH0 JH IH0 S T S'],
  "creator": ['K R IY0 EY1 T ER0'],
  "creators": ['K R IY0 EY1 T ER0 Z'],
  "creature": ['K R IY1 CH ER0'],
  "creatures": ['K R IY1 CH ER0 Z'],
  "crecelius": ['K R IH0 S IY1 L IY0 IH0 S'],
  "creche": ['K R EH1 SH'],
  "creches": ['K R EH1 SH AH0 Z'],
  "credence": ['K R IY1 D AH0 N S'],
  "credential": ['K R IH0 D EH1 N SH AH0 L', 'K R AH0 D EH1 N SH AH0 L'],
  "credentialed": ['K R AH0 D EH1 N SH AH0 L D'],
  "credentials": ['K R AH0 D EH1 N SH AH0 L Z'],
  "credenza": ['K R AH0 D EH1 N Z AH0'],
  "credeur": ['K R IH0 D ER1'],
  "credibility": ['K R EH2 D AH0 B IH1 L IH0 T IY0'],
  "credible": ['K R EH1 D AH0 B AH0 L'],
  "credibly": ['K R EH1 D AH0 B L IY0'],
  "credit": ['K R EH1 D AH0 T', 'K R EH1 D IH0 T'],
  "credit's": ['K R EH1 D IH0 T S'],
  "creditable": ['K R EH1 D AH0 T AH0 B AH0 L'],
  "creditably": ['K R EH1 D AH0 T AH0 B L IY0'],
  "creditanstalt": ['K R EH2 D IH1 T AH0 N SH T AO2 L T'],
  "creditbank": ['K R EH1 D IH0 T B AE2 NG K'],
  "credited": ['K R EH1 D AH0 T AH0 D', 'K R EH1 D IH0 T IH0 D'],
  "credithrift": ['K R EH2 D IH0 TH R IH1 F T'],
  "crediting": ['K R EH1 D AH0 T IH0 NG'],
  "credito": ['K R EH0 D IY1 T OW0'],
  "creditor": ['K R EH1 D AH0 T ER0', 'K R EH1 D IH0 T ER0'],
  "creditors": ['K R EH1 D IH0 T ER0 Z'],
  "creditors'": ['K R EH1 D IH0 T ER0 Z'],
  "credits": ['K R EH1 D IH0 T S'],
  "creditwatch": ['K R EH1 D IH0 T W AA2 CH'],
  "creditworthiness": ['K R EH1 D IH0 T W ER2 DH IY0 N AH0 S'],
  "creditworthy": ['K R EH1 D IH0 T W ER2 DH IY0'],
  "credle": ['K R EH1 D AH0 L'],
  "credo": ['K R EY1 D OW0', 'K R IY1 D OW0'],
  "credulity": ['K R IH0 D UW1 L AH0 T IY0'],
  "credulous": ['K R EH1 JH AH0 L AH0 S'],
  "cree": ['K R IY1'],
  "creech": ['K R IY1 CH'],
  "creecy": ['K R IY1 S IY0'],
  "creed": ['K R IY1 D'],
  "creeden": ['K R IY1 D AH0 N'],
  "creedon": ['K R IY1 D AH0 N'],
  "creeds": ['K R IY1 D Z'],
  "creegan": ['K R IY1 G AH0 N'],
  "creek": ['K R IY1 K'],
  "creek's": ['K R IY1 K S'],
  "creekmore": ['K R IY1 K M AO0 R'],
  "creekmur": ['K R IY1 K M ER0'],
  "creeks": ['K R IY1 K S'],
  "creel": ['K R IY1 L'],
  "creelman": ['K R IY1 L M AH0 N'],
  "creely": ['K R IY1 L IY0'],
  "creep": ['K R IY1 P'],
  "creepiness": ['K R IY1 P IY0 N IH0 S'],
  "creeping": ['K R IY1 P IH0 NG'],
  "creeps": ['K R IY1 P S'],
  "creepy": ['K R IY1 P IY0'],
  "creer": ['K R IH1 R'],
  "crees": ['K R IY1 Z'],
  "cref": ['K R EH1 F'],
  "cref's": ['K R EH1 F S'],
  "cregan": ['K R IY1 G AH0 N'],
  "cregar": ['K R IY1 G ER0'],
  "creger": ['K R IY1 JH ER0'],
  "cregg": ['K R EH1 G'],
  "cregger": ['K R EH1 G ER0'],
  "crego": ['K R EH1 G OW0'],
  "crehan": ['K R EH1 HH AH0 N'],
  "creighton": ['K R EY1 T AH0 N'],
  "creko": ['K R EH1 K OW0'],
  "crellin": ['K R EH1 L IH0 N'],
  "cremate": ['K R IY1 M EY0 T'],
  "cremated": ['K R IY1 M EY0 T IH0 D'],
  "cremation": ['K R IY0 M EY1 SH AH0 N'],
  "crematoria": ['K R IY0 M AH0 T AO1 R IY0 AH0'],
  "crematorium": ['K R IY0 M AH0 T AO1 R IY0 AH0 M'],
  "creme": ['K R IY1 M'],
  "cremeans": ['K R EH1 M AH0 N Z'],
  "cremeens": ['K R IH0 M IY1 N Z'],
  "cremer": ['K R IY1 M ER0'],
  "cremin": ['K R EH1 M IH0 N'],
  "crenelate": ['K R EH1 N AH0 L EY2 T'],
  "crenelated": ['K R EH1 N AH0 L EY2 T AH0 D'],
  "crenshaw": ['K R EH1 N SH AO2'],
  "crenwelge": ['K R EH1 N W IH0 L JH'],
  "creole": ['K R IY1 OW0 L'],
  "creoles": ['K R IY0 OW1 L Z'],
  "creolize": ['K R IY1 OW2 L AY2 Z'],
  "creolized": ['K R IY1 OW2 L AY2 Z D'],
  "creosote": ['K R IY1 AH0 S OW2 T'],
  "crepe": ['K R EY1 P'],
  "crepeau": ['K R IH0 P OW1'],
  "crepes": ['K R EY1 P S'],
  "creps": ['K R EH1 P S'],
  "crept": ['K R EH1 P T'],
  "creque": ['K R EH1 K'],
  "cresap": ['K R EH1 S AH0 P', 'K R IY1 S AH0 P'],
  "crescendo": ['K R IH0 SH EH1 N D OW0'],
  "crescent": ['K R EH1 S AH0 N T'],
  "crescents": ['K R EH1 S AH0 N T S'],
  "crescenzi": ['K R EH0 S CH EH1 N Z IY0'],
  "crescenzo": ['K R EH0 S CH EH1 N Z OW0'],
  "cresci": ['K R EH1 S IY0'],
  "crescott": ['K R EH1 S K AA0 T'],
  "creson": ['K R EH1 S AH0 N'],
  "crespi": ['K R EH1 S P IY0'],
  "crespin": ['K R EH1 S P IH0 N'],
  "crespo": ['K R EH1 S P OW0'],
  "cress": ['K R EH1 S'],
  "cresses": ['K R EH1 S AH0 Z', 'K R EH1 S IH0 Z'],
  "cressey": ['K R EH1 S IY0'],
  "cressler": ['K R EH1 S L ER0'],
  "cressman": ['K R EH1 S M AH0 N'],
  "cresson": ['K R EH1 S AH0 N'],
  "cresswell": ['K R EH1 S W EH2 L'],
  "cressy": ['K R EH1 S IY0'],
  "crest": ['K R EH1 S T'],
  "crestar": ['K R EH1 S T AA2 R'],
  "crested": ['K R EH1 S T IH0 D'],
  "crestfallen": ['K R EH1 S T F AO2 L AH0 N'],
  "cresting": ['K R EH1 S T IH0 NG'],
  "crestmont": ['K R EH1 S T M AA2 N T'],
  "crests": ['K R EH1 S T S'],
  "creswell": ['K R EH1 S W EH2 L'],
  "cretaceous": ['K R IH0 T EY1 SH IH0 S'],
  "cretchen": ['K R EH1 CH AH0 N'],
  "crete": ['K R IY1 T'],
  "cretella": ['K R EH0 T EH1 L AH0'],
  "cretien": ['K R IY1 SH Y AH0 N'],
  "cretin": ['K R IY1 T AH0 N'],
  "cretsinger": ['K R EH1 T S IH0 N JH ER0'],
  "creutzfeldt": ['K R UH1 T S F EH0 L D', 'K R UH1 T S F EH0 L T'],
  "crevasse": ['K R AH0 V AE1 S'],
  "creveling": ['K R EH1 V AH0 L IH0 NG', 'K R EH1 V L IH0 NG'],
  "crevice": ['K R EH1 V AH0 S'],
  "crevices": ['K R EH1 V AH0 S IH0 Z'],
  "crevier": ['K R IY1 V IY0 ER0'],
  "creviston": ['K R EH1 V IH0 S T AA0 N'],
  "crew": ['K R UW1'],
  "crew's": ['K R UW1 Z'],
  "crewe": ['K R UW1'],
  "crewel": ['K R UW1 AH0 L'],
  "crewes": ['K R UW1 Z'],
  "crewman": ['K R UW1 M AH0 N'],
  "crewmen": ['K R UW1 M IH0 N'],
  "crews": ['K R UW1 Z'],
  "crib": ['K R IH1 B'],
  "cribari": ['K R IY0 B AA1 R IY0'],
  "cribb": ['K R IH1 B'],
  "cribbage": ['K R IH1 B IH0 JH'],
  "cribbing": ['K R IH1 B IH0 NG'],
  "cribbs": ['K R IH1 B Z'],
  "cribs": ['K R IH1 B Z'],
  "crichlow": ['K R IH1 K L OW0'],
  "crichton": ['K R IH1 CH T AH0 N'],
  "crick": ['K R IH1 K'],
  "cricket": ['K R IH1 K AH0 T', 'K R IH1 K IH0 T'],
  "cricket's": ['K R IH1 K AH0 T S'],
  "crickets": ['K R IH1 K AH0 T S'],
  "criddle": ['K R IH1 D AH0 L'],
  "crider": ['K R AY1 D ER0'],
  "cried": ['K R AY1 D'],
  "crier": ['K R AY1 ER0'],
  "cries": ['K R AY1 Z'],
  "criger": ['K R AY1 JH ER0'],
  "crigger": ['K R IH1 G ER0'],
  "crigler": ['K R IH1 G L ER0'],
  "crihfield": ['K R IH1 F IY0 L D'],
  "crill": ['K R IH1 L'],
  "crilley": ['K R IH1 L IY0'],
  "crilly": ['K R IH1 L IY0'],
  "crim": ['K R IH1 M'],
  "crime": ['K R AY1 M'],
  "crime's": ['K R AY1 M Z'],
  "crimea": ['K R AY0 M IY1 AH0'],
  "crimean": ['K R IH0 M IY1 N', 'K R AY2 M IY1 AH0 N'],
  "crimes": ['K R AY1 M Z'],
  "crimi": ['K R IY1 M IY0'],
  "criminal": ['K R IH1 M AH0 N AH0 L'],
  "criminal's": ['K R IH1 M AH0 N AH0 L Z'],
  "criminalist": ['K R IH1 M AH0 N AH0 L IH0 S T'],
  "criminalist's": ['K R IH1 M AH0 N AH0 L IH0 S T S'],
  "criminalistics": ['K R IH2 M AH0 N AH0 L IH1 S T IH0 K S'],
  "criminalists": ['K R IH1 M AH0 N AH0 L IH0 S T S'],
  "criminality": ['K R IH2 M AH0 N AE1 L IH0 T IY0'],
  "criminalization": ['K R IH2 M AH0 N AH0 L AH0 Z EY1 SH AH0 N'],
  "criminalize": ['K R IH1 M AH0 N AH0 L AY2 Z'],
  "criminalized": ['K R IH1 M AH0 N AH0 L AY2 Z D'],
  "criminalizing": ['K R IH1 M AH0 N AH0 L AY2 Z IH0 NG'],
  "criminally": ['K R IH1 M AH0 N AH0 L IY0'],
  "criminals": ['K R IH1 M AH0 N AH0 L Z'],
  "criminals'": ['K R IH1 M AH0 N AH0 L Z'],
  "criminologist": ['K R IH2 M IH0 N AA1 L AH0 JH IH0 S T'],
  "criminologists": ['K R IH2 M IH0 N AA1 L AH0 JH IH0 S T S'],
  "criminology": ['K R IH2 M IH0 N AA1 L AH0 JH IY0'],
  "crimm": ['K R IH1 M'],
  "crimmins": ['K R IH1 M IH0 N Z'],
  "crimp": ['K R IH1 M P'],
  "crimped": ['K R IH1 M P T'],
  "crimping": ['K R IH1 M P IH0 NG'],
  "crimps": ['K R IH1 M P S'],
  "crimson": ['K R IH1 M Z AH0 N'],
  "criner": ['K R AY1 N ER0'],
  "cringe": ['K R IH1 N JH'],
  "cringed": ['K R IH1 N JH D'],
  "cringes": ['K R IH1 N JH IH0 Z'],
  "cringing": ['K R IH1 N JH IH0 NG'],
  "crip": ['K R IH1 P'],
  "cripe": ['K R AY1 P'],
  "crippen": ['K R IH1 P AH0 N'],
  "cripple": ['K R IH1 P AH0 L'],
  "crippled": ['K R IH1 P AH0 L D'],
  "cripples": ['K R IH1 P AH0 L Z'],
  "crippling": ['K R IH1 P AH0 L IH0 NG', 'K R IH1 P L IH0 NG'],
  "cripps": ['K R IH1 P S'],
  "crips": ['K R IH1 P S'],
  "cris": ['K R IH1 S'],
  "crisafi": ['K R IY0 S AA1 F IY0'],
  "crisafulli": ['K R IY0 S AA0 F UW1 L IY0'],
  "crisanti": ['K R IH0 S AE1 N T IY0'],
  "crisci": ['K R IH1 S IY0'],
  "criscione": ['K R IY0 S CH OW1 N IY0'],
  "crisco": ['K R IY1 S K OW0'],
  "criscuolo": ['K R IY0 S K W OW1 L OW0'],
  "crise": ['K R AY1 Z'],
  "crises": ['K R AY1 S IY0 Z'],
  "crishman": ['K R IH1 SH M AH0 N'],
  "crishman's": ['K R IH1 SH M AH0 N Z'],
  "crisis": ['K R AY1 S AH0 S'],
  "crisler": ['K R IH1 S AH0 L ER0', 'K R IH1 S L ER0'],
  "crislip": ['K R IH1 S L IH0 P'],
  "crisman": ['K R IH1 S M AH0 N'],
  "crismon": ['K R IH1 Z M AH0 N'],
  "crisostomo": ['K R IY0 S OW0 S T OW1 M OW0'],
  "crisp": ['K R IH1 S P'],
  "crispell": ['K R IH1 S P AH0 L'],
  "crispen": ['K R IH1 S P AH0 N'],
  "crisper": ['K R IH1 S P ER0'],
  "crispin": ['K R IH1 S P IH0 N'],
  "crispina": ['K R IY0 S P IY1 N AH0'],
  "crispino": ['K R IY0 S P IY1 N OW0'],
  "crisply": ['K R IH1 S P L IY0'],
  "crispness": ['K R IH1 S P N AH0 S'],
  "crispo": ['K R IY1 S P OW0'],
  "crisps": ['K R IH1 S P S'],
  "crispy": ['K R IH1 S P IY0'],
  "criss": ['K R IH1 S'],
  "criss-cross": ['K R IH1 S K R AO1 S'],
  "criss-crossed": ['K R IH1 S K R AO1 S T'],
  "crisscross": ['K R IH1 S K R AO2 S'],
  "crisscrossed": ['K R IH1 S K R AO2 S T'],
  "crisscrossing": ['K R IH1 S K R AO2 S IH0 NG'],
  "crissey": ['K R IH1 S IY0'],
  "crissinger": ['K R IH1 S IH0 N JH ER0'],
  "crisslow": ['K R IH1 S L OW0'],
  "crissman": ['K R IH1 S M AH0 N'],
  "crist": ['K R IH1 S T'],
  "crista": ['K R IH1 S T AH0'],
  "cristabel": ['K R IH1 S T AH0 B EH0 L'],
  "cristal": ['K R IH1 S T AH0 L'],
  "cristiani": ['K R IH2 S T IY0 AA1 N IY0', 'K R IH2 S CH IY0 AA1 N IY0'],
  "cristiani's": ['K R IH2 S T IY0 AA1 N IY0 Z', 'K R IH2 S CH IY0 AA1 N IY0 Z'],
  "cristiano": ['K R IY0 S T IY0 AA1 N OW0'],
  "cristina": ['K R IH0 S T IY1 N AH0'],
  "cristo": ['K R IH1 S T OW2'],
  "cristo's": ['K R IH1 S T OW2 Z'],
  "cristobal": ['K R IH1 S T AH0 B AH0 L'],
  "cristobel": ['K R IH1 S T AH0 B EH0 L'],
  "cristobel's": ['K R IH1 S T AH0 B EH0 L Z'],
  "criswell": ['K R IH1 S W EH0 L'],
  "critcher": ['K R IH1 CH ER0'],
  "critchfield": ['K R IH1 CH F IY0 L D'],
  "critchley": ['K R IH1 CH L IY0'],
  "critchlow": ['K R IH1 CH L OW0'],
  "critelli": ['K R IH0 T EH1 L IY0'],
  "criteria": ['K R AY0 T IH1 R IY0 AH0'],
  "criterion": ['K R AY0 T IH1 R IY0 AH0 N'],
  "crites": ['K R AY1 T S'],
  "critic": ['K R IH1 T IH0 K'],
  "critic's": ['K R IH1 T IH0 K S'],
  "critical": ['K R IH1 T IH0 K AH0 L'],
  "criticality": ['K R IH2 T AH0 K AE1 L AH0 T IY0'],
  "critically": ['K R IH1 T IH0 K AH0 L IY0', 'K R IH1 T IH0 K L IY0'],
  "criticism": ['K R IH1 T IH0 S IH2 Z AH0 M'],
  "criticisms": ['K R IH1 T IH0 S IH2 Z AH0 M Z'],
  "criticize": ['K R IH1 T IH0 S AY2 Z'],
  "criticized": ['K R IH1 T AH0 S AY2 Z D'],
  "criticizes": ['K R IH1 T IH0 S AY2 Z IH0 Z'],
  "criticizing": ['K R IH1 T IH0 S AY2 Z IH0 NG'],
  "critics": ['K R IH1 T IH0 K S'],
  "critics'": ['K R IH1 T IH0 K S'],
  "critique": ['K R AH0 T IY1 K', 'K R IH0 T IY1 K'],
  "critiqued": ['K R IH0 T IY1 K T'],
  "critiques": ['K R IH0 T IY1 K S'],
  "critiquing": ['K R IH0 T IY1 K IH0 NG'],
  "criton": ['K R AY1 T AH0 N'],
  "critser": ['K R IH1 T S ER0'],
  "crittenden": ['K R IH0 T EH1 N D AH0 N'],
  "crittendon": ['K R IH1 T AH0 N D IH0 N'],
  "critter": ['K R IH1 T ER0'],
  "critters": ['K R IH1 T ER0 Z'],
  "critz": ['K R IH1 T S'],
  "critzer": ['K R IH1 T Z ER0'],
  "crivaro": ['K R IH0 V AE1 R OW0'],
  "crivelli": ['K R IH0 V EH1 L IY0'],
  "crivello": ['K R IH0 V EH1 L OW0'],
  "criven": ['K R IH1 V AH0 N'],
  "crm": ['S IY1 AA1 R EH1 M'],
  "crnkovich": ['S ER1 N AH0 V IH2 CH'],
  "cro": ['K R OW1'],
  "croak": ['K R OW1 K'],
  "croaker": ['K R OW1 K ER0'],
  "croaking": ['K R OW1 K IH0 NG'],
  "croat": ['K R OW1 AA2 T'],
  "croat's": ['K R OW1 AA2 T S'],
  "croatia": ['K R OW0 EY1 SH AH0'],
  "croatia's": ['K R OW0 EY1 SH AH0 Z'],
  "croatian": ['K R OW0 EY1 SH AH0 N'],
  "croatian's": ['K R OW0 EY1 SH AH0 N Z'],
  "croatians": ['K R OW0 EY1 SH AH0 N Z'],
  "croatians'": ['K R OW0 EY1 SH AH0 N Z'],
  "croats": ['K R OW1 AA2 T S'],
  "croc": ['K R AA1 K'],
  "crocco": ['K R AA1 K OW0'],
  "croce": ['K R OW1 CH IY0'],
  "crochet": ['K R OW0 SH EY1'],
  "crocheted": ['K R OW0 SH EY1 D'],
  "crochetiere": ['K R OW2 SH AH0 T IH1 R'],
  "crocheting": ['K R OW0 SH EY1 IH0 NG'],
  "crock": ['K R AA1 K'],
  "crocker": ['K R AA1 K ER0'],
  "crocker's": ['K R AA1 K ER0 Z'],
  "crockery": ['K R AA1 K ER0 IY0'],
  "crockett": ['K R AA1 K IH0 T'],
  "crockford": ['K R AA1 K F ER0 D'],
  "crocodile": ['K R AA1 K AH0 D AY2 L'],
  "crocodiles": ['K R AA1 K AH0 D AY2 L Z'],
  "crocodilian": ['K R AA2 K AH0 D IH1 L Y AH0 N'],
  "crocus": ['K R OW1 K AH0 S'],
  "crocuses": ['K R OW1 K Y UW0 S IH0 Z'],
  "croff": ['K R AO1 F'],
  "crofford": ['K R AA1 F ER0 D'],
  "crofoot": ['K R OW1 F UH0 T'],
  "croft": ['K R AO1 F T'],
  "crofton": ['K R AA1 F T AH0 N'],
  "crofts": ['K R AO1 F T S'],
  "crogan": ['K R OW1 G AH0 N'],
  "croghan": ['K R AA1 G AH0 N'],
  "croissant": ['K W AA2 S AA1 N T', 'K R AH0 S AA1 N T'],
  "croissants": ['K W AA2 S AA1 N T S', 'K R AH0 S AA1 N T S'],
  "croitzer": ['K R OY1 T S ER0'],
  "croix": ['K R OY1'],
  "croix's": ['K R OY1 Z'],
  "croke": ['K R OW1 K'],
  "croker": ['K R OW1 K ER0'],
  "croley": ['K R OW1 L IY0'],
  "croll": ['K R OW1 L'],
  "crom": ['K R AA1 M'],
  "cromartie": ['K R AA1 M AA0 R T IY0'],
  "crombie": ['K R AA1 M B IY0'],
  "cromer": ['K R OW1 M ER0'],
  "cromer's": ['K R OW1 M ER0 Z'],
  "cromie": ['K R AA1 M IY0'],
  "cromley": ['K R AA1 M L IY0'],
  "crompton": ['K R AA1 M P T AH0 N'],
  "cromwell": ['K R AA1 M W AH0 L'],
  "cromwell's": ['K R AA1 M W IH0 L Z'],
  "cron": ['K R AA1 N'],
  "cronan": ['K R OW1 N AH0 N'],
  "cronauer": ['K R AA1 N AW0 R'],
  "cronce": ['K R AA1 N S'],
  "crone": ['K R OW1 N'],
  "cronen": ['K R OW1 N AH0 N'],
  "croner": ['K R OW1 N ER0'],
  "croney": ['K R OW1 N IY0'],
  "cronies": ['K R OW1 N IY0 Z'],
  "cronin": ['K R OW1 N IH0 N'],
  "cronk": ['K R AA1 NG K'],
  "cronkhite": ['K R AA1 NG K HH AY2 T'],
  "cronkite": ['K R AA1 NG K AY2 T'],
  "cronkright": ['K R AA1 NG K R AY2 T'],
  "cronquist": ['K R AA1 N K W IH2 S T'],
  "cronus": ['K R OW1 N AH0 S'],
  "crony": ['K R OW1 N IY0'],
  "cronyism": ['K R OW1 N IY0 IH2 Z AH0 M'],
  "crook": ['K R UH1 K'],
  "crooke": ['K R UH1 K'],
  "crooked": ['K R UH1 K AH0 D'],
  "crooked-leg": ['K R UH1 K AH0 D L EH2 G'],
  "crookedness": ['K R UH1 K AH0 D N AH0 S'],
  "crooker": ['K R UH1 K ER0'],
  "crooklyn": ['K R UH1 K L IH2 N'],
  "crooklyn's": ['K R UH1 K L IH2 N Z'],
  "crooks": ['K R UH1 K S'],
  "crookshank": ['K R UH1 K SH AE2 NG K'],
  "crookshanks": ['K R UH1 K SH AE2 NG K S'],
  "crookston": ['K R UH1 K S T AH0 N'],
  "croom": ['K R UW1 M'],
  "crooms": ['K R UW1 M Z'],
  "croon": ['K R UW1 N'],
  "crooner": ['K R UW1 N ER0'],
  "crooning": ['K R UW1 N IH0 NG'],
  "croons": ['K R UW1 N Z'],
  "crop": ['K R AA1 P'],
  "crop's": ['K R AA1 P S'],
  "cropland": ['K R AA1 P L AE2 N D'],
  "cropley": ['K R AA1 P L IY0'],
  "cropp": ['K R AA1 P'],
  "cropped": ['K R AA1 P T'],
  "cropper": ['K R AA1 P ER0'],
  "cropping": ['K R AA1 P IH0 NG'],
  "crops": ['K R AA1 P S'],
  "cropsey": ['K R AA1 P S IY0'],
  "croquet": ['K R OW0 K EY1'],
  "cros": ['K R AO1 S'],
  "crosbey": ['K R AA1 S B IY0'],
  "crosbie": ['K R AO1 Z B IY0'],
  "crosby": ['K R AO1 Z B IY0'],
  "crosby's": ['K R AO1 Z B IY0 Z'],
  "crose": ['K R OW1 Z'],
  "croshaw": ['K R AA1 SH AO0'],
  "croskey": ['K R AA1 S K IY0'],
  "crosland": ['K R AA1 S L AH0 N D'],
  "crosley": ['K R AA1 S L IY0'],
  "croslin": ['K R AA1 S L IH0 N'],
  "croson": ['K R OW1 S AH0 N'],
  "cross": ['K R AO1 S'],
  "cross's": ['K R AO1 S IH0 Z'],
  "cross-country": ['K R AO1 S K AH2 N T R IY0'],
  "cross-pollinate": ['K R AO1 S P AA2 L AH0 N EY2 T'],
  "cross-reference": ['K R AO1 S R EH2 F ER0 AH0 N S'],
  "crossan": ['K R AA1 S AH0 N'],
  "crossbill": ['K R AO1 S B IH2 L'],
  "crossbills": ['K R AO1 S B IH2 L Z'],
  "crossbones": ['K R AO1 S B OW2 N Z'],
  "crossborder": ['K R AO1 S B AO2 R D ER0'],
  "crossbow": ['K R AO1 S B OW2'],
  "crossbows": ['K R AO1 S B OW2 Z'],
  "crosscurrent": ['K R AO1 S K ER2 AH0 N T'],
  "crosscurrents": ['K R AO1 S K ER2 AH0 N T S'],
  "crosse": ['K R AA1 S'],
  "crossed": ['K R AO1 S T'],
  "crossen": ['K R AO1 S AH0 N'],
  "crosser": ['K R AO1 S ER0'],
  "crosses": ['K R AO1 S IH0 Z'],
  "crossett": ['K R AA1 S IH0 T'],
  "crossfield": ['K R AO1 S F IY2 L D'],
  "crossfire": ['K R AO1 S F AY0 R', 'K R AO1 S F AY2 R'],
  "crossfire's": ['K R AO1 S F AY0 R Z'],
  "crossin": ['K R AA1 S IH0 N'],
  "crossing": ['K R AO1 S IH0 NG'],
  "crossings": ['K R AO1 S IH0 NG Z'],
  "crossland": ['K R AO1 S L AE2 N D'],
  "crossley": ['K R AA1 S L IY0'],
  "crosslin": ['K R AA1 S L IH0 N'],
  "crossman": ['K R AO1 S M AH0 N'],
  "crossnine": ['K R AO1 S N AY2 N'],
  "crossno": ['K R OW1 S N OW0'],
  "crosson": ['K R AA1 S AH0 N'],
  "crossover": ['K R AO1 S OW2 V ER0'],
  "crosspiece": ['K R AO1 S P IY2 S'],
  "crosspieces": ['K R AO1 S P IY2 S AH0 Z', 'K R AO1 S P IY2 S IH0 Z'],
  "crossroad": ['K R AO1 S R OW2 D'],
  "crossroads": ['K R AO1 S R OW2 D Z'],
  "crosstalk": ['K R AO1 S T AO1 K'],
  "crosstown": ['K R AO1 S T AW2 N'],
  "crossville": ['K R AA1 S V IH0 L'],
  "crosswalk": ['K R AA1 S W AA2 K'],
  "crosswalks": ['K R AA1 S W AA2 K S'],
  "crosswhite": ['K R AA1 S W AY0 T'],
  "crosswise": ['K R AO1 S W AY2 Z'],
  "crossword": ['K R AO1 S W ER2 D'],
  "crosswords": ['K R AO1 S W ER2 D Z'],
  "crosthwait": ['K R AA1 S TH W AH0 T'],
  "crosthwaite": ['K R AA1 S TH W AH0 T'],
  "croston": ['K R AA1 S T AH0 N'],
  "croswell": ['K R AA1 S W EH0 L'],
  "crotch": ['K R AA1 CH'],
  "crotchety": ['K R AA1 CH AH0 T IY0'],
  "croteau": ['K R AH0 T OW1'],
  "crothers": ['K R AH1 DH ER0 Z'],
  "crotonville": ['K R OW1 T AH0 N V IH2 L'],
  "crotteau": ['K R AH0 T OW1'],
  "crotts": ['K R AA1 T S'],
  "crotty": ['K R AA1 T IY0'],
  "crotwell": ['K R AA1 T W EH2 L'],
  "crotzer": ['K R OW1 T Z ER0'],
  "crouch": ['K R AW1 CH'],
  "crouched": ['K R AW1 CH T'],
  "croucher": ['K R AW1 CH ER0'],
  "crouching": ['K R AW1 CH IH0 NG'],
  "crough": ['K R AW1'],
  "crounse": ['K R AW1 N S'],
  "croup": ['K R UW1 P'],
  "crouse": ['K R AW1 S'],
  "crouser": ['K R AW1 S ER0'],
  "crout": ['K R AW1 T'],
  "crouthamel": ['K R AW1 TH AH0 M EH0 L'],
  "crouton": ['K R UW1 T AH0 N'],
  "croutons": ['K R UW1 T AH0 N Z'],
  "crovitz": ['K R OW1 V IH0 T S'],
  "crovitz's": ['K R OW1 V IH0 T S IH0 Z'],
  "crovl": ['K R OW1 V AH0 L'],
  "crovls": ['K R OW1 V AH0 L Z'],
  "crow": ['K R OW1'],
  "crow's": ['K R OW1 Z'],
  "crowbar": ['K R OW1 B AA2 R'],
  "crowborough": ['K R OW1 B ER0 OW0'],
  "crowd": ['K R AW1 D'],
  "crowd's": ['K R AW1 D Z'],
  "crowded": ['K R AW1 D AH0 D', 'K R AW1 D IH0 D'],
  "crowden": ['K R AW1 D AH0 N'],
  "crowder": ['K R AW1 D ER0'],
  "crowding": ['K R AW1 D IH0 NG'],
  "crowds": ['K R AW1 D Z'],
  "crowe": ['K R OW1'],
  "crowed": ['K R OW1 D'],
  "crowell": ['K R OW1 AH0 L'],
  "crowing": ['K R OW1 IH0 NG'],
  "crowkeeper": ['K R OW1 K IY2 P ER0'],
  "crowkeepers": ['K R OW1 K IY2 P ER0 Z'],
  "crowl": ['K R AW1 L'],
  "crowle": ['K R AW1 L'],
  "crowley": ['K R AW1 L IY0'],
  "crowley's": ['K R AW1 L IY0 Z'],
  "crown": ['K R AW1 N'],
  "crown's": ['K R AW1 N Z'],
  "crowne": ['K R AW1 N'],
  "crowned": ['K R AW1 N D'],
  "crowner": ['K R AW1 N ER0'],
  "crowning": ['K R AW1 N IH0 NG'],
  "crownlike": ['K R AW1 N L AY2 K'],
  "crownover": ['K R AW1 N OW2 V ER0'],
  "crowns": ['K R AW1 N Z'],
  "crownx": ['K R AW1 N EH2 K S'],
  "crows": ['K R OW1 Z'],
  "crowson": ['K R AW1 S AH0 N'],
  "crowther": ['K R AW1 DH ER0'],
  "crowther's": ['K R OW1 TH ER0 Z'],
  "crowthers": ['K R OW1 TH ER0 Z'],
  "croxton": ['K R AA1 K S T AH0 N'],
  "croy": ['K R OY1'],
  "croyle": ['K R OY1 L'],
  "crozier": ['K R OW1 ZH ER0'],
  "cruce": ['K R UW1 S'],
  "cruces": ['K R UW1 S IY0 Z'],
  "crucial": ['K R UW1 SH AH0 L'],
  "crucially": ['K R UW1 SH AH0 L IY0'],
  "crucible": ['K R UW1 S AH0 B AH0 L'],
  "crucified": ['K R UW1 S AH0 F AY2 D'],
  "crucifix": ['K R UW1 S AH0 F IH2 K S'],
  "crucifixes": ['K R UW1 S AH0 F IH2 K S IH0 Z'],
  "crucifixion": ['K R UW2 S IH0 F IH1 K SH AH0 N'],
  "crucify": ['K R UW1 S AH0 F AY2'],
  "crud": ['K R AH1 D'],
  "cruddy": ['K R AH1 D IY0'],
  "crude": ['K R UW1 D'],
  "crude's": ['K R UW1 D Z'],
  "crudele": ['K R UW1 D AH0 L'],
  "crudely": ['K R UW1 D L IY0'],
  "crudes": ['K R UW1 D Z'],
  "crudup": ['K R AH1 D AH2 P'],
  "cruea": ['K R UW1 IY0 AH0'],
  "cruel": ['K R UW1 AH0 L', 'K R UW1 L'],
  "cruelest": ['K R UW1 L AH0 S T'],
  "cruelly": ['K R UW1 L IY0'],
  "cruelties": ['K R UW1 L T IY0 Z', 'K R UW1 AH0 L T IY0 Z'],
  "cruelty": ['K R UW1 L T IY0', 'K R UW1 AH0 L T IY0'],
  "cruey": ['K R AH1 IY0'],
  "cruger": ['K R UW1 JH ER0'],
  "cruickshank": ['K R UH1 K SH AE2 NG K'],
  "cruikshank": ['K R UW1 IH0 K SH AE2 NG K'],
  "cruise": ['K R UW1 Z'],
  "cruised": ['K R UW1 Z D'],
  "cruiser": ['K R UW1 Z ER0'],
  "cruiser's": ['K R UW1 Z ER0 Z'],
  "cruisers": ['K R UW1 Z ER0 Z'],
  "cruises": ['K R UW1 Z IH0 Z'],
  "cruising": ['K R UW1 Z IH0 NG'],
  "crull": ['K R AH1 L'],
  "crum": ['K R AH1 M'],
  "crumb": ['K R AH1 M'],
  "crumbaugh": ['K R AH1 M B AO2'],
  "crumble": ['K R AH1 M B AH0 L'],
  "crumbled": ['K R AH1 M B AH0 L D'],
  "crumbles": ['K R AH1 M B AH0 L Z'],
  "crumbley": ['K R AH1 M B L IY0'],
  "crumbling": ['K R AH1 M B AH0 L IH0 NG', 'K R AH1 M B L IH0 NG'],
  "crumbs": ['K R AH1 M Z'],
  "crumby": ['K R AH1 M B IY0'],
  "crume": ['K R UW1 M'],
  "crumitie": ['K R UW1 M IH0 T IY0'],
  "crumley": ['K R AH1 M L IY0'],
  "crumly": ['K R AH1 M L IY0'],
  "crumm": ['K R AH1 M'],
  "crummett": ['K R AH1 M IH0 T'],
  "crummey": ['K R AH1 M IY0'],
  "crummy": ['K R AH1 M IY0'],
  "crump": ['K R AH1 M P'],
  "crumpacker": ['K R AH1 M P AH0 K ER0'],
  "crumple": ['K R AH1 M P AH0 L'],
  "crumpled": ['K R AH1 M P AH0 L D'],
  "crumpler": ['K R AH1 M P AH0 L ER0', 'K R AH1 M P L ER0'],
  "crumpton": ['K R AH1 M P T AH0 N'],
  "crumrine": ['K R AH1 M R IY2 N'],
  "crunch": ['K R AH1 N CH'],
  "crunched": ['K R AH1 N CH T'],
  "cruncher": ['K R AH1 N CH ER0'],
  "crunchers": ['K R AH1 N CH ER0 Z'],
  "crunches": ['K R AH1 N CH IH0 Z'],
  "crunching": ['K R AH1 N CH IH0 NG'],
  "crunchy": ['K R AH1 N CH IY0'],
  "crunk": ['K R AH1 NG K'],
  "crunkleton": ['K R AH1 NG K AH0 L T AA0 N'],
  "crupi": ['K R UW1 P IY0'],
  "crusade": ['K R UW0 S EY1 D'],
  "crusader": ['K R UW0 S EY1 D ER0'],
  "crusaders": ['K R UW0 S EY1 D ER0 Z'],
  "crusades": ['K R UW0 S EY1 D Z'],
  "crusading": ['K R UW0 S EY1 D IH0 NG'],
  "crusan": ['K R UW1 Z AH0 N'],
  "cruse": ['K R UW1 Z'],
  "cruser": ['K R UW1 Z ER0'],
  "crush": ['K R AH1 SH'],
  "crushed": ['K R AH1 SH T'],
  "crusher": ['K R AH1 SH ER0'],
  "crushers": ['K R AH1 SH ER0 Z'],
  "crushes": ['K R AH1 SH IH0 Z'],
  "crushing": ['K R AH1 SH IH0 NG'],
  "crusoe": ['K R UW1 S OW0'],
  "crust": ['K R AH1 S T'],
  "crustacean": ['K R AH0 S T EY1 SH AH0 N'],
  "crustaceans": ['K R AH0 S T EY1 SH AH0 N Z'],
  "crustaceous": ['K R AH0 S T EY1 SH AH0 S'],
  "crustal": ['K R AH1 S T AH0 L'],
  "crusted": ['K R AH1 S T IH0 D'],
  "crusts": ['K R AH1 S T S'],
  "crusty": ['K R AH1 S T IY0'],
  "crutch": ['K R AH1 CH'],
  "crutcher": ['K R AH1 CH ER0'],
  "crutches": ['K R AH1 CH IH0 Z'],
  "crutchfield": ['K R AH1 CH F IY2 L D'],
  "crutchfield's": ['K R AH1 CH F IY2 L D Z'],
  "crutchley": ['K R AH1 CH L IY0'],
  "crute": ['K R UW1 T'],
  "cruthirds": ['K R AH1 TH ER0 D Z'],
  "crux": ['K R AH1 K S'],
  "cruz": ['K R UW1 Z'],
  "cruz's": ['K R UW1 Z IH0 Z'],
  "cruzado": ['K R UW2 Z AA1 D OW0'],
  "cruzados": ['K R UW2 Z AA1 D OW0 S'],
  "cruzan": ['K R UW1 Z AH0 N', 'K R UW2 Z AE1 N'],
  "cruze": ['K R UW1 Z'],
  "cruzen": ['K R UW1 Z AH0 N'],
  "cry": ['K R AY1'],
  "cryan": ['K R AY1 AH0 N'],
  "crybaby": ['K R AY1 B EY1 B IY0'],
  "cryder": ['K R AY1 D ER0'],
  "cryderman": ['K R AY1 D ER0 M AH0 N'],
  "crye": ['K R AY1'],
  "cryer": ['K R AY1 ER0'],
  "crying": ['K R AY1 IH0 NG'],
  "crymes": ['K R AY1 M Z'],
  "cryogenic": ['K R AY1 AH0 JH EH2 N IH0 K'],
  "cryogenics": ['K R AY1 AH0 JH EH2 N IH0 K S'],
  "cryolite": ['K R AY1 AH0 L AY2 T'],
  "cryonics": ['K R AY2 AO1 N IH0 K S'],
  "crypt": ['K R IH1 P T'],
  "cryptic": ['K R IH1 P T IH0 K'],
  "crypto": ['K R IH1 P T OW0'],
  "cryptoclearance": ['K R IH2 P T OW0 L IH1 R AH0 N S'],
  "cryptosporidium": ['K R IH2 P T OW0 S P AO0 R IH1 D IY0 AH0 M'],
  "crypts": ['K R IH1 P T S'],
  "crysler": ['K R IH1 S AH0 L ER0', 'K R IH1 S L ER0'],
  "crystal": ['K R IH1 S T AH0 L'],
  "crystal's": ['K R IH1 S T AH0 L Z'],
  "crystalline": ['K R IH1 S T AH0 L AY2 N'],
  "crystallize": ['K R IH1 S T AH0 L AY2 Z'],
  "crystallized": ['K R IH1 S T AH0 L AY2 Z D'],
  "crystallizes": ['K R IH1 S T AH0 L AY2 Z IH0 Z'],
  "crystallizing": ['K R IH1 S T AH0 L AY2 Z IH0 NG'],
  "crystallographer": ['K R IH2 S T AH0 L AA1 G R AH0 F ER0'],
  "crystallography": ['K R IH2 S T AH0 L AA1 G R AH0 F IY0'],
  "crystals": ['K R IH1 S T AH0 L Z'],
  "cryster": ['K R AY1 S T ER0'],
  "crytzer": ['K R AY1 T Z ER0'],
  "csar": ['Z AA1 R'],
  "csaszar": ['T S AA2 SH AA1 R'],
  "csi": ['S IY1 EH2 S AY1'],
  "cspan": ['S IY1 S P AE1 N'],
  "cspi": ['S IY1 EH1 S P IY1 AY1'],
  "csv": ['S IY1 EH1 S V IY1'],
  "ct": ['K AO1 R T'],
  "cuadra": ['K UW0 AA1 D R AH0'],
  "cuadrado": ['K UW0 AA0 D R AA1 D OW0'],
  "cuajone": ['K Y UW1 AH0 JH OW2 N'],
  "cuauhtemoc": ['K Y UW0 AW1 T AH0 M AA0 K'],
  "cub": ['K AH1 B'],
  "cuba": ['K Y UW1 B AH0'],
  "cuba's": ['K Y UW1 B AH0 Z'],
  "cuban": ['K Y UW1 B AH0 N'],
  "cubans": ['K Y UW1 B AH0 N Z'],
  "cubbage": ['K AH1 B IH0 JH'],
  "cubbies": ['K AH1 B IY0 Z'],
  "cubbison": ['K AH1 B IH0 S AH0 N'],
  "cubbyhole": ['K AH1 B IY0 HH OW2 L'],
  "cube": ['K Y UW1 B'],
  "cubed": ['K Y UW1 B D'],
  "cubes": ['K Y UW1 B Z'],
  "cubic": ['K Y UW1 B IH0 K'],
  "cubic's": ['K Y UW1 B IH0 K S'],
  "cubicle": ['K Y UW1 B IH0 K AH0 L'],
  "cubicles": ['K Y UW1 B IH0 K AH0 L Z'],
  "cubism": ['K Y UW1 B IH0 Z AH0 M'],
  "cubist": ['K Y UW1 B IH0 S T'],
  "cubit": ['K Y UW1 B IH0 T'],
  "cubs": ['K AH1 B Z'],
  "cubs'": ['K AH1 B Z'],
  "cuccaro": ['K UW0 K AA1 R OW0'],
  "cucchi": ['K UW1 K IY0'],
  "cucchiara": ['K UW0 K IY0 AA1 R AH0'],
  "cucci": ['K UW1 CH IY0'],
  "cuccia": ['K UW1 CH AH0'],
  "cuccio": ['K UW1 CH IY0 OW0'],
  "cuckoo": ['K AH1 K UW2', 'K UW1 K UW2'],
  "cuckoo's": ['K UW1 K UW0 Z'],
  "cuckoos": ['K UW1 K UW0 Z'],
  "cuco": ['K UW1 K OW0'],
  "cucumber": ['K Y UW1 K AH0 M B ER0'],
  "cucumbers": ['K Y UW1 K AH0 M B ER0 Z'],
  "cudahy": ['K AH1 D AH0 HH IY0'],
  "cudd": ['K AH1 D'],
  "cuddeback": ['K AH1 D B AE0 K'],
  "cuddihy": ['K AH1 D IH0 HH IY0'],
  "cuddle": ['K AH1 D AH0 L'],
  "cuddled": ['K AH1 D AH0 L D'],
  "cuddling": ['K AH1 D L IH0 NG'],
  "cuddly": ['K AH1 D L IY0'],
  "cuddy": ['K AH1 D IY0'],
  "cude": ['K Y UW1 D'],
  "cudgel": ['K AH1 JH AH0 L'],
  "cudgels": ['K AH1 JH AH0 L Z'],
  "cudmore": ['K AH1 D M AO0 R'],
  "cudney": ['K AH1 D N IY0'],
  "cudworth": ['K AH1 D W ER2 TH'],
  "cue": ['K Y UW1'],
  "cued": ['K Y UW1 D'],
  "cuellar": ['K Y UW1 L ER0'],
  "cuello": ['K UW0 EH1 L OW0'],
  "cuenca": ['K W EH1 N K AH0'],
  "cuero": ['K W EH1 R OW0'],
  "cuervo": ['K UH1 R V OW0'],
  "cues": ['K Y UW1 Z'],
  "cuesta": ['K W EH1 S T AH0'],
  "cueto": ['K W EH1 T OW0'],
  "cueva": ['K W EH1 V AH0'],
  "cuevas": ['K W EH1 V AA0 Z'],
  "cuff": ['K AH1 F'],
  "cuffe": ['K AH1 F'],
  "cuffed": ['K AH1 F T'],
  "cuffee": ['K AH1 F IY1'],
  "cuffs": ['K AH1 F S'],
  "cugini": ['K UW0 JH IY1 N IY0'],
  "cuhney": ['K UW1 N IY0'],
  "cuidado": ['K W IY0 D AE1 D OW0'],
  "cuing": ['K Y UW1 IH0 NG'],
  "cuisinart": ['K W IY1 S IH0 N ER0 T', 'K W IY1 Z AH0 N AA2 T'],
  "cuisinarts": ['K W IY1 Z AH0 N AA2 R T S'],
  "cuisine": ['K W IH0 Z IY1 N'],
  "cuisines": ['K W IH0 Z IY1 N Z'],
  "cul": ['K AH1 L'],
  "cul-de-sac": ['K AH1 L D IH0 S AE2 K'],
  "culberson": ['K AH1 L B ER0 S AH0 N'],
  "culbert": ['K AH1 L B ER0 T'],
  "culbertson": ['K AH1 L B ER0 T S AH0 N'],
  "culbreath": ['K AH1 L B R EH2 TH'],
  "culbreth": ['K AH1 L B R IH0 TH'],
  "culbro": ['K AH1 L B R OW0'],
  "culex": ['K Y UW1 L EH0 K S'],
  "culhane": ['K AH1 L HH EY2 N'],
  "culinary": ['K Y UW1 L IH0 N EH2 R IY0'],
  "culinova": ['K Y UW2 L IH0 N OW1 V AH0'],
  "culkin": ['K AH1 L K IH0 N'],
  "cull": ['K AH1 L'],
  "cullan": ['K AH1 L AH0 N'],
  "culled": ['K AH1 L D'],
  "cullen": ['K AH1 L AH0 N'],
  "cullens": ['K AH1 L AH0 N Z'],
  "culler": ['K AH1 L ER0'],
  "culler's": ['K AH1 L ER0 Z'],
  "cullers": ['K AH1 L ER0 Z'],
  "cullerton": ['K AH1 L ER0 T AH0 N'],
  "cullerton's": ['K AH1 L ER0 T AH0 N Z'],
  "cullet": ['K AH1 L AH0 T'],
  "culleton": ['K UW1 L IH0 T AA0 N'],
  "culley": ['K AH1 L IY0'],
  "cullifer": ['K AH1 L IH0 F ER0'],
  "culligan": ['K AH1 L IH0 G AH0 N'],
  "cullimore": ['K AH1 L IY0 M AO0 R'],
  "cullin": ['K AH1 L IH0 N'],
  "cullinan": ['K AH1 L IH0 N AH0 N'],
  "cullinane": ['K AH1 L IH0 N EY2 N'],
  "cullinet": ['K AH2 L IH0 N EH1 T'],
  "culling": ['K AH1 L IH0 NG'],
  "cullins": ['K AH1 L IH0 N Z'],
  "cullipher": ['K AH1 L IH0 F ER0'],
  "cullison": ['K AH1 L IH0 S AH0 N'],
  "cullman": ['K AH1 L M AH0 N'],
  "cullom": ['K AH1 L AH0 M'],
  "cullop": ['K AH1 L AH0 P'],
  "cullud": ['K AH1 L AH0 D'],
  "cullum": ['K AH1 L AH0 M'],
  "cullum's": ['K AH1 L AH0 M Z'],
  "cully": ['K AH1 L IY0'],
  "culmer": ['K AH1 L M ER0'],
  "culminate": ['K AH1 L M IH0 N EY2 T'],
  "culminated": ['K AH1 L M AH0 N EY2 T IH0 D'],
  "culminates": ['K AH1 L M IH0 N EY2 T S'],
  "culminating": ['K AH1 L M AH0 N EY2 T IH0 NG'],
  "culmination": ['K AH2 L M AH0 N EY1 SH AH0 N'],
  "culp": ['K AH1 L P'],
  "culpa": ['K AH1 L P AH0'],
  "culpability": ['K AH2 L P AH0 B IH1 L IH0 T IY0'],
  "culpable": ['K AH1 L P AH0 B AH0 L'],
  "culpepper": ['K AH1 L P IH0 P ER0'],
  "culprit": ['K AH1 L P R IH0 T'],
  "culprits": ['K AH1 L P R IH0 T S'],
  "cult": ['K AH1 L T'],
  "cult's": ['K AH1 L T S'],
  "cultic": ['K AH1 L T IH0 K'],
  "cultist": ['K AH1 L T AH0 S T', 'K AH1 L T IH0 S T'],
  "cultists": ['K AH1 L T IH0 S T S'],
  "cultivate": ['K AH1 L T AH0 V EY2 T'],
  "cultivated": ['K AH1 L T AH0 V EY2 T IH0 D'],
  "cultivates": ['K AH1 L T IH0 V EY2 T S'],
  "cultivating": ['K AH1 L T IH0 V EY2 T IH0 NG'],
  "cultivation": ['K AH2 L T IH0 V EY1 SH AH0 N'],
  "culton": ['K AH1 L T AH0 N'],
  "cults": ['K AH1 L T S'],
  "cultural": ['K AH1 L CH ER0 AH0 L'],
  "culturalism": ['K AH1 L CH ER0 AH0 L IH2 Z AH0 M'],
  "culturally": ['K AH1 L CH ER0 AH0 L IY2'],
  "culture": ['K AH1 L CH ER0'],
  "culture's": ['K AH1 L CH ER0 Z'],
  "cultured": ['K AH1 L CH ER0 D'],
  "cultures": ['K AH1 L CH ER0 Z'],
  "culturing": ['K AH1 L CH ER0 IH0 NG'],
  "culvahouse": ['K AH1 L V AH0 HH AW2 S'],
  "culver": ['K AH1 L V ER0'],
  "culver's": ['K AH1 L V ER0 Z'],
  "culverhouse": ['K AH1 L V ER0 HH AW2 S'],
  "culverhouse's": ['K AH1 L V ER0 HH AW2 S IH0 Z'],
  "culvert": ['K AH1 L V ER0 T'],
  "culwell": ['K AH1 L W EH2 L'],
  "cum": ['K AH1 M'],
  "cumbee": ['K AH1 M B IY2'],
  "cumber": ['K AH1 M B ER0'],
  "cumberbatch": ['K AH1 M B ER0 B AE2 CH'],
  "cumberland": ['K AH1 M B ER0 L AH0 N D'],
  "cumberledge": ['K AH1 M B ER0 L EH2 JH'],
  "cumbersome": ['K AH1 M B ER0 S AH0 M'],
  "cumbia": ['K AH1 M B IY0 AH0'],
  "cumbias": ['K AH1 M B IY0 AH0 Z'],
  "cumbie": ['K AH1 M B IY0'],
  "cumbo": ['K AH1 M B OW0'],
  "cumby": ['K AH1 M B IY0'],
  "cumin": ['K Y UW1 M AH0 N'],
  "cumings": ['K UW1 M IH0 NG Z'],
  "cumming": ['K AH1 M IH0 NG'],
  "cummings": ['K AH1 M IH0 NG Z'],
  "cummington": ['K AH1 M IH0 NG T AH0 N'],
  "cummins": ['K AH1 M IH0 N Z'],
  "cummins's": ['K AH1 M IH0 N Z IH0 Z'],
  "cummiskey": ['K AH1 M IH0 S K IY0'],
  "cummock": ['K AH1 M AH0 K'],
  "cumpston": ['K AH1 M P S T AH0 N'],
  "cumpton": ['K AH1 M P T AH0 N'],
  "cumulative": ['K Y UW1 M Y AH0 L AH0 T IH0 V'],
  "cumulatively": ['K Y UW1 M Y AH0 L AH0 T IH2 V L IY0'],
  "cunanan": ['K Y UW0 N AE1 N AH0 N'],
  "cunanan's": ['K Y UW0 N AE1 N AH0 N Z'],
  "cunard": ['K Y UW1 N ER0 D'],
  "cundall": ['K AH1 N D AH0 L'],
  "cundari": ['K UW0 N D AA1 R IY0'],
  "cundiff": ['K AH1 N D IH0 F'],
  "cundill": ['K AH1 N D IH0 L'],
  "cundy": ['K AH1 N D IY0'],
  "cuneiform": ['K Y UW1 N IY0 AH0 F AO2 R M'],
  "cuneo": ['K Y UW1 N IY0 OW0'],
  "cunha": ['K UW1 NG AH0'],
  "cunliffe": ['K AH1 N L IH0 F'],
  "cunnane": ['K AH1 N AH0 N'],
  "cunneen": ['K AH0 N IY1 N'],
  "cunniff": ['K AH1 N IH0 F'],
  "cunnilingus": ['K AH2 N AH0 L IH1 NG G AH0 S'],
  "cunning": ['K AH1 N IH0 NG'],
  "cunningham": ['K AH1 N IH0 NG HH AE2 M'],
  "cunningham's": ['K AH1 N IH0 NG HH AE2 M Z'],
  "cunnington": ['K AH1 N IH0 NG T AH0 N'],
  "cunt": ['K AH1 N T'],
  "cuny": ['K Y UW1 N IY0'],
  "cuoco": ['K W OW1 K OW0'],
  "cuomo": ['K W OW1 M OW0'],
  "cuomo's": ['K W OW1 M OW0 Z'],
  "cuong": ['K W AO1 NG'],
  "cuozzo": ['K W OW1 Z OW0'],
  "cup": ['K AH1 P'],
  "cup's": ['K AH1 P S'],
  "cupboard": ['K AH1 B ER0 D'],
  "cupboards": ['K AH1 B ER0 D Z'],
  "cupcake": ['K AH1 P K EY2 K'],
  "cupcakes": ['K AH1 P K EY2 K S'],
  "cuperose": ['K UW1 P ER0 OW1 Z'],
  "cupertino": ['K UW2 P ER0 T IY1 N OW0'],
  "cupid": ['K Y UW1 P IH0 D'],
  "cupidity": ['K Y UW0 P IH1 D IH0 T IY0'],
  "cupids": ['K Y UW1 P IH0 D Z'],
  "cupit": ['K Y UW1 P IH0 T'],
  "cupo": ['K Y UW1 P OW0'],
  "cupola": ['K AH0 P OW1 L AH0'],
  "cupp": ['K AH1 P'],
  "cuppett": ['K AH1 P IH0 T'],
  "cupples": ['K AH1 P AH0 L Z'],
  "cupps": ['K AH1 P S'],
  "cuppy": ['K AH1 P IY0'],
  "cups": ['K AH1 P S'],
  "cur": ['K ER1'],
  "curable": ['K Y UH1 R AH0 B AH0 L'],
  "curacao": ['K Y UH1 R AH0 S AW2'],
  "curare": ['K Y UW2 R AE1 R EY2'],
  "curari": ['K Y UW2 R AE1 R EY2'],
  "curate": ['K Y UH1 R AH0 T'],
  "curated": ['K Y UH0 R EY1 T IH0 D'],
  "curative": ['K Y UH1 R AH0 T IH0 V'],
  "curatolo": ['K UH0 R AA0 T OW1 L OW0'],
  "curator": ['K Y UH0 R EY1 T ER0', 'K Y UH1 R AH0 T ER0'],
  "curator's": ['K Y UH0 R EY1 T ER0 Z', 'K Y UH1 R AH0 T ER0 Z'],
  "curatorial": ['K Y UH2 R AH0 T AO1 R IY0 AH0 L'],
  "curators": ['K Y UH1 R AH0 T ER0 Z', 'K Y UH0 R EY1 T ER0 Z'],
  "curb": ['K ER1 B'],
  "curbed": ['K ER1 B D'],
  "curbelo": ['K UH0 R B EH1 L OW0'],
  "curbing": ['K ER1 B IH0 NG'],
  "curbow": ['K ER1 B OW0'],
  "curbs": ['K ER1 B Z'],
  "curbside": ['K ER1 B S AY2 D'],
  "curbstone": ['K ER1 B S T OW2 N'],
  "curby": ['K ER1 B IY0'],
  "curci": ['K UH1 R CH IY0'],
  "curcio": ['K UH1 R CH IY0 OW0'],
  "curcuru": ['K UH0 R K UH1 R UW0'],
  "curd": ['K ER1 D'],
  "curds": ['K ER1 D Z'],
  "cure": ['K Y UH1 R'],
  "cured": ['K Y UH1 R D'],
  "cures": ['K Y UH1 R Z'],
  "cureton": ['K Y UH1 R T AH0 N'],
  "curfew": ['K ER1 F Y UW0'],
  "curfews": ['K ER1 F Y UW0 Z'],
  "curfman": ['K ER1 F M AH0 N'],
  "curiale": ['K UH0 R IY0 AA1 L IY0'],
  "curie": ['K Y UH0 R IY1', 'K Y UH1 R IY0'],
  "curiel": ['K Y UW1 R IY0 L'],
  "curing": ['K Y UH1 R IH0 NG'],
  "curington": ['K Y UH1 R IH0 NG T AH0 N'],
  "curio": ['K Y UH1 R IY0 OW0'],
  "curios": ['K Y UH1 R IY0 OW0 Z'],
  "curiosities": ['K Y UH2 R IY0 AA1 S AH0 T IY0 Z'],
  "curiosity": ['K Y UH2 R IY0 AA1 S AH0 T IY0'],
  "curious": ['K Y UH1 R IY0 AH0 S'],
  "curiouser": ['K Y UH1 R IY0 AH0 S ER0'],
  "curiously": ['K Y UH1 R IY0 AH0 S L IY0'],
  "curitiba": ['K Y UH2 IH0 T IY1 B AH0'],
  "curl": ['K ER1 L'],
  "curle": ['K AO1 R AH0 L'],
  "curled": ['K ER1 L D'],
  "curlee": ['K ER1 L IY1'],
  "curler": ['K ER1 L ER0'],
  "curlers": ['K ER1 L ER0 Z'],
  "curless": ['K ER1 L AH0 S'],
  "curlett": ['K ER1 L IH0 T'],
  "curley": ['K ER1 L IY0'],
  "curlicue": ['K ER1 L IH2 K Y UW0'],
  "curlin": ['K ER1 L IH0 N'],
  "curling": ['K ER1 L IH0 NG'],
  "curls": ['K ER1 L Z'],
  "curly": ['K ER1 L IY0'],
  "curmudgeon": ['K ER0 M AH1 JH IH0 N'],
  "curmudgeons": ['K ER0 M AH1 JH IH0 N Z'],
  "curnow": ['K ER1 N OW0'],
  "curnutt": ['K ER1 N AH0 T'],
  "curnutte": ['K ER0 N AH1 T'],
  "curragh": ['K AH1 R AH0', 'K ER1 AH0'],
  "curran": ['K ER1 AH0 N'],
  "curren": ['K ER1 AH0 N'],
  "currence": ['K ER1 AH0 N S'],
  "currencies": ['K ER1 AH0 N S IY0 Z'],
  "currencies'": ['K ER0 EH1 N S IY0 Z'],
  "currency": ['K ER1 AH0 N S IY0'],
  "currency's": ['K ER1 AH0 N S IY0 Z'],
  "currencywatch": ['K ER1 AH0 N S IY0 W AA2 CH'],
  "currens": ['K ER1 AH0 N Z'],
  "current": ['K ER1 AH0 N T', 'K ER1 N T', 'K AA1 R AH0 N T'],
  "current's": ['K ER1 AH0 N T S'],
  "currently": ['K ER1 AH0 N T L IY0'],
  "currents": ['K ER1 AH0 N T S'],
  "curreri": ['K UH0 R EH1 R IY0'],
  "currey": ['K ER1 IY0'],
  "curricula": ['K ER0 IH1 K Y AH0 L AA2'],
  "curricular": ['K ER0 IH1 K Y AH0 L ER2'],
  "curriculum": ['K ER0 IH1 K Y AH0 L AH0 M'],
  "curriculums": ['K ER0 IH1 K Y AH0 L AH0 M Z'],
  "currie": ['K ER1 IY0'],
  "curried": ['K ER1 IY0 D'],
  "currier": ['K ER1 IY0 ER0'],
  "curries": ['K ER1 IY0 Z'],
  "currin": ['K AO1 R IH0 N'],
  "currington": ['K ER1 IH0 NG T AH0 N'],
  "curro": ['K UH1 R OW0'],
  "curry": ['K AH1 R IY0', 'K ER1 IY0'],
  "curry's": ['K AH1 R IY0 Z', 'K ER1 IY0 Z'],
  "currying": ['K ER1 IY0 IH0 NG'],
  "currys": ['K AH1 R IY0 Z', 'K ER1 IY0 Z'],
  "curse": ['K ER1 S'],
  "cursed": ['K ER1 S T'],
  "curses": ['K ER1 S IH0 Z'],
  "cursing": ['K ER1 S IH0 NG'],
  "cursor": ['K ER1 S ER0'],
  "cursory": ['K ER1 S ER0 IY0'],
  "curt": ['K ER1 T'],
  "curt's": ['K ER1 T S'],
  "curtail": ['K ER0 T EY1 L'],
  "curtailed": ['K ER0 T EY1 L D'],
  "curtailing": ['K ER0 T EY1 L IH0 NG'],
  "curtailment": ['K ER0 T EY1 L M AH0 N T'],
  "curtailments": ['K ER0 T EY1 L M AH0 N T S'],
  "curtails": ['K ER0 T EY1 L Z'],
  "curtain": ['K ER1 T AH0 N'],
  "curtains": ['K ER1 T AH0 N Z'],
  "curti": ['K UH1 R T IY0'],
  "curtice": ['K UH1 R T IH0 S'],
  "curtin": ['K ER1 T IH0 N'],
  "curtis": ['K ER1 T AH0 S', 'K ER1 T IH0 S'],
  "curtis'": ['K ER1 T IH0 S'],
  "curtiss": ['K ER1 T IH0 S'],
  "curtly": ['K ER1 T L IY0'],
  "curtner": ['K ER1 T N ER0'],
  "curto": ['K UH1 R T OW0'],
  "curtright": ['K ER1 T R AY2 T'],
  "curts": ['K ER1 T S'],
  "curtsinger": ['K ER1 T S IH0 N JH ER0'],
  "curtsy": ['K ER1 T S IY0'],
  "curvature": ['K ER1 V AH0 CH ER0'],
  "curve": ['K ER1 V'],
  "curved": ['K ER1 V D'],
  "curves": ['K ER1 V Z'],
  "curvin": ['K ER1 V IH0 N'],
  "curving": ['K ER1 V IH0 NG'],
  "curvy": ['K ER1 V IY0'],
  "curzio": ['K ER1 Z IY0 OW0'],
  "cus": ['K AH1 S', 'S IY1 Y UW1 EH1 S'],
  "cusack": ['K Y UW1 Z AH0 K'],
  "cusano": ['K UW0 S AA1 N OW0'],
  "cush": ['K AH1 SH', 'K UH1 SH'],
  "cushey": ['K UH1 SH IY0'],
  "cushing": ['K UH1 SH IH0 NG'],
  "cushion": ['K UH1 SH AH0 N'],
  "cushioned": ['K UH1 SH AH0 N D'],
  "cushioning": ['K UH1 SH AH0 N IH0 NG', 'K UH1 SH N IH0 NG'],
  "cushions": ['K UH1 SH AH0 N Z'],
  "cushitic": ['K AH0 SH IH1 T IH0 K'],
  "cushman": ['K UH1 SH M AH0 N'],
  "cushy": ['K UH1 SH IY0'],
  "cusiana": ['K Y UW2 Z IY0 AE1 N AH0'],
  "cusic": ['K Y UW1 Z IH0 K'],
  "cusick": ['K Y UW1 Z IH0 K'],
  "cusimano": ['K UW0 S IY0 M AA1 N OW0'],
  "cusip": ['K AH1 S IH0 P', 'K Y UW1 S IH0 P'],
  "cusk": ['K AH1 S K'],
  "cusmano": ['K UW0 S M AA1 N OW0'],
  "cuso": ['K Y UW1 S OW0', 'K UW1 S OW0'],
  "cuso's": ['K Y UW1 S OW0 Z', 'K UW1 S OW0 Z'],
  "cusp": ['K AH1 S P'],
  "cuss": ['K AH1 S'],
  "cussed": ['K AH1 S T'],
  "cussing": ['K AH1 S IH0 NG'],
  "cusson": ['K AH1 S AH0 N'],
  "custard": ['K AH1 S T ER0 D'],
  "custer": ['K AH1 S T ER0'],
  "custer's": ['K AH1 S T ER0 Z'],
  "custis": ['K AH1 S T IH0 S'],
  "custodial": ['K AH0 S T OW1 D IY0 AH0 L'],
  "custodian": ['K AH0 S T OW1 D IY0 AH0 N'],
  "custodians": ['K AH0 S T OW1 D IY0 AH0 N Z'],
  "custodio": ['K UW0 S T OW1 D IY0 OW0'],
  "custody": ['K AH1 S T AH0 D IY0'],
  "custom": ['K AH1 S T AH0 M'],
  "customarily": ['K AH2 S T AH0 M EH1 R AH0 L IY0'],
  "customary": ['K AH1 S T AH0 M EH2 R IY0'],
  "customer": ['K AH1 S T AH0 M ER0'],
  "customer's": ['K AH1 S T AH0 M ER0 Z'],
  "customers": ['K AH1 S T AH0 M ER0 Z'],
  "customers'": ['K AH1 S T AH0 M ER0 Z'],
  "customizable": ['K AH1 S T AH0 M AY2 Z AH0 B AH0 L'],
  "customize": ['K AH1 S T AH0 M AY2 Z'],
  "customized": ['K AH1 S T AH0 M AY2 Z D'],
  "customizes": ['K AH1 S T AH0 M AY2 Z IH0 Z'],
  "customizing": ['K AH1 S T AH0 M AY2 Z IH0 NG'],
  "customs": ['K AH1 S T AH0 M Z'],
  "cusumano": ['K UW0 S UW0 M AA1 N OW0'],
  "cut": ['K AH1 T'],
  "cutaia": ['K UW0 T AA1 Y AH0'],
  "cutaneous": ['K Y UW2 T EY1 N IY2 AH0 S'],
  "cutaway": ['K AH1 T AH0 W EY0'],
  "cutback": ['K AH1 T B AE2 K'],
  "cutbacks": ['K AH1 T B AE2 K S'],
  "cutbirth": ['K AH1 T B ER2 TH'],
  "cutchall": ['K AH1 CH AH0 L'],
  "cutcher": ['K AH1 CH ER0'],
  "cutchin": ['K AH1 CH IH0 N'],
  "cutchins": ['K AH1 CH IH0 N Z'],
  "cute": ['K Y UW1 T'],
  "cuteness": ['K Y UW1 T N AH0 S'],
  "cuter": ['K Y UW1 T ER0'],
  "cutesiness": ['K Y UW1 T S IY2 N IH0 S'],
  "cutest": ['K Y UW1 T IH0 S T'],
  "cutesy": ['K Y UW1 T S IY0'],
  "cuthbert": ['K AH1 TH B ER0 T'],
  "cuthbertson": ['K AH1 TH B ER0 T S AH0 N'],
  "cuthrell": ['K AH1 TH R AH0 L'],
  "cuticle": ['K Y UW1 T AH0 K AH0 L', 'K Y UW1 T IH0 K AH0 L'],
  "cutie": ['K Y UW1 T IY0'],
  "cutillo": ['K Y UW2 T IH1 L OW0'],
  "cutlass": ['K AH1 T L AH0 S'],
  "cutler": ['K AH1 T L ER0'],
  "cutler's": ['K AH1 T L ER0 Z'],
  "cutlery": ['K AH1 T L ER0 IY0'],
  "cutlet": ['K AH1 T L AH0 T'],
  "cutlets": ['K AH1 T L AH0 T S'],
  "cutlip": ['K AH1 T L IH0 P'],
  "cutoff": ['K AH1 T AO2 F'],
  "cutoffs": ['K AH1 T AO2 F S'],
  "cutout": ['K AH1 T AW2 T'],
  "cutouts": ['K AH1 T AW2 T S'],
  "cutrale": ['K AH1 T R EY2 L'],
  "cutrell": ['K AH1 T R AH0 L'],
  "cutrer": ['K AH1 T R ER0'],
  "cutright": ['K AH1 T R AY2 T'],
  "cutrona": ['K UW0 T R OW1 N AH0'],
  "cutrone": ['K UW0 T R OW1 N IY0'],
  "cuts": ['K AH1 T S'],
  "cutsforth": ['K AH1 T S F AO2 R TH'],
  "cutshall": ['K AH1 CH AH0 L'],
  "cutshaw": ['K AH1 CH AO2'],
  "cutsinger": ['K AH1 T S IH0 N JH ER0'],
  "cutter": ['K AH1 T ER0'],
  "cutters": ['K AH1 T ER0 Z'],
  "cutthroat": ['K AH1 T TH R OW2 T'],
  "cutting": ['K AH1 T IH0 NG'],
  "cutting-edge": ['K AH1 T IH0 NG EH2 JH'],
  "cuttings": ['K AH1 T IH0 NG Z'],
  "cuttino": ['K UW0 T IY1 N OW0'],
  "cuttlefish": ['K AH1 T AH0 L F IH2 SH'],
  "cutts": ['K AH1 T S'],
  "cutty": ['K AH1 T IY0'],
  "cutugno": ['K Y UW0 T AH1 N Y OW0'],
  "cutworm": ['K AH1 T W ER2 M'],
  "cutworms": ['K AH1 T W ER2 M Z'],
  "cuvelier": ['K Y UW1 V L IY0 ER0'],
  "cuyahoga": ['K AY2 AH0 HH OW1 G AH0'],
  "cuyler": ['K AY1 L ER0'],
  "cuzzort": ['K AH1 Z ER0 T'],
  "cv": ['S IY2 V IY1'],
  "cwiertnia": ['K W IY1 R T N IY0 AH0'],
  "cwik": ['K W IH1 K'],
  "cwikla": ['K W IH1 K L AH0'],
  "cwiklinski": ['K W IH0 K L IH1 N S K IY0'],
  "cwynar": ['K W IH1 N ER0'],
  "cxc": ['S IY1 EH1 K S S IY1', 'S IY1 EH1 K S IY1'],
  "cy": ['S AY1'],
  "cyacq": ['S AY1 AE0 K'],
  "cyan": ['S AY0 AE1 N'],
  "cyanamid": ['S AY0 AE1 N AH0 M IH0 D'],
  "cyanamid's": ['S AY0 AE1 N AH0 M IH0 D Z'],
  "cyanazine": ['S AY1 AH0 N AH0 Z IY2 N'],
  "cyanide": ['S AY1 AH0 N AY2 D', 'S AY1 N AY2 D'],
  "cyanuric": ['S AY0 AE1 N ER0 IH0 K'],
  "cyb": ['S AY1 B', 'S IY1 W AY1 B IY1'],
  "cyber": ['S AY1 B ER0'],
  "cybercash": ['S AY1 B ER0 K AE2 SH'],
  "cyberporn": ['S AY1 B ER0 P AO2 R N'],
  "cybersex": ['S AY1 B ER0 S EH2 K S'],
  "cyberspace": ['S AY1 B ER0 S P EY2 S'],
  "cyberspace's": ['S AY1 B ER0 S P EY2 S IH0 Z'],
  "cybertrend": ['S AY1 B ER0 T R EH0 N D'],
  "cybertrends": ['S AY1 B ER0 T R EH0 N D Z'],
  "cybill": ['S AY1 B IH2 L'],
  "cyborg": ['S AY1 B AO0 R G'],
  "cyborgs": ['S AY1 B AO0 R G Z'],
  "cybulski": ['K IH0 B AH1 L S K IY0'],
  "cycads": ['S AY1 K AE0 D Z'],
  "cycare": ['S AY1 K EH2 R'],
  "cyclades": ['S AY0 K L EY1 D IY0 Z', 'S AY1 K L AE2 D Z'],
  "cyclamen": ['S AY1 K L AH0 M EH2 N'],
  "cycle": ['S AY1 K AH0 L'],
  "cycled": ['S AY1 K AH0 L D'],
  "cycles": ['S AY1 K AH0 L Z'],
  "cyclic": ['S AY1 K L IH0 K'],
  "cyclical": ['S AY1 K L IH0 K AH0 L', 'S IH1 K L IH0 K AH0 L'],
  "cyclicality": ['S IH2 K L IH0 K AE1 L IH0 T IY0'],
  "cyclicals": ['S IH1 K L IH0 K AH0 L Z'],
  "cycling": ['S AY1 K AH0 L IH0 NG', 'S AY1 K L IH0 NG'],
  "cyclist": ['S AY1 K AH0 L IH0 S T', 'S AY1 K L IH0 S T'],
  "cyclists": ['S AY1 K AH0 L IH0 S T S', 'S AY1 K L IH0 S T S', 'S AY1 K AH0 L IH0 S', 'S AY1 K L IH0 S'],
  "cyclohexane": ['S AY2 K L AH0 HH EH1 K S EY0 N'],
  "cyclone": ['S IH0 K L OW1 N'],
  "cyclones": ['S IH0 K L OW1 N Z'],
  "cyclopean": ['S AY2 K L AH0 P IY1 AH0 N'],
  "cyclops": ['S AY1 K L AO2 P S'],
  "cyclops's": ['S AY1 K L AO2 P S IH0 Z'],
  "cyclosporine": ['S IH0 K L AO1 S P ER0 IY2 N'],
  "cyclostome": ['S AY1 K L AH0 S T OW2 M'],
  "cyclostomes": ['S AY1 K L AH0 S T OW2 M Z'],
  "cyclotron": ['S AY1 K L AH0 T R AA0 N'],
  "cycolor": ['S IH1 K AH0 L ER0'],
  "cyd": ['S IH1 D'],
  "cydonia": ['S IH0 D OW1 N IY0 AH0'],
  "cydrome": ['S IH0 D R OW1 M'],
  "cyert": ['S AY1 ER0 T'],
  "cygan": ['S AY1 G AH0 N'],
  "cygne": ['S IH1 G N AH0'],
  "cygnus": ['S IH1 G N AH0 S'],
  "cyhexatin": ['S AY0 HH EH1 K S AH0 T IH0 N'],
  "cylinder": ['S IH1 L AH0 N D ER0', 'S IH1 L IH0 N D ER0'],
  "cylinders": ['S IH1 L AH0 N D ER0 Z'],
  "cylindrical": ['S AH0 L IH1 N D R IH0 K AH0 L', 'S IH0 L IH1 N D R IH0 K AH0 L'],
  "cymbal": ['S IH1 M B AH0 L'],
  "cymbals": ['S IH1 M B AH0 L Z'],
  "cymrot": ['S IH1 M R AH0 T'],
  "cynara": ['K IH0 N AA1 R AH0'],
  "cyndi": ['S IH1 N D IY0'],
  "cynic": ['S IH1 N IH0 K'],
  "cynical": ['S IH1 N IH0 K AH0 L'],
  "cynically": ['S IH1 N IH0 K AH0 L IY0', 'S IH1 N IH0 K L IY0'],
  "cynicism": ['S IH1 N IH0 S IH2 Z AH0 M'],
  "cynics": ['S IH1 N IH0 K S'],
  "cynosure": ['S AY1 N AO0 S ER2'],
  "cynric": ['S IH1 N R IH0 K'],
  "cynth": ['S IH1 N TH'],
  "cynthia": ['S IH1 N TH IY0 AH0'],
  "cynthia's": ['S IH1 N TH IY0 AH0 Z'],
  "cynthie": ['S IH1 N TH IY0'],
  "cynwyd": ['S IH1 N W IH0 D'],
  "cypert": ['S AY1 P ER0 T'],
  "cypher": ['S AY1 F ER0'],
  "cyphers": ['S AY1 F ER0 Z'],
  "cyphert": ['S AY1 F ER0 T'],
  "cypress": ['S AY1 P R AH0 S', 'S AY1 P R IH0 S'],
  "cypress's": ['S AY1 P R AH0 S IH0 Z'],
  "cyprian": ['S IH1 P R IY0 AH0 N'],
  "cypriot": ['S IH1 P R IY0 AH0 T', 'S IH1 P R IY0 AA2 T'],
  "cypriots": ['S IH1 P R IY0 AH0 T S', 'S IH1 P R IY0 AA2 T S'],
  "cypris": ['S AY1 P R IH0 S'],
  "cyprus": ['S AY1 P R AH0 S'],
  "cyr": ['S IH1 R'],
  "cyran": ['K IH1 R AH0 N'],
  "cyrano": ['K IY0 R AA1 N OW0', 'S IH1 R AH0 N OW2'],
  "cyrena": ['K IH0 R IY1 N AH0'],
  "cyrenius": ['S AH0 R IY1 N IY0 AH0 S'],
  "cyril": ['S IH1 R AH0 L'],
  "cyrilla": ['S IH0 R IH1 L AH0'],
  "cyrillic": ['S ER0 IH1 L IH0 K'],
  "cyrix": ['S AY1 R IH2 K S', 'S IH1 R IH0 K S'],
  "cyrix's": ['S AY1 R IH2 K S IH0 Z', 'S IH1 R IH0 K S IH0 Z'],
  "cyrofin": ['S AY1 R AH0 F IH0 N'],
  "cyrus": ['S AY1 R AH0 S'],
  "cyst": ['S IH1 S T'],
  "cystic": ['S IH1 S T IH0 K'],
  "cysts": ['S IH1 S T S'],
  "cytherea": ['S IH2 TH ER0 IY1 AH0'],
  "cytogen": ['S AY1 T OW0 JH EH0 N'],
  "cytology": ['S AY0 T AA1 L AH0 JH IY0'],
  "cytomegalovirus": ['S AY2 T AH0 M EH2 G AH0 L OW0 V AY1 R AH0 S'],
  "cytoplasm": ['S AY1 T AH0 P L AE2 Z AH0 M'],
  "cytoplasmic": ['S AY2 T AH0 P L AE1 Z M IH0 K'],
  "cytotech": ['S AY1 T OW0 T EH2 K'],
  "cytotechs": ['S AY1 T OW0 T EH2 K S'],
  "cywinski": ['K IH0 V IH1 N S K IY0'],
  "czaja": ['CH AY1 AH0'],
  "czajka": ['CH AY1 K AH0'],
  "czajkowski": ['CH AY0 K AO1 F S K IY0'],
  "czapla": ['CH AA1 P L AH0'],
  "czaplewski": ['CH AH0 P L EH1 F S K IY0'],
  "czaplicki": ['CH AH0 P L IH1 T S K IY0'],
  "czar": ['Z AA1 R'],
  "czar's": ['Z AA1 R Z'],
  "czarist": ['Z AA1 R IH0 S T'],
  "czarnecki": ['CH ER0 N EH1 T S K IY0'],
  "czarnik": ['CH AA1 R N IH0 K'],
  "czarny": ['CH AA1 R N IY0'],
  "czars": ['Z AA1 R Z'],
  "czartoryski": ['S AA2 R T AO0 R IY1 S K IY0'],
  "czech": ['CH EH1 K'],
  "czechoslovak": ['CH EH2 K AH0 S L OW1 V AA0 K'],
  "czechoslovakia": ['CH EH2 K AH0 S L OW0 V AA1 K IY0 AH0'],
  "czechoslovakia's": ['CH EH2 K AH0 S L OW0 V AA1 K IY0 AH0 Z'],
  "czechoslovakian": ['CH EH2 CH AH0 S L OW0 V AA1 K IY0 AH0 N'],
  "czechoslovaks": ['CH EH2 K AH0 S L OW1 V AA0 K S'],
  "czechowski": ['CH IH0 HH AO1 F S K IY0'],
  "czechs": ['CH EH1 K S'],
  "czekajewski": ['CH EH2 K AH0 Y EH1 W S K IY0'],
  "czepiel": ['CH EH1 P IY0 L'],
  "czerniak": ['CH ER1 N IY0 AE0 K'],
  "czerny": ['CH ER1 N IY0'],
  "czerwinski": ['CH ER0 V IH1 N S K IY0'],
  "czerwonka": ['CH ER0 V AA1 NG K AH0'],
  "czeslaw": ['CH EH0 S L AO1'],
  "czyz": ['CH IH1 Z'],
  "czyzewski": ['CH IH0 Z EH1 F S K IY0'],
  "d": ['D IY1'],
  "d'affaires": ['D AH0 F EH1 R Z'],
  "d'agostino": ['D AA2 G AH0 S T IY1 N OW0'],
  "d'agostino's": ['D AA2 G AH0 S T IY1 N OW0 Z'],
  "d'alene": ['D AH0 L IY1 N'],
  "d'alene's": ['D AH0 L IY1 N Z'],
  "d'alessandra": ['D AE2 L EH0 S AE1 N D R AA0'],
  "d'alessandrini": ['D AA2 L EH2 S AA1 N D R IY1 N IY2'],
  "d'alessandro": ['D AA0 L EY0 Z AA1 N D R OW0', 'D AA0 L EH0 S AE1 N D R OW0'],
  "d'alessandro's": ['D AA2 L EH0 S AE1 N D R OW0 Z'],
  "d'alessi": ['D AH0 L EH1 S IY0', 'D AH0 L EY1 S IY0'],
  "d'allest": ['D AE0 L EH1 S T'],
  "d'amato": ['D AH0 M AA1 T OW0'],
  "d'amato's": ['D AH0 M AA1 T OW0 Z'],
  "d'america": ['D AH0 M EH1 R IH0 K AH0'],
  "d'amico": ['D AE2 M IY1 K OW2'],
  "d'amore": ['D IY2 AH0 M AO1 R EY0'],
  "d'amore's": ['D IY2 AH0 M AO1 R EY0 Z'],
  "d'andrea": ['D AE1 N D R IY2 AH0'],
  "d'angelo": ['D IY0 AE1 N JH IH0 L OW0'],
  "d'angelo's": ['D IY0 AE1 N JH IH0 L OW0 Z'],
  "d'arcy": ['D AA1 R S IY2'],
  "d'artagnan": ['D AH0 R T AE1 NG Y AH0 N'],
  "d'aubuisson": ['D AO1 B W IY2 S AA2 N'],
  "d'aviation": ['D EY2 V IY0 EY1 SH AH0 N'],
  "d'electricite": ['D AH0 L EH2 K T R IH1 S AH0 T EY2', 'D AH0 L EH2 K T R IH1 S IH2 T EY2'],
  "d'escoto": ['D EH0 S K OW1 T OW0'],
  "d'estaing": ['D AH0 S T EY1 NG'],
  "d'etat": ['D EH1 T AE2 T', 'D EY2 T AA1'],
  "d'etats": ['D EY2 T AA1 Z'],
  "d'etre": ['D EH1 T R IY0'],
  "d'etude": ['D EH1 T UW1 D'],
  "d'genetta": ['D IY0 JH AH0 N EH1 T AH0'],
  "d'ivoire": ['D IY0 V W AA1 R'],
  "d'oeuvre": ['D ER1 V'],
  "d'oeuvres": ['D ER1 V Z'],
  "d'olier": ['D AH0 L IY1 ER0'],
  "d'or": ['D AO1 R'],
  "d'orsay": ['D AO2 R S EY1'],
  "d's": ['D IY1 Z'],
  "d'souza": ['D IH0 S UW1 Z AH0', 'D IY0 S UW1 Z AH0'],
  "d.": ['D IY1'],
  "d.'s": ['D IY1 Z'],
  "d.c.": ['D IY2 S IY1'],
  "d.s": ['D IY1 Z'],
  "da": ['D AA1', 'D IY1 EY1'],
  "da's": ['D IY1 EY1 Z'],
  "daane": ['D AA1 N'],
  "daarmstadt": ['D AA1 R M S T AA0 D'],
  "daas": ['D AA1 S'],
  "dab": ['D AE1 B'],
  "dabah": ['D AE1 B AH0', 'D AH0 B AA1'],
  "dabber": ['D AE1 B ER0'],
  "dabbing": ['D AE1 B IH0 NG'],
  "dabble": ['D AE1 B AH0 L'],
  "dabbled": ['D AE1 B AH0 L D'],
  "dabbles": ['D AE1 B AH0 L Z'],
  "dabbling": ['D AE1 B AH0 L IH0 NG', 'D AE1 B L IH0 NG'],
  "dabbs": ['D AE1 B Z'],
  "dabchick": ['D AE1 B CH IH0 K'],
  "dabhol": ['D AE1 B OW0 L'],
  "dabkowski": ['D AH0 B K AO1 F S K IY0'],
  "dabney": ['D AE1 B N IY0'],
  "dabrowski": ['D AH0 B R AO1 F S K IY0'],
  "dac": ['D AE1 K'],
  "dace": ['D EY1 S'],
  "dacey": ['D EY1 S IY0'],
  "dacha": ['D AA1 CH AH0'],
  "dachau": ['D AE1 K AW0'],
  "dachshund": ['D AA1 K S HH UH2 N D'],
  "dachshunds": ['D AA1 K S HH UH2 N T S'],
  "dacia": ['D EY1 SH IY0 AH0'],
  "dack": ['D AE1 K'],
  "dacosta": ['D AA0 K OW1 S T AH0'],
  "dacquisto": ['D AE1 K W IH0 S T OW0'],
  "dacron": ['D AE1 K R AA2 N'],
  "dacruz": ['D AA1 K R UW0 Z'],
  "dacs": ['D AE1 K S'],
  "dactyl": ['D AE1 K T IH0 L'],
  "dacunha": ['D AH0 K AH1 N HH AH0'],
  "dacus": ['D AE1 K IH0 S'],
  "dacy": ['D EY1 S IY0'],
  "dad": ['D AE1 D'],
  "dad's": ['D AE1 D Z'],
  "dada": ['D AA1 D AA2'],
  "dadamo": ['D AA0 D AA1 M OW0'],
  "daddario": ['D AA0 D AA1 R IY0 OW0'],
  "daddies": ['D AE1 D IY0 Z'],
  "daddona": ['D AA0 D OW1 N AH0'],
  "daddy": ['D AE1 D IY0'],
  "daddy's": ['D AE1 D IY0 Z'],
  "daddy-o": ['D AE1 D IY0 OW0'],
  "daddy-o's": ['D AE1 D IY0 OW0 Z'],
  "dade": ['D EY1 D'],
  "dadeland": ['D EY1 D L AH0 N D'],
  "dadfar": ['D AE1 D F AA2 R'],
  "dadisman": ['D AE1 D IH0 S M AH0 N'],
  "dado": ['D EY1 D OW2'],
  "dads": ['D AE1 D Z'],
  "dady": ['D EY1 D IY0'],
  "dae": ['D EY1'],
  "daedalus": ['D EH1 D AH0 L AH0 S', 'D EY2 D AE1 L AH0 S'],
  "daelim": ['D EY2 L IY1 M'],
  "daemon": ['D IY1 M AH0 N', 'D EY1 M AH0 N'],
  "daenzer": ['D EH1 N Z ER0'],
  "daewoo": ['D EY1 W UW2'],
  "daffern": ['D AE1 F ER0 N'],
  "daffin": ['D AE1 F IH0 N'],
  "daffodil": ['D AE1 F AH0 D IH2 L'],
  "daffodils": ['D AE1 F AH0 D IH2 L Z'],
  "daffron": ['D AE1 F R AH0 N'],
  "daffy": ['D AE1 F IY0'],
  "daffynition": ['D AE2 F IY0 N IH1 SH AH0 N'],
  "dafna": ['D AE1 F N AH0'],
  "dafoe": ['D AE1 F OW0'],
  "dafsa": ['D AE1 F S AH0'],
  "daft": ['D AE1 F T'],
  "dag": ['D AE1 G'],
  "dagan": ['D EY1 G AH0 N'],
  "dagata": ['D AA0 G AA1 T AH0'],
  "dagen": ['D AE1 G AH0 N'],
  "dagenais": ['D AE1 ZH IH0 N EY0'],
  "dagenham": ['D AE1 G AH0 N HH AE2 M'],
  "dagenhart": ['D AE1 G AH0 N HH AA2 R T'],
  "dager": ['D EY1 G ER0'],
  "dagestan": ['D AE1 G EH0 S T AE2 N'],
  "dagg": ['D AE1 G'],
  "dagger": ['D AE1 G ER0'],
  "daggers": ['D AE1 G ER0 Z'],
  "daggett": ['D AE1 G IH0 T'],
  "daggs": ['D AE1 G Z'],
  "daggy": ['D AE1 G IY0'],
  "dagle": ['D EY1 G AH0 L'],
  "dagley": ['D AE1 G L IY0'],
  "dagmar": ['D AE1 G M AA2 R'],
  "dagon": ['D EY1 G AH0 N'],
  "dagostino": ['D AA0 G OW0 S T IY1 N OW0'],
  "dague": ['D AA1 G'],
  "dagwood": ['D AE1 G W UH2 D'],
  "dah": ['D AH1'],
  "daher": ['D AA1 ER0'],
  "dahill": ['D AA1 HH IH0 L'],
  "dahl": ['D AA1 L'],
  "dahl's": ['D AA1 L Z'],
  "dahlberg": ['D AA1 L B ER0 G'],
  "dahle": ['D AA1 AH0 L'],
  "dahlem": ['D AA1 L IH0 M'],
  "dahlen": ['D AA1 L AH0 N'],
  "dahler": ['D AA1 L ER0'],
  "dahlgren": ['D AE1 L G R IH0 N'],
  "dahlheimer": ['D AA1 L HH AY2 M ER0'],
  "dahlia": ['D AE1 L Y AH0'],
  "dahlin": ['D AA1 L IH0 N'],
  "dahlke": ['D AA1 L K'],
  "dahlman": ['D AA1 L M AH0 N'],
  "dahlquist": ['D AA1 L K W IH2 S T'],
  "dahlstrom": ['D AA1 L S T R AH0 M'],
  "dahm": ['D AE1 M'],
  "dahman": ['D AA1 M AH0 N'],
  "dahmen": ['D AA1 M EH0 N'],
  "dahmer": ['D AA1 M ER0'],
  "dahmer's": ['D AA1 M ER0 Z'],
  "dahms": ['D AA1 M Z'],
  "dahn": ['D AE1 N', 'D AA1 N'],
  "dahnke": ['D AE1 NG K'],
  "dahrain": ['D AH0 R EY1 N'],
  "dai": ['D AY1'],
  "daido": ['D EY1 D OW0'],
  "daidone": ['D EY1 D OW2 N'],
  "daiei": ['D AY1 EY2'],
  "daigle": ['D EY1 G AH0 L'],
  "daigler": ['D EY1 G L ER0'],
  "daignault": ['D EH0 G N OW1'],
  "daigneault": ['D EH0 G N OW1'],
  "daigre": ['D EY1 G ER0'],
  "daigrepont": ['D EY1 G R IH0 P AA0 N T'],
  "daihatsu": ['D AY2 HH AE1 T S UW0'],
  "daiichi": ['D AY2 IY1 CH IY0'],
  "daikin": ['D EY1 K IH0 N'],
  "daikon": ['D AY1 K AO2 N'],
  "dail": ['D EY1 L', 'D OY1 L'],
  "daile": ['D EY1 L'],
  "dailey": ['D EY1 L IY0'],
  "dailies": ['D EY1 L IY0 Z'],
  "daily": ['D EY1 L IY0'],
  "daimler": ['D EY1 M L ER0', 'D EH1 M L ER0'],
  "daimler's": ['D EY1 M L ER0 Z'],
  "daimones": ['D EY1 M OW2 N Z'],
  "dain": ['D EY1 N'],
  "daines": ['D EY1 N Z'],
  "daini": ['D EY1 N IY0'],
  "dainippon": ['D EY2 N IH0 P AA1 N'],
  "dains": ['D EY1 N Z'],
  "daintily": ['D EY1 N T AH0 L IY2'],
  "dainty": ['D EY1 N T IY0'],
  "daiquiri": ['D AE1 K ER0 IY0'],
  "dairies": ['D EH1 R IY0 Z'],
  "dairy": ['D EH1 R IY0'],
  "dairying": ['D EH1 R IY0 IH0 NG'],
  "dairymen": ['D EY1 R IY0 M AH0 N'],
  "dais": ['D EY1 Z'],
  "daise": ['D EY1 Z'],
  "daisey": ['D EY1 S IY0'],
  "daishowa": ['D EY2 SH AW1 AH0'],
  "daisies": ['D EY1 Z IY0 Z'],
  "daisy": ['D EY1 Z IY0'],
  "daisy's": ['D EY1 Z IY0 Z'],
  "daiwa": ['D EY1 W AH0'],
  "daiwa's": ['D EY1 W AH0 Z'],
  "dajun": ['D EY1 JH AH0 N'],
  "dak": ['D AE1 K', 'D IY1 EY1 K EY1'],
  "dakar": ['D AA0 K AA1 R'],
  "dake": ['D EY1 K'],
  "dakin": ['D EY1 K IH0 N'],
  "dakota": ['D AH0 K OW1 T AH0'],
  "dakota's": ['D AH0 K OW1 T AH0 Z'],
  "dakotan": ['D AH0 K OW1 T AH0 N'],
  "dakotans": ['D AH0 K OW1 T AH0 N Z'],
  "dakotas": ['D AH0 K OW1 T AH0 Z'],
  "dal": ['D AE1 L'],
  "dalafield": ['D AE1 L AH0 F IY2 L D'],
  "dalai": ['D AE1 L EY2', 'D AO1 L AY2', 'D AA1 L IY2'],
  "dalal": ['D EY1 L AH0 L'],
  "dalbar": ['D AE1 L B AA0 R'],
  "dalbec": ['D AE1 L B IH0 K'],
  "dalbert": ['D AE1 L B ER0 T'],
  "dalbey": ['D AE1 L B IY0'],
  "dalby": ['D AO1 L B IY0'],
  "dale": ['D EY1 L'],
  "dale's": ['D EY1 L Z'],
  "daleiden": ['D AE1 L AY0 D AH0 N'],
  "dalen": ['D AE1 L AH0 N'],
  "daleo": ['D AA1 L IY0 OW0'],
  "dales": ['D EY1 L Z'],
  "dalesandro": ['D AA0 L EH0 S AA1 N D R OW0'],
  "dalesio": ['D AH0 L IY1 S IY0 OW0'],
  "dalessandro": ['D AA0 L EH0 S AA1 N D R OW0'],
  "dalessio": ['D AH0 L EH1 S IY0 OW0'],
  "daleth": ['D AA1 L EH2 TH'],
  "daley": ['D EY1 L IY0'],
  "daley's": ['D EY1 L IY0 Z'],
  "dalfen": ['D AE1 L F AH0 N'],
  "dalfonso": ['D AE2 L F AA1 N S OW0'],
  "dalfort": ['D AO1 L F ER0 T'],
  "dalgety": ['D AE2 L G EH1 T IY0'],
  "dalgleish": ['D AE1 L G AH0 L IH0 SH', 'D AE1 L G L IH2 SH'],
  "dalhouse": ['D AO1 L HH AW2 S'],
  "dali": ['D AA1 L IY0'],
  "dalia": ['D AA1 L Y AH0'],
  "dalian": ['D EY1 L IY0 AH0 N'],
  "daliberti": ['D AE0 L AH0 B EH1 R T IY0'],
  "dalila": ['D AH0 L AY1 L AH0'],
  "dalis": ['D AE1 L IH0 S'],
  "dalke": ['D EY1 L K'],
  "dalkon": ['D AE1 L K AH0 N'],
  "dall": ['D AO1 L'],
  "dalla": ['D AE1 L AH0'],
  "dallaire": ['D AA1 L EH0 R'],
  "dallara": ['D AE2 L AA1 R AH0'],
  "dallas": ['D AE1 L AH0 S'],
  "dallas'": ['D AE1 L AH0 S'],
  "dallas's": ['D AE1 L AH0 S IH0 Z'],
  "dalley": ['D AE1 L IY0'],
  "dallhold": ['D AO1 L HH OW2 L D'],
  "dalliance": ['D AE1 L IY0 AH0 N S'],
  "dallied": ['D AE1 L IY0 D'],
  "dallman": ['D AO1 L M AH0 N'],
  "dallmann": ['D AO1 L M AH0 N'],
  "dally": ['D AE1 L IY0'],
  "dalma": ['D AA1 L M AH0'],
  "dalman": ['D AE1 L M AH0 N'],
  "dalmatian": ['D AE0 L M EY1 SH AH0 N'],
  "dalmatians": ['D AE0 L M EY1 SH AH0 N Z'],
  "dalmo": ['D AO1 L M OW0'],
  "dalo": ['D AA1 L OW0'],
  "daloia": ['D AA0 L OW1 Y AH0'],
  "daloisio": ['D AA0 L OY1 S IY0 OW0'],
  "dalonzo": ['D AH0 L AA1 N Z OW0'],
  "dalpe": ['D EY1 L P'],
  "dalpiaz": ['D AA0 L P IY1 AA0 Z'],
  "dalporto": ['D AA0 L P AO1 R T OW0'],
  "dalrymple": ['D AE1 L R IH0 M P AH0 L'],
  "dalto": ['D AA1 L T OW0'],
  "dalton": ['D AO1 L T AH0 N'],
  "dalton's": ['D AO1 L T AH0 N Z'],
  "daltons": ['D AO1 L T AH0 N Z'],
  "daly": ['D EY1 L IY0'],
  "dalzell": ['D AE1 L Z AH0 L'],
  "dalziel": ['D AE1 L Z IY2 L'],
  "dam": ['D AE1 M'],
  "dam's": ['D AE1 M Z'],
  "dama": ['D AA1 M AH0'],
  "damaclean": ['D AE1 M AH0 K L IY0 N'],
  "damage": ['D AE1 M AH0 JH', 'D AE1 M IH0 JH'],
  "damaged": ['D AE1 M AH0 JH D', 'D AE1 M IH0 JH D'],
  "damages": ['D AE1 M AH0 JH AH0 Z', 'D AE1 M IH0 JH IH0 Z'],
  "damaging": ['D AE1 M IH0 JH IH0 NG'],
  "daman": ['D EY1 M AH0 N'],
  "damaris": ['D AH0 M AA1 R AH0 S'],
  "damas": ['D AA1 M AH0 Z'],
  "damascus": ['D AH0 M AE1 S K AH0 S'],
  "damascus's": ['D AH0 M AE1 S K AH0 S IH0 Z'],
  "damask": ['D AE1 M AH0 S K'],
  "damasks": ['D AE1 M AH0 S K S'],
  "damato": ['D AA0 M AA1 T OW0'],
  "dambach": ['D AE1 M B AA2 K'],
  "dambacher": ['D AE1 M B AA2 K ER0'],
  "dambra": ['D AE1 M B R AH0'],
  "dambrosia": ['D AA0 M B R OW1 S IY0 AH0'],
  "dambrosio": ['D AE2 M B R OW1 S IY0 OW0'],
  "dame": ['D EY1 M'],
  "dame's": ['D EY1 M Z'],
  "damelio": ['D AH0 M IY1 L IY0 OW0'],
  "damer": ['D EY1 M ER0'],
  "damerlin": ['D EY1 M ER0 L IH0 N', 'D AE1 M ER0 L IH0 N'],
  "dameron": ['D AA0 M EH0 R AO1 N'],
  "dames": ['D EY1 M Z'],
  "damewood": ['D EY1 M W UH2 D'],
  "damgard": ['D AE1 M G AA2 R D'],
  "damian": ['D EY1 M IY0 AH0 N'],
  "damiani": ['D AA0 M IY0 AA1 N IY0'],
  "damiano": ['D AA0 M IY0 AA1 N OW0'],
  "damianos": ['D AA0 M IY2 AA1 N OW0 S'],
  "damico": ['D AA0 M IY1 K OW0'],
  "damien": ['D EY1 M IY0 AH0 N'],
  "damien's": ['D EY1 M IY0 AH0 N Z'],
  "damietta": ['D AE2 M IY0 EH1 T AH0'],
  "daminozide": ['D AE2 M IH1 N AH0 Z AY2 D'],
  "damita": ['D AA0 M IY1 T AH0'],
  "damitz": ['D AE1 M IH0 T S'],
  "damm": ['D AE1 M'],
  "dammam": ['D AE1 M AH0 M'],
  "damman": ['D AE1 M AH0 N'],
  "dammann": ['D AE1 M AH0 N'],
  "damme": ['D AE1 M', 'D EY1 M'],
  "dammed": ['D AE1 M D'],
  "dammen": ['D AE1 M AH0 N'],
  "dammer": ['D AE1 M ER0'],
  "dammerman": ['D AE1 M ER0 M AH0 N'],
  "damming": ['D AE1 M IH0 NG'],
  "dammit": ['D AE1 M IH0 T'],
  "damn": ['D AE1 M'],
  "damnation": ['D AE0 M N EY1 SH AH0 N'],
  "damned": ['D AE1 M D'],
  "damning": ['D AE1 M IH0 NG'],
  "damns": ['D AE1 M Z'],
  "damocles": ['D AE1 M AH0 K L IY2 Z'],
  "damon": ['D EY1 M AH0 N'],
  "damon's": ['D EY1 M AH0 N Z'],
  "damone": ['D AH0 M OW1 N'],
  "damons": ['D EY1 M AH0 N Z'],
  "damoose": ['D AH0 M UW1 S'],
  "damore": ['D EY1 M AO2 R'],
  "damour": ['D AH0 M UH1 R'],
  "damp": ['D AE1 M P'],
  "damped": ['D AE1 M P T'],
  "dampen": ['D AE1 M P AH0 N'],
  "dampened": ['D AE1 M P AH0 N D'],
  "dampening": ['D AE1 M P AH0 N IH0 NG', 'D AE1 M P N IH0 NG'],
  "damper": ['D AE1 M P ER0'],
  "dampers": ['D AE1 M P ER0 Z'],
  "dampier": ['D AE1 M P IY0 ER0'],
  "damping": ['D AE1 M P IH0 NG'],
  "dampness": ['D AE1 M P N IH0 S'],
  "damps": ['D AE1 M P S'],
  "damron": ['D AE1 M R AH0 N'],
  "damrow": ['D AE1 M R OW2'],
  "dams": ['D AE1 M Z'],
  "damsel": ['D AE1 M Z AH0 L'],
  "damson": ['D AE1 M S AH0 N'],
  "damuth": ['D AE1 M UW0 TH'],
  "dan": ['D AE1 N'],
  "dan's": ['D AE1 N Z'],
  "dana": ['D EY1 N AH0'],
  "dana's": ['D EY1 N AH0 Z'],
  "danaher": ['D AE1 N AH0 HH ER0'],
  "danahy": ['D AE1 N AH0 HH IY0'],
  "danbury": ['D AE1 N B ER0 IY0'],
  "danby": ['D AE1 N B IY0'],
  "danca": ['D AA1 NG K AH0'],
  "dancanet": ['D AE2 NG K AH0 N EH1 T'],
  "dance": ['D AE1 N S'],
  "danced": ['D AE1 N S T'],
  "dancer": ['D AE1 N S ER0'],
  "dancer's": ['D AE1 N S ER0 Z'],
  "dancers": ['D AE1 N S ER0 Z'],
  "dancers'": ['D AE1 N S ER0 Z'],
  "dances": ['D AE1 N S AH0 Z', 'D AE1 N S IH0 Z'],
  "dancey": ['D AE1 N S IY0'],
  "dancin'": ['D AE1 N S IH0 N'],
  "dancing": ['D AE1 N S IH0 NG'],
  "dancsak": ['D AE1 N K S AE0 K'],
  "dancy": ['D AE1 N S IY0'],
  "dandelion": ['D AE1 N D AH0 L AY2 AH0 N'],
  "dandelions": ['D AE1 N D AH0 L AY2 AH0 N Z'],
  "dandeneau": ['D AE1 N D IH0 N OW0'],
  "dando": ['D AE1 N D OW0'],
  "dandrea": ['D AE1 N D R IY0 AH0'],
  "dandridge": ['D AE1 N D R IH0 JH'],
  "dandruff": ['D AE1 N D R AH0 F'],
  "dandurand": ['D AE1 N D ER0 AH0 N D'],
  "dandy": ['D AE1 N D IY0'],
  "dandyism": ['D AE1 N D IY0 IH2 Z AH0 M'],
  "dane": ['D EY1 N'],
  "dane's": ['D EY1 N Z'],
  "daneel": ['D AH0 N IY1 L'],
  "danek": ['D AE1 N IH0 K'],
  "danell": ['D AH0 N EH1 L'],
  "danella": ['D AH0 N EH1 L AH0'],
  "danelle": ['D AH0 N EH1 L'],
  "daner": ['D EY1 N ER0'],
  "danes": ['D EY1 N Z'],
  "danese": ['D AA0 N EY1 Z IY0'],
  "daney": ['D EY1 N IY0'],
  "danford": ['D AE1 N F ER0 D'],
  "danforth": ['D AE1 N F ER0 TH'],
  "dang": ['D AE1 NG'],
  "dangel": ['D EY1 NG G AH0 L'],
  "dangelo": ['D AE1 N JH AH0 L OW0'],
  "danger": ['D EY1 N JH ER0'],
  "dangerfield": ['D EY1 N JH ER0 F IY2 L D'],
  "dangerous": ['D EY1 N JH ER0 AH0 S'],
  "dangerously": ['D EY1 N JH ER0 AH0 S L IY0'],
  "dangerousness": ['D EY1 N JH ER0 AH0 S N AH0 S'],
  "dangers": ['D EY1 N JH ER0 Z'],
  "dangews": ['D EY1 N JH UW0 Z'],
  "danglar": ['D AH0 NG L AA1 R'],
  "danglars": ['D AH0 NG L AA1 R Z'],
  "dangle": ['D AE1 NG G AH0 L'],
  "dangled": ['D AE1 NG G AH0 L D'],
  "dangler": ['D AE1 NG G AH0 L ER0', 'D AE1 NG G L ER0'],
  "dangles": ['D AE1 NG G AH0 L Z'],
  "dangling": ['D AE1 NG G AH0 L IH0 NG', 'D AE1 NG G L IH0 NG'],
  "dango": ['D AE1 NG G OW0'],
  "dani": ['D AA1 N IY0'],
  "dania": ['D EY1 N Y AH0'],
  "danica": ['D AE1 N IH0 K AH0'],
  "daniel": ['D AE1 N Y AH0 L'],
  "daniel's": ['D AE1 N Y AH0 L Z'],
  "daniela": ['D AE0 N Y EH1 L AH0'],
  "daniele": ['D AE0 N Y EH1 L'],
  "daniell": ['D AE1 N IY0 L'],
  "daniella": ['D AE2 N Y EH1 L AH0'],
  "danielle": ['D AE2 N IY0 EH1 L', 'D AE2 N Y EH1 L'],
  "daniello": ['D AA0 N IY0 EH1 L OW0'],
  "daniels": ['D AE1 N Y AH0 L Z'],
  "daniels's": ['D AE1 N Y AH0 L Z IH0 Z', 'D AE2 N Y EH1 L Z IH0 Z'],
  "danielsen": ['D AE1 N Y AH0 L S AH0 N'],
  "danielski": ['D AH0 N IY1 L S K IY0'],
  "danielson": ['D AE1 N Y AH0 L S AH0 N'],
  "daniloff": ['D AE1 N AH0 L AO0 F'],
  "danis": ['D AA1 N IY0 Z'],
  "danish": ['D EY1 N IH0 SH'],
  "danju": ['D AE1 N JH UW0'],
  "danju's": ['D AE1 N JH UW0 Z'],
  "danjub": ['D AE1 N JH UW0 B'],
  "danjub's": ['D AE1 N JH UW0 B Z'],
  "danjube": ['D AE1 N JH UW0 B'],
  "danjube's": ['D AE1 N JH UW0 B Z'],
  "danjus": ['D AE1 N JH AH0 S', 'D AE1 N JH UW0 Z'],
  "dank": ['D AE1 NG K'],
  "danker": ['D AE1 NG K ER0'],
  "dankert": ['D AE1 NG K ER0 T'],
  "dankner": ['D AE1 NG K N ER0'],
  "danko": ['D AE1 NG K OW0'],
  "danks": ['D AE1 NG K S'],
  "danley": ['D AE1 N L IY0'],
  "danly": ['D AE1 N L IY0'],
  "dann": ['D AE1 N'],
  "danna": ['D AE1 N AH0'],
  "dannelly": ['D AE1 N AH0 L IY0'],
  "dannels": ['D AE1 N AH0 L Z'],
  "dannemeyer": ['D AE1 N AH0 M AY2 R', 'D AE1 N M AY2 R'],
  "dannemiller": ['D AE1 N AH0 M IH2 L ER0', 'D AE1 N M IH2 L ER0'],
  "dannen": ['D AE1 N AH0 N'],
  "dannenberg": ['D AE1 N AH0 N B ER0 G'],
  "danner": ['D AE1 N ER0'],
  "danners": ['D AE1 N ER0 Z'],
  "dannie": ['D AE1 N IY0'],
  "dannunzio": ['D AA0 N UW1 N Z IY0 OW0'],
  "danny": ['D AE1 N IY0'],
  "danny's": ['D AE1 N IY0 Z'],
  "dano": ['D AA1 N OW0'],
  "danos": ['D EY1 N OW0 Z'],
  "danowski": ['D AH0 N AO1 F S K IY0'],
  "dansby": ['D AE1 N S B IY0'],
  "danser": ['D AE1 N S ER0'],
  "dansereau": ['D AE1 N S ER0 OW2'],
  "dansforth": ['D AE1 N S F AO1 R TH'],
  "dansforth's": ['D AE1 N S F AO1 R TH S'],
  "dansie": ['D AE1 N S IY0'],
  "danske": ['D AE1 N S K'],
  "danskin": ['D AE1 N S K IH0 N'],
  "dansky": ['D AE1 N S K IY0'],
  "danso": ['D AE1 N S OW0'],
  "danson": ['D AE1 N S AH0 N'],
  "danstett": ['D AE1 N S T EH0 T'],
  "dansville": ['D AE1 N Z V IH0 L'],
  "dansville's": ['D AE1 N Z V IH0 L Z'],
  "dant": ['D AE1 N T'],
  "dante": ['D AA1 N T EY0'],
  "dante's": ['D AE1 N T IY0 Z'],
  "dantin": ['D AE1 N T IH0 N'],
  "danton": ['D AE1 N T AH0 N'],
  "dantoni": ['D AA0 N T OW1 N IY0'],
  "dantonio": ['D AE2 N T OW1 N IY0 OW0'],
  "dantrell": ['D AE2 N T R EH1 L'],
  "dantuono": ['D AA0 N T W OW1 N OW0'],
  "dantzler": ['D AE1 N T S L ER0'],
  "danube": ['D AE1 N Y UW0 B'],
  "danvers": ['D AE1 N V ER0 Z'],
  "danville": ['D AE1 N V IH2 L'],
  "danvy": ['D AE1 N V IY0'],
  "danylyszyn": ['D AE2 N IH0 L IY1 Z IH0 N'],
  "danz": ['D AE1 N Z'],
  "danza": ['D AE1 N Z AH0'],
  "danzer": ['D AE1 N Z ER0'],
  "danzig": ['D AE1 N Z IH0 G'],
  "danziger": ['D AE1 N Z IH0 G ER0'],
  "danzy": ['D AE1 N Z IY0'],
  "dao": ['D AW1'],
  "daoud": ['D AW1 D'],
  "daoust": ['D AW1 S T'],
  "daphne": ['D AE1 F N IY0'],
  "daphne's": ['D AE1 F N IY0 Z'],
  "daphnis": ['D AE1 F N AH0 S'],
  "dapice": ['D AA1 P IH0 S'],
  "dapolito": ['D AA0 P OW0 L IY1 T OW0'],
  "daponte": ['D AA0 P OW1 N T EY0'],
  "dapozzo": ['D AH0 P AA1 Z OW2'],
  "dapp": ['D AE1 P'],
  "dapper": ['D AE1 P ER0'],
  "dapuzzo": ['D AH0 P AH1 Z OW0'],
  "daquila": ['D AA0 K W IY1 L AH0'],
  "dar": ['D AA1 R'],
  "dara": ['D AE1 R AH0'],
  "darak": ['D EH1 R AE0 K'],
  "darblay": ['D AA1 R B L EY0'],
  "darbonne": ['D AA1 R B AH0 N'],
  "darby": ['D AA1 R B IY0'],
  "darby's": ['D AA1 R B IY0 Z'],
  "darbyshire": ['D AA1 R B IH0 SH AY2 R'],
  "darcangelo": ['D AA0 R K AA0 NG G EH1 L OW0'],
  "darcey": ['D AA1 R S IY0'],
  "darcie": ['D AA1 R K IY0'],
  "darco": ['D AA1 R K OW0'],
  "darcy": ['D AA1 R S IY0'],
  "dardar": ['D AA0 R D AA1 R'],
  "darden": ['D AA1 R D AH0 N'],
  "darden's": ['D AA1 R D AH0 N Z'],
  "dardis": ['D AA1 R D IH0 S'],
  "dare": ['D EH1 R'],
  "dared": ['D EH1 R D'],
  "daredevil": ['D EH1 R D EH2 V AH0 L'],
  "daredevils": ['D EH1 R D EH2 V AH0 L Z'],
  "daremblum": ['D EH1 R AH0 M B L UW2 M'],
  "daren": ['D AE1 R IH0 N'],
  "darensbourg": ['D AE1 R IH0 N S B ER0 G', 'D AE1 R AH0 N Z B ER0 G'],
  "dares": ['D EH1 R Z'],
  "daresay": ['D EH1 R S EY0'],
  "darfur": ['D AA2 F UW1 R'],
  "darga": ['D AA1 R G AH0'],
  "dargan": ['D AA1 R G AH0 N'],
  "dargis": ['D AA1 R G IH0 S'],
  "daria": ['D AA1 R IY0 AH0'],
  "darice": ['D AA1 R IH0 S'],
  "darien": ['D EH1 R IY0 AH0 N'],
  "darienzo": ['D AA0 R IY1 N Z OW0'],
  "darin": ['D AE1 R IH0 N'],
  "daring": ['D EH1 R IH0 NG'],
  "dario": ['D EH1 R IY0 OW2'],
  "darity": ['D AE1 R IH0 T IY0'],
  "darius": ['D ER0 AY1 AH0 S'],
  "darjeeling": ['D AA2 R JH IY1 L IH0 NG', 'D AA2 R ZH IY1 L IH0 NG'],
  "dark": ['D AA1 R K'],
  "darke": ['D AA1 R K'],
  "darken": ['D AA1 R K AH0 N'],
  "darkened": ['D AA1 R K AH0 N D'],
  "darkening": ['D AA1 R K AH0 N IH0 NG', 'D AA1 R K N IH0 NG'],
  "darker": ['D AA1 R K ER0'],
  "darkest": ['D AA1 R K AH0 S T'],
  "darkie": ['D AA1 R K IY0'],
  "darkly": ['D AA1 R K L IY0'],
  "darkness": ['D AA1 R K N AH0 S'],
  "darkroom": ['D AA1 R K R UW2 M'],
  "darla": ['D AA1 R L AH0'],
  "darland": ['D AA1 R L AH0 N D'],
  "darleen": ['D AA1 R L IY2 N'],
  "darlene": ['D AA1 R L IY2 N'],
  "darley": ['D AA1 R L IY0'],
  "darlie": ['D AA1 R L IY0'],
  "darlin": ['D AA1 R L IH0 N'],
  "darline": ['D AA1 R L AY2 N'],
  "darling": ['D AA1 R L IH0 NG'],
  "darlings": ['D AA1 R L IH0 NG Z'],
  "darlington": ['D AA1 R L IH0 NG T AH0 N'],
  "darman": ['D AA1 R M AH0 N'],
  "darman's": ['D AA1 R M AH0 N Z'],
  "darmon": ['D AA1 R M AH0 N'],
  "darmstadt": ['D AA1 R M S T AE2 T'],
  "darn": ['D AA1 R N'],
  "darnall": ['D AA1 R N AH0 L'],
  "darned": ['D AA1 R N D'],
  "darnedest": ['D AA1 R N D EH0 S T', 'D AA1 R N EH0 S T'],
  "darnel": ['D AA1 R N AH0 L'],
  "darnell": ['D AA0 R N EH1 L'],
  "darner": ['D AA1 R N ER0'],
  "darnold": ['D AA1 R N OW2 L D'],
  "darns": ['D AA1 R N Z'],
  "darocha": ['D AA0 R OW1 K AH0'],
  "darosa": ['D AA0 R OW1 S AH0'],
  "darpa": ['D AA1 R P AH0'],
  "darpino": ['D AA0 R P IY1 N OW0'],
  "darr": ['D EH1 R', 'D AA1 R'],
  "darragh": ['D EH1 R AH0'],
  "darrah": ['D AE1 R AH0'],
  "darrel": ['D EH1 R AH0 L'],
  "darrell": ['D EH1 R AH0 L'],
  "darrelle": ['D ER0 EH1 L'],
  "darren": ['D AA1 R AH0 N'],
  "darrick": ['D AE1 R IH0 K'],
  "darrigo": ['D AA2 R IY1 G OW0'],
  "darrin": ['D AE1 R IH0 N'],
  "darrington": ['D AE1 R IH0 NG T AH0 N'],
  "darroch": ['D AE1 R AH0 K'],
  "darrough": ['D AE1 R AW0'],
  "darrow": ['D EH1 R OW0'],
  "darrow's": ['D EH1 R OW0 Z'],
  "darryl": ['D EH1 R AH0 L'],
  "darsey": ['D AA1 R S IY0'],
  "darst": ['D AA1 R S T'],
  "darsy": ['D AA1 R S IY0'],
  "dart": ['D AA1 R T'],
  "dart's": ['D AA1 R T S'],
  "dartboard": ['D AA1 R T B AO2 R D'],
  "darted": ['D AA1 R T IH0 D'],
  "darter": ['D AA1 R T ER0'],
  "dartez": ['D AA0 R T EH1 Z'],
  "darth": ['D AA1 R TH'],
  "darting": ['D AA1 R T IH0 NG'],
  "dartmouth": ['D AA1 R T M AH0 TH'],
  "dartmouth's": ['D AA1 R T M AH0 TH S'],
  "darton": ['D AA1 R T AH0 N'],
  "darts": ['D AA1 R T S'],
  "dartt": ['D AA1 R T'],
  "darty": ['D AA1 R T IY0'],
  "darty's": ['D AA1 R T IY0 Z'],
  "darville": ['D AA1 R V IH2 L'],
  "darwin": ['D AA1 R W IH0 N'],
  "darwin's": ['D AA1 R W IH0 N Z'],
  "darwinian": ['D AA2 R W IH1 N IY0 AH0 N'],
  "darwinism": ['D AA1 R W IH0 N IH2 Z AH0 M'],
  "darwish": ['D AA1 R W IH0 SH'],
  "daryl": ['D EH1 R AH0 L'],
  "das": ['D AE1 S', 'D AA1 S'],
  "dasa": ['D AA1 S AH0', 'D AE1 S AH0'],
  "dasaro": ['D AA0 S AA1 R OW0'],
  "dasburg": ['D AE1 S B ER0 G'],
  "dascenzo": ['D AA0 S CH EH1 N Z OW0'],
  "dasch": ['D AE1 SH'],
  "daschle": ['D AE1 SH L IY0'],
  "dascoli": ['D AA0 S K OW1 L IY0'],
  "dase": ['D EY1 Z'],
  "daseke": ['D EY1 S AH0 K IY0'],
  "dash": ['D AE1 SH'],
  "dashboard": ['D AE1 SH B AO2 R D'],
  "dashboards": ['D AE1 SH B AO2 R D Z'],
  "dashed": ['D AE1 SH T'],
  "dashel": ['D AE1 SH AH0 L'],
  "dashell": ['D AE1 SH AH0 L'],
  "dasher": ['D AE1 SH ER0'],
  "dashes": ['D AE1 SH IH0 Z'],
  "dashiell": ['D AE1 SH IY0 L'],
  "dashiki": ['D AH0 SH IY1 K IY0'],
  "dashing": ['D AE1 SH IH0 NG'],
  "dashnaw": ['D AE1 SH N AO0'],
  "dashner": ['D AE1 SH N ER0'],
  "dashville": ['D AE1 SH V IH2 L'],
  "dashwood": ['D AE1 SH W UH2 D'],
  "dasilva": ['D AH0 S IH1 L V AH0'],
  "daspin": ['D AE1 S P IH0 N'],
  "daspit": ['D AE1 S P IH0 T'],
  "dass": ['D AE1 S'],
  "dassault": ['D AE1 S AO0 L T'],
  "dassault's": ['D AE1 S AO0 L T S'],
  "dassler": ['D AE1 S L ER0'],
  "dassow": ['D AE1 S OW0'],
  "dastardly": ['D AE1 S T ER0 D L IY0'],
  "dat": ['D AE1 T'],
  "data": ['D EY1 T AH0', 'D AE1 T AH0'],
  "data's": ['D EY1 T AH0 Z', 'D AE1 T AH0 Z'],
  "database": ['D EY1 T AH0 B EY2 S', 'D AE1 T AH0 B EY2 S'],
  "databases": ['D EY1 T AH0 B EY2 S IH0 Z', 'D AE1 T AH0 B EY2 S IH0 Z'],
  "datacard": ['D EY1 T AH0 K AA2 R D', 'D AE1 T AH0 K AA2 R D'],
  "datacards": ['D EY1 T AH0 K AA2 R D Z', 'D AE1 T AH0 K AA2 R D Z'],
  "datacomm": ['D EY1 T AH0 K AA2 M', 'D AE1 T AH0 K AA2 M'],
  "datacomp": ['D EY1 T AH0 K AA2 M P', 'D AE1 T AH0 K AA2 M P'],
  "datacopy": ['D EY1 T AH0 K AA2 P IY0', 'D AE1 T AH0 K AA2 P IY0'],
  "datagraphix": ['D EY1 T AH0 G R AE2 F IH0 K S'],
  "datametrics": ['D EY1 T AH0 M EH2 T R IH0 K S', 'D AE1 T AH0 M EH2 T R IH0 K S'],
  "datapoint": ['D EY1 T AH0 P OY1 N T', 'D AE1 T AH0 P OY1 N T'],
  "datapoint's": ['D EY1 T AH0 P OY1 N T S', 'D AE1 T AH0 P OY1 N T S'],
  "datapoints": ['D EY1 T AH0 P OY1 N T S', 'D AE1 T AH0 P OY1 N T S'],
  "datapower": ['D EY1 T AH0 P AW2 R', 'D AE1 T AH0 P AW2 R'],
  "dataproducts": ['D EY1 T AH0 P R AA2 D AH0 K T S'],
  "dataproducts'": ['D EY1 T AH0 P R AO2 D AH0 K T S', 'D AE1 T AH0 P R AO2 D AH0 K T S'],
  "dataquest": ['D EY1 T AH0 K W EH2 S T', 'D AE1 T AH0 K W EH2 S T'],
  "dataquest's": ['D EY1 T AH0 K W EH2 S T S', 'D AE1 T AH0 K W EH2 S T S'],
  "dataram": ['D EY1 T ER0 AE2 M', 'D AE1 T ER0 AE2 M'],
  "datarex": ['D EY1 T ER0 EH2 K S', 'D AE1 T ER0 EH2 K S'],
  "datas": ['D EY1 T AH0 Z', 'D AE1 T AH0 Z'],
  "dataset": ['D EY1 T AH0 S EH2 T'],
  "datasets": ['D EY1 T AH0 S EH2 T S'],
  "date": ['D EY1 T'],
  "date's": ['D EY1 T S'],
  "dated": ['D EY1 T IH0 D'],
  "datek": ['D AE1 T EH0 K'],
  "dateline": ['D EY1 T L AY2 N'],
  "dateline's": ['D EY1 T L AY2 N Z'],
  "datelines": ['D EY1 T L AY2 N Z'],
  "dates": ['D EY1 T S'],
  "datext": ['D AE1 T EH2 K S T'],
  "dating": ['D EY1 T IH0 NG'],
  "dato": ['D AA1 T OW0'],
  "datron": ['D AE1 T R AH0 N'],
  "dats": ['D AE1 T S'],
  "datsun": ['D AE1 T S AH0 N', 'D AA1 T S AH0 N'],
  "datsun's": ['D AE1 T S AH0 N Z', 'D AA1 T S AH0 N Z'],
  "dattilio": ['D AA0 T IY1 L IY0 OW0'],
  "dattilo": ['D AA0 T IY1 L OW0'],
  "datuk": ['D AA1 T UW2 K'],
  "datum": ['D AE1 T AH0 M', 'D EY1 T AH0 M'],
  "datura": ['D AH0 T UH1 R AH0'],
  "datz": ['D AE1 T S'],
  "dau": ['D OW1'],
  "daub": ['D AO1 B'],
  "daube": ['D AO1 B'],
  "daubed": ['D AO1 B D'],
  "daubenspeck": ['D AW1 B IH0 N S P IH0 K'],
  "dauber": ['D AW1 B ER0'],
  "daubert": ['D AW1 B ER0 T'],
  "dauch": ['D AW1 CH'],
  "daudelin": ['D OW1 D IH0 L AE0 N'],
  "dauenhauer": ['D AW1 AH0 N HH AW0 ER0'],
  "dauer": ['D AW1 ER0'],
  "daufuskie": ['D OW1 F AH2 S K IY0'],
  "daughdrill": ['D AO1 D R IH0 L'],
  "daughenbaugh": ['D AO0 EH1 N B AO0'],
  "daugherty": ['D AA1 K ER0 T IY0'],
  "daughety": ['D AO1 IH0 T IY0'],
  "daughney": ['D AO1 N IY0'],
  "daughter": ['D AO1 T ER0'],
  "daughter's": ['D AO1 T ER0 Z'],
  "daughter-in-law": ['D AO1 T ER0 IH0 N L AO2'],
  "daughters": ['D AO1 T ER0 Z'],
  "daughters'": ['D AO1 T ER0 Z'],
  "daughters-in-law": ['D AO1 T ER0 Z IH0 N L AO2'],
  "daughtery": ['D AO1 T ER0 IY0'],
  "daughton": ['D AO1 T AH0 N'],
  "daughtrey": ['D AO1 T R IY0'],
  "daughtridge": ['D AO1 T R IH0 JH'],
  "daughtry": ['D AO1 T R IY0'],
  "daul": ['D AO1 L'],
  "dault": ['D AO1 L T'],
  "daulton": ['D AO1 L T AH0 N'],
  "daum": ['D AO1 M'],
  "daun": ['D AO1 N'],
  "daunt": ['D AO1 N T'],
  "daunted": ['D AO1 N T IH0 D'],
  "daunting": ['D AO1 N T IH0 NG'],
  "dauphin": ['D AW1 F IH0 N'],
  "dauphinais": ['D OW1 F IH0 N EY0'],
  "dauphinee": ['D AO0 F IH0 N IY1'],
  "dauria": ['D AO1 R IY0 AH0'],
  "daus": ['D AO1 Z'],
  "dauster": ['D AW1 S T ER0'],
  "dauterive": ['D OW1 T ER0 IH0 V'],
  "dauzat": ['D AW1 Z AH0 T'],
  "davalos": ['D AA0 V AA1 L OW0 Z'],
  "davanzo": ['D AH0 V AE1 N Z OW0'],
  "davao": ['D AH0 V OW1', 'D EY1 V OW0'],
  "davault": ['D AH0 V OW1'],
  "davco": ['D AE1 V K OW0'],
  "dave": ['D EY1 V'],
  "dave's": ['D EY1 V Z'],
  "davee": ['D AE1 V IY0'],
  "davenport": ['D AE1 V AH0 N P AO2 R T'],
  "davenport's": ['D AE1 V AH0 N P AO2 R T S'],
  "davern": ['D AE1 V ER0 N'],
  "daversa": ['D AA0 V EH1 R S AH0'],
  "daves": ['D EY1 V Z'],
  "davey": ['D EY1 V IY0'],
  "davi": ['D AA1 V IY0'],
  "davia": ['D AA1 V IY0 AH0'],
  "david": ['D EY1 V IH0 D'],
  "david's": ['D EY1 V IH0 D Z'],
  "davida": ['D AA0 V IY1 D AH0'],
  "davide": ['D AH2 V IY1 D EY2'],
  "davidge": ['D AE1 V IH0 JH'],
  "davidian": ['D AH0 V IH1 D IY0 AH0 N'],
  "davidian's": ['D AH0 V IH1 D IY0 AH0 N Z'],
  "davidians": ['D AH0 V IH1 D IY0 AH0 N Z'],
  "davidoff": ['D EY1 V IH0 D AO0 F'],
  "davidow": ['D AE1 V IH0 D OW0'],
  "davids": ['D EY1 V IH0 D Z'],
  "davidson": ['D EY1 V IH0 D S AH0 N'],
  "davidson's": ['D EY1 V IH0 D S AH0 N Z'],
  "davie": ['D EY1 V IY0'],
  "davies": ['D EY1 V IY0 Z'],
  "davignon": ['D AA0 V IY0 G N AO1 N'],
  "davila": ['D AH0 V IH1 L AH0'],
  "davilla": ['D AH0 V IH1 L AH0'],
  "davin": ['D AE1 V IH0 N'],
  "davina": ['D AA0 V IY1 N AH0'],
  "davinci": ['D AH0 V IH1 N CH IY2'],
  "davino": ['D AA0 V IY1 N OW0'],
  "davio's": ['D AE1 V IY0 OW0 Z'],
  "davir": ['D AH0 V IH1 R'],
  "davis": ['D EY1 V AH0 S', 'D EY1 V IH0 S'],
  "davis'": ['D EY1 V AH0 S', 'D EY1 V AH0 S AH0 Z'],
  "davis's": ['D EY1 V AH0 S AH0 Z', 'D EY1 V IH0 S IH0 Z'],
  "davison": ['D EY1 V IH0 S AH0 N'],
  "davisson": ['D AE1 V IH0 S AH0 N'],
  "davitt": ['D AH0 V IH1 T'],
  "davlin": ['D AE1 V L IH0 N'],
  "davoli": ['D AA0 V OW1 L IY0'],
  "davos": ['D AA1 V OW0 S'],
  "davox": ['D AE1 V AA0 K S'],
  "davy": ['D EY1 V IY0'],
  "davydov": ['D EY1 V IH0 D AO2 V'],
  "daw": ['D AO1'],
  "dawdle": ['D AO1 D AH0 L'],
  "dawdling": ['D AO1 D L IH0 NG'],
  "dawdy": ['D AO1 D IY0'],
  "dawe": ['D AO1'],
  "dawes": ['D AO1 Z'],
  "dawit": ['D AE1 W IH0 T'],
  "dawkins": ['D AO1 K IH0 N Z'],
  "dawley": ['D AO1 L IY0'],
  "dawn": ['D AO1 N'],
  "dawn's": ['D AO1 N Z'],
  "dawned": ['D AO1 N D'],
  "dawning": ['D AO1 N IH0 NG'],
  "dawns": ['D AO1 N Z'],
  "daws": ['D AO1 Z'],
  "dawsey": ['D AO1 S IY0'],
  "dawson": ['D AO1 S AH0 N'],
  "dawson's": ['D AO1 S AH0 N Z'],
  "dax": ['D AE1 K S'],
  "dax'": ['D AE1 K S'],
  "dax's": ['D AE1 K S IH0 Z'],
  "daxor": ['D AE1 K S ER0'],
  "day": ['D EY1'],
  "day's": ['D EY1 Z'],
  "day-by-day": ['D EY1 B AY0 D EY2'],
  "day-to-day": ['D EY1 T UW0 D EY2'],
  "daya": ['D AY1 AH0'],
  "daya's": ['D AY1 AH0 Z'],
  "dayan": ['D EY1 AH0 N'],
  "daybreak": ['D EY1 B R EY2 K'],
  "daycare": ['D EY1 K EH2 R'],
  "daycares": ['D EY1 K EH2 R Z'],
  "dayco": ['D EY1 K OW0'],
  "daydream": ['D EY1 D R IY2 M'],
  "daydreamed": ['D EY1 D R IY2 M D'],
  "daydreaming": ['D EY1 D R IY2 M IH0 NG'],
  "daydreams": ['D EY1 D R IY2 M Z'],
  "daye": ['D EY1'],
  "dayhoff": ['D EY1 HH AO0 F'],
  "dayhuff": ['D EY1 HH AH2 F'],
  "dayle": ['D EY1 L'],
  "dayley": ['D EY1 L IY0'],
  "daylight": ['D EY1 L AY2 T'],
  "daylights": ['D EY1 L AY2 T S'],
  "daylong": ['D EY1 L AO2 NG'],
  "daynard": ['D EY1 N ER0 D'],
  "dayne": ['D EY1 N'],
  "days": ['D EY1 Z'],
  "days'": ['D EY1 Z'],
  "daytime": ['D EY1 T AY2 M'],
  "daytimes": ['D EY1 T AY2 M Z'],
  "dayton": ['D EY1 T AH0 N'],
  "dayton's": ['D EY1 T AH0 N Z'],
  "daytona": ['D EY0 T OW1 N AH0'],
  "daytop": ['D EY1 T AA2 P'],
  "daywalt": ['D EY1 W AH0 L T'],
  "daze": ['D EY1 Z'],
  "dazed": ['D EY1 Z D'],
  "dazey": ['D EY1 Z IY0'],
  "dazs": ['D AA1 S'],
  "dazzle": ['D AE1 Z AH0 L'],
  "dazzled": ['D AE1 Z AH0 L D'],
  "dazzling": ['D AE1 Z AH0 L IH0 NG', 'D AE1 Z L IH0 NG'],
  "dazzo": ['D AE1 Z OW0'],
  "dbase": ['D IY1 B EY2 S'],
  "dc": ['D IY2 S IY1'],
  "ddt": ['D IY2 D IY2 T IY1'],
  "de": ['D IY1', 'D EY1', 'D AH0'],
  "de-excite": ['D IY1 IH0 K S AY1 T'],
  "de-excites": ['D IY1 IH0 K S AY1 T S'],
  "dea": ['D IY1'],
  "deacon": ['D IY1 K AH0 N'],
  "deaconess": ['D IY1 K AH0 N AH0 S'],
  "deacons": ['D IY1 K AH0 N Z'],
  "deactivate": ['D IY2 AE1 K T IH0 V EY2 T'],
  "deactivated": ['D IY2 AE1 K T IH0 V EY2 T IH0 D'],
  "dead": ['D EH1 D'],
  "dead-end": ['D EH2 D EH1 N D'],
  "dead-ender": ['D EH2 D EH1 N D ER0'],
  "dead-ends": ['D EH2 D EH1 N D Z'],
  "deadbeat": ['D EH1 D B IY2 T'],
  "deadbeats": ['D EH1 D B IY2 T S'],
  "deadbolt": ['D EH1 D B OW2 L T'],
  "deaden": ['D EH1 D AH0 N'],
  "deadening": ['D EH1 D AH0 N IH0 NG', 'D EH1 D N IH0 NG'],
  "deader": ['D EH1 D ER0'],
  "deadhead": ['D EH1 D HH EH2 D'],
  "deadheads": ['D EH1 D HH EH2 D Z'],
  "deadhorse": ['D EH1 D HH AO2 R S'],
  "deadlier": ['D EH1 D L IY0 ER0'],
  "deadliest": ['D EH1 D L IY0 AH0 S T'],
  "deadline": ['D EH1 D L AY2 N'],
  "deadlines": ['D EH1 D L AY2 N Z'],
  "deadliness": ['D EH1 D L IY0 N AH0 S'],
  "deadlock": ['D EH1 D L AA2 K'],
  "deadlocked": ['D EH1 D L AA2 K T'],
  "deadlocks": ['D EH1 D L AA2 K S'],
  "deadly": ['D EH1 D L IY0'],
  "deadpan": ['D EH1 D P AE2 N'],
  "deadweight": ['D EH1 D W EY2 T'],
  "deadwood": ['D EH1 D W UH2 D'],
  "deadwyler": ['D EH1 D W AY2 L ER0'],
  "deady": ['D EH1 D IY0'],
  "deaf": ['D EH1 F'],
  "deafen": ['D EH1 F AH0 N'],
  "deafening": ['D EH1 F AH0 N IH0 NG', 'D EH1 F N IH0 NG'],
  "deafness": ['D EH1 F N AH0 S'],
  "deahl": ['D IY1 L'],
  "deak": ['D IY1 K'],
  "deak's": ['D IY1 K S'],
  "deakin": ['D IY1 K IH0 N'],
  "deakins": ['D IY1 K IH0 N Z'],
  "deal": ['D IY1 L'],
  "deal's": ['D IY1 L Z'],
  "dealba": ['D IY2 AE1 L B AH0'],
  "deale": ['D IY1 L'],
  "dealer": ['D IY1 L ER0'],
  "dealer's": ['D IY1 L ER0 Z'],
  "dealerline": ['D IY1 L ER0 L AY2 N'],
  "dealers": ['D IY1 L ER0 Z'],
  "dealers'": ['D IY1 L ER0 Z'],
  "dealership": ['D IY1 L ER0 SH IH2 P'],
  "dealership's": ['D IY1 L ER0 SH IH2 P S'],
  "dealerships": ['D IY1 L ER0 SH IH2 P S'],
  "dealey": ['D IY1 L IY0'],
  "dealfish": ['D IY1 L F IH2 SH'],
  "dealing": ['D IY1 L IH0 NG'],
  "dealings": ['D IY1 L IH0 NG Z'],
  "dealmaker": ['D IY1 L M EY2 K ER0'],
  "dealmakers": ['D IY1 L M EY2 K ER0 Z'],
  "dealmaking": ['D IY1 L M EY2 K IH0 NG'],
  "dealmeida": ['D AH0 L M IY1 D AH0'],
  "deals": ['D IY1 L Z'],
  "dealt": ['D EH1 L T'],
  "dealy": ['D IY1 L IY0'],
  "deam": ['D IY1 M'],
  "deamer": ['D IY1 M ER0'],
  "dean": ['D IY1 N'],
  "dean's": ['D IY1 N Z'],
  "deana": ['D IY2 AE1 N AH0'],
  "deanda": ['D IY2 AE1 N D AH0'],
  "deandrade": ['D AH0 N D R AA1 D IY0'],
  "deandrea": ['D AE1 D R IY0 AH0', 'D IY2 AE0 D R EY1 AH0'],
  "deandrea's": ['D AE1 D R IY0 AH0 Z', 'D IY2 AE0 D R EY1 AH0 Z'],
  "deane": ['D IY1 N'],
  "deaner": ['D IY1 N ER0'],
  "deangelis": ['D IY0 AE1 N JH AH0 L AH0 S'],
  "deangelo": ['D AH0 NG G EH1 L OW0'],
  "deanna": ['D IY2 AE1 N AH0'],
  "deans": ['D IY1 N Z'],
  "dear": ['D IH1 R'],
  "dearborn": ['D IH1 R B AO2 R N'],
  "dearden": ['D IH1 R D AH0 N'],
  "deardorff": ['D IH1 R D AO2 R F'],
  "deardourff": ['D IH1 R D AO2 R F'],
  "dearer": ['D IH1 R ER0'],
  "dearest": ['D IH1 R AH0 S T'],
  "dearing": ['D IH1 R IH0 NG'],
  "dearinger": ['D IH1 R IH0 NG ER0'],
  "dearly": ['D IH1 R L IY0'],
  "dearman": ['D IH1 R M AH0 N'],
  "dearmas": ['D ER1 M AH0 Z'],
  "dearment": ['D IH1 R M AH0 N T'],
  "dearmon": ['D ER1 M AH0 N'],
  "dearmond": ['D ER1 M AH0 N D'],
  "dearth": ['D ER1 TH'],
  "deary": ['D IH1 R IY0'],
  "deas": ['D IY1 Z'],
  "dease": ['D IY1 S'],
  "deason": ['D IY1 Z AH0 N'],
  "deasy": ['D IY1 S IY0'],
  "deater": ['D IY1 T ER0'],
  "death": ['D EH1 TH'],
  "death's": ['D EH1 TH S'],
  "deathbed": ['D EH1 TH B EH2 D'],
  "deatherage": ['D EH1 TH ER0 IH0 JH', 'D EH1 TH R IH0 JH'],
  "deathly": ['D EH1 TH L IY0'],
  "deaths": ['D EH1 TH S'],
  "deathshot": ['D EH1 TH SH AO0 T'],
  "deathwatch": ['D EH1 TH W AA2 CH'],
  "deatley": ['D IY1 T L IY0'],
  "deaton": ['D IY1 T AH0 N'],
  "deatrick": ['D IY1 T R IH0 K'],
  "deats": ['D IY1 T S'],
  "deaver": ['D IY1 V ER0'],
  "deaver's": ['D IY1 V ER0 Z'],
  "deavers": ['D IY1 V ER0 Z'],
  "deavila": ['D AH0 V IY1 L AH0'],
  "deb": ['D EH1 B'],
  "debacker": ['D IY1 B AE0 K ER0'],
  "debacle": ['D AH0 B AA1 K AH0 L'],
  "debacles": ['D EY0 B AA1 K AH0 L Z'],
  "debakey": ['D IH0 B EY1 K IY0'],
  "debarment": ['D IH0 B AA1 R M AH0 N T'],
  "debarr": ['D IH0 B AE1 R'],
  "debarros": ['D EY0 B AA1 R OW0 Z'],
  "debartolo": ['D IH0 B AA0 R T OW1 L OW0', 'D AH0 B AA1 R T AH0 L OW0'],
  "debartolos": ['D AH0 B AA1 R T AH0 L OW0 Z', 'D IH0 B AA0 R T OW1 L OW0 Z'],
  "debase": ['D AH0 B EY1 S'],
  "debased": ['D AH0 B EY1 S T'],
  "debasement": ['D AH0 B EY1 S M AH0 N T'],
  "debasing": ['D IH0 B EY1 S IH0 NG'],
  "debatable": ['D AH0 B EY1 T AH0 B AH0 L'],
  "debate": ['D AH0 B EY1 T'],
  "debate's": ['D AH0 B EY1 T S'],
  "debated": ['D AH0 B EY1 T IH0 D'],
  "debater": ['D AH0 B EY1 T ER0'],
  "debaters": ['D AH0 B EY1 T ER0 Z'],
  "debates": ['D AH0 B EY1 T S'],
  "debating": ['D AH0 B EY1 T IH0 NG'],
  "debator": ['D IY0 B EY1 T ER0'],
  "debator's": ['D IY0 B EY1 T ER0 Z'],
  "debauche": ['D EH1 B AW0 K'],
  "debauchery": ['D AH0 B AO1 CH ER0 IY0'],
  "debaun": ['D EH1 B AW0 N'],
  "debbie": ['D EH1 B IY0'],
  "debbie's": ['D EH1 B IY0 Z'],
  "debby": ['D EH1 B IY0'],
  "debeer": ['D EH1 B IH0 R'],
  "debeers": ['D IH0 B IH1 R Z'],
  "debelak": ['D EH1 B IH0 L AH0 K'],
  "debell": ['D IY1 B EH0 L'],
  "debella": ['D IH0 B EH1 L AH0'],
  "debellis": ['D EH1 B IH0 L IH0 S'],
  "debello": ['D IH0 B EH1 L OW0'],
  "debenedetto": ['D IH0 B EH0 N AH0 D EH1 T OW0'],
  "debenedictis": ['D EH1 B IH0 N AH0 D IH2 K T IH0 S'],
  "debenture": ['D AH0 B EH1 N CH ER0'],
  "debentures": ['D AH0 B EH1 N CH ER0 Z'],
  "debentures'": ['D IH0 B EH1 N CH ER0 Z'],
  "debernardi": ['D IH0 B ER0 N AA1 R D IY0'],
  "deberry": ['D IY1 B EH0 R IY0'],
  "debes": ['D IY1 B Z'],
  "debevoise": ['D EH2 B EH0 V W AA1 Z'],
  "debi": ['D EH1 B IY0'],
  "debian": ['D EH1 B IY2 AH0 N'],
  "debiase": ['D IH0 B IY0 AA1 S IY0'],
  "debilitate": ['D AH0 B IH1 L AH0 T EY2 T'],
  "debilitated": ['D AH0 B IH1 L AH0 T EY2 T IH0 D'],
  "debilitating": ['D AH0 B IH1 L AH0 T EY2 T IH0 NG'],
  "debility": ['D AH0 B IH1 L AH0 T IY0'],
  "debit": ['D EH1 B IH0 T'],
  "debited": ['D EH1 B IH0 T IH2 D'],
  "debits": ['D EH1 B IH0 T S'],
  "deblanc": ['D IH0 B L AE1 NG K'],
  "deblasio": ['D IH0 B L AA1 S IY0 OW0'],
  "deblock": ['D EH1 B L AH0 K'],
  "deblois": ['D EH2 B L UW1'],
  "deblum": ['D AH0 B L UW1 M', 'D AH0 B L AH1 M'],
  "debnam": ['D EH1 B N AH0 M'],
  "debo": ['D IY1 B OW0'],
  "deboard": ['D IY1 B AO0 R D'],
  "deboe": ['D IH0 B OW1'],
  "deboer": ['D IY1 B OW0 ER0'],
  "deboers": ['D IY1 B OW0 ER0 Z'],
  "debold": ['D EH1 B OW0 L D'],
  "debolt": ['D EH1 B OW0 L T'],
  "debona": ['D IH0 B OW1 N AH0'],
  "debonair": ['D EH2 B AH0 N EH1 R'],
  "debonis": ['D EH1 B AH0 N IH0 S'],
  "debono": ['D IH0 B OW1 N OW0'],
  "debor": ['D EH1 B AO0 R'],
  "debora": ['D EH1 B R AH0'],
  "deborah": ['D EH1 B ER0 AH0', 'D EH1 B R AH0'],
  "deborah's": ['D EH1 B ER0 AH0 Z', 'D EH1 B R AH0 Z'],
  "debord": ['D IH0 B AO1 R D'],
  "deborde": ['D IH0 B AO1 R D'],
  "debose": ['D EH1 B AH0 S'],
  "debow": ['D EH1 B OW0'],
  "deboy": ['D IH0 B OY1'],
  "debra": ['D EH1 B R AH0'],
  "debraudwick": ['D IH0 B R AA1 D W IH0 K'],
  "debrecen": ['D EH1 B R EH1 T S IH0 N'],
  "debrief": ['D IH0 B R IY1 F'],
  "debriefed": ['D IH0 B R IY1 F T'],
  "debriefing": ['D IH0 B R IY1 F IH0 NG'],
  "debriefs": ['D IH0 B R IY1 F S'],
  "debris": ['D AH0 B R IY1'],
  "debrosse": ['D EH1 B R AH0 S'],
  "debruhl": ['D EH1 B R AH0 L'],
  "debruin": ['D EH1 B R UW0 IH0 N'],
  "debruler": ['D EH1 B R UW0 L ER0'],
  "debruyn": ['D EH1 B R AY0 N'],
  "debruyne": ['D EH1 B R AY0 N'],
  "debs": ['D EH1 B Z'],
  "debt": ['D EH1 T'],
  "debt's": ['D EH1 T S'],
  "debtholder": ['D EH1 T HH OW2 L D ER0'],
  "debtholders": ['D EH1 T HH OW2 L D ER0 Z'],
  "debtor": ['D EH1 T ER0'],
  "debtor's": ['D EH1 T ER0 Z'],
  "debtors": ['D EH1 T ER0 Z'],
  "debtors'": ['D EH1 T ER0 Z'],
  "debts": ['D EH1 T S'],
  "debug": ['D IY0 B AH1 G'],
  "debugging": ['D IY0 B AH1 G IH0 NG'],
  "debuhr": ['D EH1 B UH0 R'],
  "debunk": ['D IH0 B AH1 NG K'],
  "debunked": ['D IH0 B AH1 NG K T'],
  "debunking": ['D IH0 B AH1 NG K IH0 NG'],
  "debunks": ['D IH0 B AH1 NG K S'],
  "debus": ['D EH1 B IH0 S'],
  "debusk": ['D EH1 B AH0 S K'],
  "debussy": ['D IH0 B AH1 S IY0', 'D IH0 B Y UW1 S IY0'],
  "debussy's": ['D IH0 B AH1 S IY0 Z', 'D IH0 B Y UW1 S IY0 Z'],
  "debut": ['D EY0 B Y UW1', 'D EY1 B Y UW0'],
  "debutante": ['D EH1 B Y AH0 T AA1 N T'],
  "debutantes": ['D EH1 B Y AH0 T AA1 N T S'],
  "debuted": ['D EY0 B Y UW1 D', 'D EY1 B Y UW0 D'],
  "debuting": ['D EY0 B Y UW1 IH0 NG'],
  "debuts": ['D EY1 B Y UW0 Z'],
  "dec": ['D EH1 K'],
  "dec's": ['D EH1 K S'],
  "decade": ['D EH0 K EY1 D', 'D EH1 K EY0 D'],
  "decade's": ['D EH1 K EY0 D Z'],
  "decadence": ['D EH1 K AH0 D AH0 N S'],
  "decadent": ['D EH1 K AH0 D AH0 N T'],
  "decadents": ['D EH1 K AH0 D AH0 N T S'],
  "decades": ['D EH0 K EY1 D Z', 'D EH1 K EY0 D Z'],
  "decaf": ['D IY1 K AE0 F'],
  "decaffeinate": ['D IY0 K AE1 F AH0 N EY2 T'],
  "decaffeinated": ['D IY0 K AE1 F AH0 N EY2 T IH0 D'],
  "decaffeinating": ['D IY0 K AE1 F AH0 N EY2 T IH0 NG'],
  "decaffeination": ['D IY0 K AE2 F AH0 N EY1 SH AH0 N'],
  "decaire": ['D IY1 K EH0 R'],
  "decals": ['D IY1 K AE2 L Z'],
  "decamillo": ['D EH2 K AH0 M IH1 L OW0'],
  "decamillo's": ['D EH2 K AH0 M IH1 L OW0 Z'],
  "decamp": ['D AH0 K AE1 M P'],
  "decamped": ['D IY0 K AE1 M P T'],
  "decandia": ['D IH0 K AA1 N D IY0 AH0'],
  "decant": ['D AH0 K AE1 N T'],
  "decanting": ['D AH0 K AE1 N T IH0 NG'],
  "decapitate": ['D IY0 K AE1 P AH0 T EY2 T'],
  "decapitated": ['D IY0 K AE1 P AH0 T EY2 T IH0 D'],
  "decapitation": ['D IH0 K AE2 P IH0 T EY1 SH AH0 N'],
  "decapitations": ['D IH0 K AE2 P IH0 T EY1 SH AH0 N Z'],
  "decaprio": ['D IH0 K AA1 P R IY0 OW0'],
  "decapua": ['D IH0 K AA0 P UW1 AH0'],
  "decarava": ['D IH0 K AE1 AH0 V AH0'],
  "decarava's": ['D IH0 K AE1 AH0 V AH0 Z'],
  "decarli": ['D IH0 K AA1 R L IY0'],
  "decarlo": ['D IH0 K AA1 R L OW0'],
  "decaro": ['D IH0 K AA1 R OW0'],
  "decarolis": ['D EH1 K ER0 AH0 L IH0 S', 'D IH0 K ER1 AH0 L IH0 S'],
  "decastro": ['D IH0 K AE1 S T R OW0'],
  "decathlete": ['D IY0 K AE1 TH L IY0 T'],
  "decathlon": ['D IY0 K AE1 TH L AO0 N'],
  "decato": ['D IH0 K AA1 T OW0'],
  "decatur": ['D IH0 K EY1 T ER0'],
  "decay": ['D IH0 K EY1'],
  "decayed": ['D AH0 K EY1 D'],
  "decaying": ['D IH0 K EY1 IH0 NG'],
  "decays": ['D AH0 K EY1 Z'],
  "decca": ['D EH1 K AH0'],
  "decease": ['D IH0 S IY1 S'],
  "deceased": ['D IH0 S IY1 S T'],
  "dececco": ['D IH0 CH EH1 K OW0'],
  "decedent": ['D EH0 S IY1 D AH0 N T'],
  "decedent's": ['D EH0 S IY1 D AH0 N T S'],
  "decedents": ['D EH0 S IY1 D AH0 N T S'],
  "deceit": ['D AH0 S IY1 T', 'D IH0 S IY1 T'],
  "deceitful": ['D AH0 S IY1 T F AH0 L', 'D IH0 S IY1 T F AH0 L'],
  "deceits": ['D AH0 S IY1 T S'],
  "deceive": ['D IH0 S IY1 V'],
  "deceived": ['D IH0 S IY1 V D'],
  "deceiving": ['D IH0 S IY1 V IH0 NG'],
  "decelerate": ['D IH0 S EH1 L ER0 EY2 T'],
  "decelerated": ['D IH0 S EH1 L ER0 EY2 T IH0 D'],
  "decelerating": ['D IH0 S EH1 L ER0 EY2 T IH0 NG'],
  "deceleration": ['D IH0 S EH2 L ER0 EY1 SH AH0 N'],
  "decelle": ['D IH0 S EH1 L'],
  "decelles": ['D EH1 S AH0 L Z'],
  "december": ['D IH0 S EH1 M B ER0'],
  "december's": ['D IH0 S EH1 M B ER0 Z'],
  "decency": ['D IY1 S AH0 N S IY0'],
  "decennial": ['D AH0 S EH1 N IY0 AH0 L'],
  "decent": ['D IY1 S AH0 N T'],
  "decently": ['D IY1 S AH0 N T L IY0'],
  "decentralization": ['D IH0 S EH2 N T R AH0 L IH0 Z EY1 SH AH0 N'],
  "decentralize": ['D IH0 S EH1 N T R AH0 L AY2 Z'],
  "decentralized": ['D IH0 S EH1 N T R AH0 L AY2 Z D'],
  "decentralizing": ['D IH0 S EH1 N T R AH0 L AY2 Z IH0 NG'],
  "deception": ['D IH0 S EH1 P SH AH0 N'],
  "deceptions": ['D IH0 S EH1 P SH AH0 N Z'],
  "deceptive": ['D IH0 S EH1 P T IH0 V'],
  "deceptively": ['D IH0 S EH1 P T IH0 V L IY0'],
  "decertification": ['D IY0 S ER2 T AH0 F AH0 K EY1 SH AH0 N'],
  "decertified": ['D IY0 S ER1 T AH0 F AY2 D'],
  "decertify": ['D IY0 S ER1 T AH0 F AY2'],
  "decesare": ['D IH0 CH EH0 S AA1 R IY0'],
  "decesaris": ['D IH0 S EH1 S ER0 IH0 S'],
  "dech": ['D EH1 K'],
  "dechant": ['D EY1 CH AH0 N T'],
  "dechellis": ['D EH1 K IH0 L IH0 S'],
  "dechene": ['D EH1 K IY0 N'],
  "decherd": ['D EH1 CH ER0 D'],
  "dechert": ['D EH1 K ER0 T'],
  "dechine": ['D EH1 CH IH2 N', 'D AH0 CH IH1 N'],
  "dechristopher": ['D EH1 K R IH0 S T AA0 F ER0'],
  "decibel": ['D EH1 S AH0 B EH2 L'],
  "decibels": ['D EH1 S AH0 B AH0 L Z'],
  "decicco": ['D IH0 CH IY1 K OW0'],
  "decide": ['D IH2 S AY1 D'],
  "decided": ['D IH2 S AY1 D IH0 D'],
  "decidedly": ['D IH2 S AY1 D AH0 D L IY0'],
  "decider": ['D IH2 S AY1 D ER0'],
  "decides": ['D IH2 S AY1 D Z'],
  "deciding": ['D IH2 S AY1 D IH0 NG'],
  "deciduous": ['D IH2 S IH1 JH UW0 AH0 S'],
  "deciliter": ['D EH1 S AH0 L IY2 T ER0'],
  "decima": ['D IH0 CH IY1 M AH0'],
  "decimal": ['D EH1 S AH0 M AH0 L'],
  "decimals": ['D EH1 S AH0 M AH0 L Z'],
  "decimate": ['D EH1 S AH0 M EY2 T'],
  "decimated": ['D EH1 S AH0 M EY2 T IH0 D'],
  "decimating": ['D EH1 S AH0 M EY2 T IH0 NG'],
  "decimation": ['D EH1 S AH0 M EY2 SH AH0 N'],
  "decipher": ['D IH0 S AY1 F ER0'],
  "deciphered": ['D IH0 S AY1 F ER0 D'],
  "deciphering": ['D AH0 S AY1 F ER0 IH0 NG'],
  "decision": ['D IH0 S IH1 ZH AH0 N'],
  "decision's": ['D IH0 S IH1 ZH AH0 N Z'],
  "decision-maker": ['D IH0 S IH1 ZH AH0 N M EY2 K ER0'],
  "decision-making": ['D IH0 S IH1 ZH AH0 N M EY2 K IH0 NG'],
  "decisionmaker": ['D IH0 S IH1 ZH AH0 N M EY2 K ER0'],
  "decisionmaking": ['D IH0 S IH1 ZH AH0 N M EY2 K IH0 NG'],
  "decisions": ['D IH0 S IH1 ZH AH0 N Z'],
  "decisive": ['D IH0 S AY1 S IH0 V'],
  "decisively": ['D IH0 S AY1 S IH0 V L IY0'],
  "decisiveness": ['D IH0 S AY1 S IH0 V N AH0 S'],
  "deck": ['D EH1 K'],
  "deckard": ['D IH0 K AA1 R D'],
  "decked": ['D EH1 K T'],
  "decker": ['D EH1 K ER0'],
  "decker's": ['D EH1 K ER0 Z'],
  "deckert": ['D EH1 K ER0 T'],
  "decking": ['D EH1 K IH0 NG'],
  "deckman": ['D EH1 K M AH0 N'],
  "decks": ['D EH1 K S'],
  "declaim": ['D IH0 K L EY1 M'],
  "declaimed": ['D IH0 K L EY1 M D'],
  "declarant": ['D IH0 K L EH1 R AH0 N T'],
  "declaration": ['D EH2 K L ER0 EY1 SH AH0 N'],
  "declarations": ['D EH2 K L ER0 EY1 SH AH0 N Z'],
  "declarative": ['D IH0 K L EH1 R AH0 T IH0 V'],
  "declaratory": ['D IH0 K L EH1 R AH0 T AO2 R IY0'],
  "declare": ['D IH0 K L EH1 R'],
  "declared": ['D IH0 K L EH1 R D'],
  "declares": ['D IH0 K L EH1 R Z'],
  "declaring": ['D IH0 K L EH1 R IH0 NG'],
  "declassified": ['D IH0 K L AE1 S AH0 F AY2 D'],
  "declassify": ['D IH0 K L AE1 S AH0 F AY2'],
  "declension": ['D AH0 K L EH1 N SH AH0 N'],
  "declensions": ['D AH0 K L EH1 N SH AH0 N Z'],
  "declerck": ['D AH0 K L ER1 K'],
  "declerck's": ['D AH0 K L ER1 K S'],
  "declercq": ['D AH0 K L ER1 K'],
  "declercq's": ['D AH0 K L ER1 K S'],
  "declerk": ['D AH0 K L ER1 K'],
  "declerk's": ['D AH0 K L ER1 K S'],
  "declerque": ['D AH0 K L ER1 K'],
  "declerque's": ['D AH0 K L ER1 K S'],
  "decline": ['D IH0 K L AY1 N'],
  "declined": ['D IH0 K L AY1 N D'],
  "decliner": ['D IH0 K L AY1 N ER0'],
  "decliners": ['D IH0 K L AY1 N ER0 Z'],
  "declines": ['D IH0 K L AY1 N Z'],
  "declining": ['D IH0 K L AY1 N IH0 NG'],
  "declue": ['D EH1 K L UW0'],
  "deco": ['D EH1 K OW0'],
  "decode": ['D IH0 K OW1 D'],
  "decoded": ['D IH0 K OW1 D AH0 D'],
  "decoder": ['D IH0 K OW1 D ER0'],
  "decoders": ['D IH0 K OW1 D ER0 Z'],
  "decodes": ['D IH0 K OW1 D Z'],
  "decoding": ['D IH0 K OW1 D IH0 NG'],
  "decola": ['D IH0 K OW1 L AH0'],
  "decom": ['D EH1 K AA2 M'],
  "decommission": ['D IY0 K AH0 M IH1 SH AH0 N'],
  "decommissioned": ['D IY0 K AH0 M IH1 SH AH0 N D'],
  "decommissioning": ['D IY0 K AH0 M IH1 SH AH0 N IH0 NG'],
  "decompose": ['D IY2 K AH0 M P OW1 Z'],
  "decomposed": ['D IY2 K AH0 M P OW1 Z D'],
  "decomposes": ['D IY2 K AH0 M P OW1 Z IH0 Z'],
  "decomposing": ['D IY2 K AH0 M P OW1 Z IH0 NG'],
  "decomposition": ['D IY2 K AH0 M P OW0 Z IH1 SH AH0 N', 'D IY2 K AH0 M P AH0 Z IH1 SH AH0 N'],
  "decompression": ['D IY2 K AH0 M P R EH1 SH AH0 N'],
  "deconcini": ['D IY2 K AH0 N S IY1 N IY0'],
  "decongestant": ['D IH0 K AH0 N JH EH1 S T AH0 N T', 'D IY0 K AH0 N JH EH1 S T AH0 N T'],
  "decongestants": ['D IH0 K AH0 N JH EH1 S T AH0 N T S', 'D IY0 K AH0 N JH EH1 S T AH0 N T S'],
  "deconstruct": ['D IY2 K AH0 N S T R AH1 K T'],
  "deconstruction": ['D IY0 K AH0 N S T R AH1 K SH AH0 N'],
  "decontaminate": ['D IY0 K AH0 N T AE1 M AH0 N EY2 T'],
  "decontaminated": ['D IY0 K AH0 N T AE1 M AH0 N EY2 T IH0 D'],
  "decontamination": ['D IY0 K AH0 N T AE2 M AH0 N EY1 SH AH0 N'],
  "decontrol": ['D IY2 K AH0 N T R OW1 L'],
  "decontrolled": ['D IY2 K AH0 N T R OW1 L D'],
  "decook": ['D EH0 K UH1 K'],
  "decor": ['D IH0 K AO1 R', 'D EY1 K AO0 R'],
  "decorate": ['D EH1 K ER0 EY2 T'],
  "decorated": ['D EH1 K ER0 EY2 T IH0 D'],
  "decorating": ['D EH1 K ER0 EY2 T IH0 NG'],
  "decoration": ['D EH2 K ER0 EY1 SH AH0 N'],
  "decorations": ['D EH2 K ER0 EY1 SH AH0 N Z'],
  "decorative": ['D EH1 K R AH0 T IH0 V'],
  "decorator": ['D EH1 K ER0 EY2 T ER0'],
  "decorators": ['D EH1 K ER0 EY2 T ER0 Z'],
  "decordova": ['D IY2 K AO2 R D OW1 V AH0'],
  "decorous": ['D EH1 K ER0 AH0 S'],
  "decorte": ['D IH0 K AO1 R T IY0'],
  "decorum": ['D IH0 K AO1 R AH0 M'],
  "decosta": ['D IH0 K OW1 S T AH0'],
  "decoste": ['D IH0 K OW1 S T IY0'],
  "decoster": ['D EH1 K AH0 S T ER0'],
  "decoteau": ['D EH1 K AH0 T OW0'],
  "decou": ['D IH0 K UW1'],
  "decouple": ['D IY0 K AH1 P AH0 L'],
  "decoupling": ['D IY0 K AH1 P L IH0 NG'],
  "decourcy": ['D EH1 K UH0 R K IY0'],
  "decoursey": ['D EH1 K AO0 R S IY0'],
  "decoy": ['D AH0 K OY1'],
  "decoys": ['D IY1 K OY0 Z'],
  "decrane": ['D AH0 K R EY1 N'],
  "decrease": ['D IH0 K R IY1 S', 'D IY1 K R IY2 S'],
  "decreased": ['D IH0 K R IY1 S T', 'D IY1 K R IY2 S T'],
  "decreases": ['D IH0 K R IY1 S AH0 Z', 'D IH0 K R IY1 S IH0 Z', 'D IY1 K R IY2 S IH0 Z'],
  "decreasing": ['D IH0 K R IY1 S IH0 NG', 'D IY1 K R IY2 S IH0 NG'],
  "decree": ['D IH0 K R IY1'],
  "decreed": ['D IH0 K R IY1 D'],
  "decrees": ['D IH0 K R IY1 Z'],
  "decrepit": ['D AH0 K R EH1 P IH0 T'],
  "decrepitly": ['D AH0 K R EH1 P IH0 T L IY0'],
  "decrescenzo": ['D IH0 K R EH0 S CH EH1 N Z OW0'],
  "decried": ['D IH0 K R AY1 D'],
  "decries": ['D IH0 K R AY1 Z'],
  "decriminalization": ['D IY0 K R IH2 M AH0 N AH0 L AH0 Z EY1 SH AH0 N'],
  "decriminalize": ['D IY0 K R IH2 M AH0 N AH0 L AY1 Z'],
  "decriminalizing": ['D IY0 K R IH2 M AH0 N AH0 L AY1 Z IH0 NG'],
  "decristofaro": ['D IH0 K R IY0 S T OW0 F AA1 R OW0'],
  "decry": ['D IH0 K R AY1'],
  "decrying": ['D IH0 K R AY1 IH0 NG'],
  "decter": ['D EH1 K T ER0'],
  "decuir": ['D EH1 K IH0 R'],
  "decurtis": ['D IY0 K ER1 T AH0 S'],
  "decworld": ['D EH1 K W ER0 L D'],
  "dede": ['D IY1 D'],
  "dedeaux": ['D IH0 D OW1'],
  "dedecker": ['D EH1 D IH0 K ER0'],
  "dederichs": ['D EH1 D R IH0 K S'],
  "dederick": ['D EH1 D ER0 IH0 K'],
  "dedeurwaerder": ['D AH0 D ER1 W AA2 R D ER0'],
  "dedham": ['D EH1 D AH0 M'],
  "dedic": ['D EH1 D IH0 K'],
  "dedicate": ['D EH1 D AH0 K EY2 T'],
  "dedicated": ['D EH1 D AH0 K EY0 T AH0 D'],
  "dedicates": ['D EH1 D IH0 K EY2 T S'],
  "dedicating": ['D EH1 D IH0 K EY2 T IH0 NG'],
  "dedication": ['D EH2 D AH0 K EY1 SH AH0 N'],
  "dedios": ['D EY0 D IY1 OW0 Z'],
  "dedman": ['D EH1 D M AH0 N'],
  "dedmon": ['D EH1 D M AH0 N'],
  "dedo": ['D EY1 D OW0'],
  "dedominicis": ['D EY0 D OW0 M IY0 N IY1 S IH0 S'],
  "dedrick": ['D EH1 D R IH0 K'],
  "deduce": ['D IH0 D UW1 S'],
  "deduced": ['D IH0 D UW1 S T'],
  "deduct": ['D IH0 D AH1 K T'],
  "deducted": ['D IH0 D AH1 K T IH0 D'],
  "deductibility": ['D IH0 D AH2 K T AH0 B IH1 L AH0 T IY0'],
  "deductible": ['D IH0 D AH1 K T AH0 B AH0 L'],
  "deductibles": ['D IH0 D AH1 K T AH0 B AH0 L Z'],
  "deducting": ['D IH0 D AH1 K T IH0 NG'],
  "deduction": ['D IH0 D AH1 K SH AH0 N'],
  "deductions": ['D IH0 D AH1 K SH AH0 N Z'],
  "deductive": ['D IH0 D AH1 K T AH0 V'],
  "deductively": ['D IH0 D AH1 K T AH0 V L IY0'],
  "deducts": ['D IH0 D AH1 K T S'],
  "dee": ['D IY1'],
  "dee's": ['D IY1 Z'],
  "deeb": ['D IY1 B'],
  "deed": ['D IY1 D'],
  "deeded": ['D IY1 D AH0 D', 'D IY1 D IH0 D'],
  "deedee": ['D IY1 D IY1'],
  "deedrick": ['D IY1 D R IH0 K'],
  "deedrick's": ['D IY1 D R IH0 K S'],
  "deeds": ['D IY1 D Z'],
  "deedy": ['D IY1 D IY0'],
  "deeg": ['D IY1 G'],
  "deegan": ['D IY1 G AH0 N'],
  "deehan": ['D IY1 HH AE2 N', 'D IY1 AH0 N'],
  "deel": ['D IY1 L'],
  "deeley": ['D IY1 L IY0'],
  "deely": ['D IY1 L IY0'],
  "deem": ['D IY1 M'],
  "deemed": ['D IY1 M D'],
  "deemer": ['D IY1 M ER0'],
  "deemphasize": ['D IY0 EH1 M F AH0 S AY2 Z'],
  "deemphasizing": ['D IY0 EH1 M F AH0 S AY2 Z IH0 NG'],
  "deems": ['D IY1 M Z'],
  "deen": ['D IY1 N'],
  "deener": ['D IY1 N ER0'],
  "deeney": ['D IY1 N IY0'],
  "deep": ['D IY1 P'],
  "deepak": ['D IY1 P AE2 K'],
  "deepen": ['D IY1 P AH0 N'],
  "deepened": ['D IY1 P AH0 N D'],
  "deepening": ['D IY1 P AH0 N IH0 NG', 'D IY1 P N IH0 NG'],
  "deepens": ['D IY1 P AH0 N Z'],
  "deeper": ['D IY1 P ER0'],
  "deepest": ['D IY1 P AH0 S T'],
  "deeply": ['D IY1 P L IY0'],
  "deepwater": ['D IY1 P W AO2 T ER0'],
  "deer": ['D IH1 R'],
  "deerborne": ['D IH1 R B AO0 R N'],
  "deere": ['D IH1 R'],
  "deere's": ['D IH1 R Z'],
  "deerfield": ['D IH1 R F IY0 L D'],
  "deering": ['D IH1 R IH0 NG'],
  "deerman": ['D IH1 R M AH0 N'],
  "deerskin": ['D IH1 R S K IH2 N'],
  "deery": ['D IH1 R IY0'],
  "dees": ['D IY1 Z'],
  "deese": ['D IY1 Z'],
  "deet": ['D IY1 T'],
  "deeter": ['D IY1 T ER0'],
  "deets": ['D IY1 T S'],
  "deetz": ['D IY1 T S'],
  "defabio": ['D IH0 F AA1 B IY0 OW0'],
  "deface": ['D IH0 F EY1 S'],
  "defaced": ['D IH0 F EY1 S T'],
  "defacing": ['D IH0 F EY1 S IH0 NG'],
  "defalco": ['D IH0 F AA1 L K OW0'],
  "defamation": ['D EH2 F AH0 M EY1 SH AH0 N'],
  "defamatory": ['D IH0 F AE1 M AH0 T AO2 R IY0'],
  "defame": ['D IH0 F EY1 M'],
  "defamed": ['D IH0 F EY1 M D'],
  "defarges": ['D IH0 F AA1 R JH IH0 Z'],
  "default": ['D IH0 F AO1 L T'],
  "defaulted": ['D IH0 F AO1 L T IH0 D'],
  "defaulter": ['D IH0 F AO1 L T ER0'],
  "defaulters": ['D IH0 F AO1 L T ER0 Z'],
  "defaulting": ['D IH0 F AO1 L T IH0 NG'],
  "defaults": ['D IH0 F AO1 L T S'],
  "defazio": ['D IH0 F AA1 Z IY0 OW0', 'D IH0 F EY1 Z IY0 OW0'],
  "defcon": ['D EH0 F K AA1 N'],
  "defeasance": ['D IH0 F IY1 Z AH0 N S'],
  "defeat": ['D IH0 F IY1 T'],
  "defeated": ['D IH0 F IY1 T AH0 D', 'D IH0 F IY1 T IH0 D'],
  "defeating": ['D IH0 F IY1 T IH0 NG'],
  "defeatism": ['D IH0 F IY1 T IH0 Z AH0 M'],
  "defeatist": ['D IH0 F IY1 T IH0 S T'],
  "defeats": ['D IH0 F IY1 T S'],
  "defect": ['D IY1 F EH0 K T', 'D IH0 F EH1 K T'],
  "defected": ['D IH0 F EH1 K T IH0 D'],
  "defecting": ['D IH0 F EH1 K T IH0 NG'],
  "defection": ['D IH0 F EH1 K SH AH0 N'],
  "defections": ['D IH0 F EH1 K SH AH0 N Z'],
  "defective": ['D IH0 F EH1 K T IH0 V'],
  "defector": ['D IH0 F EH1 K T ER0'],
  "defectors": ['D IH0 F EH1 K T ER0 Z'],
  "defects": ['D IY1 F EH0 K T S', 'D IH0 F EH1 K T S'],
  "defee": ['D EH1 F IY0'],
  "defelice": ['D IH0 F EH1 L IH0 S'],
  "defenbaugh": ['D EH1 F IH0 N B AW0'],
  "defence": ['D IH0 F EH1 N S'],
  "defend": ['D IH0 F EH1 N D'],
  "defendant": ['D IH0 F EH1 N D AH0 N T'],
  "defendant's": ['D IH0 F EH1 N D AH0 N T S'],
  "defendants": ['D IH0 F EH1 N D AH0 N T S'],
  "defendants'": ['D IH0 F EH1 N D AH0 N T S'],
  "defended": ['D IH0 F EH1 N D AH0 D', 'D IH0 F EH1 N D IH0 D'],
  "defender": ['D IH0 F EH1 N D ER0'],
  "defender's": ['D IH0 F EH1 N D ER0 Z'],
  "defenders": ['D IH0 F EH1 N D ER0 Z'],
  "defending": ['D IH0 F EH1 N D IH0 NG'],
  "defends": ['D IH0 F EH1 N D Z'],
  "defenestration": ['D IY0 F EH2 N EH0 S T R EY1 SH AH0 N'],
  "defense": ['D IH0 F EH1 N S'],
  "defense's": ['D IH0 F EH1 N S IH0 Z'],
  "defenseless": ['D IH0 F EH1 N S L AH0 S'],
  "defenses": ['D IH0 F EH1 N S AH0 Z', 'D IH0 F EH1 N S IH0 Z'],
  "defensible": ['D IH0 F EH1 N S AH0 B AH0 L'],
  "defensive": ['D IH0 F EH1 N S IH0 V'],
  "defensively": ['D IH0 F EH1 N S IH0 V L IY0'],
  "defensiveness": ['D IH0 F EH1 N S IH0 V N AH0 S'],
  "defeo": ['D IY1 F IY0 OW0'],
  "defer": ['D IH0 F ER1'],
  "deference": ['D EH1 F ER0 AH0 N S', 'D EH1 F R AH0 N S'],
  "deferential": ['D EH2 F ER0 EH1 N CH AH0 L', 'D EH2 F ER0 EH1 N SH AH0 L'],
  "deferment": ['D IH0 F ER1 M AH0 N T'],
  "deferments": ['D IH0 F ER1 M AH0 N T S'],
  "deferral": ['D IH0 F ER1 AH0 L'],
  "deferrals": ['D IH0 F ER1 AH0 L Z'],
  "deferred": ['D IH0 F ER1 D'],
  "deferring": ['D IH0 F ER1 IH0 NG'],
  "defers": ['D IH0 F ER1 Z'],
  "defex": ['D EH1 F EH2 K S'],
  "deffenbaugh": ['D EH1 F IH0 N B AW0'],
  "deffeyes": ['D EH0 F AY1 Z'],
  "defiance": ['D IH0 F AY1 AH0 N S'],
  "defiant": ['D IH0 F AY1 AH0 N T'],
  "defiantly": ['D IH0 F AY1 AH0 N T L IY0'],
  "defibaugh": ['D EH1 F IH0 B AO2'],
  "defibrillator": ['D IY0 F IH1 B R IH0 L EY2 T ER0'],
  "defibrillators": ['D IY0 F IH1 B R IH0 L EY2 T ER0 Z'],
  "deficiencies": ['D IH0 F IH1 SH AH0 N S IY0 Z'],
  "deficiency": ['D IH0 F IH1 SH AH0 N S IY0'],
  "deficient": ['D IH0 F IH1 SH AH0 N T'],
  "deficit": ['D EH1 F AH0 S AH0 T'],
  "deficit's": ['D EH1 F AH0 S AH0 T S'],
  "deficits": ['D EH1 F IH0 S IH0 T S'],
  "defied": ['D IH0 F AY1 D'],
  "defies": ['D IH0 F AY1 Z'],
  "defilippis": ['D EH1 F IH0 L IH0 P IH0 S', 'D AH0 F AH0 L IH1 P AH0 S'],
  "defilippo": ['D IH0 F IY0 L IY1 P OW0'],
  "defina": ['D IH0 F IY1 N AH0'],
  "definable": ['D IH0 F AY1 N AH0 B AH0 L'],
  "define": ['D IH0 F AY1 N'],
  "defined": ['D IH0 F AY1 N D'],
  "defines": ['D IH0 F AY1 N Z'],
  "defining": ['D IH0 F AY1 N IH0 NG'],
  "definite": ['D EH1 F AH0 N AH0 T'],
  "definitely": ['D EH1 F AH0 N AH0 T L IY0'],
  "definition": ['D EH2 F AH0 N IH1 SH AH0 N'],
  "definitions": ['D EH2 F AH0 N IH1 SH AH0 N Z'],
  "definitive": ['D IH0 F IH1 N IH0 T IH0 V'],
  "definitively": ['D IH0 F IH1 N IH0 T IH0 V L IY0'],
  "defino": ['D IH0 F IY1 N OW0'],
  "defiore": ['D IH0 F IY0 AO1 R IY0'],
  "deflate": ['D IH0 F L EY1 T'],
  "deflated": ['D IH0 F L EY1 T IH0 D'],
  "deflating": ['D IH0 F L EY1 T IH0 NG'],
  "deflation": ['D IH0 F L EY1 SH AH0 N'],
  "deflationary": ['D IH0 F L EY1 SH AH0 N EH2 R IY0'],
  "deflator": ['D IH0 F L EY1 T ER0'],
  "deflect": ['D IH0 F L EH1 K T'],
  "deflected": ['D IH0 F L EH1 K T IH0 D'],
  "deflecting": ['D IH0 F L EH1 K T IH0 NG'],
  "deflection": ['D IH0 F L EH1 K SH AH0 N'],
  "deflector": ['D IH0 F L EH1 K T ER0'],
  "deflectors": ['D IH0 F L EH1 K T ER0 Z'],
  "deflects": ['D IH0 F L EH1 K T S'],
  "defleur": ['D IH0 F L ER1'],
  "defoe": ['D IH0 F OW1'],
  "defoe's": ['D IH0 F OW1 Z'],
  "defoliant": ['D IH0 F OW1 L IY0 AH0 N T'],
  "defoliants": ['D IH0 F OW1 L IY0 AH0 N T S'],
  "defollet": ['D AH0 F AO1 L AH0 T'],
  "defollets": ['D AH0 F AO1 L AH0 T S'],
  "defoor": ['D EH1 F UH0 R'],
  "deford": ['D EH1 F ER0 D'],
  "deford's": ['D EH1 F ER0 D Z'],
  "defore": ['D IY1 F AO0 R'],
  "deforest": ['D IH0 F AO1 R AH0 S T'],
  "deforestation": ['D IH0 F AO2 R IH0 S T EY1 SH AH0 N'],
  "deforge": ['D EH1 F ER0 G'],
  "deform": ['D IY2 F AO1 R M'],
  "deformation": ['D IY2 F AO0 R M EY1 SH AH0 N'],
  "deformed": ['D IH0 F AO1 R M D'],
  "deformities": ['D IH0 F AO1 R M AH0 T IY0 Z'],
  "deformity": ['D IH0 F AO1 R M AH0 T IY0'],
  "deforrest": ['D EY0 F AO1 R IH0 S T', 'D IH0 F AO1 R IH0 S T'],
  "defrain": ['D IH0 F R EY1 N'],
  "defrance": ['D IY1 F R AH0 N S'],
  "defrancesco": ['D IH0 F R AA0 N CH EH1 S K OW0'],
  "defrancisco": ['D IH0 F R AA0 N CH IY1 S K OW0'],
  "defranco": ['D IH0 F R AA1 N K OW0'],
  "defrank": ['D EH1 F R AH0 NG K'],
  "defrates": ['D EH1 F ER0 EY0 T S'],
  "defraud": ['D IH0 F R AO1 D'],
  "defrauded": ['D IH0 F R AO1 D IH0 D'],
  "defrauding": ['D IH0 F R AO1 D IH0 NG'],
  "defray": ['D IH0 F R EY1'],
  "defrays": ['D IH0 F R EY1 Z'],
  "defrees": ['D IH0 F R IY1 Z'],
  "defreese": ['D EH1 F R IY0 S'],
  "defreitas": ['D EH1 F R AY0 T AH0 Z'],
  "defries": ['D IH0 F R IY1 Z'],
  "defrost": ['D IH0 F R AO1 S T'],
  "defrosting": ['D IH0 F R AO1 S T IH0 NG'],
  "deft": ['D EH1 F T'],
  "defterios": ['D EH2 F T EH1 R IY0 OW0 Z'],
  "deftly": ['D EH1 F T L IY0'],
  "defunct": ['D IH0 F AH1 NG K T'],
  "defund": ['D IY0 F AH1 N D'],
  "defunding": ['D IY0 F AH1 N D IH0 NG'],
  "defusco": ['D IH0 F UW1 S K OW0'],
  "defuse": ['D IH0 F Y UW1 Z'],
  "defused": ['D IH0 F Y UW1 Z D', 'D IY0 F Y UW1 Z D'],
  "defusing": ['D IH0 F Y UW1 Z IH0 NG'],
  "defy": ['D IH0 F AY1'],
  "defying": ['D IH0 F AY1 IH0 NG'],
  "degaetano": ['D IH0 G AA0 EH0 T AA1 N OW0'],
  "degan": ['D IY1 G AH0 N'],
  "degarmo": ['D IH0 G AA1 R M OW0'],
  "degas": ['D EY1 G AH0 S', 'D EY1 G AH0'],
  "degaulle": ['D AH0 G AA1 L'],
  "degaulle's": ['D AH0 G AA1 L Z'],
  "degen": ['D EH1 G AH0 N'],
  "degener": ['D EH1 G IY0 N ER0'],
  "degeneracy": ['D IH0 JH EH1 N ER0 AH0 S IY0'],
  "degenerate": ['D IH0 JH EH1 N ER0 AH0 T', 'D IH0 JH EH1 N ER0 EY2 T'],
  "degenerated": ['D IH0 JH EH1 N ER0 EY2 T IH0 D'],
  "degenerates": ['D IH0 JH EH1 N ER0 AH0 T S'],
  "degenerating": ['D IH0 JH EH1 N ER0 EY2 T IH0 NG'],
  "degeneration": ['D IH0 JH EH2 N ER0 EY1 SH AH0 N'],
  "degenerative": ['D IH0 JH EH1 N ER0 AH0 T IH0 V'],
  "degeneres": ['D IH0 JH IH0 N EH1 R EH0 S', 'D IY0 JH IH0 N EH1 R EH0 S'],
  "degenhardt": ['D EH1 G IH0 N HH AA0 R T'],
  "degenhart": ['D EH1 G AH0 N HH AA2 R T'],
  "degennaro": ['D IH0 JH EH0 N AA1 R OW0'],
  "degeorge": ['D EH1 G IY0 ER0 G'],
  "deger": ['D IY1 G ER0'],
  "degiacomo": ['D IY1 JH AH0 K OW0 M OW0'],
  "degidio": ['D IH0 JH IY1 D IY0 OW0'],
  "degirolamo": ['D IH0 JH IH0 R OW0 L AA1 M OW0'],
  "degler": ['D EH1 G L ER0'],
  "degnan": ['D EH1 G N AH0 N'],
  "degner": ['D EH1 G N ER0'],
  "degood": ['D EH1 G UH0 D'],
  "degraaf": ['D EH1 G R AA0 F'],
  "degrace": ['D IH0 G R AA1 CH IY0', 'D IH0 G R EY1 S'],
  "degradable": ['D IH0 G R EY1 D AH0 B AH0 L'],
  "degradation": ['D EH2 G R AH0 D EY1 SH AH0 N'],
  "degradations": ['D EH2 G R AH0 D EY1 SH AH0 N Z'],
  "degrade": ['D IH0 G R EY1 D'],
  "degraded": ['D IH0 G R EY1 D AH0 D', 'D IH0 G R EY1 D IH0 D'],
  "degrades": ['D IH0 G R EY1 D Z'],
  "degrading": ['D IH0 G R EY1 D IH0 NG'],
  "degraff": ['D EH1 G R AH0 F'],
  "degraffenreid": ['D EH1 G R AH0 F IH0 N R AY0 D'],
  "degrand": ['D EH1 G R AE0 N D'],
  "degrange": ['D EH1 G R EY0 N JH'],
  "degrasse": ['D IH0 G R AA1 S IY0'],
  "degrave": ['D IH0 G R AA1 V IY0'],
  "degraw": ['D EH1 G R AO0'],
  "degray": ['D EH1 G R EY0'],
  "degrazia": ['D IH0 G R AA1 Z IY0 AH0'],
  "degree": ['D IH0 G R IY1'],
  "degreed": ['D IH0 G R IY1 D'],
  "degrees": ['D IH0 G R IY1 Z'],
  "degregorio": ['D IH0 G R EH0 G AO1 R IY0 OW0'],
  "degregory": ['D EH1 G R IH0 G ER0 IY0'],
  "degroat": ['D EH1 G R OW0 T'],
  "degroff": ['D EH1 G R AO0 F'],
  "degroot": ['D EH1 G R UW0 T'],
  "degroote": ['D AH0 G R UW1 T'],
  "deguerin": ['D IH0 G ER1 IH0 N'],
  "deguire": ['D EY0 G W IH1 R EY0'],
  "degussa": ['D IH0 G Y UW1 S AH0'],
  "degutare": ['D EH2 G UW0 T AA1 R IY0'],
  "degutare's": ['D EH2 G UW0 T AA1 R IY0 Z'],
  "deguzman": ['D EY0 G UW0 Z M AE1 N'],
  "dehaan": ['D EH1 HH AA0 N'],
  "dehaas": ['D EH1 HH AA0 Z'],
  "deharbe": ['D AH0 HH AA1 R B IY0'],
  "dehart": ['D EH1 HH AA0 R T'],
  "dehaven": ['D EH1 HH AH0 V AH0 N'],
  "dehecq": ['D IH0 HH EH1 K'],
  "dehere": ['D AH0 HH IH1 R IY0'],
  "deherrera": ['D EY0 HH EH0 R EH1 R AH0'],
  "dehler": ['D EH1 L ER0'],
  "dehm": ['D EH1 M'],
  "dehmer": ['D EH1 M ER0'],
  "dehn": ['D EH1 N'],
  "dehne": ['D EH1 N'],
  "dehner": ['D EH1 N ER0'],
  "dehnert": ['D EH1 N ER0 T'],
  "dehoff": ['D EH1 HH AO0 F'],
  "dehoyos": ['D EH1 HH OY0 OW0 Z'],
  "dehumanization": ['D IY2 HH Y UW2 M AH0 N AH0 Z EY1 SH AH0 N'],
  "dehumanize": ['D IH0 HH Y UW1 M AH0 N AY0 Z'],
  "dehumanized": ['D IH0 HH Y UW1 M AH0 N AY0 Z D'],
  "dehumanizing": ['D IY0 HH Y UW1 M AH0 N AY2 Z IH0 NG'],
  "dehumidified": ['D IY0 HH Y UW0 M IH1 D AH0 F AY0 D'],
  "dehumidifier": ['D IY0 HH Y UW0 M IH1 D AH0 F AY0 ER0'],
  "dehumidifies": ['D IY0 HH Y UW0 M IH1 D AH0 F AY0 Z'],
  "dehumidify": ['D IY0 HH Y UW0 M IH1 D AH0 F AY0'],
  "dehydrate": ['D IH0 HH AY1 D R EY0 T'],
  "dehydrated": ['D IH0 HH AY1 D R EY0 T AH0 D'],
  "dehydration": ['D IY2 HH AY0 D R EY1 SH AH0 N'],
  "deibel": ['D AY1 B AH0 L'],
  "deibert": ['D AY1 B ER0 T'],
  "deibler": ['D AY1 B AH0 L ER0', 'D AY1 B L ER0'],
  "deichert": ['D AY1 K ER0 T'],
  "deidre": ['D IY1 D R AH0'],
  "deidre's": ['D IY1 D R AH0 Z'],
  "deification": ['D IY2 AH0 F AH0 K EY1 SH AH0 N'],
  "deified": ['D IY1 AH0 F AY2 D'],
  "deify": ['D IY1 AH0 F AY2'],
  "deighan": ['D EY1 G AH0 N'],
  "deighton": ['D EY1 T AH0 N'],
  "deign": ['D EY1 N'],
  "deignan": ['D AH0 G N AE1 N'],
  "deigned": ['D EY1 N D'],
  "deigning": ['D EY1 N IH0 NG'],
  "deigns": ['D EY1 N Z'],
  "deihl": ['D AY1 L'],
  "deike": ['D IY1 K'],
  "deikel": ['D AY1 K AH0 L'],
  "deily": ['D IY1 L IY0'],
  "deines": ['D IY1 N Z'],
  "deininger": ['D AY1 N IH0 NG ER0'],
  "deinstitutionalization": ['D IY2 IH2 N S T IH0 T UW2 SH AH0 N AH0 L AH0 Z EY1 SH AH0 N'],
  "deinstitutionalize": ['D IY2 IH2 N S T IH0 T UW2 SH AH0 N AH0 L AY2 Z'],
  "deion": ['D IY1 Y AA0 N', 'D IY1 AA0 N'],
  "deirdre": ['D IY1 R D R AH0'],
  "deis": ['D IY1 Z', 'D EY1 IH0 Z'],
  "deisher": ['D IY1 IH0 SH ER0'],
  "deism": ['D IY1 IH0 Z AH0 M'],
  "deiss": ['D AY1 S'],
  "deist": ['D IY1 IH0 S T'],
  "deitch": ['D AY1 CH'],
  "deiter": ['D AY1 T ER0'],
  "deiters": ['D AY1 T ER0 Z'],
  "deities": ['D IY1 AH0 T IY0 Z'],
  "deitrich": ['D AY1 T R IH0 K'],
  "deitrick": ['D AY1 T R IH0 K'],
  "deitsch": ['D AY1 CH'],
  "deity": ['D IY1 AH0 T IY0'],
  "deitz": ['D IY1 T S'],
  "deja": ['D IY1 JH AH0', 'D EY1 ZH AA2'],
  "dejager": ['D EH1 JH EY0 G ER0'],
  "dejarnett": ['D IH0 JH AA1 R N IH0 T'],
  "dejarnette": ['D EH1 ZH AA0 R N EH0 T'],
  "dejean": ['D IH0 ZH IY1 N'],
  "deject": ['D IH0 JH EH1 K T'],
  "dejected": ['D IH0 JH EH1 K T IH0 D'],
  "dejesus": ['D IH0 JH IY1 Z AH0 S'],
  "dejohn": ['D AH0 JH AA1 N'],
  "dejong": ['D AH0 JH AO1 NG'],
  "dejonge": ['D AH0 JH AO1 NG'],
  "dejongh": ['D AH0 JH AO1 NG'],
  "dejoseph": ['D AH0 JH OW1 S AH0 F'],
  "dejoy": ['D AH0 JH OY1'],
  "dejulio": ['D AH0 JH UW1 L IY0 OW0'],
  "dekalb": ['D IH0 K AE1 L B'],
  "dekay": ['D AH0 K EY1'],
  "dekeyser": ['D AH0 K AY1 Z ER0'],
  "dekker": ['D EH1 K ER0'],
  "dekle": ['D EH1 K AH0 L'],
  "deklerk": ['D AH0 K L ER1 K'],
  "deklerk's": ['D AH0 K L ER1 K S'],
  "dekom": ['D EH1 K AH0 M'],
  "dekoning": ['D EH1 K AH0 N IH0 NG'],
  "dekroon": ['D EH2 K R UW1 N'],
  "dekuyper": ['D IH0 K AY1 P ER0'],
  "del": ['D EH1 L'],
  "del-campos": ['D EH1 L K AE1 M P OW0 Z'],
  "dela": ['D EH1 L AH0'],
  "delacerda": ['D EH0 L AA0 CH EH1 R D AH0'],
  "delacroix": ['D AH0 L AH0 K R W AH0', 'D AH0 L AH0 K R OY0'],
  "delacruz": ['D EY0 L AA1 K R UW0 Z'],
  "delacy": ['D AH0 L AO1 S IY0'],
  "delafield": ['D EH1 L AH0 F IY2 L D'],
  "delafuente": ['D EY0 L AA0 F W EH1 N T EY0'],
  "delagarza": ['D EH0 L AA0 G AA1 R Z AH0'],
  "delage": ['D EH1 L IH0 JH'],
  "delagrange": ['D EH0 L AA1 G R AA0 N JH'],
  "delahanty": ['D EH1 L AH0 HH AH0 N T IY0'],
  "delahoussaye": ['D EH0 L AH0 HH AW1 S EY0'],
  "delahunt": ['D EH1 L AH0 HH AH0 N T'],
  "delahunty": ['D EH1 L AH0 HH AH0 N T IY0'],
  "delaine": ['D IH0 L EY1 N'],
  "delair": ['D IH0 L EH1 R'],
  "delamar": ['D EY0 L AA0 M AA1 R'],
  "delamater": ['D EH1 L AH0 M EY0 T ER0'],
  "delancey": ['D EH1 L AH0 N S IY0'],
  "delancy": ['D EH1 L AH0 N S IY0'],
  "deland": ['D IH0 L AE1 N D'],
  "delane": ['D EH1 L AH0 N'],
  "delaney": ['D AH0 L EY1 N IY0'],
  "delange": ['D EH1 L EY0 N JH'],
  "delano": ['D IH0 L AA1 N OW0', 'D EH1 L AH0 N OW0'],
  "delanoy": ['D EH1 L AH0 N OY0'],
  "delany": ['D EH1 L AH0 N IY0'],
  "delao": ['D EH1 L AW0'],
  "delap": ['D EH1 L AH0 P'],
  "delapaz": ['D EY0 L AA1 P AA0 Z'],
  "delapena": ['D EH0 L AA0 P EH1 N AH0'],
  "delapp": ['D EH1 L AH0 P'],
  "delara": ['D EH0 L AA1 R AH0'],
  "delariva": ['D EH0 L AA0 R IY1 V AH0'],
  "delarosa": ['D EH0 L AA0 R OW1 S AH0'],
  "delashmit": ['D EH1 L AH0 SH M IH0 T'],
  "delashmutt": ['D EH1 L AH0 SH M AH0 T'],
  "delaski": ['D AH0 L AE1 S K IY0'],
  "delatorre": ['D EH0 L AA0 T AO1 R IY0'],
  "delatte": ['D IH0 L AE1 T'],
  "delauder": ['D EH1 L AW0 D ER0'],
  "delaughter": ['D EH1 L AO0 T ER0'],
  "delaune": ['D EH1 L AO0 N'],
  "delaura": ['D EH0 L AO1 R AH0'],
  "delaurel": ['D AH0 L AA1 R AH0 L'],
  "delaurentiis": ['D IY0 L AO0 R EH1 N T IY2 Z'],
  "delaurentis": ['D EY0 L AW0 R EY1 N T IH0 S'],
  "delauro": ['D EH0 L AO1 R OW0'],
  "delauter": ['D EH1 L AW0 T ER0'],
  "delaval": ['D EH1 L AH0 V AE0 L'],
  "delavan": ['D EH1 L AH0 V AH0 N'],
  "delavega": ['D EY0 L AA0 V EY1 G AH0'],
  "delaware": ['D EH1 L AH0 W EH2 R'],
  "delaware's": ['D EH1 L AH0 W EH2 R Z'],
  "delawarian": ['D EH1 L AH0 W EH2 R IY0 IH0 N'],
  "delawarians": ['D EH1 L AH0 W EH2 R IY0 IH0 N Z'],
  "delawder": ['D EH1 L AO0 D ER0'],
  "delay": ['D IH0 L EY1'],
  "delayed": ['D IH0 L EY1 D'],
  "delaying": ['D IH0 L EY1 IH0 NG'],
  "delays": ['D IH0 L EY1 Z'],
  "delbarco": ['D EH0 L B AA1 R K OW0'],
  "delbarco's": ['D EH0 L B AA1 R K OW0 Z'],
  "delbene": ['D EH1 L B IH0 N AH0'],
  "delbert": ['D EH1 L B ER0 T'],
  "delbianco": ['D EH0 L B IY0 AA1 N K OW0'],
  "delbosque": ['D IH0 L B OW1 S K'],
  "delbridge": ['D EH1 L B R IH0 JH'],
  "delbuono": ['D EH2 L B W OW1 N OW0'],
  "delcambre": ['D EH0 L K AA1 M B R IY0'],
  "delcamp": ['D EH1 L K AE0 M P'],
  "delcampo": ['D EH0 L K AA1 M P OW0'],
  "delcarlo": ['D EH2 L K AA1 R L OW0'],
  "delcastillo": ['D EH0 L K AA0 S T IH1 L OW0'],
  "delchamps": ['D EH2 L CH AE1 M P S'],
  "delcine": ['D EH0 L CH IY1 N IY0'],
  "delco": ['D EH1 L K OW0'],
  "delconte": ['D EH0 L K OW1 N T IY0'],
  "delcor": ['D EH1 L K AO2 R'],
  "delduca": ['D EH2 L D UW1 K AH0'],
  "delebarre": ['D EH2 L AH0 B AA1 R'],
  "delectable": ['D IH0 L EH1 K T AH0 B AH0 L'],
  "delee": ['D EH1 L IY0'],
  "deleeuw": ['D EH1 L IY0 UW0'],
  "delegate": ['D EH1 L AH0 G EY2 T', 'D EH1 L AH0 G AH0 T'],
  "delegate's": ['D EH1 L IH0 G AH0 T S'],
  "delegated": ['D EH1 L AH0 G EY2 T AH0 D'],
  "delegates": ['D EH1 L AH0 G EY2 T S', 'D EH1 L AH0 G AH0 T S'],
  "delegates'": ['D EH2 L AH0 G EY1 T S'],
  "delegating": ['D EH1 L AH0 G EY2 T IH0 NG'],
  "delegation": ['D EH2 L AH0 G EY1 SH AH0 N'],
  "delegation's": ['D EH2 L AH0 G EY1 SH AH0 N Z'],
  "delegations": ['D EH2 L AH0 G EY1 SH AH0 N Z'],
  "delehanty": ['D EH1 L IH0 HH AH0 N T IY0'],
  "delellis": ['D EH1 L IH0 L IH0 S', 'D IH0 L EH1 L IH0 S'],
  "deleo": ['D EH1 L IY0 OW0'],
  "deleon": ['D EH1 L IY0 AH0 N'],
  "deleonardis": ['D EH1 L IY0 AH0 N AA2 R D IH0 S'],
  "delete": ['D IH0 L IY1 T'],
  "deleted": ['D IH0 L IY1 T AH0 D'],
  "deleterious": ['D EH2 L AH0 T IH1 R IY0 AH0 S'],
  "deletes": ['D IH0 L IY1 T S'],
  "deleting": ['D IH0 L IY1 T IH0 NG'],
  "deletion": ['D IH0 L IY1 SH AH0 N'],
  "deletions": ['D IH0 L IY1 SH AH0 N Z'],
  "delfavero": ['D EH0 L F AA0 V EH1 R OW0'],
  "delfin": ['D EH1 L F IH0 N'],
  "delfina": ['D EH2 L F IY1 N AH0'],
  "delfine": ['D EH0 L F IY1 N IY0'],
  "delfino": ['D EH2 L F IY1 N OW0'],
  "delfosse": ['D EH1 L F AH0 S'],
  "delfs": ['D EH1 L F S'],
  "delft": ['D EH1 L F T'],
  "delftware": ['D EH1 L F T W EH2 R'],
  "delgadillo": ['D EH0 L G AA0 D IH1 L OW0'],
  "delgadio": ['D EH0 L G AE1 D Y OW0', 'D EH0 L G AA1 D Y OW0'],
  "delgado": ['D EH0 L G AA1 D OW0'],
  "delgaudio": ['D EH0 L G AO1 D IY0 OW0'],
  "delgiorno": ['D EH0 L JH AO1 R N OW0'],
  "delgiudice": ['D EH0 L JH UW1 D AH0 S'],
  "delgrande": ['D EH1 L G R AE0 N D IY0'],
  "delgreco": ['D EH2 L G R EH1 K OW0'],
  "delgrosso": ['D EH2 L G R OW1 S OW0'],
  "delguercio": ['D EH0 L G EH1 R CH IY0 OW0'],
  "delguidice": ['D EH0 L G AY1 D IH0 S'],
  "delhaize": ['D EH2 L HH EY1 Z'],
  "delhi": ['D EH1 L IY0'],
  "delhi's": ['D EH1 L IY0 Z'],
  "deli": ['D EH1 L IY0'],
  "delia": ['D IY1 L Y AH0'],
  "delial": ['D IH0 L AY1 EH0 L', 'D IY0 L AY1 EH0 L'],
  "deliberate": ['D IH0 L IH1 B ER0 AH0 T', 'D IH0 L IH1 B ER0 EY2 T', 'D IH0 L IH1 B R AH0 T'],
  "deliberated": ['D IH0 L IH1 B ER0 EY2 T IH0 D'],
  "deliberately": ['D IH0 L IH1 B ER0 AH0 T L IY0', 'D IH0 L IH1 B R AH0 T L IY0'],
  "deliberates": ['D IH0 L IH1 B ER0 EY2 T S', 'D IH0 L IH1 B R EY0 T S'],
  "deliberating": ['D IH0 L IH1 B ER0 EY2 T IH0 NG'],
  "deliberation": ['D IH0 L IH2 B ER0 EY1 SH AH0 N'],
  "deliberations": ['D IH0 L IH2 B ER0 EY1 SH AH0 N Z'],
  "deliberative": ['D IH0 L IH1 B ER0 EY2 T IH0 V', 'D IH0 L IH1 B R AH0 T IH0 V'],
  "delicacies": ['D EH1 L IH0 K AH0 S IY0 Z'],
  "delicacy": ['D EH1 L AH0 K AH0 S IY0', 'D EH1 L IH0 K AH0 S IY0'],
  "delicate": ['D EH1 L AH0 K AH0 T'],
  "delicately": ['D EH1 L AH0 K AH0 T L IY0'],
  "delicatessen": ['D EH2 L IH0 K AH0 T EH1 S AH0 N'],
  "delicatessens": ['D EH2 L IH0 K AH0 T EH1 S AH0 N Z'],
  "delich": ['D EH1 L IH0 K'],
  "delicia": ['D EH0 L IY1 CH AH0'],
  "delicious": ['D IH0 L IH1 SH AH0 S'],
  "deliciously": ['D IH0 L IH1 SH AH0 SH L IY0'],
  "delight": ['D IH0 L AY1 T'],
  "delighted": ['D IH0 L AY1 T AH0 D', 'D IH0 L AY1 T IH0 D'],
  "delightful": ['D IH0 L AY1 T F AH0 L'],
  "delightfully": ['D IH0 L AY1 T F AH0 L IY0'],
  "delighting": ['D IH0 L AY1 T IH0 NG'],
  "delights": ['D IH0 L AY1 T S'],
  "delila": ['D EH0 L IY1 L AH0'],
  "delilah": ['D AH0 L AY1 L AH0', 'D IH0 L AY1 L AH0'],
  "delillo": ['D AH0 L IH1 L OW0'],
  "delima": ['D EH0 L IY1 M AH0'],
  "deline": ['D EH0 L IY1 N IY0'],
  "delineate": ['D IH0 L IH1 N IY0 EY2 T'],
  "delineated": ['D IH0 L IH1 N IY0 EY2 T IH0 D'],
  "delineates": ['D IH0 L IH1 N IY0 EY2 T S'],
  "delineating": ['D IH0 L IH1 N IY0 EY2 T IH0 NG'],
  "delineation": ['D IH0 L IH2 N IY0 EY1 SH AH0 N'],
  "delinquencies": ['D IH0 L IH1 NG K W AH0 N S IY0 Z'],
  "delinquency": ['D IH0 L IH1 NG K W AH0 N S IY0'],
  "delinquent": ['D IH0 L IH1 NG K W AH0 N T'],
  "delinquents": ['D IH0 L IH1 NG K W AH0 N T S'],
  "delio": ['D EY1 L IY0 OW0'],
  "delirious": ['D IH0 L IH1 R IY0 AH0 S'],
  "delirium": ['D IH0 L IH1 R IY0 AH0 M'],
  "delisa": ['D EH0 L IY1 S AH0'],
  "delise": ['D EH1 L AY0 Z'],
  "delisi": ['D EH0 L IY1 S IY0'],
  "delisio": ['D EH0 L IY1 S IY0 OW0'],
  "delisle": ['D IH0 L AY1 L'],
  "delist": ['D IY2 L IH1 S T'],
  "delisted": ['D IY2 L IH1 S T IH0 D'],
  "delisting": ['D IY0 L IH1 S T IH0 NG'],
  "deliver": ['D IH0 L IH1 V ER0'],
  "deliverable": ['D IH0 L IH1 V ER0 AH0 B AH0 L', 'D IH0 L IH1 V R AH0 B AH0 L'],
  "deliverance": ['D IH0 L IH1 V ER0 AH0 N S', 'D IH0 L IH1 V R AH0 N S'],
  "delivered": ['D IH0 L IH1 V ER0 D'],
  "deliverer": ['D IH0 L IH1 V ER0 ER0'],
  "deliverers": ['D IH0 L IH1 V ER0 ER0 Z'],
  "deliveries": ['D IH0 L IH1 V ER0 IY0 Z', 'D IH0 L IH1 V R IY0 Z'],
  "delivering": ['D IH0 L IH1 V ER0 IH0 NG'],
  "delivers": ['D IH0 L IH1 V ER0 Z'],
  "delivery": ['D IH0 L IH1 V ER0 IY0'],
  "delk": ['D EH1 L K'],
  "delker": ['D EH1 L K ER0'],
  "dell": ['D EH1 L'],
  "dell'aquila": ['D EH1 L AH0 K W IY1 L AH0'],
  "dell's": ['D EH1 L Z'],
  "della": ['D EH1 L AH0'],
  "dellaert": ['D EH1 L AA0 R T', 'D EH1 L ER0 T'],
  "dellapenna": ['D EH1 L AH0 P EH2 N AH0'],
  "dellaquila": ['D EY0 L AA0 K W IY1 L AH0'],
  "dellarocco": ['D EH1 L ER0 OW0 K OW0'],
  "dellavalle": ['D EH1 L AH0 V AA0 L IY0'],
  "dellavecchia": ['D EH1 L AH0 V EH2 K IY0 AH0'],
  "delle": ['D EH1 L'],
  "deller": ['D EH1 L ER0'],
  "delleri": ['D AH0 L EH1 R IY0'],
  "delligatti": ['D EH0 L IY0 G AA1 T IY0'],
  "delling": ['D EH1 L IH0 NG'],
  "dellinger": ['D EH1 L IH0 NG ER0'],
  "dellis": ['D EH1 L IH0 S'],
  "dellolio": ['D EH0 L OW1 L IY0 OW0'],
  "dellums": ['D EH1 L AH0 M Z'],
  "delma": ['D EH1 L M AH0'],
  "delman": ['D EH1 L M AH0 N'],
  "delmar": ['D EH1 L M ER0'],
  "delmarva": ['D EH2 L M AA1 R V AH0'],
  "delmas": ['D EH1 L M AH0 Z'],
  "delmastro": ['D EH2 L M AE1 S T R OW0'],
  "delmed": ['D EH1 L M EH2 D'],
  "delmed's": ['D EH1 L M EH2 D Z'],
  "delmer": ['D EH1 L M ER0'],
  "delmonaco": ['D EH0 L M OW0 N AA1 K OW0'],
  "delmonico": ['D EH0 L M AA0 N IY1 K OW0'],
  "delmont": ['D EY1 L M OW0 N T'],
  "delmonte": ['D EH0 L M AA1 N T IY0'],
  "delmore": ['D EH1 L M AO0 R'],
  "delnegro": ['D EH2 L N EH1 G R OW0'],
  "delnero": ['D EH0 L N EH1 R OW0'],
  "delo": ['D EH1 L OW0'],
  "deloach": ['D EH1 L OW0 CH'],
  "deloatch": ['D EH1 L OW0 CH'],
  "deloitte": ['D AH0 L OY1 T'],
  "deloney": ['D EH1 L AH0 N IY0'],
  "delong": ['D AH0 L AO1 NG'],
  "delora": ['D EH0 L AO1 R AH0'],
  "delore": ['D AH0 L AO1 R', 'D AH0 L AO1 R IY0'],
  "delore's": ['D AH0 L AO1 R Z', 'D AH0 L AO1 R IY0 Z'],
  "delorean": ['D AH0 L AO1 R IY0 AH0 N'],
  "delorenzo": ['D EH0 L AO0 R EH1 N Z OW0', 'D EY2 L AO0 R EH1 N Z OW0'],
  "delores": ['D AH0 L AO1 R IH0 S'],
  "delorey": ['D EH1 L ER0 IY0'],
  "deloria": ['D EH0 L AO1 R IY0 AH0'],
  "deloris": ['D EH1 L ER0 IH0 S'],
  "delorme": ['D EH0 L AO1 R M IY0'],
  "delors": ['D AH0 L AO1 R Z'],
  "delosh": ['D EH1 L AH0 SH'],
  "delosreyes": ['D IH0 L AA1 S ER0 AY0 Z', 'D EH0 L OW0 S R EY1 Z', 'D EH0 L OW0 S R EY1 AH0 Z'],
  "delossantos": ['D EY0 L OW0 S AA1 N T OW0 Z'],
  "delouis": ['D EH2 L UW0 IY1 Z'],
  "delouse": ['D IY2 L AW1 Z'],
  "deloused": ['D IY2 L AW1 Z D'],
  "delozier": ['D EH1 L AH0 Z IY0 ER0'],
  "delp": ['D EH1 L P'],
  "delph": ['D EH1 L F'],
  "delphax": ['D EH1 L F AE0 K S'],
  "delphi": ['D EH1 L F AY0'],
  "delphia": ['D EH1 L F IY0 AH0'],
  "delphian": ['D EH1 L F Y AH0 N'],
  "delphic": ['D EH1 L F IH0 K'],
  "delphina": ['D EH0 L F IY1 N AH0'],
  "delphine": ['D EH0 L F IY1 N IY0'],
  "delpino": ['D EH2 L P IY1 N OW0'],
  "delpizzo": ['D EH0 L P IY1 Z OW0'],
  "delponte": ['D EH0 L P OW1 N T IY0'],
  "delpozo": ['D EH2 L P OW1 Z OW0'],
  "delprete": ['D EH1 L P R IY0 T'],
  "delpriore": ['D EH0 L P R IY0 AO1 R IY0'],
  "delray": ['D EH2 L R EY1'],
  "delre": ['D EH1 L R'],
  "delreal": ['D EH1 L R AH0 L'],
  "delrina": ['D EH2 L R IY1 N AH0'],
  "delrina's": ['D EH2 L R IY1 N AH0 Z'],
  "delrio": ['D EH1 L R IY0 OW0'],
  "delrosario": ['D EH0 L R OW0 S AA1 R IY0 OW0'],
  "delrossi": ['D EH0 L R AA1 S IY0'],
  "delrosso": ['D EH0 L R OW1 S OW0'],
  "delsanto": ['D EH0 L S AA1 N T OW0'],
  "delsignore": ['D EH0 L S IY0 G N AO1 R IY0'],
  "delta": ['D EH1 L T AH0'],
  "delta's": ['D EH1 L T AH0 Z'],
  "deltacorp": ['D EH1 L T AH0 K AO2 R P'],
  "deltadromeus": ['D EH2 L T AH0 D R OW1 M AH0 S'],
  "deltaic": ['D EH0 L T EY1 IH0 K'],
  "deltak": ['D EH1 L T AE2 K'],
  "deltas": ['D EH1 L T AH0 Z'],
  "deltec": ['D EH1 L T EH2 K'],
  "deltona": ['D EH2 L T OW1 N AH0'],
  "deltona's": ['D EH2 L T OW1 N AH0 Z'],
  "deltoro": ['D EH0 L T AO1 R OW0'],
  "deluca": ['D IH0 L UW1 K AH0'],
  "delucas": ['D IH0 L UW1 K AH0 Z'],
  "delucca": ['D EH0 L UW1 K AH0'],
  "delucchi": ['D EH0 L UW1 K IY0'],
  "deluccia": ['D EH0 L UW1 CH AH0'],
  "delucia": ['D EH0 L UW1 CH AH0'],
  "delude": ['D IH0 L UW1 D'],
  "deluded": ['D IH0 L UW1 D IH0 D'],
  "deludes": ['D IH0 L UW1 D Z'],
  "deluding": ['D IH0 L UW1 D IH0 NG'],
  "deluge": ['D EH1 L Y UW0 JH'],
  "deluged": ['D EH1 L Y UW0 JH D'],
  "deluise": ['D EH0 L UW1 S IY0'],
  "deluna": ['D EH0 L UW1 N AH0'],
  "delusion": ['D IH0 L UW1 ZH AH0 N'],
  "delusional": ['D IH0 L UW1 ZH AH0 N AH0 L'],
  "delusions": ['D IH0 L UW1 ZH AH0 N Z'],
  "deluxe": ['D AH0 L AH1 K S'],
  "delvalle": ['D EH2 L V AE1 L IY0'],
  "delvalle's": ['D EH2 L V AE1 L IY0 Z'],
  "delve": ['D EH1 L V'],
  "delvecchio": ['D EH2 L V EH1 K IY0 OW0'],
  "delved": ['D EH1 L V D'],
  "delves": ['D EH1 L V Z'],
  "delvin": ['D EH1 L V IH0 N'],
  "delving": ['D EH1 L V IH0 NG'],
  "delwin": ['D EH1 L W IH0 N'],
  "delwip": ['D EH1 L W IH0 P'],
  "delwyn": ['D EH1 L W IH0 N'],
  "delzell": ['D EH1 L Z AH0 L'],
  "delzer": ['D EH1 L Z ER0'],
  "demaggio": ['D IH0 M AA1 JH IY0 OW0'],
  "demagogic": ['D EH2 M AH0 G AA1 JH IH0 K'],
  "demagogue": ['D EH1 M AH0 G AA2 G'],
  "demagoguery": ['D EH1 M AH0 G AA2 G ER0 IY0'],
  "demagogues": ['D EH1 M AH0 G AA2 G Z'],
  "demagoguing": ['D EH1 M AH0 G AA2 G IH0 NG'],
  "demagogy": ['D EH1 M AH0 G AA2 JH IY0'],
  "demain": ['D IH0 M EY1 N'],
  "demaio": ['D IH0 M AA1 IY0 OW0', 'D IH0 M AA1 OW0'],
  "deman": ['D IY1 M AH0 N'],
  "demand": ['D IH0 M AE1 N D'],
  "demanded": ['D IH0 M AE1 N D AH0 D', 'D IH0 M AE1 N D IH0 D'],
  "demanding": ['D IH0 M AE1 N D IH0 NG'],
  "demandingly": ['D IH0 M AE1 N D IH0 NG L IY0'],
  "demands": ['D IH0 M AE1 N D Z'],
  "demar": ['D IH0 M AA1 R'],
  "demarais": ['D EH1 M ER0 EY0'],
  "demaray": ['D EH1 M ER0 EY0'],
  "demarcation": ['D IY2 M AA0 R K EY1 SH AH0 N'],
  "demarcations": ['D IY2 M AA0 R K EY1 SH AH0 N Z'],
  "demarche": ['D IH0 M AA1 R CH', 'D IY0 M AA1 R CH'],
  "demarchi": ['D IH0 M AA1 R K IY0'],
  "demarco": ['D IH0 M AA1 R K OW0'],
  "demarcus": ['D EH1 M AA0 R K IH0 S'],
  "demaree": ['D EH0 M ER0 IY1'],
  "demarest": ['D EH1 M ER0 EH2 S T'],
  "demaria": ['D IH0 M AA1 R IY0 AH0'],
  "demarinis": ['D EH1 M ER0 IH0 N IH0 S'],
  "demarino": ['D IH0 M AA0 R IY1 N OW0'],
  "demario": ['D IH0 M AA1 R IY0 OW0'],
  "demaris": ['D EH1 M ER0 IH0 S'],
  "demark": ['D AH0 M AA1 R K'],
  "demars": ['D EH1 M ER0 Z'],
  "demarsh": ['D EH1 M AA0 R SH'],
  "demartin": ['D IH0 M AA1 R T IH0 N'],
  "demartini": ['D IH0 M AA0 R T IY1 N IY0'],
  "demartino": ['D IH0 M AA0 R T IY1 N OW0'],
  "demary": ['D EH1 M EH0 R IY0'],
  "demarzo": ['D IH0 M AA1 R Z OW0'],
  "demas": ['D IY1 M AH0 S'],
  "demasi": ['D IH0 M AA1 S IY0'],
  "demasters": ['D IY1 M AE0 S T ER0 Z'],
  "dematteis": ['D EH1 M AH0 T AY0 Z'],
  "dematteo": ['D IH0 M AA1 T IY0 OW0'],
  "demattia": ['D IH0 M AA1 SH AH0'],
  "demauro": ['D IH0 M AO1 R OW0'],
  "demay": ['D EH1 M EY0'],
  "demayo": ['D EY0 M EY1 OW0'],
  "dembeck": ['D EH1 M B EH2 K'],
  "dembinski": ['D IH0 M B IH1 N S K IY0'],
  "dembowski": ['D IH0 M B AO1 F S K IY0'],
  "dembski": ['D EH1 M S K IY0'],
  "demby": ['D EH1 M B IY0'],
  "demchak": ['D EH1 M CH AH0 K'],
  "demean": ['D IH0 M IY1 N'],
  "demeaned": ['D IH0 M IY1 N D'],
  "demeaning": ['D IH0 M IY1 N IH0 NG'],
  "demeanor": ['D IH0 M IY1 N ER0'],
  "demeanors": ['D IH0 M IY1 N ER0 Z'],
  "demeanour": ['D IH0 M IY1 N ER0'],
  "demeanours": ['D IH0 M IY1 N ER0 Z'],
  "demeans": ['D IH0 M IY1 N Z'],
  "demel": ['D EH1 M AH0 L'],
  "demello": ['D IH0 M EH1 L OW0'],
  "demelo": ['D IH0 M EH1 L OW0'],
  "dement": ['D AH0 M EH1 N T'],
  "demented": ['D IH0 M EH1 N T IH0 D'],
  "dementia": ['D IH0 M EH1 N SH IY0 AH0'],
  "demeo": ['D IY1 M IY0 OW0'],
  "demeree": ['D EH1 M ER0 IY0'],
  "demerger": ['D IY0 M ER1 JH ER0'],
  "demerist": ['D IH0 M ER1 IH0 S T', 'D EH1 M ER0 IH0 S T'],
  "demerist's": ['D IH0 M ER1 IH0 S T S'],
  "demerit": ['D IY0 M EH1 R AH0 T'],
  "demerits": ['D IY0 M EH1 R AH0 T S'],
  "demeritt": ['D EH1 M ER0 IH0 T'],
  "demers": ['D IY1 M ER0 Z'],
  "demerse": ['D EH1 M ER0 S'],
  "demery": ['D IH0 M ER1 IY0'],
  "demesne": ['D AH0 M EY1 N'],
  "demeter": ['D IH0 M IY1 T ER0'],
  "demetre": ['D EH0 M IY1 T ER0'],
  "demetria": ['D IH0 M EH1 T R IY0 AH0'],
  "demetrio": ['D IH0 M EH1 T R IY0 OW0'],
  "demetriou": ['D IH0 M EH0 T R IY1 UW0'],
  "demetrius": ['D IH0 M IY1 T R IY0 AH0 S'],
  "demeyer": ['D EH1 M AY0 ER0'],
  "demi": ['D EH1 M IY0'],
  "demi's": ['D EH1 M IY0 Z'],
  "demicco": ['D IH0 M IY1 K OW0'],
  "demichael": ['D EH1 M IH0 K EH0 L'],
  "demichele": ['D EH1 M IH0 K AH0 L'],
  "demick": ['D EH1 M IH0 K'],
  "demilio": ['D IH0 M IY1 L IY0 OW0'],
  "demilitarization": ['D IY0 M IH2 L AH0 T ER0 AH0 Z EY1 SH AH0 N'],
  "demilitarize": ['D IY0 M IH1 L AH0 T ER0 AY2 Z'],
  "demilitarized": ['D IY0 M IH1 L AH0 T ER0 AY2 Z D'],
  "demilitarizes": ['D IY0 M IH1 L AH0 T ER0 AY2 Z IH0 Z'],
  "demilitarizing": ['D IY0 M IH1 L AH0 T ER0 AY2 Z IH0 NG'],
  "demille": ['D IH0 M IY1 L IY0', 'D IH0 M IH1 L'],
  "demilo": ['D IH0 M IH1 L OW0', 'D IH0 M AY1 L OW0'],
  "deming": ['D EH1 M IH0 NG'],
  "demint": ['D EY1 M IY0 N T'],
  "demirag": ['D EY2 M IH0 R AA1 JH'],
  "demirel": ['D AH0 M IH1 R AH0 L'],
  "demirjian": ['D IH0 M ER1 JH IY0 AH0 N'],
  "demisch": ['D AH0 M IH1 SH'],
  "demise": ['D IH0 M AY1 Z'],
  "demish": ['D EH1 M IH0 SH'],
  "demjanjuk": ['D EH0 M Y AA1 N Y UW0 K', 'D EH0 M Y AE1 N Y UW0 K'],
  "demjanjuk's": ['D EH0 M Y AA1 N Y UW0 K S', 'D EH0 M Y AE1 N Y UW0 K S'],
  "demko": ['D EH1 M K OW0'],
  "demler": ['D EH1 M L ER0'],
  "demma": ['D IY1 M AH0'],
  "demme": ['D EH1 M'],
  "demmer": ['D EH1 M ER0'],
  "demming": ['D EH1 M IH0 NG'],
  "demmon": ['D EH1 M AH0 N'],
  "demmons": ['D EH1 M AH0 N Z'],
  "demo": ['D EH1 M OW0'],
  "demobilization": ['D IY0 M OW2 B AH0 L AY0 Z EY1 SH AH0 N', 'D IY0 M OW2 B AH0 L AH0 Z EY1 SH AH0 N'],
  "demobilize": ['D IH0 M OW1 B AH0 L AY2 Z'],
  "demobilized": ['D IH0 M OW1 B AH0 L AY2 Z D'],
  "demobilizes": ['D IH0 M OW1 B AH0 L AY2 Z IH0 Z'],
  "demobilizing": ['D IH0 M OW1 B AH0 L AY2 Z IH0 NG'],
  "democracies": ['D IH0 M AA1 K R AH0 S IY0 Z'],
  "democracy": ['D IH0 M AA1 K R AH0 S IY0'],
  "democracy's": ['D IH0 M AA1 K R AH0 S IY0 Z'],
  "democrat": ['D EH1 M AH0 K R AE2 T'],
  "democrat's": ['D EH1 M AH0 K R AE2 T S'],
  "democratic": ['D EH2 M AH0 K R AE1 T IH0 K'],
  "democratic's": ['D EH2 M AH0 K R AE1 T IH0 K S'],
  "democratica": ['D EH2 M AH0 K R AE1 T IH0 K AH0'],
  "democratically": ['D EH2 M AH0 K R AE1 T IH0 K L IY0'],
  "democratics": ['D EH2 M AH0 K R AE1 T IH0 K S'],
  "democratization": ['D IH0 M AA2 K R AH0 T AH0 Z EY1 SH AH0 N'],
  "democratize": ['D IH0 M AA1 K R AH0 T AY2 Z'],
  "democratized": ['D IH0 M AA1 K R AH0 T AY2 Z D'],
  "democratizes": ['D IH0 M AA1 K R AH0 T AY2 Z IH0 Z'],
  "democratizing": ['D IH0 M AA1 K R AH0 T AY2 Z IH0 NG'],
  "democrats": ['D EH1 M AH0 K R AE2 T S'],
  "democrats'": ['D EH1 M AH0 K R AE2 T S'],
  "demodulate": ['D IY2 M AA2 JH AH0 L EY1 T'],
  "demodulated": ['D IY2 M AA2 JH AH0 L EY1 T AH0 D'],
  "demodulates": ['D IY2 M AA2 JH AH0 L EY1 T S'],
  "demodulation": ['D IY2 M AA2 JH AH0 L EY1 SH AH0 N'],
  "demographer": ['D IH0 M AA1 G R AH0 F ER0'],
  "demographers": ['D IH0 M AA1 G R AH0 F ER0 Z'],
  "demographic": ['D EH2 M AH0 G R AE1 F IH0 K'],
  "demographically": ['D EH2 M AH0 G R AE1 F IH0 K L IY0'],
  "demographics": ['D EH2 M AH0 G R AE1 F IH0 K S'],
  "demography": ['D IH0 M AA1 G R AH0 F IY0'],
  "demolish": ['D IH0 M AA1 L IH0 SH'],
  "demolished": ['D IH0 M AA1 L IH0 SH T'],
  "demolishes": ['D IH0 M AA1 L IH0 SH AH0 Z'],
  "demolishing": ['D IH0 M AA1 L IH0 SH IH0 NG'],
  "demolition": ['D EH2 M AH0 L IH1 SH AH0 N'],
  "demon": ['D IY1 M AH0 N'],
  "demond": ['D AH0 M AA1 N D'],
  "demonic": ['D IH0 M AA1 N IH0 K'],
  "demonization": ['D IY2 M AH0 N AH0 Z EY1 SH AH0 N'],
  "demonize": ['D IY1 M AH0 N AY2 Z'],
  "demonized": ['D IY1 M AH0 N AY2 Z D'],
  "demonizer": ['D IY1 M AH0 N AY2 Z ER0'],
  "demonizez": ['D IY1 M AH0 N AY2 Z IH0 Z'],
  "demonizing": ['D IY1 M AH0 N AY2 Z IH0 NG'],
  "demons": ['D IY1 M AH0 N Z'],
  "demonstrable": ['D EH1 M AH0 N S T R AH0 B AH0 L'],
  "demonstrably": ['D IH0 M AA1 N S T R AH0 B L IY0'],
  "demonstrate": ['D EH1 M AH0 N S T R EY2 T'],
  "demonstrated": ['D EH1 M AH0 N S T R EY2 T IH0 D'],
  "demonstrates": ['D EH1 M AH0 N S T R EY2 T S'],
  "demonstrating": ['D EH1 M AH0 N S T R EY2 T IH0 NG'],
  "demonstration": ['D EH2 M AH0 N S T R EY1 SH AH0 N'],
  "demonstrations": ['D EH2 M AH0 N S T R EY1 SH AH0 N Z'],
  "demonstrative": ['D IH0 M AA1 N S T R AH0 T IH0 V'],
  "demonstrator": ['D EH1 M AH0 N S T R EY2 T ER0'],
  "demonstrators": ['D EH1 M AH0 N S T R EY2 T ER0 Z'],
  "demont": ['D EH1 M AH0 N T'],
  "demonte": ['D AH0 M AA1 N T IY0'],
  "demopoulos": ['D AH0 M AA1 P AH0 L IH0 S'],
  "demoralization": ['D IH0 M AO2 R AH0 L IH0 Z EY1 SH AH0 N'],
  "demoralize": ['D IH0 M AO1 R AH0 L AY2 Z'],
  "demoralized": ['D IH0 M AO1 R AH0 L AY2 Z D'],
  "demoralizing": ['D IH0 M AO1 R AH0 L AY2 Z IH0 NG'],
  "demore": ['D EH1 M AO0 R'],
  "demorest": ['D EY0 M AO1 R IH0 S T'],
  "demory": ['D IH0 M ER1 IY0'],
  "demory's": ['D IH0 M ER1 IY0 Z'],
  "demos": ['D EH1 M OW2 Z'],
  "demoss": ['D AH0 M AA1 S'],
  "demote": ['D IH0 M OW1 T'],
  "demoted": ['D IH0 M OW1 T IH0 D'],
  "demotion": ['D IH0 M OW1 SH AH0 N'],
  "demotions": ['D IH0 M OW1 SH AH0 N Z'],
  "demott": ['D AH0 M AA1 T'],
  "demoulin": ['D EH1 M UW0 L AE0 N'],
  "demov": ['D EH1 M AA0 V'],
  "dempewolf": ['D EH1 M P Y UW0 UH0 L F'],
  "demps": ['D EH1 M P S'],
  "dempsey": ['D EH1 M P S IY0'],
  "dempster": ['D EH1 M P S T ER0'],
  "demski": ['D EH1 M S K IY0'],
  "demsky": ['D EH1 M S K IY0'],
  "demur": ['D IH0 M ER1'],
  "demure": ['D IH0 M Y UH1 R'],
  "demurely": ['D IH0 M Y UH1 R L IY0'],
  "demuro": ['D IH0 M UH1 R OW0'],
  "demurred": ['D IH0 M ER1 D'],
  "demurring": ['D IH0 M ER1 IH0 NG'],
  "demurs": ['D IH0 M ER1 Z'],
  "demus": ['D IY1 M AH0 S'],
  "demuth": ['D IY1 M AH0 TH'],
  "demyan": ['D EH1 M Y AH0 N'],
  "demystify": ['D IY0 M IH1 S T AH0 F AY2'],
  "den": ['D EH1 N'],
  "dena": ['D IY1 N AH0'],
  "denapoli": ['D IH0 N AA1 P AH0 L IY0'],
  "denard": ['D IH0 N AA1 R D'],
  "denardo": ['D IH0 N AA1 R D OW0'],
  "denarii": ['D IH0 N AE1 R IY0'],
  "denarius": ['D IH0 N AE1 R IY0 AH0 S'],
  "denaro": ['D IH0 N AA1 R OW0'],
  "denatale": ['D IH0 N AA0 T AA1 L IY0'],
  "denationalization": ['D IY2 N AE2 SH AH0 N AH0 L IH0 Z EY1 SH AH0 N'],
  "denationalizations": ['D IY0 N AE2 SH AH0 N AH0 L IH0 Z EY1 SH AH0 N Z'],
  "denationalize": ['D IH0 N AE1 SH AH0 N AH0 L AY2 Z'],
  "denationalized": ['D IH0 N AE1 SH AH0 N AH0 L AY2 Z D'],
  "denationalizing": ['D IH0 N AE1 SH AH0 N AH0 L AY2 Z IH0 NG'],
  "denature": ['D IH0 N EY1 CH ER0'],
  "denatured": ['D IH0 N EY1 CH ER0 D'],
  "denault": ['D IH0 N OW1'],
  "denbo": ['D IY1 N B OW0'],
  "denboer": ['D EH1 N B OW0 ER0'],
  "denbow": ['D EH1 N B OW0'],
  "denby": ['D EH1 N B IY0'],
  "denden": ['D EH1 N D AH0 N'],
  "dendinger": ['D IY1 N D IH0 NG ER0'],
  "dendritic": ['D EH0 N D R IH1 T IH0 K'],
  "dendrochronology": ['D EH2 N D R OW2 K R AH0 N AA1 L AH0 JH IY0'],
  "dendy": ['D EH1 N D IY0'],
  "deneau": ['D IH0 N OW1'],
  "deneault": ['D IH0 N OW1'],
  "deneen": ['D IH0 N IY1 N'],
  "deneke": ['D EH1 N IH0 K', 'D EH1 N IH0 K AH0'],
  "denenberg": ['D EH1 N AH0 N B ER0 G'],
  "denes": ['D IY1 N Z'],
  "deneuve": ['D IH0 N AH1 V', 'D IY0 N AH1 V'],
  "deneve": ['D EH1 N IH0 V'],
  "deng": ['D EH1 NG'],
  "deng's": ['D EH1 NG Z'],
  "dengel": ['D EH1 NG G AH0 L'],
  "dengler": ['D IH1 NG AH0 L ER0', 'D IH1 NG L ER0'],
  "dengue": ['D EH1 N G'],
  "denham": ['D EH1 N AH0 M'],
  "denhart": ['D EH1 N HH AA2 R T'],
  "denhartog": ['D EH1 N HH AA0 R T AH0 G'],
  "denherder": ['D EH1 N HH ER2 D ER0'],
  "denholm": ['D EH1 N HH OW2 L M'],
  "deniability": ['D IH0 N AY2 AH0 B IH1 L IH0 T IY0'],
  "denial": ['D IH0 N AY1 AH0 L'],
  "denials": ['D IH0 N AY1 AH0 L Z'],
  "denice": ['D IH0 N IY1 S'],
  "denicola": ['D IH0 N IY0 K OW1 L AH0'],
  "denied": ['D IH0 N AY1 D'],
  "denies": ['D IH0 N AY1 Z'],
  "denigrate": ['D EH1 N AH0 G R EY2 T'],
  "denigrated": ['D EH1 N IH0 G R EY2 T IH0 D'],
  "denigrating": ['D EH1 N IH0 G R EY2 T IH0 NG'],
  "denigris": ['D EH1 N IH0 G R IH0 S'],
  "denike": ['D EH1 N IH0 K'],
  "denim": ['D EH1 N AH0 M'],
  "denio": ['D IY1 N IY0 OW0'],
  "deniro": ['D IH0 N IH1 R OW0'],
  "deniro's": ['D IH0 N IH1 R OW0 Z'],
  "denis": ['D EH1 N IH0 S'],
  "denise": ['D IH0 N IY1 S'],
  "denison": ['D EH1 N IH0 S AH0 N'],
  "denison's": ['D EH1 N IH0 S AH0 N Z'],
  "deniston": ['D EH1 N IH0 S T AA0 N'],
  "deniz": ['D EY1 N IY0 Z'],
  "denizen": ['D EH1 N AH0 Z AH0 N'],
  "denizens": ['D EH1 N AH0 Z AH0 N Z'],
  "denk": ['D EH1 NG K'],
  "denker": ['D EH1 NG K ER0'],
  "denki": ['D EH1 NG K IY0'],
  "denkins": ['D EH1 NG K IH0 N Z'],
  "denko": ['D EH1 NG K OW0'],
  "denktas": ['D EH1 NG K T AH0 S'],
  "denlea": ['D EH1 N L IY2'],
  "denley": ['D EH1 N L IY0'],
  "denlinger": ['D EH1 N AH0 L IH0 NG ER0', 'D EH1 N L IH0 NG ER0', 'D EH1 N L IH0 N JH ER0'],
  "denman": ['D EH1 N M AH0 N'],
  "denmark": ['D EH1 N M AA2 R K'],
  "denmark's": ['D EH1 N M AA2 R K S'],
  "denmon": ['D EH1 N M AH0 N'],
  "denn": ['D EH1 N'],
  "dennard": ['D IH0 N AA1 R D'],
  "denne": ['D EH1 N'],
  "dennehy": ['D EH1 N IH0 HH IY0'],
  "dennen": ['D EH1 N AH0 N'],
  "denner": ['D EH1 N ER0'],
  "dennett": ['D EH1 N IH0 T'],
  "denney": ['D EH1 N IY0'],
  "dennie": ['D EH1 N IY0'],
  "dennin": ['D EH1 N IH0 N'],
  "denning": ['D EH1 N IH0 NG'],
  "denninger": ['D EH1 N IH0 NG ER0'],
  "dennington": ['D EH1 N IH0 NG T AH0 N'],
  "dennis": ['D EH1 N IH0 S'],
  "dennison": ['D EH1 N IH0 S AH0 N'],
  "denniston": ['D EH1 N IH0 S T AA0 N'],
  "denno": ['D EH1 N OW0'],
  "denny": ['D EH1 N IY0'],
  "denny's": ['D EH1 N IY0 Z'],
  "deno": ['D IY1 N OW0'],
  "denoble": ['D EH1 N OW0 B AH0 L'],
  "denominate": ['D IH0 N AA1 M AH0 N EY2 T'],
  "denominated": ['D IH0 N AA1 M AH0 N EY2 T IH0 D'],
  "denomination": ['D IH0 N AO2 M AH0 N EY1 SH AH0 N'],
  "denomination's": ['D IH0 N AO2 M AH0 N EY1 SH AH0 N Z'],
  "denominational": ['D IH0 N AO2 M AH0 N EY1 SH AH0 N AH0 L'],
  "denominations": ['D IH0 N AO2 M AH0 N EY1 SH AH0 N Z'],
  "denominator": ['D IH0 N AA1 M AH0 N EY2 T ER0'],
  "denomme": ['D EH1 N AH0 M'],
  "denosse": ['D IH0 N OW1 S', 'D IH0 N AO1 S IY0'],
  "denote": ['D IH0 N OW1 T'],
  "denoted": ['D IH0 N OW1 T AH0 D'],
  "denotes": ['D IH0 N OW1 T S'],
  "denoting": ['D IH0 N OW1 T IH0 NG'],
  "denouement": ['D EY2 N UW2 M AA1 N'],
  "denounce": ['D IH0 N AW1 N S'],
  "denounced": ['D IH0 N AW1 N S T'],
  "denounces": ['D IH0 N AW1 N S IH0 Z'],
  "denouncing": ['D IH0 N AW1 N S IH0 NG'],
  "denoyer": ['D EH1 N OY0 ER0'],
  "dens": ['D EH1 N Z'],
  "densch": ['D EH1 N SH'],
  "dense": ['D EH1 N S'],
  "densely": ['D EH1 N S L IY0'],
  "denser": ['D EH1 N S ER0'],
  "densest": ['D EH1 N S AH0 S T'],
  "denshin": ['D EH1 N SH IH0 N'],
  "densities": ['D EH1 N S AH0 T IY0 Z'],
  "densitometer": ['D EH2 N S AH0 T AA1 M AH0 T ER0'],
  "density": ['D EH1 N S AH0 T IY0', 'D EH1 N S IH0 T IY0'],
  "densley": ['D EH1 N S L IY0'],
  "denslow": ['D EH1 N S L OW2'],
  "densmore": ['D IY1 N S M AO0 R', 'D EH1 N S M AO0 R'],
  "denson": ['D EH1 N S AH0 N'],
  "dent": ['D EH1 N T'],
  "dental": ['D EH1 N T AH0 L', 'D EH1 N AH0 L'],
  "dentals": ['D EH1 N T AH0 L Z', 'D EH1 N AH0 L Z'],
  "dente": ['D EH1 N T EY2'],
  "dented": ['D EH1 N T IH0 D'],
  "dentin": ['D EH1 N T AH0 N'],
  "dentine": ['D EH1 N T IY0 N'],
  "denting": ['D EH1 N T IH0 NG'],
  "dentino": ['D IH0 N T IY1 N OW0'],
  "dentist": ['D EH1 N T AH0 S T', 'D EH1 N T IH0 S T', 'D EH1 N IH0 S T'],
  "dentist's": ['D EH1 N T IH0 S T S'],
  "dentistry": ['D EH1 N T IH0 S T R IY0', 'D EH1 N IH0 S T R IY0'],
  "dentists": ['D EH1 N T AH0 S T S', 'D EH1 N T IH0 S T S'],
  "dentists'": ['D EH1 N T IH0 S T S', 'D EH1 N IH0 S T S'],
  "dentition": ['D EH0 N T IH1 SH AH0 N'],
  "dentler": ['D EH1 N T L ER0'],
  "denton": ['D EH1 N T AH0 N'],
  "dentremont": ['D EY0 N T R EY1 M AA0 N T'],
  "dents": ['D EH1 N T S'],
  "dentsu": ['D EH1 N T S UW0'],
  "dentton": ['D EH1 N T AH0 N'],
  "denture": ['D EH1 N CH ER0'],
  "dentures": ['D EH1 N CH ER0 Z'],
  "dentzer": ['D EH1 N T Z ER0'],
  "denucci": ['D IH0 N UW1 CH IY2'],
  "denuclearization": ['D IY0 N UW2 K L IY0 ER2 AH0 Z EY1 SH AH0 N'],
  "denuclearized": ['D IH0 N UW1 K L IY0 ER0 AY2 Z D', 'D IY0 N UW1 K L IY0 ER0 AY2 Z D'],
  "denude": ['D IH0 N UW1 D'],
  "denuded": ['D IH0 N UW1 D IH0 D'],
  "denuding": ['D IH0 N UW1 D IH0 NG'],
  "denunciation": ['D IH0 N AH2 N S IY0 EY1 SH AH0 N'],
  "denunciations": ['D IH0 N AH2 N S IY0 EY1 SH AH0 N Z'],
  "denunzio": ['D AH0 N AH1 N Z IY0 OW0'],
  "denver": ['D EH1 N V ER0'],
  "denver's": ['D EH1 N V ER0 Z'],
  "denwa": ['D EH1 N W AA2'],
  "deny": ['D IH0 N AY1'],
  "denying": ['D IH0 N AY1 IH0 NG'],
  "denys": ['D EH1 N IH2 S'],
  "denyse": ['D EH1 N AY0 S'],
  "denz": ['D EH1 N Z'],
  "denzel": ['D EH1 N Z AH0 L'],
  "denzer": ['D EH1 N Z ER0'],
  "denzil": ['D EH1 N Z AH0 L'],
  "denzler": ['D EH1 N Z L ER0'],
  "deo": ['D IY1 OW0'],
  "deodorant": ['D IY0 OW1 D ER0 AH0 N T'],
  "deodorants": ['D IY0 OW1 D ER0 AH0 N T S'],
  "deoliveira": ['D IY2 AA2 L IH0 V EY1 R AA2'],
  "deon": ['D IY1 AA0 N'],
  "deontological": ['D IY2 AA0 N T AH0 L AA1 JH AH0 K AH0 L'],
  "deontology": ['D IY0 AA0 N T AA1 L AH0 JH IY0'],
  "deorbit": ['D IY0 AO1 R B IH0 T'],
  "deoxyribonucleic": ['D IY0 AA2 K S IY0 R AY2 B OW0 N UW0 K L EY1 IH0 K'],
  "dep": ['D EH1 P'],
  "depace": ['D IH0 P AA1 CH IY2'],
  "depalma": ['D IH0 P AA1 L M AH0'],
  "depalma's": ['D IH0 P AA1 L M AH0 Z'],
  "depalo": ['D IH0 P AA1 L OW2'],
  "depaola": ['D IH0 P AW1 L AA2'],
  "depaoli": ['D IH0 P AW1 L IY2'],
  "depaolis": ['D EH0 P AW1 L IH0 S'],
  "depaolo": ['D IH0 P AW1 L OW2'],
  "depardieu": ['D IY2 P AA0 R D Y AH1', 'D IY2 P AA0 R D UW1'],
  "depart": ['D IH0 P AA1 R T'],
  "departed": ['D IH0 P AA1 R T IH0 D'],
  "departing": ['D IH0 P AA1 R T IH0 NG'],
  "department": ['D IH0 P AA1 R T M AH0 N T'],
  "department's": ['D IH0 P AA1 R T M AH0 N T S'],
  "departmental": ['D IH0 P AA2 R T M EH1 N AH0 L', 'D IH0 P AA2 R T M EH1 N T AH0 L'],
  "departmentalize": ['D IH0 P AA2 R T M EH1 N T AH0 L AY2 Z', 'D IH0 P AA2 R T M EH1 N AH0 L AY2 Z'],
  "departmentalized": ['D IH0 P AA2 R T M EH1 N T AH0 L AY2 Z D', 'D IH0 P AA2 R T M EH1 N AH0 L AY2 Z D'],
  "departmentalizes": ['D IH0 P AA2 R T M EH1 N T AH0 L AY2 Z IH0 Z'],
  "departmentalizing": ['D IH0 P AA2 R T M EH1 N T AH0 L AY2 Z IH0 NG'],
  "departments": ['D IH0 P AA1 R T M AH0 N T S'],
  "departs": ['D IH0 P AA1 R T S'],
  "departure": ['D IH0 P AA1 R CH ER0'],
  "departures": ['D IH0 P AA1 R CH ER0 Z'],
  "depascale": ['D IH0 P AA0 S K AA1 L IY0'],
  "depasquale": ['D IH0 P AA0 S K W AA1 L IY0'],
  "depass": ['D IH0 P AE1 S'],
  "depaul": ['D IH0 P AO1 L'],
  "depaula": ['D IH0 P AO1 L AH0'],
  "depaulo": ['D IH0 P AO1 L OW0'],
  "depauw": ['D AH0 P AW1'],
  "depaz": ['D AH0 P AE1 Z'],
  "depeche": ['D EH0 P EH1 SH'],
  "depend": ['D IH0 P EH1 N D'],
  "dependability": ['D IH0 P EH2 N D AH0 B IH1 L IH0 T IY0'],
  "dependable": ['D IH0 P EH1 N D AH0 B AH0 L'],
  "depended": ['D IH0 P EH1 N D AH0 D', 'D IH0 P EH1 N D IH0 D'],
  "dependence": ['D IH0 P EH1 N D AH0 N S'],
  "dependencies": ['D IH0 P EH1 N D AH0 N S IY0 Z'],
  "dependency": ['D IH0 P EH1 N D AH0 N S IY0'],
  "dependent": ['D IH0 P EH1 N D AH0 N T'],
  "dependents": ['D IH0 P EH1 N D AH0 N T S'],
  "depending": ['D IH0 P EH1 N D IH0 NG'],
  "depends": ['D IH0 P EH1 N D Z'],
  "depersonalize": ['D IY0 P ER1 S AH0 N AH0 L AY2 Z', 'D IY0 P ER1 S N AH0 L AY2 Z'],
  "depetro": ['D IH0 P EH1 T R OW0'],
  "depew": ['D AH0 P Y UW1'],
  "dephillips": ['D EH1 F IH0 L IH0 P S', 'D IH0 F IH1 L IH0 P S'],
  "depict": ['D IH0 P IH1 K T'],
  "depicted": ['D IH0 P IH1 K T AH0 D', 'D IH0 P IH1 K T IH0 D'],
  "depicting": ['D IH0 P IH1 K T IH0 NG'],
  "depiction": ['D IH0 P IH1 K SH AH0 N'],
  "depictions": ['D IH0 P IH1 K SH AH0 N Z'],
  "depicts": ['D IH0 P IH1 K T S', 'D IH0 P IH1 K S'],
  "depietro": ['D IH0 P IY1 T R OW0'],
  "depilatory": ['D IH0 P IH1 L AH0 T AO2 R IY0'],
  "depina": ['D IH0 P IY1 N AH0'],
  "depinto": ['D IH0 P IY1 N T OW0'],
  "deplete": ['D IH0 P L IY1 T'],
  "depleted": ['D IH0 P L IY1 T IH0 D'],
  "depleter": ['D IH0 P L IY1 T ER0'],
  "depleters": ['D IH0 P L IY1 T ER0 Z'],
  "depletes": ['D IH0 P L IY1 T S'],
  "depleting": ['D IH0 P L IY1 T IH0 NG'],
  "depletion": ['D IH0 P L IY1 SH AH0 N'],
  "deplorable": ['D IH0 P L AO1 R AH0 B AH0 L'],
  "deplore": ['D IH0 P L AO1 R'],
  "deplored": ['D IH0 P L AO1 R D'],
  "deplores": ['D IH0 P L AO1 R Z'],
  "deploring": ['D IH0 P L AO1 R IH0 NG'],
  "deploy": ['D IH0 P L OY1'],
  "deployable": ['D IH0 P L OY1 AH0 B AH0 L'],
  "deployed": ['D IH0 P L OY1 D'],
  "deploying": ['D IH0 P L OY1 IH0 NG'],
  "deployment": ['D IH0 P L OY1 M AH0 N T'],
  "deployments": ['D IH0 P L OY1 M AH0 N T S'],
  "deploys": ['D IH0 P L OY1 Z'],
  "depner": ['D EH1 P N ER0'],
  "depo": ['D IY1 P OW0', 'D EH1 P OW0'],
  "depolo": ['D IH0 P OW1 L OW0'],
  "deponte": ['D IH0 P OW1 N T IY0'],
  "depopulate": ['D IY0 P AA1 P Y AH0 L EY2 T'],
  "depopulation": ['D IH0 P AA2 P Y AH0 L EY1 SH AH0 N', 'D IY2 P AA0 P Y AH0 L EY1 SH AH0 N'],
  "deport": ['D IH0 P AO1 R T'],
  "deportation": ['D IY2 P AO0 R T EY1 SH AH0 N'],
  "deportations": ['D IY2 P AO0 R T EY1 SH AH0 N Z'],
  "deported": ['D IH0 P AO1 R T AH0 D'],
  "deportee": ['D IY2 P AO0 R T IY1'],
  "deportees": ['D IY2 P AO0 R T IY1 Z'],
  "deporting": ['D IH0 P AO1 R T IH0 NG'],
  "deportment": ['D AH0 P AO1 R T M AH0 N T'],
  "depose": ['D IH0 P OW1 Z'],
  "deposed": ['D IH0 P OW1 Z D'],
  "deposi": ['D AH0 P OW1 Z IY0'],
  "deposit": ['D AH0 P AA1 Z IH0 T', 'D IH0 P AA1 Z AH0 T'],
  "depositary": ['D AH0 P AA1 Z IH0 T EH2 R IY0', 'D IH0 P AA1 Z IH0 T EH2 R IY0'],
  "deposited": ['D AH0 P AA1 Z IH0 T IH0 D', 'D IH0 P AA1 Z AH0 T AH0 D'],
  "depositing": ['D AH0 P AA1 Z IH0 T IH0 NG'],
  "deposition": ['D EH2 P AH0 Z IH1 SH AH0 N'],
  "depositional": ['D EH2 P AH0 Z IH1 SH AH0 N AH0 L'],
  "depositions": ['D EH2 P AH0 Z IH1 SH AH0 N Z'],
  "depositor": ['D AH0 P AA1 Z IH0 T ER0'],
  "depositor's": ['D AH0 P AA1 Z IH0 T ER0 Z'],
  "depositors": ['D AH0 P AA1 Z IH0 T ER0 Z'],
  "depositors'": ['D IH0 P AA1 Z IH0 T ER0 Z'],
  "depository": ['D IH0 P AA1 Z AH0 T AO2 R IY0'],
  "deposits": ['D AH0 P AA1 Z IH0 T S', 'D IH0 P AA1 Z AH0 T S'],
  "depot": ['D IY1 P OW0'],
  "depot's": ['D IY1 P OW0 Z'],
  "depots": ['D IY1 P OW0 Z'],
  "depoy": ['D EH1 P OY0'],
  "depp": ['D EH1 P'],
  "deppe": ['D EH1 P'],
  "deppen": ['D EH1 P AH0 N'],
  "depravation": ['D EH2 P R AH0 V EY1 SH AH0 N'],
  "deprave": ['D IY0 P R EY1 V'],
  "depraved": ['D IY0 P R EY1 V D'],
  "depravity": ['D IH0 P R AE1 V AH0 T IY0'],
  "deprecate": ['D EH1 P R AH0 K EY2 T'],
  "deprecated": ['D EH1 P R AH0 K EY2 T AH0 D'],
  "deprecates": ['D EH1 P R AH0 K EY2 T S'],
  "deprecating": ['D EH1 P R AH0 K EY2 T IH0 NG'],
  "depreciable": ['D IH0 P R IH1 SH AH0 B AH0 L'],
  "depreciate": ['D IH0 P R IY1 SH IY0 EY2 T'],
  "depreciated": ['D IH0 P R IY1 SH IY0 EY2 T IH0 D'],
  "depreciates": ['D IH0 P R IY1 SH IY0 EY2 T S'],
  "depreciating": ['D IH0 P R IY1 SH IY0 EY2 T IH0 NG'],
  "depreciation": ['D IH0 P R IY2 SH IY0 EY1 SH AH0 N'],
  "depreciations": ['D IH0 P R IY2 SH IY0 EY1 SH AH0 N Z'],
  "depredation": ['D EH2 P R AH0 D EY1 SH AH0 N'],
  "depredations": ['D EH2 P R AH0 D EY1 SH AH0 N Z'],
  "depree": ['D IH0 P R IY1'],
  "deprenyl": ['D EH1 P R AH0 N IH2 L'],
  "depress": ['D IH0 P R EH1 S'],
  "depressant": ['D IH0 P R EH1 S AH0 N T'],
  "depressants": ['D IH0 P R EH1 S AH0 N T S'],
  "depressed": ['D IH0 P R EH1 S T'],
  "depresses": ['D IH0 P R EH1 S AH0 Z', 'D IH0 P R EH1 S IH0 Z'],
  "depressing": ['D IH0 P R EH1 S IH0 NG'],
  "depressingly": ['D IH0 P R EH1 S IH0 NG L IY0'],
  "depression": ['D IH0 P R EH1 SH AH0 N'],
  "depressions": ['D IH0 P R EH1 SH AH0 N Z'],
  "depressive": ['D IH0 P R EH1 S IH0 V'],
  "depressurize": ['D IH0 P R EH1 SH ER0 AY2 Z'],
  "depressurized": ['D IH0 P R EH1 SH ER0 AY2 Z D'],
  "depressurizes": ['D IH0 P R EH1 SH ER0 AY2 Z IH0 Z'],
  "depressurizing": ['D IH0 P R EH1 SH ER0 AY2 Z IH0 NG'],
  "deprey": ['D EH1 P R IY0'],
  "deprez": ['D EY0 P R EH1 Z'],
  "depriest": ['D EH1 P ER0 IY0 IH0 S T', 'D IH0 P R IY1 S T'],
  "deprivation": ['D EH2 P R AH0 V EY1 SH AH0 N'],
  "deprivations": ['D EH2 P R AH0 V EY1 SH AH0 N Z'],
  "deprive": ['D IH0 P R AY1 V'],
  "deprived": ['D IH0 P R AY1 V D'],
  "deprives": ['D IH0 P R AY1 V Z'],
  "depriving": ['D IH0 P R AY1 V IH0 NG'],
  "deprogram": ['D IY0 P R OW1 G R AE0 M'],
  "deprogramming": ['D IY0 P R OW1 G R AE0 M IH0 NG'],
  "depth": ['D EH1 P TH'],
  "depths": ['D EH1 P TH S'],
  "deptula": ['D IH0 P T UW1 L AH0'],
  "depue": ['D AH0 P Y UW1'],
  "deputies": ['D EH1 P Y AH0 T IY0 Z', 'D EH1 P Y UW0 T IY0 Z'],
  "deputize": ['D EH1 P Y AH0 T AY2 Z'],
  "deputized": ['D EH1 P Y AH0 T AY2 Z D'],
  "deputy": ['D EH1 P Y AH0 T IY0', 'D EH1 P Y UW0 T IY0'],
  "depuy": ['D IH0 P W IY1'],
  "dequeker": ['D IH0 K W EH1 K ER0'],
  "der": ['D ER1'],
  "derail": ['D IH0 R EY1 L'],
  "derailed": ['D IH0 R EY1 L D'],
  "derailing": ['D IH0 R EY1 L IH0 NG'],
  "derailment": ['D IH0 R EY1 L M AH0 N T'],
  "derailments": ['D IH0 R EY1 L M AH0 N T S'],
  "derails": ['D IH0 R EY1 L Z'],
  "deramo": ['D IH0 R AA1 M OW0'],
  "deramus": ['D EH1 R AH0 M IH0 S'],
  "derange": ['D IH0 R EY1 N JH'],
  "deranged": ['D IH0 R EY1 N JH D'],
  "derasmo": ['D IH0 R AA1 S M OW0'],
  "derby": ['D ER1 B IY0'],
  "derbyshire": ['D ER1 B IY0 SH ER2'],
  "derchin": ['D ER1 CH IH0 N'],
  "dercole": ['D IH0 R K OW1 L IY0'],
  "derden": ['D ER1 D AH0 N'],
  "derderian": ['D ER0 D IH1 R IY0 AH0 N'],
  "derecktor": ['D ER0 EH1 K T ER0'],
  "deregt": ['D ER0 EH1 K T'],
  "deregulate": ['D IY0 R EH1 G Y AH0 L EY0 T'],
  "deregulated": ['D IY0 R EH1 G Y AH0 L EY0 T IH0 D'],
  "deregulating": ['D IY0 R EH1 G Y AH0 L EY2 T IH0 NG'],
  "deregulation": ['D IY0 R EH2 G Y AH0 L EY1 SH AH0 N'],
  "deregulator": ['D IY0 R EH1 G Y AH0 L EY0 T ER0'],
  "deregulators": ['D IY0 R EH1 G Y AH0 L EY0 T ER0 Z'],
  "deregulatory": ['D IY0 R EH1 G Y AH0 L AH0 T AO2 R IY0'],
  "derek": ['D EH1 R IH0 K'],
  "derek's": ['D EH1 R IH0 K S'],
  "derelict": ['D EH1 R AH0 L IH2 K T'],
  "dereliction": ['D EH2 R AH0 L IH1 K SH AH0 N'],
  "derelicts": ['D EH1 R AH0 L IH2 K T S', 'D EH1 R AH0 L IH2 K S'],
  "deremer": ['D EH1 R IY0 M ER0'],
  "deren": ['D IH1 R AH0 N'],
  "derenzo": ['D IH0 R EH1 N Z OW0'],
  "derflinger": ['D ER1 F AH0 L IH0 NG ER0', 'D ER1 F L IH0 NG ER0'],
  "derham": ['D ER1 HH AH0 M'],
  "derhammer": ['D ER1 HH AH0 M ER0'],
  "derick": ['D EH1 R IH0 K'],
  "derickson": ['D EH1 R IH0 K S AH0 N'],
  "derida": ['D AH0 R IY1 D AH0'],
  "deridder": ['D EH1 R IH0 D ER0'],
  "deride": ['D IH0 R AY1 D'],
  "derided": ['D IH0 R AY1 D IH0 D'],
  "derides": ['D IH0 R AY1 D Z'],
  "deriding": ['D IH0 R AY1 D IH0 NG'],
  "derienzo": ['D IH0 R IY1 N Z OW0'],
  "derik": ['D EH1 R IH0 K'],
  "dering": ['D IH1 R IH0 NG'],
  "deringer": ['D EH1 R IH0 N JH ER0'],
  "derington": ['D ER1 IH0 NG T AH0 N'],
  "derise": ['D EH1 R AY0 Z'],
  "derision": ['D ER0 IH1 ZH AH0 N'],
  "derisive": ['D ER0 IH1 S IH0 V', 'D ER0 AY1 S IH0 V'],
  "derisively": ['D ER0 IH1 S IH0 V L IY0', 'D ER0 AY1 S IH0 V L IY0'],
  "deriso": ['D IH0 R IY1 S OW0'],
  "derivation": ['D EH2 R AH0 V EY1 SH AH0 N'],
  "derivative": ['D ER0 IH1 V AH0 T IH0 V', 'D ER0 IH1 V IH0 T IH0 V'],
  "derivatives": ['D ER0 IH1 V AH0 T IH0 V Z'],
  "derive": ['D ER0 AY1 V'],
  "derived": ['D ER0 AY1 V D'],
  "derives": ['D ER0 AY1 V Z', 'D IH0 R AY1 V Z'],
  "deriving": ['D ER0 AY1 V IH0 NG'],
  "derk": ['D ER1 K'],
  "derks": ['D ER1 K S'],
  "derksen": ['D ER1 K S AH0 N'],
  "derleth": ['D ER1 L IH0 TH'],
  "derma": ['D ER1 M AH0'],
  "dermagraph": ['D ER1 M AH0 G R AE0 F'],
  "dermal": ['D ER1 M AH0 L'],
  "derman": ['D ER1 M AH0 N'],
  "dermatitis": ['D ER2 M AH0 T AY1 T IH0 S'],
  "dermatological": ['D ER2 M AH0 T AH0 L AA1 JH IH0 K AH0 L'],
  "dermatologist": ['D ER2 M AH0 T AA1 L AH0 JH IH0 S T'],
  "dermatologists": ['D ER2 M AH0 T AA1 L AH0 JH IH0 S T S'],
  "dermatology": ['D ER2 M AH0 T AA1 L AH0 JH IY0'],
  "dermer": ['D ER1 M ER0'],
  "dermis": ['D ER1 M AH0 S'],
  "dermody": ['D ER1 M AH0 D IY0'],
  "dermot": ['D ER1 M AH0 T'],
  "dermott": ['D ER1 M AH0 T'],
  "dern": ['D ER1 N'],
  "derner": ['D ER1 N ER0'],
  "derobertis": ['D EH1 R AH0 B ER0 T IH0 S'],
  "derocco": ['D IH0 R OW1 K OW0'],
  "deroche": ['D EH1 R AH0 K'],
  "derocher": ['D EH1 R AH0 K ER0'],
  "derogatory": ['D ER0 AA1 G AH0 T AO2 R IY0'],
  "deroo": ['D EH1 R UW0'],
  "deroos": ['D IH1 R UW0 Z'],
  "derosa": ['D IH0 R OW1 S AH0'],
  "derose": ['D EH1 R AH0 S'],
  "derosia": ['D IH0 R OW1 S IY0 AH0'],
  "derosier": ['D EH1 R AH0 S IY0 ER0'],
  "derossett": ['D EH1 R AH0 S EH0 T'],
  "derouen": ['D ER0 W EH1 N'],
  "derouin": ['D ER0 W IY1 N'],
  "derousse": ['D ER0 UW1 S'],
  "deroy": ['D IH1 R OY0'],
  "derr": ['D EH1 R'],
  "derrick": ['D EH1 R IH0 K'],
  "derrickson": ['D EH1 R IH0 K S AH0 N'],
  "derrico": ['D IH0 R IY1 K OW0'],
  "derrida": ['D EH2 R IY2 D AA1'],
  "derrig": ['D EH1 R IH0 G'],
  "derring": ['D EH1 R IH0 NG'],
  "derringer": ['D EH1 R AH0 N JH ER0'],
  "derrington": ['D EH1 R IH0 NG T AH0 N'],
  "derrow": ['D EH1 R OW0'],
  "derry": ['D EH1 R IY0'],
  "derryberry": ['D EH1 R IY0 B EH2 R IY0'],
  "dersch": ['D ER1 SH'],
  "dershem": ['D ER1 SH IH0 M'],
  "dershowitz": ['D ER1 SH AH0 W IH2 T S'],
  "dershowitz's": ['D ER1 SH AH0 W IH2 T S IH0 Z'],
  "derstine": ['D ER1 S T IY0 N'],
  "derthick": ['D ER1 TH IH0 K'],
  "derubeis": ['D EH1 R AH0 B AY0 Z'],
  "deruiter": ['D IH1 R IH0 T ER0'],
  "deruko": ['D IH0 R UW1 K OW0'],
  "derus": ['D EH1 R IH0 S'],
  "derusha": ['D EH1 R AH0 SH AH0'],
  "deruyter": ['D IH1 R AY0 T ER0'],
  "dervin": ['D ER1 V IH0 N'],
  "dervish": ['D ER1 V IH0 SH'],
  "dervishich": ['D ER1 V IH0 SH IH0 K'],
  "derward": ['D ER1 W ER0 D'],
  "derwin": ['D ER1 W IH0 N'],
  "derwinski": ['D ER0 W IH1 N S K IY0'],
  "dery": ['D EH1 R IY0'],
  "deryck": ['D EH1 R IH0 K'],
  "deryle": ['D EH1 R AH0 L'],
  "des": ['D EH1 S', 'D IH2'],
  "des-moines": ['D EH2 M OY1 N', 'D IH2 M OY1 N'],
  "desai": ['D EY0 S AA1 IY0'],
  "desalination": ['D IY0 S EY2 L IH0 N EY1 SH AH0 N'],
  "desalinization": ['D IY0 S EY2 L IH0 N AH0 Z EY1 SH AH0 N'],
  "desalvo": ['D IH0 S AA1 L V OW0'],
  "desanctis": ['D EH0 S AE1 NG K T IH0 S'],
  "desanti": ['D IH0 S AA1 N T IY0'],
  "desantiago": ['D IH0 S AA0 N T IY0 AA1 G OW0'],
  "desantis": ['D EY0 S AA1 N T IH0 S'],
  "desanto": ['D IH0 S AA1 N T OW0'],
  "desantos": ['D EY0 S AA1 N T OW0 Z'],
  "desaulniers": ['D EH1 S OW0 L N IY0 ER0 Z'],
  "desautel": ['D EH1 S OW0 T AH0 L'],
  "desautels": ['D EH1 S OW0 T AH0 L Z'],
  "descarpentries": ['D EY0 K AA1 R P AH0 N T R IY0 Z'],
  "descartes": ['D EY0 K AA1 R T'],
  "descartes's": ['D EY0 K AA1 R T S'],
  "descend": ['D IH0 S EH1 N D'],
  "descendant": ['D IH0 S EH1 N D AH0 N T'],
  "descendants": ['D IH0 S EH1 N D AH0 N T S', 'D IH0 S EH1 N IH0 N T S'],
  "descended": ['D IH0 S EH1 N D AH0 D', 'D IH0 S EH1 N D IH0 D'],
  "descendent": ['D IH0 S EH1 N D AH0 N T'],
  "descendents": ['D IH0 S EH1 N D AH0 N T S'],
  "descending": ['D IH0 S EH1 N D IH0 NG'],
  "descends": ['D IH0 S EH1 N D Z'],
  "descent": ['D IH0 S EH1 N T'],
  "descents": ['D IH0 S EH1 N T S'],
  "descenza": ['D EH0 SH EH1 N Z AH0'],
  "desch": ['D EH1 SH'],
  "deschaine": ['D IH0 S K EY1 N'],
  "deschamps": ['D EH1 SH AH0 M P S'],
  "deschene": ['D EH1 SH IY0 N'],
  "deschenes": ['D EH1 SH IY0 N Z'],
  "deschepper": ['D EH1 SH IH0 P ER0'],
  "deschler": ['D EH1 SH AH0 L ER0', 'D EH1 SH L ER0'],
  "deschner": ['D EH1 SH N ER0'],
  "descoteaux": ['D EH1 S K AH0 T OW0'],
  "describable": ['D IH0 S K R AY1 B AH0 B AH0 L'],
  "describe": ['D IH0 S K R AY1 B'],
  "described": ['D IH0 S K R AY1 B D'],
  "describes": ['D IH0 S K R AY1 B Z'],
  "describing": ['D IH0 S K R AY1 B IH0 NG'],
  "description": ['D IH0 S K R IH1 P SH AH0 N'],
  "descriptions": ['D IH0 S K R IH1 P SH AH0 N Z'],
  "descriptive": ['D IH0 S K R IH1 P T IH0 V'],
  "descriptor": ['D IH0 S K R IH1 P T ER2'],
  "descriptors": ['D IH0 S K R IH1 P T ER2 Z'],
  "descry": ['D EH0 S K R AY1'],
  "desecrate": ['D EH0 Z AH0 K R EY1 T', 'D EH0 S AH0 K R EY1 T'],
  "desecrated": ['D EH0 Z AH0 K R EY1 T IH0 D', 'D EH0 S AH0 K R EY1 T IH0 D'],
  "desecrates": ['D EH0 Z AH0 K R EY1 T S', 'D EH0 S AH0 K R EY1 T S'],
  "desecration": ['D EH0 S AH0 K R EY1 SH AH0 N', 'D EH0 Z AH0 K R EY1 SH AH0 N'],
  "desecrations": ['D EH0 S AH0 K R EY1 SH AH0 N Z', 'D EH0 Z AH0 K R EY1 SH AH0 N Z'],
  "desegregate": ['D IH0 S EH1 G R AH0 G EY2 T'],
  "desegregated": ['D IH0 S EH1 G R IH0 G EY2 T IH0 D'],
  "desegregation": ['D IH0 S EH2 G R AH0 G EY1 SH AH0 N', 'D IY2 S EH0 G R AH0 G EY1 SH AH0 N'],
  "desena": ['D IH0 S EH1 N AH0'],
  "desensitize": ['D IH0 S EH1 N S AH0 T AY2 Z'],
  "desensitized": ['D IH0 S EH1 N S AH0 T AY2 Z D'],
  "desensitizing": ['D IH0 S EH1 N S AH0 T AY2 Z IH0 NG'],
  "deseret": ['D EH2 S ER0 EH1 T', 'D EH2 Z ER0 EY1'],
  "desert": ['D EH1 Z ER0 T', 'D IH0 Z ER1 T'],
  "deserted": ['D IH0 Z ER1 T IH0 D'],
  "deserter": ['D EH1 Z ER0 T ER0'],
  "deserters": ['D EH1 Z ER0 T ER0 Z'],
  "deserting": ['D EH1 Z ER0 T IH0 NG'],
  "desertion": ['D IH0 Z ER1 SH AH0 N'],
  "desertions": ['D IH0 Z ER1 SH AH0 N Z'],
  "deserts": ['D EH1 Z ER0 T S', 'D IH0 Z ER1 T S'],
  "deserve": ['D IH0 Z ER1 V'],
  "deserved": ['D IH0 Z ER1 V D'],
  "deservedly": ['D IH0 Z ER1 V AH0 D L IY0'],
  "deserves": ['D IH0 Z ER1 V Z'],
  "deserving": ['D IH0 Z ER1 V IH0 NG'],
  "desha": ['D EH1 SH AH0'],
  "deshaies": ['D IH0 SH EY1 Z'],
  "deshane": ['D EH1 SH AH0 N'],
  "deshaw": ['D EH1 SH AO0'],
  "deshazer": ['D EH1 SH AH0 Z ER0'],
  "deshazo": ['D EY0 SH AA1 Z OW0'],
  "deshields": ['D EH1 SH IY0 L D Z'],
  "deshler": ['D EH1 SH L ER0'],
  "deshon": ['D EH1 SH AH0 N'],
  "deshong": ['D EH1 SH AO0 NG'],
  "deshotel": ['D EH1 SH AH0 T AH0 L'],
  "deshotels": ['D EH1 SH AH0 T AH0 L Z'],
  "desi": ['D EH1 Z IY0'],
  "desiccate": ['D EH1 S AH0 K EY0 T'],
  "desiccated": ['D EH1 S AH0 K EY0 T AH0 D'],
  "desiccates": ['D EH1 S AH0 K EY0 T S'],
  "desiccation": ['D EH2 S AH0 K EY1 SH AH0 N'],
  "desiderata": ['D EY2 Z IH0 D ER0 AA1 T AH0'],
  "desiderio": ['D IH0 S IY0 D EH1 R IY0 OW0'],
  "design": ['D IH0 Z AY1 N'],
  "designate": ['D EH1 Z AH0 G N EY2 T', 'D EH1 Z IH0 G N EY2 T'],
  "designated": ['D EH1 Z IH0 G N EY2 T IH0 D'],
  "designates": ['D EH1 Z IH0 G N EY2 T S'],
  "designating": ['D EH1 Z IH0 G N EY2 T IH0 NG'],
  "designation": ['D EH2 Z AH0 G N EY1 SH AH0 N', 'D EH2 Z IH0 G N EY1 SH AH0 N'],
  "designations": ['D EH2 Z AH0 G N EY1 SH AH0 N Z'],
  "designcraft": ['D IH0 Z AY1 N K R AE2 F T'],
  "designed": ['D IH0 Z AY1 N D'],
  "designee": ['D EH2 Z IH0 G N IY1'],
  "designees": ['D EH2 Z IH0 G N IY1 Z'],
  "designer": ['D IH0 Z AY1 N ER0'],
  "designer's": ['D IH0 Z AY1 N ER0 Z'],
  "designers": ['D IH0 Z AY1 N ER0 Z'],
  "designers'": ['D IH0 Z AY1 N ER0 Z'],
  "designing": ['D IH0 Z AY1 N IH0 NG'],
  "designor": ['D IH0 S IH1 G N ER0'],
  "designs": ['D IH0 Z AY1 N Z'],
  "desilets": ['D EH1 S IH0 L IH0 T S'],
  "desillers": ['D IH0 S IH1 L ER0 Z'],
  "desilva": ['D IH0 S IY1 L V AH0'],
  "desimone": ['D IH0 S IY0 M OW1 N IY0'],
  "desio": ['D IY1 S IY0 OW0'],
  "desir": ['D IH0 S IH1 R'],
  "desirability": ['D IH0 Z AY2 R AH0 B IH1 L IH0 T IY0'],
  "desirable": ['D IH0 Z AY1 R AH0 B AH0 L', 'D IH0 Z AY1 ER0 AH0 B AH0 L'],
  "desire": ['D IH0 Z AY1 ER0'],
  "desired": ['D IH0 Z AY1 ER0 D'],
  "desiree": ['D EH1 S AY0 R IY0'],
  "desires": ['D IH0 Z AY1 ER0 Z'],
  "desiring": ['D IH0 Z AY1 ER0 IH0 NG'],
  "desirous": ['D IH0 Z AY1 R AH0 S'],
  "desist": ['D IH0 S IH1 S T', 'D IH0 Z IH1 S T'],
  "desisto": ['D IH0 S IY1 S T OW0'],
  "desjardin": ['D EH1 S ZH AA0 R D AE0 N'],
  "desjardins": ['D EH1 S ZH AA0 R D IH0 N Z'],
  "desjarlais": ['D EH1 S ZH AA0 R L EY0'],
  "desk": ['D EH1 S K'],
  "deskin": ['D EH1 S K IH0 N'],
  "deskins": ['D EH1 S K IH0 N Z'],
  "deskjet": ['D EH1 S K JH EH2 T'],
  "deskpro": ['D EH1 S K P R OW2'],
  "desks": ['D EH1 S K S'],
  "desktop": ['D EH1 S K T AA2 P'],
  "desktops": ['D EH1 S K T AA2 P S'],
  "deslatte": ['D IH0 S L AE1 T'],
  "deslauriers": ['D EH1 S L AO0 R IY0 ER0 Z'],
  "desma": ['D IY1 S M AH0', 'D EH1 Z M AH0'],
  "desman": ['D EH1 Z M AH0 N'],
  "desmarais": ['D EH1 Z M ER0 EY0'],
  "desmet": ['D EH1 S M IH0 T'],
  "desmids": ['D EH1 S M AH0 D Z'],
  "desmith": ['D EH1 S M IH0 TH'],
  "desmona": ['D IH0 S M OW1 N AH0'],
  "desmond": ['D EH1 Z M AH0 N D'],
  "desmoplasia": ['D EH2 Z M OW0 P L EY1 ZH IY2 AH0'],
  "desmoplastic": ['D EH2 Z M OW0 P L AE1 S T IH0 K'],
  "desnoyers": ['D EH1 S N OY0 ER0 Z'],
  "desolate": ['D EH1 S AH0 L AH0 T', 'D EH1 Z AH0 L AH0 T', 'D EH1 S AH0 L EY2 T'],
  "desolation": ['D EH2 S AH0 L EY1 SH AH0 N'],
  "desormeaux": ['D EH1 S ER0 M OW0'],
  "desoto": ['D IH0 S OW1 T OW0'],
  "desousa": ['D IH0 S AW1 S AH0', 'D IH0 S UW1 S AH0'],
  "desouza": ['D EY0 S UW1 Z AH0'],
  "despain": ['D IH0 S P EY1 N'],
  "despair": ['D IH0 S P EH1 R'],
  "despaired": ['D IH0 S P EH1 R D'],
  "despairing": ['D IH0 S P EH1 R IH0 NG'],
  "despairs": ['D IH0 S P EH1 R Z'],
  "desper": ['D EH1 S P ER0'],
  "desperado": ['D EH2 S P ER0 AA1 D OW0'],
  "desperadoes": ['D EH2 S P ER0 AA1 D OW0 Z'],
  "desperate": ['D EH1 S P R IH0 T', 'D EH1 S P ER0 IH0 T'],
  "desperately": ['D EH1 S P ER0 AH0 T L IY0', 'D EH1 S P R AH0 T L IY0'],
  "desperation": ['D EH2 S P ER0 EY1 SH AH0 N', 'D EH2 S P ER0 EY1 SH IH0 N'],
  "despicable": ['D IH0 S P IH1 K AH0 B AH0 L'],
  "despina's": ['D EH1 S P IY0 N AH0 Z'],
  "despise": ['D IH0 S P AY1 Z'],
  "despised": ['D IH0 S P AY1 Z D'],
  "despises": ['D IH0 S P AY1 Z IH0 Z'],
  "despising": ['D IH0 S P AY1 Z IH0 NG'],
  "despite": ['D IH0 S P AY1 T'],
  "despondency": ['D IH0 S P AA1 N D AH0 N S IY0'],
  "despondent": ['D IH0 S P AA1 N D AH0 N T'],
  "desposito": ['D IH0 S P OW0 S IY1 T OW0'],
  "despot": ['D EH1 S P AH0 T'],
  "despotic": ['D IH0 S P AA1 T IH0 K'],
  "despotism": ['D EH1 S P AH0 T IH2 Z AH0 M'],
  "despres": ['D EH1 S P ER0 Z'],
  "desrochers": ['D EY0 R OW1 SH ER0 Z'],
  "desroches": ['D EY0 R OW1 SH IH0 Z'],
  "desrosier": ['D EY0 R OW1 SH IY0 ER0'],
  "desrosiers": ['D EY0 R OW1 SH IY0 ER0 Z', 'D EY2 R OW0 ZH IH1 R Z'],
  "dessauer": ['D IH0 S AW1 R', 'D EH1 S AW2 R'],
  "desselle": ['D IH0 S EH1 L'],
  "dessent": ['D IH0 S EH1 N T'],
  "dessert": ['D IH0 Z ER1 T'],
  "desserts": ['D IH0 Z ER1 T S'],
  "dest": ['D EH1 S T'],
  "destabilization": ['D IY0 S T EY2 B AH0 L AH0 Z EY1 SH AH0 N'],
  "destabilize": ['D IH0 S T EY1 B AH0 L AY2 Z'],
  "destabilized": ['D IH0 S T EY1 B AH0 L AY2 Z D'],
  "destabilizing": ['D IH0 S T EY1 B AH0 L AY2 Z IH0 NG'],
  "destec": ['D EH1 S T EH2 K'],
  "destefanis": ['D IH0 S T IH0 F AA1 N IH0 S'],
  "destefano": ['D IH0 S T EH0 F AA1 N OW0'],
  "destin": ['D EH1 S T IH0 N'],
  "destination": ['D EH2 S T AH0 N EY1 SH AH0 N', 'D EH2 S T IH0 N EY1 SH AH0 N'],
  "destinations": ['D EH2 S T AH0 N EY1 SH AH0 N Z'],
  "destined": ['D EH1 S T IH0 N D'],
  "destinies": ['D EH1 S T AH0 N IY0 Z'],
  "destiny": ['D EH1 S T AH0 N IY0'],
  "destitute": ['D EH1 S T AH0 T UW2 T'],
  "destitution": ['D EH1 S T AH0 T UW2 SH AH0 N'],
  "destroy": ['D IH0 S T R OY1'],
  "destroyed": ['D IH0 S T R OY1 D'],
  "destroyer": ['D IH0 S T R OY1 ER0'],
  "destroyers": ['D IH0 S T R OY1 ER0 Z'],
  "destroying": ['D IH0 S T R OY1 IH0 NG'],
  "destroys": ['D IH0 S T R OY1 Z'],
  "destruct": ['D IH0 S T R AH1 K T'],
  "destructable": ['D IH0 S T R AH1 K T AH0 B AH0 L'],
  "destructed": ['D IH0 S T R AH1 K T IH0 D'],
  "destructing": ['D IH0 S T R AH1 K T IH0 NG'],
  "destruction": ['D IH0 S T R AH1 K SH AH0 N'],
  "destructive": ['D IH0 S T R AH1 K T IH0 V'],
  "destructiveness": ['D IH0 S T R AH1 K T IH0 V N IH0 S'],
  "destructs": ['D IH0 S T R AH1 K T S'],
  "desultory": ['D EH1 S AH0 L T AO2 R IY0'],
  "detach": ['D IH1 T AE2 CH', 'D IY0 T AE1 CH'],
  "detachable": ['D IH0 T AE1 CH AH0 B AH0 L', 'D IY0 T AE1 CH AH0 B AH0 L'],
  "detached": ['D IH0 T AE1 CH T', 'D IY0 T AE1 CH T'],
  "detaches": ['D IH0 T AE1 CH IH0 Z', 'D IY0 T AE1 CH AH0 Z'],
  "detachment": ['D IH0 T AE1 CH M AH0 N T', 'D IY0 T AE1 CH M AH0 N T'],
  "detail": ['D IH0 T EY1 L', 'D IY1 T EY0 L'],
  "detailed": ['D IH0 T EY1 L D'],
  "detailee": ['D IH0 T EY2 L IY1'],
  "detailer": ['D IY1 T EY0 L ER0'],
  "detailing": ['D IH0 T EY1 L IH0 NG'],
  "details": ['D IH0 T EY1 L Z', 'D IY1 T EY0 L Z'],
  "detain": ['D IH0 T EY1 N'],
  "detained": ['D IH0 T EY1 N D'],
  "detainee": ['D IY2 T EY0 N IY1'],
  "detainees": ['D IH0 T EY2 N IY1 Z'],
  "detaining": ['D IH0 T EY1 N IH0 NG'],
  "detamore": ['D IH0 T AA1 M AO0 R'],
  "detar": ['D IH0 T AA1 R', 'D IY0 T AA0 R'],
  "detect": ['D IH0 T EH1 K T'],
  "detectable": ['D IH0 T EH1 K T AH0 B AH0 L'],
  "detected": ['D IH0 T EH1 K T AH0 D', 'D IH0 T EH1 K T IH0 D'],
  "detecting": ['D IH0 T EH1 K T IH0 NG'],
  "detection": ['D IH0 T EH1 K SH AH0 N'],
  "detective": ['D IH0 T EH1 K T IH0 V'],
  "detective's": ['D IH0 T EH1 K T IH0 V Z'],
  "detectives": ['D IH0 T EH1 K T IH0 V Z'],
  "detector": ['D IH0 T EH1 K T ER0'],
  "detectors": ['D IH0 T EH1 K T ER0 Z'],
  "detects": ['D IH0 T EH1 K T S'],
  "detemple": ['D EH1 T IH0 M P AH0 L'],
  "detente": ['D EY0 T AA1 N T'],
  "detention": ['D IH0 T EH1 N SH AH0 N'],
  "detentions": ['D IH0 T EH1 N SH AH0 N Z'],
  "deter": ['D IH0 T ER1'],
  "deterding": ['D EH1 T ER0 D IH0 NG'],
  "detergent": ['D IH0 T ER1 JH AH0 N T'],
  "detergents": ['D IH0 T ER1 JH AH0 N T S'],
  "deteriorate": ['D IH0 T IH1 R IY0 ER0 EY2 T'],
  "deteriorated": ['D IH0 T IH1 R IY0 ER0 EY2 T IH0 D'],
  "deteriorates": ['D IH0 T IH1 R IY0 ER0 EY2 T S'],
  "deteriorating": ['D IH0 T IH1 R IY0 ER0 EY2 T IH0 NG'],
  "deterioration": ['D IH0 T IH1 R IY0 ER0 EY2 SH AH0 N'],
  "determan": ['D IY1 T ER0 M AH0 N'],
  "determent": ['D IH0 T ER1 M AH0 N T'],
  "determinable": ['D IH0 T ER1 M AH0 N AH0 B AH0 L'],
  "determinant": ['D IH0 T ER1 M AH0 N AH0 N T'],
  "determinants": ['D IH0 T ER1 M AH0 N AH0 N T S'],
  "determinate": ['D IH0 T ER1 M AH0 N EY2 T'],
  "determination": ['D IH0 T ER2 M AH0 N EY1 SH AH0 N'],
  "determination's": ['D IH0 T ER2 M IH0 N EY1 SH AH0 N Z'],
  "determinations": ['D IH0 T ER2 M IH0 N EY1 SH AH0 N Z'],
  "determinative": ['D IH0 T ER1 M IH0 N AH0 T IH2 V'],
  "determine": ['D AH0 T ER1 M AH0 N', 'D IH0 T ER1 M AH0 N'],
  "determined": ['D IH0 T ER1 M AH0 N D'],
  "determinedly": ['D AH0 T ER1 M AH0 N AH0 D L IY0', 'D AH0 T ER1 M AH0 N D L IY0'],
  "determines": ['D AH0 T ER1 M AH0 N Z', 'D IH0 T ER1 M AH0 N Z'],
  "determining": ['D IH0 T ER1 M AH0 N IH0 NG'],
  "determinism": ['D IH0 T ER1 M AH0 N IH2 Z AH0 M'],
  "determinist": ['D IH0 T ER1 M AH0 N AH0 S T'],
  "deterministic": ['D IH0 T ER2 M AH0 N IH1 S T IH0 K'],
  "deterred": ['D IH0 T ER1 D'],
  "deterrence": ['D IH0 T ER1 AH0 N S'],
  "deterrent": ['D IH0 T ER1 R AH0 N T'],
  "deterrents": ['D IH0 T ER1 AH0 N T S'],
  "deterring": ['D IH0 T ER1 IH0 NG'],
  "deters": ['D IH0 T ER1 Z'],
  "detert": ['D EH1 T ER0 T'],
  "detest": ['D IH0 T EH1 S T', 'D IY0 T EH1 S T'],
  "detested": ['D IH0 T EH1 S T IH0 D', 'D IY0 T EH1 S T IH0 D'],
  "detherage": ['D EH1 DH ER0 IH0 JH'],
  "dethlefs": ['D EH1 TH L IH0 F S'],
  "dethlefsen": ['D EH1 TH L IH0 F S AH0 N'],
  "dethloff": ['D EH1 TH L AO0 F'],
  "dethomas": ['D IH0 TH OW1 M AH0 Z', 'D IH0 T AA1 M AH0 S'],
  "dethrone": ['D IH0 TH R OW1 N', 'D IY0 TH R OW1 N'],
  "dethroned": ['D IH0 TH R OW1 N D', 'D IY0 TH R OW1 N D'],
  "detienne": ['D EH1 T IY0 EH0 N'],
  "detjen": ['D EH1 T JH AH0 N'],
  "detlefsen": ['D EH1 T L IH0 F S AH0 N'],
  "detloff": ['D EH1 T L AO0 F'],
  "detmer": ['D EH1 T M ER0'],
  "detonate": ['D EH1 T AH0 N EY2 T'],
  "detonated": ['D EH1 T AH0 N EY2 T AH0 D'],
  "detonating": ['D EH1 T AH0 N EY2 T IH0 NG'],
  "detonation": ['D EH2 T AH0 N EY1 SH AH0 N'],
  "detonations": ['D EH2 T AH0 N EY1 SH AH0 N Z'],
  "detonator": ['D EH1 T AH0 N EY2 T ER0'],
  "detonators": ['D EH1 T AH0 N EY2 T ER0 Z'],
  "detore": ['D IH0 T AO1 R IY0'],
  "detour": ['D IH0 T UH1 R', 'D IY1 T UH0 R'],
  "detoured": ['D IY1 T UH0 R D'],
  "detours": ['D IH0 T UH1 R Z', 'D IY1 T UH0 R Z'],
  "detox": ['D IY1 T AA2 K S'],
  "detoxication": ['D IH0 T AA2 K S AH0 K EY1 SH AH0 N'],
  "detoxification": ['D IH0 T AA2 K S IH0 F IH0 K EY1 SH AH0 N'],
  "detoxify": ['D IH0 T AA1 K S AH0 F AY2'],
  "detract": ['D IH0 T R AE1 K T'],
  "detracted": ['D IH0 T R AE1 K T IH0 D'],
  "detracting": ['D IH0 T R AE1 K T IH0 NG'],
  "detractor": ['D IY0 T R AE1 K T ER0'],
  "detractors": ['D IH0 T R AE1 K T ER0 Z', 'D IY0 T R AE1 K T ER0 Z'],
  "detracts": ['D IH0 T R AE1 K T S'],
  "detrich": ['D EH1 T R IH0 K'],
  "detrick": ['D EH1 T R IH0 K'],
  "detriment": ['D EH1 T R AH0 M AH0 N T'],
  "detrimental": ['D EH2 T R AH0 M EH1 N T AH0 L', 'D EH2 T R AH0 M EH1 N AH0 L'],
  "detritus": ['D IH0 T R AY1 T AH0 S', 'D EH1 T R AH0 T AH0 S'],
  "detro": ['D IY1 T R OW0'],
  "detroit": ['D IH0 T R OY1 T', 'D IY1 T R OY2 T'],
  "detroit's": ['D AH0 T R OY1 T S', 'D IH0 T R OY1 T S'],
  "detroiters": ['D AH0 T R OY1 T ER0 Z', 'D IY1 T R OY2 T ER0 Z'],
  "detter": ['D EH1 T ER0'],
  "dettinger": ['D EH1 T IH0 N JH ER0'],
  "dettling": ['D EH1 T L IH0 NG'],
  "dettloff": ['D EH1 T L AO0 F'],
  "dettman": ['D EH1 T M AH0 N'],
  "dettmann": ['D EH1 T M AH0 N'],
  "dettmer": ['D EH1 T M ER0'],
  "dettore": ['D IH0 T AO1 R IY0'],
  "detty": ['D EH1 T IY0'],
  "deturk": ['D EH1 T ER0 K'],
  "detweiler": ['D EH1 T W AY2 L ER0'],
  "detwiler": ['D EH1 T W AY2 L ER0'],
  "detzel": ['D EH1 T Z AH0 L'],
  "deubel": ['D OY1 B AH0 L'],
  "deubler": ['D OY1 B AH0 L ER0', 'D OY1 B L ER0'],
  "deuce": ['D UW1 S'],
  "deuel": ['D UW1 EH0 L'],
  "deukmejian": ['D UW0 K M EY1 JH IY0 AH0 N'],
  "deukmejian's": ['D UW0 K M EY1 JH IY0 AH0 N Z'],
  "deupree": ['D UW0 P R IY1'],
  "deuschle": ['D OY1 SH AH0 L'],
  "deuser": ['D OY1 S ER0'],
  "deuss": ['D UW1 S'],
  "deutch": ['D OY1 CH'],
  "deuterium": ['D UW0 T IY1 R IY0 AH0 M'],
  "deuterons": ['D UW1 T ER0 AA2 N Z'],
  "deutsch": ['D OY1 CH'],
  "deutsche": ['D OY1 CH'],
  "deutschemark": ['D OY1 CH M AA2 R K'],
  "deutschemark's": ['D OY1 CH M AA2 R K S'],
  "deutschemarks": ['D OY1 CH M AA2 R K S'],
  "deutscher": ['D OY1 CH ER0'],
  "deutschland": ['D OY1 CH L AE0 N D'],
  "deutschman": ['D OY1 CH M AH0 N'],
  "deutz": ['D OY1 T S'],
  "deux": ['D UW1'],
  "dev": ['D EH1 V'],
  "deva": ['D EY1 V AH0'],
  "deval": ['D IH0 V AA1 L'],
  "deval's": ['D IH0 V AA1 L Z'],
  "devall": ['D EY0 V AA1 L'],
  "devalle": ['D IH0 V AE1 L', 'D IH0 V AE1 L IY0'],
  "devaluate": ['D IH0 V AE1 L Y UW0 EY2 T'],
  "devaluated": ['D IH0 V AE1 L Y UW0 EY2 T IH0 D'],
  "devaluation": ['D IH0 V AE2 L Y UW0 EY1 SH AH0 N', 'D IY2 V AE0 L Y UW0 EY1 SH AH0 N'],
  "devaluations": ['D IY2 V AE0 L Y UW0 EY1 SH AH0 N Z'],
  "devalue": ['D IH0 V AE1 L Y UW2'],
  "devalued": ['D IH0 V AE1 L Y UW2 D', 'D IY0 V AE1 L Y UW2 D'],
  "devaluing": ['D IH0 V AE1 L Y UW0 IH0 NG'],
  "devan": ['D EH1 V AH0 N'],
  "devane": ['D IH0 V EY1 N'],
  "devaney": ['D EH1 V AH0 N IY0'],
  "devany": ['D EH1 V AH0 N IY0'],
  "devastate": ['D EH1 V AH0 S T EY2 T'],
  "devastated": ['D EH1 V AH0 S T EY2 T IH0 D'],
  "devastating": ['D EH1 V AH0 S T EY2 T IH0 NG'],
  "devastatingly": ['D EH1 V AH0 S T EY2 T IH0 NG L IY0'],
  "devastation": ['D EH2 V AH0 S T EY1 SH AH0 N'],
  "devaughn": ['D EH1 V AO0 N'],
  "devaul": ['D IH0 V OW1 L'],
  "devault": ['D IH0 V OW1'],
  "devaux": ['D IH0 V OW1'],
  "deveau": ['D IH0 V OW1'],
  "deveaux": ['D IH0 V OW1'],
  "develcon": ['D AH0 V EH1 L K AH0 N'],
  "develle": ['D AH0 V EH1 L'],
  "develop": ['D IH0 V EH1 L AH0 P'],
  "developable": ['D IH0 V EH1 L AH0 P AH0 B AH0 L'],
  "developed": ['D IH0 V EH1 L AH0 P T'],
  "developer": ['D IH0 V EH1 L AH0 P ER0'],
  "developer's": ['D IH0 V EH1 L AH0 P ER0 Z'],
  "developers": ['D IH0 V EH1 L AH0 P ER0 Z'],
  "developers'": ['D IH0 V EH1 L AH0 P ER0 Z'],
  "developing": ['D IH0 V EH1 L AH0 P IH0 NG'],
  "development": ['D IH0 V EH1 L AH0 P M AH0 N T'],
  "development's": ['D IH0 V EH1 L AH0 P M AH0 N T S'],
  "developmental": ['D IH0 V EH2 L AH0 P M EH1 N T AH0 L', 'D IH0 V EH2 L AH0 P M EH1 N AH0 L'],
  "developmentally": ['D IH0 V EH2 L AH0 P M EH1 N AH0 L IY0', 'D IH0 V EH2 L AH0 P M EH1 N T AH0 L IY0'],
  "developments": ['D IH0 V EH1 L AH0 P M AH0 N T S'],
  "develops": ['D IH0 V EH1 L AH0 P S'],
  "devendorf": ['D EH1 V IH0 N D AO0 R F'],
  "deveney": ['D EH1 V IH0 N IY0'],
  "devenney": ['D EH1 V IH0 N IY0'],
  "devenny": ['D EH1 V IH0 N IY0'],
  "devenport": ['D IH0 V EH1 N P AO0 R T'],
  "devens": ['D IY1 V AH0 N Z'],
  "deveny": ['D IH0 V IY1 N IY0'],
  "dever": ['D IY1 V ER0'],
  "devera": ['D EY0 V EH1 R AH0'],
  "deveraux": ['D EH1 V ER0 OW0'],
  "devere": ['D EY0 V EH1 R EY0'],
  "devereaux": ['D EH1 V ER0 OW0'],
  "deverell": ['D EY0 V EH0 R EY1 L'],
  "devereux": ['D EH1 V ER0 UW2'],
  "deveroy": ['D EH1 V ER0 OY2'],
  "devers": ['D EH1 V ER0 Z'],
  "devey": ['D IH0 V EY1'],
  "deviance": ['D IY1 V IY0 AH0 N S'],
  "deviancy": ['D IY1 V IY0 EH2 N S IY0', 'D IY1 V Y EH0 N S IY0'],
  "deviant": ['D IY1 V IY0 AH0 N T'],
  "deviants": ['D IY1 V IY0 AH0 N T S'],
  "deviate": ['D IY1 V IY0 EY2 T'],
  "deviated": ['D IY1 V IY0 EY2 T IH0 D'],
  "deviates": ['D IY1 V IY0 EY2 T S'],
  "deviating": ['D IY1 V IY0 EY2 T IH0 NG'],
  "deviation": ['D IY2 V IY0 EY1 SH AH0 N'],
  "deviations": ['D IY0 V IY0 EY1 SH AH0 N Z'],
  "device": ['D IH0 V AY1 S'],
  "device's": ['D IH0 V AY1 S IH0 Z'],
  "devices": ['D IH0 V AY1 S AH0 Z', 'D IH0 V AY1 S IH0 Z'],
  "devil": ['D EH1 V AH0 L'],
  "devil's": ['D EH1 V AH0 L Z'],
  "devilbiss": ['D EH1 V IH0 L B IH0 S', 'D IH0 V IH1 L B IH0 S'],
  "devilish": ['D EH1 V L IH0 SH'],
  "devilishly": ['D EH1 V AH0 L IH0 SH L IY0', 'D EH1 V L IH0 SH L IY0'],
  "deville": ['D AH0 V IH1 L'],
  "deville's": ['D AH0 V IH1 L Z'],
  "devillier": ['D AH0 V IH1 L Y ER0'],
  "devillier's": ['D AH0 V IH1 L Y ER0 S'],
  "devils": ['D EH1 V AH0 L Z'],
  "devils'": ['D EH1 V AH0 L Z'],
  "devilwood": ['D EH1 V AH0 L W UH2 D'],
  "devin": ['D EH1 V IH0 N'],
  "devincent": ['D EY0 V IY1 N S AH0 N T'],
  "devincentis": ['D EH0 V IH0 N S EH1 N T IH0 S'],
  "devincenzi": ['D IH0 V IY0 N CH EH1 N Z IY0'],
  "devincenzo": ['D IH0 V IY0 N CH EH1 N Z OW0'],
  "devinci": ['D IH0 V IH1 N CH IY0'],
  "devinci's": ['D IH0 V IH1 N CH IY0 Z'],
  "devine": ['D AH0 V AY1 N'],
  "deviney": ['D EH1 V IH0 N IY0'],
  "devinney": ['D EH1 V IH0 N IY0'],
  "devino": ['D IH0 V IY1 N OW0'],
  "devins": ['D EH1 V IH0 N Z'],
  "devious": ['D IY1 V IY0 AH0 S'],
  "devise": ['D IH0 V AY1 Z', 'D IH0 V AY1 S'],
  "devised": ['D IH0 V AY1 Z D'],
  "devises": ['D IH0 V AY1 Z IH0 Z'],
  "devising": ['D IH0 V AY1 Z IH0 NG'],
  "devita": ['D IH0 V IY1 T AH0'],
  "devito": ['D IH0 V IY1 T OW0'],
  "devitt": ['D IH0 V IH1 T'],
  "devivo": ['D IH0 V IY1 V OW0'],
  "devlin": ['D EH1 V L IH0 N'],
  "devoe": ['D IH0 V OW1'],
  "devoid": ['D IH0 V OY1 D'],
  "devol": ['D EH1 V AO0 L'],
  "devolder": ['D EH1 V OW0 L D ER0'],
  "devoll": ['D EH1 V AH0 L'],
  "devolution": ['D EH2 V AH0 L UW1 SH AH0 N'],
  "devolve": ['D IH0 V AA1 L V'],
  "devolved": ['D IH0 V AA1 L V D'],
  "devon": ['D EH1 V AH0 N'],
  "devona": ['D EH1 V AH0 N AH0'],
  "devonian": ['D IH0 V OW1 N IY0 AH0 N'],
  "devonshire": ['D IH0 V AA1 N SH AY2 R'],
  "devor": ['D IH0 V AO1 R'],
  "devore": ['D EH1 V ER0'],
  "devos": ['D IY1 V OW0 Z'],
  "devoss": ['D IH0 V AA1 S'],
  "devote": ['D IH0 V OW1 T'],
  "devoted": ['D IH0 V OW1 T IH0 D'],
  "devotee": ['D EH2 V AH0 T IY1'],
  "devotees": ['D EH1 V AH0 T IY1 Z'],
  "devotes": ['D IH0 V OW1 T S'],
  "devoting": ['D IH0 V OW1 T IH0 NG'],
  "devotion": ['D IH0 V OW1 SH AH0 N'],
  "devotional": ['D IH0 V OW1 SH AH0 N AH0 L'],
  "devoto": ['D IH0 V OW1 T OW0'],
  "devour": ['D IH0 V AW1 ER0'],
  "devoured": ['D IH0 V AW1 ER0 D'],
  "devouring": ['D IH0 V AW1 ER0 IH0 NG'],
  "devours": ['D IH0 V AW1 ER0 Z'],
  "devout": ['D IH0 V AW1 T'],
  "devoutly": ['D IH0 V AW1 T L IY0'],
  "devoy": ['D EH1 V OY0'],
  "devries": ['D IH0 V R IY1 S'],
  "devroy": ['D EH0 V R OY1'],
  "devry": ['D EH1 V R IY0'],
  "dew": ['D UW1'],
  "dewaard": ['D UW0 AA1 R D'],
  "dewaele": ['D UW1 EH0 L'],
  "dewald": ['D UW1 AH0 L D'],
  "dewall": ['D UW1 AH0 L'],
  "dewalt": ['D UW1 AH0 L T'],
  "dewan": ['D UW1 AH0 N'],
  "dewar": ['D UW1 ER0'],
  "dewar's": ['D UW1 ER0 Z'],
  "deware": ['D UW1 EH0 R'],
  "dewarr": ['D UW1 ER0'],
  "dewart": ['D UW1 AA0 R T'],
  "dewayne": ['D IH0 W EY1 N', 'D IY0 W EY1 N'],
  "dewberry": ['D UW1 B EH2 R IY0'],
  "dewbre": ['D UW1 B ER0'],
  "dewclaw": ['D UW1 K L AO2'],
  "deweerd": ['D UW1 IH0 R D'],
  "dewees": ['D UW1 IY0 Z'],
  "deweese": ['D UW1 IY0 Z'],
  "deweiss": ['D IH0 W AY1 S'],
  "dewell": ['D EH1 W EH0 L'],
  "dewey": ['D UW1 IY0'],
  "dewey's": ['D UW1 IY0 Z'],
  "dewhirst": ['D EH1 W ER0 S T'],
  "dewhurst": ['D EH1 W ER0 S T'],
  "dewilde": ['D IY1 W AY0 L D'],
  "dewine": ['D AH0 W AY1 N'],
  "dewing": ['D UW1 IH0 NG'],
  "dewinter": ['D AH0 W IH1 N T ER0'],
  "dewire": ['D UW1 AY0 R'],
  "dewit": ['D UW1 IH0 T'],
  "dewitt": ['D AH0 W IH1 T'],
  "dewitte": ['D UW0 IH1 T'],
  "dewitz": ['D EH1 W IH0 T S'],
  "dewolf": ['D UW1 UH0 L F'],
  "dewolfe": ['D UW1 UH0 L F'],
  "dewoody": ['D IY1 W UH0 D IY0'],
  "dews": ['D UW1 Z'],
  "dewulf": ['D UW1 AH0 L F'],
  "dewy": ['D UW1 IY0'],
  "dex": ['D EH1 K S'],
  "dexfenfluramine": ['D EH2 K S AH0 N F L AO1 R AH0 M IY0 N'],
  "dexheimer": ['D EH1 K S HH AY0 M ER0'],
  "dexter": ['D EH1 K S T ER0'],
  "dexter's": ['D EH1 K S T ER0 Z'],
  "dexterity": ['D EH0 K S T EH1 R AH0 T IY0', 'D EH0 K S T EH1 R IH0 T IY0'],
  "dextra": ['D EH1 K S T R AH0'],
  "dextran": ['D EH1 K S T R AE2 N'],
  "dextrathoraphan": ['D EH0 K S T R AH0 TH AO1 R AH0 F AH0 N'],
  "dextrin": ['D EH1 K S T R IH0 N'],
  "dextro": ['D EH1 K S T R OW0'],
  "dextrose": ['D EH1 K S T R OW0 S'],
  "dextrous": ['D EH1 K S T R AH0 S'],
  "dey": ['D EY1'],
  "deyo": ['D EY1 OW0'],
  "deyoe": ['D EY1 OW0'],
  "deyoung": ['D EH1 Y AH0 NG'],
  "deyton": ['D IH0 T AO1 N', 'D EY1 T AH0 N'],
  "dezarn": ['D EY0 Z AA1 R N'],
  "dezeeuw": ['D IH0 Z IY1 UW0'],
  "dezern": ['D EY0 Z EH1 R N'],
  "deziel": ['D EH1 Z IY0 L'],
  "dfw": ['D IY1 EH1 F D AH1 B AH0 L Y UW1', 'D IY1 EH1 F D AH1 B AH0 Y UW1'],
  "dhabi": ['D AA1 B IY0'],
  "dhaharan": ['D AH0 R AA1 N'],
  "dhahran": ['D AH0 R AA1 N'],
  "dhaka": ['D AA1 K AH0', 'D AE1 K AH0', 'D AE1 K AE0'],
  "dhali": ['D AA1 L IY0'],
  "dhananjay": ['D AA1 N AH0 N JH EY2'],
  "dharamsala": ['D AA1 R AH0 M S AA1 L AA2'],
  "dharma": ['D AA1 R M AH0'],
  "dhein": ['D AY1 N'],
  "dhia": ['D IY1 AH0'],
  "dhillon": ['D IH1 L AH0 N'],
  "dhiraj": ['D IH2 R AA1 ZH'],
  "dhlakama": ['D AH0 L AH0 K AA1 M AH0'],
  "dhole": ['D OW1 L'],
  "dhondt": ['D HH AA1 N T'],
  "dhows": ['D AW1 Z'],
  "di": ['D IY1', 'D AY1'],
  "di's": ['D AY1 Z'],
  "dia": ['D IY1 AH0'],
  "diab": ['D AY1 AH0 B'],
  "diabase": ['D AY1 AH0 B EY2 S'],
  "diabasic": ['D AY2 AH0 B EY1 S IH0 K'],
  "diabetes": ['D AY2 AH0 B IY1 T IY0 Z'],
  "diabetic": ['D AY2 AH0 B EH1 T IH0 K'],
  "diabetics": ['D AY2 AH0 B EH1 T IH0 K S'],
  "diablo": ['D AY2 AE1 B L OW0', 'D IY2 AE1 B L OW0'],
  "diabolical": ['D AY2 AH0 B AA1 L IH0 K AH0 L'],
  "diaconate": ['D AY0 AE1 K AH0 N AH0 T'],
  "diacritic": ['D AY2 AH0 K R IH1 T AH0 K'],
  "diacritical": ['D AY2 AH0 K R IH1 T AH0 K AH0 L'],
  "diacritics": ['D AY2 AH0 K R IH1 T AH0 K S'],
  "diadem": ['D AY1 AH0 D EH2 M'],
  "diagnose": ['D AY2 AH0 G N OW1 S'],
  "diagnosed": ['D AY2 AH0 G N OW1 S T'],
  "diagnoses": ['D AY2 AH0 G N OW1 S IY0 Z'],
  "diagnosing": ['D AY2 AH0 G N OW1 S IH0 NG'],
  "diagnosis": ['D AY2 AH0 G N OW1 S AH0 S'],
  "diagnostek": ['D AY2 AH0 G N AA1 S T EH0 K'],
  "diagnostic": ['D AY2 AH0 G N AA1 S T IH0 K'],
  "diagnostics": ['D AY2 AH0 G N AA1 S T IH0 K S'],
  "diagonal": ['D AY0 AE1 G AH0 N AH0 L'],
  "diagonally": ['D AY0 AE1 G AH0 N AH0 L IY0'],
  "diagonals": ['D AY0 AE1 G AH0 N AH0 L Z'],
  "diagram": ['D AY1 AH0 G R AE2 M'],
  "diagraming": ['D AY1 AH0 G R AE2 M IH0 NG'],
  "diagrammatic": ['D AY2 AH0 G R AH0 M AE1 T IH0 K'],
  "diagrammed": ['D AY1 AH0 G R AE2 M D'],
  "diagrams": ['D AY1 AH0 G R AE2 M Z'],
  "diahann": ['D AY2 AE1 N'],
  "dial": ['D AY1 AH0 L', 'D AY1 L'],
  "dial's": ['D AY1 AH0 L Z', 'D AY1 L Z'],
  "dialect": ['D AY1 AH0 L EH2 K T'],
  "dialectic": ['D AY2 AH0 L EH1 K T IH0 K'],
  "dialectical": ['D AY2 AH0 L EH1 K T IH0 K AH0 L'],
  "dialects": ['D AY1 AH0 L EH2 K T S'],
  "dialed": ['D AY1 AH0 L D'],
  "dialing": ['D AY1 AH0 L IH0 NG', 'D AY1 L IH0 NG'],
  "dialog": ['D AY1 AH0 L AO0 G'],
  "dialogue": ['D AY1 AH0 L AO2 G'],
  "dialogues": ['D AY1 AH0 L AO2 G Z'],
  "dials": ['D AY1 AH0 L Z', 'D AY1 L Z'],
  "dialtone": ['D AY1 AH0 L T OW2 N', 'D AY1 L T OW2 N'],
  "dialysis": ['D AY0 AE1 L AH0 S AH0 S', 'D AY0 AE1 L IH0 S IH0 S'],
  "diamagnetic": ['D AY2 AH0 M AE0 G N EH1 T IH0 K'],
  "diamagnetism": ['D AY2 AH0 M AE1 G N IH0 T IH2 Z AH0 M'],
  "diamandis": ['D AY2 AH0 M AE1 N D IH0 S', 'D IY2 AH0 M AE1 N D IH0 S'],
  "diamant": ['D AY1 AH0 M AH0 N T'],
  "diamanta": ['D AY2 AH0 M AE1 N T AH0'],
  "diamante": ['D AY2 AH0 M AA1 N T IY0'],
  "diameter": ['D AY0 AE1 M AH0 T ER0'],
  "diametrically": ['D AY2 AH0 M EH1 T R IH0 K AH0 L IY0', 'D AY2 AH0 M EH1 T R IH0 K L IY0'],
  "diamond": ['D AY1 M AH0 N D'],
  "diamond's": ['D AY1 M AH0 N D Z'],
  "diamonds": ['D AY1 M AH0 N D Z'],
  "diamono": ['D AY2 AH0 M AA1 N OW0'],
  "dian": ['D AY1 AH0 N'],
  "diana": ['D AY0 AE1 N AH0'],
  "diana's": ['D AY0 AE1 N AH0 Z'],
  "diane": ['D AY0 AE1 N'],
  "diane's": ['D AY0 AE1 N Z'],
  "dianetics": ['D AY2 AH0 N EH1 T IH0 K S'],
  "diangelo": ['D AY0 AH0 NG G EH1 L OW0'],
  "dianna": ['D AY2 AE1 N AH0'],
  "dianne": ['D AY0 AE1 N'],
  "diantha": ['D AY2 AE1 N TH AH0'],
  "dianthe": ['D AY0 AE1 N DH IY0'],
  "dianthia": ['D AY2 AE1 N TH IY0 AH0'],
  "diantonio": ['D AY2 AH0 N T OW1 N IY0 OW0'],
  "diaper": ['D AY1 P ER0'],
  "diapering": ['D AY1 P ER0 IH0 NG'],
  "diapers": ['D AY1 AH0 P ER0 Z', 'D AY1 P ER0 Z'],
  "diaphanous": ['D AY0 AE1 F AH0 N IH0 S'],
  "diaphonia": ['D AY2 AH0 F OW1 N IY0 AH0'],
  "diaphragm": ['D AY1 AH0 F R AE2 M'],
  "diapsid": ['D AY2 AE1 P S IH0 D'],
  "diaries": ['D AY1 ER0 IY0 Z', 'D AY1 R IY0 Z'],
  "diario": ['D AY0 EH1 R IY0 OW0'],
  "diarrhea": ['D AY2 ER0 IY1 AH0'],
  "diarrheas": ['D AY2 ER0 IY1 AH0 Z'],
  "diarrhoea": ['D AY2 ER0 IY1 AH0'],
  "diary": ['D AY1 ER0 IY0', 'D AY1 R IY0'],
  "dias": ['D AY1 AH0 Z'],
  "diasa": ['D IY0 AA1 S AH0'],
  "diasa's": ['D IY0 AA1 S AH0 Z'],
  "diasonic": ['D AY2 AH0 S AA1 N IH0 K'],
  "diasonics": ['D AY2 AH0 S AA1 N IH0 K S'],
  "diaspora": ['D AY0 AE1 S P ER0 AH0'],
  "diastase": ['D AY1 AH0 S T EY2 S'],
  "diastole": ['D AY0 AE1 S T AH0 L IY2'],
  "diastolic": ['D AY2 AH0 S T AA1 L IH0 K'],
  "diastrophism": ['D AY0 AE1 S T R AH0 F IH2 Z AH0 M'],
  "diathermy": ['D AY1 AH0 TH ER2 M IY0'],
  "diatomic": ['D AY2 AH0 T AA1 M IH0 K'],
  "diatoms": ['D AY1 AH0 T AA2 M Z'],
  "diatonic": ['D AY2 AH0 T AA1 N IH0 K'],
  "diatribe": ['D AY1 AH0 T R AY2 B'],
  "diatribes": ['D AY1 AH0 T R AY2 B Z'],
  "diaz": ['D IY1 AE2 Z', 'D IY1 AA2 Z'],
  "diaz-calderon": ['D IY1 AE2 Z K AE2 L D ER0 OW1 N'],
  "diazo": ['D AY0 AE1 Z OW2'],
  "dibacco": ['D IH0 B AA1 K OW2'],
  "dibari": ['D IH0 B AA1 R IY0'],
  "dibartolo": ['D IY2 B AA0 R T OW1 L OW2'],
  "dibartolomeo": ['D IH0 B AA2 R T OW0 L OW1 M IY0 OW2'],
  "dibattista": ['D IH0 B AA0 T IY1 S T AA0'],
  "dibb": ['D IH1 B'],
  "dibbern": ['D IH1 B ER0 N'],
  "dibble": ['D IH1 B AH0 L'],
  "dibbled": ['D IH1 B AH0 L D'],
  "dibella": ['D IH0 B EH1 L AH0'],
  "dibello": ['D IH0 B EH1 L OW0'],
  "dibenedetto": ['D IH0 B IH0 N AH0 D EH1 T OW0'],
  "dibernardo": ['D IH0 B ER0 N AA1 R D OW2'],
  "dibert": ['D IH1 B ER0 T'],
  "dibiase": ['D IY2 B IY0 AA1 S IY0'],
  "dibiasio": ['D IH0 B IY0 AA1 S IY0 OW2'],
  "diblasi": ['D IH0 B L AA1 S IY2'],
  "diblasio": ['D IH0 B L AA1 S IY0 OW2'],
  "dible": ['D AY1 B AH0 L'],
  "diboll": ['D IH1 B AH0 L'],
  "dibona": ['D IH0 B OW1 N AA0'],
  "dibrell": ['D IH1 B R AH0 L'],
  "dibs": ['D IH1 B Z'],
  "dibuono": ['D IH0 B W OW1 N OW2'],
  "dic": ['D IH1 K'],
  "dicamba": ['D IH0 K AE1 M B AH0'],
  "dicamillo": ['D IH0 K AA0 M IH1 L OW2'],
  "dicaprio": ['D IH0 K AE1 P R IY0 OW2'],
  "dicarlo": ['D IH0 K AA1 R L OW2'],
  "dice": ['D AY1 S'],
  "dicecco": ['D IH0 S EH1 K OW2'],
  "diced": ['D AY1 S T'],
  "dicello": ['D IH0 S EH1 L OW2'],
  "dicenso": ['D IH0 S EH1 N S OW2'],
  "dicenzo": ['D IH0 S EH1 N Z OW2'],
  "diceon": ['D IH1 S IY0 AH0 N'],
  "dicesare": ['D IH0 CH EH0 S AA1 R IY0'],
  "dicey": ['D AY1 S IY0'],
  "dichiara": ['D IH0 K IY0 AA1 R AA0'],
  "dichotomy": ['D AY0 K AA1 T AH0 M IY0'],
  "dichroic": ['D AY0 K R OW1 IH0 K'],
  "dichromate": ['D AY0 K R OW1 M EY2 T', 'D AY1 K R OW0 M EY2 T'],
  "dichter": ['D IH1 K T ER0'],
  "dicicco": ['D IH0 S IH1 K OW2'],
  "dicier": ['D AY1 S IY0 ER0'],
  "dicing": ['D AY1 S IH0 NG'],
  "dicioccio": ['D IH0 CH OW1 CH IY0 OW2'],
  "dick": ['D IH1 K'],
  "dick's": ['D IH1 K S'],
  "dickard": ['D IH1 K ER0 D'],
  "dickason": ['D IH1 K AH0 S AH0 N'],
  "dicke": ['D IH1 K'],
  "dickel": ['D IH1 K AH0 L'],
  "dicken": ['D IH1 K AH0 N'],
  "dickens": ['D IH1 K AH0 N Z'],
  "dickens'": ['D IH1 K AH0 N Z'],
  "dickens's": ['D IH1 K AH0 N Z IH0 Z'],
  "dickensheets": ['D IH1 K AH0 N SH IY2 T S'],
  "dickensian": ['D IH0 K EH1 N Z IY0 AH0 N'],
  "dickenson": ['D IH1 K IH0 N S AH0 N'],
  "dicker": ['D IH1 K ER0'],
  "dickered": ['D IH1 K ER0 D'],
  "dickering": ['D IH1 K ER0 IH0 NG'],
  "dickerman": ['D IH1 K ER0 M AH0 N'],
  "dickers": ['D IH1 K ER0 Z'],
  "dickerson": ['D IH1 K ER0 S AH0 N'],
  "dickert": ['D IH1 K ER0 T'],
  "dickes": ['D IH1 K S'],
  "dickeson": ['D IH1 K IH0 S AH0 N'],
  "dickey": ['D IH1 K IY0'],
  "dickey's": ['D IH1 K IY0 Z'],
  "dickhaut": ['D IH1 K HH AW2 T'],
  "dickhead": ['D IH1 K HH EH2 D'],
  "dickie": ['D IH1 K IY0'],
  "dickinson": ['D IH1 K IH0 N S AH0 N'],
  "dickison": ['D IH1 K IH0 S AH0 N'],
  "dickler": ['D IH1 K L ER0'],
  "dickman": ['D IH1 K M AH0 N'],
  "dickmann": ['D IH1 K M AH0 N'],
  "dickmeyer": ['D IH1 K M AY0 ER0'],
  "dicks": ['D IH1 K S'],
  "dickson": ['D IH1 K S AH0 N'],
  "dickstein": ['D IH1 K S T AY0 N', 'D IH1 K S T IY0 N'],
  "dickstein's": ['D IH1 K S T AY2 N Z', 'D IH1 K S T IY2 N Z'],
  "dicky": ['D IH1 K IY0'],
  "diclemente": ['D IH2 K L AH0 M EH1 N T EY0'],
  "dicocco": ['D IH0 K OW1 K OW2'],
  "dicola": ['D IH0 K OW1 L AH0'],
  "dicomed": ['D IH1 K AH0 M EH0 D', 'D IY0 K OW1 M D'],
  "dicostanzo": ['D IH0 K OW0 S T AA1 N Z OW2'],
  "dicots": ['D AY1 K AA0 T S'],
  "dicta": ['D IH1 K T AA0'],
  "dictaphone": ['D IH1 K T AH0 F OW2 N'],
  "dictate": ['D IH0 K T EY1 T', 'D IH1 K T EY2 T'],
  "dictated": ['D IH0 K T EY1 T IH0 D', 'D IH1 K T EY2 T IH0 D'],
  "dictates": ['D IH0 K T EY1 T S', 'D IH1 K T EY2 T S'],
  "dictating": ['D IH1 K T EY2 T IH0 NG'],
  "dictation": ['D IH0 K T EY1 SH AH0 N'],
  "dictator": ['D IH0 K T EY1 T ER0', 'D IH1 K T EY0 T ER0'],
  "dictatorial": ['D IH2 K T AH0 T AO1 R IY0 AH0 L'],
  "dictators": ['D IH0 K T EY1 T ER0 Z', 'D IH1 K T EY0 T ER0 Z'],
  "dictatorship": ['D IH0 K T EY1 T ER0 SH IH2 P'],
  "dictatorships": ['D IH0 K T EY1 T ER0 SH IH2 P S'],
  "diction": ['D IH1 K SH AH0 N'],
  "dictionaries": ['D IH1 K SH AH0 N EH2 R IY0 Z'],
  "dictionary": ['D IH1 K SH AH0 N EH2 R IY0'],
  "dictum": ['D IH1 K T AH0 M'],
  "dicus": ['D AY1 K AH0 S'],
  "did": ['D IH1 D', 'D IH0 D'],
  "didactic": ['D AY0 D AE1 K T IH0 K'],
  "diddle": ['D IH1 D AH0 L'],
  "diddley": ['D IH1 D L IY0'],
  "diddy": ['D IH1 D IY0'],
  "didemeyer": ['D IY1 D AH0 M AY2 ER0'],
  "didemeyer's": ['D IY1 D AH0 M AY2 ER0 Z'],
  "dideoxycytidine": ['D IH2 D IY0 AA2 K S IY0 S AY1 T IH0 D AY2 N'],
  "didi": ['D IY1 D IY0'],
  "didier": ['D IH1 D IY0 ER0'],
  "didinium": ['D IH0 D IH1 N IY0 AH0 M'],
  "didion": ['D IH1 D IY0 AH0 N'],
  "didion's": ['D IH1 D IY0 AH0 N Z'],
  "didn't": ['D IH1 D AH0 N T', 'D IH1 D N T', 'D IH1 D AH0 N', 'D IH1 N T'],
  "dido": ['D AY1 D OW0'],
  "didomenico": ['D IH0 D OW0 M EH1 N IH0 K OW2'],
  "didonato": ['D IH0 D OW0 N AA1 T OW2'],
  "didonna": ['D IH0 D AA1 N AA0'],
  "die": ['D AY1'],
  "diebel": ['D IY1 B AH0 L'],
  "diebold": ['D AY1 B OW2 L D'],
  "dieck": ['D IY1 K'],
  "dieckman": ['D IY1 K M AH0 N'],
  "dieckmann": ['D IY1 K M AH0 N'],
  "died": ['D AY1 D'],
  "diede": ['D IY1 D'],
  "diederich": ['D IY1 D ER0 IH0 K'],
  "diedre": ['D IY1 D R AH0'],
  "diedrich": ['D IY1 D R IH0 K'],
  "diedrick": ['D IY1 D R IH0 K'],
  "diefenbach": ['D IY1 F IH0 N B AA0 K'],
  "diefenderfer": ['D IY1 F IH0 N D ER0 F ER0'],
  "diefendorf": ['D IY1 F IH0 N D AO0 R F'],
  "dieffenbach": ['D IY1 F IH0 N B AA0 K'],
  "diegans": ['D IY1 G AH0 N Z'],
  "diegel": ['D IY1 G AH0 L'],
  "diego": ['D IY0 EY1 G OW0'],
  "diego's": ['D IY2 EY1 G OW2 Z'],
  "diego-garcia": ['D IY0 EY1 G OW0 G AA2 R S IY1 AH0'],
  "dieguez": ['D IH0 G EH1 Z'],
  "diehard": ['D AY1 HH AA2 R D'],
  "diehards": ['D AY1 HH AA2 R D Z'],
  "diehl": ['D IY1 L'],
  "diehm": ['D IY1 M'],
  "diekman": ['D IY1 K M AH0 N'],
  "diekmann": ['D IY1 K M AH0 N'],
  "diel": ['D IY1 L'],
  "dielectric": ['D AY2 AH0 L EH1 K T R IH0 K'],
  "diem": ['D IY1 M'],
  "diemer": ['D IY1 M ER0'],
  "diemert": ['D IY1 M ER0 T'],
  "diener": ['D IY1 N ER0'],
  "dienes": ['D IY1 N EH0 Z'],
  "dienst": ['D IY1 N S T'],
  "diep": ['D IY1 P'],
  "dier": ['D IY1 ER0'],
  "diercks": ['D IY1 R K S'],
  "dieringer": ['D IH1 R IH0 N JH ER0'],
  "dierker": ['D IY1 R K ER0'],
  "dierkes": ['D IY1 R K S'],
  "dierking": ['D AY1 ER0 K IH0 NG'],
  "dierks": ['D IY1 R K S'],
  "dierolf": ['D IH1 R OW0 L F'],
  "diers": ['D IY1 ER0 Z'],
  "diery": ['D IH1 R IY0'],
  "dies": ['D AY1 Z'],
  "diesel": ['D IY1 S AH0 L', 'D IY1 Z AH0 L'],
  "diesels": ['D IY1 Z AH0 L Z'],
  "dieses": ['D AY1 Z IH0 Z'],
  "diesing": ['D IY1 S IH0 NG'],
  "diet": ['D AY1 AH0 T'],
  "diet's": ['D AY1 AH0 T S'],
  "dietary": ['D AY1 AH0 T EH2 R IY0'],
  "dietel": ['D IY1 T AH0 L'],
  "dieter": ['D IY1 T ER0'],
  "dieterich": ['D IY1 T ER0 IH0 K'],
  "dieterle": ['D IY1 T ER0 AH0 L'],
  "dieters": ['D AY1 AH0 T ER0 Z'],
  "dietetic": ['D AY2 AH0 T EH1 T IH0 K'],
  "dietician": ['D AY2 AH0 T IH1 SH AH0 N'],
  "dieting": ['D AY1 AH0 T IH0 NG'],
  "dietitian": ['D AY2 AH0 T IH1 SH AH0 N'],
  "dietitian's": ['D AY2 AH0 T IH1 SH AH0 N Z'],
  "dietitians": ['D AY2 AH0 T IH1 SH AH0 N Z'],
  "dietl": ['D AY1 AH0 T L'],
  "dietrich": ['D IY1 T R IH0 K'],
  "dietrick": ['D IY1 T R IH0 K'],
  "diets": ['D AY1 IH0 T S'],
  "dietsch": ['D IY1 CH'],
  "dietsche": ['D IY1 CH'],
  "dietz": ['D IY1 T S'],
  "dietze": ['D AY1 AH0 T Z'],
  "dietzel": ['D IY1 T Z AH0 L'],
  "dietzen": ['D IY1 T Z AH0 N'],
  "dietzler": ['D IY1 T S L ER0'],
  "dietzman": ['D IY1 T S M AH0 N'],
  "dievler": ['D IY1 V L ER0'],
  "diez": ['D AY1 AH0 Z'],
  "dif": ['D IH1 F'],
  "difabio": ['D IH0 F EY1 B IY0 OW2', 'D IH0 F AE1 B IY0 OW2'],
  "difalco": ['D IH0 F AE1 L K OW2'],
  "difazio": ['D IH0 F EY1 Z IY0 OW2'],
  "difelice": ['D IH0 F EH1 L IH0 S', 'D IH0 F EH1 L IY0 S', 'D IH0 F IH0 L IY1 CH EY0'],
  "diff": ['D IH1 F'],
  "diffee": ['D IH1 F IY0'],
  "diffenderfer": ['D IH1 F IH0 N D ER0 F ER0'],
  "differ": ['D IH1 F ER0'],
  "differed": ['D IH1 F ER0 D'],
  "difference": ['D IH1 F ER0 AH0 N S', 'D IH1 F R AH0 N S'],
  "differences": ['D IH1 F ER0 AH0 N S IH0 Z', 'D IH1 F R AH0 N S AH0 Z'],
  "different": ['D IH1 F ER0 AH0 N T', 'D IH1 F R AH0 N T'],
  "differential": ['D IH2 F ER0 EH1 N SH AH0 L'],
  "differentials": ['D IH2 F ER0 EH1 N SH AH0 L Z'],
  "differentiate": ['D IH2 F ER0 EH1 N SH IY0 EY2 T', 'D IH2 F ER0 EH1 N CH IY0 EY2 T'],
  "differentiated": ['D IH2 F ER0 EH1 N SH IY0 EY2 T IH0 D', 'D IH2 F ER0 EH1 N CH IY0 EY2 T AH0 D'],
  "differentiates": ['D IH0 F ER0 EH1 N SH IY0 EY2 T S', 'D IH2 F ER0 EH1 N CH IY0 EY2 T S'],
  "differentiating": ['D IH2 F ER0 EH1 N SH IY0 EY2 T IH0 NG', 'D IH2 F ER0 EH1 N CH IY0 EY2 T IH0 NG'],
  "differentiation": ['D IH0 F ER0 EH2 N SH IY0 EY1 SH AH0 N', 'D IH2 F ER0 EH2 N CH IY0 EY1 SH AH0 N'],
  "differently": ['D IH1 F R AH0 N T L IY0', 'D IH1 F ER0 EH1 N T L IY0'],
  "differing": ['D IH1 F ER0 IH0 NG', 'D IH1 F R IH0 NG'],
  "differs": ['D IH1 F ER0 Z'],
  "difficult": ['D IH1 F AH0 K AH0 L T'],
  "difficulties": ['D IH1 F AH0 K AH0 L T IY0 Z', 'D IH1 F IH0 K AH2 L T IY0 Z'],
  "difficultly": ['D IH1 F AH0 K AH0 L T L IY0'],
  "difficulty": ['D IH1 F AH0 K AH0 L T IY0', 'D IH1 F IH0 K AH2 L T IY0'],
  "diffid": ['D IH1 F AH0 D'],
  "diffin": ['D IH1 F IH0 N'],
  "diffley": ['D IH1 F L IY0'],
  "diffract": ['D IH0 F R AE1 K T'],
  "diffraction": ['D IH0 F R AE1 K SH AH0 N'],
  "diffuse": ['D IH0 F Y UW1 S', 'D IH0 F Y UW1 Z'],
  "diffused": ['D IH0 F Y UW1 Z D'],
  "diffuses": ['D IH0 F Y UW1 Z AH0 Z'],
  "diffusing": ['D IH0 F Y UW1 Z IH0 NG'],
  "diffusion": ['D IH0 F Y UW1 ZH AH0 N'],
  "difilippo": ['D IH0 F IY0 L IY1 P OW2'],
  "difiore": ['D IH0 F IY0 AO1 R IY2', 'D AH0 F Y AO1 R IY0'],
  "difm": ['D IH1 F M', 'D IY1 AY1 EH1 F EH1 M'],
  "difonzo": ['D IH0 F AA1 N Z OW2'],
  "difrancesco": ['D IH0 F R AA0 N CH EH1 S K OW2'],
  "difranco": ['D IH0 F R AA1 N K OW2'],
  "dig": ['D IH1 G'],
  "digaetano": ['D IH0 JH AH0 T AA1 N OW2'],
  "digalakis": ['D IH0 JH AH0 L AA1 K AH0 S'],
  "digangi": ['D IH0 G AE1 N JH IY0'],
  "digate": ['D AY1 G EY2 T'],
  "digby": ['D IH1 G B IY0'],
  "digennaro": ['D IH0 JH EH0 N AA1 R OW2'],
  "digenova": ['D IY2 JH EH0 N OW1 V AA2'],
  "digeronimo": ['D IH0 JH ER0 OW0 N IY1 M OW0'],
  "digest": ['D AY0 JH EH1 S T', 'D AY1 JH EH0 S T'],
  "digest's": ['D AY1 JH EH2 S T S'],
  "digested": ['D AY1 JH EH2 S T IH0 D'],
  "digester": ['D AY1 JH EH2 S T ER0'],
  "digestible": ['D AY0 JH EH1 S T AH0 B AH0 L'],
  "digesting": ['D AY0 JH EH1 S T IH0 NG', 'D AY1 JH EH2 S T IH0 NG'],
  "digestion": ['D AY0 JH EH1 S CH AH0 N'],
  "digestive": ['D AY0 JH EH1 S T IH0 V'],
  "digests": ['D AY2 JH EH1 S T S', 'D AY1 JH EH0 S T S'],
  "digga": ['D IH1 G AH0'],
  "digger": ['D IH1 G ER0'],
  "diggers": ['D IH1 G ER0 Z'],
  "digges": ['D IH1 G Z'],
  "digging": ['D IH1 G IH0 NG'],
  "diggins": ['D IH1 G IH0 N Z'],
  "diggle": ['D IH1 G AH0 L'],
  "diggs": ['D IH1 G Z'],
  "digiacomo": ['D IY1 JH AH0 K OW0 M OW0'],
  "digicon": ['D IH1 JH IH0 K AA2 N'],
  "digidyne": ['D IH1 JH IH0 D AY2 N'],
  "digilio": ['D IH2 JH IY1 L IY0 OW0'],
  "digioia": ['D IH2 JH OW1 Y AH0'],
  "digiorgio": ['D IH2 JH AO1 R JH IY0 OW2'],
  "digiovanna": ['D IH2 JH OW0 V AA1 N AA2'],
  "digiovanni": ['D IH2 JH OW0 V AA1 N IY2'],
  "digirolamo": ['D IH2 JH IH0 R OW0 L AA1 M OW2'],
  "digit": ['D IH1 JH AH0 T', 'D IH1 JH IH0 T'],
  "digital": ['D IH1 JH AH0 T AH0 L', 'D IH1 JH IH0 T AH0 L'],
  "digital's": ['D IH1 JH AH0 T AH0 L Z', 'D IH1 JH IH0 T AH0 L Z'],
  "digitalis": ['D IH2 JH AH0 T AE1 L AH0 S'],
  "digitally": ['D IH1 JH AH0 T AH0 L IY0'],
  "digitech": ['D IH1 JH AH0 T EH2 K'],
  "digiti": ['D IH1 JH AH0 T AY2', 'D IH1 JH AH0 T IY2'],
  "digitize": ['D IH1 JH AH0 T AY2 Z'],
  "digitized": ['D IH1 JH AH0 T AY2 Z D'],
  "digitizing": ['D IH1 JH AH0 T AY2 Z IH0 NG'],
  "digits": ['D IH1 JH AH0 T S', 'D IH1 JH IH0 T S'],
  "digiulio": ['D IH2 JH UW1 L IY0 OW2'],
  "digiuseppe": ['D IY2 JH UW0 S EH1 P IY2'],
  "digman": ['D IH1 G M AH0 N'],
  "dignan": ['D IH1 G N AH0 N'],
  "dignified": ['D IH1 G N AH0 F AY2 D'],
  "dignify": ['D IH1 G N AH0 F AY2'],
  "dignitaries": ['D IH1 G N AH0 T EH2 R IY0 Z'],
  "dignitary": ['D IH1 G N AH0 T EH2 R IY0'],
  "dignity": ['D IH1 G N AH0 T IY0'],
  "digrazia": ['D IH2 G R AA1 Z IY0 AA2'],
  "digregorio": ['D IH2 G R EH0 G AO1 R IY0 OW2'],
  "digress": ['D AY0 G R EH1 S'],
  "digressed": ['D AY0 G R EH1 S T'],
  "digresses": ['D AY0 G R EH1 S IH0 Z'],
  "digressing": ['D AY0 G R EH1 S IH0 NG'],
  "digression": ['D AY0 G R EH1 SH AH0 N'],
  "digressions": ['D AY0 G R EH1 SH AH0 N Z'],
  "digs": ['D IH1 G Z'],
  "diguglielmo": ['D IH2 G UW2 G L IY0 EH1 L M OW2'],
  "diianni": ['D IY2 AE1 N IY0'],
  "diiorio": ['D IH0 Y AO1 R IY0 OW2'],
  "dijker": ['D IY1 K ER0'],
  "dijon": ['D IY1 ZH AA2 N', 'D IY0 ZH OW1 N'],
  "dike": ['D AY1 K'],
  "dikeman": ['D AY1 K M AH0 N'],
  "dikes": ['D AY1 K S'],
  "dilapidate": ['D AH0 L AE1 P AH0 D EY2 T'],
  "dilapidated": ['D AH0 L AE1 P AH0 D EY2 T IH0 D'],
  "dilatation": ['D IH2 L AH0 T EY1 SH AH0 N'],
  "dilate": ['D AY2 L EY1 T'],
  "dilated": ['D AY2 L EY1 T IH0 D'],
  "dilation": ['D AY2 L EY1 SH AH0 N'],
  "dilatory": ['D IH1 L AH0 T AO2 R IY0'],
  "dilaura": ['D IH0 L AO1 R AH0'],
  "dilauro": ['D IH0 L AO1 R OW0'],
  "dilbeck": ['D IH1 L B EH2 K'],
  "dilbert": ['D IH0 L B ER1 T'],
  "dilday": ['D IH1 L D EY2'],
  "dildine": ['D IH0 L D IY1 N IY0'],
  "dildo": ['D IH1 L D OW2'],
  "dildos": ['D IH1 L D OW2 Z'],
  "dildy": ['D IH1 L D IY0'],
  "dilella": ['D IH0 L EH1 L AA2'],
  "dilello": ['D IH0 L EH1 L OW2'],
  "dilemma": ['D IH0 L EH1 M AH0'],
  "dilemmas": ['D AH0 L EH1 M AH0 Z'],
  "dilenschneider": ['D AY1 L AH0 N SH N AY2 D ER0'],
  "dileo": ['D IH1 L IY0 OW0'],
  "dileonardo": ['D IH0 L IY0 AH0 N AA1 R D OW0'],
  "diles": ['D AY1 L Z'],
  "dilford": ['D IH1 L F ER0 D'],
  "dilford's": ['D IH1 L F ER0 D Z'],
  "dilg": ['D IH1 L G'],
  "dilger": ['D IH1 L G ER0'],
  "diliberto": ['D IH0 L IY0 B EH1 R T OW2'],
  "diligence": ['D IH1 L AH0 JH AH0 N S', 'D IH1 L IH0 JH AH0 N S'],
  "diligent": ['D IH1 L IH0 JH AH0 N T'],
  "diligently": ['D IH1 L AH0 JH AH0 N T L IY0'],
  "dilip": ['D IH1 L IH0 P'],
  "dilithium": ['D AY2 L IH1 TH IY0 AH0 M'],
  "dilks": ['D IH1 L K S'],
  "dill": ['D IH1 L'],
  "dillahunt": ['D IH1 L AH0 HH AH2 N T'],
  "dillahunty": ['D IH1 L AH0 HH AH2 N T IY0'],
  "dillan": ['D IH1 L AH0 N'],
  "dillard": ['D IH1 L ER0 D', 'D IH1 L AA1 R D', 'D IH1 L AH0 D'],
  "dillard's": ['D IH1 L ER0 D Z', 'D IH1 L AA1 R D Z', 'D IH1 L AH0 D Z'],
  "dille": ['D IH1 L'],
  "dillehay": ['D IH1 L IH0 HH EY0'],
  "dillen": ['D IH1 L AH0 N'],
  "dillenbeck": ['D IH1 L AH0 N B EH2 K'],
  "dillenburg": ['D IH1 L AH0 N B ER0 G'],
  "diller": ['D IH1 L ER0'],
  "diller's": ['D IH1 L ER0 Z'],
  "dilletante": ['D IH1 L AH0 T AA0 N T'],
  "dilletantes": ['D IH1 L AH0 T AA0 N T S'],
  "dilley": ['D IH1 L IY0'],
  "dillie": ['D IH1 L IY0'],
  "dillin": ['D IH1 L IH0 N'],
  "dilling": ['D IH1 L IH0 NG'],
  "dillinger": ['D IH1 L IH0 NG ER0'],
  "dillingham": ['D IH1 L IH0 NG HH AE2 M'],
  "dillion": ['D IH1 L Y AH0 N'],
  "dillman": ['D IH1 L M AH0 N'],
  "dillmore": ['D IH1 L M AO0 R'],
  "dillon": ['D IH1 L AH0 N'],
  "dillon's": ['D IH1 L AH0 N Z'],
  "dillow": ['D IH1 L OW0'],
  "dills": ['D IH1 L Z'],
  "dillworth": ['D IH1 L W ER2 TH'],
  "dilly": ['D IH1 L IY0'],
  "dillydally": ['D IH1 L IY0 D AE1 L IY0'],
  "dilmore": ['D IY1 L M AO0 R'],
  "dilophosaurus": ['D IH0 L AA2 F AH0 S AO1 R AH0 S'],
  "dilorenzo": ['D IH0 L AO0 R EH1 N Z OW2'],
  "diloreto": ['D IH0 L AO0 R EH1 T OW2'],
  "dilorio": ['D IH0 L AO1 R IY0 OW2'],
  "dils": ['D IH1 L Z'],
  "dilsaver": ['D IH1 L S AH0 V ER0'],
  "dilson": ['D IH1 L S AH0 N'],
  "diltiazem": ['D IH0 L T IY1 AH0 Z EH2 M'],
  "dilts": ['D IH1 L T S'],
  "diltz": ['D IH1 L T S'],
  "dilullo": ['D IH0 L UW1 L OW2'],
  "dilute": ['D AY0 L UW1 T', 'D IH0 L UW1 T'],
  "diluted": ['D AY0 L UW1 T AH0 D', 'D IH0 L UW1 T AH0 D'],
  "dilutes": ['D AY0 L UW1 T S', 'D IH0 L UW1 T S'],
  "diluting": ['D AY0 L UW1 T IH0 NG', 'D IH0 L UW1 T IH0 NG'],
  "dilution": ['D AY0 L UW1 SH AH0 N', 'D IH0 L UW1 SH AH0 N'],
  "dilutive": ['D AH0 L UW1 T IH0 V', 'D IY0 L UW1 T IH0 V'],
  "diluzio": ['D IH0 L UW1 Z IY0 OW0'],
  "dilworth": ['D IH1 L W ER0 TH'],
  "dim": ['D IH1 M'],
  "dimaggio": ['D IH0 M AE1 JH IY0 OW2'],
  "dimaio": ['D IH0 M AA1 IY0 OW2'],
  "dimambro": ['D IH0 M AE1 M B R OW2'],
  "dimarco": ['D IH0 M AA1 R K OW2'],
  "dimare": ['D IH0 M AA1 R IY2'],
  "dimaria": ['D IH0 M AA1 R IY0 AA2'],
  "dimarino": ['D IH0 M AA0 R IY1 N OW2'],
  "dimario": ['D IH0 M AA1 R IY0 OW0'],
  "dimartino": ['D IY2 M AA0 R T IY1 N OW2'],
  "dimarzio": ['D IH0 M AA1 R Z IY0 OW2'],
  "dimarzo": ['D IH0 M AA1 R Z OW2'],
  "dimas": ['D AY1 M AH0 Z'],
  "dimascio": ['D IH0 M AE1 S IY0 OW2'],
  "dimasi": ['D IH0 M AA1 S IY2'],
  "dimatteo": ['D IH0 M AA1 T IY0 OW2'],
  "dimauro": ['D IH0 M AO1 R OW2'],
  "dime": ['D AY1 M'],
  "dime's": ['D AY1 M Z'],
  "dimeglio": ['D IH0 M EH1 G L IY0 OW2'],
  "dimenaci": ['D IH2 M EH1 AH0 CH IY2'],
  "dimension": ['D IH0 M EH1 N SH AH0 N'],
  "dimensional": ['D IH0 M EH1 N SH AH0 N AH0 L'],
  "dimensionality": ['D IH0 M EH2 N SH AH0 N AE1 L AH0 T IY0'],
  "dimensioned": ['D AH0 M EH1 N CH AH0 N D'],
  "dimensions": ['D IH0 M EH1 N SH AH0 N Z'],
  "dimeo": ['D IY1 M IY0 OW2'],
  "dimer": ['D AY1 M ER0'],
  "dimercurio": ['D IH0 M ER0 K UH1 R IY0 OW2'],
  "dimes": ['D AY1 M Z'],
  "dimetapp": ['D AY1 M AH0 T AE2 P'],
  "dimiceli": ['D IH0 M IY0 CH EH1 L IY2'],
  "dimichele": ['D IH0 M IY0 K EH1 L IY2'],
  "dimick": ['D IH1 M IH0 K'],
  "dimick's": ['D IH1 M IH0 K S'],
  "diming": ['D AY1 M IH0 NG'],
  "diminish": ['D IH0 M IH1 N IH0 SH'],
  "diminished": ['D IH0 M IH1 N IH0 SH T'],
  "diminishes": ['D IH0 M IH1 N IH0 SH IH0 Z'],
  "diminishing": ['D IH0 M IH1 N IH0 SH IH0 NG'],
  "diminishment": ['D IH0 M IH1 N IH0 SH M AH0 N T'],
  "dimino": ['D IH0 M IY1 N OW2'],
  "diminution": ['D IH2 M AH0 N UW1 SH AH0 N'],
  "diminutive": ['D IH0 M IH1 N Y AH0 T IH0 V'],
  "dimitri": ['D IH0 M IY1 T R IY0'],
  "dimitrios": ['D IH0 M IY1 T R IY0 OW2 S'],
  "dimitrius": ['D IH0 M IY1 T R IY0 AH2 S'],
  "dimitrius'": ['D IH0 M IY1 T R IY0 AH2 S'],
  "dimitrius's": ['D IH0 M IY1 T R IY0 AH2 S IH0 S'],
  "dimitroff": ['D IH1 M IH0 T R AO0 F'],
  "dimitruk": ['D IH0 M IY1 T R UH2 K'],
  "dimly": ['D IH1 M L IY0'],
  "dimmed": ['D IH1 M D'],
  "dimmer": ['D IH1 M ER0'],
  "dimmers": ['D IH1 M ER0 Z'],
  "dimmick": ['D IH1 M IH0 K'],
  "dimming": ['D IH1 M IH0 NG'],
  "dimmitt": ['D IH1 M IH0 T'],
  "dimock": ['D IH1 M AH0 K'],
  "dimon": ['D IH1 M AH0 N'],
  "dimona": ['D IH0 M OW1 N AA2'],
  "dimond": ['D AY1 M AH0 N D'],
  "dimorphic": ['D AY0 M AO1 R F IH0 K'],
  "dimorphism": ['D AY0 M AO1 R F IH0 Z AH0 M'],
  "dimperio": ['D IH0 M P EH1 R IY0 OW2'],
  "dimple": ['D IH1 M P AH0 L'],
  "dimpled": ['D IH1 M P AH0 L D'],
  "dimples": ['D IH1 M P AH0 L Z'],
  "dimry": ['D IH1 M R IY0'],
  "dims": ['D IH1 M Z'],
  "dimsdale": ['D IH1 M Z D EY2 L'],
  "dimunition": ['D IH0 M Y UW0 N IH1 SH AH0 N'],
  "dimuro": ['D IH0 M UH1 R OW2'],
  "dimuzio": ['D IH0 M UW1 Z IY0 OW2'],
  "din": ['D IH1 N'],
  "dina": ['D IY1 N AH0'],
  "dinah": ['D AY1 N AH0'],
  "dinan": ['D IH1 N AH0 N'],
  "dinapoli": ['D IH0 N AE1 P AH0 L IY2'],
  "dinar": ['D IH0 N AA1 R'],
  "dinardo": ['D IH0 N AA1 R D OW2'],
  "dinars": ['D AY1 N ER0 Z', 'D IH2 N AA1 R Z'],
  "dinatale": ['D IH0 N AA0 T AA1 L IY2', 'D IY0 N AA0 T AA1 L IY0'],
  "dinda": ['D IH1 N D AH0'],
  "dine": ['D AY1 N'],
  "dined": ['D AY1 N D'],
  "dineen": ['D IH0 N IY1 N'],
  "dinehart": ['D AY1 N HH AA2 R T'],
  "diner": ['D AY1 N ER0'],
  "diners": ['D AY1 N ER0 Z'],
  "dines": ['D AY1 N Z'],
  "dinesh": ['D IH1 N EH0 SH'],
  "ding": ['D IH1 NG'],
  "ding-dong": ['D IH1 NG D AO1 NG'],
  "dingee": ['D IH1 NG G IY0'],
  "dingel": ['D IH1 NG G AH0 L'],
  "dingell": ['D IH1 NG G AH0 L'],
  "dingell's": ['D IH1 NG G AH0 L Z'],
  "dinger": ['D IH1 NG ER0'],
  "dinges": ['D IH1 N JH IH0 Z'],
  "dingess": ['D IH1 NG G IH0 S'],
  "dingham": ['D IH1 NG AH0 M'],
  "dinghy": ['D IH1 NG IY0'],
  "dingle": ['D IH1 NG G AH0 L'],
  "dingledine": ['D IH1 NG G AH0 L D AY0 N'],
  "dingler": ['D IH1 NG G AH0 L ER0', 'D IH1 NG G L ER0'],
  "dingley": ['D IH1 NG G L IY0'],
  "dingman": ['D IH1 NG M AH0 N'],
  "dingo": ['D IH1 NG G OW0'],
  "dings": ['D IH1 NG Z'],
  "dingus": ['D IH1 NG G IH0 S'],
  "dingwall": ['D IH1 NG G W AH0 L'],
  "dingy": ['D IH1 N JH IY0'],
  "dinh": ['D IH1 N'],
  "dini": ['D IY1 N IY0'],
  "dinicola": ['D IH0 N IY0 K OW1 L AA0'],
  "dining": ['D AY1 N IH0 NG'],
  "dinino": ['D IH0 N IY1 N OW2'],
  "dinius": ['D AY1 N IY0 IH0 S'],
  "dink": ['D IH1 NG K'],
  "dinkel": ['D IH1 NG K AH0 L'],
  "dinkins": ['D IH1 NG K IH0 N Z'],
  "dinkins'": ['D IH1 NG K IH0 N Z'],
  "dinky": ['D IH1 NG K IY0'],
  "dinmukhamed": ['D IH2 N M UW0 K AA1 M EH0 D'],
  "dinneen": ['D IH0 N IY1 N'],
  "dinner": ['D IH1 N ER0'],
  "dinner's": ['D IH1 N ER0 Z'],
  "dinners": ['D IH1 N ER0 Z'],
  "dinnertime": ['D IH1 N ER0 T AY2 M'],
  "dinnerware": ['D IH1 N ER0 W EH2 R'],
  "dinning": ['D IH1 N IH0 NG'],
  "dino": ['D IY1 N OW0'],
  "dinosaur": ['D AY1 N AH0 S AO2 R'],
  "dinosaurs": ['D AY1 N AH0 S AO2 R Z'],
  "dinoseb": ['D AY1 N OW0 S EH2 B'],
  "dinoto": ['D IH0 N OW1 T OW2'],
  "dinotopia": ['D AY2 N AH0 T OW1 P IY0 AH0'],
  "dinovo": ['D IH0 N OW1 V OW2'],
  "dinsa": ['D IH1 N S AH0'],
  "dinsdale": ['D IH1 N Z D EY2 L'],
  "dinse": ['D IH1 N S'],
  "dinsmore": ['D IY1 N S M AO0 R'],
  "dint": ['D IH1 N T'],
  "dinunzio": ['D IH0 N AH1 N Z IY0 OW2'],
  "dinwiddie": ['D IH1 N W IH0 D IY0'],
  "diocesan": ['D AY0 AA1 S AH0 S AH0 N'],
  "diocese": ['D AY1 AH0 S IY2 Z', 'D AY1 AH0 S AH0 S'],
  "dioceses": ['D AY1 AH0 S IY2 Z', 'D AY1 AH0 S IY2 Z AH0 Z'],
  "diodati": ['D IY2 OW0 D AA1 T IY2'],
  "diodato": ['D IY2 OW0 D AA1 T OW2'],
  "diode": ['D AY1 OW2 D'],
  "diodes": ['D AY1 OW2 D Z'],
  "dioguardi": ['D IY0 OW0 G AA1 R D IY2'],
  "dion": ['D AY1 AH0 N', 'D IY1 AO2 N'],
  "diona": ['D IY0 OW1 N AH0'],
  "dione": ['D IY1 AA0 N'],
  "dionisio": ['D AY2 AH0 N IH1 S IY0 OW2'],
  "dionne": ['D IY1 AA0 N'],
  "dionysian": ['D AY2 AH0 N IH1 S IY0 AH0 N'],
  "dionysius": ['D AY2 AH0 N IH1 S IY0 AH0 S'],
  "dior": ['D IY2 AO1 R'],
  "diorio": ['D IY0 AO1 R IY0 OW2'],
  "diorite": ['D AY1 ER0 AY2 T'],
  "dios": ['D IY1 OW0 S'],
  "dios'": ['D IY1 OW0 S'],
  "dios's": ['D IY1 OW0 S IH0 Z'],
  "dioxide": ['D AY0 AA1 K S AY2 D'],
  "dioxides": ['D AY0 AA1 K S AY0 D Z'],
  "dioxin": ['D AY2 AA1 K S IH0 N'],
  "dioxins": ['D AY2 AA1 K S IH0 N Z'],
  "dip": ['D IH1 P'],
  "dipalma": ['D IH0 P AA1 L M AH0'],
  "dipanjan": ['D AH0 P AA1 N JH AH2 N'],
  "dipaola": ['D IH0 P AA0 OW1 L AH0'],
  "dipaolo": ['D IH0 P AA0 OW1 L OW2'],
  "dipasquale": ['D IH0 P AA0 S K W AA1 L IY2', 'D IY0 P AA0 S K W AA1 L IY0'],
  "diperna": ['D IH0 P EH1 R N AH0'],
  "diphtheria": ['D IH0 F TH IH1 R IY0 AA0'],
  "diphthong": ['D IH1 F TH AO2 NG'],
  "dipiazza": ['D IH0 P IY0 AA1 T S AA2', 'D IY0 P IY0 AA1 T S AA2'],
  "dipiero": ['D IH0 P IH1 R OW2'],
  "dipierro": ['D IH0 P IH1 R OW2'],
  "dipietro": ['D IH0 P IY1 T R OW2'],
  "dipinto": ['D IH0 P IH1 N T OW2'],
  "dipirro": ['D IH0 P IH1 R OW2'],
  "diploma": ['D IH0 P L OW1 M AA0'],
  "diplomacy": ['D IH0 P L OW1 M AH0 S IY0'],
  "diplomas": ['D IH0 P L OW1 M AH0 Z'],
  "diplomat": ['D IH1 P L AH0 M AE2 T'],
  "diplomat's": ['D IH1 P L AH0 M AE2 T S'],
  "diplomatic": ['D IH2 P L AH0 M AE1 T IH0 K'],
  "diplomatically": ['D IH2 P L AH0 M AE1 T IH0 K L IY0'],
  "diplomats": ['D IH1 P L AH0 M AE2 T S'],
  "diplomats'": ['D IH1 P L AH0 M AE2 T S'],
  "dipole": ['D AY1 P OW2 L'],
  "dipped": ['D IH1 P T'],
  "dippel": ['D IH1 P AH0 L'],
  "dipper": ['D IH1 P ER0'],
  "dippers": ['D IH1 P ER0 Z'],
  "dipping": ['D IH1 P IH0 NG'],
  "dipple": ['D IH1 P AH0 L'],
  "dippold": ['D IH1 P OW2 L D'],
  "dippolito": ['D IH0 P OW0 L IY1 T OW2'],
  "dippy": ['D IH1 P IY0'],
  "diprima": ['D IH0 P R IY1 M AH0'],
  "dips": ['D IH1 P S'],
  "dipshit": ['D IH1 P SH IH2 T'],
  "dipstick": ['D IH1 P S T IH2 K'],
  "dircks": ['D ER1 K S'],
  "dire": ['D AY1 R', 'D AY1 ER0'],
  "direct": ['D ER0 EH1 K T', 'D AY0 R EH1 K T', 'D IH0 R EH1 K T'],
  "directed": ['D ER0 EH1 K T AH0 D', 'D ER0 EH1 K T IH0 D', 'D AY0 R EH1 K T IH0 D', 'D IH0 R EH1 K T IH0 D'],
  "directing": ['D ER0 EH1 K T IH0 NG', 'D IY0 R EH1 K T IH0 NG', 'D AY0 R EH1 K T IH0 NG', 'D IH0 R EH1 K T IH0 NG'],
  "direction": ['D ER0 EH1 K SH AH0 N', 'D IY0 R EH1 K SH IH0 N', 'D AY0 R EH1 K SH IH0 N', 'D IH0 R EH1 K SH IH0 N'],
  "directional": ['D ER0 EH1 K SH AH0 N AH0 L', 'D IY0 R EH1 K SH IH0 N AH0 L', 'D AY0 R EH1 K SH IH0 N AH0 L', 'D IH0 R EH1 K SH IH0 N AH0 L'],
  "directionless": ['D ER0 EH1 K SH AH0 N L AH0 S', 'D IY0 R EH1 K SH IH0 N L AH0 S', 'D AY0 R EH1 K SH IH0 N L AH0 S', 'D IH0 R EH1 K SH IH0 N L AH0 S'],
  "directions": ['D ER0 EH1 K SH AH0 N Z', 'D IY0 R EH1 K SH IH0 N Z', 'D AY0 R EH1 K SH IH0 N Z', 'D IH0 R EH1 K SH IH0 N Z'],
  "directive": ['D ER0 EH1 K T IH0 V', 'D IY0 R EH1 K T IH0 V', 'D AY0 R EH1 K T IH0 V', 'D IH0 R EH1 K T IH0 V'],
  "directives": ['D AY0 R EH1 K T IH0 V Z', 'D IY0 R EH1 K T IH0 V Z', 'D ER0 EH1 K T IH0 V Z', 'D IH0 R EH1 K T IH0 V Z'],
  "directly": ['D ER0 EH1 K T L IY0', 'D IY0 R EH1 K L IY0', 'D AY0 R EH1 K L IY0', 'D IH0 R EH1 K L IY0'],
  "directness": ['D ER0 EH1 K T N AH0 S', 'D IY0 R EH1 K N AH0 S', 'D AY0 R EH1 K N AH0 S', 'D IH0 R EH1 K N AH0 S'],
  "director": ['D ER0 EH1 K T ER0', 'D AY0 R EH1 K T ER0', 'D IY0 R EH1 K T ER0', 'D IH0 R EH1 K T ER0'],
  "director's": ['D AY0 R EH1 K T ER0 Z', 'D ER0 EH1 K T ER0 Z', 'D IY0 R EH1 K T ER0 Z', 'D IH0 R EH1 K T ER0 Z'],
  "directoral": ['D ER0 EH1 K T ER0 AH0 L'],
  "directorate": ['D ER0 EH1 K T ER0 AH0 T', 'D AY0 R EH1 K T ER0 AH0 T', 'D IY0 R EH1 K T ER0 AH0 T', 'D IH0 R EH1 K T ER0 AH0 T'],
  "directorial": ['D ER0 EH0 K T AO1 R IY0 AH0 L', 'D AY0 R EH0 K T AO1 R IY0 AH0 L', 'D IY0 R EH0 K T AO1 R IY0 AH0 L', 'D IH0 R EH0 K T AO1 R IY0 AH0 L'],
  "directories": ['D AY0 R EH1 K T ER0 IY0 Z', 'D ER0 EH1 K T ER0 IY0 Z', 'D IY0 R EH1 K T ER0 IY0 Z', 'D IH0 R EH1 K T ER0 IY0 Z'],
  "directors": ['D ER0 EH1 K T ER0 Z', 'D AY0 R EH1 K T ER0 Z', 'D IY0 R EH1 K T ER0 Z', 'D IH0 R EH1 K T ER0 Z'],
  "directors'": ['D IH0 R EH1 K T ER0 Z', 'D ER0 EH1 K T ER0 Z', 'D IY0 R EH1 K T ER0 Z'],
  "directorship": ['D ER0 EH1 K T ER0 SH IH2 P', 'D AY0 R EH1 K T ER0 SH IH2 P', 'D IY0 R EH1 K T ER0 SH IH2 P', 'D IH0 R EH1 K T ER0 SH IH2 P'],
  "directorships": ['D ER0 EH1 K T ER0 SH IH2 P S', 'D AY0 R EH1 K T ER0 SH IH2 P S', 'D IY0 R EH1 K T ER0 SH IH2 P S', 'D IH0 R EH1 K T ER0 SH IH2 P S'],
  "directory": ['D ER0 EH1 K T ER0 IY0', 'D AY0 R EH1 K T ER0 IY0', 'D IY0 R EH1 K T ER0 IY0', 'D IH0 R EH1 K T ER0 IY0'],
  "directs": ['D ER0 EH1 K T S', 'D AY0 R EH1 K T S', 'D IY0 R EH1 K T S', 'D IH0 R EH1 K T S'],
  "directv": ['D ER0 EH1 K T IY1 V IY1', 'D AY0 R EH1 K T IY1 V IY1', 'D IY0 R EH1 K T IY1 V IY1', 'D IH0 R EH1 K T IY1 V IY1'],
  "direnzo": ['D IH0 R EH1 N Z OW2'],
  "direst": ['D AY1 R AH0 S T'],
  "dirge": ['D ER1 JH'],
  "dirhams": ['D ER1 AH0 M Z'],
  "dirickson": ['D IH1 R IH2 K S AH0 N'],
  "dirienzo": ['D IH0 R IY1 N Z OW2'],
  "dirk": ['D ER1 K'],
  "dirkes": ['D ER1 K S'],
  "dirks": ['D ER1 K S'],
  "dirkse": ['D ER1 K S'],
  "dirksen": ['D ER1 K S AH0 N'],
  "dirlam": ['D ER0 L AE1 M'],
  "dirocco": ['D IH0 R AA1 K OW2'],
  "dirosa": ['D IH0 R OW1 S AA0'],
  "dirr": ['D ER1'],
  "dirt": ['D ER1 T'],
  "dirt's": ['D ER1 T S'],
  "dirtier": ['D ER1 T IY0 ER0'],
  "dirtiest": ['D ER1 T IY0 AH0 S T'],
  "dirty": ['D ER1 T IY0'],
  "dirusso": ['D IH0 R UW1 S OW2'],
  "dis": ['D IH1 S'],
  "disa": ['D IH1 S AH0'],
  "disabatino": ['D IH0 S AA0 B AA0 T IY1 N OW0'],
  "disabato": ['D IH0 S AA0 B AA1 T OW0'],
  "disabilities": ['D IH0 S AH0 B IH1 L AH0 T IY0 Z', 'D IH0 S AH0 B IH1 L IH0 T IY0 Z'],
  "disability": ['D IH2 S AH0 B IH1 L IH0 T IY0', 'D IH0 S AH0 B IH1 L IH0 T IY0 Z'],
  "disable": ['D IH0 S EY1 B AH0 L'],
  "disabled": ['D IH0 S EY1 B AH0 L D'],
  "disables": ['D IH0 S EY1 B AH0 L Z'],
  "disabling": ['D IH0 S EY1 B AH0 L IH0 NG', 'D IH2 S EY1 B L IH0 NG'],
  "disabuse": ['D IH0 S AH0 B Y UW1 S', 'D IH0 S AH0 B Y UW1 Z'],
  "disabused": ['D IH0 S AH0 B Y UW1 Z D'],
  "disabuses": ['D IH0 S AH0 B Y UW1 S IH0 Z'],
  "disadvantage": ['D IH2 S AH0 D V AE1 N T IH0 JH', 'D IH2 S AH0 D V AE1 N IH0 JH'],
  "disadvantaged": ['D IH0 S AH0 D V AE1 N T IH0 JH D', 'D IH2 S AH0 D V AE1 N IH0 JH D'],
  "disadvantageous": ['D IH2 S AE2 D V AE2 N T EY1 JH AH0 S'],
  "disadvantages": ['D IH2 S AH0 D V AE1 N T IH0 JH IH0 Z', 'D IH2 S AH0 D V AE1 N IH0 JH Z'],
  "disaffect": ['D IH2 S AH0 F EH1 K T'],
  "disaffected": ['D IH2 S AH0 F EH1 K T IH0 D'],
  "disaffection": ['D IH2 S AH0 F EH1 K SH AH0 N'],
  "disagree": ['D IH0 S AH0 G R IY1'],
  "disagreeable": ['D IH2 S AH0 G R IY1 AH0 B AH0 L'],
  "disagreed": ['D IH0 S AH0 G R IY1 D'],
  "disagreeing": ['D IH0 S AH0 G R IY1 IH0 NG'],
  "disagreement": ['D IH0 S AH0 G R IY1 M AH0 N T'],
  "disagreements": ['D IH2 S AH0 G R IY1 M AH0 N T S'],
  "disagrees": ['D IH0 S AH0 G R IY1 Z'],
  "disallow": ['D IH2 S AH0 L AW1'],
  "disallowance": ['D IH2 S AH0 L AW1 AH0 N S'],
  "disallowances": ['D IH2 S AH0 L AW1 AH0 N S IH0 Z'],
  "disallowed": ['D IH2 S AH0 L AW1 D'],
  "disallowing": ['D IH0 S AH0 L AW1 IH0 NG'],
  "disalvo": ['D IH0 S AA1 L V OW2'],
  "disano": ['D IH0 S AA1 N OW0'],
  "disanti": ['D IH0 S AE1 N T IY2'],
  "disantis": ['D IH0 S AA1 N T IH0 S'],
  "disanto": ['D IH0 S AE1 N T OW2'],
  "disappear": ['D IH2 S AH0 P IH1 R', 'D IH2 S AH0 P IY1 R'],
  "disappearance": ['D IH2 S AH0 P IH1 R AH0 N S', 'D IH2 S AH0 P IY1 R AH0 N S'],
  "disappearances": ['D IH2 S AH0 P IH1 R AH0 N S IH0 Z', 'D IH2 S AH0 P IY1 R AH0 N S IH0 Z'],
  "disappeared": ['D IH2 S AH0 P IH1 R D', 'D IH2 S AH0 P IY1 R D'],
  "disappearing": ['D IH2 S AH0 P IH1 R IH0 NG', 'D IH2 S AH0 P IY1 R IH0 NG'],
  "disappears": ['D IH2 S AH0 P IH1 R Z', 'D IH2 S AH0 P IY1 R Z'],
  "disappoint": ['D IH2 S AH0 P OY1 N T'],
  "disappointed": ['D IH2 S AH0 P OY1 N T IH0 D', 'D IH2 S AH0 P OY1 N IH0 D'],
  "disappointing": ['D IH2 S AH0 P OY1 N T IH0 NG', 'D IH2 S AH0 P OY1 N IH0 NG'],
  "disappointingly": ['D IH0 S AH0 P OY1 N T IH0 NG L IY0', 'D IH0 S AH0 P OY1 N IH0 NG L IY0'],
  "disappointment": ['D IH2 S AH0 P OY1 N T M AH0 N T'],
  "disappointments": ['D IH0 S AH0 P OY1 N T M AH0 N T S'],
  "disappoints": ['D IH2 S AH0 P OY1 N T S'],
  "disapproval": ['D IH0 S AH0 P R UW1 V AH0 L'],
  "disapprove": ['D IH2 S AH0 P R UW1 V'],
  "disapproved": ['D IH2 S AH0 P R UW1 V D'],
  "disapproves": ['D IH0 S AH0 P R UW1 V Z'],
  "disapproving": ['D IH0 S AH0 P R UW1 V IH0 NG'],
  "disarm": ['D IH0 S AA1 R M'],
  "disarmament": ['D IH0 S AA1 R M AH0 M AH0 N T'],
  "disarmed": ['D IH0 S AA1 R M D'],
  "disarming": ['D IH0 S AA1 R M IH0 NG'],
  "disarmingly": ['D IH0 S AA1 R M IH0 NG L IY0'],
  "disarray": ['D IH2 S ER0 EY1', 'D IH2 S AH0 R EY1'],
  "disassemble": ['D IH2 S AH0 S EH1 M B AH0 L'],
  "disassembled": ['D IH2 S AH0 S EH1 M B AH0 L D'],
  "disassociate": ['D IH2 S AH0 S OW1 SH IY0 EY0 T', 'D IH2 S AH0 S OW1 S IY0 EY0 T'],
  "disassociated": ['D IH2 S AH0 S OW1 SH IY0 EY0 T AH0 D', 'D IH2 S AH0 S OW1 S IY0 EY0 T AH0 D'],
  "disaster": ['D IH0 Z AE1 S T ER0'],
  "disasters": ['D IH0 Z AE1 S T ER0 Z'],
  "disastrous": ['D IH0 Z AE1 S T R AH0 S'],
  "disastrously": ['D IH0 Z AE1 S T R AH0 S L IY0'],
  "disavow": ['D IH2 S AH0 V AW1'],
  "disavowed": ['D IH2 S AH0 V AW1 D'],
  "disavowing": ['D IH0 S AH0 V AW1 IH0 NG'],
  "disband": ['D IH0 S B AE1 N D'],
  "disbanded": ['D IH0 S B AE1 N D IH0 D'],
  "disbanding": ['D IH0 S B AE1 N D IH0 NG'],
  "disbar": ['D IH2 S B AA1 R'],
  "disbarment": ['D IH2 S B AA1 R M AH0 N T'],
  "disbarred": ['D IH0 S B AA1 R D'],
  "disbelief": ['D IH2 S B IH0 L IY1 F'],
  "disbelieve": ['D IH0 S B AH0 L IY1 V'],
  "disbelieving": ['D IH0 S B AH0 L IY1 V IH0 NG'],
  "disbro": ['D IH1 S B R OW0'],
  "disbrow": ['D IH1 S B R AW0'],
  "disburse": ['D IH0 S B ER1 S'],
  "disbursed": ['D IH0 S B ER1 S T'],
  "disbursement": ['D IH0 S B ER1 S M AH0 N T'],
  "disbursements": ['D IH0 S B ER1 S M AH0 N T S'],
  "disbursing": ['D IH0 S B ER1 S IH0 NG'],
  "disc": ['D IH1 S K'],
  "discard": ['D IH0 S K AA1 R D'],
  "discarded": ['D IH0 S K AA1 R D IH0 D'],
  "discarding": ['D IH0 S K AA1 R D IH0 NG'],
  "discards": ['D IH0 S K AA1 R D Z'],
  "discenza": ['D IH0 S CH EH1 N Z AA0'],
  "discern": ['D IH0 S ER1 N'],
  "discernably": ['D IH0 S ER1 N AH0 B L IY0'],
  "discerned": ['D IH0 S ER1 N D'],
  "discernible": ['D IH0 S ER1 N AH0 B AH0 L'],
  "discerning": ['D IH0 S ER1 N IH0 NG'],
  "discernment": ['D IH0 S ER1 N M AH0 N T'],
  "discerns": ['D IH0 S ER1 N Z'],
  "disch": ['D IH1 SH'],
  "discharge": ['D IH0 S CH AA1 R JH', 'D IH1 S CH AA2 R JH'],
  "discharged": ['D IH0 S CH AA1 R JH D', 'D IH1 S CH AA2 R JH D'],
  "discharges": ['D IH0 S CH AA1 R JH AH0 Z', 'D IH1 S CH AA2 R JH AH0 Z'],
  "discharging": ['D IH0 S CH AA1 R JH IH0 NG', 'D IH1 S CH AA2 R JH IH0 NG'],
  "discher": ['D IH1 SH ER0'],
  "dischinger": ['D IH1 SH IH0 N JH ER0'],
  "disciple": ['D IH0 S AY1 P AH0 L'],
  "disciples": ['D IH0 S AY1 P AH0 L Z'],
  "disciplinarian": ['D IH2 S IH0 P L IH0 N EH1 R IY0 AH0 N'],
  "disciplinarians": ['D IH2 S IH0 P L IH0 N EH1 R IY0 AH0 N Z'],
  "disciplinary": ['D IH1 S AH0 P L AH0 N EH2 R IY0'],
  "discipline": ['D IH1 S AH0 P L AH0 N'],
  "disciplined": ['D IH1 S AH0 P L AH0 N D'],
  "disciplines": ['D IH1 S AH0 P L AH0 N Z'],
  "disciplining": ['D IH1 S AH0 P L AH0 N IH0 NG'],
  "disclaim": ['D IH0 S K L EY1 M'],
  "disclaimed": ['D IH0 S K L EY1 M D'],
  "disclaimer": ['D IH0 S K L EY1 M ER0'],
  "disclaimers": ['D IH0 S K L EY1 M ER0 Z'],
  "disclaiming": ['D IH0 S K L EY1 M IH0 NG'],
  "disclaims": ['D IH0 S K L EY1 M Z'],
  "discland": ['D IH1 S K L AE2 N D'],
  "disclose": ['D IH0 S K L OW1 Z'],
  "disclosed": ['D IH0 S K L OW1 Z D'],
  "discloses": ['D IH0 S K L OW1 Z IH0 Z'],
  "disclosing": ['D IH0 S K L OW1 Z IH0 NG'],
  "disclosure": ['D IH0 S K L OW1 ZH ER0'],
  "disclosures": ['D IH0 S K L OW1 ZH ER0 Z'],
  "disco": ['D IH1 S K OW0'],
  "discography": ['D IH0 S K AO1 G R AH0 F IY0'],
  "discolor": ['D IH0 S K AH1 L ER0'],
  "discoloration": ['D IH0 S K AH2 L ER0 EY1 SH AH0 N'],
  "discolorations": ['D IH0 S K AH2 L ER0 EY1 SH AH0 N Z'],
  "discolored": ['D IH0 S K AH1 L ER0 D'],
  "discolors": ['D IH0 S K AH1 L ER0 Z'],
  "discombobulate": ['D IH2 S K AH0 M B AO1 B Y UW0 L EY0 T'],
  "discombobulated": ['D IH2 S K AH0 M B AO1 B Y UW0 L EY0 T AH0 D'],
  "discombobulates": ['D IH2 S K AH0 M B AO1 B Y UW0 L EY0 T S'],
  "discombobulating": ['D IH2 S K AH0 M B AO1 B Y UW0 L EY0 T IH0 NG'],
  "discomfort": ['D IH0 S K AH1 M F ER0 T'],
  "disconcert": ['D IH2 S K AH0 N S ER1 T'],
  "disconcerting": ['D IH2 S K AH0 N S ER1 T IH0 NG'],
  "disconnect": ['D IH0 S K AH0 N EH1 K T'],
  "disconnected": ['D IH2 S K AH0 N EH1 K T IH0 D'],
  "disconnecting": ['D IH2 S K AH0 N EH1 K T IH0 NG'],
  "disconnection": ['D IH2 S K AH0 N EH1 K SH AH0 N'],
  "disconnects": ['D IH0 S K AH0 N EH1 K T S'],
  "discontent": ['D IH0 S K AH0 N T EH1 N T'],
  "discontented": ['D IH2 S K AH0 N T EH1 N T IH0 D'],
  "discontentedly": ['D IH2 S K AH0 N T EH1 N T IH0 D L IY0'],
  "discontentedness": ['D IH2 S K AH0 N T EH1 N T IH0 D N AH0 S'],
  "discontents": ['D IH2 S K AH0 N T EH1 N T S'],
  "discontinuance": ['D IH2 S K AH0 N T IH1 N Y UW0 AH0 N S'],
  "discontinuation": ['D IH2 S K AH0 N T IH2 N Y UW0 EY1 SH AH0 N'],
  "discontinue": ['D IH0 S K AH0 N T IH1 N Y UW0'],
  "discontinued": ['D IH0 S K AH0 N T IH1 N Y UW0 D'],
  "discontinuing": ['D IH0 S K AH0 N T IH1 N Y UW0 IH0 NG'],
  "discontinuity": ['D IH0 S K AA2 N T IH0 N UW1 IH0 T IY0'],
  "discord": ['D IH1 S K AO0 R D'],
  "discordant": ['D IH0 S K AO1 R D AH0 N T'],
  "discos": ['D IH1 S K OW0 Z'],
  "discotheque": ['D IH1 S K OW0 T EH2 K'],
  "discotheques": ['D IH1 S K OW0 T EH2 K S'],
  "discount": ['D IH0 S K AW1 N T', 'D IH1 S K AW0 N T'],
  "discountable": ['D IH1 S K AW2 N T AH0 B AH0 L'],
  "discounted": ['D IH1 S K AW2 N T IH0 D', 'D IH1 S K AW2 N IH0 D'],
  "discounter": ['D IH1 S K AW2 N T ER0'],
  "discounters": ['D IH0 S K AW1 N T ER0 Z', 'D IH1 S K AW2 N ER0 R Z'],
  "discounting": ['D IH1 S K AW2 N T IH0 NG', 'D IH1 S K AW2 N IH0 NG'],
  "discounts": ['D IH0 S K AW1 N T S', 'D IH1 S K AW2 N T S'],
  "discourage": ['D IH0 S K ER1 IH0 JH'],
  "discouraged": ['D IH0 S K ER1 AH0 JH D', 'D IH0 S K ER1 IH0 JH D'],
  "discouragement": ['D IH0 S K ER1 IH0 JH M AH0 N T'],
  "discourages": ['D IH0 S K ER1 IH0 JH IH0 Z'],
  "discouraging": ['D IH0 S K ER1 AH0 JH IH0 NG', 'D IH0 S K ER1 IH0 JH IH0 NG'],
  "discourse": ['D IH1 S K AO0 R S'],
  "discourses": ['D IH0 S K AO1 R S IH0 Z', 'D IH1 S K AO0 R S IH0 Z'],
  "discover": ['D IH0 S K AH1 V ER0'],
  "discoverable": ['D IH0 S K AH1 V ER0 AH0 B AH0 L', 'D IH0 S K AH1 V R AH0 B AH0 L'],
  "discovered": ['D IH0 S K AH1 V ER0 D'],
  "discoverer": ['D IH0 S K AH1 V ER0 ER0'],
  "discoverers": ['D IH0 S K AH1 V AH0 R ER0 Z'],
  "discoveries": ['D IH0 S K AH1 V ER0 IY0 Z'],
  "discovering": ['D IH0 S K AH1 V ER0 IH0 NG'],
  "discovers": ['D IH0 S K AH1 V ER0 Z'],
  "discovery": ['D IH0 S K AH1 V ER0 IY0', 'D IH0 S K AH1 V R IY0', 'D IH2 S K AH1 V R IY0'],
  "discovery's": ['D IH0 S K AH1 V ER0 IY0 Z', 'D IH0 S K AH1 V R IY0 Z'],
  "discredit": ['D IH0 S K R EH1 D AH0 T'],
  "discredited": ['D IH0 S K R EH1 D IH0 T IH0 D'],
  "discrediting": ['D IH0 S K R EH1 D AH0 T IH0 NG'],
  "discredits": ['D IH0 S K R EH1 D AH0 T S'],
  "discreet": ['D IH0 S K R IY1 T'],
  "discreetly": ['D IH0 S K R IY1 T L IY0'],
  "discrepancies": ['D IH0 S K R EH1 P AH0 N S IY0 Z'],
  "discrepancy": ['D IH0 S K R EH1 P AH0 N S IY0'],
  "discrete": ['D IH0 S K R IY1 T'],
  "discretion": ['D IH0 S K R EH1 SH AH0 N'],
  "discretionary": ['D IH0 S K R EH1 SH AH0 N EH2 R IY0'],
  "discretions": ['D IH0 S K R EH1 SH AH0 N Z'],
  "discriminate": ['D IH0 S K R IH1 M AH0 N EY2 T'],
  "discriminated": ['D IH0 S K R IH1 M AH0 N EY2 T IH0 D'],
  "discriminates": ['D IH0 S K R IH1 M AH0 N EY2 T S'],
  "discriminating": ['D IH0 S K R IH1 M AH0 N EY2 T IH0 NG'],
  "discrimination": ['D IH0 S K R IH2 M AH0 N EY1 SH AH0 N'],
  "discriminative": ['D IH0 S K R IH1 M AH0 N AH0 T IH0 V'],
  "discriminatory": ['D IH0 S K R IH1 M AH0 N AH0 T AO2 R IY0'],
  "discs": ['D IH1 S K S'],
  "discursive": ['D IH0 S K ER1 S IH0 V'],
  "discus": ['D IH1 S K AH0 S'],
  "discuss": ['D IH0 S K AH1 S'],
  "discussed": ['D IH0 S K AH1 S T'],
  "discusses": ['D IH0 S K AH1 S AH0 Z', 'D IH0 S K AH1 S IH0 Z'],
  "discussing": ['D IH0 S K AH1 S IH0 NG'],
  "discussion": ['D IH0 S K AH1 SH AH0 N'],
  "discussions": ['D IH0 S K AH1 SH AH0 N Z'],
  "disdain": ['D IH0 S D EY1 N'],
  "disdained": ['D IH0 S D EY1 N D'],
  "disdainful": ['D IH0 S D EY1 N F AH0 L'],
  "disdaining": ['D IH0 S D EY1 N IH0 NG'],
  "disdains": ['D IH0 S D EY1 N Z'],
  "dise": ['D AY1 S', 'D AY1 Z'],
  "disease": ['D IH0 Z IY1 Z'],
  "disease's": ['D IH0 Z IY1 Z IH0 Z'],
  "diseased": ['D IH0 Z IY1 Z D'],
  "diseases": ['D IH0 Z IY1 Z AH0 Z', 'D IH0 Z IY1 Z IH0 Z'],
  "disembark": ['D IH0 S EH0 M B AA1 R K'],
  "disembarkation": ['D IH0 S EH2 M B AA0 R K EY1 SH AH0 N'],
  "disembodied": ['D IH0 S IH0 B AA1 D IY0 D'],
  "disembody": ['D IH0 S IH0 B AA1 D IY0'],
  "disenchanted": ['D IH0 S IH0 N CH AE1 N T IH0 D', 'D IH0 S IH0 N CH AE1 N IH0 D'],
  "disenchantment": ['D IH0 S IH0 N CH AE1 N T M AH0 N T'],
  "disenfranchise": ['D IH0 S IH0 N F R AE1 N CH AY2 Z'],
  "disenfranchised": ['D IH0 S IH0 N F R AE1 N CH AY2 Z D'],
  "disenfranchisement": ['D IH0 S IH0 N F R AE1 N CH AY2 Z M AH0 N T'],
  "disengage": ['D IH0 S IH0 N G EY1 JH'],
  "disengaged": ['D IH0 S IH0 N G EY1 JH D'],
  "disengagement": ['D IH0 S IH0 N G EY1 JH M AH0 N T'],
  "disengaging": ['D IH0 S IH0 N G EY1 JH IH0 NG'],
  "disentangle": ['D IH2 S AH0 N T AE1 NG G AH0 L'],
  "disequilibrium": ['D IH0 S IY2 K W AH0 L IH1 B R IY0 AH0 M'],
  "disestablishment": ['D IH0 S IH0 S T AE1 B L IH0 SH M AH0 N T'],
  "disfavor": ['D IH0 S F EY1 V ER0'],
  "disfavoring": ['D IH0 S F EY1 V ER0 IH0 NG'],
  "disfigure": ['D IH0 S F IH1 G Y ER0'],
  "disfigured": ['D IH0 S F IH1 G Y ER0 D'],
  "disfigurement": ['D IH0 S F IH1 G Y ER0 M EH0 N T'],
  "disfiguring": ['D IH0 S F IH1 G Y ER0 IH0 NG'],
  "disgorge": ['D IH0 S G AO1 R JH'],
  "disgorged": ['D IH0 S G AO1 R JH D'],
  "disgorgement": ['D IH0 S G AO1 R JH M AH0 N T'],
  "disgrace": ['D IH0 S G R EY1 S'],
  "disgraced": ['D IH0 S G R EY1 S T'],
  "disgraceful": ['D IH0 S G R EY1 S F AH0 L'],
  "disgruntled": ['D IH0 S G R AH1 N T AH0 L D', 'D IH0 S G R AH1 N AH0 L D'],
  "disgruntlement": ['D IH0 S G R AH1 N T AH0 L M AH0 N T'],
  "disgruntling": ['D IH0 S G R AH1 N T AH0 L IH0 NG', 'D IH0 S G R AH1 N T L IH0 NG'],
  "disguise": ['D IH0 S G AY1 Z'],
  "disguised": ['D IH0 S G AY1 Z D'],
  "disguises": ['D IH0 S G AY1 Z IH0 Z'],
  "disguising": ['D IH0 S G AY1 Z IH0 NG'],
  "disgust": ['D IH0 S G AH1 S T'],
  "disgusted": ['D IH0 S G AH1 S T AH0 D', 'D IH0 S G AH1 S T IH0 D'],
  "disgusting": ['D IH0 S G AH1 S T IH0 NG'],
  "disgusts": ['D IH0 S G AH1 S T S'],
  "dish": ['D IH1 SH'],
  "disharmony": ['D IH0 S HH AA1 R M AH0 N IY0'],
  "disharoon": ['D IH0 SH ER0 UW1 N'],
  "dishaw": ['D IH1 SH AO2'],
  "disheartened": ['D IH0 S HH AA1 R T AH0 N D'],
  "disheartening": ['D IH0 S HH AA1 R T AH0 N IH0 NG', 'D IH2 S HH AA1 R T N IH0 NG'],
  "dished": ['D IH1 SH T'],
  "disher": ['D IH1 SH ER0'],
  "dishes": ['D IH1 SH AH0 Z', 'D IH1 SH IH0 Z'],
  "dishevel": ['D IH0 SH EH1 V AH0 L'],
  "disheveled": ['D IH0 SH EH1 V AH0 L D'],
  "dishing": ['D IH1 SH IH0 NG'],
  "dishman": ['D IH1 SH M AH0 N'],
  "dishner": ['D IH1 SH N ER0'],
  "dishon": ['D IH1 S AH0 N'],
  "dishonest": ['D IH0 S AA1 N AH0 S T'],
  "dishonestly": ['D IH0 S AO1 N AH0 S T L IY0'],
  "dishonesty": ['D IH0 S AA1 N AH0 S T IY0'],
  "dishong": ['D IH1 S AO0 NG'],
  "dishonor": ['D IH0 S AA1 N ER0'],
  "dishonorable": ['D IH0 S AA1 N ER0 AH0 B AH0 L'],
  "dishonored": ['D IH0 S AA1 N ER0 D'],
  "dishwasher": ['D IH1 SH W AA2 SH ER0'],
  "dishwashers": ['D IH1 SH W AA2 SH ER0 Z'],
  "dishwashing": ['D IH1 SH W AA2 SH IH0 NG'],
  "disillusion": ['D IH2 S IH0 L UW1 ZH AH0 N'],
  "disillusioned": ['D IH2 S IH0 L UW1 ZH AH0 N D'],
  "disillusioning": ['D IH2 S IH0 L UW1 ZH AH0 N IH0 NG'],
  "disillusionment": ['D IH2 S IH0 L UW1 ZH AH0 N M AH0 N T'],
  "disilvestro": ['D IH0 S IY0 L V EH1 S T R OW0'],
  "disimone": ['D IH0 S IY0 M OW1 N IY0'],
  "disincentive": ['D IH2 S IH0 N S EH1 N T IH0 V'],
  "disincentives": ['D IH2 S IH0 N S EH1 N T IH0 V Z'],
  "disinclination": ['D IH0 S IH0 N K L AH0 N EY1 SH AH0 N'],
  "disincline": ['D IH2 S IH0 N K L AY1 N'],
  "disinclined": ['D IH2 S IH0 N K L AY1 N D'],
  "disinfect": ['D IH0 S IH0 N F EH1 K T'],
  "disinfectant": ['D IH0 S IH0 N F EH1 K T AH0 N T'],
  "disinfectants": ['D IH0 S IH0 N F EH1 K T AH0 N T S'],
  "disinfection": ['D IH0 S IH0 N F EH1 K SH AH0 N'],
  "disinflate": ['D IH2 S IH0 N F L EY1 T'],
  "disinflation": ['D IH2 S IH0 N F L EY1 SH AH0 N'],
  "disinflationary": ['D IH2 S IH0 N F L EY1 SH AH0 N EH2 R IY0'],
  "disinform": ['D IH0 S IH0 N F AO1 R M'],
  "disinformation": ['D IH0 Z IH2 N F ER0 M EY1 SH AH0 N'],
  "disingenuous": ['D IH0 S IH0 N JH EH1 N Y UW0 AH0 S'],
  "disingenuousness": ['D IH2 S IH0 N JH EH1 N Y UW0 AH0 S N AH0 S'],
  "disintegrate": ['D IH0 S IH1 N T AH0 G R EY2 T'],
  "disintegrated": ['D IH0 S IH1 N T AH0 G R EY2 T IH0 D'],
  "disintegrates": ['D IH0 S IH1 N T AH0 G R EY2 T S'],
  "disintegrating": ['D IH0 S IH1 N T AH0 G R EY2 T IH0 NG'],
  "disintegration": ['D IH0 S IH2 N T AH0 G R EY1 SH AH0 N', 'D IH0 S IH2 N AH0 G R EY1 SH AH0 N'],
  "disinterest": ['D IH0 S IH1 N T ER0 AH0 S T'],
  "disinterested": ['D IH0 S IH1 N T R IH0 S T IH0 D', 'D IH0 S IH1 N R IH0 S T IH0 D'],
  "disinvestment": ['D IH0 S IH0 N V EH1 S T M AH0 N T', 'D IH0 S IH0 N V EH1 S M AH0 N T'],
  "disinvite": ['D IH0 S IH0 N V AY1 T'],
  "disinvited": ['D IH2 S IH2 N V AY1 T IH0 D'],
  "disjoint": ['D IH0 S JH OY1 N T'],
  "disjointed": ['D IH0 S JH OY1 N T IH0 D'],
  "disk": ['D IH1 S K'],
  "diskette": ['D IH0 S K EH1 T'],
  "diskettes": ['D IH0 S K EH1 T S'],
  "diskin": ['D IH1 S K IH0 N'],
  "diskless": ['D IH1 S K L AH0 S'],
  "disks": ['D IH1 S K S'],
  "dislike": ['D IH0 S L AY1 K'],
  "disliked": ['D IH0 S L AY1 K T'],
  "dislikes": ['D IH0 S L AY1 K S'],
  "disliking": ['D IH0 S L AY1 K IH0 NG'],
  "dislocate": ['D IH1 S L OW0 K EY0 T'],
  "dislocated": ['D IH1 S L OW0 K EY0 T IH0 D'],
  "dislocation": ['D IH0 S L OW1 K EY1 SH AH0 N'],
  "dislocations": ['D IH0 S L OW1 K EY1 SH AH0 N Z'],
  "dislodge": ['D IH0 S L AA1 JH'],
  "dislodged": ['D IH0 S L AA1 JH D'],
  "dislodging": ['D IH0 S L AA1 JH IH0 NG'],
  "disloyal": ['D IH0 S L OY1 AH0 L'],
  "disloyalty": ['D IH0 S L OY1 AH0 L T IY0'],
  "dismal": ['D IH1 Z M AH0 L'],
  "dismally": ['D IH1 Z M AH0 L IY0'],
  "dismantle": ['D IH0 S M AE1 N T AH0 L', 'D IH0 S M AE1 N AH0 L'],
  "dismantled": ['D IH0 S M AE1 N T AH0 L D', 'D IH0 S M AE1 N AH0 L D'],
  "dismantlement": ['D IH0 S M AE1 N T AH0 L M AH0 N T'],
  "dismantles": ['D IH0 S M AE1 N T AH0 L Z'],
  "dismantling": ['D IH0 S M AE1 N T AH0 L IH0 NG', 'D IH0 S M AE1 N AH0 L IH0 NG', 'D IH2 S M AE1 N T L IH0 NG'],
  "dismay": ['D IH0 S M EY1'],
  "dismayed": ['D IH0 S M EY1 D'],
  "dismaying": ['D IH0 S M EY1 IH0 NG'],
  "dismays": ['D IH0 S M EY1 Z'],
  "dismember": ['D IH0 S M EH1 M B ER0'],
  "dismembered": ['D IH0 S M EH1 M B ER0 D'],
  "dismembering": ['D IH0 S M EH1 M B ER0 IH0 NG'],
  "dismemberment": ['D IH0 S M EH1 M B ER0 M AH0 N T'],
  "dismiss": ['D IH0 S M IH1 S'],
  "dismissal": ['D IH0 S M IH1 S AH0 L'],
  "dismissals": ['D IH0 S M IH1 S AH0 L Z'],
  "dismissed": ['D IH0 S M IH1 S T'],
  "dismisses": ['D IH0 S M IH1 S IH0 Z'],
  "dismissing": ['D IH0 S M IH1 S IH0 NG'],
  "dismissive": ['D IH0 S M IH1 S IH0 V'],
  "dismore": ['D IH1 S M AO0 R'],
  "dismuke": ['D IH1 S M Y UW0 K'],
  "dismukes": ['D IH1 S M Y UW0 K S'],
  "dismutase": ['D IH2 S M Y UW0 T EY1 Z'],
  "disney": ['D IH1 Z N IY0'],
  "disney's": ['D IH1 Z N IY0 Z'],
  "disneyland": ['D IH1 Z N IY0 L AE2 N D'],
  "disneyworld": ['D IH1 Z N IY0 W ER0 L D'],
  "disobedience": ['D IH2 S AH0 B IY1 D IY0 AH0 N S', 'D IH2 S OW0 B IY1 D IY0 AH0 N S'],
  "disobedient": ['D IH2 S AH0 B IY1 D IY0 AH0 N T', 'D IH2 S OW0 B IY1 D IY0 AH0 N T'],
  "disobey": ['D IH2 S AH0 B EY1'],
  "disobeyed": ['D IH2 S OW0 B EY1 D'],
  "disobeying": ['D IH2 S OW0 B EY1 IH0 NG'],
  "dison": ['D IH1 S AH0 N'],
  "disorder": ['D IH0 S AO1 R D ER0'],
  "disordered": ['D IH0 S AO1 R D ER0 D'],
  "disorderly": ['D IH0 S AO1 R D ER0 L IY0'],
  "disorders": ['D IH0 S AO1 R D ER0 Z'],
  "disorganization": ['D IH0 S AO2 R G AH0 N AH0 Z EY1 SH AH0 N'],
  "disorganize": ['D IH0 S AO1 R G AH0 N AY2 Z'],
  "disorganized": ['D IH0 S AO1 R G AH0 N AY2 Z D'],
  "disorient": ['D IH0 S AO1 R IY0 EH2 N T'],
  "disorientation": ['D IH0 S AO2 R IY0 AH0 N T EY1 SH AH0 N'],
  "disoriented": ['D IH0 S AO1 R IY0 EH2 N T IH0 D'],
  "disorienting": ['D IH0 S AO1 R IY0 EH2 N T IH0 NG'],
  "disown": ['D IH0 S OW1 N'],
  "disowned": ['D IH0 S OW1 N D'],
  "dispair": ['D IH0 S P EH1 R'],
  "disparage": ['D IH0 S P EH1 R IH0 JH'],
  "disparaged": ['D IH0 S P EH1 R IH0 JH D'],
  "disparages": ['D IH0 S P EH1 R IH0 JH IH0 Z'],
  "disparaging": ['D IH0 S P EH1 R IH0 JH IH0 NG'],
  "disparagingly": ['D IH0 S P EH1 R IH0 JH IH0 NG L IY0'],
  "disparate": ['D IH1 S P ER0 IH0 T', 'D IH0 S P EH1 R IH0 T'],
  "disparities": ['D IH0 S P EH1 R AH0 T IY0 Z'],
  "disparity": ['D IH0 S P EH1 R AH0 T IY0'],
  "dispassionate": ['D IH0 S P AE1 SH AH0 N AH0 T'],
  "dispassionately": ['D IH0 S P AE1 SH AH0 N AH0 T L IY0'],
  "dispatch": ['D IH0 S P AE1 CH'],
  "dispatched": ['D IH0 S P AE1 CH T'],
  "dispatcher": ['D IH0 S P AE1 CH ER0', 'D IH1 S P AE2 CH ER0'],
  "dispatcher's": ['D IH0 S P AE1 CH ER0 Z', 'D IH1 S P AE2 CH ER0 Z'],
  "dispatchers": ['D IH0 S P AE1 CH ER0 Z'],
  "dispatches": ['D IH0 S P AE1 CH IH0 Z'],
  "dispatching": ['D IH0 S P AE1 CH IH0 NG'],
  "dispel": ['D IH0 S P EH1 L'],
  "dispell": ['D IH0 S P EH1 L'],
  "dispelled": ['D IH0 S P EH1 L D'],
  "dispelling": ['D IH0 S P EH1 L IH0 NG'],
  "dispels": ['D IH0 S P EH1 L Z'],
  "dispensable": ['D IH0 S P EH1 N S AH0 B AH0 L'],
  "dispensary": ['D IH0 S P EH1 N S ER0 IY0', 'D IH1 S P EH0 N S EH2 R IY0'],
  "dispensation": ['D IH2 S P AH0 N S EY1 SH AH0 N'],
  "dispense": ['D IH0 S P EH1 N S'],
  "dispensed": ['D IH0 S P EH1 N S T'],
  "dispenser": ['D IH0 S P EH1 N S ER0'],
  "dispensers": ['D IH0 S P EH1 N S ER0 Z'],
  "dispenses": ['D IH0 S P EH1 N S IH0 Z'],
  "dispensing": ['D IH0 S P EH1 N S IH0 NG'],
  "dispenza": ['D IH0 S P EH1 N Z AH0'],
  "dispersal": ['D IH0 S P ER1 S AH0 L'],
  "dispersant": ['D IH2 S P ER1 S AH0 N T'],
  "dispersants": ['D IH2 S P ER1 S AH0 N T S'],
  "disperse": ['D IH0 S P ER1 S'],
  "dispersed": ['D IH0 S P ER1 S T'],
  "dispersing": ['D IH0 S P ER1 S IH0 NG'],
  "dispersion": ['D IH0 S P ER1 ZH AH0 N'],
  "dispersive": ['D IH0 S P ER1 S IH0 V'],
  "dispirited": ['D IH0 S P IH1 R AH0 T IH0 D'],
  "dispiriting": ['D IH0 S P IH1 R IH0 T IH0 NG'],
  "displace": ['D IH0 S P L EY1 S'],
  "displaced": ['D IH0 S P L EY1 S T'],
  "displacement": ['D IH0 S P L EY1 S M AH0 N T'],
  "displacements": ['D IH0 S P L EY1 S M AH0 N T S'],
  "displaces": ['D IH0 S P L EY1 S IH0 Z'],
  "displacing": ['D IH0 S P L EY1 S IH0 NG'],
  "display": ['D IH0 S P L EY1'],
  "displayed": ['D IH0 S P L EY1 D'],
  "displaying": ['D IH0 S P L EY1 IH0 NG'],
  "displays": ['D IH0 S P L EY1 Z'],
  "displaywrite": ['D IH0 S P L EY1 R AY2 T'],
  "displaywriter": ['D IH0 S P L EY1 R AY2 T ER0'],
  "displease": ['D IH0 S P L IY1 Z'],
  "displeased": ['D IH0 S P L IY1 Z D'],
  "displeasure": ['D IH0 S P L EH1 ZH ER0'],
  "disposable": ['D IH0 S P OW1 Z AH0 B AH0 L'],
  "disposables": ['D IH0 S P OW1 Z AH0 B AH0 L Z'],
  "disposal": ['D IH0 S P OW1 Z AH0 L'],
  "disposals": ['D IH0 S P OW1 Z AH0 L Z'],
  "dispose": ['D IH0 S P OW1 Z'],
  "disposed": ['D IH0 S P OW1 Z D'],
  "disposer": ['D IH0 S P OW1 Z ER0'],
  "disposes": ['D IH0 S P OW1 Z IH0 Z'],
  "disposing": ['D IH0 S P OW1 Z IH0 NG'],
  "disposition": ['D IH2 S P AH0 Z IH1 SH AH0 N'],
  "dispositions": ['D IH2 S P AH0 Z IH1 SH AH0 N Z'],
  "dispositive": ['D IH2 S P AA1 Z AH0 T IH0 V'],
  "dispossess": ['D IH2 S P AH0 Z EH1 S'],
  "dispossessed": ['D IH2 S P AH0 Z EH1 S T'],
  "disproportionate": ['D IH2 S P R AH0 P AO1 R SH AH0 N IH0 T'],
  "disproportionately": ['D IH2 S P R AH0 P AO1 R SH AH0 N AH0 T L IY0'],
  "disprove": ['D IH0 S P R UW1 V'],
  "disproved": ['D IH0 S P R UW1 V D'],
  "disproven": ['D IH0 S P R UW1 V IH0 N'],
  "disproves": ['D IH0 S P R UW1 V Z'],
  "disputable": ['D IH0 S P Y UW1 T AH0 B AH0 L'],
  "disputation": ['D IH0 S P Y UW1 T EY1 SH AH0 N'],
  "dispute": ['D IH0 S P Y UW1 T'],
  "disputed": ['D IH0 S P Y UW1 T AH0 D', 'D IH0 S P Y UW1 T IH0 D'],
  "disputes": ['D IH0 S P Y UW1 T S'],
  "disputing": ['D IH0 S P Y UW1 T IH0 NG'],
  "disqualification": ['D IH0 S K W AA2 L AH0 F AH0 K EY1 SH AH0 N'],
  "disqualified": ['D IH0 S K W AA1 L AH0 F AY2 D'],
  "disqualifies": ['D IH0 S K W AA1 L AH0 F AY2 Z'],
  "disqualify": ['D IH0 S K W AA1 L AH0 F AY2'],
  "disqualifying": ['D IH0 S K W AA1 L AH0 F AY2 IH0 NG'],
  "disque": ['D IH1 S K'],
  "disquiet": ['D IH0 S K W AY1 AH0 T'],
  "disquieting": ['D IH0 S K W AY1 AH0 T IH0 NG'],
  "disraeli": ['D IH0 Z R EY1 L IY0'],
  "disregard": ['D IH2 S R IH0 G AA1 R D'],
  "disregarded": ['D IH2 S R IH0 G AA1 R D IH0 D'],
  "disregarding": ['D IH2 S R IH0 G AA1 R D IH0 NG'],
  "disregards": ['D IH2 S R IH0 G AA1 R D Z'],
  "disrepair": ['D IH2 S R IH0 P EH1 R'],
  "disreputable": ['D IH0 S R EH1 P Y AH0 T AH0 B AH0 L'],
  "disrepute": ['D IH2 S R IH0 P Y UW1 T'],
  "disrespect": ['D IH2 S R IH0 S P EH1 K T'],
  "disrespected": ['D IH2 S R IH0 S P EH1 K T IH0 D'],
  "disrespectful": ['D IH2 S R IH0 S P EH1 K T F AH0 L'],
  "disrespecting": ['D IH2 S R IH0 S P EH1 K T IH0 NG'],
  "disrespects": ['D IH2 S R IH0 S P EH1 K T S'],
  "disrupt": ['D IH0 S R AH1 P T'],
  "disrupted": ['D IH0 S R AH1 P T IH0 D'],
  "disrupting": ['D IH0 S R AH1 P T IH0 NG'],
  "disruption": ['D IH0 S R AH1 P SH AH0 N'],
  "disruptions": ['D IH0 S R AH1 P SH AH0 N Z'],
  "disruptive": ['D IH0 S R AH1 P T IH0 V'],
  "disrupts": ['D IH0 S R AH1 P T S'],
  "diss": ['D IH1 S'],
  "dissatisfaction": ['D IH2 S AE0 T IH0 S F AE1 K SH AH0 N'],
  "dissatisfied": ['D IH0 S AE1 T AH0 S F AY2 D'],
  "dissatisfy": ['D IH0 S AE1 T AH0 S F AY2'],
  "dissect": ['D AY0 S EH1 K T'],
  "dissected": ['D AY0 S EH1 K T AH0 D'],
  "dissecting": ['D AY0 S EH1 K T IH0 NG'],
  "dissection": ['D AY0 S EH1 K SH AH0 N', 'D AY1 S EH0 K SH AH0 N'],
  "dissections": ['D AY0 S EH1 K SH AH0 N Z', 'D AY1 S EH0 K SH AH0 N Z'],
  "dissects": ['D AY0 S EH1 K T S', 'D AY0 S EH1 K S'],
  "dissemble": ['D IH0 S EH1 M B AH0 L'],
  "dissembling": ['D IH0 S EH1 M B L IH0 NG'],
  "disseminate": ['D IH0 S EH1 M AH0 N EY2 T'],
  "disseminated": ['D IH0 S EH1 M AH0 N EY2 T AH0 D'],
  "disseminates": ['D IH0 S EH1 M AH0 N EY2 T S'],
  "disseminating": ['D IH0 S EH1 M AH0 N EY2 T IH0 NG'],
  "dissemination": ['D IH0 S EH2 M AH0 N EY1 SH AH0 N'],
  "dissension": ['D IH0 S EH1 N SH AH0 N'],
  "dissent": ['D IH0 S EH1 N T'],
  "dissented": ['D IH0 S EH1 N T IH0 D', 'D IH0 S EH1 N IH0 D'],
  "dissenter": ['D IH0 S EH1 N T ER0'],
  "dissenters": ['D IH0 S EH1 N T ER0 Z', 'D IH0 S EH1 N ER0 Z'],
  "dissenting": ['D IH0 S EH1 N T IH0 NG', 'D IH0 S EH1 N IH0 NG'],
  "dissents": ['D IH0 S EH1 N T S'],
  "dissertation": ['D IH2 S ER0 T EY1 SH AH0 N'],
  "disservice": ['D IH0 S ER1 V AH0 S'],
  "dissidence": ['D IH1 S AH0 D AH0 N S'],
  "dissident": ['D IH1 S AH0 D AH0 N T'],
  "dissidents": ['D IH1 S AH0 D AH0 N T S'],
  "dissidents'": ['D IH1 S AH0 D AH0 N T S'],
  "dissimilar": ['D IH0 S IH1 M AH0 L ER0'],
  "dissimilarity": ['D IH2 S IH2 M AH0 L AE1 R AH0 T IY0'],
  "dissing": ['D IH1 S IH0 NG'],
  "dissinger": ['D IH1 S IH0 N JH ER0'],
  "dissipate": ['D IH1 S AH0 P EY2 T'],
  "dissipated": ['D IH1 S AH0 P EY2 T IH0 D'],
  "dissipates": ['D IH1 S AH0 P EY2 T S'],
  "dissipating": ['D IH1 S AH0 P EY2 T IH0 NG'],
  "dissipation": ['D IH2 S IH0 P EY1 SH AH0 N'],
  "dissipative": ['D IH1 S AH0 P EY2 T IH0 V'],
  "dissociate": ['D IH0 S OW1 S IY0 EY0 T'],
  "dissociation": ['D IH0 S OW2 S IY0 EY1 SH AH0 N'],
  "dissolution": ['D IH2 S AH0 L UW1 SH AH0 N'],
  "dissolve": ['D IH0 Z AA1 L V'],
  "dissolved": ['D IH0 Z AA1 L V D'],
  "dissolver": ['D IH0 Z AA1 L V ER0'],
  "dissolvers": ['D IH0 Z AA1 L V ER0 Z'],
  "dissolves": ['D IH0 Z AA1 L V Z'],
  "dissolving": ['D IH0 Z AO1 L V IH0 NG'],
  "dissonance": ['D IH1 S AH0 N AH0 N S'],
  "dissonant": ['D IH1 S AH0 N AH0 N T'],
  "dissuade": ['D IH0 S W EY1 D'],
  "dissuaded": ['D IH0 S W EY1 D IH0 D'],
  "dissymmetric": ['D IH2 S IH0 M EH1 T R IH0 K'],
  "dissymmetry": ['D IH0 S IH1 M AH0 T R IY0'],
  "distad": ['D IH1 S T AH0 D'],
  "distal": ['D IH1 S T AH0 L'],
  "distance": ['D IH1 S T AH0 N S'],
  "distanced": ['D IH1 S T AH0 N S T'],
  "distances": ['D IH1 S T AH0 N S AH0 Z', 'D IH1 S T AH0 N S IH0 Z'],
  "distancing": ['D IH1 S T AH0 N S IH0 NG'],
  "distant": ['D IH1 S T AH0 N T'],
  "distasi": ['D IH0 S T AA1 S IY0'],
  "distasio": ['D IH0 S T AA1 S IY0 OW0'],
  "distaste": ['D IH0 S T EY1 S T'],
  "distasteful": ['D IH0 S T EY1 S T F AH0 L'],
  "distefano": ['D IH0 S T EH0 F AA1 N OW0'],
  "distel": ['D IH1 S T AH0 L'],
  "distemper": ['D IH0 S T EH1 M P ER0'],
  "distend": ['D IH0 S T EH1 N D'],
  "distended": ['D IH0 S T EH1 N D IH0 D'],
  "distil": ['D IH0 S T IH1 L'],
  "distill": ['D IH0 S T IH1 L'],
  "distillate": ['D IH1 S T AH0 L EY2 T', 'D IH1 S T AH0 L AH0 T'],
  "distillates": ['D IH1 S T AH0 L EY2 T S'],
  "distillation": ['D IH2 S T AH0 L EY1 SH AH0 N'],
  "distilled": ['D IH0 S T IH1 L D'],
  "distiller": ['D IH0 S T IH1 L ER0'],
  "distiller's": ['D IH0 S T IH1 L ER0 Z'],
  "distilleries": ['D IH0 S T IH1 L ER0 IY0 Z'],
  "distillers": ['D IH0 S T IH1 L ER0 Z'],
  "distillers'": ['D IH0 S T IH1 L ER0 Z'],
  "distillers's": ['D IH0 S T IH1 L ER0 Z IH0 Z'],
  "distillery": ['D IH0 S T IH1 L ER0 IY0'],
  "distilling": ['D IH0 S T IH1 L IH0 NG'],
  "distills": ['D IH0 S T IH1 L Z'],
  "distinct": ['D IH0 S T IH1 NG K T'],
  "distinction": ['D IH0 S T IH1 NG K SH AH0 N'],
  "distinctions": ['D IH0 S T IH1 NG K SH AH0 N Z'],
  "distinctive": ['D IH0 S T IH1 NG K T IH0 V'],
  "distinctively": ['D IH0 S T IH1 NG K T IH0 V L IY0'],
  "distinctiveness": ['D IH0 S T IH1 NG K T IH0 V N AH0 S'],
  "distinctly": ['D IH0 S T IH1 NG K T L IY0'],
  "distinguish": ['D IH0 S T IH1 NG G W IH0 SH'],
  "distinguishable": ['D IH0 S T IH1 NG G W IH0 SH AH0 B AH0 L'],
  "distinguished": ['D IH0 S T IH1 NG G W IH0 SH T'],
  "distinguishes": ['D IH0 S T IH1 NG G W IH0 SH IH0 Z'],
  "distinguishing": ['D IH0 S T IH1 NG G W IH0 SH IH0 NG'],
  "distler": ['D IH1 S T L ER0'],
  "distort": ['D IH0 S T AO1 R T'],
  "distorted": ['D IH0 S T AO1 R T AH0 D', 'D IH0 S T AO1 R T IH0 D'],
  "distorting": ['D IH0 S T AO1 R T IH0 NG'],
  "distortion": ['D IH0 S T AO1 R SH AH0 N'],
  "distortions": ['D IH0 S T AO1 R SH AH0 N Z'],
  "distorts": ['D IH0 S T AO1 R T S'],
  "distract": ['D IH0 S T R AE1 K T'],
  "distracted": ['D IH0 S T R AE1 K T AH0 D', 'D IH0 S T R AE1 K T IH0 D'],
  "distracting": ['D IH0 S T R AE1 K T IH0 NG'],
  "distraction": ['D IH0 S T R AE1 K SH AH0 N'],
  "distractions": ['D IH0 S T R AE1 K SH AH0 N Z'],
  "distracts": ['D IH0 S T R AE1 K T S'],
  "distraught": ['D IH0 S T R AO1 T'],
  "distress": ['D IH0 S T R EH1 S'],
  "distressed": ['D IH0 S T R EH1 S T'],
  "distresses": ['D IH0 S T R EH1 S IH0 Z'],
  "distressing": ['D IH0 S T R EH1 S IH0 NG'],
  "distressingly": ['D IH0 S T R EH1 S IH0 NG L IY0'],
  "distribute": ['D IH0 S T R IH1 B Y UW0 T'],
  "distributed": ['D IH0 S T R IH1 B Y AH0 T AH0 D'],
  "distributes": ['D IH0 S T R IH1 B Y UW0 T S'],
  "distributing": ['D IH0 S T R IH1 B Y UW0 T IH0 NG'],
  "distribution": ['D IH2 S T R AH0 B Y UW1 SH AH0 N'],
  "distributions": ['D IH2 S T R AH0 B Y UW1 SH AH0 N Z'],
  "distributive": ['D IH0 S T R IH1 B Y UW0 T IH0 V'],
  "distributor": ['D IH0 S T R IH1 B Y AH0 T ER0'],
  "distributor's": ['D IH0 S T R IH1 B Y UW0 T ER0 Z'],
  "distributors": ['D IH0 S T R IH1 B Y AH0 T ER0 Z'],
  "distributors'": ['D IH0 S T R IH1 B Y AH0 T ER0 Z'],
  "distributorship": ['D IH0 S T R IH1 B Y UW0 T ER0 SH IH2 P'],
  "distributorships": ['D IH0 S T R IH1 B Y UW0 T ER0 SH IH2 P S'],
  "district": ['D IH1 S T R IH0 K T'],
  "district's": ['D IH1 S T R IH0 K T S'],
  "districting": ['D IH1 S T R IH0 K T IH0 N G'],
  "districts": ['D IH1 S T R IH0 K T S'],
  "distrigas": ['D IH0 S T R IY1 G AH0 S'],
  "distron": ['D IH1 S T R AA2 N'],
  "distrust": ['D IH0 S T R AH1 S T'],
  "distrusted": ['D IH0 S T R AH1 S T AH0 D', 'D IH0 S T R AH1 S T IH0 D'],
  "distrustful": ['D IH0 S T R AH1 S T F AH0 L'],
  "distrusts": ['D IH0 S T R AH1 S T S'],
  "disturb": ['D IH0 S T ER1 B'],
  "disturbance": ['D IH0 S T ER1 B AH0 N S'],
  "disturbances": ['D IH0 S T ER1 B AH0 N S AH0 Z', 'D IH0 S T ER1 B AH0 N S IH0 Z'],
  "disturbed": ['D IH0 S T ER1 B D'],
  "disturbing": ['D IH0 S T ER1 B IH0 NG'],
  "disturbingly": ['D IH0 S T ER1 B IH0 NG L IY0'],
  "disturbs": ['D IH0 S T ER1 B Z'],
  "disunion": ['D IH0 S Y UW1 N Y AH0 N'],
  "disunity": ['D IH0 S Y UW1 N AH0 T IY0'],
  "disuse": ['D IH0 S Y UW1 S'],
  "ditch": ['D IH1 CH'],
  "ditched": ['D IH1 CH T'],
  "ditches": ['D IH1 CH AH0 Z', 'D IH1 CH IH0 Z'],
  "ditching": ['D IH1 CH IH0 NG'],
  "dith": ['D IH1 TH'],
  "dither": ['D IH1 DH ER0'],
  "dithering": ['D IH1 DH ER0 IH0 NG'],
  "dithers": ['D IH1 DH ER0 Z'],
  "dithyramb": ['D IH1 TH ER0 AE2 M'],
  "ditka": ['D IH1 T K AH0'],
  "ditka's": ['D IH1 T K AH0 Z'],
  "ditlow": ['D IH1 T L OW0'],
  "ditmars": ['D IH1 T M ER0 Z'],
  "ditmer": ['D IH1 T M ER0'],
  "ditmore": ['D IH1 T M AO0 R'],
  "ditomasso": ['D IH0 T OW0 M AA1 S OW2'],
  "ditommaso": ['D IH0 T OW0 M AA1 S OW2'],
  "ditsy": ['D IH1 T S IY0'],
  "dittberner": ['D IH1 T B ER0 N ER0'],
  "dittemore": ['D IH0 T EH1 M AO0 R'],
  "ditter": ['D IH1 T ER0'],
  "ditties": ['D IH1 T IY0 Z'],
  "dittman": ['D IH1 T M AH0 N'],
  "dittmann": ['D IH1 T M AH0 N'],
  "dittmar": ['D IH1 T M ER0'],
  "dittmer": ['D IH1 T M ER0'],
  "ditto": ['D IH1 T OW0'],
  "dittohead": ['D IH1 T OW0 HH EH2 D'],
  "ditton": ['D IH1 T AH0 N'],
  "dittrich": ['D IH1 T R IH0 K'],
  "dittus": ['D IH1 T AH0 S'],
  "ditty": ['D IH1 T IY0'],
  "ditullio": ['D IH0 T AH1 L IY0 OW2'],
  "ditzel": ['D IH1 T Z AH0 L'],
  "ditzler": ['D IH1 T S L ER0'],
  "diuretic": ['D AY2 UW0 R EH1 T IH0 K'],
  "diuretics": ['D AY2 UW0 R EH1 T IH0 K S'],
  "diurnal": ['D AY0 ER1 N AH0 L'],
  "diurnally": ['D AY0 ER1 N AH0 L IY0'],
  "diva": ['D IY1 V AH0'],
  "divad": ['D IH1 V AE0 D'],
  "divalent": ['D AY0 V EY1 L AH0 N T'],
  "divall": ['D IH1 V AH0 L'],
  "divan": ['D IH0 V AE1 N'],
  "divas": ['D IY1 V AH0 Z'],
  "dive": ['D AY1 V'],
  "dived": ['D AY1 V D'],
  "divelbiss": ['D IH0 V EH1 L B IH0 S'],
  "diveley": ['D IH1 V IH0 L IY0'],
  "dively": ['D AY1 V L IY0'],
  "diven": ['D AY1 V AH0 N'],
  "divens": ['D AY1 V AH0 N Z'],
  "diver": ['D AY1 V ER0'],
  "diver's": ['D AY1 V ER0 Z'],
  "diverge": ['D IH0 V ER1 JH'],
  "diverged": ['D AY0 V ER1 JH D'],
  "divergence": ['D AY0 V ER1 JH AH0 N S', 'D IH0 V ER1 JH AH0 N S'],
  "divergences": ['D AY0 V ER1 JH AH0 N S IH0 Z'],
  "divergent": ['D AY0 V ER1 JH AH0 N T', 'D IH0 V ER1 JH AH0 N T'],
  "diverges": ['D AY0 V ER1 JH IH0 Z'],
  "diverging": ['D AY0 V ER1 JH IH0 NG'],
  "divers": ['D AY1 V ER0 Z'],
  "diverse": ['D AY0 V ER1 S', 'D IH0 V ER1 S'],
  "diversicare": ['D IH1 V ER0 S IH0 K EH2 R'],
  "diversification": ['D AY0 V ER2 S AH0 F AH0 K EY1 SH AH0 N', 'D IH0 V ER2 S AH0 F AH0 K EY1 SH AH0 N'],
  "diversifications": ['D AY0 V ER2 S AH0 F AH0 K EY1 SH AH0 N Z', 'D IH0 V ER2 S AH0 F AH0 K EY1 SH AH0 N Z'],
  "diversified": ['D AY0 V ER1 S AH0 F AY2 D', 'D IH0 V ER1 S AH0 F AY2 D'],
  "diversify": ['D AY0 V ER1 S AH0 F AY2', 'D IH0 V ER1 S AH0 F AY2'],
  "diversifying": ['D AY0 V ER1 S AH0 F AY2 IH0 NG', 'D IH0 V ER1 S AH0 F AY2 IH0 NG'],
  "diversion": ['D AY0 V ER1 ZH AH0 N', 'D IH0 V ER1 ZH AH0 N'],
  "diversionary": ['D AY0 V ER1 ZH AH0 N EH2 R IY0', 'D IH0 V ER1 ZH AH0 N EH2 R IY0'],
  "diversions": ['D IH0 V ER1 ZH AH0 N Z', 'D AY0 V ER1 ZH AH0 N Z'],
  "diversity": ['D IH0 V ER1 S IH0 T IY0', 'D AY0 V ER1 S IH0 T IY0'],
  "divert": ['D AY0 V ER1 T', 'D IH0 V ER1 T'],
  "diverted": ['D AY0 V ER1 T IH0 D', 'D IH0 V ER1 T IH0 D'],
  "diverticula": ['D AY2 V ER0 T IH1 K Y AH0 L AH0'],
  "diverticulum": ['D AY2 V ER0 T IH1 K Y AH0 L AH0 M'],
  "divertimento": ['D IH0 V ER2 T AH0 M EH1 N T OW2'],
  "diverting": ['D AY0 V ER1 T IH0 NG', 'D IH0 V ER1 T IH0 NG'],
  "diverts": ['D AY0 V ER1 T S', 'D IH0 V ER1 T S'],
  "dives": ['D AY1 V Z'],
  "divest": ['D AY0 V EH1 S T', 'D IH0 V EH1 S T'],
  "divested": ['D AY0 V EH1 S T IH0 D'],
  "divesting": ['D AY0 V EH1 S T IH0 NG'],
  "divestiture": ['D IH0 V EH1 S T IH0 CH ER0', 'D AY0 V EH1 S T IH0 CH ER0'],
  "divestitures": ['D IH0 V EH1 S T IH0 CH ER0 Z', 'D AY0 V EH1 S T IH0 CH ER0 Z'],
  "divestment": ['D AY0 V EH1 S T M AH0 N T', 'D AY0 V EH1 S M AH0 N T', 'D IH0 V EH1 S M AH0 N T'],
  "divestments": ['D AY0 V EH1 S T M AH0 N T S', 'D AY0 V EH1 S M AH0 N T S', 'D IH0 V EH1 S M AH0 N T S'],
  "divests": ['D AY0 V EH1 S T S'],
  "divi": ['D IY1 V IY0'],
  "divide": ['D IH0 V AY1 D'],
  "divided": ['D IH0 V AY1 D IH0 D'],
  "dividend": ['D IH1 V IH0 D EH2 N D'],
  "dividend's": ['D IH1 V AH0 D EH2 N D Z'],
  "dividends": ['D IH1 V AH0 D EH2 N D Z'],
  "divider": ['D IH0 V AY1 D ER0'],
  "divides": ['D IH0 V AY1 D Z'],
  "dividing": ['D IH0 V AY1 D IH0 NG'],
  "divination": ['D IH2 V AH0 N EY1 SH AH0 N'],
  "divincenzo": ['D IH0 V IY0 N CH EH1 N Z OW0'],
  "divine": ['D IH0 V AY1 N'],
  "divinely": ['D IH0 V AY1 N L IY0'],
  "diviney": ['D IH1 V IH0 N IY0'],
  "diving": ['D AY1 V IH0 NG'],
  "divining": ['D AH0 V AY1 N IH0 NG'],
  "divinitas": ['D IH2 V IH0 N IY1 T AH0 S'],
  "divinities": ['D IH0 V IH1 N AH0 T IY0 Z'],
  "divinity": ['D IH0 V IH1 N AH0 T IY0'],
  "divirgilio": ['D IH0 V IH0 R JH IY1 L IY0 OW2'],
  "divis": ['D IY1 V IH0 S'],
  "divisible": ['D IH0 V IH1 Z AH0 B AH0 L'],
  "division": ['D IH0 V IH1 ZH AH0 N'],
  "division's": ['D IH0 V IH1 ZH AH0 N Z'],
  "divisional": ['D IH0 V IH1 ZH AH0 N AH0 L'],
  "divisions": ['D IH0 V IH1 ZH AH0 N Z'],
  "divisions'": ['D IH0 V IH1 ZH AH0 N Z'],
  "divisive": ['D IH0 V AY1 S IH0 V'],
  "divisiveness": ['D IH0 V AY1 S IH0 V N AH0 S'],
  "divison": ['D IH0 V IH1 ZH AH0 N', 'D IH0 V IH1 S AH0 N'],
  "divisor": ['D IH0 V AY1 Z ER0'],
  "divita": ['D IH0 V IY1 T AH0'],
  "divito": ['D IH0 V IY1 T OW0'],
  "divorce": ['D IH0 V AO1 R S'],
  "divorced": ['D IH0 V AO1 R S T'],
  "divorcee": ['D AH0 V AO1 R S IY2', 'D AH0 V AO1 R S EY2'],
  "divorces": ['D IH0 V AO1 R S IH0 Z'],
  "divorcing": ['D IH0 V AO1 R S IH0 NG'],
  "divulge": ['D IH0 V AH1 L JH', 'D AY0 V AH1 L JH'],
  "divulged": ['D IH0 V AH1 L JH D', 'D AY0 V AH1 L JH D'],
  "divulging": ['D IH0 V AH1 L JH IH0 NG', 'D AY0 V AH1 L JH IH0 NG'],
  "divvied": ['D IH1 V IY0 D'],
  "divvy": ['D IH1 V IY0'],
  "diwa": ['D IY1 W AH0'],
  "diwali": ['D IH0 W AA1 L IY0'],
  "dix": ['D IH1 K S'],
  "dixie": ['D IH1 K S IY0'],
  "dixieland": ['D IH1 K S IY0 L AE2 N D'],
  "dixon": ['D IH1 K S AH0 N'],
  "dixon's": ['D IH1 K S AH0 N Z'],
  "dixons": ['D IH1 K S AH0 N Z'],
  "dixson": ['D IH1 K S AH0 N'],
  "dixville": ['D IH1 K S V IH0 L'],
  "dixy": ['D IH1 K S IY0'],
  "dizon": ['D IH1 Z AH0 N'],
  "dizziness": ['D IH1 Z IY0 N AH0 S'],
  "dizzy": ['D IH1 Z IY0'],
  "dizzying": ['D IH1 Z IY0 IH0 NG'],
  "dj": ['D IY1 JH EY1'],
  "djakarta": ['JH AH0 K AA1 R T AH0'],
  "djakarta's": ['JH AH0 K AA1 R T AH0 Z'],
  "djibouti": ['JH IH0 B UW1 T IY2'],
  "djukanovic": ['JH UW0 K AE1 N AH0 V IH0 K', 'JH AH0 K AE1 N AH0 V IH0 CH'],
  "djurdjevic": ['JH ER1 JH AH0 V IH0 K'],
  "dk": ['D IY1 K EY1'],
  "dlouhy": ['D AH0 L AW1 IY0'],
  "dlugos": ['D AH0 L UW1 G OW0 S'],
  "dlugosz": ['D AH0 L UW1 G OW0 S', 'D AH0 L UW1 G OW0 SH'],
  "dmitri": ['D AH0 M IY1 T R IY0', 'D M IY1 T R IY0'],
  "dmz": ['D IY1 EH2 M Z IY2'],
  "dna": ['D IY1 EH2 N EY1'],
  "dnase": ['D IY1 EH2 N EY2 S', 'D IY1 EH2 N EY2 Z'],
  "dnc": ['D IY1 EH2 N S IY2'],
  "dniester": ['D N IY1 S T ER0'],
  "dns": ['D IY2 EH2 N EH1 S'],
  "do": ['D UW1'],
  "do's": ['D UW1 Z'],
  "doable": ['D UW1 AH0 B AH0 L'],
  "doak": ['D OW1 K'],
  "doan": ['D OW1 N'],
  "doan's": ['D OW1 N Z'],
  "doane": ['D OW1 N'],
  "doanh": ['D OW1 N'],
  "doanna": ['D OW1 N AH0'],
  "doar": ['D AO1 R'],
  "dob": ['D AA1 B'],
  "dobb": ['D AA1 B'],
  "dobberstein": ['D AA1 B ER0 S T IY2 N', 'D AA1 B ER0 S T AY2 N'],
  "dobbie": ['D AA1 B IY0'],
  "dobbin": ['D AA1 B IH0 N'],
  "dobbins": ['D AA1 B IH0 N Z'],
  "dobbs": ['D AA1 B Z'],
  "dobek": ['D OW1 B IH0 K'],
  "dober": ['D OW1 B ER0'],
  "doberman": ['D OW1 B ER0 M AH0 N'],
  "doberstein": ['D OW1 B ER0 S T AY0 N', 'D OW1 B ER0 S T IY0 N'],
  "dobesh": ['D AA1 B IH0 SH'],
  "dobey": ['D AA1 B IY0'],
  "dobias": ['D OW0 B IY1 AH0 Z'],
  "dobie": ['D AA1 B IY0'],
  "dobies": ['D OW1 B IY0 Z'],
  "dobis": ['D OW1 B IH0 S'],
  "dobkin": ['D AA1 B K IH0 N'],
  "dobkins": ['D AA1 B K IH0 N Z'],
  "doble": ['D OW1 B AH0 L'],
  "dobler": ['D OW1 B AH0 L ER0', 'D OW1 B L ER0'],
  "dobmeier": ['D AA1 B M AY0 ER0'],
  "doboj": ['D OW1 B AO2 JH'],
  "dobos": ['D OW1 B OW0 Z'],
  "dobosz": ['D AA1 B AH0 SH'],
  "dobransky": ['D AH0 B R AE1 N S K IY0'],
  "dobratz": ['D AA1 B R AH0 T S'],
  "dobrimir": ['D AH0 B R IY1 M IH0 R'],
  "dobrin": ['D AA1 B R IH0 N'],
  "dobrinja": ['D AH0 B R IY1 N JH AH0', 'D AH0 B R IY1 N Y AH0'],
  "dobrins": ['D AA1 B R IH0 N Z'],
  "dobrinski": ['D AH0 B R IH1 N S K IY0'],
  "dobro": ['D OW1 B R OW2'],
  "dobrowolski": ['D AH0 B R AW0 OW1 L S K IY0'],
  "dobrowski": ['D AH0 B R AO1 F S K IY0'],
  "dobry": ['D AA1 B R IY0'],
  "dobrynin": ['D AA1 B R IH0 N IH0 N', 'D AH0 B R IY1 N IH0 N'],
  "dobrzynski": ['D OW2 B R AH0 ZH IH1 N S K IY0'],
  "dobson": ['D AA1 B S AH0 N'],
  "dobson's": ['D AA1 B S AH0 N Z'],
  "dobsons": ['D AA1 B S AH0 N Z'],
  "doby": ['D OW1 B IY0'],
  "dobyns": ['D OW1 B IH0 N Z'],
  "doc": ['D AA1 K'],
  "docent": ['D OW1 S AH0 N T'],
  "docents": ['D OW1 S AH0 N T S'],
  "docherty": ['D AA1 CH ER0 T IY0'],
  "dochow": ['D OW1 CH AW0'],
  "dochterman": ['D AA1 K T ER0 M AH0 N'],
  "docie": ['D AA1 K IY0'],
  "docila": ['D AA1 S IH0 L AH0'],
  "docile": ['D AA1 S AH0 L'],
  "docility": ['D AA0 S IH1 L AH0 T IY0'],
  "dock": ['D AA1 K'],
  "dock's": ['D AA1 K S'],
  "docked": ['D AA1 K T'],
  "docken": ['D AA1 K AH0 N'],
  "dockendorf": ['D AA1 K IH0 N D AO0 R F'],
  "docker": ['D AA1 K ER0'],
  "dockers": ['D AA1 K ER0 Z'],
  "dockery": ['D AA1 K ER0 IY0'],
  "docket": ['D AA1 K AH0 T'],
  "dockets": ['D AA1 K AH0 T S'],
  "dockham": ['D AA1 K HH AH0 M'],
  "dockiers": ['D AA1 K Y ER0 Z'],
  "docking": ['D AA1 K IH0 NG'],
  "dockings": ['D AA1 K IH0 NG Z'],
  "dockins": ['D AA1 K IH0 N Z'],
  "dockland": ['D AA1 K L AH0 N D'],
  "docklands": ['D AA1 K L AH0 N D Z'],
  "docks": ['D AA1 K S'],
  "dockside": ['D AA1 K S AY2 D'],
  "dockson": ['D AA1 K S AH0 N'],
  "dockstader": ['D AA1 K S T AH0 D ER0'],
  "dockter": ['D AA1 K T ER0'],
  "dockworker": ['D AA1 K W ER2 K ER0'],
  "dockworkers": ['D AA1 K W ER2 K ER0 Z'],
  "dockyard": ['D AA1 K Y AA2 R D'],
  "docs": ['D AA1 K S'],
  "doctor": ['D AA1 K T ER0', 'D AO1 K T ER0'],
  "doctor's": ['D AA1 K T ER0 Z'],
  "doctoral": ['D AA1 K T ER0 AH0 L'],
  "doctorate": ['D AA1 K T ER0 AH0 T'],
  "doctorates": ['D AA1 K T ER0 AH0 T S'],
  "doctored": ['D AA1 K T ER0 D'],
  "doctoring": ['D AA1 K T ER0 IH0 NG'],
  "doctors": ['D AA1 K T ER0 Z'],
  "doctors'": ['D AA1 K T ER0 Z'],
  "doctrinaire": ['D AA2 K T R AH0 N EH1 R'],
  "doctrinal": ['D AA1 K T R AH0 N AH0 L'],
  "doctrine": ['D AA1 K T R AH0 N', 'D AO1 K T ER0 IH0 N'],
  "doctrine's": ['D AA1 K T R AH0 N Z'],
  "doctrines": ['D AA1 K T R AH0 N Z'],
  "docudrama": ['D OW2 K AH0 D R AE1 M AH0'],
  "document": ['D AA1 K Y AH0 M EH0 N T', 'D AA1 K Y UW0 M EH0 N T'],
  "documenta": ['D AA2 K Y UW0 M EH1 N T AH0'],
  "documentaries": ['D AA2 K Y AH0 M EH1 N T ER0 IY0 Z', 'D AA2 K Y AH0 M EH1 N ER0 IY0 Z', 'D AA2 K Y UW0 M EH1 N T ER0 IY0 Z', 'D AA2 K Y UW0 M EH1 N ER0 IY0 Z'],
  "documentary": ['D AA2 K Y AH0 M EH1 N T ER0 IY0', 'D AA2 K Y AH0 M EH1 N ER0 IY0', 'D AA2 K Y UW0 M EH1 N T ER0 IY0', 'D AA2 K Y UW0 M EH1 N ER0 IY0'],
  "documentation": ['D AA2 K Y AH0 M EH0 N T EY1 SH AH0 N', 'D AA2 K Y UW0 M EH0 N T EY1 SH AH0 N'],
  "documented": ['D AA1 K Y AH0 M EH2 N T AH0 D', 'D AA1 K Y AH0 M EH2 N AH0 D', 'D AA1 K Y UW0 M EH2 N T AH0 D', 'D AA1 K Y UW0 M EH2 N AH0 D'],
  "documenting": ['D AA1 K Y AH0 M AH0 N T IH0 NG', 'D AA1 K Y AH0 M AH0 N IH0 NG', 'D AA1 K Y UW0 M AH0 N T IH0 NG', 'D AA1 K Y UW0 M AH0 N IH0 NG'],
  "documents": ['D AA1 K Y AH0 M AH0 N T S', 'D AA1 K Y UW0 M AH0 N T S'],
  "dodaro": ['D OW0 D AA1 R OW0'],
  "dodd": ['D AA1 D'],
  "dodd's": ['D AA1 D Z'],
  "doddering": ['D AA1 D ER0 IH0 NG'],
  "doddington": ['D AA1 D IH0 N T AH0 N', 'D AA1 D IH0 NG T AH0 N'],
  "doddridge": ['D AA1 D R IH0 JH'],
  "dodds": ['D AA1 D Z'],
  "dodge": ['D AA1 JH'],
  "dodge's": ['D AA1 JH IH0 Z'],
  "dodged": ['D AA1 JH D'],
  "dodgen": ['D AA1 JH AH0 N'],
  "dodger": ['D AA1 JH ER0'],
  "dodger's": ['D AA1 JH ER0 Z'],
  "dodgers": ['D AA1 JH ER0 Z'],
  "dodgers'": ['D AA1 JH ER0 Z'],
  "dodges": ['D AA1 JH IH0 Z'],
  "dodging": ['D AA1 JH IH0 NG'],
  "dodi": ['D OW1 D IY0'],
  "dodi's": ['D OW1 D IY0 Z'],
  "dodo": ['D OW1 D OW0'],
  "dodo's": ['D OW1 D OW0 Z'],
  "dodos": ['D OW1 D OW0 Z'],
  "dodrill": ['D AA1 D R AH0 L'],
  "dodson": ['D AA1 D S AH0 N'],
  "dodsworth": ['D AA1 D S W ER0 TH'],
  "doe": ['D OW1'],
  "doe's": ['D OW1 Z'],
  "doebler": ['D OW1 B AH0 L ER0', 'D OW1 B L ER0'],
  "doeden": ['D OW1 D AH0 N'],
  "doege": ['D OW1 JH'],
  "doehring": ['D AO1 R IH0 NG'],
  "doell": ['D OW1 L'],
  "doenges": ['D OW1 N JH IH0 Z'],
  "doepke": ['D OW1 P K'],
  "doepker": ['D OW1 P K ER0'],
  "doer": ['D UW1 R'],
  "doerfler": ['D AO1 R F AH0 L ER0', 'D AO1 R F L ER0'],
  "doerflinger": ['D AO1 R F AH0 L IH0 NG ER0', 'D AO1 R F L IH0 NG ER0'],
  "doering": ['D UW1 ER0 IH0 NG'],
  "doernberg": ['D AO1 R N B ER0 G'],
  "doerner": ['D AO1 R N ER0'],
  "doerr": ['D AO1 R'],
  "doers": ['D UW1 ER0 Z'],
  "doersam": ['D AO1 R S AH0 M'],
  "does": ['D AH1 Z', 'D IH0 Z'],
  "doescher": ['D OW1 SH ER0'],
  "doesn't": ['D AH1 Z AH0 N T', 'D AH1 Z AH0 N'],
  "doetsch": ['D OW1 CH'],
  "dofasco": ['D AH0 F AE1 S K OW0'],
  "doff": ['D AO1 F'],
  "doffing": ['D AO1 F IH0 NG'],
  "doffs": ['D AO1 F S'],
  "dog": ['D AO1 G'],
  "dog's": ['D AO1 G Z'],
  "dogan": ['D OW1 G AH0 N'],
  "dogbane": ['D AO1 G B EY2 N'],
  "dogberry": ['D AO1 G B EH2 R IY0'],
  "doge": ['D OW1 JH'],
  "dogear": ['D AA1 G IY0 R'],
  "dogeared": ['D AA1 G IY0 R D'],
  "dogearing": ['D AA1 G IY0 R IH0 NG'],
  "dogears": ['D AA1 G IY0 R Z'],
  "dogfight": ['D AA1 G F AY2 T'],
  "dogfights": ['D AO1 G F AY2 T S'],
  "dogfish": ['D AO1 G F IH2 SH'],
  "dogged": ['D AO1 G D'],
  "doggedly": ['D AO1 G AH0 D L IY0'],
  "doggerel": ['D AA1 G ER0 AH0 L'],
  "doggett": ['D AA1 G IH0 T'],
  "doggie": ['D AO1 G IY0'],
  "doggies": ['D AO1 G IY0 Z'],
  "dogging": ['D AO1 G IH0 NG'],
  "doggone": ['D AO1 G AO0 N'],
  "doggy": ['D AO1 G IY0'],
  "doggy's": ['D AO1 G IY0 Z'],
  "doghouse": ['D AO1 G HH AW2 S'],
  "doghouses": ['D AO1 G HH AW2 S IH0 Z'],
  "dogle": ['D OW1 G AH0 L', 'D AA1 G AH0 L'],
  "doglike": ['D AO1 G L AY2 K'],
  "dogma": ['D AA1 G M AH0'],
  "dogmatic": ['D AA0 G M AE1 T IH0 K', 'D AO0 G M AE1 T IH0 K'],
  "dogmatically": ['D AA0 G M AE1 T IH0 K L IY0'],
  "dogmatism": ['D AA1 G M AH0 T IH2 Z AH0 M'],
  "dogs": ['D AA1 G Z', 'D AO1 G Z'],
  "dogs'": ['D AO1 G Z'],
  "dogwood": ['D AO1 G W UH2 D'],
  "dogwoods": ['D AO1 G W UH2 D Z'],
  "doh": ['D OW1'],
  "doha": ['D OW1 HH AA2'],
  "doheny": ['D AA1 HH IH0 N IY0'],
  "doherty": ['D OW1 ER0 T IY0', 'D AO1 R T IY0', 'D AA1 HH ER0 T IY0'],
  "dohm": ['D AA1 M'],
  "dohman": ['D OW1 M AH0 N'],
  "dohme": ['D OW1 M'],
  "dohmen": ['D OW1 M EH0 N'],
  "dohn": ['D AA1 N'],
  "dohnanyi": ['D OW2 N AA1 N Y IY0'],
  "dohner": ['D OW1 N ER0'],
  "dohr": ['D AO1 R'],
  "dohrman": ['D AO1 R M AH0 N'],
  "dohrmann": ['D AO1 R M AH0 N'],
  "dohse": ['D OW1 S'],
  "doi": ['D OY1'],
  "doidge": ['D OY1 JH'],
  "doig": ['D OY1 G'],
  "doilies": ['D OY1 L IY0 Z'],
  "doily": ['D OY1 L IY0'],
  "doin'": ['D UW1 IH0 N'],
  "doing": ['D UW1 IH0 NG'],
  "doings": ['D UW1 IH0 NG Z'],
  "doiron": ['D OY0 R AO1 N', 'D OY1 R AH0 N'],
  "doke": ['D OW1 K'],
  "dokely": ['D OW1 K L IY0'],
  "dokey": ['D OW1 K IY0'],
  "dokken": ['D AA1 K AH0 N'],
  "doktor": ['D AA1 K T ER0'],
  "dol": ['D AA1 L'],
  "dolak": ['D OW1 L AH0 K'],
  "dolan": ['D OW1 L AH0 N'],
  "doland": ['D UW1 L AH0 N D'],
  "dolata": ['D OW0 L AA1 T AH0'],
  "dolbow": ['D OW1 L B OW0'],
  "dolby": ['D OW1 L B IY0'],
  "dolce": ['D OW1 L CH EY2'],
  "dolch": ['D OW1 L CH'],
  "dold": ['D OW1 L D'],
  "dolder": ['D OW1 L D ER0'],
  "doldrum": ['D OW1 L D R AH0 M'],
  "doldrums": ['D OW1 L D R AH0 M Z'],
  "dole": ['D OW1 L'],
  "dole's": ['D OW1 L Z'],
  "dolecki": ['D AH0 L EH1 T S K IY0'],
  "doled": ['D OW1 L D'],
  "doleful": ['D OW1 L F AH0 L'],
  "dolen": ['D OW1 L AH0 N'],
  "dolence": ['D OW1 L AH0 N S'],
  "doles": ['D OW1 L Z'],
  "doles's": ['D OW1 L Z IH0 Z'],
  "doley": ['D OW1 L IY0'],
  "dolezal": ['D OW0 L EY0 Z AE1 L'],
  "dolf": ['D OW1 L F'],
  "dolfi": ['D OW1 L F IY0'],
  "dolgen": ['D OW1 L JH EH0 N'],
  "dolgin": ['D OW1 L JH IH0 N'],
  "dolin": ['D OW1 L IH0 N'],
  "dolinar": ['D AA1 L IH0 N ER0'],
  "doling": ['D OW1 L IH0 NG'],
  "dolinger": ['D OW1 L IH0 NG ER0'],
  "dolinski": ['D AH0 L IH1 N S K IY0'],
  "dolinsky": ['D AH0 L IH1 N S K IY0'],
  "doll": ['D AA1 L'],
  "doll's": ['D AA1 L Z'],
  "dollan": ['D OW1 L AH0 N'],
  "dollar": ['D AA1 L ER0', 'D AO1 L ER0'],
  "dollar's": ['D AA1 L ER0 Z', 'D AA1 L AH0 Z', 'D AO1 L ER0 Z'],
  "dollard": ['D AA1 L ER0 D'],
  "dollarhide": ['D AA1 L ER0 HH AY2 D'],
  "dollars": ['D AA1 L ER0 Z', 'D AO1 L ER0 Z'],
  "dollars'": ['D AA1 L ER0 Z'],
  "dolle": ['D AA1 L'],
  "dolled": ['D AA1 L D'],
  "dollens": ['D AA1 L AH0 N Z'],
  "dolley": ['D AA1 L IY0'],
  "dollhouse": ['D AA1 L HH AW2 S'],
  "dollhouses": ['D AA1 L HH AW2 S IH0 Z'],
  "dollie": ['D AA1 L IY0'],
  "dollinger": ['D AA1 L IH0 NG ER0'],
  "dollins": ['D AA1 L IH0 N Z'],
  "dollison": ['D AA1 L IH0 S AH0 N'],
  "dolliver": ['D AA1 L IH0 V ER0'],
  "dolloff": ['D AA1 L AO2 F'],
  "dollop": ['D AA1 L AH0 P'],
  "dolls": ['D AA1 L Z'],
  "dolly": ['D AA1 L IY0'],
  "dolly's": ['D AA1 L IY0 Z'],
  "dolman": ['D AA1 L M AH0 N'],
  "dolney": ['D OW1 L N IY0'],
  "dolomite": ['D OW1 L AH0 M AY2 T'],
  "dolomite's": ['D OW1 L AH0 M AY2 T S'],
  "dolomites": ['D OW1 L AH0 M AY2 T S'],
  "dolores": ['D AH0 L AO1 R IH0 S'],
  "dolorita": ['D OW0 L AO0 R IY1 T AH0'],
  "dolorosa": ['D OW0 L ER0 OW1 S AH0'],
  "dolph": ['D OW1 L F'],
  "dolphin": ['D AA1 L F AH0 N'],
  "dolphins": ['D AA1 L F AH0 N Z'],
  "dolphins'": ['D AA1 L F AH0 N Z'],
  "dols": ['D AA1 L Z'],
  "dolson": ['D OW1 L S AH0 N'],
  "dolton": ['D OW1 L T AH0 N'],
  "dom": ['D AA1 M'],
  "domagala": ['D OW0 M AA0 G AA1 L AH0'],
  "domagalski": ['D AH0 M AH0 G AA1 L S K IY0'],
  "domain": ['D OW0 M EY1 N'],
  "domaine": ['D OW0 M EY1 N'],
  "domains": ['D OW0 M EY1 N Z'],
  "doman": ['D UW1 M AH0 N'],
  "domangue": ['D OW1 M AA0 NG'],
  "domanico": ['D OW0 M AA0 N IY1 K OW0'],
  "domanski": ['D AH0 M AE1 N S K IY0'],
  "domas": ['D OW1 M AH0 S'],
  "dombeck": ['D AA1 M B EH2 K'],
  "dombek": ['D AA1 M B IH0 K'],
  "dombkowski": ['D AH0 M K AO1 F S K IY0'],
  "dombroski": ['D AH0 M B R AW1 S K IY0'],
  "dombrosky": ['D AH0 M B R OW1 S K IY0'],
  "dombrowski": ['D AH0 M B R AO1 F S K IY0'],
  "dome": ['D OW1 M'],
  "dome's": ['D OW1 M Z'],
  "domecq": ['D OW2 M EH1 K'],
  "domed": ['D OW1 M D'],
  "domeier": ['D AA1 M AY0 ER0'],
  "domek": ['D OW1 M EH0 K'],
  "domenech": ['D AA1 M IH0 N IH0 K'],
  "domenici": ['D AH0 M EH1 N AH0 CH IY0', 'D OW0 M IH1 N IY0 CH IY0', 'D OW0 M EH1 N IY0 CH IY0'],
  "domenici's": ['D AH0 M EH1 N AH0 CH IY0 Z', 'D OW0 M IH1 N IY0 CH IY0 Z', 'D OW0 M EH1 N IY0 CH IY0 Z'],
  "domenick": ['D AA1 M IH0 N IH0 K'],
  "domenico": ['D OW0 M EY1 N IY0 K OW0'],
  "domeniconi": ['D OW0 M EH2 N IH0 K OW1 N IY0'],
  "domer": ['D OW1 M ER0'],
  "domes": ['D OW1 M Z'],
  "domestic": ['D AH0 M EH1 S T IH0 K'],
  "domestically": ['D AH0 M EH1 S T IH0 K L IY0'],
  "domesticate": ['D AH0 M EH1 S T AH0 K EY2 T'],
  "domesticated": ['D AH0 M EH1 S T AH0 K EY2 T AH0 D'],
  "domesticates": ['D AH0 M EH1 S T AH0 K EY2 T S'],
  "domesticating": ['D AH0 M EH1 S T AH0 K EY2 T IH0 NG'],
  "domestication": ['D AH0 M EH2 S T AH0 K EY1 SH AH0 N'],
  "domesticity": ['D OW2 M EH2 S T IH1 S AH0 T IY0'],
  "domestics": ['D AH0 M EH1 S T IH0 K S'],
  "domical": ['D AA1 M AH0 K AH0 L'],
  "domico": ['D OW1 M AH0 K OW0'],
  "domin": ['D OW1 M IH0 N'],
  "domina": ['D OW0 M IY1 N AH0'],
  "dominance": ['D AA1 M AH0 N AH0 N S'],
  "dominant": ['D AA1 M AH0 N AH0 N T'],
  "dominate": ['D AA1 M AH0 N EY2 T'],
  "dominated": ['D AA1 M AH0 N EY2 T AH0 D'],
  "dominates": ['D AA1 M AH0 N EY2 T S'],
  "dominating": ['D AA1 M AH0 N EY2 T IH0 NG'],
  "domination": ['D AA2 M AH0 N EY1 SH AH0 N'],
  "domine": ['D OW0 M IY1 N IY0'],
  "dominee": ['D OW1 M IH0 N EY2'],
  "domineer": ['D AA2 M AH0 N IH1 R'],
  "domineering": ['D AA2 M AH0 N IH1 R IH0 NG'],
  "dominelli": ['D OW2 M IH0 N EH1 L IY0', 'D AA2 M IH0 N EH1 L IY0'],
  "dominelli's": ['D OW2 M IH0 N EH1 L IY0 Z', 'D AA2 M IH0 N EH1 L IY0 Z'],
  "dominey": ['D AA1 M IH0 N IY0'],
  "domingo": ['D OW0 M IH1 NG G OW0', 'D AH0 M IH1 NG G OW0'],
  "domingos": ['D AH0 M IH1 NG G OW0 Z'],
  "domingue": ['D OW1 M IH0 NG'],
  "domingues": ['D OW0 M IY1 N G EH0 S'],
  "dominguez": ['D AH0 M IH1 NG IH0 Z'],
  "domini": ['D AA1 M IH0 N IY0'],
  "dominiak": ['D AH0 M IH1 N IY0 AE0 K'],
  "dominic": ['D AA1 M AH0 N IH0 K'],
  "dominic's": ['D AA1 M AH0 N IH0 K S'],
  "dominica": ['D AH0 M IH1 N IH0 K AH0'],
  "dominican": ['D AH0 M IH1 N AH0 K AH0 N'],
  "dominicana": ['D OW0 M IH2 N IH0 K AA1 N AH0', 'D OW0 M IH2 N IH0 K AE1 N AH0'],
  "dominicans": ['D OW0 M IH1 N IH0 K AH0 N Z'],
  "dominici": ['D OW0 M IY0 N IY1 CH IY0'],
  "dominick": ['D AA1 M AH0 N IH0 K'],
  "dominik": ['D AH0 M IH1 N IH0 K'],
  "dominion": ['D AH0 M IH1 N Y AH0 N'],
  "dominion's": ['D AH0 M IH1 N Y AH0 N Z'],
  "dominions": ['D AH0 M IH1 N Y AH0 N Z'],
  "dominique": ['D AO0 M IH0 N IY1 K'],
  "domino": ['D AA1 M AH0 N OW2', 'D AA1 M IH0 N OW2'],
  "domino's": ['D AA1 M IH0 N OW2 Z'],
  "dominoes": ['D AA1 M AH0 N OW2 Z'],
  "dominos": ['D AA1 M IH0 N OW2 Z'],
  "dominquez": ['D OW0 M IY1 N K W EH0 Z'],
  "dominski": ['D AH0 M IH1 N S K IY0'],
  "dominus": ['D OW0 M IY1 N AH0 S'],
  "dominy": ['D AH0 M AY1 N IY0'],
  "domke": ['D AA1 M K'],
  "dommer": ['D AA1 M ER0'],
  "dommie": ['D AA1 M IY0'],
  "domoling": ['D AA1 M AO0 L IH0 NG'],
  "domtar": ['D AA1 M T ER0'],
  "domtar's": ['D AA1 M T ER0 Z'],
  "domzalski": ['D AH0 M Z AA1 L S K IY0'],
  "don": ['D AA1 N'],
  "don's": ['D AA1 N Z'],
  "don't": ['D OW1 N T', 'D OW1 N'],
  "don'ts": ['D OW1 N T S', 'D OW1 N S'],
  "dona": ['D OW1 N AH0'],
  "donadio": ['D OW0 N AA1 D IY0 OW0'],
  "donaghey": ['D AA1 N AH0 G IY0'],
  "donaghue": ['D AA1 N AH0 HH UW0'],
  "donaghy": ['D AA1 N AH0 G IY0'],
  "donahey": ['D AA1 N AH0 HH IY0'],
  "donaho": ['D OW0 N AA1 HH OW0'],
  "donahoe": ['D AA1 N AH0 HH OW2'],
  "donahoo": ['D AA1 N AH0 HH UW2'],
  "donahue": ['D AA1 N AH0 HH Y UW2', 'D AA1 N AH0 Y UW2'],
  "donais": ['D AH0 N EY1'],
  "donald": ['D AA1 N AH0 L D'],
  "donald's": ['D AA1 N AH0 L D Z'],
  "donalda": ['D OW0 N AA1 L D AH0'],
  "donaldo": ['D OW0 N AA1 L D OW0'],
  "donaldo's": ['D OW0 N AA1 L D OW0 Z'],
  "donaldson": ['D AA1 N AH0 L D S AH0 N'],
  "donaldson's": ['D AA1 N AH0 L D S AH0 N Z'],
  "donaldsons": ['D AA1 N AH0 L D S AH0 N Z'],
  "donalson": ['D AA1 N AH0 L S AH0 N'],
  "donapria": ['D AH0 N AE1 P R IY0 AH0'],
  "donar": ['D AA1 N ER0'],
  "donat": ['D OW1 N AH0 T'],
  "donata": ['D AH0 N AA1 T AH0'],
  "donate": ['D OW1 N EY2 T'],
  "donated": ['D OW1 N EY2 T IH0 D'],
  "donatelli": ['D OW0 N AA0 T EH1 L IY0'],
  "donates": ['D OW1 N EY2 T S'],
  "donath": ['D AA1 N AH0 TH'],
  "donathan": ['D AA1 N AH0 TH AH0 N'],
  "donati": ['D OW0 N AA1 T IY0'],
  "donating": ['D OW1 N EY2 T IH0 NG'],
  "donation": ['D OW0 N EY1 SH AH0 N'],
  "donations": ['D OW0 N EY1 SH AH0 N Z'],
  "donatists": ['D AA1 N AH0 T IH0 S T S'],
  "donato": ['D AH0 N AA1 T OW0'],
  "donavan": ['D AA1 N AH0 V AE2 N'],
  "donaway": ['D AA1 N AH0 W EY2'],
  "donde": ['D AA1 N D'],
  "donder": ['D AA1 N D ER0'],
  "dondero": ['D OW0 N D EH1 R OW0'],
  "dondlinger": ['D AA1 N D AH0 L IH0 NG ER0', 'D AA1 N D L IH0 NG ER0'],
  "done": ['D AH1 N'],
  "donegan": ['D AA1 N IH0 G AE0 N'],
  "donehoo": ['D OW0 N EY1 HH UW0'],
  "donelan": ['D AA1 N IH0 L AE0 N'],
  "donella": ['D OW0 N EH1 L AH0'],
  "donelson": ['D AA1 N IH0 L S AH0 N'],
  "doner": ['D AO1 N ER0'],
  "dones": ['D AH1 N Z'],
  "doney": ['D AA1 N IY0'],
  "dong": ['D AO1 NG', 'D AO1 NG G'],
  "dongen": ['D AO1 NG G AH0 N'],
  "dongmei": ['D OW1 NG M AY1'],
  "donham": ['D AA1 N HH AH0 M'],
  "donia": ['D OW1 N IY0 AH0'],
  "donica": ['D AA1 N IH0 K AH0'],
  "donigan": ['D AA1 N IH0 G AH0 N'],
  "doniger": ['D AA1 N IH0 G ER0'],
  "donilon": ['D AA1 N AH0 L AA0 N'],
  "donis": ['D OW1 N IH0 S'],
  "donizetti": ['D AA2 N AH0 Z EH1 T IY0'],
  "donizetti's": ['D AA2 N IH0 Z EH1 T IY0 Z'],
  "donkey": ['D AA1 NG K IY0', 'D AO1 NG K IY0'],
  "donkeys": ['D AA1 NG K IY0 Z'],
  "donlan": ['D AA1 N L AH0 N'],
  "donley": ['D AA1 N L IY0'],
  "donlin": ['D AA1 N L IH0 N'],
  "donlon": ['D AA1 N L AH0 N'],
  "donmoyer": ['D AA1 N M OY2 ER0'],
  "donn": ['D AA1 N'],
  "donna": ['D AA1 N AH0'],
  "donna's": ['D AA1 N AH0 Z'],
  "donnan": ['D AA1 N AH0 N'],
  "donnas": ['D AA1 N AH0 Z'],
  "donnay": ['D AA1 N EY0'],
  "donne": ['D AH1 N'],
  "donned": ['D AA1 N D'],
  "donnell": ['D AA1 N IH0 L'],
  "donnellan": ['D AA1 N IH0 L AE0 N'],
  "donnelley": ['D AA1 N AH0 L IY0', 'D AA1 N EH0 L IY0'],
  "donnelley's": ['D AA1 N AH0 L IY0 Z', 'D AA1 N EH0 L IY0 Z'],
  "donnelly": ['D AA1 N AH0 L IY0', 'D AA1 N EH0 L IY0'],
  "donnelly's": ['D AA1 N AH0 L IY0 Z', 'D AA1 N EH0 L IY0 Z'],
  "donner": ['D AA1 N ER0'],
  "donnie": ['D AA1 N IY0'],
  "donning": ['D AA1 N IH0 NG'],
  "donny": ['D AA1 N IY0'],
  "donnybrook": ['D AA1 N IY0 B R UH2 K'],
  "donofrio": ['D OW0 N OW1 F R IY0 OW0'],
  "donoghue": ['D AA1 N AH0 HH Y UW0', 'D AA1 N AH0 Y UW0'],
  "donoghue's": ['D AA1 N AH0 HH Y UW0 Z', 'D AA1 N AH0 Y UW0 Z'],
  "donoho": ['D AA1 N AH0 HH OW0'],
  "donohoe": ['D AA1 N AH0 HH OW0'],
  "donohoo": ['D AA1 N AH0 HH UW2'],
  "donohue": ['D AA1 N AH0 HH Y UW2', 'D AA1 N AH0 Y UW0'],
  "donohue's": ['D AA1 N AH0 HH Y UW2 Z', 'D AA1 N AH0 Y UW2 Z'],
  "donor": ['D OW1 N ER0'],
  "donor's": ['D OW1 N ER0 Z'],
  "donors": ['D OW1 N ER0 Z'],
  "donors'": ['D OW1 N ER0 Z'],
  "donovan": ['D AA1 N AH0 V AH0 N'],
  "donovan's": ['D AA1 N AH0 V AH0 N Z'],
  "dons": ['D AA1 N Z'],
  "donsbach": ['D AA1 N Z B AA2 K'],
  "donut": ['D OW1 N AH2 T'],
  "donuts": ['D OW1 N AH2 T S'],
  "donuts'": ['D OW1 N AH2 T S'],
  "donvan": ['D AA1 N V AH0 N'],
  "donvan's": ['D AA1 N V AH0 N Z'],
  "donze": ['D AA1 N Z'],
  "doo": ['D UW1'],
  "doodad": ['D UW1 D AE2 D'],
  "doodads": ['D UW1 D AE2 D Z'],
  "doodle": ['D UW1 D AH0 L'],
  "doodles": ['D UW1 D AH0 L Z'],
  "doody": ['D UW1 D IY0'],
  "doogie": ['D UW1 G IY0'],
  "doolan": ['D UW1 L AH0 N'],
  "doolen": ['JH UW1 L AH0 N'],
  "dooley": ['D UW1 L IY0'],
  "doolin": ['D UW1 L IH0 N'],
  "dooling": ['D UW1 L IH0 NG'],
  "doolittle": ['D UW1 L IH2 T AH0 L'],
  "doom": ['D UW1 M'],
  "doomed": ['D UW1 M D'],
  "dooming": ['D UW1 M IH0 NG'],
  "dooms": ['D UW1 M Z'],
  "doomsayer": ['D UW2 M S EY1 ER0'],
  "doomsayers": ['D UW2 M S EY1 ER0 Z'],
  "doomsaying": ['D UW2 M S EY1 IH0 NG'],
  "doomsday": ['D UW1 M Z D EY2'],
  "doonan": ['D UW1 N AH0 N'],
  "dooner": ['D UW1 N ER0'],
  "doonesbury": ['D UW1 N Z B EH2 R IY0'],
  "door": ['D AO1 R'],
  "door's": ['D AO1 R Z'],
  "doorbell": ['D AO1 R B EH2 L'],
  "doordarshan": ['D UW2 R D AA1 R SH AH0 N'],
  "doorenbos": ['D UH1 R EH0 N B OW1 S'],
  "doorkeeper": ['D AO1 R K IY2 P ER0'],
  "doorknob": ['D UW1 R N AA0 B'],
  "doorknobs": ['D UW1 R N AA0 B Z'],
  "doorman": ['D AO1 R M AE2 N'],
  "doormat": ['D AO1 R M AE2 T'],
  "doormats": ['D AO1 R M AE2 T S'],
  "doorn": ['D AO1 R N'],
  "doornail": ['D AO1 R N EY2 L'],
  "doornbos": ['D AO1 R N B OW0 Z'],
  "doors": ['D AO1 R Z'],
  "doorsill": ['D AO1 R S IH0 L'],
  "doorstep": ['D AO1 R S T EH2 P'],
  "doorsteps": ['D AO1 R S T EH2 P S'],
  "doorway": ['D AO1 R W EY2'],
  "doorways": ['D AO1 R W EY2 Z'],
  "doose": ['D UW1 S'],
  "doozy": ['D UW1 Z IY0'],
  "dopa": ['D OW1 P AH0'],
  "dopamine": ['D AA1 P AH0 M AY2 N'],
  "dope": ['D OW1 P'],
  "doped": ['D OW1 P T'],
  "dopey": ['D OW1 P IY0'],
  "dopp": ['D AA1 P'],
  "doppler": ['D AA1 P L ER0'],
  "dopplerganger": ['D AO1 P AH0 L G AE2 N G ER0'],
  "dopson": ['D AA1 P S AH0 N'],
  "dora": ['D AO1 R AH0'],
  "dorado": ['D AO0 R AA1 D OW0'],
  "dorais": ['D ER0 EY1'],
  "doral": ['D AO0 R AE1 L'],
  "doralin": ['D AO0 R AA0 L IY1 N'],
  "doralynne": ['D AO1 R AH0 L AY0 N'],
  "doran": ['D AO0 R AE1 N'],
  "doraville": ['D AO1 R AH0 V IH0 L'],
  "dorazio": ['D AO0 R AA1 Z IY0 OW0', 'D AO0 R EY1 Z IY0 OW0'],
  "dorcas": ['D AO1 R K AH0 S'],
  "dorch": ['D AO1 R K'],
  "dorchester": ['D AO1 R CH EH2 S T ER0'],
  "dordies": ['D AO1 R D IY0 Z'],
  "dore": ['D AO1 R'],
  "dorea": ['D AO1 R IY0 AH0'],
  "doreen": ['D AO0 R IY1 N'],
  "dorelia": ['D AO0 R EH1 L IY0 AH0'],
  "doremus": ['D AO1 R IH0 M IH0 S'],
  "doren": ['D AO1 R AH0 N'],
  "dorena": ['D AO1 R IH0 N AH0'],
  "dorene": ['D AO1 R IY0 N'],
  "dorer": ['D AO1 R ER0'],
  "dorette": ['D ER0 EH1 T'],
  "doretti": ['D AO0 R EH1 T IY0'],
  "dorey": ['D AO1 R IY0'],
  "dorf": ['D AO1 R F'],
  "dorff": ['D AO1 R F'],
  "dorfman": ['D AO1 R F M AH0 N'],
  "dorgan": ['D AO1 R G AH0 N'],
  "dorgan's": ['D AO1 R G AH0 N Z'],
  "dori": ['D AO1 R IY0'],
  "doria": ['D AO1 R IY0 AH0'],
  "dorian": ['D AO1 R IY0 AH0 N'],
  "dorians": ['D AO1 R IY0 AH0 N Z'],
  "doric": ['D AO1 R IH0 K'],
  "dorice": ['D AO1 R IH0 S'],
  "dorie": ['D AO1 R IY0'],
  "dorin": ['D AO1 R IH0 N'],
  "dorinda": ['D AO0 R IY1 N D AH0'],
  "dorine": ['D AO0 R IY1 N IY0'],
  "doring": ['D AO1 R IH0 NG'],
  "dorio": ['D AO1 R IY0 OW0'],
  "dorion": ['D AO0 R IY0 AO1 N'],
  "doris": ['D AO1 R AH0 S', 'D AO1 R IH0 S', 'D AA1 R AH0 S', 'D AA1 R IH0 S'],
  "dorise": ['D AO1 R AY0 Z'],
  "doritos": ['D AO2 R IY1 T OW0 Z'],
  "dority": ['D AO1 R IH0 T IY0'],
  "dorko": ['D AO1 R K OW0'],
  "dorland": ['D AO1 R L AH0 N D'],
  "dorm": ['D AO1 R M'],
  "dorman": ['D AO1 R M AH0 N'],
  "dormancy": ['D AO1 R M AH0 N S IY0'],
  "dormant": ['D AO1 R M AH0 N T'],
  "dormer": ['D AO1 R M ER0'],
  "dorminey": ['D AO1 R M IH0 N IY0'],
  "dormitories": ['D AO1 R M AH0 T AO2 R IY0 Z'],
  "dormitory": ['D AO1 R M AH0 T AO2 R IY0'],
  "dormouse": ['D AO1 R M AW2 S'],
  "dorms": ['D AO1 R M Z'],
  "dorn": ['D AO1 R N'],
  "dornak": ['D AO1 R N AH0 K'],
  "dornam": ['D AO1 R N AH0 M'],
  "dornan": ['D AO1 R N IH0 N'],
  "dornan's": ['D AO1 R N IH0 N Z'],
  "dornbusch": ['D AO1 R N B UH0 SH'],
  "dornbush": ['D AO1 R N B UH0 SH'],
  "dorner": ['D AO1 R N ER0'],
  "dorney": ['D AO1 R N IY0'],
  "dornfeld": ['D AO1 R N F EH0 L D'],
  "dornhens": ['D AO1 R N HH EH0 N Z'],
  "dornier": ['D AO2 R N IH1 R'],
  "dornin": ['D AO1 N IH0 N'],
  "doro": ['D AO1 R OW0'],
  "doron": ['D AO1 R AH0 N'],
  "dorotea": ['D AO1 R AH0 SH AH0', 'D AO1 R AH0 T EY0 AH0'],
  "dorothea": ['D AO2 R AH0 TH IY1 AH0'],
  "dorothy": ['D AO1 R AH0 TH IY0', 'D AO1 R TH IY0'],
  "dorothy's": ['D AO1 R AH0 TH IY0 Z', 'D AO1 R TH IY0 Z'],
  "dorough": ['D AO1 R AW0'],
  "dorow": ['D AO1 R OW0'],
  "dorr": ['D AO1 R'],
  "dorrance": ['D AO1 R AH0 N S'],
  "dorrell": ['D AO0 R EY1 L'],
  "dorrie": ['D AO1 R IY0'],
  "dorries": ['D AO1 R IY0 Z'],
  "dorris": ['D AO1 R IH0 S'],
  "dorroh": ['D AO1 R OW0'],
  "dorrough": ['D AO1 R AW0'],
  "dorsa": ['D AO1 R S AH0'],
  "dorsal": ['D AO1 R S AH0 L'],
  "dorsally": ['D AO1 R S AH0 L IY0'],
  "dorsch": ['D AO1 R SH'],
  "dorset": ['D AO1 R S IH0 T'],
  "dorsett": ['D AO1 R S IH0 T'],
  "dorsey": ['D AO1 R S IY0'],
  "dorsi": ['D AO1 R S IY0'],
  "dorst": ['D AO1 R S T'],
  "dort": ['D AO1 R T'],
  "dorta": ['D AO1 R T AH0'],
  "dortch": ['D AO1 R CH'],
  "dorthea": ['D AO1 R DH IY0 AH0'],
  "dorthy": ['D AO1 R TH IY0'],
  "dortmund": ['D AO1 R T M AH0 N D'],
  "dorton": ['D AO1 R T AH0 N'],
  "dorval": ['D AO1 R V AH0 L'],
  "dorward": ['D AO1 R W ER0 D'],
  "dorwart": ['D AO1 R W AO0 R T'],
  "dory": ['D AO1 R IY0'],
  "dos": ['D AO1 S', 'D OW1 S'],
  "dosage": ['D OW1 S AH0 JH', 'D OW1 S IH0 JH'],
  "dosages": ['D OW1 S IH0 JH IH0 Z'],
  "dosch": ['D AO1 SH'],
  "doscher": ['D AO1 SH ER0'],
  "dose": ['D OW1 S'],
  "doser": ['D OW1 S ER0'],
  "doses": ['D OW1 S AH0 Z', 'D OW1 S IH0 Z'],
  "dosh": ['D AA1 SH'],
  "dosher": ['D AA1 SH ER0'],
  "doshi": ['D OW1 SH IY0'],
  "doshier": ['D AA1 SH IY0 ER0'],
  "dosia": ['D OW1 S IY0 AH0'],
  "dosie": ['D AA1 S IY0'],
  "dosier": ['D OW1 S IY0 ER0'],
  "dosimeters": ['D OW0 S IH1 M AH0 T ER0 Z'],
  "dosing": ['D OW1 S IH0 NG'],
  "doskocil": ['D AO1 S K AH0 S IH0 L'],
  "dospasos": ['D OW0 S P AE1 S OW0 S'],
  "dospasos'": ['D OW0 S P AE1 S OW0 S'],
  "doss": ['D AO1 S'],
  "dossantos": ['D OW0 S AA1 N T OW0 Z'],
  "dossett": ['D AA1 S IH0 T'],
  "dossey": ['D AA1 S IY0'],
  "dossier": ['D AO2 S Y EY1', 'D AO2 S IY0 EY1'],
  "dossiers": ['D AO2 S Y EY1 Z', 'D AO2 S IY0 EY1 Z'],
  "dost": ['D AA1 S T'],
  "dostal": ['D AA1 S T AH0 L'],
  "doster": ['D AA1 S T ER0'],
  "dostie": ['D AA1 S T IY0'],
  "dostoevski": ['D AO2 S T OW1 V S K IY0'],
  "dostoevsky": ['D AO2 S T OW1 V S K IY0', 'D AO0 S T OY0 EH1 V S K IY0', 'D AO0 S T OY0 EH1 F S K IY0'],
  "doswell": ['D AA1 S W EH0 L'],
  "dot": ['D AA1 T'],
  "dotan": ['D OW1 T AH0 N'],
  "dote": ['D OW1 T'],
  "doten": ['D OW1 T AH0 N'],
  "doth": ['D AO1 TH'],
  "dothan": ['D AA1 TH AH0 N'],
  "doting": ['D OW1 T IH0 NG'],
  "doto": ['D OW1 T OW0'],
  "dots": ['D AA1 T S'],
  "dotson": ['D AA1 T S AH0 N'],
  "dott": ['D AA1 T'],
  "dottavio": ['D OW0 T AA1 V IY0 OW0'],
  "dotted": ['D AA1 T AH0 D', 'D AA1 T IH0 D'],
  "dotter": ['D AA1 T ER0'],
  "dotterer": ['D AA1 T ER0 ER0'],
  "dottie": ['D AA1 T IY0'],
  "dotting": ['D AA1 T IH0 NG'],
  "dotts": ['D AA1 T S'],
  "dotty": ['D AA1 T IY0'],
  "doty": ['D OW1 T IY0'],
  "dotzler": ['D AA1 T S L ER0'],
  "doub": ['D AW1 B'],
  "doubek": ['D AW1 B IH0 K'],
  "doubet": ['D AW1 B IH0 T'],
  "double": ['D AH1 B AH0 L'],
  "double-check": ['D AH1 B AH0 L CH EH2 K'],
  "double-entendre": ['D AH1 B AH0 L AA0 N T AA1 N D R AH0'],
  "doubled": ['D AH1 B AH0 L D'],
  "doubleday": ['D AH1 B AH0 L D EY2'],
  "doubledecker": ['D AH2 B AH0 L D EH1 K ER0'],
  "doubles": ['D AH1 B AH0 L Z'],
  "doublespeak": ['D AH1 B AH0 L S P IY2 K'],
  "doublethink": ['D AH1 B AH0 L TH IH2 NG K'],
  "doubletree": ['D AH1 B AH0 L T R IY2'],
  "doubling": ['D AH1 B AH0 L IH0 NG', 'D AH1 B L IH0 NG'],
  "doubly": ['D AH1 B L IY0'],
  "doubrava": ['D AW1 B R AH0 V AH0'],
  "doubt": ['D AW1 T'],
  "doubted": ['D AW1 T IH0 D'],
  "doubter": ['D AW1 T ER0'],
  "doubters": ['D AW1 T ER0 Z'],
  "doubtfire": ['D AW1 T F AY1 ER0'],
  "doubtfire's": ['D AW1 T F AY1 ER0 Z'],
  "doubtfires": ['D AW1 T F AY1 ER0 Z'],
  "doubtful": ['D AW1 T F AH0 L'],
  "doubting": ['D AW1 T IH0 NG'],
  "doubtless": ['D AW1 T L AH0 S'],
  "doubts": ['D AW1 T S'],
  "doucet": ['D UW0 S EH1 T'],
  "doucette": ['D UW1 S EH1 T'],
  "douche": ['D UW1 SH'],
  "doud": ['D AW1 D'],
  "doudna": ['D AW1 D N AH0'],
  "douds": ['D AW1 D Z'],
  "doug": ['D AH1 G'],
  "doug's": ['D AH1 G Z'],
  "dougal": ['D UW1 G AH0 L'],
  "dougall": ['D AW1 G AH0 L', 'D UW1 G AH0 L'],
  "dougan": ['D AW1 G AH0 N', 'D UW1 G AH0 N'],
  "dougans": ['D AW1 G AH0 N', 'D UW1 G AH0 N'],
  "dougens": ['D AW1 G AH0 N Z', 'D UW1 G AH0 N Z'],
  "douggins": ['D UW1 G AH0 N Z'],
  "dough": ['D OW1'],
  "doughboy": ['D OW1 B OY2'],
  "dougher": ['D OW1 ER0'],
  "dougherty": ['D OW1 ER0 T IY0', 'D AA1 G ER0 T IY0'],
  "doughman": ['D OW1 M AH0 N'],
  "doughnut": ['D OW1 N AH2 T'],
  "doughnuts": ['D OW1 N AH2 T S'],
  "doughten": ['D AO1 T AH0 N'],
  "doughtie": ['D AO1 T IY0'],
  "doughty": ['D AO1 T IY0'],
  "doughy": ['D OW1 IY0'],
  "dougie": ['D UW1 G IY0'],
  "dougie's": ['D UW1 G IY0 Z'],
  "dougl": ['D UW1 G AH0 L'],
  "douglas": ['D AH1 G L AH0 S'],
  "douglas'": ['D AH1 G L AH0 S'],
  "douglas's": ['D AH1 G L AH0 S IH0 Z'],
  "douglass": ['D AH1 G L AH0 S'],
  "doukas": ['D AW1 K AH0 Z'],
  "doulton": ['D OW1 L T AH0 N'],
  "douma": ['D OW1 M AH0'],
  "doupe": ['D UW1 P'],
  "dour": ['D AW1 ER0', 'D AW1 R'],
  "douse": ['D AW1 S'],
  "doused": ['D AW1 S T'],
  "dousing": ['D AW1 S IH0 NG'],
  "douthat": ['D AW1 TH AH0 T'],
  "douthett": ['D AW1 TH IH0 T'],
  "douthit": ['D UW0 TH IH1 T'],
  "douthitt": ['D UW0 TH IH1 T'],
  "doutt": ['D AW1 T'],
  "douty": ['D AW1 T IY0'],
  "douville": ['D UW0 V IH1 L'],
  "douwe": ['D UW1'],
  "dov": ['D AA1 V'],
  "dove": ['D AH1 V', 'D OW1 V'],
  "dovecote": ['D AH1 V K OW2 T'],
  "dovecotes": ['D AH1 V K OW2 T S'],
  "dovel": ['D OW0 V EH1 L'],
  "dover": ['D OW1 V ER0'],
  "dover's": ['D OW1 V ER0 Z'],
  "doverspike": ['D AH0 V ER1 S P IH0 K'],
  "doves": ['D AH1 V Z'],
  "dovetail": ['D AH1 V T EY2 L'],
  "dovetailed": ['D AH1 V T EY2 L D'],
  "dovetails": ['D AH1 V T EY2 L Z'],
  "dovey": ['D AH0 V EY1'],
  "dovidio": ['D OW0 V IY1 D IY0 OW0'],
  "dovish": ['D OW1 V IH0 SH', 'D AH1 V IH0 SH'],
  "dow": ['D AW1'],
  "dow's": ['D AW1 Z'],
  "dowager": ['D AW1 AH0 JH ER0'],
  "dowd": ['D AW1 D'],
  "dowda": ['D AW1 D AH0'],
  "dowdall": ['D AW1 D AH0 L'],
  "dowdell": ['D AW1 D AH0 L'],
  "dowden": ['D AW1 D AH0 N'],
  "dowding": ['D AW1 D IH0 NG'],
  "dowdle": ['D AW1 D AH0 L'],
  "dowds": ['D AW1 D Z'],
  "dowdy": ['D AW1 D IY0'],
  "dowe": ['D AW1'],
  "dowel": ['D AW1 AH0 L'],
  "dowell": ['D AA1 W EH0 L'],
  "dowels": ['D AW1 AH0 L Z'],
  "dowen": ['D AW1 AH0 N'],
  "dower": ['D AW1 R'],
  "dowers": ['D AW1 R Z'],
  "dowie": ['D AW1 IY0'],
  "dowis": ['D AW1 IH0 S'],
  "dowland": ['D AW1 L AH0 N D'],
  "dowlen": ['D AW1 L AH0 N'],
  "dowler": ['D AW1 L ER0'],
  "dowless": ['D AW1 L AH0 S'],
  "dowling": ['D AW1 L IH0 NG'],
  "down": ['D AW1 N'],
  "down's": ['D AW1 N Z'],
  "downard": ['D AW1 N ER0 D'],
  "downbeat": ['D AW0 N B IY1 T'],
  "downcast": ['D AW1 N K AE2 S T'],
  "downdraft": ['D AW1 N D R AE2 F T'],
  "downe": ['D AW1 N'],
  "downed": ['D AW1 N D'],
  "downen": ['D AW1 N AH0 N'],
  "downer": ['D AW1 N ER0'],
  "downers": ['D AW1 N ER0 Z'],
  "downes": ['D AW1 N Z'],
  "downes's": ['D AW1 N Z IH0 Z'],
  "downey": ['D AW1 N IY0'],
  "downey's": ['D AW1 N IY0 Z'],
  "downfall": ['D AW1 N F AO2 L'],
  "downgrade": ['D AW1 N G R EY1 D'],
  "downgraded": ['D AW1 N G R EY1 D AH0 D'],
  "downgrades": ['D AW1 N G R EY1 D Z'],
  "downgrading": ['D AW1 N G R EY1 D IH0 NG'],
  "downgradings": ['D AW1 N G R EY2 D IH0 NG Z'],
  "downham": ['D AW1 N HH AH0 M'],
  "downhill": ['D AW1 N HH IH1 L'],
  "downie": ['D AW1 N IY0'],
  "downing": ['D AW1 N IH0 NG'],
  "downingtown": ['D AW1 N IH0 NG T AW2 N'],
  "download": ['D AW1 N L OW2 D'],
  "downloaded": ['D AW1 N L OW2 D IH0 D'],
  "downloading": ['D AW1 N L OW2 D IH0 NG'],
  "downloads": ['D AW1 N L OW2 D Z'],
  "downpayment": ['D AW2 N P EY1 M AH0 N T'],
  "downpayments": ['D AW2 N P EY1 M AH0 N T S'],
  "downplay": ['D AW1 N P L EY2'],
  "downplayed": ['D AW1 N P L EY2 D'],
  "downplaying": ['D AW1 N P L EY2 IH0 NG'],
  "downplays": ['D AW1 N P L EY2 Z'],
  "downpour": ['D AW1 N P AO0 R'],
  "downpours": ['D AW1 N P AO2 R Z'],
  "downrange": ['D AW1 N R EY1 N JH'],
  "downright": ['D AW1 N R AY2 T'],
  "downriver": ['D AW2 N R IH1 V ER0'],
  "downs": ['D AW1 N Z'],
  "downscale": ['D AW1 N S K EY2 L'],
  "downside": ['D AW1 N S AY2 D'],
  "downsides": ['D AW1 N S AY2 D Z'],
  "downsize": ['D AW1 N S AY2 Z'],
  "downsized": ['D AW1 N S AY2 Z D'],
  "downsizes": ['D AW1 N S AY2 Z IH0 Z'],
  "downsizing": ['D AW1 N S AY2 Z IH0 NG'],
  "downsizings": ['D AW1 N S AY2 Z IH0 NG Z'],
  "downstage": ['D AW1 N S T EY2 JH'],
  "downstairs": ['D AW1 N S T EH1 R Z'],
  "downstate": ['D AW1 N S T EY2 T'],
  "downstream": ['D AW1 N S T R IY1 M'],
  "downswing": ['D AW1 N S W IH2 NG'],
  "downtime": ['D AW1 N T AY2 M'],
  "downtown": ['D AW1 N T AW1 N'],
  "downtown's": ['D AW1 N T AW1 N Z'],
  "downtowns": ['D AW1 N T AW1 N Z'],
  "downtrend": ['D AW1 N T R EH2 N D'],
  "downtrodden": ['D AW2 N T R AA1 D AH0 N'],
  "downturn": ['D AW1 N T ER2 N'],
  "downturns": ['D AW1 N T ER2 N Z'],
  "downum": ['D AW1 N AH0 M'],
  "downvote": ['D AW1 N V OW1 T'],
  "downvoted": ['D AW1 N V OW1 T IH0 D'],
  "downvotes": ['D AW1 N V OW1 T S'],
  "downvoting": ['D AW1 N V OW1 T IH0 NG'],
  "downward": ['D AW1 N W ER0 D'],
  "downwardly": ['D AW1 N W ER0 D L IY0'],
  "downwards": ['D AW1 N W ER0 D Z'],
  "downwind": ['D AW0 N W IH1 N D'],
  "downy": ['D AW1 N IY0'],
  "dowry": ['D AW1 R IY0'],
  "dowse": ['D AW1 S'],
  "dowsing": ['D AW1 S IH0 NG'],
  "dowsman": ['D AW1 Z M AH0 N'],
  "dowty": ['D AW1 T IY0'],
  "doxey": ['D AA1 K S IY0'],
  "doxie": ['D AA1 K S IY0'],
  "doxologies": ['D AA0 K S AA1 L AH0 JH IY0 Z'],
  "doxology": ['D AA0 K S AA1 L AH0 JH IY0'],
  "doxtater": ['D AA1 K S T EY0 T ER0'],
  "doxy": ['D AA1 K S IY0'],
  "doyal": ['D OY0 AA1 L'],
  "doyel": ['D OY1 AH0 L'],
  "doyen": ['D OY1 IH0 N'],
  "doyenne": ['D OY2 EH1 N'],
  "doyle": ['D OY1 L'],
  "doyle's": ['D OY1 L Z'],
  "doyon": ['D OY1 AH0 N'],
  "doze": ['D OW1 Z'],
  "dozed": ['D OW1 Z D'],
  "dozen": ['D AH1 Z AH0 N'],
  "dozens": ['D AH1 Z AH0 N Z'],
  "dozes": ['D OW1 Z IH0 Z'],
  "dozier": ['D OW1 Z IY0 ER0'],
  "dozing": ['D OW1 Z IH0 NG'],
  "dqalpha": ['D IY1 K Y UW1 AE1 L F AH0'],
  "dr": ['D R AY1 V', 'D AA1 K T ER0'],
  "dr.": ['D R AY1 V', 'D AA1 K T ER0'],
  "drab": ['D R AE1 B'],
  "drabbed": ['D R AE1 B D'],
  "drabble": ['D R AE1 B AH0 L'],
  "drabek": ['D R AE1 B IH0 K'],
  "drabenstott": ['D R AH0 B EH1 N S T AH0 T'],
  "drabik": ['D R AA1 B IH0 K'],
  "drabinsky": ['D R AH0 B IH1 N S K IY0'],
  "drabkin": ['D R AE1 B K IH2 N'],
  "drabs": ['D R AE1 B Z'],
  "drace": ['D R EY1 S'],
  "drach": ['D R AE1 CH'],
  "drachenberg": ['D R AE1 K AH0 N B ER0 G'],
  "drachma": ['D R AE1 K M AH0'],
  "drachmas": ['D R AA1 K M AH0 Z'],
  "drackett": ['D R AE1 K IH0 T'],
  "draco": ['D R EY1 K OW0', 'D R AE1 K OW0'],
  "draconian": ['D R EY0 K OW1 N IY0 AH0 N', 'D R AH0 K OW1 N IY0 AH0 N'],
  "dracula": ['D R AE1 K Y UW0 L AH0'],
  "draeger": ['D R EH1 G ER0'],
  "draffen": ['D R AE1 F AH0 N'],
  "draft": ['D R AE1 F T'],
  "draft's": ['D R AE1 F T S'],
  "drafted": ['D R AE1 F T IH0 D'],
  "draftee": ['D R AE1 F T IY1'],
  "draftees": ['D R AE1 F T IY1 Z'],
  "drafter": ['D R AE1 F T ER0'],
  "drafters": ['D R AE1 F T ER0 Z'],
  "drafting": ['D R AE1 F T IH0 NG'],
  "drafts": ['D R AE1 F T S'],
  "draftsman": ['D R AE1 F T S M AH0 N'],
  "draftsmanship": ['D R AE1 F T S M AH0 N SH IH2 P'],
  "draftsmen": ['D R AE1 F T S M AH0 N'],
  "drafty": ['D R AE1 F T IY0'],
  "drag": ['D R AE1 G'],
  "dragan": ['D R AA1 G AH0 N'],
  "drage": ['D R EY1 JH'],
  "drager": ['D R EY1 G ER0'],
  "dragged": ['D R AE1 G D'],
  "dragging": ['D R AE1 G IH0 NG'],
  "draggy": ['D R AE1 G IY0'],
  "dragline": ['D R AE1 G L AY2 N'],
  "dragnet": ['D R AE1 G N EH2 T'],
  "dragnets": ['D R AE1 G N EH2 T S'],
  "drago": ['D R AA1 G OW0'],
  "dragon": ['D R AE1 G AH0 N'],
  "dragon's": ['D R AE1 G AH0 N Z'],
  "dragonair": ['D R AE1 G AH0 N EH2 R'],
  "dragone": ['D R AH0 G OW1 N'],
  "dragonhead": ['D R AE1 G AH0 N HH EH2 D'],
  "dragonheart": ['D R AE1 G AH0 N HH AA2 R T'],
  "dragons": ['D R AE1 G AH0 N Z'],
  "dragoo": ['D R AA1 G UW0'],
  "dragovich": ['D R AE1 G AH0 V IH0 CH'],
  "drags": ['D R AE1 G Z'],
  "draheim": ['D R AE1 HH AY0 M'],
  "drahos": ['D R EY1 HH OW0 Z'],
  "drahuschak": ['D R AE1 HH AH0 SH AE0 K', 'D R AH0 HH UW1 SH AH0 K'],
  "drain": ['D R EY1 N'],
  "drainage": ['D R EY1 N AH0 JH', 'D R EY1 N IH0 JH'],
  "draine": ['D R EY1 N'],
  "drained": ['D R EY1 N D'],
  "drainer": ['D R EY1 N ER0'],
  "drainers": ['D R EY1 N ER0 Z'],
  "draining": ['D R EY1 N IH0 NG'],
  "drains": ['D R EY1 N Z'],
  "drake": ['D R EY1 K'],
  "drakeford": ['D R AE1 K F AO0 R D'],
  "drakes": ['D R EY1 K S'],
  "drakos": ['D R EY1 K OW0 Z'],
  "dralle": ['D R EY1 L'],
  "dram": ['D R AE1 M', 'D IY1 R AE2 M'],
  "drama": ['D R AA1 M AH0'],
  "dramamine": ['D R AE1 M AH0 M IY2 N'],
  "dramas": ['D R AA1 M AH0 Z'],
  "dramatic": ['D R AH0 M AE1 T IH0 K'],
  "dramatically": ['D R AH0 M AE1 T IH0 K L IY0', 'D R AH0 M AE1 T IH0 K AH0 L IY0'],
  "dramatics": ['D R AH0 M AE1 T IH0 K S'],
  "dramatist": ['D R AA1 M AH0 T IH0 S T'],
  "dramatization": ['D R AE2 M AH0 T AH0 Z EY1 SH AH0 N'],
  "dramatizations": ['D R AE2 M AH0 T AH0 Z EY1 SH AH0 N Z'],
  "dramatize": ['D R AA1 M AH0 T AY2 Z', 'D R AE1 M AH0 T AY2 Z'],
  "dramatized": ['D R AE1 M AH0 T AY2 Z D'],
  "dramatizes": ['D R AE1 M AH0 T AY2 Z IH0 Z'],
  "dramatizing": ['D R AE1 M AH0 T AY2 Z IH0 NG'],
  "dramaturgy": ['D R AA1 M AH0 T ER0 JH IY0'],
  "drams": ['D R AE1 M Z', 'D IY1 R AE2 M Z'],
  "drane": ['D R EY1 N'],
  "draney": ['D R EY1 N IY0'],
  "drang": ['D R AE1 NG'],
  "drank": ['D R AE1 NG K'],
  "dransfield": ['D R AE1 N S F IY2 L D'],
  "drape": ['D R EY1 P'],
  "drapeau": ['D R AH0 P OW1'],
  "draped": ['D R EY1 P T'],
  "draper": ['D R EY1 P ER0'],
  "draperies": ['D R EY1 P ER0 IY0 Z'],
  "drapery": ['D R EY1 P ER0 IY0'],
  "drapes": ['D R EY1 P S'],
  "draping": ['D R EY1 P IH0 NG'],
  "drapkin": ['D R AE1 P K IH0 N'],
  "drasner": ['D R AE1 S N ER0'],
  "drastic": ['D R AE1 S T IH0 K'],
  "drastically": ['D R AE1 S T IH0 K L IY0'],
  "draughn": ['D R AO1 N'],
  "draughon": ['D R AO1 AH0 N'],
  "draught": ['D R AE1 F T'],
  "draughts": ['D R AE1 F T S'],
  "draus": ['D R AO1 Z'],
  "dravecky": ['D R AH0 V EH1 K IY0'],
  "draves": ['D R EY1 V Z'],
  "dravis": ['D R AE1 V IH0 S'],
  "dravo": ['D R AE1 V OW0'],
  "dravo's": ['D R AE1 V OW0 Z'],
  "draw": ['D R AO1'],
  "drawback": ['D R AO1 B AE2 K'],
  "drawbacks": ['D R AO1 B AE2 K S'],
  "drawbaugh": ['D R AO1 B AO2'],
  "drawbridge": ['D R AO1 B R IH2 JH'],
  "drawdown": ['D R AO1 D AW2 N'],
  "drawdowns": ['D R AO1 D AW2 N Z'],
  "drawdy": ['D R AO1 D IY0'],
  "drawer": ['D R AO1 R'],
  "drawers": ['D R AO1 R Z'],
  "drawing": ['D R AO1 IH0 NG'],
  "drawings": ['D R AO1 IH0 NG Z'],
  "drawl": ['D R AO1 L'],
  "drawled": ['D R AO1 L D'],
  "drawls": ['D R AO1 L Z'],
  "drawn": ['D R AO1 N'],
  "draws": ['D R AO1 Z'],
  "dray": ['D R EY1'],
  "drayer": ['D R EY1 ER0'],
  "drayton": ['D R EY1 T AH0 N'],
  "drayton's": ['D R EY1 T AH0 N Z'],
  "dread": ['D R EH1 D'],
  "dreaded": ['D R EH1 D IH0 D'],
  "dreadful": ['D R EH1 D F AH0 L'],
  "dreadfully": ['D R EH1 D F AH0 L IY0'],
  "dreading": ['D R EH1 D IH0 NG'],
  "dreadnought": ['D R EH1 D N AO2 T'],
  "dreads": ['D R EH1 D Z'],
  "dream": ['D R IY1 M'],
  "dreamed": ['D R IY1 M D'],
  "dreamer": ['D R IY1 M ER0'],
  "dreamers": ['D R IY1 M ER0 Z'],
  "dreaming": ['D R IY1 M IH0 NG'],
  "dreamland": ['D R IY1 M L AE2 N D'],
  "dreamlike": ['D R IY1 M L AY2 K'],
  "dreams": ['D R IY1 M Z'],
  "dreamt": ['D R EH1 M T'],
  "dreamworks": ['D R IY1 M W ER2 K S'],
  "dreamworks'": ['D R IY1 M W ER2 K S'],
  "dreamworld": ['D R IY1 M W ER2 L D'],
  "dreamy": ['D R IY1 M IY0'],
  "dreariness": ['D R IY1 R IY0 N AH0 S'],
  "dreary": ['D R IH1 R IY0'],
  "drebsky": ['D R EH1 B S K IY0'],
  "drechsel": ['D R EH1 K S AH0 L'],
  "drechsler": ['D R EH1 K S AH0 L ER0', 'D R EH1 K S L ER0'],
  "dred": ['D R EH1 D'],
  "dredd": ['D R EH1 D'],
  "dredge": ['D R EH1 JH'],
  "dredged": ['D R EH1 JH D'],
  "dredges": ['D R EH1 JH AH0 Z', 'D R EH1 JH IH0 Z'],
  "dredging": ['D R EH1 JH IH0 NG'],
  "drees": ['D R IY1 Z'],
  "dreese": ['D R IY1 Z'],
  "dreessen": ['D R IY1 S AH0 N'],
  "dreger": ['D R EH1 G ER0'],
  "dregs": ['D R EH1 G Z'],
  "dreher": ['D R EH1 R'],
  "dreibelbis": ['D R AY1 B IH0 L B IH0 S'],
  "dreier": ['D R AY1 ER0'],
  "dreighton": ['D R AY1 T IH0 N'],
  "dreiling": ['D R AY1 L IH0 NG'],
  "dreis": ['D R IY1 Z'],
  "dreisbach": ['D R AY1 S B AA2 K'],
  "dreman": ['D R IY1 M AH0 N'],
  "drench": ['D R EH1 N CH'],
  "drenched": ['D R EH1 N CH T'],
  "drenches": ['D R EH1 N CH AH0 Z'],
  "drenching": ['D R EH1 N CH IH0 NG'],
  "drennan": ['D R EH1 N AH0 N'],
  "drennen": ['D R EH1 N AH0 N'],
  "drenning": ['D R EH1 N IH0 NG'],
  "drennon": ['D R EH1 N AH0 N'],
  "drenth": ['D R EH1 N TH'],
  "drepung": ['D R EY1 P AH2 NG', 'D R EY2 P AO1 NG'],
  "dresbach": ['D R EH1 S B AA2 K'],
  "dresch": ['D R EH1 SH'],
  "drescher": ['D R EH1 SH ER0'],
  "dresden": ['D R EH1 Z D IH0 N'],
  "dresdner": ['D R EH1 Z D N ER0'],
  "dresdner's": ['D R EH1 Z D N ER0 Z'],
  "dresen": ['D R IY1 Z AH0 N'],
  "dresher": ['D R EH1 SH ER0'],
  "dresner": ['D R EH1 Z N ER0'],
  "dress": ['D R EH1 S'],
  "dressage": ['D R EH0 S AA1 ZH'],
  "dressed": ['D R EH1 S T'],
  "dressel": ['D R EH1 S AH0 L'],
  "dressen": ['D R EH1 S AH0 N'],
  "dresser": ['D R EH1 S ER0'],
  "dresser's": ['D R EH1 S ER0 Z'],
  "dressers": ['D R EH1 S ER0 Z'],
  "dresses": ['D R EH1 S AH0 Z', 'D R EH1 S IH0 Z'],
  "dressier": ['D R EH1 S IY0 ER0'],
  "dressing": ['D R EH1 S IH0 NG'],
  "dressings": ['D R EH1 S IH0 NG Z'],
  "dressler": ['D R EH1 S L ER0'],
  "dressmaker": ['D R EH1 S M EY2 K ER0'],
  "dressmakers": ['D R EH1 S M EY2 K ER0 Z'],
  "dressmaking": ['D R EH1 S M EY2 K IH0 NG'],
  "dressy": ['D R EH1 S IY0'],
  "drew": ['D R UW1'],
  "drewery": ['D R UW1 ER0 IY0'],
  "drewes": ['D R UW1 Z'],
  "drewett": ['D R UW1 IH0 T'],
  "drewry": ['D R UW1 R IY0'],
  "drews": ['D R UW1 Z'],
  "drexel": ['D R EH1 K S AH0 L'],
  "drexel's": ['D R EH1 K S AH0 L Z'],
  "drexler": ['D R EH1 K S L ER0'],
  "drey": ['D R EY1'],
  "dreyer": ['D R EY1 ER0'],
  "dreyfus": ['D R AY1 F AH0 S', 'D R EY1 F AH0 S'],
  "dreyfus's": ['D R EY1 F AH0 S IH0 Z'],
  "dreyfuss": ['D R EY1 F AH0 S'],
  "dribbed": ['D R IH1 B D'],
  "dribble": ['D R IH1 B AH0 L'],
  "dribbled": ['D R IH1 B AH0 L D'],
  "dribbles": ['D R IH1 B AH0 L Z'],
  "dribbling": ['D R IH1 B AH0 L IH0 NG', 'D R IH1 B L IH0 NG'],
  "dribs": ['D R IH1 B Z'],
  "dried": ['D R AY1 D'],
  "driehaus": ['D R IY1 HH AW2 S'],
  "drier": ['D R AY1 ER0'],
  "dries": ['D R AY1 Z'],
  "driessen": ['D R IY1 S AH0 N'],
  "driest": ['D R AY1 AH0 S T'],
  "driever": ['D R IY1 V ER0'],
  "drift": ['D R IH1 F T'],
  "drifta": ['D R IH1 F T AH0'],
  "drifted": ['D R IH1 F T AH0 D', 'D R IH1 F T IH0 D'],
  "drifter": ['D R IH1 F T ER0'],
  "drifters": ['D R IH1 F T ER0 Z'],
  "drifting": ['D R IH1 F T IH0 NG'],
  "driftnet": ['D R IH1 F T N EH2 T'],
  "drifts": ['D R IH1 F T S'],
  "driftwood": ['D R IH1 F T W UH2 D'],
  "driggers": ['D R IH1 G ER0 Z'],
  "driggs": ['D R IH1 G Z'],
  "drill": ['D R IH1 L'],
  "drillbit": ['D R IH1 L B IH2 T'],
  "drilled": ['D R IH1 L D'],
  "driller": ['D R IH1 L ER0'],
  "drillers": ['D R IH1 L ER0 Z'],
  "drilling": ['D R IH1 L IH0 NG'],
  "drilling's": ['D R IH1 L IH0 NG Z'],
  "drills": ['D R IH1 L Z'],
  "drina": ['D IY1 N AH0'],
  "drina's": ['D IY1 N AH0 Z'],
  "drinas": ['D IY1 N AH0 Z'],
  "dring": ['D R IH1 NG'],
  "drink": ['D R IH1 NG K'],
  "drinkable": ['D R IH1 N K AH0 B AH0 L'],
  "drinkard": ['D R IH1 NG K ER0 D'],
  "drinker": ['D R IH1 NG K ER0'],
  "drinker's": ['D R IH1 NG K ER0 Z'],
  "drinkers": ['D R IH1 NG K ER0 Z'],
  "drinking": ['D R IH1 NG K IH0 NG'],
  "drinks": ['D R IH1 NG K S'],
  "drinkwater": ['D R IH1 NG K W AO2 T ER0'],
  "drinkwine": ['D R IH1 NG K W AY2 N'],
  "drinnon": ['D R IH1 N AH0 N'],
  "drip": ['D R IH1 P'],
  "drip-dry": ['D R IH1 P D R AY1'],
  "dripped": ['D R IH1 P T'],
  "dripping": ['D R IH1 P IH0 NG'],
  "dripps": ['D R IH1 P S'],
  "drips": ['D R IH1 P S'],
  "driscoll": ['D R IH1 S K AH0 L'],
  "driskell": ['D R IH1 S K AH0 L'],
  "driskill": ['D R IH1 S K IH0 L'],
  "drivable": ['D R AY1 V AH0 B AH0 L'],
  "drive": ['D R AY1 V'],
  "drive's": ['D R AY1 V Z'],
  "drive-by": ['D R AY1 V B AY1'],
  "drive-thru": ['D R AY1 V TH R UW1'],
  "drive-thrus": ['D R AY1 V TH R UW1 Z'],
  "drivel": ['D R IH1 V AH0 L'],
  "driven": ['D R IH1 V AH0 N'],
  "driver": ['D R AY1 V ER0'],
  "driver's": ['D R AY1 V ER0 Z'],
  "driverless": ['D R AY1 V ER0 L IH0 S'],
  "drivers": ['D R AY1 V ER0 Z'],
  "drivers'": ['D R AY1 V ER0 Z'],
  "drives": ['D R AY1 V Z'],
  "driveway": ['D R AY1 V W EY2'],
  "driveways": ['D R AY1 V W EY2 Z'],
  "driving": ['D R AY1 V IH0 NG'],
  "drizzle": ['D R IH1 Z AH0 L'],
  "drizzling": ['D R IH1 Z AH0 L IH0 NG', 'D R IH1 Z L IH0 NG'],
  "drizzly": ['D R IH1 Z L IY0'],
  "drobkov": ['D R AO1 B K AO0 V', 'D R AO1 B K AO0 F'],
  "drobny": ['D R AA1 B N IY0'],
  "droddy": ['D R AA1 D IY0'],
  "droege": ['D R OW1 JH'],
  "droessler": ['D R OW1 S AH0 L ER0', 'D R OW1 S L ER0'],
  "droge": ['D R OW1 JH'],
  "drogoul": ['D R OW0 G UW1 L'],
  "drogoul's": ['D R OW0 G UW1 L Z'],
  "drogue": ['D R OW1 G'],
  "drohan": ['D R OW1 AH0 N'],
  "droid": ['D R OY1 D'],
  "droids": ['D R OY1 D Z'],
  "droke": ['D R OW1 K'],
  "drolet": ['D R OW1 L IH0 T'],
  "droll": ['D R OW1 L'],
  "drollinger": ['D R OW1 L IH0 NG ER0'],
  "dromedary": ['D R AA1 M AH0 D EH2 R IY0'],
  "dromer": ['D R OW1 M ER0'],
  "dromey": ['D R OW1 M IY0'],
  "dromgoole": ['D R AA1 M G UW2 L'],
  "dromgooles": ['D R AA0 M G UW1 L Z'],
  "dromi": ['D R AA1 M IY0'],
  "dromomania": ['D R OW2 M OW0 M EY1 N IY0 AH0', 'D R OW2 M OW0 M EY1 N Y AH0'],
  "dromon": ['D R OW1 M AH0 N'],
  "drone": ['D R OW1 N'],
  "droned": ['D R OW1 N D'],
  "drones": ['D R OW1 N Z'],
  "dronet": ['D R OW1 N IH0 T'],
  "droney": ['D R OW1 N IY0'],
  "droning": ['D R OW1 N IH0 NG'],
  "drool": ['D R UW1 L'],
  "drooled": ['D R UW1 L D'],
  "drooling": ['D R UW1 L IH0 NG'],
  "droop": ['D R UW1 P'],
  "drooped": ['D R UW1 P T'],
  "drooping": ['D R UW1 P IH0 NG'],
  "droopy": ['D R UW1 P IY0'],
  "drop": ['D R AA1 P', 'D R AO1 P'],
  "drop-off": ['D R AA1 P AO2 F'],
  "drop-offs": ['D R AA1 P AO2 F S'],
  "dropbox": ['D R AA1 P B AA2 K S'],
  "dropkin": ['D R AA1 P K IH0 N'],
  "droplet": ['D R AA1 P L AH0 T'],
  "droplets": ['D R AA1 P L AH0 T S'],
  "dropoff": ['D R AA1 P AO2 F'],
  "dropoffs": ['D R AA1 P AO2 F S'],
  "dropout": ['D R AA1 P AW2 T'],
  "dropouts": ['D R AA1 P AW2 T S'],
  "dropped": ['D R AA1 P T'],
  "dropper": ['D R AA1 P ER0'],
  "droppers": ['D R AA1 P ER0 Z'],
  "dropping": ['D R AA1 P IH0 NG'],
  "droppings": ['D R AA1 P IH0 NG Z'],
  "drops": ['D R AA1 P S'],
  "dropsy": ['D R AA1 P S IY0'],
  "droser": ['D R OW1 Z ER0'],
  "dross": ['D R AO1 S'],
  "drost": ['D R AA1 S T'],
  "droste": ['D R OW1 S T', 'D R AA1 S T', 'D R AA1 S T AH0'],
  "drotar": ['D R OW1 T ER0'],
  "drought": ['D R AW1 T'],
  "drought's": ['D R AW1 T S'],
  "droughts": ['D R AW1 T S'],
  "drouillard": ['D R W IY0 L AA1 R D'],
  "drouin": ['D R W IY1 N'],
  "drove": ['D R OW1 V'],
  "drover": ['D R OW1 V ER0'],
  "droves": ['D R OW1 V Z'],
  "drown": ['D R AW1 N'],
  "drowned": ['D R AW1 N D'],
  "drowning": ['D R AW1 N IH0 NG'],
  "drownings": ['D R AW1 N IH0 NG Z'],
  "drowns": ['D R AW1 N Z'],
  "drowsiness": ['D R AW1 Z IY0 N AH0 S'],
  "drowsy": ['D R AW1 Z IY0'],
  "droz": ['D R AA1 Z'],
  "drozd": ['D R AA1 Z D'],
  "drozda": ['D R AA1 Z D AH0'],
  "drozdowski": ['D R AH0 Z D AO1 F S K IY0'],
  "dru": ['D R UW1'],
  "drub": ['D R AH1 B'],
  "drubbed": ['D R AH1 B D'],
  "drubbing": ['D R AH1 B IH0 NG'],
  "druce": ['D R UW1 S'],
  "drucie": ['D R AH1 K IY0'],
  "druck": ['D R AH1 K'],
  "druckenmiller": ['D R AH1 K IH0 N M IH2 L ER0'],
  "drucker": ['D R AH1 K ER0'],
  "druckman": ['D R AH1 K M AH0 N'],
  "drudge": ['D R AH1 JH'],
  "drudgery": ['D R AH1 JH ER0 IY0'],
  "druella": ['D R UW2 EH1 L AH0'],
  "drug": ['D R AH1 G'],
  "drug's": ['D R AH1 G Z'],
  "drugan": ['D R UW1 G AH0 N'],
  "drugged": ['D R AH1 G D'],
  "druggie": ['D R AH1 G IY0'],
  "drugging": ['D R AH1 G IH0 NG'],
  "druggist": ['D R AH1 G IH0 S T'],
  "druggist's": ['D R AH1 G AH0 S T S', 'D R AH1 G IH0 S T S'],
  "druggists": ['D R AH1 G AH0 S T S', 'D R AH1 G IH0 S T S'],
  "drugmaker": ['D R AH1 G M EY2 K ER0'],
  "drugmakers": ['D R AH1 G M EY2 K ER0 Z'],
  "drugs": ['D R AH1 G Z'],
  "drugs'": ['D R AH1 G Z'],
  "drugstore": ['D R AH1 G S T AO2 R'],
  "drugstores": ['D R AH1 G S T AO2 R Z'],
  "druid": ['D R UW1 IH0 D'],
  "druidism": ['D R UW1 AH0 D IH2 Z AH0 M'],
  "druids": ['D R UW1 IH0 D Z'],
  "druisilla": ['D R IH0 S IH1 L AH0'],
  "drum": ['D R AH1 M'],
  "drumbeat": ['D R AH1 M B IY2 T'],
  "drumhead": ['D R AH1 M HH EH2 D'],
  "drumheller": ['D R AH1 M HH EH2 L ER0'],
  "drumlin's": ['D R AH1 M L IH0 N Z'],
  "drumm": ['D R AH1 M'],
  "drummed": ['D R AH1 M D'],
  "drummer": ['D R AH1 M ER0'],
  "drummers": ['D R AH1 M ER0 Z'],
  "drummey": ['D R AH1 M IY0'],
  "drumming": ['D R AH1 M IH0 NG'],
  "drummond": ['D R AH1 M AH0 N D'],
  "drummonds": ['D R AH1 M AH0 N D Z'],
  "drumright": ['D R AH1 M R AY2 T'],
  "drums": ['D R AH1 M Z'],
  "drumstick": ['D R AH1 M S T IH0 K'],
  "drumwright": ['D R AH1 M R AY2 T'],
  "drunk": ['D R AH1 NG K'],
  "drunkard": ['D R AH1 NG K ER0 D'],
  "drunkards": ['D R AH1 NG K ER0 D Z'],
  "drunken": ['D R AH1 NG K AH0 N'],
  "drunkenness": ['D R AH1 NG K AH0 N N AH0 S'],
  "drunks": ['D R AH1 NG K S'],
  "drupe": ['D R UW1 P'],
  "drupes": ['D R UW1 P S'],
  "drury": ['D R UW1 R IY0'],
  "drusa": ['D R UW1 S AH0'],
  "druse": ['D R UW1 Z'],
  "drusie": ['D R AH1 S IY0'],
  "drusilla": ['D R UW2 S IH1 L AH0'],
  "druthers": ['D R AH1 DH ER0 Z'],
  "druze": ['D R UW1 Z'],
  "dry": ['D R AY1'],
  "dryclean": ['D R AY1 K L IY2 N'],
  "drycleaner": ['D R AY1 K L IY2 N ER0'],
  "drycleaning": ['D R AY1 K L IY2 N IH0 NG'],
  "dryden": ['D R AY1 D AH0 N'],
  "drye": ['D R AY1'],
  "dryer": ['D R AY1 ER0'],
  "dryers": ['D R AY1 ER0 Z'],
  "drying": ['D R AY1 IH0 NG'],
  "dryly": ['D R AY1 L IY0'],
  "dryness": ['D R AY1 N AH0 S'],
  "drypers": ['D R AY1 P ER0 Z'],
  "drypoint": ['D R AY1 P OY2 N T'],
  "drysdale": ['D R AY1 Z D EY2 L'],
  "dryser": ['D R AY1 S ER0'],
  "drywall": ['D R AY1 W AA2 L'],
  "drzewiecki": ['JH UW0 IY1 T S K IY0'],
  "dsouza": ['D AH0 S UW1 Z AH0'],
  "dss": ['D IY1 EH1 S EH1 S'],
  "dsv": ['D IY1 EH1 S V IY1'],
  "du": ['D UW1', 'D AH0'],
  "du-bois": ['D UW1 B OY1 S'],
  "dual": ['D UW1 AH0 L', 'D UW1 L'],
  "dualism": ['D UW1 AH0 L IH2 Z AH0 M'],
  "dualisms": ['D UW1 AH0 L IH2 Z AH0 M Z'],
  "dualist": ['D UW1 AH0 L IH0 S T'],
  "dualistic": ['D UW2 AH0 L IH1 S T IH0 K'],
  "duality": ['D UW0 AE1 L AH0 T IY0'],
  "duals": ['D UW1 AH0 L Z'],
  "duan": ['D W AE1 N', 'D W EY1 N'],
  "duana": ['D UW0 AE1 N AH0'],
  "duane": ['D W EY1 N'],
  "duarte": ['D W AA1 R T EY2'],
  "duarte's": ['D W AA1 R T EY2 Z'],
  "dub": ['D AH1 B'],
  "duba": ['D UW1 B AH0'],
  "dubach": ['D AH1 B AA0 K'],
  "dubai": ['D UW0 B AY1'],
  "dubarry": ['D UW1 B EH2 R IY0'],
  "dubas": ['D UW1 B AH0 Z'],
  "dubay": ['D AH1 B EY0'],
  "dubbed": ['D AH1 B D'],
  "dubberly": ['D AH1 B ER0 L IY0'],
  "dubbing": ['D AH1 B IH0 NG'],
  "dubbs": ['D AH1 B Z'],
  "dubcek": ['D AH1 B CH EH2 K', 'D UW1 B CH EH2 K'],
  "dube": ['D UW1 B'],
  "dubeau": ['D AH0 B OW1'],
  "duberstein": ['D UW1 B ER0 S T AY2 N', 'D UW1 B ER0 S T IY2 N'],
  "duberstein's": ['D UW1 B ER0 S T AY2 N Z', 'D UW1 B ER0 S T IY2 N Z'],
  "dubey": ['D AH1 B IY0'],
  "dubhi": ['D UW1 B IY0'],
  "dubicki": ['D AH0 B IH1 T S K IY0'],
  "dubie": ['D AH1 B IY0'],
  "dubiel": ['D AH1 B IY0 L'],
  "dubilier": ['D UW2 B AH0 L IH1 R', 'D UW2 B AH0 L AY1 ER0'],
  "dubin": ['D UW1 B IH0 N'],
  "dubinin": ['D UW0 B IH1 N IH0 N'],
  "dubinsky": ['D AH0 B IH1 N S K IY0'],
  "dubious": ['D UW1 B IY0 AH0 S'],
  "dubiously": ['D UW1 B IY0 AH0 S L IY2'],
  "dubis": ['D UW1 B IH0 S'],
  "dublin": ['D AH1 B L IH0 N'],
  "dublin's": ['D AH1 B L IH0 N Z'],
  "duboff": ['D AH1 B AO2 F'],
  "dubois": ['D UW0 B OY1 S', 'D UW0 B W AA1'],
  "duboise": ['D UW0 B OY1 S', 'D UW0 B W AA1'],
  "dubord": ['D AH0 B AO1 R D'],
  "dubose": ['D UW0 B OW1 Z'],
  "dubow": ['D UW1 B OW0'],
  "dubrawski": ['D UW0 B R AW1 S K IY0'],
  "dubray": ['D AH1 B R EY2'],
  "dubree": ['D AH0 B R IY1'],
  "dubreuil": ['D AH1 B R UW0 L'],
  "dubroc": ['D AH1 B R AH0 K'],
  "dubroff": ['D UW1 B R AO0 F'],
  "dubrovnik": ['D UW0 B R AA1 V N IH0 K'],
  "dubrovnik's": ['D UW0 B R AA1 V N IH0 K Z'],
  "dubrovniks": ['D UW0 B R AA1 V N IH0 K Z'],
  "dubrovsky": ['D UW0 B R AO1 F S K IY0', 'D UW0 B R AO1 V S K IY0'],
  "dubrow": ['D AH1 B R OW2'],
  "dubs": ['D AH1 B Z'],
  "dubuc": ['D UW1 B AH0 K'],
  "dubuisson": ['D AH1 B IH0 S AH0 N'],
  "dubuque": ['D AH0 B Y UW1 K'],
  "dubuque's": ['D AH0 B Y UW1 K S'],
  "duby": ['D UW1 B IY0'],
  "duc": ['D AH1 K'],
  "duca": ['D UW1 K AH0'],
  "ducal": ['D UW1 K AH0 L'],
  "duce": ['D UW1 S'],
  "ducey": ['D AH1 S IY0'],
  "duch": ['D AH1 CH'],
  "duchaine": ['D AH0 SH EY1 N'],
  "duchamp": ['D UW0 SH AA1 M P'],
  "duchamp's": ['D UW0 SH AA1 M P S'],
  "ducharme": ['D AH0 SH AA1 R M'],
  "duchemin": ['D AH1 SH IH0 M AE0 N'],
  "duchene": ['D AH1 K IY0 N'],
  "duchenne": ['D UW0 SH EH1 N'],
  "duchesneau": ['D AH1 SH IH0 S N OW0'],
  "duchess": ['D AH1 CH AH0 S'],
  "duchin": ['D UW1 CH IH0 N'],
  "duchon": ['D AH1 CH AH0 N'],
  "duchossois": ['D UW0 CH AO1 S W AA2'],
  "duchow": ['D AH1 CH OW0'],
  "duchy": ['D AH1 CH IY0'],
  "duck": ['D AH1 K'],
  "duck's": ['D AH1 K S'],
  "ducked": ['D AH1 K T'],
  "ducker": ['D AH1 K ER0'],
  "duckett": ['D AH1 K IH0 T'],
  "duckies": ['D AH1 K IH2 Z'],
  "ducking": ['D AH1 K IH0 NG'],
  "duckling": ['D AH1 K L IH0 NG'],
  "ducklings": ['D AH1 K L IH0 NG Z'],
  "ducks": ['D AH1 K S'],
  "ducks'": ['D AH1 K S'],
  "ducksworth": ['D AH1 K S W ER2 TH'],
  "duckwall": ['D AH1 K W AO2 L'],
  "duckweed": ['D AH1 K W IY2 D'],
  "duckworth": ['D AH1 K W ER2 TH'],
  "ducky": ['D AH1 K IY2'],
  "duclos": ['D AH0 K L OW1 Z'],
  "ducommun": ['D UW0 K AA1 M AH0 N', 'D UW0 K AA1 M UW1 N'],
  "ducote": ['D AH0 K OW1 T'],
  "duct": ['D AH1 K T'],
  "ductile": ['D AH1 K T AH0 L'],
  "ductility": ['D AH0 K T IH1 L AH0 T IY0'],
  "ductless": ['D AH1 K T L AH0 S'],
  "ducts": ['D AH1 K T S'],
  "dud": ['D AH1 D'],
  "duda": ['D UW1 D AH0'],
  "dudack": ['D UW1 D AE0 K'],
  "dudar": ['D UW1 D ER0'],
  "dudas": ['D UW1 D AH0 Z'],
  "dudash": ['D AH1 D AH0 SH'],
  "dudayev": ['D UW0 D AY1 EH2 V'],
  "dudayev's": ['D UW0 D AY1 EH2 V Z'],
  "dudd": ['D AH1 D'],
  "dudding": ['D AH1 D IH0 NG'],
  "duddy": ['D AH1 D IY0'],
  "dude": ['D UW1 D'],
  "dudeck": ['D UW1 D EH0 K'],
  "dudeck's": ['D UW1 D EH0 K S'],
  "dudek": ['D UW1 D IH0 K'],
  "duden": ['D UW1 D AH0 N'],
  "dudenhoeffer": ['D AH1 D IH0 N HH OW0 F ER0'],
  "dudes": ['D Y UW1 D Z'],
  "dudgeon": ['D AH1 JH AH0 N'],
  "dudik": ['D UW1 D IH0 K'],
  "dudley": ['D AH1 D L IY0'],
  "dudman": ['D AH1 D M AH0 N'],
  "dudney": ['D AH1 D N IY0'],
  "duds": ['D AH1 D Z'],
  "dudziak": ['D AH1 JH IY0 AE0 K'],
  "dudzik": ['D AH1 D Z IH0 K'],
  "dudzinski": ['D AH0 JH IH1 N S K IY0'],
  "due": ['D UW1', 'D Y UW1'],
  "duecker": ['D UW1 K ER0'],
  "dueitt": ['D UW1 AH0 T'],
  "dueker": ['D UW1 K ER0'],
  "duel": ['D UW1 AH0 L'],
  "dueled": ['D UW1 AH0 L D'],
  "dueling": ['D UW1 L IH0 NG'],
  "duelist": ['D UW1 AH0 L IH0 S T'],
  "duell": ['JH UW1 L', 'D UW1 L'],
  "duels": ['D UW1 AH0 L Z'],
  "duena": ['D UW0 EH1 N AH0', 'D W EY1 N Y AH0'],
  "duenas": ['D UW0 EH1 N AH0 S', 'D W EY1 N Y AH0 S'],
  "duenez": ['D W EH0 N EH1 Z'],
  "duenow": ['D UW1 N OW0'],
  "duensing": ['D UH1 N S IH0 NG'],
  "duer": ['D UW1 ER0'],
  "duerksen": ['D UH1 R K S AH0 N'],
  "duerr": ['D UH1 R'],
  "duerson": ['D UH1 R S AH0 N'],
  "duerst": ['D UH1 R S T'],
  "dues": ['D UW1 Z'],
  "duesberg": ['D UW1 Z B ER0 G'],
  "duesing": ['D UW1 S IH0 NG'],
  "duesler": ['D UW1 S AH0 L ER0', 'D UW1 S L ER0'],
  "duesseldorf": ['D UW1 S AH0 L D AO2 R F'],
  "duet": ['D UW0 EH1 T', 'D Y UW0 EH1 T'],
  "duets": ['D UW0 EH1 T S', 'D Y UW0 EH1 T S'],
  "duey": ['D UW1 IY0', 'D Y UW1 IY0'],
  "dufault": ['D AH0 F OW1'],
  "dufek": ['D UW1 F IH0 K'],
  "duff": ['D AH1 F'],
  "duffala": ['D AH0 F AE1 L AH0'],
  "duffee": ['D AH1 F IY0'],
  "duffek": ['D AH1 F IH0 K'],
  "duffel": ['D AH1 F AH0 L'],
  "duffell": ['D AH1 F AH0 L'],
  "duffer": ['D AH1 F ER0'],
  "duffett": ['D AH1 F IH0 T'],
  "duffey": ['D AH1 F IY0'],
  "duffie": ['D AH1 F IY0'],
  "duffield": ['D AH1 F IY2 L D'],
  "duffin": ['D AH1 F IH0 N'],
  "duffner": ['D AH1 F N ER0'],
  "dufford": ['D AH1 F ER0 D'],
  "duffour": ['D AH1 F AO0 R'],
  "duffus": ['D AH1 F AH0 S'],
  "duffy": ['D AH1 F IY0'],
  "duffy's": ['D AH1 F IY0 Z'],
  "dufner": ['D AH1 F N ER0'],
  "duford": ['D AH1 F ER0 D'],
  "dufort": ['D AH1 F ER0 T'],
  "dufour": ['D AH0 F UH1 R'],
  "dufrane": ['D AH0 F R EY1 N'],
  "dufrene": ['D AH0 F R IY1 N'],
  "dufresne": ['D AH0 F R EH1 N'],
  "dufur": ['D AH0 F ER1'],
  "dug": ['D AH1 G'],
  "dugal": ['D UW1 JH AH0 L'],
  "dugald": ['D AH1 G AH0 L D'],
  "dugan": ['D AH1 G AH0 N'],
  "dugar": ['D UW1 G ER0'],
  "dugas": ['D UW1 G AH0 Z'],
  "dugdale": ['D AH1 G D EY2 L'],
  "duggan": ['D AH1 G AH0 N'],
  "duggar": ['D AH1 G ER0'],
  "dugger": ['D AH1 G ER0'],
  "duggin": ['D AH1 G IH0 N'],
  "duggins": ['D AH1 G IH0 N Z'],
  "dugo": ['D UW1 G OW0'],
  "dugout": ['D AH1 G AW2 T'],
  "dugouts": ['D AH1 G AW2 T S'],
  "duguay": ['D AH1 G EY0'],
  "duguid": ['D AH1 G W IH0 D'],
  "duh": ['D AH1'],
  "duhaime": ['D UW1 AY0 M'],
  "duhamel": ['D UW2 HH AE1 M AH0 L'],
  "duhart": ['D AH1 HH AA0 R T'],
  "duhe": ['D UW1 HH'],
  "duhon": ['D UW1 HH AH0 N'],
  "duhr": ['D ER1'],
  "dui": ['D IY1 Y UW1 AY1'],
  "duignan": ['D IH0 G N AE1 N'],
  "duis": ['D UW1 IH0 Z', 'D IY1 Y UW1 AY1 Z'],
  "duisburg": ['D UW1 S B ER0 G'],
  "duitsman": ['D UW1 T S M AH0 N'],
  "duk": ['D AH1 K'],
  "dukakis": ['D UW0 K AA1 K IH0 S'],
  "dukakis'": ['D UW0 K AA1 K IH0 S', 'D UW0 K AA1 K IH0 S IH0 Z'],
  "dukakis's": ['D UW0 K AA1 K IH0 S IH0 Z'],
  "dukakises": ['D UW0 K AA1 K IH0 S IH0 Z'],
  "dukart": ['D AH1 K AA0 R T'],
  "duke": ['D UW1 K'],
  "duke's": ['D UW1 K S'],
  "duked": ['D UW1 K T'],
  "dukedom": ['D UW1 K D AH0 M'],
  "dukeman": ['D UW1 K M AH0 N'],
  "duker": ['D UW1 K ER0'],
  "dukes": ['D UW1 K S'],
  "duking": ['D UW1 K IH0 NG'],
  "dula": ['D UW1 L AH0'],
  "dulac": ['D AH0 L AE1 K'],
  "dulak": ['D UW1 L AH0 K'],
  "dulaney": ['D Y UW1 L AH0 N IY0'],
  "dulany": ['D Y UW0 L AO1 N IY0'],
  "dulay": ['D Y UW1 L EY0'],
  "dulce": ['D AH1 L S'],
  "dulcea": ['D AH1 L S IY0 AH0'],
  "dulcet": ['D AH1 L S AH0 T'],
  "dulciana": ['D UW0 L CH AE1 N AH0'],
  "dulcibelle": ['D AH1 L S IH0 B AH0 L'],
  "dulcie": ['D AH1 L K IY0'],
  "dulcimer": ['D AH1 L S IH0 M ER0'],
  "dulcine": ['D AH1 L S IH0 N'],
  "dulcinea": ['D AH2 L S IH0 N IY1 AH0'],
  "dule": ['D UW1 L'],
  "duley": ['D Y UW1 L IY0'],
  "dulin": ['D UW1 L IH0 N'],
  "duling": ['D Y UW1 L IH0 NG'],
  "dull": ['D AH1 L'],
  "dulle": ['D AH1 L'],
  "dullea": ['D AH1 L IY0 AH0'],
  "dulled": ['D AH1 L D'],
  "duller": ['D AH1 L ER0'],
  "dulles": ['D AH1 L AH0 S'],
  "dullest": ['D AH1 L AH0 S T'],
  "dulling": ['D AH1 L IH0 NG'],
  "dullness": ['D AH1 L N AH0 S'],
  "dulmage": ['D AH1 L M AH0 JH'],
  "dulong": ['D Y UW1 L AO0 NG'],
  "dulski": ['D AH1 L S K IY0'],
  "dulude": ['D Y UW1 L UW2 D'],
  "duluth": ['D AH0 L UW1 TH'],
  "dulwich": ['D AH1 L W IH2 CH'],
  "dulworth": ['D AH1 L W ER0 TH'],
  "duly": ['D UW1 L IY0'],
  "dum": ['D AH1 M'],
  "duma": ['D UW1 M AH0'],
  "duma's": ['D UW1 M AH0 Z'],
  "dumaine": ['D AH0 M EY1 N'],
  "dumais": ['D AH0 M EY1'],
  "duman": ['D UW1 M AH0 N'],
  "dumas": ['D UW1 M AH0 Z', 'D UW2 M AA1'],
  "dumb": ['D AH1 M'],
  "dumb-ass": ['D AH1 M B AE2 S'],
  "dumbass": ['D AH1 M B AE2 S'],
  "dumbbell": ['D AH1 M B EH2 L'],
  "dumbbells": ['D AH1 M B EH2 L Z'],
  "dumber": ['D AH1 M ER0'],
  "dumbest": ['D AH1 M AH0 S T'],
  "dumbfound": ['D AH1 M F AW0 N D'],
  "dumbfounded": ['D AH1 M F AW0 N D IH0 D'],
  "dumbfounding": ['D AH1 M F AW0 N D IH0 NG'],
  "dumbfounds": ['D AH1 M F AW0 N D Z'],
  "dumbing": ['D AH1 M IH0 NG'],
  "dumbledore": ['D AH1 M B AH0 L D AO2 R'],
  "dumbo": ['D AH1 M B OW0'],
  "dumbstruck": ['D AH1 M S T R AH2 K'],
  "dumenil": ['D UW1 M AH0 N IH2 L'],
  "dumez": ['D UW1 M EH0 Z'],
  "dumford": ['D AH1 M F ER0 D'],
  "dumire": ['D UW0 M IH1 R EY0'],
  "dumke": ['D AH1 M K IY0'],
  "dumlao": ['D UW1 M L AW0'],
  "dumler": ['D AH1 M L ER0'],
  "dumm": ['D AH1 M'],
  "dummer": ['D AH1 M ER0'],
  "dummies": ['D AH1 M IY0 Z'],
  "dummitt": ['D AH1 M IH0 T'],
  "dummy": ['D AH1 M IY0'],
  "dumond": ['D AH0 M AA1 N D'],
  "dumont": ['D UW0 M AA1 N T'],
  "dumoulin": ['D AH1 M UW0 L AE0 N'],
  "dump": ['D AH1 M P'],
  "dumped": ['D AH1 M P T'],
  "dumper": ['D AH1 M P ER0'],
  "dumping": ['D AH1 M P IH0 NG'],
  "dumpling": ['D AH1 M P L IH0 NG'],
  "dumplings": ['D AH1 M P L IH0 NG Z'],
  "dumps": ['D AH1 M P S'],
  "dumpster": ['D AH1 M P S T ER0'],
  "dumpsters": ['D AH1 M P S T ER0 Z'],
  "dumptruck": ['D AH1 M P T R AH2 K'],
  "dumptrucks": ['D AH1 M P T R AH2 K S'],
  "dumpty": ['D AH1 M P T IY0'],
  "dumpy": ['D AH1 M P IY0'],
  "dun": ['D AH1 N'],
  "dun's": ['D AH1 N Z'],
  "duna": ['D UW1 N AH0'],
  "dunagan": ['D UW0 N AA1 G AA0 N'],
  "dunahoo": ['D UW0 N AA1 HH UW0'],
  "dunaj": ['D UW1 N AH0 JH'],
  "dunavan": ['D AH1 N AH0 V AE2 N'],
  "dunavant": ['D UW0 N AA1 V AH0 N T'],
  "dunaway": ['D AH1 N AH0 W EY2'],
  "dunay": ['D AH1 N EY0'],
  "dunbar": ['D AH1 N B AA0 R'],
  "dunblane": ['D AH1 N B L EY2 N'],
  "dunc": ['D AH1 NG K'],
  "duncan": ['D AH1 NG K AH0 N'],
  "duncanson": ['D AH1 NG K AH0 N S AH0 N'],
  "dunckel": ['D AH1 NG K AH0 L'],
  "duncombe": ['D AH1 NG K AH0 M'],
  "dundas": ['D AH1 N D AH0 Z'],
  "dundee": ['D AH0 N D IY1'],
  "dundon": ['D AH1 N D AH0 N'],
  "dundore": ['D AH1 N D ER0'],
  "dune": ['D UW1 N'],
  "dunedin": ['D UW1 N AH0 D IH0 N'],
  "dunegan": ['D AH1 N IH0 G AE0 N'],
  "dunes": ['D UW1 N Z'],
  "dunfee": ['D AH1 N F IY2'],
  "dunford": ['D AH1 N F ER0 D'],
  "dung": ['D AH1 NG'],
  "dungan": ['D AH1 NG G AH0 N'],
  "dungeon": ['D AH1 N JH AH0 N'],
  "dungeons": ['D AH1 N JH AH0 N Z'],
  "dungey": ['D AH1 N JH IY0'],
  "dunham": ['D AH1 N AH0 M'],
  "dunhill": ['D AH1 N HH IH2 L'],
  "dunigan": ['D AH1 N IH0 G AE0 N'],
  "dunite": ['D UW0 N AY1 T'],
  "dunivan": ['D AH1 N IH0 V AE0 N'],
  "dunja": ['D UW1 N Y AH0', 'D AH1 N JH AH0'],
  "dunk": ['D AH1 NG K'],
  "dunked": ['D AH1 NG K T'],
  "dunkel": ['D AH1 NG K AH0 L'],
  "dunkelberg": ['D AH1 NG K AH0 L B ER0 G'],
  "dunkelberger": ['D AH1 NG K AH0 L B ER0 G ER0'],
  "dunker": ['D AH1 NG K ER0'],
  "dunkerley": ['D AH1 NG K ER0 L IY0'],
  "dunkin": ['D AH1 NG K IH0 N'],
  "dunkin'": ['D AH1 NG K IH0 N'],
  "dunkirk": ['D AH1 N K ER0 K'],
  "dunkle": ['D AH1 NG K AH0 L'],
  "dunkleberger": ['D AH1 NG K AH0 L B ER0 G ER0'],
  "dunklee": ['D AH1 NG K L IY2'],
  "dunkley": ['D AH1 NG K L IY0'],
  "dunklin": ['D AH1 NG K L IH0 N'],
  "dunks": ['D AH1 NG K S'],
  "dunlaevy": ['D AH0 N L EY1 V IY0'],
  "dunlap": ['D AH1 N L AE0 P'],
  "dunlavey": ['D AH0 N L AH0 V EY1'],
  "dunlavy": ['D AH1 N L AH0 V IY0'],
  "dunlay": ['D AH1 N L EY2'],
  "dunleavy": ['D UW1 N L AH0 V IY0'],
  "dunlevy": ['D UW1 N L IH0 V IY0'],
  "dunley": ['D AH1 N L IY0'],
  "dunlop": ['D AH1 N L AA2 P'],
  "dunman": ['D AH1 N M AH0 N'],
  "dunmire": ['D UW0 N M IH1 R EY0'],
  "dunn": ['D AH1 N'],
  "dunnagan": ['D AH1 N AH0 G AE0 N'],
  "dunnam": ['D AH1 N AH0 M'],
  "dunnavant": ['D AH1 N AH0 V AH0 N T'],
  "dunnaway": ['D AH1 N AH0 W EY0'],
  "dunne": ['D AH1 N'],
  "dunned": ['D AH1 N D'],
  "dunnell": ['D AH1 N AH0 L'],
  "dunnett": ['D AH1 N IH0 T'],
  "dunnigan": ['D AH1 N IH0 G AH0 N'],
  "dunning": ['D AH1 N IH0 NG'],
  "dunnington": ['D AH1 N IH0 NG T AH0 N'],
  "dunno": ['D AH0 N OW1'],
  "dunphy": ['D AH1 N F IY0'],
  "duns": ['D AH1 N Z'],
  "dunshee": ['D AH1 N SH IY0'],
  "dunsmoor": ['D AH1 N Z M UH2 R'],
  "dunsmore": ['D AH1 N S M AO0 R'],
  "dunson": ['D AH1 N S AH0 N'],
  "dunst": ['D AH1 N S T'],
  "dunstan": ['D AH1 N S T AH0 N'],
  "dunster": ['D AH1 N S T ER0'],
  "dunston": ['D AH1 N S T AH0 N'],
  "dunsworth": ['D AH1 N Z W ER2 TH'],
  "dunton": ['D AH1 N T AH0 N'],
  "dunwoody": ['D AH1 N W UH2 D IY0'],
  "dunworth": ['D AH1 N W ER2 TH'],
  "duo": ['D UW1 OW0'],
  "duodenal": ['D UW1 AH0 D IY1 N AH0 L', 'D UW0 AA1 D AH0 N AH0 L'],
  "duodenum": ['D UW0 AA1 D AH0 N AH0 M'],
  "duong": ['D UW1 OW0 NG'],
  "duopoly": ['D UW1 OW0 P AA2 L IY0', 'D Y UW0 AA1 P AH0 L IY0'],
  "dupay": ['D UW0 P EY1'],
  "dupe": ['D UW1 P'],
  "duped": ['D UW1 P T'],
  "dupee": ['D UW1 P IY1'],
  "duper": ['D UW1 P ER0'],
  "dupes": ['D UW1 P S'],
  "dupin": ['D AH0 P AE1 N'],
  "duplantis": ['D AH0 P L AE1 N T IH0 S'],
  "duplechain": ['D UW1 P L IH0 SH EY0 N'],
  "duplechin": ['D UW1 P L IH0 K IH0 N'],
  "dupler": ['D UW1 P AH0 L ER0', 'D UW1 P L ER0'],
  "duplessis": ['D UW1 P L IH0 S IH0 S'],
  "duplex": ['D UW1 P L EH2 K S'],
  "duplicate": ['D UW1 P L AH0 K AH0 T', 'D UW1 P L AH0 K EY2 T'],
  "duplicated": ['D UW1 P L IH0 K EY2 T IH0 D', 'D Y UW1 P L AH0 K EY2 T IH0 D'],
  "duplicates": ['D Y UW1 P L AH0 K EY2 T S'],
  "duplicating": ['D UW1 P L IH0 K EY2 T IH0 NG'],
  "duplication": ['D Y UW2 P L AH0 K EY1 SH AH0 N'],
  "duplications": ['D UW2 P L IH0 K EY1 SH AH0 N Z'],
  "duplicative": ['D UW0 P L IH1 K AH0 T IH0 V'],
  "duplicitous": ['D UW0 P L IH1 S IH0 T AH0 S'],
  "duplicity": ['D UW0 P L IH1 S IH0 T IY0'],
  "dupont": ['D UW1 P AA0 N T'],
  "dupont's": ['D UW1 P AA0 N T S'],
  "dupras": ['D AH0 P R AA1 Z'],
  "dupre": ['D AH1 P R EY0'],
  "dupree": ['D AH0 P R IY1'],
  "duprey": ['D AH1 P R EY0'],
  "dupriest": ['D AH1 P ER0 IY0 IH0 S T', 'D UW2 P R IY1 S T'],
  "dupuis": ['D AH0 P W IY1'],
  "dupuy": ['D AH0 P W IY1'],
  "duque": ['D UW1 K'],
  "duquesne": ['D UW0 K EY1 N'],
  "duquesne's": ['D UW0 K EY1 N Z'],
  "duquette": ['D AH0 K EH1 T'],
  "dura": ['D UH1 R AH0'],
  "durability": ['D ER0 AH0 B IH1 L IH0 T IY0'],
  "durable": ['D UH1 R AH0 B AH0 L'],
  "durables": ['D UH1 R AH0 B AH0 L Z'],
  "duracell": ['D UH1 R AH0 S EH2 L'],
  "durakon": ['D UH1 R AH0 K IH0 N'],
  "durall": ['D Y UW1 R AH0 L'],
  "duramed": ['D UH1 R AH0 M EH2 D'],
  "duran": ['D ER0 AE1 N'],
  "duran's": ['D ER0 AE1 N S'],
  "durand": ['D UH0 R AA1 N D'],
  "durando": ['D UH0 R AA1 N D OW0'],
  "durang": ['D ER0 AE1 NG'],
  "durango": ['D ER0 AE1 NG G OW0'],
  "durant": ['D UH1 R AH0 N T'],
  "durant's": ['D ER0 AE1 N T S'],
  "durante": ['D UH0 R AA1 N T IY0'],
  "duration": ['D UH1 R EY1 SH AH0 N'],
  "durations": ['D UH1 R EY1 SH AH0 N Z'],
  "duray": ['D UH1 R EY0'],
  "durazo": ['D UH0 R AA1 Z OW0'],
  "durban": ['D ER1 B AH0 N'],
  "durben": ['D ER1 B AH0 N'],
  "durbin": ['D ER1 B IH0 N'],
  "durch": ['D ER1 CH'],
  "durchholz": ['D ER1 CH OW2 L T S'],
  "durden": ['D ER1 D AH0 N'],
  "durdin": ['D ER1 D IH0 N'],
  "duree": ['D UH1 R IY1'],
  "durell": ['D Y UW1 R AH0 L'],
  "duren": ['D UH1 R AH0 N'],
  "durenberger": ['D UH1 R AH0 N B ER0 G ER0'],
  "durene": ['D Y UW1 R IY0 N'],
  "durer": ['D UH1 R ER0'],
  "duress": ['D UH1 R EH0 S'],
  "durette": ['D ER0 EH1 T'],
  "durfee": ['D ER1 F IY0'],
  "durfey": ['D ER1 F IY0'],
  "durflinger": ['D ER1 F AH0 L IH0 NG ER0', 'D ER1 F L IH0 NG ER0'],
  "durgan": ['D ER1 G AH0 N'],
  "durgin": ['D ER1 JH IH0 N'],
  "durham": ['D ER1 AH0 M', 'D UH1 R HH AE1 M', 'D UH1 R AH0 M'],
  "duri": ['D UH1 R IY0'],
  "durick": ['D Y UW1 R IH0 K'],
  "durie": ['D UH1 R IY0'],
  "during": ['D UH1 R IH0 NG', 'D Y UH1 R IH0 NG', 'D ER1 IH0 NG'],
  "durio": ['D UH1 R IY0 OW0'],
  "duriron": ['D UW0 R IH1 R AH0 N'],
  "duris": ['D Y UW1 R IH0 S'],
  "durkee": ['D ER1 K IY0'],
  "durkheim": ['D ER1 K HH AY2 M'],
  "durkheim's": ['D ER1 K HH AY2 M Z'],
  "durkin": ['D ER1 K IH0 N'],
  "durland": ['D ER1 L AH0 N D'],
  "durley": ['D ER1 L IY0'],
  "durling": ['D ER1 L IH0 NG'],
  "durn": ['D ER1 N'],
  "durnell": ['D ER1 N AH0 L'],
  "durney": ['D ER1 N IY0'],
  "durnil": ['D ER1 N AH0 L'],
  "durnin": ['D ER1 N IH0 N'],
  "durning": ['D ER1 N IH0 NG'],
  "duro": ['D UH1 R OW2'],
  "durocher": ['D Y UW1 R AH0 K ER0'],
  "duron": ['D Y UW1 R AH0 N'],
  "duross": ['D Y UW1 R AH0 S'],
  "durr": ['D ER1'],
  "durrah": ['D UH1 R AH0'],
  "durrance": ['D UH1 R AH0 N S'],
  "durrant": ['D UH0 R AE1 N T'],
  "durrell": ['D UH1 R AH0 L'],
  "durrence": ['D UH1 R AH0 N S'],
  "durrett": ['D UH1 R IH0 T'],
  "durso": ['D UH1 R S OW0'],
  "durst": ['D ER1 S T'],
  "durum": ['D UH1 R AH0 M'],
  "durward": ['D ER1 W ER0 D'],
  "durwin": ['D ER1 W IH0 N'],
  "dury": ['D UH1 R IY0'],
  "duryea": ['D UH0 R IY1 AH0'],
  "duryee": ['D UH0 R IY1'],
  "dusch": ['D AH1 SH', 'D AH1 CH'],
  "dusek": ['D UW1 S EH0 K'],
  "dusenberry": ['D UW1 S AH0 N B EH0 R IY0'],
  "dusenbery": ['D AH0 S EH1 N B ER0 IY0'],
  "dusenbury": ['D UW1 S AH0 N B EH0 R IY0'],
  "dusett": ['D UW1 S AH0 T'],
  "dush": ['D AH1 SH', 'D UW1 SH'],
  "dushane": ['D UW2 SH EY1 N'],
  "dushyanth": ['D UW2 SH IY0 AA1 N TH'],
  "dusing": ['D UW1 S IH0 NG'],
  "dusk": ['D AH1 S K'],
  "duskin": ['D AH1 S K IH0 N'],
  "dussault": ['D AH0 S OW1'],
  "dusseau": ['D AH0 S OW1'],
  "dusseault": ['D AH0 S OW1'],
  "dusseldorf": ['D UW1 S AH0 L D AO2 R F'],
  "dust": ['D AH1 S T'],
  "dustbin": ['D AH1 S T B IH0 N'],
  "dusted": ['D AH1 S T IH0 D'],
  "duster": ['D AH1 S T ER0'],
  "dusters": ['D AH1 S T ER0 Z'],
  "dustin": ['D AH1 S T IH0 N'],
  "dusting": ['D AH1 S T IH0 NG'],
  "dustman": ['D AH1 S T M AH0 N'],
  "duston": ['D AH1 S T AH0 N'],
  "dusts": ['D AH1 S T S'],
  "dusty": ['D AH1 S T IY0'],
  "dusza": ['D AH1 SH AH0'],
  "duszynski": ['D AH0 SH IH1 N S K IY0'],
  "dutch": ['D AH1 CH'],
  "dutcher": ['D AH1 CH ER0'],
  "dutchman": ['D AH1 CH M AH0 N'],
  "duthie": ['D AH1 TH IY0'],
  "duties": ['D UW1 T IY0 Z'],
  "dutiful": ['D UW1 T IY0 F AH0 L'],
  "dutifully": ['D UW1 T IY0 F AH0 L IY0'],
  "dutil": ['D AH0 T IH1 L'],
  "dutka": ['D AH1 T K AH0'],
  "dutkiewicz": ['D AH1 T K AH0 V IH0 CH'],
  "dutko": ['D AH1 T K OW0'],
  "dutoit": ['D UW0 T OY1 T'],
  "dutra": ['D UW1 T R AH0'],
  "dutro": ['D AH1 T R OW0'],
  "dutrow": ['D AH1 T R OW0'],
  "dutson": ['D AH1 T S AH0 N'],
  "dutt": ['D AH1 T'],
  "dutter": ['D AH1 T ER0'],
  "dutton": ['D AH1 T AH0 N'],
  "duty": ['D UW1 T IY0', 'D Y UW1 T IY0'],
  "duva": ['D UW1 V AH0'],
  "duval": ['D UW0 V AE1 L'],
  "duvalier": ['D UW0 V AE1 L Y ER0'],
  "duvaliers": ['D UW0 V EY1 L Y ER0 Z'],
  "duvall": ['D UW0 V AA1 L'],
  "duve": ['D UW1 V'],
  "duvernay": ['D AH0 V ER1 N EY0'],
  "duvrees": ['D UW0 V R IY1 Z'],
  "duwayne": ['D UW0 W EY1 N'],
  "duwe": ['D UW1 W'],
  "dux": ['D AH1 K S'],
  "duzan": ['D UW1 Z AH0 N'],
  "dvd": ['D IY2 V IY2 D IY1'],
  "dvds": ['D IY2 V IY2 D IY1 Z'],
  "dvoracek": ['D V AO1 R AH0 CH EH0 K'],
  "dvorak": ['D V AO1 R AH0 K'],
  "dvorsky": ['D V AO1 R S K IY0'],
  "dwan": ['D W AA1 N'],
  "dwana": ['D W AA1 N AH0'],
  "dwarf": ['D W AO1 R F'],
  "dwarfed": ['D W AO1 R F T'],
  "dwarfing": ['D W AO1 R F IH0 NG'],
  "dwarfism": ['D W AO1 R F IH0 Z AH0 M'],
  "dwarfs": ['D W AO1 R F S'],
  "dwarves": ['D W AO1 R V Z'],
  "dwayne": ['D W EY1 N'],
  "dweck": ['D W EH1 K'],
  "dweeb": ['D W IY1 B'],
  "dwell": ['D W EH1 L'],
  "dwelle": ['D W EH1 L'],
  "dwelled": ['D W EH1 L D'],
  "dweller": ['D W EH1 L ER0'],
  "dwellers": ['D W EH1 L ER0 Z'],
  "dwelley": ['D W EH1 L IY0'],
  "dwelling": ['D W EH1 L IH0 NG'],
  "dwellings": ['D W EH1 L IH0 NG Z'],
  "dwells": ['D W EH1 L Z'],
  "dwelt": ['D W EH1 L T'],
  "dwi": ['D IY1 D AH1 B AH0 L Y UW1 AY1', 'D IY1 D AH1 B AH0 Y UW1 AY1'],
  "dwiggins": ['D W IH1 G IH0 N Z'],
  "dwight": ['D W AY1 T'],
  "dwindle": ['D W IH1 N D AH0 L'],
  "dwindled": ['D W IH1 N D AH0 L D'],
  "dwindles": ['D W IH1 N D AH0 L Z'],
  "dwindling": ['D W IH1 N D AH0 L IH0 NG', 'D W IH1 N D L IH0 NG'],
  "dwinell": ['D W IH1 N AH0 L'],
  "dwire": ['D W AY1 R'],
  "dwivedi": ['D W IH0 V EH1 D IY0'],
  "dworak": ['D W ER1 AH0 K'],
  "dworin": ['D W AO1 R IH0 N'],
  "dworkin": ['D W AO1 R K IH0 N'],
  "dworsky": ['D W ER1 S K IY0'],
  "dwyer": ['D W AY1 ER0'],
  "dyad": ['D AY1 AE2 D'],
  "dyal": ['D AY1 AH0 L'],
  "dyan": ['D AY0 AE1 N'],
  "dyana": ['D AY0 AE1 N AH0'],
  "dyane": ['D AY0 AE1 N'],
  "dyansen": ['D AY1 AH0 N S AH0 N'],
  "dyar": ['D AY1 ER0'],
  "dyas": ['D AY1 AH0 S'],
  "dyatron": ['D AY1 AH0 T R AH0 N'],
  "dyazide": ['D AY1 AH0 Z AY2 D'],
  "dybas": ['D AY1 B AH0 Z'],
  "dyce": ['D AY1 S'],
  "dyche": ['D AY1 CH'],
  "dyches": ['D AY1 CH IH0 Z'],
  "dyck": ['D AY1 K'],
  "dyckman": ['D IH1 K M AH0 N'],
  "dyco": ['D AY1 K OW0'],
  "dycus": ['D AY1 K AH0 S'],
  "dye": ['D AY1'],
  "dyed": ['D AY1 D'],
  "dyeing": ['D AY1 IH0 NG'],
  "dyer": ['D AY1 ER0'],
  "dyes": ['D AY1 Z'],
  "dyess": ['D AY1 AH0 S'],
  "dyestuff": ['D AY1 S T AH2 F'],
  "dyestuffs": ['D AY1 S T AH2 F S'],
  "dygert": ['D IH1 G ER0 T'],
  "dying": ['D AY1 IH0 NG'],
  "dyk": ['D IH1 K'],
  "dykas": ['D AY1 K AH0 Z'],
  "dyke": ['D AY1 K'],
  "dykeman": ['D AY1 K M AH0 N'],
  "dykes": ['D AY1 K S'],
  "dykhouse": ['D IH1 K HH AW2 S'],
  "dykman": ['D IH1 K M AH0 N'],
  "dykstra": ['D AY1 K S T R AH0'],
  "dylan": ['D IH1 L AH0 N'],
  "dylan's": ['D IH1 L AH0 N Z'],
  "dylans": ['D IH1 L AH0 N Z'],
  "dylewski": ['D IH0 L EH1 F S K IY0'],
  "dylex": ['D AY1 L AH0 K S'],
  "dyloptisaurus": ['D IH2 L AA0 P T AH0 S AA1 R AH0 S'],
  "dymally": ['D IH1 M AH0 L IY0'],
  "dymek": ['D IH1 M EH0 K'],
  "dyment": ['D IH1 M AH0 N T'],
  "dymond": ['D AY1 M AH0 N D'],
  "dynafac": ['D AY1 N AH0 F AE2 K'],
  "dynalectric": ['D AY1 N AH0 L EH2 K T R IH0 K'],
  "dynalectron": ['D AY1 N AH0 L EH2 K T R AH0 N'],
  "dynamic": ['D AY0 N AE1 M IH0 K'],
  "dynamically": ['D AY0 N AE1 M IH0 K L IY2'],
  "dynamics": ['D AY0 N AE1 M IH0 K S'],
  "dynamics'": ['D IH0 N AE1 M IH0 K S', 'D AY0 N AE1 M IH0 K S'],
  "dynamics's": ['D AY0 N AE1 M IH0 K S IH0 Z'],
  "dynamism": ['D AY1 N AH0 M IH2 Z AH0 M'],
  "dynamite": ['D AY1 N AH0 M AY2 T'],
  "dynamo": ['D AY1 N AH0 M OW2'],
  "dynamometer": ['D AY2 N AH0 M AA1 M AH0 T ER0'],
  "dynapac": ['D AY1 N AH0 P AE2 K'],
  "dynascan": ['D AY1 N AH0 S K AE2 N'],
  "dynastic": ['D AY0 N AE1 S T IH0 K'],
  "dynasties": ['D AY1 N AH0 S T IY0 Z'],
  "dynasty": ['D AY1 N AH0 S T IY0'],
  "dynatech": ['D IH1 N AH0 T EH2 K'],
  "dyncorp": ['D IH1 N K AO2 R P', 'D AY1 N K AO2 R P'],
  "dyneer": ['D IH0 N IH1 R', 'D AY0 N IH1 R'],
  "dynes": ['D AY1 N Z'],
  "dysan": ['D AY1 S AH0 N'],
  "dysart": ['D IH1 S ER0 T'],
  "dysentery": ['D IH1 S AH0 N T EH2 R IY0'],
  "dysert": ['D IH1 S ER0 T'],
  "dysfunction": ['D IH0 S F AH1 NG K SH AH0 N'],
  "dysfunctional": ['D IH0 S F AH1 NG K SH AH0 N AH0 L'],
  "dysfunctions": ['D IH0 S F AH1 NG K SH AH0 N Z'],
  "dysinger": ['D IH1 S IH0 N JH ER0'],
  "dyslexia": ['D IH0 S L EH1 K S IY0 AH0'],
  "dyslexic": ['D IH0 S L EH1 K S IH0 K'],
  "dyson": ['D AY1 S AH0 N'],
  "dyspeptic": ['D IH2 S P EH1 P T IH0 K'],
  "dysphoric": ['D IH2 S F AO1 R IH0 K'],
  "dysplasia": ['D IH2 S P L EY1 ZH AH0'],
  "dyspnea": ['D IH2 S P N IY1 AH0'],
  "dystrophin": ['D IH1 S T R AH0 F IH0 N'],
  "dystrophy": ['D IH1 S T R AH0 F IY0'],
  "dzerzhinsk": ['JH ER0 Z IH1 N S K'],
  "dzhambul": ['JH AE0 M B UW1 L'],
  "dzhirkvelov": ['JH ER2 K V EH1 L AO0 V'],
  "dzhokhar": ['JH OW2 K AA1 R'],
  "dzhokhar's": ['JH OW2 K AA1 R Z'],
  "dziak": ['D Z IY1 AE0 K'],
  "dzialo": ['JH IY0 AA1 L OW0'],
  "dziedzic": ['JH IY1 JH IH0 K'],
  "dziekan": ['JH IY1 K AH0 N'],
  "dzik": ['D Z IH1 K'],
  "dzikowski": ['JH IH0 K AO1 F S K IY2'],
  "dziuba": ['JH IY0 UW1 B AH0'],
  "dziuk": ['JH IY0 UW1 K'],
  "dzokhar": ['JH OW2 K AA1 R'],
  "e": ['IY1'],
  "e's": ['IY1 Z'],
  "e-commerce": ['IY1 K AA1 M ER0 S'],
  "e-mail": ['IY1 M EY2 L'],
  "e-mailed": ['IY1 M EY2 L D'],
  "e-mailing": ['IY1 M EY2 L IH0 NG'],
  "e-mails": ['IY1 M EY2 L Z'],
  "e.": ['IY1'],
  "e.'s": ['IY1 Z'],
  "e.g.": ['IY2 G IY1'],
  "e.s": ['IY1 Z'],
  "each": ['IY1 CH'],
  "eachan": ['IY1 CH AH0 N'],
  "eachus": ['IY1 CH AH0 S'],
  "eaddy": ['IY1 D IY0'],
  "eade": ['IY1 D'],
  "eader": ['IY1 D ER0'],
  "eades": ['IY1 D Z'],
  "eadie": ['EH1 D IY0'],
  "eads": ['IY1 D Z'],
  "eads'": ['IY1 D Z'],
  "eady": ['IY1 D IY0'],
  "eagan": ['IY1 G AH0 N'],
  "eagar": ['IY1 G ER0'],
  "eagen": ['IY1 G AH0 N'],
  "eager": ['IY1 G ER0'],
  "eagerly": ['IY1 G ER0 L IY0'],
  "eagerness": ['IY1 G ER0 N AH0 S'],
  "eagle": ['IY1 G AH0 L'],
  "eagle's": ['IY1 G AH0 L Z'],
  "eagleburger": ['IY1 G AH0 L B ER0 G ER0'],
  "eagles": ['IY1 G AH0 L Z'],
  "eagleson": ['IY1 G AH0 L S AH0 N'],
  "eagleton": ['IY1 G AH0 L T AH0 N'],
  "eagleye": ['IY1 G AH0 L AY2'],
  "eaglin": ['IY1 G L IH0 N'],
  "eagon": ['IY1 G AH0 N'],
  "eaken": ['IY1 K AH0 N'],
  "eaker": ['IY1 K ER0'],
  "eakes": ['IY1 K S'],
  "eakin": ['IY1 K IH0 N'],
  "eakins": ['IY1 K IH0 N Z'],
  "eakle": ['IY1 K AH0 L'],
  "eales": ['IY1 L Z'],
  "ealey": ['IY1 L IY0'],
  "ealing": ['IY1 L IH0 NG'],
  "ealy": ['IY1 L IY0'],
  "eamer": ['IY1 M ER0'],
  "eames": ['IY1 M Z'],
  "eamon": ['IY1 M AH0 N'],
  "eanes": ['IY1 N Z'],
  "eap": ['IY1 EY1 P IY1', 'IY1 P'],
  "eappen": ['IY1 P AH0 N'],
  "ear": ['IY1 R', 'IH1 R'],
  "eardley": ['IH1 R D L IY0', 'ER1 D L IY0'],
  "eared": ['IH1 R D'],
  "earful": ['IY1 R F AH2 L'],
  "earfull": ['IH1 R F AH2 L'],
  "eargle": ['IH1 R G AH0 L'],
  "earhart": ['IH1 R HH AA0 R T', 'EH1 R HH AA0 R T'],
  "earl": ['ER1 L'],
  "earl's": ['ER1 L Z'],
  "earldom": ['ER1 L D AH0 M'],
  "earle": ['ER1 L'],
  "earlene": ['ER1 L IY0 N'],
  "earles": ['ER1 L Z'],
  "earless": ['IH1 R L AH0 S', 'IY1 R L AH0 S'],
  "earley": ['ER1 L IY0'],
  "earlie": ['ER1 L IY0'],
  "earlier": ['ER1 L IY0 ER0'],
  "earlier's": ['ER1 L IY0 ER0 Z'],
  "earliest": ['ER1 L IY0 AH0 S T'],
  "earline": ['ER1 L AY0 N'],
  "earll": ['ER1 L'],
  "earls": ['ER1 L Z'],
  "early": ['ER1 L IY0'],
  "earlywine": ['ER1 L IY0 W AY2 N'],
  "earmark": ['IH1 R M AA2 R K', 'IY1 R M AA2 R K'],
  "earmarked": ['IH1 R M AA2 R K T', 'IY1 R M AA2 R K T'],
  "earmarking": ['IH1 R M AA2 R K IH0 NG', 'IY1 R M AA2 R K IH0 NG'],
  "earmarks": ['IH1 R M AA2 R K S', 'IY1 R M AA2 R K S'],
  "earmuff": ['IH1 R M AH2 F', 'IY1 R M AH2 F'],
  "earmuffs": ['IH1 R M AH2 F S', 'IY1 R M AH2 F S'],
  "earn": ['ER1 N'],
  "earned": ['ER1 N D'],
  "earner": ['ER1 N ER0'],
  "earners": ['ER1 N ER0 Z'],
  "earnest": ['ER1 N IH0 S T'],
  "earnestly": ['ER1 N AH0 S T L IY0'],
  "earnestness": ['ER1 N AH0 S T N AH0 S'],
  "earney": ['ER1 N IY0'],
  "earnhardt": ['ER1 N HH AA2 R T'],
  "earnhart": ['ER1 N HH AA2 R T'],
  "earnheart": ['ER1 N HH AA2 R T'],
  "earning": ['ER1 N IH0 NG'],
  "earnings": ['ER1 N IH0 NG Z'],
  "earnings'": ['ER1 N IH0 NG Z'],
  "earns": ['ER1 N Z'],
  "earnshaw": ['ER1 N SH AO2'],
  "earp": ['ER1 P'],
  "earphone": ['IH1 R F OW2 N', 'IY1 R F OW2 N'],
  "earphones": ['IH1 R F OW2 N Z', 'IY1 R F OW2 N Z'],
  "earpiece": ['IH1 R P IY0 S', 'IY1 R P IY0 S'],
  "earpieces": ['IH1 R P IY0 S IH0 Z', 'IY1 R P IY0 S IH0 Z'],
  "earplug": ['IH1 R P L AH2 G', 'IY1 R P L AH2 G'],
  "earplugs": ['IH1 R P L AH2 G Z', 'IY1 R P L AH2 G Z'],
  "earring": ['IH1 R IH0 NG', 'IY1 R IH0 NG'],
  "earrings": ['IH1 R IH0 NG Z', 'IY1 R IH0 NG Z'],
  "ears": ['IH1 R Z', 'IY1 R Z'],
  "earshot": ['IH1 R SH AA2 T', 'IY1 R SH AA2 T'],
  "earth": ['ER1 TH'],
  "earth's": ['ER1 TH S'],
  "earth-like": ['ER1 TH L AY2 K'],
  "eartha": ['ER1 TH AH0'],
  "earthbound": ['ER1 TH B AW2 N D'],
  "earthen": ['ER1 TH AH0 N'],
  "earthenware": ['ER1 TH AH0 N W EH2 R'],
  "earthling": ['ER1 TH L IH0 NG'],
  "earthlings": ['ER1 TH L IH0 NG Z'],
  "earthly": ['ER1 TH L IY0'],
  "earthmoving": ['ER1 TH M UW2 V IH0 NG'],
  "earthquake": ['ER1 TH K W EY2 K'],
  "earthquake's": ['ER1 TH K W EY2 K S'],
  "earthquakes": ['ER1 TH K W EY2 K S'],
  "earths": ['ER1 TH S'],
  "earthshaking": ['ER1 TH SH EY2 K IH0 NG'],
  "earthshine": ['ER1 TH SH AY2 N'],
  "earthstar": ['ER1 TH S T AA2 R'],
  "earthwork": ['ER1 TH W ER2 K'],
  "earthworm": ['ER1 TH W ER2 M'],
  "earthworms": ['ER1 TH W ER2 M Z'],
  "earthy": ['ER1 TH IY0'],
  "earvin": ['ER1 V IH0 N'],
  "earwax": ['IH1 R W AE2 K S', 'IY1 R W AE2 K S'],
  "earwood": ['IH1 R W UH2 D', 'IY1 R W UH2 D'],
  "eary": ['IH1 R IY0'],
  "easco": ['IY1 S K OW0'],
  "ease": ['IY1 Z'],
  "eased": ['IY1 Z D'],
  "easel": ['IY1 Z AH0 L'],
  "easement": ['IY1 Z M AH0 N T'],
  "eases": ['IY1 Z IH0 Z'],
  "eash": ['IY1 SH'],
  "easib": ['IY1 S IH0 B'],
  "easier": ['IY1 Z IY0 ER0'],
  "easiest": ['IY1 Z IY0 AH0 S T'],
  "easily": ['IY1 Z AH0 L IY0'],
  "easing": ['IY1 Z IH0 NG'],
  "easler": ['IY1 Z L ER0'],
  "easley": ['IY1 Z L IY0'],
  "easom": ['IY1 Z AH0 M'],
  "eason": ['IY1 Z AH0 N'],
  "east": ['IY1 S T'],
  "east's": ['IY1 S T S'],
  "eastbound": ['IY1 S T B AW2 N D'],
  "eastburn": ['IY1 S T B ER2 N'],
  "eastdil": ['IY1 S T D IH2 L'],
  "eastend": ['IY1 S T EH2 N D'],
  "eastender": ['IY1 S T EH2 N D ER0'],
  "eastenders": ['IY1 S T EH2 N D ER0 Z'],
  "eastep": ['IY1 Z T IH0 P'],
  "easter": ['IY1 S T ER0'],
  "easterbrook": ['IY1 S T ER0 B R UH2 K'],
  "easterday": ['IY1 S T ER0 D EY2'],
  "easterlin": ['AH0 Z T ER1 L IH0 N', 'IY1 S T ER0 L IH0 N'],
  "easterling": ['IY1 S T ER0 L IH0 NG'],
  "easterly": ['IY1 S T ER0 L IY0'],
  "eastern": ['IY1 S T ER0 N'],
  "eastern's": ['IY1 S T ER0 N Z'],
  "eastern-west": ['IY1 S T ER0 N W EH1 S T'],
  "easterner": ['IY1 S T ER0 N ER0'],
  "easterners": ['IY1 S T ER0 N ER0 Z'],
  "easterwood": ['IY1 S T ER0 W UH2 D'],
  "eastes": ['IY1 S T S'],
  "eastgroup": ['IY1 S T G R UW2 P'],
  "eastham": ['IY1 S T AH0 M'],
  "eastin": ['IY1 Z T IH0 N'],
  "eastland": ['IY1 S T L AH0 N D', 'IY1 S T L AE0 N D', 'IY1 S L AH0 N D', 'IY1 S L AE0 N D'],
  "eastland's": ['IY1 S T L AH0 N D Z', 'IY1 S T L AE0 N D Z', 'IY1 S L AH0 N D Z', 'IY1 S L AE0 N D Z'],
  "eastlick": ['IY1 S T L IH2 K'],
  "eastman": ['IY1 S T M AH0 N'],
  "eastmet": ['IY1 S T M EH2 T'],
  "eastmet's": ['IY1 S T M EH2 T S'],
  "easton": ['IY1 S T AH0 N'],
  "eastover": ['IY1 S T OW2 V ER0'],
  "eastpac": ['IY1 S T P AE2 K'],
  "eastpac's": ['IY1 S T P AE2 K S'],
  "eastridge": ['IY1 S T R IH2 JH'],
  "eastward": ['IY1 S T W ER0 D'],
  "eastwick": ['IY1 S T W IH2 K'],
  "eastwind": ['IY1 S T W IH0 N D'],
  "eastwood": ['IY1 S T W UH2 D'],
  "eastwood's": ['IY1 S T W UH2 D Z'],
  "easudes": ['EY2 S UW1 D EY0 S'],
  "easy": ['IY1 Z IY0'],
  "easy's": ['IY1 Z IY0 Z'],
  "easygoing": ['IY1 Z IY0 G OW1 IH0 NG'],
  "eat": ['IY1 T'],
  "eatable": ['IY1 T AH0 B AH0 L'],
  "eaten": ['IY1 T AH0 N'],
  "eater": ['IY1 T ER0'],
  "eateries": ['IY1 T ER0 IY0 Z'],
  "eaters": ['IY1 T ER0 Z'],
  "eatery": ['IY1 T ER0 IY0'],
  "eatherly": ['EH1 DH ER0 L IY0'],
  "eatherton": ['EH0 TH ER1 T AH0 N', 'IY2 TH ER1 T AH0 N'],
  "eating": ['IY1 T IH0 NG'],
  "eatman": ['IY1 T M AH0 N'],
  "eatmon": ['IY1 T M AH0 N'],
  "eaton": ['IY1 T AH0 N'],
  "eaton's": ['IY1 T AH0 N Z'],
  "eats": ['IY1 T S'],
  "eau": ['OW1'],
  "eau-claire": ['OW1 K L EH1 R'],
  "eaux": ['OW1', 'OW1 Z'],
  "eave": ['IY1 V'],
  "eavenson": ['IY1 V IH0 N S AH0 N'],
  "eaves": ['IY1 V Z'],
  "eavesdrop": ['IY1 V Z D R AA2 P'],
  "eavesdropping": ['IY1 V Z D R AA2 P IH0 NG'],
  "eb": ['EH1 B'],
  "eba": ['IY1 B AH0'],
  "eban": ['EH1 B AH0 N', 'IY1 B AH0 N'],
  "ebanks": ['EH1 B AH0 NG K S'],
  "ebano": ['EH0 B AA1 N OW0', 'IY1 B AH0 N OW0'],
  "ebarb": ['EH1 B AA0 R B'],
  "ebasco": ['EH0 B AE1 S K OW0'],
  "ebaugh": ['EH1 B AO0'],
  "ebay": ['IY1 B EY2'],
  "ebay's": ['IY1 B EY2 Z'],
  "ebb": ['EH1 B'],
  "ebba": ['EH1 B AH0'],
  "ebbed": ['EH1 B AH0 D'],
  "ebben": ['EH1 B AH0 N'],
  "ebbers": ['EH1 B ER0 Z'],
  "ebbert": ['EH1 B ER0 T'],
  "ebbing": ['EH1 B IH0 NG'],
  "ebbs": ['EH1 B Z'],
  "ebby": ['EH1 B IY0'],
  "ebel": ['EH1 B AH0 L'],
  "ebeling": ['EH1 B AH0 L IH0 NG'],
  "eben": ['EH1 B AH0 N'],
  "ebeneezer": ['EH1 B AH0 N IY2 Z ER0'],
  "ebenezer": ['EH2 B IH0 N IY1 Z ER0'],
  "eber": ['EH1 B ER0'],
  "eberhard": ['EH1 B ER0 HH AA0 R T'],
  "eberhardt": ['EH1 B ER0 HH AA2 R T'],
  "eberhart": ['EH1 B ER0 HH AA2 R T'],
  "eberl": ['EH1 B ER0 L'],
  "eberle": ['EH1 B ER0 AH0 L'],
  "eberlein": ['EH1 B ER0 L AY2 N'],
  "eberlin": ['EH1 B ER0 L IH0 N'],
  "eberling": ['EH1 B ER0 L IH0 NG'],
  "eberly": ['EH1 B ER0 L IY0'],
  "ebers": ['EH1 B ER0 Z'],
  "ebersol": ['EH1 B ER0 S AA0 L'],
  "ebersold": ['EH1 B ER0 S OW2 L D'],
  "ebersole": ['EH1 B ER0 S OW2 L'],
  "eberstadt": ['EH1 B ER0 S T AE2 T'],
  "ebert": ['EH1 B ER0 T'],
  "eberts": ['EH1 B ER0 T S'],
  "eberwein": ['EH1 B ER0 W AY2 N'],
  "ebey": ['EH1 B IY0'],
  "ebinger": ['EH1 B IH0 NG ER0'],
  "eble": ['EH1 B AH0 L'],
  "eblen": ['EH1 B AH0 L AH0 N'],
  "eblin": ['EH1 B L IH0 N'],
  "ebling": ['EH1 B AH0 L IH0 NG', 'EH1 B L IH0 NG'],
  "ebner": ['EH1 B N ER0'],
  "ebola": ['IY0 B OW1 L AH0'],
  "ebonic": ['IY0 B AO1 N IH0 K'],
  "ebonics": ['IY0 B AO1 N IH0 K S'],
  "ebony": ['EH1 B AH0 N IY0'],
  "ebright": ['IH2 B R AY1 T'],
  "ebro": ['IY1 B R OW2'],
  "ebron": ['EH1 B R AH0 N'],
  "ebullience": ['IH2 B UH1 L Y AH0 N S'],
  "ebullient": ['IH2 B AH1 L Y AH0 N T'],
  "eby": ['IY1 B IY0'],
  "ecad": ['IY1 K AE2 D'],
  "eccentric": ['IH0 K S EH1 N T R IH0 K', 'EH2 K S EH1 N T R IH0 K'],
  "eccentricities": ['EH2 K S EH0 N T R IH1 S IH0 T IY0 Z'],
  "eccentricity": ['EH2 K S AH0 N T R IH1 S AH0 T IY0'],
  "eccentrics": ['IH0 K S EH1 N T R IH0 K S', 'EH2 K S EH1 N T R IH0 K S'],
  "eccles": ['EH1 K AH0 L Z'],
  "ecclesiastic": ['IH0 K L IY2 Z IY0 AE1 S T IH0 K'],
  "ecclesiastical": ['IH0 K L IY2 Z IY0 AE1 S T IH0 K AH0 L'],
  "eccleston": ['EH1 K AH0 L S T AA0 N'],
  "ecevit": ['EH1 S AH0 V IH0 T'],
  "echard": ['EH1 CH ER0 D'],
  "echavarria": ['EH2 CH AH0 V AE1 R IY0 AH0'],
  "echelon": ['EH1 SH AH0 L AA2 N'],
  "echelons": ['EH1 SH AH0 L AA2 N Z'],
  "echemende": ['EH2 K AH0 M EH1 N D'],
  "echenberg": ['EH1 K AH0 N B ER0 G'],
  "echevarria": ['EY0 CH EY0 V AA1 R IY0 AH0'],
  "echeverria": ['EY0 CH EY0 V EH1 R IY0 AH0'],
  "echidna": ['IH0 K IH1 D N AH0'],
  "echinacea": ['EH2 CH AH0 N EY1 S IY2 AH0'],
  "echlin": ['EH1 K L IH0 N'],
  "echlin's": ['EH1 K L IH0 N Z'],
  "echo": ['EH1 K OW0'],
  "echo's": ['EH1 K OW0 Z'],
  "echoed": ['EH1 K OW0 D'],
  "echoes": ['EH1 K OW0 Z'],
  "echograph": ['EH1 K OW0 G R AE2 F'],
  "echography": ['EH0 K AA1 G R AH0 F IY0'],
  "echohawk": ['EH1 K OW0 HH AO2 K'],
  "echohawk's": ['EH1 K OW0 HH AO2 K S'],
  "echoing": ['EH1 K OW0 IH0 NG'],
  "echolocation": ['EH2 K OW0 L OW0 K EY1 SH AH0 N'],
  "echols": ['EH1 K AH0 L Z'],
  "echos": ['EH1 K OW0 Z'],
  "eck": ['EH1 K'],
  "eckard": ['EH1 K ER0 D', 'EH1 K HH AA2 R D'],
  "eckardt": ['EH1 K ER0 T', 'EH1 K HH AA2 R T'],
  "eckardt's": ['EH1 K ER0 T S', 'EH1 K HH AA2 R T S'],
  "eckart": ['EH1 K AA0 R T'],
  "eckberg": ['EH1 K B ER0 G'],
  "eckel": ['EH1 K AH0 L'],
  "eckelberry": ['EH1 K AH0 L B EH2 R IY0'],
  "eckels": ['EH1 K AH0 L Z'],
  "eckenfelder": ['EH1 K AH0 N F EH2 L D ER0'],
  "eckenrod": ['EH1 K IH0 N R AH0 D'],
  "eckenrode": ['EH1 K IH0 N R OW2 D'],
  "eckenroth": ['EH1 K IH0 N R AO0 TH'],
  "ecker": ['EH1 K ER0'],
  "eckerd": ['EH1 K ER0 D'],
  "eckerle": ['EH1 K ER0 AH0 L'],
  "eckerman": ['EH1 K ER0 M AH0 N'],
  "eckersley": ['EH1 K ER0 S L IY0'],
  "eckerson": ['EH1 K ER0 S AH0 N'],
  "eckert": ['EH1 K ER0 T'],
  "eckert's": ['EH1 K ER0 T S'],
  "eckes": ['EH1 K S'],
  "eckford": ['EH1 K F ER0 D'],
  "eckhard": ['EH1 K HH AA2 R D'],
  "eckhardt": ['EH1 K HH AA2 R T'],
  "eckhart": ['EH1 K HH AA2 R T'],
  "eckhoff": ['EH1 K HH AO2 F'],
  "eckl": ['EH1 K AH0 L'],
  "eckland": ['EH1 K L AH0 N D'],
  "eckler": ['EH1 K L ER0'],
  "eckles": ['EH1 K AH0 L Z'],
  "eckley": ['EH1 K L IY0'],
  "ecklund": ['EH1 K L AH0 N D'],
  "eckman": ['EH1 K M AH0 N'],
  "eckmann": ['EH1 K M AH0 N'],
  "eckrich": ['EH1 K R IH0 K'],
  "eckrote": ['EH1 K R AH0 T'],
  "eckroth": ['EH1 K R AO2 TH'],
  "eckstein": ['EH1 K S T IY2 N', 'EH1 K S T AY2 N'],
  "eckstrom": ['EH1 K S T R AH0 M'],
  "eclectic": ['IH0 K L EH1 K T IH0 K'],
  "eclipse": ['IH0 K L IH1 P S', 'AH0 K L IH1 P S', 'IY0 K L IH1 P S'],
  "eclipsed": ['IH0 K L IH1 P S T', 'AH0 K L IH1 P S T', 'IY0 K L IH1 P S T'],
  "eclipses": ['IH0 K L IH1 P S IH0 Z', 'IY0 K L IH1 P S IH0 Z'],
  "eclipsing": ['IH0 K L IH1 P S IH0 NG', 'IY0 K L IH1 P S IH0 NG', 'AH0 K L IH1 P S IH0 NG'],
  "ecliptic": ['IH0 K L IH1 P T IH0 K'],
  "eco": ['IY1 K OW0', 'EH1 K OW0'],
  "ecogen": ['IY1 K OW0 G AH0 N'],
  "ecolab": ['IY1 K OW0 L AE2 B', 'EH1 K OW0 L AE2 B'],
  "ecolaire": ['IY1 K OW0 L EH2 R'],
  "ecole": ['IH0 K OW1 L'],
  "ecological": ['IY0 K AH0 L AA1 JH IH0 K AH0 L', 'EH0 K AH0 L AA1 JH IH0 K AH0 L'],
  "ecologically": ['IY0 K AH0 L AA1 JH IH0 K L IY0', 'EH0 K AH0 L AA1 JH IH0 K L IY0'],
  "ecologist": ['IH0 K AA1 L AH0 JH IH0 S T', 'IY0 K AA1 L AH0 JH IH0 S T'],
  "ecologists": ['IH0 K AA1 L AH0 JH IH0 S T S', 'IY0 K AA1 L AH0 JH IH0 S T S', 'IH0 K AA1 L AH0 JH IH0 S', 'IY0 K AA1 L AH0 JH IH0 S'],
  "ecology": ['IH0 K AA1 L AH0 JH IY0', 'IY0 K AA1 L AH0 JH IY0'],
  "econ": ['IY1 K AA2 N'],
  "econocom": ['IY0 K AA1 N OW0 K AA2 M'],
  "econoline": ['IY0 K AA1 N OW0 L AY2 N'],
  "econometric": ['IH0 K AA2 N AH0 M EH1 T R IH0 K'],
  "econometrics": ['IH0 K AA2 N AH0 M EH1 T R IH0 K S'],
  "economic": ['EH2 K AH0 N AA1 M IH0 K', 'IY2 K AH0 N AA1 M IH0 K'],
  "economical": ['EH2 K AH0 N AA1 M IH0 K AH0 L', 'IY2 K AH0 N AA1 M IH0 K AH0 L'],
  "economically": ['EH2 K AH0 N AA1 M IH0 K L IY0', 'IY2 K AH0 N AA1 M IH0 K L IY0'],
  "economico": ['IY0 K AA2 N AH0 M IY1 K OW0'],
  "economics": ['EH2 K AH0 N AA1 M IH0 K S', 'IY2 K AH0 N AA1 M IH0 K S'],
  "economies": ['IH0 K AA1 N AH0 M IY0 Z', 'IY0 K AA1 N AH0 M IY0 Z'],
  "economist": ['IH0 K AA1 N AH0 M IH0 S T', 'IY0 K AA1 N AH0 M IH0 S T'],
  "economist's": ['IH0 K AA1 N AH0 M IH0 S T S', 'IY0 K AA1 N AH0 M IH0 S T S', 'IH0 K AA1 N AH0 M IH0 S', 'IY0 K AA1 N AH0 M IH0 S'],
  "economists": ['IH0 K AA1 N AH0 M IH0 S T S', 'IH0 K AA1 N AH0 M IH0 S', 'IY0 K AA1 N AH0 M IH0 S'],
  "economists'": ['IH0 K AA1 N AH0 M IH0 S T S', 'IY0 K AA1 N AH0 M IH0 S T S'],
  "economize": ['IH0 K AA1 N AH0 M AY2 Z', 'IY0 K AA1 N AH0 M AY2 Z'],
  "economizes": ['IH0 K AA1 N AH0 M AY2 Z AH0 Z', 'IY0 K AA1 N AH0 M AY2 Z AH0 Z'],
  "economizing": ['IH0 K AA1 N AH0 M AY2 Z IH0 NG', 'IY0 K AA1 N AH0 M AY2 Z IH0 NG'],
  "economos": ['EH0 K AH0 N OW1 M OW0 Z'],
  "economou": ['EH0 K OW0 N OW1 M UW0'],
  "economy": ['IH0 K AA1 N AH0 M IY0', 'IY0 K AA1 N AH0 M IY0'],
  "economy's": ['IH0 K AA1 N AH0 M IY0 Z', 'IY0 K AA1 N AH0 M IY0 Z'],
  "econships": ['IY1 K AA2 N SH IH2 P S'],
  "ecosystem": ['IY1 K OW0 S IH2 S T AH0 M'],
  "ecosystems": ['IY1 K OW0 S IH2 S T AH0 M Z'],
  "ecru": ['EH1 K R UW0'],
  "ecstasy": ['EH1 K S T AH0 S IY0'],
  "ecstatic": ['EH0 K S T AE1 T IH0 K'],
  "ecstatically": ['EH0 K S T AE1 T IH0 K L IY0'],
  "ecton": ['EH1 K T AH0 N'],
  "ector": ['EH1 K T ER0'],
  "ecuador": ['EH1 K W AH0 D AO2 R'],
  "ecuador's": ['EH1 K W AH0 D AO2 R Z'],
  "ecuadoran": ['EH2 K W AH0 D AO1 R AH0 N'],
  "ecuadorean": ['EH2 K W AH0 D AO1 R IY0 AH0 N'],
  "ecuadorian": ['EH2 K W AH0 D AO1 R IY0 AH0 N'],
  "ecumena": ['EH2 K Y UW0 M IY1 N AH0'],
  "ecumenical": ['EH2 K Y UW0 M EH1 N IH0 K AH0 L'],
  "eczema": ['EH1 K S AH0 M AH0'],
  "ed": ['EH1 D'],
  "ed's": ['EH1 D Z'],
  "eda": ['IY1 D AH0'],
  "edam": ['IY1 D AH0 M'],
  "edan": ['IY1 D AH0 N'],
  "edana": ['EH0 D AE1 N AH0'],
  "edberg": ['EH1 D B ER0 G'],
  "edbert": ['EH1 D B ER0 T'],
  "eddie": ['EH1 D IY0'],
  "eddie's": ['EH1 D IY2 Z'],
  "eddies": ['EH1 D IY0 Z'],
  "eddinger": ['EH1 D IH0 NG ER0'],
  "eddings": ['EH1 D IH0 NG Z'],
  "eddington": ['EH1 D IH0 NG T AH0 N'],
  "eddins": ['EH1 D IH0 N Z'],
  "eddleman": ['EH1 D AH0 L M AH0 N'],
  "eddlemon": ['EH1 D AH0 L M AA0 N'],
  "edds": ['EH1 D Z'],
  "eddy": ['EH1 D IY0'],
  "ede": ['IY1 D'],
  "edel": ['EH1 D AH0 L'],
  "edelen": ['EH1 D AH0 L AH0 N'],
  "edeline": ['EH1 D IH0 L AY0 N'],
  "edell": ['IH0 D EH1 L'],
  "edelman": ['EH1 D AH0 L M AH0 N', 'EY1 D AH0 L M AH0 N'],
  "edelman's": ['EH1 D AH0 L M AH0 N Z', 'EY1 D AH0 L M AH0 N Z'],
  "edelmann": ['EH1 D AH0 L M AH0 N'],
  "edelmar": ['EH1 D IH0 L M ER0'],
  "edelson": ['EH1 D IH0 L S AH0 N'],
  "edelstein": ['EH1 D AH0 L S T AY2 N', 'EH1 D AH0 L S T IY2 N'],
  "edelweiss": ['EY1 D AH0 L V AY2 S'],
  "edema": ['IH0 D IY1 M AH0'],
  "eden": ['IY1 D AH0 N'],
  "edenfield": ['EH1 D AH0 N F IY2 L D'],
  "edens": ['IY1 D AH0 N Z'],
  "edenton": ['EH1 D AH0 N T AH0 N'],
  "eder": ['EH1 D ER0'],
  "ederer": ['EH1 D ER0 ER0'],
  "edes": ['IY1 D Z'],
  "edgar": ['EH1 D G ER0'],
  "edgar's": ['EH1 D G ER0 Z'],
  "edgcomb": ['EH1 JH K AH0 M'],
  "edge": ['EH1 JH'],
  "edgecomb": ['EH1 JH K AO0 M'],
  "edgecombe": ['EH1 JH K OW0 M'],
  "edged": ['EH1 JH D'],
  "edgell": ['EH1 JH AH0 L'],
  "edgemon": ['EH1 JH M AH0 N'],
  "edger": ['EH1 JH ER0'],
  "edgerly": ['EH1 JH ER0 L IY0'],
  "edgerton": ['EH1 JH ER0 T AH0 N'],
  "edges": ['EH1 JH AH0 Z', 'EH1 JH IH0 Z'],
  "edgett": ['EH1 JH IH0 T'],
  "edgeway": ['EH1 JH W EY2'],
  "edgeways": ['EH1 JH W EY2 Z'],
  "edgewise": ['EH1 JH W AY2 Z'],
  "edgewood": ['EH1 JH W UH2 D'],
  "edgeworth": ['EH1 JH W ER0 TH'],
  "edgin": ['EH1 JH IH0 N'],
  "edginess": ['EH1 JH IY0 N AH0 S'],
  "edging": ['EH1 JH IH0 NG'],
  "edgington": ['EH1 JH IH0 NG T AH0 N'],
  "edgington's": ['EH1 JH IH0 NG T AH0 N Z'],
  "edgley": ['EH1 JH L IY0'],
  "edgmon": ['EH1 JH M AH0 N'],
  "edgren": ['EH1 D G R EH0 N'],
  "edgy": ['EH1 JH IY0'],
  "edi": ['IY1 D IY0'],
  "edible": ['EH1 D AH0 B AH0 L'],
  "edibles": ['EH1 D AH0 B AH0 L Z'],
  "edick": ['EH1 D IH0 K'],
  "edict": ['IY1 D IH0 K T'],
  "edicts": ['IY1 D IH0 K T S'],
  "edie": ['EH1 D IY0'],
  "edification": ['EH2 D AH0 F AH0 K EY1 SH AH0 N'],
  "edifice": ['EH1 D AH0 F AH0 S'],
  "edify": ['EH1 D AH0 F AY2'],
  "edifying": ['EH1 D AH0 F AY2 IH0 NG'],
  "ediger": ['EH1 D IH0 G ER0'],
  "edin": ['EH1 D IH0 N'],
  "edina": ['AH0 D IY1 N AH0'],
  "edinboro": ['EH1 D AH0 N B ER0 OW0'],
  "edinburgh": ['EH1 D AH0 N B ER0 OW0'],
  "edinger": ['EH1 D IH0 NG ER0'],
  "edington": ['EH1 D IH0 NG T AH0 N'],
  "edison": ['EH1 D IH0 S AH0 N'],
  "edison's": ['EH1 D IH0 S AH0 N Z'],
  "edisons": ['EH1 D IH0 S AH0 N Z'],
  "edisto": ['EH1 D IH0 S T OW0'],
  "edit": ['EH1 D AH0 T'],
  "edited": ['EH1 D AH0 T AH0 D', 'EH1 D IH0 T IH0 D'],
  "edith": ['IY1 D IH0 TH'],
  "editha": ['EH1 D IH0 DH AH0'],
  "edithe": ['EH1 D IH0 DH'],
  "editing": ['EH1 D AH0 T IH0 NG', 'EH1 D IH0 T IH0 NG'],
  "edition": ['AH0 D IH1 SH AH0 N', 'IH0 D IH1 SH AH0 N'],
  "edition's": ['IH0 D IH1 SH AH0 N Z'],
  "editions": ['IH0 D IH1 SH AH0 N Z'],
  "editor": ['EH1 D AH0 T ER0', 'EH1 D IH0 T ER0'],
  "editor's": ['EH1 D IH0 T ER0 Z'],
  "editorial": ['EH2 D AH0 T AO1 R IY0 AH0 L'],
  "editorial's": ['EH2 D AH0 T AO1 R IY0 AH0 L Z'],
  "editorialist": ['EH2 D AH0 T AO1 R IY0 AH0 L IH0 S T'],
  "editorialists": ['EH2 D AH0 T AO1 R IY0 AH0 L IH0 S T S'],
  "editorialize": ['EH2 D AH0 T AO1 R IY0 AH0 L AY2 Z'],
  "editorialized": ['EH2 D AH0 T AO1 R IY0 AH0 L AY2 Z D'],
  "editorializing": ['EH2 D AH0 T AO1 R IY0 AH0 L AY2 Z IH0 NG'],
  "editorially": ['EH2 D AH0 T AO1 R IY0 AH0 L IY0'],
  "editorials": ['EH2 D AH0 T AO1 R IY0 AH0 L Z'],
  "editors": ['EH1 D IH0 T ER0 Z'],
  "editors'": ['EH1 D IH0 T ER0 Z'],
  "editorship": ['EH1 D AH0 T ER0 SH IH2 P'],
  "edits": ['EH1 D IH0 T S'],
  "ediva": ['EH0 D IY1 V AH0'],
  "edival": ['EH0 D IY1 V AH0 L', 'EH1 D IY0 V AE2 L'],
  "edizione": ['EH0 D IY2 Z IY0 OW1 N IY0'],
  "edleman": ['EH1 D AH0 L M AH0 N'],
  "edler": ['EH1 D L ER0'],
  "edley": ['EH1 D L IY0'],
  "edlin": ['EH1 D L IH0 N'],
  "edling": ['EH1 D L IH0 NG'],
  "edlund": ['EH1 D L AH0 N D'],
  "edlyn": ['EH1 D L IH0 N'],
  "edman": ['EH1 D M AH0 N'],
  "edmands": ['EH1 D M AH0 N D Z'],
  "edmar": ['EH1 D M AA0 R'],
  "edmark": ['EH1 D M AA0 R K'],
  "edminster": ['IH0 D M IH1 N S T ER0'],
  "edmison": ['EH1 D M IH0 S AH0 N'],
  "edmisten": ['EH0 D M IH1 S AH0 N'],
  "edmister": ['EH1 D M IH0 S T ER0'],
  "edmiston": ['EH1 D M IH0 S T AA0 N'],
  "edmond": ['EH1 D M AH0 N D'],
  "edmonda": ['EH2 D M AA1 N D AH0'],
  "edmonds": ['EH1 D M AH0 N D Z'],
  "edmondson": ['EH1 D M AH0 N D S AH0 N'],
  "edmonson": ['EH1 D M AH0 N S AH0 N'],
  "edmonston": ['IH0 D M AA1 N S T AH0 N'],
  "edmonton": ['EH1 D M AH0 N T AH0 N'],
  "edmund": ['EH1 D M AH0 N D'],
  "edmunda": ['EH2 D M AH1 N D AH0'],
  "edmundo": ['EH2 D M AH1 N D OW0'],
  "edmunds": ['EH1 D M AH0 N D Z'],
  "edmundson": ['EH1 D M AH0 N D S AH0 N'],
  "edna": ['EH1 D N AH0'],
  "edney": ['EH1 D N IY0'],
  "edo": ['IY1 D OW0'],
  "edoardo": ['EH2 D W AA1 R D OW0'],
  "edolf": ['EH1 D OW0 L F'],
  "edouard": ['EH1 D UW0 AA0 R D'],
  "edper": ['EH1 D P ER0'],
  "edquist": ['EH1 D K W IH2 S T'],
  "edra": ['EH1 D R AH0'],
  "edrea": ['EH1 D R IY0 AH0'],
  "edric": ['EH1 D R IH0 K'],
  "edrington": ['EH1 D ER0 IH0 NG T AH0 N'],
  "edris": ['IH0 D R IY1 S'],
  "edsall": ['IH0 D S AO1 L'],
  "edsel": ['EH1 D S AH0 L'],
  "edson": ['EH1 D S AH0 N'],
  "edstrom": ['EH1 D S T R AH0 M'],
  "edu": ['EH1 D Y UW0', 'IY1 D IY1 Y UW1'],
  "eduard": ['EH1 D W ER0 D'],
  "eduardo": ['EH0 D W AA1 R D OW0'],
  "educate": ['EH1 JH AH0 K EY2 T', 'EH1 JH Y UW0 K EY2 T'],
  "educated": ['EH1 JH AH0 K EY2 T IH0 D', 'EH1 JH Y UW0 K EY2 T IH0 D'],
  "educates": ['EH1 JH AH0 K EY2 T S', 'EH1 JH Y AH0 K EY2 T S', 'EH1 JH UW0 K EY2 T S', 'EH1 JH Y UW0 K EY2 T S'],
  "educating": ['EH1 JH AH0 K EY2 T IH0 NG', 'EH1 JH Y UW0 K EY2 T IH0 NG'],
  "education": ['EH2 JH AH0 K EY1 SH AH0 N', 'EH2 JH Y UW0 K EY1 SH AH0 N'],
  "education's": ['EH2 JH AH0 K EY1 SH AH0 N Z', 'EH2 JH Y UW0 K EY1 SH AH0 N Z'],
  "educational": ['EH2 JH AH0 K EY1 SH AH0 N AH0 L', 'EH2 JH Y UW0 K EY1 SH AH0 N AH0 L'],
  "educationally": ['EH2 JH AH0 K EY1 SH AH0 N AH0 L IY0', 'EH2 JH AH0 K EY1 SH N AH0 L IY0', 'EH2 JH Y UW0 K EY1 SH AH0 N AH0 L IY0', 'EH2 JH Y UW0 K EY1 SH N AH0 L IY0'],
  "educations": ['EH2 JH AH0 K EY1 SH AH0 N Z', 'EH2 JH Y UW0 K EY1 SH AH0 N Z'],
  "educative": ['EH2 JH IH0 K EY1 T IH0 V'],
  "educator": ['EH1 JH AH0 K EY2 T ER0', 'EH1 JH Y UW0 K EY2 T ER0'],
  "educators": ['EH1 JH AH0 K EY2 T ER0 Z', 'EH1 JH Y UW0 K EY2 T ER0 Z'],
  "edwald": ['IH0 D W AO1 L D'],
  "edward": ['EH1 D W ER0 D'],
  "edward's": ['EH1 D W ER0 D Z'],
  "edwardian": ['EH0 D W AO1 R D IY0 AH0 N'],
  "edwardine": ['IH0 D W AO1 R D AY0 N'],
  "edwards": ['EH1 D W ER0 D Z'],
  "edwards'": ['EH1 D W ER0 D Z'],
  "edwards's": ['EH1 D W ER0 D Z IH0 Z'],
  "edwardson": ['EH1 D W AO0 R D S AH0 N'],
  "edwin": ['EH1 D W AH0 N', 'EH1 D W IH0 N'],
  "edwina": ['EH0 D W IY1 N AH0'],
  "edye": ['EH1 D IY0', 'IY1 D IY0'],
  "edyth": ['EH1 D IH0 TH'],
  "edythe": ['EH1 D AY0 DH'],
  "edzard": ['EH1 D Z ER0 D'],
  "ee": ['IY1'],
  "eeg": ['IY1 G'],
  "eel": ['IY1 L'],
  "eelam": ['IY1 L AE0 M'],
  "eelgrass": ['IY1 L G R AE2 S'],
  "eellike": ['IY1 L L AY2 K'],
  "eelpout": ['IY1 L P AW2 T'],
  "eelpouts": ['IY1 L P AW2 T S'],
  "eels": ['IY1 L Z'],
  "eeo": ['IY1 IY1 OW1'],
  "eerie": ['IH1 R IY0'],
  "eerily": ['IH1 R AH0 L IY0'],
  "efaw": ['EH1 F AO0'],
  "efecto": ['IH0 F EH1 K T OW0'],
  "efface": ['IH0 F EY1 S'],
  "effacing": ['IH0 F EY1 S IH0 NG'],
  "effect": ['IH0 F EH1 K T', 'IY1 F EH0 K T', 'AH0 F EH1 K T'],
  "effected": ['IH0 F EH1 K T IH0 D', 'IY1 F EH0 K T AH0 D', 'IY1 F EH0 K T IH0 D'],
  "effecting": ['IH0 F EH1 K T IH0 NG', 'IY1 F EH0 K T IH0 NG'],
  "effective": ['IH0 F EH1 K T IH0 V', 'IY1 F EH0 K T IH0 V'],
  "effectively": ['IH0 F EH1 K T IH0 V L IY0', 'IY1 F EH0 K T IH0 V L IY0'],
  "effectiveness": ['IH0 F EH1 K T IH0 V N AH0 S', 'IY1 F EH0 K T IH0 V N AH0 S'],
  "effector": ['IH0 F EH1 K T ER0'],
  "effectors": ['IH0 F EH1 K T ER0 Z'],
  "effects": ['IH0 F EH1 K T S', 'IY1 F EH0 K T S'],
  "effectuate": ['IH0 F EH1 K CH UW0 EY2 T'],
  "effeminate": ['IY0 F EH1 M IH0 N AH0 T'],
  "efferent": ['EH1 F ER0 AH0 N T'],
  "efferson": ['EH1 F ER0 S AH0 N'],
  "effertz": ['EH1 F ER0 T S'],
  "effervescent": ['EH2 F ER0 V EH1 S AH0 N T'],
  "effete": ['EH0 F IY1 T'],
  "efficacious": ['EH2 F AH0 K EY1 SH AH0 S'],
  "efficacy": ['EH1 F IH0 K AE2 S IY0'],
  "efficiencies": ['IH0 F IH1 SH AH0 N S IY0 Z'],
  "efficiency": ['IH0 F IH1 SH AH0 N S IY0'],
  "efficient": ['IH0 F IH1 SH AH0 N T'],
  "efficiently": ['IH0 F IH1 SH AH0 N T L IY0'],
  "effie": ['EH1 F IY0'],
  "effigy": ['EH1 F IH0 JH IY0'],
  "effinger": ['EH1 F IH0 NG ER0'],
  "effingham": ['EH1 F IH0 NG AH0 M', 'EH1 F IH0 NG HH AH0 M'],
  "effler": ['EH1 F L ER0'],
  "effluence": ['EH1 F L UW0 AH0 N S'],
  "effluent": ['EH1 F L UW0 AH0 N T'],
  "efflux": ['EH1 F L AH0 K S'],
  "effort": ['EH1 F ER0 T'],
  "effortless": ['EH1 F ER0 T L AH0 S'],
  "effortlessly": ['EH1 F ER0 T L AH0 S L IY0'],
  "efforts": ['EH1 F ER0 T S'],
  "effron": ['EH1 F R AH0 N'],
  "effrontery": ['IH0 F R AH1 N T ER0 IY0'],
  "effusive": ['EH1 F Y UW0 S IH0 V'],
  "effusively": ['IH0 F Y UW1 S IH0 V L IY0'],
  "effy": ['EH1 F IY0'],
  "efird": ['EH1 F ER0 D'],
  "efron": ['EH1 F R AH0 N'],
  "efta": ['EH1 F T AH0'],
  "egaldey": ['IY1 G AH0 L D EY0'],
  "egalitarian": ['IH0 G AE2 L AH0 T EH1 R IY0 AH0 N'],
  "egalitarianism": ['IY0 G AE2 L AH0 T EH1 R IY0 AH0 N IH2 Z AH0 M'],
  "egan": ['IY1 G AH0 N'],
  "egbert": ['EH1 G B ER0 T'],
  "egberta": ['EY0 G B EH1 R T AH0'],
  "egbertina": ['EH0 G B ER0 T IY1 N AH0'],
  "egbertine": ['EH1 G B ER0 T IY2 N'],
  "egberts": ['EH1 G B ER0 T S'],
  "ege": ['IY1 JH'],
  "egeland": ['EH1 G IH0 L AH0 N D'],
  "egeler": ['EH1 G AH0 L ER0'],
  "egelhoff": ['EH1 G IH0 L HH AO0 F'],
  "egelston": ['EH1 G IH0 L S T AH0 N'],
  "egelton": ['EH1 G AH0 L T AH0 N'],
  "eger": ['IY1 G ER0'],
  "egerer": ['EH1 G ER0 ER0'],
  "egert": ['EH1 G ER0 T'],
  "egerton": ['EH1 G ER0 T AH0 N'],
  "egg": ['EH1 G'],
  "eggcup": ['EH1 G K AH2 P'],
  "eggcups": ['EH1 G K AH2 P S'],
  "egge": ['EH1 G'],
  "eggebrecht": ['EH1 G IH0 B R IH0 K T'],
  "egged": ['EH1 G D'],
  "eggemeyer": ['EH1 G IH0 M AY2 ER0'],
  "eggen": ['EH1 G AH0 N'],
  "eggenberger": ['EH1 G AH0 N B ER0 G ER0'],
  "egger": ['EH1 G ER0'],
  "eggers": ['EH1 G ER0 Z'],
  "eggert": ['EH1 G ER0 T'],
  "egghead": ['EH1 G HH EH2 D'],
  "egghead's": ['EH1 G HH EH2 D Z'],
  "eggheads": ['EH1 G HH EH2 D Z'],
  "egging": ['EH1 G IH0 NG'],
  "eggleston": ['EH1 G AH0 L S T AH0 N'],
  "eggleton": ['EH1 G AH0 L T AA0 N'],
  "eggnog": ['EH1 G N AO2 G'],
  "eggnogs": ['EH1 G N AO2 G Z'],
  "eggplant": ['EH1 G P L AE2 N T'],
  "eggplants": ['EH1 G P L AE2 N T S'],
  "eggs": ['EH1 G Z'],
  "eggshape": ['EH1 G SH EY2 P'],
  "eggshaped": ['EH1 G SH EY2 P T'],
  "eggshell": ['EH1 G SH EH2 L'],
  "eggshells": ['EH1 G SH EH2 L Z'],
  "eggum": ['EH1 G AH0 M'],
  "egland": ['EH1 G L AH0 N D'],
  "eglantine": ['EH1 G L AH0 N T AY2 N'],
  "egle": ['EH1 G AH0 L'],
  "egler": ['EH1 G L ER0'],
  "egleston": ['EH1 G AH0 L S T AA0 N'],
  "egley": ['EH1 G L IY0'],
  "egli": ['EH1 G L IY0'],
  "eglin": ['EH1 G L IH0 N'],
  "eglise": ['EH2 G L IY1 S'],
  "egloff": ['EH1 G L AO0 F'],
  "egly": ['EH1 G L IY0'],
  "egner": ['EH1 G N ER0'],
  "egnew": ['IH0 G N UW1'],
  "egnor": ['EH1 G N ER0'],
  "ego": ['IY1 G OW0'],
  "egocentric": ['IY2 G OW0 S EH1 N T R IH0 K'],
  "egoism": ['IY1 G OW0 IH2 Z AH0 M'],
  "egolf": ['EH1 G OW0 L F'],
  "egomaniac": ['IY2 G OW0 M EY1 N IY0 AE0 K'],
  "egon": ['IY1 G AH0 N', 'IY1 G AA2 N'],
  "egos": ['IY1 G OW0 Z'],
  "egotism": ['IY1 G AH0 T IH2 Z AH0 M'],
  "egotist": ['IY1 G AH0 T IH0 S T'],
  "egotistical": ['IY2 G AH0 T IH1 S T IH0 K AH0 L'],
  "egregious": ['IH0 G R IY1 JH AH0 S'],
  "egregiously": ['IH0 G R IY1 JH AH0 S L IY0'],
  "egress": ['IH0 G R EH1 S'],
  "egret": ['EH1 G R AH0 T'],
  "egypt": ['IY1 JH AH0 P T', 'IY1 JH IH0 P T'],
  "egypt's": ['IY1 JH AH0 P T S', 'IY1 JH IH0 P T S'],
  "egyptair": ['IY1 JH IH0 P T EH2 R'],
  "egyptian": ['IH0 JH IH1 P SH AH0 N'],
  "egyptians": ['IH0 JH IH1 P SH AH0 N Z'],
  "egyptology": ['IY2 JH AH0 P T AA1 L AH0 JH IY0'],
  "eh": ['EH1'],
  "ehinger": ['EH1 HH IH0 N JH ER0'],
  "ehle": ['EH1 L'],
  "ehlen": ['EH1 L AH0 N'],
  "ehler": ['EH1 L ER0'],
  "ehlers": ['EH1 L ER0 Z'],
  "ehlert": ['EH1 L ER0 T'],
  "ehlinger": ['EH1 L IH0 NG ER0'],
  "ehlke": ['EH1 L K'],
  "ehlmann": ['EH1 L M AH0 N'],
  "ehly": ['EH1 L IY0'],
  "ehman": ['EH1 M AH0 N'],
  "ehmann": ['EH1 M AH0 N'],
  "ehmen": ['EH1 M EH0 N'],
  "ehmke": ['EH1 M K IY0'],
  "ehren": ['EH1 R AH0 N'],
  "ehrenberg": ['EH1 R AH0 N B ER0 G'],
  "ehrenfeld": ['EH1 R IH0 N F EH0 L D'],
  "ehrenhalt": ['EH1 R AH0 N HH AO2 L T'],
  "ehrenkrantz": ['EH1 R AH0 N K R AE2 N T S'],
  "ehrenreich": ['EH1 R IH0 N R AY0 K', 'EH1 R AH0 N R IH2 CH'],
  "ehresman": ['EH1 R IH0 S M AH0 N'],
  "ehret": ['EH1 R IH0 T'],
  "ehrhard": ['EH1 R HH ER0 D'],
  "ehrhardt": ['EH1 R HH AA0 R T'],
  "ehrhart": ['EH1 R HH AA0 R T'],
  "ehrich": ['EH1 R IH0 K'],
  "ehrig": ['EH1 R IH0 G'],
  "ehrke": ['EH1 R K'],
  "ehrler": ['EH1 R L ER0'],
  "ehrlich": ['ER1 L IH0 K'],
  "ehrlich's": ['ER1 L IH0 K S'],
  "ehrlichman": ['ER1 L IH0 K M AH0 N'],
  "ehrman": ['EH1 R M AH0 N'],
  "ehrmann": ['EH1 R M AH0 N'],
  "ehrsam": ['EH1 R S AH0 M'],
  "ehud": ['EH0 HH AH1 D'],
  "eiben": ['AY1 B AH0 N'],
  "eich": ['AY1 K'],
  "eichberg": ['AY1 K B ER0 G'],
  "eichel": ['AY1 K AH0 L'],
  "eichelberger": ['AY1 K AH0 L B ER0 G ER0'],
  "eichen": ['AY1 K AH0 N'],
  "eichenbaum": ['AY1 K AH0 N B AW2 M'],
  "eichenberg": ['AY1 K AH0 N B ER0 G'],
  "eichenberger": ['AY1 K AH0 N B ER0 G ER0'],
  "eichenlaub": ['AY1 K IH0 N L AW0 B'],
  "eicher": ['AY1 K ER0'],
  "eichholz": ['AY1 K HH OW0 L Z'],
  "eichhorn": ['AY1 K HH ER0 N'],
  "eichhorst": ['AY1 K HH AO0 R S T'],
  "eichinger": ['AY1 K IH0 N JH ER0'],
  "eichler": ['AY1 K AH0 L ER0', 'AY1 K L ER0'],
  "eichman": ['AY1 K M AH0 N'],
  "eichmann": ['AY1 K M AH0 N'],
  "eichner": ['AY1 K N ER0'],
  "eicholtz": ['AY1 K OW0 L T S'],
  "eichorn": ['AY1 K AO0 R N'],
  "eichorst": ['AY1 K HH AO0 R S T'],
  "eichstadt": ['AY1 K S T AE0 T'],
  "eichstaedt": ['AY1 K S T AE0 T'],
  "eick": ['AY1 K'],
  "eickhoff": ['AY1 K HH AO2 F'],
  "eickholt": ['AY1 K HH OW2 L T'],
  "eickmeyer": ['AY1 K M AY0 ER0'],
  "eid": ['AY1 D'],
  "eide": ['AY1 D'],
  "eidem": ['AY1 D IH0 M'],
  "eiden": ['AY1 D AH0 N'],
  "eidson": ['IY1 D S AH0 N'],
  "eiermann": ['AY1 R M AH0 N'],
  "eifert": ['AY1 F ER0 T'],
  "eiffel": ['AY1 F AH0 L'],
  "eifler": ['AY1 F AH0 L ER0', 'AY1 F L ER0'],
  "eigen": ['AY1 G AH0 N'],
  "eigenvalue": ['AY1 G AH0 N V AE2 L Y UW0'],
  "eigenvalues": ['AY1 G AH0 N V AE2 L Y UW0 Z'],
  "eighmey": ['EY1 M IY0'],
  "eighmy": ['EY1 G M IY0'],
  "eight": ['EY1 T'],
  "eight's": ['EY1 T S'],
  "eighteen": ['EY0 T IY1 N', 'EY1 T IY1 N'],
  "eighteen's": ['EY0 T IY1 N Z'],
  "eighteens": ['EY0 T IY1 N Z'],
  "eighteenth": ['EY0 T IY1 N TH', 'EY1 T IY1 N TH'],
  "eightfold": ['EY1 T F OW2 L D'],
  "eighth": ['EY1 T TH', 'EY1 TH'],
  "eighths": ['EY1 T TH S'],
  "eighties": ['EY1 T IY0 Z'],
  "eightieth": ['EY1 T IY0 IH0 TH'],
  "eights": ['EY1 T S'],
  "eighty": ['EY1 T IY0'],
  "eighty's": ['EY1 T IY0 Z'],
  "eigner": ['AY1 G N ER0'],
  "eiichi": ['EY0 IY1 CH IY0'],
  "eiji": ['EY1 JH IY0'],
  "eike": ['AY1 K'],
  "eikenberry": ['IY1 K AH0 N B EH0 R IY0'],
  "eilan": ['AY1 L AH0 N'],
  "eiland": ['AY1 L AH0 N D'],
  "eileen": ['AY0 L IY1 N'],
  "eiler": ['AY1 L ER0'],
  "eilerman": ['AY1 L ER0 M AH0 N'],
  "eilers": ['AY1 L ER0 Z'],
  "eilert": ['AY1 L ER0 T'],
  "eiley": ['AY1 L IY0'],
  "eilts": ['AY1 L T S'],
  "eimer": ['AY1 M ER0'],
  "eimers": ['AY1 M ER0 Z'],
  "ein": ['AY1 N'],
  "einar": ['AY1 N ER0'],
  "einbender": ['AY1 N B EH2 N D ER0'],
  "eindhoven": ['AY1 N D HH OW2 V AH0 N'],
  "einhorn": ['AY1 N HH AO2 R N'],
  "einon": ['AY1 N AO0 N'],
  "einon's": ['AY1 N AO0 N Z'],
  "einspahr": ['AY1 N S P AA0 R'],
  "einstein": ['AY1 N S T AY0 N'],
  "einstein's": ['AY1 N S T AY0 N Z'],
  "eir": ['AY1 R'],
  "eirena": ['ER0 EY1 N AH0'],
  "eirich": ['AY1 R IH0 K'],
  "eis": ['AY1 Z'],
  "eisa": ['EY1 S AH0'],
  "eisai": ['AY1 S AY2'],
  "eisaman": ['AY1 S AH0 M AH0 N'],
  "eischeid": ['AY1 SH AY0 D'],
  "eischen": ['AY1 SH AH0 N'],
  "eischens": ['AY1 SH AH0 N Z'],
  "eisel": ['AY1 S AH0 L'],
  "eisele": ['AY1 S AH0 L'],
  "eiseman": ['AY1 S M AH0 N'],
  "eisemann": ['AY1 S M AH0 N'],
  "eisen": ['AY1 S AH0 N'],
  "eisenach": ['AY1 Z AH0 N AA2 K'],
  "eisenbach": ['AY1 Z AH0 N B AA0 K'],
  "eisenbarth": ['AY1 Z AH0 N B AA0 R TH'],
  "eisenbeis": ['AY1 Z AH0 N B AY0 S'],
  "eisenberg": ['AY1 Z AH0 N B ER0 G'],
  "eisenberger": ['AY1 Z AH0 N B ER0 G ER0'],
  "eisenbraun": ['AY1 Z AH0 N B R AW0 N'],
  "eisenhardt": ['AY1 Z AH0 N HH AA0 R T'],
  "eisenhart": ['AY1 Z AH0 N HH AA0 R T'],
  "eisenhauer": ['AY1 Z AH0 N HH AW0 ER0'],
  "eisenhour": ['AY1 Z AH0 N AW0 R'],
  "eisenhower": ['AY1 Z AH0 N HH AW2 ER0'],
  "eisenhower's": ['AY1 Z AH0 N HH AW2 ER0 Z'],
  "eisenhowers": ['AY1 Z AH0 N HH AW2 ER0 Z'],
  "eisenhut": ['AY1 Z AH0 N HH AH0 T'],
  "eisenhuth": ['AY1 Z AH0 N HH UW0 TH'],
  "eisenman": ['AY1 Z AH0 N M AH0 N'],
  "eisenmann": ['AY1 Z AH0 N M AH0 N'],
  "eisenmenger": ['AY1 Z AH0 N M EH0 NG ER0'],
  "eisenstadt": ['AY1 Z AH0 N S T AE0 T'],
  "eisenstein": ['AY1 Z AH0 N S T AY0 N', 'AY1 Z AH0 N S T IY0 N'],
  "eiserman": ['AY1 Z ER0 M AH0 N'],
  "eisert": ['AY1 S ER0 T'],
  "eishi": ['EY1 SH IY0'],
  "eisin": ['AY1 S AH0 N'],
  "eisinger": ['AY1 S IH0 N JH ER0'],
  "eisler": ['AY1 S L ER0'],
  "eisman": ['AY1 S M AH0 N'],
  "eisner": ['AY1 S N ER0'],
  "eisner's": ['AY1 S N ER0 Z'],
  "eison": ['AY1 Z AH0 N'],
  "eissler": ['AY1 S AH0 L ER0', 'AY1 S L ER0'],
  "eiszner": ['AY1 Z N ER0'],
  "eitel": ['AY1 T AH0 L'],
  "either": ['IY1 DH ER0', 'AY1 DH ER0'],
  "eitzen": ['AY1 T Z AH0 N'],
  "eizenstat": ['AY1 Z AH0 N S T AE2 T'],
  "ejaculate": ['IH0 JH AE1 K Y UW0 L EY2 T'],
  "ejaculation": ['IY0 JH AE2 K Y UW0 L EY1 SH AH0 N'],
  "eject": ['IH0 JH EH1 K T'],
  "ejected": ['IH0 JH EH1 K T IH0 D'],
  "ejecting": ['IH0 JH EH1 K T IH0 NG'],
  "ejection": ['IH0 JH EH1 K SH AH0 N'],
  "ejects": ['IH0 JH EH1 K T S', 'IH0 JH EH1 K S'],
  "ejup": ['IY1 JH AH0 P', 'IY1 JH UW0 P'],
  "ejup's": ['IY1 JH AH0 P S', 'IY1 JH UW0 P S'],
  "ek": ['EH1 K', 'IY1 K EY1'],
  "eka": ['EH1 K AH0'],
  "ekaterina": ['EY0 K AA0 T EH0 R IY1 N AH0'],
  "ekberg": ['EH1 K B ER0 G'],
  "ekblad": ['EH1 K B L AE2 D'],
  "ekco": ['EH1 K OW0'],
  "ekdahl": ['EH1 K D AA2 L'],
  "eke": ['IY1 K'],
  "eked": ['IY1 K T'],
  "ekern": ['EH1 K ER0 N'],
  "ekeus": ['IY2 K UW1 S'],
  "ekholm": ['EH1 K HH OW2 L M'],
  "eking": ['IY1 K IH0 NG'],
  "ekins": ['EH1 K IH0 N Z'],
  "ekk": ['IY1 K EY1 K EY1'],
  "ekkehard": ['EH1 K HH AA2 R D'],
  "eklund": ['EH1 K L AH0 N D'],
  "ekman": ['EH1 K M AH0 N'],
  "eko": ['EH1 K OW0'],
  "ekofisk": ['EH1 K AH0 F IH0 S K'],
  "eksportfinans": ['EH1 K S P AO2 R T F IH0 N AH0 N Z'],
  "ekstrand": ['EH1 K S T R AH0 N D'],
  "ekstrom": ['EH1 K S T R AH0 M'],
  "el": ['EH1 L'],
  "el-al": ['EH1 L AE1 L'],
  "el-greco": ['EH1 L G R EH1 K OW0'],
  "el-nino": ['EH1 L N IY1 N Y OW0'],
  "el-paso": ['EH1 L P AE1 S OW0'],
  "el-salvador": ['EH1 L S AE1 L V AH0 D AO2 R'],
  "ela": ['EH1 L AH0'],
  "elaborate": ['IH0 L AE1 B R AH0 T', 'IH0 L AE1 B ER0 EY2 T'],
  "elaborated": ['IH0 L AE1 B ER0 EY0 T AH0 D'],
  "elaborately": ['IH0 L AE1 B R AH0 T L IY0'],
  "elaborates": ['IH0 L AE1 B ER0 EY2 T S'],
  "elaborating": ['IH0 L AE1 B ER0 EY2 T IH0 NG'],
  "elaboration": ['IH0 L AE2 B ER0 EY1 SH AH0 N'],
  "elaina": ['IH0 L EY1 N AH0'],
  "elaine": ['IH0 L EY1 N', 'AH0 L EY1 N', 'IY2 L EY1 N'],
  "elaine's": ['AH0 L EY1 N Z', 'IY2 L EY1 N Z', 'IH0 L EY1 N Z'],
  "elaire": ['IH0 L EY1 R'],
  "elam": ['EH1 L AH0 M'],
  "elamin": ['EH1 L AH0 M IH0 N'],
  "elan": ['IY1 L AH0 N'],
  "eland": ['IY1 L AH0 N D'],
  "elane": ['IH0 L EY1 N'],
  "elapse": ['IH0 L AE1 P S'],
  "elapsed": ['IH0 L AE1 P S T'],
  "elardo": ['EH0 L AA1 R D OW0'],
  "elastic": ['IH0 L AE1 S T IH0 K'],
  "elasticity": ['IY2 L AE2 S T IH1 S AH0 T IY0'],
  "elastomer": ['IH0 L AE1 S T AH0 M ER0'],
  "elastomers": ['IH0 L AE1 S T AH0 M ER0 Z'],
  "elat": ['EH0 L AE1 T'],
  "elata": ['EH0 L AA1 T AH0'],
  "elate": ['IH0 L EY1 T'],
  "elated": ['IH0 L EY1 T AH0 D', 'IH0 L EY1 T IH0 D'],
  "elater": ['EH1 L AH0 T ER0', 'IH1 L EY0 T ER0'],
  "elaters": ['EH1 L AH0 T ER0 Z', 'IH1 L EY0 T ER0 Z'],
  "elates": ['IH0 L EY1 T S'],
  "elating": ['IH0 L EY1 T IH0 NG'],
  "elation": ['IH0 L EY1 SH AH0 N'],
  "elayne": ['IH0 L EY1 N'],
  "elbaum": ['EH1 L B AW2 M'],
  "elbe": ['EH1 L B'],
  "elber": ['EH1 L B ER0'],
  "elberson": ['EH1 L B ER0 S AH0 N'],
  "elbert": ['EH1 L B ER0 T'],
  "elberta": ['EH0 L B EH1 R T AH0'],
  "elbertine": ['EH1 L B ER0 T IY2 N'],
  "elbow": ['EH1 L B OW2'],
  "elbowed": ['EH1 L B OW2 D'],
  "elbowing": ['EH1 L B OW2 IH0 NG'],
  "elbowroom": ['EH1 L B OW2 R UW2 M'],
  "elbows": ['EH1 L B OW2 Z'],
  "elbrick": ['EH1 L B R IH0 K'],
  "elbrick's": ['EH1 L B R IH0 K S'],
  "elbrus": ['EH1 L B R AH0 S'],
  "elchibey": ['EH1 L CH AH0 B EY0'],
  "elco": ['EH1 L K OW0'],
  "elcock": ['IH0 L K AA1 K'],
  "elcor": ['EH1 L K AO2 R'],
  "elcotel": ['EH1 L K OW0 T EH2 L'],
  "eldar": ['EH1 L D AA2 R'],
  "elden": ['EH1 L D AH0 N'],
  "elder": ['EH1 L D ER0'],
  "elderkin": ['EH1 L D ER0 K IH0 N'],
  "elderly": ['EH1 L D ER0 L IY0'],
  "elderly's": ['EH1 L D ER0 L IY0 Z'],
  "elderman": ['EH1 L D ER0 M AH0 N'],
  "elders": ['EH1 L D ER0 Z'],
  "elders'": ['EH1 L D ER0 Z'],
  "elders's": ['EH1 L D ER0 Z IH0 Z'],
  "eldest": ['EH1 L D AH0 S T'],
  "eldin": ['EH1 L D IH0 N'],
  "eldon": ['IH0 L D AA1 N'],
  "eldora": ['EH0 L D AO1 R AH0'],
  "eldorado": ['EH2 L D ER0 AA1 D OW0'],
  "eldorado's": ['EH2 L D ER0 AA1 D OW0 Z'],
  "eldred": ['EH1 L D ER0 D'],
  "eldredge": ['IH0 L D R EH1 JH'],
  "eldreth": ['IH0 L D R EH1 TH'],
  "eldric": ['EH1 L D R IH0 K'],
  "eldrida": ['EH0 L D R IY1 D AH0'],
  "eldridge": ['EH1 L D R IH2 JH'],
  "eldritch": ['EH1 L D R IH0 CH'],
  "eldwin": ['IH0 L D W IH1 N'],
  "eleanor": ['EH1 L AH0 N AO0 R', 'EH1 L AH0 N ER0'],
  "eleanor's": ['EH1 L AH0 N AO0 R Z', 'EH1 L AH0 N ER0 Z'],
  "eleanora": ['EH2 L AH0 N AO1 R AH0'],
  "eleanore": ['EH1 L AH0 N AO0 R'],
  "eleazer": ['EH1 L AH0 Z ER0'],
  "elect": ['IH0 L EH1 K T'],
  "elect's": ['IH0 L EH1 K T S'],
  "electability": ['IH0 L EH2 K T AH0 B IH1 L AH0 T IY0'],
  "electable": ['IH0 L EH1 K T AH0 B AH0 L'],
  "elected": ['IH0 L EH1 K T AH0 D', 'IH0 L EH1 K T IH0 D'],
  "electing": ['IH0 L EH1 K T IH0 NG'],
  "election": ['IH0 L EH1 K SH AH0 N'],
  "election's": ['IH0 L EH1 K SH AH0 N Z'],
  "electioneer": ['IH0 L EH2 K SH AH0 N IH1 R'],
  "electioneering": ['IH0 L EH2 K SH AH0 N IH1 R IH0 NG'],
  "electioneers": ['IH0 L EH2 K SH AH0 N IH1 R Z'],
  "elections": ['IH0 L EH1 K SH AH0 N Z'],
  "elective": ['IH0 L EH1 K T IH0 V'],
  "electives": ['IH0 L EH1 K T IH0 V Z'],
  "elector": ['IH0 L EH1 K T ER0'],
  "electoral": ['IH0 L EH1 K T ER0 AH0 L'],
  "electorate": ['IH0 L EH1 K T ER0 AH0 T', 'IH0 L EH1 K T R IH0 T'],
  "electorate's": ['IH0 L EH1 K T ER0 AH0 T S'],
  "electorates": ['IH0 L EH1 K T ER0 AH0 T S'],
  "electors": ['IH0 L EH1 K T ER0 Z'],
  "electra": ['IH0 L EH1 K T R AH0'],
  "electric": ['IH0 L EH1 K T R IH0 K'],
  "electric's": ['IH0 L EH1 K T R IH0 K S'],
  "electrical": ['IH0 L EH1 K T R IH0 K AH0 L'],
  "electrically": ['IH0 L EH1 K T R IH0 K AH0 L IY0', 'IH0 L EH1 K T R IH0 K L IY0'],
  "electricals": ['IH0 L EH1 K T R IH0 K AH0 L Z'],
  "electricar": ['IH0 L EH1 K T R IH0 K AA2 R'],
  "electrician": ['IH0 L EH0 K T R IH1 SH AH0 N'],
  "electricians": ['IH0 L EH0 K T R IH1 SH AH0 N Z'],
  "electricians'": ['IH0 L EH0 K T R IH1 SH AH0 N Z'],
  "electricite": ['AH0 L EH2 K T R IH1 S IH2 T EY0'],
  "electricity": ['IH0 L EH2 K T R IH1 S AH0 T IY0'],
  "electrics": ['IH0 L EH1 K T R IH0 K S'],
  "electrification": ['IH0 L EH2 K T R AH0 F IH0 K EY1 SH AH0 N'],
  "electrified": ['IH0 L EH1 K T R AH0 F AY2 D'],
  "electrifies": ['IH0 L EH1 K T R AH0 F AY2 Z'],
  "electrify": ['IH0 L EH1 K T R AH0 F AY2'],
  "electrifying": ['IH0 L EH1 K T R AH0 F AY2 IH0 NG'],
  "electrique": ['EH2 L EH0 K T R IY1 K'],
  "electro": ['IH0 L EH1 K T R OW0'],
  "electrobiology": ['IH2 L EH2 K T R OW0 B AY0 AA1 L AH0 JH IY2'],
  "electrobiology's": ['IH2 L EH2 K T R OW0 B AY0 AA1 L AH0 JH IY2 Z'],
  "electrocardiogram": ['IH2 L EH2 K T R OW0 K AA1 R D IY0 AH0 G R AE2 M'],
  "electrocardiograms": ['IH2 L EH2 K T R OW0 K AA1 R D IY0 AH0 G R AE2 M Z'],
  "electrochemical": ['AH2 L EH2 K T R OW0 K EH1 M IH0 K AH0 L'],
  "electrocom": ['IH2 L EH1 K T R OW0 K AA2 M'],
  "electrocute": ['IH2 L EH1 K T R AH0 K Y UW2 T'],
  "electrocuted": ['IH2 L EH1 K T R AH0 K Y UW2 T IH0 D'],
  "electrocution": ['IH2 L EH2 K T R AH0 K Y UW1 SH AH0 N'],
  "electrocutions": ['AH0 L EH2 K T R AH0 K Y UW1 SH AH0 N Z'],
  "electrode": ['IH2 L EH1 K T R OW0 D'],
  "electrodes": ['IH2 L EH1 K T R OW0 D Z'],
  "electrodynamic": ['IH2 L EH2 K T R OW0 D AY2 N AE1 M IH0 K'],
  "electrodynamics": ['IH2 L EH2 K T R OW0 D AY2 N AE1 M IH0 K S'],
  "electrolux": ['IH0 L EH1 K T R AH0 L AH0 K S'],
  "electrolysis": ['IH0 L EH2 K T R AA1 L AH0 S AH0 S'],
  "electrolyte": ['IH0 L EH2 K T R AA0 L AY1 T'],
  "electrolytes": ['IH0 L EH2 K T R AA0 L AY1 T S'],
  "electrolytic": ['IH2 L EH2 K T R AH0 L IH1 T IH0 K'],
  "electromagnet": ['IH0 L EH2 K T R OW0 M AE1 G N AH0 T'],
  "electromagnetic": ['IH2 L EH2 K T R OW0 M AE0 G N EH1 T IH0 K'],
  "electromagnetism": ['IH2 L EH2 K T R OW0 M AE1 G N AH0 T IH2 Z AH0 M'],
  "electromagnets": ['IH2 L EH2 K T R OW0 M AE1 G N AH0 T S'],
  "electromechanical": ['IH2 L EH2 K T R OW0 M AH0 K AE1 N IH0 K AH0 L'],
  "electromedics": ['IH2 L EH2 K T R OW0 M EH1 D IH0 K S'],
  "electron": ['IH2 L EH1 K T R AA0 N'],
  "electronic": ['IH2 L EH2 K T R AA1 N IH0 K'],
  "electronically": ['IH2 L EH2 K T R AA1 N IH0 K AH0 L IY2', 'IH0 L EH2 K T R AA1 N IH0 K L IY0'],
  "electronics": ['IH2 L EH2 K T R AA1 N IH0 K S'],
  "electronics'": ['IH2 L EH2 K T R AA1 N IH0 K S'],
  "electrons": ['IH2 L EH1 K T R AA0 N Z'],
  "electrophoresis": ['IH2 L EH2 K T R OW0 F AO0 R IH1 S IH0 S', 'IH0 L EH0 K T R OW0 F ER0 IY1 S IH0 S'],
  "electrophoretogram": ['IH0 L EH2 K T R AA0 F AH0 R EH1 T AH0 G R AE0 M'],
  "electroplate": ['IH2 L EH1 K T R AH0 P L EY2 T'],
  "electroplating": ['IH2 L EH1 K T R AH0 P L EY2 T IH0 NG'],
  "electroshock": ['IH2 L EH1 K T R OW2 SH AA2 K'],
  "electrosound": ['IH2 L EH1 K T R OW0 S AW2 N D'],
  "electrospace": ['IH2 L EH1 K T R OW0 S P EY2 S'],
  "electrospray": ['IH2 L EH1 K T R OW0 S P R EY2'],
  "electrostatic": ['IH2 L EH2 K T R OW0 S T AE1 T IH0 K'],
  "elects": ['IH2 L EH1 K T S'],
  "eledge": ['EH1 L IH0 JH'],
  "eleemosynary": ['EH2 L AH0 M AO1 S AH0 N EH2 R IY0'],
  "eleen": ['EH1 L IY2 N'],
  "elefante": ['EH0 L EH0 F AA1 N T IY0'],
  "elegance": ['EH1 L AH0 G AH0 N S'],
  "elegant": ['EH1 L AH0 G AH0 N T'],
  "elegantly": ['EH1 L IH0 G AH0 N T L IY2'],
  "elegy": ['EH1 L AH0 JH IY2'],
  "elek": ['EH1 L IH0 K'],
  "elektra": ['EH0 L EH1 K T R AH0'],
  "elektrisk": ['IH0 L EH2 K T R IH1 S K'],
  "elektrizitaetswerk": ['EH2 L IH0 K T R IH1 Z IH0 T AE2 T S W ER0 K'],
  "elektron": ['IH0 L EH1 K T R AA0 N'],
  "element": ['EH1 L AH0 M AH0 N T'],
  "elemental": ['EH2 L AH0 M EH1 N T AH0 L', 'EH2 L AH0 M EH1 N AH0 L'],
  "elementary": ['EH2 L AH0 M EH1 N T R IY0', 'EH2 L AH0 M EH1 N T ER0 R IY0', 'EH2 L AH0 M EH1 N CH R IY0'],
  "elements": ['EH1 L AH0 M AH0 N T S'],
  "elena": ['EH1 L AH0 N AA0', 'EH2 L EY1 N AA0'],
  "elena's": ['EH1 L AH0 N AH0 Z'],
  "elenbaas": ['EH1 L IH0 N B AA0 Z'],
  "elene": ['EH1 L IY0 N'],
  "elenore": ['EH1 L IH0 N ER0'],
  "eleonore": ['EH0 L IY0 AH0 N AO1 R IY0'],
  "elephant": ['EH1 L AH0 F AH0 N T'],
  "elephant's": ['EH1 L AH0 F AH0 N T S'],
  "elephantiasis": ['EH2 L IH0 F AH0 N T AY1 AH0 S IH0 S'],
  "elephantine": ['EH2 L AH0 F AE1 N T IY2 N'],
  "elephants": ['EH1 L AH0 F AH0 N T S'],
  "elephants'": ['EH1 L AH0 F AH0 N T S'],
  "eletr": ['EH1 L AH0 T ER0'],
  "eleuthera": ['IH0 L UW1 TH ER0 AH0'],
  "elevate": ['EH1 L AH0 V EY2 T'],
  "elevated": ['EH1 L AH0 V EY2 T IH0 D'],
  "elevates": ['EH1 L AH0 V EY2 T S'],
  "elevating": ['EH1 L AH0 V EY2 T IH0 NG'],
  "elevation": ['EH2 L AH0 V EY1 SH AH0 N'],
  "elevations": ['EH2 L AH0 V EY1 SH AH0 N Z'],
  "elevator": ['EH1 L AH0 V EY2 T ER0'],
  "elevators": ['EH1 L AH0 V EY2 T ER0 Z'],
  "eleven": ['IH0 L EH1 V AH0 N', 'IY1 L EH0 V AH0 N'],
  "eleven's": ['IH0 L EH1 V AH0 N Z', 'IY1 L EH0 V AH0 N Z'],
  "elevens": ['IH0 L EH1 V AH0 N Z', 'IY1 L EH0 V AH0 N Z'],
  "eleventh": ['IH0 L EH1 V AH0 N TH', 'IY1 L EH0 V AH0 N TH'],
  "elexis": ['EH0 L EH1 K S IH0 S'],
  "eley": ['IY1 L IY0'],
  "elf": ['EH1 L F'],
  "elf's": ['EH1 L F S'],
  "elfers": ['EH1 L F ER0 Z'],
  "elfie": ['EH1 L F IY0'],
  "elfin": ['EH1 L F IH0 N'],
  "elfish": ['EH1 L F IH0 SH'],
  "elfman": ['EH1 L F M AH0 N'],
  "elford": ['EH1 L F ER0 D'],
  "elfreda": ['EH0 L F R EH1 D AH0'],
  "elfrida": ['EH0 L F R IY1 D AH0'],
  "elfrieda": ['EH0 L F R IY1 D AH0'],
  "elfrink": ['EH1 L F R IH0 NG K'],
  "elfstrom": ['EH1 L F S T R AH0 M'],
  "elg": ['EH1 L G'],
  "elga": ['IH0 L G AA1'],
  "elgabrowny": ['EH0 L G AH0 B R AW1 N IY0'],
  "elgar": ['EH1 L G ER0'],
  "elgar's": ['EH1 L G ER0 Z'],
  "elgart": ['EY1 L G AA0 R T'],
  "elger": ['EH1 L G ER0'],
  "elgersma": ['EH0 L JH EH1 R S M AH0'],
  "elgie": ['EH1 L JH IY0'],
  "elgin": ['EH1 L JH IH0 N'],
  "eli": ['IY1 L AY0'],
  "elia": ['AH0 L AY1 AH0'],
  "eliades": ['IY1 L IY2 EY0 D Z'],
  "elias": ['AH0 L AY1 AH0 S'],
  "eliason": ['AH0 L AY1 AH0 S AH0 N'],
  "eliassen": ['AH0 L AY1 AH0 S AH0 N'],
  "elich": ['EH1 L IH0 K'],
  "elicit": ['IH0 L IH1 S IH0 T'],
  "elicited": ['IH0 L IH1 S IH0 T IH0 D'],
  "eliciting": ['IH0 L IH1 S AH0 T IH0 NG'],
  "elicits": ['IH0 L IH1 S AH0 T S'],
  "elick": ['EH1 L IH0 K'],
  "elicker": ['EH1 L IH0 K ER0'],
  "elie": ['EH1 L IY0'],
  "eligaya": ['EH0 L AH0 G AY1 AH0'],
  "eligibility": ['EH2 L IH0 JH AH0 B IH1 L IH0 T IY0'],
  "eligible": ['EH1 L AH0 JH AH0 B AH0 L', 'EH1 L IH0 JH AH0 B AH0 L'],
  "elihu": ['EH1 L IH0 HH UW0'],
  "elijah": ['EH0 L AY1 JH AH0', 'IY0 L AY1 JH AH0'],
  "eliminate": ['IH0 L IH1 M AH0 N EY2 T'],
  "eliminated": ['IH0 L IH1 M AH0 N EY2 T IH0 D'],
  "eliminates": ['IH0 L IH1 M AH0 N EY2 T S'],
  "eliminating": ['IH0 L IH1 M AH0 N EY2 T IH0 NG'],
  "elimination": ['IH0 L IH2 M AH0 N EY1 SH AH0 N'],
  "eliminations": ['IH0 L IH2 M IH0 N EY1 SH AH0 N Z'],
  "eline": ['EH1 L AY0 N'],
  "elinor": ['EH1 L IH0 N ER0'],
  "elinore": ['EH0 L IY0 N AO1 R IY0'],
  "elio": ['EH1 L IY0 OW0'],
  "eliopoulos": ['EH0 L IY0 AA1 P AH0 L IH0 S'],
  "eliot": ['EH1 L IY0 AH0 T'],
  "eliot's": ['EH1 L IY0 AH0 T S'],
  "eliott": ['EH1 L IY0 AA0 T'],
  "elisa": ['AH0 L IY1 S AH0', 'AH0 L IY1 Z AH0'],
  "elisa's": ['AH0 L IY1 S AH0 Z', 'AH0 L IY1 Z AH0 Z'],
  "elisabeth": ['IH0 L IH1 Z AH0 B IH0 TH'],
  "elise": ['AH0 L IY1 S'],
  "elish": ['EH1 L IH0 SH'],
  "elisha": ['EH1 L IH0 SH AH0'],
  "elison": ['EH1 L IH0 S AH0 N'],
  "elissa": ['EH0 L IY1 S AH0'],
  "elite": ['IH0 L IY1 T', 'EY0 L IY1 T'],
  "elites": ['IH0 L IY1 T S', 'EY0 L IY1 T S'],
  "elitism": ['EH1 L IH0 T IH2 Z AH0 M', 'EY0 L IY1 T IH2 Z AH0 M'],
  "elitist": ['EY0 L IY1 T IH0 S T', 'IH0 L IY1 T IH0 S T'],
  "elitists": ['EY0 L IY1 T IH0 S T S', 'IH0 L IY1 T IH0 S T S'],
  "elixir": ['IH0 L IH1 K S ER0'],
  "eliza": ['IH0 L AY1 Z AH0'],
  "elizabeth": ['IH0 L IH1 Z AH0 B AH0 TH', 'IH0 L IH1 Z AH0 B IH0 TH'],
  "elizabeth's": ['IH0 L IH1 Z AH0 B AH0 TH S'],
  "elizabethan": ['EH2 L IH0 Z AH0 B IY1 TH AH0 N'],
  "elizabethtown": ['AH0 L IH1 Z AH0 B EH0 TH T AW2 N'],
  "elizalde": ['EH0 L IY0 Z AA1 L D IY0'],
  "elizondo": ['EH2 L IH0 Z AA1 N D OW0'],
  "eljer": ['EH1 L JH ER0'],
  "elk": ['EH1 L K'],
  "elkes": ['EH1 L K S'],
  "elkhart": ['EH1 L K HH AA2 R T'],
  "elkhorn": ['EH1 L K HH AO2 R N'],
  "elkin": ['IH0 L K IH1 N'],
  "elkind": ['IH0 L K AY1 N D', 'EH1 L K IH0 N D'],
  "elkington": ['EH1 L K IH0 NG T AH0 N'],
  "elkins": ['EH1 L K IH0 N Z'],
  "elko": ['EH1 L K OW0'],
  "elks": ['EH1 L K S'],
  "elkton": ['EH1 L K T AH0 N'],
  "ell": ['EH1 L'],
  "ella": ['EH1 L AH0'],
  "ella's": ['EH1 L AH0 Z'],
  "ellamay": ['EH1 L AH0 M EY2'],
  "ellan": ['EH1 L AH0 N'],
  "ellan's": ['EH1 L AH0 N Z'],
  "ellard": ['EH1 L ER0 D'],
  "elle": ['EH1 L'],
  "elledge": ['EH1 L IH0 JH'],
  "ellefson": ['EH1 L IH0 F S AH0 N'],
  "ellegood": ['EH1 L IH0 G UH0 D'],
  "ellemann": ['EH1 L AH0 M AH0 N'],
  "ellen": ['EH1 L AH0 N'],
  "ellen's": ['EH1 L AH0 N Z'],
  "ellena": ['EH0 L EH1 N AH0'],
  "ellenbecker": ['EH1 L IH0 N B EH0 K ER0'],
  "ellenberg": ['EH1 L AH0 N B ER0 G'],
  "ellenberger": ['EH1 L AH0 N B ER0 G ER0'],
  "ellenbogen": ['EH1 L IH0 N B AH0 G AH0 N'],
  "ellenburg": ['EH1 L AH0 N B ER0 G'],
  "ellender": ['EH1 L EH0 N D ER0'],
  "ellene": ['EH1 L IY2 N'],
  "ellenpore": ['EH1 L IH0 N P AO0 R'],
  "ellenson": ['EH1 L IH0 N S AH0 N'],
  "ellenwood": ['EH1 L AH0 N W UH2 D'],
  "eller": ['EH1 L ER0'],
  "ellerbe": ['EH1 L ER0 B'],
  "ellerbee": ['IH0 L ER1 B IY0'],
  "ellerbrock": ['IH0 L ER1 B R AH0 K'],
  "ellerby": ['EH1 L ER0 B IY0'],
  "ellerey": ['EH1 L ER0 IY0'],
  "ellerman": ['EH1 L ER0 M AH0 N'],
  "ellers": ['EH1 L ER0 Z'],
  "ellert": ['EH1 L ER0 T'],
  "ellertson": ['EH1 L ER0 T S AH0 N'],
  "ellery": ['EH1 L ER0 IY0'],
  "ellesmere": ['EH1 L Z M IH2 R'],
  "ellesse": ['EH0 L EH1 S'],
  "ellestad": ['EH1 L IH0 S T AH0 D'],
  "ellett": ['EH1 L IH0 T'],
  "ellette": ['IH0 L EH1 T'],
  "elley": ['EH1 L IY0'],
  "ellice": ['EH1 L IH0 S'],
  "ellicott": ['EH1 L IH0 K AA0 T'],
  "ellie": ['EH1 L IY0'],
  "elliff": ['EH1 L IH0 F'],
  "ellijay": ['IY0 L AY1 JH EY0'],
  "elliman": ['EH1 L IH0 M AH0 N'],
  "elling": ['EH1 L IH0 NG'],
  "ellinger": ['EH1 L IH0 NG ER0'],
  "ellingsen": ['EH1 L IH0 NG S AH0 N'],
  "ellingson": ['EH1 L IH0 NG S AH0 N'],
  "ellingsworth": ['EH1 L IH0 NG Z W ER2 TH'],
  "ellington": ['EH1 L IH0 NG T AH0 N'],
  "ellington's": ['EH1 L IH0 NG T AH0 N Z'],
  "ellingwood": ['EH1 L IH0 NG W UH2 D'],
  "ellinwood": ['EH1 L IH0 N W UH2 D'],
  "ellios": ['EH1 L Y OW0 S'],
  "elliot": ['EH1 L IY0 AH0 T'],
  "elliott": ['EH1 L IY0 AH0 T'],
  "elliott's": ['EH1 L IY0 AH0 T S'],
  "ellipse": ['IH0 L IH1 P S'],
  "ellipsoid": ['IH0 L IH1 P S OY0 D'],
  "ellipsoids": ['IH0 L IH1 P S OY0 D Z'],
  "elliptical": ['IH0 L IH1 P T IH0 K AH0 L'],
  "ellis": ['EH1 L IH0 S'],
  "ellis's": ['EH1 L IH0 S IH0 Z'],
  "ellison": ['EH1 L IH0 S AH0 N'],
  "ellisor": ['EH1 L IH0 S ER0'],
  "elliston": ['EH1 L IH0 S T AA0 N'],
  "ellithorpe": ['EH1 L IH0 TH ER0 P'],
  "ellman": ['EH1 L M AH0 N'],
  "ellmann": ['EH1 L M AH0 N'],
  "ellner": ['EH1 L N ER0'],
  "ellroy": ['EH1 L R OY2'],
  "ells": ['EH1 L Z'],
  "ellsberg": ['EH1 L Z B ER0 G'],
  "ellsberg's": ['EH1 L Z B ER0 G Z'],
  "ellsburg": ['EH1 L Z B ER0 G'],
  "ellsworth": ['EH1 L Z W ER0 TH'],
  "ellwanger": ['EH1 L W AO0 NG ER0'],
  "ellwood": ['EH1 L W UH2 D'],
  "ellwood's": ['EH1 L W UH2 D Z'],
  "elly": ['EH1 L IY0'],
  "ellyn": ['EH1 L IH0 N'],
  "ellyson": ['EH1 L IH0 S AH0 N'],
  "ellzey": ['EH1 L Z IY0'],
  "elm": ['EH1 L M'],
  "elma": ['EH1 L M AH0'],
  "elman": ['EH1 L M AH0 N'],
  "elmendorf": ['EH1 L M IH0 N D AO0 R F'],
  "elmer": ['EH1 L M ER0'],
  "elmes": ['EH1 L M Z'],
  "elmhurst": ['EH1 L M HH ER0 S T'],
  "elmira": ['EH0 L M AY1 R AH0'],
  "elmo": ['EH1 L M OW0'],
  "elmootazbell": ['EH0 L M UW1 T AH2 Z B EH2 L'],
  "elmootazbellah": ['EH0 L M UW2 T AH2 Z B EH1 L AH0'],
  "elmore": ['EH1 L M AO0 R'],
  "elmquist": ['EH1 L M K W IH2 S T'],
  "elms": ['EH1 L M Z'],
  "elmsford": ['EH1 L M Z F ER0 D'],
  "elmwood": ['EH1 L M W UH2 D'],
  "elna": ['IH0 L N AA1'],
  "elnora": ['EH0 L N AO1 R AH0'],
  "elnore": ['IH0 L N AO1 R'],
  "elnozahy": ['EH2 L N OW1 Z AA1 HH IY0'],
  "elocution": ['EH2 L AH0 K Y UW1 SH AH0 N'],
  "elocutions": ['EH2 L AH0 K Y UW1 SH AH0 N Z'],
  "eloisa": ['EH0 L OY1 S AH0'],
  "eloise": ['IH0 L OY1 Z', 'EH1 L OW0 IY0 Z'],
  "elommal": ['IH0 L OW1 M AH0 L'],
  "elongate": ['IH0 L AO1 NG G EY0 T'],
  "elongated": ['IH0 L AO1 NG G EY0 T AH0 D'],
  "elongation": ['IY2 L AO0 NG G EY1 SH AH0 N'],
  "elope": ['IH0 L OW1 P'],
  "eloped": ['IH0 L OW1 P D'],
  "elopes": ['IH0 L OW1 P S'],
  "eloquence": ['EH1 L AH0 K W AH0 N S'],
  "eloquent": ['EH1 L AH0 K W AH0 N T'],
  "eloquently": ['EH1 L AH0 K W AH0 N T L IY0'],
  "elouise": ['EH1 L AH0 W IY2 Z'],
  "eloy": ['IY1 L OY0'],
  "elpers": ['EH1 L P ER0 Z'],
  "elrica": ['EH1 L R IH0 K AH0'],
  "elrich": ['EH1 L R IH0 CH'],
  "elrick": ['EH1 L R IH0 K'],
  "elrod": ['IH0 L R AA1 D'],
  "elron": ['EH1 L R AH0 N'],
  "elroy": ['IH0 L R OY1'],
  "els": ['EH1 L Z'],
  "elsa": ['EH1 L S AH0'],
  "elsas": ['EH1 L S AH0 Z'],
  "elsasser": ['EH1 L S AH0 S ER0'],
  "elsberry": ['EH1 L Z B EH2 R IY0'],
  "elsbury": ['EH1 L Z B EH2 R IY0'],
  "elsdon": ['EH1 L S D AH0 N'],
  "else": ['EH1 L S'],
  "else's": ['EH1 L S IH0 Z'],
  "elsea": ['EH1 L S IY0 AH0'],
  "elsen": ['EH1 L S AH0 N'],
  "elser": ['EH1 L S ER0'],
  "elses": ['EH1 L S IH0 Z'],
  "elsesser": ['EH1 L S IH0 S ER0'],
  "elsevier": ['EH0 L S EH1 V Y ER0', 'EH1 L S AH0 V IH2 R'],
  "elsevier's": ['EH0 L S EH1 V Y ER0 Z', 'EH1 L S AH0 V IH2 R Z'],
  "elsewhere": ['EH1 L S W EH2 R'],
  "elsey": ['EH1 L S IY0'],
  "elsie": ['EH1 L S IY0'],
  "elsie's": ['EH1 L S IY0 Z'],
  "elsinore": ['EH1 L S AH0 N AO2 R'],
  "elsner": ['EH1 L S N ER0'],
  "elson": ['EH1 L S AH0 N'],
  "elstad": ['EH1 L S T AH0 D'],
  "elster": ['EH1 L S T ER0'],
  "elston": ['IH0 L S T AA1 N'],
  "elswick": ['EH1 L Z W IH2 K'],
  "elsworth": ['EH1 L Z W ER2 TH'],
  "elting": ['EH1 L T IH0 NG'],
  "elton": ['EH1 L T AH0 N'],
  "eltringham": ['EH1 L T R IH0 NG AE0 M'],
  "eltzroth": ['EH1 L T S R AO0 TH'],
  "elucidate": ['IH0 L UW1 S AH0 D EY2 T'],
  "elucidated": ['IH0 L UW1 S AH0 D EY2 T AH0 D'],
  "elucidative": ['IH0 L UW1 S AH0 D EY2 T IH0 V'],
  "elude": ['IH0 L UW1 D'],
  "eluded": ['IH0 L UW1 D IH0 D'],
  "eludes": ['IH0 L UW1 D Z'],
  "eluding": ['IH0 L UW1 D IH0 NG'],
  "elusive": ['IH0 L UW1 S IH0 V'],
  "elusiveness": ['IH0 L UW1 S IH0 V N AH0 S'],
  "elva": ['EH1 L V AH0'],
  "elvera": ['EY0 L V EH1 R AH0'],
  "elvers": ['EH1 L V ER0 Z'],
  "elves": ['EH1 L V Z'],
  "elvgren": ['EH1 L V G R EH0 N'],
  "elvia": ['EH1 L V IY0 AH0'],
  "elvie": ['EH1 L V IY0'],
  "elvin": ['EH1 L V IH0 N'],
  "elvina": ['EH0 L V IY1 N AH0'],
  "elving": ['EH1 L V IH0 NG'],
  "elvington": ['EH1 L V IH0 NG T AH0 N'],
  "elvira": ['EH0 L V AY1 R AH0'],
  "elvire": ['EH1 L V AY2 R'],
  "elvis": ['EH1 L V IH0 S'],
  "elvis'": ['EH1 L V IH0 S'],
  "elvis's": ['EH1 L V IH0 S IH0 Z'],
  "elvy": ['EH1 L V IY0'],
  "elway": ['EH1 L W EY2'],
  "elway's": ['EH1 L W EY2 Z'],
  "elwell": ['IH0 L W EH1 L'],
  "elwin": ['EH1 L W IH0 N'],
  "elwood": ['EH1 L W UH2 D'],
  "elxsi": ['EH1 L K S IY0'],
  "ely": ['IY1 L AY0'],
  "elyn": ['EH1 L IH0 N'],
  "elyria": ['IH0 L IH1 R IY0 AH0'],
  "elyse": ['EH1 L AY0 S'],
  "elysee": ['EH1 L IH0 S IY2', 'EH1 L IY0 S IY2'],
  "elysees": ['EH1 L IH0 S IY2 Z', 'EH1 L IY0 S IY2 Z'],
  "elysia": ['IH0 L IH1 ZH IY0 AH0', 'IH0 L IY1 ZH AH0'],
  "elysium": ['IH0 L IH1 Z IY0 AH0 M'],
  "elza": ['EH1 L Z AH0'],
  "elzey": ['EH1 L Z IY0'],
  "elzinga": ['EH0 L Z IY1 NG G AH0'],
  "elzy": ['EH1 L Z IY0'],
  "em": ['EH1 M'],
  "ema": ['IY1 M AH0'],
  "emaciate": ['IH0 M EY1 SH IY0 EY2 T'],
  "emaciated": ['IH0 M EY1 SH IY0 EY2 T IH0 D'],
  "emaciates": ['IH0 M EY1 SH IY0 EY2 T S'],
  "emaciating": ['IH0 M EY1 SH IY0 EY2 T IH0 NG'],
  "emacs": ['IY1 M AE2 K S'],
  "emad": ['IY1 M AE0 D'],
  "email": ['IY0 M EY1 L'],
  "emailed": ['IY0 M EY1 L D'],
  "emailing": ['IY0 M EY1 L IH0 NG'],
  "emails": ['IY0 M EY1 L Z'],
  "emanate": ['EH1 M AH0 N EY2 T'],
  "emanated": ['EH1 M AH0 N EY2 T IH0 D'],
  "emanates": ['EH1 M AH0 N EY0 T S'],
  "emanating": ['EH1 M AH0 N EY2 T IH0 NG'],
  "emanation": ['EH2 M AH0 N EY1 SH AH0 N'],
  "emanations": ['EH2 M AH0 N EY1 SH AH0 N Z'],
  "emancipate": ['IH0 M AE1 N S AH0 P EY2 T'],
  "emancipated": ['IH0 M AE1 N S AH0 P EY2 T IH0 D'],
  "emancipates": ['IH0 M AE1 N S AH0 P EY2 T S'],
  "emancipating": ['IH0 M AE1 N S AH0 P EY2 T IH0 NG'],
  "emancipation": ['IH0 M AE2 N S AH0 P EY1 SH AH0 N'],
  "emancipations": ['IH0 M AE2 N S AH0 P EY1 SH AH0 N Z'],
  "emanuel": ['IH0 M AE1 N Y UW0 AH0 L'],
  "emanuele": ['EY0 M AA0 N UW0 EH1 L EY0'],
  "emanuelson": ['IH0 M AE1 N UW0 L S AH0 N'],
  "emard": ['EH1 M ER0 D'],
  "emasculate": ['AH0 M AE1 S K Y UW0 L IH0 T', 'AH0 M AE1 S K Y UW0 L EY2 T'],
  "emasculated": ['AH0 M AE1 S K Y UW0 L EY2 T IH0 D'],
  "embalm": ['EH0 M B AA1 M'],
  "embalmed": ['EH0 M B AA1 M D'],
  "embalming": ['EH0 M B AA1 M IH0 NG'],
  "embankment": ['EH0 M B AE1 NG K M AH0 N T'],
  "embankments": ['EH0 M B AE1 NG K M AH0 N T S'],
  "embarcadero": ['EH0 M B AA2 R K AH0 D EH1 R OW0'],
  "embargo": ['EH0 M B AA1 R G OW0'],
  "embargoed": ['IH0 M B AA1 R G OW0 D'],
  "embargoes": ['EH0 M B AA1 R G OW0 Z'],
  "embark": ['EH0 M B AA1 R K', 'IH0 M B AA1 R K'],
  "embarkation": ['EH2 M B AA0 R K EY1 SH AH0 N'],
  "embarked": ['EH0 M B AA1 R K T'],
  "embarking": ['EH0 M B AA1 R K IH0 NG'],
  "embarks": ['IH0 M B AA1 R K S'],
  "embarrass": ['IH0 M B EH1 R AH0 S'],
  "embarrassed": ['IH0 M B EH1 R AH0 S T'],
  "embarrasses": ['IH0 M B AE1 R AH0 S IH0 Z'],
  "embarrassing": ['IH0 M B EH1 R AH0 S IH0 NG'],
  "embarrassingly": ['IH0 M B EH1 R AH0 S IH0 NG L IY0'],
  "embarrassment": ['IH0 M B EH1 R AH0 S M AH0 N T'],
  "embarrassments": ['IH0 M B EH1 R AH0 S M AH0 N T S'],
  "embassies": ['EH1 M B AH0 S IY0 Z'],
  "embassy": ['EH1 M B AH0 S IY0'],
  "embassy's": ['EH1 M B AH0 S IY0 Z'],
  "embattle": ['EH0 M B AE1 T AH0 L'],
  "embattled": ['EH0 M B AE1 T AH0 L D'],
  "embayment": ['EH0 M B EY1 M AH0 N T'],
  "embed": ['IH0 M B EH1 D'],
  "embedded": ['EH0 M B EH1 D IH0 D'],
  "embedding": ['EH0 M B EH1 D IH0 NG'],
  "embellish": ['IH0 M B EH1 L IH0 SH'],
  "embellished": ['EH0 M B EH1 L IH0 SH T'],
  "embellishes": ['EH0 M B EH1 L IH0 SH AH0 Z'],
  "embellishing": ['EH0 M B EH1 L IH0 SH IH0 NG'],
  "embellishment": ['EH0 M B EH1 L IH0 SH M AH0 N T'],
  "ember": ['EH1 M B ER0'],
  "embers": ['EH1 M B ER0 Z'],
  "emberson": ['EH1 M B ER0 S AH0 N'],
  "emberton": ['IH0 M B ER1 T AH0 N', 'EH1 M B ER0 T AH0 N'],
  "embezzle": ['IH0 M B EH1 Z AH0 L'],
  "embezzled": ['IH0 M B EH1 Z AH0 L D'],
  "embezzlement": ['EH0 M B EH1 Z AH0 L M AH0 N T'],
  "embezzler": ['IH0 M B EH1 Z AH0 L ER0', 'EH0 M B EH1 Z L ER0'],
  "embezzlers": ['IH0 M B EH1 Z AH0 L ER0 Z', 'EH0 M B EH1 Z L ER0 Z'],
  "embezzles": ['IH0 M B EH1 Z AH0 L Z'],
  "embezzling": ['IH0 M B EH1 Z AH0 L IH0 NG', 'EH0 M B EH1 Z L IH0 NG', 'IH0 M B EH1 Z L IH0 NG', 'EH0 M B EH1 Z AH0 L IH0 NG'],
  "embitter": ['EH0 M B IH1 T ER0'],
  "embittered": ['EH0 M B IH1 T ER0 D'],
  "emblazon": ['EH0 M B L EY1 Z AH0 N'],
  "emblazoned": ['EH0 M B L EY1 Z AH0 N D'],
  "emblem": ['EH1 M B L AH0 M'],
  "emblematic": ['EH2 M B L AH0 M AE1 T IH0 K'],
  "emblems": ['EH1 M B L AH0 M Z'],
  "embler": ['EH1 M B L ER0'],
  "embleton": ['EH1 M B L IH0 T AA0 N', 'EH1 M B AH0 L T AA0 N'],
  "embodied": ['IH0 M B AA1 D IY0 D'],
  "embodies": ['EH0 M B AA1 D IY0 Z'],
  "embodiment": ['EH0 M B AA1 D IY0 M AH0 N T'],
  "embody": ['IH0 M B AA1 D IY0'],
  "embodying": ['IH0 M B AA1 D IY0 IH0 NG'],
  "embolden": ['EH0 M B OW1 L D AH0 N'],
  "emboldened": ['EH0 M B OW1 L D AH0 N D'],
  "embolism": ['EH1 M B OW0 L IH2 Z AH0 M'],
  "embolisms": ['EH1 M B OW0 L IH2 Z AH0 M Z'],
  "emboss": ['IH0 M B AO1 S'],
  "embossed": ['IH0 M B AO1 S T'],
  "embrace": ['EH0 M B R EY1 S', 'IH0 M B R EY1 S'],
  "embraceable": ['IH0 M B R EY1 S AH0 B AH0 L'],
  "embraced": ['EH0 M B R EY1 S T'],
  "embraces": ['EH0 M B R EY1 S IH0 Z'],
  "embracing": ['EH0 M B R EY1 S IH0 NG'],
  "embree": ['IH0 M B R IY1'],
  "embrey": ['EH1 M B R IY0'],
  "embroider": ['IH0 M B R OY1 D ER0'],
  "embroidered": ['EH0 M B R OY1 D ER0 D'],
  "embroiderer": ['EH0 M B R OY1 D ER0 ER0'],
  "embroiderers": ['EH0 M B R OY1 D ER0 ER0 Z'],
  "embroideries": ['IH0 M B R OY1 D ER0 IY0 Z'],
  "embroidering": ['EH0 M B R OY1 D ER0 IH0 NG'],
  "embroidery": ['EH0 M B R OY1 D ER0 IY0'],
  "embroil": ['EH0 M B R OY1 L'],
  "embroiled": ['EH0 M B R OY1 L D'],
  "embrose": ['EH1 M B R OW0 Z'],
  "embry": ['EH1 M B R IY0'],
  "embryo": ['EH1 M B R IY0 OW2'],
  "embryology": ['EH2 M B R IY0 AA1 L AH0 JH IY0'],
  "embryonic": ['EH2 M B R IY0 AA1 N IH0 K'],
  "embryos": ['EH1 M B R IY0 OW2 Z'],
  "embury": ['EH1 M B EH2 R IY0'],
  "emcee": ['EH1 M S IY1'],
  "emch": ['EH1 M CH'],
  "emco": ['EH1 M K OW0'],
  "emde": ['EH1 M D'],
  "emdr": ['IY1 EH1 M D IY1 AA1 R'],
  "emel": ['EH1 M AH0 L'],
  "emelda": ['EH0 M EH1 L D AH0'],
  "emelie": ['EH1 M AH0 L IY0'],
  "emelina": ['EH2 M EH0 L IY1 N AH0'],
  "emeline": ['EH1 M IH0 L AY2 N'],
  "emelita": ['EH0 M EH0 L IY1 T AH0'],
  "emelyne": ['EH1 M IH0 L AY0 N'],
  "emens": ['EH1 M EH0 N Z'],
  "emera": ['EH0 M EH1 R AH0'],
  "emerald": ['EH1 M R AH0 L D', 'EH1 M ER0 R AH0 L D'],
  "emeralds": ['EH1 M R AH0 L D Z', 'EH1 M ER0 R AH0 L D Z'],
  "emerant": ['EY0 M EH1 R AH0 N T'],
  "emeraude": ['EH1 M ER0 AW2 D'],
  "emerge": ['IH0 M ER1 JH', 'IY1 M ER0 JH'],
  "emerged": ['IH0 M ER1 JH D', 'IY1 M ER0 JH D'],
  "emergence": ['IH0 M ER1 JH AH0 N S', 'IY1 M ER0 JH AH0 N S'],
  "emergencies": ['IH0 M ER1 JH AH0 N S IY0 Z', 'IY1 M ER0 JH AH0 N S IY0 Z'],
  "emergency": ['IH0 M ER1 JH AH0 N S IY0', 'IY1 M ER0 JH AH0 N S IY0'],
  "emergent": ['IH0 M ER1 JH AH0 N T', 'IY1 M ER0 JH AH0 N T'],
  "emerges": ['IH0 M ER1 JH IH0 Z', 'IY1 M ER0 JH IH0 Z'],
  "emerging": ['IH0 M ER1 JH IH0 NG', 'IY1 M ER0 JH IH0 NG'],
  "emerich": ['EH1 M ER0 IH0 K'],
  "emerick": ['EH1 M ER0 IH0 K'],
  "emerine": ['EH1 M ER0 IY2 N'],
  "emeritus": ['IH0 M EH1 R AH0 T AH0 S'],
  "emerse": ['IH0 M ER1 S'],
  "emersed": ['IH0 M ER1 S T'],
  "emerson": ['EH1 M ER0 S AH0 N'],
  "emerson's": ['EH1 M ER0 S AH0 N Z'],
  "emert": ['EH1 M ER0 T'],
  "emerton": ['IH0 M ER1 T AH0 N', 'EH1 M ER0 T AH0 N'],
  "emery": ['EH1 M ER0 IY0'],
  "emery's": ['EH1 M ER0 IY0 Z'],
  "emeryville": ['EH1 M ER0 IY0 V IH2 L'],
  "emetic": ['IH0 M EH1 T IH0 K'],
  "emfinger": ['EH1 M F IH0 NG ER0'],
  "emge": ['EH1 M JH'],
  "emhart": ['EH1 M HH AA2 R T'],
  "emick": ['EH1 M IH0 K'],
  "emig": ['EH1 M IH0 G'],
  "emigh": ['EH1 M AY0'],
  "emigrant": ['EH1 M AH0 G R AH0 N T'],
  "emigrants": ['EH1 M AH0 G R AH0 N T S'],
  "emigrate": ['EH1 M AH0 G R EY2 T'],
  "emigrated": ['EH1 M AH0 G R EY2 T IH0 D'],
  "emigrates": ['EH1 M AH0 G R EY2 T S'],
  "emigrating": ['EH1 M AH0 G R EY2 T IH0 NG'],
  "emigration": ['EH2 M AH0 G R EY1 SH AH0 N'],
  "emigrations": ['EH2 M AH0 G R EY1 SH AH0 N Z'],
  "emigre": ['EH1 M AH0 G R EY2'],
  "emigres": ['EH1 M AH0 G R EY2 Z'],
  "emil": ['EH0 M IY1 L'],
  "emile": ['EY0 M IY1 L'],
  "emilia": ['AH0 M IY1 L IY0 AH0'],
  "emilie": ['EH1 M AH0 L IY0'],
  "emilio": ['AH0 M IY1 L IY0 OW0'],
  "emilo": ['EH0 M IY1 L OW0'],
  "emilo's": ['EH0 M IY1 L OW0 Z'],
  "emily": ['EH1 M IH0 L IY0'],
  "emily's": ['EH1 M IH0 L IY0 Z'],
  "emina": ['EH0 M IY1 N AH0'],
  "eminase": ['EH2 M IH0 N AA1 S IY0'],
  "eminem": ['EH1 M AH0 N AH0 M'],
  "eminence": ['EH1 M AH0 N AH0 N S'],
  "eminences": ['EH1 M AH0 N AH0 N S IH0 Z'],
  "eminent": ['EH1 M AH0 N AH0 N T'],
  "eminently": ['EH1 M AH0 N AH0 N T L IY0'],
  "emir": ['IH0 M IH1 R', 'EY0 M IH1 R'],
  "emirate": ['EH1 M ER0 AH0 T', 'EH1 M ER0 EY2 T'],
  "emirates": ['EH1 M ER0 AH0 T S', 'EH1 M ER0 EY2 T S'],
  "emirates'": ['EH1 M ER0 AH0 T S', 'EH1 M ER0 EY2 T S'],
  "emison": ['EH1 M IH0 S AH0 N'],
  "emissaries": ['EH1 M AH0 S EH2 R IY0 Z'],
  "emissary": ['EH1 M AH0 S EH2 R IY0'],
  "emission": ['IH0 M IH1 SH AH0 N'],
  "emissions": ['IH0 M IH1 SH AH0 N Z'],
  "emit": ['IH0 M IH1 T'],
  "emits": ['IH0 M IH1 T S'],
  "emitted": ['IH0 M IH1 T AH0 D', 'IH0 M IH1 T IH0 D'],
  "emitter": ['IH0 M IH1 T ER0'],
  "emitting": ['IH0 M IH1 T IH0 NG'],
  "emler": ['EH1 M L ER0'],
  "emley": ['EH1 M L IY0'],
  "emling": ['EH1 M L IH0 NG'],
  "emlyn": ['IH0 M L IH1 N'],
  "emlynne": ['IH0 M L AY1 N'],
  "emma": ['EH1 M AH0'],
  "emma's": ['EH1 M AH0 Z'],
  "emmaline": ['EH0 M AA0 L IY1 N IY0'],
  "emmanuel": ['IH0 M AE1 N Y UW0 AH0 L'],
  "emmanuelle": ['IH0 M AA1 N UW0 EH2 L'],
  "emmaus": ['EH1 M AW0 S'],
  "emme": ['EH1 M'],
  "emmel": ['EH1 M AH0 L'],
  "emmeline": ['EH1 M AH0 L AY2 N'],
  "emmendorfer": ['EH1 M IH0 N D AO0 R F ER0'],
  "emmer": ['EH1 M ER0'],
  "emmerich": ['EH1 M ER0 IH0 K'],
  "emmerling": ['EH1 M ER0 L IH0 NG'],
  "emmerson": ['EH1 M ER0 S AH0 N'],
  "emmert": ['EH1 M ER0 T'],
  "emmery": ['EH1 M ER0 IY0'],
  "emmet": ['EH1 M IH0 T'],
  "emmett": ['EH1 M IH0 T'],
  "emmi": ['EH1 M IY0'],
  "emmick": ['EH1 M IH0 K'],
  "emmie": ['EH1 M IY0'],
  "emminger": ['EH1 M IH0 NG ER0'],
  "emmis": ['EH1 M IH0 S'],
  "emmit": ['EH1 M IH0 T'],
  "emmitt": ['EH1 M IH0 T'],
  "emmons": ['EH1 M AH0 N Z'],
  "emmott": ['EH1 M AH0 T'],
  "emmy": ['EH1 M IY0'],
  "emmy's": ['EH1 M IY0 Z'],
  "emmys": ['EH1 M IY0 Z'],
  "emo": ['IY1 M OW2'],
  "emogene": ['EH1 M AH0 G IY0 N'],
  "emond": ['EH1 M AH0 N D'],
  "emory": ['EH1 M ER0 IY0'],
  "emory's": ['EH1 M ER0 IY0 Z'],
  "emoticon": ['AH0 M OW1 T AH0 K AA2 N'],
  "emotion": ['IH0 M OW1 SH AH0 N', 'IY1 M OW2 SH AH0 N'],
  "emotional": ['IH0 M OW1 SH AH0 N AH0 L', 'IY1 M OW2 SH AH0 N AH0 L'],
  "emotionalism": ['IH0 M OW1 SH AH0 N AH0 L IH2 Z AH0 M', 'IY1 M OW0 SH AH0 N AH0 L IH2 Z AH0 M'],
  "emotionally": ['IH0 M OW1 SH N AH0 L IY0', 'IY1 M OW2 SH N AH0 L IY0'],
  "emotions": ['IH0 M OW1 SH AH0 N Z', 'IY1 M OW0 SH AH0 N Z'],
  "emotive": ['IH0 M OW1 T IH0 V', 'IY1 M OW2 T IH0 V'],
  "empanel": ['IH0 M P AE1 N AH0 L'],
  "empaneled": ['IH0 M P AE1 N AH0 L D'],
  "empaneling": ['EH0 M P AE1 N AH0 L IH0 NG'],
  "empath": ['EH1 M P AE2 TH'],
  "empathetic": ['EH2 M P AH0 TH EH1 T IH0 K'],
  "empathize": ['EH1 M P AH0 TH AY2 Z'],
  "empathy": ['EH1 M P AH0 TH IY0'],
  "emperor": ['EH1 M P ER0 ER0'],
  "emperor's": ['EH1 M P ER0 ER0 Z'],
  "emperors": ['EH1 M P ER0 ER0 Z'],
  "empey": ['EH1 M P IY0'],
  "emphases": ['EH1 M F AH0 S IY2 Z'],
  "emphasis": ['EH1 M F AH0 S AH0 S', 'EH1 M F AH0 S IH0 S'],
  "emphasize": ['EH1 M F AH0 S AY2 Z'],
  "emphasized": ['EH1 M F AH0 S AY2 Z D'],
  "emphasizes": ['EH1 M F AH0 S AY2 Z AH0 Z', 'EH1 M F AH0 S AY2 Z IH0 Z'],
  "emphasizing": ['EH1 M F AH0 S AY2 Z IH0 NG'],
  "emphatic": ['EH0 M F AE1 T IH0 K'],
  "emphatically": ['EH0 M F AE1 T IH0 K L IY0', 'EH0 M F AE1 T IH0 K AH0 L IY0'],
  "emphysema": ['EH2 M F AH0 Z IY1 M AH0'],
  "empie": ['EH1 M P IY0'],
  "empire": ['EH1 M P AY0 ER0'],
  "empire's": ['EH1 M P AY0 ER0 Z'],
  "empires": ['EH1 M P AY0 ER0 Z'],
  "empirical": ['EH2 M P IH1 R IH0 K AH0 L'],
  "empirically": ['EH0 M P IH1 R IH0 K AH0 L IY0', 'EH0 M P IH1 R IH0 K L IY0'],
  "empiricism": ['EH0 M P IH1 R AH0 S IH2 Z AH0 M'],
  "empiricist": ['IH0 M P IH1 R AH0 S AH0 S T'],
  "emplacement": ['IH0 M P L EY1 S M AH0 N T'],
  "emplacements": ['IH0 M P L EY1 S M AH0 N T S'],
  "employ": ['EH0 M P L OY1', 'IH0 M P L OY1'],
  "employable": ['EH0 M P L OY1 AH0 B AH0 L'],
  "employed": ['EH0 M P L OY1 D', 'IH0 M P L OY1 D'],
  "employee": ['EH0 M P L OY1 IY0', 'IH0 M P L OY1 IY0'],
  "employee's": ['EH0 M P L OY1 IY0 Z', 'IH0 M P L OY1 IY0 Z'],
  "employees": ['EH0 M P L OY1 IY0 Z', 'IH0 M P L OY1 IY0 Z'],
  "employees'": ['EH0 M P L OY1 IY0 Z'],
  "employer": ['EH0 M P L OY1 ER0', 'IH0 M P L OY1 ER0'],
  "employer's": ['EH0 M P L OY1 ER0 Z', 'IH0 M P L OY1 ER0 Z'],
  "employers": ['EH0 M P L OY1 ER0 Z', 'IH0 M P L OY1 ER0 Z'],
  "employers'": ['EH0 M P L OY1 ER0 Z', 'IH0 M P L OY1 ER0 Z'],
  "employing": ['EH0 M P L OY1 IH0 NG', 'IH0 M P L OY1 IH0 NG'],
  "employment": ['EH0 M P L OY1 M AH0 N T', 'IH0 M P L OY1 M AH0 N T'],
  "employments": ['EH0 M P L OY1 M AH0 N T S', 'IH0 M P L OY1 M AH0 N T S'],
  "employs": ['EH0 M P L OY1 Z', 'IH0 M P L OY1 Z'],
  "emporia": ['EH0 M P AO1 R IY0 AH0'],
  "emporium": ['EH2 M P AO1 R IY0 AH0 M'],
  "empower": ['IH0 M P AW1 ER0'],
  "empowered": ['IH0 M P AW1 ER0 D'],
  "empowering": ['IH0 M P AW1 ER0 IH0 NG'],
  "empowerment": ['IH0 M P AW1 ER0 M AH0 N T'],
  "empowers": ['IH0 M P AW1 ER0 Z'],
  "empresa": ['EH0 M P R EH1 S AH0'],
  "empresas": ['EH0 M P R EH1 S AH0 Z'],
  "empress": ['EH1 M P R EH0 S'],
  "emprise": ['EH0 M P R AY1 Z'],
  "empson": ['EH1 M P S AH0 N'],
  "empt": ['EH1 M P T', 'EH1 M T'],
  "empted": ['EH1 M P T IH0 D', 'EH1 M T IH0 D'],
  "emptied": ['EH1 M P T IY0 D', 'EH1 M T IY0 D'],
  "emptier": ['EH1 M P T IY0 ER0', 'EH1 M T IY0 ER0'],
  "empties": ['EH1 M P T IY0 Z', 'EH1 M T IY0 Z'],
  "emptiness": ['EH1 M P T IY0 N AH0 S', 'EH1 M T IY0 N AH0 S'],
  "empting": ['EH1 M P T IH0 NG', 'EH1 M T IH0 NG'],
  "emption": ['EH1 M P SH AH0 N'],
  "emptive": ['EH1 M P T IH0 V'],
  "emptor": ['EH1 M P T ER0'],
  "empts": ['EH1 M P T S'],
  "empty": ['EH1 M P T IY0', 'EH1 M T IY0'],
  "empty-handed": ['EH1 M P T IY0 HH AE1 N D IH0 D'],
  "emptying": ['EH1 M P T IY0 IH0 NG', 'EH1 M T IY0 IH0 NG'],
  "emrich": ['EH1 M R IH0 K'],
  "emrick": ['EH1 M R IH0 K'],
  "emry": ['EH1 M R IY0'],
  "ems": ['IY1 EH1 M EH1 S'],
  "emslie": ['EH1 M S AH0 L IY0'],
  "emswiler": ['EH1 M Z W AY2 L ER0'],
  "emu": ['IY1 M Y UW2'],
  "emuil": ['EH1 M Y UW0 IH2 L'],
  "emuil's": ['EH1 M Y UW0 IH2 L Z'],
  "emulate": ['EH1 M Y AH0 L EY2 T'],
  "emulated": ['EH1 M Y AH0 L EY2 T IH0 D'],
  "emulates": ['EH1 M Y AH0 L EY2 T S'],
  "emulating": ['EH1 M Y AH0 L EY2 T IH0 NG'],
  "emulation": ['EH2 M Y AH0 L EY1 SH AH0 N'],
  "emulator": ['EH1 M Y AH0 L EY2 T ER0'],
  "emulex": ['EH1 M Y UW0 L AH0 K S'],
  "emulsified": ['IH0 M AH1 L S AH0 F AY0 D'],
  "emulsifier": ['IH0 M AH1 L S AH0 F AY2 ER0'],
  "emulsifies": ['IH0 M AH1 L S AH0 F AY0 Z'],
  "emulsify": ['IH0 M AH1 L S AH0 F AY0'],
  "emulsifying": ['IH0 M AH1 L S AH0 F AY2 IH0 NG'],
  "emulsion": ['IH0 M AH1 L SH AH0 N'],
  "en": ['EH1 N'],
  "ena": ['EH1 N AH0'],
  "enable": ['EH0 N EY1 B AH0 L', 'IH0 N EY1 B AH0 L'],
  "enabled": ['EH0 N EY1 B AH0 L D', 'IH0 N EY1 B AH0 L D'],
  "enabler": ['EH0 N EY1 B AH0 L ER0', 'EH0 N EY1 B L ER0', 'IH0 N EY1 B L ER0', 'IH0 N EY1 B AH0 L ER0'],
  "enables": ['EH0 N EY1 B AH0 L Z', 'IH0 N EY1 B AH0 L Z'],
  "enabling": ['EH0 N EY1 B AH0 L IH0 NG', 'IH0 N EY1 B AH0 L IH0 NG', 'IH0 N EY1 B L IH0 NG'],
  "enact": ['IH0 N AE1 K T'],
  "enacted": ['EH0 N AE1 K T AH0 D'],
  "enacting": ['EH0 N AE1 K T IH0 NG'],
  "enactment": ['EH0 N AE1 K T M AH0 N T', 'EH0 N AE1 K M AH0 N T'],
  "enactments": ['EH2 N AE1 K T M AH0 N T S', 'EH2 N AE1 K M AH0 N T S', 'EH2 N AE1 K M AH0 N S'],
  "enacts": ['IH2 N AE1 K T S'],
  "enamel": ['IH0 N AE1 M AH0 L'],
  "enameled": ['IH0 N AE1 M AH0 L D'],
  "enamels": ['IH0 N AE1 M AH0 L Z'],
  "enamor": ['EH0 N AE1 M ER0'],
  "enamored": ['EH0 N AE1 M ER0 D'],
  "enamoring": ['EH0 N AE1 M ER0 IH0 NG'],
  "enamors": ['EH0 N AE1 M ER0 Z'],
  "enasa": ['EY0 N AA1 S AH0'],
  "enberg": ['EH1 N B ER0 G'],
  "encamp": ['IH0 N K AE1 M P'],
  "encamped": ['IH0 N K AE1 M P T'],
  "encampment": ['IH0 N K AE1 M P M IH0 N T'],
  "encampments": ['IH0 N K AE1 M P M IH0 N T S'],
  "encapsulate": ['EH0 N K AE1 P S AH0 L EY2 T'],
  "encapsulated": ['EH0 N K AE1 P S AH0 L EY2 T IH0 D'],
  "encapsulating": ['EH0 N K AE1 P S AH0 L EY2 T IH0 NG'],
  "encarnacion": ['IH0 N K AA0 R N AA0 S IY0 AO1 N'],
  "encarta": ['EH0 N K AA1 R T AH2', 'EH0 N K AA1 R T AH0'],
  "encase": ['EH0 N K EY1 S'],
  "encased": ['EH0 N K EY1 S T'],
  "encata": ['EH0 N K AA1 T AH2', 'EH0 N K AA1 T AH0'],
  "encata's": ['EH0 N K AA1 T AH2 Z', 'EH0 N K AA1 T AH0 Z'],
  "encephalitis": ['EH0 N S EH2 F AH0 L AY1 T AH0 S'],
  "encephalopathy": ['EH0 N S EH2 F AH0 L AO1 P AH0 TH IY0'],
  "enchant": ['EH0 N CH AE1 N T'],
  "enchanted": ['EH0 N CH AE1 N T IH0 D', 'EH0 N CH AE1 N IH0 D'],
  "enchanter": ['EH0 N CH AE1 N T ER0'],
  "enchanter's": ['EH0 N CH AE1 N T ER0 Z', 'IH0 N CH AE1 N T ER0 Z', 'EH0 N CH AE1 N ER0 Z', 'IH0 N CH AE1 N ER0 Z'],
  "enchanters": ['EH0 N CH AE1 N T ER0 Z', 'IH0 N CH AE1 N T ER0 Z', 'EH0 N CH AE1 N ER0 Z', 'IH0 N CH AE1 N ER0 Z'],
  "enchanting": ['EH0 N CH AE1 N T IH0 NG', 'EH0 N CH AE1 N IH0 NG'],
  "enchantment": ['EH0 N CH AE1 N T M AH0 N T'],
  "enchilada": ['EH0 N CH IH0 L AA1 D AH0'],
  "encinas": ['EH1 N S IH0 N AH0 Z'],
  "encinias": ['IH0 N S IY0 N IY1 AH0 Z'],
  "encino": ['EH0 N S IY1 N OW0'],
  "encircle": ['EH0 N S ER1 K AH0 L'],
  "encircled": ['IH0 N S ER1 K AH0 L D'],
  "encirclement": ['EH0 N S ER1 K AH0 L M AH0 N T'],
  "encircling": ['EH0 N S ER1 K AH0 L IH0 NG', 'EH0 N S ER1 K L IH0 NG'],
  "enciso": ['IH0 N S IY1 S OW0'],
  "enck": ['EH1 NG K'],
  "enclave": ['AA1 N K L EY2 V', 'EH1 N K L EY2 V'],
  "enclaves": ['AA1 N K L EY2 V Z', 'EH1 N K L EY2 V Z'],
  "enclose": ['IH0 N K L OW1 Z'],
  "enclosed": ['EH0 N K L OW1 Z D', 'IH0 N K L OW1 Z D'],
  "enclosing": ['EH0 N K L OW1 Z IH0 NG'],
  "enclosure": ['EH0 N K L OW1 ZH ER0', 'IH0 N K L OW1 ZH ER0'],
  "enclosures": ['IH0 N K L OW1 ZH ER0 Z'],
  "encode": ['EH0 N K OW1 D'],
  "encoded": ['EH0 N K OW1 D IH0 D'],
  "encoding": ['EH0 N K OW1 D IH0 NG'],
  "encomium": ['EH0 N K AO1 M IH2 AH0 M'],
  "encomiums": ['EH0 N K AO1 M IH2 AH0 M Z'],
  "encompass": ['EH0 N K AH1 M P AH0 S'],
  "encompassed": ['EH0 N K AH1 M P AH0 S T'],
  "encompasses": ['EH0 N K AH1 M P AH0 S AH0 Z'],
  "encompassing": ['EH0 N K AH1 M P AH0 S IH0 NG'],
  "encor": ['EH1 N K AO2 R'],
  "encor's": ['EH1 N K AO2 R Z'],
  "encore": ['AA1 N K AO2 R'],
  "encores": ['AA1 N K AO2 R Z'],
  "encounter": ['IH0 N K AW1 N T ER0', 'IH0 N K AW1 N ER0'],
  "encountered": ['IH0 N K AW1 N T ER0 D', 'IH0 N K AW1 N ER0 D'],
  "encountering": ['EH0 N K AW1 N T ER0 IH0 NG', 'EH0 N K AW1 N ER0 IH0 NG'],
  "encounters": ['IH0 N K AW1 N T ER0 Z', 'IH0 N K AW1 N ER0 Z'],
  "encourage": ['EH0 N K ER1 IH0 JH', 'IH0 N K ER1 AH0 JH'],
  "encouraged": ['EH0 N K ER1 IH0 JH D', 'IH0 N K ER1 AH0 JH D'],
  "encouragement": ['EH0 N K ER1 IH0 JH M AH0 N T'],
  "encourages": ['EH0 N K ER1 IH0 JH IH0 Z', 'IH0 N K ER1 AH0 JH AH0 Z'],
  "encouraging": ['EH0 N K ER1 IH0 JH IH0 NG', 'IH0 N K ER1 AH0 JH IH0 NG'],
  "encroach": ['IH0 N K R OW1 CH'],
  "encroached": ['IH0 N K R OW1 CH T'],
  "encroaches": ['IH0 N K R OW1 CH IH0 Z'],
  "encroaching": ['IH0 N K R OW1 CH IH0 NG'],
  "encroachment": ['EH0 N K R OW1 CH M AH0 N T'],
  "encroachments": ['IH0 N K R OW1 CH M AH0 N T S'],
  "encrust": ['EH0 N K R AH1 S T'],
  "encrusted": ['EH0 N K R AH1 S T IH0 D'],
  "encrusting": ['EH0 N K R AH1 S T IH0 NG'],
  "encrypt": ['EH0 N K R IH1 P T', 'IH0 N K R IH1 P T'],
  "encrypted": ['EH0 N K R IH1 P T IH0 D', 'IH0 N K R IH1 P T IH0 D'],
  "encryption": ['EH0 N K R IH1 P SH AH0 N'],
  "encumber": ['EH0 N K AH1 M B ER0'],
  "encumbered": ['EH0 N K AH1 M B ER0 D'],
  "encyclical": ['EH0 N S IH1 K L IH0 K AH0 L'],
  "encyclicals": ['EH0 N S IH1 K L IH0 K AH0 L Z'],
  "encyclopaedia": ['IH0 N S AY2 K L AH0 P IY1 D IY0 AH0', 'IH0 N S AY2 K L OW0 P IY1 D IY0 AH0'],
  "encyclopedia": ['IH0 N S AY2 K L AH0 P IY1 D IY0 AH0', 'IH0 N S AY2 K L OW0 P IY1 D IY0 AH0'],
  "encyclopedias": ['IH0 N S AY2 K L AH0 P IY1 D IY0 AH0 Z', 'IH0 N S AY2 K L OW0 P IY1 D IY0 AH0 Z'],
  "encyclopedic": ['IH0 N S AY2 K L AH0 P IY1 D IH0 K', 'IH0 N S AY2 K L OW0 P IY1 D IH0 K'],
  "encyclopedist": ['IH0 N S AY2 K L AH0 P IY1 D AH0 S T', 'IH0 N S AY2 K L OW0 P IY1 D AH0 S T'],
  "end": ['EH1 N D'],
  "endaka": ['EH0 N D AA1 K AH0'],
  "endanger": ['EH0 N D EY1 N JH ER0'],
  "endangered": ['EH0 N D EY1 N JH ER0 D', 'IH0 N D EY1 N JH ER0 D'],
  "endangering": ['EH0 N D EY1 N JH ER0 IH0 NG'],
  "endangerment": ['EH0 N D EY1 N JH ER0 M AH0 N T'],
  "endangers": ['EH0 N D EY1 N JH ER0 Z'],
  "endara": ['EH1 N D AA1 R AH0'],
  "ende": ['EH1 N D'],
  "endear": ['EH0 N D IY1 R'],
  "endeared": ['EH0 N D IY1 R D'],
  "endearing": ['EH0 N D IY1 R IH0 NG'],
  "endearment": ['IH0 N D IH1 R M AH0 N T'],
  "endeavor": ['IH0 N D EH1 V ER0'],
  "endeavor's": ['IH0 N D EH1 V ER0 Z'],
  "endeavored": ['IH0 N D EH1 V ER0 D'],
  "endeavoring": ['IH0 N D EH1 V ER0 IH0 NG', 'IH0 N D EH1 V R IH0 NG'],
  "endeavors": ['IH0 N D EH1 V ER0 Z'],
  "endeavour": ['IH0 N D EH1 V ER0'],
  "endeavour's": ['IH0 N D EH1 V ER0 Z'],
  "ended": ['EH1 N D AH0 D', 'EH1 N D IH0 D'],
  "endelman": ['EH1 N D AH0 L M AH0 N'],
  "endemic": ['EH0 N D EH1 M IH0 K'],
  "ender": ['EH1 N D ER0'],
  "enderby": ['EH1 N D ER0 B IY0'],
  "enderle": ['EH1 N D ER0 AH0 L'],
  "enderlin": ['EH1 N D ER0 L IH0 N'],
  "enders": ['EH1 N D ER0 Z'],
  "enderson": ['EH1 N D ER0 S AH0 N'],
  "endevco": ['EH0 N D EH1 V K OW0'],
  "endgame": ['EH1 N D G EY0 M'],
  "endgames": ['EH1 N D G EY0 M Z'],
  "endicott": ['EH1 N D IH0 K AA2 T'],
  "ending": ['EH1 N D IH0 NG'],
  "endings": ['EH1 N D IH0 NG Z'],
  "endive": ['EH1 N D IH0 V'],
  "endler": ['EH1 N D L ER0'],
  "endless": ['EH1 N D L AH0 S'],
  "endlessly": ['EH1 N D L AH0 S L IY0'],
  "endlich": ['EH1 N D L IH0 K'],
  "endo": ['EH1 N D OW0'],
  "endocrine": ['EH1 N D OW0 K R AY2 N'],
  "endocrinologist": ['EH2 N D OW0 K R AH0 N AA1 L AH0 JH AH0 S T'],
  "endocrinologist's": ['EH2 N D OW0 K R AH0 N AA1 L AH0 JH AH0 S T S'],
  "endocrinologists": ['EH2 N D OW0 K R AH0 N AA1 L AH0 JH AH0 S T S'],
  "endocrinology": ['EH2 N D OW0 K R AH0 N AA1 L AH0 JH IY0'],
  "endodermal": ['EH2 N D OW0 D ER1 M AH0 L'],
  "endogenous": ['EH2 N D AO1 JH EH0 N AH0 S'],
  "endometrial": ['EH2 N D OW0 M EH2 T R IY0 AH0 L'],
  "endometriosis": ['EH2 N D OW0 M EH2 T R IY0 OW1 S IH0 S'],
  "endorphin": ['EH0 N D AO1 R F IH0 N'],
  "endorphins": ['EH0 N D AO1 R F IH0 N Z'],
  "endorse": ['EH0 N D AO1 R S'],
  "endorsed": ['EH0 N D AO1 R S T'],
  "endorsement": ['EH0 N D AO1 R S M AH0 N T'],
  "endorsements": ['EH0 N D AO1 R S M AH0 N T S'],
  "endorser": ['IH0 N D AO1 R S ER0'],
  "endorsers": ['IH0 N D AO1 R S ER0 Z'],
  "endorses": ['EH0 N D AO1 R S IH0 Z'],
  "endorsing": ['EH0 N D AO1 R S IH0 NG'],
  "endoscope": ['EH1 N D OW0 S K OW2 P'],
  "endoscopic": ['EH2 N D OW0 S K AA1 P IH0 K'],
  "endoscopy": ['EH2 N D AO1 S K AA0 P IY0'],
  "endosperm": ['EH1 N D AH0 S P ER2 M'],
  "endothelial": ['EH2 N D OW0 TH IY1 L IY2 AH0 L'],
  "endothermic": ['EH2 N D OW0 TH ER1 M IH0 K'],
  "endotronics": ['EH2 N D OW0 T R AA1 N IH0 K S'],
  "endotronics'": ['EH2 N D AH0 T R AA1 N IH0 K S'],
  "endotronics's": ['EH2 N D OW0 T R AA1 N IH0 K S IH0 Z'],
  "endow": ['EH0 N D AW1'],
  "endowed": ['EH0 N D AW1 D'],
  "endowing": ['EH0 N D AW1 IH0 NG'],
  "endowment": ['EH0 N D AW1 M AH0 N T'],
  "endowment's": ['EH0 N D AW1 M AH0 N T S'],
  "endowments": ['EH0 N D AW1 M AH0 N T S'],
  "endows": ['EH0 N D AW1 Z'],
  "endpoint": ['EH1 N D P OY2 N T'],
  "endpoints": ['EH1 N D P OY2 N T S'],
  "endproduct": ['EH1 N D P R AO2 D AH0 K T'],
  "endproducts": ['EH1 N D P R AO2 D AH0 K T S'],
  "endres": ['EH1 N D ER0 Z'],
  "endress": ['EH1 N D R IH0 S'],
  "endrizzi": ['IH0 N D R IY1 T S IY0'],
  "ends": ['EH1 N D Z'],
  "endsley": ['EH1 N D S L IY0'],
  "endtimer": ['EH1 N D T AY0 M ER0'],
  "endtimers": ['EH1 N D T AY0 M ER0 Z'],
  "endued": ['EH0 N D UW1 D'],
  "endurance": ['EH1 N D ER0 AH0 N S'],
  "endure": ['EH0 N D Y UH1 R', 'IH0 N D UH1 R'],
  "endured": ['EH0 N D Y UH1 R D', 'IH0 N D UH1 R D'],
  "endures": ['EH0 N D Y UH1 R Z', 'IH0 N D UH1 R Z'],
  "enduring": ['EH0 N D Y UH1 R IH0 NG', 'IH0 N D UH1 R IH0 NG'],
  "enduser": ['EH1 N D Y UW2 Z ER0'],
  "endusers": ['EH1 N D Y UW2 Z ER0 Z'],
  "endy": ['EH1 N D IY0'],
  "enea": ['EH1 N IY0 AH0'],
  "enema": ['EH1 N AH0 M AH0'],
  "enemas": ['EH1 N AH0 M AH0 Z'],
  "enemies": ['EH1 N AH0 M IY0 Z'],
  "enemies'": ['EH1 N AH0 M IY0 Z'],
  "enemy": ['EH1 N AH0 M IY0'],
  "enemy's": ['EH1 N AH0 M IY0 Z'],
  "energas": ['EH1 N ER0 G AE2 S'],
  "energen": ['EH1 N ER0 JH EH2 N'],
  "energetic": ['EH2 N ER0 JH EH1 T IH0 K'],
  "energetically": ['EH2 N ER0 JH EH1 T IH0 K L IY0'],
  "energies": ['EH1 N ER0 JH IY0 Z'],
  "energize": ['EH1 N ER0 JH AY2 Z'],
  "energized": ['EH1 N ER0 JH AY2 Z D'],
  "energizer": ['EH1 N ER0 JH AY2 Z ER0'],
  "energizes": ['EH1 N ER0 JH AY2 Z IH0 Z'],
  "energizing": ['EH1 N ER0 JH AY2 Z IH0 NG'],
  "energy": ['EH1 N ER0 JH IY0'],
  "energy's": ['EH1 N ER0 JH IY0 Z'],
  "enerson": ['EH1 N ER0 S AH0 N'],
  "enex": ['IY1 N AH0 K S'],
  "enfant": ['EH1 N F AA2 N T', 'AA2 N F AA1 N T'],
  "enfeeble": ['EH0 N F IY1 B AH0 L'],
  "enfeebled": ['EH0 N F IY1 B AH0 L D'],
  "enfield": ['EH1 N F IY0 L D'],
  "enfield's": ['EH1 N F IY0 L D Z'],
  "enfinger": ['EH1 N F IH0 NG ER0'],
  "enfold": ['IH0 N F OW1 L D'],
  "enfor": ['EH1 N F ER0'],
  "enforce": ['EH0 N F AO1 R S'],
  "enforceability": ['EH0 N F AO2 R S AH0 B IH1 L IH0 T IY0'],
  "enforceable": ['EH0 N F AO1 R S AH0 B AH0 L'],
  "enforced": ['EH0 N F AO1 R S T'],
  "enforcement": ['EH0 N F AO1 R S M AH0 N T'],
  "enforcement's": ['EH0 N F AO1 R S M AH0 N T S'],
  "enforcements": ['EH0 N F AO1 R S M AH0 N T S'],
  "enforcer": ['EH0 N F AO1 R S ER0'],
  "enforcers": ['EH0 N F AO1 R S ER0 Z'],
  "enforces": ['EH0 N F AO1 R S IH0 Z'],
  "enforcing": ['EH0 N F AO1 R S IH0 NG'],
  "enfranchise": ['IH0 N F R AE1 N CH AY2 Z'],
  "enfranchised": ['EH0 N F R AE1 N CH AY2 Z D'],
  "enfranchises": ['EH0 N F R AE1 N CH AY2 Z IH0 Z'],
  "eng": ['EH1 NG'],
  "engage": ['EH0 N G EY1 JH'],
  "engaged": ['EH0 N G EY1 JH D'],
  "engagement": ['EH0 N G EY1 JH M AH0 N T'],
  "engagements": ['EH0 N G EY1 JH M AH0 N T S'],
  "engages": ['EH0 N G EY1 JH IH0 Z'],
  "engaging": ['EH0 N G EY1 JH IH0 NG'],
  "engberg": ['EH1 NG B ER0 G'],
  "engdahl": ['EH1 NG D AA0 L'],
  "enge": ['EH1 N JH'],
  "engebretsen": ['EH1 NG G IH0 B R IH0 T S AH0 N'],
  "engebretson": ['EH1 NG G IH0 B R IH0 T S AH0 N'],
  "engel": ['EH1 N G AH0 L'],
  "engelberg": ['EH1 NG G AH0 L B ER0 G'],
  "engelbert": ['EH1 NG G IH0 L B ER0 T'],
  "engelberta": ['EH0 NG G EH0 L B EH1 R T AH0'],
  "engelbrecht": ['EH1 NG G IH0 L B R IH0 K T'],
  "engeleiter": ['EH1 NG G AH0 L AY2 T ER0'],
  "engelhard": ['EH1 NG G AH0 L HH AA2 R D'],
  "engelhardt": ['EH1 NG G IH0 L HH AA0 R T'],
  "engelhart": ['EH1 NG G AH0 L HH AA2 R T'],
  "engelke": ['EH1 NG G IH0 L K'],
  "engelken": ['EH1 NG G IH0 L K AH0 N'],
  "engelking": ['EH1 NG G IH0 L K IH0 NG'],
  "engelman": ['EH1 NG G AH0 L M AH0 N'],
  "engelmann": ['EH1 NG G AH0 L M AH0 N'],
  "engels": ['EH1 NG G AH0 L Z'],
  "engelson": ['EH1 NG G IH0 L S AH0 N'],
  "engelstad": ['EH1 NG G IH0 L S T AH0 D'],
  "engeman": ['EH1 N JH M AH0 N'],
  "engen": ['EH1 NG AH0 N'],
  "engender": ['EH0 N JH EH1 N D ER0', 'IH0 N JH EH1 N D ER0'],
  "engendered": ['EH0 N JH EH1 N D ER0 D'],
  "engenders": ['EH1 NG G AH0 N D ER0 Z'],
  "enger": ['EH1 NG G ER0'],
  "engert": ['EH1 NG G ER0 T'],
  "engesser": ['EH1 NG G IH0 S ER0'],
  "engh": ['EH1 NG'],
  "engholm": ['EH1 NG HH OW0 L M'],
  "engine": ['EH1 N JH AH0 N', 'IH1 N JH AH0 N'],
  "engine's": ['EH1 N JH AH0 N Z'],
  "engined": ['EH1 N JH AH0 N D'],
  "engineer": ['EH1 N JH AH0 N IH1 R'],
  "engineer's": ['EH2 N JH AH0 N IY1 R Z'],
  "engineered": ['EH2 N JH AH0 N IY1 R D'],
  "engineering": ['EH1 N JH AH0 N IH1 R IH0 NG'],
  "engineers": ['EH1 N JH AH0 N IH1 R Z'],
  "engineers'": ['EH1 N JH AH0 N IH1 R Z'],
  "engines": ['EH1 N JH AH0 N Z'],
  "engines'": ['EH1 NG G IY2 N Z'],
  "england": ['IH1 NG G L AH0 N D'],
  "england's": ['IH1 NG G L AH0 N D Z'],
  "englander": ['IH1 NG G L AH0 N D ER0'],
  "englanders": ['IH1 NG G L AH0 N D ER0 Z'],
  "englands": ['IH1 NG G L AH0 N D Z'],
  "englbred": ['EH1 G AH0 L B R EH2 D'],
  "engle": ['EH1 NG G AH0 L'],
  "englebert": ['IH1 NG AH0 L B ER0 T'],
  "englehardt": ['IH1 NG AH0 L HH AA0 R T'],
  "englehart": ['IH1 NG AH0 L HH AA0 R T'],
  "engleman": ['IH1 NG AH0 L M AH0 N'],
  "engler": ['EH1 NG G AH0 L ER0', 'EH1 NG G L ER0'],
  "engler's": ['EH1 NG G AH0 L ER0 Z', 'EH1 NG G L ER0 Z'],
  "englert": ['IH1 NG L ER0 T'],
  "englerth": ['IH1 NG L ER0 TH'],
  "engles": ['IH1 NG AH0 L Z'],
  "englewood": ['EH1 NG G AH0 L W UH2 D'],
  "english": ['IH1 NG G L IH0 SH', 'IH1 NG L IH0 SH'],
  "englishman": ['IH1 NG G L IH0 SH M AH0 N'],
  "englishmen": ['EH2 NG L IH1 SH M AH0 N'],
  "englishwoman": ['IH1 NG G L IH0 SH W UH2 M AH0 N'],
  "englund": ['IH1 NG L AH0 N D'],
  "engman": ['EH1 NG M AH0 N'],
  "engquist": ['EH1 NG K W IH0 S T'],
  "engram": ['EH1 N G R AE2 M'],
  "engrave": ['IH0 N G R EY1 V'],
  "engraved": ['IH0 N G R EY1 V D'],
  "engraver": ['IH0 N G R EY1 V ER0'],
  "engraving": ['IH0 N G R EY1 V IH0 NG'],
  "engravings": ['IH0 N G R EY1 V IH0 NG Z'],
  "engross": ['IH0 N G R OW1 S'],
  "engrossed": ['IH0 N G R OW1 S T'],
  "engrossing": ['IH0 N G R OW1 S IH0 NG'],
  "engrossment": ['IH0 N G R OW1 S M AH0 N T'],
  "engstrand": ['EH1 NG S T R AH0 N D'],
  "engstrom": ['EH1 NG S T R AH0 M'],
  "engulf": ['IH0 N G AH1 L F'],
  "engulfed": ['IH0 N G AH1 L F T'],
  "engulfing": ['IH0 N G AH1 L F IH0 NG'],
  "engwall": ['IH0 NG W AO1 L'],
  "enhance": ['EH0 N HH AE1 N S'],
  "enhanced": ['EH0 N HH AE1 N S T', 'IH0 N HH AE1 N S T'],
  "enhancement": ['EH0 N HH AE1 N S M AH0 N T'],
  "enhancements": ['EH0 N HH AE1 N S M AH0 N T S'],
  "enhancer": ['EH0 N HH AE1 N S ER0'],
  "enhances": ['EH0 N HH AE1 N S IH0 Z'],
  "enhancing": ['EH0 N HH AE1 N S IH0 NG'],
  "enholm": ['EH1 N HH OW2 L M'],
  "enholme": ['EH1 N HH OW2 L M'],
  "eniac": ['IY1 N IY0 AE2 K'],
  "enichem": ['EH1 N IH0 CH AH0 M'],
  "enid": ['IY1 N IH0 D'],
  "enigma": ['IH0 N IH1 G M AH0'],
  "enigmatic": ['EH2 N IH0 G M AE1 T IH0 K'],
  "enimont": ['IY1 N IH0 M AA2 N T'],
  "enis": ['EH1 N IH0 S'],
  "enitt": ['EH1 N IH0 T'],
  "enix": ['EH1 N IH0 K S'],
  "enjoin": ['EH2 N JH OY1 N', 'IH0 N JH OY1 N'],
  "enjoined": ['EH2 N JH OY1 N D'],
  "enjoining": ['EH2 N JH OY1 N IH0 NG'],
  "enjoy": ['EH2 N JH OY1', 'IH0 N JH OY1'],
  "enjoyable": ['EH2 N JH OY1 AH0 B AH0 L'],
  "enjoyably": ['EH2 N JH OY1 AH0 B L IY0'],
  "enjoyed": ['EH2 N JH OY1 D', 'IH0 N JH OY1 D'],
  "enjoying": ['EH2 N JH OY1 IH0 NG', 'IH0 N JH OY1 IH0 NG'],
  "enjoyment": ['EH2 N JH OY1 M AH0 N T', 'IH0 N JH OY1 M AH0 N T'],
  "enjoyments": ['EH0 N JH OY1 M AH0 N T S'],
  "enjoys": ['EH2 N JH OY1 Z', 'IH0 N JH OY1 Z'],
  "enke": ['EH1 NG K'],
  "enlarge": ['EH2 N L AA1 R JH', 'IH0 N L AA1 R JH'],
  "enlarged": ['EH2 N L AA1 R JH D', 'IH0 N L AA1 R JH D'],
  "enlargement": ['IH0 N L AA1 R JH M AH0 N T'],
  "enlargements": ['IH0 N L AA1 R JH M AH0 N T S'],
  "enlarger": ['IH0 N L AA1 R G ER0'],
  "enlarges": ['IH0 N L AA1 R JH IH0 Z'],
  "enlarging": ['IH0 N L AA1 R JH IH0 NG'],
  "enlighten": ['EH2 N L AY1 T AH0 N'],
  "enlightened": ['EH2 N L AY1 T AH0 N D'],
  "enlightening": ['EH2 N L AY1 T AH0 N IH0 NG', 'EH0 N L AY1 T N IH0 NG'],
  "enlightenment": ['EH2 N L AY1 T AH0 N M AH0 N T'],
  "enlightens": ['EH2 N L AY1 T AH0 N Z'],
  "enlist": ['EH2 N L IH1 S T'],
  "enlisted": ['EH2 N L IH1 S T IH0 D', 'IH0 N L IH1 S T AH0 D'],
  "enlistee": ['IH0 N L IH2 S T IY1'],
  "enlistees": ['IH0 N L IH2 S T IY1 Z'],
  "enlisting": ['EH0 N L IH1 S T IH0 NG', 'IH0 N L IH1 S T IH0 NG'],
  "enlistment": ['EH0 N L IH1 S T M AH0 N T'],
  "enlists": ['EH0 N L IH1 S T S'],
  "enliven": ['EH0 N L AY1 V AH0 N'],
  "enlivened": ['EH0 N L AY1 V AH0 N D'],
  "enlivening": ['EH0 N L AY1 V AH0 N IH0 NG'],
  "enlivens": ['EH0 N L AY1 V AH0 N Z'],
  "enlo": ['EH1 N L OW0'],
  "enloe": ['IH0 N L OW1'],
  "enlow": ['IH0 N L OW1'],
  "enman": ['EH1 N M AH0 N'],
  "enmesh": ['EH0 N M EH1 SH'],
  "enmeshed": ['EH0 N M EH1 SH T'],
  "enmeshes": ['EH0 N M EH1 SH AH0 Z'],
  "enmeshing": ['EH0 N M EH1 SH IH0 NG'],
  "enmities": ['EH1 N M AH0 T IY0 Z', 'EH1 N M IH0 T IY0 Z'],
  "enmity": ['EH1 N M AH0 T IY0', 'EH1 N M IH0 T IY0'],
  "ennea": ['EH1 N IY0 AH0'],
  "enneking": ['EH1 N IH0 K IH0 NG'],
  "ennen": ['EH1 N AH0 N'],
  "ennes": ['EH1 N Z', 'EH1 N EH0 Z'],
  "ennis": ['EH1 N IH0 S'],
  "ennoble": ['IH0 N OW1 B AH0 L'],
  "ennobled": ['IH0 N OW1 B AH0 L D'],
  "ennobles": ['IH0 N OW1 B AH0 L Z'],
  "ennobling": ['IH0 N OW1 B L IH0 NG'],
  "ennosuke": ['EH1 N AH0 S UW0 K'],
  "enns": ['EH1 N Z'],
  "ennui": ['EH0 N UW1 IY0'],
  "eno": ['EH1 N OW0'],
  "enoch": ['IY1 N AH0 K'],
  "enochs": ['EH1 N AH0 K S'],
  "enojada": ['EH2 N OW0 JH AA1 D AH0'],
  "enola": ['IH0 N OW1 L AH0'],
  "enomoto": ['IH0 N OW0 M OW1 T OW0'],
  "enormity": ['IH0 N AO1 R M AH0 T IY0', 'IY0 N AO1 R M AH0 T IY0'],
  "enormous": ['IH0 N AO1 R M AH0 S', 'IH0 N AO1 R M IH0 S', 'IY0 N AO1 R M AH0 S', 'IY0 N AO1 R M IH0 S'],
  "enormously": ['IH0 N AO1 R M AH0 S L IY0', 'IY0 N AO1 R M AH0 S L IY0'],
  "enos": ['IY1 N AH0 S'],
  "enough": ['IH0 N AH1 F', 'IY0 N AH1 F'],
  "enough's": ['IH0 N AH1 F S', 'IY0 N AH1 F S'],
  "enqueso": ['EH0 N K W EH1 S OW0'],
  "enquire": ['IH0 N K W AY1 ER0'],
  "enquirer": ['IH0 N K W AY1 R ER0'],
  "enquiry": ['IH0 N K W AY1 R IY2'],
  "enquist": ['EH1 N K W IH2 S T'],
  "enrage": ['EH0 N R EY1 JH'],
  "enraged": ['EH0 N R EY1 JH D', 'IH0 N R EY1 JH D'],
  "enrages": ['EH0 N R EY1 JH AH0 Z'],
  "enraging": ['EH0 N R EY1 JH IH0 NG'],
  "enrapture": ['EH0 N R AE1 P CH ER0'],
  "enraptured": ['EH0 N R AE1 P CH ER0 D'],
  "enrica": ['IH0 N R IY1 K AH0'],
  "enrich": ['EH0 N R IH1 CH', 'IH0 N R IH1 CH'],
  "enriched": ['EH0 N R IH1 CH T'],
  "enriches": ['EH0 N R IH1 CH IH0 Z'],
  "enriching": ['EH0 N R IH1 CH IH0 NG', 'IH0 N R IH1 CH IH0 NG'],
  "enrichment": ['EH0 N R IH1 CH M AH0 N T', 'IH0 N R IH1 CH M AH0 N T'],
  "enrico": ['EH0 N R IY1 K OW0'],
  "enright": ['IH0 N R AY1 T'],
  "enrile": ['EH0 N R IY1 L', 'EH0 N R IY1 L EY2'],
  "enrique": ['EH0 N R IY1 K EY0'],
  "enriquez": ['IH0 N R IY1 K W EH0 Z'],
  "enro": ['EH1 N R OW0'],
  "enroll": ['EH0 N R OW1 L', 'IH0 N R OW1 L'],
  "enrolled": ['EH0 N R OW1 L D'],
  "enrollee": ['EH0 N R OW1 L IY1'],
  "enrollees": ['EH0 N R OW1 L IY1 Z'],
  "enrolling": ['EH0 N R OW1 L IH0 NG'],
  "enrollment": ['EH0 N R OW1 L M AH0 N T'],
  "enrollments": ['EH0 N R OW1 L M AH0 N T S'],
  "enrolls": ['EH0 N R OW1 L Z'],
  "enron": ['EH1 N R AA0 N'],
  "enron's": ['EH1 N R AA0 N Z'],
  "enroute": ['EH0 N R UW1 T'],
  "ensco": ['EH1 N S K OW0'],
  "ensco's": ['EH1 N S K OW0 Z'],
  "ensconce": ['IH0 N S K AA1 N S'],
  "ensconced": ['IH0 N S K AA1 N S T'],
  "enseco": ['EH0 N S EH1 K OW0'],
  "ensemble": ['AA0 N S AA1 M B AH0 L'],
  "ensembles": ['AA0 N S AA1 M B AH0 L Z'],
  "enserch": ['EH1 N S ER0 CH'],
  "ensey": ['EH1 N Z IY0'],
  "enshrine": ['EH0 N SH R AY1 N'],
  "enshrined": ['EH0 N SH R AY1 N D'],
  "enshroud": ['IH0 N SH R AW1 D'],
  "enshrouded": ['IH0 N SH R AW1 D AH0 D'],
  "ensign": ['EH1 N S AH0 N'],
  "ensing": ['EH1 N S IH0 NG'],
  "ensinger": ['EH1 N S IH0 N JH ER0'],
  "enskilda": ['EH0 N S K IH1 L D AH0'],
  "enslave": ['EH0 N S L EY1 V'],
  "enslaved": ['EH0 N S L EY1 V D'],
  "enslavement": ['EH0 N S L EY1 V M AH0 N T'],
  "enslen": ['EH1 N S AH0 L AH0 N'],
  "ensley": ['EH1 N S L IY0'],
  "enslin": ['EH1 N S L IH0 N'],
  "enslow": ['IH0 N S L OW1'],
  "ensminger": ['EH1 N S AH0 M IH0 NG ER0'],
  "ensnare": ['IH0 N S N EH1 R'],
  "ensnared": ['IH0 N S N EH1 R D'],
  "ensnares": ['IH0 N S N EH1 R Z'],
  "ensnarl": ['IH0 N S N AA1 R L'],
  "ensnarled": ['IH0 N S N AA1 R L D'],
  "ensor": ['EH1 N S ER0'],
  "ensource": ['EH0 N S AO1 R S'],
  "ensrud": ['EH1 N Z R UW2 D'],
  "enstrom": ['EH1 N S T R AH0 M'],
  "ensue": ['IH0 N S UW1'],
  "ensued": ['IH0 N S UW1 D'],
  "ensues": ['IH0 N S UW1 Z'],
  "ensuing": ['EH1 N S UW0 IH0 NG'],
  "ensure": ['EH0 N SH UH1 R', 'IH0 N SH UH1 R'],
  "ensured": ['EH0 N SH UH1 R D', 'IH0 N SH UH1 R D'],
  "ensures": ['EH0 N SH UH1 R Z', 'IH0 N SH UH1 R Z'],
  "ensuring": ['EH0 N SH UH1 R IH0 NG', 'IH0 N SH UH1 R IH0 NG'],
  "ensz": ['EH1 N SH'],
  "ent": ['EH1 N T'],
  "entail": ['EH0 N T EY1 L'],
  "entailed": ['IH0 N T EY1 L D'],
  "entailing": ['IH0 N T EY1 L IH0 NG'],
  "entails": ['IH0 N T EY1 L Z'],
  "entangle": ['EH0 N T AE1 NG G AH0 L'],
  "entangled": ['EH0 N T AE1 NG G AH0 L D'],
  "entanglement": ['EH0 N T AE1 NG G AH0 L M AH0 N T', 'IH0 N T AE1 NG G AH0 L M AH0 N T'],
  "entanglements": ['EH0 N T AE1 NG G AH0 L M AH0 N T S', 'IH0 N T AE1 NG G AH0 L M AH0 N T S'],
  "entangling": ['IH0 N T AE1 NG L IH0 NG', 'EH0 N T AE1 NG L IH0 NG'],
  "ente": ['EH1 N T EY0'],
  "entebbe": ['EH2 N T EH1 B IY0'],
  "entebbe's": ['EH2 N T EH1 B IY0 Z'],
  "entendre": ['AA0 N T AA1 N D R AH0'],
  "entenmann": ['EH1 N T AH0 N M AH0 N'],
  "entenmann's": ['EH1 N T AH0 N M AH0 N Z'],
  "enter": ['EH1 N T ER0', 'EH1 N ER0'],
  "entered": ['EH1 N T ER0 D', 'EH1 N ER0 D'],
  "entergy": ['EH1 N T ER0 JH IY0'],
  "entering": ['EH1 N T ER0 IH0 NG', 'EH1 N ER0 IH0 NG'],
  "enteritidis": ['EH2 N T ER0 IH1 T IH0 D IH0 S'],
  "enterividous": ['EH2 N T ER0 IH1 V IH0 D AH0 S'],
  "enterline": ['EH1 N T ER0 L AY2 N'],
  "enterline's": ['EH1 N T ER0 L AY2 N Z'],
  "enterococcus": ['EH2 N T ER0 AH0 K AO1 K AH0 S'],
  "enterology": ['EH2 N T ER0 AA1 L AH0 JH IY0'],
  "enterprise": ['EH1 N T ER0 P R AY2 Z', 'EH1 N ER0 P R AY2 Z'],
  "enterprise's": ['EH1 N T ER0 P R AY2 Z AH0 Z', 'EH1 N T ER0 P R AY2 Z IH0 Z', 'EH1 N ER0 P R AY2 Z AH0 Z', 'EH1 N ER0 P R AY2 Z IH0 Z'],
  "enterprises": ['EH1 N T ER0 P R AY2 Z IH0 Z', 'EH1 N ER0 P R AY2 Z IH0 Z'],
  "enterprises'": ['EH1 N T ER0 P R AY2 Z IH0 Z', 'EH1 N ER0 P R AY2 Z IH0 Z'],
  "enterprising": ['EH1 N T ER0 P R AY2 Z IH0 NG', 'EH1 N ER0 P R AY2 Z IH0 NG'],
  "enterra": ['EH0 N T EH1 R AH0'],
  "enters": ['EH1 N T ER0 Z', 'EH1 N ER0 Z'],
  "entertain": ['EH2 N T ER0 T EY1 N', 'EH2 N ER0 T EY1 N'],
  "entertained": ['EH2 N T ER0 T EY1 N D', 'EH2 N ER0 T EY1 N D'],
  "entertainer": ['EH2 N T ER0 T EY1 N ER0', 'EH2 N ER0 T EY1 N ER0'],
  "entertainer's": ['EH2 N T ER0 T EY1 N ER0 Z', 'EH2 N ER0 T EY1 N ER0 Z'],
  "entertainers": ['EH2 N T ER0 T EY1 N ER0 Z', 'EH2 N ER0 T EY1 N ER0 Z'],
  "entertaining": ['EH2 N T ER0 T EY1 N IH0 NG', 'EH2 N ER0 T EY1 N IH0 NG'],
  "entertainment": ['EH2 N T ER0 T EY1 N M AH0 N T', 'EH2 N ER0 T EY1 N M AH0 N T'],
  "entertainment's": ['EH2 N T ER0 T EY1 N M AH0 N T S', 'EH2 N ER0 T EY1 N M AH0 N T S'],
  "entertainments": ['EH2 N T ER0 T EY1 N M AH0 N T S', 'EH2 N ER0 T EY1 N M AH0 N T S'],
  "entertains": ['EH2 N T ER0 T EY1 N Z', 'EH2 N ER0 T EY1 N Z'],
  "entex": ['EH1 N T EH2 K S'],
  "enthral": ['EH0 N TH R AO1 L'],
  "enthralled": ['EH0 N TH R AO1 L D'],
  "enthuse": ['IH0 N TH UW1 Z'],
  "enthused": ['IH0 N TH UW1 Z D'],
  "enthusiasm": ['IH0 N TH UW1 Z IY0 AE2 Z AH0 M'],
  "enthusiasms": ['IH0 N TH UW1 Z IY0 AE2 Z AH0 M Z'],
  "enthusiast": ['EH0 N TH UW1 Z IY0 AE2 S T'],
  "enthusiastic": ['IH0 N TH UW2 Z IY0 AE1 S T IH0 K'],
  "enthusiastically": ['IH0 N TH UW2 Z IY0 AE1 S T IH0 K L IY0'],
  "enthusiasts": ['EH0 N TH UW1 Z IY0 AE2 S T S'],
  "entice": ['IH0 N T AY1 S'],
  "enticed": ['IH0 N T AY1 S T'],
  "enticement": ['IH0 N T AY1 S M AH0 N T'],
  "enticements": ['IH0 N T AY1 S M AH0 N T S'],
  "enticing": ['EH0 N T AY1 S IH0 NG'],
  "entin": ['EH1 N T IH0 N'],
  "entire": ['IH0 N T AY1 ER0'],
  "entirely": ['IH0 N T AY1 ER0 L IY0'],
  "entirety": ['IH0 N T AY1 ER0 T IY0'],
  "entities": ['EH1 N T IH0 T IY0 Z'],
  "entities'": ['EH1 N T IH0 T IY0 Z'],
  "entitle": ['EH0 N T AY1 T AH0 L', 'IH0 N T AY1 T AH0 L'],
  "entitled": ['EH0 N T AY1 T AH0 L D'],
  "entitlement": ['EH0 N T AY1 T AH0 L M AH0 N T'],
  "entitlements": ['EH0 N T AY1 T AH0 L M AH0 N T S'],
  "entitles": ['EH0 N T AY1 T AH0 L Z'],
  "entitling": ['EH0 N T AY1 T AH0 L IH0 NG', 'EH0 N T AY1 T L IH0 NG'],
  "entity": ['EH1 N T AH0 T IY0', 'EH1 N T IH0 T IY0'],
  "entity's": ['EH1 N T AH0 T IY0 Z'],
  "entler": ['EH1 N T L ER0'],
  "entoffen": ['EH1 N T AH0 F AH0 N'],
  "entoffen's": ['EH1 N T AH0 F AH0 N Z'],
  "entomb": ['EH0 N T UW1 M'],
  "entombed": ['EH0 N T UW1 M D'],
  "entombment": ['IH0 N T UW1 M M AH0 N T'],
  "entomologist": ['EH2 N T AH0 M AA1 L AH0 JH AH0 S T'],
  "entomologists": ['EH2 N T AH0 M AA1 L AH0 JH AH0 S T S'],
  "entomology": ['EH2 N T AH0 M AA1 L AH0 JH IY0'],
  "entourage": ['AA2 N T UH0 R AA1 ZH', 'AA2 N T ER0 AA1 ZH'],
  "entourages": ['AA2 N T UH0 R AA1 ZH AH0 Z', 'AA2 N T ER0 AA1 ZH AH0 Z'],
  "entrail": ['EH1 N T R AH0 L'],
  "entrails": ['EH1 N T R AH0 L Z'],
  "entrance": ['EH1 N T R AH0 N S'],
  "entranced": ['IH0 N T R AE1 N S T'],
  "entrances": ['EH1 N T R AH0 N S AH0 Z'],
  "entrant": ['EH1 N T R AH0 N T'],
  "entrants": ['EH1 N T R AH0 N T S'],
  "entrap": ['IH0 N T R AE1 P'],
  "entrapment": ['IH0 N T R AE1 P M AH0 N T'],
  "entrapped": ['IH0 N T R AE1 P T'],
  "entre": ['AA1 N T R EY0', 'AA1 N T R AH0'],
  "entreaties": ['EH0 N T R IY1 T IY0 Z'],
  "entreating": ['EH0 N T R IY1 T IH0 NG'],
  "entreaty": ['EH0 N T R IY1 T IY0'],
  "entree": ['AA1 N T R EY2'],
  "entrees": ['AA1 N T R EY2 Z'],
  "entregrowth": ['EH1 N T R AH0 G R OW0 TH'],
  "entrekin": ['EH1 N T R IH0 K IH0 N'],
  "entrench": ['EH0 N T R EH1 N CH'],
  "entrenched": ['EH0 N T R EH1 N CH T', 'IH0 N T R EH1 N CH T'],
  "entrenches": ['EH0 N T R EH1 N CH IH0 Z'],
  "entrenching": ['EH0 N T R EH1 N CH IH0 NG'],
  "entrenchment": ['EH0 N T R EH1 N CH M AH0 N T'],
  "entrepreneur": ['AA2 N T R AH0 P R AH0 N ER1', 'AA2 N T R AH0 P R AH0 N UH1 R'],
  "entrepreneur's": ['AA2 N T R AH0 P R AH0 N ER1 Z'],
  "entrepreneurial": ['AA2 N T R AH0 P R AH0 N ER1 IY0 AH0 L'],
  "entrepreneurialism": ['EH2 N T R AH0 P R AH0 N UW2 R IY1 AH0 L IH2 Z AH0 M'],
  "entrepreneurs": ['AA2 N T R AH0 P R AH0 N ER1 Z'],
  "entrepreneurship": ['AA2 N T R AH0 P R AH0 N ER1 SH IH0 P'],
  "entries": ['EH1 N T R IY0 Z'],
  "entrikin": ['EH1 N T R IH0 K IH0 N'],
  "entringer": ['EH1 N T ER0 IH0 NG ER0'],
  "entropy": ['EH1 N T R AH0 P IY0'],
  "entrust": ['EH0 N T R AH1 S T'],
  "entrusted": ['EH0 N T R AH1 S T IH0 D'],
  "entrusting": ['EH0 N T R AH1 S T IH0 NG'],
  "entry": ['EH1 N T R IY0'],
  "entryway": ['EH1 N T R IY0 W EY0'],
  "entsminger": ['EH1 N T S AH0 M IH0 NG ER0'],
  "entwine": ['EH0 N T W AY1 N'],
  "entwined": ['EH0 N T W AY1 N D'],
  "entwisle": ['EH1 N T W AY0 AH0 L'],
  "entwistle": ['IH0 N T W IH1 S AH0 L'],
  "entz": ['EH1 N T S'],
  "entzminger": ['EH1 N T S M IH0 NG ER0'],
  "enumerate": ['IH0 N UW1 M ER0 EY2 T'],
  "enumerated": ['IH0 N UW1 M ER0 EY2 T IH0 D'],
  "enumerates": ['IH0 N UW1 M ER0 EY2 T S'],
  "enumeration": ['IH0 N UW2 M ER0 EY1 SH AH0 N'],
  "enunciate": ['IH0 N AH1 N S IY0 EY2 T', 'IY0 N AH1 N S IY0 EY2 T'],
  "enunciated": ['IH0 N AH1 N S IY0 EY2 T IH0 D'],
  "enunciating": ['IH0 N AH1 N S IY0 EY2 T IH0 NG'],
  "envelop": ['IH0 N V EH1 L AH0 P'],
  "envelope": ['EH1 N V AH0 L OW2 P'],
  "enveloped": ['EH0 N V EH1 L AH0 P T'],
  "envelopes": ['EH1 N V AH0 L OW2 P S'],
  "enveloping": ['IH0 N V EH1 L AH0 P IH0 NG'],
  "envelops": ['IH0 N V EH1 L AH0 P S'],
  "enviable": ['EH1 N V IY0 AH0 B AH0 L'],
  "envied": ['EH1 N V IY0 D'],
  "envious": ['EH1 N V IY0 AH0 S'],
  "enviously": ['EH1 N V IY0 AH0 S L IY0'],
  "enviro": ['EH0 N V AY1 R OW0'],
  "envirodyne": ['EH0 N V AY1 R OW0 D AY2 N'],
  "environic": ['EH2 N V AY0 R AO1 N IH0 K'],
  "environics": ['EH2 N V AY0 R AO1 N IH0 K S'],
  "environment": ['IH0 N V AY1 R AH0 N M AH0 N T'],
  "environment's": ['IH0 N V AY1 R AH0 N M AH0 N T S'],
  "environmental": ['IH0 N V AY2 R AH0 N M EH1 N T AH0 L', 'IH0 N V AY2 R AH0 N M EH1 N AH0 L'],
  "environmentalism": ['EH0 N V AY1 R AH0 N M EH2 N T AH0 L IH2 Z AH0 M', 'EH0 N V AY1 R AH0 N M EH2 N AH0 L IH2 Z AH0 M'],
  "environmentalist": ['IH0 N V AY2 R AH0 N M EH1 N T AH0 L IH0 S T', 'IH0 N V AY2 R AH0 N M EH1 N AH0 L IH0 S T'],
  "environmentalists": ['EH0 N V AY1 R AH0 N M EH2 N T AH0 L IH0 S T S', 'EH0 N V AY1 R AH0 N M EH2 N AH0 L IH0 S T S', 'EH0 N V AY1 R AH0 N M EH2 N T AH0 L IH0 S', 'EH0 N V AY1 R AH0 N M EH2 N AH0 L IH0 S'],
  "environmentalists'": ['IH0 N V AY2 R AH0 N M EH1 N T AH0 L IH0 S T S', 'EH0 N V AY2 R AH0 N M EH1 N AH0 L IH0 S T S'],
  "environmentally": ['IH0 N V AY2 R AH0 N M EH1 N T AH0 L IY0', 'IH0 N V AY2 R AH0 N M EH1 N AH0 L IY0'],
  "environments": ['IH0 N V AY1 R AH0 N M AH0 N T S'],
  "environs": ['IH0 N V AY1 R AH0 N Z'],
  "enviropact": ['IH0 N V AY1 R OW0 P AE2 K T'],
  "envirosafe": ['IH0 N V AY1 R OW0 S EY2 F'],
  "envirotest": ['IH2 N V AY1 R OW0 T EH2 S T'],
  "envisage": ['EH0 N V IH1 Z IH0 JH'],
  "envisaged": ['EH0 N V IH1 Z IH0 JH D'],
  "envisages": ['EH0 N V IH1 Z IH0 JH IH0 Z'],
  "envision": ['EH0 N V IH1 ZH AH0 N'],
  "envisioned": ['EH0 N V IH1 ZH AH0 N D'],
  "envisioning": ['EH0 N V IH1 ZH AH0 N IH0 NG'],
  "envisions": ['EH0 N V IH1 ZH AH0 N Z'],
  "envos": ['EH1 N V OW0 S'],
  "envoy": ['EH1 N V OY0', 'AA1 N V OY0'],
  "envoys": ['EH1 N V OY0 Z', 'AA1 N V OY0 Z'],
  "envy": ['EH1 N V IY0'],
  "enwright": ['IH0 N W R AY1 T'],
  "enyart": ['EH1 N Y AA0 R T'],
  "enyeart": ['EH1 N Y ER0 T'],
  "enz": ['EH1 N Z'],
  "enzo": ['EH1 N Z OW0'],
  "enzon": ['EH1 N Z AA0 N'],
  "enzor": ['EH1 N Z ER0'],
  "enzymatic": ['EH2 N Z AY0 M AE1 T IH0 K'],
  "enzyme": ['EH1 N Z AY2 M'],
  "enzymes": ['EH1 N Z AY2 M Z'],
  "eocene": ['IY1 AH0 S IY2 N'],
  "eoff": ['EY1 AO0 F'],
  "eohippus": ['IY2 OW0 HH IH1 P AH0 S'],
  "eolande": ['EY2 OW0 L AA1 N D IY0'],
  "eon": ['IY1 AH0 N', 'IY1 AA0 N'],
  "eons": ['IY1 AH0 N Z'],
  "eos": ['IY1 AA0 S'],
  "eos's": ['IY1 AA0 S AH0 Z'],
  "eosinophilia": ['IY2 AH0 S IH1 N AH0 F IH1 L Y AH0'],
  "eosinophilic": ['IY2 AH0 S IH1 N AH0 F IH1 L IH0 K'],
  "epa": ['IY2 P IY2 EY1'],
  "epcot": ['EH1 P K AA0 T'],
  "epeda": ['EH0 P EY1 D AH0'],
  "epeda's": ['EH0 P EY1 D AH0 Z'],
  "epes": ['IY1 P S'],
  "ephedrine": ['IH0 F EH1 D R IH0 N'],
  "ephemeral": ['IH0 F EH1 M ER0 AH0 L'],
  "ephesus": ['EH1 F UH0 S AH0 S'],
  "ephlin": ['EH1 F L IH0 N'],
  "ephraim": ['IY1 F R AH0 M'],
  "ephron": ['EH1 F R AH0 N'],
  "epic": ['EH1 P IH0 K'],
  "epic's": ['EH1 P IH0 K S'],
  "epicenter": ['EH1 P AH0 S EH2 N T ER0'],
  "epics": ['EH1 P IH0 K S'],
  "epictetus": ['EH2 P IH0 K T IY1 T AH0 S', 'EH2 P IH0 K T IY1 SH AH0 S'],
  "epicure": ['EH1 P IH0 K Y UH2 R'],
  "epicurean": ['EH2 P AH0 K Y UH0 R IY1 AH0 N', 'EH2 P AH0 K Y UH1 R IY0 AH0 N'],
  "epicures": ['EH1 P IH0 K Y UH2 R Z'],
  "epidemic": ['EH2 P AH0 D EH1 M IH0 K', 'EH2 P IH0 D EH1 M IH0 K'],
  "epidemics": ['EH2 P AH0 D EH1 M IH0 K S'],
  "epidemiological": ['EH2 P AH0 D IY0 M IY0 AH0 L AA1 JH IH0 K AH0 L'],
  "epidemiologically": ['EH2 P AH0 D IY2 M IY2 AH0 L AA1 JH IH0 K L IY2'],
  "epidemiologist": ['EH2 P AH0 D IY2 M IY2 AA1 L AH0 JH IH2 S T'],
  "epidemiologists": ['EH2 P AH0 D IY2 M IY2 AA1 L AH0 JH IH2 S T S'],
  "epidemiology": ['EH2 P AH0 D EH2 M IY0 AA1 L AH0 JH IY0'],
  "epidermal": ['EH2 P AH0 D ER1 M AH0 L'],
  "epidermis": ['EH2 P AH0 D ER1 M AH0 S'],
  "epidural": ['EH2 P AH0 D ER1 AH0 L'],
  "epigenetic": ['EH2 P AH0 JH AH0 N EH1 T IH0 K'],
  "epigram": ['EH1 P AH0 G R AE2 M'],
  "epigrammatic": ['EH2 P AH0 G R AH0 M AE1 T IH0 K'],
  "epigraphic": ['EH2 P AH0 G R AE1 F IH0 K'],
  "epilepsies": ['EH1 P AH0 L EH2 P S IY0 Z'],
  "epilepsy": ['EH1 P AH0 L EH2 P S IY0'],
  "epileptic": ['EH2 P AH0 L EH1 P T IH0 K'],
  "epileptics": ['EH2 P AH0 L EH1 P T IH0 K S'],
  "epilogue": ['EH1 P AH0 L AO2 G'],
  "epinephrine": ['EH2 P IH0 N EH1 F R IH0 N'],
  "epiphany": ['IH0 P IH1 F AH0 N IY0'],
  "episcopal": ['IH0 P IH1 S K AH0 P AH0 L'],
  "episcopalian": ['IH0 P IH2 S K AH0 P EY1 L IY0 AH0 N', 'IH0 P IH2 S K AH0 P EY1 L Y AH0 N'],
  "episcopalians": ['IH0 P IH2 S K AH0 P EY1 L IY0 AH0 N Z', 'IH0 P IH2 S K AH0 P EY1 L Y AH0 N Z'],
  "episcopo": ['IH0 P IH0 S K OW1 P OW0'],
  "episode": ['EH1 P AH0 S OW2 D', 'EH1 P IH0 S OW2 D'],
  "episodes": ['EH1 P AH0 S OW2 D Z', 'EH1 P IH0 S OW2 D Z'],
  "episodic": ['EH2 P AH0 S AA1 D IH0 K'],
  "episteme": ['EH1 P IH0 S T IY2 M'],
  "epistemic": ['EH2 P IH0 S T EH1 M IH0 K'],
  "epistemological": ['EH0 P IH2 S T AH0 M AA0 L AA1 JH IY2 K AH0 L'],
  "epistemologies": ['EH0 P IH2 S T AH0 M AA1 L AH0 JH IY2 Z'],
  "epistemology": ['EH0 P IH2 S T AH0 M AA1 L AH0 JH IY2'],
  "epistle": ['IH0 P IH1 S AH0 L'],
  "epistolary": ['IH0 P IH1 S T AH0 L EH2 R IY0'],
  "epitaph": ['EH1 P AH0 T AE2 F'],
  "epitaphs": ['EH1 P AH0 T AE2 F S'],
  "epithelial": ['EH0 P IH0 TH EH1 L Y AH0 L'],
  "epithelium": ['EH2 P IH0 TH IY1 L Y AH0 M'],
  "epithet": ['EH1 P AH0 TH EH2 T'],
  "epithets": ['EH1 P AH0 TH EH2 T S'],
  "epitome": ['IH0 P IH1 T AH0 M IY0'],
  "epitomize": ['IH0 P IH1 T AH0 M AY2 Z'],
  "epitomized": ['IH0 P IH1 T AH0 M AY2 Z D'],
  "epitomizes": ['IH0 P IH1 T AH0 M AY2 Z IH0 Z'],
  "epitope": ['EH1 P IH0 T OW2 P'],
  "epler": ['EH1 P L ER0'],
  "epley": ['EH1 P L IY0'],
  "eplin": ['EH1 P L IH0 N'],
  "epling": ['EH1 P L IH0 NG'],
  "epoch": ['EH1 P AH0 K', 'IY1 P AH0 K'],
  "epochal": ['EH1 P AH0 K AH0 L'],
  "epochs": ['EH1 P AH0 K S', 'IY1 P AH0 K S'],
  "epogen": ['EH1 P AH0 JH EH0 N'],
  "eponymous": ['EH0 P AO1 N IH2 M AH0 S'],
  "eponymy": ['EH0 P AO1 N IH0 M IY2'],
  "epoxy": ['IH0 P AA1 K S IY0'],
  "epp": ['EH1 P'],
  "eppard": ['EH1 P ER0 D'],
  "eppel": ['EH1 P AH0 L'],
  "epperly": ['EH1 P ER0 L IY0'],
  "eppers": ['EH1 P ER0 Z'],
  "epperson": ['EH1 P ER0 S AH0 N'],
  "eppes": ['EH1 P S'],
  "eppich": ['EH1 P IH0 CH'],
  "epping": ['EH1 P IH0 NG'],
  "eppinger": ['EH1 P IH0 NG ER0'],
  "epple": ['EH1 P AH0 L'],
  "eppler": ['EH1 P L ER0'],
  "eppley": ['EH1 P L IY0'],
  "eppner": ['EH1 P N ER0'],
  "eppolito": ['EH0 P OW0 L IY1 T OW0'],
  "epps": ['EH1 P S'],
  "eprom": ['EH1 P R AH0 M'],
  "eproms": ['EH1 P R AH0 M Z'],
  "epsco": ['EH1 P S K OW0'],
  "epsilon": ['EH1 P S AH0 L AA2 N'],
  "epsom": ['EH1 P S AH0 M'],
  "epsomite": ['EH1 P S AH0 M AY2 T'],
  "epson": ['EH1 P S AH0 N'],
  "epstein": ['EH1 P S T IY2 N', 'EH1 P S T AY2 N'],
  "epting": ['EH1 P T IH0 NG'],
  "equable": ['EH1 K W AH0 B AH0 L'],
  "equal": ['IY1 K W AH0 L'],
  "equaled": ['IY1 K W AH0 L D'],
  "equaling": ['IY1 K W AH0 L IH0 NG'],
  "equality": ['IH0 K W AA1 L AH0 T IY0'],
  "equalization": ['IY2 K W AH0 L IH0 Z EY1 SH AH0 N'],
  "equalize": ['IY1 K W AH0 L AY2 Z'],
  "equalized": ['IY1 K W AH0 L AY2 Z D'],
  "equalizer": ['IY1 K W AH0 L AY2 Z ER0'],
  "equalizes": ['IY1 K W AH0 L AY2 Z IH0 Z'],
  "equalizing": ['IY1 K W AH0 L AY2 Z IH0 NG'],
  "equally": ['IY1 K W AH0 L IY0'],
  "equals": ['IY1 K W AH0 L Z'],
  "equanimity": ['IY2 K W AH0 N IH1 M IH0 T IY0'],
  "equate": ['IH0 K W EY1 T'],
  "equated": ['IH0 K W EY1 T IH0 D'],
  "equates": ['IH0 K W EY1 T S'],
  "equating": ['IH0 K W EY1 T IH0 NG'],
  "equation": ['IH0 K W EY1 ZH AH0 N'],
  "equations": ['IH0 K W EY1 ZH AH0 N Z'],
  "equator": ['IH0 K W EY1 T ER0'],
  "equatorial": ['IY2 K W AH0 T AO1 R IY0 AH0 L'],
  "equators": ['IH0 K W EY1 T ER0 Z'],
  "equestrian": ['IH0 K W EH1 S T R IY0 AH0 N'],
  "equiangular": ['IY2 K W AH0 AE1 NG G Y AH0 L ER0'],
  "equibank": ['EH1 K W AH0 B AE2 NG K'],
  "equicor": ['EH1 K W IH2 K AO2 R'],
  "equifax": ['EH1 K W IH0 F AE2 K S'],
  "equilibria": ['IY2 K W AH0 L IH1 B R IY0 AH0'],
  "equilibrium": ['IY2 K W AH0 L IH1 B R IY0 AH0 M'],
  "equilink": ['EH1 K W AH0 L IH2 NG K'],
  "equimark": ['EH1 K W IH0 M AA2 R K'],
  "equine": ['IY1 K W AY2 N'],
  "equinox": ['IY1 K W AH0 N AA2 K S'],
  "equion": ['EH1 K W IY0 AA0 N'],
  "equip": ['IH0 K W IH1 P'],
  "equipment": ['IH0 K W IH1 P M AH0 N T'],
  "equipment's": ['IH0 K W IH1 P M AH0 N T S'],
  "equipments": ['IH0 K W IH1 P M AH0 N T S'],
  "equipped": ['IH0 K W IH1 P T'],
  "equipping": ['IH0 K W IH1 P IH0 NG'],
  "equips": ['IH0 K W IH1 P S'],
  "equitable": ['EH1 K W AH0 T AH0 B AH0 L', 'EH1 K W IH0 T AH0 B AH0 L'],
  "equitable's": ['EH1 K W AH0 T AH0 B AH0 L Z'],
  "equitably": ['EH1 K W IH0 T AH0 B L IY0'],
  "equitas": ['EH1 K W AH0 T AH0 S'],
  "equitation": ['EH2 K W AH0 T EY1 SH AH0 N'],
  "equitec": ['EH1 K W AH0 T EH2 K'],
  "equitex": ['EH1 K W AH0 T EH2 K S'],
  "equiticorp": ['EH1 K W IH0 T IY0 K AO2 R P'],
  "equities": ['EH1 K W AH0 T IY0 Z'],
  "equities'": ['EH1 K W AH0 T IY0 Z'],
  "equity": ['EH1 K W AH0 T IY0'],
  "equity's": ['EH1 K W AH0 T IY0 Z'],
  "equivalence": ['IH0 K W IH1 V AH0 L AH0 N S'],
  "equivalency": ['IH0 K W IH1 V AH0 L AH0 N S IY0'],
  "equivalent": ['IH0 K W IH1 V AH0 L AH0 N T'],
  "equivalents": ['IH0 K W IH1 V AH0 L AH0 N T S'],
  "equivocal": ['IH0 K W IH1 V AH0 K AH0 L'],
  "equivocate": ['IH0 K W IH1 V AH0 K EY2 T'],
  "equivocating": ['IH0 K W IH1 V AH0 K EY2 T IH0 NG'],
  "equivocation": ['IH0 K W IH0 V AH0 K EY1 SH AH0 N'],
  "er": ['ER0'],
  "era": ['EH1 R AH0', 'IH1 R AH0'],
  "era's": ['EH1 R AH0 Z', 'IH1 R AH0 Z'],
  "eradicable": ['IH0 R AE1 D AH0 K AH0 B AH0 L'],
  "eradicate": ['IH0 R AE1 D AH0 K EY2 T'],
  "eradicated": ['IH0 R AE1 D AH0 K EY2 T IH0 D'],
  "eradicating": ['IH0 R AE1 D AH0 K EY2 T IH0 NG'],
  "eradication": ['IH0 R AE2 D AH0 K EY1 SH AH0 N'],
  "erakat": ['EH0 R AA1 K AH0 T'],
  "eralp": ['EH1 R AO0 L P'],
  "eramo": ['EH0 R AA1 M OW0'],
  "eranthe": ['EH1 R AH0 N TH'],
  "erany": ['AH0 R EY1 N IY0'],
  "eras": ['IH1 R AH0 Z'],
  "erasable": ['IH0 R EY1 S AH0 B AH0 L', 'IY1 R EY0 S AH0 B AH0 L'],
  "erase": ['IH0 R EY1 S', 'IY0 R EY1 S'],
  "erased": ['IH0 R EY1 S T', 'IY0 R EY1 S T'],
  "eraser": ['IH0 R EY1 S ER0', 'IY0 R EY1 S ER0'],
  "erasers": ['IH0 R EY1 S ER0 Z', 'IY0 R EY1 S ER0 Z'],
  "erases": ['IH0 R EY1 S IH0 Z', 'IY0 R EY1 S IH0 Z'],
  "erasing": ['IH0 R EY1 S IH0 NG', 'IY0 R EY1 S IH0 NG'],
  "erasmus": ['IH0 R AE1 Z M AH0 S'],
  "erastus": ['IH0 R AE1 S T AH0 S'],
  "erawan": ['EH1 R AH0 W AA2 N'],
  "erazo": ['EH0 R AA1 Z OW0'],
  "erb": ['ER1 B'],
  "erbacher": ['ER1 B AA0 K ER0'],
  "erbakan": ['ER0 B AA0 K AA1 N'],
  "erbakan's": ['ER0 B AA0 K AA1 N Z'],
  "erbamont": ['ER1 B AH0 M AA2 N T'],
  "erbamont's": ['ER1 B AH0 M AA2 N T S'],
  "erbe": ['ER1 B'],
  "erber": ['ER1 B ER0'],
  "erbes": ['ER1 B Z'],
  "erburu": ['ER0 B UH1 R UW0'],
  "erby": ['ER1 B IY0'],
  "erceg": ['ER1 S IH0 G'],
  "erck": ['ER1 K'],
  "ercole": ['ER0 K OW1 L IY0'],
  "ercros": ['ER1 K R OW0 Z'],
  "erda": ['EH1 R D AH0'],
  "erdahl": ['ER1 D AA0 L'],
  "erdman": ['ER1 D M AH0 N'],
  "erdmann": ['ER1 D M AH0 N'],
  "erdos": ['ER1 D OW0 Z'],
  "erdrich": ['ER1 D R IH0 K'],
  "ere": ['EH1 R'],
  "erect": ['IH0 R EH1 K T'],
  "erected": ['IH0 R EH1 K T AH0 D', 'IH0 R EH1 K T IH0 D'],
  "erecting": ['IH0 R EH1 K T IH0 NG'],
  "erection": ['IH0 R EH1 K SH AH0 N'],
  "erections": ['IH0 R EH1 K SH AH0 N Z'],
  "erector": ['IH0 R EH1 K T ER0'],
  "erects": ['IH0 R EH1 K T S'],
  "erena": ['ER0 EH1 N AH0'],
  "erensel": ['EH1 R AH0 N S EH0 L'],
  "ergle": ['ER1 G AH0 L'],
  "ergo": ['ER1 G OW0'],
  "ergonomic": ['ER2 G AH0 N AA1 M IH0 K'],
  "ergonomically": ['ER2 G AH0 N AA1 M IH0 K L IY0'],
  "ergonomics": ['ER2 G AH0 N AA1 M IH0 K S'],
  "ergot": ['ER1 G AH0 T'],
  "ergot's": ['ER1 G AH0 T S'],
  "ergotism": ['ER1 G AH0 T IH2 Z AH0 M'],
  "ergots": ['ER1 G AH0 T S'],
  "erhard": ['EH1 R HH AA0 R D'],
  "erhardt": ['ER1 HH AA0 R T'],
  "erhart": ['ER1 HH AA0 R T'],
  "eric": ['EH1 R IH0 K'],
  "eric's": ['EH1 R IH0 K S'],
  "erica": ['EH1 R IH0 K AH0'],
  "erich": ['EH1 R IH0 K'],
  "erichsen": ['EH1 R IH0 K S AH0 N'],
  "erick": ['EH1 R IH0 K'],
  "ericka": ['EH1 R IH0 K AH0'],
  "ericksen": ['EH1 R IH0 K S AH0 N'],
  "erickson": ['EH1 R IH0 K S AH0 N'],
  "ericson": ['EH1 R IH0 K S AH0 N'],
  "ericsson": ['EH1 R IH0 K S AH0 N'],
  "ericsson's": ['EH1 R IH0 K S AH0 N Z'],
  "eridania": ['EH2 R IH0 D AE1 N IY0 AH0'],
  "erie": ['IH1 R IY0'],
  "erik": ['EH1 R IH0 K'],
  "erik's": ['EH1 R IH0 K S'],
  "erika": ['EH1 R IH0 K AH0'],
  "eriksen": ['EH1 R IH0 K S AH0 N'],
  "erikson": ['EH1 R IH0 K S AH0 N'],
  "erikson's": ['EH1 R IH0 K S AH0 N Z'],
  "eriksson": ['EH1 R IH0 K S AH0 N'],
  "erin": ['EH1 R IH0 N'],
  "erina": ['ER0 IY1 N AH0'],
  "erion": ['EH0 R IY0 AO1 N'],
  "erisa": ['EH0 R IY1 S AH0'],
  "erisman": ['EH1 R IH0 S M AH0 N'],
  "eritrea": ['EH2 R IH0 T R IY1 AH0', 'EH2 R IH0 T R EY1 AH0'],
  "eritrean": ['EH2 R IH0 T R IY1 AH0 N', 'EH2 R IH0 T R EY1 AH0 N'],
  "eritreans": ['EH1 R IH0 T R IY2 N Z', 'EH2 R IH0 T R EY1 AH0 N Z'],
  "erk": ['ER1 K'],
  "erker": ['ER1 K ER0'],
  "erkkila": ['ER1 K IH0 L AH0'],
  "erl": ['ER1 L'],
  "erlach": ['ER1 L AA2 K'],
  "erland": ['ER1 L AH0 N D'],
  "erlandson": ['ER1 L AH0 N D S AH0 N'],
  "erlangen": ['ER0 L AE1 NG G AH0 N'],
  "erlanger": ['EH1 R L AE0 NG ER0', 'EH1 R L AE0 NG G ER0'],
  "erlbaum": ['EH2 R L B AW1 M'],
  "erle": ['EH1 R L AH0'],
  "erlene": ['ER1 L IY0 N'],
  "erler": ['ER1 L ER0'],
  "erlich": ['ER1 L IH0 K'],
  "erlichman": ['ER1 L IH0 K M AH0 N', 'EH1 R L IH0 K M AH0 N'],
  "erlick": ['ER1 L IH0 K', 'EH1 R L IH0 K'],
  "erline": ['ER1 L AY0 N'],
  "erling": ['ER1 L IH0 NG'],
  "erly": ['ER1 L IY0'],
  "erma": ['ER1 M AH0'],
  "erma's": ['ER1 M AH0 Z'],
  "erman": ['ER1 M AH0 N'],
  "ermer": ['ER1 M ER0'],
  "ermin": ['ER1 M IH0 N'],
  "ermina": ['ER0 M IY1 N AH0'],
  "ermine": ['ER1 M AH0 N'],
  "erminia": ['ER0 M IY1 N IY0 AH0'],
  "erminie": ['ER1 M IH0 N IY0'],
  "ermis": ['ER1 M IH0 S'],
  "erna": ['ER1 N AH0'],
  "ernaline": ['ER1 N AH0 L AY0 N'],
  "erne": ['ER1 N'],
  "ernest": ['ER1 N AH0 S T', 'ER1 N IH0 S T'],
  "ernest's": ['ER1 N AH0 S T S'],
  "ernestine": ['ER1 N IH0 S T IY2 N'],
  "ernesto": ['ER0 N EH1 S T OW0'],
  "ernests": ['ER1 N AH0 S T S'],
  "erney": ['ER1 N IY0'],
  "ernie": ['ER1 N IY0'],
  "erno": ['EH1 R N OW0'],
  "ernsberger": ['ER1 N Z B ER0 G ER0'],
  "ernst": ['ER1 N S T'],
  "ernster": ['ER1 N S T ER0'],
  "erny": ['ER1 N IY0'],
  "ero": ['IH1 R OW0'],
  "erode": ['IH0 R OW1 D', 'IY1 R OW0 D'],
  "eroded": ['IH0 R OW1 D AH0 D', 'IH0 R OW1 D IH0 D'],
  "erodes": ['IH0 R OW1 D Z'],
  "erodible": ['IH0 R OW1 D AH0 B AH0 L'],
  "eroding": ['IH0 R OW1 D IH0 NG', 'IY0 R OW1 D IH0 NG'],
  "eroh": ['EH1 R OW0'],
  "eroica": ['ER0 OY1 K AH0'],
  "erol": ['EH1 R AH0 L'],
  "erol's": ['EH1 R AH0 L Z'],
  "eron": ['IH1 R AA0 N'],
  "eros": ['IH1 R AA0 S'],
  "erosion": ['IH0 R OW1 ZH AH0 N'],
  "erosional": ['IH0 R OW1 ZH AH0 N AH0 L'],
  "erosive": ['IH0 R OW1 S IH0 V'],
  "erotic": ['IH0 R AA1 T IH0 K'],
  "erotica": ['IH0 R AA1 T IH0 K AH0'],
  "eroticism": ['ER0 AA1 T IH0 S IH2 Z AH0 M'],
  "erpelding": ['ER1 P IH0 L D IH0 NG'],
  "erps": ['ER1 P S'],
  "err": ['EH1 R', 'ER1'],
  "erra": ['EH1 R AH0'],
  "errand": ['EH1 R AH0 N D'],
  "errands": ['EH1 R AH0 N D Z'],
  "errant": ['EH1 R AH0 N T'],
  "erratic": ['IH0 R AE1 T IH0 K'],
  "erratically": ['EH0 R AE1 T IH0 K L IY0'],
  "erred": ['EH1 R D'],
  "errett": ['EH1 R IH0 T'],
  "erria": ['EH1 R IY0 AH0'],
  "errickson": ['EH1 R IH0 K S AH0 N'],
  "errico": ['ER0 IY1 K OW0'],
  "erring": ['EH1 R IH0 NG'],
  "errington": ['EH1 R IH0 NG T AH0 N'],
  "errol": ['EH1 R AH0 L'],
  "errol's": ['EH1 R AH0 L Z'],
  "erroneous": ['EH0 R OW1 N IY0 AH0 S', 'ER0 OW1 N IY0 AH0 S'],
  "erroneously": ['EH0 R OW1 N IY0 AH0 S L IY0'],
  "error": ['EH1 R ER0'],
  "errorful": ['EH1 R ER0 F AH0 L'],
  "errors": ['EH1 R ER0 Z'],
  "errs": ['EH1 R Z'],
  "ers": ['ER1 Z', 'IY1 AA1 R EH1 S'],
  "ersatz": ['EH1 R S AA2 T S', 'EH1 R Z AA2 T S'],
  "ershad": ['ER1 SH AE2 D', 'ER1 SH AA2 D'],
  "erskin": ['ER1 S K IH0 N'],
  "erskine": ['ER1 S K AY2 N'],
  "erstwhile": ['ER1 S T W AY2 L'],
  "erte": ['ER1 T', 'ER1 T EY0'],
  "ertel": ['ER1 T AH0 L'],
  "ertha": ['ER1 DH AH0'],
  "ertl": ['ER1 T AH0 L'],
  "ertle": ['ER1 T AH0 L'],
  "ertman": ['ER1 T M AH0 N'],
  "ertz": ['ER1 T S'],
  "erudite": ['EH1 R AH0 D AY2 T'],
  "erudition": ['EH2 R AH0 D IH1 SH AH0 N'],
  "erupt": ['IH0 R AH1 P T', 'IY1 R AH0 P T'],
  "erupted": ['IY2 R AH1 P T IH0 D', 'IH2 R AH1 P T IH0 D'],
  "erupting": ['IY2 R AH1 P T IH0 NG', 'IH2 R AH1 P T IH0 NG'],
  "eruption": ['IY2 R AH1 P SH AH0 N', 'IH2 R AH1 P SH AH0 N'],
  "eruptions": ['IY2 R AH1 P SH AH0 N Z', 'IH2 R AH1 P SH AH0 N Z'],
  "eruptive": ['IY2 R AH1 P T IH0 V', 'IH2 R AH1 P T IH0 V'],
  "erupts": ['IY2 R AH1 P T S', 'IH2 R AH1 P T S'],
  "erven": ['ER1 V AH2 N'],
  "ervin": ['ER1 V IH2 N'],
  "erving": ['ER1 V IH2 NG'],
  "erway": ['ER1 W EY2'],
  "erwin": ['ER1 W IH2 N'],
  "erwina": ['ER0 V AY1 N AH0'],
  "erxleben": ['ER0 K S L EH1 B AH0 N'],
  "erykah": ['EH1 R IY0 K AH0'],
  "erythropoietin": ['EH0 R IH2 TH R AH0 P OY1 T IH0 N'],
  "erzurum": ['ER0 Z UH1 R AH0 M'],
  "es": ['EH1 S'],
  "esarey": ['EH1 S ER0 IY0'],
  "esau": ['IY1 S AO2'],
  "esbenshade": ['EH1 S B IH0 N SH AH0 D', 'EH1 S B AH0 N SH EY0 D'],
  "esber": ['EH1 S B ER0'],
  "escada": ['EH2 S K AA1 D AH0'],
  "escalade": ['EH2 S K AH0 L AA1 D'],
  "escalante": ['EH0 S K AA0 L AA1 N T IY0'],
  "escalate": ['EH1 S K AH0 L EY2 T'],
  "escalated": ['EH1 S K AH0 L EY2 T IH0 D'],
  "escalates": ['EH1 S K AH0 L EY2 T S'],
  "escalating": ['EH1 S K AH0 L EY2 T IH0 NG'],
  "escalation": ['EH2 S K AH0 L EY1 SH AH0 N'],
  "escalator": ['EH1 S K AH0 L EY2 T ER0'],
  "escalators": ['EH1 S K AH0 L EY2 T ER0 Z'],
  "escalera": ['EH0 S K AA0 L EH1 R AH0'],
  "escalona": ['EH0 S K AA0 L OW1 N AH0'],
  "escambia": ['EH2 S K AE1 M B IY0 AH0'],
  "escamez": ['EH0 S K AA1 M EH0 Z'],
  "escamilla": ['EH0 S K AA0 M IH1 L AH0'],
  "escanaba": ['EH2 S K AH0 N AA1 B AH0'],
  "escandon": ['IH0 S K AE1 N D AH0 N'],
  "escapade": ['EH1 S K AH0 P EY2 D'],
  "escapades": ['EH1 S K AH0 P EY2 D Z'],
  "escape": ['IH0 S K EY1 P'],
  "escaped": ['IH0 S K EY1 P T'],
  "escapee": ['IH0 S K EY2 P IY1'],
  "escapees": ['IH0 S K EY2 P IY1 Z'],
  "escapement": ['IH0 S K EY1 P M AH0 N T'],
  "escapes": ['IH0 S K EY1 P S'],
  "escaping": ['IH0 S K EY1 P IH0 NG'],
  "escapism": ['IH0 S K EY1 P IH2 Z AH0 M'],
  "escarcega": ['EH0 S K AA0 R CH EH1 G AH0'],
  "escareno": ['EH0 S K AA0 R EH1 N OW0'],
  "escargot": ['EH2 S K AA1 R G OW0'],
  "escarpment": ['EH0 S K AA1 R P M AH0 N T'],
  "escarpments": ['EH0 S K AA1 R P M AH0 N T S'],
  "esch": ['EH1 SH'],
  "eschatological": ['EH2 S K AH0 T AH0 L AA1 JH IH0 K AH0 L'],
  "eschbach": ['EH1 SH B AA2 K'],
  "esche": ['EH1 SH'],
  "eschemic": ['EH2 S K IY1 M IH0 K'],
  "eschen": ['EH1 SH AH0 N'],
  "eschenbach": ['EH1 SH IH0 N B AA0 K'],
  "eschenburg": ['EH1 SH AH0 N B ER0 G'],
  "escher": ['EH1 SH ER0'],
  "eschete": ['EH1 SH IY0 T'],
  "eschew": ['EH0 S CH UW1'],
  "eschewed": ['EH2 SH UW1 D', 'EH2 S K Y UW1 D'],
  "eschewing": ['EH2 SH UW1 IH0 NG', 'EH2 S K Y UW1 IH0 NG'],
  "eschews": ['EH0 S CH UW1 Z'],
  "eschmann": ['EH1 SH M AH0 N'],
  "esco": ['EH1 S K OW0'],
  "escobar": ['EH1 S K OW0 B AA2 R', 'EH1 S K AH0 B AA2 R'],
  "escobar's": ['EH1 S K OW0 B AA2 R Z', 'EH1 S K AH0 B AA2 R Z'],
  "escobedo": ['EH0 S K OW0 B EY1 D OW0'],
  "escoe": ['IH0 S K OW1'],
  "escondido": ['EH2 S K AA0 N D IY1 D OW0'],
  "escora": ['EH0 S K AO1 R AH0'],
  "escort": ['EH0 S K AO1 R T', 'EH1 S K AO0 R T'],
  "escorted": ['EH0 S K AO1 R T IH0 D'],
  "escorting": ['EH1 S K AO0 R T IH0 NG'],
  "escorts": ['EH1 S K AO0 R T S'],
  "escoto": ['EH0 S K OW1 T OW0'],
  "escott": ['EH1 S K AH0 T'],
  "escrow": ['EH0 S K R OW1', 'EH1 S K R OW0'],
  "escrowed": ['EH1 S K R OW0 D'],
  "escuchando": ['EH2 S K UW0 CH AA1 N D OW0'],
  "escudero": ['EH0 S K UW0 D EH1 R OW0'],
  "escudo": ['EH0 S K UW1 D OW0'],
  "escudos": ['EH0 S K UW1 D OW0 Z'],
  "escue": ['EY1 S K Y UW0'],
  "esculent": ['EH0 S K UW1 L AH0 N T'],
  "esh": ['EH1 SH'],
  "esham": ['EH1 SH AH0 M'],
  "eshbach": ['EH1 SH B AA2 K'],
  "eshbaugh": ['IH0 SH B AO1'],
  "eshelman": ['EH1 SH AH0 L M AH0 N'],
  "eshleman": ['EH1 SH AH0 L M AH0 N'],
  "eskandarian": ['EH2 S K AH0 N D EH1 R IY0 AH0 N'],
  "eskelson": ['EH1 S K IH0 L S AH0 N'],
  "eskenazi": ['EY0 S K EY0 N AA1 Z IY0', 'EH0 S K AH0 N AA1 Z IY0'],
  "esker": ['EH1 S K ER0'],
  "eskew": ['EH1 S K Y UW0'],
  "eskey": ['EH1 S K IY0'],
  "eskimo": ['EH1 S K AH0 M OW2'],
  "eskimos": ['EH1 S K AH0 M OW2 Z'],
  "eskin": ['IH0 S K IH1 N'],
  "eskridge": ['EH1 S K R IH2 JH'],
  "esler": ['EH1 S AH0 L ER0', 'EH1 S L ER0'],
  "eslick": ['EH1 S L IH0 K'],
  "eslinger": ['EH1 S AH0 L IH0 NG ER0', 'EH1 S L IH0 NG ER0'],
  "esmark": ['EH1 S M AA2 R K'],
  "esme": ['EH1 Z M'],
  "esmerelda": ['EH0 S M ER0 EH1 L D AH0'],
  "esmine": ['EH1 Z M AH0 N'],
  "esmond": ['EH1 Z M AH0 N D'],
  "esophageal": ['IH0 S AA2 F AH0 G IY1 AH0 L'],
  "esophagus": ['IH0 S AA1 F AH0 G AH0 S'],
  "esoteric": ['EH2 S AH0 T EH1 R IH0 K', 'EH2 S OW0 T EH1 R IH0 K'],
  "espada": ['EY0 S P AA1 D AH0'],
  "espalier": ['EH0 S P AE1 L Y ER0'],
  "espana": ['EH0 S P AE1 N Y AH0'],
  "espanol": ['EH2 S P AA0 N Y OW1 L'],
  "espanola": ['EH2 S P AH0 N OW1 L AH0'],
  "esparza": ['EH0 S P AA1 R Z AH0'],
  "espe": ['EH1 S P'],
  "especial": ['AH0 S P EH1 SH AH0 L'],
  "especially": ['AH0 S P EH1 SH L IY0', 'AH0 S P EH1 SH AH0 L IY0'],
  "espectador": ['EH0 S P EH2 K T AH0 D AO1 R'],
  "espejo": ['EY0 S P EY1 Y OW0'],
  "espeland": ['EH1 S P IH0 L AH0 N D'],
  "espenschied": ['EH1 S P IH0 N SH IY0 D'],
  "espenshade": ['EH1 S P IH0 N SH AH0 D', 'EH1 S P IH0 N SH EY0 D'],
  "esper": ['EH1 S P ER0'],
  "esperanto": ['EH2 S P ER0 AE1 N T OW0'],
  "esperanto's": ['EH2 S P ER0 AE1 N T OW0 Z'],
  "espeseth": ['EH1 S P IH0 S IH0 TH'],
  "espey": ['EH1 S P IY0'],
  "espinal": ['EY0 S P IY1 N AH0 L'],
  "espino": ['EY0 S P IY1 N OW0'],
  "espinola": ['EH0 S P IY0 N OW1 L AH0'],
  "espinosa": ['EH0 S P IH0 N OW1 Z AH0'],
  "espinoza": ['EY0 S P IY0 N OW1 Z AH0'],
  "espionage": ['EH1 S P IY0 AH0 N AA0 JH'],
  "espirito": ['EH2 S P IH0 R IY1 T OW0', 'EH2 S P IH1 R IH0 T OW0'],
  "espiritu": ['EH0 S P IH0 R IY1 CH UW0'],
  "espitia": ['EH0 S P IY1 SH AH0'],
  "esplanade": ['EH2 S P L AH0 N AA1 D'],
  "esplin": ['EH1 S P L IH0 N'],
  "espn": ['IY1 EH1 S P IY1 EH1 N'],
  "esposito": ['EH0 S P AH0 Z IY1 T OW0'],
  "espouse": ['IH0 S P AW1 Z', 'IH0 S P AW1 S'],
  "espoused": ['IH0 S P AW1 Z D', 'IH0 S P AW1 S T'],
  "espouses": ['IH0 S P AW1 Z IH0 Z', 'IH0 S P AW1 S IH0 Z'],
  "espousing": ['IH0 S P AW1 Z IH0 NG', 'IH0 S P AW1 S IH0 NG'],
  "espresso": ['EH2 S P R EH1 S OW0', 'EH2 K S P R EH1 S OW2'],
  "espressos": ['EH2 S P R EH1 S OW2 Z', 'EH2 K S P R EH1 S OW2 Z'],
  "esprit": ['EH0 S P R IY1'],
  "esprit's": ['EH0 S P R IY1 Z'],
  "espy": ['EH1 S P IY0'],
  "espy's": ['EH1 S P IY0 Z'],
  "esque": ['EH1 S K'],
  "esqueda": ['EH0 S K W EY1 D AH0'],
  "esquer": ['IH0 S K ER1'],
  "esquibel": ['EY0 S K W IY0 B EH1 L'],
  "esquire": ['EH1 S K W AY2 R'],
  "esquire's": ['EH1 S K W AY2 R Z'],
  "esquivel": ['EY0 S K W IY0 V EH1 L'],
  "esrey": ['EH1 S R IY0'],
  "ess": ['EH1 S'],
  "essa": ['EH1 S AH0'],
  "essary": ['EH1 S EH0 R IY0'],
  "essay": ['EH0 S EY1', 'EH1 S EY2'],
  "essayist": ['EH1 S EY2 IH0 S T'],
  "essays": ['EH0 S EY1 Z', 'EH1 S EY2 Z'],
  "esse": ['EH1 S'],
  "esselman": ['EH1 S AH0 L M AH0 N'],
  "esselte": ['EH0 S EH1 L T IY0'],
  "essen": ['EH1 S AH0 N'],
  "essenburg": ['EH1 S AH0 N B ER0 G'],
  "essence": ['EH1 S AH0 N S'],
  "essential": ['EH0 S EH1 N SH AH0 L', 'IY0 S EH1 N SH AH0 L'],
  "essentially": ['EH0 S EH1 N SH AH0 L IY0', 'IY0 S EH1 N SH AH0 L IY0'],
  "essentials": ['EH0 S EH1 N SH AH0 L Z', 'IY0 S EH1 N SH AH0 L Z'],
  "esser": ['EH1 S ER0'],
  "esses": ['EH1 S IH0 Z', 'EH1 S IY0 Z'],
  "essex": ['EH1 S IH0 K S'],
  "essex's": ['EH1 S IH0 K S IH0 Z'],
  "essick": ['EH1 S IH0 K'],
  "essie": ['EH1 S IY0'],
  "essig": ['EH1 S IH0 G'],
  "esslinger": ['EH1 S L IH0 NG G ER0'],
  "essman": ['EH1 S M AH0 N'],
  "essner": ['EH1 S N ER0'],
  "esso": ['EH1 S OW0'],
  "esson": ['EH1 S AH0 N'],
  "est": ['EH1 S T'],
  "esta": ['EH1 S T AH0'],
  "establish": ['IH0 S T AE1 B L IH0 SH'],
  "established": ['IH0 S T AE1 B L IH0 SH T'],
  "establishes": ['IH0 S T AE1 B L IH0 SH IH0 Z'],
  "establishing": ['IH0 S T AE1 B L IH0 SH IH0 NG'],
  "establishment": ['IH0 S T AE1 B L IH0 SH M AH0 N T'],
  "establishment's": ['EH0 S T AE1 B L IH0 SH M AH0 N T S'],
  "establishments": ['EH0 S T AE1 B L IH0 SH M AH0 N T S'],
  "estabrook": ['EH1 S T AH0 B R UH2 K'],
  "estabrooks": ['EH1 S T AH0 B R UH0 K S'],
  "estai": ['EH1 S T EY0'],
  "estan": ['EH1 S T AH0 N'],
  "estate": ['IH0 S T EY1 T'],
  "estate's": ['IH0 S T EY1 T S'],
  "estatehood": ['IH0 S T EY1 T HH UH2 D'],
  "estates": ['IH0 S T EY1 T S'],
  "este": ['EH1 S T EY0'],
  "esteban": ['EH1 S T AH0 B AA0 N'],
  "estedat": ['EH1 S T EH0 D AE2 T'],
  "estee": ['EH1 S T IY0'],
  "esteem": ['AH0 S T IY1 M'],
  "esteem's": ['IH0 S T IY1 M Z'],
  "esteemed": ['IH0 S T IY1 M D'],
  "estefan": ['EH1 S T IH0 F AA0 N', 'EH0 S T EH1 V AH0 N'],
  "estel": ['EH1 S T AH0 L'],
  "estell": ['EH1 S T AH0 L'],
  "estella": ['EH0 S T EH1 L AH0'],
  "estelle": ['EH0 S T EH1 L'],
  "estenson": ['EH1 S T IH0 N S AH0 N'],
  "estep": ['EH1 S T IH0 P'],
  "estepp": ['EH1 S T IH0 P'],
  "ester": ['EH1 S T ER0'],
  "esterline": ['EH1 S T ER0 L AY2 N'],
  "esterline's": ['EH1 S T ER0 L AY2 N Z'],
  "esterly": ['EH1 S T ER0 L IY0'],
  "esters": ['EH1 S T ER0 Z'],
  "estes": ['EH1 S T IY0 Z'],
  "esteve": ['EH1 S T IH0 V'],
  "esteves": ['EY0 S T EY1 V EH0 S'],
  "estevez": ['EY0 S T EY1 V EH0 Z'],
  "estey": ['EH1 S T IY0'],
  "esther": ['EH1 S T ER0'],
  "esthetic": ['EH0 S TH EH1 T IH0 K'],
  "esthetically": ['EH0 S TH EH1 T IH0 K L IY0'],
  "esthetics": ['EH0 S TH EH1 T IH0 K S'],
  "estill": ['EH0 S T IY1 L'],
  "estimable": ['EH1 S T AH0 M AH0 B AH0 L'],
  "estimate": ['EH1 S T AH0 M AH0 T', 'EH1 S T AH0 M EY2 T'],
  "estimated": ['EH1 S T AH0 M EY2 T AH0 D', 'EH1 S T AH0 M EY2 T IH0 D'],
  "estimates": ['EH1 S T AH0 M AH0 T S', 'EH1 S T AH0 M EY2 T S'],
  "estimating": ['EH1 S T AH0 M EY2 T IH0 NG'],
  "estimation": ['EH2 S T AH0 M EY1 SH AH0 N'],
  "estimations": ['EH2 S T AH0 M EY1 SH AH0 N Z'],
  "estimator": ['EH0 S T IH1 M AH0 T ER0', 'EH1 S T IH0 M EY2 T ER0'],
  "estimators": ['EH0 S T IH1 M AH0 T ER0 Z', 'EH1 S T IH0 M EY2 T ER0 Z'],
  "estis": ['EH1 S T IH0 S'],
  "estle": ['EH1 S AH0 L'],
  "estock": ['EH1 S T AA0 K'],
  "estok": ['IH0 S T AA1 K'],
  "estonia": ['EH0 S T OW1 N IY0 AH0'],
  "estonian": ['EH0 S T OW1 N IY0 AH0 N'],
  "estonians": ['EH0 S T OW1 N IY0 AH0 N Z'],
  "estrada": ['EH0 S T R AA1 D AH0'],
  "estrange": ['EH0 S T R EY1 N JH'],
  "estranged": ['EH0 S T R EY1 N JH D'],
  "estrangement": ['IH0 S T R EY1 N JH M AH0 N T'],
  "estrella": ['EH2 S T R EH1 L AH0'],
  "estrich": ['EH1 S T R IH0 K'],
  "estridge": ['IH0 S T R IH1 JH'],
  "estrin": ['EH1 S T R IH0 N'],
  "estrogen": ['EH1 S T R AH0 JH AH0 N'],
  "estrogens": ['EH1 S T R AH0 JH IH0 N Z'],
  "estrous": ['EH1 S T R AH0 S'],
  "estrus": ['EH1 S T R AH0 S'],
  "estruth": ['EH1 S T R UW2 TH'],
  "estuaries": ['EH1 S CH UW0 EH2 R IY0 Z'],
  "estuary": ['EH1 S CH UW0 EH2 R IY0'],
  "esty": ['EH1 S T IY0'],
  "esworthy": ['EH1 S W ER0 DH IY0'],
  "eszterhas": ['EH1 S T ER0 HH AA0 S'],
  "et": ['EH1 T'],
  "etablissements": ['EH2 T AE0 B L IH2 S AH0 M AA1 N T S'],
  "etc": ['EH2 T S EH1 T ER0 AH0'],
  "etc.": ['EH2 T S EH1 T ER0 AH0'],
  "etcetera": ['EH1 T S EH1 T ER0 AH0'],
  "etch": ['EH1 CH'],
  "etched": ['EH1 CH T'],
  "etches": ['EH1 CH IH0 Z'],
  "etcheverry": ['EH1 CH IH0 V EH0 R IY0'],
  "etching": ['EH1 CH IH0 NG'],
  "etchings": ['EH1 CH IH0 NG Z'],
  "etchison": ['EH1 CH IH0 S AH0 N'],
  "eternal": ['IH0 T ER1 N AH0 L', 'IY0 T ER1 N AH0 L'],
  "eternally": ['IH0 T ER1 N AH0 L IY0', 'IY0 T ER1 N AH0 L IY0'],
  "eternit": ['EH1 T ER0 N IH0 T'],
  "eternity": ['IH0 T ER1 N AH0 T IY0', 'IY0 T ER1 N AH0 T IY0'],
  "etess": ['EH2 T EH1 S'],
  "ethan": ['IY1 TH AH0 N'],
  "ethane": ['EH1 TH EY2 N'],
  "ethanol": ['EH1 TH AH0 N AO2 L'],
  "ethel": ['EH1 TH AH0 L'],
  "ethelbert": ['EH1 TH IH0 L B ER0 T'],
  "ethelda": ['IH0 TH EH1 L D AH0'],
  "ethelinda": ['EH0 TH EH0 L IY1 N D AH0'],
  "etheline": ['EH1 TH IH0 L AY2 N'],
  "etheljean": ['EH1 TH IH0 L Y AH0 N', 'EH2 TH AH0 L JH IY1 N'],
  "ethelson": ['EH1 TH IH0 L S IH0 N'],
  "ethelyn": ['EH1 TH IH0 L IH0 N'],
  "ethene": ['EH1 TH IY2 N'],
  "ether": ['IY1 TH ER0'],
  "ethereal": ['IH0 TH IH1 R IY0 AH0 L'],
  "etheredge": ['EH1 TH ER0 EH0 JH'],
  "etheridge": ['EH1 TH ER0 IH2 JH'],
  "etherington": ['EH1 DH ER0 IH0 NG T AH0 N'],
  "ethernet": ['IY1 TH ER0 N EH2 T'],
  "ethers": ['IY1 TH ER0 Z'],
  "etherton": ['IH0 TH ER1 T AH0 N', 'EH1 TH ER0 T AH0 N'],
  "ethic": ['EH1 TH IH0 K'],
  "ethical": ['EH1 TH IH0 K AH0 L'],
  "ethically": ['EH1 TH IH0 K AH0 L IY0'],
  "ethicist": ['EH1 TH IH0 S IH0 S T'],
  "ethicists": ['EH1 TH IH0 S IH0 S T S'],
  "ethicon": ['EH1 TH IH0 K AA2 N'],
  "ethics": ['EH1 TH IH0 K S'],
  "ethier": ['EH1 TH IY0 ER0'],
  "ethington": ['EH1 TH IH0 NG T AH0 N'],
  "ethiopia": ['IY2 TH IY0 OW1 P IY0 AH0'],
  "ethiopia's": ['IY2 TH IY0 OW1 P IY0 AH0 Z'],
  "ethiopian": ['IY2 TH IY0 OW1 P IY0 AH0 N'],
  "ethiopians": ['IY2 TH IY0 OW1 P IY0 AH0 N Z'],
  "ethnic": ['EH1 TH N IH0 K'],
  "ethnical": ['EH1 TH N IH0 K AH0 L'],
  "ethnically": ['EH1 TH N IH0 K L IY0'],
  "ethnicity": ['EH0 TH N IH1 S IH0 T IY0'],
  "ethnics": ['EH1 TH N IH0 K S'],
  "ethnocentric": ['EH2 TH N OW0 S EH1 N T R IH0 K'],
  "ethnocentrism": ['EH2 TH N OW0 S EH1 N T R IH0 Z AH0 M'],
  "ethnographic": ['EH2 TH N AH0 G R AE1 F IH0 K'],
  "ethnology": ['EH0 TH N AA1 L AH0 JH IY0'],
  "ethnomusicologist": ['EH0 TH N AH0 M Y UW2 Z AH0 K AA1 L AH0 JH IH0 S T'],
  "ethnos": ['EH1 TH N OW0 S'],
  "ethnos's": ['EH1 TH N AH0 S IH0 Z'],
  "ethology": ['IH0 TH AA1 L AH0 JH IY0'],
  "ethos": ['IY1 TH AA0 S'],
  "ethridge": ['IH0 TH R IH1 JH'],
  "ethyl": ['EH1 TH AH0 L'],
  "ethylene": ['EH1 TH AH0 L IY2 N'],
  "ethyol": ['EH1 TH AY0 AA0 L'],
  "etienne": ['EH1 T IY0 EH0 N'],
  "etiologies": ['IY2 T IY0 AA1 L AH0 JH IY0 Z'],
  "etiology": ['IY2 T IY0 AA1 L AH0 JH IY0', 'IY2 T IY2 AA1 L AH0 JH IY0'],
  "etiquette": ['EH1 T AH0 K AH0 T'],
  "etling": ['EH1 T L IH0 NG'],
  "etna": ['EH1 T N AH0'],
  "eton": ['IY1 T AH0 N'],
  "etops": ['IY1 T AA0 P S'],
  "etruscan": ['IH0 T R AH1 S K AH0 N'],
  "etruscans": ['IH0 T R AH1 S K AH0 N Z'],
  "etsty": ['EH1 T S T IY0'],
  "etsu": ['EH1 T S UW0'],
  "etsy": ['EH1 T S IY0'],
  "etta": ['EH1 T AH0'],
  "etter": ['EH1 T ER0'],
  "etters": ['EH1 T ER0 Z'],
  "ettie": ['EH1 T IY0'],
  "ettinger": ['EH1 T IH0 NG ER0'],
  "ettinger's": ['EH1 T IH0 NG ER0 Z'],
  "ettlinger": ['EH1 T L IH0 NG ER0'],
  "etty": ['EH1 T IY0'],
  "etudes": ['EY1 T UW2 D Z'],
  "etymological": ['EH2 T AH0 M AH0 L AA1 JH AH0 K AH0 L'],
  "etymology": ['EH2 T AH0 M AA1 L AH0 JH IY0', 'EH2 T IH0 M AA1 L AH0 JH IY0'],
  "etzel": ['EH1 T Z AH0 L'],
  "etzel's": ['EH1 T Z AH0 L Z'],
  "etzioni": ['EH2 T S IY2 OW1 N IY0'],
  "etzkorn": ['EH1 T S K ER0 N'],
  "etzler": ['EH1 T S L ER0'],
  "eu": ['IY1 Y UW2'],
  "euan": ['Y UW1 AH0 N'],
  "eubank": ['Y UW1 B AE0 NG K'],
  "eubanks": ['Y UW1 B AE0 NG K S'],
  "eucalypti": ['Y UW2 K AH0 L IH1 P T AY0', 'Y UW2 K AH0 L IH1 P T IY0'],
  "eucalyptus": ['Y UW2 K AH0 L IH1 P T AH0 S'],
  "eucharist": ['Y UW1 K ER0 AH0 S T'],
  "euchre": ['Y UW1 K ER0'],
  "euchred": ['Y UW1 K ER0 D'],
  "euclea": ['Y UW1 K L IY0 AH0'],
  "euclid": ['Y UW1 K L IH0 D'],
  "eudocia": ['Y UW0 D OW1 CH AH0'],
  "eudora": ['Y UW0 D AO1 R AH0'],
  "eudosia": ['Y UW0 D OW1 S IY0 AH0'],
  "eudoxia": ['Y UW2 D AA1 K S IY0 AH0'],
  "eudy": ['Y UW1 D IY0'],
  "eugene": ['Y UW0 JH IY1 N', 'Y UW1 JH IY0 N'],
  "eugenia": ['Y UW2 JH IY1 N IY0 AH0'],
  "eugenia's": ['Y UW2 JH IY1 N IY0 AH0 Z'],
  "eugenic": ['Y UW0 JH EH1 N IH0 K'],
  "eugenics": ['Y UW0 JH EH1 N IH0 K S'],
  "eugenie": ['Y UW2 JH IY1 N IY0'],
  "eugenio": ['Y UW2 JH IY1 N IY0 OW0'],
  "eugenio's": ['Y UW2 JH IY1 N IY0 OW0 Z'],
  "eula": ['Y UW1 L AH0'],
  "euler": ['OY1 L ER0'],
  "eulich": ['Y UW1 L IH0 K'],
  "eulogies": ['Y UW1 L AH0 JH IY0 Z'],
  "eulogize": ['Y UW1 L AH0 JH AY2 Z'],
  "eulogized": ['Y UW1 L AH0 JH AY2 Z D'],
  "eulogy": ['Y UW1 L AH0 JH IY0'],
  "eulogy's": ['Y UW1 L AH0 JH IY0 Z'],
  "eunice": ['Y UW1 N AH0 S', 'Y UW1 N IH0 S'],
  "eunuch": ['Y UW1 N AH0 K'],
  "euphemia": ['Y UW0 F IY1 M IY0 AH0'],
  "euphemism": ['Y UW1 F AH0 M IH2 Z AH0 M'],
  "euphemisms": ['Y UW1 F AH0 M IH2 Z AH0 M Z'],
  "euphemistic": ['Y UW2 F AH0 M IH1 S T IH0 K'],
  "euphemistically": ['Y UW2 F AH0 M IH1 S T IH0 K L IY0'],
  "euphony": ['Y UW1 F AH0 N IY0'],
  "euphoria": ['Y UW0 F AO1 R IY0 AH0'],
  "euphoric": ['Y UW0 F AO1 R IH0 K'],
  "euphory": ['Y UW1 F AO0 R IY0'],
  "euphrates": ['Y UW0 F R EY1 T IY0 Z'],
  "eurailpass": ['Y UW2 R EY1 L P AH2 S'],
  "eurasia": ['Y UH0 R EY1 ZH AH0'],
  "eurasian": ['Y UH0 R EY1 ZH IH0 N'],
  "eure": ['ER1'],
  "eureca": ['Y UW1 R EH0 K AH0'],
  "eureka": ['Y UH0 R IY1 K AH0'],
  "eurest": ['Y UH1 R EH0 S T'],
  "eurich": ['OY1 R IH0 K'],
  "euro": ['Y UW1 R OW0', 'Y UH1 R OW0', 'Y UH1 R AH0'],
  "eurobond": ['Y UW1 R OW0 B AA2 N D'],
  "eurobonds": ['Y UW1 R OW0 B AA2 N D Z'],
  "eurocheck": ['Y UW1 R AH0 CH EH2 K'],
  "eurochecks": ['Y UW1 R AH0 CH EH2 K S'],
  "eurocom": ['Y UW1 R AH0 K AH0 M', 'Y UW1 R OW0 K AA0 M'],
  "eurocommercial": ['Y UW1 R OW0 K AH0 M ER1 SH AH0 L'],
  "eurocopter": ['Y UH1 R OW0 K AA2 P T ER0'],
  "eurocopters": ['Y UH1 R OW0 K AA2 P T ER0 Z'],
  "eurocrat": ['Y UW1 R AH0 K R AE0 T'],
  "eurocrats": ['Y UW1 R AH0 K R AE0 T S'],
  "eurodebenture": ['Y UW2 R OW0 D AH0 B EH1 N CH ER0'],
  "eurodebentures": ['Y UW2 R OW0 D AH0 B EH1 N CH ER0 Z'],
  "eurodebt": ['Y UW1 R OW0 D EH2 T'],
  "eurodeposit": ['Y UW2 R OW0 D AH0 P AA1 Z AH0 T'],
  "eurodeposits": ['Y UW2 R OW0 D IH0 P AA1 Z IH0 T S'],
  "eurodisney": ['Y UW1 R OW0 D IH2 Z N IY0', 'Y UH1 R OW0 D IH2 Z N IY0'],
  "eurodollar": ['Y UW1 R OW0 D AA2 L ER0', 'Y UH1 R OW0 D AO2 L ER0'],
  "eurodollars": ['Y UW1 R OW0 D AA2 L ER0 Z'],
  "eurofighter": ['Y UW1 R OW0 F AY2 T ER0'],
  "eurofighters": ['Y UW1 R OW0 F AY2 T ER0 Z'],
  "eurofima": ['Y UW2 R AH0 F IY1 M AH0'],
  "euromark": ['Y UW1 R AH0 M AA0 R K'],
  "euromarket": ['Y UW1 R OW0 M AA2 R K IH0 T'],
  "euromarkets": ['Y UW1 R OW0 M AA2 R K IH0 T S'],
  "euromissile": ['Y UW2 R OW0 M IH1 S AH0 L'],
  "euromissiles": ['Y UW2 R OW0 M IH1 S AH0 L Z'],
  "euromobiliare": ['Y UW1 R OW0 M OW0 B IH0 L EH1 R'],
  "euromobiliare's": ['Y UW1 R OW0 M OW0 B IH0 L EH1 R Z'],
  "euromoney": ['Y UW1 R OW0 M AH2 N IY0'],
  "euronote": ['Y UW1 R AH0 N OW2 T'],
  "euronotes": ['Y UW1 R AH0 N OW2 T S'],
  "europa": ['Y UH0 R OW1 P AH0'],
  "europe": ['Y UH1 R AH0 P'],
  "europe's": ['Y UH1 R AH0 P S'],
  "european": ['Y UH2 R AH0 P IY1 AH0 N'],
  "europeans": ['Y UH2 R AH0 P IY1 AH0 N Z'],
  "europeans'": ['Y UH2 R AH0 P IY1 AH0 N Z'],
  "europeenne": ['Y UW1 R OW0 P IY2 N'],
  "europewide": ['Y UH1 R AH0 P W AY1 D'],
  "europhile": ['Y UH1 R OW0 F AY0 L'],
  "europhiles": ['Y UH1 R OW0 F AY0 L Z'],
  "europium": ['Y UH0 R OW1 P IY0 AH0 M'],
  "euros": ['Y UW1 R OW2 Z', 'Y UW1 R OW0 Z'],
  "eurosid": ['Y UW1 R AH0 S IH0 D'],
  "eurostar": ['Y UH1 R OW0 S T AA2 R'],
  "eurostat": ['Y UW2 R AO1 S T AE0 T'],
  "eurosterling": ['Y UW1 R OW0 S T ER1 L IH0 NG'],
  "eurotunnel": ['Y UW1 R OW0 T AH1 N AH0 L'],
  "eurotunnel's": ['Y UH1 R OW0 T AH2 N AH0 L Z'],
  "euroyen": ['Y UW1 R OW0 Y EH2 N'],
  "eury": ['Y UW1 R IY0'],
  "eusebio": ['Y UW2 S IY1 B IY0 OW0'],
  "eustace": ['Y UW1 S T AH0 S'],
  "eustachian": ['Y UW0 S T EY1 K IY0 AH0 N'],
  "eustacia": ['Y UW0 S T AA1 CH AH0'],
  "eustice": ['Y UW1 S T IH0 S'],
  "eustis": ['Y UW1 S T AH0 S'],
  "euthanasia": ['Y UW2 TH AH0 N EY1 ZH AH0', 'Y UW2 TH AH0 N EY1 ZH Y AH0'],
  "euthanasia's": ['Y UW2 TH AH0 N EY1 ZH AH0 Z'],
  "euthanize": ['Y UW1 TH AH0 N AY2 Z'],
  "eutsey": ['Y UW1 T S IY0'],
  "eutsler": ['Y UW1 T S L ER0'],
  "ev": ['EH1 V'],
  "eva": ['EY1 V AH0', 'IY1 V AH0'],
  "eva's": ['EY1 V AH0 Z', 'IY1 V AH0 Z'],
  "evacuate": ['IH0 V AE1 K Y AH0 EY2 T', 'IY0 V AE1 K Y AH0 EY2 T'],
  "evacuated": ['IH0 V AE1 K Y AH0 W EY2 T IH0 D', 'IY0 V AE1 K Y AH0 W EY2 T IH0 D'],
  "evacuating": ['IH0 V AE1 K Y AH0 W EY2 T IH0 NG', 'IY0 V AE1 K Y AH0 W EY2 T IH0 NG'],
  "evacuation": ['IH0 V AE2 K Y AH0 W EY1 SH AH0 N', 'IY0 V AE1 K Y AH0 W EY1 SH AH0 N'],
  "evacuations": ['IH0 V AE2 K Y AH0 W EY1 SH AH0 N Z', 'IY0 V AE1 K Y AH0 W EY1 SH AH0 N Z'],
  "evacuee": ['IH0 V AE1 K Y UW0 IY0', 'IY0 V AE1 K Y UW0 IY0'],
  "evacuees": ['IH0 V AE1 K Y UW0 IY0 Z', 'IY0 V AE1 K Y UW0 IY0 Z'],
  "evade": ['IH0 V EY1 D', 'IY0 V EY1 D'],
  "evaded": ['IH0 V EY1 D IH0 D', 'IY0 V EY1 D IH0 D'],
  "evader": ['IH0 V EY1 D ER0', 'IY0 V EY1 D ER0'],
  "evaders": ['IH0 V EY1 D ER0 Z', 'IY0 V EY1 D ER0 Z'],
  "evades": ['IH0 V EY1 D Z', 'IY0 V EY1 D Z'],
  "evading": ['IH0 V EY1 D IH0 NG', 'IY0 V EY1 D IH0 NG'],
  "evadne": ['IH0 V EH1 D N IY0'],
  "evald": ['EH1 V AH0 L D', 'IY1 V AA2 L D'],
  "evaleen": ['EH1 V AH0 L IY2 N'],
  "evaluate": ['IH0 V AE1 L Y UW0 EY2 T', 'IY0 V AE1 L Y UW0 EY2 T'],
  "evaluated": ['IH0 V AE1 L Y UW0 EY2 T AH0 D', 'IY0 V AE1 L Y UW0 EY2 T AH0 D'],
  "evaluates": ['IH0 V AE1 L Y UW0 EY2 T S', 'IY0 V AE1 L Y UW0 EY2 T S'],
  "evaluating": ['IH0 V AE1 L Y UW0 EY2 T IH0 NG', 'IY0 V AE1 L Y UW0 EY2 T IH0 NG'],
  "evaluation": ['IH0 V AE2 L Y UW0 EY1 SH AH0 N', 'IY0 V AE2 L Y UW0 EY1 SH AH0 N'],
  "evaluations": ['IH0 V AE2 L Y UW0 EY1 SH AH0 N Z', 'IY0 V AE2 L Y UW0 EY1 SH AH0 N Z'],
  "evan": ['EH1 V AH0 N'],
  "evancho": ['EH0 V AA1 N K OW0'],
  "evander": ['IY2 V AE1 N D ER0'],
  "evandro": ['EH2 V AA1 D R OW0', 'IY2 V AE1 D R OW0'],
  "evanescence": ['EH2 V AH0 N EH1 S AH0 N S'],
  "evanescent": ['EH2 V AH0 N EH1 S AH0 N T'],
  "evangelical": ['IY2 V AE0 N JH EH1 L IH0 K AH0 L'],
  "evangelicals": ['IY2 V AE2 N JH EH1 L IH0 K AH0 L Z'],
  "evangeline": ['IH0 V AE1 N JH IH0 L AY2 N'],
  "evangelism": ['IH0 V AE1 N JH AH0 L IH2 Z AH0 M', 'IY0 V AE1 N JH AH0 L IH2 Z AH0 M'],
  "evangelist": ['IH0 V AE1 N JH AH0 L IH0 S T', 'IY0 V AE1 N JH AH0 L IH0 S T'],
  "evangelista": ['EH0 V AA0 NG G EH0 L IY1 S T AH0'],
  "evangelistic": ['IY0 V AE1 N JH AH0 L IH0 S T IH0 K'],
  "evangelists": ['IH0 V AE1 N JH AH0 L IH0 S T S', 'IY0 V AE1 N JH AH0 L IH0 S T S', 'IH0 V AE1 N JH AH0 L IH0 S', 'IY0 V AE1 N JH AH0 L IH0 S'],
  "evangelize": ['IH0 V AE1 N JH AH0 L AY2 Z', 'IY0 V AE1 N JH AH0 L AY2 Z'],
  "evangi": ['IH0 V AE1 N JH IY0'],
  "evanko": ['IH0 V AE1 NG K OW0'],
  "evanoff": ['EH1 V AH0 N AO2 F'],
  "evanov": ['EH1 V AH0 N AO2 V'],
  "evans": ['EH1 V AH0 N Z'],
  "evans'": ['EH1 V AH0 N Z'],
  "evans's": ['EH1 V AH0 N Z IH0 Z'],
  "evanson": ['EH1 V AH0 N S AH0 N'],
  "evanston": ['EH1 V AH0 N S T AH0 N'],
  "evansville": ['EH1 V AH0 N Z V IH2 L'],
  "evaporate": ['IH0 V AE1 P ER0 EY2 T'],
  "evaporated": ['IH0 V AE1 P ER0 EY2 T AH0 D', 'IH0 V AE1 P ER0 EY2 T IH0 D', 'IY0 V AE1 P ER0 EY2 T AH0 D', 'IY0 V AE1 P ER0 EY2 T IH0 D'],
  "evaporates": ['IH0 V AE1 P ER0 EY2 T S', 'IY0 V AE1 P ER0 EY2 T S'],
  "evaporating": ['IH0 V AE1 P ER0 EY2 T IH0 NG', 'IY0 V AE1 P ER0 EY2 T IH0 NG'],
  "evaporation": ['IH0 V AE2 P ER0 EY1 SH AH0 N', 'IY0 V AE2 P ER0 EY1 SH AH0 N'],
  "evaporator": ['IH0 V AE1 P ER0 EY2 T ER0', 'IY0 V AE1 P ER0 EY2 T ER0'],
  "evarts": ['EH1 V ER0 T S'],
  "evasion": ['IH0 V EY1 ZH AH0 N', 'IY0 V EY1 ZH AH0 N'],
  "evasions": ['IH0 V EY1 ZH AH0 N Z', 'IY0 V EY1 ZH AH0 N Z'],
  "evasive": ['IH0 V EY1 Z IH0 V', 'IY0 V EY1 Z IH0 V'],
  "evatt": ['EH1 V AH0 T'],
  "eve": ['IY1 V'],
  "evel": ['EH1 V AH0 L'],
  "eveland": ['IY1 V L AH0 N D'],
  "eveleth": ['EH1 V IH0 L IH0 TH'],
  "evelina": ['EH2 V EH0 L IY1 N AH0'],
  "eveline": ['EH1 V AH0 L IH0 N'],
  "evelyn": ['EH1 V AH0 L AH0 N', 'EH1 V AH0 L IH0 N'],
  "even": ['IY1 V IH0 N'],
  "evendale": ['EH1 V AH0 N D EY2 L'],
  "evened": ['IY1 V AH0 N D'],
  "evenhanded": ['IY1 V AH0 N HH AE1 N D IH0 D'],
  "evenhandedly": ['EH1 V AH0 N HH AE2 N D IH0 D L IY0'],
  "evening": ['IY1 V N IH0 NG'],
  "evening's": ['IY1 V N IH0 NG Z'],
  "evenings": ['IY1 V N IH0 NG Z'],
  "evenly": ['IY1 V AH0 N L IY0'],
  "evenness": ['IY1 V AH0 N N AH0 S'],
  "evens": ['IY1 V AH0 N Z'],
  "evensen": ['EH1 V IH0 N S AH0 N'],
  "evensky": ['IY2 V EH1 N S K IY0'],
  "evenson": ['EH1 V IH0 N S AH0 N'],
  "event": ['IH0 V EH1 N T', 'IY0 V EH1 N T'],
  "event's": ['IH0 V EH1 N T S', 'IY0 V EH1 N T S'],
  "eventful": ['IH0 V EH1 N T F AH0 L', 'IY0 V EH1 N T F AH0 L'],
  "events": ['IH0 V EH1 N T S', 'IY0 V EH1 N T S'],
  "eventual": ['AH0 V EH1 N CH UW0 AH0 L', 'IY0 V EH1 N CH UW0 AH0 L'],
  "eventuality": ['AH0 V EH2 N CH UW0 AE1 L IH0 T IY0', 'IY0 V EH2 N CH UW0 AE1 L IH0 T IY0'],
  "eventually": ['IH0 V EH1 N CH AH0 W AH0 L IY0', 'IH0 V EH1 N SH AH0 L IY0', 'IY0 V EH1 N CH AH0 W AH0 L IY0', 'IY0 V EH1 N SH AH0 L IY0'],
  "ever": ['EH1 V ER0'],
  "everage": ['EH1 V ER0 IH0 JH'],
  "everard": ['EH1 V ER0 ER0 D'],
  "everbach": ['EH1 V ER0 B AA2 K'],
  "everding": ['EH1 V ER0 D IH0 NG'],
  "eveready": ['EH1 V ER0 R EH2 D IY0'],
  "evered": ['EH1 V ER0 D'],
  "everest": ['EH1 V ER0 AH0 S T', 'EH1 V R AH0 S T'],
  "everest's": ['EH1 V ER0 AH0 S T S', 'EH1 V R AH0 S T S'],
  "everett": ['EH1 V ER0 AH0 T', 'EH1 V R AH0 T'],
  "everette": ['EH1 V ER0 EH1 T'],
  "everetts": ['EH1 V ER0 AH0 T S', 'EH1 V R AH0 T S'],
  "everex": ['EH1 V ER0 AH0 K S'],
  "everglades": ['EH1 V ER0 G L EY2 D Z'],
  "everglades'": ['EH1 V ER0 G L EY2 D Z'],
  "evergood": ['EH1 V ER0 G UH2 D'],
  "evergreen": ['EH1 V ER0 G R IY2 N'],
  "evergreens": ['EH1 V ER0 G R IY2 N Z'],
  "everhardt": ['EH1 V ER0 HH AA2 R T'],
  "everhart": ['EH1 V ER0 HH AA2 R T'],
  "everidge": ['EH1 V ER0 IH2 JH'],
  "everingham": ['EH1 V ER0 IH0 NG HH AE2 M'],
  "everington": ['EH1 V ER0 IH2 NG T AH0 N'],
  "everitt": ['EH1 V ER0 IH0 T'],
  "everlast": ['EH2 V ER0 L AE1 S T'],
  "everlasting": ['EH2 V ER0 L AE1 S T IH0 NG'],
  "everlastings": ['EH2 V ER0 L AE1 S T IH0 NG Z'],
  "everley": ['EH1 V ER0 L IY0'],
  "everling": ['EH1 V ER0 L IH0 NG'],
  "everly": ['EH1 V ER0 L IY0'],
  "everman": ['EH1 V ER0 M AH0 N'],
  "evermore": ['EH1 V ER0 M AO2 R'],
  "evers": ['EH1 V ER0 Z'],
  "evers'": ['EH1 V ER0 Z'],
  "eversman": ['EH1 V ER0 S M AH0 N'],
  "eversole": ['EH1 V ER0 S OW2 L'],
  "everson": ['EH1 V ER0 S AH0 N'],
  "evert": ['IH0 V ER1 T'],
  "everton": ['EH1 V ER0 T AH0 N'],
  "everts": ['EH1 V ER0 T S'],
  "every": ['EH1 V ER0 IY0', 'EH1 V R IY0'],
  "everybody": ['EH1 V R IY0 B AA2 D IY0'],
  "everybody'd": ['EH1 V R IY0 B AA2 D IY0 D'],
  "everybody's": ['EH1 V R IY0 B AA2 D IY0 Z'],
  "everyday": ['EH1 V R IY0 D EY1'],
  "everyman": ['EH1 V R IY0 M AH0 N'],
  "everyman's": ['EH1 V R IY0 M AH0 N Z'],
  "everyone": ['EH1 V R IY0 W AH2 N'],
  "everyone's": ['EH1 V R IY0 W AH2 N Z'],
  "everyplace": ['EH1 V R IY0 P L EY2 S'],
  "everything": ['EH1 V R IY0 TH IH2 NG'],
  "everything's": ['EH1 V R IY0 TH IH2 NG Z'],
  "everythings": ['EH1 V R IY0 TH IH2 NG Z'],
  "everytime": ['EH1 V R IY0 T AY2 M'],
  "everywhere": ['EH1 V R IY0 W EH2 R', 'EH1 V R IY0 HH W EH2 R'],
  "eves": ['IY1 V Z'],
  "evett": ['EH1 V IH0 T'],
  "evetts": ['EH1 V IH0 T S'],
  "evian": ['EH1 V IY0 AH0 N'],
  "evict": ['IH0 V IH1 K T'],
  "evicted": ['IH0 V IH1 K T IH0 D'],
  "evicting": ['IH0 V IH1 K T IH0 NG'],
  "eviction": ['IH0 V IH1 K SH AH0 N'],
  "evictions": ['IH0 V IH1 K SH AH0 N Z'],
  "evidence": ['EH1 V AH0 D AH0 N S'],
  "evidenced": ['EH1 V IH0 D AH0 N S T'],
  "evidences": ['EH1 V AH0 D AH0 N S AH0 Z'],
  "evident": ['EH1 V AH0 D AH0 N T'],
  "evidentiary": ['EH2 V IH0 D EH1 N SH IY0 EH0 R IY0', 'EH2 V IH0 D EH1 N CH IY0 EH0 R IY0'],
  "evidently": ['EH1 V AH0 D AH0 N T L IY0'],
  "evil": ['IY1 V AH0 L'],
  "evildoer": ['IY1 V AH0 L D UW2 ER0'],
  "evildoers": ['IY1 V AH0 L D UW2 ER0 Z'],
  "evils": ['IY1 V AH0 L Z'],
  "evilsizer": ['IY1 V AH0 L S AY2 Z ER0'],
  "evilsizor": ['IY1 V AH0 L S AY2 Z ER0'],
  "evin": ['EH1 V IH0 N'],
  "evince": ['IH0 V IH1 N S'],
  "evinced": ['IH0 V IH1 N S T'],
  "evinger": ['IY1 V IH0 NG ER0'],
  "evins": ['EH1 V IH0 N Z'],
  "eviscerate": ['AH0 V IH1 S ER0 EY2 T'],
  "eviscerated": ['AH0 V IH1 S ER0 EY2 T IH0 D'],
  "evita": ['EH0 V IY1 T AH0'],
  "evitt": ['EH1 V IH0 T'],
  "evitts": ['EH1 V IH0 T S'],
  "evjen": ['EH1 V JH AH0 N'],
  "evlyn": ['EH1 V L AH0 N'],
  "evocation": ['IY2 V OW0 K EY1 SH AH0 N'],
  "evocative": ['IH0 V AA1 K AH0 T IH0 V'],
  "evoke": ['IH0 V OW1 K', 'IY0 V OW1 K'],
  "evoked": ['IH0 V OW1 K T', 'IY0 V OW1 K T'],
  "evokes": ['IH0 V OW1 K S', 'IY0 V OW1 K S'],
  "evoking": ['IH0 V OW1 K IH0 NG', 'IY0 V OW1 K IH0 NG'],
  "evola": ['EH0 V OW1 L AH0'],
  "evolution": ['EH2 V AH0 L UW1 SH AH0 N', 'IY2 V AH0 L UW1 SH AH0 N', 'EH2 V OW0 L UW1 SH AH0 N', 'IY2 V OW0 L UW1 SH AH0 N'],
  "evolutionary": ['EH2 V AH0 L UW1 SH AH0 N EH2 R IY0', 'IY2 V AH0 L UW1 SH AH0 N EH2 R IY0', 'EH2 V OW0 L UW1 SH AH0 N EH2 R IY0', 'IY2 V OW0 L UW1 SH AH0 N EH2 R IY0'],
  "evolve": ['IH0 V AA1 L V', 'IY0 V AA1 L V'],
  "evolved": ['IH0 V AA1 L V D', 'IY0 V AA1 L V D'],
  "evolves": ['IH0 V AA1 L V Z', 'IY0 V AA1 L V Z'],
  "evolving": ['IH0 V AA1 L V IH0 NG', 'IY0 V AA1 L V IH0 NG'],
  "evon": ['EH1 V AH0 N'],
  "evonne": ['EH2 V AA1 N'],
  "evrard": ['EH1 V R ER0 D'],
  "evren": ['EH1 V R AH0 N'],
  "ewald": ['Y UW1 AH0 L D'],
  "ewalt": ['Y UW1 AH0 L T'],
  "ewan": ['Y UW1 AH0 N'],
  "ewart": ['Y UW1 ER0 T'],
  "ewbal": ['Y UW1 B AA0 L'],
  "ewbank": ['Y UW1 B AH0 NG K'],
  "ewe": ['Y UW1'],
  "ewell": ['Y UW1 AH0 L'],
  "ewen": ['Y UW1 AH0 N'],
  "ewer": ['Y UW1 ER0'],
  "ewers": ['Y UW1 ER0 Z'],
  "ewert": ['Y UW1 ER0 T'],
  "ewes": ['Y UW1 Z'],
  "ewig": ['Y UW1 IH0 G'],
  "ewing": ['Y UW1 IH0 NG'],
  "ewing's": ['Y UW1 IH0 NG Z'],
  "ewings": ['Y UW1 IH0 NG Z'],
  "ewoldt": ['Y UW1 OW0 L T'],
  "ewong": ['Y UW1 AO0 NG'],
  "eworld": ['IY1 W ER2 L D'],
  "ewton": ['Y UW1 T AH0 N'],
  "ewy": ['Y UW1 IY0'],
  "ex": ['EH1 K S'],
  "ex-boyfriend": ['EH1 K S B OY1 F R EH2 N D'],
  "ex-boyfriends": ['EH1 K S B OY1 F R EH2 N D Z'],
  "ex-fighter": ['EH1 K S F AY1 T ER0'],
  "ex-girlfriend": ['EH1 K Z G ER1 L F R EH2 N D'],
  "ex-girlfriends": ['EH1 K Z G ER1 L F R EH2 N D Z'],
  "ex-husband": ['EH1 K S HH AH1 Z B AH0 N D'],
  "ex-husbands": ['EH1 K S HH AH1 Z B AH0 N D Z'],
  "ex-voto": ['EH0 K S V OW1 T OW1'],
  "ex-wife": ['EH1 K S W AY1 F'],
  "ex-wives": ['EH1 K S W AY1 V Z'],
  "exabyte": ['EH1 K S AH0 B AY2 T'],
  "exacerbate": ['IH0 G Z AE1 S ER0 B EY2 T'],
  "exacerbated": ['IH0 G Z AE1 S ER0 B EY2 T AH0 D', 'IH0 G Z AE1 S ER0 B EY2 T IH0 D'],
  "exacerbates": ['IH0 G Z AE1 S ER0 B EY2 T S'],
  "exacerbating": ['IH0 G Z AE1 S ER0 B EY2 T IH0 NG'],
  "exacerbation": ['IH0 G Z AE2 S ER0 B EY1 SH AH0 N'],
  "exacerbations": ['IH0 G Z AE2 S ER0 B EY1 SH AH0 N Z'],
  "exact": ['IH0 G Z AE1 K T'],
  "exacted": ['IH0 G Z AE1 K T IH0 D'],
  "exacting": ['IH0 G Z AE1 K T IH0 NG'],
  "exaction": ['IH0 G Z AE1 K SH AH0 N'],
  "exactions": ['IH0 G Z AE1 K SH AH0 N Z'],
  "exactitude": ['IH0 G Z AE1 K T AH0 T UW2 D'],
  "exactly": ['IH0 G Z AE1 K T L IY0'],
  "exactly's": ['IH0 G Z AE1 K T L IY0 Z'],
  "exacts": ['IH0 G Z AE1 K T S'],
  "exaggerate": ['IH0 G Z AE1 JH ER0 EY2 T'],
  "exaggerated": ['IH0 G Z AE1 JH ER0 EY2 T AH0 D', 'IH0 G Z AE1 JH ER0 EY2 T IH0 D'],
  "exaggerates": ['IH0 G Z AE1 JH ER0 EY2 T S'],
  "exaggerating": ['IH0 G Z AE1 JH ER0 EY2 T IH0 NG'],
  "exaggeration": ['IH0 G Z AE2 JH ER0 EY1 SH AH0 N'],
  "exaggerations": ['IH0 G Z AE2 JH ER0 EY1 SH AH0 N Z'],
  "exalt": ['IH0 G Z AO1 L T'],
  "exalted": ['IH0 G Z AO1 L T IH0 D'],
  "exalting": ['IH0 G Z AO1 L T IH0 NG'],
  "exalts": ['IH0 G Z AO1 L T S'],
  "exam": ['IH0 G Z AE1 M'],
  "examination": ['IH0 G Z AE2 M AH0 N EY1 SH AH0 N'],
  "examinations": ['IH0 G Z AE2 M AH0 N EY1 SH AH0 N Z'],
  "examine": ['IH0 G Z AE1 M IH0 N'],
  "examined": ['IH0 G Z AE1 M AH0 N D'],
  "examiner": ['IH0 G Z AE1 M AH0 N ER0'],
  "examiner's": ['EH0 G Z AE1 M AH0 N ER0 Z'],
  "examiners": ['IH0 G Z AE1 M IH0 N ER0 Z'],
  "examiners'": ['IH0 G Z AE1 M IH0 N ER0 Z'],
  "examines": ['IH0 G Z AE1 M AH0 N Z'],
  "examining": ['IH0 G Z AE1 M IH0 N IH0 NG'],
  "example": ['IH0 G Z AE1 M P AH0 L'],
  "examples": ['IH0 G Z AE1 M P AH0 L Z'],
  "exams": ['IH0 G Z AE1 M Z'],
  "exar": ['EH1 K S ER0'],
  "exasperate": ['IH0 G Z AE1 S P ER0 EY2 T'],
  "exasperated": ['IH0 G Z AE1 S P ER0 EY2 T IH0 D'],
  "exasperating": ['IH0 G Z AE1 S P ER0 EY2 T IH0 NG'],
  "exasperation": ['EH2 K S AE2 S P ER0 EY1 SH AH0 N'],
  "exboyfriend": ['EH2 K S B OY1 F R EH2 N D'],
  "exbroker": ['EH1 K S B R OW1 K ER0'],
  "exbroker's": ['EH1 K S B R OW1 K ER0 Z'],
  "exbrokers": ['EH1 K S B R OW1 K ER0 Z'],
  "excalibur": ['EH2 K S K AE1 L AH0 B ER0'],
  "excavate": ['EH1 K S K AH0 V EY2 T'],
  "excavated": ['EH1 K S K AH0 V EY2 T IH0 D'],
  "excavating": ['EH1 K S K AH0 V EY2 T IH0 NG'],
  "excavation": ['EH2 K S K AH0 V EY1 SH AH0 N'],
  "excavations": ['EH2 K S K AH0 V EY1 SH AH0 N Z'],
  "excavator": ['EH1 K S K AH0 V EY2 T ER0'],
  "excavators": ['EH1 K S K AH0 V EY2 T ER0 Z'],
  "excedrin": ['EH0 K S EH1 D R AH0 N'],
  "exceed": ['IH0 K S IY1 D'],
  "exceeded": ['IH0 K S IY1 D AH0 D', 'IH0 K S IY1 D IH0 D'],
  "exceeding": ['IH0 K S IY1 D IH0 NG'],
  "exceedingly": ['IH0 K S IY1 D IH0 NG L IY0'],
  "exceeds": ['IH0 K S IY1 D Z'],
  "excel": ['IH0 K S EH1 L'],
  "excel's": ['IH0 K S EH1 L Z'],
  "excelan": ['EH2 K S EH1 L AH0 N'],
  "excelled": ['IH0 K S EH1 L D'],
  "excellence": ['EH1 K S AH0 L AH0 N S'],
  "excellencies": ['EH1 K S L EH1 N S IY0 Z'],
  "excellency": ['EH1 K S L EH1 N S IY0'],
  "excellent": ['EH1 K S AH0 L AH0 N T'],
  "excellently": ['EH1 K S AH0 L AH0 N T L IY0'],
  "excelling": ['EH0 K S EH1 L IH0 NG'],
  "excels": ['IH0 K S EH1 L Z'],
  "excelsior": ['IH0 K S EH1 L S IY0 ER0'],
  "except": ['IH0 K S EH1 P T'],
  "excepted": ['IH0 K S EH1 P T IH0 D'],
  "excepting": ['EH2 K S EH1 P T IH0 NG'],
  "exception": ['IH0 K S EH1 P SH AH0 N'],
  "exceptional": ['IH0 K S EH1 P SH AH0 N AH0 L'],
  "exceptionally": ['IH0 K S EH1 P SH AH0 N AH0 L IY0', 'IH0 K S EH1 P SH N AH0 L IY0'],
  "exceptions": ['IH0 K S EH1 P SH AH0 N Z'],
  "excerpt": ['EH1 K S ER0 P T', 'EH0 K S ER1 P T'],
  "excerpted": ['EH2 K S ER1 P T IH0 D'],
  "excerpts": ['EH1 K S ER0 P T S', 'EH0 K S ER1 P T S'],
  "excess": ['EH1 K S EH2 S', 'IH0 K S EH1 S'],
  "excesses": ['EH2 K S EH1 S IH0 Z', 'IH0 K S EH1 S IH0 Z'],
  "excessive": ['IH0 K S EH1 S IH0 V'],
  "excessively": ['IH0 K S EH1 S IH0 V L IY0'],
  "exchange": ['IH0 K S CH EY1 N JH'],
  "exchange's": ['IH0 K S CH EY1 N JH IH0 Z'],
  "exchangeable": ['IH0 K S CH EY1 N JH AH0 B AH0 L'],
  "exchanged": ['IH0 K S CH EY1 N JH D'],
  "exchanger": ['IH0 K S CH EY1 N JH ER0'],
  "exchangers": ['IH0 K S CH EY1 N JH ER0 Z'],
  "exchanges": ['IH0 K S CH EY1 N JH AH0 Z', 'IH0 K S CH EY1 N JH IH0 Z'],
  "exchanges'": ['EH0 K S CH EY1 N JH IH0 Z'],
  "exchanging": ['IH0 K S CH EY1 N JH IH0 NG'],
  "exchequer": ['EH1 K S CH EH2 K ER0'],
  "excimer": ['EH2 K S IH1 M ER0'],
  "excise": ['EH0 K S AY1 S', 'EH1 K S AY0 Z'],
  "excised": ['EH1 K S AY2 Z D'],
  "excises": ['EH1 K S AY2 Z IH0 Z'],
  "excision": ['IH0 K S IH1 ZH AH0 N'],
  "excitable": ['IH0 K S AY1 T AH0 B AH0 L'],
  "excitation": ['EH2 K S AY0 T EY1 SH AH0 N'],
  "excite": ['IH0 K S AY1 T'],
  "excited": ['IH0 K S AY1 T AH0 D', 'IH0 K S AY1 T IH0 D'],
  "excitedly": ['IH0 K S AY1 T AH0 D L IY0'],
  "excitement": ['IH0 K S AY1 T M AH0 N T'],
  "excites": ['IH0 K S AY1 T S'],
  "exciting": ['IH0 K S AY1 T IH0 NG'],
  "exclaim": ['IH0 K S K L EY1 M'],
  "exclaimed": ['IH0 K S K L EY1 M D'],
  "exclaiming": ['IH0 K S K L EY1 M IH0 NG'],
  "exclaims": ['IH0 K S K L EY1 M Z'],
  "exclamation": ['EH2 K S K L AH0 M EY1 SH AH0 N'],
  "exclamation-point": ['EH2 K S K L AH0 M EY1 SH AH0 N P OY1 N T'],
  "exclamations": ['EH2 K S K L AH0 M EY1 SH AH0 N Z'],
  "excludable": ['IH0 K S K L UW1 D AH0 B AH0 L'],
  "exclude": ['IH0 K S K L UW1 D'],
  "excluded": ['IH0 K S K L UW1 D AH0 D', 'IH0 K S K L UW1 D IH0 D'],
  "excludes": ['IH0 K S K L UW1 D Z'],
  "excluding": ['IH0 K S K L UW1 D IH0 NG'],
  "exclusion": ['IH0 K S K L UW1 ZH AH0 N'],
  "exclusionary": ['IH0 K S K L UW1 ZH AH0 N EH2 R IY0'],
  "exclusions": ['IH0 K S K L UW1 ZH AH0 N Z'],
  "exclusive": ['IH0 K S K L UW1 S IH0 V'],
  "exclusively": ['IH0 K S K L UW1 S IH0 V L IY0'],
  "exclusives": ['IH0 K S K L UW1 S IH0 V Z'],
  "exclusivity": ['EH2 K S K L UW2 S IH1 V AH0 T IY0'],
  "exco": ['EH1 K S K OW0'],
  "excoa": ['EH2 K S K OW1 AH0'],
  "excommunicate": ['EH2 K S K AH0 M Y UW1 N AH0 K EY2 T'],
  "excommunicated": ['EH2 K S K AH0 M Y UW1 N AH0 K EY2 T AH0 D'],
  "excommunication": ['EH2 K S K AH0 M Y UW2 N AH0 K EY1 SH AH0 N'],
  "excoriate": ['EH0 K S K AO1 R IY0 EY2 T'],
  "excoriated": ['EH0 K S K AO1 R IY0 EY2 T IH0 D'],
  "excoriating": ['EH0 K S K AO1 R IY0 EY2 T IH0 NG'],
  "excoriation": ['EH0 K S K AO1 R IY0 EY2 SH AH0 N'],
  "excrement": ['EH1 K S K R AH0 M AH0 N T'],
  "excrete": ['IH0 K S K R IY1 T'],
  "excreted": ['IH0 K S K R IY1 T IH0 D'],
  "excretes": ['IH0 K S K R IY1 T Z'],
  "excretion": ['IH0 K S K R IY1 SH AH0 N'],
  "excretory": ['EH1 K S K R AH0 T AO2 R IY0'],
  "excruciating": ['IH0 K S K R UW1 SH IY0 EY2 T IH0 NG'],
  "excruciatingly": ['EH2 K S K R UW1 S IY0 EY2 T IH0 NG L IY0'],
  "exculpate": ['EH2 K S K AH1 L P EY0 T'],
  "exculpatory": ['EH2 K S K AH1 L P AH0 T AO2 R IY0'],
  "excursion": ['IH0 K S K ER1 ZH AH0 N'],
  "excursions": ['IH0 K S K ER1 ZH AH0 N Z'],
  "excusable": ['IH0 K S K Y UW1 Z AH0 B AH0 L'],
  "excuse": ['IH0 K S K Y UW1 S', 'IH0 K S K Y UW1 Z'],
  "excused": ['IH0 K S K Y UW1 Z D'],
  "excuses": ['IH0 K S K Y UW1 S IH0 Z', 'IH0 K S K Y UW1 Z IH0 Z'],
  "excusing": ['IH0 K S K Y UW1 Z IH0 NG'],
  "exec": ['EH2 G Z EH1 K'],
  "execrable": ['EH2 G Z EH1 K R AH0 B AH0 L'],
  "execs": ['EH2 G Z EH1 K S'],
  "execute": ['EH1 K S AH0 K Y UW2 T'],
  "executed": ['EH1 K S AH0 K Y UW2 T IH0 D'],
  "executes": ['EH1 K S AH0 K Y UW2 T S'],
  "executing": ['EH1 K S AH0 K Y UW2 T IH0 NG'],
  "execution": ['EH2 K S AH0 K Y UW1 SH AH0 N'],
  "executioner": ['EH2 K S AH0 K Y UW1 SH AH0 N ER0'],
  "executioner's": ['EH2 K S AH0 K Y UW1 SH AH0 N ER0 Z'],
  "executioners": ['EH2 K S AH0 K Y UW1 SH AH0 N ER0 Z'],
  "executions": ['EH2 K S AH0 K Y UW1 SH AH0 N Z'],
  "executive": ['IH0 G Z EH1 K Y AH0 T IH0 V'],
  "executive's": ['EH0 G Z EH1 K Y AH0 T IH0 V Z'],
  "executives": ['IH0 G Z EH1 K Y AH0 T IH0 V Z'],
  "executives'": ['EH0 G Z EH1 K Y AH0 T IH0 V Z'],
  "executone": ['EH2 G Z EH1 K Y UW0 T OW2 N'],
  "executor": ['IH0 G Z EH1 K Y AH0 T ER0'],
  "executors": ['IH0 G Z EH1 K Y AH0 T ER0 Z'],
  "exel": ['EH1 K S AH0 L'],
  "exemplar": ['IH0 G Z EH1 M P L AA0 R'],
  "exemplars": ['IH0 G Z EH1 M P L AA0 R Z'],
  "exemplary": ['IH0 G Z EH1 M P L ER0 IY0'],
  "exemplified": ['IH0 G Z EH1 M P L AH0 F AY2 D'],
  "exemplifies": ['IH0 G Z EH1 M P L AH0 F AY2 Z'],
  "exemplify": ['IH0 G Z EH1 M P L AH0 F AY2'],
  "exemplifying": ['IH0 G Z EH1 M P L AH0 F AY2 IH0 NG'],
  "exemplum": ['IH0 G Z EH1 M P L AH0 M'],
  "exempt": ['IH0 G Z EH1 M P T'],
  "exempted": ['IH0 G Z EH1 M P T IH0 D'],
  "exempting": ['IH0 G Z EH1 M P T IH0 NG'],
  "exemption": ['IH0 G Z EH1 M P SH AH0 N', 'IH0 G Z EH1 M SH AH0 N'],
  "exemptions": ['IH0 G Z EH1 M P SH AH0 N Z', 'IH0 G Z EH1 M SH AH0 N Z'],
  "exempts": ['IH0 G Z EH1 M P T S'],
  "exercisable": ['EH1 K S ER0 S AY2 Z AH0 B AH0 L'],
  "exercise": ['EH1 K S ER0 S AY2 Z'],
  "exercised": ['EH1 K S ER0 S AY2 Z D'],
  "exerciser": ['EH1 K S ER0 S AY2 Z ER0'],
  "exercisers": ['EH1 K S ER0 S AY2 Z ER0 Z'],
  "exercises": ['EH1 K S ER0 S AY2 Z AH0 Z', 'EH1 K S ER0 S AY2 Z IH0 Z'],
  "exercising": ['EH1 K S ER0 S AY2 Z IH0 NG'],
  "exert": ['IH0 G Z ER1 T'],
  "exerted": ['IH0 G Z ER1 T IH0 D'],
  "exerting": ['IH0 G Z ER1 T IH0 NG'],
  "exertion": ['IH0 G Z ER1 SH AH0 N'],
  "exertions": ['IH0 G Z ER1 SH AH0 N Z'],
  "exerts": ['IH0 G Z ER1 T S'],
  "exes": ['EH1 K S IH0 Z'],
  "exeter": ['EH1 K S IH0 T ER0'],
  "exfoliate": ['EH0 K S F OW1 L IY0 EY0 T'],
  "exfoliation": ['EH0 K S F OW2 L IY0 EY1 SH AH0 N'],
  "exhalation": ['EH2 K S HH AH0 L EY1 SH AH0 N'],
  "exhale": ['EH0 K S HH EY1 L'],
  "exhaled": ['EH0 K S HH EY1 L D'],
  "exhaust": ['IH0 G Z AO1 S T'],
  "exhausted": ['IH0 G Z AO1 S T IH0 D'],
  "exhausting": ['IH0 G Z AO1 S T IH0 NG'],
  "exhaustion": ['IH0 G Z AO1 S CH AH0 N'],
  "exhaustive": ['IH0 G Z AO1 S T IH0 V'],
  "exhaustively": ['IH0 G Z AA1 S T IH0 V L IY0'],
  "exhausts": ['IH0 G Z AO1 S T S'],
  "exhibit": ['IH0 G Z IH1 B IH0 T'],
  "exhibit's": ['IH0 G Z IH1 B AH0 T S'],
  "exhibited": ['IH0 G Z IH1 B AH0 T AH0 D'],
  "exhibiting": ['IH0 G Z IH1 B IH0 T IH0 NG'],
  "exhibition": ['EH2 K S AH0 B IH1 SH AH0 N'],
  "exhibitionist": ['EH2 K S AH0 B IH1 SH AH0 N IH0 S T'],
  "exhibitionists": ['EH2 K S AH0 B IH1 SH AH0 N IH0 S T S'],
  "exhibitions": ['EH2 K S AH0 B IH1 SH AH0 N Z'],
  "exhibitor": ['IH0 G Z IH1 B AH0 T ER0'],
  "exhibitors": ['IH0 G Z IH1 B AH0 T ER0 Z'],
  "exhibits": ['IH0 G Z IH1 B AH0 T S'],
  "exhilarate": ['IH0 G Z IH1 L ER0 EY2 T'],
  "exhilarated": ['IH0 G Z IH1 L ER0 EY2 T IH0 D'],
  "exhilarating": ['IH0 G Z IH1 L ER0 EY2 T IH0 NG'],
  "exhilaration": ['IH0 G Z IH2 L ER0 EY1 SH AH0 N'],
  "exhort": ['IH0 G Z AO1 R T'],
  "exhortation": ['EH2 G Z AO2 R T EY1 SH AH0 N'],
  "exhortations": ['EH2 G Z AO2 R T EY1 SH AH0 N Z'],
  "exhorted": ['IH0 G Z AO1 R T IH0 D'],
  "exhorting": ['IH0 G Z AO1 R T IH0 NG'],
  "exhorts": ['IH0 G Z AO1 R T S'],
  "exhumation": ['EH0 K S HH Y UW2 M EY1 SH AH0 N'],
  "exhume": ['EH0 K S HH Y UW1 M'],
  "exhumed": ['EH0 K S HH Y UW1 M D'],
  "exhumes": ['EH0 K S HH Y UW1 M Z'],
  "exide": ['EH1 K S AY2 D'],
  "exigencies": ['EH2 K S IH1 JH AH0 N S IY0 Z'],
  "exigency": ['EH2 K S IH1 JH AH0 N S IY0'],
  "exigent": ['EH1 K S IH0 JH AH0 N T'],
  "exigents": ['EH1 K S IH0 JH AH0 N T S'],
  "exile": ['EH1 G Z AY2 L', 'EH1 K S AY2 L'],
  "exiled": ['EH1 G Z AY2 L D', 'EH1 K S AY2 L D'],
  "exiles": ['EH1 G Z AY2 L Z', 'EH1 K S AY2 L Z'],
  "exim": ['EH1 K S IH0 M'],
  "exim's": ['EH1 K S IH0 M Z'],
  "exist": ['IH0 G Z IH1 S T'],
  "existed": ['IH0 G Z IH1 S T AH0 D'],
  "existence": ['EH0 G Z IH1 S T AH0 N S', 'IH0 G Z IH1 S T AH0 N S'],
  "existent": ['EH0 G Z IH1 S T AH0 N T'],
  "existential": ['EH2 G Z IH2 S T EH1 N SH AH0 L'],
  "existing": ['IH0 G Z IH1 S T IH0 NG'],
  "exists": ['IH0 G Z IH1 S T S'],
  "exit": ['EH1 G Z IH0 T', 'EH1 K S AH0 T'],
  "exited": ['EH1 G Z AH0 T IH0 D'],
  "exiting": ['EH1 G Z IH0 T IH0 NG'],
  "exits": ['EH1 G Z IH0 T S', 'EH1 K S AH0 T S'],
  "exler": ['EH1 K S L ER0'],
  "exley": ['EH1 K S L IY0'],
  "exline": ['EH1 K S L AY0 N'],
  "exner": ['EH1 K S N ER0'],
  "exocet": ['EH1 K S OW0 S EH2 T'],
  "exodus": ['EH1 K S AH0 D AH0 S'],
  "exogenous": ['EH2 K S OW1 JH AH0 N AH0 S'],
  "exon": ['EH2 K S AO1 N'],
  "exonerate": ['IH0 G Z AA1 N ER0 EY2 T'],
  "exonerated": ['IH0 G Z AA1 N ER0 EY2 T IH0 D'],
  "exonerates": ['IH0 G Z AA1 N ER0 EY2 T S'],
  "exonerating": ['IH0 G Z AA1 N ER0 EY2 T IH0 NG'],
  "exoneration": ['IH0 G Z AA0 N ER0 EY1 SH AH0 N'],
  "exorbitant": ['IH0 G Z AO1 R B IH0 T AH0 N T'],
  "exorcism": ['EH1 K S ER0 S IH2 Z AH0 M'],
  "exorcisms": ['EH1 K S ER0 S IH2 Z AH0 M Z'],
  "exorcist": ['EH1 K S ER0 S AH0 S T'],
  "exorcists": ['EH1 K S ER0 S IH0 S T S'],
  "exoskeleton": ['EH2 K S OW0 S K EH1 L AH0 T AH0 N'],
  "exothermic": ['EH2 K S OW0 TH ER1 M IH0 K'],
  "exotic": ['IH0 G Z AA1 T IH0 K'],
  "exotics": ['EH0 G Z AA1 T IH0 K S'],
  "exovir": ['EH2 K S OW0 V IH1 R'],
  "expand": ['IH0 K S P AE1 N D'],
  "expandable": ['IH0 K S P AE1 N D AH0 B AH0 L'],
  "expanded": ['IH0 K S P AE1 N D AH0 D', 'IH0 K S P AE1 N D IH0 D'],
  "expanding": ['IH0 K S P AE1 N D IH0 NG'],
  "expands": ['IH0 K S P AE1 N D Z'],
  "expanse": ['IH0 K S P AE1 N S'],
  "expanses": ['IH0 K S P AE1 N S IH0 Z'],
  "expansion": ['IH0 K S P AE1 N SH AH0 N', 'IH0 K S P AE1 N CH AH0 N'],
  "expansion's": ['IH0 K S P AE1 N SH AH0 N Z', 'IH0 K S P AE1 N CH AH0 N Z'],
  "expansionary": ['IH0 K S P AE1 N SH AH0 N EH2 R IY0', 'IH0 K S P AE1 N CH AH0 N EH2 R IY0'],
  "expansionism": ['IH0 K S P AE1 N SH AH0 N IH2 Z AH0 M', 'IH0 K S P AE1 N CH AH0 N IH2 Z AH0 M'],
  "expansionist": ['IH0 K S P AE1 N SH AH0 N IH0 S T', 'IH0 K S P AE1 N CH AH0 N IH0 S T'],
  "expansions": ['IH0 K S P AE1 N SH AH0 N Z', 'IH0 K S P AE1 N CH AH0 N Z'],
  "expansive": ['IH0 K S P AE1 N S IH0 V'],
  "expatriate": ['EH0 K S P EY1 T R IY0 EY2 T', 'EH0 K S P EY1 T R IY0 AH0 T'],
  "expatriates": ['EH0 K S P EY1 T R IY0 EY2 T S', 'EH0 K S P EY1 T R IY0 AH0 T S'],
  "expatriation": ['EH0 K S P EY2 T R IY0 EY1 SH AH0 N'],
  "expect": ['IH0 K S P EH1 K T'],
  "expectancies": ['IH0 K S P EH1 K T AH0 N S IY0 Z'],
  "expectancy": ['IH0 K S P EH1 K T AH0 N S IY0'],
  "expectant": ['IH0 K S P EH1 K T AH0 N T'],
  "expectation": ['EH2 K S P EH0 K T EY1 SH AH0 N'],
  "expectations": ['EH2 K S P EH0 K T EY1 SH AH0 N Z'],
  "expected": ['IH0 K S P EH1 K T AH0 D', 'IH0 K S P EH1 K T IH0 D'],
  "expecting": ['IH0 K S P EH1 K T IH0 NG'],
  "expectorant": ['IH0 K S P EH1 K T ER0 AH0 N T'],
  "expects": ['IH0 K S P EH1 K T S', 'IH0 K S P EH1 K S'],
  "expedia": ['EH0 K S P IY1 D IY0 AH0'],
  "expedience": ['IH0 K S P IY1 D IY0 AH0 N S'],
  "expediency": ['IH0 K S P IY1 D IY0 AH0 N S IY0'],
  "expedient": ['IH0 K S P IY1 D IY0 AH0 N T'],
  "expedite": ['EH1 K S P IH0 D AY2 T'],
  "expedited": ['EH1 K S P IH0 D AY2 T IH0 D'],
  "expediting": ['EH1 K S P AH0 D AY2 T IH0 NG'],
  "expedition": ['EH2 K S P AH0 D IH1 SH AH0 N'],
  "expeditionary": ['EH2 K S P AH0 D IH1 SH AH0 N EH2 R IY0'],
  "expeditions": ['EH2 K S P AH0 D IH1 SH AH0 N Z'],
  "expeditious": ['EH2 K S P AH0 D IH1 SH AH0 S'],
  "expeditiously": ['EH2 K S P AH0 D IH1 SH AH0 S L IY0'],
  "expel": ['IH0 K S P EH1 L'],
  "expelled": ['IH0 K S P EH1 L D'],
  "expelling": ['IH0 K S P EH1 L IH0 NG'],
  "expend": ['IH0 K S P EH1 N D'],
  "expendable": ['IH0 K S P EH1 N D AH0 B AH0 L'],
  "expended": ['IH0 K S P EH1 N D IH0 D'],
  "expending": ['EH2 K S P EH1 N D IH0 NG'],
  "expenditure": ['IH0 K S P EH1 N D AH0 CH ER0', 'IH0 K S P EH1 N D IH0 CH ER0'],
  "expenditures": ['IH0 K S P EH1 N D AH0 CH ER0 Z', 'IH0 K S P EH1 N D IH0 CH ER0 Z'],
  "expense": ['IH0 K S P EH1 N S'],
  "expenses": ['IH0 K S P EH1 N S AH0 Z', 'IH0 K S P EH1 N S IH0 Z'],
  "expensing": ['IH0 K S P EH1 N S IH0 NG'],
  "expensive": ['IH0 K S P EH1 N S IH0 V'],
  "expensively": ['EH2 K S P EH1 N S IH0 V L IY0'],
  "experience": ['IH0 K S P IH1 R IY0 AH0 N S'],
  "experienced": ['IH0 K S P IH1 R IY0 AH0 N S T'],
  "experiences": ['IH0 K S P IH1 R IY0 AH0 N S IH0 Z'],
  "experiencing": ['IH0 K S P IH1 R IY0 AH0 N S IH0 NG'],
  "experiential": ['EH0 K S P EH2 R IY0 EH1 N SH AH0 L'],
  "experiment": ['IH0 K S P EH1 R AH0 M AH0 N T'],
  "experiment's": ['IH0 K S P EH1 R AH0 M AH0 N T S'],
  "experimental": ['IH0 K S P EH2 R IH0 M EH1 N T AH0 L'],
  "experimentalist": ['IH0 K S P EH2 R AH0 M EH1 N T AH0 L IH0 S T'],
  "experimentally": ['IH0 K S P EH2 R AH0 M EH1 N T AH0 L IY0'],
  "experimentation": ['IH0 K S P EH2 R AH0 M AH0 N T EY1 SH AH0 N'],
  "experimented": ['IH0 K S P EH1 R AH0 M AH0 N T AH0 D'],
  "experimenter": ['IH0 K S P EH1 R AH0 M EH2 N T ER0'],
  "experimenters": ['IH0 K S P EH1 R AH0 M EH2 N T ER0 Z'],
  "experimenting": ['EH0 K S P EH1 R AH0 M EH2 N T IH0 NG'],
  "experiments": ['IH0 K S P EH1 R AH0 M AH0 N T S'],
  "expert": ['EH1 K S P ER0 T'],
  "expert's": ['EH1 K S P ER0 T S'],
  "expertise": ['EH2 K S P ER0 T IY1 Z'],
  "expertly": ['EH1 K S P ER0 T L IY0'],
  "experts": ['EH1 K S P ER0 T S'],
  "experts'": ['EH1 K S P ER0 T S'],
  "expiate": ['EH1 K S P IY0 EY2 T'],
  "expiration": ['EH2 K S P ER0 EY1 SH AH0 N'],
  "expirations": ['EH2 K S P ER0 EY1 SH AH0 N Z'],
  "expiratory": ['IH0 K S P AY1 R AH0 T AO2 R IY0'],
  "expire": ['IH0 K S P AY1 R'],
  "expired": ['IH0 K S P AY1 R D'],
  "expires": ['IH0 K S P AY1 ER0 Z'],
  "expiring": ['IH0 K S P AY1 R IH0 NG'],
  "expiry": ['EH2 K S P AY1 R IY0'],
  "explain": ['IH0 K S P L EY1 N'],
  "explainable": ['IH0 K S P L EY1 N AH0 B AH0 L'],
  "explained": ['IH0 K S P L EY1 N D'],
  "explaining": ['IH0 K S P L EY1 N IH0 NG'],
  "explains": ['IH0 K S P L EY1 N Z'],
  "explanation": ['EH2 K S P L AH0 N EY1 SH AH0 N'],
  "explanations": ['EH2 K S P L AH0 N EY1 SH AH0 N Z'],
  "explanatory": ['IH0 K S P L AE1 N AH0 T AO2 R IY0'],
  "expletive": ['EH1 K S P L AH0 T IH0 V'],
  "expletives": ['EH1 K S P L AH0 T IH0 V Z'],
  "explicable": ['EH0 K S P L IH1 K AH0 B AH0 L'],
  "explicate": ['EH1 K S P L AH0 K EY2 T'],
  "explication": ['EH2 K S P L AH0 K EY1 SH AH0 N'],
  "explicit": ['IH0 K S P L IH1 S AH0 T'],
  "explicite": ['IH0 K S P L IH1 S AH0 T'],
  "explicitly": ['IH0 K S P L IH1 S AH0 T L IY0'],
  "explode": ['IH0 K S P L OW1 D'],
  "exploded": ['IH0 K S P L OW1 D AH0 D', 'IH0 K S P L OW1 D IH0 D'],
  "explodes": ['IH0 K S P L OW1 D Z'],
  "exploding": ['IH0 K S P L OW1 D IH0 NG'],
  "exploit": ['EH1 K S P L OY2 T', 'EH2 K S P L OY1 T'],
  "exploitation": ['EH2 K S P L OY2 T EY1 SH AH0 N'],
  "exploitative": ['EH2 K S P L OY1 T AH0 T IH0 V'],
  "exploited": ['EH1 K S P L OY2 T AH0 D', 'IH0 K S P L OY1 T AH0 D'],
  "exploiting": ['EH1 K S P L OY2 T IH0 NG', 'IH0 K S P L OY1 T IH0 NG'],
  "exploitive": ['IH0 K S P L OY1 T IH0 V', 'EH0 K S P L OY1 T IH0 V'],
  "exploits": ['EH1 K S P L OY2 T S'],
  "exploration": ['EH2 K S P L ER0 EY1 SH AH0 N', 'EH2 K S P L AO0 R EY1 SH AH0 N'],
  "exploration's": ['EH2 K S P L ER0 EY1 SH AH0 N Z', 'EH1 K S P L AO0 R EY1 SH AH0 N Z'],
  "explorations": ['EH2 K S P L ER0 EY1 SH AH0 N Z', 'EH1 K S P L AO0 R EY1 SH AH0 N Z'],
  "exploratory": ['IH0 K S P L AO1 R AH0 T AO2 R IY0'],
  "exploravision": ['IH0 K S P L AO1 R AH0 V IH2 ZH AH0 N'],
  "explore": ['IH0 K S P L AO1 R'],
  "explored": ['IH0 K S P L AO1 R D'],
  "explorer": ['IH0 K S P L AO1 R ER0'],
  "explorers": ['IH0 K S P L AO1 R ER0 Z'],
  "explores": ['IH0 K S P L AO1 R Z'],
  "exploring": ['IH0 K S P L AO1 R IH0 NG'],
  "explosion": ['IH0 K S P L OW1 ZH AH0 N'],
  "explosions": ['IH0 K S P L OW1 ZH AH0 N Z'],
  "explosive": ['IH0 K S P L OW1 S IH0 V'],
  "explosively": ['EH2 K S P L OW1 S IH0 V L IY0'],
  "explosives": ['IH0 K S P L OW1 S IH0 V Z'],
  "explosivos": ['EH2 K S P L AH0 S IY1 V OW0 S'],
  "expo": ['EH1 K S P OW0'],
  "exponent": ['EH1 K S P OW2 N AH0 N T'],
  "exponential": ['EH2 K S P OW0 N EH1 N SH AH0 L'],
  "exponentially": ['EH2 K S P OW0 N EH1 N SH AH0 L IY0'],
  "exponents": ['IH0 K S P OW1 N AH0 N T S'],
  "export": ['EH1 K S P AO0 R T'],
  "exportable": ['EH0 K S P AO1 R T AH0 B AH0 L'],
  "exported": ['IH0 K S P AO1 R T AH0 D'],
  "exporter": ['IH0 K S P AO1 R T ER0'],
  "exporters": ['IH0 K S P AO1 R T ER0 Z'],
  "exporters'": ['EH2 K S P AO1 R T ER0 Z'],
  "exporting": ['IH0 K S P AO1 R T IH0 NG'],
  "exports": ['EH1 K S P AO0 R T S'],
  "expos": ['EH1 K S P OW0 Z'],
  "expose": ['IH0 K S P OW1 Z'],
  "exposed": ['IH0 K S P OW1 Z D'],
  "exposes": ['IH0 K S P OW1 Z IH0 Z'],
  "exposing": ['IH0 K S P OW1 Z IH0 NG'],
  "exposition": ['EH2 K S P AH0 Z IH1 SH AH0 N'],
  "expositions": ['EH2 K S P AH0 Z IH1 SH AH0 N Z'],
  "exposito": ['EH0 K S P AH0 S AY1 T OW0', 'EH0 K S P AH0 Z IY1 T OW0'],
  "exposure": ['IH0 K S P OW1 ZH ER0'],
  "exposures": ['IH0 K S P OW1 ZH ER0 Z'],
  "expound": ['IH0 K S P AW1 N D'],
  "expounded": ['IH0 K S P AW1 N D AH0 D'],
  "expounding": ['IH0 K S P AW1 N D IH0 NG'],
  "expounds": ['IH0 K S P AW1 N D Z'],
  "express": ['IH0 K S P R EH1 S'],
  "express'": ['IH0 K S P R EH1 S'],
  "express's": ['IH0 K S P R EH1 S IH0 Z'],
  "expressed": ['IH0 K S P R EH1 S T'],
  "expresses": ['IH0 K S P R EH1 S AH0 Z', 'IH0 K S P R EH1 S IH0 Z'],
  "expressing": ['IH0 K S P R EH1 S IH0 NG'],
  "expression": ['IH0 K S P R EH1 SH AH0 N'],
  "expressionism": ['IH0 K S P R EH1 SH AH0 N IH2 Z AH0 M'],
  "expressionist": ['IH0 K S P R EH1 SH AH0 N AH0 S T'],
  "expressionistic": ['IH0 K S P R EH2 SH AH0 N IH1 S T IH0 K'],
  "expressionless": ['IH0 K S P R EH2 SH AH0 N L IH0 S'],
  "expressions": ['IH0 K S P R EH1 SH AH0 N Z'],
  "expressive": ['IH0 K S P R EH1 S IH0 V'],
  "expressivity": ['EH2 K S P R AH0 S IH1 V IH0 T IY0'],
  "expressly": ['EH0 K S P R EH1 S L IY0'],
  "expresso": ['IH0 K S P EH1 S OW0', 'EH0 K S P EH1 S OW0'],
  "expressway": ['IH0 K S P R EH1 S W EY2'],
  "expropriate": ['EH0 K S P R OW1 P R IY0 EY2 T'],
  "expropriated": ['EH0 K S P R OW1 P R IY0 EY2 T IH0 D'],
  "expropriation": ['EH2 K S P R OW2 P R IY0 EY1 SH AH0 N'],
  "expropriations": ['EH2 K S P R OW2 P R IY0 EY1 SH AH0 N Z'],
  "expulsion": ['IH0 K S P AH1 L SH AH0 N'],
  "expulsions": ['IH0 K S P AH1 L SH AH0 N Z'],
  "expunge": ['IH0 K S P AH1 N JH'],
  "expunged": ['IH0 K S P AH1 N JH D'],
  "exquisite": ['EH1 K S K W AH0 Z AH0 T'],
  "exquisitely": ['EH2 K S K W IH1 Z IH0 T L IY0'],
  "extant": ['EH1 K S T AH0 N T'],
  "extel": ['EH1 K S T EH2 L'],
  "extend": ['IH0 K S T EH1 N D'],
  "extendable": ['EH2 K S T EH1 N D AH0 B AH0 L'],
  "extended": ['IH0 K S T EH1 N D AH0 D', 'IH0 K S T EH1 N D IH0 D'],
  "extender": ['EH1 K S T EH2 N D ER0'],
  "extenders": ['EH1 K S T EH2 N D ER0 Z'],
  "extendible": ['EH2 K S T EH1 N D IH0 B AH0 L'],
  "extending": ['IH0 K S T EH1 N D IH0 NG'],
  "extends": ['IH0 K S T EH1 N D Z'],
  "extension": ['IH0 K S T EH1 N SH AH0 N'],
  "extensions": ['IH0 K S T EH1 N SH AH0 N Z'],
  "extensive": ['IH0 K S T EH1 N S IH0 V'],
  "extensively": ['IH0 K S T EH1 N S IH0 V L IY0'],
  "extent": ['IH0 K S T EH1 N T'],
  "extenuate": ['IH0 K S T EH1 N Y UW0 EY2 T'],
  "extenuating": ['IH0 K S T EH1 N Y UW0 EY2 T IH0 NG'],
  "exterior": ['IH0 K S T IH1 R IY0 ER0'],
  "exteriors": ['EH0 K S T IH1 R IY0 ER0 Z'],
  "exterminate": ['IH0 K S T ER1 M AH0 N EY2 T'],
  "exterminated": ['IH0 K S T ER1 M AH0 N EY2 T IH0 D'],
  "exterminates": ['IH0 K S T ER1 M AH0 N EY2 T S'],
  "exterminating": ['IH0 K S T ER1 M AH0 N EY2 T IH0 NG'],
  "extermination": ['IH0 K S T ER2 M AH0 N EY1 SH AH0 N'],
  "exterminator": ['IH0 K S T ER1 M AH0 N EY2 T ER0'],
  "exterminators": ['IH0 K S T ER1 M AH0 N EY2 T ER0 Z'],
  "external": ['IH0 K S T ER1 N AH0 L'],
  "externally": ['IH0 K S T ER1 N AH0 L IY0'],
  "extinct": ['IH0 K S T IH1 NG K T'],
  "extinction": ['IH0 K S T IH1 NG K SH AH0 N', 'IH0 K S T IH1 NG SH AH0 N'],
  "extinctions": ['IH0 K S T IH1 NG K SH AH0 N Z', 'IH0 K S T IH1 NG SH AH0 N Z'],
  "extinguish": ['IH0 K S T IH1 NG G W IH0 SH'],
  "extinguished": ['IH0 K S T IH1 NG G W IH0 SH T'],
  "extinguisher": ['IH0 K S T IH1 NG G W IH0 SH ER0'],
  "extinguishers": ['IH0 K S T IH1 NG G W IH0 SH ER0 Z'],
  "extinguishing": ['IH0 K S T IH1 NG G W IH0 SH IH0 NG'],
  "extinguishment": ['IH0 K S T IH1 NG G W IH0 SH M AH0 N T'],
  "extirpate": ['EH1 K S T ER0 P EY2 T'],
  "extirpated": ['EH1 K S T ER0 P EY2 T AH0 D'],
  "extirpates": ['EH1 K S T ER0 P EY2 T S'],
  "extirpating": ['EH1 K S T ER0 P EY2 T IH0 NG'],
  "extol": ['IH0 K S T OW1 L'],
  "extolled": ['IH0 K S T OW1 L D'],
  "extolling": ['IH0 K S T OW1 L IH0 NG'],
  "extols": ['IH0 K S T OW1 L Z'],
  "exton": ['EH1 K S T AH0 N'],
  "extort": ['EH0 K S T AO1 R T'],
  "extorted": ['EH0 K S T AO1 R T IH0 D'],
  "extorting": ['EH0 K S T AO1 R T IH0 NG'],
  "extortion": ['EH0 K S T AO1 R SH AH0 N'],
  "extortionate": ['EH0 K S T AO1 R SH AH0 N AH0 T', 'EH0 K S T AO1 R SH AH0 N EY2 T'],
  "extortionist": ['EH0 K S T AO1 R SH AH0 N IH0 S T'],
  "extortionists": ['EH0 K S T AO1 R SH AH0 N IH0 S T S'],
  "extorts": ['EH0 K S T AO1 R T S'],
  "extra": ['EH1 K S T R AH0'],
  "extracellular": ['EH2 K S T R AH0 S EH1 L Y AH0 L ER0'],
  "extracellularly": ['EH2 K S T R AH0 S EH1 L Y AH0 L ER0 L IY0'],
  "extract": ['EH1 K S T R AE2 K T', 'IH0 K S T R AE1 K T'],
  "extracted": ['EH0 K S T R AE1 K T IH0 D'],
  "extracting": ['EH0 K S T R AE1 K T IH0 NG'],
  "extraction": ['EH0 K S T R AE1 K SH AH0 N'],
  "extractions": ['EH0 K S T R AE1 K SH AH0 N Z'],
  "extracts": ['EH1 K S T R AE2 K T S', 'IH0 K S T R AE1 K T S'],
  "extracurricular": ['EH2 K S T R AH0 K ER0 IH1 K Y AH0 L ER0'],
  "extradite": ['EH1 K S T R AH0 D AY2 T'],
  "extradited": ['EH1 K S T R AH0 D AY2 T IH0 D'],
  "extradites": ['EH1 K S T R AH0 D AY2 T S'],
  "extraditing": ['EH1 K S T R AH0 D AY2 T IH0 NG'],
  "extradition": ['EH2 K S T R AH0 D IH1 SH AH0 N'],
  "extragalactic": ['EH2 K S T R AH0 G AH0 L AE1 K T IH0 K'],
  "extralegal": ['EH2 K S T R AH0 L IY1 G AH0 L'],
  "extramarital": ['EH2 K S T R AH0 M EH1 R AH0 T AH0 L'],
  "extraneous": ['EH0 K S T R EY1 N IY0 AH0 S'],
  "extraneously": ['EH0 K S T R EY1 N IY0 AH0 S L IY0'],
  "extraordinaire": ['EH2 K S T R AH0 AO1 R D IH0 N EH2 R'],
  "extraordinarily": ['EH0 K S T R AO2 R D AH0 N EH1 R AH0 L IY0'],
  "extraordinary": ['EH2 K S T R AH0 AO1 R D AH0 N EH2 R IY0', 'IH0 K S T R AO1 R D AH0 N EH2 R IY0'],
  "extrapolate": ['EH0 K S T R AE1 P AH0 L EY2 T'],
  "extrapolated": ['EH0 K S T R AE1 P AH0 L EY2 T IH0 D'],
  "extrapolates": ['EH0 K S T R AE1 P AH0 L EY2 T S'],
  "extrapolating": ['EH0 K S T R AE1 P AH0 L EY2 T IH0 NG'],
  "extrapolation": ['EH0 K S T R AE2 P AH0 L EY1 SH AH0 N'],
  "extras": ['EH1 K S T R AH0 Z'],
  "extrasensory": ['EH2 K S T R AH0 S EH1 N S ER0 IY0'],
  "extraterrestrial": ['EH2 K S T R AH0 T ER0 EH1 S T R IY0 AH0 L'],
  "extraterrestrials": ['EH2 K S T R AH0 T ER2 EH1 S T R IY0 AH0 L Z'],
  "extraterritorial": ['EH2 K S T R AH0 T EH2 R IH0 T AO1 R IY0 AH0 L'],
  "extraterritoriality": ['EH2 K S T R AH0 T EH2 R AH0 T AO2 R IY0 AE1 L AH0 T IY0'],
  "extravagance": ['EH0 K S T R AE1 V AH0 G AH0 N S'],
  "extravagant": ['EH0 K S T R AE1 V AH0 G AH0 N T'],
  "extravagantly": ['EH2 K S T R AE1 V AH0 G AH0 N T L IY0'],
  "extravaganza": ['EH0 K S T R AE2 V AH0 G AE1 N Z AH0'],
  "extravaganzas": ['EH0 K S T R AE2 V AH0 G AE1 N Z AH0 Z'],
  "extravesated": ['EH1 K S T R AE2 V AH0 S EY1 T AH0 D'],
  "extreme": ['EH0 K S T R IY1 M'],
  "extremely": ['EH0 K S T R IY1 M L IY0'],
  "extremes": ['EH0 K S T R IY1 M Z'],
  "extremism": ['EH2 K S T R EH1 M IH0 Z AH0 M', 'EH2 K S T R IY1 M IH2 Z AH0 M'],
  "extremist": ['EH0 K S T R IY1 M IH0 S T'],
  "extremists": ['EH0 K S T R IY1 M AH0 S T S', 'EH0 K S T R IY1 M IH0 S T S'],
  "extremities": ['EH0 K S T R EH1 M AH0 T IY0 Z'],
  "extremity": ['EH0 K S T R EH1 M AH0 T IY0'],
  "extricable": ['EH0 K S T R IH1 K AH0 B AH0 L'],
  "extricate": ['EH1 K S T R AH0 K EY2 T'],
  "extricated": ['EH1 K S T R AH0 K EY2 T IH0 D'],
  "extricates": ['EH1 K S T R AH0 K EY2 T S'],
  "extricating": ['EH1 K S T R AH0 K EY2 T IH0 NG'],
  "extrication": ['EH2 K S T R AH0 K EY1 SH AH0 N'],
  "extrinsic": ['EH0 K S T R IH1 N S IH0 K'],
  "extrovert": ['EH1 K S T R AH0 V ER2 T'],
  "extroverted": ['EH1 K S T R AH0 V ER2 T IH0 D'],
  "extrude": ['IH0 K S T R UW1 D'],
  "extruded": ['IH0 K S T R UW1 D AH0 D'],
  "extrudes": ['IH0 K S T R UW1 D Z'],
  "extruding": ['IH0 K S T R UW1 D IH0 NG'],
  "extrusion": ['IH0 K S T R UW1 ZH AH0 N'],
  "exuberance": ['IH0 G Z UW1 B ER0 AH0 N S'],
  "exuberant": ['IH0 G Z UW1 B ER0 AH0 N T'],
  "exude": ['IH0 G Z UW1 D'],
  "exuded": ['IH0 G Z UW1 D IH0 D'],
  "exudes": ['IH0 G Z UW1 D Z'],
  "exuding": ['IH0 G Z UW1 D IH0 NG'],
  "exult": ['IH0 G Z AH1 L T'],
  "exultant": ['IH0 G Z AH1 L T AH0 N T'],
  "exultantly": ['IH0 G Z AH1 L T AH0 N T L IY0'],
  "exulted": ['IH0 G Z AH1 L T IH0 D'],
  "exulting": ['IH0 G Z AH1 L T IH0 NG'],
  "exults": ['IH0 G Z AH1 L T S'],
  "exum": ['IH0 G Z AH1 M'],
  "exxon": ['EH1 K S AA0 N'],
  "exxon's": ['EH1 K S AA0 N Z'],
  "eyde": ['EY1 D'],
  "eydie": ['EY1 D IY0'],
  "eye": ['AY1'],
  "eye's": ['AY1 Z'],
  "eyeball": ['AY1 B AO2 L'],
  "eyeballs": ['AY1 B AO2 L Z'],
  "eyebrow": ['AY1 B R AW2'],
  "eyebrows": ['AY1 B R AW2 Z'],
  "eyecare": ['AY1 K EH2 R'],
  "eyed": ['AY1 D'],
  "eyedrop": ['AY1 D R AA2 P'],
  "eyedropper": ['AY1 D R AA2 P ER0'],
  "eyedrops": ['AY1 D R AA2 P S'],
  "eyeglass": ['AY1 G L AE2 S'],
  "eyeglasses": ['AY1 G L AE2 S AH0 Z', 'AY1 G L AE2 S IH0 Z'],
  "eyeing": ['AY1 IH0 NG'],
  "eyelab": ['AY1 L AE2 B'],
  "eyelash": ['AY1 L AE2 SH'],
  "eyelashes": ['AY1 L AE2 SH IH0 Z'],
  "eyeless": ['AY1 L AH0 S'],
  "eyelet": ['AY1 L AH0 T'],
  "eyelets": ['AY1 L AH0 T S'],
  "eyelid": ['AY1 L IH2 D'],
  "eyelids": ['AY1 L IH2 D Z'],
  "eyelike": ['AY1 L AY2 K'],
  "eyeliner": ['AY1 L AY2 N ER0'],
  "eyepiece": ['AY1 P IY2 S'],
  "eyer": ['AY1 ER0'],
  "eyerly": ['IY1 ER0 L IY0'],
  "eyerman": ['IY1 ER0 M AH0 N'],
  "eyes": ['AY1 Z'],
  "eyes'": ['AY1 Z'],
  "eyeshade": ['AY1 SH EY2 D'],
  "eyeshadow": ['AY1 SH AE1 D OW0'],
  "eyesight": ['AY1 S AY2 T'],
  "eyesore": ['AY1 S AO2 R'],
  "eyespot": ['AY1 S P AA2 T'],
  "eyestone": ['AY1 S T OW2 N'],
  "eyestrain": ['AY1 S T R EY2 N'],
  "eyetech": ['AY1 T EH2 K'],
  "eyetech's": ['AY1 T EH2 K S'],
  "eyewear": ['AY1 W EH2 R'],
  "eyewitness": ['AY1 W IH1 T N AH0 S'],
  "eyewitnesses": ['AY1 W IH2 T N AH0 S IH0 Z'],
  "eyler": ['EY1 L ER0'],
  "eyman": ['EY1 M AH0 N'],
  "eynon": ['EY1 N AH0 N'],
  "eyre": ['EH1 R'],
  "eyrich": ['EH1 R IH0 CH'],
  "eyrie": ['EH1 R IY0'],
  "eyring": ['EY1 R IH0 NG'],
  "eyster": ['EY1 S T ER0'],
  "eyton": ['EY1 T AH0 N'],
  "ezekiel": ['EH1 Z IH0 K IY2 L'],
  "ezell": ['AH0 Z EH1 L'],
  "ezell's": ['AH0 Z EH1 L Z'],
  "ezelle": ['IH0 Z EH1 L'],
  "ezer": ['EH1 Z ER0'],
  "ezoe": ['EH1 Z OW0'],
  "ezola": ['EY2 Z OW1 L AH0'],
  "ezra": ['EH1 Z R AH0'],
  "ezrachi": ['EH0 Z R AA1 CH IY2'],
  "ezzell": ['EH1 Z AH0 L'],
  "ezzo": ['EH1 Z OW0'],
  "f": ['EH1 F'],
  "f'd": ['EH1 F D'],
  "f's": ['EH1 F S'],
  "f.": ['EH1 F'],
  "f.'s": ['EH1 F S'],
  "faal": ['F AA1 L'],
  "faal's": ['F AA1 L Z'],
  "faas": ['F AA1 Z'],
  "fab": ['F AE1 B'],
  "fabbri": ['F AE1 B R IY0'],
  "fabel": ['F AE1 B AH0 L'],
  "fabela": ['F AA0 B EH1 L AH0'],
  "faber": ['F EY1 B ER0'],
  "faberge": ['F AE1 B ER0 JH', 'F AE2 B ER0 JH EY1'],
  "faberman": ['F EY1 B ER0 M AH0 N'],
  "fabia": ['F AA1 B IY0 AH0'],
  "fabian": ['F EY1 B IY0 AH0 N'],
  "fabiani": ['F AA0 B IY0 AA1 N IY0'],
  "fabiano": ['F AA0 B IY0 AA1 N OW0'],
  "fabien": ['F AE1 B IY0 N'],
  "fabio": ['F AA1 B IY0 OW0'],
  "fable": ['F EY1 B AH0 L'],
  "fabled": ['F EY1 B AH0 L D'],
  "fables": ['F EY1 B AH0 L Z'],
  "fabre": ['F EY1 B ER0'],
  "fabri": ['F AE1 B R IY0'],
  "fabric": ['F AE1 B R IH0 K'],
  "fabricant": ['F AE1 B R IH0 K AH0 N T'],
  "fabricate": ['F AE1 B R AH0 K EY2 T'],
  "fabricated": ['F AE1 B R IH0 K EY2 T AH0 D', 'F AE1 B R IH0 K EY2 T IH0 D'],
  "fabricates": ['F AE1 B R IH0 K EY2 T S'],
  "fabricating": ['F AE1 B R IH0 K EY2 T IH0 NG'],
  "fabrication": ['F AE2 B R IH0 K EY1 SH AH0 N'],
  "fabrications": ['F AE2 B R IH0 K EY1 SH AH0 N Z'],
  "fabricator": ['F AE1 B R IH0 K EY2 T ER0'],
  "fabricators": ['F AE1 B R IH0 K EY2 T ER0 Z'],
  "fabrics": ['F AE1 B R IH0 K S'],
  "fabris": ['F AE1 B R IH0 S'],
  "fabrizi": ['F AA0 B R IY1 Z IY0'],
  "fabrizio": ['F AA0 B R IY1 T S IY0 OW0'],
  "fabrizius": ['F AE2 B R IY1 Z IY0 AH0 S'],
  "fabron": ['F AE1 B R AH0 N'],
  "fabry": ['F AE1 B R IY0'],
  "fabulous": ['F AE1 B Y AH0 L AH0 S'],
  "fabulously": ['F AE1 B Y UW0 L AH0 S L IY0'],
  "fac": ['F AE1 K'],
  "facade": ['F AH0 S AA1 D'],
  "facades": ['F AH0 S AA1 D Z'],
  "facchini": ['F AA0 K IY1 N IY0'],
  "facciola": ['F AE2 CH IY0 OW1 L AH0'],
  "face": ['F EY1 S'],
  "face-to-face": ['F EY1 S T UW0 F EY1 S'],
  "facebook": ['F EY1 S B UH2 K'],
  "facebook's": ['F EY1 S B UH2 K S'],
  "faced": ['F EY1 S T'],
  "facedown": ['F EY1 S D AW1 N'],
  "faceless": ['F EY1 S L AH0 S'],
  "facelift": ['F EY1 S L IH2 F T'],
  "facemire": ['F AA0 CH EH0 M IH1 R IY0'],
  "facer": ['F EY1 S ER0'],
  "faces": ['F EY1 S AH0 Z', 'F EY1 S IH0 Z'],
  "facet": ['F AE1 S AH0 T'],
  "facet's": ['F AE1 S AH0 T S'],
  "faceted": ['F AE1 S AH0 T IH0 D'],
  "facetious": ['F AH0 S IY1 SH AH0 S'],
  "facetiously": ['F AH0 S IY1 SH AH0 S L IY0'],
  "facets": ['F AE1 S AH0 T S'],
  "facey": ['F EY1 S IY0'],
  "fachet": ['F AE1 CH AH0 T'],
  "facial": ['F EY1 SH AH0 L'],
  "facials": ['F EY1 SH AH0 L Z'],
  "faciane": ['F AA0 S IY0 AA1 N EY0'],
  "facie": ['F EY1 S IY0'],
  "facile": ['F AE1 S AH0 L'],
  "facilitate": ['F AH0 S IH1 L AH0 T EY2 T'],
  "facilitated": ['F AH0 S IH1 L AH0 T EY2 T IH0 D'],
  "facilitates": ['F AH0 S IH1 L AH0 T EY2 T S'],
  "facilitating": ['F AH0 S IH1 L AH0 T EY2 T IH0 NG'],
  "facilitation": ['F AH0 S IH2 L AH0 T EY1 SH AH0 N'],
  "facilitator": ['F AH0 S IH1 L AH0 T EY2 T ER0'],
  "facilitator's": ['F AH0 S IH1 L AH0 T EY2 T ER0 Z'],
  "facilitators": ['F AH0 S IH1 L AH0 T EY2 T ER0 Z'],
  "facilities": ['F AH0 S IH1 L AH0 T IY0 Z', 'F AH0 S IH1 L IH0 T IY0 Z'],
  "facility": ['F AH0 S IH1 L IH0 T IY0'],
  "facility's": ['F AH0 S IH1 L IH0 T IY0 Z'],
  "facing": ['F EY1 S IH0 NG'],
  "facings": ['F EY1 S IH0 NG Z'],
  "fackler": ['F AE1 K L ER0'],
  "fackrell": ['F AE1 K R AH0 L'],
  "facsimile": ['F AE0 K S IH1 M AH0 L IY0'],
  "facsimiles": ['F AE0 K S IH1 M AH0 L IY0 Z'],
  "fact": ['F AE1 K T'],
  "facteau": ['F AH0 K T OW1'],
  "faction": ['F AE1 K SH AH0 N'],
  "factional": ['F AE1 K SH AH0 N AH0 L'],
  "factionalism": ['F AE1 K SH AH0 N AH0 L IH2 Z AH0 M'],
  "factions": ['F AE1 K SH AH0 N Z'],
  "factitious": ['F AE0 K T IH1 SH AH0 S'],
  "factly": ['F AE1 K T L IY0'],
  "facto": ['F AE1 K T OW0'],
  "factoid": ['F AE1 K T OY2 D'],
  "factor": ['F AE1 K T ER0'],
  "factored": ['F AE1 K T ER0 D'],
  "factories": ['F AE1 K T ER0 IY0 Z'],
  "factoring": ['F AE1 K T ER0 IH0 NG'],
  "factors": ['F AE1 K T ER0 Z'],
  "factors'": ['F AE1 K T ER0 Z'],
  "factory": ['F AE1 K T ER0 IY0'],
  "factory's": ['F AE1 K T ER0 IY0 Z'],
  "facts": ['F AE1 K T S', 'F AE1 K S'],
  "factual": ['F AE1 K CH UW0 AH0 L'],
  "factually": ['F AE1 K CH UW0 AH0 L IY0'],
  "facultative": ['F AE1 K AH0 L T EY2 T IH0 V'],
  "faculties": ['F AE1 K AH0 L T IY0 Z'],
  "faculty": ['F AE1 K AH0 L T IY0'],
  "fad": ['F AE1 D'],
  "fadden": ['F AE1 D AH0 N'],
  "faddis": ['F AE1 D IH0 S'],
  "faddish": ['F AE1 D IH0 SH'],
  "fade": ['F EY1 D'],
  "faded": ['F EY1 D AH0 D', 'F EY1 D IH0 D'],
  "fadel": ['F AE1 D AH0 L'],
  "fadeley": ['F AE1 D IH0 L IY0'],
  "fadely": ['F EY1 D L IY0'],
  "faden": ['F EY1 D AH0 N'],
  "fader": ['F EY1 D ER0'],
  "fades": ['F EY1 D Z'],
  "fadiman": ['F AE1 D AH0 M AH0 N'],
  "fading": ['F EY1 D IH0 NG'],
  "fadlallah": ['F AE2 D L AE1 L AH0'],
  "fadness": ['F AE1 D N AH0 S'],
  "fads": ['F AE1 D Z'],
  "fae": ['F AY1'],
  "faerber": ['F EH1 R B ER0'],
  "faeth": ['F IY1 TH'],
  "fag": ['F AE1 G'],
  "fagan": ['F EY1 G AH0 N'],
  "fagen": ['F AE1 G AH0 N'],
  "fager": ['F EY1 G ER0'],
  "fagerberg": ['F EY1 G ER0 B ER0 G'],
  "fagerstrom": ['F EY1 G ER0 S T R AH0 M'],
  "fagg": ['F AE1 G'],
  "faggart": ['F AE1 G AA0 R T'],
  "faggot": ['F AE1 G AH0 T'],
  "fagin": ['F EY1 G IH0 N'],
  "fagley": ['F AE1 G L IY0'],
  "fagnant": ['F AE1 G N AH0 N T'],
  "fagoth": ['F AE1 G AH0 TH'],
  "fags": ['F AE1 G Z'],
  "fagundes": ['F AE1 G AH0 N D Z'],
  "fahd": ['F AA1 D'],
  "faherty": ['F AE1 HH ER0 T IY0'],
  "fahey": ['F AE1 HH IY0'],
  "fahl": ['F AA1 L'],
  "fahlman": ['F AA1 L M AH0 N'],
  "fahmy": ['F AA1 M IY0'],
  "fahnestock": ['F AA1 N S T AA2 K'],
  "fahr": ['F AA1 R'],
  "fahrenheit": ['F EH1 R AH0 N HH AY2 T'],
  "fahrenheit's": ['F EH1 R AH0 N HH AY2 T S'],
  "fahrenkopf": ['F AA1 R AH0 N K AA2 P F'],
  "fahrer": ['F AA1 R ER0'],
  "fahringer": ['F AA1 R IH0 NG ER0'],
  "fahrner": ['F AA1 R N ER0'],
  "fahrney": ['F AA1 R N IY0'],
  "fahs": ['F AE1 S'],
  "fahy": ['F EY1 HH IY0'],
  "faidley": ['F EY1 D L IY0'],
  "faiella": ['F AY2 EH1 L AH0'],
  "fail": ['F EY1 L'],
  "fail-safe": ['F EY0 L S AE1 F'],
  "faile": ['F EY1 L'],
  "failed": ['F EY1 L D'],
  "failing": ['F EY1 L IH0 NG'],
  "failings": ['F EY1 L IH0 NG Z'],
  "failla": ['F EY1 L AH0'],
  "failor": ['F EY1 L ER0'],
  "fails": ['F EY1 L Z'],
  "failsafe": ['F EY1 L S EY2 F'],
  "failure": ['F EY1 L Y ER0'],
  "failures": ['F EY1 L Y ER0 Z'],
  "fain": ['F EY1 N'],
  "faint": ['F EY1 N T'],
  "fainted": ['F EY1 N T IH0 D'],
  "fainter": ['F EY1 N T ER0'],
  "faintest": ['F EY1 N T AH0 S T'],
  "fainthearted": ['F EY1 N T HH AA1 R T IH0 D'],
  "fainting": ['F EY1 N T IH0 NG'],
  "faintly": ['F EY1 N T L IY0'],
  "faintness": ['F EY1 N T N AH0 S'],
  "faiola": ['F AY1 OW0 L AH0'],
  "fair": ['F EH1 R'],
  "fair's": ['F EH1 R Z'],
  "fair-lawn": ['F EH1 R L AO2 N'],
  "fairall": ['F EH0 R AO1 L'],
  "fairbairn": ['F EH1 R B ER0 N'],
  "fairbank": ['F EH1 R B AH0 NG K'],
  "fairbanks": ['F EH1 R B AH0 NG K S'],
  "fairbrother": ['F EH1 R B R AH0 DH ER0'],
  "fairburn": ['F EH1 R B ER2 N'],
  "fairchild": ['F EH1 R CH AY2 L D'],
  "fairchild's": ['F EH1 R CH AY2 L D Z'],
  "faircloth": ['F EH1 R K L AH0 TH'],
  "fairclough": ['F EH1 R K L AW0'],
  "faire": ['F EH1 R'],
  "fairer": ['F EH1 R ER0'],
  "faires": ['F EH1 R Z'],
  "fairest": ['F EH1 R IH0 S T'],
  "fairey": ['F EH1 R IY0'],
  "fairfax": ['F EH1 R F AE2 K S'],
  "fairfield": ['F EH1 R F IY2 L D'],
  "fairground": ['F EH1 R G R AW2 N D'],
  "fairgrounds": ['F EH1 R G R AW2 N D Z'],
  "fairhaven": ['F EH1 R HH EY2 V AH0 N'],
  "fairhurst": ['F AY1 R HH ER0 S T'],
  "fairies": ['F EH1 R IY0 Z'],
  "fairlawn": ['F EH1 R L AO2 N'],
  "fairleigh": ['F EH1 R L AH0'],
  "fairless": ['F EH1 R L IH0 S'],
  "fairley": ['F EH1 R L IY0'],
  "fairlie": ['F EH1 R L IY0'],
  "fairlow": ['F EH1 R L OW0'],
  "fairly": ['F EH1 R L IY0'],
  "fairman": ['F AY1 R M AH0 N'],
  "fairmont": ['F EH1 R M AA2 N T'],
  "fairness": ['F EH1 R N AH0 S'],
  "fairs": ['F EH1 R Z'],
  "fairview": ['F EH1 R V Y UW2'],
  "fairway": ['F EH1 R W EY2'],
  "fairways": ['F EH1 R W EY2 Z'],
  "fairweather": ['F EH1 R W EH2 DH ER0'],
  "fairy": ['F EH1 R IY0'],
  "fairyland": ['F EH1 R IY0 L AE2 N D'],
  "fairytale": ['F EH1 R IY0 T EY2 L'],
  "fairytales": ['F EH1 R IY0 T EY2 L Z'],
  "faisal": ['F EY1 Z AH0 L'],
  "faison": ['F EY1 Z AH0 N'],
  "faist": ['F AA1 IH0 S T'],
  "fait": ['F EY1 T'],
  "faith": ['F EY1 TH'],
  "faithful": ['F EY1 TH F AH0 L'],
  "faithfully": ['F EY1 TH F AH0 L IY0'],
  "faithfulness": ['F EY1 TH F AH0 L N EH2 S'],
  "faithfuls": ['F EY1 TH F AH0 L Z'],
  "faiths": ['F EY1 TH S'],
  "faivre": ['F EY1 V ER0'],
  "fajardo": ['F AA0 Y AA1 R D OW0'],
  "fajita": ['F AH0 JH IY1 T AH0', 'F AH0 HH IY1 T AH0'],
  "fajitas": ['F AH0 JH IY1 T AH0 Z', 'F AH0 HH IY1 T AH0 Z'],
  "fake": ['F EY1 K'],
  "faked": ['F EY1 K T'],
  "faker": ['F EY1 K ER0'],
  "fakers": ['F EY1 K ER0 Z'],
  "fakes": ['F EY1 K S'],
  "faking": ['F EY1 K IH0 NG'],
  "falafel": ['F AH0 L AA1 F AH0 L'],
  "falanga": ['F AA0 L AA1 NG G AH0'],
  "falani": ['F AH0 L AA1 N IY0', 'F AH0 L AE1 N IY0'],
  "falardeau": ['F AE1 L AA0 R D OW2'],
  "falasco": ['F AA0 L AA1 S K OW0'],
  "falb": ['F AO1 L B'],
  "falbo": ['F AE1 L B OW0'],
  "falcigno": ['F AE2 L S IH1 G N OW0', 'F EH2 L S IY1 N Y OW0'],
  "falck": ['F AE1 L K'],
  "falco": ['F AE1 L K OW0'],
  "falcoff": ['F AE1 L K AO0 F'],
  "falcon": ['F AE1 L K AH0 N'],
  "falconbridge": ['F AE1 L K AH0 N B R IH2 JH'],
  "falconbridge's": ['F AE1 L K AH0 N B R IH2 JH IH0 Z'],
  "falcone": ['F AA0 L K OW1 N IY0'],
  "falconer": ['F AE1 L K AH0 N ER0'],
  "falconers": ['F AE1 L K AH0 N ER0 Z'],
  "falconet": ['F AE2 L K AH0 N EH1 T'],
  "falconets": ['F AE2 L K AH0 N EH1 T S'],
  "falconi": ['F AA0 L K OW1 N IY0'],
  "falconry": ['F AE1 L K AH0 N R IY0'],
  "falcons": ['F AE1 L K AH0 N Z'],
  "faldo": ['F AA1 L D OW0'],
  "faler": ['F EY1 L ER0'],
  "fales": ['F EY1 L Z'],
  "faley": ['F EY1 L IY0'],
  "falgoust": ['F AA1 L G UW0 S T'],
  "falgout": ['F AE1 L G AW0 T'],
  "falik": ['F AA1 L IH0 K'],
  "falin": ['F AE1 L IH0 N'],
  "falise": ['F AH0 L IY1 S'],
  "falk": ['F AO1 K'],
  "falke": ['F AO1 K'],
  "falkenberg": ['F AO1 K AH0 N B ER0 G'],
  "falkenstein": ['F AO1 K AH0 N S T AY0 N', 'F AO1 K AH0 N S T IY0 N'],
  "falkland": ['F AO1 K L AH0 N D'],
  "falklands": ['F AO1 K L AH0 N D Z'],
  "falkner": ['F AO1 K N ER0'],
  "falkowski": ['F AH0 L K AO1 F S K IY0'],
  "fall": ['F AO1 L', 'F AA1 L'],
  "fall's": ['F AO1 L Z'],
  "falla": ['F AE1 L AH0'],
  "fallacies": ['F AE1 L AH0 S IY0 Z'],
  "fallacious": ['F AH0 L EY1 SH AH0 S'],
  "fallaciously": ['F AH0 L EY1 SH AH0 S L IY0'],
  "fallacy": ['F AE1 L AH0 S IY0'],
  "fallaw": ['F AE1 L AO2'],
  "fallback": ['F AO1 L B AE2 K'],
  "fallen": ['F AA1 L AH0 N'],
  "faller": ['F AO1 L ER0'],
  "fallert": ['F AE1 L ER0 T'],
  "fallibility": ['F AE2 L IH0 B IH1 L IH0 T IY0'],
  "fallible": ['F AE1 L AH0 B AH0 L'],
  "fallin": ['F AE1 L IH0 N'],
  "falling": ['F AA1 L IH0 NG'],
  "fallis": ['F AE1 L IH0 S'],
  "fallish": ['F AA1 L IH0 SH'],
  "falloff": ['F AO1 L AO2 F'],
  "fallon": ['F AE1 L AH0 N'],
  "fallopian": ['F AH0 L OW1 P IY0 AH0 N'],
  "fallout": ['F AO1 L AW2 T'],
  "fallouts": ['F AO1 L AW2 T S'],
  "fallow": ['F AE1 L OW2'],
  "fallows": ['F AE1 L OW2 Z'],
  "falls": ['F AO1 L Z'],
  "falmouth": ['F AE1 L M AH0 TH'],
  "falor": ['F AE1 L ER0'],
  "falotico": ['F AA0 L OW0 T IY1 K OW0'],
  "false": ['F AO1 L S'],
  "falsehood": ['F AE1 L S HH UH2 D'],
  "falsehoods": ['F AE1 L S HH UH2 D Z'],
  "falsely": ['F AO1 L S L IY0'],
  "falsetto": ['F AO0 L S EH1 T OW2'],
  "falsettos": ['F AO0 L S EH1 T OW2 Z'],
  "falsification": ['F AE2 L S AH0 F AH0 K EY1 SH AH0 N'],
  "falsified": ['F AO1 L S AH0 F AY2 D'],
  "falsify": ['F AO1 L S AH0 F AY2'],
  "falsifying": ['F AO1 L S AH0 F AY2 IH0 NG'],
  "falsity": ['F AO1 L S AH0 T IY0'],
  "falsone": ['F AO1 L S AH0 N'],
  "falstaff": ['F AO1 L S T AE2 F'],
  "falstaff's": ['F AO1 L S T AE2 F S'],
  "falter": ['F AO1 L T ER0'],
  "faltered": ['F AA1 L T ER0 D'],
  "faltering": ['F AO1 L T ER0 IH0 NG'],
  "falters": ['F AO1 L T ER0 Z'],
  "falvey": ['F AE1 L V IY0'],
  "falvo": ['F AA1 L V OW0'],
  "falwell": ['F AO1 L W EH2 L'],
  "falwell's": ['F AO1 L W EH2 L Z'],
  "falynn": ['F AE1 L IH0 N'],
  "falzarano": ['F AA0 L Z AA0 R AA1 N OW0'],
  "falzon": ['F AA1 L Z AH0 N'],
  "falzone": ['F AA0 L Z OW1 N IY0'],
  "fama": ['F AA1 M AH0'],
  "fambro": ['F AE1 M B R OW0'],
  "fambrough": ['F AE1 M B R AW0'],
  "fame": ['F EY1 M'],
  "famed": ['F EY1 M D'],
  "famer": ['F EY1 M ER0'],
  "famers": ['F EY1 M ER0 Z'],
  "famiglietti": ['F AH0 M IH2 G L IY0 EH1 T IY0'],
  "familial": ['F AH0 M IH1 L Y AH0 L'],
  "familiar": ['F AH0 M IH1 L Y ER0'],
  "familiarity": ['F AH0 M IH2 L Y EH1 R AH0 T IY0'],
  "familiarize": ['F AH0 M IH1 L Y ER0 AY2 Z'],
  "familiarized": ['F AH0 M IH1 L Y ER0 AY2 Z D'],
  "families": ['F AE1 M AH0 L IY0 Z', 'F AE1 M L IY0 Z'],
  "families'": ['F AE1 M AH0 L IY0 Z'],
  "family": ['F AE1 M AH0 L IY0', 'F AE1 M L IY0'],
  "family's": ['F AE1 M AH0 L IY0 Z', 'F AE1 M L IY0 Z'],
  "famine": ['F AE1 M AH0 N'],
  "famines": ['F AE1 M AH0 N Z'],
  "famished": ['F AE1 M IH0 SH T'],
  "famous": ['F EY1 M AH0 S'],
  "famously": ['F EY1 M AH0 S L IY0'],
  "famularo": ['F AA0 M UW0 L AA1 R OW0'],
  "fan": ['F AE1 N'],
  "fan's": ['F AE1 N Z'],
  "fanara": ['F AA0 N AA1 R AH0'],
  "fanatic": ['F AH0 N AE1 T IH0 K'],
  "fanatical": ['F AH0 N AE1 T IH0 K AH0 L'],
  "fanatically": ['F AH0 N AE1 T IH0 K AH0 L IY0', 'F AH0 N AE1 T IH0 K L IY0'],
  "fanaticism": ['F AH0 N AE1 T AH0 S IH2 Z AH0 M'],
  "fanatics": ['F AH0 N AE1 T IH0 K S'],
  "fancher": ['F AE1 N CH ER0'],
  "fanchon": ['F AE1 N CH AH0 N'],
  "fancied": ['F AE1 N S IY0 D'],
  "fancier": ['F AE1 N S IY0 ER0'],
  "fanciers": ['F AE1 N S IY0 ER0 Z'],
  "fancies": ['F AE1 N S IY0 Z'],
  "fanciest": ['F AE1 N S IY0 AH0 S T'],
  "fanciful": ['F AE1 N S IH0 F AH0 L'],
  "fancy": ['F AE1 N S IY0'],
  "fandango": ['F AE0 N D AE1 NG G OW2'],
  "fandel": ['F AE1 N D AH0 L'],
  "fandrich": ['F AE1 N D R IH0 K'],
  "fane": ['F EY1 N'],
  "fanech": ['F AE1 N EH2 K'],
  "fanech's": ['F AE1 N EH2 K S'],
  "fanelli": ['F AH0 N EH1 L IY0'],
  "faneuil": ['F AE1 N Y UW0 IH0 L'],
  "fanfare": ['F AE1 N F EH2 R'],
  "fang": ['F AE1 NG'],
  "fanger": ['F AE1 NG ER0'],
  "fangle": ['F AE1 NG G AH0 L'],
  "fangled": ['F AE1 NG G AH0 L D'],
  "fangman": ['F AE1 NG M AH0 N'],
  "fangs": ['F AE1 NG Z'],
  "fanguy": ['F AH0 NG G W IY1'],
  "fankhauser": ['F AE1 NG K HH AW2 Z ER0'],
  "fanlike": ['F AE1 N L AY2 K'],
  "fann": ['F AE1 N'],
  "fanned": ['F AE1 N D'],
  "fannie": ['F AE1 N IY0'],
  "fannin": ['F AE1 N IH0 N'],
  "fanning": ['F AE1 N IH0 NG'],
  "fanning's": ['F AE1 N IH0 NG Z'],
  "fannon": ['F AE1 N AH0 N'],
  "fanny": ['F AE1 N IY0'],
  "fans": ['F AE1 N Z'],
  "fans'": ['F AE1 N Z'],
  "fansler": ['F AE1 N S AH0 L ER0', 'F AE1 N Z L ER0'],
  "fant": ['F AE1 N T'],
  "fanta": ['F AA1 N T AH0'],
  "fantail": ['F AE1 N T EY2 L'],
  "fantasia": ['F AE0 N T EY1 ZH AH0'],
  "fantasies": ['F AE1 N T AH0 S IY0 Z', 'F AE1 N AH0 S IY0 Z'],
  "fantasize": ['F AE1 N T AH0 S AY2 Z', 'F AE1 N AH0 S AY2 Z'],
  "fantasized": ['F AE1 N T IH0 S AY2 Z D', 'F AE1 N IH0 S AY2 Z D'],
  "fantasizes": ['F AE1 N T AH0 S AY2 Z IH0 Z', 'F AE1 N AH0 S AY2 Z IH0 Z'],
  "fantasizing": ['F AE1 N T AH0 S AY2 Z IH0 NG', 'F AE1 N AH0 S AY2 Z IH0 NG'],
  "fantastic": ['F AE0 N T AE1 S T IH0 K'],
  "fantastically": ['F AE0 N T AE1 S T IH0 K L IY0'],
  "fantastik": ['F AE2 N T AE1 S T IH0 K'],
  "fantasy": ['F AE1 N T AH0 S IY0', 'F AE1 N AH0 S IY0'],
  "fantasyland": ['F AE1 N T AH0 S IY0 L AE2 N D', 'F AE1 N AH0 S IY0 L AE2 N D'],
  "fantini": ['F AA0 N T IY1 N IY0'],
  "fantle": ['F AE1 N T AH0 L'],
  "fanton": ['F AE1 N T AH0 N'],
  "fantozzi": ['F AA0 N T OW1 T S IY0'],
  "fantroy": ['F AE1 N T R OY2'],
  "fanuc": ['F AE1 N AH0 K'],
  "fanucchi": ['F AA0 N UW1 K IY0'],
  "fanueil": ['F AE1 N Y AH0 L'],
  "fao": ['F AW1'],
  "far": ['F AA1 R'],
  "far's": ['F AA1 R Z'],
  "farabaugh": ['F AE1 R AH0 B AO2'],
  "farabee": ['F AE1 R AH0 B IY0'],
  "farabundo": ['F EH2 R AH0 B UW1 N D OW2'],
  "farace": ['F AA0 R AA1 CH IY0'],
  "faraci": ['F AA0 R AA1 CH IY0'],
  "faraday": ['F AE1 R AH0 D EY2'],
  "faraday's": ['F AE1 R AH0 D EY2 Z'],
  "faragher": ['F AE1 R AH0 G ER0'],
  "farago": ['F AA0 R AA1 G OW0'],
  "farah": ['F EH1 R AH0'],
  "faraha": ['F AA1 R AH0 HH AA0'],
  "farahkan": ['F AE1 R AH0 K AE2 N'],
  "farai": ['F EH1 R EY0', 'F AA1 R EY0', 'F AA1 R AY0'],
  "faranda": ['F ER0 AE1 N D AH0'],
  "faraone": ['F AA0 R AA0 OW1 N IY0'],
  "faraway": ['F AA1 R AH0 W EY1'],
  "farb": ['F AA1 R B'],
  "farben": ['F AA1 R B AH0 N'],
  "farber": ['F AA1 R B ER0'],
  "farberware": ['F AA1 R B ER0 W EH2 R'],
  "farbman": ['F AA1 R B M AH0 N'],
  "farce": ['F AA1 R S'],
  "farcical": ['F AA1 R S AH0 K AH0 L', 'F AA1 R S IH0 K AH0 L'],
  "fare": ['F EH1 R'],
  "fared": ['F EH1 R D'],
  "fares": ['F EH1 R Z'],
  "farese": ['F AA0 R EY1 Z IY0'],
  "faretlow": ['F AH0 R EH1 T L OW0'],
  "farewell": ['F EH2 R W EH1 L'],
  "farewells": ['F EH2 R W EH1 L Z'],
  "farfen": ['F AA1 R F AH0 N'],
  "farfetched": ['F AA1 R F EH1 CH T'],
  "farge": ['F AA1 R JH'],
  "fargnoli": ['F AA0 R G N OW1 L IY0'],
  "fargo": ['F AA1 R G OW2'],
  "fargo's": ['F AA1 R G OW2 Z'],
  "farha": ['F AA1 R HH AH0'],
  "farhat": ['F AA1 R HH AE2 T'],
  "faria": ['F AA1 R IY0 AH0'],
  "farias": ['F EH1 R IY0 AH0 Z'],
  "faribault": ['F AE1 R AH0 B AA2 L T'],
  "farica": ['F AA0 R IY1 K AH0'],
  "faridoun": ['F AA1 R IH0 D UW0 N'],
  "fariello": ['F AA0 R IY0 EH1 L OW0'],
  "faries": ['F EH0 R IY1 Z'],
  "farina": ['F ER0 IY1 N AH0'],
  "farinacci": ['F AA0 R IY0 N AA1 CH IY0'],
  "farinas": ['F ER0 IY1 N AH0 Z'],
  "farinella": ['F AA0 R IY0 N EH1 L AH0'],
  "faring": ['F EH1 R IY0 NG'],
  "farino": ['F AA0 R IY1 N OW0'],
  "faris": ['F EH1 R IH0 S'],
  "farish": ['F EH1 R IH0 SH'],
  "fariss": ['F ER0 IH1 S'],
  "farkas": ['F AA1 R K AH0 S'],
  "farland": ['F AA1 R L AH0 N D'],
  "farlee": ['F AA1 R L IY2'],
  "farler": ['F AA1 R L ER0'],
  "farless": ['F AA1 R L AH0 S'],
  "farley": ['F AA1 R L IY0'],
  "farling": ['F AA1 R L IH0 NG'],
  "farlow": ['F AA1 R L OW2'],
  "farly": ['F AA1 R L IY0'],
  "farm": ['F AA1 R M'],
  "farm's": ['F AA1 R M Z'],
  "farman": ['F AA1 R M AH0 N'],
  "farmboy": ['F AA1 R M B OY2'],
  "farmed": ['F AA1 R M D'],
  "farmer": ['F AA1 R M ER0'],
  "farmer's": ['F AA1 R M ER0 Z'],
  "farmers": ['F AA1 R M ER0 Z'],
  "farmers'": ['F AA1 R M ER0 Z'],
  "farmers's": ['F AA1 R M ER0 Z IH0 Z'],
  "farmhand": ['F AA1 R M HH AE2 N D'],
  "farmhands": ['F AA1 R M HH AE2 N D Z'],
  "farmhouse": ['F AA1 R M HH AW2 S'],
  "farmhouses": ['F AA1 R M HH AW2 S AH0 Z', 'F AA1 R M HH AW2 S IH0 Z'],
  "farming": ['F AA1 R M IH0 NG'],
  "farming's": ['F AA1 R M IH0 NG Z'],
  "farmingdale": ['F AA1 R M IH0 NG D EY2 L'],
  "farmington": ['F AA1 R M IH0 NG T AH0 N'],
  "farmland": ['F AA1 R M L AE2 N D'],
  "farmlands": ['F AA1 R M L AE2 N D Z'],
  "farms": ['F AA1 R M Z'],
  "farms'": ['F AA1 R M Z'],
  "farmstead": ['F AA1 R M S T EH2 D'],
  "farmworker": ['F AA1 R M W ER2 K ER0'],
  "farmworkers": ['F AA1 R M W ER2 K ER0 Z'],
  "farmyard": ['F AA1 R M Y AA2 R D'],
  "farnall": ['F AA1 R N AH0 L'],
  "farnam": ['F AA1 R N AH0 M'],
  "farnan": ['F AA1 R N AH0 N'],
  "farnborough": ['F AA1 R N B ER0 OW0'],
  "farnell": ['F AA1 R N AH0 L'],
  "farner": ['F AA1 R N ER0'],
  "farnes": ['F AA1 R N Z'],
  "farney": ['F AA1 R N IY0'],
  "farnham": ['F AA1 R N AH0 M'],
  "farnley": ['F AA1 R N L IY0'],
  "farnsworth": ['F AA1 R N S W ER0 TH'],
  "farnum": ['F AA1 R N AH0 M'],
  "farnworth": ['F AA1 R N W ER0 TH'],
  "faro": ['F EH1 R OW0'],
  "farold": ['F AE1 R OW0 L D'],
  "faron": ['F EH1 R AH0 N'],
  "farone": ['F ER0 OW1 N'],
  "farouk": ['F AH0 R UW1 K'],
  "farquar": ['F AA0 R K W AA1 R'],
  "farquhar": ['F AA1 R K W ER0'],
  "farquharson": ['F AA0 R K AH1 HH AA0 R S AH0 N'],
  "farr": ['F AA1 R'],
  "farra": ['F AA1 R AH0'],
  "farragut": ['F EH1 R AH0 G AH0 T'],
  "farrah": ['F EH1 R AH0'],
  "farrakhan": ['F AA1 R AH0 K AA2 N'],
  "farrakhan's": ['F AA1 R AH0 K AA2 N Z'],
  "farran": ['F AE1 R AH0 N'],
  "farrand": ['F AE1 R AH0 N D'],
  "farrant": ['F AA1 R AH0 N T'],
  "farrar": ['F ER0 AA1 R'],
  "farrel": ['F EH1 R AH0 L'],
  "farrell": ['F EH1 R IH0 L'],
  "farrell's": ['F EH1 R IH0 L Z'],
  "farrelly": ['F EH1 R AH0 L IY0'],
  "farren": ['F EH1 R AH0 N'],
  "farrens": ['F AE1 R AH0 N Z'],
  "farrer": ['F AA1 R ER0'],
  "farrey": ['F AE1 R IY0'],
  "farrier": ['F EH1 R IY0 ER0'],
  "farrington": ['F EH1 R IH0 NG T AH0 N'],
  "farrior": ['F AA0 R IY1 ER0'],
  "farris": ['F EH1 R IH0 S'],
  "farrish": ['F AE1 R IH0 SH'],
  "farro": ['F AA1 R OW0'],
  "farron": ['F EH1 R AH0 N'],
  "farrow": ['F EH1 R OW2'],
  "farrow's": ['F EH1 R OW2 Z'],
  "farrowing": ['F AE1 R OW2 IH0 NG'],
  "farruggia": ['F AA0 R UW1 G JH AH0'],
  "farrugia": ['F AA0 R UW1 JH AH0'],
  "farry": ['F AE1 R IY0'],
  "farsi": ['F AA1 R S IY0'],
  "farsighted": ['F AA1 R S AY1 T AH0 D', 'F AA1 R S AY2 T IH0 D'],
  "farsightedness": ['F AA2 R S AY1 T IH0 D N AH0 S'],
  "farson": ['F AA1 R S AH0 N'],
  "fart": ['F AA1 R T'],
  "farther": ['F AA1 R DH ER0'],
  "farthest": ['F AA1 R DH AH0 S T'],
  "farthing": ['F AA1 R DH IH0 NG'],
  "farthingale": ['F AA1 R DH IH0 NG G EY2 L'],
  "farting": ['F AA1 R T IH0 NG'],
  "farve": ['F AA1 R V'],
  "farver": ['F AA1 R V ER0'],
  "farwell": ['F AA1 R W EH2 L'],
  "farwest": ['F AA2 R W EH1 S T'],
  "fasano": ['F AA0 S AA1 N OW0'],
  "fasbender": ['F AE1 S B EH2 N D ER0'],
  "fascell": ['F AH0 S EH1 L'],
  "fasching": ['F AE1 SH IH0 NG'],
  "fasciano": ['F AA0 S CH IY0 AA1 N OW0'],
  "fascinate": ['F AE1 S AH0 N EY2 T'],
  "fascinated": ['F AE1 S AH0 N EY2 T AH0 D', 'F AE1 S AH0 N EY2 T IH0 D'],
  "fascinates": ['F AE1 S AH0 N EY2 T S'],
  "fascinating": ['F AE1 S AH0 N EY2 T IH0 NG'],
  "fascination": ['F AE2 S AH0 N EY1 SH AH0 N'],
  "fascism": ['F AE1 SH IH2 Z AH0 M'],
  "fascist": ['F AE1 SH IH0 S T'],
  "fascists": ['F AE1 SH IH0 S T S'],
  "fashion": ['F AE1 SH AH0 N'],
  "fashion's": ['F AE1 SH AH0 N Z'],
  "fashionable": ['F AE1 SH AH0 N AH0 B AH0 L', 'F AE1 SH N AH0 B AH0 L'],
  "fashionably": ['F AE1 SH AH0 N AH0 B L IY0'],
  "fashioned": ['F AE1 SH AH0 N D'],
  "fashioning": ['F AE1 SH AH0 N IH0 NG'],
  "fashions": ['F AE1 SH AH0 N Z'],
  "fasick": ['F AE1 S IH0 K'],
  "fasig": ['F AE1 S IH0 G'],
  "fasnacht": ['F AE1 S N AH0 K T'],
  "faso": ['F AA1 S OW0'],
  "faso's": ['F AA1 S OW0 Z'],
  "fason": ['F AE1 S AH0 N'],
  "fasone": ['F AA0 S OW1 N IY0'],
  "fass": ['F AE1 S'],
  "fassbender": ['F AE1 S B EH2 N D ER0'],
  "fassett": ['F AE1 S IH0 T'],
  "fassler": ['F AE1 S L ER0'],
  "fassnacht": ['F AE1 S N AH0 K T'],
  "fassulo": ['F AH0 S UW1 L OW0'],
  "fast": ['F AE1 S T'],
  "fast-forward": ['F AE2 S T F AO1 R W ER0 D'],
  "fast-paced": ['F AE2 S T P EY1 S T'],
  "fastball": ['F AE1 S T B AO2 L'],
  "fastballs": ['F AE1 S T B AO2 L Z'],
  "fasted": ['F AE1 S T IH0 D'],
  "fasten": ['F AE1 S AH0 N'],
  "fastened": ['F AE1 S AH0 N D'],
  "fastener": ['F AE1 S AH0 N ER0', 'F AE1 S N ER0'],
  "fasteners": ['F AE1 S AH0 N ER0 Z', 'F AE1 S N ER0 Z'],
  "fastening": ['F AE1 S AH0 N IH0 NG', 'F AE1 S N IH0 NG'],
  "fastenings": ['F AE1 S AH0 N IH0 NG Z', 'F AE1 S N IH0 NG Z'],
  "faster": ['F AE1 S T ER0'],
  "fastest": ['F AE1 S T AH0 S T'],
  "fastfood": ['F AE1 S T F UW2 D'],
  "fastidious": ['F AE0 S T IH1 D IY0 AH0 S'],
  "fasting": ['F AE1 S T IH0 NG'],
  "fastness": ['F AE1 S T N AH0 S'],
  "fasts": ['F AE1 S T S'],
  "fasulo": ['F AH0 S UW1 L OW0'],
  "fat": ['F AE1 T'],
  "fata": ['F AA1 T AH0'],
  "fatah": ['F AA1 T AH0', 'F AH0 T AA1'],
  "fatal": ['F EY1 T AH0 L'],
  "fatalism": ['F EY1 T AH0 L IH2 Z AH0 M'],
  "fatalist": ['F EY1 T AH0 L IH0 S T'],
  "fatalistic": ['F EY0 T AH0 L IH1 S T IH0 K'],
  "fatalists": ['F EY1 T AH0 L IH0 S T S'],
  "fatalities": ['F AH0 T AE1 L IH0 T IY0 Z'],
  "fatality": ['F AH0 T AE1 L IH0 T IY0'],
  "fatally": ['F EY1 T AH0 L IY0'],
  "fate": ['F EY1 T'],
  "fated": ['F EY1 T IH0 D'],
  "fateful": ['F EY1 T F AH0 L'],
  "fateh": ['F AA1 T EH0'],
  "fates": ['F EY1 T S'],
  "fath": ['F AE1 TH'],
  "father": ['F AA1 DH ER0'],
  "father's": ['F AA1 DH ER0 Z'],
  "father-in-law": ['F AA1 DH ER0 IH0 N L AO2'],
  "fathered": ['F AA1 DH ER0 D'],
  "fatheree": ['F AE0 TH ER0 IY1'],
  "fatherhood": ['F AA1 DH ER0 HH UH2 D'],
  "fathering": ['F AA1 DH ER0 IH0 NG'],
  "fatherland": ['F AA1 DH ER0 L AE2 N D'],
  "fatherless": ['F AA1 DH ER0 L AH0 S'],
  "fatherly": ['F AA1 DH ER0 L IY0'],
  "fathers": ['F AA1 DH ER0 Z'],
  "fathers'": ['F AE1 TH ER0 Z'],
  "fathi": ['F AE1 TH IY0'],
  "fathom": ['F AE1 DH AH0 M'],
  "fathomable": ['F AE1 DH AH0 M AH0 B AH0 L'],
  "fathoms": ['F AE1 DH AH0 M Z'],
  "fatigue": ['F AH0 T IY1 G'],
  "fatigued": ['F AH0 T IY1 G D'],
  "fatigues": ['F AH0 T IY1 G Z'],
  "fatiguing": ['F AH0 T IY1 G IH0 NG'],
  "fatima": ['F AE1 TH IH0 M AH0', 'F AE1 T IH0 M AH0'],
  "fatimah": ['F AE1 TH IH0 M AH0'],
  "fats": ['F AE1 T S'],
  "fatten": ['F AE1 T AH0 N'],
  "fattened": ['F AE1 T AH0 N D'],
  "fattening": ['F AE1 T AH0 N IH0 NG', 'F AE1 T N IH0 NG'],
  "fatter": ['F AE1 T ER0'],
  "fattest": ['F AE1 T AH0 S T'],
  "fatties": ['F AE1 T IY0 Z'],
  "fatty": ['F AE1 T IY0'],
  "fatula": ['F AA0 T UW1 L AH0'],
  "fatuous": ['F AE1 CH AH0 W AH0 S'],
  "fatwa": ['F AA1 T W AA0'],
  "fatzinger": ['F EY1 T Z IH0 NG ER0'],
  "faubel": ['F AW1 B AH0 L'],
  "fauber": ['F AW1 B ER0'],
  "faubert": ['F AW1 B ER0 T'],
  "faubion": ['F AW1 B IY0 AH0 N'],
  "fauble": ['F AO1 B AH0 L'],
  "faubus": ['F AO1 B AH0 S'],
  "faucet": ['F AO1 S AH0 T'],
  "faucets": ['F AO1 S AH0 T S'],
  "faucett": ['F AO1 S IH0 T'],
  "faucette": ['F OW0 S EH1 T'],
  "faucher": ['F AW1 K ER0'],
  "faucheux": ['F OW0 SH OW1'],
  "fauci": ['F AO1 S IY0'],
  "faughn": ['F AO1 N'],
  "faughnan": ['F AO1 N AH0 N'],
  "faught": ['F AO1 T'],
  "faught's": ['F AO1 T S'],
  "faul": ['F AO1 L'],
  "faulcon": ['F AO1 L K AH0 N'],
  "faulconer": ['F AO1 L K AH0 N ER0'],
  "faulding": ['F AO1 L D IH0 NG'],
  "faulds": ['F AO1 L D Z'],
  "faulhaber": ['F AW1 L HH AH0 B ER0'],
  "faulk": ['F AO1 K'],
  "faulkenberry": ['F AO1 L K AH0 N B EH2 R IY0'],
  "faulkner": ['F AO1 K N ER0'],
  "faulkner's": ['F AO1 K N ER0 Z'],
  "faulks": ['F AO1 K S'],
  "faull": ['F AO1 L'],
  "faulstich": ['F AO1 L S T IH0 CH'],
  "fault": ['F AO1 L T'],
  "faulted": ['F AO1 L T IH0 D'],
  "faulting": ['F AO1 L T IH0 NG'],
  "faults": ['F AO1 L T S'],
  "faulty": ['F AO1 L T IY0'],
  "fauna": ['F AO1 N AH0'],
  "faunal": ['F AA1 N AH0 L', 'F AO1 N AH0 L'],
  "faunce": ['F AO1 N S'],
  "fauntleroy": ['F AO1 N T L ER0 OY2'],
  "fauntroy": ['F AO1 N T R OY2'],
  "faupel": ['F OW0 P EH1 L'],
  "faure": ['F AO1 R'],
  "faurot": ['F AO0 R OW1'],
  "fauroux": ['F AO0 R UW1'],
  "faus": ['F AO1 Z'],
  "fauser": ['F AW1 S ER0'],
  "fausett": ['F AO1 S IH0 T'],
  "fausey": ['F AO1 S IY0'],
  "fausnaugh": ['F AO1 S N AO0'],
  "fauss": ['F AO1 S'],
  "faust": ['F AW1 S T'],
  "fausta": ['F AO1 S T AH0'],
  "faustian": ['F AO1 S CH AH0 N'],
  "faustina": ['F AO2 S T IY1 N AH0'],
  "faustine": ['F AW1 S T IY0 N'],
  "faustino": ['F AO2 S T IY1 N OW0'],
  "fausto": ['F AO1 S T OW0'],
  "faustus": ['F AO1 S T AH0 S'],
  "fauteux": ['F OW0 T OW1'],
  "fauth": ['F AO1 TH'],
  "fauver": ['F AW1 V ER0'],
  "faux": ['F AO1 K S'],
  "fava": ['F AA1 V AH0'],
  "favale": ['F AA0 V AA1 L IY0'],
  "favaloro": ['F AA0 V AA0 L AO1 R OW0'],
  "favaro": ['F AA0 V AA1 R OW0'],
  "favata": ['F AA0 V AA1 T AH0'],
  "favazza": ['F AA0 V AA1 T S AH0'],
  "fave": ['F EY1 V'],
  "favela": ['F AA0 V EH1 L AH0'],
  "faver": ['F EY1 V ER0'],
  "favero": ['F AA0 V EH1 R OW0'],
  "favia": ['F AA1 V IY0 AH0'],
  "favinger": ['F EY1 V IH0 NG ER0'],
  "favor": ['F EY1 V ER0'],
  "favorability": ['F AE2 V ER0 AH0 B IH1 L IH0 T IY0'],
  "favorable": ['F EY1 V ER0 AH0 B AH0 L', 'F EY1 V R AH0 B AH0 L'],
  "favorably": ['F EY1 V ER0 AH0 B L IY0', 'F EY1 V R AH0 B L IY0'],
  "favored": ['F EY1 V ER0 D'],
  "favoring": ['F EY1 V ER0 IH0 NG'],
  "favorite": ['F EY1 V ER0 IH0 T', 'F EY1 V R AH0 T'],
  "favorites": ['F EY1 V ER0 IH0 T S', 'F EY1 V R AH0 T S'],
  "favoritism": ['F EY1 V ER0 IH0 T IH2 Z AH0 M'],
  "favors": ['F EY1 V ER0 Z'],
  "favour": ['F EY1 V ER0'],
  "favourite": ['F EY1 V ER0 IH0 T'],
  "favours": ['F EY1 V ER0 Z'],
  "favre": ['F EY1 V ER0'],
  "favreau": ['F AH0 V R OW1'],
  "favro": ['F AE1 V R OW0'],
  "faw": ['F AO1'],
  "fawbush": ['F AO1 B UH0 SH'],
  "fawcett": ['F AO1 S IH0 T'],
  "fawkes": ['F AO1 K S'],
  "fawley": ['F AO1 L IY0'],
  "fawn": ['F AO1 N'],
  "fawning": ['F AO1 N IH0 NG'],
  "fawver": ['F AO1 V ER0'],
  "fax": ['F AE1 K S'],
  "faxed": ['F AE1 K S T'],
  "faxer": ['F AE1 K S ER0'],
  "faxes": ['F AE1 K S IH0 Z'],
  "faxing": ['F AE1 K S IH0 NG'],
  "faxon": ['F AE1 K S AH0 N'],
  "fay": ['F EY1'],
  "fay's": ['F EY1 Z'],
  "fayanjuu": ['F AY1 AH0 N JH UW0'],
  "fayanne": ['F EY1 IH0 N', 'F EY2 AE1 N'],
  "fayard": ['F AH0 Y AA1 R D'],
  "faye": ['F EY1'],
  "faye's": ['F EY1 Z'],
  "fayed": ['F EY1 D'],
  "fayed's": ['F EY1 D Z'],
  "fayette": ['F EY1 EH1 T'],
  "fayetteville": ['F EY1 EH2 T V IH2 L'],
  "fayez": ['F EY1 EH0 Z'],
  "fayme": ['F EY1 M'],
  "faymonville": ['F EY1 M AH0 N V IH2 L'],
  "fayne": ['F EY1 N'],
  "fayrouz": ['F EY1 R UW2 Z'],
  "faz": ['F AE1 Z'],
  "faze": ['F EY1 Z'],
  "fazed": ['F EY1 Z D'],
  "fazekas": ['F AE1 Z IH0 K AH0 Z'],
  "fazenbaker": ['F EY1 Z AH0 N B EY2 K ER0'],
  "fazio": ['F EY1 Z IY0 OW0', 'F AA1 Z IY0 OW0'],
  "fazzino": ['F AA0 T S IY1 N OW0'],
  "fazzio": ['F AE1 Z IY0 OW0'],
  "fbi": ['EH1 F B IY1 AY1'],
  "fbi's": ['EH1 F B IY1 AY1 Z'],
  "fcc": ['EH2 F S IY2 S IY1'],
  "fcc's": ['EH2 F S IY2 S IY1 Z'],
  "fda": ['EH2 F D IY2 EY1'],
  "fe": ['F EY1'],
  "fe's": ['F EY1 Z'],
  "feagan": ['F EY1 G AH0 N'],
  "feagans": ['F IY1 G AH0 N Z'],
  "feagin": ['F IY1 JH IH0 N'],
  "feagins": ['F IY1 JH IH0 N Z'],
  "feagle": ['F IY1 G AH0 L'],
  "fealty": ['F IY1 AH0 L T IY0', 'F IY1 L T IY0'],
  "fear": ['F IH1 R'],
  "feared": ['F IH1 R D'],
  "fearful": ['F IH1 R F AH0 L'],
  "fearing": ['F IH1 R IH0 NG'],
  "fearless": ['F IH1 R L AH0 S'],
  "fearn": ['F ER1 N'],
  "fearnow": ['F ER1 N OW0'],
  "fearon": ['F IH1 R AH0 N'],
  "fears": ['F IH1 R Z'],
  "fearsome": ['F IH1 R S AH0 M'],
  "feasel": ['F IY1 Z AH0 L'],
  "feasibility": ['F IY2 Z AH0 B IH1 L AH0 T IY0'],
  "feasible": ['F IY1 Z AH0 B AH0 L'],
  "feasibly": ['F IY1 Z AH0 B L IY0'],
  "feast": ['F IY1 S T'],
  "feasted": ['F IY1 S T IH0 D'],
  "feaster": ['F IY1 S T ER0'],
  "feasting": ['F IY1 S T IH0 NG'],
  "feasts": ['F IY1 S T S'],
  "feat": ['F IY1 T'],
  "feather": ['F EH1 DH ER0'],
  "featherbed": ['F EH1 DH ER0 B EH2 D'],
  "featherbedding": ['F EH1 DH ER0 B EH2 D IH0 NG'],
  "feathered": ['F EH1 DH ER0 D'],
  "feathering": ['F EH1 DH ER0 IH0 NG', 'F EH1 DH R IH0 NG'],
  "featherless": ['F EH1 DH ER0 L AH0 S'],
  "featherly": ['F EH1 DH ER0 L IY0'],
  "feathers": ['F EH1 DH ER0 Z'],
  "featherston": ['F EH1 DH ER0 S T AH0 N'],
  "featherstone": ['F EH1 DH ER0 S T OW2 N'],
  "featherweight": ['F EH1 DH ER0 W EY2 T'],
  "feathery": ['F EH1 DH ER0 IY0', 'F EH1 DH R IY2'],
  "feats": ['F IY1 T S'],
  "feature": ['F IY1 CH ER0'],
  "featured": ['F IY1 CH ER0 D'],
  "featureless": ['F IY1 CH ER0 L AH0 S'],
  "features": ['F IY1 CH ER0 Z'],
  "featuring": ['F IY1 CH ER0 IH0 NG'],
  "feazel": ['F IY1 Z AH0 L'],
  "feazell": ['F IY1 Z AH0 L'],
  "feb": ['F EH1 B Y AH0 W EH2 R IY0'],
  "febles": ['F EH1 B AH0 L Z'],
  "febres": ['F EH1 B R AH0 S'],
  "february": ['F EH1 B Y AH0 W EH2 R IY0', 'F EH1 B R UW0 EH2 R IY0'],
  "february's": ['F EH1 B Y AH0 W EH2 R IY0 Z', 'F EH1 B R UW0 EH2 R IY0 Z'],
  "fecal": ['F IY1 K AH0 L'],
  "feces": ['F IY1 S IY2 Z'],
  "fecher": ['F EH1 K ER0'],
  "fechner": ['F EH1 K N ER0'],
  "fecht": ['F EH1 K T'],
  "fechter": ['F EH1 K T ER0'],
  "feck": ['F EH1 K'],
  "feckless": ['F EH1 K L IH0 S'],
  "fecteau": ['F IH0 K T OW1'],
  "fecundity": ['F AH0 K AH1 N D IH0 T IY0'],
  "fed": ['F EH1 D'],
  "fed's": ['F EH1 D Z'],
  "fedak": ['F EH1 D AH0 K'],
  "fedder": ['F EH1 D ER0'],
  "fedders": ['F EH1 D ER0 Z'],
  "fedders's": ['F EH1 D ER0 Z IH0 Z'],
  "feddersen": ['F EH1 D ER0 S AH0 N'],
  "fede": ['F IY1 D'],
  "fedeccredito": ['F EH0 D EH2 K R EH0 D IY1 T OW0'],
  "fedele": ['F EH1 D AH0 L'],
  "fedeli": ['F EH0 D EH1 L IY0'],
  "feder": ['F EH1 D ER0'],
  "federal": ['F EH1 D ER0 AH0 L', 'F EH1 D R AH0 L'],
  "federal's": ['F EH1 D ER0 AH0 L Z', 'F EH1 D R AH0 L Z'],
  "federalism": ['F EH1 D ER0 AH0 L IH2 Z AH0 M', 'F EH1 D R AH0 L IH2 Z AH0 M'],
  "federalist": ['F EH1 D ER0 AH0 L IH0 S T', 'F EH1 D R AH0 L AH0 S T'],
  "federalists": ['F EH1 D ER0 AH0 L IH0 S T S', 'F EH1 D ER0 AH0 L IH0 S', 'F EH1 D R AH0 L IH0 S T S', 'F EH1 D R AH0 L IH0 S'],
  "federalize": ['F EH1 D ER0 AH0 L AY2 Z', 'F EH1 D R AH0 L AY2 Z'],
  "federalized": ['F EH1 D ER0 AH0 L AY2 Z D', 'F EH1 D R AH0 L AY2 Z D'],
  "federalizing": ['F EH1 D ER0 AH0 L AY2 Z IH0 NG', 'F EH1 D R AH0 L AY2 Z IH0 NG'],
  "federally": ['F EH1 D ER0 AH0 L IY0', 'F EH1 D R AH0 L IY0'],
  "federals": ['F EH1 D ER0 AH0 L Z', 'F EH1 D R AH0 L Z'],
  "federate": ['F EH1 D ER0 EY2 T'],
  "federated": ['F EH1 D ER0 EY2 T IH0 D'],
  "federated's": ['F EH1 D ER0 EY2 T IH0 D Z'],
  "federation": ['F EH2 D ER0 EY1 SH AH0 N'],
  "federation's": ['F EH2 D ER0 EY1 SH AH0 N Z'],
  "federations": ['F EH2 D ER0 EY1 SH AH0 N Z'],
  "federative": ['F EH1 D ER0 AH0 T IH0 V', 'F EH1 D R AH0 T IH0 V'],
  "federbush": ['F EH1 D ER0 B UH2 SH'],
  "federer": ['F EH1 D ER0 ER0'],
  "federica": ['F EH0 D ER0 IY1 K AH0'],
  "federici": ['F EH0 D ER0 IY1 CH IY0'],
  "federico": ['F EH0 D ER0 IY1 K OW0'],
  "federle": ['F EH1 D ER0 AH0 L'],
  "federman": ['F IY1 D ER0 M AH0 N'],
  "federoff": ['F EH1 D ER0 AO2 F'],
  "federov": ['F EH1 D ER0 AO2 V'],
  "federspiel": ['F EH1 D ER0 S P IY0 L'],
  "fedewa": ['F IH0 D UW1 AA2'],
  "fedex": ['F EH1 D EH1 K S'],
  "fediay": ['F IY1 D IY0 EY2'],
  "fedler": ['F EH1 D L ER0'],
  "fedor": ['F EH1 D ER0'],
  "fedora": ['F IH0 D AO1 R AH0'],
  "fedorchak": ['F EH1 D ER0 K AH0 K'],
  "fedorko": ['F IH0 D AO1 R K OW0'],
  "fedrick": ['F EH1 D R IH0 K'],
  "feds": ['F EH1 D Z'],
  "fee": ['F IY1'],
  "feeback": ['F IY1 B AE2 K'],
  "feebis": ['F IY1 B IH0 S'],
  "feeble": ['F IY1 B AH0 L'],
  "feebly": ['F IY1 B L IY0'],
  "feed": ['F IY1 D'],
  "feedback": ['F IY1 D B AE2 K'],
  "feeder": ['F IY1 D ER0'],
  "feeders": ['F IY1 D ER0 Z'],
  "feeding": ['F IY1 D IH0 NG'],
  "feedings": ['F IY1 D IH0 NG Z'],
  "feedlot": ['F IY1 D L AA2 T'],
  "feedlots": ['F IY1 D L AA2 T S'],
  "feeds": ['F IY1 D Z'],
  "feedstock": ['F IY1 D S T AA2 K'],
  "feedstocks": ['F IY1 D S T AA2 K S'],
  "feehan": ['F IY1 AH0 N'],
  "feel": ['F IY1 L'],
  "feeler": ['F IY1 L ER0'],
  "feelers": ['F IY1 L ER0 Z'],
  "feeley": ['F IY1 L IY0'],
  "feelin'": ['F IY1 L IH0 N'],
  "feeling": ['F IY1 L IH0 NG'],
  "feelings": ['F IY1 L IH0 NG Z'],
  "feels": ['F IY1 L Z'],
  "feely": ['F IY1 L IY0'],
  "feemster": ['F IY1 M S T ER0'],
  "feeney": ['F IY1 N IY0'],
  "feenstra": ['F IY1 N S T R AH0'],
  "feeny": ['F IY1 N IY0'],
  "fees": ['F IY1 Z'],
  "feese": ['F IY1 Z'],
  "feeser": ['F IY1 Z ER0'],
  "feet": ['F IY1 T'],
  "feezor": ['F IY1 Z ER0'],
  "feffer": ['F EH1 F ER0'],
  "fegan": ['F EH1 G AH0 N'],
  "feger": ['F IY1 G ER0'],
  "fegley": ['F EH1 G L IY0'],
  "feher": ['F EH1 HH ER0'],
  "fehl": ['F EH1 L'],
  "fehling": ['F EH1 L IH0 NG'],
  "fehlman": ['F EH1 L M AH0 N'],
  "fehn": ['F EH1 N'],
  "fehnel": ['F EH1 N AH0 L'],
  "fehr": ['F EH1 R'],
  "fehrenbach": ['F EH1 R IH0 N B AA0 K'],
  "fehrenbacher": ['F EH1 R IH0 N B AA0 K ER0'],
  "fehring": ['F EH1 R IH0 NG'],
  "fehringer": ['F EH1 R IH0 NG ER0'],
  "fehrman": ['F EH1 R M AH0 N'],
  "feick": ['F IY1 K'],
  "feickert": ['F AY1 K ER0 T'],
  "feig": ['F IY1 G'],
  "feige": ['F IY1 JH'],
  "feigel": ['F AY1 G AH0 L'],
  "feigen": ['F AY1 G AH0 N'],
  "feigenbaum": ['F AY1 G AH0 N B AW2 M'],
  "feiger": ['F AY1 G ER0'],
  "feighan": ['F EY1 G AH0 N'],
  "feighner": ['F EY1 N ER0'],
  "feight": ['F EY1 T'],
  "feigin": ['F AY1 G IH0 N'],
  "feign": ['F EY1 N'],
  "feigned": ['F EY1 N D'],
  "feigning": ['F EY1 N IH0 NG'],
  "feil": ['F IY1 L'],
  "feild": ['F IY1 L D'],
  "feiler": ['F AY1 L ER0'],
  "feimster": ['F AY1 M S T ER0'],
  "fein": ['F AY1 N', 'F EY1 N'],
  "fein's": ['F AY1 N Z', 'F EY1 N Z'],
  "feinauer": ['F AY1 N AW0 ER0'],
  "feinberg": ['F AY1 N B ER0 G'],
  "feiner": ['F AY1 N ER0'],
  "feinerman": ['F AY1 N ER0 M AH0 N'],
  "feingold": ['F AY1 NG G OW0 L D'],
  "feinman": ['F AY1 N M AH0 N'],
  "feinstein": ['F AY1 N S T AY2 N', 'F AY1 N S T IY2 N'],
  "feinstein's": ['F AY1 N S T AY2 N Z', 'F AY1 N S T IY2 N Z'],
  "feint": ['F EY1 N T'],
  "feis": ['F AY1 S'],
  "feist": ['F AY1 S T'],
  "feister": ['F AY1 S T ER0'],
  "feisty": ['F AY1 S T IY0'],
  "feit": ['F AY1 T'],
  "feith": ['F AY1 TH'],
  "feith's": ['F AY1 TH S'],
  "feitz": ['F AY1 T S'],
  "fejes": ['F IH0 ZH IY1 Z'],
  "fekete": ['F EH1 K IY0 T'],
  "fel": ['F EH1 L'],
  "fela": ['F EH1 L AH0'],
  "felan": ['F EH1 L AH0 N'],
  "felbatol": ['F EH1 L B AH0 T AA0 L'],
  "felber": ['F EH1 L B ER0'],
  "felch": ['F EH1 L CH'],
  "feld": ['F EH1 L D'],
  "felda": ['F EH1 L D AH0'],
  "feldberg": ['F EH1 L D B ER0 G'],
  "feldblum": ['F EH1 L D B L UW2 M', 'F EH1 L D B L AH0 M'],
  "felde": ['F EH1 L D'],
  "feldene": ['F EH0 L D IY1 N'],
  "felder": ['F EH1 L D ER0'],
  "felderman": ['F EH1 L D ER0 M AH0 N'],
  "feldhaus": ['F EH1 L D HH AW2 S'],
  "feldkamp": ['F EH1 L D K AE2 M P'],
  "feldman": ['F EH1 L D M AH0 N'],
  "feldman's": ['F EH1 L D M AH0 N Z'],
  "feldmann": ['F EH1 L D M AH0 N'],
  "feldmeier": ['F EH1 L D M AY0 ER0'],
  "feldmuehle": ['F EH1 L D M Y UW2 L AH0'],
  "feldner": ['F EH1 L D N ER0'],
  "feldpausch": ['F EH1 L D P AW2 SH'],
  "feldspar": ['F EH1 L D S P AA2 R'],
  "feldspars": ['F EH1 L D S P AA2 R Z'],
  "feldstein": ['F EH1 L D S T AY0 N', 'F EH1 L D S T IY0 N'],
  "feldstein's": ['F EH1 L D S T IY2 N Z', 'F EH1 L D S T AY2 N Z'],
  "feldt": ['F EH1 L T'],
  "felgenhauer": ['F EH1 L G IH0 N HH AW0 ER0'],
  "felger": ['F EH1 L G ER0'],
  "felice": ['F AH0 L IY1 S'],
  "felicetti": ['F EH0 L IY0 CH EH1 T IY0'],
  "felicia": ['F AH0 L IY1 SH AH0'],
  "felicia's": ['F AH0 L IY1 SH AH0 Z'],
  "feliciano": ['F AH0 L IY0 S IY0 AA1 N OW0', 'F AH0 L IY0 SH IY0 AA1 N OW0'],
  "feliciano's": ['F AH0 L IY0 S IY0 AA1 N OW0 Z', 'F AH0 L IY0 SH IY0 AA1 N OW0 Z'],
  "felicitate": ['F AH0 L IH1 S IH0 T EY2 T'],
  "felicite": ['F EH1 L IH0 S AY2 T', 'F EH0 L IH1 S AH0 T IY0'],
  "felicitous": ['F IH0 L IH1 S AH0 T AH0 S'],
  "felicity": ['F IH0 L IH1 S AH0 T IY0'],
  "felim": ['F EH1 L AH0 M'],
  "feline": ['F IY1 L AY2 N'],
  "felines": ['F IY1 L AY2 N Z'],
  "felipe": ['F AH0 L IY1 P'],
  "felis": ['F EH1 L IH0 S'],
  "felix": ['F IY1 L IH0 K S'],
  "felix's": ['F IY1 L IH0 K S IH0 Z'],
  "feliz": ['F EH1 L IH0 Z'],
  "felkel": ['F EH1 L K AH0 L'],
  "felker": ['F EH1 L K ER0'],
  "felker's": ['F EH1 L K ER0 Z'],
  "felkins": ['F EH1 L K IH0 N Z'],
  "felkner": ['F EH1 L K N ER0'],
  "fell": ['F EH1 L'],
  "fella": ['F EH1 L AH0'],
  "felland": ['F EH1 L AH0 N D'],
  "fellas": ['F EH1 L AH0 S'],
  "fellatio": ['F AH0 L EY1 SH IY0 OW0'],
  "felled": ['F EH1 L D'],
  "felleisen": ['F EH2 L AY1 S AH0 N'],
  "fellenz": ['F EH1 L IH0 N S'],
  "feller": ['F EH1 L ER0'],
  "fellers": ['F EH1 L ER0 Z'],
  "fellheimer": ['F EH1 L HH AY2 M ER0'],
  "fellin": ['F EH1 L IH0 N'],
  "felling": ['F EH1 L IH0 NG'],
  "fellinger": ['F EH1 L IH0 NG ER0'],
  "fellini": ['F AH0 L IY1 N IY0'],
  "fellman": ['F EH1 L M AH0 N'],
  "fellmeth": ['F EH1 L M AH0 TH'],
  "fellner": ['F EH1 L N ER0'],
  "fellow": ['F EH1 L OW0'],
  "fellow's": ['F EH1 L OW0 Z'],
  "fellowes": ['F EH1 L OW2 Z'],
  "fellows": ['F EH1 L OW0 Z'],
  "fellowship": ['F EH1 L OW0 SH IH2 P'],
  "fellowships": ['F EH1 L OW0 SH IH2 P S'],
  "fells": ['F EH1 L Z'],
  "fellsway": ['F EH1 L Z W EY2'],
  "felman": ['F EH1 L M AH0 N'],
  "felmlee": ['F EH1 L M L IY0'],
  "felon": ['F EH1 L AH0 N'],
  "felonies": ['F EH1 L AH0 N IY0 Z'],
  "felonious": ['F EH0 L OW1 N IY0 AH0 S'],
  "felons": ['F EH1 L AH0 N Z'],
  "felony": ['F EH1 L AH0 N IY0'],
  "felps": ['F EH1 L P S'],
  "felrice": ['F EH1 L R AY2 S'],
  "fels": ['F EH1 L Z'],
  "felsenthal": ['F EH1 L Z AH0 N TH AA2 L'],
  "felske": ['F EH1 L S K'],
  "felt": ['F EH1 L T'],
  "felten": ['F EH1 L T AH0 N'],
  "felter": ['F EH1 L T ER0'],
  "feltes": ['F EH1 L T S'],
  "feltler": ['F EH1 L T L ER0'],
  "feltman": ['F EH1 L T M AH0 N'],
  "feltner": ['F EH1 L T N ER0'],
  "felton": ['F EH1 L T AH0 N'],
  "feltrax": ['F EH1 L T R AE0 K S'],
  "felts": ['F EH1 L T S'],
  "feltsman": ['F EH1 L T S M AH0 N'],
  "feltus": ['F EH1 L T AH0 S'],
  "felty": ['F EH1 L T IY0'],
  "feltz": ['F EH1 L T S'],
  "felucca": ['F IH0 L AH1 K AH0'],
  "felver": ['F EH1 L V ER0'],
  "fema": ['F IY1 M AH0'],
  "female": ['F IY1 M EY2 L'],
  "female's": ['F IY1 M EY2 L Z'],
  "females": ['F IY1 M EY2 L Z'],
  "femia": ['F IY1 M IY0 AH0'],
  "femina": ['F EH1 M IH0 N AH0'],
  "feminine": ['F EH1 M AH0 N AH0 N'],
  "femininity": ['F EH2 M AH0 N IH1 N AH0 T IY0'],
  "feminism": ['F EH1 M IH0 N IH2 Z AH0 M'],
  "feminist": ['F EH1 M AH0 N IH0 S T'],
  "feminists": ['F EH1 M AH0 N IH0 S T S'],
  "femme": ['F EH1 M'],
  "femoral": ['F EH1 M ER2 AH0 L'],
  "femrite": ['F EH1 M R AY2 T'],
  "femsa": ['F EH1 M S AH0'],
  "femur": ['F IY1 M ER0'],
  "fen": ['F EH1 N'],
  "fenagle": ['F AH0 N EY1 G AH0 L'],
  "fence": ['F EH1 N S'],
  "fenced": ['F EH1 N S T'],
  "fenceless": ['F EH1 N S L AH0 S'],
  "fences": ['F EH1 N S AH0 Z', 'F EH1 N S IH0 Z'],
  "fencing": ['F EH1 N S IH0 NG'],
  "fencl": ['F EH1 NG K AH0 L'],
  "fend": ['F EH1 N D'],
  "fended": ['F EH1 N D IH0 D'],
  "fender": ['F EH1 N D ER0'],
  "fenders": ['F EH1 N D ER0 Z'],
  "fenderson": ['F EH1 N D ER0 S AH0 N'],
  "fendi": ['F EH1 N D IY0'],
  "fending": ['F EH1 N D IH0 NG'],
  "fendler": ['F EH1 N D L ER0'],
  "fendley": ['F EH1 N D L IY0'],
  "fendrick": ['F EH1 N D R IH0 K'],
  "fends": ['F EH1 N D Z'],
  "fendt": ['F EH1 N T'],
  "fenech": ['F EH1 N IH0 K'],
  "fenella": ['F EH0 N EH1 L AH0'],
  "fenelon": ['F EH1 N IH0 L AA2 N'],
  "fenfluramine": ['F EH0 N F L UH1 R AH0 M IY0 N'],
  "feng": ['F EH1 NG'],
  "fenger": ['F EH1 NG ER0'],
  "fenian": ['F IY1 N IY0 AH0 N'],
  "fenichell": ['F EH1 N IH0 CH AH0 L'],
  "fenimore": ['F EH1 N IH0 M AO2 R'],
  "fenjves": ['F EH0 N HH EH1 V EH0 Z', 'F EH1 N V EH0 Z'],
  "fenley": ['F EH1 N L IY0'],
  "fenlon": ['F EH1 N L AH0 N'],
  "fenn": ['F EH1 N'],
  "fennel": ['F EH1 N AH0 L'],
  "fennell": ['F EH1 N AH0 L'],
  "fennelly": ['F EH1 N AH0 L IY0'],
  "fennema": ['F EH1 N IH0 M AH0'],
  "fenner": ['F EH1 N ER0'],
  "fennessey": ['F EH1 N IH0 S IY0'],
  "fennessy": ['F EH1 N IH0 S IY0'],
  "fennewald": ['F EH1 N IH0 W AO0 L D'],
  "fenney": ['F EH1 N IY0'],
  "fennimore": ['F EH1 N IH0 M AO2 R'],
  "fenno": ['F EH1 N OW0'],
  "fenoglio": ['F EH0 N OW1 G L IY0 OW0'],
  "fenphen": ['F EH1 N F EH0 N'],
  "fenske": ['F EH1 N S K'],
  "fenster": ['F EH1 N S T ER0'],
  "fenstermacher": ['F EH1 N S T ER0 M AH0 K ER0'],
  "fenstermaker": ['F EH1 N S T ER0 M EY2 K ER0'],
  "fensterstock": ['F EH1 N S T ER0 S T AA2 K'],
  "fent": ['F EH1 N T'],
  "fenter": ['F EH1 N T ER0'],
  "fenton": ['F EH1 N T AH0 N'],
  "fentress": ['F EH1 N T R IH0 S'],
  "fenugreek": ['F EH1 N UW0 G R IY2 K'],
  "fenway": ['F EH1 N W EY2'],
  "fenwick": ['F EH1 N W IH2 K'],
  "fenwood": ['F EH1 N W UH2 D'],
  "fenyvessy": ['F EH1 N IH0 V EH2 S IY0'],
  "fenzel": ['F EH1 N Z AH0 L'],
  "feodor": ['F IY1 AH0 D ER0'],
  "feodora": ['F IY0 AH0 D AO1 R AH0'],
  "feodorovich": ['F IY0 AH0 D AO1 R AH0 V IH0 CH'],
  "feola": ['F IY0 AA1 L AH0'],
  "fer": ['F ER1', 'F EH1 R'],
  "fera": ['F EH1 R AH0'],
  "feral": ['F EH1 R AH0 L'],
  "feraluzi": ['F EH2 R AH0 L UW1 Z IY0'],
  "ferarro": ['F EH0 R AA1 R OW0'],
  "ferber": ['F ER1 B ER0'],
  "ferch": ['F ER1 K'],
  "ferd": ['F ER1 D'],
  "ferderer": ['F ER1 D ER0 ER0'],
  "ferdie": ['F ER1 D IY0'],
  "ferdig": ['F ER1 D IH0 G'],
  "ferdinand": ['F ER1 D IH0 N AE2 N D', 'F ER1 D IH0 N AE2 N'],
  "ferdinanda": ['F ER0 D IY0 N AA1 N D AH0'],
  "ferdlow": ['F EH1 R D L OW0'],
  "ferdon": ['F EH0 R D AO1 N'],
  "ferebee": ['F EH1 R IH0 B IY0'],
  "ferenc": ['F ER0 EH1 N S'],
  "ference": ['F IH1 R AH0 N S'],
  "ferencz": ['F EH1 R IH0 N CH'],
  "feretlow": ['F EH1 R AH0 T L OW0', 'F EH1 R T L OW0'],
  "ferg": ['F ER1 G'],
  "fergason": ['F ER1 G AH0 S AH0 N'],
  "ferger": ['F ER1 G ER0'],
  "fergerson": ['F ER1 G ER0 S AH0 N'],
  "fergeson": ['F ER1 G AH0 S AH0 N'],
  "fergie": ['F ER1 G IY0'],
  "fergus": ['F ER1 G AH0 S'],
  "ferguson": ['F ER1 G AH0 S AH0 N'],
  "ferguson's": ['F ER1 G AH0 S AH0 N Z'],
  "fergusson": ['F ER1 G AH0 S AH0 N'],
  "feria": ['F EH1 R IY0 AH0'],
  "ferko": ['F ER1 K OW0'],
  "ferland": ['F ER1 L AH0 N D'],
  "ferm": ['F ER1 M'],
  "ferman": ['F ER1 M AH0 N'],
  "fermata": ['F EH0 R M AA1 T AA2'],
  "ferment": ['F ER0 M EH1 N T', 'F ER1 M EH0 N T'],
  "fermenta": ['F ER0 M EH1 N T AH0', 'F ER0 M EH1 N AH0'],
  "fermenta's": ['F ER0 M EH1 N T AH0 Z', 'F ER0 M EH1 N AH0 Z'],
  "fermentation": ['F ER2 M AH0 N T EY1 SH AH0 N'],
  "fermented": ['F ER0 M EH1 N T AH0 D'],
  "fermenting": ['F ER0 M EH1 N T IH0 NG'],
  "ferments": ['F ER0 M EH1 N T S'],
  "fermi": ['F ER1 M IY0'],
  "fermilab": ['F ER1 M IH0 L AE2 B'],
  "fermin": ['F ER1 M IH0 N'],
  "fermium": ['F EH1 R M IY0 AH0 M'],
  "fermoyle": ['F ER1 M OY2 L'],
  "fern": ['F ER1 N'],
  "fernald": ['F ER0 N AA1 L D', 'F EH2 R N AA1 L D'],
  "fernand": ['F ER0 N AE1 N D', 'F ER0 N AA1 N', 'F ER0 N AA1 N D'],
  "fernanda": ['F ER0 N AE1 N D AH0', 'F ER0 N AA1 N D AH0'],
  "fernandes": ['F ER0 N AA1 N D EH0 S', 'F ER0 N AE1 N D EH0 S'],
  "fernandez": ['F ER0 N AE1 N D EH0 Z', 'F EH0 R N AE1 N D EH0 Z', 'F ER0 N AA1 N D EH0 Z', 'F EH0 R N AA1 N D EH0 Z'],
  "fernandina": ['F ER0 N AH0 N D IY1 N AH0'],
  "fernando": ['F ER0 N AE1 N D OW0', 'F ER0 N AA1 N D OW0'],
  "fernando's": ['F ER0 N AE1 N D OW0 Z', 'F ER0 N AA1 N D OW0 Z'],
  "fernao": ['F ER0 N AW1'],
  "fernau": ['F ER1 N AW0'],
  "fernbank": ['F ER1 N B AE2 NG K'],
  "fernberg": ['F ER1 N B ER0 G'],
  "ferndale": ['F ER1 N D EY2 L'],
  "ferner": ['F ER1 N ER0'],
  "fernery": ['F ER1 N ER0 IY0'],
  "fernholz": ['F ER1 N HH OW0 L Z'],
  "fernicola": ['F ER0 N IY0 K OW1 L AH0'],
  "fernlike": ['F ER1 N L AY2 K'],
  "ferns": ['F ER1 N Z'],
  "fernstrom": ['F ER1 N S T R AH0 M'],
  "fero": ['F EH1 R OW0'],
  "ferocious": ['F ER0 OW1 SH AH0 S'],
  "ferociously": ['F ER0 OW1 SH IH0 S L IY0'],
  "ferocity": ['F ER0 AA1 S AH0 T IY0'],
  "ferol": ['F EH1 R AH0 L'],
  "ferra": ['F EH1 R AH0'],
  "ferraioli": ['F EH1 R AY0 OW0 L IY0'],
  "ferrall": ['F EH1 R AH0 L'],
  "ferran": ['F EH1 R AH0 N'],
  "ferrand": ['F EH1 R AH0 N D'],
  "ferrandino": ['F ER0 AA0 N D IY1 N OW0'],
  "ferrando": ['F EH2 R AE1 N D OW0'],
  "ferrant": ['F EH1 R AH0 N T'],
  "ferrante": ['F ER0 AA1 N T IY0'],
  "ferranti": ['F EH0 R AE1 N T IY0', 'F EH0 R AE1 N IY0'],
  "ferrar": ['F ER0 AA1 R'],
  "ferrara": ['F ER0 AA1 R AH0'],
  "ferrari": ['F ER0 AA1 R IY0'],
  "ferrario": ['F ER0 AA1 R IY0 OW0'],
  "ferraris": ['F ER0 AA1 R IH0 S'],
  "ferraro": ['F ER0 AA1 R OW0'],
  "ferraro's": ['F ER0 AA1 R OW0 Z'],
  "ferre": ['F EH1 R'],
  "ferrebee": ['F EH1 R IH0 B IY0'],
  "ferree": ['F ER0 IY1'],
  "ferreira": ['F EH0 R EY1 R AH0'],
  "ferreiro": ['F EH0 R EH1 R OW0'],
  "ferrel": ['F EH0 R EH1 L'],
  "ferrell": ['F EH1 R IH0 L'],
  "ferrelli": ['F ER0 EH1 L IY0'],
  "ferren": ['F EH1 R AH0 N'],
  "ferrency": ['F EH2 R EH1 N S IY0'],
  "ferrentino": ['F ER0 EH0 N T IY1 N OW0'],
  "ferrer": ['F EH1 R ER0'],
  "ferrera": ['F EH0 R EH1 R AH0'],
  "ferreri": ['F ER0 EH1 R IY0'],
  "ferrero": ['F EH0 R EH1 R OW0'],
  "ferret": ['F EH1 R AH0 T'],
  "ferreted": ['F EH1 R AH0 T AH0 D', 'F EH1 R AH0 T IH0 D'],
  "ferreting": ['F EH1 R AH0 T IH0 NG'],
  "ferrets": ['F EH1 R AH0 T S'],
  "ferretti": ['F ER0 EH1 T IY0'],
  "ferri": ['F EH1 R IY0'],
  "ferrick": ['F EH1 R IH0 K'],
  "ferrie": ['F EH1 R IY0'],
  "ferried": ['F EH1 R IY0 D'],
  "ferrier": ['F EH1 R IY0 ER0'],
  "ferriera": ['F ER0 IH1 R AH0'],
  "ferriers": ['F EH1 R IY0 ER0 Z'],
  "ferries": ['F EH1 R IY0 Z'],
  "ferrigno": ['F ER0 IY1 G N OW0'],
  "ferrill": ['F EH0 R IY1 L'],
  "ferrin": ['F EH1 R IH0 N'],
  "ferrington": ['F EH1 R IH0 NG T AH0 N'],
  "ferrini": ['F ER0 IY1 N IY0'],
  "ferris": ['F EH1 R IH0 S'],
  "ferriss": ['F EH1 R IH0 S'],
  "ferrite": ['F EH1 R AY0 T'],
  "ferriter": ['F EH1 R AY0 T ER0'],
  "ferriz": ['F EH1 R IH0 Z'],
  "ferro": ['F EH1 R OW0'],
  "ferrochrome": ['F EH1 R AH0 K R OW2 M'],
  "ferrofluidic": ['F EH2 R OW0 F L UW0 IH1 D IH0 K'],
  "ferrofluidics": ['F EH2 R OW0 F L UW0 IH1 D IH0 K S'],
  "ferromagnet": ['F EH2 R OW0 M AE1 G N AH0 T'],
  "ferromagnetic": ['F EH2 R OW0 M AE2 G N EH1 T IH0 K'],
  "ferromagnetism": ['F EH2 R OW0 M AE1 G N AH0 T IH2 Z AH0 M'],
  "ferron": ['F EH1 R AH0 N'],
  "ferrone": ['F ER0 OW1 N IY0'],
  "ferronickel": ['F EH2 R AH0 N IH1 K AH0 L'],
  "ferrotype": ['F EH1 R AH0 T AY2 P'],
  "ferrous": ['F EH1 R AH0 S'],
  "ferrucci": ['F ER0 UW1 CH IY0'],
  "ferruzzi": ['F EH0 R UW1 Z IY0'],
  "ferruzzi's": ['F ER2 UW1 Z IY0 Z'],
  "ferry": ['F EH1 R IY0'],
  "ferry's": ['F EH1 R IY0 Z'],
  "ferryboat": ['F EH1 R IY0 B OW2 T'],
  "ferryboats": ['F EH1 R IY0 B OW2 T S'],
  "ferrying": ['F EH1 R IY0 IH0 NG'],
  "ferryman": ['F EH1 R IY0 M AH0 N'],
  "fertig": ['F ER1 T IH0 G'],
  "fertile": ['F ER1 T AH0 L', 'F ER0 T AY1 L'],
  "fertility": ['F ER0 T IH1 L AH0 T IY0', 'F ER0 T IH1 L IH0 T IY0'],
  "fertilization": ['F ER2 T AH0 L IH0 Z EY1 SH AH0 N'],
  "fertilize": ['F ER1 T AH0 L AY2 Z'],
  "fertilized": ['F ER1 T AH0 L AY2 Z D'],
  "fertilizer": ['F ER1 T AH0 L AY2 Z ER0'],
  "fertilizers": ['F ER1 T AH0 L AY2 Z ER0 Z'],
  "fertilizes": ['F ER1 T AH0 L AY2 Z IH0 Z'],
  "fertilizing": ['F ER1 T AH0 L AY2 Z IH0 NG'],
  "fertitta": ['F ER0 T IY1 T AH0'],
  "fertlow": ['F ER1 T L OW0'],
  "ferullo": ['F ER0 UW1 L OW0'],
  "fervent": ['F ER1 V AH0 N T'],
  "fervently": ['F ER1 V AH0 N T L IY0'],
  "fervid": ['F ER1 V AH0 D'],
  "fervor": ['F ER1 V ER0'],
  "fery": ['F EH1 R IY0'],
  "feschbach": ['F EH1 SH B AA2 K'],
  "fescue": ['F EH1 S K Y UW2'],
  "feser": ['F IY1 Z ER0'],
  "feshbach": ['F EH1 SH B AA2 K'],
  "fesler": ['F EH1 S AH0 L ER0', 'F EH1 S L ER0'],
  "fesmire": ['F EH1 S M AY2 R'],
  "fesperman": ['F EH1 S P ER0 M AH0 N'],
  "fess": ['F EH1 S'],
  "fessed": ['F EH1 S T'],
  "fessel": ['F EH1 S AH0 L'],
  "fessenden": ['F EH1 S AH0 N D AH0 N'],
  "fessler": ['F EH1 S L ER0'],
  "fest": ['F EH1 S T'],
  "festa": ['F EH1 S T AH0'],
  "fester": ['F EH1 S T ER0'],
  "festered": ['F EH1 S T ER0 D'],
  "festering": ['F EH1 S T ER0 IH0 NG'],
  "festiva": ['F EH0 S T IY1 V AH0'],
  "festival": ['F EH1 S T AH0 V AH0 L', 'F EH1 S T IH0 V AH0 L'],
  "festival's": ['F EH1 S T IH0 V AH0 L Z'],
  "festivals": ['F EH1 S T AH0 V AH0 L Z', 'F EH1 S T IH0 V AH0 L Z'],
  "festive": ['F EH1 S T IH0 V'],
  "festivities": ['F EH0 S T IH1 V AH0 T IY0 Z'],
  "festivity": ['F EH0 S T IH1 V AH0 T IY0'],
  "festoon": ['F EH2 S T UW1 N'],
  "festooned": ['F EH2 S T UW1 N D'],
  "fests": ['F EH1 S T S'],
  "festspielhaus": ['F EH1 S T S P IY1 L HH AW2 S'],
  "festus": ['F EH1 S T AH0 S'],
  "feta": ['F EH1 T AA2'],
  "fetal": ['F IY1 T AH0 L'],
  "fetch": ['F EH1 CH'],
  "fetched": ['F EH1 CH T'],
  "fetches": ['F EH1 CH IH0 Z'],
  "fetching": ['F EH1 CH IH0 NG'],
  "fete": ['F EY1 T'],
  "feted": ['F EY1 T IH0 D'],
  "fetherolf": ['F EH1 TH ER0 OW0 L F'],
  "fetherston": ['F EH1 TH ER0 S T AH0 N'],
  "fetid": ['F EH1 T AH0 D'],
  "fetish": ['F EH1 T IH0 SH'],
  "fetishism": ['F EH1 T IH0 SH IH2 Z AH0 M'],
  "fetishist": ['F EH1 T IH0 SH IH0 S T', 'F EH1 T IH0 SH AH0 S T'],
  "fetishists": ['F EH1 T IH0 SH AH0 S T S', 'F EH1 T IH0 SH IH0 S T S'],
  "fetner": ['F EH1 T N ER0'],
  "fetrow": ['F EH1 T R OW0'],
  "fetsch": ['F EH1 CH'],
  "fetsko": ['F EH1 T S K OW0'],
  "fett": ['F EH1 T'],
  "fette": ['F EH1 T'],
  "fetter": ['F EH1 T ER0'],
  "fettered": ['F EH1 T ER0 D'],
  "fetterhoff": ['F EH1 T ER0 HH AO0 F'],
  "fetterly": ['F EH1 T ER0 L IY0'],
  "fetterman": ['F EH1 T ER0 M AH0 N'],
  "fetterolf": ['F EH1 T ER0 OW0 L F'],
  "fetters": ['F EH1 T ER0 Z'],
  "fettes": ['F EH1 T S'],
  "fettig": ['F EH1 T IH0 G'],
  "fetting": ['F EH1 T IH0 NG'],
  "fettuccine": ['F EH2 T AH0 CH IY1 N IY0'],
  "fetty": ['F EH1 T IY0'],
  "fetus": ['F IY1 T AH0 S'],
  "fetuses": ['F IY1 T AH0 S IH0 Z'],
  "fetz": ['F EH1 T S'],
  "fetzer": ['F EH1 T S ER0'],
  "feucht": ['F OY1 K T'],
  "feud": ['F Y UW1 D'],
  "feudal": ['F Y UW1 D AH0 L'],
  "feudalism": ['F Y UW1 D AH0 L IH2 Z AH0 M'],
  "feudalistic": ['F Y UW2 D AH0 L IH1 S T IH0 K'],
  "feuded": ['F Y UW1 D IH0 D'],
  "feuding": ['F Y UW1 D IH0 NG'],
  "feuds": ['F Y UW1 D Z'],
  "feuer": ['F Y UW1 ER0'],
  "feuerborn": ['F OY1 ER0 B ER0 N'],
  "feuerman": ['F Y UW1 ER0 M AH0 N'],
  "feuerstein": ['F Y UW1 ER0 S T IY2 N', 'F Y UW1 ER0 S T AY2 N'],
  "feulner": ['F OY1 L N ER0'],
  "feur": ['F ER1'],
  "feustel": ['F OY1 S T AH0 L'],
  "feutz": ['F Y UW1 T S'],
  "fever": ['F IY1 V ER0'],
  "fevered": ['F IY1 V ER0 D'],
  "feverfew": ['F IY1 V ER0 F Y UW2'],
  "feverfews": ['F IY1 V ER0 F Y UW2 Z'],
  "feverish": ['F IY1 V ER0 IH0 SH'],
  "feverishly": ['F EH1 V ER0 IH2 SH L IY0'],
  "fevers": ['F IY1 V ER0 Z'],
  "few": ['F Y UW1'],
  "fewell": ['F EH1 W EH0 L'],
  "fewer": ['F Y UW1 ER0'],
  "fewest": ['F Y UW1 AH0 S T'],
  "fey": ['F EY1'],
  "feyen": ['F AY1 N'],
  "feynman": ['F EY1 N M AH0 N'],
  "fez": ['F EH1 Z'],
  "fi": ['F AY1', 'F IY1'],
  "fiacco": ['F IY0 AA1 K OW0'],
  "fiala": ['F IY0 AA1 L AH0'],
  "fialkowski": ['F IY0 AH0 L K AO1 F S K IY0'],
  "fiance": ['F IY0 AA1 N S EY2', 'F IY2 AA2 N S EY1'],
  "fiancee": ['F IY0 AE1 N S IY0'],
  "fianna": ['F IY0 AE1 N AH0'],
  "fiasco": ['F IY0 AE1 S K OW0'],
  "fiascos": ['F IY0 AE1 S K OW0 Z'],
  "fiat": ['F AY1 AE0 T'],
  "fiat's": ['F IY1 AE2 T S'],
  "fiato": ['F IY0 AE1 T OW0', 'F Y AE1 T OW0'],
  "fib": ['F IH1 B'],
  "fiber": ['F AY1 B ER0'],
  "fiber's": ['F AY1 B ER0 Z'],
  "fiberboard": ['F AY1 B ER0 B AO2 R D'],
  "fiberglas": ['F AY1 B ER0 G L AE2 S'],
  "fiberglass": ['F AY1 B ER0 G L AE2 S'],
  "fiberoptic": ['F AY1 B ER0 AA2 P T IH0 K'],
  "fibers": ['F AY1 B ER0 Z'],
  "fiberweb": ['F AY1 B ER0 W EH2 B'],
  "fibonacci": ['F IH2 B AH0 N AA1 CH IY0'],
  "fibre": ['F AY1 B ER0'],
  "fibreboard": ['F AY1 B ER0 B AO2 R D'],
  "fibres": ['F AY1 B ER0 Z'],
  "fibrile": ['F AY1 B R IH0 L'],
  "fibriles": ['F AY1 B R IH0 L Z'],
  "fibrillation": ['F IH2 B R IH0 L EY1 SH AH0 N'],
  "fibrin": ['F AY1 B R AH0 N'],
  "fibroid": ['F AY1 B R OY0 D'],
  "fibroids": ['F AY1 B R OY0 D Z'],
  "fibronic": ['F IH0 B R AA1 N IH0 K'],
  "fibronics": ['F IH0 B R AA1 N IH0 K S'],
  "fibrosis": ['F AY0 B R OW1 S AH0 S'],
  "fibrous": ['F AY1 B R AH0 S'],
  "fibula": ['F IH1 B Y AH0 L AH0'],
  "fica": ['F AY1 K AH0'],
  "ficarra": ['F IY0 K AA1 R AH0', 'F IH0 K AA1 R AH0'],
  "ficco": ['F IH1 K OW0'],
  "ficek": ['F IH1 S IH0 K'],
  "fichera": ['F IY0 K EH1 R AH0'],
  "fichtel": ['F IH1 CH T EH2 L'],
  "fichter": ['F IH1 K T ER0'],
  "fichtner": ['F IH1 K T N ER0'],
  "fichus": ['F IH1 CH AH0 S'],
  "ficials": ['F IH1 SH AH0 L Z'],
  "fick": ['F IH1 K'],
  "ficke": ['F IH1 K'],
  "fickel": ['F IH1 K AH0 L'],
  "ficken": ['F IH1 K AH0 N'],
  "ficker": ['F IH1 K ER0'],
  "fickes": ['F IH1 K S'],
  "fickett": ['F IH1 K IH0 T'],
  "fickle": ['F IH1 K AH0 L'],
  "fickleness": ['F IH1 K AH0 L N AH0 S'],
  "ficklin": ['F IH1 K L IH0 N'],
  "fickling": ['F IH1 K L IH0 NG'],
  "fico": ['F IY1 K OW0'],
  "ficom": ['F IH1 K AA0 M'],
  "ficorca": ['F IH0 K AO1 R K AH0'],
  "fiction": ['F IH1 K SH AH0 N'],
  "fictional": ['F IH1 K SH AH0 N AH0 L'],
  "fictionalize": ['F IH1 K SH AH0 N AH0 L AY2 Z'],
  "fictionalized": ['F IH1 K SH AH0 N AH0 L AY2 Z D'],
  "fictions": ['F IH1 K SH AH0 N Z'],
  "fictitious": ['F IH0 K T IH1 SH AH0 S'],
  "fidalgo": ['F IY0 D AA1 L G OW0'],
  "fidata": ['F IH0 D AA1 T AH0'],
  "fidder": ['F IH1 D ER0'],
  "fiddle": ['F IH1 D AH0 L'],
  "fiddled": ['F IH1 D AH0 L D'],
  "fiddler": ['F IH1 D AH0 L ER0', 'F IH1 D L ER0'],
  "fiddler's": ['F IH1 D AH0 L ER0 Z', 'F IH1 D L ER0 Z'],
  "fiddlers": ['F IH1 D AH0 L ER0 Z', 'F IH1 D L ER0 Z'],
  "fiddles": ['F IH1 D AH0 L Z'],
  "fiddling": ['F IH1 D L IH0 NG'],
  "fide": ['F AY1 D'],
  "fidel": ['F IH0 D EH1 L'],
  "fidel's": ['F IH0 D EH1 L Z'],
  "fidelco": ['F IH0 D EH1 L K OW0'],
  "fidelcor": ['F IH1 D AH0 L K AO2 R'],
  "fidelia": ['F IH0 D IY1 L Y AH0'],
  "fidelities": ['F AH0 D EH1 L AH0 T IY0 Z'],
  "fidelity": ['F AH0 D EH1 L AH0 T IY0', 'F AY2 D EH1 L AH0 T IY0'],
  "fidelity's": ['F AH0 D EH1 L AH0 T IY0 Z'],
  "fidenas": ['F IH1 D IH0 N AH0 S'],
  "fides": ['F AY1 D Z'],
  "fidget": ['F IH1 JH IH0 T'],
  "fidgeting": ['F IH1 JH AH0 T IH0 NG'],
  "fidler": ['F AY1 D AH0 L ER0', 'F AY1 D L ER0'],
  "fido": ['F AY1 D OW0'],
  "fidora": ['F AH0 D AO1 R AH0'],
  "fidora's": ['F AH0 D AO1 R AH0 Z'],
  "fiduciares": ['F IH0 D UW1 S IY0 EH2 R Z', 'F IH0 D UW1 S IY0 EH2 R IY0 Z'],
  "fiduciaries": ['F IH0 D UW1 SH IY0 EH2 R IY0 Z'],
  "fiduciary": ['F AH0 D UW1 SH IY0 EH2 R IY0'],
  "fie": ['F IY1'],
  "fiebelkorn": ['F IY1 B IH0 L K ER0 N'],
  "fieber": ['F IY1 B ER0'],
  "fiebig": ['F IY1 B IH0 G'],
  "fiebiger": ['F IY1 B IH0 G ER0'],
  "fiechter": ['F IH1 K T ER0'],
  "fiedler": ['F IY1 D L ER0'],
  "fiedler's": ['F IY1 D L ER0 Z'],
  "fief": ['F IY1 F'],
  "fiefdom": ['F IY1 F D AH0 M'],
  "fiefdoms": ['F IY1 F D AH0 M Z'],
  "fiefs": ['F IY1 F S'],
  "fiegel": ['F IY1 G AH0 L'],
  "fieger": ['F IY1 G ER0'],
  "fieger's": ['F IY1 G ER0 Z'],
  "fiel": ['F IY1 L'],
  "field": ['F IY1 L D'],
  "field's": ['F IY1 L D Z'],
  "fieldcrest": ['F IY1 L D K R EH2 S T'],
  "fielded": ['F IY1 L D IH0 D'],
  "fielden": ['F IY1 L D AH0 N'],
  "fielder": ['F IY1 L D ER0'],
  "fielder's": ['F IY1 L D ER0 Z'],
  "fielders": ['F IY1 L D ER0 Z'],
  "fieldhouse": ['F IY1 L D HH AW2 S'],
  "fielding": ['F IY1 L D IH0 NG'],
  "fields": ['F IY1 L D Z'],
  "fields'": ['F IY1 L D Z'],
  "fieldwork": ['F IY1 L D W ER2 K'],
  "fiend": ['F IY1 N D'],
  "fiendish": ['F IY1 N D IH0 SH'],
  "fiends": ['F IY1 N D Z'],
  "fiene": ['F IY1 N'],
  "fiennes": ['F IY0 EH1 N EH0 S', 'F Y EH1 N EH0 S'],
  "fier": ['F IH1 R'],
  "fierce": ['F IH1 R S'],
  "fiercely": ['F IH1 R S L IY0'],
  "fiercer": ['F IH1 R S ER0'],
  "fiercest": ['F IH1 R S AH0 S T'],
  "fierman": ['F IH1 R M AH0 N'],
  "fiero": ['F IY1 R OW0'],
  "fieros": ['F IY0 EH1 R OW0 S'],
  "fierro": ['F IH1 R OW0'],
  "fierros": ['F IH1 R OW0 Z'],
  "fiers": ['F IY1 R Z'],
  "fierst": ['F IY1 R S T'],
  "fierstein": ['F IY1 R S T IY2 N', 'F IY1 R S T AY2 N'],
  "fiery": ['F AY1 ER0 IY0'],
  "fieser": ['F IY1 S ER0'],
  "fiest": ['F IY1 IH0 S T'],
  "fiesta": ['F IY0 EH1 S T AH0'],
  "fifa": ['F IH1 F AA0'],
  "fife": ['F AY1 F'],
  "fifer": ['F AY1 F ER0'],
  "fifi": ['F IY1 F IY0'],
  "fifield": ['F IH1 F IY0 L D'],
  "fiftee": ['F IH1 F T IY0'],
  "fifteen": ['F IH0 F T IY1 N', 'F IH1 F T IY1 N'],
  "fifteen's": ['F IH0 F T IY1 N Z'],
  "fifteens": ['F IH0 F T IY1 N Z'],
  "fifteenth": ['F IH0 F T IY1 N TH'],
  "fifth": ['F IH1 F TH', 'F IH1 TH'],
  "fifths": ['F IH1 F TH S', 'F IH1 F S'],
  "fifties": ['F IH1 F T IY0 Z'],
  "fiftieth": ['F IH1 F T IY0 IH0 TH'],
  "fifty": ['F IH1 F T IY0'],
  "fifty's": ['F IH1 F T IY0 Z'],
  "fig": ['F IH1 G'],
  "figallo": ['F IH0 G AA1 L OW0'],
  "figaro": ['F IH1 G ER0 OW2'],
  "figaroa": ['F IH2 G ER0 OW1 AH0'],
  "figaroa's": ['F IH2 G ER0 OW1 AH0 Z'],
  "figel": ['F IH1 G AH0 L'],
  "figg": ['F IH1 G'],
  "figge": ['F IH1 G'],
  "figgie": ['F IH1 G IY0'],
  "figgie's": ['F IH1 G IY0 Z'],
  "figgins": ['F IH1 G IH0 N Z'],
  "figgs": ['F IH1 G Z'],
  "fight": ['F AY1 T'],
  "fight's": ['F AY1 T Z'],
  "fighter": ['F AY1 T ER0'],
  "fighter's": ['F AY1 T ER0 Z'],
  "fighters": ['F AY1 T ER0 Z'],
  "fighters'": ['F AY1 T ER0 Z'],
  "fightin'": ['F AY1 T IH0 N'],
  "fighting": ['F AY1 T IH0 NG'],
  "fighting's": ['F AY1 T IH0 NG Z'],
  "fightings": ['F AY1 T IH0 NG Z'],
  "fightmaster": ['F AY1 T M AE2 S T ER0'],
  "fights": ['F AY1 T S'],
  "figler": ['F AY1 G AH0 L ER0', 'F IH1 G L ER0'],
  "figley": ['F IH1 G L IY0'],
  "figment": ['F IH1 G M IH0 N T'],
  "figments": ['F IH1 G M IH0 N T S'],
  "figone": ['F IH1 G AH0 N'],
  "figs": ['F IH1 G Z'],
  "figueira": ['F IY0 G EH1 R AH0'],
  "figueiredo": ['F IY0 G ER0 EY1 D OW0'],
  "figueras": ['F IY0 G EH1 R AA0 Z'],
  "figueredo": ['F IY0 G EH0 R EY1 D OW0'],
  "figueroa": ['F IY0 G EH0 R OW1 AH0'],
  "figura": ['F IY0 G UH1 R AH0'],
  "figuration": ['F IH2 G Y ER0 EY1 SH AH0 N'],
  "figurative": ['F IH1 G Y ER0 AH0 T IH0 V'],
  "figuratively": ['F IH0 G Y UH1 R AH0 T IH0 V L IY0'],
  "figure": ['F IH1 G Y ER0'],
  "figure's": ['F IH1 G Y ER0 Z'],
  "figured": ['F IH1 G Y ER0 D'],
  "figurehead": ['F IH1 G Y ER0 HH EH2 D'],
  "figures": ['F IH1 G Y ER0 Z'],
  "figurine": ['F IH2 G Y ER0 IY1 N'],
  "figurines": ['F IH2 G Y ER0 IY1 N Z'],
  "figuring": ['F IH1 G Y ER0 IH0 NG'],
  "figurski": ['F IH0 G ER1 S K IY0'],
  "fiji": ['F IY1 JH IY0'],
  "fiji's": ['F IY1 JH IY0 Z'],
  "fijian": ['F IY1 JH IY0 AH0 N'],
  "fijians": ['F IH1 JH IY0 AH0 N Z'],
  "fike": ['F AY1 K'],
  "fikes": ['F AY1 K S'],
  "fil": ['F IH1 L'],
  "fila": ['F AY1 L AH0'],
  "filament": ['F IH1 L AH0 M AH0 N T'],
  "filaments": ['F IH1 L AH0 M AH0 N T S'],
  "filbert": ['F IH1 L B ER0 T'],
  "filbert's": ['F IH1 L B ER0 T S'],
  "filberts": ['F IH1 L B ER0 T S'],
  "filbrick": ['F IH1 L B R IH0 K'],
  "filby": ['F IH1 L B IY0'],
  "filch": ['F IH1 L CH'],
  "filched": ['F IH1 L CH T'],
  "fildes": ['F IH1 L D Z'],
  "file": ['F AY1 L'],
  "filed": ['F AY1 L D'],
  "filegate": ['F AY1 L G EY2 T'],
  "filename": ['F AY1 L N EY2 M'],
  "filene": ['F IH0 L IY1 N'],
  "filene's": ['F IH0 L IY1 N Z'],
  "filenet": ['F AY1 L N EH2 T'],
  "filer": ['F AY1 L ER0'],
  "filers": ['F AY1 L ER0 Z'],
  "files": ['F AY1 L Z'],
  "filet": ['F IH0 L EY1'],
  "filets": ['F IH0 L EY1 Z'],
  "filial": ['F IH1 L IY0 AH0 L'],
  "filibuster": ['F IH1 L AH0 B AH2 S T ER0'],
  "filibustered": ['F IH1 L AH0 B AH2 S T ER0 D'],
  "filibustering": ['F IH1 L AH0 B AH2 S T ER0 IH0 NG'],
  "filibusters": ['F IH1 L AH0 B AH2 S T ER0 Z'],
  "filice": ['F IY1 L IH0 S'],
  "filigree": ['F IH1 L AH0 G R IY2'],
  "filigrees": ['F IH1 L AH0 G R IY2 Z'],
  "filing": ['F AY1 L IH0 NG'],
  "filings": ['F AY1 L IH0 NG Z'],
  "filinski": ['F IH0 L IH1 N S K IY0'],
  "filion": ['F IH1 L Y AH0 N'],
  "filip": ['F IH1 L IH0 P'],
  "filipa": ['F IY0 L IY1 P AH0'],
  "filipek": ['F IH1 L IH0 P IH0 K'],
  "filipiak": ['F IH0 L IH1 P IY0 AE0 K'],
  "filipino": ['F IH2 L AH0 P IY1 N OW0'],
  "filipinos": ['F IH2 L AH0 P IY1 N OW0 Z'],
  "filipkowski": ['F IH0 L IH0 P K AO1 F S K IY0'],
  "filipovic": ['F IH0 L IH0 P OW1 V IH0 K'],
  "filipowicz": ['F IH0 L IH1 P AH0 V IH0 CH'],
  "filippelli": ['F IY0 L IY0 P EH1 L IY0'],
  "filippello": ['F IY2 L IH0 P EH1 L OW0'],
  "filippi": ['F IH0 L IH1 P IY0'],
  "filippini": ['F IY0 L IY0 P IY1 N IY0'],
  "filippo": ['F IH0 L IY1 P OW0'],
  "filippone": ['F IH1 L IH0 P OW2 N'],
  "filipski": ['F IH0 L IH1 P S K IY0'],
  "filkins": ['F IH1 L K IH0 N Z'],
  "fill": ['F IH1 L'],
  "fill-a-buster": ['F IH1 L AH0 B AH2 S T ER0'],
  "filla": ['F IH1 L AH0'],
  "fillauer": ['F IH1 L AW2 ER0'],
  "filled": ['F IH1 L D'],
  "filler": ['F IH1 L ER0'],
  "fillers": ['F IH1 L ER0 Z'],
  "fillet": ['F AH0 L EY1'],
  "filleted": ['F IH1 L AH0 T IH0 D', 'F AH0 L EY1 D'],
  "fillets": ['F IH1 L IH0 T S'],
  "filley": ['F IH1 L IY0'],
  "fillibuster": ['F IH1 L AH0 B AH2 S T ER0'],
  "fillibusters": ['F IH1 L AH0 B AH2 S T ER0 Z'],
  "fillies": ['F IH1 L IY0 Z'],
  "filling": ['F IH1 L IH0 NG'],
  "fillingame": ['F IY0 L IH0 NG G AA1 M IY0'],
  "fillinger": ['F IH1 L IH0 NG ER0'],
  "fillingham": ['F IH1 L IH0 NG HH AE2 M'],
  "fillingim": ['F IH1 L IH0 NG G IH0 M'],
  "fillings": ['F IH1 L IH0 NG Z'],
  "fillion": ['F IH1 L Y AH0 N'],
  "fillip": ['F IH1 L AH0 P'],
  "fillman": ['F IH1 L M AH0 N'],
  "fillmore": ['F IH1 L M AO0 R'],
  "fills": ['F IH1 L Z'],
  "filly": ['F IH1 L IY0'],
  "film": ['F IH1 L M'],
  "film's": ['F IH1 L M Z'],
  "filma": ['F IH1 L M AH0'],
  "filmed": ['F IH1 L M D'],
  "filmer": ['F IH1 L M ER0'],
  "filming": ['F IH1 L M IH0 NG'],
  "filmless": ['F IH1 L M L AH0 S'],
  "filmmaker": ['F IH1 L M EY2 K ER0'],
  "filmmaker's": ['F IH1 L M EY2 K ER0 Z'],
  "filmmakers": ['F IH1 L M EY2 K ER0 Z'],
  "filmmaking": ['F IH1 L M EY2 K IH0 NG'],
  "filmore": ['F IH1 L M AO2 R'],
  "films": ['F IH1 L M Z'],
  "filo": ['F IY1 L OW0'],
  "filofax": ['F IH1 L OW0 F AE2 K S', 'F AY1 L OW0 F AE2 K S'],
  "filomena": ['F IH2 L AH0 M IY1 N AH0'],
  "filosa": ['F IY0 L OW1 S AH0'],
  "filsinger": ['F IH1 L S IH0 NG ER0'],
  "filson": ['F IH1 L S AH0 N'],
  "filter": ['F IH1 L T ER0'],
  "filtered": ['F IH1 L T ER0 D'],
  "filtering": ['F IH1 L T ER0 IH0 NG'],
  "filters": ['F IH1 L T ER0 Z'],
  "filtertek": ['F IH1 L T ER0 T EH2 K'],
  "filth": ['F IH1 L TH'],
  "filthy": ['F IH1 L TH IY0'],
  "filtration": ['F IH0 L T R EY1 SH AH0 N'],
  "filtrenelli": ['F IH2 L T R AH0 N EH1 L IY0'],
  "filtrol": ['F IH1 L T R AA0 L'],
  "filyaw": ['F IH0 L IY1 AA0 V', 'F IH1 L Y AW2'],
  "fima": ['F IY1 M AH0'],
  "fimbres": ['F IH1 M B ER0 Z'],
  "fin": ['F IH1 N'],
  "fina": ['F AY1 N AH0'],
  "finagle": ['F IH1 N AH0 G AH0 L', 'F IH0 N EY1 G AH0 L'],
  "finagler": ['F AH0 N EY1 G L ER0'],
  "final": ['F AY1 N AH0 L'],
  "finalco": ['F IH0 N AE1 L K OW0'],
  "finale": ['F AH0 N AE1 L IY0'],
  "finalist": ['F AY1 N AH0 L IH0 S T'],
  "finalists": ['F AY1 N AH0 L IH0 S T S'],
  "finality": ['F AY0 N AE1 L AH0 T IY0'],
  "finalize": ['F AY1 N AH0 L AY2 Z'],
  "finalized": ['F AY1 N AH0 L AY2 Z D'],
  "finalizes": ['F AY1 N AH0 L AY2 Z IH0 Z'],
  "finalizing": ['F AY1 N AH0 L AY2 Z IH0 NG'],
  "finally": ['F AY1 N AH0 L IY0'],
  "finals": ['F AY1 N AH0 L Z'],
  "finamore": ['F IH1 N AH0 M AO2 R'],
  "finan": ['F IH1 N AH0 N'],
  "finance": ['F AH0 N AE1 N S', 'F IH0 N AE1 N S', 'F AY1 N AE2 N S'],
  "financed": ['F IH0 N AE1 N S T', 'F AY1 N AE2 N S T'],
  "financement": ['F IH0 N AE1 N S M AH0 N T', 'F AY1 N AE2 N S M AH0 N T'],
  "financer": ['F AY1 N AE2 N S ER0', 'F IH0 N AE1 N S ER0'],
  "finances": ['F IH0 N AE1 N S IH0 Z', 'F AY1 N AE2 N S IH0 Z'],
  "financial": ['F AH0 N AE1 N SH AH0 L', 'F IH0 N AE1 N SH AH0 L', 'F AY2 N AE1 N SH AH0 L'],
  "financial's": ['F AY2 N AE1 N SH AH0 L Z', 'F IH0 N AE1 N SH AH0 L Z'],
  "financially": ['F AH0 N AE1 N SH AH0 L IY0', 'F IH0 N AE1 N SH AH0 L IY0', 'F AY2 N AE1 N SH AH0 L IY0'],
  "financials": ['F AY2 N AE1 N SH AH0 L Z', 'F IH0 N AE1 N SH AH0 L Z', 'F AH0 N AE1 N SH AH0 L Z'],
  "financier": ['F IH2 N AH0 N S IH1 R', 'F AY2 N AE0 N S IH1 R'],
  "financiera": ['F IH0 N AE2 N S IY0 EH1 R AH0'],
  "financiere": ['F IH2 N AE0 N S IH1 R'],
  "financiero": ['F IH0 N AE2 N S IY0 EH1 R OW0'],
  "financiers": ['F IH2 N AH0 N S IH1 R Z'],
  "financing": ['F AH0 N AE1 N S IH0 NG', 'F IH0 N AE1 N S IH0 NG', 'F AY0 N AE1 N S IH0 NG'],
  "financings": ['F AH0 N AE1 N S IH0 NG Z', 'F IH0 N AE1 N S IH0 NG Z', 'F AY1 N AE2 N S IH0 NG Z'],
  "financo": ['F IH0 N AE1 NG K OW0'],
  "finanz": ['F IH1 N AE0 N Z'],
  "finanza": ['F IH0 N AE1 N Z AH0'],
  "finanziaria": ['F IH0 N AE2 N Z IY0 EH1 R IY0 AH0'],
  "finanziario": ['F IH0 N AE2 N Z IY0 EH1 R IY0 OW0'],
  "finarte": ['F IH1 N AA0 R T'],
  "finazzo": ['F IY0 N AA1 Z OW0'],
  "finbar": ['F IH1 N B AA0 R'],
  "finbar's": ['F IH1 N B AA0 R Z'],
  "finberg": ['F IH1 N B ER0 G'],
  "fincannon": ['F IH1 N K AH0 N AA0 N', 'F IH0 N K AE1 N AH0 N'],
  "finch": ['F IH1 N CH'],
  "fincham": ['F IH1 N CH AH0 M'],
  "fincher": ['F IH1 N CH ER0'],
  "finches": ['F IH1 N CH IH0 Z'],
  "finchum": ['F IH1 N K AH0 M'],
  "finck": ['F IH1 NG K'],
  "fincke": ['F IH1 NG K'],
  "finckle": ['F IH1 NG K AH0 L'],
  "find": ['F AY1 N D'],
  "finder": ['F AY1 N D ER0'],
  "finder's": ['F AY1 N D ER0 Z'],
  "finders": ['F AY1 N D ER0 Z'],
  "finding": ['F AY1 N D IH0 NG'],
  "findings": ['F AY1 N D IH0 NG Z'],
  "findlay": ['F IH1 N D L IY0'],
  "findler": ['F IH1 N D L ER0'],
  "findley": ['F IH1 N D L IY0'],
  "findling": ['F IH1 N D AH0 L IH0 NG', 'F IH1 N D L IH0 NG'],
  "finds": ['F AY1 N D Z'],
  "fine": ['F AY1 N', 'F IH1 N AH0'],
  "fineberg": ['F AY1 N B ER0 G'],
  "fined": ['F AY1 N D'],
  "finefrock": ['F AY1 N F R AA2 K'],
  "finegan": ['F IH1 N IH0 G AE0 N'],
  "finegold": ['F AY1 N G OW2 L D'],
  "finella": ['F IH0 N EH1 L AH0'],
  "finelli": ['F IH0 N EH1 L IY0'],
  "finely": ['F AY1 N L IY0'],
  "fineman": ['F AY1 N M AH0 N'],
  "finer": ['F AY1 N ER0'],
  "fineran": ['F IH1 N ER0 AE0 N'],
  "finerty": ['F IH1 N ER0 T IY0'],
  "finery": ['F AY1 N ER0 IY0'],
  "fines": ['F AY1 N Z'],
  "finesse": ['F IH0 N EH1 S'],
  "finessed": ['F IH0 N EH1 S T'],
  "finest": ['F AY1 N AH0 S T'],
  "finestone": ['F AY1 N S T OW2 N'],
  "finevest": ['F AY1 N V EH2 S T'],
  "finex": ['F IH1 N EH2 K S'],
  "finfrock": ['F IH1 N F R AA2 K'],
  "finger": ['F IH1 NG G ER0'],
  "fingerboard": ['F IH1 NG G ER0 B AO2 R D'],
  "fingered": ['F IH1 NG G ER0 D'],
  "fingerhut": ['F IH1 NG G ER0 HH AH2 T'],
  "fingering": ['F IH1 NG G ER0 IH0 NG'],
  "fingernail": ['F IH1 NG G ER0 N EY2 L'],
  "fingernails": ['F IH1 NG G ER0 N EY2 L Z'],
  "fingerpaint": ['F IH1 NG G ER0 P EY2 N T'],
  "fingerpainted": ['F IH1 NG G ER0 P EY2 N T IH0 D'],
  "fingerpointing": ['F IH1 NG G ER0 P OY2 N T IH0 NG'],
  "fingerprint": ['F IH1 NG G ER0 P R IH2 N T'],
  "fingerprinted": ['F IH1 NG G ER0 P R IH2 N T IH0 D'],
  "fingerprinting": ['F IH1 NG G ER0 P R IH2 N T IH0 NG'],
  "fingerprints": ['F IH1 NG G ER0 P R IH2 N T S'],
  "fingers": ['F IH1 NG G ER0 Z'],
  "fingertip": ['F IH1 NG G ER0 T IH2 P'],
  "fingertips": ['F IH1 NG G ER0 T IH2 P S'],
  "fini": ['F IY1 N IY0'],
  "finian": ['F IH1 N IY0 AH0 N'],
  "finian's": ['F IH1 N IY0 AH0 N Z'],
  "finicky": ['F IH1 N AH0 K IY0'],
  "finigan": ['F IH1 N IH0 G AH0 N'],
  "fining": ['F AY1 N IH0 NG'],
  "fininvest": ['F IH1 N IH2 N V EH2 S T'],
  "fininvest's": ['F IH1 N IH2 N V EH2 S T S'],
  "finish": ['F IH1 N IH0 SH'],
  "finished": ['F IH1 N IH0 SH T'],
  "finisher": ['F IH1 N IH0 SH ER0'],
  "finishers": ['F IH1 N IH0 SH ER0 Z'],
  "finishes": ['F IH1 N IH0 SH IH0 Z'],
  "finishing": ['F IH1 N IH0 SH IH0 NG'],
  "finite": ['F AY1 N AY2 T'],
  "fink": ['F IH1 NG K'],
  "fink's": ['F IH1 NG K S'],
  "finkbeiner": ['F IH1 NG K B AY2 N ER0'],
  "finkbiner": ['F IH1 NG K B AY2 N ER0'],
  "finke": ['F IH1 NG K'],
  "finkel": ['F IH1 NG K AH0 L'],
  "finkelman": ['F IH1 NG K AH0 L M AH0 N'],
  "finkelson": ['F IH1 NG K AH0 L S AH0 N'],
  "finkelstein": ['F IH1 NG K AH0 L S T IY2 N', 'F IH1 NG K AH0 L S T AY2 N'],
  "finkelstein's": ['F IH1 NG K AH0 L S T IY2 N Z', 'F IH1 NG K AH0 L S T AY2 N Z'],
  "finken": ['F IH1 NG K AH0 N'],
  "finkenbinder": ['F IH1 NG K IH0 N B IH0 N D ER0'],
  "finkielstain": ['F IH0 NG K IY1 L S T IH0 N', 'F IH2 NG K IY1 L S T EY0 N'],
  "finkle": ['F IH1 NG K AH0 L'],
  "finklea": ['F IH1 NG K L IY0 AH0'],
  "finkler": ['F IH1 NG K L ER0'],
  "finkley": ['F IH1 NG K L IY0'],
  "finks": ['F IH1 NG K S'],
  "finland": ['F IH1 N L AE2 N D', 'F IH1 N L AH0 N D'],
  "finland's": ['F IH1 N L AH0 N D Z'],
  "finlandization": ['F IH2 N L AE0 N D IH0 Z EY1 SH AH0 N'],
  "finlay": ['F IH1 N L IY0'],
  "finlayson": ['F IH1 N L IY0 S AH0 N'],
  "finlets": ['F IH1 N L AH0 T S'],
  "finley": ['F IH1 N L IY0'],
  "finley's": ['F IH1 N L IY0 Z'],
  "finmeccanica": ['F IH2 N M AH0 K AE1 N IH0 K AH0'],
  "finn": ['F IH1 N'],
  "finn's": ['F IH1 N Z'],
  "finnair": ['F IH1 N EH1 R'],
  "finnan": ['F IH1 N AH0 N'],
  "finne": ['F IH1 N'],
  "finnegan": ['F IH1 N AH0 G AH0 N'],
  "finnell": ['F IH1 N AH0 L'],
  "finneran": ['F IH1 N ER0 AE0 N'],
  "finnerty": ['F IH1 N ER0 T IY0'],
  "finney": ['F IH1 N IY0'],
  "finnick": ['F IH1 N IH0 K'],
  "finnicum": ['F IH1 N IH0 K AH0 M'],
  "finnie": ['F IH1 N IY0'],
  "finnigan": ['F IH1 N IH0 G AH0 N'],
  "finning": ['F IH1 N IH0 NG'],
  "finnish": ['F IH1 N IH0 SH'],
  "finns": ['F IH1 N Z'],
  "fino": ['F IY1 N OW0'],
  "finocchiaro": ['F IY0 N OW0 K IY0 AA1 R OW0'],
  "finocchio": ['F IY0 N OW1 K IY0 OW0'],
  "fins": ['F IH1 N Z'],
  "finscher": ['F IH1 N SH ER0'],
  "finsider": ['F IH1 N S AY0 D ER0'],
  "finsider's": ['F IH1 N S AY0 D ER0 Z'],
  "finstad": ['F IH1 N S T AH0 D'],
  "finster": ['F IH1 N S T ER0'],
  "finstrom": ['F IH1 N S T R AH0 M'],
  "finton": ['F IH1 N T AH0 N'],
  "finucane": ['F IH1 N AH0 K EY0 N'],
  "finunc": ['F IH0 N AH1 NG K'],
  "finzel": ['F IH1 N Z AH0 L'],
  "fiola": ['F IY0 OW1 L AH0'],
  "fiona": ['F IY0 OW1 N AH0'],
  "fioravanti": ['F IY0 AO0 R AA0 V AA1 N T IY0'],
  "fiore": ['F IY0 AO1 R IY0'],
  "fiorella": ['F IY0 AO0 R EH1 L AA0'],
  "fiorelli": ['F IY0 AO0 R EH1 L IY0'],
  "fiorello": ['F IY0 AO0 R EH1 L OW0'],
  "fiorentino": ['F IY0 AO0 R EH0 N T IY1 N OW0'],
  "fiorenza": ['F IY0 AO0 R EH1 N Z AH0'],
  "fioretti": ['F IY0 AO0 R EH1 T IY0'],
  "fiori": ['F IY0 AO1 R IY0'],
  "fiorillo": ['F IY0 AO0 R IH1 L OW0'],
  "fiorina": ['F IY0 AO0 R IY1 N AH0'],
  "fiorini": ['F IY0 AO0 R IY1 N IY0'],
  "fiorino": ['F IY0 AO0 R IY1 N OW0'],
  "fiorito": ['F IY0 AO0 R IY1 T OW0'],
  "fiorucci": ['F IY0 AO0 R UW1 CH IY0'],
  "fipple": ['F IH1 P AH0 L'],
  "fipps": ['F IH1 P S'],
  "fir": ['F ER1'],
  "fire": ['F AY1 ER0', 'F AY1 R'],
  "fire's": ['F AY1 ER0 Z'],
  "fire-men": ['F AY1 R M AH0 N'],
  "firearm": ['F AY1 ER0 AA2 R M', 'F AY1 R AA2 R M'],
  "firearms": ['F AY1 ER0 AA2 R M Z', 'F AY1 R AA2 R M Z'],
  "fireball": ['F AY1 ER0 B AO2 L', 'F AY1 R B AO2 L'],
  "fireballs": ['F AY1 ER0 B AO2 L Z', 'F AY1 R B AO2 L Z'],
  "firebaugh": ['F AY1 R B AO2'],
  "firebird": ['F AY1 ER0 B ER2 D'],
  "firebirds": ['F AY1 ER0 B ER2 D Z'],
  "firebomb": ['F AY1 ER0 B AA2 M', 'F AY1 R B AA2 M'],
  "firebombed": ['F AY1 R B AA2 M D'],
  "firebombing": ['F AY1 ER0 B AA2 M IH0 NG'],
  "firebombs": ['F AY1 R B AA2 M Z'],
  "firebrand": ['F AY1 ER0 B R AE2 N D'],
  "firebrands": ['F AY1 ER0 B R AE2 N D Z'],
  "firebrick": ['F AY1 ER0 B R IH2 K'],
  "firebug": ['F AY1 ER0 B AH2 G'],
  "firebush": ['F AY1 ER0 B UH2 SH'],
  "firebush's": ['F AY1 R B UH2 SH IH0 Z'],
  "firecracker": ['F AY1 ER0 K R AE2 K ER0'],
  "firecrackers": ['F AY1 ER0 K R AE2 K ER0 Z'],
  "fired": ['F AY1 ER0 D'],
  "firedamp": ['F AY1 ER0 D AE2 M P'],
  "firefight": ['F AY1 R F AY2 T'],
  "firefighter": ['F AY1 R F AY2 T ER0'],
  "firefighters": ['F AY1 ER0 F AY2 T ER0 Z'],
  "firefighting": ['F AY1 R F AY2 T IH0 NG'],
  "firefights": ['F AY1 R F AY2 T S'],
  "fireflies": ['F AY1 ER0 F L AY2 Z'],
  "firefly": ['F AY1 ER0 F L AY2'],
  "firefox": ['F AY1 ER0 F AO2 K S'],
  "firehouse": ['F AY1 ER0 HH AW2 S'],
  "firehouses": ['F AY1 ER0 HH AW2 S IH0 Z'],
  "firelighter": ['F AY1 ER0 L AY2 T ER0'],
  "firelighters": ['F AY1 ER0 L AY2 T ER0 Z'],
  "fireman": ['F AY1 R M AH0 N'],
  "fireman's": ['F AY1 R M AH0 N Z'],
  "firemen": ['F AY1 R M AH0 N'],
  "firenza": ['F IH0 R EH1 N Z AH0'],
  "firenzas": ['F ER0 EH1 N Z AH0 Z'],
  "fireplace": ['F AY1 ER0 P L EY2 S'],
  "fireplaces": ['F AY1 ER0 P L EY2 S AH0 Z', 'F AY1 R P L EY2 S IH0 Z'],
  "firepower": ['F AY1 R P AW2 ER0'],
  "fireproof": ['F AY1 ER0 P R UW2 F'],
  "fireproofing": ['F AY1 ER0 P R UW2 F IH0 NG'],
  "fires": ['F AY1 ER0 Z', 'F AY1 R Z'],
  "fireside": ['F AY1 ER0 S AY2 D'],
  "firesign": ['F AY1 ER0 S AY2 N'],
  "firesign's": ['F AY1 ER0 S AY2 N Z'],
  "firestine": ['F AY0 R S T IY1 N IY0'],
  "firestone": ['F AY1 R S T OW2 N'],
  "firestone's": ['F AY1 R S T OW2 N Z'],
  "firestorm": ['F AY1 R S T AO2 R M'],
  "firewall": ['F AY1 R W AA0 L'],
  "fireweed": ['F AY1 ER0 W IY2 D'],
  "firewood": ['F AY1 ER0 W UH2 D', 'F AY1 R W UH2 D'],
  "firework": ['F AY1 R W ER2 K'],
  "fireworks": ['F AY1 R W ER2 K S'],
  "firfer": ['F ER1 F ER0'],
  "firing": ['F AY1 R IH0 NG', 'F AY1 ER0 R IH0 NG'],
  "firings": ['F AY1 R IH0 NG Z', 'F AY1 ER0 R IH0 NG Z'],
  "firino": ['F IH0 R IY1 N OW0'],
  "firkins": ['F ER1 K IH0 N Z'],
  "firkus": ['F ER1 K IH0 S'],
  "firm": ['F ER1 M'],
  "firm's": ['F ER1 M Z'],
  "firma": ['F ER1 M AH0'],
  "firman": ['F ER1 M AH0 N'],
  "firmans": ['F ER1 M AH0 N Z'],
  "firmed": ['F ER1 M D'],
  "firmer": ['F ER1 M ER0'],
  "firmest": ['F ER1 M AH0 S T'],
  "firmin": ['F ER1 M IH0 N'],
  "firming": ['F ER1 M IH0 NG'],
  "firmly": ['F ER1 M L IY0'],
  "firmness": ['F ER1 M N AH0 S'],
  "firms": ['F ER1 M Z'],
  "firms'": ['F ER1 M Z'],
  "firmware": ['F ER1 M W EH2 R'],
  "firpo": ['F IH1 R P OW0'],
  "firs": ['F ER1 Z'],
  "first": ['F ER1 S T'],
  "first's": ['F ER1 S T S'],
  "first-born": ['F ER2 S T B AO1 R N'],
  "first-class": ['F ER2 S T K L AE1 S'],
  "first-rate": ['F ER2 S T R EY1 T'],
  "firstar": ['F ER1 S T AA2 R'],
  "firstborn": ['F ER1 S T B AO1 R N'],
  "firstfed": ['F ER1 S T F EH2 D'],
  "firsthand": ['F ER0 S T HH AE1 N D', 'F ER0 S HH AE1 N D', 'F ER1 S T HH AE0 N D', 'F ER1 S HH AE0 N D'],
  "firstier": ['F ER1 S T Y ER0', 'F ER1 S T IY0 ER0'],
  "firstly": ['F ER1 S T L IY0'],
  "firsts": ['F ER1 S T S'],
  "firstsouth": ['F ER1 S T S AW2 TH'],
  "firth": ['F ER1 TH'],
  "firzite": ['F ER1 Z AY2 T'],
  "fis": ['F IH1 S'],
  "fisc": ['F IH1 S K'],
  "fiscal": ['F IH1 S K AH0 L'],
  "fiscally": ['F IH1 S K AH0 L IY0'],
  "fisch": ['F IH1 SH'],
  "fischbach": ['F IH1 SH B AA2 K'],
  "fischbach's": ['F IH1 SH B AA2 K S'],
  "fischbein": ['F IH1 SH B AY2 N'],
  "fischel": ['F IH1 SH AH0 L'],
  "fischer": ['F IH1 SH ER0'],
  "fischer's": ['F IH1 SH ER0 Z'],
  "fischetti": ['F IY0 S K EH1 T IY0'],
  "fischl": ['F IH1 S K AH0 L'],
  "fischler": ['F IH1 SH AH0 L ER0', 'F IH1 SH L ER0'],
  "fischman": ['F IH1 SH M AH0 N'],
  "fiscus": ['F IH1 S K AH0 S'],
  "fiser": ['F AY1 Z ER0'],
  "fiserv": ['F IH1 S ER0 V'],
  "fisette": ['F IH0 S EH1 T'],
  "fish": ['F IH1 SH'],
  "fish's": ['F IH1 SH IH0 Z'],
  "fishbach": ['F IH1 SH B AH2 K'],
  "fishback": ['F IH1 SH B AE2 K'],
  "fishbaine": ['F IH1 SH B EY2 N'],
  "fishbaugh": ['F IH1 SH B AO2'],
  "fishbeck": ['F IH1 SH B EH2 K'],
  "fishbein": ['F IH1 SH B AY2 N'],
  "fishbowl": ['F IH1 SH B OW2 L'],
  "fishburn": ['F IH1 SH B ER2 N'],
  "fishburne": ['F IH1 SH B ER0 N'],
  "fisheater": ['F IH1 SH IY2 T ER0'],
  "fisheaters": ['F IH1 SH IY2 T ER0 Z'],
  "fished": ['F IH1 SH T'],
  "fishel": ['F IH1 SH AH0 L'],
  "fishell": ['F IH1 SH AH0 L'],
  "fisher": ['F IH1 SH ER0'],
  "fisher's": ['F IH1 SH ER0 Z'],
  "fisheries": ['F IH1 SH ER0 IY0 Z'],
  "fisherman": ['F IH1 SH ER0 M AE2 N', 'F IH1 SH ER0 M AH0 N'],
  "fisherman's": ['F IH1 SH ER0 M AH0 N Z'],
  "fishermen": ['F IH1 SH ER0 M IH0 N'],
  "fishers": ['F IH1 SH ER0 Z'],
  "fishery": ['F IH1 SH ER0 IY0'],
  "fishes": ['F IH1 SH AH0 Z', 'F IH1 SH IH0 Z'],
  "fishing": ['F IH1 SH IH0 NG'],
  "fishkill": ['F IH1 SH K IH2 L'],
  "fishkin": ['F IH1 SH K IH0 N'],
  "fishlow": ['F IH1 SH L OW2'],
  "fishman": ['F IH1 SH M AE2 N', 'F IH1 SH M AH0 N'],
  "fishmonger": ['F IH1 SH M AA2 NG G ER0'],
  "fishy": ['F IH1 SH IY0'],
  "fisk": ['F IH1 S K'],
  "fisk's": ['F IH1 S K S'],
  "fiske": ['F IH1 S K'],
  "fiske's": ['F IH1 S K S'],
  "fisler": ['F IH1 S AH0 L ER0', 'F IH1 S L ER0'],
  "fisons": ['F AY1 Z AH0 N Z'],
  "fiss": ['F IH1 S'],
  "fissel": ['F IH1 S AH0 L'],
  "fissell": ['F IH1 S AH0 L'],
  "fissile": ['F IH1 S AH0 L'],
  "fission": ['F IH1 SH AH0 N'],
  "fissionable": ['F IH1 SH AH0 N AH0 B AH0 L'],
  "fissure": ['F IH1 SH ER0'],
  "fissured": ['F IH1 SH ER0 D'],
  "fissures": ['F IH1 SH ER0 Z'],
  "fist": ['F IH1 S T'],
  "fisted": ['F IH1 S T IH0 D'],
  "fister": ['F IH1 S T ER0'],
  "fistful": ['F IH1 S T F AH0 L'],
  "fisticuff": ['F IH1 S T IH0 K AH2 F'],
  "fisticuffs": ['F IH1 S T IH0 K AH2 F S'],
  "fists": ['F IH1 S T S'],
  "fit": ['F IH1 T'],
  "fitak": ['F IH1 T AE2 K'],
  "fitch": ['F IH1 CH'],
  "fitchburg": ['F IH1 CH B ER0 G'],
  "fitchett": ['F IH1 CH IH0 T'],
  "fite": ['F AY1 T'],
  "fites": ['F AY1 T S'],
  "fitful": ['F IH1 T F AH0 L'],
  "fitfully": ['F IH1 T F AH0 L IY0'],
  "fithe": ['F IH1 TH'],
  "fithian": ['F IH1 TH IY0 AH0 N'],
  "fitness": ['F IH1 T N AH0 S'],
  "fits": ['F IH1 T S'],
  "fitswater": ['F IH1 T S W AO2 T ER0'],
  "fitt": ['F IH1 T'],
  "fitted": ['F IH1 T AH0 D', 'F IH1 T IH0 D'],
  "fitter": ['F IH1 T ER0'],
  "fitterer": ['F IH1 T ER0 ER0'],
  "fittest": ['F IH1 T AH0 S T'],
  "fitting": ['F IH1 T IH0 NG'],
  "fittingly": ['F IH1 T IH0 NG L IY0'],
  "fittings": ['F IH1 T IH0 NG Z'],
  "fittipaldi": ['F IH0 T IH0 P AA1 L D IY0'],
  "fitton": ['F IH1 T AH0 N'],
  "fittro": ['F IH1 T R OW0'],
  "fitts": ['F IH1 T S'],
  "fitz": ['F IH1 T S'],
  "fitzco": ['F IH1 T S K OW0'],
  "fitzer": ['F IH1 T S ER0'],
  "fitzgerald": ['F IH0 T S JH EH1 R AH0 L D'],
  "fitzgerald's": ['F IH0 T S JH EH1 R AH0 L D Z'],
  "fitzgibbon": ['F IH2 T S JH IH1 B AH0 N'],
  "fitzgibbons": ['F IH2 T S JH IH1 B AH0 N Z'],
  "fitzharris": ['F IH0 T S HH AE1 R IH0 S'],
  "fitzhenry": ['F IH0 T S HH EH1 N ER0 IY0', 'F IH0 T S HH EH1 N R IY0'],
  "fitzhugh": ['F IH0 T S HH Y UW1'],
  "fitzmaurice": ['F IH0 T S M AO1 R IH0 S'],
  "fitzmorris": ['F IH0 T S M AO1 R IH0 S'],
  "fitzner": ['F IH1 T S N ER0'],
  "fitzpatrick": ['F IH2 T S P AE1 T R IH0 K'],
  "fitzroy": ['F IH1 T S R OY2'],
  "fitzsimmons": ['F IH0 T S IH1 M AH0 N Z'],
  "fitzsimons": ['F IH0 T S IH1 M AH0 N Z'],
  "fitzwater": ['F IH1 T S W AO2 T ER0'],
  "fitzwater's": ['F IH1 T S W AO2 T ER0 Z'],
  "fitzwilliam": ['F IH0 T S W IH1 L Y AH0 M'],
  "fiumara": ['F IY2 UW0 M AA1 R AH0'],
  "five": ['F AY1 V'],
  "five's": ['F AY1 V Z'],
  "five-day": ['F AY1 V D EY2'],
  "five-year": ['F AY1 V Y IH2 R'],
  "fiveash": ['F AY1 V AE2 SH'],
  "fivecoat": ['F AY1 V K OW2 T'],
  "fivefold": ['F AY1 V F OW2 L D'],
  "fives": ['F AY1 V Z'],
  "fix": ['F IH1 K S'],
  "fixable": ['F IH1 K S AH0 B AH0 L'],
  "fixate": ['F IH1 K S EY2 T'],
  "fixated": ['F IH1 K S EY2 T IH0 D'],
  "fixates": ['F IH1 K S EY2 T S'],
  "fixation": ['F IH0 K S EY1 SH AH0 N'],
  "fixative": ['F IH1 K S AH0 T IH0 V'],
  "fixatives": ['F IH1 K S AH0 T IH0 V Z'],
  "fixed": ['F IH1 K S T'],
  "fixer": ['F IH1 K S ER0'],
  "fixes": ['F IH1 K S IH0 Z'],
  "fixing": ['F IH1 K S IH0 NG'],
  "fixings": ['F IH1 K S IH0 NG Z'],
  "fixler": ['F IH1 K S L ER0'],
  "fixmer": ['F IH1 K S M ER0'],
  "fixture": ['F IH1 K S CH ER0'],
  "fixtures": ['F IH1 K S CH ER0 Z'],
  "fizer": ['F AY1 Z ER0'],
  "fizz": ['F IH1 Z'],
  "fizzle": ['F IH1 Z AH0 L'],
  "fizzled": ['F IH1 Z AH0 L D'],
  "fizzles": ['F IH1 Z AH0 L Z'],
  "fizzling": ['F IH1 Z AH0 L IH0 NG', 'F IH1 Z L IH0 NG'],
  "fjeld": ['F Y EH1 L D'],
  "fjelstad": ['F Y EH1 L S T AH0 D'],
  "fjord": ['F Y AO1 R D'],
  "fjords": ['F Y AO1 R D Z'],
  "flab": ['F L AE1 B'],
  "flabbergast": ['F L AE1 B ER0 G AE2 S T'],
  "flabbergasted": ['F L AE1 B ER0 G AE2 S T IH0 D'],
  "flabby": ['F L AE1 B IY0'],
  "flaccid": ['F L AE1 K S IH0 D', 'F L AE1 K IH0 D'],
  "flach": ['F L AE1 CH'],
  "flack": ['F L AE1 K'],
  "flad": ['F L AE1 D'],
  "flag": ['F L AE1 G'],
  "flagday": ['F L AE1 G D EY2'],
  "flagella": ['F L AH0 JH EH1 L AH0'],
  "flagellate": ['F L AE1 JH AH0 L EY2 T'],
  "flagellated": ['F L AE1 JH AH0 L EY2 T AH0 D'],
  "flagellum": ['F L AH0 JH EH1 L AH0 M'],
  "flageolet": ['F L AE2 JH AH0 L EH1 T'],
  "flagg": ['F L AE1 G'],
  "flagged": ['F L AE1 G D'],
  "flagging": ['F L AE1 G IH0 NG'],
  "flagler": ['F L AE1 G L ER0'],
  "flagman": ['F L AE1 G M AE2 N'],
  "flagmen": ['F L AE1 G M EH2 N'],
  "flagofficer": ['F L AE1 G AO0 F AH0 S ER0'],
  "flagofficers": ['F L AE1 G AO0 F AH0 S ER0 Z'],
  "flagpole": ['F L AE1 G P OW2 L'],
  "flagpoles": ['F L AE1 G P OW2 L Z'],
  "flagrant": ['F L EY1 G R AH0 N T'],
  "flagrantly": ['F L EY1 G R AH0 N T L IY0'],
  "flags": ['F L AE1 G Z'],
  "flagship": ['F L AE1 G SH IH2 P'],
  "flagships": ['F L AE1 G SH IH2 P S'],
  "flagstaff": ['F L AE1 G S T AE2 F'],
  "flagstaffs": ['F L AE1 G S T AE2 F S'],
  "flagstar": ['F L AE1 G S T AA2 R'],
  "flagstone": ['F L AE1 G S T OW2 N'],
  "flagstones": ['F L AE1 G S T OW2 N Z'],
  "flaharty": ['F L EH1 R T IY0'],
  "flaherty": ['F L EH1 R T IY0'],
  "flahive": ['F L AE1 HH IH0 V'],
  "flaig": ['F L EY1 G'],
  "flail": ['F L EY1 L'],
  "flailing": ['F L EY1 L IH0 NG'],
  "flaim": ['F L EY1 M'],
  "flair": ['F L EH1 R'],
  "flak": ['F L AE1 K'],
  "flake": ['F L EY1 K'],
  "flakes": ['F L EY1 K S'],
  "flaking": ['F L EY1 K IH0 NG'],
  "flaky": ['F L EY1 K IY0'],
  "flam": ['F L AE1 M'],
  "flamboyance": ['F L AE0 M B OY1 AH0 N S'],
  "flamboyant": ['F L AE0 M B OY1 AH0 N T'],
  "flamboyantly": ['F L AE0 M B OY1 AH0 N T L IY0'],
  "flame": ['F L EY1 M'],
  "flamed": ['F L EY1 M D'],
  "flamemaster": ['F L EY1 M AE2 S T ER0'],
  "flamenco": ['F L AH0 M EH1 NG K OW2'],
  "flamer": ['F L EY1 M ER0'],
  "flames": ['F L EY1 M Z'],
  "flaming": ['F L EY1 M IH0 NG'],
  "flamingo": ['F L AH0 M IH1 NG G OW0'],
  "flamingos": ['F L AH0 M IH1 NG G OW0 Z'],
  "flaminian": ['F L AH0 M IH1 N IY0 AH0 N'],
  "flamm": ['F L AE1 M'],
  "flammability": ['F L AE2 M AH0 B IH1 L IH0 T IY0'],
  "flammable": ['F L AE1 M AH0 B AH0 L'],
  "flammang": ['F L AE1 M AH0 NG'],
  "flammer": ['F L AE1 M ER0'],
  "flammia": ['F L AE1 M IY0 AH0'],
  "flamson": ['F L AE1 M S AH0 N'],
  "flan": ['F L AE1 N'],
  "flanagan": ['F L AE1 N AH0 G AH0 N'],
  "flanagin": ['F L AE1 N AH0 G IH0 N'],
  "flanary": ['F L AE1 N ER0 IY0'],
  "flanders": ['F L AE1 N D ER0 Z'],
  "flanery": ['F L EY1 N ER0 IY0'],
  "flange": ['F L AE1 N JH'],
  "flanges": ['F L AE1 N JH AH0 Z'],
  "flanigan": ['F L AE1 N IH0 G AH0 N'],
  "flanigan's": ['F L AE1 N IH0 G AH0 N Z'],
  "flank": ['F L AE1 NG K'],
  "flanked": ['F L AE1 NG K T'],
  "flanking": ['F L AE1 NG K IH0 NG'],
  "flanks": ['F L AE1 NG K S'],
  "flann": ['F L AE1 N'],
  "flanna": ['F L AE1 N AH0'],
  "flannagan": ['F L AE1 N AH0 G AH0 N'],
  "flannel": ['F L AE1 N AH0 L'],
  "flanneled": ['F L AE1 N AH0 L D'],
  "flannels": ['F L AE1 N AH0 L Z'],
  "flannery": ['F L AE1 N ER0 IY0'],
  "flannigan": ['F L AE1 N IH0 G AH0 N'],
  "flansburg": ['F L AE1 N S B ER0 G'],
  "flap": ['F L AE1 P'],
  "flapdoodle": ['F L AE1 P D UW2 D AH0 L'],
  "flapjack": ['F L AE1 P JH AE2 K'],
  "flapjacks": ['F L AE1 P JH AE2 K S'],
  "flaplike": ['F L AE1 P L AY2 K'],
  "flapped": ['F L AE1 P T'],
  "flapper": ['F L AE1 P ER0'],
  "flappers": ['F L AE1 P ER0 Z'],
  "flapping": ['F L AE1 P IH0 NG'],
  "flaps": ['F L AE1 P S'],
  "flare": ['F L EH1 R'],
  "flared": ['F L EH1 R D'],
  "flares": ['F L EH1 R Z'],
  "flaring": ['F L EH1 R IH0 NG'],
  "flaringly": ['F L EH1 R IH0 NG L IY0'],
  "flasch": ['F L AE1 SH'],
  "flash": ['F L AE1 SH'],
  "flash's": ['F L AE1 SH IH0 Z'],
  "flashback": ['F L AE1 SH B AE2 K'],
  "flashbacks": ['F L AE1 SH B AE2 K S'],
  "flashbulb": ['F L AE1 SH B AH0 L B'],
  "flashbulbs": ['F L AE1 SH B AH0 L B Z'],
  "flashcard": ['F L AE1 SH K AA2 R D'],
  "flashcards": ['F L AE1 SH K AA2 R D Z'],
  "flashdance": ['F L AE1 SH D AE2 N S'],
  "flashdances": ['F L AE1 SH D AE2 N S IH0 Z'],
  "flashed": ['F L AE1 SH T'],
  "flasher": ['F L AE1 SH ER0'],
  "flasher's": ['F L AE1 SH ER0 Z'],
  "flashers": ['F L AE1 SH ER0 Z'],
  "flashes": ['F L AE1 SH IH0 Z'],
  "flashgun": ['F L AE1 SH G AH0 N'],
  "flashguns": ['F L AE1 SH G AH0 N Z'],
  "flashier": ['F L AE1 SH IY0 ER0'],
  "flashiest": ['F L AE1 SH IY0 AH0 S T'],
  "flashing": ['F L AE1 SH IH0 NG'],
  "flashlight": ['F L AE1 SH L AY2 T'],
  "flashlights": ['F L AE1 SH L AY2 T S'],
  "flashpoint": ['F L AE1 SH P OY2 N T'],
  "flashpoints": ['F L AE1 SH P OY2 N T S'],
  "flashy": ['F L AE1 SH IY0'],
  "flask": ['F L AE1 S K'],
  "flasks": ['F L AE1 S K S'],
  "flat": ['F L AE1 T'],
  "flatau": ['F L AE1 T AW0'],
  "flatbed": ['F L AE1 T B EH2 D'],
  "flatboat": ['F L AE1 T B OW2 T'],
  "flatbush": ['F L AE1 T B UH2 SH'],
  "flaten": ['F L AE1 T AH0 N'],
  "flater": ['F L EY1 T ER0'],
  "flatfish": ['F L AE1 T F IH2 SH'],
  "flatfishes": ['F L AE1 T F IH2 SH IH0 Z'],
  "flath": ['F L AE1 TH'],
  "flathead": ['F L AE1 T HH EH2 D'],
  "flatheads": ['F L AE1 T HH EH2 D Z'],
  "flathers": ['F L AE1 DH ER0 Z'],
  "flatland": ['F L AE1 T L AE2 N D'],
  "flatlands": ['F L AE1 T L AE0 N D Z'],
  "flatley": ['F L AE1 T L IY0'],
  "flatly": ['F L AE1 T L IY0'],
  "flatness": ['F L AE1 T N AH0 S'],
  "flato": ['F L AE1 T OW0'],
  "flats": ['F L AE1 T S'],
  "flatt": ['F L AE1 T'],
  "flatten": ['F L AE1 T AH0 N'],
  "flattened": ['F L AE1 T AH0 N D'],
  "flattening": ['F L AE1 T AH0 N IH0 NG', 'F L AE1 T N IH0 NG'],
  "flatter": ['F L AE1 T ER0'],
  "flattered": ['F L AE1 T ER0 D'],
  "flattering": ['F L AE1 T ER0 IH0 NG'],
  "flattery": ['F L AE1 T ER0 IY0'],
  "flattish": ['F L AE1 T IH0 SH'],
  "flatulent": ['F L AE1 CH AH0 L AH0 N T'],
  "flatware": ['F L AE1 T W EH2 R'],
  "flatworm": ['F L AE1 T W ER0 M'],
  "flaubert": ['F L AW1 B ER0 T'],
  "flaugh": ['F L AO1'],
  "flaugher": ['F L AO1 ER0'],
  "flaum": ['F L AO1 M'],
  "flaunt": ['F L AO1 N T'],
  "flaunted": ['F L AO1 N T IH0 D'],
  "flaunting": ['F L AO1 N T IH0 NG'],
  "flaunts": ['F L AO1 N T S'],
  "flavell": ['F L AE1 V AH0 L'],
  "flavia": ['F L AE1 V IY0 AH0'],
  "flavier": ['F L EY1 V Y ER0', 'F L AE1 V Y ER0'],
  "flavin": ['F L EY1 V IH0 N'],
  "flavio": ['F L AA1 V IY0 OW0'],
  "flavius": ['F L EY1 V IY0 IH0 S'],
  "flavor": ['F L EY1 V ER0'],
  "flavored": ['F L EY1 V ER0 D'],
  "flavorful": ['F L EY1 V ER0 F AH0 L'],
  "flavoring": ['F L EY1 V ER0 IH0 NG'],
  "flavorings": ['F L EY1 V ER0 IH0 NG Z'],
  "flavorist": ['F L EY1 V ER0 IH0 S T'],
  "flavorists": ['F L EY1 V ER0 IH0 S T S', 'F L EY1 V ER0 IH0 S S', 'F L EY1 V ER0 IH0 S'],
  "flavorless": ['F L EY1 V ER2 L EH0 S'],
  "flavors": ['F L EY1 V ER0 Z'],
  "flaw": ['F L AO1'],
  "flawed": ['F L AO1 D'],
  "flawless": ['F L AO1 L AH0 S'],
  "flawlessly": ['F L AO1 L AH0 S L IY0'],
  "flawn": ['F L AO1 N'],
  "flaws": ['F L AO1 Z'],
  "flax": ['F L AE1 K S'],
  "flaxman": ['F L AE1 K S M AH0 N'],
  "flaxseed": ['F L AE1 K S IY2 D'],
  "flay": ['F L EY1'],
  "flayed": ['F L EY1 D'],
  "flea": ['F L IY1'],
  "fleagle": ['F L IY1 G AH0 L'],
  "fleas": ['F L IY1 Z'],
  "fleck": ['F L EH1 K'],
  "fleckenstein": ['F L EH1 K AH0 N S T AY2 N', 'F L EH1 K AH0 N S T IY2 N'],
  "flecks": ['F L EH1 K S'],
  "fled": ['F L EH1 D'],
  "fledermaus": ['F L EH1 D ER0 M AW0 S'],
  "fledge": ['F L EH1 JH'],
  "fledged": ['F L EH1 JH D'],
  "fledging": ['F L EH1 JH IH0 NG'],
  "fledgling": ['F L EH1 JH L IH0 NG'],
  "flee": ['F L IY1'],
  "fleece": ['F L IY1 S'],
  "fleeced": ['F L IY1 S T'],
  "fleeces": ['F L IY1 S IH0 Z'],
  "fleecing": ['F L IY1 S IH0 NG'],
  "fleecy": ['F L IY1 S IY0'],
  "fleeger": ['F L IY1 G ER0'],
  "fleeing": ['F L IY1 IH0 NG'],
  "fleek": ['F L IY1 K'],
  "fleeman": ['F L IY1 M AH0 N'],
  "fleener": ['F L IY1 N ER0'],
  "fleenor": ['F L IY1 N ER0'],
  "fleer": ['F L IH1 R'],
  "flees": ['F L IY1 Z'],
  "fleet": ['F L IY1 T'],
  "fleet's": ['F L IY1 T S'],
  "fleeting": ['F L IY1 T IH0 NG'],
  "fleetingly": ['F L IY1 T IH0 NG L IY0'],
  "fleets": ['F L IY1 T S'],
  "fleetwood": ['F L IY1 T W UH2 D'],
  "flegal": ['F L IY1 G AH0 L'],
  "flegel": ['F L EH1 G AH0 L'],
  "fleharty": ['F L EH1 HH AA0 R T IY0'],
  "fleig": ['F L IY1 G'],
  "fleisch": ['F L AY1 SH'],
  "fleischauer": ['F L AY1 SH AW0 ER0'],
  "fleischer": ['F L AY1 SH ER0'],
  "fleischhacker": ['F L AY1 SH HH AH0 K ER0'],
  "fleischman": ['F L AY1 SH M AH0 N'],
  "fleischmann": ['F L AY1 SH M AH0 N'],
  "fleisher": ['F L AY1 SH ER0'],
  "fleishman": ['F L AY1 SH M AH0 N'],
  "fleiss": ['F L AY1 SH', 'F L AY1 S'],
  "fleiss'": ['F L AY1 SH', 'F L AY1 S'],
  "fleiss's": ['F L AY1 SH IH0 Z', 'F L AY1 S IH0 Z'],
  "fleissner": ['F L AY1 S N ER0'],
  "fleitas": ['F L AY1 T AH0 S'],
  "fleming": ['F L EH1 M IH0 NG'],
  "fleming's": ['F L EH1 M IH0 NG Z'],
  "flemings": ['F L EH1 M IH0 NG Z'],
  "flemington": ['F L EH1 M IH0 NG T AH0 N'],
  "flemish": ['F L EH1 M IH0 SH'],
  "flemister": ['F L EH1 M IH0 S T ER0'],
  "flemmer": ['F L EH1 M ER0'],
  "flemming": ['F L EH1 M IH0 NG'],
  "flemons": ['F L EH1 M AH0 N Z'],
  "flener": ['F L IY1 N ER0'],
  "flenner": ['F L EH1 N ER0'],
  "flenniken": ['F L EH1 N IH0 K AH0 N'],
  "flerfu": ['F L ER1 F UW0'],
  "flesch": ['F L EH1 SH'],
  "flesh": ['F L EH1 SH'],
  "flesh-pots": ['F L EH1 SH P AA2 T S'],
  "fleshed": ['F L EH1 SH T'],
  "flesher": ['F L EH1 SH ER0'],
  "fleshman": ['F L EH1 SH M AH0 N'],
  "fleshy": ['F L EH1 SH IY0'],
  "flesner": ['F L EH1 S N ER0'],
  "flessner": ['F L EH1 S N ER0'],
  "fleta": ['F L IY1 T AH0'],
  "fletchall": ['F L EH1 CH AH0 L'],
  "fletcher": ['F L EH1 CH ER0'],
  "flett": ['F L EH1 T'],
  "fleur": ['F L ER1'],
  "fleurette": ['F L ER2 EH1 T'],
  "fleuri": ['F L ER1 R IY1', 'F L UH1 R IY1'],
  "fleury": ['F L UH1 R IY0'],
  "flew": ['F L UW1'],
  "flewellen": ['F L UW2 EH1 L AH0 N'],
  "flewelling": ['F L UW2 EH1 L IH0 NG'],
  "flex": ['F L EH1 K S'],
  "flexed": ['F L EH1 K S T'],
  "flexer": ['F L EH1 K S ER0'],
  "flexes": ['F L EH1 K S IH0 Z'],
  "flexi": ['F L EH1 K S IY0'],
  "flexibility": ['F L EH2 K S AH0 B IH1 L AH0 T IY0'],
  "flexible": ['F L EH1 K S AH0 B AH0 L'],
  "flexibly": ['F L EH1 K S AH0 B L IY0'],
  "flexing": ['F L EH1 K S IH0 NG'],
  "flexion": ['F L EH1 K SH AH0 N'],
  "flexnor": ['F L EH1 K S N AO0 R'],
  "flextime": ['F L EH1 K S T AY2 M'],
  "flextronic": ['F L EH2 K S T R AA1 N IH0 K'],
  "flextronics": ['F L EH2 K S T R AA1 N IH0 K S'],
  "flicek": ['F L IH1 CH EH0 K'],
  "flick": ['F L IH1 K'],
  "flicker": ['F L IH1 K ER0'],
  "flickered": ['F L IH1 K ER0 D'],
  "flickering": ['F L IH1 K ER0 IH0 NG'],
  "flickers": ['F L IH1 K ER0 Z'],
  "flicking": ['F L IH1 K IH0 NG'],
  "flickinger": ['F L IH1 K IH0 NG ER0'],
  "flickr": ['F L IH1 K ER0'],
  "flicks": ['F L IH1 K S'],
  "flied": ['F L AY1 D'],
  "fliegel": ['F L IY1 G AH0 L'],
  "flier": ['F L AY1 ER0'],
  "fliers": ['F L AY1 ER0 Z'],
  "flies": ['F L AY1 Z'],
  "flight": ['F L AY1 T'],
  "flight's": ['F L AY1 T S'],
  "flightier": ['F L AY1 T IY0 ER0'],
  "flightiest": ['F L AY1 T IY0 AH0 S T'],
  "flightless": ['F L AY1 T L AH0 S'],
  "flights": ['F L AY1 T S'],
  "flightsafety": ['F L AY1 T S EY1 F T IY0'],
  "flighty": ['F L AY1 T IY0'],
  "flim": ['F L IH1 M'],
  "flimflam": ['F L IH1 M F L AE2 M'],
  "flimsiest": ['F L IH1 M Z IY0 AH0 S T'],
  "flimsy": ['F L IH1 M Z IY0'],
  "flinch": ['F L IH1 N CH'],
  "flinchbaugh": ['F L IH1 N CH B AO2'],
  "flinched": ['F L IH1 N CH T'],
  "flinching": ['F L IH1 N CH IH0 NG'],
  "flinchum": ['F L IH1 N K AH0 M'],
  "flinders": ['F L IH1 N D ER0 Z'],
  "fling": ['F L IH1 NG'],
  "flinging": ['F L IH1 NG IH0 NG'],
  "flings": ['F L IH1 NG Z'],
  "flink": ['F L IH1 NG K'],
  "flinn": ['F L IH1 N'],
  "flinner": ['F L IH1 N ER0'],
  "flint": ['F L IH1 N T'],
  "flint's": ['F L IH1 N T S'],
  "flintlock": ['F L IH1 N T L AA2 K'],
  "flintlocks": ['F L IH1 N T L AA2 K S'],
  "flintoff": ['F L IH1 N T AO0 F'],
  "flints": ['F L IH1 N T S'],
  "flintstone": ['F L IH1 N T S T OW1 N'],
  "flintstones": ['F L IH1 N T S T OW1 N Z'],
  "flinty": ['F L IH1 N T IY0'],
  "flip": ['F L IH1 P'],
  "flippant": ['F L IH1 P AH0 N T'],
  "flipped": ['F L IH1 P T'],
  "flippen": ['F L IH1 P AH0 N'],
  "flipper": ['F L IH1 P ER0'],
  "flippers": ['F L IH1 P ER0 Z'],
  "flippin": ['F L IH1 P IH0 N'],
  "flipping": ['F L IH1 P IH0 NG'],
  "flippo": ['F L IH1 P OW0'],
  "flips": ['F L IH1 P S'],
  "flirt": ['F L ER1 T'],
  "flirtation": ['F L ER0 T EY1 SH AH0 N'],
  "flirtations": ['F L ER0 T EY1 SH AH0 N Z'],
  "flirtatious": ['F L ER0 T EY1 SH AH0 S'],
  "flirted": ['F L ER1 T IH0 D'],
  "flirting": ['F L ER1 T IH0 NG'],
  "flirts": ['F L ER1 T S'],
  "flis": ['F L IH1 S'],
  "fliss": ['F L IH1 S'],
  "flit": ['F L IH1 T'],
  "flitting": ['F L IH1 T IH0 NG'],
  "flo": ['F L OW1'],
  "float": ['F L OW1 T'],
  "floated": ['F L OW1 T AH0 D', 'F L OW1 T IH0 D'],
  "floater": ['F L OW1 T ER0'],
  "floaters": ['F L OW1 T ER0 Z'],
  "floating": ['F L OW1 T IH0 NG'],
  "floats": ['F L OW1 T S'],
  "floc": ['F L AA1 K'],
  "flock": ['F L AA1 K'],
  "flocked": ['F L AA1 K T'],
  "flockhart": ['F L AA1 K HH AA2 R T'],
  "flocking": ['F L AA1 K IH0 NG'],
  "flocks": ['F L AA1 K S'],
  "flodin": ['F L OW1 D IH0 N'],
  "floe": ['F L OW1'],
  "floersheim": ['F L AO1 R SH AY2 M'],
  "flog": ['F L AA1 G'],
  "flogging": ['F L AA1 G IH0 NG'],
  "flohr": ['F L AA1 R'],
  "floirenda": ['F L OY2 R EH1 N D AH0'],
  "flom": ['F L AA1 M'],
  "flonorial": ['F L AA2 N AO1 R IY0 AH0 L'],
  "flood": ['F L AH1 D'],
  "flooded": ['F L AH1 D AH0 D', 'F L AH1 D IH0 D'],
  "floodgate": ['F L AH1 D G EY2 T'],
  "floodgates": ['F L AH1 D G EY2 T S'],
  "flooding": ['F L AH1 D IH0 NG'],
  "floodlight": ['F L AH1 D L AY2 T'],
  "floodlights": ['F L AH1 D L AY2 T S'],
  "floodplain": ['F L AH1 D P L EY2 N'],
  "floods": ['F L AH1 D Z'],
  "floodwater": ['F L AH1 D W AO2 T ER0'],
  "floodwaters": ['F L AH1 D W AO2 T ER0 Z'],
  "flook": ['F L UH1 K'],
  "floor": ['F L AO1 R'],
  "floorboard": ['F L AO1 R B AO2 R D'],
  "floorboards": ['F L AO1 R B AO2 R D Z'],
  "floored": ['F L AO1 R D'],
  "flooring": ['F L AO1 R IH0 NG'],
  "floors": ['F L AO1 R Z'],
  "flop": ['F L AA1 P'],
  "flopped": ['F L AA1 P T'],
  "flopping": ['F L AA1 P IH0 NG'],
  "floppy": ['F L AA1 P IY0'],
  "flops": ['F L AA1 P S'],
  "floptical": ['F L AA1 P T IH0 K AH0 L'],
  "flor": ['F L AO1 R'],
  "flora": ['F L AO1 R AH0'],
  "florafax": ['F L AO1 R AH0 F AE2 K S'],
  "floral": ['F L AO1 R AH0 L'],
  "florally": ['F L AO1 R AH0 L IY0'],
  "florals": ['F L AO1 R AH0 L Z'],
  "florance": ['F L AO1 R AH0 N S'],
  "flore": ['F L AO1 R'],
  "florea": ['F L AO1 R IY0 AH0'],
  "florek": ['F L AO1 R IH0 K'],
  "floren": ['F L AO1 R AH0 N'],
  "florence": ['F L AO1 R AH0 N S'],
  "florence's": ['F L AO1 R AH0 N S IH0 Z'],
  "florentina": ['F L AO2 R EH0 N T IY1 N AA0'],
  "florentine": ['F L AO1 R AH0 N T IY2 N'],
  "florentino": ['F L AO0 R EH0 N T IY1 N OW0'],
  "florer": ['F L AO1 R ER0'],
  "flores": ['F L AO1 R EH2 Z'],
  "florescue": ['F L AO1 R AH0 S K Y UW0'],
  "floresheim": ['F L AO1 R SH AY2 M'],
  "florey": ['F L AO1 R IY0'],
  "florez": ['F L AO0 R EH1 Z'],
  "flori": ['F L AO1 R IY0'],
  "floria": ['F L AO1 R IY0 AH0'],
  "florian": ['F L AO1 R IY0 AH0 N'],
  "florid": ['F L AO1 R AH0 D'],
  "florida": ['F L AO1 R AH0 D AH0', 'F L AO1 R IH0 D AH0', 'F L AA1 R AH0 D AH0', 'F L AA1 R IH0 D AH0'],
  "florida's": ['F L AO1 R IH0 D AH0 Z', 'F L AA1 R IH0 D AH0 Z', 'F L AO1 R AH0 D AH0 Z'],
  "floridabanc": ['F L AO1 R AH0 D AH0 B AE2 NG K'],
  "floridian": ['F L AO0 R IH1 D IY0 AH0 N'],
  "floridians": ['F L AO0 R IH1 D IY0 AH0 N Z'],
  "florin": ['F L AO1 R IH0 N'],
  "florinda": ['F L AO0 R IY1 N D AH0'],
  "florine": ['F L AO0 R IY1 N'],
  "florini": ['F L AO0 R IY1 N IY2'],
  "florio": ['F L AO1 R IY0 OW0'],
  "florio's": ['F L AO1 R IY0 OW0 Z'],
  "floris": ['F L AO1 R IH0 S'],
  "florist": ['F L AA1 R IH0 S T', 'F L AO1 R IH0 S T'],
  "florists": ['F L AO1 R IH0 S T S'],
  "floro": ['F L AO1 R OW0'],
  "florrie": ['F L AO1 R IY0'],
  "florry": ['F L AO1 R IY0'],
  "florsheim": ['F L AO1 R SH AY2 M'],
  "flory": ['F L AO1 R IY0'],
  "floss": ['F L AA1 S'],
  "flossie": ['F L AO1 S IY0'],
  "flotation": ['F L OW0 T EY1 SH AH0 N'],
  "flotilla": ['F L OW0 T IH1 L AH0'],
  "flotow": ['F L AA1 T AW0'],
  "flotsam": ['F L AA1 T S AH0 M'],
  "flott": ['F L AA1 T'],
  "flotta": ['F L AA1 T AH0'],
  "flounce": ['F L AW1 N S'],
  "flounces": ['F L AW1 N S IH0 Z'],
  "flounder": ['F L AW1 N D ER0'],
  "floundered": ['F L AW1 N D ER0 D'],
  "floundering": ['F L AW1 N D ER0 IH0 NG'],
  "flounders": ['F L AW1 N D ER0 Z'],
  "flour": ['F L AW1 ER0', 'F L AW1 R'],
  "flourish": ['F L ER1 IH0 SH'],
  "flourished": ['F L ER1 IH0 SH T'],
  "flourishes": ['F L ER1 IH0 SH AH0 Z', 'F L ER1 IH0 SH IH0 Z'],
  "flourishing": ['F L ER1 IH0 SH IH0 NG'],
  "flournoy": ['F L UH0 R N OY1'],
  "flours": ['F L AW1 ER0 Z'],
  "flout": ['F L AW1 T'],
  "flouted": ['F L AW1 T IH0 D'],
  "flouting": ['F L AW1 T IH0 NG'],
  "flouts": ['F L AW1 T S'],
  "flow": ['F L OW1'],
  "flowchart": ['F L OW1 CH AA2 R T'],
  "flowcharts": ['F L OW1 CH AA2 R T S'],
  "flowe": ['F L OW1'],
  "flowed": ['F L OW1 D'],
  "flower": ['F L AW1 ER0'],
  "flowered": ['F L AW1 ER0 D'],
  "flowering": ['F L AW1 ER0 IH0 NG'],
  "flowerpot": ['F L AW1 ER0 P AA2 T'],
  "flowers": ['F L AW1 ER0 Z'],
  "flowers'": ['F L AW1 ER0 Z'],
  "flowery": ['F L AW1 ER0 IY0'],
  "flowing": ['F L OW1 IH0 NG'],
  "flown": ['F L OW1 N'],
  "flows": ['F L OW1 Z'],
  "flowton": ['F L OW1 T AH0 N'],
  "floy": ['F L OY1'],
  "floyd": ['F L OY1 D'],
  "flu": ['F L UW1'],
  "flubs": ['F L AH1 B Z'],
  "fluck": ['F L AH1 K'],
  "fluctuate": ['F L AH1 K CH AH0 W EY2 T'],
  "fluctuated": ['F L AH1 K CH AH0 W EY2 T IH0 D'],
  "fluctuates": ['F L AH1 K CH UW0 EY2 T S'],
  "fluctuating": ['F L AH1 K CH AH0 W EY2 T IH0 NG'],
  "fluctuation": ['F L AH2 K CH UW0 EY1 SH AH0 N'],
  "fluctuations": ['F L AH2 K CH UW0 EY1 SH AH0 N Z'],
  "flud": ['F L AH1 D'],
  "fludd": ['F L AH1 D'],
  "flue": ['F L UW1'],
  "fluegel": ['F L UH1 G AH0 L'],
  "fluegge": ['F L UW1 G'],
  "fluency": ['F L UW1 AH0 N S IY0'],
  "fluent": ['F L UW1 AH0 N T'],
  "fluently": ['F L UW1 AH0 N T L IY0'],
  "fluet": ['F L UW1 T'],
  "fluff": ['F L AH1 F'],
  "fluffed": ['F L AH1 F T'],
  "fluffier": ['F L AH1 F IY0 ER0'],
  "fluffiest": ['F L AH1 F IY0 AH0 S T'],
  "fluffs": ['F L AH1 F S'],
  "fluffy": ['F L AH1 F IY0'],
  "fluharty": ['F L AH1 ER0 T IY0'],
  "fluhr": ['F L ER1', 'F L UH1 R'],
  "fluid": ['F L UW1 AH0 D', 'F L UW1 IH0 D'],
  "fluidity": ['F L UW0 IH1 D AH0 T IY0'],
  "fluids": ['F L UW1 AH0 D Z', 'F L UW1 IH0 D Z'],
  "fluitt": ['F L UW1 AH0 T'],
  "fluke": ['F L UW1 K'],
  "fluker": ['F L UW1 K ER0'],
  "flukes": ['F L UW1 K S'],
  "flulike": ['F L UW1 L AY2 K'],
  "flume": ['F L UW1 M'],
  "flumenbaum": ['F L UW1 M AH0 N B AW2 M'],
  "flumes": ['F L UW1 M Z'],
  "flummox": ['F L AH0 M AO1 K S'],
  "flummoxed": ['F L AH0 M AO1 K S T'],
  "flung": ['F L AH1 NG'],
  "flunk": ['F L AH1 NG K'],
  "flunked": ['F L AH1 NG K T'],
  "flunking": ['F L AH1 NG K IH0 NG'],
  "flunks": ['F L AH1 NG K S'],
  "fluor": ['F L UW1 ER0'],
  "fluor's": ['F L UW1 ER0 Z'],
  "fluoresce": ['F L UH2 R EH1 S', 'F L AO2 R EH1 S'],
  "fluorescence": ['F L UH2 R EH1 S AH0 N S'],
  "fluorescent": ['F L UH2 R EH1 S AH0 N T', 'F L AO2 R EH1 S AH0 N T'],
  "fluorescently": ['F L UH2 R EH1 S AH0 N T L IY0', 'F L AO2 R EH1 S AH0 N T L IY0'],
  "fluorescents": ['F L UH2 R EH1 S AH0 N T S', 'F L AO2 R EH1 S AH0 N T S'],
  "fluoridation": ['F L UH2 R AH0 D EY1 SH AH0 N', 'F L AO2 R AH0 D EY1 SH AH0 N'],
  "fluoride": ['F L UH1 R AY2 D', 'F L AO1 R AY2 D'],
  "fluorides": ['F L UH1 R AY2 D Z', 'F L AO1 R AY2 D Z'],
  "fluorine": ['F L UH1 R IY2 N', 'F L AO1 R IY2 N'],
  "fluorite": ['F L UH1 R AY2 T', 'F L AO1 R AY2 T'],
  "fluorocarbon": ['F L UH2 R OW0 K AA1 R B AH0 N', 'F L AO2 R OW0 K AA1 R B AH0 N'],
  "fluorocarbons": ['F L UH2 R OW0 K AA1 R B AH0 N Z', 'F L AO2 R OW0 K AA1 R B AH0 N Z'],
  "fluorometer": ['F L UH2 R AA1 M AH0 T ER0', 'F L AO2 R AA1 M AH0 T ER0'],
  "fluoroscopy": ['F L UH2 R AA1 S K AH0 P IY0', 'F L AO2 R AA1 S K AH0 P IY0'],
  "fluorspar": ['F L UH1 R S P AA2 R', 'F L AO1 R S P AA2 R'],
  "flurried": ['F L ER1 IY0 D'],
  "flurries": ['F L ER1 IY0 Z'],
  "flurry": ['F L ER1 IY0'],
  "flury": ['F L UW1 R IY0', 'F L ER1 IY0'],
  "flus": ['F L UW1 Z'],
  "flush": ['F L AH1 SH'],
  "flushed": ['F L AH1 SH T'],
  "flushes": ['F L AH1 SH IH0 Z'],
  "flushing": ['F L AH1 SH IH0 NG'],
  "fluster": ['F L AH1 S T ER0'],
  "flustered": ['F L AH1 S T ER0 D'],
  "flustering": ['F L AH1 S T ER0 IH0 NG'],
  "flusters": ['F L AH1 S T ER0 Z'],
  "flute": ['F L UW1 T'],
  "flutes": ['F L UW1 T S'],
  "flutist": ['F L UW1 T IH0 S T', 'F L AW1 T IH0 S T'],
  "flutter": ['F L AH1 T ER0'],
  "fluttered": ['F L AH1 T ER0 D'],
  "fluttering": ['F L AH1 T ER0 IH0 NG'],
  "flutters": ['F L AH1 T ER0 Z'],
  "fluty": ['F L UW1 T IY0'],
  "fluvial": ['F L UW1 V IY0 AH0 L'],
  "flux": ['F L AH1 K S'],
  "fluxes": ['F L AH1 K S IH0 Z'],
  "fluxional": ['F L AH1 K SH AH0 N AH0 L'],
  "fly": ['F L AY1'],
  "flyby": ['F L AY1 B AY2'],
  "flycatcher": ['F L AY1 K AE2 CH ER0'],
  "flye": ['F L AY1'],
  "flyer": ['F L AY1 ER0'],
  "flyers": ['F L AY1 ER0 Z'],
  "flying": ['F L AY1 IH0 NG'],
  "flynn": ['F L IH1 N'],
  "flynt": ['F L IH1 N T'],
  "flypaper": ['F L AY1 P EY2 P ER0'],
  "flythe": ['F L AY1 DH'],
  "flytrap": ['F L AY1 T R AE2 P'],
  "flyway": ['F L AY1 W EY2'],
  "flywheel": ['F L AY1 W IY2 L'],
  "fm": ['EH1 F EH1 M'],
  "fnma": ['EH2 F EH2 N EH2 M EY1', 'F AE2 N IY2 M EY1'],
  "foal": ['F OW1 L'],
  "foale": ['F OW1 L'],
  "foale's": ['F OW1 L Z'],
  "foaling": ['F OW1 L IH0 NG'],
  "foam": ['F OW1 M'],
  "foaming": ['F OW1 M IH0 NG'],
  "foams": ['F OW1 M Z'],
  "foamy": ['F OW1 M IY0'],
  "foard": ['F AO1 R D'],
  "fob": ['F AO1 B'],
  "fobbs": ['F AA1 B Z'],
  "fobel": ['F OW1 B AH0 L'],
  "fobel's": ['F OW1 B AH0 L Z'],
  "fobes": ['F OW1 B Z'],
  "focaccia": ['F OW0 K AA1 CH IY2 AH0'],
  "focal": ['F OW1 K AH0 L'],
  "focht": ['F AA1 K T'],
  "fochtman": ['F AA1 K T M AH0 N'],
  "fockler": ['F AA1 K L ER0'],
  "focus": ['F OW1 K AH0 S', 'F OW1 K IH0 S'],
  "focused": ['F OW1 K AH0 S T', 'F OW1 K IH0 S T'],
  "focuses": ['F OW1 K AH0 S IH0 Z', 'F OW1 K IH0 S IH0 Z'],
  "focusing": ['F OW1 K AH0 S IH0 NG', 'F OW1 K IH0 S IH0 NG'],
  "focussed": ['F OW1 K AH0 S T', 'F OW1 K IH0 S T'],
  "fodder": ['F AA1 D ER0'],
  "fodera": ['F OW0 D EH1 R AH0'],
  "fodor": ['F OW1 D ER0'],
  "foe": ['F OW1'],
  "foehlich": ['F OW1 L IH0 CH'],
  "foell": ['F IY1 L'],
  "foeller": ['F OW1 L ER0'],
  "foerster": ['F AO1 R S T ER0'],
  "foertsch": ['F AO1 R CH'],
  "foes": ['F OW1 Z'],
  "fog": ['F AA1 G', 'F AO1 G'],
  "fogal": ['F OW1 JH AH0 L'],
  "fogarty": ['F AA1 G AA2 R T IY0', 'F OW1 G AA2 R T IY0'],
  "fogel": ['F OW1 G AH0 L'],
  "fogelberg": ['F OW1 G AH0 L B ER0 G'],
  "fogelberg's": ['F OW1 G AH0 L B ER0 G Z'],
  "fogelman": ['F OW1 G AH0 L M AH0 N'],
  "fogelman's": ['F OW1 G AH0 L M AH0 N Z'],
  "fogelson": ['F OW1 G AH0 L S AH0 N'],
  "fogelson's": ['F OW1 G AH0 L S AH0 N Z'],
  "fogerty": ['F AA1 JH ER0 T IY0'],
  "fogerty's": ['F AA1 JH ER0 T IY0 Z'],
  "fogg": ['F AA1 G'],
  "fogg's": ['F AA1 G Z'],
  "foggiest": ['F AA1 G IY0 AH0 S T'],
  "foggy": ['F AA1 G IY0'],
  "fogle": ['F OW1 G AH0 L'],
  "fogleman": ['F OW1 G AH0 L M AH0 N'],
  "fogler": ['F OW1 G AH0 L ER0', 'F OW1 G L ER0'],
  "foglesong": ['F AA1 G AH0 L S AO0 NG'],
  "foglia": ['F AA1 G L IY0 AH0'],
  "foglio": ['F AA1 G L IY0 OW0'],
  "fogs": ['F AO1 G Z'],
  "fogt": ['F AA1 G T'],
  "fogy": ['F OW1 G IY0'],
  "fohl": ['F OW1 L'],
  "foia": ['F OW1 Y AH0', 'EH1 F OW1 AY1 EY1'],
  "foible": ['F OY1 B AH0 L'],
  "foibles": ['F OY1 B AH0 L Z'],
  "foie": ['F OY1'],
  "foil": ['F OY1 L'],
  "foiled": ['F OY1 L D'],
  "foiles": ['F AA1 AY0 L Z'],
  "foiling": ['F OY1 L IH0 NG'],
  "foils": ['F OY1 L Z'],
  "foist": ['F OY1 S T'],
  "foisted": ['F OY1 S T IH0 D'],
  "foister": ['F OY1 S T ER0'],
  "foisy": ['F OY1 Z IY0'],
  "fojtik": ['F AA1 Y T IH0 K'],
  "fokker": ['F AA1 K ER0'],
  "fokkers": ['F AA1 K ER0 Z'],
  "folan": ['F OW1 L AH0 N'],
  "foland": ['F OW1 L AH0 N D'],
  "folck": ['F OW1 L K'],
  "fold": ['F OW1 L D'],
  "folded": ['F OW1 L D AH0 D', 'F OW1 L D IH0 D'],
  "folden": ['F OW1 L D AH0 N'],
  "folder": ['F OW1 L D ER0'],
  "folders": ['F OW1 L D ER0 Z'],
  "folding": ['F OW1 L D IH0 NG'],
  "folds": ['F OW1 L D Z'],
  "foley": ['F OW1 L IY0'],
  "foley's": ['F OW1 L IY0 Z'],
  "folger": ['F OW1 L JH ER0'],
  "folger's": ['F OW1 L JH ER0 Z'],
  "folgers": ['F OW1 L JH ER0 Z'],
  "folha": ['F OW1 L HH AH0'],
  "foliage": ['F OW1 L IH0 JH', 'F OW1 L IY0 IH0 JH'],
  "foliate": ['F OW1 L IY0 EY2 T'],
  "foliation": ['F OW2 L IY0 EY1 SH AH0 N'],
  "folic": ['F AA1 L IH0 K'],
  "folino": ['F OW0 L IY1 N OW2'],
  "folio": ['F OW1 L IY2 OW0'],
  "folk": ['F OW1 K'],
  "folker": ['F OW1 K ER0'],
  "folkers": ['F OW1 K ER0 Z'],
  "folkert": ['F OW1 K ER0 T'],
  "folkerts": ['F OW1 K ER0 T S'],
  "folkes": ['F OW1 K S'],
  "folkish": ['F OW1 K IH0 SH'],
  "folklore": ['F OW1 K L AO2 R'],
  "folkman": ['F OW1 K M AH0 N'],
  "folkrock": ['F OW1 K R AA2 K'],
  "folks": ['F OW1 K S'],
  "folks'": ['F OW1 K S'],
  "folkstone": ['F OW1 K S T OW2 N'],
  "folkstone's": ['F OW1 K S T OW2 N Z'],
  "folksy": ['F OW1 K S IY0'],
  "folktale": ['F OW1 K T EY2 L'],
  "folkway": ['F OW1 K W EY2'],
  "folkways": ['F OW1 K W EY2 Z'],
  "folland": ['F AA1 L AH0 N D'],
  "follett": ['F AA1 L IH0 T'],
  "follette": ['F AA2 L EH1 T'],
  "folley": ['F AA1 L IY0'],
  "folliard": ['F AA1 L Y ER0 D'],
  "follicle": ['F AA1 L AH0 K AH0 L', 'F AA1 L IH0 K AH0 L'],
  "follicles": ['F AA1 L IH0 K AH0 L Z'],
  "follies": ['F AA1 L IY0 Z'],
  "follin": ['F AA1 L IH0 N'],
  "follis": ['F AA1 L IH0 S'],
  "follman": ['F AA1 L M AH0 N'],
  "follmer": ['F AA1 L M ER0'],
  "follow": ['F AA1 L OW0'],
  "follow-up": ['F AA1 L OW0 AH2 P'],
  "followed": ['F AA1 L OW0 D'],
  "followell": ['F AA1 L AH0 W EH0 L'],
  "follower": ['F AA1 L OW0 ER0'],
  "followers": ['F AA1 L OW0 ER0 Z'],
  "following": ['F AA1 L OW0 IH0 NG'],
  "followings": ['F AA1 L OW0 IH0 NG Z'],
  "follows": ['F AA1 L OW0 Z'],
  "followup": ['F AA1 L OW0 AH2 P'],
  "folly": ['F AA1 L IY0'],
  "folmar": ['F OW1 L M ER0'],
  "folmer": ['F OW1 L M ER0'],
  "folse": ['F OW1 L S'],
  "folsom": ['F OW1 L S AH0 M'],
  "folson": ['F OW1 L S AH0 N'],
  "folta": ['F OW1 L T AH0'],
  "foltene": ['F OW0 L T IY1 N'],
  "folts": ['F OW1 L T S'],
  "foltz": ['F OW1 L T S'],
  "folwell": ['F OW1 L W EH2 L'],
  "folz": ['F OW1 L Z'],
  "fomby": ['F AA1 M B IY0'],
  "foment": ['F OW1 M EH0 N T'],
  "fomented": ['F OW1 M EH0 N T IH0 D'],
  "fomenting": ['F OW1 M EH0 N T IH0 NG'],
  "fomento": ['F OW2 M EH1 N T OW0'],
  "fomon": ['F OW1 M AH0 N'],
  "fomon's": ['F OW1 M AH0 N Z'],
  "fonar": ['F AA1 N ER0'],
  "foncier": ['F AA1 N S Y ER0'],
  "fond": ['F AA1 N D'],
  "fonda": ['F AA1 N D AH0'],
  "fonda's": ['F AA1 N D AH0 Z'],
  "fonder": ['F AA1 N D ER0'],
  "fondest": ['F AA1 N D AH0 S T'],
  "fondiaria": ['F AA2 N D IY0 EH1 R IY0 AH0'],
  "fondkommission": ['F AA2 N D K AH0 M IH1 SH AH0 N'],
  "fondle": ['F AO1 N D AH0 L'],
  "fondled": ['F AO1 N D AH0 L D'],
  "fondling": ['F AA1 N D AH0 L IH0 NG', 'F AA1 N D L IH0 NG'],
  "fondly": ['F AA1 N D L IY0'],
  "fondness": ['F AA1 N D N AH0 S'],
  "fondren": ['F AA1 N D ER0 AH0 N'],
  "fondue": ['F AA0 N D Y UW1', 'F AA1 N D UW2'],
  "fondues": ['F AA1 N D UW0 Z'],
  "fone": ['F OW1 N'],
  "foner": ['F OW1 N ER0'],
  "fones": ['F OW1 N Z'],
  "fong": ['F AO1 NG'],
  "fonger": ['F AO1 NG ER0'],
  "fonner": ['F AA1 N ER0'],
  "fons": ['F AA1 N Z'],
  "fonseca": ['F OW0 N S EH1 K AH0'],
  "font": ['F AA1 N T'],
  "fontaine": ['F AO0 N T EY1 N'],
  "fontainebleau": ['F AA1 N T AH0 N B L OW2', 'F AA1 N T AH0 N B L UW2'],
  "fontana": ['F AO0 N T AE1 N AH0'],
  "fontanella": ['F AA2 N T AH0 N EH1 L AH0'],
  "fontanez": ['F OW0 N T AA1 N EH0 Z'],
  "fontanilla": ['F AA2 N T AH0 N IH1 L AH0'],
  "fonte": ['F AA1 N T'],
  "fontenette": ['F AA1 N T IH0 N EH0 T'],
  "fontenot": ['F AA1 N T IH0 N AH0 T'],
  "fontes": ['F OW1 N T EH0 S'],
  "fonts": ['F AA1 N T S'],
  "fonville": ['F OW1 N V IH0 L'],
  "fonz": ['F AA1 N Z'],
  "fonzie": ['F AA1 N Z IY0'],
  "foo": ['F UW1'],
  "foobar": ['F UW1 B AA1 R'],
  "food": ['F UW1 D'],
  "food's": ['F UW1 D Z'],
  "foodarama": ['F UW2 D ER0 AE1 M AH0'],
  "foodie": ['F UW1 D IY0'],
  "foodmaker": ['F UW1 D M EY2 K ER0'],
  "foodmaker's": ['F UW1 D M EY2 K ER0 Z'],
  "foodmakers": ['F UW1 D M EY2 K ER0 Z'],
  "foods": ['F UW1 D Z'],
  "foods'": ['F UW1 D Z'],
  "foodservice": ['F UW1 D S ER1 V IH0 S'],
  "foodstuff": ['F UW1 D S T AH2 F'],
  "foodstuffs": ['F UW1 D S T AH2 F S'],
  "foodtown": ['F UW1 D T AW2 N'],
  "foodway": ['F UW1 D W EY2'],
  "foodways": ['F UW1 D W EY2 Z'],
  "fooks": ['F UH1 K S'],
  "fool": ['F UW1 L'],
  "fool's": ['F UW1 L Z'],
  "fooled": ['F UW1 L D'],
  "foolery": ['F UW1 L ER0 IY0'],
  "foolhardy": ['F UW1 L HH AA2 R D IY0'],
  "fooling": ['F UW1 L IH0 NG'],
  "foolish": ['F UW1 L IH0 SH'],
  "foolishly": ['F UW1 L IH0 SH L IY0'],
  "foolishness": ['F UW1 L IH0 SH N AH0 S'],
  "foolproof": ['F UW1 L P R UW2 F'],
  "fools": ['F UW1 L Z'],
  "foong": ['F UW1 NG'],
  "foor": ['F UH1 R'],
  "foos": ['F UW1 Z'],
  "foosball": ['F UW1 S B AO2 L'],
  "foose": ['F UW1 S'],
  "fooshee": ['F UW1 SH IY0'],
  "foot": ['F UH1 T'],
  "foot-and-mouth": ['F UH2 T AH0 N D M AW1 TH'],
  "footage": ['F UH1 T IH0 JH'],
  "football": ['F UH1 T B AO2 L'],
  "football's": ['F UH1 T B AO2 L Z'],
  "footballs": ['F UH1 T B AO2 L Z'],
  "foote": ['F UH1 T'],
  "foote's": ['F UH1 T S'],
  "footed": ['F UH1 T IH0 D'],
  "footer": ['F UH1 T ER0'],
  "footfall": ['F UH1 T F AO2 L'],
  "foothill": ['F UH1 T HH IH2 L'],
  "foothills": ['F UH1 T HH IH2 L Z'],
  "foothold": ['F UH1 T HH OW2 L D'],
  "footholds": ['F UH1 T HH OW2 L D Z'],
  "footing": ['F UH1 T IH0 NG'],
  "footings": ['F UH1 T IH0 NG Z'],
  "footlight": ['F UH1 T L AY2 T'],
  "footlights": ['F UH1 T L AY2 T S'],
  "footloose": ['F UH1 T L UW2 S'],
  "footman": ['F UH1 T M AH0 N'],
  "footnote": ['F UH1 T N OW2 T'],
  "footnoted": ['F UH1 T N OW2 T IH0 D'],
  "footnotes": ['F UH1 T N OW2 T S'],
  "footnoting": ['F UH1 T N OW2 T IH0 NG'],
  "footpath": ['F UH1 T P AE2 TH'],
  "footprint": ['F UH1 T P R IH2 N T'],
  "footprints": ['F UH1 T P R IH2 N T S'],
  "footrace": ['F UH1 T R EY2 S'],
  "footstep": ['F UH1 T S T EH2 P'],
  "footsteps": ['F UH1 T S T EH2 P S'],
  "footwall": ['F UH1 T W AO2 L'],
  "footware": ['F UH1 T W EH2 R'],
  "footwear": ['F UH1 T W EH2 R'],
  "footwork": ['F UH1 T W ER2 K'],
  "fop": ['F AO1 P', 'EH1 F OW1 P IY1'],
  "foppiano": ['F OW0 P IY0 AA1 N OW0'],
  "for": ['F AO1 R', 'F ER0', 'F R ER0'],
  "for-profit": ['F AO0 P R AA1 F AH0 T'],
  "fora": ['F AO1 R AH0'],
  "forage": ['F AO1 R IH0 JH'],
  "forages": ['F AO1 R AH0 JH AH0 Z'],
  "foraging": ['F AO1 R IH0 JH IH0 NG'],
  "foraker": ['F AO1 R AH0 K ER0'],
  "foramen": ['F ER0 EY1 M AH0 N'],
  "foran": ['F AO1 R AH0 N'],
  "forand": ['F AO1 R AH0 N D'],
  "foray": ['F AO1 R EY0'],
  "forayed": ['F AO1 R EY0 D'],
  "foraying": ['F AO1 R EY0 IH0 NG'],
  "forays": ['F AO1 R EY0 Z'],
  "forbad": ['F ER0 B AE1 D'],
  "forbade": ['F ER0 B EY1 D'],
  "forbearance": ['F AO0 R B EH1 R AH0 N S'],
  "forbes": ['F AO1 R B Z'],
  "forbes'": ['F AO1 R B Z'],
  "forbes's": ['F AO1 R B Z IH0 Z'],
  "forbeses": ['F AO1 R B Z IH0 Z'],
  "forbess": ['F AO0 R B EH1 S'],
  "forbid": ['F ER0 B IH1 D', 'F AO0 B IH1 D'],
  "forbidden": ['F AO1 R B IH0 D AH0 N', 'F ER0 R B IH1 D AH0 N'],
  "forbidding": ['F ER0 B IH1 D IH0 NG', 'F AO0 B IH1 D IH0 NG'],
  "forbids": ['F ER0 B IH1 D Z', 'F AO0 B IH1 D Z'],
  "forbis": ['F AO1 R B IH0 S'],
  "forbus": ['F AO1 R B IH0 S'],
  "forbush": ['F AO1 R B UH2 SH'],
  "force": ['F AO1 R S'],
  "force's": ['F AO1 R S IH0 Z'],
  "forced": ['F AO1 R S T'],
  "forceful": ['F AO1 R S F AH0 L'],
  "forcefully": ['F AO1 R S F AH0 L IY0'],
  "forcefulness": ['F AO1 R S F AH0 L N AH0 S'],
  "forceps": ['F AO1 R S EH0 P S'],
  "forces": ['F AO1 R S IH0 Z'],
  "forces'": ['F AO1 R S IH0 Z'],
  "forcible": ['F AO1 R S AH0 B AH0 L'],
  "forcibly": ['F AO1 R S AH0 B L IY0'],
  "forcier": ['F AO1 R K IY0 ER0'],
  "forcing": ['F AO1 R S IH0 NG'],
  "forcum": ['F AO1 R K AH0 M'],
  "ford": ['F AO1 R D'],
  "ford's": ['F AO1 R D Z'],
  "forde": ['F AO1 R D'],
  "fordham": ['F AO1 R D AH0 M'],
  "fordice": ['F AO1 R D IH0 S', 'F AO1 R D AY0 S'],
  "fords": ['F AO1 R D Z'],
  "fordyce": ['F AO1 R D AY2 S'],
  "fore": ['F AO1 R'],
  "forearm": ['F AO0 R AA1 R M', 'F AO1 R AA2 R M'],
  "forearms": ['F AO0 R AA1 R M Z', 'F AO1 R AA2 R M Z'],
  "forebear": ['F AO1 R B EH2 R'],
  "forebearance": ['F AO2 R B EH1 R AH0 N S'],
  "forebears": ['F AO1 R B EH2 R Z'],
  "forebode": ['F AO0 R B OW1 D'],
  "foreboding": ['F AO0 R B OW1 D IH0 NG'],
  "forebrain": ['F AO1 R B R EY2 N'],
  "forecast": ['F AO1 R K AE2 S T'],
  "forecasted": ['F AO1 R K AE2 S T IH0 D'],
  "forecaster": ['F AO1 R K AE2 S T ER0'],
  "forecasters": ['F AO1 R K AE2 S T ER0 Z'],
  "forecasting": ['F AO1 R K AE2 S T IH0 NG'],
  "forecasts": ['F AO0 R K AE1 S T S', 'F AO1 R K AE2 S T S', 'F AO0 R K AE1 S', 'F AO1 R K AE2 S'],
  "foreclose": ['F AO0 R K L OW1 Z'],
  "foreclosed": ['F AO0 R K L OW1 Z D'],
  "forecloses": ['F AO0 R K L OW1 Z IH0 Z'],
  "foreclosing": ['F AO0 R K L OW1 Z IH0 NG'],
  "foreclosure": ['F AO0 R K L OW1 ZH ER0'],
  "foreclosures": ['F AO0 R K L OW1 ZH ER0 Z'],
  "foree": ['F AO1 R IY1'],
  "forefather": ['F AO1 R F AA2 DH ER0'],
  "forefathers": ['F AO1 R F AA2 DH ER0 Z'],
  "forefinger": ['F AO1 R F IH2 NG G ER0'],
  "forefingers": ['F AO1 R F IH2 NG G ER0 Z'],
  "forefoot": ['F AO1 R F UH2 T'],
  "forefront": ['F AO1 R F R AH2 N T'],
  "forego": ['F AO0 R G OW1'],
  "foregoing": ['F AO0 R G OW1 IH0 NG'],
  "foregone": ['F AO1 R G AO1 N'],
  "foreground": ['F AO1 R G R AW2 N D'],
  "forehand": ['F AO1 R HH AE2 N D'],
  "forehands": ['F AO1 R HH AE2 N D Z'],
  "forehead": ['F AO1 R HH EH0 D'],
  "foreheads": ['F AO1 R HH EH2 D Z'],
  "foreign": ['F AO1 R AH0 N', 'F AA1 R AH0 N'],
  "foreigner": ['F AO1 R AH0 N ER0', 'F AA1 R AH0 N ER0', 'F AO1 R N ER0', 'F AA1 R N ER0'],
  "foreigners": ['F AO1 R AH0 N ER0 Z', 'F AA1 R AH0 N ER0 Z', 'F AO1 R N ER0 Z', 'F AA1 R N ER0 Z'],
  "foreigners'": ['F AO1 R AH0 N ER0 Z', 'F AA1 R AH0 N ER0 Z', 'F AO1 R N ER0 Z', 'F AA1 R N ER0 Z'],
  "forelimb": ['F AO1 R L IH2 M'],
  "forelimbs": ['F AO1 R L IH2 M Z'],
  "foreman": ['F AO1 R M AH0 N'],
  "foremen": ['F AO1 R M AH0 N'],
  "foremost": ['F AO1 R M OW2 S T'],
  "forensic": ['F ER0 EH1 N S IH0 K', 'F AO2 R EH1 N S IH0 K'],
  "forensically": ['F ER0 EH1 N S IH0 K L IY0', 'F ER0 EH1 N S IH0 K AH0 L IY0'],
  "forensics": ['F ER0 EH1 N S IH0 K S', 'F AO2 R EH1 N S IH0 K S'],
  "foreperson": ['F AO1 R P ER0 S AH0 N'],
  "foreplay": ['F AO1 R P L EY2'],
  "forero": ['F AO1 R OW0'],
  "forerunner": ['F AO1 R AH2 N ER0'],
  "forerunners": ['F AO1 R AH2 N ER0 Z'],
  "foresaw": ['F AO2 R S AO1'],
  "foresee": ['F AO0 R S IY1'],
  "foreseeable": ['F AO0 R S IY1 AH0 B AH0 L'],
  "foreseeing": ['F AO0 R S IY1 IH0 NG'],
  "foreseen": ['F AO2 R S IY1 N'],
  "foresees": ['F AO0 R S IY1 Z'],
  "foreshadow": ['F AO0 R SH AE1 D OW0'],
  "foreshadowed": ['F AO0 R SH AE1 D OW0 D'],
  "foreshadowing": ['F AO0 R SH AE1 D OW0 IH0 NG'],
  "foreshadows": ['F AO0 R SH AE1 D OW0 Z'],
  "foresight": ['F AO1 R S AY2 T'],
  "foreskin": ['F OW1 R S K IH2 N'],
  "foresman": ['F AO1 R S M AH0 N'],
  "forest": ['F AO1 R AH0 S T', 'F AO1 R IH0 S T'],
  "forest's": ['F AO1 R AH0 S T S'],
  "foresta": ['F AO1 R S T AH0'],
  "forestall": ['F AO0 R S T AO1 L'],
  "forestalled": ['F AO2 R S T AA1 L D'],
  "forestalling": ['F AO2 R S T AA1 L IH0 NG'],
  "forestalls": ['F AO0 R S T AO1 L Z'],
  "forested": ['F AO1 R AH0 S T AH0 D'],
  "forester": ['F AO1 R AH0 S T ER0'],
  "foresters": ['F AO1 R AH0 S T ER0 Z'],
  "forestry": ['F AO1 R AH0 S T R IY0'],
  "forests": ['F AO1 R AH0 S T S'],
  "forestville": ['F AO1 R EH0 S T V IH2 L'],
  "foret": ['F AO1 R T', 'F AO1 R EH0 T'],
  "foretaste": ['F AO0 R T EY1 S T', 'F AO1 R T EY0 S T'],
  "foretastes": ['F AO0 R T EY1 S T S', 'F AO1 R T EY0 S T S'],
  "foretell": ['F AO0 R T EH1 L'],
  "foretelling": ['F AO0 R T EH1 L IH0 NG'],
  "forethought": ['F AO1 R TH AO2 T'],
  "foretold": ['F AO0 R T OW1 L D'],
  "forever": ['F ER0 EH1 V ER0'],
  "forewarn": ['F AO0 R W AO1 R N'],
  "forewarned": ['F AO0 R W AO1 R N D'],
  "forewarning": ['F AO0 R W AO1 R N IH0 NG'],
  "forewarns": ['F AO0 R W AO1 R N Z'],
  "forewing": ['F AO1 R W IH2 NG'],
  "forewings": ['F AO1 R W IH2 NG Z'],
  "forewoman": ['F AO1 R W UW0 M AH0 N'],
  "forewomen": ['F AO1 R W IH0 M EH0 N'],
  "foreword": ['F AO1 R W ER2 D'],
  "forex": ['F AO1 R EH0 K S'],
  "forfeit": ['F AO1 R F IH0 T'],
  "forfeitable": ['F AO1 R F AH0 T AH0 B AH0 L'],
  "forfeited": ['F AO1 R F IH0 T IH0 D'],
  "forfeiting": ['F AO1 R F AH0 T IH0 NG'],
  "forfeits": ['F AO1 R F IH0 T S'],
  "forfeiture": ['F AO1 R F AH0 CH ER0'],
  "forfeitures": ['F AO1 R F AH0 CH ER0 Z'],
  "forgave": ['F ER0 G EY1 V'],
  "forge": ['F AO1 R JH'],
  "forged": ['F AO1 R JH D'],
  "forger": ['F AO1 R JH ER0'],
  "forgeries": ['F AO1 R JH ER0 IY0 Z'],
  "forgers": ['F AO1 R JH ER0 Z'],
  "forgery": ['F AO1 R JH ER0 IY0'],
  "forges": ['F AO1 R JH IH0 Z'],
  "forget": ['F ER0 G EH1 T', 'F AO0 R G EH1 T'],
  "forgetful": ['F AO0 R G EH1 T F AH0 L', 'F ER0 G EH1 T F AH0 L'],
  "forgets": ['F ER0 G EH1 T S', 'F AO0 R G EH1 T S'],
  "forgettable": ['F AO0 R G EH1 T AH0 B AH0 L', 'F ER0 G EH1 T AH0 B AH0 L'],
  "forgette": ['F ER0 ZH EH1 T'],
  "forgetting": ['F ER0 G EH1 T IH0 NG', 'F AO0 R G EH1 T IH0 NG'],
  "forgey": ['F AO1 R JH IY0'],
  "forgie": ['F AO1 R JH IY0'],
  "forging": ['F AO1 R JH IH0 NG'],
  "forgings": ['F AO1 JH IH0 NG Z'],
  "forgione": ['F AO0 R JH OW1 N IY0'],
  "forgivable": ['F AO0 R G IH1 V AH0 B AH0 L', 'F ER0 G IH1 V AH0 B AH0 L'],
  "forgive": ['F ER0 G IH1 V', 'F AO0 R G IH1 V'],
  "forgiven": ['F ER0 G IH1 V AH0 N', 'F AO0 R G IH1 V AH0 N'],
  "forgiveness": ['F ER0 G IH1 V N AH0 S', 'F AO0 R G IH1 V N AH0 S'],
  "forgives": ['F ER0 G IH1 V Z', 'F AO0 R G IH1 V Z'],
  "forgiving": ['F ER0 G IH1 V IH0 NG', 'F AO0 R G IH1 V IH0 NG'],
  "forgo": ['F AO0 R G OW1'],
  "forgoes": ['F AO0 R G OW1 Z'],
  "forgoing": ['F AO0 R G OW1 IH0 NG'],
  "forgone": ['F AO0 R G AA1 N'],
  "forgot": ['F ER0 G AA1 T', 'F AO0 R G AA1 T'],
  "forgotten": ['F ER0 G AA1 T AH0 N', 'F AO0 R G AA1 T AH0 N'],
  "forgue": ['F AO1 R G'],
  "forgy": ['F AO1 R JH IY0'],
  "forhan": ['F AO1 R HH AH0 N'],
  "forie": ['F AO1 R IY0'],
  "forin": ['F AO1 R IH0 N'],
  "forinash": ['F AO1 R IH0 N AE0 SH'],
  "forint": ['F AO1 R IH0 N T'],
  "forints": ['F AO1 R IH0 N T S'],
  "forister": ['F AO1 R IH0 S T ER0'],
  "fork": ['F AO1 R K'],
  "fork-lift": ['F AO1 R K L IH1 F T'],
  "forked": ['F AO1 R K T'],
  "forker": ['F AO1 R K ER0'],
  "forkey": ['F AO1 R K IY2'],
  "forking": ['F AO1 R K IH0 NG'],
  "forklift": ['F AO1 R K L IH2 F T'],
  "forklifts": ['F AO1 R K L IH2 F T S'],
  "forkner": ['F AO1 R K N ER0'],
  "forks": ['F AO1 R K S'],
  "forlenza": ['F AO0 R L EH1 N Z AH0'],
  "forlorn": ['F ER0 L AO1 R N'],
  "form": ['F AO1 R M'],
  "form's": ['F AO1 R M Z'],
  "forma": ['F AO1 R M AH0'],
  "formal": ['F AO1 R M AH0 L'],
  "formaldehyde": ['F AO0 R M AE1 L D AH0 HH AY2 D', 'F ER0 M AE1 L D AH0 HH AY2 D'],
  "formalism": ['F AO1 R M AH0 L IH2 Z AH0 M'],
  "formalist": ['F AO1 R M AH0 L AH0 S T'],
  "formalities": ['F AO0 R M AE1 L AH0 T IY0 Z'],
  "formality": ['F AO0 R M AE1 L AH0 T IY0'],
  "formalization": ['F AO1 R M AH0 L AH0 Z EY2 SH AH0 N'],
  "formalize": ['F AO1 R M AH0 L AY2 Z'],
  "formalized": ['F AO1 R M AH0 L AY2 Z D'],
  "formalizes": ['F AO1 R M AH0 L AY2 Z IH0 Z'],
  "formalizing": ['F AO1 R M AH0 L AY2 Z IH0 NG'],
  "formally": ['F AO1 R M AH0 L IY0'],
  "forman": ['F AO1 R M AE2 N'],
  "formanek": ['F AO1 R M AH0 N IH0 K'],
  "formant": ['F AO1 R M AH0 N T'],
  "formants": ['F AO1 R M AH0 N T S'],
  "format": ['F AO1 R M AE2 T'],
  "formation": ['F AO0 R M EY1 SH AH0 N'],
  "formations": ['F AO0 R M EY1 SH AH0 N Z'],
  "formative": ['F AO1 R M AH0 T IH0 V'],
  "formato": ['F AO0 R M AA1 T OW0'],
  "formats": ['F AO1 R M AE2 T S'],
  "formatting": ['F AO1 R M AE2 T IH0 NG'],
  "formbey": ['F AO1 R M B IY0'],
  "formby": ['F AO1 R M B IY0'],
  "formed": ['F AO1 R M D'],
  "former": ['F AO1 R M ER0'],
  "formerly": ['F AO1 R M ER0 L IY0'],
  "formic": ['F AO1 R M IH0 K'],
  "formica": ['F AO0 R M AY1 K AH0', 'F ER0 M AY1 K AH0'],
  "formica's": ['F AO0 R M AY1 K AH0 Z', 'F ER0 M AY1 K AH0 Z'],
  "formidable": ['F AO1 R M AH0 D AH0 B AH0 L', 'F AO2 R M IH1 D AH0 B AH0 L'],
  "formidably": ['F AO1 R M AH0 D AH0 B L IY0'],
  "forming": ['F AO1 R M IH0 NG'],
  "formosa": ['F AO0 R M OW1 S AH0'],
  "formoso": ['F AO0 R M OW1 S OW0'],
  "forms": ['F AO1 R M Z'],
  "formula": ['F AO1 R M Y AH0 L AH0'],
  "formulae": ['F AO1 R M Y AH0 L EY2'],
  "formulaic": ['F AO2 R M Y AH0 L EY1 IH0 K'],
  "formulary": ['F AO1 R M Y AH0 L EH2 R IY0'],
  "formulas": ['F AO1 R M Y AH0 L AH0 Z'],
  "formulate": ['F AO1 R M Y AH0 L EY2 T'],
  "formulated": ['F AO1 R M Y AH0 L EY2 T AH0 D', 'F AO1 R M Y AH0 L EY2 T IH0 D'],
  "formulates": ['F AO1 R M Y AH0 L EY2 T S'],
  "formulating": ['F AO1 R M Y AH0 L EY2 T IH0 NG'],
  "formulation": ['F AO2 R M Y AH0 L EY1 SH AH0 N', 'F AO2 R M Y UW0 L EY1 SH AH0 N'],
  "formulations": ['F AO2 R M Y UW0 L EY1 SH AH0 N Z'],
  "formylin": ['F AO1 R M IH0 L IH0 N'],
  "fornal": ['F AO1 R N AH0 L'],
  "forner": ['F AO1 R N ER0'],
  "fornes": ['F AO1 R N Z'],
  "forness": ['F ER1 N IH0 S'],
  "forney": ['F AO1 R N IY0'],
  "forni": ['F AO1 R N IY0'],
  "fornoff": ['F AO1 R N AO0 F'],
  "fornwalt": ['F AO1 R N W AH0 L T'],
  "forquer": ['F AO1 R K ER0'],
  "forr": ['F AO1 R'],
  "forrer": ['F AO1 ER0 R'],
  "forrest": ['F AO1 R AH0 S T'],
  "forrestal": ['F AO1 R AH0 S T AH0 L'],
  "forrester": ['F AO1 R AH0 S T ER0'],
  "forrey": ['F AO1 R IY0'],
  "forry": ['F AO1 R IY0'],
  "fors": ['F ER1 Z'],
  "forsake": ['F AO0 R S EY1 K'],
  "forsaken": ['F AO0 R S EY1 K AH0 N'],
  "forsaking": ['F AO0 R S EY1 K IH0 NG'],
  "forsberg": ['F AO1 R S B ER0 G'],
  "forsburg": ['F AO1 R S B ER0 G'],
  "forsburg's": ['F AO1 R S B ER0 G Z'],
  "forschner": ['F AO1 R SH N ER0'],
  "forse": ['F AO1 R S'],
  "forsee": ['F ER0 S IY1'],
  "forseeable": ['F AO2 R S IY1 AH0 B AH0 L', 'F ER0 S IY1 AH0 B AH0 L'],
  "forsell": ['F AO1 R S AH0 L'],
  "forseth": ['F AO1 R S IH0 TH'],
  "forsgren": ['F AO1 R S G R EH0 N'],
  "forshan": ['F AO1 R SH AH0 N'],
  "forshee": ['F AO1 R SH IY0'],
  "forshey": ['F AO1 R SH IY0'],
  "forslund": ['F AO1 R S L AH0 N D'],
  "forsman": ['F AO1 R S M AH0 N'],
  "forson": ['F AO1 R S AH0 N'],
  "forsook": ['F AO0 R S UH1 K'],
  "forst": ['F AO1 R S T'],
  "forster": ['F AO1 R S T ER0'],
  "forstmann": ['F AO1 R S T M AH0 N'],
  "forstner": ['F AO1 R S T N ER0'],
  "forstrom": ['F AO1 R S T R AH0 M'],
  "forswear": ['F AO0 R S W EH1 R'],
  "forsworn": ['F AO2 R S W AO1 R N'],
  "forsyth": ['F AO1 R S AY2 TH'],
  "forsythe": ['F AO1 R S AY0 DH'],
  "forsythia": ['F AO0 R S IH1 TH IY0 AH0', 'F AO0 R S IH1 DH IY0 AH0'],
  "forsythias": ['F AO0 R S IH1 TH IY0 AH0 Z', 'F AO0 R S IH1 DH IY0 AH0 Z'],
  "fort": ['F AO1 R T'],
  "fortas": ['F AO1 R T AH0 S'],
  "fortas's": ['F AO1 R T AH0 S IH0 Z'],
  "forte": ['F AO1 R T EY0', 'F AO1 R T'],
  "fortenberry": ['F AO1 R T AH0 N B EH0 R IY0'],
  "fortes": ['F AO1 R T EY0 Z', 'F AO1 R T S'],
  "forth": ['F AO1 R TH'],
  "forthcoming": ['F AO1 R TH K AH1 M IH0 NG'],
  "forthright": ['F AO1 R TH R AY1 T'],
  "forthrightly": ['F AO1 R TH R AY1 T L IY0'],
  "forthrightness": ['F AO1 R TH R AY1 T N AH0 S'],
  "forthwith": ['F AO1 R TH W IH1 TH'],
  "forti": ['F AO1 R T IY0'],
  "fortier": ['F AO1 R T IY0 ER0', 'F AO1 R T Y ER0'],
  "fortier's": ['F AO1 R T IY0 ER0 Z', 'F AO1 R T Y ER0 Z'],
  "forties": ['F AO1 R T IY0 Z'],
  "fortieth": ['F AO1 R T IY0 IH0 TH'],
  "fortification": ['F AO2 R T AH0 F AH0 K EY1 SH AH0 N'],
  "fortifications": ['F AO2 R T AH0 F AH0 K EY1 SH AH0 N Z'],
  "fortified": ['F AO1 R T AH0 F AY2 D'],
  "fortifier": ['F AO1 R T AH0 F AY2 ER0'],
  "fortifiers": ['F AO1 R T AH0 F AY2 ER0 Z'],
  "fortify": ['F AO1 R T IH0 F AY2'],
  "fortifying": ['F AO1 R T IH0 F AY2 IH0 NG'],
  "fortin": ['F AO1 R T IH0 N'],
  "fortini": ['F AO0 R T IY1 N IY0'],
  "fortino": ['F AO0 R T IY1 N OW0'],
  "fortis": ['F AO1 R T IH0 S'],
  "fortitude": ['F AO1 R T IH0 T UW2 D'],
  "fortman": ['F AO1 R T M AH0 N'],
  "fortna": ['F AO1 R T N AH0'],
  "fortner": ['F AO1 R T N ER0'],
  "fortney": ['F AO1 R T N IY0'],
  "fortnight": ['F AO1 R T N AY2 T'],
  "fortnightly": ['F AO1 R T N AY2 T L IY0'],
  "fortnights": ['F AO1 R T N AY2 T S'],
  "forton": ['F AO1 R T AH0 N'],
  "fortress": ['F AO1 R T R AH0 S'],
  "fortresses": ['F AO1 R T R AH0 S IH0 Z'],
  "forts": ['F AO1 R T S'],
  "fortson": ['F AO1 R T S AH0 N'],
  "fortuitous": ['F AO0 R T UW1 IH0 T AH0 S'],
  "fortuna": ['F AO0 R T UW1 N AH0'],
  "fortunate": ['F AO1 R CH AH0 N AH0 T', 'F AO1 R CH UW0 N AH0 T'],
  "fortunately": ['F AO1 R CH AH0 N AH0 T L IY0', 'F AO1 R CH UW0 N AH0 T L IY0'],
  "fortunato": ['F AO0 R T UW0 N AA1 T OW0', 'F AO0 R CH UW0 N AA1 T OW0'],
  "fortune": ['F AO1 R CH AH0 N', 'F AO1 R CH UW0 N'],
  "fortune's": ['F AO1 R CH AH0 N Z', 'F AO1 R CH UW0 N Z'],
  "fortunes": ['F AO1 R CH AH0 N Z', 'F AO1 R CH UW0 N Z'],
  "fortuny": ['F AO0 R CH UW1 N IY0'],
  "forty": ['F AO1 R T IY0'],
  "forty's": ['F AO1 R T IY0 Z'],
  "forty-five": ['F AO1 R T IY0 F AY2 V'],
  "forum": ['F AO1 R AH0 M'],
  "forum's": ['F AO1 R AH0 M Z'],
  "forums": ['F AO1 R AH0 M Z'],
  "forward": ['F AO1 R W ER0 D'],
  "forwarded": ['F AO1 R W ER0 D IH0 D'],
  "forwarder": ['F AO1 R W ER0 D ER0'],
  "forwarders": ['F AO1 R W ER0 D ER0 Z'],
  "forwarding": ['F AO1 R W ER0 D IH0 NG'],
  "forwards": ['F AO1 R W ER0 D Z'],
  "forwood": ['F AO1 R W UH2 D'],
  "forys": ['F AO1 R IY0 Z'],
  "forza": ['F AO1 R Z AH0'],
  "fosamax": ['F AA1 S AH0 M AE2 K S'],
  "fosback": ['F AA1 S B AE2 K'],
  "fosberg": ['F AA1 S B ER0 G'],
  "fosco": ['F AA1 S K OW0'],
  "fosdick": ['F AA1 S D IH0 K'],
  "foshee": ['F AA1 SH IY0'],
  "fosia": ['F OW1 ZH AH0'],
  "foskett": ['F AA1 S K IH0 T'],
  "foskey": ['F AA1 S K IY0'],
  "fosler": ['F AA1 S AH0 L ER0', 'F AA1 S L ER0'],
  "fosnaugh": ['F AA1 S N AO0'],
  "foss": ['F AA1 S'],
  "fosse": ['F AA1 S'],
  "fossel": ['F AA1 S AH0 L'],
  "fossen": ['F AA1 S AH0 N'],
  "fossett": ['F AA1 S IH0 T'],
  "fossett's": ['F AA1 S IH0 T S'],
  "fossey": ['F AA1 S IY0'],
  "fossey's": ['F AA1 S IY0 Z'],
  "fossil": ['F AA1 S AH0 L'],
  "fossiliferous": ['F AA2 S AH0 L IH1 F ER0 AH0 S'],
  "fossilize": ['F AA1 S AH0 L AY2 Z'],
  "fossilized": ['F AA1 S AH0 L AY2 Z D'],
  "fossils": ['F AA1 S AH0 L Z'],
  "fossum": ['F AA1 S AH0 M'],
  "foster": ['F AA1 S T ER0'],
  "foster's": ['F AA1 S T ER0 Z'],
  "fostered": ['F AA1 S T ER0 D'],
  "fostering": ['F AA1 S T ER0 IH0 NG'],
  "fosters": ['F AA1 S T ER0 Z'],
  "foth": ['F AA1 TH'],
  "fothergill": ['F AH1 DH ER0 G IH2 L'],
  "fotheringham": ['F AH1 DH ER0 IH0 NG HH AE0 M'],
  "foti": ['F OW1 T IY0'],
  "fotis": ['F OW1 T IH0 S'],
  "fotopoulos": ['F AH0 T AA1 P AH0 L IH0 S'],
  "fouad": ['F UW1 AE0 D'],
  "foucault": ['F UW2 K OW1'],
  "fouch": ['F AW1 CH'],
  "fouche": ['F AW1 CH'],
  "foucher": ['F AW1 K ER0'],
  "fougere": ['F AW1 G ER0'],
  "fought": ['F AO1 T'],
  "fouhy": ['F UW1 IY0', 'F UW1 HH IY0'],
  "foul": ['F AW1 L'],
  "foulds": ['F OW1 L D Z'],
  "fouled": ['F AW1 L D'],
  "fouling": ['F AW1 L IH0 NG'],
  "foulk": ['F AW1 L K'],
  "foulke": ['F AW1 L K'],
  "foulkes": ['F AW1 L K S'],
  "foulks": ['F UW1 L K S'],
  "foulness": ['F AW1 L N AH0 S'],
  "fouls": ['F AW1 L Z'],
  "found": ['F AW1 N D'],
  "foundation": ['F AW0 N D EY1 SH AH0 N'],
  "foundation's": ['F AW0 N D EY1 SH AH0 N Z'],
  "foundational": ['F AW0 N D EY1 SH AH0 N AH0 L'],
  "foundations": ['F AW0 N D EY1 SH AH0 N Z'],
  "founded": ['F AW1 N D IH0 D'],
  "founder": ['F AW1 N D ER0'],
  "founder's": ['F AW1 N D ER0 Z'],
  "foundered": ['F AW1 N D ER0 D'],
  "foundering": ['F AW1 N D ER0 IH0 NG'],
  "founders": ['F AW1 N D ER0 Z'],
  "founders'": ['F AW1 N D ER0 Z'],
  "founding": ['F AW1 N D IH0 NG'],
  "foundling": ['F AW1 N D L IH0 NG'],
  "foundries": ['F AW1 N D R IY0 Z'],
  "foundry": ['F AW1 N D R IY0'],
  "fountain": ['F AW1 N T AH0 N'],
  "fountaine": ['F UW0 N T EY1 N'],
  "fountains": ['F AW1 N T AH0 N Z'],
  "four": ['F AO1 R'],
  "four's": ['F AO1 R Z'],
  "four-year": ['F AO2 R Y IH1 R'],
  "fourfold": ['F AO2 R F OW1 L D'],
  "fourier": ['F UH1 R IY0 EY0'],
  "fourman": ['F AO1 R M AH0 N'],
  "fournet": ['F UH0 R N EH1 T'],
  "fournier": ['F AO1 R N IY2 ER0'],
  "fours": ['F AO1 R Z'],
  "foursome": ['F AO1 R S AH0 M'],
  "foursquare": ['F AO1 R S K W EH1 R'],
  "fourteen": ['F AO1 R T IY1 N', 'F AO2 R T IY1 N'],
  "fourteens": ['F AO1 R T IY1 N Z'],
  "fourteenth": ['F AO1 R T IY1 N TH', 'F AO2 R T IY1 N TH'],
  "fourth": ['F AO1 R TH'],
  "fourth's": ['F AO1 R TH S'],
  "fourthly": ['F AO1 R TH L IY0'],
  "fourthquarter": ['F AO1 R TH K W AO1 R T ER0', 'F AO1 R TH K AO1 R T ER0'],
  "fourths": ['F AO1 R TH S', 'F AO1 R S'],
  "fourtou": ['F AO0 R T UW1'],
  "fouse": ['F AW1 S'],
  "fousek": ['F AW1 S IH0 K'],
  "foushee": ['F AW1 SH IY0'],
  "foust": ['F AW1 S T'],
  "fout": ['F AW1 T'],
  "foutch": ['F AW1 CH'],
  "fouts": ['F AW1 T S'],
  "fouty": ['F AW1 T IY0'],
  "foutz": ['F AW1 T S'],
  "fowble": ['F AW1 B AH0 L'],
  "fowers": ['F AW1 ER0 Z'],
  "fowkes": ['F AW1 K S'],
  "fowl": ['F AW1 L'],
  "fowle": ['F AW1 AH0 L'],
  "fowler": ['F AW1 L ER0'],
  "fowler's": ['F AW1 L ER0 Z'],
  "fowles": ['F AW1 AH0 L Z'],
  "fowlkes": ['F AW1 L K S'],
  "fox": ['F AA1 K S'],
  "fox's": ['F AA1 K S AH0 Z'],
  "foxboro": ['F AA1 K S B ER0 OW0'],
  "foxborough": ['F AA1 K S B ER0 OW0'],
  "foxes": ['F AA1 K S AH0 Z'],
  "foxfire": ['F AA1 K S F AY2 R', 'F AA1 K S F AY2 ER0'],
  "foxglove": ['F AA1 K S G L AH2 V'],
  "foxhole": ['F AA1 K S HH OW2 L'],
  "foxholes": ['F AA1 K S HH OW2 L Z'],
  "foxhound": ['F AA1 K S HH AW2 N D'],
  "foxman": ['F AA1 K S M AH0 N'],
  "foxman's": ['F AA1 K S M AH0 N Z'],
  "foxmeyer": ['F AA1 K S M AY2 R'],
  "foxtail": ['F AA1 K S T EY2 L'],
  "foxtrot": ['F AA1 K S T R AA0 T'],
  "foxwell": ['F AA1 K S W EH2 L'],
  "foxwood": ['F AA1 K S W UH2 D'],
  "foxwoods": ['F AA1 K S W UH2 D Z'],
  "foxworth": ['F AA1 K S W ER2 TH'],
  "foxworthy": ['F AA1 K S W ER2 DH IY0'],
  "foxworthy's": ['F AA1 K S W ER2 DH IY0 Z'],
  "foxx": ['F AA1 K S'],
  "foxy": ['F AA1 K S IY0'],
  "foy": ['F OY1'],
  "foye": ['F OY1'],
  "foyer": ['F OY1 ER0'],
  "foyle": ['F OY1 L'],
  "foyt": ['F OY1 T'],
  "fraas": ['F R AA1 Z'],
  "frable": ['F R EY1 B AH0 L'],
  "fracas": ['F R EY1 K AH0 S'],
  "frace": ['F R EY1 S'],
  "fractal": ['F R AE1 K T AH0 L'],
  "fraction": ['F R AE1 K SH AH0 N'],
  "fractional": ['F R AE1 K SH AH0 N AH0 L'],
  "fractionally": ['F R AE1 K SH AH0 N AH0 L IY0', 'F R AE1 K SH N AH0 L IY0'],
  "fractions": ['F R AE1 K SH AH0 N Z'],
  "fractious": ['F R AE1 K SH AH0 S'],
  "fractiousness": ['F R AE1 K SH AH0 S N AH0 S'],
  "fracture": ['F R AE1 K CH ER0', 'F R AE1 K SH ER0'],
  "fractured": ['F R AE1 K CH ER0 D'],
  "fractures": ['F R AE1 K CH ER0 Z', 'F R AE1 K SH ER0 Z'],
  "fracturing": ['F R AE1 K CH ER0 IH0 NG'],
  "fradette": ['F R AH0 D EH1 T'],
  "fradkin": ['F R AE1 D K IH0 N'],
  "frady": ['F R EY1 D IY0'],
  "fraga": ['F R AA1 G AH0'],
  "fragale": ['F R AA0 G AA1 L IY0'],
  "frager": ['F R EY1 G ER0'],
  "fragile": ['F R AE1 JH AH0 L'],
  "fragility": ['F R AH0 JH IH1 L AH0 T IY0'],
  "fragment": ['F R AE1 G M AH0 N T'],
  "fragmentary": ['F R AE1 G M AH0 N T EH2 R IY0'],
  "fragmentation": ['F R AE2 G M AH0 N T EY1 SH AH0 N'],
  "fragmented": ['F R AE1 G M AH0 N T IH0 D', 'F R AE1 G M AH0 N IH0 D'],
  "fragmenting": ['F R AE1 G M AH0 N T IH0 NG', 'F R AE1 G M AH0 N IH0 NG'],
  "fragments": ['F R AE1 G M AH0 N T S'],
  "frago": ['F R EY1 G OW0'],
  "fragonard": ['F R AE1 G AH0 N ER0 D', 'F R AE1 G AH0 N AA0 R D'],
  "fragoso": ['F R AA0 G OW1 S OW0'],
  "fragrance": ['F R EY1 G R AH0 N S'],
  "fragrances": ['F R EY1 G R AH0 N S AH0 Z', 'F R EY1 G R AH0 N S IH0 Z'],
  "fragrant": ['F R EY1 G R AH0 N T'],
  "fraher": ['F R AA1 ER0'],
  "frahm": ['F R AE1 M'],
  "frail": ['F R EY1 L'],
  "frailey": ['F R EY1 L IY0'],
  "frailties": ['F R EY1 L T IY0 Z'],
  "frailty": ['F R EY1 L T IY0'],
  "fraim": ['F R EY1 M'],
  "frain": ['F R EY1 N'],
  "fraine": ['F R EY1 N'],
  "fraioli": ['F R AY0 OW1 L IY0'],
  "fraire": ['F R EH1 R'],
  "fraiser": ['F R EY1 ZH ER0'],
  "fraizer": ['F R EY1 ZH ER0'],
  "fraker": ['F R EY1 K ER0'],
  "frakes": ['F R EY1 K S'],
  "fraleigh": ['F R EY1 L IY0'],
  "fraley": ['F R EY1 L IY0'],
  "fralick": ['F R AE1 L IH0 K'],
  "fralin": ['F R AE1 L IH0 N'],
  "fralix": ['F R AE1 L IH0 K S'],
  "fram": ['F R AE1 M'],
  "framatome": ['F R AE1 M AH0 T OW2 M'],
  "frame": ['F R EY1 M'],
  "framed": ['F R EY1 M D'],
  "framer": ['F R EY1 M ER0'],
  "framers": ['F R EY1 M ER0 Z'],
  "framers'": ['F R AE1 M ER0 Z'],
  "frames": ['F R EY1 M Z'],
  "framework": ['F R EY1 M W ER2 K'],
  "frameworks": ['F R EY1 M W ER2 K S'],
  "framing": ['F R EY1 M IH0 NG'],
  "framingham": ['F R EY1 M IH0 NG HH AE2 M'],
  "frampton": ['F R AE1 M P T AH0 N'],
  "fran": ['F R AE1 N'],
  "frana": ['F R AE1 N AH0'],
  "franc": ['F R AE1 NG K'],
  "franc's": ['F R AE1 NG K S'],
  "franca": ['F R AE1 NG K AH0'],
  "francais": ['F R AA0 N S EY1'],
  "francaise": ['F R AA0 N S EH1 Z'],
  "francaises": ['F R AA0 N S EH1 Z'],
  "francavilla": ['F R AA0 N K AA0 V IH1 L AH0'],
  "france": ['F R AE1 N S'],
  "france's": ['F R AE1 N S IH0 Z'],
  "francek": ['F R AE1 N CH EH2 K'],
  "frances": ['F R AE1 N S IH0 S'],
  "francesca": ['F R AE0 N CH EH1 S K AH0'],
  "franceschi": ['F R AA0 N CH EH1 S K IY0'],
  "franceschini": ['F R AA0 N CH EH0 S K IY1 N IY0'],
  "francesco": ['F R AE0 N CH EH1 S K OW0'],
  "francesco's": ['F R AE0 N CH EH1 S K OW0 Z'],
  "francesconi": ['F R AA0 N CH EH0 S K OW1 N IY0'],
  "francese": ['F R AA0 N CH EY1 Z IY0'],
  "francesmary": ['F R AE2 N S AH0 S M EH1 R IY0'],
  "franchi": ['F R AA1 N K IY0'],
  "franchik": ['F R AE1 N CH IH0 K'],
  "franchini": ['F R AA0 N K IY1 N IY0'],
  "franchino": ['F R AA0 N K IY1 N OW0'],
  "franchise": ['F R AE1 N CH AY2 Z'],
  "franchise's": ['F R AE1 N CH AY2 Z IH0 Z'],
  "franchised": ['F R AE1 N CH AY0 Z D'],
  "franchisee": ['F R AE1 N CH AY2 Z IY1'],
  "franchisees": ['F R AE2 N CH AY0 Z IY1 Z'],
  "franchisees'": ['F R AE2 N CH AY0 Z IY1 Z'],
  "franchiser": ['F R AE1 N CH AY2 Z ER0'],
  "franchisers": ['F R AE1 N CH AY2 Z ER0 Z'],
  "franchises": ['F R AE1 N CH AY2 Z IH0 Z'],
  "franchising": ['F R AE1 N CH AY0 Z IH0 NG'],
  "franchot": ['F R AE1 N K AH0 T'],
  "francia": ['F R AA1 N CH AH0'],
  "francie": ['F R AE1 NG K IY0'],
  "francies": ['F R AH0 N S IY1 Z'],
  "francine": ['F R AE0 N S IY1 N'],
  "francine's": ['F R AE0 N S IY1 N Z'],
  "francines": ['F R AE0 N S IY1 N Z'],
  "francis": ['F R AE1 N S AH0 S', 'F R AE1 N S IH0 S'],
  "francis'": ['F R AE1 N S AH0 S', 'F R AE1 N S IH0 S'],
  "franciscan": ['F R AE0 N S IH1 S K AH0 N'],
  "franciscans": ['F R AE0 N S IH1 S K AH0 N Z'],
  "francisco": ['F R AE0 N S IH1 S K OW0'],
  "francisco's": ['F R AE0 N S IH1 S K OW0 Z'],
  "franciso": ['F R AE0 N S IY1 S OW0'],
  "francisville": ['F R AE1 N S IH0 S V IH2 L'],
  "franck": ['F R AE1 NG K'],
  "francke": ['F R AE1 NG K'],
  "francklin": ['F R AE1 NG K L IH0 N'],
  "francklyn": ['F R AE1 NG K L IH0 N'],
  "franckowiak": ['F R AH0 N S K AW1 IY0 AE0 K'],
  "franco": ['F R AE1 NG K OW0'],
  "franco's": ['F R AE1 NG K OW0 Z'],
  "francoeur": ['F R AH0 N K ER1'],
  "francois": ['F R AA0 N S W AA1'],
  "francoise": ['F R AE0 N S W AA1 Z', 'F R AE0 N S W AA1'],
  "francom": ['F R AE1 NG K AA0 M'],
  "francophile": ['F R AE1 NG K AH0 F AY2 L'],
  "francophone": ['F R AE1 NG K AH0 F OW2 N'],
  "francorp": ['F R AE1 N K AO2 R P'],
  "francs": ['F R AE1 NG K S'],
  "francy": ['F R AE1 N S IY0'],
  "francyne": ['F R AE1 N S AY2 N'],
  "franczak": ['F R AE1 N CH AE0 K'],
  "frandsen": ['F R AE1 N D S AH0 N'],
  "frane": ['F R EY1 N'],
  "franek": ['F R AE1 N IH0 K'],
  "franey": ['F R EY1 N IY0'],
  "frangos": ['F R AE1 NG G OW0 Z'],
  "franjo": ['F R AE1 N JH OW0'],
  "frank": ['F R AE1 NG K'],
  "frank's": ['F R AE1 NG K S'],
  "franke": ['F R AE1 NG K', 'F R AE1 NG K IY0'],
  "franked": ['F R AE1 NG K T'],
  "frankel": ['F R AE1 NG K AH0 L'],
  "franken": ['F R AE1 NG K AH0 N'],
  "frankenberg": ['F R AE1 NG K AH0 N B ER0 G'],
  "frankenberger": ['F R AE1 NG K AH0 N B ER0 G ER0'],
  "frankenberry": ['F R AE1 NG K AH0 N B EH2 R IY0'],
  "frankenfield": ['F R AE1 NG K AH0 N F IY2 L D'],
  "frankenfood": ['F R AE1 NG K AH0 N F UW2 D'],
  "frankenheimer": ['F R AE1 NG K AH0 N HH AY2 M ER0'],
  "frankenstein": ['F R AE1 NG K AH0 N S T AY2 N', 'F R AE1 NG K AH0 N S T IY2 N'],
  "frankenstein's": ['F R AE1 NG K AH0 N S T AY2 N Z', 'F R AE1 NG K AH0 N S T IY2 N Z'],
  "frankfort": ['F R AE1 NG K F ER0 T'],
  "frankfort's": ['F R AE1 NG K F ER0 T S'],
  "frankfurt": ['F R AE1 NG K F ER0 T'],
  "frankfurt's": ['F R AE1 NG K F ER0 T S'],
  "frankfurter": ['F R AE1 NG K F ER0 T ER0'],
  "frankfurters": ['F R AE1 NG K F ER0 T ER0 Z'],
  "frankhouser": ['F R AE1 NG K HH AW2 S ER0'],
  "frankie": ['F R AE1 NG K IY0'],
  "frankiewicz": ['F R AE1 N K AH0 V IH0 CH'],
  "frankincense": ['F R AE1 NG K AH0 N S EH2 N S'],
  "franking": ['F R AE1 NG K IH0 NG'],
  "frankino": ['F R AE0 NG K IY1 N OW0'],
  "frankish": ['F R AE1 NG K IH0 SH'],
  "frankl": ['F R AE1 NG K AH0 L'],
  "frankland": ['F R AE1 NG K L AH0 N D'],
  "franklin": ['F R AE1 NG K L IH0 N'],
  "franklin's": ['F R AE1 NG K L AH0 N Z'],
  "franklinite": ['F R AE1 NG K L IH0 N AY2 T'],
  "franklinville": ['F R AE1 NG K L IH0 N V IH0 L'],
  "frankly": ['F R AE1 NG K L IY0'],
  "franklyn": ['F R AE1 NG K L IH0 N'],
  "frankness": ['F R AE1 NG K N AH0 S'],
  "franko": ['F R AE1 NG K OW0'],
  "frankovich": ['F R AE1 NG K AH0 V IH0 CH'],
  "frankowski": ['F R AH0 NG K AO1 F S K IY0'],
  "franks": ['F R AE1 NG K S'],
  "frankson": ['F R AE1 NG K S AH0 N'],
  "frankum": ['F R AE1 NG K AH0 M'],
  "frannie": ['F R AE1 N IY0'],
  "franny": ['F R AE1 N IY0'],
  "frano": ['F R AA1 N OW0'],
  "frans": ['F R AE1 N Z'],
  "fransen": ['F R AE1 N S AH0 N'],
  "franson": ['F R AE1 N S AH0 N'],
  "franssen": ['F R AE1 N S AH0 N'],
  "franta": ['F R AE1 N T AH0'],
  "frantic": ['F R AE1 N T IH0 K'],
  "frantically": ['F R AE1 N T AH0 K AH0 L IY0', 'F R AE1 N T AH0 K L IY0', 'F R AE1 N AH0 K AH0 L IY0', 'F R AE1 N AH0 K L IY0'],
  "frantom": ['F R AE1 N T AH0 M'],
  "frantz": ['F R AE1 N T S'],
  "frantzen": ['F R AE1 N T Z AH0 N'],
  "franyo": ['F R AA1 N Y OW0'],
  "franz": ['F R AE1 N Z'],
  "franze": ['F R AE1 N Z'],
  "franzel": ['F R AE1 N Z AH0 L'],
  "franzen": ['F R AE1 N Z AH0 N'],
  "franzese": ['F R AA0 N Z EY1 Z IY0'],
  "franzone": ['F R AA0 N Z OW1 N IY0'],
  "franzoni": ['F R AA0 N Z OW1 N IY0'],
  "frap": ['F R AE1 P'],
  "fraph": ['F R AE1 F'],
  "fraph's": ['F R AE1 F S'],
  "frappier": ['F R AE1 P IY0 ER0'],
  "frary": ['F R EH1 R IY0'],
  "frasca": ['F R AA1 S K AH0'],
  "frascella": ['F R AA0 S CH EH1 L AH0'],
  "frasch": ['F R AE1 SH'],
  "frasco": ['F R AA1 S K OW0'],
  "frase": ['F R EY1 Z'],
  "fraser": ['F R EY1 Z ER0'],
  "fraser's": ['F R EY1 Z ER0 Z'],
  "frasher": ['F R AE1 SH ER0'],
  "frashier": ['F R EY1 ZH Y ER0'],
  "frasier": ['F R EY1 ZH ER0'],
  "frasier's": ['F R EY1 ZH ER0 Z'],
  "frasure": ['F R AA1 ZH ER0'],
  "fratangelo": ['F R AA0 T AA0 NG G EH1 L OW0'],
  "frate": ['F R EY1 T'],
  "frater": ['F R EY1 T ER0'],
  "fraternal": ['F R AH0 T ER1 N AH0 L'],
  "fraternities": ['F R AH0 T ER1 N AH0 T IY0 Z'],
  "fraternity": ['F R AH0 T ER1 N AH0 T IY0', 'F R AH0 T ER1 N IH0 T IY0'],
  "fraternize": ['F R AE1 T ER0 N AY2 Z'],
  "fraternizing": ['F R AE1 T ER0 N AY2 Z IH0 NG'],
  "frates": ['F R EY1 T S'],
  "fratricide": ['F R AE1 T R AH0 S AY2 D'],
  "fratricides": ['F R AE1 T R AH0 S AY2 D Z'],
  "fratus": ['F R AE1 T IH0 S'],
  "fratzke": ['F R AE1 T S K', 'F R AE1 T S K IY0'],
  "frau": ['F R AW1'],
  "fraud": ['F R AO1 D'],
  "frauds": ['F R AO1 D Z'],
  "fraudster": ['F R AO1 D S T ER0'],
  "fraudster's": ['F R AO1 D S T ER0 Z'],
  "fraudsters": ['F R AO1 D S T ER0 Z'],
  "fraudulent": ['F R AO1 JH AH0 L AH0 N T'],
  "fraudulently": ['F R AO1 D UW0 L AH0 N T L IY0'],
  "fraught": ['F R AO1 T'],
  "fraum": ['F R AW1 M', 'F R AA1 M'],
  "frausto": ['F R AO1 S T OW0'],
  "fravel": ['F R AE1 V AH0 L'],
  "frawley": ['F R AO1 L IY0'],
  "fray": ['F R EY1'],
  "frayed": ['F R EY1 D'],
  "frayer": ['F R EY1 ER0'],
  "fraying": ['F R EY1 IH0 NG'],
  "frayn": ['F R EY1 N'],
  "frayne": ['F R EY1 N'],
  "fraze": ['F R EY1 Z'],
  "frazee": ['F R AH0 Z IY1'],
  "frazer": ['F R EY1 Z ER0'],
  "frazier": ['F R EY1 ZH ER0'],
  "frazzano": ['F R AH0 Z AA1 N OW0'],
  "frazzini": ['F R AA0 T S IY1 N IY0'],
  "frazzle": ['F R AE1 Z AH0 L'],
  "frazzled": ['F R AE1 Z AH0 L D'],
  "fread": ['F R EH1 D'],
  "freak": ['F R IY1 K'],
  "freaked": ['F R IY1 K T'],
  "freaking": ['F R IY1 K IH0 NG'],
  "freaks": ['F R IY1 K S'],
  "freakshow": ['F R IY1 K S HH OW2'],
  "freaky": ['F R IY1 K IY0'],
  "frean": ['F R IY1 N'],
  "frear": ['F R IH1 R'],
  "frears": ['F R IY1 R Z'],
  "freas": ['F R IY1 Z'],
  "frease": ['F R IY1 Z'],
  "freberg": ['F R IY1 B ER0 G'],
  "frech": ['F R EH1 K'],
  "frechette": ['F R IH0 SH EH1 T'],
  "freck": ['F R EH1 K'],
  "freckle": ['F R EH1 K AH0 L'],
  "freckled": ['F R EH1 K AH0 L D'],
  "freckles": ['F R EH1 K AH0 L Z'],
  "fred": ['F R EH1 D'],
  "fred's": ['F R EH1 D Z'],
  "freda": ['F R EH1 D'],
  "freddie": ['F R EH1 D IY0'],
  "freddy": ['F R EH1 D IY0'],
  "freddy's": ['F R EH1 D IY0 Z'],
  "freddye": ['F R EH1 D IY0'],
  "frede": ['F R IY1 D'],
  "fredell": ['F R EH1 D AH0 L'],
  "fredella": ['F R EH0 D EH1 L AH0'],
  "fredenburg": ['F R IY1 D AH0 N B ER0 G'],
  "frederic": ['F R EH1 D R IH0 K'],
  "frederica": ['F R EH2 D ER0 IY1 K AH0'],
  "frederick": ['F R EH1 D R IH0 K', 'F R EH1 D ER0 IH0 K'],
  "frederick's": ['F R EH1 D R IH0 K S', 'F R EH1 D ER0 IH0 K S'],
  "fredericka": ['F R EY0 D EH0 R IY1 K AH0'],
  "fredericks": ['F R EH1 D R IH0 K S'],
  "fredericksburg": ['F R EH1 D R IH0 K S B ER0 G', 'F R EH1 D ER0 IH0 K S B ER0 G'],
  "fredericksen": ['F R EH1 D ER0 IH0 K S AH0 N', 'F R EH1 D R IH0 K S AH0 N'],
  "frederickson": ['F R EH1 D ER0 IH0 K S AH0 N', 'F R EH1 D R IH0 K S AH0 N'],
  "frederico": ['F R EH0 D ER0 IY1 K OW0'],
  "frederika": ['F R EY0 D EH0 R IY1 K AH0'],
  "frederiksen": ['F R EH1 D ER0 IH0 K S AH0 N'],
  "frederking": ['F R EH1 D ER0 K IH2 NG', 'F R IY1 D ER0 K IH2 NG'],
  "fredette": ['F R IH0 D EH1 T'],
  "frediani": ['F R EH0 D IY0 AA1 N IY0'],
  "fredin": ['F R EH1 D IH0 N'],
  "fredkin": ['F R EH1 D K IH0 N'],
  "fredlund": ['F R EH1 D L AH0 N D'],
  "fredman": ['F R EH1 D M AH0 N'],
  "fredo": ['F R IY1 D OW0'],
  "fredric": ['F R EH1 D R IH0 K'],
  "fredrich": ['F R EH1 D R IH0 K'],
  "fredrick": ['F R EH1 D R IH0 K'],
  "fredricks": ['F R EH1 D R IH0 K S'],
  "fredricksen": ['F R EH1 D R IH0 K S AH0 N'],
  "fredrickson": ['F R EH1 D R IH0 K S AH0 N'],
  "fredriksen": ['F R IH0 D R IH1 K S AH0 N'],
  "fredrikson": ['F R EH1 D R IH0 K S AH0 N'],
  "free": ['F R IY1'],
  "free-for-all": ['F R IY1 F AO0 R AO2 L'],
  "freeberg": ['F R IY1 B ER0 G'],
  "freeberg's": ['F R IY1 B ER0 G Z'],
  "freebie": ['F R IY1 B IY0'],
  "freebies": ['F R IY1 B IY0 Z'],
  "freeborn": ['F R IY1 B ER0 N'],
  "freeburg": ['F R IY1 B ER0 G'],
  "freeburn": ['F R IY1 B ER2 N'],
  "freed": ['F R IY1 D'],
  "freed's": ['F R IY1 D Z'],
  "freedenberg": ['F R IY1 D EH2 N B ER0 G'],
  "freedenberg's": ['F R IY1 D EH2 N B ER0 G Z'],
  "freedland": ['F R IY1 D L AH0 N D'],
  "freedle": ['F R IY1 D AH0 L'],
  "freedman": ['F R IY1 D M AH0 N'],
  "freedman's": ['F R IY1 D M AH0 N Z'],
  "freedom": ['F R IY1 D AH0 M'],
  "freedom's": ['F R IY1 D AH0 M Z'],
  "freedoms": ['F R IY1 D AH0 M Z'],
  "freefall": ['F R IY1 F AO2 L'],
  "freeform": ['F R IY1 F AO2 R M'],
  "freeh": ['F R IY1'],
  "freeh's": ['F R IY1 Z'],
  "freehand": ['F R IY1 HH AE2 N D'],
  "freehling": ['F R IY1 L IH0 NG'],
  "freehoff": ['F R IY1 HH AO0 F'],
  "freehoff's": ['F R IY1 HH AO0 F S'],
  "freehold": ['F R IY1 HH OW2 L D'],
  "freeholder": ['F R IY1 HH OW2 L D ER0'],
  "freeholders": ['F R IY1 HH OW2 L D ER0 Z'],
  "freeing": ['F R IY1 IH0 NG'],
  "freel": ['F R IY1 L'],
  "freelance": ['F R IY1 L AE2 N S'],
  "freelancer": ['F R IY1 L AE2 N S ER0'],
  "freelancers": ['F R IY1 L AE2 N S ER0 Z'],
  "freelancing": ['F R IY1 L AE2 N S IH0 NG'],
  "freeland": ['F R IY1 L AH0 N D'],
  "freeloader": ['F R IY1 L OW2 D ER0'],
  "freeloaders": ['F R IY1 L OW2 D ER0 Z'],
  "freelove": ['F R IY1 L AH2 V'],
  "freels": ['F R IY1 L Z'],
  "freely": ['F R IY1 L IY0'],
  "freeman": ['F R IY1 M AH0 N'],
  "freeman's": ['F R IY1 M AH0 N Z'],
  "freemans": ['F R IY1 M AH0 N Z'],
  "freemason": ['F R IY1 M EY1 S AH0 N'],
  "freemasonry": ['F R IY1 M EY1 S AH0 N R IY0'],
  "freemen": ['F R IY1 M EH0 N'],
  "freemen's": ['F R IY1 M EH0 N Z'],
  "freemon": ['F R IY1 M AH0 N'],
  "freemont": ['F R IY1 M AA2 N T'],
  "freemyer": ['F R IY1 M IY0 ER0'],
  "freen": ['F R IY1 N'],
  "freeney": ['F R IY1 N IY0'],
  "freeny": ['F R IY1 N IY0'],
  "freeport": ['F R IY1 P AO2 R T'],
  "freeport's": ['F R IY1 P AO2 R T S'],
  "freer": ['F R IY1 ER0'],
  "frees": ['F R IY1 Z'],
  "freese": ['F R IY1 Z'],
  "freest": ['F R IY1 IH0 S T'],
  "freestanding": ['F R IY1 S T AE2 N D IH0 NG'],
  "freestone": ['F R IY1 S T OW2 N'],
  "freestyle": ['F R IY1 S T AY2 L'],
  "freet": ['F R IY1 T'],
  "freethinker": ['F R IY1 TH IH1 NG K ER0'],
  "freethinkers": ['F R IY1 TH IH1 NG K ER0 Z'],
  "freetown": ['F R IY1 T AW2 N'],
  "freeware": ['F R IY1 W EH2 R'],
  "freeway": ['F R IY1 W EY2'],
  "freeways": ['F R IY1 W EY2 Z'],
  "freewheeling": ['F R IY1 W IY2 L IH0 NG', 'F R IY1 HH W IY2 L IH0 NG'],
  "freewing": ['F R IY1 W IH0 NG'],
  "freeze": ['F R IY1 Z'],
  "freezer": ['F R IY1 Z ER0'],
  "freezers": ['F R IY1 Z ER0 Z'],
  "freezes": ['F R IY1 Z IH0 Z'],
  "freezing": ['F R IY1 Z IH0 NG'],
  "fregeau": ['F R IH0 ZH OW1'],
  "fregia": ['F R IY1 JH IY0 AH0'],
  "fregoso": ['F R EH0 G OW1 S OW0'],
  "frei": ['F R AY1'],
  "freia": ['F R IY1 AH0'],
  "freiberg": ['F R AY1 B ER0 G'],
  "freiberger": ['F R AY1 B ER0 G ER0'],
  "freiburger": ['F R AY1 B ER0 G ER0'],
  "freid": ['F R IY1 D'],
  "freidan": ['F R IY1 D AH0 N'],
  "freidel": ['F R AY1 D AH0 L'],
  "freidman": ['F R AY1 D M AH0 N', 'F R IY1 D M AH0 N'],
  "freidy": ['F R IY1 D IY0', 'F R AY1 D IY0'],
  "freidy's": ['F R IY1 D IY0 Z', 'F R AY1 D IY0 Z'],
  "freier": ['F R EY1 ER0'],
  "freiermuth": ['F R AY1 ER0 M UW0 TH'],
  "freight": ['F R EY1 T'],
  "freighter": ['F R EY1 T ER0'],
  "freighters": ['F R EY1 T ER0 Z'],
  "freightliner": ['F R EY1 T L AY2 N ER0'],
  "freightway": ['F R EY1 T W EY2'],
  "freightways": ['F R EY1 T W EY2 Z'],
  "freiheit": ['F R AY1 HH AY2 T'],
  "freije": ['F R IY1 JH'],
  "freilich": ['F R AY1 L IH0 K'],
  "freiman": ['F R AY1 M AH0 N', 'F R IY1 M AH0 N'],
  "freimark": ['F R AY1 M AA0 R K'],
  "freimuth": ['F R AY1 M UW0 TH'],
  "frein": ['F R EY1 N'],
  "freind": ['F R AY1 N D'],
  "freire": ['F R IH1 R'],
  "freis": ['F R IY1 Z'],
  "freise": ['F R IY1 S'],
  "freitag": ['F R AY1 T AH0 G'],
  "freitas": ['F R AY1 T AH0 Z'],
  "freiwald": ['F R AY1 W AO2 L D'],
  "frel": ['F R EH1 L'],
  "frelimo": ['F R EH1 L IH0 M OW2'],
  "frels": ['F R EH1 L Z'],
  "fremd": ['F R EH1 M D'],
  "fremd's": ['F R EH1 M D Z'],
  "fremiet": ['F R EH1 M Y EY0'],
  "fremin": ['F R EH1 M IH0 N'],
  "fremitus": ['F R EH1 M AH0 T AH0 S'],
  "fremont": ['F R IY1 M AA0 N T'],
  "french": ['F R EH1 N CH'],
  "french's": ['F R EH1 N CH IH0 Z'],
  "french-polynesia": ['F R EH1 N CH P AA2 L IH0 N IY1 ZH AH0'],
  "frenchman": ['F R EH1 N CH M AE0 N'],
  "frenchmen": ['F R EH1 N CH M EH0 N'],
  "frenetic": ['F R AH0 N EH1 T IH0 K'],
  "frenette": ['F R IH0 N EH1 T'],
  "freni": ['F R EH1 N IY0'],
  "frenkel": ['F R EH1 NG K AH0 L'],
  "frentz": ['F R EH1 N T S'],
  "frenulum": ['F R EH1 N Y AH0 L AH0 M'],
  "frenz": ['F R EH1 N Z'],
  "frenzel": ['F R EH1 N Z AH0 L', 'F R EH2 N Z EH1 L'],
  "frenzied": ['F R EH1 N Z IY0 D'],
  "frenzies": ['F R EH1 N Z IY0 Z'],
  "frenzy": ['F R EH1 N Z IY0'],
  "freon": ['F R IY1 AA0 N'],
  "freons": ['F R IY1 AA0 N Z'],
  "frequencies": ['F R IY1 K W AH0 N S IY0 Z'],
  "frequency": ['F R IY1 K W AH0 N S IY0'],
  "frequent": ['F R IY1 K W AH0 N T', 'F R IY1 K W EH2 N T'],
  "frequented": ['F R IY1 K W AH0 N T IH0 D'],
  "frequenting": ['F R IY1 K W AH0 N T IH0 NG', 'F R IY1 K W EH2 N T IH0 NG'],
  "frequently": ['F R IY1 K W AH0 N T L IY0', 'F R IY1 K W EH0 N T L IY0'],
  "frequents": ['F R IY1 K W AH0 N T S', 'F R IY1 K W EH2 N T S'],
  "frere": ['F R EH1 R', 'F R IH1 R'],
  "freres": ['F R EH1 R Z', 'F R IY1 R Z'],
  "frerichs": ['F R EH1 R IH0 K S'],
  "frericks": ['F R EH1 R IH0 K S'],
  "frerika": ['F R EH1 R IH0 K AH0'],
  "frerking": ['F R ER1 K IH0 NG'],
  "fresco": ['F R EH1 S K OW0'],
  "fresco's": ['F R EH1 S K OW0 Z'],
  "frescoed": ['F R EH1 S K OW0 D'],
  "frescoes": ['F R EH1 S K OW0 Z'],
  "frese": ['F R IY1 Z'],
  "fresenius": ['F R AH0 S IY1 N IY0 AH0 S'],
  "fresh": ['F R EH1 SH'],
  "freshbake": ['F R EH1 SH B EY2 K'],
  "freshbake's": ['F R EH1 SH B EY2 K S'],
  "freshen": ['F R EH1 SH AH0 N'],
  "freshened": ['F R EH1 SH AH0 N D'],
  "freshener": ['F R EH1 SH AH0 N ER0', 'F R EH1 SH N ER0'],
  "fresheners": ['F R EH1 SH AH0 N ER0 Z', 'F R EH1 SH N ER0 Z'],
  "freshening": ['F R EH1 SH AH0 N IH0 NG', 'F R EH1 SH N IH0 NG'],
  "freshens": ['F R EH1 SH AH0 N Z'],
  "fresher": ['F R EH1 SH ER0'],
  "freshest": ['F R EH1 SH AH0 S T'],
  "freshkill": ['F R EH1 SH K IH2 L'],
  "freshkills": ['F R EH1 SH K IH2 L Z'],
  "freshley": ['F R EH1 SH L IY0'],
  "freshly": ['F R EH1 SH L IY0'],
  "freshman": ['F R EH1 SH M AH0 N'],
  "freshmen": ['F R EH1 SH M IH0 N'],
  "freshness": ['F R EH1 SH N AH0 S'],
  "freshour": ['F R EH1 S AW0 R'],
  "freshwater": ['F R EH1 SH W AO2 T ER0'],
  "fresno": ['F R EH1 Z N OW0'],
  "fresno's": ['F R EH1 Z N OW0 Z'],
  "fresquez": ['F R EY0 S K W EH1 Z'],
  "freston": ['F R EH1 S T AH0 N'],
  "fret": ['F R EH1 T'],
  "fretful": ['F R EH1 T F AH0 L'],
  "frets": ['F R EH1 T S'],
  "frett": ['F R EH1 T'],
  "fretted": ['F R EH1 T IH0 D'],
  "fretter": ['F R EH1 T ER0'],
  "fretting": ['F R EH1 T IH0 NG'],
  "fretwell": ['F R EH1 T W EH2 L'],
  "fretz": ['F R EH1 T S'],
  "freud": ['F R OY1 D'],
  "freud's": ['F R OY1 D Z'],
  "freudenberg": ['F R OY1 D AH0 N B ER0 G'],
  "freudenthal": ['F R OY1 D IH0 N TH AH0 L'],
  "freudian": ['F R UW1 D IY0 AH0 N'],
  "freund": ['F R UW1 N D'],
  "freundlich": ['F R OY1 N D L IH0 K'],
  "frevert": ['F R EH1 V ER0 T'],
  "frew": ['F R UW1'],
  "frewen": ['F R UW1 AH0 N'],
  "frewin": ['F R UW1 IH0 N'],
  "frey": ['F R EY1'],
  "frey's": ['F R EY1 Z'],
  "freya": ['F R EY1 AH0'],
  "freyer": ['F R EY1 ER0'],
  "freyermuth": ['F ER1 AY0 R M UW0 TH'],
  "freyman": ['F R EY1 M AH0 N'],
  "freymiller": ['F R EY1 M IH2 L ER0'],
  "freyne": ['F R EY1 N'],
  "freyre": ['F R EH1 R'],
  "freytag": ['F R EY1 T AH0 G'],
  "frezza": ['F R EH1 Z AH0'],
  "friar": ['F R AY1 ER0'],
  "friars": ['F R AY1 ER0 Z'],
  "friary": ['F R AY1 ER0 IY0'],
  "frias": ['F R IY1 AH0 Z'],
  "friberg": ['F R AY1 B ER0 G'],
  "fribourg": ['F R AY1 B AO2 R G'],
  "fricano": ['F R IY0 K AA1 N OW0'],
  "frick": ['F R IH1 K'],
  "fricke": ['F R IH1 K'],
  "fricker": ['F R IH1 K ER0'],
  "frickey": ['F R IH1 K IY0'],
  "fricking": ['F R IH1 K IH0 NG'],
  "fricks": ['F R IH1 K S'],
  "friction": ['F R IH1 K SH AH0 N'],
  "frictionless": ['F R IH1 K SH AH0 N L AH0 S'],
  "frictions": ['F R IH1 K SH AH0 N Z'],
  "frida": ['F R IY1 D AH0'],
  "friday": ['F R AY1 D IY0', 'F R AY1 D EY2'],
  "friday's": ['F R AY1 D IY0 Z', 'F R AY1 D EY2 Z'],
  "fridays": ['F R AY1 D IY0 Z', 'F R AY1 D EY2 Z'],
  "friddle": ['F R IH1 D AH0 L'],
  "fridge": ['F R IH1 JH'],
  "fridges": ['F R IH1 JH AH0 Z'],
  "fridley": ['F R IH1 D L IY0'],
  "fridman": ['F R IH1 D M AH0 N'],
  "fridolf": ['F R IH1 D OW0 L F'],
  "fridovich": ['F R IH1 D AH0 V IH0 CH'],
  "fridrich": ['F R IH1 D R IH0 K'],
  "fridson": ['F R IH1 D S AH0 N'],
  "frie": ['F R AY1'],
  "friebel": ['F R IY1 B AH0 L'],
  "fried": ['F R AY1 D', 'F R IY1 D'],
  "frieda": ['F R IY1 D AH0'],
  "friedan": ['F R IY1 D AH0 N'],
  "friedberg": ['F R AY1 D B ER0 G'],
  "friede": ['F R IY1 D'],
  "friedel": ['F R IY1 D AH0 L'],
  "frieden": ['F R IY1 D AH0 N'],
  "friedenberg": ['F R IY1 D AH0 N B ER0 G'],
  "frieder": ['F R AY1 D ER0'],
  "friederich": ['F R IY1 D ER0 IH0 K'],
  "friederike": ['F R IY1 D ER0 IH0 K'],
  "friedhelm": ['F R IY1 D HH EH2 L M'],
  "friedhoff": ['F R IY1 D HH AO2 F'],
  "friedl": ['F R IY1 D AH0 L'],
  "friedland": ['F R IY1 D L AH0 N D'],
  "friedlander": ['F R IY1 D L AH0 N D ER0'],
  "friedley": ['F R IY1 D L IY0'],
  "friedli": ['F R IY1 D L IY2'],
  "friedline": ['F R IY1 D L AY2 N'],
  "friedly": ['F R IY1 D L IY0'],
  "friedman": ['F R IY1 D M AH0 N'],
  "friedman's": ['F R IY1 D M AH0 N Z'],
  "friedmann": ['F R IY1 D M AH0 N'],
  "friedrich": ['F R IY1 D R IH0 K'],
  "friedrichs": ['F R IY1 D R IH0 K S'],
  "friedrichsen": ['F R IY1 D R IH0 K S AH0 N'],
  "friedrick": ['F R IY1 D R IH0 K'],
  "friedsam": ['F R IY1 D S AE2 M'],
  "friedson": ['F R IY1 D S AH0 N'],
  "friedt": ['F R IY1 D T'],
  "friel": ['F R IY1 L'],
  "frieling": ['F R IY1 L IH0 NG'],
  "frieman": ['F R IY1 M AH0 N'],
  "friend": ['F R EH1 N D'],
  "friend's": ['F R EH1 N D Z', 'F R EH1 N Z'],
  "friendlier": ['F R EH1 N D L IY0 ER0', 'F R EH1 N L IY0 ER0'],
  "friendliest": ['F R EH1 N D L IY0 AH0 S T', 'F R EH1 N L IY0 AH0 S T'],
  "friendliness": ['F R EH1 N D L IY0 N IH0 S', 'F R EH1 N L IY0 N IH0 S'],
  "friendly": ['F R EH1 N D L IY0', 'F R EH1 N L IY0'],
  "friendly's": ['F R EH1 N D L IY0 Z', 'F R EH1 N L IY0 Z'],
  "friends": ['F R EH1 N D Z', 'F R EH1 N Z'],
  "friends'": ['F R EH1 N D Z', 'F R EH1 N Z'],
  "friendship": ['F R EH1 N D SH IH0 P', 'F R EH1 N SH IH0 P'],
  "friendships": ['F R EH1 N D SH IH0 P S', 'F R EH1 N SH IH0 P S'],
  "frier": ['F R AY1 ER0'],
  "frierson": ['F R IH1 R S AH0 N'],
  "fries": ['F R AY1 Z'],
  "friese": ['F R IY1 Z'],
  "friesen": ['F R IY1 S AH0 N'],
  "friesenhahn": ['F R IY1 S IH0 N HH AH0 N'],
  "friesner": ['F R IY1 S N ER0'],
  "frieson": ['F R IY1 Z AH0 N'],
  "friess": ['F R IY1 S'],
  "friesz": ['F R IY1 SH'],
  "frieze": ['F R IY1 Z'],
  "friezes": ['F R IY1 Z IH0 Z'],
  "frig": ['F R IH1 G'],
  "frigate": ['F R IH1 G AH0 T'],
  "frigate's": ['F R IH1 G AH0 T S'],
  "frigates": ['F R IH1 G AH0 T S'],
  "frigging": ['F R IH1 G IH0 NG'],
  "fright": ['F R AY1 T'],
  "frighten": ['F R AY1 T AH0 N'],
  "frightened": ['F R AY1 T AH0 N D'],
  "frightening": ['F R AY1 T AH0 N IH0 NG', 'F R AY1 T N IH0 NG'],
  "frighteningly": ['F R AY1 T AH0 N IH0 NG L IY0'],
  "frightens": ['F R AY1 T AH0 N Z'],
  "frightful": ['F R AY1 T F AH0 L'],
  "frightfully": ['F R AY1 T F AH0 L IY0'],
  "frigid": ['F R IH1 JH AH0 D'],
  "frigo": ['F R IY1 G OW0'],
  "frigon": ['F R IH1 G AH0 N'],
  "frigs": ['F R IH1 G Z'],
  "friis": ['F R IY1 Z'],
  "frikkie": ['F R IH1 K IY0'],
  "fril": ['F R IH1 L'],
  "friley": ['F R IH1 L IY0'],
  "frill": ['F R IH1 L'],
  "frills": ['F R IH1 L Z'],
  "frilly": ['F R IH1 L IY0'],
  "fringe": ['F R IH1 N JH'],
  "fringer": ['F R IH1 N JH ER0'],
  "fringes": ['F R IH1 N JH IH0 Z'],
  "frink": ['F R IH1 NG K'],
  "friona": ['F R IY0 OW1 N AH0'],
  "frisa": ['F R IY1 Z AH0'],
  "frisbee": ['F R IH1 S B IY0'],
  "frisbie": ['F R IH1 S B IY0'],
  "frisby": ['F R IH1 S B IY0'],
  "frisch": ['F R IH1 SH'],
  "friscia": ['F R IY1 S CH AH0'],
  "frisco": ['F R IY1 S K OW0'],
  "frisella": ['F R IH0 S EH1 L AH0'],
  "frishman": ['F R IH1 SH M AH0 N'],
  "frisina": ['F R IY0 S IY1 N AH0'],
  "frisinger": ['F R IH1 S IH0 N JH ER0'],
  "frisk": ['F R IH1 S K'],
  "friske": ['F R IH1 S K'],
  "frisked": ['F R IH1 S K T'],
  "friskin": ['F R IH1 S K IH0 N'],
  "frisky": ['F R IH1 S K IY0'],
  "frison": ['F R IH1 S AH0 N'],
  "frist": ['F R IH1 S T'],
  "fristoe": ['F R IH1 S T OW0'],
  "fritch": ['F R IH1 CH'],
  "fritcher": ['F R IH1 CH ER0'],
  "fritchman": ['F R IH1 CH M AH0 N'],
  "frites": ['F R IY1 T S'],
  "frith": ['F R IH1 TH'],
  "frito": ['F R IY1 T OW0'],
  "fritos": ['F R IY1 T OW0 Z'],
  "frits": ['F R IH1 T S'],
  "fritsch": ['F R IH1 CH'],
  "fritsche": ['F R IH1 CH'],
  "fritter": ['F R IH1 T ER0'],
  "frittered": ['F R IH1 T ER0 D'],
  "frittering": ['F R IH1 T ER0 IH0 NG'],
  "fritts": ['F R IH1 T S'],
  "fritz": ['F R IH1 T S'],
  "fritze": ['F R IH1 T Z'],
  "fritzen": ['F R IH1 T Z AH0 N'],
  "fritzi": ['F R IY1 T Z IY0'],
  "fritzie": ['F R IH1 T Z IY0'],
  "fritzinger": ['F R IH1 T Z IH0 NG ER0'],
  "fritzler": ['F R IH1 T S L ER0'],
  "fritzsche": ['F R IH1 T Z SH', 'F R IH1 T SH', 'F R IH1 CH'],
  "frivolity": ['F R AH0 V AA1 L AH0 T IY0'],
  "frivolous": ['F R IH1 V AH0 L AH0 S'],
  "frivolously": ['F R IH1 V AH0 L AH0 S L IY0'],
  "frix": ['F R IH1 K S'],
  "frizell": ['F R IH1 Z AH0 L'],
  "frizz": ['F R IH1 Z'],
  "frizzell": ['F R IH1 Z AH0 L'],
  "frizzle": ['F R IH1 Z AH0 L'],
  "frizzled": ['F R IH1 Z AH0 L D'],
  "fro": ['F R OW1'],
  "froberg": ['F R OW1 B ER0 G'],
  "frock": ['F R AA1 K'],
  "frocks": ['F R AA1 K S'],
  "froder": ['F R OW1 D ER0'],
  "frodo": ['F R OW1 D OW2'],
  "froedge": ['F R OW1 JH'],
  "froehlich": ['F R OW1 L IH0 K'],
  "froelich": ['F R OW1 L IH0 K'],
  "froemming": ['F R OW1 M IH0 NG'],
  "froese": ['F R OW1 S'],
  "frog": ['F R AA1 G'],
  "frog's": ['F R AA1 G Z'],
  "frogg": ['F R AA1 G'],
  "frogge": ['F R AA1 G'],
  "frogman": ['F R AA1 G M AE2 N'],
  "frogmen": ['F R AA1 G M IH0 N'],
  "frogmouth": ['F R AA1 G M AW2 TH'],
  "frogmouths": ['F R AA1 G M AW2 TH S', 'F R AA1 G M AW2 DH Z'],
  "frogs": ['F R AA1 G Z'],
  "froh": ['F R OW1'],
  "frohlich": ['F R OW1 L IH0 K'],
  "frohman": ['F R OW1 M AH0 N'],
  "frohn": ['F R AA1 N'],
  "frohnmayer": ['F R AO1 N M AY2 ER0'],
  "froio": ['F R OW1 IY0 OW0'],
  "frolic": ['F R AA1 L IH0 K'],
  "frolicking": ['F R AA1 L IH0 K IH0 NG'],
  "frolo": ['F R OW1 L OW0'],
  "from": ['F R AH1 M'],
  "froman": ['F R OW1 M AH0 N'],
  "frombuger": ['F R OW1 M B Y UW2 G ER0'],
  "frombuger's": ['F R OW1 M B Y UW2 G ER0 Z'],
  "frome": ['F R OW1 M'],
  "fromer": ['F R OW1 M ER0'],
  "fromm": ['F R AA1 M'],
  "fromme": ['F R AA1 M'],
  "frommelt": ['F R AA1 M IH0 L T'],
  "frommer": ['F R AA1 M ER0'],
  "fromstein": ['F R AA1 M S T IY2 N', 'F R AA1 M S T AY2 N'],
  "fronczak": ['F R AA1 N CH AE0 K'],
  "frond": ['F R AA1 N D'],
  "fronde": ['F R AA1 N D'],
  "fronds": ['F R AA1 N D Z'],
  "fronek": ['F R OW1 N IH0 K'],
  "froning": ['F R OW1 N IH0 NG'],
  "fronk": ['F R AA1 NG K'],
  "front": ['F R AH1 N T'],
  "front's": ['F R AH1 N T S'],
  "frontage": ['F R AH1 N T IH0 JH'],
  "frontal": ['F R AH1 N T AH0 L'],
  "fronted": ['F R AH1 N T IH0 D'],
  "frontera": ['F R OW0 N T EH1 R AH0'],
  "fronterhouse": ['F R AH1 N T ER0 HH AW2 S'],
  "frontier": ['F R AH0 N T IH1 R'],
  "frontier's": ['F R AH0 N T IH1 R Z'],
  "frontiere": ['F R AA0 N T Y EH1 R'],
  "frontieres": ['F R AA0 N T Y EH1 R Z'],
  "frontiers": ['F R AH0 N T IH1 R Z'],
  "fronting": ['F R AH1 N T IH0 NG'],
  "frontline": ['F R AH1 N T L AY2 N'],
  "frontline's": ['F R AH1 N T L AY2 N Z'],
  "frontrunner": ['F R AH1 N T R AH2 N ER0'],
  "frontrunners": ['F R AH1 N T R AH2 N ER0 Z'],
  "fronts": ['F R AH1 N T S'],
  "frontz": ['F R AA1 N T S'],
  "frosch": ['F R AO1 SH'],
  "fross": ['F R AO1 S'],
  "frost": ['F R AO1 S T'],
  "frostban": ['F R AO1 S T B AE2 N'],
  "frostbite": ['F R AO1 S T B AY2 T'],
  "frostbitten": ['F R AO1 S T B IH2 T AH0 N'],
  "frosted": ['F R AO1 S T AH0 D', 'F R AO1 S T IH0 D'],
  "frosting": ['F R AO1 S T IH0 NG'],
  "frostings": ['F R AO1 S T IH0 NG Z'],
  "frosts": ['F R AO1 S T S'],
  "frosty": ['F R AO1 S T IY0'],
  "froth": ['F R AO1 TH'],
  "frothiness": ['F R AO1 TH IY0 N IH0 S'],
  "frothingham": ['F R AO1 TH IH0 NG HH AE2 M'],
  "froths": ['F R AO1 TH S'],
  "frothy": ['F R AO1 TH IY0'],
  "frown": ['F R AW1 N'],
  "frowned": ['F R AW1 N D'],
  "frownfelter": ['F R AW1 N F EH2 L T ER0'],
  "frowning": ['F R AW1 N IH0 NG'],
  "frowningly": ['F R AW1 N IH0 NG L IY0'],
  "frowns": ['F R AW1 N Z'],
  "froylan": ['F R OY1 L AH0 N'],
  "froze": ['F R OW1 Z'],
  "frozen": ['F R OW1 Z AH0 N'],
  "frucher": ['F R UW1 K ER0'],
  "fruchey": ['F R AH1 CH IY0'],
  "fruchter": ['F R AH1 K T ER0'],
  "fruchtman": ['F R AH1 K T M AH0 N'],
  "fructose": ['F R AH1 K T OW2 S'],
  "frueh": ['F R UW1'],
  "fruehauf": ['F R UW1 HH AO2 F'],
  "fruehling": ['F R UW1 L IH0 NG'],
  "frugal": ['F R UW1 G AH0 L'],
  "frugality": ['F R UW0 G AE1 L AH0 T IY0'],
  "frugally": ['F R UW1 G AH0 L IY0'],
  "fruge": ['F R UW1 JH'],
  "fruin": ['F R UW1 IH0 N'],
  "fruit": ['F R UW1 T'],
  "fruitcake": ['F R UW1 T K EY2 K'],
  "fruitcakes": ['F R UW1 T K EY2 K S'],
  "fruitful": ['F R UW1 T F AH0 L'],
  "fruiting": ['F R UW1 T IH0 NG'],
  "fruition": ['F R UW0 IH1 SH AH0 N'],
  "fruitless": ['F R UW1 T L AH0 S'],
  "fruitlessly": ['F R UW1 T L AH0 S L IY0'],
  "fruitopia": ['F R UW2 T OW1 P IY0 AH0'],
  "fruits": ['F R UW1 T S'],
  "fruity": ['F R UW1 T IY0'],
  "frum": ['F R AH1 M'],
  "frumkin": ['F R AH1 M K IH0 N'],
  "frump": ['F R AH1 M P'],
  "frumpy": ['F R AH1 M P IY0'],
  "frunze": ['F R AH1 N Z'],
  "frusen": ['F R UW1 Z AH0 N'],
  "frush": ['F R AH1 SH'],
  "frustrate": ['F R AH1 S T R EY2 T'],
  "frustrated": ['F R AH1 S T R EY2 T AH0 D'],
  "frustrates": ['F R AH1 S T R EY2 T S'],
  "frustrating": ['F R AH1 S T R EY2 T IH0 NG'],
  "frustratingly": ['F R AH1 S T R EY2 T IH0 NG L IY0'],
  "frustration": ['F R AH0 S T R EY1 SH AH0 N'],
  "frustrations": ['F R AH0 S T R EY1 SH AH0 N Z'],
  "frutchey": ['F R AH1 CH IY0'],
  "fruth": ['F R UW1 TH'],
  "frutiger": ['F R AH1 T IH0 G ER0'],
  "fry": ['F R AY1'],
  "fry's": ['F R AY1 Z'],
  "fryar": ['F R AY1 ER0'],
  "fryberger": ['F R AY1 B ER0 G ER0'],
  "frye": ['F R AY1'],
  "fryer": ['F R AY1 ER0'],
  "fryers": ['F R AY1 ER0 Z'],
  "frying": ['F R AY1 IH0 NG'],
  "fryling": ['F R AY1 L IH0 NG'],
  "fryman": ['F R AY1 M AH0 N'],
  "frymire": ['F R IH0 M AY1 R', 'F R IY0 M AY1 R'],
  "fryrear": ['F R AY1 R IH2 R'],
  "frysinger": ['F R IH1 S IH0 N JH ER0'],
  "fs": ['F S', 'EH1 F EH1 S'],
  "fsi": ['F S IY1'],
  "fthenakis": ['F TH EH0 N AA1 K IH0 S'],
  "ftp": ['EH2 F T IY2 P IY1'],
  "fu": ['F UW1'],
  "fua": ['F UW1 AH0'],
  "fucci": ['F UW1 CH IY0'],
  "fuchs": ['F Y UW1 K S'],
  "fuchsias": ['F Y UW1 SH AH0 Z'],
  "fuck": ['F AH1 K'],
  "fuck's": ['F AH1 K S'],
  "fucked": ['F AH1 K T'],
  "fucked-up": ['F AH1 K T AH2 P'],
  "fucker": ['F AH1 K ER0'],
  "fuckers": ['F AH1 K ER0 Z'],
  "fuckin": ['F AH1 K IH0 N'],
  "fucking": ['F AH1 K IH0 NG'],
  "fucks": ['F AH1 K S'],
  "fudala": ['F UW0 D AA1 L AH0'],
  "fuddle": ['F UH1 D AH0 L'],
  "fuddles": ['F UH1 D AH0 L Z'],
  "fuddrucker": ['F AH1 D R AH0 K ER0'],
  "fuddruckers": ['F AH1 D R AH0 K ER0 Z'],
  "fuddy": ['F AH1 D IY0'],
  "fudge": ['F AH1 JH'],
  "fudged": ['F AH1 JH D'],
  "fudging": ['F AH1 JH IH0 NG'],
  "fudgy": ['F AH1 JH IY0'],
  "fudo": ['F Y UW1 D OW0'],
  "fudosan": ['F Y UW1 D AH0 S AA0 N'],
  "fuel": ['F Y UW1 AH0 L', 'F Y UW1 L'],
  "fuel's": ['F Y UW1 AH0 L Z'],
  "fueled": ['F Y UW1 AH0 L D'],
  "fueling": ['F Y UW1 L IH0 NG'],
  "fuelled": ['F Y UW1 AH0 L D'],
  "fuelling": ['F Y UW1 L IH0 NG'],
  "fuels": ['F Y UW1 AH0 L Z'],
  "fuente": ['F W EH1 N T EY2'],
  "fuentes": ['F W EH1 N T EY2 Z'],
  "fuentez": ['F W EH1 N T EY2 Z'],
  "fuer": ['F Y UW1 R'],
  "fuerst": ['F W EH1 R S T'],
  "fuerstenberg": ['F UH1 R S T AH0 N B ER0 G'],
  "fuerth": ['F W ER1 TH'],
  "fugard": ['F Y UW1 G AA2 R D'],
  "fugate": ['F AH1 G EY0 T'],
  "fugazy": ['F Y UW1 G AH0 Z IY0'],
  "fuge": ['F Y UW1 JH'],
  "fugere": ['F Y UW1 G ER0'],
  "fugett": ['F Y UW1 JH IH0 T'],
  "fugitive": ['F Y UW1 JH AH0 T IH0 V', 'F Y UW1 JH IH0 T IH0 V'],
  "fugitives": ['F Y UW1 JH IH0 T IH0 V Z'],
  "fugitt": ['F Y UW1 JH IH0 T'],
  "fugotsten": ['F Y UW1 G AO0 T S T EH0 N'],
  "fugue": ['F Y UW1 G'],
  "fugues": ['F Y UW1 G Z'],
  "fuhr": ['F UH1 R'],
  "fuhrer": ['F UH1 R ER0'],
  "fuhrman": ['F UH1 R M AH0 N'],
  "fuhrman's": ['F UH1 R M AH0 N Z'],
  "fuhrmann": ['F UH1 R M AH0 N'],
  "fuhrmann's": ['F UH1 R M AH0 N Z'],
  "fuhrmans": ['F UH1 R M AH0 N Z'],
  "fuhs": ['F AH1 S'],
  "fuji": ['F UW1 JH IY0'],
  "fuji's": ['F UW1 JH IY0 Z'],
  "fujian": ['F UW2 JH IY1 AH0 N'],
  "fujii": ['F UW0 JH IY1 IY0'],
  "fujikawa": ['F UW2 JH IY0 K AA1 W AH0'],
  "fujikura": ['F UW2 JH IH0 K UW1 R AH0'],
  "fujimori": ['F UW2 JH IY0 M AO1 R IY0'],
  "fujimori's": ['F UW2 JH IY0 M AO1 R IY0 Z'],
  "fujimoto": ['F UW2 JH IY0 M OW1 T OW0'],
  "fujio": ['F UW1 JH IY0 OW0'],
  "fujioka": ['F UW2 JH IY0 OW1 K AH0'],
  "fujis": ['F Y UW1 JH IH0 S'],
  "fujisankei": ['F UW2 JH IH0 S AA1 NG K EY0'],
  "fujisawa": ['F UW2 JH IH0 S AA1 W AH0'],
  "fujita": ['F UW0 JH IY1 T AH0'],
  "fujitsu": ['F UW0 JH IH1 T S UW0'],
  "fujitsu's": ['F UW0 JH IH1 T S UW0 Z'],
  "fujiwara": ['F UW0 JH IY0 W AA1 R AH0'],
  "fujiya": ['F UW0 JH IY1 Y AH0'],
  "fujiyama": ['F UW2 JH IY0 Y AA1 M AH0'],
  "fuke": ['F UW1 K'],
  "fuklah": ['F UW1 K L AH0'],
  "fuklah's": ['F UW1 K L AH0 Z'],
  "fukowoka": ['F UW2 K OW0 W AO1 K AH0'],
  "fukuda": ['F UW0 K UW1 D AH0'],
  "fukui": ['F UW0 K UW1 IY0'],
  "fukumoto": ['F UW0 K UW0 M OW1 T OW0'],
  "fukunaga": ['F UW0 K UW0 N AA1 G AH0'],
  "fukuoka": ['F UW2 K UW2 OW1 K AH0'],
  "fukushima": ['F UW0 K UW0 SH IY1 M AH0'],
  "fukuyama": ['F UW2 K UW2 Y AA1 M AH0'],
  "fulani": ['F UW2 L AA1 N IY0'],
  "fulbright": ['F UH1 L B R AY2 T'],
  "fulcher": ['F UH1 L CH ER0'],
  "fulco": ['F UH1 L K OW0'],
  "fulcrum": ['F UH1 L K R AH0 M'],
  "fuld": ['F UH1 L D'],
  "fulenwider": ['F Y UW1 L IH0 N W AY0 D ER0'],
  "fulfer": ['F UH1 L F ER0'],
  "fulfil": ['F UH0 L F IH1 L'],
  "fulfill": ['F UH0 L F IH1 L'],
  "fulfilled": ['F UH0 L F IH1 L D'],
  "fulfilling": ['F UH0 L F IH1 L IH0 NG'],
  "fulfillment": ['F UH0 L F IH1 L M AH0 N T'],
  "fulfills": ['F UH0 L F IH1 L Z'],
  "fulford": ['F UH1 L F ER0 D'],
  "fulgencio": ['F AH0 L JH EH1 N S IY0 OW0'],
  "fulgham": ['F AH1 L G AH0 M'],
  "fulghum": ['F AH1 L G AH0 M'],
  "fulginiti": ['F UH2 L JH IH0 N IH1 T IY0'],
  "fulk": ['F AH1 L K'],
  "fulkerson": ['F AH1 L K ER0 S AH0 N'],
  "fulks": ['F AH1 L K S'],
  "full": ['F UH1 L'],
  "full's": ['F UH1 L Z'],
  "full-blown": ['F UH2 L B L OW1 N'],
  "full-scale": ['F UH2 L S K EY1 L'],
  "full-size": ['F UH1 L S AY2 Z'],
  "full-sized": ['F UH1 L S AY2 Z D'],
  "full-time": ['F UH1 L T AY2 M'],
  "fullam": ['F UH1 L AH0 M'],
  "fullard": ['F UH1 L ER0 D'],
  "fullback": ['F UH1 L B AE2 K'],
  "fullbacks": ['F UH1 L B AE2 K S'],
  "fullbright": ['F UH1 L B R AY2 T'],
  "fullen": ['F UH1 L AH0 N'],
  "fullenkamp": ['F UH1 L AH0 N K AE2 M P'],
  "fullenwider": ['F UH1 L AH0 N W AY2 D ER0'],
  "fuller": ['F UH1 L ER0'],
  "fuller's": ['F UH1 L ER0 Z'],
  "fullerton": ['F UH1 L ER0 T AH0 N'],
  "fullest": ['F UH1 L AH0 S T'],
  "fullfare": ['F UH1 L F EH2 R'],
  "fullilove": ['F UW0 L IY0 L OW1 V IY0'],
  "fullington": ['F UH1 L IH0 NG T AH0 N'],
  "fullman": ['F UH1 L M AH0 N'],
  "fullmer": ['F UH1 L M ER0'],
  "fullness": ['F UH1 L N AH0 S'],
  "fulltime": ['F UH1 L T AY2 M'],
  "fullwood": ['F UH1 L W UH2 D'],
  "fully": ['F UH1 L IY0'],
  "fulmer": ['F AH1 L M ER0'],
  "fulminate": ['F UH1 L M AH0 N EY2 T'],
  "fulmore": ['F UH1 L M AO0 R'],
  "fulop": ['F UW1 L AH0 P'],
  "fulp": ['F UH1 L P'],
  "fulsome": ['F UH1 L S AH0 M'],
  "fulton": ['F UH1 L T AH0 N'],
  "fulton's": ['F UH1 L T AH0 N Z'],
  "fults": ['F UH1 L T S'],
  "fultz": ['F UH1 L T S'],
  "fulvia": ['F UH1 L V IY0 AH0'],
  "fulwider": ['F AH1 L W AY0 D ER0'],
  "fulwiler": ['F AH1 L W AY0 L ER0'],
  "fulwood": ['F AH0 L W UH1 D'],
  "fumaroles": ['F Y UW1 M ER0 OW2 L Z'],
  "fumble": ['F AH1 M B AH0 L'],
  "fumbled": ['F AH1 M B AH0 L D'],
  "fumbles": ['F AH1 M B AH0 L Z'],
  "fumbling": ['F AH1 M B AH0 L IH0 NG', 'F AH1 M B L IH0 NG'],
  "fume": ['F Y UW1 M'],
  "fumed": ['F Y UW1 M D'],
  "fumes": ['F Y UW1 M Z'],
  "fumi": ['F UW1 M IY0', 'F Y UW1 M IY0'],
  "fumidor": ['F Y UW1 M AH0 D AO0 R'],
  "fumidors": ['F Y UW1 M AH0 D AO0 R Z'],
  "fumigate": ['F Y UW1 M AH0 G EY2 T'],
  "fumigating": ['F Y UW1 M IH2 G EY2 T IH0 NG'],
  "fumigation": ['F Y UW2 M IH0 G EY1 SH AH0 N'],
  "fuming": ['F Y UW1 M IH0 NG'],
  "fumio": ['F Y UW1 M IY0 OW0'],
  "fun": ['F AH1 N'],
  "funari": ['F UW0 N AA1 R IY0'],
  "funaro": ['F UW0 N AA1 R OW0'],
  "funaro's": ['F UW0 N AA1 R OW0 Z'],
  "funches": ['F AH1 N CH IH0 Z'],
  "funchess": ['F AH1 N K IH0 S'],
  "funck": ['F AH1 NG K'],
  "function": ['F AH1 NG K SH AH0 N'],
  "functional": ['F AH1 NG K SH AH0 N AH0 L'],
  "functionality": ['F AH2 NG K SH AH0 N AE1 L IH0 T IY0'],
  "functionally": ['F AH1 NG K SH AH0 N AH0 L IY0', 'F AH1 NG K SH N AH0 L IY0'],
  "functionaries": ['F AH1 NG K SH AH0 N EH2 R IY0 Z'],
  "functionary": ['F AH1 NG K SH AH0 N EH2 R IY0'],
  "functioned": ['F AH1 NG K SH AH0 N D'],
  "functioning": ['F AH1 NG K SH AH0 N IH0 NG'],
  "functions": ['F AH1 NG K SH AH0 N Z'],
  "fund": ['F AH1 N D'],
  "fund's": ['F AH1 N D Z'],
  "fund-raiser": ['F AH1 N D R EY2 Z ER0'],
  "fundacion": ['F AH0 N D EY1 SH AH0 N', 'F AO1 N D AA0 SH Y OW2 N'],
  "fundamental": ['F AH2 N D AH0 M EH1 N T AH0 L', 'F AH2 N D AH0 M EH1 N AH0 L'],
  "fundamentalism": ['F AH2 N D AH0 M EH1 N T AH0 L IH2 Z AH0 M', 'F AH2 N D AH0 M EH1 N AH0 L IH2 Z AH0 M'],
  "fundamentalist": ['F AH2 N D AH0 M EH1 N T AH0 L IH0 S T', 'F AH2 N D AH0 M EH1 N AH0 L IH0 S T'],
  "fundamentalists": ['F AH2 N D AH0 M EH1 N T AH0 L IH0 S T S', 'F AH2 N D AH0 M EH1 N AH0 L IH0 S T S', 'F AH2 N D AH0 M EH1 N T AH0 L IH0 S', 'F AH2 N D AH0 M EH1 N AH0 L IH0 S'],
  "fundamentally": ['F AH2 N D AH0 M EH1 N T AH0 L IY0', 'F AH2 N D AH0 M EH1 N AH0 L IY0'],
  "fundamentals": ['F AH2 N D AH0 M EH1 N T AH0 L Z', 'F AH2 N D AH0 M EH1 N AH0 L Z'],
  "funded": ['F AH1 N D AH0 D', 'F AH1 N D IH0 D'],
  "funder": ['F AH1 N D ER0'],
  "funderburg": ['F AH1 N D ER0 B ER0 G'],
  "funderburk": ['F AH1 N D ER0 B ER0 K'],
  "funderburke": ['F AH1 N D ER0 B ER2 K'],
  "funders": ['F AH1 N D ER0 Z'],
  "funding": ['F AH1 N D IH0 NG'],
  "fundora": ['F UW0 N D AO1 R AH0'],
  "fundraiser": ['F AH1 N D R EY2 Z ER0'],
  "fundraiser's": ['F AH1 N D R EY2 Z ER0 Z'],
  "fundraisers": ['F AH1 N D R EY2 Z ER0 Z'],
  "fundraising": ['F AH1 N D R EY2 S IH0 NG'],
  "funds": ['F AH1 N D Z'],
  "funds'": ['F AH1 N D Z'],
  "funeral": ['F Y UW1 N ER0 AH0 L'],
  "funerals": ['F Y UW1 N ER0 AH0 L Z'],
  "funerary": ['F Y UW1 N ER0 EH2 R IY0'],
  "funes": ['F Y UW1 N Z'],
  "fung": ['F AH1 NG'],
  "fung's": ['F AH1 NG Z'],
  "fungal": ['F AH1 NG G AH0 L'],
  "fungi": ['F AH1 N JH AY0'],
  "fungible": ['F AH1 N JH IH0 B AH0 L'],
  "fungicide": ['F AH1 N JH AH0 S AY2 D'],
  "fungicides": ['F AH1 N JH AH0 S AY2 D Z'],
  "fungus": ['F AH1 NG G AH0 S'],
  "funicello": ['F AH2 N AH0 CH EH1 L OW0'],
  "funk": ['F AH1 NG K'],
  "funke": ['F AH1 NG K'],
  "funkhouser": ['F AH1 NG K HH AW2 S ER0'],
  "funks": ['F AH1 NG K S'],
  "funky": ['F AH1 NG K IY0'],
  "funnel": ['F AH1 N AH0 L'],
  "funneled": ['F AH1 N AH0 L D'],
  "funneling": ['F AH1 N AH0 L IH0 NG', 'F AH1 N L IH0 NG'],
  "funnell": ['F AH1 N AH0 L'],
  "funnels": ['F AH1 N AH0 L Z'],
  "funnest": ['F AH1 N IH0 S T'],
  "funnier": ['F AH1 N IY0 ER0'],
  "funniest": ['F AH1 N IY0 AH0 S T'],
  "funniness": ['F AH1 N IY0 N AH0 S'],
  "funny": ['F AH1 N IY0'],
  "funs": ['F AH1 N Z'],
  "funston": ['F AH1 N S T AH0 N'],
  "funtime": ['F AH1 N T AY2 M'],
  "fuoco": ['F UW0 OW1 K OW0'],
  "fuoss": ['F UW1 S'],
  "fuqua": ['F UW1 K W AH0'],
  "fuquay": ['F UW1 K EY0'],
  "fur": ['F ER1'],
  "furash": ['F Y ER0 AE1 SH'],
  "furbee": ['F ER1 B IY2'],
  "furber": ['F ER1 B ER0'],
  "furbish": ['F ER1 B IH0 SH'],
  "furbished": ['F ER1 B IH0 SH T'],
  "furbishes": ['F ER1 B IH0 SH AH0 Z'],
  "furbishing": ['F ER1 B IH0 SH IH0 NG'],
  "furbush": ['F ER1 B UH2 SH'],
  "furby": ['F ER1 B IY0'],
  "furches": ['F ER0 SH IY1 Z'],
  "furer": ['F Y UH1 R ER0'],
  "furey": ['F Y UH1 R IY0'],
  "furfaro": ['F UH0 R F AA1 R OW0'],
  "furgason": ['F ER1 G AH0 S AH0 N'],
  "furgerson": ['F ER1 G ER0 S AH0 N'],
  "furgeson": ['F ER1 G IH0 S AH0 N'],
  "furia": ['F Y UH1 R IY0 AH0'],
  "furini": ['F UH0 R IY1 N IY0'],
  "furini's": ['F UH0 R IY1 N IY0 Z'],
  "furino": ['F UH0 R IY1 N OW0'],
  "furious": ['F Y UH1 R IY0 AH0 S'],
  "furiouser": ['F Y UH1 R IY0 AH0 S ER0'],
  "furiously": ['F Y UH1 R IY0 AH0 S L IY0'],
  "furlan": ['F ER1 L AH0 N'],
  "furlaud": ['F ER0 L OW1'],
  "furlett": ['F ER0 L EH1 T', 'F ER1 L AH0 T'],
  "furlong": ['F ER1 L AO2 NG'],
  "furlough": ['F ER1 L OW0'],
  "furloughed": ['F ER1 L OW0 D'],
  "furloughs": ['F ER1 L OW0 Z'],
  "furlow": ['F ER1 L OW2'],
  "furman": ['F ER1 M AE2 N'],
  "furmark": ['F ER1 M AA2 R K'],
  "furnace": ['F ER1 N AH0 S'],
  "furnace's": ['F ER1 N AH2 S IH0 Z'],
  "furnaces": ['F ER1 N AH2 S IH0 Z'],
  "furnari": ['F UH0 R N AA1 R IY0'],
  "furnas": ['F ER1 N AH0 S'],
  "furner": ['F ER1 N ER0'],
  "furness": ['F ER1 N IH0 S'],
  "furney": ['F ER1 N IY0'],
  "furnish": ['F ER1 N IH0 SH'],
  "furnished": ['F ER1 N IH0 SH T'],
  "furnishes": ['F ER1 N IH0 SH IH0 Z'],
  "furnishing": ['F ER1 N IH0 SH IH0 NG'],
  "furnishings": ['F ER1 N IH0 SH IH0 NG Z'],
  "furniss": ['F ER0 N IH1 S'],
  "furniture": ['F ER1 N IH0 CH ER0'],
  "furniture's": ['F ER1 N IH0 CH ER0 Z'],
  "furno": ['F UH1 R N OW0'],
  "furor": ['F Y UH1 R AO0 R'],
  "furr": ['F ER1'],
  "furr's": ['F ER1 Z'],
  "furrer": ['F ER1 ER0'],
  "furrh": ['F ER1'],
  "furrier": ['F ER1 IY0 ER0'],
  "furriers": ['F ER1 IY0 ER0 Z'],
  "furrow": ['F ER1 OW0'],
  "furrowed": ['F ER1 OW0 D'],
  "furry": ['F ER1 IY0'],
  "furs": ['F ER1 Z'],
  "furse": ['F ER1 S'],
  "furst": ['F ER1 S T'],
  "furstenberg": ['F ER1 S T AH0 N B ER0 G'],
  "furtado": ['F UH0 R T AA1 D OW0'],
  "furtak": ['F ER1 T AH0 K'],
  "furtaw": ['F ER1 T AO0'],
  "furth": ['F ER1 TH'],
  "further": ['F ER1 DH ER0'],
  "furtherance": ['F ER1 TH ER0 AH0 N S'],
  "furthered": ['F ER1 DH ER0 D'],
  "furthering": ['F ER1 DH ER0 IH0 NG'],
  "furthermore": ['F ER1 DH ER0 M AO2 R'],
  "furthers": ['F ER1 DH ER0 Z'],
  "furthest": ['F ER1 TH AH0 S T'],
  "furtick": ['F ER1 T IH0 K'],
  "furtive": ['F ER1 T IH0 V'],
  "furtively": ['F ER1 T IH0 V L IY0'],
  "furukawa": ['F UH0 R UW0 K AA1 W AH0'],
  "furuta": ['F ER0 UW1 T AH0'],
  "furuya": ['F UH0 R UW1 Y AH0'],
  "fury": ['F Y UH1 R IY0'],
  "fusaro": ['F UW0 S AA1 R OW0'],
  "fusco": ['F UW1 S K OW0'],
  "fuse": ['F Y UW1 Z'],
  "fused": ['F Y UW1 Z D'],
  "fuselage": ['F Y UW1 S AH0 L AA2 JH', 'F Y UW1 S AH0 L IH0 JH'],
  "fuselages": ['F Y UW1 S AH0 L AA0 JH IH0 Z', 'F Y UW1 S AH0 L IH0 JH IH0 Z'],
  "fuselier": ['F Y UW1 S L IY0 ER0'],
  "fuses": ['F Y UW1 Z AH0 Z', 'F Y UW1 Z IH0 Z'],
  "fusible": ['F Y UW1 Z AH0 B AH0 L'],
  "fusillade": ['F Y UW1 S IH0 L EY2 D'],
  "fusilli": ['F Y UW0 S IH1 L IY0'],
  "fusing": ['F Y UW1 Z IH0 NG'],
  "fusion": ['F Y UW1 ZH AH0 N'],
  "fusner": ['F UW1 Z N ER0'],
  "fuson": ['F UW1 S AH0 N'],
  "fuss": ['F AH1 S'],
  "fussed": ['F AH1 S T'],
  "fussell": ['F AH1 S AH0 L'],
  "fusselman": ['F AH1 S AH0 L M AH0 N'],
  "fusses": ['F AH1 S IH0 Z'],
  "fussing": ['F AH1 S IH0 NG'],
  "fussner": ['F AH1 S N ER0'],
  "fussy": ['F AH1 S IY0'],
  "fust": ['F AH1 S T'],
  "fustat": ['F AH1 S T AE0 T'],
  "fustian": ['F AH1 S T IY2 AH0 N'],
  "fustok": ['F AH1 S T AA0 K'],
  "fuston": ['F AH1 S T AH0 N'],
  "fusty": ['F AH1 S T IY2'],
  "futch": ['F AH1 CH'],
  "futher": ['F AH1 DH ER0'],
  "futile": ['F Y UW1 T AH0 L'],
  "futility": ['F Y UW0 T IH1 L AH0 T IY0'],
  "futon": ['F UW1 T AA0 N'],
  "futons": ['F UW1 T AA0 N Z'],
  "futral": ['F AH1 T R AH0 L'],
  "futrell": ['F Y UW0 T R EH1 L'],
  "futterman": ['F AH1 T ER0 M AH0 N'],
  "future": ['F Y UW1 CH ER0'],
  "future's": ['F Y UW1 CH ER0 Z'],
  "futures": ['F Y UW1 CH ER0 Z'],
  "futures'": ['F Y UW1 CH ER0 Z'],
  "futurism": ['F Y UW1 CH ER0 IH2 Z AH0 M'],
  "futurist": ['F Y UW1 CH ER0 IH0 S T'],
  "futuristic": ['F Y UW2 CH ER0 IH1 S T IH0 K'],
  "futurists": ['F Y UW1 CH ER0 IH0 S T S'],
  "fuzes": ['F Y UW1 Z IH0 Z'],
  "fuzz": ['F AH1 Z'],
  "fuzzier": ['F AH1 Z IY0 ER0'],
  "fuzzy": ['F AH1 Z IY0'],
  "fye": ['F AY1'],
  "fyfe": ['F AY1 F'],
  "fyffe": ['F AY1 F'],
  "fyffes": ['F IH1 F S', 'F AY1 F S'],
  "fyi": ['F AO1 R Y AO1 R IH2 N F ER0 M EY1 SH AH0 N'],
  "fyke": ['F AY1 K'],
  "fyock": ['F Y AA1 K'],
  "fyodor": ['F Y OW1 D ER0'],
  "fyodor's": ['F Y OW1 D ER0 Z'],
  "fyodorov": ['F Y OW1 D ER0 AO2 V'],
  "fyodorov's": ['F Y OW1 D ER0 AO2 V Z'],
  "g": ['JH IY1'],
  "g's": ['JH IY1 Z'],
  "g'vanni's": ['JH IY2 OW0 V AA1 N IY0 Z'],
  "g.": ['JH IY1'],
  "g.'s": ['JH IY1 Z'],
  "g.s": ['JH IY1 Z'],
  "ga": ['G AA1', 'JH IY1 EY1', 'JH AO1 R JH AH0'],
  "gaal": ['G AA1 L'],
  "gaar": ['G AA1 R'],
  "gaarder": ['G AA1 R D ER0'],
  "gab": ['G AE1 B'],
  "gaba": ['G AA1 B AH0'],
  "gabaldon": ['G AA0 B AA0 L D AO1 N'],
  "gabardine": ['G AE1 B ER0 D IY2 N'],
  "gabardines": ['G AE1 B ER0 D IY2 N Z'],
  "gabay": ['G AE1 B EY0'],
  "gabbard": ['G AH0 B AA1 R D'],
  "gabbert": ['G AE1 B ER0 T'],
  "gabbing": ['G AE1 B IH0 NG'],
  "gabbro": ['G AE1 B R OW0'],
  "gabbroic": ['G AE0 B R OW1 IH0 K'],
  "gabby": ['G AE1 B IY0'],
  "gabe": ['G EY1 B'],
  "gabehart": ['G EY1 B HH AA2 R T'],
  "gabeira": ['G AH0 B EY1 R AH0'],
  "gabel": ['G AH0 B EH1 L'],
  "gabele": ['G AH0 B EH1 L EY0'],
  "gabelli": ['G AH0 B EH1 L IY0'],
  "gaber": ['G EY1 B ER0'],
  "gabert": ['G AE1 B ER0 T'],
  "gabhart": ['G AE1 B HH AA2 R T'],
  "gabie": ['G AE1 B IY0'],
  "gable": ['G EY1 B AH0 L'],
  "gabled": ['G EY1 B AH0 L D'],
  "gabler": ['G EY1 B AH0 L ER0', 'G EY1 B L ER0'],
  "gables": ['G EY1 B AH0 L Z'],
  "gabon": ['G AH0 B AA1 N'],
  "gabor": ['G AH0 B AO1 R'],
  "gaboriault": ['G AE1 B ER0 IY0 OW0'],
  "gaboury": ['G AE1 B UH0 R IY0'],
  "gabrall": ['G EY1 B R AH0 L'],
  "gabrall's": ['G EY1 B R AH0 L Z'],
  "gabriel": ['G EY1 B R IY0 AH0 L'],
  "gabriel's": ['G EY1 B R IY0 AH0 L Z'],
  "gabriela": ['G AA2 B R IY0 EH1 L AA2'],
  "gabriele": ['G AA0 B R IY0 EH1 L'],
  "gabriella": ['G AA2 B R IY0 EH1 L AA2'],
  "gabrielle": ['G AE1 B R IY0 EH0 L'],
  "gabrielli": ['G AA2 B R IY0 EH1 L IY2'],
  "gabrielsen": ['G AE1 B R IY0 L S AH0 N', 'G EY1 B R IY0 EH0 L S AH0 N'],
  "gabrielson": ['G AE1 B R IY0 L S AH0 N', 'G EY1 B R IY0 EH0 L S AH0 N'],
  "gabrowny": ['G AH0 B R AW1 N IY0'],
  "gabrys": ['G AE1 B ER0 IY0 Z'],
  "gaby": ['G AE1 B IY0'],
  "gaccione": ['G AA0 K CH OW1 N IY2'],
  "gacek": ['G AA1 CH EH2 K'],
  "gach": ['G AE1 CH'],
  "gacy": ['G EY1 S IY2'],
  "gacy's": ['G EY1 S IY2 Z'],
  "gad": ['G AE1 D'],
  "gadberry": ['G AE1 D B EH2 R IY2'],
  "gadbois": ['G AE1 D B W AA2'],
  "gadd": ['G AE1 D'],
  "gaddie": ['G AE1 D IY0'],
  "gadding": ['G AE1 D IH0 NG'],
  "gaddis": ['G AE1 D IH0 S'],
  "gaddum": ['G AE1 D AH0 M'],
  "gaddy": ['G AE1 D IY0'],
  "gade": ['G EY1 D'],
  "gadflies": ['G AE1 D F L AY2 Z'],
  "gadfly": ['G AE1 D F L AY2'],
  "gadget": ['G AE1 JH AH0 T'],
  "gadget's": ['G AE1 JH AH0 T S'],
  "gadgetry": ['G AE1 JH AH0 T R IY0'],
  "gadgets": ['G AE1 JH AH0 T S'],
  "gadhafi": ['G AH0 D AA1 F IY0', 'G AH0 D HH AA1 F IY0'],
  "gadhafi's": ['G AH0 D AA1 F IY0 Z', 'G AH0 D HH AA1 F IY0 Z'],
  "gadomski": ['G AH0 D AA1 M S K IY0'],
  "gadoury": ['G AE1 D UH0 R IY0'],
  "gads": ['G AE1 D Z'],
  "gadsby": ['G AE1 D Z B IY0'],
  "gadsden": ['G AE1 D Z D AH0 N'],
  "gadson": ['G AE1 D S AH0 N'],
  "gadway": ['G AE1 D W EY2'],
  "gadzinski": ['G AH0 JH IH1 N S K IY0'],
  "gae": ['G AY1'],
  "gaea": ['G IY1 AH0'],
  "gaebel": ['G EH1 B AH0 L'],
  "gaede": ['G IY1 D'],
  "gael": ['G EY1 L'],
  "gaelic": ['G EY1 L IH0 K'],
  "gaertner": ['G EH1 R T N ER0'],
  "gaeta": ['G IY1 T AH0'],
  "gaetano": ['G AY0 T AA1 N OW0'],
  "gaeth": ['G IY1 TH'],
  "gaetz": ['G IY1 T S'],
  "gaf": ['G AE1 F'],
  "gaff": ['G AE1 F'],
  "gaffe": ['G AE1 F'],
  "gaffes": ['G AE1 F S'],
  "gaffey": ['G AE1 F IY0'],
  "gaffin": ['G AE1 F IH0 N'],
  "gaffney": ['G AE1 F N IY0'],
  "gafford": ['G AE1 F ER0 D'],
  "gag": ['G AE1 G'],
  "gaga": ['G AA1 G AH2'],
  "gagan": ['G EY1 G AH0 N'],
  "gagarin": ['G AH0 G AE1 R AH0 N'],
  "gage": ['G EY1 JH'],
  "gagel": ['G AE1 G AH0 L'],
  "gagen": ['G AE1 G AH0 N'],
  "gager": ['G EY1 G ER0'],
  "gagged": ['G AE1 G D'],
  "gaggle": ['G AE1 G AH0 L'],
  "gagliano": ['G AA0 G L IY0 AA1 N OW0', 'G AE0 G L IY0 AA1 N OW0'],
  "gagliardi": ['G AA0 G L IY0 AA1 R D IY0', 'G AE0 G L IY0 AA1 R D IY0'],
  "gagliardo": ['G AA0 G L IY0 AA1 R D OW0', 'G AE0 G L IY0 AA1 R D OW0'],
  "gaglio": ['G AE1 G L IY0 OW0'],
  "gaglione": ['G AA0 G L IY0 OW1 N IY0', 'G AE0 G L IY0 OW1 N IY0'],
  "gagne": ['G EY1 N IY0', 'G AE1 G N IY0'],
  "gagner": ['G AE1 G N ER0'],
  "gagnier": ['G AE1 G N IY0 ER0'],
  "gagnon": ['G AE1 N Y AH0 N'],
  "gags": ['G AE1 G Z'],
  "gahagan": ['G AA0 HH AA1 G AA0 N'],
  "gahan": ['G AE1 HH AH0 N'],
  "gahli": ['G AA1 L IY0'],
  "gahm": ['G AE1 M'],
  "gahn": ['G AE1 N'],
  "gahr": ['G AA1 R'],
  "gai": ['G EY1'],
  "gaia": ['G AY1 AH0'],
  "gaidar": ['G AY1 D AA2 R'],
  "gaidar's": ['G AY1 D AA2 R Z'],
  "gaier": ['G EY1 ER0'],
  "gaige": ['G EY1 JH'],
  "gail": ['G EY1 L'],
  "gail's": ['G EY1 L Z'],
  "gaile": ['G EY1 L'],
  "gailey": ['G EY1 L IY0'],
  "gaillard": ['G EY1 L ER0 D'],
  "gaily": ['G EY1 L IY0'],
  "gain": ['G EY1 N'],
  "gained": ['G EY1 N D'],
  "gainer": ['G EY1 N ER0'],
  "gainers": ['G EY1 N ER0 Z'],
  "gaines": ['G EY1 N Z'],
  "gainesville": ['G EY1 N Z V IH2 L'],
  "gainey": ['G EY1 N IY0'],
  "gainful": ['G EY1 N F AH0 L'],
  "gainfully": ['G EY1 N F AH0 L IY0'],
  "gaining": ['G EY1 N IH0 NG'],
  "gainor": ['G EY1 N ER0'],
  "gainous": ['G EY1 N AH0 S'],
  "gains": ['G EY1 N Z'],
  "gainsay": ['G EY1 N S EY2'],
  "gainsboro": ['G EY1 N Z B ER0 OW0'],
  "gainsco": ['G EY1 N S K OW0'],
  "gair": ['G EH1 R'],
  "gaiser": ['G EY1 Z ER0'],
  "gait": ['G EY1 T'],
  "gaitan": ['G AY2 T AA1 N'],
  "gaiter": ['G EY1 T ER0'],
  "gaither": ['G EY1 TH ER0'],
  "gaithersburg": ['G EY1 TH ER0 Z B ER0 G'],
  "gaitskill": ['G EY1 T S K IH1 L'],
  "gajda": ['G AY1 D AH0'],
  "gajewski": ['G AY0 EH1 F S K IY0'],
  "gal": ['G AE1 L'],
  "gala": ['G AE1 L AH0', 'G EY1 L AH0'],
  "galactic": ['G AH0 L AE1 K T IH0 K'],
  "galactica": ['G AH0 L AE1 K T IH2 K AH0'],
  "galactose": ['G AH0 L AE1 K T OW0 S'],
  "galahad": ['G AE1 L AH0 HH AE2 D'],
  "galambos": ['G AA0 L AA1 M B OW0 Z'],
  "galan": ['G EY1 L AH0 N'],
  "galane": ['G AH0 L EY1 N'],
  "galang": ['G AE1 L AH0 NG'],
  "galanis": ['G AH0 L AA1 N IH0 S'],
  "galanos": ['G AH0 L AA1 N OW0 S'],
  "galant": ['G AA1 L AH0 N T'],
  "galante": ['G AA0 L AA1 N T IY0'],
  "galanter": ['G AH0 L AE1 N T ER0'],
  "galanti": ['G AH0 L AE1 N T IY0'],
  "galapagos": ['G AH0 L AA1 P AH0 G OW0 Z'],
  "galarneau": ['G AE1 L AA0 R N OW2'],
  "galarza": ['G AH0 L AA1 R Z AH0'],
  "galas": ['G EY1 L AH0 Z'],
  "galasie": ['G AE1 L AH0 S IY0'],
  "galassi": ['G AA0 L AA1 S IY0'],
  "galasso": ['G AA0 L AA1 S OW0'],
  "galatas": ['G AA0 L AA1 T AA0 Z'],
  "galatea": ['G AE2 L AH0 T IY1 AH0'],
  "galati": ['G AA0 L AA1 T IY0'],
  "galaviz": ['G AE1 L AH0 V IH0 Z'],
  "galax": ['G AE1 L AE2 K S'],
  "galaxies": ['G AE1 L AH0 K S IY0 Z'],
  "galaxy": ['G AE1 L AH0 K S IY0'],
  "galaxy's": ['G AE1 L AH0 K S IY0 Z'],
  "galban": ['G AE1 L B AH0 N'],
  "galbo": ['G AE1 L B OW0'],
  "galbraith": ['G AE1 L B R EY2 TH'],
  "galbreath": ['G AE1 L B R EH2 TH'],
  "gale": ['G EY1 L'],
  "gale's": ['G EY1 L Z'],
  "galea": ['G EY1 L IY0 AH0'],
  "galeano": ['G AA0 L IY1 N OW0'],
  "galeb": ['G AE1 L AH0 B', 'G EY1 L AH0 B'],
  "galebs": ['G AE1 L AH0 B Z', 'G EY1 L AH0 B Z'],
  "galef": ['G AE1 L AH0 F'],
  "galen": ['G EY1 L AH0 N'],
  "galen's": ['G EY1 L AH0 N Z'],
  "galena": ['G AH0 L IY1 N AH0'],
  "galenical": ['G AH0 L EH1 N IH0 K AH0 L'],
  "galentine": ['G AA0 L EH0 N T IY1 N IY0'],
  "galer": ['G EY1 L ER0'],
  "galeria": ['G AE2 L ER0 IY1 AH0'],
  "galerias": ['G AE2 L ER0 IY1 AH0 Z'],
  "galeries": ['G AE1 L ER0 IY0 Z'],
  "galernter": ['G AH0 L ER1 N T ER0'],
  "gales": ['G EY1 L Z'],
  "galesburg": ['G EY1 L Z B ER0 G'],
  "galesi": ['G AH0 L EH1 S IY0'],
  "galey": ['G EY1 L IY0'],
  "galford": ['G AE1 L F ER0 D'],
  "galgano": ['G AA0 L G AA1 N OW0'],
  "gali": ['G AA1 L IY0'],
  "gali's": ['G AA1 L IY0 Z'],
  "galiano": ['G AA0 L IY0 AA1 N OW0'],
  "galicia": ['G AH0 L IH1 SH IY0 AH0'],
  "galie": ['G EY1 L IY0'],
  "galiena": ['G AA0 L IY1 N AH0'],
  "galik": ['G AE1 L IH0 K'],
  "galilean": ['G AE2 L AH0 L IY1 AH0 N'],
  "galilee": ['G AE1 L AH0 L IY2'],
  "galileo": ['G AE2 L AH0 L IY1 OW0', 'G AE2 L AH0 L EY1 OW0'],
  "galileo's": ['G AE2 L AH0 L IY1 OW0 Z', 'G AE2 L AH0 L EY1 OW0 Z'],
  "galin": ['G AE1 L IH0 N'],
  "galina": ['G AH0 L IY1 N AH0'],
  "galindo": ['G AA0 L IY1 N D OW0'],
  "galinski": ['G AH0 L IH1 N S K IY0'],
  "galinsky": ['G AH0 L IH1 N S K IY0'],
  "galioto": ['G AA0 L IY0 OW1 T OW0'],
  "galipault": ['G AE1 L IH0 P AO2 L T', 'G AE1 L IH0 P OW2'],
  "galipeau": ['G AE1 L IH0 P OW2'],
  "galitsin": ['G AH0 L IH1 T S IH0 N'],
  "galka": ['G AE1 L K AH0'],
  "gall": ['G AO1 L'],
  "galla": ['G AE1 L AH0'],
  "gallacher": ['G AE1 L AH0 K ER0'],
  "gallager": ['G AO1 L IH0 JH ER0'],
  "gallagher": ['G AE1 L AH0 G ER0', 'G AE1 L AH0 HH ER0'],
  "gallahan": ['G AE1 L AH0 HH AE0 N'],
  "gallaher": ['G AE1 L AH0 HH ER0'],
  "gallamore": ['G AA0 L AA1 M AO0 R'],
  "galland": ['G AE1 L AH0 N D'],
  "gallant": ['G AE1 L AH0 N T'],
  "gallantly": ['G AE1 L AH0 N T L IY0'],
  "gallantry": ['G AE1 L AH0 N T R IY0'],
  "gallard": ['G AH0 L AA1 R D'],
  "gallardo": ['G AA0 L AA1 R D OW0'],
  "gallas": ['G AE1 L AH0 Z'],
  "gallatin": ['G AE1 L AH0 T IH0 N'],
  "gallaudet": ['G AE1 L AH0 D EH0 T'],
  "gallaway": ['G AO1 L AH0 W EY2'],
  "gallbladder": ['G AO1 L B L AE2 D ER0'],
  "galle": ['G EY1 L', 'G AE2 L EY1'],
  "gallego": ['G AA0 L EH1 G OW0'],
  "gallegos": ['G AE1 L IH0 G OW0 Z'],
  "gallemore": ['G AA0 L EH1 M AO0 R'],
  "gallen": ['G AO1 L AH0 N'],
  "gallentine": ['G AA0 L EH0 N T IY1 N IY0'],
  "galleon": ['G AE1 L IY0 AH0 N'],
  "galleons": ['G AE1 L IY0 AH0 N Z'],
  "galler": ['G AO1 L ER0'],
  "gallerani": ['G AA0 L ER0 AA1 N IY0'],
  "galleria": ['G AE2 L ER0 IY1 AH0'],
  "galleries": ['G AE1 L ER0 IY0 Z'],
  "gallery": ['G AE1 L ER0 IY0'],
  "gallery's": ['G AE1 L ER0 IY0 Z'],
  "galles": ['G EY1 L Z'],
  "gallet": ['G AE1 L IH0 T'],
  "galletti": ['G AA0 L EH1 T IY0'],
  "galley": ['G AE1 L IY0'],
  "galleys": ['G AE1 L IY0 Z'],
  "galli": ['G AE1 L IY0'],
  "gallia": ['G AE1 L Y AH0'],
  "galliano": ['G AA0 L IY0 AA1 N OW0'],
  "gallic": ['G AE1 L IH0 K'],
  "gallick": ['G AE1 L IH0 K'],
  "gallien": ['G AH0 L IY1 N'],
  "gallier": ['G AE1 L IY0 ER0'],
  "galligan": ['G AE1 L IH0 G AH0 N'],
  "galliher": ['G AE1 L IH0 HH ER0'],
  "gallik": ['G AE1 L IH0 K'],
  "gallimard": ['G AE1 L IH0 M ER0 D'],
  "gallimore": ['G AA0 L IY1 M AO0 R'],
  "gallina": ['G AA0 L IY1 N AH0'],
  "galling": ['G AO1 L IH0 NG'],
  "gallinger": ['G AO1 L IH0 NG ER0'],
  "gallinsky": ['G AH0 L IH1 N S K IY0'],
  "gallion": ['G AE1 L Y AH0 N'],
  "gallium": ['G AE1 L IY0 AH0 M'],
  "gallivan": ['G AE1 L IH0 V AH0 N'],
  "gallman": ['G AO1 L M AH0 N'],
  "gallo": ['G AE1 L OW0'],
  "gallo's": ['G AE1 L OW0 Z'],
  "gallogly": ['G AE1 L AH0 G L IY0'],
  "gallon": ['G AE1 L AH0 N'],
  "gallons": ['G AE1 L AH0 N Z'],
  "galloon": ['G AH0 L UW1 N'],
  "gallop": ['G AE1 L AH0 P'],
  "galloped": ['G AE1 L AH0 P T'],
  "galloping": ['G AE1 L AH0 P IH0 NG'],
  "gallos": ['G AE1 L OW0 Z'],
  "gallow": ['G AE1 L OW0'],
  "galloway": ['G AE1 L OW0 W EY2'],
  "galloways": ['G AE1 L OW0 W EY2 Z'],
  "gallows": ['G AE1 L OW0 Z'],
  "galls": ['G AO1 L Z'],
  "gallstone": ['G AO1 L S T OW2 N'],
  "gallstones": ['G AA1 L S T OW2 N Z', 'G AO1 L S T OW2 N Z'],
  "gallucci": ['G AA0 L UW1 CH IY0'],
  "galluccio": ['G AA0 L UW1 CH IY0 OW0'],
  "gallup": ['G AE1 L AH0 P'],
  "gallus": ['G AE1 L AH0 S'],
  "galluzzo": ['G AA0 L UW1 Z OW0'],
  "gallway": ['G AO1 L W EY2'],
  "galoob": ['G AH0 L UW1 B'],
  "galoob's": ['G AH0 L UW1 B Z'],
  "galore": ['G AH0 L AO1 R'],
  "galosh": ['G AH0 L AA1 SH'],
  "galoshes": ['G AH0 L AA1 SH AH0 Z', 'G AH0 L AA1 SH IH0 Z'],
  "galotti": ['G AH0 L AA1 T IY0'],
  "galpin": ['G AE1 L P IH0 N'],
  "gals": ['G AE1 L Z'],
  "galster": ['G AE1 L S T ER0'],
  "galsworthy": ['G AE1 L Z W ER2 DH IY0'],
  "galt": ['G AO1 L T'],
  "galton": ['G AE1 L T AH0 N'],
  "galudet": ['G AE2 L Y UW0 D EH1 T'],
  "galus": ['G AE1 L IH0 S'],
  "galusha": ['G AE1 L AH0 SH AH0'],
  "galuska": ['G AH0 L AH1 S K AH0'],
  "galvan": ['G AA0 L V AA1 N'],
  "galvanic": ['G AE0 L V AE1 N IH0 K'],
  "galvanize": ['G AE1 L V AH0 N AY2 Z'],
  "galvanized": ['G AE1 L V AH0 N AY2 Z D'],
  "galvanizes": ['G AE1 L V AH0 N AY2 Z AH0 Z'],
  "galvanizing": ['G AE1 L V AH0 N AY2 Z IH0 NG'],
  "galven": ['G AA1 L V AH0 N'],
  "galveston": ['G AE1 L V AH0 S T AH0 N'],
  "galvez": ['G AA0 L V EH1 Z'],
  "galvin": ['G AE1 L V IH0 N'],
  "galvin's": ['G AE1 L V IH0 N Z'],
  "galway": ['G AA1 L W EY0'],
  "galway's": ['G AA1 L W EY0 Z'],
  "galyean": ['G AE1 L IY0 AH0 N'],
  "galyen": ['G AE1 L IY0 EH0 N'],
  "galyon": ['G AE1 L IY0 AA0 N'],
  "gama": ['G AA1 M AH0'],
  "gamache": ['G AA1 M EY0 K'],
  "gamage": ['G AE1 M IH0 JH'],
  "gamal": ['G AH0 M AA1 L'],
  "gamba": ['G AE1 M B AH0'],
  "gambale": ['G AA0 M B AA1 L IY0'],
  "gambardella": ['G AA0 M B AA0 R D EH1 L AH0'],
  "gamber": ['G AE1 M B ER0'],
  "gambia": ['G AE1 M B IY0 AH0'],
  "gambill": ['G AH0 M B IH1 L'],
  "gambino": ['G AE0 M B IY1 N OW0'],
  "gambit": ['G AE1 M B IH0 T'],
  "gambits": ['G AE1 M B AH0 T S'],
  "gamble": ['G AE1 M B AH0 L'],
  "gamble's": ['G AE1 M B AH0 L Z'],
  "gambled": ['G AE1 M B AH0 L D'],
  "gambler": ['G AE1 M B L ER0'],
  "gambler's": ['G AE1 M B L ER0 Z'],
  "gamblers": ['G AE1 M B L ER0 Z'],
  "gambles": ['G AE1 M B AH0 L Z'],
  "gamblin": ['G AE1 M B L IH0 N'],
  "gambling": ['G AE1 M B AH0 L IH0 NG', 'G AE1 M B L IH0 NG'],
  "gambling's": ['G AE1 M B L IH0 NG Z'],
  "gamboa": ['G AA0 M B OW1 AH0'],
  "gambone": ['G AA1 M B OW0 N'],
  "gambrel": ['G AE1 M B R AH0 L'],
  "gambrell": ['G AE1 M B R AH0 L'],
  "gambrill": ['G AE1 M B R IH0 L'],
  "gambro": ['G AE1 M B R OW0'],
  "gamco": ['G AE1 M K OW0'],
  "game": ['G EY1 M'],
  "game's": ['G EY1 M Z'],
  "gameboy": ['G EY1 M B OY2'],
  "gamecock": ['G EY1 M K AO2 K'],
  "gamecocks": ['G EY1 M K AO2 K S'],
  "gamekeeper": ['G EY1 M K IY2 P ER0'],
  "gamekeepers": ['G EY1 M K IY2 P ER0 S'],
  "gamel": ['G AA1 M AH0 L'],
  "gamelin": ['G AE1 M IH0 L IH0 N', 'G AE1 M L IH0 N'],
  "gamello": ['G AH0 M EH1 L OW0'],
  "gamely": ['G EY1 M L IY0'],
  "gameplay": ['G EY1 M P L EY2'],
  "gamer": ['G EY1 M ER0'],
  "gamers": ['G EY1 M ER0 Z'],
  "games": ['G EY1 M Z'],
  "games'": ['G EY1 M Z'],
  "gameshow": ['G EY1 M SH OW2'],
  "gameshows": ['G EY1 M SH OW2 Z'],
  "gamesman": ['G EY1 M Z M AE2 N'],
  "gamesmanship": ['G EY1 M Z M AH0 N SH IH2 P'],
  "gamest": ['G EY1 M AH0 S T'],
  "gamet": ['G AE1 M IH0 T'],
  "gametangia": ['G AE2 M AH0 T AE1 N JH IY0 AH0'],
  "gamete": ['G AE1 M IY0 T', 'G AH0 M IY1 T'],
  "gametophyte": ['G AH0 M IY1 T AH0 F AY2 T'],
  "gamez": ['G AA0 M EH1 Z'],
  "gaming": ['G EY1 M IH0 NG'],
  "gaming's": ['G EY1 M IH0 NG Z'],
  "gamino": ['G AA0 M IY1 N OW0'],
  "gamm": ['G AE1 M'],
  "gamma": ['G AE1 M AH0'],
  "gammage": ['G AE1 M IH0 JH'],
  "gammal": ['G AH0 M AA1 L'],
  "gammel": ['G AE1 M AH0 L'],
  "gammell": ['G AE1 M AH0 L'],
  "gammill": ['G AE1 M IH0 L'],
  "gammon": ['G AE1 M AH0 N'],
  "gammons": ['G AE1 M AH0 N Z'],
  "gamons": ['G AE1 M AH0 N Z'],
  "gamp": ['G AE1 M P'],
  "gamper": ['G AE1 M P ER0'],
  "gamsakhurdia": ['G AE0 M S AH0 K ER1 D IY0 AH0', 'G AE0 M S AH0 K ER1 D Y AH0'],
  "gamunde": ['G AH0 M UW1 N D'],
  "gamunde's": ['G AH0 M UW1 N D Z'],
  "gamut": ['G AE1 M AH0 T'],
  "gan": ['G AE1 N'],
  "gananoque": ['G AH0 N AE1 N AH0 K'],
  "ganas": ['G AE1 N AH0 Z'],
  "ganatieuganauf": ['G AH0 N EY1 SH AH0 G AE2 N AH0 L F'],
  "ganaway": ['G AE1 N AH0 W EY0'],
  "gancarz": ['G AA1 N K AA0 R Z'],
  "ganci": ['G AE1 N S IY0'],
  "ganda": ['G AE1 N D AH0'],
  "gandalf": ['G AE1 N D AO0 L F'],
  "gandalf's": ['G AE1 N D AO0 L F S'],
  "gandara": ['G AA0 N D AA1 R AH0'],
  "gandee": ['G AE1 N D IY0'],
  "gander": ['G AE1 N D ER0'],
  "ganders": ['G AE1 N D ER0 Z'],
  "gandhi": ['G AA1 N D IY0'],
  "gandhi's": ['G AA1 N D IY0 Z'],
  "gandolfi": ['G AA0 N D OW1 L F IY0'],
  "gandolfo": ['G AA0 N D OW1 L F OW0'],
  "gandy": ['G AE1 N D IY0'],
  "ganem": ['G AE1 N IH0 M'],
  "ganes": ['G EY1 N Z'],
  "ganey": ['G EY1 N IY0'],
  "gang": ['G AE1 NG'],
  "gang's": ['G AE1 NG Z'],
  "gangbanger": ['G AE1 NG B AE0 NG G ER0'],
  "gangbangers": ['G AE1 NG B AE0 NG G ER0 Z'],
  "gangbuster": ['G AE1 NG B AH2 S T ER0'],
  "gangbusters": ['G AE1 NG B AH2 S T ER0 Z'],
  "gange": ['G AE1 N JH'],
  "gangemi": ['G AA0 NG G EH1 M IY0'],
  "ganger": ['G AE1 NG ER0'],
  "ganges": ['G AE1 N JH IY0 Z'],
  "gangi": ['G AE1 N JH IY0'],
  "ganging": ['G AE1 NG IH0 NG'],
  "gangl": ['G AE1 NG G AH0 L'],
  "ganglia": ['G AE1 NG G L IY0 AA0'],
  "ganglion": ['G AE1 NG G L IY0 AA0 N'],
  "ganglionic": ['G AE2 NG G L IY0 AA1 N IH0 K'],
  "gangloff": ['G AE1 NG G L AO0 F'],
  "gangly": ['G AE1 NG L IY0'],
  "gangplank": ['G AE1 NG P L AE2 NG K'],
  "gangrene": ['G AE1 N G R IY0 N'],
  "gangs": ['G AE1 NG Z'],
  "gangsta": ['G AE1 NG S T AH0'],
  "gangster": ['G AE1 NG S T ER0'],
  "gangsters": ['G AE1 NG S T ER0 Z'],
  "gangwer": ['G AE1 NG W ER0'],
  "ganic": ['G AE1 N IH0 K'],
  "ganim": ['G AE1 N IH0 M'],
  "ganis": ['G AE1 N IH0 S'],
  "ganley": ['G AE1 N L IY0'],
  "gann": ['G AE1 N'],
  "gannaway": ['G AE1 N AH0 W EY0'],
  "gannett": ['G AE1 N IH0 T'],
  "gannett's": ['G AE1 N AH0 T S'],
  "gannon": ['G AE1 N AH0 N'],
  "gano": ['G AA1 N OW0'],
  "ganoe": ['G AE1 N OW0'],
  "ganong": ['G AE1 N AO0 NG'],
  "gans": ['G AE1 N Z'],
  "gansen": ['G AE1 N S AH0 N'],
  "ganser": ['G AE1 N S ER0'],
  "ganske": ['G AE1 N S K'],
  "ganson": ['G AE1 N S AH0 N'],
  "ganster": ['G AE1 N S T ER0'],
  "gant": ['G AE1 N T'],
  "gantenbein": ['G AE1 N T IH0 N B AY0 N'],
  "ganter": ['G AE1 N T ER0'],
  "gantlet": ['G AO1 N T L AH0 T'],
  "gantner": ['G AE1 N T N ER0'],
  "gantos": ['G AE1 N T OW0 S'],
  "gantry": ['G AE1 N T R IY0'],
  "gantt": ['G AE1 N T'],
  "gantz": ['G AE1 N T S'],
  "ganus": ['G EY1 N IH0 S'],
  "ganymede": ['G AE1 N AH0 M IY2 D'],
  "ganz": ['G AE1 N Z'],
  "ganzel": ['G AE1 N Z AH0 L'],
  "ganzer": ['G AE1 N Z ER0'],
  "gao": ['G AW1'],
  "gaona": ['G AA0 OW1 N AH0'],
  "gap": ['G AE1 P'],
  "gap's": ['G AE1 P S'],
  "gape": ['G EY1 P'],
  "gaping": ['G EY1 P IH0 NG'],
  "gapinski": ['G AH0 P IH1 N S K IY0'],
  "gapp": ['G AE1 P'],
  "gappa": ['G AE1 P AH0'],
  "gaps": ['G AE1 P S'],
  "gar": ['G AA1 R'],
  "gara": ['G AE1 R AH0'],
  "garabedian": ['G AE2 R AH0 B IY1 D IY0 AH0 N'],
  "garafalo": ['G AA0 R AA0 F AA1 L OW0'],
  "garafola": ['G AA0 R AA0 F OW1 L AH0'],
  "garage": ['G ER0 AA1 ZH'],
  "garages": ['G ER0 AA1 ZH IH0 Z'],
  "garagiola": ['G ER0 AE2 JH IY0 OW1 L AH0', 'G EH2 R AH0 JH IY0 OW1 L AH0'],
  "garajda": ['G ER0 AA1 ZH D AH0', 'G AO0 R AA1 ZH D AH0'],
  "garajda's": ['G ER0 AA1 ZH D AH0 Z', 'G AO0 R AA1 ZH D AH0 Z'],
  "garamendi": ['G EH2 R AH0 M EH1 N D IY0'],
  "garamendi's": ['G EH2 R AH0 M EH1 N D IY0 Z'],
  "garand": ['G AE1 R AH0 N D'],
  "garant": ['G AA1 R AH0 N T'],
  "garard": ['G ER0 AA1 R D'],
  "garavaglia": ['G AA0 R AA0 V AA1 G L IY0 AH0'],
  "garay": ['G AE1 R EY0'],
  "garb": ['G AA1 R B'],
  "garbacz": ['G AA1 R B AH0 CH'],
  "garbage": ['G AA1 R B IH0 JH'],
  "garbarini": ['G AA0 R B AA0 R IY1 N IY0'],
  "garbarino": ['G AA0 R B AA0 R IY1 N OW0'],
  "garbe": ['G AA1 R B'],
  "garbed": ['G AA1 R B D'],
  "garber": ['G AA1 R B ER0'],
  "garbers": ['G AA1 R B ER0 Z'],
  "garbett": ['G AA1 R B IH0 T'],
  "garble": ['G AA1 R B AH0 L'],
  "garbled": ['G AA1 R B AH0 L D'],
  "garbles": ['G AA1 R B AH0 L Z'],
  "garbling": ['G AA1 R B L IH0 NG'],
  "garbo": ['G AA1 R B OW0'],
  "garbutt": ['G AA1 R B AH0 T'],
  "garceau": ['G AA0 R S OW1'],
  "garces": ['G AA1 R S EH0 S'],
  "garcetti": ['G AA2 R CH EH1 T IY0'],
  "garcetti's": ['G AA2 R CH EH1 T IY0 Z'],
  "garcia": ['G AA2 R S IY1 AH0'],
  "garcia's": ['G AA0 R S IY1 AH0 Z'],
  "garcias": ['G AA0 R S IY1 AH0 Z'],
  "garcon": ['G AA2 R S OW1 N'],
  "garcons": ['G AA2 R S OW1 N Z'],
  "garczynski": ['G ER0 CH IH1 N S K IY0'],
  "gard": ['G AA1 R D'],
  "garda": ['G AA1 R D AH0'],
  "garde": ['G AA1 R D'],
  "gardea": ['G AA1 R D IY0 AH0'],
  "gardella": ['G AA2 R D EH1 L AH0'],
  "garden": ['G AA1 R D AH0 N'],
  "garden's": ['G AA1 R D AH0 N Z'],
  "gardena": ['G AA0 R D IY1 N AH0'],
  "gardenamerica": ['G AA2 R D AH0 N AH0 M EH1 R IH0 K AH0'],
  "gardened": ['G AA1 R D AH0 N D'],
  "gardener": ['G AA1 R D AH0 N ER0'],
  "gardener's": ['G AA1 R D AH0 N ER0 Z'],
  "gardeners": ['G AA1 R D AH0 N ER0 Z', 'G AA1 R D N ER0 Z'],
  "gardenhire": ['G AA1 R D AH0 N HH AY2 R'],
  "gardenia": ['G AA0 R D IY1 N Y AH0'],
  "gardenias": ['G AA0 R D IY1 N Y AH0 Z'],
  "gardening": ['G AA1 R D AH0 N IH0 NG', 'G AA1 R D N IH0 NG'],
  "gardens": ['G AA1 R D AH0 N Z'],
  "gardin": ['G AA1 R D IH0 N'],
  "gardiner": ['G AA1 R D N ER0'],
  "gardini": ['G AA0 R D IY1 N IY0'],
  "gardini's": ['G AA0 R D IY1 N IY0 Z'],
  "gardinier": ['G AA1 R D IH0 N IY0 ER0', 'G AA1 R D IH0 N Y ER0'],
  "gardner": ['G AA1 R D N ER0'],
  "gardner's": ['G AA1 R D N ER0 Z'],
  "gardolin": ['G AA1 R D OW0 L IH0 N'],
  "gardolin's": ['G AA1 R D OW0 L IH0 N Z'],
  "garduno": ['G AA0 R D UW1 N OW0'],
  "gardyne": ['G AA1 R D AY2 N'],
  "gare": ['G EH1 R'],
  "gareau": ['G ER0 OW1'],
  "gared": ['G AE1 R AH0 D'],
  "garelick": ['G AE1 R IH0 L IH0 K'],
  "gareth": ['G EH1 R IH0 TH'],
  "garett": ['G AE1 R IH0 T'],
  "garey": ['G AE1 R IY0'],
  "garfield": ['G AA1 R F IY2 L D'],
  "garfinkel": ['G AA1 R F IH0 NG K AH0 L'],
  "garfinkle": ['G AA1 R F IH2 NG K AH0 L'],
  "garfunkel": ['G AA1 R F AH0 NG K AH0 L'],
  "garg": ['G AA1 R G'],
  "gargan": ['G AA1 R G AH0 N'],
  "gargano": ['G AA0 R G AA1 N OW0'],
  "gargantuan": ['G AA0 R G AE1 N CH UW0 AH0 N'],
  "garger": ['G AA1 R G ER0'],
  "garges": ['G AA1 R JH IH0 Z'],
  "gargill": ['G AA1 R JH IH0 L'],
  "gargis": ['G AA1 R G IH0 S'],
  "gargiulo": ['G AA1 R JH UW0 L OW0'],
  "gargoyle": ['G AA1 R G OY2 L'],
  "gargoyles": ['G AA1 R G OY2 L Z'],
  "garguilo": ['G AA0 R G W IY1 L OW0'],
  "gargus": ['G AA1 R G AH0 S'],
  "gari": ['G AA1 R IY0'],
  "garibaldi": ['G AE2 R AH0 B AO1 L D IY0'],
  "garibay": ['G AE1 R IH0 B EY0'],
  "gariepy": ['G ER0 IY1 P IY0'],
  "garin": ['G EH1 R IH0 N'],
  "garing": ['G EH1 R IH0 NG'],
  "garinger": ['G EH1 R IH0 NG ER0'],
  "garis": ['G AE1 R IH0 S'],
  "garish": ['G EH1 R IH0 SH'],
  "garissa": ['G AH0 R IH1 S AH0'],
  "garity": ['G AE1 R IH0 T IY0'],
  "garl": ['G AA1 R L'],
  "garlan": ['G AA1 R L AH0 N'],
  "garland": ['G AA1 R L AH0 N D'],
  "garlic": ['G AA1 R L IH0 K'],
  "garlick": ['G AA1 R L IH0 K'],
  "garlicky": ['G AA1 R L IH0 K IY0'],
  "garling": ['G AA1 R L IH0 NG'],
  "garlinger": ['G AA1 R L IH0 NG ER0'],
  "garlinghouse": ['G AA1 R L IH0 NG HH AW2 S'],
  "garlington": ['G AA1 R L IH0 NG T AH0 N'],
  "garlitz": ['G AA1 R L IH0 T S'],
  "garlock": ['G AA1 R L AH0 K'],
  "garlow": ['G AA1 R L OW0'],
  "garman": ['G AA1 R M AH0 N'],
  "garmany": ['G ER0 M AO1 N IY0'],
  "garment": ['G AA1 R M AH0 N T'],
  "garments": ['G AA1 R M AH0 N T S'],
  "garmon": ['G AA1 R M AH0 N'],
  "garmond": ['G AA1 R M AH0 N D'],
  "garms": ['G AA1 R M Z'],
  "garmund": ['G AA1 R M AH0 N D'],
  "garn": ['G AA1 R N'],
  "garneau": ['G AA0 R N OW1'],
  "garnell": ['G AA0 R N EH1 L'],
  "garner": ['G AA1 R N ER0'],
  "garnered": ['G AA1 R N ER0 D'],
  "garnering": ['G AA1 R N ER0 IH0 NG'],
  "garners": ['G AA1 R N ER0 Z'],
  "garnes": ['G AA1 R N Z'],
  "garnet": ['G AA1 R N AH0 T'],
  "garnett": ['G AA1 R N IH0 T'],
  "garnette": ['G AA0 R N EH1 T'],
  "garney": ['G AA1 R N IY0'],
  "garnica": ['G AA0 R N IY1 K AH0'],
  "garnier": ['G AA1 R N IY0 ER0'],
  "garnish": ['G AA1 R N IH0 SH'],
  "garnished": ['G AA1 R N IH0 SH T'],
  "garnishes": ['G AA1 R N IH0 SH AH0 Z'],
  "garnishment": ['G AA1 R N IH0 SH M AH0 N T'],
  "garno": ['G AA1 R N OW0'],
  "garnock": ['G AA1 R N AH0 K'],
  "garnsey": ['G AA1 R N S IY0'],
  "garo": ['G EH1 R OW0'],
  "garofalo": ['G AA0 R OW0 F AA1 L OW0'],
  "garofano": ['G AA0 R OW0 F AA1 N OW0'],
  "garofolo": ['G EH0 R AH0 F OW1 L AH0'],
  "garols": ['G AE1 R AO0 L Z'],
  "garon": ['G AA0 R AO1 N'],
  "garone": ['G ER0 OW1 N'],
  "garonzik": ['G ER0 AA1 N Z IH0 K'],
  "garoutte": ['G ER0 UW1 T'],
  "garp": ['G AA1 R P'],
  "garp's": ['G AA1 R P S'],
  "garps": ['G AA1 R P S'],
  "garr": ['G AE1 R'],
  "garrabrant": ['G AA0 R AA1 B R AH0 N T'],
  "garrahan": ['G AE1 R AH0 HH AE0 N'],
  "garramone": ['G AE1 R AH0 M OW2 N'],
  "garrard": ['G AE1 R ER0 D'],
  "garratt": ['G EH1 R AH0 T'],
  "garraway": ['G AE1 R AH0 W EY0'],
  "garrell": ['G AA0 R EY1 L'],
  "garrels": ['G AE1 R AH0 L Z'],
  "garrelts": ['G AE1 R IH0 L T S'],
  "garren": ['G AA1 R AH0 N'],
  "garret": ['G EH1 R IH0 T'],
  "garret's": ['G EH1 R AH0 T S'],
  "garrets": ['G EH1 R AH0 T S'],
  "garretson": ['G AE1 R IH0 T S AH0 N'],
  "garrett": ['G AE1 R IH0 T', 'G EH1 R IH0 T'],
  "garrett's": ['G AE1 R IH0 T Z', 'G EH1 R IH0 T Z'],
  "garrette": ['G ER0 EH1 T'],
  "garrey": ['G AE1 R IY0'],
  "garrick": ['G EH1 R IH0 K'],
  "garrido": ['G AA0 R IY1 D OW0'],
  "garrido-luna": ['G AA0 R IY1 D OW0 L UW1 N AH0'],
  "garriga": ['G AE1 R IH0 G AH0'],
  "garrigan": ['G AE1 R IH0 G AH0 N'],
  "garrigues": ['G AA0 R IY1 G EH0 S'],
  "garrigus": ['G AA0 R IY1 G IH0 S'],
  "garringer": ['G AE1 R IH0 NG ER0'],
  "garriott": ['G AE1 R IY0 AH0 T'],
  "garris": ['G AE1 R IH0 S'],
  "garrison": ['G AE1 R IH0 S AH0 N'],
  "garrisoned": ['G AE1 R AH0 S AH0 N D'],
  "garritano": ['G AA0 R IY0 T AA1 N OW0'],
  "garritt": ['G AE1 R IH0 T'],
  "garrity": ['G EH1 R IH0 T IY0'],
  "garro": ['G AA1 R OW0'],
  "garrod": ['G AE1 R AH0 D'],
  "garrol": ['G AE1 R AH0 L'],
  "garrols": ['G AE1 R AH0 L Z'],
  "garron": ['G AE1 R AH0 N'],
  "garron's": ['G EH1 R AH0 N Z'],
  "garros": ['G EH1 R OW0 S'],
  "garrott": ['G AE1 R AH0 T'],
  "garrow": ['G EH1 R OW0'],
  "garroway": ['G AE1 R OW0 W EY2'],
  "garroz": ['G AE1 R AH0 Z'],
  "garrulous": ['G EH1 R AH0 L AH0 S'],
  "garry": ['G AE1 R IY0', 'G EH1 R IY0'],
  "gars": ['G AA1 R Z'],
  "garside": ['G AA1 R S AY2 D'],
  "garske": ['G AA1 R S K'],
  "garson": ['G AA1 R S AH0 N'],
  "garst": ['G AA1 R S T'],
  "garsten": ['G AA1 R S T EH0 N'],
  "garsten's": ['G AA1 R S T EH0 N Z'],
  "garstin": ['G AA1 R S T AH0 N'],
  "garstka": ['G AA1 R S T K AH0'],
  "garston": ['G AA1 R S T AH0 N'],
  "garten": ['G AA1 R T AH0 N'],
  "gartenberg": ['G AA1 R T AH0 N B ER0 G'],
  "garter": ['G AA1 R T ER0'],
  "garters": ['G AA1 R T ER0 Z'],
  "garth": ['G AA1 R TH'],
  "garthwaite": ['G AA1 R TH W EY2 T'],
  "gartin": ['G AA1 R T IH0 N'],
  "gartland": ['G AA1 R T L AH0 N D'],
  "gartley": ['G AA1 R T L IY0'],
  "gartman": ['G AA1 R T M AH0 N'],
  "gartmore": ['G AA1 R T M AO2 R'],
  "gartner": ['G AA1 R T N ER0'],
  "garton": ['G AA1 R T AH0 N'],
  "gartrell": ['G AA1 R T R AH0 L'],
  "gartside": ['G AA1 R T S AY2 D'],
  "gartzke": ['G AA1 R T S K', 'G AA1 R T S K IY0'],
  "garuda": ['G AH0 R UW1 D AH0'],
  "garuda's": ['G AH0 R UW1 D AH0 Z'],
  "garver": ['G AA1 R V ER0'],
  "garver's": ['G AA1 R V ER0 Z'],
  "garverick": ['G AA1 R V ER0 IH0 K'],
  "garvey": ['G AA1 R V IY0'],
  "garvie": ['G AA1 R V IY0'],
  "garvin": ['G AA1 R V IH0 N'],
  "garwin": ['G AA1 R W IH0 N'],
  "garwood": ['G AA1 R W UH2 D'],
  "gary": ['G EH1 R IY0', 'G AE1 R IY0'],
  "gary's": ['G EH1 R IY0 Z', 'G AE1 R IY0 Z'],
  "garza": ['G AA1 R Z AH0'],
  "garzarelli": ['G AA1 R Z ER0 EH2 L IY0'],
  "garzon": ['G AA1 R Z AH0 N'],
  "gas": ['G AE1 S'],
  "gas's": ['G AE1 S IH0 Z'],
  "gasaway": ['G AE1 S AH0 W EY2'],
  "gasbarro": ['G AA0 S B AA1 R OW0'],
  "gasca": ['G AA1 S K AH0'],
  "gascon": ['G AE1 S K AH0 N'],
  "gascons": ['G AE1 S K AH0 N Z'],
  "gase": ['G EY1 Z'],
  "gaseous": ['G AE1 S IY0 AH0 S'],
  "gases": ['G AE1 S AH0 Z', 'G AE1 S IH0 Z'],
  "gash": ['G AE1 SH'],
  "gashed": ['G AE1 SH T'],
  "gashes": ['G AE1 SH AH0 Z'],
  "gasich": ['G EY1 Z IH0 K'],
  "gasification": ['G AE2 S AH0 F AH0 K EY1 SH AH0 N'],
  "gasify": ['G AE2 S AH0 F AY2'],
  "gasior": ['G AE1 S IY0 ER0'],
  "gasiorowski": ['G AH0 S IY0 AO0 R AO1 F S K IY0'],
  "gaska": ['G AA1 S K AH0'],
  "gaskamp": ['G AE1 S K AE2 M P'],
  "gaskell": ['G AE1 S K AH0 L'],
  "gasket": ['G AE1 S K AH0 T'],
  "gaskets": ['G AE1 S K AH0 T S'],
  "gaskey": ['G AE1 S K IY2'],
  "gaskill": ['G AE1 S K IH2 L'],
  "gaskin": ['G AE1 S K IH0 N'],
  "gaskins": ['G AE1 S K IH0 N Z'],
  "gaslight": ['G AE1 S L AY0 T'],
  "gasner": ['G AE1 S N ER0'],
  "gasohol": ['G AE1 S AH0 HH AO2 L'],
  "gasoline": ['G AE1 S AH0 L IY2 N'],
  "gasolines": ['G AE2 S AH0 L IY1 N Z'],
  "gasp": ['G AE1 S P'],
  "gaspar": ['G AE1 S P ER0'],
  "gaspara": ['G AH0 S P AA1 R AH0'],
  "gaspard": ['G AH0 S P AA1 R D'],
  "gaspari": ['G AA0 S P AA1 R IY0'],
  "gasparini": ['G AA0 S P AA0 R IY1 N IY0'],
  "gasparro": ['G AA0 S P AA1 R OW0'],
  "gaspe": ['G AE1 S P IY0'],
  "gasped": ['G AE1 S P T'],
  "gasper": ['G AE1 S P ER0'],
  "gasperini": ['G AA0 S P ER0 IY1 N IY0'],
  "gasping": ['G AE1 S P IH0 NG'],
  "gasps": ['G AE1 S P S'],
  "gasque": ['G EY1 S K'],
  "gass": ['G AE1 S'],
  "gassaway": ['G AE1 S AH0 W EY0'],
  "gassed": ['G AE1 S T'],
  "gassee": ['G AE1 S IY1'],
  "gassen": ['G AE1 S AH0 N'],
  "gasser": ['G AE1 S ER0'],
  "gassert": ['G AE1 S ER0 T'],
  "gasses": ['G AE1 S IH0 Z'],
  "gassett": ['G AE1 S IH0 T'],
  "gassing": ['G AE1 S IH0 NG'],
  "gassman": ['G AE1 S M AH0 N'],
  "gassmann": ['G AE1 S M AH0 N'],
  "gassner": ['G AE1 S N ER0'],
  "gast": ['G AE1 S T'],
  "gastelli": ['G AH0 S T EH1 L IY0'],
  "gaster": ['G AE1 S T ER0'],
  "gastilum": ['G EY1 S T IH0 L AH0 M'],
  "gastineau": ['G AE1 S T IH0 N OW2'],
  "gaston": ['G AE1 S T AH0 N'],
  "gaston's": ['G AE1 S T AH0 N Z'],
  "gastonia": ['G AH0 S T OW1 N IY0 AH0'],
  "gastric": ['G AE1 S T R IH0 K'],
  "gastritis": ['G AE0 S T R AY1 T AH0 S'],
  "gastro": ['G AE1 S T R OW0'],
  "gastrointestinal": ['G AE2 S T R OW0 IH0 N T EH1 S T AH0 N AH0 L'],
  "gastronomic": ['G AH0 S T R AA2 N AA1 M IH0 K'],
  "gastronomy": ['G AE0 S T R AA1 N AH0 M IY0'],
  "gastroscope": ['G AE1 S T R AH0 S K OW2 P'],
  "gastrovascular": ['G AE2 S T R OW0 V AE1 S K Y AH0 L ER0'],
  "gastrulate": ['G AE1 S T R AH0 L EY2 T'],
  "gastrulation": ['G AE2 S T R AH0 L EY1 SH AH0 N'],
  "gat": ['G AE1 T'],
  "gatch": ['G AE1 CH'],
  "gatchel": ['G AE1 CH AH0 L'],
  "gatchell": ['G AE1 CH AH0 L'],
  "gate": ['G EY1 T'],
  "gateau": ['G AA0 T OW1'],
  "gated": ['G EY1 T IH0 D'],
  "gatekeeper": ['G EY1 T K IY2 P ER0'],
  "gatekeepers": ['G EY1 T K IY2 P ER0 Z'],
  "gateley": ['G AE1 T IH0 L IY0', 'G EY1 T L IY0'],
  "gately": ['G EY1 T L IY0'],
  "gates": ['G EY1 T S'],
  "gates'": ['G EY1 T S'],
  "gates's": ['G EY1 T S IH0 Z', 'G EY1 T S'],
  "gateway": ['G EY1 T W EY2'],
  "gateway's": ['G EY1 T W EY2 Z'],
  "gateways": ['G EY1 T W EY2 Z'],
  "gatewood": ['G EY1 T W UH2 D'],
  "gath": ['G AE1 TH'],
  "gather": ['G AE1 DH ER0'],
  "gathered": ['G AE1 DH ER0 D'],
  "gatherer": ['G AE1 DH ER0 ER0'],
  "gatherers": ['G AE1 DH ER0 ER0 Z'],
  "gathering": ['G AE1 DH ER0 IH0 NG'],
  "gatherings": ['G AE1 DH ER0 IH0 NG Z'],
  "gathers": ['G AE1 DH ER0 Z'],
  "gathings": ['G AE1 TH IH0 NG Z'],
  "gathman": ['G AE1 TH M AH0 N'],
  "gathright": ['G AE1 TH R AY2 T'],
  "gatien": ['G EY1 T Y EH0 N'],
  "gating": ['G EY1 T IH0 NG'],
  "gatley": ['G AE1 T L IY0'],
  "gatliff": ['G AE1 T L IH0 F'],
  "gatlin": ['G AE1 T L IH0 N'],
  "gatling": ['G AE1 T L IH0 NG'],
  "gatling's": ['G AE1 T L IH0 NG Z'],
  "gatoil": ['G AH0 T OY1 L'],
  "gator": ['G EY1 T ER0'],
  "gatorade": ['G AE1 T ER0 EY2 D'],
  "gators": ['G EY1 T ER0 Z'],
  "gatos": ['G AA1 T OW2 S', 'G AE1 T OW2 S', 'G EY1 T OW2 S', 'G EY1 T OW0 S'],
  "gatrell": ['G AE1 T R AH0 L'],
  "gatsby": ['G AE1 T S B IY0'],
  "gatson": ['G AE1 T S AH0 N'],
  "gatt": ['G AE1 T'],
  "gatten": ['G AE1 T AH0 N'],
  "gatti": ['G AE1 T IY0'],
  "gatting": ['G AE1 T IH0 NG'],
  "gattis": ['G AE1 T IH0 S', 'G AE1 T IY0 Z'],
  "gatton": ['G AE1 T AH0 N'],
  "gattuso": ['G AA0 T UW1 S OW0'],
  "gatty": ['G AE1 T IY0'],
  "gatward": ['G AE1 T W ER0 D'],
  "gatwick": ['G AE1 T W IH2 K', 'G EY1 T W IH2 K'],
  "gatx": ['G AE1 T EH2 K S'],
  "gatz": ['G AE1 T S'],
  "gatza": ['G AA1 T Z AH0'],
  "gatzke": ['G AE1 T S K', 'G AE1 T S K IY0'],
  "gau": ['G OW1'],
  "gaub": ['G AO1 B'],
  "gaubatz": ['G AW1 B AH0 T S'],
  "gaubert": ['G AW1 B ER0 T', 'G AW0 B EH1 R T'],
  "gaubert's": ['G AW1 B ER0 T S', 'G AW0 B EH1 R T S'],
  "gauch": ['G AO1 CH'],
  "gauche": ['G OW1 SH'],
  "gaucher": ['G OW1 SH ER0'],
  "gaucho": ['G AW1 CH OW0'],
  "gauchos": ['G AW1 CH OW0 Z'],
  "gaudet": ['G OW0 D EH1 T'],
  "gaudette": ['G OW0 D EH1 T'],
  "gaudier": ['G AO2 D IY0 EY1'],
  "gaudin": ['G OW0 D AE1 N'],
  "gaudino": ['G AO2 D IY1 N OW0'],
  "gaudio": ['G AO1 D IY0 OW0'],
  "gaudioso": ['G AO0 D IY0 OW1 S OW0'],
  "gaudreau": ['G OW0 D R OW1'],
  "gaudy": ['G AO1 D IY0'],
  "gauer": ['G AW1 ER0'],
  "gauerke": ['G AW1 ER0 K'],
  "gaufman": ['G AO1 F M AH0 N'],
  "gaufman's": ['G AO1 F M AH0 N Z'],
  "gauge": ['G EY1 JH'],
  "gauged": ['G EY1 JH D'],
  "gauger": ['G EY1 JH ER0'],
  "gauges": ['G EY1 JH AH0 Z', 'G EY1 JH IH0 Z'],
  "gaugh": ['G AO1'],
  "gaughan": ['G AO1 AH0 N'],
  "gaughman": ['G AO1 M AH0 N'],
  "gaughran": ['G AO1 R AH0 N'],
  "gauging": ['G EY1 JH IH0 NG'],
  "gaugler": ['G AO1 G L ER0'],
  "gauguin": ['G AO1 G W IH0 N', 'G OW1 G AE2 N'],
  "gaul": ['G AO1 L'],
  "gaulan": ['G AO1 L AH0 N'],
  "gaulden": ['G AW1 D AH0 N'],
  "gaulding": ['G AO1 L D IH0 NG'],
  "gaulin": ['G OW0 L AE1 N'],
  "gaulke": ['G AO1 L K'],
  "gaulle": ['G AO1 L'],
  "gaullist": ['G AO1 L IH0 S T'],
  "gauls": ['G AO1 L Z'],
  "gault": ['G AO1 L T'],
  "gaultier": ['G OW1 L T Y EY0', 'G AA1 L T Y ER0'],
  "gaultney": ['G AO1 L T N IY0'],
  "gaumer": ['G AW1 M ER0'],
  "gaumond": ['G OW0 M AA1 N D'],
  "gauna": ['G AO1 N AH0'],
  "gaunce": ['G AO1 N S'],
  "gaunt": ['G AO1 N T'],
  "gauntlet": ['G AO1 N T L AH0 T'],
  "gauntness": ['G AO1 N T N AH0 S'],
  "gauntt": ['G AO1 N T'],
  "gaus": ['G AO1 Z'],
  "gause": ['G AO1 Z'],
  "gausman": ['G AW1 S M AH0 N'],
  "gauss": ['G AW1 S'],
  "gaussian": ['G AW1 S IY0 AH0 N'],
  "gaustad": ['G AW1 S T AH0 D'],
  "gaut": ['G AO1 T'],
  "gauthier": ['G AW1 TH IY0 ER0'],
  "gauthreaux": ['G OW0 TH R OW1'],
  "gautier": ['G AW1 T IY0 ER0'],
  "gautney": ['G AO1 T N IY0'],
  "gautreau": ['G OW0 T R OW1'],
  "gautreaux": ['G OW0 T R OW1'],
  "gauvain": ['G OW0 V AE1 N'],
  "gauvin": ['G OW0 V AE1 N'],
  "gauvreau": ['G OW0 V R OW1'],
  "gauze": ['G AO1 Z'],
  "gavalda": ['G AH0 V AA1 L D AH0'],
  "gavan": ['G EY1 V AH0 N'],
  "gave": ['G EY1 V'],
  "gavel": ['G AE1 V AH0 L'],
  "gaven": ['G EY1 V AH0 N'],
  "gaver": ['G EY1 V ER0'],
  "gavigan": ['G AE1 V IH0 G AH0 N'],
  "gavin": ['G AE1 V IH0 N'],
  "gaviotas": ['G AE0 V IY0 AO1 T AH0 Z', 'G AE0 V Y AO1 T AH0 Z'],
  "gaviria": ['G AH0 V IH1 R IY0 AH0'],
  "gavitt": ['G AE1 V IH0 T'],
  "gavlak": ['G AE1 V L AH0 K'],
  "gavras": ['G AE1 V R AH0 S'],
  "gavras's": ['G AE1 V R AH0 S IH0 Z'],
  "gaw": ['G AO1'],
  "gawain": ['G AA1 W AH0 N'],
  "gawel": ['G AO1 AH0 L'],
  "gawen": ['G AO1 AH0 N'],
  "gawk": ['G AO1 K'],
  "gawker": ['G AA1 K ER0'],
  "gawkers": ['G AA1 K ER0 Z'],
  "gawking": ['G AO1 K IH0 NG'],
  "gawky": ['G AO1 K IY0'],
  "gawlik": ['G AO1 L IH0 K'],
  "gawne": ['G AO1 N'],
  "gawron": ['G AO1 R AH0 N'],
  "gawronski": ['G AA0 V R AA1 N S K IY0'],
  "gawthrop": ['G AO1 TH R AH0 P'],
  "gaxiola": ['G AE0 K S IY0 OW1 L AH0'],
  "gay": ['G EY1'],
  "gayda": ['G EY1 D AH0'],
  "gaydar": ['G EY1 D AA2 R'],
  "gayden": ['G EY1 D AH0 N'],
  "gaydos": ['G EY1 D OW0 Z'],
  "gaydosh": ['G EY1 D AH0 SH'],
  "gaye": ['G EY1'],
  "gayer": ['G EY1 ER0'],
  "gayhart": ['G EY1 HH AA2 R T'],
  "gayheart": ['G EY1 HH AA2 R T'],
  "gayle": ['G EY1 L'],
  "gayler": ['G EY1 L ER0'],
  "gayles": ['G EY1 L Z'],
  "gaylor": ['G EY1 L ER0'],
  "gaylord": ['G EY1 L AO2 R D'],
  "gayman": ['G EY0 M AE1 N'],
  "gaymon": ['G EY1 M AH0 N'],
  "gaynatie": ['G EY1 N AH0 T IY0'],
  "gayner": ['G EY1 N ER0'],
  "gayness": ['G EY1 N AH0 S'],
  "gaynor": ['G EY1 N ER0'],
  "gays": ['G EY1 Z'],
  "gayshill": ['G EY2 Z HH IH1 L', 'G EY0 SH IH1 L'],
  "gaytan": ['G EY1 T AH0 N'],
  "gayton": ['G EY1 T AH0 N'],
  "gaz": ['G AA1 Z', 'G AE1 Z'],
  "gaza": ['G AA1 Z AH0'],
  "gaza's": ['G AA1 Z AH0 Z'],
  "gazans": ['G AA1 Z AH0 N Z'],
  "gazaway": ['G AA1 Z AH0 W EY0'],
  "gazda": ['G AE1 Z D AH0'],
  "gazdik": ['G AE1 Z D IH0 K'],
  "gaze": ['G EY1 Z'],
  "gazebo": ['G AH0 Z IY1 B OW0'],
  "gazed": ['G EY1 Z D'],
  "gazella": ['G AH0 Z EH1 L AH0'],
  "gazelle": ['G AH0 Z EH1 L'],
  "gazelles": ['G AH0 Z EH1 L Z'],
  "gazes": ['G EY1 Z AH0 Z', 'G EY1 Z IH0 Z'],
  "gazeta": ['G AH0 Z EY1 T AH0', 'G AH0 Z EH1 T AH0'],
  "gazette": ['G AH0 Z EH1 T'],
  "gazing": ['G EY1 Z IH0 NG'],
  "gazonsky": ['G AH0 Z AA1 N S K IY0'],
  "gazonsky's": ['G AH0 Z AA1 N S K IY0 Z'],
  "gazpacho": ['G AH0 S P AA1 CH OW0'],
  "gazprom": ['G AE1 Z P R AA2 M'],
  "gazzola": ['G AA0 T S OW1 L AH0'],
  "gdansk": ['G D AE1 N S K'],
  "gdp": ['G IY1 D IY1 P IY1'],
  "geac": ['G IY1 K', 'JH IY1 IY1 EY1 S IY1'],
  "geagea": ['JH IY1 AH0 JH IY1 AH0'],
  "gean": ['JH IY1 N'],
  "gear": ['G IH1 R'],
  "gear's": ['G IH1 R Z'],
  "gearan": ['G IH1 R AH0 N'],
  "gearan's": ['G IH1 R AH0 N Z'],
  "gearbox": ['G IH1 R B AA2 K S'],
  "gearboxes": ['G IH1 R B AA0 K S IH0 Z'],
  "geared": ['G IH1 R D'],
  "gearey": ['G IH1 R IY0'],
  "gearhart": ['G IH1 R HH AA0 R T', 'G IY1 R HH AA0 R T'],
  "gearheart": ['G IH1 R HH AA0 R T', 'G IY1 R HH AA0 R T'],
  "gearin": ['G IH1 R IH0 N'],
  "gearing": ['G IH1 R IH0 NG'],
  "gears": ['G IH1 R Z'],
  "geary": ['G IH1 R IY0'],
  "gebauer": ['G EH1 B AW0 ER0'],
  "gebbia": ['JH EH1 B IY0 AH0'],
  "gebbie": ['JH EH1 B IY0'],
  "gebbie's": ['JH EH1 B IY0 Z'],
  "gebel": ['G EH1 B AH0 L'],
  "gebert": ['G EH1 B ER0 T'],
  "gebhard": ['G EH1 B HH ER0 D'],
  "gebhardt": ['G EH1 B HH AA0 R T'],
  "gebhart": ['G EH1 B HH AA0 R T'],
  "gebler": ['G EH1 B AH0 L ER0', 'G EH1 B L ER0'],
  "gebo": ['JH EY1 B OW0'],
  "gechem": ['G EH1 CH AH0 M'],
  "geck": ['JH EH1 K'],
  "gecko": ['G EH1 K OW0'],
  "geckos": ['G EH1 K OW0 Z'],
  "ged": ['G EH1 D', 'JH IY1 IY1 D IY1'],
  "geddes": ['G EH1 D AH0 S', 'G EH1 D AH0 Z'],
  "geddie": ['JH EH1 D IY0'],
  "geddings": ['JH EH1 D IH0 NG Z'],
  "geddis": ['G EH1 D IH0 S'],
  "gedeon": ['G EH1 D IY0 AH0 N'],
  "gedney": ['JH EH1 D N IY0'],
  "geduld": ['G EH1 D AH0 L D'],
  "gee": ['JH IY1'],
  "gee's": ['JH IY1 Z'],
  "geeing": ['JH IY1 IH0 NG'],
  "geek": ['G IY1 K'],
  "geeks": ['G IY1 K S'],
  "geeky": ['G IY1 K IY0'],
  "geena": ['G IY1 N AH0'],
  "geenen": ['G IY1 N AH0 N'],
  "geer": ['G IH1 R'],
  "geerdes": ['G IH1 R D Z'],
  "geers": ['G IY1 ER0 Z'],
  "geerts": ['G IH1 R T S'],
  "gees": ['JH IY1 S'],
  "geesaman": ['G IY1 S AH0 M AH0 N'],
  "geese": ['G IY1 S'],
  "geesey": ['G IY1 S IY0'],
  "geeslin": ['G IY1 S L IH0 N'],
  "geeting": ['G IY1 T IH0 NG'],
  "geez": ['JH IY1 Z'],
  "geeze": ['JH IY1 Z'],
  "geffen": ['G EH1 F AH0 N'],
  "geffert": ['G EH1 F ER0 T'],
  "geffner": ['G EH1 F N ER0'],
  "geffre": ['JH EH1 F ER0'],
  "gefinor": ['G EH1 F IH0 N ER0'],
  "gegenheimer": ['G EH1 G IH0 N HH AY0 M ER0'],
  "gegg": ['JH EH1 G'],
  "gehl": ['G EH1 L'],
  "gehle": ['JH EH1 HH AH0 L'],
  "gehlhausen": ['G EH1 L HH AW0 Z AH0 N'],
  "gehling": ['G EH1 L IH0 NG'],
  "gehm": ['JH EH1 M'],
  "gehman": ['G EH1 M AH0 N'],
  "gehr": ['JH EH1 R'],
  "gehres": ['JH EH1 R Z'],
  "gehret": ['G EH1 R IH0 T'],
  "gehrig": ['G EH1 R IH0 G'],
  "gehrig's": ['G EH1 R IH0 G Z'],
  "gehring": ['G EH1 R IH0 NG'],
  "gehringer": ['G EH1 R IH0 NG ER0'],
  "gehris": ['G EH1 R IH0 S'],
  "gehrke": ['JH EH1 R K'],
  "gehrman": ['G EH1 R M AH0 N'],
  "gehrmann": ['G EH1 R M AH0 N'],
  "gehrt": ['G EH1 R T'],
  "gehry": ['G EH1 R IY0'],
  "geib": ['G AY1 B'],
  "geibel": ['G AY1 B AH0 L'],
  "geico": ['G AY1 K OW0'],
  "geico's": ['G AY1 K OW0 Z'],
  "geidar": ['G AY1 D AA2 R'],
  "geidel": ['G AY1 D AH0 L'],
  "geier": ['G AY1 ER0'],
  "geiger": ['G AY1 G ER0'],
  "geigle": ['G AY1 G AH0 L'],
  "geigy": ['G AY1 G IY0'],
  "geigy's": ['G AY1 G IY0 Z'],
  "geiken": ['G AY1 K AH0 N'],
  "geil": ['G AY1 L'],
  "geiler": ['G AY1 L ER0'],
  "geiman": ['G AY1 M AH0 N'],
  "geimer": ['G AY1 M ER0'],
  "geis": ['G AY1 Z'],
  "geise": ['G AY1 S'],
  "geisel": ['G AY1 S AH0 L'],
  "geiselman": ['G AY1 S AH0 L M AH0 N'],
  "geisen": ['G AY1 S AH0 N'],
  "geiser": ['G AY1 S ER0'],
  "geisert": ['G AY1 S ER0 T'],
  "geisha": ['G EY1 SH AH0'],
  "geisinger": ['G AY1 S IH0 N JH ER0'],
  "geisler": ['G AY1 S L ER0'],
  "geiss": ['G AY1 S'],
  "geissinger": ['G AY1 S IH0 N JH ER0'],
  "geissler": ['G AY1 S L ER0'],
  "geist": ['G AY1 S T'],
  "geister": ['G AY1 S T ER0'],
  "geiszler": ['G AY1 S L ER0'],
  "geitner": ['G AY1 T N ER0'],
  "geitz": ['G AY1 T S'],
  "gejdenson": ['G EY1 D AH0 N S AH0 N'],
  "gekas": ['G IY1 K AH0 S'],
  "gekko": ['G EH1 K OW0'],
  "gel": ['JH EH1 L'],
  "gelardi": ['JH EH0 L AA1 R D IY0'],
  "gelardin": ['G AH0 L AA1 R D IH0 N'],
  "gelasia": ['JH EH0 L AA1 S IY0 AH0'],
  "gelatin": ['JH EH1 L AH0 T AH0 N'],
  "gelatine": ['JH EH2 L AH0 T IY1 N'],
  "gelatinous": ['JH AH0 L AE1 T AH0 N AH0 S'],
  "gelb": ['JH EH1 L B'],
  "gelbard": ['G EH1 L B AA2 R D'],
  "gelbart": ['G EH1 L B AA2 R T'],
  "gelber": ['G EH1 L B ER0'],
  "gelco": ['JH EH1 L K OW0'],
  "gelder": ['G EH1 L D ER0'],
  "geldermann": ['G EH1 L D ER0 M AH0 N'],
  "gelernter": ['G AH0 L ER1 N T ER0'],
  "gelertner": ['G AH0 L ER1 T N ER0'],
  "gelett": ['JH EH1 L IH0 T'],
  "gelette": ['ZH IH0 L EH1 T'],
  "gelfand": ['G EH1 L F AH0 N D'],
  "gelinas": ['G EH1 L IH0 N AH0 Z'],
  "gelineau": ['ZH EH1 L IH0 N OW0'],
  "gell": ['JH EH1 L'],
  "gellatly": ['JH EH1 L AH0 T L IY0'],
  "geller": ['G EH1 L ER0'],
  "gellerman": ['G EH1 L ER0 M AH0 N'],
  "gellert": ['G EH1 L ER0 T'],
  "gelles": ['JH EH1 L Z'],
  "gelli": ['G EH1 L IY0'],
  "gellis": ['G EH1 L IH0 S'],
  "gellman": ['G EH1 L M AH0 N'],
  "gellner": ['G EH1 L N ER0'],
  "gelman": ['G EH1 L M AH0 N'],
  "gelpi": ['JH EH1 L P IY0'],
  "gels": ['JH EH1 L Z'],
  "gelsinger": ['G EH1 L S IH0 N JH ER0'],
  "geltz": ['G EH1 L T S'],
  "gelvin": ['G EH1 L V IH0 N'],
  "gem": ['JH EH1 M'],
  "gem's": ['JH EH1 M Z'],
  "gemayel": ['G AH0 M EY1 AH0 L', 'JH AH0 M AY1 AH0 L', 'G AH0 M AY1 AH0 L'],
  "gemayel's": ['G AH0 M EY1 AH0 L Z', 'JH AH0 M AY1 AH0 L Z', 'G AH0 M AY1 AH0 L Z'],
  "gemberling": ['G EH1 M B ER0 L IH0 NG'],
  "gemco": ['JH EH1 M K OW0'],
  "gemcraft": ['JH EH1 M K R AE2 F T'],
  "gemcraft's": ['JH EH1 M K R AE2 F T S'],
  "gemex": ['JH EH1 M EH0 K S'],
  "gemfibrozil": ['G EH1 M F IH0 B R OW0 Z AH0 L', 'JH IH2 M F EY1 B R OW0 Z IH2 L'],
  "gemignani": ['JH EH0 M IY0 G N AA1 N IY0'],
  "gemina": ['G EH0 M IY1 N AH0'],
  "geminate": ['JH EH1 M AH0 N AH0 T', 'JH EH1 M AH0 N EY2 T'],
  "gemini": ['JH EH1 M AH0 N AY2', 'JH EH1 M AH0 N IY2'],
  "gemma": ['JH EH1 M AH0'],
  "gemme": ['JH EH1 M'],
  "gemmell": ['G EH1 M AH0 L'],
  "gemmer": ['G EH1 M ER0'],
  "gemmill": ['G EH1 M AH0 L'],
  "gems": ['JH EH1 M Z'],
  "gemsbok": ['G EH1 M Z B AA0 K'],
  "gemstone": ['JH EH1 M S T OW2 N'],
  "gemstones": ['JH EH1 M S T OW2 N Z'],
  "gen": ['JH EH1 N'],
  "gena": ['JH EH1 N AH0'],
  "genady": ['JH AH0 N EY1 D IY0'],
  "gencarelli": ['JH EH0 N K AA0 R EH1 L IY0'],
  "genco": ['JH EH1 NG K OW0'],
  "gencor": ['JH EH1 N K AO2 R'],
  "gencorp": ['JH EH1 N K AO2 R P', 'JH EH1 N K AO2 R'],
  "gencorp's": ['JH EH1 N K AO2 R P S', 'JH EH1 N K AO2 R S'],
  "gendarme": ['ZH AA1 N D AA2 R M'],
  "gender": ['JH EH1 N D ER0'],
  "gendered": ['JH EH1 N D ER0 D'],
  "genders": ['JH EH1 N D ER0 Z'],
  "gendler": ['JH EH1 N D L ER0'],
  "gendreau": ['ZH IH0 N D R OW1'],
  "gendrisek": ['JH EH1 D R IH0 S EH2 K'],
  "gendrisek's": ['JH EH1 D R IH0 S EH2 K S'],
  "gendron": ['JH EH1 N D R AH0 N'],
  "gene": ['JH IY1 N'],
  "gene's": ['JH IY1 N Z'],
  "genealogical": ['JH IY2 N IY0 AA0 L AO1 JH IH0 K AH0 L'],
  "genealogy": ['JH IY2 N IY0 AA1 L AH0 JH IY0'],
  "geneen": ['JH AH0 N IY1 N'],
  "genego": ['G EH1 N AH0 G OW2', 'JH EH1 N AH0 G OW0', 'JH IY1 IY1 EH1 N IY1 JH IY1 OW1'],
  "genelab": ['JH EH1 N AH0 L AE2 B'],
  "genelabs": ['JH EH1 N AH0 L AE2 B Z'],
  "genemedicine": ['JH EH1 N AH0 M EH1 D AH0 S AH0 N'],
  "genencor": ['JH EH1 N AH0 N K AO2 R'],
  "genentech": ['JH EH1 N AH0 N T EH2 K'],
  "genentech's": ['JH EH1 N AH0 N T EH2 K S'],
  "genera": ['JH EH1 N ER0 AH0'],
  "general": ['JH EH1 N ER0 AH0 L', 'JH EH1 N R AH0 L'],
  "general's": ['JH EH1 N ER0 AH0 L Z', 'JH EH1 N R AH0 L Z'],
  "generale": ['JH EH2 N ER0 AE1 L'],
  "generales": ['JH EH2 N EH0 R AA1 L EH0 S'],
  "generali": ['JH EH2 N ER0 AA1 L IY0'],
  "generalissimo": ['JH EH2 N EH0 R AH0 L IH1 S IH0 M OW2'],
  "generalist": ['JH EH1 N ER0 AH0 L IH0 S T'],
  "generalists": ['JH EH1 N ER0 AH0 L IH0 S T S'],
  "generalities": ['JH EH2 N ER0 AE1 L AH0 T IY0 Z'],
  "generality": ['JH EH2 N ER0 AE1 L AH0 T IY0'],
  "generalization": ['JH EH2 N ER0 AH0 L IH0 Z EY1 SH AH0 N', 'JH EH2 N R AH0 L IH0 Z EY1 SH AH0 N'],
  "generalizations": ['JH EH2 N ER0 AH0 L AH0 Z EY1 SH AH0 N Z', 'JH EH2 N R AH0 L AH0 Z EY1 SH AH0 N Z'],
  "generalize": ['JH EH1 N ER0 AH0 L AY2 Z'],
  "generalized": ['JH EH1 N ER0 AH0 L AY2 Z D', 'JH EH1 N R AH0 L AY2 Z D'],
  "generalizing": ['JH EH1 N ER0 AH0 L AY2 Z IH0 NG', 'JH EH1 N R AH0 L AY2 Z IH0 NG'],
  "generally": ['JH EH1 N ER0 AH0 L IY0', 'JH EH1 N R AH0 L IY0'],
  "generals": ['JH EH1 N ER0 AH0 L Z', 'JH EH1 N R AH0 L Z'],
  "generalship": ['JH EH1 N ER0 AH0 L SH IH2 P'],
  "generate": ['JH EH1 N ER0 EY2 T'],
  "generated": ['JH EH1 N ER0 EY2 T AH0 D', 'JH EH1 N ER0 EY2 T IH0 D'],
  "generates": ['JH EH1 N ER0 EY2 T S'],
  "generating": ['JH EH1 N ER0 EY2 T IH0 NG'],
  "generation": ['JH EH2 N ER0 EY1 SH AH0 N'],
  "generation's": ['JH EH2 N ER0 EY1 SH AH0 N Z'],
  "generational": ['JH EH2 N ER0 EY1 SH AH0 N AH0 L'],
  "generationally": ['JH EH2 N ER0 EY1 SH AH0 N AH0 L IY0'],
  "generations": ['JH EH2 N ER0 EY1 SH AH0 N Z'],
  "generative": ['JH EH1 N ER0 AH0 T IH0 V'],
  "generator": ['JH EH1 N ER0 EY2 T ER0'],
  "generator's": ['JH EH1 N ER0 EY2 T ER0 Z'],
  "generators": ['JH EH1 N ER0 EY2 T ER0 Z'],
  "genereux": ['ZH EH1 N ER0 OW0'],
  "generic": ['JH AH0 N EH1 R IH0 K'],
  "generically": ['JH AH0 N EH1 R IH0 K L IY0'],
  "generics": ['JH AH0 N EH1 R IH0 K S'],
  "genero": ['JH AH0 N ER1 OW0'],
  "generosity": ['JH EH2 N ER0 AA1 S AH0 T IY0'],
  "generous": ['JH EH1 N ER0 AH0 S'],
  "generously": ['JH EH1 N ER0 AH0 S L IY0'],
  "genes": ['JH IY1 N Z'],
  "genesco": ['JH EH0 N EH1 S K OW0'],
  "genesee": ['JH EH1 N AH0 S IY2'],
  "genesis": ['JH EH1 N AH0 S AH0 S'],
  "genet": ['JH EH1 N IH0 T'],
  "genetic": ['JH AH0 N EH1 T IH0 K'],
  "genetically": ['JH AH0 N EH1 T IH0 K L IY0'],
  "geneticist": ['JH AH0 N EH1 T AH0 S AH0 S T'],
  "geneticists": ['JH AH0 N EH1 T AH0 S IH0 S T S'],
  "genetics": ['JH AH0 N EH1 T IH0 K S'],
  "genetization": ['JH EH2 N AH0 T IH0 Z EY1 SH AH0 N'],
  "geneva": ['JH AH0 N IY1 V AH0'],
  "geneve": ['JH AH0 N IY1 V'],
  "genevieve": ['JH EH1 N AH0 V IY2 V'],
  "genex": ['JH EH1 N EH0 K S'],
  "genex's": ['JH EH1 N EH0 K S IH0 Z'],
  "geng": ['JH EH1 NG'],
  "gengenbach": ['G EH1 NG AH0 N B AA2 K', 'JH EH1 NG AH0 N B AA2 K'],
  "genger": ['JH EH1 NG G ER0'],
  "genghis": ['JH EH1 NG HH IH0 S'],
  "gengler": ['G EH1 NG L ER0', 'JH EH1 NG L ER0'],
  "genial": ['JH IY1 N Y AH0 L'],
  "geniality": ['JH IY2 N IY0 AE1 L AH0 T IY0'],
  "genicom": ['JH EH1 N IH0 K AA0 M'],
  "genie": ['JH IY1 N IY0'],
  "geniere": ['JH EH0 N Y EH1 R'],
  "genis": ['G EH1 N IH0 S'],
  "genisco": ['JH EH0 N IH1 S K OW0'],
  "genital": ['JH EH1 N AH0 T AH0 L'],
  "genitalia": ['JH EH0 N AH0 T AA1 L Y AH0'],
  "genitals": ['JH EH1 N AH0 T AH0 L Z'],
  "genius": ['JH IY1 N Y AH0 S'],
  "geniuses": ['JH IY1 N Y AH0 S IH0 Z'],
  "genk": ['JH EH1 NG K'],
  "genlyte": ['JH EH1 N L AY2 T'],
  "genmar": ['JH EH1 N M AA0 R'],
  "genna": ['JH EH1 N AH0'],
  "gennadi": ['JH EH0 N AA1 D IY0'],
  "gennady": ['G AH0 N AA1 D IY0'],
  "gennaro": ['JH AH0 N AA1 R OW0'],
  "gennett": ['JH EH1 N IH0 T'],
  "gennifer": ['JH EH1 N IH0 F ER0'],
  "geno": ['JH IY1 N OW0'],
  "genoa": ['JH EH1 N OW0 AH0'],
  "genocidal": ['JH EH1 N AH0 S AY2 D AH0 L'],
  "genocide": ['JH EH1 N AH0 S AY2 D'],
  "genocides": ['JH EH1 N AH0 S AY2 D Z'],
  "genoese": ['JH EH1 N OW0 S'],
  "genome": ['JH IY1 N OW2 M'],
  "genomics": ['JH IY2 N OW1 M IH0 K S'],
  "genossenschaftsbank": ['G EH0 N OW1 S EH0 N SH AE0 F T S B AE2 NG K'],
  "genotype": ['JH EH1 N AH0 T AY2 P'],
  "genotypes": ['JH EH1 N AH0 T AY2 P S'],
  "genova": ['JH EH1 N OW0 V AH0'],
  "genovese": ['JH EH1 N AH0 V IY0 Z'],
  "genovesi": ['JH EH2 N OW0 V EH1 S IY0'],
  "genovise": ['JH EH1 N AH0 V IY0 Z'],
  "genpharm": ['JH EH1 N F AA2 R M'],
  "genrad": ['JH EH1 N R AE0 D'],
  "genre": ['ZH AA1 N R AH0'],
  "genres": ['ZH AA1 N R AH0 Z'],
  "genrich": ['G EH1 N R IH0 K'],
  "gens": ['JH EH1 N Z'],
  "genscher": ['G EH1 N SH ER0'],
  "gensel": ['G EH1 N S AH0 L'],
  "gensia": ['JH EH2 N S IY1 AH0'],
  "genske": ['JH EH1 N S K'],
  "gensler": ['G EH1 N S AH0 L ER0', 'G EH1 N S L ER0'],
  "genson": ['JH EH1 N S AH0 N'],
  "genstar": ['JH EH1 N S T AA2 R'],
  "gent": ['JH EH1 N T'],
  "gent's": ['JH EH1 N T S'],
  "genteel": ['JH EH0 N T IY1 L'],
  "genter": ['JH EH1 N T ER0'],
  "genther": ['G EH1 N DH ER0'],
  "genthner": ['JH EH1 N TH N ER0'],
  "gentian": ['JH EH1 N SH AH0 N'],
  "gentile": ['JH EH1 N T AY2 L'],
  "gentile's": ['JH EH1 N T AY2 L Z'],
  "gentiles": ['JH EH1 N T AY2 L Z'],
  "gentility": ['JH EH0 N T IH1 L IH0 T IY0'],
  "gentle": ['JH EH1 N T AH0 L', 'JH EH1 N AH0 L'],
  "gentleladies": ['JH EH1 N T AH0 L EY2 D IY0 Z'],
  "gentlelady": ['JH EH1 N T AH0 L EY2 D IY0'],
  "gentleman": ['JH EH1 N T AH0 L M AH0 N', 'JH EH1 N AH0 L M AH0 N'],
  "gentleman's": ['JH EH1 N T AH0 L M AH2 N Z', 'JH EH1 N AH0 L M AH2 N Z'],
  "gentlemanly": ['JH EH1 N T AH0 L M AH0 N L IY0', 'JH EH1 N AH0 L M AH0 N L IY0'],
  "gentlemen": ['JH EH1 N T AH0 L M IH0 N', 'JH EH1 N AH0 L M IH0 N'],
  "gentlemen's": ['JH EH1 N T AH0 L M EH2 N Z', 'JH EH1 N AH0 L M EH2 N Z'],
  "gentleness": ['JH EH1 N T AH0 L N AH0 S', 'JH EH1 N AH0 L N AH0 S'],
  "gentler": ['JH EH1 N T L ER0'],
  "gentles": ['JH EH1 N T AH0 L Z', 'JH EH1 N AH0 L Z'],
  "gentlest": ['JH EH1 N T AH0 L AH0 S T'],
  "gentlewoman": ['JH EH1 N T AH0 L W UH2 M AH0 N', 'JH EH1 N AH0 L W UH2 M AH0 N'],
  "gentlewoman's": ['JH EH1 N T AH0 L W UH2 M AH0 N Z', 'JH EH1 N AH0 L W UH2 M AH0 N Z'],
  "gentlewomen": ['JH EH1 N T AH0 L W IH2 M AH0 N', 'JH EH1 N AH0 L W IH2 M AH0 N'],
  "gentlewomen's": ['JH EH1 N T AH0 L W IH2 M AH0 N Z', 'JH EH1 N AH0 L W IH2 M AH0 N Z'],
  "gently": ['JH EH1 N T L IY0'],
  "gentner": ['JH EH1 N T N ER0'],
  "gentrification": ['JH EH2 N T R IH0 F IH0 K EY1 SH AH0 N'],
  "gentrified": ['JH EH1 N T R IH0 F AY2 D'],
  "gentrify": ['JH EH1 N T R IH0 F AY2'],
  "gentrifying": ['JH EH1 N T R IH0 F AY2 IH0 NG'],
  "gentry": ['JH EH1 N T R IY0'],
  "gents": ['JH EH1 N T S'],
  "gentz": ['JH EH1 N T S'],
  "gentzler": ['JH EH1 N T S L ER0'],
  "genuine": ['JH EH1 N Y AH0 W AH0 N', 'JH EH1 N Y UW1 W AY2 N'],
  "genuinely": ['JH EH1 N Y AH0 W AH0 N L IY0', 'JH EH1 N Y UW1 W AY2 N L IY0'],
  "genuineness": ['JH EH1 N Y AH0 W AH0 N IH0 S'],
  "genung": ['JH EH1 N AH0 NG'],
  "genus": ['JH IY1 N AH0 S'],
  "genz": ['JH EH1 N Z'],
  "genzyme": ['JH EH1 N Z AY2 M'],
  "geo": ['JH IY1 OW0'],
  "geocentric": ['JH IY2 OW0 S EH1 N T R IH0 K'],
  "geochemistry": ['JH IY2 OW0 K EH1 M AH0 S T R IY0'],
  "geodesic": ['JH IY2 AH0 D EH1 S IH0 K'],
  "geodesy": ['JH IY0 AA1 D AH0 S IY0'],
  "geodetic": ['JH IY2 AH0 D EH1 T IH0 K'],
  "geodyne": ['JH IY1 OW0 D AY2 N'],
  "geoff": ['JH EH1 F'],
  "geoffrey": ['JH EH1 F R IY0'],
  "geoffrey's": ['JH EH1 F R IY0 Z'],
  "geoffrion": ['JH IY2 AA1 F R IY0 AH0 N'],
  "geoffroy": ['JH IY1 AH0 F R OY0'],
  "geoghegan": ['G AH0 HH EY1 G AH0 N'],
  "geographer": ['JH IY0 AA1 G R AH0 F ER0'],
  "geographic": ['JH IY2 AH0 G R AE1 F IH0 K'],
  "geographic's": ['JH IY2 AH0 G R AE1 F IH0 K S'],
  "geographical": ['JH IY2 AH0 G R AE1 F IH0 K AH0 L'],
  "geographically": ['JH IY2 AH0 G R AE1 F IH0 K AH0 L IY0', 'JH IY2 AH0 G R AE1 F IH0 K L IY0'],
  "geography": ['JH IY0 AA1 G R AH0 F IY0'],
  "geoid": ['JH IY1 OY0 D'],
  "geologic": ['JH IY2 AH0 L AA1 JH IH0 K'],
  "geological": ['JH IY2 AH0 L AA1 JH IH0 K AH0 L'],
  "geologist": ['JH IY0 AA1 L AH0 JH AH0 S T'],
  "geologists": ['JH IY0 AA1 L AH0 JH IH0 S T S'],
  "geology": ['JH IY0 AA1 L AH0 JH IY0'],
  "geomagnetic": ['JH IY2 OW0 M AE0 G N EH1 T IH0 K'],
  "geometric": ['JH IY2 AH0 M EH1 T R IH0 K'],
  "geometrical": ['JH IY2 AH0 M EH1 T R IH0 K AH0 L'],
  "geometrically": ['JH IY2 AH0 M EH1 T R IH0 K L IY0'],
  "geometrics": ['JH IY2 AH0 M EH1 T R IH0 K S'],
  "geometries": ['JH IY0 AA1 M AH0 T R IY0 Z'],
  "geometry": ['JH IY0 AA1 M AH0 T R IY0'],
  "geomorphology": ['JH IY2 AH0 M AO2 R F AA1 L AH0 JH IY0'],
  "geon": ['JH IY1 AA0 N'],
  "geophysical": ['JH IY2 OW0 F IH1 Z AH0 K AH0 L'],
  "geopolitic": ['JH IY2 OW0 P AA1 L IH0 T IH0 K'],
  "geopolitical": ['JH IY2 OW0 P AH0 L IH1 T IH0 K AH0 L'],
  "geopolitically": ['JH IY2 OW0 P AH0 L IH1 T IH0 K L IY0'],
  "geopolitics": ['JH IY2 OW0 P AA1 L AH0 T IH0 K S'],
  "geordie": ['JH IY1 ER0 D IY0'],
  "georg": ['G EY1 AO0 R G'],
  "georgakis": ['JH AO2 R JH AA1 K AH0 S'],
  "georgann": ['JH AO2 R JH AE1 N'],
  "george": ['JH AO1 R JH'],
  "george's": ['JH AO1 R JH AH0 Z', 'JH AO1 R JH IH0 Z'],
  "georgene": ['JH AO1 R JH IY2 N'],
  "georges": ['JH AO1 R JH AH0 Z', 'JH AO1 R JH IH0 Z'],
  "georgeson": ['JH AO1 R JH IH0 S AH0 N', 'JH AO1 R JH S AH0 N'],
  "georgetown": ['JH AO1 R JH T AW2 N'],
  "georgette": ['JH AO0 R JH EH1 T'],
  "georgi": ['JH IY0 AA1 R JH IY0'],
  "georgia": ['JH AO1 R JH AH0'],
  "georgia's": ['JH AO1 R JH AH0 Z'],
  "georgiades": ['JH AO2 R JH IY0 AA1 D AH0 S'],
  "georgiadis": ['JH AO2 R JH IY0 AA1 D IH0 S'],
  "georgian": ['JH AO1 R JH AH0 N'],
  "georgiana": ['JH AO2 R JH IY0 AE1 N AH0'],
  "georgians": ['JH AO1 R JH AH0 N Z'],
  "georgie": ['JH AO1 R JH IY0'],
  "georgienne": ['JH AO2 R JH IY0 EH1 N'],
  "georgina": ['JH AO2 R JH IY1 N AH0'],
  "georgine": ['JH AO2 R JH IY1 N'],
  "georgio": ['JH AO1 R JH IY2 OW0'],
  "georgiou": ['JH AO2 R JH OW1'],
  "georgopoulos": ['JH AO2 R JH AA1 P AH0 L IH0 S'],
  "georgy": ['JH AO1 R JH IY0'],
  "geostrophic": ['JH IY2 OW0 S T R AA1 F IH0 K'],
  "geosyncline": ['JH IY2 OW0 S IH1 N K L AY0 N'],
  "geotaxis": ['JH IY2 OW0 T AE1 K S AH0 S'],
  "geotek": ['G IY1 OW0 T EH2 K'],
  "geothermal": ['JH IY2 OW0 TH ER1 M AH0 L'],
  "geotropic": ['JH IY2 AH0 T R AA1 P IH0 K'],
  "geotropism": ['JH IY0 AA1 T R AH0 P IH2 Z AH0 M'],
  "geoworks": ['JH IY1 OW0 W ER0 K S'],
  "gephardt": ['G EH1 P HH AA2 R T'],
  "gephardt's": ['G EH1 P HH AA2 R T S'],
  "gephart": ['G EH1 P HH AA0 R T'],
  "geppert": ['G EH1 P ER0 T'],
  "gera": ['JH EH1 R AH0'],
  "gerace": ['JH ER0 AA1 CH IY0'],
  "geraci": ['JH ER0 AA1 CH IY0'],
  "geraghty": ['JH EH1 R AH0 T IY0'],
  "gerais": ['JH ER0 EY1'],
  "gerald": ['JH EH1 R AH0 L D'],
  "geraldi": ['JH EH0 R AE1 L D IY0', 'HH EH0 R AE1 L D IY0'],
  "geraldina": ['JH ER0 AA0 L D IY1 N AH0'],
  "geraldine": ['JH EH0 R AH0 L D IY1 N'],
  "geraldo": ['JH ER0 AA1 L D OW0', 'HH ER0 AA1 L D OW0'],
  "geralds": ['JH EH1 R AH0 L D Z'],
  "geran": ['JH EH1 R AH0 N'],
  "geranium": ['JH ER0 EY1 N IY0 AH0 M'],
  "geraniums": ['JH ER0 EY1 N IY0 AH0 M Z'],
  "gerard": ['JH ER0 AA1 R D'],
  "gerardi": ['JH ER0 AA1 R D IY0'],
  "gerardo": ['JH ER0 AA1 R D OW0'],
  "gerashchenko": ['G EH2 AH0 SH EY1 NG K OW0'],
  "gerasimov": ['JH ER0 AE1 S IH0 M AA0 V', 'G ER0 AE1 S IH0 M AA0 V'],
  "gerbasi": ['JH ER0 B AA1 S IY0'],
  "gerber": ['G ER1 B ER0'],
  "gerber's": ['G ER1 B ER0 Z'],
  "gerberding": ['G ER1 B ER0 D IH0 NG'],
  "gerbert": ['G ER1 B ER0 T'],
  "gerbig": ['G ER1 B IH0 G'],
  "gerbil": ['JH ER1 B IH0 L'],
  "gerbils": ['JH ER1 B IH0 L S'],
  "gerbino": ['JH ER0 B IY1 N OW0'],
  "gerbrandt": ['G ER1 B R AE2 N T'],
  "gerchas": ['G ER1 CH AH0 Z', 'G ER1 SH AH0 Z'],
  "gerd": ['G ER1 D'],
  "gerda": ['G ER1 D AH0'],
  "gerdeman": ['G ER1 D M AH0 N'],
  "gerdes": ['ZH ER1 D Z'],
  "gerdes'": ['ZH ER1 D Z'],
  "gerding": ['G EH1 R T IH0 NG'],
  "gerdts": ['G ER1 D T S'],
  "gere": ['G IH1 R'],
  "geremia": ['JH ER0 IY1 M IY0 AH0'],
  "geren": ['G IH1 R AH0 N'],
  "gerena": ['JH ER0 EH1 N AH0'],
  "gerety": ['G EH1 R IH0 T IY0'],
  "gergel": ['G ER1 G AH0 L'],
  "gergely": ['JH ER1 JH L IY0'],
  "gergen": ['G ER1 G AH0 N'],
  "gergen's": ['G ER1 G AH0 N Z'],
  "gerger": ['G ER1 G ER0'],
  "gergrude": ['G ER1 G R UW0 D'],
  "gerhard": ['G ER1 HH AA2 R D'],
  "gerhardt": ['G ER1 HH AA0 R T'],
  "gerhart": ['G ER1 HH AA0 R T'],
  "gerhold": ['G ER1 HH OW0 L D'],
  "geri": ['JH EH1 R IY2'],
  "geriatric": ['JH EH2 R IY0 AE1 T R IH0 K'],
  "geriatrician": ['JH EH2 R IY0 AH0 T R IH1 SH AH0 N'],
  "geriatricians": ['JH EH2 R IY0 AH0 T R IH1 SH AH0 N Z'],
  "geriatrics": ['JH EH2 R IY0 AE1 T R IH0 K S'],
  "gerich": ['G EH1 R IH0 K'],
  "gericke": ['JH EH1 R IH0 K'],
  "gerig": ['JH EH1 R IH0 G'],
  "gering": ['G IH1 R IH0 NG'],
  "geringer": ['G EH1 R IH0 N JH ER0'],
  "gerk": ['G ER1 K'],
  "gerke": ['G ER1 K IY0'],
  "gerken": ['G ER1 K AH0 N'],
  "gerkin": ['JH ER1 K IH0 N'],
  "gerlach": ['G ER1 L AH0 K'],
  "gerleman": ['G AO1 R L M AH0 N'],
  "gerlich": ['G ER1 L IH0 K'],
  "gerling": ['G ER1 L IH0 NG'],
  "gerlock": ['G ER1 L AH0 K'],
  "gerloff": ['G ER1 L AO0 F'],
  "germ": ['JH ER1 M'],
  "germain": ['JH ER0 M EY1 N'],
  "germain's": ['JH ER0 M EY1 N Z'],
  "germaine": ['ZH ER0 M EY1 N'],
  "german": ['JH ER1 M AH0 N'],
  "german's": ['JH ER1 M AH0 N Z'],
  "germane": ['JH ER0 M EY1 N'],
  "germani": ['JH ER0 M AA1 N IY0'],
  "germania": ['JH ER0 M EY1 N IY0 AH0'],
  "germanic": ['JH ER0 M AE1 N IH0 K'],
  "germann": ['G ER1 M AH0 N'],
  "germano": ['JH ER0 M AA1 N OW0'],
  "germans": ['JH ER1 M AH0 N Z'],
  "germans'": ['JH ER1 M AH0 N Z'],
  "germantown": ['JH ER1 M AH0 N T AW2 N'],
  "germany": ['JH ER1 M AH0 N IY0'],
  "germany's": ['JH ER1 M AH0 N IY0 Z'],
  "germanys": ['JH ER1 M AH0 N IY0 Z'],
  "germe": ['JH ER1 M'],
  "germer": ['JH ER1 M ER0'],
  "germicide": ['JH ER1 M AH0 S AY2 D'],
  "germinate": ['JH ER1 M AH0 N EY2 T'],
  "germinated": ['JH ER1 M AH0 N EY2 T IH0 D'],
  "germination": ['JH ER2 M AH0 N EY1 SH AH0 N'],
  "germiston": ['JH ER1 M AH0 S T AA2 N'],
  "germiston's": ['JH ER1 M AH0 S T AA2 N Z'],
  "germond": ['G ER1 M AH0 N D'],
  "germs": ['JH ER1 M Z'],
  "gerner": ['G ER1 N ER0'],
  "gernert": ['G ER1 N ER0 T'],
  "gero": ['JH EH1 R OW0'],
  "gerold": ['G EH1 R OW0 L D'],
  "gerome": ['G EH1 R AH0 M'],
  "geron": ['JH EH1 R AH0 N'],
  "geronimo": ['JH AH0 R AO1 N IH0 M OW2'],
  "gerontologist": ['JH EH2 R AH0 N T AA1 L AH0 JH IH0 S T'],
  "gerontology": ['JH EH2 R AH0 N T AA1 L AH0 JH IY0'],
  "geroux": ['ZH ER0 UW1'],
  "gerow": ['JH EH1 R OW0'],
  "gerrald": ['JH EH1 R AH0 L D'],
  "gerri": ['JH EH1 R IY0'],
  "gerrick": ['JH EH1 R IH0 K'],
  "gerrie": ['JH EH1 R IY0'],
  "gerringer": ['JH EH1 R IH0 NG ER0'],
  "gerrish": ['JH EH1 R IH0 SH'],
  "gerrit": ['JH EH1 R IH0 T'],
  "gerrits": ['JH EH1 R IH0 T S'],
  "gerritsen": ['G EH1 R IH0 T S AH0 N'],
  "gerrity": ['JH EH1 R IH0 T IY0'],
  "gerry": ['JH EH1 R IY0'],
  "gerry's": ['JH EH1 R IY0 Z'],
  "gerrymander": ['JH EH1 R IY0 M AE2 N D ER0'],
  "gerrymandered": ['JH EH2 R IY0 M AE1 N D ER0 D'],
  "gerrymandering": ['JH EH2 R IY0 M AE1 N D ER0 IH0 NG'],
  "gerrymanders": ['JH EH1 R IY0 M AE2 N D ER0 Z'],
  "gersch": ['G ER1 SH'],
  "gersh": ['G ER1 SH'],
  "gershman": ['G ER1 SH M AH0 N'],
  "gershon": ['G ER1 SH AH0 N'],
  "gershowitz": ['G ER1 SH AH0 W IH0 T S'],
  "gershwin": ['G ER1 SH W IH0 N'],
  "gershwin's": ['G ER1 SH W IH0 N Z'],
  "gerski": ['G ER1 S K IY0'],
  "gerson": ['JH ER1 S AH0 N', 'G ER1 S AH0 N'],
  "gerst": ['G ER1 S T'],
  "gerstein": ['G ER1 S T AY0 N', 'G ER1 S T IY0 N'],
  "gerstel": ['G ER1 S T AH0 L'],
  "gersten": ['G ER1 S T AH0 N'],
  "gerstenberger": ['G ER1 S T AH0 N B ER0 G ER0'],
  "gerstenhaber": ['G ER1 S T AH0 N HH EY2 B ER0'],
  "gerster": ['G ER1 S T ER0'],
  "gerstman": ['G ER1 S T M AH0 N'],
  "gerstner": ['G ER1 S T N ER0'],
  "gerstner's": ['G ER1 S T N ER0 Z'],
  "gerszewski": ['G ER0 SH EH1 F S K IY0'],
  "gert": ['G ER1 T'],
  "gerth": ['G ER1 TH'],
  "gertie": ['JH ER1 T IY0'],
  "gertler": ['G ER1 T AH0 L ER0', 'G ER1 T L ER0'],
  "gertner": ['G ER1 T N ER0'],
  "gertrud": ['G ER1 T R UW0 D'],
  "gertrude": ['G ER1 T R UW0 D'],
  "gertsch": ['G ER1 CH'],
  "gerty": ['JH ER1 T IY0'],
  "gertz": ['G ER1 T S'],
  "gerund": ['JH EH1 R AH0 N D'],
  "gerunds": ['JH EH1 R AH0 N D Z'],
  "gervais": ['ZH ER0 V EY1'],
  "gervase": ['G ER1 V AH0 S'],
  "gervasi": ['JH ER0 V AA1 S IY0'],
  "gervasio": ['JH ER0 V AA1 S IY0 OW0'],
  "gerwig": ['G ER1 W IH0 G'],
  "gerwin": ['G ER1 W IH0 N'],
  "gery": ['JH EH1 R IY0'],
  "gesamtmetall": ['G EH0 S AE1 M T M EH2 T AH0 L'],
  "geschke": ['G EH1 SH K'],
  "gesco": ['G EH1 S K OW0'],
  "gesell": ['G EH1 S AH0 L'],
  "gesell's": ['G EH1 S AH0 L Z'],
  "gesellschaft": ['G AH0 S EH1 L SH AE0 F T'],
  "geserum": ['G AH0 S EH1 R AH0 M'],
  "geske": ['JH EH1 S K'],
  "gesner": ['G EH1 S N ER0'],
  "gess": ['JH EH1 S'],
  "gessel": ['G EH1 S AH0 L'],
  "gessert": ['G EH1 S ER0 T'],
  "gessler": ['G EH1 S AH0 L ER0', 'G EH1 S L ER0'],
  "gessner": ['G EH1 S N ER0'],
  "gest": ['JH EH1 S T'],
  "gestal": ['JH EH1 S T AH0 L'],
  "gestapo": ['G AH0 S T AA1 P OW0'],
  "gestate": ['JH EH1 S T EY2 T'],
  "gestation": ['JH EH0 S T EY1 SH AH0 N'],
  "gestetner": ['G AH0 S T EH1 T N ER0'],
  "gesture": ['JH EH1 S CH ER0'],
  "gestured": ['JH EH1 S CH ER0 D'],
  "gestures": ['JH EH1 S CH ER0 Z'],
  "gesturing": ['JH EH1 S CH ER0 IH0 NG'],
  "gesualdi": ['JH EH0 S UW0 AA1 L D IY0'],
  "gesundheit": ['G AH0 Z UH1 N D HH AY0 T'],
  "get": ['G EH1 T', 'G IH1 T'],
  "get-together": ['G EH1 T T AH0 G EH1 DH ER0', 'G EH1 T AH0 G EH1 DH ER0'],
  "get-togethers": ['G EH1 T T AH0 G EH1 DH ER0 Z', 'G EH1 T AH0 G EH1 DH ER0 Z'],
  "get-well": ['G EH1 T W EH1 L', 'G EH1 T HH W EH1 L'],
  "getaway": ['G EH1 T AH0 W EY2'],
  "getaways": ['G EH1 T AH0 W EY2 Z'],
  "getchell": ['G EH1 CH AH0 L'],
  "geter": ['G EH1 T ER0'],
  "gethers": ['G EH1 DH ER0 Z'],
  "getman": ['G EH1 T M AH0 N'],
  "gets": ['G EH1 T S', 'G IH1 T S'],
  "getsinger": ['G EH1 T S IH0 N JH ER0'],
  "gettel": ['G EH1 T AH0 L'],
  "getter": ['G EH1 T ER0'],
  "getters": ['G EH1 T ER0 Z'],
  "gettin'": ['G IH1 T AH0 N'],
  "getting": ['G EH1 T IH0 NG', 'G IH1 T IH0 NG'],
  "gettinger": ['G EH1 T IH0 NG ER0', 'G EH1 T IH0 N JH ER0'],
  "gettings": ['G EH1 T IH0 NG Z'],
  "gettis": ['G EH1 T IH0 S'],
  "gettle": ['G EH1 T AH0 L'],
  "gettler": ['G EH1 T AH0 L ER0', 'G EH1 T L ER0'],
  "gettman": ['G EH1 T M AH0 N'],
  "getts": ['G EH1 T S'],
  "getty": ['G EH1 T IY0'],
  "getty's": ['G EH1 T IY0 Z'],
  "gettys": ['G EH1 T IY0 Z'],
  "gettysburg": ['G EH1 T IY0 Z B ER0 G'],
  "gettysburg's": ['G EH1 T IY0 Z B ER0 G Z'],
  "getulio": ['G AH0 CH UW1 L IY0 OW0'],
  "getz": ['G EH1 T S'],
  "geurin": ['ZH ER0 AE1 N'],
  "geurts": ['ZH ER1 T S'],
  "geva": ['G EY1 V AH0'],
  "gevaert": ['G AH0 V EH1 R T'],
  "geving": ['JH IY1 V IH0 NG'],
  "gewgaw": ['G Y UW1 G AA0'],
  "gewirtz": ['G UW1 ER0 T S'],
  "geyelin": ['JH EY2 IH1 L IH0 N'],
  "geyer": ['G EY1 ER0'],
  "geyser": ['G AY1 Z ER0'],
  "geysers": ['G AY1 Z ER0 Z'],
  "gfeller": ['G AH0 F EH1 L ER0'],
  "ghada": ['G AA1 D AH0'],
  "ghadafi": ['G AH0 D AE1 F IY0', 'K AH0 D AA1 F IY0', 'G AH0 D AA1 F IY0'],
  "ghadafithe": ['G AH0 D AA1 F IY0 TH'],
  "ghadafito": ['G AA2 D AH0 F IY1 T OW0'],
  "ghafar": ['G AH0 F AA1 R', 'G AO1 F ER0'],
  "ghali": ['G AA1 L IY0'],
  "ghali's": ['G AA1 L IY0 Z'],
  "ghana": ['G AE1 N AH0'],
  "ghana's": ['G AE1 N AH0 Z'],
  "ghanaian": ['G AH0 N AY1 AH0 N'],
  "ghanaians": ['G AH0 N AY1 AH0 N Z'],
  "ghandi": ['G AA1 N D IY0'],
  "gharbonifar": ['G AA0 R B AA1 N IH0 F AA0 R'],
  "ghastliness": ['G AE1 S T L IY0 N AH0 S'],
  "ghastly": ['G AE1 S T L IY0'],
  "ghattas": ['G AA1 T AH2 S'],
  "ghaznavi": ['G AH0 Z N AA1 V IY0'],
  "ghee": ['G IY1'],
  "gheen": ['G IY1 N'],
  "ghent": ['G EH1 N T'],
  "gherardi": ['G ER0 AA1 R D IY0'],
  "gherig": ['G EH1 R IH0 G'],
  "gherig's": ['G EH1 R IH0 G Z'],
  "gherman": ['G ER1 M AH0 N'],
  "ghetto": ['G EH1 T OW0'],
  "ghettoize": ['G EH1 T OW0 AY2 Z'],
  "ghettos": ['G EH1 T OW0 Z'],
  "ghez": ['G EH1 Z'],
  "ghezzi": ['G EH1 Z IY0'],
  "ghidella": ['G IH0 D EH1 L AH0'],
  "ghio": ['G AY1 OW0'],
  "ghitis": ['G IY1 T IH0 Z', 'G IH1 T IH0 Z'],
  "gholamreza": ['G OW2 L AH0 M R EY1 Z AH0'],
  "gholson": ['G OW1 L S AH0 N'],
  "gholston": ['G OW1 L S T AH0 N'],
  "ghorbanifar": ['G AO0 R B AE1 N IH0 F AA0 R', 'G AO0 R B AA1 N IH0 F AA0 R', 'G AO0 R B AE1 N IH0 F ER0', 'G AO0 R B AA1 N IH0 F ER0'],
  "ghormley": ['G AO1 R M L IY0'],
  "ghose": ['G OW1 Z'],
  "ghosh": ['G AA1 SH'],
  "ghost": ['G OW1 S T'],
  "ghostbuster": ['G OW1 S T B AH2 S T ER0'],
  "ghostbusters": ['G OW1 S T B AH2 S T ER0 Z'],
  "ghostlike": ['G OW1 S T L AY2 K'],
  "ghostly": ['G OW1 S T L IY0'],
  "ghosts": ['G OW1 S T S'],
  "ghoul": ['G UW1 L'],
  "ghoulish": ['G UW1 L IH0 SH'],
  "ghoulishly": ['G UW1 L IH0 SH L IY0'],
  "ghouls": ['G UW1 L Z'],
  "ghrist": ['G R IH1 S T'],
  "ghulam": ['G Y UW1 L AE0 M'],
  "ghuloum": ['G UW2 L OW1 M'],
  "gia's": ['JH IY1 AH0 Z'],
  "giacalone": ['JH AA1 K AA0 L OW2 N IY2'],
  "giacco": ['JH AA1 K OW2'],
  "giacinta": ['JH AA1 CH IY0 N T AA2'],
  "giacobbe": ['JH AA1 K OW0 B IY2'],
  "giacomelli": ['JH AH0 K OW0 M EH1 L IY2'],
  "giacometti": ['JH AH0 K AH0 M EH1 T IY2'],
  "giacomini": ['JH AH0 K OW0 M IY1 N IY2'],
  "giacomo": ['JH AA1 K AH0 M OW2'],
  "giaconda": ['JH IY2 AH0 K AA1 N D AA2'],
  "giaconda's": ['JH IY1 AH0 K AA1 N D AH0 Z'],
  "giacone": ['JH IY2 AH0 K OW1 N IY0'],
  "giaimo": ['JH EY1 M OW0'],
  "gialanella": ['JH AH0 L AA0 N EH1 L AH0'],
  "giamatti": ['JH IY2 AH0 M AA1 T IY2'],
  "giambalvo": ['JH AA1 M B AA0 L V OW2'],
  "giambra": ['JH AA1 M B R AH0'],
  "giambrone": ['JH AA1 M B R OW0 N IY0'],
  "giammalva": ['JH IY2 AH0 M AO1 L V AH0'],
  "giammarco": ['JH AA1 M AA0 R K OW2'],
  "giammarino": ['JH AH0 M AA0 R IY1 N OW2'],
  "giampa": ['JH AA1 M P AH0'],
  "giampaolo": ['JH AA0 M P AW1 L OW2'],
  "giampapa": ['JH AA0 M P AA1 P AA2'],
  "giampietro": ['JH AA1 M P IY0 T R OW2'],
  "gian": ['JH IY1 AA0 N'],
  "giancana": ['JH IY0 AH0 K AA1 N AA2'],
  "giancarlo": ['JH IY2 AE0 NG K AA1 R L OW2'],
  "giancola": ['JH AA1 NG K OW0 L AA0'],
  "gianelli": ['JH AH0 N EH1 L IY2'],
  "gianfrancesco": ['JH AA2 N F R AE0 N CH EH1 S K OW2'],
  "gianfranco": ['JH AH0 N F R AA1 N K OW2', 'JH IY1 AH0 N F R AE1 N K OW2'],
  "giang": ['JH IY0 AA1 NG', 'JH AA1 NG'],
  "giangrande": ['JH AA1 N G R AE0 N D IY0'],
  "gianini": ['JH AH0 N IY1 N IY2'],
  "gianino": ['JH AH0 N IY1 N OW2'],
  "giannattasio": ['JH AA1 N AA0 T AA2 S IY0 OW2'],
  "giannelli": ['JH AH0 N EH1 L IY2'],
  "giannetti": ['JH AH0 N EH1 T IY2'],
  "giannetto": ['JH AH0 N EH1 T OW2'],
  "gianni": ['JH AA1 N IY2', 'JH IY0 AA1 N IY2', 'JH Y AA1 N IY2'],
  "giannini": ['JH AH0 N IY1 N IY2'],
  "giannino": ['JH IY2 AH0 N IY1 N OW2'],
  "giannola": ['JH AA1 N OW0 L AH0'],
  "giannone": ['JH AA1 N OW0 N IY2'],
  "giannotti": ['JH AA1 N OW0 T IY2'],
  "gianotti": ['JH AA1 N OW0 T IY2'],
  "giant": ['JH AY1 AH0 N T'],
  "giant's": ['JH AY1 AH0 N T S'],
  "giants": ['JH AY1 AH0 N T S'],
  "giants'": ['JH AY1 AH0 N T S'],
  "giap": ['JH IY0 AE1 P', 'JH Y AE1 P'],
  "giaquinto": ['JH AA1 K W IY0 N T OW0'],
  "giard": ['JH IY0 AA1 R D', 'JH AA1 R D'],
  "giardi": ['JH IY0 AA1 R D IY0'],
  "giardina": ['JH AA1 R D IY0 N AH0'],
  "giardini": ['JH ER0 D IY1 N IY0'],
  "giardino": ['JH ER0 D IY1 N OW0'],
  "giarratano": ['JH AA2 R AH0 T AA1 N OW0'],
  "giarrusso": ['JH AA0 R UW1 S OW0'],
  "gib": ['G IH1 B'],
  "gibas": ['JH AY1 B AH0 Z'],
  "gibb": ['JH IH1 B'],
  "gibb's": ['G IH1 B Z'],
  "gibbard": ['ZH IH0 B AA1 R D'],
  "gibbens": ['G IH1 B AH0 N Z'],
  "gibberish": ['G IH1 B ER0 IH0 SH'],
  "gibbins": ['JH IH1 B IH0 N Z'],
  "gibble": ['JH IH1 B AH0 L'],
  "gibbon": ['G IH1 B AH0 N'],
  "gibboney": ['JH IH1 B AH0 N IY0'],
  "gibbons": ['G IH1 B IH0 N Z'],
  "gibbs": ['G IH1 B Z'],
  "gibbs's": ['G IH1 B Z IH0 Z'],
  "gibby": ['JH IH1 B IY0'],
  "gibe": ['JH AY1 B'],
  "gibeau": ['ZH IH0 B OW1'],
  "gibeault": ['ZH IH0 B OW1'],
  "giberson": ['JH IH1 B ER0 S AH0 N'],
  "gibert": ['G IH1 B ER0 T'],
  "gibes": ['JH AY1 B Z'],
  "gibian": ['G IH1 B IY0 AH0 N'],
  "gibian's": ['G IH1 B IY0 AH0 N Z'],
  "giblen": ['G IH1 B L AH0 N'],
  "gibler": ['JH IH1 B AH0 L ER0', 'JH IH1 B L ER0'],
  "giblet": ['JH IH1 B L AH0 T'],
  "giblin": ['JH IH1 B L IH0 N'],
  "gibney": ['JH IH1 B N IY0'],
  "giboney": ['JH IH1 B AH0 N IY0'],
  "gibraltar": ['JH IH0 B R AO1 L T ER0'],
  "gibran": ['JH IH0 B R AA1 N'],
  "gibson": ['G IH1 B S AH0 N'],
  "gibson's": ['G IH1 B S AH0 N Z'],
  "gibsons": ['G IH1 B S AH0 N Z'],
  "gick": ['JH IH1 K'],
  "gidcumb": ['G IH1 D K AH0 M'],
  "giddens": ['G IH1 D AH0 N Z'],
  "giddings": ['G IH1 D IH0 NG Z'],
  "giddy": ['G IH1 D IY0'],
  "gide": ['ZH IY1 D'],
  "gidel": ['G AY1 D EH2 L'],
  "gideon": ['G IH1 D IY0 AH0 N'],
  "gidget": ['G IH1 JH AH0 T'],
  "gidley": ['G IH1 D L IY0'],
  "gidney": ['G IH1 D N IY0'],
  "gidwitz": ['G IH1 D W IH0 T S'],
  "giebel": ['G IY1 B AH0 L'],
  "giebler": ['G IY1 B AH0 L ER0', 'G IY1 B L ER0'],
  "gieck": ['JH IY1 K'],
  "giefer": ['G IY1 F ER0'],
  "gieger": ['G IY1 G ER0'],
  "giegerich": ['G IY1 G ER0 IH0 K'],
  "giel": ['JH IY1 L'],
  "gielgud": ['G IY1 L G AH0 D'],
  "gielow": ['JH IY1 L OW0'],
  "gienger": ['G IY1 N JH ER0'],
  "gienow": ['G IY1 N AW0'],
  "gier": ['JH IH1 R'],
  "giere": ['JH IH1 R'],
  "gierhart": ['G IH1 R HH AA0 R T'],
  "gierke": ['JH IH1 R K'],
  "gierman": ['G IH1 R M AH0 N'],
  "giersch": ['G IH1 R SH'],
  "gies": ['G IY1 Z'],
  "giesbrecht": ['G IY1 S B R IH0 K T'],
  "giese": ['JH IY1 S'],
  "giesecke": ['G IY1 S IH0 K'],
  "gieseke": ['G IY1 S IH0 K'],
  "gieseler": ['G IY1 S AH0 L ER0'],
  "gieselman": ['G IY1 S AH0 L M AH0 N'],
  "giesen": ['G IY1 S AH0 N'],
  "gieser": ['G IY1 S ER0'],
  "giesey": ['JH IY1 S IY0'],
  "gieske": ['JH IY1 S K'],
  "giesler": ['G IY1 S AH0 L ER0', 'G IY1 S L ER0'],
  "giessen": ['G IY1 Z S AH0 N'],
  "gietzen": ['G IY1 T Z AH0 N'],
  "giff": ['G IH1 F'],
  "giffard": ['G IH1 F ER0 D'],
  "giffen": ['G IH1 F AH0 N'],
  "gifferd": ['G IH1 F ER0 D'],
  "giffin": ['G IH1 F IH0 N'],
  "gifford": ['G IH1 F ER0 D'],
  "gifford's": ['G IH1 F ER0 D Z'],
  "giffords": ['G IH1 F ER0 D Z'],
  "giffy": ['G IH1 F IY0'],
  "gift": ['G IH1 F T'],
  "gift-horse": ['G IH1 F T HH AO1 R S'],
  "gifted": ['G IH1 F T AH0 D', 'G IH1 F T IH0 D'],
  "gifting": ['G IH1 F T IH0 NG'],
  "giftrust": ['G IH1 F T R AH2 S T'],
  "gifts": ['G IH1 F T S', 'G IH1 F S'],
  "giftware": ['G IH1 F T W EH2 R'],
  "gig": ['G IH1 G'],
  "gigabyte": ['G IH1 G AH0 B AY2 T'],
  "gigabytes": ['G IH1 G AH0 B AY2 T S'],
  "gigaflop": ['G IH1 G AH0 F L AA2 P'],
  "gigaflops": ['G IH1 G AH0 F L AA2 P S'],
  "gigante": ['JH IY0 G AA1 N T IY0'],
  "gigantic": ['JH AY0 G AE1 N T IH0 K', 'JH AY0 G AE1 N IH0 K'],
  "giger": ['G AY1 G ER0'],
  "gigging": ['G IH1 G IH0 NG'],
  "giggle": ['G IH1 G AH0 L'],
  "giggled": ['G IH1 G AH0 L D'],
  "giggles": ['G IH1 G AH0 L Z'],
  "giggling": ['G IH1 G AH0 L IH0 NG', 'G IH1 G L IH0 NG'],
  "giggly": ['G IH1 G AH0 L IY0'],
  "gigi": ['JH IY1 JH IY0'],
  "giglia": ['JH IY1 G L IY0 AH0'],
  "giglio": ['JH IH1 G L IY0 OW0'],
  "gigliotti": ['JH IY0 G L IY0 OW1 T IY0'],
  "gignac": ['G IH1 G N AH0 K'],
  "gignoux": ['G IH0 N UW1'],
  "gigolo": ['JH IH1 G AH0 L OW0'],
  "gigot": ['JH IH1 G AH0 T'],
  "gigot's": ['JH IH1 G AH0 T S'],
  "gigs": ['G IH1 G Z'],
  "giguere": ['JH IY0 G EH1 R EY0'],
  "gikas": ['G AY1 K AH0 Z'],
  "gil": ['G IH1 L'],
  "gila": ['G IY1 L AH0'],
  "gilardi": ['JH IY0 L AA1 R D IY0'],
  "gilb": ['G IH1 L B'],
  "gilberg": ['G IH1 L B ER0 G'],
  "gilbert": ['G IH1 L B ER0 T'],
  "gilbert's": ['G IH1 L B ER0 T S'],
  "gilberta": ['JH IY0 L B EH1 R T AH0'],
  "gilberte": ['G IH1 L B ER0 T'],
  "gilberti": ['JH IY0 L B EH1 R T IY0'],
  "gilbertina": ['JH IY0 L B ER0 T IY1 N AH0'],
  "gilbertine": ['JH IY0 L B ER0 T IY1 N IY0'],
  "gilberto": ['G IH0 L B EH1 R T OW2', 'G IH0 L B ER1 T OW0'],
  "gilbertson": ['G IH1 L B ER0 T S AH0 N'],
  "gilbey": ['G IH1 L B IY0'],
  "gilbo": ['JH IY1 L B OW0'],
  "gilboy": ['G IH1 L B OY0'],
  "gilbreath": ['G IH1 L B R EH2 TH'],
  "gilbreth": ['G IH1 L B R IH0 TH'],
  "gilbride": ['G IH1 L B R AY2 D'],
  "gilby": ['G IH1 L B IY0'],
  "gilchrest": ['G IH1 L K ER0 IH0 S T'],
  "gilchrist": ['G IH1 L K R IH0 S T'],
  "gilcrease": ['G IH0 L K R IY1 S'],
  "gilcrest": ['G IH1 L K ER0 IH0 S T', 'G IH1 L K R EH0 S T'],
  "gild": ['G IH1 L D'],
  "gilda": ['G IH1 L D AH0'],
  "gilday": ['G IH1 L D EY2'],
  "gildea": ['JH IY1 L D IY0 AH0'],
  "gilded": ['G IH1 L D IH0 D'],
  "gilden": ['G IH1 L D AH0 N'],
  "gilder": ['G IH1 L D ER0'],
  "gilder's": ['G IH1 L D ER0 Z'],
  "gildersleeve": ['G IH1 L D ER0 S L IY2 V'],
  "gilding": ['G IH1 L D IH0 NG'],
  "gildner": ['G IH1 L D N ER0'],
  "gildon": ['G IH1 L D AH0 N'],
  "gile": ['G AY1 L'],
  "gilead": ['G IH0 L IY1 D'],
  "giles": ['JH AY1 L Z'],
  "gilfillan": ['G IH2 L F IH1 L AH0 N'],
  "gilford": ['G IH1 L F ER0 D'],
  "gilger": ['G IH1 L G ER0'],
  "gilgit": ['G IH1 L JH IH0 T'],
  "gilgore": ['G IH2 L G AO1 R'],
  "gilham": ['G IH1 L HH AH0 M'],
  "gilhooly": ['G IH1 L HH UW0 L IY0'],
  "gilkerson": ['G IH1 L K ER0 S AH0 N'],
  "gilkes": ['G IH1 L K S'],
  "gilkeson": ['G IH1 L K IH0 S AH0 N'],
  "gilkey": ['G IH1 L K IY0'],
  "gilkison": ['G IH1 L K IH0 S AH0 N'],
  "gilkisons": ['G IH1 L K IH0 S AH0 N Z'],
  "gill": ['G IH1 L'],
  "gill's": ['G IH1 L Z'],
  "gillam": ['G IH1 L AH0 M'],
  "gillan": ['G IH1 L AH0 N'],
  "gilland": ['G IH1 L AH0 N D'],
  "gillard": ['ZH IH0 L AA1 R D'],
  "gillaspie": ['G IH1 L AH0 S P IY0'],
  "gillaspy": ['G IH1 L AH0 S P IY0'],
  "gille": ['G AY1 L'],
  "gillean": ['G IH1 L AH0 N'],
  "gilleland": ['G IH1 L IH0 L AE0 N D'],
  "gillem": ['G IH1 L IH0 M'],
  "gillen": ['G IH1 L AH0 N'],
  "gillen's": ['G IH1 L AH0 N Z'],
  "gillentine": ['G IH1 L AH0 N T AY2 N'],
  "giller": ['G IH1 L ER0'],
  "gilleran": ['G IH1 L ER0 AE0 N'],
  "gillers": ['G IH1 L ER0 Z'],
  "gilles": ['ZH IY1 L'],
  "gillespie": ['G AH0 L EH1 S P IY0'],
  "gillet": ['G IH1 L IH0 T'],
  "gillett": ['JH IH0 L IH1 T'],
  "gillette": ['JH IH0 L EH1 T'],
  "gillette's": ['JH IH0 L EH1 T S'],
  "gilley": ['G IH1 L IY0'],
  "gillham": ['G IH1 L HH AH0 M'],
  "gilliam": ['G IH1 L IY0 AH0 M'],
  "gillian": ['JH IH1 L IY0 AH0 N'],
  "gilliand": ['G IH1 L IY0 AH0 N D'],
  "gilliard": ['G IH1 L IY0 ER0 D'],
  "gilliardi": ['G IH0 L Y AA1 R D IY0'],
  "gilliatt": ['G IH1 L IY0 AE0 T'],
  "gillick": ['G IH1 L IH0 K'],
  "gillie": ['G IH1 L IY0'],
  "gillies": ['G IH1 L IY0 Z'],
  "gillig": ['G IH1 L IH0 G'],
  "gilligan": ['G IH1 L AH0 G AH0 N'],
  "gilligan's": ['G IH1 L AH0 G AH0 N Z'],
  "gillihan": ['G IH1 L AH0 HH AE0 N'],
  "gillikin": ['G IH1 L AH0 K AH0 N'],
  "gillilan": ['G IH1 L AH0 L AH0 N'],
  "gilliland": ['G IH1 L AH0 L AH0 N D'],
  "gillin": ['G IH1 L IH0 N'],
  "gillingham": ['G IH1 L IH0 NG HH AE2 M'],
  "gillings": ['G IH1 L IH0 NG Z'],
  "gillinov": ['G IH1 L IH0 N AA0 V'],
  "gillins": ['G IH1 L IH0 N Z'],
  "gillis": ['G IH1 L IH0 S'],
  "gillison": ['G IH1 L IH0 S AH0 N'],
  "gillispie": ['G AH0 L EH1 S P IY0'],
  "gillman": ['G IH1 L M AH0 N'],
  "gillmore": ['JH IY1 L M AO0 R'],
  "gillock": ['G IH1 L AH0 K'],
  "gillogly": ['G IH1 L AH0 G L IY0'],
  "gillon": ['G IH1 L AH0 N'],
  "gillooly": ['G IH1 L UW0 L IY0'],
  "gillooly's": ['G IH1 L UW0 L IY0 Z'],
  "gillott": ['G IH1 L AH0 T'],
  "gillotti": ['JH IY0 L OW1 T IY0'],
  "gills": ['G IH1 L Z'],
  "gillson": ['G IH1 L S AH0 N'],
  "gillum": ['G IH1 L AH0 M'],
  "gilly": ['G IH1 L IY0'],
  "gilman": ['G IH1 L M AH0 N'],
  "gilmartin": ['G IH0 L M AA1 R T IH0 N'],
  "gilmer": ['G IH1 L M ER0'],
  "gilmore": ['G IH1 L M AO0 R'],
  "gilmour": ['ZH IH0 L M UH1 R'],
  "gilpatrick": ['G IH1 L P AH0 T R IH0 K', 'G IH0 L P AE1 T R IH0 K'],
  "gilpin": ['G IH1 L P IH0 N'],
  "gilreath": ['G IH1 L R EH0 TH'],
  "gilroy": ['G IH1 L R OY2'],
  "gilsdorf": ['G IH1 L S D AO0 R F'],
  "gilson": ['G IH1 L S AH0 N'],
  "gilstrap": ['G IH1 L S T R AH0 P'],
  "gilt": ['G IH1 L T'],
  "giltner": ['G IH1 L T N ER0'],
  "gilts": ['G IH1 L T S'],
  "gilvin": ['G IH1 L V IH0 N'],
  "gilyard": ['ZH AH0 L Y AA1 R D'],
  "gimbel": ['G IH1 M B AH0 L'],
  "gimenez": ['JH IY0 M EY1 N EH0 Z'],
  "gimlet": ['G IH1 M L EH2 T'],
  "gimli": ['G IH1 M L IY0'],
  "gimlin": ['G IH1 M L IH0 N'],
  "gimme": ['G IH1 M IY0'],
  "gimmick": ['G IH1 M IH0 K'],
  "gimmickry": ['G IH1 M IH0 K R IY0'],
  "gimmicks": ['G IH1 M IH0 K S'],
  "gimmicky": ['G IH0 M IH1 K IY0'],
  "gimpel": ['G IH1 M P AH0 L'],
  "gin": ['JH IH1 N'],
  "gina": ['JH IY1 N AH0'],
  "gina's": ['JH IY1 N AH0 Z'],
  "ginandjar": ['JH IH0 N AE1 N JH ER0'],
  "ginas": ['JH IY1 N AH0 Z'],
  "ginder": ['G AY1 N D ER0'],
  "gindin": ['G IH1 N D IH0 N'],
  "gindlesperger": ['G IH1 N D AH0 L S P ER0 G ER0'],
  "gines": ['JH AY1 N Z'],
  "ginevra": ['JH IH0 N EH1 V R AH0'],
  "ging": ['JH IH1 NG'],
  "gingell": ['G IH1 NG G AH0 L'],
  "ginger": ['JH IH1 N JH ER0'],
  "gingerbread": ['JH IH1 N JH ER0 B R EH2 D'],
  "gingerich": ['G IH1 NG G ER0 IH0 K'],
  "gingerly": ['JH IH1 N JH ER0 L IY0'],
  "gingery": ['JH IH1 N JH ER0 IY0'],
  "gingham": ['G IH1 NG AH0 M'],
  "ginghams": ['G IH1 NG AH0 M Z'],
  "gingko": ['G IH1 NG K OW2'],
  "gingles": ['JH IH1 NG G AH0 L Z'],
  "gingold": ['JH IH1 N G OW2 L D', 'G IH1 N G OW2 L D'],
  "gingras": ['G IH1 NG G R AH0 Z'],
  "gingrich": ['G IH1 NG G R IH0 CH'],
  "gingrich's": ['G IH1 NG G R IH0 CH IH0 Z'],
  "gingriches": ['G IH1 NG G R IH0 CH IH0 Z'],
  "ginley": ['JH IH1 N L IY0'],
  "ginn": ['JH IH1 N'],
  "ginned": ['JH IH1 N D'],
  "ginnell": ['JH IH0 N EH1 L'],
  "ginnie": ['JH IH1 N IY0'],
  "ginning": ['JH IH1 N IH0 NG'],
  "ginny": ['JH IH1 N IY0'],
  "gino": ['JH IY1 N OW0'],
  "ginocchio": ['JH IY0 N OW1 K IY0 OW0'],
  "ginsberg": ['G IH1 N S B ER0 G'],
  "ginsburg": ['G IH1 N Z B ER0 G'],
  "ginsburg's": ['G IH1 N Z B ER0 G Z'],
  "ginseng": ['JH IH1 N S EH2 NG'],
  "ginsu": ['G IH1 N S UW0'],
  "ginsu's": ['G IH1 N S UW0 Z'],
  "gintel": ['JH IH1 N T EH2 L'],
  "ginter": ['G IH1 N T ER0'],
  "ginther": ['G IH1 N DH ER0'],
  "ginting": ['JH IH1 N T IH1 NG'],
  "ginty": ['JH IH1 N T IY0'],
  "gintz": ['G IH1 N T S'],
  "ginyard": ['JH IH1 N Y AA2 R D'],
  "ginza": ['G IH1 N Z AH0'],
  "ginzberg": ['G IH1 N Z B ER0 G'],
  "gioia": ['JH OW1 Y AH0'],
  "gionet": ['JH IY0 OW1 N EY0 T'],
  "gionfriddo": ['JH OW0 N F R IY1 D OW0'],
  "giordani": ['JH AO0 R D AA1 N IY0'],
  "giordano": ['JH AO0 R D AA1 N OW0'],
  "giorgi": ['JH AO1 R JH IY0'],
  "giorgia": ['JH AO1 R JH AH0'],
  "giorgio": ['JH AO1 R JH IY0 OW0'],
  "giovanelli": ['JH OW0 V AA0 N EH1 L IY0'],
  "giovanetti": ['JH OW0 V AA0 N EH1 T IY0'],
  "giovannetti": ['JH OW0 V AA0 N EH1 T IY0'],
  "giovanni": ['JH IY2 OW0 V AA1 N IY0', 'JH AH0 V AA1 N IY0'],
  "giovanni's": ['JH IY2 OW0 V AA1 N IY0 Z', 'JH AH0 V AA1 N IY0 Z'],
  "giovanniello": ['JH OW0 V AA2 N IY0 EH1 L OW0'],
  "giovannini": ['JH OW0 V AA0 N IY1 N IY0'],
  "giovannoni": ['JH OW0 V AA0 N OW1 N IY0'],
  "giovenco": ['JH OW0 V EH1 N K OW0'],
  "giovinazzo": ['JH OW0 V IY0 N AA1 Z OW0'],
  "gipe": ['JH AY1 P'],
  "gipp": ['JH IH1 P'],
  "gipper": ['G IH1 P ER0'],
  "gipple": ['JH IH1 P AH0 L'],
  "gipson": ['G IY1 P S AH0 N'],
  "giraffe": ['JH ER0 AE1 F'],
  "giraffes": ['JH ER0 AE1 F S'],
  "giraldo": ['JH IH0 R AA1 L D OW0'],
  "girard": ['JH ER0 AA1 R D'],
  "girardi": ['JH IH0 R AA1 R D IY0'],
  "girardin": ['ZH IH2 R AA1 R D EH0 N'],
  "girardot": ['ZH IH1 R AA2 R D OW0'],
  "giraud": ['ZH AY0 R OW1'],
  "gird": ['G ER1 D'],
  "girder": ['G ER1 D ER0'],
  "girders": ['G ER1 D ER0 Z'],
  "girding": ['G ER1 D IH0 NG'],
  "girdle": ['G ER1 D AH0 L'],
  "girdler": ['G ER1 D AH0 L ER0', 'G ER1 D L ER0'],
  "girdley": ['G ER1 D L IY0'],
  "girdner": ['G ER1 D N ER0'],
  "girds": ['G ER1 D Z'],
  "gire": ['G AY1 R'],
  "giren": ['G IH1 R AH0 N', 'JH IH1 R AH0 N'],
  "girgenti": ['JH IH0 R JH EH1 N T IY0'],
  "girgis": ['G ER1 G IH0 S'],
  "girija": ['G IH2 R IY1 JH AH0'],
  "girl": ['G ER1 L'],
  "girl's": ['G ER1 L Z'],
  "girlfriend": ['G ER1 L F R EH2 N D'],
  "girlfriend's": ['G ER1 L F R EH2 N D Z'],
  "girlfriends": ['G ER1 L F R EH2 N D Z'],
  "girlhood": ['G ER1 L HH UH2 D'],
  "girlie": ['G ER1 L IY0'],
  "girlish": ['G ER1 L IH0 SH'],
  "girlishly": ['G ER1 L IH0 SH L IY0'],
  "girls": ['G ER1 L Z'],
  "girls'": ['G ER1 L Z'],
  "girobank": ['G IH1 R OW0 B AE2 NG K'],
  "girod": ['ZH ER0 AA1 D'],
  "giroir": ['ZH AY0 R W AA1 R'],
  "girolamo": ['JH IH0 R OW0 L AA1 M OW0'],
  "giroldi": ['G IH0 R OW1 L D IY0'],
  "giroldi's": ['G IH0 R OW1 L D IY0 Z'],
  "giron": ['ZH IH1 R AH0 N'],
  "gironde": ['JH ER0 AO1 N D'],
  "girouard": ['ZH AY1 R UW0 ER0 D'],
  "giroux": ['ZH IH0 R UW1'],
  "girozentrale": ['G IH0 R OW1 Z AH0 N T R AA2 L'],
  "girsky": ['G ER1 S K IY0'],
  "girt": ['G ER1 T'],
  "girten": ['G ER1 T AH0 N'],
  "girth": ['G ER1 TH'],
  "girtman": ['G ER1 T M AH0 N'],
  "girton": ['G ER1 T AH0 N'],
  "girvan": ['G ER1 V AH0 N'],
  "girven": ['G ER1 V AH0 N'],
  "girvin": ['G ER1 V IH0 N'],
  "gisbert": ['JH IH1 S B ER0 T', 'G IH1 S B ER0 T'],
  "giscard": ['G IH0 S K AA1 R D', 'ZH IH0 S K AA1 R'],
  "gisclair": ['ZH IH0 S K L EH1 R'],
  "gisela": ['G IY1 Z AH0 L AH0'],
  "gisella": ['JH IH0 S EH1 L AH0'],
  "giselle": ['ZH IH0 S EH1 L'],
  "gisenyi": ['JH IH0 S EH1 N IY0', 'JH IH0 S EH1 N Y IY0'],
  "gish": ['JH IH1 SH', 'G IH1 SH'],
  "gisi": ['JH IY1 S IY0'],
  "gisler": ['G IH1 S AH0 L ER0', 'G IH1 S L ER0'],
  "gismondi": ['JH IY0 S M OW1 N D IY0'],
  "gissendanner": ['G IH1 S IH0 N D AH0 N ER0'],
  "gissi": ['G IH1 S IY0'],
  "gist": ['JH IH1 S T'],
  "gisu": ['JH IH1 S UW0'],
  "git": ['G IH1 T'],
  "gitana": ['JH IY0 T AE1 N AH0'],
  "gitano": ['G IH0 T AA1 N OW0'],
  "gitano's": ['G IH0 T AA1 N OW0 Z'],
  "gitchell": ['JH IH1 CH AH0 L'],
  "githa": ['JH IH1 DH AH0'],
  "githens": ['G IH1 TH AH0 N Z'],
  "github": ['G IH1 T HH AH0 B'],
  "gitlin": ['JH IH1 T L IH0 N'],
  "gittelman": ['G IH1 T AH0 L M AH0 N'],
  "gittens": ['G IH1 T AH0 N Z'],
  "gitter": ['G IH1 T ER0'],
  "gittings": ['JH IH1 T IH0 NG Z'],
  "gittins": ['JH IH1 T IH0 N Z'],
  "gittis": ['JH IH1 T AH0 S', 'G IH1 T IH0 S'],
  "gittleman": ['G IH1 T AH0 L M AH0 N'],
  "gittleman's": ['G IH1 T AH0 L M AH0 N Z'],
  "gittler": ['G IH1 T L ER0'],
  "gitto": ['JH IY1 T OW0'],
  "giudice": ['JH UW1 D IH0 S IY0'],
  "giudici": ['JH UW1 D IH0 CH IY0'],
  "giuffre": ['JH UW1 F R IY0'],
  "giuffre's": ['JH UW1 F R IY0 Z'],
  "giuffrida": ['JH UW1 F R IY0 D AH0'],
  "giuliani": ['JH UW2 L IY0 AA1 N IY0'],
  "giuliani's": ['JH UW2 L IY0 AA1 N IY0 Z'],
  "giuliano": ['JH UW2 L IY0 AA1 N OW0'],
  "giulio": ['JH UW1 L IY0 OW0'],
  "giunta": ['JH UW1 N T AH0'],
  "giurescu": ['JH UW2 R EH1 S K UW0'],
  "giuseppe": ['JH IH0 S EH1 P IY0'],
  "giusti": ['JH UW1 S T IY0'],
  "giusto": ['JH UW1 S T OW0'],
  "givan": ['G IH1 V AH0 N'],
  "give": ['G IH1 V'],
  "giveaway": ['G IH1 V AH0 W EY2'],
  "giveaways": ['G IH1 V AH0 W EY2 Z'],
  "giveback": ['G IH1 V B AE2 K'],
  "givebacks": ['G IH1 V B AE2 K S'],
  "given": ['G IH1 V AH0 N', 'G IH1 V IH0 N'],
  "givenchy": ['G IH0 V EH1 N CH IY0'],
  "givenness": ['G IH1 V AH0 N AH0 S'],
  "givens": ['G IH1 V AH0 N Z'],
  "givens's": ['G IH1 V AH0 N Z IH0 Z'],
  "giver": ['G IH1 V ER0'],
  "givers": ['G IH1 V ER0 Z'],
  "gives": ['G IH1 V Z'],
  "giveth": ['G IH1 V EH0 TH'],
  "givhan": ['G IH1 V HH AH0 N'],
  "givin'": ['G IH1 V IH0 N'],
  "giving": ['G IH1 V IH0 NG'],
  "giving-feast": ['G IH1 V IH0 NG F IY2 S T'],
  "givins": ['G IH1 V IH0 N Z'],
  "givler": ['G IH1 V L ER0'],
  "giza": ['G IH1 Z AH0', 'G IY1 Z AH0'],
  "gizbert": ['G IH1 Z B ER0 T'],
  "gizbert's": ['G IH1 Z B ER0 T S'],
  "gizmo": ['G IH1 Z M OW2'],
  "gizmos": ['G IH1 Z M OW0 Z'],
  "gizzard": ['G IH1 Z ER0 D'],
  "gizzi": ['JH IY1 T S IY0'],
  "gjelten": ['JH EH1 L T AH0 N'],
  "gjelten's": ['JH EH1 L T AH0 N Z'],
  "gjerde": ['JH ER1 D'],
  "glaab": ['G L AA1 B'],
  "glab": ['G L AE1 B'],
  "glace": ['G L EY1 S'],
  "glacial": ['G L EY1 SH AH0 L'],
  "glaciate": ['G L EY1 SH IY0 EY2 T', 'G L EY1 S IY0 EY2 T'],
  "glaciated": ['G L EY1 SH IY0 EY2 T AH0 D', 'G L EY1 S IY0 EY2 T AH0 D'],
  "glaciation": ['G L EY2 SH IY0 EY1 SH AH0 N'],
  "glacier": ['G L EY1 SH ER0'],
  "glacier's": ['G L EY1 SH ER0 Z'],
  "glaciers": ['G L EY1 SH ER0 Z'],
  "glacis": ['G L EY1 S AH0 S'],
  "glacken": ['G L AE1 K AH0 N'],
  "glackin": ['G L AE1 K IH0 N'],
  "glad": ['G L AE1 D'],
  "gladd": ['G L AE1 D'],
  "gladden": ['G L AE1 D AH0 N'],
  "gladding": ['G L AE1 D IH0 NG'],
  "gladdock": ['G L AE1 D AH0 K'],
  "glade": ['G L EY1 D'],
  "glades": ['G L EY1 D Z'],
  "gladfelter": ['G L AE1 D F EH2 L T ER0'],
  "gladhill": ['G L AE1 D HH IH2 L'],
  "gladiator": ['G L AE1 D IY0 EY2 T ER0'],
  "gladiators": ['G L AE1 D IY0 EY2 T ER0 Z'],
  "gladieux": ['G L AE1 D IY0 OW0'],
  "gladiolus": ['G L AE2 D IY0 OW1 L AH0 S'],
  "gladis": ['G L AE1 D IH0 S'],
  "gladish": ['G L AE1 D IH0 SH'],
  "gladje": ['G L AE1 D JH IY2'],
  "gladly": ['G L AE1 D L IY0'],
  "gladman": ['G L AE1 D M AH0 N'],
  "gladney": ['G L AE1 D N IY0'],
  "gladson": ['G L AE1 D S AH0 N'],
  "gladstein": ['G L AE1 D S T AY2 N', 'G L AE1 D S T IY2 N'],
  "gladstone": ['G L AE1 D S T OW2 N'],
  "gladstones": ['G L AE1 D S T OW2 N Z'],
  "gladu": ['G L EY1 D UW0'],
  "gladwell": ['G L AE1 D W EH2 L'],
  "gladwin": ['G L AE1 D W IH0 N'],
  "gladys": ['G L AE1 D IH0 S'],
  "gladys'": ['G L AE1 D IH0 S'],
  "glaeser": ['G L EY1 Z ER0'],
  "glahn": ['G L AE1 N'],
  "glam": ['G L AE1 M'],
  "glamor": ['G L AE1 M ER0'],
  "glamorize": ['G L AE1 M ER0 AY2 Z'],
  "glamorized": ['G L AE1 M ER0 AY0 Z D'],
  "glamorizing": ['G L AE1 M ER0 AY0 Z IH0 NG'],
  "glamorous": ['G L AE1 M ER0 AH0 S'],
  "glamour": ['G L AE1 M ER0'],
  "glance": ['G L AE1 N S'],
  "glanced": ['G L AE1 N S T'],
  "glances": ['G L AE1 N S IH0 Z'],
  "glancing": ['G L AE1 N S IH0 NG'],
  "glancy": ['G L AE1 N S IY0'],
  "gland": ['G L AE1 N D'],
  "glander": ['G L AE1 N D ER0'],
  "glandon": ['G L AE1 N D AH0 N'],
  "glands": ['G L AE1 N D Z'],
  "glandular": ['G L AE1 N JH AH0 L ER0'],
  "glanton": ['G L AE1 N T AH0 N'],
  "glantz": ['G L AE1 N T S'],
  "glanville": ['G L AE1 N V IH2 L'],
  "glanz": ['G L AE1 N Z'],
  "glanzer": ['G L AE1 N Z ER0'],
  "glanzman": ['G L AE1 N Z M AH0 N'],
  "glare": ['G L EH1 R'],
  "glared": ['G L EH1 R D'],
  "glares": ['G L EH1 R Z'],
  "glaring": ['G L EH1 R IH0 NG'],
  "glaris": ['G L EH1 R IH0 S'],
  "glas": ['G L AE1 S'],
  "glasby": ['G L AE1 S B IY0'],
  "glasco": ['G L AA1 S K OW0'],
  "glascock": ['G L AE1 S K AH0 K'],
  "glascoe": ['G L AE1 S K OW0'],
  "glaser": ['G L EY1 Z ER0'],
  "glasgow": ['G L AE1 S K OW2', 'G L AE1 S G OW2'],
  "glasheen": ['G L AH0 SH IY1 N'],
  "glashow": ['G L AE1 SH AW0'],
  "glasner": ['G L AE1 S N ER0'],
  "glasnost": ['G L AE1 S N AA0 S T', 'G L AO1 S T N OW2 S T', 'G L AO1 S N OW2 S T'],
  "glasow": ['G L EY1 Z OW0'],
  "glasper": ['G L AE1 S P ER0'],
  "glaspie": ['G L AE1 S P IY0'],
  "glaspy": ['G L AE1 S P IY0'],
  "glass": ['G L AE1 S'],
  "glass's": ['G L AE1 S IH0 Z'],
  "glassberg": ['G L AE1 S B ER0 G'],
  "glassblower": ['G L AE1 S B L OW2 ER0'],
  "glassblowers": ['G L AE1 S B L OW2 ER0 Z'],
  "glassblowing": ['G L AE1 S B L OW2 IH0 NG'],
  "glassburn": ['G L AE1 S B ER2 N'],
  "glassco": ['G L AE1 S K OW0'],
  "glasscock": ['G L AE1 S K AA2 K'],
  "glassed": ['G L AE1 S T'],
  "glasser": ['G L AE1 S ER0'],
  "glasses": ['G L AE1 S AH0 Z', 'G L AE1 S IH0 Z'],
  "glassey": ['G L AE1 S IY0'],
  "glassford": ['G L AE1 S F AO0 R D'],
  "glasslike": ['G L AE1 S L AY2 K'],
  "glassmaker": ['G L AE1 S M EY2 K ER0'],
  "glassmaking": ['G L AE1 S M EY2 K IH0 NG'],
  "glassman": ['G L AE1 S M AE2 N', 'G L AE1 S M AH0 N'],
  "glassmeyer": ['G L AE1 S M AY0 ER0'],
  "glassner": ['G L AE1 S N ER0'],
  "glasson": ['G L AE1 S AH0 N'],
  "glassware": ['G L AE1 S W EH2 R'],
  "glassy": ['G L AE1 S IY0'],
  "glastetter": ['G L AE1 S T IH0 T ER0'],
  "glatfelter": ['G L AE1 T F IH0 L T ER0'],
  "glatt": ['G L AE1 T'],
  "glatz": ['G L AE1 T S'],
  "glatzer": ['G L EY1 T Z ER0'],
  "glaub": ['G L AO1 B'],
  "glauber": ['G L AW1 B ER0'],
  "glaucoma": ['G L AO0 K OW1 M AH0'],
  "glaude": ['G L AO1 D'],
  "glaus": ['G L AO1 Z'],
  "glauser": ['G L AW1 S ER0'],
  "glavan": ['G L EY1 V AH0 N'],
  "glavin": ['G L AE1 V IH0 N'],
  "glavine": ['G L AE0 V IH1 N', 'G L AE0 V IY1 N'],
  "glawe": ['G L AO1'],
  "glaxo": ['G L AE1 K S OW0'],
  "glaxo's": ['G L AE1 K S OW0 Z'],
  "glaza": ['G L AA1 Z AH0'],
  "glaze": ['G L EY1 Z'],
  "glazebrook": ['G L EY1 Z B R UH2 K'],
  "glazed": ['G L EY1 Z D'],
  "glazener": ['G L AE1 Z IY0 N ER0'],
  "glazer": ['G L EY1 Z ER0'],
  "glazes": ['G L EY1 Z AH0 Z', 'G L EY1 Z IH0 Z'],
  "glazier": ['G L EY1 Z IY0 ER0'],
  "glazing": ['G L EY1 Z IH0 NG'],
  "glazner": ['G L AE1 Z N ER0'],
  "glazunov": ['G L AE1 Z UW0 N AA0 V'],
  "gleacher": ['G L IY1 CH ER0'],
  "gleam": ['G L IY1 M'],
  "gleamed": ['G L IY1 M D'],
  "gleaming": ['G L IY1 M IH0 NG'],
  "gleams": ['G L IY1 M Z'],
  "glean": ['G L IY1 N'],
  "gleaned": ['G L IY1 N D'],
  "gleason": ['G L IY1 S AH0 N'],
  "gleason's": ['G L IY1 S AH0 N Z'],
  "gleaton": ['G L IY1 T AH0 N'],
  "gleave": ['G L IY1 V'],
  "gleaves": ['G L IY1 V Z'],
  "gleba": ['G L IY1 B AH0'],
  "gleckler": ['G L EH1 K L ER0'],
  "gleda": ['G L EY1 D AH0'],
  "gledhill": ['G L EH1 D HH IH2 L'],
  "glee": ['G L IY1'],
  "gleeful": ['G L IY1 F AH0 L'],
  "gleefully": ['G L IY1 F AH0 L IY0'],
  "gleeson": ['G L IY1 Z AH0 N'],
  "gleghorn": ['G L EH1 G HH ER0 N'],
  "gleich": ['G L AY1 K'],
  "gleichauf": ['G L AY1 K AO0 F'],
  "gleim": ['G L IY1 M'],
  "gleisner": ['G L AY1 S N ER0'],
  "glemp": ['G L EH1 M P'],
  "glen": ['G L EH1 N'],
  "glenayre": ['G L EH2 N EH1 R', 'G L EH2 N AY1 R'],
  "glenbrook": ['G L EH1 N B R UH2 K'],
  "glenda": ['G L EH1 N D AH0'],
  "glendale": ['G L EH1 N D EY2 L'],
  "glendening": ['G L EH1 N D AH0 N IH0 NG'],
  "glendenning": ['G L EH1 N D IH0 N IH0 NG'],
  "glendinning": ['G L EH1 N D IH0 N IH0 NG'],
  "glendon": ['G L EH1 N D OW0 N'],
  "glenfed": ['G L EH1 N F EH2 D'],
  "glenfed's": ['G L EH1 N F EH2 D Z'],
  "glengarry": ['G L EH2 N G EH1 R IY0'],
  "glenham": ['G L EH1 N HH AH0 M', 'G L EH1 N AH0 M'],
  "glenmore": ['G L EH1 N M AO2 R'],
  "glenn": ['G L EH1 N'],
  "glenn's": ['G L EH1 N Z'],
  "glenna": ['G L EH1 N AH0'],
  "glennie": ['G L EH1 N IY0'],
  "glennon": ['G L EH1 N AH0 N'],
  "glenny": ['G L EH1 N IY0'],
  "glenora": ['G L AH0 N AO1 R AH0'],
  "glens": ['G L EH1 N Z'],
  "glenview": ['G L EH1 N V Y UW2'],
  "glenville": ['G L EH1 N V IH0 L'],
  "glenwood": ['G L EH1 N W UH2 D'],
  "glenwood's": ['G L EH1 N W UH2 D Z'],
  "gless": ['G L EH1 S'],
  "glessner": ['G L EH1 S N ER0'],
  "glew": ['G L UW1'],
  "glib": ['G L IH1 B'],
  "glibly": ['G L IH1 B L IY0'],
  "glick": ['G L IH1 K'],
  "glickenhaus": ['G L IH1 K AH0 N HH AW2 S'],
  "glickman": ['G L IH1 K M AH0 N'],
  "glickman's": ['G L IH1 K S M AH0 N Z'],
  "glicksman": ['G L IH1 K S M AH0 N'],
  "glickstein": ['G L IH1 K S T IY2 N', 'G L IH1 K S T AY2 N'],
  "glidden": ['G L IH1 D AH0 N'],
  "glide": ['G L AY1 D'],
  "glided": ['G L AY1 D IH0 D'],
  "glider": ['G L AY1 D ER0'],
  "gliders": ['G L AY1 D ER0 Z'],
  "glides": ['G L AY1 D Z'],
  "glidewell": ['G L AY1 D W EH2 L'],
  "gliding": ['G L AY1 D IH0 NG'],
  "glimcher": ['G L IH1 M CH ER0'],
  "glimmer": ['G L IH1 M ER0'],
  "glimmering": ['G L IH1 M ER0 IH0 NG'],
  "glimmers": ['G L IH1 M ER0 Z'],
  "glimpse": ['G L IH1 M P S'],
  "glimpsed": ['G L IH1 M P S T'],
  "glimpses": ['G L IH1 M P S IH0 Z'],
  "glines": ['G L AY1 N Z'],
  "glinka": ['G L IH1 NG K AH0'],
  "glinski": ['G L IH1 N S K IY0'],
  "glint": ['G L IH1 N T'],
  "glinting": ['G L IH1 N T IH0 NG'],
  "glissandi": ['G L AH0 S AA1 N D IY0'],
  "glisson": ['G L IH1 S AH0 N'],
  "glisten": ['G L IH1 S AH0 N'],
  "glistened": ['G L IH1 S AH0 N D'],
  "glistening": ['G L IH1 S AH0 N IH0 NG', 'G L IH1 S N IH0 NG'],
  "glitch": ['G L IH1 CH'],
  "glitches": ['G L IH1 CH IH0 Z'],
  "glitter": ['G L IH1 T ER0'],
  "glittered": ['G L IH1 T ER0 D'],
  "glittering": ['G L IH1 T ER0 IH0 NG'],
  "glitters": ['G L IH1 T ER0 Z'],
  "glittery": ['G L IH1 T ER0 IY0'],
  "glitz": ['G L IH1 T S'],
  "glitzy": ['G L IH1 T S IY0'],
  "glo": ['G L OW1'],
  "gloam": ['G L OW1 M'],
  "gloaming": ['G L OW1 M IH0 NG'],
  "gloat": ['G L OW1 T'],
  "gloated": ['G L OW1 T IH0 D'],
  "gloating": ['G L OW1 T IH0 NG'],
  "gloats": ['G L OW1 T S'],
  "glob": ['G L AA1 B'],
  "global": ['G L OW1 B AH0 L'],
  "global's": ['G L OW1 B AH0 L Z'],
  "globalization": ['G L OW2 B AH0 L IH0 Z EY1 SH AH0 N'],
  "globalize": ['G L OW1 B AH0 L AY2 Z'],
  "globalized": ['G L OW1 B AH0 L AY2 Z D'],
  "globally": ['G L OW1 B AH0 L IY0'],
  "globalstar": ['G L OW1 B AH0 L S T AA2 R'],
  "globcom": ['G L AA1 B K AA0 M'],
  "globe": ['G L OW1 B'],
  "globe's": ['G L OW1 B Z'],
  "globes": ['G L OW1 B Z'],
  "globetrotter": ['G L OW1 B T R AO0 T ER0'],
  "globetrotters": ['G L OW1 B T R AO0 T ER0 Z'],
  "globex": ['G L OW1 B AH0 K S'],
  "globo": ['G L OW1 B OW0'],
  "globs": ['G L AA1 B Z'],
  "globular": ['G L AA1 B Y AH0 L ER0'],
  "globulin": ['G L AA1 B Y AH0 L IH0 N'],
  "globulins": ['G L AA1 B Y AH0 L IH0 N Z'],
  "globus": ['G L OW1 B AH0 S'],
  "glocester": ['G L AW1 S T ER0'],
  "glock": ['G L AA1 K'],
  "glockenspiel": ['G L AA1 K AH0 N S P IY2 L'],
  "glockner": ['G L AA1 K N ER0'],
  "glod": ['G L AA1 D'],
  "glodowski": ['G L AH0 D AO1 F S K IY0'],
  "gloe": ['G L OW1'],
  "gloeckner": ['G L OW1 K N ER0'],
  "gloede": ['G L OW1 D'],
  "glogowski": ['G L AH0 G AO1 F S K IY0'],
  "glomar": ['G L OW1 M AA0 R'],
  "glomb": ['G L AA1 M'],
  "glomski": ['G L AA1 M S K IY2'],
  "glonass": ['G L AA1 N AH0 S'],
  "gloom": ['G L UW1 M'],
  "gloomier": ['G L UW1 M IY0 ER0'],
  "gloomily": ['G L UW1 M AH0 L IY0'],
  "gloomy": ['G L UW1 M IY0'],
  "gloor": ['G L UH1 R'],
  "glop": ['G L AA1 P'],
  "gloppy": ['G L AA1 P IY0'],
  "glor": ['G L AO1 R'],
  "glore": ['G L AO1 R'],
  "glori": ['G L AO1 R IY0'],
  "gloria": ['G L AO1 R IY0 AH0'],
  "gloriana": ['G L AO2 R IY0 AE1 N AH0'],
  "gloriane": ['G L AO2 R IY0 AE1 N'],
  "glories": ['G L AO1 R IY0 Z'],
  "glorification": ['G L AO2 R AH0 F IH0 K EY1 SH AH0 N', 'G L AO2 R IH0 F IH0 K EY1 SH AH0 N'],
  "glorified": ['G L AO1 R AH0 F AY2 D'],
  "glorifies": ['G L AO1 R AH0 F AY2 Z'],
  "glorify": ['G L AO1 R AH0 F AY2'],
  "glorifying": ['G L AO1 R AH0 F AY2 IH0 NG'],
  "glorioso": ['G L AO0 R IY0 OW1 S OW0'],
  "glorious": ['G L AO1 R IY0 AH0 S'],
  "gloriously": ['G L AO1 R IY0 AH0 S L IY0'],
  "glory": ['G L AO1 R IY0'],
  "gloss": ['G L AO1 S'],
  "glossary": ['G L AO1 S ER0 IY0'],
  "glossed": ['G L AO1 S T'],
  "glosser": ['G L AO1 S ER0'],
  "glosses": ['G L AO1 S IH0 Z'],
  "glossier": ['G L AO1 S IY0 ER0'],
  "glossman": ['G L AO1 S M AH0 N'],
  "glosson": ['G L AA1 S AH0 N'],
  "glossy": ['G L AO1 S IY0'],
  "gloster": ['G L AA1 S T ER0'],
  "glotfelty": ['G L AA1 T F IH0 L T IY0'],
  "glottal": ['G L AA1 T AH0 L'],
  "glottis": ['G L AA1 T AH0 S'],
  "glotzbach": ['G L AA1 T S B AA0 K'],
  "gloucester": ['G L AO1 S T ER0'],
  "glove": ['G L AH1 V'],
  "gloved": ['G L AH1 V D'],
  "glover": ['G L AH1 V ER0'],
  "gloves": ['G L AH1 V Z'],
  "glow": ['G L OW1'],
  "glowacki": ['G L AW0 AA1 T S K IY0'],
  "glowed": ['G L OW1 D'],
  "glower": ['G L AW1 ER0'],
  "glowered": ['G L AW1 ER0 D'],
  "glowering": ['G L AW1 ER0 IH0 NG'],
  "glowing": ['G L OW1 IH0 NG'],
  "glowingly": ['G L OW1 IH0 NG L IY0'],
  "glows": ['G L OW1 Z'],
  "gloyd": ['G L OY1 D'],
  "gluck": ['G L AH1 K'],
  "gluck's": ['G L AH1 K S'],
  "gluckman": ['G L AH1 K M AH0 N'],
  "glucksman": ['G L AH1 K S M AH0 N'],
  "glucosamate": ['G L UW0 K AA1 S AH0 M EY2 T'],
  "glucosamine": ['G L UW0 K AA1 S AH0 M IY2 N'],
  "glucose": ['G L UW1 K OW2 S'],
  "glucoside": ['G L UW1 K AH0 S AY2 D'],
  "glucosides": ['G L UW1 K AH0 S AY2 D Z'],
  "glue": ['G L UW1'],
  "glueck": ['G L UW1 K'],
  "glued": ['G L UW1 D'],
  "glues": ['G L UW1 Z'],
  "glum": ['G L AH1 M'],
  "glumly": ['G L AH1 M L IY0'],
  "glummer": ['G L AH1 M ER0'],
  "glunt": ['G L AH1 N T'],
  "gluntz": ['G L AH1 N T S'],
  "glunz": ['G L AH1 N Z'],
  "gluskin": ['G L AH1 S K IH0 N'],
  "glut": ['G L AH1 T'],
  "glutamate": ['G L UW1 T AH0 M EY2 T'],
  "glutamic": ['G L UW0 T AE1 M IH0 K'],
  "glutaric": ['G L UW0 T AE1 R IH0 K'],
  "gluten": ['G L UW1 T AH0 N'],
  "gluth": ['G L UW1 TH'],
  "gluts": ['G L AH1 T S'],
  "glutted": ['G L AH1 T IH0 D'],
  "gluttonous": ['G L AH1 T AH0 N AH0 S'],
  "gluttons": ['G L AH1 T AH0 N Z'],
  "gluttony": ['G L AH1 T AH0 N IY0'],
  "glycel": ['G L IH1 S AH0 L'],
  "glycemic": ['G L AY0 S EH1 M IH0 K'],
  "glycerol": ['G L IH1 S ER0 OW2 L'],
  "glycine": ['G L AY1 S IY2 N', 'G L AY1 S AH0 N'],
  "glycogen": ['G L AY1 K AH0 JH IH0 N'],
  "glycol": ['G L AY1 K AO2 L', 'G L AY1 K OW2 L'],
  "glycolic": ['G L AY0 K AO1 L AH0 K'],
  "glycomed": ['G L AY1 K OW2 M D', 'G L AY1 K OW0 M EH2 D'],
  "glycoside": ['G L AY1 K AH0 S AY2 D'],
  "glymph": ['G L IH1 M F'],
  "glyn": ['G L IH1 N'],
  "glyndebourne": ['G L IH1 N D B AO2 R N'],
  "glynis": ['G L IH1 N IH0 S'],
  "glynn": ['G L IH1 N'],
  "glynnie": ['G L IH1 N IY0'],
  "glynnis": ['G L IH1 N IH0 S'],
  "gm": ['JH IY2 EH1 M'],
  "gmail": ['JH IY1 M EY2 L'],
  "gmbh": ['G AH0 M', 'JH IY1 EH1 M B IY1 EY1 CH'],
  "gnagey": ['N AE1 JH IY0'],
  "gnaizda": ['N EY1 Z D AH0'],
  "gnann": ['N AE1 N'],
  "gnarl": ['N AA1 R L'],
  "gnarle": ['N AA1 R L'],
  "gnarled": ['N AA1 R L D'],
  "gnarling": ['N AA1 R L IH0 NG'],
  "gnarls": ['N AA1 R L Z'],
  "gnarly": ['N AA1 R L IY0'],
  "gnash": ['N AE1 SH'],
  "gnashing": ['N AE1 SH IH0 NG'],
  "gnat": ['N AE1 T'],
  "gnatcatcher": ['N AE1 T K AE2 CH ER0'],
  "gnatcatchers": ['N AE1 T K AE2 CH ER0 Z'],
  "gnats": ['N AE1 T S'],
  "gnau": ['N AW1'],
  "gnaw": ['N AO1'],
  "gnawed": ['N AO1 D'],
  "gnawing": ['N AO1 IH0 NG'],
  "gnaws": ['N AO1 Z'],
  "gnc": ['JH IY1 EH1 N S IY1'],
  "gneiss": ['N AY1 S'],
  "gnocchi": ['N OW1 K IY0'],
  "gnome": ['N OW1 M'],
  "gnomes": ['N OW1 M Z'],
  "gnomic": ['N OW1 M IH0 K'],
  "gnomonic": ['N OW0 M AA1 N IH0 K'],
  "gnosticism": ['N AA1 S T IH0 S IH2 Z AH0 M'],
  "gnp": ['JH IY2 EH2 N P IY1'],
  "gnu": ['N UW1'],
  "go": ['G OW1'],
  "go-cart": ['G OW1 K AA2 R T'],
  "goa": ['G OW1 AH0'],
  "goad": ['G OW1 D'],
  "goaded": ['G OW1 D IH0 D'],
  "goading": ['G OW1 D IH0 NG'],
  "goal": ['G OW1 L'],
  "goalie": ['G OW1 L IY0'],
  "goalkeeper": ['G OW1 L K IY2 P ER0'],
  "goalpost": ['G OW1 L P OW2 S T'],
  "goals": ['G OW1 L Z'],
  "goan": ['G OW1 N'],
  "goans": ['G OW1 N Z'],
  "goar": ['G AO1 R'],
  "goard": ['G AO1 R D'],
  "goat": ['G OW1 T'],
  "goat's": ['G OW1 T S'],
  "goatee": ['G OW1 T IY1'],
  "goates": ['G OW1 EY0 T S'],
  "goatley": ['G OW1 T L IY0'],
  "goats": ['G OW1 T S'],
  "gob": ['G AA1 B'],
  "gobain": ['G OW1 B IH0 N'],
  "gobble": ['G AA1 B AH0 L'],
  "gobbled": ['G AA1 B AH0 L D'],
  "gobbledygook": ['G AA2 B AH0 L D IY0 G UH1 K'],
  "gobbler": ['G AA1 B AH0 L ER0', 'G AA1 B L ER0'],
  "gobblers": ['G AA1 B AH0 L ER0 Z', 'G AA1 B L ER0 Z'],
  "gobbles": ['G AA1 B AH0 L Z'],
  "gobbling": ['G AA1 B AH0 L IH0 NG', 'G AA1 B L IH0 NG'],
  "gobel": ['G OW1 B AH0 L'],
  "goben": ['G AA1 B AH0 N'],
  "gober": ['G OW1 B ER0'],
  "goberstein": ['G OW1 B ER0 S T IY2 N', 'G OW1 B ER0 S T AY2 N'],
  "gobert": ['G AA1 B ER0 T'],
  "gobi": ['G OW1 B IY0'],
  "gobie": ['G OW1 B IY0'],
  "gobin": ['G OW1 B IH0 N'],
  "gobinot": ['G OW1 B IH0 N AA0 T'],
  "gobioff": ['G OW1 B IY0 AO0 F'],
  "goble": ['G OW1 B AH0 L'],
  "goblet": ['G AA1 B L AH0 T'],
  "goblin": ['G AA1 B L IH0 N'],
  "goblins": ['G AA1 B L IH0 N Z'],
  "goblirsch": ['G AA1 B L ER0 SH'],
  "gobs": ['G AA1 B Z'],
  "gochenour": ['G AA1 SH IH0 N UH0 R'],
  "gochnauer": ['G AA1 K N AW0 ER0'],
  "gochnour": ['G AH0 SH N UH1 R'],
  "gocke": ['G AA1 K'],
  "gockel": ['G AA1 K AH0 L'],
  "gockley": ['G AA1 K L IY0'],
  "god": ['G AA1 D'],
  "god's": ['G AA1 D Z'],
  "goda": ['G OW1 D AH0'],
  "godaddy": ['G OW1 D AE2 D IY0'],
  "godammit": ['G AA2 D AE1 M IH0 T'],
  "godar": ['G AH0 D AA1 R'],
  "godard": ['G AH0 D AA1 R D'],
  "godart": ['G AA1 D AA0 R T'],
  "godbee": ['G AA1 D B IY2'],
  "godbey": ['G AA1 D B IY0'],
  "godbold": ['G AA1 D B OW2 L D'],
  "godbolt": ['G AA1 D B OW2 L T'],
  "godbout": ['G AA1 D B AW2 T'],
  "godby": ['G AA1 D B IY0'],
  "goddam": ['G AA1 D D AE2 M'],
  "goddammit": ['G AA1 D D AE2 M IH0 T'],
  "goddamn": ['G AA1 D D AE2 M'],
  "goddamned": ['G AA1 D D AE2 M D'],
  "goddard": ['G AA1 D ER0 D'],
  "goddart": ['G AA1 D AA0 R T'],
  "goddeau": ['G AA1 D OW2'],
  "godden": ['G AA1 D AH0 N'],
  "goddess": ['G AA1 D AH0 S'],
  "goddesses": ['G AA1 D AH0 S IH0 Z'],
  "godding": ['G AA1 D IH0 NG'],
  "godek": ['G OW1 D IH0 K'],
  "godette": ['G AH0 D EH1 T'],
  "godfather": ['G AA1 D F AA2 DH ER0'],
  "godfather's": ['G AA1 D F AA2 DH ER0 Z'],
  "godfathers": ['G AA1 D F AA2 DH ER0 Z'],
  "godfrey": ['G AA1 D F R IY0'],
  "godfrey's": ['G AA1 D F R IY0 Z'],
  "godhead": ['G AA1 D HH EH2 D'],
  "godin": ['G OW1 D IH0 N'],
  "godina": ['G OW0 D IY1 N AH0'],
  "godine": ['G OW0 D IY1 N'],
  "godines": ['G AA1 D AY0 N Z'],
  "godinez": ['G OW0 D IY1 N EH0 Z'],
  "goding": ['G OW1 D IH0 NG'],
  "godino": ['G OW0 D IY1 N OW0'],
  "godiva": ['G AH0 D AY1 V AH0'],
  "godkin": ['G AA1 D K IH0 N'],
  "godleski": ['G AH0 D L EH1 S K IY0'],
  "godless": ['G AA1 D L AH0 S'],
  "godlewski": ['G AH0 D L EH1 F S K IY0'],
  "godley": ['G AA1 D L IY0'],
  "godlove": ['G AA1 D L AH2 V'],
  "godly": ['G AA1 D L IY0'],
  "godman": ['G AA1 D M AH0 N'],
  "godmother": ['G AA1 D M AH2 DH ER0'],
  "godmothers": ['G AA1 D M AH2 DH ER0 Z'],
  "godot": ['G AH0 D OW1'],
  "godot's": ['G AH0 D OW1 Z'],
  "godown": ['G OW1 D AW2 N'],
  "godoy": ['G AA1 D OY0'],
  "godrej": ['G AA1 D R EY0'],
  "gods": ['G AA1 D Z'],
  "godsell": ['G AA1 D S AH0 L'],
  "godsend": ['G AA1 D S EH2 N D'],
  "godsends": ['G AA1 D S EH2 N D Z'],
  "godsey": ['G AA1 D Z IY0'],
  "godshalk": ['G AA1 D SH AH0 K'],
  "godshall": ['G AA1 D SH AH0 L'],
  "godspeed": ['G AA1 D S P IY0 D'],
  "godspell": ['G AA1 D S P EH2 L'],
  "godunov": ['G OW1 D UW0 N AA0 V'],
  "godwin": ['G AA1 D W IH0 N'],
  "godzilla": ['G AA2 D Z IH1 L AH0'],
  "goe": ['G OW1'],
  "goeas": ['G OW1 IY0 AH0 Z', 'G OW1 Y AH0 Z'],
  "goebbels": ['G OW1 B AH0 L Z'],
  "goebel": ['G OW1 B AH0 L'],
  "goecke": ['G OW1 K'],
  "goedde": ['G OW1 D'],
  "goede": ['G OW1 D'],
  "goedecke": ['G OW1 D IH0 K'],
  "goeden": ['G OW1 D AH0 N'],
  "goedert": ['G OW1 D ER0 T'],
  "goedken": ['G OW1 D K AH0 N'],
  "goeglein": ['G OW1 G L AY2 N'],
  "goehner": ['G OW1 N ER0'],
  "goehring": ['G AO1 R IH0 NG'],
  "goeke": ['G OW1 K'],
  "goeken": ['G OW1 K AH0 N'],
  "goeller": ['G OW1 L ER0'],
  "goellner": ['G OW1 L N ER0'],
  "goelz": ['G OW1 L Z'],
  "goelzer": ['G OW1 L Z ER0'],
  "goeman": ['G OW1 M AH0 N'],
  "goemon": ['G OW1 EY2 M AH0 N'],
  "goen": ['G OW1 N'],
  "goens": ['G OW1 N Z'],
  "goepfert": ['G OW1 P F ER0 T'],
  "goer": ['G OW1 ER0'],
  "goergen": ['G AO1 R G AH0 N'],
  "goering": ['G OW1 ER0 IH0 NG'],
  "goerke": ['G AO1 R K'],
  "goerlitz": ['G AO1 R L IH0 T S'],
  "goerner": ['G AO1 R N ER0'],
  "goers": ['G OW1 ER0 Z'],
  "goertz": ['G AO1 R T S'],
  "goertzen": ['G AO1 R T Z AH0 N'],
  "goes": ['G OW1 Z'],
  "goeser": ['G OW1 Z ER0'],
  "goetabanken": ['G OW2 T AH0 B AE1 NG K AH0 N'],
  "goethals": ['G OW1 TH AH0 L Z'],
  "goethe": ['G OW1 TH'],
  "goetsch": ['G OW1 CH'],
  "goette": ['G EH1 T'],
  "goettel": ['G OW1 T AH0 L'],
  "goetting": ['G OW1 T IH0 NG'],
  "goettl": ['G OW1 T AH0 L'],
  "goettsch": ['G OW1 T CH'],
  "goetz": ['G EH1 T S'],
  "goetze": ['G OW1 T Z'],
  "goetzinger": ['G OW1 T Z IH0 NG ER0'],
  "goetzke": ['G OW1 T S K IY0'],
  "goetzman": ['G OW1 T S M AH0 N'],
  "goewey": ['G AA1 UW0 IY0'],
  "goff": ['G AO1 F'],
  "goffin": ['G AA1 F IH0 N'],
  "goffinet": ['G AA1 F IH0 N IH0 T'],
  "goffman": ['G AA1 F M AH0 N'],
  "goffman's": ['G AA1 F M AH0 N Z'],
  "goffney": ['G AA1 F N IY0'],
  "goffredo": ['G OW0 F R EY1 D OW0'],
  "goforth": ['G OW1 F AO1 R TH'],
  "goga": ['G OW1 G AH0'],
  "gogan": ['G OW1 G AH0 N'],
  "gogel": ['G OW1 G AH0 L'],
  "goggans": ['G AA1 G AH0 N Z'],
  "goggin": ['G AA1 G IH0 N'],
  "goggins": ['G AA1 G IH0 N Z'],
  "goggle": ['G AA1 G AH0 L'],
  "goggles": ['G AA1 G AH0 L Z'],
  "gogh": ['G OW1'],
  "goghs": ['G OW1 Z'],
  "goglia": ['G AA1 G L IY0 AH0'],
  "gogo": ['G OW1 G OW2'],
  "goguen": ['G OW1 G W AH0 N'],
  "goh": ['G OW1'],
  "goheen": ['G AH0 HH IY1 N'],
  "gohl": ['G OW1 L'],
  "gohlke": ['G AA1 K', 'G OW1 K'],
  "gohman": ['G OW1 M AH0 N'],
  "gohn": ['G AA1 N'],
  "gohr": ['G AA1 R'],
  "goin": ['G OY1 N'],
  "goin'": ['G OW1 AH0 N'],
  "goines": ['G OY1 N Z'],
  "going": ['G OW1 IH0 NG', 'G OW1 IH0 N'],
  "goings": ['G OW1 IH0 NG Z'],
  "goings-on": ['G OW1 IH0 NG Z AA1 N'],
  "goings-ons": ['G OW1 IH0 NG Z AA1 N Z'],
  "goins": ['G OY1 N Z'],
  "goiter": ['G OY1 T ER0'],
  "goizueta": ['G OY2 Z W EY1 T AH0'],
  "gojko": ['G OY1 K AO2'],
  "gokey": ['G OW1 K IY2'],
  "goksel": ['G AO1 K S AH1 L'],
  "gola": ['G OW1 L AH0'],
  "golab": ['G OW1 L AE2 B'],
  "golan": ['G OW1 L AH0 N', 'G AH0 L AA1 N'],
  "goland": ['G OW1 L AH0 N D'],
  "golas": ['G OW1 L AH0 Z'],
  "golaszewski": ['G AH0 L AH0 SH EH1 F S K IY0'],
  "golay": ['G AA1 L EY0'],
  "golba": ['G OW1 L B AH0'],
  "golberg": ['G OW1 L B ER0 G'],
  "golconda": ['G AA0 L K AA1 N D AH0'],
  "gold": ['G OW1 L D'],
  "gold's": ['G OW1 L D Z'],
  "gold-green": ['G OW1 L D G R IY2 N'],
  "golda": ['G OW1 L D AH0'],
  "goldade": ['G OW1 L D EY1 D'],
  "goldammer": ['G OW1 L D AH0 M ER0'],
  "goldbach": ['G OW1 L D B AA2 K'],
  "goldbaum": ['G OW1 L D B AW2 M'],
  "goldbeck": ['G OW1 L D B EH2 K'],
  "goldberg": ['G OW1 L D B ER0 G'],
  "goldberg's": ['G OW1 L D B ER0 G Z'],
  "goldberger": ['G OW1 L D B ER0 G ER0'],
  "goldblatt": ['G OW1 L D B L AH0 T'],
  "goldblum": ['G OW1 L D B L AH0 M'],
  "goldcor": ['G OW1 L D K AO2 R'],
  "goldcreek": ['G OW1 L D K R IY2 K'],
  "goldcrest": ['G OW1 L D K R EH2 S T'],
  "golde": ['G OW1 L D'],
  "golden": ['G OW1 L D AH0 N'],
  "golden's": ['G OW1 L D AH0 N Z'],
  "goldenberg": ['G OW1 L D AH0 N B ER0 G'],
  "goldeneye": ['G OW1 L D AH0 N AY2'],
  "goldenrod": ['G OW1 L D AH0 N R AA2 D'],
  "goldenson": ['G OW1 L D AH0 N S AH0 N'],
  "goldenson's": ['G OW1 L D AH0 N S AH0 N Z'],
  "goldenstein": ['G OW1 L D AH0 N S T AY2 N', 'G OW1 L D AH0 N S T IY2 N'],
  "goldenthal": ['G OW1 L D AH0 N TH AA2 L'],
  "goldenthal's": ['G OW1 L D AH0 N TH AA2 L Z'],
  "golder": ['G OW1 L D ER0'],
  "goldest": ['G OW1 L D IH0 S T'],
  "goldey": ['G OW1 L D IY0'],
  "goldfarb": ['G OW1 L D F AA2 R B'],
  "goldfeder": ['G OW1 L D F EH2 D ER0'],
  "goldfield": ['G OW1 L D F IY2 L D'],
  "goldfields": ['G OW1 L D F IY2 L D Z'],
  "goldfinch": ['G OW1 L D F IH2 N CH'],
  "goldfinches": ['G OW1 L D F IH2 N CH IH0 Z'],
  "goldfine": ['G OW1 L D F AY2 N'],
  "goldfinger": ['G OW1 L D F IH2 NG G ER0'],
  "goldfish": ['G OW1 L D F IH2 SH'],
  "goldfus": ['G OW1 L D F AH2 S'],
  "goldhammer": ['G OW1 L D HH AE2 M ER0'],
  "goldie": ['G OW1 L D IY0'],
  "goldilocks": ['G OW1 L D IY0 L AO2 K S'],
  "goldin": ['G OW1 L D IH0 N'],
  "golding": ['G OW1 L D IH0 NG'],
  "goldinger": ['G OW1 L D IH0 NG ER0'],
  "goldman": ['G OW1 L D M AH0 N'],
  "goldman's": ['G OW1 L D M AH0 N Z'],
  "goldmann": ['G OW1 L D M AH0 N'],
  "goldmans": ['G OW1 L D M AH0 N Z'],
  "goldmark": ['G OW1 L D M AA2 R K'],
  "goldmine": ['G OW1 L D M AY2 N'],
  "goldner": ['G OW1 L D N ER0'],
  "goldome": ['G OW1 L D OW2 M'],
  "goldress": ['G OW1 L D R EH2 S'],
  "goldrick": ['G OW1 L D R IH0 K'],
  "goldring": ['G OW1 L D R IH2 NG'],
  "golds": ['G OW1 L D Z'],
  "goldsberry": ['G OW1 L D Z B EH2 R IY0'],
  "goldsborough": ['G OW1 L D Z B ER0 OW0'],
  "goldsby": ['G OW1 L D Z B IY0'],
  "goldschmid": ['G OW1 L D SH M IH2 D'],
  "goldschmidt": ['G OW1 L D SH M IH2 T'],
  "goldsmith": ['G OW1 L D S M IH2 TH'],
  "goldsmith's": ['G OW1 L D S M IH2 TH S'],
  "goldson": ['G OW1 L D S AH0 N'],
  "goldstar": ['G OW1 L D S T AA2 R'],
  "goldstein": ['G OW1 L D S T AY2 N', 'G OW1 L D S T IY2 N'],
  "goldstein's": ['G OW1 L D S T AY2 N Z', 'G OW1 L D S T IY2 N Z'],
  "goldstock": ['G OW1 L D S T AA2 K'],
  "goldston": ['G OW1 L D S T AH0 N'],
  "goldstone": ['G OW1 L D S T OW2 N'],
  "goldstrike": ['G OW1 L D S T R AY2 K'],
  "goldsworthy": ['G OW1 L D Z W ER2 DH IY0'],
  "goldthwaite": ['G OW1 L D TH W EY2 T'],
  "goldwasser": ['G OW1 L D W AO0 S ER0'],
  "goldwater": ['G OW1 L D W AO2 T ER0'],
  "goldwater's": ['G OW1 L D W AO2 T ER0 Z'],
  "goldwin": ['G OW1 L D W IH0 N'],
  "goldwire": ['G OW1 L D W AY2 R'],
  "goldwyn": ['G OW1 L D W IH0 N'],
  "goldy": ['G OW1 L D IY0'],
  "golec": ['G OW1 L IH0 K'],
  "golem": ['G OW1 L AH0 M'],
  "goleman": ['G OW1 L M AH0 N'],
  "golembeski": ['G AH0 L IH0 M B EH1 S K IY0'],
  "golembiewski": ['G AH0 L IH0 M B IY0 EH1 F S K IY0'],
  "golen": ['G AA1 L AH0 N'],
  "goley": ['G OW1 L IY0'],
  "golf": ['G AA1 L F', 'G AO1 L F'],
  "golf's": ['G AA1 L F S'],
  "golfarb": ['G AO1 L F AA2 R B'],
  "golfed": ['G AA1 L F T'],
  "golfer": ['G AA1 L F ER0'],
  "golfer's": ['G AA1 L F ER0 Z'],
  "golfers": ['G AA1 L F ER0 Z'],
  "golfie": ['G AA1 L F IY0'],
  "golfing": ['G AA1 L F IH0 NG', 'G AO1 L F IH0 NG'],
  "golfman": ['G AA1 L F M AH0 N'],
  "golfs": ['G AA1 L F S'],
  "golgi": ['G OW1 L JH IY0'],
  "golgo": ['G OW1 L G OW0'],
  "goliath": ['G AH0 L AY1 AH0 TH'],
  "goliaths": ['G OW1 L IY0 AE0 TH S'],
  "golightly": ['G OW1 L AY2 T L IY0'],
  "golinski": ['G AH0 L IH1 N S K IY0'],
  "golisano": ['G OW2 L IH0 S AA1 N OW0'],
  "golkar": ['G OW1 L K AA0 R'],
  "goll": ['G AA1 L'],
  "golla": ['G AA1 L AH0'],
  "golladay": ['G AA1 L AH0 D EY2'],
  "golle": ['G AA1 L'],
  "goller": ['G AA1 L ER0'],
  "golliday": ['G AA1 L IY0 D EY0'],
  "golliher": ['G AA1 L IH0 HH ER0'],
  "gollnick": ['G AA1 L N IH0 K'],
  "gollum": ['G AA1 L AH0 M'],
  "gollust": ['G OW1 L AH0 S T'],
  "golly": ['G AA1 L IY0'],
  "golob": ['G OW1 L AH0 B'],
  "golomb": ['G AA1 L AH0 M'],
  "golombek": ['G AO1 L AH0 M B EH1 K'],
  "golonka": ['G OW0 L OW1 NG K AH0'],
  "goloven": ['G OW1 L AH0 V AH0 N'],
  "golphin": ['G OW1 L F IH0 N'],
  "golson": ['G OW1 L S AH0 N'],
  "golston": ['G OW1 L S T AH0 N'],
  "goltz": ['G OW1 L T S'],
  "golub": ['G OW1 L AH0 B'],
  "golubski": ['G AH0 L AH1 B S K IY0'],
  "golz": ['G OW1 L Z'],
  "goma": ['G OW1 M AH0'],
  "gombar": ['G AH0 M B AA1 R'],
  "gomberg": ['G AA1 M B ER0 G'],
  "gombert": ['G AA1 M B ER0 T'],
  "gombos": ['G OW1 M B OW0 Z'],
  "gomer": ['G OW1 M ER0'],
  "gomery": ['G OW1 M ER0 IY0'],
  "gomes": ['G OW1 M EH2 Z'],
  "gomez": ['G OW1 M EH0 Z'],
  "gomillion": ['G AA1 M IH0 L Y AH0 N'],
  "gomoll": ['G AA1 M AH0 L'],
  "gomorrah": ['G AH0 M AO1 R AH0'],
  "gomory": ['G OW1 M ER0 IY0'],
  "gonad": ['G OW1 N AE0 D'],
  "gonads": ['G OW1 N AE0 D Z'],
  "gonaives": ['G OW0 N AY1 V Z'],
  "goncalves": ['G OW0 N K AA1 L V EH0 S'],
  "gonce": ['G AA1 N S'],
  "goncharov": ['G AA1 N CH ER0 AA0 V'],
  "gond": ['G AA1 N D'],
  "gonda": ['G AA1 N D AH0'],
  "gondek": ['G AA1 N D IH0 K'],
  "gonder": ['G AA1 N D ER0'],
  "gondola": ['G AA1 N D AH0 L AH0', 'G AA0 N D OW1 L AH0'],
  "gondolas": ['G AA1 N D AH0 L AH0 Z', 'G AA0 N D OW1 L AH0 Z'],
  "gondolier": ['G AA2 N D AH0 L IH1 R'],
  "gondoliers": ['G AA2 N D AH0 L IH1 R Z'],
  "gondwana": ['G AA2 N D W AA1 N AA2'],
  "gondwanaland": ['G AA2 N D W AA1 N AA0 L AE2 N D'],
  "gone": ['G AO1 N'],
  "goner": ['G AA1 N ER0'],
  "gonet": ['G OW1 N IH0 T'],
  "gong": ['G AO1 NG'],
  "gongaware": ['G AA1 NG G AH0 W EH0 R'],
  "gongora": ['G OW0 NG G AO1 R AH0'],
  "gongs": ['G AO1 NG Z'],
  "gonia": ['G OW1 N IY0 AH0'],
  "goniometer": ['G OW2 N IY0 AA1 M AH0 T ER0'],
  "gonium": ['G OW1 N IY0 AH0 M'],
  "gonna": ['G AA1 N AH0'],
  "gonnella": ['G OW0 N EH1 L AH0'],
  "gonnerman": ['G AA1 N ER0 M AH0 N'],
  "gonococcal": ['G AA2 N AH0 K AA1 K AH0 L'],
  "gonorrhea": ['G AA2 N ER0 IY1 AH0'],
  "gonsales": ['G OW1 N S EY0 L Z'],
  "gonsalez": ['G OW0 N S AA1 L EH0 Z'],
  "gonsalves": ['G OW0 N S AA1 L V EH0 S'],
  "gonser": ['G AA1 N S ER0'],
  "gonsoulin": ['G AA1 N S AW0 L IH0 N'],
  "gonterman": ['G AA1 N T ER0 M AH0 N'],
  "gonya": ['G OW1 N Y AH0'],
  "gonyea": ['G OW1 N Y EH0'],
  "gonyer": ['G OW1 N Y ER0'],
  "gonyo": ['G OW1 N Y OW0'],
  "gonzales": ['G AA0 N Z AA1 L AH0 S'],
  "gonzalez": ['G AA0 N Z AA1 L AH0 Z'],
  "gonzalez's": ['G AA0 N Z AA1 L AH0 Z AH0 Z'],
  "gonzalo": ['G AA0 N Z AA1 L OW0'],
  "gonzo": ['G AA1 N Z OW0'],
  "gonzo's": ['G AA1 N Z OW0 Z'],
  "goo": ['G UW1'],
  "gooch": ['G UW1 CH'],
  "good": ['G UH1 D', 'G IH0 D'],
  "good's": ['G UH1 D Z'],
  "good-bye": ['G IH0 D B AY1', 'G UH1 D B AY2'],
  "good-heartedly": ['G UH1 D HH AA1 R T IH0 D L IY0'],
  "good-looking": ['G UH1 D L UH2 K IH0 NG'],
  "good-naturedly": ['G UH1 D N EY1 CH ER0 D L IY0'],
  "gooda": ['G UH1 D AH0', 'G UW1 D AH0'],
  "goodale": ['G UH1 D EY2 L'],
  "goodall": ['G UH1 D AO2 L'],
  "goodbar": ['G UH1 D B AA2 R'],
  "goodbread": ['G UH1 D B R EH2 D'],
  "goodby": ['G UH1 D B AY2'],
  "goodbye": ['G UH2 D B AY1'],
  "goodbyes": ['G UH2 D B AY1 Z', 'G IH0 D B AY1 Z'],
  "goodchild": ['G UH1 D CH AY2 L D'],
  "goode": ['G UH1 D'],
  "goodell": ['G UH1 D EH0 L'],
  "gooden": ['G UH1 D AH0 N'],
  "gooden's": ['G UH1 D AH0 N Z'],
  "goodenough": ['G UH1 D AH0 N AW2'],
  "goodenow": ['G UH1 D EH0 N OW0'],
  "gooder": ['G UH1 D ER0'],
  "gooderham": ['G UH1 D ER0 HH AE2 M'],
  "gooders": ['G UH1 D ER0 Z'],
  "goodfella": ['G UH1 D F EH2 L AH0'],
  "goodfellas": ['G UH1 D F EH2 L AH0 Z'],
  "goodfellow": ['G UH1 D F EH2 L OW0'],
  "goodfellows": ['G UH1 D F EH2 L OW0 Z'],
  "goodfriend": ['G UH1 D F R EH2 N D'],
  "goodfriends": ['G UH1 D F R EH2 N D Z'],
  "goodgame": ['G UH1 D G EY2 M'],
  "goodhart": ['G UH1 D HH AA2 R T'],
  "goodheart": ['G UH1 D HH AA2 R T'],
  "goodhue": ['G UH1 D HH Y UW2'],
  "goodie": ['G UH1 D IY0'],
  "goodies": ['G UH1 D IY0 Z'],
  "goodin": ['G UH1 D AH0 N'],
  "goodine": ['G UH1 D AY0 N'],
  "gooding": ['G UH1 D IH0 NG'],
  "gooding's": ['G UH1 D IH0 NG Z'],
  "goodison": ['G UH1 D IH0 S AH0 N'],
  "goodkin": ['G UH1 D K IH2 N'],
  "goodland": ['G UH1 D L AH0 N D', 'G UH1 D L AE2 N D'],
  "goodlet": ['G UH1 D L EH2 T'],
  "goodlett": ['G UH1 D L EH2 T'],
  "goodley": ['G UH1 D L IY0'],
  "goodlin": ['G UH1 D L IH0 N'],
  "goodling": ['G UH1 D L IH0 NG'],
  "goodloe": ['G UH1 D L OW2'],
  "goodlow": ['G UH1 D L OW2'],
  "goodly": ['G UH1 D L IY0'],
  "goodman": ['G UH1 D M AH0 N'],
  "goodman's": ['G UH1 D M AE2 N Z'],
  "goodmorning": ['G UH2 D M AO1 R N IH0 NG'],
  "goodner": ['G UH1 D N ER0'],
  "goodness": ['G UH1 D N AH0 S', 'G UH1 D N IH0 S'],
  "goodnight": ['G UH1 D N AY2 T'],
  "goodnights": ['G UH1 D N AY2 T S'],
  "goodnough": ['G UH1 D N AH2 F'],
  "goodnow": ['G UH1 D N OW0'],
  "goodpaster": ['G UH1 D P AE2 S T ER0'],
  "goodpasture": ['G UH1 D P AE2 S CH ER0'],
  "goodreau": ['G UH1 D R OW2'],
  "goodrich": ['G UH1 D R IH2 CH'],
  "goodrich's": ['G UH1 D R IH2 CH IH0 Z'],
  "goodrick": ['G UH1 D R IH0 K'],
  "goodridge": ['G UH1 D R IH2 JH'],
  "goodroe": ['G UH1 D R OW2'],
  "goodrow": ['G UH1 D R OW2'],
  "goodrum": ['G UH1 D R AH2 M'],
  "goods": ['G UH1 D Z'],
  "goodsell": ['G UH1 D S EH2 L'],
  "goodson": ['G UH1 D S AH0 N'],
  "goodson's": ['G UH1 D S AH0 N Z'],
  "goodspeed": ['G UH1 D S P IY2 D'],
  "goodspeed's": ['G UH1 D S P IY1 D Z'],
  "goodstein": ['G UH1 D S T AY2 N', 'G UH1 D S T IY2 N'],
  "goodtab": ['G UH1 D T AE2 B'],
  "goodwill": ['G UH1 D W IH1 L'],
  "goodwin": ['G UH1 D W IH0 N'],
  "goodwine": ['G UH1 D W AY2 N'],
  "goodwyn": ['G UH1 D W IH2 N'],
  "goody": ['G UH1 D IY0'],
  "goody's": ['G UH1 D IY0 Z'],
  "goodyear": ['G UH1 D Y IH0 R', 'G UH1 D Y IY0 R'],
  "goodyear's": ['G UH1 D Y IH0 R Z'],
  "gooey": ['G UW1 IY0'],
  "goof": ['G UW1 F'],
  "goofed": ['G UW1 F T'],
  "goofing": ['G UW1 F IH0 NG'],
  "goofs": ['G UW1 F S'],
  "goofy": ['G UW1 F IY0'],
  "googe": ['G UW1 JH'],
  "googin": ['G UW1 G IH0 N'],
  "googins": ['G UW1 G IH0 N Z'],
  "google": ['G UW1 G AH0 L'],
  "google's": ['G UW1 G AH0 L Z'],
  "googled": ['G UW1 G AH0 L D'],
  "googles": ['G UW1 G AH0 L Z'],
  "googleverse": ['G UW1 G AH0 L V ER2 S'],
  "googling": ['G UW1 G L IH0 NG'],
  "gook": ['G UH1 K'],
  "gookin": ['G UH1 K IH0 N'],
  "goold": ['G UW1 L D'],
  "gooley": ['G UW1 L IY0'],
  "goolsby": ['G UW1 L S B IY0'],
  "goon": ['G UW1 N'],
  "goonan": ['G UW1 N AH0 N'],
  "goons": ['G UW1 N Z'],
  "goop": ['G UW1 P'],
  "goos": ['G UW1 Z'],
  "goosby": ['G UW1 S B IY0'],
  "goose": ['G UW1 S'],
  "goose's": ['G UW1 S IH0 Z'],
  "gooseberries": ['G UW1 S B EH2 R IY0 Z'],
  "gooseberry": ['G UW1 S B EH2 R IY0'],
  "goosefish": ['G UW1 S F IH2 SH'],
  "goosefoot": ['G UW1 S F UH2 T'],
  "goosen": ['G UW1 S AH0 N'],
  "goosey": ['G UW1 S IY0'],
  "goossen": ['G UW1 S AH0 N'],
  "goossens": ['G UW1 S AH0 N Z'],
  "goostree": ['G UW0 S T R IY1'],
  "gootee": ['G UW1 T IY0'],
  "gopac": ['G OW1 P AE2 K'],
  "gopac's": ['G OW1 P AE2 K S'],
  "gopal": ['G OW2 P AA1 L'],
  "gopher": ['G OW1 F ER0'],
  "gophers": ['G OW1 F ER0 Z'],
  "gopi": ['G OW1 P IY0'],
  "gopinath": ['G OW1 P IH0 N AH0 TH'],
  "gopro": ['G OW1 P R OW2'],
  "gora": ['G AO1 R AH0'],
  "goracke": ['G AO1 R AH0 K'],
  "goradze": ['G AO2 R AA1 D Z AH0'],
  "goral": ['G AO1 R AH0 L'],
  "goralski": ['G ER0 AA1 L S K IY0'],
  "goran": ['G AO1 R AH0 N'],
  "goranson": ['G AO1 R AH0 N S AH0 N'],
  "gorazde": ['G AO2 R AA1 ZH D AH0', 'G ER0 AA1 ZH D AH0'],
  "gorazde's": ['G AO2 R AA1 ZH D AH0 Z', 'G ER0 AA1 ZH D AH0 Z'],
  "gorbachev": ['G AO1 R B AH0 CH EH0 V', 'G AO1 R B AH0 CH AO2 F'],
  "gorbachev's": ['G AO1 R B AH0 CH EH0 V Z', 'G AO1 R B AH0 CH AO2 F S'],
  "gorbachevs": ['G AO1 R B AH0 CH EH0 V Z', 'G AO1 R B AH0 CH AO2 F S'],
  "gorby": ['G AO1 R B IY0'],
  "gorczyca": ['G ER0 CH IH1 K AH0'],
  "gorczynski": ['G ER0 CH IH1 N S K IY0'],
  "gorda": ['G AO1 R D AH0'],
  "gordan": ['G AO1 R D AH0 N'],
  "gordana": ['G AO0 R D AE1 N AH0'],
  "gorden": ['G AO1 R D AH0 N'],
  "gorder": ['G AO1 R D ER0'],
  "gordeyev": ['G AO0 R D AY1 AH0 V'],
  "gordian": ['G AO1 R D IY0 AH0 N'],
  "gordie": ['G AO1 R D IY0'],
  "gordillo": ['G AO2 R D IH1 L OW0'],
  "gordin": ['G AO1 R D IH0 N'],
  "gordinier": ['G AO1 R D IH0 N IY0 ER0'],
  "gordji": ['G AO1 R JH IY0'],
  "gordner": ['G AO1 R D N ER0'],
  "gordon": ['G AO1 R D AH0 N'],
  "gordon's": ['G AO1 R D AH0 N Z'],
  "gordy": ['G AO1 R D IY0'],
  "gordy's": ['G AO1 R D IY0 Z'],
  "gore": ['G AO1 R'],
  "gore's": ['G AO1 R Z'],
  "gorecki": ['G ER0 EH1 T S K IY0'],
  "gored": ['G AO1 R D'],
  "goree": ['G AO1 R IY1'],
  "gorelick": ['G AO1 R IH0 L IH0 K'],
  "goren": ['G AO1 R AH0 N'],
  "gorenflo": ['G AO0 R EH1 N F L OW0'],
  "gores": ['G AO1 R Z'],
  "gorey": ['G AO1 R IY0'],
  "gorgas": ['G AO1 R G AH0 Z'],
  "gorge": ['G AO1 R JH'],
  "gorgeous": ['G AO1 R JH AH0 S'],
  "gorges": ['G AO1 R JH AH0 Z', 'G AO1 R JH IH0 Z'],
  "gorgets": ['G AO1 R G AH0 T S'],
  "gorgon": ['G AO1 R G AH0 N'],
  "gorgone": ['G AO1 R G AH0 N'],
  "gorgonian": ['G AO0 R G OW1 N IY0 AH0 N'],
  "gorgons": ['G AO1 R G AH0 N Z'],
  "gorgonzola": ['G AO2 R G AH0 N Z OW1 L AH0'],
  "gorguze": ['G AO1 R G Y UW0 Z'],
  "gorham": ['G AO1 R AH0 M'],
  "gori": ['G AO1 R IY0'],
  "goria": ['G AO1 R IY0 AH0'],
  "gorilla": ['G ER0 IH1 L AH0'],
  "gorillas": ['G ER0 IH1 L AH0 Z'],
  "gorin": ['G AO1 R AH0 N'],
  "goring": ['G AO1 R IH0 NG'],
  "goris": ['G AO1 R AH0 S'],
  "gorka": ['G AO1 R K AH0'],
  "gorki": ['G AO1 R K IY0'],
  "gorky": ['G AO1 R K IY0'],
  "gorky's": ['G AO1 R K IY0 Z'],
  "gorley": ['G AO1 R L IY0'],
  "gorman": ['G AO1 R M AH0 N'],
  "gormley": ['G AO1 R M L IY0'],
  "gormly": ['G AO1 R M L IY0'],
  "gorney": ['G AO1 R N IY0'],
  "gorniak": ['G AO1 R N IY0 AE0 K'],
  "gornick": ['G AO1 R N IH0 K'],
  "gornik": ['G AO1 R N IH0 K'],
  "gornto": ['G AO1 R N T OW0'],
  "gorny": ['G AO1 R N IY0'],
  "gorospe": ['G AO0 R OW1 S P EY0'],
  "gorr": ['G AO1 R'],
  "gorrell": ['G AO0 R EY1 L'],
  "gorriak": ['G AO1 R IY0 AE0 K'],
  "gorrid": ['G AO1 R AH0 D'],
  "gorrids": ['G AO1 R AH0 D Z'],
  "gorski": ['G AO1 R S K IY0'],
  "gorsky": ['G AO1 R S K IY0'],
  "gorsline": ['G AO1 R S L AY2 N'],
  "gorsuch": ['G AO1 R S AH0 CH'],
  "gort": ['G AO1 R T'],
  "gortari": ['G AO0 R T AA1 R IY0'],
  "gorter": ['G AO1 R T ER0'],
  "gortney": ['G AO1 R T N IY0'],
  "gorton": ['G AO1 R T AH0 N'],
  "gorum": ['G AO1 R AH0 M'],
  "gory": ['G AO1 R IY0'],
  "gosa": ['G OW1 S AH0'],
  "gosbank": ['G AO1 S B AE2 NG K'],
  "gosch": ['G AO1 SH'],
  "gosdin": ['G AA1 S D IH0 N'],
  "gose": ['G OW1 Z'],
  "gosh": ['G AA1 SH'],
  "gosha": ['G OW1 SH AH0'],
  "goshawk": ['G AA1 S HH AO2 K'],
  "goshen": ['G OW1 SH IH0 N'],
  "goshorn": ['G AA1 SH ER0 N'],
  "goslee": ['G AA1 S L IY0'],
  "goslin": ['G AA1 S L IH0 N'],
  "gosline": ['G AA1 S L AY0 N'],
  "gosman": ['G AA1 S M AH0 N'],
  "gosnell": ['G AA1 S N AH0 L'],
  "gosney": ['G AA1 S N IY0'],
  "gosornstem": ['G AH0 S AO1 R N S T EH0 M'],
  "gospel": ['G AA1 S P AH0 L', 'G AO1 S P AH0 L'],
  "gospels": ['G AA1 S P AH0 L Z'],
  "gosplan": ['G AO1 S P L AE2 N'],
  "goss": ['G AO1 S'],
  "gossage": ['G AO1 S IH0 JH'],
  "gossamer": ['G AA1 S AH0 M ER0'],
  "gossard": ['G AH0 S AA1 R D'],
  "gosse": ['G AA1 S'],
  "gosselin": ['G AA1 S IH0 L IH0 N'],
  "gossen": ['G AO1 S AH0 N'],
  "gosser": ['G AO1 S ER0'],
  "gossett": ['G AA1 S IH0 T'],
  "gossip": ['G AA1 S AH0 P'],
  "gossiper": ['G AA1 S AH0 P ER0'],
  "gossipers": ['G AA1 S AH0 P ER0 Z'],
  "gossiping": ['G AA1 S AH0 P IH0 NG'],
  "gossips": ['G AA1 S AH0 P S'],
  "gossipy": ['G AA1 S AH0 P IY0'],
  "gossman": ['G AO1 S M AH0 N'],
  "gostev": ['G AO1 S T AH0 V'],
  "gostomski": ['G AH0 S T AA1 M S K IY0'],
  "goswick": ['G AA1 S W IH0 K'],
  "got": ['G AA1 T'],
  "gotaas": ['G AA1 T AA2 S'],
  "gotbaum": ['G AA1 T B AO0 M', 'G AA1 T B AW2 M'],
  "gotch": ['G AA1 CH'],
  "gotcha": ['G AA1 CH AH0'],
  "gotcher": ['G AA1 CH ER0'],
  "goth": ['G AA1 TH'],
  "gotham": ['G AA1 TH AH0 M'],
  "gothard": ['G AA1 TH ER0 D'],
  "gothenburg": ['G OW1 T AH0 N B ER0 G', 'G AA1 T AH0 N B ER0 G'],
  "gothic": ['G AA1 TH IH0 K'],
  "gotlieb": ['G AA1 T L IY2 B'],
  "goto": ['G OW1 T UW2', 'G OW1 T OW0'],
  "gotsch": ['G AA1 CH'],
  "gotschal": ['G AA1 CH AH0 L'],
  "gotschall": ['G AA1 CH AH0 L'],
  "gotshal": ['G AA1 CH AH0 L'],
  "gotshal's": ['G AA1 CH AH0 L Z'],
  "gotshall": ['G AA1 CH AH0 L'],
  "gott": ['G AA1 T'],
  "gotta": ['G AA1 T AH0'],
  "gotten": ['G AA1 T AH0 N', 'G AO1 T AH0 N'],
  "gotterdammerung": ['G AA1 T ER0 D AE2 M ER0 AH0 NG'],
  "gottesman": ['G AA1 T S M AH0 N'],
  "gottfried": ['G AO1 T F R IY0 D'],
  "gotthardt": ['G AA1 TH AA0 R T'],
  "gotthelf": ['G AA1 T HH EH2 L F'],
  "gotti": ['G AA1 T IY0'],
  "gotti's": ['G AA1 T IY0 Z'],
  "gottis": ['G AA1 T IH0 S', 'G AA1 T IY0 Z'],
  "gottlieb": ['G AA1 T L IY2 B'],
  "gottlieb's": ['G AA1 T L IY2 B Z'],
  "gottman": ['G AA1 T M AH0 N'],
  "gotts": ['G AA1 T S'],
  "gottsch": ['G AA1 CH'],
  "gottschalk": ['G AA1 CH AH0 K'],
  "gottschalks": ['G AA1 CH AH0 K S'],
  "gottschall": ['G AA1 CH AH0 L'],
  "gottshall": ['G AA1 CH AH0 L'],
  "gottwald": ['G AA1 T W AH0 L D'],
  "gotwalt": ['G AA1 T W AH0 L T'],
  "gotz": ['G AA1 T S'],
  "gouache": ['G UW1 AA2 SH'],
  "goucher": ['G AW1 K ER0'],
  "goude": ['G AW1 D'],
  "goudeau": ['G UW2 D OW1'],
  "goudie": ['G AW1 D IY0'],
  "goudreau": ['G UW2 D R OW1'],
  "goudy": ['G AW1 D IY0'],
  "gouge": ['G AW1 JH'],
  "gouged": ['G AW1 JH D'],
  "gougeon": ['G AW1 JH IH0 N'],
  "gouger": ['G AW1 JH ER0'],
  "gough": ['G AO1 F'],
  "goughnour": ['G AW1 N ER0'],
  "gouging": ['G AW1 JH IH0 NG'],
  "gouin": ['G W IY1 N'],
  "gouker": ['G AW1 K ER0'],
  "goulart": ['G UW0 L AA1 R T'],
  "goulash": ['G UW1 L AA2 SH'],
  "gould": ['G UW1 L D'],
  "gould's": ['G UW1 L D Z'],
  "goulden": ['G UH1 D AH0 N'],
  "goulding": ['G UW1 L D IH0 NG'],
  "goulet": ['G UW0 L EH1 T'],
  "goulette": ['G UW2 L EH1 T'],
  "goulston": ['G UW1 L S T AH0 N'],
  "gounod": ['G UW1 N AH0 D'],
  "goupil": ['G UW1 P AH0 L'],
  "gourd": ['G AO1 R D'],
  "gourdine": ['G UH0 R D AY1 N'],
  "gourds": ['G AO1 R D Z'],
  "gourlay": ['G AO1 R L EY0'],
  "gourley": ['G AO1 R L IY0'],
  "gourmand": ['G UH1 R M AA0 N D', 'G AO1 R M AA0 N D'],
  "gourment": ['G AO2 R M EH1 N T'],
  "gourmet": ['G UH1 R M EY2'],
  "gourmets": ['G UH1 R M EY2 Z'],
  "gousha": ['G UW1 SH AH0'],
  "gout": ['G AW1 T'],
  "goutal": ['G UW1 T AH0 L'],
  "gouty": ['G AW1 T IY0'],
  "gouvea": ['G UW2 V EY1 AH0'],
  "gouveia": ['G UW2 V EY1 Y AH0'],
  "gov": ['G AH1 V'],
  "govan": ['G OW1 V AH0 N'],
  "gove": ['G OW1 V'],
  "govea": ['G AH1 V IY0 AH0'],
  "gover": ['G AH1 V ER0'],
  "goverment": ['G AH1 V ER0 M AH0 N T'],
  "govern": ['G AH1 V ER0 N'],
  "governale": ['G AH1 V ER0 N EY2 L'],
  "governance": ['G AH1 V ER0 N AH0 N S'],
  "governed": ['G AH1 V ER0 N D'],
  "governess": ['G AH1 V ER0 N AH0 S'],
  "governing": ['G AH1 V ER0 N IH0 NG'],
  "government": ['G AH1 V ER0 M AH0 N T', 'G AH1 V ER0 N M AH0 N T'],
  "government's": ['G AH1 V ER0 M AH0 N T S', 'G AH1 V ER0 N M AH0 N T S'],
  "governmental": ['G AH1 V ER0 M EH2 N T AH0 L', 'G AH2 V ER0 N M EH1 N T AH0 L'],
  "governmentally": ['G AH1 V ER0 M EH2 N T AH0 L IY0', 'G AH1 V ER0 M EH2 N AH0 L IY0'],
  "governments": ['G AH1 V ER0 M AH0 N T S', 'G AH1 V ER0 N M AH0 N T S'],
  "governments'": ['G AH1 V ER0 N M AH0 N T S', 'G AH1 V ER0 M AH0 N T S'],
  "governor": ['G AH1 V ER0 N ER0'],
  "governor's": ['G AH1 V ER0 N ER0 Z'],
  "governors": ['G AH1 V ER0 N ER0 Z'],
  "governors'": ['G AH1 V ER0 N ER0 Z'],
  "governorship": ['G AH1 V ER0 N ER0 SH IH2 P'],
  "governorships": ['G AH1 V ER0 N ER0 SH IH2 P S'],
  "governs": ['G AH1 V ER0 N Z'],
  "govett": ['G AH1 V AH0 T'],
  "govier": ['G OW1 V IY0 ER0'],
  "govoni": ['G OW0 V OW1 N IY0'],
  "govpx": ['G AH1 V P IY2 EH1 K S'],
  "govs": ['G AA1 V Z', 'G AA1 V ER0 N ER0 Z'],
  "gow": ['G AW1'],
  "gowan": ['G AW1 AH0 N'],
  "gowans": ['G AW1 AH0 N Z'],
  "goward": ['G OW1 W ER0 D'],
  "gowdy": ['G AW1 D IY0'],
  "gowell": ['G AA1 W EH0 L'],
  "gowen": ['G AW1 AH0 N'],
  "gowens": ['G AW1 AH0 N Z'],
  "gower": ['G AW1 ER0'],
  "gowin": ['G AW1 IH0 N'],
  "gowing": ['G AW1 IH0 NG'],
  "gowins": ['G AW1 IH0 N Z'],
  "gown": ['G AW1 N'],
  "gowns": ['G AW1 N Z'],
  "goy": ['G OY1'],
  "goya": ['G OY1 AH0'],
  "goya's": ['G OY1 AH0 Z'],
  "goyer": ['G OY1 ER0'],
  "goyette": ['G OY2 EH1 T'],
  "goyim": ['G OY1 IH0 M'],
  "goyko": ['G OY1 K OW0'],
  "goyne": ['G OY1 N'],
  "goynes": ['G OY1 N Z'],
  "goza": ['G OW1 Z AH0'],
  "gozo": ['G OW1 Z OW0'],
  "gozo's": ['G OW1 Z OW0 Z'],
  "gozofsky": ['G AH0 Z AO1 F S K IY0'],
  "gps": ['G IY1 P IY0 EH1 S'],
  "grab": ['G R AE1 B'],
  "grabau": ['G R AE1 B AW0'],
  "grabbe": ['G R AE1 B'],
  "grabbed": ['G R AE1 B D'],
  "grabber": ['G R AE1 B ER0'],
  "grabbers": ['G R AE1 B ER0 Z'],
  "grabbing": ['G R AE1 B IH0 NG'],
  "grabe": ['G R EY1 B'],
  "grabel": ['G R AE1 B AH0 L'],
  "graben": ['G R AE1 B AH0 N'],
  "grabens": ['G R AA1 B AH0 N Z'],
  "grabenstein": ['G R AE1 B AH0 N S T AY2 N', 'G R AE1 B AH0 N S T IY2 N'],
  "graber": ['G R EY1 B ER0'],
  "grabert": ['G R AE1 B ER0 T'],
  "grabill": ['G R AH0 B IH1 L'],
  "grabinski": ['G R AH0 B IH1 N S K IY0'],
  "grable": ['G R EY1 B AH0 L'],
  "grabner": ['G R AE1 B N ER0'],
  "graboski": ['G R AH0 B AW1 S K IY0'],
  "grabow": ['G R AE1 B OW0'],
  "grabowski": ['G R AH0 B AO1 F S K IY0'],
  "graboy": ['G R EY1 B OY0'],
  "grabs": ['G R AE1 B Z'],
  "grabski": ['G R AE1 B S K IY2'],
  "grace": ['G R EY1 S'],
  "grace's": ['G R EY1 S IH0 Z'],
  "graced": ['G R EY1 S T'],
  "graceffa": ['G R AH0 S EH1 F AH0'],
  "graceful": ['G R EY1 S F AH0 L'],
  "gracefully": ['G R EY1 S F AH0 L IY0'],
  "graceland": ['G R EY1 S L AE0 N D'],
  "graceless": ['G R EY1 S L AH0 S'],
  "graces": ['G R EY1 S IH0 Z'],
  "gracey": ['G R EY1 S IY0'],
  "grachev": ['G R AA1 CH EH0 V'],
  "grachev's": ['G R AA1 CH EH0 V Z'],
  "grachov": ['G R AA1 CH AO1 V'],
  "graci": ['G R AA1 CH IY0'],
  "gracia": ['G R AA1 CH AH0'],
  "graciano": ['G R AA0 CH IY0 AA1 N OW0'],
  "gracias": ['G R AA1 S IY0 AH0 Z'],
  "gracie": ['G R EY1 S IY0'],
  "gracile": ['G R AE1 S AH0 L'],
  "gracing": ['G R EY1 S IH0 NG'],
  "gracious": ['G R EY1 SH AH0 S'],
  "graciously": ['G R EY1 SH AH0 S L IY0'],
  "graciousness": ['G R EY1 SH AH0 S N AH0 S'],
  "grackle": ['G R AE1 K AH0 L'],
  "grackles": ['G R AE1 K AH0 L Z'],
  "graco": ['G R AE1 K OW0'],
  "gracy": ['G R EY1 S IY0'],
  "graczyk": ['G R AA1 CH IH0 K'],
  "grad": ['G R AE1 D'],
  "gradate": ['G R EY1 D EY0 T'],
  "gradation": ['G R EY0 D EY1 SH AH0 N'],
  "gradations": ['G R EY0 D EY1 SH AH0 N Z'],
  "gradchev": ['G R AE1 D CH EH0 F', 'G R AE1 D CH AO0 V'],
  "gradco": ['G R AE1 D K OW0'],
  "graddy": ['G R AE1 D IY0'],
  "grade": ['G R EY1 D'],
  "graded": ['G R EY1 D AH0 D', 'G R EY1 D IH0 D'],
  "gradel": ['G R AE1 D AH0 L'],
  "graden": ['G R EY1 D AH0 N'],
  "grader": ['G R EY1 D ER0'],
  "graders": ['G R EY1 D ER0 Z'],
  "grades": ['G R EY1 D Z'],
  "gradient": ['G R EY1 D IY0 AH0 N T'],
  "gradients": ['G R EY1 D IY0 AH0 N T S'],
  "grading": ['G R EY1 D IH0 NG'],
  "gradison": ['G R AE1 D IH0 S AH0 N'],
  "gradney": ['G R AE1 D N IY0'],
  "grado": ['G R AA1 D OW0'],
  "gradov": ['G R EY1 D AO0 F'],
  "gradovs": ['G R EY1 D AO0 F S'],
  "grads": ['G R AE1 D Z'],
  "gradstein": ['G R AE1 D S T IY0 N', 'G R AE1 D S T AY0 N'],
  "gradstein's": ['G R AE1 D S T IY0 N Z', 'G R AE1 D S T AY0 N Z'],
  "gradual": ['G R AE1 JH UW0 AH0 L'],
  "gradualism": ['G R AE1 JH AH0 W AH0 L IH2 Z AH0 M'],
  "gradualist": ['G R AE1 JH AH0 W AH0 L IH0 S T'],
  "gradually": ['G R AE1 JH UW0 AH0 L IY0', 'G R AE1 JH UW0 L IY0'],
  "graduate": ['G R AE1 JH AH0 W AH0 T', 'G R AE1 JH AH0 W EY2 T', 'G R AE1 JH UW0 W AH0 T', 'G R AE1 JH UW0 EY2 T'],
  "graduated": ['G R AE1 JH UW0 EY2 T IH0 D', 'G R AE1 JH AH0 W EY2 T IH0 D'],
  "graduates": ['G R AE1 JH AH0 W AH0 T S', 'G R AE1 JH AH0 W EY2 T S', 'G R AE1 JH UW0 W AH0 T S', 'G R AE1 JH UW0 EY2 T S'],
  "graduating": ['G R AE1 JH AH0 W EY2 T IH0 NG', 'G R AE1 JH UW0 EY2 T IH0 NG'],
  "graduation": ['G R AE2 JH UW0 EY1 SH AH0 N', 'G R AE2 JH AH0 W EY1 SH AH0 N'],
  "graduations": ['G R AE2 JH UW0 EY1 SH AH0 N Z', 'G R AE2 JH AH0 W EY1 SH AH0 N Z'],
  "gradus": ['G R EY1 D AH0 S'],
  "grady": ['G R EY1 D IY0'],
  "graeber": ['G R EH1 B ER0'],
  "graebner": ['G R EH1 B N ER0'],
  "graef": ['G R IY1 F', 'G R AE1 F'],
  "graefe": ['G R IY1 F', 'G R AE1 F'],
  "graeff": ['G R IY1 F', 'G R AE1 F'],
  "graeme": ['G R EY1 M', 'G R AE1 M'],
  "graeser": ['G R EY1 Z ER0'],
  "graessle": ['G R EH1 S AH0 L'],
  "graetz": ['G R IY1 T S'],
  "graf": ['G R AE1 F'],
  "grafe": ['G R EY1 F'],
  "graff": ['G R AE1 F'],
  "graffam": ['G R AE1 F AH0 M'],
  "graffeo": ['G R AA1 F IY0 OW0'],
  "graffiti": ['G R AH0 F IY1 T IY0'],
  "graffius": ['G R AE1 F IY0 IH0 S'],
  "graft": ['G R AE1 F T'],
  "grafted": ['G R AE1 F T IH0 D'],
  "grafting": ['G R AE1 F T IH0 NG'],
  "grafton": ['G R AE1 F T AH0 N'],
  "grafts": ['G R AE1 F T S'],
  "grage": ['G R EY1 JH'],
  "gragert": ['G R EY1 G ER0 T'],
  "gragg": ['G R AE1 G'],
  "graham": ['G R EY1 AH0 M', 'G R AE1 M'],
  "graham's": ['G R EY1 AH0 M Z', 'G R AE1 M Z'],
  "grahams": ['G R AE1 M Z', 'G R EY1 AH0 M Z'],
  "grahek": ['G R AE1 HH IH0 K'],
  "grahl": ['G R AA1 L'],
  "grahn": ['G R AE1 N'],
  "graichen": ['G R AY1 K AH0 N'],
  "grail": ['G R EY1 L'],
  "grain": ['G R EY1 N'],
  "grained": ['G R EY1 N D'],
  "grainery": ['G R EY1 N ER0 IY0'],
  "grainger": ['G R EY1 NG JH ER0'],
  "grains": ['G R EY1 N Z'],
  "grainy": ['G R EY1 N IY0'],
  "grajeda": ['G R AY0 IY1 D AH0'],
  "graley": ['G R AE1 L IY0'],
  "grall": ['G R AO1 L'],
  "gram": ['G R AE1 M'],
  "grama": ['G R AE1 M AH0'],
  "gramaphone": ['G R AE1 M AH0 F OW2 N'],
  "grambling": ['G R AE1 M B L IH0 NG', 'G R AE1 M B AH0 L IH0 NG'],
  "gramer": ['G R EY1 M ER0'],
  "gramercy": ['G R AH0 M ER1 S IY0'],
  "gramersey": ['G R AH0 M ER1 S IY0'],
  "grames": ['G R EY1 M Z'],
  "gramley": ['G R AE1 M L IY0'],
  "gramlich": ['G R AE1 M L IH0 K'],
  "gramling": ['G R AE1 M L IH0 NG'],
  "gramm": ['G R AE1 M'],
  "gramm's": ['G R AE1 M Z'],
  "grammar": ['G R AE1 M ER0'],
  "grammatical": ['G R AH0 M AE1 T AH0 K AH0 L', 'G R AH0 M AE1 T IH0 K AH0 L'],
  "grammatico": ['G R AA0 M AA0 T IY1 K OW0'],
  "grammer": ['G R AE1 M ER0'],
  "grammies": ['G R AE1 M IY2 Z'],
  "grammophon": ['G R AE1 M AH0 F AA0 N'],
  "gramms": ['G R AE1 M Z'],
  "grammy": ['G R AE1 M IY0'],
  "grammy's": ['G R AE1 M IY0 Z'],
  "grammys": ['G R AE1 M IY0 Z'],
  "grams": ['G R AE1 M Z'],
  "gramza": ['G R AE1 M Z AH0'],
  "gran": ['G R AE1 N'],
  "grana": ['G R AE1 N AH0'],
  "granada": ['G R AH0 N AA1 D AH0'],
  "granada's": ['G R AH0 N AA1 D AH0 Z'],
  "granade": ['G R AH0 N EY1 D'],
  "granado": ['G R AA0 N AA1 D OW0'],
  "granados": ['G R AA0 N AA1 D OW0 Z'],
  "granahan": ['G R AE1 N AH0 HH AE0 N'],
  "granat": ['G R AE1 N AH0 T'],
  "granata": ['G R AA0 N AA1 T AH0'],
  "granato": ['G R AA0 N AA1 T OW0'],
  "granberg": ['G R AE1 N B ER0 G'],
  "granberry": ['G R AE1 N B EH2 R IY0'],
  "grancare": ['G R AE1 N K EH2 R'],
  "grand": ['G R AE1 N D'],
  "grand's": ['G R AE1 N D Z'],
  "grand-daughter": ['G R AE1 N D AO2 T ER0'],
  "granda": ['G R AE1 N D AH0'],
  "grandad": ['G R AE1 N D AE2 D'],
  "grandad's": ['G R AE1 N D AE2 D Z'],
  "grandbabies": ['G R AE1 N D B EY2 B IY0 Z', 'G R AE1 N B EY2 B IY0 Z'],
  "grandbaby": ['G R AE1 N D B EY2 B IY0', 'G R AE1 N B EY2 B IY0'],
  "grandberry": ['G R AE1 N D B EH2 R IY0'],
  "grandchamp": ['G R AE1 N D CH AE2 M P'],
  "grandchild": ['G R AE1 N D CH AY2 L D', 'G R AE1 N CH AY2 L D'],
  "grandchildren": ['G R AE1 N CH IH2 L D R AH0 N', 'G R AE1 N D CH IH2 L D R AH0 N'],
  "grandchildren's": ['G R AE1 N CH IH2 L D R AH0 N Z', 'G R AE1 N D CH IH2 L D R AH0 N Z'],
  "granddad": ['G R AE1 N D AE2 D'],
  "granddaddy": ['G R AE1 N D AE2 D IY0'],
  "granddaughter": ['G R AE1 N D AO2 T ER0'],
  "granddaughter's": ['G R AE1 N D AO2 T ER0 Z'],
  "granddaughters": ['G R AE1 N D AO2 T ER0 Z'],
  "grande": ['G R AE1 N D'],
  "grander": ['G R AE1 N D ER0'],
  "granderson": ['G R AE1 N D ER0 S AH0 N'],
  "grandest": ['G R AE1 N D AH0 S T'],
  "grandeur": ['G R AE0 N D UW1 R'],
  "grandfather": ['G R AE1 N D F AA2 DH ER0', 'G R AE1 N F AA2 DH ER0'],
  "grandfather's": ['G R AE1 N D F AA2 DH ER0 Z', 'G R AE1 N F AA2 DH ER0 Z'],
  "grandfathered": ['G R AE1 N D F AA2 DH ER0 D', 'G R AE1 N F AA2 DH ER0 D'],
  "grandfathering": ['G R AE1 N D F AA2 DH ER0 IH0 NG', 'G R AE1 N F AA2 DH ER0 IH0 NG'],
  "grandfatherly": ['G R AE1 N D F AA2 DH ER0 L IY0', 'G R AE1 N F AA2 DH ER0 L IY0'],
  "grandfathers": ['G R AE1 N D F AA2 DH ER0 Z', 'G R AE1 N F AA2 DH ER0 Z'],
  "grandfield": ['G R AE1 N D F IY2 L D'],
  "grandi": ['G R AE1 N D IY0'],
  "grandillo": ['G R AE0 N D IH1 L OW0'],
  "grandiloquent": ['G R AE0 N D IH1 L AH0 K W AH0 N T'],
  "grandin": ['G R AE1 N D IH2 N'],
  "grandinetti": ['G R AE0 N D IY0 N EH1 T IY0'],
  "grandiose": ['G R AE2 N D IY0 OW1 S', 'G R AE1 N D IY0 OW2 S'],
  "grandis": ['G R AE1 N D IH0 S'],
  "grandison": ['G R AE1 N D IH0 S AH0 N'],
  "grandkid": ['G R AE1 N D K IH2 D', 'G R AE1 N K IH2 D'],
  "grandkids": ['G R AE1 N D K IH2 D Z', 'G R AE1 N K IH2 D Z'],
  "grandly": ['G R AE1 N D L IY0'],
  "grandma": ['G R AE1 N D M AA0'],
  "grandma's": ['G R AE1 N D M AA2 Z'],
  "grandmaison": ['G R AE1 N D M AY2 Z AA1 N', 'G R AE1 N D M EY1 S AH0 N'],
  "grandmas": ['G R AE1 N D M AA2 Z'],
  "grandmaster": ['G R AE1 N D M AE1 S T ER0', 'G R AE1 N M AE1 S T ER0'],
  "grandmet": ['G R AE1 N D M EH2 T'],
  "grandmom": ['G R AE1 N D M AA2 M'],
  "grandmother": ['G R AE1 N D M AH2 DH ER0'],
  "grandmother's": ['G R AE1 N D M AH2 DH ER0 Z'],
  "grandmotherly": ['G R AE1 N D M AH2 DH ER0 L IY0'],
  "grandmothers": ['G R AE1 N D M AH2 DH ER0 Z'],
  "grandnephew": ['G R AE1 N D N EH1 F Y UW0'],
  "grandnephews": ['G R AE1 N D N EH1 F Y UW0 Z'],
  "grandon": ['G R AE1 N D AA0 N'],
  "grandpa": ['G R AE1 N D P AA2'],
  "grandpa's": ['G R AE1 N P AA2 Z'],
  "grandparent": ['G R AE1 N D P EH2 R AH0 N T'],
  "grandparents": ['G R AE1 N D P EH2 R AH0 N T S'],
  "grandparents'": ['G R AE1 N D P EH2 R AH0 N T S'],
  "grandpas": ['G R AE1 N D P AA2 Z'],
  "grandpre": ['G R AE1 N D P R EY2'],
  "grands": ['G R AE1 N D Z'],
  "grandson": ['G R AE1 N D S AH2 N'],
  "grandson's": ['G R AE1 N D S AH2 N Z'],
  "grandsons": ['G R AE1 N D S AH2 N Z'],
  "grandstaff": ['G R AE1 N D S T AE2 F'],
  "grandstand": ['G R AE1 N D S T AE2 N D'],
  "grandstanding": ['G R AE1 N D S T AE2 N D IH0 NG'],
  "grandt": ['G R AE1 N T'],
  "granduncle": ['G R AE1 N D AH1 NG K AH0 L'],
  "granduncles": ['G R AE1 N D AH1 NG K AH0 L Z'],
  "grandview": ['G R AE1 N D V Y UW2'],
  "grandy": ['G R AE1 N D IY0'],
  "graner": ['G R EY1 N ER0'],
  "granese": ['G R AE1 N IY0 Z'],
  "graney": ['G R EY1 N IY0'],
  "granfield": ['G R AE1 N F IY2 L D'],
  "grange": ['G R EY1 N JH'],
  "granger": ['G R EY1 N JH ER0'],
  "grangers": ['G R EY1 N JH ER0 Z'],
  "granges": ['G R EY1 N JH IH0 Z'],
  "granholm": ['G R AE1 N HH OW2 L M'],
  "granier": ['G R EY1 N IY0 ER0'],
  "granieri": ['G R AA0 N IH1 R IY0'],
  "granillo": ['G R AH0 N IH1 L OW0'],
  "graningeverken": ['G R AE2 N IH0 NG G EH1 V ER0 K AH0 N'],
  "graningeverken's": ['G R AE2 N IH0 NG G EH1 V ER0 K AH0 N Z'],
  "granite": ['G R AE1 N AH0 T', 'G R AE1 N IH0 T'],
  "granitic": ['G R AH0 N IH1 T IH0 K'],
  "granito": ['G R AA0 N IY1 T OW0'],
  "granlund": ['G R AE1 N L AH0 N D'],
  "grannan": ['G R AE1 N AH0 N'],
  "grannies": ['G R AE1 N IY0 Z'],
  "grannis": ['G R AE1 N IH0 S'],
  "granny": ['G R AE1 N IY0'],
  "grano": ['G R AA1 N OW0'],
  "granoff": ['G R AE1 N AO0 F'],
  "granola": ['G R AH0 N OW1 L AH0'],
  "granquist": ['G R AE1 N K W IH2 S T'],
  "granstrom": ['G R AE1 N S T R AH0 M'],
  "grant": ['G R AE1 N T'],
  "grant's": ['G R AE1 N T S'],
  "granted": ['G R AE1 N T AH0 D', 'G R AE1 N T IH0 D', 'G R AE1 N AH0 D', 'G R AE1 N IH0 D'],
  "grantham": ['G R AE1 N TH AH0 M'],
  "granting": ['G R AE1 N T IH0 NG', 'G R AE1 N IH0 NG'],
  "grantland": ['G R AE1 N T L AH0 N D'],
  "grantor": ['G R AE1 N T ER0'],
  "grantors": ['G R AE1 N T ER0 Z'],
  "grantree": ['G R AE1 N T R IY2'],
  "grantree's": ['G R AE1 N T R IY2 Z'],
  "grants": ['G R AE1 N T S'],
  "grantz": ['G R AE1 N T S'],
  "granular": ['G R AE1 N Y AH0 L ER0'],
  "granulation": ['G R AE2 N Y AH0 L EY1 SH AH0 N'],
  "granule": ['G R AE1 N Y AH0 L'],
  "granules": ['G R AE1 N Y AH0 L Z'],
  "granulocyte": ['G R AH0 N UW1 L OW0 S AY2 T'],
  "granum": ['G R AE1 N AH0 M'],
  "granville": ['G R AE1 N V IH0 L'],
  "granville's": ['G R AE1 N V IH0 L Z'],
  "granz": ['G R AE1 N T S'],
  "granzow": ['G R AE1 N Z OW0'],
  "grapage": ['G R EY1 P AH0 JH'],
  "grape": ['G R EY1 P'],
  "grapefruit": ['G R EY1 P F R UW2 T'],
  "grapefruits": ['G R EY1 P F R UW2 T S'],
  "graper": ['G R EY1 P ER0'],
  "grapes": ['G R EY1 P S'],
  "grapeshot": ['G R EY1 P SH AA2 T'],
  "grapevine": ['G R EY1 P V AY2 N'],
  "grapevines": ['G R EY1 P V AY2 N Z'],
  "graph": ['G R AE1 F'],
  "graphic": ['G R AE1 F IH0 K'],
  "graphical": ['G R AE1 F IH0 K AH0 L'],
  "graphically": ['G R AE1 F IH0 K L IY0'],
  "graphics": ['G R AE1 F IH0 K S'],
  "graphics'": ['G R AE1 F IH0 K S'],
  "graphite": ['G R AE1 F AY2 T'],
  "graphology": ['G R AH0 F AA1 L AH0 JH IY0'],
  "graphs": ['G R AE1 F S'],
  "grapple": ['G R AE1 P AH0 L'],
  "grappled": ['G R AE1 P AH0 L D'],
  "grapples": ['G R AE1 P AH0 L Z'],
  "grappling": ['G R AE1 P L IH0 NG', 'G R AE1 P AH0 L IH0 NG'],
  "gras": ['G R AE1 S', 'G R AA1'],
  "graser": ['G R EY1 Z ER0'],
  "grasmick": ['G R AE1 Z M IH0 K'],
  "grasp": ['G R AE1 S P'],
  "grasped": ['G R AE1 S P T'],
  "grasping": ['G R AE1 S P IH0 NG'],
  "grasps": ['G R AE1 S P S'],
  "grass": ['G R AE1 S'],
  "grasse": ['G R AE1 S'],
  "grassed": ['G R AE1 S T'],
  "grassel": ['G R AE1 S AH0 L'],
  "grasser": ['G R AE1 S ER0'],
  "grassers": ['G R AE1 S ER0 Z'],
  "grasses": ['G R AE1 S AH0 Z', 'G R AE1 S IH0 Z'],
  "grassfield": ['G R AE1 S F IY2 L D'],
  "grassfield's": ['G R AE1 S F IY2 L D Z'],
  "grassgreen": ['G R AE1 S G R IY2 N'],
  "grasshopper": ['G R AE1 S HH AA2 P ER0'],
  "grasshoppers": ['G R AE1 S HH AA2 P ER0 Z'],
  "grassi": ['G R AE1 S IY0'],
  "grassia": ['G R AA1 S IY0 AH0'],
  "grassings": ['G R AE1 S IH0 NG Z'],
  "grassl": ['G R AE1 S AH0 L'],
  "grassland": ['G R AE1 S L AE2 N D'],
  "grasslands": ['G R AE1 S L AE2 N D Z'],
  "grassley": ['G R AE1 S L IY0'],
  "grasslike": ['G R AE1 S L AY2 K'],
  "grassman": ['G R AE1 S M AH0 N'],
  "grasso": ['G R AE1 S OW0'],
  "grassroot": ['G R AE1 S R UW1 T'],
  "grassroots": ['G R AE1 S R UW1 T S'],
  "grassy": ['G R AE1 S IY0'],
  "grasty": ['G R AE1 S T IY0'],
  "grata": ['G R AA1 T AH0'],
  "grate": ['G R EY1 T'],
  "grated": ['G R EY1 T IH0 D'],
  "grateful": ['G R EY1 T F AH0 L'],
  "gratefully": ['G R EY1 T F AH0 L IY0'],
  "grater": ['G R EY1 T ER0'],
  "grates": ['G R EY1 T S'],
  "grathwohl": ['G R AE1 TH W OW2 L'],
  "gratification": ['G R AE2 T AH0 F AH0 K EY1 SH AH0 N'],
  "gratified": ['G R AE1 T AH0 F AY2 D'],
  "gratifies": ['G R AE1 T AH0 F AY2 Z'],
  "gratify": ['G R AE1 T AH0 F AY2'],
  "gratifying": ['G R AE1 T AH0 F AY2 IH0 NG'],
  "grating": ['G R EY1 T IH0 NG'],
  "gratis": ['G R AE1 T AH0 S'],
  "gratitude": ['G R AE1 T AH0 T UW2 D'],
  "grattan": ['G R AE1 T AH0 N'],
  "gratton": ['G R AE1 T AH0 N'],
  "gratuities": ['G R AH0 T UW1 IH0 T IY0 Z'],
  "gratuitous": ['G R AH0 T UW1 AH0 T AH0 S'],
  "gratuitously": ['G R AH0 T UW1 AH0 T AH0 S L IY0'],
  "gratuity": ['G R AH0 T UW1 IH0 T IY0'],
  "gratz": ['G R AE1 T S'],
  "gratzer": ['G R EY1 T Z ER0'],
  "grau": ['G R AW1'],
  "grauberger": ['G R AW1 B ER0 G ER0'],
  "graue": ['G R AW1', 'G R UW1'],
  "grauel": ['G R AW1 AH0 L', 'G R UW1 AH0 L'],
  "grauer": ['G R AW1 ER0', 'G R UW1 ER0'],
  "graul": ['G R AO1 L'],
  "grauman": ['G R AO1 M AH0 N'],
  "graumann": ['G R AO1 M AH0 N'],
  "graunke": ['G R AO1 NG K'],
  "gravamen": ['G R AA0 V EY1 M AH0 N'],
  "gravano": ['G R AH0 V AA1 N OW0', 'G R AH0 V AE1 N OW0'],
  "gravatt": ['G R AE1 V AH0 T'],
  "grave": ['G R EY1 V'],
  "gravel": ['G R AE1 V AH0 L'],
  "graveline": ['G R EY1 V L AY2 N'],
  "gravell": ['G R AE1 V AH0 L'],
  "gravelle": ['G R AH0 V EH1 L'],
  "gravelly": ['G R AE1 V AH0 L IY0'],
  "gravely": ['G R EY1 V L IY0'],
  "graven": ['G R EY1 V AH0 N'],
  "graver": ['G R EY1 V ER0'],
  "graves": ['G R EY1 V Z'],
  "graves's": ['G R EY1 V Z IH0 Z'],
  "graveside": ['G R EY1 V S AY2 D'],
  "gravesite": ['G R EY1 V S AY2 T'],
  "gravest": ['G R AE1 V AH0 S T'],
  "gravestone": ['G R EY1 V S T OW2 N'],
  "gravestones": ['G R EY1 V S T OW2 N Z'],
  "gravett": ['G R AE1 V IH0 T'],
  "gravette": ['G R AH0 V EH1 T'],
  "graveyard": ['G R EY1 V Y AA2 R D'],
  "graveyards": ['G R EY1 V Y AA2 R D Z'],
  "gravies": ['G R EY1 V IY0 Z'],
  "gravimeter": ['G R AE1 V AH0 M IY2 T ER0'],
  "gravimetric": ['G R AE2 V AH0 M EH1 T R IH0 K'],
  "gravina": ['G R AA0 V IY1 N AH0'],
  "gravino": ['G R AA0 V IY1 N OW0'],
  "gravitas": ['G R AE1 V AH0 T AH0 S'],
  "gravitate": ['G R AE1 V IH0 T EY2 T'],
  "gravitated": ['G R AE1 V AH0 T EY2 T IH0 D'],
  "gravitates": ['G R AE1 V IH0 T EY2 T S'],
  "gravitating": ['G R AE1 V IH0 T EY2 T IH0 NG'],
  "gravitation": ['G R AE2 V IH0 T EY1 SH AH0 N'],
  "gravitational": ['G R AE2 V IH0 T EY1 SH AH0 N AH0 L'],
  "gravitationally": ['G R AE2 V AH0 T EY1 SH N AH0 L IY0'],
  "gravitt": ['G R AE1 V IH0 T'],
  "gravitt's": ['G R AE1 V IH0 T S'],
  "gravity": ['G R AE1 V AH0 T IY0', 'G R AE1 V IH0 T IY0'],
  "gravley": ['G R AE1 V L IY0'],
  "gravlin": ['G R AE1 V L IH0 N'],
  "gravois": ['G R AH0 V W AA1'],
  "gravy": ['G R EY1 V IY0'],
  "graw": ['G R AO1'],
  "grawe": ['G R AO1'],
  "gray": ['G R EY1'],
  "gray's": ['G R EY1 Z'],
  "graybeal": ['G R EY1 B AH0 L'],
  "graybeard": ['G R EY1 B IY0 R D'],
  "graybeards": ['G R EY1 B IY0 R D Z'],
  "graybill": ['G R EY1 B IH2 L'],
  "graydon": ['G R EY1 D AH0 N'],
  "graye": ['G R EY1'],
  "grayer": ['G R EY1 ER0'],
  "grayest": ['G R EY1 IH0 S T'],
  "graying": ['G R EY1 IH0 NG'],
  "grayish": ['G R EY1 IH0 SH'],
  "grayling": ['G R EY1 L IH0 NG'],
  "grays": ['G R EY1 Z'],
  "grayson": ['G R EY1 S AH0 N'],
  "graze": ['G R EY1 Z'],
  "grazed": ['G R EY1 Z D'],
  "grazer": ['G R EY1 Z ER0'],
  "graziani": ['G R AA0 Z IY0 AA1 N IY0'],
  "graziano": ['G R AA0 T S IY0 AA1 N OW0'],
  "grazier": ['G R EY1 Z IY0 ER0'],
  "grazing": ['G R EY1 Z IH0 NG'],
  "grbavica": ['G ER0 B AA1 V IH0 K AH0', 'G ER2 B AH0 V AY1 K AH0'],
  "greaney": ['G R IY1 N IY0'],
  "grear": ['G R IH1 R'],
  "grease": ['G R IY1 S'],
  "greased": ['G R IY1 S T'],
  "greaser": ['G R IY1 S ER0'],
  "greasewood": ['G R IY1 S W UH2 D'],
  "greasing": ['G R IY1 S IH0 NG'],
  "greason": ['G R IY1 S AH0 N'],
  "greasy": ['G R IY1 S IY0'],
  "great": ['G R EY1 T'],
  "great's": ['G R EY1 T S'],
  "great-circle": ['G R EY1 T S ER1 K AH0 L'],
  "great-grandfather": ['G R EY2 T G R AE1 N D F AA2 DH ER0'],
  "great-grandmother": ['G R EY2 T G R AE1 N D M AH2 TH ER0'],
  "greater": ['G R EY1 T ER0'],
  "greatest": ['G R EY1 T AH0 S T'],
  "greathouse": ['G R EY1 T HH AW2 S'],
  "greatly": ['G R EY1 T L IY0'],
  "greatness": ['G R EY1 T N AH0 S'],
  "greats": ['G R EY1 T S'],
  "greave": ['G R IY1 V'],
  "greaves": ['G R IY1 V Z'],
  "greb": ['G R EH1 B'],
  "grebe": ['G R IY1 B'],
  "greber": ['G R IY1 B ER0'],
  "grebner": ['G R EH1 B N ER0'],
  "grecco": ['G R EH1 K OW0'],
  "grech": ['G R EH1 K'],
  "grecian": ['G R IY1 SH AH0 N'],
  "grecko": ['G R EH1 K OW0'],
  "greco": ['G R EH1 K OW0'],
  "greco-roman": ['G R EH2 K OW0 R OW1 M AH0 N'],
  "grede": ['G R IY1 D'],
  "gredel": ['G R EH1 D AH0 L'],
  "greditor": ['G R EH1 D IH0 T ER0'],
  "greear": ['G R IY1 ER0'],
  "greece": ['G R IY1 S'],
  "greece's": ['G R IY1 S IH0 Z'],
  "greed": ['G R IY1 D'],
  "greedier": ['G R IY2 D IY0 ER0'],
  "greediest": ['G R IY2 D IY0 IH0 S T'],
  "greedily": ['G R IY1 D AH0 L IY0'],
  "greedy": ['G R IY1 D IY0'],
  "greek": ['G R IY1 K'],
  "greeks": ['G R IY1 K S'],
  "greeley": ['G R IY1 L IY0'],
  "greeleyville": ['G R IY1 L IY0 V IH0 L'],
  "greelieville": ['G R IY1 L IY0 V IH0 L'],
  "greely": ['G R IY1 L IY0'],
  "green": ['G R IY1 N'],
  "green's": ['G R IY1 N Z'],
  "greenan": ['G R IY1 N AH0 N'],
  "greenawalt": ['G R IY1 N AH0 W AO2 L T'],
  "greenaway": ['G R IY1 N AH0 W EY2'],
  "greenback": ['G R IY1 N B AE2 K'],
  "greenback's": ['G R IY1 N B AE2 K S'],
  "greenbacks": ['G R IY1 N B AE2 K S'],
  "greenbaum": ['G R IY1 N B AW2 M'],
  "greenbelt": ['G R IY1 N B EH2 L T'],
  "greenberg": ['G R IY1 N B ER0 G'],
  "greenberg's": ['G R IY1 N B ER0 G Z'],
  "greenberger": ['G R IY1 N B ER0 G ER0'],
  "greenblatt": ['G R IY1 N B L AH0 T'],
  "greenburg": ['G R IY1 N B ER0 G'],
  "greenbury": ['G R IY1 N B ER0 IY0'],
  "greenbush": ['G R IY1 N B UH0 SH'],
  "greencastle": ['G R IY1 N K AE2 S AH0 L'],
  "greene": ['G R IY1 N'],
  "greene's": ['G R IY1 N Z'],
  "greened": ['G R IY1 N D'],
  "greener": ['G R IY1 N ER0'],
  "greenery": ['G R IY1 N ER0 IY0'],
  "greenest": ['G R IY1 N IH0 S T'],
  "greenfeld": ['G R IY1 N F EH2 L D'],
  "greenfeld's": ['G R IY1 N F EH2 L D Z'],
  "greenfell": ['G R IY1 N F EH2 L'],
  "greenfell's": ['G R IY1 N F EH2 L Z'],
  "greenfield": ['G R IY1 N F IY2 L D'],
  "greenfield's": ['G R IY1 N F IY2 L D Z'],
  "greenhalgh": ['G R IY1 N HH AH2 L G'],
  "greenham": ['G R IY1 N AH0 M'],
  "greenhaw": ['G R IY1 N HH AO2'],
  "greenhill": ['G R IY1 N HH IH2 L'],
  "greenhoe": ['G R IY1 N HH OW2'],
  "greenhouse": ['G R IY1 N HH AW2 S'],
  "greenhouses": ['G R IY1 N HH AW2 S IH0 Z', 'G R IY1 N HH AW2 Z AH0 Z'],
  "greenhut": ['G R IY1 N HH AH0 T'],
  "greeniaus": ['G R IY1 N IY0 AW0 S'],
  "greenidge": ['G R IY1 N IH0 JH'],
  "greening": ['G R IY1 N IH0 NG'],
  "greenish": ['G R IY1 N IH0 SH'],
  "greenland": ['G R IY1 N L AH0 N D', 'G R IY1 N L AE2 N D'],
  "greenlaw": ['G R IY1 N L AO2'],
  "greenleaf": ['G R IY1 N L IY2 F'],
  "greenlee": ['G R IY1 N L IY2'],
  "greenlees": ['G R IY1 N L IY2 Z'],
  "greenley": ['G R IY1 N L IY0'],
  "greenly": ['G R IY1 N L IY0'],
  "greenmail": ['G R IY1 N M EY2 L'],
  "greenmailer": ['G R IY1 N M EY2 L ER0'],
  "greenman": ['G R IY1 N M AH0 N'],
  "greenness": ['G R IY1 N N AH0 S'],
  "greeno": ['G R IY1 N OW0'],
  "greenough": ['G R IY1 N AH0 F'],
  "greenpeace": ['G R IY1 N P IY2 S'],
  "greenpeace's": ['G R IY1 N P IY2 S IH0 Z'],
  "greens": ['G R IY1 N Z'],
  "greens'": ['G R IY1 N Z'],
  "greensboro": ['G R IY1 N Z B ER0 R OW0'],
  "greensburg": ['G R IY1 N Z B ER0 G'],
  "greenshield": ['G R IY1 N SH IY2 L D'],
  "greenshields": ['G R IY1 N SH IY2 L D Z'],
  "greenslade": ['G R IY1 N S L AH0 D'],
  "greenslet": ['G R IY1 N S L EH2 T'],
  "greenspan": ['G R IY1 N S P AE2 N'],
  "greenspan's": ['G R IY1 N S P AE2 N Z'],
  "greenspon": ['G R IY1 N S P AA2 N'],
  "greenspun": ['G R IY1 N S P AH2 N'],
  "greenstein": ['G R IY1 N S T AY2 N', 'G R IY1 N S T IY2 N'],
  "greenstone": ['G R IY1 N S T OW2 N'],
  "greenstreet": ['G R IY1 N S T R IY2 T'],
  "greenup": ['G R IY1 N AH2 P'],
  "greenvale": ['G R IY1 N V EY2 L'],
  "greenville": ['G R IY1 N V IH0 L'],
  "greenwald": ['G R IY1 N W AO2 L D'],
  "greenwald's": ['G R IY1 N W AO2 L D Z'],
  "greenwalds": ['G R IY1 N W AO2 L D Z'],
  "greenwalt": ['G R IY1 N W AH0 L T'],
  "greenway": ['G R IY1 N W EY2'],
  "greenwell": ['G R IY1 N W EH2 L'],
  "greenwich": ['G R EH1 N IH0 CH', 'G R IY1 N W IH2 CH'],
  "greenwich's": ['G R EH1 N IH0 CH IH0 Z'],
  "greenwood": ['G R IY1 N W UH2 D'],
  "greer": ['G R IH1 R'],
  "greeson": ['G R IY1 S AH0 N'],
  "greet": ['G R IY1 T'],
  "greeted": ['G R IY1 T AH0 D', 'G R IY1 T IH0 D'],
  "greetham": ['G R IY1 TH AH0 M'],
  "greeting": ['G R IY1 T IH0 NG'],
  "greetings": ['G R IY1 T IH0 NG Z'],
  "greets": ['G R IY1 T S'],
  "greever": ['G R IY1 V ER0'],
  "grefe": ['G R IY1 F'],
  "greff": ['G R EH1 F'],
  "greg": ['G R EH1 G'],
  "greg's": ['G R EH1 G Z'],
  "grega": ['G R IY1 G AH0'],
  "gregarious": ['G R AH0 G EH1 R IY0 AH0 S'],
  "greger": ['G R EH1 G ER0'],
  "gregersen": ['G R EH1 G ER0 S AH0 N'],
  "gregerson": ['G R EH1 G ER0 S AH0 N'],
  "gregg": ['G R EH1 G'],
  "greggs": ['G R EH1 G Z'],
  "grego": ['G R EH1 G OW0'],
  "gregoire": ['G R IH0 G W AA1 R'],
  "gregor": ['G R EH1 G ER0'],
  "gregori": ['G R EH0 G AO1 R IY0'],
  "gregoria": ['G R EH0 G AO1 R IY0 AH0'],
  "gregorian": ['G R AH0 G AO1 R IY0 AH0 N'],
  "gregorich": ['G R EH1 G ER0 IH0 K'],
  "gregorie": ['G R EH1 G ER0 IY0'],
  "gregorio": ['G R IH0 G AO1 R IY0 OW0'],
  "gregory": ['G R EH1 G ER0 IY0'],
  "gregory's": ['G R EH1 G ER0 IY0 Z'],
  "gregson": ['G R EH1 G S AH0 N'],
  "gregston": ['G R EH1 G S T AH0 N'],
  "gregus": ['G R IY1 G AH0 S'],
  "greider": ['G R AY1 D ER0'],
  "greif": ['G R IY1 F'],
  "greiff": ['G R IY1 F', 'G R AY1 F'],
  "greig": ['G R IY1 G'],
  "greim": ['G R IY1 M'],
  "greiman": ['G R AY1 M AH0 N'],
  "grein": ['G R EY1 N'],
  "greiner": ['G R AY1 N ER0'],
  "greinke": ['G R EY1 NG K'],
  "greis": ['G R IY1 Z'],
  "greitz": ['G R EH1 T S', 'G R AY1 T S'],
  "greiwe": ['G R IY1 W'],
  "grell": ['G R EH1 L'],
  "grella": ['G R EH1 L AH0'],
  "grelle": ['G R EH1 L'],
  "gremban": ['G R EH1 M B AE2 N'],
  "gremillion": ['G R EH1 M IH0 L Y AH0 N'],
  "gremlin": ['G R EH1 M L AH0 N'],
  "gremlins": ['G R EH1 M L AH0 N Z'],
  "gren": ['G R EH1 N'],
  "grenada": ['G R IH0 N EY1 D AH0', 'G R IH0 N AA1 D AH0'],
  "grenade": ['G R AH0 N EY1 D'],
  "grenades": ['G R AH0 N EY1 D Z'],
  "grenda": ['G R EH1 N D AH0'],
  "grendel": ['G R EH1 N D AH0 L'],
  "grendel's": ['G R EH1 N D AH0 L Z'],
  "grenell": ['G R EH1 N AH0 L'],
  "grenfell": ['G R EH1 N F AH0 L'],
  "grenier": ['G R IY1 N IY0 ER0'],
  "greninger": ['G R EH1 N IH0 NG ER0'],
  "grennan": ['G R EH1 N AH0 N'],
  "grenoble": ['G R AH0 N OW1 B AH0 L'],
  "grenon": ['G R EH1 N AH0 N'],
  "grenouille": ['G R AH0 N UW1 IY0'],
  "grenz": ['G R EH1 N Z'],
  "gresh": ['G R EH1 SH'],
  "gresham": ['G R EH1 SH AH0 M'],
  "gresham's": ['G R EH1 SH AH0 M Z'],
  "gresko": ['G R EH1 S K OW0'],
  "gress": ['G R EH1 S'],
  "gresser": ['G R EH1 S ER0'],
  "gressett": ['G R EH1 S IH0 T'],
  "gressley": ['G R EH1 S L IY0'],
  "gressman": ['G R EH1 S M AH0 N'],
  "greta": ['G R IY1 T AH0'],
  "greta's": ['G R IY1 T AH0 Z'],
  "gretal": ['G R EH1 T AH0 L'],
  "gretchen": ['G R EH1 CH AH0 N'],
  "grete": ['G R IY1 T'],
  "gretel": ['G R EH1 T AH0 L'],
  "greth": ['G R EH1 TH'],
  "grethel": ['G R EH1 TH AH0 L'],
  "grether": ['G R EH1 DH ER0'],
  "gretna": ['G R EH1 T N AH0'],
  "gretna's": ['G R EH1 T N AH0 Z'],
  "grettenberger": ['G R EH1 T AH0 N B ER0 G ER0'],
  "gretz": ['G R EH1 T S'],
  "gretzinger": ['G R EH1 T Z IH0 NG ER0'],
  "gretzky": ['G R EH1 T S K IY1'],
  "greubel": ['G R OY1 B AH0 L'],
  "greuel": ['G R UW1 AH0 L'],
  "greulich": ['G R OY1 L IH0 K'],
  "greunke": ['G R UW1 NG K'],
  "greve": ['G R IY1 V'],
  "grever": ['G R EH1 V ER0'],
  "grew": ['G R UW1'],
  "grewal": ['G R UW1 AH0 L'],
  "grewe": ['G R UW1'],
  "grewell": ['G R EH1 W EH0 L'],
  "grey": ['G R EY1'],
  "grey's": ['G R EY1 Z'],
  "greyhound": ['G R EY1 HH AW2 N D'],
  "greyhound's": ['G R EY1 HH AW2 N D Z'],
  "greying": ['G R EY1 IH0 NG'],
  "greylag": ['G R EY1 L AE2 G'],
  "greystone": ['G R EY1 S T OW2 N'],
  "gribben": ['G R IH1 B AH0 N'],
  "gribbin": ['G R IH1 B IH0 N'],
  "gribbins": ['G R IH1 B IH0 N Z'],
  "gribble": ['G R IH1 B AH0 L'],
  "gribbles": ['G R IH1 B AH0 L Z'],
  "gribbon": ['G R IH1 B AH0 N'],
  "grice": ['G R AY1 S'],
  "grid": ['G R IH1 D'],
  "grid's": ['G R IH1 D Z'],
  "grider": ['G R AY1 D ER0'],
  "gridiron": ['G R IH1 D AY2 ER0 N'],
  "gridley": ['G R IH1 D L IY0'],
  "gridley's": ['G R IH1 D L IY0 Z'],
  "gridlock": ['G R IH1 D L AA2 K'],
  "gridlock's": ['G R IH1 D L AA2 K S'],
  "gridlocked": ['G R IH1 D L AA2 K T'],
  "gridlocks": ['G R IH1 D L AA2 K S'],
  "grids": ['G R IH1 D Z'],
  "grieb": ['G R IY1 B'],
  "griebel": ['G R IY1 B AH0 L'],
  "grieco": ['G R IY1 K OW0'],
  "grieder": ['G R IY1 D ER0'],
  "grief": ['G R IY1 F'],
  "grieger": ['G R IY1 G ER0'],
  "griego": ['G R IY1 G OW0'],
  "grieme": ['G R IY1 M'],
  "griep": ['G R IY1 P'],
  "griepentrog": ['G R IY1 P IH0 N T R AH0 G'],
  "grier": ['G R AY1 ER0', 'G R IY1 R'],
  "grier's": ['G R AY1 ER0 Z', 'G R IY1 R Z'],
  "grierson": ['G R IH1 R S AH0 N'],
  "gries": ['G R AY1 Z'],
  "griesa": ['G R IY0 EH1 S AH0'],
  "griesbach": ['G R IY1 S B AA0 K'],
  "griese": ['G R IY1 Z'],
  "griesemer": ['G R IY1 S IY0 M ER0'],
  "grieser": ['G R IY1 S ER0'],
  "grieshaber": ['G R IY1 SH AH0 B ER0'],
  "griesinger": ['G R IY1 S IH0 NG ER0'],
  "griess": ['G R IY1 S'],
  "griesser": ['G R IY1 S ER0'],
  "griest": ['G R AY1 IH0 S T'],
  "grievance": ['G R IY1 V AH0 N S'],
  "grievances": ['G R IY1 V AH0 N S AH0 Z', 'G R IY1 V AH0 N S IH0 Z'],
  "grieve": ['G R IY1 V'],
  "grieved": ['G R IY1 V D'],
  "griever": ['G R IY1 V ER0'],
  "grievers": ['G R IY1 V ER0 Z'],
  "grieves": ['G R IY1 V Z'],
  "grieveson": ['G R IY1 V AH0 S AH0 N', 'G R IY1 V S AH0 N'],
  "grieving": ['G R IY1 V IH0 NG'],
  "grievous": ['G R IY1 V AH0 S'],
  "grievously": ['G R IY1 V AH0 S L IY0'],
  "griff": ['G R IH1 F'],
  "griffee": ['G R IH1 F IY0'],
  "griffen": ['G R IH1 F AH0 N'],
  "griffeth": ['G R IH1 F IH0 TH'],
  "griffey": ['G R IH1 F IY0'],
  "griffey's": ['G R IH1 F IY0 Z'],
  "griffie": ['G R IH1 F IY0'],
  "griffin": ['G R IH1 F IH0 N'],
  "griffin's": ['G R IH1 F IH0 N Z'],
  "griffing": ['G R IH1 F IH0 NG'],
  "griffis": ['G R IH1 F IH0 S'],
  "griffith": ['G R IH1 F AH0 TH', 'G R IH1 F IH0 TH'],
  "griffith's": ['G R IH1 F IH0 TH S'],
  "griffiths": ['G R IH1 F IH0 TH S'],
  "griffitts": ['G R IH1 F IH0 T S'],
  "griffo": ['G R IH1 F OW0'],
  "griffon": ['G R IH1 F AH0 N'],
  "griffy": ['G R IH1 F IY0'],
  "grigas": ['G R AY1 G AH0 Z'],
  "grigg": ['G R IH1 G'],
  "griggs": ['G R IH1 G Z'],
  "griggy": ['G R IH1 G IY0'],
  "grignon": ['G R IH1 G N AH0 N'],
  "grigoli": ['G R IH0 G OW1 L IY0'],
  "grigorovich": ['G R IH0 G AO1 R AH0 V IH0 CH'],
  "grigory": ['G R EH1 G ER0 IY0', 'G R IY1 G ER0 IY0'],
  "grigoryant": ['G R IH0 G AO1 R Y AE0 N T'],
  "grigoryants": ['G R IH0 G AO1 R Y AE0 N T S'],
  "grigsby": ['G R IH1 G Z B IY0'],
  "grijalva": ['G R IY0 Y AA1 L V AH0'],
  "grill": ['G R IH1 L'],
  "grille": ['G R IH1 L'],
  "grilled": ['G R IH1 L D'],
  "grilli": ['G R IH1 L IY0'],
  "grilling": ['G R IH1 L IH0 NG'],
  "grilliot": ['G R IH1 L IY0 AH0 T'],
  "grillo": ['G R IH1 L OW0'],
  "grillot": ['G R IH1 L AH0 T'],
  "grills": ['G R IH1 L Z'],
  "grim": ['G R IH1 M'],
  "grima": ['G R IY1 M AH0'],
  "grimace": ['G R IH1 M AH0 S'],
  "grimaced": ['G R IH1 M AH0 S T'],
  "grimaces": ['G R IH1 M AH0 S IH0 Z'],
  "grimacing": ['G R IH1 M AH0 S IH0 NG'],
  "grimaldi": ['G R IY0 M AA1 L D IY0'],
  "grimaldo": ['G R IY0 M AA1 L D OW0'],
  "grime": ['G R AY1 M'],
  "grimes": ['G R AY1 M Z'],
  "grimley": ['G R IH1 M L IY0'],
  "grimly": ['G R IH1 M L IY0'],
  "grimm": ['G R IH1 M'],
  "grimm's": ['G R IH1 M Z'],
  "grimme": ['G R IH1 M'],
  "grimmer": ['G R IH1 M ER0'],
  "grimmest": ['G R IH1 M AH0 S T'],
  "grimmett": ['G R IH1 M IH0 T'],
  "grimness": ['G R IH1 M N AH0 S'],
  "grimshaw": ['G R IH1 M SH AO2'],
  "grimsley": ['G R IH1 M Z L IY0'],
  "grimstad": ['G R IH1 M S T AH0 D'],
  "grimwood": ['G R IH1 M W UH2 D'],
  "grimy": ['G R AY1 M IY0'],
  "grin": ['G R IH1 N'],
  "grinage": ['G R IH1 N IH0 JH'],
  "grinald": ['G R IH0 N AA1 L D'],
  "grinalds": ['G R IH0 N AA1 L D Z'],
  "grinberg": ['G R IH1 N B ER0 G'],
  "grinch": ['G R IH1 N CH'],
  "grind": ['G R AY1 N D'],
  "grinde": ['G R IH1 N D'],
  "grinder": ['G R AY1 N D ER0'],
  "grinders": ['G R AY1 N D ER0 Z'],
  "grinding": ['G R AY1 N D IH0 NG'],
  "grindlay": ['G R AY1 N D L EY2', 'G R IH1 N D L IY2'],
  "grindlays": ['G R AY1 N D L EY2 Z', 'G R IH1 N D L IY2 Z'],
  "grindle": ['G R IH1 N D AH0 L'],
  "grindley": ['G R IH1 N D L IY0'],
  "grindrod": ['G R AY1 N D R AA2 D'],
  "grinds": ['G R AY1 N D Z'],
  "grindstaff": ['G R AY1 N D S T AE2 F'],
  "grindstone": ['G R AY1 N D S T OW2 N'],
  "grine": ['G R AY1 N'],
  "griner": ['G R AY1 N ER0'],
  "gring": ['G R IH1 NG'],
  "gringo": ['G R IH1 NG G OW0'],
  "gringos": ['G R IH1 NG G OW0 Z'],
  "grinned": ['G R IH1 N D'],
  "grinnell": ['G R IH0 N EH1 L'],
  "grinning": ['G R IH1 N IH0 NG'],
  "grins": ['G R IH1 N Z'],
  "grinstead": ['G R IH1 N S T EH2 D'],
  "grinstein": ['G R IH1 N S T IY2 N', 'G R IH1 N S T AY2 N'],
  "grip": ['G R IH1 P'],
  "gripe": ['G R AY1 P'],
  "griped": ['G R AY1 P T'],
  "gripes": ['G R AY1 P S'],
  "griping": ['G R AY1 P IH0 NG'],
  "gripp": ['G R IH1 P'],
  "gripped": ['G R IH1 P T'],
  "gripper": ['G R IH1 P ER2'],
  "grippi": ['G R IH1 P IY0'],
  "gripping": ['G R IH1 P IH0 NG'],
  "grippo": ['G R IH1 P OW0'],
  "grips": ['G R IH1 P S'],
  "grisanti": ['G R IH0 S AE1 N T IY0'],
  "grisby": ['G R IH1 S B IY0'],
  "griscom": ['G R IH1 S K AH0 M'],
  "grise": ['G R AY1 Z'],
  "grisham": ['G R IH1 SH AH0 M'],
  "grisham's": ['G R IH1 SH AH0 M Z'],
  "grishilda": ['G R IH0 SH IH1 L D AH0'],
  "grishman": ['G R IH1 SH M AH0 N'],
  "grisly": ['G R IH1 Z L IY0'],
  "grismer": ['G ER1 IH0 Z AH0 M ER0', 'G R IH1 S M ER0'],
  "grismore": ['G R IY1 S M AO0 R'],
  "grissett": ['G R IH1 S IH0 T'],
  "grissinger": ['G R IH1 S IH0 NG ER0'],
  "grisso": ['G R IH1 S OW0'],
  "grissom": ['G R IH1 S AH0 M'],
  "grisson": ['G R IH1 S AH0 N'],
  "grist": ['G R IH1 S T'],
  "gristle": ['G R IH1 S AH0 L'],
  "griswell": ['G R IH1 S W EH0 L'],
  "griswold": ['G R IH1 S W OW2 L D'],
  "grit": ['G R IH1 T'],
  "grites": ['G R AY1 T S'],
  "grits": ['G R IH1 T S'],
  "gritter": ['G R IH1 T ER0'],
  "gritting": ['G R IH1 T IH0 NG'],
  "gritton": ['G R IH1 T AH0 N'],
  "gritty": ['G R IH1 T IY0'],
  "gritz": ['G R IH1 T S'],
  "gritzmacher": ['G R IH1 T S M AA2 K ER0'],
  "grivas": ['G R IY1 V AA0 Z'],
  "griz": ['G R IH1 Z'],
  "grizelda": ['G R IY0 Z EH1 L D AH0'],
  "grizzard": ['G R IH1 Z ER0 D'],
  "grizzell": ['G R IH1 Z AH0 L'],
  "grizzle": ['G R IH1 Z AH0 L'],
  "grizzled": ['G R IH1 Z AH0 L D'],
  "grizzlies": ['G R IH1 Z L IY0 Z'],
  "grizzly": ['G R IH1 Z L IY0'],
  "gro": ['G R OW1'],
  "groan": ['G R OW1 N'],
  "groaned": ['G R OW1 N D'],
  "groaning": ['G R OW1 N IH0 NG'],
  "groans": ['G R OW1 N Z'],
  "groat": ['G R OW1 T'],
  "grob": ['G R AA1 B'],
  "grobe": ['G R OW1 B'],
  "groben": ['G R AA1 B AH0 N'],
  "grober": ['G R OW1 B ER0'],
  "grobian": ['G R OW1 B IY0 AH0 N'],
  "grobler": ['G R AA1 B L ER0'],
  "groce": ['G R OW1 S'],
  "grocer": ['G R OW1 S ER0'],
  "grocer's": ['G R OW1 S ER0 Z'],
  "groceries": ['G R OW1 S ER0 IY0 Z', 'G R OW1 S R IY0 Z'],
  "grocers": ['G R OW1 S ER0 Z'],
  "grocery": ['G R OW1 S ER0 IY0', 'G R OW1 S R IY0'],
  "groch": ['G R AA1 K'],
  "grochowski": ['G R AH0 CH AO1 F S K IY0'],
  "grode": ['G R OW1 D'],
  "groden": ['G R OW1 D AH0 N'],
  "grodin": ['G R OW1 D IH0 N'],
  "grodsky": ['G R AA1 D S K IY0'],
  "groebner": ['G R OW1 B N ER0'],
  "groeger": ['G R OW1 G ER0'],
  "groen": ['G R OW1 N'],
  "groene": ['G R AA1 IY0 N'],
  "groeneveld": ['G R OW1 N IH0 V IH0 L D'],
  "groenewold": ['G R OW1 N UW0 OW0 L D'],
  "groening": ['G R AA1 AH0 N IH0 NG'],
  "groep": ['G R OW1 P'],
  "groer": ['G R OW1 ER0'],
  "groesbeck": ['G R OW1 S B EH0 K'],
  "groff": ['G R AO1 F'],
  "groft": ['G R AA1 F T'],
  "grog": ['G R AA1 G'],
  "grogan": ['G R OW1 G AH0 N'],
  "grogg": ['G R AA1 G'],
  "groggy": ['G R AA1 G IY0'],
  "groh": ['G R OW1'],
  "grohman": ['G R OW1 M AH0 N'],
  "grohs": ['G R OW1 S'],
  "groin": ['G R OY1 N'],
  "groined": ['G R OY1 N D'],
  "groins": ['G R OY1 N Z'],
  "groleau": ['G R AH0 L OW1'],
  "grolier": ['G R OW1 L Y ER0'],
  "groll": ['G R OW1 L'],
  "grom": ['G R AA1 M'],
  "groma": ['G R AA1 M AH0'],
  "groman": ['G R OW1 M AH0 N'],
  "gromek": ['G R OW1 M IH0 K'],
  "gromer": ['G R OW1 M ER0'],
  "gromes": ['G R OW1 M Z'],
  "gromyko": ['G R OW0 M IY1 K OW0'],
  "gronau": ['G R OW1 N AW0'],
  "gronberg": ['G R AA1 N B ER0 G'],
  "grondahl": ['G R AA1 N D AA2 L'],
  "grondin": ['G R AA1 N D IH0 N'],
  "grone": ['G R OW1 N'],
  "gronemeyer": ['G R AA1 N IH0 M AY0 ER0'],
  "groner": ['G R OW1 N ER0'],
  "gronewold": ['G R AA1 N UW0 OW0 L D'],
  "groninger": ['G R OW1 N IH0 NG ER0'],
  "gronlund": ['G R AA1 N L AH0 N D'],
  "gronowski": ['G R AH0 N AO1 F S K IY0'],
  "gronseth": ['G R AA1 N S IH0 TH'],
  "gronski": ['G R AA1 N S K IY0'],
  "groom": ['G R UW1 M'],
  "groome": ['G R UW1 M'],
  "groomed": ['G R UW1 M D'],
  "groomer": ['G R UW1 M ER0'],
  "groomers": ['G R UW1 M ER0 Z'],
  "groomes": ['G R UW1 M Z'],
  "grooming": ['G R UW1 M IH0 NG'],
  "grooms": ['G R UW1 M Z'],
  "groopman": ['G R UW1 P M AH0 N'],
  "groos": ['G R UW1 Z'],
  "groot": ['G R UW1 T'],
  "grooters": ['G R UW1 T ER0 Z'],
  "groove": ['G R UW1 V'],
  "groover": ['G R UW1 V ER0'],
  "grooves": ['G R UW1 V Z'],
  "grooviest": ['G R UW1 V IY0 AH0 S T'],
  "groovy": ['G R UW1 V IY0'],
  "grope": ['G R OW1 P'],
  "groped": ['G R OW1 P T'],
  "gropes": ['G R OW1 P S'],
  "groping": ['G R OW1 P IH0 NG'],
  "gropp": ['G R AA1 P'],
  "gropper": ['G R AA1 P ER0'],
  "groppy": ['G R AA1 P IY0'],
  "groppy's": ['G R AA1 P IY0 Z'],
  "gros": ['G R OW1 S'],
  "grosbeak": ['G R OW1 S B IY2 K'],
  "grosbeaks": ['G R OW1 S B IY2 K S'],
  "grosch": ['G R AO1 SH'],
  "grose": ['G R OW1 Z'],
  "groseclose": ['G R AA0 S IH0 K L OW1 Z'],
  "grosh": ['G R AA1 SH'],
  "groshek": ['G R AA1 SH IH0 K'],
  "groshong": ['G R AA1 SH AO0 NG'],
  "grosjean": ['G R AH0 S ZH IY1 N'],
  "groskopf": ['G R AA1 S K AO0 P F', 'G R OW1 S K AO0 F'],
  "gross": ['G R OW1 S'],
  "gross's": ['G R OW1 S IH0 Z'],
  "grossbard": ['G R OW1 S B AA2 R D'],
  "grossberg": ['G R OW1 S B ER0 G'],
  "grosse": ['G R AA1 S'],
  "grossed": ['G R OW1 S T'],
  "grossenbacher": ['G R AA1 S IH0 N B AA0 K ER0'],
  "grosser": ['G R OW1 S ER0'],
  "grosses": ['G R OW1 S IH0 Z'],
  "grossfeld": ['G R OW1 S F EH2 L D'],
  "grosshans": ['G R AA1 SH AH0 N Z'],
  "grossi": ['G R OW1 S IY0'],
  "grossing": ['G R OW1 S IH0 NG'],
  "grosskopf": ['G R OW1 S K AO0 P F', 'G R OW1 S K AO0 F'],
  "grossly": ['G R OW1 S L IY0'],
  "grossman": ['G R OW1 S M AH0 N'],
  "grossman's": ['G R OW1 S M AH0 N Z'],
  "grossmann": ['G R AO1 S M AH0 N'],
  "grossnickle": ['G R AA1 S N IH0 K AH0 L'],
  "grosso": ['G R OW1 S OW0'],
  "grosvenor": ['G R OW1 V N ER0'],
  "grosz": ['G R OW1 S'],
  "grote": ['G R OW1 T'],
  "grotesque": ['G R OW0 T EH1 S K'],
  "grotesquely": ['G R OW0 T EH1 S K L IY0'],
  "groth": ['G R AA1 TH'],
  "grothaus": ['G R AA1 T HH AW2 S'],
  "grothe": ['G R OW1 DH'],
  "grotheer": ['G R AO1 TH IH0 R'],
  "groton": ['G R AA1 T AH0 N'],
  "grotto": ['G R AA1 T OW2'],
  "grotz": ['G R AA1 T S'],
  "grouch": ['G R AW1 CH'],
  "groucho": ['G R UW1 CH OW0', 'G R AW1 CH OW0'],
  "grouchy": ['G R AW1 CH IY0'],
  "groulx": ['G R AW1 L K S'],
  "ground": ['G R AW1 N D'],
  "groundbreaking": ['G R AW1 N D B R EY2 K IH0 NG', 'G R AW1 N B R EY2 K IH0 NG'],
  "grounded": ['G R AW1 N D IH0 D'],
  "grounder": ['G R AW1 N D ER0'],
  "groundhog": ['G R AW1 N D HH AA2 G'],
  "grounding": ['G R AW1 N D IH0 NG'],
  "groundless": ['G R AW1 N D L AH0 S'],
  "groundling": ['G R AW1 N D L IH0 NG'],
  "groundnut": ['G R AW1 N D N AH2 T'],
  "groundnuts": ['G R AW1 N D N AH2 T S'],
  "grounds": ['G R AW1 N D Z', 'G R AW1 N Z'],
  "groundskeeper": ['G R AW1 N D S K IY2 P ER0'],
  "groundskeepers": ['G R AW1 N D S K IY2 P ER0 Z'],
  "groundswell": ['G R AW1 N D S W EH2 L'],
  "groundwater": ['G R AW1 N D W AA2 T ER0', 'G R AW1 N D W AO2 T ER0'],
  "groundwork": ['G R AW1 N D W ER2 K'],
  "group": ['G R UW1 P'],
  "group's": ['G R UW1 P S'],
  "groupe": ['G R UW1 P'],
  "grouped": ['G R UW1 P T'],
  "groupement": ['G R UW1 P M AH0 N T'],
  "grouper": ['G R UW1 P ER0'],
  "groupers": ['G R UW1 P ER0 Z'],
  "groupie": ['G R UW1 P IY0'],
  "groupies": ['G R UW1 P IY0 Z'],
  "grouping": ['G R UW1 P IH0 NG'],
  "groupings": ['G R UW1 P IH0 NG Z'],
  "groups": ['G R UW1 P S'],
  "groups'": ['G R UW1 P S'],
  "groupware": ['G R UW1 P W EH2 R'],
  "grouse": ['G R AW1 S'],
  "groused": ['G R AW1 S T'],
  "grouses": ['G R AW1 S IH0 Z'],
  "grousing": ['G R AW1 S IH0 NG'],
  "groussman": ['G R AW1 S M AH0 N'],
  "grout": ['G R AW1 T'],
  "grouting": ['G R AW1 T IH0 NG'],
  "grove": ['G R OW1 V'],
  "grove's": ['G R OW1 V Z'],
  "grovel": ['G R AA1 V AH0 L'],
  "groveling": ['G R AO1 V AH0 L IH0 NG', 'G R AO1 V L IH0 NG'],
  "groveman": ['G R OW1 V M AH0 N'],
  "grover": ['G R OW1 V ER0'],
  "grover's": ['G R OW1 V ER0 Z'],
  "grovers": ['G R OW1 V ER0 Z'],
  "groves": ['G R OW1 V Z'],
  "grow": ['G R OW1'],
  "growe": ['G R OW1'],
  "grower": ['G R OW1 ER0'],
  "growers": ['G R OW1 ER0 Z'],
  "growers'": ['G R OW1 ER0 Z'],
  "growing": ['G R OW1 IH0 NG'],
  "growl": ['G R AW1 L'],
  "growled": ['G R AW1 L D'],
  "growling": ['G R OW1 L IH0 NG'],
  "growls": ['G R AW1 L Z'],
  "grown": ['G R OW1 N'],
  "grown-up": ['G R OW1 N AH2 P'],
  "grown-ups": ['G R OW1 N AH2 P S'],
  "grownup": ['G R OW1 N AH2 P'],
  "grownups": ['G R OW1 N AH2 P S'],
  "grows": ['G R OW1 Z'],
  "growth": ['G R OW1 TH'],
  "growth's": ['G R OW1 TH S'],
  "growths": ['G R OW1 TH S'],
  "grozny": ['G R OW1 Z N IY0'],
  "grozny's": ['G R OW1 Z N IY0 Z'],
  "grua": ['G R UW1 AH0'],
  "grub": ['G R AH1 B'],
  "grub-thief": ['G R AH1 B TH IY2 F'],
  "grub-thieves": ['G R AH1 B TH IY2 V Z'],
  "gruba": ['G R UW1 B AH0'],
  "grubaugh": ['G R AH1 B AO0'],
  "grubb": ['G R AH1 B'],
  "grubbs": ['G R AH1 B Z'],
  "grubby": ['G R AH1 B IY0'],
  "grube": ['G R UW1 B'],
  "gruben": ['G R AH1 B AH0 N'],
  "gruber": ['G R UW1 B ER0'],
  "gruberova": ['G R UW2 B EH0 R OW1 V AH0'],
  "grubman": ['G R AH1 B M AH0 N'],
  "grubs": ['G R AH1 B Z'],
  "grubstein": ['G R AH1 B S T IY2 N', 'G R AH1 B S T AY2 N'],
  "grucci": ['G R UW1 CH IY0'],
  "grudenstein": ['G R UW1 D IH0 N S T IY2 N', 'G R UW1 D IH0 N S T AY2 N'],
  "grudge": ['G R AH1 JH'],
  "grudges": ['G R AH1 JH IH0 Z'],
  "grudging": ['G R AH1 JH IH0 NG'],
  "grudgingly": ['G R AH1 JH IH0 NG L IY0', 'G R AH1 JH IH0 NG G L IY0'],
  "grudzien": ['G R AH1 D Z IY0 N'],
  "grudzinski": ['G R AH0 JH IH1 N S K IY0'],
  "grue": ['G R UW1'],
  "gruel": ['G R UW1 IH0 L'],
  "grueling": ['G R UW1 IH0 L IH0 NG', 'G R UW1 L IH0 NG'],
  "gruen": ['G R UW1 N'],
  "gruenberg": ['G R UH1 N B ER0 G'],
  "gruener": ['G R UH1 N ER0'],
  "gruenewald": ['G R UH1 N IH0 W AO0 L D'],
  "gruenhagen": ['G R UH1 N HH AH0 G AH0 N'],
  "gruenwald": ['G R UH1 N W AO0 L D'],
  "gruesome": ['G R UW1 S AH0 M'],
  "gruet": ['G R UW1 IH0 T'],
  "gruetzmacher": ['G R UH1 T S M AA2 K ER0'],
  "gruff": ['G R AH1 F'],
  "gruffly": ['G R AH1 F L IY0'],
  "gruhlke": ['G R UW1 L K'],
  "gruhn": ['G R UW1 N'],
  "gruis": ['G R UW1 IH0 Z'],
  "grulke": ['G R AH1 L K'],
  "grullon": ['G R AH1 L AH0 N'],
  "grum": ['G R AH1 M'],
  "grumbine": ['G R AH1 M B AY2 N'],
  "grumble": ['G R AH1 M B AH0 L'],
  "grumbled": ['G R AH1 M B AH0 L D'],
  "grumbles": ['G R AH1 M B AH0 L Z'],
  "grumbling": ['G R AH1 M B AH0 L IH0 NG', 'G R AH1 M B L IH0 NG'],
  "grumblings": ['G R AH1 M B AH0 L IH0 NG Z', 'G R AH1 M B L IH0 NG Z'],
  "grumman": ['G R AH1 M AH0 N'],
  "grumman's": ['G R AH1 M AH0 N Z'],
  "grummond": ['G R AH1 M AH0 N D'],
  "grummond's": ['G R AH1 M AH0 N D Z'],
  "grump": ['G R AH1 M P'],
  "grumpier": ['G R AH1 M P IY0 ER0', 'G R AH1 M P Y ER0'],
  "grumpiness": ['G R AH1 M P IY0 N EH2 S'],
  "grumpy": ['G R AH1 M P IY0'],
  "grun": ['G R AH1 N'],
  "grunberg": ['G R AH1 N B ER0 G'],
  "grund": ['G R AH1 N D'],
  "grunden": ['G R AH1 N D AH0 N'],
  "grunder": ['G R AH1 N D ER0'],
  "grundfest": ['G R AH1 N D F EH2 S T', 'G R UH1 N D F EH2 S T'],
  "grundhofer": ['G R AH1 N D HH AA2 F ER0'],
  "grundig": ['G R AH1 N D IH0 G'],
  "grundman": ['G R AH1 N D M AH0 N'],
  "grundstrom": ['G R AH1 N D S T R AH0 M'],
  "grundy": ['G R AH1 N D IY0'],
  "grunebaum": ['G R UW1 N B AW2 M'],
  "gruneich": ['G R UW0 N AY1 K'],
  "gruner": ['G R UW1 N ER0'],
  "grunert": ['G R UW1 N ER0 T'],
  "grunewald": ['G R AH1 N IH0 W AO0 L D', 'G R UW1 N W AO0 L D'],
  "grunge": ['G R AH1 N JH'],
  "grungy": ['G R AH1 N JH IY0'],
  "grunion": ['G R AH1 N Y AH0 N'],
  "grunow": ['G R UW1 N OW0'],
  "grunt": ['G R AH1 N T'],
  "gruntal": ['G R AH1 N T AH0 L'],
  "gruntal's": ['G R AH1 N T AH0 L Z'],
  "grunted": ['G R AH1 N T IH0 D'],
  "grunting": ['G R AH1 N T IH0 NG'],
  "grunts": ['G R AH1 N T S'],
  "grunwald": ['G R AH1 N W AO2 L D'],
  "grupe": ['G R UW1 P'],
  "grupo": ['G R UW1 P OW0'],
  "grupp": ['G R AH1 P'],
  "gruppo": ['G R UW1 P OW0'],
  "grush": ['G R AH1 SH'],
  "grushow": ['G R AH1 SH OW0'],
  "gruss": ['G R AH1 S'],
  "grussing": ['G R AH1 S IH0 NG'],
  "gruszka": ['G R AH1 SH K AH0'],
  "grutman": ['G R AH1 T M AH0 N'],
  "gruver": ['G R UW1 V ER0'],
  "gruwell": ['G R AH1 W EH0 L'],
  "gruza": ['G R UW1 Z AH0'],
  "gryder": ['G R AY1 D ER0'],
  "grygiel": ['G R IH1 JH IY0 L'],
  "gryphons": ['G R IH1 F AH0 N Z'],
  "grzelak": ['G ER1 Z AH0 L AE2 K'],
  "grzesiak": ['G ER0 Z EH1 S IY0 AE0 K'],
  "grzeskowiak": ['G ER0 Z IH0 S K AW1 IY0 AE0 K'],
  "grzyb": ['G ER1 Z IH2 B'],
  "grzybowski": ['G ER2 Z IH0 B AW1 S K IY0'],
  "grzywacz": ['G ER1 Z IH0 V AE2 CH'],
  "grzywinski": ['G ER0 Z IH0 W IH1 N S K IY0'],
  "gschwind": ['G AH0 SH W IH1 N D'],
  "gsell": ['G AH0 S EH1 L'],
  "gtech": ['JH IY1 T EH2 K'],
  "gtech's": ['JH IY1 T EH2 K S'],
  "gu": ['G UW1'],
  "guacamole": ['G W AA2 K AH0 M OW1 L IY0'],
  "guadagno": ['G AA0 D AA1 G N OW0'],
  "guadalajara": ['G W AA2 D AH2 L AH0 HH AA1 R AH0', 'G W AA2 D L AH0 HH AA1 R AH0'],
  "guadalcanal": ['G W AA2 D AH0 L K AH0 N AE1 L'],
  "guadalupe": ['G W AA2 D AH0 L UW1 P', 'G W AA2 D AH0 L UW1 P EY0'],
  "guadarrama": ['G UW0 AA0 D AA0 R AA1 M AH0'],
  "guadeloupe": ['G W AA2 D AH0 L UW1 P'],
  "guagliardo": ['G W AA2 G L IY0 AA1 R D OW0'],
  "guajardo": ['G W AA0 Y AA1 R D OW0'],
  "gualdoni": ['G AA0 L D OW1 N IY0'],
  "gualtieri": ['G AA0 L T IH1 R IY0'],
  "guam": ['G W AA1 M'],
  "guam's": ['G W AA1 M Z'],
  "guanaco": ['G W AH0 N AA1 K OW2'],
  "guandjo": ['G W AA1 N JH OW1'],
  "guandjo's": ['G W AA1 N JH OW1 Z'],
  "guandjong": ['G W AA1 N JH OW1 NG'],
  "guandjong's": ['G W AA1 N JH OW1 NG Z'],
  "guandong": ['G W AA1 N D OW2 NG'],
  "guandong's": ['G W AA1 N D OW2 NG Z'],
  "guangdong": ['G W AE1 NG D AO1 NG', 'G W AA1 NG D AO1 NG'],
  "guangjo": ['G W AA1 NG JH OW2'],
  "guangjo's": ['G W AA1 NG JH OW2 Z'],
  "guangzhou": ['G W AE1 NG Z UW2'],
  "guanine": ['G W AA1 N IY2 N'],
  "guano": ['G W AA1 N OW2'],
  "guantanamo": ['G W AA2 N T AA1 N AH0 M OW2'],
  "guantanamo's": ['G W AA2 N T AA1 N AH0 M OW2 Z'],
  "guarani": ['G W AA2 R AH0 N IY1', 'G W AA2 R AA1 N IY0'],
  "guarantee": ['G EH2 R AH0 N T IY1'],
  "guarantee's": ['G EH2 R AH0 N T IY1 Z'],
  "guaranteed": ['G EH2 R AH0 N T IY1 D'],
  "guaranteeing": ['G EH2 R AH0 N T IY1 IH0 NG'],
  "guarantees": ['G EH2 R AH0 N T IY1 Z'],
  "guaranties": ['G EH2 R AH0 N T IY1 Z'],
  "guarantor": ['G EH2 R AH0 N T AO1 R'],
  "guarantors": ['G EH2 R AH0 N T AO1 R Z'],
  "guaranty": ['G EH2 R AH0 N T IY1'],
  "guaranty's": ['G EH2 R AH0 N T IY1 Z'],
  "guaranty-first": ['G EH2 R AH0 N T IY2 F ER1 S T'],
  "guard": ['G AA1 R D'],
  "guard's": ['G AA1 R D Z'],
  "guardado": ['G AA0 R D AA1 D OW0'],
  "guarded": ['G AA1 R D AH0 D', 'G AA1 R D IH0 D'],
  "guardedly": ['G AA1 R D IH0 D L IY0'],
  "guardfish": ['G AA1 R D F IH2 SH'],
  "guardfish's": ['G AA1 R D F IH2 SH IH0 Z'],
  "guardia": ['G W AA1 R D IY0 AH0'],
  "guardian": ['G AA1 R D IY0 AH0 N'],
  "guardian's": ['G AA1 R D IY0 AH0 N Z'],
  "guardians": ['G AA1 R D IY0 AH0 N Z'],
  "guardianship": ['G AA1 R D IY0 AH0 N SH IH0 P'],
  "guardin": ['G AA1 R D IH0 N'],
  "guarding": ['G AA1 R D IH0 NG'],
  "guardino": ['G AA0 R D IY1 N OW0'],
  "guardiola": ['G AA0 R D IY0 OW1 L AH0'],
  "guardrail": ['G AA1 R D R EY2 L'],
  "guardrails": ['G AA1 R D R EY2 L Z'],
  "guards": ['G AA1 R D Z'],
  "guardsman": ['G AA1 R D Z M AE2 N', 'G AA1 R D Z M AH0 N'],
  "guardsmen": ['G AA1 R D Z M IH0 N'],
  "guariglia": ['G AA0 R IY1 G L IY0 AH0'],
  "guarin": ['G W AA1 R IH0 N', 'G AA1 R IH0 N'],
  "guarini": ['G AA0 R IY1 N IY0'],
  "guarino": ['G AA0 R IY1 N OW0'],
  "guarisco": ['G AA0 R IY1 S K OW0'],
  "guarnaccia": ['G AA0 R N AE1 CH IY0 AH0'],
  "guarneri": ['G AA0 R N EH1 R IY0'],
  "guarnieri": ['G AA0 R N IH1 R IY0'],
  "guasch": ['G W AE1 SH'],
  "guastella": ['G AA0 S T EH1 L AH0'],
  "guatemala": ['G W AA2 T AH0 M AA1 L AH0'],
  "guatemala's": ['G W AA2 T AH0 M AA1 L AH0 Z'],
  "guatemalan": ['G W AA2 T AH0 M AA1 L AH0 N'],
  "guatemalans": ['G W AA2 T AH0 M AA1 L AH0 N Z'],
  "guattery": ['G W AA1 T ER0 IY0'],
  "guava": ['G W AA1 V AH0'],
  "guavas": ['G W AA1 V AH0 Z'],
  "guay": ['G EY1'],
  "guayabera": ['G W AY2 AH0 B EH1 R AH0'],
  "guba": ['G UW1 B AH0'],
  "guba's": ['G Y UW1 B AH0 Z'],
  "gubbels": ['G AH1 B AH0 L Z'],
  "gubbins": ['G AH1 B IH0 N Z'],
  "guber": ['G Y UW1 B ER0', 'G UW1 B ER0'],
  "guberman": ['G UW1 B ER0 M AH0 N'],
  "gubernatorial": ['G UW0 B ER2 N AH0 T AO1 R IY2 AH0 L'],
  "gubler": ['G Y UW1 B AH0 L ER0', 'G Y UW1 B L ER0'],
  "gubser": ['G AH1 B S ER0'],
  "gucci": ['G UW1 CH IY0'],
  "gucciardo": ['G UW0 CH AA1 R D OW0'],
  "guccio": ['G UW1 CH IY0 OW0'],
  "guccione": ['G UW0 K CH OW1 N IY0'],
  "guck": ['G AH1 K'],
  "guckert": ['G AH1 K ER0 T'],
  "gucwa": ['G AH1 K W AH0'],
  "gude": ['G Y UW1 D'],
  "gudelay": ['G UW1 D AH0 L EY2'],
  "guderian": ['G Y UW2 D IY1 R IY0 AH0 N'],
  "gudgel": ['G AH1 JH AH0 L'],
  "gudgeon": ['G AH1 JH AH0 N'],
  "gudger": ['G AH1 JH ER0'],
  "gudino": ['G UW0 D IY1 N OW0'],
  "gudmundson": ['G AH1 D M AH0 N D S AH0 N'],
  "gue": ['G Y UW1'],
  "guedry": ['G EH1 D R IY0'],
  "guei-yuan": ['G W EY1 Y UW0 AA1 N'],
  "guel": ['G EH1 L'],
  "guenette": ['G IH0 N EH1 T'],
  "guenna": ['G EY1 N AH0'],
  "guenter": ['G EH1 N T ER0'],
  "guenther": ['G UH1 N TH ER0'],
  "guenthner": ['G EH1 N TH N ER0'],
  "guerard": ['G ER0 AA1 R D'],
  "guercio": ['G EH1 R S IY0 OW0'],
  "guerette": ['G ER0 EH1 T'],
  "guerilla": ['G ER0 IH1 L AH0'],
  "guerillas": ['G ER0 IH1 L AH0 Z'],
  "guerin": ['G EH1 R IH0 N'],
  "guerino": ['G EH0 R IY1 N OW0'],
  "guerneville": ['G ER1 N AH0 V IH0 L'],
  "guernsey": ['G ER1 N Z IY0'],
  "guernseys": ['G ER1 N Z IY0 Z'],
  "guerra": ['G W EH1 R AH0'],
  "guerrant": ['G EH1 R AH0 N T'],
  "guerre": ['G EH1 R'],
  "guerrera": ['G W ER0 EH1 R AH0'],
  "guerrero": ['G ER0 EH1 R OW0'],
  "guerrette": ['G ER0 EH1 T'],
  "guerrier": ['G EH1 R IY0 ER0'],
  "guerrieri": ['G ER0 IH1 R IY0'],
  "guerriero": ['G ER0 IH1 R OW0'],
  "guerrilla": ['G ER0 IH1 L AH0'],
  "guerrillas": ['G ER0 IH1 L AH0 Z'],
  "guerrillas'": ['G ER0 IH1 L AH0 Z'],
  "guerrini": ['G ER0 IY1 N IY0'],
  "guerry": ['G ER0 IY1'],
  "guertin": ['G EH0 R T IY1 N'],
  "guess": ['G EH1 S'],
  "guessed": ['G EH1 S T'],
  "guesser": ['G EH1 S ER0'],
  "guessers": ['G EH1 S ER0 Z'],
  "guesses": ['G EH1 S AH0 Z', 'G EH1 S IH0 Z'],
  "guessing": ['G EH1 S IH0 NG'],
  "guesstimate": ['G EH1 S T IH0 M IH0 T', 'G EH1 S T IH0 M EY0 T'],
  "guesstimates": ['G EH1 S T IH0 M IH0 T S', 'G EH1 S T IH0 M EY0 T S'],
  "guesswork": ['G EH1 S W ER2 K'],
  "guest": ['G EH1 S T'],
  "guest's": ['G EH1 S T S'],
  "guested": ['G EH1 S T IH0 D'],
  "guesthouse": ['G EH1 S T HH AW2 S'],
  "guesthouses": ['G EH1 S T HH AW2 S IH0 Z'],
  "guests": ['G EH1 S T S'],
  "guests'": ['G EH1 S T S'],
  "guettler": ['G EH1 T AH0 L ER0', 'G EH1 T L ER0'],
  "guevara": ['G EY0 V AA1 R AH0', 'G AH0 V AA1 R AH0'],
  "guevara's": ['G EY0 V AA1 R AH0 Z', 'G AH0 V AA1 R AH0 Z'],
  "guez": ['G EH1 Z'],
  "guez's": ['G EH1 Z IH0 Z'],
  "guff": ['G AH1 F'],
  "guffaw": ['G AH0 F AO1'],
  "guffaws": ['G AH0 F AO1 Z'],
  "guffey": ['G AH1 F IY0'],
  "guffin": ['G AH1 F IH0 N'],
  "guffy": ['G AH1 F IY0'],
  "gugel": ['G UW1 G AH0 L'],
  "guggenheim": ['G UW1 G AH0 N HH AY2 M'],
  "guggenheim's": ['G UW1 G AH0 N HH AY2 M Z'],
  "guggisberg": ['G AH1 G IH0 S B ER0 G'],
  "gugino": ['G UW0 JH IY1 N OW0'],
  "guglielmetti": ['G UW0 G L IY0 EH0 L M EH1 T IY0'],
  "guglielmi": ['G UW0 G L IY0 EH1 L M IY0'],
  "guglielmo": ['G UW0 G L IY0 EH1 L M OW0'],
  "gugliotta": ['G UW0 G L IY0 OW1 T AH0'],
  "gugliotti": ['G UW0 G L IY0 OW1 T IY0'],
  "gugliuzza": ['G UW0 G L IY0 UW1 T S AH0'],
  "guhl": ['G AH1 L'],
  "guiana": ['G IY0 AA1 N AH0'],
  "guice": ['G AY1 S'],
  "guichard": ['G IH0 SH AA1 R D'],
  "guida": ['G IY1 D AH0'],
  "guidance": ['G AY1 D AH0 N S'],
  "guide": ['G AY1 D'],
  "guide's": ['G AY1 D Z'],
  "guidebook": ['G AY1 D B UH2 K'],
  "guidebooks": ['G AY1 D B UH2 K S'],
  "guided": ['G AY1 D AH0 D', 'G AY1 D IH0 D'],
  "guideline": ['G AY1 D L AY2 N'],
  "guidelines": ['G AY1 D L AY2 N Z'],
  "guidepost": ['G AY1 D P OW2 S T'],
  "guideposts": ['G AY1 D P OW2 S T S'],
  "guider": ['G AY1 D ER0'],
  "guidera": ['G IY0 D EH1 R AH0'],
  "guides": ['G AY1 D Z'],
  "guidi": ['G IY1 D IY0'],
  "guidice": ['G AY1 D IH0 S'],
  "guiding": ['G AY1 D IH0 NG'],
  "guido": ['G W IY1 D OW0'],
  "guidone": ['G IY0 D OW1 N IY0'],
  "guidotti": ['G IY0 D OW1 T IY0'],
  "guidroz": ['G W IY1 D R OW0 Z'],
  "guidry": ['G IH1 D ER0 IY0'],
  "guier": ['G AY1 ER0'],
  "guiffre": ['G IH1 F ER0'],
  "guilbault": ['G IH0 L B OW1'],
  "guilbeau": ['G IH0 L B OW1'],
  "guilbeault": ['G IH0 L B OW1'],
  "guilbeaux": ['G IH0 L B OW1'],
  "guilbert": ['G IH1 L B ER0 T'],
  "guild": ['G IH1 L D'],
  "guild's": ['G IH1 L D Z'],
  "guilder": ['G IH1 L D ER0'],
  "guilder's": ['G IH1 L D ER0 Z'],
  "guilders": ['G IH1 L D ER0 Z'],
  "guildhall": ['G IH1 L D HH AO2 L'],
  "guilds": ['G IH1 L D Z'],
  "guile": ['G AY1 L'],
  "guileless": ['G AY1 L L AH0 S'],
  "guiles": ['G AY1 L Z'],
  "guilfoil": ['G IH0 L F OY1 L'],
  "guilford": ['G IH1 L F ER0 D'],
  "guilford's": ['G IH1 L F ER0 D Z'],
  "guilfoyle": ['G IH1 L F OY0 L'],
  "guiliani": ['G IY2 L IY0 AA1 N IY0'],
  "guiliano": ['G IY2 L IY0 AA1 N OW0'],
  "guilin": ['G IH1 L IH0 N'],
  "guill": ['G IH1 L'],
  "guilla": ['G W IH1 L AH0'],
  "guillaume": ['G IH2 L AW1 M', 'G W IY2 L OW1 M'],
  "guillemette": ['G IH1 L AH0 M EH2 T'],
  "guillen": ['G IH1 L AH0 N'],
  "guillermo": ['G W IH0 L Y EH1 R M OW0', 'G IY0 EH1 R M OW0', 'G W IY0 EH1 R M OW0'],
  "guillet": ['G IH0 L EH1 T'],
  "guillette": ['G IH0 L EH1 T'],
  "guilliams": ['G W IY0 L IY1 AA0 M Z'],
  "guillory": ['G IH1 L ER0 IY0'],
  "guillot": ['G IH0 L OW1'],
  "guillotine": ['G IH1 L AH0 T IY2 N', 'G IY1 Y AH0 T IY2 N'],
  "guillotte": ['G IH0 L AO1 T'],
  "guilmartin": ['G IH0 L M AA1 R T IH0 N'],
  "guilmette": ['G IH0 L M EH1 T'],
  "guilt": ['G IH1 L T'],
  "guiltless": ['G IH1 L T L IH0 S'],
  "guilty": ['G IH1 L T IY0'],
  "guimaraes": ['G IH1 M ER0 EY2 Z'],
  "guimond": ['G IH0 M AA1 N D'],
  "guimont": ['G IH0 M AA1 N T'],
  "guin": ['G IH1 N'],
  "guinan": ['G W IY0 N AA1 N'],
  "guinarou": ['G W IH0 N AA1 R OW2'],
  "guinarou's": ['G W IH0 N AA1 R OW2 Z'],
  "guindon": ['G IH1 N D AH0 N'],
  "guinea": ['G IH1 N IY0'],
  "guineas": ['G IH1 N IY0 Z'],
  "guiness": ['G IH1 N AH0 S'],
  "guiney": ['G IH1 N IY0'],
  "guinier": ['G W IH0 N IH1 R'],
  "guinier's": ['G W IH0 N IH1 R Z'],
  "guinn": ['G IH1 N'],
  "guinness": ['G IH1 N AH0 S'],
  "guinness's": ['G IH1 N AH0 S IH0 Z'],
  "guinta": ['G W IY1 N T AH0'],
  "guinther": ['G IH1 N DH ER0'],
  "guinto": ['G W IY1 N T OW0'],
  "guinyard": ['G IH0 N Y AA1 R D'],
  "guion": ['G W IY0 AO1 N'],
  "guire": ['G AY1 R'],
  "guise": ['G AY1 Z'],
  "guises": ['G AY1 Z AH0 Z', 'G AY1 Z IH0 Z'],
  "guisewite": ['G AY1 S W AY2 T'],
  "guisinger": ['G IH1 S IH0 N JH ER0'],
  "guitar": ['G IH0 T AA1 R'],
  "guitarist": ['G IH2 T AA1 R IH0 S T'],
  "guitarist's": ['G IH2 T AA1 R IH0 S T S'],
  "guitarists": ['G IH2 T AA1 R IH0 S T S'],
  "guitarro": ['G IH0 T AA1 R OW0'],
  "guitarro's": ['G IH0 T AA1 R OW0 Z'],
  "guitars": ['G IH0 T AA1 R Z'],
  "guiterrez": ['G W IY0 T EH1 R EH0 Z'],
  "guitierrez": ['G W IY0 T IH1 R EH0 Z'],
  "guizar": ['G W IY0 Z AA1 R'],
  "gujarat": ['G Y UW2 JH ER0 AE1 T'],
  "gul": ['G AH1 L'],
  "gula": ['G Y UW1 L AH0'],
  "gulag": ['G Y UW1 L AE0 G', 'G UW1 L AA2 G'],
  "gulas": ['G Y UW1 L AH0 Z'],
  "gulbrandsen": ['G AH1 L B R AH0 N D S AH0 N'],
  "gulbrandson": ['G AH1 L B R AH0 N D S AH0 N'],
  "gulbransen": ['G AH1 L B R AH0 N S AH0 N'],
  "gulbranson": ['G AH1 L B R AH0 N S AH0 N'],
  "gulbuddin": ['G AH2 B UW1 D IH0 N'],
  "gulbuddin's": ['G AH2 B UW1 D IH0 N Z'],
  "gulch": ['G AH1 L CH'],
  "guldin": ['G AH1 L D IH0 N'],
  "gulf": ['G AH1 L F'],
  "gulf's": ['G AH1 L F S'],
  "gulfport": ['G AH1 L F P AO0 R T'],
  "gulfs": ['G AH1 L F S'],
  "gulfstream": ['G AH1 L F S T R IY2 M'],
  "gulick": ['G Y UW1 L IH0 K'],
  "gulino": ['G UW0 L IY1 N OW0'],
  "gull": ['G AH1 L'],
  "gull's": ['G AH1 L Z'],
  "gulla": ['G AH1 L AH0'],
  "gullah": ['G AH1 L AH0'],
  "gullatt": ['G AH1 L AH0 T'],
  "gulledge": ['G UW1 L IH0 JH'],
  "gullet": ['G AH1 L AH0 T'],
  "gullett": ['G UW1 L IH0 T'],
  "gullette": ['G UW0 L EH1 T'],
  "gulley": ['G AH1 L IY0'],
  "gulli": ['G UW1 L IY0'],
  "gullibility": ['G AH2 L AH0 B IH1 L IH0 T IY0'],
  "gullible": ['G AH1 L AH0 B AH0 L'],
  "gullick": ['G AH1 L IH0 K'],
  "gullickson": ['G AH1 L IH0 K S AH0 N'],
  "gullies": ['G AH1 L IY0 Z'],
  "gulliford": ['G AH1 L IH0 F ER0 D'],
  "gullikson": ['G AH1 L IH0 K S AH0 N'],
  "gullion": ['G AH1 L Y AH0 N'],
  "gulliver": ['G AH1 L IH0 V ER0'],
  "gullo": ['G UW1 L OW0'],
  "gulls": ['G AH1 L Z'],
  "gully": ['G AH1 L IY0'],
  "gulp": ['G AH1 L P'],
  "gulped": ['G AH1 L P T'],
  "gulping": ['G AH1 L P IH0 NG'],
  "gulps": ['G AH1 L P S'],
  "gulyas": ['G UW1 L Y AH0 S'],
  "gum": ['G AH1 M'],
  "gumaer": ['G AH1 M AW0 ER0', 'G AH1 M AA2 R'],
  "gumbel": ['G AH1 M B AH0 L'],
  "gumbert": ['G AH1 M B ER0 T'],
  "gumbi": ['G AH1 M B IY0'],
  "gumbi's": ['G AH1 M B IY0 Z'],
  "gumbiner": ['G AH1 M B AY2 N ER0'],
  "gumbinger": ['G AH1 M B IH2 NG ER0'],
  "gumbo": ['G AH1 M B OW0'],
  "gumbo-limbo": ['G AH1 M B OW0 L IH1 M B OW0'],
  "gumbs": ['G AH1 M Z'],
  "gumi": ['G UW1 M IY0'],
  "gumina": ['G UW0 M IY1 N AH0'],
  "gumm": ['G AH1 M'],
  "gummed": ['G AH1 M D'],
  "gummi": ['G AH1 M IY0'],
  "gummy": ['G AH1 M IY0'],
  "gump": ['G AH1 M P'],
  "gump's": ['G AH1 M P S'],
  "gumpert": ['G AH1 M P ER2 T'],
  "gumport": ['G AH1 M P AO2 R T'],
  "gumpp": ['G AH1 M P'],
  "gumption": ['G AH1 M P SH AH0 N'],
  "gums": ['G AH1 M Z'],
  "gumshoe": ['G AH1 M SH UW2'],
  "gumucio": ['G Y UW2 M Y UW1 S IY0 OW0'],
  "gumz": ['G AH1 M Z'],
  "gun": ['G AH1 N'],
  "gun's": ['G AH1 N Z'],
  "gunadi": ['G AH0 N AA1 D IY0'],
  "gunatilake": ['G UW0 N AA2 T IH2 L AA1 K EY2'],
  "gunboat": ['G AH1 N B OW2 T'],
  "gunboats": ['G AH1 N B OW2 T S'],
  "gunby": ['G AH1 N B IY0'],
  "guncotton": ['G AH1 N K AA1 T AH0 N'],
  "gundel": ['G AH1 N D AH0 L'],
  "gunder": ['G AH1 N D ER0'],
  "gunderman": ['G AH1 N D ER0 M AH0 N'],
  "gundersen": ['G AH1 N D ER0 S AH0 N'],
  "gunderson": ['G AH1 N D ER0 S AH0 N'],
  "gundlach": ['G AH1 N D L AH0 K'],
  "gundle": ['G AH1 N D AH0 L'],
  "gundog": ['G AH1 N D AO2 G'],
  "gundrum": ['G AH1 N D R AH0 M'],
  "gundry": ['G AH1 N D ER0 IY0'],
  "gundy": ['G AH1 N D IY0'],
  "gunfight": ['G AH1 N F AY2 T'],
  "gunfighter": ['G AH1 N F AY2 T ER0'],
  "gunfighter's": ['G AH1 N F AY2 T ER0 Z'],
  "gunfighters": ['G AH1 N F AY2 T ER0 Z'],
  "gunfighting": ['G AH1 N F AY2 T IH0 NG'],
  "gunfights": ['G AH1 N F AY2 T S'],
  "gunfire": ['G AH1 N F AY2 ER0', 'G AH1 N F AY2 R'],
  "gunflint": ['G AH1 N F L IH0 N T'],
  "gung": ['G AH1 NG'],
  "gunhilda": ['G UW0 N HH IY1 L D AH0'],
  "gunia": ['G UW1 N IY0 AH0'],
  "gunkel": ['G AH1 NG K AH0 L'],
  "gunlick": ['G AH1 N L IH0 K'],
  "gunlicks": ['G AH1 N L IH0 K S'],
  "gunman": ['G AH1 N M AH0 N'],
  "gunman's": ['G AH1 N M AH0 N Z'],
  "gunmen": ['G AH1 N M EH2 N'],
  "gunn": ['G AH1 N'],
  "gunn's": ['G AH1 N Z'],
  "gunnar": ['G AH1 N ER0'],
  "gunnarson": ['G AH1 N AA0 R S AH0 N'],
  "gunned": ['G AH1 N D'],
  "gunnell": ['G AH1 N AH0 L'],
  "gunnells": ['G AH1 N AH0 L Z'],
  "gunnels": ['G AH1 N AH0 L Z'],
  "gunner": ['G AH1 N ER0'],
  "gunners": ['G AH1 N ER0 Z'],
  "gunnerson": ['G AH1 N ER0 S AH0 N'],
  "gunnery": ['G AH1 N ER0 IY0'],
  "gunning": ['G AH1 N IH0 NG'],
  "gunnison": ['G AH1 N IH0 S AH0 N'],
  "gunnoe": ['G AH1 N OW0'],
  "gunny": ['G AH1 N IY0'],
  "gunnysack": ['G AH1 N IY0 S AE2 K'],
  "gunnysacks": ['G AH1 N IY0 S AE2 K S'],
  "gunpoint": ['G AH1 N P OY2 N T'],
  "gunpowder": ['G AH1 N P AW2 D ER0'],
  "gunrunner": ['G AH1 N R AH2 N ER0'],
  "guns": ['G AH1 N Z'],
  "gunsalus": ['G AH0 N S AA1 L AH0 S'],
  "gunship": ['G AH1 N SH IH2 P'],
  "gunships": ['G AH1 N SH IH2 P S'],
  "gunshot": ['G AH1 N SH AA2 T'],
  "gunshots": ['G AH1 N SH AA2 T S'],
  "gunslinger": ['G AH1 N S L IH2 NG ER0'],
  "gunslingers": ['G AH1 N S L IH2 NG ER0 Z'],
  "gunsmoke": ['G AH1 N S M OW2 K'],
  "gunst": ['G AH1 N S T'],
  "gunter": ['G AH1 N T ER0'],
  "gunther": ['G AH1 N TH ER0'],
  "guntur": ['G AH1 N T ER0'],
  "gunty": ['G AH1 N T IY0'],
  "guppies": ['G AH1 P IY0 Z'],
  "guppy": ['G AH1 P IY0'],
  "gupta": ['G UH1 P T AH0'],
  "guptill": ['G AH1 P T IH0 L'],
  "gupton": ['G AH1 P T AH0 N'],
  "gura": ['G UH1 R AH0'],
  "gural": ['G Y UH1 R AH0 L'],
  "gurevich": ['G Y UW1 R IH0 V IH0 CH'],
  "gurganious": ['G ER1 G AH0 N IY0 IH0 S'],
  "gurganus": ['G ER1 G AH0 N IH0 S'],
  "gurgle": ['G ER1 G AH0 L'],
  "gurgling": ['G ER1 G AH0 L IH0 NG', 'G ER1 G L IH0 NG'],
  "gurian": ['G Y UH1 R IY0 AH0 N'],
  "gurion": ['G Y UH1 R IY0 AH0 N'],
  "gurit": ['G Y UH1 R IH0 T', 'G UH1 R IH0 T'],
  "gurka": ['G ER1 K AH0'],
  "gurkin": ['G ER1 K IH0 N'],
  "gurley": ['G ER1 L IY0'],
  "gurnards": ['G ER1 N ER0 D Z'],
  "gurnee": ['G ER0 N IY1'],
  "gurney": ['G ER1 N IY0'],
  "gurney's": ['G ER1 N IY0 Z'],
  "gurnsey": ['G ER1 N S IY0'],
  "gurr": ['G ER1'],
  "gurria": ['G ER1 IY0 AH0'],
  "gurrola": ['G UH0 R OW1 L AH0'],
  "gurry": ['G ER1 IY0'],
  "gurski": ['G ER1 S K IY0'],
  "gursky": ['G ER1 S K IY0'],
  "gurtler": ['G ER1 T AH0 L ER0', 'G ER1 T L ER0'],
  "gurtner": ['G ER1 T N ER0'],
  "gurtz": ['G ER1 T S'],
  "guru": ['G UW1 R UW2'],
  "guru's": ['G UW1 R UW2 Z'],
  "gurule": ['G ER0 UW1 L'],
  "gurus": ['G UW1 R UW2 Z'],
  "gus": ['G AH1 S'],
  "gusciora": ['G UW2 S IY0 AO1 R AH0'],
  "guse": ['G Y UW1 Z'],
  "gusella": ['G Y UW2 S EH1 L AH0'],
  "guseman": ['G UW1 S M AH0 N'],
  "guses": ['G Y UW1 Z IH0 Z', 'G AH1 S AH0 Z'],
  "gush": ['G AH1 SH'],
  "gushed": ['G AH1 SH T'],
  "gusher": ['G AH1 SH ER0'],
  "gushers": ['G AH1 SH ER0 Z'],
  "gushes": ['G AH1 SH IH0 Z'],
  "gushing": ['G AH1 SH IH0 NG'],
  "gusinsky": ['G AH0 S IH1 N S K IY0'],
  "gusky": ['G AH1 S K IY0'],
  "guslan": ['G UW1 Z L AA2 N'],
  "gusler": ['G AH1 S AH0 L ER0', 'G AH1 S L ER0'],
  "gusman": ['G AH1 S M AH0 N'],
  "guss": ['G AH1 S'],
  "gussie": ['G AH1 S IY0'],
  "gussied": ['G AH1 S IY0 D'],
  "gussman": ['G AH1 S M AH0 N'],
  "gussy": ['G AH1 S IY0'],
  "gust": ['G AH1 S T'],
  "gustaf": ['G UW1 S T AA0 F'],
  "gustafson": ['G AH1 S T AH0 F S AH0 N'],
  "gustafsson": ['G UW1 S T AA0 F S AH0 N'],
  "gustav": ['G UH1 S T AA0 F'],
  "gustava": ['G UW0 S T AA1 V AH0'],
  "gustave": ['G UW0 S T AA1 V IY0'],
  "gustaveson": ['G UW0 S T AA0 V EY1 S AH0 N'],
  "gustavo": ['G AH0 S T AA1 V OW0'],
  "gustavson": ['G AH1 S T AH0 V S AH0 N'],
  "gustavus": ['G AH1 S T AH0 V AH0 S'],
  "guste": ['G AH1 S T'],
  "gusted": ['G AH1 S T IH0 D'],
  "guster": ['G AH1 S T ER0'],
  "gustin": ['G AH1 S T IH0 N'],
  "gusting": ['G AH1 S T IH0 NG'],
  "gusto": ['G AH1 S T OW2'],
  "guston": ['G AH1 S T AH0 N'],
  "gusts": ['G AH1 S T S'],
  "gustus": ['G AH1 S T AH0 S'],
  "gusty": ['G AH1 S T IY0'],
  "gut": ['G AH1 T'],
  "gutekunst": ['G AH1 T IH0 K AH0 N S T'],
  "gutenberg": ['G UW1 T AH0 N B ER0 G'],
  "guterman": ['G Y UW1 T ER0 M AH0 N'],
  "gutermuth": ['G AH1 T ER0 M UW0 TH'],
  "gutfeld": ['G AH1 T F EH2 L D'],
  "gutfreund": ['G UH1 T F R EH2 N D'],
  "guth": ['G UW1 TH'],
  "gutherie": ['G AH1 TH ER0 IY0'],
  "guthery": ['G AH1 TH ER0 IY0'],
  "guthmiller": ['G AH1 TH M IH0 L ER0'],
  "guthridge": ['G AH1 TH R IH0 JH'],
  "guthrie": ['G AH1 TH R IY0'],
  "guthrie's": ['G AH1 TH R IY0 Z'],
  "gutierez": ['G UW0 T IH1 R EH0 Z'],
  "gutierres": ['G UW0 T Y EH1 R EH0 Z'],
  "gutierrez": ['G UW0 T Y EH1 R EH0 Z'],
  "gutknecht": ['G UW1 T N EH0 K T'],
  "gutkowski": ['G AH0 T K AO1 F S K IY0'],
  "gutless": ['G AH1 T L EH0 S'],
  "gutman": ['G AH1 T M AH0 N'],
  "gutmann": ['G AH1 T M AH0 N'],
  "gutowski": ['G AH0 T AO1 F S K IY0'],
  "gutridge": ['G AH1 T R IH2 JH'],
  "guts": ['G AH1 T S'],
  "gutshall": ['G AH1 CH AH0 L'],
  "gutsy": ['G AH1 T S IY1'],
  "gutt": ['G AH1 T'],
  "gutted": ['G AH1 T IH0 D'],
  "guttenberg": ['G AH1 T AH0 N B ER0 G'],
  "gutter": ['G AH1 T ER0'],
  "guttered": ['G AH1 T ER0 D'],
  "guttering": ['G AH1 T ER0 IH0 NG'],
  "gutterman": ['G AH1 T ER0 M AH0 N'],
  "gutters": ['G AH1 T ER0 Z'],
  "gutting": ['G AH1 T IH0 NG'],
  "guttmacher": ['G AH1 T M AA2 K ER0'],
  "guttman": ['G AH1 T M AH0 N'],
  "guttmann": ['G AH1 T M AH0 N'],
  "guttural": ['G AH1 T ER0 AH0 L'],
  "gutwaks": ['G AH1 T W AA2 K S'],
  "gutwein": ['G AH1 T W AY2 N'],
  "gutzman": ['G AH1 T S M AH0 N'],
  "gutzmer": ['G AH1 T S M ER0'],
  "gutzwiller": ['G AH1 T S W IH2 L ER0'],
  "guy": ['G AY1'],
  "guy's": ['G AY1 Z'],
  "guyana": ['G AY2 AA1 N AH0'],
  "guyer": ['G AY1 ER0'],
  "guyett": ['G AY2 EH1 T'],
  "guyette": ['G AY2 EH1 T'],
  "guymon": ['G AY1 M AH0 N'],
  "guynes": ['G AY1 N Z'],
  "guynn": ['G AY1 N'],
  "guyon": ['G AY1 AH0 N'],
  "guyot": ['G AY1 AH0 T'],
  "guys": ['G AY1 Z'],
  "guys'": ['G AY1 Z'],
  "guyton": ['G AY1 T AH0 N'],
  "guza": ['G Y UW1 Z AH0'],
  "guzek": ['G UW1 Z EH0 K'],
  "guzik": ['G Y UW1 Z IH0 K'],
  "guzman": ['G AH1 Z M AH0 N', 'G UW1 Z M AA2 N'],
  "guzowski": ['G AH0 Z AO1 F S K IY0'],
  "guzy": ['G Y UW1 Z IY0'],
  "guzzardo": ['G UW0 T S AA1 R D OW0'],
  "guzzetta": ['G UW0 T S EH1 T AH0'],
  "guzzetti": ['G Y UW0 Z EH1 T IY0'],
  "guzzi": ['G UW1 T S IY0'],
  "guzzle": ['G AH1 Z AH0 L'],
  "guzzler": ['G AH1 Z L ER0'],
  "guzzler's": ['G AH1 Z AH0 L ER0 Z', 'G AH1 Z L ER0 Z'],
  "guzzlers": ['G AH1 Z AH0 L ER0 Z', 'G AH1 Z L ER0 Z'],
  "guzzles": ['G AH1 Z AH0 L Z'],
  "guzzling": ['G AH1 Z AH0 L IH0 NG', 'G AH1 Z L IH0 NG'],
  "guzzo": ['G UW1 Z OW0'],
  "gvaryahu": ['G AH0 V EH0 R Y AA1 HH UW0'],
  "gwaltney": ['G W AO1 L T N IY0'],
  "gwartney": ['G W AO1 R T N IY0'],
  "gway": ['G W EY1', 'JH IY1 W EY2'],
  "gwen": ['G W EH1 N'],
  "gwenda": ['G W EH1 N D AH0'],
  "gwendolyn": ['G W EH1 N D AH0 L IH0 N'],
  "gwennie": ['G W EH1 N IY0'],
  "gwenore": ['G W EH1 N ER0'],
  "gwin": ['G W IH1 N'],
  "gwinn": ['G W IH1 N'],
  "gwinn's": ['G W IH1 N Z'],
  "gwinner": ['G W IH1 N ER0'],
  "gwinnett": ['G W IH0 N EH1 T'],
  "gwizdala": ['G W IH0 Z D AA1 L AH0'],
  "gwozdz": ['G W AA1 Z D Z'],
  "gwyn": ['G W IH1 N'],
  "gwyneth": ['G W IH1 N AH0 TH'],
  "gwyneth's": ['G W IH1 N AH0 TH S'],
  "gwyneths": ['G W IH1 N AH0 TH S'],
  "gwynn": ['G W IH1 N'],
  "gwynne": ['G W IH1 N'],
  "gyger": ['G AY1 G ER0'],
  "gyi": ['G IY1', 'JH IY1 W AY1 AY1'],
  "gyles": ['JH AY1 L Z'],
  "gyllenhammar": ['JH IH1 L EH0 N HH AE2 M ER0'],
  "gym": ['JH IH1 M'],
  "gym's": ['JH IH1 M Z'],
  "gymboree": ['JH IH2 M B AO0 R IY1'],
  "gymnasia": ['JH IH0 M N EY1 Z IY0 AH0'],
  "gymnasium": ['JH IH0 M N EY1 Z IY0 AH0 M'],
  "gymnasiums": ['JH IH0 M N EY1 Z IY0 AH0 M Z'],
  "gymnast": ['JH IH1 M N AH0 S T'],
  "gymnastic": ['JH IH0 M N AE1 S T IH0 K'],
  "gymnastics": ['JH IH0 M N AE1 S T IH0 K S'],
  "gymnasts": ['JH IH1 M N AE0 S T S'],
  "gyms": ['JH IH1 M Z'],
  "gyn": ['G IH1 N', 'G AY1 N'],
  "gynecologic": ['G AY2 N AH0 K AH0 L AA1 JH IH0 K'],
  "gynecological": ['G AY2 N AH0 K AH0 L AA1 JH IH0 K AH0 L'],
  "gynecologist": ['G AY2 N AH0 K AA1 L AH0 JH AH0 S T'],
  "gynecologists": ['G AY2 N AH0 K AA1 L AH0 JH AH0 S T S'],
  "gynecology": ['G AY2 N AH0 K AA1 L AH0 JH IY0'],
  "gynex": ['JH IH1 N AH0 K S'],
  "gyohten": ['G Y OW1 T AH0 N'],
  "gyosai": ['G Y OW1 S EY2'],
  "gyp": ['JH IH1 P'],
  "gypped": ['JH IH1 P T'],
  "gypsies": ['JH IH1 P S IY0 Z'],
  "gypsum": ['JH IH1 P S AH0 M'],
  "gypsum's": ['JH IH1 P S AH0 M Z'],
  "gypsy": ['JH IH1 P S IY0'],
  "gyr": ['JH AY1 R'],
  "gyrate": ['JH AY1 R EY2 T'],
  "gyrated": ['JH AY1 R EY2 T IH0 D'],
  "gyrates": ['JH AY1 R EY2 T S'],
  "gyrating": ['JH AY1 R EY2 T IH0 NG'],
  "gyration": ['JH AY0 R EY1 SH AH0 N'],
  "gyrations": ['JH AY0 R EY1 SH AH0 N Z'],
  "gyro": ['JH AY1 R OW2'],
  "gyrocompass": ['JH AY1 R OW0 K AH2 M P AH0 S'],
  "gyrodyne": ['JH IH1 R AH0 D AY0 N'],
  "gyrodynes": ['JH IH1 R AH0 D AY0 N Z'],
  "gyropilot": ['JH AY1 R OW0 P AY2 L AH0 T'],
  "gyros": ['JH AY1 R OW2 Z'],
  "gyroscope": ['JH AY1 R AH0 S K OW2 P'],
  "gyroscopes": ['JH AY1 R AH0 S K OW2 P S'],
  "gyroscopic": ['JH AY2 R AH0 S K AA1 P IH0 K'],
  "gytha": ['JH AY1 DH AH0'],
  "gyuhama": ['G Y UW2 HH AA1 M AH0'],
  "h": ['EY1 CH'],
  "h's": ['EY1 CH IH0 Z'],
  "h.": ['EY1 CH'],
  "h.'s": ['EY1 CH IH0 Z'],
  "ha": ['HH AA1'],
  "ha'aretz": ['HH AA1 R EH0 T S', 'HH AH0 AA1 R EH0 T S'],
  "ha'etzni": ['HH AH0 EH1 T S N IY0'],
  "ha-ha": ['HH AA2 HH AA1'],
  "ha-ha-ha": ['HH AA2 HH AA2 HH AA1'],
  "haab": ['HH AA1 B'],
  "haack": ['HH AA1 K'],
  "haacke": ['HH AA1 K'],
  "haaf": ['HH AA1 F'],
  "haag": ['HH AA1 G'],
  "haagen": ['HH AA1 G AH0 N'],
  "haagenson": ['HH AA1 G IH0 N S AH0 N'],
  "haak": ['HH AA1 K'],
  "haake": ['HH AA1 K'],
  "haakenson": ['HH AA1 K IH0 N S AH0 N'],
  "haaland": ['HH AA1 L AH0 N D'],
  "haan": ['HH AA1 N'],
  "haapala": ['HH AA2 P AA1 L AH0'],
  "haar": ['HH AA1 R'],
  "haas": ['HH AA1 S'],
  "haase": ['HH AA1 S'],
  "haass": ['HH AA1 S'],
  "haavelmo": ['HH AA2 V EH1 L M OW0'],
  "habben": ['HH AE1 B AH0 N'],
  "habeas": ['HH AE1 B IY0 AH0 S'],
  "habeck": ['HH AA1 B EH0 K'],
  "habecker": ['HH AE1 B EH0 K ER0'],
  "habeeb": ['HH AE1 B IY0 B'],
  "habegger": ['HH AE1 B IH0 G ER0'],
  "habel": ['HH AE1 B AH0 L'],
  "habenicht": ['HH AE1 B IH0 N IH0 K T'],
  "haber": ['HH EY1 B ER0'],
  "haberdashery": ['HH AE1 B ER0 D AE2 SH ER0 IY0'],
  "haberer": ['HH AE1 B ER0 ER0'],
  "haberkorn": ['HH AE1 B ER0 K ER0 N'],
  "haberl": ['HH AE1 B ER0 L'],
  "haberland": ['HH AE1 B ER0 L AH0 N D'],
  "haberle": ['HH AE1 B ER0 AH0 L'],
  "haberman": ['HH EY1 B ER0 M AH0 N'],
  "habermann": ['HH EY1 B ER0 M AH0 N'],
  "habermas": ['HH AA1 B ER0 M AA2 S'],
  "habermehl": ['HH AE1 B ER0 M AH0 L'],
  "haberson": ['HH EY1 B ER0 S IH0 N', 'HH AE1 B ER0 S IH0 N'],
  "haberstroh": ['HH AA0 B EH1 R S T R OW0'],
  "habib": ['HH AH0 B IY1 B'],
  "habibi": ['HH AH0 B IY1 B IY0'],
  "habibie": ['HH AH0 B IY1 B IY0'],
  "habich": ['HH AE1 B IH0 K'],
  "habicht": ['HH AE1 B IH0 K T'],
  "habig": ['HH AE1 B IH0 G'],
  "habiger": ['HH AE1 B IH0 G ER0'],
  "habit": ['HH AE1 B AH0 T'],
  "habitable": ['HH AE1 B AH0 T AH0 B AH0 L'],
  "habitat": ['HH AE1 B AH0 T AE2 T'],
  "habitat's": ['HH AE1 B AH0 T AE2 T S'],
  "habitation": ['HH AE2 B AH0 T EY1 SH AH0 N'],
  "habitats": ['HH AE1 B AH0 T AE2 T S'],
  "habits": ['HH AE1 B AH0 T S'],
  "habitual": ['HH AH0 B IH1 CH UW0 AH0 L'],
  "habitually": ['HH AH0 B IH1 CH UW0 AH0 L IY0', 'HH AH0 B IH1 CH UW0 L IY0'],
  "habitues": ['HH AE1 B IH0 CH UW0 Z'],
  "hable": ['HH EY1 B AH0 L'],
  "habs": ['HH AE1 B Z'],
  "habsburg": ['HH AE1 P S B ER0 G'],
  "haby": ['HH EY1 B IY0'],
  "habyarimana": ['HH AE2 B IY0 ER0 IY0 M AA1 N AA2', 'HH AE2 B IY0 AA0 R IY0 M AA1 N AH0'],
  "hach": ['HH AE1 CH'],
  "hachette": ['HH AH0 SH EH1 T'],
  "hachey": ['HH AE1 CH IY0'],
  "hachtel": ['HH AE1 K T AH0 L'],
  "hacienda": ['HH AE2 S IY0 EH1 N D AH0'],
  "hack": ['HH AE1 K'],
  "hackathon": ['HH AE1 K AH0 TH AO2 N'],
  "hackathorn": ['HH AE1 K AH0 TH ER2 N'],
  "hackbart": ['HH AE1 K B AA2 R T'],
  "hackbarth": ['HH AE1 K B AA2 R TH'],
  "hackberry": ['HH AE1 K B EH2 R IY0'],
  "hacke": ['HH AE1 K'],
  "hacked": ['HH AE1 K T'],
  "hackel": ['HH AE1 K AH0 L'],
  "hackenberg": ['HH AE1 K AH0 N B ER0 G'],
  "hackensack": ['HH AE1 K AH0 N S AE2 K'],
  "hacker": ['HH AE1 K ER0'],
  "hacker's": ['HH AE1 K ER0 Z'],
  "hackers": ['HH AE1 K ER0 Z'],
  "hackert": ['HH AE1 K ER0 T'],
  "hackett": ['HH AE1 K IH0 T'],
  "hackford": ['HH AE1 K F ER0 D'],
  "hacking": ['HH AE1 K IH0 NG'],
  "hackl": ['HH AE1 K AH0 L'],
  "hackle": ['HH AE1 K AH0 L'],
  "hackleman": ['HH AE1 K AH0 L M AH0 N'],
  "hackler": ['HH AE1 K AH0 L ER0', 'HH AE1 K L ER0'],
  "hackles": ['HH AE1 K AH0 L Z'],
  "hackley": ['HH AE1 K L IY0'],
  "hackman": ['HH AE1 K M AE2 N', 'HH AE1 K M AH0 N'],
  "hackmann": ['HH AE1 K M AH0 N'],
  "hackmatack": ['HH AE1 K M AH0 T AE2 K'],
  "hackney": ['HH AE1 K N IY0'],
  "hackneyed": ['HH AE1 K N IY0 D'],
  "hacks": ['HH AE1 K S'],
  "hacksaw": ['HH AE1 K S AO2'],
  "hackstaff": ['HH AE1 K S T AE2 F'],
  "hackworth": ['HH AE1 K W ER2 TH'],
  "had": ['HH AE1 D'],
  "hada": ['HH AA1 D AH0'],
  "hadad": ['HH AE1 D AH0 D'],
  "hadar": ['HH AE1 D ER2'],
  "hadaway": ['HH AA1 D AH0 W EY0'],
  "hadd": ['HH AE1 D'],
  "haddad": ['HH AE1 D AH0 D'],
  "haddan": ['HH AE1 D AH0 N'],
  "haddaway": ['HH AE1 D AH0 W EY0'],
  "hadden": ['HH AE1 D AH0 N'],
  "haddock": ['HH AE1 D AH0 K'],
  "haddon": ['HH AE1 D AH0 N'],
  "haddonfield": ['HH AE1 D AH0 N F IY0 L D'],
  "haddow": ['HH AE1 D OW0'],
  "hade": ['HH EY1 D'],
  "haden": ['HH EY1 D AH0 N'],
  "hader": ['HH EY1 D ER0'],
  "hades": ['HH EY1 D IY0 Z', 'HH EY1 D Z'],
  "hadfield": ['HH AE1 D F IY2 L D'],
  "hadid": ['HH AA0 D IY1 D'],
  "hadleigh": ['HH AE1 D L AH0'],
  "hadler": ['HH EY1 D AH0 L ER0', 'HH EY1 D L ER0'],
  "hadley": ['HH AE1 D L IY0'],
  "hadline": ['HH AE1 D L AY0 N'],
  "hadlock": ['HH AE1 D L AH0 K'],
  "hadn't": ['HH AE1 D AH0 N T', 'HH AE1 D AH0 N'],
  "hadnot": ['HH AE1 D N AH0 T'],
  "hadoop": ['HH AE1 D UW1 P'],
  "hadoya": ['HH AH0 D OY1 AH0'],
  "hadria": ['HH AE1 D R IY0 AH0'],
  "hadrian": ['HH AE1 D R IY0 AH0 N', 'HH EY1 D R IY0 AH0 N'],
  "hadrian's": ['HH EY1 D R IY0 AH0 N Z'],
  "hadron": ['HH AE1 D R AO2 N'],
  "hadsall": ['HH AE1 D S AH0 L'],
  "hadsell": ['HH AE1 D S AH0 L'],
  "hadson": ['HH AE1 D S AH0 N'],
  "hadwin": ['HH AE1 D W IH0 N'],
  "haeberle": ['HH EH1 B ER0 AH0 L'],
  "haecker": ['HH EH1 K ER0'],
  "haefele": ['HH EH1 F AH0 L'],
  "haeffner": ['HH EH1 F N ER0'],
  "haefner": ['HH EH1 F N ER0'],
  "haegele": ['HH EH1 G AH0 L'],
  "haeger": ['HH EH1 G ER0'],
  "haen": ['HH IY1 N'],
  "haenel": ['HH EH1 N AH0 L'],
  "haering": ['HH AA1 ER0 IH0 NG'],
  "haertel": ['HH EH1 R T AH0 L'],
  "haese": ['HH IY1 S'],
  "haessly": ['HH AE1 S L IY0'],
  "haeussler": ['HH AW1 S L ER0'],
  "hafele": ['HH AE1 F AH0 L'],
  "hafeman": ['HH EY1 F M AH0 N'],
  "hafen": ['HH AE1 F AH0 N'],
  "hafer": ['HH EY1 F ER0'],
  "hafey": ['HH EY1 F IY0'],
  "hafez": ['HH AA1 F EH0 Z'],
  "haff": ['HH AE1 F'],
  "haffey": ['HH AE1 F IY0'],
  "haffez": ['HH AA1 F EH0 Z'],
  "haffner": ['HH AE1 F N ER0'],
  "hafford": ['HH AE1 F ER0 D'],
  "hafif": ['HH AA0 F IY1 F'],
  "hafif's": ['HH AA0 F IY1 F S'],
  "hafley": ['HH AE1 F L IY0'],
  "hafner": ['HH AE1 F N ER0'],
  "hafnia": ['HH AE1 F N IY0 AH0'],
  "hafnium": ['HH AE1 F N IY0 AH0 M'],
  "haft": ['HH AE1 F T'],
  "haft's": ['HH AE1 F T S', 'HH AE1 F S'],
  "hafta": ['HH AE1 F T AH0'],
  "hafts": ['HH AE1 F T S', 'HH AE1 F S'],
  "hafts'": ['HH AE1 F T S', 'HH AE1 F S'],
  "hag": ['HH AE1 G'],
  "haga": ['HH AA1 G AH0'],
  "hagadorn": ['HH AE1 G AH0 D AO0 R N'],
  "hagaman": ['HH AE1 G AH0 M AH0 N'],
  "hagan": ['HH EY1 G AH0 N'],
  "hagans": ['HH EY1 G AH0 N Z'],
  "hagar": ['HH EY1 G AA0 R', 'HH EY1 G ER0'],
  "hagarty": ['HH AE1 G AA0 R T IY0'],
  "hagberg": ['HH AE1 G B ER0 G'],
  "hage": ['HH EY1 JH'],
  "hagedorn": ['HH AE1 G IH0 D ER0 N'],
  "hagee": ['HH AE1 JH IY0'],
  "hagel": ['HH AE1 G AH0 L'],
  "hagelin": ['HH AE1 G IH0 L IH0 N'],
  "hageman": ['HH EY1 JH M AH0 N'],
  "hagemann": ['HH EY1 JH M AH0 N'],
  "hagemeier": ['HH AE1 G IH0 M AY0 ER0'],
  "hagemeister": ['HH AE1 G IH0 M AY0 S T ER0'],
  "hagemeyer": ['HH AE1 G IH0 M AY0 ER0'],
  "hagen": ['HH EY1 G AH0 N'],
  "hagenbuch": ['HH AE1 G IH0 N B AH0 K'],
  "hagenlocker": ['HH EY1 G AH0 N L AA2 K ER0'],
  "hagenow": ['HH AE1 JH IH0 N OW0'],
  "hagens": ['HH EY1 G AH0 N Z'],
  "hager": ['HH EY1 G ER0'],
  "hagerman": ['HH EY1 G ER0 M AH0 N'],
  "hagerstown": ['HH EY1 G ER0 Z T AW2 N'],
  "hagerty": ['HH AE1 JH ER0 T IY0'],
  "hagewood": ['HH EY1 JH W UH0 D'],
  "hagey": ['HH AE1 JH IY0'],
  "hagfish": ['HH AE1 G F IH0 SH'],
  "hagg": ['HH AE1 G'],
  "haggadah": ['HH AH0 G AH1 D AH0'],
  "haggan": ['HH AE1 G AH0 N'],
  "haggar": ['HH AE1 G ER0'],
  "haggard": ['HH AE1 G ER0 D'],
  "haggart": ['HH AE1 G ER0 T'],
  "haggarty": ['HH AE1 G AA2 R T IY0'],
  "hagge": ['HH AE1 G'],
  "haggerty": ['HH AE1 G ER0 T IY0'],
  "haggett": ['HH AE1 G IH0 T'],
  "haggins": ['HH AE1 G IH0 N Z'],
  "haggis": ['HH AE1 G AH0 S'],
  "haggle": ['HH AE1 G AH0 L'],
  "haggled": ['HH AE1 G AH0 L D'],
  "haggling": ['HH AE1 G AH0 L IH0 NG', 'HH AE1 G L IH0 NG'],
  "hagglund": ['HH AE1 G L AH0 N D'],
  "haggstrom": ['HH AE1 G S T R AH0 M'],
  "hagin": ['HH AE1 JH IH0 N', 'HH AE1 G IH2 N'],
  "hagins": ['HH AE1 G IH0 N Z', 'HH AE1 JH IH2 N Z'],
  "hagiography": ['HH AE2 G IY0 AA1 G R AH0 F IY0'],
  "hagiwara": ['HH AA2 G IH0 W AA1 R AH0'],
  "hagle": ['HH EY1 G AH0 L'],
  "hagler": ['HH AE1 G L ER0'],
  "hagley": ['HH AE1 G L IY0'],
  "haglund": ['HH AE1 G L AH0 N D'],
  "hagman": ['HH AE1 G M AH0 N'],
  "hagmann": ['HH AE1 G M AH0 N'],
  "hagner": ['HH AE1 G N ER0'],
  "hagood": ['HH AE1 G UH0 D'],
  "hagopian": ['HH AH0 G OW1 P IY0 AH0 N'],
  "hagstrom": ['HH AE1 G S T R AH0 M'],
  "hague": ['HH EY1 G'],
  "hagwood": ['HH AE1 G W UH2 D'],
  "hagy": ['HH EY1 G IY0'],
  "hah": ['HH AA1'],
  "hahl": ['HH AA1 L'],
  "hahm": ['HH AE1 M'],
  "hahn": ['HH AA1 N'],
  "hahne": ['HH EY1 N'],
  "hahner": ['HH AA1 N ER0'],
  "hahs": ['HH AA1 S'],
  "haid": ['HH EY1 D'],
  "haida": ['HH AY1 D AH0'],
  "haidee": ['HH EY1 D IY0'],
  "haider": ['HH EY1 D ER0'],
  "haidet": ['HH EY1 D IH0 T'],
  "haifa": ['HH AY1 F AH0'],
  "haifong": ['HH AY1 F AO0 NG'],
  "haig": ['HH EY1 G'],
  "haig's": ['HH EY1 G Z'],
  "haigh": ['HH EY1'],
  "haight": ['HH EY1 T'],
  "haigler": ['HH EY1 G L ER0'],
  "haik": ['HH EY1 K'],
  "haiku": ['HH AY1 K UW0'],
  "haikus": ['HH AY1 K UW0 Z'],
  "hail": ['HH EY1 L'],
  "hailand": ['HH EY1 L AH0 N D'],
  "haile": ['HH EY1 L', 'HH AY1 L IY0'],
  "hailed": ['HH EY1 L D'],
  "hailes": ['HH EY1 L Z', 'HH AY1 L IY0 Z'],
  "hailey": ['HH EY1 L IY0'],
  "hailing": ['HH EY1 L IH0 NG'],
  "hails": ['HH EY1 L Z'],
  "hailstone": ['HH EY1 L S T OW2 N'],
  "hailstones": ['HH EY1 L S T OW2 N Z'],
  "hailstorm": ['HH EY1 L S T AO2 R M'],
  "haim": ['HH AY1 M', 'HH EY1 M'],
  "haimes": ['HH EY1 M Z'],
  "haimovitch": ['HH EY1 M AH0 V IH0 CH'],
  "haimovitz": ['HH EY1 M AH0 V IH0 T S'],
  "haimowitz": ['HH AY1 M AH0 W IH0 T S'],
  "hain": ['HH EY1 N'],
  "hainan": ['HH EY1 N AH0 N'],
  "hainer": ['HH EY1 N ER0'],
  "haines": ['HH EY1 N Z'],
  "hainey": ['HH EY1 N IY0'],
  "hainley": ['HH EY1 N L IY0'],
  "hainline": ['HH EY1 N L AY2 N'],
  "hains": ['HH EY1 N Z'],
  "hainsworth": ['HH EY1 N S W ER0 TH'],
  "hair": ['HH EH1 R'],
  "hair's": ['HH EH1 R Z'],
  "haircut": ['HH EH1 R K AH2 T'],
  "haircuts": ['HH EH1 R K AH2 T S'],
  "hairdo": ['HH EH1 R D UW2'],
  "hairdos": ['HH EH1 R D UW2 Z'],
  "hairdresser": ['HH EH1 R D R EH2 S ER0'],
  "hairdressers": ['HH EH1 R D R EH2 S ER0 Z'],
  "hairdressing": ['HH EH1 R D R EH2 S IH0 NG'],
  "haire": ['HH EH1 R'],
  "haired": ['HH EH1 R D'],
  "hairfield": ['HH EH1 R F IY2 L D'],
  "hairgrove": ['HH AY1 R G R AH0 V'],
  "hairiness": ['HH EH1 R IY0 N AH0 S'],
  "hairless": ['HH EH1 R L AH0 S'],
  "hairline": ['HH EH1 R L AY2 N'],
  "hairr": ['HH EH1 R'],
  "hairs": ['HH EH1 R Z'],
  "hairspray": ['HH EH1 R S P R EY2'],
  "hairston": ['HH AY1 R S T AH0 N'],
  "hairstyle": ['HH EH1 R S T AY2 L'],
  "hairy": ['HH EH1 R IY0'],
  "haisley": ['HH EY1 Z L IY0'],
  "haislip": ['HH AY1 S L IH0 P'],
  "haist": ['HH AA1 IH0 S T'],
  "hait": ['HH EY1 T'],
  "haith": ['HH EY1 TH'],
  "haithcock": ['HH EY1 TH K AA2 K'],
  "haiti": ['HH EY1 T IY0'],
  "haiti's": ['HH EY1 T IY0 Z'],
  "haitian": ['HH EY1 SH AH0 N'],
  "haitians": ['HH EY1 SH AH0 N Z'],
  "haitien": ['HH EY1 SH AH0 N'],
  "haitiens": ['HH EY1 SH AH0 N Z'],
  "haitis": ['HH EY1 T IY0 Z'],
  "haizlip": ['HH EY1 Z L IH0 P'],
  "hajdu": ['HH AA1 Y D UW0'],
  "hajduk": ['HH AY1 D AH0 K'],
  "hajek": ['HH AY1 EH0 K'],
  "hajime": ['HH AA0 JH IY1 M IY0'],
  "hajj": ['HH AE1 JH'],
  "hajjar": ['HH AA0 Y AA1 R'],
  "hakala": ['HH AH0 K AA1 L AH0'],
  "hakan": ['HH EY1 K AH0 N', 'HH AA1 K AH0 N'],
  "hakanson": ['HH AE1 K AH0 N S AH0 N'],
  "hake": ['HH EY1 K'],
  "hakeem": ['HH AA0 K IY1 M'],
  "hakeem's": ['HH AA0 K IY1 M Z'],
  "hakes": ['HH EY1 K S'],
  "hakim": ['HH AA0 K IY1 M', 'AA0 K IY1 M'],
  "hakim's": ['HH AA0 K IY1 M Z', 'AA0 K IY1 M Z'],
  "hakki": ['HH AE1 K IY0'],
  "hakko": ['HH AE1 K OW0'],
  "hakon": ['HH AE1 K AH0 N'],
  "hakuhodo": ['HH AA2 K UW2 HH OW1 D OW0'],
  "hal": ['HH AE1 L'],
  "hal's": ['HH AE1 L Z'],
  "halama": ['HH AA0 L AA1 M AH0'],
  "halamandaris": ['HH AE2 L AH0 M AE1 N D ER0 IH0 S'],
  "halas": ['HH AA1 L AH0 Z'],
  "halasz": ['HH AA1 L AH0 SH'],
  "halbach": ['HH AE1 L B AA0 K'],
  "halberg": ['HH AE1 L B ER0 G'],
  "halberstadt": ['HH AE1 L B ER0 SH T AE0 T'],
  "halberstam": ['HH AE1 L B ER0 S T AE0 M'],
  "halbert": ['HH AE1 L B ER0 T'],
  "halbig": ['HH AE1 L B IH0 G'],
  "halbrook": ['HH AE1 L B R UH0 K'],
  "halbrooks": ['HH AE1 L B R UH0 K S'],
  "halbur": ['HH AE1 L B ER0'],
  "halbutogullari": ['HH AE0 L B UW2 T OW2 G UW0 L AA1 R IY0'],
  "halcion": ['HH AE1 L S IY0 AH0 N'],
  "halcion's": ['HH AE1 L S IY0 AH0 N Z'],
  "halcomb": ['HH AE1 L K AH0 M'],
  "halcyon": ['HH AE1 L S IY0 AH0 N'],
  "halcyone": ['HH AE1 L S IY0 OW0 N'],
  "hald": ['HH AO1 L D'],
  "haldan": ['HH AE1 L D AH0 N'],
  "haldana": ['HH AH0 L D AE1 N AH0'],
  "haldeman": ['HH AA1 L D M AH0 N', 'HH AA1 L D AH0 M AH0 N'],
  "haldeman's": ['HH AA1 L D M AH0 N Z', 'HH AA1 L D AH0 M AH0 N Z'],
  "halden": ['HH AO1 L D AH0 N'],
  "halder": ['HH AO1 L D ER0'],
  "halderman": ['HH AO1 L D ER0 M AH0 N'],
  "hale": ['HH EY1 L'],
  "hale's": ['HH EY1 L Z'],
  "halebopp": ['HH AY0 L B AO1 P'],
  "halen": ['HH EY1 L EH0 N'],
  "hales": ['HH EY1 L Z'],
  "halevi": ['HH AH0 L EY1 V IY0', 'HH AH0 L IY1 V IY0'],
  "haley": ['HH EY1 L IY0'],
  "haley's": ['HH EY1 L IY0 Z'],
  "half": ['HH AE1 F'],
  "half's": ['HH AE1 F S'],
  "half-baked": ['HH AE1 F B EY2 K T'],
  "half-cock": ['HH AE1 F K AA2 K'],
  "half-cocked": ['HH AE1 F K AA2 K T'],
  "half-dozen": ['HH AE1 F D AH2 Z AH0 N'],
  "half-hearted": ['HH AE1 F HH AA2 R T IH0 D'],
  "half-hour": ['HH AE1 F AW2 ER0'],
  "half-time": ['HH AE1 F T AY2 M'],
  "halfacre": ['HH AE1 F EY2 K ER0'],
  "halfback": ['HH AE1 F B AE2 K'],
  "halfbacks": ['HH AE1 F B AE2 K S'],
  "halfdan": ['HH AE1 L F D AH0 N'],
  "halferty": ['HH AE1 F ER0 T IY0'],
  "halfhearted": ['HH AE1 F HH AA2 R T IH0 D'],
  "halfhill": ['HH AE1 F HH IH2 L'],
  "halfman": ['HH AE1 F M AH0 N'],
  "halfmann": ['HH AE1 F M AH0 N'],
  "halford": ['HH AE1 F ER0 D'],
  "halfrida": ['HH AE1 F R IH0 D AH0'],
  "halftime": ['HH AE1 F T AY2 M'],
  "halftimes": ['HH AE1 F T AY2 M Z'],
  "halftone": ['HH AE1 F T OW2 N'],
  "halftones": ['HH AE1 F T OW2 N Z'],
  "halfway": ['HH AE1 F W EY1'],
  "halfwit": ['HH AE1 F W IH0 T'],
  "halfwits": ['HH AE1 F W IH0 T S'],
  "halgren": ['HH AE1 L G R EH0 N'],
  "haliburton": ['HH AE1 L IH0 B ER2 T AH0 N'],
  "halibut": ['HH AE1 L AH0 B AH0 T'],
  "halide": ['HH AE1 L AY2 D'],
  "halifa": ['HH AH0 L IY1 F AH0'],
  "halifax": ['HH AE1 L IH0 F AE2 K S'],
  "halik": ['HH AE1 L IH0 K'],
  "halima": ['HH AH0 L IY1 M AH0'],
  "halimeda": ['HH AA0 L IY0 M EY1 D AH0'],
  "halite": ['HH AE1 L AY0 T'],
  "halko": ['HH AE1 L K OW0'],
  "hall": ['HH AO1 L'],
  "hall's": ['HH AO1 L Z'],
  "halla": ['HH AE1 L AH0'],
  "halladay": ['HH AE1 L AH0 D EY2'],
  "hallahan": ['HH AE1 L AH0 HH AE0 N'],
  "hallam": ['HH AE1 L AH0 M'],
  "hallanan": ['HH AE1 L AH0 N AH0 N'],
  "hallandale": ['HH AE1 L AH0 N D EY2 L'],
  "hallas": ['HH AE1 L AH0 Z'],
  "hallauer": ['HH AE1 L AW0 ER0'],
  "hallbauer": ['HH AO1 L B AW2 R'],
  "hallberg": ['HH AO1 L B ER0 G'],
  "halle": ['HH AE1 L', 'HH AE1 L IY0'],
  "halleck": ['HH AE1 L IH0 K'],
  "hallelujah": ['HH AE2 L AH0 L UW1 Y AH0'],
  "hallen": ['HH AO1 L AH0 N'],
  "hallenbeck": ['HH AO1 L AH0 N B EH2 K'],
  "haller": ['HH AO1 L ER0'],
  "halleran": ['HH AE1 L ER0 AE0 N'],
  "hallet": ['HH AE1 L IH0 T'],
  "hallett": ['HH AE1 L IH0 T'],
  "halley": ['HH AE1 L IY0', 'HH EY1 L IY0'],
  "hallford": ['HH AE1 L F ER0 D'],
  "hallgarten": ['HH AO1 L G AA2 R T AH0 N'],
  "hallgren": ['HH AE1 L G R EH0 N'],
  "halliburton": ['HH AE1 L IH0 B ER2 T AH0 N'],
  "halliburton's": ['HH AE1 L IH0 B ER2 T AH0 N Z'],
  "halliciforn": ['HH AH0 L IH1 S IH0 F AO0 R N'],
  "halliday": ['HH AE1 L IH0 D EY2'],
  "hallie": ['HH AO1 L IY0'],
  "halligan": ['HH AE1 L IH0 G AH0 N'],
  "hallin": ['HH AE1 L IH0 N'],
  "hallinan": ['HH AE1 L IH0 N AH0 N'],
  "halling": ['HH AO1 L IH0 NG'],
  "hallingby": ['HH AO1 L IH0 NG B IY0'],
  "hallisey": ['HH AE1 L IH0 S IY0'],
  "hallman": ['HH AO1 L M AH0 N'],
  "hallmark": ['HH AA1 L M AA2 R K'],
  "hallmark's": ['HH AO1 L M AA2 R K S'],
  "hallmarks": ['HH AO1 L M AA2 R K S'],
  "hallock": ['HH AE1 L AH0 K'],
  "halloran": ['HH AE1 L ER0 AH0 N'],
  "hallow": ['HH AE1 L OW0'],
  "halloway": ['HH AE1 L OW0 W EY2'],
  "hallowe'en": ['HH AE2 L AH0 W IY1 N'],
  "hallowed": ['HH AE1 L OW0 D'],
  "halloween": ['HH AE2 L AH0 W IY1 N'],
  "halloween's": ['HH AE2 L AH0 W IY1 N Z'],
  "hallowell": ['HH AE1 L AH0 W EH0 L'],
  "hallows": ['HH AE1 L OW0 Z'],
  "hallquist": ['HH AE1 L K W IH0 S T'],
  "halls": ['HH AO1 L Z'],
  "hallstrom": ['HH AE1 L S T R AH0 M'],
  "hallucinate": ['HH AH0 L UW1 S AH0 N EY0 T'],
  "hallucinated": ['HH AH0 L UW1 S AH0 N EY0 T IH0 D'],
  "hallucinates": ['HH AH0 L UW1 S AH0 N EY0 T S'],
  "hallucinating": ['HH AH0 L UW1 S AH0 N EY0 T IH0 NG', 'HH AH0 L UW1 S IH0 N EY0 T IH0 NG'],
  "hallucination": ['HH AH0 L UW2 S AH0 N EY1 SH AH0 N'],
  "hallucinations": ['HH AH0 L UW2 S AH0 N EY1 SH AH0 N Z'],
  "hallucinatory": ['HH AH0 L UW1 S AH0 N AH0 T AO2 R IY0'],
  "hallucinogenic": ['HH AH0 L UW2 S AH0 N AH0 JH EH1 N IH0 K'],
  "hallum": ['HH AE1 L AH0 M'],
  "hallums": ['HH AE1 L AH0 M Z'],
  "hallward": ['HH AO1 L W ER0 D'],
  "hallway": ['HH AO1 L W EY2'],
  "hallways": ['HH AO1 L W EY2 Z'],
  "hallwood": ['HH AO1 L W UH2 D'],
  "hally": ['HH AE1 L IY0'],
  "halm": ['HH AA1 M'],
  "halmi": ['HH AO1 L M IY0'],
  "halmos": ['HH AO1 L M OW0 S'],
  "halmstad": ['HH AA1 L M S T AE2 D'],
  "halo": ['HH EY1 L OW0'],
  "halogen": ['HH AE1 L AH0 JH AH0 N'],
  "halogenate": ['HH AE1 L AH0 JH AH0 N EY2 T'],
  "halogenated": ['HH AE1 L AH0 JH AH0 N EY2 T IH0 D'],
  "halon": ['HH EY1 L AA2 N'],
  "halophytic": ['HH AE2 L AH0 F IH1 T IH0 K'],
  "halos": ['HH EY1 L OW0 Z'],
  "halper": ['HH AE1 L P ER0'],
  "halperin": ['HH AE1 L P ER0 IH0 N', 'HH AE1 L P R IH0 N'],
  "halpern": ['HH AE1 L P ER0 N'],
  "halperstant": ['HH AE1 L P ER0 S T AH0 N T'],
  "halpert": ['HH AE1 L P ER0 T'],
  "halpin": ['HH AE1 L P IH0 N'],
  "halprin": ['HH AE1 L P R IH0 N'],
  "halsell": ['HH AE1 L S AH0 L'],
  "halseth": ['HH AE1 L S IH0 TH'],
  "halsey": ['HH AE1 L S IY0'],
  "halstead": ['HH AE1 L S T EH0 D'],
  "halsted": ['HH AE1 L S T IH0 D'],
  "halston": ['HH AO1 L S T AH0 N'],
  "halston's": ['HH AO1 L S T AH0 N Z'],
  "halt": ['HH AO1 L T'],
  "halted": ['HH AO1 L T AH0 D', 'HH AO1 L T IH0 D'],
  "halteman": ['HH EY1 L T M AH0 N'],
  "halter": ['HH AO1 L T ER0'],
  "halter's": ['HH AO1 L T ER0 Z'],
  "halterman": ['HH AO1 L T ER0 M AH0 N'],
  "halters": ['HH AO1 L T ER0 Z'],
  "halting": ['HH AO1 L T IH0 NG'],
  "haltingly": ['HH AO1 L T IH0 NG L IY0'],
  "haltiwanger": ['HH AE1 L T IH0 W AH0 NG ER0'],
  "haltom": ['HH AE1 L T AH0 M'],
  "halton": ['HH AE1 L T AH0 N'],
  "halts": ['HH AO1 L T S'],
  "haluska": ['HH AH0 L AH1 S K AH0'],
  "halve": ['HH AE1 V'],
  "halved": ['HH AE1 V D'],
  "halverson": ['HH AE1 L V ER0 S AH0 N'],
  "halves": ['HH AE1 V Z'],
  "halving": ['HH AE1 V IH0 NG'],
  "halvorsen": ['HH AE0 L V AO1 R S AH0 N'],
  "halvorson": ['HH AE1 L V ER0 S AH0 N'],
  "ham": ['HH AE1 M'],
  "hama": ['HH AA1 M AH0'],
  "hamacher": ['HH AE1 M AH0 K ER0'],
  "hamad": ['HH AE1 M AH0 D'],
  "hamada": ['HH AA0 M AA1 D AH0'],
  "hamadei": ['HH AE1 M AH0 D EY2'],
  "hamadi": ['HH AH0 M AA1 D IY0'],
  "hamaker": ['HH AA1 M EY0 K ER0'],
  "hamal": ['HH EY1 M AH0 L'],
  "hamamoto": ['HH AA0 M AA0 M OW1 T OW0'],
  "haman": ['HH EY1 M AH0 N'],
  "hamanaka": ['HH AH0 M AH0 N AA1 K AH0'],
  "hamann": ['HH AA1 M AH0 N'],
  "hamar": ['HH AH0 M AA1 R'],
  "hamas": ['HH AA2 M AA1 S'],
  "hamas'": ['HH AA2 M AA1 S'],
  "hamasaki": ['HH AA2 M AA0 S AA1 K IY0'],
  "hambelton": ['HH AH0 M B EH1 L T AH0 N'],
  "hamberg": ['HH AE1 M B ER0 G'],
  "hamberger": ['HH AE1 M B ER0 G ER0'],
  "hamblen": ['HH AE1 M B AH0 L AH0 N'],
  "hamblet": ['HH AE1 M B L IH0 T'],
  "hambleton": ['HH AE1 M B AH0 L T AA0 N'],
  "hambley": ['HH AE1 M B L IY0'],
  "hamblin": ['HH AE1 M B L IH0 N'],
  "hambly": ['HH AE1 M B L IY0'],
  "hambrecht": ['HH AE1 M B R EH2 K T'],
  "hambrick": ['HH AE1 M B R IH2 K'],
  "hambright": ['HH AE1 M B R AY2 T'],
  "hambro": ['HH AE1 M B R OW0'],
  "hambros": ['HH AE1 M B R OW0 S'],
  "hamburg": ['HH AE1 M B ER0 G'],
  "hamburger": ['HH AE1 M B ER0 G ER0'],
  "hamburgers": ['HH AE1 M B ER0 G ER0 Z'],
  "hamby": ['HH AE1 M B IY0'],
  "hamdan": ['HH AE1 M D AH0 N'],
  "hamdoon": ['HH AE0 M D UW1 N'],
  "hamdun": ['HH AE1 M D AH0 N'],
  "hamed": ['HH AE1 M D'],
  "hameister": ['HH AE1 M AY0 S T ER0'],
  "hamel": ['HH AE1 M AH0 L'],
  "hamelin": ['HH AE1 M AH0 L IH0 N', 'HH AE1 M L IH2 N'],
  "hamer": ['HH AE1 M ER0'],
  "hames": ['HH EY1 M Z'],
  "hamid": ['HH AH0 M IY1 D'],
  "hamiel": ['HH AE1 M IY0 L'],
  "hamil": ['HH AE1 M AH0 L'],
  "hamill": ['HH AE1 M AH0 L'],
  "hamilton": ['HH AE1 M AH0 L T AH0 N'],
  "hamilton's": ['HH AE1 M AH0 L T AH0 N Z'],
  "hamiltonian": ['HH AE1 M AH0 L T OW2 N Y AH0 N'],
  "hamiltons": ['HH AE1 M AH0 L T AH0 N Z'],
  "hamish": ['HH AE1 M IH0 SH'],
  "hamiter": ['HH AE1 M AY0 T ER0'],
  "hamitic": ['HH AE0 M IH1 T IH0 K'],
  "hamler": ['HH AE1 M AH0 L ER0', 'HH AE1 M L ER0'],
  "hamlet": ['HH AE1 M L AH0 T', 'HH AE1 M L IH0 T'],
  "hamlets": ['HH AE1 M L AH0 T S'],
  "hamlett": ['HH AE1 M L IH0 T'],
  "hamley": ['HH AE1 M L IY0'],
  "hamley's": ['HH AE1 M L IY0 Z'],
  "hamlin": ['HH AE1 M L IH0 N'],
  "hamling": ['HH AE1 M L IH0 NG'],
  "hamlisch": ['HH AE1 M L IH0 SH'],
  "hamlisch's": ['HH AE1 M L IH0 SH AH0 Z'],
  "hamm": ['HH AE1 M'],
  "hammac": ['HH AE1 M AH0 K'],
  "hammacher": ['HH AE1 M AA2 K ER0'],
  "hammack": ['HH AE1 M AH0 K'],
  "hammaker": ['HH AE1 M EY2 K ER0'],
  "hamman": ['HH AE1 M AH0 N'],
  "hammann": ['HH AE1 M AH0 N'],
  "hammar": ['HH AE1 M ER0'],
  "hammas": ['HH AH0 M AA1 S'],
  "hammas'": ['HH AH0 M AA1 S'],
  "hammas's": ['HH AH0 M AA1 S IH0 S'],
  "hamme": ['HH AE1 M'],
  "hammel": ['HH AE1 M AH0 L'],
  "hammell": ['HH AE1 M AH0 L'],
  "hammen": ['HH AE1 M AH0 N'],
  "hammer": ['HH AE1 M ER0'],
  "hammer's": ['HH AE1 M ER0 Z'],
  "hammered": ['HH AE1 M ER0 D'],
  "hammering": ['HH AE1 M ER0 IH0 NG'],
  "hammerle": ['HH AE1 M ER0 AH0 L'],
  "hammerlock": ['HH AE1 M ER0 L AA2 K'],
  "hammerman": ['HH AE1 M ER0 M AH0 N'],
  "hammermeister": ['HH AE1 M ER0 M AY2 S T ER0'],
  "hammermill": ['HH AE1 M ER0 M IH2 L'],
  "hammers": ['HH AE1 M ER0 Z'],
  "hammerschmidt": ['HH AE1 M ER0 SH M IH2 T'],
  "hammersley": ['HH AE1 M ER0 S L IY0'],
  "hammersmith": ['HH AE1 M ER0 S M IH2 TH'],
  "hammerson": ['HH AE1 M ER0 S AH0 N'],
  "hammerstein": ['HH AE1 M ER0 S T IY2 N', 'HH AE1 M ER0 S T AY2 N'],
  "hammerstein's": ['HH AE1 M ER0 S T IY2 N Z', 'HH AE1 M ER0 S T AY2 N Z'],
  "hammerstrom": ['HH AE1 M ER0 S T R AH0 M'],
  "hammes": ['HH AE1 M Z'],
  "hammett": ['HH AE1 M IH0 T'],
  "hammill": ['HH AE1 M AH0 L'],
  "hammitt": ['HH AE1 M IH0 T'],
  "hammock": ['HH AE1 M AH0 K'],
  "hammocks": ['HH AE1 M AH0 K S'],
  "hammon": ['HH AE1 M AH0 N'],
  "hammond": ['HH AE1 M AH0 N D'],
  "hammonds": ['HH AE1 M AH0 N D Z'],
  "hammons": ['HH AE1 M AH0 N Z'],
  "hammontree": ['HH AE0 M AH0 N T R IY1'],
  "hammurabi": ['HH AE1 M AH0 R AA2 B IY2'],
  "hamner": ['HH AE1 M N ER0'],
  "hamon": ['HH AE1 M AH0 N'],
  "hamor": ['HH AE1 M ER0'],
  "hamp": ['HH AE1 M P'],
  "hampe": ['HH AE1 M P'],
  "hampel": ['HH AE1 M P AH0 L'],
  "hamper": ['HH AE1 M P ER0'],
  "hampered": ['HH AE1 M P ER0 D'],
  "hampering": ['HH AE1 M P ER0 IH0 NG'],
  "hampers": ['HH AE1 M P ER0 Z'],
  "hample": ['HH AE1 M P AH0 L'],
  "hampshire": ['HH AE1 M P SH ER0', 'HH AE1 M SH ER0', 'HH AE1 M P SH AY0 ER0', 'HH AE1 M SH AY0 ER0'],
  "hampshire's": ['HH AE1 M P SH ER0 Z', 'HH AE1 M SH ER0 Z', 'HH AE1 M P SH AY0 ER0 Z', 'HH AE1 M SH AY0 ER0 Z'],
  "hampshirites": ['HH AE1 M P SH ER0 AY2 T S'],
  "hampson": ['HH AE1 M P S AH0 N'],
  "hampstead": ['HH AE1 M P S T EH2 D'],
  "hampton": ['HH AE1 M P T AH0 N'],
  "hampton's": ['HH AE1 M P T AH0 N Z'],
  "hamptons": ['HH AE1 M P T AH0 N Z'],
  "hamptonshire": ['HH AE1 M P T AH0 N SH ER0'],
  "hamptonshire's": ['HH AE1 M P T AH0 N SH ER0 Z'],
  "hamre": ['HH AE1 M ER0'],
  "hamric": ['HH AE1 M R IH0 K'],
  "hamrick": ['HH AE1 M R IH0 K'],
  "hamrock": ['HH AE1 M R AA2 K'],
  "hams": ['HH AE1 M Z'],
  "hamsher": ['HH AE1 M SH ER0'],
  "hamson": ['HH AE1 M S AH0 N'],
  "hamsphire": ['HH AE1 M S F AY2 R'],
  "hamster": ['HH AE1 M S T ER0'],
  "hamsters": ['HH AE1 M S T ER0 Z'],
  "hamstra": ['HH AE1 M S T R AH0'],
  "hamstring": ['HH AE1 M S T R IH2 NG'],
  "hamstrings": ['HH AE1 M S T R IH2 NG Z'],
  "hamstrung": ['HH AE1 M S T R AH0 NG'],
  "hamtramck": ['HH AE0 M T R AE1 M IH0 K'],
  "han": ['HH AA1 N', 'HH AE1 N'],
  "han's": ['HH AA1 N Z', 'HH AE1 N Z'],
  "hana": ['HH AE1 N AH0'],
  "hanafin": ['HH AE1 N AH0 F IH0 N'],
  "hanagan": ['HH AA0 N AA1 G AA0 N'],
  "hanahan": ['HH AE1 N AH0 HH AE0 N'],
  "hanak": ['HH AA1 N AH0 K'],
  "hanan": ['HH EY1 N AH0 N'],
  "hanas": ['HH AE1 N AH0 Z'],
  "hanauer": ['HH AE1 N AW0 ER0'],
  "hanawalt": ['HH AE1 N AH0 W AO2 L T'],
  "hanaway": ['HH AE1 N AH0 W EY0'],
  "hanback": ['HH AE1 N B AE2 K'],
  "hanberry": ['HH AE1 N B EH2 R IY0'],
  "hanbo": ['HH AE1 N B OW0'],
  "hanbury": ['HH AE1 N B EH2 R IY0'],
  "hanby": ['HH AE1 N B IY0'],
  "hance": ['HH AE1 N S'],
  "hancher": ['HH AE1 N CH ER0'],
  "hanchett": ['HH AE1 N CH IH0 T'],
  "hanchey": ['HH AE1 N CH IY0'],
  "hancock": ['HH AE1 N K AA2 K'],
  "hancock's": ['HH AE1 N K AA2 K S'],
  "hancox": ['HH AE1 N K AA0 K S'],
  "hand": ['HH AE1 N D'],
  "hand's": ['HH AE1 N D Z'],
  "hand-held": ['HH AE1 N D HH EH2 L D'],
  "handa": ['HH AE1 N D AH0'],
  "handbag": ['HH AE1 N D B AE2 G'],
  "handbags": ['HH AE1 N D B AE2 G Z'],
  "handball": ['HH AE1 N D B AO2 L'],
  "handballs": ['HH AE1 N D B AO2 L Z'],
  "handbill": ['HH AE1 N D B IH2 L'],
  "handbills": ['HH AE1 N D B IH2 L Z'],
  "handbook": ['HH AE1 N D B UH2 K'],
  "handbooks": ['HH AE1 N D B UH2 K S'],
  "handclasp": ['HH AE1 N D K L AE2 S P'],
  "handcraft": ['HH AE1 N D K R AE2 F T'],
  "handcrafted": ['HH AE1 N D K R AE2 F T IH0 D'],
  "handcrafts": ['HH AE1 N D K R AE2 F T S'],
  "handcuff": ['HH AE1 N D K AH2 F'],
  "handcuffed": ['HH AE1 N D K AH2 F T'],
  "handcuffing": ['HH AE1 N D K AH2 F IH0 NG'],
  "handcuffs": ['HH AE1 N D K AH2 F S'],
  "handed": ['HH AE1 N D AH0 D', 'HH AE1 N D IH0 D'],
  "handedly": ['HH AE1 N D IH0 D L IY0'],
  "handedness": ['HH AE1 N D AH0 D N AH0 S'],
  "handel": ['HH AE1 N D AH0 L'],
  "handel's": ['HH AE1 N D AH0 L Z'],
  "handeland": ['HH AE1 N D IH0 L AH0 N D'],
  "handelman": ['HH AE1 N D AH0 L M AH0 N'],
  "handels": ['HH AE1 N D AH0 L Z'],
  "handelsbank": ['HH AE1 N D AH0 L Z B AE2 NG K'],
  "handelsbanken": ['HH AE2 N D AH0 L S B AE1 NG K AH0 N'],
  "handelsman": ['HH AE1 N D IH0 L S M AH0 N'],
  "hander": ['HH AE1 N D ER0'],
  "handers": ['HH AE1 N D ER0 Z'],
  "handford": ['HH AE1 N D F ER0 D'],
  "handful": ['HH AE1 N D F UH2 L'],
  "handfuls": ['HH AE1 N D F UH2 L Z'],
  "handgun": ['HH AE1 N D G AH2 N'],
  "handguns": ['HH AE1 N D G AH2 N Z'],
  "handheld": ['HH AE1 N D HH EH1 L D'],
  "handhold": ['HH AE1 N D HH OW2 L D'],
  "handholding": ['HH AE1 N D HH OW2 L D IH0 NG'],
  "handicap": ['HH AE1 N D IY0 K AE2 P'],
  "handicapped": ['HH AE1 N D IY0 K AE2 P T'],
  "handicapper": ['HH AE1 N D IY0 K AE2 P ER0'],
  "handicappers": ['HH AE1 N D IY0 K AE2 P ER0 Z'],
  "handicapping": ['HH AE1 N D IY0 K AE2 P IH0 NG'],
  "handicaps": ['HH AE1 N D IY0 K AE2 P S'],
  "handicraft": ['HH AE1 N D IY0 K R AE2 F T'],
  "handicrafts": ['HH AE1 N D IY0 K R AE2 F T S'],
  "handier": ['HH AE1 N D IY0 ER0'],
  "handiest": ['HH AE1 N D IY0 AH0 S T'],
  "handily": ['HH AE1 N D AH0 L IY0'],
  "handing": ['HH AE1 N D IH0 NG'],
  "handiwork": ['HH AE1 N D IY0 W ER2 K'],
  "handke": ['HH AE1 N D K IY0'],
  "handkerchief": ['HH AE1 NG K ER0 CH IH0 F', 'HH AE1 NG K ER0 CH IY0 F'],
  "handkerchiefs": ['HH AE1 NG K ER0 CH AH0 F S', 'HH AE1 NG K ER0 CH IY0 F S'],
  "handle": ['HH AE1 N D AH0 L'],
  "handlebar": ['HH AE1 N D AH0 L B AA2 R'],
  "handlebars": ['HH AE1 N D AH0 L B AA2 R Z'],
  "handled": ['HH AE1 N D AH0 L D'],
  "handler": ['HH AE1 N D L ER0', 'HH AE1 N D AH0 L ER0'],
  "handlers": ['HH AE1 N D L ER0 Z', 'HH AE1 N D AH0 L ER0 Z'],
  "handles": ['HH AE1 N D AH0 L Z'],
  "handley": ['HH AE1 N D L IY0'],
  "handlin": ['HH AE1 N D L IH0 N'],
  "handling": ['HH AE1 N D L IH0 NG', 'HH AE1 N D AH0 L IH0 NG'],
  "handlon": ['HH AE1 N D L AH0 N'],
  "handloom": ['HH AE1 N D L UW2 M'],
  "handlooms": ['HH AE1 N D L UW2 M Z'],
  "handly": ['HH AE1 N D L IY0'],
  "handmade": ['HH AE1 N D M EY1 D', 'HH AE1 N M EY1 D'],
  "handout": ['HH AE1 N D AW2 T'],
  "handouts": ['HH AE1 N D AW2 T S'],
  "handover": ['HH AE1 N D OW0 V ER0'],
  "handpick": ['HH AE1 N D P IH1 K'],
  "handpicked": ['HH AE1 N D P IH1 K T'],
  "handrail": ['HH AE1 N D R EY2 L'],
  "handrails": ['HH AE1 N D R EY2 L Z'],
  "handrich": ['HH AE1 N D R IH0 K'],
  "handros": ['HH AE1 N D R OW0 S'],
  "hands": ['HH AE1 N D Z', 'HH AE1 N Z'],
  "hands-on": ['HH AE2 N Z AO1 N'],
  "handsaw": ['HH AE1 N D S AO2'],
  "handsaws": ['HH AE1 N D S AO2 Z'],
  "handset": ['HH AE1 N D S EH2 T'],
  "handsets": ['HH AE1 N D S EH2 T S'],
  "handshake": ['HH AE1 N D SH EY2 K'],
  "handshakes": ['HH AE1 N D SH EY2 K S'],
  "handshaking": ['HH AE1 N D SH EY2 K IH0 NG'],
  "handsome": ['HH AE1 N S AH0 M'],
  "handsomely": ['HH AE1 N S AH0 M L IY0'],
  "handstand": ['HH AE1 N D S T AE2 N D'],
  "handstands": ['HH AE1 N D S T AE2 N D Z'],
  "handwerk": ['HH AE1 N D W ER0 K'],
  "handwerker": ['HH AE1 N D W ER0 K ER0'],
  "handwoven": ['HH AE1 N D W OW1 V AH0 N', 'HH AE1 N W OW1 V AH0 N'],
  "handwriting": ['HH AE1 N D R AY2 T IH0 NG'],
  "handwritten": ['HH AE1 N D R IH2 T AH0 N'],
  "handy": ['HH AE1 N D IY0'],
  "handyman": ['HH AE1 N D IY0 M AE2 N'],
  "handymen": ['HH AE1 N D IY0 M EH1 N'],
  "hane": ['HH EY1 N'],
  "haneda": ['HH AH0 N EY1 D AH0'],
  "hanel": ['HH AE1 N AH0 L'],
  "haneline": ['HH AE1 N IH0 L AY2 N'],
  "hanemann": ['HH EY1 N M AH0 N'],
  "haner": ['HH EY1 N ER0'],
  "hanes": ['HH EY1 N Z'],
  "haney": ['HH EY1 N IY0'],
  "hanf": ['HH AE1 N F'],
  "hanford": ['HH AE1 N F ER0 D'],
  "hanft": ['HH AE1 N F T'],
  "hang": ['HH AE1 NG'],
  "hangar": ['HH AE1 NG ER0'],
  "hangars": ['HH AE1 NG ER0 Z'],
  "hangartner": ['HH AE1 NG G AA0 R T N ER0'],
  "hanged": ['HH AE1 NG D'],
  "hangen": ['HH AE1 NG AH0 N'],
  "hanger": ['HH AE1 NG ER0'],
  "hangers": ['HH AE1 NG ER0 Z'],
  "hangin'": ['HH AE1 NG G IH0 N'],
  "hanging": ['HH AE1 NG IH0 NG', 'HH AE1 NG G IH0 NG'],
  "hangings": ['HH AE1 NG G IH0 NG Z'],
  "hangman": ['HH AE1 NG M AH0 N'],
  "hangout": ['HH AE1 NG AW2 T'],
  "hangouts": ['HH AE1 NG AW2 T S'],
  "hangover": ['HH AE1 NG OW2 V ER0'],
  "hangovers": ['HH AE1 NG OW2 V ER0 Z'],
  "hangs": ['HH AE1 NG Z'],
  "hangsang": ['HH AE1 NG S AE2 NG'],
  "hangsang's": ['HH AE1 NG S AE2 NG Z'],
  "hangul": ['HH AE1 N G UH2 L'],
  "hangup": ['HH AE1 NG G AH2 P'],
  "hangups": ['HH AE1 NG G AH2 P S'],
  "hani": ['HH AE1 N IY0'],
  "hani's": ['HH AE1 N IY0 Z'],
  "hanifen": ['HH AE1 N IH0 F AH0 N'],
  "hanifin": ['HH AE1 N IH0 F IH0 N'],
  "hanigan": ['HH AE1 N IH0 G AH0 N'],
  "haning": ['HH EY1 N IH0 NG'],
  "hanisch": ['HH AE1 N IH0 SH'],
  "hanisee": ['HH AE1 N IH0 S IY0'],
  "hanish": ['HH AE1 N IH0 SH'],
  "hanjin": ['HH AE1 N JH IH0 N'],
  "hank": ['HH AE1 NG K'],
  "hank's": ['HH AE1 N K S'],
  "hanke": ['HH AE1 NG K'],
  "hankel": ['HH AE1 NG K AH0 L'],
  "hanken": ['HH AE1 NG K AH0 N'],
  "hanker": ['HH AE1 NG K ER0'],
  "hankering": ['HH AE1 NG K ER0 IH0 NG'],
  "hankerson": ['HH AE1 NG K ER0 S AH0 N'],
  "hankes": ['HH AE1 NG K S'],
  "hankey": ['HH AE1 NG K IY0'],
  "hankin": ['HH AE1 NG K IH0 N'],
  "hankins": ['HH AE1 NG K IH0 N Z'],
  "hankinson": ['HH AE1 NG K IH0 N S AH0 N'],
  "hankla": ['HH AE1 NG K L AH0'],
  "hanko": ['HH AE1 NG K OW0'],
  "hanks": ['HH AE1 NG K S'],
  "hanky": ['HH AE1 NG K IY0'],
  "hanley": ['HH AE1 N L IY0'],
  "hanley's": ['HH AE1 N L IY0 Z'],
  "hanlin": ['HH AE1 N L IH0 N'],
  "hanlon": ['HH AE1 N L AH0 N'],
  "hanly": ['HH AE1 N L IY0'],
  "hanmer": ['HH AE1 N M ER0'],
  "hann": ['HH AE1 N'],
  "hanna": ['HH AE1 N AH0'],
  "hanna's": ['HH AE1 N AH0 Z'],
  "hannaford": ['HH AE1 N AH0 F ER0 D'],
  "hannagan": ['HH AE1 N AH0 G AE0 N'],
  "hannah": ['HH AE1 N AH0'],
  "hannahs": ['HH AE1 N AH0 Z'],
  "hannam": ['HH AE1 N AH0 M'],
  "hannaman": ['HH AE1 N AH0 M AH0 N'],
  "hannan": ['HH AE1 N AH0 N'],
  "hannay": ['HH AE1 N EY0'],
  "hanneken": ['HH AE1 N IH0 K AH0 N'],
  "hanneman": ['HH AE1 N M AH0 N'],
  "hannemann": ['HH AE1 N M AH0 N'],
  "hannen": ['HH AE1 N AH0 N'],
  "hanner": ['HH AE1 N ER0'],
  "hanners": ['HH AE1 N ER0 Z'],
  "hannes": ['HH AE1 N Z'],
  "hannesson": ['HH AE1 N AH0 S AH0 N'],
  "hanney": ['HH AE1 N IY0'],
  "hanni": ['HH AE1 N IY0'],
  "hannibal": ['HH AE1 N IH0 B AH0 L'],
  "hannie": ['HH AE1 N IY0'],
  "hannifin": ['HH AE1 N IH0 F IH0 N'],
  "hannig": ['HH AE1 N IH0 G'],
  "hannigan": ['HH AE1 N IH0 G AH0 N'],
  "hanning": ['HH AE1 N IH0 NG'],
  "hannis": ['HH AE1 N IH0 S'],
  "hannity": ['HH AE1 N IH0 T IY0'],
  "hannoch": ['HH AE1 N AH0 K'],
  "hannold": ['HH AE1 N OW2 L D'],
  "hannon": ['HH AE1 N AH0 N'],
  "hannula": ['HH AE1 N UW0 L AH0'],
  "hannum": ['HH AE1 N AH0 M'],
  "hanny": ['HH AE1 N IY0'],
  "hano": ['HH AA1 N OW0'],
  "hanoi": ['HH AE1 N OY0'],
  "hanoi's": ['HH AH0 N OY1 Z'],
  "hanold": ['HH AE1 N OW0 L D'],
  "hanover": ['HH AE1 N OW0 V ER0'],
  "hanover's": ['HH AE1 N OW0 V ER0 Z'],
  "hanoverian": ['HH AE2 N OW0 V IH1 R IY0 AH0 N'],
  "hanrahan": ['HH AE1 N R AH0 HH AE0 N'],
  "hanratty": ['HH AE1 N R AH0 T IY0'],
  "hans": ['HH AA1 N S', 'HH AE1 N Z'],
  "hansa": ['HH AE1 N S AH0'],
  "hansard": ['HH AE1 N S ER0 D'],
  "hansberger": ['HH AE1 N S B ER0 G ER0'],
  "hansberry": ['HH AE1 N S B EH2 R IY0'],
  "hansbrough": ['HH AE1 N S B R AW0'],
  "hansbury": ['HH AE1 N S B EH0 R IY0'],
  "hansche": ['HH AE1 N SH IY0'],
  "hanscom": ['HH AE1 N S K AH0 M'],
  "hanseatic": ['HH AE2 N S IY0 AE1 T IH0 K'],
  "hansel": ['HH AE1 N S AH0 L'],
  "hansell": ['HH AE1 N S AH0 L'],
  "hanselman": ['HH AE1 N S AH0 L M AH0 N'],
  "hansen": ['HH AE1 N S AH0 N'],
  "hansen's": ['HH AE1 N S AH0 N Z'],
  "hanser": ['HH AA1 N S ER0'],
  "hansford": ['HH AE1 N S F ER0 D'],
  "hanshaw": ['HH AE1 N SH AO2'],
  "hanshew": ['HH AE1 N SH UW0'],
  "hanshin": ['HH AE1 N SH IH0 N'],
  "hansley": ['HH AE1 N S L IY0'],
  "hansma": ['HH AE1 N S M AH0'],
  "hansman": ['HH AE1 N S M AH0 N'],
  "hansmann": ['HH AE1 N S M AH0 N'],
  "hansom": ['HH AE1 N S AH0 M'],
  "hanson": ['HH AE1 N S AH0 N'],
  "hanson's": ['HH AE1 N S AH0 N Z'],
  "hanssen": ['HH AE1 N S AH0 N'],
  "hansson": ['HH AE1 N S AH0 N'],
  "hanta": ['HH AE1 N T AH0', 'HH AA1 N T AH0'],
  "hantavirus": ['HH AE1 N T AH0 V AY2 R AH0 S'],
  "hanten": ['HH AE1 N T AH0 N'],
  "hanthorn": ['HH AE1 N TH ER0 N'],
  "hantman": ['HH AE1 N T M AH0 N'],
  "hantz": ['HH AE1 N T S'],
  "hanukkah": ['HH AA1 N AH0 K AH0'],
  "hanukkah's": ['HH AA1 N AH0 K AH0 Z'],
  "hanukkahs": ['HH AA1 N AH0 K AH0 Z'],
  "hanus": ['HH EY1 N IH0 S'],
  "hanvey": ['HH AE1 N V IY0'],
  "hanwa": ['HH AE1 N W AH0'],
  "hanwa's": ['HH AE1 N W AH0 Z'],
  "hanway": ['HH AE1 N W EY2'],
  "hany": ['HH EY1 N IY0'],
  "hany's": ['HH EY1 N IY0 Z'],
  "hanzel": ['HH AE1 N Z AH0 L'],
  "hanzlik": ['HH AE1 N Z L IH0 K'],
  "hao": ['HH AW1'],
  "hao-chi": ['HH AW1 CH IY1'],
  "hap": ['HH AE1 P'],
  "hapag": ['HH EY1 P AE2 G'],
  "hapeman": ['HH EY1 P M AH0 N'],
  "hapgood": ['HH AE1 P G UH2 D'],
  "haphazard": ['HH AE0 P HH AE1 Z ER0 D'],
  "haphazardly": ['HH AE1 F AH0 Z ER0 D L IY0', 'HH AE0 P HH AE1 Z ER0 D L IY0'],
  "hapke": ['HH EY1 P K IY0'],
  "hapless": ['HH AE1 P L AH0 S'],
  "haploid": ['HH AE1 P L OY0 D'],
  "hapner": ['HH AE1 P N ER0'],
  "hapoalim": ['HH AH0 P OW1 L IH0 M', 'HH AH0 P OW2 AH0 L IY1 M'],
  "happ": ['HH AE1 P'],
  "happe": ['HH AE1 P'],
  "happel": ['HH AE1 P AH0 L'],
  "happen": ['HH AE1 P AH0 N'],
  "happened": ['HH AE1 P AH0 N D'],
  "happening": ['HH AE1 P AH0 N IH0 NG', 'HH AE1 P N IH0 NG'],
  "happenings": ['HH AE1 P AH0 N IH0 NG Z', 'HH AE1 P N IH0 NG Z'],
  "happens": ['HH AE1 P AH0 N Z'],
  "happenstance": ['HH AE1 P AH0 N S T AE2 N S'],
  "happier": ['HH AE1 P IY0 ER0'],
  "happiest": ['HH AE1 P IY0 AH0 S T'],
  "happily": ['HH AE1 P AH0 L IY0'],
  "happiness": ['HH AE1 P IY0 N AH0 S'],
  "happy": ['HH AE1 P IY0'],
  "hapsburg": ['HH AE1 P S B ER0 G'],
  "haq": ['HH AE1 K'],
  "haq's": ['HH AE1 K S'],
  "haque": ['HH AE1 K'],
  "har": ['HH AA1 R'],
  "hara": ['HH EH1 R AH0'],
  "hara-kiri": ['HH AA1 R IH0 K IH1 R IY0'],
  "harada": ['HH AA0 R AA1 D AH0'],
  "haradim": ['HH AH0 R AE1 D IH2 M', 'HH AH0 R AE2 D IY1 M'],
  "harahan": ['HH AE1 R AH0 HH AE2 N'],
  "harald": ['HH AA1 R AH0 L D'],
  "haralda": ['HH AA0 R AA1 L D AH0'],
  "haralson": ['HH AE1 R AH0 L S AH0 N'],
  "haran": ['HH AE1 R AH0 N'],
  "harangue": ['HH ER0 AE1 NG'],
  "harangued": ['HH ER0 AE1 NG D'],
  "harangues": ['HH ER0 AE1 NG Z'],
  "haranguing": ['HH ER0 AE1 NG IH0 NG'],
  "harare": ['HH ER0 AA1 R IY0'],
  "harass": ['HH ER0 AE1 S'],
  "harassed": ['HH ER0 AE1 S T'],
  "harasser": ['HH ER0 AE1 S ER0'],
  "harassers": ['HH ER0 AE1 S ER0 Z'],
  "harasses": ['HH ER0 AE1 S IH0 Z'],
  "harassing": ['HH ER0 AE1 S IH0 NG'],
  "harassment": ['HH ER0 AE1 S M AH0 N T'],
  "haraszti": ['HH ER0 AE1 S T IY0'],
  "haraway": ['HH AA1 R AH0 W EY0'],
  "harb": ['HH AA1 R B'],
  "harbach": ['HH AA1 R B AA2 K'],
  "harbaugh": ['HH AA1 R B AO2'],
  "harbeck": ['HH AA1 R B EH2 K'],
  "harber": ['HH AA1 R B ER0'],
  "harbert": ['HH AA1 R B ER0 T'],
  "harberts": ['HH AA1 R B ER0 T S'],
  "harbeson": ['HH AA1 R B IH0 S AH0 N'],
  "harbin": ['HH AA1 R B IH0 N'],
  "harbinger": ['HH AA1 R B IH0 N JH ER0'],
  "harbingers": ['HH AA1 R B IH0 NG ER0 Z'],
  "harbinson": ['HH AA1 R B IH0 N S AH0 N'],
  "harbison": ['HH AA1 R B IH0 S AH0 N'],
  "harbold": ['HH AA1 R B OW2 L D'],
  "harbor": ['HH AA1 R B ER0'],
  "harbor's": ['HH AA1 R B ER0 Z'],
  "harbored": ['HH AA1 R B ER0 D'],
  "harboring": ['HH AA1 R B ER0 IH0 NG'],
  "harbors": ['HH AA1 R B ER0 Z'],
  "harborside": ['HH AA1 R B ER0 S AY2 D'],
  "harbory": ['HH AA1 R B AH0 R IY0'],
  "harbour": ['HH AA1 R B ER0'],
  "harbuck": ['HH AA1 R B AH0 K'],
  "harbury": ['HH AA1 R B ER0 IY0'],
  "harc": ['HH AA1 R K'],
  "harclerode": ['HH AA1 R K AH0 L ER0 OW0 D', 'HH AA1 R K L ER0 OW0 D'],
  "harcourt": ['HH AA1 R K AO2 R T'],
  "harcourt's": ['HH AA1 R K ER0 T S'],
  "harcrow": ['HH AA1 R K R OW0'],
  "harcum": ['HH AA1 R K AH0 M'],
  "hard": ['HH AA1 R D'],
  "hard-ass": ['HH AA2 R D AE2 S'],
  "hard-working": ['HH AA2 R D HH W ER1 K IH0 NG'],
  "hardacre": ['HH AA1 R D EY2 K ER0'],
  "hardage": ['HH AA1 R D IH0 JH'],
  "hardart": ['HH AA1 R D AA2 R T'],
  "hardaway": ['HH AA1 R D AH0 W EY2'],
  "hardback": ['HH AA1 R D B AE2 K'],
  "hardball": ['HH AA1 R D B AO2 L'],
  "hardboard": ['HH AA1 R D B AO2 R D'],
  "hardcastle": ['HH AA1 R D K AE2 S AH0 L'],
  "hardcastle's": ['HH AA1 R D K AE2 S AH0 L Z'],
  "hardcore": ['HH AA1 R D K AO1 R'],
  "hardcover": ['HH AA1 R D K AH2 V ER0'],
  "hardcovers": ['HH AA1 R D K AH2 V ER0 Z'],
  "hardebeck": ['HH AA1 R D B EH0 K'],
  "hardee": ['HH AA1 R D IY1'],
  "hardee's": ['HH AA1 R D IY1 Z'],
  "hardegree": ['HH AA0 R D IH0 G R IY1'],
  "hardeman": ['HH AA1 R D M AH0 N'],
  "harden": ['HH AA1 R D AH0 N'],
  "harden's": ['HH AA1 R D AH0 N Z'],
  "hardenbrook": ['HH AA1 R D AH0 N B R UH2 K'],
  "hardened": ['HH AA1 R D AH0 N D'],
  "hardener": ['HH AA1 R D AH0 N ER0'],
  "hardeners": ['HH AA1 R D AH0 N ER0 Z'],
  "hardening": ['HH AA1 R D AH0 N IH0 NG', 'HH AA1 R D N IH0 NG'],
  "hardens": ['HH AA1 R D AH0 N Z'],
  "harder": ['HH AA1 R D ER0'],
  "harders": ['HH AA1 R D ER0 Z'],
  "hardest": ['HH AA1 R D AH0 S T'],
  "hardesty": ['HH AA1 R D AH0 S T IY0'],
  "hardey": ['HH AA1 R D IY0'],
  "hardgood": ['HH AA1 R D G UH2 D'],
  "hardgoods": ['HH AA1 R D G UH2 D Z'],
  "hardgrave": ['HH AA1 R D G R EY2 V'],
  "hardgrove": ['HH AA1 R D G R OW2 V'],
  "hardhead": ['HH AA1 R D HH EH2 D'],
  "hardheaded": ['HH AA1 R D HH EH2 D IH0 D'],
  "hardick": ['HH AA1 R D IH0 K'],
  "hardie": ['HH AA1 R D IY0'],
  "hardier": ['HH AA1 R D IY0 ER0'],
  "hardiest": ['HH AA1 R D IY0 AH0 S T'],
  "hardigree": ['HH AA0 R D IH0 G R IY1'],
  "hardiman": ['HH AA1 R D IH0 M AH0 N'],
  "hardimon": ['HH AA1 R D IH0 M AA0 N'],
  "hardin": ['HH AA1 R D IH0 N'],
  "harding": ['HH AA1 R D IH0 NG'],
  "harding's": ['HH AA1 R D IH0 NG S'],
  "hardinger": ['HH AA1 R D IH0 NG ER0'],
  "hardison": ['HH AA1 R D IH0 S AH0 N'],
  "hardister": ['HH AA1 R D IH0 S T ER0'],
  "hardisty": ['HH AA1 R D IH0 S T IY0'],
  "hardline": ['HH AA1 R D L AY2 N'],
  "hardliner": ['HH AA1 R D L AY2 N ER0'],
  "hardliners": ['HH AA1 R D L AY2 N ER0 Z'],
  "hardly": ['HH AA1 R D L IY0'],
  "hardman": ['HH AA1 R D M AH0 N'],
  "hardness": ['HH AA1 R D N AH0 S'],
  "hardnett": ['HH AA1 R D N IH0 T'],
  "hardpressed": ['HH AA1 R D P R EH2 S T'],
  "hardrick": ['HH AA1 R D R IH0 K'],
  "hards": ['HH AA1 R D Z'],
  "hardscrabble": ['HH AA1 R D S K R AE2 B AH0 L'],
  "hardship": ['HH AA1 R D SH IH0 P'],
  "hardships": ['HH AA1 R D SH IH0 P S'],
  "hardt": ['HH AA1 R T'],
  "hardtke": ['HH AA1 R D K IY0'],
  "hardware": ['HH AA1 R D W EH2 R'],
  "hardway": ['HH AA1 R D W EY2'],
  "hardwick": ['HH AA1 R D W IH2 K'],
  "hardwicke": ['HH AA1 R D W IH0 K'],
  "hardwin": ['HH AA1 R D W IH0 N'],
  "hardwired": ['HH AA2 R D W AY1 ER0 D'],
  "hardwood": ['HH AA1 R D W UH2 D'],
  "hardwoods": ['HH AA1 R D W UH2 D Z'],
  "hardwork": ['HH AA1 R D W ER2 K'],
  "hardworking": ['HH AA1 R D W ER2 K IH0 NG'],
  "hardy": ['HH AA1 R D IY0'],
  "hardy's": ['HH AA1 R D IY0 Z'],
  "hardymon": ['HH AA1 R D IY0 M AA2 N'],
  "hare": ['HH EH1 R'],
  "hare's": ['HH EH1 R Z'],
  "harebrained": ['HH EH1 R B R EY2 N D'],
  "harelson": ['HH AE1 R IH0 L S AH0 N'],
  "harem": ['HH EH1 R AH0 M'],
  "haren": ['HH EH1 R AH0 N'],
  "harer": ['HH EH1 R ER0'],
  "hares": ['HH EH1 R Z'],
  "harewood": ['HH EH1 R W UH2 D'],
  "harff": ['HH AA1 R F'],
  "harford": ['HH AA1 R F ER0 D'],
  "hargadon": ['HH AA0 R G AA0 D AO1 N'],
  "hargan": ['HH AA1 R G AH0 N'],
  "hargarten": ['HH AA1 R G AA0 R T AH0 N'],
  "hargens": ['HH AA1 R G AH0 N Z'],
  "harger": ['HH AA1 R G ER0'],
  "hargett": ['HH AA1 R JH IH0 T'],
  "hargis": ['HH AA1 R G IH0 S'],
  "hargrave": ['HH AA1 R G R EY2 V'],
  "hargraves": ['HH AA1 R G R EY2 V Z'],
  "hargreaves": ['HH AA1 R G R IY2 V Z'],
  "hargrove": ['HH AA1 R G R OW2 V'],
  "hargus": ['HH AA1 R G AH0 S'],
  "hari": ['HH AA1 R IY0'],
  "harig": ['HH AE1 R IH0 G'],
  "harima": ['HH EH0 R IY1 M AH0'],
  "haring": ['HH EH1 R IH0 NG'],
  "hariri": ['HH ER0 IH1 R IY0'],
  "haris": ['HH AA1 R IY0 S', 'HH EH1 R IH0 S'],
  "haritos": ['HH EH0 R IY1 T OW0 S'],
  "hariz": ['HH EH1 R IH0 Z'],
  "harjo": ['HH AA1 R JH OW0'],
  "harju": ['HH AA1 R Y UW0'],
  "hark": ['HH AA1 R K'],
  "harkavy": ['HH AA1 R K AH0 V IY0'],
  "harke": ['HH AA1 R K'],
  "harken": ['HH AA1 R K AH0 N'],
  "harkens": ['HH AA1 R K AH0 N Z'],
  "harker": ['HH AA1 R K ER0'],
  "harkey": ['HH AA1 R K IY0'],
  "harkin": ['HH AA1 R K IH0 N'],
  "harkin's": ['HH AA1 R K IH0 N Z'],
  "harking": ['HH AA1 R K IH0 NG'],
  "harkins": ['HH AA1 R K IH0 N Z'],
  "harkleroad": ['HH AA1 R K L ER0 OW0 D'],
  "harkless": ['HH AA1 R K L AH0 S'],
  "harkness": ['HH AA1 R K N AH0 S'],
  "harkrader": ['HH AA1 R K R AH0 D ER0'],
  "harkrider": ['HH AA1 R K R AY2 D ER0'],
  "harks": ['HH AA1 R K S'],
  "harl": ['HH AA1 R L'],
  "harlacher": ['HH AA1 R L AH0 K ER0'],
  "harlan": ['HH AA1 R L AH0 N'],
  "harland": ['HH AA1 R L AH0 N D'],
  "harle": ['HH AA1 R AH0 L'],
  "harlem": ['HH AA1 R L AH0 M'],
  "harlem's": ['HH AA1 R L AH0 M Z'],
  "harleman": ['HH AA1 R AH0 L M AH0 N'],
  "harlequin": ['HH AA1 R L AH0 K W AH0 N'],
  "harless": ['HH AA1 R L IH0 S'],
  "harley": ['HH AA1 R L IY0'],
  "harley's": ['HH AA1 R L IY0 Z'],
  "harleysville": ['HH AA1 R L IY0 Z V IH2 L'],
  "harlin": ['HH AA1 R L IH0 N'],
  "harlin's": ['HH AA1 R L IH0 N Z'],
  "harling": ['HH AA1 R L IH0 NG'],
  "harlingen": ['HH AA1 R L IH0 NG AH0 N'],
  "harlison": ['HH AA1 R L IH0 S AH0 N'],
  "harloff": ['HH AA1 R L AO0 F'],
  "harlot": ['HH AA1 R L AH0 T'],
  "harlow": ['HH AA1 R L OW0'],
  "harm": ['HH AA1 R M'],
  "harm's": ['HH AA1 R M Z'],
  "harman": ['HH AA1 R M AH0 N'],
  "harmata": ['HH AA0 R M AA1 T AH0'],
  "harmattan": ['HH AA2 R M AH0 T AE1 N'],
  "harmed": ['HH AA1 R M D'],
  "harmel": ['HH AA1 R M AH0 L'],
  "harmen": ['HH AA1 R M AH0 N'],
  "harmening": ['HH AA1 R M AH0 N IH0 NG'],
  "harmer": ['HH AA1 R M ER0'],
  "harmes": ['HH AA1 R M Z'],
  "harmetz": ['HH AA1 R M EH2 T S'],
  "harmeyer": ['HH AA1 R M AY2 ER0'],
  "harmful": ['HH AA1 R M F AH0 L'],
  "harmfully": ['HH AA1 R M F AH0 L IY0'],
  "harmfulness": ['HH AA1 R M F AH0 L N AH0 S'],
  "harming": ['HH AA1 R M IH0 NG'],
  "harmison": ['HH AA1 R M IH0 S AH0 N'],
  "harmless": ['HH AA1 R M L AH0 S'],
  "harmlessly": ['HH AA1 R M L AH0 S L IY0'],
  "harmon": ['HH AA1 R M AH0 N'],
  "harmon's": ['HH AA1 R M AH0 N Z'],
  "harmonia": ['HH AA0 R M OW1 N IY0 AH0'],
  "harmonic": ['HH AA0 R M AA1 N IH0 K'],
  "harmonica": ['HH AA0 R M AA1 N IH0 K AH0'],
  "harmonics": ['HH AA0 R M AA1 N IH0 K S'],
  "harmonie": ['HH AA1 R M AH0 N IY0'],
  "harmonies": ['HH AA1 R M AH0 N IY0 Z'],
  "harmonious": ['HH AA0 R M OW1 N IY0 AH0 S'],
  "harmoniously": ['HH AA0 R M OW1 N IY0 AH0 S L IY0'],
  "harmonium": ['HH AA0 R M OW1 N IY0 AH0 M'],
  "harmonization": ['HH AA2 R M AH0 N IH0 Z EY1 SH AH0 N'],
  "harmonize": ['HH AA1 R M AH0 N AY2 Z'],
  "harmonized": ['HH AA1 R M AH0 N AY2 Z D'],
  "harmonizes": ['HH AA1 R M AH0 N AY2 Z IH0 Z'],
  "harmonizing": ['HH AA1 R M AH0 N AY2 Z IH0 NG'],
  "harmony": ['HH AA1 R M AH0 N IY0'],
  "harms": ['HH AA1 R M Z'],
  "harmsen": ['HH AA1 R M S AH0 N'],
  "harn": ['HH AA1 R N'],
  "harnack": ['HH AA1 R N AH0 K'],
  "harnage": ['HH AA1 R N IH0 JH'],
  "harnden": ['HH AA1 R N D AH0 N'],
  "harne": ['HH AA1 R N'],
  "harned": ['HH AA1 R N D'],
  "harner": ['HH AA1 R N ER0'],
  "harness": ['HH AA1 R N AH0 S', 'HH AA1 R N IH0 S'],
  "harnessed": ['HH AA1 R N AH0 S T'],
  "harnesses": ['HH AA1 R N AH0 S AH0 Z', 'HH AA1 R N AH0 S IH0 Z'],
  "harnessing": ['HH AA1 R N AH0 S IH0 NG'],
  "harnett": ['HH AA1 R N IH0 T'],
  "harney": ['HH AA1 R N IY0'],
  "harnisch": ['HH AA1 R N IH0 SH'],
  "harnischfeger": ['HH AA1 R N IH0 SH F EH2 G ER0'],
  "harnischfeger's": ['HH AA1 R N IH0 SH F EH2 G ER0 Z'],
  "harnish": ['HH AA1 R N IH0 SH'],
  "harnois": ['HH AA0 R N W AA1'],
  "haro": ['HH AA1 R OW0'],
  "harold": ['HH EH1 R AH0 L D'],
  "harold's": ['HH EH1 R AH0 L D Z'],
  "haroldson": ['HH AE1 R OW0 L D S AH0 N'],
  "harp": ['HH AA1 R P'],
  "harpe": ['HH AA1 R P'],
  "harped": ['HH AA1 R P T'],
  "harpel": ['HH AA0 R P EH1 L'],
  "harpenau": ['HH AA1 R P IH0 N OW0'],
  "harper": ['HH AA1 R P ER0'],
  "harper's": ['HH AA1 R P ER0 Z'],
  "harpercollins": ['HH AA1 R P ER0 K AO1 L IH0 N Z'],
  "harpers": ['HH AA1 R P ER0 Z'],
  "harpham": ['HH AA1 R F AH0 M'],
  "harpin": ['HH AA0 R P AE1 N'],
  "harping": ['HH AA1 R P IH0 NG'],
  "harpist": ['HH AA1 R P IH0 S T'],
  "harpists": ['HH AA1 R P IH0 S T S'],
  "harpley": ['HH AA1 R P L IY0'],
  "harpo": ['HH AA1 R P OW0'],
  "harpold": ['HH AA1 R P OW2 L D'],
  "harpole": ['HH AA1 R P OW2 L'],
  "harpoon": ['HH AA0 R P UW1 N'],
  "harpoons": ['HH AA0 R P UW1 N Z'],
  "harpootlian": ['HH AA0 R P UW1 T L IY0 AH0 N'],
  "harps": ['HH AA1 R P S'],
  "harpsichord": ['HH AA1 R P S AH0 K AO2 R D'],
  "harpst": ['HH AA1 R P S T'],
  "harpster": ['HH AA1 R P S T ER0'],
  "harquebus": ['HH AA1 R K W AH0 B AH0 S'],
  "harr": ['HH AE1 R'],
  "harra": ['HH AA1 R AH0'],
  "harrah": ['HH AE1 R AH0'],
  "harrah's": ['HH EH1 R AH0 Z'],
  "harral": ['HH AE1 R AH0 L'],
  "harralson": ['HH AE1 R AH0 L S AH0 N'],
  "harre": ['HH AE1 R'],
  "harrel": ['HH AE1 R AH0 L'],
  "harreld": ['HH AE1 R IH0 L D'],
  "harrell": ['HH EH1 R AH0 L'],
  "harrell's": ['HH AE1 R AH0 L Z'],
  "harrelson": ['HH EH1 R IH0 L S AH0 N'],
  "harren": ['HH AE1 R AH0 N'],
  "harrer": ['HH AA1 R ER0'],
  "harried": ['HH EH1 R IY0 D'],
  "harrier": ['HH EH1 R IY0 ER0'],
  "harries": ['HH EH1 R IY0 Z'],
  "harriet": ['HH EH1 R IY0 AH0 T'],
  "harriette": ['HH AE1 R IY0 EH0 T'],
  "harrigan": ['HH EH1 R IH0 G AH0 N'],
  "harriger": ['HH AE1 R IH0 G ER0'],
  "harrill": ['HH AE1 R AH0 L'],
  "harriman": ['HH EH1 R IH0 M AH0 N'],
  "harring": ['HH AE1 R IH0 NG'],
  "harrington": ['HH EH1 R IH0 NG T AH0 N'],
  "harriott": ['HH AE1 R IY0 AH0 T'],
  "harris": ['HH EH1 R IH0 S'],
  "harris'": ['HH EH1 R IH0 S'],
  "harris's": ['HH EH1 R IH0 S IH0 Z'],
  "harrisburg": ['HH AE1 R IH0 S B ER0 G', 'HH EH1 R IH0 S B ER0 G'],
  "harrisburg's": ['HH AE1 R IH0 S B ER0 G Z', 'HH EH1 R IH0 S B ER0 G Z'],
  "harrisburgh": ['HH AE1 R IH0 S B ER0 G', 'HH EH1 R IH0 S B ER0 G'],
  "harrisburgh's": ['HH AE1 R IH0 S B ER0 G Z', 'HH EH1 R IH0 S B ER0 G Z'],
  "harrises": ['HH AE1 R IH0 S IH0 Z'],
  "harrison": ['HH EH1 R IH0 S AH0 N'],
  "harrison's": ['HH EH1 R IH0 S AH0 N Z'],
  "harriss": ['HH AE1 R IH0 S'],
  "harrity": ['HH AE1 R IH0 T IY0'],
  "harrod": ['HH EH1 R AH0 D'],
  "harrod's": ['HH EH1 R AH0 D Z'],
  "harrods": ['HH EH1 R AH0 D Z'],
  "harrold": ['HH EH1 R AH0 L D'],
  "harron": ['HH AE1 R AH0 N'],
  "harrop": ['HH EH1 R AH0 P'],
  "harroun": ['HH ER0 UW1 N'],
  "harrow": ['HH AE1 R OW0'],
  "harrower": ['HH AE1 R OW0 W ER0'],
  "harrowing": ['HH EH1 R OW0 IH0 NG'],
  "harry": ['HH EH1 R IY0'],
  "harry's": ['HH EH1 R IY0 Z'],
  "harryman": ['HH AE1 R IY0 M AH0 N'],
  "harsch": ['HH AA1 R SH'],
  "harsco": ['HH AA1 R S K OW0'],
  "harsh": ['HH AA1 R SH'],
  "harsha": ['HH AA1 R SH AH0'],
  "harsham": ['HH AA1 R SH AH0 M'],
  "harshaw": ['HH AA1 R SH AO2'],
  "harshbarger": ['HH AA1 R SH B AA2 R G ER0'],
  "harshberger": ['HH AA1 R SH B ER0 G ER0'],
  "harsher": ['HH AA1 R SH ER0'],
  "harshest": ['HH AA1 R SH AH0 S T'],
  "harshfield": ['HH AA1 R SH F IY2 L D'],
  "harshly": ['HH AA1 R SH L IY0'],
  "harshman": ['HH AA1 R SH M AH0 N'],
  "harshness": ['HH AA1 R SH N AH0 S'],
  "harstad": ['HH AA1 R S T AH0 D'],
  "harston": ['HH AA1 R S T AH0 N'],
  "hart": ['HH AA1 R T'],
  "hart's": ['HH AA1 R T S'],
  "harte": ['HH AA1 R T'],
  "hartel": ['HH AA1 R T AH0 L'],
  "hartell": ['HH AA1 R T AH0 L'],
  "harten": ['HH AA1 R T AH0 N'],
  "hartenstein": ['HH AA1 R T AH0 N S T AY2 N', 'HH AA1 R T AH0 N S T IY2 N'],
  "harter": ['HH AA1 R T ER0'],
  "hartfiel": ['HH AA1 R T F IY2 L'],
  "hartfield": ['HH AA1 R T F IY2 L D'],
  "hartford": ['HH AA1 R T F ER0 D'],
  "hartford's": ['HH AA1 R T F ER0 D Z'],
  "hartgraves": ['HH AA1 R T G R EY2 V Z'],
  "hartgrove": ['HH AA1 R T G R OW2 V'],
  "harth": ['HH AA1 R TH'],
  "hartig": ['HH AA1 R T IH0 G'],
  "hartigan": ['HH AA1 R T IH0 G AH0 N'],
  "hartin": ['HH AA1 R T IH0 N'],
  "harting": ['HH AA1 R T IH0 NG'],
  "hartinger": ['HH AA1 R T IH0 NG ER0'],
  "hartis": ['HH AA1 R T IH0 S'],
  "hartje": ['HH AA1 R T JH EY0'],
  "hartke": ['HH AA1 R T K IY0'],
  "hartkopf": ['HH AA1 R T K AO0 F'],
  "hartl": ['HH AA1 R T AH0 L'],
  "hartlage": ['HH AA1 R T L IH0 JH'],
  "hartland": ['HH AA1 R T L AH0 N D'],
  "hartlaub": ['HH AA1 R T L AW2 B'],
  "hartle": ['HH AA1 R T AH0 L'],
  "hartless": ['HH AA1 R T L AH0 S'],
  "hartley": ['HH AA1 R T L IY0'],
  "hartlieb": ['HH AA1 R T L IY2 B'],
  "hartline": ['HH AA1 R T L AY2 N'],
  "hartling": ['HH AA1 R T L IH0 NG'],
  "hartman": ['HH AA1 R T M AH0 N'],
  "hartmann": ['HH AA1 R T M AH0 N'],
  "hartmarx": ['HH AA1 R T M AA2 R K S'],
  "hartnell": ['HH AA1 R T N AH0 L'],
  "hartner": ['HH AA1 R T N ER0'],
  "hartness": ['HH AA1 R T N AH0 S'],
  "hartnett": ['HH AA1 R T N IH0 T'],
  "hartney": ['HH AA1 R T N IY0'],
  "hartog": ['HH AA1 R T AH0 G'],
  "harton": ['HH AA1 R T AH0 N'],
  "hartong": ['HH AA1 R T AO0 NG'],
  "hartranft": ['HH AA1 R T R AH2 N F T'],
  "harts": ['HH AA1 R T S'],
  "hartsell": ['HH AA1 R T S AH0 L'],
  "hartsfield": ['HH AA1 R T S F IY2 L D'],
  "hartshorn": ['HH AA1 R T S HH AO2 R N'],
  "hartshorne": ['HH AA1 R CH ER0 N'],
  "hartsock": ['HH AA1 R T S AH0 K'],
  "hartsoe": ['HH AA1 R T S OW0'],
  "hartson": ['HH AA1 R T S AH0 N'],
  "hartsook": ['HH AA1 R T S UH0 K'],
  "hartsough": ['HH AA1 R T S AW0'],
  "hartstein": ['HH AA1 R T S T AY2 N', 'HH AA1 R T S T IY2 N'],
  "hartsville": ['HH AA1 R T S V IH0 L'],
  "hartt": ['HH AA1 R T'],
  "hartter": ['HH AA1 R T ER0'],
  "hartung": ['HH AA1 R T AH0 NG'],
  "hartwell": ['HH AA1 R T W EH2 L'],
  "hartwick": ['HH AA1 R T W IH2 K'],
  "hartwig": ['HH AA1 R T W IH0 K'],
  "hartwood": ['HH AA1 R T W UH2 D'],
  "harty": ['HH AA1 R T IY0'],
  "hartz": ['HH AA1 R T S'],
  "hartzel": ['HH AA1 R T Z AH0 L'],
  "hartzell": ['HH AA1 R T Z AH0 L'],
  "hartzler": ['HH AA1 R T S L ER0'],
  "hartzog": ['HH AA1 R T Z AH0 G'],
  "haruo": ['HH AA0 R UW1 OW0'],
  "harv": ['HH AA1 R V'],
  "harvard": ['HH AA1 R V ER0 D'],
  "harvard's": ['HH AA1 R V ER0 D Z'],
  "harvath": ['HH AA1 R V AH0 TH'],
  "harve": ['HH AA1 R V'],
  "harvel": ['HH AA1 R V AH0 L'],
  "harvell": ['HH AA1 R V AH0 L'],
  "harvest": ['HH AA1 R V AH0 S T'],
  "harvestable": ['HH AA1 R V AH0 S T AH0 B AH0 L'],
  "harvested": ['HH AA1 R V AH0 S T IH0 D'],
  "harvester": ['HH AA1 R V AH0 S T ER0'],
  "harvesters": ['HH AA1 R V AH0 S T ER0 Z'],
  "harvesting": ['HH AA1 R V AH0 S T IH0 NG'],
  "harvests": ['HH AA1 R V AH0 S T S'],
  "harvey": ['HH AA1 R V IY0'],
  "harvey's": ['HH AA1 R V IY0 Z'],
  "harvie": ['HH AA1 R V IY0'],
  "harvill": ['HH AA1 R V IH0 L'],
  "harville": ['HH AA1 R V IH2 L'],
  "harvin": ['HH AA1 R V IH0 N'],
  "harvison": ['HH AA1 R V IH0 S AH0 N'],
  "harward": ['HH AA1 R W ER0 D'],
  "harwell": ['HH AA1 R W EH2 L'],
  "harwick": ['HH AA1 R W IH0 K'],
  "harwood": ['HH AA1 R W UH2 D'],
  "haryana": ['HH EH0 R Y AA1 N AH0'],
  "has": ['HH AE1 Z', 'HH AH0 Z'],
  "has-been": ['HH AE1 Z B IH2 N'],
  "has-beens": ['HH AE1 Z B IH2 N Z'],
  "hasan": ['HH EY1 Z AH0 N'],
  "hasbro": ['HH AE1 Z B R OW0'],
  "hasbro's": ['HH AE1 Z B R OW2 Z'],
  "hasbrook": ['HH AE1 S B R UH0 K'],
  "hasbrouck": ['HH AE1 Z B R UH2 K'],
  "hascall": ['HH AE1 S K AH0 L'],
  "hasch": ['HH AE1 SH'],
  "haschke": ['HH AE1 SH K'],
  "hase": ['HH EY1 Z'],
  "hasegawa": ['HH AA2 S EY0 G AA1 W AH0'],
  "hasek": ['HH AA1 S EH0 K'],
  "haselden": ['HH AE1 S IH0 L D AH0 N'],
  "haseley": ['HH AE1 S IH0 L IY0'],
  "haselhorst": ['HH AE1 S IH0 L HH AO0 R S T'],
  "haseltine": ['HH AE1 S IH0 L T IY0 N'],
  "haselton": ['HH AH0 S EH1 L T AH0 N'],
  "haseman": ['HH EY1 S M AH0 N'],
  "hasenauer": ['HH AE1 S IH0 N AW0 ER0'],
  "hasenfus": ['HH EY1 Z AH0 N F AH2 S'],
  "haser": ['HH EH1 Z ER0'],
  "hash": ['HH AE1 SH'],
  "hashagen": ['HH AE1 SH AH0 G AH0 N'],
  "hashed": ['HH AE1 SH T'],
  "hashem": ['HH AE1 SH IH0 M'],
  "hashemi": ['HH AH0 SH EY1 M IY0'],
  "hashemite": ['HH AE1 SH AH0 M AY2 T'],
  "hasher": ['HH AE1 SH ER0'],
  "hasher's": ['HH AE1 SH ER0 Z'],
  "hashers": ['HH AE1 SH ER0 Z'],
  "hashes": ['HH AE1 SH AH0 Z'],
  "hashi": ['HH AE1 SH IY2'],
  "hashim": ['HH AE1 SH IH0 M'],
  "hashimoto": ['HH AA0 SH IY0 M OW1 T OW0'],
  "hashing": ['HH AE1 SH IH0 NG'],
  "hashish": ['HH AE1 SH IH0 SH', 'HH AH0 SH IY1 SH'],
  "hashman": ['HH AE1 SH M AH0 N'],
  "hashmark": ['HH AE1 SH M AA2 R K'],
  "hashmi": ['HH AE1 SH M IY0', 'HH AA1 SH M IY0'],
  "hashtag": ['HH AE1 SH T AE2 G'],
  "hasid": ['HH AH0 S IH1 D'],
  "hasidic": ['HH AH0 S IH1 D IH0 K', 'HH AH0 S IY1 D IH0 K'],
  "hasidim": ['HH AH0 S IH1 D IH0 M', 'HH AH0 S IY1 D IH0 M'],
  "hasids": ['HH AH0 S IH1 D Z'],
  "haske": ['HH EY1 S K'],
  "haskell": ['HH AE1 S K AH0 L'],
  "haskett": ['HH AE1 S K IH0 T'],
  "haskew": ['HH AE1 S K Y UW0'],
  "haskin": ['HH AE1 S K IH0 N'],
  "haskins": ['HH AE1 S K IH0 N Z'],
  "haslam": ['HH AE1 S L AH0 M'],
  "hasler": ['HH AE1 S AH0 L ER0', 'HH AE1 S L ER0'],
  "haslett": ['HH AE1 S L IH0 T'],
  "hasley": ['HH AE1 Z L IY0'],
  "hasman": ['HH AE1 S M AH0 N'],
  "hasn't": ['HH AE1 Z AH0 N T'],
  "haso": ['HH AA1 S OW2'],
  "haso's": ['HH AA1 S OW2 Z'],
  "haspel": ['HH AE1 S P AH0 L'],
  "hass": ['HH AE1 S'],
  "hassan": ['HH AH0 S AA1 N'],
  "hasse": ['HH AA1 S'],
  "hassebrock": ['HH AE1 S IH0 B R AA1 K'],
  "hassel": ['HH AE1 S AH0 L'],
  "hasselbach": ['HH AE1 S IH0 L B AA0 K'],
  "hasselbring": ['HH AE1 S IH0 L B R IH0 NG'],
  "hassell": ['HH AE1 S AH0 L'],
  "hasselman": ['HH AE1 S AH0 L M AH0 N'],
  "hassen": ['HH AE1 S AH0 N'],
  "hassenberg": ['HH AE1 S AH0 N B ER0 G'],
  "hassenfeld": ['HH AE1 S AH0 N F EH2 L D'],
  "hassett": ['HH AE1 S IH0 T'],
  "hassey": ['HH AE1 S IY0'],
  "hassig": ['HH AE1 S IH0 G'],
  "hassing": ['HH AE1 S IH0 NG'],
  "hassinger": ['HH AE1 S IH0 N JH ER0'],
  "hassle": ['HH AE1 S AH0 L'],
  "hassled": ['HH AE1 S AH0 L D'],
  "hassler": ['HH AE1 S L ER0'],
  "hassles": ['HH AE1 S AH0 L Z'],
  "hassling": ['HH AE1 S AH0 L IH0 NG', 'HH AE1 S L IH0 NG'],
  "hassman": ['HH AE1 S M AH0 N'],
  "hasson": ['HH AE1 S AH0 N'],
  "hast": ['HH AE1 S T'],
  "hasta": ['HH AE1 S T AH0', 'AA1 S T AH0'],
  "haste": ['HH EY1 S T'],
  "hasten": ['HH EY1 S AH0 N'],
  "hastened": ['HH EY1 S AH0 N D'],
  "hastening": ['HH EY1 S AH0 N IH0 NG', 'HH EY1 S N IH0 NG'],
  "hastens": ['HH EY1 S AH0 N Z'],
  "hastert": ['HH AE1 S T ER0 T'],
  "hastey": ['HH EY1 S T IY0'],
  "hastie": ['HH EY1 S T IY0'],
  "hastily": ['HH EY1 S T AH0 L IY0'],
  "hasting": ['HH EY1 S T IH0 NG'],
  "hastings": ['HH EY1 S T IH0 NG Z'],
  "haston": ['HH AE1 S T AH0 N'],
  "hasty": ['HH EY1 S T IY0'],
  "haswell": ['HH AE1 S W EH0 L'],
  "hasz": ['HH AA1 SH'],
  "hat": ['HH AE1 T'],
  "hat's": ['HH AE1 T S'],
  "hata": ['HH AA1 T AH0'],
  "hatala": ['HH AE1 T AH0 L AH0'],
  "hataway": ['HH AE1 T AH0 W EY2'],
  "hatch": ['HH AE1 CH'],
  "hatch's": ['HH AE1 CH IH0 Z'],
  "hatchback": ['HH AE1 CH B AE2 K'],
  "hatched": ['HH AE1 CH T'],
  "hatchel": ['HH AE1 CH AH0 L'],
  "hatchell": ['HH AE1 CH AH0 L'],
  "hatcher": ['HH AE1 CH ER0'],
  "hatcheries": ['HH AE1 CH ER0 IY0 Z'],
  "hatchery": ['HH AE1 CH ER0 IY0'],
  "hatches": ['HH AE1 CH IH0 Z'],
  "hatchet": ['HH AE1 CH AH0 T'],
  "hatchets": ['HH AE1 CH AH0 T S'],
  "hatchett": ['HH AE1 CH IH0 T'],
  "hatching": ['HH AE1 CH IH0 NG'],
  "hate": ['HH EY1 T'],
  "hated": ['HH EY1 T AH0 D', 'HH EY1 T IH0 D'],
  "hateful": ['HH EY1 T F AH0 L'],
  "hatem": ['HH AE1 T IH0 M'],
  "hater": ['HH EY1 T ER0'],
  "haters": ['HH EY1 T ER0 Z'],
  "hates": ['HH EY1 T S'],
  "hatfield": ['HH AE1 T F IY2 L D'],
  "hatfield's": ['HH AE1 T F IY2 L D Z'],
  "hath": ['HH AE1 TH'],
  "hathaway": ['HH AE1 TH AH0 W EY2'],
  "hathaway's": ['HH AE1 TH AH0 W EY2 Z'],
  "hathcoat": ['HH AE1 TH K OW2 T'],
  "hathcock": ['HH AE1 TH K AH0 K'],
  "hatheway": ['HH EY1 DH W EY0'],
  "hathorn": ['HH AA1 TH AO0 R N'],
  "hathorne": ['HH AE1 TH ER0 N'],
  "hating": ['HH EY1 T IH0 NG'],
  "hatler": ['HH EY1 T AH0 L ER0', 'HH EY1 T L ER0'],
  "hatlestad": ['HH AE1 T AH0 L S T AH0 D'],
  "hatley": ['HH AE1 T L IY0'],
  "hatmaker": ['HH AE1 T M EY2 K ER0'],
  "haton": ['HH AE1 T AO0 N', 'AE2 T AO1 N'],
  "hatred": ['HH EY1 T R AH0 D'],
  "hatreds": ['HH EY1 T R AH0 D Z'],
  "hatrick": ['HH AE1 T R IH0 K'],
  "hats": ['HH AE1 T S'],
  "hatt": ['HH AE1 T'],
  "hattabaugh": ['HH AE1 T AH0 B AO0'],
  "hattan": ['HH AE1 T AH0 N'],
  "hattaway": ['HH AE1 T AH0 W EY0'],
  "hatten": ['HH AE1 T AH0 N'],
  "hattendorf": ['HH AE1 T IH0 N D AO0 R F'],
  "hatter": ['HH AE1 T ER0'],
  "hatter's": ['HH AE1 T ER0 Z'],
  "hatteras": ['HH AE1 T ER0 AH0 S'],
  "hattersley": ['HH AE1 T ER0 Z L IY0'],
  "hattery": ['HH AE1 T ER0 IY0'],
  "hattie": ['HH AE1 T IY0'],
  "hatton": ['HH AE1 T AH0 N'],
  "hattori": ['HH AA0 T AO1 R IY0'],
  "hattusas": ['HH AH0 T UW1 S AH0 S'],
  "hatty": ['HH AE1 T IY0'],
  "hatz": ['HH AE1 T S'],
  "hau": ['HH AW1'],
  "haub": ['HH AO1 B'],
  "hauber": ['HH AW1 B ER0'],
  "haubert": ['HH AW1 B ER0 T'],
  "haubner": ['HH AW1 B N ER0'],
  "haubrich": ['HH AW1 B R IH0 K'],
  "hauch": ['HH AO1 CH'],
  "hauck": ['HH AO1 K'],
  "hauenstein": ['HH AW1 AH0 N S T AY0 N', 'HH AW1 AH0 N S T IY0 N'],
  "hauer": ['HH AW1 ER0'],
  "haueter": ['HH AW1 T ER0'],
  "hauf": ['HH AO1 F'],
  "haufer": ['HH AO1 F ER0'],
  "hauff": ['HH AO1 F'],
  "haug": ['HH AO1 G'],
  "haugan": ['HH AO1 G AH0 N'],
  "hauge": ['HH AO1 JH'],
  "haugen": ['HH AW1 G AH0 N'],
  "hauger": ['HH AW1 G ER0'],
  "haugh": ['HH AO1'],
  "haughey": ['HH AO1 K IY0'],
  "haughn": ['HH AO1 N'],
  "haught": ['HH AO1 T'],
  "haughtily": ['HH AO1 T IH0 L IY0'],
  "haughton": ['HH AO1 T AH0 N'],
  "haughty": ['HH AO1 T IY0'],
  "haugland": ['HH AO1 G L AH0 N D'],
  "hauk": ['HH AO1 K'],
  "hauke": ['HH AO1 K'],
  "haul": ['HH AO1 L'],
  "haulage": ['HH AO1 L AH0 JH'],
  "hauled": ['HH AO1 L D'],
  "hauler": ['HH AO1 L ER0'],
  "haulers": ['HH AO1 L ER0 Z'],
  "hauling": ['HH AO1 L IH0 NG'],
  "hauls": ['HH AO1 L Z'],
  "haulsey": ['HH AO1 L S IY0'],
  "haun": ['HH AO1 N'],
  "haunt": ['HH AO1 N T'],
  "haunted": ['HH AO1 N T IH0 D'],
  "haunting": ['HH AO1 N T IH0 NG'],
  "hauntingly": ['HH AO1 N T IH0 NG L IY0'],
  "haunts": ['HH AO1 N T S'],
  "haupert": ['HH AW1 P ER0 T'],
  "hauppauge": ['HH AW1 P AO0 JH'],
  "haupt": ['HH AO1 P T'],
  "hauptfuhrer": ['HH AW1 P T F Y UH2 R ER0'],
  "hauptman": ['HH AW1 P T M AH0 N'],
  "hauptmann": ['HH AW1 P T M AH0 N'],
  "haury": ['HH AO1 R IY0'],
  "haus": ['HH AW1 S'],
  "hausa": ['HH AW1 S AH0'],
  "hausauer": ['HH AW1 S AW0 ER0'],
  "hausch": ['HH AW1 SH'],
  "hauschild": ['HH AW1 S CH AY2 L D'],
  "hauschildt": ['HH AW1 SH IH0 L T'],
  "hause": ['HH AW1 S'],
  "hausen": ['HH AW1 Z AH0 N'],
  "hauser": ['HH AW1 Z ER0'],
  "hauserman": ['HH AW1 Z ER0 M AH0 N'],
  "hausfeld": ['HH AW1 S F EH2 L D'],
  "hausky": ['HH AO1 S K IY0'],
  "hausler": ['HH AW1 S AH0 L ER0', 'HH AW1 S L ER0'],
  "hausman": ['HH AW1 S M AH0 N'],
  "hausmann": ['HH AW1 S M AH0 N'],
  "hausner": ['HH AW1 S N ER0'],
  "hauss": ['HH AW1 S'],
  "hausser": ['HH AW1 S ER0'],
  "haussler": ['HH AW1 S AH0 L ER0', 'HH AW1 S L ER0'],
  "haussmann": ['HH AW1 S M AH0 N'],
  "hausummi": ['HH AW0 S UW1 M IY0'],
  "hauswirth": ['HH AW1 S W ER0 TH'],
  "haut": ['HH AO1 T'],
  "hautala": ['HH AO1 T AH0 L AH0'],
  "haute": ['HH OW1 T', 'HH AH1 T'],
  "hauter": ['HH AW1 T ER0'],
  "hauth": ['HH AO1 TH'],
  "hauver": ['HH AW1 V ER0'],
  "havana": ['HH AH0 V AE1 N AH0'],
  "havana's": ['HH AH0 V AE1 N AH0 Z'],
  "havard": ['HH AE1 V ER0 D'],
  "havas": ['HH AA1 V AA0 Z'],
  "have": ['HH AE1 V'],
  "havel": ['HH AE1 V AH0 L'],
  "havelka": ['HH AH0 V EH1 L K AH0'],
  "havelock": ['HH AE1 V L AA2 K'],
  "haveman": ['HH EY1 V M AH0 N'],
  "havemann": ['HH AE1 V AH0 M AH0 N'],
  "haven": ['HH EY1 V AH0 N'],
  "haven't": ['HH AE1 V AH0 N T', 'HH AE1 V AH0 N'],
  "havener": ['HH AE1 V IY0 N ER0'],
  "havens": ['HH EY1 V AH0 N Z'],
  "haver": ['HH EH1 V ER0'],
  "haverfield": ['HH AE1 V ER0 F IY2 L D'],
  "haverford": ['HH AE1 V ER0 F ER0 D'],
  "haverkamp": ['HH AE1 V ER0 K AE2 M P'],
  "haverland": ['HH AE1 V ER0 L AH0 N D'],
  "haverly": ['HH EY1 V ER0 L IY0'],
  "haverstick": ['HH EY1 V ER0 S T IH0 K'],
  "haverstock": ['HH EY1 V ER0 S T AA0 K'],
  "haverty": ['HH AE1 V ER0 T IY0'],
  "haves": ['HH AE1 V Z'],
  "havey": ['HH EY1 V IY0'],
  "havier": ['HH AE1 V Y ER0'],
  "haviland": ['HH EY1 V IY0 L AH0 N D'],
  "havill": ['HH AA0 V IY1 L'],
  "havilland": ['HH AE1 V AH0 L AH0 N D'],
  "havin'": ['HH AE1 V IH0 N'],
  "having": ['HH AE1 V IH0 NG'],
  "havington": ['HH AE1 V IH0 NG T AH0 N'],
  "havington's": ['HH AE1 V IH0 NG T AH0 N Z'],
  "havins": ['HH AE1 V IH0 N Z'],
  "havis": ['HH AE1 V IH0 S'],
  "havlicek": ['HH AA1 V L IH0 CH EH0 K'],
  "havlik": ['HH AE1 V L IH0 K'],
  "havlin": ['HH AE1 V L IH0 N'],
  "havner": ['HH AE1 V N ER0'],
  "havoc": ['HH AE1 V AH0 K'],
  "havran": ['HH AE1 V R AH0 N'],
  "havranek": ['HH AH0 V R AE1 N EH0 K'],
  "havrilla": ['HH AE2 V R IH1 L AH0'],
  "havron": ['HH AE1 V R AH0 N'],
  "haw": ['HH AO1'],
  "hawaii": ['HH AH0 W AY1 IY2'],
  "hawaii's": ['HH AH0 W AY1 IY2 Z'],
  "hawaiian": ['HH AH0 W AY1 AH0 N'],
  "hawaiians": ['HH AH0 W AY1 AH0 N Z'],
  "hawass": ['HH AE1 W AA0 S'],
  "hawbaker": ['HH AO1 B EY2 K ER0'],
  "hawe": ['HH AO1'],
  "hawes": ['HH AO1 Z'],
  "hawing": ['HH AO1 IH0 NG'],
  "hawk": ['HH AO1 K'],
  "hawkbill": ['HH AO1 K B IH2 L'],
  "hawkbill's": ['HH AO1 K B IH2 L Z'],
  "hawke": ['HH AO1 K'],
  "hawke's": ['HH AO1 K S'],
  "hawked": ['HH AO1 K T'],
  "hawken": ['HH AO1 K AH0 N'],
  "hawker": ['HH AO1 K ER0'],
  "hawkers": ['HH AO1 K ER0 Z'],
  "hawkes": ['HH AO1 K S'],
  "hawkey": ['HH AO1 K IY2'],
  "hawkeye": ['HH AO1 K AY2'],
  "hawkeye's": ['HH AO1 K AY2 Z'],
  "hawkiness": ['HH AO1 K N AH0 S'],
  "hawking": ['HH AO1 K IH0 NG'],
  "hawkins": ['HH AO1 K IH0 N Z'],
  "hawkins'": ['HH AO1 K IH0 N Z'],
  "hawkinson": ['HH AO1 K IH0 N S AH0 N'],
  "hawkish": ['HH AO1 K IH0 SH'],
  "hawks": ['HH AO1 K S'],
  "hawksley": ['HH AO1 K S L IY0'],
  "hawley": ['HH AO1 L IY0'],
  "hawley's": ['HH AO1 L IY0 Z'],
  "hawn": ['HH AO1 N'],
  "haworth": ['HH AE1 W ER0 TH'],
  "haws": ['HH AO1 Z'],
  "hawthorn": ['HH AO1 TH AO2 R N'],
  "hawthorne": ['HH AO1 TH AO0 R N'],
  "hawthorne's": ['HH AO1 TH AO0 R N Z'],
  "hawthorns": ['HH AO1 TH AO2 R N Z'],
  "hawver": ['HH AO1 V ER0'],
  "haxton": ['HH AE1 K S T AH0 N'],
  "hay": ['HH EY1'],
  "hayashi": ['HH AA0 Y AA1 SH IY0'],
  "hayashida": ['HH AA0 Y AA0 SH IY1 D AH0'],
  "haycock": ['HH EY1 K AA2 K'],
  "haycraft": ['HH EY1 K R AE2 F T'],
  "haydel": ['HH EY1 D AH0 L'],
  "hayden": ['HH EY1 D AH0 N'],
  "haydn": ['HH AY1 D AH0 N'],
  "haydn's": ['HH AY1 D AH0 N Z'],
  "haydock": ['HH EY1 D AA2 K'],
  "haydon": ['HH EY1 D AH0 N'],
  "haydu": ['HH EY1 D UW0'],
  "hayduk": ['HH EY1 D AH0 K'],
  "haye": ['HH EY1'],
  "hayek": ['HH EY1 IH0 K'],
  "hayen": ['HH EY1 AH0 N'],
  "hayenga": ['HH EY0 EY1 NG G AH0'],
  "hayer": ['HH EY1 ER0'],
  "hayes": ['HH EY1 Z'],
  "hayfield": ['HH EY1 F IY2 L D'],
  "hayfields": ['HH EY1 F IY2 L D Z'],
  "hayford": ['HH EY1 F ER0 D'],
  "haygood": ['HH EY1 G UH2 D'],
  "hayhurst": ['HH EY1 HH ER0 S T'],
  "haying": ['HH EY1 IH0 NG'],
  "hayles": ['HH EY1 L Z'],
  "hayley": ['HH EY1 L IY0'],
  "hayloft": ['HH EY1 L AO2 F T'],
  "haylofts": ['HH EY1 L AO2 F T S'],
  "haymaker": ['HH EY1 M EY2 K ER0'],
  "haymakers": ['HH EY1 M EY2 K ER0 Z'],
  "hayman": ['HH EY1 M AH0 N'],
  "haymarket": ['HH EY1 M AA2 R K IH0 T'],
  "haymarkets": ['HH EY1 M AA2 R K IH0 T S'],
  "haymes": ['HH EY1 M Z'],
  "haymon": ['HH EY1 M AH0 N'],
  "haymond": ['HH EY1 M AH0 N D'],
  "haymore": ['HH EY1 M AO0 R'],
  "hayn": ['HH EY1 N'],
  "hayne": ['HH EY1 N'],
  "hayner": ['HH EY1 N ER0'],
  "haynes": ['HH EY1 N Z'],
  "haynesworth": ['HH EY1 N Z W ER0 TH'],
  "haynie": ['HH EY1 N IY0'],
  "haynsworth": ['HH EY1 N Z W ER0 TH'],
  "hays": ['HH EY1 Z'],
  "hayse": ['HH EY1 Z'],
  "hayslett": ['HH EY1 S L IH0 T'],
  "hayslip": ['HH EY1 S L IH0 P'],
  "haystack": ['HH EY1 S T AE2 K'],
  "haystacks": ['HH EY1 S T AE2 K S'],
  "hayter": ['HH EY1 T ER0'],
  "hayton": ['HH EY1 T AH0 N'],
  "hayward": ['HH EY1 W ER0 D'],
  "hayward's": ['HH EY1 W ER0 D Z'],
  "haywire": ['HH EY1 W AY2 R'],
  "haywood": ['HH EY1 W UH2 D'],
  "hayworth": ['HH EY1 W ER2 TH'],
  "hazan": ['HH EY1 Z AH0 N'],
  "hazard": ['HH AE1 Z ER0 D'],
  "hazardous": ['HH AE1 Z ER0 D AH0 S'],
  "hazards": ['HH AE1 Z ER0 D Z'],
  "haze": ['HH EY1 Z'],
  "hazel": ['HH EY1 Z AH0 L'],
  "hazelbaker": ['HH EY1 Z AH0 L B EY2 K ER0'],
  "hazelett": ['HH AE1 Z IH0 L EH0 T', 'HH AE1 Z L EH0 T'],
  "hazelip": ['HH AE1 Z IH0 L IH0 P'],
  "hazell": ['HH AE1 Z AH0 L'],
  "hazelnut": ['HH EY1 Z AH0 L N AH2 T'],
  "hazelnuts": ['HH EY1 Z AH0 L N AH2 T S'],
  "hazelrigg": ['HH AH0 Z EH1 L R IH0 G', 'HH EY1 Z EH0 L R IH0 G'],
  "hazeltine": ['HH EY1 Z AH0 L T AY2 N'],
  "hazelton": ['HH EY1 Z AH0 L T AH0 N'],
  "hazelwood": ['HH EY1 Z AH0 L W UH2 D'],
  "hazelwood's": ['HH EY1 Z AH0 L W UH2 D Z'],
  "hazen": ['HH EY1 Z AH0 N'],
  "hazier": ['HH EY1 Z IY0 ER0'],
  "hazing": ['HH EY1 Z IH0 NG'],
  "hazle": ['HH EY1 Z AH0 L'],
  "hazleton": ['HH EY1 Z AH0 L T AH0 N'],
  "hazlett": ['HH AE1 Z L IH0 T'],
  "hazlewood": ['HH EY1 Z AH0 L W UH2 D'],
  "hazy": ['HH EY1 Z IY0'],
  "hazzard": ['HH AE1 Z ER0 D'],
  "hbo": ['EY1 CH B IY0 OW1'],
  "hbox": ['EY1 CH B AO1 K S'],
  "hces": ['EY1 CH S IY1 IY1 EH1 S'],
  "he": ['HH IY1'],
  "he'd": ['HH IY1 D'],
  "he'll": ['HH IY1 L'],
  "he's": ['HH IY1 Z'],
  "heaberlin": ['HH IY1 B ER0 L IH0 N'],
  "heacock": ['HH IY1 K AH0 K'],
  "heacox": ['HH IY1 K AA0 K S'],
  "head": ['HH EH1 D'],
  "head's": ['HH EH1 D Z'],
  "headache": ['HH EH1 D EY2 K'],
  "headaches": ['HH EH1 D EY2 K S'],
  "headband": ['HH EH1 D B AE2 N D'],
  "headbands": ['HH EH1 D B AE2 N D Z'],
  "headboard": ['HH EH1 D B AO2 R D'],
  "headboards": ['HH EH1 D B AO2 R D Z'],
  "headcheese": ['HH EH1 D CH IY2 Z'],
  "headcount": ['HH EH1 D K AW2 N T'],
  "headcounts": ['HH EH1 D K AW2 N T S'],
  "headdress": ['HH EH1 D R EH2 S'],
  "headdresses": ['HH EH1 D D R EH2 S AH0 Z'],
  "headed": ['HH EH1 D AH0 D', 'HH EH1 D IH0 D'],
  "headen": ['HH EH1 D AH0 N'],
  "header": ['HH EH1 D ER0'],
  "headers": ['HH EH1 D ER0 Z'],
  "headfirst": ['HH EH1 D F ER1 S T'],
  "headgear": ['HH EH1 D G IH2 R'],
  "headhunter": ['HH EH1 D HH AH2 N T ER0'],
  "headhunters": ['HH EH1 D HH AH2 N T ER0 Z'],
  "heading": ['HH EH1 D IH0 NG'],
  "headings": ['HH EH1 D IH0 NG Z'],
  "headington": ['HH EH1 D IH0 NG T AH0 N'],
  "headlee": ['HH EH1 D L IY2'],
  "headless": ['HH EH1 D L AH0 S'],
  "headley": ['HH EH1 D L IY0'],
  "headlight": ['HH EH1 D L AY2 T'],
  "headlights": ['HH EH1 D L AY2 T S'],
  "headline": ['HH EH1 D L AY2 N'],
  "headlined": ['HH EH1 D L AY2 N D'],
  "headliner": ['HH EH1 D L AY2 N ER0'],
  "headliners": ['HH EH1 D L AY2 N ER0 Z'],
  "headlines": ['HH EH1 D L AY2 N Z'],
  "headlining": ['HH EH1 D L AY2 N IH0 NG'],
  "headlong": ['HH EH1 D L AO2 NG'],
  "headlund": ['HH EH1 D L AH0 N D'],
  "headman": ['HH EH1 D M AH0 N'],
  "headmaster": ['HH EH1 D M AE1 S T ER0'],
  "headmasters": ['HH EH1 D M AE1 S T ER0 Z'],
  "headmistress": ['HH EH1 D M IH2 S T R AH0 S'],
  "headmistresses": ['HH EH1 D M IH2 S T R AH0 S AH0 Z'],
  "headphone": ['HH EH1 D F OW2 N'],
  "headphones": ['HH EH1 D F OW2 N Z'],
  "headquarter": ['HH EH1 D K W AO2 R T ER0', 'HH EH1 D K AO2 R T ER0'],
  "headquartered": ['HH EH1 D K AO2 R T ER0 D', 'HH EH1 D K W AO2 R T ER0 D'],
  "headquarters": ['HH EH1 D K W AO2 R T ER0 Z', 'HH EH1 D K AO2 R T ER0 Z'],
  "headrest": ['HH EH1 D R EH2 S T'],
  "headrests": ['HH EH1 D R EH2 S T S'],
  "headrick": ['HH EH1 D R IH0 K'],
  "headroom": ['HH EH1 D R UW2 M'],
  "heads": ['HH EH1 D Z'],
  "heads-up": ['HH EH1 D Z AH2 P'],
  "headset": ['HH EH1 D S EH2 T'],
  "headsets": ['HH EH1 D S EH2 T S'],
  "headship": ['HH EH1 D SH IH2 P'],
  "headshot": ['HH EH1 D SH AA2 T'],
  "headsman": ['HH EH1 D Z M AH0 N'],
  "headstart": ['HH EH1 D S T AA2 R T'],
  "headstarts": ['HH EH1 D S T AA2 R T S'],
  "headstone": ['HH EH1 D S T OW2 N'],
  "headstones": ['HH EH1 D S T OW2 N Z'],
  "headstrong": ['HH EH1 D S T R AO2 NG'],
  "headwater": ['HH EH1 D W AO2 T ER0'],
  "headwaters": ['HH EH1 D W AO2 T ER0 Z'],
  "headway": ['HH EH1 D W EY2'],
  "headwind": ['HH EH1 D W IH2 N D'],
  "headwinds": ['HH EH1 D W IH2 N D Z'],
  "heady": ['HH EH1 D IY0'],
  "heafner": ['HH IY1 F N ER0'],
  "heagle": ['HH IY1 G AH0 L'],
  "heagney": ['HH IY1 G N IY0'],
  "heagy": ['HH IY1 JH IY0'],
  "heal": ['HH IY1 L'],
  "heald": ['HH IY1 L D'],
  "healed": ['HH IY1 L D'],
  "healer": ['HH IY1 L ER0'],
  "healers": ['HH IY1 L ER0 Z'],
  "healey": ['HH IY1 L IY0'],
  "healing": ['HH IY1 L IH0 NG'],
  "heals": ['HH IY1 L Z'],
  "health": ['HH EH1 L TH'],
  "health's": ['HH EH1 L TH S'],
  "healthamerica": ['HH IY2 L TH AH0 M EH1 R IH0 K AH0'],
  "healthcare": ['HH EH1 L TH K EH2 R'],
  "healthcare's": ['HH EH1 L TH K EH2 R Z'],
  "healthcorp": ['HH EH1 L TH K AO2 R P', 'HH EH1 L TH K AO2 R'],
  "healthdyne": ['HH EH1 L TH D AY2 N'],
  "healthful": ['HH EH1 L TH F AH0 L'],
  "healthier": ['HH EH1 L TH IY0 ER0'],
  "healthiest": ['HH EH1 L TH IY0 IH0 S T'],
  "healthiness": ['HH EH1 L TH IY0 N AH0 S'],
  "healthsource": ['HH EH1 L TH S AO2 R S'],
  "healthsouth": ['HH EH1 L TH S AW2 TH'],
  "healthtrust": ['HH EH1 L TH T R AH2 S T'],
  "healthtrust's": ['HH EH1 L TH T R AH2 S T S'],
  "healthvest": ['HH EH1 L TH V EH2 S T'],
  "healthweek": ['HH EH1 L TH W IY2 K'],
  "healthwise": ['HH EH1 L TH W AY2 Z'],
  "healthwork": ['HH EH1 L TH W ER0 K'],
  "healthworks": ['HH EH1 L TH W ER0 K S'],
  "healthy": ['HH EH1 L TH IY0'],
  "healy": ['HH IY1 L IY0'],
  "healy's": ['HH IY1 L IY0 Z'],
  "heaney": ['HH IY1 N IY0'],
  "heap": ['HH IY1 P'],
  "heape": ['HH IY1 P'],
  "heaped": ['HH IY1 P T'],
  "heaphy": ['HH IY1 F IY0'],
  "heaping": ['HH IY1 P IH0 NG'],
  "heaps": ['HH IY1 P S'],
  "hear": ['HH IY1 R'],
  "heard": ['HH ER1 D'],
  "hearer": ['HH IY1 R ER0'],
  "hearers": ['HH IY1 R ER0 Z'],
  "hearin": ['HH IH1 R IH0 N'],
  "hearing": ['HH IY1 R IH0 NG'],
  "hearing's": ['HH IY1 R IH0 NG Z'],
  "hearings": ['HH IY1 R IH0 NG Z'],
  "hearkens": ['HH AA1 R K AH0 N Z'],
  "hearl": ['HH ER1 L'],
  "hearn": ['HH ER1 N'],
  "hearne": ['HH ER1 N'],
  "hearns": ['HH ER1 N Z'],
  "hearon": ['HH IH1 R AH0 N'],
  "hearron": ['HH AO1 R AH0 N'],
  "hears": ['HH IY1 R Z'],
  "hearsay": ['HH IY1 R S EY2'],
  "hearse": ['HH ER1 S'],
  "hearst": ['HH ER1 S T'],
  "hearst's": ['HH ER1 S T S'],
  "heart": ['HH AA1 R T'],
  "heart's": ['HH AA1 R T S'],
  "heartache": ['HH AA1 R T EY2 K'],
  "heartbeat": ['HH AA1 R T B IY2 T'],
  "heartbeats": ['HH AA1 R T B IY2 T S'],
  "heartbreak": ['HH AA1 R T B R EY2 K'],
  "heartbreaking": ['HH AA1 R T B R EY2 K IH0 NG'],
  "heartbreaks": ['HH AA1 R T B R EY2 K S'],
  "heartbroken": ['HH AA1 R T B R OW2 K AH0 N'],
  "heartburn": ['HH AA1 R T B ER2 N'],
  "hearted": ['HH AA1 R T AH0 D', 'HH AA1 R T IH0 D'],
  "heartedly": ['HH AA1 R T IH0 D L IY0'],
  "hearten": ['HH AA1 R T AH0 N'],
  "heartened": ['HH AA1 R T AH0 N D'],
  "heartening": ['HH AA1 R T AH0 N IH0 NG', 'HH AA1 R T N IH0 NG'],
  "heartfelt": ['HH AA1 R T F EH2 L T'],
  "hearth": ['HH AA1 R TH'],
  "hearths": ['HH AA1 R TH S'],
  "heartier": ['HH AA1 R T IY0 ER0'],
  "heartiest": ['HH AA1 R T IY0 AH0 S T'],
  "heartily": ['HH AA1 R T AH0 L IY0'],
  "heartland": ['HH AA1 R T L AE2 N D'],
  "heartless": ['HH AA1 R T L AH0 S'],
  "hearts": ['HH AA1 R T S'],
  "heartscan": ['HH AA1 R T S K AE2 N'],
  "heartthrob": ['HH AA1 R T TH R AA2 B'],
  "heartthrobs": ['HH AA1 R T TH R AA2 B Z'],
  "heartwarming": ['HH AA1 R T W AO2 R M IH0 NG'],
  "heartwise": ['HH AA1 R T W AY2 Z'],
  "heartwood": ['HH AA1 R T W UH2 D'],
  "hearty": ['HH AA1 R T IY0'],
  "heasley": ['HH IY1 Z L IY0'],
  "heaslip": ['HH IY1 S L IH0 P'],
  "heaston": ['HH IY1 S T AH0 N'],
  "heat": ['HH IY1 T'],
  "heat's": ['HH IY1 T S'],
  "heated": ['HH IY1 T AH0 D', 'HH IY1 T IH0 D'],
  "heatedly": ['HH IY1 T IH0 D L IY0'],
  "heater": ['HH IY1 T ER0'],
  "heaters": ['HH IY1 T ER0 Z'],
  "heath": ['HH IY1 TH'],
  "heath's": ['HH IY1 TH S'],
  "heathcliff": ['HH EH1 TH K L IH0 F'],
  "heathcock": ['HH EH1 TH K AH0 K'],
  "heathcote": ['HH EH1 TH K AH0 T'],
  "heathen": ['HH IY1 DH AH0 N'],
  "heather": ['HH EH1 DH ER0'],
  "heather's": ['HH EH1 DH ER0 Z'],
  "heatherington": ['HH EH1 DH ER0 IH0 NG T AH0 N'],
  "heatherly": ['HH EH1 DH ER0 L IY0'],
  "heathers": ['HH EH1 DH ER0 Z'],
  "heathman": ['HH IY1 TH M AH0 N'],
  "heathrow": ['HH IY1 TH R OW0'],
  "heathrow's": ['HH IY1 TH R OW0 Z'],
  "heathwood": ['HH IY1 TH W UH2 D'],
  "heating": ['HH IY1 T IH0 NG'],
  "heatley": ['HH IY1 T L IY0'],
  "heaton": ['HH IY1 T AH0 N'],
  "heats": ['HH IY1 T S'],
  "heatwole": ['HH IY1 T W OW2 L'],
  "heave": ['HH IY1 V'],
  "heaved": ['HH IY1 V D'],
  "heaven": ['HH EH1 V AH0 N'],
  "heaven's": ['HH EH1 V AH0 N Z'],
  "heavener": ['HH EH1 V AH0 N ER0'],
  "heavenly": ['HH EH1 V AH0 N L IY0'],
  "heavens": ['HH EH1 V AH0 N Z'],
  "heaves": ['HH IY1 V Z'],
  "heavey": ['HH IY1 V IY0'],
  "heavier": ['HH EH1 V IY0 ER0'],
  "heavies": ['HH EH1 V IY0 Z'],
  "heaviest": ['HH EH1 V IY0 AH0 S T'],
  "heavily": ['HH EH1 V AH0 L IY0'],
  "heavin": ['HH EH1 V IH0 N'],
  "heaving": ['HH IY1 V IH0 NG'],
  "heavner": ['HH IY1 V N ER0'],
  "heavrin": ['HH IY1 V R IH0 N'],
  "heavy": ['HH EH1 V IY0'],
  "heavyhanded": ['HH EH1 V IY0 HH AE2 N D IH0 D'],
  "heavyset": ['HH EH1 V IY0 S EH2 T'],
  "heavyweight": ['HH EH1 V IY0 W EY2 T'],
  "heavyweights": ['HH EH1 V IY0 W EY2 T S'],
  "hebard": ['HH EH1 B ER0 D'],
  "hebb": ['HH EH1 B'],
  "hebda": ['HH EH1 B D AH0'],
  "hebding": ['HH EH1 B D IH0 NG'],
  "hebe": ['HH IY1 B'],
  "hebei": ['HH IY1 B IY0'],
  "hebel": ['HH EH1 B AH0 L'],
  "hebeler": ['HH EH1 B AH0 L ER0'],
  "hebenstreit": ['HH EH1 B IH0 N S T R AY0 T'],
  "heber": ['HH IY1 B ER0'],
  "heberer": ['HH EH1 B ER0 ER0'],
  "heberle": ['HH EH1 B ER0 AH0 L'],
  "heberlein": ['HH EH1 B ER0 L AY2 N'],
  "heberling": ['HH EH1 B ER0 L IH0 NG'],
  "hebert": ['HH EH1 B ER0 T'],
  "hebner": ['HH EH1 B N ER0'],
  "hebrew": ['HH IY1 B R UW0'],
  "hebrews": ['HH IY1 B R UW0 Z'],
  "hebrides": ['HH EH1 B R IH0 D IY0 Z'],
  "hebron": ['HH EH1 B R AH0 N', 'HH EH1 B R AO2 N'],
  "hecco": ['HH EH1 K OW0'],
  "hechinger": ['HH EH1 K IH0 N JH ER0'],
  "hechler": ['HH EH1 K L ER0'],
  "hecht": ['HH EH1 K T'],
  "hecht's": ['HH EH1 K T S'],
  "hechtman": ['HH EH1 K T M AH0 N'],
  "heck": ['HH EH1 K'],
  "heck's": ['HH EH1 K S'],
  "heckaman": ['HH EH1 K AH0 M AH0 N'],
  "heckard": ['HH EH1 K ER0 D'],
  "heckart": ['HH EH1 K ER0 T'],
  "heckathorn": ['HH EH1 K AH0 TH ER0 N'],
  "heckbert": ['HH EH1 K B ER0 T'],
  "heckel": ['HH EH1 K AH0 L'],
  "heckendorn": ['HH EH1 K EH0 N D AO0 R N'],
  "hecker": ['HH EH1 K ER0'],
  "heckert": ['HH EH1 K ER0 T'],
  "heckle": ['HH EH1 K AH0 L'],
  "heckled": ['HH EH1 K AH0 L D'],
  "heckler": ['HH EH1 K L ER0'],
  "hecklers": ['HH EH1 K L ER0 Z'],
  "heckling": ['HH EH1 K L IH0 NG'],
  "heckman": ['HH EH1 K M AH0 N'],
  "heckmann": ['HH EH1 K M AH0 N'],
  "heckuva": ['HH EH0 K Y UW1 V AH0', 'HH EH1 K AH0 V AH0'],
  "hecla": ['HH EH1 K L AH0'],
  "hecla's": ['HH EH1 K L AH0 Z'],
  "hecox": ['HH EH1 K AA0 K S'],
  "hectare": ['HH EH1 K T AA2 R'],
  "hectares": ['HH EH1 K T AA2 R Z'],
  "hectic": ['HH EH1 K T IH0 K'],
  "hectograph": ['HH EH1 K T AH0 G R AE2 F'],
  "hector": ['HH EH1 K T ER0'],
  "hector's": ['HH EH1 K T ER0 Z'],
  "hectoring": ['HH EH1 K T ER0 IH0 NG'],
  "hecuba": ['HH EH1 K Y AH0 B AH0', 'HH EH1 K Y UW0 B AH0'],
  "heda": ['HH EY1 D AH0'],
  "hedberg": ['HH EH1 D B ER0 G'],
  "hedda": ['HH EH1 D AH0'],
  "hedden": ['HH EH1 D AH0 N'],
  "hedding": ['HH EH1 D IH0 NG'],
  "heddy": ['HH EH1 D IY0'],
  "hedeen": ['HH EH1 D IY0 N'],
  "hedge": ['HH EH1 JH'],
  "hedgecock": ['HH EH1 JH K AA2 K'],
  "hedged": ['HH EH1 JH D'],
  "hedgehog": ['HH EH1 JH HH AA2 G'],
  "hedgehogs": ['HH EH1 JH HH AA2 G Z'],
  "hedgepath": ['HH EH1 JH P AE2 TH'],
  "hedgepeth": ['HH EH1 JH IH0 P EH0 TH'],
  "hedger": ['HH EH1 JH ER0'],
  "hedgers": ['HH EH1 JH ER0 Z'],
  "hedges": ['HH EH1 JH IH0 Z'],
  "hedging": ['HH EH1 JH IH0 NG'],
  "hedglin": ['HH EH1 JH L IH0 N'],
  "hedgpeth": ['HH EH1 JH P IH0 TH'],
  "hedi": ['HH EH1 D IY0'],
  "hediger": ['HH EH1 D IH0 G ER0'],
  "hedin": ['HH EH1 D IH0 N'],
  "hedinger": ['HH EH1 D IH0 N G ER0', 'HH EH1 D IH0 N JH ER0'],
  "hedley": ['HH EH1 D L IY0'],
  "hedlund": ['HH EH1 D L AH0 N D'],
  "hedman": ['HH EH1 D M AH0 N'],
  "hedonic": ['HH AH0 D AA1 N IH0 K'],
  "hedonism": ['HH IY1 D AH0 N IH2 Z AH0 M'],
  "hedonistic": ['HH IY2 D AH0 N IH1 S T IH0 K'],
  "hedquist": ['HH EH1 D K W IH2 S T'],
  "hedrich": ['HH EH1 D R IH0 K'],
  "hedrick": ['HH EH1 D R IH0 K'],
  "hedstrom": ['HH EH1 D S T R AH0 M'],
  "hedtke": ['HH EH1 D K IY0'],
  "hedwig": ['HH EH1 D W IH0 G'],
  "hedwiga": ['HH EH1 D W IH0 G AH0'],
  "hedy": ['HH IY1 D IY0'],
  "hee": ['HH IY1'],
  "heeb": ['HH IY1 B'],
  "heebner": ['HH IY1 B N ER0'],
  "heed": ['HH IY1 D'],
  "heeded": ['HH IY1 D AH0 D', 'HH IY1 D IH0 D'],
  "heeding": ['HH IY1 D IH0 NG'],
  "heeds": ['HH IY1 D Z'],
  "heefner": ['HH IY1 F N ER0'],
  "heeg": ['HH IY1 G'],
  "heehaw": ['HH IY1 HH AW2'],
  "heeke": ['HH IY1 K'],
  "heekin": ['HH IY1 K IH0 N'],
  "heel": ['HH IY1 L'],
  "heelan": ['HH IY1 L AH0 N'],
  "heeled": ['HH IY1 L D'],
  "heeling": ['HH IY1 L IH0 NG'],
  "heels": ['HH IY1 L Z'],
  "heemstra": ['HH IY1 M S T R AH0'],
  "heenan": ['HH IY1 N AH0 N'],
  "heeney": ['HH IY1 N IY0'],
  "heer": ['HH IY1 ER0'],
  "heeren": ['HH IH1 R AH0 N'],
  "heermann": ['HH IH1 R M AH0 N'],
  "hees": ['HH IY1 Z'],
  "heesch": ['HH IY1 SH'],
  "heese": ['HH IY1 Z'],
  "heeter": ['HH IY1 T ER0'],
  "hefei": ['HH AH0 F EY1'],
  "heffel": ['HH EH1 F AH0 L'],
  "heffelfinger": ['HH EH1 F IH0 L F IH0 NG ER0'],
  "hefferan": ['HH EH1 F ER0 AH0 N'],
  "hefferman": ['HH EH1 F ER0 M AH0 N'],
  "heffern": ['HH EH1 F ER0 N'],
  "heffernan": ['HH EH1 F ER0 N AH0 N'],
  "hefferon": ['HH EH1 F ER0 AH0 N'],
  "heffington": ['HH EH1 F IH0 NG T AH0 N'],
  "heffler": ['HH EH1 F L ER0'],
  "heffley": ['HH EH1 F L IY0'],
  "heffner": ['HH EH1 F N ER0'],
  "heffron": ['HH EH1 F R AH0 N'],
  "hefler": ['HH EH1 F L ER0'],
  "hefley": ['HH EH1 F L IY0'],
  "heflin": ['HH EH1 F L IH0 N'],
  "hefner": ['HH EH1 F N ER0'],
  "hefner's": ['HH EH1 F N ER0 Z'],
  "heft": ['HH EH1 F T'],
  "hefter": ['HH EH1 F T ER0'],
  "hefti": ['HH EH1 F T IY0'],
  "heftier": ['HH EH1 F T IY0 ER0'],
  "heftiest": ['HH EH1 F T IY0 AH0 S T'],
  "hefty": ['HH EH1 F T IY0'],
  "hegadorn": ['HH EH1 G AH0 D AO2 R N'],
  "hegarty": ['HH EH1 G AA0 R T IY0'],
  "hege": ['HH IY1 JH'],
  "hegedus": ['HH EH1 G IH0 D IH0 S'],
  "hegel": ['HH EH1 G AH0 L'],
  "hegelian": ['HH IY0 JH IY1 L IY0 AH0 N'],
  "hegeman": ['HH IY1 G M AH0 N'],
  "hegemonic": ['HH EH2 G AH0 M AA1 N IH0 K'],
  "hegemony": ['HH IY0 JH EH1 M AH0 N IY0'],
  "hegenna": ['HH EH0 G EH1 N AH0'],
  "heger": ['HH IY1 G ER0'],
  "hegg": ['HH EH1 G'],
  "hegge": ['HH EH1 G'],
  "heggen": ['HH EH1 G AH0 N'],
  "heggie": ['HH EH1 G IY0'],
  "hegland": ['HH EH1 G L AH0 N D'],
  "hegler": ['HH EH1 G L ER0'],
  "heglund": ['HH EH1 G L AH0 N D'],
  "hegna": ['HH EH1 G N AH0'],
  "hegner": ['HH EH1 G N ER0'],
  "hegstrom": ['HH EH1 G S T R AH0 M'],
  "hegwood": ['HH EH1 G W UH2 D'],
  "hegyi": ['HH EY1 G Y IY0'],
  "heh": ['HH EH1'],
  "hehir": ['HH EH1 HH IH0 R'],
  "hehl": ['HH EH1 L'],
  "hehman": ['HH EH1 M AH0 N'],
  "hehmeyer": ['HH EH1 M AY2 R'],
  "hehn": ['HH EH1 N'],
  "hehr": ['HH EH1 R'],
  "heibel": ['HH AY1 B AH0 L'],
  "heiberg": ['HH AY1 B ER0 G'],
  "heiberger": ['HH AY1 B ER0 G ER0'],
  "heichel": ['HH AY1 K AH0 L'],
  "heichelbech": ['HH AY1 K IH0 L B IH0 K'],
  "heick": ['HH AY1 K'],
  "heico": ['HH AY1 K OW0'],
  "heico's": ['HH AY1 K OW0 Z'],
  "heid": ['HH AY1 D'],
  "heidbreder": ['HH AY1 D B R IH0 D ER0'],
  "heidbrink": ['HH AY1 D B R IH0 NG K'],
  "heide": ['HH AY1 D'],
  "heidecker": ['HH AY1 D IH0 K ER0'],
  "heidegger": ['HH AY1 D IH0 G ER0'],
  "heidel": ['HH AY1 D AH0 L'],
  "heidelberg": ['HH AY1 D AH0 L B ER0 G'],
  "heidelberg's": ['HH AY1 D AH0 L B ER0 G Z'],
  "heidelberger": ['HH AY1 D AH0 L B ER0 G ER0'],
  "heideman": ['HH AY1 D M AH0 N'],
  "heidemann": ['HH AY1 D M AH0 N'],
  "heiden": ['HH AY1 D AH0 N'],
  "heidenreich": ['HH AY1 D IH0 N R AY0 K'],
  "heider": ['HH AY1 D ER0'],
  "heidi": ['HH AY1 D IY0'],
  "heidi's": ['HH AY1 D IY0 S'],
  "heidinger": ['HH AY1 D IH0 NG ER0'],
  "heidiwear": ['HH AY1 D IY0 W EH0 R'],
  "heidler": ['HH AY1 D AH0 L ER0', 'HH AY1 D L ER0'],
  "heidorn": ['HH AY1 D ER0 N'],
  "heidrich": ['HH AY1 D R IH0 K'],
  "heidrick": ['HH AY1 D R IH0 K'],
  "heidstra": ['HH AY1 D S T R AH0'],
  "heidt": ['HH AY1 D T'],
  "heier": ['HH AY1 ER0'],
  "heifer": ['HH AY1 F ER0', 'HH EH1 F ER0'],
  "heifers": ['HH EH1 F ER0 Z', 'HH AY1 F ER0 Z'],
  "heifetz": ['HH AY1 F IH0 T S'],
  "heifner": ['HH IY1 F N ER0'],
  "heiges": ['HH AY1 JH IH0 Z'],
  "height": ['HH AY1 T'],
  "heighten": ['HH AY1 T AH0 N'],
  "heightened": ['HH AY1 T AH0 N D'],
  "heightening": ['HH AY1 T AH0 N IH0 NG', 'HH AY1 T N IH0 NG'],
  "heightens": ['HH AY1 T AH0 N Z'],
  "heighth": ['HH AY1 TH'],
  "heights": ['HH AY1 T S'],
  "heigl": ['HH AY1 G AH0 L'],
  "heiken": ['HH AY1 K AH0 N'],
  "heikes": ['HH AY1 K AH0 Z'],
  "heikkila": ['HH AY1 K IH0 L AH0'],
  "heikkinen": ['HH AY1 K IH0 N AH0 N'],
  "heiko": ['HH AY1 K OW0', 'HH EY1 K OW0'],
  "heil": ['HH AY1 L'],
  "heiland": ['HH AY1 L AH0 N D'],
  "heilbrun": ['HH AY1 L B R AH0 N'],
  "heileman": ['HH AY1 L M AH0 N'],
  "heileman's": ['HH AY1 L M AH0 N Z'],
  "heiler": ['HH AY1 L ER0'],
  "heilig": ['HH AY1 L IH0 G'],
  "heilman": ['HH AY1 L M AH0 N'],
  "heilmann": ['HH AY1 L M AH0 N'],
  "heim": ['HH AY1 M'],
  "heiman": ['HH AY1 M AH0 N'],
  "heimann": ['HH AY1 M AH0 N'],
  "heimbach": ['HH AY1 M B AA2 K'],
  "heimberger": ['HH AY1 M B ER0 G ER0'],
  "heimbigner": ['HH AY1 M B AY0 G N ER0'],
  "heimbuch": ['HH AY1 M B AH0 K'],
  "heimburger": ['HH AY1 M B ER0 G ER0'],
  "heimer": ['HH AY1 M ER0'],
  "heimerl": ['HH AY1 M ER0 L'],
  "heimlich": ['HH AY1 M L IH0 K'],
  "heims": ['HH AY1 M Z'],
  "heimsoth": ['HH AY1 M S AH0 TH'],
  "hein": ['HH AY1 N'],
  "heinbach": ['HH AY1 N B AA2 K'],
  "heinbaugh": ['HH AY1 N B AW0'],
  "heinburger": ['HH AY1 N B ER0 G ER0'],
  "heindel": ['HH AY1 N D AH0 L'],
  "heindl": ['HH AY1 N D AH0 L'],
  "heine": ['HH AY1 N'],
  "heinecke": ['HH AY1 N IH0 K'],
  "heineken": ['HH AY1 N AH0 K AH0 N'],
  "heineman": ['HH AY1 N M AH0 N'],
  "heinemann": ['HH AY1 N M AH0 N'],
  "heinen": ['HH AY1 N AH0 N'],
  "heiner": ['HH AY1 N ER0'],
  "heines": ['HH AY1 N Z'],
  "heiney": ['HH AY1 N IY0'],
  "heini": ['HH AY1 N IY0'],
  "heinicke": ['HH AY1 N IH0 K'],
  "heinig": ['HH AY1 N IH0 G'],
  "heininger": ['HH AY1 N IH0 NG ER0'],
  "heinisch": ['HH AY1 N IH0 SH'],
  "heinke": ['HH AY1 NG K'],
  "heinkel": ['HH AY1 NG K AH0 L'],
  "heinl": ['HH AY1 N AH0 L'],
  "heinle": ['HH AY1 N AH0 L'],
  "heinlein": ['HH AY1 N L AY2 N'],
  "heinlen": ['HH AY1 N AH0 L AH0 N'],
  "heinly": ['HH AY1 N L IY0'],
  "heino": ['HH AY1 N OW0'],
  "heinold": ['HH AY1 N OW0 L D'],
  "heinonen": ['HH AY1 N AH0 N AH0 N'],
  "heinous": ['HH EY1 N AH0 S'],
  "heinrich": ['HH AY1 N R IH0 K'],
  "heinrichs": ['HH AY1 N R IH0 K S'],
  "heins": ['HH AY1 N Z'],
  "heinsohn": ['HH AY1 N S AH0 N'],
  "heintz": ['HH AY1 N T S'],
  "heintze": ['HH AY1 N T S'],
  "heintzelman": ['HH AY1 N T Z AH0 L M AH0 N'],
  "heintzman": ['HH AY1 N T S M AH0 N'],
  "heiny": ['HH AY1 N IY0'],
  "heinz": ['HH AY1 N Z'],
  "heinz's": ['HH AY1 N Z IH0 Z'],
  "heinze": ['HH AY1 N Z'],
  "heinzel": ['HH AY1 N Z AH0 L'],
  "heinzelman": ['HH AY1 N Z AH0 L M AH0 N'],
  "heinzen": ['HH AY1 N Z AH0 N'],
  "heinzman": ['HH AY1 N Z M AH0 N'],
  "heinzmann": ['HH AY1 N Z M AH0 N'],
  "heiple": ['HH AY1 P AH0 L'],
  "heir": ['EH1 R'],
  "heiress": ['EH1 R AH0 S'],
  "heiresses": ['EH1 R AH0 S IH0 Z'],
  "heirloom": ['EH1 R L UW2 M'],
  "heirlooms": ['EH1 R L UW2 M Z'],
  "heironimus": ['EH1 R AA0 N IH0 M UW0 S', 'HH AY0 R AA1 N IH0 M AH0 S'],
  "heirs": ['EH1 R Z'],
  "heisbourg": ['HH AY1 S B AO2 R G'],
  "heise": ['HH AY1 S'],
  "heisel": ['HH AY1 S AH0 L'],
  "heiser": ['HH AY1 S ER0'],
  "heiserman": ['HH AY1 S ER0 M AH0 N'],
  "heisey": ['HH AY1 S IY0'],
  "heishman": ['HH IY1 IH0 SH M AH0 N'],
  "heisinger": ['HH AY1 S IH0 N JH ER0'],
  "heiskell": ['HH AY1 S K AH0 L'],
  "heisler": ['HH AY1 S AH0 L ER0', 'HH AY1 S L ER0'],
  "heisman": ['HH AY1 S M AH0 N'],
  "heisman's": ['HH AY1 S M AH0 N Z'],
  "heisner": ['HH AY1 S N ER0'],
  "heiss": ['HH AY1 S'],
  "heist": ['HH AY1 S T'],
  "heistand": ['HH AY1 S T AH0 N D'],
  "heister": ['HH AY1 S T ER0'],
  "heit": ['HH AY1 T'],
  "heitkamp": ['HH AY1 T K AE2 M P'],
  "heitman": ['HH AY1 T M AH0 N'],
  "heitmann": ['HH AY1 T M AH0 N'],
  "heitmeyer": ['HH AY1 T M AY0 ER0'],
  "heitner": ['HH AY1 T N ER0'],
  "heitz": ['HH AY1 T S'],
  "heitzenrater": ['HH AY1 T Z IH0 N R EY0 T ER0'],
  "heitzman": ['HH AY1 T S M AH0 N'],
  "heiwa": ['HH AY1 W AH0'],
  "heizer": ['HH AY1 Z ER0'],
  "hejl": ['HH EH1 JH L'],
  "hejna": ['HH EH1 JH N AH0'],
  "hekker": ['HH EH1 K ER0'],
  "hekmatyar": ['HH EH2 K M AH0 T Y AA1 R'],
  "hekmatyar's": ['HH EH2 K M AH0 T Y AA1 R Z'],
  "helaba": ['HH EH0 L AA1 B AH0'],
  "helander": ['HH EH1 L AH0 N D ER0'],
  "helane": ['HH AH0 L EY1 N'],
  "helber": ['HH EH1 L B ER0'],
  "helberg": ['HH EH1 L B ER0 G'],
  "helbert": ['HH EH1 L B ER0 T'],
  "helbig": ['HH EH1 L B IH0 G'],
  "helbing": ['HH EH1 L B IH0 NG'],
  "helbling": ['HH EH1 L B AH0 L IH0 NG', 'HH EH1 L B L IH0 NG'],
  "held": ['HH EH1 L D'],
  "heldenbrand": ['HH EH1 L D IH0 N B R AH0 N D', 'HH EH1 L D IH0 N B R AE0 N D'],
  "heldentenor": ['HH EH1 L D EH0 N T EH2 N ER0'],
  "helder": ['HH EH1 L D ER0'],
  "helderman": ['HH EH1 L D ER0 M AH0 N'],
  "heldman": ['HH EH1 L D M AH0 N'],
  "heldor": ['HH EH1 L D ER0'],
  "heldreth": ['HH EH1 L D R IH0 TH'],
  "heldring": ['HH EH1 L D R IH0 NG'],
  "helds": ['HH EH1 L D Z'],
  "heldt": ['HH EH1 L T'],
  "helen": ['HH EH1 L AH0 N'],
  "helen's": ['HH EH1 L IH0 N Z'],
  "helena": ['HH EH1 L AH0 N AH0'],
  "helena's": ['HH EH1 L IH0 N AH0 Z'],
  "helene": ['HH AH0 L IY1 N'],
  "helens": ['HH EH1 L AH0 N Z'],
  "helf": ['HH EH1 L F'],
  "helfand": ['HH EH1 L F AH0 N D'],
  "helfer": ['HH EH1 L F ER0'],
  "helfgott": ['HH EH1 L F G AA2 T'],
  "helfman": ['HH EH1 L F M AH0 N'],
  "helfrich": ['HH EH1 L F R IH0 K'],
  "helga": ['HH EH1 L G AH0'],
  "helgerson": ['HH EH1 L G ER0 S AH0 N'],
  "helgesen": ['HH EH1 L G IY0 Z AH0 N'],
  "helgeson": ['HH EH1 L G IH0 S AH0 N'],
  "helget": ['HH EH1 L G IH0 T'],
  "helgren": ['HH EH1 L G R EH0 N'],
  "helical": ['HH EH1 L IH0 K AH0 L'],
  "helice": ['HH EH1 L IH0 S'],
  "helicon": ['HH EH1 L IH0 K AA2 N'],
  "helicons": ['HH EH1 L IH0 K AA2 N Z'],
  "helicopter": ['HH EH1 L IH0 K AA2 P T ER0'],
  "helicopter's": ['HH EH1 L AH0 K AA2 P T ER0 Z'],
  "helicopters": ['HH EH1 L IH0 K AA2 P T ER0 Z'],
  "helie": ['HH EH1 L IY0'],
  "helin": ['HH EH1 L IH0 N'],
  "heling": ['HH IY1 L IH0 NG'],
  "helinski": ['HH IH0 L IH1 N S K IY0'],
  "helionetic": ['HH IY2 L IY0 OW0 N EH1 T IH0 K'],
  "helionetics": ['HH IY2 L IY0 OW0 N EH1 T IH0 K S'],
  "heliopolis": ['HH IY2 L IY0 AA1 P AH0 L AH0 S'],
  "helios": ['HH IY1 L IY0 AA2 S'],
  "heliotrope": ['HH IY1 L IY0 AH0 T R OW2 P'],
  "helium": ['HH IY1 L IY0 AH0 M'],
  "helix": ['HH IY1 L IH0 K S'],
  "helke": ['HH EH1 L K'],
  "hell": ['HH EH1 L'],
  "hell's": ['HH EH1 L Z'],
  "hellacious": ['HH EH2 L EY1 SH AH0 S'],
  "hellaciously": ['HH EH2 L EY1 SH AH0 S L IY0'],
  "hellaciousness": ['HH EH2 L EY1 SH AH0 S N AH0 S'],
  "hellams": ['HH EH1 L AH0 M Z'],
  "helland": ['HH EH1 L AH0 N D'],
  "hellard": ['HH EH1 L ER0 D'],
  "hellberg": ['HH EH1 L B ER0 G'],
  "hellbusch": ['HH EH1 L B AH0 SH', 'HH EH1 L B UH0 SH'],
  "helle": ['HH EH1 L'],
  "hellen": ['HH EH1 L AH0 N'],
  "hellenbrand": ['HH EH1 L AH0 N B R AE2 N D'],
  "hellenic": ['HH AH0 L EH1 N IH0 K'],
  "hellenism": ['HH EH1 L AH0 N IH2 Z AH0 M'],
  "hellenistic": ['HH EH2 L AH0 N IH1 S T IH0 K'],
  "hellenize": ['HH EH1 L AH0 N AY2 Z'],
  "hellenized": ['HH EH1 L AH0 N AY2 Z D'],
  "hellenizes": ['HH EH1 L AH0 N AY2 Z IH0 Z'],
  "hellenizing": ['HH EH1 L AH0 N AY2 Z IH0 NG'],
  "heller": ['HH EH1 L ER0'],
  "heller's": ['HH EH1 L ER0 Z'],
  "hellerman": ['HH EH1 L ER0 M AH0 N'],
  "hellfire": ['HH EH1 L F AY2 R'],
  "hellickson": ['HH EH1 L IH0 K S AH0 N'],
  "hellier": ['HH EH1 L Y ER0'],
  "helling": ['HH EH1 L IH0 NG'],
  "hellinger": ['HH EH1 L IH0 NG ER0'],
  "hellish": ['HH EH1 L IH0 SH'],
  "hellman": ['HH EH1 L M AH0 N'],
  "hellmann": ['HH EH1 L M AH0 N'],
  "hellmer": ['HH EH1 L M ER0'],
  "hellmuth": ['HH EH1 L M UW2 TH'],
  "hellner": ['HH EH1 L N ER0'],
  "hello": ['HH AH0 L OW1', 'HH EH0 L OW1'],
  "hellraiser": ['HH EH1 L R EY2 Z ER0'],
  "hellstrom": ['HH EH1 L S T R AH0 M'],
  "hellums": ['HH EH1 L AH0 M Z'],
  "helluva": ['HH EH2 L UW1 V AH0'],
  "hellwig": ['HH EH1 L W IH0 G'],
  "hellyer": ['HH EH1 L IY0 ER0'],
  "helm": ['HH EH1 L M'],
  "helma": ['HH EH1 L M AH0'],
  "helman": ['HH EH1 L M AH0 N'],
  "helmbrecht": ['HH EH1 L M B R IH0 K T'],
  "helme": ['HH EH1 L M'],
  "helmer": ['HH EH1 L M ER0'],
  "helmerich": ['HH EH1 L M ER0 IH0 K'],
  "helmers": ['HH EH1 L M ER0 Z'],
  "helmes": ['HH EH1 L M Z'],
  "helmet": ['HH EH1 L M AH0 T'],
  "helmeted": ['HH EH1 L M AH0 T IH0 D'],
  "helmets": ['HH EH1 L M AH0 T S'],
  "helmich": ['HH EH1 L M IH0 K'],
  "helmick": ['HH EH1 L M IH0 K'],
  "helmig": ['HH EH1 L M IH0 G'],
  "helming": ['HH EH1 L M IH0 NG'],
  "helminiak": ['HH EH1 L M IH0 N IY0 AE0 K'],
  "helminski": ['HH IH0 L M IH1 N S K IY0'],
  "helminth": ['HH EH1 L M IH0 N TH'],
  "helmkamp": ['HH EH1 L M K AE2 M P'],
  "helmke": ['HH EH1 L M K'],
  "helmont": ['HH EH1 L M AA2 N T'],
  "helms": ['HH EH1 L M Z'],
  "helms'": ['HH EH1 L M Z'],
  "helms's": ['HH EH1 L M Z IH0 Z'],
  "helmsburton": ['HH EH1 L M Z B ER0 T AH0 N'],
  "helmsley": ['HH EH1 L M Z L IY0'],
  "helmsley's": ['HH EH1 L M Z L IY0 Z'],
  "helmsleys": ['HH EH1 L M Z L IY0 Z'],
  "helmsman": ['HH EH1 L M Z M AE2 N'],
  "helmstetter": ['HH EH1 L M S T IH0 T ER0'],
  "helmut": ['HH EH1 L M AH0 T'],
  "helmuth": ['HH EH1 L M UW2 TH'],
  "helot": ['HH EH1 L AH0 T'],
  "helotism": ['HH EH1 L AH0 T IH2 Z AH0 M'],
  "helotry": ['HH EH1 L AH0 T R IY0'],
  "helots": ['HH EH1 L AH0 T S'],
  "help": ['HH EH1 L P'],
  "helped": ['HH EH1 L P T'],
  "helper": ['HH EH1 L P ER0'],
  "helpers": ['HH EH1 L P ER0 Z'],
  "helpful": ['HH EH1 L P F AH0 L'],
  "helpfully": ['HH EH1 L P F AH0 L IY0'],
  "helping": ['HH EH1 L P IH0 NG'],
  "helpings": ['HH EH1 L P IH0 NG Z'],
  "helpless": ['HH EH1 L P L AH0 S'],
  "helplessly": ['HH EH1 L P L AH0 S L IY0'],
  "helplessness": ['HH EH1 L P L AH0 S N AH0 S'],
  "helprin": ['HH EH1 L P R IH0 N'],
  "helps": ['HH EH1 L P S'],
  "helsel": ['HH EH1 L S AH0 L'],
  "helser": ['HH EH1 L S ER0'],
  "helseth": ['HH EH1 L S IH0 TH'],
  "helsinki": ['HH EH1 L S IH0 NG K IY0'],
  "helsley": ['HH EH1 L S L IY0'],
  "helstrom": ['HH EH1 L S T R AH0 M'],
  "helt": ['HH EH1 L T'],
  "helter": ['HH EH1 L T ER0'],
  "helter-skelter": ['HH EH2 L T ER0 S K EH1 L T ER0'],
  "helton": ['HH EH1 L T AH0 N'],
  "heltsley": ['HH EH1 L T S L IY0'],
  "heltzel": ['HH EH1 L T Z AH0 L'],
  "helvetica": ['HH EH2 L V EH1 T IH0 K AH0'],
  "helvey": ['HH EH1 L V IY0'],
  "helvie": ['HH EH1 L V IY0'],
  "helwig": ['HH EH1 L W IH0 G'],
  "helzer": ['HH EH1 L Z ER0'],
  "hem": ['HH EH1 M'],
  "heman": ['HH IY1 M AH0 N'],
  "hemani": ['HH AH0 M AA1 N IY0'],
  "hemann": ['HH EH1 M AH0 N'],
  "hemant": ['HH EH1 M AH0 N T'],
  "hematite": ['HH EH1 M AH0 T AY2 T'],
  "hematology": ['HH EH2 M AH0 T AA1 L AH0 JH IY0', 'HH IY2 M AH0 T AA1 L AH0 JH IY0'],
  "hemberger": ['HH EH1 M B ER0 G ER0'],
  "hembree": ['HH IH0 M B R IY1'],
  "hembrick": ['HH EH1 M B R IH2 K'],
  "hemby": ['HH EH1 M B IY0'],
  "hemdale": ['HH EH1 M D EY2 L'],
  "hemdale's": ['HH EH1 M D EY2 L Z'],
  "hemel": ['HH EH1 M AH0 L'],
  "hemenway": ['HH EH1 M AH0 N W EY2'],
  "hemimorphite": ['HH EH2 M AH0 M AO1 R F AY2 T'],
  "heming": ['HH EH1 M IH0 NG'],
  "heminger": ['HH EH1 M IH0 NG ER0'],
  "hemingway": ['HH EH1 M IH0 NG W EY2'],
  "hemingway's": ['HH EH1 M IH0 NG W EY2 Z'],
  "hemiplegia": ['HH EH2 M AH0 P L IY1 JH IY0 AH0', 'HH EH2 M AH0 P L IY1 JH Y AH0'],
  "hemisphere": ['HH EH1 M IH0 S F IH2 R'],
  "hemispheric": ['HH EH2 M AH0 S F IH1 R IH0 K'],
  "hemker": ['HH EH1 M K ER0'],
  "hemler": ['HH EH1 M L ER0'],
  "hemline": ['HH EH1 M L AY2 N'],
  "hemlines": ['HH EH1 M L AY2 N Z'],
  "hemlo": ['HH EH1 M L OW0'],
  "hemlock": ['HH EH1 M L AA2 K'],
  "hemm": ['HH EH1 M'],
  "hemme": ['HH EH1 M'],
  "hemmed": ['HH EH1 M D'],
  "hemmelgarn": ['HH EH1 M IH0 L G AA0 R N'],
  "hemmen": ['HH EH1 M AH0 N'],
  "hemmer": ['HH EH1 M ER0'],
  "hemmerich": ['HH EH1 M ER0 IH0 K'],
  "hemmerle": ['HH EH1 M ER0 L IY0'],
  "hemmerling": ['HH EH1 M ER0 L IH0 NG'],
  "hemmert": ['HH EH1 M ER0 T'],
  "hemmeter": ['HH EH1 M IH0 T ER0'],
  "hemming": ['HH EH1 M IH0 NG'],
  "hemminger": ['HH EH1 M IH0 NG ER0'],
  "hemminghaus": ['HH EH1 M IH0 NG HH AW2 S'],
  "hemmings": ['HH EH1 M IH0 NG Z'],
  "hemmingsen": ['HH EH1 M IH0 NG S AH0 N'],
  "hemmingson": ['HH EH1 M IH0 NG S AH0 N'],
  "hemo": ['HH IY1 M OW0'],
  "hemocyanin": ['HH IY2 M AH0 S AY1 AH0 N AH0 N'],
  "hemodynamic": ['HH EH2 M OW0 D AY0 N AE1 M IH0 K'],
  "hemodynamics": ['HH EH2 M OW0 D AY0 N AE1 M IH0 K S'],
  "hemoglobin": ['HH IY2 M AH0 G L OW1 B AH0 N'],
  "hemolytic": ['HH IY2 M AH0 L IH1 T IH0 K'],
  "hemond": ['HH EH1 M AH0 N D'],
  "hemophilia": ['HH IY2 M AH0 F IY1 L IY0 AH0'],
  "hemophiliac": ['HH IY0 M AH0 F IH1 L IY0 AE0 K', 'HH IY0 M OW0 F IH1 L IY0 AE0 K', 'HH IY0 M AH0 F IH1 L Y AE0 K', 'HH IY0 M OW0 F IH1 L Y AE0 K'],
  "hemophiliacs": ['HH IY2 M AH0 F IH1 L IY0 AE2 K S'],
  "hemorrhage": ['HH EH1 M ER0 IH0 JH', 'HH EH1 M R AH0 JH'],
  "hemorrhaged": ['HH EH1 M ER0 IH0 JH D'],
  "hemorrhagic": ['HH EH2 M ER0 AE1 G IH0 K'],
  "hemorrhaging": ['HH EH1 M ER0 IH0 JH IH0 NG'],
  "hemorrhoid": ['HH EH1 M ER0 OY2 D'],
  "hemorrhoids": ['HH EH1 M ER0 OY2 D Z'],
  "hemotec": ['HH EH1 M OW0 T EH2 K'],
  "hemp": ['HH EH1 M P'],
  "hempel": ['HH EH1 M P AH0 L'],
  "hempen": ['HH EH1 M P AH0 N'],
  "hempfling": ['HH EH1 M P F AH0 L IH0 NG', 'HH EH1 M P F L IH0 NG'],
  "hemphill": ['HH EH1 M P HH IH2 L'],
  "hempstead": ['HH EH1 M P S T EH0 D'],
  "hemric": ['HH EH1 M R IH0 K'],
  "hemrick": ['HH EH1 M R IH0 K'],
  "hemry": ['HH EH1 M R IY0'],
  "hems": ['HH EH1 M Z'],
  "hemsley": ['HH EH1 M Z L IY0'],
  "hemstreet": ['HH EH1 M S T R IY2 T'],
  "hemy": ['HH EH1 M IY0'],
  "hen": ['HH EH1 N'],
  "hen's": ['HH EH1 N Z'],
  "henao": ['HH EY1 N AW0'],
  "henard": ['HH EH1 N ER0 D'],
  "henault": ['HH EH1 N AW0 L T'],
  "henbane": ['HH EH1 N B EY2 N'],
  "hence": ['HH EH1 N S'],
  "henceforth": ['HH EH1 N S F AO1 R TH'],
  "hench": ['HH EH1 N CH'],
  "henchman": ['HH EH1 N CH M AH0 N'],
  "henchmen": ['HH EH1 N CH M AH0 N'],
  "henckel": ['HH EH1 N K AH0 L'],
  "hendee": ['HH EH1 N D IY0'],
  "hendel": ['HH EH1 N D AH0 L'],
  "hendershot": ['HH EH1 N D ER0 SH AH0 T'],
  "hendershott": ['HH EH1 N D ER0 SH AH0 T'],
  "henderson": ['HH EH1 N D ER0 S AH0 N'],
  "hendler": ['HH EH1 N D L ER0'],
  "hendley": ['HH EH1 N D L IY0'],
  "hendon": ['HH EH1 N D OW0 N'],
  "hendra": ['HH EH1 N D R AH0'],
  "hendren": ['HH EH1 N D ER0 AH0 N'],
  "hendrich": ['HH EH1 N D R IH0 K'],
  "hendrick": ['HH EH1 N D R IH0 K'],
  "hendricks": ['HH EH1 N D R IH0 K S'],
  "hendricksen": ['HH EH1 N D R IH0 K S AH0 N'],
  "hendrickson": ['HH EH1 N D R IH0 K S AH0 N'],
  "hendrie": ['HH EH1 N D ER0 IY0'],
  "hendrik": ['HH EH1 N D R IH0 K'],
  "hendriks": ['HH EH1 N D R IH0 K S'],
  "hendriksen": ['HH EH1 N D R IH0 K S AH0 N'],
  "hendrix": ['HH EH1 N D R IH0 K S'],
  "hendrixson": ['HH EH1 N D R IH0 K S AH0 N'],
  "hendron": ['HH EH1 N D R AH0 N'],
  "hendry": ['HH EH1 N D R IY0'],
  "hendry's": ['HH EH1 N D R IY0 Z'],
  "hendryx": ['HH EH1 N D R IH0 K S'],
  "hendy": ['HH EH1 N D IY0'],
  "henegar": ['HH EH1 N IH0 G ER0'],
  "heneghan": ['HH IH0 N EH1 G AH0 N'],
  "henehan": ['HH EH1 N IH0 HH AE0 N'],
  "henery": ['HH EH1 N ER0 IY0'],
  "henes": ['HH IY1 N Z'],
  "heney": ['HH EH1 N IY0'],
  "heng": ['HH EH1 NG'],
  "hengel": ['HH EH1 NG G AH0 L'],
  "hengst": ['HH EH1 NG G S T'],
  "henhouse": ['HH EH1 N HH AW2 S'],
  "henie": ['HH EH1 N IY0'],
  "henigan": ['HH EH1 N IH0 G AH0 N'],
  "henin": ['HH EH1 N IH0 N'],
  "heninger": ['HH EH1 N IH0 NG ER0'],
  "henion": ['HH EH1 N Y AH0 N'],
  "henk": ['HH EH1 NG K'],
  "henke": ['HH EH1 NG K'],
  "henkel": ['HH EH1 NG K AH0 L'],
  "henkelman": ['HH EH1 NG K AH0 L M AH0 N'],
  "henkels": ['HH EH1 NG K AH0 L Z'],
  "henken": ['HH EH1 NG K AH0 N'],
  "henkes": ['HH EH1 NG K S'],
  "henkin": ['HH EH1 NG K IH0 N'],
  "henkle": ['HH EH1 NG K AH0 L'],
  "henle": ['HH EH1 N AH0 L'],
  "henley": ['HH EH1 N L IY0'],
  "henley's": ['HH EH1 N L IY0 Z'],
  "henleys": ['HH EH1 N L IY0 Z'],
  "henline": ['HH EH1 N L AY2 N'],
  "henly": ['HH EH1 N L IY0'],
  "henman": ['HH EH1 N M AH0 N'],
  "henn": ['HH EH1 N'],
  "henna": ['HH EH1 N AH0'],
  "hennan": ['HH EH1 N AH0 N'],
  "henne": ['HH EH1 N', 'HH EH1 N IY0'],
  "henneberger": ['HH EH1 N B ER0 G ER0'],
  "henneberry": ['HH EH1 N B EH0 R IY0'],
  "henneke": ['HH EH1 N IH0 K'],
  "hennelly": ['HH EH1 N AH0 L IY0'],
  "henneman": ['HH EH1 N M AH0 N'],
  "hennen": ['HH EH1 N AH0 N'],
  "hennepin": ['HH EH1 N IH0 P IH0 N'],
  "henner": ['HH EH1 N ER0'],
  "hennes": ['HH EH1 N Z'],
  "henness": ['HH EH1 N IH0 S'],
  "hennessee": ['HH EH1 N IH0 S IY0'],
  "hennessey": ['HH EH1 N AH0 S IY0'],
  "hennessey's": ['HH EH1 N AH0 S IY0 Z'],
  "hennessy": ['HH EH1 N AH0 S IY0'],
  "henney": ['HH EH1 N IY0'],
  "hennick": ['HH EH1 N IH0 K'],
  "hennig": ['HH EH1 N IH0 G'],
  "hennigan": ['HH EH1 N IH0 G AH0 N'],
  "hennigar": ['HH EH1 N IH0 G ER0'],
  "henning": ['HH EH1 N IH0 NG'],
  "henninger": ['HH EH1 N IH0 NG ER0'],
  "hennings": ['HH EH1 N IH0 NG Z'],
  "henningsen": ['HH EH1 N IH0 NG S AH0 N'],
  "hennington": ['HH EH1 N IH0 NG T AH0 N'],
  "hennis": ['HH EH1 N IH0 S'],
  "hennon": ['HH EH1 N AH0 N'],
  "henpeck": ['HH EH1 N P EH2 K'],
  "henpecked": ['HH EH1 N P EH2 K T'],
  "henri": ['HH EH1 N R IY0', 'AO2 R IY1', 'AA2 N R IY1'],
  "henrich": ['HH EH1 N R IH0 K'],
  "henrichs": ['HH EH1 N R IH0 K S'],
  "henrichsen": ['HH EH1 N R IH0 K S AH0 N'],
  "henrick": ['HH EH1 N R IH0 K'],
  "henricks": ['HH EH1 N R IH0 K S'],
  "henricksen": ['HH EH1 N R IH0 K S AH0 N'],
  "henrickson": ['HH EH1 N R IH0 K S AH0 N'],
  "henrie": ['HH EH1 N ER0 IY0'],
  "henrietta": ['HH EH2 N R IY0 EH1 T AH0'],
  "henriette": ['HH EH2 N R IY0 EH1 T'],
  "henrik": ['HH EH1 N R IH0 K'],
  "henrika": ['HH EH1 N R IH0 K AH0'],
  "henriksen": ['HH EH1 N R IH0 K S AH0 N'],
  "henrikson": ['HH EH1 N R IH0 K S AH0 N'],
  "henrique": ['AA0 N R IY1 K'],
  "henriques": ['HH EH0 N R IY1 K EH0 Z', 'AA0 N R IY1 K'],
  "henriquez": ['HH EH0 N R IY1 K EH0 Z', 'AA0 N R IY1 K'],
  "henry": ['HH EH1 N R IY0'],
  "henry's": ['HH EH1 N R IY0 Z'],
  "hens": ['HH EH1 N Z'],
  "hensarling": ['HH EH1 N S AA0 R L IH0 NG'],
  "hensch": ['HH EH1 N SH'],
  "henschel": ['HH EH1 N SH AH0 L'],
  "henschen": ['HH EH1 N SH AH0 N'],
  "hense": ['HH EH1 N S'],
  "hensel": ['HH EH1 N S AH0 L'],
  "hensen": ['HH EH1 N S AH0 N'],
  "henshaw": ['HH EH1 N SH AO2'],
  "hensil": ['HH EH1 N S AH0 L'],
  "henske": ['HH EH1 N S K IY0'],
  "henslee": ['HH EH1 N Z L IY2'],
  "hensler": ['HH EH1 N S AH0 L ER0', 'HH EH1 N S L ER0'],
  "hensley": ['HH EH1 N Z L IY0'],
  "henson": ['HH EH1 N S AH0 N'],
  "hentges": ['HH EH1 N T JH IH0 Z'],
  "henthorn": ['HH EH1 N TH AO2 R N'],
  "henthorne": ['HH EH1 N TH AO2 R N'],
  "hentic": ['HH EH1 N T IH0 K'],
  "hentoff": ['HH EH1 N T AO0 F'],
  "henton": ['HH EH1 N T AH0 N'],
  "hentschel": ['HH EH1 N CH AH0 L'],
  "hentz": ['HH EH1 N T S'],
  "henwood": ['HH EH1 N W UH2 D'],
  "henze": ['HH EH1 N Z'],
  "henzel": ['HH EH1 N Z AH0 L'],
  "henzler": ['HH EH1 N Z L ER0'],
  "heon": ['HH IY1 AH0 N'],
  "heoroico": ['HH IY2 AH0 R OW1 AH0 K OW0'],
  "hep": ['HH EH1 P'],
  "heparin": ['HH EH1 P ER0 IH0 N'],
  "hepatic": ['HH AH0 P AE1 T IH0 K'],
  "hepatitis": ['HH EH2 P AH0 T AY1 T AH0 S'],
  "hepburn": ['HH EH1 P B ER0 N'],
  "hepburn's": ['HH EH1 P B ER0 N Z'],
  "hepfer": ['HH EH1 P F ER0'],
  "hepker": ['HH EH1 P K ER0'],
  "hepler": ['HH EH1 P L ER0'],
  "hepner": ['HH EH1 P N ER0'],
  "hepp": ['HH EH1 P'],
  "heppe": ['HH EH1 P'],
  "hepper": ['HH EH1 P ER0'],
  "heppler": ['HH EH1 P L ER0'],
  "heppner": ['HH EH1 P N ER0'],
  "heptathlon": ['HH EH0 P T AE1 TH L AA0 N'],
  "hepworth": ['HH EH1 P W ER0 TH'],
  "her": ['HH ER1', 'HH ER0'],
  "her's": ['HH ER1 Z'],
  "hera": ['HH IH1 R AH0'],
  "heradia": ['HH ER0 AA1 D IY0 AH0'],
  "herald": ['HH EH1 R AH0 L D'],
  "herald's": ['HH EH1 R AH0 L D Z'],
  "heralded": ['HH EH1 R AH0 L D IH0 D'],
  "heraldic": ['HH EH0 R AE1 L D IH0 K'],
  "heralding": ['HH EH1 R AH0 L D IH0 NG'],
  "heraldry": ['HH EH1 R AH0 L D R IY0'],
  "heralds": ['HH EH1 R AH0 L D Z'],
  "herb": ['ER1 B', 'HH ER1 B'],
  "herb's": ['ER1 B Z', 'HH ER1 B Z'],
  "herbaceous": ['ER0 B EY1 SH AH0 S'],
  "herbal": ['ER1 B AH0 L', 'HH ER1 B AH0 L'],
  "herbalife": ['HH ER1 B AH0 L AY2 F', 'ER1 B AH0 L AY2 F'],
  "herbalist": ['ER1 B AH0 L AH0 S T', 'HH ER1 B AH0 L AH0 S T'],
  "herbalist's": ['ER1 B AH0 L AH0 S T S', 'HH ER1 B AH0 L AH0 S T S'],
  "herbalists": ['ER1 B AH0 L AH0 S T S', 'HH ER1 B AH0 L AH0 S T S', 'ER1 B AH0 L AH0 S', 'HH ER1 B AH0 L AH0 S'],
  "herbalists'": ['ER1 B AH0 L AH0 S T S', 'HH ER1 B AH0 L AH0 S T S'],
  "herbarium": ['HH ER0 B EH1 R IY2 AH0 M', 'ER0 B EH1 R IY2 AH0 M'],
  "herbariums": ['HH ER0 B EH1 R IY2 AH0 M Z', 'ER0 B EH1 R IY2 AH0 M Z'],
  "herbeck": ['HH ER1 B EH0 K'],
  "herbel": ['HH ER1 B AH0 L'],
  "herber": ['HH ER1 B ER0'],
  "herberg": ['HH ER1 B ER0 G'],
  "herberger": ['HH ER1 B ER0 G ER0'],
  "herbers": ['HH ER1 B ER0 Z'],
  "herbert": ['HH ER1 B ER0 T'],
  "herbert's": ['HH ER1 B ER0 T S'],
  "herbicide": ['HH ER1 B IH0 S AY2 D', 'ER1 B IH0 S AY2 D'],
  "herbicides": ['ER1 B AH0 S AY2 D Z', 'HH ER1 B AH0 S AY2 D Z'],
  "herbie": ['HH ER1 B IY0'],
  "herbig": ['HH ER1 B IH0 G'],
  "herbin": ['HH ER1 B IH0 N'],
  "herbison": ['HH ER1 B IH0 S AH0 N'],
  "herbivore": ['HH ER1 B IH0 V AO2 R', 'ER1 B IH0 V AO2 R'],
  "herbivorous": ['HH ER0 B IH1 V ER0 AH0 S', 'ER0 B IH1 V ER0 AH0 S'],
  "herbold": ['HH ER1 B OW0 L D'],
  "herbs": ['ER1 B Z'],
  "herbst": ['HH ER1 B S T'],
  "herbster": ['HH ER1 B S T ER0'],
  "herceg": ['HH ER1 S IH0 G'],
  "hercegovina": ['HH EH2 R T S AH0 G OW0 V IY1 N AH0', 'HH ER2 R T S AH0 G OW0 V IY1 N AH0'],
  "hercegovina's": ['HH EH2 R T S AH0 G OW0 V IY1 N AH0 Z', 'HH ER2 R T S AH0 G OW0 V IY1 N AH0 Z'],
  "herculean": ['HH ER0 K Y UW1 L IY0 AH0 N'],
  "hercules": ['HH ER1 K Y AH0 L IY2 Z'],
  "herczeg": ['HH ER1 CH IH0 G'],
  "herd": ['HH ER1 D'],
  "herda": ['HH EH1 R D AH0'],
  "herdal": ['HH EH1 R D AH0 L'],
  "herded": ['HH ER1 D IH0 D'],
  "herder": ['HH EH1 R D ER0', 'HH ER1 D ER0'],
  "herders": ['HH ER1 D ER0 Z'],
  "herding": ['HH ER1 D IH0 NG'],
  "herdman": ['HH ER1 D M AH0 N'],
  "herds": ['HH ER1 D Z'],
  "herdsmen": ['HH ER1 D Z M IH0 N'],
  "herdt": ['HH ER1 T'],
  "here": ['HH IY1 R'],
  "here's": ['HH IH1 R Z'],
  "hereabout": ['HH IH1 R AH0 B AW2 T'],
  "hereabouts": ['HH IH1 R AH0 B AW2 T S'],
  "hereafter": ['HH IH0 R AE1 F T ER0'],
  "hereby": ['HH IH0 R B AY1'],
  "heredia": ['HH ER0 EH1 D IY0 AH0'],
  "hereditary": ['HH ER0 EH1 D AH0 T EH2 R IY0'],
  "heredity": ['HH ER0 EH1 D AH0 T IY0'],
  "hereford": ['HH EH1 R AH0 F ER0 D'],
  "herein": ['HH IH0 R IH1 N'],
  "herendeen": ['HH IH1 R N D IY0 N'],
  "heresy": ['HH EH1 R AH0 S IY0'],
  "heretic": ['HH EH1 R AH0 T IH0 K'],
  "heretical": ['HH ER0 EH1 T IH0 K AH0 L'],
  "heretics": ['HH EH1 R AH0 T IH0 K S'],
  "heretofore": ['HH IH2 R T AH0 F AO1 R'],
  "herewith": ['HH IH1 R W IH1 TH'],
  "herford": ['HH ER1 F ER0 D'],
  "herfurth": ['HH ER1 F ER0 TH'],
  "hergert": ['HH ER1 G ER0 T'],
  "herget": ['HH ER1 G IH0 T'],
  "hergott": ['HH ER1 G AH0 T'],
  "herin": ['HH EH1 R IH0 N'],
  "hering": ['HH ER1 IH0 NG'],
  "heringer": ['HH EH1 R IH0 N JH ER0'],
  "herington": ['HH EH1 R IH0 NG T AH0 N'],
  "heritable": ['HH EH1 R AH0 T AH0 B AH0 L'],
  "heritage": ['HH EH1 R AH0 T AH0 JH', 'HH EH1 R IH0 T IH0 JH'],
  "heritage's": ['HH EH1 R AH0 T IH0 JH IH0 Z'],
  "heritages": ['HH EH1 R IH0 T IH0 JH AH0 Z'],
  "herk": ['HH ER1 K'],
  "herkert": ['HH ER1 K ER0 T'],
  "herl": ['HH ER1 L'],
  "herlihy": ['HH ER1 L IH0 HH IY0'],
  "herling": ['HH ER1 L IH0 NG'],
  "herlong": ['HH ER1 L AO0 NG'],
  "herm": ['HH ER1 M'],
  "herman": ['HH ER1 M AH0 N'],
  "herman's": ['HH ER1 M AH0 N Z'],
  "hermance": ['HH ER1 M AH0 N S'],
  "hermann": ['HH ER1 M AH0 N'],
  "hermanns": ['HH ER1 M AA0 N Z'],
  "hermans": ['HH ER1 M AH0 N Z'],
  "hermansen": ['HH ER1 M AH0 N S AH0 N'],
  "hermanson": ['HH ER1 M AH0 N S AH0 N'],
  "hermaphrodite": ['HH ER0 M AE1 F R AH0 D AY2 T'],
  "hermaphroditic": ['HH ER0 M AE2 F R AH0 D IH1 T IH0 K'],
  "hermenio": ['HH ER0 M EH1 N IY0 OW0'],
  "hermes": ['HH ER1 M IY0 Z'],
  "hermetically": ['HH ER0 M EH1 T IH0 K AH0 L IY0', 'HH ER0 M EH1 T IH0 K L IY0'],
  "hermia": ['HH ER1 M IY0 AH0'],
  "hermias": ['HH ER1 M IY0 AH0 Z'],
  "hermida": ['HH EH0 R M IY1 D AH0'],
  "hermie": ['HH ER1 M IY0'],
  "hermina": ['HH ER1 M IH0 N AH0', 'HH ER0 M IY1 N AH0'],
  "hermine": ['HH ER1 M IH0 N'],
  "herminia": ['HH EH0 R M IY1 N IY0 AH0'],
  "herminie": ['HH ER2 M IH1 N IY0'],
  "hermione": ['HH ER0 M IY0 OW1 N IY0'],
  "hermit": ['HH ER1 M AH0 T'],
  "hermitage": ['HH ER1 M AH0 T AH0 JH'],
  "hermits": ['HH ER1 M AH0 T S'],
  "hermon": ['HH ER1 M AH0 N'],
  "hermosa": ['HH EH0 R M OW1 S AH0'],
  "hermosillo": ['HH ER0 M AH0 S IH1 L OW0'],
  "herms": ['HH ER1 M Z'],
  "hermsen": ['HH ER1 M S AH0 N'],
  "hern": ['HH ER1 N'],
  "hernan": ['HH ER1 N AH0 N'],
  "hernandes": ['HH ER1 N IH0 N D Z', 'HH ER0 N AE1 N D EH0 Z'],
  "hernandez": ['HH ER0 N AE1 N D EH0 Z'],
  "hernandez's": ['HH ER0 N AE1 N D EH0 Z AH0 Z'],
  "hernando": ['HH ER0 N AA1 N D OW0'],
  "herndon": ['HH ER1 N D AH0 N'],
  "herne": ['HH ER1 N'],
  "herner": ['HH ER1 N ER0'],
  "hernia": ['HH ER1 N IY0 AH0'],
  "herniate": ['HH ER1 N IY0 EY2 T'],
  "herniates": ['HH ER1 N IY0 EY2 T S'],
  "hernon": ['HH ER1 N AH0 N'],
  "hero": ['HH IH1 R OW0', 'HH IY1 R OW0'],
  "hero's": ['HH IH1 R OW0 Z', 'HH IY1 R OW0 Z'],
  "herod": ['HH EH1 R AH0 D'],
  "heroes": ['HH IH1 R OW0 Z', 'HH IY1 R OW0 Z'],
  "heroic": ['HH IH0 R OW1 IH0 K'],
  "heroically": ['HH IH2 R OW1 IH0 K L IY0'],
  "heroics": ['HH IH0 R OW1 IH0 K S'],
  "heroin": ['HH EH1 R OW0 AH0 N'],
  "heroin's": ['HH EH1 R OW0 AH0 N Z'],
  "heroine": ['HH EH1 R OW0 AH0 N'],
  "heroines": ['HH EH1 R OW2 AH0 N Z'],
  "heroism": ['HH EH1 R OW0 IH2 Z AH0 M'],
  "heroize": ['HH IY1 R OW0 AY2 Z'],
  "heroized": ['HH IY1 R OW0 AY2 Z D'],
  "herold": ['HH EH1 R AH0 L D'],
  "heron": ['HH EH1 R AH0 N'],
  "heron's": ['HH EH1 R AH0 N Z'],
  "herons": ['HH EH1 R AH0 N Z'],
  "heros": ['HH IH1 R OW0 Z'],
  "heroux": ['HH ER0 UW1'],
  "herpes": ['HH ER1 P IY0 Z'],
  "herr": ['HH EH1 R'],
  "herre": ['HH EH1 R', 'HH AH1 R IY0'],
  "herreid": ['HH EH1 R AY0 D'],
  "herrell": ['HH EH1 R AH0 L'],
  "herren": ['HH EH1 R AH0 N'],
  "herrera": ['HH ER0 EH1 R AH0'],
  "herrero": ['HH EH0 R EH1 R OW0'],
  "herrhausen": ['HH EH1 R HH AW2 Z AH0 N'],
  "herriage": ['HH EH1 R IY0 IH0 JH'],
  "herrick": ['HH EH1 R IH0 K'],
  "herridge": ['HH EH1 R IH0 JH'],
  "herrig": ['HH EH1 R IH0 G'],
  "herriman": ['HH EH1 R IH0 M AH0 N'],
  "herrin": ['HH EH1 R IH0 N'],
  "herring": ['HH EH1 R IH0 NG'],
  "herrings": ['HH EH1 R IH0 NG Z'],
  "herringshaw": ['HH EH1 R IH0 NG SH AO2'],
  "herrington": ['HH EH1 R IH0 NG T AH0 N'],
  "herrington's": ['HH EH1 R IH0 NG T AH0 N Z'],
  "herriott": ['HH EH1 R IY0 AA0 T', 'HH EH1 R IY0 AH0 T'],
  "herrle": ['HH EH1 R AH0 L'],
  "herrlinger": ['HH EH1 R L IH2 NG ER0', 'HH EH1 R L IH2 NG G ER0'],
  "herrman": ['HH EH1 R M AH0 N'],
  "herrmann": ['HH EH1 R M AH0 N'],
  "herro": ['HH EH1 R OW0'],
  "herrod": ['HH EH1 R AH0 D'],
  "herrold": ['HH EH1 R OW2 L D'],
  "herron": ['HH EH1 R AH0 N'],
  "herronimo": ['HH ER0 AA1 N AH0 M OW0'],
  "herronimo's": ['HH ER0 AA1 N AH0 M OW0 Z'],
  "hers": ['HH ER0 Z', 'HH ER1 Z'],
  "hersant": ['HH ER1 S AH0 N T'],
  "hersch": ['HH ER1 SH'],
  "herschel": ['HH ER1 SH AH0 L'],
  "herschel's": ['HH ER1 SH AH0 L Z'],
  "herschell": ['HH ER1 SH AH0 L'],
  "herschensohn": ['HH ER1 SH AH0 N S AH0 N'],
  "herscu": ['HH ER0 S K UW1'],
  "herself": ['HH ER0 S EH1 L F'],
  "hersey": ['HH ER1 S IY0'],
  "hersh": ['HH ER1 SH'],
  "hershberger": ['HH ER1 SH B ER0 G ER0'],
  "hershey": ['HH ER1 SH IY0'],
  "hershey's": ['HH ER1 SH IY0 Z'],
  "hershiser": ['HH ER1 SH AY0 Z ER0'],
  "hershkowitz": ['HH ER1 SH K AH0 W IH0 T S'],
  "hershman": ['HH ER1 SH M AH0 N'],
  "hershner": ['HH ER1 SH N ER0'],
  "herskovitz": ['HH ER1 S K AH0 V IH0 T S'],
  "herskowitz": ['HH ER1 S K AH0 W IH0 T S'],
  "hersman": ['HH ER1 S M AH0 N'],
  "hersom": ['HH ER1 S AH0 M'],
  "herson": ['HH ER1 S AH0 N'],
  "herst": ['HH ER1 S T'],
  "hert": ['HH ER1 T'],
  "herta": ['HH ER1 T AH0'],
  "hertel": ['HH ER1 T AH0 L'],
  "hertenstein": ['HH ER1 T AH0 N S T AY0 N', 'HH ER1 T AH0 N S T IY0 N'],
  "herter": ['HH ER1 T ER0'],
  "hertha": ['HH ER1 TH AH0'],
  "hertig": ['HH ER1 T IH0 G'],
  "herting": ['HH ER1 T IH0 NG'],
  "hertlein": ['HH ER1 T L AY0 N'],
  "hertog": ['HH ER1 T AA0 G'],
  "hertz": ['HH EH1 R T S', 'HH ER1 T S'],
  "hertzberg": ['HH ER1 T S B ER0 G'],
  "hertzenleben": ['HH ER1 T AH0 N L EY2 B AH0 N'],
  "hertzenleben's": ['HH ER1 T AH0 N L EY2 B AH0 N Z'],
  "hertzler": ['HH ER1 T Z AH0 L ER0', 'HH ER1 T Z L ER0'],
  "hertzog": ['HH ER1 T Z AH0 G', 'HH ER1 T Z AA2 G'],
  "herve": ['HH ER1 V', 'HH ER1 V EY0'],
  "hervey": ['HH ER0 V EY1'],
  "herwick": ['HH ER1 W IH2 K'],
  "herwig": ['HH ER1 W IH0 G'],
  "herwitz": ['HH ER1 W IH0 T S'],
  "heryana": ['HH ER0 Y AE1 N AH0'],
  "herz": ['HH ER1 Z'],
  "herzberg": ['HH ER1 Z B ER0 G'],
  "herzberger": ['HH ER1 Z B ER0 G ER0'],
  "herzegovina": ['HH EH2 R T S AH0 G OW0 V IY1 N AH0', 'HH ER2 T S AH0 G OW0 V IY1 N AH0'],
  "herzegovina's": ['HH EH2 R T S AH0 G OW0 V IY1 N AH0 Z', 'HH ER2 T S AH0 G OW0 V IY1 N AH0 Z'],
  "herzer": ['HH ER1 Z ER0'],
  "herzfeld": ['HH ER1 Z F EH0 L D'],
  "herzig": ['HH ER1 Z IH0 G'],
  "herzing": ['HH ER1 Z IH0 NG'],
  "herzlinger": ['HH ER1 Z L IH2 NG ER0'],
  "herzog": ['HH ER1 Z AA0 G'],
  "hesch": ['HH EH1 SH'],
  "heseltine": ['HH EH1 S AH0 L T IY2 N', 'HH EH1 S AH0 L T AY2 N'],
  "heselton": ['HH IH0 S EH1 L T AH0 N'],
  "hesheng": ['HH EH1 SH EH1 NG'],
  "hesik": ['HH EH1 S IH0 K', 'HH EY1 S IH0 K'],
  "hesiod": ['HH IY1 S IY0 AH0 D'],
  "hesitancy": ['HH EH1 Z IH0 T AH0 N S IY0'],
  "hesitant": ['HH EH1 Z IH0 T AH0 N T'],
  "hesitantly": ['HH EH1 Z IH0 T AH0 N T L IY0'],
  "hesitate": ['HH EH1 Z AH0 T EY2 T'],
  "hesitated": ['HH EH1 Z IH0 T EY2 T IH0 D'],
  "hesitates": ['HH EH1 Z AH0 T EY2 T S'],
  "hesitating": ['HH EH1 Z AH0 T EY2 T IH0 NG'],
  "hesitation": ['HH EH2 Z AH0 T EY1 SH AH0 N'],
  "hesitations": ['HH EH2 Z AH0 T EY1 SH AH0 N Z'],
  "hesketh": ['HH EH1 S K IH0 TH'],
  "heskett": ['HH EH1 S K IH0 T'],
  "heslep": ['HH EH1 S L IH0 P'],
  "hesler": ['HH EH1 S AH0 L ER0', 'HH EH1 S L ER0'],
  "heslin": ['HH EH1 Z L IH0 N'],
  "heslin's": ['HH EH1 Z L IH0 N Z'],
  "heslop": ['HH EH1 S L AH0 P'],
  "hesper": ['HH EH1 S P ER0'],
  "hespera": ['HH EY0 S P EH1 R AH0'],
  "hess": ['HH EH1 S'],
  "hesse": ['HH EH1 S'],
  "hesse's": ['HH EH1 S IH0 Z'],
  "hessel": ['HH EH1 S AH0 L'],
  "hesseltine": ['HH EH1 S IH0 L T IY0 N'],
  "hesser": ['HH EH1 S ER0'],
  "hessian": ['HH EH1 SH AH0 N'],
  "hessing": ['HH EH1 S IH0 NG'],
  "hessinger": ['HH EH1 S IH0 N JH ER0'],
  "hession": ['HH EH1 SH IH0 N'],
  "hessite": ['HH EH1 S AY0 T'],
  "hessler": ['HH EH1 S L ER0'],
  "hessling": ['HH EH1 S AH0 L IH0 NG', 'HH EH1 S L IH0 NG'],
  "hesson": ['HH EH1 S AH0 N'],
  "hesston": ['HH EH1 S T AH0 N'],
  "hesston's": ['HH EH1 S T AH0 N Z'],
  "hestand": ['HH EH1 S T AH0 N D'],
  "hester": ['HH EH1 S T ER0'],
  "hesther": ['HH EH1 S DH ER0'],
  "hestia": ['HH EH1 S T IY0 AH0'],
  "heston": ['HH EH1 S T AH0 N'],
  "heterocercal": ['HH EH2 T ER0 OW0 S ER1 K AH0 L'],
  "heterodox": ['HH EH1 T ER0 AH0 D AA2 K S'],
  "heterodoxy": ['HH EH1 T ER0 AH0 D AA2 K S IY0'],
  "heterodyne": ['HH EH1 T ER0 AH0 D AY2 N'],
  "heterogeneity": ['HH EH2 T ER0 AH0 JH IH0 N IY1 AH0 T IY0', 'HH EH2 T ER0 AH0 JH IH0 N EY1 AH0 T IY0'],
  "heterogeneous": ['HH EH2 T ER0 AH0 JH IY1 N Y AH0 S'],
  "heterosexual": ['HH EH2 T ER0 OW0 S EH1 K SH AH0 W AH0 L'],
  "heterosexuality": ['HH EH2 T ER0 OW0 S EH0 K SH AH0 W AE1 L IH0 T IY0'],
  "heterosexuals": ['HH EH2 T ER0 OW0 S EH1 K SH AH0 W AH0 L Z'],
  "heterosis": ['HH EH2 T ER0 OW1 S AH0 S'],
  "heterosporous": ['HH EH2 T ER0 AA1 S P ER0 AH0 S'],
  "heterotrophic": ['HH EH2 T ER0 AH0 T R AA1 F IH0 K'],
  "heterozygous": ['HH EH2 T ER0 AH0 Z AY1 G AH0 S'],
  "heth": ['HH EH1 TH'],
  "hetherington": ['HH EH1 DH ER0 IH0 NG T AH0 N'],
  "hetland": ['HH EH1 T L AH0 N D'],
  "hetman": ['HH EH1 T M AH0 N'],
  "hetrick": ['HH EH1 T R IH0 K'],
  "hett": ['HH EH1 T'],
  "hettel": ['HH EH1 T AH0 L'],
  "hetti": ['HH EH1 T IY0'],
  "hettick": ['HH EH1 T IH0 K'],
  "hettie": ['HH EH1 T IY0'],
  "hettler": ['HH EH1 T L ER0'],
  "hettrick": ['HH EH1 T R IH0 K'],
  "hetty": ['HH EH1 T IY0'],
  "hetu": ['HH IY1 CH UW0'],
  "hetz": ['HH EH1 T S'],
  "hetzel": ['HH EH1 T Z AH0 L'],
  "hetzer": ['HH EH1 T Z ER0'],
  "hetzler": ['HH EH1 T S L ER0'],
  "heuberger": ['HH OY1 B ER0 G ER0'],
  "heublein": ['HH Y UW1 B L AY2 N'],
  "heuer": ['HH Y UW1 ER0'],
  "heuer's": ['HH Y UW1 ER0 Z'],
  "heuerman": ['HH OY1 ER0 M AH0 N'],
  "heuermann": ['HH OY1 ER0 M AH0 N'],
  "heuman": ['HH Y UW1 M AH0 N'],
  "heumann": ['HH Y UW1 M AH0 N'],
  "heun": ['HH Y UW1 N'],
  "heung-yeung": ['HH UW1 NG Y UW1 NG'],
  "heupel": ['HH OY1 P AH0 L'],
  "heuring": ['HH ER1 IH0 NG'],
  "heuristic": ['HH Y UH0 R IH1 S T IH0 K'],
  "heuristics": ['HH Y UH0 R IH1 S T IH0 K S'],
  "heusen": ['HH Y UW1 S AH0 N'],
  "heuser": ['HH OY1 S ER0'],
  "heusser": ['HH Y UW1 S ER0'],
  "hevener": ['HH EH1 V IY0 N ER0'],
  "heverly": ['HH EH1 V ER0 L IY0'],
  "hevey": ['HH IH0 V EY1'],
  "hevia": ['HH EY1 V IY0 AH0'],
  "hevner": ['HH EH1 V N ER0'],
  "hew": ['HH Y UW1'],
  "heward": ['HH Y UW1 ER0 D'],
  "hewe": ['HH Y UW1'],
  "hewell": ['HH EH1 W EH0 L', 'HH Y UW1 W EH0 L'],
  "hewer": ['HH Y UW1 ER0'],
  "hewes": ['HH Y UW1 Z'],
  "hewett": ['HH Y UW1 IH0 T'],
  "hewey": ['HH Y UW1 IY0'],
  "hewing": ['HH Y UW1 IH0 NG'],
  "hewins": ['HH Y UW1 IH0 N Z'],
  "hewitt": ['HH Y UW1 IH0 T'],
  "hewitt's": ['HH Y UW1 IH0 T S'],
  "hewler": ['HH Y UW1 L ER0'],
  "hewler's": ['HH Y UW1 L ER0 Z'],
  "hewlett": ['HH Y UW1 L IH0 T'],
  "hewn": ['HH Y UW1 N'],
  "hews": ['HH Y UW1 Z'],
  "hewson": ['HH Y UW1 S AH0 N'],
  "hex": ['HH EH1 K S'],
  "hexachlorophene": ['HH EH2 K S AH0 K L AO1 R AH0 F IY2 N'],
  "hexagon": ['HH EH1 K S AH0 G AA2 N'],
  "hexagonal": ['HH EH0 K S AE1 G AH0 N AH0 L'],
  "hexane": ['HH EH1 K S EY0 N'],
  "hexcel": ['HH EH1 K S AH0 L'],
  "hext": ['HH EH1 K S T'],
  "hey": ['HH EY1'],
  "heyboer": ['HH EY1 B OW0 ER0'],
  "heyd": ['HH EY1 D'],
  "heyday": ['HH EY1 D EY2'],
  "heyde": ['HH EY1 D'],
  "heyden": ['HH EY1 D AH0 N'],
  "heydon": ['HH EY1 D AH0 N'],
  "heydt": ['HH EY1 D T'],
  "heye": ['HH AY1'],
  "heyen": ['HH AY1 N'],
  "heyer": ['HH EY1 ER0'],
  "heying": ['HH EY1 IH0 NG'],
  "heyl": ['HH EY1 L'],
  "heyman": ['HH EY1 M AH0 N'],
  "heyman's": ['HH EY1 M AH0 N Z'],
  "heymann": ['HH EY1 M AH0 N'],
  "heymann's": ['HH EY1 M AH0 N Z'],
  "heyn": ['HH EY1 N'],
  "heyne": ['HH EY1 N'],
  "heys": ['HH EY1 Z'],
  "heyse": ['HH EY1 S'],
  "heyser": ['HH EY1 Z ER0'],
  "heyward": ['HH EY1 W ER0 D'],
  "heywood": ['HH EY1 W UH2 D'],
  "hezbollah": ['HH EH1 Z B AA0 L AH2'],
  "hezbollah's": ['HH EH1 Z B AA0 L AH2 Z'],
  "hezbullah": ['HH EH2 Z B AA0 L AH1', 'HH EH1 Z B AA0 L AH2'],
  "hezbullah's": ['HH EH1 Z B AA0 L AH2 Z'],
  "hfdf": ['EY1 CH EH1 F D IY1 EH1 F'],
  "hgh": ['EY1 CH JH IY1 EY1 CH'],
  "hi": ['HH AY1'],
  "hi-fi": ['HH AY1 F AY1'],
  "hiaa": ['EY1 CH AY1 EY1 EY1'],
  "hiaasen": ['HH AY1 AA0 S IH0 N'],
  "hialeah": ['HH AY2 AH0 L IY1 AH0'],
  "hiam": ['HH AY1 AH0 M'],
  "hiatt": ['HH AY1 AH0 T'],
  "hiatt's": ['HH AY1 AH0 T S'],
  "hiatus": ['HH AY0 EY1 T AH0 S'],
  "hiawatha": ['HH AY2 AH0 W AA1 TH AH0'],
  "hibaaq": ['HH AY1 B AE2 K'],
  "hibachi": ['HH AH0 B AA1 CH IY0', 'HH IY0 B AA1 CH IY0'],
  "hibbard": ['HH IH1 B ER0 D'],
  "hibben": ['HH IH1 B AH0 N'],
  "hibberd": ['HH IH1 B ER0 D'],
  "hibbert": ['HH IH1 B ER0 T'],
  "hibbett": ['HH IH1 B IH0 T'],
  "hibbing": ['HH IH1 B IH0 NG'],
  "hibbitts": ['HH IH1 B IH0 T S'],
  "hibbler": ['HH IH1 B L ER0'],
  "hibbs": ['HH IH1 B Z'],
  "hibdon": ['HH IH1 B D AH0 N'],
  "hibernate": ['HH AY1 B ER0 N EY2 T'],
  "hibernation": ['HH AY2 B ER0 N EY1 SH AH0 N'],
  "hibernia": ['HH AY2 B ER1 N IY0 AH0'],
  "hibernia's": ['HH AY2 B ER1 N IY0 AH0 Z'],
  "hibler": ['HH IH1 B L ER0'],
  "hibma": ['HH IH1 B M AH0'],
  "hibner": ['HH IH1 B N ER0'],
  "hibor": ['HH AY1 B ER0'],
  "hibshman": ['HH IH1 B SH M AH0 N'],
  "hiccough": ['HH IH1 K AH0 P'],
  "hiccoughs": ['HH IH1 K AH0 P S'],
  "hiccup": ['HH IH1 K AH0 P'],
  "hiccups": ['HH IH1 K AH0 P S'],
  "hice": ['HH AY1 S'],
  "hicfa": ['HH IH1 K F AH0'],
  "hichens": ['HH IH1 K AH0 N Z'],
  "hick": ['HH IH1 K'],
  "hickam": ['HH IH1 K AH0 M'],
  "hickcox": ['HH IH1 K AA0 K S'],
  "hickel": ['HH IH1 K AH0 L'],
  "hicken": ['HH IH1 K AH0 N'],
  "hickerson": ['HH IH1 K ER0 S AH0 N'],
  "hickey": ['HH IH1 K IY0'],
  "hickle": ['HH IH1 K AH0 L'],
  "hicklin": ['HH IH1 K L IH0 N'],
  "hickling": ['HH IH1 K L IH0 NG'],
  "hickman": ['HH IH1 K M AH0 N'],
  "hickmon": ['HH IH1 K M AH0 N'],
  "hickok": ['HH IH1 K AH0 K'],
  "hickories": ['HH IH1 K ER0 IY0 Z'],
  "hickory": ['HH IH1 K ER0 IY0', 'HH IH1 K R IY0'],
  "hickox": ['HH IH1 K AA0 K S'],
  "hicks": ['HH IH1 K S'],
  "hickson": ['HH IH1 K S AH0 N'],
  "hicksville": ['HH IH1 K S V IH2 L'],
  "hid": ['HH IH1 D'],
  "hidalgo": ['HH AH0 D AE1 L G OW2'],
  "hidden": ['HH IH1 D AH0 N'],
  "hide": ['HH AY1 D'],
  "hideaki": ['HH IY2 D EY0 AA1 K IY0'],
  "hideaway": ['HH AY1 D AH0 W EY2'],
  "hidebound": ['HH AY1 D B AW2 N D'],
  "hideo": ['HH IH0 D EY1 OW0'],
  "hideous": ['HH IH1 D IY0 AH0 S'],
  "hideously": ['HH IH1 D IY0 AH0 S L IY0'],
  "hideout": ['HH AY1 D AW2 T'],
  "hideouts": ['HH AY1 D AW2 T S'],
  "hider": ['HH AY1 D ER0'],
  "hides": ['HH AY1 D Z'],
  "hiding": ['HH AY1 D IH0 NG'],
  "hidy": ['HH AY1 D IY0'],
  "hieb": ['HH IY1 B'],
  "hieber": ['HH IY1 B ER0'],
  "hiebert": ['HH IY1 B ER0 T'],
  "hiegel": ['HH IY1 G AH0 L'],
  "hiems": ['HH IY1 M Z'],
  "hiemstra": ['HH IY1 M S T R AH0'],
  "hier": ['HH AY1 ER0'],
  "hierarchical": ['HH AY2 R AA1 R K AH0 K AH0 L'],
  "hierarchies": ['HH AY1 R AA2 R K IY0 Z'],
  "hierarchy": ['HH AY1 ER0 AA2 R K IY0', 'HH AY1 R AA2 R K IY0'],
  "hierholzer": ['HH AY1 R HH OW0 L Z ER0'],
  "hieroglyph": ['HH AY2 R OW0 G L IH1 F'],
  "hieroglyphic": ['HH AY2 R OW0 G L IH1 F IH0 K'],
  "hieroglyphics": ['HH AY2 R OW0 G L IH1 F IH0 K S'],
  "hieroglyphs": ['HH AY2 R OW0 G L IH1 F S'],
  "hiers": ['HH IY1 R Z'],
  "hiestand": ['HH IY1 S T AH0 N D'],
  "hiester": ['HH AY1 IH0 S T ER0'],
  "hietala": ['HH AY1 T AH0 L AH0'],
  "hietpas": ['HH AY1 T P AH0 Z'],
  "hiett": ['HH AY1 T'],
  "higa": ['HH IY1 G AH0'],
  "higashi": ['HH IY0 G AA1 SH IY0'],
  "higbee": ['HH IH1 G B IY2'],
  "higbie": ['HH IH1 G B IY0'],
  "higby": ['HH IH1 G B IY0'],
  "higdon": ['HH IH1 G D AH0 N'],
  "higgason": ['HH IH1 G AH0 S AH0 N'],
  "higgenbotham": ['HH IH1 G IH0 N B AH0 TH AH0 M'],
  "higgens": ['HH IH1 G AH0 N Z'],
  "higgerson": ['HH IH1 G ER0 S AH0 N'],
  "higginbotham": ['HH IH0 G IH0 N B AA1 TH AH0 M'],
  "higginbottom": ['HH IH0 G IH0 N B AA1 T AH0 M'],
  "higgins": ['HH IH1 G IH0 N Z'],
  "higginson": ['HH IH1 G IH0 N S AH0 N'],
  "higgs": ['HH IH1 G Z'],
  "high": ['HH AY1'],
  "high-end": ['HH AY2 EH1 N D'],
  "high-level": ['HH AY2 L EH1 V AH0 L'],
  "high-minded": ['HH AY2 M AY1 N D IH0 D'],
  "high-powered": ['HH AY2 P AW1 ER0 D'],
  "high-profile": ['HH AY2 P R OW1 F AY0 L'],
  "high-quality": ['HH AY2 K W AA1 L AH0 T IY0'],
  "high-school": ['HH AY1 S K UW2 L'],
  "high-speed": ['HH AY1 S P IY2 D'],
  "high-spirited": ['HH AY2 S P IH1 R IH0 D IH0 D'],
  "high-spiritedness": ['HH AY2 S P IH1 R IH0 D IH0 D N AH0 S'],
  "high-tech": ['HH AY2 T EH1 K'],
  "higham": ['HH AY1 AH0 M'],
  "highberger": ['HH AY1 B ER2 G ER0'],
  "highboy": ['HH AY1 B OY2'],
  "highbrow": ['HH AY1 B R AW2'],
  "highbrows": ['HH AY1 B R AW2 Z'],
  "higher": ['HH AY1 ER0'],
  "highest": ['HH AY1 AH0 S T'],
  "highfalutin": ['HH AY2 F AH0 L UW1 T IH0 N'],
  "highfield": ['HH AY1 F IY2 L D'],
  "highfill": ['HH AY1 F IH2 L'],
  "highflier": ['HH AY1 F L AY2 ER0'],
  "highfliers": ['HH AY1 F L AY2 ER0 Z'],
  "highflying": ['HH AY1 F L AY2 IH0 NG'],
  "highland": ['HH AY1 L AH0 N D'],
  "highlander": ['HH AY1 L AE2 N D ER0'],
  "highlanders": ['HH AY1 L AE2 N D ER0 Z'],
  "highlands": ['HH AY1 L AH0 N D Z'],
  "highley": ['HH AY1 L IY0'],
  "highlight": ['HH AY1 L AY2 T'],
  "highlighted": ['HH AY1 L AY2 T IH0 D'],
  "highlighting": ['HH AY1 L AY2 T IH0 NG'],
  "highlights": ['HH AY1 L AY2 T S'],
  "highly": ['HH AY1 L IY0'],
  "highman": ['HH AY1 M AH0 N'],
  "highness": ['HH AY1 N AH0 S'],
  "highosin": ['HH AY1 OW0 S IH0 N'],
  "highrise": ['HH AY1 R AY2 Z'],
  "highrises": ['HH AY1 R AY2 Z IH0 Z'],
  "highs": ['HH AY1 Z'],
  "highschool": ['HH AY1 S K UW2 L'],
  "highschools": ['HH AY1 S K UW2 L Z'],
  "highsmith": ['HH AY1 S M IH2 TH'],
  "highspeed": ['HH AY1 S P IY2 D'],
  "hight": ['HH AY1 T'],
  "hightech": ['HH AY1 T EH2 K'],
  "hightower": ['HH AY1 T AW2 ER0'],
  "hightower's": ['HH AY1 T AW2 ER0 Z'],
  "highway": ['HH AY1 W EY2'],
  "highway's": ['HH AY1 W EY2 Z'],
  "highways": ['HH AY1 W EY2 Z'],
  "highyield": ['HH AY1 Y IY1 L D'],
  "higinbotham": ['HH IH1 G IH0 N B AH0 TH AH0 M'],
  "higley": ['HH IH1 G L IY0'],
  "higman": ['HH IH1 G M AH0 N'],
  "hignight": ['HH IH1 G N AY2 T'],
  "hignite": ['HH IH1 G N AY2 T'],
  "higuchi": ['HH IY0 G UW1 CH IY0'],
  "higuera": ['HH IY0 G EH1 R AH0'],
  "hijack": ['HH AY1 JH AE2 K'],
  "hijacked": ['HH AY1 JH AE2 K T'],
  "hijacker": ['HH AY1 JH AE2 K ER0'],
  "hijackers": ['HH AY1 JH AE2 K ER0 Z'],
  "hijacking": ['HH AY1 JH AE2 K IH0 NG'],
  "hijackings": ['HH AY1 JH AE2 K IH0 NG Z'],
  "hijinks": ['HH IH1 JH IH0 NG K S'],
  "hike": ['HH AY1 K'],
  "hiked": ['HH AY1 K T'],
  "hiker": ['HH AY1 K ER0'],
  "hikers": ['HH AY1 K ER0 Z'],
  "hikes": ['HH AY1 K S'],
  "hiking": ['HH AY1 K IH0 NG'],
  "hiland": ['HH IH1 L AH0 N D'],
  "hilaria": ['HH IY0 L AA1 R IY0 AH0'],
  "hilario": ['HH IY0 L AA1 R IY0 OW0'],
  "hilarious": ['HH IH0 L EH1 R IY0 AH0 S'],
  "hilariously": ['HH IH0 L EH1 R IY0 AH0 S L IY0'],
  "hilarity": ['HH IH0 L EH1 R AH0 T IY0'],
  "hilary": ['HH IH1 L ER0 IY0'],
  "hilb": ['HH IH1 L B'],
  "hilberg": ['HH IH1 L B ER0 G'],
  "hilbert": ['HH IH1 L B ER0 T'],
  "hilborn": ['HH IH1 L B ER0 N'],
  "hilbun": ['HH IH1 L B AH0 N'],
  "hilburn": ['HH IH1 L B ER0 N'],
  "hild": ['HH IH1 L D'],
  "hilda": ['HH IH1 L D AH0'],
  "hilde": ['HH IH1 L D'],
  "hildebran": ['HH IH1 L D IH0 B R AH0 N'],
  "hildebrand": ['HH IH1 L D IH0 B R AE2 N D'],
  "hildebrandt": ['HH IH1 L D IH0 B R AH0 N T'],
  "hildebrant": ['HH IH1 L D IH0 B R AH0 N T'],
  "hildegard": ['HH IH1 L D AH0 G AA2 R D'],
  "hildegarde": ['HH IH1 L D IH0 G AA2 R D'],
  "hildegardes": ['HH IH1 L D AH0 G AA2 R D Z'],
  "hildemar": ['HH IH1 L D IH0 M ER0'],
  "hilden": ['HH AY1 L D AH0 N'],
  "hildenbrand": ['HH IH1 L D IH0 N B R AH0 N D', 'HH IH1 L D AH0 N B R AE0 N D'],
  "hilder": ['HH IH1 L D ER0'],
  "hilderbrand": ['HH IH1 L D ER0 B R AH0 N D'],
  "hilderbrandt": ['HH IH1 L D ER0 B R AH0 N T'],
  "hildie": ['HH AY1 L D IY0'],
  "hilditch": ['HH IH1 L D IH0 CH'],
  "hildreth": ['HH IH1 L D R IH0 TH'],
  "hildum": ['HH IH1 L D AH0 M'],
  "hildy": ['HH IH1 L D IY0'],
  "hile": ['HH AY1 L'],
  "hileman": ['HH AY1 L M AH0 N'],
  "hilemon": ['HH AY1 L M AH0 N'],
  "hilemon's": ['HH AY1 L M AH0 N Z'],
  "hiler": ['HH AY1 L ER0'],
  "hiles": ['HH AY1 L Z'],
  "hiley": ['HH IH1 L IY0'],
  "hilfiger": ['HH IH1 L F AY0 G ER0', 'HH IH1 L F IH0 G ER0'],
  "hilfiker": ['HH IH1 L F IH0 K ER0'],
  "hilgart": ['HH IH1 L G AA2 R T'],
  "hilgeman": ['HH IH1 L G M AH0 N'],
  "hilgenberg": ['HH IH1 L G AH0 N B ER0 G'],
  "hilgendorf": ['HH IH1 L G IH0 N D AO0 R F'],
  "hilger": ['HH IH1 L G ER0'],
  "hilgers": ['HH IH1 L G ER0 Z'],
  "hilgert": ['HH IH1 L G ER0 T'],
  "hilinski": ['HH IH0 L IH1 N S K IY0'],
  "hilke": ['HH IH1 L K AH0'],
  "hilker": ['HH IH1 L K ER0'],
  "hill": ['HH IH1 L'],
  "hill's": ['HH IH1 L Z'],
  "hilla": ['HH IH1 L AH0'],
  "hillard": ['HH IH1 L ER0 D'],
  "hillard's": ['HH IH1 L ER0 D Z'],
  "hillary": ['HH IH1 L ER0 IY0'],
  "hillary's": ['HH IH1 L ER0 IY0 Z'],
  "hillas": ['HH IH1 L AH0 S'],
  "hillbillies": ['HH IH1 L B IH2 L IY0 Z'],
  "hillbilly": ['HH IH1 L B IH0 L IY0'],
  "hillcrest": ['HH IH1 L K R EH0 S T'],
  "hille": ['HH IH1 L'],
  "hilleary": ['HH IH1 L ER0 IY0'],
  "hillebrand": ['HH IH1 L IH0 B R AH0 N D'],
  "hillegas": ['HH IH1 L IH0 G AH0 Z'],
  "hillegass": ['HH IH1 L IH0 G AH0 S'],
  "hillel": ['HH IH2 L EH1 L'],
  "hillen": ['HH IH1 L AH0 N'],
  "hillenbrand": ['HH IH1 L AH0 N B R AE2 N D'],
  "hillenbrand's": ['HH IH1 L AH0 N B R AE2 N D Z'],
  "hillenburg": ['HH IH1 L AH0 N B ER0 G'],
  "hiller": ['HH IH1 L ER0'],
  "hillerman": ['HH IH1 L ER0 M AH0 N'],
  "hillery": ['HH IH1 L ER0 IY0'],
  "hillesheim": ['HH IH1 L IH0 S HH AY0 M'],
  "hillestad": ['HH IH1 L IH0 S T AH0 D'],
  "hilley": ['HH IH1 L IY0'],
  "hillhaven": ['HH IH1 L HH EY2 V AH0 N'],
  "hillhaven's": ['HH IH1 L HH EY2 V AH0 N Z'],
  "hillhouse": ['HH IH1 L HH AW2 S'],
  "hillian": ['HH IH1 L Y AH0 N'],
  "hillians": ['HH IH1 L Y AH0 N Z'],
  "hilliard": ['HH IH1 L Y AA0 R D'],
  "hillier": ['HH IH1 L IY0 ER0'],
  "hilligoss": ['HH IH1 L IH0 G AA0 S'],
  "hilliker": ['HH IH1 L AY0 K ER0', 'HH IH1 L IH0 K ER0'],
  "hillin": ['HH IH1 L IH0 N'],
  "hilling": ['HH IH1 L IH0 NG'],
  "hillis": ['HH IH1 L IH0 S'],
  "hillman": ['HH IH1 L M AE2 N'],
  "hillmann": ['HH IH1 L M AH0 N'],
  "hillmer": ['HH IH1 L M ER0'],
  "hillock": ['HH IH1 L AH0 K'],
  "hills": ['HH IH1 L Z'],
  "hills'": ['HH IH1 L Z'],
  "hillsboro": ['HH IH1 L Z B ER0 OW0'],
  "hillsborough": ['HH IH1 L Z B ER0 OW0'],
  "hillsdale": ['HH IH1 L Z D EY2 L'],
  "hillsdown": ['HH IH1 L Z D AW2 N'],
  "hillside": ['HH IH1 L S AY2 D'],
  "hillsides": ['HH IH1 L S AY2 D Z'],
  "hillsman": ['HH IH1 L S M AH0 N'],
  "hillson": ['HH IH1 L S AH0 N'],
  "hillstrom": ['HH IH1 L S T R AH0 M'],
  "hilltop": ['HH IH1 L T AA2 P'],
  "hilltops": ['HH IH1 L T AA2 P S'],
  "hilly": ['HH IH1 L IY0'],
  "hillyard": ['HH IH1 L Y AA2 R D'],
  "hillyer": ['HH IH1 L IY0 ER0'],
  "hilma": ['HH IH1 L M AH0'],
  "hilmer": ['HH IH1 L M ER0'],
  "hilmes": ['HH IH1 L M Z'],
  "hilo": ['HH IY1 L OW0'],
  "hilpert": ['HH IH1 L P ER0 T'],
  "hilsabeck": ['HH IH1 L S AH0 B EH2 K'],
  "hilscher": ['HH IH1 L SH ER0'],
  "hilsinger": ['HH IH1 L S IH0 NG ER0'],
  "hilsman": ['HH IH1 L Z M AH0 N'],
  "hilson": ['HH IH1 L S AH0 N'],
  "hilt": ['HH IH1 L T'],
  "hiltner": ['HH IH1 L T N ER0'],
  "hilton": ['HH IH1 L T AH0 N'],
  "hilton's": ['HH IH1 L T AH0 N Z'],
  "hilton-head": ['HH IH1 L T AH0 N HH EH1 D'],
  "hiltons": ['HH IH1 L T AH0 N Z'],
  "hilts": ['HH IH1 L T S'],
  "hiltunen": ['HH IH1 L T AH0 N AH0 N'],
  "hilty": ['HH IH1 L T IY0'],
  "hiltz": ['HH IH1 L T S'],
  "hilyard": ['HH AH0 L Y AA1 R D'],
  "hilyer": ['HH IH1 L IY0 ER0'],
  "him": ['HH IH1 M', 'IH0 M'],
  "himalaya": ['HH IH2 M AH0 L AY1 AH0', 'HH IH2 M AH0 L EY1 AH0'],
  "himalayan": ['HH IH2 M AH0 L EY1 AH0 N', 'HH IH2 M AH0 L AY1 AH0 N'],
  "himalayas": ['HH IH2 M AH0 L AY1 AH0 S', 'HH IH2 M AH0 L EY1 AH0 S'],
  "hime": ['HH AY1 M'],
  "himebaugh": ['HH IH1 M IH0 B AO0'],
  "himel": ['HH IH1 M AH0 L'],
  "himes": ['HH AY1 M Z'],
  "himmel": ['HH IH1 M AH0 L'],
  "himmelberger": ['HH IH1 M AH0 L B ER0 G ER0'],
  "himmelfarb": ['HH IH1 M AH0 L F AA2 R B'],
  "himmelsbach": ['HH IH1 M IH0 L S B AA0 K'],
  "himmelstein": ['HH IH1 M AH0 L S T AY0 N', 'HH IH1 M AH0 L S T IY0 N'],
  "himmler": ['HH IH1 M L ER0'],
  "himont": ['HH IH1 M AH0 N T', 'HH AY1 M AH0 N T'],
  "himself": ['HH IH0 M S EH1 L F'],
  "hinch": ['HH IH1 N CH'],
  "hinchcliff": ['HH IH1 N CH K L IH0 F'],
  "hinchcliffe": ['HH IH1 N CH K L IH0 F'],
  "hinchey": ['HH IH1 N CH IY0'],
  "hinchliffe": ['HH IH1 N K L IH0 F'],
  "hinchman": ['HH IH1 NG K M AH0 N'],
  "hinck": ['HH IH1 NG K'],
  "hinckley": ['HH IH1 NG K L IY0'],
  "hind": ['HH AY1 N D'],
  "hinde": ['HH IH1 N D'],
  "hindelong": ['HH IH1 N D AH0 L AO0 NG'],
  "hinder": ['HH IH1 N D ER0'],
  "hindered": ['HH IH1 N D ER0 D'],
  "hinderer": ['HH IH1 N D ER0 ER0'],
  "hindering": ['HH IH1 N D ER0 IH0 NG'],
  "hinderliter": ['HH IH1 N D ER0 L IY0 T ER0'],
  "hinderman": ['HH AY1 N D ER0 M AH0 N'],
  "hinders": ['HH IH1 N D ER0 Z'],
  "hindes": ['HH IH1 N D Z'],
  "hindi": ['HH IH1 N D IY0'],
  "hindle": ['HH IH1 N D AH0 L'],
  "hindley": ['HH IH1 N D L IY0'],
  "hindman": ['HH AY1 N D M AH0 N'],
  "hindquarter": ['HH AY1 N D K W AO2 R T ER0', 'HH AY1 N D K AO2 R T ER0'],
  "hindquarters": ['HH AY1 N D K W AO2 R T ER0 Z', 'HH AY1 N D K AO2 R T ER0 Z'],
  "hindrance": ['HH IH1 N D R AH0 N S'],
  "hindrances": ['HH IH1 N D R AH0 N S IH0 Z'],
  "hinds": ['HH AY1 N D Z'],
  "hindsight": ['HH AY1 N D S AY2 T', 'HH AY1 N S AY2 T'],
  "hindsight's": ['HH AY1 N D S AY2 T S', 'HH AY1 N S AY2 T S'],
  "hindu": ['HH IH1 N D UW2'],
  "hinduism": ['HH IH1 N JH UW0 IH2 Z AH0 M'],
  "hindus": ['HH IH1 N D UW2 Z'],
  "hindustan": ['HH IH1 N D UW0 S T AE2 N'],
  "hine": ['HH AY1 N'],
  "hinebaugh": ['HH IH1 N IH0 B AO0'],
  "hineline": ['HH IH1 N IH0 L AY2 N'],
  "hinely": ['HH AY1 N L IY0'],
  "hineman": ['HH AY1 N M AH0 N'],
  "hiner": ['HH AY1 N ER0'],
  "hinerman": ['HH AY1 N ER0 M AH0 N'],
  "hines": ['HH AY1 N Z'],
  "hinesley": ['HH IH1 N IH0 S L IY0', 'HH AY1 N Z L IY0'],
  "hiney": ['HH IH1 N IY0'],
  "hing": ['HH IH1 NG'],
  "hinge": ['HH IH1 N JH'],
  "hinged": ['HH IH1 N JH D'],
  "hinger": ['HH IH1 N JH ER0'],
  "hinges": ['HH IH1 N JH IH0 Z'],
  "hingham": ['HH IH1 NG AH0 M'],
  "hingis": ['HH IH1 NG AH0 S'],
  "hingle": ['HH IH1 NG G AH0 L'],
  "hingst": ['HH IH1 NG S T'],
  "hingston": ['HH IH1 NG S T AH0 N'],
  "hink": ['HH IH1 NG K'],
  "hinkel": ['HH IH1 NG K AH0 L'],
  "hinkelman": ['HH IH1 NG K AH0 L M AH0 N'],
  "hinkle": ['HH IH1 NG K AH0 L'],
  "hinkley": ['HH IH1 NG K L IY0'],
  "hinkson": ['HH IH1 NG K S AH0 N'],
  "hinman": ['HH IH1 N M AH0 N'],
  "hinmen": ['HH IH1 N M EH0 N'],
  "hinn": ['HH IH1 N'],
  "hinnant": ['HH IH1 N AH0 N T'],
  "hinnenkamp": ['HH IH1 N IH0 N K AE0 M P'],
  "hinners": ['HH IH1 N ER0 Z'],
  "hinny": ['HH IH1 N IY0'],
  "hino": ['HH IY1 N OW0'],
  "hinojos": ['HH IY0 N OW1 Y OW0 Z'],
  "hinojosa": ['HH IY0 N OW0 JH OW1 S AH0'],
  "hinote": ['HH IH0 N OW1 T'],
  "hinrichs": ['HH IH1 N R IH0 K S'],
  "hinrichsen": ['HH IH1 N R IH0 K S AH0 N'],
  "hinsch": ['HH IH1 N SH'],
  "hinsdale": ['HH IH1 N S D EY2 L'],
  "hinshaw": ['HH IH1 N SH AO2'],
  "hinsley": ['HH IH1 N S L IY0'],
  "hinson": ['HH IH1 N S AH0 N'],
  "hint": ['HH IH1 N T'],
  "hinted": ['HH IH1 N T AH0 D', 'HH IH1 N T IH0 D', 'HH IH1 N IH0 D'],
  "hinterland": ['HH IH1 N T ER0 L AE2 N D'],
  "hinterlands": ['HH IH1 N T ER0 L AE2 N D Z'],
  "hinting": ['HH IH1 N T IH0 NG'],
  "hinton": ['HH IH1 N T AH0 N'],
  "hints": ['HH IH1 N T S'],
  "hintz": ['HH IH1 N T S'],
  "hintze": ['HH IH1 N T Z'],
  "hinz": ['HH IH1 N Z'],
  "hinzack": ['HH IH1 N Z AE0 K'],
  "hinze": ['HH IH1 N Z'],
  "hinzman": ['HH IH1 N Z M AH0 N'],
  "hiott": ['HH AY1 AH0 T'],
  "hip": ['HH IH1 P'],
  "hip-hop": ['HH IH1 P HH AO2 P'],
  "hip-pocket": ['HH IH1 P P AA1 K AH0 T'],
  "hipbone": ['HH IH1 P B OW1 N'],
  "hipbones": ['HH IH1 P B OW1 N Z'],
  "hipkins": ['HH IH1 P K IH0 N Z'],
  "hipolito": ['IY1 P OW0 L IY1 T OW0'],
  "hipp": ['HH IH1 P'],
  "hippe": ['HH IH1 P'],
  "hippen": ['HH IH1 P AH0 N'],
  "hippensteel": ['HH IH1 P IH0 N S T IY0 L'],
  "hipper": ['HH IH1 P ER0'],
  "hippert": ['HH IH1 P ER0 T'],
  "hippest": ['HH IH1 P AH0 S T'],
  "hippie": ['HH IH1 P IY0'],
  "hippies": ['HH IH1 P IY0 Z'],
  "hipple": ['HH IH1 P AH0 L'],
  "hippler": ['HH IH1 P L ER0'],
  "hippo": ['HH IH1 P OW0'],
  "hippocampus": ['HH IH2 P OW0 K AE1 M P AH0 S'],
  "hippocrates": ['HH IH1 P AH0 K R EY2 T S', 'HH IH0 P AO1 K R AH0 T IY0 Z'],
  "hippocratic": ['HH IH0 P AH0 K R AE1 T IH0 K'],
  "hippodrome": ['HH IH1 P AH0 D R OW2 M'],
  "hippolytus": ['HH AH0 P AA1 L AH0 T AH0 S'],
  "hippopotamus": ['HH IH2 P AH0 P AA1 T AH0 M AH0 S'],
  "hippopotamuses": ['HH IH2 P AH0 P AA1 T AH0 M AH0 S IH0 Z'],
  "hippos": ['HH IH1 P OW0 Z'],
  "hipps": ['HH IH1 P S'],
  "hips": ['HH IH1 P S'],
  "hipsher": ['HH IH1 P SH ER0'],
  "hipskind": ['HH IH1 P S K IH0 N D'],
  "hipster": ['HH IH1 P S T ER0'],
  "hipsters": ['HH IH1 P S T ER0 Z'],
  "hipwell": ['HH IH1 P W EH2 L'],
  "hirabayashi": ['HH IH0 R AH0 B AY0 AA1 SH IY0'],
  "hiradin": ['HH IH0 R AA1 D IH0 N'],
  "hirai": ['HH IH0 R AA1 IY0'],
  "hiram": ['HH AY1 R AH0 M'],
  "hirano": ['HH IH0 R AA1 N OW0'],
  "hirata": ['HH IH0 R AA1 T AH0'],
  "hirayama": ['HH IH0 R AA0 Y AA1 M AH0'],
  "hird": ['HH ER1 D'],
  "hire": ['HH AY1 ER0', 'HH AY1 R'],
  "hired": ['HH AY1 ER0 D'],
  "hires": ['HH AY1 ER0 Z', 'HH AY1 R Z'],
  "hiriart": ['HH IH1 R IY0 AA0 R T'],
  "hiring": ['HH AY1 R IH0 NG'],
  "hirings": ['HH AY1 R IH0 NG Z'],
  "hirn": ['HH ER1 N'],
  "hiro": ['HH IH1 R OW0'],
  "hiroaki": ['HH IH2 R OW0 AA1 K IY0'],
  "hirohito": ['HH IH2 R OW0 HH IY1 T OW2', 'HH IH2 R AH0 HH IY1 T OW2'],
  "hirohito's": ['HH IH2 R OW0 HH IY1 T OW2 Z', 'HH IH2 R AH0 HH IY1 T OW2 Z'],
  "hiromasa": ['HH IH2 R OW0 M AA1 S AH0'],
  "hirons": ['HH AY1 R AH0 N Z'],
  "hirosakamoki": ['HH IH2 R AH0 S AE0 K AH0 M OW1 K IY0'],
  "hirosakima": ['HH IH2 R AH0 S AH0 K IY1 M AH0'],
  "hirose": ['HH IH0 R OW1 S EY0'],
  "hiroshi": ['HH IH0 R OW1 SH IY0'],
  "hiroshima": ['HH IH2 R OW0 SH IY1 M AH0', 'HH IH2 R OW1 SH IH0 M AH0'],
  "hirota": ['HH IH0 R OW1 T AH0'],
  "hiroyuki": ['HH IH2 R OW0 Y UW1 K IY0'],
  "hirsch": ['HH ER1 SH'],
  "hirsch's": ['HH ER1 SH IH0 Z'],
  "hirschberg": ['HH ER1 SH B ER0 G'],
  "hirschfeld": ['HH ER1 SH F EH0 L D'],
  "hirschfield": ['HH ER1 S K F IY0 L D'],
  "hirschhorn": ['HH ER1 SH HH ER0 N'],
  "hirschi": ['HH IH1 R S K IY0'],
  "hirschman": ['HH ER1 SH M AH0 N'],
  "hirschmann": ['HH ER1 SH M AH0 N'],
  "hirschy": ['HH ER1 SH IY0'],
  "hirsh": ['HH ER1 SH'],
  "hirshberg": ['HH ER1 SH B ER0 G'],
  "hirshfield": ['HH ER1 SH F IY0 L D'],
  "hirshhorn": ['HH ER1 SH HH AO2 R N', 'HH ER1 SH AO2 R N'],
  "hirshman": ['HH ER1 SH M AH0 N'],
  "hirst": ['HH ER1 S T'],
  "hirst's": ['HH ER1 S T S'],
  "hirsute": ['HH ER0 S UW1 T'],
  "hirt": ['HH ER1 T'],
  "hirth": ['HH ER1 TH'],
  "hirtle": ['HH ER1 T AH0 L'],
  "hirtz": ['HH ER1 T S'],
  "hirulog": ['HH IH1 R UW2 L AA2 G'],
  "hirzel": ['HH ER1 Z AH0 L'],
  "his": ['HH IH1 Z', 'HH IH0 Z'],
  "hisada": ['HH IH0 S AA1 D AH0'],
  "hisao": ['HH IH0 S AA1 OW0'],
  "hisaw": ['HH AY1 S AO0'],
  "hiscock": ['HH IH1 S K AH0 K'],
  "hiscox": ['HH IH1 S K AA0 K S'],
  "hise": ['HH AY1 Z'],
  "hisel": ['HH IH1 S AH0 L'],
  "hiser": ['HH AY1 Z ER0'],
  "hisey": ['HH IH1 S IY0'],
  "hisham": ['HH IH1 SH AH0 M'],
  "hisle": ['HH AY1 AH0 L'],
  "hislop": ['HH IH1 S L AH0 P'],
  "hispanic": ['HH IH0 S P AE1 N IH0 K'],
  "hispanics": ['HH IH0 S P AE1 N IH0 K S'],
  "hispaniola": ['HH IH0 S P AA2 NG OW1 L AH0'],
  "hispano": ['HH IH0 S P AA1 N OW0'],
  "hispanoil": ['HH IH1 S P AH0 N OY2 L'],
  "hiss": ['HH IH1 S'],
  "hiss's": ['HH IH1 S IH0 Z'],
  "hissed": ['HH IH1 S T'],
  "hisself": ['HH IH2 S EH1 L F'],
  "hisses": ['HH IH1 S IH0 Z'],
  "hissing": ['HH IH1 S IH0 NG'],
  "hissong": ['HH IH1 S AO2 NG'],
  "histadrut": ['HH IH1 S T AH0 D R AH0 T'],
  "histamine": ['HH IH1 S T AH0 M IY2 N'],
  "histidine": ['HH IH1 S T AH0 D IY2 N'],
  "histogram": ['HH IH1 S T AH0 G R AE2 M'],
  "histograms": ['HH IH1 S T AH0 G R AE2 M Z'],
  "histology": ['HH IH0 S T AA1 L AH0 JH IY0'],
  "historian": ['HH IH0 S T AO1 R IY0 AH0 N'],
  "historians": ['HH IH0 S T AO1 R IY0 AH0 N Z'],
  "historic": ['HH IH0 S T AO1 R IH0 K'],
  "historical": ['HH IH0 S T AO1 R IH0 K AH0 L'],
  "historically": ['HH IH0 S T AO1 R IH0 K AH0 L IY0', 'HH IH0 S T AO1 R IH0 K L IY0'],
  "histories": ['HH IH1 S T ER0 IY0 Z', 'HH IH1 S T R IY0 Z'],
  "historiography": ['HH IH0 S T AO2 R IY0 AA1 G R AH0 F IY0'],
  "history": ['HH IH1 S T ER0 IY0', 'HH IH1 S T R IY0'],
  "history's": ['HH IH1 S T ER0 IY0 Z', 'HH IH1 S T R IY0 Z'],
  "histrionic": ['HH IH2 S T R IY0 AA1 N IH0 K'],
  "histrionics": ['HH IH2 S T R IY0 AA1 N IH0 K S'],
  "hit": ['HH IH1 T'],
  "hit-and-run": ['HH IH1 T AE0 N R AH2 N'],
  "hitachi": ['HH IH0 T AA1 CH IY0'],
  "hitch": ['HH IH1 CH'],
  "hitchcock": ['HH IH1 CH K AA2 K'],
  "hitchcock's": ['HH IH1 CH K AA2 K S'],
  "hitched": ['HH IH1 CH T'],
  "hitchens": ['HH IH1 CH AH0 N Z'],
  "hitches": ['HH IH1 CH IH0 Z'],
  "hitchhike": ['HH IH1 CH HH AY2 K'],
  "hitchhiked": ['HH IH1 CH HH AY2 K T'],
  "hitchhiker": ['HH IH1 CH HH AY2 K ER0'],
  "hitchhikers": ['HH IH1 CH HH AY2 K ER0 Z'],
  "hitchhikes": ['HH IH1 CH HH AY2 K S'],
  "hitchhiking": ['HH IH1 CH HH AY2 K IH0 NG'],
  "hitching": ['HH IH1 CH IH0 NG'],
  "hitchings": ['HH IH1 CH IH0 NG Z'],
  "hitchins": ['HH IH1 CH IH0 N Z'],
  "hitchman": ['HH IH1 CH M AH0 N'],
  "hitchner": ['HH IH1 CH N ER0'],
  "hite": ['HH AY1 T'],
  "hites": ['HH AY1 T S'],
  "hither": ['HH IH1 DH ER0'],
  "hitherto": ['HH IH1 DH ER2 T UW1'],
  "hitler": ['HH IH1 T L ER0'],
  "hitler's": ['HH IH1 T L ER0 Z'],
  "hitman": ['HH IH1 T M AE2 N'],
  "hitoshi": ['HH IH0 T OW1 SH IY0'],
  "hits": ['HH IH1 T S'],
  "hitschler": ['HH IH1 T S L ER0', 'HH IH1 CH L ER0'],
  "hitsman": ['HH IH1 T S M AH0 N'],
  "hitson": ['HH IH1 T S AH0 N'],
  "hitt": ['HH IH1 T'],
  "hitter": ['HH IH1 T ER0'],
  "hitter's": ['HH IH1 T ER0 Z'],
  "hitters": ['HH IH1 T ER0 Z'],
  "hitting": ['HH IH1 T IH0 NG'],
  "hittite": ['HH IH1 T AY2 T'],
  "hittle": ['HH IH1 T AH0 L'],
  "hittner": ['HH IH1 T N ER0'],
  "hitty": ['HH IH1 T IY0'],
  "hitz": ['HH IH1 T S'],
  "hitzeman": ['HH IH1 T S M AH0 N'],
  "hiv": ['EY1 CH AY1 V IY1'],
  "hive": ['HH AY1 V'],
  "hively": ['HH AY1 V L IY0'],
  "hives": ['HH AY1 V Z'],
  "hiway": ['HH AY1 W EY2'],
  "hix": ['HH IH1 K S'],
  "hixenbaugh": ['HH IH0 G Z EH1 N B AO0'],
  "hixon": ['HH IH1 K S AH0 N'],
  "hixson": ['HH IH1 K S AH0 N'],
  "hizbollah": ['HH IH0 Z B OW1 L AH0'],
  "hizer": ['HH AY1 Z ER0'],
  "hjelm": ['HH Y EH1 L M'],
  "hjerpe": ['HH Y ER1 P'],
  "hjort": ['HH AH0 Y AO1 R T'],
  "hlad": ['HH L AE1 D'],
  "hladik": ['HH L AE1 D IH0 K'],
  "hladky": ['HH L AE1 D K IY0'],
  "hlavac": ['HH L AA1 V AH0 K'],
  "hlavacek": ['HH L AA1 V AH0 CH EH0 K'],
  "hlavaty": ['HH L AH0 V AA1 T IY0'],
  "hm": ['HH M'],
  "hmm": ['HH M'],
  "hmmm": ['HH M'],
  "hmong": ['M AO1 NG', 'HH M AO1 NG', 'HH AH0 M AO1 NG'],
  "hnat": ['HH N AE1 T'],
  "ho": ['HH OW1'],
  "ho's": ['HH OW1 Z'],
  "hoadley": ['HH OW1 D L IY0'],
  "hoag": ['HH OW1 G'],
  "hoagie": ['HH OW1 G IY0'],
  "hoagland": ['HH OW1 G L AH0 N D'],
  "hoaglin": ['HH OW1 G L IH0 N'],
  "hoaglund": ['HH OW1 G L AH0 N D'],
  "hoagy": ['HH OW1 G IY0'],
  "hoak": ['HH OW1 K'],
  "hoang": ['HH OW1 NG'],
  "hoar": ['HH AO1 R'],
  "hoard": ['HH AO1 R D'],
  "hoarded": ['HH AO1 R D IH0 D'],
  "hoarding": ['HH AO1 R D IH0 NG'],
  "hoards": ['HH AO1 R D Z'],
  "hoare": ['HH AO1 R'],
  "hoarse": ['HH AO1 R S'],
  "hoarseness": ['HH AO1 R S N AH0 S'],
  "hoary": ['HH AO1 R IY0'],
  "hoax": ['HH OW1 K S'],
  "hoaxes": ['HH OW1 K S IH0 Z'],
  "hob": ['HH AA1 B'],
  "hoback": ['HH OW1 B AE2 K'],
  "hoban": ['HH OW1 B AH0 N'],
  "hobart": ['HH OW1 B AA2 R T'],
  "hobaugh": ['HH AA1 B AO0'],
  "hobbes": ['HH AA1 B IY0 Z', 'HH AA1 B Z'],
  "hobbie": ['HH AA1 B IY0'],
  "hobbies": ['HH AA1 B IY0 Z'],
  "hobbing": ['HH AA1 B IH0 NG'],
  "hobbins": ['HH AA1 B IH0 N Z'],
  "hobbit": ['HH AA1 B IH0 T'],
  "hobbits": ['HH AO1 B IH0 T S'],
  "hobble": ['HH AA1 B AH0 L'],
  "hobbled": ['HH AA1 B AH0 L D'],
  "hobbles": ['HH AA1 B AH0 L Z'],
  "hobbling": ['HH AA1 B AH0 L IH0 NG', 'HH AA1 B L IH0 NG'],
  "hobbs": ['HH AA1 B Z'],
  "hobby": ['HH AA1 B IY0'],
  "hobbyist": ['HH AA1 B IY0 IH0 S T'],
  "hobbyists": ['HH AA1 B IY0 IH0 S T S'],
  "hobday": ['HH AA1 B D EY2'],
  "hobdy": ['HH AA1 B D IY0'],
  "hoben": ['HH AA1 B AH0 N'],
  "hoberg": ['HH OW1 B ER0 G'],
  "hoberman": ['HH OW1 B ER0 M AH0 N'],
  "hobert": ['HH AA1 B ER0 T'],
  "hobgood": ['HH AA1 B G UH2 D'],
  "hobin": ['HH OW1 B IH0 N'],
  "hoblit": ['HH AA1 B L IH0 T'],
  "hobnail": ['HH AA1 B N EY2 L'],
  "hobnob": ['HH AA1 B N AA2 B'],
  "hobnobbing": ['HH AA1 B N AA2 B IH0 NG'],
  "hobo": ['HH OW1 B OW0'],
  "hoboes": ['HH OW1 B OW0 Z'],
  "hoboken": ['HH OW1 B OW0 K AH0 N'],
  "hobs": ['HH AA1 B Z'],
  "hobsbawm": ['HH AA0 B S B AO1 M'],
  "hobson": ['HH AA1 B S AH0 N'],
  "hobson's": ['HH AA1 B S AH0 N Z'],
  "hoc": ['HH AA1 K'],
  "hocevar": ['HH OW0 S EY0 V AA1 R'],
  "hoch": ['HH AA1 K'],
  "hochberg": ['HH AA1 K B ER0 G'],
  "hochbrueckner": ['HH AA1 K B R AH2 K N ER0'],
  "hochenheim": ['HH AO1 K AH0 N HH AY2 M'],
  "hochhalter": ['HH AA1 K HH AH0 L T ER0'],
  "hochhauser": ['HH AA1 K HH AW2 Z ER0'],
  "hochman": ['HH AA1 K M AH0 N'],
  "hochmuth": ['HH AA1 K M UW2 TH'],
  "hochstatter": ['HH AA1 K S T AH0 T ER0'],
  "hochstedler": ['HH AA1 K S T IH0 D AH0 L ER0', 'HH AA1 K S T EH0 D L ER0'],
  "hochstein": ['HH AA1 K S T AY0 N', 'HH AA1 K S T IY0 N'],
  "hochstetler": ['HH AA1 K S T IH0 T AH0 L ER0', 'HH AA1 K S T EH0 T L ER0'],
  "hochtief": ['HH AA1 K T IY2 F'],
  "hock": ['HH AA1 K'],
  "hockaday": ['HH AA1 K AH0 D EY2'],
  "hockberg": ['HH AA1 K B ER0 G'],
  "hockenberry": ['HH AA1 K AH0 N B EH2 R IY0'],
  "hockenbury": ['HH AA1 K AH0 N B EH2 R IY0'],
  "hockensmith": ['HH AA1 K AH0 N S M IH2 TH'],
  "hocker": ['HH AA1 K ER0'],
  "hockersmith": ['HH AA1 K ER0 S M IH2 TH'],
  "hockett": ['HH AA1 K IH0 T'],
  "hockey": ['HH AA1 K IY0'],
  "hockey's": ['HH AA1 K IY0 Z'],
  "hockin": ['HH AA1 K IH0 N'],
  "hocking": ['HH AA1 K IH0 NG'],
  "hockley": ['HH AA1 K L IY0'],
  "hockman": ['HH AA1 K M AH0 N'],
  "hockney": ['HH AA1 K N IY0'],
  "hockney's": ['HH AA1 K N IY0 Z'],
  "hoctor": ['HH AA1 K T ER0'],
  "hocus": ['HH OW1 K AH0 S'],
  "hocutt": ['HH AA1 K AH0 T'],
  "hodak": ['HH OW1 D AH0 K'],
  "hodapp": ['HH AA1 D AH0 P'],
  "hodde": ['HH AA1 D'],
  "hodder": ['HH AA1 D ER0'],
  "hodding": ['HH AA1 D IH0 NG'],
  "hoddur": ['HH AA1 D ER0'],
  "hodel": ['HH OW1 D AH0 L'],
  "hodes": ['HH OW1 D Z'],
  "hodgdon": ['HH AA1 JH D AH0 N'],
  "hodge": ['HH AA1 JH'],
  "hodge's": ['HH AA1 JH IH0 Z'],
  "hodgen": ['HH AA1 JH AH0 N'],
  "hodgens": ['HH AA1 JH AH0 N Z'],
  "hodgepodge": ['HH AA1 JH P AA2 JH'],
  "hodges": ['HH AA1 JH IH0 Z'],
  "hodgin": ['HH AA1 JH IH0 N'],
  "hodgins": ['HH AA1 JH IH0 N Z'],
  "hodgkin": ['HH AA1 JH K IH0 N'],
  "hodgkin's": ['HH AA1 JH K IH0 N Z'],
  "hodgkins": ['HH AA1 JH K IH0 N Z'],
  "hodgkinson": ['HH AA1 JH K IH0 N S AH0 N'],
  "hodgkiss": ['HH AA1 JH K IH0 S'],
  "hodgman": ['HH AA1 JH M AH0 N'],
  "hodgman's": ['HH AA1 JH M AH0 N Z'],
  "hodgson": ['HH AA1 JH S AH0 N'],
  "hodkinson": ['HH AA1 D K IH0 N S AH0 N'],
  "hodnett": ['HH AA1 D N IH0 T'],
  "hodo": ['HH OW1 D OW0'],
  "hodsdon": ['HH AA1 D Z D AH0 N'],
  "hodson": ['HH AA1 D S AH0 N'],
  "hoe": ['HH OW1'],
  "hoechst": ['HH OW1 K S T', 'HH OW1 SH T'],
  "hoechst's": ['HH OW1 K S T S', 'HH OW1 SH T S'],
  "hoeck": ['HH OW1 K'],
  "hoecker": ['HH OW1 K ER0'],
  "hoedown": ['HH OW1 D AW2 N'],
  "hoefer": ['HH OW1 F ER0'],
  "hoeffner": ['HH OW1 F N ER0'],
  "hoefle": ['HH OW1 F AH0 L'],
  "hoefler": ['HH OW1 F AH0 L ER0', 'HH OW1 F L ER0'],
  "hoeflich": ['HH OW1 F L IH0 K'],
  "hoefling": ['HH OW1 F AH0 L IH0 NG', 'HH OW1 F L IH0 NG'],
  "hoefs": ['HH OW1 F S'],
  "hoeft": ['HH OW1 F T'],
  "hoeg": ['HH OW1 G'],
  "hoeger": ['HH OW1 G ER0'],
  "hoehn": ['HH OW1 N'],
  "hoehne": ['HH OW1 N'],
  "hoeing": ['HH OW1 IH0 NG'],
  "hoek": ['HH OW1 K'],
  "hoeksema": ['HH OW1 K S IH0 M AH0'],
  "hoekstra": ['HH OW1 K S T R AH0'],
  "hoel": ['HH OW1 L'],
  "hoell": ['HH OW1 L'],
  "hoelle": ['HH OW1 L'],
  "hoelscher": ['HH OW1 L SH ER0'],
  "hoelter": ['HH OW1 L T ER0'],
  "hoelterhoff": ['HH OW1 L T ER0 HH AO2 F'],
  "hoelterhoff's": ['HH OW1 L T ER0 HH AO2 F S'],
  "hoelting": ['HH OW1 L T IH0 NG'],
  "hoelzel": ['HH OW1 L Z AH0 L'],
  "hoelzer": ['HH OW1 L Z ER0'],
  "hoen": ['HH OW1 N'],
  "hoene": ['HH AA1 IY0 N'],
  "hoener": ['HH OW1 N ER0'],
  "hoenig": ['HH OW1 N IH0 G'],
  "hoeper": ['HH OW1 P ER0'],
  "hoepfner": ['HH OW1 P F N ER0', 'HH OW1 P N ER0'],
  "hoepner": ['HH OW1 P N ER0'],
  "hoeppner": ['HH OW1 P N ER0'],
  "hoerig": ['HH AO1 R IH0 G'],
  "hoerner": ['HH AO1 R N ER0'],
  "hoerr": ['HH AO1 R'],
  "hoes": ['HH OW1 Z'],
  "hoesch": ['HH OW1 SH'],
  "hoeschen": ['HH OW1 SH AH0 N'],
  "hoesly": ['HH OW1 S L IY0'],
  "hoeveler": ['HH OW1 V L ER0'],
  "hoevet": ['HH OW1 V EH2 T'],
  "hoevet's": ['HH OW1 V EH2 T S'],
  "hoey": ['HH OW1 IY0'],
  "hof": ['HH AA1 F'],
  "hofacker": ['HH AA1 F AH0 K ER0'],
  "hofbauer": ['HH AA1 F B AW0 ER0'],
  "hofer": ['HH OW1 F ER0'],
  "hoff": ['HH AO1 F'],
  "hoffa": ['HH AO1 F AH0'],
  "hoffa's": ['HH AO1 F AH0 Z'],
  "hoffacker": ['HH AO1 F AH0 K ER0'],
  "hoffart": ['HH AO1 F AA0 R T'],
  "hoffarth": ['HH AO1 F AA0 R TH'],
  "hoffecker": ['HH AO1 F IH0 K ER0'],
  "hoffenberg": ['HH AO1 F AH0 N B ER0 G'],
  "hoffenberg's": ['HH AA1 F AH0 N B ER0 G Z'],
  "hoffer": ['HH AA1 F ER0'],
  "hofferber": ['HH AO1 F ER0 B ER0'],
  "hoffert": ['HH AO1 F ER0 T'],
  "hoffitz": ['HH AA1 F IH0 T S'],
  "hoffler": ['HH AO1 F AH0 L ER0', 'HH AO1 F L ER0'],
  "hoffman": ['HH AO1 F M AH0 N'],
  "hoffman's": ['HH AO1 F M AH0 N Z'],
  "hoffmann": ['HH AO1 F M AH0 N'],
  "hoffmaster": ['HH AO1 F M AE2 S T ER0'],
  "hoffmeier": ['HH AO1 F M AY0 ER0'],
  "hoffmeister": ['HH AO1 F M AY2 S T ER0'],
  "hoffmeyer": ['HH AO1 F M AY0 ER0'],
  "hoffner": ['HH AO1 F N ER0'],
  "hoffpauir": ['HH AO1 F P AW0 ER0'],
  "hofi": ['HH OW1 F IY0'],
  "hofland": ['HH AA1 F L AH0 N D'],
  "hofler": ['HH AA1 F L ER0'],
  "hofman": ['HH AA1 F M AH0 N'],
  "hofmann": ['HH AA1 F M AH0 N'],
  "hofmeister": ['HH AA1 F M AY0 S T ER0'],
  "hofrichter": ['HH AA1 F R IH0 K T ER0'],
  "hofstad": ['HH AA1 F S T AE0 D'],
  "hofstetter": ['HH AA1 F S T EH0 T ER0'],
  "hofstra": ['HH AA1 F S T R AH0'],
  "hog": ['HH AA1 G'],
  "hogan": ['HH OW1 G AA2 N', 'HH OW1 G AH0 N'],
  "hogan's": ['HH OW1 G AA2 N Z'],
  "hogans": ['HH OW1 G AA2 N Z', 'HH OW1 G AH0 N Z'],
  "hoganson": ['HH AA1 G AH0 N S AH0 N'],
  "hogarth": ['HH OW1 G AA2 R TH'],
  "hogarty": ['HH AA1 G AA2 R T IY0'],
  "hogberg": ['HH AA1 G B ER0 G'],
  "hoge": ['HH OW1 JH'],
  "hogeland": ['HH AA1 G IH0 L AH0 N D', 'HH OW1 G L AH0 N D'],
  "hogen": ['HH AA1 G AH0 N'],
  "hogenson": ['HH AA1 JH IH0 N S AH0 N'],
  "hoger": ['HH OW1 G ER0'],
  "hogg": ['HH AA1 G'],
  "hoggan": ['HH AA1 G AH0 N'],
  "hoggard": ['HH AA1 G ER0 D'],
  "hoggart": ['HH AA1 G ER0 T'],
  "hoggatt": ['HH AA1 G AH0 T'],
  "hogge": ['HH AA1 G'],
  "hogging": ['HH AO1 G IH0 NG'],
  "hogland": ['HH AA1 G L AH0 N D'],
  "hogle": ['HH OW1 G AH0 L'],
  "hoglund": ['HH AO1 G L AH0 N D'],
  "hoglund's": ['HH AO1 G L AH0 N D Z'],
  "hoglunds": ['HH AO1 G L AH0 N D Z'],
  "hognose": ['HH AA1 G N OW2 Z'],
  "hogrefe": ['HH AA1 G R IH0 F'],
  "hogs": ['HH AA1 G Z'],
  "hogsed": ['HH OW1 G S T'],
  "hogsett": ['HH AA1 G S IH0 T'],
  "hogston": ['HH AA1 G S T AH0 N'],
  "hogue": ['HH OW1 G'],
  "hogwarts": ['HH AA1 G W AO2 R T S'],
  "hogwash": ['HH AA1 G W AA2 SH'],
  "hogwood": ['HH AO1 G W UH2 D'],
  "hoh": ['HH OW1'],
  "hoheisel": ['HH OW1 AY0 S AH0 L'],
  "hohenberger": ['HH OW1 AH0 N B ER0 G ER0'],
  "hohensee": ['HH AA0 HH IH0 N S IY1'],
  "hohenstein": ['HH OW1 AH0 N S T AY0 N', 'HH OW1 AH0 N S T IY0 N'],
  "hohl": ['HH OW1 L'],
  "hohler": ['HH OW1 L ER0'],
  "hohlt": ['HH OW1 L T'],
  "hohman": ['HH OW1 M AH0 N'],
  "hohmann": ['HH OW1 M AH0 N'],
  "hohn": ['HH AA1 N'],
  "hohner": ['HH OW1 N ER0'],
  "hohnstein": ['HH OW1 N S T AY0 N', 'HH OW1 N S T IY0 N'],
  "hoho": ['HH OW1 HH OW1'],
  "hohorst": ['HH OW1 HH AO2 R S T'],
  "hohos": ['HH OW1 HH OW1 Z'],
  "hohum": ['HH OW1 HH AH1 M'],
  "hoi": ['HH OY1'],
  "hoiland": ['HH OY0 L AE1 N D'],
  "hoilman": ['HH OY1 L M AH0 N'],
  "hoisington": ['HH OY1 Z IH0 NG T AH0 N'],
  "hoist": ['HH OY1 S T'],
  "hoisted": ['HH OY1 S T AH0 D', 'HH OY1 S T IH0 D'],
  "hoisting": ['HH OY1 S T IH0 NG'],
  "hoists": ['HH OY1 S T S'],
  "hoit": ['HH OY1 T'],
  "hoity": ['HH OY1 T IY0'],
  "hoium": ['HH AA1 IY0 AH0 M'],
  "hojnacki": ['HH AH0 Y N AA1 T S K IY0'],
  "hok": ['HH AO1 K'],
  "hokanson": ['HH AA1 K AH0 N S AH0 N'],
  "hoke": ['HH OW1 K'],
  "hokenson": ['HH AA1 K IH0 N S AH0 N'],
  "hokey": ['HH OW1 K IY0'],
  "hokkaido": ['HH OW0 K AY1 D OW0'],
  "hokum": ['HH OW1 K AH0 M'],
  "hokuriku": ['HH AA2 K ER0 IY1 K UW2'],
  "hola": ['HH OW1 L AH0'],
  "holaday": ['HH OW1 L AH0 D EY0'],
  "holahan": ['HH AA1 L AH0 HH AE0 N'],
  "holan": ['HH OW1 L AH0 N'],
  "holaway": ['HH OW1 L AH0 W EY0'],
  "holbein": ['HH OW1 L B AY0 N'],
  "holben": ['HH OW1 L B AH0 N'],
  "holberg": ['HH OW1 L B ER0 G'],
  "holbert": ['HH OW1 L B ER0 T'],
  "holbrook": ['HH OW1 L B R UH2 K'],
  "holbrooke": ['HH OW1 L B R UH0 K'],
  "holbrooke's": ['HH OW1 L B R UH0 K S'],
  "holbrooks": ['HH OW1 L B R UH0 K S'],
  "holck": ['HH OW1 L K'],
  "holcomb": ['HH OW1 L K AH0 M'],
  "holcombe": ['HH OW1 L K AH0 M'],
  "hold": ['HH OW1 L D'],
  "holda": ['HH OW1 L D AH0'],
  "holdaway": ['HH OW1 L D AH0 W EY2'],
  "holde": ['HH OW1 L D'],
  "holdeman": ['HH OW1 L D M AH0 N'],
  "holden": ['HH OW1 L D AH0 N'],
  "holden's": ['HH OW1 L D AH0 N Z'],
  "holder": ['HH OW1 L D ER0'],
  "holder's": ['HH OW1 L D ER0 Z'],
  "holderbank": ['HH OW1 L D ER0 B AE2 NG K'],
  "holderbaum": ['HH OW1 L D ER0 B AW0 M'],
  "holderby": ['HH OW1 L D ER0 B IY0'],
  "holderfield": ['HH OW1 L D ER0 F IY2 L D'],
  "holderman": ['HH OW1 L D ER0 M AH0 N'],
  "holderness": ['HH OW1 L D ER0 N AH0 S'],
  "holders": ['HH OW1 L D ER0 Z'],
  "holders'": ['HH OW1 L D ER0 Z'],
  "holdfast": ['HH OW1 L F AE2 S T'],
  "holding": ['HH OW1 L D IH0 NG'],
  "holding's": ['HH OW1 L D IH0 NG Z'],
  "holdings": ['HH OW1 L D IH0 NG Z'],
  "holdings'": ['HH OW1 L D IH0 NG Z'],
  "holdman": ['HH OW1 L D M AH0 N'],
  "holdorf": ['HH OW1 L D AO0 R F'],
  "holdout": ['HH OW1 L D AW2 T'],
  "holdouts": ['HH OW1 L D AW2 T S'],
  "holdover": ['HH OW1 L D OW2 V ER0'],
  "holdovers": ['HH OW1 L D OW2 V ER0 Z'],
  "holdren": ['HH OW1 L D ER0 AH0 N'],
  "holdridge": ['HH OW1 L D R IH0 JH'],
  "holds": ['HH OW1 L D Z'],
  "holdsworth": ['HH OW1 L D Z W ER2 TH'],
  "holdup": ['HH OW1 L D AH2 P'],
  "holdups": ['HH OW1 L D AH2 P S'],
  "hole": ['HH OW1 L'],
  "holecek": ['HH AA1 L IH0 CH EH0 K'],
  "holed": ['HH OW1 L D'],
  "holeman": ['HH OW1 L M AH0 N'],
  "holen": ['HH OW1 L AH0 N'],
  "holes": ['HH OW1 L Z'],
  "holewinski": ['HH AH0 L UW0 IH1 N S K IY0'],
  "holford": ['HH OW1 L F ER0 D'],
  "holgate": ['HH OW1 L G EY2 T'],
  "holgerson": ['HH OW1 L G ER0 S AH0 N'],
  "holguin": ['HH OW1 L G IH0 N'],
  "holian": ['HH OW1 L IY0 AH0 N'],
  "holick": ['HH AA1 L IH0 K'],
  "holiday": ['HH AA1 L AH0 D EY2', 'HH AA1 L IH0 D EY2'],
  "holiday's": ['HH AA1 L AH0 D EY2 Z'],
  "holidays": ['HH AA1 L AH0 D EY2 Z'],
  "holidaysburg": ['HH AA1 L AH0 D EY2 Z B ER0 G'],
  "holien": ['HH OW1 L IY0 AH0 N'],
  "holier": ['HH OW1 L IY0 ER0'],
  "holiest": ['HH OW1 L IY0 IH0 S T'],
  "holifield": ['HH AA1 L IH0 F IY2 L D'],
  "holihan": ['HH AA1 L IH0 HH AE0 N'],
  "holik": ['HH OW1 L IH0 K'],
  "holiman": ['HH AA1 L IH0 M AH0 N'],
  "holiness": ['HH OW1 L IY0 N AH0 S'],
  "holing": ['HH OW1 L IH0 NG'],
  "holistic": ['HH OW0 L IH1 S T IH0 K'],
  "holl": ['HH AA1 L'],
  "hollabaugh": ['HH AA1 L AH0 B AO2'],
  "holladay": ['HH AA1 L AH0 D EY2'],
  "hollan": ['HH AA1 L AH0 N'],
  "holland": ['HH AA1 L AH0 N D'],
  "holland's": ['HH AA1 L AH0 N D Z'],
  "hollander": ['HH AA1 L AH0 N D ER0'],
  "hollanders": ['HH AA1 L AH0 N D ER0 Z'],
  "hollands": ['HH AA1 L AH0 N D Z'],
  "hollandsworth": ['HH AA1 L AH0 N D Z W ER2 TH'],
  "hollar": ['HH AA1 L ER0'],
  "hollars": ['HH AA1 L ER0 Z'],
  "hollatz": ['HH AA1 L AH0 T S'],
  "hollaway": ['HH AA1 L AH0 W EY0'],
  "holle": ['HH AA1 L'],
  "holleman": ['HH OW1 L M AH0 N'],
  "hollen": ['HH AA1 L AH0 N'],
  "hollenbach": ['HH AA1 L IH0 N B AA0 K'],
  "hollenback": ['HH AA1 L AH0 N B AE2 K'],
  "hollenbaugh": ['HH AH0 L EH1 N B AO0'],
  "hollenbeck": ['HH AA1 L AH0 N B EH2 K'],
  "hollenberg": ['HH AA1 L AH0 N B ER0 G'],
  "hollender": ['HH AA1 L EH0 N D ER0'],
  "hollenkamp": ['HH AA1 L IH0 N K AE0 M P'],
  "holler": ['HH AA1 L ER0'],
  "holleran": ['HH AA1 L ER0 AH0 N'],
  "hollerbach": ['HH AA1 L ER0 B AA2 K'],
  "hollered": ['HH AA1 L ER0 D'],
  "hollering": ['HH AA1 L ER0 IH0 NG'],
  "hollern": ['HH AA1 L ER0 N'],
  "hollers": ['HH AA1 L ER0 Z'],
  "hollett": ['HH AA1 L IH0 T'],
  "holley": ['HH AA1 L IY0'],
  "hollibaugh": ['HH AA1 L IH0 B AO2'],
  "hollick": ['HH AA1 L IH0 K'],
  "holliday": ['HH AA1 L IH0 D EY2'],
  "holliday's": ['HH AA1 L IH0 D EY2 Z'],
  "hollie": ['HH AA1 L IY0'],
  "hollier": ['HH AO1 L IY0 ER0'],
  "hollies": ['HH AA1 L IY0 Z'],
  "hollifield": ['HH AA1 L IH0 F IY2 L D'],
  "holliman": ['HH AA1 L IH0 M AH0 N'],
  "holliman's": ['HH AA1 L IH0 M AH0 N Z'],
  "hollimon": ['HH AA1 L IH0 M AH0 N'],
  "hollin": ['HH AA1 L IH0 N'],
  "holling": ['HH AA1 L IH0 NG'],
  "hollinger": ['HH AA1 L IH0 NG ER0'],
  "hollings": ['HH AA1 L IH0 NG Z', 'HH AA1 L IH0 NG G Z'],
  "hollingshead": ['HH AA1 L IH0 NG Z HH EH2 D'],
  "hollingsworth": ['HH AA1 L IH0 NG Z W ER2 TH'],
  "hollingworth": ['HH AA1 L IH0 NG G W ER2 TH'],
  "hollins": ['HH AA1 L IH0 N Z'],
  "hollinshead": ['HH AA1 L IH0 N S HH EH2 D', 'HH AA1 L IH0 N Z HH EH2 D'],
  "hollinsworth": ['HH AA1 L IH0 N S W ER2 TH', 'HH AA1 L IH0 N Z W ER2 TH'],
  "hollis": ['HH AA1 L IH0 S'],
  "hollister": ['HH AA1 L IH0 S T ER0'],
  "holliston": ['HH AA1 L IH0 S T AH0 N'],
  "hollman": ['HH AA1 L M AH0 N'],
  "hollmann": ['HH AA1 L M AH0 N'],
  "hollo": ['HH AA1 L OW2'],
  "hollobaugh": ['HH AA1 L AH0 B AO0'],
  "hollom": ['HH AO1 L AH0 M'],
  "holloman": ['HH AA1 L OW0 M AH0 N'],
  "hollomon": ['HH AA1 L AH0 M AA0 N'],
  "hollon": ['HH AA1 L AH0 N'],
  "hollopeter": ['HH AA1 L AH0 P IY0 T ER0'],
  "holloran": ['HH AA1 L ER0 AH0 N'],
  "hollow": ['HH AA1 L OW0'],
  "hollow's": ['HH AA1 L OW0 Z'],
  "holloway": ['HH AA1 L OW0 W EY2'],
  "hollowed": ['HH AA1 L OW0 D'],
  "hollowell": ['HH AA1 L AH0 W EH0 L'],
  "hollowing": ['HH AA1 L OW0 IH0 NG'],
  "hollows": ['HH AA1 L OW0 Z'],
  "hollstein": ['HH AA1 L S T AY0 N', 'HH AA1 L S T IY0 N'],
  "holly": ['HH AA1 L IY0'],
  "holly's": ['HH AA1 L IY0 Z'],
  "hollyfield": ['HH AA1 L IH0 F IY2 L D', 'HH AA1 L IY0 F IY2 L D'],
  "hollyhead": ['HH AO1 L IY0 HH EH2 D'],
  "hollyhock": ['HH AA1 L IY0 HH AA2 K'],
  "hollyhocks": ['HH AA1 L IY0 HH AA2 K S'],
  "hollywood": ['HH AA1 L IY0 W UH2 D'],
  "hollywood's": ['HH AA1 L IY0 W UH2 D Z'],
  "holm": ['HH OW1 M'],
  "holman": ['HH AA1 L M AH0 N'],
  "holmberg": ['HH OW1 L M B ER0 G'],
  "holmdale": ['HH OW1 L M D EY2 L'],
  "holme": ['HH OW1 L M'],
  "holmen": ['HH AA1 L M EH0 N'],
  "holmer": ['HH OW1 L M ER0'],
  "holmes": ['HH OW1 M Z', 'HH OW1 L M Z'],
  "holmes's": ['HH OW1 M Z IH0 Z', 'HH OW1 L M Z IH0 Z'],
  "holmgren": ['HH OW1 L M G R EH0 N'],
  "holmium": ['HH OW1 L M IY0 AH0 M'],
  "holmlund": ['HH OW1 L M L AH0 N D'],
  "holmquest": ['HH OW1 L M K W EH2 S T'],
  "holmquist": ['HH OW1 L M K W IH2 S T'],
  "holmstrom": ['HH OW1 L M S T R AH0 M'],
  "holness": ['HH AA1 L N IH0 S'],
  "holocaust": ['HH AA1 L AH0 K AO2 S T'],
  "holocausts": ['HH AO1 L AH0 K AO2 S T S'],
  "holodeck": ['HH AA1 L AH0 D EH2 K'],
  "hologram": ['HH AA1 L AH0 G R AE2 M'],
  "holograms": ['HH AA1 L AH0 G R AE2 M Z'],
  "holographic": ['HH AA2 L AH0 G R AE1 F IH0 K'],
  "holohan": ['HH AA1 L AH0 HH AE0 N'],
  "holquin": ['HH OW1 L K W IH0 N'],
  "holroyd": ['HH OW1 L R OY2 D'],
  "holsapple": ['HH OW1 L S AH0 P AH0 L'],
  "holscher": ['HH OW1 L SH ER0'],
  "holsclaw": ['HH OW1 L S K L AO0'],
  "holsey": ['HH OW1 L S IY0'],
  "holshouser": ['HH OW1 L S HH AW2 S ER0'],
  "holsinger": ['HH OW1 L S IH0 NG ER0'],
  "holsomback": ['HH OW1 L S AH0 M B AE2 K'],
  "holsonback": ['HH OW1 L S AH0 N B AE2 K'],
  "holsopple": ['HH OW1 L S AH0 P AH0 L'],
  "holst": ['HH OW1 L S T'],
  "holstad": ['HH OW1 L S T AH0 D'],
  "holste": ['HH OW1 L S T'],
  "holstead": ['HH OW1 L S T EH2 D'],
  "holstein": ['HH OW1 L S T IY2 N'],
  "holsten": ['HH OW1 L S AH0 N'],
  "holster": ['HH OW1 L S T ER0'],
  "holstine": ['HH OW1 L S T AY2 N'],
  "holston": ['HH OW1 L S T AH0 N'],
  "holstrom": ['HH OW1 L S T R AH0 M'],
  "holsworth": ['HH OW1 L S W ER0 TH'],
  "holt": ['HH OW1 L T'],
  "holt's": ['HH OW1 L T S'],
  "holtan": ['HH OW1 L T AH0 N'],
  "holte": ['HH OW1 L T'],
  "holten": ['HH OW1 L T AH0 N'],
  "holter": ['HH OW1 L T ER0'],
  "holterman": ['HH OW1 L T ER0 M AH0 N'],
  "holthaus": ['HH OW1 L T HH AW2 S'],
  "holthus": ['HH OW1 L TH AH0 S'],
  "holtkamp": ['HH OW1 L T K AE2 M P'],
  "holtman": ['HH OW1 L T M AH0 N'],
  "holtmann": ['HH OW1 L T M AH0 N'],
  "holton": ['HH OW1 L T AH0 N'],
  "holtorf": ['HH OW1 L T ER0 F'],
  "holtrop": ['HH OW1 L T R AH0 P'],
  "holtry": ['HH OW1 L T R IY0'],
  "holts": ['HH OW1 L T S'],
  "holtsclaw": ['HH OW1 L T S K L AO2'],
  "holtz": ['HH OW1 L T S'],
  "holtz's": ['HH OW1 L T S IH0 Z'],
  "holtzapple": ['HH OW1 L T Z AH0 P AH0 L'],
  "holtzclaw": ['HH OW1 L T S K L AO0'],
  "holtzer": ['HH OW1 L T Z ER0'],
  "holtzinger": ['HH OW1 L T Z IH0 NG ER0'],
  "holtzman": ['HH OW1 L T S M AH0 N'],
  "holub": ['HH OW1 L AH0 B'],
  "holum": ['HH OW1 L AH0 M'],
  "holven": ['HH AO1 L V EH0 N'],
  "holverson": ['HH AA1 L V ER0 S AH0 N'],
  "holvis": ['HH OW1 L V AH0 S'],
  "holvis'": ['HH OW1 L V AH0 S'],
  "holvis's": ['HH OW1 L V AH0 S IH0 Z'],
  "holway": ['HH AA1 L W EY0'],
  "holweger": ['HH OW1 L W IH0 G ER0'],
  "holwerda": ['HH OW0 L W ER1 D AH0'],
  "holy": ['HH OW1 L IY0'],
  "holycross": ['HH OW1 L IY0 K R AO2 S'],
  "holyfield": ['HH OW1 L IY0 F IY2 L D'],
  "holyoak": ['HH OW1 L IY0 OW2 K'],
  "holyoke": ['HH OW1 L IY0 OW2 K'],
  "holz": ['HH OW1 L Z'],
  "holzapfel": ['HH OW1 L Z AH0 P F AH0 L'],
  "holzer": ['HH OW1 L Z ER0'],
  "holzhauer": ['HH OW1 L Z HH AW0 ER0'],
  "holzhauser": ['HH OW1 L Z HH AW0 Z ER0'],
  "holzheimer": ['HH OW1 L Z HH AY0 M ER0'],
  "holzinger": ['HH OW1 L Z IH0 NG ER0'],
  "holzman": ['HH OW1 L Z M AH0 N'],
  "holzmann": ['HH OW1 L Z M AH0 N'],
  "holzschuh": ['HH OW1 L SH UW0'],
  "holzstoff": ['HH OW1 L S T AO2 F'],
  "holzwarth": ['HH OW1 L Z W ER0 TH'],
  "holzworth": ['HH OW1 L Z W ER0 TH'],
  "hom": ['HH AA1 M'],
  "homa": ['HH OW1 M AH0'],
  "homac": ['HH OW1 M AE0 K'],
  "homage": ['AA1 M AH0 JH', 'HH AA1 M AH0 JH'],
  "homages": ['AA1 M AH0 JH AH0 Z', 'HH AA1 M AH0 JH AH0 Z'],
  "homan": ['HH OW1 M AH0 N'],
  "homann": ['HH OW1 M AH0 N'],
  "homans": ['HH OW1 M AH0 N Z'],
  "homart": ['HH OW1 M AA2 R T'],
  "hombre": ['HH AA1 M B R AH0'],
  "homburg": ['HH AA1 M B ER0 G'],
  "home": ['HH OW1 M'],
  "home's": ['HH OW1 M Z'],
  "home-grown": ['HH OW1 M G R OW2 N'],
  "home-made": ['HH OW1 M M EY1 D'],
  "home-school": ['HH OW1 M S K UW1 L'],
  "homebound": ['HH OW1 M B AW2 N D'],
  "homeboys": ['HH OW1 M B OY2 Z'],
  "homebuilder": ['HH OW1 M B IH2 L D ER0'],
  "homebuilder's": ['HH OW1 M B IH2 L D ER0 Z'],
  "homebuilders": ['HH OW1 M B IH2 L D ER0 Z'],
  "homebuilding": ['HH OW1 M B IH2 L D IH0 NG'],
  "homebuyer": ['HH OW1 M B AY2 ER0'],
  "homebuyers": ['HH OW1 M B AY2 ER0 Z'],
  "homecare": ['HH OW1 M K EH2 R'],
  "homeclub": ['HH OW1 M K L AH2 B'],
  "homecoming": ['HH OW1 M K AH2 M IH0 NG'],
  "homed": ['HH OW1 M D'],
  "homedco": ['HH OW2 M EH1 D K OW2'],
  "homefed": ['HH OW1 M F EH2 D'],
  "homefed's": ['HH OW1 M F EH2 D Z'],
  "homefront": ['HH OW1 M F R AH0 N T'],
  "homegrown": ['HH OW1 M G R OW1 N'],
  "homeland": ['HH OW1 M L AE2 N D'],
  "homeland's": ['HH OW1 M L AE2 N D Z'],
  "homelands": ['HH OW1 M L AE2 N D Z'],
  "homeless": ['HH OW1 M L AH0 S'],
  "homelessness": ['HH OW1 M L AH0 S N AH0 S'],
  "homelike": ['HH OW1 M L AY2 K'],
  "homely": ['HH OW1 M L IY0'],
  "homemade": ['HH OW1 M M EY1 D', 'HH OW1 M EY1 D'],
  "homemaker": ['HH OW1 M M EY2 K ER0'],
  "homemakers": ['HH OW1 M M EY2 K ER0 Z'],
  "homemaking": ['HH OW1 M M EY2 K IH0 NG'],
  "homen": ['HH OW1 M AH0 N'],
  "homeopathic": ['HH OW2 M IY0 OW0 P AE1 TH AH0 K'],
  "homeopathy": ['HH OW2 M IY0 OW0 P AE1 TH IY0'],
  "homeostasis": ['HH OW2 M IY0 OW0 S T EY1 S AH0 S'],
  "homeostatic": ['HH OW2 M IY0 OW0 S T AE1 T IH0 K'],
  "homeowner": ['HH OW1 M OW2 N ER0'],
  "homeowner's": ['HH OW1 M OW2 N ER0 Z'],
  "homeowners": ['HH OW1 M OW2 N ER0 Z'],
  "homeowners'": ['HH OW1 M OW2 N ER0 Z'],
  "homeownership": ['HH OW1 M OW2 N ER0 SH IH2 P'],
  "homepage": ['HH OW1 M P EY2 JH'],
  "homeporting": ['HH OW1 M P AO1 R T IH0 NG'],
  "homer": ['HH OW1 M ER0'],
  "homer's": ['HH OW1 M ER0 Z'],
  "homeric": ['HH OW0 M EH1 R IH0 K'],
  "homers": ['HH OW1 M ER0 Z'],
  "homerun": ['HH OW0 M R AH1 N'],
  "homeruns": ['HH OW0 M R AH1 N Z'],
  "homes": ['HH OW1 M Z'],
  "homes'": ['HH OW1 M Z'],
  "homesick": ['HH OW1 M S IH2 K'],
  "homesickness": ['HH OW1 M S IH2 K N AH0 S'],
  "homesley": ['HH OW1 M Z L IY0'],
  "homespun": ['HH OW1 M S P AH2 N'],
  "homestake": ['HH OW1 M S T EY2 K'],
  "homestake's": ['HH OW1 M S T EY2 K S'],
  "homestate": ['HH OW1 M S T EY2 T'],
  "homestead": ['HH OW1 M S T EH2 D'],
  "homestead's": ['HH OW1 M S T EH2 D Z'],
  "homesteaded": ['HH OW1 M S T EH2 D IH0 D'],
  "homesteader": ['HH OW1 M S T EH0 D ER0'],
  "homesteaders": ['HH OW1 M S T EH0 D ER0 Z'],
  "homesteads": ['HH OW1 M S T EH2 D Z'],
  "homestretch": ['HH OW1 M S T R EH2 CH'],
  "hometown": ['HH OW1 M T AW2 N'],
  "hometowns": ['HH OW1 M T AW2 N Z'],
  "homeward": ['HH OW1 M W ER0 D'],
  "homewood": ['HH OW1 M W UH2 D'],
  "homework": ['HH OW1 M W ER2 K'],
  "homeworker": ['HH OW1 M W ER2 K ER0'],
  "homeworkers": ['HH OW1 M W ER2 K ER0 Z'],
  "homeworld": ['HH OW1 M W ER2 L D'],
  "homey": ['HH OW1 M IY0'],
  "homeyer": ['HH OW1 M IY0 ER0'],
  "homicidal": ['HH AA2 M AH0 S AY1 D AH0 L'],
  "homicide": ['HH AA1 M AH0 S AY2 D'],
  "homicides": ['HH AA1 M AH0 S AY2 D Z'],
  "homiletic": ['HH AA2 M AH0 L EH1 T IH0 K'],
  "homilies": ['HH OW1 M AH0 L IY0 Z'],
  "homily": ['HH AA1 M AH0 L IY0'],
  "hominem": ['HH AA1 M IH0 N EH0 M'],
  "homing": ['HH OW1 M IH0 NG'],
  "hominid": ['HH AA1 M AH0 N IH0 D'],
  "hominy": ['HH AA1 M IH0 N IY0'],
  "homme": ['HH AA1 M'],
  "hommel": ['HH AA1 M AH0 L'],
  "hommes": ['HH AA1 M Z'],
  "homo": ['HH OW1 M OW0'],
  "homo-erectus": ['HH OW1 M OW0 IH0 R EH1 K T AH0 S'],
  "homogeneity": ['HH AA2 M AH0 JH AH0 N IY1 AH0 T IY0'],
  "homogeneous": ['HH OW2 M AH0 JH IY1 N IY0 AH0 S'],
  "homogenization": ['HH OW0 M AA1 JH AH0 N IH0 Z EY2 SH AH0 N'],
  "homogenize": ['HH OW0 M AA1 JH AH0 N AY2 Z'],
  "homogenized": ['HH OW0 M AA1 JH AH0 N AY2 Z D'],
  "homogenous": ['HH AH0 M AA1 JH AH0 N AH0 S'],
  "homogeny": ['HH OW0 M AA1 JH AH0 N IY0'],
  "homola": ['HH AH0 M AA1 L AH0'],
  "homolka": ['HH AH0 M AA1 L K AH0'],
  "homologous": ['HH AA0 M AA1 L AH0 G AH0 S'],
  "homonym": ['HH AO1 M AH0 N IH0 M'],
  "homonyms": ['HH AO1 M AH0 N IH0 M Z'],
  "homophobia": ['HH OW2 M AH0 F OW1 B IY0 AH0'],
  "homophobic": ['HH OW2 M AH0 F OW1 B IH0 K'],
  "homophone": ['HH OW1 M OW0 F OW2 N'],
  "homophones": ['HH OW1 M OW0 F OW2 N Z'],
  "homosapien": ['HH OW2 M OW0 S EY1 P IY2 AH0 N'],
  "homosapiens": ['HH OW2 M OW0 S EY1 P IY2 AH0 N Z'],
  "homosexual": ['HH OW2 M OW0 S EH1 K SH AH2 W AH0 L'],
  "homosexuality": ['HH OW2 M OW0 S EH2 K SH UW0 AE1 L AH0 T IY2'],
  "homosexuals": ['HH OW2 M OW0 S EH1 K SH UW2 AH0 L Z'],
  "homosporous": ['HH OW2 M OW0 S P ER2 AH0 S'],
  "homozygote": ['HH OW2 M OW0 Z AY1 G OW0 T'],
  "homozygous": ['HH OW2 M OW0 Z AY1 G AH0 S'],
  "homrich": ['HH AA1 M R IH0 K'],
  "homs": ['HH AA1 M Z'],
  "homsey": ['HH AA1 M Z IY0'],
  "homuth": ['HH AA1 M UW0 TH'],
  "hon": ['HH AA1 N', 'HH AH1 N'],
  "honaker": ['HH AA1 N AH0 K ER0'],
  "honan": ['HH OW1 N AH0 N'],
  "honasan": ['HH AA1 N AH0 Z AH0 N'],
  "honcho": ['HH AO1 N CH OW0'],
  "honda": ['HH AO1 N D AH0'],
  "honda's": ['HH AA1 N D AH0 Z'],
  "hondas": ['HH AA1 N D AH0 S'],
  "honderich": ['HH AA1 N D ER0 IH0 K'],
  "hondo": ['HH AA1 N D OW0'],
  "honduran": ['HH AA0 N D UH1 R AH0 N'],
  "hondurans": ['HH AA0 N D UH1 R AH0 N Z'],
  "honduras": ['HH AA0 N D UH1 R AH0 S'],
  "hone": ['HH OW1 N'],
  "honea": ['HH AA1 N IY0 AH0'],
  "honeck": ['HH OW1 N EH0 K'],
  "honecker": ['HH OW1 N EH0 K ER0'],
  "honecker's": ['HH OW1 N EH0 K ER0 Z'],
  "honed": ['HH OW1 N D'],
  "honer": ['HH OW1 N ER0'],
  "hones": ['HH OW1 N Z'],
  "honest": ['AA1 N AH0 S T'],
  "honestly": ['AA1 N AH0 S T L IY0', 'AA1 N AH0 S L IY0'],
  "honestok": ['HH OW1 N AH0 S T AO2 K'],
  "honesty": ['AA1 N AH0 S T IY0'],
  "honey": ['HH AH1 N IY0'],
  "honeybee": ['HH AH1 N IY0 B IY2'],
  "honeybees": ['HH AH1 N IY0 B IY2 Z'],
  "honeycomb": ['HH AH1 N IY0 K OW2 M'],
  "honeycutt": ['HH AH1 N IY0 K AH0 T'],
  "honeydew": ['HH AH1 N IY0 D UW2'],
  "honeyman": ['HH AA1 N IY0 M AH0 N'],
  "honeymoon": ['HH AH1 N IY0 M UW2 N'],
  "honeymooned": ['HH AH1 N IY0 M UW2 N D'],
  "honeymooner": ['HH AH1 N IY0 M UW2 N ER0'],
  "honeymooners": ['HH AH1 N IY0 M UW2 N ER0 Z'],
  "honeymoons": ['HH AH1 N IY0 M UW2 N Z'],
  "honeys": ['HH AH1 N IY0 Z'],
  "honeysuckle": ['HH AH1 N IY0 S AH2 K AH0 L'],
  "honeysuckles": ['HH AH1 N IY0 S AH2 K AH0 L Z'],
  "honeywell": ['HH AH1 N IY0 W EH2 L'],
  "honeywell's": ['HH AH1 N IY0 W EH2 L Z'],
  "hong": ['HH AO1 NG'],
  "hong-kong": ['HH AO1 NG K AO1 NG'],
  "hongkong": ['HH AO1 NG K AO0 NG'],
  "hongwei": ['HH AO1 NG W EY1'],
  "honig": ['HH AA1 N IH0 G'],
  "honing": ['HH OW1 N IH0 NG'],
  "honk": ['HH AA1 NG K', 'HH AO1 NG K'],
  "honking": ['HH AO1 NG K IH0 NG'],
  "honks": ['HH AO1 NG K S'],
  "honky": ['HH AO1 NG K IY0'],
  "honn": ['HH AA1 N'],
  "honnold": ['HH AA1 N OW2 L D'],
  "honold": ['HH AA1 N OW0 L D'],
  "honolulu": ['HH AA2 N AH0 L UW1 L UW0'],
  "honolulu's": ['HH AA2 N AH0 L UW1 L UW0 Z'],
  "honor": ['AA1 N ER0'],
  "honor's": ['AA1 N ER0 Z'],
  "honora": ['AO0 N AO1 R AH0'],
  "honorable": ['AA1 N ER0 AH0 B AH0 L'],
  "honorably": ['AA1 N ER0 AH0 B L IY0'],
  "honoraria": ['AA2 N ER0 EH1 R IY0 AH0'],
  "honorarium": ['AA2 N ER0 EH1 R IY0 AH0 M'],
  "honorariums": ['AA2 N ER0 EH1 R IY0 AH0 M Z'],
  "honorary": ['AA1 N ER0 EH2 R IY0'],
  "honore": ['AA1 N ER0', 'AA1 N AH0 R EY2'],
  "honored": ['AA1 N ER0 D'],
  "honoree": ['AA0 N ER0 IY1'],
  "honorees": ['AA0 N ER0 IY1 Z'],
  "honoria": ['AA0 N ER1 IY0 AH0'],
  "honorific": ['AA2 N ER0 IH1 F IH0 K'],
  "honoring": ['AA1 N ER0 IH0 NG'],
  "honors": ['AA1 N ER0 Z'],
  "honour": ['AA1 N ER0'],
  "honour's": ['AA1 N ER0 Z'],
  "honourable": ['AA1 N ER0 AH0 B AH0 L'],
  "honourably": ['AA1 N ER0 AH0 B L IY0'],
  "honourary": ['AA1 N ER0 EH2 R IY0'],
  "honoured": ['AA1 N ER0 D'],
  "hons": ['HH AA1 N Z'],
  "honsberger": ['HH AA1 N S B ER0 G ER0'],
  "honse": ['HH AA1 N S'],
  "honshu": ['HH AA1 N SH UW0'],
  "honsinger": ['HH AA1 N S IH0 NG ER0'],
  "hontz": ['HH AA1 N T S'],
  "hoo": ['HH UW1'],
  "hoobler": ['HH UW1 B L ER0'],
  "hooch": ['HH UW1 CH'],
  "hoochie": ['HH UW1 CH IY0'],
  "hoock": ['HH UW1 K'],
  "hood": ['HH UH1 D'],
  "hooded": ['HH UH1 D IH0 D'],
  "hoodlum": ['HH UH1 D L AH0 M'],
  "hoodlums": ['HH UW1 D L AH0 M Z'],
  "hoods": ['HH UH1 D Z'],
  "hoodwink": ['HH UH1 D W IH2 NG K'],
  "hoodwinked": ['HH UH1 D W IH2 NG K T'],
  "hooey": ['HH UW1 IY0'],
  "hoof": ['HH UW1 F'],
  "hoofed": ['HH UH1 F T', 'HH UW1 F T'],
  "hoofs": ['HH UH1 F S', 'HH UW1 F S'],
  "hoog": ['HH UW1 G'],
  "hooge": ['HH UW1 JH'],
  "hoogland": ['HH UW1 G L AH0 N D'],
  "hoogovens": ['HH UW1 G AH0 V IH0 N Z'],
  "hook": ['HH UH1 K'],
  "hook's": ['HH UH1 K S'],
  "hooke": ['HH UH1 K'],
  "hooked": ['HH UH1 K T'],
  "hooker": ['HH UH1 K ER0'],
  "hooker's": ['HH UH1 K ER0 Z'],
  "hookers": ['HH UH1 K ER0 Z'],
  "hooking": ['HH UH1 K IH0 NG'],
  "hooks": ['HH UH1 K S'],
  "hookup": ['HH UH1 K AH2 P'],
  "hookups": ['HH UH1 K AH2 P S'],
  "hookworm": ['HH UH1 K W ER0 M'],
  "hookworms": ['HH UH1 K W ER0 M Z'],
  "hooky": ['HH UH1 K IY0'],
  "hoole": ['HH UW1 L'],
  "hooley": ['HH UW1 L IY0'],
  "hooligan": ['HH UW1 L IH0 G AH0 N'],
  "hooliganism": ['HH UW1 L IH0 G AH0 N IH2 Z AH0 M'],
  "hooligans": ['HH UW1 L IH0 G AH0 N Z'],
  "hoolihan": ['HH UW1 L IH0 HH AE0 N'],
  "hoolihan's": ['HH UW1 L IH0 HH AE0 N Z'],
  "hoon": ['HH UW1 N'],
  "hoonah": ['HH UW1 N AH0'],
  "hoop": ['HH UW1 P'],
  "hooper": ['HH UW1 P ER0'],
  "hooperman": ['HH UW1 P ER0 M AH0 N'],
  "hoopes": ['HH UW1 P S'],
  "hoopingarner": ['HH UW0 P IH1 NG G AA0 R N ER0'],
  "hoopla": ['HH UW1 P L AA2'],
  "hoopoe": ['HH UW1 P UW2'],
  "hoops": ['HH UW1 P S'],
  "hooray": ['HH UH0 R EY1'],
  "hoos": ['HH UW1 Z'],
  "hoose": ['HH UW1 S'],
  "hooser": ['HH UW1 Z ER0'],
  "hoosier": ['HH UW1 ZH ER0'],
  "hoosiers": ['HH UW1 Z Y ER0 Z'],
  "hoot": ['HH UW1 T'],
  "hooted": ['HH UW1 T IH0 D'],
  "hooten": ['HH UW1 T AH0 N'],
  "hooter": ['HH UW1 T ER0'],
  "hooters": ['HH UW1 T ER0 Z'],
  "hootie": ['HH UW1 T IY0'],
  "hootman": ['HH UW1 T M AH0 N'],
  "hooton": ['HH UW1 T AH0 N'],
  "hoots": ['HH UW1 T S'],
  "hooven": ['HH UW1 V AH0 N'],
  "hoover": ['HH UW1 V ER0'],
  "hoover's": ['HH UW1 V ER0 Z'],
  "hooves": ['HH UH1 V Z', 'HH UW1 V Z'],
  "hoovler": ['HH UW1 V L ER0'],
  "hop": ['HH AA1 P'],
  "hopalong": ['HH AA1 P AH0 L AO0 NG'],
  "hopburg": ['HH AA1 P B ER0 G'],
  "hopburg's": ['HH AA1 P B ER0 G Z'],
  "hope": ['HH OW1 P'],
  "hope's": ['HH OW1 P S'],
  "hoped": ['HH OW1 P T'],
  "hopeful": ['HH OW1 P F AH0 L'],
  "hopefully": ['HH OW1 P F AH0 L IY0'],
  "hopefulness": ['HH OW1 P F AH0 L N IH0 S'],
  "hopefuls": ['HH OW1 P F AH0 L Z'],
  "hopeless": ['HH OW1 P L AH0 S'],
  "hopelessly": ['HH OW1 P L AH0 S L IY0'],
  "hopelessness": ['HH OW1 P L AH0 S N AH0 S'],
  "hopes": ['HH OW1 P S'],
  "hopewell": ['HH OW1 P W EH2 L'],
  "hopf": ['HH AA1 P F'],
  "hopfensperger": ['HH AA1 P F IH0 N S P ER0 G ER0', 'HH AA1 F IH0 N S P ER0 G ER0'],
  "hopfer": ['HH AA1 P F ER0'],
  "hopfinger": ['HH AA1 P F IH0 NG ER0'],
  "hopgood": ['HH AA1 P G UH2 D'],
  "hopi": ['HH OW1 P IY0'],
  "hoping": ['HH OW1 P IH0 NG'],
  "hopke": ['HH OW1 P K'],
  "hopkin": ['HH AA1 P K IH0 N'],
  "hopkin's": ['HH AA1 P K IH0 N Z'],
  "hopkins": ['HH AA1 P K IH0 N Z'],
  "hopkins'": ['HH AA1 P K IH0 N Z'],
  "hopkinson": ['HH AA1 P K IH0 N S AH0 N'],
  "hopkinton": ['HH AA1 P K IH0 N T AH0 N'],
  "hopkyns": ['HH AA1 P K IH0 N Z'],
  "hopman": ['HH AA1 P M AH0 N'],
  "hopp": ['HH AA1 P'],
  "hoppa": ['HH AA1 P AH0'],
  "hoppe": ['HH AA1 P'],
  "hopped": ['HH AA1 P T'],
  "hoppel": ['HH AA1 P AH0 L'],
  "hopper": ['HH AA1 P ER0'],
  "hopper's": ['HH AA1 P ER0 Z'],
  "hoppers": ['HH AA1 P ER0 Z'],
  "hoppert": ['HH AA1 P ER0 T'],
  "hoppes": ['HH AA1 P S'],
  "hopping": ['HH AA1 P IH0 NG'],
  "hopple": ['HH AA1 P AH0 L'],
  "hopps": ['HH AA1 P S'],
  "hoppy": ['HH AO1 P IY0'],
  "hops": ['HH AA1 P S'],
  "hopscotch": ['HH AA1 P S K AA2 CH'],
  "hopscotched": ['HH AA1 P S K AA2 CH T'],
  "hopson": ['HH AA1 P S AH0 N'],
  "hopton": ['HH AA1 P T AH0 N'],
  "hopwood": ['HH AA1 P W UH2 D'],
  "hora": ['HH AO1 R AH0'],
  "horace": ['HH AO1 R AH0 S', 'HH AO1 R IH0 S'],
  "horace's": ['HH AO1 R AH0 S AH0 Z'],
  "horacek": ['HH AO1 R AH0 CH EH0 K'],
  "horacia": ['HH AO0 R AA1 S IY0 AH0'],
  "horacio": ['HH AO0 R EY1 S IY0 OW0'],
  "horak": ['HH AO1 R AH0 K'],
  "horan": ['HH AO1 R AH0 N'],
  "horatia": ['HH AO0 R AA1 SH AH0'],
  "horatian": ['HH ER0 EY1 SH AH0 N'],
  "horatio": ['HH ER0 EY1 SH OW0'],
  "horatio's": ['HH AO0 R EY1 SH IY0 OW0 Z'],
  "horatius": ['HH AO0 R EY1 SH AH0 S'],
  "horch": ['HH AO1 R K'],
  "horchow": ['HH AO1 R CH OW0'],
  "hord": ['HH AO1 R D'],
  "horde": ['HH AO1 R D'],
  "hordes": ['HH AO1 R D Z'],
  "horehound": ['HH AA1 R HH AW2 N D'],
  "horejsi": ['HH AO0 R EY1 Y S IY0'],
  "horelick": ['HH AO1 R AH0 L IH0 K'],
  "horen": ['HH AO1 R AH0 N'],
  "horenstein": ['HH AO1 R AH0 N S T AY0 N', 'HH AO1 R AH0 N S T IY0 N'],
  "horgan": ['HH AO1 R G AH0 N'],
  "horgavitt": ['HH AO1 R G AH0 V IH2 T'],
  "horger": ['HH AO1 R G ER0'],
  "hori": ['HH AO1 R IY0'],
  "horiguchi": ['HH AO2 R IH0 G UW1 CH IY0'],
  "horine": ['HH AO1 R IY0 N'],
  "horiuchi": ['HH AO0 R IY0 UW1 CH IY0'],
  "horizon": ['HH ER0 AY1 Z AH0 N'],
  "horizon's": ['HH ER0 AY1 Z AH0 N Z'],
  "horizons": ['HH ER0 AY1 Z AH0 N Z'],
  "horizontal": ['HH AO2 R AH0 Z AA1 N T AH0 L'],
  "horizontally": ['HH AO2 R IH0 Z AA1 N T AH0 L IY0', 'HH AO0 R IH0 Z AO1 N AH0 L IY0'],
  "horkey": ['HH AO1 R K IY0'],
  "horky": ['HH AO1 R K IY0'],
  "horlacher": ['HH AO1 R L AH0 K ER0'],
  "horlick": ['HH AO1 R L IH0 K'],
  "horman": ['HH AO1 R M AH0 N'],
  "hormann": ['HH AO1 R M AH0 N'],
  "hormats": ['HH AO1 R M AE0 T S'],
  "hormel": ['HH AO0 R M EH1 L'],
  "hormonal": ['HH AO1 R M OW2 N AH0 L'],
  "hormone": ['HH AO1 R M OW2 N'],
  "hormones": ['HH AO1 R M OW2 N Z'],
  "hormuz": ['HH AO1 R M UW0 Z'],
  "horn": ['HH AO1 R N'],
  "horn's": ['HH AO1 R N Z'],
  "hornack": ['HH AO1 R N AH0 K'],
  "hornaday": ['HH AO1 R N AH0 D EY2'],
  "hornak": ['HH AO1 R N AH0 K'],
  "hornback": ['HH AO1 R N B AE2 K'],
  "hornbaker": ['HH AO1 R N B AH0 K ER0'],
  "hornbeak": ['HH AO1 R N B AH0 K'],
  "hornbeck": ['HH AO1 R N B EH2 K'],
  "hornberger": ['HH AO1 R N B ER0 G ER0'],
  "hornbill": ['HH AO1 R N B IH2 L'],
  "hornbills": ['HH AO1 R N B IH2 L Z'],
  "hornblende": ['HH AO1 R N B L EH2 N D'],
  "hornblower": ['HH AO1 R N B L OW2 ER0'],
  "hornbook": ['HH AO1 R N B UH2 K'],
  "hornbostel": ['HH AO1 R N B AH0 S T AH0 L', 'HH AO1 R N B AH0 S AH0 L'],
  "hornbrook": ['HH AO1 R N B R UH2 K'],
  "hornbuckle": ['HH AO1 R N B AH0 K AH0 L'],
  "hornburg": ['HH AO1 R N B ER0 G'],
  "hornby": ['HH AO1 R N B IY0'],
  "horne": ['HH AO1 R N'],
  "horned": ['HH AO1 R N D'],
  "hornell": ['HH AO2 R N EH1 L'],
  "horner": ['HH AO1 R N ER0'],
  "hornere": ['HH AO1 R N ER0'],
  "hornet": ['HH AO1 R N IH0 T'],
  "hornet's": ['HH AO1 R N AH0 T S'],
  "hornets": ['HH AO1 R N AH0 T S'],
  "horney": ['HH AO1 R N IY0'],
  "hornick": ['HH AO1 R N IH0 K'],
  "hornig": ['HH AO1 R N IH0 G'],
  "hornik": ['HH AO1 R N IH0 K'],
  "horning": ['HH AO1 R N IH0 NG'],
  "hornless": ['HH AO1 R N L AH0 S'],
  "hornlike": ['HH AO1 R N L AY2 K'],
  "hornor": ['HH AO1 R N ER0'],
  "hornpipe": ['HH AO1 R N P AY0 P'],
  "horns": ['HH AO1 R N Z'],
  "hornsby": ['HH AO1 R N Z B IY0'],
  "hornstein": ['HH AO1 R N S T AY2 N', 'HH AO1 R N S T IY2 N'],
  "hornung": ['HH AO1 R N AH0 NG'],
  "horny": ['HH AO1 R N IY0'],
  "hornyak": ['HH AO1 R N Y AE0 K'],
  "horoscope": ['HH AO1 R AH0 S K OW2 P'],
  "horoscopes": ['HH AO1 R AH0 S K OW2 P S'],
  "horovitz": ['HH AA1 R AH0 V IH0 T S'],
  "horowitz": ['HH AO1 R OW0 IH0 T S'],
  "horr": ['HH AO1 R'],
  "horrell": ['HH AO1 R AH0 L'],
  "horrendous": ['HH AO2 R EH1 N D AH0 S'],
  "horrendously": ['HH AO2 R EH1 N D AH0 S L IY0'],
  "horrible": ['HH AO1 R AH0 B AH0 L'],
  "horribly": ['HH AO1 R AH0 B L IY0'],
  "horrid": ['HH AO1 R AH0 D'],
  "horrific": ['HH AO0 R IH1 F IH0 K'],
  "horrified": ['HH AO1 R AH0 F AY2 D'],
  "horrify": ['HH AO1 R AH0 F AY2'],
  "horrifying": ['HH AO1 R AH0 F AY2 IH0 NG'],
  "horrigan": ['HH AO1 R AH0 G AH0 N'],
  "horrocks": ['HH AO1 R AH0 K S'],
  "horror": ['HH AO1 R ER0'],
  "horrors": ['HH AO1 R ER0 Z'],
  "hors": ['HH AO1 R Z', 'AO2 R'],
  "hors-d-oeuvre": ['AO2 R D ER1 V'],
  "horsch": ['HH AO1 R SH'],
  "horse": ['HH AO1 R S'],
  "horse's": ['HH AO1 R S AH0 Z'],
  "horseback": ['HH AO1 R S B AE2 K'],
  "horsed": ['HH AO1 R S T'],
  "horseflesh": ['HH AO1 R S F L EH2 SH'],
  "horsehead": ['HH AO1 R S HH EH2 D'],
  "horsely": ['HH AO1 R S L IY0'],
  "horseman": ['HH AO1 R S M AH0 N'],
  "horsemanship": ['HH AO1 R S M AH0 N SH IH0 P'],
  "horsemen": ['HH AO1 R S M AH0 N'],
  "horseplay": ['HH AO1 R S P L EY2'],
  "horsepower": ['HH AO1 R S P AW2 ER0'],
  "horseradish": ['HH AO1 R S R AE2 D IH0 SH'],
  "horses": ['HH AO1 R S AH0 Z', 'HH AO1 R S IH0 Z'],
  "horses'": ['HH AO1 R S IH0 Z'],
  "horseshit": ['HH AO1 R S SH IH2 T'],
  "horseshoe": ['HH AO1 R S SH UW2'],
  "horseshoes": ['HH AO1 R S SH UW2 Z'],
  "horsetail": ['HH AO1 R S T EY2 L'],
  "horsetails": ['HH AO1 R S T EY2 L Z'],
  "horsey": ['HH AO1 R S IY0'],
  "horsfall": ['HH AO1 R S F AH0 L'],
  "horsham": ['HH AO1 R SH AH0 M'],
  "horsham's": ['HH AO1 R SH AH0 M Z'],
  "horsing": ['HH AO1 R S IH0 NG'],
  "horsley": ['HH AO1 R S L IY0'],
  "horsman": ['HH AO1 R S M AH0 N'],
  "horst": ['HH AO1 R S T'],
  "horstman": ['HH AO1 R S T M AH0 N'],
  "horstmann": ['HH AO1 R S T M AH0 N'],
  "horta": ['HH AO1 R T AH0'],
  "horten": ['HH AO1 R T AH0 N'],
  "horter": ['HH AO1 R T ER0'],
  "horticultural": ['HH AO2 R T AH0 K AH1 L CH ER0 AH0 L'],
  "horticulture": ['HH AO1 R T IH0 K AH2 L CH ER0'],
  "horticulturist": ['HH AO2 R T IH0 K AH1 L CH ER0 IH0 S T'],
  "hortman": ['HH AO1 R T M AH0 N'],
  "horton": ['HH AO1 R T AH0 N'],
  "horus": ['HH AO1 R AH0 S'],
  "horvac": ['HH AO1 R V AE0 K'],
  "horvac's": ['HH AO1 R V AE0 K S'],
  "horvat": ['HH AO1 R V AH0 T'],
  "horvath": ['HH AO1 R V AE0 TH'],
  "horvath's": ['HH AO1 R V AE0 TH S'],
  "horvitz": ['HH AO1 R V IH0 T S'],
  "horwath": ['HH AO1 R W AO0 TH'],
  "horwich": ['HH AO1 R W IH0 K'],
  "horwitz": ['HH AO1 R W IH0 T S'],
  "hosack": ['HH AA1 S AH0 K'],
  "hosakawa": ['HH OW0 S AH0 K AA1 W AH0'],
  "hosch": ['HH AO1 SH'],
  "hose": ['HH OW1 Z'],
  "hosea": ['HH OW0 S IY1 AH0', 'HH OW0 Z EY1 AH0'],
  "hosea's": ['HH OW0 Z IY1 AH0 Z', 'HH OW0 Z EY1 AH0 Z'],
  "hosed": ['HH OW1 Z D'],
  "hosek": ['HH OW1 S EH0 K'],
  "hoselton": ['HH AH0 S EH1 L T AH0 N'],
  "hoses": ['HH OW1 Z IH0 Z'],
  "hosey": ['HH OW1 Z IY0'],
  "hosford": ['HH AA1 S F ER0 D'],
  "hoshaw": ['HH AA1 SH AO0'],
  "hoshimoto": ['HH OW0 SH IY0 M OW1 T OW0'],
  "hosick": ['HH AA1 S IH0 K'],
  "hosie": ['HH OW1 Z IY0'],
  "hosiery": ['HH OW1 ZH ER0 IY0'],
  "hosing": ['HH OW1 Z IH0 NG'],
  "hoskin": ['HH AA1 S K IH0 N'],
  "hosking": ['HH AA1 S K IH0 NG'],
  "hoskins": ['HH AA1 S K IH0 N Z'],
  "hoskinson": ['HH AA1 S K IH0 N S AH0 N'],
  "hoskyns": ['HH AO1 S K IH0 N Z'],
  "hosler": ['HH AA1 S AH0 L ER0', 'HH AA1 S L ER0'],
  "hosley": ['HH AA1 S L IY0'],
  "hosman": ['HH AA1 S M AH0 N'],
  "hosner": ['HH AA1 S N ER0'],
  "hosni": ['HH OW1 Z N IY0', 'HH AO1 S N IY0'],
  "hosoka": ['HH OW2 S AA1 K AH0'],
  "hosoka's": ['HH OW2 S AA1 K AH0 Z'],
  "hosokawa": ['HH OW2 S AH0 K AA1 W AH0'],
  "hosokawa's": ['HH OW2 S AH0 K AA1 W AH0 Z'],
  "hospice": ['HH AA1 S P AH0 S'],
  "hospice's": ['HH AA1 S P AH0 S IH0 Z'],
  "hospices": ['HH AA1 S P AH0 S IH0 Z'],
  "hospitable": ['HH AA1 S P IH1 T AH0 B AH0 L'],
  "hospital": ['HH AA1 S P IH2 T AH0 L'],
  "hospital's": ['HH AA1 S P IH2 T AH0 L Z'],
  "hospitality": ['HH AA2 S P AH0 T AE1 L AH0 T IY0'],
  "hospitalization": ['HH AA2 S P IH0 T AH0 L AH0 Z EY1 SH AH0 N'],
  "hospitalizations": ['HH AA2 S P IH0 T AH0 L AH0 Z EY1 SH AH0 N Z'],
  "hospitalize": ['HH AO1 S P IH2 T AH0 L AY2 Z'],
  "hospitalized": ['HH AA1 S P IH0 T AH0 L AY2 Z D'],
  "hospitals": ['HH AA1 S P IH2 T AH0 L Z'],
  "hospitals'": ['HH AO1 S P IH0 T AH0 L Z'],
  "hoss": ['HH AA1 S'],
  "hossack": ['HH AA1 S AH0 K'],
  "hossain": ['HH AH0 S EY1 N'],
  "hossein": ['HH AO1 S EY2 N'],
  "hosseini": ['HH OW0 S EY0 IY1 N IY0'],
  "hossler": ['HH AA1 S AH0 L ER0', 'HH AA1 S L ER0'],
  "host": ['HH OW1 S T'],
  "host's": ['HH OW1 S T S'],
  "hostage": ['HH AA1 S T IH0 JH'],
  "hostages": ['HH AA1 S T AH0 JH AH0 Z'],
  "hostages'": ['HH AO1 S T IH0 JH IH0 Z'],
  "hosted": ['HH OW1 S T IH0 D'],
  "hostel": ['HH AA1 S T AH0 L'],
  "hostels": ['HH AA1 S T AH0 L Z'],
  "hostench": ['HH AO1 S T AH0 N CH'],
  "hoster": ['HH OW1 S T ER0'],
  "hosterman": ['HH OW1 S T ER0 M AH0 N'],
  "hostess": ['HH OW1 S T AH0 S'],
  "hostesses": ['HH OW1 S T AH0 S AH0 Z', 'HH OW1 S T AH0 S IH0 Z'],
  "hostetler": ['HH AA1 S T IH0 T AH0 L ER0'],
  "hostetter": ['HH AA1 S T IH0 T ER0'],
  "hostettler": ['HH AA1 S T IH0 T AH0 L ER0', 'HH AA1 S T EH0 T L ER0'],
  "hostile": ['HH AA1 S T AH0 L', 'HH AA0 S T AY1 L'],
  "hostilities": ['HH AA0 S T IH1 L AH0 T IY0 Z'],
  "hostility": ['HH AA0 S T IH1 L AH0 T IY0'],
  "hosting": ['HH OW1 S T IH0 NG'],
  "hostler": ['HH AA1 S L ER0'],
  "hosts": ['HH OW1 S T S'],
  "hostutler": ['HH AA1 S T UW0 T AH0 L ER0', 'HH AA1 S T UW0 T L ER0'],
  "hot": ['HH AA1 T'],
  "hot-cross": ['HH AA1 T K R AO1 S'],
  "hot-line": ['HH AA1 T L AY1 N'],
  "hot-shot": ['HH AA1 SH AA2 T'],
  "hot-shots": ['HH AA1 SH AA2 T S'],
  "hotaling": ['HH AA1 T AH0 L IH0 NG'],
  "hotaling's": ['HH AA1 T AH0 L IH0 NG Z'],
  "hotard": ['HH AA1 T ER0 D'],
  "hotbed": ['HH AA1 T B EH2 D'],
  "hotbeds": ['HH AA1 T B EH2 D Z'],
  "hotcake": ['HH AA1 T K EY2 K'],
  "hotcakes": ['HH AA1 T K EY2 K S'],
  "hotchkin": ['HH AA1 CH K IH0 N'],
  "hotchkiss": ['HH AA1 CH K IH0 S'],
  "hotdog": ['HH AA1 T D AO2 G'],
  "hotdogs": ['HH AA1 T D AO2 G Z'],
  "hote": ['HH OW1 T'],
  "hotel": ['HH OW0 T EH1 L'],
  "hotel's": ['HH OW0 T EH1 L Z'],
  "hotelier": ['HH OW0 T EH1 L Y ER0'],
  "hoteliers": ['HH OW0 T EH1 L Y ER0 Z'],
  "hotels": ['HH OW0 T EH1 L Z'],
  "hotels'": ['HH OW0 T EH1 L Z'],
  "hoten": ['HH OW1 T AH0 N'],
  "hoth": ['HH AA1 TH'],
  "hothouse": ['HH AA1 T HH AW2 S'],
  "hothouses": ['HH AA1 T HH AW2 S IH0 Z'],
  "hotlanta": ['HH AO0 T L AE1 N T AH0'],
  "hotline": ['HH AA1 T L AY2 N'],
  "hotlines": ['HH AA1 T L AY2 N Z'],
  "hotly": ['HH AA1 T L IY0'],
  "hotmail": ['HH AA1 T M EY2 L'],
  "hotpot": ['HH AA1 T P AO2 T'],
  "hots": ['HH AA1 T S'],
  "hotshot": ['HH AA1 SH AA2 T'],
  "hotshots": ['HH AA1 SH AA2 T S'],
  "hotspot": ['HH AA1 T S P AA2 T'],
  "hott": ['HH AA1 T'],
  "hottel": ['HH AA1 T AH0 L'],
  "hottelet": ['HH AA1 T L EH0 T'],
  "hottelet's": ['HH AA1 T L EH0 T S'],
  "hottenstein": ['HH AA1 T AH0 N S T AY0 N', 'HH AA1 T AH0 N S T IY0 N'],
  "hotter": ['HH AA1 T ER0'],
  "hottest": ['HH AA1 T AH0 S T'],
  "hottinger": ['HH AA1 T IH0 NG ER0'],
  "hottle": ['HH AA1 T AH0 L'],
  "hottman": ['HH AA1 T M AH0 N'],
  "hotwire": ['HH AA1 T W AY2 ER0'],
  "hotwired": ['HH AA1 T W AY2 ER0 D'],
  "hotz": ['HH AA1 T S'],
  "hotze": ['HH OW1 T Z'],
  "hou": ['HH UW1'],
  "houchen": ['HH AW1 K AH0 N'],
  "houchens": ['HH AW1 K AH0 N Z'],
  "houchin": ['HH AW1 K IH0 N'],
  "houchins": ['HH AW1 K IH0 N Z'],
  "houck": ['HH AW1 K'],
  "houdaille": ['HH UW1 D EY2 L'],
  "houde": ['HH AW1 D'],
  "houdek": ['HH AW1 D IH0 K'],
  "houdeshell": ['HH UW1 D IH0 SH AH0 L'],
  "houdini": ['HH UW0 D IY1 N IY0'],
  "houdini's": ['HH UW0 D IY1 N IY0 Z'],
  "houff": ['HH OW1 F'],
  "houg": ['HH AW1 G'],
  "houge": ['HH AW1 JH'],
  "hougen": ['HH AW1 G AH0 N'],
  "hough": ['HH AH1 F'],
  "hougham": ['HH AW1 AH0 M'],
  "houghland": ['HH AW1 L AH0 N D'],
  "houghs": ['HH AW1 Z'],
  "houghtaling": ['HH AO1 T AH0 L IH0 NG'],
  "houghton": ['HH AO1 T AH0 N', 'HH AW1 T AH0 N'],
  "houghton's": ['HH AO1 T AH0 N Z', 'HH AW1 T AH0 N Z'],
  "hougland": ['HH AW1 G L AH0 N D'],
  "houk": ['HH AW1 K'],
  "houle": ['HH AW1 L'],
  "houlihan": ['HH UW1 L IH0 HH AE0 N'],
  "houlihan's": ['HH UW1 L IH0 HH AE0 N Z'],
  "houlton": ['HH OW1 L T AH0 N'],
  "hound": ['HH AW1 N D'],
  "hounded": ['HH AW1 N D IH0 D'],
  "hounding": ['HH AW1 N D IH0 NG'],
  "hounds": ['HH AW1 N D Z'],
  "hounshell": ['HH AW1 N SH AH0 L'],
  "houp": ['HH UW1 P'],
  "houpt": ['HH UW1 P T'],
  "hour": ['AW1 ER0', 'AW1 R'],
  "hour's": ['AW1 ER0 Z'],
  "hourglass": ['AW1 ER0 G L AE2 S'],
  "hourglasses": ['AW1 ER0 G L AE2 S IH0 Z'],
  "hourigan": ['AW1 R IH0 G AE0 N'],
  "hourihan": ['AW0 R IY1 HH AA0 N'],
  "hourlong": ['AW1 R L AO2 NG'],
  "hourly": ['AW1 R L IY0'],
  "hours": ['AW1 ER0 Z', 'AW1 R Z'],
  "hours'": ['AW1 R Z'],
  "housand": ['HH AW1 S AH0 N D'],
  "housden": ['HH AW1 S D AH0 N'],
  "house": ['HH AW1 S'],
  "house's": ['HH AW1 S IH0 Z'],
  "houseal": ['HH AW1 S AH0 L'],
  "houseboat": ['HH AW1 S B OW2 T'],
  "houseboats": ['HH AW1 S B OW2 T S'],
  "housebroken": ['HH AW1 S B R OW2 K AH0 N'],
  "housecleaning": ['HH AW1 S K L IY2 N IH0 NG'],
  "housed": ['HH AW1 Z D'],
  "houseful": ['HH AW1 S F AH0 L'],
  "houseguest": ['HH AW1 S G EH0 S T'],
  "houseguests": ['HH AW1 S G EH0 S T S'],
  "household": ['HH AW1 S HH OW2 L D'],
  "household's": ['HH AW1 S HH OW2 L D Z'],
  "householder": ['HH AW1 S HH OW2 L D ER0'],
  "householders": ['HH AW1 S HH OW2 L D ER0 Z'],
  "households": ['HH AW1 S HH OW2 L D Z'],
  "housekeeper": ['HH AW1 S K IY2 P ER0'],
  "housekeepers": ['HH AW1 S K IY2 P ER0 Z'],
  "housekeeping": ['HH AW1 S K IY2 P IH0 NG'],
  "houseknecht": ['HH AW1 S K N IH0 K T'],
  "housel": ['HH AW1 S AH0 L'],
  "houseman": ['HH AW1 S M AH0 N'],
  "houseman's": ['HH AW1 S M AH0 N Z'],
  "housen": ['HH AW1 S AH0 N'],
  "houser": ['HH AW1 Z ER0'],
  "houseraising": ['HH AW1 S R EY2 Z IH0 NG'],
  "houses": ['HH AW1 S AH0 Z', 'HH AW1 S IH0 Z'],
  "houses'": ['HH AW1 S IH0 Z'],
  "houseware": ['HH AW1 S W EH2 R'],
  "housewares": ['HH AW1 S W EH2 R Z'],
  "housewarming": ['HH AW1 S W AA2 M IH0 NG'],
  "housewife": ['HH AW1 S W AY2 F'],
  "housewives": ['HH AW1 S W AY2 V Z'],
  "housework": ['HH AW1 S W ER2 K'],
  "houseworth": ['HH AW1 S W ER2 TH'],
  "housewright": ['HH AW1 S R AY2 T'],
  "housh": ['HH AW1 SH'],
  "housholder": ['HH AW1 SH OW0 L D ER0'],
  "housing": ['HH AW1 Z IH0 NG'],
  "housings": ['HH AW1 Z IH0 NG Z'],
  "houska": ['HH AW1 S K AH0'],
  "housley": ['HH AW1 S L IY0'],
  "housman": ['HH AW1 S M AH0 N'],
  "houston": ['HH Y UW1 S T AH0 N'],
  "houston's": ['HH Y UW1 S T AH0 N Z'],
  "houstonian": ['HH UW2 S T OW1 N IY0 AH0 N', 'HH Y UW2 S T OW1 N IY0 AH0 N'],
  "hout": ['HH AW1 T'],
  "houtchens": ['HH AW1 CH AH0 N Z'],
  "houten": ['HH AW1 T EH0 N'],
  "houten's": ['HH AW1 T EH0 N Z'],
  "houtman": ['HH AW1 T M AH0 N'],
  "houts": ['HH AW1 T S'],
  "houtz": ['HH AW1 T S'],
  "houx": ['HH UW1'],
  "houy": ['HH AA1 AY0'],
  "houze": ['HH AW1 Z'],
  "hovan": ['HH OW1 V AH0 N'],
  "hovanec": ['HH AH0 V AE1 N IH0 K'],
  "hovater": ['HH OW1 V AH0 T ER0'],
  "hovatter": ['HH AA1 V AH0 T ER0'],
  "hovda": ['HH AA1 V D AH0'],
  "hovde": ['HH OW1 V D'],
  "hovden": ['HH AA1 V D AH0 N'],
  "hove": ['HH OW1 V'],
  "hovel": ['HH AH1 V AH0 L'],
  "hovels": ['HH AH1 V AH0 L Z'],
  "hoven": ['HH OW1 V AH0 N'],
  "hover": ['HH AH1 V ER0'],
  "hovercraft": ['HH AH1 V ER0 K R AE2 F T'],
  "hovered": ['HH AH1 V ER0 D'],
  "hoverflies": ['HH AH1 V ER0 F L AY2 Z'],
  "hoverfly": ['HH AH1 V ER0 F L AY2'],
  "hovering": ['HH AH1 V ER0 IH0 NG', 'HH AH1 V R IH0 NG'],
  "hovermale": ['HH AH1 V ER0 M AH0 L'],
  "hovers": ['HH AH1 V ER0 Z'],
  "hoverson": ['HH AH1 V ER0 S AH0 N'],
  "hovey": ['HH OW1 V IY0'],
  "hovhaness": ['HH AO2 V HH AE1 N IH0 S'],
  "hoving": ['HH OW1 V IH0 NG'],
  "hovious": ['HH OW1 V IY0 AH0 S'],
  "hovis": ['HH OW1 V IH0 S'],
  "hovland": ['HH AA1 V L AH0 N D'],
  "hovnanian": ['HH AA2 V N EY1 N IY0 AH0 N'],
  "hovorka": ['HH AH0 V AO1 R K AH0'],
  "hovsepian": ['HH AH0 V S IY1 P IY0 AH0 N'],
  "hovy": ['HH OW1 V IY0'],
  "how": ['HH AW1'],
  "how'd": ['HH AW1 D'],
  "how're": ['HH AW1 ER0'],
  "how's": ['HH AW1 Z'],
  "how-about": ['HH AW2 AH0 B AW1 T'],
  "how-many": ['HH AW2 M EH1 N IY0'],
  "how-much": ['HH AW2 M AH1 CH'],
  "howald": ['HH AW1 AH0 L D'],
  "howard": ['HH AW1 ER0 D'],
  "howard's": ['HH AW1 ER0 D Z'],
  "howarth": ['HH AW1 AA0 R TH'],
  "howat": ['HH AW1 AH0 T'],
  "howatt": ['HH AW1 AH0 T'],
  "howcroft": ['HH AW1 K R AH0 F T'],
  "howden": ['HH AW1 D AH0 N'],
  "howdeshell": ['HH AW1 D IH0 SH EH0 L'],
  "howdy": ['HH AW1 D IY0'],
  "howdydoody": ['HH AW1 D IY0 D UW1 D IY0'],
  "howdyshell": ['HH AW1 D IH0 SH EH0 L'],
  "howe": ['HH AW1'],
  "howe's": ['HH AW1 Z'],
  "howell": ['HH AW1 AH0 L'],
  "howell's": ['HH AW1 AH0 L Z'],
  "howells": ['HH AW1 AH0 L Z'],
  "howenstine": ['HH AW1 IH0 N S T IY0 N'],
  "hower": ['HH AW1 ER0'],
  "howerter": ['HH AW1 ER0 T ER0'],
  "howerton": ['HH AW0 ER1 T AH0 N'],
  "howery": ['HH AW1 ER0 IY0'],
  "howes": ['HH AW1 Z'],
  "howeth": ['HH AW1 IH0 TH'],
  "however": ['HH AW2 EH1 V ER0'],
  "howey": ['HH AW1 IY0'],
  "howie": ['HH AW1 IY0'],
  "howie's": ['HH AW1 IY0 Z'],
  "howington": ['HH AW1 IH0 NG T AH0 N'],
  "howison": ['HH AW1 IH0 S AH0 N'],
  "howitt": ['HH AW1 IH0 T'],
  "howitzer": ['HH AW1 AH0 T S ER0'],
  "howitzers": ['HH AW1 AH0 T S ER0 Z'],
  "howk": ['HH AW1 K'],
  "howl": ['HH AW1 L'],
  "howland": ['HH AW1 L AH0 N D'],
  "howle": ['HH AW1 AH0 L'],
  "howled": ['HH AW1 L D'],
  "howler": ['HH AW1 L ER0'],
  "howlett": ['HH AW1 L IH0 T'],
  "howley": ['HH AW1 L IY0'],
  "howling": ['HH AW1 L IH0 NG'],
  "howls": ['HH AW1 L Z'],
  "howmet": ['HH AW1 M AH0 T'],
  "howorth": ['HH AA1 W ER0 TH'],
  "howry": ['HH AW1 R IY0'],
  "howsare": ['HH AW1 S ER0'],
  "howse": ['HH AW1 Z'],
  "howsell": ['HH AW1 Z AH0 L'],
  "howser": ['HH AW1 Z ER0'],
  "howson": ['HH AW1 S AH0 N'],
  "howtek": ['HH AW1 T EH2 K'],
  "howton": ['HH AW1 T AH0 N'],
  "howze": ['HH AW1 Z'],
  "hoxie": ['HH AA1 K S IY0'],
  "hoxsey": ['HH AA1 K S IY0'],
  "hoxsie": ['HH AA1 K S IY0'],
  "hoxworth": ['HH AA1 K S W ER0 TH'],
  "hoy": ['HH OY1'],
  "hoye": ['HH OY1'],
  "hoyer": ['HH OY1 ER0'],
  "hoying": ['HH OY1 IH0 NG'],
  "hoylake": ['HH OY1 L EY2 K'],
  "hoyland": ['HH OY1 L AH0 N D'],
  "hoyle": ['HH OY1 L'],
  "hoyne": ['HH OY1 N'],
  "hoyos": ['HH OY1 OW0 Z'],
  "hoyt": ['HH OY1 T'],
  "hoyvald": ['HH OY1 V AH0 L D'],
  "hp": ['EY1 CH P IY1'],
  "hr": ['EY1 CH AA1 R'],
  "hrabak": ['HH R AA1 B AH0 K', 'R AA1 B AH0 K'],
  "hrawi": ['HH R AA1 W IY0'],
  "hrdlicka": ['HH ER0 D L IH1 S K AH0'],
  "hreha": ['HH R IY1 HH AH0', 'R IY1 HH AH0'],
  "hren": ['HH R EH1 N', 'R EH1 N'],
  "hribar": ['HH R IH0 B AA1 R', 'R IH0 B AA1 R'],
  "hritz": ['HH R IH1 T S', 'R IH1 T S'],
  "hrivnak": ['HH R IH1 V N AH0 K', 'R IH1 V N AH0 K'],
  "hrncir": ['HH ER1 N CH ER0', 'HH ER1 N S IH0 R'],
  "hron": ['HH R AA1 N', 'R AA1 N'],
  "hronek": ['HH R OW1 N IH0 K'],
  "hrovat": ['HH R OW1 V AH0 T'],
  "hrubik": ['HH IH0 R UW1 B IH0 K', 'HH R UW1 B IH0 K'],
  "hruby": ['HH R UW1 B IY0', 'R UW1 B IY0'],
  "hruska": ['HH R UH1 S K AH0'],
  "hsbc": ['EY1 CH EH1 S B IY1 S IY1'],
  "hsia": ['SH AA1'],
  "hsiao": ['SH AW1'],
  "hsieh": ['SH IY0 EH1'],
  "hsiung": ['SH IY0 AH1 NG'],
  "hsiung's": ['SH Y AH1 NG Z'],
  "hsu": ['SH UW1'],
  "html": ['EY2 CH T IY2 EH2 M EH1 L'],
  "http": ['EY2 CH T IY2 T IY2 P IY1'],
  "hu": ['HH UW1'],
  "hua": ['HH UW1 AH0', 'HH W AA1'],
  "huachuca": ['HH W AA0 CH UW1 K AH0', 'W AA0 CH UW1 K AH0'],
  "huadong": ['HH W AA1 D OW2 NG'],
  "huairou": ['HH W AY1 R UW0'],
  "huallaga": ['HH W AA0 L AA1 G AH0', 'W AA0 L AA1 G AH0'],
  "huan": ['HH W AA1 N'],
  "huaneng": ['HH W AA1 N EH1 NG'],
  "huang": ['HH W AE1 NG', 'HH W AA1 NG'],
  "huard": ['HH W AA1 R D'],
  "hub": ['HH AH1 B'],
  "hubbard": ['HH AH1 B ER0 D'],
  "hubbard's": ['HH AH1 B ER0 D Z'],
  "hubbart": ['HH AH1 B ER0 T'],
  "hubbell": ['HH AH1 B AH0 L'],
  "hubbell's": ['HH AH1 B AH0 L Z'],
  "hubbert": ['HH AH1 B ER0 T'],
  "hubble": ['HH AH1 B AH0 L'],
  "hubble's": ['HH AH1 B AH0 L Z'],
  "hubbs": ['HH AH1 B Z'],
  "hubbub": ['HH AH1 B AH0 B'],
  "hubby": ['HH AH1 B IY0'],
  "hubcap": ['HH AH1 B K AE2 P'],
  "hubcaps": ['HH AH1 B K AE2 P S'],
  "hubco": ['HH AH1 B K OW0'],
  "hube": ['HH Y UW1 B'],
  "huber": ['HH Y UW1 B ER0'],
  "huberman": ['HH UW1 B ER0 M AH0 N'],
  "hubers": ['HH UW1 B ER0 Z'],
  "hubert": ['HH Y UW1 B ER0 T'],
  "hubert's": ['HH Y UW1 B ER0 T S'],
  "huberta": ['HH UW0 B EH1 R T AH0'],
  "huberto": ['HH UW0 B EH1 R T OW0'],
  "huberty": ['HH AH1 B ER0 T IY0'],
  "hubka": ['HH AH1 B K AH0'],
  "hubler": ['HH Y UW1 B AH0 L ER0', 'HH Y UW1 B L ER0'],
  "hubley": ['HH AH1 B L IY0'],
  "hubner": ['HH AH1 B N ER0'],
  "hubor": ['HH Y UW1 B ER0'],
  "hubris": ['HH Y UW1 B R AH0 S'],
  "hubs": ['HH AH1 B Z'],
  "huch": ['HH AH1 CH'],
  "huchison": ['HH AH1 CH AH0 S IH0 N'],
  "huck": ['HH AH1 K'],
  "huckaba": ['HH AH1 K AH0 B AH0'],
  "huckabay": ['HH AH1 K AH0 B EY2'],
  "huckabee": ['HH AH1 K AH0 B IY0'],
  "huckaby": ['HH AH1 K AH0 B IY0'],
  "hucke": ['HH AH1 K'],
  "huckeba": ['HH AH1 K IH0 B AH0'],
  "huckelby": ['HH AH1 K AH0 L B IY0'],
  "huckelby'll": ['HH AH1 K AH0 L B IY0 AH0 L'],
  "huckelby's": ['HH AH1 K AH0 L B IY0 Z'],
  "hucker": ['HH AH1 K ER0'],
  "huckins": ['HH AH1 K IH0 N Z'],
  "huckle": ['HH AH1 K AH0 L'],
  "huckleberry": ['HH AH1 K AH0 L B EH2 R IY0'],
  "hucks": ['HH AH1 K S'],
  "huckstep": ['HH AH1 K S T IH0 P'],
  "huckster": ['HH AH1 K S T ER0'],
  "hucksters": ['HH AH1 K S T ER0 Z'],
  "hud": ['HH AH1 D'],
  "hud's": ['HH AH1 D Z'],
  "huda": ['HH UW1 D AH0'],
  "hudak": ['HH UW1 D AH0 K'],
  "huddie": ['HH AH1 D IY0'],
  "huddle": ['HH AH1 D AH0 L'],
  "huddled": ['HH AH1 D AH0 L D'],
  "huddles": ['HH AH1 D AH0 L Z'],
  "huddleson": ['HH AH1 D AH0 L S AH0 N'],
  "huddleston": ['HH AH1 D AH0 L S T AH0 N'],
  "huddling": ['HH AH1 D AH0 L IH0 NG', 'HH AH1 D L IH0 NG'],
  "huddy": ['HH AH1 D IY0'],
  "hudec": ['HH UW1 D IH0 K'],
  "hudecek": ['HH AH1 D IH0 CH EH0 K'],
  "hudek": ['HH UW1 D IH0 K'],
  "hudelson": ['HH AH1 D IH0 L S AH0 N'],
  "hudgens": ['HH AH1 JH AH0 N Z'],
  "hudgins": ['HH AH1 JH IH0 N Z'],
  "hudkins": ['HH AH1 D K IH0 N Z'],
  "hudler": ['HH UW1 D AH0 L ER0', 'HH UW1 D L ER0'],
  "hudlow": ['HH AH1 D L OW0'],
  "hudman": ['HH AH1 D M AH0 N'],
  "hudnall": ['HH AH1 D N AH0 L'],
  "hudnell": ['HH AH1 D N AH0 L'],
  "hudnut": ['HH AH1 D N AH2 T'],
  "hudock": ['HH AH1 D AH0 K'],
  "hudon": ['HH UW1 D AH0 N'],
  "hudson": ['HH AH1 D S AH0 N'],
  "hudson's": ['HH AH1 D S AH0 N Z'],
  "hudspeth": ['HH AH1 D S P IH0 TH'],
  "hudsucker": ['HH AH1 D S AH2 K ER0'],
  "hudwon": ['HH AH1 D W AH0 N'],
  "hudy": ['HH Y UW1 D IY0'],
  "hudzik": ['HH AH1 D Z IH0 K'],
  "hue": ['HH Y UW1'],
  "huebel": ['HH UH1 B AH0 L'],
  "hueber": ['HH UH1 B ER0'],
  "huebert": ['HH UH1 B ER0 T'],
  "huebner": ['HH Y UW1 B N ER0'],
  "huebsch": ['HH UH1 B SH'],
  "hued": ['HH Y UW1 D'],
  "huegel": ['HH UH1 G AH0 L'],
  "hueglin": ['HH Y UW1 G L IH0 N'],
  "huelskamp": ['HH UH1 L S K AE0 M P'],
  "huelsman": ['HH UH1 L S M AH0 N'],
  "huelsmann": ['HH UH1 L S M AH0 N'],
  "huenink": ['HH UH1 N IH0 NG K'],
  "huerst": ['HH ER1 S T'],
  "huerta": ['HH W EH1 R T AH2', 'W EH1 R T AH2'],
  "huerter": ['HH ER1 T ER0'],
  "hues": ['HH Y UW1 Z'],
  "huesman": ['HH UH1 S M AH0 N'],
  "huestis": ['HH UH1 S T IH0 S'],
  "hueston": ['HH UH1 S T AH0 N'],
  "huether": ['HH UH1 DH ER0'],
  "huett": ['HH UW1 T'],
  "huetta": ['HH UW0 EH1 T AH0'],
  "huette": ['HH UW1 T'],
  "huettl": ['HH UH1 T AH0 L'],
  "huettner": ['HH UH1 T N ER0'],
  "huey": ['HH Y UW1 IY0'],
  "hufbauer": ['HH AH1 F B AW2 ER0'],
  "huff": ['HH AH1 F'],
  "huff's": ['HH AH1 F S'],
  "huffaker": ['HH AH1 F AH0 K ER0'],
  "huffed": ['HH AH1 F T'],
  "huffer": ['HH AH1 F ER0'],
  "huffine": ['HH AH1 F AY2 N'],
  "huffines": ['HH AH1 F AY2 N Z'],
  "huffing": ['HH AH1 F IH0 NG'],
  "huffington": ['HH AH1 F IH0 NG T AH0 N'],
  "huffington's": ['HH AH1 F IH0 NG T AH0 N Z'],
  "huffman": ['HH AH1 F M AH0 N'],
  "huffmaster": ['HH AH1 F M AE2 S T ER0'],
  "hufford": ['HH AH1 F ER0 D'],
  "huffs": ['HH AH1 F S'],
  "huffstetler": ['HH AH1 F S T IH0 T AH0 L ER0', 'HH AH1 F S T EH0 T L ER0'],
  "huffstutler": ['HH AH1 F S T UW0 T AH0 L ER0', 'HH AH1 F S T UW0 T L ER0'],
  "huffy": ['HH AH1 F IY0'],
  "huffy's": ['HH AH1 F IY0 Z'],
  "hufnagel": ['HH AH1 F N EY2 G AH0 L'],
  "hufnagle": ['HH AH1 F N EY2 G AH0 L'],
  "hufstedler": ['HH AH1 F S T IH0 D AH0 L ER0', 'HH AH1 F S T IH0 D L ER0'],
  "hufstetler": ['HH AH1 F S T IH0 T AH0 L ER0', 'HH AH1 F S T EH0 T L ER0'],
  "hug": ['HH AH1 G'],
  "huge": ['HH Y UW1 JH', 'Y UW1 JH'],
  "hugel": ['HH UW1 G AH0 L'],
  "hugely": ['HH Y UW1 JH L IY0'],
  "hugest": ['HH Y UW1 JH AH0 S T'],
  "hugette": ['HH AH0 ZH EH1 T'],
  "hugg": ['HH AH1 G'],
  "huggard": ['HH AH1 G ER0 D'],
  "hugged": ['HH AH1 G D'],
  "hugger": ['HH AH1 G ER0'],
  "huggett": ['HH AH1 G IH0 T'],
  "huggies": ['HH AH1 G IY0 Z'],
  "hugging": ['HH AH1 G IH0 NG'],
  "huggins": ['HH AH1 G IH0 N Z'],
  "huggler": ['HH AH1 G L ER0'],
  "huggy": ['HH AH1 G IY0'],
  "hugh": ['HH Y UW1', 'Y UW1'],
  "hughart": ['HH AH1 G HH AA2 R T', 'HH Y UW1 AA2 R T', 'Y UW1 AA2 R T'],
  "hughbanks": ['HH AH1 B AH0 NG K S', 'HH Y UW1 B AH0 NG K S', 'Y UW1 B AH0 NG K S'],
  "hughen": ['HH Y UW1 AH0 N', 'Y UW1 AH0 N'],
  "hughes": ['HH Y UW1 Z', 'Y UW1 Z'],
  "hughes'": ['HH Y UW1 Z', 'Y UW1 Z'],
  "hughes's": ['HH Y UW1 Z IH0 Z', 'Y UW1 Z IH0 Z'],
  "hughett": ['HH Y UW1 IH0 T', 'Y UW1 IH0 T'],
  "hughette": ['HH Y UW2 EH1 T', 'Y UW2 EH1 T'],
  "hughey": ['HH AH1 G IY0', 'HH Y UW1 IY0', 'Y UW1 IY0'],
  "hughie": ['HH Y UW1 IY0', 'Y UW1 IY0'],
  "hughlett": ['HH Y UW1 L IH0 T', 'Y UW1 L IH0 T'],
  "hughley": ['HH AH1 G L IY0', 'HH Y UW1 L IY0', 'Y UW1 L IY0'],
  "hughs": ['Y UW1 Z', 'HH Y UW1 Z'],
  "hughson": ['HH AH1 G S AH0 N', 'HH Y UW1 S AH0 N', 'Y UW1 S AH0 N'],
  "hughston": ['HH AH1 G S T AH0 N', 'HH Y UW1 S T AH0 N', 'Y UW1 S T AH0 N'],
  "hughy": ['HH Y UW1 IY0', 'Y UW1 IY0'],
  "hugill": ['HH AH1 JH AH0 L'],
  "hugley": ['HH AH1 G L IY0'],
  "hugo": ['HH Y UW1 G OW0', 'Y UW1 G OW0'],
  "hugo's": ['HH Y UW1 G OW0 Z', 'Y UW1 G OW0 Z'],
  "hugoton": ['HH Y UW1 G OW0 T AH0 N', 'Y UW1 G OW0 T AH0 N'],
  "hugs": ['HH AH1 G Z'],
  "huguenin": ['HH UW0 G EY0 N IY1 N'],
  "huguenot": ['HH Y UW1 G AH0 N AA2 T', 'Y UW1 G AH0 N AA2 T'],
  "huguenots": ['HH Y UW1 G AH0 N AA2 T S', 'Y UW1 G AH0 N AA2 T S'],
  "huguet": ['HH UW1 G EY0 T'],
  "huguley": ['HH AH1 G Y UW0 L IY0'],
  "hugus": ['HH Y UW1 G AH0 S', 'Y UW1 G AH0 S'],
  "huh": ['HH AH1'],
  "huhn": ['HH AH1 N'],
  "huhta": ['HH UW1 T AH0'],
  "hui": ['HH UW1 IH0'],
  "huibregtse": ['HH UW1 B R EH0 K T S'],
  "huie": ['HH Y UW1 IY0', 'Y UW1 IY0'],
  "huish": ['HH Y UW1 IH0 SH', 'Y UW1 IH0 SH'],
  "huishman": ['HH UW1 S M AH0 N'],
  "huitt": ['HH UW1 T'],
  "huizar": ['HH IH0 Z AA1 R'],
  "huizenga": ['HH IH0 Z EY1 NG G AH0'],
  "huizenga's": ['HH IH0 Z EY1 NG G AH0 Z'],
  "huizinga": ['HH IH0 Z IY1 NG G AH0'],
  "hukill": ['HH Y UW1 K IH0 L', 'Y UW1 K IH0 L'],
  "hukstra": ['HH AH1 K S T R AH0'],
  "hula": ['HH UW1 L AH0'],
  "hulbard": ['HH AH1 L B ER0 D'],
  "hulbert": ['HH AH1 L B ER0 T'],
  "hulbert's": ['HH AH1 L B ER0 T S'],
  "hulburd": ['HH AH1 L B ER0 D'],
  "hulburt": ['HH AH1 L B ER0 T'],
  "hulce": ['HH AH1 L S'],
  "hulce's": ['HH AH1 L S AH0 Z'],
  "huldie": ['HH AH1 D IY0'],
  "huldy": ['HH AH1 L D IY0'],
  "hulen": ['HH AH1 L AH0 N'],
  "hulet": ['HH UW1 L IH0 T'],
  "hulett": ['HH Y UW1 L IH0 T', 'Y UW1 L IH0 T'],
  "hulette": ['HH Y UW2 L EH1 T', 'Y UW2 L EH1 T'],
  "hulgan": ['HH AH1 L G AH0 N'],
  "hulick": ['HH Y UW1 L IH0 K', 'Y UW1 L IH0 K'],
  "hulin": ['HH Y UW1 L IH0 N', 'Y UW1 L IH0 N'],
  "huling": ['HH Y UW1 L IH0 NG', 'Y UW1 L IH0 NG'],
  "hulings": ['HH Y UW1 L IH0 NG Z', 'Y UW1 L IH0 NG Z'],
  "hulk": ['HH AH1 L K'],
  "hulking": ['HH AH1 L K IH0 NG'],
  "hulks": ['HH AH1 L K S'],
  "hull": ['HH AH1 L'],
  "hull's": ['HH AH1 L Z'],
  "hullabaloo": ['HH AH2 L AH0 B AH0 L UW1'],
  "hulled": ['HH AH1 L D'],
  "hullender": ['HH UW1 L EH0 N D ER0'],
  "hullett": ['HH UW1 L IH0 T'],
  "hulliber": ['HH AH1 L IH0 B ER0'],
  "hullinger": ['HH AH1 L IH0 NG ER0', 'HH AH1 L IH0 N JH ER0'],
  "hulls": ['HH AH1 L Z'],
  "hullum": ['HH AH1 L AH0 M'],
  "hulme": ['HH AH1 L M'],
  "hulon": ['HH Y UW1 L AH0 N'],
  "hulon's": ['HH Y UW1 L AH0 N Z'],
  "hulond": ['HH Y UW1 L AH0 N D'],
  "hulond's": ['HH Y UW1 L AH0 N D Z'],
  "huls": ['HH AH1 L Z'],
  "hulse": ['HH AH1 L S'],
  "hulsebus": ['HH AH1 L S IH0 B IH0 S'],
  "hulsey": ['HH AH1 L S IY0'],
  "hulsizer": ['HH AH1 L S AY2 Z ER0'],
  "hulslander": ['HH AH1 L S L AH0 N D ER0'],
  "hulsman": ['HH AH1 L S M AH0 N'],
  "hulst": ['HH AH1 L S T'],
  "hult": ['HH AH1 L T'],
  "hult's": ['HH AH1 L T S'],
  "hultberg": ['HH AH1 L T B ER0 G'],
  "hultgren": ['HH AH1 L T G R EH0 N'],
  "hultman": ['HH AH1 L T M AH0 N'],
  "hulton": ['HH AH1 L T AH0 N'],
  "hultquist": ['HH AH1 L T K W IH0 S T'],
  "hults": ['HH AH1 L T S'],
  "hultz": ['HH AH1 L T S'],
  "hulu": ['HH UW1 L UW2'],
  "hulvey": ['HH AH1 L V IY0'],
  "hum": ['HH AH1 M'],
  "human": ['HH Y UW1 M AH0 N', 'Y UW1 M AH0 N'],
  "human's": ['HH Y UW1 M AH0 N Z'],
  "humana": ['HH Y UW0 M AE1 N AH0'],
  "humana's": ['HH Y UW0 M AE1 N AH0 Z'],
  "humane": ['HH Y UW0 M EY1 N'],
  "humanely": ['HH Y UW0 M EY1 N L IY0'],
  "humanism": ['HH Y UW1 M AH0 N IH2 Z AH0 M'],
  "humanist": ['HH Y UW1 M AH0 N IH0 S T'],
  "humanistic": ['HH Y UW2 M AH0 N IH1 S T IH0 K'],
  "humanists": ['HH Y UW1 M AH0 N IH0 S T S'],
  "humanitarian": ['HH Y UW2 M AE2 N AH0 T EH1 R IY0 AH0 N', 'Y UW2 M AE2 N AH0 T EH1 R IY0 AH0 N'],
  "humanitarians": ['HH Y UW2 M AE2 N AH0 T EH1 R IY0 AH0 N Z', 'Y UW2 M AE2 N AH0 T EH1 R IY0 AH0 N Z'],
  "humanities": ['HH Y UW0 M AE1 N IH0 T IY0 Z', 'Y UW0 M AE1 N IH0 T IY0 Z'],
  "humanity": ['HH Y UW0 M AE1 N IH0 T IY0', 'Y UW0 M AE1 N IH0 T IY0'],
  "humanity's": ['HH Y UW0 M AE1 N IH0 T IY0 Z', 'Y UW0 M AE1 N IH0 T IY0 Z'],
  "humanize": ['HH Y UW1 M AH0 N AY2 Z'],
  "humanized": ['HH Y UW1 M AH0 N AY2 Z D'],
  "humanizes": ['HH Y UW1 M AH0 N AY2 Z IH0 Z'],
  "humanizing": ['HH Y UW1 M AH0 N AY2 Z IH0 NG'],
  "humankind": ['HH Y UW1 M AH0 N K AY2 N D'],
  "humankind's": ['HH Y UW1 M AH0 N K AY2 N D Z'],
  "humanly": ['HH Y UW1 M AH0 N L IY0'],
  "humann": ['HH Y UW1 M AH0 N'],
  "humanness": ['HH Y UW1 M AH0 N N AH0 S'],
  "humanoid": ['HH Y UW1 M AH0 N OY2 D'],
  "humans": ['HH Y UW1 M AH0 N Z', 'Y UW1 M AH0 N Z'],
  "humbarger": ['HH AH1 M B AA2 R G ER0'],
  "humber": ['HH AH1 M B ER0'],
  "humbert": ['HH AH1 M B ER0 T'],
  "humberto": ['HH AH0 M B ER1 T OW2', 'UW2 M B EH1 R T OW2'],
  "humble": ['HH AH1 M B AH0 L'],
  "humbled": ['HH AH1 M B AH0 L D'],
  "humbler": ['HH AH1 M B AH0 L ER0', 'HH AH1 M B L ER0'],
  "humbles": ['HH AH1 M B AH0 L Z'],
  "humblest": ['HH AH1 M B AH0 L AH0 S T'],
  "humbling": ['HH AH1 M B AH0 L IH0 NG', 'HH AH1 M B L IH0 NG'],
  "humbly": ['HH AH1 M B L IY0'],
  "humboldt": ['HH AH1 M B OW2 L T'],
  "humbug": ['HH AH1 M B AH2 G'],
  "humburg": ['HH AH1 M B ER0 G'],
  "humdinger": ['HH AH1 M D IH0 NG ER0'],
  "humdrum": ['HH AH1 M D R AH2 M'],
  "hume": ['HH Y UW1 M'],
  "humenik": ['HH Y UW1 M IH0 N IH0 K'],
  "humerous": ['HH Y UW1 M ER0 AH0 S', 'Y UW1 M ER0 AH0 S'],
  "humerus": ['HH Y UW1 M ER0 AH0 S'],
  "humes": ['HH Y UW1 M Z'],
  "humfrey": ['HH AH1 M F R IY0'],
  "humfry": ['HH AH1 M F ER0 IY0'],
  "humi": ['HH Y UW1 M IY0'],
  "humid": ['HH Y UW1 M AH0 D', 'HH Y UW1 M IH0 D', 'Y UW1 M AH0 D', 'Y UW1 M IH0 D'],
  "humidifier": ['HH Y UW0 M IH1 D AH0 F AY2 ER0'],
  "humidifiers": ['HH Y UW0 M IH1 D AH0 F AY2 ER0 Z'],
  "humidity": ['HH Y UW0 M IH1 D AH0 T IY0'],
  "humidity's": ['HH Y UW0 M IH1 D AH0 T IY0 Z'],
  "humidor": ['HH Y UW1 M IH0 D AO2 R'],
  "humiliate": ['HH Y UW0 M IH1 L IY0 EY2 T'],
  "humiliated": ['HH Y UW0 M IH1 L IY0 EY2 T IH0 D'],
  "humiliates": ['HH Y UW0 M IH1 L IY0 EY2 T S'],
  "humiliating": ['HH Y UW0 M IH1 L IY0 EY2 T IH0 NG'],
  "humiliation": ['HH Y UW0 M IH2 L IY0 EY1 SH AH0 N'],
  "humiliations": ['HH Y UW2 M IH2 L IY0 EY1 SH AH0 N Z'],
  "humility": ['HH Y UW0 M IH1 L IH0 T IY0'],
  "humiston": ['HH Y UW1 M IH0 S T AA0 N'],
  "humke": ['HH AH1 M K IY0'],
  "huml": ['HH AH1 M AH0 L'],
  "humm": ['HH AH1 M'],
  "hummel": ['HH AH1 M AH0 L'],
  "hummell": ['HH AH1 M AH0 L'],
  "hummer": ['HH AH1 M ER0'],
  "humming": ['HH AH1 M IH0 NG'],
  "hummingbird": ['HH AH1 M IH0 NG B ER2 D'],
  "hummingbirds": ['HH AH1 M IH0 NG B ER2 D Z'],
  "hummus": ['HH UH1 M AH0 S'],
  "humongous": ['HH Y UW0 M AO1 NG G AH0 S'],
  "humor": ['HH Y UW1 M ER0'],
  "humor's": ['HH Y UW1 M ER0 Z'],
  "humoral": ['HH Y UW1 M ER0 AH0 L'],
  "humored": ['HH Y UW1 M ER0 D'],
  "humorist": ['HH Y UW1 M ER0 AH0 S T', 'HH Y UW1 M ER0 IH0 S T'],
  "humorists": ['HH Y UW1 M ER0 IH0 S T S'],
  "humorless": ['HH Y UW1 M ER0 L AH0 S'],
  "humorous": ['HH Y UW1 M ER0 AH0 S'],
  "humorously": ['HH Y UW1 M ER0 AH0 S L IY0'],
  "humour": ['HH Y UW1 M ER0'],
  "hump": ['HH AH1 M P'],
  "humpal": ['HH AH1 M P AH0 L'],
  "humpback": ['HH AH1 M P B AE2 K'],
  "humped": ['HH AH1 M P T'],
  "humpert": ['HH AH1 M P ER2 T'],
  "humph": ['HH AH1 M F'],
  "humphery": ['HH AH1 M F ER0 IY0', 'HH AH1 M F R IY0'],
  "humpherys": ['HH AH1 M F ER0 IY0 Z', 'HH AH1 M F R IY0 Z'],
  "humphrey": ['HH AH1 M F R IY0'],
  "humphrey's": ['HH AH1 M F R IY0 Z'],
  "humphreys": ['HH AH1 M F R IY0 Z'],
  "humphries": ['HH AH1 M F ER0 IY0 Z'],
  "humphry": ['HH AH1 M F R IY0'],
  "humpty": ['HH AH1 M P T IY0'],
  "hums": ['HH AH1 M Z'],
  "humulin": ['HH Y UW2 M Y UW1 L IH0 N'],
  "humus": ['HH Y UW1 M AH0 S'],
  "humvee": ['HH AH1 M V IY2'],
  "humvee's": ['HH AH1 M V IY2 Z'],
  "humvees": ['HH AH1 M V IY2 Z'],
  "hun": ['HH AH1 N'],
  "hunan": ['HH UW1 N AA0 N'],
  "hunch": ['HH AH1 N CH'],
  "hunchback": ['HH AH1 N CH B AE2 K'],
  "hunched": ['HH AH1 N CH T'],
  "hunches": ['HH AH1 N CH IH0 Z'],
  "hunchine": ['HH AH0 CH IY1 N'],
  "hund": ['HH AH1 N D'],
  "hundai": ['HH AH1 N D EY0'],
  "hundertmark": ['HH AH1 N D ER0 T M AA2 R K'],
  "hundley": ['HH AH1 N D L IY0'],
  "hundred": ['HH AH1 N D R AH0 D', 'HH AH1 N D R IH0 D', 'HH AH1 N ER0 D', 'HH AH1 N D ER0 D'],
  "hundred's": ['HH AH1 N D R IH0 D Z'],
  "hundreds": ['HH AH1 N D R AH0 D Z', 'HH AH1 N D ER0 D Z', 'HH AH1 N ER0 D Z'],
  "hundredth": ['HH AH1 N D R AH0 D TH'],
  "hundredths": ['HH AH1 N D R AH0 D TH S'],
  "hundredweight": ['HH AH1 N D R AH0 D W EY2 T'],
  "hundt": ['HH AH1 N T'],
  "huneke": ['HH AH1 N IH0 K'],
  "huneycutt": ['HH AH1 N IY0 K AH0 T'],
  "hung": ['HH AH1 NG'],
  "hungarian": ['HH AH0 NG G EH1 R IY0 AH0 N'],
  "hungarians": ['HH AH0 NG G EH1 R IY0 AH0 N Z'],
  "hungary": ['HH AH1 NG G ER0 IY0'],
  "hungary's": ['HH AH1 NG G ER0 IY0 Z'],
  "hungate": ['HH AH1 NG EY0 T'],
  "hunger": ['HH AH1 NG G ER0'],
  "hungerford": ['HH AH1 NG G ER0 F ER0 D'],
  "hungerfords": ['HH AH1 NG G ER0 F ER0 D Z'],
  "hungering": ['HH AH1 NG G ER0 IH0 NG'],
  "hungrier": ['HH AH1 NG G R IY0 ER0'],
  "hungrily": ['HH AH1 NG G R AH0 L IY0'],
  "hungry": ['HH AH1 NG G R IY0'],
  "hunk": ['HH AH1 NG K'],
  "hunke": ['HH AH1 NG K'],
  "hunkele": ['HH AH1 NG K AH0 L'],
  "hunker": ['HH AH1 NG K ER0'],
  "hunkered": ['HH AH1 NG K ER0 D'],
  "hunkering": ['HH AH1 NG K ER0 IH0 NG'],
  "hunkins": ['HH AH1 NG K IH0 N Z'],
  "hunks": ['HH AH1 NG K S'],
  "hunky": ['HH AH1 NG K IY0'],
  "hunley": ['HH AH1 N L IY0'],
  "hunn": ['HH AH1 N'],
  "hunnell": ['HH AH1 N AH0 L'],
  "hunnewell": ['HH AH1 N IH0 W EH0 L'],
  "hunnicutt": ['HH AH1 N IH0 K AH0 T'],
  "hunsaker": ['HH AH1 N S AH0 K ER0'],
  "hunsberger": ['HH AH1 N S B ER0 G ER0'],
  "hunsicker": ['HH AH1 N S IH0 K ER0'],
  "hunsinger": ['HH AH1 N S IH0 NG ER0'],
  "hunsley": ['HH AH1 N S L IY0'],
  "hunsucker": ['HH AH1 N S AH0 K ER0'],
  "hunt": ['HH AH1 N T'],
  "hunt's": ['HH AH1 N T S'],
  "huntco": ['HH AH1 N T K OW0'],
  "hunte": ['HH AH1 N T'],
  "hunted": ['HH AH1 N T AH0 D', 'HH AH1 N T IH0 D', 'HH AH1 N AH0 D', 'HH AH1 N IH0 D'],
  "hunter": ['HH AH1 N T ER0'],
  "hunter's": ['HH AH1 N T ER0 Z'],
  "hunters": ['HH AH1 N T ER0 Z'],
  "hunting": ['HH AH1 N T IH0 NG'],
  "huntingdon": ['HH AH1 N T IH0 NG D IH0 N'],
  "huntington": ['HH AH1 N T IH0 NG T AH0 N'],
  "huntington's": ['HH AH1 N T IH0 NG T AH0 N Z'],
  "huntley": ['HH AH1 N T L IY0'],
  "huntly": ['HH AH1 N T L IY0'],
  "hunton": ['HH AH1 N T AH0 N'],
  "huntoon": ['HH AH0 N T UW1 N'],
  "huntress": ['HH AH1 N T R IH0 S'],
  "hunts": ['HH AH1 N T S'],
  "hunts'": ['HH AH1 N T S'],
  "huntsinger": ['HH AH1 N T S IH0 N JH ER0'],
  "huntsman": ['HH AH1 N T S M AH0 N'],
  "huntsville": ['HH AH1 N T S V IH0 L'],
  "huntway": ['HH AH1 N T W EY2'],
  "huntzinger": ['HH AH1 N T Z IH0 NG ER0'],
  "hunza": ['HH AH1 N Z AH0'],
  "hunzeker": ['HH AH1 N Z IH0 K ER0'],
  "hunziker": ['HH AH1 N Z IH0 K ER0'],
  "huot": ['HH Y UW1 AH0 T'],
  "hupe": ['HH Y UW1 P'],
  "hupfer": ['HH AH1 P F ER0'],
  "hupp": ['HH AH1 P'],
  "huppert": ['HH AH1 P ER0 T'],
  "hur": ['HH ER1'],
  "hurco": ['HH ER1 K OW2'],
  "hurd": ['HH ER1 D'],
  "hurdle": ['HH ER1 D AH0 L'],
  "hurdler": ['HH ER1 D AH0 L ER0', 'HH ER1 D L ER0'],
  "hurdles": ['HH ER1 D AH0 L Z'],
  "hurdling": ['HH ER1 D AH0 L IH0 NG', 'HH ER1 D L IH0 NG'],
  "hurdman": ['HH ER1 D M AH0 N'],
  "hurford": ['HH ER1 F ER0 D'],
  "hurl": ['HH ER1 L'],
  "hurlbert": ['HH ER1 L B ER0 T'],
  "hurlburt": ['HH ER1 L B ER0 T'],
  "hurlbut": ['HH ER1 L B AH0 T'],
  "hurlbutt": ['HH ER1 L B AH0 T'],
  "hurled": ['HH ER1 L D'],
  "hurless": ['HH ER1 L AH0 S'],
  "hurley": ['HH ER1 L IY0'],
  "hurling": ['HH ER1 L IH0 NG'],
  "hurlock": ['HH ER1 L AH0 K'],
  "hurly": ['HH ER1 L IY0'],
  "hurm": ['HH ER1 M'],
  "hurn": ['HH ER1 N'],
  "hurney": ['HH ER1 N IY0'],
  "huron": ['HH Y UW1 R AA2 N', 'HH Y UH1 R AA2 N'],
  "hurrah": ['HH UH0 R AA1'],
  "hurray": ['HH AH0 R EY1'],
  "hurrell": ['HH AO1 R AH0 L'],
  "hurri": ['HH ER1 IY0'],
  "hurricane": ['HH ER1 AH0 K EY2 N', 'HH AH1 R AH0 K EY2 N Z'],
  "hurricane's": ['HH ER1 AH0 K EY2 N Z'],
  "hurricanes": ['HH ER1 AH0 K EY2 N Z'],
  "hurried": ['HH ER1 IY0 D'],
  "hurriedly": ['HH ER1 IY0 D L IY0'],
  "hurries": ['HH ER1 IY0 Z'],
  "hurry": ['HH ER1 IY0'],
  "hurrying": ['HH ER1 IY0 IH0 NG'],
  "hursey": ['HH ER1 S IY0'],
  "hursh": ['HH ER1 SH'],
  "hurst": ['HH ER1 S T'],
  "hurston": ['HH ER1 S T AH0 N'],
  "hurt": ['HH ER1 T'],
  "hurta": ['HH ER1 T AH0'],
  "hurtado": ['HH ER0 T AA1 D OW0'],
  "hurteau": ['HH ER0 T OW1'],
  "hurtful": ['HH ER1 T F AH0 L'],
  "hurtig": ['HH ER1 T IH0 G'],
  "hurting": ['HH ER1 T IH0 NG'],
  "hurtle": ['HH ER1 T AH0 L'],
  "hurtling": ['HH ER1 T L IH0 NG'],
  "hurts": ['HH ER1 T S'],
  "hurtt": ['HH ER1 T'],
  "hurtubise": ['HH ER1 T AH0 B AY0 Z'],
  "hurvey": ['HH ER1 V IY0'],
  "hurvitz": ['HH ER1 V IH0 T S'],
  "hurwitz": ['HH ER1 W IH0 T S'],
  "husain": ['HH AH1 S AY0 N'],
  "husak": ['HH UW1 S AH0 K'],
  "husar": ['HH UW1 S ER0'],
  "husband": ['HH AH1 Z B AH0 N D'],
  "husband's": ['HH AH1 Z B AH0 N D Z'],
  "husbandry": ['HH AH1 Z B AH0 N D R IY0'],
  "husbands": ['HH AH1 Z B AH0 N D Z'],
  "husbands'": ['HH AH1 S B AH0 N D Z'],
  "husby": ['HH AH1 S B IY0'],
  "huschka": ['HH AH1 SH K AH0'],
  "huse": ['HH Y UW1 Z'],
  "huseby": ['HH AH1 S IH0 B IY0'],
  "huseman": ['HH UW1 S M AH0 N'],
  "husen": ['HH UW1 S AH0 N'],
  "huser": ['HH Y UW1 Z ER0'],
  "hush": ['HH AH1 SH'],
  "hushed": ['HH AH1 SH T'],
  "husic": ['HH Y UW1 Z IH0 K'],
  "husk": ['HH AH1 S K'],
  "huska": ['HH AH1 S K AH0'],
  "huskey": ['HH AH1 S K IY0'],
  "huskins": ['HH AH1 S K IH0 N Z'],
  "husks": ['HH AH1 S K S'],
  "husky": ['HH AH1 S K IY0'],
  "husky's": ['HH AH1 S K IY0 Z'],
  "husman": ['HH AH1 S M AH0 N'],
  "husmann": ['HH AH1 S M AH0 N'],
  "huson": ['HH UW1 S AH0 N'],
  "huss": ['HH AH1 S'],
  "hussain": ['HH UW0 S EY1 N'],
  "hussar": ['HH AH1 S ER0'],
  "hussein": ['HH UW0 S EY1 N'],
  "hussein's": ['HH UW0 S EY1 N Z'],
  "husseini": ['HH Y UW0 S EY1 N IY0', 'HH UW0 S EY1 N IY0'],
  "husser": ['HH AH1 S ER0'],
  "hussey": ['HH AH1 S IY0'],
  "hussite": ['HH AH1 S AY2 T'],
  "hussman": ['HH AH1 S M AH0 N'],
  "husson": ['HH AH1 S AH0 N'],
  "hussong": ['HH AH1 S AO2 NG'],
  "hussy": ['HH AH1 S IY0'],
  "hust": ['HH AH1 S T'],
  "hustad": ['HH AH1 S T AH0 D'],
  "hustead": ['HH AH1 S T EH0 D'],
  "husted": ['HH AH1 S T IH0 D'],
  "huster": ['HH AH1 S T ER0'],
  "hustings": ['HH AH1 S T IH0 NG Z'],
  "hustle": ['HH AH1 S AH0 L'],
  "hustled": ['HH AH1 S AH0 L D'],
  "hustler": ['HH AH1 S AH0 L ER0', 'HH AH1 S L ER0'],
  "hustlers": ['HH AH1 S AH0 L ER0 Z', 'HH AH1 S L ER0 Z'],
  "hustles": ['HH AH1 S AH0 L Z'],
  "hustling": ['HH AH1 S AH0 L IH0 NG', 'HH AH1 S L IH0 NG'],
  "huston": ['HH AH1 S T AH0 N'],
  "hut": ['HH AH1 T'],
  "hut's": ['HH AH1 T S'],
  "huta": ['HH UW1 T AH0'],
  "hutch": ['HH AH1 CH'],
  "hutchcraft": ['HH AH1 CH K R AE2 F T'],
  "hutchens": ['HH AH1 CH AH0 N Z'],
  "hutcheon": ['HH AH1 CH IY0 AH0 N'],
  "hutcherson": ['HH AH1 CH ER0 S AH0 N'],
  "hutcheson": ['HH AH1 CH IH0 S AH0 N'],
  "hutchings": ['HH AH1 CH IH0 NG Z'],
  "hutchins": ['HH AH1 T CH IH2 N Z'],
  "hutchinson": ['HH AH1 CH IH0 N S AH0 N'],
  "hutchison": ['HH AH1 CH IH0 S AH0 N'],
  "hutchison's": ['HH AH1 CH IH0 S AH0 N Z'],
  "huth": ['HH UW1 TH'],
  "hutmacher": ['HH AH1 T M AH0 K ER0'],
  "hutnick": ['HH AH1 T N IH0 K'],
  "huto": ['HH UW1 T OW2'],
  "huts": ['HH AH1 T S'],
  "hutsell": ['HH AH1 T S AH0 L'],
  "hutson": ['HH AH1 T S AH0 N'],
  "hutt": ['HH AH1 T'],
  "hutter": ['HH AH1 T ER0'],
  "huttner": ['HH AH1 T N ER0'],
  "hutto": ['HH UW1 T OW0'],
  "hutton": ['HH AH1 T AH0 N'],
  "hutton's": ['HH AH1 T AH0 N Z'],
  "hutu": ['HH UW1 T UW2'],
  "hutu's": ['HH UW1 T UW2 Z'],
  "hutus": ['HH UW1 T UW2 Z'],
  "hutzel": ['HH AH1 T Z AH0 L'],
  "hutzell": ['HH AH1 T Z AH0 L'],
  "hutzelman": ['HH AH1 T S AH0 L M AH0 N'],
  "hutzler": ['HH AH1 T S L ER0'],
  "huval": ['HH UW0 V AE1 L'],
  "huwe": ['HH UW1 W'],
  "hux": ['HH AH1 K S'],
  "huxford": ['HH AH1 K S F ER0 D'],
  "huxley": ['HH AH1 K S L IY0'],
  "huxtable": ['HH AH1 K S T AH0 B AH0 L'],
  "huxtables": ['HH AH1 K S T AH0 B AH0 L Z'],
  "huyck": ['HH AY1 K'],
  "huyett": ['HH AY1 IH0 T'],
  "huyler": ['HH AY1 L ER0'],
  "huynh": ['HH AY1 N'],
  "huyser": ['HH AY1 S ER0'],
  "huzzah": ['HH UH2 Z AA1'],
  "hwa": ['HH W AA1'],
  "hwan": ['HH W AA1 N'],
  "hwan's": ['HH W AA1 N Z'],
  "hwang": ['HH W AE1 NG', 'HH W AA1 NG'],
  "hwang-ho": ['HH W AE1 NG HH OW1', 'HH W AA1 NG HH OW1'],
  "hwe": ['HH W EY1'],
  "hy": ['HH AY1'],
  "hyacinth": ['HH AY1 AH0 S IH2 N TH'],
  "hyacintha": ['HH AY2 AH0 S IH1 N TH AH0'],
  "hyacinthe": ['HH AY1 AH0 S IH0 N TH'],
  "hyacinthia": ['HH AY2 AH0 S IH1 N TH IY0 AH0'],
  "hyacinthie": ['HH AY1 AH0 S IH2 N TH IY0'],
  "hyacinths": ['HH AY1 AH0 S IH0 N TH S'],
  "hyades": ['HH AY1 AH0 D IY2 Z'],
  "hyakutake": ['HH AY1 AH0 K UW0 T AA2 K IY0', 'HH AY1 AH0 K Y UW0 T AA2 K IY0'],
  "hyaluronic": ['HH AY2 AH0 L ER0 AA1 N IH0 K'],
  "hyams": ['HH AY1 AH0 M Z'],
  "hyannis": ['HH AY0 AE1 N IH0 S'],
  "hyannisport": ['HH AY0 AE1 N IH0 S P AO0 R T'],
  "hyatt": ['HH AY1 AH0 T'],
  "hyatt's": ['HH AY1 AH0 T S'],
  "hybl": ['HH IH1 B AH0 L'],
  "hybl's": ['HH IH1 B AH0 L Z'],
  "hybrid": ['HH AY1 B R AH0 D', 'HH AY1 B R IH0 D'],
  "hybridization": ['HH AY2 B R AH0 D AH0 Z EY1 SH AH0 N'],
  "hybridize": ['HH AY1 B R AH0 D AY2 Z'],
  "hybrids": ['HH AY1 B R AH0 D Z'],
  "hybrienko": ['HH AY1 B R IY0 EH2 N K OW0'],
  "hybritech": ['HH AY1 B R IH0 T EH2 K'],
  "hyche": ['HH AY1 CH'],
  "hycor": ['HH AY1 K AO2 R'],
  "hycroft": ['HH AY1 K R AO2 F T'],
  "hyde": ['HH AY1 D'],
  "hyde's": ['HH AY1 D Z'],
  "hydea": ['HH AY2 D IY1 AH0', 'HH AY2 D EY1 AH0'],
  "hydeia": ['HH AY1 D EY1 AH0'],
  "hyden": ['HH AY1 D AH0 N'],
  "hyder": ['HH AY1 D ER0'],
  "hyderabad": ['HH AY0 D EH1 R AH0 B AE2 D', 'HH AY1 D ER0 AH0 B AE2 D'],
  "hydock": ['HH AY1 D AH0 K'],
  "hydra": ['HH AY1 D R AH0'],
  "hydrant": ['HH AY1 D R AH0 N T'],
  "hydrants": ['HH AY1 D R AH0 N T S'],
  "hydras": ['HH AY1 D R AH0 Z'],
  "hydrate": ['HH AY1 D R EY2 T'],
  "hydrated": ['HH AY1 D R EY2 T AH0 D'],
  "hydrates": ['HH AY1 D R EY2 T S'],
  "hydration": ['HH AY0 D R EY1 SH AH0 N'],
  "hydraulic": ['HH AY0 D R AO1 L IH0 K'],
  "hydraulics": ['HH AY0 D R AO1 L IH0 K S'],
  "hydrazine": ['HH AY1 D R AH0 Z IY2 N'],
  "hydrick": ['HH IH1 D R IH0 K'],
  "hydride": ['HH AY1 D R AY2 D'],
  "hydro": ['HH AY1 D R OW2'],
  "hydro's": ['HH AY1 D R OW2 Z'],
  "hydrocarbon": ['HH AY2 D R OW0 K AA1 R B AH0 N'],
  "hydrocarbons": ['HH AY2 D R OW0 K AA1 R B AH0 N Z'],
  "hydrocephalic": ['HH AY2 D R OW0 S EH0 F AA1 L IH0 K'],
  "hydrocephalus": ['HH AY2 D R OW0 S EH1 F AH0 L AH0 S'],
  "hydrocortisone": ['HH AY2 D R OW0 K AO1 R T AH0 Z OW2 N'],
  "hydroelectric": ['HH AY2 D R OW0 IH0 L EH1 K T R IH0 K'],
  "hydroencephalus": ['HH AY2 D R OW0 AH0 N S EH1 F AH0 L AH0 S'],
  "hydrofoil": ['HH AY1 D R AH0 F OY2 L'],
  "hydrogen": ['HH AY1 D R AH0 JH AH0 N'],
  "hydrogenate": ['HH AY1 D R AH0 JH AH0 N EY2 T'],
  "hydrogenated": ['HH AY1 D R AH0 JH AH0 N EY2 T IH0 D', 'HH AY0 D R AA1 JH AH0 N EY2 T IH0 D'],
  "hydrogenates": ['HH AY1 D R AH0 JH AH0 N EY2 T S'],
  "hydrogenating": ['HH AY1 D R AH0 JH AH0 N EY2 T IH0 NG'],
  "hydrogenation": ['HH AY2 D R AA2 JH AH0 N EY1 SH AH0 N'],
  "hydrogens": ['HH AY1 D R AH0 JH AH0 N Z'],
  "hydrographic": ['HH AY2 D R AH0 G R AE1 F IH0 K'],
  "hydrolysis": ['HH AY0 D R AA1 L AH0 S AH0 S'],
  "hydrolyze": ['HH AY1 D R AH0 L AY2 Z'],
  "hydrolyzed": ['HH AY1 D R AH0 L AY2 Z D'],
  "hydrolyzing": ['HH AY1 D R AH0 L AY2 Z IH0 NG'],
  "hydrometer": ['HH AY0 D R AA1 M AH0 T ER0'],
  "hydron": ['HH AY1 D R AH0 N'],
  "hydrophilic": ['HH AY2 D R AH0 F IH1 L IH0 K'],
  "hydropic": ['HH AY2 D R AO1 P IH0 K'],
  "hydroponic": ['HH AY2 D R AH0 P AA1 N IH0 K'],
  "hydropower": ['HH AY1 D R OW0 P AW2 R'],
  "hydrosol": ['HH AY1 D R AH0 S AA2 L'],
  "hydrosulfide": ['HH AY2 D R OW0 S AH1 L F AY2 D'],
  "hydrotherapy": ['HH AY2 D R OW0 TH EH1 R AH0 P IY0'],
  "hydrothermal": ['HH AY2 D R OW0 TH ER1 M AH0 L'],
  "hydrous": ['HH AY1 D R AH0 S'],
  "hydrox": ['HH AY1 D R AO2 K S'],
  "hydroxide": ['HH AY0 D R AA1 K S AY0 D'],
  "hydroxides": ['HH AY0 D R AA1 K S AY0 D Z'],
  "hydroxy": ['HH AY2 D R AO1 K S IY0'],
  "hydstra": ['HH AY1 D S T R AH0'],
  "hye": ['HH AY0'],
  "hyena": ['HH AY0 IY1 N AH0'],
  "hyenas": ['HH AY0 IY1 N AH0 Z'],
  "hyer": ['HH AY1 ER0'],
  "hyers": ['HH AY1 ER0 Z'],
  "hygeia": ['HH AY2 JH EY1 AH0'],
  "hygiene": ['HH AY1 JH IY2 N'],
  "hygienist": ['HH AY2 G IY1 N IH0 S T', 'HH AY2 G EH1 N IH0 S T'],
  "hygienists": ['HH AY2 G IY1 N IH0 S T S', 'HH AY2 G IY1 N IH0 S', 'HH AY2 G EH1 N IH0 S T S', 'HH AY2 G EH1 N IH0 S'],
  "hygrometer": ['HH AY0 G R AA1 M AH0 T ER0'],
  "hykes": ['HH AY1 K S'],
  "hyland": ['HH AY1 L AH0 N D'],
  "hyle": ['HH AY1 L'],
  "hyler": ['HH AY1 L ER0'],
  "hylsa": ['HH AY1 L S AH0'],
  "hylton": ['HH IH1 L T AH0 N'],
  "hyman": ['HH AY1 M AH0 N'],
  "hymans": ['HH AY1 M AH0 N Z'],
  "hymas": ['HH AY1 M AH0 Z'],
  "hymel": ['HH AY1 M AH0 L'],
  "hymen": ['HH AY1 M AH0 N'],
  "hymeneal": ['HH IH2 M AH0 N IY1 AH0 L'],
  "hymer": ['HH AY1 M ER0'],
  "hymes": ['HH AY1 M Z'],
  "hymie": ['HH AY1 M IY0'],
  "hymies": ['HH AY1 M IY0 Z'],
  "hymn": ['HH IH1 M'],
  "hymnal": ['HH IH1 M N AH0 L'],
  "hymnals": ['HH IH1 M N AH0 L Z'],
  "hymnology": ['HH IH0 M N AA1 L AH0 JH IY0'],
  "hymns": ['HH IH1 M Z'],
  "hymowitz": ['HH IH1 M AH0 W IH0 T S', 'HH AY1 M AH0 W IH0 T S'],
  "hynd": ['HH IH1 N D'],
  "hyndai": ['HH IH1 N D AY1'],
  "hyndman": ['HH IH1 N D M AH0 N'],
  "hynds": ['HH IH1 N D Z'],
  "hynek": ['HH AY1 N IH0 K'],
  "hynes": ['HH AY1 N Z'],
  "hynes's": ['HH AY1 N Z IH0 Z'],
  "hynson": ['HH IH1 N S AH0 N'],
  "hyogo": ['HH Y OW1 G OW0'],
  "hyotan": ['HH Y OW1 T AE2 N'],
  "hype": ['HH AY1 P'],
  "hyped": ['HH AY1 P T'],
  "hyper": ['HH AY1 P ER0'],
  "hyperactive": ['HH AY2 P ER0 AE1 K T IH0 V'],
  "hyperactivity": ['HH AY2 P ER0 AE0 K T IH1 V IH0 T IY0'],
  "hyperbaric": ['HH AY0 P ER0 B AA1 R IH0 K', 'HH AY0 P ER1 B AE1 R IH0 K'],
  "hyperbola": ['HH AY0 P ER1 B AH0 L AH0'],
  "hyperbole": ['HH AY0 P ER1 B AH0 L IY2'],
  "hyperbolic": ['HH AY2 P ER0 B AA1 L IH0 K'],
  "hyperborean": ['HH AY2 P ER0 B AO1 R IY0 AH0 N'],
  "hypercard": ['HH AY2 P ER0 K AA1 R D'],
  "hyperchromatic": ['HH AY2 P ER0 K R OW0 M AE1 T IH0 K'],
  "hypercritical": ['HH AY2 P ER0 K R IH1 T IH0 K AH0 L'],
  "hyperdrive": ['HH AY1 P ER0 D R AY2 V'],
  "hyperinflation": ['HH AY2 P ER0 IH0 N F L EY1 SH AH0 N'],
  "hyperion": ['HH AY0 P IH1 R IY0 AH0 N'],
  "hyperkinetic": ['HH AY2 P ER0 K IH0 N EH1 T IH0 K'],
  "hyperlink": ['HH AY1 P ER0 L IH0 NG K'],
  "hyperlinks": ['HH AY1 P ER0 L IH0 NG K S'],
  "hypermarket": ['HH AY1 P ER0 M AA2 R K IH0 T'],
  "hypermarkets": ['HH AY1 P ER0 M AA2 R K IH0 T S'],
  "hyperopia": ['HH AY2 P ER0 OW1 P IY2 AA0'],
  "hyperplasia": ['HH AY2 P ER0 P L EY1 ZH AA0'],
  "hypersensitive": ['HH AY2 P ER0 S EH1 N S IH0 T IH0 V'],
  "hypersensitivity": ['HH AY2 P ER0 S EH1 N S IH0 T IH2 V IH0 T IY2'],
  "hypersonic": ['HH AY2 P ER0 S AA1 N IH0 K'],
  "hyperspace": ['HH AY2 P ER0 S P EY1 S'],
  "hypertension": ['HH AY2 P ER0 T EH1 N SH AH0 N'],
  "hypertensive": ['HH AY2 P ER0 T EH1 N S IH0 V'],
  "hypertext": ['HH AY1 P ER0 T EH2 K S T'],
  "hypertonic": ['HH AY2 P ER0 T AA1 N IH0 K'],
  "hypertrophic": ['HH AY2 P ER0 T R OW1 F IH0 K'],
  "hypertrophy": ['HH AY1 P ER0 T R OW2 F IY0'],
  "hypes": ['HH AY1 P S'],
  "hyphae": ['HH AY1 F IY2'],
  "hyphen": ['HH AY1 F AH0 N'],
  "hyphenate": ['HH AY1 F AH0 N EY2 T'],
  "hyphenated": ['HH AY1 F AH0 N EY2 T IH0 D'],
  "hyphenates": ['HH AY1 F AH0 N EY2 T S'],
  "hyphenating": ['HH AY1 F AH0 N EY2 T IH0 NG'],
  "hyphenation": ['HH AY2 F AH0 N EY1 SH AH0 N'],
  "hyping": ['HH AY1 P IH0 NG'],
  "hypnosis": ['HH IH0 P N OW1 S AH0 S'],
  "hypnotic": ['HH IH0 P N AA1 T IH0 K'],
  "hypnotics": ['HH IH0 P N AA1 T IH0 K S'],
  "hypnotism": ['HH IH1 P N AH0 T IH2 Z AH0 M'],
  "hypnotism's": ['HH IH1 P N AH0 T IH2 Z AH0 M Z'],
  "hypnotist": ['HH IH1 P N AH0 T IH0 S T'],
  "hypnotists": ['HH IH1 P N AH0 T IH0 S T S'],
  "hypnotize": ['HH IH1 P N AH0 T AY2 Z'],
  "hypnotized": ['HH IH1 P N AH0 T AY2 Z D'],
  "hypnotizes": ['HH IH1 P N AH0 T AY2 Z AH0 Z'],
  "hypo": ['HH AY1 P OW0'],
  "hypochondria": ['HH AY2 P AH0 K AA1 N D R IY0 AH0'],
  "hypochondriac": ['HH AY2 P AH0 K AA1 N D R IY0 AE0 K'],
  "hypocrisy": ['HH IH0 P AA1 K R AH0 S IY0'],
  "hypocrite": ['HH IH1 P AH0 K R IH2 T'],
  "hypocrites": ['HH IH1 P AH0 K R IH2 T S'],
  "hypocritical": ['HH IH2 P AH0 K R IH1 T IH0 K AH0 L'],
  "hypodermic": ['HH AY2 P AH0 D ER1 M IH0 K'],
  "hypoglycemia": ['HH AY2 P OW0 G L AY0 S IY1 M IY0 AH0'],
  "hypoglycemic": ['HH AY2 P OW0 G L AY0 S IY1 M IH0 K'],
  "hypolite": ['HH AY1 P AH0 L AY0 T'],
  "hyponex": ['HH AY1 P OW0 N EH2 K S'],
  "hypotension": ['HH AY2 P OW0 T EH1 N SH AH0 N'],
  "hypothalamic": ['HH AY2 P OW0 TH AH0 L AE1 M IH0 K'],
  "hypothalamus": ['HH AY2 P OW0 TH AA1 L AH0 M AH0 S'],
  "hypotheken": ['HH AY2 P AA1 TH AH0 K AH0 N'],
  "hypothermia": ['HH AY2 P AH0 TH ER1 M IY0 AH0'],
  "hypotheses": ['HH AY0 P AA1 TH AH0 S IY2 Z'],
  "hypothesis": ['HH AY0 P AA1 TH AH0 S AH0 S'],
  "hypothesize": ['HH AY0 P AA1 TH AH0 S AY2 Z'],
  "hypothesized": ['HH AY0 P AA1 TH AH0 S AY2 Z D'],
  "hypothetical": ['HH AY2 P AH0 TH EH1 T AH0 K AH0 L', 'HH AY2 P AH0 TH EH1 T IH0 K AH0 L'],
  "hypothetically": ['HH AY2 P AH0 TH EH1 T IH0 K L IY0'],
  "hypotheticals": ['HH AY2 P AH0 TH EH1 T AH0 K AH0 L Z'],
  "hypoxia": ['HH AY0 P AA1 K S IY0 AH0'],
  "hyraxes": ['HH AY1 R AE0 K S AH0 Z'],
  "hyre": ['HH AY1 R'],
  "hysell": ['HH AY1 S AH0 L'],
  "hyser": ['HH AY1 Z ER0'],
  "hyslop": ['HH AY1 S L AH0 P'],
  "hyson": ['HH AY1 S AH0 N'],
  "hysong": ['HH AY1 S AO2 NG'],
  "hyssop": ['HH IH1 S AH0 P'],
  "hyster": ['HH IH1 S T ER0'],
  "hysterectomies": ['HH IH2 S T ER0 EH1 K T AH0 M IY0'],
  "hysterectomy": ['HH IH2 S T ER0 EH1 K T AH0 M IY0'],
  "hysteria": ['HH IH0 S T EH1 R IY0 AH0'],
  "hysteric": ['HH IH2 S T EH1 R IH0 K'],
  "hysterical": ['HH IH0 S T EH1 R IH0 K AH0 L'],
  "hysterically": ['HH IH2 S T EH1 R IH0 K L IY0'],
  "hysterics": ['HH IH2 S T EH1 R IH0 K S'],
  "hyun": ['HH AY1 AH0 N', 'HH Y AH1 N'],
  "hyundae": ['HH Y AH1 N D EY2'],
  "hyundai": ['HH Y AH1 N D EY2', 'HH AH1 N D EY2'],
  "hyundai's": ['HH AH1 N D EY2 Z'],
  "hyundais": ['HH Y AH1 N D EY2 Z'],
  "hywell": ['HH AY1 W EH0 L'],
  "hz": ['HH ER1 T Z'],
  "i": ['AY1'],
  "i'd": ['AY1 D'],
  "i'ers": ['AY1 ER0 Z'],
  "i'll": ['AY1 L'],
  "i'm": ['AY1 M', 'AH0 M'],
  "i's": ['AY1 Z'],
  "i've": ['AY1 V'],
  "i.": ['AY1'],
  "i.'s": ['AY1 Z'],
  "i.s": ['AY1 Z'],
  "ia": ['IY1 AH0'],
  "iacobelli": ['IY0 AA2 K OW0 B EH1 L IY0'],
  "iacobellis": ['IY0 AA2 K OW0 B EH1 L IH0 S'],
  "iacobucci": ['IY0 AA0 K OW0 B UW1 CH IY0'],
  "iacocca": ['AY2 AH0 K OW1 K AH0'],
  "iacocca's": ['AY2 AH0 K OW1 K AH0 Z'],
  "iacona": ['AY2 AH0 K OW1 N AH0'],
  "iacono": ['AY2 AH0 K OW1 N OW0'],
  "iacovelli": ['IY0 AA2 K OW0 V EH1 L IY0'],
  "iafrate": ['AY2 AH0 F R EY1 T IY0'],
  "iago": ['IY0 AA1 G OW0'],
  "iain": ['IY0 EY1 N'],
  "iakovos": ['IY0 AA1 K OW0 V OW0 S'],
  "ialla": ['AY1 AA0 L AH0', 'IY1 AA0 L AH0'],
  "iams": ['IY1 AA0 M Z', 'AY1 AA0 M Z'],
  "ian": ['IY1 AH0 N'],
  "iannaccone": ['IY0 AA2 N AH0 K OW1 N IY0'],
  "iannacone": ['IY0 AA2 N AH0 K OW1 N IY0'],
  "iannamico": ['IY0 AA2 N AH0 M IY1 K OW0'],
  "iannelli": ['IY0 AH0 N EH1 L IY0'],
  "iannello": ['IY0 AH0 N EH1 L OW0'],
  "ianni": ['IY0 AA1 N IY0'],
  "ianniello": ['IY0 AA2 N IY0 EH1 L OW0'],
  "iannone": ['IY0 AH0 N OW1 N IY0'],
  "iannotti": ['IY0 AH0 N OW1 T IY0'],
  "iannucci": ['IY0 AH0 N UW1 CH IY0'],
  "iannuzzi": ['IY0 AH0 N UW1 T S IY0', 'IY0 AH0 N UW1 Z IY0'],
  "ianovski": ['Y AH0 N AA1 V S K IY0', 'Y AH0 N AA1 F S K IY0'],
  "iantha": ['IY0 AA1 N TH AH0'],
  "ianthe": ['IY0 AA1 N TH EY0'],
  "ianthina": ['IY0 AH0 N TH IY1 N AH0'],
  "iason": ['IY1 AH0 S AH0 N'],
  "iavarone": ['IY0 AA2 V ER0 OW1 N IY0'],
  "ib": ['IH1 B', 'AY1 B IY1'],
  "iba": ['IY1 B AH0'],
  "ibach": ['IH1 B AA0 K'],
  "ibanez": ['IH2 B AA1 N EH0 Z'],
  "ibaraki": ['AY2 B ER0 AA1 K IY0'],
  "ibarra": ['IH2 B AA1 R AH0'],
  "ibbotson": ['IH1 B AH0 T S AH0 N'],
  "iberia": ['AY0 B IH1 R IY0 AH0'],
  "iberia's": ['AY0 B IH1 R IY0 AH0 Z'],
  "iberian": ['AY0 B IH1 R IY0 AH0 N'],
  "ibero": ['IH2 B EH1 R OW0'],
  "ibex": ['AY1 B EH0 K S'],
  "ibid": ['IH1 B IH0 D'],
  "ibis": ['AY1 B AH0 S'],
  "ibiza": ['IY2 B IY1 Z AH0'],
  "ibm": ['AY1 B IY2 EH2 M'],
  "ibmer": ['IH1 B M ER0'],
  "ibmers": ['IH1 B M ER0 Z'],
  "ibn": ['IH1 B AH0 N'],
  "ibogaine": ['AY1 B OW0 G EY0 N', 'AY1 B AH0 G EY0 N'],
  "ibrahim": ['IH2 B R AA0 HH IY1 M'],
  "ibsen": ['IH1 B S AH0 N'],
  "ibuprofen": ['AY2 B Y UW0 P R OW1 F AH0 N'],
  "icahn": ['AY1 K AA0 N'],
  "icahn's": ['AY1 K AA0 N Z'],
  "icard": ['IH2 K AA1 R D'],
  "icarus": ['IH1 K ER2 AH0 S'],
  "ice": ['AY1 S'],
  "ice-cream": ['AY1 S K R IY2 M'],
  "ice-nine": ['AY1 S N AY1 N'],
  "iceberg": ['AY1 S B ER0 G'],
  "icebergs": ['AY1 S B ER0 G Z'],
  "icebox": ['AY1 S B AA2 K S'],
  "icebreaker": ['AY1 S B R EY2 K ER0'],
  "icebreakers": ['AY1 S B R EY2 K ER0 Z'],
  "iced": ['AY1 S T'],
  "icefish": ['AY1 S F IH2 SH'],
  "iceland": ['AY1 S L AH0 N D'],
  "icelandair": ['AY2 S L AE0 N D EH1 R'],
  "icelandic": ['AY0 S L AE1 N D IH0 K'],
  "iceman": ['AY1 S M AE0 N'],
  "icenhour": ['IH1 S AH0 N AW0 R', 'AY1 S AH0 N AW0 R'],
  "icenhower": ['IH1 S AH0 N HH AW2 ER0', 'AY1 S AH0 N HH AW2 ER0'],
  "icenogle": ['IH1 S AH0 N OW2 G AH0 L', 'AY1 S AH0 N OW2 G AH0 L'],
  "ices": ['AY1 S AH0 Z', 'AY1 S IH0 Z'],
  "iceskate": ['AY1 S S K EY2 T', 'AY1 S K EY2 T'],
  "iceskating": ['AY1 S S K EY2 T IH0 NG', 'AY1 S K EY2 T IH0 NG'],
  "ich": ['IH1 CH'],
  "ichi": ['IY1 CH IY2'],
  "ichikawa": ['IH2 CH IY2 K AA1 W AA0'],
  "ichiro": ['IY1 CH IH0 R OW0'],
  "icicle": ['AY1 S IH0 K AH0 L'],
  "icicles": ['AY1 S IH0 K AH0 L Z'],
  "icily": ['AY1 S IH0 L IY0'],
  "icing": ['AY1 S IH0 NG'],
  "ickes": ['IH1 K IY0 Z', 'AY1 K IY0 Z', 'AY1 K S'],
  "ickes'": ['IH1 K AH0 S', 'IH1 K IY0 Z'],
  "icloud": ['AY1 K L AW1 D'],
  "icon": ['AY1 K AA0 N'],
  "iconic": ['AY2 K AA1 N IH0 K'],
  "iconoclasm": ['AY2 K AA1 N AH0 K L AE2 Z AH0 M'],
  "iconoclast": ['AY2 K AA1 N AH0 K L AE2 S T'],
  "iconoclastic": ['AY2 K AH0 N AH0 K L AE1 S T IH0 K'],
  "iconography": ['AY2 K AH0 N AA1 G R AH0 F IY0'],
  "icons": ['AY1 K AA2 N Z'],
  "icy": ['AY1 S IY0'],
  "id": ['IH1 D', 'AY1 D IY1'],
  "ida": ['AY1 D AH0'],
  "idaho": ['AY1 D AH0 HH OW2'],
  "idaho's": ['AY1 D AH0 HH OW2 Z'],
  "idaho-falls": ['AY1 D AH0 HH OW2 F AA1 L Z'],
  "idalia": ['IH2 D AA1 L Y AH0'],
  "idalina": ['IH2 D AA0 L IY1 N AH0'],
  "idaline": ['IH1 D AH0 L AY0 N'],
  "iddings": ['IH1 D IH0 NG Z'],
  "ide": ['AY1 D'],
  "idea": ['AY0 D IY1 AH0'],
  "idea's": ['AY0 D IY1 AH0 Z'],
  "ideal": ['AY0 D IY1 L'],
  "ideal's": ['AY0 D IY1 L Z'],
  "idealism": ['AY0 D IY1 L IH0 Z AH0 M'],
  "idealist": ['AY0 D IY1 L IH0 S T'],
  "idealistic": ['AY0 D IY2 AH0 L IH1 S T IH0 K'],
  "idealistically": ['AY0 D IY2 AH0 L IH1 S T IH0 K L IY0'],
  "idealists": ['AY0 D IY1 L IH0 S T S'],
  "idealize": ['AY0 D IY1 L AY2 Z'],
  "idealized": ['AY0 D IY1 AH0 L AY2 Z D'],
  "idealizes": ['AY0 D IY1 L AY2 Z IH0 Z'],
  "idealizing": ['AY0 D IY1 L AY2 Z IH0 NG'],
  "ideally": ['AY0 D IY1 L IY0'],
  "ideals": ['AY0 D IY1 L Z'],
  "ideas": ['AY0 D IY1 AH0 Z'],
  "ideation": ['AY2 D IY0 EY1 SH AH0 N'],
  "idec": ['AY1 D AH0 K'],
  "iden": ['AY1 D AH0 N'],
  "ident": ['AY0 D EH1 N T'],
  "identa": ['AY0 D EH1 N T AH0'],
  "identical": ['AY0 D EH1 N T IH0 K AH0 L'],
  "identics": ['AY0 D EH1 N T IH0 K S'],
  "identifiable": ['AY0 D EH1 N T AH0 F AY2 AH0 B AH0 L'],
  "identification": ['AY0 D EH2 N T AH0 F AH0 K EY1 SH AH0 N'],
  "identifications": ['AY0 D EH2 N T AH0 F AH0 K EY1 SH AH0 N Z'],
  "identified": ['AY0 D EH1 N T AH0 F AY2 D'],
  "identifier": ['AY0 D EH1 N T AH0 F AY2 ER0'],
  "identifiers": ['AY0 D EH1 N AH0 F AY2 ER0 Z'],
  "identifies": ['AY0 D EH1 N T AH0 F AY2 Z'],
  "identify": ['AY0 D EH1 N T AH0 F AY2'],
  "identifying": ['AY0 D EH1 N T AH0 F AY2 IH0 NG'],
  "identikit": ['AY0 D EH1 N T IH0 K IH2 T'],
  "identities": ['AY0 D EH1 N T IH0 T IY2 Z'],
  "identity": ['AY0 D EH1 N T IH0 T IY2'],
  "ideological": ['AY2 D IY0 AH0 L AA1 JH IH0 K AH0 L'],
  "ideologically": ['AY2 D IY0 AH0 L AA1 JH IH0 K L IY0'],
  "ideologies": ['AY2 D IY0 AA1 L AH0 JH IY0 Z'],
  "ideologist": ['AY2 D IY0 AA1 L AH0 JH AH0 S T'],
  "ideologists": ['AY2 D IY0 AA1 L AH0 JH IH0 S T S'],
  "ideologue": ['AY1 D IY0 AH0 L OW0 G'],
  "ideologues": ['AY1 D IY0 AH0 L AO0 G Z'],
  "ideology": ['AY2 D IY0 AA1 L AH0 JH IY0'],
  "ideonomy": ['AY2 D IY0 AA1 N AH0 M IY0'],
  "idette": ['AY2 D EH1 T'],
  "ididerod": ['AY0 D IH1 D ER0 AA0 D'],
  "idiocy": ['IH1 D IY0 AH0 S IY0'],
  "idiom": ['IH1 D IY0 AH0 M'],
  "idiomatic": ['IH2 D IY0 AH0 M AE1 T IH0 K'],
  "idioms": ['IH1 D IY0 AH0 M Z'],
  "idiopath": ['IH2 D IY0 OW0 P AE1 TH'],
  "idiopathic": ['IH2 D IY0 OW0 P AE1 TH AH0 K'],
  "idiosyncrasies": ['IH2 D IY0 OW0 S IH1 N K R AH0 S IY2 Z'],
  "idiosyncrasy": ['IH2 D IY0 OW0 S IH1 N K R AH0 S IY2'],
  "idiosyncratic": ['IH2 D IY0 OW0 S IH2 N K R AE1 T IH0 K'],
  "idiot": ['IH1 D IY2 AH0 T'],
  "idiotic": ['IH2 D IY0 AA1 T IH0 K'],
  "idiotically": ['IH2 D IY0 AA1 T IH0 K L IY0'],
  "idiots": ['IH1 D IY2 AH0 T S'],
  "iditarod": ['IH2 D IH1 T AH0 R AO2 D'],
  "idle": ['AY1 D AH0 L'],
  "idled": ['AY1 D AH0 L D'],
  "idleman": ['AY1 D AH0 L M AH0 N'],
  "idleness": ['AY1 D AH0 L N AH0 S'],
  "idler": ['AY1 D AH0 L ER0', 'AY1 D L ER0'],
  "idles": ['AY1 D AH0 L Z'],
  "idlewild": ['AY1 D AH0 L W AY2 L D', 'AY1 D AH0 L W AY2 L'],
  "idling": ['AY1 D AH0 L IH0 NG', 'AY1 D L IH0 NG'],
  "idly": ['AY1 D L IY0'],
  "ido": ['IY1 D OW2'],
  "idol": ['AY1 D AH0 L'],
  "idola": ['IH2 D OW1 L AH0'],
  "idolatrous": ['AY0 D AA1 L AH0 T R AH0 S'],
  "idolatry": ['AY0 D AA1 L AH0 T R IY0'],
  "idolize": ['AY1 D AH0 L AY2 Z'],
  "idolized": ['AY1 D AH0 L AY2 Z D'],
  "idolizes": ['AY1 D AH0 L AY2 Z IH0 Z'],
  "idolizing": ['AY1 D AH0 L AY2 Z IH0 NG'],
  "idols": ['AY1 D AH0 L Z'],
  "idona": ['IH2 D OW1 N AH0'],
  "idoney": ['IH2 D OW1 N IY0'],
  "idonia": ['IH2 D OW1 N IY0 AH0'],
  "idrocarburi": ['IH2 D R AA2 K AA2 R B UH1 R IY0'],
  "ids": ['AY1 D IY1 Z'],
  "iduna": ['IH2 D UW1 N AH0'],
  "idyll": ['AY1 D AH0 L'],
  "idyllic": ['AY0 D IH1 L IH0 K'],
  "ieee": ['AY2 T R IH2 P L AH0 IY1'],
  "ierardi": ['IY0 ER0 AA1 R D IY0'],
  "ierne": ['IH1 R N'],
  "iezzi": ['IY0 EH1 T S IY0'],
  "if": ['IH1 F', 'IH0 F'],
  "if's": ['IH1 F S'],
  "ifas": ['AY1 F AH0 S'],
  "iffy": ['IH1 F IY0'],
  "ifil": ['AY2 F IY1 L'],
  "ifill": ['IH0 F IY1 L'],
  "ifint": ['AY1 F IH0 N T'],
  "ifor": ['AY1 F AO2 R'],
  "ifs": ['IH1 F S'],
  "igarashi": ['IY2 G AA0 R AA1 SH IY0'],
  "ige": ['AY1 JH'],
  "igel": ['IH1 G AH0 L'],
  "igene": ['AY0 JH IY1 N'],
  "iger": ['AY1 G ER0'],
  "iglehart": ['AY1 G AH0 L HH AA0 R T'],
  "iglesia": ['IY2 G L EY1 S IY0 AH0'],
  "iglesias": ['IH2 G L EY1 S IY0 IH0 S'],
  "igloo": ['IH1 G L UW0'],
  "igman": ['IH1 G M AH0 N'],
  "ignace": ['IH1 G N AH0 S'],
  "ignacio": ['IH2 G N AA1 S IY0 OW0'],
  "ignacio's": ['IH2 G N AA1 S IY0 OW0 Z'],
  "ignasiak": ['IH2 G N AA1 S IY0 AE0 K'],
  "ignatia": ['IH2 G N AA1 SH AH0'],
  "ignatius": ['IH2 G N EY1 SH AH0 S'],
  "ignatowski": ['IH2 G N AH0 T AO1 F S K IY0'],
  "ignatz": ['IH1 G N AH0 T S'],
  "igneous": ['IH1 G N IY0 AH0 S'],
  "ignite": ['IH2 G N AY1 T'],
  "ignited": ['IH2 G N AY1 T IH0 D'],
  "ignites": ['IH2 G N AY1 T S'],
  "igniting": ['IH2 G N AY1 T IH0 NG'],
  "ignition": ['IH2 G N IH1 SH AH0 N'],
  "ignoble": ['IH2 G N OW1 B AH0 L'],
  "ignominious": ['IH2 G N AH0 M IH1 N IY0 AH0 S'],
  "ignominy": ['IH1 G N OW0 M IH2 N IY0'],
  "ignoramus": ['IH2 G N ER0 EY1 M AH0 S'],
  "ignorance": ['IH1 G N ER0 AH0 N S'],
  "ignorant": ['IH1 G N ER0 AH0 N T'],
  "ignore": ['IH2 G N AO1 R'],
  "ignored": ['IH2 G N AO1 R D'],
  "ignores": ['IH2 G N AO1 R Z'],
  "ignoring": ['IH2 G N AO1 R IH0 NG'],
  "igo": ['IY1 G OW0'],
  "igoe": ['IY1 G OW0'],
  "igon": ['AY1 G AH0 N'],
  "igor": ['IH2 G AO1 R', 'IY1 G AO2 R'],
  "igou": ['IH2 G UW1'],
  "iguana": ['AY0 G W AA1 N AH0'],
  "iguanas": ['IH2 G W AA1 N AH0 Z'],
  "iguchi": ['AH0 G UW1 CH IY0'],
  "igushi": ['IH0 G UW1 SH IY2'],
  "ihasz": ['AY1 HH AE0 S'],
  "ihde": ['IH1 D'],
  "ihle": ['AY1 HH AH0 L'],
  "ihlenfeldt": ['IH1 L IH0 N F IH0 L T'],
  "ihnen": ['IH1 N AH0 N'],
  "ihrig": ['IH1 R IH0 G'],
  "ihrke": ['IH1 R K'],
  "iiams": ['IY1 AH0 M Z'],
  "iida": ['IY1 D AH0'],
  "iie": ['IY1 IY0'],
  "ijames": ['IH0 Y AA1 M EH0 S', 'AY1 JH EY1 M Z'],
  "ijaz": ['IH1 JH AH0 Z', 'IY0 JH AA1 Z'],
  "ikaes": ['AY1 K AE0 S'],
  "ikard": ['IH1 K ER0 D'],
  "ike": ['AY1 K'],
  "ike's": ['AY1 K S'],
  "ikea": ['AY2 K IY1 AH0'],
  "ikeda": ['IH0 K EY1 D AH0'],
  "ikenberry": ['AY1 K AH0 N B EH2 R IY0'],
  "ikerd": ['IH1 K ER0 D'],
  "ikeuchi": ['IY0 K UW1 CH IY0'],
  "ikey": ['IH1 K IY0'],
  "ikie": ['IH1 K IY0'],
  "ikle": ['IH1 K AH0 L'],
  "ikner": ['IH1 K N ER0'],
  "ikon": ['AY1 K AA0 N'],
  "il": ['IH1 L'],
  "ila": ['IY1 L AH0'],
  "ilalis": ['IH2 L AE1 L AH0 S'],
  "ilalis'": ['IH2 L AE1 L AH0 S'],
  "ilalis's": ['IH2 L AE1 L AH0 S IH0 S'],
  "ilan": ['IY2 L AA1 N'],
  "ilana": ['IH2 L AA1 N AH0'],
  "ilana's": ['IH2 L AA1 N AH0 Z'],
  "ilardi": ['IH2 L AA1 R D IY0'],
  "ilbo": ['IH1 L B OW0'],
  "ileana": ['IH2 L IY0 AE1 N AH0'],
  "ilene": ['IH1 L IY0 N'],
  "iler": ['AY1 L ER0'],
  "iles": ['AY1 L Z'],
  "ileto": ['AY0 L EY1 D OW0'],
  "ilg": ['IH1 L G'],
  "ilgenfritz": ['IH1 L G IH0 N F R IH0 T S'],
  "ilhae": ['IH2 L HH EY1'],
  "iliad": ['IH1 L IY0 AH0 D'],
  "iliano": ['IH2 L IY0 AA1 N OW0'],
  "ilidza": ['IH2 L IH1 D Z AH0'],
  "iliescu": ['IH2 L IY0 EH1 S K Y UW2'],
  "iliff": ['IH1 L IH0 F'],
  "iljin": ['IH1 L JH IH0 N'],
  "ilk": ['IH1 L K'],
  "ilka": ['IH1 L K AH0'],
  "ill": ['IH1 L'],
  "illegal": ['IH2 L IY1 G AH0 L'],
  "illegalities": ['IH2 L IY0 G AE1 L IH0 T IY0 Z'],
  "illegality": ['IH2 L IY0 G AE1 L IH0 T IY0'],
  "illegally": ['IH2 L IY1 G AH0 L IY0'],
  "illegals": ['IH2 L IY1 G AH0 L Z'],
  "illegible": ['IH2 L EH1 JH AH0 B AH0 L'],
  "illegitimacy": ['IH2 L IH0 JH IH1 T AH0 M AH0 S IY0'],
  "illegitimate": ['IH2 L IH0 JH IH1 T AH0 M IH0 T'],
  "illes": ['AY1 L Z'],
  "illg": ['IH1 L G'],
  "illicit": ['IH2 L IH1 S AH0 T'],
  "illicitly": ['IH1 L IH0 S IH0 T L IY0'],
  "illig": ['IH1 L IH0 G'],
  "illina": ['IH2 L IY1 N AH0'],
  "illingworth": ['IH1 L IH0 NG W ER2 TH'],
  "illinois": ['IH2 L AH0 N OY1', 'IH2 L AH0 N OY1 Z'],
  "illinois'": ['IH1 L IH0 N OY0 Z'],
  "illinois's": ['IH2 L IH0 N OY1 Z'],
  "illiquid": ['IH2 L IH1 K W IH0 D'],
  "illiquidity": ['IH2 L IH0 K W IH1 D IH0 T IY0'],
  "illiteracy": ['IH2 L IH1 T ER0 AH0 S IY0'],
  "illiterate": ['IH2 L IH1 T ER0 AH0 T'],
  "illiterates": ['IH2 L IH1 T ER0 AH0 T S'],
  "illness": ['IH1 L N AH0 S'],
  "illnesses": ['IH1 L N AH0 S IH0 Z'],
  "illogic": ['IH2 L AA1 JH IH0 K'],
  "illogical": ['IH2 L AA1 JH IH0 K AH0 L'],
  "ills": ['IH1 L Z'],
  "illuminate": ['IH2 L UW1 M IH0 N IH0 T'],
  "illuminated": ['IH2 L UW1 M AH0 N EY2 T AH0 D'],
  "illuminates": ['IH2 L UW1 M AH0 N EY2 T S'],
  "illuminating": ['IH2 L UW1 M AH0 N EY2 T IH0 NG'],
  "illumination": ['IH2 L UW2 M AH0 N EY1 SH AH0 N'],
  "illuminator": ['IH2 L UW1 M AH0 N EY2 T ER0'],
  "illumined": ['IH2 L UW1 M AH0 N D'],
  "illusion": ['IH2 L UW1 ZH AH0 N'],
  "illusionary": ['IH2 L UW1 ZH AH0 N EH2 R IY0'],
  "illusionism": ['IH2 L UW1 ZH AH0 N IH2 Z AH0 M'],
  "illusionist": ['IH2 L UW1 ZH AH0 N AH0 S T'],
  "illusionist's": ['IH2 L UW1 ZH AH0 N AH0 S T S'],
  "illusionists": ['IH2 L UW1 ZH AH0 N AH0 S T S'],
  "illusions": ['IH2 L UW1 ZH AH0 N Z'],
  "illusive": ['IH2 L UW1 S IH0 V'],
  "illusory": ['IH2 L UW1 S ER0 IY0'],
  "illustrate": ['IH1 L AH0 S T R EY2 T'],
  "illustrated": ['IH1 L AH0 S T R EY2 T AH0 D', 'IH1 L AH0 S T R EY2 T IH0 D'],
  "illustrates": ['IH1 L AH0 S T R EY2 T S'],
  "illustrating": ['IH1 L AH0 S T R EY2 T IH0 NG'],
  "illustration": ['IH2 L AH0 S T R EY1 SH AH0 N'],
  "illustrations": ['IH2 L AH0 S T R EY1 SH AH0 N Z'],
  "illustrative": ['IH2 L AH1 S T R AH0 T IH0 V'],
  "illustrator": ['IH1 L AH0 S T R EY2 T ER0'],
  "illustrators": ['IH1 L AH0 S T R EY2 T ER0 Z'],
  "illustrious": ['IH2 L AH1 S T R IY0 AH0 S'],
  "illyrian": ['IH2 L IH1 R IY0 AH0 N'],
  "ilo": ['AY1 L OW0'],
  "ilona": ['IH2 L OW1 N AH0'],
  "ilopango": ['IY2 L OW0 P AE1 NG G OW0'],
  "ilsa": ['IH1 L S AH0'],
  "ilse": ['IH1 L S'],
  "ilsley": ['IH1 L Z L IY0'],
  "ilva": ['IH1 L V AH0'],
  "ilya": ['IH1 L Y AH0'],
  "ilyaronoff": ['IH1 L Y AA0 R AO1 N AO0 F'],
  "im": ['IH1 M'],
  "image": ['IH1 M AH0 JH', 'IH1 M IH0 JH'],
  "imageries": ['IH1 M IH0 JH R IY0 Z'],
  "imagery": ['IH1 M AH0 JH R IY0', 'IH1 M IH0 JH R IY0'],
  "images": ['IH1 M AH0 JH AH0 Z', 'IH1 M IH0 JH IH0 Z'],
  "imaginable": ['IH2 M AE1 JH AH0 N AH0 B AH0 L'],
  "imaginary": ['IH2 M AE1 JH AH0 N EH2 R IY0'],
  "imagination": ['IH2 M AE2 JH AH0 N EY1 SH AH0 N'],
  "imaginations": ['IH2 M AE2 JH AH0 N EY1 SH AH0 N Z'],
  "imaginative": ['IH2 M AE1 JH AH0 N AH0 T IH0 V'],
  "imaginatively": ['IH2 M AE1 JH AH0 N AH0 T IH2 V L IY2'],
  "imagine": ['IH2 M AE1 JH AH0 N'],
  "imagined": ['IH2 M AE1 JH AH0 N D'],
  "imagineer": ['IH2 M AE2 JH AH0 N IH1 R'],
  "imagineering": ['IH2 M AE2 JH AH0 N IH1 R IH0 NG'],
  "imagines": ['IH2 M AE1 JH AH0 N Z'],
  "imaging": ['IH1 M IH0 JH IH0 NG'],
  "imagining": ['IH2 M AE1 JH AH0 N IH0 NG'],
  "imai": ['IH2 M AY1'],
  "imam": ['AY1 M AE0 M'],
  "imamura": ['IH2 M AA2 M UH1 R AA2'],
  "iman": ['AY1 M AH0 N'],
  "imari": ['IY0 M AA1 R IY0'],
  "imasco": ['IH0 M AE1 S OW0'],
  "imax": ['AY1 M AE2 K S'],
  "imax'": ['AY1 M AE2 K S'],
  "imax's": ['AY1 M AE2 K S IH0 Z'],
  "imbalance": ['IH0 M B AE1 L AH0 N S'],
  "imbalances": ['IH0 M B AE1 L AH0 N S IH0 Z'],
  "imbecile": ['IH1 M B AH0 S AH0 L', 'IH1 M B AH0 S AY0 L'],
  "imbeciles": ['IH1 M B AH0 S AH0 L Z', 'IH1 M B AH0 S AY0 L Z'],
  "imbed": ['IH0 M B EH1 D'],
  "imbedded": ['IH0 M B EH1 D IH0 D'],
  "imbeds": ['IH0 M B EH1 D Z'],
  "imber": ['IH1 M B ER0'],
  "imbera": ['IH1 M B ER0 AH0'],
  "imbibe": ['IH0 M B AY1 B'],
  "imbibed": ['IH0 M B AY1 B D'],
  "imboden": ['IH1 M B OW0 D AH0 N'],
  "imbroglio": ['IH2 M B R OW1 L Y OW2'],
  "imbrogno": ['IH2 M B R OW1 G N OW2'],
  "imbue": ['IH2 M B Y UW1'],
  "imbued": ['IH2 M B Y UW1 D'],
  "imburgia": ['IH1 M B ER0 G IY0 AH0'],
  "imcera": ['IH2 M S EH1 R AH0'],
  "imel": ['IH1 M AH0 L'],
  "imelda": ['IH2 M EH1 L D AH0'],
  "imes": ['AY1 M Z'],
  "imhof": ['IH1 M HH AH0 F'],
  "imhoff": ['IH1 M HH AO2 F'],
  "imig": ['IH1 M IH0 G'],
  "imitable": ['IH1 M AH0 T AH0 B AH0 L'],
  "imitate": ['IH1 M AH0 T EY2 T'],
  "imitated": ['IH1 M AH0 T EY2 T IH0 D'],
  "imitates": ['IH1 M AH0 T EY2 T S'],
  "imitating": ['IH1 M AH0 T EY2 T IH0 NG'],
  "imitation": ['IH2 M AH0 T EY1 SH AH0 N'],
  "imitations": ['IH2 M IH0 T EY1 SH AH0 N Z'],
  "imitative": ['IH1 M AH0 T EY2 T IH0 V'],
  "imitator": ['IH1 M AH0 T EY2 T ER0'],
  "imitators": ['IH1 M IH0 T EY2 T ER0 Z'],
  "imlay": ['IH2 M L EY1'],
  "imler": ['IH1 M L ER0'],
  "imm": ['IH1 M'],
  "immaculate": ['IH2 M AE1 K Y UW0 L IH0 T'],
  "immaculately": ['IH2 M AE1 K Y AH0 L AH0 T L IY0'],
  "immanence": ['IH1 M AH0 N AH0 N S'],
  "immanent": ['IH1 M AH0 N AH0 N T'],
  "immanuel": ['IH1 M AH0 N UH0 L'],
  "immaterial": ['IH2 M AH0 T IH1 R IY0 AH0 L'],
  "immature": ['IH2 M AH0 T Y UH1 R'],
  "immaturity": ['IH2 M AH0 CH UH1 R IH0 T IY0'],
  "immeasurable": ['IH2 M EH1 ZH ER0 AE2 B AH0 L'],
  "immeasurably": ['IH2 M EH1 ZH ER0 AE2 B L IY0'],
  "immediacy": ['IH2 M IY1 D IY2 AH0 S IY0'],
  "immediate": ['IH2 M IY1 D IY2 AH0 T'],
  "immediately": ['IH2 M IY1 D IY2 AH0 T L IY0'],
  "immel": ['IH1 M AH0 L'],
  "immemorial": ['IH2 M AH0 M AO1 R IY0 AH0 L'],
  "immense": ['IH2 M EH1 N S'],
  "immensely": ['IH2 M EH1 N S L IY0'],
  "immerman": ['IH1 M ER0 M AH0 N'],
  "immerse": ['IH2 M ER1 S'],
  "immersed": ['IH2 M ER1 S T'],
  "immersion": ['IH2 M ER1 ZH AH0 N'],
  "immigrant": ['IH1 M AH0 G R AH0 N T'],
  "immigrant's": ['IH1 M AH0 G R AH0 N T S'],
  "immigrants": ['IH1 M AH0 G R AH0 N T S'],
  "immigrants'": ['IH1 M IH0 G R AH0 N T S'],
  "immigrate": ['IH1 M AH0 G R EY2 T'],
  "immigrated": ['IH1 M AH0 G R EY2 T IH0 D'],
  "immigration": ['IH2 M AH0 G R EY1 SH AH0 N'],
  "imminence": ['IH1 M AH0 N AH0 N S'],
  "imminent": ['IH1 M AH0 N AH0 N T'],
  "imminently": ['IH1 M AH0 N AH0 N T L IY0'],
  "immiscible": ['IH2 M IH1 S AH0 B AH0 L'],
  "immobile": ['IH2 M OW1 B AH0 L', 'IH2 M OW1 B AY2 L', 'IH2 M OW1 B IY2 L'],
  "immobility": ['IH2 M OW0 B IH1 L IH0 T IY0'],
  "immobilize": ['IH2 M OW1 B AH0 L AY2 Z'],
  "immobilized": ['IH2 M OW1 B AH0 L AY2 Z D'],
  "immobilizing": ['IH2 M OW1 B AH0 L AY2 Z IH0 NG'],
  "immolate": ['IH1 M AH0 L EY2 T'],
  "immolated": ['IH1 M AH0 L EY2 T AH0 D'],
  "immolates": ['IH1 M AH0 L EY2 T S'],
  "immolating": ['IH1 M AH0 L EY2 T IH0 NG'],
  "immolation": ['IH1 M AH0 L EY2 SH AH0 N'],
  "immoral": ['IH2 M AO1 R AH0 L'],
  "immorality": ['IH2 M ER0 AE1 L IH0 T IY0'],
  "immortal": ['IH2 M AO1 R T AH0 L'],
  "immortality": ['IH2 M AO0 R T AE1 L IH0 T IY0'],
  "immortalize": ['IH2 M AO1 R T AH0 L AY0 Z'],
  "immortalized": ['IH2 M AO1 R T AH0 L AY0 Z D'],
  "immortalizes": ['IH2 M AO1 R T AH0 L AY0 Z IH0 Z'],
  "immortalizing": ['IH2 M AO1 R T AH0 L AY0 Z IH0 NG'],
  "immortals": ['IH2 M AO1 R T AH0 L Z'],
  "immotile": ['IH2 M OW1 T AH0 L'],
  "immovable": ['IH2 M UW1 V AH0 B AH0 L'],
  "immu": ['IH1 M Y UW0'],
  "immulogic": ['IH1 M Y UW0 L AA2 JH IH0 K'],
  "immune": ['IH2 M Y UW1 N'],
  "immunetech": ['IH1 M Y UW0 N T EH2 K'],
  "immunex": ['IH1 M Y UW0 N EH0 K S'],
  "immunities": ['IH2 M Y UW1 N IH0 T IY0 Z'],
  "immunity": ['IH2 M Y UW1 N AH0 T IY0', 'IH2 M Y UW1 N IH0 T IY0'],
  "immunization": ['IH2 M Y UW0 N AH0 Z EY1 SH AH0 N'],
  "immunizations": ['IH2 M Y UW0 N AH0 Z EY1 SH AH0 N Z'],
  "immunize": ['IH1 M Y UW0 N AY2 Z'],
  "immunized": ['IH1 M Y AH0 N AY2 Z D'],
  "immunizes": ['IH1 M Y AH0 N AY2 Z IH0 Z'],
  "immunizing": ['IH1 M Y AH0 N AY2 Z IH0 NG'],
  "immuno": ['IH2 M Y UW1 N OW0'],
  "immunodeficiency": ['IH2 M Y UW0 N OW0 D IH2 F IH1 SH AH0 N S IY0'],
  "immunological": ['IH2 M Y UW0 N AH0 L AA1 JH IH0 K AH0 L'],
  "immunologist": ['IH2 M Y UW0 N AA1 L AH0 JH IH0 S T'],
  "immunology": ['IH2 M Y UW0 N AA1 L AH0 JH IY0'],
  "immunomedic": ['IH1 M Y UW0 N OW0 M EH2 D IH0 K'],
  "immunomedics": ['IH1 M Y UW0 N OW0 M EH2 D IH0 K S'],
  "immunoperoxidase": ['IH1 M Y UW0 N OW0 P EH0 R AO1 K S IH0 D EY2 Z'],
  "immunotherapy": ['IH2 M Y UW0 N OW0 TH EH1 R AH0 P IY0'],
  "immutable": ['IH2 M Y UW1 T AH0 B AH0 L'],
  "imnet": ['IH1 M N EH2 T'],
  "imo": ['AY1 M OW0', 'AY1 EH1 M OW1'],
  "imogen": ['IH1 M AH0 G AH0 N'],
  "imogene": ['IH1 M AH0 JH IY2 N'],
  "imondi": ['IH2 M OW1 N D IY2', 'IH2 M AA1 N D IY2'],
  "imp": ['IH1 M P'],
  "impact": ['IH2 M P AE1 K T', 'IH1 M P AE0 K T'],
  "impacted": ['IH1 M P AE2 K T IH0 D', 'IH2 M P AE1 K T IH0 D'],
  "impacting": ['IH2 M P AE1 K T IH0 NG'],
  "impacts": ['IH2 M P AE1 K T S', 'IH1 M P AE0 K T S', 'IH0 M P AE1 K S', 'IH1 M P AE0 K S'],
  "impair": ['IH2 M P EH1 R'],
  "impaired": ['IH2 M P EH1 R D'],
  "impairing": ['IH2 M P EH1 R IH0 NG'],
  "impairment": ['IH2 M P EH1 R M AH0 N T'],
  "impairments": ['IH2 M P EH1 R M AH0 N T S'],
  "impairs": ['IH2 M P EH1 R Z'],
  "impala": ['IH2 M P AA1 L AH0'],
  "impale": ['IH2 M P EY1 L'],
  "impaled": ['IH2 M P EY1 L D'],
  "impales": ['IH2 M P EY1 L Z'],
  "impaling": ['IH2 M P EY1 L IH0 NG'],
  "impanel": ['IH2 M P AE1 N AH0 L'],
  "impaneled": ['IH2 M P AE1 N AH0 L D'],
  "impart": ['IH2 M P AA1 R T'],
  "imparted": ['IH2 M P AA1 R T IH0 D'],
  "impartial": ['IH2 M P AA1 R SH AH0 L'],
  "impartiality": ['IH2 M P AA2 R SH IY0 AE1 L IH0 T IY0'],
  "impartially": ['IH2 M P AA1 R SH AH0 L IY0'],
  "imparting": ['IH2 M P AA1 R T IH0 NG'],
  "imparts": ['IH2 M P AA1 R T S'],
  "impassable": ['IH2 M P AE1 S AH0 B AH0 L'],
  "impasse": ['IH2 M P AE1 S', 'IH1 M P AE2 S'],
  "impassion": ['IH2 M P AE1 SH AH0 N'],
  "impassioned": ['IH2 M P AE1 SH AH0 N D'],
  "impassive": ['IH2 M P AE1 S IH0 V'],
  "impassively": ['IH2 M P AE1 S IH0 V L IY0'],
  "impastato": ['IH2 M P AA0 S T AA1 T OW0'],
  "impatience": ['IH2 M P EY1 SH AH0 N S'],
  "impatiens": ['IH2 M P EY1 SH AH0 N Z'],
  "impatient": ['IH2 M P EY1 SH AH0 N T'],
  "impatiently": ['IH2 M P EY1 SH AH0 N T L IY0'],
  "impeach": ['IH2 M P IY1 CH'],
  "impeachable": ['IH2 M P IY1 CH AH0 B AH0 L'],
  "impeached": ['IH2 M P IY1 CH T'],
  "impeaches": ['IH2 M P IY1 CH AH0 Z'],
  "impeaching": ['IH2 M P IY1 CH IH0 NG'],
  "impeachment": ['IH2 M P IY1 CH M AH0 N T'],
  "impeachments": ['IH2 M P IY1 CH M AH0 N T S'],
  "impeccable": ['IH2 M P EH1 K AH0 B AH0 L'],
  "impeccably": ['IH2 M P EH1 K AH0 B L IY0'],
  "impedance": ['IH2 M P IY1 D AH0 N S'],
  "impede": ['IH2 M P IY1 D'],
  "impeded": ['IH2 M P IY1 D IH0 D'],
  "impedes": ['IH2 M P IY1 D Z'],
  "impediment": ['IH2 M P EH1 D AH0 M AH0 N T'],
  "impediments": ['IH2 M P EH1 D AH0 M AH0 N T S'],
  "impeding": ['IH2 M P IY1 D IH0 NG'],
  "impel": ['IH2 M P EH1 L'],
  "impelled": ['IH2 M P EH1 L D'],
  "impend": ['IH2 M P EH1 N D'],
  "impending": ['IH2 M P EH1 N D IH0 NG'],
  "impenetrable": ['IH2 M P EH1 N AH0 T R AH0 B AH0 L'],
  "imperative": ['IH2 M P EH1 R AH0 T IH0 V'],
  "imperatives": ['IH2 M P EH1 R AH0 T IH0 V Z'],
  "imperato": ['IH2 M P ER0 AA1 T OW0'],
  "imperceptible": ['IH2 M P ER0 S EH1 P T IH0 B AH0 L'],
  "imperceptibly": ['IH2 M P ER0 S EH1 P T IH0 B L IY0'],
  "imperfect": ['IH2 M P ER1 F IH0 K T'],
  "imperfection": ['IH2 M P ER0 F EH1 K SH AH0 N'],
  "imperfections": ['IH2 M P ER0 F EH1 K SH AH0 N Z'],
  "imperfectly": ['IH2 M P ER1 F IH0 K T L IY0'],
  "imperia": ['IH2 M P IY1 R IY0 AH0'],
  "imperial": ['IH2 M P IH1 R IY0 AH0 L'],
  "imperial's": ['IH2 M P IH1 R IY0 AH0 L Z'],
  "imperiale": ['IH2 M P ER0 IY0 AA1 L IY0'],
  "imperialism": ['IH2 M P IH1 R IY0 AH0 L IH2 Z AH0 M'],
  "imperialist": ['IH2 M P IH1 R IY0 AH0 L IH0 S T'],
  "imperialistic": ['IH2 M P IY2 R IY0 AH0 L IH1 S T IH0 K'],
  "imperialists": ['IH2 M P IH1 R IY0 AH0 L IH0 S T S'],
  "imperil": ['IH2 M P EH1 R AH0 L'],
  "imperiled": ['IH2 M P EH1 R AH0 L D'],
  "imperiling": ['IH2 M P EH1 R AH0 L IH0 NG'],
  "imperils": ['IH2 M P EH1 R AH0 L Z'],
  "imperious": ['IH2 M P IH1 R IY0 AH0 S'],
  "impermissible": ['IH2 M P ER0 M IH1 S IH0 B AH0 L'],
  "impersonal": ['IH2 M P ER1 S AH0 N AH0 L'],
  "impersonality": ['IH2 M P ER2 S AH0 N AE1 L AH0 T IY0'],
  "impersonate": ['IH2 M P ER1 S AH0 N EY2 T'],
  "impersonated": ['IH2 M P ER1 S AH0 N EY2 T IH0 D'],
  "impersonates": ['IH2 M P ER1 S AH0 N EY2 T S'],
  "impersonating": ['IH2 M P ER1 S AH0 N EY2 T IH0 NG'],
  "impersonation": ['IH2 M P ER0 S AH0 N EY1 SH AH0 N'],
  "impersonator": ['IH2 M P ER1 S AH0 N EY0 T ER0'],
  "impersonators": ['IH2 M P ER1 S AH0 N EY2 T ER0 Z'],
  "impertinent": ['IH2 M P ER1 T AH0 N AH0 N T'],
  "impervious": ['IH2 M P ER1 V IY0 AH0 S'],
  "impetuous": ['IH2 M P EH1 CH W AH0 S'],
  "impetus": ['IH1 M P AH0 T AH0 S'],
  "impinge": ['IH2 M P IH1 N JH'],
  "impinged": ['IH2 M P IH1 N JH D'],
  "impinges": ['IH2 M P IH1 N JH IH0 Z'],
  "impinging": ['IH2 M P IH1 N JH IH0 NG'],
  "impious": ['IH2 M P AY1 AH0 S'],
  "impish": ['IH1 M P IH0 SH'],
  "implacable": ['IH2 M P L AE1 K AH0 B AH0 L'],
  "implant": ['IH2 M P L AE1 N T', 'IH1 M P L AE2 N T'],
  "implantable": ['IH1 M P L AE2 N T AH0 B AH0 L'],
  "implantation": ['IH2 M P L AE0 N T EY1 SH AH0 N'],
  "implanted": ['IH2 M P L AE1 N T IH0 D'],
  "implanting": ['IH2 M P L AE1 N T IH0 NG'],
  "implants": ['IH2 M P L AE1 N T S', 'IH1 M P L AE2 N T S'],
  "implausible": ['IH2 M P L AO1 Z AH0 B AH0 L'],
  "implausibly": ['IH2 M P L AO1 Z AH0 B L IY0'],
  "implement": ['IH1 M P L AH0 M AH0 N T'],
  "implementation": ['IH2 M P L AH0 M EH0 N T EY1 SH AH0 N'],
  "implementations": ['IH2 M P L AH0 M EH0 N T EY1 SH AH0 N Z'],
  "implemented": ['IH1 M P L AH0 M EH2 N T AH0 D', 'IH1 M P L AH0 M EH2 N AH0 D'],
  "implementing": ['IH1 M P L AH0 M EH2 N T IH0 NG', 'IH1 M P L AH0 M EH2 N IH0 NG'],
  "implements": ['IH1 M P L AH0 M AH0 N T S'],
  "implicate": ['IH1 M P L IH0 K EY2 T'],
  "implicated": ['IH1 M P L IH0 K EY2 T IH0 D'],
  "implicates": ['IH1 M P L IH0 K EY2 T S'],
  "implicating": ['IH1 M P L IH0 K EY2 T IH0 NG'],
  "implication": ['IH2 M P L AH0 K EY1 SH AH0 N'],
  "implications": ['IH2 M P L AH0 K EY1 SH AH0 N Z'],
  "implicit": ['IH2 M P L IH1 S AH0 T'],
  "implicitly": ['IH2 M P L IH1 S AH0 T L IY0'],
  "implied": ['IH2 M P L AY1 D'],
  "implies": ['IH2 M P L AY1 Z'],
  "implode": ['IH2 M P L OW1 D'],
  "imploded": ['IH2 M P L OW1 D IH0 D'],
  "implodes": ['IH2 M P L OW1 D Z'],
  "imploding": ['IH2 M P L OW1 D IH0 NG'],
  "implore": ['IH2 M P L AO1 R'],
  "implored": ['IH2 M P L AO1 R D'],
  "implores": ['IH2 M P L AO1 R Z'],
  "imploring": ['IH2 M P L AO1 R IH0 NG'],
  "implosion": ['IH2 M P L OW1 ZH AH0 N'],
  "imply": ['IH2 M P L AY1'],
  "implying": ['IH2 M P L AY1 IH0 NG'],
  "impolite": ['IH2 M P AH0 L AY1 T'],
  "impolitic": ['IH2 M P AO0 L IH1 T IH2 K'],
  "imponderable": ['IH2 M P AA1 N D ER0 AH0 B AH0 L'],
  "imponderables": ['IH2 M P AA1 N D ER0 AH0 B AH0 L Z'],
  "import": ['IH2 M P AO1 R T', 'IH1 M P AO2 R T'],
  "importance": ['IH2 M P AO1 R T AH0 N S'],
  "important": ['IH2 M P AO1 R T AH0 N T'],
  "importantly": ['IH2 M P AO1 R T AH0 N T L IY0'],
  "importation": ['IH2 M P AO0 R T EY1 SH AH0 N'],
  "imported": ['IH2 M P AO1 R T IH0 D'],
  "importer": ['IH2 M P AO1 R T ER0'],
  "importers": ['IH2 M P AO1 R T ER0 Z'],
  "importers'": ['IH2 M P AO1 R T ER0 Z'],
  "importing": ['IH2 M P AO1 R T IH0 NG'],
  "imports": ['IH2 M P AO1 R T S', 'IH1 M P AO2 R T S'],
  "imports'": ['IH1 M P AO0 R T S'],
  "impose": ['IH2 M P OW1 Z'],
  "imposed": ['IH2 M P OW1 Z D'],
  "imposes": ['IH0 M P OW1 Z AH0 Z', 'IH0 M P OW1 Z IH0 Z'],
  "imposing": ['IH2 M P OW1 Z IH0 NG'],
  "imposition": ['IH2 M P AH0 Z IH1 SH AH0 N'],
  "impossibility": ['IH2 M P AO2 S IH0 B IH1 L IH0 T IY0'],
  "impossible": ['IH2 M P AA1 S AH0 B AH0 L'],
  "impossible's": ['IH2 M P AA1 S AH0 B AH0 L Z'],
  "impossibly": ['IH2 M P AA1 S AH0 B L IY0'],
  "imposter": ['IH2 M P AO1 S T ER0'],
  "impostor": ['IH2 M P AO1 S T ER0'],
  "impostors": ['IH2 M P AO1 S T ER0 Z'],
  "impotence": ['IH1 M P AH0 T AH0 N S'],
  "impotent": ['IH1 M P AH0 T AH0 N T'],
  "impound": ['IH2 M P AW1 N D'],
  "impounded": ['IH2 M P AW1 N D IH0 D'],
  "impounding": ['IH2 M P AW1 N D IH0 NG'],
  "impoundment": ['IH2 M P AW1 N D M AH0 N T'],
  "impoundments": ['IH2 M P AW1 N D M AH0 N T S'],
  "impounds": ['IH2 M P AW1 N D Z'],
  "impoverish": ['IH2 M P AA1 V R IH0 SH'],
  "impoverished": ['IH2 M P AA1 V R IH0 SH T'],
  "impoverishes": ['IH2 M P AA1 V R IH0 SH AH0 Z'],
  "impoverishing": ['IH2 M P AA1 V R IH0 SH IH0 NG'],
  "impoverishment": ['IH2 M P AA1 V R IH0 SH M AH0 N T'],
  "impracticable": ['IH2 M P R AE1 K T IH0 K AH0 B AH0 L'],
  "impractical": ['IH2 M P R AE1 K T AH0 K AH0 L', 'IH2 M P R AE1 K T IH0 K AH0 L'],
  "imprecise": ['IH1 M P R AH0 S AY2 S'],
  "impregnable": ['IH2 M P R EH1 G N AH0 B AH0 L'],
  "impregnate": ['IH2 M P R EH1 G N EY2 T'],
  "impregnated": ['IH2 M P R EH1 G N EY2 T AH0 D'],
  "impregnates": ['IH2 M P R EH1 G N EY2 T S'],
  "impregnating": ['IH2 M P R EH1 G N EY2 T IH0 NG'],
  "impregnation": ['IH2 M P R EH1 G N EY1 SH AH0 N'],
  "impresario": ['IH2 M P R IH0 S AA1 R IY0 OW2'],
  "impress": ['IH2 M P R EH1 S', 'IH1 M P R EH2 S'],
  "impressed": ['IH2 M P R EH1 S T'],
  "impresses": ['IH2 M P R EH1 S IH0 Z'],
  "impressing": ['IH2 M P R EH1 S IH0 NG'],
  "impression": ['IH2 M P R EH1 SH AH0 N'],
  "impressionable": ['IH2 M P R EH1 SH AH0 N AH0 B AH0 L'],
  "impressionism": ['IH2 M P R EH1 SH AH0 N IH2 Z AH0 M'],
  "impressionist": ['IH2 M P R EH1 SH AH0 N AH0 S T', 'IH2 M P R EH1 SH AH0 N IH0 S T'],
  "impressionistic": ['IH2 M P R EH2 SH AH0 N IH1 S T IH0 K'],
  "impressionists": ['IH2 M P R EH1 SH AH0 N IH0 S T S'],
  "impressions": ['IH2 M P R EH1 SH AH0 N Z'],
  "impressive": ['IH2 M P R EH1 S IH0 V'],
  "impressively": ['IH2 M P R EH1 S IH0 V L IY0'],
  "impressment": ['IH2 M P R EH1 S M AH0 N T'],
  "imprimatur": ['IH2 M P R IH0 M AA1 T ER0'],
  "imprimis": ['IH2 M P R IY1 M IH0 S'],
  "imprint": ['IH2 M P R IH1 N T', 'IH1 M P R IH0 N T'],
  "imprinted": ['IH2 M P R IH1 N T IH0 D', 'IH2 M P R IH1 N IH0 D'],
  "imprinting": ['IH2 M P R IH1 N T IH0 NG', 'IH2 M P R IH1 N IH0 NG'],
  "imprints": ['IH2 M P R IH1 N T S'],
  "imprison": ['IH2 M P R IH1 Z AH0 N'],
  "imprisoned": ['IH2 M P R IH1 Z AH0 N D'],
  "imprisoning": ['IH2 M P R IH1 Z AH0 N IH0 NG'],
  "imprisonment": ['IH2 M P R IH1 Z AH0 N M AH0 N T'],
  "imprisons": ['IH2 M P R IH1 Z AH0 N Z'],
  "improbable": ['IH2 M P R AA1 B AH0 B AH0 L'],
  "improbably": ['IH2 M P R AA1 B AH0 B L IY0'],
  "impromptu": ['IH2 M P R AA1 M P T UW0'],
  "improper": ['IH2 M P R AA1 P ER0'],
  "improperly": ['IH2 M P R AA1 P ER0 L IY0'],
  "improprieties": ['IH2 M P R AH0 P R AY1 AH0 T IY0 Z'],
  "impropriety": ['IH2 M P R AH0 P R AY1 AH0 T IY0'],
  "improv": ['IH1 M P R AA2 V'],
  "improve": ['IH2 M P R UW1 V'],
  "improved": ['IH2 M P R UW1 V D'],
  "improvement": ['IH2 M P R UW1 V M AH0 N T'],
  "improvements": ['IH2 M P R UW1 V M AH0 N T S'],
  "improves": ['IH2 M P R UW1 V Z'],
  "improving": ['IH2 M P R UW1 V IH0 NG'],
  "improvisation": ['IH2 M P R AA0 V IH0 Z EY1 SH AH0 N'],
  "improvisational": ['IH2 M P R AA0 V IH0 Z EY1 SH AH0 N AH0 L'],
  "improvisations": ['IH2 M P R AA0 V IH0 Z EY1 SH AH0 N Z'],
  "improvise": ['IH1 M P R AH0 V AY2 Z', 'IH2 M P R AH0 V AY1 Z'],
  "improvised": ['IH1 M P R AH0 V AY2 Z D'],
  "improvises": ['IH1 M P R AH0 V AY2 Z AH0 Z', 'IH2 M P R AH0 V AY1 Z AH0 Z'],
  "improvising": ['IH1 M P R AH0 V AY2 Z IH0 NG'],
  "imprudence": ['IH2 M P R UW1 D AH0 N S'],
  "imprudent": ['IH2 M P R UW1 D AH0 N T'],
  "imprudently": ['IH2 M P R UW1 D AH0 N T L IY0'],
  "impson": ['IH1 M P S AH0 N'],
  "impugn": ['IH2 M P Y UW1 N'],
  "impugned": ['IH2 M P Y UW1 N D'],
  "impugning": ['IH2 M P Y UW1 N IH0 NG'],
  "impugns": ['IH2 M P Y UW1 N Z'],
  "impulse": ['IH1 M P AH0 L S', 'IH2 M P AH1 L S'],
  "impulses": ['IH1 M P AH0 L S IH0 Z', 'IH2 M P AH1 L S IH0 Z'],
  "impulsive": ['IH2 M P AH1 L S IH0 V'],
  "impulsively": ['IH2 M P AH1 L S IH0 V L IY0'],
  "impunity": ['IH2 M P Y UW1 N IH0 T IY0'],
  "impure": ['IH2 M P Y UH1 R'],
  "impurities": ['IH2 M P Y UH1 R AH0 T IY0 Z'],
  "impurity": ['IH2 M P Y UH1 R AH0 T IY0'],
  "imputation": ['IH2 M P Y AH0 T EY1 SH AH0 N'],
  "impute": ['IH2 M P Y UW1 T'],
  "imputed": ['IH2 M P Y UW1 T IH0 D'],
  "imre": ['IH1 M R AH0'],
  "imreg": ['IH2 M R EH1 G'],
  "imreg's": ['IH2 M R EH1 G Z'],
  "imrie": ['IH1 M ER0 IY0'],
  "imus": ['AY1 M AH0 S'],
  "in": ['IH0 N', 'IH1 N'],
  "in's": ['IH0 N Z', 'IH1 N Z'],
  "in-app": ['IH0 N AE1 P'],
  "in-depth": ['IH0 N D EH1 P TH'],
  "in-house": ['IH0 N HH AW1 S'],
  "in-law": ['IH0 N L AO1'],
  "in-laws": ['IH0 N L AO1 Z'],
  "in-memory": ['IH0 N M EH1 M ER0 IY0'],
  "in-person": ['IH0 N P ER1 S AH0 N'],
  "in.": ['IH1 N', 'IH1 N CH'],
  "ina": ['IY1 N AH0'],
  "inability": ['IH2 N AH0 B IH1 L IH0 T IY0'],
  "inabinet": ['IH2 N AH0 B IH1 N IH0 T'],
  "inaccessibility": ['IH2 N AH0 K S EH2 S AH0 B IH1 L AH0 T IY0'],
  "inaccessible": ['IH2 N AH0 K S EH1 S AH0 B AH0 L'],
  "inaccuracies": ['IH2 N AE1 K Y ER0 AE2 S IY0 Z'],
  "inaccuracy": ['IH2 N AE1 K Y ER0 AH0 S IY0'],
  "inaccurate": ['IH2 N AE1 K Y ER0 AH0 T'],
  "inaccurately": ['IH2 N AE1 K Y ER0 AH0 T L IY0'],
  "inacom": ['IH1 N AH0 K AA2 M', 'AY1 N AH0 K AA2 M'],
  "inacomp": ['AY1 N AH0 K AA2 M P', 'IH1 N AH0 K AA2 M P'],
  "inaction": ['IH2 N AE1 K SH AH0 N'],
  "inactivate": ['IH2 N AE1 K T IH0 V EY2 T'],
  "inactivated": ['IH2 N AE1 K T IH0 V EY2 T IH0 D'],
  "inactivates": ['IH2 N AE1 K T IH0 V EY2 T S'],
  "inactivation": ['IH2 N AE2 K T IH0 V EY1 SH AH0 N'],
  "inactive": ['IH2 N AE1 K T IH0 V'],
  "inactivity": ['IH2 N AE0 K T IH1 V IH0 T IY0'],
  "inadequacies": ['IH2 N AE1 D AH0 K W AH0 S IY0 Z'],
  "inadequacy": ['IH2 N AE1 D IH0 K W AH0 S IY0'],
  "inadequate": ['IH2 N AE1 D AH0 K W AH0 T', 'IH2 N AE1 D AH0 K W EY2 T'],
  "inadequately": ['IH2 N AE1 D AH0 K W AH0 T L IY0'],
  "inadmissible": ['IH2 N AH0 D M IH1 S AH0 B AH0 L', 'IH2 N AE0 D M IH1 S AH0 B AH0 L'],
  "inadmissibly": ['IH2 N AH0 D M IH1 S AH0 B L IY0', 'IH2 N AE0 D M IH1 S AH0 B L IY0'],
  "inadvertence": ['IH2 N AH0 D V ER1 T AH0 N S', 'IH2 N AE0 D V ER1 T AH0 N S'],
  "inadvertent": ['IH2 N AH0 D V ER1 T AH0 N T', 'IH2 N AE0 D V ER1 T AH0 N T'],
  "inadvertently": ['IH2 N AH0 D V ER1 T AH0 N T L IY0', 'IH2 N AE0 D V ER1 T AH0 N T L IY0'],
  "inadvisable": ['IH2 N AH0 D V AY1 Z AH0 B AH0 L', 'IH2 N AE0 D V AY1 Z AH0 B AH0 L'],
  "inaez": ['IH2 N AE1 Z', 'IH2 N EY1 Z'],
  "inaki": ['IH2 N AA1 K IY0'],
  "inalienable": ['IH2 N EY1 L Y AH0 N AH0 B AH0 L'],
  "inamura": ['IH2 N AH0 M UH1 R AH0'],
  "inane": ['IH2 N EY1 N'],
  "inanimate": ['IH2 N AE1 N AH0 M AH0 T'],
  "inapplicable": ['IH2 N AE1 P L IH0 K AH0 B AH0 L'],
  "inappropriate": ['IH2 N AH0 P R OW1 P R IY0 IH0 T'],
  "inappropriately": ['IH2 N AH0 P R AA1 P R IY0 AH0 T L IY0'],
  "inarticulate": ['IH2 N AA0 R T IH1 K Y AH0 L AH0 T'],
  "inasmuch": ['IH2 N AE1 S M AH0 K'],
  "inattention": ['IH2 N AH0 T EH1 N CH AH0 N'],
  "inattentive": ['IH2 N AH0 T EH1 N T IH0 V'],
  "inaudible": ['IH2 N AO1 D AH0 B AH0 L'],
  "inaudibly": ['IH2 N AO1 D AH0 B L IY0'],
  "inaugural": ['IH2 N AO1 G ER0 AH0 L', 'IH2 N AO1 G Y ER0 AH0 L'],
  "inaugurate": ['IH0 N AO1 G Y ER0 IH0 T', 'IH0 N AO1 G Y ER0 EY0 T'],
  "inaugurated": ['IH0 N AO1 G ER0 EY2 T IH0 D', 'IH0 N AO1 G Y ER0 EY2 T IH0 D'],
  "inaugurates": ['IH0 N AO1 G Y ER0 IH0 T S'],
  "inaugurating": ['IH0 N AO1 G Y ER0 EY2 T IH0 NG'],
  "inauguration": ['IH0 N AO2 G Y ER0 EY1 SH AH0 N'],
  "inaugurations": ['IH0 N AO2 G Y ER0 EY1 SH AH0 N Z'],
  "inauspicious": ['IH2 N AW2 S P IH1 SH IH0 S'],
  "inbio": ['IH2 N B AY1 OW0'],
  "inboard": ['IH1 N B AO2 R D'],
  "inboden": ['IH1 N B OW0 D AH0 N'],
  "inbody": ['IH1 N B AA0 D IY0'],
  "inborn": ['IH1 N B AO2 R N'],
  "inbound": ['IH2 N B AW1 N D', 'IH1 N B AW0 N D'],
  "inbox": ['IH1 N B AA2 K S'],
  "inbred": ['IH1 N B R EH2 D'],
  "inbreed": ['IH1 N B R IY2 D'],
  "inbreeding": ['IH2 N B R IY1 D IH0 NG'],
  "inc": ['IH1 NG K'],
  "inc.": ['IH1 NG K', 'IH0 NG K AO1 R P AO0 R EY0 T AH0 D'],
  "inc.'s": ['IH1 NG K S'],
  "inca": ['IH1 NG K AH0'],
  "incalculable": ['IH2 N K AE1 L K Y AH0 L AH0 B AH0 L'],
  "incandescent": ['IH2 N K AH0 N D EH1 S AH0 N T'],
  "incant": ['IH2 N K AE1 N T'],
  "incantation": ['IH2 N K AE1 N T EY1 SH AH0 N'],
  "incantatory": ['IH2 N K AE1 N T AH0 T AO2 R IY0'],
  "incapable": ['IH2 N K EY1 P AH0 B AH0 L'],
  "incapacitate": ['IH2 N K AH0 P AE1 S IH0 T EY2 T'],
  "incapacitated": ['IH2 N K AH0 P AE1 S IH0 T EY2 T IH0 D'],
  "incapacitating": ['IH2 N K AH0 P AE1 S IH0 T EY2 T IH0 NG'],
  "incapacitation": ['IH2 N K AH0 P AE2 S IH0 T EY1 SH AH0 N'],
  "incapacity": ['IH2 N K AH0 P AE1 S AH0 T IY0'],
  "incarcerate": ['IH2 N K AA1 R S ER0 EY2 T'],
  "incarcerated": ['IH2 N K AA1 R S ER0 EY2 T IH0 D'],
  "incarcerates": ['IH2 N K AA1 R S ER0 EY2 T S'],
  "incarcerating": ['IH2 N K AA1 R S ER0 EY2 T IH0 NG'],
  "incarceration": ['IH2 N K AA2 R S ER0 EY1 SH AH0 N'],
  "incarnate": ['IH2 N K AA1 R N AH0 T', 'IH2 N K AA1 R N EY2 T'],
  "incarnation": ['IH2 N K AA1 R N EY1 SH AH0 N'],
  "incarnations": ['IH2 N K AA0 R N EY1 SH AH0 N Z'],
  "incas": ['IH1 NG K AH0 Z'],
  "incase": ['IH2 N K EY1 S'],
  "incata": ['IH2 NG K AA1 T AH0'],
  "incata's": ['IH2 NG K AA1 T AH0 Z'],
  "ince": ['IH1 N S'],
  "incendiary": ['IH2 N S EH1 N D IY0 EH0 R IY0'],
  "incense": ['IH2 N S EH1 N S', 'IH1 N S EH2 N S'],
  "incensed": ['IH1 N S EH2 N S T'],
  "incentive": ['IH2 N S EH1 N T IH0 V', 'IH2 N S EH1 N IH0 V'],
  "incentives": ['IH2 N S EH1 N T IH0 V Z', 'IH2 N S IH1 N IH0 V Z'],
  "inception": ['IH2 N S EH1 P SH AH0 N'],
  "incessant": ['IH2 N S EH1 S AH0 N T'],
  "incessantly": ['IH2 N S EH1 S AH0 N T L IY0'],
  "incest": ['IH1 N S EH2 S T'],
  "incestuous": ['IH2 N S EH1 S CH W AH0 S'],
  "inch": ['IH1 N CH'],
  "inchcape": ['IH1 N CH K EY2 P'],
  "inched": ['IH1 N CH T'],
  "incheon": ['IH1 N CH AO0 N'],
  "inches": ['IH1 N CH AH0 Z', 'IH1 N CH IH0 Z'],
  "inches'": ['IH1 N CH AH0 Z', 'IH1 N CH IH0 Z'],
  "inching": ['IH1 N CH IH0 NG'],
  "inchoate": ['IH2 N K OW1 AH0 T'],
  "inchon": ['IH1 N CH AO0 N'],
  "incidence": ['IH1 N S AH0 D AH0 N S', 'IH1 N S IH0 D AH0 N S'],
  "incidences": ['IH1 N S AH0 D AH0 N S IH0 Z'],
  "incident": ['IH1 N S AH0 D AH0 N T'],
  "incidental": ['IH2 N S IH0 D EH1 N T AH0 L'],
  "incidentally": ['IH2 N S IH0 D EH1 N T AH0 L IY0', 'IH2 N S IH0 D EH1 N T L IY0'],
  "incidentals": ['IH2 N S IH0 D EH1 N T AH0 L Z'],
  "incidents": ['IH1 N S AH0 D AH0 N T S'],
  "incinerate": ['IH2 N S IH1 N ER0 EY2 T'],
  "incinerated": ['IH2 N S IH1 N ER0 EY2 T IH0 D'],
  "incinerating": ['IH2 N S IH1 N ER0 EY2 T IH0 NG'],
  "incineration": ['IH2 N S IH1 N ER0 EY2 SH AH0 N'],
  "incinerator": ['IH2 N S IH1 N ER0 EY2 T ER0'],
  "incinerators": ['IH2 N S IH1 N ER0 EY2 T ER0 Z'],
  "incipient": ['IH2 N S IH1 P IY0 AH0 N T'],
  "incirlik": ['IH2 N S ER1 L IH0 K'],
  "incise": ['IH2 N S AY1 Z'],
  "incised": ['IH2 N S AY1 Z D'],
  "incision": ['IH2 N S IH1 ZH AH0 N'],
  "incisions": ['IH2 N S IH1 ZH AH0 N Z'],
  "incisive": ['IH2 N S AY1 S IH0 V'],
  "incisor": ['IH2 N S AY1 Z ER0'],
  "incisors": ['IH2 N S AY1 Z ER0 Z'],
  "incite": ['IH2 N S AY1 T'],
  "incited": ['IH2 N S AY1 T IH0 D'],
  "incitement": ['IH2 N S AY1 T M AH0 N T'],
  "incitements": ['IH2 N S AY1 T M AH0 N T S'],
  "incites": ['IH2 N S AY1 T S'],
  "inciting": ['IH2 N S AY1 T IH0 NG'],
  "incivility": ['IH2 N S IH0 V IH1 L IH0 T IY0'],
  "inclement": ['IH2 N K L EH1 M AH0 N T', 'IH1 N K L IH0 M AH0 N T'],
  "inclination": ['IH2 N K L AH0 N EY1 SH AH0 N'],
  "inclinations": ['IH2 N K L AH0 N EY1 SH AH0 N Z'],
  "incline": ['IH2 N K L AY1 N', 'IH1 N K L AY0 N'],
  "inclined": ['IH2 N K L AY1 N D'],
  "inclines": ['IH2 N K L AY1 N Z', 'IH1 N K L AY0 N Z'],
  "inclosure": ['IH2 N K L OW1 ZH ER0'],
  "include": ['IH2 N K L UW1 D'],
  "included": ['IH2 N K L UW1 D AH0 D', 'IH2 N K L UW1 D IH0 D'],
  "includes": ['IH2 N K L UW1 D Z'],
  "including": ['IH2 N K L UW1 D IH0 NG'],
  "inclusion": ['IH2 N K L UW1 ZH AH0 N'],
  "inclusions": ['IH2 N K L UW1 ZH AH0 N Z'],
  "inclusive": ['IH2 N K L UW1 S IH0 V'],
  "inclusiveness": ['IH2 N K L UW1 S IH0 V N IH0 S', 'IH2 N K L UW1 S IH0 V N EH0 S'],
  "inco": ['IH2 N K OW1'],
  "inco's": ['IH1 NG K OW0 Z'],
  "incognito": ['IH2 N K AO0 G N IY1 T OW0'],
  "incoherence": ['IH2 N K OW0 HH IH1 R AH0 N S'],
  "incoherent": ['IH2 N K OW0 HH IH1 R AH0 N T'],
  "incoherently": ['IH2 N K OW0 HH IH1 R AH0 N T L IY0'],
  "incom": ['IH1 NG K AA0 M'],
  "income": ['IH1 N K AH2 M'],
  "incomes": ['IH1 N K AH2 M Z'],
  "incoming": ['IH1 N K AH2 M IH0 NG'],
  "incommunicado": ['IH2 N K AH0 M Y UW2 N AH0 K AA1 D OW0'],
  "incomparable": ['IH2 N K AA1 M P ER0 AH0 B AH0 L'],
  "incomparably": ['IH2 N K AA1 M P ER0 AH0 B L IY0'],
  "incompatibility": ['IH2 N K AA2 M P AH0 T IH0 B IH1 L IH0 T IY0'],
  "incompatible": ['IH2 N K AH0 M P AE1 T AH0 B AH0 L'],
  "incompetence": ['IH2 N K AA1 M P AH0 T AH0 N S'],
  "incompetency": ['IH2 N K AA1 M P AH0 T AH0 N S IY0'],
  "incompetent": ['IH2 N K AA1 M P AH0 T AH0 N T'],
  "incompetently": ['IH2 N K AA1 M P AH0 T AH0 N T L IY0'],
  "incompetents": ['IH2 NG K AA1 M P AH0 T AH0 N T S'],
  "incomplete": ['IH2 N K AH0 M P L IY1 T'],
  "incomprehensible": ['IH2 NG K AA2 M P R AH0 HH EH1 N S IH0 B AH0 L'],
  "incomprehensibly": ['IH2 NG K AA2 M P R AH0 HH EH1 N S IH0 B L IY0'],
  "incompressible": ['IH2 N K AH0 M P R EH1 S AH0 B AH0 L'],
  "inconceivable": ['IH2 N K AH0 N S IY1 V AH0 B AH0 L'],
  "inconclusive": ['IH2 N K AH0 N K L UW1 S IH0 V'],
  "inconclusively": ['IH2 NG K AA1 N K L UW0 S IH0 V L IY0'],
  "incongruity": ['IH2 NG K AO0 NG R UW1 IH0 T IY0'],
  "incongruous": ['IH2 NG K AO1 NG R UW0 AH0 S'],
  "incongruously": ['IH2 NG K AO1 NG R UW0 AH0 S L IY0'],
  "inconsequential": ['IH2 NG K AA2 N S AH0 K W EH1 N CH AH0 L'],
  "inconsistencies": ['IH2 NG K AA1 N S IH0 S T EH2 N S IY0 Z'],
  "inconsistency": ['IH2 N K AH0 N S IH1 S T AH0 N S IY0'],
  "inconsistent": ['IH2 N K AH0 N S IH1 S T AH0 N T'],
  "inconspicuous": ['IH2 NG K AA1 N S P IH0 K W AH0 S'],
  "inconstancy": ['IH2 N K AA1 N S T AH0 N S IY0'],
  "incontinence": ['IH2 N K AA1 N T AH0 N AH0 N S'],
  "incontinent": ['IH2 N K AA1 N T AH0 N AH0 N T'],
  "incontrovertible": ['IH2 NG K AA2 N T R OW0 V ER1 T IH0 B AH0 L'],
  "inconvenience": ['IH2 N K AH0 N V IY1 N Y AH0 N S'],
  "inconvenienced": ['IH2 N K AH0 N V IY1 N Y AH0 N S T'],
  "inconveniences": ['IH2 N K AH0 N V IY1 N Y AH0 N S IH0 Z'],
  "inconvenient": ['IH2 N K AH0 N V IY1 N Y AH0 N T'],
  "incoordination": ['IH2 N K OW2 AO1 R D AH0 N EY2 SH AH0 N'],
  "incorporate": ['IH2 N K AO1 R P ER0 EY2 T'],
  "incorporated": ['IH2 N K AO1 R P ER0 EY2 T IH0 D', 'IH0 N K AO1 R P ER0 EY2 T IH0 D'],
  "incorporated's": ['IH2 N K AO1 R P ER0 EY2 T IH0 D Z'],
  "incorporates": ['IH2 N K AO1 R P ER0 EY2 T S'],
  "incorporating": ['IH2 N K AO1 R P ER0 EY2 T IH0 NG'],
  "incorporation": ['IH2 N K AO2 R P ER0 EY1 SH AH0 N'],
  "incorporation's": ['IH2 N K AO2 R P ER0 EY1 SH AH0 N Z'],
  "incorporations": ['IH2 N K AO2 R P ER0 EY1 SH AH0 N Z'],
  "incorrect": ['IH2 N K ER0 EH1 K T'],
  "incorrectly": ['IH2 N K ER0 EH1 K T L IY0'],
  "incorrigible": ['IH2 N K AA1 R AH0 JH AH0 B AH0 L'],
  "incorvaia": ['IH2 N K AO0 R V AA1 Y AH0'],
  "increase": ['IH2 N K R IY1 S', 'IH1 N K R IY2 S'],
  "increased": ['IH2 N K R IY1 S T', 'IH1 N K R IY2 S T'],
  "increases": ['IH0 N K R IY1 S AH0 Z', 'IH1 N K R IY2 S IH0 Z', 'IH1 N K R IY0 S AH0 Z'],
  "increasing": ['IH2 N K R IY1 S IH0 NG'],
  "increasingly": ['IH0 N K R IY1 S IH0 NG L IY0', 'IH0 N K R IY1 S IH0 NG G L IY0'],
  "incredible": ['IH2 N K R EH1 D AH0 B AH0 L'],
  "incredibly": ['IH2 N K R EH1 D AH0 B L IY0'],
  "incredulity": ['IH2 N K R AH0 D UW1 L IH0 T IY0'],
  "incredulous": ['IH2 N K R EH1 JH AH0 L AH0 S'],
  "increment": ['IH1 N K R AH0 M AH0 N T'],
  "incremental": ['IH2 N K R AH0 M EH1 N T AH0 L'],
  "incrementalism": ['IH2 N K R AH0 M EH1 N T AH0 L IH2 Z M'],
  "incrementally": ['IH2 N K R AH0 M EH1 N T AH0 L IY2'],
  "incremented": ['IH1 N K R AH0 M EH2 N T IH0 D'],
  "increments": ['IH1 NG K R AH0 M AH0 N T S'],
  "incriminate": ['IH2 N K R IH1 M AH0 N EY2 T'],
  "incriminating": ['IH2 N K R IH1 M AH0 N EY2 T IH0 NG'],
  "incrimination": ['IH2 N K R IH2 M AH0 N EY1 SH AH0 N'],
  "incrust": ['IH2 N K R AH1 S T'],
  "incrustation": ['IH2 N K R AH0 S T EY1 SH AH0 N'],
  "incstar": ['IH1 NG K S T AA2 R'],
  "incubate": ['IH1 N K Y AH0 B EY2 T'],
  "incubates": ['IH1 N K Y AH0 B EY2 T S'],
  "incubating": ['IH1 N K Y AH0 B EY2 T IH0 NG'],
  "incubation": ['IH2 NG K Y UW0 B EY1 SH AH0 N'],
  "incubator": ['IH1 NG K Y AH0 B EY2 T ER0'],
  "incubators": ['IH1 NG K Y UW0 B EY2 T ER0 Z'],
  "inculcate": ['IH1 NG K AH0 L K EY2 T'],
  "inculcated": ['IH1 NG K AH0 L K EY2 T AH0 D'],
  "inculcates": ['IH1 NG K AH0 L K EY2 T S'],
  "incumbency": ['IH2 N K AH1 M B AH0 N S IY0'],
  "incumbent": ['IH2 N K AH1 M B AH0 N T'],
  "incumbent's": ['IH2 N K AH1 M B AH0 N T S'],
  "incumbents": ['IH2 N K AH1 M B AH0 N T S'],
  "incur": ['IH2 N K ER1'],
  "incurable": ['IH2 N K Y UH1 R AH0 B AH0 L'],
  "incurred": ['IH2 N K ER1 D'],
  "incurring": ['IH2 N K ER1 IH0 NG'],
  "incurs": ['IH2 N K ER1 Z'],
  "incursion": ['IH2 N K ER1 ZH AH0 N'],
  "incursions": ['IH2 N K ER1 ZH AH0 N Z'],
  "inda": ['IY1 N D AH0'],
  "indaba": ['IH2 N D AA1 B AH0'],
  "indal": ['IH1 N D AH0 L'],
  "indata": ['IH2 N D AA1 T AH0'],
  "indebted": ['IH2 N D EH1 T IH0 D', 'IH0 N D EH1 T IH0 D'],
  "indebtedness": ['IH2 N D EH1 T IH0 D N IH0 S'],
  "indecency": ['IH2 N D IY1 S AH0 N S IY0'],
  "indecent": ['IH2 N D IY1 S AH0 N T'],
  "indecision": ['IH2 N D IH0 S IH1 ZH AH0 N'],
  "indecisive": ['IH2 N D IH0 S AY1 S IH0 V'],
  "indecisiveness": ['IH2 N D EH1 S IH0 S IH0 V N AH0 S'],
  "indeed": ['IH2 N D IY1 D'],
  "indefatigable": ['IH2 N D IH0 F AE1 T IH0 G AH0 B AH0 L'],
  "indefeasible": ['IH2 D AH0 F IY1 Z AH0 B AH0 L'],
  "indefensible": ['IH2 N D IH0 F EH1 N S AH0 B AH0 L'],
  "indefinable": ['IH2 N D IH0 F AY1 N AH0 B AH0 L'],
  "indefinite": ['IH2 N D EH1 F AH0 N AH0 T'],
  "indefinitely": ['IH2 N D EH1 F AH0 N AH0 T L IY0'],
  "indelible": ['IH2 N D EH1 L IH0 B AH0 L'],
  "indelibly": ['IH2 N D EH1 L AH0 B L IY0'],
  "indelicate": ['IH2 N D EH1 L IH0 K AH0 T'],
  "indelicato": ['IH2 N D EH0 L IY0 K AA1 T OW0'],
  "indemnification": ['IH2 N D EH2 M N AH0 F IH0 K EY1 SH AH0 N'],
  "indemnified": ['IH2 N D EH1 M N AH0 F AY2 D'],
  "indemnify": ['IH2 N D EH1 M N AH0 F AY2'],
  "indemnifying": ['IH2 N D EH2 M N IH0 F AY1 IH0 NG'],
  "indemnities": ['IH2 N D EH1 M N IH0 T IY0 Z'],
  "indemnity": ['IH2 N D EH1 M N AH0 T IY0', 'IH2 N D EH1 M N IH0 T IY0'],
  "indent": ['IH2 N D EH1 N T'],
  "indentation": ['IH2 N D EH2 N T EY1 SH AH0 N'],
  "indenture": ['IH2 N D EH1 N CH ER0'],
  "indentured": ['IH2 N D EH1 N CH ER0 D'],
  "indentures": ['IH2 N D EH1 N CH ER0 Z'],
  "independence": ['IH2 N D IH0 P EH1 N D AH0 N S'],
  "independence's": ['IH2 N D IH0 P EH1 N D AH0 N S IH0 Z'],
  "independent": ['IH2 N D IH0 P EH1 N D AH0 N T'],
  "independently": ['IH2 N D IH0 P EH1 N D AH0 N T L IY0'],
  "independents": ['IH2 N D IH0 P EH1 N D AH0 N T S'],
  "inderal": ['IH1 N D ER0 AH0 L'],
  "indescribable": ['IH2 N D IH0 S K R AY1 B AH0 B AH0 L'],
  "indestructibility": ['IH2 N D AH0 S T R AH2 K T IH0 B IH1 L IH0 T IY0'],
  "indestructible": ['IH2 N D AH0 S T R AH1 K T IH0 B AH0 L'],
  "indeterminate": ['IH2 N D IH0 T ER1 M IH0 N IH0 T'],
  "index": ['IH1 N D EH0 K S'],
  "index's": ['IH1 N D EH0 K S IH0 Z'],
  "indexation": ['IH1 N D EH2 K S EY1 SH AH0 N'],
  "indexed": ['IH1 N D EH0 K S T'],
  "indexer": ['IH1 N D EH2 K S ER0'],
  "indexers": ['IH1 N D EH2 K S ER0 Z'],
  "indexes": ['IH1 N D EH0 K S IH0 Z'],
  "indexing": ['IH1 N D EH0 K S IH0 NG'],
  "india": ['IH1 N D IY0 AH0'],
  "india's": ['IH1 N D IY0 AH0 Z'],
  "indiaia": ['IH2 N D IY0 AY1 AH0'],
  "indian": ['IH1 N D IY0 AH0 N'],
  "indian's": ['IH1 N D IY0 AH0 N Z'],
  "indiana": ['IH2 N D IY0 AE1 N AH0'],
  "indiana's": ['IH2 N D IY0 AE1 N AH0 Z'],
  "indianapolis": ['IH2 N D IY0 AH0 N AE1 P AH0 L IH0 S'],
  "indianapolis's": ['IH2 N D IY0 AH0 N AE1 P AH0 L IH0 S IH0 Z'],
  "indianian": ['IH2 N D IY0 AE1 N IY0 AH0 N'],
  "indianians": ['IH2 N D IY0 AE1 N IY0 AH0 N Z'],
  "indianola": ['IH1 N D IY0 AH0 N OW1 L AH0'],
  "indians": ['IH1 N D IY0 AH0 N Z'],
  "indians'": ['IH1 N D IY0 AH0 N Z'],
  "indic": ['IH1 N D IH0 K'],
  "indica": ['IH1 D AH0 K AH0'],
  "indicate": ['IH1 N D AH0 K EY2 T'],
  "indicated": ['IH1 N D AH0 K EY2 T AH0 D', 'IH1 N D AH0 K EY2 T IH0 D'],
  "indicates": ['IH1 N D IH0 K EY2 T S'],
  "indicating": ['IH1 N D AH0 K EY2 T IH0 NG'],
  "indication": ['IH2 N D AH0 K EY1 SH AH0 N'],
  "indications": ['IH2 N D AH0 K EY1 SH AH0 N Z'],
  "indicative": ['IH2 N D IH1 K AH0 T IH0 V'],
  "indicator": ['IH1 N D AH0 K EY2 T ER0'],
  "indicators": ['IH1 N D AH0 K EY2 T ER0 Z'],
  "indices": ['IH1 N D IH0 S IY2 Z', 'IH1 N D AH0 S IH0 Z'],
  "indicia": ['IH2 N D IH1 S Y AH0'],
  "indict": ['IH2 N D AY1 T'],
  "indicted": ['IH2 N D AY1 T IH0 D'],
  "indicting": ['IH2 N D AY1 T IH0 NG'],
  "indictment": ['IH2 N D AY1 T M AH0 N T'],
  "indictments": ['IH2 N D AY1 T M AH0 N T S'],
  "indie": ['IH1 N D IY0'],
  "indies": ['IH1 N D IY0 Z'],
  "indifference": ['IH2 N D IH1 F ER0 AH0 N S', 'IH2 N D IH1 F R AH0 N S'],
  "indifferent": ['IH2 N D IH1 F R AH0 N T', 'IH2 N D IH1 F ER0 AH0 N T'],
  "indigenous": ['IH2 N D IH1 JH AH0 N AH0 S'],
  "indigenously": ['IH2 N D IH1 JH AH0 N AH0 S L IY2'],
  "indigent": ['IH1 N D IH0 JH AH0 N T'],
  "indigents": ['IH1 N D IH0 JH AH0 N T S'],
  "indigest": ['IH2 N D AY0 JH EH1 S T', 'IH2 N D AH0 JH EH1 S T'],
  "indigestion": ['IH2 N D AY0 JH EH1 S CH AH0 N'],
  "indignant": ['IH2 N D IH1 G N AH0 N T'],
  "indignantly": ['IH2 N D IH1 G N AH0 N T L IY2'],
  "indignation": ['IH2 N D IH0 G N EY1 SH AH0 N'],
  "indignities": ['IH2 N D IH1 G N AH0 T IY0 Z'],
  "indignity": ['IH2 N D IH1 G N AH0 T IY2'],
  "indigo": ['IH1 N D AH0 G OW2', 'IH1 N D IH0 G OW2'],
  "indira": ['IH2 N D IH1 R AH0'],
  "indirect": ['IH2 N D ER0 EH1 K T'],
  "indirectly": ['IH2 N D ER0 EH1 K T L IY2'],
  "indiscreet": ['IH2 N D IH0 S K R IY1 T'],
  "indiscretion": ['IH2 N D IH0 S K R EH1 SH AH0 N'],
  "indiscretions": ['IH2 N D IH0 S K R EH1 SH AH0 N Z'],
  "indiscriminate": ['IH2 N D IH0 S K R IH1 M AH0 N AH0 T'],
  "indiscriminately": ['IH2 N D IH0 S K R IH1 M AH0 N AH0 T L IY2'],
  "indispensable": ['IH2 N D IH0 S P EH1 N S AH0 B AH0 L'],
  "indispensible": ['IH2 N D IH0 S P EH1 N S IH0 B AH0 L'],
  "indisputable": ['IH2 N D IH0 S P Y UW1 T AH0 B AH0 L'],
  "indisputably": ['IH2 N D IH1 S P Y UW0 T AE2 B L IY2'],
  "indistinct": ['IH2 N D IH0 S T IH1 NG K T'],
  "indistinguishable": ['IH2 N D IH0 S T IH1 NG G W IH0 SH AH0 B AH0 L'],
  "indite": ['IH2 N D AY1 T'],
  "indited": ['IH2 N D AY1 T IH0 D'],
  "indium": ['IH1 N D IY0 AH0 M'],
  "individual": ['IH2 N D AH0 V IH1 JH AH0 W AH0 L'],
  "individual's": ['IH2 N D AH0 V IH1 JH AH0 W AH0 L Z'],
  "individualism": ['IH2 N D IH0 V IH0 D UW1 AH0 L IH2 Z AH0 M'],
  "individualist": ['IH2 N D IH0 V IH0 D UW1 AH0 L IH0 S T'],
  "individualistic": ['IH2 N D IH0 V IH2 JH UW0 AH0 L IH1 S T IH0 K'],
  "individualists": ['IH2 N D AH0 V IH1 JH UW0 AH0 L IH0 S T S'],
  "individuality": ['IH2 N D IH2 V IH0 JH UW0 AE1 L IH0 T IY0'],
  "individualize": ['IH2 N D IH0 V IH1 JH UW0 AH0 L AY0 Z', 'IH2 N D IH0 V IH1 JH AH0 L AY0 Z'],
  "individualized": ['IH2 N D IH0 V IH1 JH UW0 AH0 L AY0 Z D', 'IH2 N D IH0 V IH1 JH AH0 L AY0 Z D'],
  "individually": ['IH2 N D IH0 V IH1 JH UW0 AH0 L IY0', 'IH2 N D IH0 V IH1 JH AH0 L IY0'],
  "individuals": ['IH2 N D AH0 V IH1 JH AH0 W AH0 L Z'],
  "individuals'": ['IH2 N D IH0 V IH1 JH AH0 W AH0 L Z'],
  "indivisible": ['IH2 N D IH0 V IH1 S IH0 B AH0 L'],
  "indo": ['IH1 N D OW0'],
  "indo-european": ['IH2 N D OW0 Y UH2 R AH0 P IY1 AH0 N'],
  "indochina": ['IH2 N D OW0 CH AY1 N AH0'],
  "indochinese": ['IH2 N D OW0 CH AY2 N IY1 Z'],
  "indoctrinate": ['IH0 N D AA1 K T R AH0 N EY2 T'],
  "indoctrinated": ['IH2 N D AA1 K T R AH0 N EY2 T IH0 D'],
  "indoctrination": ['IH2 N D AA2 K T R AH0 N EY1 SH AH0 N'],
  "indolent": ['IH1 N D AH0 L AH0 N T'],
  "indomitable": ['IH2 N D AA1 M AH0 T AH0 B AH0 L'],
  "indonesia": ['IH2 N D OW0 N IY1 ZH AH0'],
  "indonesia's": ['IH2 N D OW0 N IY1 ZH AH0 Z'],
  "indonesian": ['IH2 N D OW0 N IY1 ZH AH0 N'],
  "indonesians": ['IH2 N D OW0 N IY1 S IY0 AH0 N Z', 'IH2 N D OW0 N IY1 ZH AH0 N Z'],
  "indoor": ['IH1 N D AO2 R'],
  "indoors": ['IH1 N D AO2 R Z'],
  "indosuez": ['IH1 N D OW0 S UW0 EY1 Z', 'IH1 N D OW0 S UW0 EH0 Z'],
  "indovina": ['IH2 N D OW0 V IY1 N AH0'],
  "indri": ['IH2 N D R IY1'],
  "indubious": ['IH2 D UW1 B IY0 AH0 S'],
  "indubiously": ['IH2 D UW1 B IY0 AH0 S L IY0'],
  "induce": ['IH2 N D UW1 S'],
  "induced": ['IH2 N D UW1 S T'],
  "inducement": ['IH2 N D UW1 S M AH0 N T'],
  "inducements": ['IH2 N D UW1 S M AH0 N T S'],
  "induces": ['IH2 N D UW1 S IH0 Z'],
  "inducing": ['IH2 N D UW1 S IH0 NG'],
  "induct": ['IH2 N D AH1 K T'],
  "inductance": ['IH2 N D AH1 K T AH0 N S'],
  "inducted": ['IH2 N D AH1 K T IH0 D'],
  "inductee": ['IH2 N D AH1 K T IY1'],
  "inductees": ['IH2 N D AH1 K T IY1 Z'],
  "induction": ['IH2 N D AH1 K SH AH0 N'],
  "inductive": ['IH2 N D AH1 K T IH0 V'],
  "inductor": ['IH2 N D AH1 K T ER0'],
  "indulge": ['IH2 N D AH1 L JH'],
  "indulged": ['IH2 N D AH1 L JH D'],
  "indulgence": ['IH2 N D AH1 L JH AH0 N S'],
  "indulgences": ['IH0 N D AH1 L JH AH0 N S AH0 Z', 'IH0 N D AH1 L JH AH0 N S IH0 Z'],
  "indulgent": ['IH2 N D AH1 L JH AH0 N T'],
  "indulges": ['IH2 N D AH1 L JH IH0 Z'],
  "indulging": ['IH2 N D AH1 L JH IH0 NG'],
  "indus": ['IH1 N D AH0 S'],
  "industri": ['IH2 N D AH1 S T R IY0'],
  "industria": ['IH2 N D AH1 S T R IY0 AH0'],
  "industrial": ['IH2 N D AH1 S T R IY0 AH0 L'],
  "industrial's": ['IH2 N D AH1 S T R IY0 AH0 L Z'],
  "industriale": ['IH2 N D AH0 S T R IY0 AA1 L IY0'],
  "industriali": ['IH2 N D AH2 S T R IY0 AA1 L IY0'],
  "industrialist": ['IH2 N D AH1 S T R IY0 AH0 L IH0 S T'],
  "industrialists": ['IH2 N D AH1 S T R IY0 AH0 L IH0 S T S'],
  "industrialization": ['IH2 N D AH2 S T R IY0 AH0 L IH0 Z EY1 SH AH0 N'],
  "industrialize": ['IH2 N D AH1 S T R IY0 AH0 L AY2 Z'],
  "industrialized": ['IH2 N D AH1 S T R IY0 AH0 L AY2 Z D'],
  "industrializing": ['IH2 N D AH1 S T R IY0 AH0 L AY2 Z IH0 NG'],
  "industrially": ['IH2 N D AH1 S T R IY0 AH0 L IY0'],
  "industrials": ['IH2 N D AH1 S T R IY0 AH0 L Z'],
  "industrials'": ['IH2 N D AH1 S T R IY0 AH0 L Z'],
  "industrie": ['IH1 N D AH0 S T R IY0'],
  "industrielle": ['IH2 N D AH2 S T R IY0 EH1 L', 'IH2 N D AH1 S T R IY0 AH0 L EH2'],
  "industrier": ['IH1 N D AH0 S T R IY2 ER0'],
  "industries": ['IH1 N D AH0 S T R IY0 Z'],
  "industries'": ['IH1 N D AH0 S T R IY0 Z'],
  "industrious": ['IH2 N D AH1 S T R IY0 AH0 S'],
  "industrivaerden": ['IH1 N D AH0 S T R IY0 V EH2 R D AH0 N'],
  "industry": ['IH1 N D AH0 S T R IY0'],
  "industry's": ['IH1 N D AH0 S T R IY0 Z'],
  "industrywide": ['IH1 N D AH0 S T R IY0 W AY2 D'],
  "indy": ['IH1 N D IY0'],
  "indyk": ['IH1 N D IH0 K'],
  "inebriate": ['IH2 N EH1 B R IY0 EY2 T'],
  "inebriated": ['IH2 N EH1 B R IY0 EY2 T AH0 D'],
  "inebriates": ['IH2 N EH1 B R IY0 EY2 T S'],
  "inebriating": ['IH2 N EH1 B R IY0 EY2 T IH0 NG'],
  "inedible": ['IH2 N EH1 D AH0 B AH0 L'],
  "ineffable": ['IH2 N EH1 F AH0 B AH0 L'],
  "ineffective": ['IH2 N IH0 F EH1 K T IH0 V'],
  "ineffectiveness": ['IH2 N AH0 F EH1 K T IH0 V N AH0 S'],
  "ineffectual": ['IH2 N AH0 F EH1 K CH UW0 AH0 L'],
  "inefficiencies": ['IH2 N IH0 F IH1 SH AH0 N S IY0 Z'],
  "inefficiency": ['IH2 N IH0 F IH1 SH AH0 N S IY0'],
  "inefficient": ['IH2 N IH0 F IH1 SH AH0 N T'],
  "inefficiently": ['IH2 N IH0 F IH1 SH AH0 N T L IY0'],
  "inelastic": ['IH2 N AH0 L AE1 S T IH0 K'],
  "ineligible": ['IH2 N EH1 L IH0 JH AH0 B AH0 L'],
  "ineluctible": ['IH2 N AH0 L AH1 K T AH0 B AH0 L'],
  "inept": ['IH2 N EH1 P T'],
  "ineptitude": ['IH2 N EH1 P T IH0 T UW2 D'],
  "ineptly": ['IH2 N EH1 P T L IY0'],
  "ineptness": ['IH2 N EH1 P T N IH0 S'],
  "inequalities": ['IH2 N AH0 K W AA1 L IH0 T IY0 Z', 'IH2 N IH0 K W AA1 L IH0 T IY0 Z', 'IH1 N IY0 K W AA1 L IH0 T IY0 Z'],
  "inequality": ['IH2 N IH0 K W AA1 L AH0 T IY0', 'IH2 N AH0 K W AA1 L AH0 T IY0', 'IH1 N IY0 K W AA1 L AH0 T IY0'],
  "inequitable": ['IH2 N EH1 K W AH0 T AH0 B AH0 L'],
  "inequities": ['IH2 N EH1 K W AH0 T IY0 Z'],
  "inequity": ['IH2 N EH1 K W AH0 T IY0'],
  "ineradicable": ['IH2 N ER0 AE1 D AH0 K AH2 B AH0 L'],
  "inerco": ['IH2 N ER1 K OW0'],
  "inert": ['IH2 N ER1 T'],
  "inertia": ['IH2 N ER1 SH AH0'],
  "inertial": ['IH2 N ER1 SH AH0 L'],
  "inertness": ['IH2 N ER1 T N AH0 S'],
  "ines": ['IH1 N IH0 S', 'IH2 N EH1 S'],
  "inescapable": ['IH2 N IH0 S K EY1 P AH0 B AH0 L'],
  "inescapably": ['IH2 N IH0 S K EY1 P AH0 B L IY0'],
  "inevitability": ['IH2 N EH2 V IH0 T AH0 B IH1 L IH0 T IY0'],
  "inevitable": ['IH2 N EH1 V AH0 T AH0 B AH0 L'],
  "inevitably": ['IH2 N EH1 V AH0 T AH0 B L IY0'],
  "inexact": ['IH2 N IH0 G Z AE1 K T'],
  "inexcusable": ['IH2 N IH0 K S K Y UW1 Z AH0 B AH0 L'],
  "inexcusably": ['IH2 N IH0 K S K Y UW1 Z AH0 B L IY0'],
  "inexhaustible": ['IH2 N IH0 G Z AO1 S T AH0 B AH0 L'],
  "inexorable": ['IH2 N EH1 K S ER0 AH0 B AH0 L'],
  "inexorably": ['IH2 N EH1 K S ER0 AH0 B L IY0'],
  "inexpensive": ['IH2 N IH0 K S P EH1 N S IH0 V'],
  "inexpensively": ['IH2 N AH0 K S P EH1 N S IH0 V L IY0'],
  "inexperience": ['IH2 N IH0 K S P IH1 R IY0 AH0 N S'],
  "inexperienced": ['IH0 N IH0 K S P IH1 R IY0 AH0 N S T'],
  "inexplicable": ['IH2 N AH0 K S P L IH1 S AH0 B AH0 L'],
  "inexplicably": ['IH2 N IH0 K S P L IH1 K AH0 B L IY0'],
  "inextricable": ['IH2 N EH1 K S T R IH0 K AH2 B AH0 L'],
  "inextricably": ['IH2 N EH1 K S T R IH0 K AH2 B L IY0'],
  "inez": ['IH2 N EH1 Z'],
  "infallibility": ['IH2 N F AE2 L IH0 B IH1 L IH0 T IY0'],
  "infallible": ['IH2 N F AE1 L AH0 B AH0 L'],
  "infamous": ['IH1 N F AH0 M AH0 S'],
  "infamy": ['IH1 N F AH0 M IY0'],
  "infancy": ['IH1 N F AH0 N S IY0'],
  "infant": ['IH1 N F AH0 N T'],
  "infant's": ['IH1 N F AH0 N T S'],
  "infante": ['IH2 N F AA1 N T EY2'],
  "infanticide": ['IH2 N F AE1 N T AH0 S AY2 D'],
  "infanticides": ['IH2 N F AE1 N T AH0 S AY2 D Z'],
  "infantile": ['IH1 N F AH0 N T IH0 L'],
  "infantino": ['IH2 N F AA0 N T IY1 N OW2'],
  "infantry": ['IH1 N F AH0 N T R IY0'],
  "infantryman": ['IH1 N F AE0 N T R IY0 M AH0 N'],
  "infantrymen": ['IH1 N F AE0 N T R IY0 M AH0 N'],
  "infants": ['IH1 N F AH0 N T S'],
  "infants'": ['IH1 N F AH0 N T S'],
  "infarction": ['IH2 N F AA1 R K SH AH0 N'],
  "infatuate": ['IH2 N F AE1 CH UW0 EY2 T'],
  "infatuated": ['IH2 N F AE1 CH UW0 EY2 T IH0 D'],
  "infatuates": ['IH2 N F AE1 CH UW0 EY2 T S'],
  "infatuating": ['IH2 N F AE1 CH UW0 EY2 T IH0 NG'],
  "infatuation": ['IH2 N F AE2 CH UW0 EY1 SH AH0 N'],
  "infeasible": ['IH2 N F IY1 Z AH0 B AH0 L'],
  "infect": ['IH2 N F EH1 K T'],
  "infected": ['IH2 N F EH1 K T IH0 D'],
  "infecting": ['IH2 N F EH1 K T IH0 NG'],
  "infection": ['IH2 N F EH1 K SH AH0 N'],
  "infections": ['IH2 N F EH1 K SH AH0 N Z'],
  "infectious": ['IH2 N F EH1 K SH AH0 S'],
  "infective": ['IH2 N F EH1 K T IH0 V'],
  "infects": ['IH2 N F EH1 K T S'],
  "infer": ['IH2 N F ER1'],
  "inference": ['IH1 N F ER0 AH0 N S'],
  "inferences": ['IH1 N F ER0 EH2 N S IH0 Z'],
  "inferential": ['IH2 N F ER0 EH1 N SH AH0 L'],
  "inferior": ['IH2 N F IH1 R IY0 ER0'],
  "inferiority": ['IH2 N F IH0 R IY0 AO1 R IH0 T IY2'],
  "infernal": ['IH2 N F ER1 N AH0 L'],
  "inferno": ['IH2 N F ER1 N OW2'],
  "inferred": ['IH2 N F ER1 D'],
  "inferring": ['IH2 N F ER1 IH0 NG'],
  "infers": ['IH2 N F ER1 Z'],
  "infertile": ['IH2 N F ER1 T AH0 L'],
  "infertility": ['IH2 N F ER0 T IH1 L IH0 T IY2'],
  "infest": ['IH2 N F EH1 S T'],
  "infestation": ['IH2 N F EH1 S T EY1 SH AH0 N'],
  "infestations": ['IH2 N F EH2 S T EY1 SH AH0 N Z'],
  "infested": ['IH2 N F EH1 S T IH0 D'],
  "infests": ['IH2 N F EH1 S T S'],
  "infidel": ['IH1 N F IH0 D EH2 L'],
  "infidelities": ['IH2 N F IH0 D EH1 L IH0 T IY0 Z'],
  "infidelity": ['IH2 N F IH0 D EH1 L IH0 T IY0'],
  "infidels": ['IH1 N F IH0 D EH0 L Z'],
  "infield": ['IH1 N F IY2 L D'],
  "infielder": ['IH1 N F IY2 L D ER0'],
  "infielders": ['IH1 N F IY2 L D ER0 Z'],
  "infighting": ['IH1 N F AY2 T IH0 NG'],
  "infiltrate": ['IH1 N F IH0 L T R EY2 T'],
  "infiltrated": ['IH1 N F IH0 L T R EY2 T IH0 D'],
  "infiltrating": ['IH2 N F IH1 L T R EY2 T IH0 NG'],
  "infiltration": ['IH2 N F IH0 L T R EY1 SH AH0 N'],
  "infiltrator": ['IH1 N F IH0 L T R EY2 T ER0'],
  "infiltrators": ['IH1 N F IH0 L T R EY2 T ER0 Z'],
  "infinger": ['IH1 N F IH0 NG ER0'],
  "infinite": ['IH1 N F AH0 N AH0 T'],
  "infinitely": ['IH1 N F AH0 N AH0 T L IY0'],
  "infinitesimal": ['IH2 N F IH0 N IH0 T EH1 S IH0 M AH0 L'],
  "infiniti": ['IH2 N F IH1 N IH0 T IY0'],
  "infinitive": ['IH2 N F IH1 N IH0 T IH0 V'],
  "infinitum": ['IH2 N F IH1 N IH0 T AH0 M'],
  "infinity": ['IH2 N F IH1 N IH0 T IY0'],
  "infirm": ['IH0 N F ER1 M'],
  "infirmary": ['IH0 N F ER1 M ER0 IY0'],
  "infirmed": ['IH0 N F ER1 M D'],
  "infirmities": ['IH0 N F ER1 M IH0 T IY0 Z'],
  "infirmity": ['IH0 N F ER1 M IH0 T IY0'],
  "inflame": ['IH0 N F L EY1 M'],
  "inflamed": ['IH0 N F L EY1 M D'],
  "inflames": ['IH0 N F L EY1 M Z'],
  "inflaming": ['IH0 N F L EY1 M IH0 NG'],
  "inflammable": ['IH0 N F L AE1 M AH0 B AH0 L'],
  "inflammation": ['IH2 N F L AH0 M EY1 SH AH0 N'],
  "inflammatory": ['IH0 N F L AE1 M AH0 T AO2 R IY0'],
  "inflatable": ['IH0 N F L EY1 T AH0 B AH0 L'],
  "inflate": ['IH0 N F L EY1 T'],
  "inflated": ['IH2 N F L EY1 T IH0 D'],
  "inflates": ['IH2 N F L EY1 T S'],
  "inflating": ['IH2 N F L EY1 T IH0 NG'],
  "inflation": ['IH2 N F L EY1 SH AH0 N'],
  "inflation's": ['IH2 N F L EY1 SH AH0 N Z'],
  "inflationary": ['IH2 N F L EY1 SH AH0 N EH2 R IY0'],
  "inflator": ['IH2 N F L EY1 T ER0'],
  "inflected": ['IH2 N F L EH1 K T AH0 D'],
  "inflection": ['IH2 N F L EH1 K SH AH0 N'],
  "inflections": ['IH2 N F L EH1 K SH AH0 N Z'],
  "inflexibility": ['IH2 N F L EH2 K S IH0 B IH1 L IH0 T IY0'],
  "inflexible": ['IH2 N F L EH1 K S AH0 B AH0 L'],
  "inflexibly": ['IH2 N F L EH1 K S AH0 B L IY0'],
  "inflict": ['IH2 N F L IH1 K T'],
  "inflicted": ['IH2 N F L IH1 K T IH0 D'],
  "inflicting": ['IH2 N F L IH1 K T IH0 NG'],
  "infliction": ['IH2 N F L IH1 K SH AH0 N'],
  "inflicts": ['IH2 N F L IH1 K T S', 'IH0 N F L IH1 K S'],
  "inflight": ['IH1 N F L AY2 T'],
  "inflow": ['IH1 N F L OW2'],
  "inflows": ['IH1 N F L OW2 Z'],
  "influence": ['IH1 N F L UW0 AH0 N S'],
  "influenced": ['IH1 N F L UW0 AH0 N S T'],
  "influences": ['IH1 N F L UW2 AH0 N S IH0 Z'],
  "influencing": ['IH1 N F L UW2 AH0 N S IH0 NG'],
  "influential": ['IH2 N F L UW0 EH1 N SH AH0 L'],
  "influenza": ['IH2 N F L UW0 EH1 N Z AH0'],
  "influx": ['IH1 N F L AH2 K S'],
  "info": ['IH1 N F OW0'],
  "infocorp": ['IH1 N F OW0 K AO2 R P'],
  "infographic": ['IH1 N F OW0 G R AE1 F IH0 K'],
  "infomercial": ['IH1 N F OW0 M ER2 SH AH0 L'],
  "infomercial's": ['IH1 N F OW0 M ER2 SH AH0 L Z'],
  "infomercials": ['IH1 N F OW0 M ER2 SH AH0 L Z'],
  "inform": ['IH2 N F AO1 R M'],
  "informal": ['IH2 N F AO1 R M AH0 L'],
  "informality": ['IH2 N F ER0 M AE1 L IH0 T IY0'],
  "informally": ['IH2 N F AO1 R M AH0 L IY0'],
  "informals": ['IH2 N F AO1 R M AH0 L Z'],
  "informant": ['IH2 N F AO1 R M AH0 N T'],
  "informants": ['IH2 N F AO1 R M AH0 N T S'],
  "informatic": ['IH0 N F ER0 M AE1 T IH0 K', 'IH0 N F AO1 R M AE1 T IH0 K'],
  "informatics": ['IH2 N F ER0 M AE1 T IH0 K S', 'IH0 N F AO1 R M AE1 T IH0 K S'],
  "information": ['IH2 N F ER0 M EY1 SH AH0 N', 'IH0 N F AO1 R M EY1 SH AH0 N'],
  "information's": ['IH2 N F ER0 M EY1 SH AH0 N Z', 'IH0 N F AO1 R M EY1 SH AH0 N Z'],
  "informational": ['IH2 N F ER0 M EY1 SH AH0 N AH0 L', 'IH0 N F AO1 R M EY1 SH AH0 N AH0 L'],
  "informations": ['IH2 N F ER0 M EY1 SH AH0 N Z', 'IH0 N F AO1 R M EY1 SH AH0 N Z'],
  "informative": ['IH2 N F AO1 R M AH0 T IH0 V'],
  "informed": ['IH2 N F AO1 R M D'],
  "informer": ['IH2 N F AO1 R M ER0'],
  "informers": ['IH2 N F AO1 R M ER0 Z'],
  "informing": ['IH2 N F AO1 R M IH0 NG'],
  "informix": ['IH2 N F AO1 R M IH0 K S'],
  "informs": ['IH2 N F AO1 R M Z'],
  "infoscan": ['IH1 N F OW0 S K AE2 N'],
  "infotainment": ['IH2 N F OW0 T EY1 N M AH0 N T'],
  "infotech": ['IH1 N F OW0 T EH2 K'],
  "infotechnology": ['IH2 N F OW0 T EH0 K N AA1 L AH0 JH IY0'],
  "infotron": ['IH1 N F OW0 T R AA2 N'],
  "infotron's": ['IH1 N F OW0 T R AA2 N Z'],
  "infoworld": ['IH1 N F OW0 W ER2 L D'],
  "infra": ['IH1 N F R AH0'],
  "infraction": ['IH2 N F R AE1 K SH AH0 N'],
  "infractions": ['IH2 N F R AE1 K SH AH0 N Z'],
  "infrared": ['IH2 N F R ER0 EH1 D'],
  "infrastructural": ['IH2 N F R AH0 S T R AH1 K CH ER0 AH0 L'],
  "infrastructure": ['IH2 N F R AH0 S T R AH1 K CH ER0'],
  "infrastructures": ['IH2 N F R AH0 S T R AH1 K CH ER0 Z'],
  "infrequency": ['IH2 N F R IY1 K W AH0 N S IY0'],
  "infrequent": ['IH2 N F R IY1 K W AH0 N T'],
  "infrequently": ['IH2 N F R IY1 K W AH0 N T L IY0'],
  "infringe": ['IH2 N F R IH1 N JH'],
  "infringed": ['IH2 N F R IH1 N JH D'],
  "infringement": ['IH2 N F R IH1 N JH M AH0 N T'],
  "infringements": ['IH2 N F R IH1 N JH M AH0 N T S'],
  "infringes": ['IH2 N F R IH1 N JH IH0 Z'],
  "infringing": ['IH2 N F R IH1 N JH IH0 NG'],
  "infuriate": ['IH2 N F Y UH1 R IY0 EY2 T'],
  "infuriated": ['IH2 N F Y UH1 R IY0 EY2 T IH0 D', 'IH0 N F Y UH1 R IY0 EY2 T IH0 D'],
  "infuriates": ['IH2 N F Y UH1 R IY0 EY2 T S'],
  "infuriating": ['IH2 N F Y UH1 R IY0 EY2 T IH0 NG'],
  "infuse": ['IH2 N F Y UW1 Z'],
  "infused": ['IH2 N F Y UW1 Z D'],
  "infuses": ['IH2 N F Y UW1 Z IH0 Z'],
  "infusing": ['IH2 N F Y UW1 Z IH0 NG'],
  "infusion": ['IH2 N F Y UW1 ZH AH0 N'],
  "infusions": ['IH2 N F Y UW1 ZH AH0 N Z'],
  "ing": ['IH1 NG'],
  "inga": ['IY1 NG G AH0'],
  "ingalls": ['IH2 NG G AO1 L Z'],
  "ingalsbe": ['IH1 NG G AH0 L S B IY0'],
  "ingar": ['IH1 NG G ER0'],
  "ingber": ['IH1 NG B ER0'],
  "inge": ['IH1 N JH'],
  "ingelheim": ['IH1 NG G AH0 L HH AY2 M'],
  "ingels": ['IH1 NG G AH0 L Z'],
  "ingemar": ['IH1 NG G IH0 M ER0', 'IH1 NG G IH0 M AA0 R'],
  "ingenious": ['IH2 N JH IY1 N Y AH0 S'],
  "ingeniously": ['IH2 N JH IY1 N Y AH0 S L IY0'],
  "ingenito": ['IH2 NG G EH0 N IY1 T OW0', 'IH2 NG JH EH0 N IY1 T OW0'],
  "ingenue": ['IH1 N JH EH2 N Y UW2', 'AA1 N JH AH0 N UW2', 'IH0 N JH EH1 N UW0', 'IH1 N JH AH0 N UW0'],
  "ingenuity": ['IH2 N JH AH0 N UW1 AH0 T IY2'],
  "ingenuous": ['IH2 N JH EH1 N Y UW0 AH0 S'],
  "inger": ['IH1 NG ER0'],
  "ingersoll": ['IH1 NG G ER0 S AO0 L'],
  "ingerson": ['IH1 NG G ER0 S AH0 N'],
  "ingest": ['IH2 N JH EH1 S T'],
  "ingested": ['IH2 N JH EH1 S T AH0 D'],
  "ingesting": ['IH2 N JH EH1 S T IH0 NG'],
  "ingestion": ['IH2 N JH EH1 S CH AH0 N'],
  "ingham": ['IH1 NG AH0 M'],
  "inghram": ['IH1 NG G R AE2 M'],
  "ingimarson": ['IH1 NG AH0 M AA2 R S AH0 N'],
  "ingle": ['IH1 NG G AH0 L'],
  "inglebert": ['IH1 NG G AH0 L B ER0 T'],
  "inglenook": ['IH1 NG G AH0 L N UH2 K'],
  "ingles": ['IH1 NG G AH0 L Z'],
  "inglese": ['IH1 NG G L IY0 Z'],
  "inglett": ['IH2 NG G L EH1 T'],
  "inglewood": ['IH1 NG G AH0 L W UH2 D'],
  "inglis": ['IH1 NG G L IH0 S'],
  "inglish": ['IH1 NG G AH0 L IH0 SH'],
  "inglorious": ['IH2 N G L AO1 R IY0 AH0 S'],
  "ingman": ['IH1 NG M AH0 N'],
  "ingmar": ['IH1 NG M AA0 R'],
  "ingmire": ['IH1 NG M AY0 R'],
  "ingo": ['IH1 NG G OW0'],
  "ingoglia": ['IH2 NG G OW1 G L IY0 AA2'],
  "ingold": ['IH1 NG G OW0 L D'],
  "ingot": ['IH1 NG G AH0 T'],
  "ingots": ['IH1 NG G AH0 T S'],
  "ingraham": ['IH1 NG G R AH0 HH AE2 M'],
  "ingrained": ['IH2 N G R EY1 N D'],
  "ingram": ['IH1 NG G R AH0 M'],
  "ingrao": ['IY1 NG G R AW0'],
  "ingrassia": ['IH2 NG G R AA1 SH AH0'],
  "ingratiate": ['IH2 NG G R EY1 SH IY0 EY2 T'],
  "ingratiating": ['IH2 NG G R EY1 SH IY0 EY2 T IH0 NG'],
  "ingredient": ['IH2 N G R IY1 D IY0 AH0 N T'],
  "ingredients": ['IH2 N G R IY1 D IY0 AH0 N T S'],
  "ingria": ['IH1 NG G R IY0 AH0'],
  "ingrid": ['IH1 NG G R IH0 D'],
  "ingrum": ['IH1 NG G R AH0 M'],
  "ingvar": ['IH1 NG V AA0 R'],
  "ingwersen": ['IH1 NG G W ER0 S AH0 N'],
  "inhabit": ['IH2 N HH AE1 B AH0 T'],
  "inhabitant": ['IH0 N HH AE1 B AH0 T AH0 N T', 'IH0 N HH AE1 B IH0 T AH0 N T'],
  "inhabitants": ['IH0 N HH AE1 B AH0 T AH0 N T S', 'IH0 N HH AE1 B IH0 T AH0 N T S'],
  "inhabitation": ['IH0 N HH AE2 B AH0 T EY1 SH AH0 N'],
  "inhabited": ['IH2 N HH AE1 B AH0 T AH0 D'],
  "inhabiting": ['IH0 N HH AE1 B AH0 T IH0 NG'],
  "inhabits": ['IH0 N HH AE1 B AH0 T S'],
  "inhalable": ['IH2 N HH EY1 L AH0 B AH0 L'],
  "inhalant": ['IH2 N HH EY1 L AH0 N T'],
  "inhalants": ['IH2 N HH EY1 L AH0 N T S'],
  "inhalation": ['IH0 N AH0 L EY1 SH AH0 N', 'IH2 N HH AH0 L EY1 SH AH0 N'],
  "inhale": ['IH2 N HH EY1 L'],
  "inhaled": ['IH2 N HH EY1 L D'],
  "inhaler": ['IH2 N HH EY1 L ER0'],
  "inhalers": ['IH2 N HH EY1 L ER0 Z'],
  "inhaling": ['IH2 N HH EY1 L IH0 NG'],
  "inherent": ['IH0 N HH IH1 R AH0 N T', 'IH0 N HH EH1 R AH0 N T'],
  "inherently": ['IH0 N HH IH1 R AH0 N T L IY0', 'IH0 N HH EH1 R AH0 N T L IY0'],
  "inherit": ['IH2 N HH EH1 R AH0 T'],
  "inheritable": ['IH2 N HH EH1 R AH0 T AH0 B AH0 L'],
  "inheritance": ['IH2 N HH EH1 R AH0 T AH0 N S'],
  "inherited": ['IH2 N HH EH1 R AH0 T IH0 D'],
  "inheriting": ['IH2 N HH EH1 R AH0 T IH0 NG'],
  "inheritor": ['IH2 N HH EH1 R AH0 T ER0'],
  "inherits": ['IH2 N HH EH1 R AH0 T S'],
  "inhibit": ['IH2 N HH IH1 B AH0 T'],
  "inhibited": ['IH2 N HH IH1 B AH0 T IH0 D'],
  "inhibiting": ['IH2 N HH IH1 B AH0 T IH0 NG'],
  "inhibition": ['IH2 N HH AH0 B IH1 SH AH0 N', 'IH2 N AH0 B IH1 SH AH0 N'],
  "inhibitions": ['IH2 N HH AH0 B IH1 SH AH0 N Z', 'IH2 N AH0 B IH1 SH AH0 N Z'],
  "inhibitor": ['IH2 N HH IH1 B AH0 T ER0'],
  "inhibitors": ['IH2 N HH IH1 B AH0 T ER0 Z'],
  "inhibitory": ['IH2 N HH IH1 B AH0 T AO2 R IY0'],
  "inhibits": ['IH2 N HH IH1 B AH0 T S'],
  "inhofe": ['IH1 N HH OW2 F', 'IH1 N HH AA2 F'],
  "inhofe's": ['IH1 N HH OW2 F S', 'IH1 N HH AA2 F S'],
  "inhospitable": ['IH0 N HH AA1 S P AH0 T AH0 B AH0 L', 'IH2 N HH AA0 S P IH1 T AH0 B AH0 L'],
  "inhouse": ['IH1 N HH AW2 S'],
  "inhuman": ['IH2 N HH Y UW1 M AH0 N'],
  "inhumane": ['IH2 N HH Y UW0 M EY1 N'],
  "inhumanity": ['IH2 N HH Y UW0 M AE1 N AH0 T IY0'],
  "iniga": ['IH2 N IY1 G AH0'],
  "iniguez": ['IH2 N IY1 G EH0 Z'],
  "iniki": ['IY2 N IY1 K IY2'],
  "inikpratt": ['IH2 N IY1 K P R AE0 T'],
  "inimical": ['IH2 N IH1 M IH0 K AH0 L'],
  "inimitable": ['IH2 N IH1 M AH0 T AH0 B AH0 L'],
  "iniquity": ['IH2 N IH1 K W IH0 T IY0'],
  "iniss": ['IH1 N IH0 S'],
  "initable": ['IH2 N IH1 T AH0 B AH0 L'],
  "initial": ['IH2 N IH1 SH AH0 L'],
  "initialed": ['IH2 N IH1 SH AH0 L D'],
  "initialing": ['IH2 N IH1 SH AH0 L IH0 NG'],
  "initialize": ['IH2 N IH1 SH AH0 L AY2 Z'],
  "initialized": ['IH2 N IH1 SH AH0 L AY2 Z D'],
  "initialling": ['IH2 N IH1 SH AH0 L IH0 NG'],
  "initially": ['IH2 N IH1 SH AH0 L IY0'],
  "initials": ['IH2 N IH1 SH AH0 L Z'],
  "initiate": ['IH2 N IH1 SH IY0 EY2 T'],
  "initiated": ['IH2 N IH1 SH IY0 EY2 T AH0 D', 'IH2 N IH1 SH IY0 EY2 T IH0 D'],
  "initiates": ['IH2 N IH1 SH IY0 AH0 T S'],
  "initiating": ['IH2 N IH1 SH IY0 EY2 T IH0 NG'],
  "initiation": ['IH2 N IH2 SH IY0 EY1 SH AH0 N'],
  "initiative": ['IH2 N IH1 SH AH0 T IH0 V', 'IH2 N IH1 SH Y AH0 T IH0 V'],
  "initiatives": ['IH2 N IH1 SH AH0 T IH0 V Z', 'IH2 N IH1 SH Y AH0 T IH0 V Z'],
  "initiator": ['IH2 N IH0 SH IY1 EY0 T ER0'],
  "initiators": ['IH2 N IH1 SH IY0 EY0 T ER0 Z'],
  "initio": ['IH2 N IH1 T IY0 OW0', 'IH2 N IH1 SH IY0 OW0'],
  "iniziativa": ['IH2 N IH0 Z IY2 AH0 T IY1 V AH0'],
  "inject": ['IH2 N JH EH1 K T'],
  "injectable": ['IH2 N JH EH1 K T AH0 B AH0 L'],
  "injected": ['IH2 N JH EH1 K T AH0 D', 'IH2 N JH EH1 K T IH0 D'],
  "injecting": ['IH2 N JH EH1 K T IH0 NG'],
  "injection": ['IH2 N JH EH1 K SH AH0 N'],
  "injections": ['IH2 N JH EH1 K SH AH0 N Z'],
  "injector": ['IH2 N JH EH1 K T ER0'],
  "injectors": ['IH2 N JH EH1 K T ER0 Z'],
  "injects": ['IH2 N JH EH1 K T S'],
  "injudicious": ['IH2 N JH AH2 D IH1 SH AH0 S'],
  "injunction": ['IH2 N JH AH1 NG K SH AH0 N', 'IH2 N JH AH1 NG SH AH0 N'],
  "injunctions": ['IH2 N JH AH1 NG K SH AH0 N Z', 'IH2 N JH AH1 NG SH AH0 N Z'],
  "injunctive": ['IH2 N JH AH1 NG K T IH0 V', 'IH2 N JH AH1 NG T IH0 V'],
  "injure": ['IH1 N JH ER0'],
  "injured": ['IH1 N JH ER0 D'],
  "injures": ['IH1 N JH ER0 Z'],
  "injuries": ['IH1 N JH ER0 IY0 Z'],
  "injuring": ['IH1 N JH ER0 IH0 NG'],
  "injurious": ['IH2 N JH UH1 R IY0 AH0 S'],
  "injury": ['IH1 N JH ER0 IY0'],
  "injustice": ['IH2 N JH AH1 S T IH0 S'],
  "injustices": ['IH2 N JH AH1 S T AH0 S IH0 Z'],
  "ink": ['IH1 NG K'],
  "inkatha": ['IH2 NG K AE1 TH AH0', 'IH2 NG K AA1 T AH2', 'IH2 NG K AA1 T AH0'],
  "inkatha's": ['IH2 NG K AE1 TH AH0 Z', 'IH2 NG K AA1 T AH2 Z', 'IH2 NG K AA1 T AH0 Z'],
  "inkblot": ['IH1 NG K B L AA2 T'],
  "inkjet": ['IH1 NG K JH EH2 T'],
  "inkling": ['IH1 NG K L IH0 NG'],
  "inks": ['IH1 NG K S'],
  "inky": ['IH1 NG K IY0'],
  "inlaid": ['IH1 N L EY2 D'],
  "inland": ['IH1 N L AE2 N D'],
  "inland's": ['IH1 N L AH0 N D Z'],
  "inlaw": ['IH2 N L AO1', 'IH1 N L AO2'],
  "inlaws": ['IH2 N L AO1 Z', 'IH1 N L AO0 Z'],
  "inlay": ['IH1 N L EY2'],
  "inlet": ['IH1 N L EH2 T'],
  "inlets": ['IH1 N L EH2 T S'],
  "inlex": ['IH1 N L EH0 K S'],
  "inline": ['IH2 N L AY1 N'],
  "inlow": ['IH2 N L OW1'],
  "inmac": ['IH1 N M AE0 K'],
  "inman": ['IH1 N M AH0 N'],
  "inman's": ['IH1 N M AH0 N Z'],
  "inmarsat": ['IH2 N M AA2 R S AE1 T'],
  "inmate": ['IH1 N M EY2 T'],
  "inmate's": ['IH1 N M EY2 T S'],
  "inmates": ['IH1 N M EY2 T S'],
  "inmex": ['IH1 N M EH2 K S'],
  "inmobiliaria": ['IH2 N M OW2 B AH0 L IY0 EH1 R IY0 AH0'],
  "inmon": ['IH1 N M AH0 N'],
  "inmont": ['IH1 N M AA2 N T'],
  "inn": ['IH1 N'],
  "inn's": ['IH1 N Z'],
  "innard": ['IH1 N ER0 D'],
  "innards": ['IH1 N ER0 D Z'],
  "innate": ['IH2 N EY1 T'],
  "innately": ['IH2 N EY1 T L IY0'],
  "inner": ['IH1 N ER0'],
  "innermost": ['IH1 N ER0 M OW2 S T'],
  "innerspace": ['IH1 N ER0 S P EY2 S'],
  "innes": ['IH1 N AH0 S'],
  "inness": ['IH1 N IH0 S'],
  "inning": ['IH1 N IH0 NG'],
  "innings": ['IH1 N IH0 NG Z'],
  "innis": ['IH1 N IH0 S'],
  "inniss": ['IH1 N IH0 S'],
  "innkeeper": ['IH1 N K IY2 P ER0'],
  "innkeepers": ['IH1 N K IY2 P ER0 Z'],
  "inno": ['IH1 N OW0'],
  "innocence": ['IH1 N AH0 S AH0 N S'],
  "innocent": ['IH1 N AH0 S AH0 N T'],
  "innocenti": ['IH2 N OW0 CH EH1 N T IY0'],
  "innocently": ['IH1 N AH0 S AH0 N T L IY0'],
  "innocents": ['IH1 N AH0 S AH0 N T S'],
  "innoculate": ['IH2 N AO1 K Y AH0 L EY2 T'],
  "innoculated": ['IH2 N AO1 K Y AH0 L EY2 T AH0 D'],
  "innoculates": ['IH2 N AO1 K Y AH0 L EY2 T S'],
  "innoculating": ['IH2 N AO1 K Y AH0 L EY2 T IH0 NG'],
  "innoculation": ['IH2 N AO2 K Y AH0 L EY1 SH AH0 N'],
  "innocuous": ['IH2 N AA1 K Y UW0 AH0 S'],
  "innominate": ['IH2 N AA1 M AH0 N AH0 T'],
  "innopac": ['IH1 N AH0 P AE2 K'],
  "innovate": ['IH1 N AH0 V EY2 T', 'IH1 N OW0 V EY2 T'],
  "innovated": ['IH1 N AH0 V EY2 T IH0 D', 'IH1 N OW0 V EY2 T IH0 D'],
  "innovates": ['IH1 N AH0 V EY2 T S', 'IH1 N OW0 V EY2 T S'],
  "innovating": ['IH2 N AH0 V EY1 T IH0 NG', 'IH2 N OW0 V EY1 T IH0 NG'],
  "innovation": ['IH2 N AH0 V EY1 SH AH0 N', 'IH2 N OW0 V EY1 SH AH0 N'],
  "innovations": ['IH2 N AH0 V EY1 SH AH0 N Z', 'IH2 N OW0 V EY1 SH AH0 N Z'],
  "innovative": ['IH1 N AH0 V EY2 T IH0 V', 'IH1 N OW0 V EY2 T IH0 V'],
  "innovator": ['IH1 N AH0 V EY2 T ER0', 'IH1 N OW0 V EY2 T ER0'],
  "innovators": ['IH1 N AH0 V EY2 T ER0 Z', 'IH1 N OW0 V EY2 T ER0 Z'],
  "inns": ['IH1 N Z'],
  "inns'": ['IH1 N Z'],
  "innuendo": ['IH2 N Y UW0 EH1 N D OW0'],
  "innuendoes": ['IH2 N Y UW0 EH1 N D OW0 Z'],
  "innuendos": ['IH2 N Y UW0 EH1 N D OW0 Z'],
  "innumerable": ['IH2 N UW1 M ER0 AH0 B AH0 L'],
  "inocencio": ['IH2 N OW0 CH EH1 N CH IY0 OW0'],
  "inoculate": ['IH2 N AA1 K Y AH0 L EY2 T'],
  "inoculated": ['IH2 N AA1 K Y AH0 L EY2 T IH0 D'],
  "inoculates": ['IH2 N AA1 K Y AH0 L EY2 T S'],
  "inoculation": ['IH2 N AA2 K Y AH0 L EY1 SH AH0 N'],
  "inoculations": ['IH2 N AA2 K Y AH0 L EY1 SH AH0 N Z'],
  "inoffensive": ['IH2 N AH0 F EH1 N S IH0 V'],
  "inoperable": ['IH2 N AA1 P ER0 AH0 B AH0 L'],
  "inoperative": ['IH2 N AA1 P ER0 AH0 T IH0 V'],
  "inopportune": ['IH2 N AA2 P ER0 T UW1 N'],
  "inordinate": ['IH2 N AO1 R D AH0 N IH0 T'],
  "inordinately": ['IH2 N AO1 R D AH0 N AH0 T L IY0'],
  "inorganic": ['IH2 N AO0 R G AE1 N IH0 K'],
  "inoue": ['IH2 N OW1 EY2'],
  "inouye": ['IH2 N UW1 EY2'],
  "inpatient": ['IH1 N P EY2 SH AH0 N T'],
  "inpatients": ['IH1 N P EY2 SH AH0 N T S'],
  "input": ['IH1 N P UH2 T'],
  "inputs": ['IH1 N P UH2 T S'],
  "inquest": ['IH1 N K W EH2 S T'],
  "inquire": ['IH2 N K W AY1 R'],
  "inquired": ['IH2 N K W AY1 ER0 D'],
  "inquirer": ['IH2 N K W AY1 R ER0'],
  "inquires": ['IH2 N K W AY1 ER0 Z'],
  "inquiries": ['IH2 N K W AY1 ER0 IY0 Z', 'IH1 N K W ER0 IY0 Z'],
  "inquiring": ['IH2 N K W AY1 ER0 IH0 NG'],
  "inquiry": ['IH2 N K W AY1 R IY2', 'IH0 N K W ER0 R IY0'],
  "inquisition": ['IH2 N K W AH0 Z IH1 SH AH0 N'],
  "inquisitive": ['IH2 N K W IH1 Z IH0 T IH0 V'],
  "inquisitor": ['IH2 N K W IH1 Z AH0 T ER0'],
  "inquisitors": ['IH2 N K W IH1 Z AH0 T ER0 Z'],
  "inroad": ['IH1 N R OW2 D'],
  "inroads": ['IH1 N R OW2 D Z'],
  "ins": ['IH1 N Z', 'AY1 EH1 N EH1 S'],
  "insalaco": ['IH2 N S AA0 L AA1 K OW0'],
  "insane": ['IH2 N S EY1 N'],
  "insanity": ['IH2 N S AE1 N AH0 T IY0', 'IH2 N S AE1 N IH0 T IY0'],
  "insatiable": ['IH2 N S EY1 SH AH0 B AH0 L'],
  "inscho": ['IH1 N SH OW0'],
  "insco": ['IY1 N S K OW0'],
  "inscoe": ['IH2 N S K OW1'],
  "inscore": ['IH2 N S K AO1 R IY0'],
  "inscribe": ['IH2 N S K R AY1 B'],
  "inscribed": ['IH2 N S K R AY1 B D'],
  "inscribes": ['IH2 N S K R AY1 B Z'],
  "inscribing": ['IH2 N S K R AY1 B IH0 NG'],
  "inscription": ['IH2 N S K R IH1 P SH AH0 N'],
  "inscriptions": ['IH2 N S K R IH1 P SH AH0 N Z'],
  "inscrutable": ['IH2 N S K R UW1 T AH0 B AH0 L'],
  "insect": ['IH1 N S EH2 K T'],
  "insect's": ['IH1 N S EH2 K T S'],
  "insecticide": ['IH2 N S EH1 K T AH0 S AY2 D'],
  "insecticides": ['IH2 N S EH1 K T AH0 S AY2 D Z'],
  "insectivorous": ['IH2 N S EH0 K T IH1 V ER0 AH0 S'],
  "insects": ['IH1 N S EH2 K T S'],
  "insecure": ['IH1 N S AH0 K Y ER0'],
  "insecurities": ['IH2 N S AH0 K Y UH1 R IH0 T IY0 Z'],
  "insecurity": ['IH2 N S IH0 K Y UH1 R IH0 T IY0'],
  "insel": ['IH1 N S AH0 L'],
  "inseminate": ['IH2 N S EH1 M AH0 N EY2 T'],
  "insemination": ['IH2 N S EH2 M AH0 N EY1 SH AH0 N'],
  "insensible": ['IH2 N S EH1 N S AH0 B AH0 L'],
  "insensitive": ['IH2 N S EH1 N S AH0 T IH0 V', 'IH2 N S EH1 N S IH0 T IH0 V'],
  "insensitivity": ['IH2 N S EH2 N S AH0 T IH1 V AH0 T IY0'],
  "inseparable": ['IH2 N S EH1 P ER0 AH0 B AH0 L'],
  "inseparably": ['IH2 N S EH1 P ER0 AH0 B L IY0'],
  "inserra": ['IH2 N S EH1 R AH0'],
  "insert": ['IH2 N S ER1 T', 'IH1 N S ER2 T'],
  "inserted": ['IH2 N S ER1 T AH0 D', 'IH2 N S ER1 T IH0 D'],
  "inserting": ['IH2 N S ER1 T IH0 NG'],
  "insertion": ['IH2 N S ER1 SH AH0 N'],
  "inserts": ['IH2 N S ER1 T S', 'IH1 N S ER2 T S'],
  "inset": ['IH1 N S EH2 T'],
  "inshore": ['IH1 N SH AO1 R'],
  "inside": ['IH2 N S AY1 D', 'IH1 N S AY2 D'],
  "insider": ['IH2 N S AY1 D ER0'],
  "insider's": ['IH2 N S AY1 D ER0 Z'],
  "insiders": ['IH2 N S AY1 D ER0 Z'],
  "insiders'": ['IH2 N S AY1 D ER0 Z'],
  "insides": ['IH2 N S AY1 D Z', 'IH1 N S AY0 D Z'],
  "insidious": ['IH2 N S IH1 D IY2 AH0 S'],
  "insight": ['IH1 N S AY2 T'],
  "insightful": ['IH1 N S AY2 T F AH0 L'],
  "insights": ['IH1 N S AY2 T S'],
  "insignia": ['IH2 N S IH1 G N IY2 AH0'],
  "insignificance": ['IH2 N S IH0 G N Y IH1 F IH0 K AH0 N S'],
  "insignificant": ['IH2 N S IH0 G N Y IH1 F IH0 K AH0 N T'],
  "insilco": ['IH2 N S IH1 L K OW0'],
  "insincere": ['IH2 N S IH0 N S IH1 R'],
  "insinuate": ['IH2 N S IH1 N Y UW0 EY0 T'],
  "insinuated": ['IH2 N S IH1 N Y UW0 EY0 T IH0 D'],
  "insinuates": ['IH2 N S IH1 N Y UW0 EY0 T S'],
  "insinuating": ['IH2 N S IH1 N Y UW0 EY0 T IH0 NG'],
  "insinuation": ['IH2 N S IH2 N Y UW0 EY1 SH AH0 N'],
  "insinuations": ['IH2 N S IH2 N Y UW0 EY1 SH AH0 N Z'],
  "insipid": ['IH2 N S IH1 P AH0 D'],
  "insist": ['IH2 N S IH1 S T'],
  "insisted": ['IH2 N S IH1 S T AH0 D', 'IH2 N S IH1 S T IH0 D'],
  "insistence": ['IH2 N S IH1 S T AH0 N S'],
  "insistent": ['IH2 N S IH1 S T AH0 N T'],
  "insistently": ['IH2 N S IH1 S T AH0 N T L IY0'],
  "insisting": ['IH2 N S IH1 S T IH0 NG'],
  "insists": ['IH2 N S IH1 S T S'],
  "insituform": ['IH2 N S IH1 T UW2 F AO0 R M'],
  "inskeep": ['IH1 N Z K IY2 P'],
  "inskip": ['IH1 N S K IH0 P'],
  "insko": ['IH1 N S K OW0'],
  "inslaw": ['IH1 N S L AA0'],
  "insley": ['IH1 N S L IY0'],
  "insofar": ['IH1 N S AH0 F AA0 R'],
  "insolence": ['IH1 N S AH0 L AH0 N S'],
  "insolent": ['IH1 N S AH0 L AH0 N T'],
  "insoluble": ['IH2 N S AA1 L Y AH0 B AH0 L'],
  "insolvencies": ['IH2 N S AA1 L V AH0 N S IY0 Z'],
  "insolvency": ['IH2 N S AA1 L V AH0 N S IY0'],
  "insolvent": ['IH2 N S AA1 L V AH0 N T'],
  "insomnia": ['IH2 N S AA1 M N IY0 AH0'],
  "insomniac": ['IH2 N S AA1 M N IY0 AE2 K'],
  "insouciance": ['IH2 N S UW1 S IY0 AH0 N S', 'IH2 N S UW1 S Y AH0 N S'],
  "insouciant": ['IH2 N S UW1 S Y AH0 N T'],
  "inspect": ['IH2 N S P EH1 K T'],
  "inspected": ['IH2 N S P EH1 K T IH0 D'],
  "inspecting": ['IH2 N S P EH1 K T IH0 NG'],
  "inspection": ['IH2 N S P EH1 K SH AH0 N'],
  "inspections": ['IH2 N S P EH1 K SH AH0 N Z'],
  "inspector": ['IH2 N S P EH1 K T ER0'],
  "inspector's": ['IH2 N S P EH1 K T ER0 Z'],
  "inspectorate": ['IH2 N S P EH1 K T ER0 AH0 T'],
  "inspectors": ['IH2 N S P EH1 K T ER0 Z'],
  "inspectors'": ['IH2 N S P EH1 K T ER0 Z'],
  "inspects": ['IH2 N S P EH1 K T S'],
  "inspeech": ['IH2 N S P IY1 CH'],
  "inspiration": ['IH2 N S P ER0 EY1 SH AH0 N'],
  "inspirational": ['IH2 N S P ER0 EY1 SH AH0 N AH0 L'],
  "inspirations": ['IH2 N S P ER0 EY1 SH AH0 N Z'],
  "inspire": ['IH2 N S P AY1 R'],
  "inspired": ['IH2 N S P AY1 ER0 D'],
  "inspires": ['IH2 N S P AY1 R Z'],
  "inspiring": ['IH2 N S P AY1 R IH0 NG', 'IH2 N S P AY1 ER0 IH0 NG'],
  "instabilities": ['IH2 N S T AH0 B IH1 L IH0 T IY0 Z'],
  "instability": ['IH2 N S T AH0 B IH1 L IH0 T IY0'],
  "instagram": ['IH2 N S T AH0 G R AE2 M'],
  "install": ['IH2 N S T AO1 L'],
  "installation": ['IH2 N S T AH0 L EY1 SH AH0 N'],
  "installations": ['IH2 N S T AH0 L EY1 SH AH0 N Z'],
  "installed": ['IH2 N S T AO1 L D'],
  "installer": ['IH2 N S T AO1 L ER0'],
  "installers": ['IH2 N S T AO1 L ER0 Z'],
  "installing": ['IH2 N S T AO1 L IH0 NG'],
  "installment": ['IH2 N S T AO1 L M AH0 N T'],
  "installments": ['IH2 N S T AO1 L M AH0 N T S'],
  "installs": ['IH2 N S T AO1 L Z'],
  "instance": ['IH1 N S T AH0 N S'],
  "instances": ['IH1 N S T AH0 N S AH0 Z', 'IH1 N S T AH0 N S IH0 Z'],
  "instant": ['IH1 N S T AH0 N T'],
  "instantaneous": ['IH2 N S T AH0 N T AE1 N IY0 AH0 S'],
  "instantaneously": ['IH2 N S T AH0 N T AE1 N IY0 AH0 S L IY0'],
  "instantiate": ['IH2 N S T AE1 N SH IY0 EY2 T'],
  "instantiated": ['IH2 N S T AE1 N SH IY0 EY2 T AH0 D'],
  "instantiates": ['IH2 N S T AE1 N SH IY0 EY2 T S'],
  "instantiating": ['IH2 N S T AE1 N SH IY0 EY2 T IH0 NG'],
  "instantiation": ['IH2 N S T AE2 N SH IY0 EY1 SH AH0 N'],
  "instantly": ['IH1 N S T AH0 N T L IY0'],
  "instead": ['IH2 N S T EH1 D'],
  "instigate": ['IH1 N S T AH0 G EY2 T'],
  "instigated": ['IH1 N S T AH0 G EY2 T IH0 D'],
  "instigates": ['IH1 N S T AH0 G EY2 T S'],
  "instigating": ['IH1 N S T AH0 G EY2 T IH0 NG'],
  "instigation": ['IH2 N S T IH0 G EY1 SH AH0 N'],
  "instigator": ['IH1 N S T AH0 G EY2 T ER0'],
  "instigators": ['IH1 N S T AH0 G EY2 T ER0 Z'],
  "instill": ['IH2 N S T IH1 L'],
  "instilled": ['IH2 N S T IH1 L D'],
  "instilling": ['IH2 N S T IH1 L IH0 NG'],
  "instills": ['IH2 N S T IH1 L Z'],
  "instinct": ['IH1 N S T IH0 NG K T'],
  "instinctive": ['IH2 N S T IH1 NG K T IH0 V'],
  "instinctively": ['IH2 N S T IH1 NG K T IH0 V L IY2'],
  "instincts": ['IH1 N S T IH0 NG K T S'],
  "instinet": ['IH1 N S T IH0 N EH2 T'],
  "institucional": ['IH2 N S T IH0 T UW2 S IY0 AH0 N AE1 L'],
  "institut": ['IH1 N S T IH0 T UW0 T'],
  "institute": ['IH1 N S T AH0 T UW2 T'],
  "institute's": ['IH1 N S T IH0 T UW0 T S'],
  "instituted": ['IH1 N S T AH0 T UW2 T IH0 D'],
  "institutes": ['IH1 N S T AH0 T UW2 T S'],
  "institutes'": ['IH1 N S T IH0 T UW2 T S'],
  "instituting": ['IH1 N S T IH0 T UW2 T IH0 NG'],
  "institution": ['IH2 N S T IH0 T UW1 SH AH0 N'],
  "institution's": ['IH2 N S T IH0 T UW1 SH AH0 N Z'],
  "institutional": ['IH2 N S T IH0 T UW1 SH AH0 N AH0 L'],
  "institutionalist": ['IH2 N S T IH0 T UW1 SH AH0 N AH0 L IH0 S T'],
  "institutionalists": ['IH2 N S T IH0 T UW1 SH AH0 N AH0 L IH0 S T S'],
  "institutionalization": ['IH2 N S T IH0 T UW2 SH AH0 N AH0 L IH0 Z EY1 SH AH0 N'],
  "institutionalize": ['IH2 N S T IH0 T UW1 SH AH0 N AH0 L AY2 Z'],
  "institutionalized": ['IH2 N S T IH0 T UW1 SH AH0 N AH0 L AY2 Z D'],
  "institutionalizes": ['IH2 N S T IH0 T UW1 SH AH0 N AH0 L AY2 Z IH0 Z'],
  "institutionalizing": ['IH2 N S T IH0 T UW1 SH AH0 N AH0 L AY2 Z IH0 NG'],
  "institutionally": ['IH2 N S T IH0 T UW1 SH AH0 N AH0 L IY2', 'IH2 N S T IH0 T UW1 SH N AH0 L IY0'],
  "institutions": ['IH2 N S T IH0 T UW1 SH AH0 N Z'],
  "institutions'": ['IH2 N S T IH0 T UW1 SH AH0 N Z'],
  "instituto": ['IH2 N S T IH0 T UW1 T OW2'],
  "instone": ['IH1 N S T OW2 N'],
  "instruct": ['IH2 N S T R AH1 K T'],
  "instructed": ['IH2 N S T R AH1 K T AH0 D', 'IH2 N S T R AH1 K T IH0 D'],
  "instructing": ['IH2 N S T R AH1 K T IH0 NG'],
  "instruction": ['IH2 N S T R AH1 K SH AH0 N'],
  "instructional": ['IH2 N S T R AH1 K SH AH0 N AH0 L'],
  "instructions": ['IH2 N S T R AH1 K SH AH0 N Z'],
  "instructive": ['IH2 N S T R AH1 K T IH0 V'],
  "instructor": ['IH2 N S T R AH1 K T ER0'],
  "instructors": ['IH2 N S T R AH1 K T ER0 Z'],
  "instructs": ['IH2 N S T R AH1 K T S'],
  "instrument": ['IH1 N S T R AH0 M AH0 N T'],
  "instrument's": ['IH1 N S T R AH0 M AH0 N T S'],
  "instrumental": ['IH2 N S T R AH0 M EH1 N T AH0 L', 'IH2 N S T R AH0 M EH1 N AH0 L'],
  "instrumentalist": ['IH2 N S T R AH0 M EH1 N T AH0 L IH0 S T', 'IH2 N S T R AH0 M EH1 N AH0 L IH0 S T'],
  "instrumentalists": ['IH2 N S T R AH0 M EH1 N T AH0 L IH0 S T S', 'IH2 N S T R AH0 M EH1 N AH0 L IH0 S T S', 'IH2 N S T R AH0 M EH1 N T AH0 L IH0 S', 'IH2 N S T R AH0 M EH1 N AH0 L IH0 S'],
  "instrumentality": ['IH2 N S T R AH0 M EH0 N T AE1 L IH0 T IY0'],
  "instrumentals": ['IH2 N S T R AH0 M EH1 N T AH0 L Z', 'IH2 N S T R AH0 M EH1 N AH0 L Z'],
  "instrumentation": ['IH2 N S T R AH0 M EH2 N T EY1 SH AH0 N'],
  "instruments": ['IH1 N S T R AH0 M AH0 N T S'],
  "instruments'": ['IH1 N S T R AH0 M AH0 N T S'],
  "insubordinate": ['IH2 N S AH0 B AO1 R D AH0 N EY2 T', 'IH2 N S AH0 B AO1 R D AH0 N AH0 T'],
  "insubordination": ['IH2 N S AH0 B AO2 R D AH0 N EY1 SH AH0 N'],
  "insubstantial": ['IH2 N S AH0 B S T AE1 N SH AH0 L'],
  "insubstantiate": ['IH2 N S AH0 B S T AE1 N CH IY2 EY0 T', 'IH2 N S AH0 B S T AE1 N SH IY2 EY0 T'],
  "insubstantiated": ['IH2 N S AH0 B S T AE1 N CH IY2 EY0 T AH0 D', 'IH2 N S AH0 B S T AE1 N SH IY2 EY0 T AH0 D'],
  "insufferable": ['IH2 N S AH1 F ER0 AH0 B AH0 L'],
  "insufficiency": ['IH2 N S AH0 F IH1 SH AH0 N S IY2'],
  "insufficient": ['IH2 N S AH0 F IH1 SH AH0 N T'],
  "insufficiently": ['IH2 N S AH0 F IH1 SH AH0 N T L IY0'],
  "insular": ['IH1 N S AH0 L ER0'],
  "insularity": ['IH2 N S AH0 L EH1 R IH0 T IY0'],
  "insulate": ['IH1 N S AH0 L EY2 T'],
  "insulated": ['IH1 N S AH0 L EY2 T AH0 D', 'IH1 N S AH0 L EY2 T IH0 D'],
  "insulating": ['IH1 N S AH0 L EY2 T IH0 NG'],
  "insulation": ['IH2 N S AH0 L EY1 SH AH0 N'],
  "insulator": ['IH1 N S AH0 L EY2 T ER0'],
  "insulators": ['IH1 N S AH0 L EY2 T ER0 Z'],
  "insulin": ['IH1 N S AH0 L AH0 N'],
  "insult": ['IH2 N S AH1 L T', 'IH1 N S AH2 L T'],
  "insulted": ['IH2 N S AH1 L T IH0 D'],
  "insulting": ['IH2 N S AH1 L T IH0 NG'],
  "insults": ['IH2 N S AH1 L T S', 'IH1 N S AH2 L T S'],
  "insupportable": ['IH2 N S AH0 P AO1 R T AH0 B AH0 L'],
  "insurance": ['IH2 N SH UH1 R AH0 N S'],
  "insurance's": ['IH2 N SH UH1 R AH0 N S IH0 Z'],
  "insurances": ['IH2 N SH UH1 R AH0 N S IH0 Z'],
  "insure": ['IH2 N SH UH1 R'],
  "insured": ['IH2 N SH UH1 R D'],
  "insurer": ['IH2 N SH UH1 R ER0'],
  "insurer's": ['IH2 N SH UH1 R ER0 Z'],
  "insurers": ['IH2 N SH UH1 R ER0 Z'],
  "insurers'": ['IH2 N SH UH1 R ER0 Z'],
  "insures": ['IH2 N SH UH1 R Z'],
  "insurgencies": ['IH2 N S ER1 JH AH0 N S IY0 Z'],
  "insurgency": ['IH2 N S ER1 JH AH0 N S IY0'],
  "insurgent": ['IH2 N S ER1 JH AH0 N T'],
  "insurgents": ['IH2 N S ER1 JH AH0 N T S'],
  "insurgents'": ['IH2 N S ER1 JH AH0 N T S'],
  "insuring": ['IH2 N SH UH1 R IH0 NG'],
  "insurmountable": ['IH2 N S ER0 M AW1 N T AH0 B AH0 L'],
  "insurrection": ['IH2 N S ER0 EH1 K SH AH0 N'],
  "intact": ['IH2 N T AE1 K T'],
  "intaglio": ['IH2 N T AE1 L Y OW0', 'IH2 N T AE1 G L Y OW0'],
  "intake": ['IH1 N T EY2 K'],
  "intan": ['IH1 N T AH0 N'],
  "intangible": ['IH2 N T AE1 N JH AH0 B AH0 L'],
  "intangibles": ['IH2 N T AE1 N JH AH0 B AH0 L Z'],
  "intangibly": ['IH2 N T AE1 N JH AH0 B L IY0'],
  "intefadeh": ['IH2 N T AH0 F AA1 D AH0', 'IH2 N T IH0 F AA1 D AH0'],
  "integer": ['IH1 N T AH0 JH ER0'],
  "integers": ['IH1 N T AH0 JH ER0 Z'],
  "integon": ['IH1 N T AH0 G AA0 N'],
  "integra": ['IH2 N T EH1 G R AH0'],
  "integral": ['IH1 N T AH0 G R AH0 L', 'IH1 N AH0 G R AH0 L'],
  "integrals": ['IH1 N T AH0 G R AH0 L Z'],
  "integrate": ['IH1 N T AH0 G R EY2 T', 'IH1 N AH0 G R EY2 T'],
  "integrated": ['IH1 N T AH0 G R EY2 T AH0 D', 'IH1 N T AH0 G R EY2 T IH0 D', 'IH1 N AH0 G R EY2 T AH0 D', 'IH1 N AH0 G R EY2 T IH0 D'],
  "integrated's": ['IH1 N T AH0 G R EY2 T IH0 D Z', 'IH1 N AH0 G R EY2 T IH0 D Z'],
  "integrates": ['IH1 N T AH0 G R EY2 T S', 'IH1 N AH0 G R EY2 T S'],
  "integrating": ['IH1 N T AH0 G R EY2 T IH0 NG', 'IH1 N AH0 G R EY2 T IH0 NG'],
  "integration": ['IH2 N T AH0 G R EY1 SH AH0 N', 'IH2 N AH0 G R EY1 SH AH0 N'],
  "integrations": ['IH2 N T AH0 G R EY1 SH AH0 N Z', 'IH2 N AH0 G R EY1 SH AH0 N Z'],
  "integrator": ['IH1 N T AH0 G R EY2 T ER0', 'IH1 N AH0 G R EY2 T ER0'],
  "integrators": ['IH1 N T AH0 G R EY2 T ER0 Z', 'IH1 N AH0 G R EY2 T ER0 Z'],
  "integrelin": ['IH2 N T AH0 G R EH1 L AH0 N', 'IH2 N T EH1 G R AH0 L IH0 N'],
  "integrity": ['IH2 N T EH1 G R AH0 T IY0', 'IH0 N T EH1 G R IH0 T IY0'],
  "integument": ['IH2 N T EH1 G Y AH0 M AH0 N T'],
  "intel": ['IH2 N T EH1 L'],
  "intel's": ['IH2 N T EH1 L Z'],
  "intelco": ['IH2 N T EH1 L K OW0'],
  "intelcom": ['IH2 N T EH1 L K AA0 M'],
  "intellect": ['IH1 N T AH0 L EH2 K T', 'IH1 N AH0 L EH2 K T'],
  "intellects": ['IH1 N T AH0 L EH2 K T S', 'IH1 N AH0 L EH2 K T S', 'IH1 N T AH0 L EH2 K S', 'IH1 N AH0 L EH2 K S'],
  "intellectual": ['IH2 N T AH0 L EH1 K CH UW0 AH0 L', 'IH2 N AH0 L EH1 K CH UW0 AH0 L'],
  "intellectualism": ['IH2 N T EH2 L AH0 K CH UW1 AH0 L IH2 Z AH0 M', 'IH2 N EH2 L AH0 K CH UW1 AH0 L IH2 Z AH0 M'],
  "intellectually": ['IH2 N T AH0 L EH1 K CH UW0 AH0 L IY0', 'IH2 N T AH0 L EH1 K CH UW0 L IY0', 'IH2 N AH0 L EH1 K CH UW0 AH0 L IY0', 'IH2 N AH0 L EH1 K CH UW0 L IY0'],
  "intellectuals": ['IH2 N T AH0 L EH1 K CH UW0 AH0 L Z'],
  "intellicall": ['IH2 N T EH1 L IH0 K AO2 L'],
  "intellicorp": ['IH2 N T EH1 L IH0 K AO2 R P'],
  "intelligence": ['IH2 N T EH1 L AH0 JH AH0 N S'],
  "intelligent": ['IH2 N T EH1 L AH0 JH AH0 N T'],
  "intelligently": ['IH2 N T EH1 L IH0 JH AH0 N T L IY0'],
  "intelligentsia": ['IH2 N T EH2 L AH0 JH EH1 N T S IY0 AH0'],
  "intelligible": ['IH2 N T EH1 L AH0 JH AH0 B AH0 L'],
  "intelligibly": ['IH2 N T EH1 L AH0 JH AH0 B L IY0'],
  "intelogic": ['IH2 N T AH0 L AA1 JH IH0 K'],
  "intelsat": ['IH2 N T EH1 L S AE0 T'],
  "intemperate": ['IH2 N T EH1 M P ER0 AH0 T'],
  "intend": ['IH2 N T EH1 N D'],
  "intended": ['IH2 N T EH1 N D IH0 D', 'IH0 N T EH1 N D IH0 D'],
  "intending": ['IH2 N T EH1 N D IH0 NG'],
  "intends": ['IH2 N T EH1 N D Z'],
  "intense": ['IH2 N T EH1 N S'],
  "intensely": ['IH2 N T EH1 N S L IY0'],
  "intensification": ['IH0 N T EH2 N S AH0 F AH0 K EY1 SH AH0 N'],
  "intensified": ['IH0 N T EH1 N S AH0 F AY2 D'],
  "intensifies": ['IH0 N T EH1 N S AH0 F AY2 Z'],
  "intensify": ['IH0 N T EH1 N S AH0 F AY2'],
  "intensifying": ['IH0 N T EH1 N S AH0 F AY2 IH0 NG'],
  "intensities": ['IH0 N T EH1 N S AH0 T IY0 Z'],
  "intensity": ['IH0 N T EH1 N S AH0 T IY0', 'IH0 N T EH1 N S IH0 T IY0'],
  "intensive": ['IH2 N T EH1 N S IH0 V'],
  "intensively": ['IH2 N T EH1 N S IH0 V L IY0'],
  "intent": ['IH2 N T EH1 N T'],
  "intention": ['IH2 N T EH1 N CH AH0 N'],
  "intentional": ['IH2 N T EH1 N SH AH0 N AH0 L'],
  "intentionally": ['IH2 N T EH1 N SH AH0 N AH0 L IY0'],
  "intentioned": ['IH2 N T EH1 N CH AH0 N D'],
  "intentions": ['IH2 N T EH1 N CH AH0 N Z'],
  "intently": ['IH2 N T EH1 N T L IY0'],
  "intents": ['IH2 N T EH1 N T S'],
  "inter": ['IH2 N T ER1'],
  "interacciones": ['IH2 N T ER0 AE2 K S IY0 OW1 N Z'],
  "interact": ['IH2 N T ER0 AE1 K T', 'IH2 N ER0 AE1 K T'],
  "interacted": ['IH2 N T ER0 AE1 K T AH0 D', 'IH2 N ER0 AE1 K T AH0 D'],
  "interacting": ['IH2 N T ER0 AE1 K T IH0 NG', 'IH2 N ER0 AE1 K T IH0 NG'],
  "interaction": ['IH2 N T ER0 AE1 K SH AH0 N', 'IH2 N ER0 AE1 K SH AH0 N'],
  "interactions": ['IH2 N T ER0 AE1 K SH AH0 N Z', 'IH2 N ER0 AE1 K SH AH0 N Z'],
  "interactive": ['IH2 N T ER0 AE1 K T IH0 V', 'IH2 N ER0 AE1 K T IH0 V'],
  "interactivity": ['IH2 N T ER0 AE2 K T IH1 V IH0 T IY0', 'IH2 N ER0 AE2 K T IH1 V IH0 T IY0'],
  "interacts": ['IH2 N T ER0 AE1 K T S', 'IH2 N ER0 AE1 K T S'],
  "interagency": ['IH2 N T ER0 EY1 JH AH0 N S IY0'],
  "interamerican": ['IH2 N T ER0 AH0 M EH1 R AH0 K AH0 N'],
  "interand": ['IH1 N T ER0 AH0 N D'],
  "interbank": ['IH2 N T ER0 B AE1 NG K'],
  "interbred": ['IH2 N T ER0 B R EH1 D'],
  "interbrew": ['IH1 N T ER2 B R UW2', 'IH1 N T ER0 B R UW2'],
  "interbrew's": ['IH1 N T ER2 B R UW2 Z', 'IH1 N T ER0 B R UW2 Z'],
  "intercable": ['IH2 N T ER0 K EY1 B AH0 L'],
  "intercapital": ['IH2 N T ER0 K AE1 P AH0 T AH0 L'],
  "intercare": ['IH1 N T ER0 K EH2 R'],
  "intercede": ['IH2 N T ER0 S IY1 D'],
  "interceded": ['IH2 N T ER0 S IY1 D IH0 D'],
  "interceding": ['IH2 N T ER0 S IY1 D IH0 NG'],
  "intercellular": ['IH2 N T ER0 S EH1 L Y AH0 L ER0'],
  "intercept": ['IH2 N T ER0 S EH1 P T', 'IH2 N ER0 S EH1 P T'],
  "intercepted": ['IH2 N T ER0 S EH1 P T AH0 D', 'IH2 N T ER0 S EH1 P T IH0 D', 'IH2 N ER0 S EH1 P T AH0 D', 'IH2 N ER0 S EH1 P T IH0 D'],
  "intercepting": ['IH2 N T ER0 S EH1 P T IH0 NG', 'IH2 N ER0 S EH1 P T IH0 NG'],
  "interception": ['IH2 N T ER0 S EH1 P SH AH0 N', 'IH2 N ER0 S EH1 P SH AH0 N'],
  "interceptions": ['IH2 N T ER0 S EH1 P SH AH0 N Z', 'IH2 N ER0 S EH1 P SH AH0 N Z'],
  "interceptor": ['IH2 N T ER0 S EH1 P T ER0', 'IH2 N ER0 S EH1 P T ER0'],
  "interceptors": ['IH2 N T ER0 S EH1 P T ER0 Z', 'IH2 N ER0 S EH1 P T ER0 Z'],
  "intercepts": ['IH2 N T ER0 S EH1 P T S', 'IH2 N ER0 S EH1 P T S'],
  "intercession": ['IH2 N T ER0 S EH1 SH AH0 N', 'IH2 N ER0 S EH1 SH AH0 N'],
  "interchange": ['IH2 N T ER0 CH EY1 N JH', 'IH2 N ER0 CH EY1 N JH'],
  "interchangeable": ['IH2 N T ER0 CH EY1 N JH AH0 B AH0 L', 'IH2 N ER0 CH EY1 N JH AH0 B AH0 L'],
  "interchangeably": ['IH2 N T ER0 CH EY1 N JH AH0 B L IY0', 'IH2 N ER0 CH EY1 N JH AH0 B L IY0'],
  "interchanges": ['IH1 N T ER0 CH EY2 N JH IH0 Z', 'IH1 N ER0 CH EY2 N JH IH0 Z'],
  "intercity": ['IH1 N T ER0 S IH2 T IY0', 'IH1 N ER0 S IH2 T IY0'],
  "interco": ['IH1 N T ER0 K OW2'],
  "interco's": ['IH1 N T ER0 K OW2 Z'],
  "intercollegiate": ['IH2 N T ER0 K AH0 L IY1 JH AH0 T'],
  "intercom": ['IH1 N T ER0 K AA2 M'],
  "intercompany": ['IH2 N T ER0 K AH1 M P AH0 N IY0'],
  "interconnect": ['IH2 N T ER0 K AH0 N EH1 K T'],
  "interconnected": ['IH2 N T ER0 K AH0 N EH1 K T IH0 D'],
  "interconnection": ['IH2 N T ER0 K AH0 N EH1 K SH AH0 N'],
  "interconnections": ['IH2 N T ER0 K AH0 N EH1 K SH AH0 N Z'],
  "intercontinental": ['IH2 N T ER0 K AA2 N T AH0 N EH1 N T AH0 L'],
  "intercontinentale": ['IH2 N T ER0 K AA0 N T IH1 N AH0 N T AA2 L'],
  "intercorp": ['IH1 N T ER0 K AO2 R P'],
  "intercorporation": ['IH2 N T ER0 K AO2 R P ER0 EY1 SH AH0 N'],
  "intercourse": ['IH1 N T ER0 K AO2 R S', 'IH1 N ER0 K AO2 R S'],
  "intercultural": ['IH2 N T ER0 K AH1 L CH ER0 AH0 L'],
  "intercurrent": ['IH2 N T ER0 K ER1 AH0 N T'],
  "interdealer": ['IH1 N T ER0 D IY2 L ER0'],
  "interdependence": ['IH2 N T ER0 D AH0 P EH1 N D AH0 N S'],
  "interdependent": ['IH2 N T ER0 D IH0 P EH1 N D AH0 N T'],
  "interdict": ['IH1 N T ER0 D IH2 K T'],
  "interdicted": ['IH1 N T ER0 D IH2 K T IH0 D'],
  "interdictes": ['IH1 N T ER0 D IH2 K T S'],
  "interdicting": ['IH2 N T ER0 D IH1 K T IH0 NG'],
  "interdiction": ['IH2 N T ER0 D IH1 K SH AH0 N'],
  "interdigital": ['IH2 N T ER0 D IH1 JH AH0 T AH0 L'],
  "interdisciplinary": ['IH2 N T ER0 D IH1 S AH0 P L AH0 N EH2 R IY0'],
  "interdyne": ['IH1 N T ER0 D AY2 N'],
  "interest": ['IH1 N T R AH0 S T', 'IH1 N T R IH0 S T', 'IH1 N T ER0 AH0 S T', 'IH1 N T ER0 IH0 S T'],
  "interested": ['IH1 N T R AH0 S T IH0 D', 'IH1 N T R IH0 S T IH0 D', 'IH1 N T ER0 AH0 S T AH0 D', 'IH1 N T ER0 IH0 S T IH0 D'],
  "interesting": ['IH1 N T R AH0 S T IH0 NG', 'IH1 N T R IH0 S T IH0 NG', 'IH1 N T ER0 AH0 S T IH0 NG', 'IH1 N T ER0 IH0 S T IH0 NG'],
  "interestingly": ['IH1 N T ER0 EH2 S T IH0 NG L IY0'],
  "interestrate": ['IH1 N T ER0 AH0 S T R EY2 T'],
  "interests": ['IH1 N T R AH0 S T S', 'IH1 N T R IH0 S T S', 'IH1 N T ER0 AH0 S T S', 'IH1 N T ER0 IH0 S T S'],
  "interface": ['IH1 N T ER0 F EY2 S', 'IH1 N ER0 F EY2 S'],
  "interfaces": ['IH1 N T ER0 F EY2 S IH0 Z', 'IH1 N ER0 F EY2 S IH0 Z'],
  "interfaith": ['IH2 N T ER0 F EY1 TH'],
  "interfax": ['IH1 N T ER0 F AE2 K S'],
  "interfere": ['IH2 N T ER0 F IH1 R', 'IH2 N ER0 F IH1 R'],
  "interfered": ['IH2 N T ER0 F IH1 R D', 'IH2 N ER0 F IH1 R D'],
  "interference": ['IH2 N T ER0 F IH1 R AH0 N S', 'IH2 N ER0 F IH1 R AH0 N S'],
  "interferes": ['IH2 N T ER0 F IH1 R Z', 'IH2 N ER0 F IH1 R Z'],
  "interfering": ['IH2 N T ER0 F IH1 R IH0 NG', 'IH2 N ER0 F IH1 R IH0 NG'],
  "interferometer": ['IH2 N T ER0 F ER0 AA1 M AH0 T ER0'],
  "interferon": ['IH2 N T ER0 F EH1 R AA0 N', 'IH2 N T ER0 F IH1 R AA0 N'],
  "interferons": ['IH2 N T ER0 F IH1 R AA0 N Z'],
  "interfirst": ['IH2 N T ER0 F ER1 S T'],
  "interflug": ['IH1 N T ER0 F L AH0 G'],
  "interfunding": ['IH1 N T ER0 F AH2 N D IH0 NG'],
  "intergenerational": ['IH2 N T ER0 JH EH1 N ER0 EY1 SH AH0 N AH0 L'],
  "intergovernmental": ['IH2 N T ER0 G AH2 V ER0 N M EH1 N T AH0 L'],
  "intergraph": ['IH1 N T ER0 G R AE2 F'],
  "intergraph's": ['IH1 N T ER0 G R AE2 F S'],
  "intergroup": ['IH2 N T ER0 G R UW1 P'],
  "intergroup's": ['IH2 N T ER0 G R UW1 P S'],
  "interim": ['IH1 N T ER0 AH0 M', 'IH1 N T R AH0 M', 'IH1 N ER0 AH0 M'],
  "interior": ['IH2 N T IH1 R IY0 ER0'],
  "interiors": ['IH2 N T IH1 R IY0 ER0 Z'],
  "interject": ['IH2 N T ER0 JH EH1 K T'],
  "interjected": ['IH2 N T ER0 JH EH1 K T IH0 D'],
  "interjection": ['IH2 N T ER0 JH EH1 K SH AH0 N'],
  "interjections": ['IH2 N T ER0 JH EH1 K SH AH0 N Z'],
  "interjects": ['IH2 N T ER0 JH EH1 K T S'],
  "interlace": ['IH2 N T ER0 L EY1 S'],
  "interlaced": ['IH2 N T ER0 L EY1 S T'],
  "interlake": ['IH1 N T ER0 L EY2 K'],
  "interlake's": ['IH1 N T ER0 L EY2 K S'],
  "interleaf": ['IH1 N T ER0 L IY2 F'],
  "interleave": ['IH2 N T ER0 L IY1 V'],
  "interleaved": ['IH2 N T ER0 L IY1 V D'],
  "interleaves": ['IH2 N T ER0 L IY1 V Z'],
  "interleaving": ['IH2 N T ER0 L IY1 V IH0 NG'],
  "interleukin": ['IH2 N T ER0 L UW1 K IH0 N'],
  "interlink": ['IH2 N T ER0 L IH1 NG K'],
  "interlinked": ['IH2 N T ER0 L IH1 NG K T'],
  "interlock": ['IH2 N T ER0 L AA1 K'],
  "interlocked": ['IH2 N T ER0 L AA1 K D'],
  "interlocking": ['IH1 N T ER0 L AA2 K IH0 NG'],
  "interlocutor": ['IH2 N T ER0 L AA1 K Y AH0 T ER0'],
  "interlocutors": ['IH2 N T ER0 L AA1 K Y AH0 T ER0 Z'],
  "interloper": ['IH1 N T ER0 L OW2 P ER0'],
  "interlopers": ['IH1 N T ER0 L OW2 P ER0 Z'],
  "interlude": ['IH1 N T ER0 L UW2 D'],
  "interludes": ['IH1 N T ER0 L UW2 D Z'],
  "intermagnetic": ['IH2 N T ER0 M AE0 G N EH1 T IH0 K'],
  "intermagnetics": ['IH2 N T ER0 M AE0 G N EH1 T IH0 K S'],
  "intermark": ['IH2 N T ER0 M AA1 R K'],
  "intermarket": ['IH2 N T ER0 M AA1 R K IH0 T'],
  "intermarriage": ['IH2 N T ER0 M EH1 R IH0 JH'],
  "intermarried": ['IH2 N T ER0 M AE1 R IY0 D'],
  "intermarry": ['IH2 N T ER0 M AE1 R IY0'],
  "intermec": ['IH1 N T ER0 M AH0 K'],
  "intermedia": ['IH2 N T ER0 M IY1 D IY0 AH0'],
  "intermediaries": ['IH2 N T ER0 M IY1 D IY0 EH2 R IY0 Z', 'IH2 N ER0 M IY1 D IY0 EH2 R IY0 Z'],
  "intermediary": ['IH2 N ER0 M IY1 D IY0 EH0 R IY0', 'IH2 N T ER0 M IY1 D IY0 EH0 R IY0'],
  "intermediate": ['IH2 N T ER0 M IY1 D IY0 IH0 T', 'IH2 N ER0 M IY1 D IY0 IH0 T'],
  "intermediates": ['IH2 N T ER0 M IY1 D IY0 AH0 T S', 'IH2 N T ER0 M IY1 D IY0 EY0 T S', 'IH2 N ER0 M IY1 D IY0 AH0 T S', 'IH2 N ER0 M IY1 D IY0 EY0 T S'],
  "intermedic": ['IH1 N T ER0 M EH2 D IH0 K'],
  "intermedics": ['IH1 N T ER0 M EH2 D IH0 K S'],
  "intermet": ['IH2 N T ER0 M EH1 T'],
  "interminable": ['IH2 N T ER1 M AH0 N AH0 B AH0 L'],
  "interminably": ['IH1 N T ER0 M IH0 N AE2 B L IY0', 'IH2 N T ER1 M IH0 N AH0 B L IY0'],
  "intermingle": ['IH2 N T ER0 M IH1 NG G AH0 L'],
  "intermingled": ['IH2 N T ER0 M IH1 NG G AH0 L D'],
  "intermingling": ['IH2 N T ER0 M IH1 NG G AH0 L IH0 NG', 'IH2 N T ER0 M IH1 NG G L IH0 NG'],
  "intermission": ['IH2 N T ER0 M IH1 SH AH0 N'],
  "intermissions": ['IH2 N T ER0 M IH1 SH AH0 N Z'],
  "intermittent": ['IH2 N T ER0 M IH1 T AH0 N T'],
  "intermittently": ['IH2 N T ER0 M IH1 T AH0 N T L IY0'],
  "intermix": ['IH2 N T ER0 M IH1 K S'],
  "intermixed": ['IH2 N T ER0 M IH1 K S T'],
  "intermixing": ['IH1 N T ER0 M IH1 K S IH0 NG'],
  "intermodal": ['IH2 N T ER0 M OW1 D AH0 L'],
  "intermolecular": ['IH2 N T ER0 M AH0 L EH1 K Y AH0 L ER0'],
  "intermountain": ['IH2 N T ER0 M AW1 N T IH0 N'],
  "intern": ['IH1 N T ER0 N'],
  "internacional": ['IH2 N T ER0 N AE1 SH AH0 N AH0 L', 'IH2 N T ER0 N AA2 S IY0 OW0 N AE1 L'],
  "internal": ['IH2 N T ER1 N AH0 L'],
  "internalize": ['IH2 N T ER1 N AH0 L AY2 Z'],
  "internalized": ['IH2 N T ER1 N AH0 L AY2 Z D'],
  "internally": ['IH2 N T ER1 N AH0 L IY0'],
  "international": ['IH2 N T ER0 N AE1 SH AH0 N AH0 L', 'IH2 N ER0 N AE1 SH AH0 N AH0 L'],
  "international's": ['IH2 N T ER0 N AE1 SH AH0 N AH0 L Z', 'IH2 N ER0 N AE1 SH AH0 N AH0 L Z'],
  "internationale": ['IH0 N T ER0 N AE2 SH AH0 N AA1 L IY0', 'IH2 N ER0 N AE2 SH AH0 N AA1 L IY0'],
  "internationalism": ['IH0 N T ER0 N AE1 SH AH0 N AH0 L IH2 Z AH0 M', 'IH0 N ER0 N AE1 SH AH0 N AH0 L IH2 Z AH0 M'],
  "internationalist": ['IH2 N T ER0 N AE1 SH AH0 N AH0 L IH0 S T', 'IH2 N ER0 N AE1 SH AH0 N AH0 L IH0 S T'],
  "internationalists": ['IH2 N T ER0 N AE1 SH AH0 N AH0 L IH0 S T S', 'IH2 N ER0 N AE1 SH AH0 N AH0 L IH0 S T S', 'IH2 N T ER0 N AE1 SH AH0 N AH0 L IH0 S', 'IH2 N ER0 N AE1 SH AH0 N AH0 L IH0 S'],
  "internationalization": ['IH2 N T ER0 N AE2 SH AH0 N AH0 L IH0 Z EY1 SH AH0 N', 'IH2 N ER0 N AE2 SH AH0 N AH0 L IH0 Z EY1 SH AH0 N'],
  "internationalize": ['IH2 N T ER0 N AE1 SH AH0 N AH0 L AY2 Z', 'IH2 N ER0 N AE1 SH AH0 N AH0 L AY2 Z', 'IH2 N ER0 N AE1 SH N AH0 L AY2 Z'],
  "internationalized": ['IH0 N T ER0 N AE1 SH AH0 N AH0 L AY0 Z D', 'IH0 N ER0 N AE1 SH AH0 N AH0 L AY0 Z D', 'IH0 N ER0 N AE1 SH N AH0 L AY0 Z D'],
  "internationally": ['IH2 N T ER0 N AE1 SH AH0 N AH0 L IY0', 'IH2 N T ER0 N AE1 SH N AH0 L IY0', 'IH2 N ER0 N AE1 SH AH0 N AH0 L IY0', 'IH2 N ER0 N AE1 SH N AH0 L IY0'],
  "internationals": ['IH2 N T ER0 N AE1 SH AH0 N AH0 L Z'],
  "internatonal": ['IH2 N T ER0 N AE1 SH AH0 N AH0 L', 'IH2 N ER0 N AE1 SH AH0 N AH0 L'],
  "internecine": ['IH2 N T ER1 N AH0 S IY2 N'],
  "interned": ['IH1 N T ER2 N D'],
  "internee": ['IH2 N T ER0 N IY1'],
  "internees": ['IH2 N T ER0 N IY1 Z'],
  "internet": ['IH1 N T ER0 N EH2 T'],
  "internet's": ['IH1 N T ER0 N EH2 T S'],
  "internist": ['IH2 N T ER1 N IH0 S T'],
  "internists": ['IH2 N T ER1 N IH0 S T S'],
  "internment": ['IH2 N T ER1 N M AH0 N T'],
  "internorth": ['IH1 N T ER0 N AO0 R TH'],
  "interns": ['IH1 N T ER0 N Z'],
  "internship": ['IH1 N T ER0 N SH IH2 P'],
  "internships": ['IH1 N T ER0 N SH IH2 P S'],
  "interoffice": ['IH2 N T ER0 AO1 F AH0 S'],
  "interoperate": ['IH2 N T ER0 AO1 P ER0 AH0 T'],
  "interoperates": ['IH2 N T ER0 AO1 P ER0 AH0 T S'],
  "interpart": ['IH1 N T ER0 P AA2 R T'],
  "interpersonal": ['IH2 N T ER0 P ER1 S AH0 N AH0 L'],
  "interplanetary": ['IH2 N T ER0 P L AE1 N AH0 T EH2 R IY0'],
  "interplay": ['IH1 N T ER0 P L EY2'],
  "interpol": ['IH1 N T ER0 P OW2 L'],
  "interpolate": ['IH2 T ER1 P AH0 L EY2 T'],
  "interpolated": ['IH2 T ER1 P AH0 L EY2 T IH0 D'],
  "interpolation": ['IH2 T ER1 P AH0 L EY2 SH AH0 N'],
  "interpolations": ['IH2 T ER1 P AH0 L EY2 SH AH0 N Z'],
  "interpose": ['IH2 N T ER0 P OW1 Z'],
  "interpret": ['IH2 N T ER1 P R AH0 T'],
  "interpretation": ['IH2 N T ER2 P R IH0 T EY1 SH AH0 N'],
  "interpretations": ['IH2 N T ER2 P R IH0 T EY1 SH AH0 N Z'],
  "interpreted": ['IH2 N T ER1 P R AH0 T AH0 D'],
  "interpreter": ['IH2 N T ER1 P R AH0 T ER0'],
  "interpreters": ['IH2 N T ER1 P R AH0 T ER0 Z'],
  "interpreting": ['IH1 N T ER0 P R EH2 T IH0 NG'],
  "interpretive": ['IH2 N T ER1 P R AH0 T IH0 V'],
  "interprets": ['IH2 N T ER1 P R AH0 T S'],
  "interprovincial": ['IH2 N T ER0 P R OW0 V IH1 N SH AH0 L'],
  "interpublic": ['IH2 N T ER0 P AH1 B L IH0 K'],
  "interpublic's": ['IH2 N T ER0 P AH1 B L IH0 K S'],
  "interracial": ['IH2 N T ER0 R EY1 SH AH0 L'],
  "interrante": ['IH2 N T ER0 R AA1 N T IY0'],
  "interred": ['IH2 N T ER1 D'],
  "interregnum": ['IH2 N T ER0 R EH1 G N AH0 M'],
  "interrelate": ['IH2 N T ER0 R IH0 L EY1 T'],
  "interrelated": ['IH2 N T ER0 R IH0 L EY1 T IH0 D', 'IH2 N T ER0 R IY0 L EY1 T IH0 D'],
  "interrelationship": ['IH1 N T ER0 R IY0 L EY1 SH AH0 N SH IH0 P'],
  "interrent": ['IH1 N T ER0 EH2 N T', 'IH1 N ER0 EH2 N T'],
  "interrogate": ['IH2 N T EH1 R AH0 G EY2 T'],
  "interrogated": ['IH2 N T EH1 R AH0 G EY2 T IH0 D'],
  "interrogating": ['IH2 N T EH1 R AH0 G EY2 T IH0 NG'],
  "interrogation": ['IH2 N T EH2 R AH0 G EY1 SH AH0 N'],
  "interrogations": ['IH2 N T EH2 R AH0 G EY1 SH AH0 N Z'],
  "interrogator": ['IH2 N T EH1 R AH0 G EY2 T ER0'],
  "interrogatories": ['IH2 N T EH0 R AA1 G AH0 T AO2 R IY0 Z'],
  "interrogators": ['IH2 N T EH1 R AH0 G EY2 T ER0 Z'],
  "interrogatory": ['IH2 N T EH0 R AA1 G AH0 T AO2 R IY0'],
  "interrupt": ['IH2 N T ER0 AH1 P T'],
  "interrupted": ['IH2 N T ER0 AH1 P T IH0 D'],
  "interruptible": ['IH2 N T ER0 R AH1 P T IH0 B AH0 L'],
  "interrupting": ['IH2 N T ER0 AH1 P T IH0 NG'],
  "interruption": ['IH2 N T ER0 AH1 P SH AH0 N'],
  "interruptions": ['IH2 N T ER0 AH1 P SH AH0 N Z'],
  "interrupts": ['IH2 N T ER0 AH1 P T S'],
  "interscholastic": ['IH2 N T ER0 S K AH0 L AE1 S T IH0 K'],
  "interscope": ['IH1 N T ER0 S K OW2 P'],
  "intersec": ['IH1 N T ER0 S EH0 K'],
  "intersect": ['IH2 N T ER0 S EH1 K T'],
  "intersected": ['IH2 N T ER0 S EH1 K T IH0 D'],
  "intersecting": ['IH2 N T ER0 S EH1 K T IH0 NG'],
  "intersection": ['IH2 N T ER0 S EH1 K SH AH0 N'],
  "intersections": ['IH2 N T ER0 S EH1 K SH AH0 N Z'],
  "intersects": ['IH2 N T ER0 S EH1 K T S'],
  "intersegment": ['IH2 N T ER0 S EH1 G M AH0 N T'],
  "interspec": ['IH1 N T ER0 S P EH0 K'],
  "intersperse": ['IH2 N T ER0 S P ER1 S'],
  "interspersed": ['IH2 N T ER0 S P ER1 S T'],
  "intersperses": ['IH2 N T ER0 S P ER1 S AH0 Z'],
  "interstate": ['IH2 N T ER0 S T EY1 T'],
  "interstate's": ['IH2 N T ER0 S T EY1 T S'],
  "interstates": ['IH1 N T ER0 S T EY2 T S'],
  "interstellar": ['IH2 N T ER0 S T EH1 L ER0'],
  "interstitial": ['IH2 N T ER0 S T IH1 SH AH0 L'],
  "intertan": ['IH2 N T ER0 T AE1 N'],
  "intertech": ['IH1 N T ER0 T EH2 K'],
  "intertechnology": ['IH2 N T ER0 T AH0 K N AA1 L AH0 JH IY0'],
  "intertect": ['IH1 N T ER0 T EH2 K T'],
  "intertidal": ['IH2 N T ER0 T AY1 D AH0 L'],
  "intertrans": ['IH1 N T ER0 T R AE2 N Z'],
  "intertribal": ['IH2 N T ER0 T R AY1 B AH0 L'],
  "intertwine": ['IH2 N T ER0 T W AY1 N'],
  "intertwined": ['IH2 N T ER0 T W AY1 N D'],
  "intertwining": ['IH1 N T ER0 T W AY2 N IH0 NG'],
  "interurban": ['IH2 N T ER0 ER1 B AH0 N'],
  "interval": ['IH1 N T ER0 V AH0 L'],
  "intervals": ['IH1 N T ER0 V AH0 L Z'],
  "intervene": ['IH2 N T ER0 V IY1 N'],
  "intervened": ['IH2 N T ER0 V IY1 N D'],
  "intervenes": ['IH2 N T ER0 V IY1 N Z'],
  "intervening": ['IH2 N T ER0 V IY1 N IH0 NG'],
  "intervenor": ['IH2 N T ER0 V IY1 N ER0'],
  "intervenors": ['IH2 N T ER0 V IY1 N ER0 Z'],
  "intervention": ['IH2 N T ER0 V EH1 N SH AH0 N'],
  "interventionism": ['IH2 N T ER0 V EH1 N SH AH0 N IH2 Z AH0 M'],
  "interventionist": ['IH2 N T ER0 V EH1 N SH AH0 N IH0 S T'],
  "interventionists": ['IH2 N T ER0 V EH1 N SH AH0 N IH0 S T S'],
  "interventions": ['IH2 N T ER0 V EH1 N SH AH0 N Z'],
  "interview": ['IH1 N T ER0 V Y UW2'],
  "interviewed": ['IH1 N T ER0 V Y UW2 D'],
  "interviewee": ['IH2 N T ER0 V Y UW0 IY1'],
  "interviewees": ['IH2 N T ER0 V Y UW0 IY1 Z'],
  "interviewer": ['IH1 N T ER0 V Y UW2 ER0'],
  "interviewers": ['IH1 N T ER0 V Y UW2 ER0 Z'],
  "interviewing": ['IH1 N T ER0 V Y UW2 IH0 NG'],
  "interviews": ['IH1 N T ER0 V Y UW2 Z'],
  "intervoice": ['IH2 N T ER0 V OY1 S'],
  "interweave": ['IH2 N T ER0 W IY1 V'],
  "interweaving": ['IH2 N T ER0 W IY1 V IH0 NG'],
  "interwoven": ['IH2 N T ER0 W OW1 V AH0 N'],
  "intestinal": ['IH0 N T EH1 S T AH0 N AH0 L'],
  "intestine": ['IH2 N T EH1 S T AH0 N'],
  "intestines": ['IH2 N T EH1 S T AH0 N Z'],
  "intex": ['IH1 N T EH2 K S'],
  "inti": ['IH1 N T IY0'],
  "intifada": ['IH2 N T IH0 F AA1 D AH0'],
  "intimacy": ['IH1 N T AH0 M AH0 S IY0'],
  "intimate": ['IH1 N T AH0 M AH0 T', 'IH1 N T AH0 M EY2 T', 'IH1 N AH0 M AH0 T'],
  "intimated": ['IH1 N T AH0 M EY2 T IH0 D'],
  "intimately": ['IH1 N T AH0 M AH0 T L IY0'],
  "intimates": ['IH1 N T AH0 M AH0 T S'],
  "intimation": ['IH2 N T AH0 M EY1 SH AH0 N'],
  "intimations": ['IH2 N T AH0 M EY1 SH AH0 N Z'],
  "intimidate": ['IH2 N T IH1 M IH0 D EY2 T'],
  "intimidated": ['IH2 N T IH1 M IH0 D EY2 T IH0 D'],
  "intimidates": ['IH2 N T IH1 M IH0 D EY2 T S'],
  "intimidating": ['IH2 N T IH1 M IH0 D EY2 T IH0 NG'],
  "intimidation": ['IH2 N T IH2 M IH0 D EY1 SH AH0 N'],
  "intis": ['IH1 N T IH0 S'],
  "into": ['IH1 N T UW0', 'IH0 N T UW1', 'IH0 N T AH0'],
  "intolerable": ['IH2 N T AA1 L ER0 AH0 B AH0 L'],
  "intolerably": ['IH2 N T AA1 L ER0 AH0 B L IY0'],
  "intolerance": ['IH2 N T AA1 L ER0 AH0 N S'],
  "intolerant": ['IH2 N T AA1 L ER0 AH0 N T'],
  "intonation": ['IH2 N T AH0 N EY1 SH AH0 N'],
  "intonations": ['IH2 N T AH0 N EY1 SH AH0 N Z'],
  "intone": ['IH2 N T OW1 N'],
  "intoned": ['IH2 N T OW1 N D'],
  "intones": ['IH2 N T OW1 N Z'],
  "intoning": ['IH2 N T OW1 N IH0 NG'],
  "intourist": ['IH2 N T UH1 R IH0 S T'],
  "intoxicate": ['IH2 N T AA1 K S AH0 K EY2 T'],
  "intoxicated": ['IH0 N T AA1 K S AH0 K EY2 T IH0 D', 'IH0 N T AA1 K S IH0 K EY2 T IH0 D'],
  "intoxicates": ['IH2 N T AA1 K S AH0 K EY2 T S'],
  "intoxicating": ['IH2 N T AA1 K S IH0 K EY2 T IH0 NG'],
  "intoxication": ['IH2 N T AA2 K S AH0 K EY1 SH AH0 N'],
  "intra": ['IH1 N T R AA0'],
  "intracellular": ['IH2 N T R AA0 S EH1 L Y AH0 L ER0'],
  "intracompany": ['IH2 N T R AH0 K AA1 M P AH0 N IY0'],
  "intracranial": ['IH2 N T R AH0 K R EY1 N IY0 AH0 L'],
  "intractable": ['IH2 N T R AE1 K T AH0 B AH0 L'],
  "intraday": ['IH2 N T R AH0 D EY1'],
  "intramarginal": ['IH2 N T R AH0 M AA1 R JH IH0 N AH0 L'],
  "intramural": ['IH2 N T R AH0 M Y UH1 R AH0 L'],
  "intransigence": ['IH2 N T R AE1 N S AH0 JH AH0 N S', 'IH2 N T R AE1 N S IH0 JH AH0 N S'],
  "intransigent": ['IH2 N T R AE1 N S AH0 JH AH0 N T', 'IH2 N T R AE1 N Z AH0 JH AH0 N T'],
  "intraocular": ['IH2 N T R AH0 OW1 K Y UW0 L ER0'],
  "intraparty": ['IH1 N T R AH0 P AA2 R T IY0'],
  "intraspecific": ['IH2 N T R AH0 S P AH0 S IH1 F AH0 K'],
  "intrastate": ['IH2 N T R AH0 S T EY1 T'],
  "intrauterine": ['IH2 N T R AH0 Y UW1 T ER0 IH0 N'],
  "intravenous": ['IH2 N T R AH0 V IY1 N AH0 S'],
  "intravenously": ['IH0 N T R AE1 V AH0 N AH0 S L IY0', 'IH0 N T R AH0 V IY1 N AH0 S L IY0'],
  "intrawest": ['IH1 N T R AH0 W AH0 S T', 'IH2 N T R AH0 W EH1 S T'],
  "intrepid": ['IH0 N T R EH1 P AH0 D'],
  "intrepidity": ['IH2 N T R EH1 P IH0 D AH0 T IY2'],
  "intrepidly": ['IH0 N T R EH1 P AH0 D L IY0'],
  "intrex": ['IH1 N T R AH0 K S'],
  "intricacies": ['IH1 N T R AH0 K AH0 S IY0 Z'],
  "intricacy": ['IH1 N T R AH0 K AH0 S IY0'],
  "intricate": ['IH1 N T R AH0 K AH0 T'],
  "intricately": ['IH1 N T R AH0 K AH0 T L IY0'],
  "intrie": ['IH1 N T R IY0'],
  "intrieri": ['IH2 N T R IH1 R IY0'],
  "intrigue": ['IH2 N T R IY1 G', 'IH1 N T R IY0 G'],
  "intrigued": ['IH1 N T R IY0 G D', 'IH2 N T R IY1 G D'],
  "intrigues": ['IH2 N T R IY1 G Z', 'IH1 N T R IY0 G Z'],
  "intriguing": ['IH2 N T R IY1 G IH0 NG'],
  "intriguingly": ['IH2 N T R IY1 G IH0 NG L IY0'],
  "intrinsic": ['IH2 N T R IH1 N S IH0 K'],
  "intrinsically": ['IH2 N T R IH1 N S IH0 K AH0 L IY0', 'IH2 N T R IH1 N S IH0 K L IY0'],
  "intro": ['IH1 N T R OW0'],
  "introduce": ['IH2 N T R AH0 D UW1 S', 'IH2 N T R OW0 D UW1 S'],
  "introduced": ['IH2 N T R AH0 D UW1 S T', 'IH2 N T R OW0 D UW1 S T'],
  "introduces": ['IH2 N T R AH0 D UW1 S IH0 Z', 'IH2 N T R OW0 D UW1 S IH0 Z'],
  "introducing": ['IH2 N T R AH0 D UW1 S IH0 NG', 'IH2 N T R OW0 D UW1 S IH0 NG'],
  "introduction": ['IH2 N T R AH0 D AH1 K SH AH0 N', 'IH2 N T R OW0 D AH1 K SH AH0 N'],
  "introductions": ['IH2 N T R AH0 D AH1 K SH AH0 N Z', 'IH2 N T R OW0 D AH1 K SH AH0 N Z'],
  "introductory": ['IH2 N T R AH0 D AH1 K T ER0 IY0', 'IH2 N T R OW0 D AH1 K T ER0 IY0'],
  "intron": ['IH1 N T R AH0 N'],
  "intros": ['IH1 N T R OW0 Z'],
  "introspect": ['IH1 N T R AH0 S P EH2 K T'],
  "introspection": ['IH2 N T R AH0 S P EH1 K SH AH0 N', 'IH2 N T R OW0 S P EH1 K SH AH0 N'],
  "introspective": ['IH2 N T R AH0 S P EH1 K T IH0 V', 'IH2 N T R OW0 S P EH1 K T IH0 V'],
  "introvert": ['IH1 N T R OW0 V ER2 T'],
  "introverted": ['IH1 N T R OW0 V ER2 T IH0 D'],
  "intrude": ['IH2 N T R UW1 D'],
  "intruded": ['IH2 N T R UW1 D AH0 D'],
  "intruder": ['IH2 N T R UW1 D ER0'],
  "intruders": ['IH2 N T R UW1 D ER0 Z'],
  "intrudes": ['IH2 N T R UW1 D Z'],
  "intruding": ['IH2 N T R UW1 D IH0 NG'],
  "intrusion": ['IH2 N T R UW1 ZH AH0 N'],
  "intrusions": ['IH2 N T R UW1 ZH AH0 N Z'],
  "intrusive": ['IH2 N T R UW1 S IH0 V'],
  "intrusiveness": ['IH2 N T R UW1 S IH0 V N EH0 S'],
  "intuit": ['IH2 N T UW1 AH0 T'],
  "intuit's": ['IH2 N T UW1 AH0 T S'],
  "intuition": ['IH2 N T UW0 IH1 SH AH0 N'],
  "intuitive": ['IH2 N T UW1 AH0 T IH0 V'],
  "intuitively": ['IH2 N T UW1 IH0 T IH0 V L IY0'],
  "inuit": ['IH1 N UW0 T'],
  "inundate": ['IH1 N AH0 N D EY2 T'],
  "inundated": ['IH1 N AH0 N D EY2 T IH0 D'],
  "inundating": ['IH1 N AH0 N D EY2 T IH0 NG', 'IH2 N AH1 N D EY2 T IH0 NG'],
  "inundation": ['IH2 N AH0 N D EY1 SH AH0 N'],
  "inundations": ['IH2 N AH0 N D EY1 SH AH0 N Z'],
  "inure": ['IH2 N Y UH1 R'],
  "inured": ['IH2 N Y UH1 R D'],
  "invacare": ['IH1 N V AH0 K EH2 R'],
  "invade": ['IH2 N V EY1 D'],
  "invaded": ['IH2 N V EY1 D AH0 D', 'IH2 N V EY1 D IH0 D'],
  "invader": ['IH2 N V EY1 D ER0'],
  "invaders": ['IH2 N V EY1 D ER0 Z'],
  "invades": ['IH2 N V EY1 D Z'],
  "invading": ['IH2 N V EY1 D IH0 NG'],
  "invalid": ['IH1 N V AH0 L AH0 D', 'IH1 N V AH0 L IH0 D', 'IH2 N V AE1 L AH0 D'],
  "invalidate": ['IH2 N V AE1 L IH0 D EY2 T'],
  "invalidated": ['IH2 N V AE1 L AH0 D EY2 T AH0 D', 'IH2 N V AE1 L IH0 D EY2 T IH0 D'],
  "invalidating": ['IH2 N V AE1 L AH0 D EY2 T IH0 NG'],
  "invalidation": ['IH2 N V AE2 L AH0 D EY1 SH AH0 N'],
  "invalids": ['IH1 N V AH0 L AH0 D Z'],
  "invaluable": ['IH2 N V AE1 L Y AH0 B AH0 L'],
  "invariable": ['IH2 N V EH1 R IY0 AH0 B AH0 L'],
  "invariably": ['IH2 N V EH1 R IY0 AH0 B L IY0'],
  "invariance": ['IH2 N V EH1 R IY0 AH0 N S'],
  "invariant": ['IH2 N V EH1 R IY0 AH0 N T'],
  "invasion": ['IH2 N V EY1 ZH AH0 N'],
  "invasions": ['IH2 N V EY1 ZH AH0 N Z'],
  "invasive": ['IH2 N V EY1 S IH0 V'],
  "invective": ['IH2 N V EH1 K T IH0 V'],
  "invent": ['IH2 N V EH1 N T'],
  "invented": ['IH2 N V EH1 N T AH0 D', 'IH2 N V EH1 N T IH0 D'],
  "inventing": ['IH2 N V EH1 N T IH0 NG'],
  "invention": ['IH2 N V EH1 N SH AH0 N'],
  "inventions": ['IH2 N V EH1 N SH AH0 N Z'],
  "inventive": ['IH2 N V EH1 N T IH0 V'],
  "inventiveness": ['IH2 N V EH1 N T IH0 V N AH0 S'],
  "inventor": ['IH2 N V EH1 N T ER0'],
  "inventoried": ['IH1 N V AH0 N T AO2 R IY0 D'],
  "inventories": ['IH2 N V AH0 N T AO1 R IY0 Z'],
  "inventors": ['IH2 N V EH1 N T ER0 Z'],
  "inventory": ['IH2 N V AH0 N T AO1 R IY0'],
  "inventory's": ['IH2 N V AH0 N T AO1 R IY0 Z'],
  "invents": ['IH2 N V EH1 N T S'],
  "inverlat": ['IH1 N V ER0 L AE2 T'],
  "inverness": ['IH1 N V ER0 N EH2 S'],
  "inverse": ['IH2 N V ER1 S'],
  "inversely": ['IH2 N V ER1 S L IY0'],
  "inversion": ['IH2 N V ER1 ZH AH0 N'],
  "invert": ['IH2 N V ER1 T'],
  "invertebrate": ['IH2 N V ER1 T AH0 B R AH0 T', 'IH2 N V ER1 T AH0 B R EY2 T'],
  "invertebrates": ['IH2 N V ER1 T AH0 B R AH0 T S', 'IH2 N V ER1 T AH0 B R EY2 T S'],
  "inverted": ['IH2 N V ER1 T IH0 D'],
  "invesco": ['IH2 N V EH1 S K OW0'],
  "invesco's": ['IH2 N V EH1 S OW0 Z'],
  "invest": ['IH2 N V EH1 S T'],
  "investable": ['IH2 N V EH1 S T AH0 B AH0 L'],
  "investcorp": ['IH2 N V EH1 S T K AO0 R P'],
  "invested": ['IH2 N V EH1 S T AH0 D', 'IH2 N V EH1 S T IH0 D'],
  "investigate": ['IH2 N V EH1 S T AH0 G EY2 T'],
  "investigated": ['IH2 N V EH1 S T AH0 G EY2 T AH0 D', 'IH2 N V EH1 S T AH0 G EY2 T IH0 D'],
  "investigates": ['IH2 N V EH1 S T AH0 G EY2 T S'],
  "investigating": ['IH2 N V EH1 S T AH0 G EY2 T IH0 NG'],
  "investigation": ['IH2 N V EH2 S T AH0 G EY1 SH AH0 N'],
  "investigational": ['IH2 N V EH0 S T IH0 G EY1 SH AH0 N AH0 L'],
  "investigations": ['IH2 N V EH2 S T AH0 G EY1 SH AH0 N Z'],
  "investigative": ['IH2 N V EH1 S T AH0 G EY2 T IH0 V'],
  "investigator": ['IH2 N V EH1 S T AH0 G EY2 T ER0'],
  "investigator's": ['IH2 N V EH1 S T AH0 G EY2 T ER0 Z'],
  "investigators": ['IH2 N V EH1 S T AH0 G EY2 T ER0 Z'],
  "investigators'": ['IH2 N V EH1 S T AH0 G EY2 T ER0 Z'],
  "investigatory": ['IH2 N V EH1 S T AH0 G AH0 T AO2 R IY0'],
  "investimento": ['IH2 N V EH2 S T IH0 M EH1 N T OW2'],
  "investing": ['IH2 N V EH1 S T IH0 NG'],
  "investissements": ['IH2 N V EH2 S T IY1 Z IH0 M AA0 N T S'],
  "investiture": ['IH2 N V EH1 S T AH0 CH ER0'],
  "investment": ['IH2 N V EH1 S T M AH0 N T', 'IH2 N V EH1 S M AH0 N T'],
  "investment's": ['IH2 N V EH1 S T M AH0 N T S', 'IH2 N V EH1 S M AH0 N T S'],
  "investments": ['IH2 N V EH1 S T M AH0 N T S', 'IH2 N V EH1 S M AH0 N T S'],
  "investments'": ['IH2 N V EH1 S T M AH0 N T S', 'IH2 N V EH1 S M AH0 N T S'],
  "investnet": ['IH2 N V EH1 S T N EH2 T'],
  "investor": ['IH2 N V EH1 S T ER0'],
  "investor's": ['IH2 N V EH1 S T ER0 Z'],
  "investors": ['IH2 N V EH1 S T ER0 Z'],
  "investors'": ['IH2 N V EH1 S T ER0 Z'],
  "invests": ['IH2 N V EH1 S T S'],
  "inveterate": ['IH2 N V EH1 T ER0 AH0 T'],
  "invidious": ['IH2 N V IH1 D IY0 AH0 S'],
  "invigorate": ['IH2 N V IH1 G ER0 IH0 T'],
  "invigorated": ['IH2 N V IH1 G ER0 EY2 T IH0 D'],
  "invigorating": ['IH2 N V IH1 G ER0 EY2 T IH0 NG'],
  "invincibility": ['IH2 N V IH2 N S AH0 B IH1 L IH0 T IY0'],
  "invincible": ['IH2 N V IH1 N S AH0 B AH0 L'],
  "inviolable": ['IH2 N V AY1 AH0 L AH0 B AH0 L'],
  "inviolate": ['IH2 N V AY1 AH0 L IH0 T'],
  "invirase": ['IH2 N V AY1 R EY2 Z'],
  "invisibility": ['IH2 N V IH2 Z AH0 B IH1 L AH0 T IY0'],
  "invisible": ['IH2 N V IH1 Z AH0 B AH0 L'],
  "invisibles": ['IH2 N V IH1 Z AH0 B AH0 L Z'],
  "invitation": ['IH2 N V IH0 T EY1 SH AH0 N'],
  "invitational": ['IH2 N V AH0 T EY1 SH AH0 N AH0 L'],
  "invitations": ['IH2 N V IH0 T EY1 SH AH0 N Z'],
  "invite": ['IH2 N V AY1 T'],
  "invited": ['IH2 N V AY1 T AH0 D', 'IH2 N V AY1 T IH0 D'],
  "invitee": ['IH2 N V AY0 T IY1'],
  "invitees": ['IH2 N V AY0 T IY1 Z'],
  "invites": ['IH2 N V AY1 T S'],
  "inviting": ['IH2 N V AY1 T IH0 NG'],
  "invitron": ['IH1 N V IH0 T R AA0 N'],
  "invitron's": ['IH1 N V IH0 T R AA0 N Z'],
  "invocation": ['IH2 N V AH0 K EY1 SH AH0 N'],
  "invoice": ['IH1 N V OY0 S'],
  "invoiced": ['IH0 N V OY1 S T'],
  "invoices": ['IH1 N V OY0 S IH0 Z'],
  "invoicing": ['IH1 N V OY2 S IH0 NG'],
  "invoke": ['IH2 N V OW1 K'],
  "invoked": ['IH2 N V OW1 K T'],
  "invokes": ['IH2 N V OW1 K S'],
  "invoking": ['IH2 N V OW1 K IH0 NG'],
  "involuntarily": ['IH2 N V OW0 L AH1 N T ER0 IH2 L IY0', 'IH2 N V AA2 L AH0 N T ER1 AH0 L IY0'],
  "involuntary": ['IH2 N V AA1 L AH0 N T EH2 R IY0'],
  "involve": ['IH2 N V AA1 L V'],
  "involved": ['IH2 N V AA1 L V D'],
  "involvement": ['IH2 N V AA1 L V M AH0 N T'],
  "involvements": ['IH2 N V AA1 L V M AH0 N T S'],
  "involves": ['IH2 N V AA1 L V Z'],
  "involving": ['IH2 N V AA1 L V IH0 NG'],
  "invulnerability": ['IH2 N V AH2 L N ER0 AH0 B IH1 L IH0 T IY0'],
  "invulnerable": ['IH2 N V AH1 L N ER0 AH0 B AH0 L'],
  "inward": ['IH1 N W ER0 D'],
  "inwardly": ['IH1 N W ER0 D L IY0'],
  "inwood": ['IH1 N W UH2 D'],
  "iny": ['IH1 N IY0'],
  "inyokern": ['IH2 N Y OW1 K ER0 N'],
  "inzer": ['IH1 N Z ER0'],
  "io": ['AY1 OW0'],
  "iodice": ['AY1 AH0 D AY2 S', 'AY2 AH0 D IY1 S EY0'],
  "iodide": ['AY1 AH0 D AY2 D'],
  "iodide's": ['AY1 AH0 D AY2 D Z'],
  "iodides": ['AY1 AH0 D AY2 D Z'],
  "iodine": ['AY1 AH0 D AY2 N'],
  "iola": ['AY0 OW1 L AH0'],
  "iolande": ['IY0 OW0 L AA1 N D IY0'],
  "iolanthe": ['IY0 AH0 L AA1 N TH EY0'],
  "iole": ['IY0 OW1 L IY0'],
  "iomega": ['AY2 OW0 M EY1 G AH0'],
  "ion": ['AY1 AH0 N', 'AY1 AA2 N'],
  "iona": ['AY0 OW1 N AH0'],
  "ionarde": ['IY0 AH0 N AA1 R D EY0'],
  "ione": ['AY0 OW1 N IY0'],
  "ionic": ['AY2 AA1 N IH0 K'],
  "ionics": ['AY2 AA1 N IH0 K S'],
  "ionics's": ['AY2 AA1 N IH0 K S IH0 Z'],
  "ionization": ['AY2 AH0 N AH0 Z EY1 SH AH0 N'],
  "ionize": ['AY1 AH0 N AY2 Z'],
  "ionizer": ['AY1 AH0 N AY2 Z ER0'],
  "ionizers": ['AY1 AH0 N AY2 Z ER0 Z'],
  "ionizing": ['AY1 AH0 N AY2 Z IH0 NG'],
  "ionosphere": ['AY2 AA1 N AO0 S F IY2 R'],
  "ionospheric": ['AY2 AA2 N AO0 S F EH1 R IH0 K'],
  "ions": ['AY1 AH0 N Z', 'AY1 AA2 N Z'],
  "iorio": ['IY0 AO1 R IY0 OW2'],
  "ios": ['IY1 OW0 S', 'AY1 OW0 S'],
  "iosif": ['AY1 AH0 S IH0 F', 'Y EH1 S AH0 F'],
  "iosue": ['AY0 OW1 S UW0'],
  "iota": ['AY2 OW1 T AH0'],
  "iott": ['AY1 AH0 T'],
  "iou": ['AY2 OW2 Y UW1'],
  "iovine": ['IY0 OW0 V IY1 N IY0'],
  "iovino": ['IY0 OW0 V IY1 N OW0'],
  "iowa": ['AY1 AH0 W AH0', 'AY1 OW0 AH0'],
  "iowa's": ['AY1 AH0 W AH0 Z', 'AY1 OW0 AH0 Z'],
  "iowan": ['AY1 AH0 W AH0 N', 'AY1 OW0 AH0 N'],
  "iowans": ['AY1 AH0 W AH0 N Z', 'AY1 OW0 AH0 N Z'],
  "ip": ['AY1 P IY1', 'IH1 P'],
  "ipad": ['AY1 P AE2 D'],
  "ipad's": ['AY1 P AE2 D Z'],
  "ipads": ['AY1 P AE2 D Z'],
  "ipalco": ['IY0 P AE1 L K OW0'],
  "ipalco's": ['IY0 P AE1 L K OW0 Z'],
  "ipco": ['IH1 P K OW0'],
  "iphegenia": ['IH2 F AH0 JH AH0 N AY1 AH0'],
  "iphiginia": ['IH2 F AH0 JH IY0 N IY1 AH0', 'IH2 F AH0 JH IY0 N AY1 AH0'],
  "iphone": ['AY1 F OW2 N'],
  "iphones": ['AY1 F OW2 N Z'],
  "ipock": ['IH1 P AH0 K'],
  "ipod": ['AY1 P AA2 D'],
  "ipods": ['AY1 P AA2 D Z'],
  "ippolito": ['IH2 P OW0 L IY1 T OW0'],
  "ipsco": ['IH1 P S K OW0'],
  "ipsen": ['IH1 P S AH0 N'],
  "iptay": ['IH1 P T EY0'],
  "iq": ['AY1 K Y UW1'],
  "iq's": ['AY1 K Y UW1 Z'],
  "iqbal": ['IH1 K B AH0 L'],
  "ira": ['AY1 R AH0', 'AY2 AA2 R EY1'],
  "ira's": ['AY1 R AH0 Z'],
  "iran": ['IH2 R AA1 N', 'AY2 R AE1 N'],
  "iran's": ['IH2 R AE1 N Z', 'AY2 R AE1 N Z'],
  "iranamok": ['AY2 R AH0 N AA1 M AA0 K'],
  "irangate": ['IH2 R AA1 N G EY2 T'],
  "irani": ['IH2 R AA1 N IY0'],
  "iranian": ['IH2 R AA1 N IY0 AH0 N', 'AY0 R EY1 N IY0 AH0 N'],
  "iranians": ['AY0 R EY1 N IY0 AH0 N Z', 'IH2 R AA1 N IY0 AH0 N Z'],
  "iranians'": ['AY0 R EY1 N IY0 AH0 N Z'],
  "iranscam": ['AY0 R AE1 N S K AE0 M'],
  "iraq": ['IH2 R AA1 K', 'IY2 R AA1 K', 'AY2 R AA1 K'],
  "iraq's": ['IH2 R AA1 K S', 'IY2 R AA1 K S', 'AY2 R AA1 K S'],
  "iraqgate": ['IH2 R AA1 K G EY2 T', 'IY2 R AA1 K G EY2 T', 'AY2 R AA1 K G EY2 T'],
  "iraqi": ['IH2 R AE1 K IY0', 'IY2 R AE1 K IY0', 'AY2 R AE1 K IY0'],
  "iraqi's": ['IH2 R AE1 K IY0 Z', 'IY2 R AE1 K IY0 Z', 'AY2 R AE1 K IY0 Z'],
  "iraqis": ['IH2 R AE1 K IY0 Z', 'IY2 R AE1 K IY0 Z', 'AY2 R AE1 K IY0 Z'],
  "iras": ['AY1 R AH0 Z', 'AY1 AA1 R EY1 Z'],
  "irascible": ['IH2 R AE1 S IH0 B AH0 L'],
  "irascibly": ['IH2 R AE1 S IH0 B L IY0'],
  "irate": ['AY0 R EY1 T'],
  "irbinna": ['ER0 B IH1 N AH0'],
  "irby": ['ER1 B IY0'],
  "ire": ['AY1 R'],
  "ireene": ['AY0 R IY1 N'],
  "irelan": ['IH2 R EY0 L AA1 N'],
  "ireland": ['AY1 ER0 L AH0 N D', 'AY1 R L AH0 N D'],
  "ireland's": ['AY1 R L AH0 N D Z'],
  "irelands": ['AY1 R L AH0 N D Z'],
  "irell": ['AY0 R EH1 L'],
  "irena": ['IH2 R EY1 N AH0'],
  "irene": ['AY0 R IY1 N'],
  "irene's": ['AY2 R IY1 N Z'],
  "ireson": ['IH1 R IH0 S AH0 N', 'AY1 ER0 S AH0 N'],
  "ireta": ['IH2 R EY1 T AH0'],
  "ireton": ['IH1 R IH0 T AA0 N', 'AY1 ER0 T AH0 N'],
  "iretta": ['IH2 R EH1 T AH0'],
  "irette": ['IH2 R EH1 T'],
  "irey": ['AY1 R IY0'],
  "irian": ['AY1 R IY0 AH0 N'],
  "irianese": ['AY0 R IY1 AH0 N IY2 S'],
  "irick": ['IH1 R IH0 K'],
  "iridescent": ['IH2 R AH0 D EH1 S AH0 N T'],
  "iridium": ['IH2 R IH1 D IY0 AH0 M'],
  "irimajiri": ['AY0 R IY2 M AH0 JH IH1 R IY0'],
  "irina": ['IH2 R IY1 N AH0'],
  "iris": ['AY1 R AH0 S', 'AY1 R IH0 S'],
  "irises": ['AY1 R AH0 S IH0 Z'],
  "irish": ['AY1 R IH0 SH'],
  "irishman": ['AY1 R IH0 SH M AH0 N'],
  "irishness": ['AY1 R IH0 SH N IH0 S'],
  "irit": ['IH1 R IH0 T', 'AY1 AA1 R AY1 T IY1'],
  "irizarry": ['IH1 R IH0 Z AE0 R IY0'],
  "irk": ['ER1 K'],
  "irked": ['ER1 K T'],
  "irks": ['ER1 K S'],
  "irksome": ['ER1 K S AH0 M'],
  "irkutsk": ['ER0 K UH1 T S K', 'IH2 R K UH1 T S K'],
  "irlbeck": ['ER1 L B EH0 K'],
  "irma": ['ER1 M AH0'],
  "irma's": ['ER1 M AH0 Z'],
  "iron": ['AY1 ER0 N'],
  "ironclad": ['AY1 ER0 N K L AE2 D'],
  "ironed": ['AY1 ER0 N D'],
  "ironic": ['AY0 R AA1 N IH0 K'],
  "ironical": ['AY0 R AA1 N IH0 K AH0 L'],
  "ironically": ['AY0 R AA1 N IH0 K L IY0'],
  "ironies": ['AY1 R AH0 N IY0 Z'],
  "ironing": ['AY1 ER0 N IH0 NG', 'AY1 R N IH0 NG'],
  "ironize": ['AY1 ER0 N AY2 Z'],
  "ironized": ['AY1 ER0 N AY2 Z D'],
  "ironizer": ['AY1 ER0 N AY2 Z ER0'],
  "ironizes": ['AY1 ER0 N AY2 Z IH0 Z'],
  "ironizing": ['AY1 ER0 N AY2 Z IH0 NG'],
  "irons": ['AY1 ER0 N Z'],
  "ironside": ['AY1 ER0 N S AY2 D'],
  "ironsides": ['AY1 ER0 N S AY2 D Z'],
  "ironton": ['AY1 R N T AH0 N'],
  "ironwood": ['AY1 ER0 N W UH2 D'],
  "ironwood's": ['AY1 ER0 N W UH2 D Z'],
  "irony": ['AY1 R AH0 N IY0'],
  "iroquois": ['IH1 R AH0 K W OY2'],
  "irradiate": ['IH2 R EY1 D IY0 EY2 T'],
  "irradiated": ['IH2 R EY1 D IY0 EY2 T IH0 D'],
  "irradiation": ['IH2 R EY2 D IY0 EY1 SH AH0 N'],
  "irrational": ['IH2 R AE1 SH AH0 N AH0 L'],
  "irrationality": ['IH2 R AE2 SH AH0 N AE1 L AH0 T IY0'],
  "irrationally": ['IH2 R AE1 SH AH0 N AH0 L IY0', 'IH2 R AE1 SH N AH0 L IY0'],
  "irrawaddy": ['IH1 R AH0 W AA2 D IY0'],
  "irreconcilable": ['IH2 R EH1 K AH0 N S AY2 L AH0 B AH0 L'],
  "irredentism": ['IH2 R AH0 D EH1 N T IH0 Z AH0 M'],
  "irrefutable": ['IH2 R AH0 F Y UW1 T AH0 B AH0 L'],
  "irregardless": ['IH2 R AH0 G AA1 D L AH0 S'],
  "irregular": ['IH2 R EH1 G Y AH0 L ER0'],
  "irregularities": ['IH2 R EH0 G Y AH0 L EH1 R AH0 T IY0 Z'],
  "irregularity": ['IH2 R EH2 G Y AH0 L EH1 R AH0 T IY0'],
  "irregularly": ['IH2 R EH1 G Y AH0 L ER0 L IY0'],
  "irregulars": ['IH2 R EH1 G Y AH0 L ER0 Z'],
  "irrelevance": ['IH2 R EH1 L AH0 V AH0 N S'],
  "irrelevancy": ['IH2 R EH1 L AH0 V AH0 N S IY0'],
  "irrelevant": ['IH2 R EH1 L AH0 V AH0 N T'],
  "irremediable": ['IH2 R IH0 M IY1 D IY0 AH0 B AH0 L'],
  "irreparable": ['IH2 R EH1 P ER0 AH0 B AH0 L'],
  "irreparably": ['IH2 R EH1 P ER0 AH0 B L IY0'],
  "irreplaceable": ['IH2 R AH0 P L EY1 S AH0 B AH0 L'],
  "irrepressible": ['IH2 R AH0 P R EH1 S AH0 B AH0 L'],
  "irresistible": ['IH2 R IH0 Z IH1 S T AH0 B AH0 L'],
  "irresistibly": ['IH2 R IH0 Z IH1 S T AH0 B L IY0'],
  "irrespective": ['IH2 R AH0 S P EH1 K T IH0 V'],
  "irresponsibility": ['IH2 R AH0 S P AA2 N S AH0 B IH1 L AH0 T IY0'],
  "irresponsible": ['IH2 R AH0 S P AA1 N S AH0 B AH0 L'],
  "irresponsibly": ['IH2 R AH0 S P AA1 N S AH0 B L IY0'],
  "irretrievably": ['IH2 R AH0 T R IY1 V AH0 B L IY0'],
  "irreverence": ['IH2 R EH1 V ER0 AH0 N S'],
  "irreverent": ['IH2 R EH1 V ER0 AH0 N T'],
  "irreversible": ['IH2 R IH0 V ER1 S AH0 B AH0 L'],
  "irreversibly": ['IH2 R IH0 V ER1 S AH0 B L IY0'],
  "irrevocable": ['IH2 R EH1 V AH0 K AH0 B AH0 L'],
  "irrevocably": ['IH2 R EH1 V AH0 K AH0 B L IY0', 'IH2 R EH2 V OW1 K AH0 B L IY0'],
  "irrigate": ['IH1 R AH0 G EY2 T'],
  "irrigated": ['IH1 R AH0 G EY2 T IH0 D'],
  "irrigation": ['IH2 R AH0 G EY1 SH AH0 N'],
  "irrigator": ['IH1 R AH0 G EY2 T ER0'],
  "irrigators": ['IH1 R AH0 G EY2 T ER0 Z'],
  "irritability": ['IH2 R IH0 T AH0 B IH1 L AH0 T IY0'],
  "irritable": ['IH1 R AH0 T AH0 B AH0 L'],
  "irritant": ['IH1 R AH0 T AH0 N T'],
  "irritants": ['IH1 R AH0 T AH0 N T S'],
  "irritate": ['IH1 R IH0 T EY2 T'],
  "irritated": ['IH1 R AH0 T EY2 T AH0 D'],
  "irritates": ['IH1 R IH0 T EY2 T S'],
  "irritating": ['IH1 R AH0 T EY2 T IH0 NG'],
  "irritation": ['IH2 R IH0 T EY1 SH AH0 N'],
  "irritations": ['IH2 R IH0 T EY1 SH AH0 N Z'],
  "irs": ['AY1 AA2 R EH1 S'],
  "irv": ['ER1 V'],
  "irve": ['ER1 V', 'AY1 AA1 R V IY1 IY1'],
  "irvette": ['ER0 V EH1 T'],
  "irvin": ['ER1 V IH0 N'],
  "irvine": ['ER1 V AY0 N'],
  "irving": ['ER1 V IH0 NG'],
  "irving's": ['ER1 V IH0 NG Z'],
  "irwin": ['ER1 W AH0 N', 'ER1 W IH0 N'],
  "irwindale": ['ER1 W IH0 N D EY2 L'],
  "is": ['IH1 Z', 'IH0 Z'],
  "isa": ['IY1 S AH0'],
  "isaac": ['AY1 Z AH0 K', 'AY1 Z IH0 K'],
  "isaacks": ['IH1 S AA0 K S'],
  "isaacs": ['AY1 Z IH0 K S'],
  "isaacson": ['AY1 Z IH0 K S AH0 N'],
  "isaak": ['IH2 S AA1 K', 'AY1 Z AE0 K'],
  "isabel": ['IH1 Z AH0 B EH2 L'],
  "isabell": ['IH2 S AA0 B EH1 L'],
  "isabella": ['IH2 Z AH0 B EH1 L AH0'],
  "isabelle": ['IH1 Z AH0 B EH2 L'],
  "isackson": ['IH1 S AH0 K S AH0 N'],
  "isadore": ['IH2 S AA0 D AO1 R EY0', 'IH1 S AA0 D AO0 R'],
  "isaiah": ['AY2 Z EY1 AH0'],
  "isaksen": ['IH1 S AH0 K S AH0 N'],
  "isakson": ['IH1 S AH0 K S AH0 N'],
  "isaly": ['AY1 Z L IY0', 'AY1 S L IY0'],
  "isautier": ['AY0 S AO1 T Y ER0'],
  "isay": ['AY1 S EY2'],
  "isbell": ['IH1 S B EH0 L'],
  "isbill": ['IH2 S B IH1 L'],
  "isbn": ['AY2 EH2 S B IY2 EH1 N'],
  "iscariot": ['IH2 S K EH1 R IY0 AH0 T'],
  "isch": ['IH1 SH'],
  "ischemia": ['IH2 S K EH1 M IY2 AH0'],
  "ischemic": ['IH2 S K EH1 M IH0 K'],
  "ischo": ['IY1 SH OW0'],
  "ise": ['AY1 Z'],
  "iselin": ['IH1 S IH0 L IH0 N'],
  "iseman": ['AY1 S M AH0 N'],
  "iseminger": ['IH1 S IY0 M IH0 NG ER0'],
  "isenberg": ['AY1 Z AH0 N B ER0 G'],
  "isenhart": ['AY1 Z AH0 N HH AA2 R T'],
  "isenhour": ['IH1 S IH0 N AW0 R'],
  "isenhower": ['IH1 S IH0 N HH OW0 ER0'],
  "isensee": ['AY1 Z AH0 N S IY2'],
  "isentrope": ['AY1 Z AH0 N T R OW2 P'],
  "isentropic": ['AY2 Z AH0 N T R AA1 P AH0 K'],
  "iser": ['AY1 Z ER0'],
  "isetan": ['IH1 S IH0 T AH0 N'],
  "isgrigg": ['IH2 S G R IH1 G'],
  "isgro": ['IY1 S G R OW0'],
  "isgur": ['IH1 S G ER0'],
  "ish": ['IH1 SH'],
  "isham": ['IH1 SH AH0 M'],
  "ishaq": ['IH1 SH AE0 K'],
  "ishee": ['IH1 SH IY0'],
  "isherwood": ['IH1 SH ER0 W UH2 D'],
  "ishi": ['IH1 SH IY0'],
  "ishi's": ['IH1 SH IY0 Z'],
  "ishibashi": ['IH2 SH IY2 B AA1 SH IY2'],
  "ishida": ['IH2 SH IY1 D AA2'],
  "ishihara": ['IH2 SH IY2 HH AA1 R AA2'],
  "ishii": ['IH2 SH IY1 IY2'],
  "ishikawa": ['IH2 SH IY2 K AA1 W AA2'],
  "ishikawajima": ['IY2 SH IH2 K AA2 W AH0 JH IY1 M AA2'],
  "ishikura": ['IH2 SH IH0 K UH1 R AA2'],
  "ishimura": ['IY2 SH IH0 M UW1 R AA2'],
  "ishler": ['IH1 SH L ER0'],
  "ishmael": ['IH1 SH M IY0 L', 'IH1 SH M EY0 L'],
  "ishmail": ['IH1 SH M EY0 L'],
  "ishman": ['IH1 SH M AH0 N'],
  "ishtar": ['IH1 SH T AA0 R'],
  "isidore": ['IH1 Z IH0 D AO2 R'],
  "isikoff": ['IH1 Z AH0 K AO0 F'],
  "isis": ['AY1 S AH0 S'],
  "iskra": ['IH1 S K R AH0'],
  "isla": ['IY1 L AH0'],
  "islam": ['IH2 S L AA1 M', 'IH1 Z L AH0 M', 'IH1 S L AA2 M'],
  "islam's": ['IH2 S L AA1 M Z', 'IH1 S L AA2 M Z'],
  "islamabad": ['IH2 S L AE1 M AH0 B AE0 D'],
  "islamabad's": ['IH2 S L AE1 M AH0 B AE0 D Z'],
  "islami": ['IH2 Z L AA1 M IY0'],
  "islamic": ['IH2 Z L AA1 M IH0 K'],
  "islamically": ['IH2 Z L AA1 M IH0 K L IY0'],
  "islamist": ['IH1 S L AH0 M IH0 S T'],
  "islamists": ['IH1 S L AH0 M IH0 S T S'],
  "islamiya": ['IH2 S L AA1 M IY0 AH0'],
  "islamization": ['IH2 Z L AA0 M AH0 Z EY1 SH AH0 N'],
  "island": ['AY1 L AH0 N D'],
  "island's": ['AY1 L AH0 N D Z'],
  "islander": ['AY1 L AH0 N D ER0'],
  "islanders": ['AY1 L AH0 N D ER0 Z'],
  "islandia": ['AY2 L AE1 N D IY0 AH0'],
  "islands": ['AY1 L AH0 N D Z'],
  "islands'": ['AY1 S L AH0 N D Z'],
  "islas": ['AY1 L AH0 Z'],
  "isle": ['AY1 L'],
  "isleen": ['AY1 L IY0 N'],
  "isler": ['AY1 L ER0'],
  "isles": ['AY1 L Z'],
  "islet": ['AY1 L IH0 T'],
  "islets": ['AY1 L IH0 T S'],
  "isley": ['AY1 Z L IY0'],
  "islip": ['AY1 S L IH0 P'],
  "ism": ['IH1 Z AH0 M'],
  "ismael": ['IH1 S M EY0 L'],
  "ismail": ['IH1 S M EY0 L'],
  "isms": ['IH1 Z AH0 M Z'],
  "isn't": ['IH1 Z AH0 N T', 'IH0 Z AH0 N T', 'IH1 Z AH0 N'],
  "isner": ['IH1 S N ER0'],
  "isobel": ['AY1 S AO0 B EH2 L'],
  "isocyanate": ['AY2 S AH0 S AY1 AH0 N EY2 T'],
  "isoda": ['IY2 S OW1 D AH0'],
  "isoelectronic": ['AY2 S OW0 IH0 L EH0 K T R AA1 N IH0 K', 'AY2 S OW0 IY0 L EH0 K T R AA1 N IH0 K'],
  "isoetec": ['AY1 S OW0 T EH2 K'],
  "isogenic": ['AY2 S OW0 JH EH1 N AH0 K'],
  "isola": ['AY0 S AA1 L AH0'],
  "isolate": ['AY1 S AH0 L EY2 T'],
  "isolated": ['AY1 S AH0 L EY2 T AH0 D', 'AY1 S AH0 L EY2 T IH0 D'],
  "isolates": ['AY1 S AH0 L EY2 T S'],
  "isolating": ['AY1 S AH0 L EY2 T IH0 NG'],
  "isolation": ['AY2 S AH0 L EY1 SH AH0 N'],
  "isolationism": ['AY2 S AH0 L EY1 SH AH0 N IH2 Z AH0 M'],
  "isolationist": ['AY2 S AH0 L EY1 SH AH0 N AH0 S T'],
  "isolationists": ['AY2 S AH0 L EY1 SH AH0 N IH0 S T S', 'AY2 S AH0 L EY1 SH AH0 N IH0 S'],
  "isolde": ['IH0 S OW1 L D AH0', 'IH1 S OW0 L D'],
  "isom": ['AY1 S AH0 M'],
  "isomedix": ['AY2 S OW0 M EH1 D IH0 K S'],
  "isomedix's": ['AY2 S OW0 M EH1 D IH0 K S IH0 Z', 'AY2 S OW0 M EH1 D IH0 K S'],
  "isomer": ['AY1 S AH0 M ER0'],
  "isomers": ['AY1 S AH0 M ER0 Z'],
  "isomorphism": ['AY2 S AH0 M AO1 R F IH0 Z AH0 M'],
  "ison": ['IH1 S AH0 N'],
  "isoprinosine": ['IH2 S AH0 P R IH1 N AH0 S IY2 N'],
  "isosceles": ['AY0 S AO1 S AH0 L IY2 Z'],
  "isothermal": ['AY2 S AH0 TH ER1 M AH0 L'],
  "isotoner": ['IH1 Z OW0 T AH2 N ER0', 'AY1 S OW0 T OW2 N ER0'],
  "isotonic": ['AY2 S AH0 T AA1 N IH0 K'],
  "isotope": ['AY1 S AH0 T OW2 P'],
  "isotopes": ['AY1 S AH0 T OW2 P S'],
  "isotopic": ['AY2 S AH0 T AA1 P IH0 K'],
  "isoxicam": ['IH2 S AA1 K S IH0 K AH0 M'],
  "ispra": ['IH1 S P R AH0'],
  "israel": ['IH1 Z R IY0 AH0 L', 'IH1 Z R EY0 L'],
  "israel's": ['IH1 Z R EY0 L Z', 'IH1 Z R IY0 AH0 L Z'],
  "israeli": ['IH2 Z R EY1 L IY0'],
  "israeli's": ['IH2 Z R EY1 L IY0 Z'],
  "israelis": ['IH2 Z R EY1 L IY0 Z'],
  "israelis'": ['IH2 Z R EY1 L IY0 Z'],
  "israelite": ['IH1 Z R AH0 L AY2 T'],
  "israelites": ['IH1 Z R AH0 L AY2 T S'],
  "israeloff": ['IH2 S R EY1 L AO0 F'],
  "israelson": ['IH1 S R EH0 L S AH0 N'],
  "israhi": ['IH2 Z R AA1 HH IY0'],
  "isringhouser": ['IH1 S R IH0 NG HH AW2 Z ER0'],
  "issa": ['IH1 S AH0'],
  "issac": ['IH1 S AH0 K', 'AY1 Z IH0 K'],
  "issah": ['IH1 S AH0'],
  "issam": ['IH1 S AH0 M'],
  "issar": ['IY1 S AA2 R'],
  "issie": ['IH1 S IY0'],
  "issing": ['IH1 S IH0 NG'],
  "issuable": ['IH1 SH UW0 AH0 B AH0 L'],
  "issuance": ['IH1 SH UW0 AH0 N S'],
  "issuances": ['IH1 SH UW0 AH0 N S IH0 Z'],
  "issue": ['IH1 SH UW0'],
  "issue's": ['IH1 SH UW0 Z'],
  "issued": ['IH1 SH UW0 D'],
  "issueless": ['IH1 SH UW0 L AH0 S'],
  "issuer": ['IH1 SH UW0 ER0'],
  "issuer's": ['IH1 SH UW0 ER0 Z'],
  "issuers": ['IH1 SH UW0 ER0 Z'],
  "issuers'": ['IH1 S UW0 R Z'],
  "issues": ['IH1 SH UW0 Z'],
  "issues'": ['IH1 S UW0 Z'],
  "issuing": ['IH1 SH UW0 IH0 NG'],
  "issy": ['IH1 S IY0'],
  "istanbul": ['IH2 S T AA0 N B UW1 L'],
  "istat": ['IH1 S T AE0 T'],
  "istel": ['IH1 S T EH0 L'],
  "istel's": ['IH1 S T EH0 L Z'],
  "isthmus": ['IH1 S M AH0 S'],
  "istituto": ['IH2 S T IH0 T UW1 T OW0'],
  "istook": ['IH1 S T UH2 K'],
  "istre": ['IH1 S T ER0'],
  "isty": ['IH1 S T IY0', 'AY1 EH1 S T IY1 W AY1'],
  "isu": ['IH1 S UW2', 'AY1 EH1 S Y UW1'],
  "isuzu": ['IH2 S UW1 Z UW2'],
  "it": ['IH1 T', 'IH0 T'],
  "it'd": ['IH1 T AH0 D'],
  "it'll": ['IH1 T AH0 L', 'IH1 T L'],
  "it's": ['IH1 T S', 'IH0 T S'],
  "it-wit": ['IH1 T W IH1 T'],
  "ita": ['AY1 T AH0', 'AY1 T IY1 EY1'],
  "italia": ['IY0 T AE1 L IY0 AH0', 'IH2 T AE1 L Y AH0'],
  "italian": ['IH2 T AE1 L Y AH0 N'],
  "italiana": ['IH2 T AE2 L IY0 AE1 N AH0'],
  "italianate": ['IH2 T AE1 L Y AH0 N EY2 T'],
  "italiano": ['IH2 T AA0 L IY0 AA1 N OW0'],
  "italians": ['IH2 T AE1 L Y AH0 N Z'],
  "italic": ['AY0 T AE1 L IH0 K'],
  "italics": ['AY0 T AE1 L IH0 K S'],
  "italish": ['IH2 T AE1 L IH0 SH'],
  "italtel": ['IH2 T AE1 L T EH2 L'],
  "italy": ['IH1 T AH0 L IY0'],
  "italy's": ['IH1 T AH0 L IY0 Z'],
  "itamar": ['IY1 T AH0 M AA2 R'],
  "itami": ['IH2 T AA1 M IY2'],
  "itar": ['IY1 T AA2 R'],
  "itasca": ['AY0 T AE1 S K AA0'],
  "itch": ['IH1 CH'],
  "itches": ['IH1 CH AH0 Z', 'IH1 CH IH0 Z'],
  "itching": ['IH1 CH IH0 NG'],
  "itchy": ['IH1 CH IY0'],
  "itek": ['AY1 T EH2 K'],
  "itel": ['AY1 T EH2 L'],
  "item": ['AY1 T AH0 M'],
  "itemize": ['AY1 T AH0 M AY2 Z'],
  "itemized": ['AY1 T AH0 M AY2 Z D'],
  "itemizer": ['AY1 T AH0 M AY2 Z ER0'],
  "itemizers": ['AY1 T AH0 M AY2 Z ER0 Z'],
  "itemizes": ['AY1 T AH0 M AY2 Z IH0 Z'],
  "itemizing": ['AY1 T AH0 M AY2 Z IH0 NG'],
  "items": ['AY1 T AH0 M Z'],
  "iten": ['AY1 T AH0 N'],
  "iteration": ['IH2 T ER0 EY1 SH AH0 N'],
  "iterations": ['IH2 T AH0 R EY1 SH AH0 N Z'],
  "iterative": ['IH1 T ER0 EY2 T IH0 V'],
  "ithaca": ['IH1 TH AH0 K AH0'],
  "ithaca's": ['IH1 TH AH0 K AH0 Z'],
  "itinerant": ['AY0 T IH1 N ER0 AH0 N T'],
  "itineraries": ['AY0 T IH1 N ER0 EH2 R IY0 Z'],
  "itinerary": ['AY0 T IH1 N ER0 EH2 R IY0'],
  "itkin": ['IH2 T K IH1 N'],
  "ito": ['IY1 T OW0', 'IY1 T OW2'],
  "ito's": ['IY1 T OW0 Z', 'IY1 T OW2 Z'],
  "itochu": ['IY2 T OW1 CH UW2'],
  "itoh": ['IY1 T OW0'],
  "its": ['IH1 T S', 'IH0 T S'],
  "itself": ['IH2 T S EH1 L F'],
  "itstee": ['IH1 T S T IY0'],
  "itsy": ['IH1 T S IY0'],
  "itsy-bitsy": ['IH2 T S IY0 B IH1 T S IY0'],
  "ittner": ['IH1 T N ER0'],
  "itty": ['IH1 T IY0'],
  "itty-bitty": ['IH2 T IY0 B IH1 T IY0'],
  "itunes": ['AY1 T UW2 N Z'],
  "itzhak": ['IH1 T S AA0 K', 'IH1 T S AE0 K', 'IH1 T S HH AE0 K'],
  "itzkowitz": ['IH1 T S K AH0 W IH0 T S'],
  "iuliano": ['IY0 UW0 L IY0 AA1 N OW0', 'Y UW0 L IY0 AA1 N OW0'],
  "iva": ['IY1 V AH0'],
  "ivaco": ['AY1 V AH0 K OW2'],
  "ivan": ['AY1 V AH0 N'],
  "ivana": ['IH2 V AA1 N AH0'],
  "ivancic": ['IH2 V AE1 NG K IH0 K'],
  "ivane": ['IH1 V AH0 N'],
  "ivanhoe": ['AY1 V AH0 N HH OW2'],
  "ivanko": ['IY0 V AA1 N K OW0'],
  "ivanna": ['IH2 V AA1 N AH0'],
  "ivanoff": ['IH1 V AH0 N AO0 F'],
  "ivanov": ['IH1 V AH0 N AA0 V'],
  "ivanyenko": ['AY2 V AH0 N Y EH1 N K OW0'],
  "ivar": ['IH1 V ER0'],
  "ivatans": ['AY1 V AH0 T AH0 N Z'],
  "ivax": ['AY1 V AE0 K S'],
  "iven": ['AY1 V AH0 N'],
  "ivens": ['AY1 V AH0 N Z'],
  "iver": ['AY1 V ER0'],
  "ivern": ['AY1 V ER0 N'],
  "ivers": ['AY1 V ER0 Z'],
  "iversen": ['IH2 V ER1 S AH0 N'],
  "iverson": ['AY1 V ER0 S AH0 N'],
  "ivery": ['AY1 V ER0 IY0'],
  "ives": ['AY1 V Z'],
  "ivester": ['AY2 V EH1 S T ER0'],
  "ivey": ['AY1 V IY0'],
  "ivie": ['AY1 V IY0'],
  "ivins": ['IH1 V IH0 N Z'],
  "ivo": ['AY1 V OW2'],
  "ivor": ['AY1 V ER0'],
  "ivorian": ['AY0 V AO1 R IY0 AH0 N'],
  "ivorians": ['AY0 V AO1 R IY0 AH0 N Z'],
  "ivory": ['AY1 V ER0 IY0', 'AY1 V R IY0'],
  "ivory-coast": ['AY1 V ER0 IY0 K OW1 S T', 'AY1 V R IY0 K OW1 S T'],
  "ivy": ['AY1 V IY0'],
  "iwai": ['AY0 W AY1'],
  "iwaki": ['AY0 W AA1 K IY0'],
  "iwamoto": ['IH2 W AA2 M OW1 T OW2'],
  "iwan": ['IY1 W AA0 N'],
  "iwanicki": ['IH2 V AH0 N IH1 T S K IY2'],
  "iwanski": ['IH2 V AE1 N S K IY2'],
  "iwasaki": ['IY2 W AA2 S AA1 K IY2'],
  "iwata": ['IH0 W AA1 T AA2'],
  "iwate": ['AY0 W EY1 T'],
  "iwen": ['IH1 W AH0 N'],
  "iwerks": ['AY1 W ER2 K S'],
  "iwinski": ['IH2 V IH1 N S K IY2'],
  "iwo": ['AY1 W OW0'],
  "iwosaki": ['AY2 W AH0 S AA1 K IY0'],
  "ixion": ['IH0 K S AY1 AH0 N'],
  "iyer": ['AY1 ER0'],
  "izaguirre": ['IH0 Z AH0 G W IH1 R EY0', 'IH1 Z AH0 G W AY2 R'],
  "izansky": ['IH0 Z AE1 N S K IY0'],
  "izansky's": ['IH0 Z AE1 N S K IY0 Z'],
  "izard": ['IH1 Z ER0 D'],
  "izatt": ['IY1 Z AA0 T'],
  "izetbegovic": ['IH2 Z EH0 T B EH1 G OW0 V IH2 CH'],
  "izetbegovic's": ['IH2 Z EH0 T B EH1 G OW0 V IH2 CH IH0 Z'],
  "izod": ['AY1 Z AA0 D'],
  "izquierdo": ['IH0 Z K W IH1 R D OW0'],
  "izumi": ['IH0 Z UW1 M IY0'],
  "izvestia": ['IH0 Z V EH1 S T IY0 AH0'],
  "izzard": ['IH1 Z ER0 D'],
  "izzi": ['IH1 Z IY0'],
  "izzo": ['IH1 Z OW0'],
  "izzy": ['IH1 Z IY0'],
  "izzy's": ['IH1 Z IY0 Z'],
  "j": ['JH EY1'],
  "j's": ['JH EY1 Z'],
  "j.": ['JH EY1'],
  "j.'s": ['JH EY1 Z'],
  "ja": ['Y AA1'],
  "jaan": ['Y AA1 N', 'JH AE1 N'],
  "jab": ['JH AE1 B'],
  "jabah": ['JH AE1 B AH0'],
  "jabaliya": ['JH AA1 B AH0 L IY2 AH0'],
  "jabar": ['JH AH0 B AA1 R'],
  "jabar's": ['JH AH0 B AA1 R Z'],
  "jabbar": ['JH AH0 B AA1 R'],
  "jabbed": ['JH AE1 B D'],
  "jabbing": ['JH AE1 B IH0 NG'],
  "jabbour": ['ZH AH0 B UH1 R'],
  "jaber": ['JH EY1 B ER0'],
  "jablon": ['JH AE1 B L AH0 N'],
  "jablonowski": ['Y AH0 B L AH0 N AO1 F S K IY0'],
  "jablonski": ['Y AH0 B L AA1 N S K IY0'],
  "jablonsky": ['Y AH0 B L AA1 N S K IY0'],
  "jabril": ['JH AH0 B R IY1 L', 'JH AH0 B R IH1 L'],
  "jabs": ['JH AE1 B Z'],
  "jac": ['JH AE1 K'],
  "jacek": ['JH AA1 CH EH2 K'],
  "jachim": ['JH AE1 K IH0 M'],
  "jacinda": ['JH AH0 S IH1 N D AH0'],
  "jacinta": ['JH AH0 S IH1 N T AH0'],
  "jacinth": ['JH AE1 S IH0 N TH'],
  "jacintha": ['JH AH0 S IH1 N TH AH0'],
  "jacinthe": ['JH AE1 S IH0 N TH'],
  "jacinto": ['JH AH0 S IH1 N T OW0'],
  "jack": ['JH AE1 K'],
  "jack's": ['JH AE1 K S'],
  "jacka": ['JH AE1 K AH0'],
  "jackal": ['JH AE1 K AH0 L'],
  "jackass": ['JH AE1 K AE2 S'],
  "jacked": ['JH AE1 K T'],
  "jackel": ['JH AE1 K AH0 L'],
  "jackelyn": ['JH AE1 K IH0 L IH0 N'],
  "jacket": ['JH AE1 K AH0 T', 'JH AE1 K IH0 T'],
  "jacketed": ['JH AE1 K AH0 T IH0 D'],
  "jackets": ['JH AE1 K IH0 T S'],
  "jackett": ['JH AE1 K IH0 T'],
  "jackhammer": ['JH AE1 K HH AE2 M ER0'],
  "jackhammers": ['JH AE1 K HH AE2 M ER0 Z'],
  "jacki": ['JH AE1 K IY0'],
  "jacki's": ['JH AE1 K IY0 Z'],
  "jackie": ['JH AE1 K IY0'],
  "jackie's": ['JH AE1 K IY0 Z'],
  "jacking": ['JH AE1 K IH0 NG'],
  "jackley": ['JH AE1 K L IY0'],
  "jacklin": ['JH AE1 K L IH0 N'],
  "jacklyn": ['JH AE1 K L IH0 N'],
  "jackman": ['JH AE1 K M AH0 N'],
  "jacko": ['JH AE1 K OW0'],
  "jackoby": ['JH AE1 K AH0 B IY0'],
  "jackovich": ['JH AE1 K AH0 V IH0 CH'],
  "jackowski": ['Y AH0 S K AO1 F S K IY0'],
  "jackpot": ['JH AE1 K P AA2 T'],
  "jackpots": ['JH AE1 K P AA2 T S'],
  "jackrabbit": ['JH AE1 K R AE2 B AH0 T'],
  "jacks": ['JH AE1 K S'],
  "jacksdeit": ['JH AE1 K S D IY0 AH0 T'],
  "jackson": ['JH AE1 K S AH0 N'],
  "jackson's": ['JH AE1 K S AH0 N Z'],
  "jacksonian": ['JH AE0 K S OW1 N IY0 AH0 N'],
  "jacksonians": ['JH AE0 K S OW1 N IY0 AH0 N Z'],
  "jacksons": ['JH AE1 K S AH0 N Z'],
  "jacksonville": ['JH AE1 K S AH0 N V IH2 L'],
  "jacksonville's": ['JH AE1 K S AH0 N V IH2 L Z'],
  "jacky": ['JH AE1 K IY0'],
  "jacky's": ['JH AE1 K IY0 Z'],
  "jaclyn": ['JH AE1 K L IH0 N'],
  "jaco": ['Y AA1 K OW0'],
  "jacob": ['JH EY1 K AH0 B'],
  "jacob's": ['JH EY1 K AH0 B Z'],
  "jacoba": ['Y AA0 K OW1 B AH0'],
  "jacobe": ['JH AE1 K AH0 B'],
  "jacobean": ['JH AE2 K AH0 B IY1 AH0 N'],
  "jacober": ['JH EY1 K AH0 B ER0'],
  "jacobi": ['Y AA0 K OW1 B IY0'],
  "jacobin": ['JH AE1 K AH0 B AH0 N', 'JH AE1 K AH0 B IH0 N'],
  "jacobina": ['Y AH0 K AH0 B AY1 N AH0'],
  "jacobine": ['JH AE1 K AH0 B AY2 N'],
  "jacobite": ['JH AE1 K AH0 B AY2 T'],
  "jacobites": ['JH AE1 K AH0 B AY2 T S'],
  "jacobo": ['Y AA0 K OW1 B OW0'],
  "jacoboski": ['JH AE2 K OW0 B AO1 S K IY0'],
  "jacobowitz": ['Y AH0 K AA1 B AH0 W IH0 T S'],
  "jacobs": ['JH EY1 K AH0 B Z'],
  "jacobs'": ['JH EY1 K AH2 B Z'],
  "jacobs's": ['JH EY1 K AH0 B Z IH0 Z'],
  "jacobsen": ['JH EY1 K AH0 B S AH0 N'],
  "jacobson": ['JH EY1 K AH0 B S AH0 N'],
  "jacobson's": ['JH EY1 K AH0 B S AH0 N Z'],
  "jacobus": ['JH AH0 K OW1 B AH0 S'],
  "jacoby": ['JH AH0 K OW1 B IY0'],
  "jacome": ['JH AH0 K OW1 M'],
  "jacor": ['JH EY1 K AO2 R'],
  "jacot": ['JH AE1 K AH0 T'],
  "jacox": ['JH AE1 K AA0 K S'],
  "jacque": ['ZH EY1 K'],
  "jacqueline": ['JH AE1 K W AH0 L IH0 N', 'JH AE1 K L IH0 N', 'JH AE1 K AH0 L IH0 N'],
  "jacquelyn": ['JH AE1 K W IH0 L IH0 N'],
  "jacques": ['ZH AA1 K', 'JH AE1 K'],
  "jacques'": ['ZH AA1 K S', 'JH AE1 K S'],
  "jacquet": ['JH AE1 K EH0 T', 'JH AE0 K EY1'],
  "jacquetta": ['JH AA0 K EH1 T AH0'],
  "jacquez": ['Y AA0 K W EH1 Z'],
  "jacquie": ['JH AE1 K W IY0'],
  "jacquin": ['JH AE1 K W IH0 N'],
  "jacquot": ['ZH AH0 K W OW1'],
  "jacuzzi": ['JH AH0 K UW1 Z IY0'],
  "jade": ['JH EY1 D'],
  "jaded": ['JH EY1 D AH0 D', 'JH EY1 D IH0 D'],
  "jadeite": ['JH EY1 D AY2 T'],
  "jades": ['JH EY1 D Z'],
  "jadwin": ['JH AE1 D W IH0 N'],
  "jae": ['JH EY1'],
  "jae's": ['JH EY1 Z'],
  "jaeckel": ['Y EH1 K AH0 L'],
  "jaegar": ['JH AE1 G ER0'],
  "jaeger": ['JH EH1 G ER0', 'Y AE1 G ER0'],
  "jaegers": ['JH EH1 G ER0 Z', 'Y AE1 G ER0 Z'],
  "jaekel": ['Y EH1 K AH0 L'],
  "jaenicke": ['Y EH1 N IH0 K'],
  "jafco": ['JH AE1 F K OW0'],
  "jafco's": ['JH AE1 F K OW0 Z'],
  "jaffa": ['JH AE1 F AH0'],
  "jaffar": ['JH AH0 F AA1 R'],
  "jaffe": ['JH AE1 F IY0'],
  "jaffee": ['JH AE1 F IY0'],
  "jaffey": ['JH AE1 F IY0'],
  "jaffna": ['JH AE1 F N AH0'],
  "jaffray": ['JH AE1 F R EY0'],
  "jaffray's": ['JH AE1 F R EY2 Z'],
  "jaffrey": ['JH AE1 F R IY0'],
  "jagdish": ['JH AE1 G D IH2 SH'],
  "jager": ['JH EY1 G ER0'],
  "jagged": ['JH AE1 G D'],
  "jagger": ['JH AE1 G ER0'],
  "jagger's": ['JH AE1 G ER0 Z'],
  "jaggers": ['JH AE1 G ER0 Z'],
  "jagiello": ['JH AA0 JH EH1 L OW0'],
  "jagielski": ['Y AH0 G IY1 L S K IY0'],
  "jaglom": ['Y AE1 G L AH0 M'],
  "jaglom's": ['Y AE1 G L AH0 M Z'],
  "jago": ['Y AA1 G OW0'],
  "jagoda": ['JH AA0 G OW1 D AH0'],
  "jagodzinski": ['Y AH0 G AH0 JH IH1 N S K IY0'],
  "jagow": ['Y AA1 G OW0'],
  "jags": ['JH AE1 G Z'],
  "jagt": ['JH AE1 T'],
  "jaguar": ['JH AE1 G W AA2 R'],
  "jaguar's": ['JH AE1 G W AA2 R Z'],
  "jaguars": ['JH AE1 G W AA2 R Z'],
  "jahanian": ['JH AA0 HH EY1 N IY2 AH0 N'],
  "jahn": ['JH AE1 N'],
  "jahner": ['JH AA1 N ER0'],
  "jahnke": ['JH AE1 NG K'],
  "jahns": ['JH AA1 N Z'],
  "jahr": ['JH AA1 R'],
  "jai": ['JH AY1', 'HH AY1'],
  "jail": ['JH EY1 L'],
  "jail's": ['JH EY1 L Z'],
  "jailbreak": ['JH EY1 L B R EY2 K'],
  "jailed": ['JH EY1 L D'],
  "jailer": ['JH EY1 L ER0'],
  "jailers": ['JH EY1 L ER0 Z'],
  "jailhouse": ['JH EY1 L HH AW2 S'],
  "jailing": ['JH EY1 L IH0 NG'],
  "jails": ['JH EY1 L Z'],
  "jaime": ['HH AY1 M IY0'],
  "jaime's": ['HH AY1 M IY0 Z'],
  "jaimes": ['HH AY1 M IY0 Z'],
  "jaimie": ['JH EY1 M IY0', 'HH AY1 M IY0'],
  "jain": ['JH AY1 N', 'JH EY1 N'],
  "jainism": ['JH EY1 N IH0 Z AH0 M'],
  "jaisigher": ['JH EY1 S AH0 G ER0'],
  "jakab": ['JH AE1 K AH0 B'],
  "jakarta": ['JH AH0 K AA1 R T AH0'],
  "jakarta's": ['JH AH0 K AA1 R T AH0 Z'],
  "jake": ['JH EY1 K'],
  "jake's": ['JH EY1 K S'],
  "jakel": ['JH AE1 K AH0 L'],
  "jakes": ['JH EY1 K S'],
  "jakeway": ['JH EY1 K W EY2'],
  "jakie": ['JH AE1 K IY0'],
  "jakob": ['JH AE1 K AH0 B'],
  "jakobsen": ['JH AE1 K AH0 B S AH0 N'],
  "jakubek": ['Y AH0 K UW1 B EH0 K'],
  "jakubiak": ['Y AH0 K AH1 B IY0 AE0 K'],
  "jakubik": ['Y AH0 K UW1 B IH0 K'],
  "jakubowicz": ['Y AH0 K AH1 B AH0 V IH0 CH'],
  "jakubowski": ['Y AH0 K AH0 B AO1 F S K IY0'],
  "jalalabad": ['JH AH0 L AE1 L AH0 B AE0 D'],
  "jalapeno": ['HH AE2 L AH0 P IY1 N Y OW0', 'JH AE2 L AH0 P IY1 N OW0'],
  "jalapenos": ['HH AE2 L AH0 P IY1 N Y OW0 Z'],
  "jalbert": ['JH AE1 L B ER0 T'],
  "jalopy": ['JH AH0 L AA1 P IY0'],
  "jam": ['JH AE1 M'],
  "jam's": ['JH AE1 M Z'],
  "jama": ['JH AE1 M AH0'],
  "jamaica": ['JH AH0 M EY1 K AH0'],
  "jamaica's": ['JH AH0 M EY1 K AH0 Z'],
  "jamaican": ['JH AH0 M EY1 K AH0 N'],
  "jamaicans": ['JH AH0 M EY1 K AH0 N Z'],
  "jamail": ['JH AH0 M EY1 L'],
  "jamal": ['JH AH0 M AA1 L'],
  "jamal's": ['JH AH0 M AA1 L Z'],
  "jamar": ['Y AA0 M AA1 R'],
  "jamb": ['JH AE1 M'],
  "jambalaya": ['JH AH2 M B AH0 L AY1 AH0'],
  "jambor": ['Y AA0 M B AO1 R'],
  "jamerson": ['JH AE1 M ER0 S AH0 N'],
  "jamerson's": ['JH AE1 M ER0 S AH0 N Z'],
  "james": ['JH EY1 M Z'],
  "james'": ['JH EY1 M Z'],
  "james's": ['JH EY1 M Z IH0 Z'],
  "jameson": ['JH EY1 M S AH0 N'],
  "jamestown": ['JH EY1 M Z T AW2 N'],
  "jamesway": ['JH EY1 M Z W EY2'],
  "jami": ['JH EY1 M IY0'],
  "jamie": ['JH EY1 M IY0'],
  "jamie's": ['JH EY1 M IY0 Z'],
  "jamieson": ['JH EY1 M IH0 S AH0 N'],
  "jamila": ['JH AH0 M IH1 L AH0'],
  "jamison": ['JH EY1 M IH0 S AH0 N'],
  "jammal": ['JH AA0 M AA1 L'],
  "jammed": ['JH AE1 M D'],
  "jammer": ['JH AE1 M ER0'],
  "jammers": ['JH AE1 M ER0 Z'],
  "jamming": ['JH AE1 M IH0 NG'],
  "jammu": ['JH AH0 M UW1'],
  "jammu's": ['JH AH0 M UW1 Z'],
  "jamrog": ['JH AE1 M R AH0 G'],
  "jamroz": ['Y AA1 M R OW0 Z'],
  "jams": ['JH AE1 M Z'],
  "jamshedpur": ['JH AE2 M SH EH1 D P ER0'],
  "jamu": ['JH AE0 M UW1'],
  "jan": ['JH AE1 N'],
  "jan's": ['JH AE1 N Z'],
  "jan.": ['JH AE1 N', 'JH AE1 N Y UW0 EH0 R IY0'],
  "jana": ['JH AE1 N AH0'],
  "janacek": ['Y AE1 N AH0 CH EH0 K'],
  "janachowski": ['JH AE2 N AH0 CH AW1 S K IY0'],
  "janak": ['Y AE1 N AH0 K'],
  "janardhan": ['JH AH0 N AA1 R D AH0 N'],
  "janas": ['JH AE1 N AH0 Z'],
  "janata": ['JH AH0 N AA1 T AH0'],
  "janca": ['JH AE1 NG K AH0'],
  "janco": ['JH AE1 NG K OW0'],
  "janczak": ['Y AE1 N CH AE0 K'],
  "janda": ['JH AE1 N D AH0'],
  "jandreau": ['ZH AH0 N D R OW1'],
  "jandt": ['JH AE1 N T'],
  "jane": ['JH EY1 N'],
  "jane's": ['JH EY1 N Z'],
  "janeane": ['JH EY1 N AE2 N'],
  "janecek": ['Y AE1 N IH0 CH EH0 K'],
  "janeczko": ['Y AH0 N EH1 CH K OW0'],
  "janeiro": ['JH AH0 N EH1 R OW2', 'ZH AH0 N EH1 R OW2'],
  "janek": ['Y AE1 N EH0 K'],
  "janel": ['JH AE1 N AH0 L'],
  "janella": ['JH AH0 N EH1 L AH0'],
  "janelle": ['ZH AH0 N EH1 L'],
  "janes": ['JH EY1 N Z'],
  "janesville": ['JH EY1 N Z V IH2 L'],
  "janet": ['JH AE1 N AH0 T', 'JH AE1 N IH0 T'],
  "janet's": ['JH AE1 N AH0 T S', 'JH AE1 N IH0 T S'],
  "janette": ['JH AH0 N EH1 T'],
  "janeway": ['JH EY1 N W EY2'],
  "janey": ['JH EY1 N IY0'],
  "jang": ['JH AE1 NG'],
  "jangle": ['JH AE1 NG G AH0 L'],
  "jangled": ['JH AE1 NG G AH0 L D'],
  "jani": ['Y AA1 N IY0'],
  "janiak": ['Y AE1 N IY0 AE0 K'],
  "janice": ['JH AE1 N AH0 S', 'JH AE1 N IH0 S'],
  "janicek": ['Y AE1 N IH0 CH EH0 K'],
  "janick": ['JH AE1 N IH0 K'],
  "janicke": ['JH AE1 N IH0 K'],
  "janicki": ['JH AH0 N IH1 K IY0'],
  "janie": ['JH EY1 N IY0'],
  "janiero": ['JH AH0 N EH1 R OW0', 'JH AH0 N Y EH1 R OW0'],
  "janiga": ['JH AE1 N IH0 G AH0'],
  "janii": ['JH AH0 N IY1'],
  "janik": ['Y AE1 N IH0 K'],
  "janikowski": ['Y AH0 N IH0 K AO1 F S K IY0'],
  "janina": ['JH AH0 N IY1 N AH0'],
  "janine": ['JH AH0 N IY1 N'],
  "janis": ['JH AE1 N IH0 S'],
  "janisch": ['JH AE1 N IH0 SH'],
  "janish": ['Y AE1 N IH0 SH'],
  "janiszewski": ['Y AH0 N IH0 SH EH1 F S K IY0'],
  "janitor": ['JH AE1 N AH0 T ER0'],
  "janitorial": ['JH AE2 N IH0 T AO1 R IY0 AH0 L'],
  "janitors": ['JH AE1 N AH0 T ER0 Z'],
  "jank": ['JH AE1 NG K'],
  "janka": ['JH AE1 NG K AH0'],
  "janke": ['JH AE1 NG K'],
  "jankiewicz": ['Y AE1 N K AH0 V IH0 CH'],
  "janklow": ['JH AE1 NG K L OW0'],
  "janko": ['JH AE1 NG K OW0'],
  "jankovic": ['JH AE1 NG K AH0 V IH0 K'],
  "jankovich": ['JH AE1 NG K AH0 V IH0 CH'],
  "jankowiak": ['Y AH0 NG K AW1 IY0 AE0 K'],
  "jankowski": ['Y AH0 NG K AO1 F S K IY0'],
  "jann": ['JH AE1 N'],
  "janna": ['JH AE1 N AH0'],
  "janney": ['JH AE1 N IY0'],
  "janning": ['JH AE1 N IH0 NG'],
  "jannock": ['JH AE1 N AH0 K'],
  "jannotta": ['JH AH0 N AA1 T AH0'],
  "janoff": ['JH AE1 N AO0 F'],
  "janofsky": ['JH AH0 N AO1 F S K IY0'],
  "janos": ['JH AE1 N OW0 S'],
  "janosik": ['Y AH0 N OW1 S IH0 K'],
  "janoski": ['Y AH0 N AW1 S K IY0'],
  "janosko": ['Y AH0 N OW1 S K OW0'],
  "janosky": ['Y AH0 N OW1 S K IY0'],
  "janota": ['Y AH0 N OW1 T AH0'],
  "janousek": ['Y AH0 N UW1 S EH0 K'],
  "janovsky": ['JH AH0 N AA1 V S K IY0'],
  "janow": ['Y AE1 N OW0'],
  "janowiak": ['Y AH0 N AW1 IY0 AE0 K'],
  "janowicz": ['Y AE1 N AH0 V IH0 CH'],
  "janowitz": ['Y AE1 N AH0 W IH0 T S'],
  "janowski": ['Y AH0 N AO1 F S K IY0'],
  "jans": ['JH AE1 N Z'],
  "jansa": ['JH AE1 N S AH0'],
  "jansen": ['JH AE1 N S AH0 N'],
  "jansenism": ['JH AE1 N S IH0 N IH2 Z AH0 M'],
  "jansenist": ['JH AE1 N S AH0 N IH0 S T'],
  "jansenists": ['JH AE1 N S AH0 N IH0 S T S'],
  "jansky": ['Y AE1 N S K IY0'],
  "jansma": ['Y AE1 N S M AH0'],
  "janson": ['JH AE1 N S AH0 N'],
  "janssen": ['JH AE1 N S AH0 N'],
  "janssens": ['JH AE1 N S AH0 N Z'],
  "jansson": ['JH AE1 N S AH0 N'],
  "janthina": ['Y AA0 N TH IY1 N AH0'],
  "jantz": ['JH AE1 N T S'],
  "jantzen": ['JH AE1 N T Z AH0 N'],
  "january": ['JH AE1 N Y UW0 EH2 R IY0'],
  "january's": ['JH AE1 N Y UW0 EH2 R IY0 Z'],
  "janus": ['JH EY1 N AH0 S'],
  "janusz": ['Y AA1 N UH0 SH'],
  "januszewski": ['Y AH0 N AH0 SH EH1 F S K IY0'],
  "janvier": ['JH AE1 N V IY0 ER0'],
  "janvrin": ['JH AE1 N V R IH0 N'],
  "janz": ['JH AE1 N Z'],
  "janzen": ['JH AE1 N Z AH0 N'],
  "jap": ['JH AE1 P'],
  "japan": ['JH AH0 P AE1 N'],
  "japan's": ['JH AH0 P AE1 N Z'],
  "japanese": ['JH AE2 P AH0 N IY1 Z'],
  "japanimation": ['JH AH0 P AE1 N IH0 M EY1 SH AH0 N'],
  "japans": ['JH AH0 P AE1 N Z'],
  "japonica": ['JH AH0 P AA1 N AH0 K AH0', 'JH AH0 P AA1 N IH0 K AH0'],
  "japs": ['JH AE1 P S'],
  "jaqua": ['Y AE1 K W AH0'],
  "jaquay": ['ZH AH0 K EY1'],
  "jaques": ['JH AE1 K S'],
  "jaquess": ['Y AA1 K W IH0 S'],
  "jaquez": ['Y AA0 K W EH1 Z'],
  "jaquish": ['JH AE1 K W IH0 SH'],
  "jaquith": ['JH AE1 K W IH0 TH'],
  "jar": ['JH AA1 R'],
  "jara": ['JH AA1 R AH0'],
  "jaramillo": ['JH AE2 R AH0 M IH1 L OW0'],
  "jarara": ['JH AH0 R AA1 R AH0'],
  "jarash": ['JH AA1 R AH0 SH'],
  "jarboe": ['JH AA1 R B OW2'],
  "jarchow": ['JH AA1 R CH AW2'],
  "jardin": ['JH AA1 R D IH0 N'],
  "jardine": ['JH AA1 R D AY2 N'],
  "jardine's": ['JH AA1 R D AY2 N Z'],
  "jarecki": ['Y ER0 EH1 T S K IY0'],
  "jared": ['JH EH1 R IH0 D'],
  "jarema": ['Y ER0 IY1 M AH0'],
  "jares": ['ZH EH1 R Z'],
  "jargon": ['JH AA1 R G AH0 N'],
  "jarislowsky": ['JH EH2 R IH0 S L AW1 S K IY0'],
  "jarman": ['JH AA1 R M AH0 N'],
  "jarmin": ['JH AA1 R M AH0 N'],
  "jarmin's": ['JH AA1 R M AH0 N Z'],
  "jarmon": ['Y AA0 R M AO1 N'],
  "jarnagin": ['JH AA1 R N AH0 JH IH0 N'],
  "jarnigan": ['JH AA1 R N IH0 G AE0 N'],
  "jaromir": ['JH AE1 R AH0 M IH0 R', 'Y AA1 R AH0 M IH0 R'],
  "jaros": ['Y AA1 R OW0 Z'],
  "jarosz": ['Y AA1 R AH0 SH'],
  "jaroszewski": ['Y ER0 AH0 SH EH1 F S K IY0'],
  "jaroszynski": ['JH EH2 R AH0 Z IH1 N S K IY0'],
  "jarrard": ['ZH ER0 AA1 R D'],
  "jarratt": ['JH AE1 R AH0 T'],
  "jarreau": ['ZH ER0 OW1'],
  "jarred": ['JH AA1 R D'],
  "jarrell": ['Y AA0 R EY1 L'],
  "jarrells": ['JH AE1 R AH0 L Z'],
  "jarrett": ['JH EH1 R AH0 T'],
  "jarriel": ['ZH ER0 IY1 L'],
  "jarriel's": ['ZH ER0 IY1 L Z'],
  "jarring": ['JH AA1 R IH0 NG'],
  "jarrod": ['JH AE1 R AH0'],
  "jarryd": ['JH AE1 R IH0 D'],
  "jars": ['JH AA1 R Z'],
  "jaruzelski": ['JH EH2 R AH0 Z EH1 L S K IY0', 'Y AE2 R AH0 Z EH1 L S K IY0'],
  "jarvi": ['JH AA1 R V IY0'],
  "jarvia": ['JH AA1 R V IY0 AH0'],
  "jarvie": ['JH AA1 R V IY0'],
  "jarvik": ['JH AA1 R V IH0 K'],
  "jarvinen": ['JH AA1 R V IH0 N AH0 N'],
  "jarvis": ['JH AA1 R V AH0 S', 'JH AA1 R V IH0 S'],
  "jarvis's": ['JH AA1 R V IH0 S IH0 Z'],
  "jaryl": ['JH AE1 R AH0 L'],
  "jarzombek": ['Y ER0 Z AA1 M B EH0 K'],
  "jas": ['JH AE1 S'],
  "jasek": ['Y AA1 S EH0 K'],
  "jasinowski": ['JH AE2 S IH0 N AW1 S K IY0'],
  "jasinski": ['Y AH0 S IH1 N S K IY0'],
  "jaskiewicz": ['Y AA1 S K AH0 V IH0 CH'],
  "jasko": ['Y AA1 S K OW0'],
  "jaskolski": ['Y AH0 S K OW1 L S K IY0'],
  "jaskot": ['Y AA1 S K AH0 T'],
  "jaskowiak": ['Y AH0 S K AW1 IY0 AE0 K'],
  "jaskulski": ['Y AH0 S K AH1 L S K IY0'],
  "jasmer": ['JH AE1 S AH0 M ER0'],
  "jasmin": ['JH AE1 Z M IH0 N'],
  "jasmina": ['Y AH0 S M AY1 N AH0'],
  "jasmine": ['JH AE1 Z M AH0 N', 'JH AE1 Z M IH0 N'],
  "jaso": ['Y AA1 S OW0'],
  "jason": ['JH EY1 S AH0 N'],
  "jason's": ['JH EY1 S AH0 N Z'],
  "jaspal": ['JH AE1 S P AA0 L'],
  "jasper": ['JH AE1 S P ER0'],
  "jasper's": ['JH AE1 S P ER0 Z'],
  "jaspers": ['JH AE1 S P ER0 Z'],
  "jasperson": ['JH AE1 S P ER0 S AH0 N'],
  "jass": ['JH AE1 S'],
  "jasso": ['JH AE1 S OW0'],
  "jaster": ['JH AE1 S T ER0'],
  "jauch": ['JH AO1 CH'],
  "jaudon": ['Y AW0 D AO1 N'],
  "jaundice": ['JH AO1 N D AH0 S'],
  "jaundiced": ['JH AO1 N D IH0 S T'],
  "jaunt": ['JH AO1 N T'],
  "jauntily": ['JH AO1 N T AH0 L IY0'],
  "jaunts": ['JH AO1 N T S'],
  "jaunty": ['JH AO1 N T IY0'],
  "jauregui": ['Y AW0 R EY1 G W IY0'],
  "jaurequi": ['Y AW0 R EY1 K W IY0'],
  "java": ['JH AA1 V AH0'],
  "javan": ['JH AH0 V AA1 N'],
  "javanese": ['JH AA2 V AH0 N IY1 Z'],
  "javascript": ['JH AA1 V AH0 S K R IH2 P T'],
  "javelin": ['JH AE1 V AH0 L AH0 N', 'JH AE1 V AH0 L IH0 N', 'JH AE1 V L AH0 N', 'JH AE1 V L IH0 N'],
  "javett": ['JH AE1 V AH0 T'],
  "javier": ['HH AA2 V IY0 EH1 R'],
  "javits": ['JH AE1 V IH0 T S'],
  "javorsky": ['Y AH0 V AO1 R S K IY0'],
  "jaw": ['JH AO1'],
  "jawad": ['JH AH0 W AA1 D'],
  "jawbone": ['JH AO1 B OW2 N'],
  "jawboning": ['JH AO1 B OW2 N IH0 NG'],
  "jawed": ['JH AO1 D'],
  "jaworowski": ['Y AH0 W ER0 AO1 F S K IY0'],
  "jaworski": ['JH AH0 W AO1 R S K IY0'],
  "jaws": ['JH AO1 Z'],
  "jay": ['JH EY1'],
  "jay's": ['JH EY1 Z'],
  "jaya": ['JH AY1 AH0'],
  "jayachandra": ['JH AY2 AH0 CH AA1 N D R AH0'],
  "jayashankar": ['JH AY2 AH0 SH AA1 NG K AA0 R'],
  "jaycee": ['JH EY1 S IY1'],
  "jaycees": ['JH EY1 S IY1 Z'],
  "jaycox": ['JH EY1 K AA2 K S'],
  "jayden": ['JH EY1 D AH0 N'],
  "jaye": ['JH EY1'],
  "jayewardene": ['JH EY1 W AO0 R D IY2 N'],
  "jayhawker": ['JH EY1 HH AO0 K ER0'],
  "jayme": ['JH EY1 M IY0'],
  "jaymes": ['JH EY1 M IY0 Z'],
  "jayne": ['JH EY1 N'],
  "jaynes": ['JH EY1 N Z'],
  "jayroe": ['JH EY1 R OW2'],
  "jays": ['JH EY1 Z'],
  "jayson": ['JH EY1 Z AH0 N'],
  "jaywalk": ['JH EY1 W AO2 K'],
  "jaywalking": ['JH EY1 W AO2 K IH0 NG'],
  "jazeera": ['JH AH0 Z IY1 R AH0'],
  "jazmin": ['JH AE1 Z M IH0 N'],
  "jazz": ['JH AE1 Z'],
  "jazzed": ['JH AE1 Z D'],
  "jazzman": ['JH AE1 Z M AE2 N'],
  "jazzmen": ['JH AE1 Z M AH0 N'],
  "jazzy": ['JH AE1 Z IY0'],
  "je": ['JH IY1'],
  "jealous": ['JH EH1 L AH0 S'],
  "jealousies": ['JH EH1 L AH0 S IY0 Z'],
  "jealously": ['JH EH1 L AH0 S L IY0'],
  "jealousness": ['JH EH1 L AH0 S N IH0 S'],
  "jealousy": ['JH EH1 L AH0 S IY0'],
  "jean": ['JH IY1 N'],
  "jean's": ['JH IY1 N Z'],
  "jean-baptiste": ['ZH AA2 N B AH0 P T IH1 S T'],
  "jean-bertrand": ['ZH AA2 N B EH0 R T R AA1 N D'],
  "jean-louis": ['ZH AA2 N L UW0 IY1'],
  "jean-luc": ['ZH EH0 N L UW1 K'],
  "jean-michel": ['ZH AA2 N M AH0 SH EH1 L'],
  "jean-michele": ['ZH AA2 N M AH0 SH EH1 L'],
  "jean-pierre": ['ZH AE2 N P Y EH1 R'],
  "jeana": ['JH IY1 N AH0'],
  "jeanbaptiste": ['ZH EH1 N B AH0 P T IH0 S T'],
  "jeanbertrand": ['JH IY1 N B ER0 T R AH0 N D', 'ZH AA2 N B EH0 R T R AA1 N D'],
  "jeancourt": ['JH IY1 N K AO2 R T', 'ZH AA1 NG K AO1 R T'],
  "jeane": ['JH IY1 N'],
  "jeaner": ['JH IY1 N ER0'],
  "jeanerette": ['JH IY2 N R EH1 T'],
  "jeanes": ['JH IY1 N Z'],
  "jeanette": ['JH AH0 N EH1 T'],
  "jeaney": ['JH IY1 N IY0'],
  "jeanie": ['JH IY1 N IY0'],
  "jeanine": ['JH AH0 N IY1 N'],
  "jeanlouis": ['ZH AA2 N L UW0 IY1 Z'],
  "jeanmichele": ['ZH AA2 N M AH0 SH EH1 L'],
  "jeanne": ['JH IY1 N'],
  "jeannette": ['JH AH0 N EH1 T'],
  "jeannie": ['JH IY1 N IY0'],
  "jeanniot": ['JH IY1 N IY0 AH0 T'],
  "jeanpierre": ['ZH AO0 N P IY0 EH1 R'],
  "jeans": ['JH IY1 N Z'],
  "jeansonne": ['JH EH1 N S AH0 N'],
  "jeb": ['JH EH1 B'],
  "jech": ['JH EH1 K'],
  "jed": ['JH EH1 D'],
  "jedi": ['JH EH1 D IY2'],
  "jedlicka": ['Y IH0 D L IH1 S K AH0'],
  "jee": ['JH IY1'],
  "jeep": ['JH IY1 P'],
  "jeep's": ['JH IY1 P S'],
  "jeepney": ['JH IY1 P N IY0'],
  "jeeps": ['JH IY1 P S'],
  "jeer": ['JH IH1 R'],
  "jeered": ['JH IH1 R D'],
  "jeering": ['JH IY1 R IH0 NG'],
  "jeers": ['JH IY1 R Z'],
  "jees": ['JH IY1 Z'],
  "jeeter": ['JH IY1 T ER0'],
  "jeeves": ['JH IY1 V Z'],
  "jeez": ['JH IY1 Z'],
  "jeeze": ['JH IY1 Z'],
  "jefcoat": ['JH EH1 F K OW2 T'],
  "jeff": ['JH EH1 F'],
  "jeff's": ['JH EH1 F S'],
  "jeffcoat": ['JH EH1 F K OW0 T'],
  "jeffe": ['JH EH1 F', 'JH EH1 F IY0'],
  "jeffer": ['JH EH1 F ER0'],
  "jefferey": ['JH EH1 F ER0 IY0'],
  "jefferies": ['JH EH1 F R IY0 Z'],
  "jefferies'": ['JH EH1 F ER0 IY0 Z'],
  "jefferies's": ['JH EH1 F R IY0 Z IH0 Z'],
  "jefferis": ['JH EH1 F ER0 IH0 S'],
  "jeffers": ['JH EH1 F ER0 Z'],
  "jefferson": ['JH EH1 F ER0 S AH0 N'],
  "jefferson's": ['JH EH1 F ER0 S AH0 N Z'],
  "jeffersonian": ['JH EH2 F ER0 S OW1 N IY0 AH0 N'],
  "jeffersonians": ['JH EH2 F ER0 S OW1 N IY0 AH0 N Z'],
  "jeffery": ['JH EH1 F ER0 IY0'],
  "jefferys": ['JH EH1 F ER0 IY0 Z'],
  "jeffey": ['JH EH1 F IY0'],
  "jeffords": ['JH EH1 F ER0 D Z'],
  "jeffress": ['JH EH1 F R IH0 S'],
  "jeffrey": ['JH EH1 F R IY0'],
  "jeffrey's": ['JH EH1 F R IY0 Z'],
  "jeffreys": ['JH EH1 F R IY0 Z'],
  "jeffries": ['JH EH1 F R IY0 Z'],
  "jeffry": ['JH EH1 F R IY0'],
  "jeffs": ['JH EH1 F S'],
  "jeffus": ['JH EH1 F AH0 S'],
  "jeffy": ['JH EH1 F IY0'],
  "jehle": ['JH EH1 HH AH0 L'],
  "jehovah": ['JH AH0 HH OW1 V AH0'],
  "jehovah's": ['JH AH0 HH OW1 V AH0 Z'],
  "jeju": ['JH EH1 JH UW2'],
  "jejune": ['JH EH2 JH UW1 N'],
  "jekel": ['JH EH1 K AH0 L'],
  "jekyll": ['JH EH1 K AH0 L'],
  "jelen": ['JH EH1 L AH0 N'],
  "jelinek": ['JH EH1 L AH0 N IH0 K', 'JH EH1 L IH0 N EH0 K'],
  "jelinski": ['Y IH0 L IH1 N S K IY0'],
  "jelisic": ['JH AH0 L IH1 S IH0 K'],
  "jelks": ['JH EH1 L K S'],
  "jell": ['JH EH1 L'],
  "jelled": ['JH EH1 L D'],
  "jelley": ['JH EH1 L IY0'],
  "jellied": ['JH EH1 L IY0 D'],
  "jellies": ['JH EH1 L IY0 Z'],
  "jellison": ['JH EH1 L IH0 S AH0 N'],
  "jello": ['JH EH1 L OW0'],
  "jello's": ['JH EH1 L OW0 Z'],
  "jelly": ['JH EH1 L IY0'],
  "jelly's": ['JH EH1 L IY0 Z'],
  "jellyfish": ['JH EH1 L IY0 F IH2 SH'],
  "jelton": ['JH EH1 L T AH0 N'],
  "jem": ['JH EH1 M'],
  "jem's": ['JH EH1 M Z'],
  "jemie": ['JH EH1 M IY0'],
  "jemima": ['JH EH0 M IY1 M AH0'],
  "jemimah": ['JH EH0 M AY1 M AH0'],
  "jemison": ['JH EH1 M IH0 S AH0 N'],
  "jemmie": ['JH EH1 M IY0'],
  "jemmott": ['JH EH1 M AH0 T'],
  "jen": ['JH EH1 N'],
  "jena": ['JH IY1 N AH0'],
  "jencks": ['JH EH1 NG K S'],
  "jendro": ['JH EH1 N D R OW0'],
  "jenin": ['JH EH1 N IH0 N'],
  "jenison": ['JH EH1 N IH0 S AH0 N'],
  "jenkens": ['JH EH1 NG K AH0 N Z'],
  "jenkin": ['JH EH1 NG K IH0 N'],
  "jenkins": ['JH EH1 NG K AH0 N Z', 'JH EH1 NG K IH0 N Z'],
  "jenkinson": ['JH EH1 NG K IH0 N S AH0 N'],
  "jenks": ['JH EH1 NG K S'],
  "jenn": ['JH EH1 N'],
  "jenn's": ['JH EH1 N Z'],
  "jenna": ['JH EH1 N AH0'],
  "jenne": ['JH EH1 N'],
  "jenner": ['JH EH1 N ER0'],
  "jenness": ['JH EH1 N IH0 S'],
  "jennett": ['JH EH1 N IH0 T'],
  "jennette": ['ZH IH0 N EH1 T'],
  "jennewein": ['JH EH1 N UW0 AY2 N'],
  "jenney": ['JH EH1 N IY0'],
  "jenni": ['JH EH1 N IY0'],
  "jennie": ['JH EH1 N IY0'],
  "jennifer": ['JH EH1 N AH0 F ER0'],
  "jennifer's": ['JH EH1 N AH0 F ER0 Z'],
  "jenning": ['JH EH1 N IH0 NG'],
  "jenninger": ['JH EH1 N IH0 NG ER0'],
  "jennings": ['JH EH1 N IH0 NG Z'],
  "jennison": ['JH EH1 N IH0 S AH0 N'],
  "jenny": ['JH EH1 N IY0'],
  "jenny's": ['JH EH1 N IY0 Z'],
  "jenrette": ['JH EH2 N R EH1 T'],
  "jenrette's": ['JH EH0 N R EH1 T S'],
  "jens": ['JH EH1 N Z'],
  "jensen": ['JH EH1 N S AH0 N'],
  "jenson": ['JH EH1 N S AH0 N'],
  "jenssen": ['JH EH1 N S AH0 N'],
  "jent": ['JH EH1 N T'],
  "jentsch": ['JH EH1 N CH'],
  "jentz": ['JH EH1 N T S'],
  "jeong": ['JH EH1 NG'],
  "jeopardize": ['JH EH1 P ER0 D AY2 Z'],
  "jeopardized": ['JH EH1 P ER0 D AY2 Z D'],
  "jeopardizes": ['JH EH1 P ER0 D AY2 Z IH0 Z'],
  "jeopardizing": ['JH EH1 P ER0 D AY2 Z IH0 NG'],
  "jeopardy": ['JH EH1 P ER0 D IY0'],
  "jeppesen": ['JH EH1 P IY0 Z AH0 N'],
  "jeppson": ['JH EH1 P S AH0 N'],
  "jepsen": ['JH EH1 P S AH0 N'],
  "jepson": ['JH EH1 P S AH0 N'],
  "jepson's": ['JH EH1 P S AH0 N Z'],
  "jerabek": ['JH EH1 R AH0 B IH0 K'],
  "jerald": ['JH EH1 R AH0 L D'],
  "jerboas": ['JH ER0 B OW1 AH0 Z'],
  "jerde": ['JH ER1 D'],
  "jerden": ['JH ER1 D AH0 N'],
  "jere": ['JH EH1 R IY0'],
  "jereb": ['JH EH1 R IH0 B'],
  "jereissati": ['JH EH0 R EY2 S AA1 T IY0'],
  "jerel": ['JH EH1 R AH0 L'],
  "jereld": ['JH EH1 R IH0 L D'],
  "jeremia": ['Y EH0 R IY1 M IY0 AH0'],
  "jeremiad": ['JH EH2 R AH0 M AY1 AH0 D'],
  "jeremiah": ['JH EH2 R AH0 M AY1 AH0'],
  "jeremy": ['JH EH1 R AH0 M IY2'],
  "jeremy's": ['JH EH1 R AH0 M IY2 Z'],
  "jerez": ['Y EH0 R EH1 Z'],
  "jergens": ['JH ER1 G AH0 N Z'],
  "jergenson": ['JH ER1 G IH0 N S AH0 N'],
  "jerger": ['JH ER1 G ER0'],
  "jeri": ['JH EH1 R IY0'],
  "jericho": ['JH EH1 R IH0 K OW2'],
  "jerk": ['JH ER1 K'],
  "jerk-off": ['JH ER1 K AO1 F'],
  "jerked": ['JH ER1 K T'],
  "jerking": ['JH ER1 K IH0 NG'],
  "jerkins": ['JH ER1 K IH0 N Z'],
  "jerks": ['JH ER1 K S'],
  "jerky": ['JH ER1 K IY0'],
  "jermaine": ['JH ER0 M EY1 N'],
  "jerman": ['JH ER1 M AH0 N'],
  "jermyn": ['JH ER1 M IH0 N'],
  "jernberg": ['JH ER1 N B ER0 G'],
  "jernigan": ['JH ER1 N IH0 G AE0 N'],
  "jeroboam": ['JH EH2 R AH0 B OW1 AH0 M'],
  "jerod": ['JH EH1 R AH0 D'],
  "jerold": ['JH EH1 R OW0 L D'],
  "jerome": ['JH ER0 OW1 M'],
  "jerr": ['JH ER1'],
  "jerrell": ['Y EH0 R EY1 L', 'JH EH1 R AH0 L'],
  "jerri": ['JH EH1 R IY0'],
  "jerrico": ['JH EH1 R IH0 K OW2'],
  "jerrie": ['JH EH1 R IY0'],
  "jerris": ['JH ER1 R AH0 S'],
  "jerritts": ['JH EH1 R IH0 T S'],
  "jerrold": ['JH EH1 R AH0 L D'],
  "jerrome": ['Y EH0 R OW1 M EY0', 'JH AH0 R OW1 M'],
  "jerry": ['JH EH1 R IY0'],
  "jerry's": ['JH EH1 R IY0 Z'],
  "jersey": ['JH ER1 Z IY0'],
  "jersey's": ['JH ER1 Z IY0 Z'],
  "jerseys": ['JH ER1 Z IY0 Z'],
  "jersian": ['JH ER1 Z IY0 AH0 N'],
  "jersians": ['JH ER1 Z IY0 AH0 N Z'],
  "jerusalem": ['JH ER0 UW1 S AH0 L AH0 M'],
  "jerusalem's": ['JH ER0 UW1 S AH0 L AH0 M Z'],
  "jerusha": ['JH EH1 R AH0 SH AH0'],
  "jervis": ['JH ER1 V IH0 S'],
  "jerzak": ['Y ER1 Z AH0 K'],
  "jerzy": ['JH ER1 Z IY0'],
  "jeschke": ['JH EH1 SH K'],
  "jeske": ['JH EH1 S K'],
  "jesmer": ['JH EH1 S AH0 M ER0'],
  "jesper": ['JH EH1 S P ER0'],
  "jespersen": ['JH EH1 S P ER0 S AH0 N'],
  "jesperson": ['JH EH1 S P ER0 S AH0 N'],
  "jess": ['JH EH1 S'],
  "jessalynn": ['JH EH1 S AH0 L IH0 N'],
  "jessamine": ['JH EH1 S AH0 M IH0 N'],
  "jessamyn": ['JH EH1 S AH0 M IH0 N'],
  "jesse": ['JH EH1 S IY0'],
  "jesse's": ['JH EH1 S IY0 Z'],
  "jessee": ['JH EH1 S IY1'],
  "jesselson": ['JH EH1 S AH0 L S AH0 N'],
  "jessen": ['JH EH1 S AH0 N'],
  "jesser": ['JH EH1 S ER0'],
  "jessica": ['JH EH1 S IH0 K AH0'],
  "jessica's": ['JH EH1 S IH0 K AH0 Z'],
  "jessie": ['JH EH1 S IY0'],
  "jesson": ['JH EH1 S AH0 N'],
  "jessop": ['JH EH1 S AH0 P'],
  "jessup": ['JH EH1 S AH2 P'],
  "jessy": ['JH EH1 S IY0'],
  "jessye": ['JH EH1 S IY0'],
  "jest": ['JH EH1 S T'],
  "jester": ['JH EH1 S T ER0'],
  "jester's": ['JH EH1 S T ER0 Z'],
  "jesters": ['JH EH1 S T ER0 Z'],
  "jests": ['JH EH1 S T S'],
  "jesudason": ['JH EH2 S UW1 D AH0 S AH0 N'],
  "jesuit": ['JH EH1 ZH UW0 IH0 T'],
  "jesuits": ['JH EH1 ZH UW0 IH0 T S'],
  "jesup": ['JH EH1 S AH0 P'],
  "jesus": ['JH IY1 Z AH0 S'],
  "jesus'": ['JH IY1 Z AH0 S'],
  "jesus's": ['JH IY1 Z AH0 S IH0 Z'],
  "jet": ['JH EH1 T'],
  "jet's": ['JH EH1 T S'],
  "jetborne": ['JH EH1 T B AO2 R N'],
  "jeter": ['JH IY1 T ER0'],
  "jethro": ['JH EH1 TH R OW0'],
  "jetliner": ['JH EH1 T L AY2 N ER0'],
  "jetliner's": ['JH EH1 T L AY2 N ER0 Z'],
  "jetliners": ['JH EH1 T L AY2 N ER0 Z'],
  "jetro": ['JH EH1 T R OW0'],
  "jets": ['JH EH1 T S'],
  "jets'": ['JH EH1 T S'],
  "jetson": ['JH EH1 T S AH0 N'],
  "jetsons": ['JH EH1 T S AH0 N Z'],
  "jetstream": ['JH EH1 T S T R IY2 M'],
  "jetstream's": ['JH EH1 T S T R IY2 M Z'],
  "jetstreams": ['JH EH1 T S T R IY2 M Z'],
  "jett": ['JH EH1 T'],
  "jett's": ['JH EH1 T S'],
  "jetta": ['JH EH1 T AH0'],
  "jette": ['JH EH1 T'],
  "jetted": ['JH EH1 T IH0 D'],
  "jetter": ['JH EH1 T ER0'],
  "jetting": ['JH EH1 T IH0 NG'],
  "jettison": ['JH EH1 T IH0 S AH0 N'],
  "jettisoned": ['JH EH1 T IH0 S AH0 N D'],
  "jettisoning": ['JH EH1 T AH0 S AH0 N IH0 NG'],
  "jetton": ['JH EH1 T AH0 N'],
  "jetty": ['JH EH1 T IY0'],
  "jeumont": ['JH UW1 M AA2 N T'],
  "jew": ['JH UW1'],
  "jewel": ['JH UW1 AH0 L', 'JH UW1 L'],
  "jewelcor": ['JH UW1 AH0 L K AO0 R'],
  "jeweler": ['JH UW1 AH0 L ER0', 'JH UW1 L ER0'],
  "jeweler's": ['JH UW1 AH0 L ER0 Z', 'JH UW1 L ER0 Z'],
  "jewelers": ['JH UW1 AH0 L ER0 Z', 'JH UW1 L ER0 Z'],
  "jewell": ['JH UW1 IH0 L'],
  "jewellers": ['JH UW1 AH0 L ER0 Z', 'JH UW1 L ER0 Z'],
  "jewelmaster": ['JH UW1 AH0 L M AE2 S T ER0', 'JH UW1 L M AE2 S T ER0'],
  "jewelmasters": ['JH UW1 AH0 L M AE2 S T ER0 Z'],
  "jewelry": ['JH UW1 AH0 L R IY0', 'JH UW1 L R IY0', 'JH UW1 L ER0 IY0'],
  "jewels": ['JH UW1 AH0 L Z', 'JH UW1 L Z'],
  "jewelsburg": ['JH UW1 AH0 L Z B ER0 G', 'JH UW1 L Z B ER0 G'],
  "jewess": ['JH UW1 AH0 S'],
  "jewett": ['JH UW1 IH0 T'],
  "jewfish": ['JH UW1 F IH2 SH'],
  "jewfishes": ['JH UW1 F IH2 SH IH0 Z'],
  "jewish": ['JH UW1 IH0 SH'],
  "jewishness": ['JH UW1 IH0 SH N AH0 S'],
  "jewitt": ['JH UW1 IH0 T'],
  "jewkes": ['JH UW1 K S'],
  "jewry": ['JH UW1 R IY0'],
  "jews": ['JH UW1 Z'],
  "jews'": ['JH UW1 Z'],
  "jex": ['JH EH1 K S'],
  "jeyaretnam": ['JH EY2 ER0 EH1 T N AH0 M'],
  "jezek": ['Y EH1 Z EH0 K'],
  "jezewski": ['Y IH0 Z EH1 F S K IY0'],
  "jezierski": ['Y IH0 Z IH1 R S K IY0'],
  "jeziorski": ['Y IH0 Z IY0 AO1 R S K IY0'],
  "jfet": ['JH F EH1 T'],
  "jfk": ['JH IY1 EH2 F K EY1'],
  "jha": ['JH AA1'],
  "jheel": ['JH IY1 L'],
  "jheryl": ['JH EH1 R AH0 L'],
  "jhirmack": ['JH ER1 M AE0 K'],
  "ji": ['JH IY1'],
  "jia": ['JH IY1 AH0'],
  "jian": ['JH IY1 AH0 N'],
  "jiang": ['JH AA1 NG', 'JH IY0 AA1 NG'],
  "jiang's": ['JH IY0 AA1 NG Z'],
  "jiangsu": ['JH Y AA1 NG S UW0'],
  "jiar": ['JH IY0 AA1 R'],
  "jiawen": ['JH IY1 W EH1 N'],
  "jib": ['JH IH1 B'],
  "jibe": ['JH AY1 B'],
  "jibes": ['JH AY1 B Z'],
  "jicha": ['JH IH1 CH AH0'],
  "jie": ['JH IY1'],
  "jif": ['JH IH1 F'],
  "jifeng": ['JH IY1 F EH1 NG'],
  "jiffi-snak": ['JH IH1 F IY0 S N AE2 K'],
  "jiffy": ['JH IH1 F IY0'],
  "jig": ['JH IH1 G'],
  "jiggetts": ['JH IH1 G IH0 T S'],
  "jiggle": ['JH IH1 G AH0 L'],
  "jiggling": ['JH IH1 G AH0 L IH0 NG', 'JH IH1 G L IH0 NG'],
  "jigs": ['JH IH1 G Z'],
  "jigsaw": ['JH IH1 G S AO2'],
  "jihad": ['JH IY1 HH AE0 D', 'JH IY1 HH AA0 D'],
  "jihadi": ['JH IY0 HH AA1 D IY2'],
  "jihadist": ['JH IY0 HH AA1 D IH2 S T'],
  "jihadists": ['JH IY0 HH AA1 D IH2 S T S'],
  "jil": ['JH IH1 L'],
  "jilek": ['JH IH1 L IH0 K'],
  "jiles": ['JH AY1 L Z'],
  "jill": ['JH IH1 L'],
  "jillian": ['JH IH1 L IY0 AH0 N', 'JH IH1 L Y AH0 N'],
  "jillion": ['JH IH1 L Y AH0 N'],
  "jillions": ['JH IH1 L Y AH0 N Z'],
  "jillson": ['JH IH1 L S AH0 N'],
  "jilt": ['JH IH1 L T'],
  "jilted": ['JH IH1 L T IH0 D'],
  "jim": ['JH IH1 M'],
  "jim's": ['JH IH1 M Z'],
  "jima": ['JH IY1 M AH0'],
  "jimbo's": ['JH IH1 M B OW0 Z'],
  "jimena": ['JH IH1 M AH0 N AH0'],
  "jimenez": ['HH IH2 M EH1 N EH2 Z'],
  "jimerson": ['JH IH1 M ER0 S AH0 N'],
  "jimi": ['JH IH1 M IY0'],
  "jiminez": ['HH IH0 M EH1 N EH2 Z'],
  "jimison": ['JH IH1 M IH0 S AH0 N'],
  "jimmerson": ['JH IH1 M ER0 S AH0 N'],
  "jimmie": ['JH IH1 M IY0'],
  "jimmied": ['JH IH1 M IY0 D'],
  "jimmy": ['JH IH1 M IY0'],
  "jimmy's": ['JH IH1 M IY0 Z'],
  "jin": ['JH IH1 N'],
  "jindo": ['JH IH1 N D OW0'],
  "jindra": ['JH IH1 N D R AH0'],
  "jines": ['JH AY1 N Z'],
  "jing": ['JH IH1 NG'],
  "jingle": ['JH IH1 NG G AH0 L'],
  "jingles": ['JH IH1 NG G AH0 L Z'],
  "jingoism": ['JH IH1 NG G OW2 IH0 Z AH0 M'],
  "jingoistic": ['JH IH0 NG G OW0 IH1 S T IH0 K'],
  "jingsheng": ['JH IH1 NG SH EH0 NG'],
  "jink": ['JH IH1 NG K'],
  "jinkins": ['JH IH1 NG K IH0 N Z'],
  "jinks": ['JH IH1 NG K S'],
  "jinright": ['JH IH1 N R AY2 T'],
  "jinx": ['JH IH1 NG K S'],
  "jipson": ['JH IH1 P S AH0 N'],
  "jirak": ['JH IH1 R AE0 K'],
  "jirga": ['JH ER1 G AH0'],
  "jiri": ['JH IH1 R IY0'],
  "jirik": ['JH IH1 R IH0 K'],
  "jiron": ['JH IH0 R AO1 N'],
  "jirsa": ['JH IH1 R S AH0'],
  "jitney": ['JH IH1 T N IY0'],
  "jitterbug": ['JH IH1 T ER0 B AH2 G'],
  "jitterbugging": ['JH IH1 T ER0 B AH2 G IH0 NG'],
  "jitters": ['JH IH1 T ER0 Z'],
  "jittery": ['JH IH1 T ER0 IY0'],
  "jive": ['JH AY1 V'],
  "jividen": ['Y IY0 V AY1 D AH0 N'],
  "jiving": ['JH AY1 V IH0 NG'],
  "jna": ['JH EY1 EH1 N EY1'],
  "jo": ['JH OW1'],
  "joachim": ['Y OW2 AA1 K IH0 M', 'W AA2 K IY1 M'],
  "joachim's": ['Y OW2 AA1 K IH0 M Z', 'W AA2 K IY1 M Z'],
  "joachims": ['Y OW2 AA1 K IH0 M Z', 'W AA2 K IY1 M Z'],
  "joakima": ['JH OW2 K IY1 M AH0', 'W AA2 K IY1 M AH0'],
  "joan": ['JH OW1 N'],
  "joanie": ['JH OW1 N IY2'],
  "joann": ['JH OW2 AE1 N'],
  "joanna": ['JH OW0 AE1 N AH0'],
  "joanne": ['JH OW2 AE1 N'],
  "joanou": ['JH OW1 N UW2'],
  "joao": ['JH OW1 OW0'],
  "joaquim": ['W AA2 K IY1 M'],
  "joaquin": ['W AA2 K IY1 N'],
  "job": ['JH AA1 B', 'JH OW1 B'],
  "job's": ['JH AA1 B Z', 'JH OW1 B Z'],
  "jobber": ['JH AA1 B ER0'],
  "jobbers": ['JH AA1 B ER0 Z'],
  "jobe": ['JH OW1 B'],
  "jobes": ['JH OW1 B Z'],
  "jobete": ['JH OW0 B IY1 T'],
  "jobin": ['JH OW1 B IH0 N'],
  "jobina": ['Y OW0 B IY1 N AH0'],
  "jobless": ['JH AA1 B L AH0 S'],
  "joblessness": ['JH AA1 B L AH0 S N AH0 S'],
  "jobs": ['JH AA1 B Z'],
  "jobs'": ['JH AA1 B Z'],
  "jobs's": ['JH AA1 B Z IH0 Z'],
  "jobson": ['JH AA1 B S AH0 N'],
  "jobst": ['JH AA1 B S T'],
  "jobyna": ['Y AH0 B IY1 N AH0'],
  "jocelin": ['JH AO1 S L IH2 N'],
  "joceline": ['JH AO1 S L IH2 N'],
  "jocelyn": ['JH AO1 S L IH2 N'],
  "jocelyne": ['JH AO1 S L IH2 N'],
  "jochem": ['JH AA1 K IH0 M'],
  "jochen": ['JH OW1 K AH0 N'],
  "jochim": ['JH AA1 K IH0 M'],
  "jochum": ['JH AA1 K AH0 M'],
  "jock": ['JH AA1 K'],
  "jockey": ['JH AA1 K IY0'],
  "jockeying": ['JH AA1 K IY0 IH0 NG'],
  "jockeys": ['JH AA1 K IY0 Z'],
  "jocks": ['JH AA1 K S'],
  "jocosa": ['Y OW0 K OW1 S AH0'],
  "jocose": ['JH AO2 K OW1 Z'],
  "jocular": ['JH AA1 K Y AH0 L ER0'],
  "jocylan": ['JH AO1 S L AH0 N'],
  "jocylan's": ['JH AO1 S L AH0 N Z'],
  "jodhpur": ['JH AO1 D P ER0'],
  "jodi": ['JH OW1 D IY0'],
  "jodie": ['JH OW1 D IY0'],
  "jodoin": ['ZH AH0 D OY1 N'],
  "jody": ['JH OW1 D IY0'],
  "joe": ['JH OW1'],
  "joe's": ['JH OW1 Z'],
  "joedicke": ['JH OW1 D IH0 K'],
  "joel": ['JH OW1 AH0 L'],
  "joel's": ['JH OW1 AH0 L Z'],
  "joelle": ['JH OW0 EH1 L'],
  "joelson": ['JH OW1 L S AH0 N'],
  "joens": ['JH OW1 N Z'],
  "joerg": ['JH AO1 R G'],
  "joerger": ['Y ER1 G ER0'],
  "joers": ['JH OW1 ER0 Z'],
  "joes": ['JH OW1 Z'],
  "joette": ['ZH OW0 EH1 T', 'JH OW0 EH1 T'],
  "joey": ['JH OW1 IY0'],
  "joey's": ['JH OW1 IY0 Z'],
  "joffe": ['JH AA1 F'],
  "joffrey": ['JH AO1 F R IY0'],
  "joffrion": ['Y OW0 F R IY0 AO1 N'],
  "jog": ['JH AA1 G'],
  "jogged": ['JH AA1 G D'],
  "jogger": ['JH AA1 G ER0'],
  "joggers": ['JH AA1 G ER0 Z'],
  "jogging": ['JH AA1 G IH0 NG', 'JH AO1 G IH0 NG'],
  "jogs": ['JH AA1 G Z'],
  "joh": ['JH OW1'],
  "johan": ['Y OW0 HH AA1 N'],
  "johann": ['Y OW1 HH AA0 N'],
  "johanna": ['JH AH0 HH AE1 N AH0'],
  "johannes": ['JH OW0 HH AE1 N AH0 S'],
  "johannesburg": ['JH OW2 HH AE1 N IH0 S B ER0 G'],
  "johannesburg's": ['JH OW2 HH AE1 N IH0 S B ER0 G Z'],
  "johannesen": ['JH OW2 HH AE1 N IH0 S AH0 N'],
  "johannessen": ['JH OW2 HH AE1 N IH0 S AH0 N'],
  "johanning": ['Y OW2 HH AE1 N IH0 NG', 'JH OW2 HH AE1 N IH0 NG'],
  "johanns": ['Y OW1 HH AA0 N Z'],
  "johannsen": ['JH OW2 HH AE1 N S AH0 N'],
  "johansen": ['JH OW2 HH AE1 N S AH0 N'],
  "johanson": ['JH OW2 HH AE1 N S AH0 N'],
  "johansson": ['Y OW2 HH AA1 N S AH0 N', 'JH OW2 HH AA1 N S AH0 N'],
  "john": ['JH AA1 N'],
  "john's": ['JH AA1 N Z'],
  "johnathan": ['JH AA1 N AH0 TH AH0 N'],
  "johnathan's": ['JH AA1 N AH0 TH AH0 N Z'],
  "johnathon": ['JH AA1 N AH0 TH AH0 N'],
  "johnathon's": ['JH AA1 N AH0 TH AH0 N Z'],
  "johndrow": ['JH AA1 N D R OW0'],
  "johnie": ['JH AA1 N IY0'],
  "johnnie": ['JH AA1 N IY0'],
  "johnnie's": ['JH AA1 N IY0 Z'],
  "johnny": ['JH AA1 N IY0'],
  "johnny's": ['JH AA1 N IY0 Z'],
  "johns": ['JH AA1 N Z'],
  "johns's": ['JH AA1 N Z IH0 Z'],
  "johnsen": ['JH AA1 N S AH0 N'],
  "johnsen's": ['JH AA1 N S AH0 N Z'],
  "johnsey": ['JH AA1 N Z IY0'],
  "johnson": ['JH AA1 N S AH0 N'],
  "johnson's": ['JH AA1 N S AH0 N Z'],
  "johnsons": ['JH AA1 N S AH0 N Z'],
  "johnsrud": ['JH AA1 N S R AH0 D'],
  "johnston": ['JH AA1 N S T AH0 N'],
  "johnston's": ['JH AA1 N S T AH0 N Z'],
  "johnstone": ['JH AA1 N S T OW2 N'],
  "johnstown": ['JH AA1 N Z T AW2 N'],
  "johnstown's": ['JH AA1 N Z T AW2 N Z'],
  "johnta": ['JH AA1 N T AH0'],
  "johny": ['JH AA1 N IY0'],
  "johson": ['JH AA1 S AH0 N'],
  "joice": ['JH OY1 S'],
  "joie": ['ZH W AA1'],
  "join": ['JH OY1 N'],
  "joined": ['JH OY1 N D'],
  "joiner": ['JH OY1 N ER0'],
  "joinery": ['JH OY1 N ER0 IY0'],
  "joines": ['JH OY1 N Z'],
  "joining": ['JH OY1 N IH0 NG'],
  "joins": ['JH OY1 N Z'],
  "joint": ['JH OY1 N T'],
  "jointed": ['JH OY1 N T AH0 D', 'JH OY1 N T IH0 D', 'JH OY1 N IH0 D'],
  "jointly": ['JH OY1 N T L IY0'],
  "jointness": ['JH OY1 N T N AH0 S'],
  "joints": ['JH OY1 N T S'],
  "joist": ['JH OY1 S T'],
  "joists": ['JH OY1 S T S'],
  "jojoba": ['HH OW0 HH OW1 B AH0'],
  "jojola": ['Y OW0 Y OW1 L AH0'],
  "joke": ['JH OW1 K'],
  "jokebook": ['JH OW1 K B UH2 K'],
  "jokebooks": ['JH OW1 K B UH2 K S'],
  "joked": ['JH OW1 K T'],
  "jokela": ['JH AA1 K IH0 L AH0'],
  "joker": ['JH OW1 K ER0'],
  "jokers": ['JH OW1 K ER0 Z'],
  "jokerst": ['JH AA1 K ER0 S T'],
  "jokes": ['JH OW1 K S'],
  "jokester": ['JH OW1 K S T ER0'],
  "jokhang": ['JH AA1 K HH AE0 NG'],
  "joki": ['JH OW1 K IY0'],
  "jokinen": ['JH AH0 K IH1 N AH0 N'],
  "joking": ['JH OW1 K IH0 NG'],
  "jokingly": ['JH OW1 K IH0 NG L IY0'],
  "jolene": ['JH OW0 L IY1 N'],
  "joles": ['JH OW1 L Z'],
  "joletta": ['JH OW0 L EH1 T AH0'],
  "jolette": ['JH OW2 L EH1 T'],
  "jolicoeur": ['ZH AA1 L IH0 K ER0'],
  "jolie": ['JH OW2 L IY1'],
  "joliet": ['JH OW1 L IY0 EH2 T'],
  "jolin": ['JH OW1 L IH0 N'],
  "jolivette": ['ZH AA1 L IH0 V EH0 T'],
  "jolla": ['HH OY1 Y AH0', 'JH OW1 L AH0'],
  "jolley": ['JH AA1 L IY0'],
  "jollie": ['JH AA1 L IY0'],
  "jollier": ['JH AA1 L IY0 ER0'],
  "jollies": ['JH AA1 L IY0 Z'],
  "jolliest": ['JH AA1 L IY0 AH0 S T'],
  "jolliff": ['JH AA1 L IH0 F'],
  "jolliffe": ['JH AA1 L IH0 F'],
  "jolly": ['JH AA1 L IY0'],
  "jollying": ['JH AA1 L IY0 IH0 NG'],
  "jolson": ['JH OW1 L S AH0 N'],
  "jolt": ['JH OW1 L T'],
  "jolted": ['JH OW1 L T IH0 D'],
  "jolting": ['JH OW1 L T IH0 NG'],
  "jolts": ['JH OW1 L T S'],
  "joly": ['JH OW1 L IY0'],
  "jon": ['JH AA1 N'],
  "jon's": ['JH AA1 N Z'],
  "jonah": ['JH OW1 N AH0'],
  "jonas": ['JH OW1 N AH0 S'],
  "jonassaint": ['JH OW2 N AH0 S EY1 N T'],
  "jonassaint's": ['JH OW2 N AH0 S EY1 N T S'],
  "jonassen": ['JH AA1 N AH0 S AH0 N'],
  "jonathan": ['JH AA1 N AH0 TH AH0 N'],
  "jonathan's": ['JH AA1 N AH0 TH AH0 N Z'],
  "jonathon": ['JH AA1 N AH0 TH AH0 N'],
  "jonbenet": ['JH AO1 N B AH0 N EY1'],
  "jonbenet's": ['JH AO1 N B AH0 N EY1 S'],
  "joncas": ['JH AA1 NG K AH0 Z'],
  "jone": ['JH OW1 N'],
  "jones": ['JH OW1 N Z'],
  "jones'": ['JH OW1 N Z'],
  "jones's": ['JH OW1 N Z IH0 Z'],
  "jonesboro": ['JH OW1 N Z B AH0 R OW0'],
  "joneses": ['JH OW1 N Z IH0 Z'],
  "jonestown": ['JH OW1 N Z T AW0 N'],
  "jong": ['JH AO1 NG'],
  "joni": ['JH OW1 N IY0'],
  "jonker": ['JH AA1 NG K ER0'],
  "jonn": ['JH AA1 N'],
  "jonothan": ['JH AA1 N AH0 TH AH0 N'],
  "jonsman": ['JH AA1 N Z M AH0 N'],
  "jonson": ['JH AA1 N S AH0 N'],
  "jonsson": ['JH AA1 N S AH0 N'],
  "joo": ['JH UW1'],
  "joon": ['JH UW1 N'],
  "joong": ['JH UH1 NG'],
  "joos": ['JH UW1 Z'],
  "joost": ['JH UW1 S T'],
  "joosten": ['Y UW1 S AH0 N'],
  "joplin": ['JH AA1 P L IH0 N'],
  "jopling": ['JH OW1 P AH0 L IH0 NG', 'JH OW1 P L IH0 NG'],
  "jopp": ['JH AA1 P'],
  "jordache": ['JH AO1 R D AE2 SH'],
  "jordahl": ['JH AO1 R D AA0 L'],
  "jordan": ['JH AO1 R D AH0 N'],
  "jordan's": ['JH AO1 R D AH0 N Z'],
  "jordana": ['Y AO0 R D AE1 N AH0'],
  "jordanian": ['JH AO2 R D EY1 N IY0 AH0 N'],
  "jordanians": ['JH AO0 R D EY1 N IY0 AH0 N Z'],
  "jordans": ['JH AO1 R D AH0 N Z'],
  "jorde": ['JH AO1 R D'],
  "jorden": ['JH AO1 R D AH0 N'],
  "jordi": ['JH AO1 R D IY0'],
  "jordie": ['JH AO1 R D IY0'],
  "jordon": ['JH AO1 R D AH0 N'],
  "jordy": ['JH AO1 R D IY0'],
  "jorge": ['HH AO1 R HH EY0'],
  "jorgensen": ['JH AO1 R G IH0 N S AH0 N'],
  "jorgenson": ['JH AO1 R G IH0 N S AH0 N'],
  "jorio": ['JH AO1 R IY0 OW0'],
  "jornal": ['JH AO1 R N AH0 L'],
  "jorstad": ['JH AO1 R S T AH0 D'],
  "jory": ['JH AO1 R IY0'],
  "jos": ['JH OW1 Z'],
  "josceline": ['JH AA1 S IH0 L AY2 N'],
  "joscelyn": ['JH AA1 S IH0 L IH0 N'],
  "joscelyne": ['JH AA1 S IH0 L AY2 N'],
  "jose": ['HH OW2 Z EY1'],
  "jose's": ['HH OW2 Z EY1 Z'],
  "josef": ['JH OW1 S AH0 F'],
  "josefina": ['Y OW0 S IH0 F IY1 N AH0', 'JH OW0 Z AH0 F IY1 N AH0'],
  "joseph": ['JH OW1 S AH0 F', 'JH OW1 Z AH0 F'],
  "joseph's": ['JH OW1 Z AH0 F S'],
  "josepha": ['JH OW0 S EH1 F AH0'],
  "josephina": ['JH AA0 S IH0 F AY1 N AH0', 'JH OW0 Z AH0 F IY1 N AH0'],
  "josephine": ['JH OW1 S AH0 F IY2 N'],
  "josephine's": ['JH OW1 S AH0 F IY2 N Z'],
  "josephs": ['JH OW1 S IH0 F S', 'JH OW1 Z IH0 F S'],
  "josephson": ['JH OW1 S AH0 F S AH0 N'],
  "josephthal": ['JH OW1 S AH0 F TH AO2 L'],
  "josephus": ['JH OW0 S IY1 F AH0 S'],
  "joser": ['JH OW1 Z ER0'],
  "joser's": ['JH OW1 Z ER0 Z'],
  "josette": ['ZH AH0 S EH1 T'],
  "josey": ['JH OW1 Z IY0'],
  "josh": ['JH AA1 SH'],
  "joshi": ['JH OW1 SH IY0'],
  "joshua": ['JH AA1 SH UW2 AH0'],
  "joshua's": ['JH AA1 SH UW0 AH0 Z'],
  "josiah": ['JH OW0 S AY1 AH0'],
  "josie": ['JH OW1 Z IY0'],
  "joske's": ['JH AO1 S K IY0 Z'],
  "josleyn": ['JH AA1 S L IH2 N'],
  "joslin": ['JH AA1 S L IH0 N'],
  "joslyn": ['JH AO1 S L IH0 N'],
  "jospin": ['JH AA1 S P IH0 N'],
  "joss": ['JH AO1 S'],
  "josselyn": ['JH AA1 S IH0 L IH0 N'],
  "jost": ['JH AA1 S T'],
  "josten": ['JH AA1 S AH0 N'],
  "jostens": ['JH AO1 S T AH0 N Z'],
  "jostes": ['JH OW1 S T S'],
  "jostle": ['JH AA1 S AH0 L'],
  "jostled": ['JH AA1 S AH0 L D'],
  "jostling": ['JH AA1 S AH0 L IH0 NG', 'JH AA1 S L IH0 NG'],
  "joswick": ['JH AA1 S W IH0 K'],
  "jot": ['JH AA1 T'],
  "jotted": ['JH AA1 T IH0 D'],
  "joubert": ['Y AW1 B ER0 T'],
  "jouett": ['JH AA1 UW0 T'],
  "joule": ['JH UW1 L'],
  "joules": ['JH UW1 L Z'],
  "joulwan": ['JH UW1 L W AA0 N', 'JH UW1 L HH W AA0 N'],
  "jour": ['JH UW1 R'],
  "jourdan": ['ZH UH0 R D AE1 N'],
  "jouret": ['ZH W AA0 R EY1', 'JH ER0 EH1 T'],
  "jouret's": ['ZH W AA0 R EY1 Z', 'JH ER0 EH1 T S'],
  "journal": ['JH ER1 N AH0 L'],
  "journal's": ['JH ER1 N AH0 L Z'],
  "journalese": ['JH ER2 N AH0 L IY1 Z'],
  "journalism": ['JH ER1 N AH0 L IH2 Z AH0 M'],
  "journalism's": ['JH ER1 N AH0 L IH2 Z AH0 M Z'],
  "journalist": ['JH ER1 N AH0 L AH0 S T', 'JH ER1 N AH0 L IH0 S T'],
  "journalist's": ['JH ER1 N AH0 L IH0 S T S'],
  "journalistic": ['JH ER2 N AH0 L IH1 S T IH0 K'],
  "journalistically": ['JH ER2 N AH0 L IH1 S T IH0 K AH0 L IY0', 'JH ER2 N AH0 L IH1 S T IH0 K L IY0'],
  "journalists": ['JH ER1 N AH0 L AH0 S T S', 'JH ER1 N AH0 L IH0 S T S'],
  "journalists'": ['JH ER1 N AH0 L IH0 S T S'],
  "journals": ['JH ER1 N AH0 L Z'],
  "journey": ['JH ER1 N IY0'],
  "journeyed": ['JH ER1 N IY0 D'],
  "journeying": ['JH ER1 N IY0 IH0 NG'],
  "journeyman": ['JH ER1 N IY0 M AE2 N'],
  "journeyman's": ['JH ER1 N IY0 M AH0 N Z'],
  "journeys": ['JH ER1 N IY0 Z'],
  "joust": ['JH AW1 S T'],
  "jousting": ['JH AW1 S T IH0 NG'],
  "jovan": ['JH OW1 V AH0 N'],
  "jovanovic": ['Y AH0 V AH0 N AA1 V IH0 K'],
  "jovanovich": ['JH OW0 V AE1 N AH0 V IH0 CH'],
  "jovi": ['JH OW1 V IY0'],
  "jovial": ['JH OW1 V IY0 AH0 L'],
  "jovian": ['JH OW1 V IY0 AH0 N'],
  "jovita": ['Y AH0 V AY1 T AH0'],
  "jovito": ['JH OW0 V IY1 T OW0'],
  "jowers": ['JH AW1 ER0 Z'],
  "jowett": ['JH AW1 IH0 T'],
  "jowl": ['JH AW1 L'],
  "jowley": ['JH OW1 L IY0'],
  "jowls": ['JH AW1 L Z'],
  "jowly": ['JH AW1 L IY0'],
  "joy": ['JH OY1'],
  "joy's": ['JH OY1 Z'],
  "joy-thrill": ['JH OY1 TH R IH2 L'],
  "joyal": ['JH OY1 AH0 L'],
  "joyce": ['JH OY1 S'],
  "joyce's": ['JH OY1 S IH0 Z'],
  "joycelyn": ['JH OY1 S L IH2 N'],
  "joycelyn's": ['JH OY1 S L IH2 N Z'],
  "joye": ['JH OY1'],
  "joyful": ['JH OY1 F AH0 L'],
  "joyfully": ['JH OY1 F AH0 L IY0'],
  "joying": ['JH OY1 IH0 NG'],
  "joyner": ['JH OY1 N ER0'],
  "joynes": ['JH OY1 N Z'],
  "joynt": ['JH OY1 N T'],
  "joyoni": ['JH OY0 OW1 N IY0'],
  "joyous": ['JH OY1 AH0 S'],
  "joyride": ['JH OY1 R AY2 D'],
  "joyriding": ['JH OY1 R AY2 D IH0 NG'],
  "joys": ['JH OY1 Z'],
  "joystick": ['JH OY1 S T IH2 K'],
  "jozef": ['JH OW1 Z AH0 F'],
  "jozwiak": ['Y AA1 Z V IY0 AE0 K'],
  "jr": ['JH UW1 N Y ER0'],
  "jr.": ['JH UW1 N ER0'],
  "ju": ['JH UW1'],
  "jua": ['JH UW1 AH0'],
  "juan": ['W AA1 N', 'HH W AA1 N'],
  "juana": ['W AA1 N AH0', 'HH W AA1 N AH0'],
  "juang": ['JH W AA1 NG'],
  "juanita": ['W AA0 N IY1 T AH0', 'JH UW2 AH0 N IY1 T AH0'],
  "juarez": ['W AA1 R EH2 Z', 'HH W AA1 R EH2 Z'],
  "juarez-espin": ['W AA2 R EH1 Z EH1 S P IH0 N', 'HH W AA2 R EH1 Z EH1 S P IH0 N'],
  "juba": ['JH UW1 B AH0'],
  "jubb": ['JH AH1 B'],
  "jubilant": ['JH UW1 B AH0 L AH0 N T'],
  "jubilation": ['JH UW2 B AH0 L EY1 SH AH0 N'],
  "jubilee": ['JH UW1 B AH0 L IY2', 'JH UW2 B AH0 L IY1'],
  "juckett": ['JH AH1 K IH0 T'],
  "judah": ['JH UW1 D AH0'],
  "judaism": ['JH UW1 D EY0 IH2 Z AH0 M', 'JH UW1 D IY0 IH2 Z AH0 M'],
  "judaism's": ['JH UW1 D EY0 IH2 Z AH0 M Z', 'JH UW1 D IY0 IH2 Z AH0 M Z'],
  "judas": ['JH UW1 D AH0 S'],
  "juday": ['JH UW1 D EY2'],
  "judd": ['JH AH1 D'],
  "judds": ['JH AH1 D Z'],
  "jude": ['JH UW1 D'],
  "jude's": ['JH UW1 D Z'],
  "judea": ['JH UW0 D IY1 AH0'],
  "judeo": ['JH UW0 D EY1 OW0'],
  "judge": ['JH AH1 JH'],
  "judge's": ['JH AH1 JH IH0 Z'],
  "judged": ['JH AH1 JH D'],
  "judgement": ['JH AH1 JH M AH0 N T'],
  "judgemental": ['JH AH0 JH M EH1 N T AH0 L'],
  "judgements": ['JH AH1 JH M AH0 N T S'],
  "judges": ['JH AH1 JH IH0 Z'],
  "judges'": ['JH AH1 JH IH0 Z'],
  "judgeship": ['JH AH1 JH SH IH2 P'],
  "judgeships": ['JH AH1 JH SH IH2 P S'],
  "judging": ['JH AH1 JH IH0 NG'],
  "judgment": ['JH AH1 JH M AH0 N T'],
  "judgmental": ['JH AH0 JH M EH1 N T AH0 L', 'JH AH0 JH M EH1 N AH0 L'],
  "judgments": ['JH AH1 JH M AH0 N T S'],
  "judi": ['JH UW1 D IY0'],
  "judicate": ['JH UW1 D IH0 K EY2 T'],
  "judice": ['JH UW1 D IH0 S'],
  "judicial": ['JH UW0 D IH1 SH AH0 L'],
  "judicially": ['JH UW0 D IH1 SH AH0 L IY0'],
  "judiciary": ['JH UW0 D IH1 SH IY0 EH2 R IY0'],
  "judicious": ['JH UW0 D IH1 SH AH0 S'],
  "judiciously": ['JH UW0 D IH1 SH IH0 S L IY0'],
  "judie": ['JH AH1 D IY0'],
  "judishe": ['JH UW2 D IY1 SH'],
  "judith": ['JH UW1 D AH0 TH', 'JH UW1 D IH0 TH'],
  "juditha": ['JH UW0 D IH1 TH AH0'],
  "judkins": ['JH AH1 D K IH0 N Z'],
  "judo": ['JH UW1 D OW2'],
  "judsen": ['JH AH1 D S AH0 N'],
  "judson": ['JH AH1 D S AH0 N'],
  "judy": ['JH UW1 D IY0'],
  "judy's": ['JH UW1 D IY0 Z'],
  "jue": ['JH UW1'],
  "juedes": ['JH W EH1 D EH0 S', 'Y UW0 EH1 D EH0 S'],
  "juel": ['JH UW1 L'],
  "juenemann": ['Y UW1 N M AH0 N'],
  "juenger": ['Y UW1 NG G ER0'],
  "juergen": ['Y ER1 G AH0 N', 'JH ER1 G AH0 N'],
  "juergens": ['Y ER1 G AH0 N Z', 'JH ER1 G AH0 N Z'],
  "jueteng": ['JH UW1 T EH2 NG'],
  "juett": ['JH UW1 T'],
  "jug": ['JH AH1 G'],
  "juge": ['JH UW1 JH'],
  "juggernaut": ['JH AH1 G ER0 N AO2 T'],
  "juggle": ['JH AH1 G AH0 L'],
  "juggled": ['JH AH1 G AH0 L D'],
  "juggler": ['JH AH1 G AH0 L ER0', 'JH AH1 G L ER0'],
  "jugglers": ['JH AH1 G AH0 L ER0 Z', 'JH AH1 G L ER0 Z'],
  "juggles": ['JH AH1 G AH0 L Z'],
  "juggling": ['JH AH1 G AH0 L IH0 NG', 'JH AH1 G L IH0 NG'],
  "jugs": ['JH AH1 G Z'],
  "jugular": ['JH UW1 G Y AH0 L ER0'],
  "juhas": ['JH UW1 AH0 Z'],
  "juhasz": ['Y AH1 HH AH0 SH'],
  "juhl": ['JH AH1 L'],
  "juhlin": ['JH UW1 L IH0 N'],
  "juhnke": ['JH AH1 NG K'],
  "juice": ['JH UW1 S'],
  "juices": ['JH UW1 S AH0 Z', 'JH UW1 S IH0 Z'],
  "juicier": ['JH UW0 IH1 S Y ER0'],
  "juiciest": ['JH UW1 S IY0 AH0 S T'],
  "juicy": ['JH UW1 S IY0'],
  "juilliard": ['JH UW1 L IY0 AA0 R D'],
  "jujitsu": ['JH UW0 JH IH1 T S UW2'],
  "jujo": ['JH UW1 JH OW0'],
  "juju": ['JH UW1 JH UW0'],
  "jujube": ['JH UW1 JH UW2 B'],
  "juke": ['JH UW1 K'],
  "jukebox": ['JH UW1 K B AA2 K S'],
  "jukeboxes": ['JH UW1 K B AA2 K S IH0 Z'],
  "jukes": ['JH UW1 K S'],
  "juki": ['JH UW1 K IY0'],
  "jule": ['JH UW1 L'],
  "julep": ['JH UW1 L AH0 P'],
  "juleps": ['JH UW1 L AH0 P S'],
  "jules": ['JH UW1 L Z'],
  "juli": ['JH UW1 L IY0'],
  "julia": ['JH UW1 L Y AH0'],
  "julia's": ['JH UW1 L Y AH0 Z'],
  "julian": ['JH UW1 L IY0 AH0 N', 'JH UW1 L Y AH0 N'],
  "juliana": ['JH UW2 L IY0 AE1 N AH0'],
  "juliann": ['JH UW1 L IY0 AE0 N', 'JH UW1 L Y AH0 N'],
  "julianne": ['JH UW1 L IY0 EH2 N'],
  "juliano": ['JH UW2 L IY0 AA1 N OW0'],
  "julie": ['JH UW1 L IY0'],
  "julie's": ['JH UW1 L IY0 Z'],
  "julien": ['JH UW1 L IY0 AH0 N'],
  "juliet": ['JH UW1 L IY0 EH2 T'],
  "julieta": ['Y UW0 L IY1 T AH0'],
  "julietta": ['JH UW0 L IY0 EH1 T AH0'],
  "juliette": ['JH UW0 L IY0 EH1 T'],
  "julin": ['JH UW1 L IH0 N'],
  "julina": ['Y UW0 L IY1 N AH0'],
  "juline": ['JH UW1 L AY0 N'],
  "julio": ['JH UW1 L IY0 OW0', 'HH UW1 L IY0 OW0'],
  "julius": ['JH UW1 L Y AH0 S'],
  "juliusz": ['JH UW1 L IY0 UW0 S'],
  "julliard": ['JH UW1 L IY0 AA2 R D'],
  "julson": ['JH AH1 L S AH0 N'],
  "july": ['JH UW2 L AY1', 'JH AH0 L AY1'],
  "july's": ['JH UW2 L AY1 Z', 'JH AH0 L AY1 Z'],
  "jumanji": ['JH UW0 M AA1 N JH IY0'],
  "jumble": ['JH AH1 M B AH0 L'],
  "jumbled": ['JH AH1 M B AH0 L D'],
  "jumbo": ['JH AH1 M B OW0'],
  "jumbos": ['JH AH1 M B OW2 Z'],
  "jumbotron": ['JH AH1 M B OW0 T R AA0 N'],
  "jumbotrons": ['JH AH1 M B OW0 T R AA0 N Z'],
  "jumonville": ['ZH AH1 M AH0 N V IH0 L', 'JH UW1 M AH0 N V IH0 L'],
  "jump": ['JH AH1 M P'],
  "jumped": ['JH AH1 M P T'],
  "jumper": ['JH AH1 M P ER0'],
  "jumpers": ['JH AH1 M P ER0 Z'],
  "jumping": ['JH AH1 M P IH0 NG'],
  "jumps": ['JH AH1 M P S'],
  "jumpstart": ['JH AH1 M P S T AA2 R T'],
  "jumpstarts": ['JH AH1 M P S T AA2 R T S'],
  "jumpsuit": ['JH AH1 M P S UW2 T'],
  "jumpsuits": ['JH AH1 M P S UW2 T S'],
  "jumpy": ['JH AH1 M P IY0'],
  "jun": ['JH AH1 N'],
  "junco": ['JH AH1 NG K OW0'],
  "junction": ['JH AH1 NG K SH AH0 N'],
  "junctions": ['JH AH1 NG K SH AH0 N Z'],
  "juncture": ['JH AH1 NG K CH ER0'],
  "junctures": ['JH AH1 NG K CH ER0 Z'],
  "junda": ['JH AH1 N D AH0'],
  "jundt": ['JH AH1 N T'],
  "june": ['JH UW1 N'],
  "june's": ['JH UW1 N Z'],
  "juneau": ['JH UW1 N OW0'],
  "junejo": ['JH UW0 N EY1 HH OW0'],
  "junek": ['JH UW1 N IH0 K'],
  "junella": ['JH UW2 N EH1 L AH0'],
  "junes": ['JH UW1 N Z'],
  "junette": ['JH UW2 N EH1 T'],
  "jung": ['Y UH1 NG'],
  "jung's": ['Y UH1 NG Z'],
  "jungbluth": ['JH AH1 NG B L UW0 TH'],
  "junge": ['JH AH1 NG'],
  "jungels": ['JH AH1 NG G AH0 L Z'],
  "junger": ['JH AH1 NG ER0'],
  "jungers": ['JH AH1 NG ER0 Z'],
  "junghans": ['JH AH1 NG G AH0 N Z'],
  "jungin": ['JH AH1 N G IH0 N'],
  "jungle": ['JH AH1 NG G AH0 L'],
  "jungles": ['JH AH1 NG G AH0 L Z'],
  "jungman": ['JH AH1 NG M AH0 N'],
  "jungwirth": ['JH AH1 NG G W ER0 TH'],
  "junia": ['Y UW1 N IY0 AH0'],
  "juniata": ['Y UW0 N IY0 AA1 T AH0'],
  "junine": ['JH AH1 N IH0 N'],
  "junior": ['JH UW1 N Y ER0'],
  "junior's": ['JH UW1 N Y ER0 Z'],
  "juniors": ['JH UW1 N Y ER0 Z'],
  "juniper": ['JH UW1 N AH0 P ER0'],
  "junipers": ['JH UW1 N IH0 P ER0 Z'],
  "junius": ['JH UW1 N IY0 IH0 S'],
  "junji": ['JH AH1 N JH IY0'],
  "junk": ['JH AH1 NG K'],
  "junkbond": ['JH AH1 NG K B AA2 N D'],
  "junked": ['JH AH1 NG K T'],
  "junker": ['JH AH1 NG K ER0'],
  "junket": ['JH AH1 NG K IH0 T'],
  "junkets": ['JH AH1 NG K IH0 T S'],
  "junkholder": ['JH AH1 NG K HH OW2 L D ER0'],
  "junkholders": ['JH AH1 NG K HH OW2 L D ER0 Z'],
  "junkie": ['JH AH1 NG K IY0'],
  "junkier": ['JH AH1 NG K IY0 ER0'],
  "junkies": ['JH AH1 NG K IY0 Z'],
  "junkiest": ['JH AH1 NG K IY0 AH0 S T'],
  "junkin": ['JH AH1 NG K IH0 N'],
  "junking": ['JH AH1 NG K IH0 NG'],
  "junkins": ['JH AH1 NG K IH2 N Z'],
  "junky": ['JH AH1 NG K IY0'],
  "junkyard": ['JH AH1 NG K Y AA2 R D'],
  "junkyards": ['JH AH1 NG K Y AA2 R D Z'],
  "juno": ['JH UW1 N OW0'],
  "juno's": ['JH UW1 N OW0 Z'],
  "junod": ['JH UW1 N AH0 D'],
  "junot": ['JH UW1 N AH0 T'],
  "junta": ['HH UH1 N T AH0'],
  "juntunen": ['JH AH1 N T AH0 N AH0 N'],
  "junwuxiyan": ['JH AH0 N W UW1 K S IY0 AH0 N'],
  "jupin": ['JH UW1 P IH0 N'],
  "jupiter": ['JH UW1 P AH0 T ER0', 'JH UW1 P IH0 T ER0'],
  "jupiter's": ['JH UW1 P AH0 T ER0 Z', 'JH UW1 P IH0 T ER0 Z'],
  "juppe": ['JH UW1 P IY0'],
  "jura": ['JH UH1 R AH0'],
  "jura's": ['JH UH1 R AH0 Z'],
  "jurado": ['Y UH0 R AA1 D OW0'],
  "juran": ['Y UH0 R AA1 N'],
  "juranek": ['JH UH1 R AH0 N IH0 K'],
  "juras": ['JH UH1 R AH0 Z'],
  "jurassic": ['JH UH0 R AE1 S IH0 K'],
  "jurczak": ['Y ER1 CH AE0 K'],
  "jurczyk": ['Y ER1 CH IH0 K'],
  "jure": ['JH UH1 R'],
  "jurek": ['JH UH1 R EH0 K', 'Y UH1 R EH0 K'],
  "jurewicz": ['JH UH1 R AH0 V IH0 CH', 'Y UH1 R AH0 V IH0 CH'],
  "jurgen": ['JH ER1 G AH0 N'],
  "jurgens": ['JH ER1 G AH0 N Z'],
  "jurgensen": ['JH ER1 G IH0 N S AH0 N'],
  "jurgensmeyer": ['JH ER1 G AH0 N Z M AY2 R'],
  "jurgenson": ['JH ER1 G IH0 N S AH0 N'],
  "jurica": ['JH UH1 R IH0 K AH0'],
  "jurich": ['JH UH1 R IH0 K'],
  "juries": ['JH UH1 R IY0 Z'],
  "juries'": ['JH UH1 R IY0 Z'],
  "juris": ['JH UH1 R IH0 S', 'Y UH1 R IH0 S'],
  "jurisdiction": ['JH UH2 R AH0 S D IH1 K SH AH0 N', 'JH UH2 R IH0 S D IH1 K SH AH0 N'],
  "jurisdictional": ['JH UH2 R AH0 S D IH1 K SH AH0 N AH0 L'],
  "jurisdictions": ['JH UH2 R IH0 S D IH1 K SH AH0 N Z'],
  "jurisprudence": ['JH UH2 R AH0 S P R UW1 D AH0 N S'],
  "jurisprudential": ['JH UH2 R AH0 S P R UW2 D EH1 N SH AH0 L'],
  "jurist": ['JH UH1 R AH0 S T', 'JH UH1 R IH0 S T'],
  "jurists": ['JH UH1 R IH0 S T S'],
  "jurkiewicz": ['Y ER1 K AH0 V IH0 CH'],
  "jurkovich": ['Y ER1 K AH0 V IH0 CH'],
  "jurkowski": ['Y ER0 K AO1 F S K IY0'],
  "jurney": ['JH ER1 N IY0'],
  "juror": ['JH UH1 R ER0'],
  "juror's": ['JH UH1 R ER0 Z'],
  "jurors": ['JH UH1 R ER0 Z'],
  "jurors'": ['JH UH1 R ER0 Z'],
  "jurs": ['JH ER1 Z'],
  "jury": ['JH UH1 R IY0'],
  "jury's": ['JH UH1 R IY0 Z'],
  "jus": ['JH UW1', 'JH AH1 S'],
  "jusco": ['JH AH1 S K OW0'],
  "jusino": ['Y UW0 S IY1 N OW0'],
  "just": ['JH AH1 S T', 'JH IH0 S T'],
  "justa": ['JH AH1 S T AH0', 'JH IH0 S T AH0'],
  "justen": ['JH AH1 S T AH0 N'],
  "juster": ['JH AH1 S T ER0'],
  "justesen": ['JH AH1 S T IY0 Z AH0 N'],
  "justice": ['JH AH1 S T AH0 S', 'JH AH1 S T IH0 S'],
  "justice's": ['JH AH1 S T IH0 S IH0 Z'],
  "justices": ['JH AH1 S T AH0 S AH0 Z', 'JH AH1 S T IH0 S IH0 Z'],
  "justices'": ['JH AH1 S T IH0 S IH0 Z'],
  "justifiable": ['JH AH1 S T AH0 F AY2 AH0 B AH0 L'],
  "justifiably": ['JH AH1 S T AH0 F AY2 AH0 B L IY0'],
  "justification": ['JH AH2 S T AH0 F AH0 K EY1 SH AH0 N'],
  "justifications": ['JH AH2 S T IH0 F IH0 K EY1 SH AH0 N Z'],
  "justified": ['JH AH1 S T AH0 F AY2 D'],
  "justifies": ['JH AH1 S T AH0 F AY2 Z'],
  "justify": ['JH AH1 S T AH0 F AY2'],
  "justifying": ['JH AH1 S T AH0 F AY2 IH0 NG'],
  "justin": ['JH AH1 S T AH0 N', 'JH AH1 S T IH0 N'],
  "justin's": ['JH AH1 S T AH0 N Z', 'JH AH1 S T IH0 N Z'],
  "justina": ['Y UW0 S T IY1 N AH0'],
  "justine": ['JH AH0 S T IY1 N'],
  "justiniano": ['JH UW0 S T IY0 N IY0 AA1 N OW0'],
  "justino": ['JH AH0 S T IY1 N OW0'],
  "justis": ['Y UW1 S T IH0 S'],
  "justiss": ['Y UW1 S T IY0 S'],
  "justly": ['JH AH1 S T L IY0'],
  "justman": ['JH AH1 S T M AH0 N'],
  "justo": ['JH AH1 S T OW0'],
  "justus": ['JH AH1 S T AH0 S'],
  "justy": ['JH AH1 S T IY0'],
  "jut": ['JH AH1 T'],
  "jute": ['JH UW1 T'],
  "jutland": ['JH AH1 T L AH0 N D'],
  "jutras": ['Y UW1 T R AA0 Z'],
  "juts": ['JH AH1 T S'],
  "jutting": ['JH AH1 T IH0 NG'],
  "juul": ['JH UW1 AH0 L'],
  "juve": ['JH UW1 V'],
  "juvenile": ['JH UW1 V AH0 N AH0 L', 'JH UW1 V AH0 N AY2 L'],
  "juvenile's": ['JH UW1 V AH0 N AH0 L Z', 'JH UW1 V AH0 N AY2 L Z'],
  "juveniles": ['JH UW1 V AH0 N AH0 L Z', 'JH UW1 V AH0 N AY2 L Z'],
  "juwan": ['JH UW1 AA0 N'],
  "juxtapose": ['JH AH2 K S T AH0 P OW1 Z'],
  "juxtaposed": ['JH AH2 K S T AH0 P OW1 Z D'],
  "juxtaposes": ['JH AH2 K S T AH0 P OW1 Z IH0 Z'],
  "juxtaposing": ['JH AH2 K S T AH0 P OW1 Z IH0 NG'],
  "juxtaposition": ['JH AH2 K S T AH0 P AH0 Z IH1 SH AH0 N'],
  "juxtapositions": ['JH AH2 K S T AH0 P AH0 Z IH1 SH AH0 N Z'],
  "jwan": ['JH W AA1 N'],
  "jyishane": ['JH IY1 SH EY1 N'],
  "jynx": ['JH IH1 NG K S'],
  "k": ['K EY1'],
  "k's": ['K EY1 Z'],
  "k-mart": ['K EY1 M AA1 R T'],
  "k-mart's": ['K EY1 M AA1 R T S'],
  "k.": ['K EY1'],
  "k.'s": ['K EY1 Z'],
  "ka": ['K AA1'],
  "kaas": ['K AA1 S'],
  "kaatz": ['K AA1 T S'],
  "kabart": ['K AH0 B AA1 R T'],
  "kabat": ['K AE1 B AH0 T'],
  "kabbah": ['K AH0 B AH1'],
  "kabbalah": ['K AH0 B AA1 L AH0'],
  "kabbani": ['K AH0 B AA1 N IY0'],
  "kabel": ['K AE1 B AH0 L'],
  "kabi": ['K AE1 B IY0', 'K AA1 B IY0'],
  "kabila": ['K AH0 B IH1 L AH0'],
  "kabila's": ['K AH0 B IH1 L AH0 Z'],
  "kabivitrum": ['K AH0 B IH1 V IH0 T R AH0 M'],
  "kable": ['K EY1 B AH0 L'],
  "kabler": ['K EY1 B AH0 L ER0', 'K EY1 B L ER0'],
  "kaboom": ['K AH0 B UW1 M'],
  "kabral": ['K AH0 B R AA1 L'],
  "kabuki": ['K AH0 B UW1 K IY2'],
  "kabul": ['K AA1 B UH0 L'],
  "kacer": ['K EY1 S ER0'],
  "kach": ['K AE1 CH'],
  "kachel": ['K AE1 K AH0 L'],
  "kachigian": ['K AH0 SH IY1 G IY2 AH0 N', 'K AH0 SH IH1 G IY0 AH0 N'],
  "kachmar": ['K AE1 K M ER0'],
  "kachuck": ['K AH0 CH AH1 K'],
  "kachur": ['K AE1 CH ER0'],
  "kackley": ['K AE1 K L IY0'],
  "kaczmarczyk": ['K AA1 CH M ER0 CH IH0 K'],
  "kaczmarek": ['K AH0 CH M AA1 R EH0 K'],
  "kaczmarski": ['K AH0 CH M AA1 R S K IY0'],
  "kaczor": ['K AA1 CH ER0'],
  "kaczorowski": ['K AH0 CH ER0 AO1 F S K IY0'],
  "kaczynski": ['K AH0 CH IH1 N S K IY0', 'K AH0 Z IH1 N S K IY0'],
  "kaczynski's": ['K AH0 CH IH1 N S K IY0 Z', 'K AH0 Z IH1 N S K IY0 Z'],
  "kadar": ['K AE1 D ER0', 'K AH0 D AA1 R'],
  "kade": ['K EY1 D'],
  "kadel": ['K AE1 D AH0 L'],
  "kaden": ['K EY1 D AH0 N'],
  "kader": ['K EY1 D ER0'],
  "kading": ['K EY1 D IH0 NG'],
  "kadish": ['K EY1 D IH0 SH'],
  "kadlec": ['K AA1 D L IH0 K'],
  "kadow": ['K AA1 D OW0'],
  "kadrescu": ['K AH0 D R EH1 S K Y UW0'],
  "kadrmas": ['K AE1 D ER0 M AA2 Z'],
  "kadumi": ['K AH0 D UW1 M IY0'],
  "kady": ['K EY1 D IY0'],
  "kaeding": ['K EH1 D IH0 NG'],
  "kaehler": ['K EH1 L ER0'],
  "kael": ['K EY1 L'],
  "kaelin": ['K EH1 L IH0 N'],
  "kaelin's": ['K EH1 L IH0 N Z'],
  "kaercher": ['K EH1 R K ER0'],
  "kaeser": ['K EY1 Z ER0'],
  "kaestner": ['K EH1 S T N ER0'],
  "kaetzel": ['K EH1 T Z AH0 L'],
  "kafelnikov": ['K AH0 F EH1 L N IH0 K AO2 F', 'K AH0 F EH1 L N IH0 K AO2 V'],
  "kafer": ['K EY1 F ER0'],
  "kaffenberger": ['K AE1 F AH0 N B ER0 G ER0'],
  "kafka": ['K AA1 F K AH0'],
  "kafka's": ['K AA1 F K AH0 Z'],
  "kafkaesque": ['K AA1 F K AH0 EH1 S K'],
  "kagami": ['K AE1 G AH0 M IY0'],
  "kagan": ['K EY1 G AH0 N'],
  "kagarlitsky": ['K AE2 G ER0 L IH1 T S K IY1'],
  "kagawa": ['K AH0 G AA1 W AH0'],
  "kage": ['K EY1 JH'],
  "kagel": ['K EY1 G AH0 L'],
  "kagey": ['K EY1 JH IY0'],
  "kageyama": ['K AA2 G IY0 AA1 M AH0'],
  "kagin": ['K EY1 G IH0 N'],
  "kagins": ['K EY1 G IH0 N Z'],
  "kagler": ['K AE1 G L ER0'],
  "kagy": ['K EY1 G IY0'],
  "kah": ['K AA1'],
  "kahalani": ['K AA2 HH AH0 L AA1 N IY0'],
  "kahan": ['K AH0 HH AA1 N'],
  "kahan's": ['K AH0 HH AA1 N Z'],
  "kahane": ['K AH0 HH AA1 N EY2', 'K AH0 HH EY1 N'],
  "kahl": ['K AA1 L'],
  "kahle": ['K AA1 L'],
  "kahler": ['K AA1 L ER0'],
  "kahley": ['K AA1 L IY0'],
  "kahn": ['K AA1 N'],
  "kahn's": ['K AA1 N Z'],
  "kahng": ['K AA1 NG'],
  "kahr": ['K AA1 R'],
  "kahr's": ['K AA1 R Z'],
  "kahre": ['K EH1 R', 'K AA1 R'],
  "kahrs": ['K AA1 R Z'],
  "kahului": ['K AH0 HH UW1 L W IY0'],
  "kahuna": ['K AH0 HH UW1 N AH0'],
  "kai": ['K AY1'],
  "kaifu": ['K AY1 F UW2'],
  "kaigler": ['K EY1 G L ER0'],
  "kail": ['K EY1 L'],
  "kailash": ['K EY1 L AH0 SH'],
  "kailua": ['K AY0 L UW1 AH0'],
  "kaim": ['K EY1 M'],
  "kain": ['K EY1 N'],
  "kaine": ['K EY1 N'],
  "kainer": ['K EY1 N ER0'],
  "kainz": ['K EY1 N Z'],
  "kairamo": ['K EH2 R AA1 M OW0'],
  "kairey": ['K EH1 R IY0'],
  "kaiser": ['K AY1 Z ER0'],
  "kaiser's": ['K AY1 Z ER0 Z'],
  "kaiseraugst": ['K AY1 Z ER0 AO0 G S T'],
  "kaisertech": ['K AY1 Z ER0 T EH2 K'],
  "kaisertech's": ['K AY1 Z ER0 T EH2 K S'],
  "kaisha": ['K EY1 SH AH0'],
  "kaji": ['K AA1 JH IY0'],
  "kajima": ['K AA2 JH IY1 M AH0'],
  "kajuahar": ['K AH0 JH UW1 AH0 HH AA0 R'],
  "kakadu": ['K AA2 K AA1 D UW0'],
  "kakimoto": ['K AA2 K IH0 M OW1 T OW0'],
  "kakos": ['K EY1 K OW0 Z'],
  "kaktovik": ['K AE2 K T OW1 V IH0 K'],
  "kakuei": ['K AE1 K Y UW0 IY0'],
  "kakumaru": ['K AA2 K UW0 M AA1 R UW0'],
  "kal": ['K AE1 L', 'K EY1 EY1 EH1 L'],
  "kalafut": ['K AE1 L AH0 F AH0 T'],
  "kalal": ['K EY1 L AH0 L'],
  "kalamazoo": ['K AE2 L AH0 M AH0 Z UW1'],
  "kalan": ['K EY1 L AH0 N'],
  "kalas": ['K AA1 L AH0 Z'],
  "kalashnikov": ['K AH0 L AE1 SH N IH0 K AA2 V'],
  "kalata": ['K AH0 L AA1 T AH0'],
  "kalb": ['K AE1 L B'],
  "kalbach": ['K AE1 L B AA2 K'],
  "kalberer": ['K AE1 L B ER0 ER0'],
  "kalbfleisch": ['K AE1 L B F L AY2 SH'],
  "kale": ['K EY1 L'],
  "kaleel": ['K AE1 L IY0 L'],
  "kaleida": ['K AH0 L AY1 D AH0'],
  "kaleidoscope": ['K AH0 L AY1 D AH0 S K OW2 P'],
  "kalen": ['K EY1 L AH0 N'],
  "kaler": ['K EY1 L ER0'],
  "kaleta": ['K AE1 L IH0 T AH0'],
  "kaley": ['K EY1 L IY0'],
  "kalgoorlie": ['K AE2 L G UW1 R L IY0'],
  "kali": ['K AA1 L IY2'],
  "kalikow": ['K AE1 L IH0 K OW0'],
  "kalil": ['K AE1 L AH0 L'],
  "kalin": ['K AE1 L IH0 N'],
  "kalina": ['K AH0 L AY1 N AH0'],
  "kaliningrad": ['K AH0 L IH1 N IH0 NG G R AE2 D'],
  "kalinoski": ['K AH0 L IH0 N AW1 S K IY0'],
  "kalinowski": ['K AH0 L IH0 N AO1 F S K IY0'],
  "kalinske": ['K AH0 L IH1 N S K IY0'],
  "kalinski": ['K AH0 L IH1 N S K IY0'],
  "kalis": ['K AE1 L IH0 S'],
  "kalish": ['K AE1 L IH0 SH'],
  "kalispell": ['K AE1 L IH0 S P EH2 L'],
  "kalisz": ['K AA1 L IH0 SH'],
  "kaliszewski": ['K AH0 L IH0 SH EH1 F S K IY0'],
  "kalivoda": ['K AH0 L IH0 V OW1 D AH0'],
  "kalk": ['K AO1 K'],
  "kalka": ['K AE1 L K AH0'],
  "kalkaska": ['K AE0 L K AA1 S K AH0'],
  "kalkbrenner": ['K AE1 L K B R IH0 N ER0'],
  "kall": ['K AO1 L'],
  "kalla": ['K AE1 L AH0'],
  "kallal": ['K AE1 L AH0 L'],
  "kallam": ['K AE1 L AH0 M'],
  "kallas": ['K AE1 L AH0 Z'],
  "kallay": ['K AE1 L EY0'],
  "kallen": ['K AO1 L AH0 N'],
  "kallenbach": ['K AE1 L IH0 N B AA0 K'],
  "kallenberger": ['K AO1 L AH0 N B ER0 G ER0'],
  "kallhoff": ['K AE1 L HH AO0 F'],
  "kalliel": ['K AE1 L IY0 AH0 L'],
  "kallins": ['K AE1 L IH0 N Z'],
  "kallio": ['K AE1 L IY0 OW0'],
  "kallis": ['K AE1 L IH0 S'],
  "kallman": ['K AO1 L M AH0 N'],
  "kallmeyer": ['K AE1 L M AY0 ER0'],
  "kallstrom": ['K AE1 L S T R AH0 M'],
  "kallus": ['K AE1 L AH0 S'],
  "kalman": ['K AE1 L M AH0 N'],
  "kalmanovitz": ['K AE2 L M AE1 N AH0 V IH0 T S'],
  "kalmar": ['K AE1 L M ER0'],
  "kalmbach": ['K AE1 L M B AA0 K'],
  "kalmus": ['K AE1 L M IH0 S'],
  "kalnins": ['K AE1 L N IH0 N Z'],
  "kalo": ['K EY1 L OW0'],
  "kalok": ['K AE1 L AA2 K'],
  "kalous": ['K AE1 L AH0 S'],
  "kalp": ['K AE1 L P'],
  "kalt": ['K AO1 L T'],
  "kaltenbach": ['K AE1 L T IH0 N B AA0 K'],
  "kaltenbacher": ['K AA1 L T AH0 N B AA2 K ER0'],
  "kalter": ['K AO1 L T ER0'],
  "kalthoff": ['K AE1 L TH'],
  "kalugin": ['K AH0 L UW1 G AH0 N'],
  "kaluza": ['K AH0 L UW1 Z AH0'],
  "kaluzny": ['K AH0 L AH1 Z N IY0'],
  "kalvar": ['K AE1 L V AA0 R'],
  "kam": ['K AE1 M'],
  "kama": ['K AA1 M AH0'],
  "kamakau": ['K AA2 M AH0 K AA1 UW0'],
  "kamakau's": ['K AA2 M AH0 K AA1 UW0 Z'],
  "kamal": ['K EY1 M AH0 L'],
  "kamali": ['K AH0 M AA1 L IY0'],
  "kaman": ['K EY1 M AH0 N'],
  "kamber": ['K AE1 M B ER0'],
  "kamchatka": ['K AE2 M CH AE1 T K AH0', 'K AA2 M CH AA1 T K AH0'],
  "kamehameha": ['K AH0 M EY1 AH0 M EY1 AH0'],
  "kamehameha's": ['K AH0 M EY1 AH0 M EY1 AH0 Z'],
  "kamei": ['K AE1 M IY0', 'K AA2 M EY1'],
  "kamel": ['K AA1 M AH0 L'],
  "kamen": ['K AA1 M EH0 N', 'K EY1 M EH0 N'],
  "kamenar": ['K AE1 M AH0 N ER0'],
  "kamens": ['K AA1 M EH0 N Z', 'K EY1 M EH0 N Z'],
  "kamensky": ['K AH0 M EH1 N S K IY0'],
  "kamentsev": ['K AH0 M EH1 N T S AA2 V'],
  "kamer": ['K EY1 M ER0'],
  "kamerer": ['K AE1 M ER0 ER0'],
  "kamikaze": ['K AA2 M AH0 K AA1 Z IY0'],
  "kamin": ['K AA1 M IY0 N', 'K EY1 M IH2 N', 'K AE1 M IH2 N'],
  "kaminer": ['K AE1 M IH0 N ER0'],
  "kamins": ['K AE1 M IH0 N Z', 'K EY1 M IH2 N Z'],
  "kaminski": ['K AH0 M IH1 N S K IY0'],
  "kaminsky": ['K AH0 M IH1 N S K IY0'],
  "kamir": ['K AH0 M IH1 R'],
  "kamke": ['K AE1 M K IY0'],
  "kamler": ['K AE1 M AH0 L ER0', 'K AE1 M L ER0'],
  "kamm": ['K AE1 M'],
  "kamman": ['K AE1 M AH0 N'],
  "kammer": ['K AE1 M ER0'],
  "kammerer": ['K AE1 M ER0 ER0'],
  "kammerzell": ['K AE1 M ER0 Z AH0 L'],
  "kammeyer": ['K AE1 M AY0 ER0'],
  "kamneva": ['K AE2 M N EY1 V AH0'],
  "kamp": ['K AE1 M P'],
  "kamp's": ['K AE1 M P S'],
  "kampa": ['K AE1 M P AH0'],
  "kampala": ['K AH0 M P AA1 L AH0'],
  "kampe": ['K AE1 M P'],
  "kampelman": ['K AE1 M P AH0 L M AH0 N'],
  "kampen": ['K AE1 M P AH0 N'],
  "kamper": ['K AE1 M P ER0'],
  "kampf": ['K AE1 M P F'],
  "kampfer": ['K AE1 M P F ER0'],
  "kamphaus": ['K AE1 M P HH AW2 S'],
  "kampman": ['K AE1 M P M AH0 N'],
  "kampmann": ['K AE1 M P M AH0 N'],
  "kamps": ['K AE1 M P S'],
  "kampschulte": ['K AE1 M P SH UH2 L T IY0'],
  "kamra": ['K AE1 M R AH0'],
  "kamradt": ['K AE1 M R AH0 T'],
  "kamran": ['K AE1 M R AH0 N'],
  "kamrath": ['K AE1 M R AH0 TH'],
  "kamstra": ['K AE1 M S T R AH0'],
  "kan": ['K AE1 N'],
  "kana": ['K AE1 N AH0'],
  "kanade": ['K AH0 N AA1 D EY2'],
  "kanady": ['K AE1 N AH0 D IY0'],
  "kanagy": ['K AE1 N AH0 JH IY0'],
  "kanai": ['K AH0 N AY1'],
  "kanak": ['K AE1 N AH0 K'],
  "kanan": ['K EY1 N AH0 N'],
  "kanarek": ['K AE1 N ER0 IH0 K'],
  "kanawa": ['K AA2 N AA1 W AH0'],
  "kanawha": ['K AH0 N AO1 HH AH0'],
  "kanazawa": ['K AA2 N AA0 Z AA1 W AH0'],
  "kanda": ['K AE1 N D AH0'],
  "kandahar": ['K AE1 N D AH0 HH AA0 R'],
  "kandel": ['K AE1 N D AH0 L'],
  "kandler": ['K AE1 N D L ER0'],
  "kandt": ['K AE1 N T'],
  "kane": ['K EY1 N'],
  "kane's": ['K EY1 N Z'],
  "kaneb": ['K AE1 N AH0 B'],
  "kanegsberg": ['K AE1 N AH0 G Z B ER0 G'],
  "kaneko": ['K AA0 N EY1 K OW0'],
  "kanell": ['K AH0 N EH1 L', 'K AE1 N AH0 L'],
  "kanemaru": ['K AE2 N EH0 M AA1 R UW0'],
  "kanemaru's": ['K AA2 N EY0 M AA1 R UW0 Z'],
  "kaner": ['K EY1 N ER0'],
  "kaneshiro": ['K AA0 N EY0 SH IH1 R OW0'],
  "kaney": ['K EY1 N IY0'],
  "kang": ['K AE1 NG', 'K AA1 NG'],
  "kangaroo": ['K AE2 NG G ER0 UW1'],
  "kangaroos": ['K AE2 NG G ER0 UW1 Z'],
  "kangas": ['K AE1 NG G AH0 Z'],
  "kanghua": ['K AE1 NG HH Y UW0 AH0'],
  "kangyo": ['K AE1 N JH Y OW0'],
  "kania": ['K AA0 N IY1 AH0'],
  "kaniewski": ['K AA0 N IY0 EH1 F S K IY0', 'K AA0 N IY0 UW1 S K IY0'],
  "kanipe": ['K AE1 N IH0 P'],
  "kanis": ['K AE1 N IH0 S'],
  "kanitz": ['K AE1 N IH0 T S'],
  "kanji": ['K AE1 N JH IY0'],
  "kanjorski": ['K AH0 N JH AO1 R S K IY0'],
  "kanka": ['K AE1 N K AH0', 'K AE1 NG K AH0'],
  "kankakee": ['K AE1 NG K IH0 K IY0'],
  "kankaku": ['K AA2 N K AA1 K UW0'],
  "kann": ['K AE1 N'],
  "kannan": ['K AA1 N AH0 N'],
  "kanne": ['K AE1 N'],
  "kannenberg": ['K AE1 N AH0 N B ER0 G'],
  "kanner": ['K AE1 N ER0'],
  "kanno": ['K AE1 N OW0'],
  "kano": ['K AA1 N OW0'],
  "kanode": ['K AH0 N OW1 D'],
  "kanon": ['K EY1 N AH0 N'],
  "kanouse": ['K AA0 N OW0 UW1 S EY0'],
  "kansai": ['K AE0 N S AY1'],
  "kansallis": ['K AE2 N S AE1 L IH0 S'],
  "kansan": ['K AE1 N Z AH0 N'],
  "kansan's": ['K AE1 N Z AH0 N Z'],
  "kansans": ['K AE1 N Z AH0 N Z'],
  "kansans'": ['K AE1 N Z AH0 N Z'],
  "kansas": ['K AE1 N Z AH0 S'],
  "kansas'": ['K AE1 N Z AH0 S'],
  "kansas's": ['K AE1 N Z AH0 S IH0 Z'],
  "kansas-city": ['K AE1 N Z AH0 S IH1 T IY0', 'K AE1 N Z AH0 S S IH1 T IY0'],
  "kansian": ['K AE1 N Z IY0 AH0 N'],
  "kant": ['K AE1 N T'],
  "kanter": ['K AE1 N T ER0'],
  "kanter's": ['K AE1 N T ER0 Z'],
  "kantian": ['K AE1 N T IY0 AH0 N'],
  "kantle": ['K AE1 N T AH0 L'],
  "kantner": ['K AE1 N T N ER0'],
  "kantola": ['K AH0 N T OW1 L AH0'],
  "kantor": ['K AE1 N T ER0'],
  "kantor's": ['K AE1 N T ER0 Z', 'K AE1 N T AO0 R Z'],
  "kantrowitz": ['K AE1 N T R AH0 W IH0 T S'],
  "kantz": ['K AE1 N T S'],
  "kanz": ['K AE1 N Z'],
  "kanzi": ['K AA1 N Z IY0'],
  "kanzler": ['K AE1 N Z L ER0'],
  "kao": ['K AW1', 'K EY1 OW2'],
  "kaohsiung": ['K EY2 OW1 S IY0 AH0 NG'],
  "kaolin": ['K AW1 L IH0 N', 'K EY1 OW0 L IH0 N'],
  "kaori": ['K AO1 R IY0'],
  "kapaun": ['K AE1 P AW0 N'],
  "kapfer": ['K AE1 P F ER0'],
  "kapinos": ['K AE1 P IH0 N OW0 Z', 'K AE1 P IY0 N OW0 Z'],
  "kapitan": ['K AE1 P IH0 T AH0 N'],
  "kaplain": ['K AE1 P L AH0 N'],
  "kaplain's": ['K AE1 P L AH0 N Z'],
  "kaplan": ['K AE1 P L AH0 N'],
  "kaplan's": ['K AE1 P L AH0 N Z'],
  "kapler": ['K EY1 P AH0 L ER0', 'K EY1 P L ER0'],
  "kaplin": ['K AE1 P L IH0 N'],
  "kaplow": ['K AE1 P L OW0'],
  "kaplowitz": ['K AA1 P L AH0 W IH0 T S'],
  "kapner": ['K AE1 P N ER0'],
  "kapnick": ['K AE1 P N IH0 K'],
  "kapok": ['K EY1 P AA0 K'],
  "kapoor": ['K AH0 P UW1 R'],
  "kapor": ['K EY1 P ER0'],
  "kaposi": ['K AH0 P OW1 S IY0'],
  "kaposi's": ['K AH0 P OW1 S IY0 Z'],
  "kapp": ['K AE1 P'],
  "kappa": ['K AE1 P AH0'],
  "kappel": ['K AE1 P AH0 L'],
  "kappeler": ['K AE1 P AH0 L ER0'],
  "kappelman": ['K AE1 P AH0 L M AH0 N'],
  "kapper": ['K AE1 P ER0'],
  "kappes": ['K AE1 P S'],
  "kappler": ['K AE1 P L ER0'],
  "kappus": ['K AE1 P AH0 S'],
  "kapral": ['K AE1 P R AH0 L'],
  "kaprayoon": ['K AE1 P R AH0 Y UW0 N'],
  "kaps": ['K AE1 P S'],
  "kapton": ['K AE1 P T AH0 N'],
  "kaptur": ['K AE1 P T ER0'],
  "kapur": ['K AE1 P ER0'],
  "kapuscinski": ['K AH0 P AH0 S CH IH1 N S K IY0'],
  "kapusta": ['K AH0 P AH1 S T AH0'],
  "kara": ['K EH1 R AH0'],
  "karabakh": ['K EH1 R AH0 B AE0 K'],
  "karabin": ['K AA0 R AA1 B IY0 N'],
  "karach": ['K AE1 R AH0 CH'],
  "karachi": ['K AA0 R AA1 CH IY0'],
  "karadzic": ['K AA0 R AA1 JH IH0 K'],
  "karadzic's": ['K AA0 R AA1 JH IH0 K S'],
  "karaffa": ['K AE1 R AH0 F AH0'],
  "karajan": ['K EH1 R AH0 JH AH0 N'],
  "karakos": ['K AA1 R AH0 K AO2 S'],
  "karam": ['K AE1 R AH0 M'],
  "karami": ['K ER0 AA1 M IY0'],
  "karan": ['K EH1 R AH0 N'],
  "karan's": ['K EH1 R AH0 N Z'],
  "karanicki": ['K EH2 R AH0 N IH1 K IY0'],
  "karanitski": ['K EH2 R AH0 N IH1 T S K IY0'],
  "karaoke": ['K EH2 R IY0 OW1 K IY0'],
  "karas": ['K AA1 R AH0 Z'],
  "karasawa": ['K AA2 R AH0 S AA1 W AH0'],
  "karasek": ['K ER0 AA1 S EH0 K'],
  "karasik": ['K ER0 AA1 S IH0 K'],
  "karat": ['K EH1 R AH0 T'],
  "karate": ['K ER0 AA1 T IY0'],
  "karatirm": ['K ER1 AH0 T ER0 M'],
  "karatsu": ['K ER0 AE1 T S UW1'],
  "karatz": ['K EH1 R AH0 T S'],
  "karban": ['K AA1 R B AH0 N'],
  "karbassioun": ['K AA2 R B AE1 S IY0 UW2 N'],
  "karber": ['K AA1 R B ER0'],
  "karbowski": ['K ER0 B AO1 F S K IY0'],
  "karch": ['K AA1 R K'],
  "karcher": ['K AA1 R CH ER0'],
  "karcz": ['K AA1 R CH'],
  "karczewski": ['K ER0 CH EH1 F S K IY0'],
  "kardashian": ['K AA1 D AH0 SH EY2 N'],
  "kardashian's": ['K AA1 D AH0 SH EY2 N Z'],
  "kardell": ['K AA1 R D AH0 L'],
  "kardos": ['K AA1 R D OW0 Z'],
  "kareem": ['K ER0 IY1 M'],
  "kareem's": ['K ER0 IY1 M Z'],
  "karel": ['K EH1 R AH0 L'],
  "karelian": ['K ER0 IY1 L IY0 AH0 N'],
  "karels": ['K EH1 R AH0 L Z'],
  "karema": ['K AH0 R EH1 M AH0'],
  "karema's": ['K AH0 R EH1 M AH0 Z'],
  "karen": ['K EH1 R AH0 N'],
  "karen's": ['K EH1 R AH0 N Z'],
  "karenina": ['K EH2 R EH0 N IY1 N AH0', 'K AH0 R EH1 N IH0 N AH0'],
  "karet": ['K EH1 R AH0 T'],
  "karg": ['K AA1 R G'],
  "karger": ['K AA1 R G ER0'],
  "karges": ['K AA1 R JH IH0 Z'],
  "kargonaov": ['K AA1 R G AH0 N AO2 V'],
  "kari": ['K EH1 R IY0'],
  "karibu": ['K EH2 R IY1 B UW0'],
  "karim": ['K ER0 IY1 M'],
  "karimi": ['K AA0 R IY1 M IY0'],
  "karin": ['K EH1 R IH0 N'],
  "karina": ['K AA0 R IY1 N AH0'],
  "karino": ['K EH2 R IY1 N OW0'],
  "kariotis": ['K AA2 R IY0 OW1 T IH0 S'],
  "karis": ['K EH1 R IY0 Z'],
  "karl": ['K AA1 R L'],
  "karl's": ['K AA1 R L Z'],
  "karla": ['K AA1 R L AH0'],
  "karle": ['K AA1 R AH0 L'],
  "karlen": ['K AA1 R L AH0 N'],
  "karlheinz": ['K AA1 R L HH AY1 N Z'],
  "karlik": ['K AA1 R L IH0 K'],
  "karlin": ['K AA1 R L IH0 N'],
  "karline": ['K AA1 R L AY2 N'],
  "karlow": ['K AA1 R L OW0'],
  "karls": ['K AA1 R L Z'],
  "karlsen": ['K AA1 R L S AH0 N'],
  "karlson": ['K AA1 R L S AH0 N'],
  "karlsruhe": ['K AA1 R L Z R UW2'],
  "karlsson": ['K AA1 R L S AH0 N'],
  "karma": ['K AA1 R M AH0'],
  "karman": ['K AA1 R M AH0 N'],
  "karn": ['K AA1 R N'],
  "karnal": ['K AA1 R N AH0 L'],
  "karner": ['K AA1 R N ER0'],
  "karnes": ['K AA1 R N Z'],
  "karney": ['K AA1 R N IY0'],
  "karnicki": ['K AA0 R N IH1 K IY0'],
  "karnow": ['K AA1 R N OW0', 'K AA1 R N AW0'],
  "karns": ['K AA1 R N Z'],
  "karnsund": ['K AA1 R N S AH0 N D'],
  "karol": ['K EH1 R AO0 L'],
  "karolina": ['K EH2 R AH0 L AY1 N AH0'],
  "karoline": ['K EH1 R AH0 L AY2 N'],
  "karolinska": ['K EH2 R OW0 L IH1 N S K AH0'],
  "karoly": ['K ER0 OW1 L IY0'],
  "karolyi": ['K ER0 OW1 L Y IY0'],
  "karolyn": ['K AE1 R AH0 L IH0 N'],
  "karon": ['K EH1 R AH0 N'],
  "karos": ['K AA1 R OW0 S'],
  "karoun": ['K ER0 UW1 N'],
  "karow": ['K AA1 R OW0'],
  "karp": ['K AA1 R P'],
  "karpatkin": ['K AA2 R P AE1 T K IH0 N'],
  "karpel": ['K AA1 R P AH0 L'],
  "karpen": ['K AA1 R P AH0 N'],
  "karpf": ['K AA1 R P F'],
  "karpinski": ['K ER0 P IH1 N S K IY0'],
  "karpov": ['K AA1 R P AA2 V'],
  "karpowicz": ['K AA1 R P AH0 V IH0 CH'],
  "karr": ['K AA1 R'],
  "karraker": ['K AA1 R AH0 K ER0'],
  "karras": ['K AE1 R AH0 Z'],
  "karren": ['K AE1 R AH0 N'],
  "karrer": ['K AA1 R ER0'],
  "karrick": ['K AE1 R IH0 K'],
  "karriker": ['K AE1 R IH0 K ER0'],
  "karry": ['K EH1 R IY0'],
  "karsh": ['K AA1 R SH'],
  "karshner": ['K AA1 R SH N ER0'],
  "karson": ['K AA1 R S AH0 N'],
  "karst": ['K AA1 R S T'],
  "karstadt": ['K AA1 R S T AE2 T'],
  "karsten": ['K AA1 R S T AH0 N'],
  "karstens": ['K AA1 R S T AH0 N Z'],
  "karstetter": ['K AA1 R S T IH0 T ER0'],
  "kartasasmita": ['K AA0 R T AE2 S AH0 S M IY1 T AH0'],
  "kartchner": ['K AA1 R CH N ER0'],
  "karter": ['K AA1 R T ER0'],
  "kartes": ['K AA1 R T S'],
  "karun": ['K ER0 UW1 N'],
  "karvonen": ['K AA1 R V AH0 N AH0 N'],
  "karwoski": ['K ER0 V AW1 S K IY0'],
  "karwowski": ['K ER0 V AO1 F S K IY0'],
  "kary": ['K EH1 R IY0'],
  "karydakis": ['K EH2 R IY0 D AE1 K IH0 S'],
  "karyn": ['K AE1 R IH0 N'],
  "karyrrhexis": ['K EH2 R IY0 R EH1 K S IH0 S'],
  "kasabian": ['K AH0 S EY1 B IY0 AH0 N'],
  "kasagic": ['K AH0 S AA1 G IH0 K'],
  "kasal": ['K EY1 S AH0 L'],
  "kasbrol": ['K AE1 Z B R AO0 L'],
  "kasbrols": ['K AE1 Z B R AO0 L Z'],
  "kasch": ['K AE1 SH'],
  "kaschak": ['K AE1 SH AH0 K'],
  "kasdorf": ['K AE1 S D AO0 R F'],
  "kase": ['K EY1 Z'],
  "kasel": ['K AE1 S AH0 L'],
  "kasell": ['K AE1 S AH0 L'],
  "kaseman": ['K EY1 S M AH0 N'],
  "kaser": ['K EY1 Z ER0'],
  "kasese": ['K AH0 S IY1 Z IY0'],
  "kasey": ['K AE1 S IY0'],
  "kash": ['K AE1 SH'],
  "kasha": ['K AA1 SH AH0'],
  "kashiwagi": ['K AE2 SH IH0 W AE1 G IY0'],
  "kashiwahara": ['K AA2 SH IY0 W AH0 HH AA1 R AH0'],
  "kashiwahara's": ['K AA2 SH IY0 W AH0 HH AA1 R AH0 Z'],
  "kashiyama": ['K AA2 SH IY0 Y AA1 M AH0'],
  "kashmir": ['K AE1 SH M IH0 R', 'K AE1 SH M IY0 R'],
  "kashmiri": ['K AE0 SH M IY1 R IY0'],
  "kashner": ['K AE1 SH N ER0'],
  "kashoggi": ['K AH0 SH AA1 JH IY0'],
  "kashuba": ['K AH0 SH UW1 B AH0'],
  "kasi": ['K AA1 Z IY0'],
  "kasich": ['K AA1 S IH0 CH'],
  "kasich's": ['K AA1 S IH0 CH IH0 Z'],
  "kasick": ['K AA1 S IH0 K'],
  "kasik": ['K AA1 S IH0 K'],
  "kasinga": ['K AH0 S IH1 NG G AH0'],
  "kasinga's": ['K AH0 S IH1 NG G AH0 Z'],
  "kasinger": ['K AE1 S IH0 N JH ER0'],
  "kaska": ['K AA1 S K AH0'],
  "kaske": ['K AE1 S K IY0'],
  "kaskel": ['K AE1 S K AH0 L'],
  "kasler": ['K AE1 S AH0 L ER0', 'K AE1 S L ER0'],
  "kasmer": ['K AE1 S AH0 M ER0'],
  "kasmira": ['K AH0 S M AY1 R AH0'],
  "kasner": ['K AE1 S N ER0'],
  "kaspar": ['K AE1 S P ER0'],
  "kasparek": ['K AE1 S P ER0 IH0 K'],
  "kasparian": ['K AH0 S P EH1 R IY0 AH0 N'],
  "kasparov": ['K AE1 S P ER0 AA0 V', 'K AH0 S P AA1 R AA2 V'],
  "kasper": ['K AE1 S P ER0'],
  "kasperek": ['K AE1 S P ER0 IH0 K'],
  "kasperski": ['K AH0 S P ER1 S K IY0'],
  "kasprowicz": ['K AA1 S P R AH0 V IH0 CH'],
  "kasprzak": ['K AA1 S P ER0 Z AH0 K'],
  "kasprzyk": ['K AA1 S P ER0 Z IH0 K'],
  "kasputys": ['K AE2 S P Y UW1 T IH0 S'],
  "kasriel": ['K AE1 S R IY0 AH0 L'],
  "kass": ['K AE1 S'],
  "kassa": ['K AE1 S AH0'],
  "kassab": ['K AE1 S AH0 B'],
  "kassan": ['K AE1 S AH0 N'],
  "kassar": ['K AE1 S ER0'],
  "kassebaum": ['K AE1 S AH0 B AW2 M', 'K AE1 S AH0 B AA2 M'],
  "kassebaum's": ['K AE1 S AH0 B AW2 M Z', 'K AE1 S AH0 B AA2 M Z'],
  "kassel": ['K AE1 S AH0 L'],
  "kassem": ['K AE1 S AH0 M'],
  "kassen": ['K AE1 S AH0 N'],
  "kassin": ['K AE1 S IH0 N'],
  "kassing": ['K AE1 S IH0 NG'],
  "kassis": ['K AE1 S IH0 S'],
  "kassler": ['K AE1 S L ER0'],
  "kassner": ['K AE1 S N ER0'],
  "kasson": ['K AE1 S AH0 N'],
  "kast": ['K AE1 S T'],
  "kastel": ['K EY1 S T AH0 L'],
  "kastelic": ['K AH0 S T EH1 L IH0 K'],
  "kasten": ['K AE1 S T AH0 N'],
  "kastenmeier": ['K AE1 S T AH0 N M AY2 R'],
  "kastens": ['K EY1 S AH0 N Z'],
  "kaster": ['K AE1 S T ER0'],
  "kastl": ['K AE1 S T AH0 L'],
  "kastle": ['K AE1 S AH0 L'],
  "kastler": ['K AE1 S T L ER0'],
  "kastner": ['K AE1 S T N ER0'],
  "kasun": ['K AA1 S UW0 N'],
  "kasza": ['K AA1 SH AH0'],
  "kaszuba": ['K AH0 SH UW1 B AH0'],
  "kat": ['K AE1 T'],
  "katana": ['K AA2 T AA1 N AA2'],
  "kataoka": ['K AA0 T AA0 OW1 K AH0'],
  "katarina": ['K AA2 T ER0 IY1 N AH0'],
  "katarina's": ['K AA2 T ER0 IY1 N AH0 Z'],
  "katashiba": ['K AE2 T AH0 SH IY1 B AH0'],
  "katayama": ['K AA0 T AA0 Y AA1 M AH0'],
  "katayan": ['K AA1 T AH0 Y AA0 N'],
  "katcher": ['K AE1 CH ER0'],
  "kate": ['K EY1 T'],
  "kate's": ['K EY1 T S'],
  "katen": ['K EY1 T AH0 N'],
  "kater": ['K EY1 T ER0'],
  "kates": ['K EY1 T S'],
  "katey": ['K EY1 T IY0'],
  "kath": ['K AE1 TH'],
  "katha": ['K AE1 TH AH0'],
  "kathalene": ['K AE0 TH AH0 L IY1 N'],
  "kathan": ['K AE1 TH AH0 N'],
  "katharina": ['K AE2 TH EH0 R IY1 N AH0'],
  "katharine": ['K AE1 TH R IH0 N'],
  "katherine": ['K AE1 TH ER0 IH0 N', 'K AE1 TH R IH0 N'],
  "kathi": ['K AE1 TH IY0'],
  "kathie": ['K AE1 TH IY0'],
  "kathleen": ['K AE0 TH L IY1 N'],
  "kathleen's": ['K AE0 TH L IY1 N Z'],
  "kathlene": ['K AE1 TH L IY2 N'],
  "kathline": ['K AE1 TH L AY2 N'],
  "kathman": ['K AE1 TH M AH0 N'],
  "kathryn": ['K AE1 TH R IH0 N'],
  "kathy": ['K AE1 TH IY0'],
  "kathy's": ['K AE1 TH IY0 Z'],
  "kati": ['K EY1 T IY0'],
  "katia": ['K AA1 T IY0 AH0'],
  "katie": ['K EY1 T IY0'],
  "katie's": ['K EY1 T IY0 Z'],
  "katja": ['K AA1 T Y AH0'],
  "katmandu": ['K AE2 T M AE0 N D UW1'],
  "kato": ['K EY1 T OW0'],
  "kato's": ['K EY1 T OW0 Z'],
  "katona": ['K AA0 T OW1 N AH0'],
  "katragadda": ['K AA2 T R AH0 G AA1 D AH0'],
  "katrina": ['K AH0 T R IY1 N AH0'],
  "kats": ['K AE1 T S'],
  "katsanos": ['K AE2 T S AA1 N OW0 S'],
  "katsaros": ['K AE1 T S ER0 OW0 Z'],
  "katsuhiko": ['K AA2 T S UW0 HH IY1 K OW0'],
  "katsumi": ['K AA2 T S UW1 M IY0'],
  "katsushi": ['K AE2 T S UW1 SH IY0'],
  "katt": ['K AE1 T'],
  "kattner": ['K AE1 T N ER0'],
  "katuni": ['K AH0 T UW1 N IY0'],
  "katy": ['K EY1 T IY0'],
  "katya": ['K AA1 T Y AH0'],
  "katydid": ['K EY1 T IY0 D IH2 D'],
  "katyn": ['K EY1 T IH2 N', 'K EY1 T AH0 N', 'K AH0 T IY1 N'],
  "katyusha": ['K AH0 T Y UW1 SH AH0'],
  "katyushas": ['K AH0 T Y UW1 SH AH0 Z'],
  "katz": ['K AE1 T S'],
  "katzen": ['K AE1 T Z AH0 N'],
  "katzenbach": ['K AE1 T S AH0 N B AA2 K'],
  "katzenberg": ['K AE1 T S AH0 N B ER0 G'],
  "katzenberg's": ['K AE1 T S AH0 N B ER0 G Z'],
  "katzenberger": ['K AE1 T Z AH0 N B ER0 G ER0'],
  "katzenstein": ['K AE1 T S AH0 N S T AY0 N', 'K AE1 T S AH0 N S T IY0 N'],
  "katzer": ['K AE1 T S ER0'],
  "katzin": ['K AE1 T S IH0 N'],
  "katzman": ['K AE1 T S M AH0 N'],
  "kau": ['K AW1'],
  "kauai": ['K AW1 AY2'],
  "kauble": ['K AO1 B AH0 L'],
  "kaucher": ['K AW1 K ER0'],
  "kauer": ['K AW1 ER0'],
  "kaufer": ['K AO1 F ER0'],
  "kauffman": ['K AO1 F M AH0 N'],
  "kauffmann": ['K AO1 F M AH0 N'],
  "kaufhof": ['K AO1 F HH AO0 F'],
  "kaufhold": ['K AW1 F HH OW0 L D'],
  "kaufman": ['K AO1 F M AH0 N'],
  "kaufman's": ['K AO1 F M AH0 N Z'],
  "kaufmann": ['K AO1 F M AH0 N'],
  "kauk": ['K AO1 K'],
  "kaul": ['K AO1 L'],
  "kaumography": ['K OW0 M AA1 G R AH0 F IY0'],
  "kaunakakai": ['K AW2 N AH1 K AH0 K AY0'],
  "kaunda": ['K AO1 N D AH0'],
  "kaup": ['K AO1 P'],
  "kaupp": ['K AO1 P'],
  "kauppi": ['K AO1 P IY0'],
  "kaus": ['K AO1 Z'],
  "kausch": ['K AW1 SH'],
  "kauth": ['K AO1 TH'],
  "kautz": ['K AO1 T S'],
  "kautzman": ['K AW1 T S M AH0 N'],
  "kauzlarich": ['K AW1 Z L ER0 IH0 K'],
  "kavan": ['K EY1 V AH0 N'],
  "kavanagh": ['K AE1 V AH0 N AO2'],
  "kavanaugh": ['K AE1 V AH0 N AO2'],
  "kaveney": ['K AE1 V IH0 N IY0'],
  "kavner": ['K AE1 V N ER0', 'K AO1 V N ER0'],
  "kawa": ['K AA1 W AH0'],
  "kawaguchi": ['K AA2 W AA0 G UW1 CH IY0'],
  "kawahara": ['K AA2 W AA0 HH AA1 R AH0'],
  "kawai": ['K AA0 W AA1 IY0'],
  "kawakami": ['K AA2 W AA0 K AA1 M IY0'],
  "kawamoto": ['K AA0 W AA0 M OW1 T OW0'],
  "kawamura": ['K AW2 AA0 M UH1 R AH0'],
  "kawano": ['K AA0 W AA1 N OW0'],
  "kawasaki": ['K AA2 W AA0 S AA1 K IY0'],
  "kawashima": ['K AA2 W AA0 SH IY1 M AH0'],
  "kawasmi": ['K AH0 W AA1 S M IY0'],
  "kawate": ['K AA2 W AA1 T EY2'],
  "kawecki": ['K AA0 V EH1 T S K IY0'],
  "kaweske": ['K AA0 V EH1 S K IY0', 'K AH0 W EH1 S K IY0'],
  "kay": ['K EY1'],
  "kay's": ['K EY1 Z'],
  "kaya": ['K AA1 Y AH0'],
  "kayak": ['K AY1 AE0 K'],
  "kayaker": ['K AY1 AE0 K ER0'],
  "kayakers": ['K AY1 AE0 K ER0 Z'],
  "kayaking": ['K AY1 AE2 K IH0 NG'],
  "kayaks": ['K AY1 AE0 K S'],
  "kayapo": ['K AY1 AH0 P OW2'],
  "kaydon": ['K EY1 D AH0 N'],
  "kaye": ['K EY1'],
  "kayes": ['K EY1 Z'],
  "kayla": ['K EY1 L AH0'],
  "kaylie": ['K EY1 L IY0'],
  "kaylor": ['K EY1 L ER0'],
  "kaylynn": ['K EY0 L IH1 N'],
  "kayne": ['K EY1 N'],
  "kayo": ['K EY0 OW1'],
  "kaypro": ['K EY1 P R OW0'],
  "kays": ['K EY1 Z'],
  "kayser": ['K EY1 Z ER0'],
  "kaysersberg": ['K AY1 Z ER0 Z B ER0 G'],
  "kayvon": ['K EY1 V AA0 N'],
  "kazakh": ['K AE1 Z AE0 K'],
  "kazakhs": ['K AE1 Z AE0 K S'],
  "kazakhstan": ['K AA2 Z AA0 K S T AA1 N', 'K AH0 Z AE2 K S T AE1 N'],
  "kazakhstan's": ['K AA2 Z AA0 K S T AA1 N Z', 'K AH0 Z AE2 K S T AE1 N Z'],
  "kazakhstana": ['K AA2 Z AA0 K S T AA1 N AH0', 'K AH0 Z AE2 K S T AE1 N AH0'],
  "kazan": ['K EY1 Z AH0 N'],
  "kazanjian": ['K AH0 Z AE1 N JH IY0 AH0 N'],
  "kazarian": ['K AH0 Z EH1 R IY0 AH0 N'],
  "kazarian's": ['K AH0 Z EH1 R IY0 AH0 N Z'],
  "kazda": ['K AA1 Z D AH0'],
  "kazee": ['K AA1 Z IY0'],
  "kazempour": ['K AH0 Z EH1 M P AW0 R'],
  "kazen": ['K AE1 Z AH0 N'],
  "kazikaev": ['K AE1 Z IH0 K EY2 V'],
  "kazin": ['K EY1 Z IH0 N'],
  "kazis": ['K AE1 Z IH0 S'],
  "kazlauskas": ['K AE1 Z L AW0 S K AH0 Z'],
  "kazmer": ['K AE1 Z M ER0'],
  "kazmierczak": ['K AA1 Z M IH0 R CH AE0 K'],
  "kazmierski": ['K AH0 Z M IH1 R S K IY0'],
  "kazoo": ['K AH0 Z UW1'],
  "kazuhiko": ['K AA2 Z UW0 HH IY1 K OW0'],
  "kazunori": ['K AE2 Z AH0 N OW1 R IY2'],
  "kazuo": ['K AA2 Z UW1 OW0'],
  "kcal": ['K EY1 K AA0 L'],
  "kcop": ['K EY1 K AO0 P'],
  "ke": ['K EH1'],
  "kea": ['K IY1'],
  "keach": ['K IY1 CH'],
  "keadle": ['K IY1 D AH0 L'],
  "keady": ['K IY1 D IY0'],
  "keagan": ['K IY1 G AH0 N'],
  "keagle": ['K IY1 G AH0 L'],
  "keagy": ['K IY1 JH IY0'],
  "keahey": ['K IY1 HH IY0'],
  "keal": ['K IY1 L'],
  "kealey": ['K IY1 L IY0'],
  "kealy": ['K IY1 L IY0'],
  "kean": ['K IY1 N'],
  "kean's": ['K IY1 N Z'],
  "keane": ['K IY1 N'],
  "keaney": ['K IY1 N IY0'],
  "keanu": ['K IY0 AA1 N UW0', 'K EY0 AA1 N UW0'],
  "kear": ['K IH1 R'],
  "kearby": ['K ER1 B IY0'],
  "kearfott": ['K IH1 R F AA0 T'],
  "kearley": ['K ER1 L IY0'],
  "kearn": ['K ER1 N'],
  "kearney": ['K ER1 N IY0'],
  "kearney's": ['K ER1 N IY0 Z'],
  "kearns": ['K ER1 N Z'],
  "kearny": ['K ER1 N IY0'],
  "kearsarge": ['K IY1 R S AA0 R JH'],
  "kearse": ['K ER1 S'],
  "keary": ['K IH1 R IY0'],
  "keas": ['K IY1 Z'],
  "keasler": ['K IY1 Z L ER0'],
  "keasling": ['K IY1 Z L IH0 NG'],
  "keast": ['K IY1 S T'],
  "keath": ['K IY1 TH'],
  "keathley": ['K IY1 TH L IY0'],
  "keathley's": ['K IY1 TH L IY0 Z'],
  "keating": ['K IY1 T IH0 NG'],
  "keating's": ['K IY1 T IH0 NG Z'],
  "keatley": ['K IY1 T L IY0'],
  "keaton": ['K IY1 T AH0 N'],
  "keator": ['K IY1 T ER0'],
  "keats": ['K IY1 T S'],
  "keatts": ['K IY1 T S'],
  "keaveney": ['K IY1 V IH0 N IY0'],
  "keaveny": ['K IY1 V IH0 N IY0'],
  "keay": ['K IY1 IY0'],
  "kebab": ['K AH0 B AA1 B'],
  "kebab-n-curry": ['K IH0 B AA2 B AH0 N K ER1 IY0'],
  "kebab-n-kurry": ['K IH0 B AA2 B AH0 N K ER1 IY0'],
  "keck": ['K EH1 K'],
  "keckler": ['K EH1 K L ER0'],
  "kedar": ['K IY1 D ER0'],
  "keddy": ['K EH1 D IY0'],
  "kedo": ['K EH1 D OW0'],
  "kedrowski": ['K IH0 D R AO1 F S K IY0'],
  "keds": ['K EH1 D Z'],
  "kedzierski": ['K IH0 JH IH1 R S K IY0'],
  "kee": ['K IY1'],
  "kee's": ['K IY1 Z'],
  "keeble": ['K IY1 B AH0 L'],
  "keebler": ['K IY1 B L ER0'],
  "keech": ['K IY1 CH'],
  "keedy": ['K IY1 D IY0'],
  "keef": ['K IY1 F'],
  "keefe": ['K IY1 F'],
  "keefer": ['K IY1 F ER0'],
  "keeffe": ['K IY1 F'],
  "keegali": ['K IY2 G AA1 L IY0'],
  "keegali's": ['K IY2 G AA1 L IY0 Z'],
  "keegan": ['K IY1 G AH0 N'],
  "keehan": ['K IY1 AH0 N'],
  "keehn": ['K IY1 N'],
  "keehner": ['K IY1 N ER0'],
  "keel": ['K IY1 L'],
  "keelan": ['K IY1 L AH0 N'],
  "keele": ['K IY1 L'],
  "keeler": ['K IY1 L ER0'],
  "keeley": ['K IY1 L IY0'],
  "keelin": ['K IY1 L IH0 N'],
  "keeling": ['K IY1 L IH0 NG'],
  "keels": ['K IY1 L Z'],
  "keelson": ['K EH1 L S AH0 N'],
  "keely": ['K IY1 L IY0'],
  "keen": ['K IY1 N'],
  "keena": ['K IY1 N AH0'],
  "keenan": ['K IY1 N AH0 N'],
  "keene": ['K IY1 N'],
  "keeneland": ['K IY1 N AH0 L AH0 N D'],
  "keener": ['K IY1 N ER0'],
  "keenest": ['K IY1 N AH0 S T'],
  "keeney": ['K IY1 N IY0'],
  "keenly": ['K IY1 N L IY0'],
  "keenum": ['K IY1 N AH0 M'],
  "keeny": ['K IY1 N IY0'],
  "keep": ['K IY1 P'],
  "keeper": ['K IY1 P ER0'],
  "keepers": ['K IY1 P ER0 Z'],
  "keeping": ['K IY1 P IH0 NG'],
  "keeps": ['K IY1 P S'],
  "keepsake": ['K IY1 P S EY2 K'],
  "keepsakes": ['K IY1 P S EY2 K S'],
  "keeran": ['K IH1 R AH0 N'],
  "kees": ['K IY1 Z'],
  "keese": ['K IY1 Z'],
  "keesee": ['K IY0 S IY1'],
  "keesey": ['K IY1 S IY0'],
  "keesler": ['K IY1 Z L ER0'],
  "keesling": ['K IY1 Z L IH0 NG'],
  "keetch": ['K IY1 CH'],
  "keeter": ['K IY1 T ER0'],
  "keeth": ['K IY1 TH'],
  "keeton": ['K IY1 T AH0 N'],
  "keever": ['K IY1 V ER0'],
  "keevil": ['K IY1 V AH0 L'],
  "keezer": ['K IY1 Z ER0'],
  "kefauver": ['K EH1 F AW0 V ER0'],
  "keffer": ['K EH1 F ER0'],
  "keg": ['K EH1 G'],
  "kegel": ['K EH1 JH AH0 L'],
  "kegg": ['K EH1 G'],
  "kegler": ['K EH1 G L ER0'],
  "kegley": ['K EH1 G L IY0'],
  "kegs": ['K EH1 G Z'],
  "kehl": ['K EH1 L'],
  "kehler": ['K EH1 L ER0'],
  "kehm": ['K EH1 M'],
  "kehn": ['K EH1 N'],
  "kehne": ['K EH1 N'],
  "kehoe": ['K EH1 HH OW0'],
  "kehr": ['K EH1 R'],
  "kehrer": ['K EH1 R ER0'],
  "kehres": ['K EH1 R Z'],
  "keicher": ['K AY1 K ER0'],
  "keidanren": ['K AY2 D AE1 N R AH0 N'],
  "keidel": ['K AY1 D AH0 L'],
  "keifer": ['K IY1 F ER0'],
  "keiffer": ['K IY1 F ER0'],
  "keigley": ['K IY1 G L IY0'],
  "keiichi": ['K EY2 IY1 CH IY0'],
  "keiji": ['K IY1 JH IY0'],
  "keiko": ['K EY1 K OW0'],
  "keil": ['K IY1 L'],
  "keillor": ['K IY1 L ER0'],
  "keillor's": ['K IY1 L ER0 Z'],
  "keilman": ['K AY1 L M AH0 N'],
  "keim": ['K IY1 M'],
  "keimig": ['K IY1 M IH0 G'],
  "keinath": ['K AY1 N AH0 TH'],
  "keiner": ['K IY1 N ER0'],
  "keio": ['K EY1 OW0'],
  "keiper": ['K IY1 P ER0'],
  "keir": ['K IY1 R'],
  "keiretsu": ['K IH2 R EH1 T S UW0'],
  "keirn": ['K IH1 R N'],
  "keirns": ['K AY1 R N Z'],
  "keirsey": ['K IH1 R S IY0'],
  "keisei": ['K AY1 S EY2'],
  "keiser": ['K AY1 S ER0'],
  "keisler": ['K AY1 S AH0 L ER0', 'K AY1 S L ER0'],
  "keisling": ['K AY1 S AH0 L IH0 NG', 'K AY1 S L IH0 NG'],
  "keister": ['K IY1 IH0 S T ER0'],
  "keisuke": ['K EY2 S UW1 K IY0'],
  "keitel": ['K AY1 T AH0 L'],
  "keiter": ['K IY1 T ER0'],
  "keith": ['K IY1 TH'],
  "keith's": ['K IY1 TH S'],
  "keithley": ['K IY1 TH L IY0'],
  "keithly": ['K IY1 TH L IY0'],
  "keitt": ['K IY1 T'],
  "keitz": ['K IY1 T S'],
  "keizai": ['K IY1 Z EY0'],
  "keizer": ['K AY1 Z ER0', 'K IY1 Z ER0'],
  "kekst": ['K EH1 K S T'],
  "kelbaugh": ['K EH1 L B AO2'],
  "kelber": ['K EH1 L B ER0'],
  "kelberg": ['K EH1 L B ER0 G'],
  "kelberg's": ['K EH1 L B ER0 G Z'],
  "kelby": ['K EH1 L B IY0'],
  "kelcey": ['K EH1 L S IY0'],
  "kelch": ['K EH1 L CH'],
  "kelchner": ['K EH1 L K N ER0'],
  "kelda": ['K EH1 L D AH0'],
  "kelder": ['K EH1 L D ER0'],
  "keleher": ['K EH1 L IH0 HH ER0'],
  "keleman": ['K IY1 L M AH0 N'],
  "kelemen": ['K IY1 L M EH0 N'],
  "kelker": ['K EH1 L K ER0'],
  "kell": ['K EH1 L'],
  "kellam": ['K EH1 L AH0 M'],
  "kellams": ['K EH1 L AH0 M Z'],
  "kellan": ['K EH1 L AH0 N'],
  "kellar": ['K EH1 L ER0'],
  "kelleher": ['K EH1 L IH0 HH ER0'],
  "kellems": ['K EH1 L IH0 M Z'],
  "kellen": ['K EH1 L AH0 N'],
  "kellenberger": ['K EH1 L AH0 N B ER0 G ER0'],
  "kellenyi": ['K EH2 L EH1 N Y IY0'],
  "keller": ['K EH1 L ER0'],
  "keller's": ['K EH1 L ER0 Z'],
  "kellerman": ['K EH1 L ER0 M AH0 N'],
  "kellermann": ['K EH1 L ER0 M AH0 N'],
  "kellett": ['K EH1 L IH0 T'],
  "kelley": ['K EH1 L IY0', 'OW0 K EH1 L IY0'],
  "kelley's": ['K EH1 L IY0 Z'],
  "kelli": ['K EH1 L IY0'],
  "kelli's": ['K EH1 L IY0 Z'],
  "kellie": ['K EH1 L IY0'],
  "kelliher": ['K EH1 L IH0 HH ER0'],
  "kelling": ['K EH1 L IH0 NG'],
  "kellis": ['K EH1 L IH0 S'],
  "kellison": ['K EH1 L IH0 S AH0 N'],
  "kellman": ['K EH1 L M AH0 N'],
  "kellner": ['K EH1 L N ER0'],
  "kellogg": ['K EH1 L AO0 G'],
  "kellogg's": ['K EH1 L AO0 G Z'],
  "kelloggs": ['K EH1 L AO0 G Z'],
  "kellough": ['K EH1 L AW0'],
  "kellow": ['K EH1 L OW0'],
  "kells": ['K EH1 L Z'],
  "kellum": ['K EH1 L AH0 M'],
  "kellwood": ['K EH1 L W UH2 D'],
  "kelly": ['K EH1 L IY0'],
  "kelly's": ['K EH1 L IY0 Z'],
  "kellyanne": ['K EH1 L IY0 AE1 N'],
  "kellys": ['K EH1 L IY0 Z'],
  "kelm": ['K EH1 L M'],
  "kelman": ['K EH1 L M AH0 N'],
  "kelner": ['K EH1 L N ER0'],
  "kelnhofer": ['K EH1 L N HH AH0 F ER0'],
  "kelp": ['K EH1 L P'],
  "kelps": ['K EH1 L P S'],
  "kelsall": ['K EH1 L S AH0 L'],
  "kelsay": ['K EH1 L S EY0'],
  "kelsch": ['K EH1 L SH'],
  "kelsey": ['K EH1 L S IY0'],
  "kelso": ['K EH1 L S OW0'],
  "kelso's": ['K EH1 L S OW0 Z'],
  "kelsoe": ['K EH1 L S OW0'],
  "kelson": ['K EH1 L S AH0 N'],
  "kelter": ['K EH1 L T ER0'],
  "kelting": ['K EH1 L T IH0 NG'],
  "keltner": ['K EH1 L T N ER0'],
  "kelton": ['K EH1 L T AH0 N'],
  "kelty": ['K EH1 L T IY0'],
  "keltz": ['K EH1 L T S'],
  "kelvan": ['K EH1 L V AH0 N'],
  "kelven": ['K EH1 L V AH0 N'],
  "kelvin": ['K EH1 L V AH0 N', 'K EH1 L V IH0 N'],
  "kelvin's": ['K EH1 L V IH0 N Z'],
  "kem": ['K EH1 M'],
  "kemal": ['K AH0 M AA1 L'],
  "kembel": ['K EH1 M B AH0 L'],
  "kemble": ['K EH1 M B AH0 L'],
  "kemeny": ['K EH1 M IH0 N IY0'],
  "kemerer": ['K EH1 M ER0 ER0'],
  "kemery": ['K EH1 M ER0 IY0'],
  "kemler": ['K EH1 M L ER0'],
  "kemmer": ['K EH1 M ER0'],
  "kemmerer": ['K EH1 M ER0 ER0'],
  "kemmerling": ['K EH1 M ER0 L IH0 NG'],
  "kemmons": ['K EH1 M AH0 N Z'],
  "kemner": ['K EH1 M N ER0'],
  "kemnitz": ['K EH1 M N IH0 T S'],
  "kemp": ['K EH1 M P'],
  "kemp's": ['K EH1 M P S'],
  "kempa": ['K EH1 M P AH0'],
  "kempe": ['K EH1 M P'],
  "kempel": ['K EH1 M P AH0 L'],
  "kempen": ['K EH1 M P AH0 N'],
  "kemper": ['K EH1 M P ER0'],
  "kemper's": ['K EH1 M P ER0 Z'],
  "kempf": ['K EH1 M P F'],
  "kempfer": ['K EH1 M P F ER0'],
  "kempke": ['K EH1 M P K'],
  "kempker": ['K EH1 M P K ER0'],
  "kemple": ['K EH1 M P AH0 L'],
  "kempler": ['K EH1 M P L ER0'],
  "kemplin": ['K EH1 M P L IH0 N'],
  "kempner": ['K EH1 M P N ER0'],
  "kemppainen": ['K EH1 M P AY0 N AH0 N'],
  "kempski": ['K EH1 M P S K IY0'],
  "kempson": ['K EH1 M P S AH0 N'],
  "kempster": ['K EH1 M P S T ER0'],
  "kempthorne": ['K EH1 M P TH AO0 R N'],
  "kempton": ['K EH1 M P T AH0 N'],
  "kemron": ['K EH1 M R AH0 N'],
  "ken": ['K EH1 N'],
  "ken's": ['K EH1 N Z'],
  "kenaf": ['K EH1 N AE0 F'],
  "kenagy": ['K EH1 N AH0 JH IY0'],
  "kenai": ['K IY1 N AY0'],
  "kenan": ['K IY1 N AH0 N'],
  "kendal": ['K EH1 N D AH0 L'],
  "kendall": ['K EH1 N D AH0 L'],
  "kendall's": ['K EH1 N D AH0 L Z'],
  "kendavis": ['K EH1 N D EY1 V IH0 S'],
  "kendell": ['K EH1 N D AH0 L'],
  "kender": ['K EH1 N D ER0'],
  "kendig": ['K EH1 N D IH0 G'],
  "kendle": ['K EH1 N D AH0 L'],
  "kendra": ['K EH1 N D R AH0'],
  "kendrick": ['K EH1 N D R IH0 K'],
  "kendricks": ['K EH1 N D R IH0 K S'],
  "kendzierski": ['K IH0 N JH IH1 R S K IY0'],
  "kendzior": ['K IH0 N JH IY1 ER0'],
  "kenealy": ['K EH1 N IY0 AH0 L IY0'],
  "keneer": ['K AH0 N IY1 R'],
  "kenefick": ['K EH1 N IH0 F IH0 K'],
  "kenerson": ['K EH1 N ER0 S AH0 N'],
  "kenesset": ['K EH0 N EH1 S EH0 T', 'K N EH1 S EH0 T'],
  "kenetech": ['K EH1 N IH0 T EH0 K'],
  "kenfield": ['K EH1 N F IY2 L D'],
  "kenichi": ['K EH2 N IY1 CH IY0'],
  "kenilworth": ['K EH1 N AH0 L W ER2 TH'],
  "kenison": ['K EH1 N IH0 S AH0 N'],
  "keniston": ['K EH1 N IH0 S T AA0 N'],
  "kenji": ['K EH1 N JH IY0'],
  "kenkel": ['K EH1 NG K AH0 L'],
  "kenley": ['K EH1 N L IY0'],
  "kenmare": ['K EH2 N M EH1 R'],
  "kenmore": ['K EH1 N M AO2 R'],
  "kenn": ['K EH1 N'],
  "kenna": ['K EH1 N AH0'],
  "kennamer": ['K EH1 N AH0 M ER0'],
  "kennametal": ['K EH1 N AH0 M EH2 T AH0 L'],
  "kennan": ['K EH1 N AH0 N'],
  "kennard": ['K EH1 N ER0 D'],
  "kenneally": ['K EH1 N AH0 L IY0'],
  "kennebeck": ['K EH1 N B EH0 K'],
  "kennebrew": ['K EH1 N IH0 B R UW0'],
  "kennebunkport": ['K EH2 N AH0 B AH1 NG K P AO2 R T'],
  "kennecott": ['K EH1 N AH0 K AA2 T'],
  "kennedy": ['K EH1 N AH0 D IY0'],
  "kennedy's": ['K EH1 N AH0 D IY0 Z'],
  "kennedys": ['K EH1 N AH0 D IY0 Z'],
  "kennedys'": ['K EH1 N AH0 D IY0 Z'],
  "kennel": ['K EH1 N AH0 L'],
  "kennell": ['K EH1 N AH0 L'],
  "kennelly": ['K EH1 N AH0 L IY0'],
  "kennels": ['K EH1 N AH0 L Z'],
  "kennemer": ['K EH1 N IY0 M ER0'],
  "kennemore": ['K EH1 N M AO0 R'],
  "kenner": ['K EH1 N ER0'],
  "kenner's": ['K EH1 N ER0 Z'],
  "kennerly": ['K EH1 N ER0 L IY0'],
  "kennerson": ['K EH1 N ER0 S AH0 N'],
  "kennesaw": ['K EH1 N AH0 S AO2'],
  "kenneth": ['K EH1 N IH0 TH'],
  "kennett": ['K EH1 N AH0 T'],
  "kenney": ['K EH1 N IY0'],
  "kenning": ['K EH1 N IH0 NG'],
  "kennington": ['K EH1 N IH0 NG T AH0 N'],
  "kennison": ['K EH1 N IH0 S AH0 N'],
  "kennon": ['K EH1 N AH0 N'],
  "kenny": ['K EH1 N IY2'],
  "kenny's": ['K EH1 N IY2 Z'],
  "keno": ['K IY1 N OW0'],
  "kenobi": ['K AH0 N OW1 B IY2'],
  "kenosha": ['K IH0 N OW1 SH AH0'],
  "kenoyer": ['K EH1 N OY0 ER0'],
  "kenrick": ['K EH1 N R IH0 K'],
  "kensinger": ['K EH1 N S IH0 N JH ER0'],
  "kensington": ['K EH1 N Z IH0 NG T AH0 N'],
  "kensler": ['K EH1 N S AH0 L ER0', 'K EH1 N S L ER0'],
  "kent": ['K EH1 N T'],
  "kent's": ['K EH1 N T S'],
  "kente": ['K EH1 N T EY0'],
  "kentner": ['K EH1 N T N ER0'],
  "kenton": ['K EH1 N T AH0 N'],
  "kentuckian": ['K EH2 N T AH1 K IY0 AH0 N'],
  "kentuckians": ['K EH2 N T AH1 K IY0 AH0 N Z'],
  "kentucky": ['K AH0 N T AH1 K IY0'],
  "kentucky's": ['K AH0 N T AH1 K IY0 Z'],
  "kenward": ['K EH1 N W ER0 D'],
  "kenway": ['K EH1 N W EY2'],
  "kenwood": ['K EH1 N W UH2 D'],
  "kenworth": ['K EH1 N W ER2 TH'],
  "kenworthy": ['K EH1 N W ER2 DH IY0'],
  "kenya": ['K EH1 N Y AH0', 'K IY1 N Y AH0'],
  "kenya's": ['K EH1 N Y AH0 Z', 'K IY1 N Y AH0 Z'],
  "kenyan": ['K EH1 N Y AH0 N', 'K IY1 N Y AH0 N'],
  "kenyans": ['K EH1 N Y AH0 N Z', 'K IY1 N Y AH0 N Z'],
  "kenyen": ['K EH1 N Y AH0 N'],
  "kenyon": ['K EH1 N Y AH0 N'],
  "kenzie": ['K EH1 N Z IY0'],
  "kenzo": ['K EH1 N Z OW0'],
  "keo": ['K IY1 OW0'],
  "keogh": ['K IY1 OW0', 'K IY1 AW0 G'],
  "keohane": ['K IY1 AH0 HH EY2 N'],
  "keokuk": ['K IY1 OW0 K AH0 K', 'K IY1 OW0 K UH0 K'],
  "keough": ['K IY1 OW0', 'K IY1 AW0 G'],
  "keown": ['K IY1 OW0 N'],
  "kepco": ['K EH1 P K OW0'],
  "kephart": ['K EH1 F AA0 R T'],
  "kepler": ['K EH1 P L ER0'],
  "kepley": ['K EH1 P L IY0'],
  "keplinger": ['K EH1 P AH0 L IH0 NG ER0', 'K EH1 P L IH0 NG ER0'],
  "kepner": ['K EH1 P N ER0'],
  "kepp": ['K EH1 P'],
  "keppel": ['K EH1 P AH0 L'],
  "kepple": ['K EH1 P AH0 L'],
  "keppler": ['K EH1 P L ER0'],
  "kept": ['K EH1 P T', 'K AE1 P T'],
  "ker": ['K ER1'],
  "kera": ['K EH1 R AH0'],
  "kerala": ['K EH2 R AA1 L AH0'],
  "keranen": ['K EH1 R AH0 N AH0 N'],
  "keratin": ['K EH1 R AH0 T AH0 N', 'K EH1 R AH0 T IH0 N'],
  "keratoconus": ['K EH2 R AH0 T AH0 K OW1 N AH0 S'],
  "keratotomy": ['K EH2 R AH0 T AO1 T AH0 M IY0'],
  "kerb": ['K ER1 B'],
  "kerbel": ['K ER1 B AH0 L'],
  "kerber": ['K ER1 B ER0'],
  "kerbow": ['K ER1 B OW0'],
  "kerbs": ['K ER1 B Z'],
  "kerby": ['K ER1 B IY0'],
  "kerce": ['K ER1 S'],
  "kercher": ['K ER1 K ER0'],
  "kercheval": ['K ER1 CH IH0 V AH0 L'],
  "kerchief": ['K ER1 CH AH0 F'],
  "kerchiefs": ['K ER1 CH AH0 F S'],
  "kerchner": ['K ER1 K N ER0'],
  "kerekes": ['K EH1 R IH0 K S'],
  "keren": ['K EH1 R EH2 N'],
  "kerestes": ['K EH1 R IH0 S T S'],
  "keresztes": ['K EH1 R AH0 S T IY0 Z'],
  "kerfoot": ['K ER1 F UH0 T'],
  "kerfuffle": ['K ER0 F AH1 F AH0 L'],
  "kerien": ['K EH1 R IY0 AH0 N'],
  "kerin": ['K EH1 R IH0 N'],
  "kerins": ['K EH1 R IH0 N Z'],
  "kerker": ['K ER1 K ER0'],
  "kerkhoff": ['K ER1 K HH AO0 F'],
  "kerkman": ['K ER1 K M AH0 N'],
  "kerkorian": ['K ER0 K AO1 R IY0 AH0 N'],
  "kerkorian's": ['K ER0 K AO1 R IY0 AH0 N Z'],
  "kerl": ['K ER1 L'],
  "kerley": ['K ER1 L IY0'],
  "kerlin": ['K ER1 L IH0 N'],
  "kerman": ['K ER1 M AH0 N'],
  "kermit": ['K ER1 M IH0 T'],
  "kern": ['K ER1 N'],
  "kern's": ['K ER1 N Z'],
  "kernaghan": ['K ER0 N AE1 G AH0 N'],
  "kernan": ['K ER1 N AH0 N'],
  "kernel": ['K ER1 N AH0 L'],
  "kernels": ['K ER1 N AH0 L Z'],
  "kernen": ['K ER1 N AH0 N'],
  "kerner": ['K ER1 N ER0'],
  "kernes": ['K ER1 N Z'],
  "kerney": ['K ER1 N IY0'],
  "kernigan": ['K ER1 N AH0 G AH0 N'],
  "kernite": ['K ER1 N AY0 T'],
  "kernodle": ['K ER1 N OW0 D AH0 L'],
  "kerns": ['K ER1 N Z'],
  "kerosene": ['K EH1 R AH0 S IY2 N'],
  "kerouac": ['K EH1 R UW0 AE0 K'],
  "kerpedjiev": ['K ER0 P EH1 JH IY0 EH2 V'],
  "kerper": ['K ER1 P ER0'],
  "kerr": ['K ER1'],
  "kerrey": ['K EH1 R IY0'],
  "kerrey's": ['K EH1 R IY0 Z'],
  "kerri": ['K EH1 R IY0'],
  "kerrick": ['K EH1 R IH0 K'],
  "kerridge": ['K EH1 R IH0 JH'],
  "kerrigan": ['K EH1 R IH0 G AH0 N'],
  "kerrigan's": ['K EH1 R IH0 G AH0 N Z'],
  "kerrville": ['K ER1 V IH2 L'],
  "kerry": ['K EH1 R IY0'],
  "kerry's": ['K EH1 R IY0 Z'],
  "kersch": ['K ER1 SH'],
  "kerscher": ['K ER1 SH ER0'],
  "kerschner": ['K ER1 SH N ER0'],
  "kersee": ['K ER1 S IY0'],
  "kersey": ['K ER1 S IY0'],
  "kersh": ['K ER1 SH'],
  "kershaw": ['K ER1 SH AA2'],
  "kershner": ['K ER1 SH N ER0'],
  "kerst": ['K ER1 S T'],
  "kerstein": ['K ER1 S T AY0 N', 'K ER1 S T IY0 N'],
  "kersten": ['K ER1 S T AH0 N'],
  "kerstetter": ['K ER1 S T IH0 T ER0'],
  "kersting": ['K ER1 S T IH0 NG'],
  "kertesz": ['K ER1 T IH0 SH'],
  "kerth": ['K ER1 TH'],
  "kertz": ['K ER1 T S'],
  "kervin": ['K ER1 V IH0 N'],
  "kervorkian": ['K ER0 V AO1 R K IY0 AH0 N', 'K ER0 V AO1 R K Y AH0 N'],
  "kerwen": ['K ER1 W AH0 N'],
  "kerwin": ['K ER1 W IH0 N'],
  "kerwood": ['K ER1 W UH0 D'],
  "kerzner": ['K ER1 Z N ER0'],
  "kesel": ['K EH1 S AH0 L'],
  "keshishian": ['K IH0 SH IH1 SH IY0 AH0 N'],
  "kesinger": ['K EH1 S IH0 NG ER0'],
  "keske": ['K EH1 S K'],
  "keslar": ['K EH1 S L ER0'],
  "kesler": ['K EH1 S AH0 L ER0', 'K EH1 S L ER0'],
  "kesling": ['K EH1 S AH0 L IH0 NG', 'K EH1 S L IH0 NG'],
  "kesner": ['K EH1 S N ER0'],
  "kess": ['K EH1 S'],
  "kessel": ['K EH1 S AH0 L'],
  "kessell": ['K EH1 S AH0 L'],
  "kesselman": ['K EH1 S AH0 L M AH0 N'],
  "kesselring": ['K EH1 S IH0 L R IH0 NG'],
  "kessen": ['K EH1 S AH0 N'],
  "kessenich": ['K EH1 S IH0 N IH0 K'],
  "kessinger": ['K EH1 S IH0 NG ER0'],
  "kessle": ['K EH1 S AH0 L'],
  "kessler": ['K EH1 S L ER0'],
  "kessler's": ['K EH1 S L ER0 Z'],
  "kessner": ['K EH1 S N ER0'],
  "kestel": ['K EH1 S T AH0 L'],
  "kesten": ['K EH1 S AH0 N'],
  "kestenbaum": ['K EH1 S AH0 N B AW0 M'],
  "kester": ['K EH1 S T ER0'],
  "kesterson": ['K EH1 S T ER0 S AH0 N'],
  "kesting": ['K EH1 S T IH0 NG'],
  "kestler": ['K EH1 S T L ER0'],
  "kestler's": ['K EH1 S T L ER0 Z'],
  "kestner": ['K EH1 S T N ER0'],
  "kestrels": ['K EH1 S T R AH0 L Z'],
  "keswick": ['K EH1 S W IH2 K'],
  "keta": ['K EH1 T AH0'],
  "ketch": ['K EH1 CH'],
  "ketcham": ['K EH1 CH AH0 M'],
  "ketchem": ['K EH1 CH IH0 M'],
  "ketchen": ['K EH1 CH AH0 N'],
  "ketcher": ['K EH1 CH ER0'],
  "ketcherside": ['K EH1 CH ER0 S AY2 D'],
  "ketchie": ['K EH1 CH IY0'],
  "ketchikan": ['K EH1 CH IH0 K AH0 N'],
  "ketchledge": ['K EH1 CH L EH2 JH'],
  "ketchum": ['K EH1 CH AH0 M'],
  "ketchup": ['K EH1 CH AH0 P'],
  "ketelhut": ['K EH1 T IH0 L HH AH0 T'],
  "ketelsen": ['K EH1 T IH0 L S AH0 N'],
  "ketema": ['K EH2 T EY1 M AH0'],
  "keteyian": ['K AH0 T EY1 AH0 N'],
  "keteyian's": ['K AH0 T EY1 AH0 N Z'],
  "ketler": ['K EH1 T L ER0'],
  "ketner": ['K EH1 T N ER0'],
  "ketney": ['K EH1 T N IY0'],
  "keto": ['K EY1 T OW0'],
  "ketola": ['K EH1 T AH0 L AH0'],
  "ketone": ['K IY1 T OW0 N'],
  "ketou": ['K AH0 T UW1'],
  "ketron": ['K EH1 T R AH0 N'],
  "kett": ['K EH1 T'],
  "kettell": ['K EH1 T AH0 L'],
  "ketter": ['K EH1 T ER0'],
  "ketterer": ['K EH1 T ER0 ER0'],
  "kettering": ['K EH1 T ER0 IH0 NG'],
  "ketterling": ['K EH1 T ER0 L IH0 NG'],
  "ketterman": ['K EH1 T ER0 M AH0 N'],
  "ketti": ['K EH1 T IY0'],
  "kettle": ['K EH1 T AH0 L'],
  "kettler": ['K EH1 T AH0 L ER0', 'K EH1 T L ER0'],
  "kettles": ['K EH1 T AH0 L Z'],
  "kettlewell": ['K EH1 T AH0 L W EH2 L'],
  "kettner": ['K EH1 T N ER0'],
  "ketura": ['K EH1 T UH0 R AH0'],
  "ketz": ['K EH1 T S'],
  "ketzel": ['K EH1 T S AH0 L'],
  "ketzel's": ['K EH1 T S AH0 L Z'],
  "keune": ['K Y UW1 N'],
  "kevan": ['K EH1 V AH0 N'],
  "keven": ['K IY1 V AH0 N'],
  "kever": ['K EH1 V ER0'],
  "kevex": ['K EH1 V AH0 K S'],
  "keville": ['K IY1 V IH0 L'],
  "kevin": ['K EH1 V IH0 N'],
  "kevin's": ['K EH1 V IH0 N Z'],
  "kevins": ['K EH1 V IH0 N Z'],
  "kevlar": ['K EH1 V L ER0'],
  "kevlin": ['K EH1 V L IH0 N'],
  "kevorkian": ['K AH0 V AO1 R K IY0 AH0 N'],
  "kevorkian's": ['K AH0 V AO1 R K IY0 AH0 N Z'],
  "kew": ['K Y UW1'],
  "kewaunee": ['K Y UW0 AO1 N IY0'],
  "kewley": ['K Y UW1 L IY0'],
  "key": ['K IY1'],
  "key's": ['K IY1 Z'],
  "key-largo": ['K IY2 L AA1 R G OW0'],
  "key-west": ['K IY2 W EH1 S T'],
  "keyboard": ['K IY1 B AO2 R D'],
  "keyboarding": ['K IY1 B AO2 R D IH0 NG'],
  "keyboards": ['K IY1 B AO2 R D Z'],
  "keycorp": ['K IY1 K AO0 R P'],
  "keycorp's": ['K IY1 K AO0 R P S'],
  "keye": ['K AY1'],
  "keyed": ['K IY1 D'],
  "keyes": ['K IY1 Z'],
  "keyes'": ['K IY1 Z'],
  "keyhole": ['K IY1 HH OW2 L'],
  "keyholes": ['K IY1 HH OW2 L Z'],
  "keying": ['K IY1 IH0 NG'],
  "keyless": ['K IY1 L AH0 S'],
  "keylon": ['K EY1 L AH0 N'],
  "keynes": ['K EY1 N Z'],
  "keynesian": ['K EY1 N Z IY0 AH0 N'],
  "keynesians": ['K EY1 N Z IY0 AH0 N Z'],
  "keynote": ['K IY1 N OW2 T'],
  "keynotes": ['K IY1 N OW2 T S'],
  "keypad": ['K IY1 P AE2 D'],
  "keypad's": ['K IY1 P AE2 D Z'],
  "keypads": ['K IY1 P AE2 D Z'],
  "keys": ['K IY1 Z'],
  "keyser": ['K AY1 Z ER0'],
  "keysor": ['K IY1 S ER0'],
  "keystone": ['K IY1 S T OW2 N'],
  "keystone's": ['K IY1 S T OW2 N Z'],
  "keystones": ['K IY1 S T OW2 N Z'],
  "keystroke": ['K IY1 S T R OW2 K'],
  "keystrokes": ['K IY1 S T R OW2 K S'],
  "keyton": ['K IY1 T AH0 N'],
  "keyword": ['K IY1 W ER2 D'],
  "keywords": ['K IY1 W ER2 D Z'],
  "keyworth": ['K IY1 W ER2 TH'],
  "keziah": ['K AH0 Z IY1 AH0'],
  "kganakga": ['K AH0 G AH0 N AE1 G AH0'],
  "kgb": ['K EY2 JH IY2 B IY1'],
  "kgori": ['K AH0 G AO1 R IY0'],
  "khabarovsk": ['K AE2 B ER0 AA1 F S K'],
  "khachigian": ['K AH0 SH IY1 G IY2 AH0 N'],
  "khad": ['K AE1 D'],
  "khadafi": ['K AH0 D AA1 F IY0'],
  "khaki": ['K AA1 K IY0', 'K AE1 K IY0'],
  "khakis": ['K AE1 K IY0 Z'],
  "khalaf": ['K AE1 L AH0 F'],
  "khaled": ['HH AH1 L AH0 D', 'K AH1 L AH0 D'],
  "khalid": ['K AA1 L IH0 D', 'HH AA1 L IH0 D'],
  "khalid's": ['K AA1 L IH0 D Z', 'HH AA1 L IH0 D Z'],
  "khalifa": ['K AH0 L IY1 F AH0'],
  "khalil": ['K AE1 L AH0 L'],
  "khalsa": ['K AA1 L S AH0'],
  "khamenei": ['K AH0 M EY1 N IY2'],
  "khan": ['K AA1 N'],
  "khan's": ['K AA1 N Z'],
  "khanate": ['K AA1 N EY0 T'],
  "khanna": ['K AE1 N AH0'],
  "kharg": ['K AA1 R G'],
  "khartoum": ['K AA2 R T UW1 M'],
  "khasbulatov": ['K AA2 S B AH0 L AA1 T AA0 F'],
  "khasbulatov's": ['K AA2 S B AH0 L AA1 T AA0 F S'],
  "khashoggi": ['K AH0 SH AA1 JH IY0'],
  "khat": ['K AA1 T'],
  "khatami": ['K AH0 T AA1 M IY0'],
  "khatami's": ['K AH0 T AA1 M IY0 Z'],
  "khazakhstan": ['K AH0 Z AE1 K S T AA0 N'],
  "khe": ['K EY1'],
  "kheel": ['K IY1 L'],
  "khem": ['K EH1 M'],
  "khlebnikov": ['K L EH1 B N IH0 K AA2 V'],
  "khlebnikov's": ['K L EH1 B N IH0 K AA2 V Z'],
  "khmer": ['K M EH1 R'],
  "khomeini": ['K OW0 M EY1 N IY0', 'HH OW0 M EY1 N IY0'],
  "khomeini's": ['HH OW0 M EY1 N IY0 Z', 'K OW0 M EY1 N IY0 Z'],
  "khoo": ['K UW1'],
  "khosla": ['K AO1 S L AH0'],
  "khost": ['K OW1 S T'],
  "khouri": ['K AW1 R IY0'],
  "khoury": ['K AW1 R IY0'],
  "khrushchev": ['K R UW1 S CH EH2 V', 'K R UW1 S CH AO2 F'],
  "khrushchev's": ['K R UW1 S CH EH2 V Z', 'K R UW1 S CH AO2 F S'],
  "khufu": ['K UW1 F UW2'],
  "khumalo": ['K Y UW0 M AA1 L OW0'],
  "khuu": ['K UW1'],
  "ki": ['K IY1'],
  "kia": ['K IY1 ER0'],
  "kiam": ['K IY1 AH0 M'],
  "kian": ['K IY1 AH0 N'],
  "kiawah": ['K AY1 AH0 W AH0', 'K IY1 AH0 W AH2'],
  "kibbe": ['K IH1 B'],
  "kibbee": ['K IH1 B IY2'],
  "kibbel": ['K IH1 B AH0 L'],
  "kibbey": ['K IH1 B IY0'],
  "kibble": ['K IH1 B AH0 L'],
  "kibbutz": ['K IH0 B UH1 T S'],
  "kibbutzim": ['K IH2 B UH0 T S IH1 M'],
  "kibbutznik": ['K IH0 B UH1 T S N IH0 K'],
  "kibbutzniks": ['K IH0 B UH1 T S N IH0 K S'],
  "kibby": ['K IH1 B IY0'],
  "kibeho": ['K IH1 B AH0 HH OW0'],
  "kibell": ['K IH1 B AH0 L'],
  "kibler": ['K IH1 B L ER0'],
  "kibodeaux": ['K IH1 B AH0 D OW0'],
  "kibumba": ['K IH0 B UW1 M B AH0'],
  "kichline": ['K IH1 K L AY2 N'],
  "kick": ['K IH1 K'],
  "kick-ass": ['K IH1 K AE2 S'],
  "kickback": ['K IH1 K B AE2 K'],
  "kickbacks": ['K IH1 K B AE2 K S'],
  "kicked": ['K IH1 K T'],
  "kicker": ['K IH1 K ER0'],
  "kickers": ['K IH1 K ER0 Z'],
  "kicking": ['K IH1 K IH0 NG'],
  "kicklighter": ['K IH1 K L AY2 T ER0'],
  "kickoff": ['K IH1 K AO2 F'],
  "kicks": ['K IH1 K S'],
  "kickstarter": ['K IH1 K S T AA1 R T ER0'],
  "kid": ['K IH1 D'],
  "kid's": ['K IH1 D Z'],
  "kida": ['K IY1 D AH0'],
  "kidd": ['K IH1 D'],
  "kidde": ['K IH1 D'],
  "kidded": ['K IH1 D IH0 D'],
  "kidder": ['K IH1 D ER0'],
  "kidder's": ['K IH1 D ER0 Z'],
  "kiddie": ['K IH1 D IY0'],
  "kiddies": ['K IH1 D IY0 Z'],
  "kidding": ['K IH1 D IH0 NG'],
  "kiddingly": ['K IH1 D IH0 NG L IY0'],
  "kiddle": ['K IH1 D AH0 L'],
  "kiddy": ['K IH1 D IY0'],
  "kidman": ['K IH1 D M AH0 N'],
  "kidnap": ['K IH1 D N AE2 P'],
  "kidnaped": ['K IH1 D N AE2 P T'],
  "kidnaping": ['K IH1 D N AE2 P IH0 NG'],
  "kidnapped": ['K IH1 D N AE2 P T'],
  "kidnapper": ['K IH1 D N AE2 P ER0'],
  "kidnappers": ['K IH1 D N AE2 P ER0 Z'],
  "kidnapping": ['K IH1 D N AE2 P IH0 NG'],
  "kidnappings": ['K IH1 D N AE2 P IH0 NG Z'],
  "kidnaps": ['K IH1 D N AE2 P S'],
  "kidney": ['K IH1 D N IY0'],
  "kidneys": ['K IH1 D N IY0 Z'],
  "kido": ['K IY1 D OW0'],
  "kids": ['K IH1 D Z'],
  "kids'": ['K IH1 D Z'],
  "kidstock": ['K IH1 D S T AA2 K'],
  "kidwa": ['K IH1 D W AH0'],
  "kidwell": ['K IH1 D W EH2 L'],
  "kiechl": ['K IY1 CH AH0 L', 'K AY1 CH AH0 L'],
  "kiecker": ['K IY1 K ER0'],
  "kiedrowski": ['K IY0 D R AO1 F S K IY0'],
  "kief": ['K IY1 F'],
  "kiefer": ['K IY1 F ER0'],
  "kieffer": ['K IY1 F ER0'],
  "kieft": ['K IY1 F T'],
  "kiehl": ['K IY1 L'],
  "kiehn": ['K IY1 N'],
  "kiehne": ['K IY1 N'],
  "kiel": ['K IY1 L'],
  "kielar": ['K IY1 L ER0'],
  "kielb": ['K IY1 L B'],
  "kielbasa": ['K IY0 L B AA1 S AH0'],
  "kieler": ['K IY1 L ER0'],
  "kielley": ['K IY1 L IY0'],
  "kielman": ['K IY1 L M AH0 N'],
  "kielty": ['K IY1 L T IY0'],
  "kiely": ['K IY1 L IY0'],
  "kienan": ['K IY1 N AH0 N'],
  "kienast": ['K IY1 N AH0 S T'],
  "kiene": ['K IY1 N'],
  "kiener": ['K IY1 N ER0'],
  "kieninger": ['K IY1 N IH0 NG ER0'],
  "kienitz": ['K IY1 N IH0 T S'],
  "kienle": ['K IY1 N AH0 L'],
  "kientz": ['K IY1 N T S'],
  "kienzle": ['K IY1 N Z AH0 L'],
  "kieper": ['K IY1 P ER0'],
  "kier": ['K IH1 R'],
  "kieran": ['K IY1 R AH0 N'],
  "kiernan": ['K IH1 R N AH0 N'],
  "kierscht": ['K IH1 R SH T'],
  "kierstead": ['K IH1 R S T EH0 D'],
  "kierulff": ['K IY1 R AH0 L F'],
  "kies": ['K AY1 S'],
  "kieschnick": ['K IY1 SH N IH0 K'],
  "kiesel": ['K IY1 S AH0 L'],
  "kieselmann": ['K IY1 Z AH0 L M AH0 N'],
  "kieser": ['K IY1 S ER0'],
  "kiesewetter": ['K IY1 S UW0 IH0 T ER0'],
  "kiesler": ['K IY1 Z L ER0'],
  "kiesling": ['K IY1 Z L IH0 NG'],
  "kiesow": ['K IY1 S OW0'],
  "kiess": ['K IY1 S'],
  "kiessling": ['K IY1 S L IH0 NG'],
  "kiester": ['K AY1 IH0 S T ER0'],
  "kietzman": ['K IY1 T S M AH0 N'],
  "kiev": ['K IY2 EH1 V'],
  "kiev's": ['K IY2 EH1 V Z'],
  "kievan": ['K IY1 V AH0 N'],
  "kievit": ['K IY1 V IH0 T'],
  "kiewit": ['K IY1 W IH0 T'],
  "kifer": ['K AY1 F ER0'],
  "kiff": ['K IH1 F'],
  "kigale": ['K IY0 G AA1 L IY0'],
  "kigale's": ['K IY0 G AA1 L IY0 Z'],
  "kigali": ['K IY0 G AA1 L IY0'],
  "kigali's": ['K IY0 G AA1 L IY0 Z'],
  "kiger": ['K AY1 G ER0'],
  "kiggins": ['K IH1 G IH0 N Z'],
  "kight": ['K AY1 T'],
  "kightlinger": ['K AY1 T AH0 L IH0 NG ER0', 'K AY1 T L IH0 NG ER0'],
  "kihn": ['K IH1 N'],
  "kiichi": ['K IY0 IY1 CH IY0', 'K IY1 CH IY0'],
  "kijowski": ['K IH0 Y AO1 F S K IY0'],
  "kika": ['K IH1 K AH0'],
  "kikatte": ['K IH1 K AE0 T'],
  "kiker": ['K AY1 K ER0'],
  "kiki": ['K IY1 K IY0'],
  "kikkoman": ['K IY1 K OW0 M AA0 N'],
  "kikta": ['K IH1 K T AH0'],
  "kikuchi": ['K IY0 K UW1 CH IY0'],
  "kikumura": ['K IY2 K UW2 M UW1 R AH0'],
  "kikwit": ['K IH1 K W IH0 T'],
  "kila": ['K IH1 L AH0'],
  "kilauea": ['K IY2 L AW2 EY1 AH0'],
  "kilbane": ['K IH1 L B AH0 N'],
  "kilborn": ['K IH1 L B ER0 N'],
  "kilborne": ['K IH1 L B AO2 R N'],
  "kilbourn": ['K IH1 L B ER0 N'],
  "kilbourne": ['K IH1 L B ER0 N'],
  "kilbride": ['K IH1 L B R AY2 D'],
  "kilburg": ['K IH1 L B ER0 G'],
  "kilburn": ['K IH1 L B ER0 N'],
  "kilbury": ['K IH1 L B EH2 R IY0'],
  "kilby": ['K IH1 L B IY0'],
  "kilby's": ['K IH1 L B IY0 Z'],
  "kilcoin": ['K IH1 L K OY0 N'],
  "kilcoyne": ['K IH1 L K OY0 N'],
  "kilcrease": ['K IH0 L K R IY1 S', 'K IH1 L K R IY0 S'],
  "kilcullen": ['K IH0 L K AH1 L AH0 N'],
  "kilday": ['K IH1 L D EY2'],
  "kildee": ['K IH1 L D IY0'],
  "kildow": ['K IH1 L D OW0'],
  "kilduff": ['K IH1 L D AH0 F'],
  "kile": ['K AY1 L'],
  "kilen": ['K IH1 L AH0 N'],
  "kiley": ['K AY1 L IY0'],
  "kilgo": ['K IH1 L G OW0'],
  "kilgore": ['K IH1 L G AO0 R'],
  "kilgour": ['K IH1 L G ER0'],
  "kilgus": ['K IH1 L G AH0 S'],
  "kilian": ['K IH1 L IY0 AH0 N'],
  "kilimanjaro": ['K IH0 L IY2 M AH0 N JH AA1 R OW0', 'K IH2 L AH0 M AH0 N JH AA1 R OW0'],
  "kilkenny": ['K IH0 L K EH1 N IY0'],
  "kilkenny's": ['K IH0 L K EH1 N IY0 Z'],
  "kilker": ['K IH1 L K ER0'],
  "kill": ['K IH1 L'],
  "killam": ['K IH1 L AH0 M'],
  "kille": ['K IH1 L'],
  "killeagh": ['K IH0 L IY1 G'],
  "killebrew": ['K IH1 L IH0 B R UW0'],
  "killed": ['K IH1 L D'],
  "killeen": ['K IH0 L IY1 N'],
  "killen": ['K IH1 L AH0 N'],
  "killer": ['K IH1 L ER0'],
  "killer's": ['K IH1 L ER0 Z'],
  "killers": ['K IH1 L ER0 Z'],
  "killey": ['K IH1 L IY0'],
  "killgore": ['K IH1 L G AO2 R'],
  "killian": ['K IH1 L Y AH0 N'],
  "killifish": ['K IH1 L IH0 F IH0 SH'],
  "killilea": ['K IH0 L IH0 L IY1 AH0'],
  "killin": ['K IH1 L IH0 N'],
  "killing": ['K IH1 L IH0 NG'],
  "killingbeck": ['K IH1 L IH0 NG B EH2 K'],
  "killinger": ['K IH1 L IH0 NG ER0'],
  "killings": ['K IH1 L IH0 NG Z'],
  "killingsworth": ['K IH1 L IH0 NG Z W ER2 TH'],
  "killington": ['K IH1 L IH0 NG T AH0 N'],
  "killion": ['K IH1 L Y AH0 N'],
  "killjoy": ['K IH1 L JH OY0'],
  "killman": ['K IH1 L M AH0 N'],
  "killman's": ['K IH1 L M AH0 N Z'],
  "killmer": ['K IH1 L M ER0'],
  "killmon": ['K IH1 L M AH0 N'],
  "killoran": ['K IH1 L ER0 AH0 N'],
  "killory": ['K IH1 L ER0 IY0'],
  "killough": ['K IH1 L AW0'],
  "killpack": ['K IH1 L P AE2 K'],
  "kills": ['K IH1 L Z'],
  "kilman": ['K IH1 L M AH0 N'],
  "kilmarnock": ['K IH0 L M AA1 R N AA2 K'],
  "kilmartin": ['K IH0 L M AA1 R T IH0 N'],
  "kilmer": ['K IH1 L M ER0'],
  "kiln": ['K IH1 L N'],
  "kilns": ['K IH1 L N Z'],
  "kilo": ['K IH1 L OW2'],
  "kilobit": ['K IH1 L AH0 B IH0 T'],
  "kilobyte": ['K IH1 L OW0 B AY2 T'],
  "kilobytes": ['K IH1 L OW0 B AY2 T S'],
  "kilogram": ['K IH1 L AH0 G R AE2 M'],
  "kilograms": ['K IH1 L AH0 G R AE2 M Z'],
  "kilometer": ['K AH0 L AA1 M AH0 T ER0', 'K IH1 L AH0 M IY2 T ER0'],
  "kilometers": ['K AH0 L AA1 M AH2 T ER0 Z', 'K IH1 L AH0 M IY2 T ER0 Z'],
  "kilometre": ['K IH2 L AO1 M AH2 T ER0', 'K IH2 L AH0 M IY1 T ER0'],
  "kilometres": ['K IH2 L AO1 M AH2 T ER0 Z', 'K IH2 L AH0 M IY1 T ER0 Z'],
  "kilos": ['K IY1 L OW2 Z'],
  "kilowatt": ['K IH1 L AH0 W AA2 T'],
  "kilowatts": ['K IH1 L AH0 W AA2 T S'],
  "kilpatrick": ['K IH2 L P AE1 T R IH0 K'],
  "kilroy": ['K IH1 L R OY2'],
  "kilt": ['K IH1 L T'],
  "kilter": ['K IH1 L T ER0'],
  "kilton": ['K IH1 L T AH0 N'],
  "kilts": ['K IH1 L T S'],
  "kilty": ['K IH1 L T IY0'],
  "kilzer": ['K IH1 L Z ER0'],
  "kim": ['K IH1 M'],
  "kim's": ['K IH1 M Z'],
  "kimba": ['K IH1 M B AH0'],
  "kimball": ['K IH1 M B AH0 L'],
  "kimball's": ['K IH1 M B AH0 L Z'],
  "kimbel": ['K IH1 M B AH0 L'],
  "kimbell": ['K IH1 M B EH0 L'],
  "kimber": ['K IH1 M B ER0'],
  "kimberley": ['K IH1 M B ER0 L IY0'],
  "kimberlin": ['K IH1 M B ER0 L IH0 N'],
  "kimberling": ['K IH1 M B ER0 L IH0 NG'],
  "kimberly": ['K IH1 M B ER0 L IY0'],
  "kimberly's": ['K IH1 M B ER0 L IY0 Z'],
  "kimble": ['K IH1 M B AH0 L'],
  "kimbler": ['K IH1 M B L ER0'],
  "kimbley": ['K IH1 M B L IY0'],
  "kimbrel": ['K IH1 M B R AH0 L'],
  "kimbrell": ['K IH1 M B R AH0 L'],
  "kimbriel": ['K IH1 M B R IY0 AH0 L'],
  "kimbro": ['K IH1 M B R OW0'],
  "kimbrough": ['K IH1 M B R AW0'],
  "kimche": ['K IH1 M CH IY0'],
  "kimchee": ['K IH1 M CH IY0'],
  "kimchi": ['K IH1 M CH IY0'],
  "kimco": ['K IH1 M K OW0'],
  "kime": ['K AY1 M'],
  "kimel": ['K IH1 M AH0 L'],
  "kimery": ['K IH1 M ER0 IY0'],
  "kimes": ['K AY1 M Z'],
  "kimler": ['K IH1 M AH0 L ER0', 'K IH1 M L ER0'],
  "kimm": ['K IH1 M'],
  "kimmel": ['K IH1 M AH0 L'],
  "kimmell": ['K IH1 M AH0 L'],
  "kimmelman": ['K IH1 M AH0 L M AH0 N'],
  "kimmer": ['K IH1 M ER0'],
  "kimmerle": ['K IH1 M ER0 AH0 L'],
  "kimmet": ['K IH1 M IH0 T'],
  "kimmey": ['K IH1 M IY0'],
  "kimmich": ['K IH1 M IH0 CH'],
  "kimmins": ['K IH1 M IH0 N Z'],
  "kimmitt": ['K IH1 M IH0 T'],
  "kimmons": ['K IH1 M AH0 N Z'],
  "kimono": ['K AH0 M OW1 N AH0'],
  "kimonos": ['K AH0 M OW1 N AH0 Z'],
  "kimoto": ['K IY0 M OW1 T OW0'],
  "kimpel": ['K IH1 M P AH0 L'],
  "kimple": ['K IH1 M P AH0 L'],
  "kimpo": ['K IH1 M P OW0'],
  "kimpton": ['K IH1 M P T AH0 N'],
  "kimrey": ['K IH1 M R IY0'],
  "kims": ['K IH1 M Z'],
  "kimsey": ['K IH1 M Z IY0'],
  "kimura": ['K IY0 M UH1 R AH0'],
  "kimwipe": ['K IH1 M W AY0 P'],
  "kimwipes": ['K IH1 M W AY0 P S'],
  "kimzey": ['K IH1 M Z IY0'],
  "kin": ['K IH1 N'],
  "kin's": ['K IH1 N Z'],
  "kinard": ['K IH1 N ER0 D'],
  "kinark": ['K IH1 N AA0 R K'],
  "kinase": ['K AY1 N EY2 Z'],
  "kinburn": ['K IH1 N B ER2 N'],
  "kincade": ['K IH2 N K EY1 D'],
  "kincaid": ['K IH2 N K EY1 D'],
  "kincaid's": ['K IH2 N K EY1 D Z'],
  "kincannon": ['K IH2 N K AE1 N AH0 N'],
  "kincer": ['K IH1 N S ER0'],
  "kinch": ['K IH1 N CH'],
  "kincheloe": ['K IH1 N CH IH0 L OW0'],
  "kinchen": ['K IH1 NG K AH0 N'],
  "kincy": ['K IH1 N S IY0'],
  "kind": ['K AY1 N D'],
  "kinda": ['K IH1 N D AH0'],
  "kindall": ['K IH1 N D AH0 L'],
  "kindel": ['K IH1 N D AH0 L'],
  "kindell": ['K IH1 N D AH0 L'],
  "kinder": ['K AY1 N D ER0'],
  "kindergarten": ['K IH1 N D ER0 G AA2 R T AH0 N'],
  "kindergartens": ['K IH1 N D ER0 G AA2 R T AH0 N Z'],
  "kindergartner": ['K IH1 N D ER0 G AA2 R T N ER0'],
  "kindergartners": ['K IH1 N D ER0 G AA2 R T N ER0 Z'],
  "kinderman": ['K AY1 N D ER0 M AH0 N'],
  "kindest": ['K AY1 N D AH0 S T'],
  "kindig": ['K IH1 N D IH0 G'],
  "kindle": ['K IH1 N D AH0 L'],
  "kindleberger": ['K IH1 N D AH0 L B ER0 G ER0'],
  "kindled": ['K IH1 N D AH0 L D'],
  "kindler": ['K IH1 N D AH0 L ER0', 'K IH1 N D L ER0'],
  "kindley": ['K IH1 N D L IY0'],
  "kindling": ['K IH1 N D L IH0 NG'],
  "kindly": ['K AY1 N D L IY0'],
  "kindness": ['K AY1 N D N AH0 S'],
  "kindran": ['K IH1 N D R AH0 N'],
  "kindred": ['K IH1 N D R IH0 D'],
  "kindrick": ['K IH1 N D R IH0 K'],
  "kinds": ['K AY1 N D Z', 'K AY1 N Z'],
  "kindt": ['K IH1 N T'],
  "kindy": ['K AY1 N D IY0'],
  "kinect": ['K IH0 N EH1 K T'],
  "kinepolis": ['K IH0 N EH1 P AH0 L IH0 S'],
  "kiner": ['K AY1 N ER0'],
  "kines": ['K AY1 N Z'],
  "kinesiology": ['K IH2 N IH0 S IY2 AA1 L AH0 JH IY0'],
  "kinesthetic": ['K IH2 N AH0 S TH EH1 T IH0 K'],
  "kineta": ['K IH1 N IH0 T AH0'],
  "kinetic": ['K AH0 N EH1 T IH0 K', 'K IH0 N EH1 T IH0 K'],
  "kinetics": ['K AH0 N EH1 T IH0 K S'],
  "king": ['K IH1 NG'],
  "king's": ['K IH1 NG Z'],
  "kingbird": ['K IH1 NG B ER2 D'],
  "kingbirds": ['K IH1 NG B ER2 D Z'],
  "kingdom": ['K IH1 NG D AH0 M'],
  "kingdom's": ['K IH1 NG D AH0 M Z'],
  "kingdoms": ['K IH1 NG D AH0 M Z'],
  "kingdon": ['K IH1 NG D AH0 N'],
  "kingen": ['K IH1 NG AH0 N'],
  "kingery": ['K IH1 NG G ER0 IY0'],
  "kingfisher": ['K IH1 NG F IH2 SH ER0'],
  "kingfishers": ['K IH1 NG F IH2 SH ER0 Z'],
  "kingham": ['K IH1 NG HH AE2 M'],
  "kinghorn": ['K IH1 NG HH ER0 N'],
  "kingly": ['K IH1 NG L IY0'],
  "kingma": ['K IH1 NG M AH0'],
  "kingmaker": ['K IH1 NG M EY2 K ER0'],
  "kingmakers": ['K IH1 NG M EY2 K ER0 Z'],
  "kingman": ['K IH1 NG M AH0 N'],
  "kingon": ['K IH1 NG AO2 N'],
  "kingpin": ['K IH1 NG P IH2 N'],
  "kingpins": ['K IH1 NG P IH2 N Z'],
  "kingrey": ['K IH1 NG G R IY0'],
  "kingry": ['K IH1 NG G ER0 IY0'],
  "kings": ['K IH1 NG Z'],
  "kings'": ['K IH1 NG Z'],
  "kingsborough": ['K IH1 NG Z B ER2 OW0'],
  "kingsbridge": ['K IH1 NG Z B R IH2 JH'],
  "kingsbury": ['K IH1 NG Z B EH2 R IY0'],
  "kingsey": ['K IH1 NG Z IY0'],
  "kingsford": ['K IH1 NG S F ER0 D'],
  "kingship": ['K IH1 NG SH IH0 P'],
  "kingsland": ['K IH1 NG Z L AE0 N D'],
  "kingsley": ['K IH1 NG Z L IY0'],
  "kingsley's": ['K IH1 NG Z L IY0 Z'],
  "kingsolver": ['K IH1 NG S AA0 L V ER0'],
  "kingsport": ['K IH1 NG S P AO2 R T'],
  "kingston": ['K IH1 NG S T AH0 N'],
  "kingsun": ['K IH1 NG S AH0 N'],
  "kingsville": ['K IH1 NG Z V IH2 L'],
  "kingsway": ['K IH1 NG G Z W EY0'],
  "kingswell": ['K IH1 NG G Z W EH0 L'],
  "kington": ['K IH1 NG T AH0 N'],
  "kinion": ['K IH1 N Y AH0 N'],
  "kiniry": ['K IH1 N AY0 R IY0'],
  "kinkade": ['K IH1 NG K AH0 D'],
  "kinkead": ['K IH1 NG K EH0 D'],
  "kinkel": ['K IH1 NG K AH0 L'],
  "kinker": ['K IH1 NG K ER0'],
  "kinkle": ['K IH1 NG K AH0 L'],
  "kinko": ['K IH1 NG K OW2'],
  "kinko's": ['K IH1 NG K OW2 Z'],
  "kinks": ['K IH1 NG K S'],
  "kinky": ['K IH1 NG K IY0'],
  "kinlaw": ['K IH1 N L AO2'],
  "kinley": ['K IH1 N L IY0'],
  "kinloch": ['K IH1 N L AH0 K'],
  "kinman": ['K IH1 N M AH0 N'],
  "kinmen": ['K IH1 N M EH0 N'],
  "kinn": ['K IH1 N'],
  "kinnaird": ['K IH1 N ER0 D'],
  "kinnaman": ['K IH1 N AH0 M AH0 N'],
  "kinnamon": ['K IH1 N AH0 M AH0 N'],
  "kinnan": ['K IH1 N AH0 N'],
  "kinnard": ['K IH1 N ER0 D'],
  "kinne": ['K IH1 N'],
  "kinnear": ['K IH1 N IH2 R'],
  "kinnebrew": ['K IH1 N IH0 B R UW0'],
  "kinnell": ['K IH1 N AH0 L'],
  "kinner": ['K IH1 N ER0'],
  "kinnett": ['K IH1 N IH0 T'],
  "kinney": ['K IH1 N IY0'],
  "kinnick": ['K IH1 N IH0 K'],
  "kinnie": ['K IH1 N IY0'],
  "kinnison": ['K IH1 N IH0 S AH0 N'],
  "kinnock": ['K IH1 N AH0 K'],
  "kinnock's": ['K IH1 N AH0 K S'],
  "kinnunen": ['K IH0 N AH1 N AH0 N'],
  "kinny": ['K IH1 N IY0'],
  "kinoshita": ['K IY0 N OW0 SH IY1 T AH0'],
  "kinsel": ['K IH1 N S AH0 L'],
  "kinsell": ['K IH1 N S AH0 L'],
  "kinsella": ['K IY0 N S EH1 L AH0'],
  "kinser": ['K IH1 N S ER0'],
  "kinsey": ['K IH1 N Z IY0'],
  "kinshasa": ['K IH0 N SH AA1 S AH0', 'K IH0 N SH AE1 S AH0'],
  "kinship": ['K IH1 N SH IH2 P'],
  "kinsinger": ['K IH1 N S IH0 N JH ER0'],
  "kinsler": ['K IH1 N S AH0 L ER0', 'K IH1 N S L ER0'],
  "kinsley": ['K IH1 N Z L IY0'],
  "kinsley's": ['K IH1 N Z L IY0 Z'],
  "kinslow": ['K IH1 N S L OW2'],
  "kinsman": ['K IH1 N Z M AE2 N'],
  "kinst": ['K IH1 N S T'],
  "kinstler": ['K IH1 N S T L ER0'],
  "kinston": ['K IH1 N S T AH0 N'],
  "kint": ['K IH1 N T'],
  "kinter": ['K IH1 N T ER0'],
  "kintigh": ['K IH1 N T AY0'],
  "kintner": ['K IH1 N T N ER0'],
  "kinton": ['K IH1 N T AH0 N'],
  "kintz": ['K IH1 N T S'],
  "kintzel": ['K IH1 N T Z AH0 L'],
  "kinyon": ['K IH1 N Y AH0 N'],
  "kinzel": ['K IH1 N Z AH0 L'],
  "kinzer": ['K IH1 N Z ER0'],
  "kinzey": ['K IH1 N Z IY0'],
  "kinzie": ['K IH1 N Z IY0'],
  "kinzler": ['K IH1 N Z L ER0'],
  "kinzlmaier": ['K IH1 N Z AH0 L M AY2 ER0'],
  "kiosk": ['K IY1 AO2 S K'],
  "kiosks": ['K IY1 AO2 S K S'],
  "kious": ['K AY1 AH0 S'],
  "kip": ['K IH1 P'],
  "kiper": ['K AY1 P ER0'],
  "kipfer": ['K IH1 P F ER0'],
  "kipling": ['K IH1 P L IH0 NG'],
  "kiplinger": ['K IH1 P AH0 L IH0 NG ER0', 'K IH1 P L IH0 NG ER0'],
  "kiplinger's": ['K IH1 P L IH2 NG ER0 Z'],
  "kipnis": ['K IH1 P N IH0 S'],
  "kipp": ['K IH1 P'],
  "kipper": ['K IH1 P ER0'],
  "kipperman": ['K IH1 P ER0 M AH0 N'],
  "kippers": ['K IH1 P ER0 Z'],
  "kippes": ['K IH1 P S'],
  "kippur": ['K IH1 P ER0'],
  "kir": ['K IH1 R'],
  "kira": ['K IH1 R AA0'],
  "kiracofe": ['K IH1 R AH0 K OW2 F'],
  "kiraly": ['K IH1 R AH0 L IY0'],
  "kirby": ['K ER1 B IY0'],
  "kirby's": ['K ER1 B IY0 Z'],
  "kirch": ['K ER1 K'],
  "kirchberg": ['K ER1 K B ER0 G', 'K ER1 CH B ER0 G'],
  "kirchberger": ['K ER1 CH B ER0 G ER0'],
  "kirchen": ['K ER1 K AH0 N'],
  "kircher": ['K ER1 K ER0'],
  "kirchgessner": ['K ER1 K G IH0 S N ER0'],
  "kirchhofer": ['K ER1 K HH AH0 F ER0'],
  "kirchhoff": ['K ER1 K HH AO0 F'],
  "kirchman": ['K ER1 K M AH0 N'],
  "kirchner": ['K ER1 K N ER0'],
  "kirchners": ['K ER1 K N ER0 Z'],
  "kirchoff": ['K ER1 K HH AO0 F'],
  "kirgan": ['K ER1 G AH0 N'],
  "kirgiz": ['K IH1 R G IH0 Z'],
  "kiri": ['K IH1 R IY0'],
  "kiribati": ['K IH2 R IH1 B AA1 T IY0'],
  "kirin": ['K IH1 R IH0 N'],
  "kirk": ['K ER1 K'],
  "kirk's": ['K ER1 K S'],
  "kirkbride": ['K ER1 K B R AY0 D'],
  "kirkby": ['K ER1 K B IY0'],
  "kirkeby": ['K ER1 K IH0 B IY0'],
  "kirkendall": ['K ER0 K EH1 N D AH0 L'],
  "kirkendoll": ['K ER0 K EH1 N D OW0 L'],
  "kirker": ['K ER1 K ER0'],
  "kirkey": ['K ER1 K IY0'],
  "kirkham": ['K ER1 K HH AH0 M'],
  "kirkhart": ['K ER1 K HH AA0 R T'],
  "kirkland": ['K ER1 K L AH0 N D'],
  "kirkland's": ['K ER1 K L AH0 N D Z'],
  "kirkley": ['K ER1 K L IY0'],
  "kirklin": ['K ER1 K L IH0 N'],
  "kirkman": ['K ER1 K M AH0 N'],
  "kirkner": ['K ER1 K N ER0'],
  "kirkpatrick": ['K ER0 K P AE1 T R IH0 K', 'K ER0 P AE1 T R IH0 K'],
  "kirks": ['K ER1 K S'],
  "kirksey": ['K ER1 K S IY0'],
  "kirkuk": ['K IH2 R K UH1 K'],
  "kirkum": ['K IH1 R K AH0 M'],
  "kirkwood": ['K ER1 K W UH0 D'],
  "kirley": ['K ER1 L IY0'],
  "kirlin": ['K ER1 L IH0 N'],
  "kirmse": ['K ER1 M S IY0'],
  "kirn": ['K ER1 N'],
  "kirnan": ['K ER1 N AH0 N'],
  "kirner": ['K ER1 N ER0'],
  "kirouac": ['K AY1 R AW0 AE0 K'],
  "kirov": ['K IH1 R AA0 V'],
  "kirsch": ['K ER1 SH'],
  "kirschbaum": ['K ER1 SH B AW0 M'],
  "kirschenbaum": ['K ER1 SH AH0 N B AW0 M'],
  "kirschenmann": ['K ER1 SH AH0 N M AH0 N'],
  "kirschman": ['K ER1 SH M AH0 N'],
  "kirschner": ['K ER1 SH N ER0'],
  "kirsh": ['K ER1 SH'],
  "kirshbaum": ['K ER1 SH B AW2 M'],
  "kirshenbaum": ['K ER1 SH AH0 N B AW0 M'],
  "kirshfink": ['K ER1 SH F IH0 NG K'],
  "kirshner": ['K ER1 SH N ER0'],
  "kirst": ['K ER1 S T'],
  "kirstein": ['K ER1 S T AY0 N', 'K ER1 S T IY0 N'],
  "kirsten": ['K ER1 S T AH0 N'],
  "kirstie": ['K ER1 S T IY0'],
  "kirstin": ['K ER1 S T IH0 N'],
  "kirt": ['K ER1 T'],
  "kirtland": ['K ER1 T L AH0 N D'],
  "kirtley": ['K ER1 T L IY0'],
  "kirton": ['K ER1 T AH0 N'],
  "kirts": ['K ER1 T S'],
  "kirven": ['K ER1 V AH0 N'],
  "kirwan": ['K ER1 W AO0 N'],
  "kirwin": ['K ER1 W IH0 N'],
  "kiryas": ['K IH1 R Y AH0 S', 'K IH2 R Y AA1 S'],
  "kiryat": ['K IH1 R Y AH0 T', 'K IH2 R Y AA1 T'],
  "kis": ['K IH1 S'],
  "kisamore": ['K IY0 S AA1 M AO0 R'],
  "kisan": ['K IH1 Z AH0 N'],
  "kisch": ['K IH1 SH'],
  "kischell": ['K IH1 SH AH0 L'],
  "kisco": ['K IH1 S K OW0'],
  "kise": ['K AY1 Z'],
  "kiser": ['K AY1 Z ER0'],
  "kish": ['K IH1 SH'],
  "kisha": ['K IH1 SH AH0'],
  "kishbaugh": ['K IH1 SH B AW0'],
  "kishi": ['K IY1 SH IY0'],
  "kishore": ['K IY1 SH AO2 R'],
  "kisiel": ['K IH1 S IY0 L'],
  "kiska": ['K IH1 S K AH0'],
  "kiska's": ['K IH1 S K AH0 Z'],
  "kisler": ['K IH1 S AH0 L ER0', 'K IH1 S L ER0'],
  "kisling": ['K IH1 S AH0 L IH0 NG', 'K IH1 S L IH0 NG'],
  "kismayu": ['K IH0 S M AA1 Y UW0', 'K IH0 Z M AA1 Y UW0'],
  "kismet": ['K IH1 Z M IH0 T'],
  "kismets": ['K IH1 Z M IH0 T S'],
  "kisner": ['K IH1 S N ER0'],
  "kisor": ['K AY1 Z ER0'],
  "kiss": ['K IH1 S'],
  "kissack": ['K IH1 S AH0 K'],
  "kissam": ['K IH1 S AH0 M'],
  "kissane": ['K IH1 S AH0 N'],
  "kissed": ['K IH1 S T'],
  "kissee": ['K IH1 S IY1'],
  "kissel": ['K IH1 S AH0 L'],
  "kissell": ['K IH1 S AH0 L'],
  "kisser": ['K IH1 S ER0'],
  "kisses": ['K IH1 S AH0 Z', 'K IH1 S IH0 Z'],
  "kissick": ['K IH1 S IH0 K'],
  "kissimmee": ['K IH0 S IH1 M IY0'],
  "kissing": ['K IH1 S IH0 NG'],
  "kissinger": ['K IH1 S IH0 N JH ER0'],
  "kissinger's": ['K IH1 S IH0 N JH ER0 Z'],
  "kissler": ['K IH1 S L ER0'],
  "kissling": ['K IH1 S L IH0 NG'],
  "kissner": ['K IH1 S N ER0'],
  "kist": ['K IH1 S T'],
  "kister": ['K IH1 S T ER0'],
  "kistler": ['K IH1 S T L ER0'],
  "kistner": ['K IH1 S T N ER0'],
  "kiszczak": ['K IH1 Z AE0 K', 'K IH1 SH AE0 K'],
  "kit": ['K IH1 T'],
  "kita": ['K IY1 T AH0'],
  "kitagawa": ['K IY0 T AA0 G AA1 W AH0'],
  "kitajima": ['K IY2 T AH0 JH IY1 M AH0'],
  "kitamura": ['K IY0 T AA0 M UH1 R AH0'],
  "kitcat": ['K IH1 T K AE2 T'],
  "kitch": ['K IH1 CH'],
  "kitchel": ['K IH1 CH AH0 L'],
  "kitchell": ['K IH1 CH AH0 L'],
  "kitchen": ['K IH1 CH AH0 N'],
  "kitchen's": ['K IH1 CH AH0 N Z'],
  "kitchenaid": ['K IH1 CH AH0 N EY2 D'],
  "kitchener": ['K IH1 CH AH0 N ER0', 'K IH1 CH N ER0'],
  "kitchenette": ['K IH2 CH AH0 N EH1 T'],
  "kitchens": ['K IH1 CH AH0 N Z'],
  "kitchenware": ['K IH1 CH AH0 N W EH2 R'],
  "kitchin": ['K IH1 CH IH0 N'],
  "kitching": ['K IH1 CH IH0 NG'],
  "kitchings": ['K IH1 CH IH0 NG Z'],
  "kite": ['K AY1 T'],
  "kites": ['K AY1 T S'],
  "kithcart": ['K IH1 TH K AA2 R T'],
  "kitimat": ['K IH1 T IH0 M AE0 T'],
  "kiting": ['K AY1 T IH0 NG'],
  "kitman": ['K IH1 T M AH0 N'],
  "kitner": ['K IH1 T N ER0'],
  "kitowski": ['K IH0 T AO1 F S K IY0'],
  "kits": ['K IH1 T S'],
  "kitsch": ['K IH1 CH'],
  "kitson": ['K IH1 T S AH0 N'],
  "kitt": ['K IH1 T'],
  "kittaneh": ['K IH0 T AA1 N EH2'],
  "kittel": ['K IH1 T AH0 L'],
  "kittell": ['K IH1 T AH0 L'],
  "kittelson": ['K IH1 T IH0 L S AH0 N'],
  "kitten": ['K IH1 T AH0 N'],
  "kittens": ['K IH1 T AH0 N Z'],
  "kitterman": ['K IH1 T ER0 M AH0 N'],
  "kittinger": ['K IH1 T IH0 NG ER0'],
  "kittiwake": ['K IH1 T IH0 W EY2 K'],
  "kittle": ['K IH1 T AH0 L'],
  "kittler": ['K IH1 T AH0 L ER0'],
  "kittles": ['K IH1 T AH0 L Z'],
  "kittleson": ['K IH1 T AH0 L S AH0 N'],
  "kittman": ['K IH1 T M AH0 N'],
  "kittner": ['K IH1 T N ER0'],
  "kittredge": ['K IH1 T R IH0 JH'],
  "kittrell": ['K IH1 T R AH0 L'],
  "kitts": ['K IH1 T S'],
  "kitty": ['K IH1 T IY0'],
  "kitz": ['K IH1 T S'],
  "kitzhaber": ['K IH1 T S HH EY2 B ER0', 'K IH1 T S HH AA2 B ER0'],
  "kitzman": ['K IH1 T S M AH0 N'],
  "kitzmiller": ['K IH1 T S M IH2 L ER0'],
  "kivela": ['K IH1 V IH0 L AH0'],
  "kivett": ['K IH1 V IH0 T'],
  "kivi": ['K IH1 V IY0'],
  "kiwani": ['K IH0 W AA1 N IH0'],
  "kiwanis": ['K IH0 W AA1 N IH0 S'],
  "kiwi": ['K IY1 W IY0'],
  "kiwi's": ['K IY1 W IY0 Z'],
  "kiwis": ['K IY1 W IY0 Z'],
  "kiyohida": ['K IY2 OW0 HH IY1 D AH0'],
  "kiyoshi": ['K IY0 OW1 SH IY0'],
  "kiyotaka": ['K IY2 OW0 T AA1 K AH0'],
  "kizer": ['K AY1 Z ER0'],
  "kizziah": ['K IH1 Z IY0 AH0'],
  "kjar": ['K Y AA1 R', 'K EY1 JH EY1 EY1 AA1 R'],
  "kjell": ['K Y EH1 L'],
  "kjellberg": ['K Y EH1 L B ER0 G', 'JH EH1 L B ER0 G'],
  "kjenstad": ['K Y EH1 N S T AE2 D'],
  "kjos": ['K Y AO1 S'],
  "kkk": ['K EY1 K EY1 K EY1'],
  "klaas": ['K L AA1 S'],
  "klaassen": ['K L AA1 S AH0 N'],
  "klabunde": ['K L AE1 B AH0 N D'],
  "kladstrup": ['K L AE1 D S T R AH0 P'],
  "kladstrup's": ['K L AE1 D S T R AH0 P S'],
  "klages": ['K L EY1 JH IH0 Z'],
  "klahn": ['K L AE1 N'],
  "klahr": ['K L AE1 R'],
  "klaiber": ['K L EY1 B ER0'],
  "klamath-falls": ['K L AE1 M AH0 TH F AO1 L Z'],
  "klamer": ['K L EY1 M ER0'],
  "klamm": ['K L AE1 M'],
  "klammer": ['K L AE1 M ER0'],
  "klamon": ['K L EY1 M AH0 N'],
  "klan": ['K L AE1 N'],
  "klan's": ['K L AE1 N Z'],
  "klang": ['K L AE1 NG'],
  "klann": ['K L AE1 N'],
  "klans": ['K L AE1 N Z'],
  "klansman": ['K L AE1 N Z M AH0 N'],
  "klansmen": ['K L AE1 N Z M AH0 N'],
  "klanwatch": ['K L AE1 N W AA2 CH'],
  "klapp": ['K L AE1 P'],
  "klapper": ['K L AE1 P ER0'],
  "klapperich": ['K L AE1 P ER0 IH0 K'],
  "klar": ['K L AA1 R'],
  "klare": ['K L EH1 R'],
  "klarich": ['K L AE1 R IH0 K'],
  "klarman": ['K L AA1 R M AH0 N'],
  "klas": ['K L AE1 S'],
  "klase": ['K L EY1 Z'],
  "klasen": ['K L EY1 S AH0 N'],
  "klass": ['K L AE1 S'],
  "klassen": ['K L AE1 S AH0 N'],
  "klatsky": ['K L AE1 T S K IY0'],
  "klatt": ['K L AE1 T'],
  "klauber": ['K L AW1 B ER0'],
  "klauer": ['K L AW1 ER0'],
  "klaus": ['K L AW1 S'],
  "klauser": ['K L AW1 S ER0'],
  "klausing": ['K L AW1 S IH0 NG'],
  "klausner": ['K L AW1 S N ER0'],
  "klaver": ['K L EY1 V ER0'],
  "klawitter": ['K L AO1 IH0 T ER0'],
  "klay": ['K L EY1'],
  "klayman": ['K L EY1 M AH0 N'],
  "kleban": ['K L EH1 B AH0 N'],
  "klebba": ['K L EH1 B AH0'],
  "klebe": ['K L IY1 B'],
  "kleber": ['K L IY1 B ER0'],
  "kleck": ['K L EH1 K'],
  "klecka": ['K L EH1 K AH0'],
  "klecker": ['K L EH1 K ER0'],
  "kleckner": ['K L EH1 K N ER0'],
  "kleczka": ['K L EH1 K S K AH0'],
  "klee": ['K L IY1'],
  "klee's": ['K L IY1 Z'],
  "kleeman": ['K L IY1 M AH0 N'],
  "kleen": ['K L IY1 N'],
  "kleenex": ['K L IY1 N AH0 K S'],
  "kleer": ['K L IH1 R'],
  "klees": ['K L IY1 Z'],
  "kleffner": ['K L EH1 F N ER0'],
  "kleiber": ['K L AY1 B ER0'],
  "kleier": ['K L AY1 ER0'],
  "kleiman": ['K L AY1 M AH0 N'],
  "klein": ['K L AY1 N'],
  "klein's": ['K L AY1 N Z'],
  "kleinberg": ['K L AY1 N B ER0 G'],
  "kleindienst": ['K L AY1 N D IY2 N S T'],
  "kleine": ['K L AY1 N'],
  "kleiner": ['K L AY1 N ER0'],
  "kleinert": ['K L AY1 N ER0 T'],
  "kleinfeld": ['K L AY1 N F EH2 L D'],
  "kleinfelter": ['K L AY1 N F IH0 L T ER0'],
  "kleinhans": ['K L AY1 N HH AH0 N Z'],
  "kleinhenz": ['K L AY1 N HH IH0 N S'],
  "kleinknecht": ['K L AY1 NG K N IH0 K T'],
  "kleinman": ['K L AY1 N M AH0 N'],
  "kleinpaste": ['K L AY1 N P EY2 S T'],
  "kleinpeter": ['K L AY1 N P IY0 T ER0'],
  "kleinsasser": ['K L AY1 N S AH0 S ER0'],
  "kleinschmidt": ['K L AY1 N SH M IH2 T'],
  "kleinsmith": ['K L AY1 N S M IH0 TH'],
  "kleinwort": ['K L AY1 N W ER0 T'],
  "kleis": ['K L AY1 Z'],
  "kleiss": ['K L AY1 S'],
  "kleist": ['K L AY1 S T'],
  "klejna": ['K L EY1 N ER0'],
  "klem": ['K L EH1 M'],
  "klema": ['K L EH1 M AH0'],
  "kleman": ['K L EH1 M AH0 N'],
  "klemann": ['K L EH1 M AH0 N'],
  "klemens": ['K L EH1 M AH0 N S'],
  "klement": ['K L EH1 M AH0 N T'],
  "klemm": ['K L EH1 M'],
  "klemme": ['K L EH1 M'],
  "klemmer": ['K L EH1 M ER0'],
  "klemp": ['K L EH1 M P'],
  "klempner": ['K L EH1 M P N ER0'],
  "klemz": ['K L EH1 M Z'],
  "klenge": ['K L EH1 N JH'],
  "klenk": ['K L EH1 NG K'],
  "klenke": ['K L EH1 NG K'],
  "klensch": ['K L EH1 N SH'],
  "klepac": ['K L EH1 P AH0 K'],
  "klepfer": ['K L EH1 P F ER0'],
  "kleppe": ['K L EH1 P'],
  "klepper": ['K L EH1 P ER0'],
  "kleppinger": ['K L EH1 P IH0 NG G ER0'],
  "kleptocrat": ['K L EH1 P T AH0 K R AE0 T'],
  "kleptomaniac": ['K L EH2 P T AH0 M EY1 N IY0 AE0 K'],
  "klerk": ['K L ER1 K'],
  "klerk's": ['K L ER1 K S'],
  "klesch": ['K L EH1 SH'],
  "klesken": ['K L EH1 S K AH0 N'],
  "kless": ['K L EH1 S'],
  "klett": ['K L EH1 T'],
  "kleubeck": ['K L UW1 B EH0 K'],
  "kleve": ['K L IY1 V'],
  "kleven": ['K L IY1 V AH0 N'],
  "klever": ['K L EH1 V ER0'],
  "kley": ['K L EY1'],
  "klezmatic": ['K L EH0 Z M AE1 T IH0 K'],
  "klezmatics": ['K L EH0 Z M AE1 T IH0 K S'],
  "klezmer": ['K L EH1 Z M ER0'],
  "klich": ['K L IH1 CH'],
  "klick": ['K L IH1 K'],
  "klickovic": ['K L IH1 K AH0 V IH2 K'],
  "kliebert": ['K L IY1 B ER0 T'],
  "klieg": ['K L IY1 G'],
  "klieman": ['K L AY1 M AH0 N'],
  "klier": ['K L AY1 ER0'],
  "kliethermes": ['K L IY1 TH ER0 M Z'],
  "kliewer": ['K L IY1 W ER0'],
  "kligman": ['K L IH1 G M AH0 N'],
  "klim": ['K L IH1 M'],
  "klima": ['K L AY1 M AH0'],
  "klimas": ['K L AY1 M AH0 Z'],
  "klimaszewski": ['K L IH0 M AH0 SH EH1 F S K IY0'],
  "klimczak": ['K L IH1 M CH AE0 K'],
  "klimek": ['K L IH1 M IH0 K'],
  "kliment": ['K L AY1 M AH0 N T'],
  "klimer": ['K L IH1 M ER0', 'K L AY1 M ER0'],
  "klimowicz": ['K L IH1 M AH0 V IH0 CH'],
  "klinck": ['K L IH1 NG K'],
  "klindt": ['K L IH1 N T'],
  "klindworth": ['K L IH1 N D W ER0 TH'],
  "kline": ['K L AY1 N'],
  "klinedinst": ['K L IH1 N IH0 D IH0 N S T', 'K L AY1 N D IH0 N S T'],
  "klinefelter": ['K L IH1 N IH0 F IH0 L T ER0', 'K L AY1 N F IH0 L T ER0'],
  "kling": ['K L IH1 NG'],
  "klingaman": ['K L IH1 NG G AH0 M AH0 N'],
  "klingbeil": ['K L IH1 NG B AY0 L'],
  "klingberg": ['K L IH1 NG B ER0 G'],
  "klinge": ['K L IH1 N JH'],
  "klingel": ['K L IH1 NG G AH0 L'],
  "klingenberg": ['K L IH1 NG AH0 N B ER0 G'],
  "klingensmith": ['K L IH1 NG G IH0 N S M IH0 TH'],
  "klinger": ['K L IH1 NG ER0'],
  "klinger's": ['K L IH1 NG ER0 Z'],
  "klingerman": ['K L IH1 NG ER0 M AH0 N'],
  "klinghoffer": ['K L IH1 NG HH AO2 F ER0'],
  "klingler": ['K L IH1 NG G AH0 L ER0', 'K L IH1 NG G L ER0'],
  "klingman": ['K L IH1 NG M AH0 N'],
  "klingon": ['K L IH1 NG G AH0 N'],
  "klingons": ['K L IH1 NG G AH0 N Z'],
  "klink": ['K L IH1 NG K'],
  "klinke": ['K L IH1 NG K'],
  "klinker": ['K L IH1 NG K ER0'],
  "klinkhammer": ['K L IH1 NG K HH AE0 M ER0'],
  "klinkhammer's": ['K L IH1 NG K HH AE0 M ER0 Z'],
  "klinkner": ['K L IH1 NG K N ER0'],
  "klint": ['K L IH1 N T'],
  "klintworth": ['K L IH1 N T W ER0 TH'],
  "klinzing": ['K L IH1 N Z IH0 NG'],
  "klipfel": ['K L IH1 P F AH0 L'],
  "klipp": ['K L IH1 P'],
  "klippel": ['K L IH1 P AH0 L'],
  "klish": ['K L IH1 SH'],
  "klitz": ['K L IH1 T S'],
  "klitzke": ['K L IH1 T S K IY0'],
  "klixshavich": ['K L IH0 K S AE1 V IH0 CH'],
  "kloberdanz": ['K L AA1 B ER0 D AH0 N S'],
  "kloc": ['K L AA1 K'],
  "klocek": ['K L OW1 S IH0 K'],
  "klock": ['K L AA1 K'],
  "klocke": ['K L AA1 K'],
  "kloeckner": ['K L OW1 K N ER0'],
  "kloehn": ['K L OW1 N'],
  "kloepfer": ['K L OW1 P F ER0'],
  "kloeppel": ['K L OW1 P AH0 L'],
  "kloepper": ['K L OW1 P ER0'],
  "kloiber": ['K L OY1 B ER0'],
  "klom": ['K L AO1 M'],
  "klomp": ['K L AA1 M P'],
  "klondike": ['K L AA1 N D AY2 K'],
  "klonowski": ['K L AH0 N AO1 F S K IY0'],
  "klontz": ['K L AA1 N T S'],
  "kloos": ['K L UW1 Z'],
  "klooster": ['K L UW1 S T ER0'],
  "kloosterman": ['K L UW1 S T ER0 M AH0 N'],
  "klopf": ['K L AA1 P F'],
  "klopfenstein": ['K L AA1 P F AH0 N S T AY0 N', 'K L AA1 P F AH0 N S T IY0 N'],
  "klopfer": ['K L AA1 P F ER0'],
  "klopp": ['K L AA1 P'],
  "kloppenburg": ['K L AA1 P AH0 N B ER0 G'],
  "klos": ['K L AO1 S'],
  "klose": ['K L OW1 Z'],
  "klosinski": ['K L AH0 S IH1 N S K IY0'],
  "klosowski": ['K L AH0 S AO1 F S K IY0'],
  "kloss": ['K L AO1 S'],
  "klossner": ['K L AA1 S N ER0'],
  "kloster": ['K L AO1 S T ER0'],
  "klosterman": ['K L AA1 S T ER0 M AH0 N'],
  "klostermann": ['K L AA1 S T ER0 M AH0 N'],
  "kloth": ['K L AA1 TH'],
  "klotz": ['K L AA1 T S'],
  "klotzbach": ['K L AA1 T S B AA0 K'],
  "klu": ['K L UW1', 'K EY2 EH2 L Y UW1'],
  "klucevsek": ['K L UW0 S EH1 V S EH0 K'],
  "kluck": ['K L AH1 K'],
  "kludt": ['K L AH1 D T'],
  "kluender": ['K L UH1 N D ER0'],
  "kluesner": ['K L UH1 S N ER0'],
  "kluever": ['K L UH1 V ER0'],
  "klug": ['K L AH1 G'],
  "kluge": ['K L UW1 JH'],
  "kluge's": ['K L UW1 JH IH0 Z'],
  "kluger": ['K L UW1 G ER0'],
  "klugh": ['K L AH1'],
  "klugman": ['K L AH1 G M AH0 N'],
  "klugt": ['K L AH1 T'],
  "klukas": ['K L UW1 K AH0 Z'],
  "klumb": ['K L AH1 M'],
  "klump": ['K L AH1 M P'],
  "klumpp": ['K L AH1 M P'],
  "klunder": ['K L AH1 N D ER0'],
  "klundt": ['K L AH1 N T'],
  "klunk": ['K L AH1 NG K'],
  "klus": ['K L AH1 S'],
  "klusman": ['K L AH1 S M AH0 N'],
  "klute": ['K L UW1 T'],
  "kluth": ['K L UW1 TH'],
  "klutts": ['K L AH1 T S'],
  "kluttz": ['K L AH1 T S'],
  "klutz": ['K L AH1 T S'],
  "klutznick": ['K L AH1 T S N IH0 K'],
  "kluver": ['K L UW1 V ER0'],
  "kluwer": ['K L UW1 W ER0'],
  "klux": ['K L AH1 K S'],
  "klym": ['K L IH1 M'],
  "klynveld": ['K L IH1 N V EH2 L D'],
  "kmart": ['K EY1 M AA2 R T'],
  "kmart's": ['K EY1 M AA2 R T S'],
  "kmetz": ['K AH0 M EH1 T S'],
  "kmiec": ['K AH0 M IY1 K'],
  "kmiecik": ['K AH0 M IY1 CH IH0 K'],
  "knaack": ['N AA1 K'],
  "knaak": ['N AA1 K'],
  "knab": ['N AE1 B'],
  "knabb": ['N AE1 B'],
  "knabe": ['N EY1 B'],
  "knable": ['N EY1 B AH0 L'],
  "knack": ['N AE1 K'],
  "knacks": ['N AE1 K S'],
  "knackstedt": ['N AE1 K S T IH0 T'],
  "knaggs": ['N AE1 G Z'],
  "knape": ['N EY1 P'],
  "knapik": ['N AE1 P IH0 K'],
  "knapke": ['N EY1 P K'],
  "knapp": ['N AE1 P'],
  "knapp's": ['N AE1 P S'],
  "knappenberger": ['N AE1 P AH0 N B ER0 G ER0'],
  "knapper": ['N AE1 P ER0'],
  "knapsack": ['N AE1 P S AE2 K'],
  "knapton": ['N AE1 P T AH0 N'],
  "knarr": ['N AE1 R'],
  "knaub": ['N AO1 B'],
  "knauer": ['N AW1 ER0'],
  "knauf": ['N AO1 F'],
  "knauff": ['N AO1 F'],
  "knaup": ['N AO1 P'],
  "knaus": ['N AO1 Z'],
  "knauss": ['N AO1 S'],
  "knave": ['N EY1 V'],
  "knaves": ['N EY1 V Z'],
  "knbc": ['K EY1 EH1 N B IY1 S IY1'],
  "knead": ['N IY1 D'],
  "kneading": ['N IY1 D IH0 NG'],
  "kneafsey": ['N IY1 F S IY0'],
  "kneale": ['N IY1 L'],
  "knebel": ['N EH1 B AH0 L'],
  "knecht": ['N EH1 K T'],
  "knechtel": ['N EH1 K T AH0 L'],
  "knee": ['N IY1'],
  "kneebone": ['N IY1 B OW2 N'],
  "kneecap": ['N IY1 K AE2 P'],
  "kneecaps": ['N IY1 K AE2 P S'],
  "kneece": ['N IY1 S'],
  "kneed": ['N IY1 D'],
  "kneeing": ['N IY1 IH0 NG'],
  "kneel": ['N IY1 L'],
  "kneeland": ['N IY1 L AH0 N D'],
  "kneeling": ['N IY1 L IH0 NG'],
  "kneer": ['N IH1 R'],
  "knees": ['N IY1 Z'],
  "kneip": ['N IY1 P'],
  "kneisel": ['N AY1 S AH0 L'],
  "kneisley": ['N IY1 S L IY0'],
  "knell": ['N EH1 L'],
  "kneller": ['N EH1 L ER0'],
  "knelt": ['N EH1 L T'],
  "knepp": ['N EH1 P'],
  "knepper": ['N EH1 P ER0'],
  "knerr": ['N EH1 R'],
  "kness": ['N EH1 S'],
  "knesset": ['N EH1 S AH0 T', 'K N EH1 S AH0 T', 'K AH0 N EH1 S AH0 T'],
  "knew": ['N UW1', 'N Y UW1'],
  "knezevich": ['N EH1 Z IH0 V IH0 CH'],
  "knicely": ['N AY1 S L IY0'],
  "knick": ['N IH1 K'],
  "knick-knack": ['N IH1 K N AE1 K'],
  "knick-knacks": ['N IH1 K N AE1 K S'],
  "knicker": ['N IH1 K ER0'],
  "knickerbocker": ['N IH1 K ER0 B AA2 K ER0'],
  "knickerbockered": ['N IH1 K ER0 B AA2 K ER0 D'],
  "knickerbockers": ['N IH1 K ER0 B AA2 K ER0 Z'],
  "knickers": ['N IH1 K ER0 Z'],
  "knickknack": ['N IH1 K N AE2 K'],
  "knickknacks": ['N IH1 K N AE2 K S'],
  "knicks": ['N IH1 K S'],
  "knicks'": ['N IH1 K S'],
  "knief": ['N IY1 F'],
  "kniep": ['N IY1 P'],
  "knieriem": ['N IY1 R IY2 M'],
  "knierim": ['N IH1 R IH0 M'],
  "knies": ['N AY1 Z'],
  "kniess": ['N IY1 S'],
  "knievel": ['K AH0 N IY1 V AH0 L', 'N IY1 V AH0 L'],
  "knife": ['N AY1 F'],
  "knifed": ['N AY1 F T'],
  "knifelike": ['N AY1 F L AY2 K'],
  "knifepoint": ['N AY1 F P OY2 N T'],
  "kniffen": ['N IH1 F AH0 N'],
  "kniffin": ['N IH1 F IH0 N'],
  "knifing": ['N AY1 F IH0 NG'],
  "knifings": ['N AY1 F IH0 NG Z'],
  "knigge": ['N IH1 G'],
  "knight": ['N AY1 T'],
  "knight's": ['N AY1 T S'],
  "knighted": ['N AY1 T IH0 D'],
  "knighten": ['N AY1 T AH0 N'],
  "knighthood": ['N AY1 T HH UH2 D'],
  "knightly": ['N AY1 T L IY0'],
  "knighton": ['N AY1 T AH0 N'],
  "knights": ['N AY1 T S'],
  "knill": ['N IH1 L'],
  "knin": ['K EY1 EH1 N AY1 EH1 N', 'K N IH1 N', 'N IH1 N'],
  "knipe": ['N AY1 P'],
  "knipfer": ['N IH1 P F ER0'],
  "knipl": ['N IH1 P AH0 L'],
  "knipp": ['N IH1 P'],
  "knippa": ['N IH1 P AH0'],
  "knippel": ['N IH1 P AH0 L'],
  "knippenberg": ['N IH1 P AH0 N B ER0 G'],
  "knipper": ['N IH1 P ER0'],
  "knipple": ['N IH1 P AH0 L'],
  "knisely": ['N AY1 Z L IY0'],
  "kniskern": ['N IH1 S K ER0 N'],
  "knisley": ['N IH1 S L IY0'],
  "knispel": ['N IH1 S P AH0 L'],
  "kniss": ['N IH1 S'],
  "knit": ['N IH1 T'],
  "knits": ['N IH1 T S'],
  "knitted": ['N IH1 T AH0 D', 'N IH1 T IH0 D'],
  "knittel": ['N IH1 T AH0 L'],
  "knitter": ['N IH1 T ER0'],
  "knitting": ['N IH1 T IH0 NG'],
  "knittle": ['N IH1 T AH0 L'],
  "knitwear": ['N IH1 T W EH2 R'],
  "knives": ['N AY1 V Z'],
  "kniveton": ['N AY1 V T AH0 N'],
  "knob": ['N AA1 B'],
  "knobbe": ['N AA1 B'],
  "knobby": ['N AA1 B IY0'],
  "knobel": ['N OW1 B AH0 L'],
  "knoblauch": ['N AA1 B L AW0 K'],
  "knoble": ['N OW1 B AH0 L'],
  "knobloch": ['N AA1 B L AH0 K'],
  "knoblock": ['N AA1 B L AA0 K'],
  "knobs": ['N AA1 B Z'],
  "knoch": ['N AA1 K'],
  "knoche": ['N AA1 CH'],
  "knochel": ['N AA1 K AH0 L'],
  "knock": ['N AA1 K'],
  "knockdown": ['N AA1 K D AW2 N'],
  "knocked": ['N AA1 K T'],
  "knocking": ['N AA1 K IH0 NG'],
  "knockoff": ['N AA1 K AO2 F'],
  "knockoffs": ['N AA1 K AO2 F S'],
  "knockout": ['N AA1 K AW2 T'],
  "knockouts": ['N AA1 K AW2 T S'],
  "knocks": ['N AA1 K S'],
  "knode": ['N OW1 D'],
  "knodel": ['N OW1 D AH0 L'],
  "knodle": ['N OW1 D AH0 L'],
  "knoebel": ['N OW1 B AH0 L'],
  "knoedler": ['N OW1 D AH0 L ER0', 'N OW1 D L ER0'],
  "knoell": ['N OW1 L'],
  "knoff": ['N AO1 F'],
  "knogo": ['N OW1 G OW0'],
  "knoke": ['N OW1 K'],
  "knoles": ['N OW1 L Z'],
  "knoll": ['N OW1 L'],
  "knoll's": ['N OW1 L Z'],
  "knoop": ['N UW1 P'],
  "knop": ['N AA1 P'],
  "knope": ['N OW1 P'],
  "knopf": ['N AA1 P F', 'N AA1 F'],
  "knopp": ['N AA1 P'],
  "knorr": ['N AO1 R'],
  "knost": ['N AA1 S T'],
  "knot": ['N AA1 T'],
  "knotek": ['N OW1 T IH0 K'],
  "knoth": ['N AA1 TH'],
  "knots": ['N AA1 T S'],
  "knott": ['N AA1 T'],
  "knott's": ['N AA1 T S'],
  "knotted": ['N AA1 T IH0 D'],
  "knotts": ['N AA1 T S'],
  "knotty": ['N AA1 T IY0'],
  "knouff": ['N OW1 F'],
  "knous": ['N AO1 S'],
  "knouse": ['N AW1 S'],
  "know": ['N OW1'],
  "know-how": ['N OW1 HH AW2'],
  "knowed": ['N OW1 D'],
  "knower": ['N OW1 ER0'],
  "knowing": ['N OW1 IH0 NG'],
  "knowingly": ['N OW1 IH0 NG L IY0'],
  "knowledge": ['N AA1 L AH0 JH', 'N AA1 L IH0 JH'],
  "knowledgeable": ['N AA1 L AH0 JH AH0 B AH0 L'],
  "knowledgeably": ['N AA1 L IH0 JH AH0 B L IY0'],
  "knowledgeware": ['N AA1 L IH0 JH W EH2 R'],
  "knowles": ['N OW1 L Z'],
  "knowlton": ['N OW1 L T AH0 N'],
  "known": ['N OW1 N'],
  "knowns": ['N OW1 N Z'],
  "knows": ['N OW1 Z'],
  "knox": ['N AA1 K S'],
  "knox's": ['N AA1 K S IH0 Z'],
  "knoxville": ['N AA1 K S V IH0 L'],
  "knoy": ['N OY1'],
  "knuckle": ['N AH1 K AH0 L'],
  "knuckled": ['N AH1 K AH0 L D'],
  "knuckles": ['N AH1 K AH0 L Z'],
  "knudsen": ['K N UW1 D S AH0 N'],
  "knudsen's": ['K N UW1 D S AH0 N Z'],
  "knudson": ['K N UW1 D S AH0 N'],
  "knudtson": ['K N UW1 T S AH0 N'],
  "knueppel": ['K N UW1 P AH0 L'],
  "knupp": ['K N AH1 P'],
  "knust": ['K N AH1 S T'],
  "knut": ['K N AH1 T'],
  "knute": ['K N UW1 T'],
  "knuth": ['K N UW1 TH'],
  "knuts": ['K N AH1 T S'],
  "knutsen": ['N AH1 T S AH0 N'],
  "knutson": ['N UW1 T S AH0 N'],
  "knutzen": ['N AH1 T Z AH0 N'],
  "ko": ['K OW1'],
  "koala": ['K OW0 AA1 L AH0'],
  "koalas": ['K OW0 AA1 L AH0 Z'],
  "koba": ['K OW1 B AA0'],
  "kobacker": ['K OW1 B AE2 K ER0'],
  "kobak": ['K OW1 B AH0 K'],
  "kobayashi": ['K OW2 B AA0 Y AA1 SH IY0'],
  "kobe": ['K OW1 B EY0'],
  "kobe's": ['K OW1 B EY0 Z'],
  "kobel": ['K OW1 B AH0 L'],
  "kober": ['K OW1 B ER0'],
  "koberstein": ['K OW1 B ER0 S T AY0 N', 'K OW1 B ER0 S T IY0 N'],
  "kobes": ['K OW1 B Z'],
  "koble": ['K OW1 B AH0 L'],
  "kobler": ['K OW1 B AH0 L ER0', 'K OW1 B L ER0'],
  "kobren": ['K AA1 B R AH0 N'],
  "kobrin": ['K AA1 B R IH0 N'],
  "kobrin's": ['K AA1 B R IH0 N Z'],
  "kobs": ['K AA1 B Z'],
  "kobus": ['K OW1 B AH0 S'],
  "koby": ['K OW1 B IY0'],
  "kobylarz": ['K AH0 B IH1 L ER0 Z'],
  "kobylinski": ['K AH0 B IH0 L IH1 N S K IY0'],
  "kobza": ['K AA1 B Z AH0'],
  "kocak": ['K OW1 K AH0 K'],
  "koch": ['K AO1 CH', 'K OW1 K'],
  "kochan": ['K AA1 K AH0 N'],
  "kochanek": ['K AA1 K AH0 N IH0 K'],
  "kochanowski": ['K AH0 HH AH0 N AO1 F S K IY0'],
  "kochanski": ['K AH0 HH AE1 N S K IY0'],
  "kochel": ['K AA1 K AH0 L'],
  "kochendorfer": ['K AA1 K IH0 N D AO0 R F ER0'],
  "kocher": ['K AO1 CH ER0'],
  "kochevar": ['K AH0 HH EH1 V ER0'],
  "kochis": ['K AA1 K IH0 S'],
  "kochman": ['K AA1 K M AH0 N'],
  "koci": ['K OW1 S IY0'],
  "kocian": ['K OW1 SH AH0 N'],
  "kociemba": ['K OW2 S IY0 EH1 M B AH0'],
  "kocinski": ['K AH0 CH IH1 N S K IY0'],
  "kociolek": ['K OW2 S IY0 OW1 L EH0 K'],
  "kocis": ['K OW1 S IH0 S'],
  "kock": ['K AA1 K'],
  "kocourek": ['K AH0 K UH1 R EH0 K'],
  "kocsis": ['K AA1 K S IH0 S'],
  "kocur": ['K OW1 K ER0'],
  "kocur's": ['K OW1 K ER0 Z'],
  "kocurek": ['K AH0 K Y UW1 R EH0 K'],
  "kodacolor": ['K OW1 D AH0 K AH2 L ER0'],
  "kodak": ['K OW1 D AE2 K'],
  "kodak's": ['K OW1 D AE2 K S'],
  "kodama": ['K OW0 D AA1 M AH0'],
  "kodiak": ['K OW1 D IY0 AE2 K'],
  "kodo": ['K OW1 D OW0'],
  "koebel": ['K OW1 B AH0 L'],
  "koedinger": ['K OW1 D IH0 NG ER0'],
  "koegel": ['K OW1 G AH0 L'],
  "koegler": ['K OW1 G AH0 L ER0', 'K OW1 G L ER0'],
  "koehl": ['K OW1 L'],
  "koehler": ['K OW1 L ER0'],
  "koehn": ['K OW1 N'],
  "koehne": ['K OW1 N'],
  "koehnen": ['K OW1 N AH0 N'],
  "koeki": ['K OW1 K IY0'],
  "koelle": ['K OW1 L'],
  "koeller": ['K OW1 L ER0'],
  "koelling": ['K OW1 L IH0 NG'],
  "koelsch": ['K OW1 L SH'],
  "koelzer": ['K OW1 L Z ER0'],
  "koen": ['K OW1 N'],
  "koeneman": ['K AA1 IY0 N M AH0 N'],
  "koenen": ['K OW1 N AH0 N'],
  "koenig": ['K ER1 N IH0 G'],
  "koenigs": ['K ER1 N IH0 G Z'],
  "koenigsberg": ['K OW1 N IH0 G Z B ER0 G'],
  "koenigsfeld": ['K OW1 N IH0 G Z F EH2 L D'],
  "koep": ['K OW1 P'],
  "koepke": ['K OW1 P K'],
  "koepp": ['K OW1 P'],
  "koeppe": ['K OW1 P'],
  "koeppel": ['K OW1 P AH0 L'],
  "koeppen": ['K OW1 P AH0 N'],
  "koepsel": ['K OW1 P S EH0 L'],
  "koepsell": ['K OW1 P S EH0 L'],
  "koerber": ['K AO1 R B ER0'],
  "koerner": ['K AO1 R N ER0'],
  "koernke": ['K AO1 R N AH0 K IY0'],
  "koester": ['K AO1 S T ER0'],
  "koesters": ['K AO1 S T ER0 Z'],
  "koestler": ['K OW1 S T L ER0'],
  "koestner": ['K OW1 S T N ER0'],
  "koether": ['K OW1 DH ER0'],
  "koetje": ['K OW1 JH AH0'],
  "koetter": ['K OW1 T ER0'],
  "koetting": ['K OW1 T IH0 NG'],
  "koff": ['K AO1 F'],
  "koffler": ['K AA1 F AH0 L ER0', 'K AA1 F L ER0'],
  "koffman": ['K AO1 F M AH0 N'],
  "kofi": ['K OW1 F IY0'],
  "kofler": ['K OW1 F AH0 L ER0', 'K OW1 F L ER0'],
  "kofoed": ['K OW1 F OW0 D'],
  "kofron": ['K AA1 F R AH0 N'],
  "kofu": ['K OW1 F UW0'],
  "koga": ['K OW1 G AH0'],
  "kogan": ['K OW1 G AH0 N'],
  "kogel": ['K OW1 G AH0 L'],
  "koger": ['K OW1 G ER0'],
  "kogi": ['K OW1 G IY0'],
  "kogler": ['K OW1 G AH0 L ER0', 'K OW1 G L ER0'],
  "koglin": ['K AA1 G L IH0 N'],
  "kogut": ['K OW1 G AH0 T'],
  "kogyo": ['K OW1 JH Y OW0'],
  "koh": ['K OW1'],
  "kohan": ['K OW1 HH AA0 N'],
  "kohen": ['K OW1 AH0 N'],
  "kohl": ['K OW1 L'],
  "kohl's": ['K OW1 L Z'],
  "kohlbeck": ['K OW1 L B EH2 K'],
  "kohlberg": ['K OW1 L B ER0 G'],
  "kohlberg's": ['K OW1 L B ER0 G Z'],
  "kohlenberg": ['K OW1 L AH0 N B ER0 G'],
  "kohler": ['K OW1 L ER0'],
  "kohles": ['K OW1 HH AH0 L Z'],
  "kohlhepp": ['K OW1 L HH IH0 P'],
  "kohlhoff": ['K OW1 L HH AO2 F'],
  "kohli": ['K OW1 L IY0'],
  "kohlman": ['K OW1 L M AH0 N'],
  "kohlmann": ['K OW1 L M AH0 N'],
  "kohlmeier": ['K OW1 L M AY0 ER0'],
  "kohlmeyer": ['K OW1 L M AY0 ER0'],
  "kohlrabi": ['K AO0 L R AA1 B IY0'],
  "kohls": ['K OW1 L Z'],
  "kohn": ['K AA1 N'],
  "kohne": ['K OW1 N'],
  "kohnen": ['K OW1 N AH0 N'],
  "kohner": ['K OW1 N ER0'],
  "kohnke": ['K AA1 NG K'],
  "kohout": ['K OW1 AW0 T'],
  "kohr": ['K AO1 R'],
  "kohring": ['K AO1 R IH0 NG'],
  "kohrs": ['K AO1 R Z'],
  "kohtaro": ['K OW0 T AA1 R OW0'],
  "kohtoro": ['K OW0 T AO1 R OW0'],
  "kohut": ['K OW1 AH0 T'],
  "koichi": ['K OW0 IY1 CH IY0'],
  "koido": ['K OY1 D OW0'],
  "koike": ['K OY1 K'],
  "koito": ['K OY1 T OW0', 'K OY1 T OW2', 'K OW2 IY1 T OW2'],
  "koivisto": ['K OY2 V IH1 S T OW0'],
  "kojak": ['K OW1 JH AE2 K'],
  "koji": ['K OW1 JH IY0'],
  "kojima": ['K AH0 Y AY1 M AH0'],
  "kok": ['K AA1 K'],
  "kokan": ['K OW1 K AH0 N'],
  "kokan's": ['K OW1 K AH0 N Z'],
  "kokate": ['K OW2 K AA1 T EY2', 'K OW2 K AA1 T EY0'],
  "kokate's": ['K OW2 K AA1 T EY2 Z', 'K OW2 K AA1 T EY0 Z'],
  "koke": ['K OW1 K'],
  "koken": ['K OW1 K AH0 N'],
  "koker": ['K OW1 K ER0'],
  "kokes": ['K OW1 K S'],
  "kokesh": ['K AA1 K IH0 SH'],
  "koki": ['K OW1 K IY0'],
  "kokinda": ['K AH0 K IH1 N D AH0'],
  "koko": ['K OW1 K OW0'],
  "koko's": ['K OW1 K OW0 Z'],
  "kokomo": ['K OW1 K AH0 M OW2'],
  "kokoschka": ['K AH0 K AO1 SH K AH0'],
  "kokoszka": ['K AH0 K AA1 SH K AH0'],
  "kokusai": ['K AA1 K Y UW0 S AY2'],
  "kol": ['K OW1 L'],
  "kolakowski": ['K AH0 L AH0 K AO1 F S K IY0'],
  "kolander": ['K AA1 L AH0 N D ER0'],
  "kolar": ['K OW1 L ER0'],
  "kolarik": ['K AH0 L AA1 R IH0 K'],
  "kolasa": ['K AH0 L AA1 S AH0'],
  "kolasinski": ['K AH0 L AH0 S IH1 N S K IY0'],
  "kolb": ['K OW1 L B'],
  "kolbe": ['K OW1 L B'],
  "kolbeck": ['K AA1 L B EH0 K'],
  "kolber": ['K OW1 L B ER0'],
  "kolberg": ['K OW1 L B ER0 G'],
  "kolbert": ['K OW1 L B ER0 T'],
  "kolbo": ['K OW1 L B OW0'],
  "kolden": ['K OW1 L D AH0 N'],
  "kole": ['K OW1 L'],
  "kolek": ['K OW1 L EH0 K'],
  "kolenda": ['K AH0 L EH1 N D AH0'],
  "kolesar": ['K AH0 L EH1 S ER0'],
  "kolich": ['K AA1 L IH0 HH'],
  "kolin": ['K OW1 L IH0 N'],
  "kolinski": ['K AH0 L IH1 N S K IY0'],
  "kolk": ['K OW1 K'],
  "kolka": ['K OW1 L K AH0'],
  "kolker": ['K OW1 K ER0'],
  "koll": ['K AA1 L'],
  "kollar": ['K AA1 L ER0'],
  "kollasch": ['K AA1 L AH0 SH'],
  "kollath": ['K AA1 L AH0 TH'],
  "kolle": ['K AA1 L'],
  "kollek": ['K AO1 L EH0 K', 'K OW1 L EH0 K'],
  "koller": ['K AA1 L ER0'],
  "kolli": ['K AA1 L IY0'],
  "kolling": ['K AA1 L IH0 NG'],
  "kollman": ['K AA1 L M AH0 N'],
  "kollmann": ['K AA1 L M AH0 N'],
  "kollmeyer": ['K AA1 L M AY0 ER0'],
  "kollmorgen": ['K OW0 L M AO1 R G AH0 N', 'K AO0 L M AO1 R G AH0 N'],
  "kolm": ['K OW1 M'],
  "kolman": ['K AA1 L M AH0 N'],
  "kolodny": ['K AH0 L AA1 D N IY0'],
  "kolodziej": ['K AH0 L AA1 D Z IY0 JH'],
  "kolodziejski": ['K AH0 L AA2 JH IY0 EY1 S K IY0'],
  "kolojejchick": ['K OW2 L OW0 JH EY1 CH IH0 K'],
  "kolokoff": ['K AA1 L AH0 K AO2 F'],
  "koloski": ['K AH0 L AW1 S K IY0'],
  "kolowich": ['K AA1 L AH0 W IH0 CH'],
  "kolp": ['K OW1 L P'],
  "kolski": ['K OW1 L S K IY0'],
  "kolsky": ['K OW1 L S K IY0'],
  "kolstad": ['K OW1 L S T AH0 D'],
  "kolter": ['K OW1 L T ER0'],
  "kolterman": ['K OW1 L T ER0 M AH0 N'],
  "kolton": ['K OW1 L T AH0 N'],
  "kolts": ['K OW1 L T S'],
  "koltz": ['K OW1 L T S'],
  "komaba": ['K OW0 M AA1 B AH0'],
  "komag": ['K OW1 M AE0 G'],
  "koman": ['K OW1 M AH0 N'],
  "komansky": ['K OW0 M AE1 N S K IY0'],
  "komar": ['K OW1 M ER0'],
  "komara": ['K OW0 M AA1 R AH0'],
  "komarek": ['K OW0 M AA1 R EH0 K'],
  "komarik": ['K OW0 M AA1 R IH0 K'],
  "komarik's": ['K OW0 M AA1 R IH0 K S'],
  "komatani": ['K AO1 M AH0 T AA1 N IY2'],
  "komatsu": ['K OW0 M AA1 T S UW1'],
  "kombat": ['K AA1 M B AE0 T'],
  "komer": ['K OW1 M ER0'],
  "komercni": ['K OW2 M ER1 CH N IY0'],
  "komi": ['K OW1 M IY0'],
  "komine": ['K OW1 M AY2 N'],
  "komineft": ['K AA1 M IH0 N EH0 F T'],
  "kominek": ['K AO1 M AH0 N EH2 K'],
  "komisar": ['K AH0 M IH1 S ER0', 'K AA1 M IH0 S AA0 R'],
  "kommer": ['K AA1 M ER0'],
  "komodo": ['K AH0 M OW1 D OW0', 'K OW0 M OW1 D OW0'],
  "komori": ['K OW0 M AO1 R IY0'],
  "komorny": ['K OW0 M AO1 R N IY0'],
  "komorowski": ['K AH0 M ER0 AO1 F S K IY0'],
  "komoto": ['K OW0 M OW1 T OW0'],
  "komp": ['K AA1 M P'],
  "kompanek": ['K AA2 M P AA1 N EH2 K'],
  "komsomol": ['K AA1 M S OW0 M AH0 L'],
  "komsomol's": ['K AA1 M S OW0 M AH0 L Z'],
  "komura": ['K OW2 M UH1 R AH0'],
  "kon": ['K AA1 N'],
  "kona": ['K OW1 N AH0'],
  "konaga": ['K AH0 N AA1 G AH0'],
  "konarski": ['K AH0 N AA1 R S K IY0'],
  "konczal": ['K AA1 N CH AH0 L'],
  "kondas": ['K AA1 N D AH0 Z'],
  "kondo": ['K AA1 N D OW0'],
  "kondracki": ['K AH0 N D R AA1 T S K IY0'],
  "kondrat": ['K AA1 N D R AH0 T'],
  "konecny": ['K AH0 N EH1 K N IY0'],
  "konen": ['K AA1 N AH0 N'],
  "kong": ['K AO1 NG', 'K AO1 NG G'],
  "kong's": ['K AO1 NG Z', 'K AO1 NG G Z'],
  "konger": ['K AA1 NG G ER0'],
  "kongers": ['K AA1 NG G ER0 Z'],
  "kongsberg": ['K AO1 NG Z B ER0 G', 'K AO1 NG G Z B ER0 G'],
  "kongsberg's": ['K AO1 NG Z B ER0 G Z', 'K AO1 NG G Z B ER0 G Z'],
  "konica": ['K AA1 N IH0 K AH0'],
  "konicek": ['K AA1 N IH0 CH EH2 K'],
  "konicki": ['K AH0 N IH1 T S K IY0'],
  "konieczka": ['K AH0 N IY1 CH K AH0'],
  "konieczny": ['K AH0 N IY1 CH N IY0'],
  "konig": ['K AA1 N IH0 G'],
  "koning": ['K OW1 N IH0 NG'],
  "koninklijke": ['K AA2 N IH0 NG K L IY1 K IY0'],
  "konishi": ['K OW0 N IY1 SH IY0'],
  "konishiroku": ['K AA2 N IH2 SH IH0 R OW1 K UW2'],
  "konitzer": ['K AA1 N IH0 T Z ER0'],
  "konkel": ['K AA1 NG K AH0 L'],
  "konkle": ['K AA1 NG K AH0 L'],
  "konkol": ['K AA1 NG K AO0 L'],
  "konner": ['K AA1 N ER0'],
  "kono": ['K OW1 N OW0'],
  "konold": ['K AA1 N OW0 L D'],
  "konop": ['K OW1 N AH0 P'],
  "konopka": ['K AH0 N OW1 P K AH0'],
  "konrad": ['K AA1 N R AH0 D'],
  "konrath": ['K AA1 N R AH0 TH'],
  "konstantin": ['K AA1 N S T IH0 N T IY2 N'],
  "konsultat": ['K AA2 N S AH0 L T AA1 T'],
  "kontos": ['K AA1 N T OW0 Z'],
  "kontra": ['K AA1 N T R AH0'],
  "kontras": ['K AA1 N T R AH0 S'],
  "kontrollbank": ['K AA1 N T R AH0 L B AE2 NG K'],
  "konwinski": ['K AH0 N V IH1 N S K IY0'],
  "konz": ['K AA1 N Z'],
  "konzen": ['K AA1 N Z AH0 N'],
  "konzi": ['K AA1 N Z IY0'],
  "konzi's": ['K AA1 N Z IY0 Z'],
  "koo": ['K UW1'],
  "koob": ['K UW1 B'],
  "koogler": ['K UW1 G L ER0'],
  "kooi": ['K UW1 IY0'],
  "kooiker": ['K UW1 IH0 K ER0'],
  "kooiman": ['K UW1 IH0 M AH0 N'],
  "kooistra": ['K UW0 IH1 Z T R AH0'],
  "kook": ['K UW1 K'],
  "kooken": ['K UW1 K AH0 N'],
  "kooker": ['K UH1 K ER0'],
  "kooks": ['K UW1 K S'],
  "kooky": ['K UW1 K IY0'],
  "kool": ['K UW1 L'],
  "koolhaas": ['K UW1 L HH AA0 S'],
  "koon": ['K UW1 N'],
  "koon's": ['K UW1 N Z'],
  "koonce": ['K UW1 N S'],
  "koone": ['K UW1 N'],
  "koonig": ['K UW1 N IH0 G'],
  "kooning": ['K UW1 N IH0 NG'],
  "koons": ['K UW1 N Z'],
  "koonts": ['K UW1 N T S'],
  "koontz": ['K UW1 N T S'],
  "koop": ['K UW1 P'],
  "koop's": ['K UW1 P S'],
  "koopman": ['K UW1 P M AH0 N'],
  "koopmann": ['K UW1 P M AH0 N'],
  "koops": ['K UW1 P S'],
  "koor": ['K UW1 R'],
  "koors": ['K UH1 R Z'],
  "koos": ['K UW1 Z'],
  "kooser": ['K UW1 Z ER0'],
  "kootenay": ['K UW1 T AH0 N EY2'],
  "kooy": ['K UW1 IY0'],
  "kooyman": ['K AA1 OY0 M AH0 N'],
  "kopacz": ['K AA1 P AH0 CH'],
  "kopas": ['K OW1 P AH0 Z'],
  "kopchev": ['K AA1 P CH AH0 V'],
  "kopczynski": ['K AH0 P CH IH1 N S K IY0'],
  "kope": ['K OW1 P'],
  "kopec": ['K OW1 P IH0 K'],
  "kopecky": ['K AH0 P EH1 T S K IY0'],
  "kopek": ['K OW1 P AH0 K'],
  "kopeks": ['K OW1 P AH0 K S'],
  "kopel": ['K OW1 P AH0 L'],
  "kopelman": ['K OW1 P AH0 L M AH0 N'],
  "koper": ['K OW1 P ER0'],
  "kopera": ['K AH0 P IH1 R AH0'],
  "koperski": ['K AH0 P ER1 S K IY0'],
  "kopetski": ['K AH0 P EH1 T S K IY0'],
  "kopf": ['K AO1 P F'],
  "kopinski": ['K AH0 P IH1 N S K IY0'],
  "kopischke": ['K AH0 P IH1 SH K IY0'],
  "kopit": ['K AA1 P IH0 T'],
  "kopka": ['K OW1 P K AH0'],
  "kopke": ['K OW1 P K'],
  "kopko": ['K OW1 P K OW0'],
  "koplan": ['K AA1 P L AH0 N'],
  "koplin": ['K AA1 P L IH0 N'],
  "koplovitz": ['K AA1 P L AH0 V IH0 T S'],
  "kopp": ['K AA1 P'],
  "koppe": ['K AA1 P'],
  "koppel": ['K AA1 P AH0 L'],
  "koppel's": ['K AA1 P AH0 L Z'],
  "koppell": ['K AA1 P AH0 L'],
  "koppelman": ['K AA1 P AH0 L M AH0 N'],
  "koppen": ['K AA1 P AH0 N'],
  "koppenhaver": ['K AA1 P IH0 N HH AH0 V ER0'],
  "kopper": ['K AA1 P ER0'],
  "koppers": ['K AA1 P ER0 Z'],
  "koppers'": ['K AA1 P ER0 Z'],
  "koppes": ['K AA1 P S'],
  "kopple": ['K AA1 P AH0 L'],
  "kopplin": ['K AA1 P L IH0 N'],
  "kopriva": ['K AA1 P R IH0 V AH0'],
  "koprowski": ['K AH0 P R AO1 F S K IY0'],
  "kops": ['K AA1 P S'],
  "kopusovic": ['K AH0 P UW1 S AH0 V IH0 K'],
  "kopy": ['K AA1 P IY0'],
  "kora": ['K AO1 R AH0'],
  "koral": ['K AO1 R AH0 L'],
  "koran": ['K AO0 R AA1 N'],
  "koranda": ['K ER0 AE1 N D AH0'],
  "koranic": ['K AO0 R AE1 N IH0 K'],
  "korb": ['K AO1 R B'],
  "korba": ['K AO1 R B AH0'],
  "korbel": ['K AO1 R B AH0 L'],
  "korber": ['K AO1 R B ER0'],
  "korbren": ['K AO1 R B R EH0 N'],
  "korby": ['K AO1 R B IY0'],
  "korczak": ['K AO1 R CH AE0 K'],
  "korda": ['K AO1 R D AH0'],
  "kordell": ['K AO0 R D EH1 L'],
  "korea": ['K AO2 R IY1 AH0', 'K R IY1 AH0', 'K ER0 R IY1 AH0'],
  "korea's": ['K AO2 R IY1 AH0 Z', 'K R IY1 AH0 Z', 'K ER0 R IY1 AH0 Z'],
  "koreagate": ['K AO2 R IY1 AH0 G EY2 T', 'K ER0 R IY1 AH0 G EY2 T'],
  "korean": ['K AO2 R IY1 AH0 N', 'K R IY1 AH0 N', 'K ER0 R IY1 AH0 N'],
  "korean's": ['K R IY1 AH0 N Z', 'K ER0 IY1 AH0 N Z'],
  "koreans": ['K AO2 R IY1 AH0 N Z', 'K R IY1 AH0 N Z', 'K ER0 R IY1 AH0 N Z'],
  "koreans'": ['K AO2 R IY1 AH0 N Z', 'K R IY1 AH0 N Z', 'K ER0 R IY1 AH0 N Z'],
  "koreas": ['K AO1 R IY0 AH0 Z', 'K R IY0 AH0 Z', 'K ER0 R IY0 AH0 Z'],
  "koreatown": ['K ER0 IY1 AH0 T AW2 N'],
  "korell": ['K AO1 R EH0 L'],
  "koren": ['K AO1 R AH0 N'],
  "korenek": ['K AO1 R IH0 N IH0 K'],
  "koresh": ['K AO2 R EH1 SH'],
  "koresh's": ['K AO2 R EH1 SH AH0 Z'],
  "koret": ['K AO1 R AH0 T'],
  "korey": ['K AO1 R IY0'],
  "korf": ['K AO1 R F'],
  "korff": ['K AO1 R F'],
  "korfhage": ['K AO1 R F HH IH0 JH'],
  "korhonen": ['K AO1 R HH AH0 N AH0 N'],
  "korinek": ['K AO1 R IH0 N IH0 K'],
  "korman": ['K AO1 R M AH0 N'],
  "kormos": ['K AO1 R M OW0 Z'],
  "korn": ['K AO1 R N'],
  "kornacki": ['K ER0 N AA1 T S K IY0'],
  "kornberg": ['K AO1 R N B ER0 G'],
  "kornblum": ['K AO1 R N B L AH0 M'],
  "kornbluth": ['K AO1 R N B L UW0 TH'],
  "kornegay": ['K AO1 R N IH0 G EY0'],
  "korner": ['K AO1 R N ER0'],
  "kornfeld": ['K AO1 R N F EH0 L D'],
  "kornhauser": ['K AO1 R N HH AW0 Z ER0'],
  "kornreich": ['K AO1 R N R AY0 K'],
  "korns": ['K AO1 N Z'],
  "korol": ['K AO1 R AO0 L'],
  "korologos": ['K AO0 R AA2 L OW1 G OW0 S'],
  "korona": ['K ER0 OW1 N AH0'],
  "korotich": ['K AO1 R AH0 T IH0 CH'],
  "korpela": ['K ER0 P IY1 L AH0'],
  "korpi": ['K AO1 R P IY0'],
  "korry": ['K AO1 R IY0'],
  "korry's": ['K AO1 R IY0 Z'],
  "korson": ['K AO1 R S AH0 N'],
  "kort": ['K AO1 R T'],
  "korte": ['K AO1 R T'],
  "korten": ['K AO1 R T AH0 N'],
  "korth": ['K AO1 R TH'],
  "korthals": ['K AO1 R TH AH0 L Z'],
  "kortman": ['K AO1 R T M AH0 N'],
  "kortum": ['K AO1 R T AH0 M'],
  "kortz": ['K AO1 R T S'],
  "koruna": ['K AO0 R UW1 N AA2'],
  "korva": ['K AO1 R V AA2'],
  "korver": ['K AO1 R V ER2'],
  "kory": ['K AO1 R IY2'],
  "koryagin": ['K AO2 R Y AA1 G IH0 N'],
  "korzeniewski": ['K ER0 Z IH2 N IY0 EH1 F S K IY0', 'K AO2 R Z AH0 N UW1 F S K IY0'],
  "kos": ['K AA1 S'],
  "kosa": ['K OW1 S AA2'],
  "kosak": ['K OW1 S AH0 K'],
  "kosakowski": ['K AH0 S AH0 K AO1 F S K IY2'],
  "kosan": ['K OW1 Z AH0 N'],
  "kosanke": ['K AA1 S AH0 NG K'],
  "kosanovich": ['K AH0 S AE1 N AH0 V IH0 CH'],
  "kosar": ['K OW1 S ER0'],
  "kosberg": ['K AO1 Z B ER0 G'],
  "kosbie": ['K AA1 Z B IY0'],
  "kosch": ['K AO1 SH'],
  "koscheck": ['K AO1 S CH EH0 K'],
  "koscheck's": ['K AO1 S CH EH0 K S'],
  "koscielniak": ['K AH0 S CH IY1 L N IY0 AE0 K'],
  "koscinski": ['K AH0 S CH IH1 N S K IY0'],
  "kosco": ['K OW1 S K OW0'],
  "kosecoff": ['K OW1 S AH0 K AO0 F'],
  "kosek": ['K OW1 S EH0 K'],
  "kosel": ['K OW1 S AH0 L'],
  "koser": ['K OW1 Z ER0'],
  "kosh": ['K AA1 SH'],
  "kosher": ['K OW1 SH ER0'],
  "koshered": ['K OW1 SH ER0 D'],
  "koshering": ['K OW1 SH ER0 IH0 NG'],
  "koshers": ['K OW1 SH ER0 S'],
  "kosiba": ['K OW0 S IY1 B AH0'],
  "kosier": ['K OW1 S IY0 ER0'],
  "kosik": ['K OW1 S IH0 K'],
  "kosin": ['K OW1 S IH0 N'],
  "kosinski": ['K AH0 S IH1 N S K IY0'],
  "koska": ['K OW1 S K AH0'],
  "koskela": ['K AH0 S K IY1 L AH0'],
  "koskey": ['K AA1 S K IY0'],
  "koski": ['K AW1 S K IY0'],
  "koskinen": ['K AA1 S K IH0 N AH0 N'],
  "kosko": ['K OW1 S K OW0'],
  "koskotas": ['K AO2 S K OW1 T AH0 S'],
  "kosky": ['K AA1 S K IY0'],
  "kosloski": ['K AH0 S L AW1 S K IY0'],
  "koslosky": ['K AH0 S L OW1 S K IY0'],
  "koslow": ['K AA1 S L OW0'],
  "koslow's": ['K AA1 Z L OW2 Z'],
  "koslowski": ['K AH0 S L AO1 F S K IY0'],
  "kosman": ['K AA1 S M AH0 N'],
  "kosmatka": ['K AH0 S M AA1 T K AH0'],
  "kosmetsky": ['K AA2 Z M EH1 T S K IY0'],
  "kosmetsky's": ['K AA2 Z M EH1 T S K IY0 Z'],
  "kosmicki": ['K AH0 S M IH1 T S K IY0'],
  "kosnovsky": ['K AA2 Z N AA1 F S K IY0'],
  "kosnovsky's": ['K AA2 Z N AA1 F S K IY0 Z'],
  "kosovo": ['K OW1 S OW0 V OW2'],
  "kosowski": ['K AH0 S AO1 F S K IY0'],
  "kosowsky": ['K AH0 S AW1 S K IY0'],
  "koss": ['K AO1 S'],
  "kossman": ['K AO1 S M AH0 N'],
  "kossow": ['K AA1 S OW0'],
  "kossuth": ['K AA1 S AH0 TH'],
  "kost": ['K AA1 S T'],
  "kosta": ['K OW1 S T AH0'],
  "kostal": ['K AA1 S T AH0 L'],
  "kostas": ['K OW1 S T AH0 Z'],
  "kostecki": ['K AH0 S T EH1 T S K IY0'],
  "kostek": ['K AA1 S T EH0 K'],
  "kostelecky": ['K AH0 S T EH0 L EH1 T S K IY0'],
  "kostelnik": ['K AH0 S T EH1 L N IH0 K'],
  "koster": ['K AA1 S T ER0'],
  "kostic": ['K AA1 S T IH0 K'],
  "kostick": ['K OW1 S T IH0 K'],
  "kostka": ['K AA1 S T K AH0'],
  "kostmayer": ['K AO1 S T M EY2 ER0'],
  "kostoff": ['K AA1 S T AO0 F'],
  "kostrzewa": ['K AH0 S T R AH0 Z EH1 W AH0'],
  "kostrzewski": ['K AO2 S T ER0 Z EH1 W S K IY0'],
  "kosub": ['K OW1 S AH0 B'],
  "kosy": ['K OW1 S IY0'],
  "kosyakov": ['K OW1 S Y AA0 K AO0 V'],
  "kosygin": ['K OW1 S IH0 G IH0 N', 'K OW1 S IY0 G IH0 N'],
  "kot": ['K AA1 T'],
  "kotara": ['K OW0 T AA1 R AH0'],
  "kotarski": ['K AH0 T AA1 R S K IY0'],
  "kotas": ['K OW1 T AH0 Z'],
  "kotch": ['K AA1 CH'],
  "kote": ['K OW1 T'],
  "kotecki": ['K AH0 T EH1 T S K IY0'],
  "kotek": ['K OW1 T EH2 K'],
  "koteles": ['K AA1 T EH0 L EH0 Z'],
  "koth": ['K AA1 TH'],
  "kothari": ['K AA1 TH ER0 IY0'],
  "kothe": ['K OW1 DH'],
  "kotila": ['K AH0 T AY1 L AH0'],
  "kotlarz": ['K AA1 T L ER0 Z'],
  "kotler": ['K OW1 T AH0 L ER0', 'K AA1 T L ER0'],
  "kotlowitz": ['K AA1 T L AH0 W IH0 T S'],
  "koto": ['K OW1 T OW0'],
  "kotowski": ['K AH0 T AO1 F S K IY0'],
  "kotsonis": ['K AE2 T S OW1 N AH0 S', 'K OW0 T S OW1 N AH0 S'],
  "kotsonis'": ['K AE2 T S OW1 N AH0 S', 'K OW0 T S OW1 N AH0 S'],
  "kotsonis's": ['K AE2 T S OW1 N AH0 S IH0 Z', 'K OW0 T S OW1 N AH0 S IH0 Z'],
  "kott": ['K AA1 T'],
  "kotter": ['K AA1 T ER0'],
  "kottke": ['K AA1 T K IY0'],
  "kottler": ['K AA1 T L ER0'],
  "kottwitz": ['K AA1 T W IH0 T S'],
  "kotula": ['K AH0 T UW1 L AH0'],
  "kotz": ['K AA1 T S'],
  "kotzebue": ['K AA1 T S AH0 B Y UW2'],
  "kouba": ['K UW1 B AH0'],
  "koudelka": ['K AW0 D EH1 L K AH0'],
  "kough": ['K AW1'],
  "kouns": ['K AW1 N Z'],
  "kountz": ['K AW1 N T S'],
  "kouri": ['K OW0 UH1 R IY0'],
  "kouril": ['K UW1 R AH0 L'],
  "kourou": ['K UW1 R UW2'],
  "koury": ['K AW1 R IY0'],
  "kouyate": ['K AW2 Y AA1 T EY2'],
  "kovac": ['K OW1 V AH0 K'],
  "kovacevic": ['K AH0 V AH0 CH EH1 V IH0 K'],
  "kovacevich": ['K AH0 V AA1 CH IH0 V IH0 CH'],
  "kovach": ['K OW1 V AA0 K'],
  "kovach's": ['K OW1 V AA0 K S'],
  "kovacic": ['K AH0 V AA1 K IH0 K'],
  "kovacich": ['K AH0 V AA1 CH IH0 HH'],
  "kovacik": ['K AA1 V AH0 CH IH0 K'],
  "kovack": ['K AA1 V AH0 K'],
  "kovacs": ['K OW1 V AE0 K S'],
  "kovaks": ['K OW1 V AE0 K S'],
  "koval": ['K OW1 V AH0 L'],
  "kovalchik": ['K AH0 V AA1 L HH IH0 K'],
  "kovalcik": ['K AA1 V AH0 L CH IH0 K'],
  "kovaleski": ['K AH0 V AH0 L EH1 S K IY0'],
  "kovalik": ['K AH0 V AA1 L IH0 K'],
  "kovalsky": ['K AH0 V AA1 L S K IY0'],
  "kovalyov": ['K OW1 V AA0 L Y AH0 V'],
  "kovar": ['K OW1 V ER0'],
  "kovarik": ['K AH0 V AA1 R IH0 K'],
  "kovatch": ['K AA1 V AH0 CH'],
  "kovats": ['K OW1 V AH0 T S'],
  "koven": ['K OW1 V AH0 N'],
  "kover": ['K OW1 V ER0'],
  "koversada": ['K AH2 V ER0 S AA1 T AH0'],
  "kovich": ['K AA1 V IH0 CH'],
  "kowabunga": ['K AW2 AH0 B AH1 NG G AH0'],
  "kowal": ['K AW1 AH0 L'],
  "kowalchuk": ['K AW0 AA1 L HH AH0 K'],
  "kowalczyk": ['K AW1 AH0 L CH IH0 K'],
  "kowaleski": ['K AW0 AH0 L EH1 S K IY0'],
  "kowalewski": ['K AW0 AH0 L EH1 F S K IY0'],
  "kowalik": ['K AW0 AA1 L IH0 K'],
  "kowalke": ['K AA1 W AO2 K'],
  "kowalkowski": ['K AA1 W AO0 K AO2 F S K IY0'],
  "kowall": ['K AW1 AH0 L'],
  "kowalski": ['K AH0 W AA1 L S K IY0'],
  "kowalsky": ['K AW0 AA1 L S K IY0'],
  "kowitz": ['K AA1 W IH0 T S'],
  "kownacki": ['K AW2 N AA1 K IY0'],
  "kowtow": ['K AW1 T AW1', 'K OW1 T OW1'],
  "koyama": ['K OW0 Y AA1 M AH0'],
  "koyo": ['K OY1 OW0'],
  "koza": ['K OW1 Z AH0'],
  "kozak": ['K OW1 Z AH0 K'],
  "kozakiewicz": ['K AH0 Z AA1 K AH0 V IH0 CH'],
  "kozar": ['K OW1 Z ER0'],
  "kozberg": ['K AA1 Z B ER0 G'],
  "kozel": ['K OW1 Z AH0 L'],
  "kozemchak": ['K OW2 Z EH1 M CH AE2 K'],
  "kozicki": ['K AH0 Z IH1 T S K IY0'],
  "koziel": ['K AA1 Z IY0 L'],
  "kozik": ['K OW1 Z IH0 K'],
  "kozikowski": ['K AH0 Z IH0 K AO1 F S K IY0'],
  "kozinski": ['K AH0 Z IH1 N S K IY0'],
  "koziol": ['K AA1 Z IY0 AO0 L'],
  "kozlik": ['K AA1 Z L IH0 K'],
  "kozloff": ['K AA1 Z L AO0 F'],
  "kozloski": ['K AH0 Z L AW1 S K IY0'],
  "kozlow": ['K AA1 Z L OW0'],
  "kozlowski": ['K AH0 Z L AO1 F S K IY0'],
  "kozma": ['K OW1 Z M AH0'],
  "kozminski": ['K AH0 Z M IH1 N S K IY0'],
  "koznovsky": ['K AA2 Z N AA1 F S K IY0'],
  "koznovsky's": ['K AA2 Z N AA1 F S K IY0 Z'],
  "kozo": ['K OW1 Z OW0'],
  "kozol": ['K OW1 Z AH0 L'],
  "kozub": ['K OW1 Z AH0 B'],
  "kozuch": ['K AA1 Z AH0 HH'],
  "kozyrev": ['K AA1 Z ER0 EH2 V'],
  "kozyrev's": ['K AA1 Z ER0 EH2 V Z'],
  "kpmg": ['K EY1 P IY1 EH1 M JH IH1'],
  "kraai": ['K R AA1 IY0'],
  "kraatz": ['K R AA1 T S'],
  "krabbe": ['K R AE1 B'],
  "krabbenhoft": ['K R AE1 B IH0 N HH AH0 F T'],
  "krabill": ['K R AE1 B AH0 L'],
  "krach": ['K R AE1 CH'],
  "kracht": ['K R AE1 K T'],
  "krack": ['K R AE1 K'],
  "kracke": ['K R AE1 K'],
  "kraeger": ['K R EH1 G ER0'],
  "kraemer": ['K R EH1 M ER0'],
  "kraeutler": ['K R AW1 T L ER0'],
  "krafft": ['K R AE1 F T'],
  "kraft": ['K R AE1 F T'],
  "kraft's": ['K R AE1 F T S'],
  "kraftwerk": ['K R AE1 F T W ER0 K'],
  "krage": ['K R EY1 JH'],
  "krager": ['K R EY1 G ER0'],
  "kragh": ['K R AE1 G'],
  "kragt": ['K R AE1 G T'],
  "krah": ['K R AA1'],
  "krahenbuhl": ['K R AA1 IH0 N B AH0 L'],
  "krahl": ['K R AA1 L'],
  "krahn": ['K R AE1 N'],
  "krain": ['K R EY1 N'],
  "krajewski": ['K R AY0 EH1 F S K IY0'],
  "krajicek": ['K R AY1 IH0 CH EH0 K'],
  "krajina": ['K R AY1 N AH0', 'K R AY0 IY1 N AH0'],
  "krajina's": ['K R AY1 N AH0 Z', 'K R AY0 IY1 N AH0 Z'],
  "kraker": ['K R EY1 K ER0'],
  "krakow": ['K R AA1 K AW0', 'K R AA1 K AA0 V', 'K R AE1 K AW0'],
  "krakower": ['K R AE1 K OW0 ER0'],
  "krakowski": ['K R AH0 K AO1 F S K IY0'],
  "kral": ['K R AE1 L'],
  "kralicek": ['K R AA1 L IH0 CH EH0 K'],
  "kralik": ['K R AA1 L IH0 K'],
  "krall": ['K R AO1 L'],
  "kram": ['K R AE1 M'],
  "kramar": ['K R AE1 M ER0'],
  "kramer": ['K R EY1 M ER0'],
  "kramer's": ['K R EY1 M ER0 Z'],
  "kramlich": ['K R AE1 M L IH0 K'],
  "kramm": ['K R AE1 M'],
  "kramme": ['K R AE1 M'],
  "krammer": ['K R AE1 M ER0'],
  "krammes": ['K R AE1 M Z'],
  "kramp": ['K R AE1 M P'],
  "krampe": ['K R AE1 M P'],
  "krandall": ['K R AE1 N D AH0 L'],
  "krane": ['K R EY1 N'],
  "kraner": ['K R EY1 N ER0'],
  "kranich": ['K R AE1 N IH0 CH'],
  "krans": ['K R AE1 N Z'],
  "krantz": ['K R AE1 N T S'],
  "kranz": ['K R AE1 N Z'],
  "kranzler": ['K R AE1 N Z L ER0'],
  "krapels": ['K R AE1 P AH0 L Z'],
  "krapf": ['K R AE1 P F'],
  "krapp": ['K R AE1 P'],
  "kraprayoon": ['K R AE1 P R AA0 Y UW0 N'],
  "kras": ['K R AE1 S'],
  "krasinski": ['K R AH0 S IH1 N S K IY0'],
  "kraska": ['K R AA1 S K AH0'],
  "krasner": ['K R AE1 S N ER0'],
  "krasnoff": ['K R AE1 S N AO0 F'],
  "krasnow": ['K R AA1 S N OW0'],
  "krasnoyarsk": ['K R AE0 S N OY1 AA0 R S K'],
  "krasny": ['K R AE1 Z N IY0'],
  "krasowski": ['K R AH0 S AO1 F S K IY0'],
  "krass": ['K R AE1 S'],
  "kraszewski": ['K R AH0 SH EH1 F S K IY0'],
  "krat": ['K R AE1 T'],
  "kratky": ['K R AE1 T K IY0'],
  "kratochvil": ['K R AE1 T AH0 K V AH0 L'],
  "kratos": ['K R AE1 T AO2 S'],
  "kratt": ['K R AE1 T'],
  "kratz": ['K R AE1 T S'],
  "kratzer": ['K R EY1 T Z ER0'],
  "kratzke": ['K R AE1 T S K IY0'],
  "krauer": ['K R AW1 R'],
  "kraus": ['K R AW1 S'],
  "krause": ['K R AO1 S'],
  "krauser": ['K R AW1 S ER0'],
  "kraushaar": ['K R AW1 SH AA2 R'],
  "krauskopf": ['K R AW1 S K AO0 F'],
  "krauss": ['K R AW1 S'],
  "krausse": ['K R AO1 S'],
  "krausz": ['K R AW1 SH'],
  "kraut": ['K R AW1 T'],
  "krauter": ['K R AW1 T ER0'],
  "krauth": ['K R AO1 TH'],
  "krauthammer": ['K R AW1 T HH AE2 M ER0'],
  "krauze": ['K R AW1 Z'],
  "kravchuk": ['K R AA1 V CH UH2 K'],
  "kravchuk's": ['K R AA1 V CH UH2 K Z'],
  "kravetz": ['K R AE1 V IH0 T S'],
  "kravis": ['K R AE1 V IH0 S'],
  "kravitz": ['K R AE1 V IH0 T S'],
  "krawccykiewi": ['K R AW2 CH IH0 K UW1 IY0'],
  "krawchuk": ['K R AO1 CH AH0 K'],
  "krawczak": ['K R AA1 V CH AE0 K'],
  "krawczyk": ['K R AA1 V CH IH0 K'],
  "krawiec": ['K R AA1 V IY0 K'],
  "krawitz": ['K R AA1 W IH0 T S'],
  "kray": ['K R EY1'],
  "kraynak": ['K R EY1 N AH0 K'],
  "kreager": ['K R IY1 G ER0'],
  "kreamer": ['K R IY1 M ER0'],
  "krebbs": ['K R EH1 B Z'],
  "krebs": ['K R EH1 B Z'],
  "krebs's": ['K R EH1 B Z IH0 Z'],
  "krebsbach": ['K R EH1 B Z B AA0 K'],
  "krech": ['K R EH1 K'],
  "krecko": ['K R EH1 K OW0'],
  "kredietbank": ['K R EH0 D IY0 T B AE1 NG K'],
  "kredit": ['K R EH1 T IH0 T'],
  "kreditanstalt": ['K R EH0 D IH1 T AH2 N S T AO2 L T'],
  "kreeger": ['K R IY1 G ER0'],
  "kreft": ['K R EH1 F T'],
  "kreg": ['K R EH1 G'],
  "kregel": ['K R EH1 G AH0 L'],
  "kreger": ['K R IY1 G ER0'],
  "kreh": ['K R EH1'],
  "krehbiel": ['K R EH1 B IY0 L'],
  "kreher": ['K R EH1 R'],
  "kreicher": ['K R AY1 K ER0'],
  "kreider": ['K R AY1 D ER0'],
  "kreidler": ['K R AY1 D AH0 L ER0', 'K R AY1 D L ER0'],
  "kreifels": ['K R AY1 F AH0 L Z'],
  "kreig": ['K R IY1 G'],
  "kreiger": ['K R AY1 G ER0'],
  "kreiling": ['K R AY1 L IH0 NG'],
  "kreimer": ['K R AY1 M ER0'],
  "krein": ['K R EY1 N'],
  "kreiner": ['K R AY1 N ER0'],
  "kreis": ['K R IY1 Z'],
  "kreisberg": ['K R AY1 S B ER0 G'],
  "kreischer": ['K R AY1 SH ER0'],
  "kreisel": ['K R AY1 S AH0 L'],
  "kreiser": ['K R AY1 S ER0'],
  "kreisher": ['K R IY1 IH0 SH ER0'],
  "kreisler": ['K R AY1 S L ER0'],
  "kreisman": ['K R AY1 S M AH0 N'],
  "kreiss": ['K R AY1 S'],
  "kreiter": ['K R AY1 T ER0'],
  "kreitman": ['K R AY1 T M AH0 N'],
  "kreitner": ['K R AY1 T N ER0'],
  "kreitz": ['K R IY1 T S'],
  "kreitzberg": ['K R AY1 T S B ER0 G'],
  "kreitzer": ['K R AY1 T Z ER0'],
  "krejci": ['K R EH1 JH S IY0'],
  "krell": ['K R EH1 L'],
  "kremer": ['K R IY1 M ER0'],
  "kremers": ['K R IY1 M ER0 Z'],
  "kremlin": ['K R EH1 M L AH0 N', 'K R EH1 M L IH0 N'],
  "kremlin's": ['K R EH1 M L IH0 N Z'],
  "kremlinologist": ['K R EH2 M L IH0 N AA1 L AH0 JH IH0 S T'],
  "kremlinologists": ['K R EH2 M L IH0 N AA1 L AH0 JH IH0 S T S'],
  "krempa": ['K R EH1 M P AH0'],
  "krempasky": ['K R IH0 M P AA1 S K IY0'],
  "krempler": ['K R EH1 M P L ER0'],
  "kremplers": ['K R EH1 M P L ER0 Z'],
  "kren": ['K R EH1 N'],
  "krenek": ['K R EH1 N IH0 K'],
  "krengel": ['K R EH1 NG G AH0 L'],
  "krenik": ['K R EH1 N IH0 K'],
  "krenke": ['K R EH1 NG K'],
  "krenn": ['K R EH1 N'],
  "krentz": ['K R EH1 N T S'],
  "krenwinkle": ['K R EH1 N W IH2 NG K AH0 L'],
  "krenwinkle's": ['K R EH1 N W IH2 NG K AH0 L Z'],
  "krenz": ['K R EH1 N Z'],
  "krenzer": ['K R EH1 N Z ER0'],
  "krepps": ['K R EH1 P S'],
  "kreps": ['K R EH1 P S'],
  "kresa": ['K R IY1 S ER0'],
  "kresge": ['K R EH1 S G IY0'],
  "kreslovsky": ['K R EH0 S L AO1 V S K IY0'],
  "kress": ['K R EH1 S'],
  "kresse": ['K R EH1 S'],
  "kresser": ['K R EH1 S ER0'],
  "kressin": ['K R EH1 S IH0 N'],
  "kressler": ['K R EH1 S L ER0'],
  "kretchman": ['K R EH1 CH M AH0 N'],
  "kretchmer": ['K R EH1 CH M ER0'],
  "kretsch": ['K R EH1 CH'],
  "kretschmar": ['K R EH1 CH M ER0'],
  "kretschmer": ['K R EH1 CH M ER0'],
  "kretz": ['K R EH1 T S'],
  "kretzer": ['K R EH1 T Z ER0'],
  "kretzschmar": ['K R EH1 CH M ER0'],
  "kreuger": ['K R OY1 G ER0'],
  "kreul": ['K R UW1 L'],
  "kreuser": ['K R OY1 S ER0'],
  "kreuter": ['K R OY1 T ER0'],
  "kreutz": ['K R UW1 T S'],
  "kreuzer": ['K R UW1 Z ER0'],
  "krewson": ['K R UW1 S AH0 N'],
  "krey": ['K R EY1'],
  "kribs": ['K R IH1 B Z'],
  "krichbaum": ['K R IH1 K B AW0 M'],
  "krick": ['K R IH1 K'],
  "krider": ['K R AY1 D ER0'],
  "kridler": ['K R IH1 D L ER0'],
  "kriebel": ['K R IY1 B AH0 L'],
  "krieg": ['K R IY1 G'],
  "kriegel": ['K R IY1 G AH0 L'],
  "krieger": ['K R IY1 G ER0'],
  "krieger's": ['K R IY1 G ER0 Z'],
  "krienke": ['K R IY1 NG K'],
  "krier": ['K R AY1 ER0'],
  "kriese": ['K R IY1 Z'],
  "kriesel": ['K R IY1 S AH0 L'],
  "kriete": ['K R IY1 T'],
  "krigbaum": ['K R IH1 G B AW2 M'],
  "kriger": ['K R AY1 G ER0'],
  "krigsten": ['K R IH1 G S T IH0 N'],
  "krikalev": ['K R IH1 K AH0 L EH2 V'],
  "krikalev's": ['K R IH1 K AH0 L EH2 V Z'],
  "krikorian": ['K R IH0 K AO1 R IY0 AH0 N'],
  "krill": ['K R IH1 L'],
  "krim": ['K R IH1 M'],
  "kriminyok": ['K R IH0 M IH1 N Y AO0 K'],
  "krimmel": ['K R IH1 M AH0 L'],
  "kriner": ['K R AY1 N ER0'],
  "kring": ['K R IH1 NG'],
  "kringen": ['K R IH1 NG AH0 N'],
  "kringley": ['K R IH1 NG G L IY0'],
  "krings": ['K R IH1 NG Z'],
  "krinke": ['K R IH1 NG K'],
  "krinsky": ['K R IH1 N S K IY0'],
  "kris": ['K R IH1 S'],
  "krisch": ['K R IH1 SH'],
  "krischer": ['K R IH1 SH ER0'],
  "krise": ['K R AY1 Z'],
  "krisher": ['K R IH1 SH ER0'],
  "krishna": ['K R IH1 SH N AH0', 'K R IY1 SH N AH0'],
  "krishnan": ['K R IH1 SH N AH0 N'],
  "krisko": ['K R IH1 S K OW0'],
  "krispies": ['K R IH1 S P IY0 Z'],
  "kriss": ['K R IH1 S'],
  "krist": ['K R IH1 S T'],
  "krista": ['K R IH1 S T AH0'],
  "kristall": ['K R IH1 S T AH0 L'],
  "kristallnacht": ['K R IH1 S T AH0 L N AA2 K T'],
  "kristen": ['K R IH1 S T AH0 N'],
  "kristensen": ['K R IH1 S T AH0 N S AH0 N'],
  "kristi": ['K R IH1 S T IY0'],
  "kristi's": ['K R IH1 S T IY0 Z'],
  "kristiansen": ['K R IH1 S CH AH0 N S AH0 N'],
  "kristie": ['K R IH1 S T IY0'],
  "kristin": ['K R IH1 S T IH0 N'],
  "kristina": ['K R IH0 S T IY1 N AH0'],
  "kristine": ['K R IH0 S T IY1 N'],
  "kristof": ['K R IH1 S T AH0 F'],
  "kristoff": ['K R IH1 S T AO0 F'],
  "kristoffe": ['K R IH1 S T AO0 F'],
  "kristofferson": ['K R IH2 S T AO1 F ER0 S AH0 N'],
  "kristol": ['K R IH1 S T AH0 L'],
  "kristol's": ['K R IH1 S T AH0 L Z'],
  "kristopher": ['K R IH1 S T AH0 F ER0'],
  "kristopher's": ['K R IH1 S T AH0 F ER0 Z'],
  "kristy": ['K R IH1 S T IY0'],
  "kritz": ['K R IH1 T S'],
  "kritzer": ['K R IH1 T Z ER0'],
  "krivanek": ['K R IH1 V AH0 N IH0 K'],
  "kriz": ['K R IH1 Z'],
  "krizan": ['K R IH1 Z AH0 N'],
  "krizek": ['K R IH1 Z EH0 K'],
  "krob": ['K R AA1 B'],
  "kroboth": ['K R AA1 B AH0 TH'],
  "kroc": ['K R AA1 K'],
  "kroc's": ['K R AA1 K S'],
  "krock": ['K R AA1 K'],
  "kroeber": ['K R OW1 B ER0'],
  "kroeber's": ['K R OW1 B ER0 Z'],
  "kroeger": ['K R OW1 G ER0'],
  "kroeger's": ['K R OW1 G ER0 Z'],
  "kroegers": ['K R OW1 G ER0 Z'],
  "kroeker": ['K R OW1 K ER0'],
  "kroener": ['K R OW1 N ER0'],
  "kroening": ['K R AA1 AH0 N IH0 NG'],
  "kroenke": ['K R OW1 NG K'],
  "kroes": ['K R OW1 Z'],
  "kroese": ['K R OW1 S'],
  "kroeze": ['K R OW1 Z'],
  "kroft": ['K R AA1 F T'],
  "krog": ['K R AA1 G'],
  "kroger": ['K R OW1 G ER0'],
  "kroger's": ['K R OW1 G ER0 Z'],
  "krogh": ['K R OW1'],
  "krogman": ['K R AA1 G M AH0 N'],
  "krogstad": ['K R AA1 G S T AH0 D'],
  "kroh": ['K R OW1'],
  "krohn": ['K R OW1 N'],
  "krok": ['K R AA1 K'],
  "krol": ['K R AO1 L'],
  "krolak": ['K R OW1 L AH0 K'],
  "krolczyk": ['K R OW1 L CH IH0 K'],
  "krolick": ['K R AA1 L IH0 K'],
  "krolikowski": ['K R AH0 L IH0 K AO1 F S K IY0'],
  "kroll": ['K R AO1 L'],
  "krom": ['K R AA1 M'],
  "krome": ['K R OW1 M'],
  "kromer": ['K R OW1 M ER0'],
  "kromm": ['K R AA1 M'],
  "kron": ['K R AA1 N'],
  "krona": ['K R OW1 N AH0'],
  "kronberg": ['K R AA1 N B ER0 G'],
  "krone": ['K R OW1 N AH0'],
  "kronen": ['K R OW1 N AH0 N'],
  "kronenberg": ['K R AA1 N AH0 N B ER0 G'],
  "kronenberger": ['K R AA1 N AH0 N B ER0 G ER0'],
  "kroner": ['K R OW1 N ER0'],
  "kronick": ['K R AA1 N IH0 K'],
  "kronish": ['K R AA1 N IH0 SH'],
  "kronk": ['K R AA1 NG K'],
  "kronor": ['K R OW1 N ER0'],
  "kronos": ['K R OW1 N OW0 S'],
  "krontz": ['K R AA1 N T S'],
  "kroon": ['K R UW1 N'],
  "kropf": ['K R AA1 P F'],
  "kropp": ['K R AA1 P'],
  "kross": ['K R AO1 S'],
  "krossel": ['K R AO1 S AH0 L'],
  "krotkov": ['K R AO1 T K AO0 V'],
  "krotz": ['K R AA1 T S'],
  "krotzer": ['K R OW1 T Z ER0'],
  "krouner": ['K R UW1 N ER0'],
  "kroupa": ['K R UW1 P AH0'],
  "krouse": ['K R AW1 S'],
  "krout": ['K R AW1 T'],
  "krowe": ['K R OW1'],
  "krowitz": ['K R AW1 IH0 T S'],
  "kroy": ['K R OY1'],
  "kruchten": ['K R AH1 K T AH0 N'],
  "kruck": ['K R AH1 K'],
  "kruckeberg": ['K R AH1 K B ER0 G'],
  "kruckenberg": ['K R AH1 K AH0 N B ER0 G'],
  "kruczek": ['K R AH1 CH EH0 K'],
  "krudman": ['K R AH1 D M AH0 N'],
  "krudman's": ['K R AH1 D M AH0 N Z'],
  "krueger": ['K R UW1 G ER0'],
  "kruer": ['K R UW1 ER0'],
  "krug": ['K R AH1 G'],
  "kruger": ['K R UW1 G ER0'],
  "krugerrand": ['K R UW0 G EH1 R AE0 N D'],
  "krugh": ['K R AH1'],
  "krugman": ['K R AH1 G M AH0 N'],
  "kruk": ['K R AH1 K'],
  "krukowski": ['K R AH0 K AO1 F S K IY0'],
  "krul": ['K R AH1 L'],
  "krull": ['K R AH1 L'],
  "krulwich": ['K R AH1 L W IH0 CH'],
  "krum": ['K R AH1 M'],
  "krumholz": ['K R AH1 M HH OW2 L Z'],
  "krumm": ['K R AH1 M'],
  "krumme": ['K R AH1 M'],
  "krummel": ['K R AH1 M AH0 L'],
  "krummel's": ['K R AH1 M AH0 L Z'],
  "krumrey": ['K R AH1 M R IY0'],
  "krumwiede": ['K R AH1 M W IY2 D'],
  "krupa": ['K R UW1 P AH0'],
  "krupicka": ['K R UW2 P IH1 K AH0'],
  "krupinski": ['K R AH0 P IH1 N S K IY0'],
  "krupka": ['K R AH1 P K AH0'],
  "krupman": ['K R AH1 P M AH0 N'],
  "krupnick": ['K R AH1 P N IH0 K'],
  "krupp": ['K R AH1 P'],
  "krupp's": ['K R AH1 P S'],
  "kruppa": ['K R AH1 P AH0'],
  "krupski": ['K R AH1 P S K IY0'],
  "krus": ['K R AH1 S'],
  "kruschev": ['K R UW1 S CH EH2 V'],
  "kruschke": ['K R AH1 SH K'],
  "kruse": ['K R UW1 Z'],
  "krusemark": ['K R AH1 S IH0 M AA0 R K'],
  "krusinski": ['K R AH0 S IH1 N S K IY0'],
  "kruszewski": ['K R AH0 SH EH1 F S K IY0'],
  "kruszka": ['K R AH1 SH K AH0'],
  "kruszynski": ['K R AH0 SH IH1 N S K IY0'],
  "krutick": ['K R UW1 T IH2 K'],
  "krutsinger": ['K R AH1 T S IH0 N JH ER0'],
  "kruttschnitt": ['K R AH1 CH N IH0 T'],
  "krutz": ['K R AH1 T S'],
  "kruzel": ['K R UW1 Z AH0 L'],
  "krych": ['K R IH1 CH'],
  "kryder": ['K R AY1 D ER0'],
  "kryger": ['K R AY1 G ER0'],
  "krygier": ['K R AY1 G IY0 ER0'],
  "krypton": ['K R IH1 P T AA0 N'],
  "kryptonian": ['K R IH0 P T OW1 N IY2 AH0 N'],
  "kryptonite": ['K R IH1 P T AH0 N AY2 T'],
  "kryptos": ['K R IH1 P T OW0 S'],
  "krysiak": ['K R IH1 S IY0 AE0 K'],
  "krystal": ['K R IH1 S T AH0 L'],
  "krzeminski": ['K R IH0 M IH1 N S K IY0'],
  "krzysztof": ['K R IY1 S T AO0 F', 'K R IY1 SH T AO0 F'],
  "krzywicki": ['K R IH0 V IH1 T S K IY0'],
  "krzyzanowski": ['K R IH0 Z AH0 N AO1 F S K IY0'],
  "ksiazek": ['K S Y AA1 Z EH0 K'],
  "ku": ['K UW1'],
  "kuala": ['K W AA1 L AH0'],
  "kuan": ['K W AA1 N'],
  "kuba": ['K Y UW1 B AH0'],
  "kubacki": ['K AH0 B AA1 T S K IY0'],
  "kubala": ['K AH0 B AA1 L AH0', 'K UW0 B AA1 L AH0'],
  "kuban": ['K Y UW1 B AH0 N'],
  "kubas": ['K UW1 B AH0 Z'],
  "kubat": ['K UW1 B AH0 T'],
  "kube": ['K Y UW1 B'],
  "kubena": ['K AH0 B IY1 N AH0'],
  "kuberski": ['K UW0 B ER1 S K IY0'],
  "kubes": ['K UW1 B EH2 S'],
  "kubiak": ['K UW1 B IY0 AE0 K'],
  "kubic": ['K Y UW1 B IH0 K'],
  "kubica": ['K Y UW1 B IH0 K AH0'],
  "kubicek": ['K AH1 B IH0 CH EH0 K'],
  "kubick": ['K Y UW1 B IH0 K'],
  "kubicki": ['K AH0 B IH1 T S K IY0'],
  "kubik": ['K Y UW1 B IH0 K'],
  "kubin": ['K Y UW1 B IH0 N'],
  "kubinski": ['K AH0 B IH1 N S K IY0'],
  "kubis": ['K UW1 B IH0 S'],
  "kubisiak": ['K AH0 B IH1 S IY0 AE0 K'],
  "kubitz": ['K Y UW1 B IH0 T S'],
  "kubler": ['K Y UW1 B AH0 L ER0', 'K Y UW1 B L ER0'],
  "kubly": ['K AH1 B L IY0'],
  "kubo": ['K Y UW1 B OW0'],
  "kubota": ['K UW0 B OW1 T AH0'],
  "kubrick": ['K Y UW1 B R IH2 K'],
  "kubrick's": ['K Y UW1 B R IH2 K S'],
  "kuby": ['K Y UW1 B IY0'],
  "kuc": ['K AH1 K'],
  "kucan": ['K Y UW1 K AH0 N'],
  "kucera": ['K AH0 CH IH1 R AH0'],
  "kucewicz": ['K Y UW1 S IH0 W IH0 T S'],
  "kuch": ['K AH1 CH'],
  "kuchar": ['K AH1 K ER0'],
  "kucharski": ['K AH0 CH AA1 R S K IY0'],
  "kuchenbecker": ['K AH1 K IH0 N B EH0 K ER0'],
  "kucher": ['K AH1 K ER0'],
  "kuchera": ['K AH1 CH ER0 AH0', 'K UW2 CH EH1 R AH0'],
  "kuchinski": ['K AH0 CH IH1 N S K IY0'],
  "kuchinsky": ['K AH0 CH IH1 N S K IY0'],
  "kuchler": ['K AH1 K AH0 L ER0', 'K AH1 K L ER0'],
  "kuchma": ['K UW1 CH M AH0'],
  "kuchma's": ['K UW1 CH M AH0 Z'],
  "kuchta": ['K AH1 CH T AH0'],
  "kucinski": ['K AH0 CH IH1 N S K IY0'],
  "kuck": ['K AH1 K'],
  "kuczek": ['K AH1 CH EH0 K'],
  "kuczynski": ['K AH0 CH IH1 N S K IY0'],
  "kuder": ['K Y UW1 D ER0'],
  "kudla": ['K AH1 D L AH0'],
  "kudlow": ['K AH1 D L OW0'],
  "kudner": ['K AH1 D N ER0'],
  "kudos": ['K UW1 D OW0 S'],
  "kudrna": ['K AH2 D ER1 N AH0'],
  "kudzu": ['K AH1 D Z UW0'],
  "kuebler": ['K UH1 B AH0 L ER0', 'K UH1 B L ER0'],
  "kuechler": ['K UH1 K AH0 L ER0', 'K UH1 K L ER0'],
  "kueck": ['K UW1 K'],
  "kuecker": ['K UH1 K ER0'],
  "kuehl": ['K UH1 L'],
  "kuehler": ['K UH1 L ER0'],
  "kuehn": ['K UW1 N'],
  "kuehne": ['K UW1 N'],
  "kuehnel": ['K UH1 N AH0 L'],
  "kuehner": ['K UH1 N ER0'],
  "kuehnle": ['K UH1 N AH0 L'],
  "kueker": ['K UH1 K ER0'],
  "kuenheim": ['K Y UW1 AH0 N HH AY2 M'],
  "kuennen": ['K UH1 N AH0 N'],
  "kuenstler": ['K UH1 N S AH0 L ER0', 'K UH1 N S L ER0'],
  "kuenzel": ['K UH1 N Z AH0 L'],
  "kuenzi": ['K UW0 EY1 N Z IY0'],
  "kuenzli": ['K UH1 N Z L IY0'],
  "kuester": ['K UH1 S T ER0'],
  "kuether": ['K UH1 DH ER0'],
  "kufahl": ['K AH1 F AA0 L'],
  "kuffel": ['K AH1 F AH0 L'],
  "kuffner": ['K AH1 F N ER0'],
  "kugel": ['K UW1 G AH0 L'],
  "kugelman": ['K AH1 G AH0 L M AH0 N'],
  "kugler": ['K UW1 G AH0 L ER0', 'K UW1 G L ER0'],
  "kuhar": ['K UW1 ER0'],
  "kuhl": ['K AH1 L'],
  "kuhle": ['K UW1 AH0 L'],
  "kuhlenschmidt": ['K Y UW1 L AH0 N SH M IH2 T'],
  "kuhlman": ['K UW1 L M AH0 N'],
  "kuhlmann": ['K UW1 L M AH0 N'],
  "kuhn": ['K UW1 N'],
  "kuhne": ['K AH1 N'],
  "kuhner": ['K UW1 N ER0'],
  "kuhnert": ['K UW1 N ER0 T'],
  "kuhnke": ['K AH1 NG K'],
  "kuhnle": ['K AH1 N AH0 L'],
  "kuhns": ['K UW1 N Z'],
  "kuhr": ['K ER1'],
  "kuhrt": ['K ER1 T'],
  "kuiken": ['K UW1 K AH0 N'],
  "kuiper": ['K UW1 P ER0'],
  "kuipers": ['K UW1 P ER0 Z'],
  "kujala": ['K AH0 Y AA1 L AH0'],
  "kujawa": ['K UW0 JH AA1 W AH0'],
  "kujawski": ['K AH0 Y AA1 F S K IY0'],
  "kuk": ['K AH1 K'],
  "kuker": ['K Y UW1 K ER0'],
  "kukje": ['K UW1 K Y IH0', 'K UW1 K JH EY2', 'K UW1 K JH IY2'],
  "kukla": ['K AH1 K L AH0'],
  "kuklinski": ['K AH0 K L IH1 N S K IY0'],
  "kukowski": ['K AH0 K AO1 F S K IY0'],
  "kukuk": ['K UW1 K AH0 K'],
  "kula": ['K UW1 L AH0'],
  "kulaga": ['K UW0 L AA1 G AH0'],
  "kulakowski": ['K Y UW0 L AH0 K AO1 F S K IY0'],
  "kulas": ['K Y UW1 L AH0 Z'],
  "kulesa": ['K Y UW0 L IY1 S AH0'],
  "kulesza": ['K Y UW0 L EH1 SH AH0'],
  "kulhanek": ['K AH1 L HH AH0 N EH0 K'],
  "kulich": ['K Y UW1 L IH0 K'],
  "kulick": ['K Y UW1 L IH0 K'],
  "kulig": ['K Y UW1 L IH0 G'],
  "kuligowski": ['K Y UW0 L IH0 G AO1 F S K IY0'],
  "kulik": ['K Y UW1 L IH0 K'],
  "kulikowski": ['K Y UW0 L IH0 K AO1 F S K IY0'],
  "kulinski": ['K Y UW0 L IH1 N S K IY0'],
  "kulis": ['K UW1 L IH0 S'],
  "kulish": ['K Y UW1 L IH0 SH'],
  "kulka": ['K AH1 L K AH0'],
  "kulkarni": ['K AH0 L K AA1 R N IY0'],
  "kull": ['K AH1 L'],
  "kulla": ['K AH1 L AH0'],
  "kullberg": ['K AH1 L B ER0 G'],
  "kullman": ['K AH1 L M AH0 N'],
  "kully": ['K AH1 L IY0'],
  "kulon": ['K UW1 L AO2 N'],
  "kulow": ['K Y UW1 L OW0'],
  "kulp": ['K AH1 L P'],
  "kulpa": ['K AH1 L P AH0'],
  "kulwicki": ['K AH0 L V IH1 T S K IY0'],
  "kulzer": ['K AH1 L Z ER0'],
  "kumagai": ['K UW0 M AA0 G AA1 IY0'],
  "kumar": ['K UW0 M AA1 R'],
  "kumaratunga": ['K UW0 M AA2 R AH0 T AH1 N G AH0'],
  "kumarisami": ['K UW0 M AA2 R AH0 S AA1 M IY0'],
  "kumbaya": ['K UH2 M B AY0 Y AH1'],
  "kumble": ['K AH1 M B AH0 L'],
  "kume": ['K Y UW1 M'],
  "kumho": ['K AH1 M HH OW0'],
  "kumler": ['K AH1 M L ER0'],
  "kumm": ['K AH1 M'],
  "kummer": ['K AH1 M ER0'],
  "kumouri": ['K UW2 M AO1 R IY0'],
  "kumouri's": ['K UW2 M AO1 R IY0 Z'],
  "kump": ['K AH1 M P'],
  "kumpf": ['K AH1 M P F'],
  "kumquat": ['K AH1 M K W AA0 T'],
  "kun": ['K AH1 N'],
  "kuna": ['K Y UW1 N AH0'],
  "kunaev": ['K Y UW0 N EY1 V'],
  "kunath": ['K AH1 N AH0 TH'],
  "kunayev": ['K Y UW0 N EY1 AH0 V'],
  "kunce": ['K AH1 N S'],
  "kunda": ['K AH1 N D AH0'],
  "kunde": ['K AH1 N D'],
  "kundera": ['K AH0 N D EH1 R AH0'],
  "kundert": ['K AH1 N D ER0 T'],
  "kundinger": ['K AH1 N D IH0 NG ER0'],
  "kundrat": ['K AH1 N D R AH0 T'],
  "kundun": ['K UH1 N D AH0 N'],
  "kunert": ['K Y UW1 N ER0 T'],
  "kunes": ['K Y UW1 N Z'],
  "kunesh": ['K AH1 N IH0 SH'],
  "kuney": ['K Y UW1 N IY0'],
  "kung": ['K AH1 NG'],
  "kunin": ['K Y UW1 N IH0 N'],
  "kunio": ['K Y UW1 N IY0 OW0'],
  "kunka": ['K AH1 NG K AH0'],
  "kunkel": ['K AH1 NG K AH0 L'],
  "kunkle": ['K AH1 NG K AH0 L'],
  "kunkler": ['K AH1 NG K L ER0'],
  "kuns": ['K AH1 N Z'],
  "kunselman": ['K AH1 N S AH0 L M AH0 N'],
  "kunsman": ['K AH1 N S M AH0 N'],
  "kunst": ['K AH1 N S T'],
  "kunstler": ['K AH1 N S T L ER0', 'K AH1 N S L ER0'],
  "kuntsler": ['K AH1 N T S L ER0'],
  "kuntz": ['K AH1 N T S'],
  "kuntze": ['K AH1 N T Z'],
  "kuntzman": ['K AH1 N T S M AH0 N'],
  "kunz": ['K AH1 N Z'],
  "kunze": ['K AH1 N Z'],
  "kunzelman": ['K AH1 N Z AH0 L M AH0 N'],
  "kunzler": ['K AH1 N Z L ER0'],
  "kunzman": ['K AH1 N Z M AH0 N'],
  "kuo": ['K UW1 OW0'],
  "kuomintang": ['K W OW1 M IH2 N T AE1 NG', 'G W OW1 M IH2 N T AE1 NG'],
  "kuomintang's": ['K W OW1 M IH2 N T AE1 NG Z', 'G W OW1 M IH2 N T AE1 NG Z'],
  "kuow": ['K Y UW1 OW0'],
  "kupek": ['K UW1 P IH0 K'],
  "kuper": ['K Y UW1 P ER0'],
  "kuperman": ['K UW1 P ER0 M AH0 N'],
  "kupfer": ['K AH1 P F ER0'],
  "kupferman": ['K AH1 P F ER0 M AH0 N'],
  "kupiec": ['K AH1 P IY0 K'],
  "kupka": ['K AH1 P K AH0'],
  "kupor": ['K Y UW1 P ER0'],
  "kupper": ['K AH1 P ER0'],
  "kupres": ['K UW1 P R AH0 S'],
  "kuralt": ['K Y ER0 AO1 L T'],
  "kuralt's": ['K Y ER0 AO1 L T S'],
  "kuramoto": ['K UH0 R AA0 M OW1 T OW0'],
  "kuranari": ['K UH2 R AH0 N AA1 R IY0'],
  "kuras": ['K UH1 R AH0 Z'],
  "kurd": ['K ER1 D'],
  "kurdish": ['K ER1 D IH0 SH'],
  "kurdistan": ['K ER1 D IH0 S T AE2 N'],
  "kurds": ['K ER1 D Z'],
  "kurdziel": ['K ER1 D Z IY0 L'],
  "kurek": ['K Y UW1 R EH0 K'],
  "kurian": ['K Y UH1 R IY0 AH0 N'],
  "kuriansky": ['K UH2 R IY0 AE1 N S K IY0'],
  "kurihara": ['K UW2 R IH0 HH AA1 R AH0'],
  "kuril": ['K ER0 AH0 L'],
  "kurilla": ['K ER0 IH1 L AH0'],
  "kurinsky": ['K Y UW2 R IH1 N S K IY0'],
  "kuriyama": ['K UW0 R IH0 Y AA1 M AH0'],
  "kurk": ['K ER1 K'],
  "kurka": ['K ER1 K AH0'],
  "kurkjian": ['K ER1 K JH IY0 AH0 N'],
  "kurkowski": ['K ER0 K AO1 F S K IY0'],
  "kurlak": ['K ER1 L AE0 K'],
  "kurland": ['K ER1 L AH0 N D'],
  "kurlander": ['K ER1 L AH0 N D ER0'],
  "kurman": ['K ER1 M AH0 N'],
  "kurmel": ['K ER1 M AH0 L'],
  "kurnit": ['K ER1 N IH0 T'],
  "kurnool": ['K ER2 N UW1 L'],
  "kurnul": ['K ER2 N UH1 L'],
  "kuroda": ['K ER0 OW1 D AH0'],
  "kurokawa": ['K UW2 R OW0 K AA1 W AH0'],
  "kurosawa": ['K UH2 R OW0 S AA1 W AH0'],
  "kurowski": ['K ER0 AO1 F S K IY0'],
  "kurshikov": ['K ER1 SH IH0 K AO0 V'],
  "kurt": ['K ER1 T'],
  "kurtenbach": ['K ER1 T IH0 N B AA0 K'],
  "kurth": ['K ER1 TH'],
  "kurtis": ['K ER1 T IH0 S'],
  "kurtz": ['K ER1 T S'],
  "kurtzman": ['K ER1 T S M AH0 N'],
  "kuruman": ['K Y UH1 R UW0 M AH0 N'],
  "kurumizowa": ['K UH2 R UW0 M IY0 Z OW1 AH0'],
  "kurumizowa's": ['K UH2 R UW0 M IY0 Z OW1 AH0 Z'],
  "kury": ['K Y UW1 R IY0'],
  "kurylo": ['K ER0 IH1 L OW0'],
  "kurz": ['K ER1 Z'],
  "kurzawa": ['K UH0 R Z AA1 W AH0'],
  "kurzban": ['K ER1 Z B AE2 N'],
  "kurzweil": ['K ER0 Z W AY1 L'],
  "kus": ['K AH1 S'],
  "kusa": ['K UW1 Z AH0', 'K UW1 S AH0'],
  "kusch": ['K AH1 SH'],
  "kuschel": ['K AH1 SH AH0 L'],
  "kuse": ['K Y UW1 Z'],
  "kusek": ['K UW1 S EH0 K'],
  "kusel": ['K UW1 S AH0 L'],
  "kush": ['K UH1 SH'],
  "kushner": ['K AH1 SH N ER0'],
  "kushnir": ['K AH1 SH N ER0'],
  "kusiak": ['K AH1 S IY0 AE0 K'],
  "kuske": ['K AH1 S K'],
  "kusler": ['K AH1 S AH0 L ER0', 'K AH1 S L ER0'],
  "kuss": ['K AH1 S'],
  "kusserow": ['K AH1 S ER0 OW0'],
  "kussman": ['K AH1 S M AH0 N'],
  "kuster": ['K AH1 S T ER0'],
  "kustra": ['K AH1 S T R AH0'],
  "kut": ['K AH1 T'],
  "kutch": ['K AH1 CH'],
  "kutcher": ['K AH1 CH ER0'],
  "kutchna": ['K AH1 CH N AH0'],
  "kuter": ['K Y UW1 T ER0'],
  "kutner": ['K AH1 T N ER0'],
  "kutsch": ['K AH1 CH'],
  "kutscher": ['K AH1 CH ER0'],
  "kuttab": ['K UW1 T AE2 B'],
  "kutter": ['K AH1 T ER0'],
  "kuttner": ['K AH1 T N ER0'],
  "kutuzovsky": ['K UW2 T AH0 Z AA1 V S K IY0'],
  "kutz": ['K AH1 T S'],
  "kutzer": ['K AH1 T Z ER0'],
  "kuvin": ['K UW1 V IH0 N'],
  "kuwahara": ['K UW2 W AA0 HH AA1 R AH0'],
  "kuwait": ['K UW0 W EY1 T'],
  "kuwait's": ['K UW0 W EY1 T S'],
  "kuwaiti": ['K UW0 W EY1 T IY0'],
  "kuwaitis": ['K UW0 W EY1 T IY0 Z'],
  "kuwam": ['K Y UW1 W AA0 M'],
  "kuykendall": ['K AY1 K EH0 N D AA2 L'],
  "kuyper": ['K AY1 P ER0'],
  "kuzara": ['K Y UW0 Z AA1 R AH0'],
  "kuze": ['K Y UW1 Z'],
  "kuzel": ['K UW1 Z AH0 L'],
  "kuzma": ['K AH1 Z M AH0'],
  "kuzminski": ['K AH0 Z M IH1 N S K IY0'],
  "kuznets": ['K AH1 Z N EH2 T S'],
  "kuznia": ['K AH1 Z N IY0 AH0'],
  "kuzniar": ['K AH1 Z N Y ER0'],
  "kuznicki": ['K AH0 Z N IH1 T S K IY0'],
  "kvale": ['K V EY1 L'],
  "kvam": ['K V AE1 M'],
  "kvamme": ['K AH0 V AE1 M', 'K V AA1 M EY0'],
  "kvant": ['K V AA1 N T'],
  "kvasnicka": ['K V AH0 S N IH1 S K AH0'],
  "kvetch": ['K V EH1 CH'],
  "kveton": ['K V EH1 T AO0 N'],
  "kvistad": ['K V IH1 S T AE2 D'],
  "kvitsinsky": ['K V IH0 T S IH1 N S K IY0'],
  "kwai": ['K W AY1'],
  "kwak": ['K W AE1 K'],
  "kwan": ['K W AA1 N'],
  "kwang": ['K W AA1 NG'],
  "kwangju": ['K W AA0 NG JH UW1', 'G W AA0 NG JH UW1'],
  "kwanza": ['K W AA1 N Z AH0'],
  "kwanzaa": ['K W AA1 N Z AH0'],
  "kwasniewski": ['K W AH0 Z N EH1 F S K IY0', 'K W AH0 Z N UW1 S K IY0'],
  "kwasnik": ['K W AA1 Z N IH0 K'],
  "kwasny": ['K W AA1 Z N IY0'],
  "kwazulu": ['K W AA0 Z UW1 L UW0'],
  "kweisi": ['K W AY1 Z IY0'],
  "kwh": ['K EY1 D AH1 B AH0 L Y UW0 EY1 CH'],
  "kwiatek": ['K W IY0 AA1 T EH0 K'],
  "kwiatkowski": ['K W IY0 AH0 T K AO1 F S K IY0'],
  "kwiecien": ['K W IY1 S IY0 N'],
  "kwiecinski": ['K W IY0 CH IH1 N S K IY0'],
  "kwik": ['K W IH1 K'],
  "kwitny": ['K W IH1 T N IY0'],
  "kwok": ['K W AA1 K'],
  "kwok-shing": ['K W AO1 K SH IH1 NG'],
  "kwolek": ['K W OW1 L EH0 K'],
  "kwon": ['K W AA1 N'],
  "kwong": ['K W AO1 NG'],
  "kyd": ['K IH1 D'],
  "kyd's": ['K IH1 D Z'],
  "kyer": ['K AY1 ER0'],
  "kyes": ['K AY1 Z'],
  "kyger": ['K AY1 G ER0'],
  "kyi": ['K IY1', 'K EY1 W AY1 AY1'],
  "kyker": ['K AY1 K ER0'],
  "kyl": ['K AY1 L'],
  "kyle": ['K AY1 L'],
  "kyle's": ['K AY1 L Z'],
  "kyler": ['K AY1 L ER0'],
  "kyles": ['K AY1 L Z'],
  "kylie": ['K AY1 L IY2'],
  "kyllo": ['K IH1 L OW0'],
  "kyllonen": ['K IH0 L AA1 N AH0 N'],
  "kym": ['K IH1 M'],
  "kyna": ['K IH1 N AH0'],
  "kynard": ['K IH1 N ER0 D'],
  "kyne": ['K AY1 N'],
  "kynikos": ['K IH0 N IY1 K OW0 S'],
  "kyo": ['K Y OW1', 'K IY1 OW0'],
  "kyocera": ['K Y OW0 S EH1 R AH0', 'K IY0 OW0 S EH1 R AH0'],
  "kyodo": ['K Y OW1 D OW0'],
  "kyoko": ['K IY0 OW1 K OW0'],
  "kyokuto": ['K Y AA0 K UW1 T OW0'],
  "kyong": ['K Y AO1 NG'],
  "kyongsang": ['K Y AO1 NG S AE0 NG'],
  "kyoshi": ['K IY0 OW1 SH IY0'],
  "kyoto": ['K Y OW1 T OW0'],
  "kyoung": ['K Y AO1 NG'],
  "kyoung-min": ['K Y AO1 NG M IH1 N'],
  "kyowa": ['K Y OW1 AH0'],
  "kyra": ['K AY1 R AH0'],
  "kyrgyzstan": ['K IH1 R G IH0 S T AE2 N', 'K IH2 R G IY1 S T AA2 N'],
  "kyron": ['K AY1 R AH0 N'],
  "kysar": ['K IH1 S ER0'],
  "kyser": ['K AY1 S ER0'],
  "kysor": ['K AY1 S ER0'],
  "kyte": ['K AY1 T'],
  "kytril": ['K IH1 T R IH0 L'],
  "kyu": ['K Y UW1'],
  "kyung": ['K Y AH1 NG'],
  "kyushu": ['K Y AH1 SH UW0'],
  "kyzar": ['K IH1 Z ER0'],
  "kyzer": ['K AY1 Z ER0'],
  "l": ['EH1 L'],
  "l'alma": ['L AA1 L M AH0'],
  "l'amour": ['L AE1 M AO0 R'],
  "l'eggs": ['L EH1 G Z'],
  "l'enfant": ['L EH1 N F AA2 N T', 'L AA2 N F AA1 N T'],
  "l'espalier": ['L EH0 S P AE2 L IY0 EY1'],
  "l'expansion": ['L EH2 K S P AE1 N SH AH0 N'],
  "l'express": ['L EH2 K S P R EH1 S'],
  "l'heureux": ['L HH Y UW2 R UH1'],
  "l'oreal": ['L AO0 R IY0 AE1 L'],
  "l's": ['EH1 L Z'],
  "l.": ['EH1 L'],
  "l.'s": ['EH1 L Z'],
  "l.s": ['EH1 L Z'],
  "la": ['L AA1'],
  "la-carre": ['L AA1 K AA2 R EY1'],
  "la-crosse": ['L AH0 K R AO1 S'],
  "la-guardia": ['L AH0 G W AA1 R D IY0 AH0'],
  "la-nina": ['L AH0 N IY1 N Y AH0'],
  "la-paz": ['L AH1 P AO0 Z'],
  "la-quinta": ['L AH0 K W IH1 N T AH0'],
  "laabs": ['L AA1 B Z'],
  "laack": ['L AA1 K'],
  "laaco": ['L AA1 K OW0'],
  "laake": ['L AA1 K'],
  "laakso": ['L AA1 K S OW0'],
  "laas": ['L AA1 Z'],
  "laatsch": ['L AA1 CH'],
  "lab": ['L AE1 B'],
  "lab's": ['L AE1 B Z'],
  "laba": ['L AA1 B AH0'],
  "labadie": ['L AE1 B AH0 D IY0'],
  "laban": ['L EY1 B AH0 N'],
  "laband": ['L AA0 B AE1 N D'],
  "labant": ['L AH0 B AE1 N T'],
  "labar": ['L AH0 B AA1 R'],
  "labarbera": ['L AA0 B AA0 R B EH1 R AH0'],
  "labarge": ['L AE1 B AA0 R JH'],
  "labarr": ['L AH0 B AE1 R'],
  "labarre": ['L AA0 B AA1 R EY0'],
  "labat": ['L AA1 B AA0 T'],
  "labate": ['L AA1 B EY0 T'],
  "labaton": ['L AE1 B AH0 T AH0 N'],
  "labatt": ['L AH0 B AE1 T'],
  "labatt's": ['L AH0 B AE1 T S'],
  "labauve": ['L AH0 B OW1 V'],
  "labay": ['L AE1 B EY0'],
  "labbe": ['L AE1 B'],
  "labe": ['L EY1 B'],
  "labeau": ['L AH0 B OW1'],
  "label": ['L EY1 B AH0 L'],
  "label's": ['L EY1 B AH0 L Z'],
  "labeled": ['L EY1 B AH0 L D'],
  "labeling": ['L EY1 B AH0 L IH0 NG', 'L EY1 B L IH0 NG'],
  "labell": ['L AH0 B EH1 L'],
  "labella": ['L AH0 B EH1 L AH0'],
  "labelle": ['L AH0 B EH1 L'],
  "labelled": ['L EY1 B AH0 L D'],
  "labels": ['L EY1 B AH0 L Z'],
  "labenski": ['L AA0 B EH1 N S K IY0'],
  "laber": ['L EY1 B ER0'],
  "laberge": ['L AA1 B ER0 G'],
  "labianca": ['L AA0 B IY0 AA1 NG K AH0'],
  "labianca's": ['L AA0 B IY0 AA1 NG K AH0 Z'],
  "labine": ['L AA0 B IY1 N IY0'],
  "lablanc": ['L AH0 B L AE1 NG K'],
  "labo": ['L AA1 B OW0'],
  "laboda": ['L AA0 B OW1 D AH0'],
  "labombard": ['L AE1 B AH0 M B ER0 D'],
  "labonte": ['L AH0 B AA1 N T', 'L AH0 B AA1 N T IY0'],
  "labor": ['L EY1 B ER0'],
  "labor's": ['L EY1 B ER0 Z'],
  "laboratoires": ['L AH0 B AO1 R AH0 T W AA2 Z'],
  "laboratories": ['L AE1 B R AH0 T AO2 R IY0 Z'],
  "laboratories'": ['L AE1 B R AH0 T AO2 R IY0 Z'],
  "laboratory": ['L AE1 B R AH0 T AO2 R IY0'],
  "laboratory's": ['L AE1 B R AH0 T AO2 R IY0 Z'],
  "laborde": ['L AH0 B AO1 R D'],
  "labore": ['L AH0 B AO1 R'],
  "labored": ['L EY1 B ER0 D'],
  "laborer": ['L EY1 B ER0 ER0'],
  "laborers": ['L EY1 B ER0 ER0 Z'],
  "laboring": ['L EY1 B ER0 IH0 NG'],
  "laborious": ['L AH0 B AO1 R IY0 AH0 S'],
  "laboriously": ['L AH0 B AO1 R IY0 AH0 S L IY0'],
  "labors": ['L EY1 B ER0 Z'],
  "labossiere": ['L AE1 B AH0 S IY0 EH0 R'],
  "labouisse": ['L AA0 B UW1 S'],
  "labounty": ['L AH0 B AW1 N T IY0'],
  "labour": ['L EY1 B ER0'],
  "labov": ['L AH0 B AH1 V'],
  "labove": ['L AH0 B AH1 V'],
  "labow": ['L AE1 B OW0'],
  "laboy": ['L AH0 B OY1'],
  "labrador": ['L AE1 B R AH0 D AO2 R'],
  "labradors": ['L AE1 B R AH0 D AO2 R Z'],
  "labrake": ['L AE1 B R AH0 K'],
  "labranche": ['L AA0 B R AA1 N K IY0'],
  "labreck": ['L AE1 B R IH0 K'],
  "labrecque": ['L AH0 B R EH1 K'],
  "labree": ['L AH0 B R IY1'],
  "labrie": ['L AE1 B ER0 IY0'],
  "labrioche": ['L AA2 B R IY0 OW1 SH'],
  "labriola": ['L AA0 B R IY0 OW1 L AH0'],
  "labrosse": ['L AA0 B R OW1 S IY0'],
  "labs": ['L AE1 B Z'],
  "labs'": ['L AE1 B Z'],
  "labuda": ['L AH0 B Y UW1 D AH0'],
  "labus": ['L AE1 B IH0 S'],
  "labyrinth": ['L AE1 B ER0 IH2 N TH'],
  "labyrinthine": ['L AE2 B ER0 IH1 N TH IY2 N'],
  "lac": ['L AE1 K'],
  "lac's": ['L AE1 K S'],
  "lacan": ['L AA0 K AA1 N'],
  "lacana": ['L AA0 K AE1 N AH0'],
  "lacasse": ['L AA0 K AA1 S IY0'],
  "lacava": ['L AA0 K AA1 V AH0'],
  "lacayo": ['L AA0 K EY1 OW0'],
  "lacaze": ['L AA0 K AA1 Z IY0'],
  "laccoliths": ['L AE1 K AH0 L IH0 TH S'],
  "lace": ['L EY1 S'],
  "laced": ['L EY1 S T'],
  "lacefield": ['L EY1 S F IY2 L D'],
  "lacer": ['L EY1 S ER0'],
  "lacerate": ['L AE1 S ER0 EY2 T'],
  "laceration": ['L AE2 S ER0 EY1 SH AH0 N'],
  "lacerations": ['L AE2 S ER0 EY1 SH AH0 N Z'],
  "lacerte": ['L AA0 CH EH1 R T IY0'],
  "laces": ['L EY1 S AH0 Z', 'L EY1 S IH0 Z'],
  "lacewell": ['L EY1 S W EH2 L'],
  "lacey": ['L EY1 S IY0'],
  "lach": ['L AE1 CH'],
  "lachance": ['L AA1 CH AH0 N S'],
  "lachapelle": ['L AE1 SH AH0 P AH0 L'],
  "lacharite": ['L AE1 CH ER0 AY2 T'],
  "lache": ['L AE1 CH'],
  "lachenbruch": ['L AE1 K AH0 N B R UW2 K'],
  "lacher": ['L AE1 K ER0'],
  "lachica": ['L AE1 CH IH0 K AH0'],
  "lachlan": ['L AE1 K L AH0 N'],
  "lachman": ['L AE1 K M AH0 N'],
  "lachmar": ['L AE1 K M AA0 R'],
  "lachney": ['L AE1 K N IY0'],
  "lachowicz": ['L AA1 HH AH0 V IH0 CH'],
  "lachrymose": ['L AE1 K R IY0 M OW2 Z'],
  "lacina": ['L AA0 CH IY1 N AH0'],
  "lacivita": ['L AA0 CH IY0 V IY1 T AH0'],
  "lack": ['L AE1 K'],
  "lackadaisical": ['L AE2 K AH0 D EY1 Z IH0 K AH0 L'],
  "lackawanna": ['L AE2 K AH0 W AA1 N AH0'],
  "lacked": ['L AE1 K T'],
  "lackey": ['L AE1 K IY0'],
  "lackeys": ['L AE1 K IY0 Z'],
  "lackie": ['L AE1 K IY0'],
  "lacking": ['L AE1 K IH0 NG'],
  "lackluster": ['L AE1 K L AH2 S T ER0'],
  "lackman": ['L AE1 K M AH0 N'],
  "lackner": ['L AE1 K N ER0'],
  "lacko": ['L AE1 K OW0'],
  "lackovic": ['L AE1 K AH0 V IH0 K'],
  "lacks": ['L AE1 K S'],
  "lacksadaiscious": ['L AE2 K S AH0 D EY1 S IY0 AH0 S'],
  "laclair": ['L AE1 K L ER0'],
  "laclede": ['L AA0 K L IY1 D'],
  "lacock": ['L AE1 K AH0 K'],
  "lacomb": ['L AE1 K AH0 M'],
  "lacombe": ['L AA0 K OW1 M B IY0'],
  "laconic": ['L AA0 K AA1 N IH0 K'],
  "laconte": ['L AA0 K OW1 N T IY0'],
  "lacorte": ['L AA0 K AO1 R T IY0'],
  "lacoss": ['L AH0 K AA1 S'],
  "lacosse": ['L AA0 K OW1 S IY0'],
  "lacoste": ['L AA0 K AO1 S T'],
  "lacount": ['L AH0 K UW1 N T'],
  "lacour": ['L AH0 K UH1 R'],
  "lacourse": ['L AH0 K UH1 R S'],
  "lacoursiere": ['L AE1 K UH0 R S IY0 EH0 R'],
  "lacouture": ['L AE1 K UW0 CH ER0'],
  "lacovara": ['L AA0 K OW0 V AA1 R AH0'],
  "lacquer": ['L AE1 K ER0'],
  "lacquered": ['L AE1 K ER0 D'],
  "lacroix": ['L AH0 K R OY1'],
  "lacross": ['L AH0 K R AO1 S'],
  "lacrosse": ['L AH0 K R AO1 S'],
  "lactase": ['L AE1 K T EY2 S'],
  "lactate": ['L AE1 K T EY0 T'],
  "lactating": ['L AE1 K T EY0 T IH0 NG'],
  "lactation": ['L AE0 K T EY1 SH AH0 N'],
  "lacteals": ['L AE1 K T IY2 L Z'],
  "lacter": ['L AE1 K T ER0'],
  "lactic": ['L AE1 K T IH0 K'],
  "lactobacillus": ['L AE2 K T OW0 B AH0 S IH1 L AH0 S'],
  "lactone": ['L AE1 K T OW0 N'],
  "lactose": ['L AE1 K T OW0 S'],
  "lacy": ['L EY1 S IY0'],
  "lad": ['L AE1 D'],
  "lada": ['L AA1 D AH0'],
  "ladakh": ['L AA1 D AH0 K'],
  "ladas": ['L AA1 D AH0 Z'],
  "ladbroke": ['L AE1 D B R OW2 K'],
  "ladbroke's": ['L AE1 D B R OW2 K S'],
  "ladd": ['L AE1 D'],
  "ladd's": ['L AE1 D Z'],
  "ladder": ['L AE1 D ER0'],
  "ladders": ['L AE1 D ER0 Z'],
  "lade": ['L EY1 D'],
  "ladehoff": ['L AE1 D AH0 HH AO0 F'],
  "laden": ['L EY1 D AH0 N'],
  "ladenburg": ['L EY1 D AH0 N B ER0 G'],
  "lader": ['L EY1 D ER0'],
  "ladewig": ['L AE1 D UW0 IH0 G'],
  "ladies": ['L EY1 D IY0 Z'],
  "ladies'": ['L EY1 D IY2 Z'],
  "lading": ['L EY1 D IH0 NG'],
  "ladino": ['L AH0 D IY1 N OW0'],
  "ladish": ['L AA0 D IH1 SH'],
  "ladle": ['L EY1 D AH0 L'],
  "ladled": ['L EY1 D AH0 L D'],
  "ladles": ['L EY1 D AH0 L Z'],
  "ladley": ['L AE1 D L IY0'],
  "ladling": ['L EY1 D L IH0 NG'],
  "ladner": ['L AE1 D N ER0'],
  "ladnier": ['L AE1 D N IY0 ER0'],
  "ladouceur": ['L AE1 D UW0 S ER0'],
  "ladow": ['L AE1 D OW0'],
  "lads": ['L AE1 D Z'],
  "ladson": ['L AE1 D S AH0 N'],
  "laduca": ['L AA0 D UW1 K AH0'],
  "ladue": ['L AA1 D W EH0'],
  "laduke": ['L AA0 D UW1 K EY0'],
  "ladwig": ['L AE1 D W IH0 G'],
  "lady": ['L EY1 D IY0'],
  "lady's": ['L EY1 D IY0 Z'],
  "ladybird": ['L EY1 D IY0 B ER2 D'],
  "ladybug": ['L EY1 D IY0 B AH2 G'],
  "ladybugs": ['L EY1 D IY0 B AH2 G Z'],
  "ladylike": ['L EY1 D IY0 L AY2 K'],
  "laenderbank": ['L AE1 N D ER0 B AE2 NG K'],
  "laevo": ['L EY1 V OW0'],
  "lafalce": ['L AA0 F AE1 L S IY0', 'L AH0 F AA1 L S'],
  "lafarge": ['L AA0 F AA1 R JH'],
  "lafata": ['L AA0 F AA1 T AH0'],
  "lafauci": ['L AA0 F AO1 CH IY0'],
  "lafave": ['L AH0 F EY1 V'],
  "lafavor": ['L AE1 F AH0 V ER0'],
  "lafavre": ['L AH0 F EY1 V ER0'],
  "lafayette": ['L AA2 F IY0 EH1 T', 'L AA2 F EY0 EH1 T'],
  "lafer": ['L EY1 F ER0'],
  "laferriere": ['L AE1 F ER0 IY0 EH0 R'],
  "lafever": ['L AE1 F EH0 V ER0'],
  "lafevers": ['L AE1 F EH0 V ER0 Z'],
  "lafeyette": ['L AA2 F IY0 EH1 T'],
  "laff": ['L AE1 F'],
  "laffer": ['L AE1 F ER0'],
  "lafferty": ['L AE1 F ER0 T IY0'],
  "laffey": ['L AE1 F IY0'],
  "laffin": ['L AE1 F IH0 N'],
  "laffitte": ['L AH0 F IH1 T'],
  "laffoon": ['L AH0 F UW1 N'],
  "lafite": ['L AA0 F AY1 T'],
  "lafitte": ['L AH0 F IH1 T'],
  "laflam": ['L AH0 F L AE1 M'],
  "laflamme": ['L AE2 F L AE1 M'],
  "lafleche": ['L AH0 F L EH1 SH'],
  "lafler": ['L EY1 F AH0 L ER0', 'L EY1 F L ER0'],
  "lafleur": ['L AH0 F L ER1'],
  "laflin": ['L AE1 F L IH0 N'],
  "lafoe": ['L AH0 F OW1'],
  "lafollette": ['L AE1 F AH0 L EH0 T'],
  "lafon": ['L AE1 F AH0 N'],
  "lafond": ['L AH0 F AA1 N D'],
  "lafont": ['L AH0 F AA1 N T'],
  "lafontaine": ['L AE1 F AH0 N T EY2 N'],
  "lafontant": ['L AA0 F AA1 N T AH0 N T'],
  "laforce": ['L AH0 F AO1 R S'],
  "laforest": ['L AH0 F AO1 R AH0 S T'],
  "laforge": ['L AH0 F AO1 R G'],
  "laforte": ['L AH0 F AO1 R T'],
  "lafortune": ['L AE1 F ER0 T UW0 N'],
  "lafosse": ['L AH0 F AA1 S'],
  "lafountain": ['L AA2 F AA1 N T EY2 N'],
  "lafountaine": ['L AA2 F AA0 N T EY1 N'],
  "lafoy": ['L AH0 F OY1'],
  "laframboise": ['L AH2 F R AE2 M B W AA1'],
  "lafrance": ['L AH0 F R AE1 N S'],
  "lafreniere": ['L AE1 F R IH0 N IY0 EH0 R'],
  "lafrenz": ['L AE1 F R IH0 N S'],
  "lafuente": ['L AA0 F W EH1 N T EY0'],
  "lag": ['L AE1 G'],
  "lagace": ['L AA0 G AA1 CH IY0'],
  "lagan": ['L AE1 G AH0 N'],
  "lagana": ['L AA0 G AE1 N AH0'],
  "lagarde": ['L AA0 G AA1 R D IY0'],
  "lagardere": ['L AA0 G AA0 R D IH1 R'],
  "lagasse": ['L AA0 G AA1 S IY0'],
  "lagattuta": ['L AA0 G AA0 T UW1 T AH0'],
  "lage": ['L EY1 JH'],
  "lager": ['L AA1 G ER0'],
  "lagerfeld": ['L AA1 G ER0 F EH0 L D', 'L EY1 G ER0 F EH0 L D'],
  "lagergren": ['L EY1 G ER0 G R EH0 N'],
  "lagerquist": ['L EY1 G ER0 K W IH0 S T'],
  "lagerstrom": ['L EY1 G ER0 S T R AH0 M'],
  "lagesse": ['L AE1 G EH0 S'],
  "laggard": ['L AE1 G ER0 D'],
  "laggards": ['L AE1 G ER0 D Z'],
  "lagged": ['L AE1 G D'],
  "lagging": ['L AE1 G IH0 NG'],
  "lagle": ['L EY1 G AH0 L'],
  "lagnado": ['L AA2 G N AA1 D OW0'],
  "lagniappe": ['L AE1 NG AA2 P'],
  "lago": ['L AA1 G OW0'],
  "lagomarsino": ['L AA0 G OW2 M AA0 R S IY1 N OW0'],
  "lagoon": ['L AH0 G UW1 N'],
  "lagoons": ['L AH0 G UW1 N Z'],
  "lagos": ['L EY1 G AO0 S'],
  "lagow": ['L AE1 G OW0'],
  "lagrange": ['L AE1 G R EY2 N JH'],
  "lagreca": ['L AA0 G R EH1 K AH0'],
  "lagroceria": ['L AA0 G R OW2 S ER0 IY1 AH2', 'L AH0 G R OW2 S ER0 IY1 AH2'],
  "lagrone": ['L AA0 G R OW1 N IY0'],
  "lagrow": ['L AE1 G R OW2'],
  "lags": ['L AE1 G Z'],
  "laguardia": ['L AH0 G W AA1 R D IY0 AH0'],
  "lague": ['L AA1 G'],
  "laguna": ['L AH0 G UW1 N AH0'],
  "lagunas": ['L AH0 G UW1 N AH0 Z'],
  "lahaie": ['L AE1 HH IY0', 'L AH0 HH AY1'],
  "laham": ['L AE1 HH AH0 M'],
  "lahaye": ['L AE1 HH EY0'],
  "lahey": ['L EY1 HH IY0'],
  "lahiff": ['L AE1 HH IH0 F'],
  "lahm": ['L AE1 M'],
  "lahman": ['L AA1 M AH0 N'],
  "lahmann": ['L AA1 M AH0 N'],
  "lahn": ['L AE1 N'],
  "lahood": ['L AA1 HH UH0 D'],
  "lahore": ['L AA0 HH AO1 R'],
  "lahr": ['L AA1 R'],
  "lahti": ['L AA1 T IY0'],
  "lahue": ['L AE1 HH Y UW0'],
  "lai": ['L AY1'],
  "laib": ['L EY1 B'],
  "laible": ['L EY1 B AH0 L'],
  "laibowitz": ['L EY1 B AH0 W IH0 T S'],
  "laibrook": ['L EY1 B R UH2 K'],
  "laiche": ['L EY1 CH'],
  "laid": ['L EY1 D'],
  "laidig": ['L EY1 D IH0 G'],
  "laidlaw": ['L EY1 D L AO2'],
  "laidlaw's": ['L EY1 D L AO1 Z'],
  "laidler": ['L EY1 D L ER0'],
  "laidley": ['L EY1 D L IY0'],
  "lail": ['L EY1 L'],
  "laimbeer": ['L EY2 M B IH1 R'],
  "lain": ['L EY1 N'],
  "laine": ['L EY1 N'],
  "laing": ['L AA1 IH0 NG'],
  "lainhart": ['L AY1 N HH AA0 R T'],
  "laino": ['L EY1 N OW0'],
  "lair": ['L EH1 R'],
  "laird": ['L EH1 R D'],
  "lairmore": ['L EH1 R M AO0 R'],
  "lairson": ['L EH1 R S AH0 N'],
  "lais": ['L EY1 Z'],
  "laissez": ['L EY1 Z EY2', 'L EH1 Z EY2'],
  "lait": ['L EY1'],
  "laitinen": ['L AY1 T IH0 N AH0 N'],
  "laity": ['L EY1 AH0 T IY0'],
  "lajeune": ['L AH0 JH AH1 N'],
  "lajeunesse": ['L AH0 JH AH1 N EH0 S'],
  "lajoie": ['L AE1 JH W AA0'],
  "lajous": ['L AH0 ZH UW1 S'],
  "lak": ['L AE1 K'],
  "lakatos": ['L AE1 K AH0 T OW0 Z'],
  "lake": ['L EY1 K'],
  "lake's": ['L EY1 K S'],
  "lakeberg": ['L EY1 K B ER0 G'],
  "lakefield": ['L EY1 K F IY2 L D'],
  "lakefront": ['L EY1 K F R AH2 N T'],
  "lakeland": ['L EY1 K L AH0 N D'],
  "lakeman": ['L EY1 K M AH0 N'],
  "laker": ['L EY1 K ER0'],
  "lakers": ['L EY1 K ER0 Z'],
  "lakes": ['L EY1 K S'],
  "lakes'": ['L EY1 K S'],
  "lakeshore": ['L EY1 K SH AO2 R'],
  "lakeside": ['L EY1 K S AY2 D'],
  "lakeview": ['L EY1 K V Y UW2'],
  "lakewood": ['L EY1 K W UH2 D'],
  "lakey": ['L EY1 K IY0'],
  "lakin": ['L AE1 K IH0 N'],
  "lakins": ['L AE1 K IH0 N Z'],
  "lakota": ['L AH0 K OW1 T AH0'],
  "lakotas": ['L AH0 K OW1 T AH0 Z'],
  "lakotas'": ['L AH0 K OW1 T AH0 Z'],
  "lakshamanan": ['L AE0 K SH AH0 M AA1 N AH0 N'],
  "lakshamanan's": ['L AE0 K SH AH0 M AA1 N AH0 N Z'],
  "lal": ['L AE1 L'],
  "lala": ['L AA1 L AH0'],
  "lalande": ['L AA0 L AA1 N D IY0'],
  "lalas": ['L AA1 L AH0 S'],
  "laliberte": ['L AA0 L IY0 B EH1 R T IY0'],
  "lalit": ['L AH0 L IY1 T'],
  "lalita": ['L AH0 L IY1 T AH0'],
  "lalitesh": ['L AA1 L IH2 T EH2 SH'],
  "lall": ['L AO1 L'],
  "lalla": ['L AE1 L AH0'],
  "lallera": ['L AH0 L EH1 R AH0'],
  "lalley": ['L AE1 L IY0'],
  "lalli": ['L AE1 L IY0'],
  "lallie": ['L AO1 L IY0'],
  "lallier": ['L AE1 L IY0 ER0'],
  "lallo": ['L AE1 L OW0'],
  "lally": ['L AE1 L IY0'],
  "lalonde": ['L AA0 L OW1 N D IY0'],
  "lalone": ['L AA0 L OW1 N IY0'],
  "lalor": ['L AE0 L AO1 R'],
  "lam": ['L AE1 M'],
  "lama": ['L AA1 M AH0'],
  "lama's": ['L AA1 M AH0 Z'],
  "lamacchia": ['L AA0 M AA1 K IY0 AH0'],
  "lamagna": ['L AA0 M AA1 G N AA0', 'L AH0 M AA1 N Y AA0'],
  "lamaist": ['L AA1 M AY0 IH0 S T'],
  "lamamra": ['L AH0 M AA1 M R AA0'],
  "laman": ['L EY1 M AH0 N'],
  "lamanna": ['L AE1 M IH0 N AH0'],
  "lamantia": ['L AA0 M AA1 N SH AH0'],
  "lamar": ['L AH0 M AA1 R'],
  "lamar's": ['L AH0 M AA1 R Z'],
  "lamarca": ['L AA0 M AA1 R K AH0'],
  "lamarche": ['L AA0 M AA1 R K IY0'],
  "lamarr": ['L AH0 M AA1 R', 'L AA0 M AA1 R'],
  "lamarre": ['L AA0 M AA1 R IY0'],
  "lamartina": ['L AA0 M AA0 R T IY1 N AH0'],
  "lamas": ['L AA1 M AH0 Z'],
  "lamaster": ['L AA1 M AE0 S T ER0'],
  "lamastus": ['L AE1 M AH0 S T IH0 S'],
  "lamattina": ['L AA0 M AA0 T IY1 N AH0'],
  "lamaur": ['L AH0 M AO1 R'],
  "lamay": ['L AE1 M EY0'],
  "lamb": ['L AE1 M'],
  "lamb's": ['L AE1 M Z'],
  "lambaste": ['L AE2 M B AE1 S T', 'L AE2 M B EY1 S T'],
  "lambasted": ['L AE2 M B AE1 S T IH0 D', 'L AE2 M B EY1 S T IH0 D'],
  "lambastes": ['L AE2 M B AE1 S T S', 'L AE2 M B EY1 S T S', 'L AE2 M B AE1 S', 'L AE2 M B EY1 S'],
  "lambasting": ['L AE0 M B EY1 S T IH0 NG'],
  "lambda": ['L AE1 M D AH0'],
  "lambdin": ['L AE1 M D IH0 N'],
  "lambe": ['L AE1 M'],
  "lambeau": ['L AE0 M B OW1'],
  "lamberg": ['L AE1 M B ER0 G'],
  "lamberson": ['L AE1 M B ER0 S AH0 N'],
  "lambert": ['L AE1 M B ER0 T'],
  "lambert's": ['L AE1 M B ER0 T S'],
  "lamberth": ['L AE1 M B ER0 TH'],
  "lamberti": ['L AA0 M B EH1 R T IY0'],
  "lamberto": ['L AA0 M B EH1 R T OW2'],
  "lamberton": ['L AE1 M B ER0 T AH0 N'],
  "lambertson": ['L AE1 M B ER0 T S AH0 N'],
  "lamberty": ['L AE1 M B ER0 T IY0'],
  "lambeth": ['L AE1 M B IH0 TH'],
  "lambiase": ['L AA0 M B IY0 AA1 S IY0'],
  "lambie": ['L AE1 M B IY0'],
  "lambing": ['L AE1 M IH0 NG'],
  "lamborghini": ['L AE2 M B ER0 G IY1 N IY0'],
  "lamborghini's": ['L AE2 M B ER0 G IY1 N IY0 Z'],
  "lamborghinis": ['L AE2 M B ER0 G IY1 N IY0 Z'],
  "lamborn": ['L AH0 M B AO1 R N'],
  "lamboy": ['L AE1 M B OY2'],
  "lambreau": ['L AE0 M B R OW1'],
  "lambrecht": ['L AE1 M B R IH0 K T'],
  "lambright": ['L AE1 M B R AY2 T'],
  "lambro": ['L AE1 M B R OW0'],
  "lambros": ['L AE1 M B R OW0 Z'],
  "lambs": ['L AE1 M Z'],
  "lambs'": ['L AE1 M Z'],
  "lambsdorff": ['L AE1 M Z D AO0 R F'],
  "lambson": ['L AE1 M S AH0 N'],
  "lamby": ['L AE1 M B IY0'],
  "lame": ['L EY1 M'],
  "lamellar": ['L AH0 M EH1 L ER0'],
  "lamely": ['L EY1 M L IY0'],
  "lamendola": ['L AA0 M EH0 N D OW1 L AH0'],
  "lament": ['L AH0 M EH1 N T'],
  "lamentable": ['L AH0 M EH1 N T AH0 B AH0 L'],
  "lamented": ['L AH0 M EH1 N T IH0 D'],
  "lamenting": ['L AH0 M EH1 N T IH0 NG'],
  "laments": ['L AH0 M EH1 N T S'],
  "lamer": ['L EY1 M ER0'],
  "lamere": ['L AA0 M EH1 R EY0'],
  "lamers": ['L EY1 M ER0 Z'],
  "lamesa": ['L AA0 M EY1 S AH0'],
  "lameta": ['L AH0 M EH1 T AH0'],
  "lamey": ['L EY1 M IY0'],
  "lamia": ['L EY1 M IY0 AH0'],
  "lamica": ['L AE1 M IH0 K AH0'],
  "lamie": ['L EY1 M IY0'],
  "lamina": ['L AE1 M AH0 N AH0'],
  "laminack": ['L AE1 M IH0 N AH0 K'],
  "laminar": ['L AE1 M IH0 N ER0'],
  "laminate": ['L AE1 M AH0 N AH0 T', 'L AE1 M AH0 N EY2 T'],
  "laminated": ['L AE1 M AH0 N EY2 T AH0 D'],
  "laminates": ['L AE1 M AH0 N AH0 T S'],
  "laminating": ['L AE1 M AH0 N EY2 T IH0 NG'],
  "lamine": ['L AH0 M IY1 N'],
  "lamine's": ['L AH0 M IY1 N Z'],
  "laminer": ['L AE1 M AH0 N ER0'],
  "laminne": ['L AH0 M IH1 N'],
  "lamirande": ['L AA0 M IH0 R AA1 N D IY0'],
  "lamison": ['L AE1 M IH0 S AH0 N'],
  "lamke": ['L AE1 M K IY0'],
  "lamkin": ['L AE1 M K IH0 N'],
  "lamle": ['L AE1 M L IY0'],
  "lamm": ['L AE1 M'],
  "lamm's": ['L AE1 M Z'],
  "lamme": ['L AE1 M'],
  "lammers": ['L AE1 M ER0 Z'],
  "lammert": ['L AE1 M ER0 T'],
  "lammey": ['L AE1 M IY0'],
  "lammi": ['L AE1 M IY0'],
  "lammon": ['L AE1 M AH0 N'],
  "lammond": ['L AE1 M AH0 N D'],
  "lammons": ['L AE1 M AH0 N Z'],
  "lamon": ['L AA0 M AO1 N'],
  "lamond": ['L AH0 M AA1 N D'],
  "lamonica": ['L AA0 M OW0 N IY1 K AH0'],
  "lamons": ['L AA1 M OW0 N Z'],
  "lamont": ['L AH0 M AA1 N T'],
  "lamontagne": ['L AA2 M AA0 N T EY1 N'],
  "lamonte": ['L AA0 M AA1 N T'],
  "lamora": ['L AA0 M AO1 R AH0'],
  "lamore": ['L AA1 M AO0 R'],
  "lamoreaux": ['L AE1 M ER0 OW0'],
  "lamoreux": ['L AE1 M ER0 OW0'],
  "lamorte": ['L AA0 M AO1 R T IY0'],
  "lamos": ['L AA1 M OW0 S'],
  "lamothe": ['L AE1 M AH0 DH'],
  "lamott": ['L AH0 M AA1 T'],
  "lamotta": ['L AA0 M OW1 T AH0'],
  "lamotte": ['L AH0 M AO1 T'],
  "lamountain": ['L AE1 M UW0 N T EY0 N'],
  "lamoureaux": ['L AE1 M UH0 R OW0'],
  "lamoureux": ['L AE1 M UH0 R OW0'],
  "lamp": ['L AE1 M P'],
  "lampe": ['L AE1 M P'],
  "lampert": ['L AE1 M P ER2 T'],
  "lampf": ['L AE1 M P F'],
  "lamphear": ['L AE1 M HH ER0'],
  "lamphere": ['L AE1 M F ER0'],
  "lamphier": ['L AE1 M F IY0 ER0'],
  "lampi": ['L AE1 M P IY0'],
  "lamping": ['L AE1 M P IH0 NG'],
  "lampkin": ['L AE1 M P K IH0 N'],
  "lampkins": ['L AE1 M P K IH0 N Z'],
  "lampl": ['L AE1 M P AH0 L'],
  "lampley": ['L AE1 M P L IY0'],
  "lamplighter": ['L AE1 M P L AY2 T ER0'],
  "lamplighter's": ['L AE1 M P L AY2 T ER0 Z'],
  "lamplighters": ['L AE1 M P L AY2 T ER0 Z'],
  "lampman": ['L AE1 M P M AH0 N'],
  "lampo": ['L AE1 M P OW0'],
  "lampoon": ['L AE0 M P UW1 N'],
  "lampoon's": ['L AE0 M P UW1 N Z'],
  "lampooned": ['L AE0 M P UW1 N D'],
  "lamport": ['L AE1 M P AO2 R T'],
  "lampp": ['L AE1 M P'],
  "lamppost": ['L AE1 M P P OW2 S T', 'L AE1 M P OW2 S T'],
  "lampposts": ['L AE1 M P P OW2 S T S'],
  "lamprey": ['L AE1 M P R IY0'],
  "lampreys": ['L AE1 M P R IY0 Z'],
  "lampron": ['L AE1 M P R AH0 N'],
  "lampros": ['L AE1 M P R OW0 Z'],
  "lamps": ['L AE1 M P S'],
  "lampson": ['L AE1 M P S AH0 N'],
  "lampton": ['L AE1 M P T AH0 N'],
  "lamson": ['L AE1 M S AH0 N'],
  "lamston": ['L AE1 M S T AH0 N'],
  "lamy": ['L EY1 M IY0'],
  "lan": ['L AE1 N'],
  "lana": ['L AE1 N AH0'],
  "lanagan": ['L AE1 N AH0 G AE0 N'],
  "lanahan": ['L AE1 N AH0 HH AE0 N'],
  "lanai": ['L AH0 N AY1'],
  "lanai-city": ['L AH0 N AY1 S IH2 T IY0'],
  "lanasa": ['L AA0 N AA1 S AH0'],
  "lancashire": ['L AE1 NG K AH0 SH AY2 R'],
  "lancaster": ['L AE1 N K AE2 S T ER0', 'L AE1 NG K AH0 S T ER0'],
  "lancastrian": ['L AE2 NG K AE1 S T R IY0 AH0 N'],
  "lance": ['L AE1 N S'],
  "lance's": ['L AE1 N S IH0 Z'],
  "lancelet": ['L AE1 N S L AH0 T'],
  "lancelot": ['L AE1 N S AH0 L AA2 T'],
  "lanceolate": ['L AE1 N S IY0 AH0 L EY2 T'],
  "lancer": ['L AE1 N S ER0'],
  "lancers": ['L AE1 N S ER0 Z'],
  "lancet": ['L AE1 N S AH0 T'],
  "lancet's": ['L AE1 N S AH0 T S'],
  "lancets": ['L AE1 N S AH0 T S'],
  "lancia": ['L AA1 N CH AH0'],
  "lancing": ['L AE1 N S IH0 NG'],
  "lancit": ['L AE1 N S IH0 T'],
  "lanclos": ['L AE1 N K L OW0 Z'],
  "lancome": ['L AE1 N K AH0 M', 'L AA1 N K OW2 M'],
  "lancon": ['L AE1 NG K AH0 N'],
  "lancour": ['L AH0 N K UH1 R'],
  "lanctot": ['L AE1 NG K T AH0 T'],
  "lancz": ['L AE1 N CH'],
  "land": ['L AE1 N D'],
  "land's": ['L AE1 N D Z'],
  "landa": ['L AE1 N D AH0'],
  "landau": ['L AE1 N D AW2'],
  "landauer": ['L AE1 N D AW2 ER0'],
  "landay": ['L AE1 N D EY2'],
  "landbank": ['L AE1 N D B AE2 NG K'],
  "landberg": ['L AE1 N D B ER0 G'],
  "lande": ['L AE1 N D'],
  "landeck": ['L AE1 N D IH0 K'],
  "landed": ['L AE1 N D AH0 D', 'L AE1 N D IH0 D'],
  "landefeld": ['L AE1 N D IH0 F EH0 L D'],
  "landell": ['L AE0 N D EH1 L'],
  "landen": ['L AE1 N D AH0 N'],
  "landenberger": ['L AE1 N D AH0 N B ER0 G ER0'],
  "lander": ['L AE1 N D ER0'],
  "landeros": ['L AE1 N D ER0 OW0 Z'],
  "landers": ['L AE1 N D ER0 Z'],
  "landes": ['L AE1 N D Z'],
  "landesbank": ['L AE1 N D IY0 Z B AE2 NG K'],
  "landesman": ['L AE1 N D IH0 S M AH0 N'],
  "landess": ['L AE1 N D AH0 S'],
  "landfair": ['L AE1 N D F EH2 R'],
  "landfall": ['L AE1 N D F AO2 L'],
  "landfill": ['L AE1 N D F IH2 L'],
  "landfilling": ['L AE1 N D F IH0 L IH0 NG'],
  "landfills": ['L AE1 N D F IH2 L Z'],
  "landform": ['L AE1 N D F AO2 R M'],
  "landforms": ['L AE1 N D F AO2 R M Z'],
  "landfried": ['L AE1 N D F ER0 IY0 D'],
  "landgraf": ['L AE1 N JH R AH0 F'],
  "landgrave": ['L AE1 N D G R EY2 V'],
  "landgrebe": ['L AE1 N JH R IH0 B'],
  "landgren": ['L AE1 N D G R EH0 N'],
  "landholder": ['L AE1 N D HH OW2 L D ER0'],
  "landholders": ['L AE1 N D HH OW2 L D ER0 Z'],
  "landholding": ['L AE1 N D HH OW2 L D IH0 NG'],
  "landholdings": ['L AE1 N D HH OW2 L D IH0 NG Z'],
  "landi": ['L AE1 N D IY0'],
  "landin": ['L AE1 N D IH0 N'],
  "landing": ['L AE1 N D IH0 NG'],
  "landingham": ['L AE1 N D IH0 NG HH AE2 M'],
  "landings": ['L AE1 N D IH0 NG Z'],
  "landini": ['L AA0 N D IY1 N IY0'],
  "landino": ['L AA0 N D IY1 N OW0'],
  "landis": ['L AE1 N D IH0 S'],
  "landlady": ['L AE1 N D L EY2 D IY0'],
  "landler": ['L AE1 N D L ER0'],
  "landler's": ['L AE1 N D L ER0 Z'],
  "landless": ['L AE1 N D L AH0 S'],
  "landline": ['L AE1 N D L AY2 N'],
  "landlock": ['L AE1 N D L AA2 K'],
  "landlocked": ['L AE1 N D L AA2 K T'],
  "landlord": ['L AE1 N D L AO2 R D'],
  "landlord's": ['L AE1 N D L AO2 R D Z'],
  "landlords": ['L AE1 N D L AO2 R D Z'],
  "landman": ['L AE1 N D M AH0 N'],
  "landmark": ['L AE1 N D M AA2 R K'],
  "landmark's": ['L AE1 N D M AA2 R K S'],
  "landmarks": ['L AE1 N D M AA2 R K S'],
  "landmass": ['L AE1 N D M AE2 S'],
  "landmasses": ['L AE1 N D M AE2 S IH0 Z'],
  "landmesser": ['L AE1 N D M EH2 S ER0'],
  "landmine": ['L AE1 N D M AY2 N'],
  "landmines": ['L AE1 N D M AY2 N Z'],
  "lando": ['L AE1 N D OW0'],
  "landolfi": ['L AA0 N D OW1 L F IY0'],
  "landolt": ['L AE1 N D OW2 L T'],
  "landon": ['L AE1 N D AH0 N'],
  "landon's": ['L AE1 N D AH0 N Z'],
  "landor": ['L AE1 N D ER0'],
  "landover": ['L AE1 N D OW2 V ER0'],
  "landow": ['L AE1 N D OW0'],
  "landowner": ['L AE1 N D OW2 N ER0'],
  "landowners": ['L AE1 N D OW2 N ER0 Z'],
  "landowski": ['L AH0 N D AO1 F S K IY0'],
  "landreneau": ['L AE1 N D R IH0 N OW0'],
  "landress": ['L AE1 N D R IH0 S'],
  "landreth": ['L AE1 N D R IH0 TH'],
  "landrieu": ['L AE1 N D R UW0', 'L AE0 N D R IY1 UW0'],
  "landrigan": ['L AE1 N D R IH0 G AH0 N'],
  "landrith": ['L AE1 N D R IH0 TH'],
  "landrover": ['L AE1 N D R OW1 V ER0'],
  "landrum": ['L AE1 N D R AH0 M'],
  "landrus": ['L AE1 N D R AH0 S'],
  "landry": ['L AE1 N D R IY0'],
  "landry's": ['L AE1 N D R IY0 Z'],
  "lands": ['L AE1 N D Z'],
  "lands'": ['L AE1 N D Z'],
  "landsat": ['L AE1 N D S AE1 T'],
  "landsberg": ['L AE1 N D Z B ER0 G'],
  "landsberger": ['L AE1 N D Z B ER0 G ER0'],
  "landsbergis": ['L AE1 N D Z B ER0 G AH0 S'],
  "landscape": ['L AE1 N D S K EY2 P', 'L AE1 N S K EY2 P'],
  "landscaped": ['L AE1 N D S K EY2 P T'],
  "landscaper": ['L AE1 N D S K EY2 P ER0'],
  "landscapers": ['L AE1 N D S K EY2 P ER0 Z'],
  "landscapes": ['L AE1 N D S K EY2 P S', 'L AE1 N S K EY2 P S'],
  "landscaping": ['L AE1 N D S K EY2 P IH0 NG', 'L AE1 N S K EY2 P IH0 NG'],
  "landsend": ['L AE1 N D Z EH1 N D'],
  "landslide": ['L AE1 N D S L AY2 D', 'L AE1 N S L AY2 D'],
  "landslides": ['L AE1 N D S L AY2 D Z', 'L AE1 N S L AY2 D Z'],
  "landsman": ['L AE1 N D Z M AH0 N'],
  "landsner": ['L AE1 N D Z N ER0'],
  "landston": ['L AE1 N D S T AH0 N'],
  "landstrom": ['L AE1 N D S T R AH0 M'],
  "landt": ['L AE1 N T'],
  "landview": ['L AE1 N D V Y UW2'],
  "landview's": ['L AE1 N D V Y UW2 Z'],
  "landviewite": ['L AE1 N D V Y UW0 AY0 T'],
  "landviewites": ['L AE1 N D V Y UW0 AY0 T S'],
  "landward": ['L AE1 N D W ER0 D'],
  "landwehr": ['L AE1 N D W IH0 R'],
  "landy": ['L AE1 N D IY0'],
  "lane": ['L EY1 N'],
  "lane's": ['L EY1 N Z'],
  "lanehart": ['L EY1 N HH AA2 R T'],
  "lanes": ['L EY1 N Z'],
  "lanese": ['L AA0 N EY1 Z IY0'],
  "lanete": ['L AH0 N IY1 T'],
  "laneve": ['L AH0 N IY1 V'],
  "laney": ['L EY1 N IY0'],
  "lanfear": ['L AE1 N F ER0'],
  "lanford": ['L AE1 N F ER0 D'],
  "lang": ['L AE1 NG'],
  "langan": ['L AE1 NG G AH0 N'],
  "langbehn": ['L AE1 NG B IH0 N'],
  "langbein": ['L AE1 NG B AY0 N'],
  "langbo": ['L AE1 NG B OW0'],
  "langdale": ['L AE1 NG D EY2 L'],
  "langdon": ['L AE1 NG D AH0 N'],
  "lange": ['L AE1 NG'],
  "lange's": ['L AE1 NG Z'],
  "langel": ['L EY1 NG G AH0 L'],
  "langeland": ['L EY1 NG G IH0 L AH0 N D', 'L EY1 NG G L AH0 N D'],
  "langelier": ['L EY1 N JH L IY0 ER0'],
  "langella": ['L AE0 N JH EH1 L ER0'],
  "langen": ['L AE1 NG AH0 N'],
  "langenbach": ['L EY1 NG G IH0 N B AA0 K'],
  "langenberg": ['L AE1 NG AH0 N B ER0 G'],
  "langenderfer": ['L EY1 NG G IH0 N D ER0 F ER0'],
  "langendorf": ['L EY1 NG G IH0 N D AO0 R F'],
  "langenfeld": ['L EY1 NG G IH0 N F EH0 L D'],
  "langer": ['L AE1 NG ER0'],
  "langerman": ['L AE1 NG ER0 M AH0 N'],
  "langers": ['L AE1 NG ER0 Z'],
  "langevin": ['L EY1 NG G IH0 V IH0 N'],
  "langfeldt": ['L AE1 NG F EH0 L T'],
  "langfitt": ['L AE1 NG F IH0 T'],
  "langford": ['L AE1 NG F ER0 D'],
  "langguth": ['L AE1 NG UW0 TH'],
  "langham": ['L AE1 NG HH AE0 M', 'L AE1 NG AH0 M'],
  "langhans": ['L AE1 NG HH AH0 N Z'],
  "langhoff": ['L AE1 NG HH AO0 F'],
  "langhorn": ['L AE1 NG HH AO0 R N'],
  "langhorne": ['L AE1 NG HH AO0 R N'],
  "langhorst": ['L AE1 NG HH AO0 R S T'],
  "langill": ['L AE1 NG G AH0 L'],
  "langille": ['L AA0 NG G IY1 L IY0'],
  "langlais": ['L AH0 NG G L EY1'],
  "langland": ['L AE1 NG L AH0 N D'],
  "langley": ['L AE1 NG L IY0', 'L AE1 NG G L IY0'],
  "langlinais": ['L AE1 NG G L IH0 N EY0'],
  "langlois": ['L AH0 NG L W AA1'],
  "langman": ['L AE1 NG M AH0 N'],
  "langner": ['L AE1 NG N ER0'],
  "lango": ['L AA1 NG G OW0'],
  "langone": ['L AA0 NG G OW1 N IY0'],
  "langowski": ['L AH0 NG G AO1 F S K IY0'],
  "langrehr": ['L AE1 NG G R IH0 R'],
  "langridge": ['L AE1 NG G R IH0 JH'],
  "langsam": ['L AE1 NG S AH0 M'],
  "langsdorf": ['L AE1 NG Z D AO0 R F'],
  "langseth": ['L AE1 NG S EH0 TH'],
  "langsford": ['L AE1 NG Z F ER0 D'],
  "langstaff": ['L AE1 NG S T AE0 F'],
  "langston": ['L AE1 NG S T AH0 N'],
  "langton": ['L AE1 NG T AH0 N'],
  "language": ['L AE1 NG G W AH0 JH', 'L AE1 NG G W IH0 JH'],
  "languages": ['L AE1 NG G W AH0 JH AH0 Z', 'L AE1 NG G W IH0 JH IH0 Z'],
  "languid": ['L AE1 NG G W AH0 D'],
  "languish": ['L AE1 NG G W IH0 SH'],
  "languished": ['L AE1 NG G W IH0 SH T'],
  "languishes": ['L AE1 NG G W IH0 SH IH0 Z'],
  "languishing": ['L AE1 NG G W IH0 SH IH0 NG'],
  "langwell": ['L AE1 NG G W EH0 L'],
  "langworth": ['L AE1 NG G W ER0 TH'],
  "langworthy": ['L AE1 NG W ER0 DH IY0'],
  "lanham": ['L AE1 N AH0 M'],
  "lani": ['L AA1 N IY0'],
  "lanier": ['L AH0 N IH1 R'],
  "lanigan": ['L AE1 N IH0 G AH0 N'],
  "laning": ['L EY1 N IH0 NG'],
  "laningham": ['L AE1 N IH0 NG HH AE2 M'],
  "lanius": ['L EY1 N IY0 IH0 S'],
  "lank": ['L AE1 NG K'],
  "lanka": ['L AE1 NG K AH0'],
  "lanka's": ['L AE1 NG K AH0 Z'],
  "lankan": ['L AE1 NG K AH0 N'],
  "lankans": ['L AE1 NG K AH0 N Z'],
  "lankford": ['L AE1 NG K F ER0 D'],
  "lanktree": ['L AE1 NG K T R IY2'],
  "lanky": ['L AE1 NG K IY0'],
  "lanman": ['L AE1 N M AH0 N'],
  "lann": ['L AE1 N'],
  "lanna": ['L AE1 N AH0'],
  "lannan": ['L AE1 N AH0 N'],
  "lannen": ['L AE1 N AH0 N'],
  "lanners": ['L AE1 N ER0 Z'],
  "lanni": ['L AE1 N IY0'],
  "lanning": ['L AE1 N IH0 NG'],
  "lanno": ['L AE1 N OW0'],
  "lanno's": ['L AE1 N OW0 Z'],
  "lannom": ['L AE1 N AH0 M'],
  "lannon": ['L AE1 N AH0 N'],
  "lanny": ['L AE1 N IY0'],
  "lano": ['L AA1 N OW0'],
  "lanolin": ['L AE1 N AH0 L AH0 N', 'L AE1 N AH0 L IH0 N'],
  "lanoue": ['L AE1 N UW0 EY2'],
  "lanouette": ['L AE1 N UW0 EH0 T'],
  "lanphear": ['L AE1 N F IH2 R'],
  "lanpher": ['L AE1 N F ER0'],
  "lanphere": ['L AE1 N F IH2 R'],
  "lanphier": ['L AE1 N F IH0 R'],
  "lansberry": ['L AE1 N Z B EH2 R IY0'],
  "lansbury": ['L AE1 N Z B ER2 IY0'],
  "lansbury's": ['L AE1 N Z B ER2 IY0 Z'],
  "lansdale": ['L AE1 N Z D EY2 L'],
  "lansdell": ['L AE1 N Z D AH0 L'],
  "lansdown": ['L AE1 N Z D AW0 N'],
  "lansdowne": ['L AE1 N S D AW2 N'],
  "lanser": ['L AE1 N S ER0'],
  "lansford": ['L AE1 N S F ER0 D'],
  "lansing": ['L AE1 N S IH0 NG'],
  "lansing's": ['L AE1 N S IH0 NG Z'],
  "lansky": ['L AE1 N S K IY0'],
  "lanston": ['L AE1 N S T AH0 N'],
  "lant": ['L AE1 N T'],
  "lantana": ['L AE0 N T AE1 N AH0'],
  "lanter": ['L AE1 N T ER0'],
  "lanterman": ['L AE1 N T ER0 M AH0 N'],
  "lantern": ['L AE1 N T ER0 N'],
  "lanterns": ['L AE1 N T ER0 N Z'],
  "lanthanum": ['L AE1 N TH AH0 N AH0 M'],
  "lanthier": ['L AE1 N TH IY0 ER0'],
  "lanting": ['L AE1 N T IH0 NG'],
  "lanting's": ['L AE1 N T IH0 NG Z'],
  "lantis": ['L AA1 N T IH0 S'],
  "lantos": ['L AE1 N T OW0 S'],
  "lantrip": ['L AE1 N T R IH0 P'],
  "lantry": ['L AE1 N T R IY0'],
  "lantz": ['L AE1 N T S'],
  "lantzy": ['L AE1 N T Z IY0'],
  "lanum": ['L AE1 N AH0 M'],
  "lanvin": ['L AE1 N V IH0 N'],
  "lanyard": ['L AE1 N Y ER0 D'],
  "lanyi": ['L AA1 N Y IY0'],
  "lanyon": ['L AE1 N Y AH0 N'],
  "lanz": ['L AE1 N Z'],
  "lanza": ['L AE1 N Z AH0'],
  "lanzelotti": ['L AE2 N Z AH0 L AA1 T IY0'],
  "lanzer": ['L AE1 N Z ER0'],
  "lanzet": ['L AE1 N Z AH0 T'],
  "lanzi": ['L AE1 N Z IY0'],
  "lanzillo": ['L AE2 N Z IH1 L OW0'],
  "lanzo": ['L AE1 N Z OW0'],
  "lao": ['L AW1'],
  "laos": ['L AA1 OW0 S', 'L AW1 S', 'L EY1 OW0 S'],
  "laotian": ['L EY0 OW1 SH AH0 N', 'L AA0 OW1 SH AH0 N', 'L AW1 SH AH0 N'],
  "lap": ['L AE1 P'],
  "lapage": ['L AA1 P IH0 JH'],
  "lapaglia": ['L AA0 P AA1 G L IY0 AH0'],
  "lapalme": ['L AA0 P AA1 L M IY0'],
  "lapan": ['L AH0 P AE1 N'],
  "laparoscopic": ['L AE2 P ER0 AA1 S K AH0 P IH0 K', 'L AE2 P R AH0 S K AA1 P IH0 K'],
  "laparoscopy": ['L AE2 P ER0 AA1 S K AH0 P IY0', 'L AE1 P R AH0 S K AH0 P IY0'],
  "lapd": ['EH1 L EY1 P IY1 D IY1'],
  "lapd's": ['EH1 L EY1 P IY1 D IY1 Z'],
  "lapdog": ['L AE1 P D AO2 G'],
  "lape": ['L EY1 P'],
  "lapel": ['L AH0 P EH1 L'],
  "lapels": ['L AH0 P EH1 L Z'],
  "lapenna": ['L AA0 P EH1 N AH0'],
  "lapenta": ['L AA0 P EH1 N T AH0'],
  "laperle": ['L AE1 P ER0 AH0 L'],
  "lapeyrouse": ['L AE1 P IH0 R UW0 S'],
  "lapham": ['L AE1 F AH0 M'],
  "laphroaig": ['L AE2 F R OW0 EY1 G'],
  "lapiana": ['L AA0 P IY0 AE1 N AH0'],
  "lapidary": ['L AE1 P AH0 D EH2 R IY0'],
  "lapides": ['L AH0 P AY1 D Z'],
  "lapidus": ['L AE1 P IH0 D IH0 S', 'L AH0 P IY1 D AH0 S'],
  "lapier": ['L EY1 P IY0 ER0'],
  "lapierre": ['L AE1 P IY0 EH0 R'],
  "lapin": ['L AE1 P IH0 N'],
  "lapine": ['L AA0 P IY1 N IY0'],
  "lapinski": ['L AH0 P IH1 N S K IY0'],
  "lapinsky": ['L AH0 P IH1 N S K IY0'],
  "lapis": ['L AE1 P AH0 S'],
  "lapka": ['L AA1 P K AH0'],
  "laplaca": ['L AH0 P L AA1 K AH0'],
  "lapland": ['L AE1 P L AE2 N D'],
  "laplant": ['L AH0 P L AO1 N T'],
  "laplante": ['L AH0 P L AA1 N T IY0'],
  "laplume": ['L AH0 P L UW1 M'],
  "lapoint": ['L AH0 P OY1 N T'],
  "lapointe": ['L AH0 P OY1 N T'],
  "lapolla": ['L AH0 P OW1 L AH0'],
  "laporta": ['L AH0 P AO1 R T AH0'],
  "laporte": ['L AH0 P AO1 R T'],
  "lapp": ['L AE1 P'],
  "lappe": ['L AE1 P'],
  "lapped": ['L AE1 P T'],
  "lappen": ['L AE1 P AH0 N'],
  "lappin": ['L AH0 P AE1 N'],
  "lapping": ['L AE1 P IH0 NG'],
  "lapps": ['L AE1 P S'],
  "laprade": ['L AA0 P R AA1 D IY0'],
  "laprairie": ['L AH0 P R EH1 R IY0'],
  "lapre": ['L EY1 P ER0'],
  "laprise": ['L AA1 P R AY0 Z'],
  "laps": ['L AE1 P S'],
  "lapse": ['L AE1 P S'],
  "lapsed": ['L AE1 P S T'],
  "lapses": ['L AE1 P S IH0 Z'],
  "lapsing": ['L AE1 P S IH0 NG'],
  "lapsley": ['L AE1 P S L IY0'],
  "laptop": ['L AE1 P T AA2 P'],
  "laptops": ['L AE1 P T AA2 P S'],
  "lar": ['L AA1 R'],
  "lara": ['L AA1 R AH0'],
  "larabee": ['L AE1 R AH0 B IY0'],
  "laraia": ['L AA0 R AA1 Y AH0'],
  "laraine": ['L ER0 EY1 N'],
  "laramee": ['L AE1 R AH0 M IY0'],
  "laramie": ['L EH1 R AH0 M IY0'],
  "laramore": ['L AE1 R AH0 M AO2 R'],
  "laraway": ['L AE1 R AH0 W EY2'],
  "larcenous": ['L AA1 R S AH0 N AH0 S'],
  "larceny": ['L AA1 R S AH0 N IY0'],
  "larch": ['L AA1 R CH'],
  "larche": ['L AA1 R CH IY0'],
  "larchmont": ['L AA1 R CH M AA2 N T'],
  "larchmont's": ['L AA1 R CH M AA2 N T S'],
  "larco": ['L AA1 R K OW2'],
  "larcom": ['L AA1 R K AA2 M', 'L AA1 R K AH0 M'],
  "lard": ['L AA1 R D'],
  "larded": ['L AA1 R D IH0 D'],
  "larder": ['L AA1 R D ER0'],
  "lardner": ['L AA1 R D N ER0'],
  "lare": ['L EH1 R'],
  "lareau": ['L ER0 OW1'],
  "laredo": ['L ER0 EY1 D OW0'],
  "lareina": ['L AA0 R EY0 IY1 N AH0'],
  "larena": ['L AA0 R EH1 N AH0'],
  "larew": ['L AE1 R UW0'],
  "large": ['L AA1 R JH'],
  "large-scale": ['L AA1 R JH S K EY2 L'],
  "largely": ['L AA1 R JH L IY0'],
  "largen": ['L AA1 R G AH0 N'],
  "largeness": ['L AA1 R JH N IH0 S'],
  "largent": ['L AA1 R JH AH0 N T'],
  "larger": ['L AA1 R JH ER0'],
  "largess": ['L AA1 R JH AH0 S'],
  "largesse": ['L AA0 R G EH1 S'],
  "largest": ['L AA1 R JH AH0 S T'],
  "largo": ['L AA1 R G OW0'],
  "lariat": ['L EH1 R IY0 AH0 T'],
  "lariccia": ['L AA0 R IY1 CH AH0'],
  "larijani": ['L EH2 R IH0 JH AA1 N IY0'],
  "larimer": ['L AE1 R IH0 M ER0'],
  "larimore": ['L AA0 R IY1 M AO0 R'],
  "larina": ['L AA0 R IY1 N AH0'],
  "larine": ['L AA0 R IY1 N IY0'],
  "larios": ['L AA0 R IY1 OW0 Z'],
  "lariscy": ['L ER0 IH1 S K IY0'],
  "larison": ['L AE1 R IH0 S AH0 N'],
  "larissa": ['L AA0 R IY1 S AH0'],
  "larivee": ['L AE1 R IH0 V IY0'],
  "lariviere": ['L AE1 R IH0 V IY0 EH0 R'],
  "larizza": ['L ER0 IH1 Z AH0'],
  "lark": ['L AA1 R K'],
  "larke": ['L AA1 R K'],
  "larkey": ['L AA1 R K IY0'],
  "larkin": ['L AA1 R K IH0 N'],
  "larkins": ['L AA1 R K IH0 N Z'],
  "larks": ['L AA1 R K S'],
  "larkspur": ['L AA1 R K S P ER2'],
  "larmer": ['L AA1 R M ER0'],
  "larmon": ['L AA1 R M AH0 N'],
  "larmore": ['L AA1 R M AO0 R'],
  "larned": ['L AA1 R N D'],
  "larner": ['L AA1 R N ER0'],
  "larocca": ['L AA0 R OW1 K AH0'],
  "larocco": ['L AA0 R OW1 K OW0'],
  "laroche": ['L AA0 R OW1 SH', 'L ER0 OW1 SH'],
  "laroche's": ['L AA0 R OW1 SH IH0 Z'],
  "larochelle": ['L AE1 R AH0 SH AH0 L'],
  "larock": ['L AE1 R AH0 K'],
  "larocque": ['L ER0 OW1 K'],
  "laroe": ['L AE1 R OW0'],
  "laroque": ['L ER0 OW1 K'],
  "larosa": ['L AA0 R OW1 S AH0'],
  "larose": ['L AA0 R OW1 S IY0'],
  "larosiere": ['L AA0 R OW0 S Y EH1 R'],
  "larouche": ['L AA0 R UW1 SH'],
  "laroux": ['L ER0 UW1'],
  "larowe": ['L AE1 R AW0'],
  "larrabee": ['L AE1 R AH0 B IY0'],
  "larrance": ['L AE1 R AH0 N S'],
  "larrea": ['L AE1 R IY0 AH0'],
  "larrick": ['L AE1 R IH0 K'],
  "larrimore": ['L AA0 R IY1 M AO0 R'],
  "larrison": ['L AE1 R IH0 S AH0 N'],
  "larrivee": ['L AE1 R IH0 V IY0'],
  "larroquette": ['L AA2 R OW0 K EH1 T'],
  "larroquette's": ['L AA2 R OW0 K EH1 T S'],
  "larrow": ['L AE1 R OW0'],
  "larry": ['L EH1 R IY0'],
  "larry's": ['L EH1 R IY0 Z'],
  "lars": ['L AA1 R Z'],
  "larsen": ['L AA1 R S AH0 N'],
  "larsh": ['L AA1 R SH'],
  "larson": ['L AA1 R S AH0 N'],
  "larsson": ['L AA1 R S AH0 N'],
  "larue": ['L ER0 UW1'],
  "laruso": ['L AA0 R UW1 S OW0'],
  "larussa": ['L AA0 R UW1 S AH0'],
  "larusso": ['L AA0 R UW1 S OW0'],
  "larva": ['L AA1 R V AH0'],
  "larvae": ['L AA1 R V IY0'],
  "larval": ['L AA1 R V AH0 L'],
  "lary": ['L EH1 R IY0'],
  "laryngeal": ['L AA2 R IH1 N JH IY2 AH0 L'],
  "laryngitis": ['L AA2 R IH0 N JH AY1 T AH0 S'],
  "laryngoscope": ['L AA0 R IH1 N G AH0 S K OW2 P', 'L AA0 R IH1 N JH AH0 S K OW2 P'],
  "laryngoscopic": ['L AA0 R IH1 N G AH0 S K AA1 P IH0 K', 'L AA0 R IH1 N JH AH0 S K AA1 P IH0 K'],
  "laryngoscopical": ['L AA0 R IH1 N G AH0 S K AA1 P IH2 K AH0 L', 'L AA0 R IH1 N JH AH0 S K AA1 P IH2 K AH0 L'],
  "laryngoscopicaly": ['L AA0 R IH1 N G AH0 S K AA1 P IH2 K AH0 L IY2', 'L AA0 R IH1 N JH AH0 S K AA1 P IH2 K AH0 L IY2'],
  "laryngoscopy": ['L AA2 R IH0 N G AO1 S K OW0 P IY2'],
  "larynx": ['L EH1 R IH0 NG K S'],
  "larzelere": ['L ER0 Z EH1 L ER0'],
  "las": ['L AA1 S', 'EH1 L EY1 EH1 S'],
  "las-vegas": ['L AA1 S V EY1 G AH0 S'],
  "lasagna": ['L AA0 S AA1 N Y AH0', 'L AH0 Z AA1 N Y AH0'],
  "lasala": ['L AA0 S AA1 L AH0'],
  "lasalle": ['L AH0 S AE1 L'],
  "lasater": ['L AE1 S EY0 T ER0'],
  "lasca": ['L AA1 S K AH0'],
  "lascala": ['L AA0 S K AA1 L AH0'],
  "lascaux": ['L AE1 S K OW0'],
  "lasch": ['L AE1 SH'],
  "lascivious": ['L AH0 S IH1 V IY0 AH0 S'],
  "lasco": ['L AA1 S K OW0'],
  "lascola": ['L AA0 S K OW1 L AH0'],
  "lase": ['L EY1 Z'],
  "lasecki": ['L AH0 S EH1 T S K IY0'],
  "lasek": ['L AA1 S EH0 K'],
  "laser": ['L EY1 Z ER0'],
  "laser's": ['L EY1 Z ER0 Z'],
  "lasers": ['L EY1 Z ER0 Z'],
  "lasersonic": ['L EY0 Z ER0 S AA1 N IH0 K'],
  "lasersonics": ['L EY0 Z ER0 S AA1 N IH0 K S'],
  "laseter": ['L AE1 S IY0 T ER0', 'L AE1 S IH0 T ER0'],
  "lash": ['L AE1 SH'],
  "lashbrook": ['L AE1 SH B R UH2 K'],
  "lashed": ['L AE1 SH T'],
  "lasher": ['L AE1 SH ER0'],
  "lashes": ['L AE1 SH IH0 Z'],
  "lashing": ['L AE1 SH IH0 NG'],
  "lashings": ['L AE1 SH IH0 NG Z'],
  "lashlee": ['L AE1 SH L IY2'],
  "lashley": ['L AE1 SH L IY0'],
  "lashomb": ['L AE1 SH AH0 M'],
  "lashua": ['L AE1 SH UW0 AE0'],
  "lashway": ['L AE1 SH W EY2'],
  "lasik": ['L EY1 S IH2 K'],
  "lasiter": ['L AE1 S AY0 T ER0'],
  "lask": ['L AE1 S K'],
  "laska": ['L AA1 S K AH0'],
  "laske": ['L EY1 S K'],
  "lasker": ['L AE1 S K ER0'],
  "laskey": ['L AE1 S K IY0'],
  "laski": ['L AA1 S K IY0'],
  "laskin": ['L AE1 S K IH0 N'],
  "lasko": ['L AE1 S K OW0'],
  "laskowski": ['L AH0 S K AO1 F S K IY0'],
  "lasky": ['L AE1 S K IY0'],
  "lasley": ['L AE1 S L IY0'],
  "laslo": ['L AA1 S L OW0'],
  "lasmo": ['L AE1 S M OW0'],
  "lasorda": ['L AH0 S AO1 R D AH0'],
  "lasorsa": ['L AA0 S AO1 R S AH0'],
  "lasota": ['L AA0 S OW1 T AH0'],
  "laspina": ['L AA0 S P IY1 N AH0'],
  "lass": ['L AE1 S'],
  "lassa": ['L AA1 S AH0'],
  "lasseigne": ['L AH0 S EY1 N Y'],
  "lassen": ['L AE1 S AH0 N'],
  "lasser": ['L AE1 S ER0'],
  "lasseter": ['L AE1 S IY0 T ER0'],
  "lassetter": ['L AE1 S IH0 T ER0'],
  "lassie": ['L AE1 S IY0'],
  "lassila": ['L AA0 S IY1 L AH0'],
  "lassiter": ['L AE1 S AY0 T ER0'],
  "lassman": ['L AE1 S M AH0 N'],
  "lassner": ['L AE1 S N ER0'],
  "lasso": ['L AE1 S OW0'],
  "lasswell": ['L AE1 S W EH2 L'],
  "last": ['L AE1 S T', 'L AE1 S'],
  "last's": ['L AE1 S T S'],
  "last-minute": ['L AE2 S M IH1 N AH0 T'],
  "lasted": ['L AE1 S T AH0 D', 'L AE1 S T IH0 D'],
  "laster": ['L AE1 S T ER0'],
  "lastest": ['L AE1 S T AH0 S T'],
  "lasting": ['L AE1 S T IH0 NG'],
  "lastinger": ['L AE1 S T IH0 NG ER0'],
  "lastingly": ['L AE1 S T IH0 NG L IY0'],
  "lastly": ['L AE1 S T L IY0'],
  "lastra": ['L AE1 S T R AH0'],
  "lastrapes": ['L AA0 S T R AA1 P EH0 S'],
  "lasts": ['L AE1 S T S'],
  "lasumma": ['L AH0 S UW1 M AH0'],
  "lasure": ['L AA1 ZH ER0'],
  "laswell": ['L AE1 S W EH2 L'],
  "laszlo": ['L AE1 Z L OW0'],
  "lat": ['L AA1 T', 'L AE1 T'],
  "lat-lon": ['L AE1 T L AA1 N'],
  "lat-lons": ['L AE1 T L AA1 N Z'],
  "lata": ['L AA1 T AH0'],
  "lataif": ['L AA0 T EY1 F'],
  "latanze": ['L AH0 T AA1 N Z EY2'],
  "latasha": ['L AH0 T AA1 SH AH0'],
  "latch": ['L AE1 CH'],
  "latchaw": ['L AE1 CH AO0'],
  "latched": ['L AE1 CH T'],
  "latches": ['L AE1 CH AH0 Z', 'L AE1 CH IH0 Z'],
  "latchford": ['L AE1 CH F ER0 D'],
  "latching": ['L AE1 CH IH0 NG'],
  "latchkey": ['L AE1 CH K IY2'],
  "late": ['L EY1 T'],
  "late-night": ['L EY1 T N AY2 T'],
  "latecomer": ['L EY1 T K AH0 M ER0'],
  "latecomers": ['L EY1 T K AH0 M ER0 Z'],
  "latelies": ['L EY1 T L IY0 Z'],
  "latella": ['L AA0 T EH1 L AH0'],
  "lately": ['L EY1 T L IY0'],
  "latenan": ['L EY1 T AH0 N AH0 N'],
  "latency": ['L EY1 T AH0 N S IY0'],
  "latendresse": ['L AE1 T IH0 N D R EH0 S'],
  "lateness": ['L EY1 T N AH0 S'],
  "latent": ['L EY1 T AH0 N T'],
  "later": ['L EY1 T ER0'],
  "lateral": ['L AE1 T ER0 AH0 L'],
  "laterally": ['L AE1 T ER0 AH0 L IY2'],
  "laterites": ['L AE1 T ER0 AY2 T S'],
  "laterriere": ['L AA0 T EH2 R Y EH1 R'],
  "laterza": ['L AA0 T EH1 R Z AH0'],
  "latest": ['L EY1 T AH0 S T'],
  "latex": ['L EY1 T EH2 K S'],
  "latexes": ['L EY1 T EH0 K S AH0 Z'],
  "lath": ['L AE1 TH'],
  "latham": ['L AE1 TH AH0 M'],
  "latham's": ['L AE1 TH AH0 M Z'],
  "lathan": ['L AE1 TH AH0 N'],
  "lathe": ['L EY1 DH'],
  "lathem": ['L AE1 TH IH0 M'],
  "lather": ['L AE1 DH ER0'],
  "lathers": ['L AE1 DH ER0 Z'],
  "lathes": ['L EY1 DH Z'],
  "lathon": ['L AE1 TH AH0 N'],
  "lathouris": ['L AH0 TH UW1 R IH0 S'],
  "lathrop": ['L AE1 TH R AH0 P'],
  "latigo": ['L AH0 T IY1 G OW0'],
  "latimer": ['L AE1 T AH0 M ER0'],
  "latimore": ['L AA0 T IY1 M AO0 R'],
  "latin": ['L AE1 T AH0 N'],
  "latina": ['L AA0 T IY1 N AH0', 'L AH0 T IY1 N AH0'],
  "latinate": ['L AE1 T AH0 N EY2 T'],
  "latini": ['L AA0 T IY1 N IY0'],
  "latino": ['L AA0 T IY1 N OW0', 'L AH0 T IY1 N OW0'],
  "latinos": ['L AA0 T IY1 N OW0 S'],
  "latins": ['L AE1 T AH0 N Z'],
  "latiolais": ['L AE1 T IY0 AH0 L EY0'],
  "latisha": ['L AH0 T IH1 SH AH0'],
  "latitude": ['L AE1 T AH0 T UW2 D'],
  "latitudes": ['L AE1 T AH0 T UW2 D Z'],
  "latitudinal": ['L AE2 T AH0 T UW1 D AH0 N AH0 L'],
  "latka": ['L AA1 T K AH0'],
  "latkas": ['L AA1 T K AH0 Z'],
  "latner": ['L AE1 T N ER0'],
  "lato": ['L AA1 T OW0'],
  "laton": ['L AE1 T AH0 N'],
  "latona": ['L AA0 T OW1 N AH0'],
  "latonia": ['L AA0 T OW1 N IY0 AH0'],
  "latorre": ['L AA0 T AO1 R IY0'],
  "latour": ['L AH0 T UH1 R'],
  "latourette": ['L AE1 T UH0 R EH0 T'],
  "latoya": ['L AH0 T OY1 AH0'],
  "latoya's": ['L AH0 T OY1 AH0 Z'],
  "latrine": ['L AH0 T R IY1 N'],
  "latrines": ['L AH0 T R IY1 N Z'],
  "latrobe": ['L AA0 T R OW1 B'],
  "latrone": ['L AH0 T R OW1 N'],
  "lats": ['L AA1 T S', 'L AE1 T S'],
  "latsch": ['L AE1 CH'],
  "latshaw": ['L AE1 CH AO2'],
  "latson": ['L AE1 T S AH0 N'],
  "latta": ['L AE1 T AH0'],
  "lattanzi": ['L AA0 T AA1 N Z IY0'],
  "lattanzio": ['L AA0 T AA1 N Z IY0 OW0'],
  "latte": ['L AA1 T EY2'],
  "latter": ['L AE1 T ER0'],
  "latter's": ['L AE1 T ER0 Z'],
  "lattice": ['L AE1 T AH0 S'],
  "latticework": ['L AE1 T AH0 S W ER2 K'],
  "lattimer": ['L AE1 T AH0 M ER0'],
  "lattimore": ['L AA0 T IY1 M AO0 R'],
  "lattin": ['L AE1 T IH0 N'],
  "lattner": ['L AE1 T N ER0'],
  "latty": ['L AE1 T IY0'],
  "latulippe": ['L AA0 T UW0 L IY1 P IY0'],
  "latvala": ['L AA0 T V AA1 L AH0'],
  "latvia": ['L AE1 T V IY0 AH0'],
  "latvia's": ['L AE1 T V IY0 AH0 Z'],
  "latvian": ['L AE1 T V IY0 AH0 N'],
  "latvians": ['L AE1 T V IY0 AH0 N Z'],
  "latz": ['L AE1 T S'],
  "latzoff": ['L AE1 T Z AO0 F', 'L AE1 T S AO0 F'],
  "lau": ['L AW1'],
  "laub": ['L AO1 B'],
  "laubach": ['L AW1 B AA2 K'],
  "laubacher": ['L AW1 B AA2 K ER0'],
  "laube": ['L AO1 B'],
  "laubenstein": ['L AW1 B AH0 N S T AY0 N', 'L AW1 B AH0 N S T IY0 N'],
  "laubenthal": ['L AW1 B IH0 N TH AH0 L'],
  "lauber": ['L AW1 B ER0'],
  "laubhan": ['L AW1 B HH AH0 N'],
  "laubscher": ['L AW1 B SH ER0'],
  "lauch": ['L AA1 CH'],
  "lauck": ['L AO1 K'],
  "laud": ['L AO1 D'],
  "laudable": ['L AO1 D AH0 B AH0 L'],
  "laudanum": ['L AO1 D AH0 N AH0 M'],
  "laudatory": ['L AO1 D AH0 T AO2 R IY0'],
  "laude": ['L AO1 D'],
  "lauded": ['L AO1 D IH0 D'],
  "laudenslager": ['L AW1 D IH0 N S L EY0 G ER0'],
  "lauder": ['L AO1 D ER0', 'L AW1 D ER0'],
  "lauderback": ['L AW1 D ER0 B AE0 K'],
  "lauderbaugh": ['L AO0 D ER1 B AO0'],
  "lauderdale": ['L AO1 D ER0 D EY2 L'],
  "laudicina": ['L AO0 D IY0 CH IY1 N AH0'],
  "lauding": ['L AO1 D IH0 NG'],
  "laudise": ['L AO1 D AY2 Z'],
  "laudner": ['L AO1 D N ER0', 'L AW1 D N ER0'],
  "laudon": ['L AO1 D AH0 N'],
  "lauds": ['L AO1 D Z'],
  "laue": ['L AW1 IY0'],
  "lauer": ['L AW1 ER0'],
  "lauerman": ['L AW1 ER0 M AH0 N'],
  "lauf": ['L AO1 F'],
  "laufenberg": ['L AW1 F AH0 N B ER0 G'],
  "laufer": ['L AO1 F ER0'],
  "lauffer": ['L AO1 F ER0'],
  "laugh": ['L AE1 F'],
  "laughable": ['L AE1 F AH0 B AH0 L'],
  "laughed": ['L AE1 F T'],
  "laugher": ['L AA1 K ER0'],
  "laughery": ['L AE1 F ER0 IY0'],
  "laughing": ['L AE1 F IH0 NG'],
  "laughinghouse": ['L AE1 F IH0 NG HH AW2 S'],
  "laughingly": ['L AE1 F IH0 NG L IY0'],
  "laughingstock": ['L AE1 F IH0 NG S T AA2 K'],
  "laughler": ['L AE1 F L ER0'],
  "laughlin": ['L AA1 K L IH0 N', 'L AA1 F L IH0 N'],
  "laughman": ['L AE1 F M AH0 N'],
  "laughner": ['L AE1 F N ER0'],
  "laughrey": ['L AE1 F R IY0'],
  "laughridge": ['L AE1 F R IH2 JH'],
  "laughs": ['L AE1 F S'],
  "laughter": ['L AE1 F T ER0'],
  "laughton": ['L AO1 T AH0 N'],
  "lauinger": ['L AW1 IH0 NG ER0'],
  "lauman": ['L AW1 M AH0 N'],
  "laumann": ['L AW1 M AH0 N'],
  "laun": ['L AO1 N'],
  "launcelot": ['L AO1 N S IH0 L AA2 T'],
  "launch": ['L AO1 N CH'],
  "launched": ['L AO1 N CH T'],
  "launcher": ['L AO1 N CH ER0'],
  "launchers": ['L AO1 N CH ER0 Z'],
  "launches": ['L AO1 N CH AH0 Z', 'L AO1 N CH IH0 Z'],
  "launching": ['L AO1 N CH IH0 NG'],
  "launchings": ['L AO1 N CH IH0 NG Z'],
  "launder": ['L AO1 N D ER0'],
  "laundered": ['L AO1 N D ER0 D'],
  "launderer": ['L AO1 N D ER0 ER0'],
  "launderers": ['L AO1 N D ER0 ER0 Z'],
  "laundering": ['L AO1 N D ER0 IH0 NG'],
  "launders": ['L AO1 N D ER0 Z'],
  "laundries": ['L AO1 N D R IY0 Z'],
  "laundromat": ['L AO1 N D R AH0 M AE2 T'],
  "laundromats": ['L AO1 N D R AH0 M AE2 T S'],
  "laundry": ['L AO1 N D R IY0'],
  "launer": ['L AO1 N ER0'],
  "launius": ['L AW1 N IY0 IH0 S'],
  "lauper": ['L AW1 P ER0'],
  "laur": ['L AO1 R'],
  "laura": ['L AO1 R AH0'],
  "laura's": ['L AO1 R AH0 Z'],
  "laurance": ['L AO1 R AH0 N S'],
  "laurdan": ['L AO1 R D AH0 N'],
  "laure": ['L AO1 R'],
  "laureano": ['L AO0 R IY1 N OW0'],
  "laureate": ['L AO1 R IY0 AH0 T'],
  "laureates": ['L AO1 R IY0 AH0 T S'],
  "laureen": ['L AO0 R IY1 N'],
  "laurel": ['L AO1 R AH0 L'],
  "laurel's": ['L AO1 R AH0 L Z'],
  "laurels": ['L AO1 R AH0 L Z'],
  "lauren": ['L AO1 R AH0 N'],
  "laurena": ['L AO0 R EH1 N AH0'],
  "laurence": ['L AO1 R AH0 N S'],
  "laurene": ['L AO0 R IY1 N'],
  "laurens": ['L AO1 R AH0 N Z'],
  "laurent": ['L AO1 R AH0 N T'],
  "laurentian": ['L AO0 R EH1 N SH AH0 N'],
  "laurentiis": ['L AO1 R AH0 N T IY2 Z'],
  "laurenz": ['L AW1 R EY0 N Z'],
  "lauretarian": ['L AO2 R IH0 T EH1 R IY0 AH0 N'],
  "lauretta": ['L ER0 EH1 T AH0'],
  "laurette": ['L AO0 R EH1 T'],
  "laurey": ['L AO1 R IY0'],
  "lauri": ['L AO1 R IY0', 'L AA1 R IY0'],
  "lauria": ['L AO1 R IY0 AH0'],
  "lauriat's": ['L AA1 R IY0 IH0 T S'],
  "lauriats": ['L AA1 R IY0 IH0 T S'],
  "laurice": ['L AO1 R IH0 S'],
  "lauricella": ['L AO2 R IH0 S EH1 L AH0'],
  "laurich": ['L AW1 R IH0 K'],
  "lauridsen": ['L AO0 R IH1 D S AH0 N'],
  "laurie": ['L AO1 R IY0', 'L AA1 R IY0'],
  "laurie's": ['L AO1 R IY0 Z', 'L AA1 R IY0 Z'],
  "laurin": ['L AO1 R IH0 N'],
  "laurino": ['L AO0 R IY1 N OW0'],
  "lauritsen": ['L AW1 R IH0 T S AH0 N', 'L AA0 R IY1 T S AH0 N'],
  "lauritzen": ['L AW1 R IH0 T Z AH0 N', 'L AA0 R IY1 T Z AH0 N'],
  "lauro": ['L AO1 R OW0'],
  "laursen": ['L AW1 R S AH0 N'],
  "laury": ['L AO1 R IY0'],
  "lausanne": ['L AO2 S AE1 N'],
  "lausch": ['L AW1 SH'],
  "laut": ['L AO1 T'],
  "lauten": ['L AW1 T AH0 N'],
  "lautenbach": ['L AW1 T AH0 N B AA0 K'],
  "lautenberg": ['L AW1 T AH0 N B ER0 G'],
  "lautenschlager": ['L AW1 T IH0 N SH L EY0 G ER0'],
  "lauter": ['L AW1 T ER0'],
  "lauterbach": ['L AW1 T ER0 B AA0 K'],
  "lauth": ['L AO1 TH'],
  "lautner": ['L AW1 T N ER0'],
  "lautzenheiser": ['L AW1 T Z AH0 N HH AY0 Z ER0'],
  "lauver": ['L AW1 V ER0'],
  "laux": ['L AO1 K S'],
  "lauzon": ['L AW1 Z AH0 N'],
  "lava": ['L AA1 V AH0'],
  "laval": ['L AH0 V AA1 L'],
  "lavalle": ['L AA0 V AA1 L IY0'],
  "lavallee": ['L AE1 V AH0 L IY0'],
  "lavalley": ['L AE1 V AH0 L IY0'],
  "lavan": ['L EY1 V AH0 N'],
  "lavarnway": ['L AH0 V AA1 R N W EY0'],
  "lavatories": ['L AE1 V AH0 T AO2 R IY0 Z'],
  "lavatory": ['L AE1 V AH0 T AO2 R IY0'],
  "lave": ['L EY1 V'],
  "laveau": ['L AH0 V OW1'],
  "lavecchia": ['L AA0 V EH1 K IY0 AH0'],
  "laveda": ['L AA0 V EY1 D AH0'],
  "lavell": ['L AA0 V EY1 L'],
  "lavelle": ['L AH0 V EH1 L'],
  "lavely": ['L EY1 V L IY0'],
  "laven": ['L EY1 V AH0 N'],
  "lavender": ['L AE1 V AH0 N D ER0'],
  "laventhol": ['L EY1 V AH0 N TH AA0 L'],
  "laventure": ['L AA0 V EH1 N CH ER0'],
  "laver": ['L EY1 V ER0'],
  "laverdiere": ['L AA0 V ER0 D IH1 R IY0'],
  "laverdure": ['L AA0 V ER0 D UH1 R IY0'],
  "lavere": ['L AA0 V EH1 R EY0'],
  "lavergne": ['L AH0 V ER1 N Y'],
  "laverne": ['L AH0 V ER1 N'],
  "lavers": ['L EY1 V ER0 Z'],
  "laverty": ['L AE1 V ER0 T IY0'],
  "lavery": ['L EY1 V ER0 IY0'],
  "lavetta": ['L AA0 V EH1 T AH0'],
  "lavette": ['L AH0 V EH1 T'],
  "lavey": ['L EY1 V IY0'],
  "lavi": ['L EY1 V IY0'],
  "lavie": ['L EY1 V IY0', 'L AH0 V IY1'],
  "lavigne": ['L AH0 V IH1 N Y'],
  "lavin": ['L AE1 V IH0 N'],
  "lavina": ['L AA0 V IY1 N AH0'],
  "lavinder": ['L AE1 V IH0 N D ER0'],
  "lavine": ['L AA0 V IY1 N IY0'],
  "lavinia": ['L AH0 V IH1 N Y AH0'],
  "laviolette": ['L AE2 V IY0 OW0 L EH1 T'],
  "lavis": ['L AE1 V IH0 S'],
  "lavish": ['L AE1 V IH0 SH'],
  "lavished": ['L AE1 V IH0 SH T'],
  "lavishes": ['L AE1 V IH0 SH AH0 Z'],
  "lavishing": ['L AE1 V IH0 SH IH0 NG'],
  "lavishly": ['L AE1 V IH0 SH L IY0'],
  "lavoie": ['L AH0 V W AA1'],
  "lavorgna": ['L AH0 V AO1 R N AH0'],
  "lavoro": ['L AH0 V AO1 R OW0'],
  "lavoy": ['L AH0 V OY1'],
  "lavy": ['L EY1 V IY0'],
  "law": ['L AO1', 'L AA1'],
  "law's": ['L AO1 Z'],
  "lawall": ['L AW1 AH0 L'],
  "laware": ['L AH0 W EH1 R'],
  "lawbreaker": ['L AO1 B R EY2 K ER0'],
  "lawbreakers": ['L AO1 B R EY2 K ER0 Z'],
  "lawbreaking": ['L AO1 B R EY2 K IH0 NG'],
  "lawes": ['L AO1 Z'],
  "lawford": ['L AO1 F ER0 D'],
  "lawful": ['L AO1 F AH0 L'],
  "lawfully": ['L AO1 F AH0 L IY0'],
  "lawhead": ['L AO1 HH EH2 D'],
  "lawhon": ['L AO1 HH AH0 N'],
  "lawhorn": ['L AE1 W ER0 N'],
  "lawhorne": ['L AE1 W ER0 N'],
  "lawing": ['L AO1 IH0 NG'],
  "lawler": ['L AO1 L ER0'],
  "lawless": ['L AO1 L AH0 S'],
  "lawlessness": ['L AO1 L AH0 S N AH0 S'],
  "lawley": ['L AO1 L IY0'],
  "lawlis": ['L AO1 L IH0 S'],
  "lawlor": ['L AO1 L ER0'],
  "lawmaker": ['L AO1 M EY2 K ER0'],
  "lawmaker's": ['L AO1 M EY2 K ER0 Z'],
  "lawmakers": ['L AO1 M EY2 K ER0 Z'],
  "lawmakers'": ['L AO1 M EY1 K ER0 Z'],
  "lawmaking": ['L AO1 M EY2 K IH0 NG'],
  "lawman": ['L AO1 M AH0 N'],
  "lawmen": ['L AO1 M EH1 N'],
  "lawn": ['L AO1 N'],
  "lawner": ['L AO1 N ER0', 'L AW1 N ER0'],
  "lawniczak": ['L AA1 V N IH0 CH AE0 K'],
  "lawnmower": ['L AO1 N M OW0 ER0'],
  "lawnmowers": ['L AO1 N M OW0 ER0 Z'],
  "lawns": ['L AO1 N Z'],
  "lawnsdale": ['L AO1 N Z D EY2 L'],
  "lawnsdale's": ['L AO1 N Z D EY2 L Z'],
  "lawplan": ['L AO1 P L AE2 N'],
  "lawrance": ['L AO1 R AH0 N S'],
  "lawrence": ['L AO1 R AH0 N S'],
  "lawrence's": ['L AO1 R AH0 N S IH0 Z'],
  "lawrenceburg": ['L AO1 R AH0 N S B ER0 G'],
  "lawrenceville": ['L AO1 R AH0 N S V IH2 L'],
  "lawrencium": ['L AO0 R EH1 N S IY0 AH0 M'],
  "lawrenz": ['L AA1 V R IH0 N Z'],
  "lawrie": ['L AO1 R IY0'],
  "lawry": ['L AO1 R IY0'],
  "laws": ['L AO1 Z'],
  "lawshe": ['L AO1 SH'],
  "lawson": ['L AO1 S AH0 N'],
  "lawson's": ['L AO1 S AH0 N Z'],
  "lawsuit": ['L AO1 S UW2 T'],
  "lawsuit's": ['L AO1 S UW2 T S'],
  "lawsuits": ['L AO1 S UW2 T S'],
  "lawter": ['L AO1 T ER0'],
  "lawther": ['L AO1 DH ER0'],
  "lawton": ['L AO1 T AH0 N'],
  "lawver": ['L AA1 V ER0'],
  "lawyer": ['L AO1 Y ER0', 'L OY1 ER0'],
  "lawyer's": ['L AO1 Y ER0 Z'],
  "lawyered": ['L AO1 Y ER0 D'],
  "lawyering": ['L AO1 Y ER0 IH0 NG'],
  "lawyerly": ['L AO1 Y ER0 L IY0'],
  "lawyers": ['L AO1 Y ER0 Z', 'L OY1 ER0 Z'],
  "lawyers'": ['L AO1 Y ER0 Z'],
  "lax": ['L AE1 K S'],
  "laxalt": ['L AE1 K S AA0 L T'],
  "laxative": ['L AE1 K S AH0 T IH0 V'],
  "laxatives": ['L AE1 K S AH0 T IH0 V Z'],
  "laxey": ['L AE1 K S IY0'],
  "laxity": ['L AE1 K S AH0 T IY0'],
  "laxson": ['L AE1 K S AH0 N'],
  "laxton": ['L AE1 K S T AH0 N'],
  "lay": ['L EY1'],
  "lay's": ['L EY1 Z'],
  "lay-up": ['L EY1 AH1 P'],
  "layah": ['L EY1 AH0'],
  "layaway": ['L EY1 AH0 W EY2'],
  "layborne": ['L EY1 B AO2 R N'],
  "layborne's": ['L EY1 B AO2 R N Z'],
  "laycock": ['L EY1 K AA2 K'],
  "layden": ['L EY1 D AH0 N'],
  "laye": ['L EY1'],
  "layer": ['L EY1 ER0'],
  "layered": ['L EY1 ER0 D'],
  "layering": ['L EY1 ER0 IH0 NG'],
  "layers": ['L EY1 ER0 Z'],
  "layfield": ['L EY1 F IY2 L D'],
  "layhee": ['L EY1 HH IY0'],
  "laying": ['L EY1 IH0 NG'],
  "layla": ['L EY1 L AA0'],
  "layland": ['L EY1 L AH0 N D'],
  "layman": ['L EY1 M AH0 N'],
  "layman's": ['L EY1 M AH0 N Z'],
  "laymen": ['L EY1 M AH0 N'],
  "laymon": ['L EY1 M AH0 N'],
  "layne": ['L EY1 N'],
  "layoff": ['L EY1 AO2 F'],
  "layoffs": ['L EY1 AO2 F S'],
  "layout": ['L EY1 AW2 T'],
  "layouts": ['L EY1 AW2 T S'],
  "layover": ['L EY1 OW2 V ER0'],
  "layovers": ['L EY1 OW2 V ER0 Z'],
  "laypeople": ['L EY0 P IY1 P AH0 L'],
  "layperson": ['L EY0 P ER1 S IH0 N'],
  "lays": ['L EY1 Z'],
  "layson": ['L EY1 Z AH0 N'],
  "laythan": ['L EY1 TH AH0 N'],
  "layton": ['L EY1 T AH0 N'],
  "lazar": ['L EY1 Z ER0'],
  "lazard": ['L AH0 Z AA1 R D'],
  "lazare": ['L AA0 Z AA1 R EY0'],
  "lazaro": ['L AH0 Z AA1 R OW0'],
  "lazaroff": ['L AE1 Z ER0 AO0 F'],
  "lazarski": ['L AH0 Z AA1 R S K IY0'],
  "lazarus": ['L AE1 Z ER0 AH0 S'],
  "lazarz": ['L AA1 Z AA0 R Z'],
  "lazcano": ['L AA0 Z K AA1 N OW0'],
  "lazear": ['L AH0 Z IH1 R'],
  "lazenby": ['L AE1 Z IH0 N B IY0'],
  "lazer": ['L EY1 Z ER0'],
  "lazere": ['L AH0 Z EY1 R'],
  "lazier": ['L EY1 Z IY0 ER0'],
  "laziest": ['L EY1 Z IY0 AH0 S T'],
  "lazily": ['L AE1 Z AH0 L IY0'],
  "laziness": ['L EY1 Z IY0 N AH0 S'],
  "lazo": ['L AA1 Z OW0'],
  "lazor": ['L AA0 Z AO1 R'],
  "lazuli": ['L AH0 Z UW1 L IY0'],
  "lazulite": ['L AE1 Z AH0 L AY2 T'],
  "lazur": ['L AE1 Z ER0'],
  "lazy": ['L EY1 Z IY0'],
  "lazzara": ['L AA0 T S AA1 R AH0'],
  "lazzari": ['L AA0 T S AA1 R IY0'],
  "lazzarini": ['L AA0 T S AA0 R IY1 N IY0'],
  "lazzaro": ['L AA0 T S AA1 R OW0'],
  "lazzell": ['L AE1 Z AH0 L'],
  "lb": ['P AW1 N D'],
  "lbs": ['P AW1 N D Z'],
  "lcb": ['EH2 S IY2 B IY1'],
  "lcs": ['EH1 L S IY1 EH1 S'],
  "le": ['L AH0'],
  "lea": ['L IY1'],
  "leabo": ['L IY1 B OW0'],
  "leach": ['L IY1 CH'],
  "leach's": ['L IY1 CH IH0 Z'],
  "leached": ['L IY1 CH T'],
  "leaches": ['L IY1 CH IH0 Z'],
  "leaching": ['L IY1 CH IH0 NG'],
  "leachman": ['L IY1 CH M AH0 N'],
  "leachman's": ['L IY1 CH M AH0 N Z'],
  "leacock": ['L IY1 K AA2 K'],
  "lead": ['L EH1 D', 'L IY1 D'],
  "leadbelly": ['L EH1 D B EH0 L IY0'],
  "leadbetter": ['L IY1 D B IH0 T ER0', 'L EH1 D B IH0 T ER0'],
  "leaded": ['L EH1 D IH0 D'],
  "leaden": ['L EH1 D AH0 N'],
  "leader": ['L IY1 D ER0'],
  "leader's": ['L IY1 D ER0 Z'],
  "leaderboard": ['L IY1 D ER0 B AO2 R D'],
  "leaderboards": ['L IY1 D ER0 B AO2 R D Z'],
  "leaderless": ['L IY1 D ER0 L AH0 S'],
  "leaders": ['L IY1 D ER0 Z'],
  "leaders'": ['L IY1 D ER0 Z'],
  "leadership": ['L IY1 D ER0 SH IH2 P'],
  "leadership's": ['L IY1 D ER0 SH IH2 P S'],
  "leaderships": ['L IY1 D ER0 SH IH2 P S'],
  "leading": ['L IY1 D IH0 NG'],
  "leadingham": ['L IY1 D IH0 NG HH AE2 M'],
  "leadoff": ['L IY1 D AO2 F'],
  "leads": ['L IY1 D Z'],
  "leadville": ['L EH1 D V IH2 L'],
  "leadwork": ['L EH1 D W ER2 K'],
  "leaf": ['L IY1 F'],
  "leafed": ['L IY1 F T'],
  "leafhopper": ['L IY1 F HH AA2 P ER0'],
  "leafhoppers": ['L IY1 F HH AA2 P ER0 Z'],
  "leafing": ['L IY1 F IH0 NG'],
  "leafless": ['L IY1 F L AH0 S'],
  "leaflet": ['L IY1 F L AH0 T'],
  "leaflets": ['L IY1 F L AH0 T S'],
  "leaflike": ['L IY1 F L AY2 K'],
  "leafs": ['L IY1 F S'],
  "leafy": ['L IY1 F IY0'],
  "league": ['L IY1 G'],
  "league's": ['L IY1 G Z'],
  "leaguer": ['L IY1 G ER0'],
  "leaguers": ['L IY1 G ER0 Z'],
  "leagues": ['L IY1 G Z'],
  "leagues'": ['L IY1 G Z'],
  "leah": ['L IY1 AH0'],
  "leahey": ['L EY1 HH IY0'],
  "leahy": ['L EY1 HH IY0'],
  "leak": ['L IY1 K'],
  "leakage": ['L IY1 K AH0 JH', 'L IY1 K IH0 JH'],
  "leake": ['L IY1 K'],
  "leaked": ['L IY1 K T'],
  "leaker": ['L IY1 K ER0'],
  "leakers": ['L IY1 K ER0 Z'],
  "leakey": ['L IY1 K IY0'],
  "leaking": ['L IY1 K IH0 NG'],
  "leaks": ['L IY1 K S'],
  "leaky": ['L IY1 K IY0'],
  "leal": ['L IY1 L'],
  "leala": ['L IY1 L AH0'],
  "lealan": ['L IY1 L AH0 N'],
  "lealia": ['L IY1 L IY0 AH0'],
  "lealie": ['L IY1 L IY0'],
  "leaman": ['L IY1 M AH0 N'],
  "leamer": ['L IY1 M ER0'],
  "leaming": ['L IY1 M IH0 NG'],
  "leamon": ['L IY1 M AH0 N'],
  "leamy": ['L IY1 M IY0'],
  "lean": ['L IY1 N'],
  "leana": ['L IY1 N AH0'],
  "leander": ['L IY0 AE1 N D ER0'],
  "leandro": ['L IY0 AA1 N D R OW0'],
  "leaned": ['L IY1 N D'],
  "leaner": ['L IY1 N ER0'],
  "leaners": ['L IY1 N ER0 Z'],
  "leanest": ['L IY1 N AH0 S T'],
  "leaning": ['L IY1 N IH0 NG'],
  "leanings": ['L IY1 N IH0 NG Z'],
  "leann": ['L IY0 AE1 N'],
  "leans": ['L IY1 N Z'],
  "leant": ['L IY1 AH0 N T'],
  "leanza": ['L IY0 AE1 N Z AH0'],
  "leap": ['L IY1 P'],
  "leaped": ['L EH1 P T', 'L IY1 P T'],
  "leapfrog": ['L IY1 P F R AO2 G'],
  "leapfrogged": ['L IY1 P F R AO2 G D'],
  "leapfrogging": ['L IY1 P F R AO2 G IH0 NG'],
  "leaphart": ['L IY1 P HH AA2 R T'],
  "leaping": ['L IY1 P IH0 NG'],
  "leapley": ['L IY1 P L IY0'],
  "leaps": ['L IY1 P S'],
  "leapt": ['L EH1 P T', 'L IY1 P T'],
  "lear": ['L IH1 R'],
  "lear's": ['L IY1 R Z'],
  "leard": ['L ER1 D'],
  "learjet": ['L IH1 R JH EH2 T'],
  "learn": ['L ER1 N'],
  "learned": ['L ER1 N D', 'L ER1 N IH0 D'],
  "learner": ['L ER1 N ER0'],
  "learners": ['L ER1 N ER0 Z'],
  "learning": ['L ER1 N IH0 NG'],
  "learns": ['L ER1 N Z'],
  "learnt": ['L ER1 N T'],
  "learonal": ['L IY1 R AH0 N AH0 L'],
  "leary": ['L IH1 R IY0'],
  "leary's": ['L IH1 R IY0 Z'],
  "leas": ['L IY1 Z'],
  "leasable": ['L IY1 S AH0 B AH0 L'],
  "leasco": ['L IY1 S K OW0'],
  "lease": ['L IY1 S'],
  "lease's": ['L IY1 S IH0 Z'],
  "leaseback": ['L IY1 S B AE2 K'],
  "leasebacks": ['L IY1 S B AE2 K S'],
  "leased": ['L IY1 S T'],
  "leasehold": ['L IY1 S HH OW2 L D'],
  "leaser": ['L IY1 S ER0'],
  "leases": ['L IY1 S IH0 Z'],
  "leaseway": ['L IY1 S W EY2'],
  "leaseway's": ['L IY1 S W EY2 Z'],
  "leash": ['L IY1 SH'],
  "leashed": ['L IY1 SH T'],
  "leashes": ['L IY1 SH IH0 Z'],
  "leasing": ['L IY1 S IH0 NG'],
  "leasing's": ['L IY1 S IH0 NG Z'],
  "leask": ['L IY1 S K'],
  "leason": ['L IY1 S AH0 N'],
  "least": ['L IY1 S T'],
  "leasure": ['L EH1 ZH ER0'],
  "leath": ['L IY1 TH'],
  "leath's": ['L IY1 TH S'],
  "leatham": ['L IY1 TH AH0 M'],
  "leathem": ['L EH1 TH IH0 M'],
  "leather": ['L EH1 DH ER0'],
  "leatherback": ['L EH1 DH ER0 B AE2 K'],
  "leatherbacks": ['L EH1 DH ER0 B AE2 K S'],
  "leatherberry": ['L EH1 DH ER0 B EH2 R IY0'],
  "leatherbury": ['L EH1 DH ER0 B EH2 R IY0'],
  "leatherman": ['L EH1 DH ER0 M AH0 N'],
  "leathers": ['L EH1 DH ER0 Z'],
  "leatherwood": ['L EH1 DH ER0 W UH2 D'],
  "leathery": ['L EH1 DH ER0 IY0'],
  "leaton": ['L IY1 T AH0 N'],
  "leatrice": ['L IY1 T R IH0 S'],
  "leave": ['L IY1 V'],
  "leavell": ['L IY1 V AH0 L'],
  "leavelle": ['L AH0 V EH1 L'],
  "leaven": ['L EH1 V AH0 N'],
  "leavened": ['L EH1 V AH0 N D'],
  "leavening": ['L EH1 V AH0 N IH0 NG'],
  "leavens": ['L EH1 V AH0 N Z'],
  "leavenworth": ['L EH1 V AH0 N W ER2 TH'],
  "leaver": ['L IY1 V ER0'],
  "leaver's": ['L IY1 V ER0 Z'],
  "leaverton": ['L IY1 V ER0 T AH0 N'],
  "leaves": ['L IY1 V Z'],
  "leavey": ['L IY1 V IY0'],
  "leaving": ['L IY1 V IH0 NG'],
  "leavings": ['L IY1 V IH0 NG Z'],
  "leavins": ['L IY1 V IH0 N Z'],
  "leavitt": ['L EH1 V IH0 T'],
  "leavy": ['L IY1 V IY0'],
  "leazer": ['L IY1 Z ER0'],
  "lebanese": ['L EH1 B AH0 N IY2 Z'],
  "lebanon": ['L EH1 B AH0 N AH0 N'],
  "lebanon's": ['L EH1 B AH0 N AH0 N Z'],
  "lebar": ['L IH0 B AA1 R'],
  "lebaron": ['L AH0 B EH1 R AH0 N'],
  "lebarron": ['L AH0 B EH1 R AH0 N'],
  "lebeau": ['L IH0 B OW1'],
  "lebeck": ['L IY1 B EH0 K'],
  "lebed": ['L EH1 B EH0 D'],
  "lebed's": ['L EH1 B EH0 D Z'],
  "lebeda": ['L EY0 B EY1 D AH0'],
  "lebegue": ['L EH1 B IH0 G'],
  "lebel": ['L EH1 B AH0 L'],
  "leben": ['L EH1 B AH0 N'],
  "lebenthal": ['L EH1 B AH0 N TH AO2 L'],
  "leber": ['L IY1 B ER0'],
  "lebert": ['L EH1 B ER0 T'],
  "leblanc": ['L AH0 B L AE1 NG K'],
  "leble": ['L EH1 B AH0 L'],
  "lebleu": ['L EH1 B L UW0'],
  "leblond": ['L IH0 B L AA1 N D'],
  "lebo": ['L EY1 B OW0'],
  "leboeuf": ['L AH0 B AH1 F'],
  "lebold": ['L EH1 B OW0 L D'],
  "lebon": ['L EH1 B AH0 N'],
  "lebouef": ['L AH0 B AH1 F'],
  "lebovitz": ['L EH1 B AH0 V IH0 T S'],
  "lebow": ['L AH0 B OW1'],
  "lebow's": ['L AH0 B OW1 Z'],
  "lebowe": ['L IH0 B OW1'],
  "lebowitz": ['L EH1 B AH0 W IH0 T S'],
  "lebowski": ['L IH0 B AA1 F S K IY0'],
  "lebrecht": ['L EH1 B R IH0 K T'],
  "lebreton": ['L EH1 B R IH0 T AA0 N'],
  "lebron": ['L EH1 B R AH0 N'],
  "lebrun": ['L EH1 B R AH0 N'],
  "lebsack": ['L EH1 B S AH0 K'],
  "lebudde": ['L AH0 B AH1 D'],
  "lecates": ['L IH0 K EY1 T S'],
  "leccese": ['L EH0 CH EY1 Z IY0'],
  "lech": ['L EH1 K'],
  "lecher": ['L EH1 CH ER0'],
  "lecherous": ['L EH1 CH ER0 AH0 S'],
  "lechler": ['L EH1 K L ER0'],
  "lechman": ['L EH1 K M AH0 N'],
  "lechmere": ['L EH1 K M IH2 R'],
  "lechner": ['L EH1 K N ER0'],
  "lechtenberg": ['L EH1 K T AH0 N B ER0 G'],
  "lechters": ['L EH1 K T ER0 Z'],
  "lechuga": ['L EH1 CH UW0 G AH0'],
  "lecithin": ['L EH1 S AH0 TH AH0 N', 'L EH1 S IH0 TH IH0 N'],
  "leck": ['L EH1 K'],
  "leckey": ['L EH1 K IY0'],
  "leckie": ['L EH1 K IY0'],
  "leckrone": ['L EH1 K R AH0 N'],
  "leclair": ['L EH1 K L ER0'],
  "leclaire": ['L IH0 K L EH1 R'],
  "leclerc": ['L AH0 K L ER1 K'],
  "leclercq": ['L EH1 K L ER0 K'],
  "leclere": ['L EH1 K L ER0'],
  "lecli": ['L EH1 K L IY0'],
  "lecli's": ['L EH1 K L IY0 Z'],
  "lecocq": ['L EH1 K AH0 K'],
  "lecomba": ['L AH0 K AH1 M B AH0'],
  "lecompte": ['L EH1 K AH0 M P T'],
  "lecomte": ['L IH0 K AA1 M T'],
  "leconte": ['L EH0 K OW1 N T IY0'],
  "lecount": ['L IH0 K UW1 N T'],
  "lecrone": ['L EH0 K R OW1 N IY0'],
  "lecroy": ['L EH1 K R OY0'],
  "lectec": ['L EH1 K T EH2 K'],
  "lecter": ['L EH1 K T ER0'],
  "lectern": ['L EH1 K T ER0 N'],
  "lector": ['L EH1 K T ER0'],
  "lecture": ['L EH1 K CH ER0'],
  "lectured": ['L EH1 K CH ER0 D'],
  "lecturer": ['L EH1 K CH ER0 ER0'],
  "lecturers": ['L EH1 K CH ER0 ER0 Z'],
  "lectures": ['L EH1 K CH ER0 Z'],
  "lecturing": ['L EH1 K CH ER0 IH0 NG'],
  "lecuyer": ['L EH1 K AY0 ER0'],
  "lecy": ['L IY1 S IY0'],
  "led": ['L EH1 D'],
  "leda": ['L IY1 D AH0'],
  "leday": ['L IY1 D EY0'],
  "ledbetter": ['L EH1 D B ER0 T ER0'],
  "ledden": ['L EH1 D AH0 N'],
  "leddy": ['L EH1 D IY0'],
  "ledeen": ['L AH0 D IY1 N'],
  "leder": ['L EH1 D ER0'],
  "lederer": ['L EH1 D ER0 ER0'],
  "lederle": ['L EH1 D ER0 L IY0'],
  "lederman": ['L IY1 D ER0 M AH0 N'],
  "ledesma": ['L EH0 D EH1 S M AH0'],
  "ledet": ['L EH1 D IH0 T'],
  "ledezma": ['L EY0 D EY1 Z M AH0'],
  "ledford": ['L EH1 D F ER0 D'],
  "ledge": ['L EH1 JH'],
  "ledger": ['L EH1 JH ER0'],
  "ledgers": ['L EH1 JH ER0 Z'],
  "ledgerwood": ['L EH1 JH ER0 W UH2 D'],
  "ledges": ['L EH1 JH IH0 Z'],
  "ledin": ['L EH1 D IH0 N'],
  "ledlow": ['L EH1 D L OW1'],
  "ledo": ['L EY1 D OW0'],
  "ledonne": ['L EH1 D AH0 N'],
  "ledoux": ['L IH0 D UW1'],
  "ledvina": ['L EH0 D V IY1 N AH0'],
  "ledwell": ['L EH1 D W EH1 L'],
  "ledwith": ['L EH1 D W IH1 TH'],
  "ledyard": ['L EH1 D Y ER0 D'],
  "lee": ['L IY1'],
  "lee's": ['L IY1 Z'],
  "leeb": ['L IY1 B'],
  "leece": ['L IY1 S'],
  "leech": ['L IY1 CH'],
  "leeches": ['L IY1 CH IH0 Z'],
  "leeco": ['L IY1 K OW0'],
  "leed": ['L IY1 D'],
  "leeder": ['L IY1 D ER0'],
  "leedham": ['L IY1 D HH AH0 M'],
  "leedom": ['L IY1 D AH0 M'],
  "leeds": ['L IY1 D Z'],
  "leeds's": ['L IY1 D Z IH0 Z'],
  "leedy": ['L IY1 D IY0'],
  "leek": ['L IY1 K'],
  "leeks": ['L IY1 K S'],
  "leeman": ['L IY1 M AH0 N'],
  "leeming": ['L IY1 M IH0 NG'],
  "leemon": ['L IY1 M AH0 N'],
  "leen": ['L IY1 N'],
  "leena": ['L IY1 N AH0'],
  "leep": ['L IY1 P'],
  "leeper": ['L IY1 P ER0'],
  "leery": ['L IH1 R IY0'],
  "lees": ['L IY1 Z'],
  "leesburg": ['L IY1 Z B ER0 G'],
  "leese": ['L IY1 S'],
  "leeser": ['L IY1 S ER0'],
  "leeson": ['L IY1 S AH0 N'],
  "leeson's": ['L IY1 S AH0 N Z'],
  "leet": ['L IY1 T'],
  "leetch": ['L IY1 CH'],
  "leete": ['L IY1 T'],
  "leeth": ['L IY1 TH'],
  "leeuwen": ['L UW1 AH0 N', 'L Y UW1 AH0 N'],
  "leever": ['L IY1 V ER0'],
  "leeward": ['L IY1 W ER0 D'],
  "leeway": ['L IY1 W EY2'],
  "lefauve": ['L AH0 F AA1 V'],
  "lefave": ['L IH0 F EY1 V'],
  "lefcourt": ['L EH1 F K AO2 R T'],
  "lefeber": ['L AH0 F EY1 B ER0'],
  "lefebre": ['L AH0 F EY1 B ER0'],
  "lefebure": ['L EH1 F IH0 B Y UW0 R'],
  "lefebvre": ['L AH0 F EY1 B ER0'],
  "lefever": ['L AH0 F EY1 V ER0'],
  "lefevers": ['L AH0 F EY1 V ER0 Z'],
  "lefevre": ['L AH0 F EY1 V ER0'],
  "leff": ['L EH1 F'],
  "leff's": ['L EH1 F S'],
  "leffel": ['L EH1 F AH0 L'],
  "leffert": ['L EH1 F ER0 T'],
  "lefferts": ['L EH1 F ER0 T S'],
  "leffew": ['L EH1 F Y UW0'],
  "leffingwell": ['L EH1 F IH0 NG G W EH0 L'],
  "leffler": ['L EH1 F L ER0'],
  "lefkowitz": ['L EH1 F K AH0 W IH0 T S'],
  "lefler": ['L EH1 F L ER0'],
  "lefleur": ['L IH0 F L ER1'],
  "leflore": ['L EH1 F L ER0'],
  "lefort": ['L EH1 F ER0 T'],
  "lefrak": ['L EH1 F R AE0 K'],
  "lefrancois": ['L EH1 F R AH0 N K W AA0'],
  "lefrere": ['L AH0 F R EH1 R'],
  "left": ['L EH1 F T'],
  "left's": ['L EH1 F T S'],
  "left-brace": ['L EH2 F T B R EY1 S'],
  "left-hand": ['L EH2 F T HH AE1 N D'],
  "left-handed": ['L EH2 F T HH AE1 N D IH0 D'],
  "left-winger": ['L EH2 F T W IH1 NG ER0'],
  "left-wingers": ['L EH2 F T W IH1 NG ER0 Z'],
  "lefthand": ['L EH2 F T HH AE1 N D'],
  "lefthanded": ['L EH2 F T HH AE1 N D IH0 D'],
  "lefties": ['L EH1 F T IY0 Z'],
  "leftism": ['L EH1 F T IH2 Z AH0 M'],
  "leftist": ['L EH1 F T IH0 S T'],
  "leftists": ['L EH1 F T IH0 S T S'],
  "lefton": ['L EH1 F T AH0 N'],
  "leftover": ['L EH1 F T OW2 V ER0'],
  "leftovers": ['L EH1 F T OW2 V ER0 Z'],
  "leftridge": ['L EH1 F T R IH2 JH'],
  "leftward": ['L EH1 F T W ER0 D'],
  "leftwich": ['L EH1 F T W IH0 K'],
  "leftwing": ['L EH1 F T W IH2 NG'],
  "leftwinger": ['L EH1 F T W IH2 NG ER0'],
  "leftwingers": ['L EH1 F T W IH2 NG ER0 Z'],
  "lefty": ['L EH1 F T IY0'],
  "leg": ['L EH1 G'],
  "leg's": ['L EH1 G Z'],
  "legacies": ['L EH1 G AH0 S IY0 Z'],
  "legacy": ['L EH1 G AH0 S IY0'],
  "legal": ['L IY1 G AH0 L'],
  "legalese": ['L EH1 G AH0 L IY2 S'],
  "legalism": ['L IY1 G AH0 L IH2 Z AH0 M'],
  "legalistic": ['L EH2 G AH0 L IH1 S T IH0 K'],
  "legalities": ['L IY0 G AE1 L IH0 T IY0 Z', 'L IH0 G AE1 L IH0 T IY0 Z'],
  "legality": ['L IY0 G AE1 L AH0 T IY0'],
  "legalization": ['L IY2 G AH0 L AH0 Z EY1 SH AH0 N'],
  "legalize": ['L IY1 G AH0 L AY2 Z'],
  "legalized": ['L IY1 G AH0 L AY2 Z D'],
  "legalizing": ['L IY1 G AH0 L AY2 Z IH0 NG'],
  "legally": ['L IY1 G AH0 L IY0'],
  "legals": ['L IY1 G AH0 L Z'],
  "legan": ['L EH1 G AH0 N'],
  "legare": ['L EH0 G AA1 R IY0'],
  "legaspi": ['L EH0 G AA1 S P IY0'],
  "legate": ['L EH1 G AH0 T'],
  "legates": ['L EH1 G AH0 T S'],
  "legato": ['L AH0 G AA1 T OW2'],
  "legault": ['L IH0 G OW1'],
  "lege": ['L IY1 JH'],
  "legend": ['L EH1 JH AH0 N D'],
  "legendary": ['L EH1 JH AH0 N D EH2 R IY0'],
  "legendre": ['L EH1 G IH0 N D R EY0'],
  "legends": ['L EH1 JH AH0 N D Z'],
  "legent": ['L EH1 JH AH0 N T'],
  "legent's": ['L EH1 JH AH0 N T S'],
  "leger": ['L EH1 JH ER0'],
  "legerdemain": ['L EH2 JH ER0 D AH0 M EY1 N'],
  "legere": ['L EH1 G ER0'],
  "leget": ['L EH1 G IH0 T'],
  "legette": ['L IH0 ZH EH1 T'],
  "legg": ['L EH1 G'],
  "legge": ['L EH1 G'],
  "legged": ['L EH1 G AH0 D', 'L EH1 G D'],
  "leggett": ['L EH1 G IH0 T'],
  "leggette": ['L EH1 G EH1 T'],
  "legging": ['L EH1 G IH0 NG'],
  "leggings": ['L EH1 G IH0 NG Z'],
  "leggio": ['L EH1 JH IY0 OW0'],
  "leggitt": ['L EH1 G IH0 T'],
  "leggy": ['L EH1 G IY0'],
  "leghorn": ['L EH1 G HH AO0 R N'],
  "leghorns": ['L EH1 G HH AO0 R N Z'],
  "legibility": ['L EH2 JH AH0 B IH1 L AH0 T IY0'],
  "legible": ['L EH1 JH AH0 B AH0 L'],
  "legion": ['L IY1 JH AH0 N'],
  "legionaries": ['L IY1 JH AH0 N EH2 R IY0 Z'],
  "legionnaire": ['L IY1 JH AH0 N EH2 R'],
  "legionnaire's": ['L IY1 JH AH0 N EH2 R Z'],
  "legionnaires": ['L IY1 JH AH0 N EH2 R Z'],
  "legions": ['L IY1 JH AH0 N Z'],
  "legislate": ['L EH1 JH IH0 S L EY2 T'],
  "legislated": ['L EH1 JH AH0 S L EY2 T AH0 D'],
  "legislates": ['L EH1 JH IH0 S L EY2 T S'],
  "legislating": ['L EH1 JH IH0 S L EY2 T IH0 NG'],
  "legislation": ['L EH2 JH AH0 S L EY1 SH AH0 N'],
  "legislation's": ['L EH2 JH AH0 S L EY1 SH AH0 N Z'],
  "legislative": ['L EH1 JH AH0 S L EY2 T IH0 V'],
  "legislatively": ['L EH1 JH IH0 S L EY2 T IH0 V L IY0'],
  "legislator": ['L EH1 JH AH0 S L EY2 T ER0'],
  "legislators": ['L EH1 JH AH0 S L EY2 T ER0 Z'],
  "legislators'": ['L EH1 JH IH0 S L EY2 T ER0 Z'],
  "legislature": ['L EH1 JH AH0 S L EY2 CH ER0'],
  "legislature's": ['L EH1 JH AH0 S L EY2 CH ER0 Z'],
  "legislatures": ['L EH1 JH IH0 S L EY2 CH ER0 Z'],
  "legit": ['L EH1 JH IH0 T', 'L AH0 JH IH1 T'],
  "legitimacy": ['L AH0 JH IH1 T AH0 M AH0 S IY0', 'L IH0 JH IH1 T AH0 M AH0 S IY0'],
  "legitimate": ['L AH0 JH IH1 T AH0 M AH0 T'],
  "legitimately": ['L AH0 JH IH1 T AH0 M AH0 T L IY0'],
  "legitimize": ['L AH0 JH IH1 T AH0 M AY2 Z', 'L IH0 JH IH1 T AH0 M AY2 Z'],
  "legitimized": ['L IH0 JH IH1 T AH0 M AY2 Z D'],
  "legitimizes": ['L IH0 JH IH1 T AH0 M AY2 Z IH0 Z'],
  "legitimizing": ['L IH0 JH IH1 T AH0 M AY2 Z IH0 NG'],
  "legler": ['L EH1 G L ER0'],
  "legless": ['L EH1 G L AH0 S'],
  "legner": ['L EH1 G N ER0'],
  "lego": ['L EH1 G OW0'],
  "legore": ['L EH1 G AO2 R'],
  "legrand": ['L EH1 G R AE0 N D'],
  "legrande": ['L EH1 G R AE0 N D'],
  "legree": ['L IH0 G R IY1'],
  "legroom": ['L AH0 G R UW1 M'],
  "legros": ['L EH1 G R OW0 Z'],
  "legs": ['L EH1 G Z'],
  "legume": ['L EH1 G Y UW2 M'],
  "legumes": ['L EH1 G Y UW2 M Z'],
  "legwork": ['L EH1 G W ER2 K'],
  "leh": ['L EH1'],
  "lehan": ['L IY1 HH AE0 N'],
  "lehane": ['L AH0 HH EY1 N'],
  "lehder": ['L EH1 D ER0'],
  "lehder's": ['L EH1 D ER0 Z'],
  "lehenbauer": ['L EY1 AH0 N B AW0 ER0'],
  "lehew": ['L EY1 HH Y UW0'],
  "lehigh": ['L IY1 HH AY2'],
  "lehl": ['L EH1 L'],
  "lehman": ['L IY1 M AH0 N'],
  "lehman's": ['L IY1 M AH0 N Z'],
  "lehmann": ['L EY1 M AH0 N'],
  "lehmer": ['L EH1 M ER0'],
  "lehmkuhl": ['L EH1 M K AH0 L'],
  "lehn": ['L EH1 N'],
  "lehne": ['L EH1 N'],
  "lehnen": ['L EH1 N AH0 N'],
  "lehner": ['L EH1 N ER0'],
  "lehnert": ['L EH1 N ER0 T'],
  "lehnhoff": ['L EH1 N HH AO0 F'],
  "lehr": ['L EH1 R'],
  "lehrer": ['L EH1 R ER0'],
  "lehrke": ['L EH1 R K'],
  "lehrman": ['L EH1 R M AH0 N'],
  "lehrmann": ['L EH1 R M AH0 N'],
  "lehtinen": ['L EH1 T IH0 N AH0 N'],
  "lehtonen": ['L IH0 T AA1 N AH0 N'],
  "lei": ['L EY1'],
  "leia": ['L IY1 AA0'],
  "leib": ['L IY1 B'],
  "leibel": ['L AY1 B AH0 L'],
  "leibensperger": ['L AY1 B IH0 N S P ER0 G ER0'],
  "leiber": ['L IY1 B ER0'],
  "leibert": ['L AY1 B ER0 T'],
  "leibfried": ['L AY1 B F ER0 IY0 D'],
  "leibler": ['L IY1 B L ER0'],
  "leibman": ['L IY1 B M AH0 N'],
  "leibniz": ['L IY1 B N IH0 Z'],
  "leibold": ['L AY1 B OW2 L D'],
  "leibovit": ['L IY1 B AH0 V IH0 T'],
  "leibovitz": ['L IY1 B AH0 V IH0 T S'],
  "leibowitz": ['L IY1 B OW0 IH0 T S'],
  "leibrand": ['L AY1 B R AE2 N D', 'L IY1 B R AE2 N D'],
  "leibrand's": ['L AY1 B R AE2 N D Z', 'L IY1 B R AE2 N D Z'],
  "leibrock": ['L AY1 B R AH0 K'],
  "leiby": ['L IY1 B IY0'],
  "leicester": ['L EH1 S T ER0'],
  "leichliter": ['L AY1 K L IY0 T ER0'],
  "leichner": ['L AY1 K N ER0'],
  "leicht": ['L AY1 K T'],
  "leichter": ['L AY1 K T ER0'],
  "leichtman": ['L AY1 K T M AH0 N'],
  "leichty": ['L AY1 K T IY0'],
  "leick": ['L IY1 K'],
  "leider": ['L AY1 D ER0'],
  "leiderman": ['L AY1 D ER0 M AH0 N'],
  "leiderman's": ['L AY1 D ER0 M AH0 N Z'],
  "leidig": ['L AY1 D IH0 G'],
  "leidner": ['L AY1 D N ER0'],
  "leidy": ['L IY1 D IY0'],
  "leier": ['L AY1 ER0'],
  "leif": ['L IY1 F'],
  "leifer": ['L AY1 F ER0'],
  "leifeste": ['L AY1 F IH0 S T'],
  "leifheit": ['L AY1 F HH AY0 T'],
  "leigh": ['L IY1'],
  "leight": ['L EY1 T'],
  "leighton": ['L EY1 T AH0 N'],
  "leighty": ['L EY1 T IY0'],
  "leija": ['L IY1 Y AH0'],
  "leikam": ['L AY1 K AH0 M'],
  "leiken": ['L AY1 K AH0 N'],
  "leiker": ['L AY1 K ER0'],
  "leila": ['L IY1 L AH0'],
  "leilani": ['L AH0 L AA1 N IY0'],
  "leilia": ['L IY1 L IY0 AH0'],
  "leiman": ['L AY1 M AH0 N'],
  "leimbach": ['L AY1 M B AA2 K'],
  "leimer": ['L AY1 M ER0'],
  "leimert": ['L IY1 M ER0 T', 'L AY1 M ER0 T'],
  "lein": ['L IY1 N'],
  "leinart": ['L AY1 N AA0 R T'],
  "leinbach": ['L AY1 N B AA2 K'],
  "leinberger": ['L AY1 N B ER0 G ER0'],
  "leindecker": ['L AY1 N D IH0 K ER0'],
  "leinen": ['L AY1 N AH0 N'],
  "leiner": ['L AY1 N ER0'],
  "leingang": ['L AY1 NG G AH0 NG'],
  "leininger": ['L AY1 N IH0 NG ER0'],
  "leino": ['L EY0 IY1 N OW0'],
  "leinonen": ['L AY1 N AH0 N AH0 N'],
  "leins": ['L IY1 N Z'],
  "leinsdorf": ['L AY1 N Z D AO2 R F'],
  "leinweber": ['L AY1 N W IH0 B ER0'],
  "leiper": ['L IY1 P ER0'],
  "leiphart": ['L AY1 P HH AA0 R T'],
  "leipold": ['L AY1 P OW0 L D'],
  "leipzig": ['L AY1 P S IH0 G'],
  "leis": ['L EY1 Z'],
  "leischner": ['L AY1 SH N ER0'],
  "leise": ['L IY1 S'],
  "leisenring": ['L AY1 S IH0 N R IH0 NG'],
  "leiser": ['L AY1 S ER0'],
  "leisey": ['L IY1 S IY0'],
  "leishman": ['L IY1 SH M AH0 N'],
  "leising": ['L AY1 S IH0 NG'],
  "leisinger": ['L AY1 S IH0 N JH ER0'],
  "leisner": ['L AY1 S N ER0'],
  "leiss": ['L AY1 S'],
  "leist": ['L IY1 IH0 S T'],
  "leister": ['L IY1 S T ER0'],
  "leistikow": ['L AY0 S T IH1 K OW0'],
  "leistner": ['L AY1 S T N ER0'],
  "leisure": ['L EH1 ZH ER0', 'L IY1 ZH ER0'],
  "leisurely": ['L IY1 Z ER0 L IY0'],
  "leisy": ['L IY1 S IY0'],
  "leitch": ['L IY1 CH'],
  "leite": ['L IY1 T'],
  "leiter": ['L AY1 T ER0'],
  "leiterman": ['L AY1 T ER0 M AH0 N'],
  "leith": ['L IY1 TH'],
  "leitha": ['L IY1 DH AH0'],
  "leithia": ['L IY1 DH IY0 AH0'],
  "leitman": ['L AY1 T M AH0 N'],
  "leitmotif": ['L AY1 T M OW0 T IY2 F'],
  "leitner": ['L AY1 T N ER0'],
  "leitz": ['L IY1 T S'],
  "leitzel": ['L AY1 T Z AH0 L'],
  "leitzke": ['L AY1 T S K IY0'],
  "leiva": ['L IY1 V AH0'],
  "leja": ['L EY1 Y AH0'],
  "lejeune": ['L EH1 Y OY0 N'],
  "lek": ['L EH1 K'],
  "lekachman": ['L EH1 K AA2 K M AH0 N'],
  "lekas": ['L IY1 K AH0 Z'],
  "lekberg": ['L EH1 K B ER0 G'],
  "lela": ['L IY1 L AH0'],
  "lelah": ['L EH1 L AH0'],
  "leland": ['L IY1 L AH0 N D'],
  "leleux": ['L IH0 L OW1'],
  "lelia": ['L IY1 L Y AH0'],
  "lellouche": ['L EH0 L UW1 SH'],
  "lem": ['L EH1 M'],
  "lema": ['L IY1 M AH0'],
  "lemaire": ['L AH0 M EH1 R'],
  "lemaitre": ['L IH0 M EY1 T ER0', 'L IH0 M EY1 T R AH0'],
  "leman": ['L IY1 M AH0 N'],
  "lemans": ['L EH1 M AH0 N Z'],
  "lemanski": ['L IH0 M AE1 N S K IY0'],
  "lemar": ['L EH0 M AA1 R'],
  "lemarr": ['L EH0 M AA1 R'],
  "lemaster": ['L IY1 M AE0 S T ER0'],
  "lemasters": ['L AH0 M AE1 S T ER0 Z'],
  "lemay": ['L EH1 M EY0'],
  "lembcke": ['L EH1 M B K IY0'],
  "lemberg": ['L EH1 M B ER0 G'],
  "lemberger": ['L EH1 M B ER0 G ER0'],
  "lembke": ['L EH1 M B K'],
  "lembo": ['L EH1 M B OW0'],
  "lemburg": ['L EH1 M B ER0 G'],
  "lemcke": ['L EH1 M K IY0'],
  "lemelin": ['L EH1 M IH0 L IH0 N'],
  "lemelle": ['L IH0 M EH1 L'],
  "lemen": ['L IY1 M EH0 N'],
  "lemercier": ['L AH0 M ER1 S IY0 ER0'],
  "lemercier's": ['L AH0 M ER1 S IY0 ER0 Z'],
  "lemere": ['L EH1 M ER0'],
  "lemery": ['L EH1 M ER0 IY0'],
  "lemieux": ['L AH0 M Y UW1'],
  "leming": ['L IY1 M IH0 NG', 'L EH1 M IH0 NG'],
  "lemire": ['L EY0 M IH1 R EY0'],
  "lemke": ['L EH1 M K IY0'],
  "lemler": ['L EH1 M L ER0'],
  "lemley": ['L EH1 M L IY0'],
  "lemm": ['L EH1 M'],
  "lemma": ['L EH1 M AH0'],
  "lemme": ['L EH1 M AH0'],
  "lemmen": ['L EH1 M EH0 N'],
  "lemmer": ['L EH1 M ER0'],
  "lemmerman": ['L EH1 M ER0 M AH0 N'],
  "lemmie": ['L EH1 M IY0'],
  "lemming": ['L EH1 M IH0 NG'],
  "lemmings": ['L EH1 M IH0 NG Z'],
  "lemmo": ['L EH1 M OW0'],
  "lemmon": ['L EH1 M AH0 N'],
  "lemmond": ['L EH1 M AH0 N D'],
  "lemmons": ['L EH1 M AH0 N Z'],
  "lemoine": ['L IH0 M OY1 N'],
  "lemon": ['L EH1 M AH0 N'],
  "lemonade": ['L EH1 M AH0 N EY1 D'],
  "lemond": ['L EH1 M AH0 N D'],
  "lemonds": ['L EH1 M AH0 N D Z'],
  "lemons": ['L EH1 M AH0 N Z'],
  "lemont": ['L AH0 M AA1 N T'],
  "lemos": ['L IY1 M OW0 Z'],
  "lemp": ['L EH1 M P'],
  "lempert": ['L EH1 M P ER0 T'],
  "lempke": ['L EH1 M P K'],
  "lemuela": ['L EH0 M UW1 L AH0'],
  "lemur": ['L IY1 M ER0'],
  "lemurs": ['L IY1 M ER0 Z'],
  "lemus": ['L IY1 M AH0 S'],
  "lemzo": ['L EH1 M Z OW0'],
  "len": ['L EH1 N'],
  "lena": ['L IY1 N AH0'],
  "lena's": ['L IY1 N AH0 Z'],
  "lenahan": ['L EH1 N AH0 HH AE0 N'],
  "lenard": ['L EH1 N ER0 D'],
  "lenart": ['L EH1 N ER0 T'],
  "lenarz": ['L EY1 N AA0 R Z'],
  "lencioni": ['L EH0 N CH OW1 N IY0'],
  "lend": ['L EH1 N D'],
  "lender": ['L EH1 N D ER0'],
  "lender's": ['L EH1 N D ER0 Z'],
  "lenderman": ['L EH1 N D ER0 M AH0 N'],
  "lenders": ['L EH1 N D ER0 Z'],
  "lenders'": ['L EH1 N D ER0 Z'],
  "lending": ['L EH1 N D IH0 NG'],
  "lendings": ['L EH1 N D IH0 NG Z'],
  "lendl": ['L EH1 N D AH0 L'],
  "lends": ['L EH1 N D Z'],
  "lene": ['L IY1 N'],
  "lenehan": ['L EH1 N IH0 HH AE0 N'],
  "leneta": ['L EH0 N EH1 T AH0'],
  "lenexa": ['L EH0 N EH1 K S AH0'],
  "leng": ['L EH1 NG'],
  "lengacher": ['L EH1 NG G AH0 K ER0'],
  "lengel": ['L EH1 NG G AH0 L'],
  "lenger": ['L EH1 NG ER0'],
  "lengle": ['L IH1 NG AH0 L'],
  "length": ['L EH1 NG K TH', 'L EH1 NG TH'],
  "lengthen": ['L EH1 NG TH AH0 N', 'L EH1 NG K TH AH0 N'],
  "lengthened": ['L EH1 NG TH AH0 N D', 'L EH1 NG K TH AH0 N D'],
  "lengthening": ['L EH1 NG TH AH0 N IH0 NG', 'L EH1 NG K TH AH0 N IH0 NG'],
  "lengthens": ['L EH1 NG TH AH0 N Z', 'L EH1 NG K TH AH0 N Z'],
  "lengths": ['L EH1 NG K TH S', 'L EH1 NG TH S'],
  "lengthways": ['L EH1 NG TH W EY2 Z'],
  "lengthwise": ['L EH1 NG TH W AY2 Z'],
  "lengthy": ['L EH1 NG TH IY0'],
  "lengyel": ['L EH1 NG Y EH2 L'],
  "lenhard": ['L EH1 N ER0 D'],
  "lenhardt": ['L EH1 N HH AA2 R T'],
  "lenhart": ['L EH1 N HH AA2 R T'],
  "lenhoff": ['L EH1 N HH AO2 F'],
  "leniency": ['L IY1 N Y AH0 N S IY0'],
  "lenient": ['L IY1 N IY0 AH0 N T', 'L IY1 N Y AH0 N T'],
  "leniently": ['L IY1 N Y AH0 N T L IY0'],
  "lenig": ['L EH1 N IH0 G'],
  "lenihan": ['L EH1 N IH0 HH AE0 N'],
  "lenin": ['L EH1 N AH0 N', 'L EH1 N IH0 N'],
  "lenin's": ['L EH1 N IH0 N Z'],
  "leningrad": ['L EH1 N AH0 N G R AE2 D', 'L EH1 N IH0 N G R AE2 D'],
  "lenington": ['L EH1 N IH0 NG T AH0 N'],
  "leninism": ['L EH1 N IH0 N IH2 Z AH0 M'],
  "leninist": ['L EH1 N IH0 N IH0 S T'],
  "lenis": ['L IY1 N AH0 S'],
  "lenita": ['L EH0 N IY1 T AH0'],
  "lenius": ['L IY1 N IY0 IH0 S'],
  "lenk": ['L EH1 NG K'],
  "lenke": ['L EH1 NG K IY0'],
  "lenker": ['L EH1 NG K ER0'],
  "lennane": ['L EH0 N EY1 N', 'L IY1 N AE2 N'],
  "lennar": ['L EH1 N ER0'],
  "lennard": ['L EH1 N ER0 D'],
  "lennartz": ['L EH1 N AA0 R T S'],
  "lennie": ['L EH1 N IY0'],
  "lennig": ['L EH1 N IH0 G'],
  "lenning": ['L EH1 N IH0 NG'],
  "lennon": ['L EH1 N AH0 N'],
  "lennon's": ['L EH1 N AH0 N Z'],
  "lennox": ['L EH1 N AH0 K S'],
  "lenny": ['L EH1 N IY0'],
  "leno": ['L EH1 N OW0', 'L IY1 N OW0'],
  "leno's": ['L EH1 N OW0 Z', 'L IY1 N OW0 Z'],
  "lenon": ['L EH1 N AH0 N'],
  "lenora": ['L EH1 N ER0 AH0'],
  "lenore": ['L AH0 N AO1 R'],
  "lenos": ['L IY1 N OW0 Z'],
  "lenox": ['L EH1 N AH0 K S'],
  "lens": ['L EH1 N Z'],
  "lenscrafter": ['L EH1 N Z K R AE2 F T ER0'],
  "lenscrafters": ['L EH1 N Z K R AE2 F T ER0 Z'],
  "lenses": ['L EH1 N Z AH0 Z', 'L EH1 N Z IH0 Z'],
  "lensing": ['L EH1 N S IH0 NG'],
  "lent": ['L EH1 N T'],
  "lentil": ['L EH1 N T AH0 L'],
  "lentils": ['L EH1 N T AH0 L Z'],
  "lentinan": ['L EH1 N T IH0 N AH0 N'],
  "lentine": ['L EH0 N T IY1 N IY0'],
  "lentini": ['L EH0 N T IY1 N IY0'],
  "lentner": ['L EH1 N T N ER0'],
  "lento": ['L EH1 N T OW0'],
  "lenton": ['L EH1 N T AH0 N'],
  "lents": ['L EH1 N T S'],
  "lentsch": ['L EH1 N CH'],
  "lentz": ['L EH1 N T S'],
  "lentzsch": ['L EH1 N T S'],
  "leny": ['L EH1 N IY0'],
  "lenz": ['L EH1 N Z'],
  "lenz's": ['L EH1 N Z IH0 Z'],
  "lenzen": ['L EH1 N Z AH0 N'],
  "lenzi": ['L EH1 N Z IY0'],
  "lenzini": ['L EH0 N Z IY1 N IY0'],
  "lenzo": ['L EH1 N Z OW0'],
  "leo": ['L IY1 OW0'],
  "leo's": ['L IY1 OW0 Z'],
  "leoda": ['L EH1 D AH0'],
  "leoine": ['L IH0 OY1 N'],
  "leola": ['L EH1 L AH0'],
  "leoma": ['L IH0 OW1 M AH0'],
  "leominster": ['L IY1 OW0 M IH2 N S T ER0'],
  "leon": ['L IY1 AA0 N'],
  "leon's": ['L IY1 AA0 N Z'],
  "leona": ['L IY1 OW0 N AH0'],
  "leonara": ['L EH0 N AA1 R AH0'],
  "leonard": ['L EH1 N ER0 D'],
  "leonard's": ['L EH1 N ER0 D Z'],
  "leonarda": ['L EH0 N AA1 R D AH0'],
  "leonardi": ['L EH0 N AA1 R D IY0'],
  "leonardis": ['L EH1 N AA0 R D IH0 S'],
  "leonardo": ['L IY2 AH0 N AA1 R D OW0'],
  "leone": ['L IY0 OW1 N'],
  "leonel": ['L IY1 OW0 N AH0 L'],
  "leonelle": ['L EH0 N EH1 L'],
  "leonelli": ['L EH0 N EH1 L IY0'],
  "leonerd": ['L IY1 AH0 N ER0 D'],
  "leones": ['L IY0 OW1 N Z'],
  "leonetti": ['L EH0 N EH1 T IY0'],
  "leong": ['L EH1 NG'],
  "leonhard": ['L IY1 AH0 N HH AA0 R T'],
  "leonhardt": ['L EH1 N HH AA0 R T'],
  "leonhart": ['L EH1 N HH AA0 R T'],
  "leoni": ['L EH0 OW1 N IY0', 'L IY0 OW1 N IY0'],
  "leonid": ['L IY1 AH0 N IH0 D'],
  "leonidas": ['L IY0 AO1 N IH0 D AH0 Z'],
  "leonie": ['L EH1 N IY0'],
  "leonora": ['L IY2 AH0 N AO1 R AH0'],
  "leonore": ['L IY1 AH0 N AO0 R'],
  "leontine": ['L EH0 N T IY1 N IY0', 'L IY1 AA0 N T AY1 N'],
  "leontyne": ['L IY1 AA0 N T AY0 N'],
  "leopard": ['L EH1 P ER0 D'],
  "leopards": ['L EH1 P ER0 D Z'],
  "leopold": ['L IY1 AH0 P OW2 L D'],
  "leopoldina": ['L EH0 P OW0 L D IY1 N AH0'],
  "leopoldine": ['L EH0 P OW0 L D IY1 N IY0'],
  "leopoldo": ['L IY2 AH0 P OW1 L D OW0'],
  "leora": ['L EH1 R AH0'],
  "leos": ['L IY1 OW0 Z'],
  "leota": ['L EH1 T AH0'],
  "leotard": ['L IY1 AH0 T AA2 R D'],
  "lep": ['L EH1 P'],
  "lepage": ['L EH1 P IH0 JH'],
  "lepak": ['L EH1 P AH0 K'],
  "lepard": ['L EH1 P ER0 D'],
  "lepe": ['L IY1 P'],
  "leper": ['L EH1 P ER0'],
  "lepera": ['L EH0 P EH1 R AH0'],
  "lepere": ['L EY0 P EH1 R EY0'],
  "lepers": ['L EH1 P ER0 Z'],
  "lepine": ['L EH0 P IY1 N IY0'],
  "lepisto": ['L EH0 P IY1 S T OW0'],
  "lepkowski": ['L IH0 P K AO1 F S K IY0'],
  "lepley": ['L EH1 P L IY0'],
  "lepore": ['L EH0 P AO1 R IY0'],
  "lepp": ['L EH1 P'],
  "leppanen": ['L EH1 P AH0 N AH0 N'],
  "leppard": ['L EH1 P ER0 D'],
  "leppek": ['L EH1 P IH0 K'],
  "lepper": ['L EH1 P ER0'],
  "leppert": ['L EH1 P ER0 T'],
  "leppla": ['L EH1 P L AH0'],
  "leppo": ['L EH1 P OW0'],
  "lepre": ['L EH1 P ER0'],
  "leprechaun": ['L EH1 P ER0 K AW0 N'],
  "leprechauns": ['L EH1 P ER0 K AO2 N Z'],
  "leprosy": ['L EH1 P R AH0 S IY0'],
  "leptin": ['L EH1 P T IH0 N'],
  "leptonic": ['L EH0 P T AA1 N IH0 K'],
  "lequire": ['L EY0 K W IH1 R EY0'],
  "lerach": ['L EH1 R AH0 K'],
  "lerch": ['L ER1 K'],
  "lerche": ['L ER1 CH'],
  "lereah": ['L EH2 R EY1 AH0'],
  "lerette": ['L ER0 EH1 T'],
  "lerew": ['L EH1 R UW0'],
  "lerhman": ['L ER1 M AH0 N'],
  "lerma": ['L ER1 M AH0'],
  "lerman": ['L EH1 R M AH0 N'],
  "lerner": ['L ER1 N ER0'],
  "lerner's": ['L ER1 N ER0 Z'],
  "leroux": ['L ER0 UW1'],
  "leroy": ['L IY1 R OY2'],
  "lerro": ['L EH1 R OW0'],
  "lersch": ['L ER1 SH'],
  "lerum": ['L EH1 R AH0 M'],
  "les": ['L EH1 S', 'L EY1'],
  "lesa": ['L IY1 S AH0'],
  "lesabre": ['L AH0 S EY1 B ER0'],
  "lesabres": ['L EH1 S AH0 B R AH0 S'],
  "lesage": ['L EH1 S IH0 JH'],
  "lesane": ['L EY0 S AA1 N EY0'],
  "lesbian": ['L EH1 Z B IY0 AH0 N'],
  "lesbianism": ['L EH1 Z B IY0 AH0 N IH0 Z M'],
  "lesbians": ['L EH1 Z B IY0 AH0 N Z'],
  "lescaze": ['L AH0 S K EY1 Z'],
  "lesch": ['L EH1 SH'],
  "leschly": ['L EH1 SH L IY0'],
  "leser": ['L EH1 S ER0'],
  "lesesne": ['L EH1 S IH0 N'],
  "lesh": ['L EH1 SH'],
  "lesharo": ['L EH0 SH AA1 R OW0'],
  "lesher": ['L EH1 SH ER0'],
  "lesi": ['L EH1 S IY0'],
  "lesiak": ['L EH1 S IY0 AE0 K'],
  "lesieur": ['L EH1 S IY0 ER0'],
  "lesinski": ['L IH0 S IH1 N S K IY0'],
  "lesion": ['L IY1 ZH AH0 N'],
  "lesions": ['L IY1 ZH AH0 N Z'],
  "leske": ['L EH1 S K'],
  "lesko": ['L EH1 S K OW0'],
  "lesley": ['L EH1 S L IY0'],
  "leslie": ['L EH1 S L IY0'],
  "lesmeister": ['L EH1 S M AY2 S T ER0'],
  "lesner": ['L EH1 S N ER0'],
  "lesniak": ['L EH1 S N IY0 AE0 K'],
  "lesnick": ['L EH1 S N IH0 K'],
  "lesniewski": ['L EH0 S N EH1 F S K IY0', 'L EH0 S N UW1 S K IY0'],
  "lesotho": ['L EH0 S OW1 TH OW0'],
  "lesperance": ['L EH0 S P ER0 AA1 N S'],
  "lespinasse": ['L EH1 S P IH0 N AE0 S'],
  "less": ['L EH1 S'],
  "lessard": ['L EH1 S ER0 D'],
  "lessee": ['L EH0 S IY1'],
  "lessees": ['L EH0 S IY1 Z'],
  "lessen": ['L EH1 S AH0 N'],
  "lessened": ['L EH1 S AH0 N D'],
  "lessening": ['L EH1 S AH0 N IH0 NG'],
  "lessens": ['L EH1 S AH0 N Z'],
  "lesser": ['L EH1 S ER0'],
  "lessig": ['L EH1 S IH0 G'],
  "lessin": ['L EH1 S IH0 N'],
  "lessing": ['L EH1 S IH0 NG'],
  "lessley": ['L EH1 S L IY0'],
  "lessman": ['L EH1 S M AH0 N'],
  "lessnau": ['L EH1 S N OW2', 'L EH1 S N AW2'],
  "lessner": ['L EH1 S N ER0'],
  "lessness": ['L EH1 S N AH0 S'],
  "lesson": ['L EH1 S AH0 N'],
  "lessons": ['L EH1 S AH0 N Z'],
  "lessor": ['L EH1 S ER0'],
  "lessors": ['L EH1 S ER0 Z'],
  "lest": ['L EH1 S T'],
  "lestat": ['L EH1 S T AE2 T'],
  "lester": ['L EH1 S T ER0'],
  "lestrange": ['L EH0 S T R EY1 N JH'],
  "lesuer": ['L EH0 S UW1 ER0'],
  "lesueur": ['L EH0 S UW1 ER0'],
  "lesure": ['L EH0 SH UH1 R'],
  "leszczynski": ['L EH0 SH IH1 N S K IY0'],
  "leszek": ['L EH1 S EH0 K'],
  "let": ['L EH1 T'],
  "let's": ['L EH1 T S'],
  "let-up": ['L EH1 T AH2 P'],
  "leta": ['L EH1 T AH0'],
  "letarte": ['L EH1 T AA0 R T'],
  "letcher": ['L EH1 CH ER0'],
  "letchworth": ['L EH1 CH W ER0 TH'],
  "letdown": ['L EH1 T D AW2 N'],
  "letellier": ['L EH1 T AH0 L IY0 ER0'],
  "letendre": ['L AH0 T AA1 N D R AH0'],
  "leth": ['L EH1 TH'],
  "letha": ['L EH1 TH AH0'],
  "lethal": ['L IY1 TH AH0 L'],
  "lethality": ['L IY0 TH AE1 L AH0 T IY0'],
  "lethargic": ['L AH0 TH AA1 R JH IH0 K'],
  "lethargy": ['L EH1 TH ER0 JH IY0'],
  "lethco": ['L EH1 TH K OW0'],
  "lethia": ['L EH1 TH IY0 AH0'],
  "leticia": ['L AH0 T IH1 SH AH0'],
  "letitia": ['L IH0 T IH1 SH IY0 AH0'],
  "letizia": ['L EH0 T IY1 Z IY0 AH0'],
  "letner": ['L EH1 T N ER0'],
  "leto": ['L IY1 T OW0'],
  "letourneau": ['L EH1 T UH0 R N OW0'],
  "lets": ['L EH1 T S'],
  "letsch": ['L EH1 CH'],
  "letsinger": ['L EH1 T S IH0 N JH ER0'],
  "letson": ['L EH1 T S AH0 N'],
  "lett": ['L EH1 T'],
  "lettau": ['L IH0 T OW1'],
  "letter": ['L EH1 T ER0'],
  "lettered": ['L EH1 T ER0 D'],
  "letterhead": ['L EH1 T ER0 HH EH2 D'],
  "lettering": ['L EH1 T ER0 IH0 NG'],
  "letterkenny": ['L EH2 T ER0 K EH1 N IY0'],
  "letterman": ['L EH1 T ER0 M AH0 N'],
  "letterman's": ['L EH1 T ER0 M AH0 N Z'],
  "letterpress": ['L EH1 T ER0 P R EH2 S'],
  "letters": ['L EH1 T ER0 Z'],
  "lettice": ['L EH1 T IH0 S'],
  "lettie": ['L EH1 T IY0'],
  "lettiere": ['L EH1 T IY0 EH0 R'],
  "lettieri": ['L EH0 T IH1 R IY0'],
  "letting": ['L EH1 T IH0 NG'],
  "lettish": ['L EH1 T IH0 SH'],
  "letts": ['L EH1 T S'],
  "lettuce": ['L EH1 T AH0 S'],
  "letty": ['L EH1 T IY0'],
  "letup": ['L EH1 T AH2 P'],
  "letz": ['L EH1 T S'],
  "leu": ['L UW1'],
  "leu's": ['L UW1 Z'],
  "leubert": ['L UW1 B ER0 T'],
  "leucadia": ['L UW0 K EY1 D IY0 AH0'],
  "leucadia's": ['L UW0 K EY1 D IY0 AH0 Z'],
  "leuck": ['L UW1 K'],
  "leuenberger": ['L UW1 AH0 N B ER0 G ER0'],
  "leuffer": ['L UW1 F ER0'],
  "leugers": ['L OY1 G ER0 Z'],
  "leukemia": ['L UW0 K IY1 M IY0 AH0'],
  "leukocyte": ['L UW1 K AH0 S AY2 T'],
  "leumi": ['L UW1 M IY0'],
  "leung": ['L UW1 NG'],
  "leupold": ['L OY1 P OW0 L D'],
  "leuschner": ['L OY1 SH N ER0'],
  "leuthold": ['L OY1 TH OW0 L D'],
  "leutwiler": ['L UW1 T W AY2 L ER0'],
  "leuzzi": ['L UW1 Z IY0'],
  "lev": ['L EH1 V'],
  "leva": ['L EH1 V AH0'],
  "leval": ['L AH0 V AA1 L'],
  "levalley": ['L EH1 V AH0 L IY0'],
  "levamisole": ['L AH0 V AE1 M IH0 S OW2 L'],
  "levan": ['L EH1 V AH0 N'],
  "levana": ['L IH0 V AE1 N AH0'],
  "levander": ['L EH1 V AH0 N D ER0'],
  "levandoski": ['L IH0 V AH0 N D AW1 S K IY0'],
  "levandowski": ['L IH0 V AH0 N D AO1 F S K IY0'],
  "levangie": ['L EH1 V AH0 NG IY0'],
  "levant": ['L AH0 V AE1 N T'],
  "levario": ['L EY0 V AA1 R IY0 OW0'],
  "levasseur": ['L EH1 V AH0 S ER0'],
  "levay": ['L EH1 V EY0'],
  "levchenko": ['L EH2 V CH EH1 NG K OW0'],
  "leve": ['L IY1 V'],
  "leveck": ['L EH1 V IH0 K'],
  "levee": ['L EH1 V IY0'],
  "levees": ['L EH1 V IY0 Z'],
  "leveille": ['L EH1 V AY0 L'],
  "level": ['L EH1 V AH0 L'],
  "level's": ['L EH1 V AH0 L Z'],
  "leveled": ['L EH1 V AH0 L D'],
  "leveler's": ['L EH1 V AH0 L ER0 Z', 'L EH1 V L ER0 Z'],
  "levelers": ['L EH1 V AH0 L ER0 Z', 'L EH1 V L ER0 Z'],
  "leveling": ['L EH1 V AH0 L IH0 NG', 'L EH1 V L IH0 NG'],
  "levell": ['L EY0 V EY1 L'],
  "levelled": ['L EH1 V AH0 L D'],
  "levels": ['L EH1 V AH0 L Z'],
  "leven": ['L IY1 V AH0 N'],
  "levenberg": ['L IY1 V AH0 N B ER0 G'],
  "levendusky": ['L IH0 V IH0 N D AH1 S K IY0'],
  "levene": ['L EH1 V IY0 N'],
  "levengood": ['L EH1 V IH0 N G UH0 D'],
  "levenhagen": ['L EH1 V IH0 N HH AH0 G AH0 N'],
  "levens": ['L IY1 V AH0 N Z'],
  "levenson": ['L EH1 V IH0 N S AH0 N'],
  "levenstein": ['L IY1 V AH0 N S T AY0 N', 'L IY1 V AH0 N S T IY0 N'],
  "leventhal": ['L EH1 V AH0 N TH AO2 L'],
  "leveque": ['L AH0 V IY1 K'],
  "lever": ['L EH1 V ER0', 'L IY1 V ER0'],
  "leverage": ['L EH1 V ER0 IH0 JH', 'L EH1 V R AH0 JH', 'L IY1 V ER0 IH0 JH'],
  "leveraged": ['L IY1 V ER0 IH0 JH D', 'L EH1 V ER0 IH0 JH D', 'L EH1 V R IH0 JH D'],
  "leveraging": ['L EH1 V R IH0 JH IH0 NG', 'L EH1 V ER0 IH0 JH IH0 NG'],
  "leverenz": ['L EH1 V ER0 IH0 N S'],
  "leverett": ['L EH1 V ER0 EH0 T'],
  "leverette": ['L EH1 V ER0 EH0 T'],
  "leverich": ['L EH1 V ER0 IH0 K'],
  "levering": ['L EH1 V ER0 IH0 NG'],
  "leverone": ['L EH0 V ER0 OW1 N IY0'],
  "levers": ['L EH1 V ER0 Z'],
  "leverson": ['L EH1 V ER0 S AH0 N'],
  "levert": ['L EH1 V ER0 T'],
  "leverton": ['L IH0 V ER1 T AH0 N'],
  "levesque": ['L IH0 V EH1 S K'],
  "levett": ['L EH1 V IH0 T'],
  "levey": ['L IH0 V EY1'],
  "levi": ['L IY1 V AY0'],
  "levi's": ['L IY1 V AY0 Z'],
  "leviathan": ['L AH0 V AY1 AH0 TH AH0 N'],
  "levick": ['L EH1 V IH0 K'],
  "levie": ['L IY1 V IY0'],
  "levied": ['L EH1 V IY0 D'],
  "levien": ['L EH1 V IY0 AH0 N'],
  "levies": ['L EH1 V IY0 Z'],
  "levin": ['L EH1 V IH0 N', 'L AH0 V IH1 N'],
  "levin's": ['L EH1 V IH0 N Z', 'L AH0 V IH1 N Z'],
  "levina": ['L EH0 V IY1 N AH0'],
  "levine": ['L AH0 V IY1 N'],
  "levine's": ['L AH0 V IY1 N Z'],
  "leviner": ['L EH1 V IH0 N ER0'],
  "levings": ['L EH1 V IH0 NG Z'],
  "levingston": ['L IH1 V IH0 NG S T AH0 N'],
  "levins": ['L EH1 V IH0 N Z'],
  "levinsky": ['L IH0 V IH1 N S K IY0'],
  "levinsky's": ['L IH0 V IH1 N S K IY0 Z'],
  "levinson": ['L EH1 V IH0 N S AH0 N'],
  "levinson's": ['L EH1 V IH0 N S AH0 N Z'],
  "levinstone": ['L EH1 V IH0 N S T OW2 N'],
  "levis": ['L EH1 V IH0 S', 'L IY1 V AY2 Z'],
  "levison": ['L EH1 V IH0 S AH0 N'],
  "levit": ['L EH1 V IH0 T'],
  "levitan": ['L EH1 V IH0 T AH0 N'],
  "levitate": ['L EH1 V IH0 T EY2 T'],
  "levitated": ['L EH1 V IH0 T EY2 T IH0 D'],
  "levitating": ['L EH1 V AH0 T EY2 T IH0 NG'],
  "levitation": ['L EH2 V IH0 T EY1 SH AH0 N'],
  "levitch": ['L EH1 V IH0 CH'],
  "levitican": ['L AH0 V IH1 T AH0 K AH0 N'],
  "leviticus": ['L AH0 V IH1 T AH0 K AH0 S'],
  "leviton": ['L IH0 V IH1 T AH0 N'],
  "levitsky": ['L IH0 V IH1 T S K IY0'],
  "levitt": ['L EH1 V IH0 T'],
  "levitt's": ['L EH1 V IH0 T S'],
  "levitte": ['L EH1 V IH0 T'],
  "levitte's": ['L EH1 V IH0 T S'],
  "levittown": ['L EH1 V IH0 T AW2 N'],
  "levittown's": ['L EH1 V IH0 T AW2 N Z'],
  "levity": ['L EH1 V IH0 T IY0'],
  "levitz": ['L EH1 V IH0 T S'],
  "levy": ['L EH1 V IY0', 'L IY1 V IY0'],
  "levy's": ['L EH1 V IY0 Z', 'L IY1 V IY0 Z'],
  "levying": ['L EH1 V IY0 IH0 NG'],
  "lew": ['L UW1'],
  "lewallen": ['L UW0 AO1 L AH0 N'],
  "lewan": ['L UW1 AH0 N'],
  "lewanda": ['L AH0 W AA1 N D AH0'],
  "lewandoski": ['L UW0 AH0 N D AW1 S K IY0'],
  "lewandowski": ['L UW0 AH0 N D AO1 F S K IY0'],
  "lewanna": ['L UW1 IH0 N AH0'],
  "lewd": ['L UW1 D'],
  "lewellen": ['L UW2 EH1 L AH0 N'],
  "lewelling": ['L UW2 EH1 L IH0 NG'],
  "lewellyn": ['L UW2 EH1 L IH0 N'],
  "lewensky": ['L UW2 EH1 N S K IY0'],
  "lewensky's": ['L UW2 EH1 N S K IY0 Z'],
  "lewers": ['L UW1 ER0 Z'],
  "lewey": ['L UW1 IY0'],
  "lewi": ['L UW1 IY0'],
  "lewicki": ['L UW0 IH1 T S K IY0'],
  "lewin": ['L UW1 IH0 N'],
  "lewing": ['L UW1 IH0 NG'],
  "lewins": ['L UW1 IH0 N Z'],
  "lewinski": ['L UW0 IH1 N S K IY0', 'L AH0 W IH1 N S K IY0'],
  "lewinsky": ['L UW0 IH1 N S K IY0', 'L AH0 W IH1 N S K IY0'],
  "lewinsky's": ['L UW0 IH1 N S K IY0 Z', 'L AH0 W IH1 N S K IY0 Z'],
  "lewinsohn": ['L UW1 IH0 N S AH0 N'],
  "lewinton": ['L UW1 IH0 N T AH0 N'],
  "lewis": ['L UW1 IH0 S'],
  "lewis'": ['L UW1 IH0 S'],
  "lewis's": ['L UW1 IH0 S IH0 Z'],
  "lewisburg": ['L UW1 IH0 S B ER0 G'],
  "lewiston": ['L UW1 AH0 S T AH0 N'],
  "lewke": ['L UW1 K IY0'],
  "lewkowicz": ['L UW1 K AH0 V IH0 CH'],
  "lewman": ['L UW1 M AH0 N'],
  "lewter": ['L UW1 T ER0'],
  "lewton": ['L UW1 T AH0 N'],
  "lewy": ['L UW1 IY0'],
  "lex": ['L EH1 K S'],
  "lexical": ['L EH1 K S IH0 K AH0 L'],
  "lexicographer": ['L EH2 K S IH0 K AA1 G R AH0 F ER0'],
  "lexicographers": ['L EH2 K S IH0 K AA1 G R AH0 F ER0 Z'],
  "lexicon": ['L EH1 K S IH0 K AA2 N'],
  "lexicons": ['L EH1 K S IH0 K AA2 N Z'],
  "lexie": ['L EH1 K S IY0'],
  "lexine": ['L EH1 K S AY0 N'],
  "lexington": ['L EH1 K S IH0 NG T AH0 N'],
  "lexiphonic": ['L EH0 K S AH0 F AO1 N AH0 K'],
  "lexis": ['L EH1 K S IH0 S'],
  "lexmark": ['L EH1 K S M AA2 R K'],
  "lexus": ['L EH1 K S AH0 S'],
  "lexus's": ['L EH1 K S AH0 S IH0 Z'],
  "ley": ['L EY1'],
  "leya": ['L EY1 AH0'],
  "leyba": ['L EY1 B AH0'],
  "leyda": ['L EY1 D AH0'],
  "leyden": ['L AY1 D AH0 N'],
  "leyendecker": ['L AY1 N D IH0 K ER0'],
  "leyh": ['L EY1'],
  "leyland": ['L EY1 L AH0 N D'],
  "leyrer": ['L EY1 R ER0'],
  "leys": ['L EY1 Z'],
  "leysen": ['L EY1 S AH0 N'],
  "leysen's": ['L EY1 S AH0 N Z'],
  "leyva": ['L EY1 V AH0'],
  "lezotte": ['L IH0 Z AO1 T'],
  "lhasa": ['L AA1 S AH0'],
  "lheureux": ['L ER0 OW1'],
  "lhommedieu": ['L OW2 M EH0 D Y UW1'],
  "li": ['L IY1'],
  "li's": ['L IY1 Z'],
  "li-kang": ['L IY1 K AE1 NG'],
  "lia": ['L IY1 AH0'],
  "lia's": ['L IY1 AH0 Z'],
  "liabilities": ['L AY2 AH0 B IH1 L AH0 T IY0 Z', 'L AY2 AH0 B IH1 L IH0 T IY0 Z'],
  "liability": ['L AY2 AH0 B IH1 L IH0 T IY0'],
  "liable": ['L AY1 AH0 B AH0 L'],
  "liacos": ['L AY0 AA1 K OW0 S'],
  "liaison": ['L IY0 EY1 Z AA2 N', 'L EY1 Z AA2 N'],
  "liaisons": ['L IY0 EY1 S AA2 N Z'],
  "liam": ['L IY1 AH0 M'],
  "liam's": ['L IY1 AH0 M Z'],
  "liamine": ['L IY2 AH0 M IY1'],
  "lian": ['L Y AA1 N'],
  "lian's": ['L Y AA1 N Z'],
  "liana": ['L IY0 AA1 N AH0'],
  "liane": ['L IY0 AE1 N'],
  "liang": ['L Y AE1 NG', 'L IY0 AE1 NG'],
  "lianna": ['L IY0 AA1 N AH0'],
  "lianne": ['L IY0 AE1 N'],
  "liao": ['L IY0 AW1'],
  "liar": ['L AY1 ER0'],
  "liars": ['L AY1 ER0 Z'],
  "liason": ['L AY1 AH0 S AH0 N', 'L AY1 S AH0 N'],
  "liasson": ['L AY1 AH0 S AH0 N'],
  "liau": ['L IY1 AW0'],
  "lib": ['L IH1 B'],
  "libbed": ['L IH1 B D'],
  "libbey": ['L IH1 B IY0'],
  "libbing": ['L IH1 B IH0 NG'],
  "libby": ['L IH1 B IY2'],
  "libel": ['L AY1 B AH0 L'],
  "libeled": ['L AY1 B AH0 L D'],
  "libelous": ['L AY1 B AH0 L AH0 S'],
  "liberace": ['L IH2 B ER2 AA1 CH IY2'],
  "liberal": ['L IH1 B ER2 AH0 L'],
  "liberalism": ['L IH1 B ER2 AH0 L IH2 Z AH0 M'],
  "liberalism's": ['L IH1 B ER2 AH0 L IH2 Z AH0 M Z'],
  "liberality": ['L IH2 B ER2 AE1 L AH0 T IY2'],
  "liberalization": ['L IH2 B ER2 AH0 L IH0 Z EY1 SH AH0 N'],
  "liberalizations": ['L IH2 B ER2 AH0 L IH0 Z EY1 SH AH0 N Z'],
  "liberalize": ['L IH1 B ER2 AH0 L AY2 Z'],
  "liberalized": ['L IH1 B ER2 AH0 L AY2 Z D'],
  "liberalizes": ['L IH1 B ER2 AH0 L AY2 Z IH0 Z'],
  "liberalizing": ['L IH1 B ER2 AH0 L AY2 Z IH0 NG'],
  "liberally": ['L IH1 B ER2 AH0 L IY2'],
  "liberals": ['L IH1 B ER2 AH0 L Z'],
  "liberals'": ['L IH1 B ER2 AH0 L Z'],
  "liberate": ['L IH1 B ER2 EY2 T'],
  "liberated": ['L IH1 B ER2 EY2 T IH0 D'],
  "liberati": ['L IY0 B ER2 AA1 T IY2'],
  "liberating": ['L IH1 B ER2 EY2 T IH0 NG'],
  "liberation": ['L IH2 B ER2 EY1 SH AH0 N'],
  "liberato": ['L IY0 B ER2 AA1 T OW2'],
  "liberator": ['L IH1 B ER2 EY2 T AH0 R'],
  "liberatore": ['L IY0 B ER0 AA0 T AO1 R IY0'],
  "liberators": ['L IH1 B ER2 EY2 T AH0 R Z'],
  "liberia": ['L AY2 B IH1 R IY2 AH0'],
  "liberia's": ['L AY2 B IH1 R IY2 AH0 Z'],
  "liberian": ['L AY2 B IH1 R IY2 AH0 N'],
  "liberians": ['L AY2 B IH1 R IY2 AH0 N Z'],
  "liberman": ['L IH1 B ER2 M AH0 N'],
  "libert": ['L IH1 B ER2 T'],
  "libertarian": ['L IH2 B ER2 T EH1 R IY2 AH0 N'],
  "libertarians": ['L IH2 B ER0 T EH1 R IY2 AH0 N Z'],
  "liberte": ['L IH2 B ER0 T EY1'],
  "liberti": ['L IY0 B EH1 R T IY2'],
  "liberties": ['L IH1 B ER0 T IY2 Z'],
  "libertine": ['L IH1 B ER0 T IY2 N'],
  "libertines": ['L IH1 B ER0 T IY2 N Z'],
  "liberto": ['L IY0 B EH1 R T OW2'],
  "liberty": ['L IH1 B ER0 T IY2'],
  "liberty's": ['L IH1 B ER0 T IY2 Z'],
  "libido": ['L AH0 B IY1 D OW0'],
  "libman": ['L IH1 B M AH0 N'],
  "libor": ['L IY1 B ER0'],
  "libra": ['L IY1 B R AA0'],
  "librarian": ['L AY0 B R EH1 R IY2 AH0 N'],
  "librarians": ['L AY0 B R EH1 R IY2 AH0 N Z'],
  "libraries": ['L AY1 B R EH0 R IY2 Z'],
  "library": ['L AY1 B R EH0 R IY2'],
  "library's": ['L AY1 B R EH0 R IY2 Z'],
  "libration": ['L AY2 B R EY1 SH AH0 N'],
  "librettist": ['L AH0 B R EH1 T AH0 S T'],
  "libretto": ['L AH0 B R EH1 T OW0', 'L IH0 B R EH1 T OW0'],
  "librivox": ['L IY1 B R AH0 V AA2 K S', 'L IY1 B R IH0 V AA2 K S'],
  "librizzi": ['L IY0 B R IY1 T S IY2'],
  "liby": ['L AY1 B IY2'],
  "libya": ['L IH1 B IY2 AH0'],
  "libya's": ['L IH1 B IY2 AH0 Z'],
  "libyan": ['L IH1 B IY2 AH0 N'],
  "libyans": ['L IH1 B IY2 AH0 N Z'],
  "licari": ['L IY0 K AA1 R IY2'],
  "licata": ['L IY0 K AA1 T AA0'],
  "licausi": ['L IY0 K AO1 S IY2'],
  "licavoli": ['L IY0 K AA0 V OW1 L IY2'],
  "licciardi": ['L IY0 CH AA1 R D IY2'],
  "lice": ['L AY1 S'],
  "licea": ['L IH1 S IY2 AH0'],
  "licence": ['L AY1 S AH0 N S'],
  "licences": ['L AY1 S AH0 N S IH0 Z'],
  "license": ['L AY1 S AH0 N S'],
  "licensed": ['L AY1 S AH0 N S T'],
  "licensee": ['L AY2 S AH0 N S IY1'],
  "licensees": ['L AY2 S AH0 N S IY1 Z'],
  "licenser": ['L AY1 S AH0 N S ER0'],
  "licenses": ['L AY1 S AH0 N S IH0 Z'],
  "licensing": ['L AY1 S AH0 N S IH0 NG'],
  "licensor": ['L AY1 S AH0 N S ER0'],
  "licensure": ['L AY1 S AH0 N CH ER0'],
  "licentious": ['L AY0 S EH1 N CH AH0 S'],
  "lich": ['L IH1 CH'],
  "lichen": ['L AY1 K AH0 N'],
  "lichens": ['L AY1 K AH0 N Z'],
  "lichlyter": ['L IH1 K L AY0 T ER0'],
  "licht": ['L IH1 K T'],
  "lichtblau": ['L IH1 CH T B L AW2'],
  "lichte": ['L IH1 CH T'],
  "lichten": ['L IH1 K T AH0 N'],
  "lichtenberg": ['L IH1 K T AH0 N B ER0 G'],
  "lichtenberger": ['L IH1 K T AH0 N B ER0 G ER0'],
  "lichtenfels": ['L IH1 K T IH0 N F AH0 L Z'],
  "lichtenstein": ['L IH1 K T AH0 N S T IY2 N', 'L IH1 K T AH0 N S T AY2 N'],
  "lichtenwalner": ['L IH1 K T IH0 N W AH0 L N ER0'],
  "lichtenwalter": ['L IH1 K T IH0 N W AH0 L T ER0'],
  "lichter": ['L IH1 K T ER0'],
  "lichterman": ['L IH1 K T ER0 M AH0 N'],
  "lichtman": ['L IH1 K T M AH0 N'],
  "lichty": ['L IH1 CH T IY0'],
  "licio": ['L IH1 S IY0 OW0'],
  "licitra": ['L IY0 CH IY1 T R AH0'],
  "lick": ['L IH1 K'],
  "licked": ['L IH1 K T'],
  "licker": ['L IH1 K ER0'],
  "lickety": ['L IH1 K AH0 T IY0'],
  "licking": ['L IH1 K IH0 NG'],
  "licklider": ['L IH1 K L AY0 D ER0'],
  "licks": ['L IH1 K S'],
  "lickteig": ['L IH1 K T AY2 G'],
  "lico": ['L IY1 K OW0'],
  "licon": ['L IH1 K AH0 N'],
  "licorice": ['L IH1 K ER0 IH0 SH'],
  "lid": ['L IH1 D'],
  "lida": ['L IY1 D AH0'],
  "liddell": ['L IH1 D AH0 L'],
  "liddick": ['L IH1 D IH0 K'],
  "liddicoat": ['L IH1 D IH0 K OW2 T'],
  "liddle": ['L IH1 D AH0 L'],
  "liddy": ['L IH1 D IY0'],
  "liddy's": ['L IH1 D IY0 Z'],
  "lide": ['L AY1 D'],
  "liden": ['L AY1 D AH0 N'],
  "lidgerwood": ['L IH1 JH ER0 W UH2 D'],
  "lido": ['L IY1 D OW0'],
  "lids": ['L IH1 D Z'],
  "lie": ['L AY1'],
  "lieb": ['L IY1 B'],
  "liebe": ['L IY1 B'],
  "liebel": ['L IY1 B AH0 L'],
  "liebeler": ['L IY1 B AH0 L ER0'],
  "liebelt": ['L IY1 B IH0 L T'],
  "liebenow": ['L IY1 B IH0 N OW0'],
  "lieber": ['L IY1 B ER0'],
  "lieberman": ['L IY1 B ER0 M AH0 N'],
  "lieberman's": ['L IY1 B ER0 M AH0 N Z'],
  "liebermann": ['L IY1 B ER0 M AH0 N'],
  "liebert": ['L IY1 B ER0 T'],
  "lieberthal": ['L IY1 B ER0 TH AO2 L'],
  "liebig": ['L IY1 B IH0 G'],
  "liebl": ['L IY1 B AH0 L'],
  "liebler": ['L IY1 B L ER0'],
  "liebling": ['L IY1 B AH0 L IH0 NG', 'L IY1 B L IH0 NG'],
  "liebman": ['L IY1 B M AH0 N'],
  "liebmann": ['L IY1 B M AH0 N'],
  "liebowitz": ['L IY1 B AH0 W IH0 T S'],
  "liechtenstein": ['L IH1 K T AH0 N S T AY2 N'],
  "liechty": ['L IY1 CH T IY0'],
  "lied": ['L AY1 D'],
  "liedel": ['L IY1 D AH0 L'],
  "lieder": ['L IY1 D ER0'],
  "liederman": ['L IY1 D ER0 M AH0 N'],
  "liederman's": ['L IY1 D ER0 M AH0 N Z'],
  "liedtke": ['L IY1 T K IY0'],
  "liedtke's": ['L IY1 T K IY0 Z'],
  "lief": ['L IY1 F'],
  "liefer": ['L IY1 F ER0'],
  "liege": ['L IY1 JH'],
  "liem": ['L IY1 M'],
  "liemandt": ['L IY1 M AE0 N T'],
  "lien": ['L IY1 N'],
  "lienau": ['L AH0 N OW1'],
  "lienemann": ['L IY1 N AH0 M AH0 N'],
  "liener": ['L IY1 N ER0'],
  "lienhard": ['L IY1 N HH AA2 R D'],
  "lienhart": ['L IY1 N HH AA2 R T'],
  "liens": ['L IY1 N Z'],
  "lierman": ['L IH1 R M AH0 N'],
  "liermann": ['L IH1 R M AH0 N'],
  "lies": ['L AY1 Z'],
  "liesch": ['L IY1 SH'],
  "liese": ['L IY1 Z'],
  "lieser": ['L IY1 S ER0'],
  "liesin": ['L IY1 S IH0 N'],
  "lieske": ['L IY1 S K IY0'],
  "lieson": ['L IY1 S AO0 N'],
  "lietz": ['L IY1 T S'],
  "lietzke": ['L IY1 T S K IY0'],
  "lieu": ['L UW1', 'L Y UW1'],
  "lieurance": ['L UW1 R AH0 N S'],
  "lieutenant": ['L UW0 T EH1 N AH0 N T'],
  "lieutenant's": ['L UW0 T EH1 N AH0 N T S'],
  "lieutenants": ['L UW0 T EH1 N AH0 N T S'],
  "life": ['L AY1 F'],
  "life's": ['L AY1 F S'],
  "life-affirming": ['L AY1 F AH0 F ER2 M IH0 NG'],
  "life-changing": ['L AY1 F CH EY2 N JH IH0 NG'],
  "life-conserving": ['L AY1 F K AH0 N S ER1 V IH0 NG'],
  "life-giving": ['L AY1 F G IH2 V IH0 NG'],
  "life-threatening": ['L AY1 F TH R EH2 T N IH0 NG'],
  "lifeblood": ['L AY1 F B L AH2 D'],
  "lifeboat": ['L AY1 F B OW2 T'],
  "lifeboats": ['L AY1 F B OW2 T S'],
  "lifeco": ['L IY1 F K OW2'],
  "lifecycle": ['L AY1 F S AY2 K AH0 L'],
  "lifeguard": ['L AY1 F G AA2 R D'],
  "lifeguards": ['L AY1 F G AA2 R D Z'],
  "lifeless": ['L AY1 F L AH0 S'],
  "lifelike": ['L AY1 F L AY2 K'],
  "lifeline": ['L AY1 F L AY2 N'],
  "lifelines": ['L AY1 F L AY2 N Z'],
  "lifelong": ['L AY1 F L AO1 NG'],
  "lifer": ['L AY1 F ER0'],
  "lifers": ['L AY1 F ER0 Z'],
  "lifes": ['L AY1 F S'],
  "lifesaver": ['L AY1 F S EY2 V ER0'],
  "lifesavers": ['L AY1 F S EY2 V ER0 Z'],
  "lifesaving": ['L AY1 F S EY2 V IH0 NG'],
  "lifespan": ['L AY1 F S P AE2 N'],
  "lifespans": ['L AY1 F S P AE2 N Z'],
  "lifestyle": ['L AY1 F S T AY2 L'],
  "lifestyles": ['L AY1 F S T AY2 L Z'],
  "lifetime": ['L AY1 F T AY2 M'],
  "lifetime's": ['L AY1 F T AY2 M Z'],
  "lifetimes": ['L AY1 F T AY2 M Z'],
  "liff": ['L IH1 F'],
  "liffe": ['L IH1 F'],
  "liffe's": ['L IH1 F S'],
  "lifland": ['L IH1 F L AH0 N D'],
  "lifo": ['L IY1 F OW0'],
  "liford": ['L IH1 F ER0 D'],
  "lifsey": ['L IH1 F S IY0'],
  "lifshitz": ['L IH1 F SH IH0 T S'],
  "lifson": ['L IH1 F S AA2 N'],
  "lifson's": ['L IH1 F S AA2 N Z'],
  "lift": ['L IH1 F T'],
  "lifted": ['L IH1 F T AH0 D', 'L IH1 F T IH0 D'],
  "lifter": ['L IH1 F T ER0'],
  "lifters": ['L IH1 F T ER0 Z'],
  "liftin": ['L IH1 F T IH0 N'],
  "lifting": ['L IH1 F T IH0 NG'],
  "liftoff": ['L IH1 F T AO2 F'],
  "lifton": ['L IH1 F T AH0 N'],
  "lifts": ['L IH1 F T S'],
  "ligachev": ['L IH1 G AH0 CH EH0 V'],
  "ligachev's": ['L IH1 G AH0 CH EH0 V Z'],
  "ligament": ['L IH1 G AH0 M AH0 N T'],
  "ligaments": ['L IH1 G AH0 M AH0 N T S'],
  "ligand": ['L IH1 G AH0 N D'],
  "ligands": ['L IH1 G AH0 N D Z'],
  "ligas": ['L AY1 G AH0 Z'],
  "ligation": ['L AY0 G EY1 SH AH0 N'],
  "ligget": ['L IH1 G IH0 T'],
  "liggett": ['L IH1 G IH0 T'],
  "liggett's": ['L IH1 G EH2 T S'],
  "liggins": ['L IH1 G IH0 N Z'],
  "liggio": ['L IY1 JH IY0 OW0'],
  "lighmtan": ['L AY1 T M AH0 N'],
  "light": ['L AY1 T'],
  "light's": ['L AY1 T S'],
  "light-year": ['L AY1 T Y IH2 R'],
  "light-years": ['L AY1 T Y IH2 R Z'],
  "lightbulb": ['L AY1 T B AH2 L B'],
  "lightbulbs": ['L AY1 T B AH2 L B Z'],
  "lightcap": ['L AY1 T K AE2 P'],
  "lighted": ['L AY1 T AH0 D', 'L AY1 T IH0 D'],
  "lighten": ['L AY1 T AH0 N'],
  "lightened": ['L AY1 T AH0 N D'],
  "lightening": ['L AY1 T AH0 N IH0 NG'],
  "lightens": ['L AY1 T AH0 N Z'],
  "lighter": ['L AY1 T ER0'],
  "lighters": ['L AY1 T ER0 Z'],
  "lightest": ['L AY1 T AH0 S T'],
  "lightfast": ['L AY1 T F AE2 S T'],
  "lightfastness": ['L AY1 T F AE2 S T N AH0 S'],
  "lightfoot": ['L AY1 T F UH2 T'],
  "lighthall": ['L AY1 T HH AO2 L'],
  "lightheaded": ['L AY2 T HH EH1 D AH0 D'],
  "lightheadedness": ['L AY1 T HH EH2 D AH0 D N AH0 S'],
  "lighthearted": ['L AY1 T HH AA2 R T IH0 D'],
  "lighthizer": ['L AY1 T HH AY2 Z ER0'],
  "lighthouse": ['L AY1 T HH AW2 S'],
  "lighthouses": ['L AY1 T HH AW2 S IH0 Z', 'L AY1 T HH AW2 Z AH0 Z'],
  "lighting": ['L AY1 T IH0 NG'],
  "lighting's": ['L AY1 T IH0 NG Z'],
  "lightle": ['L AY1 T AH0 L'],
  "lightly": ['L AY1 T L IY0'],
  "lightman": ['L AY1 T M AH2 N'],
  "lightner": ['L AY1 T N ER0'],
  "lightness": ['L AY1 T N AH0 S'],
  "lightning": ['L AY1 T N IH0 NG'],
  "lightnings": ['L AY1 T N IH0 NG Z'],
  "lights": ['L AY1 T S'],
  "lightsey": ['L AY1 T S IY0'],
  "lightship": ['L AY1 T SH IH2 P'],
  "lightships": ['L AY1 T SH IH2 P S'],
  "lightstone": ['L AY1 T S T OW2 N'],
  "lightweight": ['L AY1 T W EY1 T'],
  "lighty": ['L AY1 T IY0'],
  "ligman": ['L IH1 G M AH0 N'],
  "lignin": ['L IH1 G N IH0 N'],
  "lignite": ['L IH1 G N AY2 T'],
  "ligon": ['L IH1 G AH0 N'],
  "liguori": ['L IY0 G AO1 R IY0'],
  "lihue": ['L IH0 HH W EY1'],
  "lijewski": ['L IH0 Y EH1 F S K IY0'],
  "likable": ['L AY1 K AH0 B AH0 L'],
  "like": ['L AY1 K'],
  "likeable": ['L AY1 K AH0 B AH0 L'],
  "liked": ['L AY1 K T'],
  "likelier": ['L AY1 K L IY0 ER0'],
  "likeliest": ['L AY1 K L IY0 AH0 S T'],
  "likelihood": ['L AY1 K L IY0 HH UH2 D'],
  "likely": ['L AY1 K L IY0'],
  "liken": ['L AY1 K AH0 N'],
  "likened": ['L AY1 K AH0 N D'],
  "likeness": ['L AY1 K N AH0 S'],
  "likenesses": ['L AY1 K N AH0 S IH0 Z'],
  "likening": ['L AY1 K AH0 N IH0 NG'],
  "likens": ['L AY1 K AH0 N Z'],
  "likes": ['L AY1 K S'],
  "likewise": ['L AY1 K W AY2 Z'],
  "likhachov": ['L IH1 K AH0 CH AA0 V'],
  "likhyani": ['L IH0 K Y AA1 N IY0'],
  "liking": ['L AY1 K IH0 NG'],
  "likins": ['L IH1 K IH0 N Z'],
  "likud": ['L IH1 K AH0 D', 'L IY0 K UW1 D'],
  "likud's": ['L IH1 K AH0 D Z', 'L IY0 K UW1 D Z'],
  "lil": ['L IH1 L'],
  "lila": ['L IY1 L AH0'],
  "lilac": ['L AY1 L AE2 K'],
  "lilacs": ['L AY1 L AE2 K S'],
  "lilah": ['L IH1 L AH0'],
  "lilco": ['L IH1 L K OW0'],
  "lilco's": ['L IH1 L K OW0 Z'],
  "lile": ['L AY1 L'],
  "liles": ['L AY1 L Z'],
  "liley": ['L IH1 L IY0'],
  "lili": ['L IH1 L IY0'],
  "lilia": ['L IY1 L IY0 AH0'],
  "lilian": ['L IH1 L IY0 AH0 N', 'L IH1 L Y AH0 N'],
  "liliana": ['L IH2 L IY0 AE1 N AH0'],
  "liliane": ['L IH0 L IY0 AE1 N', 'L IH1 L IY0 AH0 N'],
  "lilias": ['L AY1 L IY0 AH0 Z'],
  "lilien": ['L IH1 L IY0 AH0 N'],
  "lilienthal": ['L IH1 L IY0 N TH AH0 L'],
  "lilies": ['L IH1 L IY0 Z'],
  "lilith": ['L IH1 L IH0 TH'],
  "lilja": ['L IY1 L Y AH0'],
  "liljedahl": ['L IH1 L JH IH0 D AA0 L'],
  "liljegren": ['L IH1 L JH IH0 G R EH0 N'],
  "lill": ['L IH1 L'],
  "lilla": ['L IH1 L AH0'],
  "lillard": ['L IH1 L ER0 D'],
  "lille": ['L IH1 L', 'L IY1 L'],
  "lillehamer": ['L IH1 L IY0 HH AE2 M ER0'],
  "lillehamer's": ['L IH1 L IY0 HH AE2 M ER0 Z'],
  "lillehammer": ['L IH1 L IY0 HH AE2 M ER0'],
  "lillehammer's": ['L IH1 L IY0 HH AE2 M ER0 Z'],
  "liller": ['L IH1 L ER0'],
  "lilley": ['L IH1 L IY0'],
  "lilli": ['L IH1 L IY0'],
  "lillian": ['L IH1 L IY0 AH0 N'],
  "lillian's": ['L IH1 L IY0 AH0 N Z'],
  "lilliana": ['L IH0 L IY0 AE1 N AH0'],
  "lillibridge": ['L IH1 L IH0 B R IH2 JH'],
  "lillich": ['L IH1 L IH0 K'],
  "lillicrop": ['L IH1 L IH0 K R AA0 P'],
  "lillie": ['L IH1 L IY0'],
  "lilliputian": ['L IH2 L AH0 P Y UW1 SH AH0 N'],
  "lillis": ['L IH1 L IH0 S'],
  "lillo": ['L IH1 L OW0'],
  "lilly": ['L IH1 L IY0'],
  "lilly's": ['L IH1 L IY0 Z'],
  "lilt": ['L IH1 L T'],
  "lily": ['L IH1 L IY0'],
  "lily's": ['L IH1 L IY2 Z'],
  "lilyan": ['L IH1 L IY0 AH0 N'],
  "lilybell": ['L IH1 L IY0 B EH2 L'],
  "lim": ['L IH1 M'],
  "lima": ['L AY1 M AH0', 'L IY1 M AH0'],
  "liman": ['L AY1 M AH0 N'],
  "limas": ['L AY1 M AH0 Z', 'L IY1 M AH0 Z'],
  "limb": ['L IH1 M'],
  "limbach": ['L IH1 M B AA2 K'],
  "limbaugh": ['L IH1 M B AO2'],
  "limbaugh's": ['L IH1 M B AO2 Z'],
  "limbed": ['L IH1 M D'],
  "limber": ['L IH1 M B ER0'],
  "limberg": ['L IH1 M B ER0 G'],
  "limbers": ['L IH1 M B ER0 Z'],
  "limbert": ['L IH1 M B ER0 T'],
  "limbless": ['L IH1 M L AH0 S'],
  "limbo": ['L IH1 M B OW0'],
  "limbrick": ['L IH1 M B R IH0 K'],
  "limbs": ['L IH1 M Z'],
  "limburg": ['L IH1 M B ER0 G'],
  "lime": ['L AY1 M'],
  "limehouse": ['L AY1 M HH AW2 S'],
  "limelight": ['L AY1 M L AY2 T'],
  "limerick": ['L IH1 M ER0 IH0 K'],
  "limerick's": ['L IH1 M ER0 IH0 K S'],
  "limericks": ['L IH1 M ER0 IH0 K S'],
  "limes": ['L AY1 M Z'],
  "limestone": ['L AY1 M S T OW2 N'],
  "limestones": ['L AY1 M S T OW2 N Z'],
  "limine": ['L IH0 M AY1 N', 'L IH0 M IY1 N'],
  "liming": ['L AY1 M IH0 NG'],
  "limit": ['L IH1 M AH0 T'],
  "limitation": ['L IH2 M IH0 T EY1 SH AH0 N'],
  "limitations": ['L IH2 M IH0 T EY1 SH AH0 N Z'],
  "limited": ['L IH1 M AH0 T AH0 D', 'L IH1 M IH0 T IH0 D'],
  "limited's": ['L IH1 M AH0 T AH0 D Z', 'L IH1 M IH0 T IH0 D Z'],
  "limiting": ['L IH1 M AH0 T IH0 NG'],
  "limitless": ['L IH1 M AH0 T L AH0 S'],
  "limits": ['L IH1 M AH0 T S', 'L IH1 M IH0 T S'],
  "limmer": ['L IH1 M ER0'],
  "limn": ['L IH1 M'],
  "limned": ['L IH1 M D'],
  "limnology": ['L IH0 M N AA1 L AH0 JH IY0'],
  "limo": ['L IH1 M OW0'],
  "limoges": ['L IH0 M OW1 JH IH0 Z', 'L AH0 M OW1 ZH'],
  "limon": ['L IH1 M AH0 N'],
  "limonite": ['L AY1 M AH0 N AY2 T'],
  "limos": ['L IH1 M OW0 Z', 'L IY1 M OW0 Z'],
  "limousine": ['L IH1 M AH0 Z IY2 N'],
  "limousines": ['L IH1 M AH0 Z IY2 N Z'],
  "limp": ['L IH1 M P'],
  "limped": ['L IH1 M P T'],
  "limpert": ['L IH1 M P ER0 T'],
  "limpets": ['L IH1 M P AH0 T S'],
  "limping": ['L IH1 M P IH0 NG'],
  "limps": ['L IH1 M P S'],
  "lin": ['L IH1 N'],
  "lina": ['L IY1 N AH0'],
  "linafelter": ['L IH1 N AH0 F EH2 L T ER0', 'L AY1 N AH0 F EH2 L T ER0'],
  "linage": ['L AY1 N IH0 JH'],
  "linam": ['L IH1 N AH0 M'],
  "linares": ['L IH1 N ER0 Z'],
  "linc": ['L IH1 NG K'],
  "lincare": ['L IH1 N K EH2 R'],
  "lince": ['L IH1 N S'],
  "lincecum": ['L IH1 N S IH0 K AH0 M'],
  "linch": ['L IH1 N CH'],
  "linchpin": ['L IH1 N CH P IH2 N'],
  "lincicome": ['L IH1 N S IH0 K OW2 M'],
  "linck": ['L IH1 NG K'],
  "lincks": ['L IH1 NG K S'],
  "lincoln": ['L IH1 NG K AH0 N'],
  "lincoln's": ['L IH1 NG K AH0 N Z'],
  "lincolns": ['L IH1 NG K AH0 N Z'],
  "lincolnshire": ['L IH1 NG K AH0 N SH IH2 R'],
  "lind": ['L IH1 N D'],
  "linda": ['L IH1 N D AH0'],
  "linda's": ['L IH1 N D AH0 Z'],
  "lindahl": ['L IH1 N D AA2 L'],
  "lindaman": ['L IH1 N D AH0 M AH0 N'],
  "lindamood": ['L IH1 N D AH0 M UW2 D'],
  "lindane": ['L IH1 N D EY2 N'],
  "lindau": ['L IH1 N D AW0'],
  "lindauer": ['L IH1 N D AW0 ER0'],
  "lindbeck": ['L AY1 N D B EH0 K'],
  "lindberg": ['L AY1 N D B ER0 G'],
  "lindbergh": ['L IH1 N D B ER0 G'],
  "lindblad": ['L IH1 N D B L AH0 D'],
  "lindblom": ['L IH1 N D B L AH0 M'],
  "lindbloom": ['L IH1 N D B L UW2 M'],
  "lindburg": ['L AY1 N D B ER0 G'],
  "linde": ['L IH1 N D'],
  "lindeen": ['L IH1 N D IY0 N'],
  "lindell": ['L IH1 N D AH0 L'],
  "lindeman": ['L IH1 N D M AH0 N'],
  "lindemann": ['L IH1 N D AH0 M AH0 N'],
  "lindemuth": ['L IH1 N D IH0 M UW0 TH'],
  "linden": ['L IH1 N D AH0 N'],
  "linden's": ['L IH1 N D AH0 N Z'],
  "lindenbaum": ['L AY1 N D AH0 N B AW0 M'],
  "lindenberg": ['L IH1 N D AH0 N B ER0 G'],
  "lindenberger": ['L IH1 N D AH0 N B ER0 G ER0'],
  "lindenmuth": ['L IH1 N D IH0 N M UW0 TH'],
  "linder": ['L IH1 N D ER0'],
  "linderman": ['L AY1 N D ER0 M AH0 N'],
  "lindfors": ['L IH1 N D F ER0 Z'],
  "lindgren": ['L IH1 N D G R EH0 N'],
  "lindh": ['L IH1 N D'],
  "lindholm": ['L IH1 N D HH OW2 L M'],
  "lindhorst": ['L IH1 N D HH AO0 R S T'],
  "lindig": ['L IH1 N D IH0 G'],
  "lindler": ['L IH1 N D L ER0'],
  "lindley": ['L IH1 N D L IY0'],
  "lindman": ['L IH1 N D M AH0 N'],
  "lindmark": ['L IH1 N D M AA2 R K'],
  "lindner": ['L IH1 N D N ER0'],
  "lindner's": ['L IH1 N D N ER0 Z'],
  "lindo": ['L IH1 N D OW0'],
  "lindon": ['L IH1 N D AH0 N'],
  "lindow": ['L IH1 N D OW0'],
  "lindquist": ['L IH1 N D K W IH2 S T'],
  "lindroth": ['L IH1 N D R AO2 TH'],
  "lindsay": ['L IH1 N D Z IY0'],
  "lindseth": ['L IH1 N D S IH0 TH'],
  "lindsey": ['L IH1 N D Z IY0'],
  "lindsey's": ['L IH1 N D Z IY0 Z'],
  "lindskog": ['L IH1 N D S K AH0 G'],
  "lindsley": ['L IH1 N D S L IY0'],
  "lindstedt": ['L IH1 N D S T IH0 T'],
  "lindstrand": ['L IH1 N D S T R AH0 N D'],
  "lindstrom": ['L IH1 N D S T R AH0 M'],
  "lindvall": ['L IH1 N D V AH0 L'],
  "lindy": ['L IH1 N D IY0'],
  "line": ['L AY1 N'],
  "line's": ['L AY1 N Z'],
  "line-up": ['L AY1 N AH2 P'],
  "lineage": ['L IH1 N IY0 AH0 JH'],
  "lineages": ['L IH1 N IY0 IH0 JH IH0 Z'],
  "lineal": ['L IH1 N IY0 AH0 L'],
  "linear": ['L IH1 N IY0 ER0'],
  "linear's": ['L IH1 N IY0 ER0 Z'],
  "linearly": ['L IH1 N IY0 ER0 L IY0'],
  "lineback": ['L AY1 N B AE2 K'],
  "linebacker": ['L AY1 N B AE2 K ER0'],
  "linebackers": ['L AY1 N B AE2 K ER0 Z'],
  "linebarger": ['L IH1 N IH0 B AA0 R G ER0', 'L AY1 N B AA0 R G ER0'],
  "linebaugh": ['L IH1 N IH0 B AO0'],
  "lineberger": ['L AY1 N B ER0 G ER0'],
  "lineberry": ['L AY1 N B EH2 R IY0'],
  "lined": ['L AY1 N D'],
  "linehan": ['L IH1 N IH0 HH AE0 N'],
  "lineitem": ['L AY1 N EY0 T AH0 M'],
  "lineman": ['L AY1 N M AH0 N'],
  "linemen": ['L AY1 N M AH0 N'],
  "linen": ['L IH1 N AH0 N'],
  "linenberger": ['L IH1 N AH0 N B ER0 G ER0'],
  "linenger": ['L IH1 N AH0 N JH ER0'],
  "linenger's": ['L IH1 N AH0 N JH ER0 Z'],
  "linens": ['L IH1 N AH0 N Z'],
  "liner": ['L AY1 N ER0'],
  "liner's": ['L AY1 N ER0 Z'],
  "linerboard": ['L AY1 N ER0 B AO2 R D'],
  "liners": ['L AY1 N ER0 Z'],
  "liners'": ['L AY1 N ER0 Z'],
  "lines": ['L AY1 N Z'],
  "lines'": ['L AY1 N Z'],
  "linette": ['L IH0 N EH1 T'],
  "lineup": ['L AY1 N AH2 P'],
  "lineups": ['L AY1 N AH2 P S'],
  "lineweaver": ['L AY1 N W IY2 V ER0'],
  "linford": ['L IH1 N F ER0 D'],
  "ling": ['L IH1 NG'],
  "lingafelter": ['L IH1 NG G AH0 F IH0 L T ER0'],
  "lingard": ['L IH1 NG G ER0 D'],
  "lingelbach": ['L IH1 NG G IH0 L B AA0 K'],
  "lingenfelter": ['L IH1 NG G IH0 N F IH0 L T ER0'],
  "linger": ['L IH1 NG G ER0', 'L IH1 NG ER0'],
  "lingered": ['L IH1 NG G ER0 D'],
  "lingerfelt": ['L IH1 NG G ER0 F EH2 L T'],
  "lingerie": ['L AA1 N ZH ER0 EY2'],
  "lingering": ['L IH1 NG G ER0 IH0 NG', 'L IH1 NG G R IH0 NG'],
  "lingers": ['L IH1 NG G ER0 Z'],
  "lingg": ['L IH1 NG G'],
  "lingle": ['L IH1 NG G AH0 L'],
  "lingner": ['L IH1 NG N ER0'],
  "lingo": ['L IH1 NG G OW0'],
  "lings": ['L IH1 NG Z'],
  "lingua": ['L IH1 NG G W AH0'],
  "linguine": ['L IH0 NG G W IY1 N IY0'],
  "linguist": ['L IH1 NG G W IH0 S T'],
  "linguistic": ['L IH0 NG G W IH1 S T IH0 K'],
  "linguistically": ['L IH0 NG G W IH1 S T IH0 K L IY0'],
  "linguistics": ['L IH0 NG G W IH1 S T IH0 K S'],
  "linguists": ['L IH1 NG G W IH0 S T S'],
  "lingus": ['L IH1 NG G AH0 S'],
  "linh": ['L IH1 N'],
  "linhardt": ['L IH1 N HH AA2 R T'],
  "linhares": ['L IH1 N HH ER0 Z'],
  "linhart": ['L IH1 N HH AA2 R T'],
  "lini": ['L IY1 N IY0'],
  "lining": ['L AY1 N IH0 NG'],
  "lininger": ['L AY1 N IH0 NG ER0'],
  "linings": ['L AY1 N IH0 NG Z'],
  "link": ['L IH1 NG K'],
  "link's": ['L IH1 NG K S'],
  "linkage": ['L IH1 NG K AH0 JH', 'L IH1 NG K IH0 JH'],
  "linkages": ['L IH1 NG K IH0 JH IH0 Z'],
  "linke": ['L IH1 NG K'],
  "linked": ['L IH1 NG K T'],
  "linkedin": ['L IH1 NG K T IH2 N'],
  "linkenhoker": ['L IH1 NG K IH0 N HH AH0 K ER0'],
  "linker": ['L IH1 NG K ER0'],
  "linking": ['L IH1 NG K IH0 NG'],
  "linkletter": ['L IH1 NG K L EH2 T ER0'],
  "linkous": ['L IH1 NG K AH0 S'],
  "links": ['L IH1 NG K S'],
  "linkup": ['L IH1 NG K AH2 P'],
  "linkups": ['L IH1 NG K AH2 P S'],
  "linley": ['L IH1 N L IY0'],
  "linn": ['L IH1 N'],
  "linnane": ['L IH1 N AH0 N'],
  "linnas": ['L IH1 N AH0 S'],
  "linne": ['L IH1 N'],
  "linnea": ['L IH1 N IY0 AH0'],
  "linnehan": ['L IH1 N IH0 HH AE0 N'],
  "linnell": ['L IH1 N AH0 L'],
  "linneman": ['L IH1 N M AH0 N'],
  "linnemann": ['L IH1 N M AH0 N'],
  "linnet": ['L IH1 N IH0 T'],
  "linney": ['L IH1 N IY0'],
  "linnik": ['L IH1 N IH0 K'],
  "lino": ['L IY1 N OW0'],
  "linoleum": ['L AH0 N OW1 L IY0 AH0 M'],
  "linotype": ['L IH1 N OW0 T AY2 P'],
  "linowes": ['L IH1 N OW0 Z'],
  "linquist": ['L IH1 N K W IH0 S T'],
  "lins": ['L IH1 N Z'],
  "linsay": ['L IH1 N S EY0'],
  "linscomb": ['L IH1 N S K AH0 M'],
  "linscott": ['L IH1 N S K AH0 T'],
  "linse": ['L IH1 N S'],
  "linseed": ['L IH1 N S IY2 D'],
  "linsey": ['L IH1 N Z IY0'],
  "linsey-woolsey": ['L IH1 N Z IY0 W UH1 L Z IY0'],
  "linskey": ['L IH1 N S K IY0'],
  "linsky": ['L IH1 N S K IY0'],
  "linsley": ['L IH1 N S L IY0'],
  "linson": ['L IH1 N S AH0 N'],
  "linstrom": ['L IH1 N S T R AH0 M'],
  "lint": ['L IH1 N T'],
  "lintas": ['L IH1 N T AH0 S'],
  "lintel": ['L IH1 N T AH0 L'],
  "linters": ['L IH1 N T ER0 Z'],
  "linthicum": ['L IH1 N TH IH0 K AH0 M'],
  "lintner": ['L IH1 N T N ER0'],
  "linton": ['L IH1 N T AH0 N'],
  "lintz": ['L IH1 N T S'],
  "linus": ['L AY1 N AH0 S'],
  "linux": ['L IH1 N AH0 K S'],
  "linville": ['L IY1 N V IH0 L'],
  "linwick": ['L IH1 N W IH2 K'],
  "linwood": ['L IH1 N W UH2 D'],
  "linz": ['L IH1 N Z'],
  "linzer": ['L IH1 N Z ER0'],
  "linzey": ['L IH1 N Z IY0'],
  "linzy": ['L IH1 N Z IY0'],
  "liomine": ['L IY1 AH0 M AY0 N'],
  "lion": ['L AY1 AH0 N'],
  "lion's": ['L AY1 AH0 N Z'],
  "lionberger": ['L AY1 AH0 N B ER0 G ER0'],
  "lionel": ['L AY1 AH0 N AH0 L'],
  "lionetti": ['L IY0 AH0 N EH1 T IY0', 'L AY0 AH0 N EH1 T IY0'],
  "lionheart": ['L AY1 AH0 N HH AA2 R T'],
  "lionize": ['L AY1 AH0 N AY2 Z'],
  "lionized": ['L AY1 AH0 N AY2 Z D'],
  "lions": ['L AY1 AH0 N Z'],
  "lions'": ['L AY1 AH0 N Z'],
  "liotier": ['L IY0 OW1 T IY0 EY2', 'L IY0 OW1 T IY0 ER0'],
  "liotta": ['L IY0 OW1 T AH0'],
  "liou": ['L IY0 UW1'],
  "lip": ['L IH1 P'],
  "lipa": ['L IY1 P AH0'],
  "lipari": ['L IY0 P AA1 R IY0'],
  "lipe": ['L AY1 P'],
  "lipford": ['L IH1 P F ER0 D'],
  "lipham": ['L IH1 F AH0 M'],
  "lipid": ['L AY1 P AH0 D'],
  "lipide": ['L IH0 P IY1 D'],
  "lipids": ['L IH1 P IH0 D Z'],
  "lipinski": ['L IH0 P IH1 N S K IY0'],
  "lipka": ['L IH1 P K AH0'],
  "lipke": ['L IH1 P K'],
  "lipkin": ['L IH1 P K IH0 N'],
  "lipkind": ['L IH1 P K IH0 N D', 'L IH1 P K AY0 N D'],
  "lipman": ['L IH1 P M AH0 N'],
  "lipnick": ['L IH1 P N IH2 K'],
  "lipoprotein": ['L IH2 P AH0 P R OW1 T IY0 N'],
  "lipoproteins": ['L IH2 P OW0 P R OW1 T IY0 N Z'],
  "liposome": ['L IH1 P AH0 S OW2 M'],
  "liposomes": ['L IH1 P AH0 S OW2 M Z'],
  "liposuction": ['L IH1 P OW0 S AH2 K SH AH0 N', 'L AY1 P OW0 S AH2 K SH AH0 N'],
  "lipovsky": ['L IH0 P AA1 V S K IY0'],
  "lipp": ['L IH1 P'],
  "lippa": ['L IH1 P AH0'],
  "lippard": ['L IH1 P ER0 D'],
  "lippe": ['L IH1 P'],
  "lipped": ['L IH1 P T'],
  "lippens": ['L IH1 P AH0 N Z'],
  "lipper": ['L IH1 P ER0'],
  "lipper's": ['L IH1 P ER0 Z'],
  "lippert": ['L IH1 P ER0 T'],
  "lippi": ['L IH1 P IY0'],
  "lippincott": ['L IH1 P IH0 N K AH0 T'],
  "lippitt": ['L IH1 P IH0 T'],
  "lippman": ['L IH1 P M AH0 N'],
  "lippmann": ['L IH1 P M AH0 N'],
  "lippo": ['L IH1 P OW0'],
  "lippold": ['L IH1 P OW2 L D'],
  "lipps": ['L IH1 P S'],
  "lippy": ['L IH1 P IY0'],
  "lips": ['L IH1 P S'],
  "lips'": ['L IH1 P S'],
  "lipschitz": ['L IH1 P SH IH0 T S'],
  "lipschultz": ['L IH1 P SH AH0 L T S'],
  "lipschutz": ['L IH1 P SH AH0 T S'],
  "lipscomb": ['L IH1 P S K AH0 M'],
  "lipsett": ['L IH1 P S IH0 T'],
  "lipsey": ['L IH1 P S IY0'],
  "lipshie": ['L IH1 P SH IY0'],
  "lipshutz": ['L IH1 P SH AH0 T S'],
  "lipsig": ['L IH1 P S IH0 G'],
  "lipsitz": ['L IH1 P S IH0 T S'],
  "lipski": ['L IH1 P S K IY2'],
  "lipsky": ['L IH1 P S K AY2'],
  "lipson": ['L IH1 P S AH0 N'],
  "lipstein": ['L IH1 P S T IY2 N', 'L IH1 P S T AY2 N'],
  "lipstick": ['L IH1 P S T IH2 K'],
  "lipsticks": ['L IH1 P S T IH2 K S'],
  "liptak": ['L IH1 P T AH0 K'],
  "lipton": ['L IH1 P T AH0 N'],
  "lipton's": ['L IH1 P T AH0 N Z'],
  "lipuma": ['L IY0 P UW1 M AH0'],
  "liquefaction": ['L IH2 K W AH0 F AE1 K SH AH0 N'],
  "liquefied": ['L IH1 K W AH0 F AY2 D'],
  "liquefy": ['L IH1 K W AH0 F AY2'],
  "liqueur": ['L IH0 K ER1'],
  "liqueurs": ['L IH0 K ER1 Z'],
  "liquid": ['L IH1 K W AH0 D', 'L IH1 K W IH0 D'],
  "liquidate": ['L IH1 K W IH0 D EY2 T'],
  "liquidated": ['L IH1 K W IH0 D EY2 T IH0 D'],
  "liquidates": ['L IH1 K W IH0 D EY2 T S'],
  "liquidating": ['L IH1 K W IH0 D EY2 T IH0 NG'],
  "liquidation": ['L IH2 K W IH0 D EY1 SH AH0 N'],
  "liquidations": ['L IH2 K W IH0 D EY1 SH AH0 N Z'],
  "liquidator": ['L IH1 K W IH0 D EY2 T ER0'],
  "liquidators": ['L IH1 K W IH0 D EY2 T ER0 Z'],
  "liquide": ['L IH0 K W AY1 D'],
  "liquidities": ['L IH0 K W IH1 D AH0 T IY0 Z'],
  "liquidity": ['L IH0 K W IH1 D AH0 T IY0', 'L IH0 K W IH1 D IH0 T IY0'],
  "liquids": ['L IH1 K W AH0 D Z', 'L IH1 K W IH0 D Z'],
  "liqun": ['L IH0 K UW1 N'],
  "liquor": ['L IH1 K ER0'],
  "liquori": ['L IY0 K AO1 R IY0'],
  "liquors": ['L IH1 K ER0 Z'],
  "lira": ['L IH1 R AH0'],
  "lira's": ['L IH1 R AH0 Z'],
  "lire": ['L IH1 R AH0'],
  "lirette": ['L ER0 EH1 T'],
  "liro": ['L IH1 R OW0'],
  "liroff": ['L IH1 R AO0 F'],
  "lis": ['L IH1 S'],
  "lisa": ['L IY1 S AH0'],
  "lisa's": ['L IY1 S AH0 Z'],
  "lisabet": ['L IH1 S AH0 B EH0 T'],
  "lisabeth": ['L IH1 S AH0 B EH0 TH'],
  "lisak": ['L IH1 S AH0 K'],
  "lisanti": ['L IH0 S AE1 N T IY0'],
  "lisbeth": ['L IH1 S B IH0 TH'],
  "lisbon": ['L IH1 Z B AH0 N'],
  "lisby": ['L IH1 S B IY0'],
  "lisco": ['L IH1 S K OW0'],
  "liscom": ['L IH1 S K AH0 M'],
  "lise": ['L AY1 Z'],
  "lisec": ['L IH1 Z AH0 K'],
  "lisenbee": ['L IH0 S EH1 N B IY0', 'L IH1 S AH0 N B IY0'],
  "lisenby": ['L IH1 S IH0 N B IY0'],
  "lisette": ['L IH0 S EH1 T'],
  "lish": ['L IH1 SH'],
  "lishman": ['L IH1 SH M AH0 N'],
  "lisi": ['L IY1 S IY0'],
  "lisiecki": ['L IH0 S IY1 T S K IY0'],
  "lisk": ['L IH1 S K'],
  "liska": ['L IH1 S K AH0'],
  "liske": ['L IH1 S K'],
  "liskey": ['L IH1 S K IY0'],
  "lisko": ['L IH1 S K OW0'],
  "lisle": ['L AY1 AH0 L'],
  "lisman": ['L IH1 Z M AH0 N'],
  "lisowski": ['L IH0 S AO1 F S K IY0'],
  "lisp": ['L IH1 S P'],
  "liss": ['L IH1 S'],
  "lissa": ['L IH1 S AH0'],
  "lissack": ['L IH1 S AH0 K'],
  "lissie": ['L IH1 S IY0'],
  "lissner": ['L IH1 S N ER0'],
  "lissouba": ['L IH0 S UW1 B AH0'],
  "lissy": ['L IH1 S IY0'],
  "list": ['L IH1 S T'],
  "list's": ['L IH1 S T S'],
  "listed": ['L IH1 S T AH0 D', 'L IH1 S T IH0 D'],
  "listen": ['L IH1 S AH0 N'],
  "listened": ['L IH1 S AH0 N D'],
  "listener": ['L IH1 S AH0 N ER0', 'L IH1 S N ER0'],
  "listeners": ['L IH1 S AH0 N ER0 Z', 'L IH1 S N ER0 Z'],
  "listening": ['L IH1 S AH0 N IH0 NG', 'L IH1 S N IH0 NG'],
  "listens": ['L IH1 S AH0 N Z'],
  "lister": ['L IH1 S T ER0'],
  "listeria": ['L IH0 S T IH1 R IY0 AH0'],
  "listerine": ['L IH1 S T ER0 IY2 N'],
  "listerines": ['L IH1 S T ER0 IY2 N Z'],
  "listing": ['L IH1 S T IH0 NG'],
  "listings": ['L IH1 S T IH0 NG Z'],
  "listless": ['L IH1 S T L AH0 S'],
  "listlessly": ['L IH1 S T L AH0 S L IY0'],
  "liston": ['L IH1 S T AH0 N'],
  "lists": ['L IH1 S T S'],
  "liszewski": ['L IH0 SH EH1 F S K IY0'],
  "liszka": ['L IH1 SH K AH0'],
  "liszt": ['L IH1 S T'],
  "lit": ['L IH1 T'],
  "lita": ['L IY1 T AH0'],
  "litaker": ['L IH1 T EY0 K ER0'],
  "litalien": ['L IH1 T AH0 L IY0 N'],
  "litan": ['L AY1 T AH0 N'],
  "litanies": ['L IH1 T AH0 N IY0 Z'],
  "litany": ['L IH1 T AH0 N IY0'],
  "litaro": ['L IH0 T AA1 R OW0'],
  "litchfield": ['L IH1 CH F IY0 L D'],
  "litchfield's": ['L IH1 CH F IY0 L D Z'],
  "litchford": ['L IH1 CH F ER0 D'],
  "litchford's": ['L IH1 CH F ER0 D Z'],
  "litco": ['L IH1 T K OW2'],
  "litco's": ['L IH1 T K OW2 Z'],
  "lite": ['L AY1 T'],
  "litem": ['L AY1 T EH0 M', 'L IY1 T EH0 M'],
  "liter": ['L IY1 T ER0'],
  "literacy": ['L IH1 T ER0 AH0 S IY0'],
  "literal": ['L IH1 T ER0 AH0 L'],
  "literally": ['L IH1 T ER0 AH0 L IY0', 'L IH1 T R AH0 L IY0'],
  "literary": ['L IH1 T ER0 EH2 R IY0'],
  "literate": ['L IH1 T ER0 AH0 T'],
  "literati": ['L IH2 T ER0 AA1 T IY0'],
  "literature": ['L IH1 T ER0 AH0 CH ER0'],
  "literaturnaya": ['L IH0 T EH2 R AH0 T ER0 N AY1 AH0'],
  "liters": ['L IY1 T ER0 Z'],
  "lites": ['L AY1 T S'],
  "lithe": ['L AY1 DH'],
  "litherland": ['L IH1 TH ER0 L AH0 N D'],
  "lithgow": ['L IH1 TH G AW0'],
  "lithic": ['L IH1 TH IH0 K'],
  "lithium": ['L IH1 TH IY0 AH0 M'],
  "lithograph": ['L IH1 TH AH0 G R AE2 F'],
  "lithographic": ['L IH2 TH AH0 G R AE1 F IH0 K'],
  "lithographs": ['L IH1 TH AH0 G R AE2 F S'],
  "lithography": ['L AH0 TH AA1 G R AH0 F IY0'],
  "lithotripter": ['L IH1 TH AH0 T R IH2 P T ER0'],
  "lithuania": ['L IH2 TH AH0 W EY1 N IY0 AH0'],
  "lithuania's": ['L IH2 TH AH0 W EY1 N IY0 AH0 Z'],
  "lithuanian": ['L IH2 TH AH0 W EY1 N IY0 AH0 N'],
  "lithuanians": ['L IH2 TH AH0 W EY1 N IY0 AH0 N Z'],
  "litigant": ['L IH1 T IH0 G AH0 N T'],
  "litigants": ['L IH1 T IH0 G AH0 N T S'],
  "litigate": ['L IH1 T IH0 G EY2 T'],
  "litigated": ['L IH1 T IH0 G EY2 T IH0 D'],
  "litigates": ['L IH1 T IH0 G EY2 T S'],
  "litigating": ['L IH1 T IH0 G EY2 T IH0 NG'],
  "litigation": ['L IH2 T AH0 G EY1 SH AH0 N'],
  "litigations": ['L IH2 T AH0 G EY1 SH AH0 N Z'],
  "litigator": ['L IH1 T AH0 G EY2 T ER0'],
  "litigators": ['L IH1 T AH0 G EY2 T ER0 Z'],
  "litigious": ['L IH0 T IH1 JH AH0 S', 'L IH1 T IH0 JH AH0 S'],
  "litke": ['L IH1 T K IY0'],
  "litle": ['L AY1 T AH0 L'],
  "litman": ['L IH1 T M AH0 N'],
  "litmus": ['L IH1 T M AH0 S'],
  "lits": ['L IH1 T S'],
  "litsey": ['L IH1 T S IY0'],
  "litt": ['L IH1 T'],
  "litteken": ['L IH1 T IH0 K AH0 N'],
  "littell": ['L IH1 T AH0 L'],
  "litten": ['L IH1 T AH0 N'],
  "litter": ['L IH1 T ER0'],
  "litteral": ['L IH1 T ER0 AH0 L'],
  "littered": ['L IH1 T ER0 D'],
  "littering": ['L IH1 T ER0 IH0 NG'],
  "litters": ['L IH1 T ER0 Z'],
  "littig": ['L IH1 T IH0 G'],
  "little": ['L IH1 T AH0 L'],
  "little's": ['L IH1 T AH0 L Z'],
  "little-rock": ['L IH1 T AH0 L R AO1 K'],
  "littleboy": ['L IH1 T AH0 L B OY2'],
  "littlechild": ['L IH1 T AH0 L CH AY2 L D'],
  "littlefield": ['L IH1 T AH0 L F IY2 L D'],
  "littleford": ['L IH1 T AH0 L F ER0 D'],
  "littlejohn": ['L IH1 T AH0 L JH AA2 N'],
  "littlepage": ['L IH1 T AH0 L P EY2 JH'],
  "littler": ['L IH1 T AH0 L ER0', 'L IH1 T L ER0'],
  "littles": ['L IH1 T AH0 L Z'],
  "littlest": ['L IH1 T AH0 L AH0 S T'],
  "littleton": ['L IH1 T AH0 L T AH0 N'],
  "littlewood": ['L IH1 T AH0 L W UH2 D'],
  "littman": ['L IH1 T M AH0 N'],
  "littmann": ['L IH1 T M AH0 N'],
  "litton": ['L IH1 T AH0 N'],
  "litton's": ['L IH1 T AH0 N Z'],
  "littoral": ['L IH1 T ER0 AH0 L'],
  "littrell": ['L IH1 T R AH0 L'],
  "litts": ['L IH1 T S'],
  "litty": ['L IH1 T IY0'],
  "liturgical": ['L AH0 T ER1 JH IH0 K AH0 L'],
  "liturgy": ['L IH1 T ER0 JH IY0'],
  "litvack": ['L IH1 T V AE0 K'],
  "litvak": ['L IH1 T V AH0 K'],
  "litvin": ['L IH1 T V IH0 N'],
  "litwack": ['L IH1 T W AO0 K'],
  "litwak": ['L IH1 T W AH0 K'],
  "litwiller": ['L IH0 T W IH1 L ER0'],
  "litwin": ['L IH1 T W IH0 N'],
  "litz": ['L IH1 T S'],
  "litzenberg": ['L IH1 T Z AH0 N B ER0 G'],
  "litzenberger": ['L IH1 T Z AH0 N B ER0 G ER0'],
  "litzinger": ['L IH1 T Z IH0 NG ER0'],
  "liu": ['L Y UW1'],
  "liuzza": ['L IY0 UW1 T S AH0'],
  "liuzzi": ['L IY0 UW1 T S IY0'],
  "liv": ['L IH1 V'],
  "livable": ['L IH1 V AH0 B AH0 L'],
  "livan": ['L IY0 V AA1 N'],
  "livan's": ['L IY0 V AA1 N Z'],
  "live": ['L AY1 V', 'L IH1 V'],
  "lived": ['L IH1 V D', 'L AY1 V D'],
  "livelier": ['L AY1 V L IY0 ER0'],
  "liveliest": ['L AY1 V L IY2 AH0 S T'],
  "livelihood": ['L AY1 V L IY0 HH UH2 D'],
  "livelihoods": ['L AY1 V L IY0 HH UH2 D Z'],
  "liveliness": ['L AY1 V L IY0 N AH0 S'],
  "lively": ['L AY1 V L IY0'],
  "liven": ['L AY1 V AH0 N'],
  "livened": ['L AY1 V AH0 N D'],
  "livengood": ['L IH1 V IH0 N G UH0 D'],
  "liver": ['L IH1 V ER0'],
  "livergood": ['L IH1 V ER0 G UH2 D'],
  "liveried": ['L IH1 V R IY0 D'],
  "liverman": ['L IH1 V ER0 M AH0 N'],
  "livermore": ['L IH0 V ER0 M AO1 R'],
  "livernois": ['L IH1 V ER0 N W AA2'],
  "liverpool": ['L IH1 V ER0 P UW2 L'],
  "livers": ['L IH1 V ER0 Z'],
  "liverwort": ['L IH1 V ER0 W ER0 T'],
  "liverworts": ['L IH1 V ER0 W ER0 T S'],
  "livery": ['L IH1 V ER0 IY0'],
  "lives": ['L IH1 V Z', 'L AY1 V Z'],
  "lives'": ['L AY1 V Z'],
  "livesay": ['L IH1 V IH0 S EY0'],
  "livesey": ['L IH1 V IH0 S IY0'],
  "livestock": ['L AY1 V S T AA2 K'],
  "livezey": ['L IH1 V IH0 Z IY0'],
  "livia": ['L IH1 V IY0 AH0'],
  "livid": ['L IH1 V IH0 D'],
  "lividity": ['L IH0 V IH1 D IH0 T IY0'],
  "livin'": ['L IH1 V IH0 N'],
  "living": ['L IH1 V IH0 NG'],
  "livingood": ['L IH1 V IH0 N G UH2 D'],
  "livingroom": ['L IH1 V IH0 NG R UW2 M'],
  "livingrooms": ['L IH1 V IH0 NG R UW2 M Z'],
  "livings": ['L IH1 V IH0 NG Z'],
  "livingston": ['L IH1 V IH0 NG S T AH0 N'],
  "livingston's": ['L IH1 V IH0 NG S T AH0 N Z'],
  "livingstone": ['L IH1 V IH0 NG S T OW2 N'],
  "livingstone's": ['L IH1 V IH0 NG S T OW2 N Z'],
  "livingwell": ['L IH1 V IH0 NG W EH2 L'],
  "livingwell's": ['L IH1 V IH0 NG W EH2 L Z'],
  "livolsi": ['L IY0 V OW1 L S IY0'],
  "livonia": ['L IH0 V OW1 N Y AH0'],
  "livor": ['L IH0 V AO1 R'],
  "livoti": ['L IY0 V OW1 T IY0'],
  "livsey": ['L IH1 V Z IY0'],
  "livvie": ['L IH1 V IY0'],
  "liz": ['L IH1 Z'],
  "liz's": ['L IH1 Z AH0 Z'],
  "liza": ['L IY1 Z AH0'],
  "lizabeth": ['L IH1 Z AH0 B EH0 TH'],
  "lizak": ['L IH1 Z AH0 K'],
  "lizard": ['L IH1 Z ER0 D'],
  "lizard's": ['L IH1 Z ER0 D Z'],
  "lizards": ['L IH1 Z ER0 D Z'],
  "lizarraga": ['L IY0 Z AA0 R AA1 G AH0'],
  "lizhi": ['L IH1 Z IY0'],
  "lizotte": ['L IH0 Z AO1 T'],
  "lizzie": ['L IH1 Z IY0'],
  "lizzy": ['L IH1 Z IY0'],
  "ljubljana": ['L Y UW1 B L Y AA0 N AH0'],
  "ljubomir": ['L Y UW1 B OW0 M IH2 R'],
  "llama": ['L AA1 M AH0'],
  "llamas": ['L AA1 M AH0 Z'],
  "llana": ['L AE1 N AH0'],
  "llanas": ['L AE1 N AH0 Z'],
  "llanes": ['L EY1 N Z'],
  "llano": ['L AA1 N OW0'],
  "llanos": ['L AA1 N OW0 Z'],
  "llanview": ['L AA2 N V Y UW1'],
  "llc": ['EH2 L EH2 L S IY2'],
  "llerena": ['L EH0 R EY1 N AH0'],
  "llewellyn": ['L UW2 EH1 L IH0 N'],
  "llewelyn": ['L UW1 IH0 L IH0 N'],
  "llorens": ['L AO0 R EH0 N Z'],
  "llorente": ['L AO0 R EH1 N T EY0'],
  "llosa": ['L OW1 S AH0'],
  "llosa's": ['L OW1 S AH0 Z'],
  "llovio": ['L OW1 V IY0 OW0'],
  "lloyd": ['L OY1 D'],
  "lloyd's": ['L OY1 D Z'],
  "lloyds": ['L OY1 D Z'],
  "lloyds'": ['L OY1 D Z'],
  "ln": ['L EY1 N'],
  "lo": ['L OW1'],
  "lo's": ['L OW1 Z'],
  "load": ['L OW1 D'],
  "loaded": ['L OW1 D IH0 D'],
  "loader": ['L OW1 D ER0'],
  "loaders": ['L OW1 D ER0 Z'],
  "loadholt": ['L OW1 D HH OW2 L T'],
  "loading": ['L OW1 D IH0 NG'],
  "loadings": ['L OW1 D IH0 NG Z'],
  "loadman": ['L OW1 D M AH0 N'],
  "loadman's": ['L OW1 D M AH0 N Z'],
  "loads": ['L OW1 D Z'],
  "loaf": ['L OW1 F'],
  "loafer": ['L OW1 F ER0'],
  "loafers": ['L OW1 F ER0 Z'],
  "loafs": ['L OW1 F S'],
  "loaiza": ['L OW0 AA0 IY1 Z AH0'],
  "loam": ['L OW1 M'],
  "loamy": ['L OW1 M IY0'],
  "loan": ['L OW1 N'],
  "loan's": ['L OW1 N Z'],
  "loaned": ['L OW1 N D'],
  "loaning": ['L OW1 N IH0 NG'],
  "loanloss": ['L OW1 N L AO2 S'],
  "loans": ['L OW1 N Z'],
  "loans'": ['L OW1 N Z'],
  "loar": ['L AO1 R'],
  "loath": ['L OW1 TH'],
  "loathe": ['L OW1 DH'],
  "loathed": ['L OW1 DH D'],
  "loathing": ['L OW1 TH IH0 NG'],
  "loathsome": ['L OW1 DH S AH0 M', 'L OW1 TH S AH0 M'],
  "loaves": ['L OW1 V Z'],
  "lob": ['L AA1 B'],
  "lobato": ['L OW0 B AA1 T OW0'],
  "lobaugh": ['L AA1 B AO0'],
  "lobb": ['L AA1 B'],
  "lobban": ['L AA1 B AH0 N'],
  "lobbed": ['L AA1 B D'],
  "lobbia": ['L AA1 B IY0 AH0'],
  "lobbied": ['L AA1 B IY0 D'],
  "lobbies": ['L AA1 B IY0 Z'],
  "lobbing": ['L AA1 B IH0 NG'],
  "lobby": ['L AA1 B IY0'],
  "lobby's": ['L AA1 B IY0 Z'],
  "lobbying": ['L AA1 B IY0 IH0 NG'],
  "lobbyist": ['L AA1 B IY0 AH0 S T'],
  "lobbyists": ['L AA1 B IY0 IH0 S T S'],
  "lobbyists'": ['L AA1 B IY0 IH0 S T S'],
  "lobdell": ['L AA1 B D AH0 L'],
  "lobe": ['L OW1 B'],
  "lobed": ['L OW1 B D'],
  "lobel": ['L OW1 B AH0 L'],
  "lobell": ['L OW0 B EH1 L'],
  "lobello": ['L OW0 B EH1 L OW0'],
  "lober": ['L OW1 B ER0'],
  "loberg": ['L OW1 B ER0 G'],
  "lobes": ['L OW1 B Z'],
  "lobianco": ['L OW0 B IY0 AA1 N K OW0'],
  "loblaw": ['L AA0 B L AO1'],
  "loblolly": ['L AA1 B L AA2 L IY0'],
  "lobo": ['L OW1 B OW0'],
  "lobos": ['L OW1 B OW0 S'],
  "lobosco": ['L OW0 B OW1 S K OW0'],
  "lobotomy": ['L OW1 B OW2 T OW2 M IY0'],
  "lobs": ['L AA1 B Z'],
  "lobster": ['L AA1 B S T ER0'],
  "lobster's": ['L AA1 B S T ER0 Z'],
  "lobsterman": ['L AA1 B S T ER0 M AH0 N'],
  "lobstermen": ['L AA1 B S T ER0 M IH0 N'],
  "lobsters": ['L AA1 B S T ER0 Z'],
  "lobue": ['L OW1 B W EH0'],
  "lobules": ['L AA1 B Y UW2 L Z'],
  "loca": ['L OW1 K AH0'],
  "locadia": ['L OW0 K EY1 D IY0 AH0'],
  "local": ['L OW1 K AH0 L'],
  "local's": ['L OW1 K AH0 L Z'],
  "locale": ['L OW0 K AE1 L'],
  "locales": ['L OW0 K AE1 L Z'],
  "localities": ['L OW0 K AE1 L IH0 T IY0 Z'],
  "locality": ['L OW0 K AE1 L AH0 T IY0'],
  "localization": ['L OW2 K AH0 L AH0 Z EY1 SH AH0 N'],
  "localize": ['L OW1 K AH0 L AY2 Z'],
  "localized": ['L OW1 K AH0 L AY2 Z D'],
  "locally": ['L OW1 K AH0 L IY0'],
  "locals": ['L OW1 K AH0 L Z'],
  "locascio": ['L AH0 K AE1 S IY0 OW0'],
  "locastro": ['L AH0 K AE1 S T R OW0'],
  "locate": ['L OW1 K EY2 T'],
  "located": ['L OW1 K EY2 T AH0 D', 'L OW1 K EY2 D'],
  "locatelli": ['L OW0 K AA0 T EH1 L IY0'],
  "locates": ['L OW1 K EY2 T S'],
  "locating": ['L OW1 K EY2 T IH0 NG'],
  "location": ['L OW0 K EY1 SH AH0 N'],
  "locations": ['L OW0 K EY1 SH AH0 N Z'],
  "locator": ['L OW1 K EY2 T ER0'],
  "locators": ['L OW1 K EY2 T ER0 Z'],
  "locey": ['L OW1 S IY0'],
  "loch": ['L AA1 K'],
  "locher": ['L AA1 K ER0'],
  "lochhead": ['L AA1 K HH EH2 D'],
  "lochner": ['L AA1 K N ER0'],
  "lochridge": ['L AA1 K R IH0 JH'],
  "loci": ['L OW1 K IY0', 'L OW1 K AY0'],
  "locicero": ['L OW0 CH IY0 CH EH1 R OW0'],
  "lock": ['L AA1 K'],
  "locka": ['L AA1 K AH0'],
  "lockaby": ['L AA1 K AH0 B IY0'],
  "lockamy": ['L AA1 K AH0 M IY0'],
  "lockard": ['L AA1 K ER0 D'],
  "lockart": ['L AA1 K AA2 R T'],
  "lockdown": ['L AA1 K D AW2 N'],
  "locke": ['L AA1 K'],
  "locke-ober": ['L AA2 K OW1 B ER0'],
  "locked": ['L AA1 K T'],
  "locken": ['L AA1 K AH0 N'],
  "locker": ['L AA1 K ER0'],
  "lockerbie": ['L AA1 K ER0 B IY0'],
  "lockerby": ['L AA1 K ER0 B IY0'],
  "lockerman": ['L AA1 K ER0 M AH0 N'],
  "lockers": ['L AA1 K ER0 Z'],
  "lockert": ['L AA1 K ER0 T'],
  "locket": ['L AA1 K IH0 T'],
  "lockets": ['L AA1 K IH0 T S'],
  "lockett": ['L AA1 K IH0 T'],
  "lockett's": ['L AA1 K AH0 T S'],
  "lockey": ['L AA1 K IY0'],
  "lockhart": ['L AA1 K HH AA2 R T'],
  "lockheed": ['L AA1 K HH IY2 D'],
  "lockheed's": ['L AA1 K HH IY2 D Z'],
  "lockie": ['L AA1 K IY0'],
  "locking": ['L AA1 K IH0 NG'],
  "locklair": ['L AA1 K L ER0'],
  "locklar": ['L AA1 K L ER0'],
  "locklear": ['L AA1 K L ER0'],
  "lockley": ['L AA1 K L IY0'],
  "locklin": ['L AA1 K L IH0 N'],
  "lockman": ['L AA1 K M AH0 N'],
  "lockmiller": ['L AA1 K M IH2 L ER0'],
  "lockner": ['L AA1 K N ER0'],
  "lockney": ['L AA1 K N EY0', 'L AA1 K N IY0'],
  "lockneys": ['L AA1 K N EY0 Z', 'L AA1 K N IY0 Z'],
  "lockout": ['L AA1 K AW2 T'],
  "lockouts": ['L AA1 K AW2 T S'],
  "lockport": ['L AA1 K P AO2 R T'],
  "lockrem": ['L AA1 K R IH0 M'],
  "lockridge": ['L AA1 K R IH2 JH'],
  "locks": ['L AA1 K S'],
  "locksmith": ['L AA1 K S M IH2 TH'],
  "lockstep": ['L AA1 K S T EH2 P'],
  "lockup": ['L AA1 K AH2 P'],
  "lockwood": ['L AA1 K W UH2 D'],
  "lockwood's": ['L AA1 K W UH2 D Z'],
  "lockyer": ['L AA1 K Y ER0'],
  "loco": ['L OW1 K OW0'],
  "lococo": ['L OW0 K OW1 K OW0'],
  "locomotion": ['L OW2 K AH0 M OW1 SH AH0 N'],
  "locomotive": ['L OW2 K AH0 M OW1 T IH0 V'],
  "locomotives": ['L OW2 K AH0 M OW1 T IH0 V Z'],
  "locomotor": ['L OW2 K AH0 M OW1 T ER0'],
  "loconte": ['L OW0 K OW1 N T IY0'],
  "locsin": ['L AA1 K S IH0 N'],
  "loctite": ['L AA1 K T AY2 T'],
  "locurto": ['L OW0 K UH1 R T OW0'],
  "locus": ['L OW1 K AH0 S'],
  "locust": ['L OW1 K AH0 S T'],
  "locusts": ['L OW1 K AH0 S T S'],
  "locy": ['L OW1 S IY0'],
  "lodato": ['L OW0 D AA1 T OW0'],
  "lode": ['L OW1 D'],
  "lodema": ['L OW0 D EH1 M AH0'],
  "loden": ['L OW1 D AH0 N'],
  "loder": ['L OW1 D ER0'],
  "lodes": ['L OW1 D Z'],
  "lodestar": ['L OW1 D S T AA2 R'],
  "lodestone": ['L OW1 D S T OW2 N'],
  "lodge": ['L AA1 JH'],
  "lodge's": ['L AA1 JH IH0 Z'],
  "lodged": ['L AA1 JH D'],
  "lodgepole": ['L AA1 JH P OW2 L'],
  "lodges": ['L AA1 JH AH0 Z', 'L AA1 JH IH0 Z'],
  "lodging": ['L AA1 JH IH0 NG'],
  "lodgings": ['L AA1 JH IH0 NG Z'],
  "lodi": ['L OW1 D AY0'],
  "lodico": ['L OW0 D IY1 K OW0'],
  "lodley": ['L AA1 D L IY0'],
  "lodwick": ['L AA1 D W IH0 K'],
  "lodwrick": ['L AA1 D R IH0 K'],
  "loe": ['L OW1'],
  "loeb": ['L OW1 B'],
  "loeber": ['L OW1 B ER0'],
  "loeffel": ['L OW1 F AH0 L'],
  "loeffelholz": ['L OW1 F IH0 L HH OW0 L Z'],
  "loeffler": ['L OW1 F L ER0'],
  "loehmann": ['L OW1 M AH0 N'],
  "loehmann's": ['L OW1 M AH0 N Z'],
  "loehr": ['L AO1 R'],
  "loella": ['L OW2 EH1 L AH0'],
  "loeper": ['L OW1 P ER0'],
  "loepfe": ['L OW1 P F IY0'],
  "loera": ['L AO1 R AH0'],
  "loesch": ['L OW1 SH'],
  "loesche": ['L OW1 SH'],
  "loescher": ['L OW1 SH ER0'],
  "loeser": ['L OW1 Z ER0'],
  "loess": ['L OW1 AH0 S'],
  "loesser": ['L EH1 S ER0', 'L AA1 S ER0'],
  "loew": ['L AA1 UW0', 'L OW1'],
  "loewe": ['L AA1 UW0', 'L OW1'],
  "loewen": ['L AA1 UW0 AH0 N'],
  "loewenson": ['L OW1 W AH0 N S AH0 N'],
  "loewenstein": ['L OW1 AH0 N S T AY0 N', 'L OW1 AH0 N S T IY0 N'],
  "loewenthal": ['L OW1 IH0 N TH AH0 L'],
  "loewi": ['L OW1 W IY0'],
  "loews": ['L OW1 Z'],
  "loewy": ['L OW1 IY0'],
  "lofaro": ['L OW0 F AA1 R OW0'],
  "lofaso": ['L OW0 F AA1 S OW0'],
  "loffredo": ['L OW0 F R EY1 D OW0'],
  "lofgren": ['L AA1 F G R EH0 N'],
  "lofland": ['L AA1 F L AH0 N D'],
  "loflin": ['L AA1 F L IH0 N'],
  "lofquist": ['L AA1 F K W IH0 S T'],
  "lofstrom": ['L AA1 F S T R AH0 M'],
  "loft": ['L AO1 F T'],
  "loft's": ['L AO1 F T S'],
  "lofthouse": ['L AO1 F T HH AW2 S'],
  "lofthus": ['L AA1 F TH IH0 S'],
  "loftier": ['L AO1 F T IY0 ER0'],
  "loftin": ['L AA1 F T IH0 N'],
  "loftis": ['L AA1 F T IH0 S'],
  "lofton": ['L AA1 F T AH0 N'],
  "lofts": ['L AO1 F T S'],
  "loftus": ['L AO1 F T AH0 S'],
  "lofty": ['L AO1 F T IY0'],
  "log": ['L AO1 G'],
  "logan": ['L OW1 G AH0 N'],
  "logar": ['L OW1 G ER0'],
  "logarithm": ['L AA1 G ER0 IH2 DH AH0 M'],
  "logarithmic": ['L AA2 G ER0 IH1 DH M IH0 K'],
  "logarithms": ['L AA1 G ER0 IH2 DH AH0 M Z'],
  "logbook": ['L AO1 G B UH2 K'],
  "loge": ['L OW1 JH'],
  "loges": ['L OW1 JH IH0 Z'],
  "logged": ['L AO1 G D'],
  "logger": ['L AA1 G ER0'],
  "loggerhead": ['L AA1 G ER0 HH EH2 D'],
  "loggerheads": ['L AA1 G ER0 HH EH2 D Z'],
  "loggers": ['L AA1 G ER0 Z'],
  "loggia": ['L AA1 G IY0 AH0'],
  "logging": ['L AO1 G IH0 NG'],
  "loggins": ['L AA1 G IH0 N Z'],
  "loghry": ['L AA1 G R IY0'],
  "logic": ['L AA1 JH IH0 K'],
  "logica": ['L AA1 JH IH0 K AH0'],
  "logical": ['L AA1 JH IH0 K AH0 L'],
  "logically": ['L AA1 JH IH0 K L IY0'],
  "logician": ['L OW0 JH IH1 SH AH0 N'],
  "logicon": ['L AA1 JH IH0 K AA2 N'],
  "logie": ['L OW1 JH IY0'],
  "login": ['L AO1 G IH2 N'],
  "logistic": ['L AH0 JH IH1 S T IH0 K'],
  "logistical": ['L AH0 JH IH1 S T IH0 K AH0 L'],
  "logistically": ['L AH0 JH IH1 S T IH0 K L IY0'],
  "logistics": ['L AH0 JH IH1 S T IH0 K S'],
  "logiudice": ['L OW0 JH UW1 D AH0 S'],
  "logjam": ['L AA1 G JH AE2 M'],
  "logjams": ['L AA1 G JH AE2 M Z'],
  "logo": ['L OW1 G OW0'],
  "logogram": ['L AO1 G AH0 G R AE2 M'],
  "logograms": ['L AO1 G AH0 G R AE2 M Z'],
  "logographic": ['L AO2 G AH0 G R AE1 F IH0 K'],
  "logophilia": ['L OW2 G OW0 F IH1 L IY2 AH0'],
  "logos": ['L OW1 G OW2 Z'],
  "logothetis": ['L OW2 G OW0 TH EH1 T IH0 S'],
  "logout": ['L AO1 G AW2 T'],
  "lograsso": ['L OW0 G R AA1 S OW0'],
  "logs": ['L AO1 G Z'],
  "logsdon": ['L AA1 G Z D AH0 N'],
  "logston": ['L AA1 G S T AH0 N'],
  "logue": ['L OW1 G'],
  "loguidice": ['L OW0 G AY1 D IH0 S'],
  "logwood": ['L AA1 G W UH2 D'],
  "loh": ['L OW1'],
  "lohengrin": ['L OW1 AH0 N G R IH0 N'],
  "lohman": ['L OW1 M AH0 N'],
  "lohmann": ['L OW1 M AH0 N'],
  "lohmeier": ['L OW1 M AY0 ER0'],
  "lohmeyer": ['L OW1 M AY0 ER0'],
  "lohn": ['L AA1 N'],
  "lohner": ['L OW1 N ER0'],
  "lohnes": ['L OW1 N Z'],
  "lohr": ['L AO1 R'],
  "lohrenz": ['L AO1 R IH0 N S'],
  "lohrey": ['L AA1 R IY0'],
  "lohrman": ['L AO1 R M AH0 N'],
  "lohrmann": ['L AO1 R M AH0 N'],
  "lohse": ['L OW1 S'],
  "loiacano": ['L OW0 Y AH0 K AA1 N OW0'],
  "loiacono": ['L OW0 Y AH0 K OW1 N OW0'],
  "loibl": ['L OY1 B AH0 L'],
  "loin": ['L OY1 N'],
  "loincloth": ['L OY1 N K L AO2 TH'],
  "loire": ['L OY1 R'],
  "lois": ['L OW1 AH0 S'],
  "loise": ['L UW1 AH0 S', 'L UW1 IY0 S'],
  "loiseau": ['L UW1 AH0 S OW2'],
  "loisel": ['L OY0 Z EH1 L'],
  "loiselle": ['L OY0 Z EH1 L'],
  "loiter": ['L OY1 T ER0'],
  "loitering": ['L OY1 T ER0 IH0 NG'],
  "lok": ['L AA1 K'],
  "loken": ['L OW1 K AH0 N'],
  "loker": ['L OW1 K ER0'],
  "lokey": ['L OW1 K IY0'],
  "lokken": ['L AA1 K AH0 N'],
  "lol": ['L AO1 L'],
  "lola": ['L OW1 L AH0'],
  "loleta": ['L OW0 L EH1 T AH0'],
  "lolita": ['L OW0 L IY1 T AH0'],
  "loll": ['L AA1 L'],
  "lollapalooza": ['L AA2 L AH0 P AH0 L UW1 Z AH0'],
  "lollapaloozas": ['L AA2 L AH0 P AH0 L UW1 Z AH0 Z'],
  "lollar": ['L AA1 L ER0'],
  "lollard": ['L AA1 L ER0 D'],
  "lolley": ['L AA1 L IY0'],
  "lolli": ['L AA1 L IY0'],
  "lolling": ['L AA1 L IH0 NG'],
  "lollipop": ['L AA1 L IY0 P AA2 P'],
  "lollipops": ['L AA1 L IY0 P AA2 P S'],
  "lollis": ['L AH0 L IY1 S'],
  "lolly": ['L AA1 L IY0'],
  "loma": ['L OW1 M AH0'],
  "loma-prieda": ['L OW1 M AH0 P R IY0 EY1 D AH0'],
  "lomak": ['L OW1 M AE0 K'],
  "loman": ['L OW1 M AH0 N'],
  "lomas": ['L OW1 M AH0 Z'],
  "lomasney": ['L AA1 M AH0 S N IY0'],
  "lomax": ['L OW1 M AE2 K S'],
  "lomb": ['L AA1 M'],
  "lomba": ['L AA1 M B AH0'],
  "lombard": ['L AA1 M B AA0 R D'],
  "lombardi": ['L AA0 M B AA1 R D IY0'],
  "lombardo": ['L AA0 M B AA1 R D OW0'],
  "lombards": ['L AA1 M B AA0 R D Z'],
  "lomeli": ['L OW0 M EH1 L IY0'],
  "lommen": ['L OW1 M AH0 N'],
  "lomonaco": ['L OW0 M OW0 N AA1 K OW0'],
  "lompoc": ['L AA1 M P AA0 K'],
  "lon": ['L AA1 N'],
  "lona": ['L OW1 N AH0'],
  "lonardo": ['L OW0 N AA1 R D OW0'],
  "lonas": ['L OW1 N AH0 Z'],
  "loncar": ['L AA1 N K AA0 R'],
  "londo": ['L AA1 N D OW0'],
  "london": ['L AH1 N D AH0 N'],
  "london's": ['L AH1 N D AH0 N Z'],
  "londonderry": ['L AH1 N D AH0 N D EH2 R IY0'],
  "londoner": ['L AH1 N D AH0 N ER0'],
  "londoners": ['L AH1 N D AH0 N ER0 Z'],
  "londono": ['L OW0 N D OW1 N OW0'],
  "lone": ['L OW1 N'],
  "loneliness": ['L OW1 N L IY0 N AH0 S'],
  "lonely": ['L OW1 N L IY0'],
  "loner": ['L OW1 N ER0'],
  "lonergan": ['L AA1 N ER0 G AH0 N'],
  "lonero": ['L OW0 N EH1 R OW0'],
  "loners": ['L OW1 N ER0 Z'],
  "lones": ['L OW1 N Z'],
  "lonesome": ['L OW1 N S AH0 M'],
  "lonetree": ['L OW1 N T R IY2'],
  "loney": ['L OW1 N IY0'],
  "long": ['L AO1 NG'],
  "long's": ['L AO1 NG Z'],
  "long-beach": ['L AO1 NG B IY1 CH'],
  "long-distance": ['L AO2 NG D IY1 S T AH0 N S'],
  "long-island": ['L AO2 NG AY1 L AH0 N D'],
  "long-overdue": ['L AO1 NG OW2 V ER0 D UW1'],
  "long-range": ['L AO1 NG R EY1 N JH'],
  "long-term": ['L AO1 NG T ER1 M'],
  "long-winded": ['L AO1 NG W IH1 N D IH0 D'],
  "longacre": ['L AO1 NG EY2 K ER0'],
  "longaker": ['L AA1 NG G AH0 K ER0'],
  "longan": ['L AA1 NG G AH0 N'],
  "longanecker": ['L AA1 NG G AH0 N EH0 K ER0'],
  "longbow": ['L AO1 NG B OW2'],
  "longbrake": ['L AO1 NG B R EY2 K'],
  "longcor": ['L OW0 NG G K AO1 R'],
  "longden": ['L AO1 NG D EH2 N'],
  "longe": ['L AA1 N JH'],
  "longed": ['L AO1 NG D'],
  "longenecker": ['L AA1 N JH N EH0 K ER0'],
  "longer": ['L AO1 NG G ER0'],
  "longerbeam": ['L AO1 NG G ER0 B IY2 M'],
  "longest": ['L AO1 NG G AH0 S T', 'L AO1 NG G IH0 S T'],
  "longevity": ['L AO0 N JH EH1 V AH0 T IY0'],
  "longfellow": ['L AO1 NG F EH2 L OW0'],
  "longfield": ['L AO1 NG F IY2 L D'],
  "longhair": ['L AO1 NG HH EH2 R'],
  "longhand": ['L AO1 NG HH AE2 N D'],
  "longhi": ['L AO1 N G IY0'],
  "longhorn": ['L AO1 NG HH AO2 R N'],
  "longhorns": ['L AO1 NG HH AO2 R N Z'],
  "longhouse": ['L AO1 NG HH AW2 S'],
  "longhurst": ['L AA1 NG HH ER0 S T'],
  "longing": ['L AO1 NG IH0 NG'],
  "longingly": ['L AO1 NG IH0 NG L IY0'],
  "longings": ['L AO1 NG IH0 NG Z'],
  "longino": ['L OW0 NG G IY1 N OW0'],
  "longish": ['L AO1 NG IH0 SH'],
  "longitude": ['L AA1 N JH AH0 T UW2 D'],
  "longitudes": ['L AA1 N JH AH0 T UW2 D Z'],
  "longitudinal": ['L AA2 N JH AH0 T UW1 D AH0 N AH0 L'],
  "longleaf": ['L AO1 NG L IY2 F'],
  "longleg": ['L AO1 NG L EH2 G'],
  "longlegs": ['L AO1 NG L EH2 G Z'],
  "longley": ['L AA1 NG G L IY0'],
  "longman": ['L AO1 NG M AH0 N'],
  "longmeyer": ['L AO1 NG M AY2 ER0'],
  "longmeyer's": ['L AO1 NG M AY2 ER0 Z'],
  "longmire": ['L AA1 NG M AY0 R'],
  "longmont": ['L AO1 NG M AA2 N T'],
  "longmore": ['L OW1 NG M AO0 R'],
  "longnecker": ['L AO1 NG N EH2 K ER0'],
  "longo": ['L AO1 NG G OW0'],
  "longobardi": ['L OW0 NG G OW0 B AA1 R D IY0'],
  "longoria": ['L OW0 NG G AO1 R IY0 AH0'],
  "longpre": ['L AA1 NG P ER0'],
  "longrange": ['L AO1 NG R EY2 N JH'],
  "longs": ['L AO1 NG Z'],
  "longshore": ['L AO1 NG SH AO2 R'],
  "longshoremen": ['L AO1 NG SH AO1 R M IH0 N'],
  "longshoremen's": ['L AO1 NG SH AO1 R M IH0 N Z'],
  "longshot": ['L AO1 NG SH AA2 T'],
  "longspur": ['L AO1 NG S P ER2'],
  "longspurs": ['L AO1 NG S P ER2 Z'],
  "longstaff": ['L AO1 NG S T AE2 F'],
  "longstanding": ['L AO1 NG S T AE2 N D IH0 NG'],
  "longstreet": ['L AO1 NG S T R IY2 T'],
  "longstreth": ['L AA1 NG S T R EH0 TH'],
  "longsworth": ['L AO1 NG Z W ER2 TH'],
  "longterm": ['L AO1 NG T ER2 M'],
  "longtime": ['L AO1 NG T AY2 M'],
  "longtin": ['L AO1 NG T IH0 N'],
  "longton": ['L AO1 NG T AH0 N'],
  "longuet": ['L AO1 NG W EH2 T'],
  "longview": ['L AO1 NG V Y UW2'],
  "longwell": ['L AO1 NG W EH2 L'],
  "longwood": ['L AO1 NG W UH2 D'],
  "longworth": ['L AO1 NG W ER2 TH'],
  "loni": ['L AA1 N IY0'],
  "lonigro": ['L OW0 N IY1 G R OW0'],
  "lonnie": ['L AO1 N IY0'],
  "lonny": ['L AO1 N IY0'],
  "lonon": ['L OW1 N AH0 N'],
  "lonrho": ['L AA1 N R OW0'],
  "lonrho's": ['L AA1 N R OW0 Z'],
  "lons": ['L AA1 N Z'],
  "lonsdale": ['L AA1 N S D EY2 L'],
  "lonski": ['L AA1 N S K IY0'],
  "lonsky": ['L AA1 N S K IY0'],
  "lonsky's": ['L AA1 N S K IY0 Z'],
  "lonsway": ['L AA1 N S W EY0'],
  "loo": ['L UW1'],
  "looart": ['L UW1 AA0 R T'],
  "looby": ['L UW1 B IY0'],
  "look": ['L UH1 K'],
  "lookabaugh": ['L UW1 K AH0 B AO0'],
  "lookabill": ['L UH1 K AH0 B IH2 L'],
  "lookalike": ['L UH1 K AH0 L AY2 K'],
  "looked": ['L UH1 K T'],
  "looker": ['L UH1 K ER0'],
  "lookers": ['L UH1 K ER0 Z'],
  "lookin'": ['L UH1 K IH0 N'],
  "looking": ['L UH1 K IH0 NG'],
  "lookingbill": ['L UH1 K IH0 NG B IH2 L'],
  "lookit": ['L UH1 K IH0 T'],
  "lookout": ['L UH1 K AW2 T'],
  "looks": ['L UH1 K S'],
  "lookup": ['L UH1 K AH0 P'],
  "lookups": ['L UH1 K AH0 P S'],
  "loom": ['L UW1 M'],
  "looman": ['L UW1 M AH0 N'],
  "loomed": ['L UW1 M D'],
  "loomer": ['L UW1 M ER0'],
  "looming": ['L UW1 M IH0 NG'],
  "loomis": ['L UW1 M IH0 S'],
  "looms": ['L UW1 M Z'],
  "loon": ['L UW1 N'],
  "looney": ['L UW1 N IY0'],
  "loong": ['L UH1 NG'],
  "loons": ['L UW1 N Z'],
  "loony": ['L UW1 N IY0'],
  "loop": ['L UW1 P'],
  "looped": ['L UW1 P T'],
  "looper": ['L UW1 P ER0'],
  "loopers": ['L UW1 P ER0 Z'],
  "loophole": ['L UW1 P HH OW2 L'],
  "loopholes": ['L UW1 P HH OW2 L Z'],
  "looping": ['L UW1 P IH0 NG'],
  "loops": ['L UW1 P S'],
  "loopy": ['L UW1 P IY0'],
  "loos": ['L UW1 Z'],
  "loose": ['L UW1 S'],
  "loosed": ['L UW1 S T'],
  "loosely": ['L UW1 S L IY0'],
  "loosen": ['L UW1 S AH0 N'],
  "loosened": ['L UW1 S AH0 N D'],
  "loosening": ['L UW1 S AH0 N IH0 NG'],
  "loosens": ['L UW1 S AH0 N Z'],
  "looser": ['L UW1 S ER0'],
  "looses": ['L UW1 S IH0 Z'],
  "loosestrife": ['L UW1 S S T R AY2 F', 'L UW1 S T R AY2 F'],
  "loosestrifes": ['L UW1 S S T R AY2 F S', 'L UW1 S T R AY2 F S'],
  "loosing": ['L UW1 S IH0 NG'],
  "loot": ['L UW1 T'],
  "looted": ['L UW1 T AH0 D', 'L UW1 T IH0 D'],
  "lootens": ['L UW1 T AH0 N Z'],
  "looter": ['L UW1 T ER0'],
  "looters": ['L UW1 T ER0 Z'],
  "looting": ['L UW1 T IH0 NG'],
  "lop": ['L AA1 P'],
  "lop-ear": ['L AA1 P IY2 R'],
  "lopardo": ['L OW0 P AA1 R D OW0'],
  "lopata": ['L OW0 P AA1 T AH0'],
  "lopatin": ['L OW0 P AA0 T IY1 N'],
  "lope": ['L OW1 P'],
  "lopeman": ['L OW1 P M AH0 N'],
  "loper": ['L OW1 P ER0'],
  "lopes": ['L OW1 P S'],
  "lopez": ['L OW1 P EH0 Z'],
  "lopez's": ['L OW1 P EH0 Z IH0 Z'],
  "lopiccolo": ['L OW0 P IY0 K OW1 L OW0'],
  "lopid": ['L OW1 P IH0 D'],
  "lopinto": ['L AH0 P IH1 N T OW0'],
  "lopp": ['L AA1 P'],
  "lopped": ['L AA1 P T'],
  "loppers": ['L AA1 P ER0 Z'],
  "lopping": ['L AA1 P IH0 NG'],
  "loppnow": ['L AA1 P N AW0'],
  "lopresti": ['L OW0 P R EH1 S T IY0'],
  "lopresto": ['L OW0 P R EH1 S T OW0'],
  "loprete": ['L OW1 P R IY0 T'],
  "lopsided": ['L AA1 P S AY1 D IH0 D'],
  "loquacious": ['L OW0 K W EY1 SH AH0 S'],
  "loquat": ['L OW1 K W AA2 T'],
  "lor": ['L AO1 R'],
  "lora": ['L AO1 R AH0'],
  "lorah": ['L AO1 R AH0'],
  "lorain": ['L ER0 EY1 N'],
  "loraine": ['L ER0 EY1 N'],
  "loral": ['L AO1 R AH0 L'],
  "loral's": ['L AO1 R AH0 L Z'],
  "loran": ['L AO1 R AE2 N'],
  "lorance": ['L AO1 R AH0 N S'],
  "lorang": ['L AO1 R AH0 NG'],
  "loranger": ['L AO1 R EY0 N JH ER0'],
  "lorber": ['L AO1 R B ER0'],
  "lorch": ['L AO1 R K'],
  "lord": ['L AO1 R D'],
  "lord's": ['L AO1 R D Z'],
  "lorden": ['L AO1 R D AH0 N'],
  "lordi": ['L AO1 R D IY2'],
  "lordly": ['L AO1 R D L IY2'],
  "lords": ['L AO1 R D Z'],
  "lordship": ['L AO1 R D SH IH0 P'],
  "lordstown": ['L AO1 R D Z T AW2 N'],
  "lordy": ['L AO1 R D IY2'],
  "lore": ['L AO1 R'],
  "lorean": ['L AO1 R IY0 AH0 N'],
  "lorean's": ['L AO0 R IY1 N Z'],
  "loredo": ['L AO0 R EY1 D OW0'],
  "loree": ['L AO1 R IY1'],
  "lorei": ['L AO1 R EY0'],
  "lorek": ['L AO1 R IH0 K'],
  "lorelei": ['L AO1 R IH0 L AY2'],
  "lorelli": ['L AO0 R EH1 L IY0'],
  "lorello": ['L AO0 R EH1 L OW0'],
  "loren": ['L AO1 R AH0 N'],
  "lorena": ['L AO0 R EH1 N AH0'],
  "lorena's": ['L AO0 R EH1 N AH0 Z'],
  "lorenc": ['L AO1 R IH0 NG K'],
  "lorence": ['L AO1 R AH0 N S'],
  "lorene": ['L AO0 R IY1 N'],
  "lorensen": ['L AO1 R IH0 N S AH0 N'],
  "lorenson": ['L AO1 R IH0 N S AH0 N'],
  "lorentz": ['L AO1 R IH0 N T S'],
  "lorentzen": ['L AO1 R IH0 N T Z AH0 N'],
  "lorenz": ['L ER0 EH1 N T S'],
  "lorenza": ['L AO0 R EH1 N Z AH0'],
  "lorenzana": ['L AO0 R EH0 N Z AE1 N AH0'],
  "lorenzen": ['L AO1 R IH0 N Z AH0 N'],
  "lorenzetti": ['L AO0 R EH0 N Z EH1 T IY0'],
  "lorenzi": ['L AO0 R EH1 N Z IY0'],
  "lorenzini": ['L AO0 R EH0 N Z IY1 N IY0'],
  "lorenzo": ['L ER0 EH1 N Z OW0', 'L AO0 EH1 N Z OW0'],
  "lorenzo's": ['L AO0 R EH1 N Z OW0 Z', 'L ER0 EH1 N Z OW0 Z'],
  "loreto": ['L AO0 R EH1 T OW0'],
  "loretta": ['L ER0 EH1 T AH0'],
  "lorette": ['L AO0 R EH1 T'],
  "loretto": ['L AO0 R EH1 T OW0'],
  "lorey": ['L AO1 R IY0'],
  "lorge": ['L AO1 R JH'],
  "lorgnette": ['L AO0 R N Y EH1 T'],
  "lorgnettes": ['L AO0 R N Y EH1 T S'],
  "lori": ['L AO1 R IY0'],
  "lori's": ['L AO1 R IY0 Z'],
  "loria": ['L AO1 R IY0 AH0'],
  "lorick": ['L AO1 R IH0 K'],
  "lorie": ['L AO1 R IY0'],
  "lorillard": ['L AO1 R AH0 L AA0 R D'],
  "lorimar": ['L AO1 R AH0 M AA0 R'],
  "lorimar's": ['L AO1 R AH0 M AA0 R Z'],
  "lorimer": ['L AO1 R IH0 M ER0'],
  "lorimor": ['L AO1 R IH0 M ER0'],
  "lorin": ['L AO1 R IH0 N'],
  "lorincz": ['L AO1 R IH0 N CH'],
  "loring": ['L AO1 R IH0 NG'],
  "lorino": ['L AO0 R IY1 N OW0'],
  "lorio": ['L AO1 R IY0 OW0'],
  "loris": ['L AO1 R IH0 S'],
  "lorita": ['L AO0 R IY1 T AH0'],
  "lorman": ['L AO1 R M AH0 N'],
  "lormand": ['L AO1 R M AH0 N D'],
  "lorna": ['L AO1 R N AH0'],
  "lorne": ['L AO1 R N'],
  "loro": ['L AO1 R OW0'],
  "lorraine": ['L ER0 EY1 N'],
  "lorrie": ['L AO1 R IY0'],
  "lorries": ['L AO1 R IY0 Z'],
  "lorry": ['L AO1 R IY0'],
  "lorson": ['L AO1 R S AH0 N'],
  "lortel": ['L AO1 R T EH2 L'],
  "lortie": ['L AO1 R T IY0'],
  "lorton": ['L AO1 R T AH0 N'],
  "lortz": ['L AO1 R T S'],
  "lorusso": ['L AO0 R UW1 S OW0'],
  "lory": ['L AO1 R IY0'],
  "los": ['L OW1 S', 'L AO1 S'],
  "los-angeles": ['L AO2 S AE1 N JH AH0 L AH0 S'],
  "los-palmas": ['L AA2 S P AA1 L M AH0 S'],
  "losada": ['L OW0 S AA1 D AH0'],
  "losasso": ['L OW0 S AA1 S OW0'],
  "loscalzo": ['L OW0 S K AA1 L Z OW0'],
  "losch": ['L AO1 SH'],
  "loschiavo": ['L OW0 S K IY0 AA1 V OW0'],
  "lose": ['L UW1 Z'],
  "losec": ['L OW1 Z AH0 K'],
  "losee": ['L AH0 S IY1'],
  "loseke": ['L AA1 S IH0 K'],
  "loser": ['L UW1 Z ER0'],
  "losers": ['L UW1 Z ER0 Z'],
  "losers'": ['L OW1 Z ER0 Z'],
  "loses": ['L UW1 Z AH0 Z', 'L UW1 Z IH0 Z'],
  "losey": ['L AA1 S IY0'],
  "losh": ['L AA1 SH'],
  "losing": ['L UW1 Z IH0 NG'],
  "losinski": ['L AH0 S IH1 N S K IY0'],
  "losito": ['L OW0 S IY1 T OW0'],
  "loso": ['L OW1 S OW0'],
  "losoya": ['L AA1 S OY0 AE0'],
  "loss": ['L AO1 S'],
  "losses": ['L AO1 S AH0 Z', 'L AO1 S IH0 Z'],
  "lossing": ['L AO1 S IH0 NG'],
  "lost": ['L AO1 S T'],
  "losurdo": ['L OW0 S UH1 R D OW0'],
  "losure": ['L OW1 ZH ER0'],
  "lot": ['L AA1 T', 'L AO1 T'],
  "lot's": ['L AA1 T S'],
  "loth": ['L AA1 TH'],
  "lothian": ['L AA1 TH IY0 AH0 N'],
  "lothrop": ['L AA1 TH R AH0 P', 'L OW1 TH R AH0 P'],
  "lothson": ['L AA1 TH S AH0 N'],
  "lotion": ['L OW1 SH AH0 N'],
  "lotion's": ['L OW1 SH AH0 N Z'],
  "lotions": ['L OW1 SH AH0 N Z'],
  "lotito": ['L OW0 T IY1 T OW0'],
  "loton": ['L OW1 T AH0 N'],
  "lots": ['L AA1 T S'],
  "lotspeich": ['L AA1 T S P AY0 K'],
  "lott": ['L AA1 T'],
  "lott's": ['L AA1 T Z'],
  "lotta": ['L AA1 T AH0'],
  "lotte": ['L AA1 T'],
  "lotter": ['L AA1 T ER0'],
  "lotteries": ['L AA1 T ER0 IY0 Z'],
  "lottery": ['L AA1 T ER0 IY0'],
  "lottes": ['L AA1 T S'],
  "lottie": ['L AA1 T IY0'],
  "lottman": ['L AA1 T M AH0 N'],
  "lotto": ['L AA1 T OW2', 'L AA1 T OW0'],
  "lotts": ['L AA1 T S'],
  "lotty": ['L AA1 T IY0'],
  "lotus": ['L OW1 T AH0 S'],
  "lotus'": ['L OW1 T AH0 S'],
  "lotus's": ['L OW1 T AH0 S IH0 Z'],
  "lotz": ['L AA1 T S'],
  "lotze": ['L OW1 T Z'],
  "lou": ['L UW1'],
  "lou's": ['L UW1 Z'],
  "louanna": ['L AW0 AA1 N AH0', 'L UW0 AE1 N AH0'],
  "louanne": ['L UW0 AE1 N'],
  "louart": ['L UW1 AA0 R T'],
  "louche": ['L UW1 SH'],
  "louck": ['L AW1 K'],
  "loucks": ['L AW1 K S'],
  "loud": ['L AW1 D'],
  "louden": ['L AW1 D AH0 N'],
  "loudenslager": ['L AW1 D IH0 N S L EY0 G ER0'],
  "louder": ['L AW1 D ER0'],
  "louderback": ['L AW1 D ER0 B AE2 K'],
  "loudermilk": ['L AW1 D ER0 M IH2 L K'],
  "loudest": ['L AW1 D AH0 S T'],
  "loudin": ['L UW0 D AE1 N'],
  "loudly": ['L AW1 D L IY0'],
  "loudon": ['L AW1 D AH0 N'],
  "loudspeaker": ['L AW1 D S P IY2 K ER0'],
  "loudspeakers": ['L AW1 D S P IY2 K ER0 Z'],
  "louella": ['L AW0 EH1 L AH0'],
  "louganis": ['L UW2 G EY1 N IH0 S'],
  "louganos": ['L UW2 G AE1 N OW0 Z'],
  "lougee": ['L AW1 JH IY0'],
  "lough": ['L AW1'],
  "lougheed": ['L AW1 IY0 D'],
  "loughery": ['L AW1 ER0 IY0'],
  "loughlin": ['L AO1 F L IH0 N'],
  "loughman": ['L AW1 M AH0 N'],
  "loughmiller": ['L AW1 M AH0 L ER0'],
  "loughner": ['L AW1 N ER0'],
  "loughney": ['L AW1 N IY0'],
  "loughran": ['L AW1 R AH0 N'],
  "loughrey": ['L AW1 R IY0'],
  "loughridge": ['L AW1 R IH0 JH'],
  "loughry": ['L AW1 R IY0'],
  "louie": ['L UW0 IY1'],
  "louima": ['L UW0 IY1 M AH0'],
  "louima's": ['L UW0 IY1 M AH0 Z'],
  "louis": ['L UW1 IH0 S', 'L UW1 IY0'],
  "louis'": ['L UW1 IH0 S', 'L UW1 IY0 Z', 'L UW1 IH0 S IH0 Z'],
  "louis's": ['L UW1 IH0 S IH0 Z'],
  "louisa": ['L UW0 IY1 Z AH0'],
  "louisan": ['L UW1 IH0 S AH0 N'],
  "louise": ['L UW0 IY1 Z'],
  "louisette": ['L W IY0 Z EH1 T'],
  "louisiana": ['L UW0 IY2 Z IY0 AE1 N AH0'],
  "louisiana's": ['L UW0 IY2 Z IY0 AE1 N AH0 Z'],
  "louisianian": ['L UW0 IY2 Z IY0 AE1 N IY0 AH0 N'],
  "louisianians": ['L UW0 IY2 Z IY0 AE1 N IY0 AH0 N Z'],
  "louisville": ['L UW1 IY0 V IH2 L'],
  "louk": ['L AW1 K'],
  "louks": ['L AW1 K S'],
  "lounge": ['L AW1 N JH'],
  "lounges": ['L AW1 N JH IH0 Z'],
  "lounging": ['L AW1 N JH IH0 NG'],
  "lounsberry": ['L AW1 N S B EH0 R IY0'],
  "loup": ['L UW1 P'],
  "loupe": ['L UW1 P'],
  "louque": ['L UW1 K'],
  "lour": ['L AW1 R'],
  "lourdes": ['L AO1 R D Z'],
  "loureiro": ['L UH0 R EH1 R OW0'],
  "lourenco": ['L AW0 R EH1 N K OW0'],
  "lourie": ['L AW1 R IY0'],
  "loury": ['L UW1 R IY0'],
  "louse": ['L AW1 S'],
  "loused": ['L AW1 Z D'],
  "lousiness": ['L AW1 Z IY0 N AH0 S'],
  "lousy": ['L AW1 Z IY0'],
  "lout": ['L AW1 T'],
  "louth": ['L AW1 TH'],
  "louthan": ['L AW1 TH AH0 N'],
  "louts": ['L AW1 T S'],
  "loutzenhiser": ['L AW1 T Z IH0 N HH AY0 Z ER0'],
  "louvier": ['L AW1 V IY0 ER0'],
  "louviere": ['L UW1 V IY0 EH0 R'],
  "louvre": ['L UW1 V R AH0'],
  "louw": ['L UW1'],
  "loux": ['L UW1'],
  "lovaas": ['L AA1 V AA0 Z'],
  "lovable": ['L AH1 V AH0 B AH0 L'],
  "lovage": ['L AH1 V AH0 JH'],
  "lovallo": ['L OW0 V AA1 L OW0'],
  "lovan": ['L OW1 V AH0 N'],
  "lovas": ['L OW1 V AH0 Z'],
  "lovastatin": ['L OW0 V AE1 S T AH0 T IH0 N'],
  "lovato": ['L OW0 V AA1 T OW0'],
  "love": ['L AH1 V'],
  "love's": ['L AH1 V Z'],
  "loveall": ['L AH1 V AO2 L'],
  "lovecchio": ['L AH0 V EH1 K IY0 OW0'],
  "loved": ['L AH1 V D'],
  "loveday": ['L AH1 V D EY2'],
  "lovegrove": ['L AH1 V G R OW2 V'],
  "lovejoy": ['L AH1 V JH OY2'],
  "lovelace": ['L AH1 V L EY2 S'],
  "lovelan": ['L AH1 V L AH0 N'],
  "loveland": ['L AH1 V L AH0 N D'],
  "loveless": ['L AH1 V L AH0 S'],
  "lovell": ['L AH1 V AH0 L'],
  "lovely": ['L AH1 V L IY0'],
  "lovemaking": ['L AH1 V M EY0 K IH0 NG'],
  "loven": ['L AH1 V AH0 N'],
  "lover": ['L AH1 V ER0'],
  "lover's": ['L AH1 V ER0 Z'],
  "loverde": ['L OW0 V EH1 R D IY0'],
  "loveridge": ['L AH1 V R IH2 JH'],
  "lovering": ['L AH1 V ER0 IH0 NG'],
  "lovern": ['L AH1 V ER0 N'],
  "lovers": ['L AH1 V ER0 Z'],
  "lovers'": ['L AH1 V ER0 Z'],
  "loves": ['L AH1 V Z'],
  "lovett": ['L AH1 V IH0 T'],
  "lovette": ['L AH1 V EH1 T'],
  "lovewell": ['L AH1 V W EH2 L'],
  "lovgren": ['L AA1 V G R EH0 N'],
  "lovick": ['L AA1 V IH0 K'],
  "lovie": ['L AH1 V IY0'],
  "loville": ['L OW1 V IH0 L'],
  "lovin": ['L OW1 V IH0 N'],
  "loving": ['L AH1 V IH0 NG'],
  "lovinger": ['L AH1 V IH0 NG ER0'],
  "lovingly": ['L AH1 V IH0 NG L IY0'],
  "lovingood": ['L AA1 V IH0 NG G UH0 D'],
  "lovings": ['L AH1 V IH0 NG Z'],
  "lovins": ['L OW1 V IH0 N Z'],
  "lovitt": ['L AA1 V IH0 T'],
  "lovitz": ['L OW1 V IH0 T S'],
  "lovler": ['L AH1 V L ER0'],
  "lovler's": ['L AH1 V L ER0 Z'],
  "lovvorn": ['L AA1 V ER0 N'],
  "low": ['L OW1'],
  "low-cost": ['L OW2 K AO1 S T'],
  "low-income": ['L OW2 IH1 N K AH0 M'],
  "low-level": ['L OW2 L EH1 V AH0 L'],
  "low-spirited": ['L OW2 S P IH1 R AH0 T IH0 D'],
  "low-spiritedness": ['L OW2 S P IH1 R IH0 T IH0 D N AH0 S'],
  "low-tech": ['L OW2 T EH1 K'],
  "lowborn": ['L OW1 B AO1 R N'],
  "lowden": ['L OW1 D AH0 N'],
  "lowder": ['L OW1 D ER0'],
  "lowdermilk": ['L OW0 D ER1 M IH0 L K'],
  "lowdown": ['L OW1 D AW2 N'],
  "lowe": ['L OW1'],
  "lowe's": ['L OW1 Z'],
  "lowekamp": ['L OW1 K AE2 M P'],
  "lowell": ['L OW1 AH0 L'],
  "lowen": ['L OW1 AH0 N'],
  "lowenberg": ['L OW1 AH0 N B ER0 G'],
  "lowensky": ['L OW0 EH1 N S K IY0'],
  "lowenstein": ['L OW1 AH0 N S T AY2 N', 'L OW1 AH0 N S T IY2 N'],
  "lowenthal": ['L OW1 IH0 N TH AH0 L'],
  "lower": ['L OW1 ER0'],
  "lowered": ['L OW1 ER0 D'],
  "lowering": ['L OW1 ER0 IH0 NG'],
  "lowers": ['L OW1 ER0 Z'],
  "lowery": ['L AW1 ER0 IY0'],
  "lowes": ['L OW1 Z'],
  "lowest": ['L OW1 AH0 S T'],
  "lowey": ['L OW1 IY0'],
  "lowing": ['L OW1 IH0 NG'],
  "lowinsky": ['L AH0 W IH1 N S K IY0'],
  "lowland": ['L OW1 L AE2 N D'],
  "lowlands": ['L OW1 L AE2 N D Z'],
  "lowly": ['L OW1 L IY0'],
  "lowman": ['L OW1 M AH0 N'],
  "lown": ['L AW1 N'],
  "lowndes": ['L OW1 N D IY0 Z', 'L OW1 N D Z'],
  "lowney": ['L OW1 N IY0'],
  "lowrance": ['L AO1 R AH0 N S'],
  "lowrey": ['L AW1 R IY0'],
  "lowrie": ['L AO1 R IY0'],
  "lowrimore": ['L AO1 R IY0 M AO0 R'],
  "lowry": ['L AW1 R IY0'],
  "lows": ['L OW1 Z'],
  "lowther": ['L OW1 DH ER0'],
  "lowy": ['L OW1 IY0'],
  "lox": ['L AA1 K S', 'L AH1 K S'],
  "loy": ['L OY1'],
  "loya": ['L OY1 AH0'],
  "loyal": ['L OY1 AH0 L'],
  "loyalist": ['L OY1 AH0 L IH0 S T'],
  "loyalists": ['L OY1 AH0 L IH0 S T S'],
  "loyall": ['L OY1 AH0 L'],
  "loyally": ['L OY1 AH0 L IY0'],
  "loyalties": ['L OY1 AH0 L T IY0 Z'],
  "loyalton": ['L OY1 AH0 L T IH0 N'],
  "loyalty": ['L OY1 AH0 L T IY0'],
  "loyce": ['L OY1 S'],
  "loyd": ['L OY1 D'],
  "loye": ['L OY1'],
  "loyer": ['L OY1 ER0'],
  "loynd": ['L OY1 N D'],
  "loyola": ['L OY2 OW1 L AH0'],
  "loza": ['L OW1 Z AH0'],
  "lozad": ['L OW1 Z AE0 D'],
  "lozada": ['L OW0 Z AA1 D AH0'],
  "lozano": ['L OW0 Z AA1 N OW0'],
  "lozano's": ['L OW0 Z AA1 N OW0 Z'],
  "lozeau": ['L AH0 Z OW1'],
  "lozenge": ['L AO1 Z AH0 N JH'],
  "lozenges": ['L AO1 Z AH0 N JH AH0 Z'],
  "lozier": ['L OW1 Z IY0 ER0'],
  "lozinski": ['L AH0 Z IH1 N S K IY0'],
  "lozito": ['L OW0 Z IY1 T OW0'],
  "lozon": ['L OW1 Z AH0 N'],
  "lozoya": ['L OW0 Z OY1 AH0'],
  "lp": ['EH1 L P IY1'],
  "lpn": ['EH1 L P IY1 EH1 N'],
  "ls": ['EH1 L EH1 S'],
  "lsd": ['EH2 L EH2 S D IY1'],
  "ltd": ['L IH1 M IH0 T IH0 D', 'EH1 L T IY1 D IY1'],
  "ltd.": ['L IH1 M IH0 T IH0 D'],
  "lti": ['EH2 T IY2 AY1'],
  "lti's": ['EH2 T IY2 AY1 Z'],
  "lu": ['L UW1'],
  "lua": ['L UW1 AH0'],
  "luaka": ['L UW0 AA1 K AH0'],
  "luallen": ['L UW0 AE1 L AH0 N'],
  "luana": ['L UW0 AE1 N AH0'],
  "luanda": ['L UW0 AA1 N D AH0'],
  "luanda's": ['L UW0 AA1 N D AH0 Z'],
  "luane": ['L UW1 AH0 N'],
  "luau": ['L UW1 AW2'],
  "luba": ['L UW1 B AH0'],
  "lubar": ['L UW1 B AA0 R'],
  "lubarsky": ['L AH0 B AA1 R S K IY0'],
  "lubas": ['L UW1 B AH0 Z'],
  "lubavitch": ['L UW1 B AH0 V IH2 CH'],
  "lubavitcher": ['L UW1 B AH0 V IH2 CH ER0', 'L UW0 B AH1 V IH0 CH ER0'],
  "lubavitcher's": ['L UW1 B AH0 V IH2 CH ER0 Z', 'L UW0 B AH1 V IH0 CH ER0 Z'],
  "lubavitchers": ['L UW1 B AH0 V IH2 CH ER0 Z', 'L UW0 B AH1 V IH0 CH ER0 Z'],
  "lubben": ['L AH1 B AH0 N'],
  "lubber": ['L AH1 B ER0'],
  "lubbers": ['L AH1 B ER0 Z'],
  "lubbock": ['L AH1 B AH0 K'],
  "lube": ['L UW1 B'],
  "lube's": ['L UW1 B Z'],
  "lubeck": ['L UW1 B EH2 K'],
  "lubell": ['L UW1 B EH0 L'],
  "lubensky": ['L UW0 B EH1 N S K IY0'],
  "luber": ['L UW1 B ER0'],
  "lubicons": ['L UW1 B IH0 K AA2 N Z'],
  "lubin": ['L UW1 B IH0 N'],
  "lubinov": ['L UW1 B AH0 N AA2 V', 'L UW1 B AH0 N AA2 F'],
  "lubinski": ['L AH0 B IH1 N S K IY0'],
  "lubinsky": ['L AH0 B IH1 N S K IY0'],
  "lubitz": ['L UW1 B IH0 T S'],
  "lubke": ['L AH1 B K'],
  "lublin": ['L AH1 B L IH0 N'],
  "lubow": ['L UW1 B OW0'],
  "lubowski": ['L UW0 B AW1 S K IY0'],
  "lubrano": ['L UW0 B R AA1 N OW0'],
  "lubricant": ['L UW1 B R AH0 K AH0 N T'],
  "lubricants": ['L UW1 B R AH0 K AH0 N T S', 'L UW1 B R IH0 K AH0 N T S', 'L UW1 B R AH0 K AH0 N S', 'L UW1 B R IH0 K AH0 N S'],
  "lubricate": ['L UW1 B R IH0 K EY2 T'],
  "lubricated": ['L UW1 B R AH0 K EY2 T IH0 D'],
  "lubricating": ['L UW1 B R AH0 K EY2 T IH0 NG'],
  "lubrication": ['L UW2 B R IH0 K EY1 SH AH0 N'],
  "lubricious": ['L UW0 B R IH1 SH AH0 S'],
  "lubricity": ['L UW0 B R IH1 S IH0 T IY2'],
  "lubrizol": ['L UW1 B R IH0 Z AA0 L'],
  "luby": ['L UW1 B IY0'],
  "luc": ['L UW1 K'],
  "luca": ['L UW1 K AH0'],
  "lucado": ['L UW0 K AA1 D OW0'],
  "lucarelli": ['L UW0 K AA0 R EH1 L IY0'],
  "lucas": ['L UW1 K AH0 S'],
  "lucas'": ['L UW1 K AH0 S'],
  "lucas's": ['L UW1 K AH0 S IH0 Z'],
  "lucasfilm": ['L UW0 K AE1 S F IH0 L M', 'L UW1 K AH0 S F IH0 L M'],
  "lucasville": ['L UW1 K AH0 S V IH2 L'],
  "lucca": ['L UW1 K AH0'],
  "lucchese": ['L UW0 K EY1 Z IY0'],
  "lucchesi": ['L UW0 K EH1 S IY0'],
  "lucchetti": ['L UW0 K EH1 T IY0'],
  "lucci": ['L UW1 CH IY0'],
  "lucco": ['L UW1 K OW0'],
  "luce": ['L UW1 S'],
  "luce's": ['L UW1 S IH0 Z'],
  "lucent": ['L UW1 S IH0 N T'],
  "lucente": ['L UW0 CH EH1 N T IY0'],
  "lucerne": ['L UW1 S ER0 N'],
  "lucero": ['L UW0 CH EH1 R OW0'],
  "lucette": ['L UW1 S EH1 T'],
  "lucey": ['L UW1 S IY0'],
  "luchini": ['L UW0 K IY1 N IY0'],
  "luchsinger": ['L AH1 K S IH0 N JH ER0'],
  "lucht": ['L AH1 K T'],
  "lucia": ['L UW1 SH AH0'],
  "lucian": ['L UW1 SH AH0 N'],
  "luciani": ['L UW1 CH AH0 N IY0'],
  "lucianna": ['L UW1 CH AH0 N AH0'],
  "lucianne": ['L UW2 S IY0 AE1 N'],
  "luciano": ['L UW0 CH IY0 AA1 N OW0'],
  "lucich": ['L AH1 CH IH0 HH'],
  "lucid": ['L UW1 S AH0 D'],
  "lucida": ['L UW0 CH IY1 D AH0'],
  "lucidity": ['L UW0 S IH1 D IH0 T IY0'],
  "lucido": ['L UW0 CH IY1 D OW0'],
  "lucie": ['L UW1 S IY0'],
  "lucien": ['L UW1 S IY0 AH0 N'],
  "lucienne": ['L UW0 CH IY1 EH0 N'],
  "lucier": ['L UW1 S IY2 ER0'],
  "lucifer": ['L UW1 S AH0 F ER0'],
  "lucile": ['L UW0 S IY1 L'],
  "lucille": ['L UW0 S IY1 L'],
  "lucinda": ['L UW0 S IH1 N D AH0'],
  "lucio": ['L UW1 S IY0 OW0'],
  "lucite": ['L UW1 S AY2 T'],
  "lucius": ['L UW1 SH AH0 S'],
  "luciw": ['L UW1 S IH0'],
  "luck": ['L AH1 K'],
  "lucke": ['L AH1 K'],
  "lucked": ['L AH1 K T'],
  "lucken": ['L AH1 K AH0 N'],
  "luckenbach": ['L AH1 K IH0 N B AA0 K'],
  "luckenbaugh": ['L AH0 K EH1 N B AO0'],
  "luckenbill": ['L AH1 K IH0 N B AH0 L'],
  "lucker": ['L AH1 K ER0'],
  "luckett": ['L AH1 K IH0 T'],
  "luckey": ['L AH1 K IY0'],
  "luckhardt": ['L AH1 K HH AA2 R T'],
  "luckie": ['L AH1 K IY0'],
  "luckier": ['L AH1 K IY0 ER0'],
  "luckiest": ['L AH1 K IY0 AH0 S T'],
  "luckily": ['L AH1 K AH0 L IY0'],
  "lucking": ['L AH1 K IH0 NG'],
  "luckless": ['L AH1 K L AH0 S'],
  "luckman": ['L AH1 K M AH0 N'],
  "luckow": ['L AH1 S K OW0'],
  "lucks": ['L AH1 K S'],
  "lucky": ['L AH1 K IY0'],
  "lucky's": ['L AH1 K IY0 Z'],
  "luckyn": ['L AH1 K IH0 N'],
  "lucodendrum": ['L UW0 K OW0 D EH1 N D R AH0 M'],
  "lucrative": ['L UW1 K R AH0 T IH0 V'],
  "lucrecia": ['L UW0 K R IY1 SH AH0'],
  "lucretia": ['L UW0 K R IY1 SH AH0'],
  "lucrezia": ['L UW0 K R IY1 SH AH0'],
  "lucus": ['L UW1 K AH0 S'],
  "lucy": ['L UW1 S IY0'],
  "lucy's": ['L UW1 S IY0 Z'],
  "luczak": ['L AH1 CH AE0 K'],
  "ludcke": ['L AH1 D K IY0'],
  "ludden": ['L AH1 D AH0 N'],
  "luddite": ['L AH1 D AY2 T'],
  "luddites": ['L AH1 D AY2 T S'],
  "luddy": ['L AH1 D IY0'],
  "ludeke": ['L AH1 D IH0 K'],
  "ludella": ['L UW2 D EH1 L AH0'],
  "ludeman": ['L UW1 D M AH0 N'],
  "ludemann": ['L UW1 D M AH0 N'],
  "ludewig": ['L AH1 D UW0 IH0 G'],
  "ludicrous": ['L UW1 D AH0 K R AH0 S'],
  "ludicrously": ['L UW1 D AH0 K R AH0 S L IY0'],
  "ludington": ['L AH1 D IH0 NG T AH0 N'],
  "ludite": ['L UW1 D AY0 T'],
  "ludites": ['L UW1 D AY0 T S'],
  "ludke": ['L AH1 D K IY0'],
  "ludlam": ['L AH1 D L AH0 M'],
  "ludlam's": ['L AH1 D L AH0 M Z'],
  "ludlow": ['L AH1 D L OW2'],
  "ludlum": ['L AH1 D L AH0 M'],
  "ludlum's": ['L AH1 D L AH0 M Z'],
  "ludmer": ['L AH1 D M ER0'],
  "ludmila": ['L AH0 D M AY1 L AH0'],
  "ludmilla": ['L AH0 D M IH1 L AH0'],
  "ludo": ['L UW1 D OW2'],
  "ludology": ['L UW2 D AO1 L AH0 JH IY2'],
  "ludolph": ['L UW1 D OW2 L F'],
  "ludtke": ['L AH1 D K IY0'],
  "ludvigsen": ['L AH1 D V IH0 G S AH0 N'],
  "ludvigson": ['L AH1 D V IH0 G S AH0 N'],
  "ludvik": ['L AH1 D V IH0 K'],
  "ludwick": ['L AH1 D W IH0 K'],
  "ludwig": ['L AH1 D W IH0 G'],
  "ludwiga": ['L AH0 D V AY1 G AH0'],
  "ludwigshafen": ['L AH0 D W IH1 G SH AH0 F AH0 N'],
  "ludwin": ['L AH1 D W IH0 N'],
  "ludy": ['L UW1 D IY0'],
  "lue": ['L UW1'],
  "luebbe": ['L UW1 B'],
  "luebbers": ['L UH1 B ER0 Z'],
  "luebbert": ['L UH1 B ER0 T'],
  "luebke": ['L UW1 B K'],
  "lueck": ['L UW1 IH0 K'],
  "luecke": ['L UW1 K'],
  "luedecke": ['L UH1 D IH0 K'],
  "lueder": ['L UH1 D ER0'],
  "lueders": ['L UH1 D ER0 Z'],
  "luedke": ['L UW1 D K IY0'],
  "luedtke": ['L UH1 D K IY0'],
  "lueh": ['L W EH1'],
  "luehring": ['L UH1 R IH0 NG'],
  "luehrs": ['L UH1 R Z'],
  "lueken": ['L UH1 K AH0 N'],
  "luella": ['L UW2 EH1 L AH0'],
  "luelle": ['L UW1 L'],
  "luellen": ['L UH1 L AH0 N'],
  "luepke": ['L UW1 P K IY0'],
  "luera": ['L UW0 EH1 R AH0'],
  "lueras": ['L UH1 R AH0 Z', 'L UW0 EH1 R AH0 Z'],
  "luers": ['L UW1 ER0 Z'],
  "luerssen": ['L UW1 R S AH0 N'],
  "lueth": ['L UW1 TH'],
  "luetkemeyer": ['L UH1 T K IH0 M AY0 ER0'],
  "luevano": ['L UW0 EH0 V AA1 N OW0'],
  "luff": ['L AH1 F'],
  "luffed": ['L AH1 F T'],
  "luffman": ['L AH1 F M AH0 N'],
  "lufkin": ['L AH1 F K IH0 N'],
  "luft": ['L AH1 F T'],
  "lufthansa": ['L AH0 F T AE1 N Z AH0'],
  "lufthansa's": ['L AH0 F T AE1 N Z AH0 Z'],
  "luftig": ['L AH1 F T IH0 G'],
  "luftkin": ['L AH1 F T K IH0 N'],
  "lufttransport": ['L AH1 F T R AE2 N Z P AO2 R T'],
  "lug": ['L AH1 G'],
  "lugano": ['L UW0 G AA1 N OW0'],
  "lugar": ['L UW1 G ER0'],
  "lugar's": ['L UW1 G ER0 Z'],
  "lugarda": ['L UW0 G AA1 R D AH0'],
  "luge": ['L UW1 JH'],
  "luger": ['L UW1 G ER0'],
  "lugers": ['L UW1 G ER0 Z'],
  "luggage": ['L AH1 G AH0 JH', 'L AH1 G IH0 JH'],
  "lugging": ['L AH1 G IH0 NG'],
  "lugi": ['L UW1 G IY0'],
  "luginbill": ['L AH1 G IH0 N B AH0 L'],
  "luginbuhl": ['L AH1 G IH0 N B AH0 L'],
  "lugkov": ['L UW1 G K AO0 V'],
  "lugo": ['L UW1 G OW0'],
  "lugosi": ['L UW0 G OW1 S IY0', 'L AH0 G OW1 S IY0'],
  "lugs": ['L AH1 G Z'],
  "luguarda": ['L UW1 G AA0 R D AH0'],
  "lugubrious": ['L UW0 G Y UW1 B R IY0 AH0 S'],
  "lugworm": ['L AH1 G W ER0 M'],
  "lugworms": ['L AH1 G W ER0 M Z'],
  "luhman": ['L AH1 M AH0 N'],
  "luhmann": ['L AH1 M AH0 N'],
  "luhn": ['L AH1 N'],
  "luhr": ['L ER1', 'L UH1 R'],
  "luhring": ['L UH1 R IH0 NG'],
  "luhrs": ['L UH1 R Z'],
  "lui": ['L UW1 IH0'],
  "luick": ['L UW1 K'],
  "luigi": ['L UW0 IY1 JH IY0'],
  "luigi's": ['L UW0 IY1 JH IY0 Z', 'L W IY1 JH IY0 Z'],
  "luikart": ['L UW1 K AA0 R T'],
  "luis": ['L UW0 IY1 S'],
  "luisa": ['L UW0 IY1 Z AH0'],
  "luisi": ['L UW1 S IY0'],
  "luiz": ['L UW1 Z'],
  "lujan": ['L UW0 Y AA1 N'],
  "luk": ['L AH1 K'],
  "luka": ['L UW1 K AH0'],
  "lukach": ['L AH1 K AH0 K'],
  "lukacs": ['L AH1 K AH0 K S'],
  "lukas": ['L UW1 K AH0 Z'],
  "lukash": ['L UW0 K AE1 SH'],
  "lukasiewicz": ['L AH0 K AA1 S AH0 V IH0 CH'],
  "lukasik": ['L AH0 K AA1 S IH0 K'],
  "lukaszewski": ['L AH0 K AH0 SH EH1 F S K IY0'],
  "lukavica": ['L UW0 K AH0 V IH1 K AH0'],
  "lukavizta": ['L UW0 K AH0 V IH1 T S T AH0'],
  "luke": ['L UW1 K'],
  "luke's": ['L UW1 K S'],
  "lukehart": ['L UW1 K HH AA0 R T'],
  "luken": ['L UW1 K AH0 N'],
  "lukenbill": ['L UW1 K IH0 N B IH0 L'],
  "lukens": ['L UW1 K AH0 N Z'],
  "luker": ['L UW1 K ER0'],
  "lukes": ['L UW1 K S'],
  "lukewarm": ['L UW1 K W AO1 R M'],
  "lukin": ['L UW1 K IH0 N'],
  "lukins": ['L UW1 K IH0 N Z'],
  "lukman": ['L AH1 K M AH0 N'],
  "lukoil": ['L UW1 K OY1 L'],
  "lukowski": ['L AH0 K AO1 F S K IY0'],
  "luks": ['L AH1 K S'],
  "lukyanov": ['L UW1 K Y AH0 N AA0 V'],
  "lula": ['L UW1 L AH0'],
  "lula's": ['L UW1 L AH0 Z'],
  "lulie": ['L AH1 L IY0'],
  "lull": ['L AH1 L'],
  "lullabies": ['L AH1 L AH0 B AY2 Z'],
  "lullaby": ['L AH1 L AH0 B AY2'],
  "lulled": ['L AH1 L D'],
  "lulling": ['L AH1 L IH0 NG'],
  "lulls": ['L AH1 L Z'],
  "lulu": ['L UW1 L UW2'],
  "lulue": ['L UW1 L UW2'],
  "lum": ['L AH1 M'],
  "luma": ['L UW1 M AH0'],
  "luman": ['L UW1 M AH0 N'],
  "lumb": ['L AH1 M'],
  "lumbago": ['L AH0 M B EY1 G OW2'],
  "lumbar": ['L AH1 M B AA2 R'],
  "lumbard": ['L AH1 M B ER0 D'],
  "lumber": ['L AH1 M B ER0'],
  "lumbering": ['L AH1 M B ER0 IH0 NG'],
  "lumberjack": ['L AH1 M B ER0 JH AE2 K'],
  "lumberman": ['L AH1 M B ER0 M AH0 N'],
  "lumberman's": ['L AH1 M B ER0 M AE2 N Z'],
  "lumbert": ['L AH1 M B ER0 T'],
  "lumberton": ['L AH1 M B ER0 T AH0 N'],
  "lumberyard": ['L AH1 M B ER0 Y AA2 R D'],
  "lumberyards": ['L AH1 M B ER0 Y AA2 R D Z'],
  "lumbra": ['L AH1 M B R AH0'],
  "lumen": ['L UW1 M AH0 N'],
  "lumet": ['L UW1 M AH0 T'],
  "lumex": ['L UW1 M AH0 K S'],
  "lumia": ['L UW1 M IY0 AH0'],
  "lumina": ['L UW1 M IH0 N AH0'],
  "luminal": ['L UW1 M AH0 N AH0 L'],
  "luminance": ['L UW1 M AH0 N AH0 N S'],
  "luminaries": ['L UW1 M AH0 N EH2 R IY0 Z'],
  "luminary": ['L UW1 M AH0 N EH2 R IY0'],
  "luminescence": ['L UW2 M AH0 N EH1 S AH0 N S'],
  "luminescent": ['L UW2 M AH0 N EH1 S AH0 N T'],
  "luminol": ['L UW2 M IH0 N AO1 L'],
  "luminoso": ['L UW2 M IH0 N OW1 S OW0'],
  "luminous": ['L UW1 M AH0 N AH0 S'],
  "lumley": ['L AH1 M L IY0'],
  "lumm": ['L AH1 M'],
  "lummus": ['L AH1 M AH0 S'],
  "lumonics": ['L UW0 M AA1 N IH0 K S'],
  "lump": ['L AH1 M P'],
  "lumpectomies": ['L AH2 M P EH1 K T AH0 M IY0 Z'],
  "lumpectomy": ['L AH2 M P EH1 K T AH0 M IY0'],
  "lumped": ['L AH1 M P T'],
  "lumper": ['L AH1 M P ER0'],
  "lumper's": ['L AH1 M P ER0 Z'],
  "lumping": ['L AH1 M P IH0 NG'],
  "lumpkin": ['L AH1 M P K IH0 N'],
  "lumpkins": ['L AH1 M P K IH0 N Z'],
  "lumpp": ['L AH1 M P'],
  "lumps": ['L AH1 M P S'],
  "lumpur": ['L AH2 M P UH1 R'],
  "lumpy": ['L AH1 M P IY0'],
  "lumsden": ['L AH1 M S D AH0 N'],
  "lun": ['L AH1 N'],
  "luna": ['L UW1 N AH0'],
  "lunacy": ['L UW1 N AH0 S IY0'],
  "lunar": ['L UW1 N ER0'],
  "lunati": ['L UW0 N AA0 T IY1'],
  "lunatic": ['L UW1 N AH0 T IH2 K'],
  "lunatics": ['L UW1 N AH0 T IH2 K S'],
  "lunberg": ['L AH1 N B ER0 G'],
  "lunceford": ['L AH1 N S IH0 F AO0 R D', 'L AH1 N S F AO0 R D'],
  "lunch": ['L AH1 N CH'],
  "lunched": ['L AH1 N CH T'],
  "luncheon": ['L AH1 N CH AH0 N'],
  "luncheonette": ['L AH2 N CH IH0 N EH1 T'],
  "luncheonettes": ['L AH2 N CH IH0 N EH1 T S'],
  "luncheons": ['L AH1 N CH AH0 N Z'],
  "lunches": ['L AH1 N CH IH0 Z'],
  "lunching": ['L AH1 N CH IH0 NG'],
  "lunchroom": ['L AH1 N CH R UW2 M'],
  "lunchrooms": ['L AH1 N CH R UW2 M Z'],
  "lunchtime": ['L AH1 N CH T AY2 M'],
  "lund": ['L AH1 N D'],
  "lundahl": ['L AH1 N D AA2 L'],
  "lunday": ['L AH1 N D EY2'],
  "lundberg": ['L AH1 N D B ER0 G'],
  "lundblad": ['L AH1 N D B L AH0 D'],
  "lundborg": ['L AH1 N D B AO0 R G'],
  "lunde": ['L AH1 N D'],
  "lundeen": ['L AH1 N D IY0 N'],
  "lundell": ['L AH1 N D AH0 L'],
  "lunden": ['L AH1 N D AH0 N'],
  "lunder": ['L AH1 N D ER0'],
  "lundgren": ['L AH1 N D G R EH0 N'],
  "lundholm": ['L AH1 N D HH OW2 L M'],
  "lundin": ['L AH1 N D IH0 N'],
  "lundmark": ['L AH1 N D M AA2 R K'],
  "lundquist": ['L AH1 N D K W IH2 S T'],
  "lundstedt": ['L AH1 N D S T IH0 T'],
  "lundsten": ['L AH1 N D S AH0 N'],
  "lundstrom": ['L AH1 N D S T R AH0 M'],
  "lundt": ['L AH1 N T'],
  "lundy": ['L AH1 N D IY0'],
  "lunenberg": ['L UW0 N EH1 N B ER0 G'],
  "lunetta": ['L UW0 N EH1 T AH0'],
  "lung": ['L AH1 NG'],
  "lunge": ['L AH1 N JH'],
  "lunged": ['L AH1 N JH D'],
  "lunger": ['L AH1 NG ER0'],
  "lunges": ['L AH1 N JH IH0 Z'],
  "lungfish": ['L AH1 NG F IH2 SH'],
  "lunging": ['L AH1 N JH IH0 NG'],
  "lungren": ['L AH1 NG R EH0 N'],
  "lungs": ['L AH1 NG Z'],
  "lunn": ['L AH1 N'],
  "lunney": ['L AH1 N IY0'],
  "lunny": ['L AH1 N IY0'],
  "lunsford": ['L AH1 N S F ER0 D'],
  "lunt": ['L AH1 N T'],
  "luntz": ['L AH1 N T S'],
  "luntz'": ['L AH1 N T S'],
  "luntz's": ['L AH1 N T S IH0 Z'],
  "lunz": ['L AH1 N Z'],
  "luo": ['L W OW1'],
  "luoma": ['L UW0 OW1 M AH0'],
  "luong": ['L UW0 AO1 NG'],
  "luongo": ['L UW0 OW1 NG G OW0'],
  "lupa": ['L UW1 P AH0'],
  "lupatkin": ['L UW2 P AA1 T K IH0 N'],
  "lupe": ['L UW1 P'],
  "luper": ['L UW1 P ER0'],
  "lupfer": ['L AH1 P F ER0'],
  "lupi": ['L UW1 P IY0'],
  "lupica": ['L UW0 P IY1 K AH0'],
  "lupien": ['L AH1 P IY0 N'],
  "lupin": ['L UW1 P AH0 N'],
  "lupinacci": ['L UW0 P IY0 N AA1 CH IY0'],
  "lupine": ['L UW1 P AY2 N'],
  "lupinski": ['L AH0 P IH1 N S K IY0'],
  "lupita": ['L UW0 P IY1 T AH0'],
  "luplow": ['L AH1 P L OW0'],
  "lupo": ['L UW1 P OW0'],
  "lupone": ['L UW2 P OW1 N'],
  "luppino": ['L UW0 P IY1 N OW0'],
  "luptak": ['L AH1 P T AH0 K'],
  "lupton": ['L AH1 P T AH0 N'],
  "lupus": ['L UW1 P AH0 S'],
  "luque": ['L UW1 K'],
  "luquette": ['L AH0 K EH1 T'],
  "lura": ['L UH1 R AH0'],
  "lurch": ['L ER1 CH'],
  "lurched": ['L ER1 CH T'],
  "lurches": ['L ER1 CH IH0 Z'],
  "lurching": ['L ER1 CH IH0 NG'],
  "lure": ['L UH1 R'],
  "lured": ['L UH1 R D'],
  "lures": ['L UH1 R Z'],
  "lurette": ['L ER0 EH1 T'],
  "lurgi": ['L ER1 JH IY0'],
  "luria": ['L UH1 R IY0 AH0'],
  "lurid": ['L UH1 R AH0 D'],
  "lurie": ['L UH1 R IY0'],
  "luring": ['L UH1 R IH0 NG'],
  "lurk": ['L ER1 K'],
  "lurked": ['L ER1 K T'],
  "lurking": ['L ER1 K IH0 NG'],
  "lurks": ['L ER1 K S'],
  "lurleen": ['L ER0 L IY1 N'],
  "lurlene": ['L ER1 L IY0 N'],
  "lurline": ['L ER1 L AY0 N'],
  "lurvey": ['L ER0 V EY1'],
  "lurz": ['L ER1 Z'],
  "lusaka": ['L UW0 S AA1 K AH0'],
  "lusardi": ['L UW0 S AA1 R D IY0'],
  "lusby": ['L AH1 S B IY0'],
  "lusch": ['L AH1 SH'],
  "luscher": ['L AH1 SH ER0'],
  "luscious": ['L AH1 SH IH0 S'],
  "luscombe": ['L UW0 S K OW1 M B IY0'],
  "luse": ['L UW1 Z'],
  "lush": ['L AH1 SH'],
  "lusher": ['L AH1 SH ER0'],
  "lushlife": ['L AH1 SH L AY0 F'],
  "lushly": ['L AH1 SH L IY0'],
  "lusignan": ['L AH1 S IH0 G N AH0 N'],
  "lusinchi": ['L UW0 S IH1 N CH IY0'],
  "lusitania": ['L UW2 S AH0 T EY1 N IY0 AH0'],
  "lusitania's": ['L UW2 S AH0 T EY1 N IY0 AH0 Z'],
  "lusitanias": ['L UW2 S AH0 T EY1 N IY0 AH0 Z'],
  "lusk": ['L AH1 S K'],
  "luskin": ['L AH1 S K IH0 N'],
  "lusky": ['L AH1 S K IY0'],
  "lusser": ['L AH1 S ER0'],
  "lussier": ['L AH1 S IY0 ER0'],
  "lust": ['L AH1 S T'],
  "lusted": ['L AH1 S T IH0 D'],
  "luster": ['L AH1 S T ER0'],
  "lustful": ['L AH1 S T F AH0 L'],
  "lustgarten": ['L AH1 S T G AA2 R D AH0 N'],
  "lustig": ['L AH1 S T IH0 G'],
  "lusting": ['L AH1 S T IH0 NG'],
  "lustre": ['L AH1 S T ER0'],
  "lustrous": ['L AH1 S T R AH0 S'],
  "lusty": ['L AH1 S T IY0'],
  "lute": ['L UW1 T'],
  "lutece": ['L UW2 T EH1 S'],
  "lutecia": ['L UW0 T IY1 SH Y AH0'],
  "luten": ['L UW1 T AH0 N'],
  "luter": ['L UW1 T ER0'],
  "lutes": ['L UW1 T S'],
  "lutey": ['L UW1 T IY0'],
  "lutfalla": ['L AH0 T F AE1 L AH0'],
  "lutgen": ['L AH1 T G AH0 N'],
  "luth": ['L UW1 TH'],
  "luther": ['L UW1 TH ER0'],
  "lutheran": ['L UW1 TH ER0 AH0 N'],
  "lutherans": ['L UW1 TH ER0 AH0 N Z'],
  "luthi": ['L UW1 DH IY0'],
  "luthy": ['L AH1 TH IY0'],
  "lutman": ['L AH1 T M AH0 N'],
  "luton": ['L UW1 T AH0 N'],
  "lutsky": ['L AH1 T S K IY0'],
  "lutter": ['L AH1 T ER0'],
  "lutterman": ['L AH1 T ER0 M AH0 N'],
  "luttman": ['L AH1 T M AH0 N'],
  "lutton": ['L AH1 T AH0 N'],
  "luttrell": ['L AH1 T R AH0 L'],
  "luttrull": ['L AH1 T R AH0 L'],
  "luttwak": ['L AH1 T W AA0 K'],
  "lutweiler": ['L AH1 T W AY0 L ER0'],
  "luty": ['L UW1 T IY0'],
  "lutz": ['L AH1 T S'],
  "lutze": ['L AH1 T Z'],
  "lutzke": ['L AH1 T S K IY0'],
  "luu": ['L UW1 UW0'],
  "luvena": ['L AH1 V IH0 N AH0'],
  "luvs": ['L AH1 V Z'],
  "luwana": ['L AH0 W AE1 N AH0'],
  "lux": ['L AH1 K S'],
  "luxembourg": ['L AH1 K S AH0 M B AO2 R G', 'L AH1 K S AH0 M B ER1 G'],
  "luxor": ['L AH1 K S ER0'],
  "luxottica": ['L AH2 K S OW0 T IY1 K AH0', 'L AH2 K S AA1 T IH0 K AH0'],
  "luxottica's": ['L AH2 K S OW0 T IY1 K AH0 Z', 'L AH2 K S AA1 T IH0 K AH0 Z'],
  "luxton": ['L AH1 K S T AH0 N'],
  "luxuriant": ['L AH0 G ZH ER1 IY0 AH0 N T'],
  "luxuries": ['L AH1 G ZH ER0 IY0 Z'],
  "luxurious": ['L AH0 G ZH ER1 IY0 AH0 S'],
  "luxury": ['L AH1 G ZH ER0 IY0'],
  "luyster": ['L AY1 S T ER0'],
  "luz": ['L AH1 Z'],
  "luzader": ['L AH1 Z AH0 D ER0'],
  "luzar": ['L UW1 Z ER0'],
  "luzhkov": ['L UW1 ZH K AO2 V'],
  "luzier": ['L UW1 Z IY0 ER0'],
  "luzon": ['L UW1 Z AA0 N'],
  "luzzi": ['L UW1 T S IY0'],
  "lwin": ['L W IH1 N', 'EH1 L W IH2 N', 'L UW1 IH2 N'],
  "lxi": ['L K S IY1', 'EH1 L EH1 K S AY1'],
  "ly": ['L AY1'],
  "lyall": ['L AY1 AH0 L'],
  "lyanne": ['L IY0 AE1 N', 'L AY2 AE1 N'],
  "lybarger": ['L AY1 B AA2 R G ER0'],
  "lybeck": ['L AY1 B EH2 K'],
  "lybrand": ['L AY1 B R AE2 N D'],
  "lybrand's": ['L AY1 B R AH0 N D Z'],
  "lycan": ['L AY1 K AH0 N'],
  "lycoming": ['L AY1 K AH0 M IH0 NG', 'L AY2 K OW1 M IH0 NG'],
  "lycos": ['L AY1 K OW0 S'],
  "lycra": ['L AY1 K R AH0'],
  "lycurgus": ['L AY0 K ER1 G AH0 S'],
  "lyda": ['L AY1 D AH0'],
  "lydall": ['L AY1 D AH0 L'],
  "lyday": ['L AY1 D EY2'],
  "lyde": ['L AY1 D'],
  "lyden": ['L AY1 D AH0 N'],
  "lydia": ['L IH1 D IY0 AH0'],
  "lydic": ['L IH1 D IH0 K'],
  "lydick": ['L IH1 D IH0 K'],
  "lydie": ['L IH1 D IY0'],
  "lydon": ['L IH1 D AH0 N'],
  "lye": ['L AY1'],
  "lyell": ['L AY2 EH1 L'],
  "lyerla": ['L AY2 ER1 L AH0'],
  "lyerly": ['L AY1 ER0 L IY0'],
  "lyford": ['L IH1 F ER0 D'],
  "lying": ['L AY1 IH0 NG'],
  "lyke": ['L AY1 K'],
  "lykens": ['L AY1 K AH0 N Z'],
  "lykes": ['L AY1 K S'],
  "lykin": ['L AY1 K AH0 N'],
  "lykins": ['L IH1 K AH0 N Z'],
  "lyle": ['L AY1 L'],
  "lyle's": ['L AY1 L Z'],
  "lyles": ['L AY1 L Z'],
  "lyman": ['L AY1 M AH0 N'],
  "lyme": ['L AY1 M'],
  "lymph": ['L IH1 M F'],
  "lymphadenopathy": ['L IH2 M F AH0 D EH1 N AH0 P AE2 TH IY0'],
  "lymphatic": ['L IH2 M F AE1 T IH0 K'],
  "lymphoblast": ['L IH2 M F AH0 B L AE1 S T'],
  "lymphoblastic": ['L IH2 M F AH0 B L AE1 S T AH0 K'],
  "lymphocyte": ['L IH1 M F AH0 S AY2 T'],
  "lymphocytes": ['L IH1 M F AH0 S AY2 T S'],
  "lymphoid": ['L IH1 M F OY2 D'],
  "lymphoma": ['L IH0 M F OW1 M AH0'],
  "lymphomas": ['L IH0 M F OW1 M AH0 Z'],
  "lyn": ['L IH1 N'],
  "lynam": ['L IH1 N AH0 M'],
  "lynch": ['L IH1 N CH'],
  "lynch's": ['L IH1 N CH IH0 Z'],
  "lynchburg": ['L IH1 N CH B ER0 G'],
  "lynched": ['L IH1 N CH T'],
  "lynching": ['L IH1 N CH IH0 NG'],
  "lynchings": ['L IH1 N CH IH0 NG Z'],
  "lynd": ['L IH1 N D'],
  "lynda": ['L IH1 N D AH0'],
  "lynde": ['L IH1 N D'],
  "lynden": ['L IH1 N D AH0 N'],
  "lyndhurst": ['L IH1 N D HH ER0 S T'],
  "lyndon": ['L IH1 N D AH0 N'],
  "lynds": ['L IH1 N D Z'],
  "lyne": ['L AY1 N'],
  "lynes": ['L AY1 N Z'],
  "lyness": ['L AY2 N EH1 S'],
  "lynette": ['L AY2 N EH1 T', 'L IH0 N EH1 T'],
  "lynford": ['L IH1 N F ER0 D'],
  "lyng": ['L IH1 NG'],
  "lynk": ['L IH1 NG K'],
  "lynn": ['L IH1 N'],
  "lynn's": ['L IH1 N Z'],
  "lynna": ['L AY1 N AH0'],
  "lynne": ['L IH1 N'],
  "lynott": ['L AY1 N AH0 T'],
  "lynskey": ['L IH1 N S K IY0'],
  "lynton": ['L IH1 N T AH0 N'],
  "lynx": ['L IH1 NG K S'],
  "lynxes": ['L IH1 NG K S IH0 Z'],
  "lyon": ['L AY1 AH0 N'],
  "lyon's": ['L AY1 AH0 N Z'],
  "lyondell": ['L AY2 AH0 N D EH1 L'],
  "lyonnais": ['L IY2 AH0 N EY1', 'L AY2 AH0 N EY1 Z'],
  "lyonnais's": ['L AY2 AH0 N EY1 Z IH0 Z'],
  "lyonnaise": ['L AY2 AH0 N EY1 Z'],
  "lyons": ['L AY1 AH0 N Z'],
  "lyons's": ['L AY1 AH0 N Z IH0 Z'],
  "lyphomed": ['L AY1 F AH0 M EH0 D', 'L IH1 F AH0 M EH0 D'],
  "lyphomed's": ['L AY1 F AH0 M EH0 D Z', 'L IH1 F AH0 M EH0 D Z'],
  "lyra": ['L AY1 R AH0'],
  "lyre": ['L AY1 R'],
  "lyric": ['L IH1 R IH0 K'],
  "lyrical": ['L IH1 R IH0 K AH0 L'],
  "lyrically": ['L IH1 R IH0 K L IY0'],
  "lyricism": ['L IH1 R IH0 S IH2 Z AH0 M'],
  "lyricist": ['L IH1 R IH0 S IH0 S T'],
  "lyricists": ['L IH1 R IH0 S IH0 S T S'],
  "lyrics": ['L IH1 R IH0 K S'],
  "lyris": ['L IH1 R IH0 S'],
  "lyrist": ['L IH1 R IH0 S T'],
  "lysaght": ['L AY1 S AA0 T'],
  "lysander": ['L AY2 S AE1 N D ER0'],
  "lysandra": ['L AY2 S AE1 N D R AH0'],
  "lysine": ['L AY1 S IY0 N'],
  "lysis": ['L AY1 S IH0 S'],
  "lysistrata": ['L AY2 S IH0 S T R AA1 T AH0'],
  "lyski": ['L AY1 S K IY0'],
  "lysne": ['L AY1 N'],
  "lysol": ['L AY1 S AO2 L'],
  "lyssy": ['L IH1 S IY0'],
  "lyster": ['L IH1 S T ER0'],
  "lyter": ['L AY1 T ER0'],
  "lythgoe": ['L IH1 TH G OW0'],
  "lytle": ['L AY1 T AH0 L'],
  "lyttle": ['L IH1 T AH0 L'],
  "lytton": ['L IH1 T AH0 N'],
  "lyubimov": ['L Y UW1 B AH0 M AA0 V'],
  "lyubimov's": ['L Y UW1 B AH0 M AA0 V Z'],
  "lyvers": ['L AY1 V ER0 Z'],
  "m": ['EH1 M'],
  "m'bow": ['M B OW1', 'EH2 M B OW1'],
  "m's": ['EH1 M Z'],
  "m-code": ['EH1 M K OW1 D'],
  "m-codes": ['EH1 M K OW1 D Z'],
  "m.": ['EH1 M'],
  "m.'s": ['EH1 M Z'],
  "m.d.": ['EH2 M D IY1'],
  "m.s": ['EH1 M Z'],
  "ma": ['M AA1'],
  "ma'am": ['M AE1 M'],
  "maack": ['M AA1 K'],
  "maaco": ['M EY1 K OW0'],
  "maag": ['M AA1 G'],
  "maahs": ['M AA1 Z'],
  "maalox": ['M EY1 L AA0 K S'],
  "maam": ['M AH1 M'],
  "maariv": ['M AA0 R IY1 V'],
  "maas": ['M AA1 Z'],
  "maass": ['M AA1 S'],
  "maassen": ['M AA1 S AH0 N'],
  "maastricht": ['M AA1 S T R IH2 K T'],
  "mab": ['M AE1 B'],
  "mabe": ['M EY1 B'],
  "mabee": ['M AE1 B IY0'],
  "mabel": ['M EY1 B AH0 L'],
  "mabelle": ['M AH0 B EH1 L'],
  "maben": ['M AE1 B AH0 N'],
  "maberry": ['M AA1 B EH0 R IY0'],
  "mabery": ['M AE1 B ER0 IY0'],
  "mabey": ['M EY1 B IY0'],
  "mabie": ['M AE1 B IY0'],
  "mabile": ['M AA1 B AH0 L'],
  "mabin": ['M AE1 B IH0 N'],
  "mabis": ['M AE1 B IH0 S'],
  "mable": ['M EY1 B AH0 L'],
  "mabley": ['M AE1 B L IY0'],
  "mabon": ['M EY1 B AH0 N'],
  "mabrey": ['M AE1 B R IY0'],
  "mabry": ['M AE1 B ER0 IY0'],
  "mabus": ['M AE1 B IH0 S'],
  "mac": ['M AE1 K'],
  "mac's": ['M AE1 K S'],
  "macabre": ['M AH0 K AA1 B R AH0', 'M AH0 K AA1 B ER0'],
  "macadam": ['M AH0 K AE1 D AH0 M'],
  "macadamia": ['M AE2 K AH0 D EY1 M IY0 AH0'],
  "macallister": ['M AH0 K AE1 L IH0 S T ER0'],
  "macalpine": ['M AH0 K AE1 L P AY1 N'],
  "macaluso": ['M AE2 K AH0 L UW1 S OW0'],
  "macandrews": ['M AH0 K AE1 N D R UW2 Z'],
  "macao": ['M AH0 K AW1'],
  "macaques": ['M AH0 K AA1 K S'],
  "macarena": ['M AE0 K AH0 R IY1 N AH0'],
  "macari": ['M AA0 K AA1 R IY0'],
  "macaroni": ['M AE2 K ER0 OW1 N IY0'],
  "macarthur": ['M AH0 K AA1 R TH ER0'],
  "macartney": ['M AH0 K AA1 R T N IY0'],
  "macau": ['M AH0 K AW1'],
  "macaulay": ['M AH0 K AO1 L IY0'],
  "macauley": ['M AH0 K AO1 L IY0'],
  "macaw": ['M AH0 K AO1'],
  "macaws": ['M AH0 K AO1 Z'],
  "macbeth": ['M AH0 K B EH1 TH'],
  "macbook": ['M AE1 K B UH2 K'],
  "macbride": ['M AH0 K B R AY1 D'],
  "maccabean": ['M AE2 K AH0 B IY1 AH0 N'],
  "maccabee": ['M AE1 K AH0 B IY2'],
  "maccabees": ['M AE1 K AH0 B IY2 Z'],
  "maccallum": ['M AH0 K AE1 L AH0 M'],
  "maccaquano": ['M AE2 K AH0 K W AA1 N OW0'],
  "maccarone": ['M AE1 K ER0 OW2 N'],
  "maccarthy": ['M AH0 K AA1 R TH IY0'],
  "macchi": ['M AE1 K IY0'],
  "macchia": ['M AE1 K IY0 AH0'],
  "macchio": ['M AE1 K IY0 OW0'],
  "macconnell": ['M AH0 K AA1 N AH0 L'],
  "maccormack": ['M AH0 K AO1 R M AH0 K'],
  "macdermott": ['M AH0 K D ER1 M AH0 T'],
  "macdiarmid": ['M AH0 K D IH1 R M IH0 D'],
  "macdill": ['M AH0 K D IH1 L'],
  "macdill's": ['M AH0 K D IH1 L Z'],
  "macdonald": ['M AH0 K D AA1 N AH0 L D'],
  "macdonald's": ['M AH0 K D AA1 N AH0 L D Z'],
  "macdonell": ['M AH0 K D AA1 N AH0 L'],
  "macdonnell": ['M AH0 K D AA1 N AH0 L'],
  "macdonough": ['M AH0 K D AA1 N AH0 F'],
  "macdougal": ['M AH0 K D UW1 G AH0 L'],
  "macdougall": ['M AH0 K D UW1 G AH0 L'],
  "macdowell": ['M AH0 K D AW1 AH0 L'],
  "macduff": ['M AH0 K D AH1 F'],
  "mace": ['M EY1 S'],
  "maceachern": ['M AH0 K IY1 CH ER0 N'],
  "maceda": ['M AH0 S EY1 D AH0'],
  "macedo": ['M AH0 S EY1 D OW0'],
  "macedonia": ['M AE2 S AH0 D OW1 N IY0 AH0', 'M AE2 K AH0 D OW1 N IY0 AH0'],
  "macedonia's": ['M AE2 S AH0 D OW1 N IY0 AH0 Z', 'M AE2 K AH0 D OW1 N IY0 AH0 Z'],
  "macedonian": ['M AE2 S AH0 D OW1 N Y AH0 N', 'M AE2 K AH0 D OW1 N Y AH0 N'],
  "macedonians": ['M AE2 S AH0 D OW1 N IY0 AH0 N Z', 'M AE2 K AH0 D OW1 N IY0 AH0 N Z'],
  "macek": ['M IH0 K EH1 K'],
  "macera": ['M AH0 S EH1 R AH0'],
  "macewan": ['M AH0 K Y UW1 AH0 N', 'M EY1 S W AA0 N'],
  "macewen": ['M AH0 K Y UW1 AH0 N', 'M EY1 S W AA0 N'],
  "macey": ['M EY1 S IY0'],
  "macfadden": ['M AH0 K F AE1 D AH0 N'],
  "macfadyen": ['M AH0 K F AE1 D IY0 AH0 N'],
  "macfarland": ['M AH0 K F AA1 R L AH0 N D'],
  "macfarlane": ['M AH0 K F AA1 R L AH0 N'],
  "macgowan": ['M AH0 G AW1 AH0 N'],
  "macgraw": ['M AH0 G R AA1'],
  "macgregor": ['M AH0 G R EH1 G ER0'],
  "macgyver": ['M AH0 G AY1 V ER0'],
  "macgyver's": ['M AH0 G AY1 V ER0 Z'],
  "mach": ['M AA1 K'],
  "macha": ['M AE1 CH AH0'],
  "machacek": ['M AE1 K AH0 S IH0 K'],
  "machado": ['M AH0 CH AA1 D OW0'],
  "machain": ['M AH0 CH EY2 N'],
  "machala": ['M AH0 CH AA1 L AH0'],
  "machamer": ['M AE1 K AH0 M ER0'],
  "mache": ['M AE1 CH'],
  "machel": ['M AE1 CH AH0 L'],
  "machen": ['M AE1 K AH0 N'],
  "macher": ['M AE1 K ER0'],
  "macheski": ['M AH0 CH EH1 S K IY0'],
  "machete": ['M AH0 SH EH1 T IY2', 'M AH0 CH EH1 T IY2'],
  "machetes": ['M AH0 SH EH1 T IY2 Z', 'M AH0 CH EH1 T IY2 Z'],
  "machi": ['M AA1 K IY0'],
  "machiavelli": ['M AA2 K IY0 AH0 V EH1 L IY0', 'M AA2 K Y AH0 V EH1 L IY0'],
  "machiavelli's": ['M AA2 K IY0 AH0 V EH1 L IY0 Z', 'M AA2 K Y AH0 V EH1 L IY0 Z'],
  "machiavellian": ['M AA2 K IY0 AH0 V EH1 L IY0 AH0 N', 'M AA2 K Y AH0 V EH1 L IY0 AH0 N'],
  "machida": ['M AH0 CH IY1 D AH0'],
  "machin": ['M AE1 CH IH0 N'],
  "machination": ['M AE2 K AH0 N EY1 SH AH0 N', 'M AE2 SH AH0 N EY1 SH AH0 N'],
  "machinations": ['M AE2 K AH0 N EY1 SH AH0 N Z', 'M AE2 SH AH0 N EY1 SH AH0 N Z'],
  "machine": ['M AH0 SH IY1 N'],
  "machine's": ['M AH0 SH IY1 N Z'],
  "machinea": ['M AE2 SH AH0 N IY1 AH0'],
  "machined": ['M AH0 SH IY1 N D'],
  "machinegun": ['M AH0 SH IY1 N G AH0 N'],
  "machineguns": ['M AH0 SH IY1 N G AH0 N Z'],
  "machineries": ['M AH0 SH IY1 N ER0 IY0 Z', 'M AH0 SH IY1 N R IY0 Z'],
  "machinery": ['M AH0 SH IY1 N ER0 IY0', 'M AH0 SH IY1 N R IY0'],
  "machines": ['M AH0 SH IY1 N Z'],
  "machines'": ['M AH0 CH IY1 N Z'],
  "machinima": ['M AH0 SH IY1 N IH0 M AH0'],
  "machining": ['M AH0 SH IY1 N IH0 NG'],
  "machinist": ['M AH0 SH IY1 N AH0 S T'],
  "machinists": ['M AH0 SH IY1 N IH0 S T S'],
  "machinists'": ['M AH0 SH IY1 N IH0 S T S'],
  "machismo": ['M AH0 CH IH1 Z M OW0', 'M AH0 K IH1 Z M OW0'],
  "machivelli": ['M AE2 K AH0 V EH1 L IY0'],
  "machivellian": ['M AE2 K AH0 V EH1 L IY0 AH0 N'],
  "machnik": ['M AE1 K N IH0 K'],
  "macho": ['M AA1 CH OW0'],
  "machold": ['M AH0 HH OW1 L D'],
  "machowski": ['M AH0 CH AO1 F S K IY0'],
  "macht": ['M AE1 CH T'],
  "machtley": ['M AE1 CH T L IY0'],
  "machuca": ['M AH0 CH UW1 K AH0'],
  "machuga": ['M AH0 CH UW1 G AH0'],
  "maciag": ['M AH0 K EY1 G'],
  "macias": ['M AA0 S IY1 AH0 S'],
  "maciejewski": ['M AH0 CH EH1 F S K IY0', 'M AH0 CH UW1 S K IY0'],
  "maciel": ['M IH0 K IY1 L'],
  "macik": ['M AA1 CH IH0 K'],
  "macinnes": ['M AH0 K IH1 N AH0 S'],
  "macinnis": ['M AH0 K IH1 N AH0 S'],
  "macintax": ['M AE1 K AH0 N T AE2 K S'],
  "macintosh": ['M AE1 K AH0 N T AO2 SH'],
  "macintosh's": ['M AE1 K AH0 N T AA2 SH IH0 Z'],
  "macintoshes": ['M AE1 K AH0 N T AO2 SH IH0 Z'],
  "macintyre": ['M AE1 K AH0 N T AY2 R'],
  "macioce": ['M AA0 CH OW1 CH IY0'],
  "maciolek": ['M AH0 CH IY0 OW1 L EH0 K'],
  "macisaac": ['M AH0 CH IH1 S AE2 K'],
  "maciver": ['M IY1 K IH0 V ER0'],
  "mack": ['M AE1 K'],
  "mack's": ['M AE1 K S'],
  "mackall": ['M AE1 K AH0 L'],
  "mackay": ['M AH0 K EY1'],
  "macke": ['M AE1 K'],
  "mackel": ['M AE1 K AH0 L'],
  "mackellar": ['M AH0 K EH1 L ER0'],
  "macken": ['M AE1 K AH0 N'],
  "mackenzie": ['M AH0 K EH1 N Z IY0'],
  "mackerel": ['M AE1 K ER0 AH0 L'],
  "mackert": ['M AE1 K ER0 T'],
  "mackey": ['M AE1 K IY0'],
  "mackie": ['M AE1 K IY0'],
  "mackie's": ['M AE1 K IY0 Z'],
  "mackiewicz": ['M AE1 K IY0 AH0 W IH0 T S', 'M AE1 K Y AH0 W IH0 T S'],
  "mackin": ['M AE1 K IH0 N'],
  "mackinaw": ['M AE1 K AH0 N AO2'],
  "mackinley": ['M AH0 K IH1 N L IY0'],
  "mackinney": ['M AH0 K IH1 N IY0'],
  "mackinnon": ['M AH0 K IH1 N AH0 N'],
  "mackintosh": ['M AE1 K AH0 N T AA2 SH'],
  "macklem": ['M AE1 K L AH0 M'],
  "mackler": ['M AE1 K L ER0'],
  "mackley": ['M AE1 K L IY0'],
  "macklin": ['M AE1 K L IH0 N'],
  "macklin's": ['M AE1 K L IH0 N Z'],
  "macknay": ['M AE0 K N EY1'],
  "macknight": ['M AH0 K N AY1 T'],
  "macko": ['M AE1 K OW0'],
  "mackowiak": ['M AH0 S K AW1 IY0 AE0 K'],
  "mackowski": ['M AH0 S K AO1 F S K IY0'],
  "macks": ['M AE1 K S'],
  "macktal": ['M AE1 K T AH0 L'],
  "maclachlan": ['M AH0 K L AE1 K L AH0 N'],
  "maclaine": ['M AH0 K L EY1 N'],
  "maclaren": ['M AH0 K L EH1 R AH0 N'],
  "maclaughlin": ['M AH0 K L AO1 K L IH0 N'],
  "maclay": ['M AH0 K L EY1'],
  "maclean": ['M AH0 K L EY1 N'],
  "macleish": ['M AH0 K L IY1 SH'],
  "maclellan": ['M AH0 K L EH1 L AH0 N'],
  "maclennan": ['M AH0 K L EH1 N AH0 N'],
  "macleod": ['M AH0 K L AW1 D'],
  "maclin": ['M AE1 K L AH0 N'],
  "macmahon": ['M AH0 K M AE1 N'],
  "macmaster": ['M AH0 K M AE1 S T ER0'],
  "macmillan": ['M AH0 K M IH1 L AH0 N'],
  "macmillan's": ['M AH0 K M IH1 L AH0 N Z'],
  "macmullen": ['M AH0 K M AH1 L AH0 N'],
  "macmurray": ['M AH0 K M ER1 IY0'],
  "macnab": ['M AH0 K N AE1 B'],
  "macnair": ['M AH0 K N EH1 R'],
  "macnamara": ['M AE1 K N AH0 M EH2 R AH0'],
  "macnaughton": ['M AH0 K N AO1 T AH0 N'],
  "macneal": ['M AH0 K N IY1 L'],
  "macneil": ['M AH0 K N IY1 L'],
  "macneill": ['M AH0 K N IY1 L'],
  "macomb": ['M EY1 K AH0 M'],
  "macomber": ['M AH0 K AA1 M B ER0'],
  "macon": ['M EY1 K AH0 N'],
  "macoute": ['M AH0 K UW1 T'],
  "macoutes": ['M AH0 K UW1 T S'],
  "macphail": ['M AH0 K F EY1 L'],
  "macphee": ['M AH0 K F IY1'],
  "macpherson": ['M AH0 K F ER1 S AH0 N', 'M AH0 K F IH1 R S AH0 N'],
  "macpherson's": ['M AH0 K F ER1 S AH0 N Z', 'M AH0 K F IH1 R S AH0 N Z'],
  "macquarrie": ['M AH0 K EH1 R IY0'],
  "macqueen": ['M AH0 K W IY1 N'],
  "macrae": ['M AH0 K R EY1'],
  "macrame": ['M AE1 K R AH0 M EY2'],
  "macri": ['M AE1 K R IY0'],
  "macro": ['M AE1 K R OW0'],
  "macrodantin": ['M AE2 K R OW0 D AE1 N T IH0 N'],
  "macroeconomic": ['M AE2 K R OW0 EH0 K AH0 N AA1 M IH0 K', 'M AE2 K R OW0 IY0 K AH0 N AA1 M IH0 K'],
  "macroeconomics": ['M AE2 K R OW0 EH0 K AH0 N AA1 M IH0 K S', 'M AE2 K R OW0 IY0 K AH0 N AA1 M IH0 K S'],
  "macromedia": ['M AE2 K R OW0 M IY1 D IY0 AH0'],
  "macrophage": ['M AE1 K R OW0 F EY2 JH'],
  "macrophages": ['M AE1 K R OW0 F EY2 JH IH0 Z'],
  "macrovision": ['M AE1 K R OW0 V IH2 ZH AH0 N'],
  "macs": ['M AE1 K S'],
  "macsharry": ['M AH0 K SH EH1 R IY0'],
  "mactaggart": ['M AH0 K T AE1 G ER0 T'],
  "mactan": ['M AH0 K T AE1 N'],
  "mactavish": ['M AH0 K T AE1 V IH0 SH'],
  "macumber": ['M AH0 K AH1 M B ER0'],
  "macumolo": ['M AH0 K UW1 M OW0 L OW0'],
  "macumolo's": ['M AH0 K UW1 M OW0 L OW0 Z'],
  "macvicar": ['M AH0 K V IH1 K ER0'],
  "macvicar's": ['M AH0 K V IH1 K ER0 Z'],
  "macvie": ['M AE1 K V IY0'],
  "macwilliams": ['M AH0 K W IH1 L Y AH0 M Z'],
  "macworld": ['M AE1 K W ER2 L D'],
  "macy": ['M EY1 S IY0'],
  "macy's": ['M EY1 S IY0 Z'],
  "macys": ['M EY1 S IY0 Z'],
  "maczko": ['M AA1 CH K OW0'],
  "mad": ['M AE1 D'],
  "mada": ['M AA1 D AH0'],
  "madagascar": ['M AE2 D AH0 G AE1 S K ER0'],
  "madalena": ['M AE2 D AH0 L EY1 N AH0'],
  "madam": ['M AE1 D AH0 M'],
  "madame": ['M AE1 D AH0 M', 'M AH0 D AE1 M'],
  "madan": ['M EY1 D AH0 N'],
  "madar": ['M AE1 D ER0'],
  "madara": ['M AA0 D AA1 R AH0'],
  "madaras": ['M AA0 D AA1 R AA0 Z'],
  "madaris": ['M AE1 D ER0 IH0 S'],
  "maday": ['M AA1 D EY0'],
  "madcap": ['M AE1 D K AE2 P'],
  "madd": ['M AE1 D'],
  "maddalena": ['M AA0 D AA0 L EH1 N AH0'],
  "maddamma": ['M AH0 D AA1 M AH0'],
  "madden": ['M AE1 D AH0 N'],
  "maddening": ['M AE1 D AH0 N IH0 NG', 'M AE1 D N IH0 NG'],
  "maddeningly": ['M AE1 D AH0 N IH0 NG L IY0', 'M AE1 D N IH0 NG L IY0'],
  "madder": ['M AE1 D ER0'],
  "maddie": ['M AE1 D IY0'],
  "madding": ['M AE1 D IH0 NG'],
  "maddison": ['M AE1 D IH0 S AH0 N'],
  "maddock": ['M AE1 D AH0 K'],
  "maddocks": ['M AE1 D AH0 K S'],
  "maddox": ['M AE1 D AH0 K S'],
  "maddux": ['M AE1 D AH0 K S'],
  "maddy": ['M AE1 D IY0'],
  "made": ['M EY1 D'],
  "madeira": ['M AH0 D IH1 R AH0'],
  "madej": ['M AE1 D IH0 JH'],
  "madel": ['M AE1 D AH0 L'],
  "madelaine": ['M AE1 D IH0 L EY0 N'],
  "madeleine": ['M AE2 D AH0 L EH1 N'],
  "madelena": ['M AA0 D EH0 L EH1 N AH0'],
  "madelene": ['M AE0 D AH0 L IY1 N'],
  "madelin": ['M AE1 D AH0 L IH0 N'],
  "madeline": ['M AE1 D AH0 L IH0 N'],
  "madella": ['M AH0 D EH1 L AH0'],
  "madelle": ['M AH0 D EH1 L'],
  "madelon": ['M AA0 D EY0 L AO1 N'],
  "madelyn": ['M AE1 D IH0 L IH0 N', 'M AE1 D L IH0 N'],
  "mademoiselle": ['M AE2 D AH0 M AH0 Z EH1 L'],
  "maden": ['M EY1 D AH0 N'],
  "mader": ['M EY1 D ER0'],
  "madera": ['M AA0 D EH1 R AH0'],
  "madere": ['M AE1 D ER0'],
  "madero": ['M AA0 D EH1 R OW0'],
  "madewell": ['M AE1 D IH0 W EH0 L', 'M EY1 D W EH0 L'],
  "madey": ['M EY1 D IY0'],
  "madge": ['M AE1 JH'],
  "madhouse": ['M AE1 D HH AW2 S'],
  "madhusudan": ['M AA2 D HH UW0 S UW1 D AH0 N'],
  "madia": ['M AA1 D IY0 AH0'],
  "madigan": ['M AE1 D IH0 G AH0 N'],
  "madill": ['M AA0 D IY1 L'],
  "madis": ['M AE1 D AH0 S'],
  "madison": ['M AE1 D AH0 S AH0 N', 'M AE1 D IH0 S AH0 N'],
  "madison's": ['M AE1 D AH0 S AH0 N Z', 'M AE1 D IH0 S AH0 N Z'],
  "madkins": ['M AE1 D K IH0 N Z'],
  "madl": ['M AE1 D AH0 L'],
  "madlen": ['M AE1 D AH0 L AH0 N'],
  "madlin": ['M AE1 D L IH0 N'],
  "madlock": ['M AE1 D L AA2 K'],
  "madly": ['M AE1 D L IY0'],
  "madman": ['M AE1 D M AE2 N'],
  "madmen": ['M AE1 D M AH0 N'],
  "madness": ['M AE1 D N AH0 S'],
  "madoc": ['M AE1 D AH0 K'],
  "madock": ['M AE1 D AH0 K'],
  "madoff": ['M AE1 D AO2 F'],
  "madog": ['M AE1 D AH0 G'],
  "madole": ['M AH0 D OW1 L'],
  "madonia": ['M AA0 D OW1 N IY0 AH0'],
  "madonna": ['M AH0 D AA1 N AH0'],
  "madonna's": ['M AH0 D AA1 N AH0 Z'],
  "madora": ['M AH0 D AO1 R AH0'],
  "madore": ['M AH0 D AO1 R EY0'],
  "madra": ['M AA1 D R AH0'],
  "madras": ['M AE1 D R AH0 S'],
  "madre": ['M AA1 D R EY2'],
  "madres": ['M AA1 D R EY2 Z'],
  "madrid": ['M AH0 D R IH1 D'],
  "madrids": ['M AH0 D R IH1 D Z'],
  "madrigal": ['M AE1 D R AH0 G AH0 L', 'M AE1 D R IH0 G AH0 L'],
  "madrigals": ['M AE1 D R AH0 G AH0 L Z', 'M AE1 D R IH0 G AH0 L Z'],
  "madril": ['M AE1 D R IH0 L'],
  "madron": ['M AE1 D R AH0 N'],
  "madruga": ['M AE1 D R UW0 G AH0'],
  "madry": ['M AE1 D R IY0'],
  "madsen": ['M AE1 D S AH0 N'],
  "madsen's": ['M AE1 D S AH0 N Z'],
  "madson": ['M AE1 D S AH0 N'],
  "madstones": ['M AE1 D S T OW2 N Z'],
  "mady": ['M EY1 D IY0'],
  "madyun": ['M AE1 D IY0 AH0 N', 'M AE1 D Y AH0 N'],
  "mae": ['M EY1'],
  "mae's": ['M EY1 Z'],
  "maeda": ['M EY0 IY1 D AH0'],
  "maeder": ['M EH1 D ER0'],
  "maekawa": ['M AA2 IH0 K AA1 W AH0'],
  "maelstrom": ['M EY1 L S T R AH0 M'],
  "maenza": ['M AA0 EH1 N Z AH0'],
  "maersk": ['M EH1 R S K'],
  "maertens": ['M EH1 R T AH0 N Z'],
  "maertz": ['M EH1 R T S'],
  "maerz": ['M EH1 R Z'],
  "maes": ['M EY1 Z'],
  "maese": ['M IY1 S'],
  "maestas": ['M EH1 S T AH0 Z'],
  "maestra": ['M AY1 S T R AH0'],
  "maestri": ['M AA0 EH1 S T R IY0'],
  "maestro": ['M AY1 S T R OW0'],
  "maeve": ['M AH0 EH1 V'],
  "maez": ['M AY0 EH1 Z'],
  "maffei": ['M AE1 F AY0'],
  "maffeo": ['M AA1 F IY0 OW0'],
  "maffett": ['M AE1 F IH0 T'],
  "maffia": ['M AE1 F IY0 AH0'],
  "maffucci": ['M AA0 F UW1 CH IY0'],
  "mafia": ['M AA1 F IY0 AH0'],
  "mafia's": ['M AA1 F IY0 AH0 Z'],
  "mafias": ['M AA1 F IY0 AH0 Z'],
  "mafikeng": ['M AA1 F AH0 K EH0 NG'],
  "mag": ['M AE1 G'],
  "magadan": ['M AE1 G AH0 D AE2 N'],
  "magaji": ['M AH0 G AA1 JH IY0'],
  "magallanes": ['M AE1 G AH0 L EY2 N Z'],
  "magallon": ['M AE1 G AH0 L AA0 N'],
  "magan": ['M EY1 G AH0 N'],
  "magana": ['M AA0 G AE1 N AH0'],
  "magar": ['M AE1 G ER0'],
  "magarian": ['M AH0 G EH1 R IY0 AH0 N'],
  "magaw": ['M AE1 G AO0'],
  "magazine": ['M AE1 G AH0 Z IY2 N'],
  "magazine's": ['M AE1 G AH0 Z IY2 N Z'],
  "magaziner": ['M AE2 G AH0 Z IY1 N ER0'],
  "magaziner's": ['M AE2 G AH0 Z IY1 N ER0 Z'],
  "magazines": ['M AE1 G AH0 Z IY2 N Z'],
  "magazines'": ['M AE1 G AH0 Z IY2 N Z'],
  "magby": ['M AE1 G B IY0'],
  "magda": ['M AE1 G D AH0'],
  "magdala": ['M AA0 G D AA1 L AH0'],
  "magdalen": ['M AE1 G D AH0 L AH0 N'],
  "magdalena": ['M AE2 G D AH0 L IY1 N AH0'],
  "magdalene": ['M AE1 G D AH0 L IY2 N'],
  "magdaleno": ['M AA0 G D AA0 L EY1 N OW0'],
  "magee": ['M AH0 G IY1'],
  "magel": ['M AE1 G AH0 L'],
  "magellan": ['M AH0 JH EH1 L AH0 N'],
  "magellan's": ['M AH0 JH EH1 L AH0 N Z'],
  "magellanic": ['M AE2 JH AH0 L AE1 N IH0 K'],
  "magenta": ['M AH0 JH EH1 N T AH0'],
  "mager": ['M AE1 G ER0', 'M EY1 G ER0'],
  "magerman": ['M AE1 G ER0 M AH0 N'],
  "magers": ['M AE1 G ER0 Z'],
  "mages": ['M EY1 JH IH0 Z'],
  "maggard": ['M AE1 G ER0 D'],
  "maggart": ['M AE1 G ER0 T'],
  "maggi": ['M AE1 JH IY2'],
  "maggie": ['M AE1 G IY2'],
  "maggie's": ['M AE1 G IY2 Z'],
  "maggio": ['M AA1 JH IY2 OW0'],
  "maggot": ['M AE1 G AH0 T'],
  "maggots": ['M AE1 G AH0 T S'],
  "maggs": ['M AE1 G Z'],
  "magi": ['M EY1 JH AY0'],
  "magic": ['M AE1 JH IH0 K'],
  "magic's": ['M AE1 JH IH0 K S'],
  "magical": ['M AE1 JH IH0 K AH0 L'],
  "magically": ['M AE1 JH IH0 K AH0 L IY0', 'M AE1 JH IH0 K L IY0'],
  "magician": ['M AH0 JH IH1 SH AH0 N'],
  "magicians": ['M AH0 JH IH1 SH AH0 N Z'],
  "magid": ['M AE1 JH IH0 D'],
  "magie": ['M EY1 JH IY0'],
  "magiera": ['M AA0 JH IH1 R AH0'],
  "magill": ['M AE1 JH AH0 L'],
  "magin": ['M AE1 JH IH0 N'],
  "maginn": ['M AE1 JH IH0 N'],
  "maginnis": ['M AE1 JH IH0 N IH0 S', 'M AH0 G IH1 N IH0 S'],
  "maginot": ['M AE1 JH IH0 N AA0', 'M AE1 JH IH0 N AH0 T'],
  "magistad": ['M AE1 JH IH0 S T AE2 D'],
  "magisterial": ['M AE2 JH IH0 S T IY1 R IY0 AH0 L'],
  "magistrate": ['M AE1 JH AH0 S T R EY2 T', 'M AE1 JH IH0 S T R EY2 T'],
  "magistrates": ['M AE1 JH IH0 S T R EY2 T S'],
  "magistro": ['M AA0 JH IY1 S T R OW0'],
  "maglaj": ['M AA1 G L AY2', 'M AE1 G L AY2'],
  "maglaj's": ['M AA1 G L AY2 Z', 'M AE1 G L AY2 Z'],
  "maglev": ['M AE1 G L EH2 V'],
  "magley": ['M AE1 G L IY0'],
  "magli": ['M AE1 G L IY0'],
  "magliano": ['M AA0 G L IY0 AA1 N OW0'],
  "maglica": ['M AE1 G L IH0 K AH0'],
  "maglio": ['M AE1 G L IY0 OW0'],
  "magliocco": ['M AA0 G L IY0 OW1 K OW0'],
  "magliolo": ['M AE2 G L IY0 OW1 L OW0'],
  "maglione": ['M AA0 G L IY0 OW1 N IY0'],
  "maglis": ['M AE1 G L IY0 Z'],
  "magma": ['M AE1 G M AH0'],
  "magma's": ['M AE1 G M AH0 Z'],
  "magna": ['M AE1 G N AH0'],
  "magna's": ['M AE1 G N AH0 Z'],
  "magnan": ['M AE1 G N AH0 N'],
  "magnani": ['M AA0 G N AA1 N IY0'],
  "magnanimous": ['M AE0 G N AE1 N AH0 M AH0 S'],
  "magnano": ['M AA0 G N AA1 N OW0'],
  "magnant": ['M AE1 G N AH0 N T'],
  "magnate": ['M AE1 G N AH0 T', 'M AE1 G N EY2 T'],
  "magnates": ['M AE1 G N EY2 T S'],
  "magnavox": ['M AE1 G N AH0 V AA0 K S'],
  "magner": ['M AE1 G N ER0'],
  "magnesia": ['M AE0 G N IY1 ZH AH0'],
  "magnesite": ['M AE1 G N AH0 S AY2 T'],
  "magnesium": ['M AE0 G N IY1 Z IY0 AH0 M'],
  "magness": ['M AH0 G N IY1 S'],
  "magnet": ['M AE1 G N AH0 T'],
  "magnet's": ['M AE1 G N AH0 T S'],
  "magnetek": ['M AE1 G N EH0 T EH2 K'],
  "magneti": ['M AE0 G N EH1 T IY0'],
  "magnetic": ['M AE0 G N EH1 T IH0 K'],
  "magnetically": ['M AE0 G N EH1 T IH0 K AH0 L IY0', 'M AE0 G N EH1 T IH0 K L IY0'],
  "magnetics": ['M AE0 G N EH1 T IH0 K S'],
  "magnetism": ['M AE1 G N AH0 T IH2 Z AH0 M'],
  "magnetite": ['M AE1 G N AH0 T AY2 T'],
  "magnetization": ['M AE2 G N AH0 T AH0 Z EY1 SH AH0 N'],
  "magnetized": ['M AE1 G N IH0 T AY2 Z D'],
  "magnetometer": ['M AE2 G N AH0 T AA1 M AH0 T ER0'],
  "magnetometers": ['M AE2 G N AH0 T AA1 M AH0 T ER0 Z'],
  "magnetron": ['M AE1 G N AH0 T R AA2 N'],
  "magnets": ['M AE1 G N AH0 T S'],
  "magnification": ['M AE2 G N AH0 F AH0 K EY1 SH AH0 N'],
  "magnifications": ['M AE2 G N AH0 F AH0 K EY1 SH AH0 N Z'],
  "magnificent": ['M AE0 G N IH1 F AH0 S AH0 N T', 'M AE0 G N IH1 F IH0 S AH0 N T'],
  "magnificently": ['M AE0 G N IH1 F AH0 S AH0 N T L IY0'],
  "magnified": ['M AE1 G N AH0 F AY2 D'],
  "magnifier": ['M AE1 G N AH0 F AY2 ER0'],
  "magnifiers": ['M AE1 G N AH0 F AY2 ER0 Z'],
  "magnifies": ['M AE1 G N AH0 F AY2 Z'],
  "magnify": ['M AE1 G N AH0 F AY2'],
  "magnifying": ['M AE1 G N AH0 F AY2 IH0 NG'],
  "magnin": ['M AE1 G N IH0 N'],
  "magnitogorsk": ['M AE0 G N IH1 T AH0 G AO0 R S K'],
  "magnitude": ['M AE1 G N AH0 T UW2 D'],
  "magnitudes": ['M AE1 G N AH0 T UW2 D Z'],
  "magno": ['M AE1 G N OW0'],
  "magnolia": ['M AE0 G N OW1 L Y AH0'],
  "magnolias": ['M AE0 G N OW1 L Y AH0 Z'],
  "magnone": ['M AA0 G N OW1 N IY0'],
  "magnum": ['M AE1 G N AH0 M'],
  "magnus": ['M AE1 G N AH0 S'],
  "magnuson": ['M AE1 G N AH0 S AH0 N'],
  "magnussen": ['M AE1 G N AH0 S AH0 N'],
  "magnusson": ['M AE1 G N AH0 S AH0 N'],
  "magoon": ['M AH0 G UW1 N'],
  "magouirk": ['M AH0 G W ER1 K'],
  "magowan": ['M AA0 G OW1 W AA0 N'],
  "magpie": ['M AE1 G P AY2'],
  "magpies": ['M AE1 G P AY2 Z'],
  "magrane": ['M AE1 G R EY2 N'],
  "magrath": ['M AH0 G R AE1 TH'],
  "magri": ['M AE1 G R IY0'],
  "magrini": ['M AA0 G R IY1 N IY0'],
  "magro": ['M AE1 G R OW0'],
  "magruder": ['M AH0 G R UW1 D ER0'],
  "mags": ['M AE1 G Z'],
  "magten": ['M AE1 G T EH1 N'],
  "maguire": ['M AH0 G W AY1 R'],
  "magus": ['M EY1 G AH0 S'],
  "magwood": ['M AE1 G W UH2 D'],
  "magyar": ['M AE1 G Y AA2 R'],
  "magyars": ['M AE1 G Y AA2 R Z'],
  "mah": ['M AA1'],
  "mahabharata": ['M AA0 HH AA0 B ER0 AA1 T AH0'],
  "mahady": ['M AH0 HH AA1 D IY0'],
  "mahaffey": ['M AH0 HH AE1 F IY0'],
  "mahaffy": ['M AE1 HH AH0 F IY0'],
  "mahal": ['M AH0 HH AA1 L'],
  "mahala": ['M AE1 HH AH0 L AH0'],
  "mahalah": ['M AE1 HH AH0 L AH0'],
  "mahalia": ['M AH0 HH AE1 L IY0 AH0'],
  "mahamud": ['M AH0 HH AA1 M UH2 D'],
  "mahan": ['M EY1 AH0 N'],
  "mahaney": ['M AH0 HH EY1 N IY0'],
  "mahannah": ['M AH0 HH AE1 N AH0'],
  "mahany": ['M AE1 HH AH0 N IY0'],
  "mahar": ['M AE1 HH ER0'],
  "maharaja": ['M AA2 HH ER0 AA1 ZH AH2'],
  "maharaja's": ['M AA0 HH ER0 AA1 ZH AH2 Z'],
  "maharajahs": ['M AA0 HH ER0 AA1 JH AH0 Z'],
  "maharashtra": ['M AA2 HH ER0 AA1 SH T R AH2'],
  "mahariv": ['M AA2 HH AA1 R IY0 V'],
  "maharive": ['M AA2 HH AH0 R IY1 V'],
  "mahathir": ['M AE1 HH AH0 TH ER0', 'M AH0 HH AE1 TH IH2 R', 'M AH0 HH AA1 TH IH2 R'],
  "mahatma": ['M AH0 HH AA1 T M AH0'],
  "mahavira": ['M AA1 HH AA0 V IY2 R AA0'],
  "mahayana": ['M AA2 HH AH0 Y AA1 N AH0'],
  "mahdi": ['M AA1 D IY0'],
  "mahe": ['M EY1 HH IY0'],
  "mahen": ['M EY1 HH EH2 N', 'M EY1 AH0 N'],
  "maher": ['M AA1 R'],
  "mahesh": ['M AH0 HH EH1 SH'],
  "maheu": ['M AE1 HH Y UW0'],
  "mahfouz": ['M AA0 F UW1 Z'],
  "mahfouz's": ['M AA0 F UW1 Z IH0 Z'],
  "mahila": ['M AH0 HH IH1 L AH0'],
  "mahin": ['M AE1 HH IH0 N'],
  "mahindra": ['M AH0 HH IH1 N D R AH0'],
  "mahl": ['M AA1 L'],
  "mahle": ['M EY1 HH AH0 L'],
  "mahler": ['M AA1 L ER0'],
  "mahler's": ['M AA1 L ER0 Z'],
  "mahlman": ['M AA1 L M AH0 N'],
  "mahlmann": ['M AA1 L M AH0 N'],
  "mahlon": ['M EY1 L AO2 N'],
  "mahlstedt": ['M AA1 L S T IH0 T'],
  "mahlum": ['M AA1 L AH0 M'],
  "mahmood": ['M AE1 M UW0 D', 'M AA0 M UW1 D'],
  "mahmoud": ['M AE1 M UW0 D', 'M AA0 M UW1 D'],
  "mahmud": ['M AE1 M UW0 D', 'M AA0 M UW1 D'],
  "mahn": ['M AE1 N'],
  "mahnke": ['M AE1 NG K'],
  "mahnken": ['M AA1 NG K AH0 N'],
  "mahogany": ['M AH0 HH AA1 G AH0 N IY0'],
  "mahon": ['M EY1 AH0 N'],
  "mahone": ['M AE1 HH AH0 N'],
  "mahoney": ['M AH0 HH OW1 N IY0'],
  "mahony": ['M AE1 HH AH0 N IY0'],
  "mahood": ['M AA1 HH UH0 D'],
  "mahowald": ['M AE1 HH AW0 AO0 L D'],
  "mahr": ['M AA1 R'],
  "mahrt": ['M AA1 R T'],
  "mahtan": ['M AA1 T AA2 N'],
  "mahtar": ['M AA1 T ER0'],
  "mahurin": ['M AE1 HH Y ER0 IH0 N'],
  "mahvi": ['M AA1 V IY0'],
  "mahwah": ['M AA1 W AH0'],
  "mai": ['M AY1'],
  "maia": ['M AY1 AH0'],
  "maid": ['M EY1 D'],
  "maid's": ['M EY1 D Z'],
  "maida": ['M EY1 D AH0'],
  "maidan": ['M AY2 D AA1 N'],
  "maidel": ['M EY1 D AH0 L'],
  "maiden": ['M EY1 D AH0 N'],
  "maidenform": ['M EY1 D AH0 N F AO2 R M'],
  "maidens": ['M EY1 D AH0 N Z'],
  "maidie": ['M EY1 D IY0'],
  "maids": ['M EY1 D Z'],
  "maids'": ['M EY1 D Z'],
  "maiello": ['M AY2 EH1 L OW0'],
  "maier": ['M EY1 ER0'],
  "maiers": ['M EY1 ER0 Z'],
  "maietta": ['M AY0 EH1 T AH0'],
  "mail": ['M EY1 L'],
  "mail's": ['M EY1 L Z'],
  "mailbag": ['M EY1 L B AE2 G'],
  "mailbags": ['M EY1 L B AE2 G Z'],
  "mailbox": ['M EY1 L B AA2 K S'],
  "mailboxes": ['M EY1 L B AA2 K S IH0 Z'],
  "maile": ['M EY1 L'],
  "mailed": ['M EY1 L D'],
  "mailer": ['M EY1 L ER0'],
  "mailer's": ['M EY1 L ER0 Z'],
  "mailers": ['M EY1 L ER0 Z'],
  "mailey": ['M EY1 L IY0'],
  "mailgram": ['M EY1 L G R AE2 M'],
  "mailgrams": ['M EY1 L G R AE2 M Z'],
  "mailhot": ['M AY1 L HH AH0 T'],
  "mailing": ['M EY1 L IH0 NG'],
  "mailings": ['M EY1 L IH0 NG Z'],
  "maillard": ['M EH0 L AA1 R D'],
  "maille": ['M EY1 L'],
  "maillet": ['M EH0 L EH1 T'],
  "mailloux": ['M EH0 L UW1'],
  "mailman": ['M EY1 L M AE2 N'],
  "mailmen": ['M EY1 L M EH2 N'],
  "mailroom": ['M EY1 L R UW2 M'],
  "mailrooms": ['M EY1 L R UW2 M Z'],
  "mails": ['M EY1 L Z'],
  "mailson": ['M EY1 L S AH0 N'],
  "maim": ['M EY1 M'],
  "maimed": ['M EY1 M D'],
  "maiming": ['M EY1 M IH0 NG'],
  "maimone": ['M EY1 M OW2 N'],
  "maims": ['M EY1 M Z'],
  "main": ['M EY1 N'],
  "maina": ['M EY1 N AH0'],
  "mainard": ['M EY1 N ER0 D'],
  "maine": ['M EY1 N'],
  "maine's": ['M EY1 N Z'],
  "mainer": ['M EY1 N ER0'],
  "mainers": ['M EY1 N ER0 Z'],
  "maines": ['M EY1 N Z'],
  "mainframe": ['M EY1 N F R EY2 M'],
  "mainframes": ['M EY1 N F R EY2 M Z'],
  "mainichi": ['M AA2 IH0 N IY1 CH IY0'],
  "mainland": ['M EY1 N L AE2 N D', 'M EY1 N L AH0 N D'],
  "mainlander": ['M EY1 N L AE2 N D ER0'],
  "mainlanders": ['M EY1 N L AE2 N D ER0 Z'],
  "mainline": ['M EY1 N L AY2 N'],
  "mainly": ['M EY1 N L IY0'],
  "maino": ['M EY1 N OW0'],
  "mainor": ['M EY1 N ER0'],
  "mainord": ['M EH0 N AO1 R D'],
  "mains": ['M EY1 N Z'],
  "mainstay": ['M EY1 N S T EY2'],
  "mainstays": ['M EY1 N S T EY2 Z'],
  "mainstream": ['M EY1 N S T R IY2 M'],
  "mainstreaming": ['M EY1 N S T R IY2 M IH0 NG'],
  "mainstreet": ['M EY1 N S T R IY2 T'],
  "maintain": ['M EY0 N T EY1 N'],
  "maintained": ['M EY0 N T EY1 N D'],
  "maintaining": ['M EY0 N T EY1 N IH0 NG'],
  "maintains": ['M EY0 N T EY1 N Z'],
  "maintenance": ['M EY1 N T AH0 N AH0 N S', 'M EY1 N T N AH0 N S'],
  "mainville": ['M EY1 N V IH2 L'],
  "mainwaring": ['M EY1 N W EH2 R IH0 NG'],
  "mainz": ['M EY1 N Z'],
  "maio": ['M EY1 OW0'],
  "maiolo": ['M AY1 OW0 L OW0'],
  "maione": ['M AY1 OW0 N IY0'],
  "maiorana": ['M AY0 AO0 R AE1 N AH0'],
  "maiorano": ['M AY1 AO0 R AA0 N OW0'],
  "maiorino": ['M AY0 AO0 R IY1 N OW0'],
  "mair": ['M EH1 R'],
  "maire": ['M EH1 R'],
  "mairead": ['M EY1 R EH0'],
  "mairs": ['M EH1 R Z'],
  "mais": ['M EY1 S'],
  "maisano": ['M AH0 S AA1 N OW0'],
  "maisch": ['M AY1 SH'],
  "maisel": ['M AY1 S AH0 L'],
  "maish": ['M AA1 IH0 SH'],
  "maisie": ['M EY1 Z IY0'],
  "maison": ['M EY1 Z AH0 N'],
  "maisonet": ['M EH1 S AH0 N IH0 T'],
  "maiti": ['M EY1 T IY0'],
  "maitland": ['M EY1 T L AH0 N D'],
  "maitre": ['M EY1 T R AH0'],
  "maize": ['M EY1 Z'],
  "maize's": ['M EY1 Z AH0 Z'],
  "maiziere": ['M EY1 Z IH2 R'],
  "maj": ['M AE1 JH'],
  "majcher": ['M AE1 JH K ER0'],
  "majchrzak": ['M AY1 R Z AH0 K'],
  "majer": ['M EY1 JH ER0'],
  "majerus": ['M AE1 JH ER0 IH0 S'],
  "majeske": ['M AY0 EH1 S K IY0'],
  "majeski": ['M AY0 EH1 S K IY0'],
  "majesta": ['M AA0 Y EY1 S T AH0'],
  "majestic": ['M AH0 JH EH1 S T IH0 K'],
  "majestically": ['M AH0 JH EH1 S T IH0 K AH0 L IY0', 'M AH0 JH EH1 S T IH0 K L IY0'],
  "majesty": ['M AE1 JH AH0 S T IY0'],
  "majesty's": ['M AE1 JH AH0 S T IY0 Z'],
  "majette": ['M AH0 ZH EH1 T'],
  "majeure": ['M AH0 JH UW1 R'],
  "majewski": ['M AY0 EH1 F S K IY0'],
  "majid": ['M AH0 JH IH1 D'],
  "majik": ['M AE1 JH IH0 K'],
  "majka": ['M AY1 K AH0'],
  "majkowski": ['M AY0 K AO1 F S K IY0'],
  "majnoon": ['M AE2 JH N UW1 N'],
  "majolica": ['M AH0 JH AO1 L AH0 K AH0'],
  "major": ['M EY1 JH ER0'],
  "major's": ['M EY1 JH ER0 Z'],
  "majorca": ['M AY0 AO1 R K AH0'],
  "majordomo": ['M EY2 JH ER0 D OW1 M OW0'],
  "majored": ['M EY1 JH ER0 D'],
  "majoring": ['M EY1 JH ER0 IH0 NG'],
  "majoritarian": ['M AE2 JH ER0 IH0 T EH1 R IY0 AH0 N'],
  "majorities": ['M AH0 JH AO1 R IH0 T IY0 Z'],
  "majority": ['M AH0 JH AO1 R AH0 T IY0'],
  "majority's": ['M AH0 JH AO1 R AH0 T IY0 Z'],
  "majoros": ['M AA0 Y AO1 R OW0 Z'],
  "majors": ['M EY1 JH ER0 Z'],
  "majors'": ['M EY1 JH ER0 Z'],
  "majrooh": ['M AE1 JH R UW0'],
  "majure": ['M AA0 Y UH1 R EY0'],
  "mak": ['M AE1 K'],
  "mak-kwan": ['M AE1 K K W AA1 N'],
  "makar": ['M AE1 K ER0'],
  "makara": ['M AA0 K AA1 R AH0'],
  "makarewicz": ['M AH0 K AA1 R IH0 V IH0 CH'],
  "make": ['M EY1 K'],
  "make-do": ['M EY1 K D UW2'],
  "make-over": ['M EY1 K OW2 V ER0'],
  "make-up": ['M EY1 K AH2 P'],
  "makefield": ['M EY1 K F IY0 L D'],
  "makegood": ['M EY1 K G UH2 D'],
  "makegoods": ['M EY1 K G UH2 D Z'],
  "makela": ['M AE1 K IH0 L AH0'],
  "makeover": ['M AE1 K OW2 V ER0'],
  "makeovers": ['M AE1 K OW2 V ER0 Z'],
  "makepeace": ['M AE1 K P IY0 S'],
  "maker": ['M EY1 K ER0'],
  "maker's": ['M EY1 K ER0 Z'],
  "makers": ['M EY1 K ER0 Z'],
  "makers'": ['M EY1 K ER0 Z'],
  "makes": ['M EY1 K S'],
  "makeshift": ['M EY1 K SH IH2 F T'],
  "makeup": ['M EY1 K AH2 P'],
  "makhoul": ['M AH0 K UW1 L'],
  "maki": ['M AA1 K IY0'],
  "makin": ['M AE1 K IH0 N'],
  "makin'": ['M EY1 K IH0 N'],
  "makinen": ['M AE1 K IH0 N AH0 N'],
  "making": ['M EY1 K IH0 NG'],
  "makings": ['M EY1 K IH0 NG Z'],
  "makinson": ['M AE1 K IH0 N S AH0 N'],
  "makita": ['M AH0 K IY1 T AH0'],
  "mako": ['M AA1 K OW0'],
  "makos": ['M EY1 K OW0 Z'],
  "makoto": ['M AH0 K OW1 T OW0'],
  "makowski": ['M AH0 K AO1 F S K IY0'],
  "makowsky": ['M AH0 K AW1 S K IY0'],
  "makris": ['M AE1 K R IH0 S'],
  "makro": ['M AE1 K R OW0'],
  "maksoud": ['M AE0 K S UW1 D'],
  "maksymuk": ['M AE1 K S IY0 M UH2 K'],
  "maktoum": ['M AE0 K T UW1 M'],
  "maktoums": ['M AE0 K T UW1 M Z'],
  "makuch": ['M AE1 K AH0 K'],
  "mal": ['M AE1 L'],
  "mal's": ['M AE1 L Z'],
  "mala": ['M AA1 L AH0'],
  "malabre": ['M AE1 L AH0 B R AH0'],
  "malacanang": ['M AH0 L AE1 K AH0 N AE0 NG'],
  "malacca": ['M AH0 L AE1 K AH0'],
  "malachowski": ['M AH0 L AH0 HH AO1 F S K IY0'],
  "maladich": ['M AE1 L AH0 D IH0 K', 'M AE1 L AH0 D IH0 CH'],
  "maladies": ['M AE1 L AH0 D IY0 Z'],
  "maladjusted": ['M AE2 L AH0 JH AH1 S T IH0 D'],
  "maladroit": ['M AE2 L AH0 D R OY1 T'],
  "malady": ['M AE1 L AH0 D IY0'],
  "malaga": ['M AE1 L AH0 G AH0'],
  "malagasy": ['M AE2 L AH0 G AE1 S IY0'],
  "malahide": ['M AE2 L AH0 HH AY1 D'],
  "malaise": ['M AE0 L EY1 Z'],
  "malak": ['M AE1 L AH0 K'],
  "malamud": ['M AE1 L AH0 M AH0 D'],
  "malamugi": ['M AE1 L AH0 M Y UW2 JH IY0'],
  "malamute": ['M AE1 L AH0 M Y UW2 T'],
  "malan": ['M EY1 L AH0 N'],
  "maland": ['M AE1 L AH0 N D'],
  "malaney": ['M AH0 L EY1 N IY0'],
  "malanga": ['M AA0 L AA1 NG G AH0'],
  "malanowski": ['M AH0 L AH0 N AO1 F S K IY0'],
  "malaprop": ['M AE1 L AH0 P R AA2 P'],
  "malara": ['M AA0 L AA1 R AH0'],
  "malaria": ['M AH0 L EH1 R IY0 AH0'],
  "malarial": ['M AH0 L EH1 R IY0 AH0 L'],
  "malarkey": ['M AH0 L AA1 R K IY0'],
  "malary": ['M AE1 L AH0 R IY0'],
  "malaspina": ['M AA0 L AA0 S P IY1 N AH0'],
  "malatesta": ['M AA0 L AA0 T EH1 S T AH0'],
  "malathion": ['M AH0 L AE1 TH IY0 AO0 N', 'M AH0 L AE1 TH IY0 AH0 N'],
  "malave": ['M AA0 L AA1 V IY0'],
  "malawi": ['M AH0 L AA1 W IY0'],
  "malawi's": ['M AH0 L AA1 W IY0 Z'],
  "malay": ['M EY1 L EY0'],
  "malayan": ['M AH0 L EY1 AH0 N'],
  "malays": ['M AH0 L EY1 Z'],
  "malaysia": ['M AH0 L EY1 ZH AH0'],
  "malaysia's": ['M AH0 L EY1 ZH AH0 Z'],
  "malaysian": ['M AH0 L EY1 ZH AH0 N'],
  "malaysians": ['M AH0 L EY1 ZH AH0 N Z'],
  "malbon": ['M AE1 L B AH0 N'],
  "malbrough": ['M AE1 L B R AW0'],
  "malburg": ['M AE1 L B ER0 G'],
  "malchow": ['M AA1 L HH AW0'],
  "malcolm": ['M AE1 L K AH0 M'],
  "malcolm's": ['M AE1 L K AH0 M Z'],
  "malcolmson": ['M AE1 L K AH0 M S AH0 N'],
  "malcom": ['M AE1 L K AH0 M'],
  "malcomb": ['M AE1 L K AH0 M'],
  "malcomson": ['M AE1 L K AH0 M S AH0 N'],
  "malcontent": ['M AE2 L K AH0 N T EH1 N T'],
  "malcontents": ['M AE2 L K AH0 N T EH1 N T S'],
  "malczewski": ['M AH0 L CH EH1 F S K IY0'],
  "malden": ['M AO1 L D AH0 N'],
  "maldives": ['M AO1 L D AY2 V Z'],
  "maldonado": ['M AE0 L D AH0 N AA1 D OW0'],
  "maldutis": ['M AA0 L D UW1 T IH0 S'],
  "male": ['M EY1 L'],
  "male's": ['M EY1 L Z'],
  "malec": ['M AA1 L IH0 K'],
  "malecha": ['M AH0 L EH1 CH AH0'],
  "malecki": ['M AH0 L EH1 K IY0'],
  "malefactor": ['M AE1 L AH0 F AE2 K T ER0', 'M AE1 L F AE2 K T ER0'],
  "malefactors": ['M AE1 L AH0 F AE2 K T ER0 Z', 'M AE1 L F AE2 K T ER0 Z'],
  "malek": ['M AA1 L EH0 K'],
  "malena": ['M AE1 L IH0 N AH0'],
  "malenfant": ['M AA0 L EY1 N F AH0 N T'],
  "maler": ['M EY1 L ER0'],
  "malerba": ['M AA0 L EH1 R B AH0'],
  "males": ['M EY1 L Z'],
  "maleski": ['M AH0 L EH1 S K IY0'],
  "malesky": ['M AH0 L EH1 S K IY0'],
  "malevolent": ['M AH0 L EH1 V AH0 L AH0 N T'],
  "maley": ['M EY1 L IY0'],
  "malfeasance": ['M AE2 L F IY1 Z AH0 N S'],
  "malfitano": ['M AE2 L F IH0 T AA1 N OW0'],
  "malformation": ['M AE2 L F AO0 R M EY1 SH AH0 N'],
  "malformations": ['M AE2 L F AO0 R M EY1 SH AH0 N Z'],
  "malformed": ['M AE2 L F AO1 R M D'],
  "malfunction": ['M AE0 L F AH1 NG K SH AH0 N'],
  "malfunctioned": ['M AE0 L F AH1 NG K SH AH0 N D'],
  "malfunctioning": ['M AE0 L F AH1 NG K SH AH0 N IH0 NG'],
  "malfunctions": ['M AE0 L F AH1 NG K SH AH0 N Z'],
  "malhotra": ['M AE2 L HH AA1 T R AH0'],
  "mali": ['M AA1 L IY0'],
  "malia": ['M AA1 L IY0 AH0'],
  "malibu": ['M AE1 L AH0 B UW0'],
  "malice": ['M AE1 L AH0 S', 'M AE1 L IH0 S'],
  "malicious": ['M AH0 L IH1 SH AH0 S'],
  "maliciously": ['M AH0 L IH1 SH IH0 S L IY0'],
  "malick": ['M AE1 L IH0 K'],
  "malicki": ['M AH0 L IH1 K IY0'],
  "malicoat": ['M AE1 L IH0 K OW2 T'],
  "malign": ['M AH0 L AY1 N'],
  "malignancies": ['M AH0 L IH1 G N AH0 N S IY0 Z'],
  "malignancy": ['M AH0 L IH1 G N AH0 N S IY0'],
  "malignant": ['M AH0 L IH1 G N AH0 N T'],
  "maligned": ['M AH0 L AY1 N D'],
  "malik": ['M AE1 L IH0 K'],
  "malin": ['M AE1 L IH0 N', 'M EY1 L IH0 N'],
  "malina": ['M AA0 L IY1 N AH0'],
  "malinak": ['M AE1 L IH0 N AE2 K'],
  "malinda": ['M AA0 L IY1 N D AH0'],
  "malinde": ['M AE1 L IH0 N D'],
  "maline": ['M AA0 L IY1 N IY0'],
  "malino": ['M AH0 L IY1 N OW0'],
  "malinoski": ['M AH0 L IH0 N AW1 S K IY0'],
  "malinowski": ['M AH0 L IH0 N AO1 F S K IY0'],
  "malinski": ['M AH0 L IH1 N S K IY0'],
  "malis": ['M AA1 L IY0 Z'],
  "maliszewski": ['M AH0 L IH0 SH EH1 F S K IY0'],
  "malizia": ['M AH0 L IH1 Z IY0 AH0'],
  "malkiel": ['M AO1 L K IY0 AH0 L'],
  "malkiewicz": ['M AA1 L K AH0 V IH0 CH'],
  "malkin": ['M AE1 L K IH0 N'],
  "malkovich": ['M AO1 L K AH0 V IH0 CH'],
  "malkowski": ['M AH0 L K AO1 F S K IY0'],
  "mall": ['M AO1 L'],
  "mall's": ['M AO1 L Z'],
  "mallard": ['M AE1 L ER0 D'],
  "mallards": ['M AE1 L ER0 D Z'],
  "malle": ['M AE1 L'],
  "malleability": ['M AE2 L IY0 AH0 B IH1 L AH0 T IY0'],
  "malleable": ['M AE1 L IY0 AH0 B AH0 L'],
  "mallek": ['M AE1 L IH0 K'],
  "mallen": ['M AO1 L AH0 N'],
  "maller": ['M AO1 L ER0'],
  "mallery": ['M AE1 L ER0 IY0'],
  "mallet": ['M AE1 L IH0 T'],
  "malleton": ['M AE1 L AH0 T AH0 N'],
  "malleton's": ['M AE1 L AH0 T AH0 N Z'],
  "mallett": ['M AE1 L IH0 T'],
  "mallette": ['M AH0 L EH1 T'],
  "malley": ['M AE1 L IY0'],
  "mallia": ['M AA1 L IY0 AH0'],
  "mallick": ['M AE1 L IH0 K'],
  "mallicoat": ['M AE1 L IH0 K OW0 T'],
  "mallie": ['M AO1 L IY0'],
  "mallightco": ['M AE2 L AY1 T K OW0'],
  "mallin": ['M AE1 L IH0 N'],
  "mallinckrodt": ['M AE1 L IH0 NG K R AA2 T'],
  "mallinger": ['M AO1 L IH0 NG ER0'],
  "mallinson": ['M AE1 L IH0 N S AH0 N'],
  "mallis": ['M AE1 L IH0 S'],
  "mallison": ['M AE1 L IH0 S AH0 N'],
  "mallo": ['M AA1 L OW0'],
  "malloch": ['M AE1 L AH0 K'],
  "mallon": ['M AE1 L AH0 N'],
  "mallonee": ['M AE0 L AH0 N IY1'],
  "mallory": ['M AE1 L ER0 IY0'],
  "malloseismic": ['M AA1 L OW0 S AY1 Z M IH0 K'],
  "mallow": ['M AE1 L OW0'],
  "mallows": ['M AE1 L OW0 Z'],
  "malloy": ['M AH0 L OY1'],
  "mallozzi": ['M AE2 L AA1 Z IY0'],
  "mallrat": ['M AO1 L R AE0 T'],
  "mallrats": ['M AO1 L R AE0 T S'],
  "malls": ['M AO1 L Z'],
  "mally": ['M AE1 L IY0'],
  "malm": ['M AA1 M'],
  "malmberg": ['M AA1 L M B ER0 G'],
  "malmgren": ['M AE1 L M G R EH0 N'],
  "malmquist": ['M AE1 L M K W IH0 S T'],
  "malmstrom": ['M AE1 L M S T R AH0 M'],
  "malnar": ['M AE1 L N ER0'],
  "malnourish": ['M AE0 L N ER1 IH0 SH'],
  "malnourished": ['M AE0 L N ER1 IH0 SH T'],
  "malnutrition": ['M AE2 L N UW0 T R IH1 SH AH0 N'],
  "malo": ['M AA1 L OW0'],
  "malon": ['M AE1 L AH0 N'],
  "malone": ['M AH0 L OW1 N'],
  "malone's": ['M AH0 L OW1 N Z'],
  "maloney": ['M AH0 L OW1 N IY0'],
  "maloof": ['M AH0 L UW1 F'],
  "malosovich": ['M AH0 L AO1 S AH0 V IH0 CH'],
  "malott": ['M AH0 L AA1 T'],
  "malouf": ['M AE1 L OW0 F'],
  "maloy": ['M AE1 L OY0'],
  "malpass": ['M AE1 L P AH0 S'],
  "malpensa": ['M AE2 L P EH1 N S AA0'],
  "malphrus": ['M AE1 L F R AH0 S'],
  "malpractice": ['M AE0 L P R AE1 K T AH0 S', 'M AE0 L P R AE1 K T IH0 S'],
  "malrite": ['M AE1 L R AY2 T'],
  "malrite's": ['M AE1 L R AY2 T S'],
  "malsom": ['M AE1 L S AH0 M'],
  "malson": ['M AE1 L S AH0 N'],
  "malstrom": ['M AE1 L S T R AH0 M'],
  "malt": ['M AO1 L T'],
  "malta": ['M AO1 L T AH0'],
  "maltais": ['M AH0 L T EY1'],
  "maltase": ['M AO1 L T EY2 S'],
  "maltbie": ['M AE1 L T B IY0'],
  "maltby": ['M AE1 L T B IY0'],
  "malted": ['M AO1 L T AH0 D'],
  "malter": ['M AO1 L T ER0'],
  "maltese": ['M AO0 L T IY1 Z'],
  "malting": ['M AO1 L T IH0 NG'],
  "maltose": ['M AO1 L T OW0 S'],
  "maltreated": ['M AE0 L T R IY1 T IH0 D'],
  "maltreatment": ['M AE0 L T R IY1 T M AH0 N T'],
  "malts": ['M AO1 L T S'],
  "maltz": ['M AE1 L T S'],
  "malueg": ['M AE1 L UH0 G'],
  "maluken": ['M AE2 L UW1 K IH0 N'],
  "maluso": ['M AH0 L UW1 S OW0'],
  "malva": ['M AA1 L V AH0'],
  "malval": ['M AA1 L V AA0 L'],
  "malveaux": ['M AE0 L V OW1'],
  "malvern": ['M AE1 L V ER0 N'],
  "malvie": ['M AO1 L V IY0'],
  "malvin": ['M AE1 L V IH0 N'],
  "malvina": ['M AA0 L V IY1 N AH0'],
  "malvinas": ['M AO0 L V IY1 N AH0 S'],
  "malware": ['M AE1 L W EH2 R'],
  "maly": ['M EY1 L IY0'],
  "malzahn": ['M AE1 L Z AH0 N'],
  "mam": ['M AA1 M', 'EH1 M EY1 EH1 M'],
  "mama": ['M AA1 M AH0'],
  "mama's": ['M AA1 M AH0 Z'],
  "mamaroneck": ['M AH0 M EH1 R AH0 N EH0 K'],
  "mamas": ['M AA1 M AH0 Z'],
  "mamba": ['M AA1 M B AH0'],
  "mambas": ['M AA1 M B AH0 Z'],
  "mambo": ['M AA1 M B OW0'],
  "mame": ['M EY1 M'],
  "mameluke": ['M AE1 M AH0 L UW2 K'],
  "mamet": ['M AE1 M AH0 T'],
  "mamet's": ['M AE1 M AH0 T S'],
  "mamie": ['M EY1 M IY0'],
  "mamis": ['M AE1 M IH0 S'],
  "mamma": ['M AA1 M AH0'],
  "mammal": ['M AE1 M AH0 L'],
  "mammalian": ['M AH0 M EY1 L IY0 AH0 N', 'M AH0 M EY1 L Y AH0 N'],
  "mammallike": ['M AE1 M AH0 L L AY2 K'],
  "mammals": ['M AE1 M AH0 L Z'],
  "mammary": ['M AE1 M ER0 IY0'],
  "mammen": ['M AE1 M AH0 N'],
  "mammogram": ['M AE1 M OW0 G R AE2 M'],
  "mammograms": ['M AE1 M OW0 G R AE2 M Z'],
  "mammography": ['M AH0 M AA1 G R AH0 F IY0'],
  "mammoth": ['M AE1 M AH0 TH'],
  "mammoths": ['M AE1 M AH0 TH S'],
  "mamone": ['M AH0 M OW1 N'],
  "mamsted": ['M AE1 M S T EH0 D'],
  "mamula": ['M AE1 M Y UW0 L AH0'],
  "man": ['M AE1 N'],
  "man's": ['M AE1 N Z'],
  "man-made": ['M AE1 N M EY2 D'],
  "mana": ['M AA1 N AH0'],
  "manac": ['M AE1 N AE0 K'],
  "manafort": ['M AE1 N AH0 F AO0 R T'],
  "manage": ['M AE1 N AH0 JH', 'M AE1 N IH0 JH'],
  "manageable": ['M AE1 N IH0 JH AH0 B AH0 L'],
  "managed": ['M AE1 N AH0 JH D', 'M AE1 N IH0 JH D'],
  "management": ['M AE1 N AH0 JH M AH0 N T', 'M AE1 N IH0 JH M AH0 N T'],
  "management's": ['M AE1 N IH0 JH M AH0 N T S'],
  "managements": ['M AE1 N IH0 JH M AH0 N T S'],
  "managements'": ['M AE1 N IH0 JH M AH0 N T S'],
  "manager": ['M AE1 N AH0 JH ER0', 'M AE1 N IH0 JH ER0'],
  "manager's": ['M AE1 N IH0 JH ER0 Z'],
  "managerial": ['M AE2 N IH0 JH IH1 R IY0 AH0 L'],
  "managers": ['M AE1 N AH0 JH ER0 Z', 'M AE1 N IH0 JH ER0 Z'],
  "managers'": ['M AE1 N AH0 JH ER0 Z'],
  "manages": ['M AE1 N IH0 JH IH0 Z'],
  "managing": ['M AE1 N AH0 JH IH0 NG'],
  "managua": ['M AH0 N AA1 G W AH0'],
  "managua's": ['M AH0 N AA1 G W AH0 Z'],
  "manahan": ['M AE1 N AH0 HH AE0 N'],
  "manak": ['M AE1 N AH0 K'],
  "manalo": ['M AA0 N AA1 L OW0'],
  "manama": ['M AE1 N AH0 M AH0'],
  "manard": ['M AE1 N ER0 D'],
  "manas": ['M AA1 N AH0 Z'],
  "manasco": ['M AA0 N AA1 S K OW0'],
  "manasion": ['M AE2 N AH0 SH AH0 N'],
  "manasion's": ['M AE2 N AH0 SH AH0 N Z'],
  "manassas": ['M AH0 N AA1 S AH0 S'],
  "manatee": ['M AE1 N AH0 T IY2'],
  "manatees": ['M AE1 N AH0 T IY2 Z'],
  "manatt": ['M AE1 N AH0 T'],
  "manbeck": ['M AE1 N B EH2 K'],
  "mancala": ['M AA0 N K AA1 L AH0'],
  "mance": ['M AE1 N S'],
  "mancebo": ['M AA0 N CH EH1 B OW0'],
  "mancera": ['M AE0 N S EH1 R AH0'],
  "mancha": ['M AA1 N K AH0'],
  "manchester": ['M AE1 N CH EH2 S T ER0'],
  "manchu": ['M AE1 N CH UW0'],
  "manchuria": ['M AE0 N CH UH1 R IY0 AH0'],
  "mancil": ['M AE1 N S IH0 L'],
  "mancilla": ['M AE2 N S IH1 L AH0'],
  "mancillas": ['M AH0 N S IH1 L AH0 Z'],
  "mancinelli": ['M AA0 N CH IY0 N EH1 L IY0'],
  "mancini": ['M AA0 N CH IY1 N IY0'],
  "mancino": ['M AA0 N CH IY1 N OW0'],
  "manco": ['M AE1 NG K OW0'],
  "mancusi": ['M AA0 N K UW1 S IY0'],
  "mancuso": ['M AE2 NG K Y UW1 S OW0'],
  "manda": ['M AE1 N D AH0'],
  "mandala": ['M AA1 D AH0 L AH0'],
  "mandalay": ['M AE1 N D AH0 L EY2'],
  "mandalit": ['M AE1 N D AH0 L IH2 T'],
  "mandalite": ['M AE1 N D AH0 L AY2 T'],
  "mandamus": ['M AE0 N D EY1 M AH0 S'],
  "mandarin": ['M AE1 N D ER0 AH0 N'],
  "mandarine": ['M AE0 N D ER0 IY1 N'],
  "mandarino": ['M AA0 N D AA0 R IY1 N OW0'],
  "mandarins": ['M AE1 N D ER0 AH0 N Z'],
  "mandate": ['M AE1 N D EY2 T'],
  "mandated": ['M AE1 N D EY2 T IH0 D'],
  "mandates": ['M AE1 N D EY2 T S'],
  "mandating": ['M AE1 N D EY2 T IH0 NG'],
  "mandato": ['M AA0 N D AA1 T OW0'],
  "mandatory": ['M AE1 N D AH0 T AO2 R IY0'],
  "mandel": ['M AE1 N D AH0 L'],
  "mandela": ['M AE2 N D EH1 L AH0'],
  "mandela's": ['M AE2 N D EH1 L AH0 Z'],
  "mandelbaum": ['M AE1 N D AH0 L B AW2 M'],
  "mandell": ['M AE1 N D AH0 L'],
  "mandella": ['M AE2 N D EH1 L AH0'],
  "mander": ['M AE1 N D ER0'],
  "manders": ['M AE1 N D ER0 Z'],
  "manderscheid": ['M AE1 N D ER0 SH AY2 D'],
  "manderson": ['M AE1 N D ER0 S AH0 N'],
  "mandeville": ['M AE1 N D AH0 V IH2 L'],
  "mandi": ['M AE1 N D IY0'],
  "mandible": ['M AE1 N D AH0 B AH0 L', 'M AE1 N D IH0 B AH0 L'],
  "mandibular": ['M AE2 N D IH1 B Y UW0 L ER0'],
  "mandich": ['M AE1 N D IH0 K'],
  "mandie": ['M AE1 N D IY0'],
  "mandigo": ['M AA0 N D IY1 G OW0'],
  "mandl": ['M AE1 N D AH0 L'],
  "mandle": ['M AE1 N D AH0 L'],
  "mandler": ['M AE1 N D L ER0'],
  "mandolin": ['M AE1 N D AH0 L IH2 N'],
  "mandracchia": ['M AE2 N D R AE1 K IY0 AH0'],
  "mandrake": ['M AE1 N D R EY2 K'],
  "mandrell": ['M AE1 N D R AH0 L'],
  "mandresh": ['M AE0 N D R EH1 SH'],
  "mandrill": ['M AE1 N D R IH0 L'],
  "mandt": ['M AE1 N T'],
  "mandujano": ['M AA0 N D UW0 Y AA1 N OW0'],
  "mandy": ['M AE1 N D IY0'],
  "mane": ['M EY1 N'],
  "maned": ['M EY1 N D'],
  "manella": ['M AH0 N EH1 L AH0'],
  "maner": ['M EY1 N ER0'],
  "maners": ['M EY1 N ER0 Z'],
  "manes": ['M EY1 N Z'],
  "maness": ['M AA1 N IH0 S'],
  "manet": ['M AE0 N EY1', 'M AA0 N EY1'],
  "manette": ['M AH0 N EH1 T'],
  "maneuver": ['M AH0 N UW1 V ER0'],
  "maneuverability": ['M AH0 N UW2 V ER0 AH0 B IH1 L IH0 T IY0', 'M AH0 N UW2 V R AH0 B IH1 L IH0 T IY0'],
  "maneuverable": ['M AH0 N UW1 V ER0 AH0 B AH0 L'],
  "maneuvered": ['M AH0 N UW1 V ER0 D'],
  "maneuvering": ['M AH0 N UW1 V ER0 IH0 NG'],
  "maneuverings": ['M AH0 N UW1 V ER0 IH0 NG Z'],
  "maneuvers": ['M AH0 N UW1 V ER0 Z'],
  "maneval": ['M AA0 N EY0 V AE1 L'],
  "maney": ['M EY1 N IY0'],
  "manford": ['M AE1 N F ER0 D'],
  "manfra": ['M AE1 N F R AH0'],
  "manfre": ['M AE1 N F ER0'],
  "manfred": ['M AE1 N F R IH0 D'],
  "manfredi": ['M AA0 N F R EH1 D IY0'],
  "manfredo": ['M AA0 N F R EY1 D OW0'],
  "manfully": ['M AE1 N F AH0 L IY0'],
  "mang": ['M AE1 NG'],
  "manga": ['M AE1 NG G AH0'],
  "mangan": ['M AE1 NG G AH0 N'],
  "manganaro": ['M AA0 NG G AA0 N AA1 R OW0'],
  "manganello": ['M AA0 NG G AA0 N EH1 L OW0'],
  "manganese": ['M AE1 NG G AH0 N IY2 Z'],
  "manganiello": ['M AA0 NG G AA0 N IY0 EH1 L OW0'],
  "mangano": ['M AA0 NG G AA1 N OW0'],
  "mangas": ['M AE1 NG G AH0 Z'],
  "mange": ['M EY1 N JH'],
  "mangel": ['M EY1 NG G AH0 L'],
  "mangels": ['M EY1 NG G AH0 L Z'],
  "mangement": ['M EY1 N JH M AH0 N T'],
  "mangen": ['M AE1 NG AH0 N'],
  "manger": ['M EY1 N JH ER0'],
  "manges": ['M EY1 N JH IH0 Z'],
  "mangham": ['M AE1 NG G AH0 M'],
  "mangiapane": ['M AE1 N JH IY0 AH0 P EY2 N'],
  "mangiaracina": ['M AA1 N JH ER0 AA0 CH IY2 N AA0'],
  "mangieri": ['M AA0 NG G IH1 R IY0'],
  "mangin": ['M AE1 NG G IH0 N'],
  "mangine": ['M AA0 NG G IY1 N IY0'],
  "mangini": ['M AA0 NG G IY1 N IY0'],
  "mangino": ['M AA0 NG G IY1 N OW0'],
  "mangione": ['M AA0 N JH OW1 N IY0'],
  "manglapus": ['M AE1 NG L AH0 P AH0 S'],
  "mangle": ['M AE1 NG G AH0 L'],
  "mangled": ['M AE1 NG G AH0 L D'],
  "mangles": ['M AE1 NG G AH0 L Z'],
  "mangling": ['M AE1 NG G AH0 L IH0 NG', 'M AE1 NG G L IH0 NG'],
  "mango": ['M AE1 NG G OW0'],
  "mangoes": ['M AE1 NG G OW0 Z'],
  "mangold": ['M AE1 N G OW2 L D'],
  "mangone": ['M AA0 NG G OW1 N IY0'],
  "mangope": ['M AE0 NG G OW1 P EY2'],
  "mangosteen": ['M AE1 NG G OW0 S T IY2 N'],
  "mangosteens": ['M AE1 NG G OW0 S T IY2 N Z'],
  "mangosuthu": ['M AE2 NG G AH0 S AH1 TH UW0'],
  "mangrove": ['M AE1 N G R OW2 V', 'M AE1 NG G R OW2 V'],
  "mangrum": ['M AE1 NG G R AH0 M'],
  "mangual": ['M AE1 N G AH0 L'],
  "mangueira": ['M AA0 NG G EH1 R AH0'],
  "mangum": ['M AE1 NG G AH0 M'],
  "mangus": ['M AE1 NG G IH0 S'],
  "mangy": ['M EY1 N JH IY0'],
  "manhandle": ['M AE1 N HH AE2 N D AH0 L'],
  "manhandled": ['M AE1 N HH AE2 N D AH0 L D'],
  "manhart": ['M AE1 N HH AA2 R T'],
  "manhasset": ['M AE0 N HH AE1 S EH0 T'],
  "manhattan": ['M AE0 N HH AE1 T AH0 N'],
  "manhattan's": ['M AE0 N HH AE1 T AH0 N Z'],
  "manheim": ['M AE1 N HH AY0 M'],
  "manhole": ['M AE1 N HH OW2 L'],
  "manhood": ['M AE1 N HH UH2 D'],
  "manhunt": ['M AE1 N HH AH2 N T'],
  "mani": ['M AA1 N IY0'],
  "mania": ['M EY1 N IY0 AH0'],
  "maniac": ['M EY1 N IY0 AE2 K'],
  "maniacal": ['M AH0 N AY1 AH0 K AH0 L'],
  "maniaci": ['M AA0 N IY0 AA1 CH IY0'],
  "maniacs": ['M EY1 N IY0 AE2 K S'],
  "maniatis": ['M AE1 N IY0 AA1 T IH0 S'],
  "manic": ['M AE1 N IH0 K'],
  "manicure": ['M AE1 N IH0 K Y ER0'],
  "manicured": ['M AE1 N IH0 K Y ER0 D'],
  "manicurist": ['M AE1 N IH0 K Y ER2 IH0 S T'],
  "manier": ['M EH1 N IY0 ER0'],
  "manifest": ['M AE1 N AH0 F EH2 S T'],
  "manifestation": ['M AE2 N AH0 F EH0 S T EY1 SH AH0 N'],
  "manifestations": ['M AE2 N AH0 F EH0 S T EY1 SH AH0 N Z'],
  "manifested": ['M AE1 N AH0 F EH2 S T AH0 D'],
  "manifesting": ['M AE1 N AH0 F EH2 S T IH0 NG'],
  "manifestly": ['M AE1 N AH0 F EH0 S T L IY0'],
  "manifesto": ['M AE2 N AH0 F EH1 S T OW2', 'M AE2 N IH0 F EH1 S T OW2'],
  "manifests": ['M AE1 N AH0 F EH2 S T S'],
  "manifold": ['M AE1 N AH0 F OW2 L D', 'M AE1 N IH0 F OW2 L D'],
  "manigat": ['M AE1 N IH0 G AE0 T'],
  "manigault": ['M AE1 N IH0 G AO0 L T'],
  "manigo": ['M AA0 N IY1 G OW0'],
  "manik": ['M AE1 N IH0 K'],
  "manila": ['M AH0 N IH1 L AH0'],
  "manila's": ['M AH0 N IH1 L AH0 Z'],
  "manilla": ['M AH0 N IH1 L AH0'],
  "manilow": ['M AE1 N IH0 L OW0'],
  "manioc": ['M AE1 N IY0 AA2 K'],
  "manion": ['M AA0 N Y AO1 N'],
  "maniples": ['M AE1 N AH0 P AH0 L Z', 'M AE1 N IH0 P AH0 L Z'],
  "manipulate": ['M AH0 N IH1 P Y AH0 L EY2 T'],
  "manipulated": ['M AH0 N IH1 P Y AH0 L EY2 T IH0 D'],
  "manipulates": ['M AH0 N IH1 P Y AH0 L EY2 T S'],
  "manipulating": ['M AH0 N IH1 P Y AH0 L EY2 T IH0 NG'],
  "manipulation": ['M AH0 N IH2 P Y AH0 L EY1 SH AH0 N'],
  "manipulations": ['M AH0 N IH2 P Y AH0 L EY1 SH AH0 N Z'],
  "manipulative": ['M AH0 N IH1 P Y AH0 L EY2 T IH0 V'],
  "manipulator": ['M AH0 N IH1 P Y AH0 L EY2 T ER0'],
  "manipulators": ['M AH0 N IH1 P Y AH0 L EY2 T ER0 Z'],
  "manis": ['M AE1 N IH0 S'],
  "maniscalco": ['M AA0 N IY0 S K AA1 L K OW0'],
  "manischewitz": ['M AE2 N IH0 SH EH1 V IH0 T S'],
  "manish": ['M AE1 N IH0 SH'],
  "manitoba": ['M AE2 N IH0 T OW1 B AH0'],
  "manitowoc": ['M AE1 N IH0 T AH0 W AA0 K'],
  "manjaca": ['M AA0 N JH AA1 K AH0'],
  "manjarrez": ['M AA0 N Y AA1 R EH0 Z'],
  "mank": ['M AE1 NG K'],
  "manka": ['M AE1 NG K AH0'],
  "mankato": ['M AE0 N K AA1 T OW0'],
  "manke": ['M AE1 NG K'],
  "manker": ['M AE1 NG K ER0'],
  "mankey": ['M AE1 N K IY2'],
  "mankiewicz": ['M AE1 NG K IH0 W IH0 T S'],
  "mankiller": ['M AE1 N K IH2 L ER0'],
  "mankin": ['M AE1 NG K IH0 N'],
  "mankind": ['M AE1 N K AY1 N D'],
  "mankind's": ['M AE1 N K AY1 N D Z'],
  "mankinds": ['M AE1 N K AY1 N D Z'],
  "mankins": ['M AE1 NG K IH0 N Z'],
  "manko": ['M AE1 NG K OW0'],
  "mankowski": ['M AH0 NG K AO1 F S K IY0'],
  "manley": ['M AE1 N L IY0'],
  "manlove": ['M AE1 N L AH2 V'],
  "manly": ['M AE1 N L IY0'],
  "manmade": ['M AE1 N M EY1 D'],
  "mann": ['M AE1 N'],
  "mann's": ['M AE1 N Z'],
  "manna": ['M AE1 N AH0'],
  "mannarino": ['M AE1 N ER0 IY0 N OW0'],
  "manne": ['M AE1 N'],
  "manned": ['M AE1 N D'],
  "mannella": ['M AA0 N EH1 L AH0'],
  "mannen": ['M AE1 N AH0 N'],
  "mannequin": ['M AE1 N AH0 K IH0 N'],
  "mannequins": ['M AE1 N AH0 K IH0 N Z'],
  "manner": ['M AE1 N ER0'],
  "mannered": ['M AE1 N ER0 D'],
  "mannering": ['M AE1 N ER0 IH0 NG'],
  "mannerism": ['M AE1 N ER0 IH2 Z AH0 M'],
  "mannerisms": ['M AE1 N ER0 IH2 Z AH0 M Z'],
  "mannerist": ['M AE1 N ER0 AH0 S T', 'M AE1 N ER0 IH0 S T'],
  "manners": ['M AE1 N ER0 Z'],
  "mannes": ['M AE1 N Z'],
  "mannesmann": ['M AE1 N AH0 S M AH0 N'],
  "manney": ['M AE1 N IY0'],
  "mannheim": ['M AE1 N HH AY0 M'],
  "manni": ['M AE1 N IY0'],
  "mannie": ['M AE1 N IY0'],
  "mannina": ['M AE1 N IH0 N AH0'],
  "manninen": ['M AE1 N IH0 N AH0 N'],
  "manning": ['M AE1 N IH0 NG'],
  "manning's": ['M AE1 N IH0 NG Z'],
  "mannino": ['M AE1 N IY0 N OW0'],
  "mannion": ['M AE1 N Y AH0 N'],
  "mannis": ['M AE1 N IH0 S'],
  "mannix": ['M AE1 N IH0 K S'],
  "manno": ['M AE1 N OW0'],
  "mannon": ['M AE1 N AH0 N'],
  "manns": ['M AE1 N Z'],
  "manny": ['M AE1 N IY0'],
  "manny's": ['M AE1 N IY0 Z'],
  "mano": ['M AA1 N OW0'],
  "manocchio": ['M AA0 N OW1 K IY0 OW0'],
  "manoff": ['M AE1 N AO0 F'],
  "manolis": ['M AE1 N AH0 L IH0 S'],
  "manon": ['M AA0 N AO1 N'],
  "manoogian": ['M AH0 N UW1 JH IY0 AH0 N'],
  "manor": ['M AE1 N ER0'],
  "manors": ['M AE1 N ER0 Z'],
  "manos": ['M EY1 N OW0 Z'],
  "manpower": ['M AE1 N P AW2 ER0'],
  "manpower's": ['M AE1 N P AW2 ER0 Z'],
  "manring": ['M AE1 N R IH2 NG'],
  "manrique": ['M AH0 N R IY1 K'],
  "manriquez": ['M AA0 N R IY1 K W EH0 Z'],
  "manry": ['M AE1 N R IY0'],
  "mans": ['M AE1 N Z'],
  "manseau": ['M AH0 N S OW1'],
  "mansel": ['M AE1 N S AH0 L'],
  "mansell": ['M AE1 N S AH0 L'],
  "manser": ['M AE1 N S ER0'],
  "mansfield": ['M AE1 N Z F IY2 L D'],
  "manship": ['M AE1 N SH IH2 P'],
  "mansion": ['M AE1 N SH AH0 N'],
  "mansions": ['M AE1 N CH AH0 N Z'],
  "manske": ['M AE1 N S K'],
  "mansker": ['M AE1 N S K ER0'],
  "manslaughter": ['M AE1 N S L AO2 T ER0'],
  "manso": ['M AE1 N S OW0'],
  "manson": ['M AE1 N S AH0 N'],
  "manson's": ['M AE1 N S AH0 N Z'],
  "mansour": ['M AE1 N S ER0'],
  "mansplain": ['M AE1 N S P L EY2 N'],
  "mansueto": ['M AE0 N S W EY1 T OW0'],
  "mansur": ['M AE1 N S ER0'],
  "manta": ['M AE1 N T AH0'],
  "manteca": ['M AA0 N T EH1 K AH0'],
  "manteer": ['M AE2 N T IY1 R'],
  "mantei": ['M AE1 N T AY0'],
  "mantel": ['M AE1 N T AH0 L'],
  "mantell": ['M AE0 N T EH1 L'],
  "manter": ['M AE1 N T ER0'],
  "manternach": ['M AE1 N T ER0 N AH0 K'],
  "manteufel": ['M AE1 N T OY0 F AH0 L'],
  "mantey": ['M AE1 N T IY0'],
  "manthe": ['M AE1 N DH'],
  "manthei": ['M AE1 N DH AY0'],
  "manthey": ['M AE1 N TH IY0'],
  "mantia": ['M AA1 N SH AH0'],
  "mantilla": ['M AE0 N T IH1 L AH0'],
  "mantione": ['M AA0 N T IY0 OW1 N IY0'],
  "mantis": ['M AE1 N T IH0 S'],
  "mantle": ['M AE1 N T AH0 L'],
  "mantle's": ['M AE1 N T AH0 L Z'],
  "mantles": ['M AE1 N T AH0 L Z'],
  "manton": ['M AE1 N T AH0 N'],
  "mantooth": ['M AE1 N T UW2 TH'],
  "mantra": ['M AE1 N T R AH0'],
  "mantua": ['M AE1 N CH UW0 AH0'],
  "mantz": ['M AE1 N T S'],
  "manu": ['M AA1 N UW2'],
  "manual": ['M AE1 N Y UW0 AH0 L'],
  "manually": ['M AE1 N Y UW0 AH0 L IY0'],
  "manuals": ['M AE1 N Y UW0 AH0 L Z'],
  "manucher": ['M AE1 N UW0 K ER0'],
  "manuel": ['M AA0 N W EH1 L'],
  "manuela": ['M AE0 N W EY1 L AH0'],
  "manuele": ['M AE1 N UH0 L'],
  "manufacture": ['M AE2 N Y AH0 F AE1 K CH ER0'],
  "manufactured": ['M AE2 N Y AH0 F AE1 K CH ER0 D'],
  "manufacturer": ['M AE2 N Y AH0 F AE1 K CH ER0 ER0'],
  "manufacturer's": ['M AE2 N Y AH0 F AE1 K CH ER0 ER0 Z'],
  "manufacturers": ['M AE2 N Y AH0 F AE1 K CH ER0 ER0 Z'],
  "manufacturers'": ['M AE2 N AH0 F AE1 K CH ER0 ER0 Z'],
  "manufactures": ['M AE2 N Y AH0 F AE1 K CH ER0 Z'],
  "manufacturing": ['M AE2 N Y AH0 F AE1 K CH ER0 IH0 NG'],
  "manufacturing's": ['M AE2 N Y AH0 F AE1 K CH ER0 IH0 NG Z'],
  "manumission": ['M AA2 N UW0 M IH1 SH AH0 N'],
  "manure": ['M AH0 N UH1 R'],
  "manus": ['M EY1 N IH0 S'],
  "manuscript": ['M AE1 N Y AH0 S K R IH2 P T'],
  "manuscripts": ['M AE1 N Y AH0 S K R IH2 P T S'],
  "manvel": ['M AE1 N V AH0 L'],
  "manvil": ['M AE1 N V IH0 L'],
  "manville": ['M AE1 N V IH0 L'],
  "manville's": ['M AE1 N V IH0 L Z'],
  "manwaring": ['M AE1 N W EH2 R IH0 NG'],
  "manwarren": ['M AH0 N W AO1 R AH0 N'],
  "manweb": ['M AE1 N W EH2 B'],
  "manwell": ['M AE1 N W EH2 L'],
  "manwiller": ['M AE1 N W IH2 L ER0'],
  "manx": ['M AE1 NG K S'],
  "many": ['M EH1 N IY0'],
  "manz": ['M AE1 N Z'],
  "manza": ['M AA0 N Z AH0'],
  "manzanares": ['M AA0 N Z AA0 N AA1 R EH0 S'],
  "manzanilla": ['M AE2 N Z AH0 N IH1 L AH0'],
  "manzano": ['M AA0 N Z AA1 N OW0'],
  "manzella": ['M AE2 N Z EH1 L AH0'],
  "manzer": ['M AE1 N Z ER0'],
  "manzi": ['M AE1 N Z IY0'],
  "manzi's": ['M AE1 N Z IY0 Z'],
  "manzione": ['M AA0 N Z IY0 OW1 N IY0'],
  "manzo": ['M AE1 N Z OW0'],
  "mao": ['M AW1'],
  "mao's": ['M AW1 Z'],
  "maoist": ['M AW1 IH0 S T'],
  "maoists": ['M AW1 IH0 S T S'],
  "maori": ['M AW1 R IY0'],
  "maoris": ['M EY1 ER0 IH0 S', 'M AW1 R IY0 Z'],
  "map": ['M AE1 P'],
  "mapco": ['M AE1 P K OW0'],
  "mapel": ['M AE1 P AH0 L'],
  "mapes": ['M EY1 P S'],
  "mapfumo": ['M AE0 P F Y UW1 M OW0'],
  "mapi": ['M AE1 P IY0'],
  "mapi's": ['M AE1 P IY0 Z'],
  "maple": ['M EY1 P AH0 L'],
  "maples": ['M EY1 P AH0 L Z'],
  "maplewood": ['M EY1 P AH0 L W UH2 D'],
  "mapp": ['M AE1 P'],
  "mapped": ['M AE1 P T'],
  "mapping": ['M AE1 P IH0 NG'],
  "mapplethorpe": ['M AE1 P AH0 L TH AO0 R P'],
  "maps": ['M AE1 P S'],
  "maputo": ['M AH0 P UW1 T OW0'],
  "maputo's": ['M AH0 P UW1 T OW0 Z'],
  "maquila": ['M AH0 K W IY1 L AH0'],
  "maquiladora": ['M AE2 K W IH0 L AE1 D ER0 AH0'],
  "maquiladoras": ['M AE0 K IY2 Y AH0 D AO1 R AH0 S'],
  "maquilas": ['M AE1 K W AH0 L AH0 S'],
  "mar": ['M AA1 R'],
  "mara": ['M AA1 R AH0'],
  "marabella": ['M AE2 R AH0 B EH1 L AH0'],
  "marable": ['M EH1 R AH0 B AH0 L'],
  "marabou": ['M EH1 R AH0 B UW2'],
  "maraca": ['M AA2 R AA1 K AA0'],
  "maracas": ['M AA2 R AA1 K AA0 Z'],
  "maracle": ['M AA1 R AH0 K AH0 L'],
  "maradona": ['M AA2 R AH0 D OW1 N AH2'],
  "maradona's": ['M AA2 R AH0 D OW1 N AH2 Z'],
  "maradonna": ['M AA2 R AH0 D OW1 N AH2'],
  "maradonna's": ['M AA2 R AH0 D OW1 N AH2 Z'],
  "marafat": ['M EH1 R AH0 F AE0 T'],
  "marais": ['M EH2 R EY1'],
  "marak": ['M AE1 R AH0 K'],
  "maralina": ['M AA0 R AA0 L IY1 N AH0'],
  "maraline": ['M AA0 R AA0 L IY1 N IY0'],
  "maran": ['M AA0 R AA1 N'],
  "maranda": ['M ER0 AE1 N D AH0'],
  "marando": ['M ER0 AE1 N D OW0'],
  "maraniss": ['M ER0 AE1 N IH0 S'],
  "marano": ['M AA0 R AA1 N OW0'],
  "maranon": ['M EH1 R AH0 N AA0 N'],
  "marantette": ['M EH1 R AH0 N T EH2 T'],
  "maranto": ['M ER0 AE1 N T OW0'],
  "marantz": ['M AE1 R AH0 N T S'],
  "maranville": ['M AA0 R AA1 N V IH0 L'],
  "maras": ['M AA1 R AH0 Z'],
  "maraschino": ['M AE2 R AH0 S K IY1 N OW0'],
  "marasco": ['M AA0 R AA1 S K OW0'],
  "marasek": ['M ER0 AA1 S EH0 K'],
  "marash": ['M AA1 R AH0 SH'],
  "marash'": ['M AA1 R AH0 SH'],
  "marash's": ['M AA1 R AH0 SH IH0 S'],
  "marathi": ['M AH0 R AA1 T IY0'],
  "marathon": ['M EH1 R AH0 TH AA2 N'],
  "marathons": ['M EH1 R AH0 TH AA2 N Z'],
  "maraud": ['M ER0 AO1 D'],
  "marauder": ['M ER0 AO1 D ER0'],
  "marauders": ['M ER0 AO1 D ER0 Z'],
  "marauding": ['M ER0 AO1 D IH0 NG'],
  "maravilla": ['M AA0 R AA0 V IH1 L AH0'],
  "marbach": ['M AA1 R B AA2 K'],
  "marbella": ['M AA2 R B EH1 L AH0'],
  "marberry": ['M AA1 R B EH2 R IY0'],
  "marbil": ['M AA1 R B IH0 L'],
  "marble": ['M AA1 R B AH0 L'],
  "marble's": ['M AA1 R B AH0 L Z'],
  "marbled": ['M AA1 R B AH0 L D'],
  "marblehead": ['M AA1 R B AH0 L HH EH2 D'],
  "marbles": ['M AA1 R B AH0 L Z'],
  "marbod": ['M AA1 R B AA0 D'],
  "marbry": ['M AA1 R B R IY0'],
  "marburger": ['M AA1 R B ER0 G ER0'],
  "marbury": ['M AA1 R B EH2 R IY0'],
  "marbut": ['M AA1 R B AH0 T'],
  "marc": ['M AA1 R K'],
  "marcade": ['M AA1 R K EY1 D'],
  "marcano": ['M AA0 R K AA1 N OW0'],
  "marcantel": ['M AA0 R K AA0 N T EH1 L'],
  "marcantonio": ['M AA2 R K AH0 N T OW1 N IY0 OW0'],
  "marceau": ['M AA0 R S OW1'],
  "marceaux": ['M AA0 R S OW1'],
  "marceca": ['M AA0 R S EH1 K AH0'],
  "marceca's": ['M AA2 R S EH1 K AH0 Z'],
  "marcel": ['M AA0 R S EH1 L'],
  "marcela": ['M AA0 R CH EH1 L AH0'],
  "marcelia": ['M AA0 R CH EH1 L IY0 AH0'],
  "marcelino": ['M AA0 R CH EH0 L IY1 N OW0'],
  "marcell": ['M AA0 R S EY1 L'],
  "marcella": ['M AA0 R S EH1 L AH0'],
  "marcelle": ['M AA0 R S EH1 L'],
  "marcelli": ['M AA0 R CH EH1 L IY0'],
  "marcellina": ['M AA0 R CH EH0 L IY1 N AH0'],
  "marcelline": ['M AA0 R CH EH0 L IY1 N IY0'],
  "marcellino": ['M AA0 R CH EH0 L IY1 N OW0'],
  "marcello": ['M AA2 R S EH1 L OW0'],
  "marcellus": ['M AA0 R S EH1 L AH0 S'],
  "marcelo": ['M AA0 R CH EH1 L OW0'],
  "marcessa": ['M AA0 R S EH1 S AH0'],
  "marcessa's": ['M AA0 R S EH1 S AH0 Z'],
  "march": ['M AA1 R CH'],
  "march's": ['M AA1 R CH IH0 Z'],
  "marchak": ['M AA1 R CH AH0 K'],
  "marchal": ['M AA1 R CH AH0 L'],
  "marchand": ['M AA0 R K HH AE1 N D', 'M AA0 R CH AE1 N D'],
  "marchant": ['M AA1 R CH AH0 N T'],
  "marchbank": ['M AA1 R CH B AE2 NG K'],
  "marchbanks": ['M AA1 R CH B AE2 NG K S'],
  "marche": ['M AA1 R SH'],
  "marched": ['M AA1 R CH T'],
  "marchenko": ['M AA2 R CH EH1 N K OW0'],
  "marcher": ['M AA1 R CH ER0'],
  "marchers": ['M AA1 R CH ER0 Z'],
  "marches": ['M AA1 R CH IH0 Z'],
  "marchesani": ['M AA0 R K EH0 S AA1 N IY0'],
  "marchesano": ['M AA0 R K EH0 S AA1 N OW0'],
  "marcheschi": ['M AA0 R CH EH1 SH IY0'],
  "marchese": ['M AA0 R K IY1 Z IY0'],
  "marchesi": ['M AA0 R K EH1 S IY0'],
  "marchessault": ['M AA1 R SH IH0 S OW0'],
  "marchetta": ['M AA0 R K EH1 T AH0'],
  "marchetti": ['M AA0 R K EH1 T IY0'],
  "marchewka": ['M ER0 CH Y UW1 K AH0'],
  "marchi": ['M AA1 R K IY0'],
  "marching": ['M AA1 R CH IH0 NG'],
  "marchini": ['M AA0 R K IY1 N IY0'],
  "marchinko": ['M AA0 R CH IY1 NG K OW0'],
  "marchio": ['M AA1 R K IY0 OW0'],
  "marchione": ['M AA0 R K IY0 OW1 N IY0'],
  "marchioness": ['M AA1 R SH AH0 N IH0 S', 'M AA1 R SH AH0 N EH0 S'],
  "marchita": ['M AA0 R K IY1 T AH0'],
  "marchitto": ['M AA0 R K IY1 T OW0'],
  "marchman": ['M AA1 R K M AH0 N'],
  "marci": ['M AA1 R S IY0'],
  "marcia": ['M AA1 R SH AH0'],
  "marcia's": ['M AA1 R SH AH0 Z'],
  "marcial": ['M AA0 R S IY0 AA1 L'],
  "marciano": ['M AA0 R CH IY0 AA1 N OW0'],
  "marciante": ['M AA1 R CH AH0 N T IY0'],
  "marcie": ['M AA1 R K IY0'],
  "marcil": ['M AA1 R S IH0 L'],
  "marcile": ['M AA1 R CH AH0 L'],
  "marcilio": ['M AA0 R S IY1 L IY0 OW0'],
  "marcille": ['M AA1 R S IH0 L'],
  "marcin": ['M AA0 R S IY1 N'],
  "marcinek": ['M ER0 CH IH1 N EH0 K'],
  "marciniak": ['M ER0 CH IH1 N IY0 AE0 K'],
  "marcinko": ['M AA2 R S IH1 NG K OW0'],
  "marcinkowski": ['M ER0 CH IH0 NG K AO1 F S K IY0'],
  "marcinkus": ['M AA2 R S IH1 NG K AH0 S'],
  "marcissa": ['M AA2 R S IH1 S AH0'],
  "marckesano": ['M AA2 R K EH2 S AA1 N OW0'],
  "marcks": ['M AA1 R K S'],
  "marcmann": ['M AA1 R K M AH0 N'],
  "marco": ['M AA1 R K OW0'],
  "marco's": ['M AA1 R K OW2 Z'],
  "marcoe": ['M AA1 R K OW0'],
  "marcom": ['M AA1 R K AH0 M'],
  "marcon": ['M AA1 R K AH0 N'],
  "marcone": ['M AA0 R K OW1 N IY0'],
  "marconi": ['M AA0 R K OW1 N IY0'],
  "marcor": ['M AA1 R K AO2 R'],
  "marcos": ['M AA1 R K OW0 S'],
  "marcos'": ['M AA1 R K OW0 S', 'M AA1 R K OW0 S IH0 Z'],
  "marcos's": ['M AA1 R K AH0 S IH0 Z'],
  "marcoses": ['M AA0 R K OW1 S IH0 Z'],
  "marcoses'": ['M AA0 R K OW1 S IH0 Z'],
  "marcott": ['M AA0 R K AA1 T'],
  "marcotte": ['M AA0 R K AO1 T'],
  "marcou": ['M AA0 R K UW1'],
  "marcoux": ['M AA0 R K UW1'],
  "marcrum": ['M AA1 R K R AH0 M'],
  "marcucci": ['M AA0 R K UW1 CH IY0'],
  "marcum": ['M AA1 R K AH0 M'],
  "marcus": ['M AA1 R K AH0 S'],
  "marcus's": ['M AA1 R K AH0 S IH0 Z'],
  "marcussen": ['M AA1 R K AH0 S AH0 N'],
  "marcy": ['M AA1 R S IY0'],
  "marczak": ['M AA1 R CH AE0 K'],
  "marden": ['M AA1 R D AH0 N'],
  "marder": ['M AA1 R D ER0'],
  "mardi": ['M AA1 R D IY0'],
  "mardi-gras": ['M AA1 R D IY0 G R AA2'],
  "mardis": ['M AA1 R D IH0 S', 'M AA1 R D IY0 S'],
  "mardon": ['M AA1 R D AH0 N'],
  "mare": ['M EH1 R'],
  "mare's": ['M EH1 R Z'],
  "maready": ['M ER0 EH1 D IY0'],
  "marean": ['M AA0 R IY1 N'],
  "marek": ['M AA1 R EH0 K'],
  "marelda": ['M AA0 R EH1 L D AH0'],
  "marella": ['M ER0 EH1 L AH0'],
  "marelli": ['M EH0 R EH1 L IY0'],
  "mareno": ['M AA0 R EH1 N OW0'],
  "mares": ['M EH1 R Z'],
  "maresca": ['M AA0 R EH1 S K AH0'],
  "maresco": ['M AA0 R EH1 S K OW0'],
  "maresh": ['M AE1 R IH0 SH'],
  "maret": ['M AA1 R EY0 T'],
  "marett": ['M AE1 R IH0 T'],
  "maretta": ['M AA0 R EH1 T AH0'],
  "marette": ['M ER0 EH1 T'],
  "marez": ['M AA0 R EH1 Z'],
  "marfa": ['M AA1 R F AH0'],
  "marga": ['M AA1 R G AH0'],
  "margalo": ['M AA0 R G AA1 L OW0'],
  "margalov": ['M AA1 G AH0 L AO0 V'],
  "margaret": ['M AA1 R G ER0 IH0 T', 'M AA1 R G R AH0 T', 'M AA1 R G R IH0 T'],
  "margaret's": ['M AA1 R G ER0 IH0 T S', 'M AA1 R G R IH0 T S'],
  "margareta": ['M AA0 R G AA0 R EH1 T AH0'],
  "margarete": ['M AA1 R G ER0 IY2 T'],
  "margarethe": ['M AA0 R G AA0 R EH1 DH IY0'],
  "margaretten": ['M AA2 R G ER0 EH1 T AH0 N'],
  "margarine": ['M AA1 R JH ER0 AH0 N'],
  "margarita": ['M AA2 R G ER0 IY1 T AH0'],
  "margaritas": ['M AA2 R G EH0 R IY1 T AH0 S'],
  "margaux": ['M AA0 R G OW1'],
  "marge": ['M AA1 R JH'],
  "margeotes": ['M AA1 R JH IY0 OW2 T IY0 Z'],
  "margerum": ['M AA1 R G ER0 AH0 M'],
  "margery": ['M AA1 R JH ER0 IY0'],
  "margeson": ['M AA1 R G IH0 S AH0 N'],
  "marget": ['M AA1 R G IH0 T'],
  "margette": ['M AA0 R ZH EH1 T'],
  "margie": ['M AA1 R JH IY0'],
  "margin": ['M AA1 R JH AH0 N'],
  "marginal": ['M AA1 R JH AH0 N AH0 L'],
  "marginalization": ['M AA2 R JH AH0 N AH0 L AH0 Z EY1 SH AH0 N'],
  "marginalize": ['M AA1 R JH AH0 N AH0 L AY2 Z'],
  "marginalized": ['M AA1 R JH AH0 N AH0 L AY2 Z D'],
  "marginalizes": ['M AA1 R JH AH0 N AH0 L AY2 Z IH0 Z'],
  "marginalizing": ['M AA1 R JH AH0 N AH0 L AY2 Z IH0 NG'],
  "marginally": ['M AA1 R JH AH0 N AH0 L IY0'],
  "margined": ['M AA1 R JH AH0 N D'],
  "margining": ['M AA1 R JH AH0 N IH0 NG'],
  "margins": ['M AA1 R JH AH0 N Z'],
  "margiotta": ['M AA0 R JH OW1 T AH0'],
  "margittai": ['M AA1 R JH IH0 T AY2'],
  "margo": ['M AA1 R G OW0'],
  "margolick": ['M AA0 R G OW1 L IH0 K'],
  "margolies": ['M AA0 R G OW1 L IY0 Z'],
  "margolin": ['M AA1 R G AH0 L IH0 N', 'M AA0 R G OW1 L AH0 N'],
  "margolis": ['M AA0 R G OW1 L IH0 S'],
  "margory": ['M ER0 G ER1 IY0'],
  "margoshes": ['M AA0 R G AO1 SH IH0 Z'],
  "margot": ['M AA1 R G OW0'],
  "margraf": ['M AA1 R G R AH0 F'],
  "margrave": ['M AA1 R G R EY2 V'],
  "margret": ['M AA1 R G R EH0 T'],
  "margret's": ['M AA1 R G R EH0 T S'],
  "marguerite": ['M AA2 R G ER0 IY1 T'],
  "margulies": ['M AA1 R G UW0 L IY0 Z'],
  "margulis": ['M AA1 R G AH0 L IH0 S'],
  "margus": ['M AA1 R G AH0 S'],
  "margy": ['M AA1 R JH IY0'],
  "marhefka": ['M ER0 HH EH1 F K AH0'],
  "mari": ['M AA1 R IY0'],
  "maria": ['M ER0 IY1 AH0'],
  "maria's": ['M ER0 IY1 AH0 Z'],
  "mariachi": ['M AA2 R IY0 AA1 CH IY0'],
  "mariah": ['M ER0 AY1 AH0'],
  "mariam": ['M EH1 R IY0 AE0 M'],
  "marian": ['M EH1 R IY0 AH0 N'],
  "marian's": ['M EH1 R IY0 AH0 N Z'],
  "mariana": ['M EH2 R IY0 AE1 N AH0'],
  "marianas": ['M EH2 R IY0 AE1 N AH0 S'],
  "mariani": ['M AA0 R IY0 AA1 N IY0', 'M AA0 R IY0 AE1 N IY0'],
  "marianna": ['M AA0 R IY0 AA1 N AH0'],
  "marianne": ['M EH2 R IY0 AE1 N'],
  "mariano": ['M EH2 R IY0 AA1 N OW0'],
  "maribel": ['M AE1 R IH0 B EH0 L'],
  "marice": ['M AA1 R IH0 S'],
  "marich": ['M AE1 R IH0 K'],
  "maricle": ['M AE1 R IH0 K AH0 L'],
  "maricopa": ['M EH2 R IH0 K OW1 P AH0'],
  "marie": ['M ER0 IY1'],
  "marie's": ['M ER0 IY1 Z'],
  "mariel": ['M EH1 R IY0 AH0 L'],
  "marielito": ['M EH0 R IY2 EH0 L IY1 T OW0'],
  "marielitos": ['M EH0 R IY2 EH0 L IY1 T OW0 S'],
  "marien": ['M EH1 R IY0 N'],
  "marienbonn": ['M EH1 R IY0 N B AO2 N'],
  "marienbonn's": ['M EH1 R IY0 N B AO2 N Z'],
  "marier": ['M EH1 R IY0 ER0'],
  "marietta": ['M EH2 R IY0 EH1 T AH0'],
  "marietta's": ['M EH2 R IY0 EH1 T AH0 Z'],
  "mariette": ['M EH2 R IY0 EH1 T'],
  "marife": ['M AH0 R IY1 F EY0'],
  "marigold": ['M EH1 R AH0 G OW2 L D'],
  "marigolds": ['M EH1 R AH0 G OW2 L D Z'],
  "marijuana": ['M EH2 R AH0 W AA1 N AH0'],
  "marik": ['M AE1 R IH0 K'],
  "marilda": ['M AA0 R IY1 L D AH0'],
  "marilin": ['M AE1 R IH0 L IH0 N'],
  "marilla": ['M AA0 R IH1 L AH0'],
  "marilu": ['M EH1 R IY0 L UW0'],
  "marilyn": ['M EH1 R AH0 L AH0 N', 'M EH1 R AH0 L IH0 N'],
  "marilyn's": ['M EH1 R AH0 L AH0 N Z', 'M EH1 R AH0 L IH0 N Z'],
  "marilynn": ['M EH1 R AH0 L AH0 N'],
  "marimba": ['M ER0 IH1 M B AH0'],
  "marimbalax": ['M ER0 IH1 M B AH0 L AH2 K S'],
  "marin": ['M EH1 R IH0 N'],
  "marina": ['M ER0 IY1 N AH0'],
  "marinaccio": ['M AA0 R IH0 N AA1 CH IY0 OW0'],
  "marinade": ['M AA1 R IH0 N EY2 D'],
  "marinades": ['M AA1 R IH0 N EY2 D Z'],
  "marinaro": ['M AA0 R IH0 N AA1 R OW0'],
  "marinas": ['M ER0 IY1 N AH0 Z'],
  "marinate": ['M EH1 R AH0 N EY2 T'],
  "marinated": ['M EH1 R AH0 N EY2 T IH0 D'],
  "marinates": ['M EH1 R AH0 N EY2 T S'],
  "marinating": ['M EH1 R AH0 N EY2 T IH0 NG'],
  "marine": ['M ER0 IY1 N'],
  "marine's": ['M ER0 IY1 N Z'],
  "marineau": ['M EH1 R IH0 N OW0'],
  "marineland": ['M ER0 IY1 N L AH0 N D'],
  "marinelli": ['M AA0 R IH0 N EH1 L IY0'],
  "marinello": ['M AA0 R IH0 N EH1 L OW0'],
  "mariner": ['M EH1 R AH0 N ER0'],
  "mariners": ['M EH1 R AH0 N ER0 Z'],
  "marines": ['M ER0 IY1 N Z'],
  "marines'": ['M ER0 IY1 N Z'],
  "marinez": ['M AA0 R IY1 N EH0 Z'],
  "maring": ['M EH1 R IH0 NG'],
  "marini": ['M AA0 R IY1 N IY0'],
  "marinkovich": ['M ER0 IH1 NG K AH0 V IH0 CH'],
  "marino": ['M ER0 IY1 N OW0'],
  "marino's": ['M ER0 IY1 N OW0 Z'],
  "marinoff": ['M AE1 R IH0 N AO0 F'],
  "marinos": ['M ER0 IY1 N OW0 Z'],
  "marinucci": ['M AA0 R IY0 N UW1 CH IY0'],
  "marinvest": ['M ER0 IH1 N V AH0 S T'],
  "mario": ['M AA1 R IY0 OW0'],
  "mario's": ['M AA1 R IY0 OW0 Z'],
  "marion": ['M EH1 R IY0 AH0 N'],
  "marion's": ['M EH1 R IY0 AH0 N Z'],
  "mariotta": ['M AA2 R IY0 AO1 T AA0'],
  "mariotti": ['M AA2 R IY0 AO1 T IY0'],
  "mariposa": ['M AE2 R IH0 P OW1 S AH0'],
  "maris": ['M EH1 R IH0 S'],
  "marisa": ['M AH0 R IH1 S AH0'],
  "mariscal": ['M AA1 R AH0 S K AH0 L'],
  "marish": ['M EH1 R IH0 SH'],
  "marissa": ['M AH0 R IH1 S AH0'],
  "marissa's": ['M AH0 R IH1 S AH0 Z'],
  "marital": ['M EH1 R AH0 T AH0 L'],
  "maritime": ['M EH1 R AH0 T AY2 M'],
  "maritimes": ['M EH1 R AH0 T AY2 M Z'],
  "maritz": ['M EH1 R IH0 T S'],
  "mariucci": ['M EH2 R IY0 UW1 CH IY0'],
  "marius": ['M AA1 R IY0 AH0 S'],
  "mariusa": ['M AA0 R IY0 Y UW1 S AH0'],
  "marjai": ['M AA1 R JH EY0'],
  "marje": ['M AA1 R JH'],
  "marjie": ['M AA1 R JH IY0'],
  "marjoram": ['M AA1 JH ER0 AH0 M'],
  "marjorie": ['M AA1 R JH ER0 IY0', 'M AA1 R JH R IY0'],
  "marjory": ['M AA1 R JH ER0 IY0', 'M AA1 R JH R IY0'],
  "marjy": ['M AA1 R JH IY0'],
  "mark": ['M AA1 R K'],
  "mark's": ['M AA1 R K S'],
  "markair": ['M AA1 R K EH2 R'],
  "markair's": ['M AA1 R K EH2 R Z'],
  "markan": ['M AA1 R K AH0 N'],
  "markarian": ['M AA2 R K EH1 R IY0 AH0 N'],
  "markdown": ['M AA1 R K D AW2 N'],
  "markdowns": ['M AA1 R K D AW2 N Z'],
  "marke": ['M AA1 R K'],
  "marked": ['M AA1 R K T'],
  "markedly": ['M AA1 R K AH0 D L IY0', 'M AA1 R K IH0 D L IY0'],
  "markee": ['M AA1 R K IY1'],
  "markel": ['M AA1 R K AH0 L', 'M AA0 R K EH1 L'],
  "markell": ['M AA1 R K AH0 L'],
  "marken": ['M AA1 R K AH0 N'],
  "marker": ['M AA1 R K ER0'],
  "markers": ['M AA1 R K ER0 Z'],
  "markert": ['M AA1 R K ER0 T'],
  "markese": ['M AA0 R K IY1 S'],
  "market": ['M AA1 R K AH0 T', 'M AA1 R K IH0 T'],
  "market's": ['M AA1 R K AH0 T S'],
  "marketability": ['M AA2 R K IH0 T AH0 B IH1 L IH0 T IY0'],
  "marketable": ['M AA1 R K AH0 T AH0 B AH0 L'],
  "marketamerica": ['M AA2 R K AH0 T AH0 M EH1 R IH0 K AH0'],
  "marketed": ['M AA1 R K AH0 T AH0 D', 'M AA1 R K AH0 T IH0 D'],
  "marketeer": ['M AA2 R K AH0 T IH1 R'],
  "marketeers": ['M AA2 R K AH0 T IH1 R Z'],
  "marketer": ['M AA1 R K AH0 T ER0'],
  "marketer's": ['M AA1 R K AH0 T ER0 Z'],
  "marketers": ['M AA2 R K AH0 T ER0 Z'],
  "marketers'": ['M AA1 R K AH0 T ER0 Z'],
  "marketi": ['M AA1 R K AH0 T IY0'],
  "marketing": ['M AA1 R K AH0 T IH0 NG'],
  "marketing's": ['M AA1 R K AH0 T IH0 NG Z'],
  "marketings": ['M AA1 R K AH0 T IH0 NG Z'],
  "marketmaker": ['M AA1 R K AH0 T M EY2 K ER0'],
  "marketmakers": ['M AA1 R K AH0 T M EY2 K ER0 Z'],
  "marketplace": ['M AA1 R K AH0 T P L EY2 S', 'M AA1 R K IH0 T P L EY2 S'],
  "marketplace's": ['M AA1 R K AH0 T P L EY2 S IH0 Z'],
  "marketplaces": ['M AA1 R K AH0 T P L EY2 S IH0 Z'],
  "markets": ['M AA1 R K AH0 T S', 'M AA1 R K IH0 T S'],
  "markets'": ['M AA1 R K IH0 T S'],
  "marketscope": ['M AA1 R K AH0 T S K OW2 P'],
  "marketwide": ['M AA1 R K AH0 T W AY2 D'],
  "markey": ['M AA1 R K IY2'],
  "markey's": ['M AA1 R K IY2 Z'],
  "markgraf": ['M AA1 R K G R AH0 F'],
  "markham": ['M AA1 R K AH0 M'],
  "markham's": ['M AA1 R K AH0 M Z'],
  "markie": ['M AA1 R K IY0'],
  "markiewicz": ['M AA1 R K AH0 V IH0 CH'],
  "markin": ['M AA1 R K IH0 N'],
  "marking": ['M AA1 R K IH0 NG'],
  "markings": ['M AA1 R K IH0 NG Z'],
  "markka": ['M AA1 R K AH0'],
  "markkaa": ['M AA0 R K AA1'],
  "markkas": ['M AA1 R K AH0 S'],
  "markland": ['M AA1 R K L AH0 N D'],
  "markle": ['M AA1 R K AH0 L'],
  "markley": ['M AA1 R K L IY0'],
  "markman": ['M AA1 R K M AH0 N'],
  "marko": ['M AA1 R K OW0'],
  "markoff": ['M AA1 R K AO2 F'],
  "markos": ['M AA1 R K OW0 Z'],
  "markov": ['M AA1 R K OW0 V'],
  "markovian": ['M AA2 R K OW1 V IY2 AH0 N'],
  "markovic": ['M AA1 R K AH0 V IH0 K'],
  "markovich": ['M AA1 R K AH0 V IH0 CH'],
  "markovitz": ['M AA1 R K AH0 V IH0 T S'],
  "markow": ['M AA1 R K OW0'],
  "markowicz": ['M AA1 R K AH0 V IH0 CH'],
  "markowitz": ['M AA1 R K AH0 W IH0 T S'],
  "markowski": ['M ER0 K AO1 F S K IY0'],
  "marks": ['M AA1 R K S'],
  "marks's": ['M AA1 R K S IH0 Z'],
  "marksberry": ['M AA1 R K S B EH2 R IY0'],
  "marksman": ['M AA1 R K S M AH0 N'],
  "marksmanship": ['M AA1 R K S M AH0 N SH IH0 P'],
  "marksmen": ['M AA1 R K S M EH0 N'],
  "markson": ['M AA1 R K S AH0 N'],
  "markum": ['M AA1 R K AH0 M'],
  "markunas": ['M AA1 R K UW0 N AH0 Z'],
  "markup": ['M AA1 R K AH2 P'],
  "markups": ['M AA1 R K AH2 P S'],
  "markus": ['M AA1 R K AH0 S'],
  "markuson": ['M AA1 R K AH0 S AH0 N'],
  "markwardt": ['M AA1 R K W AO0 R T'],
  "markwell": ['M AA1 R K W EH2 L'],
  "markwood": ['M AA1 R K W UH2 D'],
  "marky": ['M AA1 R K IY0'],
  "marla": ['M AA1 R L AH0'],
  "marland": ['M AA1 R L AH0 N D'],
  "marlar": ['M AA1 R L ER0'],
  "marlatt": ['M AA1 R L AH0 T'],
  "marlboro": ['M AA1 R L B ER0 OW0'],
  "marlboro's": ['M AA1 R L B ER0 R OW0 Z'],
  "marlboros": ['M AA1 R L B ER0 R OW0 Z'],
  "marlborough": ['M AA1 R L B ER0 OW0'],
  "marleen": ['M AA0 R L IY1 N'],
  "marleene": ['M AA0 R L IY1 N'],
  "marlen": ['M AA1 R L AH0 N'],
  "marlena": ['M AA0 R L EY1 N AH0'],
  "marlene": ['M AA1 R L IY2 N'],
  "marlens": ['M AA1 R L AH0 N Z'],
  "marler": ['M AA1 R L ER0'],
  "marlett": ['M AA1 R L IH0 T'],
  "marlette": ['M AA2 R L EH1 T'],
  "marley": ['M AA1 R L IY0'],
  "marliave": ['M AA2 R L IY0 EH1 V'],
  "marlin": ['M AA1 R L AH0 N', 'M AA1 R L IH0 N'],
  "marling": ['M AA1 R L IH0 NG'],
  "marlins": ['M AA1 R L AH0 N Z'],
  "marlins'": ['M AA1 R L AH0 N Z'],
  "marlo": ['M AA1 R L OW0'],
  "marlon": ['M AA1 R L AH0 N'],
  "marlow": ['M AA1 R L OW0'],
  "marlowe": ['M AA1 R L OW2'],
  "marlton": ['M AA1 R L T AH0 N'],
  "marlys": ['M AA1 R L IY0 Z'],
  "marmaduke": ['M AA1 R M AH0 D UW2 K'],
  "marmaduke's": ['M AA1 R M AH0 D UW2 K S'],
  "marmalade": ['M AA1 R M AH0 L EY2 D'],
  "marmalades": ['M AA1 R M AH0 L EY2 D Z'],
  "marmara": ['M AA0 R M AA1 R AH0'],
  "marmer": ['M AA1 R M ER0'],
  "marmion": ['M AA1 R M IY0 AH0 N'],
  "marmo": ['M AA1 R M OW0'],
  "marmolejo": ['M AA0 R M OW0 L EY1 Y OW0'],
  "marmon": ['M AA1 R M AH0 N'],
  "marmor": ['M AA1 R M ER0'],
  "marmoset": ['M AA1 R M AH0 Z EH2 T'],
  "marnell": ['M AA1 R N AH0 L'],
  "marner": ['M AA1 R N ER0'],
  "marney": ['M AA1 R N IY0'],
  "marnie": ['M AA1 R N IY0'],
  "maro": ['M AA1 R OW0'],
  "maroc": ['M AA2 R AO1 K'],
  "marogen": ['M EH1 R OW0 G AH0 N'],
  "marohl": ['M AE1 R OW0 L'],
  "marohn": ['M AA1 R AH0 N'],
  "marois": ['M ER0 W AA1'],
  "marold": ['M AE1 R OW0 L D'],
  "marolf": ['M AE1 R OW0 L F'],
  "marolt": ['M AE1 R OW0 L T'],
  "maron": ['M AA0 R AO1 N'],
  "marone": ['M ER0 OW1 N'],
  "maroney": ['M ER0 OW1 N IY0'],
  "maroni": ['M AA0 R OW1 N IY0'],
  "maronite": ['M EH1 R AH0 N AY2 T'],
  "maroon": ['M ER0 UW1 N'],
  "marooned": ['M ER0 UW1 N D'],
  "marooney": ['M ER0 UW1 N IY0'],
  "marose": ['M ER0 OW1 S'],
  "marotta": ['M ER0 AA1 T AH0'],
  "marotte": ['M ER0 AO1 T'],
  "marotto": ['M AA0 R OW1 T OW0'],
  "marotz": ['M AE1 R AH0 T S'],
  "marous": ['M EH1 R AH0 S'],
  "marovich": ['M AA1 R AH0 V IH0 CH'],
  "marplan": ['M AA1 R P L AE2 N'],
  "marple": ['M AA1 R P AH0 L'],
  "marquai": ['M AA0 R K W EY1', 'M AA0 R K EY1'],
  "marquand": ['M AA1 R K W AH0 N D'],
  "marquard": ['M AA0 R K W AA1 R D'],
  "marquardt": ['M AA1 R K W AA0 R T'],
  "marquart": ['M AA1 R K W AA0 R T'],
  "marque": ['M AA1 R K'],
  "marquee": ['M AA0 R K IY1'],
  "marquerite": ['M AA0 R K ER0 IY1 T'],
  "marques": ['M AA1 R K S'],
  "marquess": ['M AA1 R K W AH0 S'],
  "marquest": ['M AA1 R K W EH2 S T'],
  "marquette": ['M AA0 R K EH1 T'],
  "marquez": ['M AA0 R K EH1 Z'],
  "marquis": ['M AA2 R K IY1'],
  "marquiss": ['M AA1 R K W IY0 S'],
  "marquita": ['M AA0 R K W IY1 T AH0'],
  "marr": ['M AA1 R'],
  "marra": ['M AA1 R AH0'],
  "marrakech": ['M AE1 R AH0 K EH2 SH'],
  "marrazzo": ['M AA2 R AA1 Z OW0'],
  "marred": ['M AA1 R D'],
  "marren": ['M AA1 R AH0 N'],
  "marrero": ['M AA2 R EH1 R OW0'],
  "marrett": ['M EH1 R IH0 T'],
  "marriage": ['M EH1 R IH0 JH'],
  "marriages": ['M EH1 R IH0 JH IH0 Z'],
  "married": ['M EH1 R IY0 D'],
  "marries": ['M EH1 R IY0 Z'],
  "marrin": ['M AE1 R IH0 N'],
  "marriner": ['M EH1 R IH0 N ER0'],
  "marring": ['M AA1 R IH0 NG'],
  "marriott": ['M EH1 R IY0 AH0 T'],
  "marriott's": ['M EH1 R IY0 AH0 T S'],
  "marris": ['M AE1 R IH0 S'],
  "marro": ['M AA1 R OW0'],
  "marrocco": ['M AA0 R OW1 K OW0'],
  "marron": ['M EH1 R AH0 N'],
  "marrone": ['M AA0 R OW1 N IY0'],
  "marroquin": ['M AA0 R OW0 K W IY1 N'],
  "marrou": ['M AH0 R UW1'],
  "marrow": ['M EH1 R OW0'],
  "marrs": ['M AA1 R Z'],
  "marrufo": ['M AA2 R UW1 F OW0'],
  "marrujo": ['M AA0 R UW1 Y OW0'],
  "marry": ['M EH1 R IY0'],
  "marrying": ['M EH1 R IY0 IH0 NG'],
  "mars": ['M AA1 R Z'],
  "mars'": ['M AA1 R Z'],
  "mars's": ['M AA1 R Z IH0 Z'],
  "marsal": ['M AA1 R S AH0 L', 'M AA0 R S AA1 L'],
  "marsala": ['M AA0 R S AA1 L AH0'],
  "marsalis": ['M AA1 R S AH0 L IH0 S', 'M AA0 R S AA1 L IH2 S'],
  "marsam": ['M AA1 R S AE0 M'],
  "marsch": ['M AA1 R SH'],
  "marschalk": ['M AA1 R SH AO2 K'],
  "marschall": ['M AA1 R SH AH0 L'],
  "marschke": ['M AA1 R SH K'],
  "marschner": ['M AA1 R SH N ER0'],
  "marsden": ['M AA1 R S D AH0 N'],
  "marsdon": ['M AA1 R S D AH0 N'],
  "marse": ['M AA1 R S'],
  "marseca": ['M AA0 R S EH1 K AH0'],
  "marseca's": ['M AA0 R S EH1 K AH0 Z'],
  "marsee": ['M AA1 R S IY2'],
  "marseille": ['M AA0 R S AY1 L'],
  "marseilles": ['M AA0 R S AY1 L AH0 S', 'M AA0 R S AY1 Z'],
  "marseka": ['M AA0 R S EH1 K AH0'],
  "marsella": ['M AA2 R S EH1 L AH0'],
  "marsh": ['M AA1 R SH'],
  "marsh's": ['M AA1 R SH IH0 Z'],
  "marsha": ['M AA1 R SH AH0'],
  "marshak": ['M AA1 R SH AH0 K'],
  "marshal": ['M AA1 R SH AH0 L'],
  "marshal's": ['M AA1 R SH AH0 L Z'],
  "marshaled": ['M AA1 R SH AH0 L D'],
  "marshaling": ['M AA1 R SH AH0 L IH0 NG'],
  "marshall": ['M AA1 R SH AH0 L'],
  "marshall's": ['M AA1 R SH AH0 L Z'],
  "marshalled": ['M AA1 R SH AH0 L D'],
  "marshalls": ['M AA1 R SH AO2 L Z'],
  "marshalltown": ['M AA1 R SH AH0 L T AW2 N'],
  "marshals": ['M AA1 R SH AH0 L Z'],
  "marshburn": ['M AA1 R SH B ER2 N'],
  "marshes": ['M AA1 R SH AH0 Z', 'M AA1 R SH IH0 Z'],
  "marshfield": ['M AA1 R SH F IY2 L D'],
  "marshland": ['M AA1 R SH L AE2 N D'],
  "marshlands": ['M AA1 R SH L AE2 N D Z'],
  "marshmallow": ['M AA1 R SH M EH2 L OW0'],
  "marshmallows": ['M AA1 R SH M EH2 L OW0 Z'],
  "marshman": ['M AA1 R SH M AH0 N'],
  "marshy": ['M AA1 R SH IY0'],
  "marsicano": ['M AA0 R S IY0 K AA1 N OW0'],
  "marsico": ['M AA0 R S IY1 K OW0'],
  "marsiglia": ['M AA2 R S IH1 G L IY0 AH0'],
  "marsili": ['M AA0 R S IY1 L IY0'],
  "marsisa": ['M AA0 R S IH1 S AH0'],
  "marsisa's": ['M AA0 R S IH1 S AH0 Z'],
  "marsland": ['M AA1 R S L AH0 N D'],
  "marso": ['M AA1 R S OW0'],
  "marsolek": ['M AA1 R S AH0 L EH0 K'],
  "marson": ['M AA1 R S AH0 N'],
  "marsteller": ['M AA1 R Z T EH2 L ER0'],
  "marsters": ['M AA1 R S T ER0 Z'],
  "marston": ['M AA1 R S T AH0 N'],
  "marsupial": ['M AA0 R S UW1 P IY0 AH0 L'],
  "marsupials": ['M AA0 R S UW1 P IY0 AH0 L S'],
  "marszalek": ['M ER0 SH AA1 L EH0 K'],
  "mart": ['M AA1 R T'],
  "mart's": ['M AA1 R T S'],
  "marta": ['M AA1 R T AH0'],
  "marte": ['M AA1 R T'],
  "martech": ['M AA1 R T EH2 K'],
  "martek": ['M AA1 R T EH2 K'],
  "martel": ['M AA1 R T EH2 L'],
  "martell": ['M AA1 R T EH2 L'],
  "martella": ['M AA2 R T EH1 L AH0'],
  "martelle": ['M AA0 R T EH1 L'],
  "martelli": ['M AA0 R T EH1 L IY0'],
  "martellini": ['M AA2 R T EH2 L IY1 N IY0'],
  "martello": ['M AA0 R T EH1 L OW0'],
  "marten": ['M AA1 R T AH0 N'],
  "marteney": ['M AA1 R T IH0 N IY0'],
  "martens": ['M AA1 R T AH0 N Z'],
  "martens's": ['M AA1 R T AH0 N Z IH0 Z'],
  "martensen": ['M AA1 R T IH0 N S AH0 N'],
  "martenson": ['M AA1 R T IH0 N S AH0 N'],
  "marter": ['M AA1 R T ER0'],
  "marth": ['M AA1 R TH'],
  "martha": ['M AA1 R TH AH0'],
  "martha's": ['M AA1 R TH AH0 Z'],
  "marthaler": ['M AA1 R TH AH0 L ER0'],
  "marthe": ['M AA1 R DH'],
  "marthena": ['M AA1 R TH IH0 N AH0'],
  "marti": ['M AA1 R T IY0'],
  "marti's": ['M AA1 R T IY0 Z'],
  "martial": ['M AA1 R SH AH0 L'],
  "martialed": ['M AA1 R SH AH0 L D'],
  "martials": ['M AA1 R SH AH0 L Z'],
  "martian": ['M AA1 R SH AH0 N'],
  "martians": ['M AA1 R SH AH0 N Z'],
  "martie": ['M AA1 R T IY0'],
  "martin": ['M AA1 R T AH0 N', 'M AA1 R T IH0 N'],
  "martin's": ['M AA1 R T AH0 N Z'],
  "martina": ['M AA0 R T IY1 N AH0'],
  "martindale": ['M AA1 R T IH0 N D EY0 L'],
  "martine": ['M AA0 R T IY1 N'],
  "martineau": ['M AA1 R T IH0 N OW2'],
  "martinec": ['M AA1 R T IH0 N EH0 K'],
  "martinek": ['M AA1 R T IH0 N IH0 K'],
  "martines": ['M AA0 R T IY1 N Z'],
  "martinez": ['M AA0 R T IY1 N EH0 Z'],
  "martinez's": ['M AA0 R T IY1 N EH2 Z IH0 Z'],
  "marting": ['M AA1 R T IH0 NG'],
  "martingale": ['M AA1 R T IH0 NG EY2 L'],
  "martini": ['M AA0 R T IY1 N IY0'],
  "martinique": ['M AA0 R T IH0 N IY1 K'],
  "martinis": ['M AA0 R T IY1 N IY0 Z'],
  "martinka": ['M AA2 R T IH1 NG K AH0'],
  "martinko": ['M AA2 R T IH1 NG K OW0'],
  "martino": ['M AA0 R T IY1 N OW0'],
  "martinovich": ['M AA2 R T IH1 N AH0 V IH0 CH'],
  "martins": ['M AA1 R T IH0 N Z'],
  "martins's": ['M AA1 R T IH0 N Z IH0 Z'],
  "martinsen": ['M AA0 R T IH1 N S AH0 N'],
  "martinson": ['M AA1 R T IH0 N S AH0 N'],
  "martinsville": ['M AA1 R T IH0 N Z V IH2 L'],
  "martiny": ['M AA1 R T IH0 N IY0'],
  "martion": ['M AA1 R SH Y AH0 N'],
  "martire": ['M AA0 R T IH1 R IY0'],
  "martirosov": ['M AA0 R T IH1 R AH0 S AA0 V'],
  "martis": ['M AA1 R T IH0 S'],
  "martita": ['M AA0 R T IY1 T AH0'],
  "martocci": ['M AA0 R T OW1 CH IY0'],
  "marton": ['M AA1 R T AH0 N'],
  "martone": ['M AA0 R T OW1 N IY0'],
  "martorana": ['M AA0 R T AO0 R AE1 N AH0'],
  "martorano": ['M AA0 R T AO0 R AA1 N OW0'],
  "martorelli": ['M AA0 R T AO0 R EH1 L IY0'],
  "marts": ['M AA1 R T S'],
  "martucci": ['M AA0 R T UW1 CH IY0'],
  "marturano": ['M AA0 R T UH0 R AA1 N OW0'],
  "martus": ['M AA1 R T AH0 S'],
  "marty": ['M AA1 R T IY0'],
  "marty's": ['M AA1 R T IY0 Z'],
  "martyn": ['M AA1 R T IH0 N'],
  "martyr": ['M AA1 R T ER0'],
  "martyrdom": ['M AA1 R T ER0 D AH0 M'],
  "martyre": ['M AA0 R T AY1 R'],
  "martyred": ['M AA1 R T ER0 D'],
  "martyrs": ['M AA1 R T ER0 Z'],
  "martz": ['M AA1 R T S'],
  "martz's": ['M AA1 R T S IH0 Z'],
  "marubeni": ['M AA2 R UW0 B IY1 N IY0'],
  "maruca": ['M ER0 UW1 K AH0'],
  "marucci": ['M AA0 R UW1 CH IY0'],
  "marui": ['M ER0 UW1 IY0'],
  "marukin": ['M ER0 UW1 K IH0 N'],
  "marulanda": ['M AE2 R UW0 L AE1 N D AH0'],
  "marullo": ['M AA0 R UW1 L OW0'],
  "maruska": ['M ER0 AH1 S K AH0'],
  "maruti": ['M ER0 UW1 T IY0'],
  "maruyama": ['M AA2 R UW0 Y AA1 M AH0'],
  "maruyu": ['M EH1 R UW0 Y UW0'],
  "marv": ['M AA1 R V'],
  "marva": ['M AA1 R V AH0'],
  "marvel": ['M AA1 R V AH0 L'],
  "marvel's": ['M AA1 R V AH0 L Z'],
  "marvela": ['M AA0 R V EY1 L AH0'],
  "marveled": ['M AA1 R V EH0 L D'],
  "marveling": ['M AA1 R V AH0 L IH0 NG'],
  "marvell": ['M AA0 R V EH1 L'],
  "marvella": ['M AA2 R V EH1 L AH0'],
  "marvelle": ['M AA0 R V EH1 L'],
  "marvelled": ['M AA0 R V EH1 L D'],
  "marvellous": ['M AA1 R V AH0 L AH0 S'],
  "marvelous": ['M AA1 R V AH0 L AH0 S'],
  "marvelously": ['M AA1 R V AH0 L AH0 S L IY0'],
  "marvels": ['M AA1 R V AH0 L Z'],
  "marver": ['M AA1 R V ER0'],
  "marvin": ['M AA1 R V IH0 N'],
  "marvin's": ['M AA1 R V IH0 N Z'],
  "marwick": ['M AA1 R W IH2 K'],
  "marwick's": ['M AA1 R W IH2 K S'],
  "marwin": ['M AA1 R W IH0 N'],
  "marwood": ['M AA1 R W UH2 D'],
  "marx": ['M AA1 R K S'],
  "marxen": ['M AA1 R K S AH0 N'],
  "marxism": ['M AA1 R K S IH0 Z AH0 M'],
  "marxist": ['M AA1 R K S AH0 S T', 'M AA1 R K S IH0 S T'],
  "marxists": ['M AA1 R K S IH0 S T S'],
  "mary": ['M EH1 R IY0'],
  "mary's": ['M EH1 R IY0 Z'],
  "marya": ['M AA1 R Y AH0'],
  "maryalice": ['M EH0 R IY0 AE1 L AH0 S'],
  "maryann": ['M EH0 R IY0 AE1 N'],
  "maryanne": ['M EH0 R IY0 AE1 N'],
  "marybeth": ['M EH1 R IY0 B EH1 TH'],
  "maryland": ['M EH1 R AH0 L AH0 N D'],
  "maryland's": ['M EH1 R AH0 L AH0 N D Z'],
  "marylebone": ['M ER1 L AH0 B OW0 N'],
  "marylin": ['M AE1 R IH0 L IH0 N'],
  "marylou": ['M AE1 R IY0 L UW0'],
  "marymount": ['M EH1 R IY0 M AW2 N T'],
  "marymount's": ['M EH1 R IY0 M AW2 N T S'],
  "maryruth": ['M AE1 R IH0 R UW0 TH'],
  "maryse": ['M AA0 R IY1 S'],
  "marysville": ['M EH1 R IY0 Z V IH2 L'],
  "maryville": ['M EH1 R IY0 V IH2 L'],
  "marz": ['M AA1 R Z'],
  "marzan": ['M AA1 R Z AH0 N'],
  "marzano": ['M AA0 R Z AA1 N OW2'],
  "marzec": ['M AA1 R Z IH0 K'],
  "marzette": ['M AA2 R Z EH1 T'],
  "marzilli": ['M AA0 R Z IY1 L IY2'],
  "marzipan": ['M AA1 R Z AH0 P AE0 N', 'M AA1 R T S AH0 P AA0 N', 'M AA1 R T S AH0 P AE0 N'],
  "marzo": ['M AA1 R Z OW2'],
  "marzolf": ['M AA1 R Z OW2 L F'],
  "marzotto": ['M AA2 R Z AA1 T OW2'],
  "marzullo": ['M AA0 R Z UW1 L OW2'],
  "mas": ['M AA1 Z'],
  "masa": ['M AA1 S AA0'],
  "masaaki": ['M AA2 S AA0 AA1 K IY2'],
  "masahiko": ['M AA2 S AA0 HH IY1 K OW2'],
  "masahiro": ['M AA2 S AA0 HH IH1 R OW2'],
  "masai": ['M AA0 S AA1 IY0'],
  "masakazu": ['M AA2 S AA0 K AA1 Z UW2'],
  "masaki": ['M AA0 S AA1 K IY2'],
  "masako": ['M AH0 S AA1 K OW2'],
  "masami": ['M AA0 S AA1 M IY0'],
  "masamilla": ['M AA0 S AH0 M IH1 L AA2'],
  "masao": ['M AA0 S AA1 OW2'],
  "masaru": ['M AA0 S AA1 R UW2'],
  "masaya": ['M AA0 S AA1 Y AA2'],
  "masayoshi": ['M AA2 S AA0 Y OW1 SH IY2'],
  "masayuki": ['M AA2 S AA0 Y UW1 K IY2'],
  "masback": ['M AA1 S B AE2 K'],
  "mascara": ['M AE0 S K EH1 R AH0'],
  "mascarena": ['M AE1 S K ER0 IH0 N AA2', 'M AE2 K AH0 R IY1 N AA2'],
  "mascarenas": ['M AE1 S K ER0 IH0 N AH0 Z', 'M AE2 K AH0 R IY1 N AH0 Z'],
  "mascari": ['M AA0 S K AA1 R IY2'],
  "mascaro": ['M AA0 S K AA1 R OW2'],
  "masch": ['M AE1 SH'],
  "maschino": ['M AA0 S K IY1 N OW2'],
  "maschke": ['M AE1 SH K'],
  "masci": ['M AE1 S IY2'],
  "mascia": ['M AA1 S CH AA2'],
  "masciarelli": ['M AA0 S CH ER0 EH1 L IY2'],
  "mascio": ['M AE1 S IY0 OW0'],
  "mascioli": ['M AA0 S CH OW1 L IY0'],
  "masco": ['M AE1 S K OW0'],
  "mascola": ['M AA0 S K OW1 L AH0'],
  "mascolo": ['M AA0 S K OW1 L OW0'],
  "mascorro": ['M AA0 S K AO1 R OW0'],
  "mascot": ['M AE1 S K AH0 T', 'M AE1 S K AA2 T'],
  "mascots": ['M AE1 S K AA2 T S'],
  "masculine": ['M AE1 S K Y AH0 L AH0 N'],
  "masculinity": ['M AE2 S K Y AH0 L IH1 N AH0 T IY0'],
  "masden": ['M AE1 S D AH0 N'],
  "mase": ['M EY1 Z'],
  "masefield": ['M EY1 Z F IY2 L D'],
  "masek": ['M AA1 S EH0 K'],
  "masella": ['M AH0 S EH1 L AH0'],
  "maselli": ['M AH0 S EH1 L IY0'],
  "maseng": ['M AE1 S IH0 NG'],
  "maser": ['M EY1 Z ER0'],
  "maserati": ['M AE2 S EH0 R AA1 T IY0'],
  "maseri": ['M AH0 S EH1 R IY0'],
  "mash": ['M AE1 SH'],
  "mashalu": ['M AH0 SH AE1 L UW0'],
  "mashantucket": ['M AE2 SH AH0 N T AH1 K AH0 T'],
  "mashaw": ['M AE1 SH AO0'],
  "mashburn": ['M AE1 SH B ER2 N'],
  "mashed": ['M AE1 SH T'],
  "mashek": ['M AE1 SH IH0 K'],
  "masi": ['M AA1 S IY0'],
  "masiello": ['M AA0 S IY0 EH1 L OW0'],
  "masin": ['M AA1 S IH0 N'],
  "masini": ['M AA0 S IY1 N IY0'],
  "masino": ['M AA0 S IY1 N OW0'],
  "masius": ['M AE1 S IY0 AH0 S'],
  "mask": ['M AE1 S K'],
  "maske": ['M EY1 S K'],
  "masked": ['M AE1 S K T'],
  "maskell": ['M AE1 S K AH0 L'],
  "masker": ['M AE1 S K ER0'],
  "masking": ['M AE1 S K IH0 NG'],
  "masks": ['M AE1 S K S'],
  "maslanka": ['M AH0 S L AE1 NG K AH0'],
  "maslen": ['M AE1 S AH0 L AH0 N'],
  "masley": ['M AE1 S L IY0'],
  "maslin": ['M AE1 Z L IH0 N'],
  "maslo": ['M AA1 S L AO0'],
  "maslow": ['M AA1 S L OW0'],
  "maslowski": ['M AH0 S L AO1 F S K IY0'],
  "maslyukov": ['M AE1 S L Y UW0 K AA2 V'],
  "maso": ['M AE1 Z OW0'],
  "masochism": ['M AE1 S AH0 K IH0 Z AH0 M'],
  "masochist": ['M AE1 S AH0 K IH0 S T'],
  "masochistic": ['M AE1 S AH0 K IH0 S T IH0 K'],
  "mason": ['M EY1 S AH0 N'],
  "mason's": ['M EY1 S AH0 N Z'],
  "masone": ['M AH0 S OW1 N'],
  "masoner": ['M EY1 S AH0 N ER0'],
  "masonic": ['M AH0 S AA1 N IH0 K'],
  "masonite": ['M AE1 S AH0 N AY2 T'],
  "masonry": ['M EY1 S AH0 N R IY0'],
  "masons": ['M EY1 S AH0 N Z'],
  "masood": ['M AH0 S UW1 D'],
  "masoud": ['M AH0 S UW1 D'],
  "masquerade": ['M AE2 S K ER0 EY1 D'],
  "masquerading": ['M AE2 S K ER0 EY1 D IH0 NG'],
  "masri": ['M AA1 S R IY0'],
  "mass": ['M AE1 S'],
  "mass.": ['M AE1 S', 'M AE2 S AH0 CH UW1 S AH0 T S'],
  "massa": ['M AE1 S AH0'],
  "massachusetts": ['M AE2 S AH0 CH UW1 S AH0 T S'],
  "massachusetts'": ['M AE2 S AH0 CH UW1 S AH0 T S'],
  "massachussetts": ['M AE2 S AH0 CH UW1 S AH0 T S'],
  "massacre": ['M AE1 S AH0 K ER0'],
  "massacred": ['M AE1 S AH0 K ER0 D'],
  "massacres": ['M AE1 S IH0 K ER0 Z'],
  "massacring": ['M AE1 S AH0 K ER0 IH0 NG'],
  "massad": ['M AE1 S AH0 D'],
  "massage": ['M AH0 S AA1 ZH'],
  "massaged": ['M AH0 S AA1 ZH D'],
  "massager": ['M AH0 S AA1 ZH ER0'],
  "massagers": ['M AH0 S AA1 ZH ER0 Z'],
  "massages": ['M AH0 S AA1 ZH IH0 Z'],
  "massaging": ['M AH0 S AA1 ZH IH0 NG'],
  "massar": ['M AE1 S ER0'],
  "massari": ['M AA0 S AA1 R IY0'],
  "massaro": ['M AH0 S AA1 R OW0'],
  "massat": ['M AH0 S AA1 T'],
  "massbauch": ['M AE1 S B AA2 K'],
  "masse": ['M AE1 S'],
  "massed": ['M AE1 S T'],
  "massenburg": ['M AE1 S AH0 N B ER0 G'],
  "massenet": ['M AE1 S AH0 N EH2 T'],
  "massenet's": ['M AE1 S AH0 N EH2 T S'],
  "massengale": ['M AE1 S AH0 N G EY2 L'],
  "massengill": ['M AE1 S AH0 N G IH2 L'],
  "masser": ['M AE1 S ER0'],
  "massery": ['M AE1 S ER0 IY0'],
  "masses": ['M AE1 S AH0 Z', 'M AE1 S IH0 Z'],
  "massett": ['M AE1 S IH0 T'],
  "massey": ['M AE1 S IY0'],
  "massi": ['M AE1 S IY0'],
  "massicotte": ['M AE1 S IH0 K AO0 T'],
  "massie": ['M AE1 S IY0'],
  "massieu": ['M AE1 S IY0 UW0'],
  "massif": ['M AE0 S IY1 F', 'M AE1 S IH0 F'],
  "massimino": ['M AA0 S IY0 M IY1 N OW0'],
  "massimino's": ['M AE2 S IH0 M IY1 N OW0 Z'],
  "massimo": ['M AE1 S IH0 M OW2'],
  "massing": ['M AE1 S IH0 NG'],
  "massingale": ['M AA0 S IH0 NG G AA1 L IY0'],
  "massingill": ['M AE1 S IH0 NG G AH0 L'],
  "massive": ['M AE1 S IH0 V'],
  "massively": ['M AE1 S IH0 V L IY0'],
  "massman": ['M AE1 S M AH0 N'],
  "massmann": ['M AE1 S M AH0 N'],
  "massmutual": ['M AE1 S M Y UW1 CH UW0 AH0 L'],
  "masso": ['M AE1 S OW0'],
  "masson": ['M AE1 S AH0 N'],
  "massoni": ['M AA0 S OW1 N IY0'],
  "massoth": ['M AE1 S AH0 TH'],
  "massport": ['M AE1 S P AO2 R T'],
  "massucci": ['M AA0 S UW1 CH IY0'],
  "mast": ['M AE1 S T'],
  "mastandrea": ['M AA0 S T AA1 N D R IY0 AH0'],
  "mastectomies": ['M AE0 S T EH1 K T AH0 M IY0 Z'],
  "mastectomy": ['M AE0 S T EH1 K T AH0 M IY0'],
  "masted": ['M AE1 S T AH0 D', 'M AE1 S T IH0 D'],
  "mastel": ['M EY1 S T AH0 L'],
  "masteller": ['M EY1 S T AH0 L ER0'],
  "masten": ['M AE1 S T AH0 N'],
  "master": ['M AE1 S T ER0'],
  "master's": ['M AE1 S T ER0 Z'],
  "masterbate": ['M AE1 S T ER0 B EY2 T'],
  "masterbated": ['M AE1 S T ER0 B EY2 T AH0 D'],
  "masterbates": ['M AE1 S T ER0 B EY2 T S'],
  "masterbating": ['M AE1 S T ER0 B EY2 T IH0 NG'],
  "masterbation": ['M AE2 S T ER0 B EY1 SH AH0 N'],
  "mastercard": ['M AE1 S T ER0 K AA2 R D'],
  "mastercard's": ['M AE1 S T ER0 K AA2 R D Z'],
  "mastercards": ['M AE1 S T ER0 K AA2 R D Z'],
  "mastered": ['M AE1 S T ER0 D'],
  "masterful": ['M AE1 S T ER0 F AH0 L'],
  "masterfully": ['M AE1 S T ER0 F AH0 L IY0'],
  "mastergate": ['M AE1 S T ER0 G EY2 T'],
  "mastering": ['M AE1 S T ER0 IH0 NG'],
  "masterly": ['M AE1 S T ER0 L IY0'],
  "masterman": ['M AE1 S T ER0 M AH0 N'],
  "mastermind": ['M AE1 S T ER0 M AY2 N D'],
  "masterminded": ['M AE1 S T ER0 M AY2 N D IH0 D'],
  "masterminding": ['M AE1 S T ER0 M AY2 N D IH0 NG'],
  "masterminds": ['M AE1 S T ER0 M AY2 N D Z'],
  "masterpiece": ['M AE1 S T ER0 P IY2 S'],
  "masterpieces": ['M AE1 S T ER0 P IY2 S AH0 Z', 'M AE1 S T ER0 P IY2 S IH0 Z'],
  "masters": ['M AE1 S T ER0 Z'],
  "masters'": ['M AE1 S T ER0 Z'],
  "masterson": ['M AE1 S T ER0 S AH0 N'],
  "masterstroke": ['M AE1 S T ER0 S T R OW2 K'],
  "masterwork": ['M AE1 S T ER0 W ER2 K'],
  "masterworks": ['M AE1 S T ER0 W ER2 K S'],
  "mastery": ['M AE1 S T ER0 IY0'],
  "masthead": ['M AE1 S T HH EH2 D'],
  "mastif": ['M AE1 S T AH0 F'],
  "mastif's": ['M AE1 S T AH0 F S'],
  "mastiff": ['M AE1 S T AH0 F'],
  "mastifs": ['M AE1 S T AH0 F S'],
  "mastin": ['M AE1 S T IH0 N'],
  "mastodon": ['M AE1 S T AH0 D AO2 N'],
  "mastoiditis": ['M AE2 S T OY0 D AY1 T AH0 S'],
  "maston": ['M AE1 S T AH0 N'],
  "mastrangelo": ['M AA0 S T R AA0 NG G EH1 L OW0'],
  "mastrianni": ['M AA0 S T R IY0 AA1 N IY0'],
  "mastriano": ['M AA0 S T R IY0 AA1 N OW0'],
  "mastrich": ['M AE1 S T R IH0 CH', 'M AA1 S T R IH0 K'],
  "mastrich's": ['M AE1 S T R IH0 CH IH0 Z', 'M AA1 S T R IH0 K S'],
  "mastro": ['M AE1 S T R OW0'],
  "mastrocola": ['M AE0 S T R OW0 K OW1 L AH0'],
  "mastrogiovanni": ['M AE0 S T R OW0 JH OW0 V AA1 N IY0'],
  "mastroianni": ['M AE0 S T R OW0 Y AA1 N IY0'],
  "mastronardi": ['M AE0 S T R OW0 N AA1 R D IY0'],
  "mastropietro": ['M AE0 S T R OW0 P IY1 T R OW0'],
  "masts": ['M AE1 S T S'],
  "masturbate": ['M AE1 S T ER0 B EY2 T'],
  "masturbated": ['M AE1 S T ER0 B EY2 T IH0 D'],
  "masturbates": ['M AE1 S T ER0 B EY2 T S'],
  "masturbating": ['M AE1 S T ER0 B EY2 T IH0 NG'],
  "masturbation": ['M AE2 S T ER0 B EY1 SH AH0 N'],
  "masucci": ['M AA0 S UW1 CH IY0'],
  "masuda": ['M AH0 S UW1 D AH0'],
  "masullo": ['M AA0 S UW1 L OW0'],
  "masur": ['M AE1 S ER0'],
  "mat": ['M AE1 T'],
  "mata": ['M AA1 T AH0'],
  "matabane": ['M AE1 T AH0 B EY2 N'],
  "matador": ['M AE1 T AH0 D AO2 R'],
  "matagorda": ['M AE2 T AH0 G AO1 R D AH0'],
  "mataksas": ['M AH0 T AE1 K S AH0 S'],
  "matala": ['M AH0 T AA1 L AH0'],
  "matala's": ['M AH0 T AA1 L AH0 Z'],
  "matalin": ['M AE1 T AH0 L IH0 N', 'M AE1 T L IH0 N'],
  "matalon": ['M AA0 T AA0 L AO1 N'],
  "matamoros": ['M AA0 T AA0 M AO1 R OW0 S'],
  "matarazzo": ['M AA0 T AA0 R AA1 Z OW0'],
  "matarese": ['M AA0 T AA0 R EY1 Z IY0'],
  "matas": ['M AA1 T AH0 Z'],
  "matassa": ['M AA0 T AA1 S AH0'],
  "mataya": ['M AA0 T AA1 Y AH0'],
  "match": ['M AE1 CH'],
  "match's": ['M AE1 CH IH0 Z'],
  "matchbook": ['M AE1 CH B UH2 K'],
  "matchbooks": ['M AE1 CH B UH2 K S'],
  "matchbox": ['M AE1 CH B AA2 K S'],
  "matchboxes": ['M AE1 CH B AA2 K S IH0 Z'],
  "matched": ['M AE1 CH T'],
  "matches": ['M AE1 CH AH0 Z', 'M AE1 CH IH0 Z'],
  "matchett": ['M AE1 CH IH0 T'],
  "matching": ['M AE1 CH IH0 NG'],
  "matchlock": ['M AE1 CH L AA2 K'],
  "matchlocks": ['M AE1 CH L AA2 K S'],
  "matchmaker": ['M AE1 CH M EY2 K ER0'],
  "matchmakers": ['M AE1 CH M EY2 K ER0 Z'],
  "matchmaking": ['M AE1 CH M EY2 K IH0 NG'],
  "matchup": ['M AE1 CH AH0 P'],
  "matchups": ['M AE1 CH AH0 P S'],
  "mate": ['M EY1 T'],
  "matecki": ['M AH0 T EH1 K IY0'],
  "mated": ['M EY1 T IH0 D'],
  "mateer": ['M AH0 T IH1 R'],
  "mateja": ['M AA0 T EY1 Y AH0'],
  "matejka": ['M AH0 T EY1 K AH0'],
  "matell": ['M AH0 T EH1 L'],
  "matelski": ['M AH0 T EH1 L S K IY0'],
  "mateo": ['M AA0 T EY1 OW0'],
  "mater": ['M AA1 T ER0', 'M EY1 T ER0', 'M AE1 T ER0'],
  "matera": ['M AA0 T EH1 R AH0'],
  "material": ['M AH0 T IH1 R IY0 AH0 L'],
  "materialism": ['M AH0 T IH1 R IY0 AH0 L IH2 Z AH0 M'],
  "materialist": ['M AH0 T IH1 R IY0 AH0 L IH0 S T'],
  "materialistic": ['M AH0 T IH2 R IY0 AH0 L IH1 S T IH0 K'],
  "materiality": ['M AH0 T IH2 R IY0 AE1 L IH0 T IY0'],
  "materialize": ['M AH0 T IH1 R IY0 AH0 L AY2 Z'],
  "materialized": ['M AH0 T IH1 R IY0 AH0 L AY2 Z D'],
  "materializes": ['M AH0 T IH1 R IY0 AH0 L AY2 Z IH0 Z'],
  "materializing": ['M AH0 T IH1 R IY0 AH0 L AY2 Z IH0 NG'],
  "materially": ['M AH0 T IH1 R IY0 AH0 L IY0'],
  "materials": ['M AH0 T IH1 R IY0 AH0 L Z'],
  "materials'": ['M AH0 T IH1 R IY0 AH0 L Z'],
  "materiel": ['M AH0 T IH2 R IY0 EH1 L'],
  "materiels": ['M AH0 T IH2 R IY0 EH1 L Z'],
  "matern": ['M AE1 T ER0 N'],
  "materna": ['M AA0 T EH1 R N AH0'],
  "maternal": ['M AH0 T ER1 N AH0 L'],
  "maternity": ['M AH0 T ER1 N IH0 T IY0'],
  "mates": ['M EY1 T S'],
  "matewan": ['M AE1 T UW2 AA0 N', 'M EY1 T W AA2 N'],
  "matey": ['M EY1 T IY0'],
  "math": ['M AE1 TH'],
  "mathai": ['M AA0 DH AA1 IY0'],
  "mathan": ['M EY1 TH AH0 N'],
  "mathe": ['M EY1 DH'],
  "mathea": ['M AE1 DH IY0 AH0'],
  "matheis": ['M AE1 DH AY0 Z'],
  "mathematic": ['M AE2 TH AH0 M AE1 T IH0 K'],
  "mathematical": ['M AE2 TH AH0 M AE1 T IH0 K AH0 L'],
  "mathematically": ['M AE2 TH AH0 M AE1 T IH0 K AH0 L IY0', 'M AE2 TH AH0 M AE1 T IH0 K L IY0'],
  "mathematician": ['M AE2 TH AH0 M AH0 T IH1 SH AH0 N'],
  "mathematicians": ['M AE2 TH AH0 M AH0 T IH1 SH AH0 N Z'],
  "mathematics": ['M AE2 TH AH0 M AE1 T IH0 K S'],
  "mathena": ['M AE1 TH IH0 N AH0'],
  "matheney": ['M AE1 TH IH0 N IY0'],
  "matheny": ['M AE1 TH IH0 N IY0'],
  "mather": ['M AE1 DH ER0'],
  "matherly": ['M AE1 DH ER0 L IY0'],
  "mathern": ['M AE1 DH ER0 N'],
  "matherne": ['M AE1 TH ER0 N'],
  "mathers": ['M AE1 DH ER0 Z'],
  "matherson": ['M AE1 TH ER0 S AH0 N'],
  "mathes": ['M AE1 TH IH0 S'],
  "matheson": ['M AE1 TH AH0 S AH0 N'],
  "matheson's": ['M AE1 TH AH0 S AH0 N Z'],
  "matheus": ['M AE1 DH OY0 Z'],
  "mathew": ['M AE1 TH Y UW0'],
  "mathews": ['M AE1 TH Y UW0 Z'],
  "mathewson": ['M AE1 TH Y UW0 S AH0 N'],
  "mathey": ['M AE1 DH IY0'],
  "mathia": ['M AA1 DH IY0 AH0'],
  "mathias": ['M AH0 TH AY1 AH0 S', 'M AH0 T IY1 AH0 S'],
  "mathiasen": ['M AH0 TH AY1 AH0 S AH0 N'],
  "mathiason": ['M AH0 TH AY1 AH0 S AH0 N'],
  "mathie": ['M AE1 TH IY0'],
  "mathies": ['M AE1 TH IY0 Z'],
  "mathiesen": ['M AE1 TH IY0 S AH0 N'],
  "mathieson": ['M AH0 TH IY1 Z AH0 N'],
  "mathieu": ['M AE1 TH Y UW0'],
  "mathilda": ['M AH0 T IH1 L D AH0'],
  "mathilde": ['M AE1 TH IH0 L D', 'M AH0 T IH1 L D'],
  "mathis": ['M AE1 TH IH0 S'],
  "mathisen": ['M AE1 TH AY0 Z AH0 N'],
  "mathison": ['M AE1 TH IH0 S AH0 N'],
  "mathur": ['M AE1 TH ER0'],
  "mathwig": ['M AE1 TH W IH0 G'],
  "mathy": ['M AE1 TH IY0'],
  "mathys": ['M EY1 TH IY0 Z'],
  "matias": ['M AA1 SH AH0 Z'],
  "matic": ['M AE1 T IH0 K'],
  "matif": ['M AA0 T IY1 F'],
  "matilda": ['M AH0 T IH1 L D AH0'],
  "matinee": ['M AE1 T IH0 N EY2'],
  "matinees": ['M AE2 T IH0 N EY1 Z'],
  "mating": ['M EY1 T IH0 NG'],
  "mation": ['M AE1 T Y AH0 N', 'M EY1 SH AH0 N'],
  "matis": ['M AA1 T IH0 S'],
  "matisse": ['M AE1 T IY0 S'],
  "matisse's": ['M AE1 T IY0 S IH0 Z'],
  "matkin": ['M AE1 T K IH0 N'],
  "matkins": ['M AE1 T K IH0 N Z'],
  "matkovich": ['M AA1 T K AH0 V IH0 CH'],
  "matlack": ['M AE1 T L AE2 K'],
  "matlick": ['M AE1 T L IH0 K'],
  "matlin": ['M AE1 T L IH0 N'],
  "matlock": ['M AE1 T L AA2 K'],
  "matney": ['M AE1 T N IY0'],
  "mato": ['M AA1 T OW0'],
  "matoco": ['M AH0 T AO1 K OW0'],
  "matola": ['M AH0 T OW1 L AH0'],
  "matos": ['M AA1 T OW0 Z'],
  "matott": ['M AE1 T AH0 T'],
  "matousek": ['M AH0 T UW1 S EH0 K'],
  "matra": ['M AE1 T R AH0'],
  "matra's": ['M AE1 T R AH0 Z'],
  "matranga": ['M AA0 T R AA1 NG G AH0'],
  "matriarch": ['M EY1 T R IY0 AA2 R K'],
  "matriarchal": ['M EY2 T R IY0 AA1 R K AH0 L'],
  "matriarchy": ['M EY1 T R IY0 AA2 R K IY0'],
  "matrice": ['M EY1 T R IH0 S'],
  "matrices": ['M EY1 T R IH0 S IH0 Z'],
  "matriculate": ['M AH0 T R IH1 K Y AH0 L EY0 T'],
  "matriculated": ['M AH0 T R IH1 K Y AH0 L EY0 T AH0 D'],
  "matriculates": ['M AH0 T R IH1 K Y AH0 L EY0 T S'],
  "matriculating": ['M AH0 T R IH1 K Y AH0 L EY0 T IH0 NG'],
  "matrilineal": ['M AE2 T R IH0 L IH1 N IY0 AH0 L'],
  "matrimonial": ['M AE2 T R AH0 M OW1 N IY0 AH0 L'],
  "matrimony": ['M AE1 T R AH0 M OW2 N IY0'],
  "matrix": ['M EY1 T R IH0 K S'],
  "matrix's": ['M EY1 T R IH0 K S IH0 Z'],
  "matrixes": ['M EY1 T R IH0 K S IH0 Z'],
  "matron": ['M EY1 T R AH0 N'],
  "matronly": ['M EY1 T R AH0 N L IY0'],
  "matrons": ['M EY1 T R AH0 N Z'],
  "mats": ['M AE1 T S'],
  "matsapa": ['M AE0 T S AA1 P AH0'],
  "matsch": ['M AE1 CH'],
  "matsch's": ['M AE1 CH AH0 Z'],
  "matscher": ['M AE1 CH ER0'],
  "matsen": ['M AA1 T S AH0 N'],
  "matsko": ['M AA1 T S K OW0'],
  "matson": ['M AA1 T S AH0 N'],
  "matsu-ya": ['M AE2 T S UW1 Y AH0'],
  "matsubara": ['M AA2 T S UW2 B AA1 R AH0'],
  "matsuda": ['M AA0 T S UW1 D AH0'],
  "matsui": ['M AA0 T S UW1 IY0'],
  "matsukawa": ['M AA2 T S UW2 K AA1 W AH0'],
  "matsumoto": ['M AA0 T S UW0 M OW1 T OW0'],
  "matsumura": ['M AA0 T S UW0 M UH1 R AH0'],
  "matsunaga": ['M AA0 T S UW0 N AA1 G AH0'],
  "matsuo": ['M AA0 T S UW1 OW0'],
  "matsuoka": ['M AA0 T S UW0 OW1 K AH0'],
  "matsushita": ['M AA0 T S UW0 SH IY1 T AH0', 'M AA0 T S UW1 SH IY0 T AH0'],
  "matsushita's": ['M AA2 T S UW0 SH IY1 T AH0 Z', 'M AA0 T S UW1 SH IY0 T AH0 Z'],
  "matsuura": ['M AA0 T S UW0 UH1 R AH0'],
  "matt": ['M AE1 T'],
  "matt's": ['M AE1 T Z'],
  "matta": ['M AE1 T AH0'],
  "matte": ['M AE1 T'],
  "mattea": ['M AE1 SH AH0'],
  "mattei": ['M AE1 T AY0'],
  "matteis": ['M AH0 T AY1 S'],
  "mattel": ['M AH0 T EH1 L'],
  "mattel's": ['M AH0 T EH1 L Z'],
  "matteo": ['M AA0 T EY1 OW0'],
  "matter": ['M AE1 T ER0'],
  "matter's": ['M AE1 T ER0 Z'],
  "mattera": ['M AA0 T EH1 R AH0'],
  "matterease": ['M AE1 T ER0 IY2 Z', 'M AH0 T R IY1 Z IY0'],
  "mattered": ['M AE1 T ER0 D'],
  "mattern": ['M AE1 T ER0 N'],
  "matters": ['M AE1 T ER0 Z'],
  "mattes": ['M AE1 T S'],
  "matteson": ['M AE1 T IH0 S AH0 N'],
  "matteucci": ['M AA0 T UW1 CH IY0'],
  "matthau": ['M AE1 T HH AW0'],
  "matthausen": ['M AE1 T HH AW0 Z AH0 N'],
  "matthea": ['M AE1 T DH IY0 AH0'],
  "mattheis": ['M AE1 T DH AY0 Z'],
  "matthes": ['M AE1 TH IH0 Z'],
  "matthew": ['M AE1 TH Y UW0'],
  "matthew's": ['M AE1 TH Y UW0 Z'],
  "matthews": ['M AE1 TH Y UW0 Z'],
  "matthews's": ['M AE1 TH Y UW0 Z IH0 Z'],
  "matthewson": ['M AE1 TH Y UW0 S AH0 N'],
  "matthey": ['M AE1 T DH IY0'],
  "matthia": ['M AA1 T DH IY0 AH0'],
  "matthias": ['M AH0 TH AY1 AH0 S'],
  "matthies": ['M AE1 TH IY0 Z'],
  "matthiesen": ['M AE1 TH IY0 S AH0 N'],
  "matthis": ['M AE1 TH IH0 S'],
  "matthys": ['M AE1 TH IY0 Z'],
  "mattias": ['M AE1 SH AH0 Z'],
  "mattice": ['M AA1 T IH0 S'],
  "mattick": ['M AE1 T IH0 K'],
  "mattie": ['M AE1 T IY0'],
  "mattikalli": ['M AE2 T IH2 K AA1 L IY0'],
  "mattila": ['M AA0 T IY1 L AH0'],
  "mattina": ['M AA0 T IY1 N AH0'],
  "matting": ['M AE1 T IH0 NG'],
  "mattingley": ['M AE1 T IH0 NG G L IY0'],
  "mattingly": ['M AE1 T IH0 NG L IY0'],
  "mattioli": ['M AA0 T IY0 OW1 L IY0'],
  "mattis": ['M AE1 T IH0 S'],
  "mattison": ['M AE1 T IH0 S AH0 N'],
  "mattke": ['M AE1 T K IY0'],
  "mattocks": ['M AE1 T AH0 K S'],
  "matton": ['M AE1 T AH0 N'],
  "mattoon": ['M AH0 T UW1 N'],
  "mattos": ['M AE1 T OW0 Z'],
  "mattox": ['M AE1 T AH0 K S', 'M AE1 T AA2 K S'],
  "mattress": ['M AE1 T R AH0 S'],
  "mattress's": ['M AE1 T R AH0 S IH0 Z'],
  "mattresses": ['M AE1 T R AH0 S AH0 Z', 'M AE1 T R AH0 S IH0 Z'],
  "matts": ['M AE1 T S'],
  "mattson": ['M AE1 T S AH0 N'],
  "mattucci": ['M AA0 T UW1 CH IY0'],
  "matty": ['M AE1 T IY0'],
  "matula": ['M AA0 T UW1 L AH0'],
  "matulich": ['M AH0 T Y UW1 L IH0 HH'],
  "matulis": ['M AE1 T AH0 L IH0 S'],
  "maturation": ['M AE2 CH ER0 EY1 SH AH0 N', 'M AE2 CH UH0 R EY1 SH AH0 N'],
  "mature": ['M AH0 CH UH1 R', 'M AH0 T Y UH1 R'],
  "matured": ['M AH0 T Y UH1 R D'],
  "matures": ['M AH0 CH UH1 R Z', 'M AH0 T Y UH1 R Z'],
  "maturing": ['M AH0 CH UH1 R IH0 NG', 'M AH0 T Y UH1 R IH0 NG'],
  "maturities": ['M AH0 CH UH1 R IH0 T IY0 Z'],
  "maturity": ['M AH0 CH UH1 R AH0 T IY0', 'M AH0 T Y UH1 R IH0 T IY0'],
  "maturo": ['M AA0 T UH1 R OW0'],
  "matus": ['M AE1 T IH0 S'],
  "matuschka": ['M AH0 T AH1 SH K AH0'],
  "matusek": ['M AH0 T UW1 S EH0 K'],
  "matusi": ['M AH0 T UW1 S IY0'],
  "matusiak": ['M AH0 T AH1 S IY0 AE0 K'],
  "matusik": ['M AH0 T UW1 S IH0 K'],
  "matuska": ['M AH0 T AH1 S K AH0'],
  "matussi": ['M AH0 T UW1 S IY0'],
  "matuszak": ['M AH0 T AH1 SH AH0 K'],
  "matuszewski": ['M AH0 T AH0 SH EH1 F S K IY0'],
  "matyas": ['M AE1 T Y AH0 S'],
  "matz": ['M AE1 T S'],
  "matza": ['M AA1 T Z AH0'],
  "matzek": ['M AE1 T Z IH0 K'],
  "matzen": ['M AE1 T Z AH0 N'],
  "matzinger": ['M EY1 T S IH0 NG ER0'],
  "matzke": ['M AE1 T S K IY0'],
  "matzner": ['M AE1 T S N ER0'],
  "matzoh": ['M AE1 T S AH0'],
  "matzu": ['M AE1 T Z UW0'],
  "mau": ['M OW1', 'M AW1'],
  "mauceri": ['M AO0 CH EH1 R IY0'],
  "mauch": ['M AO1 CH'],
  "maucher": ['M AW1 CH ER0', 'M AA1 K ER0'],
  "mauck": ['M AO1 K'],
  "maud": ['M AO1 D'],
  "maude": ['M AO1 D'],
  "maudlin": ['M AO1 D L IH0 N'],
  "maue": ['M AW1 IY0'],
  "mauer": ['M AW1 ER0'],
  "mauger": ['M AO1 G ER0'],
  "maugeri": ['M AO0 JH EH1 R IY0'],
  "maughan": ['M AO1 G AH0 N'],
  "maui": ['M AW1 IY0'],
  "mauk": ['M AO1 K'],
  "maul": ['M AO1 L'],
  "maulden": ['M AW1 L D AH0 N'],
  "mauldin": ['M AW1 L D AH0 N'],
  "maulding": ['M AO1 L D IH0 NG'],
  "maule": ['M AO1 L'],
  "mauled": ['M AO1 L D'],
  "mauler": ['M AO1 L ER0'],
  "maull": ['M AO1 L'],
  "maulsby": ['M AO1 L S B IY0'],
  "mault": ['M AO1 L T'],
  "maultasch": ['M AO1 L T AE0 SH'],
  "maultsby": ['M AO1 L T S B IY0'],
  "maumee": ['M AW1 M IY0'],
  "mauna": ['M AO1 N AH0'],
  "maund": ['M AO1 N D'],
  "maundering": ['M AO1 N D ER0 IH0 NG'],
  "maunderings": ['M AO1 N D ER0 IH0 NG Z'],
  "maune": ['M AO1 N'],
  "mauney": ['M AO1 N IY0'],
  "maung": ['M AO1 NG'],
  "maupin": ['M OW0 P AE1 N'],
  "maura": ['M AO1 R AH0'],
  "maura's": ['M AO1 R AH0 Z'],
  "maureen": ['M AO0 R IY1 N'],
  "maurer": ['M AO1 R ER0'],
  "mauri": ['M AO1 R IY0'],
  "maurice": ['M AO0 R IY1 S', 'M AA1 R IH0 S'],
  "maurice's": ['M AO1 R AH0 S AH0 Z', 'M AO0 R IY1 S AH0 Z'],
  "mauricio": ['M AW0 R IY1 S IY0 OW0'],
  "maurie": ['M AO1 R IY0'],
  "mauriello": ['M AO2 R IY0 EH1 L OW0'],
  "maurin": ['M AO1 R IH0 N'],
  "maurine": ['M AO0 R IY1 N IY0'],
  "maurita": ['M AO0 R IY1 T AH0'],
  "mauritania": ['M AO2 R IH0 T EY1 N IY0 AH0', 'M AO2 R IH0 T EY1 N Y AH0'],
  "mauritius": ['M AO0 R IH1 T IY0 AH0 S'],
  "mauritz": ['M AO1 R IH0 T S'],
  "maurizio": ['M AO0 R IH1 Z IY0 OW0'],
  "mauro": ['M AO1 R OW0'],
  "maurus": ['M AO1 R AH0 S'],
  "maury": ['M AO1 R IY0'],
  "maus": ['M AO1 Z'],
  "mauser": ['M AW1 S ER0'],
  "mausoleum": ['M AO2 S AH0 L IY1 AH0 M'],
  "mausolf": ['M AW1 S OW0 L F'],
  "mauss": ['M AO1 S'],
  "mausser": ['M AW1 S ER0'],
  "maust": ['M AO1 S T'],
  "maute": ['M AO1 T'],
  "mauthe": ['M AO1 DH'],
  "mautner": ['M AO1 T N ER0'],
  "mautz": ['M AO1 T S'],
  "mauve": ['M AO1 V'],
  "mauzey": ['M AO1 Z IY0'],
  "mauzy": ['M AO1 Z IY0'],
  "mav": ['M AA1 V'],
  "mavarach": ['M AH0 V AA1 R AH0 CH'],
  "mave": ['M EY1 V'],
  "maven": ['M EY1 V AH0 N'],
  "maven's": ['M EY1 V AH0 N Z'],
  "mavens": ['M EY1 V AH0 N Z'],
  "maverick": ['M AE1 V ER0 IH0 K', 'M AE1 V R IH0 K'],
  "maverick's": ['M AE1 V ER0 IH0 K S', 'M AE1 V R IH0 K S'],
  "mavericks": ['M AE1 V ER0 IH0 K S', 'M AE1 V R IH0 K S'],
  "maverix": ['M AE1 V ER0 IH0 K S'],
  "maves": ['M EY1 V Z'],
  "mavis": ['M EY1 V IH0 S'],
  "mavrodi": ['M AE0 V R OW1 D IY0'],
  "mavroules": ['M AE0 V R UW1 L Z'],
  "mavs": ['M AA1 V Z'],
  "mavs'": ['M AA1 V Z'],
  "maw": ['M AO1'],
  "mawhinney": ['M AE1 W IH0 N IY0'],
  "mawkish": ['M AO1 K IH0 SH'],
  "mawr": ['M AO1 ER0', 'M AA1 R'],
  "mawson": ['M AO1 S AH0 N'],
  "mawyer": ['M AO1 IY0 ER0'],
  "max": ['M AE1 K S'],
  "max's": ['M AE1 K S IH0 Z'],
  "maxa": ['M AE1 K S AH0'],
  "maxam": ['M AE1 K S AH0 M'],
  "maxcy": ['M AE1 K S IY0'],
  "maxed": ['M AE1 K S T'],
  "maxell": ['M AE0 K S EH1 L'],
  "maxey": ['M AE1 K S IY0'],
  "maxfield": ['M AE1 K S F IY0 L D'],
  "maxham": ['M AE1 K S AH0 M'],
  "maxi": ['M AE1 K S IY0'],
  "maxicare": ['M AE1 K S IY0 K EH1 R'],
  "maxie": ['M AE1 K S IY0'],
  "maxilla": ['M AE0 K S IH1 L AH0'],
  "maxillary": ['M AE0 K S IH1 L AH0 R IY2'],
  "maxim": ['M AE1 K S AH0 M'],
  "maxima": ['M AE1 K S AH0 M AH0'],
  "maximal": ['M AE1 K S AH0 M AH0 L'],
  "maxime": ['M AE0 K S IY1 M'],
  "maximilian": ['M AE2 K S IH0 M IH1 L IY0 AH0 N'],
  "maximization": ['M AE2 K S IH0 M IH0 Z EY1 SH AH0 N'],
  "maximize": ['M AE1 K S AH0 M AY2 Z'],
  "maximized": ['M AE1 K S AH0 M AY2 Z D'],
  "maximizes": ['M AE1 K S AH0 M AY2 Z IH0 Z'],
  "maximizing": ['M AE1 K S AH0 M AY2 Z IH0 NG'],
  "maxims": ['M AE1 K S AH0 M Z'],
  "maximum": ['M AE1 K S AH0 M AH0 M'],
  "maximus": ['M AE1 K S AH0 M AH0 S'],
  "maxine": ['M AE0 K S IY1 N'],
  "maxion": ['M AE1 K S IY0 AA0 N'],
  "maxis": ['M AE1 K S IY0 Z'],
  "maxon": ['M AE1 K S AH0 N'],
  "maxpharma": ['M AE0 K S F AA1 R M AH0'],
  "maxsaver": ['M AE2 K S EY1 V ER0'],
  "maxsavers": ['M AE2 K S EY1 V ER0 Z'],
  "maxson": ['M AE1 K S AH0 N'],
  "maxton": ['M AE1 K S T AH0 N'],
  "maxtor": ['M AE1 K S T ER0'],
  "maxus": ['M AE1 K S AH0 S'],
  "maxus's": ['M AE1 K S AH0 S IH0 Z'],
  "maxwell": ['M AE1 K S W EH2 L'],
  "maxwell's": ['M AE1 K S W EH2 L Z'],
  "maxx": ['M AE1 K S'],
  "maxxam": ['M AE1 K S AH0 M'],
  "maxxim": ['M AE1 K S IH0 M'],
  "maxy": ['M AE1 K S IY0'],
  "maxzide": ['M AE0 K S IY1 T AH0'],
  "may": ['M EY1'],
  "may's": ['M EY1 Z'],
  "maya": ['M AY1 AH0'],
  "mayaguez": ['M AY1 AH0 G EH2 Z'],
  "mayall": ['M EY1 AO2 L'],
  "mayan": ['M AY1 AH0 N'],
  "mayans": ['M AY1 AH0 N Z'],
  "maybe": ['M EY1 B IY0'],
  "maybee": ['M EY1 B IY2'],
  "maybelle": ['M EY2 B EH1 L'],
  "maybelline": ['M EY1 B AH0 L IY2 N'],
  "mayben": ['M EY1 B AH0 N'],
  "mayberry": ['M EY1 B EH2 R IY0'],
  "maybin": ['M EY1 B IH0 N'],
  "maybury": ['M EY1 B EH2 R IY0'],
  "maycock": ['M EY1 K AA2 K'],
  "mayda": ['M EY1 D AH0'],
  "mayday": ['M EY1 D EY0'],
  "mayde": ['M EY1 D'],
  "mayden": ['M EY1 D AH0 N'],
  "maydena": ['M EY1 D IH0 N AH0'],
  "maye": ['M EY1'],
  "mayeaux": ['M EY1 OW0'],
  "mayeda": ['M AY0 IY1 D AH0'],
  "mayer": ['M EY1 ER0'],
  "mayer's": ['M EY1 ER0 Z'],
  "mayernik": ['M EY0 ER1 N IH0 K'],
  "mayers": ['M EY1 ER0 Z'],
  "mayerson": ['M EY1 ER0 S AH0 N'],
  "mayes": ['M EY1 Z'],
  "mayeux": ['M EY0 OW1'],
  "mayfair": ['M EY1 F EH2 R'],
  "mayfield": ['M EY1 F IY2 L D', 'M EY1 F IH0 L D'],
  "mayflower": ['M EY1 F L AW2 ER0'],
  "mayhall": ['M EY1 HH AO2 L'],
  "mayhan": ['M EY1 AH0 N'],
  "mayhem": ['M EY1 HH EH2 M'],
  "mayher": ['M EY1 ER0'],
  "mayhew": ['M EY1 HH Y UW0'],
  "mayhue": ['M EY1 HH Y UW2'],
  "mayhugh": ['M EY1 HH AH0'],
  "mayland": ['M EY1 L AH0 N D'],
  "mayle": ['M EY1 L', 'M EY1 L IY0'],
  "mayme": ['M EY1 M'],
  "maynard": ['M EY1 N ER0 D'],
  "maynard's": ['M EY1 N ER0 D Z'],
  "mayne": ['M EY1 N'],
  "mayner": ['M EY1 N ER0'],
  "maynes": ['M EY1 N Z'],
  "maynor": ['M EY1 N ER0'],
  "mayo": ['M EY1 OW0'],
  "mayon": ['M EY0 AO1 N'],
  "mayonnaise": ['M EY1 AH0 N EY2 Z'],
  "mayor": ['M EY1 ER0'],
  "mayor's": ['M EY1 ER0 Z'],
  "mayoral": ['M EY1 ER0 AH0 L'],
  "mayoralty": ['M EY1 ER0 AH0 L T IY0'],
  "mayorga": ['M EY2 AO1 R G AH0'],
  "mayors": ['M EY1 ER0 Z'],
  "mayotte": ['M EY0 AO1 T'],
  "mayr": ['M EY1 R'],
  "mays": ['M EY1 Z'],
  "mays'": ['M EY1 Z'],
  "mayse": ['M EY1 Z'],
  "mayson": ['M EY1 Z AH0 N'],
  "maytag": ['M EY1 T AE2 G'],
  "mayton": ['M EY1 T AH0 N'],
  "mayville": ['M EY1 V IH2 L'],
  "mayweather": ['M EY1 W EH2 DH ER0'],
  "maz": ['M AA1 Z'],
  "maza": ['M AA1 Z AH0'],
  "mazaitis": ['M AH0 Z AY1 T IH0 S'],
  "mazanec": ['M AH0 Z AE1 N IH0 K'],
  "mazankowski": ['M AE2 Z AH0 NG K AW1 S K IY0'],
  "mazda": ['M AA1 Z D AH0', 'M AE1 Z D AH0'],
  "mazda's": ['M AA1 Z D AH0 Z', 'M AE1 Z D AH0 Z'],
  "maze": ['M EY1 Z'],
  "mazeika": ['M AH0 Z AY1 K AH0'],
  "mazel": ['M AH1 Z AH0 L'],
  "mazeltov": ['M AA1 Z AH0 L T AA2 F'],
  "mazer": ['M EY1 Z ER0'],
  "mazes": ['M EY1 Z IH0 Z'],
  "maziarz": ['M AH0 Z IY1 ER0 Z'],
  "mazie": ['M EY1 Z IY0'],
  "mazo": ['M AA1 Z OW0'],
  "mazola": ['M AH0 Z OW1 L AH0'],
  "mazon": ['M AA0 Z AO1 N', 'M AH0 Z OW1 N'],
  "mazone": ['M AH0 Z OW1 N'],
  "mazor": ['M AA0 Z AO1 R'],
  "mazowiecki": ['M AH0 Z OW2 IY0 EH1 K IY0'],
  "mazur": ['M AE1 Z ER0'],
  "mazurek": ['M AH0 Z Y UW1 R EH0 K'],
  "mazurkas": ['M AH0 Z ER1 K AH0 Z'],
  "mazurkiewicz": ['M AH0 Z ER1 K AH0 V IH0 CH'],
  "mazurowski": ['M AH0 Z ER0 AO1 F S K IY0'],
  "mazyck": ['M AE1 Z IH0 K'],
  "mazza": ['M AE1 Z AH0'],
  "mazzaferro": ['M AE2 Z AH0 F EH1 R OW0'],
  "mazzanti": ['M AA0 T S AA1 N T IY0'],
  "mazzara": ['M AA0 T S AA1 R AH0'],
  "mazzarella": ['M AA0 T S AA0 R EH1 L AH0'],
  "mazzei": ['M AA0 T S EY1 IY0'],
  "mazzella": ['M AA0 T S EH1 L AH0'],
  "mazzeo": ['M AE1 Z IY0 OW0'],
  "mazzetti": ['M AA0 T S EH1 T IY0'],
  "mazzie": ['M AE1 Z IY0'],
  "mazziotti": ['M AA0 T S IY0 OW1 T IY0'],
  "mazzo": ['M AE1 Z OW0'],
  "mazzocchi": ['M AA0 T S OW1 K IY0'],
  "mazzocco": ['M AA0 T S OW1 K OW0'],
  "mazzola": ['M AA0 T S OW1 L AH0'],
  "mazzola's": ['M AA0 T S OW1 L AH0 Z'],
  "mazzoli": ['M AH0 Z OW1 L IY0'],
  "mazzolla": ['M AH0 Z OW1 L AH0'],
  "mazzone": ['M AA0 T S OW1 N IY0'],
  "mazzoni": ['M AA0 T S OW1 N IY0'],
  "mazzorana": ['M AE2 Z ER0 AA1 N AH0'],
  "mazzotta": ['M AA0 T S OW1 T AH0'],
  "mazzuca": ['M AA0 T S UW1 K AH0'],
  "mazzucco": ['M AA0 T S UW1 K OW0'],
  "mba": ['EH1 M B IY1 EY1'],
  "mbank": ['EH1 M B AE1 NG K'],
  "mbeki": ['M B EH1 K IY0', 'AH0 M B EH1 K IY0', 'EH0 M B EH1 K IY0'],
  "mbira": ['M AH0 B IH1 R AH0'],
  "mc": ['M IH0 K', 'EH1 M S IY1'],
  "mcabee": ['M AH0 K AE1 B IY0', 'M AE1 K AH0 B IY0'],
  "mcadam": ['M AH0 K AE1 D AH0 M'],
  "mcadams": ['M AH0 K AE1 D AH0 M Z'],
  "mcadoo": ['M AE1 K AH0 D UW2'],
  "mcadory": ['M AH0 K AE1 D ER0 IY0'],
  "mcadow": ['M AH0 K AE1 D OW0'],
  "mcafee": ['M AE1 K AH0 F IY2', 'M AH0 K AE1 F IY2'],
  "mcaffee": ['M AE1 K AH0 F IY0', 'M AH0 K AE1 F IY2'],
  "mcafferty": ['M AH0 K AE1 F ER0 T IY0'],
  "mcaleer": ['M AE1 K AH0 L IH0 R'],
  "mcaleese": ['M AH0 K AH0 L IY1 Z', 'M AH0 K AH0 L IY1 Z IY0'],
  "mcaleese's": ['M AH0 K AH0 L IY1 S IY0 Z', 'M AH0 K AH0 L IY1 S IH0 Z'],
  "mcalexander": ['M AH0 K AE2 L IH0 G Z AE1 N D ER0'],
  "mcalinden": ['M AH0 K L IH1 N D AH0 N'],
  "mcalister": ['M AH0 K AE1 L AH0 S T ER0'],
  "mcallen": ['M AH0 K AO1 L AH0 N'],
  "mcallester": ['M AH0 K AE1 L AH0 S T ER0'],
  "mcallister": ['M AH0 K AE1 L AH0 S T ER0'],
  "mcaloon": ['M AE1 K AH0 L UW2 N'],
  "mcalpin": ['M AH0 K AE1 L P AH0 N'],
  "mcalpine": ['M AH0 K AE1 L P AY2 N'],
  "mcamis": ['M AH0 K AE1 M AH0 S'],
  "mcan": ['M AH0 K AE1 N'],
  "mcanally": ['M AE1 K AH0 N AE2 L IY0'],
  "mcanany": ['M AH0 K AE1 N AH0 N IY0'],
  "mcandrew": ['M AH0 K AE1 N D R UW2'],
  "mcandrews": ['M AH0 K AE1 N D R UW2 Z'],
  "mcanelly": ['M AH0 K AE1 N AH2 L IY0'],
  "mcaninch": ['M AH0 K AE1 N IH0 N CH'],
  "mcannally": ['M AE1 K AH0 N AE2 L IY0'],
  "mcanulty": ['M AE1 K AH0 N AH0 L T IY0'],
  "mcardle": ['M AH0 K AA1 R D AH0 L'],
  "mcarthur": ['M AH0 K AA1 R TH ER0'],
  "mcartor": ['M AH0 K AA1 R T ER0'],
  "mcatee": ['M AH0 K AE1 T IY0'],
  "mcateer": ['M AE1 K AH0 T IH2 R'],
  "mcaulay": ['M AH0 K AO1 L EY0'],
  "mcauley": ['M AH0 K AO1 L IY0'],
  "mcauliff": ['M AH0 K AO1 L AH0 F'],
  "mcauliffe": ['M AH0 K AO1 L AH0 F'],
  "mcauliffe's": ['M AH0 K AO1 L AH0 F S'],
  "mcavinchey": ['M AH0 K AE0 V IH1 N CH IY0'],
  "mcavity": ['M AH0 K AE1 V AH0 T IY0'],
  "mcavoy": ['M AE1 K AH0 V OY2'],
  "mcbain": ['M AH0 K B EY1 N'],
  "mcbane": ['M AH0 K B EY1 N'],
  "mcbay": ['M AH0 K B EY1'],
  "mcbean": ['M AH0 K B IY1 N'],
  "mcbeath": ['M AH0 K B EH1 TH'],
  "mcbee": ['M AH0 K B IY1'],
  "mcbeth": ['M AH0 K B EH1 TH'],
  "mcbirney": ['M AH0 K B ER1 N IY0'],
  "mcbrayer": ['M AH0 K B R EY1 ER0'],
  "mcbrearty": ['M AH0 K B R IH1 R T IY0'],
  "mcbreen": ['M AH0 K B R IY1 N'],
  "mcbride": ['M AH0 K B R AY1 D'],
  "mcbridge": ['M AH0 K B R IH1 JH'],
  "mcbrien": ['M AH0 K B R AY1 AH0 N'],
  "mcbroom": ['M AH0 K B R UW1 M'],
  "mcbryar": ['M AH0 K B R AY1 ER0'],
  "mcbryde": ['M AH0 K B R AY1 D'],
  "mcburnett": ['M AH0 K B ER1 N AH0 T'],
  "mcburney": ['M AH0 K B ER1 N IY0'],
  "mccaa": ['M AH0 K AA1'],
  "mccabe": ['M AH0 K EY1 B', 'M AE1 K AH0 B IY0'],
  "mccadden": ['M AH0 K AE1 D AH0 N'],
  "mccade": ['M AH0 K EY1 D'],
  "mccafferty": ['M AH0 K AE1 F ER0 T IY0'],
  "mccaffery": ['M AH0 K AE1 F R IY0'],
  "mccaffrey": ['M AH0 K AE1 F R IY0'],
  "mccaffrey's": ['M AH0 K AE1 F R IY0 Z'],
  "mccaghren": ['M AH0 K AE1 G R AH0 N'],
  "mccague": ['M AH0 K EY1 G'],
  "mccahan": ['M AH0 K AE1 N'],
  "mccahill": ['M AH0 K AA1 HH IH2 L'],
  "mccaig": ['M AH0 K EY1 G'],
  "mccain": ['M AH0 K EY1 N'],
  "mccain's": ['M AH0 K EY1 N Z'],
  "mccaleb": ['M AH0 K EY1 L AH0 B'],
  "mccalip": ['M AH0 K AE1 L IH2 P'],
  "mccalister": ['M AH0 K AE1 L AH0 S T ER0'],
  "mccall": ['M AH0 K AO1 L'],
  "mccall's": ['M AH0 K AO1 L Z'],
  "mccalla": ['M AH0 K AE1 L AH0'],
  "mccallen": ['M AH0 K AO1 L AH0 N'],
  "mccalley": ['M AH0 K AE1 L IY0'],
  "mccallie": ['M AH0 K AO1 L IY0'],
  "mccallion": ['M AH0 K AE1 L Y AH0 N'],
  "mccallister": ['M AH0 K AE1 L AH0 S T ER0'],
  "mccallon": ['M AH0 K AE1 L AH0 N'],
  "mccallum": ['M AH0 K AE1 L AH0 M'],
  "mccalmont": ['M AH0 K AE1 L M AH0 N T'],
  "mccamant": ['M AH0 K AE1 M AH0 N T'],
  "mccambridge": ['M AH0 K AE1 M B R IH2 JH', 'M AH0 K EY1 M B R IH2 JH'],
  "mccamey": ['M AH0 K AE1 M IY0'],
  "mccamish": ['M AH0 K AE1 M IH0 SH'],
  "mccammon": ['M AH0 K AE1 M AH0 N'],
  "mccampbell": ['M AH0 K AE1 M P B AH0 L'],
  "mccamy": ['M AH0 K EY1 M IY0'],
  "mccan": ['M AH0 K AE1 N'],
  "mccance": ['M AH0 K AE1 N S'],
  "mccandless": ['M AH0 K AE1 N D L AH0 S'],
  "mccandlish": ['M AH0 K AE1 N D L IH0 SH'],
  "mccane": ['M AH0 K EY1 N'],
  "mccanless": ['M AH0 K AE1 N L AH0 S'],
  "mccann": ['M AH0 K AE1 N'],
  "mccann's": ['M AH0 K AE1 N Z'],
  "mccanna": ['M AH0 K AE1 N AH0'],
  "mccannon": ['M AH0 K AE1 N AH0 N'],
  "mccants": ['M AH0 K AE1 N T S'],
  "mccard": ['M AH0 K AA1 R D'],
  "mccardell": ['M AH0 K AA1 R D AH0 L'],
  "mccardle": ['M AH0 K AA1 R D AH0 L'],
  "mccarey": ['M AH0 K AE1 R IY0'],
  "mccargar": ['M AH0 K AA1 R G ER0'],
  "mccargo": ['M AH0 K AA1 R G OW0'],
  "mccarl": ['M AH0 K AA1 R L'],
  "mccarley": ['M AH0 K AA1 R L IY0'],
  "mccarn": ['M AH0 K AA1 R N'],
  "mccarney": ['M AH0 K AA1 R N IY0'],
  "mccarran": ['M AH0 K EH1 R AH0 N'],
  "mccarrell": ['M AH0 K AE1 R AH0 L'],
  "mccarren": ['M AH0 K EH1 R AH0 N'],
  "mccarrick": ['M AH0 K AE1 R IH0 K'],
  "mccarroll": ['M AH0 K AE1 R AH0 L'],
  "mccarron": ['M AH0 K AE1 R AH0 N'],
  "mccarry": ['M AH0 K AE1 R IY0'],
  "mccarson": ['M AH0 K AA1 R S AH0 N'],
  "mccart": ['M AH0 K AA1 R T'],
  "mccartan": ['M AH0 K AA1 R T AH0 N'],
  "mccarten": ['M AH0 K AA1 R T AH0 N'],
  "mccarter": ['M AH0 K AA1 R T ER0'],
  "mccartha": ['M AH0 K AA1 R TH AH0'],
  "mccarthy": ['M AH0 K AA1 R TH IY0'],
  "mccarthy's": ['M AH0 K AA1 R TH IY0 Z'],
  "mccarthyism": ['M AH0 K AA1 R TH IY0 IH0 Z AH0 M'],
  "mccarthyite": ['M AH0 K AA1 R TH IY0 AY2 T'],
  "mccartin": ['M AH0 K AA1 R T AH0 N'],
  "mccartney": ['M AH0 K AA1 R T N IY0'],
  "mccartney's": ['M AH0 K AA1 R T N IY0 Z'],
  "mccartt": ['M AH0 K AA1 R T'],
  "mccarty": ['M AH0 K AA1 R T IY0'],
  "mccarver": ['M AH0 K AA1 R V ER0'],
  "mccarville": ['M AH0 K AA1 R V AH0 L'],
  "mccary": ['M AH0 K EH1 R IY0'],
  "mccaskey": ['M AH0 K AE1 S K IY0'],
  "mccaskill": ['M AH0 K AE1 S K AH0 L'],
  "mccasland": ['M AH0 K AE1 Z L AH0 N D'],
  "mccaslin": ['M AH0 K AE1 Z L AH0 N'],
  "mccaughan": ['M AH0 K AO1 N'],
  "mccaughey": ['M AH0 K AO1 IY0'],
  "mccaul": ['M AH0 K AO1 L'],
  "mccauley": ['M AH0 K AO1 L IY0'],
  "mccaulley": ['M AH0 K AO1 L IY0'],
  "mccausland": ['M AH0 K AO1 Z L AH0 N D'],
  "mccauslin": ['M AH0 K AO1 Z L AH0 N'],
  "mccauthy": ['M AH0 K AW1 TH IY0'],
  "mccaw": ['M AH0 K AO1'],
  "mccaw's": ['M AH0 K AO1 Z'],
  "mccawley": ['M AH0 K AO1 L IY0'],
  "mccay": ['M AH0 K EY1'],
  "mcchesney": ['M AH0 K CH EH1 Z N IY0'],
  "mcchristian": ['M AH0 K R IH1 S CH AH0 N'],
  "mcclafferty": ['M AH0 K L AE1 F ER0 T IY0'],
  "mcclaflin": ['M AH0 K L AE1 F L AH0 N'],
  "mcclain": ['M AH0 K L EY1 N'],
  "mcclaine": ['M AH0 K L EY1 N'],
  "mcclam": ['M AH0 K L AE1 M'],
  "mcclanahan": ['M AH0 K L AE1 N AH0 HH AE0 N'],
  "mcclane": ['M AH0 K L EY1 N'],
  "mcclaran": ['M AH0 K L AE1 R AH0 N'],
  "mcclard": ['M AH0 K L AA1 D'],
  "mcclaren": ['M AH0 K L AA1 R AH0 N'],
  "mcclarnon": ['M AH0 K L AA1 R N AH0 N'],
  "mcclarty": ['M AH0 K L AA1 R T IY0'],
  "mcclary": ['M AH0 K L EH1 R IY0'],
  "mcclaskey": ['M AH0 K L AE1 S K IY0'],
  "mcclatchey": ['M AH0 K L AE1 CH IY0'],
  "mcclatchy": ['M AH0 K L AE1 CH IY0'],
  "mcclaugherty": ['M AH0 K L AE1 F ER0 T IY0'],
  "mcclave": ['M AH0 K L EY1 V'],
  "mcclay": ['M AH0 K L EY1'],
  "mccleaf": ['M AH0 K L IY1 F'],
  "mcclean": ['M AH0 K L IY1 N'],
  "mccleary": ['M AH0 K L IH1 R IY0'],
  "mccleary's": ['M AH0 K L IH1 R IY0 Z'],
  "mccleave": ['M AH0 K L IY1 V'],
  "mccleery": ['M AH0 K L IH1 R IY0'],
  "mcclees": ['M AH0 K L IY1 Z'],
  "mccleese": ['M AH0 K L IY1 S'],
  "mcclellan": ['M AH0 K L EH1 L AH0 N'],
  "mcclelland": ['M AH0 K L EH1 L AH0 N D'],
  "mcclellen": ['M AH0 K L EH1 L AH0 N'],
  "mcclements": ['M AH0 K L EH1 M AH0 N T S'],
  "mcclenaghan": ['M AH0 K L EH1 N AH0 G AH0 N'],
  "mcclenahan": ['M AH0 K L EH1 N AH0 HH AE0 N'],
  "mcclenathan": ['M AH0 K L EH1 N AH0 TH AH0 N'],
  "mcclendon": ['M AH0 K L EY1 N D AH0 N'],
  "mcclenny": ['M AH0 K L EH1 N IY0'],
  "mccleskey": ['M AH0 K L EH1 S K IY0'],
  "mcclimans": ['M AH0 K L AY1 M AH0 N Z', 'M AH0 K L IH1 M AH0 N Z'],
  "mcclintic": ['M AH0 K L IH1 N T IH0 K'],
  "mcclintick": ['M AH0 K L IH1 N T IH0 K'],
  "mcclintock": ['M AH0 K L IH1 N T AH0 K'],
  "mcclinton": ['M AH0 K L IH1 N T AH0 N'],
  "mcclish": ['M AH0 K L IH1 SH'],
  "mcclory": ['M AH0 K L AO1 R IY0'],
  "mccloskey": ['M AH0 K L AO1 S K IY0'],
  "mcclosky": ['M AH0 K L AO1 S K IY0'],
  "mccloud": ['M AH0 K L AW1 D'],
  "mccloy": ['M AH0 K L OY1'],
  "mccluer": ['M AH0 K L UW1 R'],
  "mcclune": ['M AH0 K L UW1 N'],
  "mccluney": ['M AH0 K L UW1 N IY0'],
  "mcclung": ['M AH0 K L AH1 NG'],
  "mcclure": ['M AH0 K L UW1 R'],
  "mcclurg": ['M AH0 K L ER1 G'],
  "mcclurkin": ['M AH0 K L ER1 K AH0 N'],
  "mccluskey": ['M AH0 K L AH1 S K IY0'],
  "mccoig": ['M AH0 K OY1 G'],
  "mccoin": ['M AH0 K OY1 N'],
  "mccole": ['M AH0 K OW1 L'],
  "mccolgan": ['M AH0 K OW1 L G AH0 N'],
  "mccoll": ['M AH0 K OW1 L'],
  "mccollam": ['M AH0 K AA1 L AH0 M'],
  "mccolley": ['M AH0 K AA1 L IY0'],
  "mccollister": ['M AH0 K AA1 L AH0 S T ER0'],
  "mccolloch": ['M AH0 K AA1 L AH0 K'],
  "mccollom": ['M AH0 K AA1 L AH0 M'],
  "mccollough": ['M AH0 K AA1 L AH0', 'M AH0 K AA1 L AW0'],
  "mccollum": ['M AH0 K AO1 L AH0 M'],
  "mccolm": ['M AH0 K OW1 M'],
  "mccomas": ['M AH0 K OW1 M AH0 S'],
  "mccomb": ['M AH0 K OW1 M'],
  "mccomber": ['M AH0 K OW1 M B ER0', 'M AH0 K OW1 M ER0'],
  "mccombie": ['M AH0 K OW1 M B IY0', 'M AH0 K OW1 M IY0'],
  "mccombs": ['M AH0 K AA1 M Z'],
  "mccommon": ['M AH0 K AA1 M AH0 N'],
  "mccommons": ['M AH0 K AA1 M AH0 N Z'],
  "mccomsey": ['M AH0 K AA1 M S IY0'],
  "mcconaghy": ['M AH0 K AA1 N AH0 G IY0'],
  "mcconaha": ['M AH0 K AA1 N AH0 HH AA0'],
  "mcconahay": ['M AH0 K AA1 N AH0 HH EY2'],
  "mcconahy": ['M AH0 K AA1 N AH0 HH IY0'],
  "mcconathy": ['M AH0 K AA1 N AH0 TH IY0', 'M AE1 K AH0 N AE2 TH IY0'],
  "mcconaughey": ['M AH0 K AA1 N AH0 G EY0'],
  "mcconaughy": ['M AH0 K AA1 N AH0 G IY0'],
  "mccone": ['M AH0 K OW1 N'],
  "mcconico": ['M AH0 K AA1 N AH0 K OW0'],
  "mcconkey": ['M AH0 K AA1 NG K IY0'],
  "mcconn": ['M AH0 K AA1 N'],
  "mcconnaughey": ['M AH0 K AA1 N AH0 G EY0'],
  "mcconnel": ['M AH0 K AA1 N AH0 L'],
  "mcconnel's": ['M AH0 K AA1 N AH0 L Z'],
  "mcconnell": ['M AH0 K AA1 N AH0 L'],
  "mcconnell's": ['M AH0 K AA1 N AH0 L Z'],
  "mcconnon": ['M AH0 K AA1 N AH0 N'],
  "mcconville": ['M AH0 K AA1 N V IH2 L'],
  "mccooey": ['M AH0 K UW1 IY0'],
  "mccook": ['M AH0 K UH1 K'],
  "mccool": ['M AH0 K UW1 L'],
  "mccord": ['M AH0 K AO1 R D'],
  "mccorkel": ['M AH0 K AO1 R K AH0 L'],
  "mccorkell": ['M AH0 K AO1 R K AH0 L'],
  "mccorkindale": ['M AH0 K AO1 R K AH0 N D EY2 L'],
  "mccorkle": ['M AH0 K AO1 R K AH0 L'],
  "mccormac": ['M AH0 K AO1 R M AH0 K'],
  "mccormack": ['M AH0 K AO1 R M AH0 K'],
  "mccormick": ['M AH0 K AO1 R M IH0 K'],
  "mccormick's": ['M AH0 K AO1 R M IH0 K S'],
  "mccorquodale": ['M AH0 K AO1 R K AH0 D EY2 L'],
  "mccorry": ['M AH0 K AO1 R IY0'],
  "mccort": ['M AH0 K AO1 R T'],
  "mccorvey": ['M AH0 K AO1 R V IY0'],
  "mccosh": ['M AH0 K AA1 SH'],
  "mccoskey": ['M AH0 K AA1 S K IY0'],
  "mccotter": ['M AH0 K AA1 T ER0'],
  "mccoun": ['M AH0 K AW1 N'],
  "mccourt": ['M AH0 K AO1 R T'],
  "mccovey": ['M AH0 K OW1 V IY2'],
  "mccowan": ['M AH0 K AW1 AH0 N'],
  "mccowen": ['M AH0 K AW1 AH0 N'],
  "mccowin": ['M AH0 K AW1 IH0 N'],
  "mccown": ['M AH0 K AW1 N'],
  "mccoy": ['M AH0 K OY1'],
  "mccoys": ['M AH0 K OY1 Z'],
  "mccracken": ['M AH0 K R AE1 K AH0 N'],
  "mccrackin": ['M AH0 K R AE1 K AH0 N'],
  "mccrady": ['M AH0 K R EY1 D IY0'],
  "mccrae": ['M AH0 K R EY1'],
  "mccraney": ['M AH0 K R AE1 N IY0'],
  "mccranie": ['M AH0 K R EY1 N IY0'],
  "mccrary": ['M AH0 K R EH1 R IY0'],
  "mccravy": ['M AH0 K R EY1 V IY0'],
  "mccraw": ['M AH0 K R AO1'],
  "mccray": ['M AH0 K R EY1'],
  "mccrea": ['M AH0 K R EY1'],
  "mccreadie": ['M AH0 K R IY1 D IY0'],
  "mccready": ['M AH0 K R IY1 D IY0'],
  "mccreary": ['M AH0 K R IH1 R IY0'],
  "mccredie": ['M AH0 K R IY1 D IY0'],
  "mccree": ['M AH0 K R IY1'],
  "mccreedy": ['M AH0 K R IY1 D IY0'],
  "mccreery": ['M AH0 K R IH1 R IY0'],
  "mccreight": ['M AH0 K R EY1 T'],
  "mccreless": ['M AH0 K R IY1 L AH0 S'],
  "mccrickard": ['M AH0 K R IH1 K ER0 D'],
  "mccright": ['M AH0 K R AY1 T'],
  "mccrillis": ['M AH0 K R IH1 L AH0 S'],
  "mccrimmon": ['M AH0 K R IH1 M AH0 N'],
  "mccrocklin": ['M AH0 K R AA1 K L AH0 N'],
  "mccrone": ['M AH0 K R OW1 N'],
  "mccrorey": ['M AH0 K R AO1 R IY0'],
  "mccrory": ['M AH0 K R AO1 R IY0'],
  "mccroskey": ['M AH0 K R AA1 S K IY0'],
  "mccrossen": ['M AH0 K R AO1 S AH0 N'],
  "mccrudden": ['M AH0 K R AH1 D AH0 N'],
  "mccrum": ['M AH0 K R AH1 M'],
  "mccrumb": ['M AH0 K R AH1 M'],
  "mccrystal": ['M AH0 K R IH1 S T AH0 L'],
  "mccuan": ['M AH0 K UW1 AH0 N'],
  "mccubbin": ['M AH0 K AH1 B AH0 N'],
  "mccubbins": ['M AH0 K AH1 B AH0 N Z'],
  "mccue": ['M AH0 K Y UW1'],
  "mccuen": ['M AH0 K Y UW1 N'],
  "mccuin": ['M AH0 K UW1 AH0 N'],
  "mccuistion": ['M AH0 K W IH1 SH AH0 N'],
  "mccuiston": ['M AH0 K W IH1 S T AH0 N'],
  "mcculla": ['M AH0 K AH1 L AH0'],
  "mccullagh": ['M AH0 K AH1 L AH0 G'],
  "mccullah": ['M AH0 K AH1 L AH0'],
  "mccullar": ['M AH0 K AH1 L ER0'],
  "mccullars": ['M AH0 K AH1 L ER0 Z'],
  "mccullen": ['M AH0 K AH1 L AH0 N'],
  "mcculler": ['M AH0 K AH1 L ER0'],
  "mccullers": ['M AH0 K AH1 L ER0 Z'],
  "mcculley": ['M AH0 K AH1 L IY0'],
  "mcculloch": ['M AH0 K AH1 L AH0 K'],
  "mcculloh": ['M AH0 K AH1 L AH0'],
  "mccullough": ['M AH0 K AH1 L AH0'],
  "mccullum": ['M AH0 K AH1 L AH0 M'],
  "mccully": ['M AH0 K AH1 L IY0'],
  "mccumber": ['M AH0 K AH1 M B ER0'],
  "mccune": ['M AH0 K Y UW1 N'],
  "mccur": ['M AH0 K ER1'],
  "mccurdy": ['M AH0 K ER1 D IY0'],
  "mccurley": ['M AH0 K ER1 L IY0'],
  "mccurry": ['M AH0 K ER1 IY0'],
  "mccusker": ['M AH0 K AH1 S K ER0'],
  "mccutchan": ['M AH0 K AH1 CH AH0 N'],
  "mccutchen": ['M AH0 K AH1 CH AH0 N'],
  "mccutcheon": ['M AH0 K AH1 CH AH0 N'],
  "mccuvey": ['M AH0 K AH1 V IY0'],
  "mcdade": ['M AH0 K D EY1 D'],
  "mcdade's": ['M AH0 K D EY1 D Z'],
  "mcdaid": ['M AH0 K D EY1 D'],
  "mcdanel": ['M AH0 K D AE1 N AH0 L'],
  "mcdaniel": ['M AH0 K D AE1 N Y AH0 L'],
  "mcdaniels": ['M AH0 K D AE1 N Y AH0 L Z'],
  "mcdannel": ['M AH0 K D AE1 N AH0 L'],
  "mcdaris": ['M AH0 K D AE1 R AH0 S'],
  "mcdavid": ['M AH0 K D EY1 V AH0 D'],
  "mcdavitt": ['M AH0 K D AE1 V AH0 T'],
  "mcdeal": ['M AH0 K D IY1 L'],
  "mcdearmon": ['M AH0 K D ER1 M AH0 N'],
  "mcdermid": ['M AH0 K D ER1 M AH0 D'],
  "mcdermitt": ['M AH0 K D ER1 M AH0 T'],
  "mcdermot": ['M AH0 K D ER1 M AH0 T'],
  "mcdermott": ['M AH0 K D ER1 M AH0 T'],
  "mcdermott's": ['M AH0 K D ER1 M AH0 T S'],
  "mcdevitt": ['M AH0 K D EH1 V AH0 T'],
  "mcdiarmid": ['M AH0 K D EH1 R M AH0 D'],
  "mcdill": ['M AH0 K D IH1 L'],
  "mcdivett": ['M AH0 D IH1 V AH0 T'],
  "mcdivitt": ['M AH0 K D IH1 V AH0 T'],
  "mcdole": ['M AH0 K D OW1 L'],
  "mcdonagh": ['M AH0 K D AH1 N AH0'],
  "mcdonald": ['M AH0 K D AA1 N AH0 L D'],
  "mcdonald's": ['M AH0 K D AA1 N AH0 L D Z'],
  "mcdonalds": ['M AH0 K D AA1 N AH0 L D Z'],
  "mcdonell": ['M AH0 K D AA1 N AH0 L'],
  "mcdonnel": ['M AH0 K D AA1 N AH0 L'],
  "mcdonnel's": ['M AH0 K D AA1 N AH0 L Z'],
  "mcdonnell": ['M AH0 K D AA1 N AH0 L'],
  "mcdonnell's": ['M AH0 K D AA1 N AH0 L Z'],
  "mcdonough": ['M AH0 K D AH1 N AH0'],
  "mcdorman": ['M AH0 K D AO1 R M AH0 N'],
  "mcdougal": ['M AH0 K D UW1 G AH0 L'],
  "mcdougal's": ['M AH0 K D UW1 G AH0 L Z'],
  "mcdougald": ['M AH0 K D UW1 G AH0 L D'],
  "mcdougall": ['M AH0 K D UW1 G AH0 L'],
  "mcdougals": ['M AH0 K D UW1 G AH0 L Z'],
  "mcdougals'": ['M AH0 K D UW1 G AH0 L Z'],
  "mcdougle": ['M AH0 K D UW1 G AH0 L'],
  "mcdow": ['M AH0 K D AW1'],
  "mcdowall": ['M AH0 K D AW1 AH0 L'],
  "mcdowell": ['M AH0 K D AW1 AH0 L'],
  "mcduff": ['M AH0 K D AH1 F'],
  "mcduffee": ['M AH0 K D AH1 F IY0'],
  "mcduffie": ['M AH0 K D AH1 F IY0'],
  "mcduffy": ['M AH0 K D AH1 F IY0'],
  "mcdugal": ['M AH0 K D UW1 G AH0 L'],
  "mcdurman": ['M AH0 K D ER1 M AH0 N'],
  "mcdyess": ['M AH0 K D AY1 AH0 S'],
  "mceachern": ['M AH0 K IY1 CH ER0 N'],
  "mceachin": ['M AH0 K IY1 CH AH0 N'],
  "mcelderry": ['M AE1 K AH0 L D IH2 R IY0'],
  "mceldowney": ['M AE1 K AH0 L D AW2 N IY0'],
  "mcelfresh": ['M AE1 K AH0 L F R EH2 SH'],
  "mcelhaney": ['M AE1 K AH0 L HH EY2 N IY0'],
  "mcelhannon": ['M AE1 K AH0 L HH AE2 N AH0 N'],
  "mcelhany": ['M AE1 K AH0 L HH EY2 N IY0'],
  "mcelheney": ['M AE1 K AH0 L HH EY2 N IY0'],
  "mcelheny": ['M AE1 K AH0 L HH IY2 N IY0'],
  "mcelhiney": ['M AE1 K AH0 L HH IH2 N IY0'],
  "mcelhinney": ['M AE1 K AH0 L HH IH2 N IY0'],
  "mcelhinny": ['M AE1 K AH0 L HH IH2 N IY0'],
  "mcelhone": ['M AE1 K AH0 L HH OW2 N'],
  "mcelligott": ['M AH0 K EH1 L AH0 G AH0 T'],
  "mcelmurray": ['M AE1 K AH0 L M ER2 IY0'],
  "mcelmurry": ['M AE1 K AH0 L M ER2 R IY0'],
  "mcelrath": ['M AE1 K AH0 L R AE2 TH', 'M AH0 K EH1 L R AE2 TH'],
  "mcelravy": ['M AE1 K AH0 L R EY2 V IY0'],
  "mcelreath": ['M AE1 K AH0 L R AE2 TH'],
  "mcelroy": ['M AE1 K AH0 L R OY2'],
  "mcelvain": ['M AE1 K AH0 L V EY2 N'],
  "mcelvaine": ['M AE1 K AH0 L V EY2 N'],
  "mcelveen": ['M AE1 K AH0 L V IY2 N'],
  "mcelwain": ['M AE1 K AH0 L W EY2 N'],
  "mcelwaine": ['M AE1 K AH0 L W EY2 N'],
  "mcelwee": ['M AE1 K AH0 L W IY0'],
  "mcelyea": ['M AE1 K AH0 L Y EY2'],
  "mcenaney": ['M AE1 K AH0 N EY2 N IY0'],
  "mcenany": ['M AE1 K AH0 N EY2 N IY0'],
  "mcendree": ['M AH0 K EH1 N D R IY2'],
  "mcenerney": ['M AE1 K AH0 N EH0 R N IY0'],
  "mcenery": ['M AH0 K EH1 N ER0 IY0'],
  "mcenroe": ['M AE1 K AH0 N R OW0'],
  "mcentee": ['M AE1 K AH0 N T IY0', 'M AH0 K EH1 N T IY0'],
  "mcentire": ['M AE1 K AH0 N T AY0 R'],
  "mcentyre": ['M AE1 K AH0 N T AY0 R'],
  "mcerlean": ['M AH0 K ER1 L IY0 N'],
  "mceuen": ['M AH0 K Y UW1 AH0 N'],
  "mcever": ['M AH0 K EH1 V ER0'],
  "mcevers": ['M AH0 K EH1 V ER0 Z'],
  "mcevilly": ['M AH0 K EH1 V AH0 L IY0'],
  "mcevoy": ['M AE1 K AH0 V OY2'],
  "mcewan": ['M AE1 K UW0 AE0 N'],
  "mcewen": ['M AH0 K Y UW1 AH0 N'],
  "mcfadden": ['M AH0 K F AE1 D AH0 N'],
  "mcfadden's": ['M AH0 K F AE1 D AH0 N Z'],
  "mcfaddin": ['M AH0 K F AE1 D AH0 N'],
  "mcfadin": ['M AH0 K F AE1 D AH0 N'],
  "mcfadyen": ['M AH0 K F AE1 D IY0 AH0 N'],
  "mcfall": ['M AH0 K F AO1 L'],
  "mcfalland": ['M AH0 K F AE1 L AH0 N D'],
  "mcfalls": ['M AH0 K F AO1 L Z'],
  "mcfann": ['M AH0 K F AE1 N'],
  "mcfarlan": ['M AH0 K F AA1 R L AH0 N'],
  "mcfarland": ['M AH0 K F AA1 R L AH0 N D'],
  "mcfarlane": ['M AH0 K F AA1 R L AH0 N'],
  "mcfarlane's": ['M AH0 K F AA1 R L AH0 N Z'],
  "mcfarlin": ['M AH0 K F AA1 R L AH0 N'],
  "mcfarling": ['M AH0 K F AA1 R L IH0 NG'],
  "mcfarren": ['M AH0 K F EH1 R AH0 N'],
  "mcfate": ['M AH0 K F EY1 T'],
  "mcfatridge": ['M AH0 K F AE1 T R IH0 JH'],
  "mcfatter": ['M AH0 K F AE1 T ER0'],
  "mcfaul": ['M AH0 K F AO1 L'],
  "mcfayden": ['M AH0 K F EY1 D AH0 N'],
  "mcfee": ['M AH0 K F IY1'],
  "mcfeely": ['M AH0 K F IY1 L IY0'],
  "mcfeeters": ['M AH0 K F IY1 T ER0 Z'],
  "mcferran": ['M AH0 K F EH1 R AH0 N'],
  "mcferren": ['M AH0 K F EH1 R AH0 N'],
  "mcferrin": ['M AH0 K F EH1 R AH0 N'],
  "mcferron": ['M AH0 K F EH1 R AH0 N'],
  "mcfetridge": ['M AH0 K F EH1 T R IH0 JH'],
  "mcfly": ['M AH0 K F L AY1'],
  "mcfly's": ['M AH0 K F L AY1 Z'],
  "mcfun": ['M AH0 K F AH1 N'],
  "mcfun's": ['M AH0 K F AH1 N Z'],
  "mcgaffey": ['M AH0 G AE1 F IY0'],
  "mcgagh": ['M AH0 G AO1'],
  "mcgaha": ['M AH0 G AA1 HH AH0'],
  "mcgahan": ['M AH0 G AE1 HH AH0 N'],
  "mcgahee": ['M AH0 G AE1 HH IY0'],
  "mcgahey": ['M AH0 G AE1 HH IY0'],
  "mcgalley": ['M AH0 G AE1 L IY0'],
  "mcgalley's": ['M AH0 G AE1 L IY0 Z'],
  "mcgalliard": ['M AH0 G AE1 L IY0 AA0 R D'],
  "mcgann": ['M AH0 G AE1 N'],
  "mcgannon": ['M AH0 G AE1 N AH0 N'],
  "mcgarity": ['M AH0 G AE1 R AH0 T IY0'],
  "mcgarr": ['M AH0 G AA1 R'],
  "mcgarrah": ['M AH0 G AE1 R AH0'],
  "mcgarrigle": ['M AH0 G AE1 R AH0 G AH0 L'],
  "mcgarrity": ['M AH0 G AE1 R AH0 T IY0'],
  "mcgarry": ['M AH0 G EH1 R IY0'],
  "mcgarvey": ['M AH0 G AA0 R V EY1'],
  "mcgary": ['M AH0 G EH1 R IY0'],
  "mcgath": ['M AH0 G AE1 TH'],
  "mcgaugh": ['M AH0 G AO1'],
  "mcgaughey": ['M AH0 G AO1 IY0'],
  "mcgaughy": ['M AH0 G AO1 IY0'],
  "mcgauley": ['M AH0 G AO1 L IY0'],
  "mcgavin": ['M AH0 G AE1 V AH0 N'],
  "mcgavock": ['M AH0 G AE1 V AH0 K'],
  "mcgaw": ['M AH0 G AO1'],
  "mcgeachy": ['M AH0 G IY1 CH IY0'],
  "mcgeary": ['M AH0 G IH1 R IY0'],
  "mcgee": ['M AH0 G IY1'],
  "mcgee's": ['M AH0 G IY1 Z'],
  "mcgeean": ['M AH0 G IY1 AH0 N'],
  "mcgeean's": ['M AH0 G IY1 AH0 N Z'],
  "mcgeehan": ['M AH0 G IY1 HH AH0 N'],
  "mcgeever": ['M AH0 G IY1 V ER0'],
  "mcgegan": ['M AH0 G EH1 G AH0 N'],
  "mcgehee": ['M AH0 G EH1 HH IY0'],
  "mcgeorge": ['M AH0 K JH AO1 R JH'],
  "mcgeough": ['M AH0 G AH1 F'],
  "mcgettigan": ['M AH0 G EH1 T AH0 G AH0 N'],
  "mcghee": ['M AH0 G IY1'],
  "mcghie": ['M AH0 G IY1'],
  "mcgibbon": ['M AH0 G IH1 B AH0 N'],
  "mcgill": ['M AH0 G IH1 L'],
  "mcgillen": ['M AH0 G IH1 L AH0 N'],
  "mcgillicuddy": ['M AH0 G IH1 L AH0 K AH0 D IY0'],
  "mcgillis": ['M AH0 G IH1 L AH0 S'],
  "mcgillivray": ['M AH0 G IH1 L AH0 V R EY0'],
  "mcgilton": ['M AH0 G IH1 L T AH0 N'],
  "mcgilvery": ['M AH0 G IH1 L V ER0 IY0'],
  "mcgilvray": ['M AH0 G IH1 L V R IY0'],
  "mcginess": ['M AH0 G IH1 N AH0 S'],
  "mcginley": ['M AH0 G IH1 N L IY0'],
  "mcginn": ['M AH0 G IH1 N'],
  "mcginnes": ['M AH0 G IH1 N Z'],
  "mcginness": ['M AH0 G IH1 N AH0 S'],
  "mcginnis": ['M AH0 G IH1 N AH0 S'],
  "mcginniss": ['M AH0 G IH1 N AH0 S'],
  "mcginnity": ['M AH0 G IH1 N AH0 T IY0'],
  "mcginty": ['M AH0 G IH1 N T IY0'],
  "mcgirr": ['M AH0 G ER1'],
  "mcgirt": ['M AH0 G ER1 T'],
  "mcgivern": ['M AH0 G IH1 V ER0 N'],
  "mcgivney": ['M AH0 G IH1 V N IY0'],
  "mcglade": ['M AH0 G L EY1 D'],
  "mcglamery": ['M AH0 G L AE1 M ER0 IY0'],
  "mcglashan": ['M AH0 G L AE1 SH AH0 N'],
  "mcglasson": ['M AH0 G L AE1 S AH0 N'],
  "mcglaughlin": ['M AH0 G L AO1 F L AH0 N'],
  "mcglaun": ['M AH0 G L AO1 N'],
  "mcglinchey": ['M AH0 G L IH1 N CH IY0'],
  "mcglinn": ['M AH0 G L IH1 N'],
  "mcglocklin": ['M AH0 G L AA1 K L AH0 N'],
  "mcgloin": ['M AH0 G L OY1 N'],
  "mcglone": ['M AH0 G L OW1 N'],
  "mcglory": ['M AH0 G L AO1 R IY0'],
  "mcglothen": ['M AH0 G L AA1 TH AH0 N'],
  "mcglothin": ['M AH0 G L AA1 TH AH0 N'],
  "mcglothlin": ['M AH0 G L AA1 TH L AH0 N'],
  "mcglynn": ['M AH0 G L IH1 N'],
  "mcgoey": ['M AH0 G AA1 IY0'],
  "mcgoff": ['M AH0 G AO1 F'],
  "mcgoldrick": ['M AH0 G OW1 L D R AH0 K'],
  "mcgols": ['M AH0 K G AA1 L Z'],
  "mcgonagle": ['M AH0 G AA1 N AH0 G AH0 L'],
  "mcgonigal": ['M AH0 G AA1 N AH0 G AH0 L'],
  "mcgonigle": ['M AH0 G AA1 N AH0 G AH0 L'],
  "mcgough": ['M AH0 G AW1', 'M AH0 G AH1 F'],
  "mcgourty": ['M AH0 G UH1 R T IY0'],
  "mcgovern": ['M AH0 G AH1 V ER0 N'],
  "mcgovern's": ['M AH0 G AH1 V ER0 N Z'],
  "mcgowan": ['M AH0 G AW1 AH0 N'],
  "mcgowan's": ['M AH0 G AW1 AH0 N Z'],
  "mcgowen": ['M AH0 G AW1 AH0 N'],
  "mcgowin": ['M AH0 K G AW1 AH0 N'],
  "mcgown": ['M AH0 G AW1 N'],
  "mcgrady": ['M AH0 G R EY1 D IY0'],
  "mcgrail": ['M AH0 G R EY1 L'],
  "mcgrain": ['M AH0 G R EY1 N'],
  "mcgranahan": ['M AH0 G R AE1 N AH0 HH AE2 N'],
  "mcgrane": ['M AH0 G R EY1 N'],
  "mcgrath": ['M AH0 G R AE1 TH'],
  "mcgraw": ['M AH0 G R AO1'],
  "mcgray": ['M AH0 G R EY1'],
  "mcgreal": ['M AH0 G R IY1 L'],
  "mcgreevey": ['M AH0 G R IY1 V IY0'],
  "mcgreevy": ['M AH0 G R IY1 V IY0'],
  "mcgregor": ['M AH0 G R EH1 G ER0'],
  "mcgregory": ['M AH0 G R EH1 G ER0 IY0'],
  "mcgrevin": ['M AH0 G R EH1 V AH0 N'],
  "mcgrew": ['M AH0 G R UW1'],
  "mcgriff": ['M AH0 G R IH1 F'],
  "mcgroarty": ['M AH0 G R AO1 R T IY0'],
  "mcgrogan": ['M AH0 G R OW1 G AH0 N'],
  "mcgrory": ['M AH0 G R AO1 R IY0'],
  "mcgruder": ['M AH0 G R UW1 D ER0'],
  "mcguane": ['M AH0 G W EY1 N'],
  "mcguckin": ['M AH0 G AH1 K AH0 N'],
  "mcgue": ['M AH0 G Y UW1'],
  "mcguffee": ['M AH0 G AH1 F IY0'],
  "mcguffey": ['M AH0 G AH1 F IY0'],
  "mcguffie": ['M AH0 G AH1 F IY0'],
  "mcguffin": ['M AH0 G AH1 F AH0 N'],
  "mcguigan": ['M AH0 G IH1 G AH0 N'],
  "mcguiness": ['M AH0 G IH1 N AH0 S'],
  "mcguinn": ['M AH0 G IH1 N'],
  "mcguinness": ['M AH0 G IH1 N AH0 S'],
  "mcguire": ['M AH0 G W AY1 R'],
  "mcguirk": ['M AH0 G ER1 K'],
  "mcguirt": ['M AH0 G ER1 T'],
  "mcgurk": ['M AH0 G ER1 K'],
  "mcgurn": ['M AH0 G ER1 N'],
  "mcguyer": ['M AH0 G AY1 ER0'],
  "mcgwire": ['M AH0 G W AY1 AH0 R'],
  "mcgyver": ['M AH0 G AY1 V ER0'],
  "mchaffie": ['M AH0 K AE1 F IY0'],
  "mchale": ['M AH0 K EY1 L'],
  "mcham": ['M AH0 K AE1 M'],
  "mchan": ['M AH0 K AE1 N'],
  "mchaney": ['M AH0 K AE1 N IY0'],
  "mchargue": ['M AH0 K AA1 R G', 'M AH0 K AA1 R G Y UW0'],
  "mchatton": ['M AH0 K AE1 T AH0 N'],
  "mchenry": ['M AH0 K EH1 N R IY0'],
  "mchone": ['M AH0 K OW1 N'],
  "mchugh": ['M AH0 K Y UW1'],
  "mcilhenny": ['M AE1 K IH2 L HH EH2 N IY0'],
  "mcilrath": ['M AE1 K AH0 L R AE2 TH', 'M AH0 K IH1 L R AE2 TH'],
  "mcilroy": ['M AE1 K AH0 L R OY2', 'M AH0 K IH1 L R OY2'],
  "mcilvain": ['M AE1 K IH2 L V EY2 N', 'M AH0 K IH1 L V EY2 N'],
  "mcilvaine": ['M AE1 K IH2 L V EY2 N', 'M AH0 K IH1 L V EY2 N'],
  "mcilveen": ['M AE1 K IH2 L V IY2 N', 'M AH0 K IH1 L V IY2 N'],
  "mcilwain": ['M AE1 K IH2 L W EY2 N', 'M AH0 K IH1 L W EY2 N'],
  "mcinerney": ['M AE1 K AH0 N EH0 R N IY0'],
  "mcinerny": ['M AH0 K IH1 N ER0 N IY0'],
  "mcingvale": ['M AE1 K IH0 NG V EY2 L'],
  "mcinnes": ['M AH0 K IH1 N AH0 S'],
  "mcinnis": ['M AH0 K IH1 N AH0 S'],
  "mcinroy": ['M AE1 K IH2 N R OY2'],
  "mcintee": ['M AE1 K IH2 N T IY2'],
  "mcintire": ['M AE1 K IH2 N T AY2 R'],
  "mcintosh": ['M AE1 K AH0 N T AO2 SH'],
  "mcinturf": ['M AE1 K IH2 N T ER2 F'],
  "mcinturff": ['M AE1 K IH2 N T ER2 F'],
  "mcintyre": ['M AE1 K IH2 N T AY2 R'],
  "mcinvale": ['M AE1 K IH2 N V EY2 L'],
  "mcisaac": ['M AH0 K AY1 Z AH0 K'],
  "mciver": ['M AH0 K IH1 V ER0'],
  "mcivor": ['M AH0 K IH1 V ER0'],
  "mcjunkin": ['M AH0 K JH AH1 NG K AH0 N'],
  "mcjunkins": ['M AH0 K JH AH1 NG K AH0 N Z'],
  "mckaig": ['M AH0 K EY1 G'],
  "mckain": ['M AH0 K EY1 N'],
  "mckamey": ['M AH0 K AE1 M IY0'],
  "mckane": ['M AH0 K EY1 N'],
  "mckanie": ['M AH0 K EY1 N IY0'],
  "mckanna": ['M AH0 K AE1 N AH0'],
  "mckarrick": ['M AH0 K AE1 R IH0 K'],
  "mckay": ['M AH0 K EY1'],
  "mckeag": ['M AH0 K IY1 G'],
  "mckeague": ['M AH0 K IY1 G'],
  "mckean": ['M AH0 K IY1 N'],
  "mckeand": ['M AH0 K IY1 N D'],
  "mckechnie": ['M AH0 K EH1 K N IY0'],
  "mckee": ['M AH0 K IY1'],
  "mckee's": ['M AH0 K IY1 Z'],
  "mckeegan": ['M AH0 K IY1 G AH0 N'],
  "mckeehan": ['M AH0 K IY1 HH AH0 N'],
  "mckeel": ['M AH0 K IY1 L'],
  "mckeeman": ['M AH0 K IY1 M AH0 N'],
  "mckeen": ['M AH0 K IY1 N'],
  "mckeesport": ['M AH0 K IY1 S P AO2 R T'],
  "mckeever": ['M AH0 K IY1 V ER0'],
  "mckeithan": ['M AH0 K IY1 TH AH0 N'],
  "mckeithen": ['M AH0 K IY1 TH AH0 N'],
  "mckell": ['M AH0 K EH1 L'],
  "mckellan": ['M AH0 K EH1 L AH0 N'],
  "mckellar": ['M AH0 K EH1 L ER0'],
  "mckeller": ['M AH0 K EH1 L ER0'],
  "mckellips": ['M AH0 K EH1 L IH0 P S'],
  "mckelvey": ['M AE1 K AH0 L V EY2'],
  "mckelvie": ['M AE1 K AH0 L V IY1'],
  "mckelvy": ['M AE1 K AH0 L V IY2'],
  "mckemie": ['M AH0 K EH1 M IY0'],
  "mckendree": ['M AH0 K EH1 N D R IY0'],
  "mckendrick": ['M AH0 K EH1 N D R IH0 K'],
  "mckendry": ['M AH0 K EH1 N D R IY0'],
  "mckenna": ['M AH0 K EH1 N AH0'],
  "mckenney": ['M AH0 K EH1 N IY0'],
  "mckennon": ['M AH0 K EH1 N AH0 N'],
  "mckenny": ['M AH0 K EH1 N IY0'],
  "mckenrick": ['M AH0 K EH1 N R IH0 K'],
  "mckenzie": ['M AH0 K EH1 N Z IY0'],
  "mckeon": ['M AH0 K IY1 AH0 N'],
  "mckeone": ['M AH0 K IY1 AH0 N'],
  "mckeough": ['M AH0 K IY1 OW0'],
  "mckeown": ['M AH0 K Y UW1 AH0 N'],
  "mckercher": ['M AH0 K ER1 CH ER0'],
  "mckern": ['M AH0 K ER1 N'],
  "mckernan": ['M AH0 K ER1 N AH0 N'],
  "mckesson": ['M AH0 K EH1 S AH0 N'],
  "mckesson's": ['M AH0 K EH1 S AH0 N Z'],
  "mckethan": ['M AH0 K EH1 TH AH0 N'],
  "mckevitt": ['M AH0 K EH1 V AH0 T'],
  "mckey": ['M AH0 K IY1'],
  "mckibben": ['M AH0 K IH1 B AH0 N'],
  "mckibbin": ['M AH0 K IH1 B AH0 N'],
  "mckibbon": ['M AH0 K IH1 B AH0 N'],
  "mckids": ['M AH0 K IH1 D Z'],
  "mckie": ['M AH0 K IY1'],
  "mckiernan": ['M AH0 K IH1 R N AH0 N'],
  "mckillip": ['M AH0 K IH1 L AH0 P'],
  "mckillop": ['M AH0 K IH1 L AH0 P'],
  "mckim": ['M AH0 K IH1 M'],
  "mckimmey": ['M AH0 K IH1 M IY0'],
  "mckimmy": ['M AH0 K IH1 M IY0'],
  "mckiness": ['M AH0 K IH1 N AH0 S'],
  "mckinlay": ['M AH0 K IH1 N L IY0'],
  "mckinley": ['M AH0 K IH1 N L IY0'],
  "mckinney": ['M AH0 K IH1 N IY0'],
  "mckinney's": ['M AH0 K IH1 N IY0 Z'],
  "mckinnie": ['M AH0 K IH1 N IY0'],
  "mckinnis": ['M AH0 K IH1 N AH0 S'],
  "mckinnon": ['M AH0 K IH1 N AH0 N'],
  "mckinny": ['M AH0 K IH1 N IY0'],
  "mckinny's": ['M AH0 K IH1 N IY0 Z'],
  "mckinsey": ['M AH0 K IH1 N Z IY0'],
  "mckinstry": ['M AH0 K IH1 N S T R IY0'],
  "mckinzie": ['M AH0 K IH1 N Z IY0'],
  "mckissack": ['M AH0 K IH1 S AH0 K'],
  "mckissic": ['M AH0 K IH1 S IH0 K'],
  "mckissick": ['M AH0 K IH1 S IH0 K'],
  "mckitrick": ['M AH0 K IH1 T R IH0 K'],
  "mckittrick": ['M AH0 K IH1 T R IH0 K'],
  "mcklatchy": ['M AH0 K L AE1 CH IY0'],
  "mckneely": ['M AH0 K N IY1 L IY0'],
  "mcknew": ['M AH0 K N UW1'],
  "mcknight": ['M AH0 K N AY1 T'],
  "mckone": ['M AH0 K OW1 N'],
  "mckowen": ['M AH0 K AW1 AH0 N'],
  "mckown": ['M AH0 K OW1 N'],
  "mckoy": ['M AH0 K OY1'],
  "mckree": ['M AH0 K R IY0'],
  "mckrinkowski": ['M AH0 K R IH0 N K AW1 S K IY0'],
  "mckune": ['M AH0 K Y UW1 N'],
  "mclachlan": ['M AH0 K L AA1 K L AH0 N'],
  "mclafferty": ['M AH0 K L AE1 F ER0 T IY0'],
  "mclain": ['M AH0 K L EY1 N'],
  "mclamb": ['M AH0 K L AE1 M'],
  "mclanahan": ['M AH0 K L AE1 N AH0 HH AE0 N'],
  "mclane": ['M AH0 K L EY1 N'],
  "mclaren": ['M AH0 K L EH1 R AH0 N'],
  "mclarney": ['M AH0 K L AA1 R N IY0'],
  "mclarty": ['M AH0 K L AA1 R T IY0'],
  "mclarty's": ['M AH0 K L AA1 R T IY0 Z'],
  "mclauchlin": ['M AH0 K L AO1 K L AH0 N'],
  "mclaughlin": ['M AH0 G L AA1 K L AH0 N'],
  "mclaurin": ['M AH0 K L AO1 R AH0 N'],
  "mclaury": ['M AH0 K L AO1 R IY0'],
  "mclawhorn": ['M AH0 K L AE1 W ER0 N', 'M AH0 K L AW1 HH AO2 R N'],
  "mclay": ['M AH0 K L EY1'],
  "mclean": ['M AH0 K L IY1 N', 'M AH0 K L EY1 N'],
  "mclean's": ['M AH0 K L IY1 N Z', 'M AH0 K L EY1 N Z'],
  "mclear": ['M AH0 K L IH1 R'],
  "mcleary": ['M AH0 K L IH1 R IY0'],
  "mclees": ['M AH0 K L IY1 Z'],
  "mcleish": ['M AH0 K L IY1 SH'],
  "mcleland": ['M AH0 K L EH1 L AH0 N D'],
  "mclellan": ['M AH0 K L EH1 L AH0 N'],
  "mclelland": ['M AH0 K L EH1 L AH0 N D'],
  "mclemore": ['M AH0 K L EH1 M ER0'],
  "mclendon": ['M AH0 K L EH1 N D AH0 N'],
  "mclennan": ['M AH0 K L EH1 N AH0 N'],
  "mcleod": ['M AH0 K L AW1 D'],
  "mcleroy": ['M AH0 K L IY1 R OY0'],
  "mclerran": ['M AH0 K L EH1 R AH0 N'],
  "mclester": ['M AH0 K L EH1 S T ER0'],
  "mclin": ['M AH0 K L IH1 N'],
  "mclinden": ['M AH0 K L IH1 N D AH0 N'],
  "mclinn": ['M AH0 K L IH1 N'],
  "mclish": ['M AH0 K L IH1 SH'],
  "mcloud": ['M AH0 K L AW1 D'],
  "mclouth": ['M AH0 K L AW1 TH'],
  "mclucas": ['M AH0 K L UW1 K AH0 Z'],
  "mcluckie": ['M AH0 K L AH1 K IY0'],
  "mcluhan": ['M AH0 K L UW1 AH0 N'],
  "mclure": ['M AH0 K L UW1 R'],
  "mcmackin": ['M AH0 K M AE1 K AH0 N'],
  "mcmahan": ['M AH0 K M EY1 HH AH0 N'],
  "mcmahen": ['M AH0 K M EY1 HH AH0 N'],
  "mcmahill": ['M AH0 K M EY1 HH IH2 L'],
  "mcmahon": ['M AH0 K M EY1 AH0 N', 'M AH0 K M AE1 N'],
  "mcmains": ['M AH0 K M EY1 N Z'],
  "mcmaken": ['M AH0 K M EY1 K AH0 N'],
  "mcmakin": ['M AH0 K M AE1 K AH0 N'],
  "mcmanama": ['M AH0 K M AE1 N AH0 M AH0'],
  "mcmanaman": ['M AH0 K M AE1 N AH0 M AH0 N'],
  "mcmanamon": ['M AH0 K M AE1 N AH0 M AH0 N'],
  "mcmanaway": ['M AH0 K M AE1 N AH0 W EY0'],
  "mcmanigal": ['M AH0 K M AE1 N AH0 G AH0 L'],
  "mcmanis": ['M AH0 K M AE1 N AH0 S'],
  "mcmann": ['M AH0 K M AE1 N'],
  "mcmannis": ['M AH0 K M AE1 N AH0 S'],
  "mcmansion": ['M AH0 K M AE1 N SH AH0 N'],
  "mcmanus": ['M AH0 K M AE1 N AH0 S'],
  "mcmartin": ['M AH0 K M AA1 R T AH0 N'],
  "mcmaster": ['M AH0 K M AE1 S T ER0'],
  "mcmasters": ['M AH0 K M AE1 S T ER0 Z'],
  "mcmath": ['M AH0 K M AE1 TH'],
  "mcmeans": ['M AH0 K M IY1 N Z'],
  "mcmeekin": ['M AH0 K M IY1 K AH0 N'],
  "mcmeen": ['M AH0 K M IY1 N'],
  "mcmenamin": ['M AH0 K M EH1 N AH0 M AH0 N'],
  "mcmenamy": ['M AH0 K M EH1 N AH0 M IY0'],
  "mcmenemy": ['M AH0 K M EH1 N AH0 M IY0'],
  "mcmennamin": ['M AH0 K M EH1 N AH0 M AH0 N'],
  "mcmichael": ['M AH0 K M AY1 K AH0 L'],
  "mcmichen": ['M AH0 K M IH1 CH AH0 N'],
  "mcmickle": ['M AH0 K M IH1 K AH0 L'],
  "mcmil": ['M AH0 K M IH1 L'],
  "mcmillan": ['M AH0 K M IH1 L AH0 N'],
  "mcmillen": ['M AH0 K M IH1 L AH0 N'],
  "mcmiller": ['M AH0 K M IH1 L ER0'],
  "mcmillian": ['M AH0 K M IH1 L Y AH0 N', 'M AH0 K M IH1 L AH0 N'],
  "mcmillin": ['M AH0 K M IH1 L IH0 N'],
  "mcmillion": ['M AH0 K M IH1 L Y AH0 N'],
  "mcmillon": ['M AH0 K M IH1 L AH0 N'],
  "mcminn": ['M AH0 K M IH1 N'],
  "mcmonagle": ['M AH0 K M AA1 N AH0 G AH0 L'],
  "mcmonigle": ['M AH0 K M AA1 N AH0 G AH0 L'],
  "mcmoran": ['M AH0 K M AO1 R AH0 N'],
  "mcmorran": ['M AH0 K M AO1 R AH0 N'],
  "mcmorris": ['M AH0 K M AO1 R AH0 S'],
  "mcmorrow": ['M AH0 K M AO1 R OW0'],
  "mcmuffin": ['M AH0 K M AH1 F AH0 N'],
  "mcmullan": ['M AH0 K M AH1 L AH0 N'],
  "mcmullen": ['M AH0 K AH1 L AH0 N'],
  "mcmullin": ['M AH0 K M AH1 L AH0 N'],
  "mcmunn": ['M AH0 K M AH1 N'],
  "mcmurdo": ['M AH0 K M ER1 D OW0'],
  "mcmurphy": ['M AH0 K M ER1 F IY0'],
  "mcmurray": ['M AH0 K M ER1 EY0'],
  "mcmurrey": ['M AH0 K M ER1 IY0'],
  "mcmurry": ['M AH0 K M ER1 IY0'],
  "mcmurtrey": ['M AH0 K M ER1 T R IY0'],
  "mcmurtrie": ['M AH0 K M ER1 T ER0 IY0'],
  "mcmurtry": ['M AH0 K M ER1 T R IY0'],
  "mcnab": ['M AH0 K N AE1 B'],
  "mcnabb": ['M AH0 K N AE1 B'],
  "mcnair": ['M AH0 K N EH1 R'],
  "mcnairy": ['M AH0 K N EH1 R IY0'],
  "mcnall": ['M AH0 K N AO1 L'],
  "mcnalley": ['M AH0 K N AE1 L IY0'],
  "mcnally": ['M AH0 K N AE1 L IY2'],
  "mcnally's": ['M AH0 K N AE1 L IY2 Z'],
  "mcnamara": ['M AE1 K N AH0 M EH2 R AH0'],
  "mcnamara's": ['M AE1 K N AH0 M EH2 R AH0 Z'],
  "mcnamee": ['M AE1 K N AH0 M IY0'],
  "mcnamer": ['M AH0 K N EY1 M ER0'],
  "mcnaney": ['M AH0 K N AE1 N IY0'],
  "mcnary": ['M AH0 K N EH1 R IY0'],
  "mcnatt": ['M AH0 K N AE1 T'],
  "mcnaught": ['M AH0 K N AO1 T'],
  "mcnaughton": ['M AH0 K N AO1 T AH0 N'],
  "mcnay": ['M AH0 K N EY1'],
  "mcneal": ['M AH0 K N IY1 L'],
  "mcneally": ['M AH0 K N IY1 L IY0'],
  "mcnealy": ['M AH0 K N IY1 L IY0'],
  "mcnear": ['M AH0 K N IH1 R'],
  "mcneary": ['M AH0 K N IH1 R IY0'],
  "mcnease": ['M AH0 K N IY1 Z'],
  "mcnee": ['M AH0 K N IY1'],
  "mcneece": ['M AH0 K N IY1 S'],
  "mcneel": ['M AH0 K N IY1 L'],
  "mcneeley": ['M AH0 K N IY1 L IY0'],
  "mcneely": ['M AH0 K N IY1 L IY0'],
  "mcneer": ['M AH0 K N IH1 R'],
  "mcnees": ['M AH0 K N IY1 Z'],
  "mcneese": ['M AH0 K N IY1 S'],
  "mcneff": ['M AH0 K N EH1 F'],
  "mcneice": ['M AH0 K N IY1 S'],
  "mcneil": ['M AH0 K N IY1 L'],
  "mcneill": ['M AH0 K N IY1 L'],
  "mcneilly": ['M AH0 K N IY1 L IY0'],
  "mcneish": ['M AH0 K N IY1 SH'],
  "mcnelis": ['M AH0 K N EH1 L AH0 S'],
  "mcnellis": ['M AH0 K N EH1 L AH0 S'],
  "mcnelly": ['M AH0 K N EH1 L IY0'],
  "mcnemar": ['M AE1 K N AH0 M AA2 R'],
  "mcnerney": ['M AH0 K N ER1 N IY0'],
  "mcnett": ['M AH0 K N EH1 T'],
  "mcnevin": ['M AH0 K N EH1 V AH0 N'],
  "mcnew": ['M AH0 K N UW1'],
  "mcnichol": ['M AH0 K N IH1 K AH0 L'],
  "mcnicholas": ['M AH0 K N IH1 K L AH0 S'],
  "mcnichols": ['M AH0 K N IH1 K AH0 L Z'],
  "mcnickle": ['M AH0 K N IH1 K AH0 L'],
  "mcnicol": ['M AH0 K N IH1 K AO0 L'],
  "mcniel": ['M AH0 K N IY1 L'],
  "mcniff": ['M AH0 K N IH1 F'],
  "mcninch": ['M AH0 K N IH1 N CH'],
  "mcnish": ['M AH0 K N IH1 SH'],
  "mcnitt": ['M AH0 K N IH1 T'],
  "mcnorton": ['M AH0 K N AO1 R T AH0 N'],
  "mcnuggets": ['M AH0 K N AH1 G AH0 T S'],
  "mcnulty": ['M AH0 K N AH1 L T IY0'],
  "mcnutt": ['M AH0 K N AH1 T'],
  "mcomber": ['M AH0 K AA1 M B ER0'],
  "mcorp": ['EH1 M K AO2 R P'],
  "mcorp's": ['EH1 M K AO2 R P S'],
  "mcpaper": ['M AH0 K P EY1 P ER0'],
  "mcparland": ['M AH0 K P AA1 R L AH0 N D'],
  "mcpartland": ['M AH0 K P AA1 R T L AH0 N D'],
  "mcpartlin": ['M AH0 K P AA1 R T L AH0 N'],
  "mcpeak": ['M AH0 K P IY1 K'],
  "mcpeake": ['M AH0 K P IY1 K'],
  "mcpeck": ['M AH0 K P EH1 K'],
  "mcpeek": ['M AH0 K P IY1 K'],
  "mcpeters": ['M AH0 K P IY1 T ER0 Z'],
  "mcphail": ['M AH0 K F EY1 L'],
  "mcphatter": ['M AH0 K F AE1 T ER0'],
  "mcphaul": ['M AH0 K F AO1 L'],
  "mcphearson": ['M AH0 K F ER1 S AH0 N', 'M AH0 K F IH1 R S AH0 N'],
  "mcphee": ['M AH0 K F IY1'],
  "mcpheeters": ['M AH0 K F IY1 T ER0 Z'],
  "mcpheron": ['M AH0 K F EH1 R AH0 N'],
  "mcpherson": ['M AH0 K F ER1 S AH0 N'],
  "mcphie": ['M AH0 K F IY1'],
  "mcphillips": ['M AH0 K F IH1 L AH0 P S'],
  "mcpike": ['M AH0 K P AY1 K'],
  "mcquade": ['M AH0 K W EY1 D'],
  "mcquaid": ['M AH0 K W EY1 D'],
  "mcquaide": ['M AH0 K W EY1 D'],
  "mcquaig": ['M AH0 K W EY1 G'],
  "mcquain": ['M AH0 K W EY1 N'],
  "mcquarrie": ['M AH0 K W AA1 R IY2'],
  "mcquary": ['M AH0 K W EH1 R IY2'],
  "mcquay": ['M AH0 K W EY1'],
  "mcqueary": ['M AH0 K W IH1 R IY0'],
  "mcqueen": ['M AH0 K W IY1 N'],
  "mcqueeney": ['M AH0 K W IY1 N IY0'],
  "mcquerry": ['M AH0 K W EH1 R IY0'],
  "mcquethy": ['M AH0 K W EH1 TH IY0'],
  "mcquethy's": ['M AH0 K W EH1 TH IY0 Z'],
  "mcquigg": ['M AH0 K W IH1 G'],
  "mcquilkin": ['M AH0 K W IH1 L K AH0 N'],
  "mcquillan": ['M AH0 K W IH1 L AH0 N'],
  "mcquillen": ['M AH0 K W IH1 L AH0 N'],
  "mcquillin": ['M AH0 K W IH1 L AH0 N'],
  "mcquinn": ['M AH0 K W IH1 N'],
  "mcquire": ['M AH0 K W AY1 R'],
  "mcquiston": ['M AH0 K W IH1 S T AH0 N'],
  "mcquitty": ['M AH0 K W IH1 T IY0'],
  "mcquown": ['M AH0 K W AW1 N'],
  "mcrae": ['M AH0 K R EY1'],
  "mcrainey": ['M AH0 K R AE1 N IY0'],
  "mcraney": ['M AH0 K R AE1 N IY0'],
  "mcray": ['M AH0 K R EY1'],
  "mcree": ['M AH0 K R IY1'],
  "mcreynolds": ['M AH0 K R EY1 N AH0 L D Z'],
  "mcright": ['M AH0 K R AY1 T'],
  "mcroberts": ['M AH0 K R AA1 B ER0 T S'],
  "mcrorie": ['M AH0 K R AO1 R IY0'],
  "mcroy": ['M AH0 K R OY1'],
  "mcshan": ['M AH0 K SH AE1 N'],
  "mcshane": ['M AH0 K SH EY1 N'],
  "mcshea": ['M AH0 K SH EY1'],
  "mcsherry": ['M AH0 K SH EH1 R IY0'],
  "mcsleep": ['M AH0 K S L IY1 P'],
  "mcsorley": ['M AH0 K S AO1 R L IY0'],
  "mcspadden": ['M AH0 K S P AE1 D AH0 N'],
  "mcstay": ['M AH0 K S T EY1'],
  "mcswain": ['M AH0 K S W EY1 N'],
  "mcsween": ['M AH0 K S W IY1 N'],
  "mcsweeney": ['M AH0 K S W IY1 N IY0'],
  "mctaggart": ['M AH0 K T AE1 G ER0 T'],
  "mctague": ['M AH0 K T EY1 G'],
  "mctavish": ['M AH0 K T EY1 V IH0 SH', 'M AH0 K T AE1 V IH0 SH'],
  "mcteer": ['M AH0 K T IH1 R'],
  "mcternan": ['M AH0 K T ER1 N AH0 N'],
  "mctier": ['M AH0 K T AY1 ER0', 'M AH0 K T IH1 R'],
  "mctiernan": ['M AH0 K T AY1 R N AH0 N', 'M AH0 K T IH1 R N AH0 N'],
  "mctighe": ['M AH0 K T AY1 G'],
  "mctigue": ['M AH0 K T IY1 G'],
  "mcvay": ['M AH0 K V EY1'],
  "mcvea": ['M AH0 K V IY1'],
  "mcveigh": ['M AH0 K V EY1'],
  "mcveigh's": ['M AH0 K V EY1 Z'],
  "mcvey": ['M AH0 K V EY1'],
  "mcvicar": ['M AH0 K V IH1 K ER0'],
  "mcvicker": ['M AH0 K V IH1 K ER0'],
  "mcvoy": ['M AH0 K V OY1'],
  "mcwain": ['M AH0 K W EY1 N'],
  "mcwaters": ['M AH0 K W AO1 T ER0 Z'],
  "mcwatters": ['M AH0 K W AO1 T ER0 Z'],
  "mcweeney": ['M AH0 K W IY1 N IY0'],
  "mcwethy": ['M AH0 K W EH1 TH IY0'],
  "mcwherter": ['M AH0 K W ER1 T ER0'],
  "mcwhinney": ['M AH0 K W IH1 N IY0'],
  "mcwhirt": ['M AH0 K W ER1 T'],
  "mcwhirter": ['M AH0 K W ER1 T ER0'],
  "mcwhite": ['M AH0 K W AY1 T'],
  "mcwhorter": ['M AH0 K W AO1 R T ER0'],
  "mcwilliam": ['M AH0 K W IH1 L Y AH0 M'],
  "mcwilliams": ['M AH0 K W IH1 L Y AH0 M Z'],
  "mcwright": ['M AH0 K R AY1 T'],
  "mczeal": ['M AH0 K Z IY1 L'],
  "md": ['EH2 M D IY1'],
  "me": ['M IY1'],
  "mea": ['M IY1'],
  "meacham": ['M IY1 CH AH0 M'],
  "meachum": ['M IY1 CH AH0 M'],
  "mead": ['M IY1 D'],
  "mead's": ['M IY1 D Z'],
  "meade": ['M IY1 D'],
  "meader": ['M IY1 D ER0'],
  "meaders": ['M IY1 D ER0 Z'],
  "meador": ['M IY1 D ER0'],
  "meadors": ['M IY1 D ER0 Z'],
  "meadow": ['M EH1 D OW2'],
  "meadowland": ['M EH1 D OW0 L AE1 N D'],
  "meadowlands": ['M EH1 D OW0 L AE1 N D Z'],
  "meadowlark": ['M EH1 D OW0 L AA2 R K'],
  "meadows": ['M EH1 D OW2 Z'],
  "meads": ['M IY1 D Z'],
  "meager": ['M IY1 G ER0'],
  "meagher": ['M AA1 R'],
  "meaker": ['M IY1 K ER0'],
  "meakin": ['M IY1 K IH0 N'],
  "meal": ['M IY1 L'],
  "meal's": ['M IY1 L Z'],
  "mealer": ['M IY1 L ER0'],
  "mealey": ['M IY1 L IY0'],
  "mealing": ['M IY1 L IH0 NG'],
  "mealo": ['M IY1 AH0 L OW1'],
  "mealor": ['M IY1 L ER0'],
  "meals": ['M IY1 L Z'],
  "mealtime": ['M IY1 L T AY2 M'],
  "mealy": ['M IY1 L IY0'],
  "mealynose": ['M IY1 L IY0 N OW2 Z'],
  "mealynosed": ['M IY1 L IY0 N OW2 Z D'],
  "mean": ['M IY1 N'],
  "meander": ['M IY0 AE1 N D ER0'],
  "meandered": ['M IY0 AE1 N D ER0 D'],
  "meandering": ['M IY0 AE1 N D ER0 IH0 NG'],
  "meanders": ['M IY0 AE1 N D ER0 Z'],
  "meaner": ['M IY1 N ER0'],
  "meanest": ['M IY1 N AH0 S T'],
  "meaney": ['M IY1 N IY0'],
  "meaning": ['M IY1 N IH0 NG'],
  "meaningful": ['M IY1 N IH0 NG F AH0 L'],
  "meaningfully": ['M IY1 N IH0 NG F AH0 L IY0'],
  "meaningless": ['M IY1 N IH0 NG L AH0 S'],
  "meanings": ['M IY1 N IH0 NG Z'],
  "meanness": ['M IY1 N N AH0 S'],
  "meanor": ['M IY1 N ER0'],
  "means": ['M IY1 N Z'],
  "means'": ['M IY1 N Z'],
  "meant": ['M EH1 N T'],
  "meantime": ['M IY1 N T AY2 M'],
  "meanwhile": ['M IY1 N W AY2 L'],
  "meany": ['M IY1 N IY0'],
  "mear": ['M IH1 R'],
  "meara": ['M IY1 R AH0'],
  "meares": ['M IY1 R Z'],
  "mearns": ['M ER1 N Z'],
  "mears": ['M IH1 R Z'],
  "mease": ['M IY1 Z'],
  "measel": ['M IY1 Z AH0 L'],
  "measles": ['M IY1 Z AH0 L Z'],
  "measly": ['M IY1 Z L IY0'],
  "measurable": ['M EH1 ZH ER0 AH0 B AH0 L'],
  "measurably": ['M EH1 ZH ER0 AH0 B L IY0'],
  "measure": ['M EH1 ZH ER0'],
  "measure's": ['M EH1 ZH ER0 Z'],
  "measured": ['M EH1 ZH ER0 D'],
  "measurement": ['M EH1 ZH ER0 M AH0 N T'],
  "measurements": ['M EH1 ZH ER0 M AH0 N T S'],
  "measures": ['M EH1 ZH ER0 Z'],
  "measurex": ['M EH1 Z ER0 AH0 K S'],
  "measuring": ['M EH1 ZH ER0 IH0 NG'],
  "meat": ['M IY1 T'],
  "meat-eating": ['M IY1 T IY2 T IH0 NG'],
  "meatball": ['M IY1 T B AO2 L'],
  "meatballs": ['M IY1 T B AO2 L Z'],
  "meath": ['M IY1 TH'],
  "meatier": ['M IY1 T IY0 ER0'],
  "meatless": ['M IY1 T L AH0 S'],
  "meatloaf": ['M IY1 T L OW0 F'],
  "meatpacker": ['M IY1 T P AE2 K ER0'],
  "meatpackers": ['M IY1 T P AE2 K ER0 Z'],
  "meatpacking": ['M IY1 T P AE2 K IH0 NG'],
  "meats": ['M IY1 T S'],
  "meaty": ['M IY1 T IY0'],
  "meaux": ['M OW1'],
  "meave": ['M IY1 V'],
  "mebane": ['M EH1 B AH0 N'],
  "meca": ['M EH1 K AH0'],
  "mecca": ['M EH1 K AH0'],
  "mecca's": ['M EH1 K AH0 Z'],
  "mech": ['M EH1 K'],
  "mecham": ['M EH1 CH AH0 M'],
  "mecham's": ['M EH1 CH AH0 M Z'],
  "mechanic": ['M AH0 K AE1 N IH0 K', 'M IH0 K AE1 N IH0 K'],
  "mechanical": ['M AH0 K AE1 N IH0 K AH0 L'],
  "mechanically": ['M AH0 K AE1 N IH0 K L IY0'],
  "mechanics": ['M AH0 K AE1 N IH0 K S'],
  "mechanics'": ['M AH0 K AE1 N IH0 K S'],
  "mechanicsburg": ['M AH0 K AE1 N IH0 K S B ER0 G'],
  "mechanism": ['M EH1 K AH0 N IH2 Z AH0 M'],
  "mechanisms": ['M EH1 K AH0 N IH2 Z AH0 M Z'],
  "mechanistic": ['M EH2 K AH0 N IH1 S T IH0 K'],
  "mechanization": ['M EH2 K AH0 N AH0 Z EY1 SH AH0 N'],
  "mechanize": ['M EH1 K AH0 N AY2 Z'],
  "mechanized": ['M EH1 K AH0 N AY2 Z D'],
  "meche": ['M EH1 CH'],
  "mechem": ['M EH1 K IH0 M', 'M EH1 CH AH0 M'],
  "mechler": ['M EH1 K L ER0'],
  "mechling": ['M EH1 K L IH0 NG'],
  "meciar": ['M EH1 S IY0 AA0 R'],
  "meck": ['M EH1 K'],
  "meckel": ['M EH1 K AH0 L'],
  "meckes": ['M EH1 K S'],
  "mecklenburg": ['M EH1 K L AH0 N B ER0 G'],
  "meckler": ['M EH1 K L ER0'],
  "meckley": ['M EH1 K L IY0'],
  "meckstroth": ['M EH1 K S T R AO0 TH'],
  "mecum": ['M EH1 K AH0 M'],
  "med": ['M EH1 D'],
  "meda": ['M EY1 D AH0'],
  "medaglia": ['M EH0 D AA1 G L IY0 AH0'],
  "medal": ['M EH1 D AH0 L'],
  "medalist": ['M EH1 D AH0 L IH0 S T'],
  "medalist's": ['M EH1 D AH0 L IH0 S T S'],
  "medalists": ['M EH1 D AH0 L IH0 S T S'],
  "medallion": ['M AH0 D AE1 L Y AH0 N'],
  "medallions": ['M AH0 D AE1 L Y AH0 N Z'],
  "medals": ['M EH1 D AH0 L Z'],
  "medaphis": ['M EH1 D AH0 F IH2 S'],
  "medar": ['M EH1 D ER0'],
  "medarex": ['M EH1 D ER0 EH2 K S'],
  "medaris": ['M EY0 D AA1 R IH0 S'],
  "medcalf": ['M EH1 D K AE0 L F'],
  "medchem": ['M EH1 D K EH2 M'],
  "medchem's": ['M EH1 D K EH2 M Z'],
  "medco": ['M EH1 D K OW0'],
  "medco's": ['M EH1 D K OW0 Z'],
  "meddaugh": ['M EH1 D AO0'],
  "medders": ['M EH1 D ER0 Z'],
  "meddle": ['M EH1 D AH0 L'],
  "meddlesome": ['M EH1 D AH0 L S AH0 M'],
  "meddling": ['M EH1 D AH0 L IH0 NG', 'M EH1 D L IH0 NG'],
  "medea": ['M AH0 D IY1 AH0'],
  "medearis": ['M EH1 D ER0 IH0 S'],
  "medecin": ['M EH1 D IH0 S IH2 N'],
  "medecins": ['M EH1 D IH0 S IH2 N Z'],
  "medeiros": ['M EY0 D IH1 R OW0 Z'],
  "medel": ['M EH1 D AH0 L'],
  "medellin": ['M IH0 D EH1 L IH0 N'],
  "medema": ['M EH0 D EH1 M AH0'],
  "medendorp": ['M EH1 D EH0 N D AO0 R P'],
  "meder": ['M IY1 D ER0'],
  "mederos": ['M EH1 D ER0 OW0 Z'],
  "medes": ['M IY1 D Z'],
  "medeva": ['M EH2 D EH1 V AH0'],
  "medex": ['M EH1 D AH0 K S'],
  "medfact": ['M EH1 D F AE1 K T'],
  "medfacts": ['M EH1 D F AE1 K T S'],
  "medfirst": ['M EH1 D F ER1 S T'],
  "medfly": ['M EH1 D F L IY0'],
  "medford": ['M EH1 D F ER0 D'],
  "medgar": ['M EH1 D G ER0'],
  "medgar's": ['M EH1 D G ER0 Z'],
  "medi": ['M EH1 D IY0'],
  "media": ['M IY1 D IY0 AH0'],
  "media's": ['M IY1 D IY0 AH0 Z'],
  "medial": ['M IY1 D IY0 AH0 L', 'M IY1 D Y AH0 L'],
  "mediamark": ['M IY1 D IY0 AH0 M AA1 R K'],
  "median": ['M IY1 D IY0 AH0 N'],
  "medianews": ['M IY1 D IY0 AH0 N Y UW2 Z'],
  "mediaset": ['M IY1 D IY0 AH0 S EH2 T'],
  "mediate": ['M IY1 D IY0 EY2 T'],
  "mediated": ['M IY1 D IY0 EY2 T IH0 D'],
  "mediating": ['M IY1 D IY0 EY2 T IH0 NG'],
  "mediation": ['M IY2 D IY0 EY1 SH AH0 N'],
  "mediator": ['M IY1 D IY0 EY2 T ER0'],
  "mediators": ['M IY1 D IY0 EY2 T ER0 Z'],
  "medic": ['M EH1 D IH0 K'],
  "medic's": ['M EH1 D IH0 K S'],
  "medicaid": ['M EH1 D AH0 K EY2 D'],
  "medical": ['M EH1 D AH0 K AH0 L', 'M EH1 D IH0 K AH0 L'],
  "medical's": ['M EH1 D AH0 K AH0 L Z', 'M EH1 D IH0 K AH0 L Z'],
  "medically": ['M EH1 D AH0 K L IY0', 'M EH1 D IH0 K AH0 L IY0'],
  "medicare": ['M EH1 D AH0 K EH2 R'],
  "medicare's": ['M EH1 D AH0 K EH2 R Z'],
  "medicate": ['M EH1 D IH0 K EY2 T'],
  "medicated": ['M EH1 D IH0 K EY2 T IH0 D'],
  "medicates": ['M EH1 D IH0 K EY2 T S'],
  "medicating": ['M EH1 D IH0 K EY2 T IH0 NG'],
  "medication": ['M EH2 D AH0 K EY1 SH AH0 N'],
  "medications": ['M EH2 D AH0 K EY1 SH AH0 N Z'],
  "medici": ['M EH0 D IY1 S IY0'],
  "medicinal": ['M AH0 D IH1 S AH0 N AH0 L'],
  "medicinally": ['M AH0 D IH1 S AH0 N AH0 L IY0'],
  "medicine": ['M EH1 D AH0 S AH0 N'],
  "medicine's": ['M EH1 D AH0 S AH0 N Z'],
  "medicines": ['M EH1 D AH0 S AH0 N Z'],
  "medico": ['M EH1 D IH0 K OW2'],
  "medics": ['M EH1 D IH0 K S'],
  "medicus": ['M EH1 D IH0 K AH0 S'],
  "medieval": ['M IH0 D IY1 V AH0 L', 'M IY0 D IY1 V AH0 L', 'M IH0 D Y IY1 V AH0 L'],
  "medigap": ['M EH1 D IH0 G AE0 P'],
  "medil": ['M AH0 D IH1 L'],
  "medimmune": ['M EH1 D IH0 M Y UW2 N'],
  "medin": ['M EY0 D IY1 N'],
  "medina": ['M AH0 D AY1 N AH0', 'M AH0 D IY1 N AH0'],
  "medinger": ['M IY1 D IH0 NG ER0'],
  "medio": ['M IY1 D IY0 OW0', 'M EH1 D IY0 OW0'],
  "mediobanca": ['M IH0 D IY2 OW0 B AE1 NG K AH0'],
  "mediocre": ['M IY2 D IY0 OW1 K ER0'],
  "mediocrity": ['M IY2 D IY0 AA1 K R AH0 T IY0'],
  "mediplex": ['M EH1 D IH0 P L EH2 K S'],
  "mediq": ['M EH0 D IY1 K'],
  "mediscare": ['M EH1 D IH0 S K EY2 R'],
  "medisgroup": ['M EH1 D IH0 S G R UW2 P'],
  "medisgroups": ['M EH1 D IH0 S G R UW2 P S'],
  "meditate": ['M EH1 D AH0 T EY2 T'],
  "meditating": ['M EH1 D AH0 T EY2 T IH0 NG'],
  "meditation": ['M EH2 D AH0 T EY1 SH AH0 N'],
  "meditations": ['M EH2 D IH0 T EY1 SH AH0 N Z'],
  "meditative": ['M EH1 D AH0 T EY2 T IH0 V'],
  "mediterranean": ['M EH2 D AH0 T ER0 EY1 N IY0 AH0 N'],
  "meditrust": ['M EH1 D IH0 T R AH2 S T'],
  "meditz": ['M EH1 D IH0 T S'],
  "medium": ['M IY1 D IY0 AH0 M'],
  "mediums": ['M IY1 D IY0 AH0 M Z'],
  "medivac": ['M EH1 D IH0 V AE2 K'],
  "medivaced": ['M EH1 D IH0 V AE2 K T'],
  "medland": ['M EH1 D L AH0 N D'],
  "medlar": ['M EH1 D L ER0'],
  "medlen": ['M EH1 D L AH0 N'],
  "medler": ['M EH1 D L ER0'],
  "medley": ['M EH1 D L IY0'],
  "medlin": ['M EH1 D L IH0 N'],
  "medlock": ['M EH1 D L AH0 K'],
  "mednick": ['M EH1 D N IH0 K'],
  "medoc": ['M EH2 D AO1 K'],
  "medoff": ['M EH1 D AO0 F'],
  "medora": ['M EY0 D AO1 R AH0'],
  "medrano": ['M EH0 D R AA1 N OW0'],
  "medserv": ['M EH1 D S ER0 V'],
  "medsker": ['M EH1 D S K ER0'],
  "medstone": ['M EH1 D S T OW2 N'],
  "medtronic": ['M EH0 D T R AA1 N IH0 K'],
  "medulla": ['M IH0 D AH1 L AH0', 'M IH0 D UW1 L AH0'],
  "medusa": ['M AH0 D UW1 S AH0'],
  "medusas": ['M AH0 D UW1 S AH0 Z'],
  "medved": ['M EH1 D V AH0 D'],
  "medvedev": ['M EH1 D V AH0 D EH2 V'],
  "medwin": ['M EH1 D W IH0 N'],
  "mee": ['M IY1'],
  "meece": ['M IY1 S'],
  "meech": ['M IY1 CH'],
  "meecham": ['M IY1 CH AH0 M'],
  "meeder": ['M IY1 D ER0'],
  "meegan": ['M IY1 G AH0 N'],
  "meehan": ['M IY1 AH0 N'],
  "meehans": ['M IY1 HH AE2 N Z', 'M IY1 AH0 N Z'],
  "meehl": ['M IY1 L'],
  "meek": ['M IY1 K'],
  "meeker": ['M IY1 K ER0'],
  "meekins": ['M IY1 K IH0 N Z'],
  "meekly": ['M IY1 K L IY0'],
  "meeks": ['M IY1 K S'],
  "meeler": ['M IY1 L ER0'],
  "meenaghan": ['M IY1 N AH0 HH AE0 N'],
  "meenan": ['M IY1 N AH0 N'],
  "meents": ['M IY1 N T S'],
  "meer": ['M IY1 ER0'],
  "meers": ['M IY1 ER0 Z'],
  "meert": ['M IY1 R T'],
  "mees": ['M IY1 Z'],
  "meese": ['M IY1 S'],
  "meese's": ['M IY1 S IH0 Z'],
  "meester": ['M IY1 S T ER0'],
  "meet": ['M IY1 T'],
  "meeting": ['M IY1 T IH0 NG'],
  "meeting's": ['M IY1 T IH0 NG Z'],
  "meetings": ['M IY1 T IH0 NG Z'],
  "meets": ['M IY1 T S'],
  "meetup": ['M IY1 T AH2 P'],
  "meetze": ['M IY1 T Z'],
  "meeuwsen": ['M IY2 UW1 S AH0 N'],
  "mefferd": ['M EH1 F ER0 D'],
  "meffert": ['M EH1 F ER0 T'],
  "mefford": ['M EH1 F ER0 D'],
  "meg": ['M EH1 G'],
  "mega": ['M EH1 G AH0'],
  "megabit": ['M EH1 G AH0 B IH0 T'],
  "megabuck": ['M EH1 G AH0 B AH2 K'],
  "megabucks": ['M EH1 G AH0 B AH2 K S'],
  "megabyte": ['M EH1 G AH0 B AY2 T'],
  "megabytes": ['M EH1 G AH0 B AY2 T S'],
  "megacarrier": ['M EH1 G AH0 K AE2 R Y ER0'],
  "megacarriers": ['M EH2 G AH0 K AE1 R Y ER0 Z'],
  "megace": ['M IY1 G AH0 S'],
  "megadeal": ['M EH1 G AH0 D IY2 L'],
  "megadeals": ['M EH1 G AH0 D IY2 L Z'],
  "megadeath": ['M EH1 G AH0 D EH2 TH'],
  "megafood": ['M EH1 G AH0 F UW2 D'],
  "megafoods": ['M EH1 G AH0 F UW2 D Z'],
  "megahertz": ['M EH1 G AH0 HH ER0 T S'],
  "megahouse": ['M EH1 G AH0 HH AW2 S'],
  "megahouses": ['M EH1 G AH0 HH AW2 S IH0 Z'],
  "megalomania": ['M EH2 G AH0 L OW0 M EY1 N IY0 AH0'],
  "megalomaniac": ['M EH2 G AH0 L OW0 M EY1 N IY0 AE2 K'],
  "megalopolis": ['M EH2 G AH0 L AA1 P AH0 L AH0 S'],
  "megamerger": ['M EH1 G AH0 M ER2 JH ER0'],
  "megamergers": ['M EH1 G AH0 M ER2 JH ER0 Z'],
  "megan": ['M EY1 G AH0 N'],
  "megan's": ['M EY1 G AH0 N Z'],
  "megaphone": ['M EH1 G AH0 F OW2 N'],
  "megaphones": ['M EH1 G AH0 F OW2 N Z'],
  "megaplex": ['M EH1 G AH0 P L EH1 K S'],
  "megaquest": ['M EH1 G AH0 K W EH2 S T'],
  "megaquest's": ['M EH1 G AH0 K W EH2 S T S'],
  "megarry": ['M EH1 G ER0 IY0'],
  "megastore": ['M EH1 G AH0 S T AO2 R'],
  "megastores": ['M EH1 G AH0 S T AO2 R Z'],
  "megaton": ['M EH1 G AH0 T AH2 N'],
  "megatons": ['M EH1 G AH0 T AH2 N Z'],
  "megatron": ['M EH1 G AH0 T R AA2 N'],
  "megawatt": ['M EH1 G AH0 W AA2 T'],
  "megawatts": ['M EH1 G AH0 W AA2 T S'],
  "megee": ['M EH1 JH IY0'],
  "meger": ['M EH1 G ER0'],
  "megginson": ['M EH1 G IH0 N S AH0 N'],
  "meggison": ['M EH1 G IH0 S AH0 N'],
  "meggs": ['M EH1 G Z'],
  "meghan": ['M EH1 G AH0 N'],
  "meghdar": ['M EH1 G D AA0 R'],
  "megill": ['M EH1 JH AH0 L'],
  "megna": ['M EH1 G N AH0'],
  "megner": ['M EH1 G N ER0'],
  "mehaffey": ['M EH1 HH AH0 F IY0', 'M AH0 HH AE1 F IY0'],
  "mehalkoff": ['M EH0 HH AE1 L K AO0 F'],
  "mehall": ['M AH0 HH AO1 L'],
  "mehan": ['M EY1 HH AE0 N'],
  "mehanovitch": ['M AH0 HH AE1 N AH0 V AH0 CH'],
  "meharg": ['M EY2 HH AA1 R G'],
  "meharry": ['M EY2 HH AE1 R IY0'],
  "mehdi": ['M EH1 D IY0'],
  "mehetabel": ['M IH0 HH EH1 T AH0 B IH0 L'],
  "mehitabel": ['M EH1 HH IH0 T AH0 B AH0 L', 'M EH0 HH IH1 T AH0 B AH0 L'],
  "mehitabelle": ['M EH1 HH IH0 T AH0 B AH0 L'],
  "mehl": ['M EH1 L'],
  "mehlberg": ['M EH1 L B ER0 G'],
  "mehle": ['M EH1 HH AH0 L'],
  "mehlenbacher": ['M EH1 L IH0 N B AA0 K ER0'],
  "mehler": ['M EH1 L ER0'],
  "mehlhaff": ['M EH1 L HH AH0 F'],
  "mehlhoff": ['M EH1 L HH AO0 F'],
  "mehlhorn": ['M EH1 L HH ER0 N'],
  "mehling": ['M EH1 L IH0 NG'],
  "mehlman": ['M EH1 L M AH0 N'],
  "mehmet": ['M EH1 M AH0 T'],
  "mehner": ['M EH1 N ER0'],
  "mehnert": ['M EH1 N ER0 T'],
  "mehr": ['M EH1 R'],
  "mehrabian": ['M EH2 R EY1 B IY0 AH0 N', 'M ER2 EY1 B IY0 AH0 N'],
  "mehran": ['M EH1 R AH0 N'],
  "mehrens": ['M EH1 R AH0 N Z'],
  "mehrer": ['M EH1 R ER0'],
  "mehring": ['M EH1 R IH0 NG'],
  "mehringer": ['M EH1 R IH0 NG ER0'],
  "mehrtens": ['M EH1 R T AH0 N Z'],
  "mehta": ['M EH1 T AH0'],
  "mehta's": ['M EH1 T AH0 Z'],
  "mehul": ['M EH1 HH UH2 L'],
  "mei": ['M EY1'],
  "mei-ling": ['M EY1 L IH1 NG'],
  "meidinger": ['M AY1 D IH0 NG ER0'],
  "meidl": ['M IY1 D AH0 L'],
  "meier": ['M AY1 ER0'],
  "meier's": ['M AY1 ER0 Z'],
  "meierfeld": ['M AY1 R F EH2 L D'],
  "meighan": ['M EY1 G AH0 N', 'M IY1 AH0 N'],
  "meigher": ['M EY1 G ER0'],
  "meiji": ['M EY1 JH IY2'],
  "meikle": ['M IY1 K AH0 L'],
  "mein": ['M IY1 N'],
  "meincke": ['M AY1 NG K IY0'],
  "meinders": ['M AY1 N D ER0 Z'],
  "meindl": ['M AY1 N D AH0 L'],
  "meine": ['M IY1 N'],
  "meinecke": ['M AY1 N IH0 K IY0'],
  "meineke": ['M AY1 N IH0 K IY0'],
  "meinen": ['M AY1 N AH0 N'],
  "meiner": ['M AY1 N ER0'],
  "meiners": ['M AY1 N ER0 Z'],
  "meinert": ['M AY1 N ER0 T'],
  "meinertzhagen": ['M AY1 N ER0 T S HH AA2 G AH0 N'],
  "meinhardt": ['M AY1 N HH AA2 R T'],
  "meinhart": ['M AY1 N HH AA2 R T'],
  "meinhold": ['M AY1 N HH OW2 L D'],
  "meininger": ['M AY1 N IH0 NG ER0'],
  "meinke": ['M IY1 NG K'],
  "meints": ['M AY1 N T S'],
  "meinzer": ['M AY1 N Z ER0'],
  "meiosis": ['M AY0 OW1 S AH0 S'],
  "meir": ['M EY1 R', 'M AY1 R'],
  "meiring": ['M AY1 R IH0 NG'],
  "meis": ['M IY1 Z'],
  "meisch": ['M AY1 SH'],
  "meise": ['M IY1 S'],
  "meisel": ['M AY1 S AH0 L'],
  "meisels": ['M AY1 S AH0 L Z'],
  "meisenheimer": ['M AY1 S IH0 N HH AY0 M ER0'],
  "meiser": ['M AY1 S ER0'],
  "meishan": ['M AY1 SH AH0 N'],
  "meisinger": ['M AY1 S IH0 N JH ER0'],
  "meisler": ['M AY1 S AH0 L ER0', 'M AY1 S L ER0'],
  "meisner": ['M AY1 S N ER0'],
  "meiss": ['M AY1 S'],
  "meissner": ['M AY1 S N ER0'],
  "meister": ['M AY1 S T ER0'],
  "meisters": ['M AY1 S T ER0 Z'],
  "meitz": ['M IY1 T S'],
  "meitzler": ['M AY1 T S L ER0'],
  "meixner": ['M IY1 K S N ER0'],
  "meiyuh": ['M EY0 Y UW1'],
  "mejia": ['M EY1 Y IY0 AH0'],
  "mejias": ['M EY0 Y IY1 AH0 Z'],
  "mekeel": ['M EH1 K IY0 L'],
  "mekong": ['M EY1 K AA0 NG'],
  "mel": ['M EH1 L'],
  "mel's": ['M EH1 L Z'],
  "melador": ['M EH1 L AH0 D AO0 R'],
  "melamed": ['M EH1 L AH0 M EH0 D'],
  "melamine": ['M EH1 L AH0 M IY2 N'],
  "melanby": ['M EH1 L AH0 N B IY0'],
  "melancholic": ['M EH2 L AH0 N K AA1 L IH0 K'],
  "melancholy": ['M EH1 L AH0 N K AA2 L IY0'],
  "melancon": ['M IH0 L AE1 N K AH0 N'],
  "meland": ['M EH1 L AH0 N D'],
  "melander": ['M EH1 L AH0 N D ER0'],
  "melanesian": ['M EH2 L AH0 N IY1 ZH AH0 N'],
  "melanesians": ['M EH2 L AH0 N IY1 ZH AH0 N Z'],
  "melange": ['M EH1 L AE0 NG'],
  "melanie": ['M EH1 L AH0 N IY0'],
  "melanin": ['M EH1 L AH0 N AH0 N', 'M EH1 L AH0 N IH0 N'],
  "melanoma": ['M EH2 L AH0 N OW1 M AH0'],
  "melanson": ['M EH1 L AH0 N S AH0 N'],
  "melantha": ['M IH0 L AE1 N DH AH0'],
  "melany": ['M EH1 L AH0 N IY0'],
  "melaragno": ['M EH0 L AA0 R AA1 G N OW0'],
  "melatonin": ['M EH2 L AH0 T OW1 N IH0 N'],
  "melatonin's": ['M EH2 L AH0 T OW1 N IH0 N Z'],
  "melatonins": ['M EH2 L AH0 T OW1 N IH0 N Z'],
  "melba": ['M EH1 L B AH0'],
  "melberg": ['M EH1 L B ER0 G'],
  "melbourne": ['M EH1 L B ER0 N', 'M EH1 L B AO0 N'],
  "melburn": ['M EH1 L B ER0 N'],
  "melby": ['M EH1 L B IY0'],
  "melcher": ['M EH1 L CH ER0'],
  "melchert": ['M EH1 L CH ER0 T'],
  "melching": ['M EH1 L CH IH0 NG'],
  "melchior": ['M EY0 L CH IY1 ER0'],
  "melchiorre": ['M EH0 L K IY0 AO1 R EY0'],
  "melchizedek": ['M EH2 K IY1 Z EH0 D EH2 K'],
  "melchor": ['M EH1 L CH ER0'],
  "meld": ['M EH1 L D'],
  "melded": ['M EH1 L D AH0 D', 'M EH1 L D IH0 D'],
  "melder": ['M EH1 L D ER0'],
  "melding": ['M EH1 L D IH0 NG'],
  "meldon": ['M EH1 L D AH0 N'],
  "meldons": ['M EH1 L D AH0 N Z'],
  "meldrum": ['M EH1 L D R AH0 M'],
  "mele": ['M IY1 L'],
  "melear": ['M EH1 L ER0'],
  "melee": ['M EY1 L EY2'],
  "meleis": ['M AH0 L EY1 AH0 S'],
  "melendez": ['M AH0 L EH1 N D EH0 Z'],
  "melendrez": ['M EY0 L EY1 N D R EH0 Z'],
  "melendy": ['M IH0 L EH1 N D IY0'],
  "melero": ['M EY0 L EH1 R OW0'],
  "meleski": ['M IH0 L EH1 S K IY0'],
  "melessa": ['M EH0 L EH1 S AH0'],
  "melfi": ['M EH1 L F IY0'],
  "melgaard": ['M EH1 L G AA2 R D'],
  "melgar": ['M EY0 L G AA1 R'],
  "melgoza": ['M EH0 L G OW1 Z AH0'],
  "melhem": ['M EH1 L AH0 M'],
  "melhorn": ['M EH1 L HH ER0 N'],
  "meli": ['M EH1 L IY0'],
  "melia": ['M EH1 L IY0 AH0'],
  "melican": ['M EH1 L IH0 K AH0 N'],
  "melicent": ['M EH1 L IH0 S AH0 N T'],
  "melichar": ['M EH1 L IH0 K ER0'],
  "melick": ['M EH1 L IH0 K'],
  "melikian": ['M IH0 L IH1 K IY0 AH0 N'],
  "melillo": ['M EH0 L IH1 L OW0'],
  "melin": ['M EH1 L IH0 N'],
  "melina": ['M EH0 L IY1 N AH0'],
  "melinda": ['M AH0 L IH1 N D AH0'],
  "meline": ['M EH1 L AY0 N'],
  "meling": ['M EH1 L IH0 NG'],
  "melisent": ['M EH1 L IH0 S AH0 N T'],
  "melissa": ['M AH0 L IH1 S AH0'],
  "melissa's": ['M AH0 L IH1 S AH0 Z'],
  "melisse": ['M EH1 L IH0 S'],
  "melita": ['M EH0 L IY1 T AH0'],
  "melito": ['M EH0 L IY1 T OW0'],
  "melitta": ['M EH0 L IY1 T AH0'],
  "melius": ['M IY1 L IY0 IH0 S'],
  "melka": ['M EH1 L K AH0'],
  "melkar": ['M EH1 L K AA2 R'],
  "melkar's": ['M EH1 L K AA2 R Z'],
  "melkonian": ['M EH2 L K OW1 N IY0 AH0 N'],
  "mell": ['M EH1 L'],
  "mella": ['M EH1 L AH0'],
  "melland": ['M EH1 L AH0 N D'],
  "mellaril": ['M EH1 L ER0 AH0 L'],
  "melle": ['M EH1 L'],
  "mellem": ['M EH1 L IH0 M'],
  "mellema": ['M EH0 L EH1 M AH0'],
  "mellen": ['M EH1 L AH0 N'],
  "meller": ['M EH1 L ER0'],
  "mellett": ['M EH1 L IH0 T'],
  "melley": ['M EH1 L IY0'],
  "mellgren": ['M EH1 L G R EH0 N'],
  "melli": ['M EH1 L IY0'],
  "mellicent": ['M EY0 L IY1 S AH0 N T'],
  "mellick": ['M EH1 L IH0 K'],
  "mellie": ['M EH1 L IY0'],
  "mellin": ['M EH1 L IH0 N'],
  "melling": ['M EH1 L IH0 NG'],
  "mellinger": ['M EH1 L IH0 NG ER0'],
  "mellis": ['M EH1 L IH0 S'],
  "mellish": ['M EH1 L IH0 SH'],
  "mellitus": ['M EH1 L IH0 T AH0 S'],
  "mellman": ['M EH1 L M AH0 N'],
  "mello": ['M EH1 L OW0'],
  "melloan": ['M EH0 L OW1 N'],
  "melloan's": ['M EH0 L OW1 N Z'],
  "mellon": ['M EH1 L AH0 N'],
  "mellon's": ['M EH1 L AH0 N Z'],
  "mellonby": ['M EH1 L AH0 N B IY0'],
  "mellons": ['M EH1 L AH0 N Z'],
  "mellor": ['M EH1 L ER0'],
  "mellott": ['M EH1 L AH0 T'],
  "mellow": ['M EH1 L OW0'],
  "mellowed": ['M EH1 L OW0 D'],
  "mellowing": ['M EH1 L OW0 IH0 NG'],
  "melly": ['M EH1 L IY0'],
  "melman": ['M EH1 L M AH0 N'],
  "melnick": ['M EH1 L N IH0 K'],
  "melnik": ['M EH1 L N IH0 K'],
  "melnor": ['M EH1 L N ER0'],
  "melnyk": ['M EH1 L N IH0 K'],
  "melo": ['M EH1 L OW0'],
  "meloche": ['M EH0 L OW1 K IY0'],
  "melodic": ['M AH0 L AA1 D IH0 K'],
  "melodies": ['M EH1 L AH0 D IY0 Z'],
  "melodious": ['M AH0 L OW1 D IY0 AH0 S'],
  "melodrama": ['M EH1 L AH0 D R AA2 M AH0'],
  "melodramas": ['M EH1 L AH0 D R AA2 M AH0 Z'],
  "melodramatic": ['M EH2 L AH0 D R AH0 M AE1 T IH0 K'],
  "melody": ['M EH1 L AH0 D IY0'],
  "melon": ['M EH1 L AH0 N'],
  "melone": ['M EH0 L OW1 N IY0'],
  "meloni": ['M EH0 L OW1 N IY0'],
  "melons": ['M EH1 L AH0 N Z'],
  "melor": ['M EH2 L AO1 R', 'M AH0 L AO1 R'],
  "meloy": ['M EH1 L OY0'],
  "melquist": ['M EH1 L K W IH2 S T'],
  "melridge": ['M EH1 L R IH2 JH'],
  "melridge's": ['M EH1 L R IH2 JH IH0 Z'],
  "melrod": ['M EH1 L R AA2 D'],
  "melrose": ['M EH1 L R OW2 Z'],
  "melroy": ['M EH1 L R OY2'],
  "melson": ['M EH1 L S AH0 N'],
  "melt": ['M EH1 L T'],
  "meltdown": ['M EH1 L T D AW2 N'],
  "melted": ['M EH1 L T AH0 D', 'M EH1 L T IH0 D'],
  "melting": ['M EH1 L T IH0 NG'],
  "melton": ['M EH1 L T AH0 N'],
  "melts": ['M EH1 L T S'],
  "meltwater": ['M EH1 L T W AA2 T ER0', 'M EH1 L T W AO2 T ER0'],
  "meltz": ['M EH1 L T S'],
  "meltzer": ['M EH1 L T S ER0'],
  "melucci": ['M EH0 L UW1 CH IY0'],
  "melugin": ['M EH1 L AH0 G IH0 N'],
  "melva": ['M EH1 L V AH0'],
  "melvie": ['M EH1 L V IY0'],
  "melville": ['M EH1 L V IH0 L'],
  "melvin": ['M EH1 L V IH0 N'],
  "melvina": ['M EH0 L V IY1 N AH0'],
  "melvine": ['M EH1 L V AY2 N'],
  "melvyn": ['M EH1 L V IH0 N'],
  "melwood": ['M EH1 L W UH0 D'],
  "melzer": ['M EH1 L Z ER0'],
  "member": ['M EH1 M B ER0'],
  "member's": ['M EH1 M B ER0 Z'],
  "membered": ['M EH1 M B ER0 D'],
  "members": ['M EH1 M B ER0 Z'],
  "members'": ['M EH1 M B ER0 Z'],
  "membership": ['M EH1 M B ER0 SH IH2 P'],
  "memberships": ['M EH1 M B ER0 SH IH2 P S'],
  "membrane": ['M EH1 M B R EY2 N'],
  "membranes": ['M EH1 M B R EY2 N Z'],
  "membranous": ['M EH1 M B R AH0 N AH0 S'],
  "meme": ['M IY1 M'],
  "memel": ['M EH1 M AH0 L'],
  "memento": ['M IH0 M EH1 N T OW0'],
  "mementos": ['M IH0 M EH1 N T OW0 Z'],
  "memes": ['M EH1 M Z'],
  "memmer": ['M EH1 M ER0'],
  "memmott": ['M EH1 M AH0 T'],
  "memnon": ['M EH1 M N AA2 N'],
  "memo": ['M EH1 M OW2'],
  "memo's": ['M EH1 M OW0 Z'],
  "memoir": ['M EH1 M W AA2 R'],
  "memoirs": ['M EH1 M W AA2 R Z'],
  "memoli": ['M EH0 M OW1 L IY0'],
  "memorabilia": ['M EH2 M ER0 AH0 B IY1 L Y AH0'],
  "memorable": ['M EH1 M ER0 AH0 B AH0 L'],
  "memorably": ['M EH1 M ER0 AH0 B L IY0'],
  "memoranda": ['M EH2 M ER0 AE1 N D AH0'],
  "memorandum": ['M EH2 M ER0 AE1 N D AH0 M'],
  "memorandums": ['M EH2 M ER0 AE1 N D AH0 M Z'],
  "memorex": ['M EH1 M AO0 R EH2 K S'],
  "memorial": ['M AH0 M AO1 R IY0 AH0 L'],
  "memorialize": ['M AH0 M AO1 R IY0 AH0 L AY2 Z'],
  "memorialized": ['M AH0 M AO1 R IY0 AH0 L AY2 Z D'],
  "memorials": ['M AH0 M AO1 R IY0 AH0 L Z'],
  "memories": ['M EH1 M ER0 IY0 Z'],
  "memories'": ['M EH1 M ER0 IY2 Z'],
  "memorize": ['M EH1 M ER0 AY2 Z'],
  "memorized": ['M EH1 M ER0 AY2 Z D'],
  "memorizes": ['M EH1 M ER0 AY2 Z IH0 Z'],
  "memorizing": ['M EH1 M ER0 AY2 Z IH0 NG'],
  "memory": ['M EH1 M ER0 IY0'],
  "memos": ['M EH1 M OW0 Z'],
  "memotec": ['M EH1 M OW0 T EH2 K'],
  "memphis": ['M EH1 M F AH0 S', 'M EH1 M F IH0 S', 'M EH1 M P F AH0 S', 'M EH1 M P F IH0 S'],
  "memtec": ['M EH1 M T EH2 K'],
  "memtec's": ['M EH1 M T EH2 K S'],
  "men": ['M EH1 N'],
  "men's": ['M EH1 N Z'],
  "mena": ['M IY1 N AH0'],
  "menace": ['M EH1 N AH0 S', 'M EH1 N IH0 S'],
  "menaced": ['M EH1 N AH0 S T', 'M EH1 N IH0 S T'],
  "menaces": ['M EH1 N AH0 S IH0 Z', 'M EH1 N IH0 S IH0 Z'],
  "menachem": ['M AH0 N AA1 HH AH0 M', 'M EH1 N AH0 HH EH0 M'],
  "menacing": ['M EH1 N AH0 S IH0 NG'],
  "menacingly": ['M EH1 N AH0 S IH0 NG L IY0'],
  "menagerie": ['M AH0 N AE1 JH ER0 IY0'],
  "menahem": ['M AH0 N AA1 HH AH0 M'],
  "menaker": ['M EH1 N AH0 K ER0'],
  "menapace": ['M EH0 N AA0 P AA1 CH IY0'],
  "menard": ['M IH0 N AA1 R D'],
  "menasco": ['M EH0 N AA1 S K OW0'],
  "menasion": ['M EH0 N AE1 S IY0 AH0 N'],
  "menasion's": ['M EH0 N AE1 S IY0 AH0 N Z'],
  "menatep": ['M EH1 N AH0 T EH2 P'],
  "mencer": ['M EH1 N S ER0'],
  "mench": ['M EH1 N CH'],
  "menchaca": ['M EH0 N K AA1 K AH0'],
  "mencher": ['M EH1 N CH ER0'],
  "mencken": ['M EH1 NG K AH0 N'],
  "menconi": ['M EH0 N K OW1 N IY0'],
  "mend": ['M EH1 N D'],
  "mendacious": ['M EH0 N D EY1 SH AH0 S'],
  "mendacity": ['M EH0 N D AE1 S IH0 T IY0'],
  "mendan": ['M EH1 N D AH0 N'],
  "mende": ['M EH1 N D'],
  "mended": ['M EH1 N D IH0 D'],
  "mendel": ['M EH1 N D AH0 L'],
  "mendel's": ['M EH1 N D AH0 L Z'],
  "mendell": ['M EH1 N D EH1 L'],
  "mendelsohn": ['M EH1 N D AH0 L S AH0 N'],
  "mendelson": ['M EH1 N D AH0 L S AH0 N'],
  "mendelssohn": ['M EH1 N D AH0 L S AH0 N'],
  "mendenhall": ['M EH1 N D AH0 N HH AO2 L'],
  "mendes": ['M EY1 N D EH0 S'],
  "mendez": ['M EH0 N D EH1 Z', 'M EH1 N D EH0 Z'],
  "mendicino": ['M EH0 N D IY0 CH IY1 N OW0'],
  "mendieta": ['M EH0 N D IY1 T AH0'],
  "mendillo": ['M EH2 N D IH1 L OW0'],
  "mending": ['M EH1 N D IH0 NG'],
  "mendiola": ['M EH2 N D IY0 OW1 L AH0'],
  "mendivil": ['M EY0 N D IY0 V IY1 L'],
  "mendlowitz": ['M EH1 N D L AH0 W IH0 T S'],
  "mendocino": ['M EH2 N D AH0 S IY1 N OW0'],
  "mendola": ['M EH0 N D OW1 L AH0'],
  "mendolia": ['M EH0 N D OW1 L IY0 AH0'],
  "mendonca": ['M EH0 N D OW1 N K AH0'],
  "mendonsa": ['M EH2 N D AA1 N S AH0'],
  "mendosa": ['M EH0 N D OW1 S AH0'],
  "mendota": ['M EH0 N D OW1 T AH0', 'M EH0 N D AA1 T AH0'],
  "mendoza": ['M EH0 N D OW1 Z AH0'],
  "mendyk": ['M EH1 N D IH0 K'],
  "menear": ['M IH0 N IH1 R'],
  "meneely": ['M IH0 N IY1 L IY0'],
  "menees": ['M EH1 N IY1 Z'],
  "menefee": ['M EH1 N IH0 F IY0'],
  "menem": ['M EH1 N AH0 M'],
  "menem's": ['M EH1 N AH0 M Z'],
  "menendez": ['M EH0 N EH1 N D EH0 Z'],
  "meneses": ['M EY0 N EY1 S EH0 S'],
  "menezes": ['M EY0 N EY1 Z EH0 S'],
  "meng": ['M EH1 NG'],
  "menge": ['M EH1 N JH'],
  "mengel": ['M EH1 NG G AH0 L'],
  "mengele": ['M EH1 NG G AH0 L AH0'],
  "menger": ['M EH1 N JH ER0'],
  "mengers": ['M EH1 NG G ER0 Z'],
  "menges": ['M EH1 N JH IH0 Z'],
  "menghini": ['M EH0 N G IY1 N IY0'],
  "mengistu": ['M EH2 NG G IY1 S T UW0'],
  "menhaden": ['M EH0 N HH EY1 D AH0 N'],
  "menia": ['M IY1 N Y AH0'],
  "menial": ['M IY1 N IY0 AH0 L'],
  "menifee": ['M EH1 N IH0 F IY2'],
  "menil": ['M EH0 N IY1 L'],
  "meningitis": ['M EH2 N AH0 N JH AY1 T AH0 S'],
  "menino": ['M AH0 N IY1 N OW0'],
  "menjivar": ['M EY0 N Y IY0 V AA1 R'],
  "menk": ['M EH1 NG K'],
  "menka": ['M EH1 NG K AH0'],
  "menke": ['M EH1 NG K'],
  "menken": ['M EH1 NG K AH0 N'],
  "menkes": ['M EH1 NG K S'],
  "menlo": ['M EH1 N L OW0'],
  "menn": ['M EH1 N'],
  "menna": ['M EH1 N AH0'],
  "mennan": ['M EH1 N AH0 N'],
  "mennan's": ['M EH1 N AH0 N Z'],
  "menne": ['M EH1 N'],
  "mennella": ['M EH2 N EH1 L AH0'],
  "mennen": ['M EH1 N AH0 N'],
  "mennenga": ['M IH0 N EH1 NG G AH0'],
  "menning": ['M EH1 N IH0 NG'],
  "menninger": ['M EH1 N IH0 NG ER0'],
  "mennini": ['M EH0 N IY1 N IY0'],
  "mennonite": ['M EH1 N AH0 N AY2 T'],
  "mennonites": ['M EH1 N AH0 N AY2 T S'],
  "meno": ['M EY1 N OW0'],
  "menon": ['M EY0 N AO1 N'],
  "menopausal": ['M EH2 N AH0 P AW1 Z AH0 L'],
  "menopause": ['M EH1 N AH0 P AW2 Z'],
  "menor": ['M EH1 N ER0'],
  "menorah": ['M AH0 N AO1 R AH0'],
  "menorah's": ['M AH0 N AO1 R AH0 Z'],
  "menorahs": ['M AH0 N AO1 R AH0 Z'],
  "menotomy": ['M IH0 N AA1 T IH0 M IY0'],
  "menotti": ['M EH0 N AO1 T IY2'],
  "menoyo": ['M AH0 N OY1 OW0'],
  "mens": ['M EH1 N Z'],
  "mensah": ['M EH1 N S AH0'],
  "mensch": ['M EH1 N SH'],
  "menschville": ['M EH1 N SH V IH0 L'],
  "menser": ['M EH1 N S ER0'],
  "mensik": ['M EH1 N S IH0 K'],
  "mensing": ['M EH1 N S IH0 NG'],
  "mensinger": ['M EH1 N S IH0 N JH ER0'],
  "menstrual": ['M EH1 N S T R UW0 AH0 L', 'M EH1 N S T R AH0 L'],
  "menstruate": ['M EH1 N S T R UW0 EY2 T'],
  "menstruation": ['M EH2 N S T R UW0 EY1 SH AH0 N'],
  "menswear": ['M EH1 N Z W EY2 R'],
  "mental": ['M EH1 N T AH0 L'],
  "mentality": ['M EH0 N T AE1 L AH0 T IY0', 'M EH0 N T AE1 L IH0 T IY0'],
  "mentally": ['M EH1 N T AH0 L IY0', 'M EH1 N AH0 L IY0'],
  "mente": ['M EH1 N T'],
  "mentel": ['M EY0 N T EH1 L'],
  "menter": ['M EH1 N T ER0'],
  "menthol": ['M EH1 N TH AO0 L'],
  "mentholatum": ['M EH2 N TH AH0 L EY1 T AH0 M'],
  "mentink": ['M EH1 N T IH0 NG K'],
  "mention": ['M EH1 N SH AH0 N'],
  "mentioned": ['M EH1 N SH AH0 N D'],
  "mentioning": ['M EH1 N SH AH0 N IH0 NG'],
  "mentions": ['M EH1 N SH AH0 N Z'],
  "mento": ['M EH1 N T OW0'],
  "menton": ['M EH1 N T AH0 N'],
  "mentor": ['M EH1 N T AO2 R', 'M EH1 N T ER0'],
  "mentor's": ['M EH1 N T AO2 R Z'],
  "mentored": ['M EH1 N T ER0 D'],
  "mentoring": ['M EH1 N T ER0 IH0 NG'],
  "mentors": ['M EH1 N T ER0 Z', 'M EH1 N T AO2 R Z'],
  "mentorship": ['M EH1 N T ER0 SH IH2 P'],
  "mentz": ['M EH1 N T S'],
  "mentzel": ['M EH1 N T Z AH0 L'],
  "mentzer": ['M EH1 N T Z ER0'],
  "menu": ['M EH1 N Y UW0'],
  "menu's": ['M EH1 N UW0 Z'],
  "menudo": ['M AH0 N Y UW1 D OW0'],
  "menuhin": ['M AH0 N UW1 HH IH0 N', 'M IH0 N Y UW1 IH0 N'],
  "menus": ['M EH1 N Y UW0 Z'],
  "menz": ['M EH1 N Z'],
  "menze": ['M EH1 N Z'],
  "menzel": ['M EH1 N Z AH0 L'],
  "menzer": ['M EH1 N Z ER0'],
  "menzie": ['M EH1 N Z IY0'],
  "menzies": ['M EH1 N Z IY0 Z'],
  "menzione": ['M EH0 N Z IY0 OW1 N IY0', 'M EH0 N Z Y OW1 N IY0'],
  "meo": ['M IY1 OW0'],
  "meola": ['M IY0 AA1 L AH0'],
  "meow": ['M IY0 AW1'],
  "mequon": ['M EH1 K W AH0 N'],
  "mer": ['M EH1 R', 'M ER1'],
  "merabank": ['M EH1 R AH0 B AE2 NG K'],
  "meranda": ['M ER0 AA1 N D AH0'],
  "meraz": ['M EH1 R AA0 Z'],
  "merc": ['M ER1 K', 'M AA1 R K'],
  "merc's": ['M ER1 K S', 'M AA1 R K S'],
  "mercadante": ['M ER0 K AA0 D AA1 N T IY2'],
  "mercado": ['M ER0 K AA1 D OW0'],
  "mercantil": ['M ER0 K AE1 N T IH0 L'],
  "mercantile": ['M ER1 K AH0 N T AY2 L'],
  "mercantile's": ['M ER1 K AH0 N T IY2 L Z'],
  "mercantilism": ['M ER0 K AE1 N T AH0 L IH2 Z AH0 M'],
  "mercantilist": ['M ER0 K AE1 N T AH0 L IH0 S T'],
  "mercator": ['M ER0 K EY1 T ER0'],
  "merce": ['M ER1 S'],
  "merced": ['M ER1 S T'],
  "mercedes": ['M ER0 S EY1 D IY0 Z'],
  "mercedes's": ['M ER0 S EY1 D IY0 Z', 'M ER0 S EY1 D IY0 Z IH0 Z'],
  "mercedeses": ['M ER2 S EY1 D IY2 Z IH0 Z', 'M ER2 S EY1 D IY2 Z'],
  "mercenaries": ['M ER1 S AH0 N EH2 R IY0 Z'],
  "mercenary": ['M ER1 S AH0 N EH2 R IY0'],
  "mercer": ['M ER1 S ER0'],
  "merchandise": ['M ER1 CH AH0 N D AY2 Z'],
  "merchandiser": ['M ER1 CH AH0 N D AY2 Z ER0'],
  "merchandisers": ['M ER1 CH AH0 N D AY2 Z ER0 Z'],
  "merchandising": ['M ER1 CH AH0 N D AY2 Z IH0 NG'],
  "merchant": ['M ER1 CH AH0 N T'],
  "merchant's": ['M ER1 CH AH0 N T S'],
  "merchantmen": ['M ER1 CH AH0 N T M IH0 N'],
  "merchants": ['M ER1 CH AH0 N T S'],
  "merchants'": ['M ER1 CH AH0 N T S'],
  "merchantsbank": ['M ER1 CH AH0 N T S B AE2 NG K'],
  "merci": ['M ER0 S IY1'],
  "mercier": ['M ER1 S IY2 ER0'],
  "mercies": ['M ER1 S IY2 Z'],
  "merciful": ['M ER1 S IH0 F AH0 L'],
  "mercifully": ['M ER1 S IH0 F AH0 L IY2', 'M ER1 S IH0 F L IY0'],
  "merciless": ['M ER1 S AH0 L AH0 S'],
  "mercilessly": ['M ER1 S AH0 L AH0 S L IY0'],
  "merck": ['M ER1 K'],
  "merck's": ['M ER1 K S'],
  "mercland": ['M ER1 K L AE0 N D'],
  "mercosur": ['M ER1 K OW2 S ER2'],
  "mercure": ['M ER0 K UH1 R'],
  "mercuri": ['M ER0 K UH1 R IY0'],
  "mercurial": ['M ER0 K Y UH1 R IY0 AH0 L'],
  "mercuric": ['M ER0 K Y UH1 R IH0 K'],
  "mercurio": ['M ER0 K UH1 R IY0 OW2'],
  "mercury": ['M ER1 K Y ER0 IY0'],
  "mercury's": ['M ER1 K Y ER0 IY0 Z'],
  "mercy": ['M ER1 S IY0'],
  "merdyce": ['M ER1 D AY0 S'],
  "mere": ['M IH1 R'],
  "meredith": ['M EH1 R IH0 D IH0 TH'],
  "merely": ['M IH1 R L IY0'],
  "merenda": ['M EH0 R EY1 N D AA0'],
  "merendino": ['M ER0 EH0 N D IY1 N OW2'],
  "merengue": ['M ER0 EY1 NG'],
  "merest": ['M EH1 R AH0 S T'],
  "meretz": ['M EH1 R EH0 T S'],
  "merfeld": ['M ER1 F EH0 L D'],
  "merge": ['M ER1 JH'],
  "merged": ['M ER1 JH D'],
  "mergen": ['M ER1 G AH0 N'],
  "merger": ['M ER1 JH ER0'],
  "merger's": ['M ER1 JH ER0 Z'],
  "mergers": ['M ER1 JH ER0 Z'],
  "merges": ['M ER1 JH IH0 Z'],
  "merging": ['M ER1 JH IH0 NG'],
  "merhige": ['M ER0 HH IY1 JH'],
  "meriam": ['M IH1 R IY0 IH0 M'],
  "merica": ['M EH1 R IH0 K AH0'],
  "mericantante": ['M EH0 R IY2 K AH0 N T AA1 N T EY0'],
  "mericle": ['M EH1 R IH0 K AH0 L'],
  "mericopa": ['M EH2 R AH0 K OW1 P AH0'],
  "merida": ['M ER0 IY1 D AH0'],
  "meriden": ['M EH1 R IH0 D AH0 N'],
  "merideth": ['M EH1 R IH0 D IH0 TH'],
  "meridian": ['M ER0 IH1 D IY0 AH0 N'],
  "meridian's": ['M ER0 IH1 D IY0 AH0 N Z'],
  "meridien": ['M ER0 IH1 D IY0 AH0 N'],
  "meridionale": ['M ER0 IH2 D IY0 AH0 N AA1 L IY0'],
  "meridith": ['M EH1 R IH0 D IH0 TH'],
  "meridor": ['M EH1 R IH0 D AO0 R'],
  "meriel": ['M IH1 R IY0 L'],
  "merieux": ['M EH1 R IY0 UW2'],
  "merillat": ['M EH1 R IH0 L AE0 T'],
  "merino": ['M ER0 IY1 N OW0'],
  "merion": ['M EH1 R IY0 AH0 N'],
  "meris": ['M EH1 R AH0 S'],
  "merisel": ['M EH1 R IH0 S EH2 L'],
  "merit": ['M EH1 R AH0 T'],
  "merited": ['M EH1 R IH0 T IH0 D'],
  "merithew": ['M ER0 IH1 TH Y UW0'],
  "meriting": ['M EH1 R AH0 T IH0 NG'],
  "meritless": ['M EH1 R IH0 T L AH0 S'],
  "meritocracy": ['M EH0 R IH0 T AO1 K R AH0 S IY0'],
  "meritor": ['M EH1 R AH0 T ER0', 'M EH1 R AH0 T AO2 R'],
  "meritorious": ['M EH2 R AH0 T AO1 R IY0 AH0 S'],
  "merits": ['M EH1 R AH0 T S', 'M EH1 R IH0 T S'],
  "meritt": ['M EH1 R IH0 T'],
  "meriweather": ['M EH1 R IH0 W EH2 DH ER0'],
  "meriwether": ['M EH1 R IH0 W EH2 DH ER0'],
  "merk": ['M ER1 K'],
  "merkel": ['M ER1 K AH0 L'],
  "merker": ['M ER1 K ER0'],
  "merkey": ['M ER1 K IY0'],
  "merkich": ['M ER1 K IH0 CH'],
  "merkin": ['M ER1 K IH0 N'],
  "merklan": ['M ER1 K L AH0 N'],
  "merkle": ['M ER1 K AH0 L'],
  "merkley": ['M ER1 K L IY0'],
  "merklin": ['M ER1 K L IH0 N'],
  "merksamer": ['M ER1 K S AH0 M ER0'],
  "merkt": ['M ER1 K T'],
  "merkur": ['M ER1 K ER0'],
  "merl": ['M ER1 L'],
  "merle": ['M ER1 L'],
  "merletti": ['M ER0 L EH1 T IY0'],
  "merlin": ['M ER1 L IH0 N'],
  "merlina": ['M ER0 L IY1 N AH0'],
  "merline": ['M ER1 L AY0 N'],
  "merlino": ['M ER0 L IY1 N OW0'],
  "merlins": ['M ER1 L IH0 N Z'],
  "merlis": ['M ER1 L IY0 Z'],
  "merlo": ['M EH1 R L OW0'],
  "merlot": ['M ER1 L AH0 T'],
  "merlots": ['M ER1 L AH0 T S'],
  "mermaid": ['M ER1 M EY2 D'],
  "mermaids": ['M ER1 M EY2 D Z'],
  "merman": ['M ER1 M AE2 N'],
  "mermelstein": ['M ER1 M AH0 L S T AY0 N', 'M ER1 M AH0 L S T IY0 N'],
  "merna": ['M EH1 R N AH0'],
  "merner": ['M ER1 N ER0'],
  "mernick": ['M ER1 N IH0 K'],
  "mero": ['M EH1 R OW0'],
  "merola": ['M ER0 OW1 L AH0'],
  "merolla": ['M ER0 OW1 L AH0'],
  "meroney": ['M EH1 R AH0 N IY0'],
  "merow": ['M EH1 R OW0'],
  "merrell": ['M EH1 R AH0 L'],
  "merrett": ['M EH1 R IH0 T'],
  "merriam": ['M EH1 R IY0 AH0 M'],
  "merrick": ['M EH1 R IH0 K'],
  "merricks": ['M EH1 R IH0 K S'],
  "merrie": ['M EH1 R IY0'],
  "merrier": ['M EH1 R IY0 ER0'],
  "merrifield": ['M EH1 R IH0 F IY2 L D'],
  "merrigan": ['M EH1 R IH0 G AH0 N'],
  "merrihew": ['M ER0 IH1 HH Y UW0'],
  "merrill": ['M EH1 R AH0 L'],
  "merrill's": ['M EH1 R AH0 L Z'],
  "merrily": ['M EH1 R AH0 L IY0'],
  "merrimac": ['M EH1 R IH0 M AE0 K'],
  "merrimack": ['M EH1 R IH0 M AE2 K'],
  "merriman": ['M EH1 R IH0 M AH0 N'],
  "merrin": ['M EH1 R IH0 N'],
  "merriott": ['M EH1 R IY0 AH0 T'],
  "merrit": ['M EH1 R IH0 T'],
  "merritt": ['M EH1 R IH0 T'],
  "merritts": ['M EH1 R IH0 T S'],
  "merriweather": ['M EH0 R IH0 W EH1 DH ER0'],
  "merriwether": ['M EH1 R IH0 W EH0 DH ER0'],
  "merrow": ['M EH1 R OW0'],
  "merry": ['M EH1 R IY0'],
  "merry-go-round": ['M EH1 R IY0 G OW0 R AW2 N D'],
  "merryfield": ['M EH1 R IY0 F IY2 L D'],
  "merryman": ['M EH1 R IY0 M AH0 N'],
  "mers": ['M ER1 Z'],
  "mersch": ['M ER1 SH'],
  "mersereau": ['M ER1 S ER0 OW0'],
  "mershon": ['M ER1 SH AH0 N'],
  "mersman": ['M ER1 S M AH0 N'],
  "merson": ['M ER1 S AH0 N'],
  "merta": ['M EH1 R T AH0'],
  "merten": ['M ER1 T AH0 N'],
  "mertens": ['M ER1 T AH0 N Z'],
  "mertes": ['M EH1 R T EH0 S'],
  "mertice": ['M EH1 R T IH0 S'],
  "mertins": ['M ER1 T IH0 N Z'],
  "mertle": ['M ER1 T AH0 L'],
  "merton": ['M ER1 T AH0 N'],
  "mertz": ['M ER1 T S'],
  "merv": ['M ER1 V'],
  "mervin": ['M ER1 V IH0 N'],
  "mervine": ['M ER1 V AY0 N'],
  "mervis": ['M ER1 V IH0 S'],
  "mervyn": ['M ER1 V IH0 N'],
  "mervyn's": ['M ER1 V IH0 N Z'],
  "merwe": ['M ER1 W IY0'],
  "merwin": ['M ER1 W IH0 N'],
  "merwyn": ['M ER1 W IH0 N'],
  "meryl": ['M EH1 R AH0 L'],
  "meryll": ['M EH1 R AH0 L'],
  "merz": ['M ER1 Z'],
  "mesa": ['M EY1 S AH0'],
  "mesa's": ['M EY1 S AH0 Z'],
  "mesaba": ['M EH0 S AA1 B AH0'],
  "mesaros": ['M EY0 S AA1 R OW0 Z'],
  "mesbic": ['M EH1 S B IH0 K'],
  "mesbics": ['M EH1 S B IH0 K S'],
  "mescalero": ['M EH2 S K AH0 L EH1 R OW0'],
  "mescaline": ['M EH1 S K AH0 L IY2 N'],
  "mesch": ['M EH1 SH'],
  "mesched": ['M EH1 SH EH0 D'],
  "mescher": ['M EH1 SH ER0'],
  "meschke": ['M EH1 SH K'],
  "mesdames": ['M EY0 D AE1 M Z'],
  "meselsohn": ['M EH1 Z AH0 L S AH0 N'],
  "meselson": ['M EH1 Z AH0 L S AH0 N'],
  "mesenbrink": ['M EH1 S IH0 N B R IH0 NG K'],
  "mesenchymal": ['M EH2 Z EH0 K AY1 M AH0 L', 'M EH2 Z EH1 N K AH0 M AH0 L'],
  "mesenteric": ['M EH2 S AH0 N T EH1 R IH0 K'],
  "meserole": ['M EH0 S ER0 OW1 L IY0'],
  "meserve": ['M EH1 S ER0 V'],
  "meservey": ['M EH0 Z ER0 V EY1'],
  "mesh": ['M EH1 SH'],
  "meshed": ['M EH1 SH T'],
  "meshell": ['M EH1 SH AH0 L'],
  "meshes": ['M EH1 SH IH0 Z'],
  "meshing": ['M EH1 SH IH0 NG'],
  "meshulam": ['M EH1 SH UW0 L AE0 M'],
  "mesick": ['M EH1 S IH0 K'],
  "mesirow": ['M EH1 S IH0 R OW0'],
  "meske": ['M EH1 S K'],
  "mesker": ['M EH1 S K ER0'],
  "meskill": ['M EH1 S K IH0 L'],
  "meskimen": ['M EH1 S K IY0 M EH0 N'],
  "mesko": ['M EH1 S K OW0'],
  "mesler": ['M EH1 S AH0 L ER0', 'M EH1 S L ER0'],
  "mesmer": ['M EH1 Z M ER0'],
  "mesmerism": ['M EH1 S M ER0 IH2 Z AH0 M'],
  "mesmerize": ['M EH1 Z M ER0 AY2 Z'],
  "mesmerized": ['M EH1 Z M ER0 AY2 Z D'],
  "mesmerizing": ['M EH1 Z M ER0 AY2 Z IH0 NG'],
  "mesner": ['M EH1 S N ER0'],
  "mesodermal": ['M EH2 Z AH0 D ER1 M AH0 L'],
  "mesolithic": ['M EH2 Z AH0 L IH1 TH IH0 K'],
  "meson": ['M EY1 Z AA2 N'],
  "mesons": ['M IY1 Z AA2 N Z'],
  "mesopotamia": ['M EH2 S AH0 P AH0 T EY1 M IY0 AH0'],
  "mesopotamian": ['M EH2 S AH0 P AH0 T EY1 M IY0 AH0 N'],
  "mesopotamians": ['M EH2 S AH0 P AH0 T EY1 M IY0 AH0 N Z'],
  "mesothelioma": ['M EH2 S AH0 TH IY2 L IY0 OW1 M AH0'],
  "mesothorax": ['M EH2 Z AH0 TH AO1 R AE2 K S'],
  "mesozoic": ['M EH2 S AH0 Z OW1 IH0 K'],
  "mesquite": ['M EH1 S K IY2 T'],
  "mess": ['M EH1 S'],
  "messa": ['M EH1 S AH0'],
  "message": ['M EH1 S AH0 JH', 'M EH1 S IH0 JH'],
  "messagepad": ['M EH1 S AH0 JH P AE2 D'],
  "messages": ['M EH1 S AH0 JH AH0 Z', 'M EH1 S IH0 JH IH0 Z'],
  "messaging": ['M EH1 S IH0 JH IH0 NG'],
  "messamore": ['M EH0 S AA1 M AO0 R'],
  "messana": ['M EH0 S AE1 N AH0'],
  "messed": ['M EH1 S T'],
  "messeghero": ['M EH2 S AH0 G EH1 R OW0'],
  "messel": ['M EH1 S AH0 L'],
  "messenger": ['M EH1 S AH0 N JH ER0', 'M EH1 S IH0 N JH ER0'],
  "messengers": ['M EH1 S AH0 N JH ER0 Z'],
  "messer": ['M EH1 S ER0'],
  "messerli": ['M EH1 S ER0 L IY0'],
  "messerly": ['M EH1 S ER0 L IY0'],
  "messerschmidt": ['M EH1 S ER0 SH M IH2 T'],
  "messerschmitt": ['M EH1 S ER0 SH M IH2 T'],
  "messersmith": ['M EH1 S ER0 S M IH2 TH'],
  "messes": ['M EH1 S IH0 Z'],
  "messiaen": ['M EH1 S IY0 EY2 N'],
  "messiaen's": ['M EH1 S IY0 EY2 N Z'],
  "messiah": ['M AH0 S AY1 AH0'],
  "messiahs": ['M AH0 S AY1 AH0 Z'],
  "messianic": ['M EH2 S IY0 AE1 N IH0 K'],
  "messick": ['M EH1 S IH0 K'],
  "messier": ['M EH1 S IY0 ER0'],
  "messieurs": ['M EY0 S Y ER1 Z'],
  "messimer": ['M EH1 S IH0 M ER0'],
  "messina": ['M IH0 S IY1 N AH0'],
  "messineo": ['M EH2 S IH1 N IY0 OW0'],
  "messing": ['M EH1 S IH0 NG'],
  "messinger": ['M EH1 S IH0 NG ER0'],
  "messler": ['M EH1 S L ER0'],
  "messman": ['M EH1 S M AH0 N'],
  "messmer": ['M EH1 S M ER0'],
  "messmore": ['M EH1 S M AO0 R'],
  "messner": ['M EH1 S N ER0'],
  "messrs": ['M EH1 S ER0 Z'],
  "messrs.": ['M EH1 S ER0 Z', 'M IH0 S UW1 R Z'],
  "messy": ['M EH1 S IY0'],
  "mest": ['M EH1 S T'],
  "mesta": ['M EH1 S T AH0'],
  "mestas": ['M EH1 S T AH0 Z'],
  "mestek": ['M EH1 S T EH2 K'],
  "mester": ['M EH1 S T ER0'],
  "mestizo": ['M EH0 S T IY1 Z OW0'],
  "meston": ['M EH1 S T AH0 N'],
  "mestrallet": ['M EH1 S T R AH0 L EH2 T'],
  "mestre": ['M EH1 S T ER0'],
  "mestrovic": ['M EH1 S T R OW0 V IH0 K'],
  "meszaros": ['M IH0 SH AA1 R OW0 Z'],
  "met": ['M EH1 T'],
  "met's": ['M EH1 T S'],
  "meta": ['M IY1 T AH0'],
  "metabolic": ['M EH2 T AH0 B AA1 L IH0 K'],
  "metabolism": ['M AH0 T AE1 B AH0 L IH2 Z AH0 M'],
  "metabolisms": ['M AH0 T AE1 B AH0 L IH2 Z AH0 M Z'],
  "metabolite": ['M AH0 T AE1 B AH0 L AY2 T'],
  "metabolites": ['M AH0 T AE1 B AH0 L AY2 T S'],
  "metabolize": ['M AH0 T AE1 B AH0 L AY2 Z'],
  "metacarpal": ['M EH2 T AH0 K AA1 R P AH0 L'],
  "metacarpals": ['M EH2 T AH0 K AA1 R P AH0 L Z'],
  "metagogue": ['M EH1 T AH0 G AO2 G'],
  "metagogued": ['M EH1 T AH0 G AO2 G D'],
  "metairie": ['M AH0 T EH1 R IY0'],
  "metal": ['M EH1 T AH0 L'],
  "metal's": ['M EH1 T AH0 L Z'],
  "metall": ['M EH1 T AO1 L'],
  "metallatz": ['M EH1 T AE1 L AH0 T S'],
  "metallgesellschaft": ['M EH2 T AH0 L G EH1 S AH0 L SH AE2 F T'],
  "metallgesellschaft's": ['M EH2 T AH0 L G EH1 S AH0 L SH AE2 F T S'],
  "metallic": ['M AH0 T AE1 L IH0 K'],
  "metallic's": ['M AH0 T AE1 L IH0 K S'],
  "metallica": ['M AH0 T AE1 L IH0 K AH0'],
  "metallica's": ['M AH0 T AE1 L IH0 K AH0 Z'],
  "metallics": ['M AH0 T AE1 L IH0 K S'],
  "metallo": ['M EH0 T AA1 L OW0'],
  "metallurgical": ['M EH2 T AH0 L ER1 JH IH0 K AH0 L'],
  "metallurgy": ['M EH1 T AH0 L ER0 JH IY0'],
  "metals": ['M EH1 T AH0 L Z'],
  "metals'": ['M EH1 T AH0 L Z'],
  "metalwork": ['M EH1 T AH0 L W ER2 K'],
  "metalworker": ['M EH1 T AH0 L W ER2 K ER0'],
  "metalworkers": ['M EH1 T AH0 L W ER2 K ER0 Z'],
  "metalworking": ['M EH1 T AH0 L W ER2 K IH0 NG'],
  "metamorphasis": ['M EH2 T AH0 M AO1 R F AH0 S AH0 S'],
  "metamorphic": ['M EH2 T AH0 M AO1 R F IH0 K'],
  "metamorphose": ['M EH2 T AH0 M AO1 R F OW0 Z'],
  "metamorphosis": ['M EH2 T AH0 M AO1 R F AH0 S AH0 S'],
  "metamucil": ['M EH2 T AH0 M Y UW1 S AH0 L'],
  "metamucil's": ['M EH2 T AH0 M Y UW1 S AH0 L Z'],
  "metaphor": ['M EH1 T AH0 F AO0 R'],
  "metaphorical": ['M EH2 T AH0 F AO1 R IH0 K AH0 L'],
  "metaphorically": ['M EH2 T AH0 F AO1 R IH0 K L IY0'],
  "metaphors": ['M EH1 T AH0 F AO0 R Z'],
  "metaphysical": ['M EH2 T AH0 F IH1 Z IH0 K AH0 L'],
  "metaphysics": ['M EH2 T AH0 F IH1 Z IH0 K S'],
  "metastases": ['M AH0 T AE1 S T EY2 S IY0 S'],
  "metastasis": ['M AH0 T AE1 S T AH0 S IH0 S'],
  "metastasize": ['M AH0 T AE1 S T AH0 S AY2 Z'],
  "metastasized": ['M AH0 T AE1 S T AH0 S AY2 Z D'],
  "metastatic": ['M EH2 T AH0 S T AE1 T IH0 K'],
  "metathorax": ['M EH2 T AH0 TH AO1 R AE2 K S'],
  "metavsky": ['M AH0 T AE1 V S K IY0'],
  "metaxas": ['M AH0 T AE1 K S AH0 S'],
  "metcalf": ['M EH1 T K AE2 F'],
  "metcalfe": ['M EH1 T K AH0 L F'],
  "metcom": ['M EH1 T K AA0 M'],
  "mete": ['M IY1 T', 'M EH1 T AH0'],
  "meted": ['M IY1 T IH0 D'],
  "meteor": ['M IY1 T IY0 ER0'],
  "meteoric": ['M IY2 T IY0 AO1 R IH0 K'],
  "meteorite": ['M IY1 T IY0 AO0 R AY2 T'],
  "meteorological": ['M IY2 T IY0 AO2 R AH0 L AA1 JH IH0 K AH0 L'],
  "meteorologist": ['M IY2 T IY0 ER0 AA1 L AH0 JH IH0 S T'],
  "meteorologist's": ['M IY2 T IY0 ER0 AA1 L AH0 JH IH0 S T S'],
  "meteorologists": ['M IY2 T IY0 ER0 AA1 L AH0 JH IH0 S T S'],
  "meteorology": ['M IY2 T IY0 ER0 AA1 L AH0 JH IY0'],
  "meteors": ['M IY1 T IY0 ER0 Z'],
  "meter": ['M IY1 T ER0'],
  "metered": ['M IY1 T ER0 D'],
  "metering": ['M IY1 T ER0 IH0 NG'],
  "meters": ['M IY1 T ER0 Z'],
  "metex": ['M EH1 T EH2 K S'],
  "meth": ['M EH1 TH'],
  "methadone": ['M EH1 TH AH0 D OW2 N'],
  "methamphetamine": ['M EH2 TH AE0 M F EH1 T AH0 M IY0 N', 'M EH2 TH AE0 M F EH1 T AH0 M AY0 N'],
  "methane": ['M EH1 TH EY2 N'],
  "methanex": ['M EH1 TH AH0 N EH2 K S'],
  "methanol": ['M EH1 TH AH0 N AA2 L'],
  "methazine": ['M EH1 TH AH0 Z IY2 N'],
  "metheney": ['M EH1 TH IH0 N IY0'],
  "metheny": ['M EH1 TH IH0 N IY0'],
  "methner": ['M EH1 TH N ER0'],
  "method": ['M EH1 TH AH0 D'],
  "methode": ['M AH0 TH OW1 D'],
  "methodical": ['M AH0 TH AA1 D AH0 K AH0 L', 'M AH0 TH AA1 D IH0 K AH0 L'],
  "methodically": ['M AH0 TH AA1 D IH0 K AH0 L IY0', 'M AH0 TH AA1 D IH0 K L IY0'],
  "methodism": ['M EH1 TH AH0 D IH2 Z AH0 M'],
  "methodisms": ['M EH1 TH AH0 D IH2 Z AH0 M Z'],
  "methodist": ['M EH1 TH AH0 D AH0 S T', 'M EH1 TH AH0 D IH0 S T'],
  "methodological": ['M EH2 TH AH0 D AH0 L AA1 JH IH0 K AH0 L'],
  "methodologies": ['M EH2 TH OW0 D AA1 L AH0 JH IY0 Z'],
  "methodology": ['M EH2 TH AH0 D AA1 L AH0 JH IY0'],
  "methods": ['M EH1 TH AH0 D Z'],
  "methot": ['M EH1 TH AH0 T'],
  "methotrexate": ['M EH2 TH OW0 T R EH1 K S EY2 T'],
  "methought": ['M IY2 TH AO1 T'],
  "methuselah": ['M AH0 TH Y UW1 Z AH0 L AH0'],
  "methvin": ['M EH1 TH V IH0 N'],
  "methyl": ['M EH1 TH AH0 L'],
  "methylene": ['M EH1 TH IH0 L IY2 N'],
  "metics": ['M EH1 T IH0 K S'],
  "meticulous": ['M AH0 T IH1 K Y AH0 L AH0 S'],
  "meticulously": ['M AH0 T IH1 K Y AH0 L AH0 S L IY0'],
  "metier": ['M EH1 T Y ER0'],
  "metis": ['M EH1 T IH0 S'],
  "metivier": ['M EH1 T IH0 V IY0 ER0'],
  "metlakatla": ['M EH0 T L AH0 K AE1 T L AH0'],
  "metlife": ['M EH1 T L AY2 F'],
  "metolachlor": ['M AH0 T OW1 L AH0 K L ER0'],
  "metonomy": ['M AH0 T AO1 N AH0 M IY0'],
  "metoyer": ['M EH1 T OY0 ER0'],
  "metpath": ['M EH1 T P AE2 TH'],
  "metra": ['M EH1 T R AH0'],
  "metrahealth": ['M EH1 T R AH0 HH EH2 L TH'],
  "metre": ['M IY1 T ER0'],
  "metres": ['M IY1 T ER0 Z'],
  "metric": ['M EH1 T R IH0 K'],
  "metrical": ['M EH1 T R IH0 K AH0 L'],
  "metrically": ['M EH1 T R IH0 K AH0 L IY0'],
  "metrick": ['M EH1 T R IH0 K'],
  "metrics": ['M EH1 T R IH0 K S'],
  "metro": ['M EH1 T R OW2'],
  "metro's": ['M EH1 T R OW0 Z'],
  "metrobanc": ['M EH1 T R OW0 B AE2 NG K'],
  "metrobank": ['M EH1 T R OW0 B AE2 NG K'],
  "metrocall": ['M EH1 T R OW2 K AO2 L'],
  "metrocolor": ['M EH1 T R OW0 K AH1 L ER0'],
  "metrocorp": ['M EH1 T R OW0 K AO2 R P'],
  "metrodade": ['M EH2 T R OW0 D EY1 D'],
  "metrodome": ['M EH1 T R OW0 D OW2 M', 'M EH1 T R AH0 D OW2 M'],
  "metromail": ['M EH1 T R OW0 M EY2 L'],
  "metromedia": ['M EH2 T R OW0 M IY1 D IY0 AH0'],
  "metroplex": ['M EH1 T R OW0 P L EH2 K S'],
  "metroplexes": ['M EH1 T R OW0 P L EH2 K S IH0 Z'],
  "metropol": ['M EH1 T R AH0 P OW2 L'],
  "metropol's": ['M EH1 T R AH0 P OW2 L Z'],
  "metropoli": ['M AH0 T R AA1 P AH0 L IY0'],
  "metropolis": ['M AH0 T R AA1 P AH0 L AH0 S'],
  "metropolitain": ['M EH2 T R AH0 P AO1 L AH0 T AH0 N'],
  "metropolitan": ['M EH2 T R AH0 P AA1 L AH0 T AH0 N'],
  "metropolitan's": ['M EH2 T R AH0 P AA1 L AH0 T AH0 N Z'],
  "metropolitans": ['M EH2 T R AH0 P AA1 L AH0 T AH0 N Z'],
  "metrosexual": ['M EH2 T R AH0 S EH1 K SH UW2 AH0 L'],
  "mets": ['M EH1 T S'],
  "mets'": ['M EH1 T S'],
  "metsker": ['M EH1 T S K ER0'],
  "mette": ['M EH1 T'],
  "metter": ['M EH1 T ER0'],
  "metternich": ['M EH1 T ER0 N IH0 CH'],
  "mettle": ['M EH1 T AH0 L'],
  "mettlen": ['M EH1 T L AH0 N'],
  "mettler": ['M EH1 T L ER0'],
  "metts": ['M EH1 T S'],
  "mettur": ['M EH1 T ER0'],
  "metz": ['M EH1 T S'],
  "metze": ['M EH1 T Z'],
  "metzenbaum": ['M EH1 T S AH0 N B AW2 M'],
  "metzenbaum's": ['M EH1 T S AH0 N B AW2 M Z'],
  "metzer": ['M EH1 T S ER0'],
  "metzgar": ['M EH1 T S G ER0'],
  "metzger": ['M EH1 T S G ER0'],
  "metzger's": ['M EH1 T S G ER0 Z'],
  "metzinger": ['M EH1 T Z IH0 NG ER0'],
  "metzker": ['M EH1 T S K ER0'],
  "metzler": ['M EH1 T S L ER0'],
  "metzner": ['M EH1 T S N ER0'],
  "meunier": ['M OY1 N IY0 ER0'],
  "meurer": ['M ER1 ER0'],
  "meuse": ['M Y UW1 Z'],
  "meuser": ['M OY1 S ER0'],
  "meuth": ['M Y UW1 TH'],
  "mevacor": ['M EH1 V AH0 K AO2 R'],
  "mevarach": ['M EH0 V AA1 R AH0 CH'],
  "mevarach's": ['M EH0 V AA1 R AH0 CH AH0 Z'],
  "mevarachs": ['M EH0 V AA1 R AH0 CH AH0 Z'],
  "mew": ['M Y UW1'],
  "mewas": ['M Y UW1 AH0 S', 'M IY1 W AH0 S'],
  "mewborn": ['M Y UW1 B ER0 N'],
  "mewes": ['M Y UW1 Z'],
  "mex": ['M EH1 K S'],
  "mexicali": ['M EH2 K S IH0 K AA1 L IY0'],
  "mexican": ['M EH1 K S AH0 K AH0 N'],
  "mexicana": ['M EH2 K S IH0 K AE1 N AH0'],
  "mexicano": ['M EH2 K S IH0 K AA1 N OW0'],
  "mexicanos": ['M EH2 K S IH0 K AA1 N OW0 Z'],
  "mexicans": ['M EH1 K S IH0 K AH0 N Z'],
  "mexico": ['M EH1 K S AH0 K OW2'],
  "mexico's": ['M EH1 K S AH0 K OW2 Z'],
  "mey": ['M EY1'],
  "meyer": ['M AY1 ER0'],
  "meyer's": ['M AY1 ER0 Z'],
  "meyerbeer": ['M AY1 ER0 B IH2 R'],
  "meyerhoff": ['M AY1 ER0 HH AO0 F'],
  "meyering": ['M EY1 ER0 IH0 NG'],
  "meyerman": ['M AY1 ER0 M AH0 N'],
  "meyerowitz": ['M AY1 ER0 AH0 W IH0 T S'],
  "meyers": ['M AY1 ER0 Z'],
  "meyerson": ['M AY1 ER0 S AH0 N'],
  "meyn": ['M EY1 N'],
  "meyo": ['M EY1 OW0'],
  "meyohas": ['M EY0 OW1 HH AA0 S'],
  "mez": ['M EH1 Z'],
  "meza": ['M EH1 Z AH0'],
  "mezera": ['M EY0 Z EH1 R AH0'],
  "mezey": ['M EH1 Z IY0'],
  "mezger": ['M EH1 Z JH ER0'],
  "mezhdumarodnom": ['M EH0 Z D UW2 M AH0 R AA1 D N AH0 M'],
  "mezo": ['M EH1 Z OW0'],
  "mezro": ['M EH1 Z R OW0'],
  "mezuzah": ['M AH0 Z UH1 Z AH0'],
  "mezvinsky": ['M EH2 Z V IH1 N S K IY0'],
  "mezvinsky's": ['M EH2 Z V IH1 N S K IY0 Z'],
  "mezzaluna": ['M EH2 Z AH0 L UW1 N AH0', 'M EH2 T S AH0 L UW1 N AH0'],
  "mezzanine": ['M EH1 Z AH0 N IY2 N'],
  "mezzo": ['M EH1 Z OW0'],
  "mfume": ['M AH0 F UW1 M EY2'],
  "mg": ['EH2 M G IY1'],
  "mgm": ['EH2 M G IY2 EH1 M'],
  "mh": ['EH1 M EY1 CH'],
  "mhm": ['AH0 M HH AH0 M'],
  "mhoon": ['M HH UW1 N'],
  "mi": ['M IY1'],
  "mi-vami": ['M IY0 V AE1 M IY0'],
  "mia": ['M IY1 AH0'],
  "miami": ['M AY0 AE1 M IY0'],
  "miami's": ['M AY0 AE1 M IY0 Z'],
  "miano": ['M IY0 AA1 N OW0'],
  "miara": ['M AY0 AA1 R AH0'],
  "miasma": ['M IY0 AE1 Z M AH0', 'M AY0 AE1 Z M AH0'],
  "miata": ['M IY0 AA1 T AH0'],
  "miazga": ['M IY0 AA1 Z G AH0'],
  "mib": ['M IH1 B'],
  "mic": ['M IH1 K'],
  "mica": ['M AY1 K AH0'],
  "micaela": ['M IY0 K EY1 L AH0'],
  "micah": ['M AY1 K AH0'],
  "micale": ['M IY0 K AA1 L IY0'],
  "micallef": ['M IH1 K AH0 L EH0 F'],
  "micanopy": ['M IH0 K AE1 N AH0 P IY0'],
  "micatin": ['M AY1 K AH0 T IH0 N'],
  "micciche": ['M IY0 CH IY1 K IY0'],
  "miccio": ['M IY1 CH IY0 OW0'],
  "miccosukee": ['M IH2 K AH0 S UW2 K IY2'],
  "mice": ['M AY1 S'],
  "micek": ['M IH1 CH EH0 K'],
  "miceli": ['M IY0 CH EH1 L IY0'],
  "mich": ['M IH1 CH'],
  "michael": ['M AY1 K AH0 L'],
  "michael's": ['M AY1 K AH0 L Z'],
  "michaela": ['M AY0 K EH1 L AH0'],
  "michaelina": ['M AY2 K AH0 L IY1 N AH0'],
  "michaeline": ['M AY1 K AH0 L AY0 N', 'M AY1 K AH0 L IY0 N'],
  "michaelis": ['M AY2 K EH1 L IH0 S'],
  "michaella": ['M AY2 K EH1 L AH0'],
  "michaels": ['M AY1 K AH0 L Z'],
  "michaelsen": ['M AY1 K AH0 L S AH0 N'],
  "michaelson": ['M AY1 K AH0 L S AH0 N'],
  "michal": ['M IH1 CH AH0 L'],
  "michalak": ['M IH1 CH AH0 L AE0 K'],
  "michalec": ['M IH0 HH AA1 L IH0 K'],
  "michalek": ['M IH0 HH AA1 L EH0 K'],
  "michalik": ['M IH1 K AH0 L IH0 K'],
  "michalowski": ['M IH2 CH AH0 L AO1 F S K IY0'],
  "michals": ['M IH1 CH AH0 L Z'],
  "michalski": ['M IH2 CH AA1 L S K IY0'],
  "michalsky": ['M IH2 CH AA1 L S K IY0'],
  "michaud": ['M IH0 SH OW1'],
  "michaux": ['M IH0 SH OW1'],
  "michcon": ['M IH1 SH K AA0 N'],
  "michcon's": ['M IH1 SH K AA0 N Z'],
  "micheal": ['M AY1 K AH0 L'],
  "micheaux": ['M IH0 SH OW1'],
  "micheaux's": ['M IH0 SH OW1 Z'],
  "michel": ['M IH0 SH EH1 L', 'M IH1 CH AH0 L', 'M AY1 K AH0 L'],
  "michel's": ['M IH2 SH EH1 L Z', 'M IH1 CH AH0 L Z', 'M AY1 K AH0 L Z'],
  "michela": ['M IH0 K EY1 L AH0'],
  "michela's": ['M IH0 K EY1 L AH0 Z'],
  "michelangelo": ['M AY2 K AH0 L AE1 N JH AH0 L OW2', 'M IH2 K AH0 L AE1 N JH AH0 L OW2'],
  "michelangelo's": ['M AY2 K AH0 L AE1 N JH AH0 L OW2 Z', 'M IH2 K AH0 L AE1 N JH AH0 L OW2 Z'],
  "michelangelos": ['M AY2 K AH0 L AE1 N JH AH0 L OW2 Z', 'M IH2 K AH0 L AE1 N JH AH0 L OW2 Z'],
  "michele": ['M IH0 SH EH1 L'],
  "micheletti": ['M IH0 K AH0 L EH1 T IY0'],
  "micheli": ['M IH0 K EH1 L IY0'],
  "michelin": ['M IH1 SH AH0 L AH0 N', 'M IH1 SH L AH0 N'],
  "michelina": ['M IH0 K AH0 L IY1 N AH0'],
  "micheline": ['M IH2 SH AH0 L AH0 N'],
  "michelini": ['M IH0 K AH0 L IY1 N IY0'],
  "michell": ['M IH1 CH AH0 L'],
  "michelle": ['M IH0 SH EH1 L'],
  "michelle's": ['M IH0 SH EH1 L Z'],
  "michelli": ['M IH0 CH EH1 L IY0'],
  "michelman": ['M AY1 K AH0 L M AH0 N'],
  "michelob": ['M IH1 K AH0 L OW0 B'],
  "michelotti": ['M IH0 K AH0 L OW1 T IY0'],
  "michels": ['M IH0 SH EH1 L Z'],
  "michelsen": ['M AY1 K AH0 L S AH0 N'],
  "michelson": ['M AY1 K AH0 L S AH0 N'],
  "michener": ['M IH1 CH N ER0'],
  "michener's": ['M IH1 CH N ER0 Z'],
  "michie": ['M IH1 CH IY0'],
  "michiels": ['M AY1 K AH0 L Z', 'M IH1 CH IY0 L Z'],
  "michigan": ['M IH1 SH IH0 G AH0 N'],
  "michigan's": ['M IH1 SH IH0 G AH0 N Z'],
  "michihiro": ['M IH2 CH IY0 HH IY1 R OW0'],
  "michio": ['M IH1 CH IY0 OW0'],
  "michl": ['M IH1 CH AH0 L'],
  "michler": ['M IH1 CH L ER0'],
  "michna": ['M IH1 CH N AH0'],
  "michoacan": ['M IH0 CH OW1 K AH0 N'],
  "michon": ['M IH1 CH AH0 N'],
  "mick": ['M IH1 K'],
  "micka": ['M IH1 K AH0'],
  "micke": ['M IH1 K'],
  "mickel": ['M IH1 K AH0 L'],
  "mickelberry": ['M IH1 K AH0 L B EH2 R IY0'],
  "mickels": ['M IH1 K AH0 L Z'],
  "mickelsen": ['M IH1 K AH0 L S AH0 N'],
  "mickelson": ['M IH1 K AH0 L S AH0 N'],
  "mickens": ['M IH1 K AH0 N Z'],
  "mickey": ['M IH1 K IY0'],
  "mickey'll": ['M IH1 K IY0 AH0 L'],
  "mickey's": ['M IH1 K IY0 Z'],
  "micki": ['M IH1 K IY0'],
  "mickie": ['M IH1 K IY0'],
  "mickiewicz": ['M IH1 K AH0 V IH0 CH'],
  "mickish": ['M IH1 K IH0 SH'],
  "mickle": ['M IH1 K AH0 L'],
  "mickler": ['M IH1 K L ER0'],
  "mickles": ['M IH1 K AH0 L Z'],
  "mickley": ['M IH1 K L IY0'],
  "mickus": ['M IH1 K AH0 S'],
  "micky": ['M IH1 K IY0'],
  "micoin": ['M IH0 K OY1 N'],
  "micom": ['M AY1 K AA0 M'],
  "micosukee": ['M IH2 K AH0 S UW1 K IY0'],
  "micro": ['M AY1 K R OW2'],
  "micro's": ['M AY1 K R OW0 Z'],
  "microage": ['M AY1 K R OW0 EY2 JH'],
  "microaire": ['M AY1 K R OW0 EH2 R'],
  "microamerica": ['M AY2 K R OW0 AH0 M EH2 R IH0 K AH0'],
  "microbe": ['M AY1 K R OW2 B'],
  "microbes": ['M AY1 K R OW2 B Z'],
  "microbial": ['M AY0 K R OW1 B IY0 AH0 L'],
  "microbilt": ['M AY1 K R OW0 B IH2 L T'],
  "microbiologist": ['M AY2 K R OW0 B IY0 AA1 L AH0 JH IH0 S T'],
  "microbiologists": ['M AY2 K R OW0 B IY0 AA1 L AH0 JH IH0 S T S', 'M AY2 K R OW0 B IY0 AA1 L AH0 JH IH0 S'],
  "microbiology": ['M AY2 K R OW0 B AY2 AA1 L AH0 JH IY0', 'M AY2 K R AH0 B AY2 AA1 L AH0 JH IY0'],
  "microbreweries": ['M AY1 K R OW2 B R UW2 ER0 IY0 Z'],
  "microbrewery": ['M AY1 K R OW2 B R UW2 ER0 IY0'],
  "microcentrifuge": ['M AY2 K R OW0 S EH1 N T R AH0 F Y UW2 JH'],
  "microchip": ['M AY1 K R OW2 CH IH1 P'],
  "microchips": ['M AY1 K R OW2 CH IH1 P S'],
  "microcircuit": ['M AY1 K R OW0 S ER2 K AH0 T'],
  "microcircuits": ['M AY1 K R OW0 S ER2 K AH0 T S'],
  "microclimate": ['M AY1 K R OW0 K L AY2 M AH0 T'],
  "microclimates": ['M AY1 K R OW0 K L AY2 M AH0 T S'],
  "microcode": ['M AY1 K R OW0 K OW2 D'],
  "microcodes": ['M AY1 K R OW0 K OW2 D Z'],
  "microcom": ['M AY1 K R OW0 K AA2 M'],
  "microcomputer": ['M AY1 K R OW2 K AH0 M P Y UW1 T ER0'],
  "microcomputers": ['M AY1 K R OW2 K AH0 M P Y UW1 T ER0 Z'],
  "microcosm": ['M AY1 K R AH0 K AA2 Z AH0 M'],
  "microcredit": ['M AY1 K R OW2 K R EH1 D IH0 T'],
  "microdyne": ['M AY1 K R OW0 D AY2 N'],
  "microeconomic": ['M AY2 K R OW0 EH2 K AH0 N AA1 M IH0 K'],
  "microeconomics": ['M AY2 K R OW0 EH2 K AH0 N AA1 M IH0 K S'],
  "microelectronic": ['M AY2 K R OW0 IH0 L EH0 K T R AA1 N IH0 K'],
  "microelectronics": ['M AY2 K R OW0 IH0 L EH0 K T R AA1 N IH0 K S'],
  "microelettronica": ['M AY2 K R OW0 IH0 L EH0 T R AA1 N IH0 K AH0'],
  "microfiche": ['M AY1 K R OW0 F IY2 CH'],
  "microfilm": ['M AY1 K R AH0 F IH2 L M'],
  "microfossil": ['M AY1 K R OW2 F AA1 S AH0 L'],
  "microfossils": ['M AY1 K R OW2 F AA1 S AH0 L Z'],
  "microgenesys": ['M AY2 K R OW0 JH EH1 N AH0 S IH0 S'],
  "micrografx": ['M AY2 K R OW0 G R AE1 F EH0 K S'],
  "microgram": ['M AY1 K R OW0 G R AE2 M'],
  "micrograms": ['M AY1 K R OW0 G R AE2 M Z'],
  "micrographic": ['M AY2 K R OW0 G R AE1 F IH0 K'],
  "micrographics": ['M AY2 K R OW0 G R AE1 F IH0 K S'],
  "microliter": ['M AY1 K R OW0 L IY0 T ER0'],
  "microliters": ['M AY1 K R OW0 L IY0 T ER0 Z'],
  "micromanage": ['M AY2 K R OW0 M AE1 N IH0 JH'],
  "micromanaged": ['M AY2 K R OW0 M AE1 N IH0 JH D'],
  "micromanagement": ['M AY2 K R OW0 M AE1 N IH0 JH M AH0 N T'],
  "micromanages": ['M AY2 K R OW0 M AE1 N IH0 JH AH0 Z'],
  "micromanaging": ['M AY2 K R OW0 M AE1 N IH0 JH IH0 NG'],
  "micrometer": ['M AY0 K R AA1 M AH0 T ER0'],
  "micrometers": ['M AY0 K R AA1 M AH0 T ER0 Z'],
  "micron": ['M AY1 K R AA2 N'],
  "micron's": ['M AY1 K R AA2 N Z'],
  "micronesia": ['M AY2 K R OW0 N IY1 ZH AH0'],
  "micronic": ['M AY2 K R AO1 N IH2 K'],
  "micronics": ['M AY2 K R AO1 N IH2 K S'],
  "microns": ['M AY1 K R AA2 N Z'],
  "microorganism": ['M AY2 K R OW0 AO1 R G AH0 N IH2 Z AH0 M'],
  "microorganisms": ['M AY2 K R OW0 AO1 R G AH0 N IH2 Z AH0 M Z'],
  "micropaleontology": ['M AY2 K R OW0 P EY2 L IY0 AH0 N T AA1 L AH0 JH IY0'],
  "microphone": ['M AY1 K R AH0 F OW2 N'],
  "microphones": ['M AY1 K R OW0 F OW2 N Z'],
  "micropolis": ['M AY2 K R AO1 P AH0 L IH0 S'],
  "micropro": ['M AY1 K R OW0 P R OW2'],
  "microprocessor": ['M AY2 K R OW0 P R AA1 S EH0 S ER0'],
  "microprocessors": ['M AY2 K R OW0 P R AA1 S EH0 S ER0 Z'],
  "microprose": ['M AY1 K R OW0 P R OW2 Z'],
  "micros": ['M AY1 K R OW0 Z'],
  "microscope": ['M AY1 K R AH0 S K OW2 P'],
  "microscopes": ['M AY1 K R AH0 S K OW2 P S'],
  "microscopic": ['M AY2 K R AH0 S K AA1 P IH0 K'],
  "microscopically": ['M AY2 K R AH0 S K AA1 P IH0 K L IY0'],
  "microscopy": ['M AY0 K R AH1 S K AH0 P IY0'],
  "microsemi": ['M AY2 K R OW0 S EH1 M IY0'],
  "microsoft": ['M AY1 K R OW2 S AO1 F T'],
  "microsoft's": ['M AY1 K R OW2 S AO1 F T S', 'M AY1 K R OW2 S AO1 F S'],
  "microsystems": ['M AY1 K R OW2 S IH1 S T AH0 M Z'],
  "microsystems'": ['M AY1 K R OW0 S IH2 S T AH0 M Z'],
  "microtel": ['M AY1 K R OW0 T EH2 L'],
  "microtubule": ['M AY1 K R OW2 T UW1 B Y UW0 L'],
  "microtubules": ['M AY1 K R OW2 T UW1 B Y UW0 L Z'],
  "microvax": ['M AY1 K R OW0 V AE2 K S'],
  "microware": ['M AY1 K R OW2 W EH1 R'],
  "microwavable": ['M AY2 K R OW0 W EY1 V AH0 B AH0 L'],
  "microwave": ['M AY1 K R AH0 W EY2 V'],
  "microwaved": ['M AY1 K R OW0 W EY2 V D'],
  "microwaves": ['M AY1 K R OW0 W EY2 V Z'],
  "microwaving": ['M AY1 K R AH0 W EY2 V IH0 NG'],
  "microx": ['M IH1 K R AH0 K S'],
  "micucci": ['M IY0 K UW1 CH IY0'],
  "mid": ['M IH1 D'],
  "midafternoon": ['M IH1 D AE2 F T ER0 N UW2 N'],
  "midair": ['M IH1 D EH1 R'],
  "midamerica": ['M IH2 D AH0 M EH1 R IH0 K AH0'],
  "midas": ['M AY1 D AH0 S'],
  "midcap": ['M IH1 D K AE2 P'],
  "midcentury": ['M IH0 D S EH1 N CH ER0 IY0'],
  "midcon": ['M IH1 D K AA2 N'],
  "midcontinent": ['M IH0 D K AA1 N T IH0 N AH0 N T'],
  "midcourse": ['M IH1 D K AO1 R S'],
  "middaugh": ['M IH1 D AO0'],
  "midday": ['M IH1 D D EY2'],
  "middendorf": ['M IH1 D AH0 N D AO2 R F'],
  "middenstandsbank": ['M IH0 D EH1 N S T AE0 N D Z B AE2 NG K'],
  "middle": ['M IH1 D AH0 L'],
  "middle-aged": ['M IH1 D AH0 L EY2 G D'],
  "middle-class": ['M IH1 D AH0 L K L AE1 S'],
  "middle-earth": ['M IH1 D AH0 L UH2 R TH'],
  "middle-income": ['M IH1 D AH0 L IH2 N K AH0 M'],
  "middle-of-the-road": ['M IH1 D AH0 L AH0 V TH AH0 R AO2 D'],
  "middle-upper": ['M IH1 D AH0 L AH0 AH2 P ER0'],
  "middlebrook": ['M IH1 D AH0 L B R UH2 K'],
  "middlebrooks": ['M IH1 D AH0 L B R UH2 K S'],
  "middleburg": ['M IH1 D AH0 L B ER0 G'],
  "middlebury": ['M IH1 D AH0 L B EH2 R IY0'],
  "middlekauff": ['M IH1 D AH0 L K OW0 F'],
  "middleman": ['M IH1 D AH0 L M AE2 N'],
  "middlemen": ['M IH1 D AH0 L M EH2 N'],
  "middlemiss": ['M IH1 D AH0 L M IH2 S'],
  "middlesex": ['M IH1 D AH0 L S EH2 K S'],
  "middlesworth": ['M IH1 D AH0 L Z W ER2 TH'],
  "middleton": ['M IH1 D AH0 L T AH0 N'],
  "middletown": ['M IH1 D AH0 L T AW2 N'],
  "middleweight": ['M IH1 D AH0 L W EY2 T'],
  "middling": ['M IH1 D AH0 L IH0 NG', 'M IH1 D L IH0 NG'],
  "mideast": ['M IH1 D IY2 S T'],
  "mideastern": ['M IH2 D IY1 S T ER0 N'],
  "midflight": ['M IH1 D F L AY2 T'],
  "midge": ['M IH1 JH'],
  "midge's": ['M IH1 JH IH0 Z'],
  "midges": ['M IH1 JH AH0 Z', 'M IH1 JH IH0 Z'],
  "midget": ['M IH1 JH AH0 T'],
  "midgetman": ['M IH1 JH AH0 T M AE2 N'],
  "midgets": ['M IH1 JH AH0 T S'],
  "midgett": ['M IH1 JH IH0 T'],
  "midgett's": ['M IH1 JH AH0 T S'],
  "midgette": ['M IH0 JH EH1 T'],
  "midgley": ['M IH1 JH L IY0'],
  "midi": ['M IY1 D IY0'],
  "midi's": ['M IY1 D IY0 Z'],
  "midkiff": ['M IH1 D K IH0 F'],
  "midland": ['M IH1 D L AE2 N D'],
  "midland's": ['M IH1 D L AE2 N D Z'],
  "midlands": ['M IH1 D L AE2 N D Z'],
  "midlantic": ['M IH0 D L AE1 N T IH0 K', 'M IH0 D L AE1 N IH0 K'],
  "midler": ['M IH1 D L ER0'],
  "midlevel": ['M IH1 D L AH0 V AH0 L'],
  "midlife": ['M IH1 D L AY2 F'],
  "midline": ['M IH1 D L AY2 N'],
  "midmorning": ['M IH1 D M AO2 R N IH0 NG'],
  "midnight": ['M IH1 D N AY2 T'],
  "midpac": ['M IH1 D P AE2 K'],
  "midpac's": ['M IH1 D P AE2 K S'],
  "midpoint": ['M IH1 D P OY2 N T'],
  "midprice": ['M IH1 D P R AY2 S'],
  "midpriced": ['M IH1 D P R AY1 S T'],
  "midrange": ['M IH1 D R EY2 N JH'],
  "midrib": ['M IH1 D R IH0 B'],
  "midseason": ['M IH1 D S IY1 Z AH0 N'],
  "midsection": ['M IH1 D S EH2 K SH AH0 N'],
  "midsession": ['M IH1 D S EH2 SH AH0 N'],
  "midshipman": ['M IH1 D SH IH1 P M AH0 N'],
  "midshipman's": ['M IH1 D SH IH1 P M AH0 N Z'],
  "midshipmen": ['M IH1 D SH IH1 P M EH0 N'],
  "midshipmen's": ['M IH1 D SH IH1 P M EH0 N Z'],
  "midsize": ['M IH1 D S AY2 Z'],
  "midsized": ['M IH1 D S AY2 Z D'],
  "midst": ['M IH1 D S T', 'M IH1 S T'],
  "midstream": ['M IH1 D S T R IY2 M'],
  "midsummer": ['M IH1 D S AH1 M ER0'],
  "midterm": ['M IH1 D T ER2 M'],
  "midterms": ['M IH1 D T ER2 M Z'],
  "midthun": ['M IH1 D TH AH0 N'],
  "midtown": ['M IH1 D T AW2 N'],
  "midura": ['M IY0 D UH1 R AH0'],
  "midway": ['M IH1 D W EY2'],
  "midway's": ['M IH1 D W EY2 Z'],
  "midweek": ['M IH1 D W IY2 K'],
  "midwest": ['M IH2 D W EH1 S T'],
  "midwest's": ['M IH2 D W EH1 S T S'],
  "midwestern": ['M IH0 D W EH1 S T ER0 N'],
  "midwesterner": ['M IH2 D W EH1 S T ER0 N ER0'],
  "midwesterners": ['M IH0 D W EH1 S T ER0 N ER0 Z'],
  "midwife": ['M IH1 D W AY2 F'],
  "midwifery": ['M IH1 D W AY2 F ER0 IY0'],
  "midwinter": ['M IH1 D W IH1 N T ER0'],
  "midwinter's": ['M IH1 D W IH1 N T ER0 Z'],
  "midwives": ['M IH1 D W AY2 V Z'],
  "midyear": ['M IH1 D Y IH2 R'],
  "midyett": ['M IH2 D Y EH1 T'],
  "midyette": ['M IH2 D Y EH1 T'],
  "miears": ['M IY0 IH1 R Z'],
  "mieczkowski": ['M IY0 CH K AO1 F S K IY0'],
  "mieczyslaw": ['M IY1 CH IH0 S L AO2'],
  "miedema": ['M IY0 D EH1 M AH0'],
  "mielcarek": ['M IY0 L K AA1 R EH0 K'],
  "miele": ['M IY1 L'],
  "mielke": ['M IY1 L K IY0'],
  "mien": ['M IY1 N'],
  "mieno": ['M IY1 N OW0'],
  "mier": ['M AY1 ER0'],
  "miera": ['M IY1 R AH0'],
  "mieras": ['M IH0 R AA1 Z'],
  "miers": ['M AY1 ER0 Z'],
  "miert": ['M AY1 ER0 T'],
  "mierzejewski": ['M IH0 R Z EY0 EH1 F S K IY0'],
  "mierzwa": ['M IY1 R Z W AH0'],
  "mies": ['M AY1 Z'],
  "mieske": ['M AY1 S K IY0'],
  "miesner": ['M IY1 Z N ER0'],
  "miesse": ['M IY1 S'],
  "miffed": ['M IH1 F T'],
  "mifflin": ['M IH1 F L IH0 N'],
  "mifsud": ['M IH1 F S AH0 D'],
  "mig": ['M IH1 G'],
  "migdal": ['M IH1 G D AH0 L'],
  "migent": ['M IH1 JH AH0 N T'],
  "miggins": ['M IH1 G IH0 N Z'],
  "might": ['M AY1 T'],
  "might've": ['M AY1 T AH0 V'],
  "might-have-been": ['M AY1 T AH0 V B IH2 N'],
  "might-have-beens": ['M AY1 T AH0 V B IH2 N Z'],
  "mightier": ['M AY1 T IY0 ER0'],
  "mightiest": ['M AY1 T IY0 AH0 S T'],
  "mightily": ['M AY1 T AH0 L IY0'],
  "mightn't": ['M AY1 T AH0 N T'],
  "mighty": ['M AY1 T IY0'],
  "migliaccio": ['M IY0 G L IY0 AA1 CH IY0 OW0'],
  "miglin": ['M IH1 G L IH0 N'],
  "miglin's": ['M IH1 G L IH0 N Z'],
  "migliore": ['M IY0 G L IY0 AO1 R IY0'],
  "mignanelli": ['M IH0 G N AH0 N EH1 L IY0'],
  "mignano": ['M IY0 G N AA1 N OW0'],
  "migneault": ['M IH0 G N OW1'],
  "mignogna": ['M IY0 G N OW1 G N AH0'],
  "mignon": ['M IH1 G N AH0 N'],
  "mignone": ['M IY0 G N OW1 N IY0'],
  "mignons": ['M IH1 G N AH0 N Z'],
  "migra": ['M IH1 G R AH0'],
  "migraine": ['M AY1 G R EY2 N'],
  "migraines": ['M AY1 G R EY2 N Z'],
  "migrant": ['M AY1 G R AH0 N T'],
  "migrants": ['M AY1 G R AH0 N T S'],
  "migrate": ['M AY1 G R EY2 T'],
  "migrated": ['M AY1 G R EY2 T IH0 D'],
  "migrates": ['M AY1 G R EY2 T S'],
  "migrating": ['M AY1 G R EY2 T IH0 NG'],
  "migration": ['M AY0 G R EY1 SH AH0 N'],
  "migrations": ['M AY0 G R EY1 SH AH0 N Z'],
  "migratory": ['M AY1 G R AH0 T AO2 R IY0'],
  "migs": ['M IH1 G Z'],
  "miguel": ['M IH0 G EH1 L'],
  "migues": ['M IY1 G EH0 S'],
  "miguez": ['M IY0 G EH1 Z'],
  "mihai": ['M IY2 HH AY1'],
  "mihai's": ['M IY2 HH AY1 Z'],
  "mihal": ['M AY1 HH AH0 L'],
  "mihalek": ['M IH1 HH AH0 L EH0 K'],
  "mihalic": ['M IH0 HH AE1 L IH0 K'],
  "mihalik": ['M IH1 HH AH0 L IH0 K'],
  "mihalko": ['M IH0 HH AE1 L K OW0'],
  "mihaly": ['M IH1 HH AH0 L IY0'],
  "mihelich": ['M IH1 HH IH0 L IH0 K'],
  "mihm": ['M IH1 M'],
  "mihn": ['M IH1 N'],
  "mihn's": ['M IH1 N Z'],
  "mihok": ['M IH1 HH AH0 K'],
  "mijares": ['M IY0 Y AA1 R EH0 S'],
  "mika": ['M IY1 K AH0'],
  "mikado": ['M IH0 K AA1 D OW0'],
  "mikael": ['M AH0 K EY1 L'],
  "mikaela": ['M IY0 K EY1 L AH0'],
  "mikako": ['M IY0 K AA1 K OW0'],
  "mikal": ['M IY1 K AH0 L'],
  "mike": ['M AY1 K'],
  "mike's": ['M AY1 K S'],
  "mikel": ['M IH1 K AH0 L'],
  "mikell": ['M IH1 K AH0 L'],
  "mikels": ['M IH1 K AH0 L Z'],
  "mikelson": ['M IH1 K IH0 L S AH0 N'],
  "mikes": ['M AY1 K S'],
  "mikesell": ['M IH1 K IH0 S AH0 L'],
  "mikesh": ['M IH1 K IH0 SH'],
  "mikeska": ['M IH0 K EH1 S K AH0'],
  "mikey": ['M AY1 K IY0'],
  "mikhail": ['M IH0 K EY1 L', 'M IH0 K AY1 L'],
  "mikidu": ['M IH0 K IY1 D UW0'],
  "mikita": ['M IH0 K IY1 T AH0'],
  "mikkelsen": ['M IH0 K EH1 L S AH0 N'],
  "mikkelson": ['M IH1 K IH0 L S AH0 N'],
  "mikkola": ['M IH0 K OW1 L AH0'],
  "miklas": ['M AY1 K L AH0 Z'],
  "mikles": ['M AY1 K AH0 L Z'],
  "miklos": ['M IY1 K L OW0 S'],
  "miko": ['M AY1 K OW0'],
  "mikol": ['M IH1 K AO0 L'],
  "mikolajczak": ['M IH0 K AA1 L AY0 CH AE0 K'],
  "mikolajczyk": ['M IH0 K AA1 L AY0 CH IH0 K'],
  "mikos": ['M AY1 K OW0 Z'],
  "mikrut": ['M IH1 K R AH0 T'],
  "miksch": ['M IH1 K SH'],
  "mikula": ['M IH0 K UW1 L AH0'],
  "mikulak": ['M IH0 K UW1 L AH0 K'],
  "mikulec": ['M IH0 K UW1 L IH0 K'],
  "mikulic": ['M IH0 K UW1 L IH0 K'],
  "mikulich": ['M IH0 K UW1 L IH0 CH'],
  "mikulski": ['M IH0 K AH1 L S K IY0'],
  "mikus": ['M AY1 K AH0 S'],
  "mikva": ['M IH1 K V AH0'],
  "mil": ['M IH1 L'],
  "mila": ['M AY1 L AH0'],
  "milacron": ['M IH1 L AH0 K R AA0 N'],
  "milacron's": ['M IH1 L AH0 K R AA0 N Z'],
  "miladic": ['M IH0 L AA1 D IH0 K'],
  "milady": ['M IH0 L EY1 D IY2'],
  "milagro": ['M IH0 L AE1 G R OW0'],
  "milam": ['M IH1 L AH0 M'],
  "milan": ['M AH0 L AA1 N', 'M IH0 L AA1 N', 'M AY1 L AE2 N'],
  "milan's": ['M IH0 L AA1 N Z'],
  "milani": ['M IY0 L AA1 N IY0'],
  "milano": ['M IY0 L AA1 N OW0'],
  "milanowski": ['M IH0 L AH0 N AO1 F S K IY0'],
  "milardo": ['M IY0 L AA1 R D OW0'],
  "milas": ['M AY1 L AH0 Z'],
  "milazzo": ['M IY0 L AA1 Z OW0'],
  "milbank": ['M IH1 L B AE2 NG K'],
  "milbauer": ['M IH1 L B AW0 ER0'],
  "milberg": ['M IH1 L B ER0 G'],
  "milberger": ['M IH1 L B ER0 G ER0'],
  "milbourn": ['M IH0 L B UH1 R N'],
  "milbourne": ['M IH0 L B UH1 R N'],
  "milbrandt": ['M IH1 L B R AH0 N T'],
  "milbrath": ['M IH1 L B R AH0 TH'],
  "milburn": ['M IH1 L B ER2 N'],
  "milby": ['M IH1 L B IY0'],
  "milch": ['M IH1 L CH'],
  "milco": ['M IH1 L K OW0'],
  "mild": ['M AY1 L D'],
  "mildenberger": ['M AY1 L D AH0 N B ER0 G ER0'],
  "milder": ['M AY1 L D ER0'],
  "mildest": ['M AY1 L D AH0 S T'],
  "mildew": ['M IH1 L D UW2'],
  "mildews": ['M IH1 L D UW2 Z'],
  "mildly": ['M AY1 L D L IY0'],
  "mildred": ['M IH1 L D R IH0 D'],
  "mildrid": ['M IH1 L D ER0 IH0 D'],
  "mile": ['M AY1 L'],
  "mileage": ['M AY1 L AH0 JH', 'M AY1 L IH0 JH'],
  "mileham": ['M IH1 L IH0 HH AE0 M'],
  "milem": ['M IH1 L AH0 M'],
  "miler": ['M AY1 L ER0'],
  "miles": ['M AY1 L Z', 'M AY1 AH0 L Z'],
  "mileski": ['M IH0 L EH1 S K IY0'],
  "milestone": ['M AY1 L S T OW2 N'],
  "milestones": ['M AY1 L S T OW2 N Z'],
  "miletich": ['M IH1 L IH0 T IH0 K'],
  "milewski": ['M IH0 L EH1 F S K IY0'],
  "miley": ['M AY1 L IY0'],
  "milford": ['M IH1 L F ER0 D'],
  "milgram": ['M IH1 L G R AE2 M'],
  "milham": ['M IH1 L HH AH0 M'],
  "milhoan": ['M IH1 L HH OW0 N'],
  "milholland": ['M IH1 L HH AH0 L AH0 N D'],
  "milhollin": ['M IH0 L HH AA1 L IH0 N'],
  "milhorn": ['M IH1 L HH ER0 N'],
  "milhous": ['M IH1 L HH AW2 S'],
  "milhouse": ['M IH1 L HH AW2 S'],
  "milian": ['M IH1 L IY0 AH0 N'],
  "milicent": ['M IH1 L IH0 S IH0 N T'],
  "milich": ['M IH1 L IH0 K'],
  "milici": ['M IY0 L IY1 CH IY0'],
  "milieu": ['M IH0 L Y UH1'],
  "mililiter": ['M IH1 L IH0 L IY2 T ER0'],
  "milissent": ['M IH1 L IH0 S AH0 N T'],
  "militancy": ['M IH1 L AH0 T AH0 N S IY0'],
  "militant": ['M IH1 L AH0 T AH0 N T'],
  "militantly": ['M IH1 L IH0 T AH0 N T L IY0'],
  "militants": ['M IH1 L AH0 T AH0 N T S'],
  "militaries": ['M IH1 L AH0 T EH2 R IY0 Z'],
  "militarily": ['M IH2 L AH0 T EH1 R AH0 L IY0'],
  "militarism": ['M IH1 L AH0 T ER0 IH2 Z AH0 M'],
  "militaristic": ['M IH2 L IH0 T ER0 IH1 S T IH0 K'],
  "militarists": ['M IH1 L AH0 T ER0 IH0 S T S'],
  "militarize": ['M IH1 L AH0 T ER0 AY2 Z'],
  "militarized": ['M IH1 L AH0 T ER0 AY2 Z D'],
  "military": ['M IH1 L AH0 T EH2 R IY0', 'M IH1 L IH0 T EH2 R IY0'],
  "military's": ['M IH1 L IH0 T EH2 R IY0 Z'],
  "militate": ['M IH1 L IH0 T EY2 T'],
  "militello": ['M IY0 L IY0 T EH1 L OW0'],
  "militia": ['M AH0 L IH1 SH AH0', 'M IH0 L IH1 SH AH0'],
  "militia's": ['M AH0 L IH1 SH AH0 Z', 'M IH0 L IH1 SH AH0 Z'],
  "militiamen": ['M AH0 L IH1 SH AH0 M IH0 N'],
  "militias": ['M AH0 L IH1 SH AH0 Z', 'M IH0 L IH1 SH AH0 Z'],
  "milito": ['M IY0 L IY1 T OW0'],
  "milius": ['M AY1 L IY0 IH0 S'],
  "milk": ['M IH1 L K'],
  "milke": ['M IH1 L K'],
  "milked": ['M IH1 L K T'],
  "milken": ['M IH1 L K AH0 N'],
  "milken's": ['M IH1 L K AH0 N Z'],
  "milkens": ['M IH1 L K AH0 N Z'],
  "milkens'": ['M IH1 L K AH0 N Z'],
  "milking": ['M IH1 L K IH0 NG'],
  "milkman": ['M IH1 L K M AE2 N'],
  "milko": ['M IH1 L K OW0'],
  "milkovich": ['M IH1 L K AH0 V IH0 CH'],
  "milkowski": ['M IH0 L K AO1 F S K IY0'],
  "milks": ['M IH1 L K S'],
  "milkshake": ['M IH1 L K SH EY2 K'],
  "milkweed": ['M IH1 L K W IY2 D'],
  "milky": ['M IH1 L K IY0'],
  "mill": ['M IH1 L'],
  "mill's": ['M IH1 L Z'],
  "millage": ['M IH1 L IH0 JH'],
  "millan": ['M IH1 L AH0 N'],
  "millar": ['M IH1 L ER0'],
  "millard": ['M IH1 L ER0 D'],
  "millard's": ['M IH1 L ER0 D Z'],
  "millau": ['M IH1 L AW0'],
  "millay": ['M IH0 L EY1'],
  "millbank": ['M IH1 L B AE2 NG K'],
  "millburn": ['M IH1 L B ER0 N'],
  "mille": ['M IH1 L'],
  "milled": ['M IH1 L D'],
  "milledge": ['M IH1 L IH0 JH'],
  "millen": ['M IH1 L AH0 N'],
  "millender": ['M IH1 L EH0 N D ER0'],
  "millennia": ['M AH0 L EH1 N IY0 AH0'],
  "millennial": ['M IH0 L EH1 N IY0 AH0 L'],
  "millennium": ['M AH0 L EH1 N IY0 AH0 M'],
  "millenniums": ['M AH0 L EH1 N IY0 AH0 M Z'],
  "miller": ['M IH1 L ER0'],
  "miller's": ['M IH1 L ER0 Z'],
  "millerbrook": ['M IH1 L ER0 B R UH2 K'],
  "millerick": ['M IH1 L ER0 IH0 K'],
  "millers": ['M IH1 L ER0 Z'],
  "milleson": ['M IH1 L IH0 S AH0 N'],
  "millet": ['M IH1 L AH0 T'],
  "millets": ['M IH1 L AH0 T S'],
  "millett": ['M IH1 L IH0 T'],
  "millette": ['M IH0 L EH1 T'],
  "milley": ['M IH1 L IY0'],
  "millhouse": ['M IH1 L HH AW2 S'],
  "milli": ['M IH1 L IY0'],
  "millibar": ['M IH1 L AH0 B AA2 R'],
  "millican": ['M IH1 L IH0 K AH0 N'],
  "millicent": ['M IH1 L IH0 S IH0 N T'],
  "millicom": ['M IH1 L IH0 K AA0 M'],
  "millicom's": ['M IH1 L IH0 K AA0 M Z'],
  "millie": ['M IH1 L IY0'],
  "milliet": ['M IH1 L IY0 EH2 T'],
  "milligal": ['M IH1 L IH0 G AH0 L'],
  "milligan": ['M IH1 L IH0 G AH0 N'],
  "milligauss": ['M IH1 L IY0 G AW2 S'],
  "milligram": ['M IH1 L AH0 G R AE2 M'],
  "milligrams": ['M IH1 L AH0 G R AE2 M Z'],
  "millikan": ['M IH1 L AH0 K AH0 N'],
  "milliken": ['M IH1 L IH0 K AH0 N'],
  "millikin": ['M IH1 L IH0 K IH0 N'],
  "milliliter": ['M IH1 L AH0 L IY2 T ER0'],
  "milliliters": ['M IH1 L AH0 L IY2 T ER0 Z'],
  "millilitre": ['M IH1 L AH0 L IY2 T ER0'],
  "millilitres": ['M IH1 L AH0 L IY2 T ER0 Z'],
  "milliman": ['M IH1 L IH0 M AH0 N'],
  "millimeter": ['M IH1 L AH0 M IY2 T ER0'],
  "millimeters": ['M IH1 L AH0 M IY2 T ER0 Z'],
  "millimetre": ['M IH1 L AH0 M IY2 T ER0'],
  "millimetres": ['M IH1 L AH0 M IY2 T ER0 Z'],
  "millin": ['M IH1 L AH0 N'],
  "milliner": ['M IH1 L IH0 N ER0'],
  "milling": ['M IH1 L IH0 NG'],
  "millington": ['M IH1 L IH0 NG T AH0 N'],
  "million": ['M IH1 L Y AH0 N'],
  "million-dollar": ['M IH1 L Y AH0 N D AO2 L ER0'],
  "millionaire": ['M IH2 L Y AH0 N EH1 R'],
  "millionaire's": ['M IH2 L Y AH0 N EH1 R Z'],
  "millionaires": ['M IH2 L Y AH0 N EH1 R Z'],
  "millions": ['M IH1 L Y AH0 N Z'],
  "millionth": ['M IH1 L Y AH0 N TH'],
  "millionths": ['M IH1 L Y AH0 N T TH S'],
  "millipore": ['M IH1 L IH0 P AO2 R'],
  "milliron": ['M IH1 L ER0 AH0 N'],
  "millirons": ['M IH1 L ER0 OW0 N Z'],
  "millis": ['M IH1 L IH0 S'],
  "millisecond": ['M IH1 L IH0 S EH2 K AH0 N D'],
  "milliseconds": ['M IH1 L IH0 S EH2 K AH0 N D Z'],
  "millisent": ['M IH1 L AH0 S AH0 N T'],
  "millman": ['M IH1 L M AH0 N'],
  "milln": ['M IH1 L N'],
  "millner": ['M IH1 L N ER0'],
  "millon": ['M IH1 L AH0 N'],
  "milloy": ['M IH1 L OY0'],
  "mills": ['M IH1 L Z'],
  "mills'": ['M IH1 L Z'],
  "mills's": ['M IH1 L Z IH0 Z'],
  "millsap": ['M IH1 L S AE2 P'],
  "millsaps": ['M IH1 L S AE2 P S'],
  "millspaugh": ['M IH1 L S P AO0'],
  "millstein": ['M IH1 L S T AY2 N', 'M IH1 L S T IY2 N'],
  "millstone": ['M IH1 L S T OW2 N'],
  "millward": ['M IH1 L W ER0 D'],
  "millwood": ['M IH1 L W UH2 D'],
  "millworker": ['M IH1 L W AO0 R K ER0'],
  "millworkers": ['M IH1 L W AO0 R K ER0 Z'],
  "millworking": ['M IH1 L W AO0 R K IH0 NG'],
  "milly": ['M IH1 L IY0'],
  "milman": ['M IH1 L M AH0 N'],
  "milne": ['M IH1 L N'],
  "milner": ['M IH1 L N ER0'],
  "milnes": ['M IH1 L N Z'],
  "milo": ['M AY1 L OW0'],
  "milodic": ['M IH0 L OW1 D IH0 K'],
  "milonas": ['M IY0 L OW1 N AA0 Z'],
  "milone": ['M IH0 L OW1 N'],
  "milos": ['M IY1 L OW0 Z'],
  "milosevic": ['M IH0 L OW1 S AH0 V IH0 K', 'M IH0 L OW1 S AH0 V IH0 CH'],
  "milosevic's": ['M IH0 L OW1 S AH0 V IH0 CH IH0 Z'],
  "milosevich": ['M IH0 L AA1 S IH0 V IH0 CH'],
  "milosh": ['M IH0 L AO1 SH'],
  "milot": ['M IH1 L AH0 T'],
  "milpitas": ['M IH0 L P IY1 T AH0 S'],
  "milroy": ['M IH1 L R OY2'],
  "mils": ['M IH1 L Z'],
  "milsap": ['M IH1 L S AE2 P'],
  "milson": ['M IH1 L S AH0 N'],
  "milstead": ['M IH1 L S T EH2 D'],
  "milstein": ['M IH1 L S T AY2 N', 'M IH1 L S T IY2 N'],
  "milt": ['M IH1 L T'],
  "miltenberger": ['M IH1 L T AH0 N B ER0 G ER0'],
  "miltie": ['M IH1 T IY0'],
  "miltner": ['M IH1 L T N ER0'],
  "milton": ['M IH1 L T AH0 N'],
  "miltonic": ['M IH0 L T AA1 N IH0 K'],
  "milum": ['M IH1 L AH0 M'],
  "milunovich": ['M IH0 L UW1 N AH0 V IH0 CH'],
  "milward": ['M IH1 L W ER0 D'],
  "milwaukee": ['M IH0 L W AO1 K IY0'],
  "milwaukee's": ['M IH0 L W AO1 K IY0 Z'],
  "milz": ['M IH1 L Z'],
  "mim": ['M IH1 M'],
  "mimbs": ['M IH1 M Z'],
  "mime": ['M AY1 M'],
  "mimeograph": ['M IH1 M IY0 AH0 G R AE2 F'],
  "mimi": ['M IY1 M IY0'],
  "mimi's": ['M IY1 M IY0 Z'],
  "mimic": ['M IH1 M IH0 K'],
  "mimicked": ['M IH1 M IH0 K T'],
  "mimicking": ['M IH1 M IH0 K IH0 NG'],
  "mimicry": ['M IH1 M IH0 K R IY0'],
  "mimics": ['M IH1 M IH0 K S'],
  "mimis": ['M IY1 M IY0 Z'],
  "mimms": ['M IH1 M Z'],
  "mimnaugh": ['M IH1 M N AW0'],
  "mimosa": ['M IH0 M OW1 S AH0'],
  "mims": ['M IH1 M Z'],
  "min": ['M IH1 N'],
  "mina": ['M IY1 N AH0', 'M IH1 N AH0'],
  "minahan": ['M IH1 N AH0 HH AE0 N'],
  "minami": ['M IY0 N AA1 M IY0'],
  "minamide": ['M IH2 N AH0 M IY1 D EY0'],
  "minar": ['M AY1 N ER0'],
  "minard": ['M IH0 N AA1 R D'],
  "minardos": ['M IH0 N AA1 R D OW0 S'],
  "minaret": ['M IH2 N ER0 EH1 T'],
  "minarets": ['M IH2 N ER0 EH1 T S'],
  "minarik": ['M IH1 N ER0 IH0 K'],
  "minas": ['M IY1 N AH0 S'],
  "minasian": ['M IH0 N AE1 ZH IH0 N', 'M IH0 N EY1 ZH IH0 N'],
  "minassian": ['M IH0 N AE1 S ZH IH0 N'],
  "minatome": ['M IH1 N AH0 T OW2 M'],
  "minc": ['M IH1 NG K'],
  "mince": ['M IH1 N S'],
  "minced": ['M IH1 N S T'],
  "mincemeat": ['M IH1 N S M IY2 T'],
  "mincer": ['M IH1 N S ER0'],
  "mincers": ['M IH1 N S ER0 Z'],
  "minces": ['M IH1 N S IH0 Z'],
  "mincey": ['M IH1 N S IY0'],
  "minch": ['M IH1 N CH'],
  "mincher": ['M IH1 N CH ER0'],
  "minchew": ['M IH1 N CH Y UW0'],
  "minchey": ['M IH1 N CH IY0'],
  "minchin": ['M IH1 N CH IH0 N'],
  "mincing": ['M IH1 N S IH0 NG'],
  "minckler": ['M IH1 NG K L ER0'],
  "mincks": ['M IH1 NG K S'],
  "mincy": ['M IH1 N S IY0'],
  "mind": ['M AY1 N D'],
  "mind's": ['M AY1 N D Z'],
  "minda": ['M IH1 N D AH0'],
  "mindanao": ['M IH2 N D AH0 N AW1', 'M IH2 N D AH0 N EY1 OW0'],
  "mindboggling": ['M AY1 N D B AO2 G L IH0 NG'],
  "minded": ['M AY1 N D AH0 D', 'M AY1 N D IH0 D'],
  "mindedly": ['M AY1 N D IH0 D L IY0'],
  "mindedness": ['M AY1 N D IH0 D N AH0 S'],
  "mindel": ['M IH1 N D AH0 L'],
  "mindell": ['M IH0 N D EH1 L'],
  "minden": ['M AY1 N D AH0 N'],
  "minder": ['M AY1 N D ER0'],
  "mindful": ['M AY1 N D F AH0 L'],
  "mindfully": ['M AY1 N D F AH0 L IY0'],
  "minding": ['M AY1 N D IH0 NG'],
  "mindless": ['M AY1 N D L AH0 S'],
  "mindlessly": ['M AY1 N D L AH0 S L IY0'],
  "mindlin": ['M IH1 N D L IH0 N'],
  "minds": ['M AY1 N D Z'],
  "mindset": ['M AY1 N D S EH2 T'],
  "mindsets": ['M AY1 N D S EH2 T S'],
  "mindy": ['M IH1 N D IY0'],
  "mine": ['M AY1 N'],
  "mine's": ['M AY1 N Z'],
  "minea": ['M IH0 N IY1 AH0'],
  "minear": ['M IH0 N IH1 R'],
  "mineau": ['M IH0 N OW1'],
  "minebea": ['M IH2 N AH0 B IY1 AH0'],
  "minecraft": ['M AY1 N K R AE2 F T'],
  "mined": ['M AY1 N D'],
  "mineer": ['M AY1 N ER0'],
  "minefield": ['M AY1 N F IY2 L D'],
  "minefields": ['M AY1 N F IY2 L D Z'],
  "minehan": ['M IH1 N IH0 HH AE0 N'],
  "minehart": ['M AY1 N HH AA2 R T'],
  "minella": ['M IH0 N EH1 L AH0'],
  "minelli": ['M IH0 N EH1 L IY0'],
  "mineo": ['M IH1 N IY0 OW0'],
  "mineola": ['M IH2 N IY0 OW1 L AH0'],
  "mineowner": ['M AY1 N OW2 N ER0'],
  "mineowners": ['M AY1 N OW2 N ER0 Z'],
  "miner": ['M AY1 N ER0'],
  "miner's": ['M AY1 N ER0 Z'],
  "minera": ['M IH0 N EH1 R AH0'],
  "mineral": ['M IH1 N ER0 AH0 L', 'M IH1 N R AH0 L'],
  "mineral's": ['M IH1 N ER0 AH0 L Z', 'M IH1 N R AH0 L Z'],
  "mineralization": ['M IH2 N ER0 AH0 L AH0 Z EY1 SH AH0 N'],
  "mineralize": ['M IH1 N ER0 AH0 L AY2 Z'],
  "mineralogically": ['M IH2 N ER0 AH0 L AA1 JH IH0 K AH0 L IY0', 'M IH2 N ER0 AH0 L AA1 JH IH0 K L IY0'],
  "mineralogist": ['M IH2 N ER0 AE1 L AH0 JH IH0 S T', 'M IH2 N ER0 AA1 L AH0 JH IH0 S T'],
  "mineralogy": ['M IH2 N ER0 AA1 L AH0 JH IY0'],
  "minerals": ['M IH1 N ER0 AH0 L Z', 'M IH1 N R AH0 L Z'],
  "minerals'": ['M IH1 N ER0 AH0 L Z', 'M IH1 N R AH0 L Z'],
  "minerd": ['M IH1 N ER0 D'],
  "minero": ['M IH0 N EH1 R OW0'],
  "miners": ['M AY1 N ER0 Z'],
  "miners'": ['M AY1 N ER0 Z'],
  "minerva": ['M AH0 N ER1 V AH0', 'M IH0 N ER1 V AH0'],
  "minervini": ['M IY2 N ER0 V IY1 N IY0'],
  "mines": ['M AY1 N Z'],
  "mines'": ['M AY1 N Z'],
  "minestrone": ['M IH2 N AH0 S T R OW1 N IY2'],
  "minesweeper": ['M AY1 N S W IY2 P ER0'],
  "minesweepers": ['M AY1 N S W IY2 P ER0 Z'],
  "minet": ['M IH1 N IH0 T', 'M AY1 N AH0 T'],
  "mineta": ['M IH0 N EY1 T AH0'],
  "minette": ['M IH0 N EH1 T'],
  "mineworker": ['M AY1 N W ER2 K ER0'],
  "mineworkers": ['M AY1 N W ER2 K ER0 Z'],
  "ming": ['M IH1 NG'],
  "ming-jen": ['M IH1 NG JH EH1 N'],
  "minge": ['M IH1 N JH'],
  "minger": ['M IH1 NG ER0'],
  "minges": ['M IH1 N JH IH0 Z'],
  "mingle": ['M IH1 NG G AH0 L'],
  "mingled": ['M IH1 NG G AH0 L D'],
  "mingles": ['M IH1 NG G AH0 L Z'],
  "mingling": ['M IH1 NG G AH0 L IH0 NG', 'M IH1 NG G L IH0 NG'],
  "mingo": ['M IY1 NG G OW0'],
  "mings": ['M IH1 NG Z'],
  "mingun": ['M IH1 N G AH0 N'],
  "mingus": ['M IH1 NG G IH0 S'],
  "minh": ['M IH1 N'],
  "mini": ['M IH1 N IY0'],
  "mini-cost": ['M IH1 N IY0 K AO2 S T'],
  "miniard": ['M IH1 N Y ER0 D'],
  "miniature": ['M IH1 N IY0 AH0 CH UH2 R', 'M IH1 N IH0 CH UH2 R'],
  "miniatures": ['M IH1 N IY0 AH0 CH ER0 Z', 'M IH1 N IH0 CH ER0 Z'],
  "miniaturization": ['M IH2 N IY0 AH0 CH ER2 IH0 Z EY1 SH AH0 N'],
  "miniaturize": ['M IH1 N IH0 CH ER0 AY2 Z'],
  "miniaturized": ['M IH1 N IH0 CH ER0 AY2 Z D'],
  "minibus": ['M IH1 N IY0 B AH2 S'],
  "minibuses": ['M IH1 N IY0 B AH2 S IH0 Z'],
  "minicar": ['M IH1 N IY0 K AA2 R'],
  "minicars": ['M IH1 N IY0 K AA1 R Z'],
  "minich": ['M IH1 N IH0 CH'],
  "minichiello": ['M IY0 N IY0 K IY0 EH1 L OW0'],
  "minick": ['M IH1 N IH0 K'],
  "minicomputer": ['M IH2 N IY0 K AH0 M P Y UW1 T ER0'],
  "minicomputers": ['M IH2 N IY0 K AH0 M P Y UW1 T ER0 Z'],
  "minicucci": ['M IY0 N IY0 K UW1 CH IY0'],
  "minidisc": ['M IH1 N IY0 D IH2 S K'],
  "minier": ['M IH1 N IY0 ER0'],
  "minihan": ['M IH1 N IH0 HH AE0 N'],
  "minilab": ['M IH1 N IY0 L AE2 B'],
  "minilabs": ['M IH1 N IY0 L AE2 B Z'],
  "minimal": ['M IH1 N AH0 M AH0 L'],
  "minimalism": ['M IH1 N AH0 M AH0 L IH2 Z AH0 M'],
  "minimalist": ['M IH1 N AH0 M AH0 L IH0 S T'],
  "minimally": ['M IH1 N AH0 M AH0 L IY0'],
  "minimill": ['M IH1 N IY0 M AA2 L'],
  "minimills": ['M IH1 N IY0 M IH1 L Z'],
  "minimize": ['M IH1 N AH0 M AY2 Z'],
  "minimized": ['M IH1 N AH0 M AY2 Z D'],
  "minimizes": ['M IH1 N AH0 M AY2 Z AH0 Z'],
  "minimizing": ['M IH1 N AH0 M AY2 Z IH0 NG'],
  "minimum": ['M IH1 N AH0 M AH0 M'],
  "minimums": ['M IH1 N IH0 M AH0 M Z'],
  "mining": ['M AY1 N IH0 NG'],
  "mining's": ['M AY1 N IH0 NG Z'],
  "mininger": ['M AY1 N IH0 NG ER0'],
  "minion": ['M IH1 N Y AH0 N'],
  "minions": ['M IH1 N Y AH0 N Z'],
  "minis": ['M IH1 N IY0 Z'],
  "miniscribe": ['M IH1 N IY0 S K R AY1 B'],
  "miniscule": ['M IH1 N IH0 S K Y UW0 L'],
  "miniseries": ['M IH1 N IH0 S EH2 R IY0 Z', 'M IH1 N IY0 S EH2 R IY0 Z'],
  "minish": ['M IH1 N IH0 SH'],
  "miniskirt": ['M IH1 N IY0 S K ER2 T'],
  "miniskirts": ['M IH1 N IY0 S K ER2 T S'],
  "minister": ['M IH1 N AH0 S T ER0', 'M IH1 N IH0 S T ER0'],
  "minister's": ['M IH1 N IH0 S T ER0 Z'],
  "ministerial": ['M IH2 N IH0 S T IY1 R IY0 AH0 L'],
  "ministering": ['M IH1 N IH0 S T R IH0 NG'],
  "ministers": ['M IH1 N AH0 S T ER0 Z', 'M IH1 N IH0 S T ER0 Z'],
  "ministers'": ['M IH1 N IH0 S T ER0 Z'],
  "ministership": ['M IH1 N IH0 S T ER0 SH IH0 P'],
  "ministral": ['M IH1 N AH0 S T R AH0 L'],
  "ministration": ['M IH2 N AH0 S T R EY1 SH AH0 N'],
  "ministrations": ['M IH2 N AH0 S T R EY1 SH AH0 N Z'],
  "ministries": ['M IH1 N IH0 S T R IY0 Z'],
  "ministry": ['M IH1 N AH0 S T R IY0', 'M IH1 N IH0 S T R IY0'],
  "ministry's": ['M IH1 N AH0 S T R IY0 Z'],
  "minisupercomputer": ['M IH2 N IY0 S UW1 P ER0 K AH2 M P Y UW2 T ER0'],
  "minisupercomputers": ['M IH2 N IY0 S UW1 P ER0 K AH2 M P Y UW2 T ER0 Z'],
  "minit": ['M IH1 N IH0 T'],
  "minitel": ['M IH1 N AH0 T EH2 L'],
  "minium": ['M IH1 N IY0 AH0 M'],
  "minivan": ['M IH1 N IY0 V AE1 N'],
  "minivans": ['M IH1 N IY0 V AE1 N Z'],
  "minix": ['M IH1 N IH0 K S'],
  "minjares": ['M IY0 N Y AA1 R EH0 S'],
  "minjarez": ['M IY0 N Y AA1 R EH0 Z'],
  "mink": ['M IH1 NG K'],
  "minke": ['M IH1 NG K'],
  "minkel": ['M IH1 NG K AH0 L'],
  "minkin": ['M IH1 NG K IH0 N'],
  "minkler": ['M IH1 NG K L ER0'],
  "minkoff": ['M IH1 NG K AO2 F'],
  "minkow": ['M IH1 NG K AW0'],
  "minks": ['M IH1 NG K S'],
  "minna": ['M IH1 N AH0'],
  "minnaar": ['M IH0 N AA1 R'],
  "minneapolis": ['M IH2 N IY0 AE1 P AH0 L IH0 S'],
  "minneapolis's": ['M IH2 N IY0 AE1 P AH0 L IH0 S IH0 Z'],
  "minnelli": ['M IH0 N EH1 L IY0'],
  "minner": ['M IH1 N ER0'],
  "minnesota": ['M IH2 N IH0 S OW1 T AH0'],
  "minnesota's": ['M IH2 N AH0 S OW1 T AH0 Z'],
  "minnesotan": ['M IH2 N AH0 S OW1 T AH0 N'],
  "minnesotans": ['M IH2 N AH0 S OW1 T AH0 N Z'],
  "minnetonka": ['M IH2 N IH0 T AO1 NG K AH0'],
  "minney": ['M IH1 N IY0'],
  "minnich": ['M IH1 N IH0 CH'],
  "minnick": ['M IH1 N IH0 K'],
  "minnie": ['M IH1 N IY0'],
  "minnie's": ['M IH1 N IY0 Z'],
  "minniear": ['M IH1 N IY0 IH2 R'],
  "minnifield": ['M IH1 N AH0 F IY2 L D'],
  "minnig": ['M IH1 N IH0 G'],
  "minnis": ['M IH1 N IH0 S'],
  "minniti": ['M IY0 N IY1 T IY0'],
  "minnix": ['M IH1 N IH0 K S'],
  "minnow": ['M IH1 N OW0'],
  "minnows": ['M IH1 N OW0 Z'],
  "minns": ['M IH1 N Z'],
  "minntech": ['M IH1 N T EH2 K'],
  "minny": ['M IH1 N IY0'],
  "mino": ['M IY1 N OW0'],
  "minoan": ['M AH0 N OW1 AH0 N'],
  "minogue": ['M IY1 N AO0 G', 'M AH0 N OW1 G IY0'],
  "minoli": ['M IH0 N OW1 L IY0'],
  "minolta": ['M IH0 N AA1 L T AH2', 'M IH0 N OW1 L T AH0'],
  "minor": ['M AY1 N ER0'],
  "minorca": ['M AH0 N AO1 R K AH0'],
  "minorco": ['M IH0 N AO1 R K OW0'],
  "minorco's": ['M IH0 N AO1 R K AH0 Z'],
  "minored": ['M AY1 N ER0 D'],
  "minoring": ['M AY1 N ER0 IH0 NG'],
  "minorite": ['M IH1 N ER0 AY2 T'],
  "minorities": ['M AY0 N AO1 R AH0 T IY0 Z', 'M AH0 N AO1 R AH0 T IY0 Z'],
  "minority": ['M AY0 N AO1 R AH0 T IY0', 'M AH0 N AO1 R AH0 T IY0'],
  "minors": ['M AY1 N ER0 Z'],
  "minoru": ['M IH0 N AO1 R UW0'],
  "minoso": ['M AH0 N OW1 S OW0'],
  "minosos": ['M AH0 N OW1 S OW0 Z'],
  "minot": ['M IH1 N AH0 T', 'M IH0 N AA1 T'],
  "minott": ['M IH1 N AH0 T'],
  "minotti": ['M IH0 N AO1 T IY0'],
  "minow": ['M IH1 N OW0'],
  "minoxidil": ['M IH0 N AA1 K S IH0 D IH0 L'],
  "minpeco": ['M IH0 N P EH1 K OW0'],
  "minshall": ['M IH1 N SH AH0 L'],
  "minshew": ['M IH1 N SH UW0'],
  "minsk": ['M IH1 N S K'],
  "minskoff": ['M IH1 N S K AO0 F'],
  "minsky": ['M IH1 N S K IY0'],
  "minson": ['M IH1 N S AH0 N'],
  "minstar": ['M IH1 N S T AA2 R'],
  "minstar's": ['M IH1 N S T AA2 R Z'],
  "minster": ['M IH1 N S T ER0'],
  "minstrel": ['M IH1 N S T R AH0 L'],
  "minstrels": ['M IH1 N S T R AH0 L Z'],
  "mint": ['M IH1 N T'],
  "mint's": ['M IH1 N T S'],
  "minta": ['M IH1 N T AH0'],
  "mintage": ['M IH1 N T AH0 JH', 'M IH1 N T IH0 JH'],
  "minted": ['M IH1 N T IH0 D'],
  "minteer": ['M IH1 N T IH1 R'],
  "minter": ['M IH1 N T ER0'],
  "mintha": ['M IH1 N TH AH0'],
  "mintier": ['M IH1 N T IY0 ER0'],
  "minting": ['M IH1 N T IH0 NG'],
  "minto": ['M IH1 N T OW0'],
  "minton": ['M IH1 N T AH0 N'],
  "mints": ['M IH1 N T S'],
  "minturn": ['M IH1 N T ER2 N'],
  "mintz": ['M IH1 N T S'],
  "mintzer": ['M IH1 N T Z ER0'],
  "minuet": ['M IH0 N Y UW2 EH1 T'],
  "minus": ['M AY1 N AH0 S'],
  "minuscule": ['M IH1 N AH0 S K Y UW2 L'],
  "minuses": ['M AY1 N AH0 S IH0 Z'],
  "minute": ['M IH1 N AH0 T', 'M AY0 N UW1 T', 'M AY0 N Y UW1 T'],
  "minute's": ['M IH1 N AH0 T S'],
  "minutely": ['M IH1 N AH0 T L IY0'],
  "minuteman": ['M IH1 N AH0 T M AE2 N'],
  "minutemen": ['M IH1 N AH0 T M EH2 N'],
  "minutes": ['M IH1 N AH0 T S'],
  "minutes'": ['M IH1 N AH0 T S'],
  "minutia": ['M IH0 N UW1 SH IY0 AH0'],
  "minutiae": ['M IH0 N UW1 SH IY0 AH0'],
  "minyard": ['M IH1 N Y AA0 R D'],
  "miocene": ['M AY1 AH0 S IY2 N'],
  "mion": ['M AY1 AH0 N'],
  "mione": ['M AY2 OW1 N'],
  "miot": ['M AY1 AH0 T'],
  "miotke": ['M AY2 AA1 T K IY0', 'M Y AA1 T K IY0'],
  "mips": ['M IH1 P S'],
  "mir": ['M IH1 R'],
  "mir's": ['M IH1 R Z'],
  "mira": ['M IH1 R AH0'],
  "mirabal": ['M IH1 R AH0 B AH0 L'],
  "mirabel": ['M IH0 R AA0 B EH1 L'],
  "mirabella": ['M IH0 R AA0 B EH1 L AH0'],
  "mirabelle": ['M IH1 R AH0 B EH2 L'],
  "mirabelli": ['M IH0 R AA0 B EH1 L IY0'],
  "mirabile": ['M IH0 R AA1 B AH0 L'],
  "mirabito": ['M IH0 R AA0 B IY1 T OW0'],
  "miracid": ['M IH2 R AE1 S AH0 D'],
  "miracid's": ['M IH2 R AE1 S AH0 D Z'],
  "miracle": ['M IH1 R AH0 K AH0 L'],
  "miracles": ['M IH1 R AH0 K AH0 L Z'],
  "miraculous": ['M ER0 AE1 K Y AH0 L AH0 S'],
  "miraculously": ['M ER0 AE1 K Y AH0 L AH0 S L IY0'],
  "mirad": ['M AH0 R AE1 D'],
  "mirada": ['M IH0 R AA1 D AH0'],
  "mirage": ['M ER0 AA1 ZH'],
  "mirages": ['M ER0 AA1 ZH AH0 Z'],
  "miraglia": ['M IH0 R AE1 G L IY0 AH0'],
  "mirak": ['M IH1 R AE0 K'],
  "miramar": ['M IH1 R AH0 M AA2 R'],
  "miramax": ['M IH1 R AH0 M AE2 K S'],
  "miramax's": ['M IH1 R AH0 M AE2 K S IH0 Z'],
  "miramontes": ['M IH0 R AA0 M OW1 N T EH0 S'],
  "miranda": ['M ER0 AE1 N D AH0'],
  "miranda's": ['M ER0 AE1 N D AH0 Z'],
  "mirando": ['M IH0 R AE1 N D OW0'],
  "miratec": ['M IH1 R AH0 T EH2 K'],
  "mire": ['M AY1 R', 'M AY1 ER0'],
  "mired": ['M AY1 R D'],
  "mireles": ['M IH0 R EY1 L EH0 S'],
  "mirelez": ['M IH0 R EY1 L EH0 Z'],
  "mirell": ['M IH0 R EH1 L'],
  "mirella": ['M IH0 R EH1 L AH0'],
  "mirelle": ['M ER0 EH1 L'],
  "mirenda": ['M IH0 R EH1 N D AH0'],
  "mires": ['M AY1 R Z'],
  "miriam": ['M IH1 R IY0 AH0 M'],
  "mirick": ['M IH1 R IH0 K'],
  "mirilla": ['M IH0 R IH1 L AH0'],
  "mirkin": ['M ER1 K IH0 N'],
  "mirna": ['M ER1 N AH0'],
  "miro": ['M IH1 R OW0', 'M IH0 R OW1'],
  "miro's": ['M IH1 R OW0 Z', 'M IH0 R OW1 Z'],
  "miron": ['M IH0 R AO1 N'],
  "mironenko": ['M IH2 R OW0 N EH1 NG K OW0'],
  "miroslav": ['M IH1 R AH0 S L AA0 V'],
  "mirra": ['M IH1 R AH0'],
  "mirren": ['M IH1 R AH0 N'],
  "mirror": ['M IH1 R ER0'],
  "mirror's": ['M IH1 R ER0 Z'],
  "mirrored": ['M IH1 R ER0 D'],
  "mirroring": ['M IH1 R ER0 IH0 NG'],
  "mirrors": ['M IH1 R ER0 Z'],
  "mirsad": ['M IH1 R S AE2 D'],
  "mirsky": ['M ER1 S K IY0'],
  "mirth": ['M ER1 TH'],
  "mirti": ['M ER1 T IY0', 'M ER1 T IY2'],
  "mirtle": ['M ER1 T AH0 L'],
  "mirto": ['M IH1 R T OW0'],
  "mirtz": ['M ER1 T S'],
  "mirv": ['M ER1 V'],
  "mirza": ['M ER1 Z AH0'],
  "mis": ['M IH1 S'],
  "misa": ['M IY1 Z AH0'],
  "misa's": ['M IY1 Z AH0 Z'],
  "misadventure": ['M IH0 S AH0 D V EH1 N CH ER0'],
  "misadventures": ['M IH0 S AH0 D V EH1 N CH ER0 Z'],
  "misallocate": ['M IH0 S AE1 L AH0 K EY2 T'],
  "misallocated": ['M IH0 S AE1 L AH0 K EY2 T IH0 D'],
  "misallocates": ['M IH0 S AE1 L AH0 K EY2 T S'],
  "misallocating": ['M IH0 S AE1 L AH0 K EY2 T IH0 NG'],
  "misallocation": ['M IH0 S AE2 L AH0 K EY1 SH AH0 N'],
  "misanthrope": ['M IH1 S AH0 N TH R OW2 P'],
  "misapplication": ['M IH0 S AE2 P L AH0 K EY1 SH AH0 N'],
  "misapplied": ['M IH2 S AH0 P L AY1 D'],
  "misapplies": ['M IH2 S AH0 P L AY1 Z'],
  "misapply": ['M IH0 S AH0 P L AY1'],
  "misapplying": ['M IH0 S AH0 P L AY1 IH0 NG'],
  "misapprehension": ['M IH0 S AE2 P R IY0 HH EH1 N SH AH0 N'],
  "misappropriate": ['M IH0 S AH0 P R OW1 P R IY0 EY2 T'],
  "misappropriated": ['M IH2 S AH0 P R OW1 P R IY0 EY2 T IH0 D'],
  "misappropriates": ['M IH2 S AH0 P R OW1 P R IY0 EY2 T S'],
  "misappropriating": ['M IH2 S AH0 P R OW1 P R IY0 EY2 T IH0 NG'],
  "misappropriation": ['M IH2 S AH0 P R OW2 P R IY0 EY1 SH AH0 N'],
  "misater": ['M IH0 S EY1 T ER0'],
  "misawa": ['M IH0 S AA1 W AH0'],
  "misbegotten": ['M IH2 S B AH0 G AA1 T AH0 N'],
  "misbehave": ['M IH2 S B AH0 HH EY1 V', 'M IH2 S B IY0 HH EY1 V'],
  "misbehaved": ['M IH2 S B AH0 HH EY1 V D', 'M IH2 S B IY0 HH EY1 V D'],
  "misbehaves": ['M IH2 S B AH0 HH EY1 V Z', 'M IH2 S B IY0 HH EY1 V Z'],
  "misbehaving": ['M IH2 S B AH0 HH EY1 V IH0 NG', 'M IH2 S B IY0 HH EY1 V IH0 NG'],
  "misbehavior": ['M IH2 S B AH0 HH EY1 V Y ER0', 'M IH2 S B IY0 HH EY1 V Y ER0'],
  "misbrener": ['M IH1 S B R EH2 N ER0'],
  "miscalculate": ['M IH0 S K AE1 L K Y AH0 L EY2 T'],
  "miscalculated": ['M IH0 S K AE1 L K Y AH0 L EY2 T IH0 D'],
  "miscalculates": ['M IH0 S K AE1 L K Y AH0 L EY2 T S'],
  "miscalculation": ['M IH0 S K AE1 L K Y AH0 L EY1 SH AH0 N'],
  "miscalculations": ['M IH0 S K AE1 L K Y AH0 L EY1 SH AH0 N Z'],
  "miscarriage": ['M IH0 S K EH1 R AH0 JH'],
  "miscarriages": ['M IH0 S K EH1 R IH0 JH IH0 Z'],
  "miscast": ['M IH0 S K AE1 S T'],
  "miscavige": ['M IH0 S K AE1 V IH1 JH'],
  "miscayuna": ['M IH2 S K AY0 Y UW1 N AH0'],
  "miscayuna's": ['M IH2 S K AY0 Y UW1 N AH0 Z'],
  "miscellaneous": ['M IH2 S AH0 L EY1 N IY0 AH0 S'],
  "miscellany": ['M IH1 S AH0 L EY2 N IY0'],
  "misch": ['M IH1 SH'],
  "mischa": ['M IH1 SH AH0'],
  "mischaracterization": ['M IH0 S K AE2 R AH0 K T ER0 AH0 Z EY1 SH AH0 N'],
  "mischaracterizations": ['M IH0 S K AE2 R AH0 K T ER0 AH0 Z EY1 SH AH0 N Z'],
  "mischaracterize": ['M IH0 S K AE1 R AH0 K T ER0 AY2 Z'],
  "mischaracterized": ['M IH0 S K AE1 R AH0 K T ER0 AY2 Z D'],
  "mischaracterizes": ['M IH0 S K AE1 R AH0 K T ER0 AY2 Z IH0 Z'],
  "mischaracterizing": ['M IH0 S K AE1 R AH0 K T ER0 AY2 Z IH0 NG'],
  "mischarge": ['M IH0 S CH AA1 R JH'],
  "mischarged": ['M IH0 S CH AA1 R JH D'],
  "mischarges": ['M IH0 S CH AA1 R JH IH0 Z'],
  "mischarging": ['M IH0 S CH AA1 R JH IH0 NG'],
  "mischel": ['M IH1 SH AH0 L'],
  "mischer": ['M IH1 SH ER0'],
  "mischief": ['M IH1 S CH AH0 F'],
  "mischievous": ['M IH1 S CH AH0 V AH0 S'],
  "mischke": ['M IH1 SH K'],
  "mischler": ['M IH1 SH AH0 L ER0', 'M IH1 SH L ER0'],
  "miscibility": ['M IH2 S IH0 B IH1 L IH0 T IY0'],
  "miscible": ['M IH1 S AH0 B AH0 L', 'M IH1 S IH0 B AH0 L'],
  "miscommunicate": ['M IH0 S K AH0 M Y UW1 N AH0 K EY2 T'],
  "miscommunication": ['M IH0 S K AH0 M Y UW2 N AH0 K EY1 SH AH0 N'],
  "misconceive": ['M IH0 S K AH0 N S IY1 V'],
  "misconceived": ['M IH0 S K AH0 N S IY1 V D'],
  "misconceives": ['M IH0 S K AH0 N S IY1 V Z'],
  "misconceiving": ['M IH0 S K AH0 N S IY1 V IH0 NG'],
  "misconception": ['M IH0 S K AH0 N S EH1 P SH AH0 N'],
  "misconceptions": ['M IH0 S K AH0 N S EH1 P SH AH0 N Z'],
  "misconduct": ['M IH0 S K AA1 N D AH0 K T'],
  "misconstrue": ['M IH0 S K AH0 N S T R UW1'],
  "misconstrued": ['M IH2 S K AH0 N S T R UW1 D'],
  "misconstrues": ['M IH0 S K AH0 N S T R UW1 Z'],
  "misconstruing": ['M IH0 S K AH0 N S T R UW1 IH0 NG'],
  "miscount": ['M IH1 S K AW1 N T'],
  "miscreant": ['M IH1 S K R IY0 AH0 N T'],
  "miscreants": ['M IH1 S K R IY0 AH0 N T S'],
  "miscreation": ['M IH0 S K R IY0 EY1 SH AH0 N'],
  "miscreations": ['M IH2 S K R IY0 EY1 SH AH0 N Z'],
  "miscue": ['M IH1 S K Y UW2'],
  "miscues": ['M IH1 S K Y UW2 Z'],
  "misdeed": ['M IH1 S D IY1 D'],
  "misdeeds": ['M IH0 S D IY1 D Z'],
  "misdemeanor": ['M IH2 S D AH0 M IY1 N ER0'],
  "misdemeanors": ['M IH2 S D AH0 M IY1 N ER0 Z'],
  "misdiagnose": ['M IH0 S D AY2 IH0 G N OW1 Z'],
  "misdiagnosed": ['M IH0 S D AY2 IH0 G N OW1 Z D'],
  "misdiagnoses": ['M IH0 S D AY2 IH0 G N OW1 S IY0 Z', 'M IH0 S D AY2 IH0 G N OW1 S AH0 Z'],
  "misdiagnosis": ['M IH0 S D AY2 IH0 G N OW1 S AH0 S'],
  "misdirect": ['M IH0 S D IH0 R EH1 K T'],
  "misdirected": ['M IH0 S D IH0 R EH1 K T IH0 D'],
  "misek": ['M IH1 S EH0 K'],
  "misener": ['M IH1 S IY0 N ER0'],
  "misenheimer": ['M IH1 S IH0 N HH AY0 M ER0'],
  "miser": ['M AY1 Z ER0'],
  "miserable": ['M IH1 Z ER0 AH0 B AH0 L', 'M IH1 Z R AH0 B AH0 L'],
  "miserables": ['M IH1 Z ER0 AH0 B AH0 L Z', 'M IH1 Z R AH0 B AH0 L Z', 'M IH2 Z ER0 AA1 B'],
  "miserably": ['M IH1 Z ER0 AH0 B L IY0', 'M IH1 Z R AH0 B L IY0'],
  "miseration": ['M IH0 Z ER0 EY1 SH AH0 N'],
  "miseries": ['M IH1 Z ER0 IY0 Z'],
  "miserly": ['M AY1 Z ER0 L IY0'],
  "misers": ['M AY1 Z ER0 Z'],
  "misery": ['M IH1 Z ER0 IY0'],
  "mises": ['M AY1 Z IH0 Z'],
  "misfeldt": ['M IH1 S F IH0 L T'],
  "misfire": ['M IH0 S F AY1 ER0'],
  "misfit": ['M IH1 S F IH2 T'],
  "misfits": ['M IH1 S F IH2 T S'],
  "misfortune": ['M IH0 S F AO1 R CH AH0 N'],
  "misfortunes": ['M IH0 S F AO1 R CH AH0 N Z'],
  "misgive": ['M IH0 S G IH1 V'],
  "misgiving": ['M IH0 S G IH1 V IH0 NG'],
  "misgivings": ['M IH0 S G IH1 V IH0 NG Z'],
  "misgovernment": ['M IH0 S G AH1 V ER0 N M AH0 N T'],
  "misguide": ['M IH0 S G AY1 D'],
  "misguided": ['M IH0 S G AY1 D IH0 D'],
  "misguides": ['M IH0 S G AY1 D Z'],
  "mish": ['M IH1 SH'],
  "misha": ['M IH1 SH AH0'],
  "mishandle": ['M IH0 S HH AE1 N D AH0 L'],
  "mishandled": ['M IH0 S HH AE1 N D AH0 L D'],
  "mishandles": ['M IH0 S HH AE1 N D AH0 L Z'],
  "mishandling": ['M IH0 S HH AE1 N D L IH0 NG'],
  "mishap": ['M IH1 S HH AE2 P'],
  "mishaps": ['M IH1 S HH AE2 P S'],
  "mishawaka": ['M IH2 SH AH0 W AO1 K AH0'],
  "mishawaum": ['M IH1 SH AH0 W AA2 M'],
  "mishawum": ['M IH1 SH AH0 W AH2 M'],
  "mishear": ['M IH0 S HH IY1 R'],
  "misheard": ['M IH2 S HH ER1 D'],
  "mishkin": ['M IY1 SH K IY0 N'],
  "mishler": ['M IH1 SH L ER0'],
  "mishmash": ['M IH1 SH M AE2 SH'],
  "mishoe": ['M IY1 SH UW0'],
  "mishra": ['M IH1 SH R AH0'],
  "misiak": ['M IH1 S IY0 AE0 K'],
  "misiaszek": ['M IH0 S IY0 AA1 SH EH0 K'],
  "misidentification": ['M IH2 S AY0 D EH2 N T IH0 F IH0 K EY1 SH AH0 N', 'M IH2 S AY0 D EH2 N IH0 F IH0 K EY1 SH AH0 N'],
  "misidentified": ['M IH0 S AY0 D EH1 N T IH0 F AY2 D', 'M IH0 S AY0 D EH1 N IH0 F AY2 D'],
  "misidentifies": ['M IH0 S AY0 D EH1 N T IH0 F AY2 Z', 'M IH0 S AY0 D EH1 N IH0 F AY2 Z'],
  "misidentify": ['M IH0 S AY0 D EH1 N T AH0 F AY2', 'M IH0 S AY0 D EH1 N AH0 F AY2'],
  "misidentifying": ['M IH0 S AY0 D EH1 N T AH0 F AY2 IH0 NG', 'M IH0 S AY0 D EH1 N AH0 F AY2 IH0 NG'],
  "misiewicz": ['M IH1 S AH0 V IH0 CH'],
  "misimpression": ['M IH0 S IH0 M P R EH1 SH AH0 N'],
  "misinform": ['M IH0 S IH2 N F AO1 R M'],
  "misinformation": ['M IH2 S IH0 N F ER0 M EY1 SH AH0 N'],
  "misinformed": ['M IH0 S IH0 N F AO1 R M D'],
  "misinforming": ['M IH0 S IH0 N F AO1 R M IH0 NG'],
  "misinforms": ['M IH0 S IH2 N F AO1 R M Z'],
  "misinterpret": ['M IH0 S IH0 N T ER1 P R AH0 T'],
  "misinterpretation": ['M IH0 S IH0 N T ER2 P R AH0 T EY1 SH AH0 N'],
  "misinterpreted": ['M IH0 S IH0 N T ER1 P R AH0 T IH0 D'],
  "misinterpreting": ['M IH0 S IH0 N T ER1 P R AH0 T IH0 NG'],
  "misinterprets": ['M IH0 S IH0 N T ER1 P R AH0 T S'],
  "misjudge": ['M IH0 S JH AH1 JH'],
  "misjudged": ['M IH0 S JH AH1 JH D'],
  "misjudges": ['M IH0 S JH AH1 JH IH0 Z'],
  "misjudging": ['M IH0 S JH AH1 JH IH0 NG'],
  "misjudgment": ['M IH0 S JH AH1 JH M AH0 N T'],
  "misjudgments": ['M IH0 S JH AH1 JH M AH0 N T S'],
  "miska": ['M IH1 S K AH0'],
  "miske": ['M IH1 S K'],
  "miskell": ['M IH1 S K AH0 L'],
  "miskito": ['M IH0 S K IY1 T OW0'],
  "miskitos": ['M IH0 S K IY1 T OW0 S'],
  "misko": ['M IH1 S K OW0'],
  "mislabel": ['M IH0 S L EY1 B AH0 L'],
  "mislabeled": ['M IH0 S L EY1 B AH0 L D'],
  "mislabeling": ['M IH0 S L EY1 B AH0 L IH0 NG', 'M IH0 S L EY1 B L IH0 NG'],
  "mislabels": ['M IH0 S L EY1 B AH0 L Z'],
  "mislaid": ['M IH0 S L EY1 D'],
  "mislead": ['M IH0 S L IY1 D'],
  "misleading": ['M IH0 S L IY1 D IH0 NG'],
  "misleadingly": ['M IH0 S L IY1 D IH0 NG L IY0'],
  "misleads": ['M IH0 S L IY1 D Z'],
  "misled": ['M IH0 S L EH1 D'],
  "mismanage": ['M IH0 S M AE1 N IH0 JH'],
  "mismanaged": ['M IH0 S M AE1 N IH0 JH D'],
  "mismanagement": ['M IH0 S M AE1 N IH0 JH M AH0 N T'],
  "mismanages": ['M IH0 S M AE1 N IH0 JH AH0 Z'],
  "mismanaging": ['M IH0 S M AE1 N IH0 JH IH0 NG'],
  "mismatch": ['M IH0 S M AE1 CH', 'M IH1 S M AE2 CH'],
  "mismatched": ['M IH0 S M AE1 CH T'],
  "mismatches": ['M IH0 S M AE1 CH IH0 Z'],
  "misner": ['M IH1 Z N ER0'],
  "misnomer": ['M IH0 S N OW1 M ER0'],
  "misogyne": ['M IH1 Z AH0 JH IH0 N'],
  "misogynist": ['M IH1 Z AH0 JH IH0 N IH0 S T'],
  "misogynists": ['M IH1 Z AH0 JH IH0 N IH0 S T S', 'M IH1 Z AH0 JH IH0 N IH0 S'],
  "misogyny": ['M IH1 Z AH0 JH IH0 N IY0'],
  "misperceive": ['M IH0 S P ER0 S IY1 V'],
  "misperceived": ['M IH0 S P ER0 S IY1 V D'],
  "misperceives": ['M IH0 S P ER0 S IY1 V Z'],
  "misperception": ['M IH2 S P ER0 S EH1 P SH AH0 N'],
  "misperceptions": ['M IH0 S P ER0 S EH1 P SH AH0 N Z'],
  "misplace": ['M IH0 S P L EY1 S'],
  "misplaced": ['M IH0 S P L EY1 S T'],
  "misplaces": ['M IH0 S P L EY1 S IH0 Z'],
  "misplacing": ['M IH0 S P L EY1 S IH0 NG'],
  "misprice": ['M IH0 S P R AY1 S'],
  "mispriced": ['M IH0 S P R AY1 S T'],
  "misprices": ['M IH0 S P R AY1 S IH0 Z'],
  "mispricing": ['M IH0 S P R AY1 S IH0 NG'],
  "misprint": ['M IH1 S P R IH1 N T'],
  "misprinted": ['M IH1 S P R IH1 N T AH0 D'],
  "misprints": ['M IH1 S P R IH1 N T S'],
  "misprision": ['M IH0 S P R IH1 ZH AH0 N'],
  "mispronounce": ['M IH0 S P R AH0 N AW1 N S'],
  "mispronounced": ['M IH0 S P R AH0 N AW1 N S T'],
  "mispronounces": ['M IH0 S P R AH0 N AW1 N S IH0 Z'],
  "mispronouncing": ['M IH0 S P R AH0 N AW1 N S IH0 NG'],
  "misquote": ['M IH0 S K W OW1 T'],
  "misquoted": ['M IH0 S K W OW1 T IH0 D'],
  "misquotes": ['M IH0 S K W OW1 T S'],
  "misquoting": ['M IH0 S K W OW1 T IH0 NG'],
  "misread": ['M IH0 S R IY1 D', 'M IH0 S R EH1 D'],
  "misreading": ['M IH0 S R IY1 D IH0 NG'],
  "misrecognition": ['M IH0 S R EH2 K AH0 G N IH1 SH AH0 N'],
  "misrecognize": ['M IH0 S R EH1 K AH0 G N AY2 Z'],
  "misrecognized": ['M IH0 S R EH1 K AH0 G N AY2 Z D'],
  "misrecognizes": ['M IH0 S R EH1 K AH0 G N AY2 Z IH0 Z'],
  "misrecognizing": ['M IH0 S R EH1 K AH0 G N AY2 Z IH0 NG'],
  "misremember": ['M IH0 S R IY0 M EH1 M B ER0'],
  "misremembered": ['M IH0 S R IY0 M EH1 M B ER0 D'],
  "misreport": ['M IH0 S R IH0 P AO1 R T'],
  "misreported": ['M IH0 S R IH0 P AO1 R T IH0 D'],
  "misrepresent": ['M IH0 S R EH2 P R AH0 Z EH1 N T'],
  "misrepresentation": ['M IH0 S R EH2 P R IH0 Z EH0 N T EY1 SH AH0 N'],
  "misrepresentations": ['M IH0 S R EH2 P R IH0 Z EH0 N T EY1 SH AH0 N Z'],
  "misrepresented": ['M IH2 S R EH0 P R IH0 Z EH1 N T IH0 D', 'M IH2 S R EH0 P R IH0 Z EH1 N IH0 D'],
  "misrepresenting": ['M IH0 S R EH2 P R AH0 Z EH1 N T IH0 NG', 'M IH0 S R EH2 P R AH0 Z EH1 N IH0 NG'],
  "misrepresents": ['M IH0 S R EH2 P R AH0 Z EH1 N T S', 'M IH0 S R EH2 P R AH0 Z EH1 N S'],
  "misrule": ['M IH0 S R UW1 L'],
  "miss": ['M IH1 S'],
  "missal": ['M IH1 S AH0 L'],
  "missed": ['M IH1 S T'],
  "missel": ['M IH1 S AH0 L'],
  "misses": ['M IH1 S AH0 Z', 'M IH1 S IH0 Z'],
  "missett": ['M IH1 S AH0 T'],
  "missey": ['M IH1 S IY0'],
  "misshapen": ['M IH0 S SH EY1 P AH0 N', 'M IH0 S HH AE1 P AH0 N'],
  "missie": ['M IH1 S IY0'],
  "missildine": ['M IH0 S IY0 L D IY1 N IY0', 'M IH0 S IH0 L D AY1 N'],
  "missile": ['M IH1 S AH0 L'],
  "missile's": ['M IH1 S AH0 L Z'],
  "missiles": ['M IH1 S AH0 L Z'],
  "missiles'": ['M IH1 S AH0 L Z'],
  "missimer": ['M IH1 S IH0 M ER0'],
  "missing": ['M IH1 S IH0 NG'],
  "mission": ['M IH1 SH AH0 N'],
  "mission's": ['M IH1 SH AH0 N Z'],
  "missionaries": ['M IH1 SH AH0 N EH2 R IY0 Z'],
  "missionary": ['M IH1 SH AH0 N EH2 R IY0'],
  "missions": ['M IH1 SH AH0 N Z'],
  "missis": ['M IH1 S IH0 Z'],
  "mississauga": ['M IH2 S IH0 S AO1 G AH0'],
  "mississippi": ['M IH2 S IH0 S IH1 P IY0'],
  "mississippi's": ['M IH2 S IH0 S IH1 P IY0 Z'],
  "mississippian": ['M IH2 S IH0 S IH1 P IY0 AH0 N'],
  "mississippians": ['M IH2 S IH0 S IH1 P IY0 AH0 N Z'],
  "mississippis": ['M IH2 S IH0 S IH1 P IY0 Z'],
  "missive": ['M IH1 S IH0 V'],
  "missler": ['M IH1 S L ER0'],
  "missoula": ['M IH0 Z UW1 L AH0'],
  "missouri": ['M AH0 Z UH1 R IY0', 'M AH0 Z ER1 AH0'],
  "missouri's": ['M AH0 Z UH1 R IY0 Z', 'M AH0 Z ER1 AH0 Z'],
  "missourian": ['M AH0 Z UH1 R IY0 AH0 N'],
  "missourians": ['M AH0 Z UH1 R IY0 AH0 N Z'],
  "misspeak": ['M IH0 S S P IY1 K', 'M IH0 S P IY1 K'],
  "misspell": ['M IH0 S S P EH1 L', 'M IH0 S P EH1 L'],
  "misspelled": ['M IH0 S S P EH1 L D', 'M IH0 S P EH1 L D'],
  "misspelling": ['M IH0 S S P EH1 L IH0 NG', 'M IH0 S P EH1 L IH0 NG'],
  "misspells": ['M IH0 S S P EH1 L Z', 'M IH0 S P EH1 L Z'],
  "misspend": ['M IH0 S S P EH1 N D', 'M IH0 S P EH1 N D'],
  "misspending": ['M IH0 S S P EH1 N D IH0 NG', 'M IH0 S P EH1 N D IH0 NG'],
  "misspent": ['M IH0 S S P EH1 N T', 'M IH0 S P EH1 N T'],
  "misspoke": ['M IH0 S S P OW1 K', 'M IH0 S P OW1 K'],
  "misspoken": ['M IH0 S S P OW1 K AH0 N', 'M IH0 S P OW1 K AH0 N'],
  "misstate": ['M IH0 S S T EY1 T', 'M IH0 S T EY1 T'],
  "misstated": ['M IH0 S S T EY1 T IH0 D', 'M IH0 S T EY1 T IH0 D'],
  "misstatement": ['M IH0 S T EY1 T M AH0 N T'],
  "misstatements": ['M IH0 S T EY1 T M AH0 N T S', 'M IH0 S T EY1 T M AH0 N S'],
  "misstates": ['M IH0 S S T EY1 T S', 'M IH0 S T EY1 T S'],
  "misstating": ['M IH0 S S T EY1 T IH0 NG', 'M IH0 S T EY1 T IH0 NG'],
  "misstep": ['M IH0 S S T EH1 P', 'M IH0 S T EH1 P'],
  "missteps": ['M IH0 S S T EH1 P S', 'M IH0 S T EH1 P S'],
  "missus": ['M IH1 S IH0 Z'],
  "missy": ['M IH1 S IY0'],
  "missy's": ['M IH1 S IY0 Z'],
  "mist": ['M IH1 S T'],
  "mistake": ['M IH0 S T EY1 K'],
  "mistaken": ['M IH0 S T EY1 K AH0 N'],
  "mistakenly": ['M IH0 S T EY1 K AH0 N L IY0'],
  "mistakes": ['M IH0 S T EY1 K S'],
  "mistaking": ['M IH0 S T EY1 K IH0 NG'],
  "mister": ['M IH1 S T ER0'],
  "mistero": ['M IH0 S T EH1 R OW0'],
  "misters": ['M IH1 S T ER0 Z'],
  "mistic": ['M IH1 S T IH0 K'],
  "mistler": ['M IH1 S T L ER0'],
  "mistletoe": ['M IH1 S AH0 L T OW2'],
  "mistook": ['M IH0 S T UH1 K'],
  "mistral": ['M IH1 S T R AH0 L'],
  "mistreat": ['M IH0 S T R IY1 T'],
  "mistreated": ['M IH0 S T R IY1 T IH0 D'],
  "mistreating": ['M IH0 S T R IY1 T IH0 NG'],
  "mistreatment": ['M IH0 S T R IY1 T M AH0 N T'],
  "mistreats": ['M IH0 S T R IY1 T S'],
  "mistress": ['M IH1 S T R AH0 S'],
  "mistresses": ['M IH1 S T R AH0 S AH0 Z', 'M IH1 S T R AH0 S IH0 Z'],
  "mistretta": ['M IH0 S T R EH1 T AH0'],
  "mistrial": ['M IH0 S T R AY1 AH0 L', 'M IH1 S T R AY2 AH0 L'],
  "mistrials": ['M IH0 S T R AY1 AH0 L Z', 'M IH1 S T R AY2 AH0 L Z'],
  "mistrust": ['M IH0 S T R AH1 S T'],
  "mistrusted": ['M IH0 S T R AH1 S T IH0 D'],
  "mistrustful": ['M IH0 S T R AH1 S T F AH0 L', 'M IH0 S T R AH1 S F AH0 L'],
  "mistrusting": ['M IH0 S T R AH1 S T IH0 NG'],
  "mistrusts": ['M IH0 S T R AH1 S T S', 'M IH0 S T R AH1 S'],
  "mistry": ['M IH1 S T R IY0', 'M IH0 S T R AY1'],
  "mists": ['M IH1 S T S'],
  "misty": ['M IH1 S T IY0'],
  "misunder": ['M IH0 S AH1 N D ER0'],
  "misunderstand": ['M IH2 S AH0 N D ER0 S T AE1 N D'],
  "misunderstanding": ['M IH2 S AH0 N D ER0 S T AE1 N D IH0 NG'],
  "misunderstandings": ['M IH2 S AH0 N D ER0 S T AE1 N D IH0 NG Z'],
  "misunderstands": ['M IH2 S AH0 N D ER0 S T AE1 N D Z'],
  "misunderstood": ['M IH2 S AH0 N D ER0 S T UH1 D'],
  "misuraca": ['M IH0 S UH0 R AA1 K AH0'],
  "misuse": ['M IH0 S Y UW1 Z', 'M IH0 S Y UW1 S'],
  "misused": ['M IH0 S Y UW1 Z D'],
  "misuses": ['M IH0 S Y UW1 Z IH0 Z', 'M IH0 S Y UW1 S IH0 Z'],
  "misusing": ['M IH0 S Y UW1 Z IH0 NG'],
  "mit": ['EH1 M AY1 T IY1', 'M IH1 T'],
  "mita": ['M IY1 T AH0'],
  "mitamura": ['M IY2 T AH0 M UH1 R AH0'],
  "mitch": ['M IH1 CH'],
  "mitcham": ['M IH1 CH AH0 M'],
  "mitchel": ['M IH1 CH AH0 L'],
  "mitchell": ['M IH1 CH AH0 L'],
  "mitchell's": ['M IH1 CH AH0 L Z'],
  "mitchelson": ['M IH1 CH AH0 L S AH0 N'],
  "mitcheltree": ['M IH0 CH IH0 L T R IY1'],
  "mitchem": ['M IH1 CH IH0 M'],
  "mitchen": ['M IH1 CH AH0 N'],
  "mitchener": ['M IH1 CH IY0 N ER0'],
  "mitchner": ['M IH1 CH N ER0'],
  "mitchum": ['M IH1 CH AH0 M'],
  "mite": ['M AY1 T'],
  "mitek": ['M AY1 T EH2 K'],
  "mitel": ['M AY1 T EH2 L'],
  "miter": ['M AY1 T ER0'],
  "mitering": ['M AY1 T ER0 IH0 NG'],
  "mites": ['M AY1 T S'],
  "mithcell": ['M IH1 TH S AH0 L'],
  "mithridate": ['M IH1 TH R IH0 D EY2 T'],
  "mithridates": ['M IH1 TH R IH0 D EY2 T S'],
  "mithun": ['M IH1 TH AH0 N'],
  "miti": ['M IH1 T IY2', 'M IY1 T IY2', 'M IY1 T IY0'],
  "mitigate": ['M IH1 T AH0 G EY2 T'],
  "mitigated": ['M IH1 T AH0 G EY2 T IH0 D'],
  "mitigates": ['M IH1 T AH0 G EY2 T S'],
  "mitigating": ['M IH1 T AH0 G EY2 T IH0 NG'],
  "mitigation": ['M IH2 T IH0 G EY1 SH AH0 N'],
  "mitman": ['M IH1 T M AH0 N'],
  "mitnick": ['M IH1 T N IH0 K'],
  "mitochondria": ['M AY2 T AH0 K AA1 N D R IY0 AH0'],
  "mitochondrial": ['M AY2 T AH0 K AA1 N D R IY0 AH0 L'],
  "mitochondrion": ['M AY2 T AH0 K AA1 N D R IY0 AH0 N'],
  "mitofsky": ['M IH0 T AO1 F S K IY0'],
  "mitotic": ['M AY2 T AO1 T IH0 K'],
  "mitra": ['M IH1 T R AH0'],
  "mitral": ['M AY1 T R AH0 L'],
  "mitrano": ['M IY0 T R AA1 N OW0'],
  "mitre": ['M IH1 T R IY0', 'M AY1 T ER0'],
  "mitro": ['M IH1 T R OW0'],
  "mitrovich": ['M IH1 T R AH0 V IH0 CH'],
  "mitsch": ['M IH1 CH'],
  "mitschke": ['M IH1 CH K'],
  "mitsotakis": ['M IH2 T S OW0 T AA1 K IH0 S'],
  "mitsuba": ['M IY0 T S UW1 B AH0'],
  "mitsubishi": ['M IH2 T S UW2 B IY1 SH IY0'],
  "mitsubishi's": ['M IH2 T S UW2 B IY1 SH IY0 Z'],
  "mitsui": ['M IY0 T S UW1 IY0', 'M IH0 T S UW1 IY0'],
  "mitsui's": ['M IY0 T S UW1 IY0 Z'],
  "mitsukoshi": ['M IY2 T S UW2 K OW1 SH IY0'],
  "mitsuru": ['M IY0 T S UW1 R UW0'],
  "mitt": ['M IH1 T'],
  "mittag": ['M IH1 T AH0 G'],
  "mittal": ['M IH2 T AA1 L'],
  "mittan": ['M IH1 T AH0 N'],
  "mitteleuropa": ['M IH2 T EH2 L UW2 R OW1 P AH0'],
  "mittelman": ['M IH1 T AH0 L M AH0 N'],
  "mittelstadt": ['M IH1 T IH0 L SH T AE0 T'],
  "mittelstaedt": ['M IH1 T IH0 L S T EH0 T'],
  "mittelsteadt": ['M IH1 T IH0 L S T EH0 T'],
  "mitten": ['M IH1 T AH0 N'],
  "mittendorf": ['M IH1 T IH0 N D AO0 R F'],
  "mittens": ['M IH1 T AH0 N Z'],
  "mitterand": ['M IY1 T ER0 AO2 N D', 'M IY1 T ER0 AE2 N D'],
  "mitterand's": ['M IY1 T ER0 AO2 N D Z', 'M IY1 T ER0 AE2 N D Z'],
  "mitterrand": ['M IY1 T ER0 AO2 N D', 'M IY1 T ER0 AE2 N D'],
  "mitterrand's": ['M IY1 T ER0 AE0 N D Z'],
  "mittleman": ['M IH1 T AH0 L M AH0 N'],
  "mittler": ['M IH1 T L ER0'],
  "mittman": ['M IH1 T M AH0 N'],
  "mitton": ['M IH1 T AH0 N'],
  "mitts": ['M IH1 T S'],
  "mityunov": ['M IH1 T Y UW0 N AA0 V'],
  "mitzel": ['M IH1 T Z AH0 L'],
  "mitzi": ['M IH1 T S IY0'],
  "mitzner": ['M IH1 T S N ER0'],
  "mitzvah": ['M IH1 T S V AH0'],
  "miura": ['M IY0 UH1 R AH0'],
  "mix": ['M IH1 K S'],
  "mix-up": ['M IH1 K S AH2 P'],
  "mixed": ['M IH1 K S T'],
  "mixer": ['M IH1 K S ER0'],
  "mixers": ['M IH1 K S ER0 Z'],
  "mixes": ['M IH1 K S AH0 Z', 'M IH1 K S IH0 Z'],
  "mixing": ['M IH1 K S IH0 NG'],
  "mixner": ['M IH1 K S N ER0'],
  "mixology": ['M IH1 K S AO1 L AH0 G IY2'],
  "mixon": ['M IH1 K S AH0 N'],
  "mixson": ['M IH1 K S AH0 N'],
  "mixte": ['M IH1 K S T'],
  "mixter": ['M IH1 K S T ER0'],
  "mixture": ['M IH1 K S CH ER0'],
  "mixtures": ['M IH1 K S CH ER0 Z'],
  "mixup": ['M IH1 K S AH0 P'],
  "mixups": ['M IH1 K S AH0 P S'],
  "miyagawa": ['M IY2 AA0 G AA1 W AH0'],
  "miyahara": ['M IY2 Y AA0 HH AA1 R AH0'],
  "miyake": ['M IY0 Y AA1 K EY0'],
  "miyako": ['M IY1 AH2 K OW2'],
  "miyamori": ['M IY2 AA0 M AO1 R IY0'],
  "miyamoto": ['M IY0 Y AA0 M OW1 T OW0'],
  "miyasaki": ['M IY2 Y AA0 S AA1 K IY0'],
  "miyasato": ['M IY0 Y AA0 S AA1 T OW0'],
  "miyashiro": ['M IY0 Y AA0 SH IH1 R OW0'],
  "miyazaki": ['M IY2 Y AA0 Z AA1 K IY0'],
  "miyazawa": ['M IY2 Y AH0 Z AA1 W AH0'],
  "miyazawa's": ['M IY2 AH0 Z AA1 W AH0 Z'],
  "mizar": ['M AY1 Z AA2 R'],
  "mizar's": ['M AY1 Z AA2 R Z'],
  "mize": ['M AY1 Z'],
  "mize's": ['M AY1 Z IH0 Z'],
  "mizel": ['M AY1 Z AH0 L', 'M AY2 Z EH1 L'],
  "mizell": ['M IH1 Z AH0 L'],
  "mizelle": ['M IH0 Z EH1 L'],
  "mizen": ['M AY1 Z AH0 N'],
  "mizer": ['M AY1 Z ER0'],
  "mizner": ['M IH1 Z N ER0'],
  "mizrahi": ['M IY0 Z R AA1 HH IY0'],
  "mizuno": ['M IH0 Z UW1 N OW0'],
  "mizzell": ['M IH1 Z AH0 L'],
  "mj's": ['EH1 M JH EY1 Z'],
  "mkhatshwa": ['M AH0 K AE1 CH W AH0'],
  "mladenic": ['M L AA2 D EH1 N IH0 CH'],
  "mladic": ['M L AA1 D IH0 CH'],
  "mladic's": ['M L AA1 D IH0 CH IH0 Z'],
  "mlecko": ['M L EH1 K OW0', 'M AH0 L EH1 K OW0'],
  "mlotok": ['M L OW1 T AA2 K', 'M AH0 L AA1 T AA2 K'],
  "mm": ['M'],
  "mmabatho": ['M AH0 B AA1 TH OW0'],
  "mme": ['EH1 M EH1 M IY1'],
  "mnemonic": ['N IH0 M AA1 N IH0 K', 'N IY0 M AA1 N IH0 K'],
  "mnemonics": ['N IH0 M AA1 N IH0 K S', 'N IY0 M AA1 N IH0 K S'],
  "mnookin": ['M N UH1 K AH0 N', 'M AH0 N UH1 K AH0 N'],
  "mo": ['M OW1'],
  "moab": ['M OW1 AE0 B'],
  "moacq": ['M OW1 K'],
  "moad": ['M OW1 D'],
  "moak": ['M OW1 K'],
  "moakley": ['M OW1 K L IY0'],
  "moami": ['M OW1 M IY0'],
  "moammar": ['M OW1 AH0 M AA2 R'],
  "moan": ['M OW1 N'],
  "moaned": ['M OW1 N D'],
  "moaning": ['M OW1 N IH0 NG'],
  "moans": ['M OW1 N Z'],
  "moat": ['M OW1 T'],
  "moates": ['M OW1 EY2 T S'],
  "moats": ['M OW1 T S'],
  "moawiya": ['M OW0 AA1 W IY0 AH0'],
  "mob": ['M AA1 B'],
  "mob's": ['M AA1 B Z'],
  "mobay": ['M OW0 B EY1'],
  "mobbed": ['M AA1 B D'],
  "mobbs": ['M AA1 B Z'],
  "mobe": ['M OW1 B'],
  "moberg": ['M OW1 B ER0 G'],
  "moberly": ['M OW1 B ER0 L IY0'],
  "mobil": ['M OW1 B AH0 L'],
  "mobil's": ['M OW1 B AH0 L Z'],
  "mobile": ['M OW1 B AH0 L'],
  "mobile's": ['M OW1 B AH0 L Z'],
  "mobiler": ['M OW1 B IH0 L ER0'],
  "mobiles": ['M OW1 B AH0 L Z'],
  "mobilia": ['M OW0 B IY1 L IY0 AH0'],
  "mobiliare": ['M OW1 B AH0 L EH2 R'],
  "mobility": ['M OW0 B IH1 L AH0 T IY0', 'M OW0 B IH1 L IH0 T IY0'],
  "mobilization": ['M OW2 B AH0 L AH0 Z EY1 SH AH0 N', 'M OW2 B AH0 L IH0 Z EY1 SH AH0 N'],
  "mobilizations": ['M OW2 B AH0 L AH0 Z EY1 SH AH0 N Z'],
  "mobilize": ['M OW1 B AH0 L AY2 Z'],
  "mobilized": ['M OW1 B AH0 L AY2 Z D'],
  "mobilizer": ['M OW1 B AH0 L AY2 Z ER0'],
  "mobilizers": ['M OW1 B AH0 L AY2 Z ER0 Z'],
  "mobilizing": ['M OW1 B AH0 L AY2 Z IH0 NG'],
  "mobius": ['M OW1 B IY0 AH0 S'],
  "mobley": ['M OW1 B L IY0'],
  "mobs": ['M AA1 B Z'],
  "mobster": ['M AA1 B S T ER0'],
  "mobster's": ['M AA1 B S T ER0 Z'],
  "mobsters": ['M AA1 B S T ER0 Z'],
  "mobutu": ['M OW0 B UW1 T UW2'],
  "mobutu's": ['M OW0 B UW1 T UW2 Z'],
  "moby": ['M OW1 B IY0'],
  "moca": ['M OW1 K AH0'],
  "mocarski": ['M AH0 K AA1 R S K IY0'],
  "mocatta": ['M AH0 K AA1 T AH0'],
  "moccasin": ['M AA1 K AH0 S IH0 N'],
  "moccasins": ['M AA1 K AH0 S IH0 N Z'],
  "moccia": ['M OW1 CH AH0'],
  "moccio": ['M OW1 CH IY0 OW0'],
  "moceri": ['M OW0 CH EH1 R IY0'],
  "moch": ['M AA1 K'],
  "mocha": ['M OW1 K AH0'],
  "mochas": ['M OW1 K AH0 Z'],
  "mochel": ['M AA1 K AH0 L'],
  "mochida": ['M AH0 CH IY1 D AH0'],
  "mochizuki": ['M OW0 CH IY0 Z UW1 K IY0'],
  "mock": ['M AA1 K'],
  "mock-heroically": ['M AA1 K HH ER0 OW1 IH0 K L IY0'],
  "mockby": ['M AA1 K B IY0'],
  "mocked": ['M AA1 K T'],
  "mockery": ['M AA1 K ER0 IY0'],
  "mocking": ['M AA1 K IH0 NG'],
  "mockingbird": ['M AA1 K IH0 NG B ER2 D'],
  "mockler": ['M AA1 K L ER0'],
  "mocks": ['M AA1 K S'],
  "moczygemba": ['M AH0 CH IH0 G EH1 M B AH0'],
  "mod": ['M AO1 D'],
  "modal": ['M OW1 D AH0 L'],
  "modalism": ['M OW1 D AH0 L IH0 Z AH0 M'],
  "modalities": ['M AH0 D AE1 L AH0 T IY0 Z'],
  "modality": ['M AH0 D AE1 L AH0 T IY0'],
  "modarressi": ['M OW0 D AA2 R EH1 S IY0'],
  "mode": ['M OW1 D'],
  "moded": ['M OW1 D IH0 D'],
  "model": ['M AA1 D AH0 L'],
  "model's": ['M AA1 D AH0 L Z'],
  "modeled": ['M AA1 D AH0 L D'],
  "modeling": ['M AA1 D AH0 L IH2 NG', 'M AA1 D L IH0 NG'],
  "modell": ['M OW0 D EY1 L'],
  "modelling": ['M AA1 D AH0 L IH2 NG'],
  "modelo": ['M OW0 D EH1 L OW0'],
  "models": ['M AA1 D AH0 L Z'],
  "models'": ['M AA1 D AH0 L Z'],
  "modem": ['M OW1 D AH0 M'],
  "modems": ['M OW1 D AH0 M Z'],
  "moden": ['M OW1 D AH0 N'],
  "modena": ['M OW0 D EH1 N AH0'],
  "moder": ['M OW1 D ER0'],
  "moderate": ['M AA1 D ER0 AH0 T', 'M AA1 D ER0 EY2 T'],
  "moderated": ['M AA1 D ER0 EY2 T IH0 D'],
  "moderately": ['M AA1 D ER0 AH0 T L IY0'],
  "moderates": ['M AA1 D ER0 AH0 T S', 'M AA1 D ER0 EY2 T S'],
  "moderating": ['M AA1 D ER0 EY2 T IH0 NG'],
  "moderation": ['M AA2 D ER0 EY1 SH AH0 N'],
  "moderator": ['M AA1 D ER0 EY2 T ER0'],
  "moderators": ['M AA1 D ER0 EY2 T ER0 Z'],
  "modern": ['M AA1 D ER0 N'],
  "moderne": ['M OW0 D EH1 R N'],
  "modernism": ['M AA1 D ER0 N IH2 Z AH0 M'],
  "modernist": ['M AA1 D ER0 N IH0 S T'],
  "modernists": ['M AA1 D ER0 N IH0 S T S'],
  "modernity": ['M AA0 D ER1 N AH0 T IY0'],
  "modernization": ['M AA2 D ER0 N AH0 Z EY1 SH AH0 N', 'M AA2 D ER0 N AY0 Z EY1 SH AH0'],
  "modernize": ['M AA1 D ER0 N AY2 Z'],
  "modernized": ['M AA1 D ER0 N AY2 Z D'],
  "modernizes": ['M AA1 D ER0 N AY2 Z IH0 Z'],
  "modernizing": ['M AA1 D ER0 N AY2 Z IH0 NG'],
  "moderns": ['M AA1 D ER0 N Z'],
  "modes": ['M OW1 D Z'],
  "modesitt": ['M AA1 D IH0 S IH0 T'],
  "modest": ['M AA1 D AH0 S T'],
  "modesta": ['M OW0 D EH1 S T AH0'],
  "modestia": ['M OW0 D EH1 S T Y AH0'],
  "modestine": ['M OW0 D EH0 S T IY1 N IY0'],
  "modestly": ['M AA1 D AH0 S T L IY0'],
  "modesto": ['M OW0 D EH1 S T OW0'],
  "modesty": ['M AA1 D AH0 S T IY0'],
  "modglin": ['M AA1 JH L IH0 N'],
  "modi": ['M OW1 D IY0'],
  "modic": ['M AA1 D IH0 K'],
  "modica": ['M OW0 D IY1 K AH0'],
  "modicum": ['M AA1 D IH0 K AH0 M'],
  "modification": ['M AA2 D AH0 F AH0 K EY1 SH AH0 N'],
  "modifications": ['M AA2 D AH0 F AH0 K EY1 SH AH0 N Z'],
  "modified": ['M AA1 D AH0 F AY2 D'],
  "modifier": ['M AA1 D AH0 F AY2 ER0'],
  "modifiers": ['M AA1 D AH0 F AY2 ER0 Z'],
  "modifies": ['M AA1 D AH0 F AY2 Z'],
  "modify": ['M AA1 D AH0 F AY2'],
  "modifying": ['M AA1 D AH0 F AY2 IH0 NG'],
  "modigliani": ['M OW0 D IH2 G L IY0 AA1 N IY0', 'M OW0 D IH2 G L IY0 AE1 N IY0'],
  "modiliani": ['M OW0 D IH2 L IY0 AE1 N IY0'],
  "modine": ['M OW0 D IY1 N'],
  "modisette": ['M AA1 D IH0 S EH0 T'],
  "modjeski": ['M AH0 JH EH1 S K IY0'],
  "modlin": ['M AA1 D L IH0 N'],
  "modrak": ['M AA1 D R AH0 K'],
  "modrow": ['M AA1 D R OW0'],
  "modugno": ['M OW0 D UW1 G N OW0'],
  "modulaire": ['M AO1 JH UW0 L EH2 R'],
  "modular": ['M AA1 JH AH0 L ER0'],
  "modulate": ['M AO1 JH Y UW0 L EY0 T', 'M AO1 JH UW0 L EY0 T'],
  "modulated": ['M AO1 JH Y UW0 L EY0 T IH0 D', 'M AO1 JH UW0 L EY0 T IH0 D'],
  "modulation": ['M AA2 JH AH0 L EY1 SH AH0 N'],
  "modulator": ['M AA1 JH AH0 L EY2 T ER0'],
  "module": ['M AA1 JH UW0 L'],
  "modules": ['M AA1 JH UW0 L Z'],
  "modulo": ['M AO1 JH UW0 L OW2'],
  "modulus": ['M AO1 JH UW0 L UH0 S'],
  "modus": ['M OW1 D AH0 S'],
  "modus-operandi": ['M OW1 D AH0 AO2 P ER0 AE1 N D IY0'],
  "mody": ['M OW1 D IY0'],
  "modzelewski": ['M AH0 JH IH0 L EH1 F S K IY0'],
  "moe": ['M OW1'],
  "moebius": ['M OW1 B IY0 IH0 S'],
  "moeckel": ['M OW1 K AH0 L'],
  "moede": ['M OW1 D'],
  "moehle": ['M OW1 HH AH0 L'],
  "moehlman": ['M OW1 L M AH0 N'],
  "moehring": ['M AO1 R IH0 NG'],
  "moeller": ['M OW1 L ER0'],
  "moellering": ['M OW1 L ER0 IH0 NG'],
  "moen": ['M OW1 N'],
  "moench": ['M OW1 NG K'],
  "moening": ['M AA1 AH0 N IH0 NG'],
  "moerbe": ['M AO1 R B'],
  "moerke": ['M AO1 R K'],
  "moerman": ['M OW1 ER0 M AH0 N'],
  "moers": ['M OW1 ER0 Z'],
  "moertel": ['M AO1 R T EH2 L'],
  "moes": ['M OW1 Z'],
  "moesch": ['M OW1 SH'],
  "moeser": ['M OW1 Z ER0'],
  "moesha": ['M OW2 IY1 SH AH0'],
  "moessner": ['M OW1 S N ER0'],
  "moet": ['M OW1 IH0 T'],
  "mof": ['M AA1 F'],
  "moffa": ['M AA1 F AH0'],
  "moffat": ['M AA1 F AH0 T'],
  "moffatt": ['M AA1 F AH0 T'],
  "moffet": ['M AA1 F IH0 T'],
  "moffett": ['M AA1 F IH0 T'],
  "moffit": ['M AA1 F IH0 T'],
  "moffitt": ['M AA1 F IH0 T'],
  "mofford": ['M AO1 F ER0 D'],
  "mofield": ['M OW1 F IY2 L D'],
  "moga": ['M OW1 G AH0'],
  "mogadishu": ['M OW2 G AH0 D IY1 SH UW2'],
  "mogadishu's": ['M OW2 G AH0 D IY1 SH UW2 Z'],
  "mogan": ['M OW1 G AH0 N'],
  "mogavaro": ['M OW0 G AH0 V AA1 R OW0'],
  "mogavero": ['M OW0 G AA0 V EH1 R OW0'],
  "mogayon": ['M OW0 G AA0 Y AH0 N'],
  "mogayon's": ['M OW0 G AA0 Y AH0 N Z'],
  "mogel": ['M OW1 G AH0 L'],
  "mogensen": ['M AA1 G IH0 N S AH0 N'],
  "moger": ['M OW1 G ER0'],
  "mogg": ['M AA1 G'],
  "mogle": ['M OW1 G AH0 L'],
  "moglia": ['M AA1 G L IY0 AH0'],
  "mogopa": ['M OW0 G OW1 P AH0'],
  "mogul": ['M OW1 G AH0 L'],
  "moguls": ['M OW1 G AH0 L Z'],
  "mohair": ['M OW1 HH EH2 R'],
  "mohamad": ['M AH0 HH AA1 M AH0 D'],
  "mohamadi": ['M AH0 HH AA1 M AH0 D IY0'],
  "mohamed": ['M OW0 HH AA1 M EH0 D'],
  "mohammad": ['M OW0 HH AA1 M EH0 D'],
  "mohammed": ['M OW0 HH AE1 M IH0 D'],
  "mohan": ['M OW1 HH AA0 N'],
  "mohar": ['M OW1 HH ER0'],
  "mohasco": ['M OW0 HH AE1 S K OW0'],
  "mohawk": ['M OW1 HH AO2 K'],
  "mohawks": ['M OW1 HH AO2 K S'],
  "mohel": ['M OW1 AH0 L'],
  "moher": ['M AA1 HH ER0'],
  "mohican": ['M OW2 HH IY1 K AH0 N'],
  "mohicans": ['M OW2 HH IY1 K AH0 N Z'],
  "mohit": ['M OW1 HH IY0 T', 'M OW1 HH IH0 T'],
  "mohl": ['M OW1 L'],
  "mohler": ['M OW1 L ER0'],
  "mohlman": ['M OW1 L M AH0 N'],
  "mohn": ['M AA1 N'],
  "mohney": ['M AA1 N IY0'],
  "mohnke": ['M AA1 N K', 'M AA1 N K IY0'],
  "mohnkern": ['M AA1 N K ER0 N'],
  "moholy-nagy": ['M OW0 HH OW1 L IY0 N EY1 G IY0'],
  "mohon": ['M OW1 HH AH0 N'],
  "mohr": ['M AO1 R'],
  "mohring": ['M AO1 R IH0 NG'],
  "mohrman": ['M AO1 R M AH0 N'],
  "mohrmann": ['M AO1 R M AH0 N'],
  "mohs": ['M AA1 S'],
  "mohtashemi": ['M OW2 T AH0 SH EY1 M IY0'],
  "moi": ['M W AA1'],
  "moiety": ['M OY1 AH0 T IY2'],
  "moilanen": ['M OY1 L AH0 N AH0 N'],
  "moina": ['M OY1 N AH0'],
  "moines": ['M OY1 N Z', 'M OY1 N'],
  "moir": ['M OY1 R'],
  "moira": ['M OY1 R AH0'],
  "moisan": ['M OY0 Z AE1 N'],
  "moise": ['M OY1 Z'],
  "moises": ['M OY1 Z IH0 Z'],
  "moishe": ['M OW1 SH EH0', 'M OY1 SH IH0'],
  "moisi": ['M OY1 S IY0'],
  "moist": ['M OY1 S T'],
  "moisten": ['M OY1 S AH0 N'],
  "moistened": ['M OY1 S AH0 N D'],
  "moisture": ['M OY1 S CH ER0'],
  "moisturizer": ['M OY1 S CH ER0 AY2 Z ER0'],
  "mojave": ['M OW0 HH AA1 V IY0'],
  "mojica": ['M OW0 Y IY1 K AH0'],
  "mojo": ['M OW1 JH OW0'],
  "mok": ['M AA1 K'],
  "moka": ['M OW1 K AH0'],
  "moke": ['M OW1 K'],
  "mokes": ['M OW1 K S'],
  "mokhiber": ['M AA1 K HH IH0 B ER0'],
  "mokoena": ['M AH0 K OW1 N AH0'],
  "mokry": ['M AA1 K R IY0'],
  "mol": ['M AO1 L'],
  "mola": ['M OW1 L AH0'],
  "molaison": ['M AH0 L EY1 Z AH0 N'],
  "moland": ['M AA1 L AH0 N D'],
  "molander": ['M AA1 L AH0 N D ER0'],
  "molano": ['M OW0 L AA1 N OW0'],
  "molar": ['M OW1 L AH0 R'],
  "molasses": ['M AH0 L AE1 S AH0 Z'],
  "molchan": ['M OW1 L CH AH0 N'],
  "mold": ['M OW1 L D'],
  "moldable": ['M OW1 L D AH0 B AH0 L'],
  "moldavia": ['M OW2 L D EY1 V IY0 AH0', 'M OW2 L D AA1 V IY0 AH0'],
  "moldavian": ['M OW2 L D EY1 V IY0 AH0 N', 'M OW2 L D AA1 V IY0 AH0 N'],
  "molded": ['M OW1 L D AH0 D', 'M OW1 L D IH0 D'],
  "molden": ['M OW1 L D AH0 N'],
  "moldenhauer": ['M OW1 L D IH0 N HH AW0 ER0'],
  "molder": ['M OW1 L D ER0'],
  "molders": ['M OW1 L D ER0 Z'],
  "molding": ['M OW1 L D IH0 NG'],
  "moldings": ['M OW1 L D IH0 NG Z'],
  "moldova": ['M OW2 L D OW1 V AH0'],
  "moldovan": ['M OW2 L D OW0 V AA1 N'],
  "molds": ['M OW1 L D Z'],
  "moldy": ['M OW1 L D IY0'],
  "mole": ['M OW1 L'],
  "molecular": ['M AH0 L EH1 K Y AH0 L ER0'],
  "molecule": ['M AA1 L AH0 K Y UW2 L'],
  "molecules": ['M AA1 L AH0 K Y UW2 L Z'],
  "moleculon": ['M OW0 L EH1 K Y UW0 L AA0 N'],
  "molehill": ['M OW1 L HH IH2 L'],
  "molelike": ['M OW1 L L AY2 K'],
  "molen": ['M OW1 L AH0 N'],
  "molenaar": ['M AA1 L IH0 N AA0 R'],
  "molenda": ['M OW0 L EH1 N D AH0'],
  "moler": ['M OW1 L ER0'],
  "moles": ['M OW1 L Z'],
  "moleski": ['M AH0 L EH1 S K IY0'],
  "moleskin": ['M AO1 L EH0 S K IH2 N'],
  "moleskine": ['M AO1 L EH0 S K IH2 N'],
  "molesky": ['M AH0 L EH1 S K IY0'],
  "molest": ['M AH0 L EH1 S T'],
  "molestation": ['M OW2 L EH0 S T EY1 SH AH0 N'],
  "molested": ['M AH0 L EH1 S T IH0 D'],
  "molester": ['M AH0 L EH1 S T ER0'],
  "molesters": ['M AH0 L EH1 S T ER0 Z'],
  "molesting": ['M AH0 L EH1 S T IH0 NG'],
  "molests": ['M AH0 L EH1 S T S'],
  "molesworth": ['M OW1 L Z W ER2 TH'],
  "molex": ['M OW1 L AH0 K S'],
  "moliere": ['M OW0 L Y EH1 R'],
  "moliere's": ['M OW0 L Y EH1 R Z'],
  "molin": ['M OW1 L IH0 N'],
  "molina": ['M AH0 L IY1 N AH0'],
  "molinar": ['M AA1 L IH0 N ER0'],
  "molinari": ['M AO2 L AH0 N AA1 R IY0'],
  "molinaro": ['M OW0 L IY0 N AA1 R OW0'],
  "moline": ['M OW0 L IY1 N'],
  "molineaux": ['M AO1 L AH0 N OW2'],
  "molinelli": ['M OW0 L IY0 N EH1 L IY0'],
  "molino": ['M OW0 L IY1 N OW0'],
  "molitor": ['M OW0 L IY1 T ER0'],
  "moll": ['M AA1 L'],
  "molle": ['M AA1 L'],
  "mollen": ['M AA1 L IH0 N'],
  "mollenhauer": ['M AA1 L IH0 N HH AW0 ER0'],
  "mollenkopf": ['M AA1 L AH0 N K AO0 P F', 'M AA1 L AH0 N K AO0 F'],
  "moller": ['M AA1 L ER0'],
  "mollering": ['M AA1 L ER0 IH0 NG'],
  "mollet": ['M AA1 L IH0 T'],
  "mollett": ['M AA1 L IH0 T'],
  "molley": ['M AA1 L IY0'],
  "mollica": ['M AA1 L IH0 K AH0'],
  "mollie": ['M AA1 L IY0'],
  "mollified": ['M AA1 L AH0 F AY2 D'],
  "mollify": ['M AA1 L AH0 F AY2'],
  "mollison": ['M AA1 L IH0 S AH0 N'],
  "mollner": ['M AA1 L N ER0'],
  "mollo": ['M AA1 L OW0'],
  "mollohan": ['M AA1 L AH0 HH AE0 N'],
  "molloy": ['M AA1 L OY0'],
  "mollusk": ['M AA1 L AH0 S K'],
  "mollusks": ['M AA1 L AH0 S K S'],
  "molly": ['M AA1 L IY0'],
  "molly's": ['M AA1 L IY0 Z'],
  "mollycoddle": ['M AA1 L IY0 K AA2 D AH0 L'],
  "molnar": ['M OW1 L N ER0', 'M OW1 L N AA0 R'],
  "molner": ['M OW1 L N ER0'],
  "molock": ['M AA1 L AH0 K'],
  "molokai": ['M AA1 L AH0 K AY2'],
  "moloney": ['M AH0 L OW1 N IY0'],
  "molonicks": ['M AH0 L AO1 N IH0 K S'],
  "molony": ['M AH0 L AO1 N IY0'],
  "molotov": ['M AO1 L AH0 T AA2 F'],
  "molpus": ['M AO1 L P AH0 S'],
  "molpus'": ['M AO1 L P AH0 S'],
  "molpus's": ['M AO1 L P AH0 S IH0 S'],
  "molson": ['M OW1 L S AH0 N'],
  "molstad": ['M OW1 L S T AH0 D'],
  "molt": ['M OW1 L T'],
  "molten": ['M OW1 L T AH0 N'],
  "molter": ['M OW1 L T ER0'],
  "molting": ['M OW1 L T IH0 NG'],
  "molton": ['M OW1 L T AH0 N'],
  "moltz": ['M OW1 L T S'],
  "molucca": ['M AH0 L UW1 K AH0'],
  "moly": ['M OW1 L IY0'],
  "molybdenum": ['M AH0 L IH1 B D IH0 N AH0 M'],
  "molyneaux": ['M AA1 L IH0 N OW0'],
  "molzahn": ['M OW1 L Z AH0 N'],
  "mom": ['M AA1 M'],
  "mom's": ['M AA1 M Z'],
  "moma": ['M OW1 M AH0'],
  "moma's": ['M OW1 M AH0 Z'],
  "moman": ['M OW1 M AH0 N'],
  "momayez": ['M OW0 M EY1 EH0 Z'],
  "mombasa": ['M AA0 M B AA1 S AH0'],
  "moment": ['M OW1 M AH0 N T'],
  "moment's": ['M OW1 M AH0 N T S'],
  "momentarily": ['M OW2 M AH0 N T EH1 R AH0 L IY0'],
  "momentary": ['M OW1 M AH0 N T EH2 R IY0'],
  "momentous": ['M OW0 M EH1 N T AH0 S'],
  "moments": ['M OW1 M AH0 N T S'],
  "momentum": ['M OW0 M EH1 N T AH0 M'],
  "momigliano": ['M OW0 M IH1 G L IY0 AA1 N OW0'],
  "momma": ['M AA1 M AH0'],
  "mommens": ['M AA1 M AH0 N Z'],
  "mommies": ['M AA1 M IY0 Z'],
  "mommy": ['M AA1 M IY0'],
  "mommy's": ['M AA1 M IY0 Z'],
  "momokawa": ['M OW2 M OW0 K AA1 W AH0'],
  "momokawa's": ['M OW2 M OW0 K AA1 W AH0 Z'],
  "moms": ['M AA1 M Z'],
  "mon": ['M OW1 N', 'M AA1 N'],
  "mona": ['M OW1 N AH0'],
  "mona's": ['M OW1 N AH0 Z'],
  "monacelli": ['M OW0 N AA0 CH EH1 L IY0'],
  "monachino": ['M OW0 N AA0 K IY1 N OW0'],
  "monaco": ['M AA1 N AH0 K OW2'],
  "monaco's": ['M AA1 N AH0 K OW2 Z'],
  "monad": ['M OW1 N AE0 D'],
  "monadnock": ['M AA2 N AE1 D N AA0 K'],
  "monagene": ['M AA1 N AH0 JH IY2 N'],
  "monaghan": ['M AA1 N AH0 HH AE0 N'],
  "monahan": ['M AA1 N AH0 HH AE0 N'],
  "monarch": ['M AA1 N AA2 R K'],
  "monarch's": ['M AA1 N AA2 R K S'],
  "monarchies": ['M AA1 N AA0 R K IY0 Z'],
  "monarchist": ['M AA1 N AA0 R K IH0 S T'],
  "monarchists": ['M AA1 N AA0 R K IH0 S T S'],
  "monarchs": ['M AA1 N AA2 R K S'],
  "monarchy": ['M AA1 N AA0 R K IY0'],
  "monarrez": ['M OW0 N AA1 R EH0 Z'],
  "monash": ['M AA1 N AE2 SH'],
  "monasteries": ['M AA1 N AH0 S T EH2 R IY0 Z'],
  "monastery": ['M AA1 N AH0 S T EH2 R IY0'],
  "monastic": ['M AH0 N AE1 S T IH0 K'],
  "monasticism": ['M AH0 N AE1 S T AH0 S IH2 Z AH0 M'],
  "monatomic": ['M AA2 N AH0 T AA1 M IH0 K'],
  "monca": ['M OW1 N K AH0'],
  "moncada": ['M OW0 N K AA1 D AH0'],
  "moncayo": ['M OW0 N K EY1 OW0'],
  "monceaux": ['M AH0 N S OW1'],
  "moncrief": ['M AA1 N K R IY0 F'],
  "moncur": ['M AA1 N K ER0'],
  "moncure": ['M OW0 N K UH1 R IY0'],
  "moncus": ['M AA1 N K IH0 S'],
  "monda": ['M AA1 N D AH0'],
  "mondadori": ['M AA2 N D AH0 D AO1 R IY0'],
  "mondale": ['M AA1 N D EY2 L'],
  "mondale's": ['M AA1 N D EY2 L Z'],
  "mondallo": ['M AA0 N D AE1 L OW0'],
  "mondavi": ['M AA0 N D AA1 V IY0'],
  "monday": ['M AH1 N D IY0', 'M AH1 N D EY2'],
  "monday's": ['M AH1 N D IY0 Z', 'M AH1 N D EY2 Z'],
  "mondays": ['M AH1 N D IY0 Z', 'M AH1 N D EY2 Z'],
  "monde": ['M AA1 N D'],
  "mondelli": ['M OW0 N D EH1 L IY0'],
  "mondello": ['M AA2 N D EH1 L OW0'],
  "mondeo": ['M AA2 N D EY1 OW0'],
  "mondex": ['M AA1 N D EH0 K S'],
  "mondo": ['M AA1 N D OW0'],
  "mondor": ['M AA1 N D ER0'],
  "mondragon": ['M OW0 N D R AA0 G AO1 N'],
  "mondrian": ['M AA1 N D R IY0 AH0 N', 'M AA1 N D R IY0 AE0 N'],
  "mondry": ['M AA1 N D R IY0'],
  "monds": ['M AA1 N D Z'],
  "mondschein": ['M AA1 N D SH AY2 N'],
  "mondy": ['M AA1 N D IY0'],
  "mone": ['M OW1 N'],
  "monell": ['M AA1 N AH0 L'],
  "mones": ['M OW1 N Z'],
  "monessen": ['M OW1 N AH0 S AH0 N', 'M AH0 N EH1 S AH0 N'],
  "monet": ['M OW0 N EY1'],
  "monet's": ['M OW0 N EY1 Z'],
  "monetarily": ['M AA0 N AH0 T ER1 IH0 L IY0'],
  "monetarism": ['M AA1 N AH0 T ER0 IH2 Z AH0 M'],
  "monetarist": ['M AA1 N AH0 T ER0 IH0 S T'],
  "monetarists": ['M AA1 N AH0 T ER0 IH2 S T S'],
  "monetary": ['M AA1 N AH0 T EH2 R IY0'],
  "monetize": ['M AA1 N AH0 T AY2 Z'],
  "monetizing": ['M AA1 N AH0 T AY2 Z IH0 NG'],
  "monett": ['M AA1 N IH0 T'],
  "monette": ['M AH0 N EH1 T'],
  "money": ['M AH1 N IY0'],
  "money's": ['M AH1 N IY0 Z'],
  "moneycard": ['M AH1 N IY0 K AA2 R D'],
  "moneycenter": ['M AH1 N IY0 S EH2 N T ER0'],
  "moneyed": ['M AH1 N IY0 D'],
  "moneyless": ['M AH1 N IY0 L IH0 S'],
  "moneyline": ['M AH1 N IY0 L AY2 N'],
  "moneyline's": ['M AH1 N IY0 L AY2 N Z'],
  "moneymaker": ['M AH1 N IY0 M EY2 K ER0'],
  "moneymakers": ['M AH1 N IY0 M EY2 K ER0 Z'],
  "moneymaking": ['M AH1 N IY0 M EY2 K IH0 NG'],
  "moneypenny": ['M AH1 N IY0 P EH2 N IY0'],
  "moneys": ['M AH1 N IY0 Z'],
  "moneywatch": ['M AH1 N IY0 W AA2 CH'],
  "moneyweek": ['M AH1 N IY0 W IY2 K'],
  "moneyweek's": ['M AH1 N IY0 W IY2 K S'],
  "monfils": ['M AA1 N F IH0 L Z'],
  "monfort": ['M AA1 N F ER0 T'],
  "monforte": ['M OW0 N F AO1 R T IY0'],
  "mong": ['M AO1 NG'],
  "mongan": ['M AA1 NG G AH0 N'],
  "monge": ['M AA1 N JH'],
  "mongeau": ['M AH0 NG G OW1'],
  "mongelli": ['M OW0 NG G EH1 L IY0'],
  "mongeon": ['M AA1 N JH IH0 N'],
  "monger": ['M AH1 NG G ER0'],
  "mongering": ['M AH1 NG G ER0 IH0 NG'],
  "mongers": ['M AH1 NG G ER0 Z'],
  "mongiello": ['M OW0 N JH EH1 L OW0'],
  "mongillo": ['M OW0 NG G IH1 L OW0'],
  "mongol": ['M AA1 NG G AH0 L'],
  "mongold": ['M AA1 N G OW2 L D'],
  "mongolia": ['M AA0 NG G OW1 L IY0 AH0', 'M AA0 NG G OW1 L Y AH0'],
  "mongolian": ['M AA0 NG G OW1 L IY0 AH0 N', 'M AA0 NG G OW1 L Y AH0 N'],
  "mongoloid": ['M AA1 NG G AH0 L OY2 D'],
  "mongols": ['M AA1 NG G AH0 L Z'],
  "mongoose": ['M AA1 NG G UW0 S'],
  "mongooses": ['M AA1 NG G UW0 S AH0 Z'],
  "mongosutu": ['M AA2 NG G OW0 S UW1 T UW0'],
  "mongosutu's": ['M AA2 NG G OW0 S UW1 T UW0 Z'],
  "mongstad": ['M AO1 NG S T AE2 D'],
  "monhollen": ['M AA1 N HH AH0 L AH0 N'],
  "monica": ['M AA1 N IH0 K AH0'],
  "monica's": ['M AA1 N IH0 K AH0 Z'],
  "monical": ['M AA1 N IH0 K AH0 L'],
  "monico": ['M OW0 N IY1 K OW0'],
  "monied": ['M AH1 N IY0 D'],
  "monier": ['M OW1 N IY0 ER0'],
  "monies": ['M AH1 N IY0 Z'],
  "monieson": ['M OW1 N IY0 S AH0 N'],
  "monieson's": ['M OW1 N IY0 S AH0 N Z'],
  "monigold": ['M AA1 N IH0 G OW2 L D'],
  "moniker": ['M AA1 N IH0 K ER0'],
  "monikers": ['M AA1 N IH0 K ER0 Z'],
  "moninger": ['M OW1 N IH0 NG ER0'],
  "monique": ['M OW2 N IY1 K'],
  "monism": ['M AA1 N IH0 Z AH0 M'],
  "monisms": ['M AA1 N IH0 Z AH0 M Z'],
  "monit": ['M AA1 N AH0 T'],
  "monitor": ['M AA1 N AH0 T ER0'],
  "monitored": ['M AA1 N AH0 T ER0 D'],
  "monitoring": ['M AA1 N AH0 T ER0 IH0 NG'],
  "monitors": ['M AA1 N AH0 T ER0 Z'],
  "moniz": ['M AA1 N IH0 Z'],
  "monje": ['M AA1 N JH'],
  "monk": ['M AH1 NG K'],
  "monk's": ['M AH1 NG K S'],
  "monkee": ['M AA1 NG K IY0'],
  "monkees": ['M AA1 NG K IY0 Z'],
  "monkey": ['M AH1 NG K IY0'],
  "monkey's": ['M AH1 NG K IY0 Z'],
  "monkeying": ['M AH1 NG K IY0 IH0 NG'],
  "monkeylike": ['M AH1 NG K IY0 L AY2 K'],
  "monkeys": ['M AH1 NG K IY0 Z'],
  "monks": ['M AH1 NG K S'],
  "monmouth": ['M AA1 N M AH0 TH'],
  "monmouth's": ['M AA1 N M AH0 TH S'],
  "monn": ['M AA1 N'],
  "monnett": ['M AA1 N IH0 T'],
  "monnier": ['M AA1 N IY0 ER0'],
  "monnig": ['M AA1 N IH0 G'],
  "monnin": ['M AA1 N IH0 N'],
  "mono": ['M OW1 N OW0'],
  "monocarpic": ['M AA2 N AH0 K AA1 R P IH0 K'],
  "monochromatic": ['M AA2 N AH0 K R OW0 M AE1 T IH0 K'],
  "monochrome": ['M AA1 N AH0 K R OW2 M'],
  "monoclate": ['M AA1 N AH0 K L EY2 T'],
  "monocle": ['M AA1 N AH0 K AH0 L'],
  "monoclinic": ['M AA2 N AH0 K L IH1 N IH0 K'],
  "monoclonal": ['M AA2 N AH0 K L OW1 N AH0 L'],
  "monogamous": ['M AH0 N AA1 G AH0 M AH0 S'],
  "monogamy": ['M AH0 N AA1 G AH0 M IY0'],
  "monogram": ['M AA1 N AH0 G R AE2 M'],
  "monogrammed": ['M AA1 N AH0 G R AE2 M D'],
  "monograph": ['M AA1 N AH0 G R AE2 F'],
  "monographs": ['M AA1 N AH0 G R AE2 F S'],
  "monohull": ['M AA1 N AH0 HH AH0 L'],
  "monolingual": ['M AA2 N AH0 L IH1 NG G W AH0 L'],
  "monolith": ['M AA1 N AH0 L IH2 TH'],
  "monolithic": ['M AA2 N AH0 L IH1 TH IH0 K'],
  "monoliths": ['M AA1 N AH0 L IH2 TH S'],
  "monologue": ['M AA1 N AH0 L AO2 G'],
  "monologues": ['M AA1 N AH0 L AO2 G Z'],
  "monomer": ['M AA1 N AH0 M ER0'],
  "monomers": ['M AA1 N AH0 M ER0 Z'],
  "monongahela": ['M AH0 N AO2 NG G AH0 HH EY1 L AH0'],
  "mononokai": ['M AH0 N AA1 N AH0 K AY2'],
  "mononuclear": ['M AA2 N AH0 N UW1 K L IY0 ER0'],
  "monophonic": ['M AA2 N AH0 F AA1 N IH0 K'],
  "monoplane": ['M AA1 N AH0 P L EY2 N'],
  "monoplanes": ['M AA1 N AH0 P L EY2 N Z'],
  "monopole": ['M AA1 N AH0 P OW2 L'],
  "monopoles": ['M AA1 N AH0 P OW2 L Z'],
  "monopolies": ['M AH0 N AA1 P AH0 L IY0 Z'],
  "monopolist": ['M AH0 N AA1 P AH0 L AH0 S T'],
  "monopolistic": ['M AH0 N AA2 P AH0 L IH1 S T IH0 K'],
  "monopolization": ['M AH0 N AA2 P AH0 L IH0 Z EY1 SH AH0 N'],
  "monopolize": ['M AH0 N AA1 P AH0 L AY2 Z'],
  "monopolized": ['M AH0 N AA1 P AH0 L AY2 Z D'],
  "monopolizes": ['M AH0 N AA1 P AH0 L AY2 Z IH0 Z'],
  "monopolizing": ['M AH0 N AA1 P AH0 L AY2 Z IH0 NG'],
  "monopoly": ['M AH0 N AA1 P AH0 L IY0'],
  "monorail": ['M AA1 N ER0 EY2 L'],
  "monorails": ['M AA1 N ER0 EY2 L Z'],
  "monosaccharide": ['M AA2 N AH0 S AE1 K ER0 AY2 D'],
  "monoszon": ['M AA1 N AH0 Z AA2 N'],
  "monotheism": ['M AA1 N AH0 TH IY2 IH0 Z AH0 M'],
  "monotone": ['M AA1 N AH0 T OW2 N'],
  "monotonous": ['M AH0 N AA1 T AH0 N AH0 S'],
  "monotony": ['M AH0 N AA1 T AH0 N IY0'],
  "monotype": ['M AA1 N AH0 T AY2 P'],
  "monovalent": ['M AA2 N AH0 V EY1 L AH0 N T'],
  "monoxide": ['M AH0 N AA1 K S AY0 D'],
  "monreal": ['M AA1 N R AH0 L'],
  "monro": ['M AA1 N R OW0'],
  "monroe": ['M AH0 N R OW1'],
  "monroe's": ['M AH0 N R OW1 Z'],
  "monroeville": ['M AA0 N R OW1 V IH2 L', 'M AH0 N R OW1 V IH2 L'],
  "monrovia": ['M AA2 N R OW1 V IY0 AH0'],
  "monroy": ['M AA1 N R OY2'],
  "monsanto": ['M AA2 N S AE1 N T OW0'],
  "monsanto's": ['M AA0 N S AE1 N T OW0 Z'],
  "monsees": ['M AA1 N S IY2 Z'],
  "monsen": ['M AA1 N S AH0 N'],
  "monsey": ['M AA1 N Z IY0'],
  "monsieur": ['M AH0 S Y ER1'],
  "monsieurs": ['M AH0 S Y ER1 Z'],
  "monsignor": ['M AA0 N S IY1 N Y ER0'],
  "monsignors": ['M AA0 N S IY1 N Y ER0 Z'],
  "monsky": ['M AA1 N S K IY0'],
  "monsod": ['M AA1 N S AA0 D'],
  "monson": ['M AA1 N S AH0 N'],
  "monsoon": ['M AA0 N S UW1 N'],
  "monsoonal": ['M AA0 N S UW1 N AH0 L'],
  "monsoons": ['M AA0 N S UW1 N Z'],
  "monsour": ['M AA1 N S ER0'],
  "monster": ['M AA1 N S T ER0'],
  "monsters": ['M AA1 N S T ER0 Z'],
  "monstrosity": ['M AA0 N S T R AA1 S AH0 T IY0'],
  "monstrous": ['M AA1 N S T R AH0 S'],
  "mont": ['M AA1 N T'],
  "montag": ['M AH0 N T AE1 G'],
  "montage": ['M AA0 N T AA1 ZH'],
  "montagna": ['M OW0 N T AA1 G N AH0'],
  "montagne": ['M AH0 N T EY1 N Y'],
  "montagnier": ['M AA0 N T AE1 N Y ER0'],
  "montagnino": ['M OW0 N T AA0 G N IY1 N OW0'],
  "montagu": ['M AA1 N T AH0 G Y UW2'],
  "montagu's": ['M AA1 N T AH0 G Y UW2 Z'],
  "montague": ['M AA1 N T AH0 G Y UW2'],
  "montalban": ['M AA0 N T AE1 L B AH0 N'],
  "montalbano": ['M OW0 N T AA0 L B AA1 N OW0'],
  "montalbo": ['M AA2 N T AE1 L B OW0'],
  "montalto": ['M OW0 N T AA1 L T OW0'],
  "montalvo": ['M OW0 N T AA1 L V OW0'],
  "montana": ['M AA0 N T AE1 N AH0'],
  "montana's": ['M AA0 N T AE1 N AH0 Z'],
  "montanan": ['M AA0 N T AE1 N AH0 N'],
  "montanans": ['M AA0 N T AE1 N AH0 N Z', 'M AO2 N T AE1 N AH0 N Z'],
  "montanari": ['M OW0 N T AA0 N AA1 R IY0'],
  "montanaro": ['M OW0 N T AA0 N AA1 R OW0'],
  "montand": ['M AA1 N T AH0 N D'],
  "montanez": ['M OW0 N T AA1 N EH0 Z'],
  "montano": ['M AO2 N T AA1 N OW0'],
  "montante": ['M OW0 N T AA1 N T IY0'],
  "montanye": ['M OW0 N T AA1 N Y EY0'],
  "montavon": ['M OW0 N T AA0 V AO1 N'],
  "montazeri": ['M AA2 N T AH0 Z EH1 R IY0'],
  "montblanc": ['M AA0 N T B L AE1 NG K', 'M OW0 N T B L AA1 NG K'],
  "montclair": ['M AA2 N T K L EH1 R'],
  "monte": ['M AA1 N T IY0'],
  "monte's": ['M AA1 N T IY0 Z'],
  "monteagudo": ['M OW0 N T AH0 G UW1 D OW0'],
  "montebello": ['M AA2 N T IH0 B EH1 L OW0'],
  "montecalvo": ['M OW0 N T EH0 K AA1 L V OW0'],
  "montedison": ['M AA0 N T EH1 D IH0 S AH0 N'],
  "montedison's": ['M AA0 N T EH1 D IH0 S AH0 Z'],
  "montee": ['M AA1 N T IY0'],
  "montefiore": ['M AA2 N T AH0 F IY0 AO1 R IY0', 'M AA2 N T AH0 F Y AO1 R'],
  "monteforte": ['M OW0 N T EH0 F AO1 R T IY0'],
  "montefusco": ['M OW0 N T EH0 F UW1 S K OW0'],
  "montego": ['M AO2 N T IY1 G OW0'],
  "monteil": ['M AA0 N T AY1 L'],
  "monteiro": ['M AA0 N T EH1 R OW0'],
  "montejano": ['M AA2 N T EY0 AA1 N OW0'],
  "montel": ['M AA0 N T EH1 L'],
  "montel's": ['M AA0 N T EH1 L Z'],
  "monteleone": ['M AA2 N T EY0 L EY0 OW1 N IY0'],
  "montella": ['M AA2 N T EH1 L AH0'],
  "montello": ['M AA2 N T EH1 L OW0'],
  "montelongo": ['M AA2 N T EH0 L OW1 NG G OW0'],
  "montemarano": ['M AA2 N T EH0 M AA0 R AA1 N OW0'],
  "montemayor": ['M AA2 N T EY0 M EY0 AO1 R'],
  "montemurro": ['M AA2 N T EH0 M UH1 R OW0'],
  "montenay": ['M AA1 N T AH0 N EY2'],
  "montenegran": ['M AA2 N T AH0 N EY1 G R AH0 N'],
  "montenegro": ['M AA2 N T AH0 N EY1 G R OW0'],
  "montenegro's": ['M AA2 N T AH0 N EY1 G R OW0 Z'],
  "monter": ['M AA1 N T ER0'],
  "monterey": ['M AA2 N T ER0 EY1', 'M AA2 N ER0 EY1'],
  "montero": ['M AA0 N T EH1 R OW0'],
  "monterosso": ['M OW0 N T ER0 OW1 S OW0'],
  "monterrey": ['M AA2 N T ER0 EY1', 'M AA2 N ER0 EY1', 'M AA2 N T ER0 R EY1', 'M AA2 N ER0 R EY1'],
  "montes": ['M AA1 N T IY0 Z'],
  "montesano": ['M OW0 N T EH0 S AA1 N OW0'],
  "montesdeoca": ['M OW0 N T EH0 S D IY0 AA1 K AA0'],
  "montesi": ['M OW0 N T EH1 S IY0'],
  "montesino": ['M OW0 N T EH0 S IY1 N OW0'],
  "montessori": ['M AA2 N T AH0 S AO1 R IY0'],
  "monteverde": ['M OW0 N T EH0 V EH1 R D IY0'],
  "montevideo": ['M AA2 N T AH0 V IH1 D IY0 OW2', 'M AA2 N T AH0 V IH0 D EY1 OW0'],
  "montez": ['M OW0 N T EH1 Z'],
  "montezuma": ['M AA2 N T IH0 Z UW1 M AH0'],
  "montford": ['M AH0 N T F AO1 R D'],
  "montfort": ['M AA1 N T F ER0 T'],
  "montgomery": ['M AA0 N T G AH1 M R IY0'],
  "montgomery's": ['M AA0 N T G AH1 M R IY0 Z'],
  "montgoris": ['M AA0 N T G AO1 R IH0 S'],
  "month": ['M AH1 N TH'],
  "month's": ['M AH1 N TH S'],
  "monthlong": ['M AH1 N TH L AO2 NG'],
  "monthly": ['M AH1 N TH L IY0'],
  "months": ['M AH1 N TH S'],
  "months'": ['M AA1 N TH S'],
  "monti": ['M AA1 N T IY0'],
  "monticciolo": ['M AA0 N T IY2 CH IY0 OW1 L OW0'],
  "monticello": ['M AA2 N T AH0 S EH1 L OW0', 'M AA2 N T IH0 S EH1 L OW0'],
  "montie": ['M AA1 N T IY0'],
  "montiel": ['M OW0 N T IY0 EH1 L'],
  "montien": ['M AA2 N T IY1 EH0 N'],
  "montier": ['M AA1 N T Y ER0'],
  "montieth": ['M AA1 N T IY0 IH0 TH'],
  "montijo": ['M OW0 N T IY1 Y OW0'],
  "montini": ['M OW0 N T IY1 N IY0'],
  "montjoy": ['M AA1 N T JH OY2'],
  "montmartre": ['M AA2 N M AE1 R T R AH0'],
  "montminy": ['M AA1 N T M IH0 N IY0'],
  "montmorency": ['M AA2 N M AO0 R EH1 N S IY2'],
  "montone": ['M OW0 N T OW1 N IY0'],
  "montooth": ['M AA1 N T UW2 TH'],
  "montoya": ['M AA0 N T OY1 AH0'],
  "montpelier": ['M AA0 N T P IY1 L IY0 ER0'],
  "montpelier's": ['M AA0 N T P IY1 L IY0 ER0 Z'],
  "montreal": ['M AH2 N T R IY0 AO1 L'],
  "montreal's": ['M AA2 N T R IY0 AA1 L Z'],
  "montrealer": ['M AH2 N T R IY0 AO1 L ER0'],
  "montrealer's": ['M AH2 N T R IY0 AO1 L ER0 Z'],
  "montrealers": ['M AH2 N T R IY0 AO1 L ER0 Z'],
  "montrone": ['M AA0 N T R OW1 N'],
  "montrose": ['M AA1 N T R OW2 Z'],
  "montross": ['M OW1 N T R OW0 S'],
  "montrouis": ['M AA0 N T R UW1 IY0'],
  "montroy": ['M AA1 N T R OY2'],
  "monts": ['M AA1 N T S'],
  "montserrat": ['M AA0 N S ER0 AA1 T'],
  "montuori": ['M OW0 N T W AO1 R IY0'],
  "montvale": ['M AA1 N T V EY2 L'],
  "montville": ['M OW1 N T V IH0 L'],
  "monty": ['M AO1 N T IY0'],
  "montz": ['M AA1 N T S'],
  "monument": ['M AA1 N Y UW0 M AH0 N T', 'M AA1 N Y AH0 M AH0 N T'],
  "monumental": ['M AA2 N Y AH0 M EH1 N T AH0 L', 'M AA2 N Y AH0 M EH1 N AH0 L', 'M AA2 N Y UW0 M EH1 N T AH0 L'],
  "monumentally": ['M AA2 N Y AH0 M EH1 N T AH0 L IY0', 'M AA2 N Y UW0 M EH1 N T AH0 L IY0', 'M AA2 N Y AH0 M EH1 N AH0 L IY0', 'M AA2 N Y UW0 M EH1 N AH0 L IY0'],
  "monuments": ['M AA1 N Y AH0 M AH0 N T S', 'M AA1 N Y UW0 M AH0 N T S'],
  "monus": ['M OW1 N AH0 S'],
  "mony": ['M OW1 N IY0'],
  "monzert": ['M AA1 N Z ER0 T'],
  "monzingo": ['M OW0 N Z IY1 NG G OW0'],
  "monzo": ['M AA1 N Z OW0'],
  "monzon": ['M OW0 N Z AO1 N'],
  "monzonite": ['M AA1 N Z AH0 N AY2 T'],
  "moo": ['M UW1'],
  "mooberry": ['M UW1 B EH2 R IY0'],
  "mood": ['M UW1 D'],
  "mood's": ['M UW1 D Z'],
  "moodie": ['M UW1 D IY0'],
  "moods": ['M UW1 D Z'],
  "moody": ['M UW1 D IY0'],
  "moody's": ['M UW1 D IY0 Z'],
  "mooers": ['M UW1 ER0 Z'],
  "moog": ['M UW1 G'],
  "mook": ['M UH1 K'],
  "moomaw": ['M UW1 M AO2'],
  "moomey": ['M UW1 M IY0'],
  "moon": ['M UW1 N'],
  "moon's": ['M UW1 N Z'],
  "moonbeam": ['M UW1 N B IY2 M'],
  "moonbeams": ['M UW1 N B IY2 M Z'],
  "moondreamer": ['M UW1 N D R IY2 M ER0'],
  "moondreamers": ['M UW1 N D R IY2 M ER0 Z'],
  "moone": ['M UW1 N'],
  "mooney": ['M UW1 N IY0'],
  "mooneyham": ['M UW1 N IY0 HH AH0 M'],
  "mooneyhan": ['M UW1 N IY0 HH AH0 N'],
  "moonie": ['M UW1 N IY0'],
  "moonies": ['M UW1 N IY0 Z'],
  "moonlight": ['M UW1 N L AY2 T'],
  "moonlighting": ['M UW1 N L AY2 T IH0 NG'],
  "moonlike": ['M UW1 N L AY2 K'],
  "moonlit": ['M UW1 N L IH2 T'],
  "moons": ['M UW1 N Z'],
  "moonshine": ['M UW1 N SH AY2 N'],
  "moonstone": ['M UW1 N S T OW2 N'],
  "moonstones": ['M UW1 N S T OW2 N Z'],
  "moonstruck": ['M UW1 N S T R AH2 K'],
  "moonves": ['M UW1 N V EH0 Z'],
  "moor": ['M UH1 R'],
  "mooradian": ['M UH0 R EY1 D IY0 AH0 N'],
  "moorco": ['M UH1 R K OW0', 'M AO1 R K OW0'],
  "moore": ['M UH1 R', 'M AO1 R'],
  "moore's": ['M UH1 R Z', 'M AO1 R Z'],
  "moored": ['M UH1 R D'],
  "moorefield": ['M UH1 R IH0 F IY0 L D', 'M UH1 R F IY0 L D'],
  "moorehead": ['M UH1 R HH EH2 D'],
  "moorehouse": ['M UH1 R HH AW2 S'],
  "moorer": ['M UH1 R ER0'],
  "moores": ['M UH1 R Z'],
  "moorestown": ['M AO1 R Z T AW2 N'],
  "moorhead": ['M UH1 R HH EH2 D'],
  "moorhouse": ['M UH1 R HH AW2 S'],
  "mooring": ['M UH1 R IH0 NG'],
  "moorings": ['M UW1 R IH0 NG Z', 'M AO1 R IH0 NG Z'],
  "moorish": ['M UH1 R IH0 SH'],
  "moorland": ['M UH1 R L AE2 N D'],
  "moorland's": ['M UH1 R L AE2 N D Z'],
  "moorlands": ['M UH1 R L AE2 N D Z'],
  "moorman": ['M UH1 R M AH0 N'],
  "moormann": ['M UH1 R M AH0 N'],
  "moors": ['M UH1 R Z'],
  "moos": ['M UW1 Z'],
  "moosa": ['M UW1 S AH0'],
  "moose": ['M UW1 S'],
  "moose-birds": ['M UW1 S B ER2 D Z'],
  "moosehead": ['M UW1 S HH EH2 D'],
  "moosman": ['M UW1 S M AH0 N'],
  "moot": ['M UW1 T'],
  "moothart": ['M UW1 TH AA0 R T'],
  "moots": ['M UW1 T S'],
  "mooty": ['M UW1 T IY0'],
  "mootz": ['M UW1 T S'],
  "mop": ['M AA1 P'],
  "mopboard": ['M AA1 P B AO2 R D'],
  "mope": ['M OW1 P'],
  "moped": ['M OW1 P T', 'M OW1 P EH0 D'],
  "mopeds": ['M OW1 P EH0 D Z'],
  "moping": ['M OW1 P IH0 NG'],
  "mopped": ['M AA1 P T'],
  "moppes": ['M AA1 P S'],
  "mopping": ['M AA1 P IH0 NG'],
  "mops": ['M AA1 P S'],
  "mopus": ['M OW1 P AH0 S'],
  "moquin": ['M OW0 K W IY1 N'],
  "mor": ['M AO1 R'],
  "mor's": ['M AO1 R Z'],
  "mora": ['M AO1 R AH0'],
  "morabito": ['M AO0 R AA0 B IY1 T OW0'],
  "morace": ['M AO0 R AA1 CH IY0'],
  "morachov": ['M AO1 R AH0 CH AO2 V'],
  "morad": ['M AO1 R AH0 D'],
  "morado": ['M AO0 R AA1 D OW0'],
  "moraga": ['M AO0 R AA1 G AH0'],
  "moragne": ['M ER0 EY1 N Y'],
  "morahan": ['M AO1 R AH0 HH AE0 N'],
  "morain": ['M ER0 EY1 N'],
  "morainal": ['M ER0 EY1 N AH0 L'],
  "moraine": ['M ER0 EY1 N'],
  "morais": ['M ER0 EY1'],
  "moraitis": ['M AO0 R AY1 T IH0 S'],
  "moral": ['M AO1 R AH0 L'],
  "morale": ['M ER0 AE1 L'],
  "morales": ['M ER0 AE1 L Z', 'M AO0 R AE1 L EH0 S'],
  "moralez": ['M AO0 R AA1 L EH0 Z'],
  "moralism": ['M AO1 R AH0 L IH2 Z AH0 M'],
  "moralist": ['M AO1 R AH0 L IH0 S T'],
  "moralistic": ['M AO2 R AH0 L IH1 S T IH0 K'],
  "moralists": ['M AO1 R AH0 L IH2 S T S'],
  "morality": ['M ER0 AE1 L AH0 T IY0'],
  "moralize": ['M AO1 R AH0 L AY2 Z'],
  "moralizing": ['M AO1 R AH0 L AY2 Z IH0 NG'],
  "morally": ['M AO1 R AH0 L IY0'],
  "morals": ['M AO1 R AH0 L Z'],
  "moran": ['M ER0 AE1 N'],
  "morand": ['M AO1 R AH0 N D'],
  "morandan": ['M AH0 R AE1 N D AH0 N'],
  "morandi": ['M AO0 R AA1 N D IY0'],
  "morando": ['M AO0 R AA1 N D OW0'],
  "morang": ['M AO1 R AH0 NG'],
  "morano": ['M AO0 R AA1 N OW0'],
  "morant": ['M AO1 R AH0 N T'],
  "morante": ['M AO0 R AA1 N T IY0'],
  "morash": ['M AO1 R AH0 SH'],
  "moraski": ['M ER0 AA1 S K IY0'],
  "morass": ['M ER0 AE1 S', 'M AO1 R AE0 S'],
  "morasses": ['M ER0 AE1 S IH0 Z', 'M AO0 R AE1 S IH0 Z'],
  "morath": ['M AO1 R AH0 TH'],
  "moratoria": ['M AO2 R AH0 T AO1 R IY0 AH0'],
  "moratorium": ['M AO2 R AH0 T AO1 R IY0 AH0 M'],
  "moratoriums": ['M AO2 R AH0 T AO1 R IY0 AH0 M Z'],
  "moravec": ['M ER0 AA1 V IH0 K'],
  "moravek": ['M AO1 R AH0 V IH0 K'],
  "moravian": ['M ER0 EY1 V IY0 AH0 N'],
  "morawski": ['M ER0 AA1 F S K IY0'],
  "moray": ['M ER0 EY1', 'M AO1 R EY0'],
  "morays": ['M ER0 EY1 Z', 'M AO1 R EY0 Z'],
  "morbid": ['M AO1 R B AH0 D'],
  "morbidity": ['M AO0 R B IH1 D AH0 T IY0'],
  "morbidly": ['M AO1 R B AH0 D L IY0'],
  "morby": ['M AO1 R B IY0'],
  "morcom": ['M AO1 R K AH0 M'],
  "mord": ['M AO1 R D'],
  "mordant": ['M AO1 R D AH0 N T'],
  "mordecai": ['M AO1 R D AH0 K AY2'],
  "mordechai": ['M AO1 R D AH0 K AY2'],
  "morden": ['M AO1 R D AH0 N'],
  "mordor": ['M AO1 R D AO2 R'],
  "more": ['M AO1 R'],
  "morea": ['M AO1 R IY0 AH0'],
  "moreau": ['M ER0 OW1'],
  "moredock": ['M AO1 R IH0 D AA0 K'],
  "moree": ['M ER0 IY1'],
  "moreen": ['M AO0 R IY1 N'],
  "morefield": ['M AO1 R IH0 F IY2 L D', 'M AO1 R F IY2 L D'],
  "morehart": ['M AO1 R IH0 HH AA0 R T', 'M AO1 R HH AA0 R T'],
  "morehead": ['M AO1 R HH EH0 D'],
  "morehouse": ['M AO1 R HH AW2 S'],
  "moreira": ['M AO0 R EH1 R AH0'],
  "morejon": ['M AO1 R IH0 JH AA0 N'],
  "morel": ['M ER0 EH1 L'],
  "moreland": ['M AO1 R L AH0 N D'],
  "morell": ['M AO1 R AH0 L'],
  "morella": ['M AO0 R EH1 L AH0'],
  "morelli": ['M AO0 R EH1 L IY0'],
  "morello": ['M ER0 EH1 L OW0'],
  "morelock": ['M AO1 R IH0 L AA0 K', 'M AO1 R L AA0 K'],
  "moreman": ['M AO1 R M AH0 N'],
  "moren": ['M AO1 R AH0 N'],
  "morena": ['M AO0 R EY1 N AH0'],
  "morency": ['M AO0 R EH1 N S IY0'],
  "moreno": ['M AO0 R IY1 N OW0', 'M AO0 R EY1 N OW0'],
  "moreover": ['M AO0 R OW1 V ER0'],
  "morera": ['M AO0 R EH1 R AH0'],
  "mores": ['M AO1 R EY2 Z', 'M AO1 R IY2 Z'],
  "moresco": ['M AO0 R EH1 S K OW0'],
  "moreso": ['M AO0 R EH1 S OW0'],
  "moret": ['M AO1 R IH0 T'],
  "moreton": ['M AO1 R IH0 T AA0 N'],
  "moretti": ['M AO0 R EH1 T IY0'],
  "moretto": ['M AO0 R EH1 T OW0'],
  "moretz": ['M AO1 R IH0 T S'],
  "morever": ['M AO2 R EH1 V ER0'],
  "morey": ['M AO1 R IY0'],
  "morfin": ['M AO1 R F IH0 N'],
  "morford": ['M AO1 R F ER0 D'],
  "morga": ['M AO1 R G AH0'],
  "morgado": ['M AO0 R G AA1 D OW0'],
  "morgan": ['M AO1 R G AH0 N'],
  "morgan's": ['M AO1 R G AH0 N Z'],
  "morgana": ['M AO0 R G AE1 N AH0'],
  "morgans": ['M AO1 R G AH0 N Z'],
  "morganstern": ['M AO1 R G AH0 N S T ER0 N'],
  "morgante": ['M AO0 R G AA1 N T IY0'],
  "morganti": ['M AO0 R G AA1 N T IY0'],
  "morgantown": ['M AO1 R G AH0 N T AW2 N'],
  "morgart": ['M AO1 R G AA0 R T'],
  "morgen": ['M AO1 R G AH0 N'],
  "morgenroth": ['M AO1 R G IH0 N R AO0 TH'],
  "morgenstern": ['M AO1 R G IH0 N S T ER0 N'],
  "morgenthaler": ['M AO1 R G IH0 N TH AH0 L ER0'],
  "morgenthau": ['M AO1 R G AH0 N TH AW2'],
  "morgue": ['M AO1 R G'],
  "morgues": ['M AO1 R G Z'],
  "morgun": ['M AO1 R G AH0 N'],
  "mori": ['M AO1 R IY0'],
  "moria": ['M AO1 R IY0 AH0'],
  "morial": ['M AO1 R IY0 AH0 L'],
  "moriarity": ['M AO2 R IY0 AA1 R AH0 T IY0'],
  "moriarty": ['M AO2 R IY0 AA1 R T IY0'],
  "moribund": ['M AO1 R AH0 B AH0 N D'],
  "moric": ['M AO1 R IH0 K'],
  "morice": ['M AO1 R IH0 S'],
  "morici": ['M AO0 R IY1 CH IY0'],
  "morihiro": ['M AO0 R IY0 HH IH1 R OW0'],
  "morihiro's": ['M AO0 R IY0 HH IH1 R OW0 Z'],
  "morikawa": ['M AO0 R IY0 K AA1 W AH0'],
  "morillo": ['M AO0 R IH1 L OW0'],
  "morillom": ['M AO1 R IH0 L AO0 M'],
  "morillono": ['M AO0 R IH1 L AH0 N OW0'],
  "morimoto": ['M AO0 R IY0 M OW1 T OW0'],
  "morin": ['M AO1 R IH0 N'],
  "morine": ['M AO0 R IY1 N IY0'],
  "moring": ['M AO1 R IH0 NG'],
  "morini": ['M AO0 R IY1 N IY0'],
  "morino": ['M AO0 R IY1 N OW0'],
  "morioka": ['M AO0 R IY0 OW1 K AH0'],
  "moris": ['M AO1 R IH0 S'],
  "morisette": ['M AO1 R IH0 S EH0 T'],
  "morishita": ['M AO0 R IY0 SH IY1 T AH0'],
  "morison": ['M AO1 R IH0 S AH0 N'],
  "morissette": ['M AO1 R IH0 S EH0 T'],
  "morita": ['M AO0 R IY1 T AH0'],
  "moritz": ['M AO0 R IH1 T S'],
  "moriya": ['M AO0 R IY1 AH0'],
  "morjera": ['M AO0 R JH EH1 R AH0'],
  "mork": ['M AO1 R K'],
  "morken": ['M AO1 R K AH0 N'],
  "morlan": ['M AO1 R L AH0 N'],
  "morland": ['M AO1 R L AH0 N D'],
  "morley": ['M AO1 R L IY0'],
  "morlock": ['M AO1 R L AH0 K'],
  "morman": ['M AO1 R M AH0 N'],
  "mormile": ['M AO1 R M AY0 L'],
  "mormino": ['M AO0 R M IY1 N OW0'],
  "mormon": ['M AO1 R M AH0 N'],
  "mormonism": ['M AO1 R M AH0 N IH0 Z M', 'M AO1 R M AH0 N IH0 Z AH0 M'],
  "mormons": ['M AO1 R M AH0 N Z'],
  "morn": ['M AO1 R N'],
  "morna": ['M AO1 R N AH0'],
  "morneau": ['M ER0 N OW1'],
  "morneault": ['M ER0 N OW1'],
  "morning": ['M AO1 R N IH0 NG'],
  "morning's": ['M AO1 R N IH0 NG Z'],
  "mornings": ['M AO1 R N IH0 NG Z'],
  "morningstar": ['M AO1 R N IH0 NG S T AA2 R'],
  "morningstar's": ['M AO1 R N IH0 NG S T AA2 R Z'],
  "moro": ['M AO1 R OW2'],
  "moroccan": ['M ER0 AA1 K AH0 N'],
  "moroccans": ['M ER0 AA1 K AH0 N Z'],
  "morocco": ['M ER0 AA1 K OW0'],
  "moron": ['M AO1 R AA2 N'],
  "moron's": ['M AO1 R AA2 N Z'],
  "morones": ['M AO0 R OW1 N EH0 S'],
  "moroney": ['M ER0 OW1 N IY0'],
  "moroni": ['M ER0 OW1 N IY0'],
  "morons": ['M AO1 R AA2 N Z'],
  "moros": ['M AO1 R OW0 Z'],
  "morose": ['M ER0 OW1 S'],
  "morosky": ['M AO0 R AO1 S K IY0'],
  "morovcic": ['M AO0 R AA1 V CH IH0 K'],
  "morovcic's": ['M AO0 R AA1 V CH IH0 K S'],
  "morowick": ['M AO1 R AH0 W IH2 K'],
  "moroz": ['M AO1 R OW0 Z'],
  "morph": ['M AO1 R F'],
  "morphed": ['M AO1 R F T'],
  "morpheus": ['M AO1 R F IY2 AH0 S'],
  "morphew": ['M AO1 R F Y UW0'],
  "morphin": ['M AO1 R F AH0 N'],
  "morphine": ['M AO1 R F IY0 N'],
  "morphing": ['M AO1 R F IH0 NG'],
  "morphis": ['M AO1 R F IH0 S'],
  "morphogenesis": ['M AO2 R F AH0 JH EH1 N AH0 S AH0 S'],
  "morphological": ['M AO2 R F AH0 L AA1 JH IH0 K AH0 L'],
  "morphology": ['M AO0 R F AA1 L AH0 JH IY0'],
  "morphonios": ['M AO0 R F AO1 N IY0 AH0 S', 'M AO0 R F AO1 N IY0 OW0 S'],
  "morphs": ['M AO1 R F S'],
  "morr": ['M AO1 R'],
  "morra": ['M AO1 R AH0'],
  "morrall": ['M AO0 R AA1 L'],
  "morreale": ['M AO0 R IY1 L IY0'],
  "morrell": ['M AO0 R EH1 L'],
  "morren": ['M AO1 R AH0 N'],
  "morrical": ['M AO1 R IH0 K AH0 L'],
  "morricone": ['M AO2 R AH0 K OW1 N', 'M AO2 R AH0 K OW1 N IY0'],
  "morricone's": ['M AO2 R AH0 K OW1 N Z', 'M AO2 R AH0 K OW1 N IY0 Z'],
  "morrie": ['M AO1 R IY0'],
  "morrill": ['M AO0 R IY1 L'],
  "morrin": ['M AO1 R IH0 N'],
  "morris": ['M AO1 R AH0 S', 'M AO1 R IH0 S'],
  "morris'": ['M AO1 R AH0 S'],
  "morris's": ['M AO1 R IH0 S IH0 Z'],
  "morrisett": ['M AO1 R AH0 S EH2 T'],
  "morrisette": ['M AO1 R IH0 S EH0 T'],
  "morrisey": ['M AO1 R IH0 S IY0'],
  "morrish": ['M AO1 R IH0 SH'],
  "morrison": ['M AO1 R IH0 S AH0 N'],
  "morrison's": ['M AO1 R IH0 S AH0 N Z'],
  "morriss": ['M AO1 R IH0 S'],
  "morrissette": ['M AO1 R IH0 S EH2 T'],
  "morrissey": ['M AO1 R IH0 S IY0'],
  "morristown": ['M AO1 R AH0 S T AW2 N'],
  "morrisville": ['M AO1 R AH0 S V IH2 L'],
  "morrisville's": ['M AO1 R AH0 S V IH2 L Z'],
  "morro": ['M AO1 R OW0'],
  "morrone": ['M AO0 R OW1 N IY0'],
  "morrow": ['M AA1 R OW0', 'M AO1 R OW0'],
  "morry": ['M AO1 R IY0'],
  "morsch": ['M AO1 R SH'],
  "morse": ['M AO1 R S'],
  "morsel": ['M AO1 R S AH0 L'],
  "morsels": ['M AO1 R S AH0 L Z'],
  "morson": ['M AO1 R S AH0 N'],
  "morss": ['M AO1 R S'],
  "mort": ['M AO1 R T'],
  "mortal": ['M AO1 R T AH0 L'],
  "mortality": ['M AO0 R T AE1 L AH0 T IY0'],
  "mortally": ['M AO1 R T AH0 L IY0'],
  "mortals": ['M AO1 R T AH0 L Z'],
  "mortar": ['M AO1 R T ER0'],
  "mortara": ['M AO0 R T AA1 R AH0'],
  "mortars": ['M AO1 R T ER0 Z'],
  "morteki": ['M AO0 R T EH1 K IY0'],
  "mortell": ['M AO0 R T EY1 L'],
  "mortellaro": ['M AO0 R T EH0 L AA1 R OW0'],
  "mortem": ['M AO1 R T AH0 M'],
  "mortems": ['M AO1 R T AH0 M Z'],
  "morten": ['M AO1 R T AH0 N'],
  "mortensen": ['M AO1 R T IH0 N S AH0 N'],
  "mortenson": ['M AO1 R T IH0 N S AH0 N'],
  "morter": ['M AO1 R T ER0'],
  "mortgage": ['M AO1 R G AH0 JH', 'M AO1 R G IH0 JH'],
  "mortgage's": ['M AO1 R G IH0 JH IH0 Z'],
  "mortgaged": ['M AO1 R G IH0 JH D'],
  "mortgagepower": ['M AO1 R G IH0 JH P AW2 R'],
  "mortgages": ['M AO1 R G AH0 JH AH0 Z', 'M AO1 R G IH0 JH IH0 Z'],
  "mortgaging": ['M AO1 R G IH0 JH IH0 NG'],
  "mortician": ['M AO0 R T IH1 SH AH0 N'],
  "mortie": ['M AO1 R T IY0'],
  "mortier": ['M AO1 R T IY0 ER0'],
  "mortification": ['M AO1 R T AH0 F K EY2 SH AH0 N'],
  "mortified": ['M AO1 R T AH0 F AY2 D'],
  "mortify": ['M AO1 R T IH0 F AY2'],
  "mortimer": ['M AO1 R T IH0 M ER0'],
  "mortimore": ['M AO0 R T IY1 M AO0 R'],
  "mortis": ['M AO1 R T IH0 S'],
  "mortland": ['M AO1 R T L AH0 N D'],
  "mortner": ['M AO1 R T N ER0'],
  "morton": ['M AO1 R T AH0 N'],
  "morton's": ['M AO1 R T AH0 N Z'],
  "mortuary": ['M AO1 R CH UW0 EH2 R IY0'],
  "morty": ['M AO1 R T IY0'],
  "moruhiro": ['M AO2 R UW0 HH IH1 R OW0'],
  "moruhiro's": ['M AO2 R UW0 HH IH1 R OW0 Z'],
  "morvant": ['M AO1 R V AH0 N T'],
  "morven": ['M AO1 R V AH0 N'],
  "morvillo": ['M AO0 R V IH1 L OW0'],
  "mory": ['M AO1 R IY0'],
  "morze": ['M AO1 R Z'],
  "mos": ['M AO1 S'],
  "mosaic": ['M OW0 Z EY1 IH0 K'],
  "mosaical": ['M OW0 Z EY1 IH0 K AH0 L'],
  "mosaicked": ['M OW0 Z EY1 IH0 K T'],
  "mosaics": ['M OW0 Z EY1 IH0 K S'],
  "mosbacher": ['M AA1 S B AA0 K ER0'],
  "mosbrucker": ['M AA1 S B R AH0 K ER0'],
  "mosby": ['M AO1 S B IY0'],
  "mosca": ['M OW1 S K AH0'],
  "moscato": ['M OW0 S K AA1 T OW0'],
  "moschella": ['M OW0 S K EH1 L AH0'],
  "moschetti": ['M OW0 S K EH1 T IY0'],
  "mosco": ['M OW1 S K OW0'],
  "moscom": ['M AO1 S K AH0 M'],
  "moscoso": ['M OW0 S K OW1 S OW0'],
  "moscow": ['M AA1 S K OW2', 'M AO1 S K AW2'],
  "moscow's": ['M AO1 S K OW2 Z', 'M AA1 S K AW2 Z'],
  "moscowane": ['M AA1 S K OW0 W EY0 N'],
  "moscowitz": ['M AA1 S K AH0 W IH0 T S'],
  "mose": ['M OW1 Z'],
  "mosel": ['M OW1 S AH0 L'],
  "moseley": ['M OW1 Z L IY2'],
  "moseley-braun": ['M OW1 Z L IY0 B R AA1 N'],
  "moselle": ['M OW0 Z EH1 L'],
  "mosely": ['M OW1 Z L IY0'],
  "moseman": ['M OW1 S M AH0 N'],
  "moser": ['M OW1 Z ER0'],
  "moses": ['M OW1 Z AH0 S', 'M OW1 Z IH0 S'],
  "mosey": ['M OW1 Z IY0'],
  "mosh": ['M AO1 SH'],
  "moshe": ['M OW0 SH EH1', 'M OW1 SH AH0'],
  "moshe's": ['M OW0 SH EH1 Z', 'M OW1 SH AH0 Z'],
  "mosher": ['M OW1 ZH ER0'],
  "moshier": ['M AA1 SH IY0 ER0'],
  "moshood": ['M AA1 S HH UH2 D'],
  "moshus": ['M OW1 SH AH0 S', 'M UW1 SH AH0 S'],
  "mosie": ['M AA1 S IY0'],
  "mosier": ['M OW1 ZH ER0', 'M OW1 Z IY0 ER0'],
  "mosiman": ['M OW1 S IY0 M AH0 N'],
  "mosinee": ['M AH0 S IY1 N IY0'],
  "mosk": ['M AO1 S K'],
  "moskal": ['M AA1 S K AH0 L'],
  "moskatel's": ['M AO2 S K AH0 T EH1 L Z'],
  "mosko": ['M OW1 S K OW0'],
  "moskolenko": ['M AO2 S K OW0 L EH1 NG K OW0'],
  "moskovitz": ['M AA1 S K AH0 V IH0 T S'],
  "moskow": ['M AA1 S K OW0'],
  "moskowitz": ['M AO1 S K AH0 W IH0 T S'],
  "moskwa": ['M AA1 S K V AH0'],
  "mosle": ['M OW1 S AH0 L'],
  "moslem": ['M AA1 Z L AH0 M'],
  "moslems": ['M AA1 Z L AH0 M Z'],
  "mosler": ['M AA1 S AH0 L ER0', 'M AA1 S L ER0'],
  "mosley": ['M OW1 Z L IY0'],
  "mosman": ['M AA1 S M AH0 N'],
  "mosque": ['M AA1 S K', 'M AO1 S K'],
  "mosqueda": ['M OW0 S K W EY1 D AH0'],
  "mosquera": ['M OW0 S K W EH1 R AH0'],
  "mosques": ['M AA1 S K S', 'M AO1 S K S'],
  "mosquito": ['M AH0 S K IY1 T OW0'],
  "mosquitoes": ['M AH0 S K IY1 T OW0 Z'],
  "mosquitos": ['M AH0 S K IY1 T OW0 Z'],
  "moss": ['M AO1 S'],
  "mossad": ['M OW0 S AE1 D', 'M OW0 S AA1 D'],
  "mossbacher": ['M AA1 S B AA0 K ER0'],
  "mossbarger": ['M AA1 S B AA0 R G ER0'],
  "mossberg": ['M AO1 S B ER0 G'],
  "mossburg": ['M AO1 S B ER0 G'],
  "mosser": ['M AO1 S ER0'],
  "mosses": ['M AO1 S AH0 Z', 'M AO1 S IH0 Z'],
  "mossey": ['M AA1 S IY0'],
  "mossholder": ['M AO1 S HH OW2 L D ER0'],
  "mosslike": ['M AO1 S L AY2 K'],
  "mossman": ['M AO1 S M AH0 N'],
  "mosso": ['M OW1 S OW0'],
  "mossy": ['M AO1 S IY0'],
  "most": ['M OW1 S T', 'M OW1 S'],
  "mostaert": ['M AH0 S T EY1 R T'],
  "mostar": ['M OW1 S T AA0 R'],
  "mostar's": ['M OW1 S T AA0 R Z'],
  "mostek": ['M AA1 S T IH0 K'],
  "mosteller": ['M AA1 S T AH0 L ER0'],
  "moster": ['M OW1 S T ER0'],
  "mostly": ['M OW1 S T L IY0', 'M OW1 S L IY0'],
  "mostoller": ['M AA1 S T OW0 L ER0'],
  "mostow": ['M AA1 S T AW0'],
  "mostrom": ['M AA1 S T R AH0 M'],
  "mostyn": ['M AA1 S T IH0 N'],
  "mosul": ['M OW1 S AH0 L'],
  "mosur": ['M OW2 S UH1 R'],
  "moszkowski": ['M AA2 S K AW1 S K IY0'],
  "mota": ['M OW1 T AH0'],
  "mote": ['M OW1 T'],
  "motel": ['M OW0 T EH1 L'],
  "motels": ['M OW0 T EH1 L Z'],
  "moten": ['M OW1 T AH0 N'],
  "motes": ['M OW1 T S'],
  "moteurs": ['M OW0 T UW1 R Z'],
  "moth": ['M AO1 TH'],
  "mothball": ['M AO1 TH B AO2 L'],
  "mothballed": ['M AO1 TH B AO2 L D'],
  "mothballing": ['M AO1 TH B AO1 L IH0 NG'],
  "mothballs": ['M AO1 TH B AO2 L Z'],
  "mother": ['M AH1 DH ER0'],
  "mother's": ['M AH1 DH ER0 Z'],
  "mother-in-law": ['M AH1 DH ER0 IH0 N L AO2'],
  "motherboard": ['M AH1 DH ER0 B AO2 R D'],
  "mothercare": ['M AH1 DH ER0 K EH2 R'],
  "motherfucker": ['M AH1 DH ER0 F AH2 K ER0'],
  "motherfucker's": ['M AH1 DH ER0 F AH2 K ER0 Z'],
  "motherfuckers": ['M AH1 DH ER0 F AH2 K ER0 Z'],
  "motherfucking": ['M AH1 DH ER0 F AH2 K IH0 NG'],
  "motherhood": ['M AH1 DH ER0 HH UH2 D'],
  "mothering": ['M AH1 DH ER0 IH0 NG'],
  "motherland": ['M AH1 DH ER0 L AE2 N D'],
  "motherless": ['M AH1 DH ER0 L IH0 S'],
  "motherly": ['M AH1 DH ER0 L IY0'],
  "mothers": ['M AH1 DH ER0 Z'],
  "mothers'": ['M AH1 TH ER0 Z'],
  "mothers-in-law": ['M AH1 DH ER0 Z IH0 N L AO2'],
  "mothershead": ['M AH1 DH ER0 Z HH EH2 D'],
  "mothershed": ['M AH1 DH ER0 SH EH2 D'],
  "mothership": ['M AH1 DH ER0 SH IH2 P'],
  "mothership's": ['M AH1 DH ER0 SH IH2 P S'],
  "motherships": ['M AH1 DH ER0 SH IH2 P S'],
  "mothon": ['M AA1 TH AH0 N'],
  "moths": ['M AO1 TH S'],
  "moti": ['M OW1 T IY0'],
  "motif": ['M OW0 T IY1 F'],
  "motifs": ['M OW0 T IY1 F S'],
  "motile": ['M OW1 T AH0 L'],
  "motility": ['M OW0 T IH1 L IH0 T IY0'],
  "motion": ['M OW1 SH AH0 N'],
  "motioned": ['M OW1 SH AH0 N D'],
  "motioning": ['M OW1 SH AH0 N IH0 NG'],
  "motionless": ['M OW1 SH AH0 N L AH0 S'],
  "motions": ['M OW1 SH AH0 N Z'],
  "motivate": ['M OW1 T AH0 V EY2 T'],
  "motivated": ['M OW1 T AH0 V EY2 T AH0 D'],
  "motivates": ['M OW1 T AH0 V EY2 T S'],
  "motivating": ['M OW1 T AH0 V EY2 T IH0 NG'],
  "motivation": ['M OW2 T AH0 V EY1 SH AH0 N'],
  "motivational": ['M OW2 T AH0 V EY1 SH AH0 N AH0 L'],
  "motivations": ['M OW2 T AH0 V EY1 SH AH0 N Z'],
  "motivator": ['M OW1 T AH0 V EY0 T ER0'],
  "motive": ['M OW1 T IH0 V'],
  "motives": ['M OW1 T IH0 V Z'],
  "motl": ['M AA1 T AH0 L'],
  "motley": ['M AA1 T L IY0'],
  "moto": ['M OW1 T OW0', 'M AA1 T OW0'],
  "motohashi": ['M OW2 T OW0 HH AA1 SH IY0'],
  "motola": ['M OW0 T OW1 L AH0'],
  "moton": ['M OW1 T OW0 N'],
  "motonori": ['M OW2 T OW0 N AO1 R IY0'],
  "motor": ['M OW1 T ER0'],
  "motor's": ['M OW1 T ER0 Z'],
  "motorbike": ['M OW1 T ER0 B AY2 K'],
  "motorbikes": ['M OW1 T ER0 B AY2 K S'],
  "motorboat": ['M OW1 T ER0 B OW2 T'],
  "motorcade": ['M OW1 T ER0 K EY2 D'],
  "motorcades": ['M OW1 T ER0 K EY2 D Z'],
  "motorcar": ['M OW1 T ER0 K AA2 R'],
  "motorcars": ['M OW1 T ER0 K AA2 R Z'],
  "motorcycle": ['M OW1 T ER0 S AY2 K AH0 L'],
  "motorcycles": ['M OW1 T ER0 S AY2 K AH0 L Z'],
  "motorcyclist": ['M OW1 T ER0 S AY2 K AH0 L IH0 S T', 'M OW1 T ER0 S AY2 K L IH0 S T'],
  "motorcyclists": ['M OW1 T ER0 S AY2 K AH0 L IH0 S T S', 'M OW1 T ER0 S AY2 K L IH0 S'],
  "motoren": ['M OW1 T ER0 AH0 N'],
  "motoring": ['M OW1 T ER0 IH0 NG'],
  "motorist": ['M OW1 T ER0 IH0 S T'],
  "motorists": ['M OW1 T ER0 AH0 S T S', 'M OW1 T ER0 IH0 S T S'],
  "motorize": ['M OW1 T ER0 AY2 Z'],
  "motorized": ['M OW1 T ER0 AY2 Z D'],
  "motorola": ['M OW2 T ER0 OW1 L AH0'],
  "motorola's": ['M OW2 T ER0 OW1 L AH0 Z'],
  "motors": ['M OW1 T ER0 Z'],
  "motors'": ['M OW1 T ER0 Z'],
  "motown": ['M OW1 T AW2 N'],
  "motown's": ['M OW1 T AW2 N Z'],
  "motrin": ['M AA1 T R IH0 N'],
  "mots": ['M AA1 T S'],
  "motsinger": ['M AA1 T S IH0 N JH ER0'],
  "motsoaledi": ['M AA0 T S OW0 L EY1 D IY0'],
  "mott": ['M AA1 T'],
  "mott's": ['M AA1 T S'],
  "motte": ['M AA1 T'],
  "motter": ['M AA1 T ER0'],
  "mottern": ['M AA1 T ER0 N'],
  "mottl": ['M AA1 T AH0 L'],
  "mottled": ['M AA1 T AH0 L D'],
  "mottley": ['M AA1 T L IY0'],
  "mottling": ['M AA1 T AH0 L IH0 NG', 'M AA1 T L IH0 NG'],
  "motto": ['M AA1 T OW0'],
  "mottola": ['M OW0 T OW1 L AH0'],
  "mottos": ['M AA1 T OW0 Z'],
  "mottram": ['M AA1 T R AH0 M'],
  "motts": ['M AA1 T S'],
  "mottus": ['M AA1 T AH0 S'],
  "motyka": ['M AA1 T AY0 K AH0'],
  "motyl": ['M OW1 T AH0 L'],
  "motz": ['M AA1 T S'],
  "motzer": ['M OW1 T Z ER0'],
  "motzko": ['M AA1 T S K OW0'],
  "moua": ['M AW1 AH0'],
  "moudry": ['M OW1 D R IY0'],
  "moudy": ['M AW1 D IY0'],
  "moue": ['M UW1'],
  "moueix": ['M UW2 W AY1 K S'],
  "moul": ['M AW1 L'],
  "mould": ['M OW1 L D'],
  "moulden": ['M OW1 L D AH0 N'],
  "moulder": ['M OW1 L D ER0'],
  "moulding": ['M OW1 L D IH0 NG'],
  "mouldings": ['M OW1 L D IH0 NG Z'],
  "moulds": ['M OW1 L D Z'],
  "mouldy": ['M OW1 L D IY0'],
  "moulin": ['M UW0 L AE1 N'],
  "moulinex": ['M UW1 L IH0 N EH0 K S'],
  "moulthrop": ['M AW1 L TH R AH0 P'],
  "moulton": ['M OW1 L T AH0 N'],
  "moultrie": ['M OW1 L T R IY0'],
  "mounce": ['M AW1 N S'],
  "mound": ['M AW1 N D'],
  "mounds": ['M AW1 N D Z'],
  "mounger": ['M AW1 NG ER0'],
  "mounsey": ['M AW1 N S IY0'],
  "mount": ['M AW1 N T'],
  "mount-everest": ['M AW2 N T EH1 V ER0 EH0 S T'],
  "mountain": ['M AW1 N T AH0 N'],
  "mountain's": ['M AW1 N T AH0 N Z'],
  "mountaineer": ['M AW1 N T IH0 N IH2 R'],
  "mountaineering": ['M AW1 N T IH0 N IH2 R IH0 NG'],
  "mountaineers": ['M AW1 N T IH0 N IH2 R Z'],
  "mountainous": ['M AW1 N T AH0 N AH0 S'],
  "mountains": ['M AW1 N T AH0 N Z'],
  "mountainside": ['M AW1 N T AH0 N S AY2 D'],
  "mountainsides": ['M AW1 N T AH0 N S AY2 D Z'],
  "mountaintop": ['M AW1 N T AH0 N T AA2 P'],
  "mountaintops": ['M AW1 N T AH0 N T AA2 P S'],
  "mountcastle": ['M AW1 N T K AE2 S AH0 L'],
  "mounted": ['M AW1 N T AH0 D', 'M AW1 N T IH0 D', 'M AW1 N IH0 D'],
  "mountford": ['M UW0 N T F AO1 R D'],
  "mounties": ['M AW1 N T IY0 Z', 'M AW1 N IY0 Z'],
  "mounting": ['M AW1 N T IH0 NG'],
  "mountjoy": ['M AW1 N T JH OY2'],
  "mountleigh": ['M AW1 N T L IY2'],
  "mountleigh's": ['M AW1 N T L IY2 Z'],
  "mounts": ['M AW1 N T S'],
  "mountz": ['M AW1 N T S'],
  "moura": ['M UH1 R AH0'],
  "mourad": ['M UW1 R AE0 D'],
  "mouradian": ['M AO0 R EY1 D IY0 AH0 N'],
  "mourer": ['M AO1 R ER0'],
  "mourn": ['M AO1 R N'],
  "mourned": ['M AO1 R N D'],
  "mourner": ['M AO1 R N ER0'],
  "mourners": ['M AO1 R N ER0 Z'],
  "mournful": ['M AO1 R N F AH0 L'],
  "mourning": ['M AO1 R N IH0 NG'],
  "mourns": ['M AO1 R N Z'],
  "mouse": ['M AW1 S'],
  "mousehole": ['M AW1 S HH OW2 L'],
  "mousel": ['M UW0 S EH1 L'],
  "mousepad": ['M AW1 S P AE0 D'],
  "mouser": ['M AW1 Z ER0'],
  "mousers": ['M AW1 Z ER0 Z'],
  "mousetrap": ['M AW1 S T R AE2 P'],
  "mousley": ['M AW1 S L IY0'],
  "moussa": ['M AW1 S AH0'],
  "moussaka": ['M UW0 S AA1 K AH0'],
  "mousse": ['M UW1 S'],
  "mousseau": ['M UW2 S OW1'],
  "moussorgsky": ['M UW0 S AO1 R G S K IY0', 'M UW0 Z AO1 R G S K IY0'],
  "moustache": ['M AH1 S T AE2 SH'],
  "mousy": ['M AW1 S IY0'],
  "mouth": ['M AW1 TH'],
  "mouthed": ['M AW1 DH D'],
  "mouthful": ['M AW1 TH F UH2 L'],
  "mouthing": ['M AW1 DH IH0 NG'],
  "mouthpart": ['M AW1 TH P AA2 R T'],
  "mouthparts": ['M AW1 TH P AA2 R T S'],
  "mouthpiece": ['M AW1 TH P IY2 S'],
  "mouthpieces": ['M AW1 TH P IY2 S IH0 Z'],
  "mouths": ['M AW1 DH Z'],
  "mouthwash": ['M AW1 TH W AA2 SH'],
  "mouthy": ['M AW1 TH IY0'],
  "mouton": ['M UW1 T AO0 N'],
  "moutray": ['M UW0 T R EY1'],
  "moutse": ['M UW1 T S IY0'],
  "mouw": ['M AW1 W'],
  "mouzon": ['M UW0 Z AO1 N'],
  "movable": ['M UW1 V AH0 B AH0 L'],
  "move": ['M UW1 V'],
  "moved": ['M UW1 V D'],
  "movement": ['M UW1 V M AH0 N T'],
  "movement's": ['M UW1 V M AH0 N T S'],
  "movements": ['M UW1 V M AH0 N T S'],
  "mover": ['M UW1 V ER0'],
  "movers": ['M UW1 V ER0 Z'],
  "moves": ['M UW1 V Z'],
  "movida": ['M UW2 V IY1 D AH0'],
  "movie": ['M UW1 V IY0'],
  "movie's": ['M UW1 V IY0 Z'],
  "moviegoer": ['M UW1 V IY2 G OW2 ER0'],
  "moviegoers": ['M UW1 V IY2 G OW2 ER0 Z'],
  "moviegoing": ['M UW1 V IY2 G OW2 IH0 NG'],
  "movielab": ['M UW1 V IY0 L AE2 B'],
  "movieline": ['M UW1 V IY0 L AY0 N'],
  "moviemaker": ['M UW1 V IY2 M EY2 K ER0'],
  "moviemakers": ['M UW1 V IY2 M EY2 K ER0 Z'],
  "moviemaking": ['M UW1 V IY2 M EY2 K IH0 NG'],
  "movies": ['M UW1 V IY0 Z'],
  "movietime": ['M UW1 V IY0 T AY2 M'],
  "moving": ['M UW1 V IH0 NG'],
  "movingly": ['M UW1 V IH0 NG L IY0'],
  "mow": ['M OW1'],
  "mowat": ['M OW1 AH0 T'],
  "mowatt": ['M OW1 AH0 T'],
  "mowbray": ['M OW1 B R EY0'],
  "mowdy": ['M OW1 D IY0'],
  "mowed": ['M OW1 D'],
  "mowell": ['M AA1 W EH0 L'],
  "mowen": ['M OW1 AH0 N'],
  "mower": ['M OW1 ER0'],
  "mowers": ['M OW1 ER0 Z'],
  "mowery": ['M AW1 ER0 IY0'],
  "mowing": ['M AW1 IH0 NG'],
  "mowrer": ['M AO1 R ER0'],
  "mowrey": ['M AO1 R IY0'],
  "mowry": ['M AO1 R IY0'],
  "mows": ['M AW1 Z'],
  "moxie": ['M AA1 K S IY0'],
  "moxley": ['M AA1 K S L IY0'],
  "moxon": ['M AA1 K S AH0 N'],
  "moy": ['M OY1'],
  "moya": ['M OY1 AH0'],
  "moye": ['M OY1'],
  "moyer": ['M OY1 ER0'],
  "moyer's": ['M OY1 ER0 Z'],
  "moyers": ['M OY1 ER0 Z'],
  "moyes": ['M OY1 Z'],
  "moyl": ['M OY1 L'],
  "moylan": ['M OY1 L AH0 N'],
  "moyle": ['M OY1 L'],
  "moyna": ['M OY1 N AH0'],
  "moynahan": ['M OY1 N AH0 HH AE0 N'],
  "moynihan": ['M OY1 N IH0 HH AE0 N'],
  "moynihan's": ['M OY1 N IH0 HH AE0 N Z'],
  "moyse": ['M OY1 S'],
  "mozambican": ['M OW2 Z AE0 M B IY1 K AH0 N'],
  "mozambicans": ['M OW2 Z AE0 M B IY1 K AH0 N Z'],
  "mozambique": ['M OW2 Z AE0 M B IY1 K', 'M OW2 Z AH0 M B IY1 K'],
  "mozart": ['M OW1 Z AA0 R T', 'M OW1 T S AA0 R T'],
  "mozart's": ['M OW1 Z AA0 R T S', 'M OW1 T S AA0 R T S'],
  "mozartean": ['M OW2 Z AA1 R T IY0 AH0 N', 'M OW2 T Z AA1 R T IY0 AH0 N'],
  "mozee": ['M AA1 Z IY0'],
  "mozelle": ['M AH0 Z EH1 L'],
  "mozena": ['M AH0 Z IY1 N AH0'],
  "mozer": ['M OW1 Z ER0'],
  "mozer's": ['M OW1 Z ER0 Z'],
  "mozingo": ['M OW0 Z IY1 NG G OW0'],
  "mozley": ['M AA1 Z L IY0'],
  "mozzarella": ['M AA2 T S AH0 R EH1 L AH0'],
  "mpeg": ['EH1 M P EH2 G'],
  "mpg": ['EH1 M P IY1 JH IY1', 'M AY1 L Z P ER0 G AE1 L AH0 N'],
  "mph": ['EH1 M P IY1 EY1 CH', 'M AY1 L Z P ER0 AW1 ER0'],
  "mr": ['M IH1 S T ER0'],
  "mr.": ['M IH1 S T ER0'],
  "mraz": ['M R AE1 Z'],
  "mrazek": ['M R AA1 Z EH0 K'],
  "mrazik": ['M R AA1 Z IH0 K'],
  "mri": ['EH2 M AA2 R AY1'],
  "mroczek": ['M R AA1 CH EH0 K'],
  "mroczka": ['M R AA1 CH K AH0'],
  "mroczkowski": ['M R AH0 CH K AO1 F S K IY0'],
  "mrotek": ['M R OW1 T EH2 K'],
  "mroz": ['M R AA1 Z'],
  "mrozek": ['M R OW1 Z EH0 K'],
  "mrozinski": ['M R AH0 Z IH1 N S K IY0'],
  "mrs": ['M IH1 S IH0 Z'],
  "mrs.": ['M IH1 S IH0 Z'],
  "mruk": ['M R AH1 K'],
  "ms": ['M IH1 Z'],
  "ms.": ['M IH1 Z'],
  "msgr": ['M AA0 N S IY1 N Y ER0'],
  "mssrs": ['M EH1 S ER0 Z'],
  "mssrs.": ['M EH1 S ER0 Z'],
  "mt": ['M AW1 N T', 'EH1 M T IY1'],
  "mtel": ['EH1 M T EH2 L'],
  "mtv": ['EH1 M T IY1 V IY1'],
  "mu": ['M UW1'],
  "muammar": ['M UW0 AE1 M ER0'],
  "muavenet": ['M UW2 AH0 V EH1 N IH0 T'],
  "mubarak": ['M UW0 B AA1 R IH0 K', 'M Y UW0 B AA1 R IH0 K'],
  "mubarak's": ['M UW0 B AA1 R IH0 K S', 'M Y UW0 B AA1 R IH0 K S'],
  "mucci": ['M UW1 CH IY0'],
  "muccio": ['M UW1 CH IY0 OW0'],
  "much": ['M AH1 CH'],
  "mucha": ['M AH1 CH AH0'],
  "muchmore": ['M AH1 K M AO0 R'],
  "muchnick": ['M AH1 K N IH0 K'],
  "mucho": ['M UW1 CH OW0'],
  "muchow": ['M AH1 CH OW0'],
  "muck": ['M AH1 K'],
  "muckenfuss": ['M AH1 K AH0 N F AH2 S'],
  "muckey": ['M AH1 K IY0'],
  "mucking": ['M AH1 K IH0 NG'],
  "muckle": ['M AH1 K AH0 L'],
  "muckleroy": ['M AH1 K L ER0 OY0', 'M AH1 K AH0 L R OY0'],
  "muckrake": ['M AH1 K R EY2 K'],
  "muckraker": ['M AH1 K R EY2 K ER0'],
  "muckraking": ['M AH1 K R EY2 K IH0 NG'],
  "mucky": ['M AH1 K IY0'],
  "mucosa": ['M Y UW0 K OW1 S AH0'],
  "mucosal": ['M Y UW0 K OW1 S AH0 L'],
  "mucous": ['M Y UW1 K AH0 S'],
  "mucus": ['M Y UW1 K AH0 S'],
  "mud": ['M AH1 D'],
  "mudd": ['M AH1 D'],
  "muddied": ['M AH1 D IY0 D'],
  "muddier": ['M AH1 D IY0 ER0'],
  "muddle": ['M AH1 D AH0 L'],
  "muddled": ['M AH1 D AH0 L D'],
  "muddleheaded": ['M AH1 D AH0 L HH EH2 D AH0 D', 'M AH1 D AH0 L HH EH2 D IH0 D'],
  "muddles": ['M AH1 D AH0 L Z'],
  "muddling": ['M AH1 D AH0 L IH0 NG', 'M AH1 D L IH0 NG'],
  "muddy": ['M AH1 D IY0'],
  "muddying": ['M AH1 D IY0 IH0 NG'],
  "mudge": ['M AH1 JH'],
  "mudgett": ['M AH1 JH IH0 T'],
  "mudra": ['M AH1 D R AH0'],
  "mudrick": ['M AH1 D R IH0 K'],
  "mudry": ['M AH1 D R IY0'],
  "muds": ['M AH1 D Z'],
  "mudslide": ['M AH1 D S L AY0 D'],
  "mudslides": ['M AH1 D S L AY0 D Z'],
  "mudslinging": ['M AH1 D S L IH2 NG IH0 NG'],
  "mudwagon": ['M AH1 D W AE2 G AH0 N'],
  "muecke": ['M UW1 K'],
  "muegge": ['M UW1 G'],
  "muehl": ['M Y UW1 L'],
  "muehlbauer": ['M Y UW1 L B AW0 ER0'],
  "muehlebach": ['M Y UW1 L B AA2 K'],
  "muehler": ['M Y UW1 L ER0'],
  "mueller": ['M Y UW1 L ER0'],
  "muellner": ['M Y UW1 L N ER0'],
  "muench": ['M Y UW1 NG K'],
  "muenchen": ['M Y UW1 N CH AH0 N'],
  "muenchow": ['M UW1 N CH AW0'],
  "muenster": ['M Y UW1 N S T ER0'],
  "muetzel": ['M Y UW1 T Z AH0 L'],
  "muff": ['M AH1 F'],
  "muffin": ['M AH1 F AH0 N'],
  "muffins": ['M AH1 F AH0 N Z'],
  "muffle": ['M AH1 F AH0 L'],
  "muffled": ['M AH1 F AH0 L D'],
  "muffler": ['M AH1 F L ER0'],
  "mufflers": ['M AH1 F L ER0 Z'],
  "muffles": ['M AH1 F AH0 L Z'],
  "muffley": ['M AH1 F L IY0'],
  "muffling": ['M AH1 F L IH0 NG', 'M AH1 F AH0 L IH0 NG'],
  "muffoletto": ['M UW0 F OW0 L EH1 T OW0'],
  "muffs": ['M AH1 F S'],
  "muffy": ['M AH1 F IY0'],
  "mug": ['M AH1 G'],
  "mugabe": ['M UW0 G AA1 B EY0'],
  "mugabe's": ['M UW0 G AA1 B EY0 Z'],
  "mugar": ['M Y UW1 G ER0'],
  "mugavero": ['M UW0 G AA0 V EH1 R OW0'],
  "mugford": ['M AH1 G F ER0 D'],
  "mugged": ['M AH1 G D'],
  "mugger": ['M AH1 G ER0'],
  "muggeridge": ['M AH1 G ER0 IH2 JH'],
  "muggers": ['M AH1 G ER0 Z'],
  "mugging": ['M AH1 G IH0 NG'],
  "muggings": ['M AH1 G IH0 NG Z'],
  "muggsy": ['M AH1 G S IY0'],
  "muggy": ['M AH1 G IY0'],
  "mughniyeh": ['M AH1 G N IH0 Y AH0'],
  "mugniyah": ['M AH1 G N IH0 Y AH0'],
  "mugs": ['M AH1 G Z'],
  "mugu": ['M UW1 G UW0'],
  "muha": ['M Y UW1 HH AH0'],
  "muhabara": ['M UW2 HH AH0 B AA1 R AH0'],
  "muhamed": ['M UH0 HH AA1 M EH0 D'],
  "muhamed's": ['M UH0 HH AA1 M EH0 D Z'],
  "muhammad": ['M UH0 HH AA1 M AH0 D'],
  "muhammad's": ['M UH0 HH AA1 M AH0 D Z'],
  "muhammed": ['M UH0 HH AA1 M EH0 D'],
  "muhammed's": ['M UH0 HH AA1 M EH0 D Z'],
  "muharram": ['M AH0 HH AE1 R AH0 M'],
  "muhl": ['M AH1 L'],
  "muhlbauer": ['M UW1 L B AW0 ER0'],
  "muhlenkamp": ['M UW1 L IH0 N K AE0 M P'],
  "muhr": ['M UH1 R'],
  "muhs": ['M AH1 S'],
  "mui": ['M UW1 IH0'],
  "muilenburg": ['M UW1 L AH0 N B ER0 G'],
  "muir": ['M Y UH1 R'],
  "muirfield": ['M Y UH1 R F IY0 L D'],
  "muirhead": ['M Y UH1 R HH EH2 D'],
  "muise": ['M UW1 Z'],
  "mujahadeen": ['M Y UW0 JH AE1 HH AH0 D IY2 N'],
  "mujahedeen": ['M UW2 JH AH0 HH EH0 D IY1 N'],
  "mujahideen": ['M UW2 JH AH0 HH EH0 D IY1 N'],
  "mujica": ['M Y UW1 JH IH0 K AH0'],
  "mujzel": ['M AH1 JH Z AH0 L'],
  "mukai": ['M UW0 K AA1 IY0'],
  "mukasey": ['M Y UW1 K IH0 S IY0'],
  "mukherjee": ['M AH0 K ER1 JH IY0'],
  "mukhopadhyay": ['M UW2 K OW0 P AA1 D Y AY0'],
  "mukluk": ['M AH1 K L AH0 K'],
  "mul's": ['M UH1 L Z'],
  "mula": ['M Y UW1 L AH0'],
  "mulanaphy": ['M Y UW1 L AH0 N AE2 F IY0'],
  "mulanax": ['M Y UW1 L AH0 N AE0 K S'],
  "mulatto": ['M AH0 L AA1 T OW0'],
  "mulberry": ['M AH1 L B EH2 R IY0'],
  "mulcahey": ['M AH1 L K AH0 HH IY0'],
  "mulcahy": ['M AH0 L K EY1 HH IY0'],
  "mulcare": ['M AH1 L K ER0'],
  "mulch": ['M AH1 L CH'],
  "mulched": ['M AH1 L CH T'],
  "mulches": ['M AH1 L CH IH0 Z'],
  "mulching": ['M AH1 L CH IH0 NG'],
  "mulct": ['M AH1 L K T'],
  "mulder": ['M AH1 L D ER0'],
  "muldoon": ['M AH0 L D UW1 N'],
  "muldorfer": ['M AH1 L D AO2 F ER0'],
  "muldowney": ['M AH1 L D AW0 N IY0'],
  "muldrew": ['M AH1 L D R UW0'],
  "muldrow": ['M AH1 L D R AW0'],
  "mule": ['M Y UW1 L'],
  "mules": ['M Y UW1 L Z'],
  "mulford": ['M AH1 L F ER0 D'],
  "mulgrew": ['M AH1 L G R UW0'],
  "mulhall": ['M AH1 L HH AH0 L'],
  "mulhearn": ['M AH1 L HH ER0 N'],
  "mulheren": ['M AH0 L HH EH1 R AH0 N'],
  "mulheren's": ['M AH0 L HH EH1 R AH0 N Z'],
  "mulherin": ['M AH1 L HH ER0 IH0 N'],
  "mulhern": ['M AH1 L HH ER0 N'],
  "mulholland": ['M AH2 L HH AA1 L AH0 N D'],
  "mulhouse": ['M AH1 L HH AW2 S'],
  "mulkern": ['M AH1 L K ER0 N'],
  "mulkey": ['M AH1 L K IY0'],
  "mulkins": ['M AH1 L K IH0 N Z'],
  "mull": ['M AH1 L'],
  "mullady": ['M AH1 L AH0 D IY0'],
  "mullah": ['M AH1 L AH0'],
  "mullahs": ['M AH1 L AH0 Z'],
  "mullally": ['M AH1 L AH0 L IY0'],
  "mullaly": ['M AH1 L AH0 L IY0'],
  "mullan": ['M AH1 L AH0 N'],
  "mullane": ['M AH1 L AH0 N'],
  "mullaney": ['M AH1 L AH0 N IY0'],
  "mullany": ['M AH1 L AH0 N IY0'],
  "mullarkey": ['M AH0 L AA1 R K IY0'],
  "mulldorfer": ['M AH1 L D AO0 R F ER0'],
  "mulled": ['M AH1 L D'],
  "mullen": ['M AH1 L AH0 N'],
  "mullenax": ['M AH1 L AH0 N AE2 K S'],
  "mullendore": ['M UW0 L EH1 N D AO0 R'],
  "mullenix": ['M UW1 L IH0 N IH0 K S', 'M AH1 L AH0 N IH0 K S'],
  "mullens": ['M AH1 L AH0 N Z'],
  "muller": ['M AH1 L ER0'],
  "muller's": ['M AH1 L ER0 Z'],
  "mullet": ['M AH1 L AH0 T'],
  "mullett": ['M UW1 L IH0 T'],
  "mullican": ['M AH1 L IH0 K AH0 N'],
  "mulligan": ['M AH1 L IH0 G AH0 N'],
  "mulligans": ['M AH1 L IH0 G AH0 N Z'],
  "mulligatawny": ['M AH1 L IH0 G AH0 T AA1 N IY0'],
  "mulliken": ['M AH1 L IH0 K AH0 N'],
  "mullikin": ['M AH1 L IH0 K IH0 N'],
  "mullin": ['M AH1 L IH0 N'],
  "mullinax": ['M AH1 L IH0 N AE0 K S'],
  "mullineaux": ['M AH1 L IH0 N OW2'],
  "mulling": ['M AH1 L IH0 NG'],
  "mullings": ['M AH1 L IH0 NG Z'],
  "mullinix": ['M AH1 L IH0 N IH0 K S'],
  "mullins": ['M AH1 L IH2 N Z'],
  "mullis": ['M AH1 L IH0 S'],
  "mulloy": ['M AH1 L OY0'],
  "mulls": ['M AH1 L Z'],
  "mulnix": ['M AH1 L N IH0 K S'],
  "mulqueen": ['M AH0 L K W IY1 N'],
  "mulroney": ['M AH0 L R OW1 N IY0'],
  "mulroney's": ['M AH0 L R OW1 N IY0 Z'],
  "mulrooney": ['M AH1 L R UW0 N IY0'],
  "mulroy": ['M AH1 L R OY2'],
  "mulry": ['M AH1 L R IY0'],
  "multer": ['M AH1 L T ER0'],
  "multi": ['M AH1 L T IY0'],
  "multibank": ['M AH1 L T IY0 B AE2 NG K'],
  "multibillion": ['M AH2 L T AY2 B IH1 L Y AH0 N', 'M AH2 L T IY2 B IH1 L Y AH0 N'],
  "multicandidate": ['M AH2 L T IY0 K AE1 N D IH0 D EY2 T', 'M AH2 L T IY0 K AE1 N D AH0 D AH0 T'],
  "multicenter": ['M AH1 L T IY0 S EH2 N T ER0'],
  "multicolor": ['M AH2 L T IY0 K AH1 L ER0'],
  "multicolored": ['M AH2 L T IY0 K AH1 L ER0 D'],
  "multicultural": ['M AH2 L T IY0 K AH1 L CH ER0 AH0 L'],
  "multiculturalism": ['M AH2 L T IY0 K AH1 L CH ER0 AH0 L IH2 Z AH0 M'],
  "multidex": ['M AH1 L T IH0 D EH2 K S'],
  "multiemployer": ['M AH2 L T IY0 IH0 M P L OY1 ER0'],
  "multiethnic": ['M AH2 L T IY0 EH1 TH N IH0 K'],
  "multifacet": ['M AH2 L T IY0 F AE1 S AH0 T'],
  "multifaceted": ['M AH2 L T IY0 F AE1 S AH0 T IH0 D'],
  "multifamily": ['M AH2 L T AY0 F AE1 M AH0 L IY0', 'M AH2 L T IY0 F AE1 M L IY0'],
  "multifoods": ['M AH1 L T IY0 F UW1 D Z'],
  "multifoods'": ['M AH1 L T IY0 F UW2 D Z'],
  "multifunctional": ['M AH2 L T IY0 F AH1 NG K SH AH2 N AH0 L'],
  "multilateral": ['M AH2 L T IH0 L AE1 T ER0 AH0 L', 'M AH2 L T IY0 L AE1 T ER0 AH0 L'],
  "multilateralism": ['M AH2 L T IH0 L AE1 T ER0 AH0 L IH2 Z AH0 M', 'M AH2 L T IH0 L AE1 T ER0 AH0 L IH0 Z M'],
  "multilaterally": ['M AH2 L T IH0 L AE1 T ER0 AH0 L IY0', 'M AH2 L T IY0 L AE1 T ER0 AH0 L IY0'],
  "multilayer": ['M AH2 L T IY0 L EY1 ER0'],
  "multilayered": ['M AH2 L T IY0 L EY1 ER0 D'],
  "multilevel": ['M AH2 L T AY0 L EH1 V AH0 L', 'M AH2 L T IY0 L EH1 V AH0 L'],
  "multiline": ['M AH1 L T IY0 L AY2 N'],
  "multilingual": ['M AH2 L T IY0 L IH1 NG W AH0 L', 'M AH2 L T AY0 L IH1 NG W AH0 L'],
  "multimarket": ['M AH1 L T IY0 M AA1 R K IH0 T'],
  "multimate": ['M AH1 L T IY0 M EY2 T'],
  "multimedia": ['M AH2 L T IY0 M IY1 D IY0 AH0', 'M AH2 L T AY0 M IY1 D IY0 AH0'],
  "multimedia's": ['M AH2 L T IY0 M IY1 D IY0 AH0 Z', 'M AH2 L T AY0 M IY1 D IY0 AH0 Z'],
  "multimillion": ['M AH2 L T AY2 M IH1 L Y AH0 N', 'M AH2 L T IY2 M IH1 L Y AH0 N'],
  "multimillionaire": ['M AH2 L T IY0 M IH2 L Y AH0 N EH1 R', 'M AH2 L T AY0 M IH2 L Y AH0 N EH1 R'],
  "multimillionaires": ['M AH2 L T IY0 M IH2 L Y AH0 N EH1 R Z', 'M AH2 L T AY0 M IH2 L Y AH0 N EH1 R Z'],
  "multinational": ['M AH2 L T AY2 N AE1 SH AH0 N AH0 L', 'M AH2 L T IY2 N AE1 SH AH0 N AH0 L'],
  "multinationals": ['M AH2 L T AY2 N AE1 SH AH0 N AH0 L Z', 'M AH2 L T IY2 N AE1 SH AH0 N AH0 L Z'],
  "multipart": ['M AH1 L T IY0 P AA2 R T'],
  "multiparty": ['M AH1 L T IY0 P AA2 R T IY0'],
  "multiplayer": ['M AH1 L T IY0 P L EY2 ER0'],
  "multiple": ['M AH1 L T AH0 P AH0 L'],
  "multiples": ['M AH1 L T AH0 P AH0 L Z'],
  "multiplex": ['M AH1 L T IY0 P L EH2 K S'],
  "multiplexer": ['M AH1 L T IY0 P L EH2 K S ER0'],
  "multiplexers": ['M AH1 L T IY0 P L EH2 K S ER0 Z'],
  "multiplication": ['M AH2 L T AH0 P L AH0 K EY1 SH AH0 N'],
  "multiplicity": ['M AH2 L T AH0 P L IH1 S IH0 T IY0'],
  "multiplied": ['M AH1 L T AH0 P L AY2 D'],
  "multiplier": ['M AH1 L T AH0 P L AY2 ER0'],
  "multiplies": ['M AH1 L T AH0 P L AY2 Z'],
  "multiply": ['M AH1 L T AH0 P L AY2'],
  "multiplying": ['M AH1 L T AH0 P L AY2 IH0 NG'],
  "multiprocessor": ['M AH2 L T IY0 P R AA1 S EH2 S ER0'],
  "multipurpose": ['M AH2 L T IY0 P ER1 P AH0 S'],
  "multiracial": ['M AH2 L T AY2 R EY1 SH AH0 L'],
  "multistate": ['M AH1 L T IY0 S T EY1 T'],
  "multistory": ['M AH1 L T IY0 S T AO2 R IY0'],
  "multitask": ['M AH1 L T IY0 T AE2 S K'],
  "multitasking": ['M AH1 L T IY0 T AE2 S K IH0 NG'],
  "multitude": ['M AH1 L T AH0 T UW2 D', 'M AH1 L T AH0 T Y UW2 D'],
  "multitudes": ['M AH1 L T AH0 T Y UW2 D Z'],
  "multiuser": ['M AH1 L T IY0 Y UW2 Z ER0'],
  "multivalve": ['M AH1 L T IY0 V AE0 L V'],
  "multivariate": ['M AH2 L T IY0 V AE1 R IY0 IH0 T'],
  "multivariates": ['M AH2 L T IY0 V AE1 R IY0 IH0 T S'],
  "multivision": ['M AH2 L T IY0 V IH1 ZH AH0 N'],
  "multivitamin": ['M AH2 L T IY0 V AY1 T AH2 M AH0 N'],
  "multiyear": ['M AH1 L T IY0 Y IY1 R'],
  "multnomah": ['M AH2 L N OW1 M AH0'],
  "mulvaney": ['M AH2 L V EY1 N IY0'],
  "mulvany": ['M AH2 L V EY1 N IY0'],
  "mulvehill": ['M AH1 L V HH IH0 L', 'M AH1 L V IH0 HH IH0 L'],
  "mulvey": ['M AH0 L V EY1'],
  "mulvihill": ['M AH1 L V IY0 HH IH0 L', 'M AH1 L V IH0 HH IH0 L'],
  "mum": ['M AH1 M'],
  "mum's": ['M AH1 M Z'],
  "muma": ['M Y UW1 M AH0'],
  "mumaw": ['M UW1 M AO0'],
  "mumbai": ['M UH2 M B AY1'],
  "mumble": ['M AH1 M B AH0 L'],
  "mumbled": ['M AH1 M B AH0 L D'],
  "mumbles": ['M AH1 M B AH0 L Z'],
  "mumbling": ['M AH1 M B AH0 L IH0 NG', 'M AH1 M B L IH0 NG'],
  "mumbo": ['M AH1 M B OW0'],
  "mumby": ['M AH1 M B IY0'],
  "mumford": ['M AH1 M F ER0 D'],
  "mumia": ['M AH0 M IY1 Y AH0'],
  "mumm": ['M AH1 M'],
  "mumma": ['M AH1 M AH0'],
  "mumme": ['M AH1 M'],
  "mummer": ['M AH1 M ER0'],
  "mummer's": ['M AH1 M ER0 Z'],
  "mummert": ['M AH1 M ER0 T'],
  "mummey": ['M AH1 M IY0'],
  "mummies": ['M AH1 M IY0 Z'],
  "mummification": ['M AH2 M IH0 F IH0 K EY1 SH AH0 N'],
  "mummified": ['M AH1 M IH0 F AY2 D'],
  "mummify": ['M AH1 M AH0 F AY2'],
  "mummifying": ['M AH1 M AH0 F AY2 IH0 NG'],
  "mummy": ['M AH1 M IY0'],
  "mummy's": ['M AH1 M IY0 Z'],
  "mumper": ['M AH1 M P ER0'],
  "mumphrey": ['M AH1 M F R IY0'],
  "mumpower": ['M AH1 M P OW0 ER0'],
  "mumps": ['M AH1 M P S'],
  "mums": ['M AH1 M Z'],
  "mun": ['M AH1 N'],
  "munafo": ['M UW0 N AA1 F OW0'],
  "munce": ['M AH1 N S'],
  "muncee": ['M AH1 N S IY0'],
  "muncey": ['M AH1 N S IY0'],
  "munch": ['M AH1 N CH'],
  "munchausen": ['M AH1 N CH AW2 Z IH0 N'],
  "munched": ['M AH1 N CH T'],
  "munchies": ['M AH1 N CH IY2 Z'],
  "munching": ['M AH1 N CH IH0 NG'],
  "muncie": ['M AH1 N S IY0'],
  "muncy": ['M AH1 N S IY0'],
  "mund": ['M AH1 N D'],
  "mundane": ['M AH0 N D EY1 N'],
  "munday": ['M AH1 N D EY2'],
  "mundell": ['M AH1 N D AH0 L'],
  "munden": ['M AH1 N D AH0 N'],
  "mundi": ['M AH1 N D IY0'],
  "mundie": ['M AH1 N D IY0'],
  "mundinger": ['M AH1 N D IH0 NG ER0'],
  "mundis": ['M AH1 N D IH0 S'],
  "mundlow": ['M AH1 N D L OW0'],
  "mundo": ['M AH1 N D OW0'],
  "mundorf": ['M AH1 N D AO0 R F'],
  "mundorff": ['M AH1 N D AO0 R F'],
  "mundt": ['M AH1 N T'],
  "mundy": ['M AH1 N D IY0'],
  "munford": ['M AH1 N F ER0 D'],
  "munford's": ['M AH1 N F ER0 D Z'],
  "munger": ['M AH1 NG ER0'],
  "mungia": ['M UW1 N JH AH0'],
  "mungin": ['M AH1 NG G IH0 N'],
  "mungle": ['M AH1 NG G AH0 L'],
  "mungo": ['M AH1 NG G OW0'],
  "munguia": ['M UW1 N G W IY0 AH0'],
  "muni": ['M Y UW1 N IY0'],
  "munich": ['M Y UW1 N IH0 K'],
  "munich's": ['M Y UW1 N IH0 K S'],
  "municipal": ['M Y UW0 N IH1 S AH0 P AH0 L'],
  "municipalities": ['M Y UW2 N IH0 S AH0 P AE1 L IH0 T IY0 Z'],
  "municipality": ['M Y UW2 N IH0 S AH0 P AE1 L AH0 T IY0'],
  "municipally": ['M Y UW0 N IH1 S IH0 P AH0 L IY0', 'M Y UW0 N IH1 S IH0 P L IY0'],
  "municipals": ['M Y UW0 N IH1 S IH0 P AH0 L Z'],
  "munier": ['M Y UW1 N IY0 ER0'],
  "munis": ['M Y UW1 N IH0 S'],
  "munition": ['M Y UW0 N IH1 SH AH0 N'],
  "munitions": ['M Y UW0 N IH1 SH AH0 N Z'],
  "muniz": ['M Y UW1 N IH0 Z'],
  "munk": ['M AH1 NG K'],
  "munkres": ['M AH1 NG K ER0 Z'],
  "munley": ['M AH1 N L IY0'],
  "munn": ['M AH1 N'],
  "munnell": ['M AH1 N AH0 L'],
  "munnerlyn": ['M AH0 N ER1 L IH0 N'],
  "munni": ['M Y UW1 N IY0', 'M AH1 N IY0'],
  "munns": ['M AH1 N Z'],
  "munos": ['M UW1 N OW0 Z'],
  "munoz": ['M UW1 N Y OW0 Z'],
  "munro": ['M AH0 N R OW1'],
  "munroe": ['M AH1 N R OW0'],
  "muns": ['M AH1 N Z'],
  "munsch": ['M AH1 N SH'],
  "munsell": ['M AH1 N S AH0 L'],
  "munsey": ['M AH1 N Z IY0'],
  "munsingwear": ['M AH1 N S IH0 NG W EH2 R'],
  "munson": ['M AH1 N S AH0 N'],
  "munster": ['M AH1 N S T ER0'],
  "munsterman": ['M AH1 N S T ER0 M AH0 N'],
  "munt": ['M AH1 N T'],
  "muntean": ['M AH0 N T IY1 N'],
  "munter": ['M AH1 N T ER0'],
  "muntz": ['M AH1 N T S'],
  "munyan": ['M AH1 N Y AH0 N'],
  "munyon": ['M AH1 N Y AH0 N'],
  "munz": ['M AH1 N Z'],
  "munzer": ['M AH1 N Z ER0'],
  "muoio": ['M W OW1 IY0 OW0'],
  "muolo": ['M W OW1 L OW0'],
  "muppet": ['M AH1 P AH0 T'],
  "muppets": ['M AH1 P IH0 T S'],
  "mura": ['M UH1 R AH0'],
  "murad": ['M Y UH1 R AE0 D'],
  "murai": ['M Y ER0 AY1'],
  "murakami": ['M UH0 R AA0 K AA1 M IY0'],
  "mural": ['M Y UH1 R AH0 L'],
  "murali": ['M ER0 AA1 L IY0'],
  "murals": ['M Y UH1 R AH0 L Z'],
  "muramatsu": ['M UW2 R AA0 M AA1 T S UW2'],
  "muramoto": ['M UH0 R AA0 M OW1 T OW0'],
  "muranaga": ['M ER0 R AH0 N AA1 G AH0'],
  "murano": ['M UH0 R AA1 N OW0'],
  "muraoka": ['M UH0 R AA0 OW1 K AH0'],
  "murase": ['M Y ER1 EY0 Z'],
  "muraski": ['M ER0 AA1 S K IY0'],
  "murasky": ['M ER0 AE1 S K IY0'],
  "murata": ['M UH0 R AA1 T AH0'],
  "muratore": ['M UH0 R AA0 T AO1 R EY0'],
  "muravich": ['M ER1 AH0 V IH2 CH'],
  "murawski": ['M ER0 AA1 F S K IY0'],
  "murayama": ['M ER2 AY0 AA1 M AH0'],
  "murayama's": ['M ER2 AY0 AA1 M AH0 Z'],
  "murch": ['M ER1 K'],
  "murchie": ['M ER1 CH IY0'],
  "murchinson": ['M ER1 CH IH0 N S AH0 N'],
  "murchison": ['M ER1 CH IH0 S AH0 N'],
  "murdaugh": ['M ER1 D AO0'],
  "murden": ['M ER1 D AH0 N'],
  "murder": ['M ER1 D ER0'],
  "murdered": ['M ER1 D ER0 D'],
  "murderer": ['M ER1 D ER0 ER0'],
  "murderer's": ['M ER1 D ER0 ER0 Z'],
  "murderers": ['M ER1 D ER0 ER0 Z'],
  "murdering": ['M ER1 D ER0 IH0 NG'],
  "murderous": ['M ER1 D ER0 AH0 S'],
  "murders": ['M ER1 D ER0 Z'],
  "murdick": ['M ER1 D IH0 K'],
  "murdoch": ['M ER1 D AA0 K'],
  "murdoch's": ['M ER1 D AA0 K S'],
  "murdock": ['M ER1 D AA0 K'],
  "murdock's": ['M ER1 D AA0 K S'],
  "murdocks": ['M ER1 D AA0 K S'],
  "murdy": ['M ER1 D IY0'],
  "murff": ['M ER1 F'],
  "murfin": ['M ER1 F IH0 N'],
  "murguia": ['M UH1 R G W IY0 AH0'],
  "muri": ['M UH1 R IY0'],
  "murial": ['M Y UH1 R IY0 AH0 L'],
  "murias": ['M Y UH1 R IY0 AH0 S'],
  "muriel": ['M Y UH1 R IY0 AH0 L'],
  "murihuro": ['M UH2 R IY0 HH UH1 R OW0'],
  "murihuro's": ['M UH2 R IY0 HH UH1 R OW0 Z'],
  "murillo": ['M AH0 R IH1 L OW0'],
  "murin": ['M Y UH1 R IH0 N'],
  "murine": ['M Y UH1 R IY2 N'],
  "murjani": ['M ER0 JH AA1 N IY0'],
  "murk": ['M ER1 K'],
  "murkier": ['M ER1 K IY0 ER0'],
  "murkland": ['M ER1 K L AE2 N D'],
  "murkowski": ['M ER0 K AW1 S K IY0'],
  "murky": ['M ER1 K IY0'],
  "murley": ['M ER1 L IY0'],
  "murmansk": ['M ER0 M AE0 N S K'],
  "murmur": ['M ER1 M ER0'],
  "murmured": ['M ER1 M ER0 D'],
  "murmuring": ['M ER1 M ER0 IH0 NG'],
  "murmurs": ['M ER1 M ER0 Z'],
  "murnan": ['M ER1 N AH0 N'],
  "murnane": ['M ER1 N AH0 N'],
  "murnian": ['M ER1 N IY0 AH0 N'],
  "muro": ['M UH1 R OW0'],
  "murph": ['M ER1 F'],
  "murphey": ['M ER1 F IY0'],
  "murphree": ['M ER0 F R IY1'],
  "murphrey": ['M ER1 F R IY0'],
  "murphy": ['M ER1 F IY0'],
  "murphy's": ['M ER1 F IY0 Z'],
  "murphys": ['M ER1 F IY0 Z'],
  "murr": ['M ER1'],
  "murrah": ['M ER0 R AA1'],
  "murray": ['M ER1 IY0', 'M AH1 R IY0'],
  "murray's": ['M ER1 IY0 Z'],
  "murree": ['M ER1 IY0'],
  "murrelet": ['M ER1 L IH0 T'],
  "murrell": ['M AO1 R AH0 L'],
  "murren": ['M ER1 AH0 N'],
  "murrey": ['M ER1 IY0'],
  "murrie": ['M ER1 IY0'],
  "murrieta": ['M UH0 R IY1 T AH0'],
  "murrill": ['M AO1 R AH0 L'],
  "murrin": ['M AO1 R IH0 N'],
  "murrow": ['M AH1 R OW0'],
  "murry": ['M ER1 IY0'],
  "murtagh": ['M ER1 T AH0 G'],
  "murtaugh": ['M ER1 T AO0'],
  "murtha": ['M ER1 TH AH0'],
  "murthy": ['M ER1 TH IY0'],
  "murto": ['M ER1 T OW2'],
  "murton": ['M ER1 T AH0 N'],
  "murty": ['M ER1 T IY0'],
  "murveit": ['M ER0 V IY1 T'],
  "murzyn": ['M ER1 Z IH0 N'],
  "musa": ['M Y UW1 S AH0'],
  "musacchio": ['M Y UW2 S AE1 K IY0 OW0'],
  "musalo": ['M AH0 S AA1 L OW0'],
  "musante": ['M UW0 S AA1 N T IY0'],
  "musarra": ['M UW0 S AA1 R AH0'],
  "musavi": ['M Y UW0 S AA1 V IY0'],
  "musburger": ['M AH1 S B ER0 G ER0'],
  "muscarella": ['M UW0 S K AA0 R EH1 L AH0'],
  "muscarello": ['M UW0 S K AA0 R EH1 L OW0'],
  "muscat": ['M AH1 S K AE0 T'],
  "muscatine": ['M AH1 S K AH0 T IY2 N'],
  "muscato": ['M UW0 S K AA1 T OW0'],
  "musch": ['M AH1 SH'],
  "muscle": ['M AH1 S AH0 L'],
  "muscled": ['M AH1 S AH0 L D'],
  "muscles": ['M AH1 S AH0 L Z'],
  "muscling": ['M AH1 S AH0 L IH0 NG', 'M AH1 S L IH0 NG'],
  "musco": ['M UW1 S K OW0'],
  "muscocho": ['M AH0 S K AA1 CH OW0'],
  "muscovite": ['M AH1 S K AH0 V AY2 T'],
  "muscovites": ['M AH1 S K AH0 V AY2 T S'],
  "muscular": ['M AH1 S K Y AH0 L ER0'],
  "musculature": ['M AH1 S K Y AH0 L AH0 CH ER0'],
  "muse": ['M Y UW1 Z'],
  "mused": ['M Y UW1 Z D'],
  "musee": ['M Y UW1 Z IY1'],
  "muses": ['M Y UW1 Z AH0 Z', 'M Y UW1 Z IH0 Z'],
  "museum": ['M Y UW0 Z IY1 AH0 M', 'M Y UW1 Z IY0 AH0 M'],
  "museum's": ['M Y UW0 Z IY1 AH0 M Z'],
  "museums": ['M Y UW0 Z IY1 AH0 M Z', 'M Y UW1 Z IY0 AH0 M Z'],
  "musgrave": ['M AH1 S G R EY0 V'],
  "musgrave's": ['M AH1 S G R EY0 V Z'],
  "musgrove": ['M AH1 S G R AH0 V'],
  "mush": ['M AH1 SH'],
  "musha": ['M Y UW1 SH AH0', 'M UW1 SH AH0'],
  "musher": ['M AH1 SH ER0'],
  "mushers": ['M AH1 SH ER0 Z'],
  "mushroom": ['M AH1 SH R UW0 M'],
  "mushroomed": ['M AH1 SH R UH2 M D'],
  "mushrooming": ['M AH1 SH R UH2 M IH0 NG'],
  "mushrooms": ['M AH1 SH R UW0 M Z'],
  "mushrush": ['M AH1 SH R AH0 SH'],
  "mushtaq": ['M AH1 SH T AE0 K'],
  "mushy": ['M AH1 SH IY0'],
  "musial": ['M Y UW1 Z IY0 AH0 L'],
  "music": ['M Y UW1 Z IH0 K'],
  "music's": ['M Y UW1 Z IH0 K S'],
  "musica": ['M Y UW1 Z IH0 K AH0'],
  "musica's": ['M Y UW1 Z IH0 K AH0 Z'],
  "musical": ['M Y UW1 Z IH0 K AH0 L'],
  "musicality": ['M Y UW2 Z IH0 K AE1 L AH0 T IY0'],
  "musically": ['M Y UW1 Z IH0 K L IY0'],
  "musicals": ['M Y UW1 Z IH0 K AH0 L Z'],
  "musich": ['M Y UW1 S IH0 K'],
  "musician": ['M Y UW0 Z IH1 SH AH0 N'],
  "musician's": ['M Y UW0 Z IH1 SH AH0 N Z'],
  "musicians": ['M Y UW0 Z IH1 SH AH0 N Z'],
  "musicians'": ['M Y UW0 Z IH1 SH AH0 N Z'],
  "musicianship": ['M Y UW0 Z IH1 SH AH0 N SH IH0 P'],
  "musick": ['M Y UW1 S IH0 K'],
  "musicland": ['M Y UW1 Z IH0 K L AE2 N D'],
  "musicologist": ['M Y UW2 Z IH0 K AA1 L AH0 JH AH0 S T'],
  "musics": ['M Y UW1 Z IH0 K S'],
  "musidora": ['M UW0 S IY0 D AO1 R AH0'],
  "musil": ['M UW1 Z AH0 L'],
  "musin": ['M Y UW1 S IH0 N'],
  "musing": ['M Y UW1 Z IH0 NG'],
  "musings": ['M Y UW1 Z IH0 NG Z'],
  "musk": ['M AH1 S K'],
  "muska": ['M AH1 S K AH0'],
  "muske": ['M AH1 S K'],
  "muskegon": ['M AH0 S K IY1 G IH0 N'],
  "musket": ['M AH1 S K AH0 T'],
  "musketeer": ['M AH2 S K AH0 T IY1 R'],
  "musketeers": ['M AH2 S K AH0 T IY1 R Z'],
  "muskie": ['M AH1 S K IY0'],
  "muskie's": ['M AH1 S K IY0 Z'],
  "muskmelon": ['M AH1 S K M EH2 L AH0 N'],
  "muskogee": ['M AH0 S K OW1 G IY0'],
  "muskopf": ['M AH1 S K AO0 P F', 'M AH1 S K AO0 F'],
  "muskrat": ['M AH1 S K R AE2 T'],
  "muskrats": ['M AH1 S K R AE2 T Z'],
  "musky": ['M AH1 S K IY0'],
  "muslim": ['M AH1 Z L AH0 M', 'M AH1 Z L IH0 M'],
  "muslims": ['M AH1 Z L AH0 M Z', 'M AH1 Z L IH0 M Z'],
  "muslin": ['M AH1 Z L AH0 N'],
  "musman": ['M AH1 S M AH0 N'],
  "musolf": ['M Y UW1 S OW2 L F'],
  "musolino": ['M UW0 S OW0 L IY1 N OW0'],
  "musschenbroek": ['M AH0 S CH EH1 N B R OW0 K'],
  "mussel": ['M AH1 S AH0 L'],
  "mussell": ['M AH1 S AH0 L'],
  "musselman": ['M AH1 S AH0 L M AH0 N'],
  "mussels": ['M AH1 S AH0 L Z'],
  "musselwhite": ['M AH1 S AH0 L W AY2 T'],
  "musser": ['M AH1 S ER0'],
  "mussman": ['M AH1 S M AH0 N'],
  "musso": ['M UW1 S OW0'],
  "mussolini": ['M UW2 S OW0 L IY1 N IY0'],
  "mussolini's": ['M UW2 S OW0 L IY1 N IY0 Z'],
  "musson": ['M AH1 S AH0 N'],
  "must": ['M AH1 S T'],
  "must've": ['M AH1 S T IH0 V'],
  "mustache": ['M AH1 S T AE2 SH'],
  "mustached": ['M AH1 S T AE2 SH T'],
  "mustaches": ['M AH1 S T AE2 SH IH0 Z'],
  "mustachio": ['M AH0 S T AE1 SH IY0 OW2'],
  "mustachioed": ['M AH0 S T AE1 SH IY0 OW2 D'],
  "mustafa": ['M UW0 S T AA1 F AH0'],
  "mustain": ['M AH0 S T EY1 N'],
  "mustang": ['M AH1 S T AE2 NG'],
  "mustangs": ['M AH1 S T AE2 NG Z'],
  "mustard": ['M AH1 S T ER0 D'],
  "mustards": ['M AH1 S T ER0 D Z'],
  "muster": ['M AH1 S T ER0'],
  "mustered": ['M AH1 S T ER0 D'],
  "mustering": ['M AH1 S T ER0 IH0 NG'],
  "musters": ['M AH1 S T ER0 Z'],
  "mustin": ['M AH1 S T IH0 N'],
  "mustn't": ['M AH1 S AH0 N T'],
  "musto": ['M AH1 S T OW0'],
  "mustoe": ['M AH1 S T OW0'],
  "muston": ['M AH1 S T AH0 N'],
  "musty": ['M AH1 S T IY0'],
  "musumeci": ['M UW0 S UW0 M EH1 CH IY0'],
  "muszynski": ['M AH0 SH IH1 N S K IY0'],
  "mutable": ['M Y UW1 T AH0 B AH0 L'],
  "mutagen": ['M Y UW1 T AH0 JH EH0 N'],
  "mutagens": ['M Y UW1 T AH0 JH EH0 N Z'],
  "mutant": ['M Y UW1 T AH0 N T'],
  "mutants": ['M Y UW1 T AH0 N T S'],
  "mutate": ['M Y UW1 T EY2 T'],
  "mutated": ['M Y UW1 T EY2 T IH0 D'],
  "mutating": ['M Y UW1 T EY0 T IH0 NG'],
  "mutation": ['M Y UW0 T EY1 SH AH0 N'],
  "mutations": ['M Y UW0 T EY1 SH AH0 N Z'],
  "mutch": ['M AH1 CH'],
  "mutchler": ['M AH1 CH L ER0'],
  "mute": ['M Y UW1 T'],
  "muted": ['M Y UW1 T IH0 D'],
  "mutert": ['M Y UW1 T ER0 T'],
  "mutes": ['M Y UW1 T S'],
  "muth": ['M UW1 TH'],
  "muti": ['M UW1 T IY2', 'M Y UW1 T IY0'],
  "mutilate": ['M Y UW1 T AH0 L EY2 T'],
  "mutilated": ['M Y UW1 T AH0 L EY2 T AH0 D', 'M Y UW1 T AH0 L EY2 T IH0 D'],
  "mutilating": ['M Y UW1 T AH0 L EY2 T IH0 NG'],
  "mutilation": ['M Y UW2 T AH0 L EY1 SH AH0 N'],
  "mutilations": ['M Y UW2 T AH0 L EY1 SH AH0 N Z'],
  "mutineer": ['M Y UW2 T AH0 N IH1 R'],
  "mutineers": ['M Y UW2 T IH0 N IH1 R Z'],
  "muting": ['M Y UW1 T IH0 NG'],
  "mutinied": ['M Y UW1 T AH0 N IY0 D'],
  "mutinies": ['M Y UW1 T AH0 N IY0 Z'],
  "mutinous": ['M Y UW1 T AH0 N AH0 S'],
  "mutiny": ['M Y UW1 T AH0 N IY0'],
  "mutlangen": ['M AH0 T L AE1 NG G AH0 N'],
  "mutsch": ['M AH1 CH'],
  "mutschler": ['M AH1 CH L ER0'],
  "mutt": ['M AH1 T'],
  "mutter": ['M AH1 T ER0'],
  "muttered": ['M AH1 T ER0 D'],
  "muttering": ['M AH1 T ER0 IH0 NG'],
  "mutterings": ['M AH1 T ER0 IH0 NG Z'],
  "mutters": ['M AH1 T ER0 Z'],
  "mutton": ['M AH1 T AH0 N'],
  "mutts": ['M AH1 T S'],
  "mutual": ['M Y UW1 CH UW0 AH0 L'],
  "mutual's": ['M Y UW1 CH UW0 AH0 L Z'],
  "mutualism": ['M Y UW1 CH UW0 AH2 L IH2 Z AH0 M'],
  "mutuality": ['M Y UW2 CH UW0 AE1 L IH2 T IY0'],
  "mutually": ['M Y UW1 CH UW0 AH0 L IY0'],
  "mutz": ['M AH1 T S'],
  "mutzich": ['M Y UW1 T S IH0 CH'],
  "mutzman": ['M AH1 T S M AH0 N'],
  "mutzman's": ['M AH1 T S M AH0 N Z'],
  "muzak": ['M Y UW1 Z AE0 K'],
  "muzik": ['M Y UW1 Z IH0 K'],
  "muzio": ['M Y UW1 Z IY0 OW0'],
  "muzyka": ['M Y UW0 Z IH1 K AH0'],
  "muzzey": ['M AH1 Z IY0'],
  "muzzle": ['M AH1 Z AH0 L'],
  "muzzled": ['M AH1 Z AH0 L D'],
  "muzzles": ['M AH1 Z AH0 L Z'],
  "muzzy": ['M AH1 Z IY0'],
  "mwanza": ['M W AA1 N Z AH0'],
  "my": ['M AY1'],
  "myanmar": ['M Y AA1 N M AA1 R'],
  "myanmar's": ['M Y AA1 N M AA1 R Z'],
  "myatt": ['M AY1 AH0 T'],
  "mycenaean": ['M AY2 S AH0 N IY1 AH0 N'],
  "mycogen": ['M AY1 K OW0 G AH0 N'],
  "mycology": ['M AY0 K AA1 L AH0 JH IY0'],
  "myelin": ['M AY1 IH0 L IH0 N'],
  "myer": ['M AY1 ER0'],
  "myers": ['M AY1 ER0 Z'],
  "myers's": ['M AY1 ER0 Z IH0 Z'],
  "myerson": ['M AY1 R S AH0 N'],
  "myette": ['M AY2 EH1 T'],
  "myhand": ['M AY1 HH AE2 N D'],
  "myhre": ['M IH1 R'],
  "myhren": ['M IH1 R AH0 N'],
  "myklebust": ['M IH1 K AH0 L B AH0 S T'],
  "myla": ['M AY1 L AH0'],
  "myla's": ['M AY1 L AH0 Z'],
  "mylan": ['M AY1 L AH0 N'],
  "mylar": ['M AY1 L AA2 R'],
  "myler": ['M AY1 L ER0'],
  "myles": ['M AY1 L Z'],
  "mylod": ['M AY1 L AA0 D'],
  "myna": ['M AY1 N AH0'],
  "mynatt": ['M IH1 N AH0 T', 'M AY1 N AH0 T'],
  "mynhier": ['M IH1 N HH IY0 ER0'],
  "myocardial": ['M AY2 AH0 K AA1 R D IY0 AH0 L'],
  "myocardium": ['M AY2 AH0 K AA1 R D IY0 AH0 M'],
  "myojin": ['M Y OW1 JH IH2 N'],
  "myopia": ['M AY0 OW1 P IY0 AH0'],
  "myopic": ['M AY0 AA1 P IH0 K'],
  "myoscint": ['M Y AO1 S IH0 N T'],
  "myosin": ['M AY1 AH0 S AH0 N'],
  "myotrophin": ['M AY2 OW0 T R OW1 F IH0 N'],
  "myra": ['M AY1 R AH0'],
  "myre": ['M AY1 R'],
  "myren": ['M AY1 R AH0 N'],
  "myreon": ['M AY1 R IY0 AH0 N'],
  "myres": ['M AY1 R Z'],
  "myriad": ['M IH1 R IY0 AH0 D'],
  "myriam": ['M IH1 R IY0 AH0 M'],
  "myrick": ['M AY1 R IH0 K'],
  "myrilla": ['M IH0 R IH1 L AH0'],
  "myrlene": ['M IH0 R L IY1 N'],
  "myrlie": ['M IH1 R L IY0'],
  "myrmidon": ['M ER1 M IH0 D IH0 N'],
  "myrmidons": ['M ER1 M IH0 D IH0 N Z'],
  "myrna": ['M ER1 N AH0'],
  "myron": ['M AY1 R AH0 N'],
  "myrrh": ['M ER1'],
  "myrta": ['M ER1 T AH0'],
  "myrtia": ['M ER1 SH AH0'],
  "myrtice": ['M IH1 R T IH0 S'],
  "myrtis": ['M IH1 R T IH0 S'],
  "myrtle": ['M ER1 T AH0 L'],
  "myrtles": ['M ER1 T AH0 L Z'],
  "myself": ['M AY2 S EH1 L F'],
  "myslinski": ['M IH0 S L IH1 N S K IY0'],
  "mysliwiec": ['M IH0 S L IH1 V IY0 K'],
  "myspace": ['M AY1 S P EY2 S'],
  "mystere": ['M IH1 S T IH2 R'],
  "mysteries": ['M IH1 S T ER0 IY0 Z'],
  "mysterious": ['M IH0 S T IH1 R IY0 AH0 S'],
  "mysteriously": ['M IH2 S T IH1 R IY0 AH0 S L IY0', 'M IH2 S T IH1 R Y AH0 S L IY0'],
  "mysterium": ['M IH2 S T IY1 R IY0 AH0 M'],
  "mystery": ['M IH1 S T ER0 IY0'],
  "mystic": ['M IH1 S T IH0 K'],
  "mystical": ['M IH1 S T IH0 K AH0 L'],
  "mysticism": ['M IH1 S T IH0 S IH2 Z AH0 M'],
  "mystics": ['M IH1 S T IH0 K S'],
  "mystified": ['M IH1 S T AH0 F AY2 D'],
  "mystifies": ['M IH1 S T AH0 F AY2 Z'],
  "mystify": ['M IH1 S T AH0 F AY2'],
  "mystifying": ['M IH1 S T AH0 F AY2 IH0 NG'],
  "mystique": ['M IH0 S T IY1 K'],
  "myszka": ['M IH1 SH K AH0'],
  "myth": ['M IH1 TH'],
  "mythic": ['M IH1 TH IH0 K'],
  "mythical": ['M IH1 TH AH0 K AH0 L', 'M IH1 TH IH0 K AH0 L'],
  "mythological": ['M IH2 TH AH0 L AA1 JH IH0 K AH0 L'],
  "mythology": ['M AH0 TH AA1 L AH0 JH IY2'],
  "myths": ['M IH1 TH S'],
  "myung": ['M Y AH1 NG'],
  "n": ['EH1 N'],
  "n'djamena": ['N D Y AH0 M IY1 N AH0'],
  "n'dour": ['N D AW1 R'],
  "n's": ['EH1 N Z'],
  "n-tuple": ['EH1 N T UW1 P AH0 L'],
  "n-word": ['EH1 N W ER2'],
  "n-words": ['EH1 N W ER1 D Z'],
  "n.": ['EH1 N'],
  "n.'s": ['EH1 N Z'],
  "n.s": ['EH1 N Z'],
  "na": ['N AA1'],
  "naab": ['N AA1 B'],
  "naacp": ['EH2 N EY2 EY2 S IY2 P IY1'],
  "naan": ['N AA1 N'],
  "naas": ['N AA1 Z', 'N EY1 S'],
  "nab": ['N AE1 B'],
  "nabb": ['N AE1 B'],
  "nabbed": ['N AE1 B D'],
  "nabbing": ['N AE1 B IH0 NG'],
  "naber": ['N EY1 B ER0'],
  "nabers": ['N EY1 B ER0 Z'],
  "nabi": ['N AA1 B IY2', 'N EY1 B IY2'],
  "nabil": ['N AA0 B IY1'],
  "nabisco": ['N AH0 B IH1 S K OW2'],
  "nabisco's": ['N AH0 B IH1 S K OW2 Z'],
  "nablus": ['N AE1 B L AH0 S'],
  "nabob": ['N AE1 B AA0 B'],
  "nabobs": ['N AE1 B AA0 B Z'],
  "nabokov": ['N AE1 B AH0 K AA2 V'],
  "nabokov's": ['N AE1 B AH0 K AA2 V Z'],
  "nabors": ['N EY1 B ER0 Z'],
  "nabozny": ['N AH0 B AA1 Z N IY0'],
  "nabretta": ['N AH0 B R EH1 T AH0'],
  "nabs": ['N AE1 B Z'],
  "naccarato": ['N AA0 K ER0 AA1 T OW0'],
  "nacchio": ['N AE1 K IY0 OW2'],
  "nacco": ['N AE1 K OW2'],
  "nace": ['N EY1 S'],
  "nachbar": ['N AE1 K B ER0'],
  "nachman": ['N AE1 K M AH0 N'],
  "nachmany": ['N AE1 K M AH0 N IY2'],
  "nacho": ['N AE1 CH OW2', 'N AA1 CH OW2'],
  "nachos": ['N AA1 CH OW2 Z', 'N AE1 CH OW2 Z'],
  "nachreiner": ['N AE1 K R AY0 N ER0'],
  "nachtigal": ['N AE1 CH T IH0 G AH0 L'],
  "nacional": ['N EY2 S IY1 AH0 N AH0 L'],
  "nack": ['N AE1 K'],
  "nacke": ['N AE1 K'],
  "naclerio": ['N AA0 K L EH1 R IY0 OW2'],
  "nacobre": ['N AE1 K AH0 B R AH0'],
  "nacogdoches": ['N AH0 K AA1 G D AH0 CH IH0 Z'],
  "nad": ['N AE1 D'],
  "nada": ['N AA1 D AA0'],
  "nadal": ['N AA0 D AE1 L'],
  "nadeau": ['N AH0 D OW1'],
  "nadein": ['N AH0 D AY1 N'],
  "nadel": ['N AA0 D EH1 L'],
  "nadell": ['N AA0 D EY1 L'],
  "nader": ['N EY1 D ER0'],
  "nader's": ['N EY1 D ER0 Z'],
  "naderite": ['N EY1 D ER0 AY2 T'],
  "naderites": ['N EY1 D ER0 AY2 T S'],
  "nadgwick": ['N AE1 JH W IH2 K'],
  "nadia": ['N AE1 D Y AH0', 'N AA1 D Y AH0'],
  "nadine": ['N AH0 D IY1 N'],
  "nading": ['N EY1 D IH0 NG'],
  "nadir": ['N EY1 D ER0'],
  "nadja": ['N AE1 D Y AH0'],
  "nadjen": ['N AA1 JH AH0 N'],
  "nadler": ['N EY1 D AH0 L ER0', 'N EY1 D L ER0'],
  "nadolny": ['N AH0 D OW1 L N IY0'],
  "nadolski": ['N AH0 D OW1 L S K IY0'],
  "naef": ['N IY1 F'],
  "naegele": ['N EH1 G AH0 L'],
  "naegeli": ['N EH1 G IH0 L IY0'],
  "naeger": ['N EH1 G ER0'],
  "naeve": ['N IY1 V'],
  "nafco": ['N AE1 F K OW0'],
  "naff": ['N AE1 F'],
  "naffziger": ['N AE1 F Z IH0 G ER0'],
  "nafta": ['N AE1 F T AH0'],
  "nafta's": ['N AE1 F T AH0 Z'],
  "naftalis": ['N AE0 F T AA1 L IH0 S'],
  "nafziger": ['N AE1 F Z IH0 G ER0'],
  "nag": ['N AE1 G'],
  "nagai": ['N AA0 G AA1 IY0'],
  "nagano": ['N AA0 G AA1 N OW0'],
  "nagao": ['N AA0 G AA1 OW0'],
  "nagasaki": ['N AA2 G AA0 S AA1 K IY0'],
  "nagata": ['N AA0 G AA1 T AH0'],
  "nagel": ['N EY1 G AH0 L'],
  "nagele": ['N AE1 G AH0 L'],
  "nagengast": ['N AE1 G IH0 NG G AH0 S T'],
  "nager": ['N EY1 G ER0'],
  "nagged": ['N AE1 G D'],
  "nagging": ['N AE1 G IH0 NG'],
  "nagi": ['N AA1 G IY0'],
  "nagin": ['N EY1 G IH0 N'],
  "nagle": ['N EY1 G AH0 L'],
  "nagler": ['N EY1 G L ER0'],
  "nagorno": ['N AH0 G AO1 R N OW0'],
  "nagorski": ['N AH0 G AO1 R S K IY0'],
  "nagoya": ['N AH0 G OY1 AH0'],
  "nags": ['N AE1 G Z'],
  "nagy": ['N EY1 G IY0'],
  "nagymaros": ['N AE2 JH IH0 M AA1 R OW0 S'],
  "nah": ['N AA1'],
  "nahas": ['N AA1 HH AH0 Z'],
  "nahm": ['N AE1 M', 'N AA1 M'],
  "nahmias": ['N AA1 M IY0 AH0 Z'],
  "nahum": ['N AE1 HH AH0 M'],
  "naidoo": ['N EY1 D UW2'],
  "naify": ['N EY1 F AY2'],
  "naik": ['N EY1 K'],
  "nail": ['N EY1 L'],
  "nailed": ['N EY1 L D'],
  "nailing": ['N EY1 L IH0 NG'],
  "naill": ['N EY1 L'],
  "nailor": ['N EY1 L ER0'],
  "nails": ['N EY1 L Z'],
  "naim": ['N AY1 IH0 M'],
  "naiman": ['N EY1 M AH0 N'],
  "naina": ['N AY1 N AA0'],
  "nair": ['N EH1 R'],
  "naira": ['N EH1 R AH0'],
  "nairn": ['N EH1 R N'],
  "nairne": ['N EH1 R N'],
  "nairobi": ['N AY2 R OW1 B IY0'],
  "naisbitt": ['N EY1 S B IH0 T', 'N EY1 Z B IH0 T'],
  "naish": ['N AA1 IH0 SH', 'N EY1 SH'],
  "naismith": ['N EY1 Z M IH0 TH', 'N EY1 S M IH0 TH'],
  "naito": ['N EY1 T OW0'],
  "naive": ['N AY2 IY1 V'],
  "naively": ['N AA0 IY1 V L IY0'],
  "naivete": ['N AA0 IY2 V AH0 T EY1'],
  "najar": ['N AA0 Y AA1 R'],
  "najarian": ['N AH0 JH EH1 R IY0 AH0 N'],
  "najera": ['N AA0 JH EH1 R AH0'],
  "najib": ['N AH0 JH IY1 B'],
  "najib's": ['N AH0 JH IY1 B Z'],
  "najibullah": ['N AE2 JH IH0 B UH1 L AH0'],
  "najibullah's": ['N AE2 JH IH0 B UH1 L AH0 Z'],
  "najjar": ['N AA0 Y AA1 R'],
  "nakagawa": ['N AA0 K AA0 G AA1 W AH0'],
  "nakahara": ['N AA0 K AA0 HH AA1 R AH0'],
  "nakai": ['N AA0 K AA1 IY0'],
  "nakajima": ['N AA0 K AA0 JH IY1 M AH0'],
  "nakama": ['N AA0 K AA1 M AH0'],
  "nakamoto": ['N AA0 K AA0 M OW1 T OW0'],
  "nakamura": ['N AA0 K AA0 M UH1 R AH0'],
  "nakanishi": ['N AA0 K AA0 N IY1 SH IY0'],
  "nakano": ['N AA0 K AA1 N OW0'],
  "nakao": ['N AA0 K AA1 OW0'],
  "nakashima": ['N AA0 K AA0 SH IY1 M AH0'],
  "nakasone": ['N AA2 K AH0 S OW1 N IY0'],
  "nakasone's": ['N AA2 K AH0 S OW1 N IY0 Z'],
  "nakata": ['N AA0 K AA1 T AH0'],
  "nakatani": ['N AA0 K AA0 T AA1 N IY0'],
  "nakayama": ['N AA0 K AA0 Y AA1 M AH0'],
  "naked": ['N EY1 K AH0 D'],
  "nakhamkin": ['N AE1 K AE2 M K IH0 N'],
  "nalbandian": ['N AE2 L B AE1 N D IY0 AH0 N'],
  "nalbone": ['N AE1 L B OW2 N'],
  "nalcap": ['N AE1 L K AE0 P'],
  "nalco": ['N AE1 L K OW0'],
  "naldo": ['N AA1 L D OW0'],
  "nale": ['N EY1 L'],
  "nalepa": ['N AH0 L IY1 P AH0'],
  "nall": ['N AO1 L'],
  "nalley": ['N AE1 L IY0'],
  "nalls": ['N AO1 L Z'],
  "nally": ['N AE1 L IY0'],
  "nam": ['N AE1 M'],
  "namara": ['N AH0 M AA1 R AH0'],
  "namaste": ['N AA1 M AA0 S T EY2'],
  "nambi": ['N AE1 M B IY0'],
  "nambla": ['N AE1 M B L AH0'],
  "namby-pamby": ['N AE1 M B IY0 P AE1 M B IY0'],
  "name": ['N EY1 M'],
  "name's": ['N EY1 M Z'],
  "named": ['N EY1 M D'],
  "nameless": ['N EY1 M L AH0 S'],
  "namely": ['N EY1 M L IY0'],
  "nameplate": ['N EY1 M P L EY2 T'],
  "nameplates": ['N EY1 M P L EY2 T S'],
  "namer": ['N EY1 M ER0'],
  "names": ['N EY1 M Z'],
  "namesake": ['N EY1 M S EY2 K'],
  "namesakes": ['N EY1 M S EY2 K S'],
  "namibia": ['N AH0 M IH1 B IY0 AH0'],
  "namibia's": ['N AH0 M IH1 B IY0 AH0 Z'],
  "namibian": ['N AH0 M IH1 B IY0 AH0 N'],
  "naming": ['N EY1 M IH0 NG'],
  "namis": ['N AE1 M IH0 S'],
  "namphy": ['N AE1 M F IY0'],
  "nan": ['N AE1 N'],
  "nan's": ['N AE1 N Z'],
  "nana": ['N AE1 N AH0'],
  "nancarrow": ['N AE2 NG K AE1 R OW0'],
  "nance": ['N AE1 N S'],
  "nanchang": ['N AE1 N CH AE1 NG'],
  "nancy": ['N AE1 N S IY0'],
  "nancy's": ['N AE1 N S IY0 Z'],
  "nandaime": ['N AE1 N D EY2 M'],
  "nandi": ['N AA1 N D IY0'],
  "nandita": ['N AA0 N D IY1 T AH0'],
  "nando": ['N AE1 N D OW0'],
  "nandonet": ['N AE1 N D OW0 N EH0 T'],
  "nanette": ['N AH0 N EH1 T'],
  "nanez": ['N AA0 N EH1 Z'],
  "nang": ['N AE1 NG'],
  "nangaku": ['N AE2 NG G AA1 K UW2'],
  "nangle": ['N AE1 NG G AH0 L'],
  "nania": ['N AA1 N IY0 AH0'],
  "nanjing": ['N AE1 N JH IH0 NG'],
  "nankai": ['N AE0 NG K AY1'],
  "nankervis": ['N AE1 NG K ER0 V IH0 S'],
  "nanking": ['N AE1 NG K IH0 NG'],
  "nanna": ['N AE1 N AH0'],
  "nanney": ['N AE1 N IY0'],
  "nanni": ['N AE1 N IY0'],
  "nannies": ['N AE1 N IY0 Z'],
  "nanninga": ['N AA0 N IY1 NG G AH0'],
  "nannini": ['N AA0 N IY1 N IY0'],
  "nanny": ['N AE1 N IY0'],
  "nano": ['N AA1 N OW0', 'N AE1 N OW0'],
  "nanobaby": ['N AE1 N OW0 B EY2 B IY0'],
  "nanogram": ['N AA1 N OW0 G R AE0 M', 'N AE1 N OW0 G R AE0 M'],
  "nanograms": ['N AE1 N OW0 G R AE0 M Z'],
  "nanometer": ['N AE1 N OW0 M IY2 T ER0'],
  "nanometers": ['N AE1 N OW0 M IY2 T ER0 Z'],
  "nanos": ['N AA1 N OW0 Z', 'N AE1 N OW0 Z'],
  "nanosecond": ['N AE1 N OW0 S EH2 K AH0 N D'],
  "nanoseconds": ['N AE1 N OW0 S EH2 K AH0 N D Z'],
  "nanotechnologies": ['N AE2 N OW0 T EH2 K N AA1 L AH0 JH IY2 Z'],
  "nanotechnology": ['N AE2 N OW0 T EH2 K N AA1 L AH0 JH IY2'],
  "nantes": ['N AE1 N T S'],
  "nantucket": ['N AE0 N T AH1 K IH0 T'],
  "nantucket's": ['N AE2 N T AH1 K IH0 T S'],
  "nantz": ['N AE1 N T S'],
  "naoki": ['N EY0 OW1 K IY0'],
  "naoma": ['N AA0 OW1 M AH0'],
  "naomi": ['N EY0 OW1 M IY0'],
  "nap": ['N AE1 P'],
  "napa": ['N AE1 P AH0'],
  "napalm": ['N EY1 P AA0 M'],
  "napco": ['N AE1 P K OW0'],
  "napea": ['N AA1 P IY0 AH0'],
  "naperville": ['N EY1 P ER0 V IH2 L'],
  "naphtha": ['N AE1 F TH AH0'],
  "naphthalene": ['N AE1 F TH AH0 L IY2 N'],
  "napier": ['N EY1 P IY0 ER0'],
  "napierala": ['N AA0 P IH0 R AA1 L AH0'],
  "napkin": ['N AE1 P K IH0 N'],
  "napkins": ['N AE1 P K IH0 N Z'],
  "naples": ['N EY1 P AH0 L Z'],
  "napoleon": ['N AH0 P OW1 L IY0 AH0 N', 'N AH0 P OW1 L Y AH0 N'],
  "napoleon's": ['N AH0 P OW1 L IY0 AH0 N Z'],
  "napoleonic": ['N AH0 P OW2 L IY0 AA1 N IH0 K'],
  "napoles": ['N AE1 P AH0 L Z'],
  "napoletano": ['N AA0 P OW2 L EH0 T AA1 N OW0'],
  "napoli": ['N AE1 P AH0 L IY0'],
  "napolitan": ['N AA0 P OW2 L IY0 T AA1 N'],
  "napolitano": ['N AA0 P OW2 L IY0 T AA1 N OW0'],
  "napora": ['N AA0 P AO1 R AH0'],
  "napp": ['N AE1 P'],
  "nappa": ['N AE1 P AH0'],
  "napped": ['N AE1 P T'],
  "napper": ['N AE1 P ER0'],
  "nappi": ['N AE1 P IY0'],
  "nappier": ['N AE1 P IY0 ER0'],
  "napping": ['N AE1 P IH0 NG'],
  "nappo": ['N AE1 P OW0'],
  "naprosyn": ['N AE1 P R AH0 S IH0 N'],
  "naps": ['N AE1 P S'],
  "napster": ['N AE1 P S T ER0'],
  "naquin": ['N AE1 K W IH0 N'],
  "naqvi": ['N AE1 K V IY0', 'N AA1 K V IY0'],
  "nara": ['N AA1 R AH0'],
  "naral": ['N AA1 R AA0 L'],
  "naramore": ['N AA0 R AA1 M AO0 R'],
  "naranja": ['N AA0 R AA1 N Y AH0'],
  "naranjo": ['N AA0 R AA1 N Y OW0'],
  "narasimha": ['N AA2 R AH0 S IH1 M HH AA2'],
  "narasimhan": ['N AA2 R AH0 S IH1 M HH AA2 N'],
  "narayan": ['N AA0 R AA1 Y AA0 N'],
  "narayanan": ['N AA2 R AY0 AA1 N AH0 N'],
  "narazaki": ['N AA2 R AA0 Z AA1 K IY0'],
  "narc": ['N AA1 R K'],
  "narciso": ['N AA0 R CH IY1 S OW0'],
  "narcisse": ['N AA1 R S IH0 S'],
  "narcissism": ['N AA1 R S IH0 S IH2 Z AH0 M'],
  "narcissist": ['N AA1 R S IH0 S IH0 S T'],
  "narcissistic": ['N AA2 R S IH0 S IH1 S T IH0 K'],
  "narcissus": ['N AA0 R S IH1 S AH0 S'],
  "narco": ['N AA1 R K OW0'],
  "narcolepsy": ['N AA1 R K AH0 L EH2 P S IY0'],
  "narcos": ['N AA1 R K OW0 S'],
  "narcotic": ['N AA0 R K AA1 T IH0 K'],
  "narcotics": ['N AA0 R K AA1 T IH0 K S'],
  "nard": ['N AA1 R D'],
  "narda": ['N AA1 R D AH0'],
  "nardella": ['N AA2 R D EH1 L AH0'],
  "nardelli": ['N AA0 R D EH1 L IY0'],
  "nardi": ['N AA1 R D IY0'],
  "nardiello": ['N AA0 R D IY0 EH1 L OW0'],
  "nardini": ['N AA0 R D IY1 N IY0'],
  "nardo": ['N AA1 R D OW0'],
  "nardone": ['N AA0 R D OW1 N IY0'],
  "nardozzi": ['N AA0 R D OW1 T S IY0'],
  "narducci": ['N AA0 R D UW1 CH IY0'],
  "nares": ['N AE1 R Z'],
  "narita": ['N EH0 R IY1 T AH0'],
  "narjes": ['N AA1 R Y AH0 S'],
  "narlikar": ['N AA1 R L IH0 K AA2 R'],
  "narlock": ['N AA1 R L AH0 K'],
  "narly": ['N AA1 R L IY0'],
  "narnia": ['N AA1 R N IY2 AA0'],
  "naro": ['N AA1 R OW0'],
  "narodny": ['N ER0 AA1 D N IY0'],
  "naron": ['N AA0 R AO1 N'],
  "narragansett": ['N EH2 R AH0 G AE1 N S AH0 T'],
  "narramore": ['N AA0 R AA1 M AO0 R'],
  "narrate": ['N EH1 R EY2 T'],
  "narrated": ['N EH1 R EY2 T IH0 D'],
  "narrates": ['N EH0 R EY1 T S', 'N EH1 R EY0 T S'],
  "narration": ['N EH0 R EY1 SH AH0 N'],
  "narrative": ['N AE1 R AH0 T IH0 V', 'N EH1 R AH0 T IH0 V'],
  "narratives": ['N AE1 R AH0 T IH0 V Z', 'N EH1 R AH0 T IH0 V Z'],
  "narrator": ['N EH1 R EY0 T ER0'],
  "narrators": ['N EH1 R EY2 T ER0 Z'],
  "narron": ['N AE1 R AH0 N'],
  "narrow": ['N EH1 R OW0', 'N AE1 R OW0'],
  "narrowed": ['N EH1 R OW0 D'],
  "narrower": ['N EH1 R OW0 ER0'],
  "narrowest": ['N EH1 R OW0 AH0 S T'],
  "narrowing": ['N EH1 R OW0 IH0 NG'],
  "narrowly": ['N EH1 R OW0 L IY0'],
  "narrowness": ['N EH1 R OW0 N AH0 S'],
  "narrows": ['N EH1 R OW0 Z'],
  "nars": ['N AA1 R Z'],
  "naruhito": ['N AA0 R UW0 HH IY1 T OW0'],
  "narum": ['N ER0 AH1 M'],
  "narvaez": ['N AA0 R V AA1 EH0 Z'],
  "narveson": ['N AA1 R V IH0 S AH0 N'],
  "nary": ['N EH1 R IY0'],
  "nasa": ['N AE1 S AH0'],
  "nasa's": ['N AE1 S AH0 Z'],
  "nasal": ['N EY1 Z AH0 L'],
  "nasally": ['N EY1 Z AH0 L IY0'],
  "nasby": ['N AE1 S B IY0'],
  "nasca": ['N AA1 S K AH0'],
  "nascar": ['N AE1 S K AA2 R'],
  "nascent": ['N EY1 S AH0 N T'],
  "nascimento": ['N AE2 S IH0 M EH1 N T OW0'],
  "nasco": ['N AE1 S K OW0'],
  "nasdaq": ['N AE1 Z D AE0 K'],
  "nasdaq's": ['N AE1 Z D AE0 K S'],
  "nase": ['N EY1 Z'],
  "naser": ['N EY1 Z ER0'],
  "nash": ['N AE1 SH'],
  "nash's": ['N AE1 SH IH0 Z'],
  "nashashibi": ['N AE2 SH AH0 SH IY1 B IY0'],
  "nashburg": ['N AE1 SH B ER0 G'],
  "nashburg's": ['N AE1 SH B ER0 G Z'],
  "nashnamie": ['N AE1 SH N AH0 M IY0'],
  "nashua": ['N AE1 SH UW0 AH0'],
  "nashua's": ['N AE1 SH UW0 AH0 Z'],
  "nashville": ['N AE1 SH V IH0 L'],
  "nashville's": ['N AE1 SH V IH2 L Z'],
  "nasional": ['N AE2 S IY0 AH0 N AE1 L'],
  "naslund": ['N AE1 S L AH0 N D'],
  "naso": ['N AA1 S OW0'],
  "nason": ['N AE1 S AH0 N'],
  "nasons": ['N AE1 S AH0 N Z'],
  "nasopharynx": ['N AH0 S AA1 F ER0 IH0 NG K S'],
  "nasr": ['N AA1 S ER0'],
  "nasrullah": ['N AH0 Z R UW1 L AH0'],
  "nass": ['N AE1 S'],
  "nassar": ['N AE1 S ER0'],
  "nassau": ['N AE1 S AO0'],
  "nasser": ['N AE1 S ER0'],
  "nassif": ['N AE1 S IH0 F'],
  "nast": ['N AE1 S T'],
  "nast's": ['N AE1 S T S'],
  "nasta": ['N AE1 S T AH0'],
  "nastasi": ['N AA0 S T AA1 S IY0'],
  "nastier": ['N AE1 S T IY0 ER0'],
  "nastiest": ['N AE1 S T IY0 AH0 S T'],
  "nastiness": ['N AE1 S T IY0 N AH0 S'],
  "nasty": ['N AE1 S T IY0'],
  "nasworthy": ['N AE1 S W ER0 DH IY0'],
  "nat": ['N AE1 T'],
  "nata": ['N AA1 T AH0'],
  "natal": ['N EY1 T AH0 L', 'N AH0 T AA1 L'],
  "natala": ['N AA0 T AA1 L AH0'],
  "natale": ['N AA0 T AA1 L IY0'],
  "natali": ['N AA0 T AA1 L IY0'],
  "natalia": ['N AH0 T AA1 L Y AH0'],
  "natalie": ['N AE1 T AH0 L IY0'],
  "natalle": ['N AH0 T AA1 L EY0'],
  "natalle's": ['N AH0 T AA1 L EY0 Z'],
  "natan": ['N EY1 T AH0 N'],
  "natasha": ['N AH0 T AA1 SH AH0', 'N AH0 T AE1 SH AH0'],
  "natasha's": ['N AH0 T AA1 SH AH0 Z', 'N AH0 T AE1 SH AH0 Z'],
  "natcher": ['N AE1 CH ER0'],
  "natchez": ['N AE1 CH EH2 Z'],
  "natchez'": ['N AE1 CH EH2 Z'],
  "natchez's": ['N AE1 CH EH2 Z IH0 Z'],
  "natchitoches": ['N AE0 K AH0 T AO1 SH'],
  "nate": ['N EY1 T'],
  "nate's": ['N EY1 T S'],
  "natec's": ['N EY1 T EH2 K S'],
  "nath": ['N AE1 TH'],
  "nathalia": ['N AH0 TH AE1 L IY0 AH0'],
  "nathalie": ['N AE1 T AH0 L IY0'],
  "nathan": ['N EY1 TH AH0 N'],
  "nathan's": ['N EY1 TH AH0 N Z'],
  "nathanael": ['N AE1 TH AH0 N EY2 L'],
  "nathania": ['N AH0 TH AE1 N IY0 AH0'],
  "nathaniel": ['N AH0 TH AE1 N Y AH0 L'],
  "nathanson": ['N AE1 TH AH0 N S AH0 N'],
  "nathe": ['N EY1 DH'],
  "nathene": ['N AH0 TH IY1 N'],
  "natick": ['N EY1 T IH0 K'],
  "nation": ['N EY1 SH AH0 N'],
  "nation's": ['N EY1 SH AH0 N Z'],
  "nationair": ['N EY1 SH AH0 N EH1 R'],
  "national": ['N AE1 SH AH0 N AH0 L', 'N AE1 SH N AH0 L'],
  "national's": ['N AE1 SH AH0 N AH0 L Z', 'N AE1 SH N AH0 L Z'],
  "nationale": ['N AE1 SH AH0 N AE2 L EY0', 'N AE1 SH AH0 N AH0 L'],
  "nationales": ['N AE2 SH AH0 N AA1 L EH0 S'],
  "nationalism": ['N AE1 SH AH0 N AH0 L IH2 Z AH0 M'],
  "nationalist": ['N AE1 SH AH0 N AH0 L AH0 S T', 'N AE1 SH AH0 N AH0 L IH0 S T', 'N AE1 SH N AH0 L AH0 S T', 'N AE1 SH N AH0 L IH0 S T'],
  "nationalistic": ['N AE2 SH AH0 N AH0 L IH1 S T IH0 K', 'N AE2 SH N AH0 L IH1 S T IH0 K'],
  "nationalists": ['N AE1 SH AH0 N AH0 L IH0 S T S', 'N AE1 SH N AH0 L IH0 S T S', 'N AE1 SH AH0 N AH0 L IH0 S', 'N AE1 SH N AH0 L IH0 S'],
  "nationalities": ['N AE2 SH AH0 N AE1 L IH0 T IY0 Z'],
  "nationality": ['N AE2 SH AH0 N AE1 L AH0 T IY0', 'N AE2 SH AH0 N AE1 L IH0 T IY0'],
  "nationalization": ['N AE2 SH AH0 N AH0 L AH0 Z EY1 SH AH0 N', 'N AE2 SH N AH0 L AH0 Z EY1 SH AH0 N'],
  "nationalizations": ['N AE2 SH AH0 N AH0 L AH0 Z EY1 SH AH0 N Z', 'N AE2 SH N AH0 L AH0 Z EY1 SH AH0 N Z'],
  "nationalize": ['N AE1 SH AH0 N AH0 L AY2 Z', 'N AE1 SH N AH0 L AY2 Z'],
  "nationalized": ['N AE1 SH AH0 N AH0 L AY2 Z D', 'N AE1 SH N AH0 L AY2 Z D'],
  "nationalizes": ['N AE1 SH AH0 N AH0 L AY2 Z IH0 Z', 'N AE1 SH N AH0 L AY2 Z IH0 Z'],
  "nationalizing": ['N AE1 SH AH0 N AH0 L AY2 Z IH0 NG', 'N AE1 SH N AH0 L AY2 Z IH0 NG'],
  "nationally": ['N AE1 SH AH0 N AH0 L IY0', 'N AE1 SH N AH0 L IY0'],
  "nationals": ['N AE1 SH AH0 N AH0 L Z', 'N AE1 SH N AH0 L Z'],
  "nationhood": ['N EY1 SH AH0 N HH UH2 D'],
  "nations": ['N EY1 SH AH0 N Z'],
  "nations'": ['N EY1 SH AH0 N Z'],
  "nationsbanc": ['N EY1 SH AH0 N Z B AE2 NG K', 'N EY1 SH AH0 N Z B AA2 NG K'],
  "nationsbanc's": ['N EY1 SH AH0 N Z B AE2 NG K S', 'N EY1 SH AH0 N Z B AA2 NG K S'],
  "nationsbank": ['N EY1 SH AH0 N Z B AE2 NG K'],
  "nationsbank's": ['N EY1 SH AH0 N Z B AE2 NG K S'],
  "nationwide": ['N EY1 SH AH0 N W AY1 D'],
  "nationwide's": ['N EY1 SH AH0 N W AY1 D Z'],
  "native": ['N EY1 T IH0 V'],
  "natively": ['N EY1 T IH0 V L IY0'],
  "natives": ['N EY1 T IH0 V Z'],
  "natividad": ['N AH2 T IH0 V IH0 D AA1 D'],
  "nativism": ['N EY1 T IH0 V IH2 Z AH0 M'],
  "nativist": ['N EY1 T IH0 V IH2 S T'],
  "nativity": ['N AH0 T IH1 V AH0 T IY0'],
  "nato": ['N EY1 T OW0'],
  "nato's": ['N EY1 T OW0 Z'],
  "natoli": ['N AA0 T OW1 L IY0'],
  "natomas": ['N EY2 T OW1 M AH0 Z'],
  "nats": ['N AE1 T S'],
  "natsios": ['N AE1 T S IY0 OW0 S'],
  "natter": ['N AE1 T ER0'],
  "nattering": ['N AE1 T ER0 IH0 NG'],
  "nattie": ['N AE1 T IY0'],
  "nattily": ['N AE1 T AH0 L IY0'],
  "natty": ['N AE1 T IY0'],
  "natuna": ['N AH0 T UW1 N AH0'],
  "natural": ['N AE1 CH ER0 AH0 L', 'N AE1 CH R AH0 L'],
  "natural's": ['N AE1 CH ER0 AH0 L Z', 'N AE1 CH R AH0 L Z'],
  "naturalism": ['N AE1 CH ER0 AH0 L IH2 Z AH0 M', 'N AE1 CH R AH0 L IH2 Z AH0 M'],
  "naturalist": ['N AE1 CH ER0 AH0 L AH0 S T', 'N AE1 CH R AH0 L AH0 S T'],
  "naturalistic": ['N AE2 CH ER0 AH0 L IH1 S T IH0 K', 'N AE2 CH R AH0 L IH1 S T IH0 K'],
  "naturalists": ['N AE1 CH ER0 AH0 L IH0 S T S', 'N AE1 CH R AH0 L IH0 S T S', 'N AE1 CH ER0 AH0 L IH0 S', 'N AE1 CH R AH0 L IH0 S'],
  "naturalization": ['N AE1 CH ER0 AH0 L AH0 Z EY1 SH AH0 N', 'N AE1 CH R AH0 L AH0 Z EY1 SH AH0 N'],
  "naturalize": ['N AE1 CH ER0 AH0 L AY2 Z', 'N AE1 CH R AH0 L AY2 Z'],
  "naturalized": ['N AE1 CH ER0 AH0 L AY2 Z D', 'N AE1 CH R AH0 L AY2 Z D'],
  "naturalizes": ['N AE1 CH ER0 AH0 L AY2 Z IH0 Z', 'N AE1 CH R AH0 L AY2 Z IH0 Z'],
  "naturalizing": ['N AE1 CH ER0 AH0 L AY2 Z IH0 NG', 'N AE1 CH R AH0 L AY2 Z IH0 NG'],
  "naturally": ['N AE1 CH ER0 AH0 L IY0', 'N AE1 CH R AH0 L IY0'],
  "nature": ['N EY1 CH ER0'],
  "nature's": ['N EY1 CH ER0 Z'],
  "natured": ['N EY1 CH ER0 D'],
  "naturedly": ['N EY1 CH ER0 D L IY0'],
  "natures": ['N EY1 CH ER0 Z'],
  "natwest": ['N AE2 T W EH1 S T'],
  "natwest's": ['N AE2 T W EH1 S T S'],
  "natzke": ['N AE1 T S K IY0'],
  "nau": ['N OW1'],
  "nauer": ['N AW1 ER0'],
  "nauert": ['N AW1 ER0 T'],
  "naugatuck": ['N AO1 G AH0 T AH2 K'],
  "naught": ['N AO1 T'],
  "naughton": ['N AO1 T AH0 N'],
  "naughty": ['N AO1 T IY0'],
  "naugle": ['N AO1 G AH0 L'],
  "naugles": ['N AO1 G AH0 L Z'],
  "nault": ['N AO1 L T'],
  "nauman": ['N AW1 M AH0 N'],
  "naumann": ['N AW1 M AH0 N'],
  "naus": ['N AO1 Z'],
  "nausea": ['N AO1 Z IY0 AH0'],
  "nauseam": ['N AW1 Z IY2 M'],
  "nauseate": ['N AO1 Z IY0 EY2 T'],
  "nauseated": ['N AO1 Z IY0 EY2 T AH0 D'],
  "nauseating": ['N AO1 ZH IY0 EY2 T IH0 NG'],
  "nauseous": ['N AO1 SH AH0 S'],
  "nauss": ['N AO1 S'],
  "nauta": ['N AA0 UW1 T AH0'],
  "nautica": ['N AO1 T AH0 K AH0'],
  "nautical": ['N AO1 T AH0 K AH0 L'],
  "nautilus": ['N AO1 T AH0 L AH0 S'],
  "nautilus's": ['N AO1 T AH0 L AH0 S IH0 Z'],
  "nav": ['N AE1 V'],
  "nava": ['N AA1 V AH0'],
  "navajo": ['N AA1 V AH0 HH OW2', 'N AE1 V AH0 HH OW2'],
  "navajos": ['N AA1 V AH0 HH OW2 Z', 'N AE1 V AH0 HH OW2 Z'],
  "naval": ['N EY1 V AH0 L'],
  "navar": ['N AA0 V AA1 R'],
  "navarette": ['N AE1 V ER0 EH2 T'],
  "navarra": ['N AA0 V AA1 R AH0'],
  "navarre": ['N AA0 V AA1 R'],
  "navarrete": ['N AE1 V ER0 IY2 T'],
  "navarrette": ['N AE1 V ER0 EH2 T'],
  "navarro": ['N AH0 V AA1 R OW0'],
  "navas": ['N AA1 V AH0 S'],
  "navcom": ['N AE1 V K AA2 M'],
  "nave": ['N EY1 V'],
  "navel": ['N EY1 V AH0 L'],
  "navellier": ['N AH0 V EH1 L Y ER0'],
  "navickas": ['N AA0 V IY1 K AA0 Z'],
  "navies": ['N EY1 V IY0 Z'],
  "navigable": ['N AE1 V AH0 G AH0 B AH0 L'],
  "navigate": ['N AE1 V AH0 G EY2 T'],
  "navigated": ['N AE1 V AH0 G EY2 T IH0 D'],
  "navigates": ['N AE1 V AH0 G EY2 T S'],
  "navigating": ['N AE1 V AH0 G EY2 T IH0 NG'],
  "navigation": ['N AE1 V AH0 G EY1 SH AH0 N', 'N AE2 V AH0 G EY1 SH AH0 N'],
  "navigational": ['N AE2 V AH0 G EY1 SH AH0 N AH0 L'],
  "navigator": ['N AE1 V AH0 G EY2 T ER0'],
  "navigators": ['N AE1 V AH0 G EY2 T ER0 Z'],
  "navin": ['N AA0 V IY1 N'],
  "navin-chandr": ['N AA1 V IH2 N CH AA1 N D ER0'],
  "navin-chandra": ['N AA1 V IH2 N CH AA1 N D R AH0'],
  "navis": ['N AA1 V IH0 S'],
  "navistar": ['N AE1 V IH0 S T AA2 R'],
  "navlab": ['N AE1 V L AE0 B'],
  "navratilova": ['N AE0 V R AE2 T IH0 L OW1 V AH0'],
  "navratilova's": ['N AE0 V R AE2 T IH0 L OW1 V AH0 Z'],
  "navstar": ['N AE1 V S T AA2 R'],
  "navy": ['N EY1 V IY0'],
  "navy's": ['N EY1 V IY0 Z'],
  "naw": ['N AA1'],
  "nawrocki": ['N AA0 V R OW1 T S K IY0'],
  "nawrot": ['N AO1 R AH0 T'],
  "nay": ['N EY1'],
  "nayden": ['N EY1 D IH0 N'],
  "naylor": ['N EY1 L ER0'],
  "nays": ['N EY1 Z'],
  "naysayer": ['N EY2 S EY1 ER0'],
  "naysayers": ['N EY2 S EY1 ER0 Z'],
  "nayyar": ['N EY1 Y AA2 R'],
  "nazar": ['N AA0 Z AA1 R'],
  "nazarbayev": ['N AA2 Z AH0 R B AY1 Y EH0 V'],
  "nazareth": ['N AE1 Z AH0 R IH0 TH'],
  "nazarian": ['N AH0 Z EH1 R IY0 AH0 N'],
  "nazario": ['N AA0 Z AA1 R IY0 OW0'],
  "nazer": ['N EY1 Z ER0'],
  "nazer's": ['N EY1 Z ER0 Z'],
  "nazi": ['N AA1 T S IY0'],
  "nazi's": ['N AA1 T S IY0 Z'],
  "nazionale": ['N AA0 T S IY0 OW0 N AA1 L IY0'],
  "nazis": ['N AA1 T S IY0 Z'],
  "nazis'": ['N AA1 T S IY0 Z'],
  "nazism": ['N AE1 Z IH0 Z AH0 M'],
  "nazzaro": ['N AA0 T S AA1 R OW0'],
  "nba": ['EH1 N B IY2 EY1'],
  "nbc": ['EH1 N B IY2 S IY1'],
  "nbc's": ['EH1 N B IY2 S IY1 Z'],
  "ndau": ['EH0 N D AW1'],
  "ne": ['N IY1', 'N EY1'],
  "ne'er": ['N EH1 R'],
  "neace": ['N IY1 S'],
  "nead": ['N IY1 D'],
  "neagle": ['N IY1 G AH0 L'],
  "neal": ['N IY1 L'],
  "neal's": ['N IY1 L Z'],
  "neala": ['N IY1 L AH0'],
  "neale": ['N IY1 L'],
  "nealey": ['N IY1 L IY0'],
  "nealis": ['N IY1 L IH0 S'],
  "neall": ['N IY1 L'],
  "nealon": ['N IY1 L AH0 N'],
  "nealson": ['N IY1 L S AH0 N'],
  "nealy": ['N IY1 L IY0'],
  "neanderthal": ['N IY0 AE1 N D ER0 TH AO2 L'],
  "neanderthals": ['N IY0 AE1 N D ER0 TH AO2 L Z'],
  "neapolitan": ['N IY2 AH0 P AA1 L AH0 T AH0 N'],
  "near": ['N IH1 R'],
  "nearby": ['N IH1 R B AY1'],
  "neared": ['N IH1 R D'],
  "nearer": ['N IH1 R ER0'],
  "nearest": ['N IH1 R AH0 S T'],
  "nearhood": ['N IH1 R HH UH0 D'],
  "nearing": ['N IH1 R IH0 NG'],
  "nearly": ['N IH1 R L IY0'],
  "nears": ['N IH1 R Z'],
  "nearsighted": ['N IY1 R S AY2 T IH0 D'],
  "nearsightedness": ['N IY1 R S AY2 T IH0 D N AH0 S'],
  "neary": ['N IH1 R IY0'],
  "neas": ['N IY1 Z'],
  "nease": ['N IY1 Z'],
  "neat": ['N IY1 T'],
  "neater": ['N IY1 T ER0'],
  "neatest": ['N IY1 T AH0 S T'],
  "neathery": ['N EH1 TH ER0 IY0'],
  "neatly": ['N IY1 T L IY0'],
  "neatness": ['N IY1 T N AH0 S'],
  "neault": ['N OW1'],
  "neave": ['N IY1 V'],
  "neaves": ['N IY1 V Z'],
  "nebeker": ['N EH1 B IH0 K ER0'],
  "nebel": ['N EH1 B AH0 L'],
  "nebergall": ['N IY1 B ER0 G AH0 L'],
  "neblett": ['N EH1 B L IH0 T'],
  "nebraska": ['N AH0 B R AE1 S K AH0'],
  "nebraska's": ['N AH0 B R AE1 S K AH0 Z'],
  "nebraskan": ['N AH0 B R AE1 S K AH0 N'],
  "nebraskans": ['N AH0 B R AE1 S K AH0 N Z'],
  "nebula": ['N EH1 B Y AH0 L AH0'],
  "nebulizer": ['N EH1 B Y AH0 L AY2 Z ER0'],
  "nebulous": ['N EH1 B Y AH0 L AH0 S'],
  "nec": ['N EH1 K'],
  "necaise": ['N EH1 K AY0 S'],
  "necci": ['N EH1 CH IY0'],
  "necessarily": ['N EH2 S AH0 S EH1 R AH0 L IY0'],
  "necessary": ['N EH1 S AH0 S EH2 R IY0'],
  "necessitate": ['N AH0 S EH1 S AH0 T EY2 T'],
  "necessitated": ['N AH0 S EH1 S AH0 T EY2 T AH0 D'],
  "necessitates": ['N AH0 S EH1 S AH0 T EY2 T S'],
  "necessitating": ['N AH0 S EH1 S IH0 T EY2 T IH0 NG'],
  "necessities": ['N AH0 S EH1 S IH0 T IY0 Z'],
  "necessity": ['N AH0 S EH1 S AH0 T IY0', 'N AH0 S EH1 S IH0 T IY0'],
  "nechayev": ['N EH2 CH AY1 EH0 V'],
  "nechyba": ['N EH2 CH IY1 B AH0'],
  "neck": ['N EH1 K'],
  "necked": ['N EH1 K T'],
  "necker": ['N EH1 K ER0'],
  "necklace": ['N EH1 K L AH0 S'],
  "necklaces": ['N EH1 K L AH0 S IH0 Z'],
  "necklacing": ['N EH1 K L AH0 S IH0 NG'],
  "necks": ['N EH1 K S'],
  "necktie": ['N EH1 K T AY2'],
  "neckties": ['N EH1 K T AY2 Z'],
  "neckwear": ['N EH1 K W EH2 R'],
  "necmettin": ['N EH2 JH M EH1 T IH0 N'],
  "neco": ['N IY1 K OW0'],
  "neco's": ['N IY1 K OW0 Z'],
  "necrologist": ['N AH0 K R AO1 L AH0 JH IH0 S T'],
  "necrologists": ['N AH0 K R AO1 L AH0 JH IH0 S T S', 'N AH0 K R AO1 L AH0 JH IH0 S'],
  "necrology": ['N AH0 K R AO1 L AH0 JH IY0'],
  "necromancer": ['N EH1 K R AH0 M AE2 N S ER0'],
  "necromancy": ['N EH1 K R AH0 M AE2 N S IY0'],
  "necropolis": ['N AH0 K R AA1 P AH0 L AH0 S'],
  "necrosis": ['N AH0 K R OW1 S AH0 S'],
  "nectar": ['N EH1 K T ER0'],
  "necula": ['N EH1 K Y UW0 L AH0'],
  "ned": ['N EH1 D'],
  "ned's": ['N EH1 D Z'],
  "neda": ['N EY1 D AH0'],
  "nedd": ['N EH1 D'],
  "nedda": ['N EH1 D AH0'],
  "neddick": ['N EH1 D IH0 K'],
  "neddo": ['N EH1 D OW0'],
  "nedeau": ['N IH0 D OW1'],
  "nedel": ['N IY1 D AH0 L'],
  "nederland": ['N EH1 D ER0 L AH0 N D'],
  "nederlanden": ['N EH1 D ER0 L AE2 N D AH0 N'],
  "nederlander": ['N EH1 D ER0 L AE2 N D ER0'],
  "nederlandsche": ['N EH2 D ER0 L AE1 N D SH IY0'],
  "nederlandse": ['N EH2 D ER0 L AE1 N D S IY0'],
  "nedlloyd": ['N EH1 D AH0 L OY2 D', 'N EH1 D L OY2 D'],
  "nedrow": ['N EH1 D R OW0'],
  "nedved": ['N EH1 D V AH0 D', 'N EH1 D V EH2 D'],
  "nee": ['N IY1'],
  "neeb": ['N IY1 B'],
  "neece": ['N IY1 S'],
  "neeco": ['N IY1 K OW0'],
  "need": ['N IY1 D'],
  "needed": ['N IY1 D AH0 D', 'N IY1 D IH0 D'],
  "needelman": ['N IY1 D AH0 L M AH0 N'],
  "needful": ['N IY1 D F AH0 L'],
  "needham": ['N IY1 D AH0 M'],
  "needham's": ['N IY1 D AH0 M Z'],
  "needier": ['N IY1 D IY0 ER0'],
  "neediest": ['N IY1 D IY0 IH0 S T'],
  "needing": ['N IY1 D IH0 NG'],
  "needle": ['N IY1 D AH0 L'],
  "needled": ['N IY1 D AH0 L D'],
  "needlefish": ['N IY1 D AH0 L F IH2 SH'],
  "needlelike": ['N IY1 D AH0 L L AY2 K'],
  "needleman": ['N IY1 D AH0 L M AH0 N'],
  "needlepoint": ['N IY1 D AH0 L P OY2 N T'],
  "needler": ['N IY1 D AH0 L ER0', 'N IY1 D L ER0'],
  "needles": ['N IY1 D AH0 L Z'],
  "needless": ['N IY1 D L AH0 S'],
  "needlessly": ['N IY1 D L AH0 S L IY0'],
  "needlework": ['N IY1 D AH0 L W ER2 K'],
  "needling": ['N IY1 D L IH0 NG'],
  "needn't": ['N IY1 D AH0 N T'],
  "needs": ['N IY1 D Z'],
  "needy": ['N IY1 D IY0'],
  "neef": ['N IY1 F'],
  "neel": ['N IY1 L'],
  "neeld": ['N IY1 L D'],
  "neeley": ['N IY1 L IY0'],
  "neels": ['N IY1 L Z'],
  "neely": ['N IY1 L IY0'],
  "neeman": ['N IY1 M AH0 N'],
  "neenan": ['N IY1 N AH0 N'],
  "neeper": ['N IY1 P ER0'],
  "neer": ['N IH1 R'],
  "nees": ['N IY1 Z'],
  "neese": ['N IY1 Z'],
  "neeson": ['N IY1 S AH0 N'],
  "neet": ['N IY1 T'],
  "nefarious": ['N AH0 F EH1 R IY0 AH0 S'],
  "neff": ['N EH1 F'],
  "neft": ['N EH1 F T'],
  "neftegorsk": ['N EH1 F T IH0 G AO2 R S K'],
  "neg": ['N EH1 G'],
  "negara": ['N EH0 G AA1 R AH0'],
  "negate": ['N IH0 G EY1 T'],
  "negated": ['N IY1 G EY0 T IH0 D', 'N IH0 G EY1 T AH0 D'],
  "negates": ['N IH0 G EY1 T S'],
  "negating": ['N IH0 G EY1 T IH0 NG'],
  "negation": ['N AH0 G EY1 SH AH0 N'],
  "negative": ['N EH1 G AH0 T IH0 V'],
  "negatively": ['N EH1 G AH0 T IH0 V L IY0'],
  "negatives": ['N EH1 G AH0 T IH0 V Z'],
  "negativism": ['N EH1 G AH0 T IH0 V IH2 Z AH0 M'],
  "negativity": ['N EH2 G AH0 T IH1 V AH0 T IY0'],
  "negatron": ['N EH1 G AH0 T R AA0 N'],
  "negatrons": ['N EH1 G AH0 T R AA0 N Z'],
  "negent": ['N EH1 G AH0 N T', 'N EH1 JH AH0 N T'],
  "negev": ['N EH1 G EH2 V'],
  "neglect": ['N AH0 G L EH1 K T', 'N IH0 G L EH1 K T'],
  "neglected": ['N AH0 G L EH1 K T AH0 D', 'N IH0 G L EH1 K T IH0 D'],
  "neglectful": ['N IH0 G L EH1 K T F AH0 L'],
  "neglecting": ['N IH0 G L EH1 K T IH0 NG'],
  "neglects": ['N IH0 G L EH1 K T S'],
  "negley": ['N EH1 G L IY0'],
  "neglia": ['N EH1 G L IY0 AH0'],
  "negligence": ['N EH1 G L AH0 JH AH0 N S', 'N EH1 G L IH0 JH AH0 N S'],
  "negligent": ['N EH1 G L AH0 JH AH0 N T', 'N EH1 G L IH0 JH AH0 N T'],
  "negligently": ['N EH1 G L IH0 JH AH0 N T L IY0'],
  "negligible": ['N EH1 G L AH0 JH AH0 B AH0 L', 'N EH1 G L IH0 JH AH0 B AH0 L'],
  "negotiable": ['N AH0 G OW1 SH AH0 B AH0 L'],
  "negotiate": ['N AH0 G OW1 SH IY0 EY2 T', 'N IH0 G OW1 SH IY0 EY2 T'],
  "negotiated": ['N AH0 G OW1 SH IY0 EY2 T AH0 D', 'N IH0 G OW1 SH IY0 EY2 T IH0 D'],
  "negotiates": ['N IH0 G OW1 SH IY0 EY2 T S'],
  "negotiating": ['N IH0 G OW1 SH IY0 EY2 T IH0 NG'],
  "negotiation": ['N IH0 G OW2 SH IY0 EY1 SH AH0 N'],
  "negotiations": ['N AH0 G OW2 SH IY0 EY1 SH AH0 N Z', 'N IH0 G OW2 SH IY0 EY1 SH AH0 N Z'],
  "negotiator": ['N AH0 G OW1 SH IY0 EY2 T ER0', 'N IH0 G OW1 SH IY0 EY2 T ER0'],
  "negotiator's": ['N IH0 G OW1 SH IY0 EY2 T ER0 Z'],
  "negotiators": ['N IH0 G OW1 SH IY0 EY2 T ER0 Z'],
  "negotiators'": ['N AH0 G OW1 SH IY0 EY2 T ER0 Z'],
  "negrete": ['N EH1 G R IY2 T'],
  "negri": ['N EH1 G R IY0'],
  "negrin": ['N EH1 G R IH0 N'],
  "negro": ['N IY1 G R OW0'],
  "negroes": ['N IY1 G R OW0 Z'],
  "negroid": ['N IY1 G R OY0 D'],
  "negron": ['N EH1 G R AH0 N'],
  "negroni": ['N EH2 G R OW1 N IY0'],
  "negropalpus": ['N EH2 G R AH0 P AA1 L P AH0 S'],
  "negros": ['N IY1 G R OW0 Z'],
  "negs": ['N EH1 G Z'],
  "negu": ['N EH1 G UW0'],
  "negus": ['N IY1 G AH0 S'],
  "nehemiah": ['N IY2 AH0 M AY1 AH0'],
  "neher": ['N EY1 ER0'],
  "nehls": ['N EH1 L Z'],
  "nehring": ['N EH1 R IH0 NG'],
  "nehru": ['N EY1 R UW2'],
  "neibauer": ['N AY1 B AW0 ER0'],
  "neiberg": ['N AY1 B ER0 G'],
  "neibert": ['N IY1 B ER0 T'],
  "neice": ['N IY1 S'],
  "neider": ['N IY1 D ER0'],
  "neidhardt": ['N AY1 D HH AA0 R T'],
  "neidhart": ['N AY1 D HH AA0 R T'],
  "neidigh": ['N IY1 D AY2'],
  "neidl": ['N IY1 D AH0 L'],
  "neidlinger": ['N AY1 D AH0 L IH0 NG ER0', 'N IY1 D L IH0 NG ER0'],
  "neier": ['N AY1 ER0'],
  "neifert": ['N IY1 F ER0 T'],
  "neiger": ['N AY1 G ER0'],
  "neigh": ['N EY1'],
  "neighbor": ['N EY1 B ER0'],
  "neighbor's": ['N EY1 B ER0 Z'],
  "neighborhood": ['N EY1 B ER0 HH UH2 D'],
  "neighborhood's": ['N EY1 B ER0 HH UH2 D Z'],
  "neighborhoods": ['N EY1 B ER0 HH UH2 D Z'],
  "neighboring": ['N EY1 B ER0 IH0 NG'],
  "neighborly": ['N EY1 B ER0 L IY0'],
  "neighbors": ['N EY1 B ER0 Z'],
  "neighbors'": ['N EY1 B ER0 Z'],
  "neighbour": ['N EY1 B ER0'],
  "neighbour's": ['N EY1 B ER0 Z'],
  "neighbourhood": ['N EY1 B ER0 HH UH2 D'],
  "neighbourhood's": ['N EY1 B ER0 HH UH2 D Z'],
  "neighbourhoods": ['N EY1 B ER0 HH UH2 D Z'],
  "neighbouring": ['N EY1 B ER0 IH0 NG'],
  "neighbourly": ['N EY1 B ER0 L IY0'],
  "neighbours": ['N EY1 B ER0 Z'],
  "neighbours'": ['N EY1 B ER0 Z'],
  "neighmond": ['N EY1 M AH0 N D'],
  "neighmond's": ['N EY1 M AH0 N D Z'],
  "neikirk": ['N IY1 K ER0 K'],
  "neil": ['N IY1 L'],
  "neil's": ['N IY1 L Z'],
  "neilan": ['N IY1 L AH0 N'],
  "neild": ['N IY1 L D'],
  "neile": ['N IY1 L'],
  "neill": ['N IY1 L'],
  "neils": ['N IY1 L Z'],
  "neilsen": ['N AY1 L S AH0 N'],
  "neilson": ['N IY1 L S AH0 N'],
  "neiman": ['N IY1 M AH0 N'],
  "neimeyer": ['N AY1 M AY0 ER0'],
  "nein": ['N IY1 N'],
  "neira": ['N EH1 R AH0'],
  "neis": ['N IY1 Z'],
  "neisen": ['N AY1 S AH0 N'],
  "neises": ['N IY1 Z IH0 Z'],
  "neisler": ['N AY1 S AH0 L ER0', 'N AY1 S L ER0'],
  "neiss": ['N IY1 S'],
  "neiswender": ['N AY1 S W EH0 N D ER0'],
  "neither": ['N IY1 DH ER0', 'N AY1 DH ER0'],
  "neitz": ['N IY1 T S'],
  "neitzel": ['N AY1 T Z AH0 L'],
  "neitzke": ['N AY1 T S K IY0'],
  "nej": ['N EY1'],
  "nejamatin": ['N EH0 JH AH0 M AE1 T IH0 N'],
  "nekia": ['N AH0 K IY1 AH0'],
  "nekoosa": ['N IH0 K UW1 S AH0'],
  "nekton": ['N EH1 K T AH0 N'],
  "nel": ['N EH1 L'],
  "nela": ['N EH1 L AH0'],
  "nelda": ['N EH1 L D AH0'],
  "nelia": ['N EH1 L IY0 AH0'],
  "nelina": ['N EH0 L IY1 N AH0'],
  "nelita": ['N EH0 L IY1 T AH0'],
  "nell": ['N EH1 L'],
  "nella": ['N EH1 L AH0'],
  "nellcor": ['N EH1 L K AO2 R'],
  "nelle": ['N EH1 L'],
  "neller": ['N EH1 L ER0'],
  "nelles": ['N EH1 L Z'],
  "nellette": ['N EH2 L EH1 T'],
  "nelli": ['N EH1 L IY0'],
  "nelliana": ['N EH2 L IY0 AE1 N AH0'],
  "nellie": ['N EH1 L IY0'],
  "nelligan": ['N EH1 L IH0 G AH0 N'],
  "nellis": ['N EH1 L IH0 S'],
  "nellwyn": ['N EH1 L W IH0 N'],
  "nelly": ['N EH1 L IY0'],
  "nelms": ['N EH1 L M Z'],
  "neloms": ['N EH1 L AH0 M Z'],
  "nelon": ['N EH1 L AH0 N'],
  "nels": ['N EH1 L Z'],
  "nelsen": ['N EH1 L S AH0 N'],
  "nelson": ['N EH1 L S AH0 N'],
  "nelson's": ['N EH1 L S AH0 N Z'],
  "nematode": ['N EH1 M AH0 T OW2 D'],
  "nematodes": ['N EH1 M AH0 T OW2 D Z'],
  "nemean": ['N IY1 M IY0 AH0 N'],
  "nemec": ['N EH1 M IH0 K'],
  "nemecek": ['N EH1 M IH0 S IH0 K'],
  "nemer": ['N IY1 M ER0'],
  "nemeroff": ['N EH1 M ER0 AO0 F'],
  "nemes": ['N IY1 M Z'],
  "nemesis": ['N EH1 M AH0 S IH0 S'],
  "nemeth": ['N EY1 M IH0 TH'],
  "nemetz": ['N EH1 M IH0 T S'],
  "nemir": ['N AH1 M ER0'],
  "nemitz": ['N EH1 M IH0 T S'],
  "nemmers": ['N EH1 M ER0 Z'],
  "nemo": ['N EH1 M OW0'],
  "nemos": ['N IY1 M OW0 Z'],
  "nemours": ['N IH0 M AO1 R Z'],
  "nendick": ['N EH1 N D IH0 K'],
  "nene": ['N IY1 N'],
  "nenet": ['N EH1 N IH0 T'],
  "neni": ['N EH1 N IY0'],
  "neni's": ['N EH1 N IY0 S'],
  "nenninger": ['N EH1 N IH0 NG ER0'],
  "neo": ['N IY1 OW0'],
  "neoax": ['N IY2 OW0 AE1 K S'],
  "neoclassic": ['N IY2 OW0 K L AE1 S IH0 K'],
  "neoclassical": ['N IY2 OW0 K L AE1 S IH0 K AH0 L'],
  "neoconservative": ['N IY2 OW0 K AH0 N S ER1 V AH0 T IH0 V'],
  "neoconservatives": ['N IY2 OW0 K AH0 N S ER1 V AH0 T IH0 V Z'],
  "neola": ['N IY0 AA1 L AH0'],
  "neoliberal": ['N IY2 OW0 L IH1 B ER0 AH0 L'],
  "neoliberals": ['N IY2 OW0 L IH1 B ER0 AH0 L Z'],
  "neolith": ['N IY1 OW0 L IH2 TH'],
  "neolithic": ['N IY1 OW0 L IH2 TH IH0 K'],
  "neoma": ['N EY0 OW1 M AH0'],
  "neon": ['N IY1 AA0 N'],
  "neonatal": ['N IY2 OW0 N EY1 T AH0 L'],
  "neonazi": ['N IY2 OW0 N AA1 T S IY0'],
  "neonazis": ['N IY2 OW0 N AA1 T S IY0 Z'],
  "neons": ['N IY1 AA2 N Z'],
  "neophyte": ['N IY1 AH0 F AY2 T'],
  "neophytes": ['N IY1 AH0 F AY2 T S'],
  "neoplasm": ['N IY1 AH0 P L AE2 Z AH0 M'],
  "neoplatonic": ['N IY2 OW0 P L AH0 T AA1 N IH0 K'],
  "neoplatonist": ['N IY2 OW0 P L EY1 T AH0 N AH0 S T'],
  "neopolis": ['N IY0 AO1 P AH0 L IH0 S'],
  "neopositivism": ['N IY0 OW0 P AO1 Z AH0 T AH0 V IH2 Z AH0 M'],
  "neopositivist": ['N IY0 OW0 P AO1 Z AH0 T AH0 V IH2 S T'],
  "neoprene": ['N IY1 AH0 P R IY2 N', 'N IY1 OW0 P R IY2 N'],
  "neoprobe": ['N IY1 OW0 P R OW2 B'],
  "neorx": ['N IY1 OW0 R EH2 K S'],
  "neotenic": ['N IY2 AH0 T IY1 N IH0 K'],
  "neoteny": ['N IY0 AA1 T AH0 N IY0'],
  "nepa": ['N IY1 P AH0'],
  "nepal": ['N AH0 P AO1 L'],
  "nepalese": ['N EH2 P AH0 L IY1 Z'],
  "nepali": ['N AH0 P AO1 L IY0'],
  "nepenthe": ['N EH2 P EH1 N TH IY0'],
  "nephew": ['N EH1 F Y UW0'],
  "nephew's": ['N EH1 F Y UW0 Z'],
  "nephews": ['N EH1 F Y UW0 Z'],
  "nephridium": ['N AH0 F R IH1 D IY0 AH0 M'],
  "nephrite": ['N EH1 F R AY0 T'],
  "nephron": ['N EH1 F R AA0 N'],
  "nephrosis": ['N AH0 F R OW1 S AH0 S'],
  "nepl": ['N EH1 P AH0 L', 'EH1 N IY1 P IY1 EH1 L'],
  "neponset": ['N AH0 P AA1 N S IH0 T'],
  "nepool": ['N AH0 P UW1 L'],
  "nepotism": ['N EH1 P AH0 T IH2 Z AH0 M'],
  "neptune": ['N EH1 P T UW0 N'],
  "neptunium": ['N EH0 P T UW1 N IY0 AH0 M'],
  "nerco": ['N ER1 K OW0'],
  "nerd": ['N ER1 D'],
  "nerds": ['N ER1 D Z'],
  "nerdy": ['N ER1 D IY0'],
  "nerenberg": ['N IH1 R AH0 N B ER0 G'],
  "neri": ['N EH1 R IY0'],
  "nerice": ['N EH1 R IH0 S'],
  "nerine": ['N EH1 R IY0 N'],
  "nerio": ['N EH1 R IY0 OW0'],
  "neritic": ['N ER0 IH1 T IH0 K'],
  "nerl": ['N ER1 L'],
  "nerlich": ['N ER1 L IH0 K'],
  "nerney": ['N ER1 N IY0'],
  "nero": ['N IH1 R OW0'],
  "nero's": ['N IH1 R OW0 Z'],
  "nerone": ['N EH1 R AH0 N'],
  "nerren": ['N EH1 R AH0 N'],
  "neruda": ['N AH0 R UW1 D AH0'],
  "nervana": ['N ER0 V AE1 N AH0'],
  "nerve": ['N ER1 V'],
  "nerve-racking": ['N ER2 V R AE1 K IH0 NG'],
  "nerves": ['N ER1 V Z'],
  "nervion": ['N ER1 V IY0 AH0 N'],
  "nervosa": ['N ER0 V OW1 S AH0'],
  "nervous": ['N ER1 V AH0 S'],
  "nervously": ['N ER1 V AH0 S L IY0'],
  "nervousness": ['N ER1 V AH0 S N AH0 S'],
  "nervy": ['N ER1 V IY0'],
  "nes": ['N EH1 S'],
  "nesbit": ['N EH1 S B IH0 T'],
  "nesbitt": ['N EH1 Z B IH0 T'],
  "nesby": ['N EH1 S B IY0'],
  "nesci": ['N EH1 S IY0'],
  "nesheim": ['N EH1 S HH AY2 M'],
  "nesi": ['N EH1 S IY0'],
  "nesler": ['N EH1 S AH0 L ER0', 'N EH1 S L ER0'],
  "nesler's": ['N EH1 S AH0 L ER0 Z', 'N EH1 S L ER0 Z'],
  "nesmith": ['N EH1 Z M IH0 TH'],
  "ness": ['N EH1 S'],
  "nessa": ['N EH1 S AH0'],
  "nessel": ['N EH1 S AH0 L'],
  "nessen": ['N IY1 S AH0 N'],
  "nesser": ['N EH1 S ER0'],
  "nesseth": ['N EH1 S IH0 TH'],
  "nessi": ['N EH1 S IY0'],
  "nessie": ['N EH1 S IY0'],
  "nessler": ['N EH1 S L ER0'],
  "nest": ['N EH1 S T'],
  "nest-egg": ['N EH1 S T EY1 G'],
  "nesta": ['N EH1 S T AH0'],
  "neste": ['N EH1 S T'],
  "nestea": ['N EH1 S T IY0 AH0'],
  "nested": ['N EH1 S T AH0 D', 'N EH1 S T IH0 D'],
  "nester": ['N EH1 S T ER0'],
  "nesters": ['N EH1 S T ER0 Z'],
  "nesting": ['N EH1 S T IH0 NG'],
  "nestle": ['N EH1 S AH0 L', 'N EH1 S L IY1'],
  "nestle's": ['N EH1 S AH0 L Z', 'N EH1 S L IY1 Z'],
  "nestled": ['N EH1 S AH0 L D'],
  "nestler": ['N EH1 S AH0 L ER0', 'N EH1 S L ER0'],
  "nestles": ['N EH1 S AH0 L Z'],
  "nestling": ['N EH1 S T L IH0 NG', 'N EH1 S L IH0 NG'],
  "nestlings": ['N EH1 S T L IH0 NG Z', 'N EH1 S L IH0 NG Z'],
  "nestor": ['N EH1 S T ER0'],
  "nestorian": ['N EH0 S T AO1 R IY0 AH0 N'],
  "nestorianism": ['N EH0 S T AO1 R IY0 AH0 N IH2 Z AH0 M'],
  "nests": ['N EH1 S T S'],
  "net": ['N EH1 T'],
  "netan": ['N EH1 T AH0 N'],
  "netanyahu": ['N EH2 T AH0 N Y AA1 HH UW2'],
  "netanyahu's": ['N EH2 T AH0 N Y AA1 HH UW2 Z'],
  "netback": ['N EH1 T B AE2 K'],
  "netcom": ['N EH1 T K AA2 M'],
  "netflix": ['N EH1 T F L IH0 K S'],
  "neth": ['N EH1 TH'],
  "nether": ['N EH1 DH ER0'],
  "nethercutt": ['N EH1 TH ER0 K AH0 T'],
  "netherland": ['N EH1 DH ER0 L AH0 N D'],
  "netherlandic": ['N EH1 DH ER0 L AE2 N D IH0 K'],
  "netherlands": ['N EH1 DH ER0 L AH0 N D Z'],
  "netherlands'": ['N EH1 TH ER0 L AE0 N D Z'],
  "nethers": ['N EH1 DH ER0 Z'],
  "netherton": ['N EH1 DH ER0 T AH0 N'],
  "netherworld": ['N EH1 DH ER0 W ER2 L D'],
  "nethery": ['N EH1 DH ER0 IY0'],
  "nethuva": ['N EH2 TH UW1 V AH0'],
  "nethuva's": ['N EH2 TH UW1 V AH0 Z'],
  "netiquette": ['N EH1 T AH0 K AH2 T'],
  "netlike": ['N EH1 T L AY2 K'],
  "neto": ['N EH1 T OW0'],
  "nets": ['N EH1 T S'],
  "netscape": ['N EH1 T S K EY2 P'],
  "netscape's": ['N EH1 T S K EY2 P S'],
  "nett": ['N EH1 T'],
  "netta": ['N EH1 T AH0'],
  "netted": ['N EH1 T IH0 D'],
  "netter": ['N EH1 T ER0'],
  "netterville": ['N EH1 T ER0 V IH0 L'],
  "nettesheim": ['N EH1 T IH0 S SH AY0 M'],
  "nettie": ['N EH1 T IY0'],
  "netting": ['N EH1 T IH0 NG'],
  "nettle": ['N EH1 T AH0 L'],
  "nettled": ['N EH1 T AH0 L D'],
  "nettles": ['N EH1 T AH0 L Z'],
  "nettlesome": ['N EH1 T AH0 L S AH0 M'],
  "nettleton": ['N EH1 T AH0 L T AH0 N'],
  "nettleton's": ['N EH1 T AH0 L T AH0 N Z'],
  "netto": ['N EH1 T OW0'],
  "netty": ['N EH1 T IY0'],
  "netview": ['N EH1 T V Y UW2'],
  "netware": ['N EH1 T W EH2 R'],
  "network": ['N EH1 T W ER2 K'],
  "network's": ['N EH1 T W ER2 K S'],
  "networked": ['N EH1 T W ER2 K T'],
  "networking": ['N EH1 T W ER2 K IH0 NG'],
  "networks": ['N EH1 T W ER2 K S'],
  "networks'": ['N EH1 T W ER2 K S'],
  "networth": ['N EH1 T W ER2 TH'],
  "netz": ['N EH1 T S'],
  "netzel": ['N EH1 T Z AH0 L'],
  "netzer": ['N EH1 T Z ER0'],
  "netzley": ['N EH1 T S L IY0'],
  "neu": ['N OY1'],
  "neubauer": ['N UW1 B AW0 ER0'],
  "neubecker": ['N UW1 B EH2 K ER0'],
  "neuber": ['N UW1 B ER0'],
  "neuberger": ['N UW1 B ER0 G ER0'],
  "neubert": ['N UW1 B ER0 T'],
  "neuburg": ['N UW1 B ER0 G'],
  "neuburger": ['N UW1 B ER0 G ER0'],
  "neudecker": ['N UW1 D IH0 K ER0'],
  "neue": ['N UW1 IY0'],
  "neuendorf": ['N UW1 AH0 N D AO0 R F'],
  "neuendorffer": ['N UW1 N D AO0 R F ER0'],
  "neuenfeldt": ['N UW1 AH0 N F EH0 L T'],
  "neuenschwander": ['N UW1 AH0 N SH W AO0 N D ER0'],
  "neuer": ['N OY1 ER0'],
  "neufeld": ['N UW1 F EH2 L D'],
  "neufeld's": ['N UW1 F EH2 L D Z'],
  "neugebauer": ['N UW1 G AH0 B AW0 ER0'],
  "neugent": ['N UW1 JH AH0 N T'],
  "neuhart": ['N UW1 HH AA2 R T'],
  "neuharth": ['N UW1 HH AA2 R TH'],
  "neuhaus": ['N UW1 HH AW2 S'],
  "neuhauser": ['N UW1 HH AW2 Z ER0'],
  "neuhoff": ['N UW1 HH AO2 F'],
  "neukam": ['N UW1 K AH0 M'],
  "neukirchen": ['N UW1 K ER0 CH AH0 N', 'N UW2 K IH1 R CH AH0 N'],
  "neukom": ['N UW1 K AA0 M'],
  "neumaier": ['N UW1 M AY0 ER0'],
  "neuman": ['N UW1 M AH0 N'],
  "neumann": ['N UW1 M AH0 N'],
  "neumayer": ['N UW1 M EY2 ER0'],
  "neumeier": ['N UW1 M AY0 ER0'],
  "neumeister": ['N UW1 M AY2 S T ER0'],
  "neumeyer": ['N UW1 M AY0 ER0'],
  "neumiller": ['N UW1 M AH0 L ER0'],
  "neuner": ['N UW1 N ER0'],
  "neupert": ['N UW1 P ER0 T'],
  "neupogen": ['N UW1 P OW0 JH EH2 N'],
  "neural": ['N UH1 R AH0 L', 'N Y UH1 R AH0 L'],
  "neurasthenia": ['N UH2 R AE0 S TH IY1 N IY0 AH0'],
  "neuro": ['N UH1 R OW2'],
  "neurofibromatosis": ['N UH2 R OW0 F AY0 B R OW2 M AH0 T OW1 S IH0 S'],
  "neurohr": ['N UH1 R AO0 R'],
  "neurologic": ['N UH2 R AH0 L AA1 JH IH0 K'],
  "neurological": ['N UH2 R AH0 L AA1 JH IH0 K AH0 L'],
  "neurologist": ['N UH0 R AA1 L AH0 JH AH0 S T'],
  "neurologists": ['N UH0 R AA1 L AH0 JH AH0 S T S'],
  "neurology": ['N UH0 R AA1 L AH0 JH IY0'],
  "neuromorphic": ['N UH1 R OW0 M AO2 R F IH0 K'],
  "neuron": ['N UH1 R AA0 N'],
  "neuronal": ['N UH1 R OW2 N AH0 L Z'],
  "neurons": ['N UH1 R AA0 N Z'],
  "neuropathy": ['N UH1 R OW0 P AE2 TH IY0', 'N UH2 R AO1 P AH0 TH IY0', 'N Y UH1 R OW0 P AE2 TH IY0'],
  "neuroscience": ['N Y UH1 R OW0 S AY2 AH0 N S'],
  "neuroscientist": ['N Y UH1 R OW0 S AY2 AH0 N T IH0 S T', 'N Y UH1 R OW0 S AY2 AH0 N IH0 S T'],
  "neuroses": ['N UH0 R OW1 S IY0 Z'],
  "neurosis": ['N UH0 R OW1 S AH0 S'],
  "neurosurgeon": ['N UH1 R OW0 S ER2 JH AH0 N'],
  "neurosurgeons": ['N UH1 R OW0 S ER2 JH AH0 N Z'],
  "neurosurgery": ['N UH2 R OW0 S ER1 JH ER0 IY0'],
  "neuroth": ['N UH1 R AO0 TH'],
  "neurotic": ['N UH0 R AA1 T IH0 K'],
  "neuser": ['N UW1 S ER0'],
  "neustadt": ['N UW1 SH T AE0 T'],
  "neustine": ['N UW1 S T AY2 N'],
  "neuter": ['N UW1 T ER0'],
  "neutered": ['N UW1 T ER0 D'],
  "neutering": ['N UW1 T ER0 IH0 NG'],
  "neutral": ['N UW1 T R AH0 L'],
  "neutralism": ['N UW1 T R AH0 L IH2 Z AH0 M'],
  "neutralist": ['N UW1 T R AH0 L AH0 S T'],
  "neutrality": ['N UW0 T R AE1 L AH0 T IY0'],
  "neutralization": ['N UW2 T R AH0 L AH0 Z EY1 SH AH0 N'],
  "neutralize": ['N UW1 T R AH0 L AY2 Z'],
  "neutralized": ['N UW1 T R AH0 L AY2 Z D'],
  "neutralizes": ['N UW1 T R AH0 L AY2 Z IH0 Z'],
  "neutralizing": ['N UW1 T R AH0 L AY2 Z IH0 NG'],
  "neutrally": ['N UW1 T R AH0 L IY0'],
  "neutrals": ['N UW1 T R AH0 L Z'],
  "neutrino": ['N UW0 T R IY1 N OW0'],
  "neutrinos": ['N UW0 T R IY1 N OW0 Z'],
  "neutrogena": ['N UW2 T R AH0 JH IY1 N AH0'],
  "neutron": ['N UW1 T R AA2 N'],
  "neutrons": ['N UW1 T R AA2 N Z'],
  "neuville": ['N UW1 V IH2 L'],
  "neuwirth": ['N UW1 W ER0 TH'],
  "neuzil": ['N UW1 Z AH0 L'],
  "neva": ['N EY1 V AH0'],
  "nevada": ['N AH0 V AA1 D AH0', 'N AH0 V AE1 D AH0'],
  "nevada's": ['N AH0 V AE1 D AH0 Z', 'N AH0 V AA1 D AH0 Z'],
  "nevadan": ['N AH0 V AE1 D AH0 N'],
  "nevadans": ['N AH0 V AE1 D AH0 N Z'],
  "nevala": ['N EY0 V AA1 L AH0'],
  "nevarach": ['N AH0 V AA1 R AH0 CH'],
  "nevarach's": ['N AH0 V AA1 R AH0 CH AH0 Z'],
  "nevarez": ['N EY0 V AA1 R EH0 Z'],
  "neve": ['N IY1 V'],
  "neveau": ['N IH0 V OW1'],
  "nevel": ['N EY0 V EH1 L'],
  "nevels": ['N EH1 V AH0 L Z'],
  "never": ['N EH1 V ER0'],
  "never-ending": ['N EH2 V ER0 EH1 N D IH0 NG'],
  "neverland": ['N EH1 V ER0 L AE0 N D', 'N EH1 V ER0 L AH0 N D'],
  "nevermind": ['N EH1 V ER0 M AY2 N D'],
  "nevermore": ['N EH1 V ER0 M AO2 R'],
  "nevers": ['N EH1 V ER0 Z'],
  "nevertheless": ['N EH2 V ER0 DH AH0 L EH1 S'],
  "neves": ['N IY1 V Z'],
  "neveu": ['N IH0 V UW1'],
  "nevil": ['N EY0 V IY1 L'],
  "nevile": ['N EY0 V AY1 L'],
  "nevill": ['N EH1 V IH0 L'],
  "neville": ['N EH1 V IH0 L'],
  "nevills": ['N EH1 V IH0 L Z'],
  "nevils": ['N EH1 V AH0 L Z'],
  "nevin": ['N EH1 V IH0 N'],
  "nevin's": ['N EH1 V IH0 N Z'],
  "nevins": ['N EH1 V IH0 N Z'],
  "nevis": ['N EH1 V IH0 S'],
  "nevitt": ['N EH1 V IH0 T'],
  "nevius": ['N IY1 V IY0 IH0 S'],
  "new": ['N UW1', 'N Y UW1'],
  "new-bedford": ['N UW2 B EH1 D F ER0 D'],
  "new-bern": ['N UW0 B ER1 N'],
  "new-caledonia": ['N UW1 K AE2 L AH0 D OW1 N IY0 AH0'],
  "new-delhi": ['N UW0 D EH1 L IY0'],
  "new-hampshire": ['N UW1 HH AE1 M P SH ER0'],
  "new-hampshire's": ['N UW1 HH AE1 M P SH ER0 Z'],
  "new-hampshirite": ['N UW1 HH AE1 M P SH ER0 AY2 T'],
  "new-hampshirites": ['N UW1 HH AE1 M P SH ER0 AY2 T S'],
  "new-haven": ['N UW1 HH EY0 V AH0 N'],
  "new-jersey": ['N UW1 JH ER1 Z IY0'],
  "new-jersey's": ['N UW1 JH ER1 Z IY0 Z'],
  "new-london": ['N UW0 L AH1 N D AH0 N'],
  "new-mexican": ['N UW1 M EH1 K S IH0 K AH0 N'],
  "new-mexicans": ['N UW1 M EH1 K S IH0 K AH0 N Z'],
  "new-mexico": ['N UW1 M EH1 K S AH0 K OW2'],
  "new-mexico's": ['N UW1 M EH1 K S AH0 K OW2 Z'],
  "new-orleans": ['N UW0 AO1 R L IY0 N Z', 'N Y UW1 AO1 R L IY0 N Z'],
  "new-years": ['N UW1 Y IY0 R Z'],
  "new-york": ['N UW1 Y AO1 R K'],
  "new-york's": ['N UW1 Y AO1 R K S'],
  "new-york-city": ['N UW1 Y AO1 R K S IH1 T IY0'],
  "new-yorker": ['N UW1 Y AO1 R K ER0'],
  "new-yorkers": ['N UW1 Y AO1 R K ER0 Z'],
  "new-zealand": ['N UW1 Z IY1 L AH0 N D'],
  "newall": ['N UW1 AO2 L'],
  "newark": ['N UW1 ER0 K', 'N Y UW1 ER0 K'],
  "newark's": ['N Y UW1 ER0 K S'],
  "newbauer": ['N UW1 B AW0 ER0'],
  "newberg": ['N UW1 B ER0 G'],
  "newberger": ['N UW1 B ER0 G ER0'],
  "newbern": ['N UW1 B ER0 N'],
  "newberry": ['N UW1 B EH2 R IY0'],
  "newbery": ['N UW1 B EH2 R IY0'],
  "newbie": ['N UW1 B IY2'],
  "newbill": ['N UW1 B IH2 L'],
  "newbold": ['N UW1 B OW2 L D'],
  "newborn": ['N UW1 B AO0 R N'],
  "newborns": ['N UW1 B AO2 R N Z'],
  "newbraudwick": ['N UW0 B R AA1 D W IH0 K'],
  "newbridge": ['N UW1 B R IH2 JH'],
  "newbrough": ['N UW1 B R AW0'],
  "newburg": ['N UW1 B ER0 G'],
  "newburger": ['N UW1 B ER0 G ER0'],
  "newburgh": ['N UW1 B ER0 G'],
  "newburn": ['N UW1 B ER2 N'],
  "newbury": ['N UW1 B EH2 R IY0'],
  "newbury's": ['N UW1 B EH2 R IY0 Z'],
  "newby": ['N UW1 B IY0'],
  "newcastle": ['N UW1 K AE2 S AH0 L', 'N Y UW1 K AE2 S AH0 L'],
  "newcastle's": ['N UW1 K AE2 S AH0 L Z', 'N Y UW1 K AE2 S AH0 L Z'],
  "newcom": ['N UW1 K AH0 M'],
  "newcomb": ['N UW1 K AH0 M'],
  "newcombe": ['N UW1 K AH0 M'],
  "newcome": ['N UW1 K AH0 M'],
  "newcomer": ['N UW1 K AH2 M ER0'],
  "newcomers": ['N UW1 K AH2 M ER0 Z'],
  "newcor": ['N UW1 K AO2 R'],
  "newedge": ['N UW1 AH0 JH'],
  "newell": ['N UW1 AH0 L'],
  "newell's": ['N UW1 AH0 L Z'],
  "newer": ['N UW1 ER0'],
  "newest": ['N UW1 AH0 S T'],
  "newey": ['N UW1 IY0'],
  "newfangle": ['N UW2 F AE1 NG G AH0 L'],
  "newfangled": ['N UW2 F AE1 NG G AH0 L D'],
  "newfield": ['N UW1 F IY2 L D'],
  "newfound": ['N UW1 F AW1 N D'],
  "newfoundland": ['N UW1 F AH0 N D L AH0 N D'],
  "newgard": ['N UW1 G ER0 D'],
  "newgate": ['N UW1 G EY0 T'],
  "newgateway": ['N UW1 G EY2 T W EY2'],
  "newhall": ['N UW1 HH AO2 L'],
  "newhall's": ['N UW1 HH AO2 L Z'],
  "newhard": ['N UW1 HH AA2 R D'],
  "newhart": ['N UW1 HH AA2 R T'],
  "newhouse": ['N UW1 HH AW2 S'],
  "newill": ['N IY0 W IH1 L'],
  "newingham": ['N UW1 IH0 NG HH AE2 M'],
  "newish": ['N UW1 IH0 SH'],
  "newkirk": ['N UW1 K ER0 K'],
  "newlan": ['N UW1 L AH0 N'],
  "newland": ['N UW1 L AH0 N D'],
  "newley": ['N UW1 L IY0'],
  "newlin": ['N UW1 L IH0 N'],
  "newlon": ['N UW1 L AH0 N'],
  "newlun": ['N UW1 L AH0 N'],
  "newly": ['N UW1 L IY0'],
  "newlyn": ['N UW1 L IH0 N'],
  "newlywed": ['N UW1 L IY0 W EH2 D'],
  "newlyweds": ['N UW1 L IY0 W EH2 D Z'],
  "newman": ['N UW1 M AH0 N'],
  "newman's": ['N UW1 M AH0 N Z'],
  "newmann": ['N UW1 M AH0 N'],
  "newmark": ['N UW1 M AA2 R K'],
  "newmarket": ['N UW1 M AA2 R K AH0 T'],
  "newmeyer": ['N UW1 M AY0 ER0'],
  "newmont": ['N UW1 M AA2 N T'],
  "newmont's": ['N UW1 M AA2 N T S'],
  "newmyer": ['N UW1 M IY0 ER0'],
  "newnam": ['N UW1 N AH0 M'],
  "newness": ['N UW1 N AH0 S'],
  "neworld": ['N EH1 W ER1 L D', 'N UW1 ER1 L D'],
  "newport": ['N UW1 P AO0 R T'],
  "newport's": ['N UW1 P AO0 R T S'],
  "newport-news": ['N UW1 P AO0 R T N UW1 Z'],
  "newquist": ['N UW1 K W IH2 S T'],
  "news": ['N UW1 Z', 'N Y UW1 Z'],
  "news'": ['N UW1 Z'],
  "news's": ['N UW1 Z IH0 Z'],
  "newscast": ['N UW1 Z K AE2 S T'],
  "newscaster": ['N UW1 Z K AE2 S T ER0'],
  "newscasters": ['N UW1 Z K AE2 S T ER0 Z'],
  "newscasts": ['N UW1 Z K AE2 S T S'],
  "newscorp": ['N UW1 Z K AO2 R P'],
  "newscorp's": ['N UW1 Z K AO2 R P S'],
  "newsday": ['N UW1 Z D EY2'],
  "newsday's": ['N UW1 Z D EY2 Z'],
  "newsflash": ['N UW1 S F L AE0 SH'],
  "newsflashes": ['N UW1 S F L AE0 SH AH0 Z'],
  "newsgroup": ['N UW1 Z G R UW2 P'],
  "newsgroups": ['N UW1 Z G R UW2 P S'],
  "newsham": ['N UW1 SH AH0 M'],
  "newshour": ['N UW1 Z AW2 R'],
  "newsies": ['N UW1 Z IY0 Z'],
  "newsletter": ['N UW1 Z L EH2 T ER0'],
  "newsletter's": ['N UW1 Z L EH2 T ER0 Z'],
  "newsletters": ['N UW1 Z L EH2 T ER0 Z'],
  "newslink": ['N UW1 Z L IH0 NG K'],
  "newslink's": ['N UW1 Z L IH0 NG K S'],
  "newsmagazine": ['N UW1 Z M AE2 G AH0 Z IY2 N'],
  "newsmagazines": ['N UW1 Z M AE2 G AH0 Z IY2 N Z'],
  "newsmaker": ['N UW1 Z M EY2 K ER0'],
  "newsmakers": ['N UW1 Z M EY2 K ER0 Z'],
  "newsman": ['N UW1 Z M AE2 N', 'N UW1 Z M AH0 N'],
  "newsmen": ['N UW1 Z M IH0 N'],
  "newsnight": ['N UW1 Z N AY2 T'],
  "newsom": ['N UW1 Z AH0 M'],
  "newsome": ['N UW1 S AH0 M'],
  "newson": ['N UW1 S AH0 N'],
  "newspaper": ['N UW1 Z P EY2 P ER0'],
  "newspaper's": ['N UW1 Z P EY2 P ER0 Z'],
  "newspaperman": ['N UW1 Z P EY2 P ER0 M AE2 N'],
  "newspapermen": ['N UW1 Z P AE2 P ER0 M AH0 N'],
  "newspapers": ['N UW1 Z P EY2 P ER0 Z'],
  "newspapers'": ['N UW1 Z P EY2 P ER0 Z'],
  "newspeak": ['N UW1 S P IY2 K'],
  "newspeople": ['N UW1 Z P IY0 P AH0 L'],
  "newsperson": ['N UW1 Z P ER0 S AH0 N'],
  "newspersons": ['N UW1 Z P ER0 S AH0 N Z'],
  "newsprint": ['N UW1 Z P R IH2 N T'],
  "newsreel": ['N UW1 Z R IY2 L'],
  "newsreels": ['N UW1 Z R IY2 L Z'],
  "newsroom": ['N UW1 Z R UW2 M'],
  "newsroom's": ['N UW1 Z R UW2 M Z'],
  "newsrooms": ['N UW1 Z R UW2 M Z'],
  "newsstand": ['N UW1 Z S T AE2 N D'],
  "newsstands": ['N UW1 Z S T AE2 N D Z'],
  "newstrom": ['N UW1 Z T R AH0 M'],
  "newsum": ['N UW1 Z AH0 M'],
  "newswanger": ['N UW1 Z W AO0 NG ER0'],
  "newsweek": ['N UW1 Z W IY2 K'],
  "newsweek's": ['N UW1 Z W IY2 K S'],
  "newsweekly": ['N UW1 Z W IY2 K L IY0'],
  "newswire": ['N UW1 Z W AY2 R'],
  "newswires": ['N UW1 Z W AY2 R Z'],
  "newswoman": ['N UW1 Z W UH0 M AH0 N'],
  "newswomen": ['N UW1 Z W IH0 M AH0 N'],
  "newsworthy": ['N UW1 Z W ER2 DH IY0'],
  "newsy": ['N UW1 Z IY0'],
  "newt": ['N UW1 T'],
  "newt's": ['N UW1 T S'],
  "newton": ['N UW1 T AH0 N'],
  "newton's": ['N UW1 T AH0 N Z'],
  "newtonchik": ['N UW1 T AA2 N CH IH0 K'],
  "newtonian": ['N UW0 T OW1 N IY0 AH0 N'],
  "newtown": ['N UW1 T AW2 N'],
  "newtowne": ['N UW1 T AW2 N'],
  "newts": ['N UW1 T S'],
  "newvector": ['N UW1 V EH2 K T ER0'],
  "newville": ['N UW1 V IH2 L'],
  "newwave": ['N UW1 W EY2 V'],
  "nex": ['N EH1 K S'],
  "nexgen": ['N EH1 K S JH EH2 N'],
  "nexis": ['N EH1 K S IH0 S'],
  "nexrad": ['N EH1 K S R AE0 D'],
  "next": ['N EH1 K S T', 'N EH1 K S'],
  "next's": ['N EH1 K S T S'],
  "nextel": ['N EH1 K S T EH2 L'],
  "nextel's": ['N EH1 K S T EH2 L Z'],
  "nextstep": ['N EH1 K S T S T EH2 P', 'N EH1 K S T EH2 P'],
  "nexus": ['N EH1 K S AH0 S'],
  "ney": ['N EY1'],
  "neyens": ['N AY1 N Z'],
  "neyer": ['N EY1 ER0'],
  "neyhart": ['N EY1 HH AA2 R T'],
  "neyland": ['N EY1 L AH0 N D'],
  "neylon": ['N EY1 L AH0 N'],
  "neyman": ['N EY1 M AH0 N'],
  "neysa": ['N EY1 S AH0'],
  "nez": ['N EH1 Z'],
  "nfc": ['EH1 N EH1 F S IY1'],
  "nfl": ['EH1 N EH2 F EH1 L'],
  "ng": ['EH1 NG', 'IH1 NG'],
  "ngai": ['G AY1', 'EH0 N G AY1'],
  "ngema": ['EH0 N G EH1 M AA0'],
  "nghi": ['G IY1', 'EH0 N G IY1'],
  "ngo": ['EH0 NG G OW1'],
  "ngor": ['EH0 NG G AO1 R'],
  "ngos": ['EH0 NG G OW1 Z'],
  "ngueppe": ['EH0 NG G EH1 P IY0'],
  "nguyen": ['N UW0 Y EH1 N'],
  "ni": ['N IY1'],
  "niacin": ['N AY1 AH0 S AH0 N', 'N AY1 AH0 S IH0 N'],
  "niad": ['N AY1 AE0 D'],
  "niagara": ['N AY0 AE1 G R AH0'],
  "niagara's": ['N AY0 AE1 G R AH0 Z'],
  "niaid": ['N AY1 EY2 D'],
  "nial": ['N AY1 AH0 L'],
  "niall": ['N AY1 L'],
  "niam": ['N AY1 AH0 M'],
  "niamh": ['N AY1 AH0 M', 'N IY1 V'],
  "nib": ['N IH1 B'],
  "nibbe": ['N IH1 B'],
  "nibble": ['N IH1 B AH0 L'],
  "nibbled": ['N IH1 B AH0 L D'],
  "nibbles": ['N IH1 B AH0 L Z'],
  "nibbling": ['N IH1 B AH0 L IH0 NG', 'N IH1 B L IH0 NG'],
  "nibert": ['N IH1 B ER0 T'],
  "niblack": ['N IH1 B L AE2 K'],
  "niblett": ['N IH1 B L IH0 T'],
  "niblock": ['N IH1 B L AA2 K'],
  "nibs": ['N IH1 B Z'],
  "nic": ['N IH1 K'],
  "nicandros": ['N IH0 K AE1 N D R OW0 S'],
  "nicaragua": ['N IH2 K ER0 AA1 G W AH0'],
  "nicaragua's": ['N IH2 K ER0 AA1 G W AH0 Z'],
  "nicaraguan": ['N IH2 K ER0 AA1 G W AH0 N'],
  "nicaraguans": ['N IH2 K ER0 AA1 G W AH0 N Z'],
  "nicastro": ['N IH0 K AE1 S T R OW0'],
  "niccoli": ['N IY0 K OW1 L IY0'],
  "niccolite": ['N IH1 K AH0 L AY2 T'],
  "niccum": ['N IH1 K AH0 M'],
  "nice": ['N AY1 S', 'N IY1 S'],
  "nicely": ['N AY1 S L IY0'],
  "nicen": ['N AY1 S AH0 N'],
  "niceness": ['N AY1 S N AH0 S'],
  "nicer": ['N AY1 S ER0'],
  "nicest": ['N AY1 S IH0 S T'],
  "niceties": ['N AY1 S IH0 T IY0 Z'],
  "nicety": ['N AY1 S IH0 T IY0'],
  "niche": ['N IH1 CH'],
  "nichelson": ['N IH1 CH IH0 L S AH0 N'],
  "niches": ['N IH1 CH IH0 Z'],
  "nichol": ['N IH1 K AO0 L'],
  "nichola": ['N IH0 HH OW1 L AH0'],
  "nicholas": ['N IH1 K AH0 L AH0 S', 'N IH1 K L AH0 S'],
  "nicholas'": ['N IH1 K AH0 L AH0 S', 'N IH1 K L AH0 S'],
  "nicholas's": ['N IH1 K AH0 L AH0 S IH0 Z', 'N IH1 K L AH0 S IH0 Z'],
  "nichole": ['N IH1 K OW0 L'],
  "nicholes": ['N IH1 K OW0 L Z'],
  "nicholi": ['N IH1 K AH0 L AY0'],
  "nicholl": ['N IH1 K AH0 L'],
  "nicholls": ['N IH1 K AH0 L Z'],
  "nichols": ['N IH1 K AH0 L Z'],
  "nichols'": ['N IH1 K AH0 L Z'],
  "nichols's": ['N IH1 K AH0 L Z IH0 Z'],
  "nicholson": ['N IH1 K AH0 L S AH0 N'],
  "nicholson's": ['N IH1 K AH0 L S AH0 N Z'],
  "nichter": ['N IH1 K T ER0'],
  "nick": ['N IH1 K'],
  "nick's": ['N IH1 K S'],
  "nicked": ['N IH1 K T'],
  "nickel": ['N IH1 K AH0 L'],
  "nickel's": ['N IH1 K AH0 L Z'],
  "nickell": ['N IH1 K AH0 L'],
  "nickelodeon": ['N IH2 K IH0 L OW1 D IY0 AH0 N'],
  "nickels": ['N IH1 K AH0 L Z'],
  "nickelson": ['N IH1 K IH0 L S AH0 N'],
  "nickens": ['N IH1 K AH0 N Z'],
  "nickerson": ['N IH1 K ER0 S AH0 N'],
  "nickeson": ['N IH1 K IH0 S AH0 N'],
  "nickey": ['N IH1 K IY0'],
  "nicki": ['N IH1 K IY0'],
  "nicklas": ['N IH1 K L AH0 Z'],
  "nicklaus": ['N IH1 K L AH0 S'],
  "nicklaus's": ['N IH1 K L AH0 S IH0 Z'],
  "nickle": ['N IH1 K AH0 L'],
  "nickleby": ['N IH1 K AH0 L B IY0'],
  "nickles": ['N IH1 K AH0 L Z'],
  "nickless": ['N IH1 K L AH0 S'],
  "nicklin": ['N IH1 K L IH0 N'],
  "nicklow": ['N IH1 K L OW2'],
  "nickname": ['N IH1 K N EY2 M'],
  "nicknamed": ['N IH1 K N EY2 M D'],
  "nicknames": ['N IH1 K N EY2 M Z'],
  "nickol": ['N IH1 K AH0 L'],
  "nickolas": ['N IH1 K AH0 L AH0 Z'],
  "nickolay": ['N IH1 K AH0 L AY2'],
  "nickoloff": ['N IH1 K AH0 L AO0 F'],
  "nickols": ['N IH1 K AH0 L Z'],
  "nickolson": ['N IH1 K OW0 L S AH0 N'],
  "nicks": ['N IH1 K S'],
  "nickson": ['N IH1 K S AH0 N'],
  "nickum": ['N IH1 K AH0 M'],
  "nicky": ['N IH1 K IY0'],
  "nicky's": ['N IH1 K IY0 Z'],
  "niclaneshia": ['N IH1 K L AH0 N EH2 SH AH0'],
  "niclaneshia's": ['N IH1 K L AH0 N EH2 SH AH0 Z'],
  "nicley": ['N IH1 K L IY0'],
  "nico": ['N IY1 K OW0'],
  "nicodemo": ['N IY2 K OW0 D EY1 M OW0'],
  "nicoderm": ['N IH1 K AH0 D ER2 M'],
  "nicol": ['N IH1 K AH0 L'],
  "nicola": ['N IH0 K OW1 L AA0'],
  "nicolae": ['N IH1 K OW0 L AY2'],
  "nicolai": ['N IY0 K OW0 L AA1 IY0'],
  "nicolaides": ['N IH1 K AH0 L EY0 D Z'],
  "nicolaisen": ['N IH1 K AH0 L AY0 S AH0 N'],
  "nicolas": ['N IH1 K AH0 L AH0 S'],
  "nicolau": ['N IH1 K AH0 L AW0'],
  "nicolaus": ['N IH1 K AH0 L AW0 Z'],
  "nicolay": ['N IH1 K AH0 L EY2'],
  "nicole": ['N IH0 K OW1 L'],
  "nicole's": ['N IH0 K OW1 L Z'],
  "nicolet": ['N IH2 K AH0 L EH1 T'],
  "nicoletta": ['N IH2 K AH0 L EH1 T AH0'],
  "nicolette": ['N IH2 K AH0 L EH1 T'],
  "nicoletti": ['N IY2 K OW0 L EH1 T IY0', 'N IH2 K AH0 L EH1 T IY0'],
  "nicoli": ['N IY0 K OW1 L IY0'],
  "nicolin": ['N IH1 K AH0 L IH0 N'],
  "nicolina": ['N IY2 K OW0 L IY1 N AA0'],
  "nicoline": ['N IY0 K OW0 L IY1 N IY0'],
  "nicolini": ['N IY2 K OW0 L IY1 N IY0'],
  "nicoll": ['N IH1 K AH0 L'],
  "nicolle": ['N IH0 K OW1 L'],
  "nicollier": ['N IH0 K OW1 L Y ER0'],
  "nicolls": ['N IH1 K AH0 L Z'],
  "nicolo": ['N IH1 K AH0 L OW0'],
  "nicoloff": ['N IH1 K AH0 L AO2 F'],
  "nicolosi": ['N IY0 K OW0 L OW1 S IY0'],
  "nicols": ['N IH1 K AH0 L Z'],
  "nicolson": ['N IH1 K OW0 L S AH0 N'],
  "nicor": ['N AY1 K AO2 R'],
  "nicorette": ['N IH1 K ER0 EH2 T'],
  "nicosia": ['N IH0 K AH0 S IY1 AH0'],
  "nicoski": ['N IH0 K AO1 S K IY2'],
  "nicoson": ['N IH1 K AH0 S AH0 N'],
  "nicotera": ['N IY2 K OW0 T EH1 R AA0'],
  "nicotine": ['N IH1 K AH0 T IY2 N'],
  "nicotine's": ['N IH1 K AH0 T IY2 N Z'],
  "nicotra": ['N IH0 K AA1 T R AH0'],
  "nida": ['N IY1 D AH0'],
  "nidal": ['N IH0 D AA1 L'],
  "nidal's": ['N IH0 D AA1 L Z'],
  "niday": ['N AY1 D EY2'],
  "nidiffer": ['N IH1 D IH0 F ER0'],
  "nie": ['N IY1', 'N AY1'],
  "niebauer": ['N IY1 B AW0 ER0'],
  "niebling": ['N IY1 B AH0 L IH0 NG', 'N IY1 B L IH0 NG'],
  "niebuhr": ['N IY1 B UH0 R'],
  "niebur": ['N IY1 B ER0'],
  "niece": ['N IY1 S'],
  "niece's": ['N IY1 S IH0 Z'],
  "nieces": ['N IY1 S IH0 Z'],
  "nied": ['N IY1 D'],
  "niedbala": ['N IY0 D B AA1 L AH0'],
  "niedbalski": ['N IY0 D B AA1 L S K IY0'],
  "nieder": ['N IY1 D ER0'],
  "niederer": ['N IY1 D ER0 ER0'],
  "niederhauser": ['N IY1 D ER0 HH AW0 Z ER0'],
  "niederman": ['N AY1 D ER0 M AH0 N'],
  "niedermeier": ['N IY1 D ER0 M AY0 ER0'],
  "niedermeyer": ['N IY1 D ER0 M AY0 ER0'],
  "niedzielski": ['N IY0 JH IY1 L S K IY0'],
  "niedzwiecki": ['N IY0 JH V IY1 T S K IY0'],
  "niehaus": ['N IY1 HH AW2 S', 'N AY1 HH AW2 S'],
  "niehoff": ['N IY1 HH AO0 F'],
  "niekamp": ['N IY1 K AE2 M P'],
  "niel": ['N IY1 L'],
  "nieland": ['N IY1 L AH0 N D'],
  "nield": ['N IY1 L D'],
  "nields": ['N IY1 L D Z'],
  "niels": ['N IY1 L Z'],
  "nielsen": ['N IY1 L S AH0 N'],
  "nielsen's": ['N IY1 L S AH0 N Z'],
  "nielson": ['N IY1 L S AH0 N'],
  "nieman": ['N IY1 M AH0 N'],
  "niemann": ['N IY1 M AH0 N'],
  "niemczyk": ['N IY1 M CH IH0 K'],
  "niemeier": ['N IY1 M AY0 ER0'],
  "niemela": ['N IY0 M EH1 L AH0'],
  "niemeyer": ['N IY1 M AY0 ER0'],
  "niemi": ['N IY1 M IY0'],
  "niemiec": ['N IY1 M IY2 K'],
  "niemoeller": ['N AY1 M AO0 L ER0'],
  "niemuth": ['N IY1 M AH0 TH'],
  "nienaber": ['N IY1 N AH0 B ER0'],
  "nienhaus": ['N IY1 N HH AW2 S'],
  "nienhuis": ['N IY1 N HH UW0 IH0 Z'],
  "nienow": ['N IY1 N OW0'],
  "nier": ['N IY1 ER0'],
  "nierenberg": ['N IH1 R AH0 N B ER0 G'],
  "nierman": ['N IH1 R M AH0 N'],
  "nies": ['N AY1 Z'],
  "niese": ['N IY1 Z'],
  "niesen": ['N IY1 S AH0 N'],
  "niess": ['N IY1 S'],
  "nieto": ['N IY1 T OW0', 'N IY1 AH0 T OW0'],
  "nieto's": ['N IY1 T OW0 Z', 'N IY1 AH0 T OW0 Z'],
  "nietzsche": ['N IY1 CH IY0'],
  "nieves": ['N IY0 EH1 V EH0 S'],
  "niezgoda": ['N IY0 Z G OW1 D AH0'],
  "nifedipine": ['N AY2 F EH1 D AH0 P IY0 N', 'N AH0 F EH1 D AH0 P IY0 N'],
  "nifong": ['N IH1 F AO0 NG'],
  "nifty": ['N IH1 F T IY0'],
  "nigel": ['N AY1 JH AH0 L'],
  "niger": ['N AY1 JH ER0'],
  "nigeria": ['N AY0 JH IH1 R IY0 AH0'],
  "nigeria's": ['N AY0 JH IH1 R IY0 AH0 Z'],
  "nigerian": ['N AY0 JH IH1 R IY0 AH0 N'],
  "nigerians": ['N AY0 JH IH1 R IY0 AH0 N Z'],
  "nigg": ['N IH1 G'],
  "niggard": ['N IH1 G ER0 D'],
  "niggardliness": ['N IH1 G ER0 D L IY0 N AH0 S'],
  "niggardly": ['N IH1 G ER0 D L IY0'],
  "nigger": ['N IH1 G ER0'],
  "nigger's": ['N IH1 G ER0 Z'],
  "niggers": ['N IH1 G ER0 Z'],
  "nigh": ['N AY1'],
  "nighswonger": ['N AY1 S W AO0 NG ER0'],
  "night": ['N AY1 T'],
  "night's": ['N AY1 T S'],
  "nightclub": ['N AY1 T K L AH2 B'],
  "nightclubs": ['N AY1 T K L AH2 B Z'],
  "nightengale": ['N AY1 T IH0 NG G AH0 L'],
  "nightengale's": ['N AY1 T IH0 NG G AH0 L Z'],
  "nightengales": ['N AY1 T IH0 NG G AH0 L Z'],
  "nighter": ['N AY1 T ER0'],
  "nighters": ['N AY1 T ER0 Z'],
  "nightfall": ['N AY1 T F AO2 L'],
  "nighthawk": ['N AY1 T HH AO2 K'],
  "nighthawks": ['N AY1 T HH AO2 K S'],
  "nighthorse": ['N AY1 T HH AO0 R S'],
  "nightingale": ['N AY1 T IH0 NG G EY0 L'],
  "nightlife": ['N AY1 T L AY2 F'],
  "nightline": ['N AY1 T L AY2 N'],
  "nightline's": ['N AY1 T L AY2 N Z'],
  "nightlines": ['N AY1 T L AY2 N Z'],
  "nightly": ['N AY1 T L IY0'],
  "nightmare": ['N AY1 T M EH2 R'],
  "nightmares": ['N AY1 T M EH2 R Z'],
  "nightmarish": ['N AY1 T M EH2 R IH0 SH'],
  "nights": ['N AY1 T S'],
  "nights'": ['N AY1 T S'],
  "nightshade": ['N AY1 CH EY2 D'],
  "nightshirt": ['N AY1 CH ER2 T'],
  "nightshirts": ['N AY1 CH ER2 T S'],
  "nightstage": ['N AY1 T S T EY2 JH'],
  "nightstand": ['N AY1 T S T AE2 N D'],
  "nightstands": ['N AY1 T S T AE2 N D Z'],
  "nightstick": ['N AY1 T S T IH2 K'],
  "nightsticks": ['N AY1 T S T IH2 K S'],
  "nighttime": ['N AY1 T T AY2 M'],
  "nighttimes": ['N AY1 T T AY2 M Z'],
  "nigrelli": ['N IY0 G R EH1 L IY0'],
  "nigris": ['N IH1 G R IH0 S'],
  "nigro": ['N IH1 G R OW0'],
  "niguel": ['N IH0 G EH1 L'],
  "nihart": ['N IH1 HH AA0 R T'],
  "nihay": ['N AY1 HH EY0', 'N IY1 HH EY0'],
  "nihei": ['N IH0 HH EY1'],
  "nihilism": ['N AY1 AH0 L IH2 Z AH0 M'],
  "nihilists": ['N AY1 AH0 L AH0 S T S'],
  "nihiser": ['N IH1 HH AY0 Z ER0'],
  "nihon": ['N IH1 HH AA0 N'],
  "niihau": ['N IY1 HH AW0'],
  "nik": ['N IH1 K'],
  "nike": ['N AY1 K IY0'],
  "nike's": ['N AY1 K IY0 Z'],
  "nikes": ['N AY1 K S', 'N AY1 K IY0 Z'],
  "niki": ['N IH1 K IY0'],
  "nikita": ['N IH2 K IY1 T AH0', 'N AH0 K IY1 T AH0'],
  "nikk's": ['N IH1 K S'],
  "nikkei": ['N IH0 K EY1', 'N IY1 K EY2', 'N AY1 K IY2'],
  "nikkei's": ['N IY1 K EY2 Z'],
  "nikkel": ['N IH1 K AH0 L'],
  "nikkhah": ['N IH1 K HH AA0'],
  "nikki": ['N IH1 K IY0'],
  "nikko": ['N IY1 K OW0'],
  "nikko's": ['N IY1 K OW0 Z'],
  "niko": ['N IY1 K OW0'],
  "niko's": ['N IY1 K OW0 Z'],
  "nikola": ['N IH0 K OW1 L AH0'],
  "nikolai": ['N IH1 K OW0 L AY2'],
  "nikolaivich": ['N IH0 K OW0 L AY1 V IH0 CH'],
  "nikolaus": ['N IH1 K AH0 L AW0 Z'],
  "nikolic": ['N IH0 K AA1 L IH0 K'],
  "nikolich": ['N IH0 K AA1 L IH0 HH'],
  "nikon": ['N AY1 K AA2 N'],
  "nikou": ['N IY1 K UW0'],
  "nikovski": ['N IH0 K AA1 F S K IY0'],
  "nil": ['N IH1 L'],
  "nila": ['N IY1 L AH0'],
  "nilan": ['N IY0 L AA1 N'],
  "niland": ['N AY1 L AH0 N D'],
  "nile": ['N AY1 L'],
  "niles": ['N AY1 L Z'],
  "nilges": ['N IH1 L JH IH0 Z'],
  "nill": ['N IH1 L'],
  "nilles": ['N AY1 L Z'],
  "nilly": ['N IH1 L IY0'],
  "nils": ['N IH1 L Z'],
  "nilsen": ['N IH1 L S AH0 N'],
  "nilson": ['N IH1 L S AH0 N'],
  "nilsson": ['N IH1 L S AH0 N'],
  "nimble": ['N IH1 M B AH0 L'],
  "nimbly": ['N IH1 M B L IY0'],
  "nimbus": ['N IH1 M B AH0 S'],
  "nimby": ['N IH1 M B IY0'],
  "nimitz": ['N IH1 M IH0 T S'],
  "nimmer": ['N IH1 M ER0'],
  "nimmo": ['N IH1 M OW0'],
  "nimmons": ['N IH1 M AH0 N Z'],
  "nimoy": ['N IY1 M OY2'],
  "nimrod": ['N IH1 M R AA0 D'],
  "nimrodi": ['N IH0 M R OW1 D IY0'],
  "nims": ['N IH1 M Z'],
  "nimtz": ['N IH1 M T S'],
  "nina": ['N IY1 N AH0'],
  "ninad": ['N AY1 N AH0 D'],
  "ninagawa": ['N IY2 N AA0 G AA1 W AH0'],
  "nincompoop": ['N IH1 NG K AH0 M P UW2 P'],
  "nincompoops": ['N IH1 NG K AH0 M P UW2 P S'],
  "nine": ['N AY1 N'],
  "nine's": ['N AY1 N Z'],
  "ninefold": ['N IH1 N F OW2 L D'],
  "niner": ['N AY1 N ER0'],
  "niners": ['N AY1 N ER0 Z'],
  "niners'": ['N AY1 N ER0 Z'],
  "nines": ['N AY1 N Z'],
  "nineteen": ['N AY1 N T IY1 N'],
  "nineteenth": ['N AY1 N T IY1 N TH'],
  "nineties": ['N AY1 N T IY0 Z'],
  "nineties'": ['N AY1 N T IY0 Z'],
  "ninetieth": ['N AY1 N T IY0 IH0 TH'],
  "ninette": ['N IH0 N EH1 T'],
  "ninety": ['N AY1 N T IY0'],
  "ninety's": ['N AY1 N T IY0 Z'],
  "nineveh": ['N IH1 N AH0 V AH0'],
  "ning": ['N IH1 NG'],
  "ninja": ['N IH1 N JH AH0'],
  "ninjas": ['N IH1 N JH AH0 Z'],
  "ninneman": ['N IH1 N M AH0 N'],
  "ninny": ['N IH1 N IY0'],
  "nino": ['N IY1 N OW0', 'N IY1 N Y OW0'],
  "ninon": ['N IH1 N AH0 N'],
  "ninos": ['N IY1 N OW0 Z', 'N IY1 N Y OW0 Z'],
  "ninsu": ['N IH1 N S UW0'],
  "nintendo": ['N IH0 N T EH1 N D OW0'],
  "nintendo's": ['N IH0 N T EH1 N D OW0 Z'],
  "ninth": ['N AY1 N TH'],
  "ninths": ['N AY1 N TH S'],
  "niobite": ['N AY1 OW0 B AY2 T'],
  "niobium": ['N AY2 OW1 B IY0 AH0 M'],
  "nip": ['N IH1 P'],
  "nipon": ['N IH1 P AA2 N'],
  "nipp": ['N IH1 P'],
  "nipped": ['N IH1 P T'],
  "nipper": ['N IH1 P ER0'],
  "nippert": ['N IH1 P ER0 T'],
  "nipping": ['N IH1 P IH0 NG'],
  "nipple": ['N IH1 P AH0 L'],
  "nipples": ['N IH1 P AH0 L Z'],
  "nippon": ['N IH2 P AA1 N'],
  "nippondenso": ['N IH2 P AA2 N D EH1 N S OW0'],
  "nippy": ['N IH1 P IY0'],
  "nips": ['N IH1 P S'],
  "nipsco": ['N IH1 P S K OW0'],
  "niquette": ['N IH0 K EH1 T'],
  "nir": ['N IH1 R'],
  "nirenberg": ['N AY1 R AH0 N B ER0 G'],
  "niriko": ['N IH1 R IH0 K OW0'],
  "niro": ['N IH1 R OW0'],
  "nirvana": ['N IH0 R V AA1 N AH0', 'N ER0 V AA1 N AH0'],
  "nirvana's": ['N IH0 R V AA1 N AH0 Z', 'N ER0 V AA1 N AH0 Z'],
  "nirvanas": ['N IH0 R V AA1 N AH0 Z', 'N ER0 V AA1 N AH0 Z'],
  "nisbet": ['N IH1 Z B AH0 T'],
  "nisbett": ['N IH1 S B IH0 T'],
  "nisei": ['N IH0 S EY1'],
  "nishi": ['N IY1 SH IY0'],
  "nishida": ['N IY0 SH IY1 D AH0'],
  "nishikawa": ['N IY0 SH IY0 K AA1 W AH0'],
  "nishimo": ['N IH0 SH IY1 M OW0'],
  "nishimoto": ['N IY0 SH IY0 M OW1 T OW0'],
  "nishimura": ['N IY0 SH IY0 M UH1 R AH0'],
  "nishioka": ['N IY2 SH IY0 OW1 K AH0'],
  "nishiyama": ['N IY0 SH IY0 Y AA1 M AH0'],
  "nishizawa": ['N IY2 SH IH0 Z AA1 W AH0'],
  "niskanen": ['N IH1 S K AH0 N AH0 N'],
  "nisley": ['N IH1 Z L IY0'],
  "nissa": ['N IH1 S AH0'],
  "nissan": ['N IY1 S AA0 N'],
  "nissan's": ['N IY1 S AA0 N Z'],
  "nissans": ['N IY1 S AA0 N Z'],
  "nissei": ['N IH0 S EY1'],
  "nissen": ['N IH1 S AH0 N'],
  "nissenbaum": ['N IH1 S AH0 N B AW2 M'],
  "nisshin": ['N IH1 SH IH0 N'],
  "nissho": ['N IH1 SH OW0'],
  "nissim": ['N IH1 S IH0 M'],
  "nissin": ['N IH1 S IH0 N'],
  "nissley": ['N IH1 S L IY0'],
  "nist": ['N IH1 S T'],
  "nistler": ['N IH1 S T L ER0'],
  "niswander": ['N IH1 S W AO0 N D ER0'],
  "niswonger": ['N IH1 S W AO0 NG ER0'],
  "nit": ['N IH1 T'],
  "nita": ['N IY1 T AH0'],
  "nite": ['N AY1 T'],
  "nithuekan": ['N IH2 TH W AH0 K AA1 N'],
  "nithuekan's": ['N IH2 TH W AH0 K AA1 N Z'],
  "nitka": ['N IH1 T K AH0'],
  "nito": ['N IY1 T OW0'],
  "nitpick": ['N IH1 T P IH0 K'],
  "nitpicking": ['N IH1 T P IH2 K IH0 NG'],
  "nitpicky": ['N IH1 T P IH2 K IY0'],
  "nitrate": ['N AY1 T R EY2 T'],
  "nitrates": ['N AY1 T R EY2 T S'],
  "nitrating": ['N AY1 T R EY2 T IH0 NG'],
  "nitration": ['N AY0 T R EY1 SH AH0 N'],
  "nitric": ['N AY1 T R IH0 K'],
  "nitride": ['N AY1 T R AY0 D'],
  "nitriles": ['N AY1 T R AH0 L Z'],
  "nitro": ['N IH1 T R OW0'],
  "nitrocellulose": ['N AY2 T R OW0 S EH1 L Y AH0 L OW2 S'],
  "nitrogen": ['N AY1 T R AH0 JH AH0 N'],
  "nitrogenous": ['N AY0 T R AA1 JH AH0 N AH0 S'],
  "nitroglycerin": ['N AY2 T R OW0 G L IH1 S ER0 AH0 N', 'N AY2 T R AH0 G L IH1 S ER0 AH0 N', 'N AY2 CH R AH0 G L IH1 S ER0 AH0 N'],
  "nitroglycerine": ['N AY2 T R OW0 G L IH1 S ER0 AH0 N', 'N AY2 T R AH0 G L IH1 S ER0 AH0 N', 'N AY2 CH R AH0 G L IH1 S ER0 AH0 N'],
  "nitrosamines": ['N IH0 T R AA2 S AH0 M IY1 N Z'],
  "nitrosomine": ['N IH0 T R AA2 S AH0 M IY1 N'],
  "nitrosomines": ['N IH0 T R AA2 S AH0 M IY1 N Z'],
  "nitrous": ['N IH1 T R AH0 S'],
  "nitsa": ['N IH1 T S AH0'],
  "nitsch": ['N IH1 CH'],
  "nitsche": ['N IH1 CH'],
  "nitschke": ['N IH1 CH K'],
  "nitta": ['N IH1 T AH0', 'N IY1 T AH0'],
  "nitto": ['N IH1 T OW0'],
  "nitty": ['N IH1 T IY0'],
  "nitz": ['N IH1 T S'],
  "nitza": ['N IH1 T Z AH0'],
  "nitza's": ['N IH1 T Z AH0 Z'],
  "nitzberg": ['N IH1 T S B ER0 G'],
  "nitze": ['N IH1 T S IY0'],
  "nitzel": ['N IH1 T Z AH0 L'],
  "nitzsche": ['N IH1 T Z SH', 'N IH1 T SH'],
  "niven": ['N AY1 V AH0 N'],
  "nivens": ['N AY1 V AH0 N Z'],
  "niver": ['N AY1 V ER0'],
  "nivison": ['N IH1 V IH0 S AH0 N'],
  "nix": ['N IH1 K S'],
  "nixdorf": ['N IH1 K S D AO2 R F'],
  "nixdorf's": ['N IH1 K S D AO2 R F S'],
  "nixed": ['N IH1 K S T'],
  "nixes": ['N IH1 K S IH0 Z'],
  "nixie": ['N IH1 K S IY0'],
  "nixon": ['N IH1 K S AH0 N'],
  "nixon's": ['N IH1 K S AH0 N Z'],
  "nixons": ['N IH1 K S AH0 N Z'],
  "nizar": ['N AY1 Z AA0 R'],
  "nizhny": ['N IH1 ZH N IY0'],
  "niziolek": ['N IH0 Z IY0 OW1 L EH0 K'],
  "niznik": ['N IH1 Z N IH0 K'],
  "nkohse": ['EH0 NG K OW1 S IY0'],
  "nmr": ['EH2 N EH2 M AA1 R'],
  "no": ['N OW1'],
  "no's": ['N OW1 Z'],
  "no-brainer": ['N OW2 B R EY1 N ER0'],
  "no-go": ['N OW1 G OW2'],
  "no-good": ['N OW1 G UH2 D'],
  "no-one": ['N OW1 W AH0 N'],
  "no-one's": ['N OW1 AH0 N Z'],
  "no-show": ['N OW1 SH OW2'],
  "noa": ['N OW1 AH0'],
  "noaa": ['N OW1 AH0', 'EH1 N OW1 EY1 EY1'],
  "noack": ['N OW1 K'],
  "noah": ['N OW1 AH0'],
  "noah's": ['N OW1 AH0 Z'],
  "noakes": ['N OW1 K S'],
  "noam": ['N OW1 M'],
  "noami": ['N OW1 M IY0'],
  "nobatoshi": ['N OW2 B AH0 T OW1 SH IY0'],
  "nobbe": ['N AA1 B'],
  "nobec": ['N OW0 B EH1 K'],
  "nobec's": ['N OW0 B EH1 K S'],
  "nobel": ['N OW0 B EH1 L'],
  "nobelium": ['N OW0 B EH1 L IY0 AH0 M'],
  "nobile": ['N AA1 B AH0 L'],
  "nobility": ['N OW0 B IH1 L AH0 T IY0'],
  "nobis": ['N OW1 B IH0 S'],
  "noble": ['N OW1 B AH0 L'],
  "noble's": ['N OW1 B AH0 L Z'],
  "nobleman": ['N OW1 B AH0 L M AH0 N'],
  "nobleman's": ['N OW1 B AH0 L M AH0 N Z'],
  "nobler": ['N OW1 B L ER0'],
  "nobles": ['N OW1 B AH0 L Z'],
  "noblesse": ['N OW0 B L EH1 S'],
  "noblest": ['N OW1 B L IH0 S T'],
  "noblet": ['N AA1 B L AH0 T'],
  "noblett": ['N AA1 B L IH0 T'],
  "noblewoman": ['N OW1 B AH0 L W UH2 M AH0 N'],
  "noblin": ['N AA1 B L IH0 N'],
  "noblitt": ['N AA1 B L IH0 T'],
  "nobly": ['N AA1 B L IY0'],
  "nobodies": ['N OW1 B AA2 D IY2 Z', 'N OW1 B AH0 D IY0 Z'],
  "nobody": ['N OW1 B AA2 D IY2', 'N OW1 B AH0 D IY0'],
  "nobody'd": ['N OW1 B AA2 D IY2 D', 'N OW1 B AH0 D IY0 D'],
  "nobody's": ['N OW1 B AA2 D IY2 Z', 'N OW1 B AH0 D IY0 Z'],
  "noboru": ['N OW0 B AO1 R UW0'],
  "nobrega": ['N AA1 B R IH0 G AH0'],
  "nobriga": ['N AA1 B R IH0 G AH0'],
  "nobuaki": ['N OW2 B UW0 AA1 K IY0'],
  "nobuo": ['N OW0 B UW1 OW0'],
  "nobuto": ['N OW0 B UW1 T OW0'],
  "nobuyuki": ['N OW2 B UW0 Y UW1 K IY0'],
  "noce": ['N OW1 S'],
  "nocella": ['N OW0 CH EH1 L AH0'],
  "nocera": ['N OW0 CH EH1 R AH0'],
  "nock": ['N AA1 K'],
  "nockard": ['N AA1 K ER0 D'],
  "nocturnal": ['N AA0 K T ER1 N AH0 L'],
  "nod": ['N AA1 D'],
  "noda": ['N OW1 D AH0'],
  "nodded": ['N AA1 D AH0 D', 'N AA1 D IH0 D'],
  "nodding": ['N AA1 D IH0 NG'],
  "node": ['N OW1 D'],
  "nodes": ['N OW1 D Z'],
  "nodine": ['N OW0 D IY1 N IY0'],
  "nods": ['N AA1 D Z'],
  "nodular": ['N AA1 JH AH0 L ER0'],
  "nodule": ['N AA1 JH UW0 L'],
  "nodules": ['N AA1 JH UW0 L Z'],
  "noe": ['N OW1'],
  "noecker": ['N OW1 K ER0'],
  "noel": ['N OW0 EH1 L'],
  "noel's": ['N OW0 EH1 L Z'],
  "noell": ['N OW1 L'],
  "noelle": ['N OW0 EH1 L'],
  "noes": ['N OW1 Z'],
  "noeth": ['N OW1 TH'],
  "noffke": ['N AA1 F K'],
  "noffsinger": ['N AA1 F S IH0 N JH ER0'],
  "nofsinger": ['N AA1 F S IH0 N JH ER0'],
  "noftsker": ['N AO1 F T S K ER0'],
  "nofziger": ['N AO1 F Z IH0 G ER0'],
  "noga": ['N OW1 G AH0'],
  "nogales": ['N OW0 G AA1 L EH0 S'],
  "nogawa": ['N OW0 G AA1 W AH0'],
  "noggle": ['N AA1 G AH0 L'],
  "nogle": ['N OW1 G AH0 L'],
  "noguchi": ['N OW0 G UW1 CH IY0'],
  "nogueira": ['N OW0 G EY1 R AA0'],
  "noguera": ['N OW0 G EY1 R AA0'],
  "noh": ['N OW1'],
  "nohl": ['N OW1 L'],
  "nohr": ['N AO1 R'],
  "noiman": ['N OY1 M AH0 N'],
  "noir": ['N OY1 R'],
  "noirs": ['N OY1 R Z'],
  "noise": ['N OY1 Z'],
  "noises": ['N OY1 Z IH0 Z'],
  "noisier": ['N OY1 Z IY0 ER0'],
  "noisiest": ['N OY1 Z IY0 AH0 S T'],
  "noisily": ['N OY1 Z AH0 L IY0'],
  "noisy": ['N OY1 Z IY0'],
  "nokes": ['N OW1 K S'],
  "nokia": ['N OW1 K IY0 AH0'],
  "nokia's": ['N OW1 K IY0 AH0 Z'],
  "nokyo": ['N OW1 K Y OW0'],
  "nola": ['N OW1 L AH0'],
  "nolan": ['N OW1 L AH0 N'],
  "nolana": ['N OW0 L AE1 N AH0'],
  "noland": ['N OW1 L AH0 N D'],
  "nolanda": ['N AH0 L AE1 N D AH0'],
  "nolasco": ['N OW0 L AA1 S K OW0'],
  "nold": ['N OW1 L D'],
  "nolde": ['N OW1 L D'],
  "nolden": ['N OW1 L D AH0 N'],
  "nolder": ['N OW1 L D ER0'],
  "nole": ['N OW1 L'],
  "nolen": ['N AA1 L AH0 N'],
  "noles": ['N OW1 L Z'],
  "nolet": ['N OW1 L IH0 T'],
  "noleta": ['N OW0 L EH1 T AH0'],
  "nolette": ['N OW2 L EH1 T'],
  "nolf": ['N OW1 L F'],
  "nolie": ['N AA1 L IY0'],
  "nolin": ['N OW1 L IH0 N'],
  "nolita": ['N OW0 L IY1 T AH0'],
  "noll": ['N OW1 L'],
  "nolla": ['N OW1 L AH0'],
  "nollan": ['N AA1 L AH0 N'],
  "noller": ['N OW1 L ER0'],
  "nolley": ['N AA1 L IY0'],
  "nollie": ['N OW1 L IY0'],
  "nolls": ['N OW1 L Z'],
  "nolo": ['N OW1 L OW0'],
  "nolt": ['N OW1 L T'],
  "nolte": ['N OW1 L T'],
  "nolting": ['N OW1 L T IH0 NG'],
  "nom": ['N AA1 M'],
  "nomad": ['N OW1 M AE2 D'],
  "nomad's": ['N OW1 M AE2 D Z'],
  "nomadic": ['N OW0 M AE1 D IH0 K'],
  "nomads": ['N OW1 M AE2 D Z'],
  "nome": ['N OW1 M'],
  "nome's": ['N OW1 M Z'],
  "nomenclatorial": ['N OW2 M IH0 N K L AH0 T AO1 R IY0 AH0 L'],
  "nomenclatural": ['N OW0 M AH0 N K L EY1 CH ER0 AH0 L'],
  "nomenclature": ['N OW1 M AH0 N K L EY2 CH ER0'],
  "nomenklatura": ['N OW0 M EH2 NG K L AH0 CH UH1 R AH0'],
  "nomi": ['N OW1 M IY0'],
  "nominal": ['N AA1 M AH0 N AH0 L'],
  "nominally": ['N AA1 M AH0 N AH0 L IY0'],
  "nominate": ['N AA1 M AH0 N AH0 T', 'N AA1 M AH0 N EY2 T'],
  "nominated": ['N AA1 M AH0 N EY2 T AH0 D'],
  "nominates": ['N AA1 M AH0 N EY2 T S', 'N AA1 M AH0 N AH0 T S'],
  "nominating": ['N AA1 M AH0 N EY2 T IH0 NG'],
  "nomination": ['N AA2 M AH0 N EY1 SH AH0 N'],
  "nominations": ['N AA2 M AH0 N EY1 SH AH0 N Z'],
  "nominee": ['N AA2 M AH0 N IY1'],
  "nominee's": ['N AA2 M AH0 N IY1 Z'],
  "nominees": ['N AA2 M AH0 N IY1 Z'],
  "nomo": ['N OW1 M OW0'],
  "nomura": ['N OW0 M UH1 R AH0'],
  "nomura's": ['N OW0 M UH1 R AH0 Z', 'N UW1 M ER0 AH0 Z'],
  "non": ['N AA1 N'],
  "non-academic": ['N AA0 N AE2 K AH0 D EH1 M IH0 K'],
  "non-agricultural": ['N AA2 N AE2 G R IH0 K AH1 L CH ER0 AH0 L'],
  "non-alcoholic": ['N AA2 N AE2 L K AH0 HH AA1 L IH0 K'],
  "non-align": ['N AA1 N AH0 L AY2 N'],
  "non-aligned": ['N AA1 N AH0 L AY2 N D'],
  "non-automotive": ['N AA2 N AO2 T OW0 M OW1 T IH0 V'],
  "non-bank": ['N AA1 N B AE1 NG K'],
  "non-banking": ['N AA1 N B AE1 NG K IH0 NG'],
  "non-believer": ['N AA2 N B IH0 L IY1 V ER0'],
  "non-believers": ['N AA2 N B IH0 L IY1 V ER0 Z'],
  "non-binding": ['N AA1 N B AY1 N D IH0 NG'],
  "non-biting": ['N AA0 N B AY1 T IH0 NG'],
  "non-building": ['N AA1 N B IH1 L D IH0 NG'],
  "non-business": ['N AA1 N B IH1 Z N AH0 S'],
  "non-callable": ['N AA0 N K AO1 L AH0 B AH0 L'],
  "non-cash": ['N AA1 N K AE1 SH'],
  "non-catholic": ['N AA0 N K AE1 TH L IH0 K'],
  "non-catholics": ['N AA0 N K AE1 TH L IH0 K S'],
  "non-combatant": ['N AA2 N K AH0 M B AE1 T AH0 N T'],
  "non-combatants": ['N AA2 N K AH0 M B AE1 T AH0 N T S'],
  "non-commercial": ['N AA1 N K AH0 M ER1 SH AH0 L'],
  "non-committal": ['N AA1 N K AH0 M IH1 T AH0 L'],
  "non-communist": ['N AA1 N K AA1 M Y UW0 N IH0 S T'],
  "non-communists": ['N AA1 N K AA1 M Y UW0 N IH0 S T S'],
  "non-compete": ['N AA0 N K AH0 M P IY1 T'],
  "non-competitive": ['N AA2 N K AH0 M P EH1 T AH0 T IH0 V'],
  "non-compliance": ['N AA2 N K AH0 M P L AY1 AH0 N S'],
  "non-conformist": ['N AA2 N K AH0 N F AO1 R M IH0 S T'],
  "non-conformists": ['N AA2 N K AH0 N F AO1 R M AH0 S T S'],
  "non-conformity": ['N AA2 N K AH0 N F AO1 R M AH0 T IY0'],
  "non-contract": ['N AA0 N K AA1 N T R AE2 K T'],
  "non-controversial": ['N AA0 N K AA2 N T R AH0 V ER1 SH AH0 L'],
  "non-convertible": ['N AA0 N K AH0 N V ER1 T AH0 B AH0 L'],
  "non-core": ['N AA1 N K AO1 R'],
  "non-corporate": ['N AA0 N K AO1 R P R AH0 T'],
  "non-criminal": ['N AA0 N K R IH1 M IH0 N AH0 L'],
  "non-cumulative": ['N AA0 N K Y UW1 M Y AH0 L AH0 T IH0 V'],
  "non-deductible": ['N AA0 N D IH0 D AH1 K T IH0 B AH0 L'],
  "non-defense": ['N AA0 N D IH0 F EH1 N S'],
  "non-descript": ['N AA1 N D IH0 S K R IH1 P T'],
  "non-direct": ['N AA1 N D ER0 EH1 K T'],
  "non-discriminate": ['N AA0 N D IH2 S K R IH1 M AH0 N AH0 T'],
  "non-discrimination": ['N AA0 N D IH2 S K R IH0 M IH0 N EY1 SH AH0 N'],
  "non-discriminatory": ['N AA1 N D IH0 S K R IH1 M AH0 N AH0 T AO2 R IY0'],
  "non-dollar": ['N AA1 N D AA1 L ER0'],
  "non-durable": ['N AA0 N D UH1 R AH0 B AH0 L'],
  "non-durables": ['N AA0 N D UH1 R AH0 B AH0 L Z'],
  "non-economic": ['N AA2 N EH2 K AH0 N AA1 M IH0 K'],
  "non-electrical": ['N AA0 N IH0 L EH1 K T R IH0 K AH0 L'],
  "non-emergency": ['N AA2 N IH0 M ER1 JH AH0 N S IY0'],
  "non-entity": ['N AA0 N EH1 N T AH0 T IY0'],
  "non-essential": ['N AA2 N IH0 S EH1 N SH AH0 L'],
  "non-event": ['N AA1 N IH0 V EH1 N T'],
  "non-exclusive": ['N AA2 N IH0 K S K L UW1 S IH0 V'],
  "non-executive": ['N AA1 N IH0 G Z EH1 K Y AH0 T IH0 V'],
  "non-existent": ['N AA2 N AH0 G Z IH1 S T AH0 N T'],
  "non-farm": ['N AA1 N F AA1 R M'],
  "non-fat": ['N AA1 N F AE1 T'],
  "non-fatal": ['N AA1 N F EY1 T AH0 L'],
  "non-ferrous": ['N AA0 N F EH1 R AH0 S'],
  "non-fiction": ['N AA0 N F IH1 K SH AH0 N'],
  "non-financial": ['N AA0 N F AH0 N AE1 N SH AH0 L', 'N AA0 N F AY0 N AE1 N SH AH0 L'],
  "non-food": ['N AA1 N F UW1 D'],
  "non-government": ['N AA0 N G AH1 V ER0 N M AH0 N T'],
  "non-governmental": ['N AA0 N G AH2 V ER0 N M EH1 N T AH0 L'],
  "non-greek": ['N AA2 N G R IY1 K'],
  "non-human": ['N AA0 N HH Y UW1 M AH0 N'],
  "non-inflationary": ['N AA2 N IH0 N F L EY1 SH AH0 N EH2 R IY0'],
  "non-interest": ['N AA0 N IH1 N T R AH0 S T'],
  "non-interference": ['N AA2 N IH2 N T ER0 F IH1 R AH0 N S'],
  "non-intervention": ['N AA2 N IH2 N T ER0 V EH1 N SH AH0 N'],
  "non-jet": ['N AA1 N JH EH1 T'],
  "non-jets": ['N AA1 N JH EH1 T S'],
  "non-lethal": ['N AA0 N L IY1 TH AH0 L'],
  "non-linear": ['N AA0 N L IH1 N IY2 ER0'],
  "non-liturgical": ['N AA0 N L AH0 T ER1 JH IH0 K AH0 L'],
  "non-management": ['N AA0 N M AE1 N IH0 JH M AH0 N T'],
  "non-manufacturing": ['N AA2 N M AE2 N Y AH0 F AE1 K CH ER0 IH0 NG'],
  "non-member": ['N AA0 N M EH1 M B ER0'],
  "non-members": ['N AA0 N M EH1 M B ER0 Z'],
  "non-military": ['N AA0 N M IH1 L AH0 T EH2 R IY0'],
  "non-native": ['N AA1 N EY1 T IH0 V'],
  "non-natives": ['N AA1 N EY1 T IH0 V Z'],
  "non-negotiable": ['N AA2 N AH0 G OW1 SH AH0 B AH0 L'],
  "non-nemacher": ['N AA1 N IH0 M AH0 K ER0'],
  "non-nonsense": ['N AA0 N N AA1 N S EH2 N S'],
  "non-nuclear": ['N AA1 N UW1 K L IY2 ER0'],
  "non-operating": ['N AA1 N AO1 P ER0 EY2 T IH0 NG'],
  "non-pacifist": ['N AA2 N P AE1 S IH0 F IH0 S T'],
  "non-pacifists": ['N AA2 N P AE1 S IH0 F IH0 S T S'],
  "non-partisan": ['N AA0 N P AA1 R T AH0 Z AH0 N'],
  "non-paying": ['N AA0 N P EY1 IH0 NG'],
  "non-payment": ['N AA0 N P EY1 M AH0 N T'],
  "non-performing": ['N AA0 N P ER0 F AO1 R M IH0 NG'],
  "non-planar": ['N AA0 N P L EY1 N ER0'],
  "non-pluss": ['N AA0 N P L AH1 S'],
  "non-plussed": ['N AA0 N P L AH1 S T'],
  "non-poisonous": ['N AA0 N P OY1 Z AH0 N AH0 S'],
  "non-political": ['N AA2 N P AH0 L IH1 T IH0 K AH0 L'],
  "non-prescription": ['N AA2 N P R AH0 S K R IH1 P SH AH0 N'],
  "non-productive": ['N AA2 N P R AH0 D AH1 K T IH0 V'],
  "non-professional": ['N AA2 N P R AH0 F EH1 SH AH0 N AH0 L'],
  "non-professionals": ['N AA0 N P R AH0 F EH1 SH AH0 N AH0 L Z'],
  "non-profit": ['N AA2 N P R AO1 F IH0 T', 'N AA0 N P R AO1 F AH0 T'],
  "non-profits": ['N AA1 N P R AO1 F IH0 T S'],
  "non-proliferation": ['N AA0 N P R AO0 L IH2 F ER0 EY1 SH AH0 N'],
  "non-public": ['N AA0 N P AH1 B L IH0 K'],
  "non-qualified": ['N AA0 N K W AA1 L AH0 F AY2 D'],
  "non-racial": ['N AA0 N R EY1 SH AH0 L'],
  "non-recurring": ['N AA0 N R IH0 K ER1 IH0 NG'],
  "non-refundable": ['N AA0 N R IH0 F AH1 N D AH0 B AH0 L'],
  "non-regulated": ['N AA0 N R EH1 G Y AH0 L EY2 T IH0 D'],
  "non-religious": ['N AA2 N R IH0 L IH1 JH AH0 S'],
  "non-renewable": ['N AA0 N R IY0 N UW1 AH0 B AH0 L'],
  "non-resident": ['N AA0 N R EH1 Z AH0 D AH0 N T'],
  "non-residential": ['N AA2 N R EH2 Z AH0 D EH1 N SH AH0 L'],
  "non-residents": ['N AA0 N R EH1 Z AH0 D AH0 N T S'],
  "non-responsive": ['N AA0 N R IH0 S P AA1 N S IH0 V'],
  "non-restricted": ['N AA0 N R IH2 S T R IH1 K T IH0 D', 'N AA0 N R IY2 S T R IH1 K T IH0 D'],
  "non-roman": ['N AA0 N R OW1 M AH0 N'],
  "non-ruling": ['N AA0 N R UW1 L IH0 NG'],
  "non-sectarian": ['N AA2 N S EH0 K T EH1 R IY0 AH0 N'],
  "non-sense": ['N AA1 N S EH0 N S'],
  "non-sequitor": ['N AA1 N S EH1 K W IH0 T ER0'],
  "non-sequitors": ['N AA1 N S EH1 K W IH0 T ER0 Z'],
  "non-sequitur": ['N AA1 N S EH1 K W IH0 T ER0'],
  "non-sequiturs": ['N AA1 N S EH1 K W IH0 T ER0 Z'],
  "non-smoker": ['N AA0 N S M OW1 K ER0'],
  "non-smokers": ['N AA0 N S M OW1 K ER0 Z'],
  "non-smokers'": ['N AA1 N S M OW1 K ER0 Z'],
  "non-smoking": ['N AA0 N S M OW1 K IH0 NG'],
  "non-specific": ['N AA2 N S P AH0 S IH1 F IH0 K'],
  "non-sporting": ['N AA0 N S P AO1 R T IH0 NG'],
  "non-standard": ['N AA0 N S T AE1 N D ER0 D'],
  "non-starter": ['N AA0 N S T AA1 R T ER0'],
  "non-starters": ['N AA0 N S T AA1 R T ER0 Z'],
  "non-stick": ['N AA0 N S T IH1 K'],
  "non-stop": ['N AA2 N S T AA1 P'],
  "non-stops": ['N AA2 N S T AA1 P S'],
  "non-strategic": ['N AA0 N S T R AH0 T IY1 JH IH0 K'],
  "non-supervisory": ['N AA0 N S UW2 P ER0 V AY1 Z ER0 IY0'],
  "non-surgical": ['N AA0 N S ER1 JH IH0 K AH0 L'],
  "non-taxable": ['N AA0 N T AE1 K S AH0 B AH0 L'],
  "non-technical": ['N AA0 N T EH1 K N IH0 K AH0 L'],
  "non-toxic": ['N AA0 N T AA1 K S IH0 K'],
  "non-traditional": ['N AA2 N T R AH0 D IH1 SH AH0 N AH0 L'],
  "non-transferable": ['N AA2 N T R AE2 N S F ER1 AH0 B AH0 L'],
  "non-turbo": ['N AO0 N T ER1 B OW0'],
  "non-turbos": ['N AO0 N T ER1 B OW0 Z'],
  "non-union": ['N AA0 N Y UW1 N Y AH0 N'],
  "non-unionized": ['N AA0 N Y UW1 N Y AH0 N AY2 Z D'],
  "non-utility": ['N AA2 N Y UW0 T IH1 L IH0 T IY0'],
  "non-verbal": ['N AA0 N V ER1 B AH0 L'],
  "non-violence": ['N AA0 N V AY1 AH0 L AH0 N S'],
  "non-violent": ['N AA0 N V AY1 AH0 L AH0 N T'],
  "non-volatile": ['N AA0 N V AA1 L AH0 T AH0 L'],
  "non-voting": ['N AA0 N V OW1 T IH0 NG'],
  "non-white": ['N AA1 N W AY1 T', 'N AA1 N HH W AY1 T'],
  "non-whites": ['N AA1 N W AY1 T S', 'N AA1 N HH W AY1 T S'],
  "non-woven": ['N AA1 N W OW1 V IH0 N'],
  "non-wovens": ['N AA1 N W OW1 V AH0 N Z'],
  "non-zero": ['N AA0 N Z IY1 R OW0'],
  "non-zero-sum": ['N AA0 N Z IY1 R OW0 S AH2 M'],
  "nona": ['N AA1 N AA0'],
  "nonacademic": ['N AA0 N AE2 K AH0 D EH1 M IH0 K'],
  "nonaccrual": ['N AA2 N AH0 K R UW1 AH0 L'],
  "nonaccruing": ['N AA2 N AH0 K R UW1 IH0 NG'],
  "nonaggression": ['N AA2 N AH0 G R EH1 SH AH0 N'],
  "nonagricultural": ['N AA2 N AE2 G R IH0 K AH1 L CH ER0 AH0 L'],
  "nonalcoholic": ['N AA2 N AE2 L K AH0 HH AA1 L IH0 K'],
  "nonalign": ['N AA1 N AH0 L AY2 N'],
  "nonaligned": ['N AA1 N AH0 L AY2 N D'],
  "nonautomotive": ['N AA2 N AO2 T OW0 M OW1 T IH0 V'],
  "nonbank": ['N AA1 N B AE1 NG K'],
  "nonbanking": ['N AA1 N B AE1 NG K IH0 NG'],
  "nonbeliever": ['N AA2 N B AH0 L IY1 V ER0'],
  "nonbelievers": ['N AA2 N B AH0 L IY1 V ER0 Z'],
  "nonbinding": ['N AA1 N B AY1 N D IH0 NG'],
  "nonbiting": ['N AA0 N B AY1 T IH0 NG'],
  "nonbuilding": ['N AA1 N B IH1 L D IH0 NG'],
  "nonbusiness": ['N AA1 N B IH1 Z N AH0 S'],
  "noncallable": ['N AA0 N K AO1 L AH0 B AH0 L'],
  "noncash": ['N AA1 N K AE1 SH'],
  "nonce": ['N AA1 N S'],
  "nonchalance": ['N AA1 N SH AH0 L AA1 N S'],
  "nonchalant": ['N AA2 N SH AH0 L AA1 N T'],
  "nonchalantly": ['N AA1 N SH AH0 L AA1 N T L IY0'],
  "noncolor": ['N AA0 N K AH1 L ER0'],
  "noncombatant": ['N AA2 N K AH0 M B AE1 T AH0 N T'],
  "noncombatants": ['N AA2 N K AH0 M B AE1 T AH0 N T S'],
  "noncommercial": ['N AA1 N K AH0 M ER1 SH AH0 L'],
  "noncommittal": ['N AA1 N K AH0 M IH1 T AH0 L'],
  "noncommunist": ['N AA1 N K AA1 M Y UW0 N IH0 S T'],
  "noncommunists": ['N AA1 N K AA1 M Y UW0 N IH0 S T S'],
  "noncompete": ['N AA0 N K AH0 M P IY1 T'],
  "noncompetitive": ['N AA2 N K AH0 M P EH1 T AH0 T IH0 V'],
  "noncompliance": ['N AA2 N K AH0 M P L AY1 AH0 N S'],
  "nonconformist": ['N AA2 N K AH0 N F AO1 R M IH0 S T'],
  "nonconformists": ['N AA2 N K AH0 N F AO1 R M AH0 S T S'],
  "nonconformity": ['N AA2 N K AH0 N F AO1 R M AH0 T IY0'],
  "noncontract": ['N AA0 N K AA1 N T R AE2 K T'],
  "noncontroversial": ['N AA0 N K AA2 N T R AH0 V ER1 SH AH0 L'],
  "nonconvertible": ['N AA0 N K AH0 N V ER1 T AH0 B AH0 L'],
  "noncore": ['N AA1 N K AO1 R'],
  "noncorporate": ['N AA0 N K AO1 R P R AH0 T'],
  "noncriminal": ['N AA0 N K R IH1 M IH0 N AH0 L'],
  "noncumulative": ['N AA0 N K Y UW1 M Y AH0 L AH0 T IH0 V'],
  "nondeductible": ['N AA0 N D IH0 D AH1 K T IH0 B AH0 L'],
  "nondefense": ['N AA0 N D IH0 F EH1 N S'],
  "nondescript": ['N AA1 N D IH0 S K R IH1 P T'],
  "nondirect": ['N AA1 N D ER0 EH1 K T'],
  "nondiscriminate": ['N AA0 N D IH2 S K R IH1 M AH0 N AH0 T'],
  "nondiscrimination": ['N AA0 N D IH2 S K R IH0 M IH0 N EY1 SH AH0 N'],
  "nondiscriminatory": ['N AA1 N D IH0 S K R IH1 M AH0 N AH0 T AO2 R IY0'],
  "nondollar": ['N AA1 N D AA1 L ER0'],
  "nondurable": ['N AA0 N D UH1 R AH0 B AH0 L'],
  "nondurables": ['N AA0 N D UH1 R AH0 B AH0 L Z'],
  "none": ['N AH1 N'],
  "noneconomic": ['N AA2 N EH2 K AH0 N AA1 M IH0 K'],
  "nonelectrical": ['N AA0 N IH0 L EH1 K T R IH0 K AH0 L'],
  "nonemergency": ['N AA2 N IH0 M ER1 JH AH0 N S IY0'],
  "nonentity": ['N AA0 N EH1 N T AH0 T IY0'],
  "nonessential": ['N AA2 N IH0 S EH1 N SH AH0 L'],
  "nonesuch": ['N AH1 N S AH1 CH'],
  "nonetheless": ['N AH2 N DH AH0 L EH1 S'],
  "nonevent": ['N AA1 N IH0 V EH1 N T'],
  "nonexclusive": ['N AA2 N IH0 K S K L UW1 S IH0 V'],
  "nonexecutive": ['N AA1 N IH0 G Z EH1 K Y AH0 T IH0 V'],
  "nonexistent": ['N AA2 N AH0 G Z IH1 S T AH0 N T'],
  "nonfarm": ['N AA1 N F AA1 R M'],
  "nonfat": ['N AA1 N F AE1 T'],
  "nonfatal": ['N AA1 N F EY1 T AH0 L'],
  "nonferrous": ['N AA0 N F EH1 R AH0 S'],
  "nonfiction": ['N AA0 N F IH1 K SH AH0 N'],
  "nonfinancial": ['N AA0 N F AH0 N AE1 N SH AH0 L', 'N AA0 N F AY0 N AE1 N SH AH0 L'],
  "nonfood": ['N AA1 N F UW1 D'],
  "nongovernment": ['N AA0 N G AH1 V ER0 N M AH0 N T'],
  "nongovernmental": ['N AA0 N G AH2 V ER0 N M EH1 N T AH0 L'],
  "nongreek": ['N AA2 N G R IY1 K'],
  "nonhuman": ['N AA0 N HH Y UW1 M AH0 N'],
  "nonie": ['N AA1 N IY0'],
  "noninflationary": ['N AA2 N IH0 N F L EY1 SH AH0 N EH2 R IY0'],
  "noninterest": ['N AA0 N IH1 N T R AH0 S T'],
  "noninterference": ['N AA2 N IH2 N T ER0 F IH1 R AH0 N S'],
  "nonintervention": ['N AA2 N IH2 N T ER0 V EH1 N SH AH0 N'],
  "nonjet": ['N AA1 N JH EH1 T'],
  "nonjets": ['N AA1 N JH EH1 T S'],
  "nonlethal": ['N AA0 N L IY1 TH AH0 L'],
  "nonlinear": ['N AA0 N L IH1 N IY2 ER0'],
  "nonliturgical": ['N AA0 N L AH0 T ER1 JH IH0 K AH0 L'],
  "nonmanagement": ['N AA0 N M AE1 N IH0 JH M AH0 N T'],
  "nonmanufacturing": ['N AA2 N M AE2 N Y AH0 F AE1 K CH ER0 IH0 NG'],
  "nonmember": ['N AA0 N M EH1 M B ER0'],
  "nonmembers": ['N AA0 N M EH1 M B ER0 Z'],
  "nonmilitary": ['N AA0 N M IH1 L AH0 T EH2 R IY0'],
  "nonnative": ['N AA1 N EY1 T IH0 V'],
  "nonnatives": ['N AA1 N EY1 T IH0 V Z'],
  "nonnegotiable": ['N AA2 N AH0 G OW1 SH AH0 B AH0 L'],
  "nonnemacher": ['N AA1 N IH0 M AH0 K ER0'],
  "nonnuclear": ['N AA1 N UW1 K L IY2 ER0'],
  "nonoperating": ['N AA1 N AO1 P ER0 EY2 T IH0 NG'],
  "nonpacifist": ['N AA2 N P AE1 S IH0 F IH0 S T'],
  "nonpacifists": ['N AA2 N P AE1 S IH0 F IH0 S T S'],
  "nonpartisan": ['N AA0 N P AA1 R T AH0 Z AH0 N'],
  "nonpaying": ['N AA0 N P EY1 IH0 NG'],
  "nonpayment": ['N AA0 N P EY1 M AH0 N T'],
  "nonperforming": ['N AA0 N P ER0 F AO1 R M IH0 NG'],
  "nonplanar": ['N AA0 N P L EY1 N ER0'],
  "nonpluss": ['N AA0 N P L AH1 S'],
  "nonplussed": ['N AA0 N P L AH1 S T'],
  "nonpoisonous": ['N AA0 N P OY1 Z AH0 N AH0 S'],
  "nonpolitical": ['N AA2 N P AH0 L IH1 T IH0 K AH0 L'],
  "nonprescription": ['N AA2 N P R AH0 S K R IH1 P SH AH0 N'],
  "nonproductive": ['N AA2 N P R AH0 D AH1 K T IH0 V'],
  "nonprofessional": ['N AA2 N P R AH0 F EH1 SH AH0 N AH0 L'],
  "nonprofessionals": ['N AA0 N P R AH0 F EH1 SH AH0 N AH0 L Z'],
  "nonprofit": ['N AA0 N P R AA1 F AH0 T'],
  "nonprofits": ['N AA1 N P R AA1 F IH0 T S'],
  "nonproliferation": ['N AA0 N P R AH0 L IH2 F ER0 EY1 SH AH0 N'],
  "nonpublic": ['N AA0 N P AH1 B L IH0 K'],
  "nonqualified": ['N AA0 N K W AA1 L AH0 F AY2 D'],
  "nonracial": ['N AA0 N R EY1 SH AH0 L'],
  "nonrecurring": ['N AA0 N R IH0 K ER1 IH0 NG'],
  "nonrefundable": ['N AA0 N R IH0 F AH1 N D AH0 B AH0 L'],
  "nonregulated": ['N AA0 N R EH1 G Y AH0 L EY2 T IH0 D'],
  "nonreligious": ['N AA2 N R IH0 L IH1 JH AH0 S'],
  "nonrenewable": ['N AA0 N R IY0 N UW1 AH0 B AH0 L'],
  "nonresident": ['N AA0 N R EH1 Z AH0 D AH0 N T'],
  "nonresidential": ['N AA2 N R EH2 Z AH0 D EH1 N SH AH0 L'],
  "nonresidents": ['N AA0 N R EH1 Z AH0 D AH0 N T S'],
  "nonresponsive": ['N AA0 N R IH0 S P AA1 N S IH0 V'],
  "nonrestricted": ['N AA0 N R IH0 S T R IH1 K T IH0 D', 'N AA0 N R IY0 S T R IH1 K T IH0 D'],
  "nonroman": ['N AA0 N R OW1 M AH0 N'],
  "nonruling": ['N AA0 N R UW1 L IH0 NG'],
  "nonsectarian": ['N AA2 N S EH0 K T EH1 R IY0 AH0 N'],
  "nonsense": ['N AA1 N S EH0 N S'],
  "nonsensical": ['N AA0 N S EH1 N S IH0 K AH0 L'],
  "nonsmoker": ['N AA0 N S M OW1 K ER0'],
  "nonsmokers": ['N AA0 N S M OW1 K ER0 Z'],
  "nonsmokers'": ['N AA1 N S M OW1 K ER0 Z'],
  "nonsmoking": ['N AA1 N S M OW1 K IH0 NG'],
  "nonspecific": ['N AA2 N S P AH0 S IH1 F IH0 K'],
  "nonsporting": ['N AA0 N S P AO1 R T IH0 NG'],
  "nonstandard": ['N AA0 N S T AE1 N D ER0 D'],
  "nonstarter": ['N AA0 N S T AA1 R T ER0'],
  "nonstarters": ['N AA0 N S T AA1 R T ER0 Z'],
  "nonstick": ['N AA0 N S T IH1 K'],
  "nonstop": ['N AA2 N S T AA1 P'],
  "nonstops": ['N AA2 N S T AA1 P S'],
  "nonstrategic": ['N AA0 N S T R AH0 T IY1 JH IH0 K'],
  "nonsurgical": ['N AA0 N S ER1 JH IH0 K AH0 L'],
  "nontaxable": ['N AA0 N T AE1 K S AH0 B AH0 L'],
  "nontechnical": ['N AA0 N T EH1 K N IH0 K AH0 L'],
  "nontoxic": ['N AA0 N T AA1 K S IH0 K'],
  "nontraditional": ['N AA2 N T R AH0 D IH1 SH AH0 N AH0 L'],
  "nontransferable": ['N AA2 N T R AE2 N S F ER1 AH0 B AH0 L'],
  "nonturbo": ['N AO0 N T ER1 B OW0'],
  "nonturbos": ['N AO0 N T ER1 B OW0 Z'],
  "nonunion": ['N AA0 N Y UW1 N Y AH0 N'],
  "nonunionized": ['N AA0 N Y UW1 N Y AH0 N AY2 Z D'],
  "nonutility": ['N AA2 N Y UW0 T IH1 L IH0 T IY0'],
  "nonverbal": ['N AA0 N V ER1 B AH0 L'],
  "nonviolence": ['N AA0 N V AY1 AH0 L AH0 N S'],
  "nonviolent": ['N AA0 N V AY1 AH0 L AH0 N T'],
  "nonvolatile": ['N AA0 N V AA1 L AH0 T AH0 L'],
  "nonvoting": ['N AA0 N V OW1 T IH0 NG'],
  "nonwhite": ['N AA1 N W AY1 T', 'N AA1 N HH W AY1 T'],
  "nonwhites": ['N AA1 N W AY1 T S', 'N AA1 N HH W AY1 T S'],
  "nonwoven": ['N AA1 N W OW1 V IH0 N'],
  "nonwovens": ['N AA1 N W OW1 V AH0 N Z'],
  "noodle": ['N UW1 D AH0 L'],
  "noodles": ['N UW1 D AH0 L Z'],
  "nook": ['N UH1 K'],
  "nooks": ['N UH1 K S'],
  "noon": ['N UW1 N'],
  "noonan": ['N UW1 N AH0 N'],
  "noone": ['N UW1 N'],
  "nooney": ['N UW1 N IY0'],
  "noons": ['N UW1 N Z'],
  "noontime": ['N UW1 N T AY2 M'],
  "noorda": ['N UH1 R D AH0'],
  "noose": ['N UW1 S'],
  "nope": ['N OW1 P'],
  "nor": ['N AO1 R'],
  "nora": ['N AO1 R AH0'],
  "nora's": ['N AO1 R AH0 Z'],
  "norah": ['N AO1 R AH0'],
  "noramco": ['N AO0 R AE1 M K OW0'],
  "noranda": ['N AO0 R AE1 N D AH0'],
  "noranda's": ['N AO0 R AE1 N D AH0 Z'],
  "norba": ['N AO1 R B AH0'],
  "norbeck": ['N AO1 R B EH0 K'],
  "norberg": ['N AO1 R B ER0 G'],
  "norbert": ['N AO1 R B ER0 T'],
  "norberta": ['N AO0 R B EH1 R T AH0'],
  "norberto": ['N AO0 R B ER1 T OW2'],
  "norbury": ['N AO1 R B EH0 R IY0'],
  "norby": ['N AO1 R B IY0'],
  "norcen": ['N AO1 R S AH0 N'],
  "norcen's": ['N AO1 R S AH0 N Z'],
  "norcia": ['N AO1 R CH AH0'],
  "norco": ['N AO1 R K OW0'],
  "norcross": ['N AO1 R K R AO0 S'],
  "nord": ['N AO1 R D'],
  "nordahl": ['N AO1 R D AA0 L'],
  "nordan": ['N AO1 R D AH0 N'],
  "nordbanken": ['N AO1 R D B AE2 NG K AH0 N'],
  "nordberg": ['N AO1 R D B ER0 G'],
  "nordby": ['N AO1 R D B IY0'],
  "nordeen": ['N ER0 D IY1 N'],
  "nordell": ['N AO1 R D AH0 L'],
  "norden": ['N AO1 R D AH0 N'],
  "norder": ['N AO1 R D ER0'],
  "nordgren": ['N AO1 R D G R EH0 N'],
  "nordic": ['N AO1 R D IH0 K'],
  "nordica": ['N AO1 R D IH0 K AH0'],
  "nordictrack": ['N AO1 R D IH0 K T R AE2 K'],
  "nordin": ['N AO1 R D IH0 N'],
  "nordine": ['N AO0 R D IY1 N IY0'],
  "nordio": ['N AO1 R D IY0 OW0'],
  "nordisk": ['N AO1 R D IH2 S K'],
  "nordling": ['N AO1 R D AH0 L IH0 NG', 'N AO1 R D L IH0 NG'],
  "nordlund": ['N AO1 R D L AH0 N D'],
  "nordman": ['N AO1 R D M AH0 N'],
  "nordmann": ['N AO1 R D M AH0 N'],
  "nordmeyer": ['N AO1 R D M AY0 ER0'],
  "nordquist": ['N AO1 R D K W IH0 S T'],
  "nordson": ['N AO1 R D S AH0 N'],
  "nordstrom": ['N AO1 R D S T R AH0 M'],
  "nordstrom's": ['N AO1 R D S T R AH0 M Z'],
  "nordstroms": ['N AO1 R D S T R AH0 M Z'],
  "nordyke": ['N AO1 R D AY2 K'],
  "noreco": ['N AO0 R EH1 K OW0'],
  "nored": ['N AO1 R D'],
  "noreen": ['N ER0 IY1 N'],
  "noreiga": ['N AO0 R EY1 G AH0'],
  "norelco": ['N ER0 EH1 L K OW0'],
  "norell": ['N AO1 R AH0 L'],
  "norem": ['N AO1 R IH0 M'],
  "noren": ['N AO1 R AH0 N'],
  "norenberg": ['N AO1 R AH0 N B ER0 G'],
  "norenco": ['N AO1 R AH0 N K OW0'],
  "norenko": ['N AH0 R EH1 N K OW0'],
  "norex": ['N AO1 R AH0 K S'],
  "norfleet": ['N AO1 R F L IY2 T'],
  "norfolk": ['N AO1 R F AH0 K'],
  "norfolk's": ['N AO1 R F AH0 K S'],
  "norgaard": ['N AO1 R G AA0 R D'],
  "norgard": ['N AO1 R G ER0 D'],
  "norge": ['N AO1 R JH'],
  "norgren": ['N AO1 R G R AH0 N'],
  "norick": ['N AO1 R IH0 K'],
  "noriega": ['N AO2 R IY0 EY1 G AH0'],
  "noriega's": ['N AO2 R IY0 EY1 G AH0 Z'],
  "noriegas": ['N AO2 R IY0 EY1 G AH0 S'],
  "noriko": ['N AO2 R IY1 K OW2'],
  "norilsk": ['N AO2 R IH1 L S K'],
  "norimatsu": ['N AO2 R IY0 M AA1 T S UW0'],
  "norinchukin": ['N AO2 R IH0 N CH UW1 K IH0 N'],
  "norinko": ['N AO2 R IH1 NG K OW0'],
  "norita": ['N AH0 R IY1 T AH0'],
  "nork": ['N AO1 R K'],
  "norkus": ['N AO1 R K IH0 S'],
  "norland": ['N AO1 R L AH0 N D'],
  "norlander": ['N AO1 R L AH0 N D ER0'],
  "norlin": ['N AO1 R L IH0 N'],
  "norling": ['N AO1 R L IH0 NG'],
  "norm": ['N AO1 R M'],
  "norma": ['N AO1 R M AH0'],
  "norma-jean": ['N AO2 R M AH0 JH IY1 N'],
  "normal": ['N AO1 R M AH0 L'],
  "normalcy": ['N AO1 R M AH0 L S IY0'],
  "normality": ['N AO2 R M AE1 L AH0 T IY0'],
  "normalization": ['N AO2 R M AH0 L IH0 Z EY1 SH AH0 N'],
  "normalize": ['N AO1 R M AH0 L AY2 Z'],
  "normalized": ['N AO1 R M AH0 L AY2 Z D'],
  "normalizes": ['N AO1 R M AH0 L AY2 Z IH0 Z'],
  "normalizing": ['N AO1 R M AH0 L AY2 Z IH0 NG'],
  "normally": ['N AO1 R M AH0 L IY0', 'N AO1 R M L IY0'],
  "norman": ['N AO1 R M AH0 N'],
  "norman's": ['N AO1 R M AH0 N Z'],
  "normand": ['N AO1 R M AH0 N D'],
  "normandie": ['N AO1 R M AH0 N D IY0'],
  "normandin": ['N AO1 R M AH0 N D IH0 N'],
  "normandy": ['N AO1 R M AH0 N D IY0'],
  "normative": ['N AO1 R M AH0 T IH0 V'],
  "normcore": ['N AO1 R M K AO2 R'],
  "norment": ['N AO1 R M AH0 N T'],
  "normick": ['N AO1 R M IH0 K'],
  "normie": ['N AO1 R M IY0'],
  "normile": ['N AO1 R M AY0 L'],
  "norming": ['N AO1 R M IH0 NG'],
  "normington": ['N AO1 R M IH0 NG T AH0 N'],
  "normoyle": ['N AO1 R M OY0 L'],
  "norms": ['N AO1 R M Z'],
  "normy": ['N AO1 R M IY0'],
  "norna": ['N AO1 R N AH0'],
  "norodom": ['N AO1 R OW0 D AH2 M', 'N AO1 R AH0 D AH2 M'],
  "norplant": ['N AO1 R P L AE2 N T'],
  "norplant's": ['N AO1 R P L AE2 N T S'],
  "norquist": ['N AO1 R K W IH0 S T'],
  "norred": ['N AO1 R D'],
  "norrell": ['N AO1 R AH0 L'],
  "norrie": ['N AO1 R IY0'],
  "norrington": ['N AO1 R IH0 NG T AH0 N'],
  "norris": ['N AO1 R IH0 S'],
  "norris's": ['N AO1 R AH0 S IH0 Z'],
  "norrod": ['N AO1 R AH0 D'],
  "norry": ['N AO1 R IY0'],
  "norse": ['N AO1 R S'],
  "norsemen": ['N AO1 R S M IH0 N'],
  "norsk": ['N AO1 R S K'],
  "norske": ['N AO1 R S K IY0'],
  "norstan": ['N AO1 R S T AH0 N'],
  "norstar": ['N AO1 R S T AA2 R'],
  "norstrom": ['N AO1 R S T R AH0 M'],
  "norsworthy": ['N AO1 R S W ER0 DH IY0'],
  "norte": ['N AO1 R T'],
  "nortek": ['N AO1 R T EH2 K'],
  "nortel": ['N AO1 R T EH0 L'],
  "north": ['N AO1 R TH'],
  "north's": ['N AO1 R TH S'],
  "north-east": ['N AO2 R TH IY1 S T'],
  "northam": ['N AO1 R TH AH0 M'],
  "northampton": ['N AO2 R TH AE1 M P T AH0 N'],
  "northbound": ['N AO1 R TH B AW0 N D'],
  "northbrook": ['N AO1 R TH B R UH2 K'],
  "northcliff": ['N AO1 R TH K L IH0 F'],
  "northcott": ['N AO1 R TH K AH0 T'],
  "northcraft": ['N AO1 R TH K R AE2 F T'],
  "northcutt": ['N AO1 R TH K AH0 T'],
  "northeast": ['N AO2 R TH IY1 S T'],
  "northeast's": ['N AO2 R TH IY1 S T S'],
  "northeasterly": ['N AO2 R TH IY1 S T ER0 L IY0'],
  "northeastern": ['N AO2 R TH IY1 S T ER0 N'],
  "northeasterner": ['N AO0 R TH IY1 S T ER0 N ER0'],
  "northeasterners": ['N AO0 R TH IY1 S T ER0 N ER0 Z'],
  "northeastward": ['N AO2 R TH IY1 S T W ER0 D'],
  "norther": ['N AO1 R DH ER0'],
  "northerly": ['N AO1 R DH ER0 L IY0'],
  "northern": ['N AO1 R DH ER0 N'],
  "northern's": ['N AO1 R DH ER0 N Z'],
  "northerner": ['N AO1 R DH ER0 N ER0'],
  "northerners": ['N AO1 R DH ER0 N ER0 Z'],
  "northernmost": ['N AO1 R DH ER0 N M OW2 S T'],
  "northey": ['N AO1 R DH IY0'],
  "northfield": ['N AO1 R TH F IY2 L D'],
  "northgate": ['N AO1 R TH G EY2 T'],
  "northington": ['N AO1 R TH IH0 NG T AH0 N'],
  "northland": ['N AO1 R TH L AE2 N D'],
  "northolt": ['N AO1 R TH AO0 L T'],
  "northridge": ['N AO1 R TH R IH2 JH'],
  "northrop": ['N AO1 R TH R AH0 P'],
  "northrop's": ['N AO1 R TH R AH0 P S'],
  "northrup": ['N AO1 R TH R AH0 P'],
  "northrup's": ['N AO1 R TH R AH0 P S'],
  "northstar": ['N AO1 R TH S T AA2 R'],
  "northstar's": ['N AO1 R TH S T AA2 R Z'],
  "northup": ['N AO1 R TH AH2 P'],
  "northvale": ['N AO1 R TH V EY2 L'],
  "northview": ['N AO1 R TH V Y UW2'],
  "northward": ['N AO1 R TH W ER0 D'],
  "northwardly": ['N AO1 R TH W ER0 D L IY0'],
  "northway": ['N AO1 R TH W EY2'],
  "northwest": ['N AO2 R TH W EH1 S T'],
  "northwest's": ['N AO2 R TH W EH1 S T S'],
  "northwesterly": ['N AO2 R TH W EH1 S T ER0 L IY0'],
  "northwestern": ['N AO2 R TH W EH1 S T ER0 N'],
  "northwestern's": ['N AO2 R TH W EH1 S T ER0 N Z'],
  "northwood": ['N AO1 R TH W UH2 D'],
  "norton": ['N AO1 R T AH0 N'],
  "norton's": ['N AO1 R T AH0 N Z'],
  "norvel": ['N AO1 R V AH0 L'],
  "norvell": ['N AO1 R V AH0 L'],
  "norvil": ['N AO1 R V AH0 L'],
  "norville": ['N AO1 R V IH0 L'],
  "norvin": ['N AO1 R V IH0 N'],
  "norwalk": ['N AO1 R W AO2 K'],
  "norward": ['N AO1 R W ER0 D'],
  "norway": ['N AO1 R W EY2'],
  "norway's": ['N AO1 R W EY2 Z'],
  "norweb": ['N AO1 R W EH2 B'],
  "norwegian": ['N AO2 R W IY1 JH AH0 N'],
  "norwegians": ['N AO0 R W IY1 JH AH0 N Z'],
  "norwell": ['N AO1 R W EH0 L'],
  "norwest": ['N AO2 R W EH1 S T'],
  "norwest's": ['N AO2 R W EH1 S T S'],
  "norwich": ['N AO1 R W IH0 CH'],
  "norwin": ['N AO1 R W IH0 N'],
  "norwitz": ['N AO1 R W IH0 T S'],
  "norwood": ['N AO1 R W UH0 D'],
  "norwyn": ['N AO1 R W IH0 N'],
  "nosair": ['N OW1 Z EY0 R', 'N OW1 S EY0 R'],
  "nosair's": ['N OW1 Z EY0 R Z', 'N OW1 S EY0 R Z'],
  "nosal": ['N OW1 Z AH0 L'],
  "nose": ['N OW1 Z'],
  "nosebleed": ['N OW1 Z B L IY2 D'],
  "nosed": ['N OW1 Z D'],
  "nosedive": ['N OW1 Z D AY2 V'],
  "nosedived": ['N OW1 Z D AY2 V D'],
  "nosek": ['N OW1 S EH0 K'],
  "noses": ['N OW1 Z IH0 Z'],
  "noseworthy": ['N OW1 Z W ER2 DH IY0'],
  "nosiness": ['N OW1 Z IY0 N AH0 S'],
  "nosing": ['N OW1 Z IH0 NG'],
  "noska": ['N OW1 S K AH0'],
  "noss": ['N AO1 S'],
  "nostalgia": ['N AO0 S T AE1 L JH AH0'],
  "nostalgic": ['N AO0 S T AE1 L JH IH0 K'],
  "nostra": ['N AO1 S T R AH0'],
  "nostradamus": ['N AO2 S T R AH0 D AE1 M AH0 S'],
  "nostrand": ['N AA1 S T R AH0 N D'],
  "nostril": ['N AA1 S T R IH0 L'],
  "nostrils": ['N AA1 S T R AH0 L Z'],
  "nostrums": ['N AA1 S T R AH0 M Z'],
  "nosy": ['N OW1 Z IY0'],
  "not": ['N AA1 T'],
  "notable": ['N OW1 T AH0 B AH0 L'],
  "notables": ['N OW1 T AH0 B AH0 L Z'],
  "notably": ['N OW1 T AH0 B L IY0'],
  "notarianni": ['N OW0 T AA0 R IY0 AA1 N IY0'],
  "notaro": ['N OW0 T AA1 R OW0'],
  "notary": ['N OW1 T ER0 IY0'],
  "notation": ['N OW0 T EY1 SH AH0 N'],
  "notations": ['N OW0 T EY1 SH AH0 N Z'],
  "notch": ['N AA1 CH'],
  "notched": ['N AA1 CH T'],
  "notches": ['N AA1 CH AH0 Z', 'N AA1 CH IH0 Z'],
  "note": ['N OW1 T'],
  "note's": ['N OW1 T S'],
  "notebaert": ['N OW1 T AH0 B EH2 R T'],
  "notebook": ['N OW1 T B UH2 K'],
  "notebooks": ['N OW1 T B UH2 K S'],
  "noteboom": ['N AA1 T IH0 B UW0 M', 'N UW1 T IH0 B UW0 M'],
  "noted": ['N OW1 T AH0 D', 'N OW1 T IH0 D'],
  "noteholder": ['N OW1 T HH OW2 L D ER0'],
  "noteholders": ['N OW1 T HH OW2 L D ER0 Z'],
  "notepad": ['N OW1 T P AE2 D'],
  "notepads": ['N OW1 T P AE2 D Z'],
  "notes": ['N OW1 T S'],
  "notes'": ['N OW1 T S'],
  "notestine": ['N OW0 T EH0 S T IY1 N IY0'],
  "notetaker": ['N OW1 T T EY2 K ER0'],
  "notetakers": ['N OW1 T T EY2 K ER0 Z'],
  "noteware": ['N OW1 T W EH2 R'],
  "noteworthy": ['N OW1 T W ER2 DH IY0'],
  "noth": ['N AA1 TH'],
  "nothdurft": ['N AA1 TH D ER0 F T'],
  "nother": ['N AH1 DH ER0'],
  "nothin": ['N AA1 TH IH0 N'],
  "nothin'": ['N AH1 TH IH0 N'],
  "nothing": ['N AH1 TH IH0 NG'],
  "nothing's": ['N AH1 TH IH0 NG Z'],
  "nothingness": ['N AH1 TH IH0 NG N AH0 S'],
  "nothings": ['N AH1 TH IH0 NG Z'],
  "nothnagel": ['N AA1 TH N AH0 G AH0 L'],
  "nothstein": ['N AA1 TH S T IY2 N', 'N AA1 TH S T AY2 N'],
  "notice": ['N OW1 T AH0 S', 'N OW1 T IH0 S'],
  "noticeable": ['N OW1 T AH0 S AH0 B AH0 L'],
  "noticeably": ['N OW1 T IH0 S AH0 B L IY0'],
  "noticed": ['N OW1 T AH0 S T'],
  "notices": ['N OW1 T AH0 S AH0 Z', 'N OW1 T IH0 S IH0 Z'],
  "noticing": ['N OW1 T IH0 S IH0 NG'],
  "notification": ['N OW2 T AH0 F AH0 K EY1 SH AH0 N'],
  "notifications": ['N OW2 T AH0 F AH0 K EY1 SH AH0 N Z'],
  "notified": ['N OW1 T AH0 F AY2 D'],
  "notifies": ['N OW1 T AH0 F AY2 Z'],
  "notify": ['N OW1 T AH0 F AY2'],
  "notifying": ['N OW1 T AH0 F AY2 IH0 NG'],
  "noting": ['N OW1 T IH0 NG'],
  "notion": ['N OW1 SH AH0 N'],
  "notional": ['N OW1 SH AH0 N AH0 L'],
  "notions": ['N OW1 SH AH0 N Z'],
  "notis": ['N OW1 T IH0 S'],
  "noto": ['N OW1 T OW0'],
  "notochord": ['N OW1 T AH0 K AO2 R D'],
  "notoriety": ['N OW2 T ER0 AY1 AH0 T IY0'],
  "notorious": ['N OW0 T AO1 R IY0 AH0 S'],
  "notoriously": ['N OW0 T AO1 R IY0 AH0 S L IY0'],
  "notre": ['N OW1 T R AH0', 'N OW1 T ER0'],
  "nots": ['N AA1 T S'],
  "nott": ['N AA1 T'],
  "nottage": ['N AA1 T IH0 JH'],
  "notte": ['N AA1 T'],
  "notter": ['N AA1 T ER0'],
  "nottingham": ['N AA1 T IH0 NG HH AE2 M'],
  "notwithstanding": ['N AA2 T W IH0 TH S T AE1 N D IH0 NG'],
  "notz": ['N AA1 T S'],
  "noun": ['N AW1 N'],
  "nouns": ['N AW1 N Z'],
  "nourish": ['N ER1 IH0 SH'],
  "nourished": ['N ER1 IH0 SH T'],
  "nourishes": ['N ER1 IH0 SH AH0 Z'],
  "nourishing": ['N ER1 IH0 SH IH0 NG'],
  "nourishment": ['N ER1 IH0 SH M AH0 N T'],
  "nourse": ['N AO1 R S'],
  "nous": ['N UW1 S'],
  "nouveau": ['N UW2 V OW1'],
  "nouvel": ['N UW1 V AH0 L'],
  "nouvelle": ['N UW2 V EH1 L'],
  "nov": ['N OW1 V'],
  "nova": ['N OW1 V AH0'],
  "nova's": ['N OW1 V AH0 Z'],
  "novacare": ['N OW1 V AH0 K EH2 R'],
  "novacek": ['N AA1 V AH0 CH EH0 K'],
  "novack": ['N AA1 V AH0 K'],
  "novacor": ['N OW1 V AH0 K AO2 R'],
  "novak": ['N OW1 V AE0 K'],
  "novak's": ['N OW1 V AE0 K S'],
  "novakovich": ['N AH0 V AA1 K AH0 V IH0 CH'],
  "novakowski": ['N AH0 V AH0 K AO1 F S K IY0'],
  "novametrix": ['N OW0 V AE1 M AH0 T R IH0 K S'],
  "novas": ['N OW1 V AH0 Z'],
  "novato": ['N OW0 V AA1 T OW0'],
  "novel": ['N AA1 V AH0 L'],
  "novel's": ['N AA1 V AH0 L Z'],
  "novelist": ['N AA1 V AH0 L AH0 S T'],
  "novelistic": ['N AA2 V AH0 L IH1 S T IH0 K'],
  "novelists": ['N AA1 V AH0 L AH0 S T S'],
  "novell": ['N OW0 V EH1 L'],
  "novell's": ['N OW0 V EH1 L Z'],
  "novella": ['N OW0 V EH1 L AH0'],
  "novelli": ['N OW0 V EH1 L IY0'],
  "novello": ['N OW0 V EH1 L OW0'],
  "novellus": ['N OW0 V EH1 L AH0 S'],
  "novelly": ['N OW1 V AH0 L IY0'],
  "novels": ['N AA1 V AH0 L Z'],
  "novelties": ['N AA1 V AH0 L T IY0 Z'],
  "novelty": ['N AA1 V AH0 L T IY0'],
  "november": ['N OW0 V EH1 M B ER0'],
  "november's": ['N OW0 V EH1 M B ER0 Z'],
  "noverco": ['N OW0 V ER1 K OW0'],
  "novey": ['N OW1 V IY0'],
  "novgorod": ['N AA1 V G ER0 AA2 D'],
  "novi": ['N OW1 V IY0', 'N OW1 V AY0'],
  "novia": ['N OW1 V IY0 AH0'],
  "novice": ['N AA1 V AH0 S'],
  "novices": ['N AA1 V AH0 S IH0 Z'],
  "novick": ['N OW1 V IH0 K', 'N AA1 V IH0 K'],
  "novicki": ['N AH0 V IH1 T S K IY0'],
  "novinger": ['N OW1 V IH0 NG ER0'],
  "novitski": ['N AH0 V IH1 T S K IY0'],
  "novitsky": ['N AH0 V IH1 T S K IY0'],
  "novo": ['N OW1 V OW0'],
  "novo's": ['N OW1 V OW0 Z'],
  "novoa": ['N OW0 V OW1 AH0'],
  "novogrod": ['N OW1 V OW0 G R AE2 D'],
  "novopharm": ['N OW1 V OW0 F AA2 R M'],
  "novosad": ['N AA1 V AH0 S AE0 D'],
  "novosel": ['N AA1 V AH0 S EH0 L'],
  "novosibirsk": ['N OW2 V AH0 S IY0 B IH1 R S K'],
  "novosti": ['N OW0 V AO1 S T IY0'],
  "novotel": ['N OW1 V OW0 T EH2 L'],
  "novotels": ['N OW1 V OW0 T EH2 L Z'],
  "novotney": ['N AA1 V AH0 T N IY0'],
  "novotny": ['N AH0 V OW1 T N IY0'],
  "novum": ['N OW1 V AH0 M'],
  "novy": ['N OW1 V IY0'],
  "now": ['N AW1'],
  "now's": ['N AW1 Z'],
  "nowa": ['N AW1 AH0'],
  "nowack": ['N AW1 AH0 K'],
  "nowacki": ['N AW0 AA1 T S K IY0'],
  "nowaczyk": ['N AW1 AH0 CH IH2 K'],
  "nowaday": ['N AW1 AH0 D EY2'],
  "nowadays": ['N AW1 AH0 D EY2 Z'],
  "nowak": ['N OW1 V AE0 K'],
  "nowakowski": ['N AW0 AH0 K AO1 F S K IY0'],
  "nowell": ['N OW1 AH0 L'],
  "nowels": ['N AW1 AH0 L Z'],
  "nowhere": ['N OW1 W EH2 R', 'N OW1 HH W EH2 R'],
  "nowicki": ['N AW0 IH1 T S K IY0'],
  "nowinski": ['N AW0 IH1 N S K IY0'],
  "nowlan": ['N AW1 L AH0 N'],
  "nowland": ['N AW1 L AH0 N D'],
  "nowlin": ['N AW1 L IH0 N'],
  "nowling": ['N AW1 L IH0 NG'],
  "nowotny": ['N AW0 OW1 T N IY0'],
  "noxell": ['N AA0 K S EH1 L'],
  "noxema": ['N AA0 K S IY1 M AH0'],
  "noxious": ['N AA1 K SH AH0 S'],
  "noxon": ['N AA1 K S AH0 N'],
  "noxso": ['N AA1 K S OW0'],
  "noyce": ['N OY1 S'],
  "noyd": ['N OY1 D'],
  "noyes": ['N OY1 Z'],
  "noyola": ['N OY2 OW1 L AH0'],
  "nozzle": ['N AA1 Z AH0 L'],
  "nozzles": ['N AA1 Z AH0 L Z'],
  "npr": ['EH1 N P IY1 AA1 R'],
  "npr's": ['EH1 N P IY1 AA1 R Z'],
  "npr.org": ['EH1 N P IY1 AA1 R D AA1 T AO1 R G'],
  "nth": ['EH1 N TH'],
  "nu": ['N UW1'],
  "nuala": ['N UW0 AA1 L AH0'],
  "nuance": ['N UW1 AA0 N S'],
  "nuanced": ['N UW1 AA0 N S T'],
  "nuances": ['N UW1 AA0 N S IH0 Z'],
  "nub": ['N AH1 B'],
  "nuber": ['N UW1 B ER0'],
  "nubian": ['N Y UW1 B IY0 AH0 N'],
  "nucci": ['N UW1 CH IY0'],
  "nuccio": ['N UW1 CH IY0 OW0'],
  "nuckles": ['N AH1 K AH0 L Z'],
  "nuckolls": ['N AH1 K AH0 L Z'],
  "nuckols": ['N AH1 K AH0 L Z'],
  "nuclear": ['N UW1 K L IY0 ER0'],
  "nucleation": ['N UW2 K L IY0 EY1 SH AH0 N'],
  "nuclei": ['N UW1 K L IY0 AY2'],
  "nucleic": ['N UW0 K L EY1 IH0 K'],
  "nucleoli": ['N UW1 K L IY0 OW2 L IY0'],
  "nucleonic": ['N UW2 K L IY0 AA1 N IH0 K'],
  "nucleonics": ['N UW2 K L IY0 AA1 N IH0 K S'],
  "nucleotide": ['N UW1 K L IY0 AH0 T AY2 D'],
  "nucleotides": ['N UW1 K L IY0 AH0 T AY2 D Z'],
  "nucleus": ['N UW1 K L IY0 AH0 S'],
  "nucor": ['N UW1 K AO2 R'],
  "nucor's": ['N UW1 K AO2 R Z'],
  "nucorp": ['N UW1 K AO2 R P'],
  "nudd": ['N AH1 D'],
  "nude": ['N UW1 D'],
  "nudelman": ['N AH1 D AH0 L M AH0 N'],
  "nudes": ['N UW1 D Z'],
  "nudey": ['N UW1 D IY0'],
  "nudge": ['N AH1 JH'],
  "nudged": ['N AH1 JH D'],
  "nudges": ['N AH1 JH IH0 Z'],
  "nudging": ['N AH1 JH IH0 NG'],
  "nudist": ['N UW1 D IH0 S T'],
  "nudity": ['N UW1 D IH0 T IY0'],
  "nudo": ['N UW1 D OW0'],
  "nuevo": ['N W EY1 V OW0'],
  "nuexco": ['N W EY1 K S K OW0'],
  "nueyung": ['N UW2 Y AH1 NG'],
  "nufer": ['N UW1 F ER0'],
  "nuffer": ['N AH1 F ER0'],
  "nugan": ['N UW1 G AH0 N'],
  "nugatory": ['N UW1 G AA0 T AO2 R IY0'],
  "nugent": ['N UW1 JH AH0 N T'],
  "nugett": ['N UW1 JH AH0 T'],
  "nugget": ['N AH1 G IH0 T'],
  "nugget's": ['N AH1 G IH0 T S'],
  "nuggets": ['N AH1 G AH0 T S'],
  "nuhfer": ['N UW1 F ER0'],
  "nuhn": ['N AH1 N'],
  "nuisance": ['N UW1 S AH0 N S'],
  "nuisances": ['N UW1 S AH0 N S IH0 Z'],
  "nujoma": ['N UW0 JH OW1 M AH0'],
  "nuke": ['N UW1 K'],
  "nukem": ['N UW1 K AH0 M'],
  "nukes": ['N UW1 K S'],
  "nuland": ['N UW1 L AE2 N D', 'N UW1 L AH0 N D'],
  "null": ['N AH1 L'],
  "nullification": ['N AH2 L AH0 F AH0 K EY1 SH AH0 N'],
  "nullified": ['N AH1 L AH0 F AY2 D'],
  "nullifies": ['N AH1 L AH0 F AY2 Z'],
  "nullify": ['N AH1 L AH0 F AY2'],
  "nullifying": ['N AH1 L AH0 F AY2 IH0 NG'],
  "nulph": ['N AH1 L F'],
  "nulton": ['N AH1 L T AH0 N'],
  "nulty": ['N AH1 L T IY0'],
  "numac": ['N UW1 M AE0 K'],
  "numb": ['N AH1 M'],
  "numbed": ['N AH1 M D'],
  "number": ['N AH1 M B ER0'],
  "number's": ['N AH1 M B ER0 Z'],
  "number-one": ['N AH2 M B ER0 W AH1 N'],
  "numbered": ['N AH1 M B ER0 D'],
  "numbering": ['N AH1 M B ER0 IH0 NG'],
  "numbers": ['N AH1 M B ER0 Z'],
  "numbing": ['N AH1 M IH0 NG'],
  "numbingly": ['N AH1 M IH0 NG L IY0'],
  "numbness": ['N AH1 M N AH0 S'],
  "numed": ['N UW0 M EH1 D'],
  "numeiri": ['N UW0 M EY1 R IY0'],
  "numeral": ['N UW1 M ER0 AH0 L'],
  "numerals": ['N UW1 M ER0 AH0 L Z'],
  "numerate": ['N UW1 M AH0 R AH0 T'],
  "numerator": ['N UW1 M AH0 R EY2 T ER0'],
  "numerators": ['N UW1 M AH0 R EY2 T ER0 Z'],
  "numeric": ['N UW0 M EH1 R IH0 K'],
  "numerica": ['N UW0 M EH1 R IH0 K AH0'],
  "numerical": ['N UW0 M EH1 R AH0 K AH0 L', 'N UW0 M EH1 R IH0 K AH0 L'],
  "numerically": ['N UW0 M EH1 R IH0 K L IY0'],
  "numerology": ['N UW0 M ER0 AA1 L AH0 JH IY0'],
  "numerous": ['N UW1 M ER0 AH0 S'],
  "numia": ['N UW1 M Y AH0'],
  "numidia": ['N UW0 M IH1 D IY0 AH0'],
  "numismatic": ['N UW2 M IH0 S M AE1 T IH0 K'],
  "numismatics": ['N UW2 M IH0 S M AE1 T IH0 K S'],
  "numismatist": ['N UW0 M IH1 S M AH0 T IH2 S T'],
  "numismatists": ['N UW0 M IH1 S M AH0 T IH2 S T S'],
  "nummi": ['N UW1 M IY0'],
  "nun": ['N AH1 N'],
  "nun's": ['N AH1 N Z'],
  "nunamaker": ['N UW0 N AA1 M EY0 K ER0'],
  "nunan": ['N UW1 N AA0 N'],
  "nunavut": ['N UH1 N AH0 V AH0 T'],
  "nuncio": ['N AH1 N S IY0 OW0'],
  "nunemaker": ['N UW1 N M EY2 K ER0'],
  "nunes": ['N UW1 N Z'],
  "nunez": ['N UW1 N EH0 Z'],
  "nungesser": ['N AH1 NG G IH0 S ER0'],
  "nunley": ['N AH1 N L IY0'],
  "nunn": ['N AH1 N'],
  "nunn's": ['N AH1 N Z'],
  "nunnally": ['N AH1 N AH0 L IY0'],
  "nunnelley": ['N AH1 N IH0 L IY0'],
  "nunnelly": ['N AH1 N AH0 L IY0'],
  "nunnery": ['N AH1 N ER0 IY0'],
  "nuno": ['N UW1 N OW0'],
  "nuno's": ['N UW1 N OW0 Z'],
  "nuns": ['N AH1 N Z'],
  "nuns'": ['N AH1 N Z'],
  "nunsense": ['N AH1 N S EH2 N S'],
  "nunziata": ['N UW0 N Z IY0 AA1 T AH0'],
  "nunziato": ['N UW0 N Z IY0 AA1 T OW0'],
  "nunzio": ['N AH1 N Z IY0 OW0'],
  "nuova": ['N W OW1 V AH0'],
  "nuovo": ['N W OW1 V OW0'],
  "nupeng": ['N Y UW1 P EH0 NG'],
  "nuptial": ['N AH1 P CH AH0 L'],
  "nuptials": ['N AH1 P CH AH0 L Z'],
  "nur": ['N UH1 R'],
  "nuremberg": ['N UH1 R AH0 M B ER0 G'],
  "nureyev": ['N ER0 AY1 AH0 V'],
  "nurmi": ['N UH1 R M IY0'],
  "nurnberger": ['N ER1 N B ER0 G ER0'],
  "nurock": ['N UW1 R AA2 K'],
  "nurr": ['N ER1'],
  "nurre": ['N ER1'],
  "nurse": ['N ER1 S'],
  "nurse's": ['N ER1 S IH0 Z'],
  "nursed": ['N ER1 S T'],
  "nurseries": ['N ER1 S ER0 IY0 Z'],
  "nursery": ['N ER1 S ER0 IY0'],
  "nurseryman": ['N ER1 S ER0 IY0 M AE2 N'],
  "nurserymen": ['N ER1 S ER0 IY0 M AH0 N'],
  "nurses": ['N ER1 S AH0 Z', 'N ER1 S IH0 Z'],
  "nurses'": ['N ER1 S AH0 Z', 'N ER1 S IH0 Z'],
  "nursing": ['N ER1 S IH0 NG'],
  "nurture": ['N ER1 CH ER0'],
  "nurtured": ['N ER1 CH ER0 D'],
  "nurturer": ['N ER1 CH ER0 ER0'],
  "nurturers": ['N ER1 CH ER0 ER0 Z'],
  "nurtures": ['N ER1 CH ER0 Z'],
  "nurturing": ['N ER1 CH ER0 IH0 NG'],
  "nusbaum": ['N AH0 S B AW0 M', 'N AH0 S B AA0 M'],
  "nusen": ['N UW1 S AH0 N'],
  "nuss": ['N AH1 S'],
  "nussbaum": ['N AH1 S B AW2 M', 'N AH1 S B AA2 M'],
  "nussbaum's": ['N AH1 S B AW2 M Z', 'N AH1 S B AA2 M Z'],
  "nussbaumer": ['N AH1 S B AW2 M ER0'],
  "nusser": ['N AH1 S ER0'],
  "nussle": ['N AH1 S AH0 L'],
  "nusz": ['N AH1 SH'],
  "nut": ['N AH1 T'],
  "nut's": ['N AH1 T S'],
  "nutcracker": ['N AH1 T K R AE2 K ER0'],
  "nutcrackers": ['N AH1 T K R AE2 K ER0 Z'],
  "nute": ['N Y UW1 T'],
  "nutlets": ['N AH1 T L AH0 T S'],
  "nutley": ['N AH1 T L IY0'],
  "nutmeg": ['N AH1 T M EH2 G'],
  "nutone": ['N UW1 T OW2 N'],
  "nutrasweet": ['N UW1 T R AH0 S W IY2 T'],
  "nutri": ['N UW1 T R IY0', 'N UW1 T R IH0'],
  "nutria": ['N UW1 T R IY0 AH0'],
  "nutriclean": ['N UW1 T R IH0 K L IY2 N'],
  "nutrient": ['N UW1 T R IY0 AH0 N T'],
  "nutrients": ['N UW1 T R IY0 AH0 N T S'],
  "nutrition": ['N UW0 T R IH1 SH AH0 N'],
  "nutritional": ['N UW0 T R IH1 SH AH0 N AH0 L'],
  "nutritionally": ['N UW0 T R IH1 SH AH0 N AH0 L IY0', 'N UW0 T R IH1 SH N AH0 L IY0'],
  "nutritionist": ['N UW0 T R IH1 SH AH0 N IH0 S T'],
  "nutritionists": ['N UW0 T R IH1 SH AH0 N IH0 S T S'],
  "nutritious": ['N UW0 T R IH1 SH AH0 S'],
  "nutritive": ['N UW1 T R AH0 T IH0 V'],
  "nuts": ['N AH1 T S'],
  "nutshell": ['N AH1 T SH EH2 L'],
  "nutshells": ['N AH1 T SH EH2 L Z'],
  "nutt": ['N AH1 T'],
  "nuttal": ['N AH1 T AH0 L'],
  "nuttall": ['N AH1 T AH0 L'],
  "nutter": ['N AH1 T ER0'],
  "nutters": ['N AH1 T ER0 Z'],
  "nutting": ['N AH1 T IH0 NG'],
  "nuttle": ['N AH1 T AH0 L'],
  "nutty": ['N AH1 T IY0'],
  "nuveen": ['N UW0 V IY1 N'],
  "nuxoll": ['N AH1 K S AH0 L'],
  "nuys": ['N AY1 Z'],
  "nuzum": ['N UW1 Z AH0 M'],
  "nuzzi": ['N UW1 T S IY0'],
  "nuzzle": ['N AH1 Z AH0 L'],
  "nuzzles": ['N AH1 Z AH0 L Z'],
  "nuzzling": ['N AH1 Z AH0 L IH2 NG'],
  "nuzzo": ['N UW1 Z OW0'],
  "nvhome": ['EH1 N V IY1 HH OW1 M'],
  "nvhomes": ['EH1 N V IY1 HH OW1 M Z'],
  "nvidia": ['EH1 N V IH1 D IY0 AH0'],
  "nvryan": ['EH0 N V R AY1 AH0 N'],
  "nyack": ['N AY1 AE2 K'],
  "nyberg": ['N AY1 B ER0 G'],
  "nyborg": ['N AY1 B AO0 R G'],
  "nyce": ['N AY1 S'],
  "nycor": ['N AY1 K AO2 R'],
  "nycum": ['N IH1 K AH0 M', 'N AY1 K AH0 M'],
  "nycz": ['N IH1 CH'],
  "nydam": ['N IH1 D AH0 M'],
  "nydegger": ['N AY1 D IH0 G ER0'],
  "nydia": ['N IH1 D IY0 AH0'],
  "nye": ['N AY1'],
  "nyenhuis": ['N AY1 AH0 N HH UW2 S'],
  "nyerere": ['N AY1 ER0 IH2 R'],
  "nyeri": ['N AY1 ER0 IY0'],
  "nyers": ['N AY1 R Z'],
  "nyet": ['N Y EH1 T'],
  "nygaard": ['N AY1 G AA0 R D'],
  "nygard": ['N AY1 G ER0 D'],
  "nygren": ['N IH1 G R EH0 N', 'N AY1 G R EH0 N'],
  "nyhan": ['N AY1 HH AE0 N'],
  "nyholm": ['N AY1 HH OW0 L M'],
  "nyhus": ['N IH1 HH IH0 S'],
  "nyina": ['N AY1 IY0 N AH0'],
  "nyland": ['N AY1 L AH0 N D'],
  "nylander": ['N IH1 L AH0 N D ER0', 'N AY1 L AH0 N D ER0'],
  "nylen": ['N IH1 L AH0 N'],
  "nylex": ['N AY1 L AH0 K S'],
  "nylon": ['N AY1 L AA2 N'],
  "nylons": ['N AY1 L AA2 N Z'],
  "nylund": ['N AY1 L AH0 N D'],
  "nyman": ['N AY1 M AH0 N'],
  "nymex": ['N AY1 M EH2 K S'],
  "nymph": ['N IH1 M F'],
  "nympho": ['N IH2 M F OW0'],
  "nymphomaniac": ['N IH2 M F OW0 M EY1 N IY0 AE2 K'],
  "nymphomaniac's": ['N IH2 M F OW0 M EY1 N IY0 AE2 K S'],
  "nymphomaniacs": ['N IH2 M F OW0 M EY1 N IY0 AE2 K S'],
  "nymphs": ['N IH1 M F S'],
  "nynex": ['N AY1 N EH2 K S'],
  "nynex's": ['N AY1 N EH2 K S IH0 Z'],
  "nypd": ['EH2 N W AY1 P IY2 D IY2'],
  "nyquist": ['N AY1 K W IH0 S T'],
  "nyssa": ['N IH1 S AH0'],
  "nystrom": ['N AY1 S T R AH0 M'],
  "nyx": ['N IH1 K S'],
  "o": ['OW1'],
  "o'": ['OW1'],
  "o'banion": ['OW0 B AE1 N Y AH0 N'],
  "o'bannon": ['OW0 B AE1 N AH0 N'],
  "o'bara": ['OW0 B AA1 R AH0'],
  "o'barr": ['OW0 B AA1 R'],
  "o'beirne": ['OW0 B ER1 N'],
  "o'berg": ['OW0 B ER1 G'],
  "o'berle": ['OW0 B ER1 AH0 L'],
  "o'berry": ['OW0 B EH1 R IY0'],
  "o'boyle": ['OW0 B OY1 L'],
  "o'bradovich": ['OW0 B R AE1 D AH0 V IH0 CH'],
  "o'brecht": ['OW1 B R EH0 K T'],
  "o'bregon": ['OW0 B R EH1 G AH0 N'],
  "o'bremski": ['OW0 B R EH1 M S K IY0'],
  "o'brenovich": ['OW0 B R EH1 N AH0 V IH0 CH'],
  "o'brian": ['OW0 B R AY1 AH0 N'],
  "o'briant": ['OW0 B R AY1 AH0 N T'],
  "o'brien": ['OW0 B R AY1 IH0 N'],
  "o'brien's": ['OW0 B R AY1 IH0 N Z'],
  "o'bringer": ['OW1 B R IH0 NG ER0'],
  "o'brinsky": ['OW0 B R IH1 N S K IY0'],
  "o'bryan": ['OW0 B R AY1 AH0 N'],
  "o'bryant": ['OW0 B R AY1 AH0 N T'],
  "o'byrne": ['OW0 B ER1 N'],
  "o'callaghan": ['OW0 K AE1 L AH0 G AH0 N'],
  "o'callahan": ['OW0 K AE1 L AH0 HH AE2 N'],
  "o'campo": ['OW0 K AE1 M P OW0'],
  "o'cana": ['OW0 K AE1 N AH0'],
  "o'canas": ['OW0 K AE1 N AH0 Z'],
  "o'carroll": ['OW0 K AE1 R AH0 L', 'OW0 K EH1 R AH0 L'],
  "o'cilla": ['OW0 S IH1 L AH0'],
  "o'clair": ['OW0 K L ER1'],
  "o'clock": ['AH0 K L AA1 K'],
  "o'connell": ['OW0 K AA1 N AH0 L'],
  "o'conner": ['OW0 K AA1 N ER0'],
  "o'conner's": ['OW0 K AA1 N ER0 Z'],
  "o'connor": ['OW0 K AA1 N ER0'],
  "o'connor's": ['OW0 K AA1 N ER0 Z'],
  "o'day": ['OW0 D EY1'],
  "o'dea": ['OW0 D EY1'],
  "o'deli": ['OW0 D EH1 L IY0'],
  "o'dell": ['OW0 D EH1 L'],
  "o'dell's": ['OW0 D EH1 L Z'],
  "o'diorne": ['OW0 D Y AO1 R N IY0', 'OW0 D Y AO1 R N'],
  "o'doherty": ['OW0 D AA1 ER0 T IY0', 'OW0 D OW1 ER0 T IY0'],
  "o'donald": ['OW0 D AA1 N AH0 L D'],
  "o'donnel": ['OW0 D AA1 N AH0 L'],
  "o'donnell": ['OW0 D AA1 N AH0 L'],
  "o'donnell's": ['OW0 D AA1 N AH0 L Z'],
  "o'donoghue": ['OW0 D AA1 N AH0 HH UW0', 'OW0 D AA1 N AH0 HH Y UW0'],
  "o'donohue": ['OW0 D AA1 N AH0 HH Y UW0'],
  "o'donovan": ['OW0 D AA1 N AH0 V AH0 N'],
  "o'dougherty": ['OW0 D AA1 HH ER0 T IY0', 'OW0 D AA1 ER0 T IY0'],
  "o'dowd": ['OW0 D AW1 D'],
  "o'driscoll": ['OW0 D R IH1 S K AA0 L'],
  "o'dwyer": ['OW0 D W AY1 R'],
  "o'fallon": ['OW0 F AE1 L AH0 N'],
  "o'farrell": ['OW0 F AE1 R AH0 L'],
  "o'five": ['OW0 F AY1 V'],
  "o'flaherty": ['OW0 F L EY1 HH ER0 T IY0', 'OW0 F L EY1 ER0 T IY0'],
  "o'flynn": ['OW0 F L IH1 N'],
  "o'glove": ['OW0 G L AH1 V'],
  "o'gorman": ['OW0 G AO1 R M AH0 N'],
  "o'grady": ['OW0 G R EY1 D IY0'],
  "o'grady's": ['OW0 G R EY1 D IY0 Z'],
  "o'guin": ['OW0 G UW1 IY0 N'],
  "o'guinn": ['OW0 G W IH1 N'],
  "o'hagan": ['OW0 HH AA1 G AA0 N', 'OW0 HH EY1 G AH0 N'],
  "o'hair": ['OW0 HH EH1 R'],
  "o'hallaron": ['OW0 HH AE1 L ER0 AH0 N'],
  "o'halloran": ['OW0 HH AE1 L ER0 AH0 N'],
  "o'halloron": ['OW0 HH AE1 L ER0 AH0 N'],
  "o'hanesian": ['OW0 HH AH0 N EH1 ZH IH0 N'],
  "o'hanian": ['OW0 HH EY1 N IY0 AH0 N'],
  "o'hanlon": ['OW0 HH AE1 N L AH0 N'],
  "o'hara": ['OW0 HH EH1 R AH0'],
  "o'hara's": ['OW0 HH EH1 R AH0 Z'],
  "o'hare": ['OW0 HH EH1 R'],
  "o'harra": ['OW0 HH ER1 AH0'],
  "o'hashi": ['OW0 HH AA1 SH IY0'],
  "o'haver": ['OW0 HH EY1 V ER0'],
  "o'hearn": ['OW0 HH ER1 N'],
  "o'hern": ['OW0 HH ER1 N'],
  "o'herron": ['OW0 HH EH1 R AH0 N'],
  "o'kane": ['OW0 K EY1 N'],
  "o'keefe": ['OW0 K IY1 F'],
  "o'keeffe": ['OW0 K IY1 F'],
  "o'keeffe's": ['OW0 K IY1 F S'],
  "o'kelley": ['OW0 K EH1 L IY0'],
  "o'kelly": ['OW0 K EH1 L IY0'],
  "o'keson": ['OW0 K IY1 S AH0'],
  "o'kicki": ['OW0 K IH1 K IY0'],
  "o'lague": ['OW0 L EY1 G'],
  "o'laughlin": ['OW0 L AO1 K L IH0 N'],
  "o'lear": ['OW0 L IH1 R'],
  "o'leary": ['OW0 L IH1 R IY0'],
  "o'leary's": ['OW0 L IH1 R IY0 Z'],
  "o'loughlin": ['OW0 L AW1 K L IH0 N'],
  "o'mahoney": ['OW0 M AH0 HH OW1 N IY0'],
  "o'mahony": ['OW0 M AH0 HH OW1 N IY0'],
  "o'malley": ['OW0 M AE1 L IY0'],
  "o'mara": ['OW0 M AA1 R AH0'],
  "o'marska": ['OW0 M AA1 R S K AH0'],
  "o'meara": ['OW0 M IY1 R AH0', 'OW0 M AA1 R AH0'],
  "o'melveny": ['OW0 M EH1 L V AH0 N IY0'],
  "o'mori": ['OW0 M AO1 R IY0'],
  "o'neal": ['OW0 N IY1 L'],
  "o'neil": ['OW0 N IY1 L'],
  "o'neill": ['OW0 N IY1 L'],
  "o'neill's": ['OW0 N IY1 L Z'],
  "o'nuts": ['OW0 N AH1 T S', 'AH0 N AH1 T S'],
  "o'quinn": ['OW0 K W IH1 N'],
  "o'reilly": ['OW0 R AY1 L IY0'],
  "o'riley": ['OW0 R AY1 L IY0'],
  "o'roark": ['OW0 R AO1 R K'],
  "o'rourke": ['OW0 R AO1 R K'],
  "o's": ['OW1 Z'],
  "o'shaughnessy": ['OW2 SH AA1 N IH0 S IY0'],
  "o'shea": ['OW2 SH EY1'],
  "o'shell": ['OW0 SH EH1 L'],
  "o'smarty": ['OW2 S M AA1 R T IY0'],
  "o'steen": ['OW0 S T IY1 N'],
  "o'sullivan": ['OW0 S AH1 L AH0 V AH0 N'],
  "o'toole": ['OW0 T UW1 L'],
  "o.": ['OW1'],
  "o.'s": ['OW1 Z'],
  "o.s": ['OW1 Z'],
  "o.s'": ['OW1 Z'],
  "oad": ['OW1 EY1 D IY1'],
  "oahu": ['OW2 AA1 HH UW0'],
  "oak": ['OW1 K'],
  "oak's": ['OW1 K S'],
  "oakar": ['OW1 K AA2 R'],
  "oakbrook": ['OW1 K B R UH2 K'],
  "oakdale": ['OW1 K D EY2 L'],
  "oakes": ['OW1 K S'],
  "oakey": ['OW1 K IY0'],
  "oakite": ['OW1 K AY2 T'],
  "oakland": ['OW1 K L AH0 N D'],
  "oakland's": ['OW1 K L AH0 N D Z'],
  "oakleaf": ['OW1 K L IY2 F'],
  "oakley": ['OW1 K L IY0'],
  "oakley's": ['OW1 K L IY0 Z'],
  "oakman": ['OW1 K M AH0 N'],
  "oakmark": ['OW1 K M AA2 R K'],
  "oakmont": ['OW1 K M AA2 N T'],
  "oakridge": ['OW1 K R IH2 JH'],
  "oaks": ['OW1 K S'],
  "oaks'": ['OW1 K S'],
  "oakville": ['OW1 K V IH2 L'],
  "oakwood": ['OW1 K W UH2 D'],
  "oaky": ['OW1 K IY0'],
  "oana": ['OW1 N AH0'],
  "oanh": ['OW1 N'],
  "oar": ['AO1 R'],
  "oared": ['AO1 R D'],
  "oarlock": ['AO1 R L AA2 K'],
  "oars": ['AO1 R Z'],
  "oarsman": ['AO1 R Z M AH0 N'],
  "oas": ['OW1 EY1 EH1 S'],
  "oases": ['OW0 EY1 S IY0 Z'],
  "oasis": ['OW0 EY1 S IH0 S'],
  "oat": ['OW1 T'],
  "oates": ['OW1 T S'],
  "oath": ['OW1 TH'],
  "oathout": ['OW1 TH AW2 T'],
  "oaths": ['OW1 DH Z', 'OW1 TH S'],
  "oatis": ['OW1 T IH0 S'],
  "oatley": ['OW1 T L IY0'],
  "oatman": ['OW1 T M AH0 N'],
  "oatmeal": ['OW1 T M IY2 L'],
  "oats": ['OW1 T S'],
  "oaxaca": ['OW1 K S AH0 K AH0'],
  "oba": ['OW1 B AH0'],
  "obadiah": ['OW2 B AH0 D AY1 AH0'],
  "obama": ['OW2 B AA1 M AH0'],
  "obama's": ['OW2 B AA1 M AH0 Z'],
  "obamacare": ['OW2 B AA1 M AH0 K EH2 R'],
  "obando": ['OW0 B AE1 N D OW0'],
  "obanion": ['OW0 B AE1 N Y AH0 N'],
  "obannon": ['OW0 B AE1 N AH0 N'],
  "obar": ['AH0 B AA1 R'],
  "obara": ['OW0 B AA1 R AH0'],
  "obarr": ['OW0 B AA1 R'],
  "obdurate": ['AA1 B D ER0 AH0 T'],
  "obedience": ['OW0 B IY1 D IY0 AH0 N S'],
  "obedient": ['OW0 B IY1 D IY0 AH0 N T'],
  "obediently": ['OW0 B IY1 D IY0 AH0 N T L IY0', 'OW0 B IY1 D Y AH0 N T L IY0'],
  "obeid": ['OW0 B AY1 D'],
  "obeirne": ['OW0 B ER1 R N'],
  "obeisance": ['OW0 B EY1 S AH0 N S'],
  "obelia": ['OW0 B EH1 L IY0 AH0'],
  "obenchain": ['AA1 B IH0 N K AY0 N'],
  "obenshain": ['AA1 B IH0 N SH AY0 N'],
  "ober": ['OW1 B ER0'],
  "oberbeck": ['OW1 B ER0 B EH0 K'],
  "oberdorf": ['OW1 B ER0 D AO0 R F'],
  "oberg": ['OW1 B ER0 G'],
  "oberhaus": ['OW1 B ER0 HH AW0 S'],
  "oberhausen": ['OW1 B ER0 HH AW2 Z AH0 N'],
  "oberhelman": ['OW1 B ER0 HH AH0 L M AH0 N'],
  "oberholtzer": ['OW1 B ER0 HH OW0 L T Z ER0'],
  "oberlander": ['OW1 B ER0 L AH0 N D ER0'],
  "oberle": ['OW1 B ER0 AH0 L'],
  "oberlin": ['OW1 B ER0 L IH0 N'],
  "oberly": ['OW1 B ER0 L IY0'],
  "obermaier": ['OW1 B ER0 M AY2 R'],
  "oberman": ['OW1 B ER0 M AH0 N'],
  "obermeier": ['OW1 B ER0 M AY0 ER0'],
  "obermeyer": ['OW1 B ER0 M AY0 ER0'],
  "obermiller": ['OW1 B ER0 M IH0 L ER0'],
  "oberon": ['OW1 B ER0 AA2 N'],
  "oberry": ['OW0 B EH1 R IY0'],
  "oberst": ['OW1 B ER0 S T'],
  "oberstar": ['OW1 B ER0 S T AA2 R'],
  "obert": ['AA1 B ER0 T'],
  "oberweis": ['OW1 B ER0 W AY2 S'],
  "obese": ['OW0 B IY1 S', 'AH0 B IY1 S'],
  "obesity": ['OW0 B IY1 S AH0 T IY0', 'AH0 B IY1 S AH0 T IY0'],
  "obey": ['OW0 B EY1'],
  "obeyed": ['OW0 B EY1 D'],
  "obeying": ['OW0 B EY1 IH0 NG'],
  "obeys": ['OW0 B EY1 Z'],
  "obfuscate": ['AA1 B F AH0 S K EY2 T'],
  "obfuscation": ['AA2 B F AH0 S K EY1 SH AH0 N'],
  "obie": ['OW1 B IY0'],
  "obispo": ['OW0 B IH1 S P OW0'],
  "obit": ['OW1 B IH0 T', 'OW0 B IH1 T'],
  "obits": ['OW1 B IH0 T S', 'OW0 B IH1 T S'],
  "obituaries": ['OW0 B IH1 CH UW0 EH2 R IY0 Z'],
  "obituary": ['OW0 B IH1 CH UW0 EH2 R IY0'],
  "object": ['AA1 B JH EH0 K T', 'AH0 B JH EH1 K T'],
  "objected": ['AH0 B JH EH1 K T AH0 D'],
  "objecting": ['AH0 B JH EH1 K T IH0 NG'],
  "objection": ['AH0 B JH EH1 K SH AH0 N'],
  "objection's": ['AH0 B JH EH1 K SH AH0 N Z'],
  "objectionable": ['AH0 B JH EH1 K SH AH0 N AH0 B AH0 L'],
  "objections": ['AH0 B JH EH1 K SH AH0 N Z'],
  "objective": ['AH0 B JH EH1 K T IH0 V'],
  "objectively": ['AA0 B JH EH1 K T IH0 V L IY0'],
  "objectives": ['AH0 B JH EH1 K T IH0 V Z'],
  "objectivity": ['AA2 B JH EH0 K T IH1 V IH0 T IY0'],
  "objector": ['AH0 B JH EH1 K T ER0'],
  "objectors": ['AH0 B JH EH1 K T ER0 Z'],
  "objects": ['AA1 B JH EH0 K T S', 'AH0 B JH EH1 K T S'],
  "oblak": ['AA1 B L AH0 K', 'OW0 B L AE1 K'],
  "oblander": ['AA1 B L AH0 N D ER0'],
  "oblast": ['AA1 B L AE0 S T'],
  "oblate": ['AA0 B L EY1 T', 'AA1 B L EY0 T'],
  "obligate": ['AA1 B L AH0 G EY2 T'],
  "obligated": ['AA1 B L AH0 G EY2 T IH0 D'],
  "obligates": ['AA1 B L AH0 G EY2 T S'],
  "obligating": ['AA1 B L AH0 G EY2 T IH0 NG'],
  "obligation": ['AA2 B L AH0 G EY1 SH AH0 N'],
  "obligations": ['AA2 B L AH0 G EY1 SH AH0 N Z'],
  "obligatory": ['AH0 B L IH1 G AH0 T AO2 R IY0'],
  "oblige": ['AH0 B L AY1 JH'],
  "obliged": ['AH0 B L AY1 JH D'],
  "obliges": ['AH0 B L AY1 JH IH0 Z'],
  "obliging": ['AH0 B L AY1 JH IH0 NG'],
  "obligingly": ['AH0 B L AY1 JH IH0 NG L IY0'],
  "oblinger": ['OW1 B AH0 L IH0 NG ER0', 'OW1 B L IH0 NG ER0'],
  "oblique": ['AH0 B L IY1 K'],
  "obliquely": ['AH0 B L IY1 K L IY0'],
  "obliterate": ['AH0 B L IH1 T ER0 EY2 T'],
  "obliterated": ['AH0 B L IH1 T ER0 EY2 T IH0 D'],
  "obliterates": ['AH0 B L IH1 T ER0 EY2 T S'],
  "obliterating": ['AH0 B L IH1 T ER0 EY2 T IH0 NG'],
  "obliteration": ['AH0 B L IH2 T ER0 EY1 SH AH0 N'],
  "oblivion": ['AH0 B L IH1 V IY0 AH0 N'],
  "oblivious": ['AH0 B L IH1 V IY0 AH0 S'],
  "oblong": ['AA1 B L AO0 NG'],
  "obloquy": ['AO1 B L AH0 K W IY2'],
  "obnoxious": ['AA0 B N AA1 K SH AH0 S'],
  "oboe": ['OW1 B OW0'],
  "oboist": ['OW1 B OW0 AH0 S T'],
  "obon": ['OW1 B AH0 N'],
  "oboyle": ['OW0 B OY1 L'],
  "obradovich": ['OW0 B R AE1 D AH0 V IH0 CH'],
  "obrecht": ['OW1 B R IH0 K T'],
  "obregon": ['AA1 B R IH0 G AH0 N'],
  "obremski": ['OW0 B R EH1 M S K IY0'],
  "obremsky": ['OW0 B R EH1 M S K IY0'],
  "obrenovich": ['OW0 B R EH1 N AH0 V IH0 CH'],
  "obrian": ['OW0 B R AY1 AH0 N'],
  "obriant": ['OW0 B R AY1 AH0 N T'],
  "obrien": ['OW0 B R AY1 IH0 N'],
  "obringer": ['OW1 B R IH0 NG ER0'],
  "obrinsky": ['OW0 B R IH1 N S K IY0'],
  "obryan": ['OW0 B R AY1 AH0 N'],
  "obryant": ['OW0 B R AY1 AH0 N T'],
  "obscene": ['AA0 B S IY1 N', 'AH0 B S IY1 N'],
  "obscenely": ['AA0 B S IY1 N AH0 L IY0', 'AA0 B S IY1 N L IY0'],
  "obscenities": ['AA0 B S EH1 N IH0 T IY0 Z'],
  "obscenity": ['AH0 B S EH1 N IH0 T IY0'],
  "obscure": ['AH0 B S K Y UH1 R'],
  "obscured": ['AH0 B S K Y UH1 R D'],
  "obscures": ['AH0 B S K Y UH1 R Z'],
  "obscuring": ['AH0 B S K Y UH1 R IH0 NG'],
  "obscurity": ['AH0 B S K Y UH1 R AH0 T IY0'],
  "obsequious": ['AH0 B S IY1 K W IY0 AH0 S'],
  "observable": ['AH0 B Z ER1 V AH0 B AH0 L'],
  "observables": ['AH0 B Z ER1 V AH0 B AH0 L Z'],
  "observance": ['AH0 B Z ER1 V AH0 N S'],
  "observances": ['AH0 B Z ER1 V AH0 N S IH0 Z'],
  "observant": ['AH0 B Z ER1 V AH0 N T'],
  "observateur": ['AA0 B Z ER2 V AH0 T UH1 R'],
  "observation": ['AA2 B Z ER0 V EY1 SH AH0 N'],
  "observational": ['AA2 B Z ER0 V EY1 SH AH0 N AH0 L'],
  "observations": ['AA2 B Z ER0 V EY1 SH AH0 N Z'],
  "observatories": ['AH0 B Z ER1 V AH0 T AO2 R IY0 Z'],
  "observatory": ['AH0 B Z ER1 V AH0 T AO2 R IY0'],
  "observatory's": ['AH0 B Z ER1 V AH0 T AO2 R IY0 Z'],
  "observe": ['AH0 B Z ER1 V'],
  "observed": ['AH0 B Z ER1 V D'],
  "observer": ['AH0 B Z ER1 V ER0'],
  "observers": ['AH0 B Z ER1 V ER0 Z'],
  "observes": ['AH0 B Z ER1 V Z'],
  "observing": ['AH0 B Z ER1 V IH0 NG'],
  "obsess": ['AH0 B S EH1 S'],
  "obsessed": ['AH0 B S EH1 S T'],
  "obsesses": ['AH0 B S EH1 S IH0 Z'],
  "obsessing": ['AH0 B S EH1 S IH0 NG'],
  "obsession": ['AH0 B S EH1 SH AH0 N'],
  "obsessional": ['AH0 B S EH1 SH AH0 N AH0 L'],
  "obsessions": ['AH0 B S EH1 SH AH0 N Z'],
  "obsessive": ['AH0 B S EH1 S IH0 V'],
  "obsessively": ['AA0 B S EH1 S IH0 V L IY0'],
  "obshchestvennom": ['AA2 B SH AH0 S T V EH1 N AH0 M'],
  "obsidian": ['AH0 B S IH1 D IY0 AH0 N'],
  "obsolescence": ['AA2 B S AH0 L EH1 S AH0 N S'],
  "obsolescent": ['AA2 B S AH0 L EH1 S AH0 N T'],
  "obsolete": ['AA1 B S AH0 L IY2 T', 'AA2 B S AH0 L IY1 T'],
  "obsoletes": ['AA2 B S AH0 L IY1 T S'],
  "obst": ['AA1 B S T'],
  "obstacle": ['AA1 B S T AH0 K AH0 L'],
  "obstacles": ['AA1 B S T AH0 K AH0 L Z'],
  "obstetric": ['AH0 B S T EH1 T R IH0 K'],
  "obstetrical": ['AH0 B S T EH1 T R IH0 K AH0 L'],
  "obstetrician": ['AA2 B S T AH0 T R IH1 SH AH0 N'],
  "obstetricians": ['AA2 B S T AH0 T R IH1 SH AH0 N Z'],
  "obstetrics": ['AH0 B S T EH1 T R IH0 K S'],
  "obstfeld": ['AA1 B S T F EH2 L D'],
  "obstinacy": ['AA1 B S T AH0 N AH0 S IY0'],
  "obstinate": ['AA1 B S T AH0 N AH0 T'],
  "obstreperous": ['AH0 B S T R EH1 P ER0 AH0 S'],
  "obstruct": ['AH0 B S T R AH1 K T'],
  "obstructed": ['AH0 B S T R AH1 K T IH0 D'],
  "obstructing": ['AH0 B S T R AH1 K T IH0 NG'],
  "obstruction": ['AH0 B S T R AH1 K SH AH0 N'],
  "obstructionism": ['AH0 B S T R AH1 K SH AH0 N IH2 Z AH0 M'],
  "obstructionist": ['AH0 B S T R AH1 K SH AH0 N AH0 S T', 'AH0 B S T R AH1 K SH AH0 N IH0 S T'],
  "obstructionists": ['AH0 B S T R AH1 K SH AH0 N AH0 S T S', 'AH0 B S T R AH1 K SH AH0 N IH0 S T S'],
  "obstructions": ['AH0 B S T R AH1 K SH AH0 N Z'],
  "obstructive": ['AH0 B S T R AH1 K T IH0 V'],
  "obstructs": ['AH0 B S T R AH1 K T S'],
  "obtain": ['AH0 B T EY1 N'],
  "obtainable": ['AH0 B T EY1 N AH0 B AH0 L'],
  "obtained": ['AH0 B T EY1 N D'],
  "obtaining": ['AH0 B T EY1 N IH0 NG'],
  "obtains": ['AH0 B T EY1 N Z'],
  "obtrude": ['AH0 B T R UW1 D'],
  "obtrudes": ['AH0 B T R UW1 D Z'],
  "obtrusive": ['AH0 B T R UW1 S IH0 V'],
  "obtuse": ['AA0 B T UW1 S'],
  "obuchowski": ['OW0 B Y UW0 K AW1 S K IY0', 'OW0 B UW0 K AW1 S K IY0', 'OW0 B UW0 CH OW1 S K IY0'],
  "obverse": ['AH0 B V ER1 S'],
  "obviate": ['AA1 B V IY0 EY2 T'],
  "obviated": ['AA1 B V IY0 EY2 T AH0 D'],
  "obviates": ['AA1 B V IY0 EY2 T S'],
  "obviating": ['AA1 B V IY0 EY2 T IH0 NG'],
  "obvious": ['AA1 B V IY0 AH0 S'],
  "obviously": ['AA1 B V IY0 AH0 S L IY0'],
  "obyrne": ['OW0 B ER1 N'],
  "ocain": ['OW0 K AA0 IY1 N'],
  "ocala": ['OW0 K AE1 L AH0'],
  "ocallaghan": ['OW0 K AE1 L AH0 G AH0 N'],
  "ocallahan": ['OW0 K AE1 L AH0 HH AE2 N'],
  "ocampo": ['OW0 K AE1 M P OW0'],
  "ocana": ['OW0 K AE1 N AH0'],
  "ocanas": ['OW0 K AE1 N AH0 Z'],
  "ocarroll": ['OW0 K AE1 R AH0 L', 'OW0 K EH1 R AH0 L'],
  "ocasio": ['OW0 K AA1 S IY0 OW0'],
  "ocaw": ['OW0 K AO1'],
  "ocaw's": ['OW0 K AO1 Z'],
  "occasion": ['AH0 K EY1 ZH AH0 N'],
  "occasional": ['AH0 K EY1 ZH AH0 N AH0 L'],
  "occasionally": ['AH0 K EY1 ZH AH0 N AH0 L IY0', 'AH0 K EY1 ZH N AH0 L IY0', 'AH0 K EY1 ZH AH0 N L IY0'],
  "occasioned": ['AH0 K EY1 ZH AH0 N D'],
  "occasions": ['AH0 K EY1 ZH AH0 N Z'],
  "occhino": ['OW2 K IY1 N OW2'],
  "occhipinti": ['OW0 K IY0 P IY1 N T IY0'],
  "occhoa": ['OW2 CH OW1 AA0'],
  "occhoa's": ['OW2 CH OW1 AH0 Z'],
  "occident": ['AA1 K S AH0 D EH2 N T'],
  "occidental": ['AA2 K S AH0 D EH1 N T AH0 L', 'AA2 K S AH0 D EH1 N AH0 L'],
  "occidental's": ['AA2 K S AH0 D EH1 N T AH0 L Z', 'AA2 K S AH0 D EH1 N AH0 L Z'],
  "occidentale": ['AA2 K S IH0 D EH1 N T AH0 L'],
  "occidentale's": ['AA2 K S IH0 D EH1 N T AH0 L Z'],
  "occipital": ['AA0 K S IH1 P AH0 T AH0 L'],
  "occlusion": ['AH0 K L UW1 ZH AH0 N'],
  "occult": ['AH0 K AH1 L T'],
  "occupancy": ['AA1 K Y AH0 P AH0 N S IY0'],
  "occupant": ['AA1 K Y AH0 P AH0 N T'],
  "occupants": ['AA1 K Y AH0 P AH0 N T S'],
  "occupation": ['AA2 K Y AH0 P EY1 SH AH0 N'],
  "occupational": ['AA0 K Y AH0 P EY1 SH AH0 N AH0 L'],
  "occupations": ['AA2 K Y AH0 P EY1 SH AH0 N Z'],
  "occupied": ['AA1 K Y AH0 P AY2 D'],
  "occupier": ['AA1 K Y AH0 P AY2 ER0'],
  "occupiers": ['AA1 K Y AH0 P AY2 ER0 Z'],
  "occupies": ['AA1 K Y AH0 P AY2 Z'],
  "occupy": ['AA1 K Y AH0 P AY2'],
  "occupying": ['AA1 K Y AH0 P AY2 IH0 NG'],
  "occur": ['AH0 K ER1'],
  "occuring": ['AH0 K ER1 IH0 NG'],
  "occurred": ['AH0 K ER1 D'],
  "occurrence": ['AH0 K ER1 AH0 N S'],
  "occurrences": ['AH0 K ER1 AH0 N S IH0 Z'],
  "occurring": ['AH0 K ER1 IH0 NG'],
  "occurs": ['AH0 K ER1 Z'],
  "ocean": ['OW1 SH AH0 N'],
  "ocean's": ['OW1 SH AH0 N Z'],
  "oceana": ['OW0 SH IY0 AE1 N AH0'],
  "oceaneering": ['OW2 SH AH0 N IH1 R IH0 NG'],
  "oceanfront": ['OW1 SH AH0 N F R AH2 N T'],
  "oceangoing": ['OW1 SH AH0 N G OW2 IH0 NG'],
  "oceania": ['OW2 SH IY2 AE1 N Y AH0'],
  "oceanic": ['OW2 SH IY0 AE1 N IH0 K'],
  "oceanographer": ['OW2 SH AH0 N AA1 G R AH0 F ER0'],
  "oceanographic": ['OW2 SH AH0 N AH0 G R AE1 F IH0 K'],
  "oceanography": ['OW2 SH AH0 N AA1 G R AH0 F IY0'],
  "oceans": ['OW1 SH AH0 N Z'],
  "oceanside": ['OW1 SH AH0 N S AY2 D'],
  "oceanview": ['OW1 SH AH0 N V Y UW2'],
  "ocelot": ['AA1 S AH0 L AA2 T'],
  "ocelot's": ['AA1 S AH0 L AA2 T S'],
  "och": ['AA1 K'],
  "ocheltree": ['AA0 CH IH0 L T R IY1'],
  "ochoa": ['AA2 CH OW1 AH0', 'OW2 CH OW1 AH0'],
  "ochra": ['AA1 K R AH0'],
  "ochre": ['OW1 K ER0'],
  "ochs": ['AA1 K S', 'OW1 K S'],
  "ochsenschlager": ['AA1 K S AH0 N SH L AA2 G ER0'],
  "ochsner": ['AA1 K S N ER0'],
  "ocilla": ['OW0 S IH1 L AH0'],
  "ocker": ['AA1 K ER0'],
  "ockerbloom": ['AA1 K ER0 B L UW0 M'],
  "ockerman": ['AA1 K ER0 M AH0 N'],
  "oclair": ['OW0 K L ER1'],
  "ocon": ['AH0 K AA1 N'],
  "oconnell": ['OW0 K AA1 N AH0 L'],
  "oconner": ['OW0 K AA1 N ER0'],
  "oconner's": ['OW0 K AA1 N ER0 Z'],
  "oconnor": ['OW0 K AA1 N ER0'],
  "oconnor's": ['OW0 K AA1 N ER0 Z'],
  "oct": ['AO1 K T'],
  "octagon": ['AA1 K T AH0 G AA2 N'],
  "octagonal": ['AA0 K T AE1 G AH0 N AH0 L'],
  "octahedral": ['AA2 K T AH0 HH IY1 D R AH0 L'],
  "octahedron": ['AA2 K T AH0 HH IY1 D R AH0 N'],
  "octane": ['AA1 K T EY0 N'],
  "octave": ['AA1 K T IH0 V'],
  "octaves": ['AA1 K T IH0 V Z'],
  "octavia": ['AA0 K T EY1 V IY0 AH0'],
  "octavio": ['AA2 K T EY1 V IY0 OW0'],
  "octavius": ['AA0 K T EY1 V IY0 AH0 S'],
  "octavus": ['AA1 K T AH0 V UW0 S'],
  "octel": ['AA2 K T EH1 L'],
  "octet": ['AA0 K T EH1 T'],
  "octial": ['AA1 K T Y AH0 L'],
  "octillion": ['AA0 K T IH1 L Y AH0 N'],
  "october": ['AA0 K T OW1 B ER0'],
  "october's": ['AA0 K T OW1 B ER0 Z'],
  "octogenarian": ['AA2 K T AH0 JH IH0 N EH1 R IY0 AH0 N'],
  "octopi": ['AA1 K T AH0 P AY0'],
  "octopus": ['AA1 K T AH0 P UH2 S'],
  "octuplet": ['AA0 K T AH1 P L AH0 T'],
  "octuplets": ['AA0 K T AH1 P L AH0 T S'],
  "ocular": ['AO1 K Y UW0 L ER0'],
  "oculist": ['AO1 K Y UW0 L IH2 S T'],
  "oda": ['OW1 D AH0'],
  "odaiko": ['OW0 D EY1 K OW0'],
  "odaniel": ['AA1 D AH0 N IY0 L'],
  "oday": ['OW0 D EY1'],
  "odd": ['AA1 D'],
  "oddball": ['AA1 D B AO2 L'],
  "oddballs": ['AA1 D B AO2 L Z'],
  "odden": ['AA1 D AH0 N'],
  "odder": ['AA1 D ER0'],
  "oddest": ['AA1 D AH0 S T'],
  "oddi": ['AA1 D IY0', 'OW1 D IY0'],
  "oddities": ['AA1 D AH0 T IY0 Z'],
  "oddity": ['AA1 D AH0 T IY0'],
  "oddler": ['AA1 D L ER0'],
  "oddler's": ['AA1 D L ER0 Z'],
  "oddly": ['AA1 D L IY0'],
  "odds": ['AA1 D Z'],
  "odds-on": ['AA1 D Z AA1 N'],
  "oddsmaker": ['AA1 D Z M EY2 K ER0'],
  "oddsmakers": ['AA1 D Z M EY2 K ER0 Z'],
  "oddy": ['AA1 D IY0'],
  "ode": ['OW1 D'],
  "odea": ['AA1 D IY0 AH0'],
  "oded": ['OW1 D EH0 D'],
  "odegaard": ['AA1 D IH0 G AA0 R D'],
  "odegard": ['AA1 D IH0 G ER0 D'],
  "odekirk": ['AA1 D IH0 K ER0 K'],
  "odele": ['OW0 D EH1 L IY0'],
  "odelet": ['AA1 D IH0 L IH0 T'],
  "odelette": ['AA1 D IH0 L EH0 T'],
  "odelia": ['OW0 D EH1 L IY0 AH0'],
  "odelinda": ['OW0 D EH0 L IY1 N D AH0'],
  "odell": ['OW0 D EH1 L'],
  "odella": ['OW0 D EH1 L AH0'],
  "odem": ['OW1 D IH0 M'],
  "oden": ['OW1 D AH0 N'],
  "odenthal": ['AA1 D IH0 N TH AH0 L'],
  "odeon": ['OW1 D IY0 AH0 N'],
  "oder": ['OW1 D ER0'],
  "odes": ['OW1 D Z'],
  "odessa": ['OW0 D EH1 S AH0'],
  "odetics": ['OW0 D EH1 T IH0 K S'],
  "odette": ['OW2 D EH1 T'],
  "odgers": ['AA1 JH ER0 Z'],
  "odiaum": ['OW1 D IY0 AH0 M'],
  "odiaun": ['OW1 D IY0 AH0 N'],
  "odier": ['OW1 D IY0 ER0'],
  "odilia": ['OW0 D IY1 L IY0 AH0'],
  "odin": ['OW1 D AH0 N'],
  "odiorne": ['OW0 D IY0 AO1 R N IY0'],
  "odious": ['OW1 D IY0 AH0 S'],
  "odland": ['AA1 D L AH0 N D'],
  "odle": ['OW1 D AH0 L'],
  "odneal": ['OW1 D N IY2 L'],
  "odoherty": ['OW0 D AA1 ER0 T IY0', 'OW0 D OW1 ER0 T IY0'],
  "odolf": ['AH0 D OW1 L F'],
  "odom": ['OW1 D AH0 M'],
  "odometer": ['OW2 D AA1 M AH0 T ER0'],
  "odometers": ['OW2 D AA1 M AH0 T ER0 Z'],
  "odoms": ['OW1 D AH0 M Z'],
  "odonald": ['OW0 D AA1 N AH0 L D'],
  "odonnel": ['OW0 D AA1 N AH0 L'],
  "odonnell": ['OW0 D AA1 N AH0 L'],
  "odonoghue": ['OW0 D AA1 N AH0 HH Y UW0', 'OW0 D AA1 N AH0 HH UW0'],
  "odonohue": ['OW0 D AA1 N AH0 HH Y UW0'],
  "odonovan": ['OW0 D AA1 N AH0 V AH0 N'],
  "odor": ['OW1 D ER0'],
  "odorant": ['OW1 D ER0 AH0 N T'],
  "odorless": ['OW1 D ER0 L AH0 S'],
  "odorous": ['OW1 D ER0 AH0 S'],
  "odors": ['OW1 D ER0 Z'],
  "odour": ['OW1 D ER0'],
  "odours": ['OW1 D ER0 S'],
  "odowd": ['OW0 D AW1 D'],
  "odp": ['OW1 D IY1 P IY1'],
  "odriscoll": ['OW0 D R IH1 S K AA0 L'],
  "odum": ['OW1 D AH0 M'],
  "odwalla": ['AA0 D W AA1 L AH0'],
  "odwyer": ['OW0 D W AY1 ER0'],
  "odysseus": ['OW0 D IH1 S IY0 AH0 S'],
  "odyssey": ['AA1 D AH0 S IY0'],
  "odyssey's": ['AA1 D AH0 S IY0 Z'],
  "oecd": ['OW2 IY2 S IY2 D IY1'],
  "oedipal": ['EH1 D AH0 P AH0 L'],
  "oedipus": ['EH1 D IH0 P AH0 S'],
  "oehler": ['OW1 L ER0'],
  "oehlert": ['OW1 L ER0 T'],
  "oehlke": ['OW1 L K'],
  "oehme": ['OW1 M'],
  "oehmen": ['OW1 M AH0 N'],
  "oehmens": ['OW1 M AH0 N Z'],
  "oehrlein": ['AO1 R L AY0 N'],
  "oeien": ['OW1 IY0 AH0 N'],
  "oelke": ['OW1 L K'],
  "oelkers": ['OW1 L K ER0 Z'],
  "oelman": ['OW1 L M AH0 N'],
  "oelrich": ['OW1 L R IH0 K'],
  "oelschlager": ['OW1 L SH L EY0 G ER0'],
  "oenophile": ['IY1 N AH0 F AY2 L'],
  "oerlikon": ['AO1 R L IH0 K AA2 N'],
  "oertel": ['AO1 R T AH0 L'],
  "oesch": ['OW1 SH'],
  "oeser": ['OW1 Z ER0'],
  "oest": ['OW1 IH0 S T'],
  "oesterle": ['OW1 S T ER0 AH0 L'],
  "oesterling": ['OW1 S T ER0 L IH0 NG'],
  "oesterreich": ['OW1 S T ER0 AY0 K'],
  "oesterreichische": ['OW2 S T ER0 AY1 K IH0 SH IY0'],
  "oestreich": ['OW1 S T R AY2 K'],
  "oestreicher": ['OW1 S T R AY2 K ER0'],
  "oetken": ['OW1 T K AH0 N'],
  "oetting": ['OW1 T IH0 NG'],
  "oettinger": ['OW1 T IH0 N JH ER0', 'OW1 T IH0 NG ER0'],
  "oeuvre": ['UW1 V R AH0', 'ER1 V'],
  "of": ['AH1 V'],
  "ofallon": ['OW0 F AE1 L AH0 N'],
  "ofarrell": ['OW0 F EH1 R AH0 L', 'OW0 F AE1 R AH0 L'],
  "ofc": ['OW1 EH1 F S IY1'],
  "ofelia": ['OW0 F EY1 L IY0 AH0'],
  "ofer": ['OW1 F ER0'],
  "off": ['AO1 F'],
  "off's": ['AO1 F S'],
  "off-day": ['AO1 F D EY2'],
  "off-duty": ['AO2 F D UW1 T IY0'],
  "off-limits": ['AO2 F L IH1 M IH0 T S'],
  "off-line": ['AO1 F L AY2 N'],
  "off-side": ['AO1 F S AY2 D'],
  "offbeat": ['AO1 F B IY1 T'],
  "offen": ['AO1 F AH0 N'],
  "offend": ['AH0 F EH1 N D'],
  "offended": ['AH0 F EH1 N D AH0 D', 'AH0 F EH1 N D IH0 D'],
  "offender": ['AH0 F EH1 N D ER0'],
  "offenders": ['AH0 F EH1 N D ER0 Z'],
  "offending": ['AH0 F EH1 N D IH0 NG'],
  "offends": ['AH0 F EH1 N D Z'],
  "offense": ['AH0 F EH1 N S'],
  "offenses": ['AH0 F EH1 N S IH0 Z'],
  "offensive": ['AH0 F EH1 N S IH0 V'],
  "offensively": ['AH0 F EH1 N S IH0 V L IY0'],
  "offensives": ['AH0 F EH1 N S IH0 V Z'],
  "offer": ['AO1 F ER0'],
  "offer's": ['AO1 F ER0 Z'],
  "offerdahl": ['AA1 F ER0 D AA0 L'],
  "offered": ['AO1 F ER0 D'],
  "offerer": ['AO1 F ER0 ER0'],
  "offerers": ['AO1 F ER0 ER0 Z'],
  "offering": ['AO1 F ER0 IH0 NG', 'AO1 F R IH0 NG'],
  "offering's": ['AO1 F ER0 IH0 NG Z', 'AO1 F R IH0 NG Z'],
  "offerings": ['AO1 F ER0 IH0 NG Z', 'AO1 F R IH0 NG Z'],
  "offerman": ['AO1 F ER0 M AH0 N'],
  "offermann": ['AO1 F ER0 M AH0 N'],
  "offers": ['AO1 F ER0 Z'],
  "offhand": ['AO1 F HH AE1 N D'],
  "office": ['AO1 F IH0 S'],
  "office's": ['AO1 F AH0 S IH0 Z'],
  "officeholder": ['AO1 F AH0 S HH OW2 L D ER0'],
  "officeholders": ['AO1 F AH0 S HH OW2 L D ER0 Z'],
  "officemax": ['AO1 F AH0 S M AE2 K S'],
  "officer": ['AO1 F AH0 S ER0', 'AO1 F IH0 S ER0'],
  "officer's": ['AO1 F IH0 S ER0 Z'],
  "officers": ['AO1 F AH0 S ER0 Z', 'AO1 F IH0 S ER0 Z'],
  "officers'": ['AO1 F IH0 S ER0 Z'],
  "offices": ['AO1 F AH0 S AH0 Z', 'AO1 F AH0 S IH0 Z'],
  "official": ['AH0 F IH1 SH AH0 L'],
  "official's": ['AH0 F IH1 SH AH0 L Z'],
  "officialdom": ['AH0 F IH1 SH AH0 L D AH0 M'],
  "officially": ['AH0 F IH1 SH AH0 L IY0'],
  "officials": ['AH0 F IH1 SH AH0 L Z'],
  "officials'": ['AH0 F IH1 SH AH0 L Z'],
  "officiate": ['AH0 F IH1 SH IY0 EY2 T'],
  "officiated": ['AH0 F IH1 SH IY0 EY2 T AH0 D'],
  "officiates": ['AH0 F IH1 SH IY0 EY2 T S'],
  "officiating": ['AH0 F IH1 SH IY0 EY2 T IH0 NG'],
  "officio": ['AH0 F IH1 S IY0 OW0'],
  "offield": ['AA1 F IY2 L D'],
  "offill": ['AO1 F IH2 L'],
  "offing": ['AO1 F IH0 NG'],
  "offlimit": ['AO2 F L IH1 M IH0 T'],
  "offlimits": ['AO2 F L IH1 M IH0 T S'],
  "offline": ['AO1 F L AY2 N'],
  "offner": ['AA1 F N ER0'],
  "offord": ['AA1 F ER0 D'],
  "offs": ['AO1 F S'],
  "offseason": ['AO1 F S IY1 Z AH0 N'],
  "offset": ['AO0 F S EH1 T', 'AO1 F S EH2 T'],
  "offsets": ['AO1 F S EH2 T S'],
  "offsetting": ['AO0 F S EH1 T IH0 NG', 'AO1 F S EH2 T IH0 NG'],
  "offshoot": ['AO1 F SH UW2 T'],
  "offshoots": ['AO1 F SH UW2 T S'],
  "offshorable": ['AO1 F SH AO1 R AH2 B AH0 L'],
  "offshore": ['AO1 F SH AO1 R'],
  "offshored": ['AO1 F SH AO1 R D'],
  "offspring": ['AO1 F S P R IH2 NG'],
  "offstage": ['AO1 F S T EY1 JH'],
  "offutt": ['AA1 F AH0 T'],
  "ofilia": ['OW0 F IY1 L IY0 AH0'],
  "oflaherty": ['OW0 F L EY1 HH ER0 T IY0', 'OW0 F L EY1 ER0 T IY0'],
  "oflynn": ['OW0 F L IH1 N'],
  "oft": ['AO1 F T'],
  "oftedahl": ['AA1 F T IH0 D AA0 L'],
  "oftel": ['AA1 F T EH2 L'],
  "often": ['AO1 F AH0 N', 'AO1 F T AH0 N'],
  "oftener": ['AO1 F AH0 N ER0', 'AO1 F T AH0 N ER0'],
  "oftentimes": ['AO1 F AH0 N T AY2 M Z', 'AO1 F T AH0 N T AY2 M Z'],
  "og": ['AA1 G'],
  "ogan": ['OW1 G AH0 N'],
  "ogara": ['OW2 G AA1 R AA2'],
  "ogata": ['OW2 G AA1 T AA2'],
  "ogawa": ['OW2 G AA1 W AA2'],
  "ogborn": ['AA1 G B ER0 N'],
  "ogburn": ['AA1 G B ER0 N'],
  "ogden": ['AA1 G D AH0 N'],
  "ogden's": ['AA1 G D AH0 N Z'],
  "ogg": ['AA1 G'],
  "ogier": ['OW1 G IY0 ER0'],
  "ogilvie": ['AA1 JH IH0 L V IY0', 'OW2 G IH1 L V IY0'],
  "ogilvy": ['OW1 G AH0 L V IY0'],
  "ogilvy's": ['OW1 G AH0 L V IY0 Z'],
  "oglala": ['OW0 G L AA1 L AH0'],
  "ogle": ['OW1 G AH0 L'],
  "oglebay": ['OW1 G AH0 L B EY2'],
  "ogled": ['OW1 G AH0 L D'],
  "ogles": ['OW1 G AH0 L Z'],
  "oglesbee": ['OW1 G AH0 L Z B IY2'],
  "oglesby": ['AA1 G AH0 L S B IY0'],
  "oglethorpe": ['OW1 G AH0 L TH AO2 R P'],
  "ogletree": ['OW1 G AH0 L T R IY2'],
  "ognibene": ['OW0 G N IY0 B EH1 N AH0'],
  "ogoni": ['OW0 G OW1 N IY0'],
  "ogonyok": ['OW0 G OW1 N Y AA0 K'],
  "ogorman": ['OW0 G AO1 R M AH0 N'],
  "ogrady": ['OW0 G R EY1 D IY0'],
  "ogre": ['OW1 G ER0'],
  "ogren": ['AA1 G R EH0 N'],
  "ogress": ['OW1 G R AH0 S'],
  "oguin": ['OW0 G UW1 IY0 N'],
  "oguinn": ['OW0 G W IH1 N'],
  "oh": ['OW1'],
  "oh's": ['OW1 Z'],
  "ohagan": ['OW0 HH AA1 G AH0 N', 'OW0 HH EY1 G AH0 N'],
  "ohair": ['OW0 HH EH1 R'],
  "ohalloran": ['OW0 HH AE1 L ER0 AH0 N'],
  "ohanesian": ['OW0 HH AH0 N EH1 ZH IH0 N'],
  "ohanian": ['OW0 HH EY1 N IY0 AH0 N'],
  "ohanlon": ['OW0 HH AE1 N L AH0 N'],
  "ohara": ['OW0 HH EH1 R AH0'],
  "ohara's": ['OW0 HH EH1 R AH0 Z'],
  "ohare": ['OW0 HH EH1 R'],
  "oharra": ['OW0 HH ER1 AH0'],
  "ohashi": ['OW2 HH AA1 SH IY2'],
  "ohaver": ['OW0 HH EY1 V ER0'],
  "ohba": ['OW1 B AH0'],
  "ohbayashi": ['OW2 B AA2 Y AA1 SH IY2'],
  "ohearn": ['OW0 HH ER1 N'],
  "ohern": ['OW0 HH ER1 N'],
  "oherron": ['OW0 HH EH1 R AH0 N'],
  "ohh": ['OW1'],
  "ohio": ['OW0 HH AY1 OW0'],
  "ohio's": ['OW0 HH AY1 OW0 Z'],
  "ohioan": ['OW2 HH AY1 OW2 AH0 N'],
  "ohioans": ['OW2 HH AY1 OW2 AH0 N Z'],
  "ohkawara": ['OW2 K AA2 W AA1 R AA2'],
  "ohl": ['OW1 L'],
  "ohland": ['OW1 L AH0 N D'],
  "ohlendorf": ['OW1 L IH0 N D AO0 R F'],
  "ohler": ['OW1 L ER0'],
  "ohlin": ['OW1 L IH0 N'],
  "ohlinger": ['OW1 L IH0 NG ER0'],
  "ohlman": ['OW1 L M AH0 N'],
  "ohlmann": ['OW1 L M AH0 N'],
  "ohlmeyer": ['OW1 L M AY2 R'],
  "ohlrich": ['OW1 L R IH0 K'],
  "ohlsen": ['OW1 L S AH0 N'],
  "ohlson": ['OW1 L S AH0 N'],
  "ohlsson": ['OW1 L S AH0 N'],
  "ohm": ['OW1 M'],
  "ohm's": ['OW1 M Z'],
  "ohmae": ['OW1 M EY2'],
  "ohman": ['OW1 M AH0 N'],
  "ohmann": ['OW1 M AH0 N'],
  "ohmer": ['OW1 M ER0'],
  "ohms": ['OW1 M Z'],
  "ohmura": ['OW0 M UW1 R AH0'],
  "ohnemus": ['OW1 N IH0 M IH0 S'],
  "ohnstad": ['OW1 N S T AH0 D'],
  "ohr": ['AO1 R'],
  "ohrt": ['AO1 R T'],
  "ohs": ['OW1 Z'],
  "oi": ['OY1'],
  "oie": ['OY1'],
  "oien": ['AA1 IY0 N'],
  "oil": ['OY1 L'],
  "oil's": ['OY1 L Z'],
  "oiled": ['OY1 L D'],
  "oiler": ['OY1 L ER0'],
  "oilers": ['OY1 L ER0 Z'],
  "oilfield": ['OY1 L F IY2 L D'],
  "oilfields": ['OY1 L F IY2 L D Z'],
  "oiling": ['OY1 L IH0 NG'],
  "oilman": ['OY1 L M AE2 N'],
  "oilmen": ['OY1 L M EH1 N'],
  "oils": ['OY1 L Z'],
  "oilseed": ['OY1 L S IY2 D'],
  "oilseeds": ['OY1 L S IY2 D Z'],
  "oilwell": ['OY1 L W EH2 L'],
  "oily": ['OY1 L IY0'],
  "ointment": ['OY1 N T M AH0 N T'],
  "oishi": ['OW0 IY1 SH IY0'],
  "oj": ['OW1 JH EY1'],
  "oja": ['OW1 Y AH0'],
  "ojala": ['OW0 Y AA1 L AH0'],
  "ojeda": ['OW0 Y EY1 D AH0'],
  "oji": ['OW1 JH IY0'],
  "ojibwa": ['OW0 JH IH1 B W AH0'],
  "ok": ['OW1 K EY1'],
  "oka": ['OW1 K AH0'],
  "okabe": ['OW0 K AA1 B EY0'],
  "okada": ['OW0 K AA1 D AH0'],
  "okaloosa": ['AO2 K AH0 L UW1 S AH0', 'OW2 K AH0 L UW1 S AH0'],
  "okamoto": ['OW2 K AH0 M OW1 T OW0'],
  "okamura": ['OW2 K AH0 M UH1 R AH0'],
  "okane": ['OW0 K EY1 N'],
  "okano": ['OW0 K AA1 N OW0'],
  "okasaki": ['OW2 K AH0 S AA1 K IY0'],
  "okasan": ['OW0 K EY1 Z AH0 N'],
  "okay": ['OW2 K EY1'],
  "okayed": ['OW2 K EY1 D'],
  "okays": ['OW2 K EY1 Z'],
  "okaz": ['OW0 K AA1 Z'],
  "okazaki": ['OW0 K AA0 Z AA1 K IY0'],
  "okdo": ['AO1 K D OW2'],
  "okeechobee": ['OW0 K IY2 CH OW1 B IY0'],
  "okeefe": ['OW0 K IY1 F'],
  "okeeffe": ['OW0 K IY1 F'],
  "okelley": ['OW0 K EH1 L IY0'],
  "okelly": ['OW0 K EH1 L IY0'],
  "oken": ['OW0 K EY1 AH0 N'],
  "okerlund": ['AA1 K ER0 L AH0 N D'],
  "okeson": ['OW1 K IH0 S AH0 N'],
  "okey": ['OW1 K IY0'],
  "okey-dokey": ['OW1 K IY0 D OW1 K IY0'],
  "oki": ['OW1 K IY0'],
  "okie": ['OW1 K IY0'],
  "okimoto": ['OW0 K IY0 M OW1 T OW0'],
  "okin": ['OW1 K IH0 N'],
  "okinawa": ['OW2 K IH2 N AA1 W AH0', 'OW2 K IY0 N AA1 W AA2'],
  "okinawans": ['OW2 K IH2 N AA1 W AH0 N Z', 'OW2 K IY2 N AA1 W AH0 N Z'],
  "oklahoma": ['OW2 K L AH0 HH OW1 M AH0'],
  "oklahoma's": ['OW2 K L AH0 HH OW1 M AH0 Z'],
  "oklahoman": ['OW2 K L AH0 HH OW1 M AH0 N'],
  "oklahomans": ['OW2 K L AH0 HH OW1 M AH0 N Z'],
  "okon": ['OW1 K OW0 N'],
  "okoniewski": ['OW0 K AA2 N IY0 EH1 V S K IY0', 'OW2 K AH0 N UW1 S K IY0'],
  "okonski": ['AH0 K AA1 N S K IY0'],
  "okpo": ['AA1 K P OW0'],
  "okra": ['OW1 K R AH0'],
  "okray": ['AA1 K R EY0'],
  "oksana": ['AA2 K S AE1 N AH0'],
  "oksana's": ['AA2 K S AE1 N AH0 Z'],
  "oksenberg": ['AA1 K S AH0 N B ER0 G'],
  "okubo": ['OW0 K UW1 B OW0'],
  "okuda": ['OW0 K UW1 D AH0'],
  "okuma": ['OW0 K Y UW1 M ER0'],
  "okumura": ['OW0 K UW0 M UH1 R AH0'],
  "okun": ['OW1 K UW0 N'],
  "okuno": ['OW0 K Y UW1 N OW0'],
  "okura": ['OW0 K UW1 R AH0'],
  "okurowski": ['OW0 K ER0 OW1 S K IY0'],
  "okwu": ['AO1 K W UW0'],
  "ol'": ['OW1 L'],
  "ola": ['OW1 L AH0'],
  "olack": ['OW1 L AE0 K'],
  "olaf": ['OW1 L AA0 F'],
  "olafson": ['OW1 L AH0 F S AH0 N'],
  "olague": ['OW1 L AA0 G'],
  "olah": ['AH0 L AA1', 'OW1 L AH0'],
  "olajuwon": ['AH0 L AY1 JH UW0 AA2 N'],
  "olajuwon's": ['AH0 L AY1 JH UW0 AA2 N Z'],
  "oland": ['AA1 L AH0 N D'],
  "olander": ['AA1 L AH0 N D ER0'],
  "olano": ['AH0 L AA1 N OW0'],
  "olasky": ['OW0 L AE1 S K IY0'],
  "olathe": ['OW0 L AE1 TH IY0'],
  "olaughlin": ['OW0 L AO1 K L IH0 N'],
  "olay": ['OW0 L EY1'],
  "olayan": ['OW0 L AY1 AH0 N'],
  "olberding": ['OW1 L B ER0 D IH0 NG'],
  "olbrich": ['OW1 L B R IH0 K'],
  "olcott": ['OW1 L K AH0 T'],
  "olczak": ['OW1 L CH AE0 K'],
  "old": ['OW1 L D'],
  "old's": ['OW1 L D Z'],
  "old-fashioned": ['OW2 L D F AE1 SH AH0 N D'],
  "old-growth": ['OW2 L D G R OW1 TH'],
  "old-school": ['OW2 L D S K UW1 L'],
  "old-timer": ['OW2 L D T AY1 M ER0'],
  "old-timers": ['OW2 L D T AY1 M ER0 Z'],
  "oldaker": ['OW1 L D AH0 K ER0'],
  "oldani": ['OW0 L D AA1 N IY0'],
  "olde": ['OW1 L D'],
  "olden": ['OW1 L D AH0 N'],
  "oldenburg": ['OW1 L D AH0 N B ER0 G'],
  "oldenkamp": ['OW1 L D IH0 N K AE0 M P'],
  "older": ['OW1 L D ER0'],
  "oldest": ['OW1 L D AH0 S T'],
  "oldfashioned": ['OW2 L D F AE1 SH AH0 N D'],
  "oldfather": ['OW1 L D F AA2 DH ER0'],
  "oldfield": ['OW1 L D F IY2 L D'],
  "oldham": ['OW1 L D AH0 M'],
  "oldham's": ['OW1 L D AH0 M Z'],
  "oldie": ['OW1 L D IY0'],
  "oldies": ['OW1 L D IY0 Z'],
  "oldman": ['OW1 L D M AH0 N'],
  "oldroyd": ['OW1 L D R OY2 D'],
  "olds": ['OW1 L D Z'],
  "oldsmar": ['OW1 L D Z M AA0 R'],
  "oldsmobile": ['OW1 L D Z M OW0 B IY2 L'],
  "oldsmobile's": ['OW1 L D Z M OW0 B IY2 L Z', 'OW1 L Z M OW0 B IY2 L Z'],
  "oldsmobiles": ['OW1 L D Z M OW0 B IY2 L Z'],
  "oldster": ['OW1 L D S T ER0'],
  "oldsters": ['OW1 L D S T ER0 Z'],
  "oldt": ['OW1 L T'],
  "ole": ['OW1 L', 'OW2 L EY1'],
  "olea": ['AA1 L IY0 AH0', 'OW0 L IY1'],
  "oleaginous": ['OW2 L IY0 AE1 JH AH0 N AH0 S'],
  "olean": ['OW0 L IY1 N'],
  "oleander": ['OW1 L IY0 AE2 N D ER0'],
  "oleandrin": ['OW0 L IY0 AE1 N D R IH0 N'],
  "olear": ['OW0 L IH1 R'],
  "oleary": ['OW0 L IH1 R IY0'],
  "oleaster": ['OW2 L IY0 AE1 S T ER0'],
  "olefin": ['OW1 L AH0 F IH0 N'],
  "olefins": ['OW1 L AH0 F IH0 N Z'],
  "oleg": ['OW1 L AH0 G'],
  "olejniczak": ['AH0 L EY1 N IH0 CH AE0 K'],
  "olejnik": ['AH0 L EY1 N IH0 K'],
  "oleksiak": ['AH0 L EH1 K S IY0 AE0 K'],
  "oleksy": ['AH0 L EH1 K S IY0'],
  "olen": ['AA1 L AH0 N'],
  "olender": ['AA1 L EH0 N D ER0'],
  "olenick": ['AA1 L IH0 N IH0 K'],
  "olenik": ['AA1 L IH0 N IH0 K'],
  "oleo": ['OW1 L IY0 OW2'],
  "oleoyl": ['OW2 L IY0 OY1 L'],
  "oleoyls": ['OW2 L IY0 OY1 L Z'],
  "oler": ['OW1 L ER0'],
  "oles": ['OW1 L Z', 'OW2 L EY1 Z'],
  "olesen": ['AA1 L IY0 Z AH0 N'],
  "oleske": ['OW1 L AH0 S K IY0'],
  "olesky": ['AH0 L EH1 S K IY0'],
  "oleson": ['AA1 L IH0 S AH0 N'],
  "olestra": ['OW0 L EH1 S T R AH0'],
  "olexa": ['AH0 L IY1 K S AH0'],
  "olexson": ['OW1 L AH0 K S AH0 N'],
  "oley": ['OW1 L IY0'],
  "olfactory": ['OW0 L F AE1 K T ER0 IY0'],
  "olga": ['OW1 L G AH0'],
  "olguin": ['OW1 L G IH0 N'],
  "olick": ['OW1 L IH0 K'],
  "oliff": ['AA1 L IH0 F'],
  "oligarch": ['OW1 L IH0 G AA2 R K'],
  "oligarchs": ['OW1 L IH0 G AA2 R K S'],
  "oligarchy": ['AA1 L AH0 G AA2 R K IY0'],
  "oliger": ['AA1 L IH0 G ER0'],
  "oligocene": ['AA1 L AH0 G OW0 S IY2 N'],
  "oligopolistic": ['OW0 L IH2 G AH0 P OW0 L IH1 S T IH0 K'],
  "oligopoly": ['AA2 L IH0 G AA1 P AH0 L IY0'],
  "olin": ['OW1 L IH0 N'],
  "olin's": ['OW1 L IH0 N Z'],
  "olinda": ['OW0 L IY1 N D AH0'],
  "olinde": ['AA1 L IH0 N D'],
  "olinger": ['AA1 L IH0 NG ER0'],
  "oliphant": ['AA1 L IH0 F AH0 N T'],
  "oliva": ['OW0 L IY1 V AH0'],
  "olivares": ['OW0 L IY0 V AA1 R EH0 S'],
  "olivarez": ['OW0 L IY0 V AA1 R EH0 Z'],
  "olivas": ['OW0 L IY1 V AA0 Z'],
  "olive": ['AA1 L AH0 V', 'AA1 L IH0 V'],
  "oliveira": ['AA2 L IH0 V EY1 R AH0'],
  "oliver": ['AA1 L AH0 V ER0', 'AA1 L IH0 V ER0'],
  "oliver's": ['AA1 L IH0 V ER0 Z'],
  "olivera": ['OW0 L IY0 V EH1 R AH0'],
  "oliveras": ['OW0 L IY0 V EH1 R AA0 Z'],
  "oliveri": ['OW0 L IY0 V EH1 R IY0'],
  "oliveria": ['AA2 L IH0 V IY1 R IY0 AH0'],
  "oliverio": ['AA2 L IH0 V IY1 R IY0 OW0'],
  "olivero": ['OW0 L IY0 V EH1 R OW0'],
  "oliveros": ['OW0 L IY0 V EH1 R OW0 Z'],
  "olives": ['AA1 L IH0 V Z'],
  "oliveto": ['OW0 L IY0 V EY1 T OW0'],
  "olivette": ['AA1 L IH0 V EH1 T'],
  "olivetti": ['AA2 L IH0 V EH1 T IY0'],
  "olivetti's": ['AA2 L IH0 V EH1 T IY0 Z'],
  "olivia": ['OW0 L IH1 V IY0 AH0'],
  "olivia's": ['OW0 L IH1 V IY0 AH0 Z'],
  "olivier": ['OW2 L IH1 V IY2 EY2'],
  "olivieri": ['OW0 L IY0 V IH1 R IY0'],
  "olivine": ['AA1 L AH0 V IY2 N'],
  "olivo": ['AO0 L IY1 V OW0'],
  "oliwa": ['OW0 L IY1 W AH0'],
  "olk": ['OW1 K', 'OW1 L K'],
  "olkowski": ['OW0 L K AO1 F S K IY0'],
  "olkowski's": ['OW0 L K AO1 F S K IY0 Z'],
  "ollar": ['AA1 L ER0'],
  "oller": ['AA1 L ER0'],
  "olley": ['AA1 L IY0'],
  "ollie": ['AA1 L IY0', 'OW1 L IY0'],
  "ollie's": ['AA1 L IY0 Z', 'OW1 L IY0 Z'],
  "olliff": ['AA1 L IH0 F'],
  "ollila": ['AA1 L IH0 L AH0'],
  "ollinger": ['AA1 L IH0 NG ER0'],
  "ollinger's": ['AA1 L IH0 NG ER0 Z'],
  "ollis": ['AO1 L IY0 Z'],
  "ollison": ['AA1 L IH0 S AH0 N'],
  "ollison's": ['AA1 L IH0 S AH0 N Z'],
  "olly": ['AA1 L IY0'],
  "olmeda": ['OW0 L M EY1 D AH0'],
  "olmedo": ['OW0 L M EY1 D OW0'],
  "olmert": ['OW0 L M ER0 T'],
  "olmo": ['OW1 L M OW0'],
  "olmos": ['OW1 L M OW0 Z'],
  "olmstead": ['OW1 L M S T EH2 D'],
  "olmstead's": ['OW1 L M S T EH2 D Z'],
  "olney": ['OW1 L N IY0'],
  "olof": ['OW1 L AO0 F'],
  "olof's": ['OW1 L AO0 F S'],
  "olofson": ['AA1 L AH0 F S AH0 N'],
  "olofson's": ['AA1 L AH0 F S AH0 N Z'],
  "olokuei": ['OW2 L OW0 K UW1 IY0'],
  "olokuei's": ['OW2 L OW0 K UW1 IY0 Z'],
  "oloughlin": ['OW0 L AW1 K L IH0 N'],
  "olovo": ['AH0 L AO1 V OW0'],
  "olsdat": ['OW1 L Z D AE0 T'],
  "olsen": ['OW1 L S AH0 N'],
  "olsen's": ['OW1 L S AH0 N Z'],
  "olshan": ['OW1 L SH AH0 N'],
  "olshansky": ['OW1 L SH AH0 N S K IY0'],
  "olshansky's": ['OW1 L SH AH0 N S K IY0 Z'],
  "olshefski": ['OW0 L SH EH1 F S K IY0'],
  "olshefski's": ['OW0 L SH EH1 F S K IY0 Z'],
  "olsher": ['OW1 L SH ER0'],
  "olson": ['OW1 L S AH0 N'],
  "olson's": ['OW1 L S AH0 N Z'],
  "olsson": ['OW1 L S AH0 N'],
  "olstad": ['OW1 L S T AH0 D'],
  "olsten": ['OW1 L S T AH0 N'],
  "olszewski": ['OW0 L SH EH1 F S K IY0'],
  "olt": ['OW1 L T'],
  "olthoff": ['OW1 L T HH AO2 F'],
  "oltman": ['OW1 L T M AH0 N'],
  "oltmann": ['OW1 L T M AH0 N'],
  "oltmanns": ['OW1 L T M AH0 N Z'],
  "olund": ['AA1 L AH0 N D'],
  "olva": ['OW1 L V AH0'],
  "olver": ['OW1 L V ER0'],
  "olvera": ['OW0 L V EH1 R AH0'],
  "olvey": ['OW1 L V IY0'],
  "olympia": ['OW0 L IH1 M P IY0 AH0'],
  "olympia's": ['OW0 L IH1 M P IY0 AH0 Z'],
  "olympiad": ['OW0 L IH1 M P IY0 AE2 D'],
  "olympiads": ['OW0 L IH1 M P IY0 AE2 D'],
  "olympian": ['OW0 L IH1 M P IY0 AH0 N'],
  "olympians": ['OW0 L IH1 M P IY0 AH0 N Z'],
  "olympias": ['OW0 L IH1 M P IY0 AH0 S'],
  "olympic": ['OW0 L IH1 M P IH0 K'],
  "olympic's": ['OW0 L IH1 M P IH0 K S'],
  "olympics": ['OW0 L IH1 M P IH0 K S'],
  "olympics'": ['OW0 L IH1 M P IH0 K S'],
  "olympus": ['OW0 L IH1 M P AH0 S'],
  "olympus'": ['OW0 L IH1 M P AH0 S'],
  "om": ['AO1 M'],
  "oma": ['OW1 M AH0'],
  "omaar": ['OW1 M AA0 R'],
  "omaha": ['OW1 M AH0 HH AA2'],
  "omaha's": ['OW1 M AH0 HH AA2 Z'],
  "omahoney": ['OW0 M AH0 HH OW1 N IY0'],
  "omahony": ['OW0 M AH0 HH OW1 N IY0'],
  "omalley": ['OW0 M AE1 L IY0'],
  "oman": ['OW0 M AA1 N'],
  "omani": ['OW0 M AA1 N IY0'],
  "omans": ['OW0 M AA1 N Z'],
  "omar": ['OW1 M AA0 R'],
  "omara": ['OW0 M AA1 R AH0'],
  "omarska": ['OW0 M AA1 R S K AH0'],
  "omary": ['AH0 M EH1 R IY0'],
  "ombudsman": ['AA1 M B AH0 D Z M AH0 N'],
  "omdahl": ['AA1 M D AA2 L'],
  "omead": ['OW0 M IY1 D'],
  "omeara": ['OW0 M IY1 R AH0', 'OW2 M AA1 R AH0'],
  "omega": ['OW0 M EH1 G AH0', 'OW0 M EY1 G AH0'],
  "omelet": ['AA1 M L AH0 T'],
  "omelets": ['AA1 M L AH0 T S'],
  "omelia": ['OW0 M EH1 L IY0 AH0'],
  "omen": ['OW1 M AH0 N'],
  "omens": ['OW1 M AH0 N Z'],
  "omer": ['OW1 M ER0'],
  "ominous": ['AA1 M AH0 N AH0 S'],
  "ominously": ['AA1 M AH0 N AH0 S L IY0'],
  "omission": ['OW0 M IH1 SH AH0 N'],
  "omissions": ['OW0 M IH1 SH AH0 N Z'],
  "omit": ['OW0 M IH1 T'],
  "omits": ['OW0 M IH1 T S'],
  "omitted": ['OW0 M IH1 T AH0 D', 'OW0 M IH1 T IH0 D'],
  "omitting": ['OW0 M IH1 T IH0 NG'],
  "omlor": ['AA1 M L ER0'],
  "ommen": ['AA1 M AH0 N', 'OW1 M AH0 N'],
  "omni": ['AA1 M N IY0'],
  "omnibank": ['AA1 M N IH0 B AE2 NG K'],
  "omnibook": ['AA1 M N IH0 B UH2 K'],
  "omnibus": ['AA1 M N AH0 B AH0 S'],
  "omnibuses": ['AA1 M N AH0 B AH0 S AH0 Z'],
  "omnicare": ['AA1 M N IH0 K EH2 R'],
  "omnicom": ['AA1 M N IH0 K AA0 M'],
  "omnicom's": ['AA1 M N IH0 K AA0 M Z'],
  "omnipotence": ['AA0 M N IH1 P AH0 T AH0 N S'],
  "omnipotent": ['AA0 M N IH1 P AH0 T AH0 N T'],
  "omnipresence": ['AA2 M N AH0 P R EH1 Z AH0 N S'],
  "omnipresent": ['AA2 M N IH0 P R EH1 Z AH0 N T'],
  "omniscient": ['AA0 M N IH1 SH AH0 N T'],
  "omnivorous": ['AA0 M N IH1 V ER0 AH0 S'],
  "omohundro": ['OW0 M OW0 HH UW1 N D R OW0'],
  "omonia": ['AH0 M OW1 N IY0 AH0', 'OW1 M OW0 N Y AH0'],
  "omori": ['OW0 M AO1 R IY0'],
  "omron": ['AA1 M R AH0 N'],
  "on": ['AA1 N', 'AO1 N'],
  "on-line": ['AO1 N L AY2 N'],
  "on-screen": ['AA1 N S K R IY2 N'],
  "ona": ['AH0 N AA1'],
  "onagers": ['AA1 N AH0 JH ER0 Z'],
  "onan": ['OW1 N AH0 N'],
  "onassis": ['OW0 N AE1 S IH0 S', 'OW0 N AA1 S IH0 S'],
  "onassis'": ['OW0 N AE1 S IH0 S', 'OW0 N AA1 S IH0 S'],
  "onassis's": ['OW0 N AE1 S IH0 S IH0 S', 'OW0 N AA1 S IH0 S IH0 S'],
  "onate": ['OW1 N EY0 T'],
  "onawa": ['OW0 N AA1 W AH0'],
  "onboard": ['AA1 N B AO2 R D'],
  "oncale": ['OW0 N K AA1 L IY0'],
  "once": ['W AH1 N S'],
  "oncogen": ['AA1 NG K OW0 JH AH0 N'],
  "oncogene": ['AA1 NG K OW0 JH IY2 N'],
  "oncogenes": ['AA0 NG K AA1 JH EH2 N IY0 S'],
  "oncologist": ['AA0 NG K AA1 L AH0 JH IH0 S T'],
  "oncologists": ['AA0 NG K AA1 L AH0 JH IH0 S T S'],
  "oncology": ['AA0 NG K AA1 L AH0 JH IY0'],
  "oncoming": ['AO1 N K AH2 M IH0 NG'],
  "oncor": ['AA1 N K AO2 R'],
  "ondaatje": ['AA0 N D AA1 T Y AH0'],
  "onder": ['AA1 N D ER0'],
  "onderdonk": ['AA1 N D ER0 D AH0 NG K'],
  "ondo": ['AO1 N D OW0'],
  "ondracek": ['AA1 N D R AH0 S EH0 K'],
  "one": ['W AH1 N'],
  "one's": ['W AH1 N Z'],
  "one-eyed": ['W AH2 N AY1 D'],
  "one-legged": ['W AH2 N L EH1 G AH0 D'],
  "one-man": ['W AH1 N M AE1 N'],
  "one-night": ['W AH1 N N AY2 T'],
  "one-off": ['W AH2 N AO1 F'],
  "one-on-one": ['W AH1 N AH0 N AA1 N'],
  "one-sided": ['W AH2 N S AY1 D AH0 D'],
  "one-third": ['W AH2 N TH ER1 D'],
  "one-time": ['W AH1 N T AY1 M'],
  "one-up-manship": ['W AH2 N AH1 P M AE0 N SH IH2 P'],
  "one-upmanship": ['W AH2 N AH1 P M AH0 N SH IH2 P'],
  "one-way": ['W AH1 N W EY1'],
  "one-year": ['W AH1 N Y IH2 R'],
  "oneal": ['OW0 N IY1 L'],
  "oneall": ['AA1 N AH0 L'],
  "onecomm": ['W AH1 N K AA2 M'],
  "oneida": ['OW0 N AY1 D AH0'],
  "oneil": ['OW0 N IY1 L'],
  "oneill": ['OW0 N IY1 L'],
  "oneness": ['W AH1 N N AH0 S', 'W AH1 N AH0 S'],
  "oneok": ['OW1 N IY0 AA0 K'],
  "onerous": ['OW1 N ER0 AH0 S'],
  "ones": ['W AH1 N Z'],
  "ones'": ['W AH1 N Z'],
  "oneself": ['W AH2 N S EH1 L F'],
  "onetime": ['W AH1 N T AY1 M'],
  "onex": ['W AH1 N EH1 K S'],
  "onex's": ['W AH1 N EH1 K S IH0 Z'],
  "oney": ['OW1 N IY0'],
  "oneyear": ['W AH1 N Y IH1 R'],
  "ong": ['AO1 NG'],
  "ongoing": ['AA1 N G OW2 IH0 NG', 'AO1 N G OW2 IH0 NG'],
  "ongpin": ['AO1 NG P IH0 N'],
  "onion": ['AH1 N Y AH0 N'],
  "onions": ['AH1 N Y AH0 N Z'],
  "onishi": ['OW0 N IY1 SH IY0'],
  "onken": ['AA1 NG K AH0 N'],
  "onley": ['AA1 N L IY0'],
  "online": ['AO1 N L AY2 N'],
  "online's": ['AO1 N L AY2 N Z'],
  "onlooker": ['AO1 N L UH2 K ER0'],
  "onlookers": ['AO1 N L UH2 K ER0 Z'],
  "only": ['OW1 N L IY0'],
  "onnen": ['AA1 N AH0 N'],
  "onni": ['AA1 N IY0'],
  "onno": ['AA1 N OW0'],
  "ono": ['OW1 N OW0'],
  "onoda": ['OW0 N OW1 D AH0'],
  "onofre": ['OW0 N AO1 F R IY0'],
  "onofrio": ['OW0 N OW1 F R IY0 OW0'],
  "onomastic": ['AA2 N AH0 M AE1 S T IH0 K'],
  "onomastics": ['AA2 N AH0 M AE1 S T IH0 K S'],
  "onomatopeia": ['AA2 N AH0 M AE0 T AH0 P IY1 AH0'],
  "onondaga": ['AA2 N AH0 N D AO1 G AH0'],
  "onorato": ['OW0 N AO0 R AA1 T OW0'],
  "onrushing": ['AA1 N R AH2 SH IH0 NG'],
  "ons": ['AA1 N Z'],
  "onscreen": ['AA2 N S K R IY1 N'],
  "onset": ['AA1 N S EH2 T', 'AO1 N S EH2 T'],
  "onshore": ['AA1 N SH AO2 R'],
  "onsite": ['AA1 N S AY1 T'],
  "onslaught": ['AO1 N S L AO2 T'],
  "onslow": ['AA1 N S L OW0'],
  "onstad": ['AA1 N S T AH0 D'],
  "onstage": ['AA2 N S T EY1 JH'],
  "onstott": ['AA1 N S T AH0 T'],
  "ontario": ['AA0 N T EH1 R IY0 OW0'],
  "ontario's": ['AA0 N T EH1 R IY0 OW0 Z'],
  "ontiveros": ['OW0 N T IY0 V EH1 R OW0 Z'],
  "ontko": ['AA1 N T K OW0'],
  "onto": ['AA1 N T UW0', 'AO1 N T UW0'],
  "ontogeny": ['AA0 N T AA1 JH AH0 N IY0'],
  "ontological": ['AA2 N T AH0 L AA1 JH IH0 K AH0 L'],
  "ontology": ['AA0 N T AA1 L AH0 JH IY0'],
  "onus": ['OW1 N AH0 S'],
  "onward": ['AO1 N W ER0 D'],
  "onwards": ['AA1 N W ER0 D Z'],
  "onyx": ['AA1 N IH0 K S'],
  "oodle": ['UW1 D AH0 L'],
  "oodles": ['UW1 D AH0 L Z'],
  "ooh": ['UW1'],
  "oohs": ['UW1 Z'],
  "ooley": ['UW1 L IY0'],
  "oolong": ['UW1 L AO0 NG'],
  "oomph": ['UW1 M F'],
  "oona": ['UW1 N AH0'],
  "oooh": ['UW1'],
  "ooohs": ['UW1 Z', 'OW1 Z'],
  "oop": ['UW1 P'],
  "ooph": ['UW1 F'],
  "oops": ['UW1 P S'],
  "ooten": ['UW1 T AH0 N'],
  "ooze": ['UW1 Z'],
  "oozed": ['UW1 Z D'],
  "oozes": ['UW1 Z IH0 Z'],
  "oozing": ['UW1 Z IH0 NG'],
  "op": ['AA1 P', 'AO1 P'],
  "op's": ['AA1 P S'],
  "opacity": ['OW0 P AE1 S AH0 T IY0'],
  "opal": ['OW1 P AH0 L'],
  "opal's": ['OW1 P AH0 L Z'],
  "opalina": ['OW0 P AA0 L IY1 N AH0'],
  "opaline": ['OW1 P AH0 L IY2 N'],
  "opalines": ['OW1 P AH0 L IY2 N Z'],
  "opalka": ['AH0 P AA1 L K AH0'],
  "opaque": ['OW0 P EY1 K'],
  "opax": ['OW1 P AE2 K S'],
  "opcom": ['AA1 P K AA0 M'],
  "opdahl": ['AA1 P D AA2 L'],
  "opdyke": ['AA1 P D AY2 K'],
  "opec": ['OW1 P EH2 K'],
  "opec's": ['OW1 P EH2 K S'],
  "opel": ['OW1 P AH0 L'],
  "opel's": ['OW1 P AH0 L Z'],
  "open": ['OW1 P AH0 N'],
  "open-ended": ['OW1 P AH0 N EH2 N D AH0 D'],
  "open-source": ['OW1 P AH0 N S AO2 R S'],
  "opened": ['OW1 P AH0 N D'],
  "opener": ['OW1 P AH0 N ER0'],
  "openers": ['OW1 P AH0 N ER0 Z'],
  "opening": ['OW1 P AH0 N IH0 NG'],
  "openings": ['OW1 P AH0 N IH0 NG Z'],
  "openly": ['OW1 P AH0 N L IY0'],
  "openness": ['OW1 P AH0 N N AH0 S', 'OW1 P AH0 N AH0 S'],
  "opens": ['OW1 P AH0 N Z'],
  "openshaw": ['OW1 P AH0 N SH AO2'],
  "openwork": ['OW1 P AH0 N W ER2 K'],
  "opera": ['AA1 P R AH0'],
  "opera's": ['AA1 P R AH0 Z'],
  "operable": ['AA1 P ER0 AH0 B AH0 L'],
  "operan": ['AA1 P ER0 AH0 N'],
  "operandi": ['AA2 P ER0 AE1 N D IY0', 'AA2 P ER0 AE1 N D AY0', 'AA2 P ER0 EH1 N D AY0'],
  "operant": ['AA1 P ER0 AH0 N T'],
  "operas": ['AA1 P R AH0 Z'],
  "operate": ['AA1 P ER0 EY2 T', 'AO1 P ER0 EY2 T'],
  "operated": ['AA1 P ER0 EY2 T AH0 D'],
  "operates": ['AA1 P ER0 EY2 T S'],
  "operatic": ['AA2 P ER0 AE1 T IH0 K'],
  "operating": ['AA1 P ER0 EY2 T IH0 NG', 'AO1 P ER0 EY2 T IH0 NG'],
  "operation": ['AA2 P ER0 EY1 SH AH0 N'],
  "operation's": ['AA2 P ER0 EY1 SH AH0 N Z'],
  "operational": ['AA2 P ER0 EY1 SH AH0 N AH0 L'],
  "operationally": ['AA1 P ER0 EY1 SH AH0 N AH0 L IY0', 'AA1 P ER0 EY1 SH N AH0 L IY0'],
  "operations": ['AA2 P ER0 EY1 SH AH0 N Z'],
  "operations'": ['AA2 P ER0 EY1 SH AH0 N Z'],
  "operative": ['AA1 P ER0 AH0 T IH0 V'],
  "operatives": ['AA1 P ER0 AH0 T IH0 V Z'],
  "operator": ['AA1 P ER0 EY2 T ER0'],
  "operator's": ['AA1 P ER0 EY2 T ER0 Z'],
  "operators": ['AA1 P ER0 EY2 T ER0 Z', 'AO1 P ER0 EY2 T ER0 Z'],
  "operators'": ['AO1 P ER0 EY2 T ER0 Z'],
  "opere": ['OW0 P EH1 R'],
  "operetta": ['AA2 P ER0 EH1 T AH0'],
  "operettas": ['AA2 P ER0 EH1 T AH0 Z'],
  "opfer": ['AA1 P F ER0'],
  "opheim": ['AA1 P HH AY2 M'],
  "ophelia": ['AH0 F IY1 L Y AH0'],
  "ophthalmic": ['AA0 F TH AE1 L M IH0 K'],
  "ophthalmologist": ['AA2 P TH AH0 M AA1 L AH0 JH IH0 S T', 'AA2 F TH AH0 M AA1 L AH0 JH IH0 S T'],
  "ophthalmologists": ['AA2 P TH AH0 M AA1 L AH0 JH IH0 S T S', 'AA2 P TH AH0 M AA1 L AH0 JH IH0 S', 'AA2 F TH AH0 M AA1 L AH0 JH IH0 S T S', 'AA2 F TH AH0 M AA1 L AH0 JH IH0 S'],
  "ophthalmology": ['AA2 P TH AH0 M AA1 L AH0 JH IY0', 'AA2 F TH AH0 M AA1 L AH0 JH IY0'],
  "ophthalmoscope": ['AA0 F TH AE1 L M AH0 S K OW2 P'],
  "ophuls": ['OW1 F AH0 L Z'],
  "opiate": ['OW1 P IY2 AH0 T'],
  "opiates": ['OW1 P IY2 AH0 T S'],
  "opic": ['AA1 P IH0 K'],
  "opic's": ['AA1 P IH0 K S'],
  "opie": ['OW1 P IY2'],
  "opiela": ['OW2 P IY1 L AH0'],
  "opine": ['OW0 P AY1 N'],
  "opined": ['OW0 P AY1 N D'],
  "opines": ['OW0 P AY1 N Z'],
  "opining": ['OW0 P AY1 N IH0 NG'],
  "opinion": ['AH0 P IH1 N Y AH0 N'],
  "opinionate": ['AH0 P IH1 N Y AH0 N EY2 T'],
  "opinionated": ['AH0 P IH1 N Y AH0 N EY2 T IH0 D'],
  "opinions": ['AH0 P IH1 N Y AH0 N Z'],
  "opitz": ['AA1 P IH0 T S'],
  "opium": ['OW1 P IY0 AH0 M'],
  "opland": ['AA1 P L AH0 N D'],
  "ople": ['OW1 P AH0 L'],
  "oplinger": ['OW1 P AH0 L IH0 NG ER0', 'OW1 P L IH0 NG ER0'],
  "opossum": ['OW0 P AA1 S AH0 M'],
  "opp": ['AA1 P'],
  "oppedisano": ['OW0 P EH0 D IY0 S AA1 N OW0'],
  "oppel": ['AA1 P AH0 L'],
  "oppelt": ['AA1 P IH0 L T'],
  "oppenheim": ['AA1 P IH0 N HH AY2 M'],
  "oppenheimer": ['AA1 P AH0 N HH AY2 M ER0'],
  "oppenheimer's": ['AA1 P AH0 N HH AY2 M ER0 Z'],
  "oppenheimers": ['AA1 P AH0 N HH AY2 M ER0 Z'],
  "oppens": ['AA1 P AH0 N Z'],
  "opper": ['AA1 P ER0'],
  "opperman": ['AA1 P ER0 M AH0 N'],
  "oppermann": ['AA1 P ER0 M AH0 N'],
  "oppler": ['AO1 P L ER0'],
  "oppliger": ['AA1 P L IH0 G ER0'],
  "opponent": ['AH0 P OW1 N AH0 N T'],
  "opponent's": ['AH0 P OW1 N AH0 N T S'],
  "opponents": ['AH0 P OW1 N AH0 N T S'],
  "opponents'": ['AH0 P OW1 N AH0 N T S'],
  "opportune": ['AA2 P ER0 T UW1 N'],
  "opportunism": ['AA2 P ER0 T UW1 N IH2 Z AH0 M'],
  "opportunist": ['AA2 P ER0 T UW1 N IH0 S T'],
  "opportunistic": ['AA2 P ER0 T UW2 N IH1 S T IH0 K'],
  "opportunists": ['AA2 P ER0 T UW1 N IH0 S T S'],
  "opportunities": ['AA2 P ER0 T UW1 N AH0 T IY0 Z'],
  "opportunity": ['AA2 P ER0 T UW1 N AH0 T IY0'],
  "oppose": ['AH0 P OW1 Z'],
  "opposed": ['AH0 P OW1 Z D'],
  "opposes": ['AH0 P OW1 Z IH0 Z'],
  "opposing": ['AH0 P OW1 Z IH0 NG'],
  "opposite": ['AA1 P AH0 Z AH0 T', 'AA1 P Z AH0 T'],
  "opposites": ['AA1 P AH0 Z AH0 T S'],
  "opposition": ['AA2 P AH0 Z IH1 SH AH0 N'],
  "opposition's": ['AA2 P AH0 Z IH1 SH AH0 N Z'],
  "oppositions": ['AA2 P AH0 Z IH1 SH AH0 N Z'],
  "oppress": ['AH0 P R EH1 S'],
  "oppressed": ['AH0 P R EH1 S T'],
  "oppresses": ['AH0 P R EH1 S IH0 Z'],
  "oppressing": ['AH0 P R EH1 S IH0 NG'],
  "oppression": ['AH0 P R EH1 SH AH0 N'],
  "oppressions": ['AH0 P R EH1 SH AH0 N Z'],
  "oppressive": ['AH0 P R EH1 S IH0 V'],
  "oppressor": ['AH0 P R EH1 S ER0'],
  "oppressors": ['AH0 P R EH1 S ER0 Z'],
  "opprobrium": ['AH0 P R OW1 B R IY0 AH0 M'],
  "oprah": ['OW1 P R AH0'],
  "oprah's": ['OW1 P R AH0 Z'],
  "opry": ['AA1 P R IY0'],
  "opryland": ['AA1 P R IY0 L AH0 N D'],
  "ops": ['AA1 P S'],
  "opsahl": ['AA1 P S AA0 L'],
  "opsal": ['AA1 P S AH0 L'],
  "opt": ['AA1 P T'],
  "optation": ['AA1 P T EY1 SH AH0 N'],
  "opted": ['AA1 P T IH0 D'],
  "optek": ['AA1 P T EH2 K'],
  "opthalmologist": ['AA2 F TH AH0 L M AA1 L AH0 JH IH0 S T'],
  "opthalmology": ['AA2 F TH AH0 L M AO1 L AH0 JH IY0'],
  "optic": ['AA1 P T IH0 K'],
  "optica": ['AA1 P T IH0 K AH0'],
  "optical": ['AA1 P T IH0 K AH0 L'],
  "optical's": ['AA1 P T IH0 K AH0 L Z'],
  "optically": ['AA1 P T IH0 K L IY0'],
  "optician": ['AA0 P T IH1 SH AH0 N'],
  "opticians": ['AA0 P T IH1 SH AH0 N Z'],
  "optics": ['AA1 P T IH0 K S'],
  "optima": ['AA1 P T AH0 M AH0'],
  "optimal": ['AA1 P T AH0 M AH0 L'],
  "optimism": ['AA1 P T AH0 M IH2 Z AH0 M'],
  "optimist": ['AA1 P T AH0 M IH0 S T'],
  "optimistic": ['AA2 P T AH0 M IH1 S T IH0 K'],
  "optimistically": ['AA2 P T IH0 M IH1 S T IH0 K AH0 L IY0', 'AA2 P T IH0 M IH1 S T IH0 K L IY0'],
  "optimists": ['AA1 P T AH0 M IH0 S T S'],
  "optimization": ['AA0 P T AH0 M AH0 Z EY1 SH AH0 N'],
  "optimize": ['AA1 P T AH0 M AY2 Z'],
  "optimized": ['AA1 P T AH0 M AY2 Z D'],
  "optimizing": ['AA1 P T AH0 M AY2 Z IH0 NG'],
  "optimum": ['AA1 P T AH0 M AH0 M'],
  "optimus": ['AA1 P T IH0 M AH0 S'],
  "opting": ['AA1 P T IH0 NG'],
  "option": ['AA1 P SH AH0 N', 'AO1 P SH AH0 N'],
  "option's": ['AA1 P SH AH0 N Z'],
  "optional": ['AA1 P SH AH0 N AH0 L', 'AO1 P SH AH0 N AH0 L'],
  "optioned": ['AA1 P SH AH0 N D', 'AO1 P SH AH0 N D'],
  "optioning": ['AA1 P SH AH0 N IH0 NG', 'AO1 P SH AH0 N IH0 NG'],
  "options": ['AA1 P SH AH0 N Z', 'AO1 P SH AH0 N Z'],
  "options'": ['AA1 P SH AH0 N Z'],
  "opto": ['AA1 P T OW0'],
  "optometric": ['AA2 P T OW0 M EH1 T R IH0 K'],
  "optometrist": ['AA0 P T AA1 M AH0 T R IH0 S T'],
  "optometrists": ['AA0 P T AA1 M AH0 T R IH0 S T S'],
  "optometry": ['AA0 P T AA1 M AH0 T R IY0'],
  "opts": ['AA1 P T S'],
  "optus": ['AA1 P T AH0 S'],
  "opulence": ['AA1 P Y AH0 L AH0 N S'],
  "opulent": ['AA1 P Y AH0 L AH0 N T'],
  "opus": ['OW1 P AH0 S'],
  "oquendo": ['OW0 K W EH1 N D OW0'],
  "oquin": ['OW0 K W IY1 N'],
  "oquinn": ['OW0 K W IY1 N'],
  "or": ['AO1 R', 'ER0'],
  "ora": ['AO1 R AH0'],
  "orabel": ['AO0 R AA0 B EH1 L'],
  "orabelle": ['AO1 R AH0 B AH0 L'],
  "oracle": ['AO1 R AH0 K AH0 L'],
  "oracle's": ['AO1 R AH0 K AH0 L Z'],
  "oracles": ['AO1 R AH0 K AH0 L Z'],
  "oradour": ['AO1 R AH0 D AO2 R'],
  "oraflex": ['AO1 R AH0 F L EH2 K S'],
  "orahood": ['AO1 R AH0 HH UH2 D'],
  "oral": ['AO1 R AH0 L'],
  "oralia": ['AO0 R AA1 L IY0 AH0'],
  "oralie": ['AO1 R AH0 L IY0'],
  "orally": ['AO1 R AH0 L IY0'],
  "oram": ['AO1 R AH0 M'],
  "oran": ['AO0 R AA1 N'],
  "orand": ['AO1 R AH0 N D'],
  "orange": ['AO1 R AH0 N JH', 'AO1 R IH0 N JH'],
  "orange-green": ['AO1 R AH0 N JH G R IY2 N'],
  "orangeburg": ['AO1 R AH0 N JH B ER0 G'],
  "oranges": ['AO1 R AH0 N JH AH0 Z', 'AO1 R IH0 N JH IH0 Z'],
  "orangina": ['AO0 R AE0 N JH IY1 N ER0', 'AO0 R AE0 N JH IY1 N AH0'],
  "orangutan": ['AO0 R AE1 NG AH0 T AE0 N', 'AO0 R AE1 NG AH0 T AA0 N'],
  "orangutan's": ['AO0 R AE1 NG AH0 T AE0 N Z', 'AO0 R AE1 NG AH0 T AA0 N Z'],
  "orangutans": ['AO0 R AE1 NG AH0 T AE0 N Z', 'AO0 R AE1 NG AH0 T AA0 N Z'],
  "orasure": ['AO1 R AH2 SH UH2 R'],
  "oration": ['AO0 R EY1 SH AH0 N'],
  "orations": ['AO0 R EY1 SH AH0 N Z'],
  "orator": ['AO1 R AH0 T ER0'],
  "oratorical": ['AO2 R AH0 T AO1 R AH0 K AH0 L'],
  "oratorio": ['AA2 R AH0 T AO1 R IY0 OW0'],
  "orators": ['AO1 R AH0 T ER0 Z'],
  "oratory": ['AO1 R AH0 T AO2 R IY0'],
  "oravec": ['AO0 R AA1 V IH0 K'],
  "oravetz": ['AO1 R AH0 V IH0 T S'],
  "orb": ['AO1 R B'],
  "orbach": ['AO1 R B AA0 K'],
  "orban": ['AO1 R B AH0 N'],
  "orbanco": ['AO0 R B AE1 NG K OW0'],
  "orben": ['AO1 R B AH0 N'],
  "orbin": ['AO1 R B IH0 N'],
  "orbis": ['AO1 R B IH0 S'],
  "orbit": ['AO1 R B AH0 T'],
  "orbital": ['AO1 R B AH0 T AH0 L'],
  "orbitals": ['AO1 R B AH0 T AH0 L S'],
  "orbited": ['AO1 R B AH0 T AH0 D'],
  "orbiter": ['AO1 R B AH0 T ER0'],
  "orbiters": ['AO1 R B AH0 T ER0 Z'],
  "orbiting": ['AO1 R B AH0 T IH0 NG'],
  "orbits": ['AO1 R B AH0 T S'],
  "orca": ['AO1 R K AH0'],
  "orcas": ['AO1 R K AH0 S'],
  "orchard": ['AO1 R CH ER0 D'],
  "orchards": ['AO1 R CH ER0 D Z'],
  "orchestra": ['AO1 R K AH0 S T R AH0'],
  "orchestra's": ['AO1 R K AH0 S T R AH0 Z'],
  "orchestral": ['AO0 R K EH1 S T R AH0 L'],
  "orchestrally": ['AO0 R K EH1 S T R AH0 L IY0'],
  "orchestras": ['AO1 R K AH0 S T R AH0 Z'],
  "orchestrate": ['AO1 R K IH0 S T R EY2 T'],
  "orchestrated": ['AO1 R K IH0 S T R EY2 T IH0 D'],
  "orchestrates": ['AO1 R K AH0 S T R EY2 T S'],
  "orchestrating": ['AO1 R K IH0 S T R EY2 T IH0 NG'],
  "orchestration": ['AO2 R K AH0 S T R EY1 SH AH0 N'],
  "orchestrations": ['AO2 R K AH0 S T R EY1 SH AH0 N Z'],
  "orchid": ['AO1 R K AH0 D'],
  "orchids": ['AO1 R K AH0 D Z'],
  "orct": ['AO1 R K T'],
  "orcutt": ['AO1 R K AH0 T'],
  "ord": ['AO1 R D'],
  "ordain": ['AO0 R D EY1 N'],
  "ordained": ['AO0 R D EY1 N D'],
  "ordaining": ['AO0 R D EY1 N IH0 NG'],
  "ordaz": ['AO1 R D AA0 Z'],
  "ordeal": ['AO0 R D IY1 L'],
  "ordeals": ['AO0 R D IY1 L Z'],
  "ordella": ['AO2 R D EH1 L AH0'],
  "order": ['AO1 R D ER0'],
  "order's": ['AO1 R D ER0 Z'],
  "ordered": ['AO1 R D ER0 D'],
  "ordering": ['AO1 R D ER0 IH0 NG'],
  "orderliness": ['AO1 R D ER0 L IY0 N AH0 S'],
  "orderly": ['AO1 R D ER0 L IY0'],
  "orders": ['AO1 R D ER0 Z'],
  "ordinance": ['AO1 R D AH0 N AH0 N S'],
  "ordinances": ['AO1 R D AH0 N AH0 N S IH0 Z'],
  "ordinaries": ['AO1 R D AH0 N EH2 R IY0 Z'],
  "ordinarily": ['AO2 R D AH0 N EH1 R AH0 L IY0'],
  "ordinary": ['AO1 R D AH0 N EH2 R IY0'],
  "ordination": ['AO2 R D AH0 N EY1 SH AH0 N'],
  "ordiway": ['AO1 R D IY0 W EY0'],
  "ordnance": ['AO1 R D N AH0 N S'],
  "ordnance's": ['AO1 R D N AH0 N S IH0 Z'],
  "ordonez": ['AO0 R D OW1 N EH0 Z'],
  "ordoyne": ['AO1 R D OY0 N'],
  "ordway": ['AO1 R D W EY0'],
  "ore": ['AO1 R'],
  "orea": ['ER0 IY1 AH0'],
  "orear": ['ER0 IH1 R'],
  "orebaugh": ['AO0 R EH1 B AO0'],
  "oreffice": ['AO0 R EH1 F IH0 S'],
  "orefice": ['AO0 R EH1 F AY0 S'],
  "oregan": ['AO0 R EH1 G AH0 N'],
  "oregano": ['AO2 R EH1 G AH0 N OW2', 'ER0 EH1 G AH0 N OW2'],
  "oregon": ['AO1 R AH0 G AH0 N', 'AO1 R AH0 G AA2 N'],
  "oregon's": ['AO1 R AH0 G AH0 N Z', 'AO1 R AH0 G AA2 N Z'],
  "oregonian": ['AO2 R AH0 G OW1 N IY0 AH0 N'],
  "oregonians": ['AO2 R AH0 G OW1 N IY0 AH0 N Z'],
  "oreilly": ['AO1 R AH0 L IY0'],
  "orel": ['AO1 R AH0 L'],
  "orela": ['AO0 R EH1 L AH0'],
  "orelia": ['AO0 R EH1 L IY0 AH0'],
  "orellana": ['AO0 R EH0 L AE1 N AH0'],
  "orelon": ['AO1 R AH0 L AA0 N'],
  "orem": ['AO1 R IH0 M'],
  "oren": ['AO1 R AH0 N'],
  "orenda": ['AO0 R EH1 N D AH0'],
  "orendorff": ['AO1 R IH0 N D AO0 R F'],
  "orens": ['AO1 R AH0 N Z'],
  "orenstein": ['AO1 R AH0 N S T AY0 N', 'AO1 R AH0 N S T IY0 N'],
  "orenthal": ['AO1 R AH0 N TH AA2 L'],
  "oreo": ['AO1 R IY0 OW0'],
  "ores": ['AO1 R Z'],
  "oreskovich": ['AO0 R EH1 S K AH0 V IH0 CH'],
  "orestes": ['AO0 R EH1 S T IY0 Z'],
  "orf": ['AO1 R F'],
  "orfield": ['AO1 R F IY0 L D'],
  "orford": ['AO1 R F ER0 D'],
  "org": ['AO1 R G'],
  "organ": ['AO1 R G AH0 N'],
  "organelles": ['AO2 R G AH0 N EH1 L Z'],
  "organic": ['AO0 R G AE1 N IH0 K'],
  "organically": ['AO0 R G AE1 N IH0 K L IY0'],
  "organics": ['AO0 R G AE1 N IH0 K S'],
  "organisation": ['AO2 R G AH0 N IH0 Z EY1 SH AH0 N'],
  "organisations": ['AO2 R G AH0 N IH0 Z EY1 SH AH0 N Z'],
  "organism": ['AO1 R G AH0 N IH2 Z AH0 M'],
  "organism's": ['AO1 R G AH0 N IH2 Z AH0 M Z'],
  "organismic": ['AO2 R G AH0 N IH1 Z M IH0 K'],
  "organisms": ['AO1 R G AH0 N IH2 Z AH0 M Z'],
  "organist": ['AO1 R G AH0 N AH0 S T'],
  "organization": ['AO2 R G AH0 N AH0 Z EY1 SH AH0 N'],
  "organization's": ['AO2 R G AH0 N IH0 Z EY1 SH AH0 N Z'],
  "organizational": ['AO2 R G AH0 N AH0 Z EY1 SH AH0 N AH0 L'],
  "organizations": ['AO2 R G AH0 N AH0 Z EY1 SH AH0 N Z'],
  "organize": ['AO1 R G AH0 N AY2 Z'],
  "organized": ['AO1 R G AH0 N AY2 Z D'],
  "organizer": ['AO1 R G AH0 N AY2 Z ER0'],
  "organizers": ['AO1 R G AH0 N AY2 Z ER0 Z'],
  "organizes": ['AO1 R G AH0 N AY2 Z IH0 Z'],
  "organizing": ['AO1 R G AH0 N AY2 Z IH0 NG'],
  "organogenesis": ['AO2 R G AH0 N OW0 JH EH1 N AH0 S IH0 S'],
  "organs": ['AO1 R G AH0 N Z'],
  "organum": ['AO1 R G AH0 N AH0 M'],
  "orgasm": ['AO1 R G AE0 Z AH0 M'],
  "orgasms": ['AO1 R G AE0 Z AH0 M Z'],
  "orgel": ['AO1 R G AH0 L'],
  "orgeron": ['AO1 R G ER0 AH0 N'],
  "orgies": ['AO1 R JH IY0 Z'],
  "orgill": ['AO1 R JH AH0 L'],
  "orgotein": ['AO1 R G OW0 T AY2 N'],
  "orgy": ['AO1 R JH IY0'],
  "ori": ['AO1 R IY0'],
  "oriana": ['AO0 R IY0 AE1 N AH0'],
  "orick": ['AO1 R IH0 K'],
  "orielda": ['AO0 R IY1 L D AH0'],
  "orielle": ['AO1 R IY0 EH0 L'],
  "orient": ['AO1 R IY0 EH2 N T'],
  "oriental": ['AO2 R IY0 EH1 N T AH0 L', 'AO2 R IY0 EH1 N AH0 L'],
  "orientals": ['AO2 R IY0 EH1 N T AH0 L Z', 'AO2 R IY0 EH1 N AH0 L Z'],
  "orientated": ['AO1 R IY0 AH0 N T EY2 T IH0 D'],
  "orientation": ['AO2 R IY0 EH0 N T EY1 SH AH0 N'],
  "orientations": ['AO2 R IY0 EH0 N T EY1 SH AH0 N Z'],
  "oriented": ['AO1 R IY0 EH2 N T AH0 D', 'AO1 R IY0 EH2 N T IH0 D'],
  "orifice": ['AO1 R AH0 F AH0 S'],
  "orifices": ['AO1 R AH0 F AH0 S AH0 Z'],
  "origami": ['AO2 R IY0 G AA1 M IY2'],
  "origin": ['AO1 R AH0 JH AH0 N'],
  "original": ['ER0 IH1 JH AH0 N AH0 L'],
  "originality": ['ER0 IH2 JH AH0 N AE1 L IH0 T IY0'],
  "originally": ['ER0 IH1 JH AH0 N AH0 L IY0', 'ER0 IH1 JH N AH0 L IY0'],
  "originals": ['ER0 IH1 JH AH0 N AH0 L Z'],
  "originate": ['ER0 IH1 JH AH0 N EY2 T'],
  "originated": ['ER0 IH1 JH AH0 N EY2 T AH0 D', 'AO0 R IH1 JH AH0 N EY2 T IH0 D'],
  "originates": ['ER0 IH1 JH AH0 N EY2 T S'],
  "originating": ['ER0 IH1 JH AH0 N EY2 T IH0 NG'],
  "origination": ['ER0 IH2 JH AH0 N EY1 SH AH0 N'],
  "originations": ['ER0 IH2 JH AH0 N EY1 SH AH0 N Z'],
  "originator": ['ER0 IH1 JH AH0 N EY2 T ER0'],
  "originators": ['ER0 IH1 JH AH0 N EY2 T ER0 Z'],
  "origins": ['AO1 R AH0 JH IH0 N Z'],
  "oriley": ['OW0 R AY1 L IY0'],
  "orilla": ['AO0 R IH1 L AH0'],
  "orin": ['AO1 R IH0 N'],
  "orinda": ['ER0 IH1 N D AH0'],
  "orinoco": ['AO2 R AH0 N OW1 K OW0'],
  "oriola": ['AO0 R IY0 OW1 L AH0'],
  "oriole": ['AO1 R IY0 OW2 L'],
  "orioles": ['AO1 R IY0 OW2 L Z'],
  "orioles'": ['AO1 R IY0 OW2 L Z'],
  "orion": ['OW0 R AY1 AH0 N', 'AO0 R AY1 AH0 N'],
  "orion's": ['OW0 R AY1 AH0 N Z', 'AO0 R AY1 AH0 N Z'],
  "oriordan": ['OW0 R IH1 R D AH0 N', 'AO0 R IH1 R D AH0 N'],
  "oritz": ['AO1 R IH0 T S'],
  "orix": ['AO1 R IH0 K S'],
  "orkem": ['AO1 R K AH0 M'],
  "orkin": ['AO1 R K IH0 N'],
  "orkney": ['AO1 R K N IY0'],
  "orks": ['AO1 R K S'],
  "orkut": ['AO1 R K AH0 T'],
  "orky": ['AO1 R K IY0'],
  "orla": ['AO1 R L AH0'],
  "orlan": ['AO1 R L AH0 N'],
  "orland": ['AO1 R L AH0 N D'],
  "orlanda": ['AO0 R L AA1 N D AA2'],
  "orlandi": ['AO0 R L AA1 N D IY2'],
  "orlando": ['AO0 R L AE1 N D OW2'],
  "orlando's": ['AO0 R L AE1 N D OW2 Z'],
  "orlean": ['AO1 R L IY2 AH0 N'],
  "orlean's": ['AO1 R L IY2 AH0 N Z', 'AO1 R L IY2 N Z'],
  "orleanian": ['AO2 R L IY0 AH1 N IY0 AH0 N'],
  "orleanians": ['AO2 R L IY0 AH1 N IY0 AH0 N Z'],
  "orleans": ['AO1 R L IY0 AH0 N Z', 'AO2 R L IY1 N Z'],
  "orleans'": ['AO1 R L IY0 AH0 N Z'],
  "orlena": ['AO1 R L IH0 N AH0'],
  "orlene": ['AO1 R L IY2 N'],
  "orlich": ['AO1 R L IH0 K'],
  "orlick": ['AO1 R L IH0 K'],
  "orloff": ['AO1 R L AO0 F'],
  "orloff's": ['AO1 R L AO0 F S'],
  "orloski": ['AO0 R L AW1 S K IY2'],
  "orloski's": ['AO0 R L AW1 S K IY0 Z'],
  "orlosky": ['AO0 R L OW1 S K IY0'],
  "orlosky's": ['AO0 R L OW1 S K IY0 Z'],
  "orlov": ['AO1 R L AO0 V'],
  "orlov's": ['AO1 R L AO0 V S'],
  "orlowski": ['AO0 R L AO1 F S K IY0'],
  "orlowski's": ['AO0 R L AO1 F S K IY0 Z'],
  "orly": ['AO1 R L IY0'],
  "orman": ['AO1 R M AH0 N'],
  "ormand": ['AO1 R M AH0 N D'],
  "ormand's": ['AO1 R M AH0 N D Z'],
  "orme": ['AO1 R M'],
  "ormerod": ['AO1 R M ER0 AH0 D'],
  "ormes": ['AO1 R M Z'],
  "ormiston": ['AO1 R M IH0 S T AA0 N'],
  "ormiston's": ['AO1 R M IH0 S T AA0 N Z'],
  "ormond": ['AO1 R M AH0 N D'],
  "ormond's": ['AO1 R M AH0 N D Z'],
  "ormonde": ['AO1 R M AH0 N D'],
  "ormsbee": ['AO1 R M Z B IY0'],
  "ormsby": ['AO1 R M Z B IY0'],
  "ormsby's": ['AO1 R M Z B IY0 Z'],
  "orn": ['AO1 R N'],
  "orna": ['AO1 R N AH0'],
  "ornament": ['AO1 R N AH0 M AH0 N T'],
  "ornamental": ['AO2 R N AH0 M EH1 N T AH0 L'],
  "ornamentally": ['AO2 R N AH0 M EH1 N T AH0 L IY0'],
  "ornamentation": ['AO2 R N AH0 M EH0 N T EY1 SH AH0 N'],
  "ornamented": ['AO1 R N AH0 M EH2 N T IH0 D'],
  "ornaments": ['AO1 R N AH0 M AH0 N T S'],
  "ornate": ['AO0 R N EY1 T'],
  "ornately": ['AO0 R N EY1 T L IY0'],
  "ornda": ['AO1 R N D AH0'],
  "orndoff": ['AO1 R N D AO0 F'],
  "orndorff": ['AO1 R N D AO0 R F'],
  "orne": ['AO1 R N'],
  "ornelas": ['AO1 R N IH0 L AH0 Z'],
  "ornella": ['AO0 R N EH1 L AH0'],
  "ornella's": ['AO0 R N EH1 L AH0 Z'],
  "ornellas": ['AO0 R N EH1 L AH0 Z'],
  "orner": ['AO1 R N ER0'],
  "ornery": ['AO1 R N ER0 IY0'],
  "ornette": ['AO2 R N EH1 T'],
  "ornish": ['AO1 R N IH0 SH'],
  "ornithological": ['AO2 R N IH0 TH AH0 L AO1 JH IH0 K AH0 L'],
  "ornithology": ['AO2 R N IH0 TH AA1 L AH0 JH IY0'],
  "ornoff": ['AO1 R N AA0 F'],
  "ornstein": ['AO1 R N S T AY0 N', 'AO1 R N S T IY0 N'],
  "oro": ['AO1 R OW0'],
  "oroark": ['OW0 R AO1 R K'],
  "orogenic": ['AO2 R AH0 JH EH1 N IH0 K'],
  "orogil": ['AO1 R OW0 G IH2 L'],
  "orographic": ['AO2 R AH0 G R AE1 F IH0 K'],
  "orona": ['AO0 R OW1 N AH0'],
  "oropeza": ['AO0 R OW0 P EH1 Z AH0'],
  "oros": ['AO1 R OW0 Z'],
  "orosco": ['AO0 R OW1 S K OW0'],
  "orosz": ['AO1 R AH0 SH'],
  "orourke": ['ER0 UH1 R K'],
  "orozco": ['AO0 R OW1 Z K OW0'],
  "orpah": ['AO1 R P AA0'],
  "orphan": ['AO1 R F AH0 N'],
  "orphanage": ['AO1 R F AH0 N AH0 JH'],
  "orphanages": ['AO1 R F AH0 N IH0 JH IH0 Z'],
  "orphaned": ['AO1 R F AH0 N D'],
  "orphaning": ['AO1 R F AH0 N IH0 NG'],
  "orphans": ['AO1 R F AH0 N Z'],
  "orphee": ['AO1 R F IY0'],
  "orpheum": ['AO1 R F IY0 UW0 M'],
  "orpheus": ['AO1 R F IY0 AH0 S'],
  "orphic": ['AO1 R F IH0 K'],
  "orpiment": ['AO1 R P AH0 M AH0 N T'],
  "orr": ['AO1 R'],
  "orran": ['AO0 R AA1 N'],
  "orrell": ['AO0 R EY1 L'],
  "orren": ['AO1 R AH0 N'],
  "orrery": ['AO1 R ER0 IY0'],
  "orrick": ['AO1 R IH0 K'],
  "orrico": ['AO0 R IY1 K OW0'],
  "orrin": ['AO1 R IH0 N'],
  "orris": ['AO1 R IH0 S'],
  "orrison": ['AO1 R IH0 S AH0 N'],
  "orsa": ['AO1 R S AH0'],
  "orsak": ['AO1 R S AH0 K'],
  "orsay": ['AO0 R S EY1', 'AH0 R S EY1'],
  "orsborn": ['AO1 R S B ER0 N', 'AO1 R Z B ER0 N'],
  "orsburn": ['AO1 R S B ER0 N', 'AO1 R Z B ER0 N'],
  "orser": ['AO1 R S ER0'],
  "orsi": ['AO1 R S IY0'],
  "orsini": ['AO0 R S IY1 N IY0'],
  "orsino": ['AO0 R S IY1 N OW0'],
  "orso": ['AO1 R S OW0'],
  "orsola": ['AO0 R S OW1 L AH0'],
  "orson": ['AO1 R S AH0 N'],
  "ort": ['AO1 R T'],
  "orta": ['AO1 R T AH0'],
  "ortega": ['AO0 R T EY1 G AH0'],
  "ortega's": ['AO0 R T EY1 G AH0 Z'],
  "ortegas": ['AO0 R T EY1 G AH0 S'],
  "ortego": ['AO0 R T EY1 G OW0'],
  "ortegon": ['AO0 R T EY0 G AO1 N'],
  "orten": ['AO1 R T AH0 N'],
  "ortenzio": ['AO0 R T EH1 N Z IY0 OW0'],
  "ortez": ['AO0 R T EH1 Z'],
  "orth": ['AO1 R TH'],
  "ortho": ['AO1 R TH OW2'],
  "orthoclase": ['AO1 R TH AH0 K L EY2 S'],
  "orthodontia": ['AO2 R TH AH0 D AA1 N CH IY0 AH0'],
  "orthodontic": ['AO2 R TH AH0 D AA1 N T IH0 K'],
  "orthodontics": ['AO2 R TH AH0 D AA1 N T IH0 K S'],
  "orthodontist": ['AO2 R TH AH0 D AA1 N T AH0 S T'],
  "orthodontist's": ['AO2 R TH AH0 D AA1 N T AH0 S T S'],
  "orthodontists": ['AO2 R TH AH0 D AA1 N T AH0 S T S'],
  "orthodox": ['AO1 R TH AH0 D AA2 K S'],
  "orthodoxy": ['AO1 R TH AH0 D AA2 K S IY0'],
  "orthogonal": ['AO0 R TH AO1 G AH0 N AH0 L'],
  "orthography": ['AO0 R TH AA1 G R AH0 F IY0'],
  "orthopedic": ['AO2 R TH AH0 P IY1 D IH0 K'],
  "orthopedist": ['AO2 R TH AH0 P IY1 D AH0 S T'],
  "orthopedists": ['AO2 R TH AH0 P IY1 D AH0 S T S'],
  "ortis": ['AO1 R T IH0 S'],
  "ortiz": ['AO2 R T IY1 Z'],
  "ortlieb": ['AO1 R T L IY2 B'],
  "ortloff": ['AO1 R T L AO0 F'],
  "ortman": ['AO1 R T M AH0 N'],
  "ortmann": ['AO1 R T M AH0 N'],
  "ortner": ['AO1 R T N ER0'],
  "ortolano": ['AO0 R T OW0 L AA1 N OW0'],
  "ortomisio": ['AO2 R T AH0 M IH1 S IY0 OW0'],
  "orton": ['AO1 R T AH0 N'],
  "ortwein": ['AO1 R T W AY0 N'],
  "ortwin": ['AO1 R T W IH0 N'],
  "orum": ['AO1 R AH0 M'],
  "orva": ['AO1 R V AH0'],
  "orval": ['AO1 R V AH0 L'],
  "orville": ['AO1 R V IH0 L'],
  "orvin": ['AO1 R V IH0 N'],
  "orvis": ['AO1 R V IH0 S'],
  "orwell": ['AO1 R W EH2 L'],
  "orwell's": ['AO1 R W EH2 L Z'],
  "orwellian": ['AO2 R W EH1 L IY0 AH0 N'],
  "orwick": ['AO1 R W IH0 K'],
  "orwig": ['AO1 R W IH0 G'],
  "ory": ['AO1 R IY0'],
  "oryx": ['AO1 R IH0 K S'],
  "orzech": ['AO1 R Z IH0 HH'],
  "orzechowski": ['AO0 R Z IH0 HH AO1 F S K IY0'],
  "orzel": ['AO1 R Z AH0 L'],
  "os": ['AA1 S'],
  "osage": ['OW0 S EY1 JH'],
  "osages": ['OW0 S EY1 JH AH0 Z'],
  "osaka": ['OW0 S AA1 K AH0'],
  "osake": ['OW0 S AA1 K IY0'],
  "osaki": ['OW0 S AA1 K IY0'],
  "osakowa": ['OW2 S AH0 K OW1 AH0'],
  "osakowa's": ['OW2 S AH0 K OW1 AH0 Z'],
  "osama": ['OW0 S AA1 M AH0'],
  "osamu": ['OW0 S AA1 M UW0'],
  "osberg": ['AA1 S B ER0 G'],
  "osbert": ['AA1 S B ER0 T'],
  "osbon": ['AA1 S B AH0 N'],
  "osborn": ['AO1 Z B AO2 R N'],
  "osborne": ['AO1 Z B AO2 R N'],
  "osborne's": ['AO1 Z B AO2 R N Z'],
  "osbourn": ['AA1 S B ER0 N'],
  "osbourne": ['AA1 S B ER0 N'],
  "osbournes": ['AA1 S B ER0 N Z'],
  "osbun": ['AA1 S B AH0 N'],
  "osburn": ['AA1 S B ER2 N'],
  "osby": ['AA1 S B IY0'],
  "oscalus": ['AO1 S K AH0 L AH0 S'],
  "oscar": ['AO1 S K ER0'],
  "oscar's": ['AO1 S K ER0 Z'],
  "oscars": ['AO1 S K ER0 Z'],
  "oscarson": ['AA1 S K AA0 R S AH0 N'],
  "osceola": ['AO2 S K IY0 OW1 L AH0', 'AO2 S IY0 OW1 L AH0'],
  "oscillate": ['AA1 S AH0 L EY2 T'],
  "oscillated": ['AA1 S AH0 L EY2 T AH0 D'],
  "oscillates": ['AA1 S AH0 L EY2 T S'],
  "oscillating": ['AA1 S AH0 L EY2 T IH0 NG'],
  "oscillation": ['AA2 S AH0 L EY1 SH AH0 N'],
  "oscillations": ['AA2 S AH0 L EY1 SH AH0 N Z'],
  "oscillator": ['AA1 S AH0 L EY2 T ER0'],
  "oscillators": ['AA1 S AH0 L EY2 T ER0 Z'],
  "oscillatory": ['AA1 S AH0 L AH0 T AO2 R IY0'],
  "oscilloscope": ['AO2 S IH1 L AH0 S K OW2 P'],
  "oscilloscopes": ['AO2 S IH1 L AH0 S K OW2 P S'],
  "osco": ['AA1 S K OW0'],
  "ose": ['OW1 Z'],
  "oseguera": ['OW0 S EY0 G EH1 R AH0'],
  "oser": ['OW1 S ER0'],
  "osgood": ['AA1 S G UH2 D'],
  "osh": ['AA1 SH'],
  "osha": ['OW1 SH AH0'],
  "osha's": ['OW1 SH AH0 Z'],
  "oshaf": ['OW1 SH AA0 F'],
  "oshatz": ['OW0 SH AE1 T S'],
  "oshaughnessy": ['OW0 SH AA1 N IH0 S IY0'],
  "oshawa": ['AO2 SH AA1 W AH0'],
  "oshea": ['OW0 SH EY1'],
  "oshel": ['AA1 SH AH0 L'],
  "oshell": ['OW1 SH AH0 L'],
  "osher": ['AA1 SH ER0'],
  "oshields": ['OW0 SH IY1 L D Z'],
  "oshima": ['OW0 SH IY1 M AH0'],
  "oshiro": ['OW0 SH IH1 R OW0'],
  "oshita": ['OW0 SH IY1 T AH0'],
  "oshkosh": ['AO1 SH K AO2 SH'],
  "oshman": ['AO1 SH M AH0 N'],
  "oshman's": ['AO1 SH M AH0 N Z'],
  "osiecki": ['AH0 S IY1 T S K IY0'],
  "osika": ['AH0 S AY1 K AH0'],
  "osinski": ['AH0 S IH1 N S K IY0'],
  "oskar": ['AO1 S K ER0'],
  "osland": ['AA1 S L AH0 N D'],
  "osler": ['AA1 Z L ER0'],
  "osley": ['AA1 S L IY0'],
  "oslin": ['AA1 S L IH0 N'],
  "oslo": ['AA1 Z L OW0'],
  "oslund": ['AA1 S L AH0 N D'],
  "osman": ['AA1 S M AH0 N'],
  "osmar": ['AA1 S M ER0'],
  "osmena": ['AO2 Z M IY1 N AH0'],
  "osment": ['AA1 S M AH0 N T'],
  "osmer": ['AA1 S AH0 M ER0'],
  "osmium": ['AA1 Z M IY0 AH0 M'],
  "osmon": ['AA1 S M AH0 N'],
  "osmond": ['AA1 S M AH0 N D'],
  "osmonds": ['AO1 Z M AH0 N D Z'],
  "osmosis": ['AO2 Z M OW1 S IH0 S'],
  "osmotic": ['AA0 Z M AA1 T AH0 K'],
  "osmun": ['AA1 Z M AH0 N'],
  "osmund": ['AA1 S M AH0 N D'],
  "osmundson": ['AA1 Z M AH0 N D S AH0 N'],
  "osorio": ['OW0 S AO1 R IY0 OW0'],
  "osowski": ['AH0 S AO1 F S K IY0'],
  "ospina": ['OW0 S P IY1 N AH0'],
  "osprey": ['AO1 S P R IY0'],
  "osred": ['AA1 S ER0 D'],
  "osric": ['AA1 Z R IH0 K'],
  "oss": ['AO1 S'],
  "ossa": ['AA1 S AH0'],
  "ossetia": ['AA2 S EH1 SH Y AH0', 'AA2 S IY1 SH Y AH0'],
  "ossetian": ['AA2 S EH1 SH Y IH0 N', 'AA2 S IY1 SH Y IH0 N'],
  "ossetians": ['AA2 S EH1 SH Y IH0 N Z', 'AA2 S IY1 SH Y IH0 N Z'],
  "ossicles": ['AA1 S AH0 K AH0 L Z'],
  "ossification": ['AA2 S AH0 F AH0 K EY1 SH AH0 N'],
  "ossified": ['AA1 S AH0 F AY2 D'],
  "ossify": ['AA1 S AH0 F AY2'],
  "ossman": ['AA1 S M AH0 N'],
  "osso": ['OW1 S OW0'],
  "osswald": ['AA1 S W AH0 L D'],
  "ost": ['OW1 S T'],
  "ostankino": ['AA1 S T AH0 N K IH1 N OW0'],
  "ostberg": ['AA1 S T B ER0 G'],
  "ostby": ['AA1 S T B IY0'],
  "osteen": ['AA1 S T IY2 N'],
  "osten": ['AA1 S AH0 N'],
  "ostendorf": ['AA1 S T AH0 N D AO0 R F'],
  "ostensible": ['AA0 S T EH1 N S AH0 B AH0 L'],
  "ostensibly": ['AA0 S T EH1 N S AH0 B L IY0'],
  "ostenson": ['AA1 S T IH0 N S AH0 N'],
  "ostentation": ['AO2 S T EH0 N T EY1 SH AH0 N'],
  "ostentatious": ['AA2 S T AH0 N T EY1 SH AH0 S'],
  "ostentatiously": ['AA2 S T AH0 N T EY1 SH AH0 S L IY0'],
  "osteoarthritis": ['AA2 S T IY0 OW2 AA0 R TH R AY1 T AH0 S'],
  "osteopathic": ['AA2 S T IY0 AH0 P AE1 TH IH0 K'],
  "osteoporosis": ['AO2 S T IY0 AA2 P ER0 OW1 S IH0 S'],
  "oster": ['AA1 S T ER0'],
  "osterberg": ['AA1 S T ER0 B ER0 G'],
  "ostergaard": ['AA1 S T ER0 G AA0 R D'],
  "ostergard": ['AA1 S T ER0 G ER0 D'],
  "ostergren": ['AA1 S T ER0 G R EH0 N'],
  "osterhaus": ['AA1 S T ER0 HH AW0 S'],
  "osterhoff": ['OW1 S T ER0 HH AO2 F'],
  "osterholt": ['AA1 S T ER0 HH OW0 L T'],
  "osterhoudt": ['AA1 S T ER0 HH AW0 T'],
  "osterhout": ['AA1 S T ER0 HH AW0 T'],
  "osterkamp": ['AA1 S T ER0 K AE0 M P'],
  "osterling": ['AA1 S T ER0 L IH0 NG'],
  "osterloh": ['OW0 S T EH1 R L OW0'],
  "osterlund": ['AA1 S T ER0 L AH0 N D'],
  "osterman": ['AA1 S T ER0 M AH0 N'],
  "ostermann": ['AA1 S T ER0 M AH0 N'],
  "ostermeier": ['AA1 S T ER0 M AY0 ER0'],
  "ostermeyer": ['AA1 S T ER2 M AY2 ER0'],
  "ostermiller": ['AA1 S T ER0 M IH0 L ER0'],
  "osterreichische": ['AO1 S T ER0 R AY2 K IH0 SH IY0'],
  "ostertag": ['AA1 S T ER0 T AH0 G'],
  "osthoff": ['AA1 S T HH AO0 F'],
  "ostia": ['AA1 S T IY0 AH0'],
  "ostin": ['AA1 S T AH0 N'],
  "ostinato": ['AA2 S T AH0 N AA1 T OW2'],
  "osting": ['AA1 S T IH0 NG'],
  "ostling": ['AA1 S AH0 L IH0 NG', 'AA1 S T L IH0 NG', 'AA1 S L IH0 NG'],
  "ostlund": ['AA1 S T L AH0 N D'],
  "ostman": ['AA1 S T M AH0 N'],
  "ostpolitik": ['OW2 S T P OW2 L IH0 T IH1 K'],
  "ostracism": ['AO1 S T R AH0 S IH2 Z AH0 M'],
  "ostracize": ['AO1 S T R AH0 S AY2 Z'],
  "ostracized": ['AO1 S T R AH0 S AY2 Z D'],
  "ostrand": ['AA1 S T R AH0 N D'],
  "ostrander": ['AA1 S T R AH0 N D ER0'],
  "ostrem": ['AA1 S T R IH0 M'],
  "ostrich": ['AO1 S T R IH0 CH'],
  "ostriches": ['AA1 S T R IH0 CH IH0 Z'],
  "ostroff": ['AO1 S T R AO0 F'],
  "ostrogoth": ['AA1 S T R AH0 G AA2 TH'],
  "ostrogoths": ['AA1 S T R AH0 G AA2 TH S'],
  "ostrom": ['AA1 S T R AH0 M'],
  "ostroski": ['AH0 S T R AW1 S K IY0'],
  "ostrosky": ['AH0 S T R OW1 S K IY0'],
  "ostrow": ['AA1 S T R AW0', 'AA1 S T R OW0'],
  "ostrowski": ['AH0 S T R AO1 F S K IY0'],
  "ostrowsky": ['AH0 S T R AW1 S K IY0'],
  "ostrum": ['AA1 S T R AH0 M'],
  "ostwald": ['AA1 S T W AH0 L D'],
  "osuch": ['AA1 S AH0 K'],
  "osullivan": ['OW0 S AH1 L IH0 V AH0 N'],
  "osuna": ['OW0 S UW1 N AH0'],
  "oswald": ['AO1 Z W AO0 L D'],
  "oswald's": ['AO1 Z W AO0 L D Z'],
  "oswalt": ['AA1 S W AH0 L T'],
  "oswego": ['AA0 S W IY1 G OW0'],
  "oswell": ['AA1 S W EH2 L'],
  "ot": ['AO1 T', 'OW1 T IY1'],
  "ota": ['OW1 T AH0'],
  "otaiba": ['OW0 T EY1 B AH0'],
  "otani": ['OW0 T AA1 N IY0'],
  "otello": ['OW0 T EH1 L OW0'],
  "oteri": ['OW0 T EH1 R IY0'],
  "otero": ['OW0 T EH1 R OW0'],
  "otey": ['OW1 T IY0'],
  "otha": ['AH0 DH AA1'],
  "othello": ['AH0 TH EH1 L OW0'],
  "other": ['AH1 DH ER0'],
  "other's": ['AH1 DH ER0 Z'],
  "otherness": ['AH1 DH ER0 N AH0 S'],
  "others": ['AH1 DH ER0 Z'],
  "others'": ['AH1 DH ER0 Z'],
  "otherwise": ['AH1 DH ER0 W AY2 Z'],
  "otherworldly": ['AH1 DH ER0 W ER1 L D L IY0'],
  "othilia": ['OW0 TH IY1 L IY0 AH0'],
  "othman": ['AA1 TH M AH0 N'],
  "othman's": ['AA1 TH M AH0 N Z'],
  "otiose": ['OW1 T IY0 OW2 S'],
  "otis": ['OW1 T IH0 S'],
  "otmar": ['AA1 T M AA0 R'],
  "otologies": ['OW0 T AA1 L AH0 JH IY0 Z'],
  "otology": ['OW0 T AA1 L AH0 JH IY0'],
  "otomobil": ['OW0 T OW1 M OW0 B AH0 L'],
  "otoole": ['AA1 T UW0 L'],
  "otremba": ['OW0 T R EH1 M B AH0'],
  "otsego": ['AA0 T S EY1 G OW0'],
  "otsuka": ['OW0 T S UW1 K AH0'],
  "ott": ['AA1 T'],
  "ottaviani": ['OW0 T AA0 V IY0 AA1 N IY0'],
  "ottaviano": ['OW0 T AA0 V IY0 AA1 N OW0'],
  "ottawa": ['AA1 T AH0 W AA2'],
  "ottawa's": ['AA1 T AH0 W AH0 Z'],
  "ottaway": ['AA1 T AH0 W EY0'],
  "ottaway's": ['AA1 T AH0 W EY0 Z'],
  "otte": ['AA1 T'],
  "otten": ['AA1 T AH0 N'],
  "otten's": ['AA1 T AH0 N Z'],
  "ottens": ['AA1 T AH0 N Z'],
  "otter": ['AA1 T ER0'],
  "otter's": ['AA1 T ER0 Z'],
  "otterbein": ['AA1 T ER0 B AY0 N'],
  "otterbein's": ['AA1 T ER0 B AY0 N Z'],
  "otterloo": ['AA1 T ER0 L UW2'],
  "otters": ['AA1 T ER0 Z'],
  "otterson": ['AA1 T ER0 S AH0 N'],
  "otterson's": ['AA1 T ER0 S AH0 N Z'],
  "ottesen": ['AA1 T IY0 Z AH0 N'],
  "otteson": ['AA1 T IH0 S AH0 N'],
  "ottilie": ['AA1 T AH0 L IY0'],
  "otting": ['AA1 T IH0 NG'],
  "ottley": ['AA1 T L IY0'],
  "ottman": ['AA1 T M AH0 N'],
  "otto": ['AA1 T OW2'],
  "otto's": ['AA1 T OW2 Z'],
  "ottoman": ['AA1 T AH0 M AH0 N'],
  "ottosen": ['AA1 T OW0 S AH0 N'],
  "ottoson": ['AA1 T AH0 S AH0 N'],
  "otts": ['AA1 T S'],
  "ottum": ['AA1 T AH0 M'],
  "otulsky": ['AH0 T UH1 L S K IY0'],
  "otwell": ['AA1 T W EH2 L'],
  "ou": ['UW1'],
  "ouaga": ['W AA1 G AH0'],
  "ouagadougou": ['W AA2 G AH0 D UW1 G UW0'],
  "oubre": ['UW1 B ER0'],
  "ouch": ['AW1 CH'],
  "ouderkirk": ['AW1 D ER0 K ER0 K'],
  "ouelette": ['AA1 UW0 L EH0 T'],
  "ouellet": ['AA1 UW0 L IH0 T'],
  "ouellette": ['AA1 UW0 L EH0 T'],
  "ought": ['AO1 T'],
  "oughta": ['AO1 T AH0'],
  "oughtn't": ['AO1 T AH0 N T'],
  "oui": ['W IY1', 'UW0 W IY1'],
  "ouimet": ['W IY0 M EH1 T'],
  "ouimette": ['W IY0 M EH1 T'],
  "ounce": ['AW1 N S'],
  "ounces": ['AW1 N S AH0 Z', 'AW1 N S IH0 Z'],
  "ounsted": ['AW1 N S T EH2 D'],
  "our": ['AW1 ER0', 'AW1 R', 'AA1 R'],
  "ourada": ['OW0 UH0 R AA1 D AH0'],
  "ours": ['AW1 ER0 Z', 'AA1 R Z', 'AW1 R Z'],
  "ourself": ['AW0 ER0 S EH1 L F', 'AA0 R S EH1 L F'],
  "ourselves": ['AW0 ER0 S EH1 L V Z', 'AA0 R S EH1 L V Z'],
  "ourso": ['ER1 S OW0'],
  "ouseba": ['UW1 S AH0 B AH0'],
  "ousley": ['AW1 S L IY0'],
  "oust": ['AW1 S T'],
  "ousted": ['AW1 S T IH0 D'],
  "ouster": ['AW1 S T ER0'],
  "ousting": ['AW1 S T IH0 NG'],
  "out": ['AW1 T'],
  "out's": ['AW1 T S'],
  "out-mode": ['AW1 T M OW1 D'],
  "out-moded": ['AW1 T M OW1 D IH0 D'],
  "outage": ['AW1 T AH0 JH', 'AW1 T IH0 JH'],
  "outages": ['AW1 T IH0 JH IH0 Z'],
  "outback": ['AW1 T B AE2 K'],
  "outbid": ['AW1 T B IH2 D'],
  "outbidded": ['AW1 T B IH2 D AH0 D'],
  "outbidding": ['AW1 T B IH2 D IH0 NG'],
  "outbids": ['AW1 T B IH2 D Z'],
  "outboard": ['AW1 T B AO2 R D'],
  "outboard's": ['AW1 T B AO2 R D Z'],
  "outboards": ['AW1 T B AO2 R D Z'],
  "outbound": ['AW1 T B AW2 N D'],
  "outbreak": ['AW1 T B R EY2 K'],
  "outbreaks": ['AW1 T B R EY2 K S'],
  "outburst": ['AW1 T B ER2 S T'],
  "outbursts": ['AW1 T B ER2 S T S'],
  "outcalt": ['AW1 T K AH0 L T'],
  "outcast": ['AW1 T K AE2 S T'],
  "outcasts": ['AW1 T K AE2 S T S'],
  "outclass": ['AW1 T K L AE2 S'],
  "outclassed": ['AW1 T K L AE2 S T'],
  "outclasses": ['AW1 T K L AE2 S IH0 Z'],
  "outclassing": ['AW1 T K L AE2 S IH0 NG'],
  "outcome": ['AW1 T K AH2 M'],
  "outcomes": ['AW1 T K AH2 M Z'],
  "outcries": ['AW1 T K R AY2 Z'],
  "outcry": ['AW1 T K R AY2'],
  "outdate": ['AW1 T D EY2 T'],
  "outdated": ['AW1 T D EY2 T IH0 D'],
  "outdid": ['AW1 T D IH2 D'],
  "outdistance": ['AW1 T D IH1 S T AH0 N S'],
  "outdistanced": ['AW1 T D IH1 S T AH0 N S T'],
  "outdistances": ['AW1 T D IH1 S T AH0 N S IH0 Z'],
  "outdistancing": ['AW1 T D IH1 S T AH0 N S IH0 NG'],
  "outdo": ['AW2 T D UW1'],
  "outdoes": ['AW2 T D AH1 Z'],
  "outdoing": ['AW2 T D UW1 IH0 NG'],
  "outdone": ['AW1 T D AH1 N'],
  "outdoor": ['AW1 T D AO2 R'],
  "outdoors": ['AW1 T D AO1 R Z'],
  "outdoorsman": ['AW1 T D AO1 R Z M AH0 N'],
  "outed": ['AW1 T AH0 D'],
  "outen": ['AW1 T AH0 N'],
  "outer": ['AW1 T ER0'],
  "outermost": ['AW1 T ER0 M OW2 S T'],
  "outerwear": ['AW1 T ER0 W EH2 R'],
  "outfield": ['AW1 T F IY2 L D'],
  "outfielder": ['AW1 T F IY2 L D ER0'],
  "outfielders": ['AW1 T F IY2 L D ER0 Z'],
  "outfields": ['AW1 T F IY2 L D Z'],
  "outfit": ['AW1 T F IH2 T'],
  "outfits": ['AW1 T F IH2 T S'],
  "outfitted": ['AW1 T F IH2 T IH0 D'],
  "outfitter": ['AW1 T F IH2 T ER0'],
  "outfitters": ['AW1 T F IH2 T ER0 Z'],
  "outfitting": ['AW1 T F IH2 T IH0 NG'],
  "outflank": ['AW1 T F L AE2 NG K'],
  "outflanked": ['AW0 T F L AE1 NG K T'],
  "outflanking": ['AW1 T F L AE2 NG K IH0 NG'],
  "outflanks": ['AW1 T F L AE2 NG K S'],
  "outflow": ['AW1 T F L OW2'],
  "outflows": ['AW1 T F L OW2 Z'],
  "outfox": ['AW0 T F AA1 K S'],
  "outfoxed": ['AW0 T F AA1 K S T'],
  "outfoxes": ['AW0 T F AA1 K S IH0 Z'],
  "outfoxing": ['AW0 T F AA1 K S IH0 NG'],
  "outgain": ['AW1 T G EY2 N'],
  "outgained": ['AW1 T G EY2 N D'],
  "outgaining": ['AW1 T G EY2 N IH0 NG'],
  "outgains": ['AW1 T G EY2 N Z'],
  "outgo": ['AW1 T G OW2'],
  "outgoing": ['AW1 T G OW2 IH0 NG'],
  "outgrew": ['AW2 T G R UW1'],
  "outgrow": ['AW2 T G R OW1'],
  "outgrowing": ['AW1 T G R OW2 IH0 NG'],
  "outgrown": ['AW2 T G R OW1 N'],
  "outgrows": ['AW2 T G R OW1 Z'],
  "outgrowth": ['AW1 T G R OW2 TH'],
  "outguess": ['AW1 T G EH2 S'],
  "outguessed": ['AW1 T G EH2 S T'],
  "outguesses": ['AW1 T G EH2 S IH0 Z'],
  "outguessing": ['AW1 T G EH2 S IH0 NG'],
  "outgun": ['AW1 T G AH2 N'],
  "outgunned": ['AW1 T G AH2 N D'],
  "outgunning": ['AW1 T G AH2 N IH0 NG'],
  "outguns": ['AW1 T G AH2 N Z'],
  "outhouse": ['AW1 T HH AW2 S'],
  "outhouses": ['AW1 T HH AW2 S IH0 Z'],
  "outing": ['AW1 T IH0 NG'],
  "outings": ['AW1 T IH0 NG Z'],
  "outland": ['AW1 T L AE2 N D', 'AW1 T L AH0 N D'],
  "outlandish": ['AW0 T L AE1 N D IH0 SH'],
  "outlast": ['AW1 T L AE2 S T', 'AW0 T L AE1 S T'],
  "outlasted": ['AW1 T L AE2 S T IH0 D', 'AW0 T L AE1 S T IH0 D'],
  "outlasting": ['AW1 T L AE2 S T IH0 NG', 'AW0 T L AE1 S T IH0 NG'],
  "outlasts": ['AW1 T L AE2 S T S', 'AW0 T L AE1 S T S', 'AW1 T L AE2 S', 'AW0 T L AE1 S'],
  "outlaw": ['AW1 T L AO2'],
  "outlawed": ['AW1 T L AO2 D'],
  "outlawing": ['AW1 T L AO2 IH0 NG'],
  "outlawry": ['AW1 T L AO2 R IY0'],
  "outlaws": ['AW1 T L AO2 Z'],
  "outlay": ['AW1 T L EY2'],
  "outlays": ['AW1 T L EY2 Z'],
  "outler": ['AW1 T AH0 L ER0', 'AW1 T L ER0'],
  "outlet": ['AW1 T L EH2 T'],
  "outlet's": ['AW1 T L EH2 T S'],
  "outlets": ['AW1 T L EH2 T S'],
  "outley": ['AW1 T L IY0'],
  "outlier": ['AW1 T L AY0 ER0'],
  "outliers": ['AW1 T L AY0 ER0 Z'],
  "outline": ['AW1 T L AY2 N'],
  "outlined": ['AW1 T L AY2 N D'],
  "outlines": ['AW1 T L AY2 N Z'],
  "outlining": ['AW1 T L AY2 N IH0 NG'],
  "outlive": ['AW2 T L IH1 V'],
  "outlived": ['AW2 T L IH1 V D'],
  "outlives": ['AW2 T L IH1 V Z'],
  "outliving": ['AW2 T L IH1 V IH0 NG'],
  "outlook": ['AW1 T L UH2 K'],
  "outlooks": ['AW1 T L UH2 K S'],
  "outlying": ['AW1 T L AY2 IH0 NG'],
  "outman": ['AW1 T M AH0 N', 'AW2 T M AE1 N'],
  "outmaneuver": ['AW0 T M AH0 N UW1 V ER0'],
  "outmaneuvered": ['AW0 T M AH0 N UW1 V ER0 D'],
  "outmaneuvering": ['AW0 T M AH0 N UW1 V ER0 IH0 NG'],
  "outmaneuvers": ['AW0 T M AH0 N UW1 V ER0 Z'],
  "outmanned": ['AW2 T M AE1 N D'],
  "outmode": ['AW2 T M OW1 D'],
  "outmoded": ['AW2 T M OW1 D IH0 D'],
  "outnumber": ['AW0 T N AH1 M B ER0'],
  "outnumbered": ['AW2 T N AH1 M B ER0 D'],
  "outnumbering": ['AW1 T N AH2 M B ER0 IH0 NG'],
  "outnumbers": ['AW0 T N AH1 M B ER0 Z'],
  "outokumpu": ['UW2 T AH0 K AH1 M P UW0'],
  "outpace": ['AW1 T P EY2 S'],
  "outpaced": ['AW1 T P EY2 S T'],
  "outpaces": ['AW1 T P EY2 S IH0 Z'],
  "outpacing": ['AW1 T P EY2 S IH0 NG'],
  "outpatient": ['AW1 T P EY2 SH AH0 N T'],
  "outpatients": ['AW1 T P EY2 SH AH0 N T S'],
  "outperform": ['AW1 T P ER0 F AO2 R M'],
  "outperformance": ['AW2 T P ER0 F AO1 R M AH0 N S'],
  "outperformed": ['AW1 T P ER0 F AO2 R M D'],
  "outperformer": ['AW2 T P ER0 F AO1 R M ER0'],
  "outperformers": ['AW2 T P ER0 F AO1 R M ER0 Z'],
  "outperforming": ['AW1 T P ER0 F AO2 R M IH0 NG'],
  "outperforms": ['AW1 T P ER0 F AO1 R M Z'],
  "outplacement": ['AW1 T P L EY2 S M AH0 N T'],
  "outpost": ['AW1 T P OW2 S T'],
  "outposts": ['AW1 T P OW2 S T S'],
  "outpouch": ['AW1 T P AW2 CH'],
  "outpouching": ['AW1 T P AW2 CH IH0 NG'],
  "outpour": ['AW2 T P AO1 R'],
  "outpouring": ['AW2 T P AO1 R IH0 NG'],
  "outpours": ['AW2 T P AO1 R Z'],
  "output": ['AW1 T P UH2 T'],
  "outputs": ['AW1 T P UH2 T S'],
  "outrage": ['AW1 T R EY2 JH'],
  "outraged": ['AW1 T R EY2 JH D'],
  "outrageous": ['AW0 T R EY1 JH AH0 S'],
  "outrageously": ['AW2 T R EY1 JH AH0 S L IY0'],
  "outrageousness": ['AW0 T R EY1 JH AH0 S N AH0 S'],
  "outrages": ['AW1 T R EY2 JH IH0 Z'],
  "outraging": ['AW1 T R EY2 JH IH0 NG'],
  "outran": ['AW0 T R AE1 N'],
  "outreach": ['AW1 T R IY2 CH'],
  "outrider": ['AW1 T R AY2 D ER0'],
  "outriders": ['AW1 T R AY2 D ER0 Z'],
  "outrigger": ['AW1 T R IH2 G ER0'],
  "outriggers": ['AW1 T R IH2 G ER0 Z'],
  "outright": ['AW1 T R AY1 T'],
  "outrun": ['AW0 T R AH1 N'],
  "outrunning": ['AW0 T R AH1 N IH0 NG'],
  "outruns": ['AW0 T R AH1 N Z'],
  "outs": ['AW1 T S'],
  "outscore": ['AW0 T S K AO1 R'],
  "outscored": ['AW0 T S K AO1 R D'],
  "outscores": ['AW0 T S K AO1 R Z'],
  "outscoring": ['AW0 T S K AO1 R IH0 NG'],
  "outsell": ['AW0 T S EH1 L'],
  "outselling": ['AW0 T S EH1 L IH0 NG'],
  "outsells": ['AW0 T S EH1 L Z'],
  "outset": ['AW1 T S EH2 T'],
  "outshine": ['AW1 CH AY2 N'],
  "outshines": ['AW1 CH AY2 N Z'],
  "outshining": ['AW1 CH AY2 N IH0 NG'],
  "outshone": ['AW0 T SH OW1 N'],
  "outside": ['AW1 T S AY1 D'],
  "outsider": ['AW0 T S AY1 D ER0'],
  "outsider's": ['AW0 T S AY1 D ER0 Z'],
  "outsiders": ['AW0 T S AY1 D ER0 Z'],
  "outsiders'": ['AW0 T S AY1 D ER0 Z'],
  "outsides": ['AW1 T S AY1 D Z'],
  "outsize": ['AW1 T S AY2 Z'],
  "outsized": ['AW1 T S AY2 Z D'],
  "outskirt": ['AW1 T S K ER2 T'],
  "outskirts": ['AW1 T S K ER2 T S'],
  "outsmart": ['AW1 T S M AA2 R T'],
  "outsmarted": ['AW1 T S M AA2 R T IH0 D'],
  "outsmarting": ['AW1 T S M AA2 R T IH0 NG'],
  "outsmarts": ['AW1 T S M AA2 R T S'],
  "outsold": ['AW0 T S OW1 L D'],
  "outsource": ['AW2 T S AO1 R S'],
  "outsourced": ['AW2 T S AO1 R S T'],
  "outsources": ['AW2 T S AO1 R S IH0 Z'],
  "outsourcing": ['AW2 T S AO1 R S IH0 NG'],
  "outspend": ['AW1 T S P EH2 N D'],
  "outspending": ['AW1 T S P EH2 N D IH0 NG'],
  "outspends": ['AW1 T S P EH2 N D Z'],
  "outspent": ['AW0 T S P EH1 N T'],
  "outspoken": ['AW1 T S P OW1 K AH0 N'],
  "outspokenness": ['AW0 T S P OW1 K AH0 N AH0 S'],
  "outstanding": ['AW2 T S T AE1 N D IH0 NG'],
  "outstandingly": ['AW2 T S T AE1 N D IH0 NG L IY0'],
  "outstretch": ['AW0 T S T R EH1 CH'],
  "outstretched": ['AW0 T S T R EH1 CH T'],
  "outstrip": ['AW0 T S T R IH1 P'],
  "outstripped": ['AW0 T S T R IH1 P T'],
  "outstripping": ['AW0 T S T R IH1 P IH0 NG'],
  "outstrips": ['AW0 T S T R IH1 P S'],
  "outta": ['UW1 T AH0', 'AW1 T AH0'],
  "outtake": ['AW1 T T EY2 K', 'AW1 T EY2 K'],
  "outtakes": ['AW1 T T EY2 K S', 'AW1 T EY2 K S'],
  "outten": ['AW1 T AH0 N'],
  "outvote": ['AW0 T V OW1 T'],
  "outvoted": ['AW0 T V OW1 T IH0 D'],
  "outvotes": ['AW0 T V OW1 T S'],
  "outvoting": ['AW0 T V OW1 T IH0 NG'],
  "outward": ['AW1 T W ER0 D'],
  "outwardly": ['AW1 T W ER0 D L IY0'],
  "outwards": ['AW1 T W ER0 D Z'],
  "outweigh": ['AW1 T W EY2'],
  "outweighed": ['AW0 T W EY1 D'],
  "outweighing": ['AW1 T W EY2 IH0 NG'],
  "outweighs": ['AW1 T W EY2 Z'],
  "outwit": ['AW1 T W IH2 T'],
  "outwits": ['AW1 T W IH2 T S'],
  "outwitted": ['AW1 T W IH2 T IH0 D'],
  "outwitting": ['AW1 T W IH2 T IH0 NG'],
  "ouzts": ['AW1 Z T S', 'AW1 S T S'],
  "ova": ['OW1 V AH0'],
  "oval": ['OW1 V AH0 L'],
  "ovalle": ['AA1 V EY0 L'],
  "ovarian": ['OW0 V EH1 R IY2 AH0 N'],
  "ovaries": ['OW1 V ER2 IY0 Z'],
  "ovary": ['OW1 V ER2 IY0'],
  "ovate": ['OW1 V EY0 T'],
  "ovation": ['OW0 V EY1 SH AH0 N'],
  "ovations": ['OW0 V EY1 SH AH0 N Z'],
  "oven": ['AH1 V AH0 N'],
  "ovens": ['AH1 V AH0 N Z'],
  "over": ['OW1 V ER0'],
  "overabundance": ['OW1 V ER0 AH0 B AH1 N D AH0 N S'],
  "overacker": ['OW1 V ER0 AH0 K ER0'],
  "overact": ['OW1 V ER0 AE2 K T'],
  "overacted": ['OW1 V ER0 AE2 K T IH0 D', 'OW2 V ER0 AE1 K T IH0 D'],
  "overactive": ['OW1 V ER0 AE1 K T IH0 V'],
  "overacts": ['OW1 V ER0 AE2 K T S'],
  "overall": ['OW1 V ER0 AO2 L'],
  "overallotment": ['OW1 V ER0 AH0 L AA1 T M AH0 N T'],
  "overallotments": ['OW1 V ER0 AH0 L AA1 T M AH0 N T S'],
  "overalls": ['OW1 V ER0 AO2 L Z'],
  "overambitious": ['OW1 V ER0 AE0 M B IH2 SH AH0 S'],
  "overarching": ['OW1 V ER0 AA2 R CH IH0 NG'],
  "overate": ['OW0 V ER0 EY1 T'],
  "overbaugh": ['OW0 V ER1 B AO0'],
  "overbay": ['OW1 V ER0 B EY2'],
  "overbearing": ['OW1 V ER0 B EH1 R IH0 NG'],
  "overbeck": ['OW1 V ER0 B EH2 K'],
  "overbey": ['OW1 V ER0 B IY0'],
  "overbill": ['OW2 V ER0 B IH1 L'],
  "overbilled": ['OW1 V ER0 B IH2 L D'],
  "overbilling": ['OW1 V ER0 B IH2 L IH0 NG'],
  "overbills": ['OW2 V ER0 B IH1 L Z'],
  "overblown": ['OW2 V ER0 B L OW1 N'],
  "overboard": ['OW1 V ER0 B AO2 R D'],
  "overbook": ['OW1 V ER0 B UH2 K'],
  "overbooked": ['OW1 V ER0 B UH2 K T'],
  "overbooking": ['OW1 V ER0 B UH2 K IH0 NG'],
  "overbooks": ['OW1 V ER0 B UH2 K S'],
  "overbought": ['OW1 V ER0 B AO1 T'],
  "overbuilding": ['OW1 V ER0 B IH2 L D IH0 NG'],
  "overbuilt": ['OW1 V ER0 B IH1 L T'],
  "overburden": ['OW1 V ER0 B ER1 D AH0 N'],
  "overburdened": ['OW1 V ER0 B ER1 D AH0 N D'],
  "overbuy": ['OW1 V ER0 B AY2'],
  "overby": ['OW1 V ER0 B IY0'],
  "overcame": ['OW1 V ER0 K EY1 M'],
  "overcapacity": ['OW1 V ER0 K AH0 P AE1 S AH0 T IY0'],
  "overcash": ['OW1 V ER0 K AE2 SH'],
  "overcast": ['OW1 V ER0 K AE2 S T'],
  "overcharge": ['OW1 V ER0 CH AA2 R JH'],
  "overcharged": ['OW1 V ER0 CH AA1 R JH D'],
  "overcharges": ['OW1 V ER0 CH AA2 R JH IH0 Z'],
  "overcharging": ['OW2 V ER0 CH AA1 R JH IH0 NG'],
  "overclock": ['OW1 V ER0 K L AO1 K'],
  "overclocking": ['OW1 V ER0 K L AO1 K IH0 NG'],
  "overcoat": ['OW1 V ER0 K OW2 T'],
  "overcoats": ['OW1 V ER0 K OW2 T S'],
  "overcome": ['OW1 V ER0 K AH2 M'],
  "overcomes": ['OW1 V ER0 K AH2 M Z'],
  "overcoming": ['OW1 V ER0 K AH2 M IH0 NG'],
  "overconfidence": ['OW2 V ER0 K AA1 N F IH0 D AH0 N S'],
  "overconfident": ['OW2 V ER0 K AA1 N F IH0 D AH0 N T'],
  "overconsumption": ['OW1 V ER0 K AH0 N S AH2 M P SH AH0 N'],
  "overcook": ['OW2 V ER0 K UH1 K'],
  "overcooked": ['OW2 V ER0 K UH1 K T'],
  "overcooking": ['OW2 V ER0 K UH1 K IH0 NG'],
  "overcooks": ['OW2 V ER0 K UH1 K S'],
  "overcrowd": ['OW2 V ER0 K R AW1 D'],
  "overcrowded": ['OW1 V ER0 K R AW2 D IH0 D'],
  "overcrowding": ['OW1 V ER0 K R AW2 D IH0 NG'],
  "overcrowds": ['OW2 V ER0 K R AW1 D Z'],
  "overdamping": ['OW1 V ER0 D AE2 M P IH0 NG'],
  "overdependence": ['OW1 V ER0 D IH0 P EH2 N D AH0 N S'],
  "overdependent": ['OW1 V ER0 D IH0 P EH2 N D AH0 N T'],
  "overdid": ['OW1 V ER0 D IH2 D'],
  "overdo": ['OW1 V ER0 D UW1'],
  "overdoes": ['OW2 V ER0 D AH1 Z'],
  "overdoing": ['OW1 V ER0 D UW1 IH0 NG'],
  "overdone": ['OW1 V ER0 D AH1 N'],
  "overdorf": ['OW1 V ER0 D AO0 R F'],
  "overdose": ['OW1 V ER0 D OW2 S'],
  "overdosed": ['OW1 V ER0 D OW2 S T'],
  "overdoses": ['OW1 V ER0 D OW2 S IH0 Z'],
  "overdosing": ['OW1 V ER0 D OW2 S IH0 NG'],
  "overdraft": ['OW1 V ER0 D R AE2 F T'],
  "overdrafting": ['OW1 V ER0 D R AE2 F T IH0 NG'],
  "overdrafts": ['OW1 V ER0 D R AE2 F T S'],
  "overdraw": ['OW1 V ER0 D R AO2'],
  "overdrawn": ['OW1 V ER0 D R AO1 N'],
  "overdress": ['OW1 V ER0 D R EH2 S'],
  "overdrew": ['OW1 V ER0 D R UW2'],
  "overdrive": ['OW1 V ER0 D R AY2 V'],
  "overdue": ['OW1 V ER0 D UW1'],
  "overeager": ['OW1 V ER0 IY2 G ER0'],
  "overeat": ['OW1 V ER0 IY2 T'],
  "overeating": ['OW1 V ER0 IY1 T IH0 NG'],
  "overemphasize": ['OW1 V ER0 EH1 M F AH0 S AY2 Z'],
  "overemphasized": ['OW1 V ER0 EH1 M F AH0 S AY2 Z D'],
  "overemphasizes": ['OW1 V ER0 EH1 M F AH0 S AY2 Z IH0 Z'],
  "overemphasizing": ['OW1 V ER0 EH1 M F AH0 S AY2 Z IH0 NG'],
  "overestimate": ['OW2 V ER0 EH1 S T AH0 M EY2 T'],
  "overestimated": ['OW2 V ER0 EH1 S T AH0 M EY2 T IH0 D'],
  "overestimates": ['OW2 V ER0 EH1 S T AH0 M EY2 T S'],
  "overestimating": ['OW2 V ER0 EH1 S T AH0 M EY2 T IH0 NG'],
  "overexcite": ['OW2 V ER0 EH0 K S AY1 T'],
  "overexcited": ['OW2 V ER0 EH0 K S AY1 T IH0 D'],
  "overexcites": ['OW2 V ER0 EH0 K S AY1 T S'],
  "overexciting": ['OW2 V ER0 EH0 K S AY1 T IH0 NG'],
  "overexpansion": ['OW2 V ER0 IH0 K S P AE1 N SH AH0 N'],
  "overexpose": ['OW2 V ER0 IH0 K S P OW1 Z'],
  "overexposed": ['OW2 V ER0 IH0 K S P OW1 Z D'],
  "overexposes": ['OW2 V ER0 IH0 K S P OW1 Z IH0 Z'],
  "overexposing": ['OW2 V ER0 IH0 K S P OW1 Z IH0 NG'],
  "overexposure": ['OW2 V ER0 IH0 K S P OW1 ZH ER0'],
  "overextend": ['OW2 V ER0 IH0 K S T EH1 N D'],
  "overextended": ['OW2 V ER0 IH0 K S T EH1 N D IH0 D'],
  "overextending": ['OW2 V ER0 IH0 K S T EH1 N D IH0 NG'],
  "overextends": ['OW2 V ER0 IH0 K S T EH1 N D Z'],
  "overfed": ['OW2 V ER0 F EH1 D'],
  "overfeed": ['OW2 V ER0 F IY1 D'],
  "overfelt": ['OW1 V ER0 F EH2 L T'],
  "overfield": ['OW1 V ER0 F IY2 L D'],
  "overfill": ['OW1 V ER0 F IH2 L'],
  "overfishing": ['OW1 V ER0 F IH2 SH IH0 NG'],
  "overflight": ['OW1 V ER0 F L AY2 T'],
  "overflights": ['OW1 V ER0 F L AY2 T S'],
  "overflow": ['OW1 V ER0 F L OW2', 'OW2 V ER0 F L OW1'],
  "overflowed": ['OW2 V ER0 F L OW1 D'],
  "overflowing": ['OW1 V ER0 F L OW2 IH0 NG'],
  "overflows": ['OW1 V ER0 F L OW2 Z'],
  "overfly": ['OW2 V ER0 F L AY1'],
  "overflying": ['OW2 V ER0 F L AY1 IH0 NG'],
  "overfund": ['OW1 V ER0 F AH2 N D'],
  "overfunded": ['OW1 V ER0 F AH2 N D IH0 D'],
  "overfunding": ['OW1 V ER0 F AH2 N D IH0 NG'],
  "overgaard": ['OW1 V ER0 G AA2 R D'],
  "overgenerous": ['OW1 V ER0 JH EH2 N ER0 AH0 S'],
  "overgrazing": ['OW1 V ER0 G R EY2 Z IH0 NG'],
  "overgrowing": ['OW2 V ER0 G R OW1 W IH0 NG'],
  "overgrown": ['OW2 V ER0 G R OW1 N'],
  "overgrowth": ['OW1 V ER0 G R OW0 TH'],
  "overhang": ['OW1 V ER0 HH AE2 NG'],
  "overhanging": ['OW1 V ER0 HH AE2 NG IH0 NG'],
  "overhangs": ['OW1 V ER0 HH AE2 NG Z'],
  "overhaul": ['OW1 V ER0 HH AO2 L'],
  "overhauled": ['OW1 V ER0 HH AO2 L D'],
  "overhauling": ['OW1 V ER0 HH AO2 L IH0 NG'],
  "overhauls": ['OW1 V ER0 HH AO2 L Z'],
  "overhead": ['OW1 V ER0 HH EH1 D'],
  "overheads": ['OW1 V ER0 HH EH2 D Z'],
  "overhear": ['OW1 V ER0 HH IY1 R'],
  "overheard": ['OW1 V ER0 HH ER1 D'],
  "overhearing": ['OW2 V ER0 HH IY1 R IH0 NG'],
  "overhears": ['OW1 V ER0 HH IY1 R Z'],
  "overheat": ['OW1 V ER0 HH IY2 T'],
  "overheated": ['OW1 V ER0 HH IY2 T IH0 D'],
  "overheating": ['OW1 V ER0 HH IY2 T IH0 NG'],
  "overheats": ['OW1 V ER0 HH IY2 T S'],
  "overholser": ['OW1 V ER0 HH OW2 L S ER0'],
  "overholt": ['OW1 V ER0 HH OW0 L T'],
  "overholtzer": ['OW1 V ER0 HH OW0 L T Z ER0'],
  "overhung": ['OW0 V ER0 HH AH1 NG'],
  "overjoyed": ['OW2 V ER0 JH OY1 D'],
  "overkill": ['OW1 V ER0 K IH2 L'],
  "overkilling": ['OW1 V ER0 K IH2 L IH0 NG'],
  "overlaid": ['OW1 V ER0 L EY2 D'],
  "overlain": ['OW1 V ER0 L EY2 N'],
  "overland": ['OW1 V ER0 L AE2 N D', 'OW1 V ER0 L AH0 N D'],
  "overlap": ['OW1 V ER0 L AE2 P'],
  "overlapped": ['OW1 V ER0 L AE2 P T'],
  "overlapping": ['OW1 V ER0 L AE2 P IH0 NG'],
  "overlaps": ['OW1 V ER0 L AE2 P S'],
  "overlay": ['OW1 V ER0 L EY2'],
  "overlays": ['OW1 V ER0 L EY2 Z'],
  "overleverage": ['OW2 V ER0 L EH1 V R IH0 JH'],
  "overleveraged": ['OW1 V ER0 L EH1 V R IH0 JH D'],
  "overley": ['OW1 V ER0 L IY0'],
  "overload": ['OW1 V ER0 L OW2 D'],
  "overloaded": ['OW1 V ER0 L OW2 D IH0 D'],
  "overloading": ['OW1 V ER0 L OW2 D IH0 NG'],
  "overloads": ['OW1 V ER0 L OW2 D Z'],
  "overlock": ['OW1 V ER0 L AA2 K'],
  "overlook": ['OW1 V ER0 L UH2 K'],
  "overlooked": ['OW1 V ER0 L UH2 K T'],
  "overlooking": ['OW1 V ER0 L UH2 K IH0 NG'],
  "overlooks": ['OW1 V ER0 L UH2 K S'],
  "overlord": ['OW1 V ER0 L AO2 R D'],
  "overlords": ['OW1 V ER0 L AO2 R D Z'],
  "overly": ['OW1 V ER0 L IY0'],
  "overlying": ['OW2 V ER0 L AY1 IH0 NG'],
  "overman": ['OW1 V ER0 M AH0 N'],
  "overmatch": ['OW2 V ER0 M AE1 CH'],
  "overmatched": ['OW1 V ER0 M AE1 CH T'],
  "overmyer": ['OW1 V ER0 M IY0 ER0'],
  "overnight": ['OW1 V ER0 N AY1 T'],
  "overnighter": ['OW2 V ER0 N AY1 T ER0'],
  "overnighters": ['OW2 V ER0 N AY1 T ER0 Z'],
  "overnite": ['OW1 V ER0 N AY1 T'],
  "overoptimism": ['OW2 V ER0 AA1 P T IH0 M IH2 Z AH0 M'],
  "overpaid": ['OW1 V ER0 P EY1 D'],
  "overpass": ['OW1 V ER0 P AE2 S'],
  "overpasses": ['OW1 V ER0 P AE2 S IH0 Z'],
  "overpay": ['OW1 V ER0 P EY2'],
  "overpaying": ['OW1 V ER0 P EY2 IH0 NG'],
  "overpayment": ['OW1 V ER0 P EY2 M AH0 N T'],
  "overpayments": ['OW1 V ER0 P EY2 M AH0 N T S'],
  "overpeck": ['OW1 V ER0 P EH2 K'],
  "overplay": ['OW1 V ER0 P L EY1'],
  "overplayed": ['OW1 V ER0 P L EY1 D'],
  "overplaying": ['OW1 V ER0 P L EY1 IH0 NG'],
  "overplays": ['OW1 V ER0 P L EY1 Z'],
  "overpopulate": ['OW2 V ER0 P AA1 P Y AH0 L EY0 T'],
  "overpopulated": ['OW2 V ER0 P AA1 P Y AH0 L EY0 T IH0 D'],
  "overpopulates": ['OW2 V ER0 P AA1 P Y AH0 L EY0 T S'],
  "overpopulating": ['OW2 V ER0 P AA1 P Y AH0 L EY0 T IH0 NG'],
  "overpopulation": ['OW2 V ER0 P AA2 P Y AH0 L EY1 SH AH0 N'],
  "overpower": ['OW2 V ER0 P AW1 ER0'],
  "overpowered": ['OW2 V ER0 P AW1 ER0 D'],
  "overpowering": ['OW1 V ER0 P AW1 R IH0 NG'],
  "overpowers": ['OW2 V ER0 P AW1 ER0 Z'],
  "overprice": ['OW2 V ER0 P R AY1 S'],
  "overpriced": ['OW1 V ER0 P R AY2 S T'],
  "overprices": ['OW2 V ER0 P R AY1 S IH0 Z'],
  "overpricing": ['OW2 V ER0 P R AY1 S IH0 NG'],
  "overproduce": ['OW1 V ER0 P R AH0 D UW1 S'],
  "overproduced": ['OW1 V ER0 P R AH0 D UW1 S T'],
  "overproducer": ['OW1 V ER0 P R AH0 D UW1 S ER0'],
  "overproducers": ['OW1 V ER0 P R AH0 D UW1 S ER0 Z'],
  "overproducing": ['OW2 V ER0 P R AH0 D Y UW1 S IH0 NG'],
  "overproduction": ['OW1 V ER0 P R AH0 D AH1 K SH AH0 N'],
  "overprotect": ['OW2 V ER0 P R AH0 T EH1 K T'],
  "overprotected": ['OW2 V ER0 P R AH0 T EH1 K T IH0 D'],
  "overprotection": ['OW2 V ER0 P R AH0 T EH1 K SH AH0 N'],
  "overprotective": ['OW2 V ER0 P R AH0 T EH1 K T AH0 V'],
  "overqualified": ['OW1 V ER0 K W AA2 L AH0 F AY2 D'],
  "overqualify": ['OW1 V ER0 K W AA2 L IH0 F AY2'],
  "overran": ['OW1 V ER0 R AE1 N'],
  "overrate": ['OW2 V ER0 R EY1 T'],
  "overrated": ['OW2 V ER0 R EY1 T IH0 D'],
  "overrates": ['OW2 V ER0 R EY1 T S'],
  "overrating": ['OW2 V ER0 R EY1 T IH0 NG'],
  "overreach": ['OW1 V ER0 R IY2 CH'],
  "overreached": ['OW1 V ER0 R IY2 CH T'],
  "overreaches": ['OW1 V ER0 R IY2 CH IH0 Z'],
  "overreaching": ['OW1 V ER0 R IY2 CH IH0 NG'],
  "overreact": ['OW1 V ER0 R IY0 AE1 K T'],
  "overreacted": ['OW1 V ER0 R IY0 AE2 K T IH0 D'],
  "overreacting": ['OW1 V ER0 R IY0 AE2 K T IH0 NG'],
  "overreaction": ['OW1 V ER0 R IY0 AE2 K SH AH0 N'],
  "overreacts": ['OW1 V ER0 R IY0 AE1 K T S'],
  "overregulate": ['OW1 V ER0 R EH1 G Y AH0 L EY2 T'],
  "overregulated": ['OW2 V ER0 R EH1 G Y AH0 L EY2 T IH0 D'],
  "overregulates": ['OW1 V ER0 R EH1 G Y AH0 L EY2 T S'],
  "overregulation": ['OW2 V ER0 R EH2 G Y AH0 L EY1 SH AH0 N'],
  "overreliance": ['OW1 V ER0 R IH0 L AY2 AH0 N S'],
  "overrepresent": ['OW1 V ER0 R EH2 P R AH0 Z EH1 N T'],
  "overrepresented": ['OW1 V ER0 R EH2 P R AH0 Z EH1 N T IH0 D'],
  "overridden": ['OW1 V ER0 R IH1 D AH0 N'],
  "override": ['OW1 V ER0 R AY2 D'],
  "overrides": ['OW1 V ER0 R AY2 D Z'],
  "overriding": ['OW1 V ER0 R AY2 D IH0 NG'],
  "overripe": ['OW1 V ER0 R AY1 P'],
  "overrode": ['OW1 V ER0 R OW1 D'],
  "overrule": ['OW1 V ER0 R UW2 L'],
  "overruled": ['OW2 V ER0 R UW1 L D'],
  "overrules": ['OW1 V ER0 R UW2 L Z'],
  "overruling": ['OW1 V ER0 R UW2 L IH0 NG'],
  "overrun": ['OW1 V ER0 R AH2 N'],
  "overrunning": ['OW1 V ER0 R AH2 N IH0 NG'],
  "overruns": ['OW1 V ER0 R AH2 N Z'],
  "overs": ['OW1 V ER0 Z'],
  "oversaw": ['OW1 V ER0 S AO2'],
  "oversea": ['OW2 V ER0 S IY1'],
  "overseas": ['OW1 V ER0 S IY1 Z'],
  "oversee": ['OW1 V ER0 S IY2'],
  "overseeing": ['OW1 V ER0 S IY2 IH0 NG'],
  "overseen": ['OW1 V ER0 S IY2 N'],
  "overseer": ['OW1 V ER0 S IY1 ER0'],
  "overseers": ['OW2 V ER0 S IY1 ER0 Z'],
  "oversees": ['OW1 V ER0 S IY2 Z'],
  "oversell": ['OW1 V ER0 S EH2 L'],
  "overselling": ['OW1 V ER0 S EH2 L IH0 NG'],
  "oversensitive": ['OW2 V ER0 S EH1 N S AH0 T IH0 V'],
  "oversensitivity": ['OW2 V ER0 S EH0 N S AH0 T IH1 V IH0 T IY0'],
  "overshadow": ['OW1 V ER0 SH AE1 D OW0'],
  "overshadowed": ['OW2 V ER0 SH AE1 D OW0 D'],
  "overshadowing": ['OW1 V ER0 SH AE1 D OW0 IH0 NG'],
  "overshadows": ['OW1 V ER0 SH AE1 D OW0 Z'],
  "overshoot": ['OW1 V ER0 SH UW2 T'],
  "overshooting": ['OW1 V ER0 SH UW2 T IH0 NG'],
  "overshot": ['OW1 V ER0 SH AA2 T'],
  "oversight": ['OW1 V ER0 S AY2 T'],
  "oversimplification": ['OW0 V ER0 S IH1 M P L IH0 F IH0 K EY2 SH AH0 N'],
  "oversimplified": ['OW0 V ER0 S IH1 M P L IH0 F AY2 D'],
  "oversimplify": ['OW0 V ER0 S IH1 M P L IH0 F AY2'],
  "oversimplifying": ['OW0 V ER0 S IH1 M P L IH0 F AY2 IH0 NG'],
  "oversize": ['OW2 V ER0 S AY1 Z'],
  "oversized": ['OW1 V ER0 S AY2 Z D'],
  "oversizes": ['OW2 V ER0 S AY1 Z IH0 Z'],
  "oversizing": ['OW2 V ER0 S AY1 Z IH0 NG'],
  "oversleep": ['OW2 V ER0 S L IY1 P'],
  "overslept": ['OW2 V ER0 S L EH1 P T'],
  "oversold": ['OW1 V ER0 S OW1 L D'],
  "overson": ['OW1 V ER0 S AH0 N'],
  "overspend": ['OW1 V ER0 S P EH2 N D'],
  "overspending": ['OW1 V ER0 S P EH2 N D IH0 NG'],
  "overspends": ['OW1 V ER0 S P EH2 N D Z'],
  "overspent": ['OW1 V ER0 S P EH1 N T'],
  "overstaff": ['OW1 V ER0 S T AE2 F'],
  "overstaffed": ['OW1 V ER0 S T AE2 F T'],
  "overstate": ['OW1 V ER0 S T EY2 T'],
  "overstated": ['OW1 V ER0 S T EY2 T IH0 D'],
  "overstatement": ['OW1 V ER0 S T EY2 T M AH0 N T'],
  "overstatements": ['OW1 V ER0 S T EY2 T M AH0 N T S'],
  "overstates": ['OW1 V ER0 S T EY2 T S'],
  "overstating": ['OW1 V ER0 S T EY2 T IH0 NG'],
  "overstay": ['OW2 V ER0 S T EY1'],
  "overstayed": ['OW2 V ER0 S T EY1 D'],
  "overstep": ['OW1 V ER0 S T EH2 P'],
  "overstepped": ['OW1 V ER0 S T EH2 P T'],
  "overstepping": ['OW1 V ER0 S T EH2 P IH0 NG'],
  "overstock": ['OW1 V ER0 S T AA1 K'],
  "overstocked": ['OW1 V ER0 S T AA1 K T'],
  "overstreet": ['OW1 V ER0 S T R IY2 T'],
  "overstrom": ['OW1 V ER0 S T R AA1 M'],
  "overstuff": ['OW1 V ER0 S T AH2 F'],
  "overstuffed": ['OW1 V ER0 S T AH2 F T'],
  "oversubscribe": ['OW2 V ER0 S AH0 B S K R AY1 B'],
  "oversubscribed": ['OW2 V ER0 S AH0 B S K R AY1 B D'],
  "oversupplied": ['OW2 V ER0 S AH0 P L AY1 D'],
  "oversupply": ['OW2 V ER0 S AH0 P L AY1'],
  "overt": ['OW0 V ER1 T', 'OW1 V ER0 T'],
  "overtake": ['OW1 V ER0 T EY2 K'],
  "overtaken": ['OW1 V ER0 T EY2 K AH0 N'],
  "overtakes": ['OW1 V ER0 T EY2 K S'],
  "overtaking": ['OW1 V ER0 T EY2 K IH0 NG'],
  "overtax": ['OW1 V ER0 T AE2 K S'],
  "overtaxed": ['OW2 V ER0 T AE1 K S T'],
  "overthrew": ['OW2 V ER0 TH R UW1'],
  "overthrow": ['OW1 V ER0 TH R OW2'],
  "overthrowing": ['OW1 V ER0 TH R OW2 IH0 NG'],
  "overthrown": ['OW2 V ER0 TH R OW1 N'],
  "overthrows": ['OW1 V ER0 TH R OW2 Z'],
  "overtime": ['OW1 V ER0 T AY2 M'],
  "overtly": ['OW0 V ER1 T L IY0'],
  "overton": ['OW1 V ER0 T AH0 N'],
  "overtone": ['OW1 V ER0 T OW2 N'],
  "overtones": ['OW1 V ER0 T OW2 N Z'],
  "overtook": ['OW2 V ER0 T UH1 K'],
  "overtrain": ['OW0 V ER0 T R EY1 N'],
  "overtraining": ['OW0 V ER0 T R EY1 N IH0 NG'],
  "overture": ['OW1 V ER0 CH ER0'],
  "overtures": ['OW1 V ER0 CH UH2 R Z'],
  "overturf": ['OW1 V ER0 T ER2 F'],
  "overturn": ['OW1 V ER0 T ER2 N'],
  "overturned": ['OW1 V ER0 T ER2 N D'],
  "overturning": ['OW1 V ER0 T ER2 N IH0 NG'],
  "overturns": ['OW1 V ER0 T ER2 N Z'],
  "overuse": ['OW2 V ER0 Y UW1 Z'],
  "overused": ['OW2 V ER0 Y UW1 Z D'],
  "overuses": ['OW2 V ER0 Y UW1 Z IH0 Z'],
  "overusing": ['OW2 V ER0 Y UW1 Z IH0 NG'],
  "overvaluation": ['OW2 V ER0 V AE2 L Y UW0 EY1 SH AH0 N'],
  "overvalue": ['OW1 V ER0 V AE2 L Y UW0'],
  "overvalued": ['OW1 V ER0 V AE2 L Y UW0 D'],
  "overview": ['OW1 V ER0 V Y UW2'],
  "overviews": ['OW1 V ER0 V Y UW2 Z'],
  "overweight": ['OW2 V ER0 W EY1 T'],
  "overweighted": ['OW2 V ER0 W EY1 T IH0 D'],
  "overwhelm": ['OW2 V ER0 W EH1 L M', 'OW2 V ER0 HH W EH1 L M'],
  "overwhelmability": ['OW2 V ER0 W EH1 L M AH0 B IH1 L AH0 T IY0', 'OW2 V ER0 HH W EH0 L M AH0 B IH1 L AH0 T IY0'],
  "overwhelmed": ['OW2 V ER0 W EH1 L M D', 'OW2 V ER0 HH W EH1 L M D'],
  "overwhelming": ['OW2 V ER0 W EH1 L M IH0 NG', 'OW2 V ER0 HH W EH1 L M IH0 NG'],
  "overwhelmingly": ['OW2 V ER0 W EH1 L M IH0 NG L IY0', 'OW2 V ER0 HH W EH1 L M IH0 NG L IY0'],
  "overwhelms": ['OW2 V ER0 W EH1 L M Z', 'OW2 V ER0 HH W EH1 L M Z'],
  "overwinter": ['OW2 V ER0 W IH1 N T ER0'],
  "overwork": ['OW2 V ER0 W ER1 K'],
  "overworked": ['OW2 V ER0 W ER1 K T'],
  "overworking": ['OW2 V ER0 W ER1 K IH0 NG'],
  "overworks": ['OW2 V ER0 W ER1 K S'],
  "overwrite": ['OW2 V ER0 R AY1 T'],
  "overwrites": ['OW2 V ER0 R AY1 T S'],
  "overwriting": ['OW2 V ER0 R AY1 T IH0 NG'],
  "overwritten": ['OW2 V ER0 R IH1 T AH0 N'],
  "overwrote": ['OW2 V ER0 R OW1 T'],
  "overwrought": ['OW2 V ER0 R AO1 T'],
  "overy": ['OW1 V ER0 IY0'],
  "overzealous": ['OW2 V ER0 Z EH1 L AH0 S'],
  "overzealously": ['OW2 V ER0 Z EH1 L AH0 S L IY0'],
  "oveson": ['AA1 V AH0 S AH0 N'],
  "ovett": ['OW2 V EH1 T'],
  "ovett's": ['OW2 V EH1 T S'],
  "ovex": ['OW1 V EH0 K S'],
  "oviatt": ['OW1 V IY0 AA2 T'],
  "oviatt's": ['OW1 V IY0 AA2 T S'],
  "oviedo": ['OW0 V IY0 EY1 D OW0'],
  "oviparous": ['OW0 V IH1 P ER0 AH0 S'],
  "ovitt": ['OW1 V IH0 T'],
  "ovitt's": ['OW1 V IH0 T S'],
  "ovitz": ['OW0 V IH1 T S'],
  "ovitz's": ['OW0 V IH1 T S IH0 Z'],
  "ovoid": ['OW1 V OY2 D'],
  "ovonic": ['OW0 V AA1 N IH0 K'],
  "ovoviviparous": ['OW2 V OW2 V AY2 V IH1 P ER0 AH0 S'],
  "ovshinsky": ['AA0 V SH IH1 N S K IY0'],
  "ovulation": ['OW2 V Y AH0 L EY1 SH AH0 N'],
  "ovulations": ['OW2 V Y AH0 L EY1 SH AH0 N Z'],
  "ovule": ['OW1 V Y UW0 L'],
  "ovules": ['OW1 V Y UW0 L Z'],
  "ovum": ['OW1 V AH0 M'],
  "ow": ['OW1', 'AW1'],
  "owada": ['OW0 AA1 D AH0'],
  "owades": ['OW0 AA1 D Z'],
  "owczarzak": ['AW0 CH AA1 R Z AH0 K'],
  "owe": ['OW1'],
  "owed": ['OW1 D'],
  "owen": ['OW1 AH0 N'],
  "owen's": ['OW1 AH0 N Z'],
  "owenby": ['AW1 IH0 N B IY0'],
  "owenby's": ['AW1 IH0 N B IY0 Z'],
  "owens": ['OW1 AH0 N Z'],
  "owens's": ['OW1 AH0 N Z IH0 Z'],
  "owensboro": ['OW1 AH0 N Z B ER0 OW0'],
  "owensby": ['AW1 IH0 N S B IY0'],
  "owensby's": ['AW1 IH0 N S B IY0 Z'],
  "owes": ['OW1 Z'],
  "owing": ['OW1 IH0 NG'],
  "owings": ['OW1 IH0 NG Z'],
  "owl": ['AW1 L'],
  "owls": ['AW1 L Z'],
  "own": ['OW1 N'],
  "ownbey": ['AW1 N B IY0'],
  "ownby": ['AW1 N B IY0'],
  "owned": ['OW1 N D'],
  "owner": ['OW1 N ER0'],
  "owner's": ['OW1 N ER0 Z'],
  "owners": ['OW1 N ER0 Z'],
  "owners'": ['OW1 N ER0 Z'],
  "ownership": ['OW1 N ER0 SH IH2 P'],
  "owning": ['OW1 N IH0 NG'],
  "owns": ['OW1 N Z'],
  "owosso": ['OW0 AO1 S OW0'],
  "owsley": ['AW1 S L IY0'],
  "owyhee": ['OW1 IH0 HH IY0'],
  "ox": ['AA1 K S'],
  "oxalates": ['AA1 K S AH0 L EY2 T S'],
  "oxalis": ['AA1 K S AH0 L AH0 S'],
  "oxbow": ['AA1 K S B OW2'],
  "oxbridge": ['AA1 K S B R IH2 JH'],
  "oxcart": ['AA1 K S K AA2 R T'],
  "oxdon": ['AA1 K S D AA2 N'],
  "oxen": ['AA1 K S AH0 N'],
  "oxendine": ['AA1 K S IH0 N D AY0 N'],
  "oxfam": ['AA1 K S F AE2 M'],
  "oxfam's": ['AA1 K S F AE2 M Z'],
  "oxford": ['AA1 K S F ER0 D'],
  "oxford's": ['AA1 K S F ER0 D Z'],
  "oxfords": ['AA1 K S F ER0 D Z'],
  "oxidant": ['AA1 K S AH0 D AH0 N T'],
  "oxidants": ['AA1 K S IH0 D AH0 N T S'],
  "oxidase": ['AA1 K S IH0 D EY2 Z'],
  "oxidation": ['AA2 K S AH0 D EY1 SH AH0 N'],
  "oxidative": ['AA2 K S AH0 D AY1 T IH0 V'],
  "oxide": ['AA1 K S AY2 D'],
  "oxides": ['AA1 K S AY2 D Z'],
  "oxidize": ['AA1 K S AH0 D AY2 Z'],
  "oxidized": ['AA1 K S AH0 D AY2 Z D'],
  "oxidizer": ['AA1 K S IH0 D AY2 Z ER0'],
  "oxidizing": ['AA1 K S AH0 D AY2 Z IH0 NG'],
  "oxidyne": ['AA1 K S IH0 D AY2 N'],
  "oxley": ['AA1 K S L IY0'],
  "oxman": ['AA1 K S M AH0 N'],
  "oxnard": ['AA1 K S N ER0 D'],
  "oxner": ['AA1 K S N ER0'],
  "oxoco": ['AA0 K S OW1 K OW0'],
  "oxton": ['AA1 K S T AH0 N'],
  "oxy": ['AA1 K S IY0'],
  "oxydative": ['AA2 K S AH0 D AY1 T IH0 V'],
  "oxygen": ['AA1 K S AH0 JH AH0 N', 'AA1 K S IH0 JH AH0 N'],
  "oxygenate": ['AA1 K S AH0 JH AH0 N EY2 T'],
  "oxygenated": ['AA1 K S AH0 JH AH0 N EY2 T IH0 D'],
  "oxymoron": ['AA2 K S IY1 M AO1 R AA0 N'],
  "oxytocin": ['AA2 K S AH0 T OW1 S AH0 N'],
  "oy": ['OY1'],
  "oyama": ['OW0 Y AA1 M AH0'],
  "oye": ['OY1'],
  "oyen": ['OY1 IH0 N'],
  "oyer": ['OY1 ER0'],
  "oyler": ['OY1 L ER0'],
  "oyola": ['OY2 OW1 L AH0'],
  "oyster": ['OY1 S T ER0'],
  "oysters": ['OY1 S T ER0 Z'],
  "oz": ['AA1 Z'],
  "ozaki": ['OW0 Z AA1 K IY0'],
  "ozal": ['OW1 Z AH0 L'],
  "ozal's": ['OW0 Z AA1 L Z'],
  "ozanich": ['AH0 Z AE1 N IH0 HH'],
  "ozanne": ['OW1 Z AE0 N'],
  "ozark": ['OW1 Z AA2 R K'],
  "ozarks": ['OW1 Z AA2 R K S'],
  "ozawa": ['OW0 Z AA1 W AH0'],
  "ozbun": ['AA1 Z B AH0 N'],
  "ozburn": ['AA1 Z B ER0 N'],
  "ozelle": ['AH0 Z EH1 L'],
  "ozga": ['OW1 Z G AH0'],
  "ozick": ['OW1 Z IH0 K'],
  "ozick's": ['OW1 Z IH0 K S'],
  "ozier": ['OW1 Z IY0 ER0'],
  "ozimek": ['AH0 Z IH1 M EH0 K'],
  "ozment": ['AA1 Z M AH0 N T'],
  "ozmet": ['AA1 Z M AH0 T'],
  "ozmun": ['AA1 Z M AH0 N'],
  "ozolins": ['OW0 Z OW1 L IY0 N Z'],
  "ozols": ['OW1 Z OW0 L Z'],
  "ozone": ['OW1 Z OW2 N'],
  "ozora": ['AH0 Z AO1 R AH0'],
  "ozuna": ['OW0 Z UW1 N AH0'],
  "ozzie": ['AA1 Z IY2'],
  "ozzy": ['AA1 Z IY2'],
  "p": ['P IY1'],
  "p's": ['P IY1 Z'],
  "p.": ['P IY1'],
  "p.'s": ['P IY1 Z'],
  "p.m.": ['P IY1 EH1 M'],
  "p.s": ['P IY1 Z'],
  "pa": ['P AA1'],
  "paap": ['P AA1 P'],
  "paape": ['P AA1 P'],
  "paar": ['P AA1 R'],
  "paasch": ['P AA1 SH'],
  "paavola": ['P AA2 V OW1 L AH0'],
  "pablo": ['P AA1 B L OW0'],
  "pablum": ['P AE1 B L AH0 M'],
  "pabon": ['P AA0 B AO1 N'],
  "pabst": ['P AE1 B S T'],
  "pac": ['P AE1 K'],
  "pac's": ['P AE1 K S'],
  "paca": ['P AA1 K AH0', 'P AE1 K AH0'],
  "paccar": ['P AH0 K AA1 R'],
  "paccione": ['P AA0 K CH OW1 N IY0'],
  "pace": ['P EY1 S'],
  "pace's": ['P EY1 S IH0 Z'],
  "paced": ['P EY1 S T'],
  "pacella": ['P AH0 S EH1 L AH0'],
  "pacelli": ['P AH0 S EH1 L IY0'],
  "pacemaker": ['P EY1 S M EY2 K ER0'],
  "pacemakers": ['P EY1 S M EY2 K ER0 Z'],
  "pacer": ['P EY1 S ER0'],
  "pacers": ['P EY1 S ER0 Z'],
  "paces": ['P EY1 S IH0 Z'],
  "pacesetter": ['P EY1 S EH2 T ER0'],
  "paceway": ['P EY1 S W EY2'],
  "pacey": ['P EY1 S IY0'],
  "pacheco": ['P AH0 CH EH1 K OW0'],
  "pachinko": ['P AH0 CH IH1 NG K OW0'],
  "pacholder": ['P AE1 CH AH0 L D ER0'],
  "pacholski": ['P AH0 HH OW1 L S K IY0'],
  "pachter": ['P AE1 K T ER0'],
  "pachysandra": ['P AE2 CH IH0 S AE1 N D R AH0'],
  "paci": ['P AA1 CH IY0'],
  "pacific": ['P AH0 S IH1 F IH0 K'],
  "pacific's": ['P AH0 S IH1 F IH0 K S'],
  "pacifica": ['P AH0 S IH1 F IH0 K AH0'],
  "pacificare": ['P AH0 S IH1 F IH0 K EH2 R'],
  "pacification": ['P AE2 S AH0 F AH0 K EY1 SH AH0 N'],
  "pacifico": ['P AA0 CH IY0 F IY1 K OW0'],
  "pacificorp": ['P AH0 S IH1 F IH0 K AO0 R P'],
  "pacified": ['P AE1 S AH0 F AY2 D'],
  "pacifier": ['P AE1 S AH0 F AY2 ER0'],
  "pacifiers": ['P AE1 S AH0 F AY2 ER0 Z'],
  "pacifism": ['P AE1 S IH0 F IH2 Z AH0 M'],
  "pacifist": ['P AE1 S IH0 F IH0 S T'],
  "pacifists": ['P AE1 S IH0 F IH0 S T S'],
  "pacify": ['P AE1 S AH0 F AY2'],
  "pacing": ['P EY1 S IH0 NG'],
  "pacini": ['P AA0 CH IY1 N IY0'],
  "pacino": ['P AH0 S IY1 N OW0'],
  "pack": ['P AE1 K'],
  "package": ['P AE1 K AH0 JH', 'P AE1 K IH0 JH'],
  "package's": ['P AE1 K IH0 JH IH0 Z'],
  "packaged": ['P AE1 K IH0 JH D'],
  "packager": ['P AE1 K IH0 JH ER0'],
  "packagers": ['P AE1 K IH0 JH ER0 Z'],
  "packages": ['P AE1 K AH0 JH AH0 Z', 'P AE1 K IH0 JH IH0 Z'],
  "packaging": ['P AE1 K IH0 JH IH0 NG'],
  "packard": ['P AE1 K ER0 D'],
  "packard's": ['P AE1 K ER0 D Z'],
  "packed": ['P AE1 K T'],
  "packer": ['P AE1 K ER0'],
  "packers": ['P AE1 K ER0 Z'],
  "packet": ['P AE1 K AH0 T', 'P AE1 K IH0 T'],
  "packets": ['P AE1 K IH0 T S'],
  "packett": ['P AE1 K IH0 T'],
  "packham": ['P AE1 K HH AH0 M'],
  "packing": ['P AE1 K IH0 NG'],
  "packinghouse": ['P AE1 K IH0 NG HH AW2 S'],
  "packman": ['P AE1 K M AH0 N'],
  "packs": ['P AE1 K S'],
  "packwood": ['P AE1 K W UH2 D'],
  "packwood's": ['P AE1 K W UH2 D Z'],
  "pacman": ['P AE1 K M AH0 N'],
  "paco": ['P EY1 K OW0'],
  "pacs": ['P AE1 K S'],
  "pact": ['P AE1 K T'],
  "pact's": ['P AE1 K T S'],
  "pactel": ['P AE1 K T EH2 L'],
  "pactel's": ['P AE1 K T EH2 L Z'],
  "pacto": ['P AE1 K T OW0'],
  "pacts": ['P AE1 K T S', 'P AE1 K S'],
  "pacyna": ['P AA1 K IY0 N AH0', 'P AH0 S IY1 N AH0'],
  "paczkowski": ['P AH0 CH K AO1 F S K IY0'],
  "pad": ['P AE1 D'],
  "padawan": ['P AA1 D AA2 W AH0 N'],
  "paddack": ['P AE1 D AH0 K'],
  "padded": ['P AE1 D IH0 D'],
  "padden": ['P AE1 D AH0 N'],
  "paddies": ['P AE1 D IY0 Z'],
  "padding": ['P AE1 D IH0 NG'],
  "paddington": ['P AE1 D IH0 NG T AH0 N'],
  "paddle": ['P AE1 D AH0 L'],
  "paddled": ['P AE1 D AH0 L D'],
  "paddles": ['P AE1 D AH0 L Z'],
  "paddling": ['P AE1 D AH0 L IH0 NG', 'P AE1 D L IH0 NG'],
  "paddock": ['P AE1 D AH0 K'],
  "paddy": ['P AE1 D IY0'],
  "paddy's": ['P AE1 D IY0 Z'],
  "paden": ['P EY1 D AH0 N'],
  "paderewski": ['P AA2 D ER0 UW1 S K IY0'],
  "padfield": ['P AE1 D F IY2 L D'],
  "padget": ['P AE1 JH IH0 T'],
  "padgett": ['P AE1 JH IH0 T'],
  "padgitt": ['P AE1 JH IH0 T'],
  "padilla": ['P AH0 D IH1 L AH0'],
  "padley": ['P AE1 D L IY0'],
  "padlock": ['P AE1 D L AA2 K'],
  "padlocked": ['P AE1 D L AA2 K T'],
  "padlocks": ['P AE1 D L AA2 K S'],
  "padmanabhan": ['P AE0 D M AE1 N AH0 B AE2 N', 'P AA2 D M AH0 N AA1 B AH0 N'],
  "padovano": ['P AA0 D OW0 V AA1 N OW0'],
  "padre": ['P AE1 D R EY2'],
  "padres": ['P AE1 D R EY2 Z'],
  "padrick": ['P AE1 D R IH0 K'],
  "padro": ['P AA1 D R OW0'],
  "padron": ['P AE1 D R AH0 N'],
  "pads": ['P AE1 D Z'],
  "padua": ['P AE1 D Y UW0 AH0'],
  "paduano": ['P AA0 D UW0 AA1 N OW0'],
  "paducah": ['P AH0 D UW1 K AH0'],
  "padula": ['P AA0 D UW1 L AH0'],
  "pae": ['P AY1'],
  "paean": ['P IY1 AH0 N'],
  "paeans": ['P IY1 AH0 N Z'],
  "paek": ['P IY1 K'],
  "paeth": ['P IY1 TH'],
  "paetz": ['P IY1 T S'],
  "paez": ['P AY0 EH1 Z'],
  "paff": ['P AE1 F'],
  "pafford": ['P AE1 F ER0 D'],
  "pagan": ['P EY1 G AH0 N'],
  "paganelli": ['P AA0 G AA0 N EH1 L IY0'],
  "pagani": ['P AA0 G AA1 N IY0'],
  "paganini": ['P AE2 G AH0 N IY1 N IY0'],
  "paganism": ['P EY1 G AH0 N IH2 Z AH0 M'],
  "pagano": ['P AA0 G AA1 N OW0'],
  "pagar": ['P AE1 G ER0'],
  "page": ['P EY1 JH'],
  "page's": ['P EY1 JH IH0 Z'],
  "pageant": ['P AE1 JH AH0 N T'],
  "pageantry": ['P AE1 JH AH0 N T R IY0'],
  "pageants": ['P AE1 JH AH0 N T S'],
  "paged": ['P EY1 JH D'],
  "pagel": ['P AE1 G AH0 L'],
  "pagels": ['P AE1 G AH0 L Z'],
  "pagemaker": ['P EY1 JH M EY2 K ER0'],
  "pagenkopf": ['P AE1 G AH0 N K AO0 P F', 'P AE1 G AH0 N K AO0 F'],
  "pager": ['P EY1 JH ER0'],
  "pagers": ['P EY1 JH ER0 Z'],
  "pages": ['P EY1 JH AH0 Z', 'P EY1 JH IH0 Z'],
  "paget": ['P AE1 JH AH0 T'],
  "pagett": ['P AE1 JH AH0 T'],
  "pagette": ['P AE2 JH EH1 T'],
  "pagey": ['P EY1 JH IY0'],
  "pagezy": ['P AE2 JH EH1 Z IY0'],
  "pagination": ['P AE2 JH AH0 N EY1 SH AH0 N'],
  "paging": ['P EY1 JH IH0 NG'],
  "paglia": ['P AE1 G L IY0 AH0'],
  "pagliaro": ['P AE2 G L IY0 AA1 R OW0'],
  "pagliarulo": ['P AE2 G L IY0 ER0 UW1 L OW0'],
  "pagliuca": ['P AE2 G L IY0 UW1 K AH0'],
  "pagliuca's": ['P AE2 G L IY0 UW1 K AH0 Z'],
  "pagni": ['P AE1 G N IY0'],
  "pagnotta": ['P AA0 G N OW1 T AH0'],
  "pago-pago": ['P AA2 G OW0 P AA1 G OW0'],
  "pagoda": ['P AH0 G OW1 D AH0'],
  "pagoda's": ['P AH0 G OW1 D AH0 Z'],
  "pagodas": ['P AH0 G OW1 D AH0 Z'],
  "pagurian": ['P AH0 G Y UH1 R IY0 AH0 N'],
  "pah": ['P AE1'],
  "pahl": ['P AA1 L'],
  "pahnos": ['P AA1 N OW0 S'],
  "pai": ['P AA1 IY0'],
  "paid": ['P EY1 D'],
  "paige": ['P EY1 JH'],
  "paighton": ['P EY1 T AH0 N'],
  "paign": ['P EY1 N'],
  "paik": ['P EY1 K'],
  "pail": ['P EY1 L'],
  "paille": ['P EY1 L'],
  "pails": ['P EY1 L Z'],
  "pain": ['P EY1 N'],
  "paine": ['P EY1 N'],
  "pained": ['P EY1 N D'],
  "painesville": ['P EY1 N Z V IH2 L'],
  "painewebber": ['P EY1 N W EH1 B ER0'],
  "painewebber's": ['P EY1 N W EH1 B ER0 Z'],
  "painful": ['P EY1 N F AH0 L'],
  "painfully": ['P EY1 N F AH0 L IY0'],
  "painkiller": ['P EY1 N K IH2 L ER0'],
  "painkillers": ['P EY1 N K IH2 L ER0 Z'],
  "painless": ['P EY1 N L AH0 S'],
  "painlessly": ['P EY1 N L AH0 S L IY0'],
  "paino": ['P EY1 N OW0'],
  "pains": ['P EY1 N Z'],
  "painstaking": ['P EY1 N S T EY2 K IH0 NG'],
  "painstakingly": ['P EY1 N S T EY2 K IH0 NG L IY0'],
  "paint": ['P EY1 N T'],
  "paintball": ['P EY1 N T B AA2 L'],
  "paintbrush": ['P EY1 N T B R AH2 SH'],
  "paintbrushes": ['P EY1 N T B R AH2 SH IH0 S'],
  "painted": ['P EY1 N T IH0 D'],
  "painter": ['P EY1 N T ER0'],
  "painter's": ['P EY1 N T ER0 Z'],
  "painterly": ['P EY1 N T ER0 L IY0'],
  "painters": ['P EY1 N T ER0 Z'],
  "painting": ['P EY1 N T IH0 NG'],
  "paintings": ['P EY1 N T IH0 NG Z'],
  "paints": ['P EY1 N T S'],
  "pair": ['P EH1 R'],
  "paired": ['P EH1 R D'],
  "pairing": ['P EH1 R IH0 NG'],
  "pairs": ['P EH1 R Z'],
  "pais": ['P EY1 Z'],
  "paisley": ['P EY1 Z L IY0'],
  "paisley's": ['P EY1 Z L IY0 Z'],
  "pait": ['P EY1 T'],
  "paiute": ['P AY1 Y UW0 T'],
  "paiva": ['P EY1 V AH0'],
  "paiz": ['P EY1 Z'],
  "pajak": ['P AY1 AH0 K'],
  "pajama": ['P AH0 JH AA1 M AH0', 'P AH0 JH AE1 M AH0'],
  "pajamas": ['P AH0 JH AA1 M AH0 Z', 'P AH0 JH AE1 M AH0 Z'],
  "pajole": ['P AH0 JH OW1 L'],
  "pajoli": ['P AH0 JH OW1 L IY0'],
  "pak": ['P AE1 K'],
  "pak's": ['P AE1 K S'],
  "pake": ['P EY1 K'],
  "pakeha": ['P AH0 K EY1 HH AA0'],
  "pakistan": ['P AE1 K IH0 S T AE2 N'],
  "pakistan's": ['P AE1 K IH0 S T AE2 N Z'],
  "pakistani": ['P AE2 K IH0 S T AE1 N IY0'],
  "pakistanian": ['P AE2 K IH0 S T AE1 N IY0 AH0 N'],
  "pakistanis": ['P AE2 K IH0 S T AE1 N IY0 Z'],
  "pakula": ['P AH0 K UW1 L AH0'],
  "pakulski": ['P AH0 K AH1 L S K IY0'],
  "pal": ['P AE1 L'],
  "pal's": ['P AE1 L Z'],
  "palace": ['P AE1 L AH0 S'],
  "palace's": ['P AE1 L AH0 S AH0 Z', 'P AE1 L AH0 S IH0 Z'],
  "palaces": ['P AE1 L AH0 S AH0 Z', 'P AE1 L AH0 S IH0 Z'],
  "palacio": ['P AH0 L EY1 S IY0 OW0'],
  "palacios": ['P AA0 L AA0 S IY1 OW0 Z'],
  "paladin": ['P AE1 L AH0 D IH0 N'],
  "paladino": ['P AA0 L AA0 D IY1 N OW0'],
  "palafox": ['P AE1 L AH0 F AA2 K S'],
  "palais": ['P AH0 L EY1'],
  "palance": ['P AE1 L AH0 N S'],
  "palansky": ['P AH0 L AE1 N S K IY0'],
  "palardy": ['P AH0 L AA1 R D IY0'],
  "palash": ['P AH0 L AE1 SH'],
  "palatability": ['P AE2 L AH0 T AH0 B IH1 L AH0 T IY0'],
  "palatable": ['P AE1 L AH0 T AH0 B AH0 L'],
  "palate": ['P AE1 L AH0 T', 'P AE1 L IH0 T'],
  "palates": ['P AE1 L AH0 T S'],
  "palatial": ['P AH0 L EY1 SH AH0 L'],
  "palatine": ['P AE1 L AH0 T AY2 N'],
  "palau": ['P AE1 L AW0'],
  "palau's": ['P AH0 L AW1 UW0 Z'],
  "palauans": ['P AH0 L AW1 AH0 N Z'],
  "palay": ['P EY1 L EY2'],
  "palazina": ['P AE2 L AH0 Z IY1 N AH0'],
  "palazzi": ['P AA0 L AA1 T S IY0'],
  "palazzo": ['P AH0 L AA1 Z OW0'],
  "palazzola": ['P AA0 L AA0 T S OW1 L AH0'],
  "palazzolo": ['P AA0 L AA0 T S OW1 L OW0'],
  "palca": ['P AE1 L K AH0'],
  "palca's": ['P AE1 L K AH0 Z'],
  "palco": ['P AE1 L K OW0'],
  "paldon": ['P AA1 L D AH0 N'],
  "pale": ['P EY1 L'],
  "palecek": ['P AA1 L IH0 CH EH0 K'],
  "paled": ['P EY1 L D'],
  "palen": ['P AE1 L AH0 N'],
  "paleo": ['P EY1 L IY0 OW0'],
  "paleobotany": ['P EY2 L IY0 OW0 B AA1 T AH0 N IY0'],
  "paleocene": ['P EY1 L IY0 AH0 S IY2 N'],
  "paleontologist": ['P EY2 L IY0 AH0 N T AA1 L AH0 JH IH0 S T'],
  "paleontologists": ['P EY2 L IY0 AH0 N T AA1 L AH0 JH IH0 S T S'],
  "paleontology": ['P EY2 L IY0 AH0 N T AA1 L AH0 JH IY0'],
  "paleozoic": ['P EY2 L IY0 AH0 Z OW1 IH0 K'],
  "palermo": ['P AH0 L EH1 R M OW0'],
  "pales": ['P EY1 L Z'],
  "palese": ['P AA0 L EY1 Z IY0'],
  "palest": ['P EY1 L AH0 S T'],
  "palestine": ['P AE1 L AH0 S T AY2 N'],
  "palestinian": ['P AE2 L IH0 S T IH1 N IY0 AH0 N'],
  "palestinian's": ['P AE2 L IH0 S T IH1 N IY0 AH0 N Z'],
  "palestinians": ['P AE2 L IH0 S T IH1 N IY0 AH0 N Z'],
  "palestinians'": ['P AE2 L AH0 S T IH1 N IY0 AH0 N Z'],
  "palette": ['P AE1 L AH0 T'],
  "paley": ['P EY1 L IY0'],
  "palfrey": ['P AE1 L F R IY0'],
  "palimony": ['P AE1 L IH0 M OW2 N IY0'],
  "palimpsest": ['P AE1 L IH0 S EH2 S T'],
  "palin": ['P AE1 L IH0 N'],
  "palinkas": ['P AE1 L IH0 NG K AH0 Z'],
  "palisade": ['P AE2 L IH0 S EY1 D'],
  "palisades": ['P AE2 L IH0 S EY1 D Z'],
  "palka": ['P AE1 L K AH0'],
  "palkar": ['P AE1 L K AA0 R'],
  "palko": ['P AE1 L K OW0'],
  "palkovic": ['P AH0 L K AA1 V IH0 K'],
  "pall": ['P AA1 L', 'P AO1 L'],
  "palla": ['P AE1 L AH0'],
  "palladino": ['P AA0 L AA0 D IY1 N OW0'],
  "palladium": ['P AH0 L EY1 D IY0 AH0 M'],
  "pallante": ['P AA0 L AA1 N T IY0'],
  "pallas": ['P AE1 L AH0 S'],
  "paller": ['P AE1 L ER0'],
  "palleschi": ['P AA0 L EH1 S K IY0'],
  "pallet": ['P AE1 L AH0 T'],
  "pallets": ['P AE1 L AH0 T S'],
  "pallett": ['P AE1 L AH0 T'],
  "palliative": ['P AE1 L IY0 AH0 T IH0 V'],
  "palliatives": ['P AE1 L IY0 AH0 T IH0 V Z'],
  "pallid": ['P AE1 L AH0 D'],
  "pallidotomy": ['P AE2 L IH0 D AO1 T AH0 M IY0'],
  "pallo": ['P AE1 L OW0'],
  "pallone": ['P AA0 L OW1 N IY0'],
  "palm": ['P AA1 M', 'P AA1 L M'],
  "palm-springs": ['P AA1 M S P R IH0 NG Z', 'P AA1 L M S P R IH0 NG Z'],
  "palma": ['P AA1 L M AH0'],
  "palma's": ['P AA1 L M AH0 Z'],
  "palmateer": ['P AE1 L M AH0 T IH0 R'],
  "palmatier": ['P AE1 L M AH0 T IY0 ER0'],
  "palmdale": ['P AA1 M D EY2 L'],
  "palmdale's": ['P AA1 M D EY2 L Z'],
  "palme": ['P AA1 M', 'P AA1 L M'],
  "palmer": ['P AA1 M ER0', 'P AA1 L M ER0'],
  "palmer's": ['P AA1 M ER0 Z', 'P AA1 L M ER0 Z'],
  "palmeri": ['P AA0 L M EH1 R IY0'],
  "palmerino": ['P AO2 L M EH0 R IY1 N OW0'],
  "palmero": ['P AA0 L M EH1 R OW0'],
  "palmerton": ['P AA1 M ER0 T AH0 N'],
  "palmertree": ['P AA1 M ER0 T R IY2'],
  "palmetto": ['P AE0 L M EH1 T OW0', 'P AA0 L M EH1 T OW0'],
  "palmgren": ['P AE1 L M G R EH0 N'],
  "palmieri": ['P AO2 L M IY0 EH1 R IY0'],
  "palminteri": ['P AO2 L M IH0 N T EH1 R IY0'],
  "palmira": ['P AA0 L M IH1 R AH0'],
  "palmisano": ['P AA0 L M IY0 S AA1 N OW0'],
  "palmist": ['P AA1 M IH0 S T'],
  "palmistry": ['P AA1 M IH0 S T R IY0'],
  "palmists": ['P AA1 M IH0 S T S'],
  "palmiter": ['P AE1 L M AY0 T ER0'],
  "palmitic": ['P AE0 L M IH1 T IH0 K'],
  "palmolive": ['P AA0 L M AA1 L IH0 V'],
  "palmore": ['P AE1 L M AO0 R'],
  "palmquist": ['P AE1 L M K W IH0 S T'],
  "palms": ['P AA1 M Z', 'P AA1 L M Z'],
  "palmstierna": ['P AO2 L M S T IY0 EH1 R N AH0'],
  "palo": ['P AE1 L OW0'],
  "palo-alto": ['P AE1 L OW0 AE1 L T OW0'],
  "paloma": ['P AA0 L OW1 M AH0'],
  "palomar": ['P AE1 L AH0 M AA0 R'],
  "palomares": ['P AA0 L OW0 M AA1 R EH0 S'],
  "palomba": ['P AA0 L OW1 M B AH0'],
  "palombi": ['P AH0 L AA1 M B IY0'],
  "palombo": ['P AH0 L AA1 M B OW0'],
  "palometa": ['P AA0 L OW0 M EH1 T AH0'],
  "palomino": ['P AE2 L AH0 M IY1 N OW0'],
  "palomita": ['P AA0 L OW0 M IY1 T AH0'],
  "palomo": ['P AA0 L OW1 M OW0'],
  "palone": ['P AH0 L OW1 N'],
  "palonius": ['P AH0 L OW1 N IY0 AH0 S'],
  "palos": ['P AA1 L OW0 Z'],
  "palpable": ['P AE1 L P AH0 B AH0 L'],
  "palpably": ['P AE1 L P AH0 B L IY0'],
  "palpitate": ['P AE1 L P AH0 T EY0 T'],
  "palpitated": ['P AE1 L P AH0 T EY0 T IH0 D'],
  "palpitates": ['P AE1 L P AH0 T EY0 T S'],
  "palpitating": ['P AE1 L P AH0 T EY0 T IH0 NG'],
  "palpitation": ['P AE2 L P AH0 T EY1 SH AH0 N'],
  "palpitations": ['P AE2 L P IH0 T EY1 SH AH0 N Z'],
  "pals": ['P AE1 L Z'],
  "palsy": ['P AO1 L Z IY0'],
  "palton": ['P AO1 L T AH0 N'],
  "paltry": ['P AO1 L T R IY0'],
  "paltz": ['P AO1 L T S'],
  "paluch": ['P AE1 L AH0 K'],
  "paluck": ['P AE1 L AH0 K'],
  "palumbo": ['P AH0 L AH1 M B OW0'],
  "paluzzi": ['P AA0 L UW1 T S IY0'],
  "pam": ['P AE1 M'],
  "pam's": ['P AE1 M Z'],
  "pamacho": ['P AH0 M AA1 CH OW0'],
  "pamby": ['P AE1 M B IY0'],
  "pamela": ['P AE1 M AH0 L AH0'],
  "pamela's": ['P AE1 M AH0 L AH0 Z'],
  "pamelina": ['P AA0 M EH0 L IY1 N AH0'],
  "pamella": ['P AH0 M EH1 L AH0'],
  "pamer": ['P EY1 M ER0'],
  "pammy": ['P AE1 M IY0'],
  "pamour": ['P AE1 M AO0 R'],
  "pampas": ['P AE1 M P AH0 Z'],
  "pampel": ['P AE1 M P AH0 L'],
  "pamper": ['P AE1 M P ER0'],
  "pampered": ['P AE1 M P ER0 D'],
  "pamperin": ['P AE1 M P ER0 IH0 N'],
  "pampering": ['P AE1 M P ER0 IH0 NG'],
  "pampers": ['P AE1 M P ER0 Z'],
  "pamphlet": ['P AE1 M F L AH0 T'],
  "pamphleteer": ['P AE2 M F L AH0 T IH1 R'],
  "pamphleteers": ['P AE2 M F L AH0 T IH1 R Z'],
  "pamphlets": ['P AE1 M F L AH0 T S'],
  "pamplin": ['P AE1 M P L IH0 N'],
  "pamplona": ['P AE0 M P L OW1 N AH0'],
  "pan": ['P AE1 N'],
  "pan's": ['P AE1 N Z'],
  "panacea": ['P AE2 N AH0 S IY1 AH0'],
  "panache": ['P AH0 N AA1 SH'],
  "panaco": ['P AE1 N AH0 K OW0'],
  "panagopoulos": ['P AE0 N AH0 G AA1 P AH0 L IH0 S'],
  "panagos": ['P AA0 N AA1 G OW0 Z'],
  "panam": ['P AE2 N AE1 M'],
  "panama": ['P AE1 N AH0 M AA2'],
  "panama's": ['P AE1 N AH0 M AA2 Z'],
  "panamanian": ['P AE2 N AH0 M EY1 N IY0 AH0 N'],
  "panamanians": ['P AE2 N AH0 M EY1 N IY0 AH0 N Z'],
  "panamsat": ['P AH0 N AE1 M S AE0 T', 'P AE1 N AE2 M S AE2 T'],
  "panaro": ['P AA0 N AA1 R OW0'],
  "panas": ['P AE1 N AH0 Z'],
  "panasonic": ['P AE2 N AH0 S AA1 N IH0 K'],
  "pancake": ['P AE1 N K EY2 K'],
  "pancaked": ['P AE1 N K EY2 K T'],
  "pancakes": ['P AE1 N K EY2 K S'],
  "pancanadian": ['P AE2 NG K AH0 N EY1 D IY0 AH0 N'],
  "pancer": ['P AE1 N S ER0'],
  "panchen": ['P AA2 N CH EH1 N'],
  "pancho": ['P AE1 N CH OW0'],
  "panciera": ['P AA0 N CH IH1 R AH0'],
  "pancoast": ['P AE1 N K OW2 S T'],
  "pancontinental": ['P AE1 N K AA2 N T AH0 N EH1 N T AH0 L'],
  "pancreas": ['P AE1 N K R IY0 AH0 S'],
  "pancreatic": ['P AE2 N K R IY0 AE1 T IH0 K'],
  "panda": ['P AE1 N D AH0'],
  "pandanus": ['P AE0 N D EY1 N AH0 S'],
  "pandas": ['P AE1 N D AH0 Z'],
  "pandemic": ['P AE0 N D EH1 M IH0 K'],
  "pandemonium": ['P AE2 N D IH0 M OW1 N IY0 AH0 M'],
  "pander": ['P AE1 N D ER0'],
  "pandered": ['P AE1 N D ER0 D'],
  "pandering": ['P AE1 N D ER0 IH0 NG'],
  "pandey": ['P AA1 N D EY2'],
  "pandick": ['P AE1 N D IH2 K'],
  "pandit": ['P AH1 N D AH0 T'],
  "pando": ['P AA1 N D OW0'],
  "pandolfi": ['P AA0 N D OW1 L F IY0'],
  "pandolfo": ['P AA0 N D OW1 L F OW0'],
  "pandora": ['P AE0 N D AO1 R AH0'],
  "pandora's": ['P AE0 N D AO1 R AH0 Z'],
  "pandy": ['P AE1 N D IY0'],
  "pandya": ['P AA1 N D Y AH0'],
  "pane": ['P EY1 N'],
  "panebianco": ['P AA0 N EH0 B IY0 AA1 N K OW0'],
  "panek": ['P AE1 N IH0 K'],
  "panel": ['P AE1 N AH0 L'],
  "panel's": ['P AE1 N AH0 L Z'],
  "paneled": ['P AE1 N AH0 L D'],
  "paneling": ['P AE1 N AH0 L IH0 NG'],
  "panelist": ['P AE1 N AH0 L AH0 S T'],
  "panelists": ['P AE1 N AH0 L IH0 S T S'],
  "panelization": ['P AE1 N AH0 L AH0 Z EY1 SH AH0 N'],
  "panelize": ['P AE1 N AH0 L AY2 Z'],
  "panelized": ['P AE1 N AH0 L AY2 Z D'],
  "panella": ['P AH0 N EH1 L AH0'],
  "panels": ['P AE1 N AH0 L Z'],
  "panels'": ['P AE1 N AH0 L Z'],
  "panem": ['P EY1 N AH0 M'],
  "panepinto": ['P AA0 N EH0 P IY1 N T OW0'],
  "panes": ['P EY1 N Z'],
  "panetta": ['P AH0 N EH1 T AH0'],
  "panetta's": ['P AH0 N EH1 T AH0 Z'],
  "panfida": ['P AE2 N F IY1 D AH0'],
  "panfil": ['P AE1 N F IH0 L'],
  "panfile": ['P AE1 N F AY1 L'],
  "panful": ['P AE1 N F AH0 L'],
  "panfuls": ['P AE1 N F AH0 L Z'],
  "pang": ['P AE1 NG'],
  "pangallo": ['P AA0 NG G AA1 L OW0'],
  "pangborn": ['P AE1 NG B AO2 R N'],
  "pangburn": ['P AE1 NG B ER2 N'],
  "pangels": ['P AE2 NG G EH1 L Z'],
  "pangle": ['P AE1 NG G AH0 L'],
  "pangloss": ['P AE1 N G L AA2 S', 'P AE1 NG G L AA2 S'],
  "pangs": ['P AE1 NG Z'],
  "panhandle": ['P AE1 N HH AE2 N D AH0 L'],
  "panhandle's": ['P AE1 N HH AE2 N D AH0 L Z'],
  "panhandler": ['P AE1 N HH AE2 N D L ER0'],
  "panhandlers": ['P AE1 N HH AE2 N D L ER0 Z'],
  "panhandling": ['P AE1 N HH AE2 N D L IH0 NG'],
  "paniagua": ['P AA0 N IY0 AA1 G AH0'],
  "panic": ['P AE1 N IH0 K'],
  "panic's": ['P AE1 N IH0 K S'],
  "paniccia": ['P AA0 N IY1 CH AH0'],
  "panicked": ['P AE1 N IH0 K T'],
  "panicking": ['P AE1 N IH0 K IH0 NG'],
  "panicky": ['P AE1 N IH0 K IY0'],
  "panico": ['P AA0 N IY1 K OW0'],
  "panics": ['P AE1 N IH0 K S'],
  "panik": ['P AE1 N IH0 K'],
  "panini": ['P AH0 N IY1 N IY2'],
  "pankau": ['P AE1 NG K AW0'],
  "pankey": ['P AE1 N K IY2'],
  "pankki": ['P AE1 NG K IY0'],
  "panko": ['P AE1 NG K OW0'],
  "pankonin": ['P AE1 NG K AH0 N IH0 N'],
  "pankow": ['P AE1 NG K OW0'],
  "pankratz": ['P AE1 NG K R AH0 T S'],
  "panky": ['P AE1 NG K IY0'],
  "panmunjom": ['P AE2 N M UW0 N JH AO1 M'],
  "panmure": ['P AE1 N M UH2 R'],
  "panned": ['P AE1 N D'],
  "pannell": ['P AE1 N AH0 L'],
  "pannier": ['P AE1 N IY0 ER0'],
  "pannill": ['P AE1 N IH0 L'],
  "pannill's": ['P AE1 N IH0 L Z'],
  "panning": ['P AE1 N IH0 NG'],
  "pannone": ['P AA0 N OW1 N IY0'],
  "pannu": ['P AA1 N UW0'],
  "pannullo": ['P AA0 N UW1 L OW0'],
  "panny": ['P AE1 N IY0'],
  "panoply": ['P AE1 N AA0 P L IY0'],
  "panopolys": ['P AH0 N AA1 P AH0 L IY0 Z'],
  "panora": ['P AH0 N AO1 R AH0'],
  "panorama": ['P AE2 N ER0 AE1 M AH0'],
  "panoramic": ['P AE2 N ER0 AE1 M IH0 K'],
  "panos": ['P AA1 N OW0 S'],
  "panoz": ['P AE1 N AA0 Z'],
  "panozzo": ['P AH0 N AA1 Z OW0'],
  "panphila": ['P AE1 N F IH0 L AH0'],
  "pans": ['P AE1 N Z'],
  "pansies": ['P AE1 N Z IY0 Z'],
  "pansophic": ['P AE1 N S AH0 F IH0 K'],
  "pansy": ['P AE1 N Z IY0'],
  "pant": ['P AE1 N T'],
  "pantaleo": ['P AA0 N T AA1 L IY0 OW0'],
  "pantalone": ['P AE1 N T AH0 L OW2 N'],
  "pantaloon": ['P AE1 N T AH0 L UW2 N'],
  "pantaloons": ['P AE1 N T AH0 L UW2 N Z'],
  "pantano": ['P AA0 N T AA1 N OW0'],
  "panted": ['P AE1 N T IH0 D'],
  "pantel": ['P AA0 N T EH1 L'],
  "panter": ['P AE1 N T ER0'],
  "pantera": ['P AE0 N T EH1 R AH0'],
  "pantera's": ['P AA0 N T EH1 R AH0 Z', 'P AE0 N T EH1 R AH0 Z'],
  "pantex": ['P AE1 N T EH0 K S'],
  "panthea": ['P AE1 N TH IY0 AH0'],
  "pantheistic": ['P AE2 N TH IY0 IH1 S T IH0 K'],
  "pantheon": ['P AE1 N TH IY0 AA2 N'],
  "panther": ['P AE1 N TH ER0'],
  "panther's": ['P AE1 N TH ER0 Z'],
  "panthers": ['P AE1 N TH ER0 Z'],
  "panthers'": ['P AE1 N TH ER0 Z'],
  "panties": ['P AE1 N T IY0 Z'],
  "panting": ['P AE1 N T IH0 NG'],
  "pantle": ['P AE1 N T AH0 L'],
  "pantoja": ['P AA0 N T OW1 Y AH0'],
  "pantomime": ['P AE1 N T AH0 M AY2 M'],
  "panton": ['P AE1 N T AH0 N'],
  "pantry": ['P AE1 N T R IY0'],
  "pants": ['P AE1 N T S'],
  "pantsuit": ['P AE1 N T S UW2 T', 'P AE1 N S UW2 T'],
  "pantsuits": ['P AE1 N T S UW2 T S', 'P AE1 N S UW2 T S'],
  "pantuso": ['P AA0 N T UW1 S OW0'],
  "panty": ['P AE1 N T IY0'],
  "pantyhose": ['P AE1 N T IY0 HH OW2 Z'],
  "panza": ['P AE1 N Z AH0'],
  "panzarella": ['P AA0 N Z AA0 R EH1 L AH0'],
  "panzer": ['P AE1 N Z ER0'],
  "panzhihua": ['P AE2 N Z IH0 HH Y UW1 AH0'],
  "panzica": ['P AE1 N Z IH0 K AH0'],
  "pao": ['P AW1'],
  "pao's": ['P AW1 Z'],
  "paolella": ['P AA0 OW0 L EH1 L AH0'],
  "paoletti": ['P AA0 OW0 L EH1 T IY0'],
  "paoli": ['P AW1 L IY0', 'P EY2 OW1 L IY0'],
  "paolillo": ['P AA0 OW0 L IH1 L OW0'],
  "paolini": ['P AA0 OW0 L IY1 N IY0'],
  "paolino": ['P AA0 OW0 L IY1 N OW0'],
  "paolo": ['P AW1 L OW0'],
  "paolucci": ['P AA0 OW0 L UW1 CH IY0'],
  "paone": ['P EY1 OW2 N'],
  "paonessa": ['P AA0 OW0 N EH1 S AH0'],
  "pap": ['P AE1 P'],
  "papa": ['P AA1 P AH2'],
  "papa's": ['P AA1 P AA2 Z'],
  "papacy": ['P EY1 P AH0 S IY0'],
  "papadakis": ['P AA0 P AA0 D AA1 K IH0 S'],
  "papadopoulos": ['P AE2 P AH0 D AA1 P AH0 L AH0 S', 'P AA2 P AH0 D AA1 P AH0 L AH0 S'],
  "papageorge": ['P AE2 P AH0 JH AO1 R JH', 'P AA2 P AH0 JH AO1 R JH'],
  "papago": ['P AA1 P AH0 G OW2'],
  "papal": ['P EY1 P AH0 L'],
  "papale": ['P AA0 P AA1 L IY0'],
  "papaleo": ['P AA0 P EY0 L IY1 AH0'],
  "papalia": ['P AA0 P AA1 L IY0 AH0'],
  "papandrea": ['P AA0 P AA1 N D R IY0 AH0'],
  "papandreou": ['P AH0 P AE1 N D R IY0 UW2'],
  "papania": ['P AA0 P AA1 N IY0 AH0'],
  "paparazzi": ['P AA2 P AA0 R AO1 Z IY2'],
  "paparazzi's": ['P AA2 P AA0 R AO1 Z IY2 Z'],
  "paparazzis": ['P AA2 P AA0 R AO1 Z IY2 Z'],
  "paparella": ['P AA0 P AA0 R EH1 L AH0'],
  "papas": ['P AA1 P AH0 Z'],
  "papaw": ['P AO1 P AO0'],
  "papay": ['P AE1 P EY0'],
  "papaya": ['P AH0 P AY1 AH0'],
  "papayas": ['P AH0 P AY1 AH0 Z'],
  "papazian": ['P AH0 P EY1 Z IY0 AH0 N'],
  "pape": ['P EY1 P'],
  "papenfuss": ['P AE1 P AH0 N F AH2 S'],
  "paper": ['P EY1 P ER0'],
  "paper's": ['P EY1 P ER0 Z'],
  "paperazzi": ['P AE1 P AH0 R AA2 T S IY2'],
  "paperback": ['P EY1 P ER0 B AE2 K'],
  "paperbacks": ['P EY1 P ER0 B AE2 K S'],
  "paperboard": ['P EY1 P ER0 B AO2 R D'],
  "paperboy": ['P EY1 P ER0 B AO2 Y'],
  "paperboy's": ['P EY1 P ER0 B AO2 Y Z'],
  "paperboys": ['P EY1 P ER0 B AO2 Y Z'],
  "papered": ['P EY1 P ER0 D'],
  "papering": ['P EY1 P ER0 IH0 NG'],
  "paperless": ['P EY1 P ER0 L AH0 S'],
  "papermaking": ['P AE1 P ER0 M EY2 K IH0 NG'],
  "papermate": ['P EY1 P ER0 M EY2 T'],
  "papernick": ['P EY1 P ER0 N IH2 K'],
  "papers": ['P EY1 P ER0 Z'],
  "papers'": ['P EY1 P ER0 Z'],
  "paperweight": ['P EY1 P ER0 W EY2 T'],
  "paperwork": ['P EY1 P ER0 W ER2 K'],
  "paperworker": ['P EY1 P ER0 W ER2 K ER0'],
  "paperworkers": ['P EY1 P ER0 W ER2 K ER0 Z'],
  "papery": ['P EY1 P ER0 IY0'],
  "papesh": ['P AE1 P IH0 SH'],
  "papier": ['P EY1 P Y ER0'],
  "papier-mache": ['P EY1 P ER0 M AH0 SH EY1'],
  "papillion": ['P AH0 P IH1 L Y AH0 N'],
  "papilloma": ['P AE2 P IH0 L OW1 M AH0'],
  "papin": ['P AA0 P IY1 N'],
  "papineau": ['P AE1 P IH0 N OW2'],
  "papini": ['P AA0 P IY1 N IY0'],
  "papke": ['P EY1 P K'],
  "papo": ['P AE0 P OW1'],
  "papon": ['P AE1 P AO0 N'],
  "papon's": ['P AE1 P AO0 N Z'],
  "papp": ['P AE1 P'],
  "pappa": ['P AE1 P AH0'],
  "pappadio": ['P AH0 P EY1 D IY0 OW0'],
  "pappalardo": ['P AA0 P AA0 L AA1 R D OW0'],
  "pappas": ['P AE1 P AH0 S'],
  "pappert": ['P AE1 P ER0 T'],
  "paprika": ['P AE0 P R IY1 K AH0', 'P AH0 P R IY1 K AH0'],
  "paprocki": ['P AH0 P R OW1 T S K IY0'],
  "papson": ['P AE1 P S AH0 N'],
  "papua": ['P AE1 P Y UW0 AH0'],
  "paque": ['P AE1 K'],
  "paquet": ['P AA1 K EY0 T'],
  "paquette": ['P AH0 K EH1 T'],
  "paquin": ['P AE1 K W IH0 N'],
  "par": ['P AA1 R'],
  "par's": ['P AA1 R Z'],
  "para": ['P EH1 R AH0'],
  "parable": ['P EH1 R AH0 B AH0 L'],
  "parables": ['P EH1 R AH0 B AH0 L Z'],
  "parabola": ['P ER0 AE1 B AH0 L AH0'],
  "parabolic": ['P EH2 R AH0 B AA1 L IH0 K'],
  "parachute": ['P EH1 R AH0 SH UW2 T'],
  "parachuted": ['P EH1 R AH0 SH UW2 T IH0 D'],
  "parachutes": ['P EH1 R AH0 SH UW2 T S'],
  "parachuting": ['P EH1 R AH0 SH UW2 T IH0 NG'],
  "parada": ['P ER0 AE1 D AH0'],
  "parade": ['P ER0 EY1 D'],
  "paraded": ['P ER0 EY1 D IH0 D'],
  "paradee": ['P EH1 R AH0 D IY0'],
  "parades": ['P ER0 EY1 D Z'],
  "paradigm": ['P EH1 R AH0 D AY2 M'],
  "paradigms": ['P EH1 R AH0 D AY2 M Z'],
  "parading": ['P ER0 EY1 D IH0 NG'],
  "paradis": ['P ER0 AE1 D IH0 S'],
  "paradise": ['P EH1 R AH0 D AY2 S'],
  "paradiso": ['P ER0 AH0 D IY1 S OW0'],
  "parador": ['P EH1 R AH0 D AO2 R'],
  "paradowski": ['P ER0 AH0 D AO1 F S K IY0'],
  "paradox": ['P EH1 R AH0 D AA2 K S'],
  "paradoxes": ['P EH1 R AH0 D AA2 K S IH0 Z'],
  "paradoxical": ['P EH2 R AH0 D AA1 K S IH0 K AH0 L'],
  "paradoxically": ['P EH2 R AH0 D AA1 K S AH0 K L IY0', 'P EH2 R AH0 D AA1 K S AH0 K AH0 L IY0'],
  "parady": ['P ER0 AE1 D IY0'],
  "paradyne": ['P EH1 R AH0 D AY2 N'],
  "paradyne's": ['P EH1 R AH0 D AY2 N Z'],
  "paraffin": ['P EH1 R AH0 F AH0 N'],
  "parag": ['P ER0 AA1 G'],
  "paraglide": ['P EH1 R AH0 G L AY2 D'],
  "paragon": ['P EH1 R AH0 G AA2 N'],
  "paragraph": ['P AE1 R AH0 G R AE2 F', 'P EH1 R AH0 G R AE2 F'],
  "paragraphs": ['P AE1 R AH0 G R AE2 F S', 'P EH1 R AH0 G R AE2 F S'],
  "paraguay": ['P EH1 R AH0 G W EY2'],
  "paraguayan": ['P EH2 R AH0 G W AY1 AH0 N'],
  "parakeet": ['P EH1 R AH0 K IY2 T'],
  "parakeets": ['P EH1 R AH0 K IY2 T S'],
  "paralegal": ['P EH2 R AH0 L IY1 G AH0 L'],
  "paralegals": ['P EH2 R AH0 L IY1 G AH0 L Z'],
  "parallax": ['P EH1 R AH0 L AE2 K S'],
  "parallel": ['P EH1 R AH0 L EH2 L'],
  "paralleled": ['P EH1 R AH0 L EH2 L D'],
  "paralleling": ['P EH1 R AH0 L EH2 L IH0 NG'],
  "parallelism": ['P EH1 R AH0 L EH2 L IH2 Z AH0 M'],
  "parallels": ['P EH1 R AH0 L EH2 L Z'],
  "paralympic": ['P EH2 R AH0 L IH1 M P IH0 K'],
  "paralysis": ['P ER0 AE1 L AH0 S AH0 S', 'P ER0 AE1 L IH0 S IH0 S'],
  "paralytic": ['P EH2 R AH0 L IH1 T IH0 K'],
  "paralyze": ['P EH1 R AH0 L AY2 Z'],
  "paralyzed": ['P EH1 R AH0 L AY2 Z D'],
  "paralyzes": ['P EH1 R AH0 L AY2 Z IH0 Z'],
  "paralyzing": ['P EH1 R AH0 L AY2 Z IH0 NG'],
  "paramagnetic": ['P AE2 R AH0 M AE2 G N EH1 T IH0 K'],
  "paramax": ['P EH1 R AH0 M AE0 K S'],
  "paramecium": ['P EH2 R AH0 M IY1 S IY0 AH0 M'],
  "paramedic": ['P EH2 R AH0 M EH1 D IH0 K'],
  "paramedics": ['P EH2 R AH0 M EH1 D IH0 K S'],
  "parameter": ['P ER0 AE1 M AH0 T ER0'],
  "parameters": ['P ER0 AE1 M AH0 T ER0 Z'],
  "parametric": ['P EH2 R AH0 M EH1 T R IH0 K'],
  "paramilitaries": ['P EH2 R AH0 M IH1 L AH0 T EH2 R IY0 Z'],
  "paramilitary": ['P EH2 R AH0 M IH1 L AH0 T EH2 R IY0'],
  "paramo": ['P ER0 AE1 M OW0'],
  "paramonova": ['P EH2 R AH0 M AH0 N OW1 V AH0'],
  "paramore": ['P EH1 AH0 M AO1 R'],
  "paramount": ['P EH1 R AH0 M AW2 N T'],
  "paramount's": ['P EH1 R AH0 M AW2 N T S'],
  "paramus": ['P ER0 AE1 M AH0 S'],
  "parana": ['P ER0 AA1 N AH0'],
  "paranoia": ['P EH2 R AH0 N OY1 AH0'],
  "paranoiac": ['P EH2 R AH0 N OY1 AE2 K'],
  "paranoid": ['P EH1 R AH0 N OY2 D'],
  "paranormal": ['P EH0 R AH0 N AO1 R M AH0 L'],
  "parapet": ['P EH1 R AH0 P EH2 T'],
  "paraphernalia": ['P EH2 R AH0 F AH0 N EY1 L Y AH0'],
  "paraphrase": ['P EH1 R AH0 F R EY2 Z'],
  "paraphrasing": ['P EH1 R AH0 F R EY2 Z IH0 NG'],
  "paraplegia": ['P EH2 R AH0 P L IY1 JH IY0 AH0'],
  "paraplegic": ['P EH2 R AH0 P L EH1 G IH0 K', 'P EH2 R AH0 P L IY1 JH IH0 K'],
  "parasite": ['P EH1 R AH0 S AY2 T'],
  "parasites": ['P EH1 R AH0 S AY2 T S'],
  "parasitic": ['P EH2 R AH0 S IH1 T IH0 K'],
  "parasympathetic": ['P EH2 R AH0 S IH2 M P AH0 TH EH1 T IH0 K'],
  "paratore": ['P ER0 AH0 T AO1 R IY0'],
  "paratroop": ['P EH1 R AH0 T R UW2 P'],
  "paratrooper": ['P EH1 R AH0 T R UW2 P ER0'],
  "paratroopers": ['P EH1 R AH0 T R UW2 P ER0 Z'],
  "paratroops": ['P EH1 R AH0 T R UW2 P S'],
  "parazoa": ['P EH2 R AH0 Z OW1 AH0'],
  "parboil": ['P AA1 R B OY2 L'],
  "parboiling": ['P AA1 R B OY2 L IH0 NG'],
  "parc": ['P AA1 R K'],
  "parcel": ['P AA1 R S AH0 L'],
  "parceled": ['P AA1 R S AH0 L D'],
  "parceling": ['P AA1 R S AH0 L IH0 NG', 'P AA1 R S L IH0 NG'],
  "parcell": ['P AA0 R S EY1 L'],
  "parcells": ['P AA1 R S AH0 L Z'],
  "parcels": ['P AA1 R S AH0 L Z'],
  "parch": ['P AA1 R CH'],
  "parched": ['P AA1 R CH T'],
  "parcher": ['P AA1 R CH ER0'],
  "parchman": ['P AA1 R K M AH0 N'],
  "parchment": ['P AA1 R CH M AH0 N T'],
  "parchmentlike": ['P AA1 R CH M AH0 N T L AY2 K'],
  "parcplace": ['P AA1 R K P L EY2 S'],
  "parde": ['P AA1 R D'],
  "pardee": ['P AA1 R D IY0'],
  "parden": ['P AA1 R D AH0 N'],
  "pardi": ['P AA1 R D IY0'],
  "pardini": ['P AA0 R D IY1 N IY0'],
  "pardo": ['P AA1 R D OW0'],
  "pardoe": ['P AA1 R D OW2'],
  "pardon": ['P AA1 R D AH0 N'],
  "pardoned": ['P AA1 R D AH0 N D'],
  "pardoning": ['P AA1 R D AH0 N IH0 NG'],
  "pardons": ['P AA1 R D AH0 N Z'],
  "pardue": ['P AA1 R D UW2'],
  "pardus": ['P AA1 R D AH0 S'],
  "pardy": ['P AA1 R D IY0'],
  "pare": ['P EH1 R'],
  "pared": ['P EH1 R D'],
  "paredes": ['P AA0 R EY1 D EH0 S'],
  "paredez": ['P AA0 R EY1 D EH0 Z'],
  "paredis": ['P EH1 R AH0 D IH2 S'],
  "paredo": ['P AH0 R EY1 D OW0'],
  "parekh": ['P AA1 R IH0 K'],
  "parella": ['P AA0 R EH1 L AH0'],
  "parent": ['P EH1 R AH0 N T'],
  "parent's": ['P EH1 R AH0 N T S'],
  "parentage": ['P EH1 R AH0 N T AH0 JH'],
  "parental": ['P ER0 EH1 N T AH0 L'],
  "parente": ['P AA0 R EH1 N T IY0'],
  "parenteau": ['P AA0 R EH1 N T OW0'],
  "parentheses": ['P ER0 EH1 N TH AH0 S IY2 Z'],
  "parenthesis": ['P ER0 EH1 N TH AH0 S IH0 S'],
  "parenthetical": ['P ER0 EH0 N TH EH1 T IH0 K AH0 L'],
  "parenthetically": ['P ER0 EH0 N TH EH1 T IH0 K AH0 L IY0', 'P ER0 EH0 N TH EH1 T IH0 K L IY0'],
  "parenthood": ['P EH1 R AH0 N T HH UH2 D'],
  "parenti": ['P AA0 R EH1 N T IY0'],
  "parenting": ['P EH1 R AH0 N T IH0 NG'],
  "parents": ['P EH1 R AH0 N T S'],
  "parents'": ['P EH1 R AH0 N T S'],
  "pares": ['P EH1 R Z'],
  "pareti": ['P EH2 R EH1 T IY0'],
  "pareto": ['P AA2 R EH1 T OW0'],
  "paretti": ['P EH2 R EH1 T IY0'],
  "parfait": ['P AA2 R F EY1'],
  "parfaits": ['P AA2 R F EY1 Z'],
  "parfitt": ['P AA1 R F IH0 T'],
  "parfums": ['P ER0 F Y UW1 M Z', 'P AA0 R F Y UW1 M Z'],
  "parga": ['P AA1 R G AH0'],
  "pargesa": ['P AA0 R JH EH1 S AH0'],
  "parham": ['P AA1 R HH AH0 M'],
  "pariagua": ['P AA0 R IY0 AA1 G W AH0'],
  "pariah": ['P ER0 AY1 AH0'],
  "pariahs": ['P ER0 AY1 AH0 Z'],
  "paribas": ['P EH0 R IY1 B AH0 S'],
  "paribas's": ['P EH0 R IY1 B AH0 S IH0 Z'],
  "parietal": ['P ER0 AY1 AH0 T AH0 L'],
  "parikh": ['P AE1 R IH0 K'],
  "parillo": ['P AA0 R IH1 L OW0'],
  "parimutuel": ['P EH2 R IH0 M Y UW1 CH UW0 AH0 L'],
  "paring": ['P EH1 R IH0 NG'],
  "paris": ['P EH1 R IH0 S', 'P AE1 R IH0 S'],
  "paris'": ['P EH1 R IH0 S', 'P AE1 R IH0 S'],
  "paris's": ['P EH1 R IH0 S IH0 Z'],
  "parise": ['P AA1 R AY0 Z'],
  "pariseau": ['P AE1 R IH0 S OW0'],
  "parish": ['P AE1 R IH0 SH', 'P EH1 R IH0 SH'],
  "parishes": ['P AE1 R IH0 SH IH0 Z', 'P EH1 R IH0 SH IH0 Z'],
  "parishioner": ['P ER0 IH1 SH AH0 N ER0'],
  "parishioners": ['P ER0 IH1 SH AH0 N ER0 Z'],
  "parisi": ['P AA0 R IY1 S IY0'],
  "parisian": ['P ER0 IH1 ZH AH0 N'],
  "parisians": ['P ER0 IH1 S IY0 AH0 N Z'],
  "parisienne": ['P ER0 IH1 S IY0 EH2 N'],
  "parities": ['P EH1 R AH0 T IY0 Z'],
  "parity": ['P EH1 R AH0 T IY0'],
  "parizeau": ['P EH1 R IH0 Z OW2'],
  "parizek": ['P ER0 IH1 Z EH0 K'],
  "parizo": ['P AA0 R IY1 Z OW0'],
  "park": ['P AA1 R K'],
  "park's": ['P AA1 R K S'],
  "parka": ['P AA1 R K AH0'],
  "parkas": ['P AA1 R K AH0 Z'],
  "parke": ['P AA1 R K'],
  "parked": ['P AA1 R K T'],
  "parker": ['P AA1 R K ER0'],
  "parker's": ['P AA1 R K ER0 Z'],
  "parkers": ['P AA1 R K ER0 Z'],
  "parkersburg": ['P AA1 R K ER0 Z B ER0 G'],
  "parkerson": ['P AA1 R K ER0 S AH0 N'],
  "parkes": ['P AA1 R K S'],
  "parkey": ['P AA1 R K IY2'],
  "parkfield": ['P AA1 R K F IY0 L D'],
  "parkhill": ['P AA1 R K HH IH2 L'],
  "parkhurst": ['P AA1 R K HH ER0 S T'],
  "parkin": ['P AA1 R K IH0 N'],
  "parking": ['P AA1 R K IH0 NG'],
  "parkins": ['P AA1 R K IH0 N Z'],
  "parkinson": ['P AA1 R K IH0 N S AH0 N'],
  "parkinson's": ['P AA1 R K IH0 N S AH0 N Z'],
  "parkinsonism": ['P AA1 R K IH0 N S AH0 N IH2 Z IH0 M'],
  "parkison": ['P AA1 R K IH0 S AH0 N'],
  "parkland": ['P AA1 R K L AE2 N D'],
  "parkman": ['P AA1 R K M AH0 N'],
  "parks": ['P AA1 R K S'],
  "parks'": ['P AA1 R K S'],
  "parkside": ['P AA1 R K S AY2 D'],
  "parkway": ['P AA1 R K W EY2'],
  "parlance": ['P AA1 R L AH0 N S'],
  "parlato": ['P AA0 R L AA1 T OW0'],
  "parlay": ['P AA1 R L EY0'],
  "parlayed": ['P AA1 R L EY2 D'],
  "parlaying": ['P AA1 R L EY0 IH0 NG'],
  "parlays": ['P AA1 R L EY0 Z'],
  "parlee": ['P AA1 R L IY2'],
  "parler": ['P AA1 R L ER0'],
  "parlett": ['P AA1 R L IH0 T'],
  "parlette": ['P AA2 R L EH1 T'],
  "parley": ['P AA1 R L IY0'],
  "parliament": ['P AA1 R L AH0 M AH0 N T'],
  "parliament's": ['P AA1 R L AH0 M AH0 N T S'],
  "parliamentarian": ['P AA2 R L AH0 M AH0 N T EH1 R IY0 AH0 N'],
  "parliamentarianism": ['P AA2 R L AH0 M AH0 N T EH1 R IY0 AH0 N IH0 Z AH0 M', 'P AA2 R L AH0 M AH0 N T EH1 R IY0 AH0 N IH0 Z M'],
  "parliamentarians": ['P AA2 R L AH0 M EH0 N T EH1 R IY0 AH0 N Z'],
  "parliamentary": ['P AA2 R L AH0 M EH1 N T ER0 IY0', 'P AA2 R L AH0 M EH1 N ER0 IY0'],
  "parliaments": ['P AA1 R L AH0 M AH0 N T S'],
  "parlier": ['P AA1 R L IY0 ER0'],
  "parlin": ['P AA1 R L IH0 N'],
  "parlodel": ['P AA1 R L OW2 D EH2 L'],
  "parlor": ['P AA1 R L ER0'],
  "parlors": ['P AA1 R L ER0 Z'],
  "parlour": ['P AA1 R L ER0'],
  "parlow": ['P AA1 R L OW2'],
  "parma": ['P AA1 R M AH0'],
  "parmalat": ['P AA1 R M AH0 L AE2 T'],
  "parman": ['P AA1 R M AH0 N'],
  "parmar": ['P AA1 R M AA2 R'],
  "parmele": ['P AA0 R M EH1 L IY0'],
  "parmelee": ['P AA1 R M IH0 L IY0'],
  "parmenter": ['P AA1 R M AH0 N T ER0'],
  "parmentier": ['P AA1 R M AH0 N T IY0 ER0'],
  "parmer": ['P AA1 R M ER0'],
  "parmesan": ['P AA1 R M AH0 Z AA2 N'],
  "parmeter": ['P AA1 R M IH0 T ER0'],
  "parmley": ['P AA1 R M L IY0'],
  "parnassus": ['P AA2 R N AE1 S AH0 S'],
  "parnell": ['P AA1 R N AH0 L'],
  "parnella": ['P AA0 R N EH1 L AH0'],
  "parnes": ['P AA1 R N Z'],
  "parness": ['P AA1 R N AH0 S'],
  "paro": ['P AA1 R OW0'],
  "parochial": ['P ER0 OW1 K IY0 AH0 L'],
  "parochialism": ['P ER0 OW1 K IY0 AH0 L IH2 Z AH0 M'],
  "parodi": ['P AA0 R OW1 D IY0'],
  "parodied": ['P EH1 R AH0 D IY0 D'],
  "parodies": ['P EH1 R AH0 D IY0 Z'],
  "parodist": ['P AE1 R AH0 D IH0 S T'],
  "parody": ['P EH1 R AH0 D IY0'],
  "parole": ['P ER0 OW1 L'],
  "paroled": ['P ER0 OW1 L D'],
  "parolee": ['P ER0 OW2 L IY1'],
  "parolees": ['P ER0 OW2 L IY1 Z'],
  "paroles": ['P ER0 OW1 L Z'],
  "paroling": ['P ER0 OW1 L IH0 NG'],
  "parpart": ['P AA1 R P AA2 R T'],
  "parquet": ['P AA0 R K EY1'],
  "parr": ['P AA1 R'],
  "parra": ['P AA1 R AH0'],
  "parrack": ['P AE1 R AH0 K'],
  "parramore": ['P AA0 R AA1 M AO0 R'],
  "parras": ['P EH1 R AH0 Z'],
  "parrella": ['P AA0 R EH1 L AH0'],
  "parrent": ['P AA1 R AH0 N T'],
  "parrett": ['P AE1 R IH0 T'],
  "parretti": ['P ER0 EH1 T IY0'],
  "parretti's": ['P ER0 EH1 T IY0 S'],
  "parried": ['P EH1 R IY0 D'],
  "parrill": ['P AE1 R AH0 L'],
  "parrilla": ['P AA2 R IH1 L AH0'],
  "parrillo": ['P AA2 R IH1 L OW0'],
  "parrinello": ['P AA0 R IY0 N EH1 L OW0'],
  "parrino": ['P AA2 R IY1 N OW0'],
  "parriott": ['P AE1 R IY0 AH0 T'],
  "parris": ['P EH1 R IH0 S'],
  "parrish": ['P EH1 R IH0 SH'],
  "parrishes": ['P EH1 R IH0 SH IH0 Z'],
  "parrot": ['P EH1 R AH0 T'],
  "parrots": ['P EH1 R AH0 T S'],
  "parrott": ['P EH1 R AH0 T'],
  "parrotta": ['P AA0 R OW1 T AH0'],
  "parrow": ['P AE1 R OW0'],
  "parry": ['P EH1 R IY0'],
  "pars": ['P AA1 R Z'],
  "parse": ['P AA1 R S'],
  "parsec": ['P AA1 R S EH2 K'],
  "parsed": ['P AA1 R S T'],
  "parsefal": ['P AA1 R S IH0 F AH0 L'],
  "parsell": ['P AA1 R S AH0 L'],
  "parser": ['P AA1 R S ER0'],
  "parsers": ['P AA1 R S ER0 Z'],
  "parshall": ['P AA1 R SH AH0 L'],
  "parshley": ['P AA1 R SH L IY0'],
  "parsi": ['P AA1 R S IY0'],
  "parsifal": ['P AA1 R S IH0 F AH0 L', 'P AA1 R Z AH0 F AA2 L'],
  "parsimonious": ['P AA2 R S AH0 M OW1 N IY0 AH0 S'],
  "parsimony": ['P AA1 R S AH0 M OW2 N IY0'],
  "parsing": ['P AA1 R S IH0 NG'],
  "parsippany": ['P AA0 R S IH1 P AH0 N IY0'],
  "parsky": ['P AA1 R S K IY0'],
  "parsley": ['P AA1 R S L IY0'],
  "parslow": ['P AA1 R S L OW2'],
  "parson": ['P AA1 R S AH0 N'],
  "parsonage": ['P AA1 R S AH0 N IH0 JH'],
  "parsonages": ['P AA1 R S AH0 N IH0 JH AH0 Z'],
  "parsons": ['P AA1 R S AH0 N Z'],
  "parsow": ['P AA1 R S OW0'],
  "part": ['P AA1 R T'],
  "part's": ['P AA1 R T S'],
  "part-time": ['P AA1 R T T AY1 M'],
  "partain": ['P AA0 R T EY1 N'],
  "partake": ['P AA0 R T EY1 K'],
  "partch": ['P AA1 R CH'],
  "parte": ['P AA1 R T', 'P AA1 R T EY0'],
  "partecipazioni": ['P AA2 R T EH2 S IH0 P AE2 Z IY0 OW1 N IY0'],
  "parted": ['P AA1 R T IH0 D'],
  "partee": ['P AA1 R T IY1'],
  "parten": ['P AA1 R T AH0 N'],
  "partenope": ['P AA1 R T AH0 N OW2 P'],
  "parthenia": ['P AA0 R TH EH1 N IY0 AH0'],
  "parthenogenetic": ['P AA2 R TH AH0 N OW0 JH AH0 N EH1 T IH0 K'],
  "parthenon": ['P AA1 R TH AH0 N AA2 N'],
  "parthian": ['P AA1 R TH IY0 AH0 N'],
  "parti": ['P AA1 R T IY0'],
  "partial": ['P AA1 R SH AH0 L'],
  "partiality": ['P AA2 R SH IY0 AE1 L AH0 T IY0'],
  "partially": ['P AA1 R SH AH0 L IY0', 'P AA1 R SH L IY0'],
  "participant": ['P AA0 R T IH1 S AH0 P AH0 N T'],
  "participants": ['P AA0 R T IH1 S AH0 P AH0 N T S'],
  "participants'": ['P AA0 R T IH1 S AH0 P AH0 N T S'],
  "participate": ['P AA0 R T IH1 S AH0 P EY2 T'],
  "participated": ['P AA0 R T IH1 S AH0 P EY2 T IH0 D'],
  "participates": ['P AA0 R T IH1 S AH0 P EY2 T S'],
  "participating": ['P AA0 R T IH1 S AH0 P EY2 T IH0 NG'],
  "participation": ['P AA0 R T IH2 S AH0 P EY1 SH AH0 N'],
  "participations": ['P AA0 R T IH2 S AH0 P EY1 SH AH0 N Z'],
  "participative": ['P AA2 R T IH1 S AH0 P AH0 T IH0 V'],
  "participatory": ['P AA2 R T IH1 S AH0 P AH0 T AO2 R IY0'],
  "participle": ['P AA1 R T IH0 S IH2 P AH0 L'],
  "participles": ['P AA1 R T IH0 S IH2 P AH0 L Z'],
  "particle": ['P AA1 R T AH0 K AH0 L', 'P AA1 R T IH0 K AH0 L'],
  "particles": ['P AA1 R T AH0 K AH0 L Z', 'P AA1 R T IH0 K AH0 L Z'],
  "particular": ['P ER0 T IH1 K Y AH0 L ER0', 'P AA2 T IH1 K Y AH0 L ER0'],
  "particularity": ['P ER0 T IH2 K Y AH0 L AE1 R AH0 T IY0'],
  "particularly": ['P AA2 R T IH1 K Y AH0 L ER0 L IY0', 'P ER0 T IH1 K Y AH0 L ER0 L IY0'],
  "particulars": ['P ER0 T IH1 K Y AH0 L ER0 Z'],
  "particulate": ['P ER0 T IH1 K Y AH0 L AH0 T'],
  "particulates": ['P AA2 R T IH1 K Y AH0 L AH0 T S'],
  "partida": ['P AA0 R T IY1 D AH0'],
  "partido": ['P AA0 R T IY1 D OW0'],
  "partied": ['P AA1 R T IY0 D'],
  "parties": ['P AA1 R T IY0 Z'],
  "parties'": ['P AA1 R T IY0 Z', 'P AA1 R T IY2 Z'],
  "partin": ['P AA1 R T IH0 N'],
  "parting": ['P AA1 R T IH0 NG'],
  "partington": ['P AA1 R T IH0 NG T AH0 N'],
  "partisan": ['P AA1 R T AH0 Z AH0 N'],
  "partisaned": ['P AA1 R T AH0 Z AH0 N D'],
  "partisans": ['P AA1 R T AH0 Z AH0 N Z'],
  "partisanship": ['P AA1 R T AH0 Z AH0 N SH IH2 P'],
  "partition": ['P AA0 R T IH1 SH AH0 N'],
  "partitioned": ['P AA0 R T IH1 SH AH0 N D'],
  "partitioning": ['P AA0 R T IH1 SH AH0 N IH0 NG'],
  "partitions": ['P AA0 R T IH1 SH AH0 N Z'],
  "partlow": ['P AA1 R T L OW2'],
  "partly": ['P AA1 R T L IY0'],
  "partner": ['P AA1 R T N ER0'],
  "partner's": ['P AA1 R T N ER0 Z'],
  "partnered": ['P AA1 R T N ER0 D'],
  "partnering": ['P AA1 R T N ER0 IH0 NG'],
  "partners": ['P AA1 R T N ER0 Z'],
  "partners'": ['P AA1 R T N ER0 Z'],
  "partnership": ['P AA1 R T N ER0 SH IH2 P'],
  "partnership's": ['P AA1 R T N ER0 SH IH2 P S'],
  "partnerships": ['P AA1 R T N ER0 SH IH2 P S'],
  "partnerships'": ['P AA1 R T N ER0 SH IH0 P S'],
  "partney": ['P AA1 R T N IY0'],
  "parton": ['P AA1 R T AH0 N'],
  "partridge": ['P AA1 R T R AH0 JH', 'P AA1 R T R IH0 JH'],
  "partridges": ['P AA1 R T R IH0 JH IH0 Z'],
  "parts": ['P AA1 R T S'],
  "parts'": ['P AA1 R T S'],
  "parttime": ['P AA1 R T T AY2 M'],
  "partum": ['P AA1 R T AH0 M'],
  "party": ['P AA1 R T IY0'],
  "party's": ['P AA1 R T IY0 Z'],
  "partying": ['P AA1 R T IY0 IH0 NG'],
  "partyka": ['P ER0 T IH1 K AH0'],
  "parvin": ['P AA1 R V IH0 N'],
  "pary": ['P AA1 R IY0'],
  "parziale": ['P AA2 R Z IY0 AA1 L IY0'],
  "parzych": ['P AA1 R Z IH0 HH'],
  "pas": ['P AA1 Z'],
  "pas-de-deux": ['P AA1 D AH0 D UW1'],
  "pasadena": ['P AE2 S AH0 D IY1 N ER0'],
  "pascagoula": ['P AE2 S K AH0 G UW1 L AH0'],
  "pascal": ['P AE0 S K AE1 L'],
  "pascale": ['P AE0 S K AE1 L'],
  "pascarella": ['P AA0 S K AA0 R EH1 L AH0'],
  "pascarelli": ['P AA0 S K AA0 R EH1 L IY0'],
  "pasch": ['P AE1 S K'],
  "paschal": ['P AE1 SH AH0 L'],
  "paschall": ['P AE1 SH AH0 L'],
  "paschen": ['P AE1 SH AH0 N'],
  "paschke": ['P AE1 SH K'],
  "pasco": ['P AA1 S K OW0'],
  "pascoe": ['P AE1 S K OW0'],
  "pascua": ['P AA0 S K UW1 AH0'],
  "pascual": ['P AE1 S K UW0 AH0 L'],
  "pascucci": ['P AA0 S K UW1 CH IY0'],
  "pascutto": ['P AH0 S K Y UW1 T OW0'],
  "pascuzzi": ['P AA0 S K UW1 T S IY0'],
  "pasek": ['P AA1 S EH0 K'],
  "paseo": ['P AA0 S EY1 OW2'],
  "pash": ['P AE1 SH'],
  "pasha": ['P AH0 SH AA1', 'P AA1 SH AH0'],
  "pashley": ['P AE1 SH L IY0'],
  "pasillas": ['P AA0 S IH1 L AH0 Z'],
  "pasing": ['P EY1 S IH0 NG'],
  "pasion": ['P AA0 ZH IY1 N'],
  "pask": ['P AE1 S K'],
  "paske": ['P EY1 S K'],
  "paskey": ['P AE1 S K IY0'],
  "pasko": ['P AA1 S K OW0'],
  "paslay": ['P AE1 S L EY0'],
  "pasley": ['P AE1 S L IY0'],
  "pasman": ['P AE1 S M AH0 N'],
  "paso": ['P AE1 S OW0'],
  "paso's": ['P AE1 S OW0 Z'],
  "pasok": ['P AA1 Z AA0 K', 'P AE1 S AO0 K'],
  "pasqua": ['P AA1 S K W AH0'],
  "pasquale": ['P AA0 S K W AA1 L EY0'],
  "pasquarella": ['P AA0 S K W AA0 R EH1 L AH0'],
  "pasquarelli": ['P AA0 S K W AA0 R EH1 L IY0'],
  "pasquarello": ['P AA0 S K W AA0 R EH1 L OW0'],
  "pasquariello": ['P AA0 S K W AA0 R IY0 EH1 L OW0'],
  "pasque": ['P AE1 S K'],
  "pasquinelli": ['P AA0 S K W IY0 N EH1 L IY0'],
  "pasquini": ['P AA0 S K W IY1 N IY0'],
  "pass": ['P AE1 S'],
  "passable": ['P AE1 S AH0 B AH0 L'],
  "passably": ['P AE1 S AH0 B L IY0'],
  "passafiume": ['P AA0 S AA0 F IY1 UW0 M'],
  "passage": ['P AE1 S AH0 JH', 'P AE1 S IH0 JH'],
  "passages": ['P AE1 S AH0 JH AH0 Z', 'P AE1 S IH0 JH IH0 Z'],
  "passageway": ['P AE1 S AH0 JH W EY2', 'P AE1 S IH0 JH W EY2'],
  "passaic": ['P AH0 S EY1 IH0 K'],
  "passalacqua": ['P AE2 S AH0 L AE1 K W AH0'],
  "passanisi": ['P AA0 S AA0 N IY1 S IY0'],
  "passante": ['P AA0 S AA1 N T IY0'],
  "passantino": ['P AA0 S AA0 N T IY1 N OW0'],
  "passarella": ['P AA0 S AA0 R EH1 L AH0'],
  "passarelli": ['P AA0 S AA0 R EH1 L IY0'],
  "passaretti": ['P AA0 S AA0 R EH1 T IY0'],
  "passaro": ['P AA0 S AA1 R OW0'],
  "passat": ['P AE1 S AE0 T'],
  "passbook": ['P AE1 S B UH2 K'],
  "passbooks": ['P AE1 S B UH2 K S'],
  "passe": ['P AE2 S EY1'],
  "passed": ['P AE1 S T'],
  "passel": ['P AE1 S IH0 L'],
  "passenger": ['P AE1 S AH0 N JH ER0'],
  "passenger's": ['P AE1 S AH0 N JH ER0 Z'],
  "passengers": ['P AE1 S AH0 N JH ER0 Z'],
  "passengers'": ['P AE1 S AH0 N JH ER0 Z'],
  "passer": ['P AE1 S ER0'],
  "passerby": ['P AE1 S ER0 B IY0'],
  "passero": ['P AA0 S EH1 R OW0'],
  "passers": ['P AE1 S ER0 Z'],
  "passers-by": ['P AE1 S ER0 Z B AY2'],
  "passersby": ['P AE1 S ER0 Z B IY0'],
  "passes": ['P AE1 S AH0 Z', 'P AE1 S IH0 Z'],
  "passey": ['P AE1 S IY0'],
  "passim": ['P AE0 S IY1 M'],
  "passing": ['P AE1 S IH0 NG'],
  "passino": ['P AA0 S IY1 N OW0'],
  "passion": ['P AE1 SH AH0 N'],
  "passionate": ['P AE1 SH AH0 N AH0 T'],
  "passionately": ['P AE1 SH AH0 N AH0 T L IY0'],
  "passions": ['P AE1 SH AH0 N Z'],
  "passive": ['P AE1 S IH0 V'],
  "passively": ['P AE1 S IH0 V L IY0'],
  "passivity": ['P AH0 S IH1 V IH0 T IY0'],
  "passman": ['P AE1 S M AH0 N'],
  "passmore": ['P AA1 S M AO0 R'],
  "passon": ['P AE1 S AH0 N'],
  "passover": ['P AE1 S OW2 V ER0'],
  "passow": ['P AE1 S OW0'],
  "passport": ['P AE1 S P AO2 R T'],
  "passports": ['P AE1 S P AO2 R T S'],
  "password": ['P AE1 S W ER2 D'],
  "passwords": ['P AE1 S W ER2 D Z'],
  "past": ['P AE1 S T'],
  "pasta": ['P AA1 S T AH0'],
  "pastas": ['P AA1 S T AH0 Z'],
  "paste": ['P EY1 S T'],
  "pasted": ['P EY1 S T IH0 D'],
  "pastel": ['P AE0 S T EH1 L'],
  "pastels": ['P AE0 S T EH1 L Z'],
  "paster": ['P AE1 S T ER0'],
  "pasternack": ['P AE1 S T ER0 N AE0 K'],
  "pasternak": ['P AE1 S T ER0 N AE0 K'],
  "pasternak's": ['P AE1 S T ER0 N AE0 K S'],
  "pastes": ['P EY1 S T S'],
  "pasteur": ['P AH0 S T UW1 R'],
  "pasteurization": ['P AE2 S CH ER0 AH0 Z EY1 SH AH0 N'],
  "pasteurize": ['P AE1 S CH ER0 AY2 Z'],
  "pasteurized": ['P AE1 S CH ER0 AY2 Z D'],
  "pasteurizes": ['P AE1 S CH ER0 AY2 Z IH0 Z'],
  "pasteurizing": ['P AE1 S CH ER0 AY2 Z IH0 NG'],
  "pastiche": ['P AE2 S T IY1 SH'],
  "pastiches": ['P AE2 S T IY1 SH AH0 Z'],
  "pastime": ['P AE1 S T AY2 M'],
  "pastimes": ['P AE1 S T AY2 M Z'],
  "pasting": ['P EY1 S T IH0 NG'],
  "pastor": ['P AE1 S T ER0'],
  "pastor's": ['P AE1 S T ER0 Z'],
  "pastora": ['P AE0 S T AO1 R AH0'],
  "pastoral": ['P AE1 S T ER0 AH0 L'],
  "pastoralism": ['P AE1 S T ER0 AH0 L IH2 Z AH0 M'],
  "pastoralize": ['P AE1 T ER0 AH0 L AY2 Z'],
  "pastore": ['P AE1 S T AO2 R'],
  "pastorino": ['P AA0 S T AO0 R IY1 N OW0'],
  "pastorius": ['P AE1 S T AO0 R IY0 IH0 S'],
  "pastors": ['P AE1 S T ER0 Z'],
  "pastrami": ['P AH0 S T R AA1 M IY0'],
  "pastrana": ['P AA0 S T R AE1 N AH0'],
  "pastries": ['P EY1 S T R IY0 Z'],
  "pastry": ['P EY1 S T R IY0'],
  "pasts": ['P AE1 S T S'],
  "pastula": ['P AA0 S T UW1 L AH0'],
  "pasture": ['P AE1 S CH ER0'],
  "pastures": ['P AE1 S CH ER0 Z'],
  "paszek": ['P AA1 SH EH0 K'],
  "paszkiewicz": ['P AA1 SH K AH0 V IH0 CH'],
  "pasztor": ['P AE1 S T ER0'],
  "pat": ['P AE1 T'],
  "pat's": ['P AE1 T S'],
  "patagonia": ['P AE2 T AH0 G OW1 N IY0 AH0'],
  "patagonian": ['P AE2 T AH0 G OW1 N IY0 AH0 N'],
  "patak": ['P AE1 T AH0 K'],
  "pataki": ['P AH0 T AA1 K IY0'],
  "pataki's": ['P AH0 T AA1 K IY0 Z'],
  "pataky": ['P AE1 T AH0 K IY0'],
  "patalano": ['P AA0 T AA0 L AA1 N OW0'],
  "patane": ['P AE1 T AH0 N'],
  "patasse": ['P AA2 T AA1 S'],
  "patch": ['P AE1 CH'],
  "patched": ['P AE1 CH T'],
  "patchell": ['P AE1 CH AH0 L'],
  "patchen": ['P AE1 CH AH0 N'],
  "patches": ['P AE1 CH AH0 Z', 'P AE1 CH IH0 Z'],
  "patchett": ['P AE1 CH IH0 T'],
  "patchin": ['P AE1 CH IH0 N'],
  "patching": ['P AE1 CH IH0 NG'],
  "patchwork": ['P AE1 CH W ER2 K'],
  "patchworks": ['P AE1 CH W ER2 K S'],
  "patchy": ['P AE1 CH IY0'],
  "patco": ['P AE1 T K OW0'],
  "pate": ['P EY1 T'],
  "patek": ['P AA1 T EH0 K'],
  "patel": ['P AH0 T EH1 L'],
  "patella": ['P AH0 T EH1 L AH0'],
  "patenaude": ['P AE1 T IH0 N OW0 D'],
  "patent": ['P AE1 T AH0 N T'],
  "patentable": ['P AE1 T AH0 N T AH0 B AH0 L'],
  "patented": ['P AE1 T AH0 N T IH0 D'],
  "patenting": ['P AE1 T AH0 N T IH0 NG'],
  "patently": ['P AE1 T AH0 N T L IY0'],
  "patents": ['P AE1 T AH0 N T S'],
  "pater": ['P EY1 T ER0'],
  "patera": ['P AA0 T EH1 R AH0'],
  "paternal": ['P AH0 T ER1 N AH0 L'],
  "paternalism": ['P AH0 T ER1 N AH0 L IH2 Z AH0 M'],
  "paternalistic": ['P AH0 T ER2 N AH0 L IH1 S T IH0 K'],
  "paternity": ['P AH0 T ER1 N IH0 T IY0'],
  "paterno": ['P AA0 T EH1 R N OW0'],
  "paternostro": ['P AA0 T ER0 N OW1 S T R OW0'],
  "paterson": ['P AE1 T ER0 S AH0 N'],
  "pates": ['P EY1 T S'],
  "patese": ['P AH0 T IY1 S IY0', 'P AH0 T IY1 S'],
  "patey": ['P EY1 T IY0'],
  "path": ['P AE1 TH'],
  "pathak": ['P AH0 TH AA1 K', 'P AH0 T AA1 K'],
  "pathan": ['P AE1 TH AH0 N'],
  "pathans": ['P AE1 TH AH0 N Z'],
  "pathe": ['P AE1 TH'],
  "pathet-lao": ['P AE1 TH AH0 T L AW1'],
  "pathetic": ['P AH0 TH EH1 T IH0 K'],
  "pathetically": ['P AH0 TH EH1 T IH0 K AH0 L IY0', 'P AH0 TH EH1 T IH0 K L IY0'],
  "pathfinder": ['P AE1 TH F AY2 N D ER0'],
  "pathmark": ['P AE1 TH M AA2 R K'],
  "pathmark's": ['P AE1 TH M AA2 R K S'],
  "pathogen": ['P AE1 TH AH0 JH AH0 N'],
  "pathogenesis": ['P AE2 TH AH0 JH EH1 N AH0 S IH0 S'],
  "pathogenic": ['P AE2 TH AH0 JH EH1 N IH0 K'],
  "pathogens": ['P AE1 TH AH0 JH AH0 N Z'],
  "pathologic": ['P AE2 TH AH0 L AA1 JH IH0 K'],
  "pathological": ['P AE2 TH AH0 L AA1 JH IH0 K AH0 L'],
  "pathologically": ['P AE2 TH AH0 L AA1 JH IH0 K L IY0'],
  "pathologies": ['P AH0 TH AA1 L AH0 JH IY0 Z'],
  "pathologist": ['P AH0 TH AA1 L AH0 JH AH0 S T'],
  "pathologists": ['P AH0 TH AA1 L AH0 JH AH0 S T S'],
  "pathology": ['P AH0 TH AA1 L AH0 JH IY0'],
  "pathos": ['P EY1 TH AA0 S'],
  "paths": ['P AE1 DH Z', 'P AE1 TH S'],
  "pathway": ['P AE1 TH W EY2'],
  "pathways": ['P AE1 TH W EY2 Z'],
  "patience": ['P EY1 SH AH0 N S'],
  "patient": ['P EY1 SH AH0 N T'],
  "patient's": ['P EY1 SH AH0 N T S'],
  "patiently": ['P EY1 SH AH0 N T L IY0'],
  "patients": ['P EY1 SH AH0 N T S', 'P EY1 SH AH0 N Z'],
  "patients'": ['P EY1 SH AH0 N T S'],
  "patillo": ['P AH0 T IH1 L OW0'],
  "patin": ['P AE1 T IH0 N'],
  "patina": ['P AH0 T IY1 N AH0'],
  "patinkin": ['P AH0 T IH1 NG K IH0 N'],
  "patino": ['P AA0 T IY1 N OW0'],
  "patio": ['P AE1 T IY0 OW2'],
  "patios": ['P AE1 T IY0 OW2 Z'],
  "patlan": ['P AE1 T L AH0 N'],
  "patlex": ['P AE1 T L EH0 K S'],
  "patlex's": ['P AE1 T L EH0 K S IH0 Z'],
  "patman": ['P AE1 T M AH0 N'],
  "patmore": ['P AE1 T M AO0 R'],
  "patnaude": ['P AA0 T N AO1 D IY0'],
  "patnode": ['P AE1 T N OW2 D'],
  "patois": ['P AE2 T W AA1'],
  "paton": ['P AE1 T AH0 N'],
  "patons": ['P AE1 T AH0 N Z'],
  "patriarca": ['P AA0 T R IY0 AA1 R K AH0'],
  "patriarch": ['P EY1 T R IY0 AA2 R K'],
  "patriarch's": ['P EY1 T R IY0 AA2 R K S'],
  "patriarchal": ['P EY2 T R IY0 AA1 R K AH0 L'],
  "patriarchate": ['P EY1 T R IY0 AA2 R K AH0 T'],
  "patriarchs": ['P EY1 T R IY0 AA2 R K S'],
  "patriarchy": ['P EY1 T R IY0 AA2 R K IY0'],
  "patrice": ['P AH0 T R IY1 S'],
  "patricelli": ['P AA0 T R IY0 CH EH1 L IY0'],
  "patricia": ['P AH0 T R IH1 SH AH0'],
  "patrician": ['P AH0 T R IH1 SH AH0 N'],
  "patricians": ['P AH0 T R IH1 SH AH0 N Z'],
  "patricio": ['P AH0 T R IH1 S IY0 OW0'],
  "patrick": ['P AE1 T R IH0 K'],
  "patrick's": ['P AE1 T R IH0 K S'],
  "patricks": ['P AE1 T R IH0 K S'],
  "patricof": ['P AE1 T R IH0 K AO2 F'],
  "patridge": ['P AE1 T R IH2 JH'],
  "patrie": ['P AE1 T ER0 IY0'],
  "patrilineal": ['P AE2 T R IH0 L IH1 N IY0 AH0 L'],
  "patrimonial": ['P AE1 T R AH0 M OW2 N Y AH0 L', 'P AE1 T R AH0 M OW2 N IY0 AH0 L'],
  "patrimony": ['P AE1 T R AH0 M OW2 N IY0'],
  "patriot": ['P EY1 T R IY0 AH0 T'],
  "patriot's": ['P EY1 T R IY0 AH0 T S'],
  "patriotic": ['P EY2 T R IY0 AA1 T IH0 K'],
  "patriotism": ['P EY1 T R IY0 AH0 T IH2 Z AH0 M'],
  "patriots": ['P EY1 T R IY0 AH0 T S'],
  "patristic": ['P AH0 T R IH1 S T IH0 K'],
  "patrizio": ['P AA0 T R IY1 Z IY0 OW0'],
  "patrol": ['P AH0 T R OW1 L'],
  "patrol's": ['P AH0 T R OW1 L Z'],
  "patrolled": ['P AH0 T R OW1 L D'],
  "patrolling": ['P AH0 T R OW1 L IH0 NG'],
  "patrolman": ['P AH0 T R OW1 L M AE2 N'],
  "patrolmen": ['P AH0 T R OW0 L M EH1 N'],
  "patrols": ['P AH0 T R OW1 L Z'],
  "patron": ['P EY1 T R AH0 N'],
  "patronage": ['P AE1 T R AH0 N IH0 JH', 'P EY1 T R AH0 N AH0 JH', 'P EY1 T R AH0 N IH0 JH'],
  "patrone": ['P AA0 T R OW1 N IY0'],
  "patroness": ['P EY1 T R AH0 N AH0 S'],
  "patronize": ['P EY1 T R AH0 N AY2 Z', 'P AE1 T R AH0 N AY2 Z'],
  "patronized": ['P EY1 T R AH0 N AY2 Z D', 'P AE1 T R AH0 N AY2 Z D'],
  "patronizes": ['P EY1 T R AH0 N AY2 Z AH0 Z'],
  "patronizing": ['P EY1 T R AH0 N AY2 Z IH0 NG'],
  "patrons": ['P EY1 T R AH0 N Z'],
  "patronym": ['P AE2 T R AH0 N IH1 M'],
  "patronymic": ['P AE2 T R AH0 N IH1 M IH0 K'],
  "patry": ['P AE1 T R IY0'],
  "pats": ['P AE1 T S'],
  "patsies": ['P AE1 T S IY0 Z'],
  "patsy": ['P AE1 T S IY0'],
  "patt": ['P AE1 T'],
  "patted": ['P AE1 T IH0 D'],
  "pattee": ['P AE1 T IY1'],
  "patten": ['P AE1 T AH0 N'],
  "patten's": ['P AE1 T AH0 N Z'],
  "patter": ['P AE1 T ER0'],
  "pattered": ['P AE1 T ER0 D'],
  "pattern": ['P AE1 T ER0 N'],
  "patterned": ['P AE1 T ER0 N D'],
  "patterns": ['P AE1 T ER0 N Z'],
  "patterson": ['P AE1 T ER0 S AH0 N'],
  "patterson's": ['P AE1 T ER0 S AH0 N Z'],
  "patteson": ['P AE1 T IH0 S AH0 N'],
  "patti": ['P AE1 T IY0'],
  "pattie": ['P AE1 T IY0'],
  "patties": ['P AE1 T IY0 Z'],
  "pattillo": ['P AA0 T IH1 L OW2'],
  "pattin": ['P AE1 T IH0 N'],
  "patting": ['P AE1 T IH0 NG'],
  "pattinson": ['P AE1 T IH0 N S AH0 N'],
  "pattis": ['P AE1 T IH0 S'],
  "pattison": ['P AE1 T IH0 S AH0 N'],
  "pattiz": ['P AE1 T IH0 Z'],
  "patton": ['P AE1 T AH0 N'],
  "patton's": ['P AE1 T AH0 N Z'],
  "patty": ['P AE1 T IY0'],
  "patty's": ['P AE1 T IY0 Z'],
  "patuxent": ['P AH0 T AH1 K S AH0 N T'],
  "patuxet": ['P AH0 T AH1 K S AH0 T'],
  "patuxets": ['P AH0 T AH1 K S AH0 T S'],
  "paty": ['P EY1 T IY2'],
  "patz": ['P AE1 T S'],
  "patzer": ['P EY1 T Z ER0'],
  "patzke": ['P AE1 T S K IY0'],
  "paucity": ['P AO1 S AH0 T IY2'],
  "paugh": ['P AO1'],
  "paul": ['P AO1 L'],
  "paul's": ['P AO1 L Z'],
  "paula": ['P AO1 L AH0'],
  "paulding": ['P AO1 L D IH0 NG'],
  "paule": ['P AO1 L'],
  "paules": ['P AO1 L Z'],
  "pauletta": ['P AA0 L EH1 T AH0'],
  "paulette": ['P AO0 L EH1 T'],
  "pauley": ['P AO1 L IY0'],
  "paulhamus": ['P AO1 L HH EY2 M AH0 S'],
  "paulhus": ['P AW1 L HH IH0 S'],
  "pauli": ['P AO1 L IY2'],
  "paulick": ['P AO1 L IH0 K'],
  "paulie": ['P AO1 L IY2'],
  "paulik": ['P AO1 L IH0 K'],
  "paulin": ['P AO1 L IH0 N'],
  "paulina": ['P AO2 L IY1 N AH0'],
  "pauline": ['P AO0 L IY1 N'],
  "pauling": ['P AO1 L IH0 NG'],
  "paulino": ['P AO0 L IY1 N OW0'],
  "paulita": ['P AO0 L IY1 T AH0'],
  "paulk": ['P AO1 L K'],
  "paull": ['P AO1 L'],
  "paullin": ['P AO1 L IH0 N'],
  "paulo": ['P AO1 L OW2'],
  "paulos": ['P AW1 L OW2 Z'],
  "pauls": ['P AO1 L Z'],
  "paulsen": ['P AW1 L S AH0 N'],
  "paulson": ['P AO1 L S AH0 N'],
  "paulus": ['P AO1 L AH0 S'],
  "pauly": ['P AO1 L IY0'],
  "paunchy": ['P AO1 N CH IY0'],
  "paup": ['P AO1 P'],
  "pauper": ['P AO1 P ER0'],
  "paupers": ['P AO1 P ER0 Z'],
  "pause": ['P AO1 Z'],
  "paused": ['P AO1 Z D'],
  "pauses": ['P AO1 Z AH0 Z', 'P AO1 Z IH0 Z'],
  "pausing": ['P AO1 Z IH0 NG'],
  "paustian": ['P AO1 Z CH IH0 N'],
  "pautler": ['P AW1 T AH0 L ER0', 'P AW1 T L ER0'],
  "pautsch": ['P AW1 CH'],
  "pautz": ['P AO1 T S'],
  "pauwels": ['P AW1 W AH0 L Z'],
  "pavao": ['P AA1 V AW0'],
  "pavarotti": ['P AE2 V ER0 AA1 T IY0'],
  "pavarotti's": ['P AE2 V ER0 AA1 T IY0 Z'],
  "pave": ['P EY1 V'],
  "paved": ['P EY1 V D'],
  "pavek": ['P AE1 V IH0 K'],
  "pavel": ['P AE1 V AH0 L'],
  "pavelic": ['P AH0 V EH1 L IH0 K', 'P AE1 V L IH0 K'],
  "pavelka": ['P AH0 V EH1 L K AH0'],
  "pavelko": ['P AH0 V EH1 L K OW0'],
  "pavement": ['P EY1 V M AH0 N T'],
  "pavements": ['P EY1 V M AH0 N T S'],
  "paver": ['P EY1 V ER0'],
  "paves": ['P EY1 V Z'],
  "pavese": ['P AA0 V EY1 Z IY0'],
  "pavey": ['P EY1 V IY0'],
  "pavia": ['P EY1 V IY0 AH0'],
  "pavich": ['P AE1 V IH0 CH'],
  "pavilion": ['P AH0 V IH1 L Y AH0 N'],
  "pavilions": ['P AH0 V IH1 L Y AH0 N Z'],
  "pavillion": ['P AH0 V IH1 L Y AH0 N'],
  "paving": ['P EY1 V IH0 NG'],
  "pavlak": ['P AA1 V L AH0 K'],
  "pavlic": ['P AE1 V L IH0 K'],
  "pavlica": ['P AE1 V L IH0 K AH0'],
  "pavlicek": ['P AA1 V L IH0 CH EH0 K'],
  "pavlich": ['P AA1 V L IH0 HH'],
  "pavlick": ['P AE1 V L IH0 K'],
  "pavlik": ['P AE1 V L IH0 K'],
  "pavlis": ['P AE1 V L IH0 S'],
  "pavlock": ['P AE1 V L AH0 K'],
  "pavlov": ['P AE1 V L AA0 V'],
  "pavlovian": ['P AE2 V L OW1 V IY0 AH0 N'],
  "pavlovic": ['P AH0 V L AA1 V IH0 K'],
  "pavlovich": ['P AE1 V L AH0 V IH0 CH'],
  "pavo": ['P AA1 V OW0'],
  "pavon": ['P AA1 V AH0 N'],
  "pavone": ['P AH0 V OW1 N'],
  "paw": ['P AO1'],
  "pawed": ['P AO1 D'],
  "pawelek": ['P AA0 V EH1 L EH0 K'],
  "pawelski": ['P AA0 V EH1 L S K IY0'],
  "pawelsky": ['P AA0 V EH1 L S K IY0'],
  "pawing": ['P AO1 IH0 NG'],
  "pawlak": ['P AO1 L AH0 K'],
  "pawley": ['P AO1 L IY0'],
  "pawlicki": ['P AA0 V L IH1 T S K IY0'],
  "pawlik": ['P AO1 L IH0 K'],
  "pawlikowski": ['P AA0 V L IH0 K AO1 F S K IY0'],
  "pawling": ['P AO1 L IH0 NG'],
  "pawloski": ['P AA0 V L AW1 S K IY0'],
  "pawlowicz": ['P AA1 V L AH0 V IH0 CH'],
  "pawlowski": ['P AA0 V L AO1 F S K IY0'],
  "pawluk": ['P AA1 V L AH0 K'],
  "pawn": ['P AO1 N'],
  "pawned": ['P AO1 N D'],
  "pawnee": ['P AO1 N IY1'],
  "pawnees": ['P AO1 N IY1 Z'],
  "pawns": ['P AO1 N Z'],
  "pawnshop": ['P AO1 N SH AA2 P'],
  "pawnshops": ['P AO1 N SH AA2 P S'],
  "paws": ['P AO1 Z'],
  "pawson": ['P AO1 S AH0 N'],
  "pawtucket": ['P AO2 T AH1 K IH0 T'],
  "pawtuxet": ['P AO2 T AH1 K S AH0 T'],
  "pax": ['P AE1 K S'],
  "paxar": ['P AE1 K S ER0'],
  "paxman": ['P AE1 K S M AH0 N'],
  "paxon": ['P AE1 K S AH0 N'],
  "paxson": ['P AE1 K S AH0 N'],
  "paxton": ['P AE1 K S T AH0 N'],
  "pay": ['P EY1'],
  "pay'n": ['P EY1 AH0 N'],
  "payable": ['P EY1 AH0 B AH0 L'],
  "payables": ['P EY1 AH0 B AH0 L Z'],
  "payan": ['P EY1 AH0 N'],
  "payback": ['P EY1 B AE2 K'],
  "paybacks": ['P EY1 B AE2 K S'],
  "paycheck": ['P EY1 CH EH2 K'],
  "paychecks": ['P EY1 CH EH2 K S'],
  "paychex": ['P EY1 CH EH2 K S'],
  "payco": ['P EY1 K OW0'],
  "payday": ['P EY1 D EY2'],
  "paye": ['P EY1'],
  "payee": ['P EY2 IY1'],
  "payee's": ['P EY2 IY1 Z'],
  "payer": ['P EY1 ER0'],
  "payer's": ['P EY1 ER0 Z'],
  "payers": ['P EY1 ER0 Z'],
  "payers'": ['P EY1 ER0 Z'],
  "payette": ['P EY1 EH1 T'],
  "payeur": ['P EY0 ER1'],
  "payin'": ['P EY1 IH0 N'],
  "paying": ['P EY1 IH0 NG'],
  "payless": ['P EY1 L EH2 S'],
  "payload": ['P EY1 L OW2 D'],
  "payload's": ['P EY1 L OW2 D Z'],
  "payloads": ['P EY1 L OW2 D Z'],
  "paylor": ['P EY1 L ER0'],
  "payment": ['P EY1 M AH0 N T'],
  "payments": ['P EY1 M AH0 N T S'],
  "paymer": ['P EY1 M ER0'],
  "payna": ['P EY1 N AH0'],
  "payne": ['P EY1 N'],
  "paynter": ['P EY1 N T ER0'],
  "payoff": ['P EY1 AO2 F'],
  "payoffs": ['P EY1 AO2 F S'],
  "payola": ['P EY2 OW1 L AH0'],
  "payout": ['P EY1 AW2 T'],
  "payouts": ['P EY1 AW2 T S'],
  "paypal": ['P EY1 P AH2 L'],
  "paypal's": ['P EY1 P AH0 L Z'],
  "payroll": ['P EY1 R OW2 L'],
  "payroll's": ['P EY1 R OW2 L Z'],
  "payrolls": ['P EY1 R OW2 L Z'],
  "pays": ['P EY1 Z'],
  "paysinger": ['P EY1 S IH0 NG G ER0', 'P EY1 Z IH0 NG G ER0'],
  "payson": ['P EY1 Z AH0 N'],
  "paysop": ['P EY1 S AA2 P'],
  "paysops": ['P EY1 S AA2 P S'],
  "payton": ['P EY1 T AH0 N'],
  "paz": ['P AA1 Z'],
  "pazner": ['P AA1 Z N ER0'],
  "pazos": ['P AA1 Z OW0 Z'],
  "pazuto": ['P AH0 Z UW1 T OW0'],
  "pc": ['P IY1 S IY1'],
  "pc's": ['P IY1 S IY1 Z'],
  "pcs": ['P IY1 S IY1 Z'],
  "pdf": ['P IY2 D IY2 EH1 F'],
  "pea": ['P IY1'],
  "peabody": ['P IY1 B AA2 D IY0'],
  "peabody's": ['P IY1 B AA2 D IY0 Z'],
  "peace": ['P IY1 S'],
  "peaceable": ['P IY1 S AH0 B AH0 L'],
  "peaceably": ['P IY1 S AH0 B L IY0'],
  "peaceful": ['P IY1 S F AH0 L'],
  "peacefully": ['P IY1 S F AH0 L IY0'],
  "peacefulness": ['P IY1 S F AH0 L N AH0 S'],
  "peacekeeper": ['P IY1 S K IY2 P ER0'],
  "peacekeepers": ['P IY1 S K IY2 P ER0 Z'],
  "peacekeeping": ['P IY1 S K IY2 P IH0 NG'],
  "peacemaker": ['P IY1 S M EY2 K ER0'],
  "peacemaker's": ['P IY1 S M EY2 K ER0 Z'],
  "peacemakers": ['P IY1 S M EY2 K ER0 Z'],
  "peacemaking": ['P IY1 S M EY2 K IH0 NG'],
  "peacenik": ['P IY1 S N IH2 K'],
  "peacetime": ['P IY1 S T AY2 M'],
  "peach": ['P IY1 CH'],
  "peacher": ['P IY1 CH ER0'],
  "peaches": ['P IY1 CH AH0 Z', 'P IY1 CH IH0 Z'],
  "peachey": ['P IY1 CH IY0'],
  "peachtree": ['P IY1 CH T R IY2'],
  "peachy": ['P IY1 CH IY0'],
  "peacock": ['P IY1 K AA2 K'],
  "peacocks": ['P IY1 K AA2 K S'],
  "peaden": ['P EH1 D AH0 N'],
  "peafowl": ['P IY1 F AW2 L'],
  "peagler": ['P IY1 G L ER0'],
  "peak": ['P IY1 K'],
  "peake": ['P IY1 K'],
  "peaked": ['P IY1 K T'],
  "peakes": ['P IY1 K S'],
  "peakes'": ['P IY1 K S'],
  "peaking": ['P IY1 K IH0 NG'],
  "peaks": ['P IY1 K S'],
  "peaks'": ['P IY1 K S'],
  "peal": ['P IY1 L'],
  "peale": ['P IY1 L'],
  "pealer": ['P IY1 L ER0'],
  "peanut": ['P IY1 N AH0 T', 'P IY1 N AH2 T'],
  "peanuts": ['P IY1 N AH0 T S', 'P IY1 N AH2 T S'],
  "peapack": ['P IY1 P AE2 K'],
  "peapod": ['P IY1 P AO2 D'],
  "pear": ['P EH1 R'],
  "pearce": ['P IH1 R S'],
  "pearcy": ['P ER1 K IY0'],
  "pearl": ['P ER1 L'],
  "pearl-harbor": ['P ER1 L HH AA1 R B ER0'],
  "pearle": ['P ER1 L'],
  "pearline": ['P ER1 L AY0 N'],
  "pearling": ['P ER1 L IH0 NG'],
  "pearlman": ['P ER1 L M AE2 N'],
  "pearls": ['P ER1 L Z'],
  "pearlstein": ['P ER1 L S T AY2 N', 'P ER1 L S T IY2 N'],
  "pearlstine": ['P ER1 L S T AY2 N'],
  "pearly": ['P ER1 L IY0'],
  "pearman": ['P EH1 R M AH0 N'],
  "pears": ['P EH1 R Z'],
  "pearsall": ['P EH1 R S AO0 L'],
  "pearse": ['P ER1 S'],
  "pearson": ['P IH1 R S AH0 N'],
  "pearson's": ['P IH1 R S AH0 N Z'],
  "peart": ['P ER1 T'],
  "peary": ['P IY1 R IY0'],
  "peas": ['P IY1 Z'],
  "peasant": ['P EH1 Z AH0 N T'],
  "peasantry": ['P EH1 Z AH0 N T R IY0'],
  "peasants": ['P EH1 Z AH0 N T S'],
  "peasants'": ['P EH1 Z AH0 N T S'],
  "pease": ['P IY1 Z', 'P IY1 S'],
  "peasey": ['P IY1 Z IY0'],
  "peasey's": ['P IY1 Z IY0 Z'],
  "peaslee": ['P IY1 Z L IY2'],
  "peasley": ['P IY1 Z L IY0'],
  "peat": ['P IY1 T'],
  "peat's": ['P IY1 T S'],
  "peatross": ['P IY1 T R AH0 S'],
  "peaudouce": ['P OW1 D UW2 S'],
  "peavey": ['P IY1 V IY0'],
  "peavler": ['P IY1 V L ER0'],
  "peavy": ['P IY1 V IY0'],
  "peay": ['P IY1'],
  "pebble": ['P EH1 B AH0 L'],
  "pebbles": ['P EH1 B AH0 L Z'],
  "pebereau": ['P EH1 B ER0 OW2'],
  "pebereau's": ['P EH1 B ER0 OW2 Z'],
  "pebley": ['P EH1 B L IY0'],
  "pecan": ['P AH0 K AA1 N', 'P IY1 K AA2 N', 'P IH0 K AE1 N'],
  "pecanins": ['P EH1 K AH0 N IH0 N Z'],
  "pecanins'": ['P EH1 K AH0 N IH0 N Z'],
  "pecans": ['P IH0 K AE1 N Z', 'P IY1 K AA2 N Z', 'P AH0 K AA1 N Z'],
  "pecci": ['P EH1 CH IY0'],
  "pech": ['P EH1 K'],
  "pecha": ['P EH1 CH AH0'],
  "pechacek": ['P EH1 K AH0 S IH0 K'],
  "pechin": ['P EH1 CH IH0 N'],
  "pechiney": ['P EH1 CH IH0 N IY0'],
  "pechman": ['P EH1 K M AH0 N'],
  "pechora": ['P AH0 K AO1 R AH0', 'P EH1 K ER0 AH0'],
  "pecht": ['P EH1 K T'],
  "pecina": ['P EH0 CH IY1 N AH0'],
  "peck": ['P EH1 K'],
  "peck's": ['P EH1 K S'],
  "pecka": ['P EH1 K AH0'],
  "peckenpaugh": ['P IH0 K EH1 N P AO0'],
  "peckham": ['P EH1 K AH0 M'],
  "pecking": ['P EH1 K IH0 NG'],
  "peckinpaugh": ['P IH0 K IH1 N P AO0'],
  "peckman": ['P EH1 K M AH0 N'],
  "pecksniffian": ['P EH0 K S N IH1 F IY2 AH0 N'],
  "peco": ['P EY1 K OW0'],
  "peco's": ['P EY1 K OW0 Z'],
  "pecor": ['P EH1 K ER0'],
  "pecora": ['P EH0 K AO1 R AH0'],
  "pecoraro": ['P EH0 K AO0 R AA1 R OW0'],
  "pecore": ['P EH0 K AO1 R IY0'],
  "pecot": ['P EH1 K AH0 T'],
  "pectic": ['P EH1 K T IH0 K'],
  "pectin": ['P EH1 K T AH0 N', 'P EH1 K T IH0 N'],
  "pectoral": ['P EH1 K T ER0 AH0 L'],
  "pectoris": ['P EH1 K T AH0 R IH0 S'],
  "peculiar": ['P AH0 K Y UW1 L Y ER0', 'P IH0 K Y UW1 L Y ER0'],
  "peculiarities": ['P IH0 K Y UW2 L IY0 EH1 R AH0 T IY0 Z'],
  "peculiarity": ['P IH0 K Y UW2 L IY0 EH1 R AH0 T IY0'],
  "peculiarly": ['P IH0 K Y UW1 L Y ER0 L IY0'],
  "pecuniary": ['P EH0 K Y UW1 N IY0 EH2 R IY0'],
  "pedagogical": ['P EH2 D AH0 G AA1 JH IH0 K AH0 L'],
  "pedagogy": ['P EH1 D AH0 G OW2 JH IY0'],
  "pedal": ['P EH1 D AH0 L'],
  "pedaled": ['P EH1 D AH0 L D'],
  "pedaling": ['P EH1 D AH0 L IH0 NG', 'P EH1 D L IH0 NG'],
  "pedalled": ['P EH1 D AH0 L D'],
  "pedals": ['P EH1 D AH0 L Z'],
  "pedant": ['P EH1 D AH0 N T'],
  "pedantic": ['P AH0 D AE1 N T IH0 K'],
  "pedantry": ['P EH1 D AH0 N T R IY0'],
  "pedants": ['P EH1 D AH0 N T S'],
  "peddicord": ['P EH1 D IH0 K AO0 R D'],
  "peddie": ['P EH1 D IY0'],
  "peddle": ['P EH1 D AH0 L'],
  "peddled": ['P EH1 D AH0 L D'],
  "peddler": ['P EH1 D L ER0'],
  "peddlers": ['P EH1 D L ER0 Z'],
  "peddles": ['P EH1 D AH0 L Z'],
  "peddling": ['P EH1 D AH0 L IH0 NG', 'P EH1 D L IH0 NG'],
  "peddy": ['P EH1 D IY0'],
  "peden": ['P EH1 D AH0 N'],
  "pedersen": ['P EH1 D ER0 S AH0 N'],
  "pederson": ['P EH1 D ER0 S AH0 N'],
  "pedestal": ['P EH1 D AH0 S T AH0 L'],
  "pedestals": ['P EH1 D AH0 S T AH0 L Z'],
  "pedestrian": ['P AH0 D EH1 S T R IY0 AH0 N'],
  "pedestrians": ['P AH0 D EH1 S T R IY0 AH0 N Z'],
  "pediatric": ['P IY2 D IY0 AE1 T R IH0 K'],
  "pediatrician": ['P IY2 D IY0 AH0 T R IH1 SH AH0 N'],
  "pediatricians": ['P IY2 D IY0 AH0 T R IH1 SH AH0 N Z'],
  "pediatrics": ['P IY2 D IY0 AE1 T R IH0 K S'],
  "pedicure": ['P EH1 D IH0 K Y ER0', 'P EH1 D IH0 K Y AO0 R'],
  "pedicures": ['P EH1 D IH0 K Y ER0 Z', 'P EH1 D IH0 K Y AO0 R Z'],
  "pedigo": ['P EH0 D IY1 G OW0'],
  "pedigree": ['P EH1 D AH0 G R IY0'],
  "pedigrees": ['P EH1 D AH0 G R IY0 Z'],
  "pedley": ['P EH1 D L IY0'],
  "pedone": ['P EY0 D OW1 N EY0'],
  "pedophile": ['P EH1 D OW0 F AY0 L', 'P EH1 D AH0 F IH0 L'],
  "pedophiles": ['P EH1 D OW0 F AY0 L Z', 'P EH1 D AH0 F IH0 L Z'],
  "pedophilia": ['P EH2 D AH0 F IH1 L Y AH0'],
  "pedophiliac": ['P EH2 D AH0 F IH1 L Y AE0 K'],
  "pedophiliacs": ['P EH2 D AH0 F IH1 L Y AE0 K S'],
  "pedophilic": ['P EH0 D OW0 F IH1 L IH0 K'],
  "pedowitz": ['P EH1 D OW0 IH0 T S', 'P AH0 D AW1 IH0 T S'],
  "pedraza": ['P EY0 D R AA1 Z AH0'],
  "pedretti": ['P EH0 D R EH1 T IY0'],
  "pedrick": ['P EH1 D R IH0 K'],
  "pedro": ['P EY1 D R OW0'],
  "pedroli": ['P EH2 D R OW1 L IY0'],
  "pedros": ['P EY1 D R OW0 Z'],
  "pedrosa": ['P EY0 D R OW1 S AH0'],
  "pedroso": ['P EY0 D R OW1 S OW0'],
  "pedrotti": ['P EH0 D R OW1 T IY0'],
  "pedroza": ['P EY0 D R OW1 Z AH0'],
  "pee": ['P IY1'],
  "pee-pee": ['P IY1 P IY1'],
  "pee-wee": ['P IY1 W IY2'],
  "peebler": ['P IY1 B L ER0'],
  "peebles": ['P IY1 B AH0 L Z'],
  "peed": ['P IY1 D'],
  "peedin": ['P IY1 D IH0 N'],
  "peeing": ['P IY1 IH0 NG'],
  "peek": ['P IY1 K'],
  "peeked": ['P IY1 K T'],
  "peeking": ['P IY1 K IH0 NG'],
  "peeks": ['P IY1 K S'],
  "peel": ['P IY1 L'],
  "peele": ['P IY1 L'],
  "peeled": ['P IY1 L D'],
  "peeler": ['P IY1 L ER0'],
  "peelers": ['P IY1 L ER0 Z'],
  "peeling": ['P IY1 L IH0 NG'],
  "peels": ['P IY1 L Z'],
  "peens": ['P IY1 N Z'],
  "peep": ['P IY1 P'],
  "peeped": ['P IY1 P T'],
  "peeping": ['P IY1 P IH0 NG'],
  "peeples": ['P IY1 P AH0 L Z'],
  "peeps": ['P IY1 P S'],
  "peer": ['P IH1 R'],
  "peer-to-peer": ['P IH1 R T UW0 P IH1 R'],
  "peerage": ['P IH1 R AH0 JH'],
  "peered": ['P IH1 R D'],
  "peering": ['P IY1 R IH0 NG'],
  "peerless": ['P IH1 R L IH0 S'],
  "peers": ['P IH1 R Z'],
  "peerson": ['P IH1 R S AH0 N'],
  "peery": ['P IY1 R IY0'],
  "pees": ['P IY1 Z'],
  "peet": ['P IY1 T'],
  "peet's": ['P IY1 T S'],
  "peete": ['P IY1 T'],
  "peeters": ['P IY1 T ER0 Z'],
  "peets": ['P IY1 T S'],
  "peetz": ['P IY1 T S'],
  "peeve": ['P IY1 V'],
  "peeved": ['P IY1 V D'],
  "peeves": ['P IY1 V Z'],
  "peevey": ['P IY1 V IY0'],
  "peevish": ['P IY1 V IH0 SH'],
  "peevy": ['P IY1 V IY0'],
  "peewee": ['P IY1 W IY2'],
  "peffer": ['P EH1 F ER0'],
  "peffley": ['P EH1 F L IY0'],
  "peg": ['P EH1 G'],
  "pegasus": ['P EH1 G AH0 S AH0 S'],
  "pegboard": ['P EH1 G B AO2 R D'],
  "pegboards": ['P EH1 G B AO2 R D Z'],
  "pegg": ['P EH1 G'],
  "pegged": ['P EH1 G D'],
  "peggie": ['P EH1 G IY0'],
  "pegging": ['P EH1 G IH0 NG'],
  "peggs": ['P EH1 G Z'],
  "peggy": ['P EH1 G IY0'],
  "peglow": ['P EH1 G L OW2'],
  "pegmatite": ['P EH1 G M AH0 T AY2 T'],
  "pegram": ['P EH1 G R AE2 M'],
  "pegs": ['P EH1 G Z'],
  "peguero": ['P EY0 G EH1 R OW0'],
  "pegues": ['P EY1 G EH0 S'],
  "pehl": ['P EH1 L'],
  "pehrson": ['P EH1 R S AH0 N'],
  "pei": ['P EY1'],
  "pei's": ['P EY1 Z'],
  "peifer": ['P AY1 F ER0'],
  "peiffer": ['P AY1 F ER0'],
  "peil": ['P IY1 L'],
  "peinado": ['P EY0 IY0 N AA1 D OW0'],
  "peine": ['P IY1 N'],
  "peipu": ['P EY1 P UW2'],
  "peirce": ['P IH1 R S', 'P IY1 R S'],
  "peirson": ['P IY1 R S AH0 N'],
  "peiser": ['P AY1 S ER0'],
  "peitz": ['P IY1 T S'],
  "peixoto": ['P AH0 K S OW1 T OW0'],
  "pejorative": ['P AH0 JH AO1 R AH0 T IH0 V'],
  "pekala": ['P IH0 K AA1 L AH0'],
  "pekar": ['P EH1 K ER0'],
  "pekarek": ['P EH1 K ER0 IH0 K'],
  "pekin": ['P IY1 K AH0 N'],
  "peking": ['P IY1 K IH1 NG'],
  "peko": ['P IY1 K OW2'],
  "pekrul": ['P EH1 K R AH0 L'],
  "peladeau": ['P EH1 L AH0 D OW2'],
  "pelaez": ['P EY0 L AA1 EH0 Z'],
  "pelagia": ['P EH0 L AA1 JH IY0 AH0'],
  "pelagian": ['P IH0 L EY1 JH IY0 AH0 N'],
  "pelagians": ['P IH0 L EY1 JH IY0 AH0 N Z'],
  "pelagic": ['P AH0 L AE1 JH IH0 K'],
  "pelagreeny": ['P EH0 L AH0 G R IY1 N IY0'],
  "pelayo": ['P EY0 L EY1 OW0'],
  "pelc": ['P EH1 L K'],
  "pelchat": ['P EH1 L CH AH0 T'],
  "pelczar": ['P EH1 L CH ER0'],
  "pele": ['P EH1 L EY0'],
  "pelekunu": ['P EH2 L AH0 K UW1 N UW0'],
  "pelekunu's": ['P EH2 L AH0 K UW1 N UW0 Z'],
  "peleponnesian": ['P EH2 L AH0 P AH0 N IY1 ZH AH0 N'],
  "pelerin": ['P EH1 L ER0 IH0 N'],
  "peleton": ['P EH1 L AH0 T AO2 N'],
  "pelfrey": ['P EH1 L F R IY0'],
  "pelham": ['P EH1 L AH0 M'],
  "pelican": ['P EH1 L AH0 K AH0 N'],
  "pelican's": ['P EH1 L IH0 K AH0 N Z'],
  "pelicans": ['P EH1 L IH0 K AH0 N Z'],
  "pelikan": ['P EH1 L IH0 K AE0 N'],
  "pelino": ['P EH0 L IY1 N OW0'],
  "pelissier": ['P EH1 L IH0 S IY0 ER0'],
  "pelka": ['P EH1 L K AH0'],
  "pelkey": ['P EH1 L K IY0'],
  "pelky": ['P EH1 L K IY0'],
  "pell": ['P EH1 L'],
  "pell's": ['P EH1 L Z'],
  "pella": ['P EH1 L AH0'],
  "pellagra": ['P AH0 L AE1 G R AH0'],
  "pelland": ['P EH1 L AH0 N D'],
  "pelle": ['P EH1 L'],
  "pellecchia": ['P EH2 L EH1 K IY0 AH0'],
  "pellegrin": ['P EH1 L IH0 G R IH0 N'],
  "pellegrini": ['P EH2 L EH0 G R IY1 N IY0'],
  "pellegrino": ['P EH2 L EH0 G R IY1 N OW0'],
  "peller": ['P EH1 L ER0'],
  "pellerin": ['P EH1 L ER0 IH0 N'],
  "pellerito": ['P EH0 L ER0 IY1 T OW0'],
  "pellet": ['P EH1 L AH0 T'],
  "pelleted": ['P EH1 L AH0 T IH0 D'],
  "pelletier": ['P EH0 L AH0 T IH1 R'],
  "pellets": ['P EH1 L AH0 T S'],
  "pellett": ['P EH1 L IH0 T'],
  "pelleu": ['P EH1 L UW0'],
  "pelley": ['P EH1 L IY0'],
  "pellicane": ['P EH1 L IH0 K EY2 N'],
  "pellicano": ['P EH0 L IY0 K AA1 N OW0'],
  "pellman": ['P EH1 L M AH0 N'],
  "pellow": ['P EH1 L OW0'],
  "pellston": ['P EH1 L S T AH0 N'],
  "pellum": ['P EH1 L AH0 M'],
  "pelon": ['P EH1 L AH0 N'],
  "peloponnesian": ['P EH2 L AH0 P AH0 N IY1 ZH AH0 N'],
  "peloquin": ['P EY0 L OW0 K W IY1 N'],
  "pelosi": ['P EH0 L OW1 S IY0'],
  "peloso": ['P EH0 L OW1 S OW0'],
  "pelot": ['P EH1 L AH0 T'],
  "pelphrey": ['P EH1 L F R IY0'],
  "pels": ['P EH1 L Z'],
  "pelster": ['P EH1 L S T ER0'],
  "pelt": ['P EH1 L T'],
  "pelted": ['P EH1 L T IH0 D'],
  "peltier": ['P EH1 L T IY0 ER0'],
  "pelting": ['P EH1 L T IH0 NG'],
  "pelto": ['P EH1 L T OW0'],
  "pelton": ['P EH1 L T AH0 N'],
  "pelts": ['P EH1 L T S'],
  "peltz": ['P EH1 L T S'],
  "peltzer": ['P EH1 L T Z ER0'],
  "peluso": ['P EH0 L UW1 S OW0'],
  "pelvic": ['P EH1 L V IH0 K'],
  "pelvis": ['P EH1 L V AH0 S'],
  "pelz": ['P EH1 L Z'],
  "pelzel": ['P EH1 L Z AH0 L'],
  "pelzer": ['P EH1 L Z ER0'],
  "pember": ['P EH1 M B ER0'],
  "pemberton": ['P EH1 M B ER0 T AH0 N'],
  "pembina": ['P EH1 M B IH0 N AH0'],
  "pemble": ['P EH1 M B AH0 L'],
  "pembleton": ['P EH1 M B AH0 L T AA0 N'],
  "pembridge": ['P EH1 M B R IH2 JH'],
  "pembroke": ['P EH1 M B R OW2 K', 'P EH1 M B R UH0 K'],
  "pemex": ['P EH1 M EH2 K S'],
  "pemrich": ['P EH1 M R IH2 CH'],
  "pen": ['P EH1 N'],
  "pen's": ['P EH1 N Z'],
  "pena": ['P EH1 N AH0', 'P EY1 N Y AH0'],
  "pena's": ['P EH1 N AH0 Z', 'P EY1 N Y AH0 Z'],
  "penal": ['P IY1 N AH0 L'],
  "penalize": ['P EH1 N AH0 L AY2 Z', 'P IY1 N AH0 L AY2 Z'],
  "penalized": ['P IY1 N AH0 L AY2 Z D'],
  "penalizes": ['P EH1 N AH0 L AY2 Z IH0 Z', 'P IY1 N AH0 L AY2 Z IH0 Z'],
  "penalizing": ['P IY1 N AH0 L AY2 Z IH0 NG'],
  "penaloza": ['P EH0 N AA0 L OW1 Z AH0'],
  "penalties": ['P EH1 N AH0 L T IY0 Z'],
  "penalty": ['P EH1 N AH0 L T IY0'],
  "penance": ['P EH1 N AH0 N S'],
  "penang": ['P EH1 N AE0 NG'],
  "penans": ['P EH1 N AH0 N Z'],
  "penberthy": ['P IH0 N B ER1 TH IY0'],
  "pence": ['P EH1 N S'],
  "penchant": ['P EH1 N CH AH0 N T'],
  "pencil": ['P EH1 N S AH0 L'],
  "penciled": ['P EH1 N S AH0 L D'],
  "pencils": ['P EH1 N S AH0 L Z'],
  "pendant": ['P EH1 N D AH0 N T'],
  "pendarvis": ['P EH1 N D AA0 R V IH0 S'],
  "pendell": ['P EH1 N D AH0 L'],
  "pendelton": ['P IH0 N D EH1 L T AH0 N'],
  "pender": ['P EH1 N D ER0'],
  "pendergast": ['P EH1 N D ER0 G AE2 S T'],
  "pendergraft": ['P EH1 N D ER0 G R AH0 F T'],
  "pendergraph": ['P EH1 N D ER0 G R AE2 F'],
  "pendergrass": ['P EH1 N D ER0 G R AH0 S'],
  "pendergrast": ['P EH1 N D ER0 G R AH0 S T'],
  "penders": ['P EH1 N D ER0 Z'],
  "pending": ['P EH1 N D IH0 NG'],
  "pendleton": ['P EH1 N D AH0 L T AH0 N'],
  "pendley": ['P EH1 N D L IY0'],
  "pendola": ['P EH0 N D OW1 L AH0'],
  "pendril": ['P EH1 N D R IH0 L'],
  "pendril's": ['P EH1 N D R IH0 L Z'],
  "pendry": ['P EH1 N D R IY0'],
  "pendulous": ['P EH1 N JH AH0 L AH0 S'],
  "pendulum": ['P EH1 N JH AH0 L AH0 M'],
  "pendyala": ['P EH2 D Y AA1 L AH0'],
  "penelas": ['P EH1 N AH0 L AA0 S'],
  "penelope": ['P AH0 N EH1 L AH0 P IY0'],
  "penetrate": ['P EH1 N AH0 T R EY2 T'],
  "penetrated": ['P EH1 N AH0 T R EY2 T IH0 D'],
  "penetrates": ['P EH1 N AH0 T R EY2 T S'],
  "penetrating": ['P EH1 N AH0 T R EY2 T IH0 NG'],
  "penetration": ['P EH2 N AH0 T R EY1 SH AH0 N'],
  "penetrator": ['P EH1 N AH0 T R EY2 T ER0'],
  "penetrators": ['P EH1 N AH0 T R EY2 T ER0 Z'],
  "penfield": ['P EH1 N F IY2 L D'],
  "penfil": ['P EH1 N F IH0 L'],
  "penfold": ['P EH1 N F OW2 L D'],
  "peng": ['P EH1 NG'],
  "pengassan": ['P EH1 NG G AE2 S AH0 N'],
  "pengelly": ['P EH1 NG G AH0 L IY0'],
  "pengo": ['P EH1 NG G OW0'],
  "penguin": ['P EH1 NG G W AH0 N'],
  "penguins": ['P EH1 NG G W AH0 N Z'],
  "penh": ['P EH1 N'],
  "penh's": ['P EH1 N Z'],
  "penicillin": ['P EH2 N AH0 S IH1 L AH0 N'],
  "penicillins": ['P EH2 N AH0 S IH1 L AH0 N Z'],
  "penick": ['P EH1 N IH0 K'],
  "penikese": ['P EH1 N IH0 K IY2 Z'],
  "penile": ['P IY1 N AY0 L'],
  "peninger": ['P EH1 N IH0 NG ER0'],
  "peninsula": ['P AH0 N IH1 N S AH0 L AH0'],
  "peninsular": ['P AH0 N IH1 N S AH0 L ER0'],
  "penis": ['P IY1 N IH0 S'],
  "penises": ['P IY1 N IH0 S IH0 Z'],
  "peniston": ['P EH1 N IH0 S T AA0 N'],
  "penitent": ['P EH1 N IH0 T IH0 N T'],
  "penitentiaries": ['P EH2 N IH0 T EH1 N CH ER0 IY0 Z'],
  "penitentiary": ['P EH2 N IH0 T EH1 N CH ER0 IY0'],
  "penix": ['P EH1 N IH0 K S'],
  "penja": ['P EH1 N JH AH0'],
  "penja's": ['P EH1 N JH AH0 Z'],
  "penkala": ['P IH0 NG K AA1 L AH0'],
  "penkava": ['P EH0 NG K AA1 V AH0'],
  "penland": ['P EH1 N L AH0 N D'],
  "penley": ['P EH1 N L IY0'],
  "penman": ['P EH1 N M AH0 N'],
  "penn": ['P EH1 N'],
  "penn's": ['P EH1 N Z'],
  "penn-hills": ['P EH1 N HH IH0 L Z'],
  "penna": ['P EH1 N AH0', 'P EH2 N S IH0 L V EY1 N Y AH0'],
  "pennacchio": ['P EH0 N AA1 K IY0 OW0'],
  "pennant": ['P EH1 N AH0 N T'],
  "pennants": ['P EH1 N AH0 N T S'],
  "pennbancorp": ['P EH1 N B AE1 N K AO2 R P'],
  "penncorp": ['P EH1 N K AO2 R P'],
  "penne-pasta": ['P EH2 N EY0 P AA1 S T AH0'],
  "pennebaker": ['P EH1 N IH0 B AH0 K ER0', 'P EH1 N IH0 B EY2 K ER0'],
  "penned": ['P EH1 N D'],
  "pennel": ['P EH1 N AH0 L'],
  "pennell": ['P EH1 N AH0 L'],
  "pennella": ['P EH2 N EH1 L AH0'],
  "penner": ['P EH1 N ER0'],
  "pennex": ['P EH1 N AH0 K S'],
  "penney": ['P EH1 N IY0'],
  "penney's": ['P EH1 N IY0 Z'],
  "pennick": ['P EH1 N IH0 K'],
  "pennie": ['P EH1 N IY0'],
  "pennies": ['P EH1 N IY0 Z'],
  "penniless": ['P EH1 N IY0 L AH0 S'],
  "penniman": ['P EH1 N IH0 M AH0 N'],
  "penning": ['P EH1 N IH0 NG'],
  "penninger": ['P EH1 N IH0 NG ER0'],
  "pennings": ['P EH1 N IH0 NG Z'],
  "pennington": ['P EH1 N IH0 NG T AH0 N'],
  "pennino": ['P EH0 N IY1 N OW0'],
  "pennisi": ['P EH0 N IY1 S IY0'],
  "pennix": ['P EH1 N IH0 K S'],
  "pennock": ['P EH1 N AH0 K'],
  "penns": ['P EH1 N Z'],
  "pennsauken": ['P EH2 N S AO1 K AH0 N'],
  "pennsylvania": ['P EH2 N S AH0 L V EY1 N Y AH0'],
  "pennsylvania's": ['P EH2 N S AH0 L V EY1 N Y AH0 Z'],
  "pennsylvanian": ['P EH2 N S IH0 L V EY1 N IY0 AH0 N'],
  "pennsylvanians": ['P EH2 N S IH0 L V EY1 N IY0 AH0 N Z'],
  "pennwalt": ['P EH1 N W AO2 L T'],
  "penny": ['P EH1 N IY0'],
  "penny's": ['P EH1 N IY0 Z'],
  "pennypacker": ['P EH1 N IH0 P AE0 K ER0', 'P EH1 N IY0 P AE0 K ER0'],
  "pennywell": ['P EH1 N IY0 W EH2 L'],
  "pennzoil": ['P EH1 N Z OY2 L'],
  "pennzoil's": ['P EH1 N Z OY2 L Z'],
  "penobscot": ['P AH0 N AA1 B S K AA0 T'],
  "penology": ['P IY0 N AA1 L AH0 JH IY0'],
  "penoyer": ['P EH1 N OY0 ER0'],
  "penril": ['P EH1 N R IH0 L'],
  "penrod": ['P EH1 N R AA2 D'],
  "penrod's": ['P EH1 N R AA2 D Z'],
  "penrose": ['P EH1 N R OW2 Z'],
  "penry": ['P EH1 N R IY0'],
  "pens": ['P EH1 N Z'],
  "pens'": ['P EH1 N Z'],
  "pensacola": ['P EH2 N S AH0 K OW1 L AH0'],
  "pense": ['P EH1 N S'],
  "pensinger": ['P EH1 N S IH0 N JH ER0'],
  "pension": ['P EH1 N SH AH0 N'],
  "pensioner": ['P EH1 N SH AH0 N ER0'],
  "pensioners": ['P EH1 N SH AH0 N ER0 Z'],
  "pensions": ['P EH1 N SH AH0 N Z'],
  "pensive": ['P EH1 N S IH0 V'],
  "pensiveness": ['P EH1 N S IH0 V N EH2 S'],
  "penske": ['P EH1 N S K IY0'],
  "penson": ['P EH1 N S AH0 N'],
  "pensyl": ['P EH1 N S IH0 L'],
  "pent": ['P EH1 N T'],
  "penta": ['P EH1 N T AH0'],
  "pentagon": ['P EH1 N T IH0 G AA2 N', 'P EH1 N IH0 G AA2 N'],
  "pentagon's": ['P EH1 N T IH0 G AA2 N Z', 'P EH1 N IH0 G AA2 N Z'],
  "pentagons": ['P EH1 N T IH0 G AA2 N Z', 'P EH1 N IH0 G AA2 N Z'],
  "pentair": ['P EH2 N T EH1 R'],
  "pentamidine": ['P EH2 N T AE1 M IH0 D IY2 N'],
  "pentane": ['P EH1 N T EY2 N'],
  "pentathlon": ['P EH0 N T AE1 TH L AH0 N'],
  "pentax": ['P EH1 N T AE2 K S'],
  "pentech": ['P EH1 N T EH2 K'],
  "pentecost": ['P EH1 N T AH0 K AO2 S T'],
  "pentecostal": ['P EH2 N T AH0 K AO1 S T AH0 L'],
  "penthea": ['P EH1 N TH IY0 AH0'],
  "penthouse": ['P EH1 N T HH AW2 S'],
  "penthouses": ['P EH1 N T HH AW2 S IH0 Z'],
  "pentimento": ['P EH2 N T IH0 M EH1 N T OW0'],
  "pentium": ['P EH1 N T IY0 AH0 M'],
  "pentiums": ['P EH1 N T IY0 AH0 M Z'],
  "pentland": ['P EH1 N T L AH0 N D'],
  "penton": ['P EH1 N T AH0 N'],
  "pentothal": ['P EH1 N T AH0 TH AA0 L'],
  "pentron": ['P EH1 N T R AH0 N'],
  "pentrust": ['P EH1 N T R AH2 S T'],
  "pentz": ['P EH1 N T S'],
  "penuel": ['P EH1 N UH0 L'],
  "penultimate": ['P EH0 N AH1 L T AH0 M AH0 T'],
  "penumbra": ['P IH0 N AH1 M B R AH0'],
  "penurious": ['P EH2 N Y UW1 R IY0 AH0 S'],
  "penury": ['P EH1 N Y UH0 R IY0'],
  "penwell": ['P EH1 N W EH2 L'],
  "penwest": ['P EH2 N W EH1 S T'],
  "penza": ['P EH1 N Z AH0'],
  "penzer": ['P EH1 N Z ER0'],
  "peon": ['P IY1 AH0 N'],
  "peonies": ['P IY1 AH0 N IY0 Z'],
  "peons": ['P IY1 AH0 N Z'],
  "peony": ['P IY1 AH0 N IY0'],
  "people": ['P IY1 P AH0 L'],
  "people's": ['P IY1 P AH0 L Z'],
  "peopled": ['P IY1 P AH0 L D'],
  "peoples": ['P IY1 P AH0 L Z'],
  "peoples'": ['P IY1 P AH0 L Z'],
  "peoria": ['P IY0 AO1 R IY0 AH0'],
  "peoria's": ['P IY0 AO1 R IY0 AH0 Z'],
  "pep": ['P EH1 P'],
  "pepcid": ['P EH1 P S IH0 D'],
  "pepe": ['P EH1 P IY0'],
  "pepenadores": ['P EH2 P EH2 N AH0 D AO1 R EH0 Z'],
  "peper": ['P EH1 P ER0'],
  "pepi": ['P EH1 P IY0'],
  "pepin": ['P EH1 P IH0 N'],
  "pepita": ['P EH0 P IY1 T AH0'],
  "pepitone": ['P EH1 P IH0 T OW2 N'],
  "peplinski": ['P IH0 P L IH1 N S K IY0'],
  "peplow": ['P EH1 P L OW2'],
  "peppard": ['P EH1 P ER0 D'],
  "peppel": ['P EH1 P AH0 L'],
  "pepper": ['P EH1 P ER0'],
  "pepper's": ['P EH1 P ER0 Z'],
  "pepperdine": ['P EH1 P ER0 D AY2 N'],
  "peppered": ['P EH1 P ER0 D'],
  "pepperell": ['P EH1 P ER0 AH0 L'],
  "pepperidge": ['P EH1 P ER0 IH2 JH', 'P EH1 P R IH2 JH'],
  "peppering": ['P EH1 P ER0 IH0 NG'],
  "pepperman": ['P EH1 P ER0 M AH0 N'],
  "peppermint": ['P EH1 P ER0 M IH2 N T'],
  "pepperoni": ['P EH2 P ER0 OW1 N IY0'],
  "peppers": ['P EH1 P ER0 Z'],
  "peppi": ['P EH1 P IY0'],
  "peppin": ['P EH1 P IH0 N'],
  "pepple": ['P EH1 P AH0 L'],
  "peppler": ['P EH1 P L ER0'],
  "peppy": ['P EH1 P IY0'],
  "pepsi": ['P EH1 P S IY0'],
  "pepsi's": ['P EH1 P S IY0 Z'],
  "pepsico": ['P EH1 P S IH0 K OW0'],
  "pepsico's": ['P EH1 P S IH0 K OW0 Z'],
  "peptic": ['P EH1 P T IH0 K'],
  "peptide": ['P EH1 P T AY2 D'],
  "peptides": ['P EH1 P T AY2 D Z'],
  "pequeno": ['P EY0 K W EY1 N OW0'],
  "pequignot": ['P IH0 K W IH1 G N AH0 T'],
  "per": ['P ER1'],
  "per-se": ['P ER2 S EY1'],
  "pera": ['P ER1 AH0'],
  "peragine": ['P ER0 AA0 JH IY1 N IY0'],
  "peraino": ['P ER0 AA0 IY1 N OW0'],
  "perala": ['P ER0 AA1 L AH0'],
  "perales": ['P ER0 AA1 L EH0 S'],
  "peralez": ['P ER0 AA1 L EH0 Z'],
  "peralta": ['P ER0 AA1 L T AH0'],
  "peras": ['P EH1 R AH0 S'],
  "peratis": ['P ER0 AA1 T IH0 S', 'P ER0 AE1 T IH0 S'],
  "peratis'": ['P ER0 AA1 T IH0 S', 'P ER0 AE1 T IH0 S'],
  "peraza": ['P ER0 AA1 Z AH0'],
  "perazzo": ['P ER0 AA1 Z OW0'],
  "perc": ['P ER1 K'],
  "perce": ['P ER1 S'],
  "perceive": ['P ER0 S IY1 V'],
  "perceived": ['P ER0 S IY1 V D'],
  "perceives": ['P ER0 S IY1 V Z'],
  "perceiving": ['P ER0 S IY1 V IH0 NG'],
  "percell": ['P ER0 S EH1 L'],
  "percent": ['P ER0 S EH1 N T'],
  "percentage": ['P ER0 S EH1 N T AH0 JH', 'P ER0 S EH1 N AH0 JH', 'P ER0 S EH1 N T IH0 JH', 'P ER0 S EH1 N IH0 JH'],
  "percentages": ['P ER0 S EH1 N T IH0 JH IH0 Z', 'P ER0 S EH1 N IH0 JH IH0 Z'],
  "percentile": ['P ER0 S EH1 N T AY2 L'],
  "percentiles": ['P ER0 S EH1 N T AY2 L Z'],
  "percents": ['P ER0 S EH1 N T S'],
  "percept": ['P ER1 S EH0 P T'],
  "perceptible": ['P ER0 S EH1 P T AH0 B AH0 L'],
  "perception": ['P ER0 S EH1 P SH AH0 N'],
  "perceptions": ['P ER0 S EH1 P SH AH0 N Z'],
  "perceptive": ['P ER0 S EH1 P T IH0 V'],
  "perceptual": ['P ER0 S EH1 P CH AH0 W AH0 L'],
  "perceval": ['P ER0 S EY0 V AE1 L'],
  "perch": ['P ER1 CH'],
  "perchance": ['P ER0 CH AE1 N S'],
  "perched": ['P ER1 CH T'],
  "perches": ['P ER1 CH IH0 Z'],
  "perchlorate": ['P ER0 K L AO1 R EY0 T'],
  "perchloric": ['P ER0 K L AO1 R IH0 K'],
  "percifield": ['P ER1 S IH0 F IY0 L D'],
  "percipient": ['P ER0 S IH1 P IY0 AH0 N T', 'P ER0 S IH1 P Y AH0 N T'],
  "percival": ['P ER1 S IH0 V AH0 L'],
  "percle": ['P ER1 K AH0 L'],
  "percolate": ['P ER1 K AH0 L EY2 T'],
  "percolated": ['P ER1 K AH0 L EY2 T IH0 D'],
  "percolating": ['P ER1 K AH0 L EY2 T IH0 NG'],
  "percolator": ['P ER1 K AH0 L EY2 T ER0'],
  "percolators": ['P ER1 K AH0 L EY2 T ER0 Z'],
  "percs": ['P ER1 K S'],
  "percussion": ['P ER0 K AH1 SH AH0 N'],
  "percussionist": ['P ER0 K AH1 SH AH0 N IH0 S T'],
  "percussive": ['P ER0 K AH1 S AH0 V'],
  "percutaneous": ['P ER0 K Y UW0 T EY1 N IY0 AH0 S'],
  "percy": ['P ER1 S IY0'],
  "percy's": ['P ER1 S IY0 Z'],
  "perdew": ['P ER1 D UW0'],
  "perdita": ['P ER0 D IY1 T AH0'],
  "perdomo": ['P ER0 D OW1 M OW0'],
  "perdue": ['P ER0 D UW1'],
  "pere": ['P ER1'],
  "perea": ['P ER0 IY1 AH0'],
  "pereda": ['P ER0 EY1 D AH0'],
  "peregoy": ['P ER1 IH0 G OY0'],
  "peregrine": ['P EH1 R AH0 G R IY2 N'],
  "peregrines": ['P EH1 R AH0 G R IY2 N Z'],
  "pereira": ['P ER0 EH1 R AH0'],
  "perella": ['P ER0 EH1 L AH0'],
  "perelman": ['P EH1 R AH0 L M AH0 N'],
  "perelman's": ['P EH1 R AH0 L M AH0 N Z'],
  "peremptory": ['P ER0 EH1 M P T ER0 IY0'],
  "perennial": ['P ER0 EH1 N IY0 AH0 L'],
  "perennially": ['P ER0 EH1 N IY0 AH0 L IY0'],
  "perennials": ['P ER0 EH1 N IY0 AH0 L Z'],
  "perera": ['P ER0 EH1 R AH0'],
  "peres": ['P EH1 R EH0 Z'],
  "peres'": ['P EH1 R EH0 Z'],
  "peres's": ['P EH1 R EH0 Z IH0 Z'],
  "perestroika": ['P EH2 R AH0 S T R OY1 K AH0'],
  "peretti": ['P ER0 EH1 T IY0'],
  "peretz": ['P ER1 IH0 T S'],
  "pereyra": ['P ER0 EH1 R AH0'],
  "perez": ['P EH1 R EH0 Z'],
  "perfect": ['P ER0 F EH1 K T', 'P ER1 F IH2 K T'],
  "perfecta": ['P ER0 F EH1 K T AH0'],
  "perfected": ['P ER0 F EH1 K T IH0 D'],
  "perfecting": ['P ER0 F EH1 K T IH0 NG'],
  "perfection": ['P ER0 F EH1 K SH AH0 N'],
  "perfectionism": ['P ER0 F EH1 K SH AH0 N IH2 Z AH0 M'],
  "perfectionist": ['P ER0 F EH1 K SH AH0 N AH0 S T', 'P ER0 F EH1 K SH AH0 N IH0 S T'],
  "perfectionists": ['P ER0 F EH1 K SH AH0 N AH0 S T S'],
  "perfectly": ['P ER1 F AH0 K T L IY0', 'P ER1 F AH0 K L IY0'],
  "perfetti": ['P ER0 F EH1 T IY0'],
  "perfetto": ['P ER0 F EH1 T OW0'],
  "perfidy": ['P ER1 F IH0 D IY0'],
  "perforate": ['P ER1 F ER0 EY2 T'],
  "perforated": ['P ER1 F ER0 EY2 T IH0 D'],
  "perforates": ['P ER1 F ER0 EY2 T S'],
  "perforating": ['P ER1 F ER0 EY2 T IH0 NG'],
  "perforation": ['P ER2 F ER0 EY1 SH AH0 N'],
  "perforations": ['P ER2 F ER0 EY1 SH AH0 N Z'],
  "perforce": ['P ER0 F AO1 R S'],
  "perform": ['P ER0 F AO1 R M'],
  "performa": ['P ER0 F AO1 R M AH0'],
  "performance": ['P ER0 F AO1 R M AH0 N S'],
  "performances": ['P ER0 F AO1 R M AH0 N S AH0 Z', 'P ER0 F AO1 R M AH0 N S IH0 Z'],
  "performative": ['P ER0 F AO1 R M AH0 T IH0 V'],
  "performatives": ['P ER0 F AO1 R M AH0 T IH0 V Z'],
  "performed": ['P ER0 F AO1 R M D'],
  "performer": ['P ER0 F AO1 R M ER0'],
  "performers": ['P ER0 F AO1 R M ER0 Z'],
  "performers'": ['P ER0 F AO1 R M ER0 Z'],
  "performing": ['P ER0 F AO1 R M IH0 NG'],
  "performs": ['P ER0 F AO1 R M Z'],
  "perfume": ['P ER0 F Y UW1 M'],
  "perfumed": ['P ER0 F Y UW1 M D'],
  "perfumes": ['P ER0 F Y UW1 M Z'],
  "perfuming": ['P ER0 F Y UW1 M IH0 NG'],
  "perfunctory": ['P ER0 F AH1 NG K T ER0 IY0'],
  "perfusion": ['P ER0 F Y UW1 Z AH0 N'],
  "pergamon": ['P ER1 G AH0 M AH0 N'],
  "pergande": ['P ER1 G IH0 N D'],
  "pergola": ['P ER1 G AH0 L AH0', 'P ER0 G OW1 L AH0'],
  "pergolas": ['P ER2 G OW1 L AH0 Z'],
  "perham": ['P ER1 HH AH0 M'],
  "perhaps": ['P ER0 HH AE1 P S'],
  "peria": ['P EH1 R IY0 AH0'],
  "peria's": ['P EH1 R IY0 AH0 Z'],
  "perich": ['P ER1 IH0 K'],
  "peridot": ['P EH1 R IH0 D AO0 T', 'P EH1 R IH0 D OW0'],
  "perignon": ['P ER0 IH1 N Y AA0 N', 'P EH2 R IH0 G N AA1 N'],
  "perigo": ['P ER0 IY1 G OW0'],
  "peril": ['P EH1 R AH0 L'],
  "perillo": ['P ER0 IH1 L OW0'],
  "perilous": ['P EH1 R AH0 L AH0 S'],
  "perilously": ['P EH1 R AH0 L AH0 S L IY0'],
  "perils": ['P EH1 R AH0 L Z'],
  "periman": ['P ER1 IY0 M AH0 N'],
  "perimeter": ['P ER0 IH1 M AH0 T ER0'],
  "perimeters": ['P ER0 IH1 M AH0 T ER0 Z'],
  "perin": ['P ER1 IH0 N'],
  "perine": ['P ER0 IY1 N IY0'],
  "perineural": ['P ER2 IY1 N UH2 R AH0 L'],
  "perini": ['P ER0 IY1 N IY0'],
  "perino": ['P ER0 IY1 N OW0'],
  "period": ['P IH1 R IY0 AH0 D'],
  "period's": ['P IH1 R IY0 AH0 D Z'],
  "periodic": ['P IH2 R IY0 AA1 D IH0 K'],
  "periodical": ['P IH2 R IY0 AA1 D IH0 K AH0 L'],
  "periodically": ['P IY2 R IY0 AA1 D IH0 K AH0 L IY0', 'P IY2 R IY0 AA1 D IH0 K L IY0'],
  "periodicals": ['P IH2 R IY0 AA1 D IH0 K AH0 L Z'],
  "periodontal": ['P EH2 R IY0 AH0 D AA1 N T AH0 L', 'P EH2 R IY0 AH0 D AA1 N AH0 L'],
  "periods": ['P IH1 R IY0 AH0 D Z'],
  "peripatetic": ['P EH2 R AH0 P AH0 T EH1 T IH0 K'],
  "peripheral": ['P ER0 IH1 F ER0 AH0 L', 'P ER0 IH1 F R AH0 L'],
  "peripherally": ['P ER0 IH1 F ER0 AH0 L IY0', 'P ER0 IH1 F R AH0 L IY0'],
  "peripherals": ['P ER0 IH1 F ER0 AH0 L Z', 'P ER0 IH1 F R AH0 L Z'],
  "periphery": ['P ER0 IH1 F ER0 IY0'],
  "periscope": ['P EH1 R AH0 S K OW2 P'],
  "periscopes": ['P EH1 R AH0 S K OW2 P S'],
  "perish": ['P EH1 R IH0 SH'],
  "perishable": ['P EH1 R IH0 SH AH0 B AH0 L'],
  "perishables": ['P EH1 R IH0 SH AH0 B AH0 L Z'],
  "perished": ['P EH1 R IH0 SH T'],
  "perishing": ['P EH1 R IH0 SH IH0 NG'],
  "peristyle": ['P EH1 R AH0 S T AY2 L'],
  "perito": ['P EH2 R IY1 T OW0'],
  "peritoneal": ['P EH2 R IY0 T OW0 N IY1 AH0 L'],
  "perjure": ['P ER1 JH ER0'],
  "perjured": ['P ER1 JH ER0 D'],
  "perjurer": ['P ER1 JH ER0 ER0'],
  "perjures": ['P ER1 JH ER0 Z'],
  "perjuring": ['P ER1 JH ER0 IH0 NG'],
  "perjury": ['P ER1 JH ER0 IY0'],
  "perk": ['P ER1 K'],
  "perked": ['P ER1 K T'],
  "perkey": ['P ER1 K IY0'],
  "perkin": ['P ER1 K IH0 N'],
  "perking": ['P ER1 K IH0 NG'],
  "perkins": ['P ER1 K AH0 N Z', 'P ER1 K IH0 N Z'],
  "perkins'": ['P ER1 K IH0 N Z'],
  "perkins's": ['P ER1 K IH0 N Z IH0 Z'],
  "perkinson": ['P ER1 K IH0 N S AH0 N'],
  "perko": ['P ER1 K OW0'],
  "perkovic": ['P ER1 K AH0 V IH0 CH'],
  "perkovich": ['P ER1 K AH0 V IH0 CH'],
  "perkowski": ['P ER0 K AO1 F S K IY0'],
  "perks": ['P ER1 K S'],
  "perky": ['P ER1 K IY0'],
  "perl": ['P ER1 L'],
  "perla": ['P ER1 L AH0'],
  "perlberg": ['P ER1 L B ER0 G'],
  "perle": ['P ER1 L'],
  "perley": ['P ER1 L IY0'],
  "perlin": ['P ER1 L IH0 N'],
  "perlis": ['P ER1 L IH2 S'],
  "perlite": ['P ER1 L AY0 T'],
  "perlman": ['P ER1 L M AH0 N'],
  "perlmutter": ['P ER1 L M AH2 T ER0'],
  "perlow": ['P ER1 L OW0'],
  "perls": ['P ER1 L Z'],
  "perlstein": ['P ER1 L S T AY0 N', 'P ER1 L S T IY0 N'],
  "perma": ['P ER1 M AH0'],
  "perman": ['P ER1 M AH0 N'],
  "permanence": ['P ER1 M AH0 N AH0 N S'],
  "permanent": ['P ER1 M AH0 N AH0 N T'],
  "permanente": ['P ER2 M AH0 N EH1 N T EY0'],
  "permanently": ['P ER1 M AH0 N AH0 N T L IY0'],
  "permeability": ['P ER0 M IY2 AH0 B IH1 L IH0 T IY0'],
  "permeable": ['P ER1 M IY2 AH0 B AH0 L'],
  "permeate": ['P ER1 M IY0 EY2 T'],
  "permeated": ['P ER1 M IY0 EY2 T IH0 D'],
  "permeates": ['P ER1 M IY0 EY2 T S'],
  "permeating": ['P ER1 M IY0 EY2 T IH0 NG'],
  "permenante": ['P ER2 M AH0 N EH1 N T EY0'],
  "permenter": ['P ER1 M AH0 N T ER0'],
  "permiam": ['P ER0 M AY1 AH0 M', 'P ER1 M IY0 AH0 M'],
  "permian": ['P ER1 M IY0 AH0 N'],
  "permissible": ['P ER0 M IH1 S AH0 B AH0 L'],
  "permission": ['P ER0 M IH1 SH AH0 N'],
  "permissions": ['P ER0 M IH1 SH AH0 N Z'],
  "permissive": ['P ER0 M IH1 S IH0 V'],
  "permissiveness": ['P ER0 M IH1 S IH0 V N AH0 S'],
  "permit": ['P ER0 M IH1 T', 'P ER1 M IH2 T'],
  "permits": ['P ER0 M IH1 T S', 'P ER1 M IH2 T S'],
  "permitted": ['P ER0 M IH1 T IH0 D'],
  "permitting": ['P ER0 M IH1 T IH0 NG'],
  "permount": ['P ER1 M AW2 N T'],
  "permut": ['P ER1 M AH0 T'],
  "permutate": ['P ER1 M Y UW0 T EY2 T'],
  "permutated": ['P ER1 M Y UW0 T EY2 T IH0 D'],
  "permutates": ['P ER1 M Y UW0 T EY2 T S'],
  "permutating": ['P ER1 M Y UW0 T EY2 T IH0 NG'],
  "permutation": ['P ER2 M Y UW0 T EY1 SH AH0 N'],
  "permutations": ['P ER2 M Y UW0 T EY1 SH AH0 N Z'],
  "permute": ['P ER2 M Y UW1 T'],
  "perna": ['P ER1 N AH0'],
  "pernell": ['P ER1 N AH0 L'],
  "pernella": ['P ER0 N EH1 L AH0'],
  "pernice": ['P ER1 N IH0 S'],
  "perniciaro": ['P ER0 N IY1 CH ER0 OW0'],
  "pernicious": ['P ER0 N IH1 SH AH0 S'],
  "perno": ['P ER1 N OW0'],
  "pernod": ['P EH0 R N OW1', 'P ER0 N AA1 D'],
  "pernod's": ['P ER0 N AA1 D Z'],
  "pero": ['P ER1 OW0'],
  "peron": ['P ER0 AO1 N'],
  "perona": ['P ER0 OW1 N AH0'],
  "perone": ['P ER0 OW1 N'],
  "peroni": ['P ER0 OW1 N IY0'],
  "peronism": ['P EH1 R AH0 N IH2 Z AH0 M'],
  "peronist": ['P EH1 R AH0 N IH0 S T'],
  "peronists": ['P EH1 R AH0 N IH0 S T S'],
  "perot": ['P ER0 OW1'],
  "perot's": ['P ER0 OW1 Z'],
  "perots": ['P ER0 OW1 Z'],
  "perotti": ['P ER0 OW1 T IY0'],
  "peroutka": ['P ER0 UW1 T K AH0'],
  "perovich": ['P ER1 AH0 V IH0 CH'],
  "peroxide": ['P ER0 AA1 K S AY2 D'],
  "perpendicular": ['P ER2 P AH0 N D IH1 K Y AH0 L ER0'],
  "perpetrate": ['P ER1 P AH0 T R EY2 T'],
  "perpetrated": ['P ER1 P AH0 T R EY2 T IH0 D'],
  "perpetrates": ['P ER1 P AH0 T R EY2 T S'],
  "perpetrating": ['P ER1 P AH0 T R EY2 T IH0 NG'],
  "perpetrator": ['P ER1 P AH0 T R EY2 T ER0'],
  "perpetrator's": ['P ER1 P AH0 T R EY2 T ER0 Z'],
  "perpetrators": ['P ER1 P AH0 T R EY2 T ER0 Z'],
  "perpetua": ['P ER0 P EH1 CH UW0 AH0'],
  "perpetual": ['P ER0 P EH1 CH UW0 AH0 L'],
  "perpetually": ['P ER0 P EH1 CH UW0 AH0 L IY0'],
  "perpetuals": ['P ER0 P EH1 CH UW0 AH0 L Z'],
  "perpetuate": ['P ER0 P EH1 CH AH0 W EY2 T'],
  "perpetuated": ['P ER0 P EH1 CH AH0 W EY2 T IH0 D'],
  "perpetuates": ['P ER0 P EH1 CH AH0 W EY2 T S'],
  "perpetuating": ['P ER0 P EH1 CH AH0 W EY2 T IH0 NG'],
  "perpetuation": ['P ER0 P EH2 CH AH0 W EY1 SH AH0 N'],
  "perpetuity": ['P ER2 P IH0 T Y UW1 IH0 T IY0'],
  "perpich": ['P ER1 P IH0 K'],
  "perplex": ['P ER0 P L EH1 K S'],
  "perplexed": ['P ER0 P L EH1 K S T'],
  "perplexes": ['P ER0 P L EH1 K S IH0 Z'],
  "perplexing": ['P ER0 P L EH1 K S IH0 NG'],
  "perplexity": ['P ER0 P L EH1 K S AH0 T IY0'],
  "perquisite": ['P ER1 K W AH0 Z IH0 T'],
  "perquisites": ['P ER1 K W AH0 Z IH0 T S', 'P ER1 K W IH0 Z IH0 T S'],
  "perra": ['P ER1 R AH0'],
  "perras": ['P ER1 R AH0 Z'],
  "perrault": ['P EH1 R AO0 L T', 'P ER0 AO1 L T'],
  "perreault": ['P ER0 R OW1'],
  "perreira": ['P ER0 R EH1 R AH0'],
  "perrell": ['P ER0 R EY1 L'],
  "perrella": ['P ER0 R EH1 L AH0'],
  "perrelli": ['P ER0 R EH1 L IY0'],
  "perren": ['P ER1 R AH0 N'],
  "perret": ['P ER1 R IH0 T'],
  "perrett": ['P ER1 R IH0 T'],
  "perretta": ['P ER0 R EH1 T AH0'],
  "perretti": ['P ER0 R EH1 T IY0'],
  "perri": ['P ER1 R IY0'],
  "perricone": ['P ER0 R IY0 K OW1 N IY0'],
  "perriello": ['P ER0 R IY0 EH1 L OW0'],
  "perrier": ['P EH1 R IY0 ER0', 'P EH2 R IY0 EY1'],
  "perrigo": ['P ER0 R IY1 G OW0'],
  "perrin": ['P EH1 R IH0 N'],
  "perrine": ['P ER0 R IY1 N IY0'],
  "perrino": ['P ER0 R IY1 N OW0'],
  "perrins": ['P EH1 R IH0 N Z'],
  "perritt": ['P ER1 R IH0 T'],
  "perro": ['P ER1 R OW0'],
  "perrodin": ['P ER1 R AH0 D IH0 N'],
  "perron": ['P EH1 R AH0 N'],
  "perrone": ['P ER0 R OW1 N IY0'],
  "perrot": ['P ER1 R AH0 T'],
  "perrott": ['P ER0 R AA1 T'],
  "perrotta": ['P ER0 R OW1 T AH0'],
  "perrotti": ['P ER0 R OW1 T IY0'],
  "perrotto": ['P ER0 R OW1 T OW0'],
  "perrow": ['P ER1 R OW0'],
  "perry": ['P EH1 R IY0'],
  "perry's": ['P EH1 R IY0 Z'],
  "perryman": ['P ER1 R IY0 M AH0 N'],
  "perryn": ['P ER1 R IH0 N'],
  "pers": ['P ER1 S'],
  "persall": ['P ER1 S AH0 L'],
  "persaud": ['P ER1 S AO0 D'],
  "perschbacher": ['P ER1 SH B AA0 K ER0'],
  "perse": ['P ER1 S'],
  "persecute": ['P ER1 S AH0 K Y UW2 T'],
  "persecuted": ['P ER1 S AH0 K Y UW2 T IH0 D'],
  "persecutes": ['P ER1 S AH0 K Y UW2 T S'],
  "persecuting": ['P ER1 S IH0 K Y UW2 T IH0 NG'],
  "persecution": ['P ER2 S AH0 K Y UW1 SH AH0 N'],
  "persecutions": ['P ER2 S AH0 K Y UW1 SH AH0 N S'],
  "persecutor": ['P ER1 S AH0 K Y UW2 T ER0'],
  "persecutors": ['P ER1 S AH0 K Y UW2 T ER0 Z'],
  "persepolis": ['P ER0 S EH1 P AH0 L AH0 S'],
  "perses": ['P ER1 S IH0 Z'],
  "perset": ['P ER1 S EH0 T'],
  "perseus": ['P ER1 S IY0 AH0 S'],
  "perseverance": ['P ER2 S AH0 V IH1 R AH0 N S'],
  "persevere": ['P ER0 S AH0 V IH1 R'],
  "persevered": ['P ER2 S AH0 V IH1 R D'],
  "perseveres": ['P ER0 S AH0 V IH1 R Z'],
  "persevering": ['P ER0 S AH0 V IH1 R IH0 NG'],
  "pershall": ['P ER1 SH AH0 L'],
  "pershare": ['P ER0 SH EH1 R'],
  "pershing": ['P ER1 SH IH0 NG'],
  "pershing's": ['P ER1 SH IH0 NG Z'],
  "pershings": ['P ER1 SH IH0 NG Z'],
  "persia": ['P ER1 ZH AH0'],
  "persian": ['P ER1 ZH AH0 N'],
  "persians": ['P ER1 ZH AH0 N Z'],
  "persico": ['P ER0 S IY1 K OW0'],
  "persing": ['P ER1 S IH0 NG'],
  "persinger": ['P ER1 S IH0 N JH ER0'],
  "persis": ['P ER1 S IH0 S'],
  "persist": ['P ER0 S IH1 S T'],
  "persisted": ['P ER0 S IH1 S T IH0 D'],
  "persistence": ['P ER0 S IH1 S T AH0 N S'],
  "persistent": ['P ER0 S IH1 S T AH0 N T'],
  "persistently": ['P ER0 S IH1 S T AH0 N T L IY0'],
  "persisting": ['P ER0 S IH1 S T IH0 NG'],
  "persists": ['P ER0 S IH1 S T S'],
  "persky": ['P ER1 S K IY0'],
  "persley": ['P ER1 S L IY0'],
  "person": ['P ER1 S AH0 N'],
  "person's": ['P ER1 S AH0 N Z'],
  "persona": ['P ER0 S OW1 N AH0'],
  "personable": ['P ER1 S AH0 N AH0 B AH0 L'],
  "personably": ['P ER1 S AH0 N AH0 B L IY0'],
  "personae": ['P ER0 S OW1 N EY0'],
  "personal": ['P ER1 S IH0 N AH0 L'],
  "personalink": ['P ER1 S AH0 N AH0 L IH2 NG K'],
  "personalities": ['P ER2 S AH0 N AE1 L IH0 T IY0 Z'],
  "personality": ['P ER2 S AH0 N AE1 L IH0 T IY0'],
  "personalization": ['P ER2 S AH0 N AH0 L AH0 Z EY1 SH AH0 N'],
  "personalize": ['P ER1 S AH0 N AH0 L AY2 Z'],
  "personalized": ['P ER1 S AH0 N AH0 L AY2 Z D'],
  "personalizes": ['P ER1 S AH0 N AH0 L AY2 Z IH0 Z'],
  "personalizing": ['P ER1 S AH0 N AH0 L AY2 Z IH0 NG'],
  "personally": ['P ER1 S AH0 N AH0 L IY0', 'P ER1 S AH0 N L IY0', 'P ER1 S N AH0 L IY0'],
  "personals": ['P ER1 S IH0 N IH0 L Z'],
  "personic": ['P ER0 S AA1 N IH0 K'],
  "personics": ['P ER0 S AA1 N IH0 K S'],
  "personification": ['P ER0 S AA2 N AH0 F AH0 K EY1 SH AH0 N'],
  "personified": ['P ER0 S AA1 N AH0 F AY2 D'],
  "personifies": ['P ER0 S AA1 N AH0 F AY2 Z'],
  "personify": ['P ER0 S AA1 N AH0 F AY2'],
  "personifying": ['P ER0 S AA1 N AH0 F AY2 IH0 NG'],
  "personnel": ['P ER2 S AH0 N EH1 L'],
  "persons": ['P ER1 S AH0 N Z'],
  "perspective": ['P ER0 S P EH1 K T IH0 V'],
  "perspectives": ['P ER0 S P EH1 K T IH0 V Z'],
  "perspicacious": ['P ER0 S P AH0 K EY1 SH AH0 S'],
  "perspiration": ['P ER2 S P ER0 EY1 SH AH0 N'],
  "perspire": ['P ER0 S P AY1 R'],
  "perspiring": ['P ER0 S P AY1 R IH0 NG'],
  "persson": ['P ER1 S AH0 N'],
  "persuadable": ['P ER0 S W EY1 D AH0 B AH0 L'],
  "persuade": ['P ER0 S W EY1 D'],
  "persuaded": ['P ER0 S W EY1 D IH0 D'],
  "persuades": ['P ER0 S W EY1 D Z'],
  "persuading": ['P ER0 S W EY1 D IH0 NG'],
  "persuasion": ['P ER0 S W EY1 ZH AH0 N'],
  "persuasions": ['P ER0 S W EY1 ZH AH0 N Z'],
  "persuasive": ['P ER0 S W EY1 S IH0 V'],
  "persuasively": ['P ER0 S W EY1 S IH0 V L IY0'],
  "persuasiveness": ['P ER0 S W EY1 S IH0 V N AH0 S'],
  "persyn": ['P ER1 S IH0 N'],
  "pert": ['P ER1 T'],
  "pertain": ['P ER0 T EY1 N'],
  "pertained": ['P ER0 T EY1 N D'],
  "pertaining": ['P ER0 T EY1 N IH0 NG'],
  "pertains": ['P ER0 T EY1 N Z'],
  "pertamina": ['P ER0 T AH0 M IY1 N AH0'],
  "perth": ['P ER1 TH'],
  "pertinent": ['P ER1 T IH0 N IH0 N T'],
  "pertinently": ['P ER1 T IH0 N IH0 N T L IY0'],
  "pertschuk": ['P ER1 CH AH0 K'],
  "pertuit": ['P ER1 T IH0 T', 'P ER1 T UW0 AH0 T'],
  "perturb": ['P ER0 T ER1 B'],
  "perturbation": ['P ER2 T ER0 B EY1 SH AH0 N'],
  "perturbations": ['P ER2 T ER0 B EY1 SH AH0 N Z'],
  "perturbed": ['P ER0 T ER1 B D'],
  "perturbing": ['P ER0 T ER1 B IH0 NG'],
  "perturbs": ['P ER0 T ER1 B Z'],
  "pertussis": ['P ER0 T AH1 S IH0 S'],
  "peru": ['P ER0 UW1'],
  "peru's": ['P ER0 UW1 Z'],
  "perugia": ['P ER0 UW1 G IY0 AH0'],
  "perugina": ['P EH2 R UW0 JH IY1 N AH0'],
  "perugini": ['P ER0 UW0 JH IY1 N IY0'],
  "perulac": ['P EH1 R UW0 L AE0 K'],
  "perusal": ['P ER0 UW1 Z AH0 L'],
  "peruse": ['P ER0 UW1 Z'],
  "perused": ['P ER0 UW1 Z D'],
  "peruses": ['P ER0 UW1 Z IH0 Z'],
  "perusing": ['P ER0 UW1 Z IH0 NG'],
  "perusse": ['P ER0 UW1 S IY0'],
  "peruvian": ['P ER0 UW1 V IY0 AH0 N'],
  "peruvians": ['P ER0 UW1 V IY0 AH0 N Z'],
  "peruzzi": ['P ER0 UW1 T S IY0'],
  "pervade": ['P ER0 V EY1 D'],
  "pervaded": ['P ER0 V EY1 D IH0 D'],
  "pervades": ['P ER0 V EY1 D Z'],
  "pervading": ['P ER0 V EY1 D IH0 NG'],
  "pervasive": ['P ER0 V EY1 S IH0 V'],
  "pervasiveness": ['P ER0 V EY1 S IH0 V N IH0 S'],
  "perverse": ['P ER0 V ER1 S'],
  "perversely": ['P ER0 V ER1 S L IY0'],
  "perversion": ['P ER0 V ER1 ZH AH0 N'],
  "perversity": ['P ER0 V ER1 S AH0 T IY0'],
  "pervert": ['P ER1 V ER0 T', 'P ER0 V ER1 T'],
  "perverted": ['P ER0 V ER1 T IH0 D'],
  "perverting": ['P ER0 V ER1 T IH0 NG'],
  "perverts": ['P ER1 V ER0 T S', 'P ER0 V ER1 T S'],
  "pervomaiskaya": ['P ER0 V OW0 M EY0 S K AY1 AH0'],
  "perz": ['P ER1 Z'],
  "perzigian": ['P ER0 Z IH1 G IY0 AH0 N'],
  "pesatori": ['P EH2 S AH0 T AO1 R IY0'],
  "pesavento": ['P EH2 S AH0 V EH1 N T OW0'],
  "pescatore": ['P EH0 S K AA0 T AO1 R IY0'],
  "pesce": ['P EH1 S'],
  "pesch": ['P EH1 SH'],
  "peschel": ['P EH1 SH AH0 L'],
  "peschke": ['P EH1 SH K'],
  "pesci": ['P EH1 S K IY0'],
  "pesek": ['P EH1 S EH0 K'],
  "peseta": ['P AH0 S EY1 T AH0'],
  "pesetas": ['P AH0 S EY1 T AH0 Z'],
  "pesh": ['P EH1 SH'],
  "peshawar": ['P EH1 SH AH0 W AO0 R'],
  "peshek": ['P EH1 SH EH0 K'],
  "pesina": ['P EH0 S IY1 N AH0'],
  "peskin": ['P EH1 S K IH0 N'],
  "pesky": ['P EH1 S K IY0'],
  "pesnell": ['P IH0 S N EH1 L'],
  "peso": ['P EY1 S OW0'],
  "peso's": ['P EY1 S OW0 Z'],
  "pesola": ['P EH0 S OW1 L AH0'],
  "pesos": ['P EY1 S OW0 Z'],
  "pessimism": ['P EH1 S AH0 M IH2 Z AH0 M'],
  "pessimist": ['P EH1 S AH0 M AH0 S T'],
  "pessimistic": ['P EH2 S AH0 M IH1 S T IH0 K'],
  "pessimists": ['P EH1 S AH0 M IH0 S T S'],
  "pessin": ['P EH1 S IH0 N'],
  "pest": ['P EH1 S T'],
  "pesta": ['P EH1 S T AH0'],
  "pestana": ['P EH0 S T AE1 N AH0'],
  "pester": ['P EH1 S T ER0'],
  "pestered": ['P EH1 S T ER0 D'],
  "pestering": ['P EH1 S T ER0 IH0 NG'],
  "pesters": ['P EH1 S T ER0 Z'],
  "pesticide": ['P EH1 S T AH0 S AY2 D'],
  "pesticides": ['P EH1 S T AH0 S AY2 D Z'],
  "pestilence": ['P EH1 S T AH0 L AH0 N S'],
  "pestillo": ['P EH2 S T IH1 L OW0', 'P EH2 S T IY1 OW0'],
  "pestka": ['P EH1 S T K AH0'],
  "pests": ['P EH1 S T S'],
  "pet": ['P EH1 T'],
  "pet's": ['P EH1 T S'],
  "peta": ['P IY1 T AH0'],
  "peta's": ['P IY1 T AH0 Z'],
  "petain": ['P AH0 T EY1 N', 'P EY0 T AE1 N'],
  "petak": ['P EH1 T AH0 K'],
  "petal": ['P EH1 T AH0 L'],
  "petals": ['P EH1 T AH0 L Z'],
  "petaluma": ['P EH2 T AH0 L UW1 M AH0'],
  "petard": ['P EH1 T ER0 D', 'P AH0 T AA1 D'],
  "pete": ['P IY1 T'],
  "pete's": ['P IY1 T S'],
  "peter": ['P IY1 T ER0'],
  "peter's": ['P IY1 T ER0 Z'],
  "peterbilt": ['P IY1 T ER0 B IH2 L T'],
  "peterborough": ['P IY1 T ER0 B ER0 OW0'],
  "petered": ['P IY1 T ER0 D'],
  "petering": ['P IY1 T ER0 IH0 NG'],
  "peterka": ['P IH0 T ER1 K AH0'],
  "peterkin": ['P IH0 T ER1 K IH0 N'],
  "peterman": ['P IY1 T ER0 M AH0 N'],
  "petermann": ['P IY1 T ER0 M AH0 N'],
  "peterpaul": ['P IY1 T ER0 P AO1 L'],
  "peters": ['P IY1 T ER0 Z'],
  "petersburg": ['P IY1 T ER0 Z B ER0 G'],
  "petersen": ['P IY1 T ER0 S AH0 N'],
  "petersen's": ['P IY1 T ER0 S AH0 N Z'],
  "peterson": ['P IY1 T ER0 S AH0 N'],
  "peterson's": ['P IY1 T ER0 S AH0 N Z'],
  "petery": ['P IY1 T ER0 IY0'],
  "petey": ['P IY1 T IY0'],
  "peth": ['P EH1 TH'],
  "pethel": ['P EH1 TH AH0 L'],
  "pethtel": ['P EH1 TH AH0 L'],
  "petie": ['P EH1 T IY0'],
  "petillo": ['P EH0 T IH1 L OW0'],
  "petino": ['P EH0 T IY1 N OW0'],
  "petipa": ['P EH2 T IY1 P AH0'],
  "petipa's": ['P EH0 T IY1 P AH0 Z'],
  "petit": ['P EH1 T IY0'],
  "petite": ['P AH0 T IY1 T'],
  "petites": ['P EH2 T IY1 T S'],
  "petition": ['P AH0 T IH1 SH AH0 N'],
  "petitioned": ['P AH0 T IH1 SH AH0 N D'],
  "petitioner": ['P AH0 T IH1 SH AH0 N ER0'],
  "petitioners": ['P AH0 T IH1 SH AH0 N ER0 Z'],
  "petitioning": ['P AH0 T IH1 SH AH0 N IH0 NG'],
  "petitions": ['P AH0 T IH1 SH AH0 N Z'],
  "petitjean": ['P IH0 T IH1 T JH AH0 N'],
  "petito": ['P EH0 T IY1 T OW0'],
  "petitt": ['P EH1 T IH0 T'],
  "petitti": ['P EH0 T IY1 T IY0'],
  "petitto": ['P EH0 T IY1 T OW0'],
  "petko": ['P EH1 T K OW0'],
  "petkovsek": ['P EH2 T K AA1 V S EH0 K'],
  "petkus": ['P EH1 T K IH0 S'],
  "peto": ['P IY1 T OW0'],
  "petr": ['P EH1 T ER0'],
  "petra": ['P EH1 T R AH0'],
  "petracca": ['P EH0 T R AA1 K AH0'],
  "petraglia": ['P EH0 T R AA1 G L IY0 AH0'],
  "petraitis": ['P EH0 T R AY1 T IH0 S'],
  "petrak": ['P EH1 T R AH0 K'],
  "petrakis": ['P EH1 T R AH0 K IH0 S'],
  "petralia": ['P EH0 T R AA1 L IY0 AH0'],
  "petrarca": ['P EH2 T R AA1 R K AH0'],
  "petras": ['P EH1 T R AH0 Z'],
  "petrasek": ['P IH0 T R AA1 S EH0 K'],
  "petrash": ['P EH1 T R AH0 SH'],
  "petrauskas": ['P EH1 T R AW0 S K AH0 Z'],
  "petre": ['P EH1 T ER0'],
  "petrea": ['P EH1 T R IY0 AH0'],
  "petree": ['P EH1 T R IY1'],
  "petrella": ['P EH2 T R EH1 L AH0'],
  "petrelli": ['P EH0 T R EH1 L IY0'],
  "petrels": ['P EH1 T R AH0 L Z'],
  "petrey": ['P EH1 T R IY0'],
  "petri": ['P EH1 T R IY0', 'P IY1 T R IY0'],
  "petrich": ['P EH1 T R IH0 K'],
  "petricioli": ['P EH2 T R IH0 S IY0 OW1 L IY0'],
  "petrick": ['P EH1 T R IH0 K'],
  "petrides": ['P EH1 T R AY0 D Z'],
  "petrie": ['P EH1 T R IY0'],
  "petrie's": ['P EH1 T R IY0 Z'],
  "petrified": ['P EH1 T R AH0 F AY2 D'],
  "petrify": ['P EH1 T R AH0 F AY2'],
  "petrik": ['P EH1 T R IH0 K'],
  "petrilla": ['P EH2 T R IH1 L AH0'],
  "petrilli": ['P EH0 T R IY1 L IY0'],
  "petrillo": ['P EH2 T R IH1 L OW0'],
  "petrin": ['P EH1 T R IH0 N'],
  "petrina": ['P EH0 T R IY1 N AH0'],
  "petrini": ['P EH0 T R IY1 N IY0'],
  "petrino": ['P EH0 T R IY1 N OW0'],
  "petrizzo": ['P EH0 T R IY1 Z OW0'],
  "petro": ['P EH1 T R OW0'],
  "petrobras": ['P EH2 T R AA1 B R AH0 S'],
  "petrocelli": ['P EH0 T R OW0 CH EH1 L IY0'],
  "petrochemical": ['P EH2 T R OW0 K EH1 M IH0 K AH0 L'],
  "petrochemicals": ['P EH2 T R OW0 K EH1 M IH0 K AH0 L Z'],
  "petrocorp": ['P EH1 T R OW0 K AO2 R P'],
  "petrodollar": ['P EH1 T R OW0 D AA2 L ER0'],
  "petrodollars": ['P EH1 T R OW0 D AA2 L ER0 Z'],
  "petroff": ['P EH1 T R AO0 F'],
  "petrofina": ['P EH2 T R AH0 F IY1 N AH0', 'P EH2 T R OW0 F IY1 N AH0'],
  "petroglyph": ['P EH1 T R OW0 G L IH2 F'],
  "petroglyphs": ['P EH1 T R OW0 G L IH2 F S'],
  "petrol": ['P EH1 T R OW0 L'],
  "petrolane": ['P EH1 T R OW0 L EY2 N'],
  "petrolear": ['P EH1 T R OW0 L IH2 R'],
  "petroleos": ['P AH0 T R OW1 L IY0 OW0 S'],
  "petroles": ['P EH1 T R OW2 L Z'],
  "petroleum": ['P AH0 T R OW1 L IY0 AH0 M'],
  "petroleum's": ['P AH0 T R OW1 L IY0 AH0 M Z'],
  "petrolia": ['P AH0 T R OW1 L IY0 AH0'],
  "petrology": ['P AH0 T R AA1 L AH0 JH IY0'],
  "petromin": ['P EH1 T R AH0 M IH0 N'],
  "petromineral": ['P EH2 T R OW0 M IH1 N ER0 AH0 L'],
  "petrominerals": ['P EH2 T R OW0 M IH1 N ER0 AH0 L Z'],
  "petronas": ['P EH2 T R OW1 N AH0 S'],
  "petrone": ['P EH0 T R OW1 N IY0'],
  "petronella": ['P EH2 T R OW0 N EH1 L AH0'],
  "petroni": ['P EH0 T R OW1 N IY0'],
  "petronia": ['P EH0 T R OW1 N IY0 AH0'],
  "petronilla": ['P EH2 T R AH0 N IH1 L AH0'],
  "petronio": ['P EH2 T R OW1 N IY0 OW0'],
  "petropoulos": ['P IH0 T R AA1 P AH0 L IH0 S'],
  "petros": ['P EH1 T R OW0 Z'],
  "petrosa": ['P EH0 T R OW1 Z AH0'],
  "petrosino": ['P EH0 T R OW0 S IY1 N OW0'],
  "petroski": ['P IH0 T R AW1 S K IY0', 'P IH0 T R AA1 S K IY0'],
  "petrosky": ['P IH0 T R OW1 S K IY0'],
  "petrossian": ['P IH0 T R OW1 S Y AH0 N'],
  "petrosynthese": ['P IH0 T R OW1 S IH0 N TH IY2 S'],
  "petrovic": ['P IH0 T R AA1 V IH0 K'],
  "petrovich": ['P EH1 T R AH0 V IH0 CH'],
  "petrovietnam": ['P EH2 T R OW0 V IY2 EH0 T N AA1 M'],
  "petrow": ['P EH1 T R OW2'],
  "petrowski": ['P IH0 T R AO1 F S K IY0'],
  "petrowsky": ['P IH0 T R AW1 S K IY0'],
  "petru": ['P EH1 T R UW0'],
  "petruccelli": ['P EH2 T R UW0 CH EH1 L IY0'],
  "petrucci": ['P EH0 T R UW1 CH IY0'],
  "petrucelli": ['P EH0 T R UW0 CH EH1 L IY0'],
  "petrus": ['P EH1 T R AH0 S'],
  "petruska": ['P IH0 T R AH1 S K AH0'],
  "petruzzelli": ['P EH0 T R UW0 T S EH1 L IY0'],
  "petruzzi": ['P EH0 T R UW1 T S IY0'],
  "petry": ['P EH1 T R IY0'],
  "pets": ['P EH1 T S'],
  "petsch": ['P EH1 CH'],
  "petsche": ['P EH1 CH'],
  "petsmart": ['P EH1 T S M AA2 R T'],
  "petstuff": ['P EH1 T S T AH2 F'],
  "pett": ['P EH1 T'],
  "petta": ['P EH1 T AA0'],
  "pettaway": ['P EH1 T AH0 W EY2'],
  "petted": ['P EH1 T IH0 D'],
  "pettee": ['P EH1 T IY1'],
  "pettengill": ['P EH1 T IH0 NG G AH0 L'],
  "petter": ['P EH1 T ER0'],
  "pettersen": ['P EH1 T ER0 S AH0 N'],
  "petterson": ['P EH1 T ER0 S AH0 N'],
  "pettersson": ['P IY1 T ER0 S AH0 N', 'P EH1 T ER0 S AH0 N'],
  "pettet": ['P EH1 T IH0 T'],
  "petteway": ['P EH1 T W EY0'],
  "pettey": ['P EH1 T IY0'],
  "petteys": ['P EH1 T IY0 Z'],
  "petti": ['P EH1 T IY0'],
  "petticoat": ['P EH1 T IY0 K OW2 T'],
  "petticoats": ['P EH1 T IY0 K OW2 T S'],
  "pettie": ['P EH1 T IY0'],
  "petties": ['P EH1 T IY0 Z'],
  "pettigrew": ['P EH1 T IH0 G R UW2'],
  "pettijohn": ['P EH1 T IH0 JH AA0 N'],
  "pettinato": ['P EH0 T IY0 N AA1 T OW0'],
  "pettine": ['P EH0 T IY1 N IY0'],
  "pettiness": ['P EH1 T IY0 N AH0 S'],
  "petting": ['P EH1 T IH0 NG'],
  "pettinger": ['P EH1 T IH0 NG ER0'],
  "pettingill": ['P IH0 T IH1 NG G AH0 L'],
  "pettis": ['P EH1 T IH2 S'],
  "pettit": ['P EH1 T IH2 T'],
  "pettitt": ['P EH1 T IH2 T'],
  "pettitte": ['P EH1 T IH2 T'],
  "pettry": ['P EH1 T R IY2'],
  "pettus": ['P EH1 T AH0 S'],
  "pettway": ['P EH1 T W EY2'],
  "petty": ['P EH1 T IY2'],
  "petulance": ['P EH1 CH AH0 L AH0 N S'],
  "petulant": ['P EH1 CH AH0 L AH0 N T'],
  "petunia": ['P AH0 T UW1 N IY0 AH0', 'P AH0 T UW1 N Y AH0'],
  "petunias": ['P AH0 T UW1 N IY0 AH0 Z', 'P AH0 T UW1 N Y AH0 Z'],
  "petway": ['P EH1 T W EY2'],
  "petz": ['P EH1 T S'],
  "petzel": ['P EH1 T Z AH0 L'],
  "petzinger": ['P EH1 T S IH2 NG ER0'],
  "petzold": ['P EH1 T Z OW0 L D'],
  "petzoldt": ['P EH1 T Z OW0 L T'],
  "peugeot": ['P Y UW0 ZH OW1'],
  "peugh": ['P Y UW1'],
  "pevehouse": ['P IY1 V HH AW2 S'],
  "peveler": ['P EH1 V AH0 L ER0'],
  "peverell": ['P EH1 V ER0 EH2 L'],
  "peveto": ['P EH0 V EH1 T OW2'],
  "pevey": ['P IH0 V EY1'],
  "pew": ['P Y UW1'],
  "pewabic": ['P Y UW2 AA1 B IH0 K'],
  "pewex": ['P EH1 W EH2 K S'],
  "pewitt": ['P Y UW1 IH0 T'],
  "pews": ['P Y UW1 Z'],
  "pewter": ['P Y UW1 T ER0'],
  "peyman": ['P EY1 M AH0 N'],
  "peyot": ['P EY1 AO0 T'],
  "peyote": ['P IH0 Y AO1 T IY2'],
  "peyrelevade": ['P EH2 R EH1 L AH0 V EY2 D'],
  "peyser": ['P EY1 Z ER0'],
  "peyton": ['P EY1 T AH0 N'],
  "pez": ['P EH1 Z'],
  "pezeshkan": ['P AH0 Z EH1 SH K AH0 N'],
  "pezim": ['P EH2 Z IY1 M'],
  "pezim's": ['P EH2 Z IY1 M Z'],
  "pezza": ['P EH1 Z AH0'],
  "pezzella": ['P EH0 T S EH1 L AH0'],
  "pezzullo": ['P EH0 T S UW1 L OW0'],
  "pfaff": ['F AE1 F'],
  "pfahl": ['F AA1 L'],
  "pfahler": ['F AA1 L ER0'],
  "pfalzgraf": ['F AE1 L Z G R AE0 F'],
  "pfannenstiel": ['F AE1 N AH0 N S T IY0 L'],
  "pfarr": ['F AA1 R'],
  "pfau": ['F AW1'],
  "pfeffer": ['F EH1 F ER0'],
  "pfefferkorn": ['F EH1 F ER0 K AO2 R N'],
  "pfefferle": ['F EH1 F ER0 AH0 L'],
  "pfeifer": ['F AY1 F ER0'],
  "pfeiff": ['F AY1 F'],
  "pfeiffer": ['F AY1 F ER0'],
  "pfeifle": ['F AY1 F AH0 L'],
  "pfeil": ['F AY1 L'],
  "pfennig": ['F EH1 N IH0 G'],
  "pfennigs": ['F EH1 N IH0 G Z'],
  "pfenning": ['F EH1 N IH0 NG'],
  "pfenninger": ['F EH1 N IH0 NG ER0'],
  "pfeuffer": ['F Y UW1 F ER0'],
  "pfiester": ['F AY1 S T ER0'],
  "pfiesteria": ['F AY2 S T EH1 R IY0 AH0'],
  "pfiffner": ['F IH1 F N ER0'],
  "pfingsten": ['F IH1 NG S T AH0 N'],
  "pfister": ['F IH1 S T ER0'],
  "pfisterer": ['F IH1 S T ER0 ER0'],
  "pfizer": ['F AY1 Z ER0'],
  "pfizer's": ['F AY1 Z ER0 Z'],
  "pflaum": ['F L AW1 M'],
  "pfleger": ['F L IY1 G ER0'],
  "pfleiderer": ['F L AY1 D ER0 ER0'],
  "pflieger": ['F L IY1 G ER0'],
  "pflueger": ['F L UW1 G ER0'],
  "pflug": ['F L UW1 G'],
  "pfluger": ['F L UW1 G ER0'],
  "pflum": ['F L AH1 M'],
  "pfohl": ['F OW1 L'],
  "pforzheimer": ['F AO1 R Z HH AY2 M ER0'],
  "pfost": ['F AA1 S T'],
  "pfund": ['F AH1 N D'],
  "pfundstein": ['F AH1 N D S T IY2 N', 'F AH1 N D S T AY2 N'],
  "pga": ['P IY1 JH IY1 EY1'],
  "pga's": ['P IY1 JH IY1 EY1 Z'],
  "pgm": ['P IY1 JH IY1 EH1 M'],
  "ph": ['P IY1 EY1 CH'],
  "ph.d.": ['P IY1 EY2 CH D IY1'],
  "phagan": ['F EY1 G AH0 N'],
  "phage": ['F EY1 JH'],
  "phagocyte": ['F AE1 G AH0 S AY2 T'],
  "phagocytes": ['F AE1 G AH0 S AY2 T S'],
  "phair": ['F EH1 R'],
  "phalange": ['F AH0 L AE1 N JH', 'F AH0 L EY1 N JH'],
  "phalanges": ['F AH0 L AE1 N JH IY0 Z', 'F AH0 L EY1 N JH IH0 Z'],
  "phalanx": ['F EY1 L AE0 NG K S'],
  "phalen": ['F AE1 L AH0 N'],
  "phallic": ['F AE1 L IH0 K'],
  "pham": ['F AE1 M'],
  "phan": ['F AE1 N'],
  "phaneuf": ['F AE1 N UW0 F'],
  "phantasm": ['F AE2 N T AE1 Z AH0 M'],
  "phantasmagoria": ['F AE0 N T AE2 Z M AH0 G AO1 R IY0 AH0'],
  "phantom": ['F AE1 N T AH0 M', 'F AE1 N AH0 M'],
  "phantoms": ['F AE1 N T AH0 M Z', 'F AE1 N AH0 M Z'],
  "phar": ['F AA1 R'],
  "pharaoh": ['F EH1 R OW0', 'F EY1 R OW0'],
  "pharaohs": ['F EH1 R OW0 Z', 'F EY1 R OW0 Z'],
  "pharaon": ['F EH1 R OW0 N'],
  "pharaonic": ['F EH2 R AH0 AA1 N IH0 K'],
  "phares": ['F EH1 R Z'],
  "pharis": ['F AE1 R AH0 S'],
  "pharisaism": ['F AE1 R IH0 S EY2 IH2 Z AH0 M'],
  "pharisee": ['F AA1 R IH0 S IY2'],
  "pharisees": ['F AA1 R IH0 S IY2 Z'],
  "phariss": ['F ER0 IH1 S'],
  "pharma": ['F AA1 R M AH0'],
  "pharmaceutical": ['F AA2 R M AH0 S UW1 T IH0 K AH0 L'],
  "pharmaceuticals": ['F AA2 R M AH0 S UW1 T IH0 K AH0 L Z'],
  "pharmacia": ['F AA0 R M EY1 S IY0 AH0'],
  "pharmacies": ['F AA1 R M AH0 S IY0 Z'],
  "pharmacist": ['F AA1 R M AH0 S IH0 S T'],
  "pharmacists": ['F AA1 R M AH0 S IH0 S T S'],
  "pharmacological": ['F AA2 R M AH0 K AH0 L AA1 JH IH0 K AH0 L'],
  "pharmacologist": ['F AA2 R M AH0 K AA1 L AH0 JH IH0 S T'],
  "pharmacologists": ['F AA2 R M AH0 K AA1 L AH0 JH IH0 S T S'],
  "pharmacology": ['F AA2 R M AH0 K AA1 L AH0 JH IY0'],
  "pharmacy": ['F AA1 R M AH0 S IY0'],
  "pharmakinetic": ['F AA2 R M AH0 K AH0 N EH1 T IH0 K'],
  "pharmakinetics": ['F AA2 R M AH0 K AH0 N EH1 T IH0 K S'],
  "pharo": ['F AA1 R OW0'],
  "pharoah": ['F EY1 R OW0', 'F EH1 R OW0'],
  "pharoahs": ['F EY1 R OW0 Z', 'F EH1 R OW0 Z'],
  "pharos": ['F EH1 R OW2 Z'],
  "pharr": ['F AA1 R'],
  "pharris": ['F AE1 R IH0 S'],
  "pharynx": ['F EH1 R IH0 NG K S'],
  "phase": ['F EY1 Z'],
  "phased": ['F EY1 Z D'],
  "phaseout": ['F EY1 Z AW2 T'],
  "phases": ['F EY1 Z AH0 Z', 'F EY1 Z IH0 Z'],
  "phasing": ['F EY1 Z IH0 NG'],
  "phd": ['P IY1 EY2 CH D IY1'],
  "pheasant": ['F EH1 Z AH0 N T'],
  "pheasants": ['F EH1 Z AH0 N T S'],
  "phebe": ['F IY1 B IY0'],
  "phebus": ['F IY1 B AH0 S'],
  "phedra": ['F EH1 D R AH0'],
  "phegley": ['F EH1 G L IY0'],
  "phelan": ['F EH1 L AH0 N'],
  "phelps": ['F EH1 L P S'],
  "phelps's": ['F EH1 L P S IH0 Z'],
  "phen": ['F EH1 N'],
  "phenfen": ['F EH1 N F EH1 N'],
  "phenicie": ['F EH1 N AH0 K IY0'],
  "phenix": ['F EH1 N IH0 K S'],
  "phenol": ['F IY1 N AA0 L', 'F EH1 N AH0 L'],
  "phenolic": ['F AH0 N AA1 L IH0 K'],
  "phenolphthalein": ['F IY2 N AA0 L F TH EY1 L AH0 N', 'F EH2 N AA0 L F TH EY1 L AH0 N'],
  "phenom": ['F EH1 N AH0 M'],
  "phenomena": ['F AH0 N AA1 M AH0 N AH0'],
  "phenomenal": ['F AH0 N AA1 M AH0 N AH0 L'],
  "phenomenally": ['F AH0 N AA1 M AH0 N AH0 L IY0'],
  "phenomenology": ['F AH0 N AA1 M AH0 N AA2 L AA0 G IY2'],
  "phenomenon": ['F AH0 N AA1 M AH0 N AA2 N'],
  "phenotype": ['F IY1 N AH0 T AY2 P'],
  "phenotypes": ['F IY1 N AH0 T AY2 P S'],
  "phenotypic": ['F IY2 N AH0 T IH1 P IH0 K'],
  "phenylthaline": ['F IY2 N AH0 L TH EY1 L IY0 N'],
  "pherigo": ['F EH0 R IY1 G OW0'],
  "pheromone": ['F EH1 R AH0 M OW2 N'],
  "pheromones": ['F EH1 R AH0 M OW2 N Z'],
  "pherson": ['F ER1 S AH0 N'],
  "phetteplace": ['F EH1 T P L EY0 S'],
  "phew": ['F Y UW1'],
  "phi": ['F AY1'],
  "phibbs": ['F IH1 B Z'],
  "phibro": ['F IH1 B R OW0'],
  "phifer": ['F AY1 F ER0'],
  "phil": ['F IH1 L'],
  "phil's": ['F IH1 L Z'],
  "philadelphia": ['F IH2 L AH0 D EH1 L F IY0 AH0'],
  "philadelphia's": ['F IH2 L AH0 D EH1 L F IY0 AH0 Z'],
  "philadelphian": ['F IH2 L AH0 D EH1 L F IY0 AH0 N'],
  "philadelphians": ['F IH2 L AH0 D EH1 L F IY0 AH0 N Z'],
  "philana": ['F IH0 L AE1 N AH0'],
  "philander": ['F AH0 L AE1 N D ER0'],
  "philanderer": ['F AH0 L AE1 N D EH2 R ER0'],
  "philanderers": ['F AH0 L AE1 N D EH2 R ER0 Z'],
  "philandering": ['F AY2 L AE1 N D ER0 IH0 NG', 'F AH0 L AE1 N D ER0 IH0 NG'],
  "philantha": ['F IH0 L AE1 N TH AH0'],
  "philanthropic": ['F IH2 L AH0 N TH R AA1 P IH0 K'],
  "philanthropies": ['F AH0 L AE1 N TH R AH0 P IY0 Z'],
  "philanthropist": ['F AH0 L AE1 N TH R AH0 P AH0 S T', 'F IH0 L AE1 N TH R AH0 P IH0 S T'],
  "philanthropists": ['F IH0 L AE1 N TH R AH0 P IH0 S T S'],
  "philanthropy": ['F IH0 L AE1 N TH R AH0 P IY0'],
  "philatelic": ['F IH0 L AH0 T EH1 L AH0 K'],
  "philatelist": ['F IH0 L AE1 T AH0 L IH0 S T'],
  "philately": ['F IH0 L AE1 T AH0 L IY0'],
  "philbeck": ['F IH1 L B EH2 K'],
  "philbert": ['F IH1 L B ER0 T'],
  "philberta": ['F IH0 L B ER1 T AH0'],
  "philbin": ['F IH1 L B IH0 N'],
  "philbrick": ['F IH1 L B R IH0 K'],
  "philbrook": ['F IH1 L B R UH2 K'],
  "philby": ['F IH1 L B IY0'],
  "phileas": ['F AH0 L EY1 AH0 S'],
  "philemon": ['F AH0 L EY1 M AH0 N'],
  "philene": ['F IH0 L IY1 N'],
  "phileo": ['F IH1 L IY0 OW0'],
  "philharmonia": ['F IH2 L HH AA0 R M OW1 N IY0 AH0'],
  "philharmonic": ['F IH2 L HH AA2 R M AA1 N IH0 K'],
  "philharmonic's": ['F IH2 L HH AA0 R M AA1 N IH0 K S'],
  "philhower": ['F IH1 L AW0 ER0'],
  "philibert": ['F IH1 L IH0 B ER0 T'],
  "philibosian": ['F IH2 L IH0 B OW1 Z IY0 AH0 N'],
  "philida": ['F AH0 L IY1 D AH0'],
  "philina": ['F AH0 L IY1 N AH0'],
  "philip": ['F IH1 L AH0 P', 'F IH1 L IH0 P'],
  "philip's": ['F IH1 L AH0 P S', 'F IH1 L IH0 P S'],
  "philipp": ['F IH1 L IH0 P'],
  "philippa": ['F IH0 L IH1 P AH0'],
  "philippe": ['F IH0 L IY1 P EY0'],
  "philippi": ['F IH0 L IH1 P IY0'],
  "philippic": ['F IH0 L IH1 P IH0 K'],
  "philippine": ['F IH1 L AH0 P IY2 N'],
  "philippines": ['F IH1 L AH0 P IY2 N Z'],
  "philippines'": ['F IH1 L AH0 P IY2 N Z'],
  "philippino": ['F IH2 L AH0 P IY1 N OW0'],
  "philippoussis": ['F IH2 L AH0 P UW1 S AH0 S'],
  "philipps": ['F IH1 L IH0 P S'],
  "philips": ['F IH1 L AH0 P S', 'F IH1 L IH0 P S'],
  "philips'": ['F IH1 L IH0 P S'],
  "philips's": ['F IH1 L IH0 P S IH0 Z'],
  "philipson": ['F IH1 L AH0 P S AH0 N'],
  "philistia": ['F IH0 L IH1 S T IY0 AH0'],
  "philistine": ['F IH1 L AH0 S T IY2 N'],
  "philistines": ['F IH1 L AH0 S T IY2 N Z'],
  "phillabaum": ['F IH1 L AH0 B AW2 M'],
  "philley": ['F IH1 L IY0'],
  "phillie": ['F IH1 L IY0'],
  "phillies": ['F IH1 L IY0 Z'],
  "phillip": ['F IH1 L IH0 P'],
  "phillip's": ['F IH1 L IH0 P S'],
  "phillipe": ['F IH0 L IY1 P'],
  "phillipine": ['F IH1 L AH0 P IY2 N', 'F IH2 L AH0 P IY1 N'],
  "phillipines": ['F IH2 L AH0 P IY1 N Z'],
  "phillipp": ['F IH1 L IH0 P'],
  "phillippe": ['F IH1 L AH0 P'],
  "phillippi": ['F AH0 L IY1 P IY0'],
  "phillipps": ['F IH1 L IH0 P S'],
  "phillips": ['F IH1 L IH0 P S'],
  "phillips'": ['F IH1 L IH0 P S'],
  "phillips's": ['F IH1 L IH0 P S IH0 Z'],
  "phillipson": ['F IH1 L AH0 P S AH0 N'],
  "phillis": ['F IH1 L IH0 S'],
  "philly": ['F IH1 L IY0'],
  "philo": ['F IY1 L OW0'],
  "philodendron": ['F IH2 L AH0 D EH1 N D R AH0 N'],
  "philomela": ['F IH2 L AH0 M IY1 L AH0'],
  "philomena": ['F IH0 L AH0 M IY1 N AH0'],
  "philon": ['F IH1 L AH0 N'],
  "philosopher": ['F AH0 L AA1 S AH0 F ER0'],
  "philosophers": ['F AH0 L AA1 S AH0 F ER0 Z'],
  "philosophic": ['F IH2 L AH0 S AA1 F IH0 K'],
  "philosophical": ['F IH2 L AH0 S AA1 F IH0 K AH0 L'],
  "philosophically": ['F IH2 L AH0 S AA1 F IH0 K AH0 L IY0', 'F IH2 L AH0 S AA1 F IH0 K L IY0'],
  "philosophies": ['F AH0 L AA1 S AH0 F IY0 Z'],
  "philosophy": ['F AH0 L AA1 S AH0 F IY0'],
  "philp": ['F IH1 L P'],
  "philpot": ['F IH1 L P AH0 T'],
  "philpott": ['F IH1 L P AH0 T'],
  "philson": ['F IH1 L S AH0 N'],
  "philyaw": ['F IH1 L Y AA2'],
  "phineas": ['F IH1 N IY0 AH0 S'],
  "phinney": ['F IH1 N IY0'],
  "phippen": ['F IH1 P AH0 N'],
  "phipps": ['F IH1 P S'],
  "phish": ['F IH1 SH'],
  "phishing": ['F IH1 SH IH0 NG'],
  "phlcorp": ['P IY1 EY1 CH EH1 L K AO1 R P'],
  "phlebitis": ['F L AH0 B AY1 T IH0 S'],
  "phlegm": ['F L EH1 M'],
  "phlegmatic": ['F L EH0 G M AE1 T IH0 K'],
  "phlogopite": ['F L AA1 G AH0 P AY2 T'],
  "phnom": ['F N AA1 M', 'P AH0 N AA1 M'],
  "pho": ['F OW1'],
  "phobia": ['F OW1 B IY0 AH0'],
  "phobias": ['F OW1 B IY0 AH0 Z'],
  "phobic": ['F OW1 B IH0 K'],
  "phobos": ['F OW1 B OW0 S'],
  "phoebe": ['F IY1 B IY0'],
  "phoebus": ['F IY1 B AH0 S'],
  "phoenicia": ['F AH0 N IY1 SH AH0'],
  "phoenician": ['F AH0 N IY1 SH AH0 N'],
  "phoenicians": ['F AH0 N IY1 SH AH0 N Z'],
  "phoenix": ['F IY1 N IH0 K S'],
  "phoenix's": ['F IY1 N IH0 K S IH0 Z'],
  "phone": ['F OW1 N'],
  "phone's": ['F OW1 N Z'],
  "phoned": ['F OW1 N D'],
  "phonemate": ['F OW1 N M EY2 T'],
  "phoneme": ['F OW1 N IY0 M'],
  "phonemes": ['F OW1 N IY0 M Z'],
  "phonemic": ['F AH0 N IY1 M IH0 K'],
  "phones": ['F OW1 N Z'],
  "phonetic": ['F AH0 N EH1 T IH0 K'],
  "phonetically": ['F AH0 N EH1 T IH0 K L IY0'],
  "phonetics": ['F AH0 N EH1 T IH0 K S'],
  "phoney": ['F OW1 N IY0'],
  "phonic": ['F AA1 N IH0 K'],
  "phonics": ['F AA1 N IH0 K S'],
  "phoning": ['F OW1 N IH0 NG'],
  "phonograph": ['F OW1 N AH0 G R AE2 F'],
  "phonographs": ['F OW1 N AH0 G R AE2 F S'],
  "phonological": ['F OW2 N AH0 L AA1 JH IH0 K AH0 L'],
  "phonology": ['F AH0 N AA1 L AH0 JH IY2'],
  "phony": ['F OW1 N IY0'],
  "phooey": ['F UW1 IY0'],
  "phosphate": ['F AA1 S F EY0 T'],
  "phosphates": ['F AA1 S F EY0 T S'],
  "phosphatic": ['F AA0 S F AE1 T IH0 K'],
  "phosphor": ['F AA1 S F AO2 R'],
  "phosphorescence": ['F AA2 S F ER0 EH1 S AH0 N S'],
  "phosphoric": ['F AA1 S F ER0 IH0 K'],
  "phosphorite": ['F AA1 S F ER0 AY2 T'],
  "phosphorites": ['F AA1 S F ER0 AY2 T S'],
  "phosphorous": ['F AA1 S F ER0 AH0 S'],
  "phosphors": ['F AA1 S F AO2 R Z'],
  "phosphorus": ['F AA1 S F ER0 AH0 S'],
  "photo": ['F OW1 T OW2'],
  "photo's": ['F OW1 T OW2 Z'],
  "photocall": ['F OW1 T OW2 K AA0 L'],
  "photochemical": ['F OW2 T OW0 K EH1 M AH0 K AH0 L', 'F OW2 T OW0 K EH1 M IH0 K AH0 L'],
  "photoconductive": ['F OW2 T OW0 K AH0 N D AH1 K T IH0 V'],
  "photocopied": ['F OW1 T OW0 K AA2 P IY0 D'],
  "photocopier": ['F OW1 T OW0 K AA2 P IY0 ER0'],
  "photocopiers": ['F OW1 T OW0 K AA2 P IY0 ER0 Z'],
  "photocopies": ['F OW1 T OW0 K AA2 P IY0 Z'],
  "photocopy": ['F OW1 T OW0 K AA2 P IY0'],
  "photocopying": ['F OW1 T OW0 K AA2 P IY0 IH0 NG'],
  "photodynamic": ['F OW2 T OW0 D AY0 N AE1 M IH0 K'],
  "photoelectric": ['F OW2 T OW0 IH0 L EH1 K T R IH0 K'],
  "photofinisher": ['F OW2 T OW0 F IH1 N IH2 SH ER0'],
  "photofinishers": ['F OW2 T OW0 F IH1 N IH2 SH ER0 Z'],
  "photofinishing": ['F OW1 T OW0 F IH1 N IH0 SH IH0 NG'],
  "photogenic": ['F OW2 T AH0 JH EH1 N IH0 K'],
  "photograph": ['F OW1 T AH0 G R AE2 F'],
  "photographed": ['F OW1 T AH0 G R AE2 F T'],
  "photographer": ['F AH0 T AA1 G R AH0 F ER0'],
  "photographer's": ['F AH0 T AA1 G R AH0 F ER0 Z'],
  "photographers": ['F AH0 T AA1 G R AH0 F ER0 Z'],
  "photographic": ['F OW2 T AH0 G R AE1 F IH0 K'],
  "photographing": ['F OW1 T AH0 G R AE2 F IH0 NG'],
  "photographs": ['F OW1 T AH0 G R AE2 F S'],
  "photography": ['F AH0 T AA1 G R AH0 F IY0'],
  "photojournalist": ['F OW2 T OW0 JH ER1 N AH0 L AH0 S T'],
  "photometer": ['F AH0 T AA1 M IH0 T ER0'],
  "photon": ['F OW1 T AA2 N'],
  "photons": ['F OW1 T AA2 N Z'],
  "photoop": ['F OW1 T OW0 AO1 P'],
  "photophoresis": ['F OW2 T OW0 F ER0 IY1 S IH0 S'],
  "photorefractive": ['F OW2 T OW0 R IH0 F R AE1 K T IH0 V'],
  "photos": ['F OW1 T OW2 Z'],
  "photoshop": ['F OW1 T OW2 SH AA2 P'],
  "photoshopped": ['F OW2 T OW0 SH AA1 P T'],
  "photoshopping": ['F OW1 T OW2 SH AA2 P IH0 NG'],
  "photoshops": ['F OW1 T OW2 SH AA2 P S'],
  "photosynthesis": ['F OW2 T OW0 S IH1 N TH AH0 S IH0 S'],
  "phototaxis": ['F OW2 T AH0 T AE1 K S IH0 S'],
  "phototron": ['F OW1 T AH0 T R AA0 N'],
  "photovoltaic": ['F OW2 T AH0 V OW2 L T EY1 IH0 K'],
  "photovoltaics": ['F OW1 T OW0 V OW0 L T EY1 IH0 K S'],
  "photronic": ['F OW2 T R AA1 N IH0 K'],
  "photronics": ['F OW2 T R AA1 N IH0 K S'],
  "php": ['P IY1 EY1 CH P IY1'],
  "phrase": ['F R EY1 Z'],
  "phrased": ['F R EY1 Z D'],
  "phraseology": ['F R EY2 Z IY0 AO1 L AO0 JH IY0'],
  "phrases": ['F R EY1 Z AH0 Z', 'F R EY1 Z IH0 Z'],
  "phrasing": ['F R EY1 Z IH0 NG'],
  "phrygian": ['F R IH1 JH IY0 AH0 N'],
  "phu": ['F UW1'],
  "phua": ['F Y UW1 AH0'],
  "phung": ['F AH1 NG'],
  "phuong": ['F UW0 AO1 NG'],
  "phut": ['F AH1 T'],
  "phy": ['F AY1'],
  "phyla": ['F AY1 L AH0'],
  "phyle": ['F AY1 L'],
  "phylis": ['F AY1 L AH0 S'],
  "phyllis": ['F IH1 L IH0 S'],
  "phyllys": ['F IH1 L IY0 Z'],
  "phylogeny": ['F AY0 L AA1 JH AH0 N IY0'],
  "phylum": ['F AY1 L AH0 M'],
  "physical": ['F IH1 Z IH0 K AH0 L'],
  "physically": ['F IH1 Z IH0 K AH0 L IY0', 'F IH1 Z IH0 K L IY0'],
  "physicals": ['F IH1 Z IH0 K AH0 L Z'],
  "physician": ['F AH0 Z IH1 SH AH0 N'],
  "physician's": ['F AH0 Z IH1 SH AH0 N Z'],
  "physicians": ['F AH0 Z IH1 SH AH0 N Z', 'F IH0 Z IH1 SH AH0 N Z'],
  "physicians'": ['F IH0 Z IH1 SH AH0 N Z'],
  "physicist": ['F IH1 Z IH0 S IH0 S T'],
  "physicists": ['F IH1 Z IH0 S IH0 S T S'],
  "physics": ['F IH1 Z IH0 K S'],
  "physics'": ['F IH1 S IH0 K S'],
  "physio": ['F IH1 Z IY0 OW0'],
  "physiologic": ['F IH2 Z IY0 AH0 L AA1 JH IH0 K'],
  "physiological": ['F IH2 Z IY0 AH0 L AA1 JH IH0 K AH0 L'],
  "physiologically": ['F IH2 Z IY0 AH0 L AA1 JH IH0 K L IY0'],
  "physiologist": ['F IH2 Z IY0 AA1 L AH0 JH IH0 S T'],
  "physiology": ['F IH2 Z IY0 AA1 L AH0 JH IY0'],
  "physique": ['F AH0 Z IY1 K'],
  "phytogeography": ['F AY0 T OW0 JH IY0 AA1 G R AH0 F IY0'],
  "phytoplankton": ['F AY2 T OW0 P L AE1 NG T AH0 N'],
  "pi": ['P AY1'],
  "pi-meson": ['P AY1 M EY1 Z AA2 N'],
  "pia": ['P IY1 AH0'],
  "piacente": ['P IY0 AA0 CH EH1 N T IY0'],
  "piacentini": ['P IY0 AA0 CH EH0 N T IY1 N IY0'],
  "piaget": ['P IY2 AH0 ZH EY1'],
  "piaget's": ['P IY2 AH0 ZH EY1 Z'],
  "piana": ['P IY0 AE1 N AH0'],
  "pianist": ['P IY0 AE1 N AH0 S T', 'P IY0 AA1 N AH0 S T', 'P IY1 AH0 N IH0 S T'],
  "pianist's": ['P IY0 AE1 N AH0 S T S', 'P IY1 AH0 N IH0 S T S'],
  "pianists": ['P IY0 AE1 N AH0 S T S', 'P IY1 AH0 N IH0 S T S', 'P IY0 AE1 N AH0 S', 'P IY1 AH0 N IH0 S'],
  "pianka": ['P IY0 AA1 NG K AH0'],
  "piano": ['P IY0 AE1 N OW0', 'P IY0 AE1 N AH0'],
  "piano's": ['P IY0 AE1 N OW0 Z', 'P IY0 AE1 N AH0 Z'],
  "pianos": ['P IY0 AE1 N OW0 Z', 'P IY0 AE1 N AH0 Z'],
  "piascik": ['P IY1 AH0 S CH IH0 K'],
  "piasecki": ['P IY0 AH0 S EH1 T S K IY0'],
  "piasio": ['P IY0 AE1 S IY0 OW0'],
  "piatek": ['P IY0 AA1 T EH0 K'],
  "piatkowski": ['P IY0 AH0 T K AO1 F S K IY0'],
  "piatt": ['P AY1 AH0 T'],
  "piazza": ['P IY0 AE1 Z AH0'],
  "piazzolla": ['P IY2 AH0 Z AA1 L AH0'],
  "pic": ['P IH1 K'],
  "pic-a-pasta": ['P IH1 K AH0 P AA1 S T AH0'],
  "pica": ['P AY1 K AH0'],
  "picabo": ['P AH0 K AA1 B OW0'],
  "picante": ['P IY0 K AA1 N T EY0'],
  "picard": ['P IH0 K AA1 R D'],
  "picaresque": ['P IH2 K AH0 R EH1 S K'],
  "picariello": ['P IY0 K AA0 R IY0 EH1 L OW0'],
  "picaro": ['P IY1 K AA0 R OW2'],
  "picas": ['P AY1 K AH0 Z'],
  "picasso": ['P IH0 K AA1 S OW0'],
  "picasso's": ['P IH0 K AA1 S OW0 Z'],
  "picassos": ['P IH0 K AA1 S OW0 S'],
  "picayune": ['P IH2 K IY0 Y UW1 N'],
  "picazo": ['P IY0 K AA1 Z OW0'],
  "piccadilly": ['P IH1 K AH0 D IH2 L IY0'],
  "picchi": ['P IH1 K IY0'],
  "picchu": ['P IY1 CH UW0'],
  "picciano": ['P IY2 CH IY0 AA1 N OW0'],
  "piccinini": ['P IY0 CH IY0 N IY1 N IY0'],
  "piccione": ['P IY0 K CH OW1 N IY0'],
  "piccirilli": ['P IY0 CH IH0 R IY1 L IY0'],
  "piccirillo": ['P IY0 CH IH0 R IH1 L OW0'],
  "picco": ['P IH1 K OW0'],
  "piccola": ['P IY0 K OW1 L AH0'],
  "piccoli": ['P IY0 K OW1 L IY0'],
  "piccolo": ['P IH1 K AH0 L OW2'],
  "piccone": ['P IY0 K OW1 N IY0'],
  "picha": ['P IH1 CH AH0'],
  "pichardo": ['P IH0 CH AA1 R D OW0'],
  "piche": ['P IH1 CH'],
  "picheny": ['P AH0 CH EH1 N IY2'],
  "picher": ['P IH1 CH ER0'],
  "pichette": ['P AH0 SH EH1 T'],
  "pichler": ['P IH1 CH L ER0', 'P IH1 K L ER0'],
  "pichon": ['P IH1 CH AH0 N'],
  "picht": ['P IH1 K T'],
  "picinich": ['P IH1 S IH0 N IH0 CH'],
  "pick": ['P IH1 K'],
  "pick-up": ['P IH1 K AH2 P'],
  "pick-ups": ['P IH1 K AH2 P S'],
  "pickands": ['P IH1 K AH0 N D Z'],
  "pickar": ['P IH0 K AA1 R'],
  "pickard": ['P IH0 K AA1 R D'],
  "pickart": ['P IH1 K AA2 R T'],
  "picked": ['P IH1 K T'],
  "pickel": ['P IH1 K AH0 L'],
  "pickell": ['P IH1 K AH0 L'],
  "pickelsimer": ['P IH1 K IH0 L S IH0 M ER0'],
  "picken": ['P IH1 K AH0 N'],
  "pickens": ['P IH1 K AH0 N Z'],
  "pickens'": ['P IH1 K AH0 N Z'],
  "pickens's": ['P IH1 K AH0 N Z IH0 Z'],
  "picker": ['P IH1 K ER0'],
  "pickeral": ['P IH1 K ER0 AH0 L'],
  "pickerel": ['P IH1 K ER0 AH0 L'],
  "pickerell": ['P IH1 K ER0 AH0 L'],
  "pickerill": ['P IH1 K ER0 IH2 L'],
  "pickering": ['P IH1 K ER0 IH0 NG'],
  "pickers": ['P IH1 K ER0 Z'],
  "pickert": ['P IH1 K ER0 T'],
  "picket": ['P IH1 K IH0 T'],
  "picketed": ['P IH1 K AH0 T IH0 D'],
  "picketer": ['P IH1 K AH0 T ER0'],
  "picketers": ['P IH1 K AH0 T ER0 Z'],
  "picketing": ['P IH1 K AH0 T IH0 NG'],
  "pickets": ['P IH1 K AH0 T S'],
  "pickett": ['P IH1 K IH0 T'],
  "pickett's": ['P IH1 K IH0 T S'],
  "pickford": ['P IH1 K F ER0 D'],
  "pickier": ['P IH1 K IY0 ER0'],
  "picking": ['P IH1 K IH0 NG'],
  "pickings": ['P IH1 K IH0 NG Z'],
  "pickins": ['P IH1 K IH0 N Z'],
  "pickle": ['P IH1 K AH0 L'],
  "pickled": ['P IH1 K AH0 L D'],
  "pickler": ['P IH1 K AH0 L ER0', 'P IH1 K L ER0'],
  "pickles": ['P IH1 K AH0 L Z'],
  "picklesimer": ['P IH1 K AH0 L S IH0 M ER0'],
  "pickling": ['P IH1 K L IH0 NG'],
  "pickney": ['P IH1 K N IY0'],
  "pickpocket": ['P IH1 K P AA2 K AH0 T'],
  "pickpockets": ['P IH1 K P AA2 K AH0 T S'],
  "pickrel": ['P IH1 K R AH0 L'],
  "pickrell": ['P IH1 K R AH0 L'],
  "pickren": ['P IH1 K ER0 AH0 N'],
  "pickron": ['P IH1 K R AH0 N'],
  "picks": ['P IH1 K S'],
  "pickup": ['P IH1 K AH2 P'],
  "pickups": ['P IH1 K AH2 P S'],
  "pickus": ['P IH1 K AH0 S'],
  "pickwick": ['P IH1 K W IH2 K'],
  "pickworth": ['P IH1 K W ER2 TH'],
  "picky": ['P IH1 K IY0'],
  "picnic": ['P IH1 K N IH2 K'],
  "picnics": ['P IH1 K N IH2 K S'],
  "pico": ['P IY1 K OW0'],
  "picon": ['P IH1 K AH0 N'],
  "picone": ['P IH0 K OW1 N'],
  "picop": ['P IH1 K AA2 P'],
  "picotte": ['P IH0 K AO1 T'],
  "picou": ['P IY1 K UW0'],
  "picoult": ['P IH0 K OW1 L T'],
  "picower": ['P IH1 K AW2 R'],
  "pictet": ['P IH1 K T IH0 T'],
  "picton": ['P IH1 K T AH0 N'],
  "pictorial": ['P IH0 K T AO1 R IY0 AH0 L'],
  "picts": ['P IH1 K T S'],
  "picture": ['P IH1 K CH ER0'],
  "picture's": ['P IH1 K CH ER0 Z'],
  "pictured": ['P IH1 K CH ER0 D'],
  "pictures": ['P IH1 K CH ER0 Z'],
  "pictures'": ['P IH1 K CH ER0 Z'],
  "picturesque": ['P IH1 K CH ER0 AH0 S K'],
  "picturetel": ['P IH1 K CH ER2 T EH2 L'],
  "picturing": ['P IH1 K CH ER0 IH0 NG'],
  "pidcock": ['P IH1 D K AH0 K'],
  "piddle": ['P IH1 D AH0 L'],
  "piddles": ['P IH1 D AH0 L Z'],
  "piddling": ['P IH1 D AH0 L IH0 NG', 'P IH1 D L IH0 NG'],
  "piddock": ['P IH1 D AH0 K'],
  "pidgeon": ['P IH1 D JH IH0 N'],
  "pie": ['P AY1'],
  "piebald": ['P AY1 B AO2 L D'],
  "piece": ['P IY1 S'],
  "pieced": ['P IY1 S T'],
  "piecemeal": ['P IY1 S M IY2 L'],
  "pieces": ['P IY1 S AH0 Z', 'P IY1 S IH0 Z'],
  "piecework": ['P IY1 S W ER2 K'],
  "piech": ['P IY1 CH'],
  "piech's": ['P IY1 CH IH0 Z'],
  "piechocki": ['P IY0 HH OW1 T S K IY0'],
  "piechota": ['P IY0 HH OW1 T AH0'],
  "piechowski": ['P IY0 HH AO1 F S K IY0'],
  "piecing": ['P IY1 S IH0 NG'],
  "piecuch": ['P IY1 K AH0 K'],
  "pieczynski": ['P IY0 CH IH1 N S K IY0'],
  "pied": ['P AY1 D'],
  "piedboeuf": ['P IY1 D B AH2 F'],
  "piedmont": ['P IY1 D M AA2 N T'],
  "piedmont's": ['P IY1 D M AA2 N T S'],
  "piedra": ['P IY1 D R AH0'],
  "piehl": ['P IY1 L'],
  "piekarski": ['P IY0 K AA1 R S K IY0'],
  "piel": ['P IY1 L'],
  "piela": ['P IY1 L AH0'],
  "pienta": ['P IY1 N T AH0'],
  "pieper": ['P IY1 P ER0'],
  "piepgras": ['P IY1 P G R AE2 S'],
  "piepho": ['P IY1 F OW0'],
  "pier": ['P IH1 R'],
  "pieraccini": ['P IH2 R AH0 CH IY1 N IY0', 'P Y EH2 R AH0 CH IY1 N IY0'],
  "pieratt": ['P IY1 R AH0 T'],
  "pierce": ['P IH1 R S'],
  "pierce's": ['P IH1 R S IH0 Z'],
  "pierceall": ['P IH0 R S IY1 L'],
  "pierced": ['P IH1 R S T'],
  "piercey": ['P IH0 R S IY1'],
  "piercing": ['P IH1 R S IH0 NG'],
  "piercy": ['P IH1 R K IY0'],
  "pierette": ['P IH0 R EH1 T'],
  "pieri": ['P IY1 R IY0'],
  "pierie": ['P IY0 EH1 R IY0'],
  "pierie's": ['P IY0 EH1 R IY0 Z'],
  "pierini": ['P IH0 R IY1 N IY0'],
  "pierman": ['P IH1 R M AH0 N'],
  "piero": ['P IY1 R OW0'],
  "pieroni": ['P IH0 R OW1 N IY0'],
  "pierotti": ['P IH0 R OW1 T IY0'],
  "pierpoint": ['P IH0 R P OY1 N T'],
  "pierpont": ['P IH1 R P AA2 N T'],
  "pierre": ['P IY0 EH1 R'],
  "pierre's": ['P IY0 EH1 R Z'],
  "pierrelouis": ['P IH1 R IH0 L W IY0 Z', 'P Y EH1 R L W IY0 Z'],
  "pierrepont": ['P IH0 R EY1 P OW0 N T', 'P Y EH1 R P OW0 N T'],
  "pierro": ['P IH1 R OW0'],
  "pierron": ['P IH0 R AO1 N'],
  "piers": ['P IH1 R Z'],
  "piersall": ['P IH1 R S AH0 L'],
  "piersol": ['P IH1 R S AO0 L'],
  "pierson": ['P IH1 R S AH0 N'],
  "pies": ['P AY1 Z'],
  "pies's": ['P AY1 Z IH0 Z'],
  "piet": ['P AY1 IH0 T', 'P Y EH1 T', 'P IY1 T'],
  "pieter": ['P IY1 T ER0'],
  "pietermaritzburg": ['P IY2 T ER0 M EH1 R IH0 T S B ER0 G'],
  "pieters": ['P IY1 T ER0 Z'],
  "pieties": ['P AY1 AH0 T IY0 Z'],
  "pietila": ['P IY0 T IY1 L AH0'],
  "pietism": ['P IY1 T IH0 Z AH0 M'],
  "pietrangelo": ['P IY0 T R AA0 NG G EH1 L OW0'],
  "pietras": ['P IY1 T R AH0 Z'],
  "pietro": ['P IY0 EH1 T R OW0'],
  "pietrowski": ['P IY0 T R AO1 F S K IY0'],
  "pietruski": ['P IY2 EH0 T R AH1 S K IY0'],
  "pietruszka": ['P IY0 T R AH1 SH K AH0'],
  "pietrzak": ['P IY1 T ER0 Z AE0 K'],
  "pietrzyk": ['P IY1 T ER0 Z IH0 K'],
  "pietsch": ['P IY1 CH'],
  "piette": ['P IY1 T'],
  "piety": ['P AY1 AH0 T IY0'],
  "pietz": ['P IY1 T S'],
  "piezoelectric": ['P AY2 AH0 Z OW2 AH0 L EH1 K T R IH0 K'],
  "pifer": ['P AY1 F ER0'],
  "pig": ['P IH1 G'],
  "pig's": ['P IH1 G Z'],
  "pigan": ['P IH1 G AH0 N'],
  "pigeon": ['P IH1 JH AH0 N', 'P IH1 JH IH0 N'],
  "pigeon's": ['P IH1 JH AH0 N Z'],
  "pigeonhole": ['P IH1 JH AH0 N HH OW2 L'],
  "pigeonholed": ['P IH1 JH AH0 N HH OW2 L D'],
  "pigeons": ['P IH1 JH AH0 N Z'],
  "pigford": ['P IH1 G F ER0 D'],
  "pigg": ['P IH1 G'],
  "piggee": ['P IH1 G IY1'],
  "piggly": ['P IH1 G L IY0'],
  "piggott": ['P IH1 G AH0 T'],
  "piggy": ['P IH1 G IY0'],
  "piggyback": ['P IH1 G IY0 B AE2 K'],
  "piggybacked": ['P IH1 G IY0 B AE2 K T'],
  "piggybacking": ['P IH1 G IY0 B AE2 K IH0 NG'],
  "piglet": ['P IH1 G L IH0 T'],
  "piglets": ['P IH1 G L IH0 T S'],
  "pigman": ['P IH1 G M AH0 N'],
  "pigment": ['P IH1 G M AH0 N T'],
  "pigmentation": ['P IH2 G M AH0 N T EY1 SH AH0 N'],
  "pigmented": ['P IH2 G M EH1 T IH0 D'],
  "pigments": ['P IH1 G M AH0 N T S'],
  "pigmied": ['P IH1 G M IY0 D'],
  "pigmy": ['P IH1 G M IY0'],
  "pignataro": ['P IY0 G N AA0 T AA1 R OW0'],
  "pignatelli": ['P IY0 G N AA0 T EH1 L IY0'],
  "pignato": ['P IY0 G N AA1 T OW0'],
  "pignone": ['P IY0 G N OW1 N IY0'],
  "pigott": ['P IH1 G AH0 T'],
  "pigs": ['P IH1 G Z'],
  "pigskin": ['P IH1 G S K IH2 N'],
  "pigskins": ['P IH1 G S K IH2 N Z'],
  "pigsty": ['P IH1 G S T AY2'],
  "pigue": ['P IY1 G'],
  "pih": ['P IH1', 'P IY1 AY1 EY1 CH'],
  "pihl": ['P IH1 L'],
  "pik": ['P IH1 K'],
  "pike": ['P AY1 K'],
  "piker": ['P AY1 K ER0'],
  "pikers": ['P AY1 K ER0 Z'],
  "pikes": ['P AY1 K S'],
  "pikul": ['P IH1 K AH0 L'],
  "pikula": ['P IH0 K UW1 L AH0'],
  "pikus": ['P AY1 K AH0 S'],
  "pil": ['P IH1 L'],
  "pilaf": ['P IY2 L AA1 F'],
  "piland": ['P IH1 L AH0 N D'],
  "pilant": ['P IY1 L AH0 N T'],
  "pilar": ['P AY1 L ER0'],
  "pilarski": ['P IH0 L AA1 R S K IY0'],
  "pilaster": ['P AH0 L AE1 S T ER0'],
  "pilasters": ['P AH0 L AE1 S T ER0 Z'],
  "pilat": ['P IY1 L AA0 T'],
  "pilate": ['P AY1 L AH2 T'],
  "pilate's": ['P AY1 L AH2 T S'],
  "pilates": ['P IH0 L AA1 T IY2 S'],
  "pilato": ['P IY0 L AA1 T OW0'],
  "pilatus": ['P IY0 L EY1 T AH0 S'],
  "pilch": ['P IH1 L CH'],
  "pilcher": ['P IH1 L CH ER0'],
  "pile": ['P AY1 L'],
  "pilecki": ['P IH0 L EH1 K IY0'],
  "piled": ['P AY1 L D'],
  "pileggi": ['P IH0 L EH1 JH IY0'],
  "piles": ['P AY1 L Z'],
  "pileup": ['P AY1 L AH2 P'],
  "pilevsky": ['P IH0 L EH1 V S K IY0'],
  "pilfer": ['P IH1 L F ER0'],
  "pilferage": ['P IH1 L F ER0 IH0 JH'],
  "pilfered": ['P IH1 L F ER0 D'],
  "pilfering": ['P IH1 L F ER0 IH0 NG'],
  "pilfers": ['P IH1 L F ER0 Z'],
  "pilger": ['P IH1 L G ER0'],
  "pilgram": ['P IH1 L G R AH0 M'],
  "pilgreen": ['P IH0 L G R IY1 N'],
  "pilgrim": ['P IH1 L G R AH0 M', 'P IH1 L G R IH0 M'],
  "pilgrim's": ['P IH1 L G R AH0 M Z'],
  "pilgrimage": ['P IH1 L G R AH0 M AH0 JH', 'P IH1 L G R AH0 M IH0 JH'],
  "pilgrimages": ['P IH1 L G R AH0 M IH0 JH IH0 Z'],
  "pilgrims": ['P IH1 L G R AH0 M Z'],
  "piling": ['P AY1 L IH0 NG'],
  "pilings": ['P AY1 L IH0 NG Z'],
  "pilkenton": ['P IH0 L K EH1 N T AH0 N'],
  "pilkerton": ['P IH0 L K ER1 T AH0 N'],
  "pilkington": ['P IH1 L K IH0 NG T AH0 N'],
  "pilkinton": ['P IH1 L K IH0 N T AH0 N'],
  "pill": ['P IH1 L'],
  "pill's": ['P IH1 L Z'],
  "pilla": ['P IH1 L AH0'],
  "pillage": ['P IH1 L IH0 JH'],
  "pillaged": ['P IH1 L IH0 JH D'],
  "pillager": ['P IH1 L IH0 JH ER0'],
  "pillager's": ['P IH1 L IH0 JH ER0 Z'],
  "pillagers": ['P IH1 L IH0 JH ER0 Z'],
  "pillages": ['P IH1 L IH0 JH IH0 Z'],
  "pillaging": ['P IH1 L IH0 JH IH0 NG'],
  "pillar": ['P IH1 L ER0'],
  "pillard": ['P IH1 L ER0 D'],
  "pillared": ['P IH1 L ER0 D'],
  "pillars": ['P IH1 L ER0 Z'],
  "pille": ['P IH1 L'],
  "piller": ['P IH1 L ER0'],
  "pilley": ['P IH1 L IY0'],
  "pilling": ['P IH1 L IH0 NG'],
  "pillion": ['P IH1 L Y AH0 N'],
  "pilloried": ['P IH1 L ER0 IY0 D'],
  "pillory": ['P IH1 L ER0 IY0'],
  "pillow": ['P IH1 L OW0'],
  "pillows": ['P IH1 L OW0 Z'],
  "pillowtex": ['P IH1 L OW0 T EH2 K S'],
  "pills": ['P IH1 L Z'],
  "pillsbury": ['P IH1 L Z B EH2 R IY0'],
  "pillsbury's": ['P IH1 L Z B EH2 R IY0 Z'],
  "pilnak": ['P IH1 L N AE0 K'],
  "pilon": ['P IY0 L AO1 N'],
  "pilot": ['P AY1 L AH0 T'],
  "pilot's": ['P AY1 L AH0 T S'],
  "piloted": ['P AY1 L AH0 T IH0 D'],
  "piloting": ['P AY1 L AH0 T IH0 NG'],
  "pilotless": ['P AY1 L AH0 T L AH0 S'],
  "pilots": ['P AY1 L AH0 T S'],
  "pilots'": ['P AY1 L AH0 T S'],
  "pilotte": ['P IH0 L AO1 T'],
  "pilsner": ['P IH1 L Z N ER0'],
  "pilson": ['P IH1 L S AH0 N'],
  "piltdown": ['P IH1 L T D AW2 N'],
  "piltz": ['P IH1 L T S'],
  "pilz": ['P IH1 L Z'],
  "pima": ['P IY1 M AH0'],
  "pimco": ['P IH1 M K OW0'],
  "pimental": ['P IH0 M EH1 N T AH0 L'],
  "pimentel": ['P IH1 M IH0 N T AH0 L'],
  "pimm": ['P IH1 M'],
  "pimm's": ['P IH1 M Z'],
  "pimp": ['P IH1 M P'],
  "pimping": ['P IH1 M P IH0 NG'],
  "pimplapure": ['P IH2 M P L AH0 P Y UH1 R'],
  "pimple": ['P IH1 M P AH0 L'],
  "pimples": ['P IH1 M P AH0 L Z'],
  "pimps": ['P IH1 M P S'],
  "pin": ['P IH1 N'],
  "pina": ['P IY1 N AH0'],
  "pinard": ['P IH1 N ER0 D'],
  "pinatubo": ['P IH0 N AH0 T UW1 B OW0'],
  "pinault": ['P IH2 N AO1 L T'],
  "pinball": ['P IH1 N B AO2 L'],
  "pincavage": ['P IH1 NG K AH0 V IH0 JH'],
  "pincerlike": ['P IH1 N S ER0 L AY2 K'],
  "pincers": ['P IH1 N S ER0 Z'],
  "pinch": ['P IH1 N CH'],
  "pinched": ['P IH1 N CH T'],
  "pincher": ['P IH1 N CH ER0'],
  "pinchers": ['P IH1 N CH ER0 Z'],
  "pinching": ['P IH1 N CH IH0 NG'],
  "pinckard": ['P IH1 NG K ER0 D'],
  "pinckney": ['P IH1 NG K N IY0'],
  "pincus": ['P IH1 NG K AH0 S'],
  "pindaric": ['P IH0 N D AE1 R IH0 K'],
  "pindell": ['P IH1 N D AH0 L'],
  "pinder": ['P AY1 N D ER0'],
  "pindling": ['P IH1 N D L IH0 NG'],
  "pine": ['P AY1 N'],
  "pineal": ['P AY2 N IY1 AH0 L'],
  "pineapple": ['P AY1 N AE2 P AH0 L'],
  "pineapples": ['P AY1 N AE2 P AH0 L Z'],
  "pineau": ['P IH0 N OW1'],
  "pined": ['P AY1 N D'],
  "pineda": ['P IY0 N EH1 D AH0'],
  "pinedo": ['P IY0 N EY1 D OW0'],
  "pinegar": ['P IH1 N IH0 G ER0'],
  "pineiro": ['P IY0 N EH1 R OW0'],
  "pinellas": ['P IH0 N EH1 L AH0 S'],
  "pinelli": ['P IH0 N EH1 L IY0'],
  "pineo": ['P IH1 N IY0 OW0'],
  "piner": ['P AY1 N ER0'],
  "pinero": ['P IH0 N EH1 R OW0'],
  "pines": ['P AY1 N Z'],
  "pinetta": ['P AH0 N EH1 T AH0'],
  "pinetta's": ['P AH0 N EH1 T AH0 Z'],
  "pinette": ['P IH0 N EH1 T'],
  "ping": ['P IH1 NG'],
  "ping-pong": ['P IH1 NG P AO2 NG'],
  "pinged": ['P IH1 NG D'],
  "pingel": ['P IH1 NG G AH0 L'],
  "pinging": ['P IH1 NG IH0 NG'],
  "pingitore": ['P IH0 NG G IY0 T AO1 R IY2'],
  "pingleton": ['P IH1 NG G AH0 L T AA0 N'],
  "pingley": ['P IH1 NG G L IY0'],
  "pingpong": ['P IH1 NG P AO2 NG'],
  "pingree": ['P IH0 NG G R IY1'],
  "pings": ['P IH1 NG Z'],
  "pinheiro": ['P IY0 N HH EH1 R OW0'],
  "pinho": ['P IH1 N HH OW0'],
  "pinhole": ['P IH1 N HH OW2 L'],
  "pinholes": ['P IH1 N HH OW2 L Z'],
  "pini": ['P IY1 N IY0'],
  "pining": ['P AY1 N IH0 NG'],
  "pinion": ['P IH1 N Y AH0 N'],
  "pink": ['P IH1 NG K'],
  "pinkard": ['P IH1 NG K ER0 D'],
  "pinkelman": ['P IH1 NG K AH0 L M AH0 N'],
  "pinker": ['P IH1 NG K ER0'],
  "pinkerman": ['P IH1 NG K ER0 M AH0 N'],
  "pinkerton": ['P IH1 NG K ER0 T AH0 N'],
  "pinkerton's": ['P IH1 NG K ER0 T AH0 N Z'],
  "pinkest": ['P IH1 NG K IH0 S T'],
  "pinkett": ['P IH1 NG K IH0 T'],
  "pinkham": ['P IH1 NG K HH AH0 M'],
  "pinkie": ['P IH1 NG K IY0'],
  "pinkish": ['P IH1 NG K IH0 SH'],
  "pinkley": ['P IH1 NG K L IY0'],
  "pinkney": ['P IH1 NG K N IY0'],
  "pinkos": ['P IH1 NG K OW0 Z'],
  "pinkowski": ['P IH0 NG K AO1 F S K IY0'],
  "pinks": ['P IH1 NG K S'],
  "pinkstaff": ['P IH1 NG K S T AE2 F'],
  "pinkston": ['P IH1 NG K S T AH0 N'],
  "pinkus": ['P IH1 NG K AH0 S'],
  "pinkwater": ['P IH1 NG K W AO2 T ER0'],
  "pinkwater's": ['P IH1 NG K W AO2 T ER0 Z'],
  "pinky": ['P IH1 NG K IY0'],
  "pinn": ['P IH1 N'],
  "pinnacle": ['P IH1 N AH0 K AH0 L'],
  "pinnacle's": ['P IH1 N IH0 K AH0 L Z'],
  "pinned": ['P IH1 N D'],
  "pinnell": ['P IH1 N AH0 L'],
  "pinneo": ['P IH1 N IY0 OW0'],
  "pinner": ['P IH1 N ER0'],
  "pinney": ['P IH1 N IY0'],
  "pinnick": ['P IH1 N IH0 K'],
  "pinning": ['P IH1 N IH0 NG'],
  "pinnix": ['P IH1 N IH0 K S'],
  "pinnochio": ['P IH0 N OW1 K IY2 OW2'],
  "pinnochio's": ['P IH0 N OW1 K IY2 OW2 Z'],
  "pinnock": ['P IH1 N AH0 K'],
  "pinnow": ['P IH1 N OW0'],
  "pino": ['P IY1 N OW0'],
  "pino's": ['P IY1 N OW0 Z'],
  "pinocchio": ['P IH0 N OW1 K IY0 OW0'],
  "pinochet": ['P IH2 N AH0 SH EY1', 'P IY2 N AO0 CH EH1 T', 'P IY2 N OW0 SH EY1'],
  "pinochet's": ['P IH2 N AH0 SH EY1 Z', 'P IY2 N AO0 CH EH1 T S', 'P IY2 N OW0 SH EY1 Z'],
  "pinola": ['P IH0 N OW1 L AH0'],
  "pinot": ['P IH1 N AH0 T'],
  "pinpoint": ['P IH1 N P OY2 N T'],
  "pinpointed": ['P IH1 N P OY2 N T IH0 D'],
  "pinpointing": ['P IH1 N P OY2 N T IH0 NG'],
  "pinpoints": ['P IH1 N P OY2 N T S'],
  "pinprick": ['P IH1 N P R IH0 K'],
  "pinquater": ['P IH1 N K W AA2 T ER0'],
  "pins": ['P IH1 N Z'],
  "pinshan": ['P IH1 N SH AH0 N'],
  "pinsker": ['P IH1 N S K ER0'],
  "pinsky": ['P IH1 N S K IY0'],
  "pinson": ['P IH1 N S AH0 N'],
  "pinsoneault": ['P IH1 N S AH0 N AO2 L T', 'P IH2 N S AH0 N OW1'],
  "pinstripe": ['P IH1 N S T R AY2 P'],
  "pinstriped": ['P IH1 N S T R AY2 P T'],
  "pinstripes": ['P IH1 N S T R AY2 P S'],
  "pint": ['P AY1 N T'],
  "pint-size": ['P AY1 N T S AY1 Z'],
  "pint-sized": ['P AY1 N T S AY1 Z D'],
  "pinta": ['P IH1 N T AH0'],
  "pintar": ['P IY0 N T AA1 R'],
  "pinter": ['P AY1 N T ER0'],
  "pinterest": ['P IH1 N T R AH0 S T'],
  "pinto": ['P IH1 N T OW2'],
  "pints": ['P AY1 N T S'],
  "piny": ['P AY1 N IY0'],
  "pinyan": ['P IH1 N Y AH0 N'],
  "pinzon": ['P IH2 N Z AO1 N'],
  "pio": ['P AY1 OW0'],
  "pion": ['P AY1 AA2 N'],
  "pioneer": ['P AY2 AH0 N IH1 R'],
  "pioneer's": ['P AY2 AH0 N IH1 R Z'],
  "pioneered": ['P AY2 AH0 N IH1 R D'],
  "pioneering": ['P AY2 AH0 N IH1 R IH0 NG'],
  "pioneers": ['P AY2 AH0 N IH1 R Z'],
  "piontek": ['P IY0 OW1 N T EH0 K'],
  "piontkowski": ['P IY0 OW0 N T K AO1 F S K IY0'],
  "piorkowski": ['P IY0 AO0 R K AO1 F S K IY0'],
  "piotrowski": ['P IY0 OW0 T R AO1 F S K IY0'],
  "piotter": ['P IY0 AA1 T ER0'],
  "pious": ['P AY1 AH0 S'],
  "piously": ['P AY1 AH0 S L IY0'],
  "pip": ['P IH1 P'],
  "pipe": ['P AY1 P'],
  "piped": ['P AY1 P T'],
  "pipefish": ['P AY1 P F IH2 SH'],
  "pipefishes": ['P AY1 P F IH2 SH IH0 Z'],
  "pipeline": ['P AY1 P L AY2 N'],
  "pipeline's": ['P AY1 P L AY2 N Z'],
  "pipelines": ['P AY1 P L AY2 N Z'],
  "pipelines'": ['P AY1 P L AY2 N Z'],
  "piper": ['P AY1 P ER0'],
  "pipers": ['P AY1 P ER0 Z'],
  "pipes": ['P AY1 P S'],
  "pipetec": ['P AY1 P T EH2 K'],
  "pipette": ['P AY2 P EH1 T'],
  "pipetter": ['P AY2 P EH1 T ER0'],
  "pipetters": ['P AY2 P EH1 T ER0 Z'],
  "pipettes": ['P AY2 P EH1 T S'],
  "pipher": ['P IH1 F ER0'],
  "piping": ['P AY1 P IH0 NG'],
  "pipitone": ['P IH1 P IH0 T OW2 N'],
  "pipkin": ['P IH1 P K IH0 N'],
  "pipkins": ['P IH1 P K IH0 N Z'],
  "pipp": ['P IH1 P'],
  "pippen": ['P IH1 P AH0 N'],
  "pippen's": ['P IH1 P AH0 N Z'],
  "pippenger": ['P IH1 P IH0 N JH ER0'],
  "pippert": ['P IH1 P ER0 T'],
  "pippin": ['P IH1 P IH0 N'],
  "pippins": ['P IH1 P IH0 N Z'],
  "pips": ['P IH1 P S'],
  "piquant": ['P IY1 K AH0 N T'],
  "pique": ['P IY1 K'],
  "piqued": ['P IY1 K T'],
  "piquette": ['P IH0 K EH1 T'],
  "piracy": ['P AY1 R AH0 S IY0'],
  "piraeus": ['P AH0 R EY1 AH0 S'],
  "piraino": ['P IH0 R EY1 N OW0'],
  "pirandello": ['P IH2 R AH0 N D EH1 L OW0'],
  "piranha": ['P IH0 R AE1 N HH AH0'],
  "pirate": ['P AY1 R AH0 T'],
  "pirate's": ['P AY1 R AH0 T S'],
  "pirated": ['P AY1 R AH0 T IH0 D'],
  "pirates": ['P AY1 R AH0 T S'],
  "pirating": ['P AY1 R AH0 T IH0 NG'],
  "pirelli": ['P IH0 R EH1 L IY0'],
  "pirelli's": ['P IH0 R EH1 L IY0 Z'],
  "pires": ['P AY1 R Z'],
  "pirestani": ['P IH2 R EH0 S T AA1 N IY0'],
  "pirie": ['P IH1 R IY0'],
  "pirkey": ['P ER1 K IY0'],
  "pirkl": ['P ER1 K AH0 L'],
  "pirkle": ['P ER1 K AH0 L'],
  "pirko": ['P ER1 K OW0'],
  "piro": ['P IH1 R OW0'],
  "pirog": ['P ER0 AA1 G'],
  "pirone": ['P IH0 R OW1 N'],
  "pirouette": ['P IH2 R UW0 EH1'],
  "pirouettes": ['P IH2 R UW0 EH1 T S'],
  "pirozzi": ['P IH0 R AA1 Z IY0'],
  "pirrello": ['P IH0 R EH1 L OW0'],
  "pirro": ['P IH1 R OW0'],
  "pirrone": ['P IH0 R OW1 N IY0'],
  "pirtle": ['P ER1 T AH0 L'],
  "pisa": ['P IY1 S AH0'],
  "pisani": ['P IY0 S AA1 N IY0'],
  "pisano": ['P IY0 S AA1 N OW0'],
  "pisarek": ['P IH1 S ER0 EH0 K'],
  "pisarski": ['P IH0 S AA1 R S K IY0'],
  "pisca": ['P IH1 S K AH0'],
  "piscataway": ['P IH0 S K AE1 T AH0 W EY2'],
  "pisces": ['P AY1 S IY0 Z'],
  "pischel": ['P IH1 SH AH0 L'],
  "pischke": ['P IH1 SH K'],
  "pisciotta": ['P IY0 S CH OW1 T AH0'],
  "piscitelli": ['P IY0 S CH IY0 T EH1 L IY0'],
  "piscitello": ['P IH2 S IH0 T EH1 L OW0'],
  "piscopo": ['P IY0 S K OW1 P OW0'],
  "pisello": ['P IH0 S EH1 L OW0'],
  "pisoni": ['P IH0 S OW1 N IY0'],
  "piss": ['P IH1 S'],
  "piss-poor": ['P IH1 S P UW2 R'],
  "pissed": ['P IH1 S T'],
  "pisses": ['P IH1 S IH2 Z'],
  "pissing": ['P IH1 S IH2 NG'],
  "pistachio": ['P AH0 S T AE1 SH IY0 OW2'],
  "pistachios": ['P AH0 S T AE1 SH IY0 OW2 Z'],
  "pistil": ['P IH1 S T AH0 L'],
  "pistilli": ['P IY0 S T IY1 L IY0'],
  "pistol": ['P IH1 S T AH0 L'],
  "pistole": ['P IH0 S T OW1 L', 'P IH0 S T OW1 L EY0'],
  "pistols": ['P IH1 S T AH0 L Z'],
  "piston": ['P IH1 S T AH0 N'],
  "pistone": ['P IY1 S T OW0 N'],
  "pistons": ['P IH1 S T AH0 N Z'],
  "pistor": ['P IH1 S T ER0'],
  "pistorio": ['P IH2 S T AO1 R IY0 OW0'],
  "pit": ['P IH1 T'],
  "pita": ['P IY1 T AH0'],
  "pitblado": ['P IH0 T B L AA1 D OW0'],
  "pitch": ['P IH1 CH'],
  "pitchblende": ['P IH1 CH B L EH2 N D'],
  "pitched": ['P IH1 CH T'],
  "pitcher": ['P IH1 CH ER0'],
  "pitcher's": ['P IH1 CH ER0 Z'],
  "pitchers": ['P IH1 CH ER0 Z'],
  "pitches": ['P IH1 CH IH0 Z'],
  "pitchford": ['P IH1 CH F ER0 D'],
  "pitchfork": ['P IH1 CH F AO2 R K'],
  "pitchforks": ['P IH1 CH F AO2 R K S'],
  "pitching": ['P IH1 CH IH0 NG'],
  "pitchman": ['P IH1 CH M AH0 N'],
  "pitchmen": ['P IH1 CH M EH1 N'],
  "pitcock": ['P IH1 T K AA2 K'],
  "pitfall": ['P IH1 T F AO2 L'],
  "pitfalls": ['P IH1 T F AO2 L Z'],
  "pith": ['P IH1 TH'],
  "pithy": ['P IH1 TH IY0'],
  "pitiable": ['P IH1 T IY0 AH0 B AH0 L'],
  "pitied": ['P IH1 T IY2 D'],
  "pitiesalpetriere": ['P IY0 T Y EY1 S AA0 L P EH0 T R IY0 EH1 R'],
  "pitiful": ['P IH1 T AH0 F AH0 L'],
  "pitifully": ['P IH1 T IH0 F AH0 L IY0', 'P IH1 T IH0 F L IY0'],
  "pitiless": ['P IH1 T IY0 L AH0 S'],
  "pitino": ['P AH0 T IY1 N OW0'],
  "pitkin": ['P IH1 T K IH0 N'],
  "pitman": ['P IH1 T M AH0 N'],
  "pitner": ['P IH1 T N ER0'],
  "pitney": ['P IH1 T N IY0'],
  "pitofsky": ['P AH0 T AA1 F S K IY0'],
  "piton": ['P IY1 T AO2 N'],
  "pitons": ['P IY1 T AO2 N Z'],
  "pitre": ['P AY1 T ER0'],
  "pits": ['P IH1 T S'],
  "pitsch": ['P IH1 CH'],
  "pitsenbarger": ['P IH1 T S IH0 N B AA0 R G ER0'],
  "pitstick": ['P IH1 T S T IH2 K'],
  "pitswon": ['P IH1 T S W AA0 N'],
  "pitt": ['P IH1 T'],
  "pitt's": ['P IH1 T S'],
  "pittance": ['P IH1 T AH0 N S'],
  "pittard": ['P IH1 T ER0 D'],
  "pitted": ['P IH1 T IH0 D'],
  "pittencrieff": ['P IH1 T IH0 N K R IY2 F'],
  "pittenger": ['P IH1 T IH0 N JH ER0'],
  "pitting": ['P IH1 T IH0 NG'],
  "pittinger": ['P IH1 T IH0 NG ER0'],
  "pittle": ['P IH1 T AH0 L'],
  "pittman": ['P IH1 T M AH0 N'],
  "pittner": ['P IH1 T N ER0'],
  "pitts": ['P IH1 T S'],
  "pitts's": ['P IH1 T S IH0 Z'],
  "pittsboro": ['P IH1 T S B ER0 OW0'],
  "pittsburg": ['P IH1 T S B ER0 G'],
  "pittsburgh": ['P IH1 T S B ER0 G'],
  "pittsburgh's": ['P IH1 T S B ER0 G Z'],
  "pittsburgher": ['P IH1 T S B ER0 G ER0'],
  "pittsburghers": ['P IH1 T S B ER0 G ER0 Z'],
  "pittsfield": ['P IH1 T S F IY0 L D'],
  "pittsford": ['P IH1 T S F ER0 D'],
  "pittsley": ['P IH1 T S L IY0'],
  "pittston": ['P IH1 T S T AH0 N'],
  "pituitary": ['P AH0 T UW1 AH0 T EH2 R IY0', 'P IH0 T UW1 IH0 T EH2 R IY0'],
  "pity": ['P IH1 T IY0'],
  "pitying": ['P IH1 T IY0 IH0 NG'],
  "pitz": ['P IH1 T S'],
  "pitzen": ['P IH1 T Z AH0 N'],
  "pitzer": ['P IH1 T Z ER0'],
  "pius": ['P AY1 AH0 S'],
  "piver": ['P AY1 V ER0'],
  "pivonka": ['P IH0 V AA1 NG K AH0'],
  "pivot": ['P IH1 V AH0 T'],
  "pivotal": ['P IH1 V AH0 T AH0 L'],
  "pivoted": ['P IH1 V AH0 T IH0 D'],
  "pivoting": ['P IH1 V AH0 T IH0 NG'],
  "pivots": ['P IH1 V AH0 T S'],
  "pix": ['P IH1 K S'],
  "pixar": ['P IH1 K S AA0 R'],
  "pixel": ['P IH1 K S AH0 L'],
  "pixels": ['P IH1 K S AH0 L Z'],
  "pixie": ['P IH1 K S IY0'],
  "pixler": ['P IH1 K S L ER0'],
  "pixley": ['P IH1 K S L IY0'],
  "pizana": ['P IY0 Z AE1 N AH0'],
  "pizano": ['P IY0 Z AA1 N OW0'],
  "pizarro": ['P IH0 Z AA1 R OW0'],
  "pizatti": ['P IH0 Z AA1 T IY0'],
  "pizazz": ['P IH0 Z AE1 Z'],
  "pizer": ['P AY1 Z ER0'],
  "pizza": ['P IY1 T S AH0'],
  "pizza's": ['P IY1 T S AH0 Z'],
  "pizzano": ['P IY0 T S AA1 N OW0'],
  "pizzas": ['P IY1 T S AH0 Z'],
  "pizzazier": ['P IH0 Z AE1 Z Y ER0'],
  "pizzazz": ['P IH2 Z AE1 Z'],
  "pizzeria": ['P IY2 T S ER0 IY1 AH0'],
  "pizzerias": ['P IY2 T S ER0 IY1 AH0 Z'],
  "pizzi": ['P IH1 Z IY0'],
  "pizzimenti": ['P IY0 T S IY0 M EH1 N T IY0'],
  "pizzino": ['P IY0 T S IY1 N OW0'],
  "pizzitola": ['P IY0 T S IY0 T OW1 L AH0'],
  "pizzo": ['P IH1 Z OW0'],
  "pizzolato": ['P IY0 T S OW0 L AA1 T OW0'],
  "pizzuti": ['P IY0 T S UW1 T IY0'],
  "pizzuto": ['P IY0 T S UW1 T OW0'],
  "pj's": ['P IY1 JH EY2 Z'],
  "pla": ['P L AA1'],
  "placard": ['P L AE1 K ER0 D'],
  "placards": ['P L AE1 K ER0 D Z'],
  "placate": ['P L EY1 K EY0 T'],
  "placating": ['P L EY1 K EY2 T IH0 NG'],
  "place": ['P L EY1 S'],
  "place's": ['P L EY1 S IH0 Z'],
  "placebo": ['P L AH0 S IY1 B OW0'],
  "placebos": ['P L AH0 S IY1 B OW0 Z'],
  "placed": ['P L EY1 S T'],
  "placek": ['P L AA1 CH EH2 K'],
  "placemat": ['P L EY1 S M AE2 T'],
  "placement": ['P L EY1 S M AH0 N T'],
  "placements": ['P L EY1 S M AH0 N T S'],
  "placencia": ['P L AA0 CH EH1 N CH AH0'],
  "placenta": ['P L AH0 S EH1 N T AH0'],
  "placentia": ['P L AH0 S EH1 N SH AH0'],
  "placer": ['P L AE1 S ER0', 'P L EY1 S ER0'],
  "places": ['P L EY1 S AH0 Z', 'P L EY1 S IH0 Z'],
  "placeta": ['P L AH0 S EY1 T AH0'],
  "placetas": ['P L AH0 S EY1 T AH0 Z'],
  "placeway": ['P L EY1 S W EY2'],
  "placid": ['P L AE1 S AH0 D', 'P L AE1 S IH0 D'],
  "placid's": ['P L AE1 S IH0 D Z'],
  "placida": ['P L AA0 CH IY1 D AH0'],
  "placidly": ['P L AE1 S IH0 D L IY0'],
  "placido": ['P L AA1 CH IH0 D OW0', 'P L AH0 S IY1 D OW0'],
  "placing": ['P L EY1 S IH0 NG'],
  "plack": ['P L AE1 K'],
  "plack's": ['P L AE1 K S'],
  "placke": ['P L AE1 K'],
  "placko": ['P L AE1 K OW0'],
  "placoid": ['P L AE1 K OY0 D'],
  "placzek": ['P L AA1 CH EH0 K'],
  "plagens": ['P L AE1 G AH0 N Z'],
  "plagge": ['P L AE1 G'],
  "plagiarism": ['P L EY1 JH ER0 IH2 Z AH0 M'],
  "plagiarize": ['P L EY1 JH ER0 AY2 Z'],
  "plagiarized": ['P L EY1 JH ER0 AY2 Z D'],
  "plagioclase": ['P L EY1 JH IY0 AH0 K L EY2 S'],
  "plague": ['P L EY1 G'],
  "plagued": ['P L EY1 G D'],
  "plagues": ['P L EY1 G Z'],
  "plaguing": ['P L EY1 G IH0 NG'],
  "plaia": ['P L AA1 Y AH0'],
  "plaid": ['P L AE1 D'],
  "plain": ['P L EY1 N'],
  "plainclothes": ['P L EY1 N K L OW1 Z'],
  "plainer": ['P L EY1 N ER0'],
  "plaines": ['P L EY1 N Z'],
  "plainfield": ['P L EY1 N F IY2 L D'],
  "plainly": ['P L EY1 N L IY0'],
  "plaino": ['P L EY1 N OW0'],
  "plains": ['P L EY1 N Z'],
  "plainsong": ['P L EY1 N S AO2 NG'],
  "plaintiff": ['P L EY1 N T AH0 F', 'P L EY1 N AH0 F'],
  "plaintiff's": ['P L EY1 N T IH0 F S', 'P L EY1 N IH0 F S'],
  "plaintiffs": ['P L EY1 N T IH0 F S', 'P L EY1 N IH0 F S'],
  "plaintiffs'": ['P L EY1 N T IH0 F S', 'P L EY1 N IH0 F S'],
  "plaintive": ['P L EY1 N T IH0 V', 'P L EY1 N IH0 V'],
  "plaintively": ['P L EY1 N T AY2 V L IY0', 'P L EY1 N AY2 V L IY0'],
  "plainview": ['P L EY1 N V Y UW2'],
  "plair": ['P L EH1 R'],
  "plaisance": ['P L EY1 S AH0 N S'],
  "plaisted": ['P L AA1 IH0 S T IH0 D', 'P L EY1 S T IH0 D'],
  "plait": ['P L EY1 T'],
  "plaited": ['P L EY1 T IH0 D'],
  "plaiting": ['P L EY1 T IH0 NG'],
  "plaits": ['P L EY1 T S'],
  "plake": ['P L EY1 K'],
  "plam": ['P L AE1 M'],
  "plamann": ['P L AA1 M AH0 N'],
  "plambeck": ['P L AE1 M B EH2 K'],
  "plamondon": ['P L AA0 M OW0 N D AO1 N'],
  "plan": ['P L AE1 N'],
  "plan's": ['P L AE1 N Z'],
  "planar": ['P L EY1 N ER0'],
  "planarian": ['P L AH0 N EH1 R IY0 AH0 N'],
  "planas": ['P L AE1 N AH0 Z'],
  "planck": ['P L AE1 NG K'],
  "plane": ['P L EY1 N'],
  "plane's": ['P L EY1 N Z'],
  "planecon": ['P L AE1 N AH0 K AA2 N', 'P L AE1 N K AA2 N'],
  "planed": ['P L EY1 N D'],
  "planeload": ['P L EY1 N L OW2 D'],
  "planeloads": ['P L EY1 N L OW2 D Z'],
  "planer": ['P L EY1 N ER0'],
  "planers": ['P L EY1 N ER0 Z'],
  "planes": ['P L EY1 N Z'],
  "planes'": ['P L EY1 N Z'],
  "planet": ['P L AE1 N AH0 T'],
  "planet's": ['P L AE1 N AH0 T S'],
  "planetarium": ['P L AE2 N AH0 T EH1 R IY0 AH0 M'],
  "planetary": ['P L AE1 N AH0 T EH2 R IY0'],
  "planets": ['P L AE1 N AH0 T S'],
  "planitzer": ['P L AE1 N IH0 T S ER0'],
  "plank": ['P L AE1 NG K'],
  "planking": ['P L AE1 NG K IH0 NG'],
  "planks": ['P L AE1 NG K S'],
  "plankton": ['P L AE1 NG K T AH0 N'],
  "planktonic": ['P L AE0 NG K T AA1 N IH0 K'],
  "planned": ['P L AE1 N D'],
  "planner": ['P L AE1 N ER0'],
  "planner's": ['P L AE1 N ER0 Z'],
  "planners": ['P L AE1 N ER0 Z'],
  "planners'": ['P L AE1 N ER0 Z'],
  "planning": ['P L AE1 N IH0 NG'],
  "plano": ['P L EY1 N OW0'],
  "plans": ['P L AE1 N Z'],
  "plans'": ['P L AE1 N Z'],
  "plant": ['P L AE1 N T'],
  "plant's": ['P L AE1 N T S'],
  "plantain": ['P L AE1 N T AH0 N'],
  "plantains": ['P L AE1 N T AH0 N Z'],
  "plantation": ['P L AE2 N T EY1 SH AH0 N'],
  "plantations": ['P L AE2 N T EY1 SH AH0 N Z'],
  "plante": ['P L AE1 N T'],
  "planted": ['P L AE1 N T IH0 D'],
  "planter": ['P L AE1 N T ER0'],
  "planters": ['P L AE1 N T ER0 Z'],
  "plantiffs": ['P L AE1 N T IH0 F S'],
  "planting": ['P L AE1 N T IH0 NG'],
  "plantings": ['P L AE1 N T IH0 NG Z'],
  "plantlike": ['P L AE1 N T L AY2 K'],
  "plantronic": ['P L AE2 N T R AA1 N IH0 K'],
  "plantronics": ['P L AE2 N T R AA1 N IH0 K S'],
  "plants": ['P L AE1 N T S'],
  "plants'": ['P L AE1 N T S'],
  "plantz": ['P L AE1 N T S'],
  "plaque": ['P L AE1 K'],
  "plaques": ['P L AE1 K S'],
  "plas": ['P L AE1 S'],
  "plascencia": ['P L AA0 S CH EH1 N CH AH0'],
  "plasencia": ['P L AA0 S EH1 N CH AH0'],
  "plaskett": ['P L AE1 S K IH0 T'],
  "plasma": ['P L AE1 Z M AH0'],
  "plasminogen": ['P L AE2 Z M IH1 N AH0 JH IH0 N'],
  "plasmodia": ['P L AE0 Z M OW1 D IY0 AH0'],
  "plasmodium": ['P L AE0 Z M OW1 D IY0 AH0 M'],
  "plass": ['P L AE1 S'],
  "plassard": ['P L AE1 S ER0 D'],
  "plasse": ['P L AE1 S'],
  "plaster": ['P L AE1 S T ER0'],
  "plasterboard": ['P L AE1 S T ER0 B AO2 R D'],
  "plastered": ['P L AE1 S T ER0 D'],
  "plasterer": ['P L AE1 S T ER0 ER0'],
  "plastering": ['P L AE1 S T ER0 IH0 NG'],
  "plasters": ['P L AE1 S T ER0 Z'],
  "plasterwork": ['P L AE1 S T ER0 W ER2 K'],
  "plastic": ['P L AE1 S T IH0 K'],
  "plasticine": ['P L AE1 S T IH0 S IY2 N'],
  "plasticizer": ['P L AE1 S T AH0 S AY2 Z ER0'],
  "plastics": ['P L AE1 S T IH0 K S'],
  "plata": ['P L AA1 T AH0'],
  "plate": ['P L EY1 T'],
  "plateau": ['P L AE0 T OW1'],
  "plateaued": ['P L AE0 T OW1 D'],
  "plateauing": ['P L AH0 T OW1 IH0 NG'],
  "plated": ['P L EY1 T IH0 D'],
  "platek": ['P L AA1 T EH0 K'],
  "platelet": ['P L EY1 T L AH0 T'],
  "platelets": ['P L EY1 T L AH0 T S'],
  "platelike": ['P L EY1 T L AY2 K'],
  "platen": ['P L AE1 T AH0 N'],
  "plater": ['P L EY1 T ER0'],
  "plates": ['P L EY1 T S'],
  "platform": ['P L AE1 T F AO2 R M'],
  "platform's": ['P L AE1 T F AO2 R M Z'],
  "platforms": ['P L AE1 T F AO2 R M Z'],
  "plath": ['P L AE1 TH'],
  "plating": ['P L EY1 T IH0 NG'],
  "platinum": ['P L AE1 T N AH0 M', 'P L AE1 T AH0 N AH0 M'],
  "platinum's": ['P L AE1 T AH0 N AH0 M Z', 'P L AE1 T N AH0 M Z'],
  "platitude": ['P L AE1 T IH0 T UW2 D'],
  "platitudes": ['P L AE1 T IH0 T UW2 D Z'],
  "platner": ['P L AE1 T N ER0'],
  "plato": ['P L EY1 T OW0'],
  "plato's": ['P L EY1 T OW0 Z'],
  "platona": ['P L AA0 T OW1 N AH0'],
  "platonic": ['P L AH0 T AA1 N IH0 K'],
  "platonist": ['P L EY1 T AH0 N AH0 S T'],
  "platonists": ['P L EY1 T AH0 N AH0 S T S'],
  "platoon": ['P L AH0 T UW1 N'],
  "platoons": ['P L AH0 T UW1 N Z'],
  "platt": ['P L AE1 T'],
  "platte": ['P L AE1 T'],
  "platten": ['P L AE1 T AH0 N'],
  "platter": ['P L AE1 T ER0'],
  "platters": ['P L AE1 T ER0 Z'],
  "plattner": ['P L AE1 T N ER0'],
  "platts": ['P L AE1 T S'],
  "plattsburgh": ['P L AE1 T S B ER0 G'],
  "platy": ['P L EY1 T IY0'],
  "platypus": ['P L AE1 T AH0 P UH2 S'],
  "platz": ['P L AE1 T S'],
  "platzer": ['P L EY1 T Z ER0'],
  "plauche": ['P L AO1 CH'],
  "plaudit": ['P L AO1 D IH0 T'],
  "plaudits": ['P L AO1 D IH0 T S'],
  "plaugher": ['P L AO1 ER0'],
  "plausibility": ['P L AO2 Z IH0 B IH1 L IH0 T IY0'],
  "plausible": ['P L AO1 Z AH0 B AH0 L'],
  "plausibly": ['P L AO1 Z AH0 B L IY0'],
  "plaut": ['P L AO1 T'],
  "plautus": ['P L AW1 T AH0 S'],
  "plautz": ['P L AO1 T S'],
  "plavsic": ['P L AE1 V S IH0 K'],
  "plavsic's": ['P L AE1 V S IH0 K S'],
  "plax": ['P L AE1 K S'],
  "play": ['P L EY1'],
  "play's": ['P L EY1 Z'],
  "playa": ['P L AY1 AH0'],
  "playback": ['P L EY1 B AE2 K'],
  "playbook": ['P L EY1 B UH0 K'],
  "playboy": ['P L EY1 B OY2'],
  "playboy's": ['P L EY1 B OY2 Z'],
  "playcount": ['P L EY1 K AW2 N T'],
  "played": ['P L EY1 D'],
  "player": ['P L EY1 ER0'],
  "player's": ['P L EY1 ER0 Z'],
  "players": ['P L EY1 ER0 Z'],
  "players'": ['P L EY1 ER0 Z'],
  "playford": ['P L EY1 F ER0 D'],
  "playful": ['P L EY1 F AH0 L'],
  "playfully": ['P L EY1 F AH0 L IY0'],
  "playfulness": ['P L EY1 F AH0 L N AH0 S'],
  "playground": ['P L EY1 G R AW2 N D', 'P L EY1 G R AW2 N'],
  "playgrounds": ['P L EY1 G R AW2 N D Z', 'P L EY1 G R AW2 N Z'],
  "playhouse": ['P L EY1 HH AW2 S'],
  "playhouses": ['P L EY1 HH AW2 S IH0 Z'],
  "playin'": ['P L EY1 IH0 N'],
  "playing": ['P L EY1 IH0 NG'],
  "playlist": ['P L EY1 L IH0 S T'],
  "playlists": ['P L EY1 L IH0 S T S'],
  "playmate": ['P L EY1 M EY2 T'],
  "playmates": ['P L EY1 M EY2 T S'],
  "playoff": ['P L EY1 AO2 F'],
  "playoffs": ['P L EY1 AO2 F S'],
  "playpen": ['P L EY1 P EH2 N'],
  "playpens": ['P L EY1 P EH2 N Z'],
  "playroom": ['P L EY1 R UW2 M'],
  "playrooms": ['P L EY1 R UW2 M Z'],
  "plays": ['P L EY1 Z'],
  "playstation": ['P L EY1 S T EY2 SH AH0 N'],
  "playstations": ['P L EY1 S T EY2 SH AH0 N Z'],
  "playtex": ['P L EY1 T EH2 K S'],
  "plaything": ['P L EY1 TH IH2 NG'],
  "playthings": ['P L EY1 TH IH2 NG Z'],
  "playwright": ['P L EY1 R AY2 T'],
  "playwright's": ['P L EY1 R AY2 T S'],
  "playwrights": ['P L EY1 R AY2 T S'],
  "plaza": ['P L AA1 Z AH0', 'P L AE1 Z AH0'],
  "plaza's": ['P L AA1 Z AH0 Z', 'P L AE1 Z AH0 Z'],
  "plazas": ['P L AA1 Z AH0 Z', 'P L AE1 Z AH0 Z'],
  "plazic": ['P L EY1 Z IH0 K'],
  "plea": ['P L IY1'],
  "plead": ['P L IY1 D'],
  "pleaded": ['P L IY1 D IH0 D'],
  "pleading": ['P L IY1 D IH0 NG'],
  "pleadings": ['P L IY1 D IH0 NG Z'],
  "pleads": ['P L IY1 D Z'],
  "pleas": ['P L IY1 Z'],
  "pleasant": ['P L EH1 Z AH0 N T'],
  "pleasantly": ['P L EH1 Z AH0 N T L IY0'],
  "pleasanton": ['P L EH1 Z AH0 N T AH0 N'],
  "pleasantries": ['P L EH1 Z AH0 N T R IY0 Z'],
  "pleasantry": ['P L EH1 Z AH0 N T R IY0'],
  "pleasants": ['P L EH1 Z AH0 N T S'],
  "pleasantville": ['P L EH1 Z AH0 N T V IH2 L'],
  "please": ['P L IY1 Z'],
  "pleased": ['P L IY1 Z D'],
  "pleaser": ['P L IY1 Z ER0'],
  "pleases": ['P L IY1 Z IH0 Z'],
  "pleasing": ['P L IY1 Z IH0 NG'],
  "pleasingly": ['P L IY1 Z IH0 NG L IY0'],
  "pleasurable": ['P L EH1 ZH ER0 AH0 B AH0 L'],
  "pleasurama": ['P L EH2 ZH UH0 R AE1 M AH0'],
  "pleasure": ['P L EH1 ZH ER0'],
  "pleasures": ['P L EH1 ZH ER0 Z'],
  "pleat": ['P L IY1 T'],
  "pleats": ['P L IY1 T S'],
  "plebeian": ['P L AH0 B IY1 AH0 N'],
  "plebeians": ['P L AH0 B IY1 AH0 N Z'],
  "plebiscite": ['P L EH1 B AH0 S AY2 T', 'P L IY1 B IH0 S AY2 T'],
  "pled": ['P L EH1 D'],
  "pledge": ['P L EH1 JH'],
  "pledged": ['P L EH1 JH D'],
  "pledger": ['P L EH1 JH ER0'],
  "pledges": ['P L EH1 JH IH0 Z'],
  "pledging": ['P L EH1 JH IH0 NG'],
  "pleiad": ['P L IY1 AH0 D'],
  "pleiades": ['P L IY1 AH0 D IY2 Z'],
  "pleiads": ['P L IY1 AH0 D Z'],
  "plein": ['P L IY1 N'],
  "pleistocene": ['P L AY1 S T AH0 S IY2 N'],
  "plemmons": ['P L EH1 M AH0 N Z'],
  "plemons": ['P L EH1 M AH0 N Z'],
  "plenary": ['P L EH1 N ER0 IY0', 'P L IY1 N ER0 IY0'],
  "plenet": ['P L EH1 N IH0 T'],
  "plenmeer": ['P L EH0 N M IH1 R'],
  "plentiful": ['P L EH1 N T AH0 F AH0 L', 'P L EH1 N T IH0 F AH0 L', 'P L EH1 N AH0 F AH0 L', 'P L EH1 N IH0 F AH0 L'],
  "plenty": ['P L EH1 N T IY0', 'P L EH1 N IY0'],
  "plenum": ['P L EH1 N AH0 M'],
  "plenum's": ['P L EH1 N AH0 M Z'],
  "pleomorphic": ['P L IY2 AH0 M AO1 F IH0 K'],
  "pleomorphism": ['P L IY2 AH0 M AO1 F IH0 Z M'],
  "pleonasm": ['P L IY1 AH0 N AE2 Z M'],
  "plescia": ['P L EY1 SH Y AH0'],
  "plese": ['P L IY1 Z'],
  "pleshar": ['P L EH1 SH AA2 R'],
  "pleskow": ['P L EH1 S K AW0'],
  "pless": ['P L EH1 S'],
  "plessey": ['P L EH1 S IY0'],
  "plessey's": ['P L EH1 S IY0 Z'],
  "plessinger": ['P L EH1 S IH0 NG ER0'],
  "plessis": ['P L EH1 S IH0 S'],
  "plessy": ['P L EH1 S IY0'],
  "pletcher": ['P L EH1 CH ER0'],
  "plethora": ['P L EH1 TH ER0 AH0', 'P L AH0 TH AO1 R AH0'],
  "pletscher": ['P L EH1 CH ER0'],
  "plett": ['P L EH1 T'],
  "pletz": ['P L EH1 T S'],
  "pleural": ['P L UH1 R AH0 L'],
  "pleurisy": ['P L UH1 R AH0 S IY0'],
  "pleuritides": ['P L UH2 R IH1 D AH0 T IY2 Z'],
  "pleuritis": ['P L UH2 R AY1 T AH0 S', 'P L IY2 Y AH0 R AY1 T AH0 S'],
  "pleva": ['P L EY1 V AH0'],
  "plew": ['P L UW1'],
  "plewa": ['P L UW1 AH0'],
  "plex": ['P L EH1 K S'],
  "plexiglas": ['P L EH1 K S IH0 G L AE2 S', 'P L EH1 K S IY0 G L AE2 S'],
  "plexiglass": ['P L EH1 K S IH0 G L AE2 S', 'P L EH1 K S IY0 G L AE2 S'],
  "plexus": ['P L EH1 K S AH0 S'],
  "pliable": ['P L AY1 AH0 B AH0 L'],
  "pliant": ['P L AY1 AH0 N T'],
  "plichta": ['P L IH1 CH T AH0'],
  "plied": ['P L AY1 D'],
  "plier": ['P L AY1 ER0'],
  "pliers": ['P L AY1 ER0 Z'],
  "plies": ['P L AY1 Z'],
  "plight": ['P L AY1 T'],
  "pliler": ['P L AY1 L ER0'],
  "plimpton": ['P L IH1 M P T AH0 N'],
  "plinius": ['P L IH1 N IY0 AH0 S'],
  "plink": ['P L IH1 NG K'],
  "plinking": ['P L IH1 NG K IH0 NG'],
  "plinth": ['P L IH1 N TH'],
  "pliny": ['P L IH1 N IY0'],
  "pliocene": ['P L AY1 AH0 S IY2 N'],
  "plisetskaya": ['P L IH2 S AH0 T S K AY1 AH0'],
  "pliska": ['P L IH1 S K AH0'],
  "plitt": ['P L IH1 T'],
  "plocar": ['P L OW1 K AA0 R'],
  "plocek": ['P L OW1 CH EH2 K'],
  "ploch": ['P L AA1 K'],
  "plocher": ['P L AA1 K ER0'],
  "plock": ['P L AA1 K'],
  "plod": ['P L AA1 D'],
  "plodded": ['P L AA1 D IH0 D'],
  "plodding": ['P L AA1 D IH0 NG'],
  "ploeger": ['P L OW1 G ER0'],
  "ploetz": ['P L OW1 T S'],
  "plog": ['P L AA1 G'],
  "plohn": ['P L OW1 N'],
  "plonka": ['P L OW1 NG K AH0'],
  "plonski": ['P L AA1 N S K IY0'],
  "ploof": ['P L UW1 F'],
  "plop": ['P L AA1 P'],
  "plopped": ['P L AA1 P T'],
  "plosive": ['P L OW1 S IH0 V'],
  "ploss": ['P L AO1 S'],
  "plot": ['P L AA1 T'],
  "plotkin": ['P L AA1 T K IH0 N'],
  "plotner": ['P L AA1 T N ER0'],
  "plotnick": ['P L AA1 T N IH0 K'],
  "plots": ['P L AA1 T S'],
  "plott": ['P L AA1 T'],
  "plotted": ['P L AA1 T IH0 D'],
  "plotter": ['P L AA1 T ER0'],
  "plotters": ['P L AA1 T ER0 Z'],
  "plotting": ['P L AA1 T IH0 NG'],
  "plotts": ['P L AA1 T S'],
  "plotz": ['P L AA1 T S'],
  "plouff": ['P L OW1 F'],
  "plouffe": ['P L OW1 F'],
  "plough": ['P L AW1', 'P L OW1'],
  "ploughed": ['P L AW1 D'],
  "ploughs": ['P L AW1 Z'],
  "ploughshare": ['P L AW1 SH EY0 R'],
  "ploughshares": ['P L AW1 SH EY0 R Z'],
  "plourde": ['P L AO1 R D'],
  "plover": ['P L AH1 V ER0'],
  "plovers": ['P L AH1 V ER0 Z'],
  "plow": ['P L AW1'],
  "plowden": ['P L AW1 D AH0 N'],
  "plowed": ['P L AW1 D'],
  "plowing": ['P L AW1 IH0 NG'],
  "plowman": ['P L AW1 M AH0 N'],
  "plowman's": ['P L AW1 M AH0 N Z'],
  "plowright": ['P L AW1 R AY2 T'],
  "plows": ['P L AW1 Z'],
  "plowshare": ['P L AW1 SH EY2 R'],
  "plowshares": ['P L AW1 SH EY2 R Z'],
  "ploy": ['P L OY1'],
  "ploys": ['P L OY1 Z'],
  "plucinski": ['P L AH0 CH IH1 N S K IY0'],
  "pluck": ['P L AH1 K'],
  "pluck's": ['P L AH1 K S'],
  "plucked": ['P L AH1 K T'],
  "plucker": ['P L AH1 K ER0'],
  "plucking": ['P L AH1 K IH0 NG'],
  "plucks": ['P L AH1 K S'],
  "plucky": ['P L AH1 K IY0'],
  "plude": ['P L UW1 D'],
  "plue": ['P L UW1'],
  "pluff": ['P L AH1 F'],
  "plug": ['P L AH1 G'],
  "plugged": ['P L AH1 G D'],
  "plugging": ['P L AH1 G IH0 NG'],
  "plugin": ['P L AH1 G IH0 N'],
  "plugins": ['P L AH1 G IH0 N Z'],
  "plugs": ['P L AH1 G Z'],
  "plum": ['P L AH1 M'],
  "plum's": ['P L AH1 M Z'],
  "plumage": ['P L UW1 M AH0 JH', 'P L UW1 M IH0 JH'],
  "plumages": ['P L UW1 M AH0 JH AH0 Z', 'P L UW1 M IH0 JH IH0 Z'],
  "plumb": ['P L AH1 M'],
  "plumbed": ['P L AH1 M D'],
  "plumber": ['P L AH1 M ER0'],
  "plumbers": ['P L AH1 M ER0 Z'],
  "plumbing": ['P L AH1 M IH0 NG'],
  "plume": ['P L UW1 M'],
  "plumer": ['P L UW1 M ER0'],
  "plumeri": ['P L UW2 M EH1 R IY0'],
  "plumes": ['P L UW1 M Z'],
  "plumlee": ['P L AH1 M L IY2'],
  "plumley": ['P L AH1 M L IY0'],
  "plummer": ['P L AH1 M ER0'],
  "plummet": ['P L AH1 M AH0 T'],
  "plummeted": ['P L AH1 M AH0 T IH0 D'],
  "plummeting": ['P L AH1 M AH0 T IH0 NG'],
  "plummets": ['P L AH1 M AH0 T S'],
  "plump": ['P L AH1 M P'],
  "plumped": ['P L AH1 M P T'],
  "plums": ['P L AH1 M Z'],
  "plunder": ['P L AH1 N D ER0'],
  "plundered": ['P L AH1 N D ER0 D'],
  "plundering": ['P L AH1 N D ER0 IH0 NG'],
  "plunge": ['P L AH1 N JH'],
  "plunged": ['P L AH1 N JH D'],
  "plunger": ['P L AH1 N JH ER0'],
  "plunger's": ['P L AH1 N JH ER0 Z'],
  "plunges": ['P L AH1 N JH IH0 Z'],
  "plunging": ['P L AH1 N JH IH0 NG'],
  "plunk": ['P L AH1 NG K'],
  "plunked": ['P L AH1 NG K T'],
  "plunker": ['P L AH1 NG K ER0'],
  "plunkers": ['P L AH1 NG K ER0 Z'],
  "plunkett": ['P L AH1 NG K IH0 T'],
  "plunking": ['P L AH1 NG K IH0 NG'],
  "plunkitt": ['P L AH1 NG K IH0 T'],
  "plural": ['P L UH1 R AH0 L'],
  "pluralism": ['P L UH1 R AH0 L IH2 Z AH0 M'],
  "pluralistic": ['P L UH2 R AH0 L IH1 S T IH0 K'],
  "plurality": ['P L ER0 AE1 L IH0 T IY0'],
  "pluralize": ['P L UH1 R AH0 L AY0 Z'],
  "pluralized": ['P L UH1 R AH0 L AY0 Z D'],
  "pluralizes": ['P L UH1 R AH0 L AY0 Z IH0 Z'],
  "pluralizing": ['P L UH1 R AH0 L AY0 Z IH0 NG'],
  "plurals": ['P L UH1 R AH0 L Z'],
  "pluribus": ['P L UH1 R IH0 B AH0 S'],
  "plurilateral": ['P L UH2 R IH0 L AE1 T ER0 AH0 L'],
  "plus": ['P L AH1 S'],
  "plus's": ['P L AH1 S IH0 Z'],
  "pluses": ['P L AH1 S IH0 Z'],
  "plush": ['P L AH1 SH'],
  "pluta": ['P L UW1 T AH0'],
  "pluth": ['P L UW1 TH'],
  "pluto": ['P L UW1 T OW0'],
  "pluto's": ['P L UW1 T OW0 Z'],
  "plutocrat": ['P L UW1 T AH0 K R AE2 T'],
  "plutocrats": ['P L UW1 T AH0 K R AE2 T S'],
  "plutonian": ['P L UW0 T OW1 N IY0 AH0 N'],
  "plutonic": ['P L UW0 T AA1 N IH0 K'],
  "plutonium": ['P L UW0 T OW1 N IY0 AH0 M'],
  "pluvial": ['P L UW1 V IY0 AH0 L'],
  "ply": ['P L AY1'],
  "plybon": ['P L IH1 B AH0 N'],
  "plying": ['P L AY1 IH0 NG'],
  "plyler": ['P L AY1 L ER0'],
  "plymale": ['P L AY1 M EY2 L'],
  "plymouth": ['P L IH1 M AH0 TH'],
  "plymouth's": ['P L IH1 M AH0 TH S'],
  "plympton": ['P L IH1 M P T AH0 N'],
  "plywood": ['P L AY1 W UH2 D'],
  "pm": ['P IY1 EH1 M'],
  "pneumatic": ['N UW0 M AE1 T IH0 K'],
  "pneumatics": ['N UH0 M AE1 T IH0 K S'],
  "pneumo": ['N UW1 M OW0'],
  "pneumocystis": ['N UW0 M OW1 S IH0 S T IH0 S'],
  "pneumonia": ['N UW0 M OW1 N Y AH0', 'N AH0 M OW1 N Y AH0'],
  "pneumonic": ['N UW0 M AA1 N IH0 K'],
  "po": ['P OW1'],
  "po-jen": ['P OW1 JH EH1 N'],
  "poach": ['P OW1 CH'],
  "poached": ['P OW1 CH T'],
  "poacher": ['P OW1 CH ER0'],
  "poachers": ['P OW1 CH ER0 Z'],
  "poaching": ['P OW1 CH IH0 NG'],
  "poag": ['P OW1 G'],
  "poage": ['P OW1 IH0 JH'],
  "poarch": ['P AO1 R HH'],
  "pobanz": ['P OW1 B AA0 N Z'],
  "pobla": ['P OW1 B L AH0'],
  "poblano": ['P OW0 B L AA1 N OW0'],
  "pobst": ['P AA1 B S T'],
  "pocahontas": ['P OW2 K AH0 HH AA1 N T AH0 S', 'P OW2 K AH0 HH AA1 N AH0 S'],
  "pocatello": ['P OW2 K AH0 T EH1 L OW0'],
  "poch": ['P AA1 K'],
  "poche": ['P AA1 CH'],
  "pochiluk": ['P AH0 CH IY1 L UW0 K'],
  "pociask": ['P AH0 CH IY1 AH0 S K'],
  "pocius": ['P OW1 S IY0 IH0 S'],
  "pock": ['P AA1 K'],
  "pocked": ['P AA1 K T'],
  "pocket": ['P AA1 K AH0 T'],
  "pocketbook": ['P AA1 K AH0 T B UH2 K'],
  "pocketbooks": ['P AA1 K AH0 T B UH2 K S'],
  "pocketed": ['P AA1 K AH0 T IH0 D'],
  "pocketful": ['P AA1 K AH0 T F UH2 L'],
  "pocketing": ['P AA1 K AH0 T IH0 NG'],
  "pockets": ['P AA1 K AH0 T S'],
  "pocklington": ['P AA1 K L IH0 NG T AH0 N'],
  "pockmark": ['P AA1 K M AA2 R K'],
  "pockmarked": ['P AA1 K M AA2 R K T'],
  "pocks": ['P AA1 K S'],
  "poclain": ['P AA1 K L IH0 N'],
  "poco": ['P OW1 K OW0'],
  "pocock": ['P AA1 K AH0 K'],
  "pocono": ['P OW1 K AH0 N OW2'],
  "poconos": ['P OW1 K AH0 N OW0 Z'],
  "pocus": ['P OW1 K AH0 S'],
  "pod": ['P AA1 D'],
  "pod's": ['P AA1 D Z'],
  "podany": ['P AH0 D AO1 N IY0'],
  "podcast": ['P AO1 D K AE2 S T'],
  "podcaster": ['P AO1 D K AE2 S T ER0'],
  "podcasting": ['P AO1 D K AE2 S T IH0 NG'],
  "podell": ['P OW0 D EY1 L'],
  "podesta": ['P OW0 D EH1 S T AH0'],
  "podge": ['P AA1 JH'],
  "podgorski": ['P AA0 JH AO1 R S K IY0'],
  "podgurski": ['P AA0 JH ER1 S K IY0'],
  "podhoretz": ['P AA1 D HH ER0 EH0 T S', 'P AA1 D HH AO0 R EH0 T S'],
  "podiatrist": ['P AH0 D AY1 AH0 T R IH2 S T'],
  "podium": ['P OW1 D IY0 AH0 M'],
  "podiums": ['P OW1 D IY0 AH0 M Z'],
  "podleska": ['P AA2 D L EH1 S K AH0'],
  "podnar": ['P AA1 D N AA2 R'],
  "podolak": ['P AH0 D OW1 L AH0 K'],
  "podoll": ['P AA1 D AH0 L'],
  "podolski": ['P AH0 D OW1 L S K IY0'],
  "podolsky": ['P AH0 D OW1 L S K IY0'],
  "podrasky": ['P AH0 D R AE1 S K IY0'],
  "podraza": ['P OW0 D R AA1 Z AH0'],
  "pods": ['P AA1 D Z'],
  "podunk": ['P OW1 T AH0 NG K'],
  "poduska": ['P OW0 D AH1 S K AH0'],
  "poe": ['P OW1'],
  "poe's": ['P OW1 Z'],
  "poehl": ['P OW1 L'],
  "poehl's": ['P OW1 L Z', 'P AO1 L Z'],
  "poehler": ['P OW1 L ER0'],
  "poehlman": ['P OW1 L M AH0 N'],
  "poel": ['P OW1 AH0 L'],
  "poelman": ['P OW1 L M AH0 N'],
  "poem": ['P OW1 AH0 M'],
  "poems": ['P OW1 AH0 M Z'],
  "poer": ['P OW1 ER0'],
  "poeschel": ['P OW1 SH AH0 L'],
  "poeschl": ['P OW1 S K AH0 L'],
  "poet": ['P OW1 AH0 T'],
  "poet's": ['P OW1 AH0 T S'],
  "poetic": ['P OW0 EH1 T IH0 K'],
  "poetical": ['P OW0 EH1 T IH0 K AH0 L'],
  "poetically": ['P OW0 EH1 T IH0 K L IY0'],
  "poetics": ['P OW0 EH1 T IH0 K S'],
  "poetry": ['P OW1 AH0 T R IY0'],
  "poets": ['P OW1 AH0 T S'],
  "pofahl": ['P AA1 F AA0 L'],
  "poff": ['P AO1 F'],
  "poffenbarger": ['P AA1 F IH0 N B AA0 R G ER0'],
  "poffenberger": ['P AO1 F AH0 N B ER0 G ER0'],
  "pog": ['P AA1 G'],
  "poggi": ['P AA1 JH IY0'],
  "poggioli": ['P OW2 JH OW1 L IY0'],
  "pogo": ['P OW1 G OW2'],
  "pogorzelski": ['P AH0 G ER0 Z EH1 L S K IY0'],
  "pogrom": ['P AH0 G R AA1 M', 'P OW1 G R AH0 M'],
  "pogroms": ['P AH0 G R AA1 M Z', 'P OW1 G R AH0 M Z'],
  "pogs": ['P AA1 G Z'],
  "pogue": ['P OW1 G'],
  "poh": ['P OW1'],
  "pohang": ['P OW1 HH AE0 NG'],
  "pohjola": ['P OW2 JH OW1 L AH0'],
  "pohl": ['P OW1 L'],
  "pohl's": ['P OW1 L Z'],
  "pohlad": ['P OW1 L AE0 D'],
  "pohle": ['P OW1 HH AH0 L'],
  "pohlman": ['P OW1 L M AH0 N'],
  "pohlmann": ['P OW1 L M AH0 N'],
  "pohnpei": ['P OW1 N P EY2'],
  "poignancy": ['P OY1 N Y AH0 N S IY0'],
  "poignant": ['P OY1 N Y AH0 N T'],
  "poignantly": ['P OY1 N Y AH0 N T L IY0'],
  "poindexter": ['P OY1 N D EH2 K S T ER0'],
  "poindexter's": ['P OY1 N D EH2 K S T ER0 Z'],
  "poinot": ['P OY1 N AA2 T'],
  "poinsett": ['P OY1 N S IH0 T'],
  "poinsettia": ['P OY2 N S EH1 T IY2 AH0'],
  "poinsettias": ['P OY2 N S EH1 T IY2 AH0 Z'],
  "point": ['P OY1 N T'],
  "point's": ['P OY1 N T S'],
  "pointe": ['P OY1 N T'],
  "pointed": ['P OY1 N T IH0 D', 'P OY1 N AH0 D'],
  "pointedly": ['P OY1 N T IH0 D L IY2', 'P OY1 N AH0 D L IY0'],
  "pointenoire": ['P OY1 N T AH0 N OY2 R'],
  "pointer": ['P OY1 N T ER0'],
  "pointers": ['P OY1 N T ER0 Z'],
  "pointing": ['P OY1 N T IH0 NG'],
  "pointingly": ['P OY1 N T IH0 NG L IY0'],
  "pointless": ['P OY1 N T L AH0 S'],
  "points": ['P OY1 N T S'],
  "pointy": ['P OY1 N T IY0'],
  "poirier": ['P OY1 R IY0 ER0'],
  "poirot": ['P W AA2 R OW1'],
  "poirrier": ['P OY1 R IY0 ER0'],
  "poise": ['P OY1 Z'],
  "poised": ['P OY1 Z D'],
  "poison": ['P OY1 Z AH0 N'],
  "poisoned": ['P OY1 Z AH0 N D'],
  "poisoning": ['P OY1 Z AH0 N IH0 NG'],
  "poisonings": ['P OY1 Z AH0 N IH0 NG Z'],
  "poisonous": ['P OY1 Z AH0 N AH0 S'],
  "poisons": ['P OY1 Z AH0 N Z'],
  "poissant": ['P OY0 Z S AO1 N T'],
  "poisson": ['P OY1 Z S AH0 N'],
  "poitier": ['P OY1 T Y ER0', 'P W AA1 T Y EY2'],
  "poitra": ['P OY1 T R AA0'],
  "poitras": ['P OY0 T R AA1 Z'],
  "pokazukha": ['P AO2 K AH0 Z UW1 K AA0'],
  "poke": ['P OW1 K'],
  "poked": ['P OW1 K T'],
  "pokemon": ['P OW1 K EY0 M AH0 N'],
  "poker": ['P OW1 K ER0'],
  "pokes": ['P OW1 K S'],
  "pokeweed": ['P OW1 K W IY2 D'],
  "pokey": ['P OW1 K IY0'],
  "poking": ['P OW1 K IH0 NG'],
  "pokorney": ['P AA1 K ER0 N IY0'],
  "pokorny": ['P AH0 K AO1 R N IY0'],
  "pokorski": ['P AH0 K AO1 R S K IY0'],
  "poky": ['P OW1 K IY0'],
  "pol": ['P AO1 L'],
  "polacek": ['P AA1 L AH0 CH EH0 K'],
  "polachek": ['P AA1 L AH0 K IH0 K'],
  "polack": ['P OW1 L AE0 K'],
  "polak": ['P OW1 L AH0 K'],
  "polakoff": ['P AA1 L AH0 K AO0 F'],
  "polakowski": ['P AH0 L AH0 K AO1 F S K IY0'],
  "polan": ['P OW1 L AH0 N'],
  "polanco": ['P OW0 L AA1 N K OW0'],
  "poland": ['P OW1 L AH0 N D'],
  "poland's": ['P OW1 L AH0 N D Z'],
  "polandri": ['P AH0 L AA1 N D R IY0'],
  "polanski": ['P AH0 L AE1 N S K IY0'],
  "polansky": ['P AH0 L AE1 N S K IY0'],
  "polar": ['P OW1 L ER0'],
  "polarimeter": ['P OW2 L ER0 IH1 M AH0 T ER0'],
  "polaris": ['P OW0 L EH1 R AH0 S'],
  "polariscope": ['P OW0 L EH1 R AH0 S K OW2 P'],
  "polarity": ['P OW0 L EH1 R AH0 T IY0'],
  "polarization": ['P OW2 L ER0 AH0 Z EY1 SH AH0 N', 'P OW2 L ER0 IH0 Z EY1 SH AH0 N'],
  "polarize": ['P OW1 L ER0 AY2 Z'],
  "polarized": ['P OW1 L ER0 AY2 Z D'],
  "polarizer": ['P OW1 L ER0 AY2 Z ER0'],
  "polarizes": ['P OW1 L ER0 AY2 Z IH0 Z'],
  "polarizing": ['P OW1 L ER0 AY2 Z IH0 NG'],
  "polarography": ['P OW2 L ER0 AA1 G R AH0 F IY0'],
  "polaroid": ['P OW1 L ER0 OY2 D'],
  "polaroid's": ['P OW1 L ER0 OY2 D Z'],
  "polaroids": ['P OW1 L ER0 OY2 D Z'],
  "polasek": ['P AH0 L AA1 S EH0 K'],
  "polaski": ['P AH0 L AA1 S K IY0'],
  "polasky": ['P AH0 L AA1 S K IY0'],
  "polce": ['P OW1 L S'],
  "polcyn": ['P OW1 L S IH0 N'],
  "polder": ['P OW1 L D ER0'],
  "pole": ['P OW1 L'],
  "polecat": ['P OW1 L K AE2 T'],
  "polecats": ['P OW1 L K AE2 T S'],
  "polek": ['P OW1 L EH0 K'],
  "polemic": ['P AH0 L EH1 M IH0 K'],
  "polemical": ['P AH0 L EH1 M AH0 K AH0 L'],
  "polemicist": ['P AH0 L EH1 M AH0 S AH0 S T'],
  "polemics": ['P OW0 L EH1 M IH0 K S'],
  "polen": ['P OW1 L AH0 N'],
  "poles": ['P OW1 L Z'],
  "poletti": ['P OW0 L EH1 T IY0'],
  "polevanov": ['P AH0 L EH1 V AH0 N AA0 V'],
  "polevoi": ['P OW1 L AH0 V OY2'],
  "poleward": ['P OW1 L W ER0 D'],
  "poley": ['P OW1 L IY0'],
  "polgar": ['P OW1 L G ER0'],
  "polhamus": ['P OW1 L HH AH0 M IH0 S'],
  "polhemus": ['P OW1 L HH IH0 M AH0 S'],
  "polhill": ['P OW1 L HH IH2 L'],
  "poli": ['P OW1 L IY0', 'P OW1 L AY0'],
  "policastro": ['P OW0 L IY0 K AE1 S T R OW0'],
  "police": ['P AH0 L IY1 S'],
  "police's": ['P AH0 L IY1 S IH0 Z'],
  "policed": ['P AH0 L IY1 S T'],
  "policeman": ['P AH0 L IY1 S M AH0 N'],
  "policeman's": ['P AH0 L IY1 S M AH0 N Z'],
  "policemen": ['P AH0 L IY1 S M IH0 N'],
  "polices": ['P AH0 L IY1 S IH0 Z'],
  "policewoman": ['P AH0 L IY1 S W UH2 M AH0 N'],
  "policewomen": ['P AH0 L IY1 S W IH2 M EH0 N'],
  "polich": ['P AA1 L IH0 K'],
  "policies": ['P AA1 L AH0 S IY0 Z'],
  "policing": ['P AH0 L IY1 S IH0 NG'],
  "policy": ['P AA1 L AH0 S IY0'],
  "policy's": ['P AA1 L AH0 S IY0 Z'],
  "policyholder": ['P AA1 L AH0 S IY0 HH OW2 L D ER0'],
  "policyholder's": ['P AA1 L AH0 S IY0 HH OW2 L D ER0 Z'],
  "policyholders": ['P AA1 L AH0 S IY0 HH OW2 L D ER0 Z'],
  "policyholders'": ['P AA1 L AH0 S IY0 HH OW2 L D ER0 Z'],
  "policymaker": ['P AA1 L AH0 S IY0 M EY2 K ER0'],
  "policymakers": ['P AA1 L AH0 S IY0 M EY2 K ER0 Z'],
  "policymaking": ['P AA1 L AH0 S IY0 M EY2 K IH0 NG'],
  "polidori": ['P OW0 L IY0 D AO1 R IY0'],
  "polidoro": ['P OW0 L IY0 D AO1 R OW0'],
  "polifroni": ['P AA0 L AH0 F R OW1 N IY0'],
  "polimeni": ['P OW0 L IY0 M EH1 N IY0'],
  "polin": ['P OW1 L IH0 N'],
  "poling": ['P OW1 L IH0 NG'],
  "polino": ['P OW0 L IY1 N OW0'],
  "polinski": ['P AH0 L IH1 N S K IY0'],
  "polinsky": ['P AH0 L IH1 N S K IY0'],
  "polio": ['P OW1 L IY0 OW2'],
  "poliquin": ['P OW0 L IY0 K W IY1 N'],
  "polis": ['P OW1 L AH0 S', 'P OW1 L AY0 Z'],
  "polisario": ['P OW2 L IH0 S EH1 R IY0 OW0'],
  "polish": ['P AA1 L IH0 SH', 'P OW1 L IH0 SH'],
  "polished": ['P AA1 L IH0 SH T'],
  "polishing": ['P AA1 L IH0 SH IH0 NG'],
  "politano": ['P OW0 L IY0 T AA1 N OW0'],
  "politburo": ['P AA1 L AH0 T B Y UH2 R OW0'],
  "politburo's": ['P AA1 L AH0 T B Y UH2 R OW0 Z'],
  "polite": ['P AH0 L AY1 T'],
  "politely": ['P AH0 L AY1 T L IY0'],
  "politeness": ['P AH0 L AY1 T N AH0 S'],
  "polites": ['P AH0 L AY1 T S'],
  "politi": ['P OW0 L IY1 T IY0'],
  "politic": ['P AA1 L AH0 T IH2 K'],
  "political": ['P AH0 L IH1 T AH0 K AH0 L', 'P AH0 L IH1 T IH0 K AH0 L'],
  "politically": ['P AH0 L IH1 T IH0 K AH0 L IY0', 'P L IH1 T IH0 K L IY0'],
  "politician": ['P AA2 L AH0 T IH1 SH AH0 N'],
  "politician's": ['P AA2 L AH0 T IH1 SH AH0 N Z'],
  "politicians": ['P AA2 L AH0 T IH1 SH AH0 N Z'],
  "politicians'": ['P AA2 L AH0 T IH1 SH AH0 N Z'],
  "politicization": ['P AH0 L IH2 T AH0 S AH0 Z EY1 SH AH0 N'],
  "politicize": ['P AH0 L IH1 T IH0 S AY2 Z'],
  "politicized": ['P AH0 L IH1 T IH0 S AY2 Z D'],
  "politicizing": ['P AH0 L IH1 T IH0 S AY2 Z IH0 NG'],
  "politicking": ['P AA1 L AH0 T IH2 K IH0 NG'],
  "politico": ['P AH0 L IH1 T IH0 K OW2'],
  "politicos": ['P AH0 L IH1 T IH0 K OW2 Z'],
  "politics": ['P AA1 L AH0 T IH2 K S'],
  "politics'": ['P AA1 L AH0 T IH2 K S'],
  "politis": ['P AA1 L AY0 T IH0 S'],
  "polito": ['P OW0 L IY1 T OW0'],
  "politte": ['P AH0 L IH1 T'],
  "polity": ['P AA1 L AH0 T IY0'],
  "politz": ['P AA1 L IH0 T S'],
  "polivka": ['P OW0 L IY1 V K AH0'],
  "polizzi": ['P OW0 L IY1 T S IY0'],
  "polje": ['P OW1 L JH IY0'],
  "polk": ['P OW1 K', 'P OW1 L K'],
  "polk's": ['P OW1 K S', 'P OW1 L K S'],
  "polka": ['P OW1 L K AA0', 'P OW1 K AA0'],
  "polkas": ['P OW1 L K AA0 Z'],
  "polkinghorn": ['P OW1 L K IH0 NG HH AO2 R N'],
  "poll": ['P OW1 L'],
  "poll's": ['P OW1 L Z'],
  "pollack": ['P AA1 L AH0 K'],
  "pollack's": ['P AA1 L AH0 K S'],
  "pollak": ['P AA1 L AH0 K'],
  "pollan": ['P AA1 L AH0 N'],
  "polland": ['P AA1 L AH0 N D'],
  "pollard": ['P AA1 L ER0 D'],
  "pollard's": ['P AA1 L ER0 D Z'],
  "polled": ['P OW1 L D'],
  "pollen": ['P AA1 L AH0 N'],
  "pollens": ['P AA1 L AH0 N Z'],
  "pollet": ['P AA1 L IH0 T'],
  "pollett": ['P AA1 L IH0 T'],
  "polley": ['P AA1 L IY0'],
  "pollick": ['P AA1 L IH0 K'],
  "pollina": ['P OW0 L IY1 N AH0'],
  "pollinate": ['P AA1 L AH0 N EY2 T'],
  "pollinated": ['P AA1 L IH0 N EY2 T IH0 D'],
  "pollinates": ['P AA1 L AH0 N EY2 T S'],
  "pollination": ['P AA2 L AH0 N EY1 SH AH0 N'],
  "polling": ['P OW1 L IH0 NG'],
  "pollinger": ['P OW1 L IH0 NG ER0'],
  "pollini": ['P AH0 L IY1 N IY0'],
  "pollinia": ['P AA0 L IH1 N IY0 AH0'],
  "pollino": ['P OW0 L IY1 N OW0'],
  "pollio": ['P AA1 L IY0 OW0'],
  "pollitt": ['P AA1 L IH0 T'],
  "pollman": ['P OW1 L M AH0 N'],
  "pollner": ['P OW1 L N ER0'],
  "pollo": ['P AA1 L OW0'],
  "pollock": ['P AA1 L AH0 K'],
  "polloi": ['P AA2 L OY1'],
  "pollok": ['P AA1 L AH0 K'],
  "polls": ['P OW1 L Z'],
  "pollster": ['P OW1 L S T ER0'],
  "pollsters": ['P OW1 L S T ER0 Z'],
  "pollutant": ['P AH0 L UW1 T AH0 N T'],
  "pollutants": ['P AH0 L UW1 T AH0 N T S'],
  "pollute": ['P AH0 L UW1 T'],
  "polluted": ['P AH0 L UW1 T IH0 D'],
  "polluter": ['P AH0 L UW1 T ER0'],
  "polluters": ['P AH0 L UW1 T ER0 Z'],
  "pollutes": ['P AH0 L UW1 T S'],
  "polluting": ['P AH0 L UW1 T IH0 NG'],
  "pollution": ['P AH0 L UW1 SH AH0 N'],
  "pollux": ['P AA1 L AH0 K S'],
  "polly": ['P AA1 L IY0'],
  "polly's": ['P AA1 L IY0 Z'],
  "pollyanna": ['P AA2 L IY0 AE1 N AH0'],
  "pollyannish": ['P AA2 L IY0 AE1 N IH0 SH'],
  "polo": ['P OW1 L OW0'],
  "polo's": ['P OW1 L OW0 Z'],
  "polonaise": ['P AA2 L AH0 N EY1 Z'],
  "polonium": ['P AH0 L OW1 N IY0 AH0 M'],
  "polonius": ['P AH0 L OW1 N IY0 AH0 S'],
  "polonsky": ['P AH0 L AA1 N S K IY0'],
  "pols": ['P OW1 L Z'],
  "polsby": ['P OW1 L Z B IY0'],
  "polsinelli": ['P OW0 L S IY0 N EH1 L IY0'],
  "polskin": ['P AA1 L S K IH0 N', 'P OW1 L S K IH0 N'],
  "polsky": ['P OW1 L S K IY0'],
  "polson": ['P OW1 L S AH0 N'],
  "polster": ['P OW1 L S T ER0'],
  "polston": ['P OW1 L S T AH0 N'],
  "poltergeist": ['P OW1 L T ER0 G AY2 S T'],
  "poltergeists": ['P OW1 L T ER0 G AY2 S T S'],
  "poltrack": ['P OW1 L T R AE2 K'],
  "polus": ['P OW1 L AH0 S'],
  "poly": ['P AA1 L IY0'],
  "poly's": ['P AA1 L IY0 Z'],
  "polyacetylene": ['P AA2 L IY2 AH0 S EH1 T AH0 L IY2 N'],
  "polyak": ['P AA1 L Y AE0 K'],
  "polyamide": ['P AA2 L IY2 AE1 M AY2 D'],
  "polyandrous": ['P AA2 L IY2 AE1 N D R AH0 S'],
  "polyandry": ['P AA2 L IY2 AE1 N D R IY0'],
  "polybutylene": ['P AA2 L IY2 B Y UW1 T AH0 L IY2 N'],
  "polycarpic": ['P AA2 L IY2 K AA1 R P IH0 K'],
  "polycast": ['P AA1 L IY2 K AE2 S T'],
  "polychlorinate": ['P AA2 L IY2 K L AO1 R IH0 N EY2 T'],
  "polychlorinated": ['P AA2 L IY2 K L AO1 R IH0 N EY2 T IH0 D'],
  "polychrome": ['P AA1 L IY2 K R OW2 M'],
  "polyconomics": ['P AA2 L IH0 K AH0 N AA1 M IH0 K S'],
  "polyester": ['P AA2 L IY2 EH1 S T ER0'],
  "polyesters": ['P AA1 L IY2 EH2 S T ER0 Z'],
  "polyethylene": ['P AA2 L IY2 EH1 TH AH0 L IY2 N'],
  "polygamous": ['P AH0 L IH1 G AH0 M AH0 S'],
  "polygamy": ['P AH0 L IH1 G AH0 M IY2'],
  "polyglot": ['P AA2 L IY2 G L AA0 T'],
  "polygon": ['P AA1 L IH0 G AA2 N'],
  "polygonal": ['P AH0 L IH1 G AH0 N AH0 L'],
  "polygram": ['P AA1 L IY2 G R AE2 M'],
  "polygram's": ['P AA1 L IY2 G R AE2 M Z'],
  "polygraph": ['P AA1 L IY2 G R AE2 F'],
  "polygraphs": ['P AA1 L IY2 G R AE2 F S'],
  "polygynous": ['P AH0 L IH1 JH AH0 N AH0 S'],
  "polygyny": ['P AH0 L IH1 JH AH0 N IY0'],
  "polyhedron": ['P AA2 L IY2 HH IY1 D R AH0 N'],
  "polyhedrons": ['P AA2 L IY2 HH IY1 D R AH0 N Z'],
  "polyhemoglobin": ['P AA2 L IY2 HH AH0 M AA1 G L AH0 B IH0 N'],
  "polymarker": ['P AA2 L IY2 M AA1 R K ER0'],
  "polymarkers": ['P AA2 L IY2 M AA1 R K ER0 Z'],
  "polymer": ['P AA1 L AH0 M ER0'],
  "polymerase": ['P AA1 L IH2 M ER0 EY2 S'],
  "polymerization": ['P AA2 L IH0 M ER0 AH0 Z EY1 SH AH0 N'],
  "polymerize": ['P AA1 L IH0 M ER0 AY2 Z'],
  "polymerized": ['P AA1 L IH0 M ER0 AY2 Z D'],
  "polymerizes": ['P AA1 L IH0 M ER0 AY2 Z AH0 Z'],
  "polymers": ['P AA1 L IH0 M ER0 Z'],
  "polymorph": ['P AA1 L IY2 M AO2 R F'],
  "polymorphic": ['P AA2 L IY2 M AO1 R F IH0 K'],
  "polymorphism": ['P AA2 L IY2 M AO1 R F IH0 Z M'],
  "polynesia": ['P AA2 L IH2 N IY1 ZH AH0'],
  "polynesian": ['P AA2 L IH2 N IY1 ZH AH0 N'],
  "polynomial": ['P AA2 L IY2 N OW1 M IY0 AH0 L'],
  "polynomials": ['P AA2 L IY2 N OW1 M IY0 AH0 L Z'],
  "polyolefin": ['P AA2 L IY2 OW1 L AH0 F IH0 N'],
  "polyp": ['P AA1 L IH0 P'],
  "polyphase": ['P AA1 L IY2 F EY2 Z'],
  "polyphonic": ['P AA2 L IH2 F AA1 N IH0 K'],
  "polyphony": ['P AH0 L IH1 F AH0 N IY0'],
  "polypropylene": ['P AA2 L IY2 P R OW1 P AH0 L IY2 N'],
  "polyps": ['P AA1 L IH0 P S'],
  "polysaccharide": ['P AA2 L IY2 S AE1 K ER0 AY2 D'],
  "polysaccharides": ['P AA2 L IY2 S AE1 K ER0 AY2 D Z'],
  "polysar": ['P AA1 L IH0 S AA0 R'],
  "polysar's": ['P AA1 L IH0 S AA0 R Z'],
  "polysilicon": ['P AA2 L IY0 S IH1 L IH0 K AA2 N'],
  "polystyrene": ['P AA2 L IY2 S T AY1 R IY2 N'],
  "polysyllabic": ['P AA2 L IY2 S IH0 L AE1 B IH0 K'],
  "polytech": ['P AA2 L IY2 T EH1 K'],
  "polytechnic": ['P AA2 L IH0 T EH1 K N IH0 K', 'P AA2 L IY2 T EH1 K N IH0 K'],
  "polytechnologies": ['P AA2 L IY2 T EH0 K N AA1 L AH0 JH IY0 Z'],
  "polytechnology": ['P AA2 L IY2 T EH0 K N AA1 L AH0 JH IY0'],
  "polytheism": ['P AA1 L IY2 TH IY0 IH0 Z AH0 M'],
  "polytheistic": ['P AA2 L IY2 TH IY0 IH1 S T IH0 K'],
  "polytropic": ['P AA2 L IY2 T R AO1 P IH0 K'],
  "polyurethane": ['P AA2 L IY2 UH1 R AH0 TH EY2 N'],
  "polyvinyl": ['P AA2 L IY0 V AY1 N AH0 L'],
  "polzer": ['P OW1 L Z ER0'],
  "polzin": ['P OW1 L Z IH0 N'],
  "pom": ['P AO1 M'],
  "poma": ['P OW1 M AH0'],
  "pombo": ['P AA1 M B OW0'],
  "pomegranate": ['P AA2 M AH0 G R AE1 N AH0 T'],
  "pomerance": ['P AA1 M ER0 AE1 N S'],
  "pomerania": ['P AA2 M ER0 EY1 N IY0 AH0'],
  "pomeranian": ['P AA2 M ER0 EY1 N IY0 AH0 N'],
  "pomerantz": ['P AA1 M ER0 AE2 N T S'],
  "pomeranz": ['P AA1 M ER0 AE1 N S'],
  "pomerleau": ['P AA1 M ER0 L OW0'],
  "pomeroy": ['P AA1 M ER0 OY2'],
  "pomicino": ['P OW2 M IH0 S IY1 N OW0'],
  "pommel": ['P AA1 M AH0 L'],
  "pommer": ['P AA1 M ER0'],
  "pommier": ['P AA1 M IY0 ER0'],
  "pomodoro": ['P AA2 M AH0 D AO1 R OW0'],
  "pomography": ['P AH0 M AA1 G R AH0 F IY0'],
  "pomology": ['P OW0 M AA1 L AH0 JH IY0'],
  "pomona": ['P OW0 M OW1 N AH0'],
  "pomp": ['P AA1 M P'],
  "pompa": ['P AA1 M P AH0'],
  "pompadour": ['P AA1 M P AH0 D AO0 R'],
  "pompadur": ['P AA1 M P AH0 D ER0'],
  "pompano": ['P AA1 M P AH0 N OW2'],
  "pompeo": ['P OW1 M P IY0 OW0'],
  "pomper": ['P AA1 M P ER0'],
  "pompey": ['P AA1 M P IY0'],
  "pompidou": ['P AA1 M P IH0 D UW2'],
  "pompilio": ['P OW0 M P IY1 L IY0 OW0'],
  "pomplun": ['P AA1 M P L AH0 N'],
  "pompom": ['P AA1 M P AA0 M'],
  "pompoms": ['P AA1 M P AA0 M Z'],
  "pompon": ['P AA1 M P AA2 N'],
  "pomponio": ['P OW0 M P OW1 N IY0 OW0'],
  "pompons": ['P AA1 M P AA2 N Z'],
  "pomposity": ['P AA2 M P AA1 S IH0 T IY0'],
  "pompous": ['P AA1 M P AH0 S'],
  "pompousness": ['P AA1 M P AH0 S N AH0 S'],
  "pomrenze": ['P AA1 M R AH0 N Z'],
  "pomroy": ['P AA1 M R OY2'],
  "pon": ['P AA1 N'],
  "ponce": ['P OW1 N S EY0', 'P AA1 N S', 'P AA1 N S EY0'],
  "poncet": ['P AA1 N S AH0 T'],
  "ponchan": ['P AA1 N CH AH0 N'],
  "poncho": ['P AA1 N CH OW0'],
  "ponchos": ['P AA1 N CH OW0 Z'],
  "pond": ['P AA1 N D'],
  "pond's": ['P AA1 N D Z'],
  "ponder": ['P AA1 N D ER0'],
  "pondered": ['P AA1 N D ER0 D'],
  "pondering": ['P AA1 N D ER0 IH0 NG'],
  "ponderosa": ['P AA2 N D ER0 OW1 S AH0'],
  "ponderosa's": ['P AA2 N D ER0 OW1 S AH0 Z'],
  "ponderous": ['P AA1 N D ER0 AH0 S'],
  "ponders": ['P AA1 N D ER0 Z'],
  "pondimin": ['P AA1 N D AH0 M IH0 N'],
  "ponds": ['P AA1 N D Z'],
  "pong": ['P AO1 NG'],
  "pongratz": ['P AA1 NG G R AH0 T S'],
  "poniatowski": ['P AH0 N IY0 AH0 T AO1 F S K IY0'],
  "ponied": ['P OW1 N IY0 D'],
  "ponies": ['P OW1 N IY0 Z'],
  "pons": ['P AA1 N Z'],
  "ponsolle": ['P AA1 N S OW0 L'],
  "pont": ['P AA1 N T'],
  "pont's": ['P AA1 N T S'],
  "pontarelli": ['P OW0 N T AA0 R EH1 L IY0'],
  "pontbriand": ['P AA1 N T B R IY0 AH0 N D'],
  "ponte": ['P AA1 N T'],
  "pontes": ['P OW1 N T EH0 S'],
  "ponti": ['P AA1 N T IY0'],
  "pontiac": ['P AA1 N T IY0 AE2 K', 'P AA1 N IY0 AE2 K'],
  "pontiac's": ['P AA1 N T IY0 AE2 K S', 'P AA1 N IY0 AE2 K S'],
  "pontiacs": ['P AA1 N T IY0 AE2 K S', 'P AA1 N IY0 AE2 K S'],
  "pontiff": ['P AA1 N T AH0 F', 'P AA1 N T IH0 F'],
  "pontiff's": ['P AA1 N T AH0 F S'],
  "pontifical": ['P AA0 N T IH1 F AH0 K AH0 L'],
  "pontificate": ['P AA0 N T IH1 F AH0 K EY2 T'],
  "pontificated": ['P AA0 N T IH1 F AH0 K EY2 T IH0 D'],
  "pontificater": ['P AA0 N T IH1 F AH0 K EY2 T ER0'],
  "pontificaters": ['P AA0 N T IH1 F AH0 K EY2 T ER0 Z'],
  "pontificates": ['P AA0 N T IH1 F AH0 K EY2 T S'],
  "pontificating": ['P AA0 N T IH1 F AH0 K EY2 T IH0 NG'],
  "pontification": ['P AA0 N T IH2 F AH0 K EY1 SH AH0 N'],
  "pontifications": ['P AA0 N T IH2 F AH0 K EY1 SH AH0 N Z'],
  "pontikes": ['P AA2 N T IY1 K EH2 Z'],
  "pontillo": ['P OW0 N T IH1 L OW0'],
  "pontious": ['P OW1 N SH IH0 S'],
  "pontius": ['P AA1 N T IY2 IH0 S'],
  "ponto": ['P AA1 N T OW2'],
  "ponton": ['P AA1 N T AH0 N'],
  "pontoon": ['P AA0 N T UW1 N'],
  "pontoons": ['P AA2 N T UW1 N Z'],
  "ponts": ['P AA1 N T S'],
  "pony": ['P OW1 N IY2'],
  "ponytail": ['P OW1 N IY0 T EY2 L'],
  "ponzetti": ['P AA0 N Z EH1 T IY0'],
  "ponzi": ['P AA1 N Z IY0'],
  "ponzio": ['P AA1 N Z IY0 OW2'],
  "ponzo": ['P AA1 N Z OW2'],
  "poo": ['P UW1'],
  "pooch": ['P UW1 CH'],
  "pooches": ['P UW1 CH IH0 Z'],
  "poodle": ['P UW1 D AH0 L'],
  "poodles": ['P UW1 D AH0 L Z'],
  "poof": ['P UW1 F'],
  "pooh": ['P UW1'],
  "pooh-bah": ['P UW1 B AH1'],
  "poohed": ['P UW1 D'],
  "pookie": ['P UW1 K IY2'],
  "pool": ['P UW1 L'],
  "pool's": ['P UW1 L Z'],
  "pool-side": ['P UW1 L S AY1 D'],
  "poolas": ['P UW1 L AH0 S'],
  "poole": ['P UW1 L'],
  "pooled": ['P UW1 L D'],
  "pooler": ['P UW1 L ER0'],
  "pooley": ['P UW1 L IY0'],
  "pooling": ['P UW1 L IH0 NG'],
  "pools": ['P UW1 L Z'],
  "poolside": ['P UW1 L S AY2 D'],
  "poon": ['P UW1 N'],
  "poop": ['P UW1 P'],
  "pooped": ['P UW1 P T'],
  "pooper": ['P UW1 P ER0'],
  "poopers": ['P UW1 P ER0 Z'],
  "poops": ['P UW1 P S'],
  "poor": ['P UW1 R'],
  "poor's": ['P UW1 R Z'],
  "poor-spirited": ['P UW1 R S P IH1 R IH0 T IH0 D'],
  "poor-spiritedness": ['P UW1 R S P IH1 R IH0 T IH0 D N AH0 S'],
  "poorbaugh": ['P UW1 R B AO0'],
  "poore": ['P UW1 R'],
  "poorer": ['P UW1 R ER0'],
  "poorest": ['P UW1 R IH0 S T'],
  "poorhouse": ['P UW1 R HH AW2 S'],
  "poorly": ['P UW1 R L IY0'],
  "poorman": ['P UW1 R M AH0 N'],
  "poors": ['P UW1 R Z', 'P AO1 R Z'],
  "pooser": ['P UW1 Z ER0'],
  "poovey": ['P UW1 V IY0'],
  "pop": ['P AA1 P'],
  "pop's": ['P AA1 P S'],
  "popa": ['P OW1 P AH0'],
  "poparelli": ['P OW2 P AH0 R EH1 L IY0'],
  "popcorn": ['P AA1 P K AO2 R N'],
  "pope": ['P OW1 P'],
  "pope's": ['P OW1 P S'],
  "popeil": ['P OW2 P IY1 L'],
  "popejoy": ['P OW1 P JH OY2'],
  "popejoy's": ['P OW1 P JH OY2 Z'],
  "popek": ['P OW1 P IH0 K'],
  "popelka": ['P AH0 P EH1 L K AH0'],
  "popes": ['P OW1 P Z'],
  "popeye": ['P AA1 P AY2'],
  "popeye's": ['P AA1 P AY2 Z'],
  "popeyes": ['P AO1 P AY2 Z'],
  "popham": ['P AA1 F AH0 M'],
  "popick": ['P AA1 P IH0 K'],
  "popiel": ['P AA1 P IY0 L'],
  "popieluszko": ['P OW0 P IY2 EH0 L AH1 S K OW0'],
  "popik": ['P OW1 P IH0 K'],
  "popken": ['P AA1 P K AH0 N'],
  "popkin": ['P AA1 P K IH0 N'],
  "popko": ['P OW1 P K OW0'],
  "poplar": ['P AA1 P L ER0'],
  "poplawski": ['P AH0 P L AA1 F S K IY0'],
  "poplin": ['P AA1 P L IH0 N'],
  "poplins": ['P AA1 P L IH0 N Z'],
  "popoff": ['P AA1 P AO2 F'],
  "popolare": ['P AA2 P OW0 L AA1 R IY0'],
  "popov": ['P OW1 P AH0 V'],
  "popovic": ['P AA1 P AH0 V IH0 K'],
  "popovich": ['P AA1 P AH0 V IH0 CH'],
  "popowski": ['P AH0 P AO1 F S K IY0'],
  "popp": ['P AA1 P'],
  "poppa": ['P AA1 P AH0'],
  "poppadom": ['P AO1 P AH0 D AO2 M'],
  "poppe": ['P AA1 P'],
  "poppea": ['P AA1 P IY0 AH0'],
  "popped": ['P AA1 P T'],
  "poppell": ['P AA1 P AH0 L'],
  "poppen": ['P AA1 P AH0 N'],
  "popper": ['P AA1 P ER0'],
  "poppers": ['P AA1 P ER0 Z'],
  "poppies": ['P AA1 P IY0 Z'],
  "popping": ['P AA1 P IH0 NG'],
  "poppins": ['P AA1 P IH0 N Z'],
  "popple": ['P AA1 P AH0 L'],
  "poppleton": ['P AA1 P AH0 L T AA0 N'],
  "popplewell": ['P AA1 P AH0 L W EH0 L'],
  "poppy": ['P AA1 P IY0'],
  "poppy's": ['P AA1 P IY0 Z'],
  "poppycock": ['P AA1 P IY0 K AO2 K'],
  "pops": ['P AA1 P S'],
  "popsicle": ['P AA1 P S IH0 K AH0 L'],
  "popu": ['P OW1 P Y UW0'],
  "populace": ['P AA1 P Y AH0 L AH0 S'],
  "popular": ['P AA1 P Y AH0 L ER0'],
  "popularity": ['P AA2 P Y AH0 L EH1 R AH0 T IY0'],
  "popularization": ['P AA2 P Y AH0 L ER0 AH0 Z EY1 SH AH0 N'],
  "popularize": ['P AA1 P Y AH0 L ER0 AY2 Z'],
  "popularized": ['P AA1 P Y AH0 L ER0 AY2 Z D'],
  "popularizer": ['P AA1 P Y AH0 L ER0 AY2 Z ER0'],
  "popularizing": ['P AA1 P Y AH0 L ER0 AY2 Z IH0 NG'],
  "popularly": ['P AA1 P Y AH0 L ER0 L IY0'],
  "populate": ['P AA1 P Y AH0 L EY2 T'],
  "populated": ['P AA1 P Y AH0 L EY2 T IH0 D'],
  "populating": ['P AA1 P Y AH0 L EY2 T IH0 NG'],
  "population": ['P AA2 P Y AH0 L EY1 SH AH0 N'],
  "populations": ['P AA2 P Y AH0 L EY1 SH AH0 N Z'],
  "populism": ['P AA1 P Y AH0 L IH2 Z AH0 M'],
  "populist": ['P AA1 P Y AH0 L AH0 S T'],
  "populists": ['P AA1 P Y AH0 L IH0 S T S', 'P AA1 P Y AH0 L IH0 S S', 'P AA1 P Y AH0 L IH0 S'],
  "populous": ['P AA1 P Y AH0 L AH0 S'],
  "popup": ['P AA1 P AH2 P'],
  "popwell": ['P AA1 P W EH2 L'],
  "poquette": ['P AH0 K EH1 T'],
  "por": ['P AO1 R'],
  "porada": ['P AO0 R AA1 D AH0'],
  "porath": ['P AO1 R AH0 TH'],
  "porcaro": ['P AO0 R K AA1 R OW0'],
  "porcelain": ['P AO1 R S AH0 L AH0 N'],
  "porcelains": ['P AO1 R S AH0 L AH0 N Z'],
  "porcella": ['P AO0 R CH EH1 L AH0'],
  "porcelli": ['P AO0 R CH EH1 L IY0'],
  "porcello": ['P AO0 R CH EH1 L OW0'],
  "porch": ['P AO1 R CH'],
  "porcher": ['P AO1 R CH ER0', 'P AO2 R SH EY1'],
  "porches": ['P AO1 R CH AH0 Z', 'P AO1 R CH IH0 Z'],
  "porchia": ['P AO1 R K IY0 AH0'],
  "porco": ['P AO1 R K OW0'],
  "porcupine": ['P AO1 R K Y AH0 P AY2 N'],
  "porcupines": ['P AO1 R K Y AH0 P AY2 N Z'],
  "pordy": ['P AO1 R D IY0'],
  "pore": ['P AO1 R'],
  "pored": ['P AO1 R D'],
  "poremba": ['P AO0 R EH1 M B AH0'],
  "pores": ['P AO1 R Z'],
  "porges": ['P AO1 R JH IH0 Z'],
  "porgy": ['P AO1 R G IY0'],
  "poring": ['P AO1 R IH0 NG'],
  "pork": ['P AO1 R K'],
  "pork-barrel": ['P AO1 R K B EH2 R AH0 L'],
  "porker": ['P AO1 R K ER0'],
  "porkers": ['P AO1 R K ER0 Z'],
  "porky": ['P AO1 R K IY0'],
  "porn": ['P AO1 R N'],
  "porno": ['P AO1 R N OW0'],
  "pornographer": ['P AO2 R N AA1 G R AH0 F ER0'],
  "pornographers": ['P AO2 R N AA1 G R AH0 F ER0 Z'],
  "pornographic": ['P AO2 R N AH0 G R AE1 F IH0 K'],
  "pornography": ['P AO0 R N AA1 G R AH0 F IY0'],
  "porous": ['P AO1 R AH0 S'],
  "porphyritic": ['P AO2 R F ER0 IH1 T IH0 K'],
  "porphyry": ['P AO1 R F ER0 IY0'],
  "porpoise": ['P AO1 R P AH0 S'],
  "porpoises": ['P AO1 R P AH0 S AH0 Z'],
  "porr": ['P AO1 R'],
  "porras": ['P AO1 R AA0 Z'],
  "porrazzo": ['P AO0 R AA1 Z OW0'],
  "porreca": ['P AO0 R EH1 K AH0'],
  "porretta": ['P AO0 R EH1 T AH0'],
  "porridge": ['P AO1 R AH0 JH'],
  "porritt": ['P AO1 R IH0 T'],
  "porro": ['P AO1 R OW0'],
  "porsche": ['P AO1 R SH AH0', 'P AO1 R SH'],
  "porsche's": ['P AO1 R SH AH0 Z', 'P AO1 R SH IH0 Z'],
  "porsches": ['P AO1 R SH IH0 Z'],
  "port": ['P AO1 R T'],
  "port's": ['P AO1 R T S'],
  "port-angeles": ['P AO1 R T AE1 N JH AH0 L IH0 S'],
  "port-arthur": ['P AO1 R T AA1 R TH ER0'],
  "port-au-prince": ['P AO2 R T OW0 P R IH1 N S'],
  "port-clinton": ['P AO1 R T K L IH1 N T AH0 N'],
  "port-london": ['P AO1 R T L AH1 N D AH0 N'],
  "port-victoria": ['P AO1 R T V IH0 K T AO1 R IY0 AH0'],
  "porta": ['P AO1 R T AH0'],
  "portability": ['P AO2 R T AH0 B IH1 L IH0 T IY0'],
  "portable": ['P AO1 R T AH0 B AH0 L'],
  "portables": ['P AO1 R T AH0 B AH0 L Z'],
  "portadown": ['P AO1 R T AH0 D AW0 N'],
  "portage": ['P AO1 R T AH0 JH', 'P AO1 R T IH0 JH'],
  "portal": ['P AO1 R T AH0 L'],
  "portales": ['P AO0 R T AA1 L EH0 S'],
  "portals": ['P AO1 R T AH0 L Z'],
  "portanova": ['P AO0 R T AA0 N OW1 V AH0'],
  "portculis": ['P AO0 R T K AH1 L IH0 S'],
  "porte": ['P AO1 R T'],
  "portec": ['P AO1 R T EH2 K'],
  "portec's": ['P AO1 R T EH2 K S'],
  "ported": ['P AO1 R T IH0 D'],
  "portee": ['P AO1 R T IY1'],
  "portela": ['P AO0 R T EH1 L AH0'],
  "portell": ['P AO0 R T EY1 L'],
  "portelli": ['P AO0 R T EH1 L IY0'],
  "portend": ['P AO0 R T EH1 N D'],
  "portending": ['P AO0 R T EH1 N D IH0 NG'],
  "portends": ['P AO0 R T EH1 N D Z'],
  "portent": ['P AO1 R T EH0 N T'],
  "portentous": ['P AO0 R T EH1 N T AH0 S'],
  "portents": ['P AO1 R T EH2 N T S'],
  "porteous": ['P AO1 R T IY0 IH0 S'],
  "porter": ['P AO1 R T ER0'],
  "porter's": ['P AO1 R T ER0 Z'],
  "portera": ['P AO0 R T EH1 R AH0'],
  "porterages": ['P AO1 R T ER0 IH0 JH IH0 Z'],
  "porterfield": ['P AO1 R T ER0 F IY2 L D'],
  "porters": ['P AO1 R T ER0 Z'],
  "portfolio": ['P AO0 R T F OW1 L IY0 OW2'],
  "portfolio's": ['P AO0 R T F OW1 L IY0 OW2 Z'],
  "portfolios": ['P AO0 R T F OW1 L IY0 OW2 Z'],
  "porth": ['P AO1 R TH'],
  "porthos": ['P AO0 R T AO1 S'],
  "portia": ['P AO1 R SH AH0'],
  "portico": ['P AO1 R T AH0 K OW2'],
  "portier": ['P AO1 R T IY0 ER0'],
  "portilla": ['P AO2 R T IH1 L AH0'],
  "portillo": ['P AO2 R T IH1 L OW0'],
  "portinadi": ['P AO2 R T IH0 N AA1 D IY0'],
  "porting": ['P AO1 R T IH0 NG'],
  "portion": ['P AO1 R SH AH0 N'],
  "portions": ['P AO1 R SH AH0 N Z'],
  "portis": ['P AO1 R T IH0 S'],
  "portland": ['P AO1 R T L AH0 N D'],
  "portland's": ['P AO1 R T L AH0 N D Z'],
  "portlock": ['P AO1 R T L AA2 K'],
  "portly": ['P AO1 R T L IY0'],
  "portman": ['P AO1 R T M AH0 N'],
  "portmanteau": ['P AO1 R T M AA0 N T OW2'],
  "portner": ['P AO1 R T N ER0'],
  "portney": ['P AO1 R T N IY0'],
  "portnoy": ['P AO1 R T N OY0'],
  "porto": ['P AO1 R T OW0'],
  "portrait": ['P AO1 R T R AH0 T'],
  "portraits": ['P AO1 R T R AH0 T S'],
  "portray": ['P AO0 R T R EY1'],
  "portrayal": ['P AO0 R T R EY1 AH0 L'],
  "portrayals": ['P AO0 R T R EY1 AH0 L Z'],
  "portrayed": ['P AO0 R T R EY1 D'],
  "portraying": ['P AO0 R T R EY1 IH0 NG'],
  "portrays": ['P AO0 R T R EY1 Z'],
  "ports": ['P AO1 R T S'],
  "portsmouth": ['P AO1 R T S M AH0 TH'],
  "portugal": ['P AO1 R CH AH0 G AH0 L'],
  "portugal's": ['P AO1 R CH AH0 G AH0 L Z'],
  "portugalia": ['P AO2 R CH UW1 G EY1 L IY0 AH0'],
  "portuguese": ['P AO1 R CH AH0 G IY2 Z'],
  "portwood": ['P AO1 R T W UH2 D'],
  "portz": ['P AO1 R T S'],
  "porzio": ['P AO1 R Z IY0 OW0'],
  "pos": ['P AA1 S', 'P IY1 OW1 EH1 S'],
  "posa": ['P OW1 S AH0'],
  "posada": ['P OW0 S AA1 D AH0'],
  "posavina": ['P OW0 S AH0 V IY1 N AH0', 'P AO0 S AH0 V IY1 N AH0'],
  "posch": ['P AO1 SH'],
  "posco": ['P AO1 S K OW0'],
  "pose": ['P OW1 Z'],
  "posed": ['P OW1 Z D'],
  "poseidon": ['P AH0 S AY1 D AH0 N'],
  "poseidon's": ['P AH0 S AY1 D AH0 N Z'],
  "posen": ['P OW1 Z AH0 N'],
  "poser": ['P OW1 Z ER0'],
  "poses": ['P OW1 Z AH0 Z', 'P OW1 Z IH0 Z'],
  "poseur": ['P OW1 Z ER0', 'P OW2 Z ER1'],
  "poseurs": ['P OW1 Z ER0 Z', 'P OW2 Z ER1 Z'],
  "posey": ['P OW1 Z IY0'],
  "posh": ['P AA1 SH'],
  "poshard": ['P AA1 SH ER0 D'],
  "posing": ['P OW1 Z IH0 NG'],
  "posit": ['P AA1 Z AH0 T'],
  "posited": ['P AA1 Z AH0 T IH0 D'],
  "position": ['P AH0 Z IH1 SH AH0 N'],
  "positioned": ['P AH0 Z IH1 SH AH0 N D'],
  "positioning": ['P AH0 Z IH1 SH AH0 N IH0 NG'],
  "positions": ['P AH0 Z IH1 SH AH0 N Z'],
  "positive": ['P AA1 Z AH0 T IH0 V'],
  "positively": ['P AA1 Z AH0 T IH0 V L IY0'],
  "positives": ['P AA1 Z AH0 T IH0 V Z'],
  "positivism": ['P AA1 Z AH0 T IH0 V IH2 Z AH0 M'],
  "positron": ['P AA1 Z AH0 T R AA2 N'],
  "positrons": ['P AA1 Z AH0 T R AA2 N Z'],
  "posits": ['P AA1 Z AH0 T S'],
  "posluns": ['P AO1 Z L AH0 N Z'],
  "posluszny": ['P AH0 S L AH1 SH N IY0'],
  "posner": ['P OW1 Z N ER0', 'P AO1 Z N ER0'],
  "posner's": ['P OW1 Z N ER0 Z', 'P AO1 Z N ER0 Z'],
  "posners": ['P OW1 Z N ER0 Z', 'P AO1 Z N ER0 Z'],
  "posnick": ['P AO1 S N IH0 K'],
  "pospisil": ['P OW0 S P IY0 S IY1 L'],
  "poss": ['P AO1 S'],
  "posse": ['P AA1 S IY0'],
  "possehl": ['P AA1 S AH0 L'],
  "posses": ['P AA1 S IY0 Z'],
  "possess": ['P AH0 Z EH1 S'],
  "possessed": ['P AH0 Z EH1 S T'],
  "possesses": ['P AH0 Z EH1 S AH0 Z', 'P AH0 Z EH1 S IH0 Z'],
  "possessing": ['P AH0 Z EH1 S IH0 NG'],
  "possession": ['P AH0 Z EH1 SH AH0 N'],
  "possessions": ['P AH0 Z EH1 SH AH0 N Z'],
  "possessive": ['P AH0 Z EH1 S IH0 V'],
  "possessiveness": ['P AH0 Z EH1 S IH0 V N AH0 S'],
  "possessor": ['P AH0 Z EH1 S ER0'],
  "possibilities": ['P AA2 S AH0 B IH1 L AH0 T IY0 Z'],
  "possibility": ['P AA2 S AH0 B IH1 L AH0 T IY2'],
  "possible": ['P AA1 S AH0 B AH0 L'],
  "possibly": ['P AA1 S AH0 B L IY0'],
  "possum": ['P AA1 S AH0 M'],
  "possums": ['P AA1 S AH0 M Z'],
  "post": ['P OW1 S T'],
  "post's": ['P OW1 S T S'],
  "post-attack": ['P OW1 S T AH0 T AE1 K'],
  "post-mortem": ['P OW0 S T M AO1 R T EH0 M'],
  "post-traumatic": ['P OW2 S T R AO0 M AE1 T IH0 K'],
  "postage": ['P OW1 S T AH0 JH', 'P OW1 S T IH0 JH'],
  "postal": ['P OW1 S T AH0 L'],
  "postal's": ['P OW1 S T AH0 L Z'],
  "postcard": ['P OW1 S T K AA2 R D', 'P OW1 S K AA2 R D'],
  "postcards": ['P OW1 S T K AA2 R D Z', 'P OW1 S K AA2 R D Z'],
  "postcrash": ['P OW1 S T K R AE2 SH'],
  "postdate": ['P OW2 S T D EY1 T'],
  "postdated": ['P OW2 S T D EY1 T IH0 D'],
  "postdates": ['P OW2 S T D EY1 T S'],
  "postdating": ['P OW2 S T D EY1 T IH0 NG'],
  "postdoctoral": ['P OW2 S T D AA1 K T ER2 AH0 L'],
  "posted": ['P OW1 S T IH0 D'],
  "postel": ['P AA1 S T AH0 L'],
  "postell": ['P AA1 S T AH0 L'],
  "postema": ['P AA0 S T EH1 M AH0'],
  "posten": ['P OW1 S T AH0 N'],
  "poster": ['P OW1 S T ER0'],
  "posteraro": ['P AO2 S T EH0 R AA1 R OW0'],
  "posterior": ['P AO2 S T IH1 R Y ER0'],
  "posteriors": ['P AO2 S T IH1 R Y ER0 Z'],
  "posterity": ['P AA0 S T EH1 R AH0 T IY0'],
  "posterize": ['P OW1 S T ER0 AY2 Z'],
  "posters": ['P OW1 S T ER0 Z'],
  "postgame": ['P OW2 S T G EY1 M'],
  "posthole": ['P OW1 S T HH OW2 L'],
  "postholes": ['P OW1 S T HH OW2 L Z'],
  "posthumous": ['P AA1 S CH UH0 M AH0 S'],
  "posthumously": ['P AA1 S CH UH0 M AH0 S L IY0'],
  "posting": ['P OW1 S T IH0 NG'],
  "postings": ['P OW1 S T IH0 NG Z'],
  "postino": ['P AO0 S T IY1 N OW0'],
  "postipankki": ['P AO2 S T IH0 P AE1 NG K IY0'],
  "postit": ['P OW1 S T IH0 T'],
  "postle": ['P AA1 S AH0 L'],
  "postlethwait": ['P OW1 S T AH0 L TH W EY0 T'],
  "postlewait": ['P AA1 S T AH0 L W EY0 T'],
  "postlewaite": ['P OW1 S T AH0 L W EY0 T'],
  "postma": ['P OW1 S T M AH0'],
  "postman": ['P OW1 S T M AH0 N', 'P OW1 S M AH0 N'],
  "postmark": ['P OW1 S T M AA2 R K'],
  "postmarked": ['P OW1 S T M AA2 R K T', 'P OW1 S M AA2 R K T'],
  "postmaster": ['P OW1 S T M AE2 S T ER0', 'P OW1 S M AE2 S T ER0'],
  "postmasters": ['P OW1 S T M AE2 S T ER0 Z', 'P OW1 S M AE2 S T ER0 Z'],
  "postmodern": ['P OW2 S T M AA1 D ER0 N'],
  "postmodernism": ['P OW2 S T M AA1 D ER0 N IH2 Z M'],
  "postmortem": ['P OW2 S T M AO1 R T EH0 M'],
  "postnatal": ['P OW2 S T N EY1 T AH0 L'],
  "poston": ['P OW1 S T AH0 N'],
  "postoperative": ['P OW2 S T AO1 P AH0 R AH0 T IH0 V'],
  "postpone": ['P OW0 S T P OW1 N', 'P OW0 S P OW1 N'],
  "postponed": ['P OW0 S T P OW1 N D', 'P OW0 S P OW1 N D'],
  "postponement": ['P OW0 S T P OW1 N M AH0 N T', 'P OW0 S P OW1 N M AH0 N T'],
  "postponements": ['P OW0 S T P OW1 N M AH0 N T S', 'P OW0 S P OW1 N M AH0 N T S'],
  "postpones": ['P OW0 S T P OW1 N Z', 'P OW0 S P OW1 N Z'],
  "postponing": ['P OW0 S T P OW1 N IH0 NG', 'P OW0 S P OW1 N IH0 NG'],
  "postrel": ['P AO1 S T R EH0 L'],
  "postrelle": ['P OW2 S T R EH1 L'],
  "postretirement": ['P OW2 S T R IY0 T AY1 ER0 M AH0 N T'],
  "posts": ['P OW1 S T S'],
  "postscript": ['P OW1 S K R IH2 P T', 'P OW1 S T S K R IH2 P T'],
  "postscripts": ['P OW1 S K R IH2 P T S', 'P OW1 S T S K R IH2 P T S', 'P OW1 S K R IH2 P S', 'P OW1 S T S K R IH2 P S'],
  "posttraumatic": ['P OW2 S T T R AO0 M AE1 T IH0 K', 'P OW2 S T R AO0 M AE1 T IH0 K'],
  "postulate": ['P AA1 S CH AH0 L EY2 T', 'P AA1 S CH AH0 L AH0 T'],
  "postulated": ['P AA1 S CH AH0 L EY2 T IH0 D'],
  "postulates": ['P AA1 S CH AH0 L EY2 T S', 'P AA1 S CH AH0 L AH0 T S'],
  "postum": ['P OW1 S T AH0 M'],
  "posture": ['P AA1 S CH ER0'],
  "postures": ['P AA1 S CH ER0 Z'],
  "posturing": ['P AA1 S CH ER0 IH0 NG'],
  "posturings": ['P AA1 S CH ER0 IH0 NG Z'],
  "postwar": ['P OW1 S T W AO1 R'],
  "posuvalyuk": ['P AA2 S UW0 V AA1 L Y UH0 K'],
  "pot": ['P AA1 T'],
  "pot's": ['P AA1 T S'],
  "potable": ['P OW1 T AH0 B AH0 L'],
  "potage": ['P OW1 T AA2 ZH', 'P OW1 T AH0 JH'],
  "potala": ['P OW2 T AA1 L AH0'],
  "potamkin": ['P OW1 T AE2 M K IH0 N'],
  "potapov": ['P AA1 T AH0 P AA2 V'],
  "potash": ['P AA1 T AE2 SH'],
  "potassium": ['P AH0 T AE1 S IY0 AH0 M'],
  "potato": ['P AH0 T EY1 T OW2'],
  "potatoe": ['P AH0 T EY1 T OW2'],
  "potatoes": ['P AH0 T EY1 T OW0 Z'],
  "potbaryoskin": ['P AA2 T B AA0 R Y AO1 S K AH0 N'],
  "potboiler": ['P AA2 B OY1 L ER0'],
  "potboilers": ['P AA2 B OY1 L ER0 Z'],
  "pote": ['P OW1 T'],
  "poteat": ['P OW0 T IY1 T'],
  "poteet": ['P AA1 T IY0 T'],
  "poteete": ['P AA1 T IY0 T'],
  "potemkin": ['P AH0 T EH1 M K IH0 N'],
  "potempa": ['P OW0 T EH1 M P AH0'],
  "poten": ['P OW1 T AH0 N'],
  "potency": ['P OW1 T AH0 N S IY0'],
  "potent": ['P OW1 T AH0 N T'],
  "potentate": ['P OW1 T AH0 N T EY2 T'],
  "potentates": ['P OW1 T AH0 N T EY2 T S'],
  "potential": ['P AH0 T EH1 N SH AH0 L'],
  "potentially": ['P AH0 T EH1 N SH AH0 L IY0'],
  "potentials": ['P AH0 T EH1 N SH AH0 L Z'],
  "potenza": ['P OW0 T EH1 N Z AH0'],
  "poth": ['P AA1 TH'],
  "pothier": ['P OW1 TH IY0 ER0'],
  "pothitos": ['P AH0 TH IY1 T OW0 S'],
  "pothole": ['P AA1 T HH OW2 L'],
  "potholed": ['P AA1 T HH OW2 L D'],
  "potholes": ['P AA1 T HH OW2 L Z'],
  "potier": ['P OW1 T IY0 ER0'],
  "potiker": ['P OW1 T IH0 K ER0'],
  "potion": ['P OW1 SH AH0 N'],
  "potions": ['P OW1 SH AH0 N Z'],
  "potlatch": ['P AA1 T L AE2 CH'],
  "potluck": ['P AA1 T L AH2 K'],
  "potocki": ['P AH0 T OW1 T S K IY0'],
  "potomac": ['P AH0 T OW1 M AH0 K'],
  "potpie": ['P AA1 T P AY2'],
  "potpies": ['P AA1 T P AY2 Z'],
  "potpourri": ['P OW2 P UH0 R IY1'],
  "potpourris": ['P OW2 P UH0 R IY1 Z'],
  "potratz": ['P AA1 T R AH0 T S'],
  "pots": ['P AA1 T S'],
  "potsdam": ['P AA1 T S D AE2 M'],
  "potshot": ['P AA1 SH AA2 T'],
  "potshots": ['P AA1 CH AA2 T S'],
  "pott": ['P AA1 T'],
  "pottage": ['P AA1 T AH0 JH'],
  "pottebaum": ['P AA1 T B AW0 M'],
  "potted": ['P AA1 T IH0 D'],
  "potteiger": ['P AA1 T AY0 G ER0'],
  "pottenger": ['P AA1 T IH0 N JH ER0'],
  "potter": ['P AA1 T ER0'],
  "potter's": ['P AA1 T ER0 Z'],
  "potters": ['P AA1 T ER0 Z'],
  "pottery": ['P AA1 T ER0 IY0'],
  "potthast": ['P AA1 TH AH0 S T'],
  "potthoff": ['P AA1 T HH AO2 F'],
  "potthurst": ['P AA1 T HH ER0 S T'],
  "pottinger": ['P AA1 T IH0 NG ER0'],
  "pottle": ['P AA1 T AH0 L'],
  "pottorff": ['P AA1 T ER0 F'],
  "potts": ['P AA1 T S'],
  "potty": ['P AA1 T IY0'],
  "potucek": ['P AA1 T AH0 CH EH0 K'],
  "potvin": ['P AA1 T V IH0 N'],
  "pou": ['P UW1'],
  "pouch": ['P AW1 CH'],
  "poucher": ['P AW1 CH ER0'],
  "pouches": ['P AW1 CH AH0 Z'],
  "poudrier": ['P AW1 D ER0 IY0 ER0'],
  "pough": ['P AW1'],
  "poughkeepsie": ['P AH0 K IH1 P S IY0'],
  "poughkeepsie's": ['P AH0 K IH1 P S IY0 Z'],
  "poul": ['P UW1 L'],
  "poulenc": ['P UW1 L AH0 NG K'],
  "poulenc's": ['P UW1 L AH0 NG K S'],
  "poulin": ['P UW0 L AE1 N'],
  "pouliot": ['P UW1 L IY0 OW0'],
  "poulos": ['P UW1 L AH0 S'],
  "poulsen": ['P AW1 L S AH0 N'],
  "poulson": ['P AW1 L S AH0 N'],
  "poulter": ['P OW1 L T ER0'],
  "poultice": ['P OW1 L T AH0 S'],
  "poultices": ['P OW1 L T AH0 S IH0 Z'],
  "poulton": ['P AW1 L T AH0 N'],
  "poultry": ['P OW1 L T R IY0'],
  "pounce": ['P AW1 N S'],
  "pounced": ['P AW1 N S T'],
  "pouncey": ['P AW1 N S IY0'],
  "pouncing": ['P AW1 N S IH0 NG'],
  "pouncy": ['P UW0 NG K IY1'],
  "pound": ['P AW1 N D'],
  "pound's": ['P AW1 N D Z', 'P AW1 N Z'],
  "poundage": ['P AW1 N D IH0 JH'],
  "pounded": ['P AW1 N D IH0 D'],
  "pounder": ['P AW1 N D ER0'],
  "pounders": ['P AW1 N D ER0 Z'],
  "pounding": ['P AW1 N D IH0 NG'],
  "pounds": ['P AW1 N D Z', 'P AW1 N Z'],
  "poundstone": ['P AW1 N D S T OW2 N'],
  "poupard": ['P UW1 P ER0 D'],
  "pour": ['P AO1 R'],
  "pourciau": ['P AO1 R S IY0 OW0'],
  "poured": ['P AO1 R D'],
  "pouring": ['P AO1 R IH0 NG'],
  "pours": ['P AO1 R Z'],
  "poussaint": ['P UW2 S AA1 N T'],
  "poussant": ['P UW1 S AH0 N T'],
  "poussin": ['P UW1 S IH0 N'],
  "pousson": ['P UW2 S AA1 N'],
  "poust": ['P AW1 S T'],
  "pout": ['P AW1 T'],
  "pouted": ['P AW1 T IH0 D'],
  "pouter": ['P AW1 T ER0'],
  "pouting": ['P AW1 T IH0 NG'],
  "poutre": ['P AW1 T ER0'],
  "pouts": ['P AW1 T S'],
  "pouty": ['P AW1 T IY0'],
  "poveromo": ['P OW2 V EH0 R OW1 M OW0'],
  "poverty": ['P AA1 V ER0 T IY0'],
  "povich": ['P OW1 V IH0 CH'],
  "povich's": ['P OW1 V IH0 CH IH0 Z'],
  "pow": ['P AW1'],
  "powalsky": ['P OW0 AE1 L S K IY0'],
  "powder": ['P AW1 D ER0'],
  "powdered": ['P AW1 D ER0 D'],
  "powdering": ['P AW1 D ER0 IH0 NG'],
  "powderly": ['P AW1 D ER0 L IY0'],
  "powders": ['P AW1 D ER0 Z'],
  "powdery": ['P AW1 D ER0 IY0'],
  "powe": ['P AW1'],
  "powell": ['P AW1 AH0 L'],
  "powell's": ['P OW1 IH0 L Z'],
  "powelson": ['P AW1 AH0 L S AH0 N'],
  "power": ['P AW1 ER0'],
  "power's": ['P AW1 ER0 Z'],
  "powerball": ['P AW1 ER0 B AO2 L'],
  "powerboat": ['P AW1 ER0 B OW2 T'],
  "powerbook": ['P AW1 ER0 B UH2 K'],
  "powercise": ['P AW1 R S AY2 Z'],
  "powered": ['P AW1 ER0 D'],
  "powerful": ['P AW1 ER0 F AH0 L'],
  "powerfully": ['P AW1 ER0 F L IY0'],
  "powergen": ['P AW1 ER0 JH EH2 N'],
  "powerhouse": ['P AW1 ER0 HH AW2 S'],
  "powerhouses": ['P AW1 ER0 HH AW2 S IH0 Z'],
  "powering": ['P AW1 ER0 IH0 NG'],
  "powerless": ['P AW1 ER0 L AH0 S'],
  "powerlessness": ['P AW1 ER0 L AH0 S N AH0 S'],
  "powerpc": ['P AW1 ER0 P IY1 S IY1'],
  "powerpcs": ['P AW1 ER0 P IY1 S IY1 Z'],
  "powerpcs'": ['P AW1 ER0 P IY1 S IY1 Z'],
  "powerpoint": ['P AW1 ER0 P OY2 N T'],
  "powerpoints": ['P AW1 ER0 P OY2 N T S'],
  "powers": ['P AW1 ER0 Z'],
  "powers'": ['P AW1 ER0 Z'],
  "powersoft": ['P AW1 ER0 S AA2 F T'],
  "powertrain": ['P AW1 R T R EY2 N'],
  "powis": ['P AW1 IH0 S'],
  "powles": ['P AW1 AH0 L Z'],
  "powless": ['P AW1 L IH0 S'],
  "powley": ['P AW1 L IY0'],
  "pownall": ['P AW1 N AH0 L'],
  "pows": ['P OW1 Z'],
  "powter": ['P AW1 T ER0'],
  "powwow": ['P AW1 W AW2'],
  "powwows": ['P AW1 W AW2 Z'],
  "pox": ['P AA1 K S'],
  "poyer": ['P OY1 ER0'],
  "poyner": ['P OY1 N ER0'],
  "poynor": ['P OY1 N ER0'],
  "poynter": ['P OY1 N T ER0'],
  "poynton": ['P OY1 N T AH2 N'],
  "poyser": ['P OY1 S ER0'],
  "poythress": ['P OY1 TH R IH0 S'],
  "poznan": ['P AA1 Z N AH0 N'],
  "poznanski": ['P AH0 Z N AE1 N S K IY0'],
  "pozner": ['P AA1 Z N ER0'],
  "pozniak": ['P AA1 Z N IY0 AE0 K'],
  "poznikov": ['P AA1 Z N IH0 K AA2 V'],
  "pozo": ['P OW1 Z OW0'],
  "pozos": ['P OW1 Z OW0 S'],
  "pozzi": ['P AA1 Z IY0'],
  "ppm": ['P IY1 P IY1 EH1 M'],
  "pr": ['P IY1 AA1 R'],
  "prab": ['P R AE1 B'],
  "pracht": ['P R AE1 K T'],
  "practicable": ['P R AE1 K T AH0 K AH0 B AH0 L'],
  "practical": ['P R AE1 K T AH0 K AH0 L', 'P R AE1 K T IH0 K AH0 L'],
  "practicality": ['P R AE2 K T IH0 K AE1 L AH0 T IY0'],
  "practically": ['P R AE1 K T IH0 K L IY0', 'P R AE1 K T IH0 K AH0 L IY0'],
  "practice": ['P R AE1 K T AH0 S', 'P R AE1 K T IH0 S'],
  "practiced": ['P R AE1 K T AH0 S T', 'P R AE1 K T IH0 S T'],
  "practices": ['P R AE1 K T AH0 S AH0 Z', 'P R AE1 K T IH0 S IH0 Z'],
  "practicing": ['P R AE1 K T AH0 S IH0 NG'],
  "practise": ['P R AE1 K T IH0 S'],
  "practised": ['P R AE1 K T IH0 S T'],
  "practises": ['P R AE1 K T IH0 S IH0 Z'],
  "practitioner": ['P R AE0 K T IH1 SH AH0 N ER0', 'P R AE0 K T IH1 SH N ER0'],
  "practitioners": ['P R AE0 K T IH1 SH AH0 N ER0 Z', 'P R AE0 K T IH1 SH N ER0 Z'],
  "prada": ['P R AA1 D AH0'],
  "pradesh": ['P R AH0 D EH1 SH'],
  "pradetto": ['P R AH0 D EH1 T OW0'],
  "prado": ['P R AA1 D OW0'],
  "praeger": ['P R EY1 G ER0'],
  "praetor": ['P R IY1 T ER0'],
  "praetorian": ['P R IY0 T AO1 R IY0 AH0 N'],
  "prager": ['P R EY1 G ER0'],
  "pragma": ['P R AE1 G M AH0'],
  "pragmatic": ['P R AE0 G M AE1 T IH0 K'],
  "pragmatically": ['P R AE0 G M AE1 T IH0 K AH0 L IY0', 'P R AE0 G M AE1 T IH0 K L IY0'],
  "pragmatism": ['P R AE1 G M AH0 T IH2 Z AH0 M'],
  "pragmatist": ['P R AE1 G M AH0 T IH0 S T'],
  "pragmatists": ['P R AE1 G M AH0 T IH0 S T S'],
  "prague": ['P R AA1 G'],
  "prague's": ['P R AA1 G Z'],
  "prahl": ['P R AA1 L'],
  "prairie": ['P R EH1 R IY0'],
  "prairie's": ['P R EH1 R IY0 Z'],
  "prairies": ['P R EY1 R IY0 Z'],
  "praise": ['P R EY1 Z'],
  "praised": ['P R EY1 Z D'],
  "praises": ['P R EY1 Z AH0 Z', 'P R EY1 Z IH0 Z'],
  "praiseworthy": ['P R EY1 Z W ER2 DH IY0'],
  "praising": ['P R EY1 Z IH0 NG'],
  "prakash": ['P R AA0 K AA1 SH'],
  "prall": ['P R AO1 L'],
  "pralle": ['P R EY1 L'],
  "pran": ['P R AA1 N', 'P R AE1 N'],
  "prance": ['P R AE1 N S'],
  "prancer": ['P R AE1 N S ER0'],
  "prances": ['P R AE1 N S IH0 Z'],
  "prancing": ['P R AE1 N S IH0 NG'],
  "prange": ['P R EY1 N JH'],
  "pranger": ['P R EY1 N JH ER0'],
  "prank": ['P R AE1 NG K'],
  "pranks": ['P R AE1 NG K S'],
  "prankster": ['P R AE1 NG K S T ER0'],
  "pranksters": ['P R AE1 NG K S T ER0 Z'],
  "prapas": ['P R AA1 P AH0 S'],
  "prasad": ['P R AE1 S AH0 D'],
  "prasek": ['P R AA1 S EH0 K'],
  "prashant": ['P R AA2 SH AA1 N T'],
  "pratap": ['P R AA1 T AH0 P'],
  "prater": ['P R EY1 T ER0'],
  "pratfall": ['P R AE1 T F AO2 L'],
  "pratfalls": ['P R AE1 T F AO2 L Z'],
  "prather": ['P R AE1 DH ER0'],
  "prato": ['P R AA1 T OW0'],
  "prats": ['P R AE1 T S'],
  "pratt": ['P R AE1 T'],
  "pratt's": ['P R AE1 T S'],
  "pratte": ['P R AE1 T'],
  "prattle": ['P R AE1 T AH0 L'],
  "prattled": ['P R AE1 T AH0 L D'],
  "prattles": ['P R AE1 T AH0 L Z'],
  "prattling": ['P R AE1 T L IH0 NG'],
  "prattville": ['P R AE1 T V IH0 L'],
  "praun": ['P R AO1 N'],
  "prause": ['P R AO1 Z'],
  "pravda": ['P R AE1 V D AH0'],
  "pravda's": ['P R AE1 V D AH0 Z'],
  "prawn": ['P R AO1 N'],
  "prawns": ['P R AO1 N Z'],
  "praxair": ['P R AE1 K S EH1 R'],
  "praxis": ['P R AE1 K S IH0 S'],
  "pray": ['P R EY1'],
  "prayed": ['P R EY1 D'],
  "prayer": ['P R EH1 R', 'P R EY1 ER0'],
  "prayerful": ['P R EH1 R F AH0 L', 'P R EY1 ER0 F AH0 L'],
  "prayers": ['P R EH1 R Z', 'P R EY1 ER0 Z'],
  "praying": ['P R EY1 IH0 NG'],
  "prays": ['P R EY1 Z'],
  "praytor": ['P R EY1 T ER0'],
  "prazak": ['P R AA1 Z AH0 K'],
  "prchal": ['P ER0 SH AE1 L'],
  "pre": ['P R IY1'],
  "pre-emptive": ['P R IY2 EH1 M P T IH0 V'],
  "pre-season": ['P R IY2 S IY1 Z AH0 N'],
  "preach": ['P R IY1 CH'],
  "preached": ['P R IY1 CH T'],
  "preacher": ['P R IY1 CH ER0'],
  "preacher's": ['P R IY1 CH ER0 Z'],
  "preachers": ['P R IY1 CH ER0 Z'],
  "preaches": ['P R IY1 CH IH0 Z'],
  "preaching": ['P R IY1 CH IH0 NG'],
  "preachy": ['P R IY1 CH IY0'],
  "preadolescence": ['P R IY2 AE0 D AH0 L EH1 S IH0 S'],
  "preadolescent": ['P R IY2 AE0 D AH0 L EH1 S IH0 N T'],
  "preakness": ['P R IY1 K N AH0 S'],
  "preamble": ['P R IY0 AE1 M B AH0 L'],
  "prearrange": ['P R IY2 ER0 EY1 N JH'],
  "prearranged": ['P R IY2 ER0 EY1 N JH D'],
  "prearranges": ['P R IY2 ER0 EY1 N JH AH0 Z'],
  "prearranging": ['P R IY2 ER0 EY1 N JH IH0 NG'],
  "preas": ['P R IY1 Z'],
  "prebble": ['P R EH1 B AH0 L'],
  "prebe": ['P R IY1 B'],
  "prebiotic": ['P R IY2 B AY2 AO1 T IH0 K'],
  "preble": ['P R EH1 B AH0 L'],
  "prebon": ['P R IY1 B AA0 N'],
  "precambrian": ['P R IY0 K AE1 M B R IY0 AH0 N'],
  "precancerous": ['P R IY0 K AE1 N S ER0 AH0 S'],
  "precarious": ['P R IY0 K EH1 R IY0 AH0 S'],
  "precariously": ['P R IH0 K EH1 R IY0 AH0 S L IY0'],
  "precast": ['P R IY0 K AE1 S T'],
  "precaution": ['P R IY0 K AO1 SH AH0 N'],
  "precautionary": ['P R IH0 K AO1 SH AH0 N EH0 R IY0'],
  "precautions": ['P R IY0 K AO1 SH AH0 N Z'],
  "precede": ['P R IH0 S IY1 D'],
  "preceded": ['P R IH0 S IY1 D IH0 D', 'P R IY0 S IY1 D IH0 D'],
  "precedence": ['P R EH1 S AH0 D AH0 N S'],
  "precedent": ['P R EH1 S IH0 D AH0 N T'],
  "precedents": ['P R EH1 S AH0 D AH0 N T S', 'P R EH1 S AH0 D EH2 N T S'],
  "precedes": ['P R IH0 S IY1 D Z'],
  "preceding": ['P R IY0 S IY1 D IH0 NG'],
  "preceed": ['P R IH0 S IY1 D'],
  "preceeding": ['P R IH0 S IY1 D IH0 NG'],
  "preceeds": ['P R IH0 S IY1 D Z'],
  "precept": ['P R IY1 S EH2 P T'],
  "precepts": ['P R IY1 S EH2 P T S'],
  "precession": ['P R IY0 S EH1 SH AH0 N'],
  "precht": ['P R EH1 K T'],
  "prechter": ['P R EH1 K T ER0'],
  "prechtl": ['P R EH1 K T AH0 L'],
  "preciado": ['P R EH0 CH AA1 D OW0'],
  "precinct": ['P R IY1 S IH2 NG K T', 'P R IY1 S IH2 NG K'],
  "precincts": ['P R IY1 S IH2 NG K T S', 'P R IY1 S IH2 NG K S'],
  "precious": ['P R EH1 SH AH0 S'],
  "precip": ['P R EH1 S IH0 P'],
  "precipice": ['P R EH1 S AH0 P AH0 S'],
  "precipitate": ['P R IH0 S IH1 P IH0 T EY2 T'],
  "precipitated": ['P R IH0 S IH1 P IH0 T EY2 T IH0 D'],
  "precipitating": ['P R IH0 S IH1 P AH0 T EY2 T IH0 NG'],
  "precipitation": ['P R IH0 S IH2 P IH0 T EY1 SH AH0 N'],
  "precipitous": ['P R IH0 S IH1 P IH0 T AH0 S'],
  "precipitously": ['P R IY2 S IH1 P IH0 T AH0 S L IY0'],
  "precis": ['P R EY1 S IY2'],
  "precise": ['P R IH0 S AY1 S', 'P R IY0 S AY1 S'],
  "precisely": ['P R IH0 S AY1 S L IY0', 'P R IY0 S AY1 S L IY0'],
  "precision": ['P R IY0 S IH1 ZH AH0 N'],
  "preclinical": ['P R IY0 K L IH1 N IH0 K AH0 L'],
  "preclude": ['P R IH0 K L UW1 D', 'P R IY0 K L UW1 D'],
  "precluded": ['P R IH0 K L UW1 D IH0 D', 'P R IY0 K L UW1 D IH0 D'],
  "precludes": ['P R IH0 K L UW1 D Z', 'P R IY0 K L UW1 D Z'],
  "precluding": ['P R IH0 K L UW1 D IH0 NG'],
  "preclusion": ['P R IH0 K L UW1 ZH AH0 N', 'P R IY0 K L UW1 ZH AH0 N'],
  "precocious": ['P R IH0 K OW1 SH AH0 S', 'P R IY0 K OW1 SH AH0 S'],
  "preconceive": ['P R IY2 K AH0 N S IY1 V'],
  "preconceived": ['P R IY2 K AH0 N S IY1 V D'],
  "preconception": ['P R IY0 K AH0 N S EH1 P SH AH0 N'],
  "preconceptions": ['P R IY0 K AH0 N S EH1 P SH AH0 N Z'],
  "precondition": ['P R IY2 K AH0 N D IH1 SH AH0 N'],
  "preconditions": ['P R IY2 K AH0 N D IH1 SH AH0 N Z'],
  "precook": ['P R IY1 K UH1 K'],
  "precooked": ['P R IY0 K UH1 K T'],
  "precourt": ['P R IH0 K AO1 R T'],
  "precrash": ['P R IY0 K R AE1 SH'],
  "precursor": ['P R IY0 K ER1 S ER0'],
  "precursors": ['P R IY0 K ER1 S ER0 Z'],
  "predaceous": ['P R IY0 D EY1 SH AH0 S'],
  "predate": ['P R IY0 D EY1 T', 'P R IY1 D EY1 T'],
  "predated": ['P R IY0 D EY1 T IH0 D', 'P R IY1 D EY1 T IH0 D'],
  "predates": ['P R IY1 D EY1 T S'],
  "predation": ['P R AH0 D EY1 SH AH0 N'],
  "predator": ['P R EH1 D AH0 T ER0'],
  "predators": ['P R EH1 D AH0 T ER0 Z'],
  "predatory": ['P R EH1 D AH0 T AO2 R IY0'],
  "predawn": ['P R IY0 D AO1 N'],
  "preddy": ['P R EH1 D IY0'],
  "predecessor": ['P R EH1 D AH0 S EH2 S ER0'],
  "predecessor's": ['P R EH1 D AH0 S EH2 S ER0 Z'],
  "predecessors": ['P R EH1 D AH0 S EH2 S ER0 Z'],
  "predecessors'": ['P R EH2 D AH0 S EH1 S ER0 Z'],
  "predefined": ['P R IY2 D IH0 F AY1 N D'],
  "predestination": ['P R IY2 D EH2 S T AH0 N EY1 SH AH0 N'],
  "predestine": ['P R IY2 D EH1 S T AH0 N'],
  "predestined": ['P R IY2 D EH1 S T AH0 N D'],
  "predetermine": ['P R IY2 D IH0 T ER1 M AH0 N', 'P R IY2 D IY0 T ER1 M AH0 N'],
  "predetermined": ['P R IY2 D IY0 T ER1 M IH0 N D'],
  "predicament": ['P R IH0 D IH1 K AH0 M AH0 N T', 'P R IY0 D IH1 K AH0 M AH0 N T'],
  "predicaments": ['P R IH0 D IH1 K AH0 M AH0 N T S'],
  "predicate": ['P R EH1 D AH0 K EY2 T', 'P R EH1 D IH0 K AH0 T'],
  "predicated": ['P R EH1 D AH0 K EY2 T IH0 D'],
  "predicates": ['P R EH1 D AH0 K EY2 T S', 'P R EH1 D IH0 K AH0 T S'],
  "predicating": ['P R EH1 D AH0 K EY2 T IH0 NG', 'P R EH1 D IH0 K AH0 T IH0 NG'],
  "predict": ['P R IH0 D IH1 K T', 'P R IY0 D IH1 K T'],
  "predictability": ['P R IH0 D IH2 K T AH0 B IH1 L IH0 T IY0'],
  "predictable": ['P R IH0 D IH1 K T AH0 B AH0 L', 'P R IY0 D IH1 K T AH0 B AH0 L'],
  "predictably": ['P R IH0 D IH1 K T AH0 B L IY0'],
  "predicted": ['P R IH0 D IH1 K T IH0 D', 'P R IY0 D IH1 K T IH0 D'],
  "predicting": ['P R IH0 D IH1 K T IH0 NG', 'P R IY0 D IH1 K T IH0 NG'],
  "prediction": ['P R IY0 D IH1 K SH AH0 N'],
  "predictions": ['P R IY0 D IH1 K SH AH0 N Z'],
  "predictive": ['P R IH0 D IH1 K T IH0 V', 'P R IY0 D IH1 K T IH0 V'],
  "predictor": ['P R IH0 D IH1 K T ER0'],
  "predictors": ['P R IH0 D IH1 K T ER0 Z', 'P R IY0 D IH1 K T ER0 Z'],
  "predicts": ['P R IH0 D IH1 K T S', 'P R IY0 D IH1 K T S', 'P R IH0 D IH1 K S', 'P R IY0 D IH1 K S'],
  "predilection": ['P R EH2 D AH0 L EH1 K SH AH0 N'],
  "predilections": ['P R EH2 D AH0 L EH1 K SH AH0 N Z'],
  "predispose": ['P R IY2 D IH0 S P OW1 Z'],
  "predisposed": ['P R IY2 D IH0 S P OW1 Z D'],
  "predisposing": ['P R IY2 D IH0 S P OW1 Z IH0 NG'],
  "predisposition": ['P R IY2 D IH0 S P AH0 Z IH1 SH AH0 N'],
  "predispositions": ['P R IY2 D IH0 S P AH0 Z IH1 SH AH0 N Z'],
  "predmore": ['P R EH1 D M AO0 R'],
  "predominance": ['P R IH0 D AA1 M AH0 N AH0 N S'],
  "predominant": ['P R IH0 D AA1 M AH0 N AH0 N T'],
  "predominantly": ['P R IH2 D AA1 M AH0 N AH0 N T L IY2'],
  "predominate": ['P R IH0 D AA1 M AH0 N EY2 T', 'P R IH0 D AA1 M AH0 N AH0 T'],
  "predominated": ['P R IH0 D AA1 M AH0 N EY2 T IH0 D'],
  "predominately": ['P R IH2 D AA1 M AH0 N AH0 N T L IY2'],
  "predominates": ['P R IH0 D AA1 M AH0 N EY2 T S'],
  "predominating": ['P R IH0 D AA1 M AH0 N EY2 T IH0 NG'],
  "pree": ['P R IY1'],
  "preece": ['P R IY1 S'],
  "preelection": ['P R IY0 IH0 L EH1 K SH AH0 N'],
  "preeminence": ['P R IY0 EH1 M AH0 N AH0 N S'],
  "preeminent": ['P R IY0 EH1 M AH0 N AH0 N T'],
  "preempt": ['P R IY1 EH2 M P T'],
  "preempted": ['P R IY0 EH1 M P T IH0 D'],
  "preempting": ['P R IY0 EH1 M P T IH0 NG'],
  "preemption": ['P R IY2 EH1 M P SH AH0 N'],
  "preemptive": ['P R IY0 EH1 M P T IH0 V'],
  "preemptively": ['P R IY0 EH1 M P T IH0 V L IY0'],
  "preempts": ['P R IY1 EH2 M P T S'],
  "preen": ['P R IY1 N'],
  "preened": ['P R IY1 N D'],
  "preening": ['P R IY1 N IH0 NG'],
  "preexist": ['P R IY1 IH0 G Z IH1 S T'],
  "preexisted": ['P R IY1 IH0 G Z IH1 S T IH0 D'],
  "preexisting": ['P R IY1 IH0 G Z IH1 S T IH0 NG'],
  "preexists": ['P R IY1 IH0 G Z IH1 S T S'],
  "prefab": ['P R IY1 F AE1 B'],
  "prefabricate": ['P R IY0 F AE1 B R IH0 K EY2 T'],
  "prefabricated": ['P R IY0 F AE1 B R IH0 K EY2 T IH0 D'],
  "prefabrication": ['P R IY2 F AE2 B R AH0 K EY1 SH AH0 N'],
  "prefabs": ['P R IY1 F AE1 B Z'],
  "preface": ['P R EH1 F AH0 S'],
  "prefaced": ['P R EH1 F AH0 S T'],
  "prefect": ['P R IY1 F EH2 K T'],
  "prefectural": ['P R IY0 F EH1 K CH ER0 AH0 L'],
  "prefecture": ['P R IY1 F EH2 K CH ER0'],
  "prefer": ['P R AH0 F ER1', 'P R IH0 F ER1', 'P R IY0 F ER1'],
  "preferable": ['P R EH1 F ER0 AH0 B AH0 L', 'P R EH1 F R AH0 B AH0 L'],
  "preferably": ['P R EH1 F ER0 AH0 B L IY0', 'P R EH1 F R AH0 B L IY0'],
  "prefered": ['P R IH0 F ER1 D'],
  "preference": ['P R EH1 F ER0 AH0 N S', 'P R EH1 F R AH0 N S'],
  "preferenced": ['P R EH1 F ER0 AH0 N S T', 'P R EH1 F R AH0 N S T'],
  "preferences": ['P R EH1 F ER0 AH0 N S IH0 Z', 'P R EH1 F R AH0 N S IH0 Z'],
  "preferential": ['P R EH2 F ER0 EH1 N SH AH0 L'],
  "preferentially": ['P R EH2 F ER0 EH1 N SH AH0 L IY0'],
  "preferred": ['P R AH0 F ER1 D', 'P R IH0 F ER1 D', 'P R IY0 F ER1 D'],
  "preferreds": ['P R IY0 F ER1 AH0 D Z'],
  "preferring": ['P R IH0 F ER1 IH0 NG'],
  "prefers": ['P R AH0 F ER1 Z', 'P R IH0 F ER1 Z', 'P R IY0 F ER1 Z'],
  "prefix": ['P R IY1 F IH0 K S'],
  "prefrontal": ['P R IY0 F R AH1 N T AH0 L'],
  "pregler": ['P R EH1 G L ER0'],
  "pregnancies": ['P R EH1 G N AH0 N S IY0 Z'],
  "pregnancy": ['P R EH1 G N AH0 N S IY0'],
  "pregnant": ['P R EH1 G N AH0 N T'],
  "prego": ['P R EY1 G OW0'],
  "preheim": ['P R EH1 HH AY0 M'],
  "prehistoric": ['P R IY2 HH IH0 S T AO1 R IH0 K'],
  "prehn": ['P R EH1 N'],
  "preholiday": ['P R IY2 HH AO1 L IH0 D EY0'],
  "preignition": ['P R IY2 AH0 G N IH1 SH AH0 N'],
  "preis": ['P R IY1 Z'],
  "preiser": ['P R AY1 S ER0'],
  "preisig": ['P R AY1 Z IH0 G'],
  "preisler": ['P R AY1 S AH0 L ER0', 'P R AY1 S L ER0'],
  "preiss": ['P R AY1 S'],
  "prejean": ['P R IY2 JH IY1 N'],
  "prejudge": ['P R IY0 JH AH1 JH'],
  "prejudged": ['P R IY0 JH AH1 JH D'],
  "prejudgment": ['P R IY0 JH AH1 JH M AH0 N T'],
  "prejudice": ['P R EH1 JH AH0 D IH0 S'],
  "prejudiced": ['P R EH1 JH AH0 D AH0 S T'],
  "prejudices": ['P R EH1 JH AH0 D IH0 S IH0 Z'],
  "prejudicial": ['P R EH2 JH AH0 D IH1 SH AH0 L'],
  "prejudicing": ['P R EH1 JH AH0 D IH0 S IH0 NG'],
  "prekindergarten": ['P R IY0 K IH1 N D ER0 G AA2 D AH0 N'],
  "prelate": ['P R EH1 L IH0 T', 'P R IY1 L EY2 T'],
  "prelates": ['P R EH1 L IH0 T S'],
  "prelim": ['P R IH0 L IH1 M', 'P R IY0 L IH1 M'],
  "preliminaries": ['P R IH0 L IH1 M AH0 N EH2 R IY0 Z', 'P R IY0 L IH1 M AH0 N EH2 R IY0 Z'],
  "preliminarily": ['P R IH0 L IH2 M AH0 N EH1 R IH0 L IY0'],
  "preliminary": ['P R IH0 L IH1 M AH0 N EH2 R IY0', 'P R IY0 L IH1 M AH0 N EH2 R IY0'],
  "prelims": ['P R IY1 L IH2 M Z'],
  "prell": ['P R EH1 L'],
  "prellwitz": ['P R EH1 L W IH0 T S'],
  "prelude": ['P R EY1 L UW2 D'],
  "preludes": ['P R EY1 L UW2 D Z'],
  "prem": ['P R EH1 M'],
  "premadasa": ['P R IY2 M AH0 D AA1 S AH0'],
  "premarin": ['P R EH1 M ER0 IH0 N'],
  "premarital": ['P R IY0 M EH1 R AH0 T AH0 L'],
  "premark": ['P R IY0 M AA1 R K'],
  "premarket": ['P R IY1 M AA1 R K AH0 T'],
  "premature": ['P R IY2 M AH0 CH UH1 R'],
  "prematurely": ['P R IY2 M AH0 CH UH1 R L IY0'],
  "premeditate": ['P R IY0 M EH1 D AH0 T EY2 T'],
  "premeditated": ['P R IY0 M EH1 D AH0 T EY2 T IH0 D'],
  "premeditation": ['P R IY0 M EH2 D AH0 T EY1 SH AH0 N'],
  "premier": ['P R EH0 M IH1 R', 'P R IY0 M IH1 R'],
  "premier's": ['P R EH0 M IH1 R Z', 'P R IY0 M IH1 R Z'],
  "premiere": ['P R EH0 M IH1 R'],
  "premiered": ['P R EH0 M IH1 R D'],
  "premieres": ['P R EH0 M IH1 R Z'],
  "premiering": ['P R EH0 M IH1 R IH0 NG'],
  "premiers": ['P R EH0 M IH1 R Z'],
  "premiership": ['P R EH0 M IH1 R SH IH2 P'],
  "premise": ['P R EH1 M IH0 S'],
  "premised": ['P R EH1 M AH0 S T'],
  "premises": ['P R EH1 M AH0 S AH0 Z'],
  "premium": ['P R IY1 M IY0 AH0 M'],
  "premiums": ['P R IY1 M IY0 AH0 M Z'],
  "premo": ['P R EH1 M OW0'],
  "premodern": ['P R IY0 M AO1 D ER0 N'],
  "premonition": ['P R EH0 M AH0 N IH1 SH AH0 N'],
  "premonitions": ['P R EH0 M AH0 N IH1 SH AH0 N Z'],
  "premonitory": ['P R AH0 M AH1 N AH0 T ER0 IY0'],
  "prenatal": ['P R IY0 N EY1 T AH0 L'],
  "prendergast": ['P R EH1 N D ER0 G AE2 S T'],
  "prenger": ['P R EH1 N JH ER0'],
  "prenn": ['P R EH1 N'],
  "prensa": ['P R EH1 N S AH0'],
  "prentice": ['P R EH1 N T IH0 S'],
  "prentiss": ['P R EH1 N T IH0 S'],
  "prentnieks": ['P R EH1 N T N IY0 EH2 K S'],
  "prenuptial": ['P R IY0 N AH1 P SH AH0 L'],
  "preoccupation": ['P R IY0 AA2 K Y AH0 P EY1 SH AH0 N'],
  "preoccupations": ['P R IY0 AA2 K Y AH0 P EY1 SH AH0 N Z'],
  "preoccupied": ['P R IY0 AA1 K Y AH0 P AY2 D'],
  "preoccupies": ['P R IY0 AA1 K Y AH0 P AY2 Z'],
  "preoccupy": ['P R IY0 AA1 K Y AH0 P AY2'],
  "preoperative": ['P R IY2 AO1 P AH0 R AH0 T IH0 V'],
  "preordain": ['P R IY2 AO0 R D EY1 N'],
  "preordained": ['P R IY2 AO0 R D EY1 N D'],
  "preordered": ['P R IY2 AO0 R D ER0 D'],
  "preorders": ['P R IY2 AO1 R D ER0 Z'],
  "prep": ['P R EH1 P'],
  "prepackage": ['P R IY0 P AE1 K AH0 JH'],
  "prepackaged": ['P R IY0 P AE1 K AH0 JH D'],
  "prepaid": ['P R IY0 P EY1 D'],
  "preparation": ['P R EH2 P ER0 EY1 SH AH0 N'],
  "preparations": ['P R EH2 P ER0 EY1 SH AH0 N Z'],
  "preparatory": ['P R IH0 P EH1 R AH0 T AO2 R IY0', 'P R EH1 P R AH0 T AO2 R IY0'],
  "prepare": ['P R IY0 P EH1 R'],
  "prepared": ['P R IY0 P EH1 R D'],
  "preparedness": ['P R IY0 P EH1 R AH0 D N AH0 S'],
  "preparer": ['P R IY0 P EH1 R ER0'],
  "preparers": ['P R IY0 P EH1 R ER0 Z'],
  "prepares": ['P R IY0 P EH1 R Z', 'P ER0 P EH1 R Z'],
  "preparing": ['P R IY0 P EH1 R IH0 NG', 'P ER0 P EH1 R IH0 NG'],
  "prepay": ['P R IY0 P EY1'],
  "prepaying": ['P R IY0 P EY1 IH0 NG'],
  "prepayment": ['P R IY0 P EY1 M AH0 N T'],
  "prepayments": ['P R IY0 P EY1 M AH0 N T S'],
  "preplanning": ['P R IY0 P L AE1 N IH0 NG'],
  "preponderance": ['P R IY0 P AA1 N D R AH0 N S'],
  "preposterous": ['P R IH0 P AA1 S T ER0 AH0 S', 'P R IH0 P AA1 S T R AH0 S'],
  "prepped": ['P R EH1 P T'],
  "preppie": ['P R EH1 P IY0'],
  "prepping": ['P R EH1 P IH0 NG'],
  "preppy": ['P R EH1 P IY0'],
  "prepuce": ['P R EH1 P Y UW2 S'],
  "prequel": ['P R IY1 K W EH0 L'],
  "prerecord": ['P R IY2 R IY0 K AO1 R D', 'P R IY2 R IH0 K AO1 R D'],
  "prerecorded": ['P R IY2 R IY0 K AO1 R D IH0 D'],
  "prerequisite": ['P R IY0 R EH1 K W AH0 Z AH0 T'],
  "prerequisites": ['P R IY0 R EH1 K W AH0 Z AH0 T S'],
  "prerogative": ['P R IH0 R AA1 G AH0 T IH0 V', 'P ER1 AA1 G AH0 T IH0 V'],
  "prerogatives": ['P R IH0 R AA1 G AH0 T IH0 V Z', 'P ER1 AA1 G AH0 T IH0 V Z'],
  "pres": ['P R EH1 Z'],
  "presage": ['P R EH1 S IH0 JH'],
  "presaged": ['P R EH1 S IH0 JH D'],
  "presages": ['P R EH1 S IH0 JH IH0 Z'],
  "presaging": ['P R EH1 S IH0 JH IH0 NG'],
  "presale": ['P R IY1 S EY2 L'],
  "presario": ['P R IH0 S AA1 R IY0 OW2'],
  "presby": ['P R EH1 S B IY0', 'P R EH1 Z B IY0'],
  "presbyterian": ['P R EH2 S B IH0 T IH1 R IY0 AH0 N', 'P R EH2 Z B IH0 T IH1 R IY0 AH0 N'],
  "presbyterians": ['P R EH2 S B IH0 T IH1 R IY0 AH0 N Z', 'P R EH2 Z B IH0 T IH1 R IY0 AH0 N Z'],
  "preschel": ['P R EH1 SH AH0 L'],
  "prescher": ['P R EH1 SH ER0'],
  "preschool": ['P R IY0 S K UW1 L', 'P R IY1 S K UW2 L'],
  "preschooler": ['P R IY1 S K UW2 L ER0'],
  "preschoolers": ['P R IY1 S K UW2 L ER0 Z'],
  "preschools": ['P R IY0 S K UW1 L Z', 'P R IY1 S K UW2 L Z'],
  "prescience": ['P R IY1 SH IY0 AH0 N S'],
  "prescient": ['P R EH1 S IY0 AH0 N T'],
  "prescot": ['P R EH1 S K AH0 T'],
  "prescott": ['P R EH1 S K AA0 T'],
  "prescribe": ['P R AH0 S K R AY1 B', 'P R IY0 S K R AY1 B'],
  "prescribed": ['P R IY0 S K R AY1 B D'],
  "prescriber": ['P R IY0 S K R AY1 B ER0'],
  "prescribers": ['P R IY0 S K R AY1 B ER0 Z'],
  "prescribes": ['P R IY0 S K R AY1 B Z'],
  "prescribing": ['P R IY0 S K R AY1 B IH0 NG'],
  "prescription": ['P R AH0 S K R IH1 P SH AH0 N'],
  "prescriptions": ['P R AH0 S K R IH1 P SH AH0 N Z'],
  "preseason": ['P R IY2 S IY1 Z AH0 N'],
  "presence": ['P R EH1 Z AH0 N S'],
  "presences": ['P R EH1 Z AH0 N S IH0 Z'],
  "present": ['P R EH1 Z AH0 N T', 'P R IY0 Z EH1 N T', 'P ER0 Z EH1 N T'],
  "presentable": ['P R AH0 Z EH1 N T AH0 B AH0 L'],
  "presentation": ['P R EH2 Z AH0 N T EY1 SH AH0 N'],
  "presentations": ['P R EH2 Z AH0 N T EY1 SH AH0 N Z'],
  "presented": ['P R IY0 Z EH1 N T IH0 D', 'P ER0 Z EH1 N T AH0 D', 'P R IY0 Z EH1 N AH0 D', 'P ER0 Z EH1 N AH0 D'],
  "presenter": ['P R EH1 Z AH0 N T ER0', 'P R IY0 Z EH1 N T ER0'],
  "presenters": ['P R EH1 Z AH0 N T ER0 Z', 'P R IY0 Z EH1 N T ER0 Z'],
  "presenting": ['P R IH0 Z EH1 N T IH0 NG', 'P R IY0 Z EH1 N T IH0 NG'],
  "presently": ['P R EH1 Z AH0 N T L IY0'],
  "presentment": ['P R IY0 Z EH1 N T M AH0 N T'],
  "presents": ['P R EH1 Z AH0 N T S', 'P R IY0 Z EH1 N T S'],
  "preservation": ['P R EH2 Z ER0 V EY1 SH AH0 N'],
  "preservationist": ['P R EH2 Z ER0 V EY1 SH AH0 N IH0 S T'],
  "preservationists": ['P R EH2 Z ER0 V EY1 SH AH0 N IH0 S T S'],
  "preservative": ['P R IY0 Z ER1 V AH0 T IH0 V'],
  "preservatives": ['P R AH0 Z ER1 V AH0 T IH0 V Z'],
  "preserve": ['P R AH0 Z ER1 V', 'P R IH0 Z ER1 V', 'P R IY0 Z ER1 V'],
  "preserved": ['P R AH0 Z ER1 V D', 'P R IH0 Z ER1 V D', 'P R IY0 Z ER1 V D'],
  "preserver": ['P R IY0 Z ER1 V ER0', 'P R IH0 Z ER1 V ER0', 'P R AH0 Z ER1 V ER0'],
  "preservers": ['P R IY0 Z ER1 V ER0 Z', 'P R AH0 Z ER1 V ER0 Z'],
  "preserves": ['P R AH0 Z ER1 V Z', 'P R IH0 Z ER1 V Z', 'P R IY0 Z ER1 V Z'],
  "preserving": ['P R AH0 Z ER1 V IH0 NG', 'P R IH0 Z ER1 V IH0 NG', 'P R IY0 Z ER1 V IH0 NG'],
  "preset": ['P R IY0 S EH1 T'],
  "preside": ['P R IH0 Z AY1 D', 'P R IY0 Z AY1 D'],
  "presided": ['P R IH0 Z AY1 D IH0 D', 'P R IY0 Z AY1 D IH0 D'],
  "presidencies": ['P R EH1 Z AH0 D AH0 N S IY0 Z'],
  "presidency": ['P R EH1 Z AH0 D AH0 N S IY0'],
  "presidency's": ['P R EH1 Z AH0 D AH0 N S IY0 Z'],
  "president": ['P R EH1 Z AH0 D EH2 N T', 'P R EH1 Z IH0 D AH0 N T'],
  "president's": ['P R EH1 Z IH0 D AH0 N T S'],
  "presidential": ['P R EH2 Z AH0 D EH1 N SH AH0 L'],
  "presidential's": ['P R EH2 Z AH0 D EH1 N SH AH0 L Z'],
  "presidentialist": ['P R EH2 Z AH0 D EH1 N SH AH0 L IH0 S T'],
  "presidentially": ['P R EH2 S IH0 D EH1 N SH AH0 L IY0'],
  "presidents": ['P R EH1 Z AH0 D EH2 N T S', 'P R EH1 Z IH0 D AH0 N T S', 'P R EH1 Z IH0 D AH0 N S'],
  "presidents'": ['P R EH1 Z IH0 D AH0 N T S'],
  "presides": ['P R IH0 Z AY1 D Z', 'P R IY0 Z AY1 D Z'],
  "presiding": ['P R IH0 Z AY1 D IH0 NG', 'P R IY0 Z AY1 D IH0 NG'],
  "presidio": ['P R IH0 S IH1 D IY0 OW2'],
  "presidium": ['P R IH0 S IH1 D IY0 AH0 M'],
  "preslar": ['P R IH0 S L AA1 R'],
  "presler": ['P R EH1 S AH0 L ER0', 'P R EH1 S L ER0'],
  "presley": ['P R EH1 S L IY0'],
  "presley's": ['P R EH1 S L IY0 Z'],
  "presnall": ['P R EH1 S N AH0 L'],
  "presnell": ['P R EH1 S N AH0 L'],
  "presplit": ['P R IY1 S P L IH1 T'],
  "presque-isle": ['P R EH1 S K AY0 L'],
  "press": ['P R EH1 S'],
  "press'": ['P R EH1 S'],
  "press's": ['P R EH1 S IH0 Z'],
  "pressboard": ['P R EH1 S B AO2 R D'],
  "pressburger": ['P R EH1 S B ER0 G ER0'],
  "presse": ['P R EH1 S'],
  "pressed": ['P R EH1 S T'],
  "pressel": ['P R EH1 S AH0 L'],
  "presser": ['P R EH1 S ER0'],
  "presser's": ['P R EH1 S ER0 Z'],
  "presses": ['P R EH1 S AH0 Z', 'P R EH1 S IH0 Z'],
  "pressey": ['P R EH1 S IY0'],
  "pressing": ['P R EH1 S IH0 NG'],
  "pressler": ['P R EH1 S L ER0'],
  "pressley": ['P R EH1 S L IY0'],
  "pressly": ['P R EH1 S L IY0'],
  "pressman": ['P R EH1 S M AH0 N'],
  "pressnell": ['P R EH1 S N AH0 L'],
  "presson": ['P R EH1 S AH0 N'],
  "presstek": ['P R EH1 S T EH2 K'],
  "pressure": ['P R EH1 SH ER0'],
  "pressure's": ['P R EH1 SH ER0 Z'],
  "pressured": ['P R EH1 SH ER0 D'],
  "pressures": ['P R EH1 SH ER0 Z'],
  "pressuring": ['P R EH1 SH ER0 IH0 NG'],
  "pressurization": ['P R EH2 SH ER0 IH0 Z EY1 SH AH0 N'],
  "pressurize": ['P R EH1 SH ER0 AY2 Z'],
  "pressurized": ['P R EH1 SH ER0 AY2 Z D'],
  "pressurizes": ['P R EH1 SH ER0 AY2 Z IH0 Z'],
  "pressurizing": ['P R EH1 SH ER0 AY2 Z IH0 NG'],
  "presswood": ['P R EH1 S W UH2 D'],
  "presswoods": ['P R EH1 S W UH2 D Z'],
  "prest": ['P R EH1 S T'],
  "presta": ['P R EH1 S T AH0'],
  "prestage": ['P R EH1 S T IH0 JH'],
  "prestech": ['P R EH0 S T EH1 K'],
  "presti": ['P R EH1 S T IY0'],
  "prestia": ['P R EH1 S T Y AH0'],
  "prestidge": ['P R EH1 S T IH0 JH'],
  "prestidigitation": ['P R EH2 S T IH0 D IH0 JH AH0 T EY1 SH AH0 N'],
  "prestidigitator": ['P R EH2 S T IH0 D IH1 JH AH0 T EY0 T ER0'],
  "prestidigitators": ['P R EH2 S T IH0 D IH1 JH AH0 T EY0 T ER0 Z'],
  "prestige": ['P R EH0 S T IY1 ZH'],
  "prestigiacomo": ['P R EH0 S T IY1 JH AH0 K OW0 M OW0'],
  "prestigious": ['P R EH0 S T IH1 JH AH0 S', 'P ER0 S T IY1 JH AH0 S'],
  "presto": ['P R EH1 S T OW2'],
  "preston": ['P R EH1 S T AH0 N'],
  "preston's": ['P R EH1 S T AH0 N Z'],
  "prestowitz": ['P R EH1 S T AH0 W IH0 T S'],
  "prestridge": ['P R EH1 S T R IH0 JH'],
  "prestwich": ['P R EH1 S T W IH0 CH'],
  "prestwood": ['P R EH1 S T W UH2 D'],
  "presumably": ['P R AH0 Z UW1 M AH0 B L IY0', 'P R IH0 Z UW1 M AH0 B L IY0', 'P R IY0 Z UW1 M AH0 B L IY0'],
  "presume": ['P R IH0 Z UW1 M'],
  "presumed": ['P R AH0 Z UW1 M D', 'P R IH0 Z UW1 M D', 'P R IY0 Z UW1 M D'],
  "presumes": ['P R AH0 Z UW1 M Z', 'P R IY0 Z UW1 M Z'],
  "presuming": ['P R IH0 Z UW1 M IH0 NG', 'P R IY0 Z UW1 M IH0 NG'],
  "presumption": ['P R IH0 Z AH1 M P SH AH0 N', 'P R IY0 Z AH1 M P SH AH0 N'],
  "presumptions": ['P R IH0 Z AH1 M P SH AH0 N Z', 'P R IY0 Z AH1 M P SH AH0 N Z'],
  "presumptive": ['P R IY0 Z AH1 M P T IH0 V'],
  "presumptuous": ['P R IH0 Z AH1 M P CH AH0 W AH0 S'],
  "presuppose": ['P R IY2 S AH0 P OW1 Z'],
  "presupposes": ['P R IY2 S AH0 P OW1 Z IH0 Z'],
  "presupposition": ['P R IY2 S AH0 P AH0 Z IH1 SH AH0 N'],
  "presutti": ['P R EH0 S UW1 T IY0'],
  "preszler": ['P R EH1 S L ER0'],
  "pret": ['P R EH1 T'],
  "pretax": ['P R IY1 T AE1 K S'],
  "prete": ['P R IY1 T'],
  "preteen": ['P R IY2 T IY1 N'],
  "preteens": ['P R IY2 T IY1 N Z'],
  "pretend": ['P R IY0 T EH1 N D'],
  "pretended": ['P R IY0 T EH1 N D IH0 D'],
  "pretender": ['P R IY0 T EH1 N D ER0'],
  "pretenders": ['P R IY0 T EH1 N D ER0 Z'],
  "pretending": ['P R IY0 T EH1 N D IH0 NG'],
  "pretends": ['P R IY0 T EH1 N D Z'],
  "pretense": ['P R IY0 T EH1 N S'],
  "pretenses": ['P R IY0 T EH1 N S IH0 Z'],
  "pretension": ['P R IY0 T EH1 N SH AH0 N'],
  "pretensions": ['P R AH0 T EH1 N SH AH0 N Z'],
  "pretentious": ['P R IY0 T EH1 N SH AH0 S'],
  "preterm": ['P R IY1 T ER0 M'],
  "pretext": ['P R IY1 T EH2 K S T'],
  "pretoria": ['P R IY0 T AO1 R IY0 AH0'],
  "pretoria's": ['P R IY0 T AO1 R IY0 AH0 Z'],
  "pretrial": ['P R IY0 T R AY1 AH0 L'],
  "pretti": ['P R EH1 T IY0'],
  "prettier": ['P R IH1 T IY0 ER0'],
  "pretties": ['P R IH1 T IY0 Z'],
  "prettiest": ['P R IH1 T IY0 AH0 S T'],
  "pretty": ['P R IH1 T IY0'],
  "prettyman": ['P R EH1 T IY0 M AH0 N'],
  "pretzel": ['P R EH1 T Z AH0 L'],
  "pretzels": ['P R EH1 T Z AH0 L Z'],
  "pretzer": ['P R EH1 T Z ER0'],
  "preuss": ['P R UW1 S'],
  "preussag": ['P R UW1 S AE0 G'],
  "preusser": ['P R OY1 S ER0'],
  "prevail": ['P R IH0 V EY1 L', 'P R IY0 V EY1 L'],
  "prevailed": ['P R IH0 V EY1 L D', 'P R IY0 V EY1 L D'],
  "prevailing": ['P R IH0 V EY1 L IH0 NG', 'P R IY0 V EY1 L IH0 NG'],
  "prevails": ['P R IH0 V EY1 L Z', 'P R IY0 V EY1 L Z'],
  "preval": ['P R IY1 V AE0 L'],
  "prevalence": ['P R EH1 V AH0 L AH0 N S'],
  "prevalent": ['P R EH1 V AH0 L AH0 N T'],
  "prevaricate": ['P R AH0 V EH1 R AH0 K EY0 T'],
  "prevaricated": ['P R AH0 V EH1 R AH0 K EY0 T IH0 D'],
  "prevaricates": ['P R AH0 V EH1 R AH0 K EY0 T S'],
  "prevaricating": ['P R AH0 V EH1 R AH0 K EY0 T IH0 NG'],
  "prevarication": ['P R AH0 V EH2 R AH0 K EY1 SH AH0 N'],
  "prevatt": ['P R IH0 V AE1 T'],
  "prevatte": ['P R IH0 V AE1 T'],
  "prevent": ['P R IH0 V EH1 N T', 'P R IY0 V EH1 N T'],
  "preventable": ['P R IH0 V EH1 N T AH0 B AH0 L', 'P R IY0 V EH1 N T AH0 B AH0 L'],
  "preventative": ['P R IY0 V EH1 N T AH0 T IH0 V'],
  "preventatives": ['P R IY0 V EH1 N T AH0 T IH0 V Z'],
  "prevented": ['P R IH0 V EH1 N T IH0 D', 'P R IY0 V EH1 N T IH0 D', 'P R IH0 V EH1 N IH0 D', 'P R IY0 V EH1 N IH0 D'],
  "preventing": ['P R IH0 V EH1 N T IH0 NG', 'P R IY0 V EH1 N T IH0 NG', 'P R IH0 V EH1 N IH0 NG', 'P R IY0 V EH1 N IH0 NG'],
  "prevention": ['P R IY0 V EH1 N SH AH0 N'],
  "preventive": ['P R IH0 V EH1 N T IH0 V', 'P R IY0 V EH1 N T IH0 V', 'P R IH0 V EH1 N IH0 V', 'P R IY0 V EH1 N IH0 V'],
  "prevents": ['P R IH0 V EH1 N T S', 'P R IY0 V EH1 N T S'],
  "prevette": ['P R IH0 V EH1 T'],
  "preview": ['P R IY1 V Y UW2'],
  "previewed": ['P R IY1 V Y UW2 D'],
  "previewing": ['P R IY1 V Y UW2 IH0 NG'],
  "previews": ['P R IY1 V Y UW2 Z'],
  "previn": ['P R EH1 V AH0 N'],
  "previous": ['P R IY1 V IY0 AH0 S'],
  "previously": ['P R IY1 V IY0 AH0 S L IY0'],
  "previte": ['P R EH1 V AY0 T'],
  "previti": ['P R EH0 V IY1 T IY0'],
  "prevo": ['P R EH1 V OW0'],
  "prevost": ['P R EY1 V OW0 S T'],
  "prew": ['P R UW1'],
  "prewar": ['P R IY0 W AO1 R'],
  "preway": ['P R IY1 W EY2'],
  "prewett": ['P R UW1 IH0 T'],
  "prewitt": ['P R UW1 IH0 T'],
  "prey": ['P R EY1'],
  "preyed": ['P R EY1 D'],
  "preyer": ['P R EY1 ER0'],
  "preying": ['P R EY1 IH0 NG'],
  "preyista": ['P R EY1 IH2 S T AH0'],
  "preyistas": ['P R EY1 IH2 S T AH0 Z'],
  "preys": ['P R EY1 Z'],
  "preziosi": ['P R EH0 Z IY0 OW1 S IY0'],
  "prezioso": ['P R EH0 Z IY0 OW1 S OW0'],
  "prezzano": ['P R EH0 Z AA1 N OW0'],
  "pri": ['P R AY1', 'P R IY1'],
  "priam": ['P R AY1 AE2 M', 'P R AY1 AH0 M'],
  "priam's": ['P R AY1 AE2 M Z', 'P R AY1 AH0 M Z'],
  "pribble": ['P R IH1 B AH0 L'],
  "pribula": ['P R IY0 B UW1 L AH0'],
  "pribyl": ['P R IH1 B AH0 L'],
  "price": ['P R AY1 S'],
  "price's": ['P R AY1 S IH0 Z'],
  "priced": ['P R AY1 S T'],
  "priceless": ['P R AY1 S L AH0 S'],
  "pricer": ['P R AY1 S ER0'],
  "prices": ['P R AY1 S AH0 Z', 'P R AY1 S IH0 Z'],
  "pricey": ['P R AY1 S IY0'],
  "prichard": ['P R IH1 CH ER0 D'],
  "prichett": ['P R IH1 CH IH0 T'],
  "pricier": ['P R AY1 S IY0 ER0'],
  "priciest": ['P R AY2 S IY0 IH0 S T'],
  "pricing": ['P R AY1 S IH0 NG'],
  "pricings": ['P R AY1 S IH0 NG Z'],
  "prick": ['P R IH1 K'],
  "pricked": ['P R IH1 K T'],
  "prickett": ['P R IH1 K IH0 T'],
  "prickleback": ['P R IH1 K AH0 L B AE2 K'],
  "pricklebacks": ['P R IH1 K AH0 L B AE2 K S'],
  "prickly": ['P R IH1 K L IY0'],
  "pricks": ['P R IH1 K S'],
  "pricor": ['P R AY1 K AO2 R'],
  "priddy": ['P R IH1 D IY0'],
  "pride": ['P R AY1 D'],
  "prideaux": ['P R IH1 D OW0'],
  "prided": ['P R AY1 D IH0 D'],
  "pridemore": ['P R AY1 D M AO0 R'],
  "prides": ['P R AY1 D Z'],
  "pridgen": ['P R IH1 JH AH0 N'],
  "pridgeon": ['P R IH1 D JH IH0 N'],
  "pridmore": ['P R IH1 D M AO0 R'],
  "priebe": ['P R IY1 B'],
  "priebke": ['P R IY1 B K IY0'],
  "pried": ['P R AY1 D'],
  "priem": ['P R IY1 M'],
  "prien": ['P R IY1 N'],
  "prier": ['P R AY1 ER0'],
  "pries": ['P R AY1 Z'],
  "priess": ['P R IY1 S'],
  "priest": ['P R IY1 S T'],
  "priest's": ['P R IY1 S T S'],
  "priester": ['P R IY1 S T ER0'],
  "priestess": ['P R IY1 S T AH0 S'],
  "priesthood": ['P R IY1 S T HH UH0 D'],
  "priestley": ['P R IY1 S T L IY0'],
  "priestly": ['P R IY1 S T L IY0'],
  "priests": ['P R IY1 S T S'],
  "prieta": ['P R IY1 T AH0', 'P R IY2 EY1 T AH0'],
  "prieto": ['P R IY1 T OW0'],
  "prieur": ['P R IY1 ER0'],
  "prig": ['P R IH1 G'],
  "prigge": ['P R IH1 G'],
  "prigmore": ['P R IH1 G M AO0 R'],
  "prihoda": ['P R IY0 HH OW1 D AH0'],
  "prijedor": ['P R IH0 JH EH1 D ER0'],
  "prill": ['P R IH1 L'],
  "prillaman": ['P R IH1 L AH0 M AH0 N'],
  "prim": ['P R IH1 M'],
  "prima": ['P R IY1 M AH0'],
  "primack": ['P R IH1 M AH0 K'],
  "primacy": ['P R AY1 M AH0 S IY0'],
  "primakov": ['P R IY1 M AH0 K AA0 V', 'P R IY1 M AH0 K AA0 F'],
  "primal": ['P R AY1 M AH0 L'],
  "primaries": ['P R AY1 M EH2 R IY0 Z', 'P R AY1 M ER0 IY0 Z'],
  "primarily": ['P R AY0 M EH1 R AH0 L IY0'],
  "primark": ['P R IH1 M AA0 R K'],
  "primary": ['P R AY1 M EH2 R IY0'],
  "primate": ['P R AY1 M EY2 T'],
  "primates": ['P R AY1 M EY2 T S'],
  "primavera": ['P R IY0 M AA0 V EH1 R AH0'],
  "prime": ['P R AY1 M'],
  "prime's": ['P R AY1 M Z'],
  "primeau": ['P R IH0 M OW1'],
  "primeaux": ['P R IH0 M OW1'],
  "primebank": ['P R AY1 M B AE2 NG K'],
  "primeco": ['P R AY1 M K OW2'],
  "primed": ['P R AY1 M D'],
  "primenews": ['P R AY1 M N UW1 Z'],
  "primer": ['P R AY1 M ER0'],
  "primerica": ['P R IH0 M EH1 R IH0 K AH0'],
  "primerica's": ['P R IH0 M EH1 R IH0 K AH0 Z'],
  "primers": ['P R AY1 M ER0 Z'],
  "primes": ['P R AY1 M Z'],
  "primestar": ['P R AY1 M S T AA2 R'],
  "primetime": ['P R AY1 M T AY2 M'],
  "primeval": ['P R AY0 M IY1 V AH0 L'],
  "primiano": ['P R IY2 M IY0 AA1 N OW0'],
  "priming": ['P R AY1 M IH0 NG'],
  "primitive": ['P R IH1 M AH0 T IH0 V', 'P R IH1 M IH0 T IH0 V'],
  "primitivistic": ['P R IH2 M IH0 T IH0 V IH1 S T IH0 K'],
  "primly": ['P R IH1 M L IY0'],
  "primm": ['P R IH1 M'],
  "primmer": ['P R IH1 M ER0'],
  "primo": ['P R IY1 M OW2'],
  "primo's": ['P R IY1 M OW2 Z'],
  "primordial": ['P R IH0 M AO1 R D IY0 AH0 L', 'P R AY0 M AO1 R D IY0 AH0 L'],
  "primp": ['P R IH1 M P'],
  "primrose": ['P R IH1 M R OW2 Z'],
  "primroses": ['P R IH1 M R OW2 Z AH0 Z', 'P R IH1 M R OW2 Z IH0 Z'],
  "primus": ['P R AY1 M AH0 S'],
  "prince": ['P R IH1 N S'],
  "prince's": ['P R IH1 N S IH0 Z'],
  "princely": ['P R IH1 N S L IY0'],
  "princes": ['P R IH1 N S AH0 Z', 'P R IH1 N S IH0 Z'],
  "princess": ['P R IH1 N S EH0 S'],
  "princess'": ['P R IH1 N S EH0 S'],
  "princess's": ['P R IH1 N S EH0 S IH0 Z'],
  "princesses": ['P R IH1 N S EH0 S IH0 Z'],
  "princeton": ['P R IH1 N S T AH0 N'],
  "princeton's": ['P R IH1 N S T AH0 N Z'],
  "princeville": ['P R IH1 N S V IH2 L'],
  "principal": ['P R IH1 N S AH0 P AH0 L'],
  "principal's": ['P R IH1 N S AH0 P AH0 L Z'],
  "principalities": ['P R IH2 N S IH0 P AE1 L IH0 T IY0 Z'],
  "principality": ['P R IH2 N S IH0 P AE1 L IH0 T IY0'],
  "principally": ['P R IH1 N S IH0 P L IY0'],
  "principals": ['P R IH1 N S AH0 P AH0 L Z'],
  "principato": ['P R IY0 N CH IY0 P AA1 T OW0'],
  "principe": ['P R IY0 N CH IY1 P IY0'],
  "principia": ['P R IH0 N S IH1 P IY0 AH0'],
  "principle": ['P R IH1 N S AH0 P AH0 L'],
  "principled": ['P R IH1 N S AH0 P AH0 L D'],
  "principles": ['P R IH1 N S AH0 P AH0 L Z'],
  "prindiville": ['P R IY0 N D IY1 V IH0 L'],
  "prindl": ['P R IH1 N D AH0 L'],
  "prindle": ['P R IH1 N D AH0 L'],
  "prine": ['P R AY1 N'],
  "pring": ['P R IH1 NG'],
  "pringle": ['P R IH1 NG G AH0 L'],
  "pringle's": ['P R IH1 NG G AH0 L Z'],
  "prinkey": ['P R IH1 NG K IY0'],
  "prinos": ['P R IY1 N OW0 S'],
  "prins": ['P R IH1 N Z'],
  "prinsen": ['P R IH1 N S AH0 N'],
  "print": ['P R IH1 N T'],
  "printable": ['P R IH1 N T AH0 B AH0 L'],
  "printed": ['P R IH1 N T IH0 D', 'P R IH1 N AH0 D', 'P R IH1 N IH0 D'],
  "printemps": ['P R IH1 N T EH0 M P S'],
  "printer": ['P R IH1 N T ER0', 'P R IH1 N ER0'],
  "printer's": ['P R IH1 N T ER0 Z', 'P R IH1 N ER0 Z'],
  "printers": ['P R IH1 N T ER0 Z', 'P R IH1 N ER0 Z'],
  "printing": ['P R IH1 N T IH0 NG', 'P R IH1 N IH0 NG'],
  "printing's": ['P R IH1 N T IH0 NG Z', 'P R IH1 N IH0 NG Z'],
  "printings": ['P R IH1 N T IH0 NG Z', 'P R IH1 N IH0 NG Z'],
  "printmaker": ['P R IH1 N T M EY2 K ER0'],
  "printmakers": ['P R IH1 N T M EY2 K ER0 Z'],
  "printmaking": ['P R IH1 N T M EY2 K IH0 NG'],
  "printon": ['P R IH1 N T AH0 N'],
  "printout": ['P R IH1 N T AW2 T'],
  "printouts": ['P R IH1 N T AW2 T S'],
  "prints": ['P R IH1 N T S'],
  "printup": ['P R IH1 N T AH2 P'],
  "printy": ['P R IH1 N T IY0'],
  "printz": ['P R IH1 N T S'],
  "prinz": ['P R IH1 N Z'],
  "prinz's": ['P R IH1 N Z AH0 Z'],
  "priola": ['P R IY0 OW1 L AH0'],
  "prioleau": ['P R IY0 OW1 L OW0'],
  "priolo": ['P R IY0 OW1 L OW0'],
  "prior": ['P R AY1 ER0'],
  "priore": ['P R IY0 AO1 R IY0', 'P R AY0 AO1 R AY0'],
  "priori": ['P R AY0 AO1 R AY2', 'P R IY0 AO1 R IY2'],
  "priorities": ['P R AY0 AO1 R AH0 T IY0 Z'],
  "prioritize": ['P R AY0 AO1 R AH0 T AY2 Z'],
  "prioritized": ['P R AY0 AO1 R AH0 T AY2 Z D'],
  "prioritizes": ['P R AY0 AO1 R AH0 T AY2 Z IH0 Z'],
  "prioritizing": ['P R AY0 AO1 R AH0 T AY2 Z IH0 NG'],
  "priority": ['P R AY0 AO1 R AH0 T IY0'],
  "priors": ['P R AY1 ER0 Z'],
  "priory": ['P R AY1 ER0 IY0'],
  "priroda": ['P R AH0 R OW1 D AH0'],
  "pris": ['P R IH1 S'],
  "prisburg": ['P R IH1 S B ER0 G'],
  "priscilla": ['P R IH0 S IH1 L AH0'],
  "priscilla's": ['P R IH0 S IH1 L AH0 Z'],
  "prisco": ['P R IY1 S K OW0'],
  "prisk": ['P R IH1 S K'],
  "prism": ['P R IH1 Z AH0 M'],
  "prismatic": ['P R IH0 Z M AE1 T IH0 K'],
  "prisms": ['P R IH1 Z AH0 M Z'],
  "prison": ['P R IH1 Z AH0 N'],
  "prison's": ['P R IH1 Z AH0 N Z'],
  "prisoned": ['P R IH1 Z AH0 N D'],
  "prisoner": ['P R IH1 Z AH0 N ER0', 'P R IH1 Z N ER0'],
  "prisoner's": ['P R IH1 Z AH0 N ER0 Z', 'P R IH1 Z N ER0 Z'],
  "prisoners": ['P R IH1 Z AH0 N ER0 Z', 'P R IH1 Z N ER0 Z'],
  "prisoners'": ['P R IH1 Z AH0 N ER0 Z', 'P R IH1 Z N ER0 Z'],
  "prisons": ['P R IH1 Z AH0 N Z'],
  "prissie": ['P R IH1 S IY0'],
  "prissy": ['P R IH1 S IY0'],
  "pristine": ['P R IH1 S T IY0 N'],
  "pritchard": ['P R IH1 CH ER0 D'],
  "pritchett": ['P R IH1 CH IH0 T'],
  "prithvi": ['P R IH1 TH V IY0'],
  "pritt": ['P R IH1 T'],
  "pritts": ['P R IH1 T S'],
  "pritz": ['P R IH1 T S'],
  "pritzker": ['P R IH1 T S K ER0'],
  "pritzkers": ['P R IH1 T S K ER0 Z'],
  "pritzl": ['P R IH1 T Z AH0 L'],
  "prius": ['P R AY1 AH0 S'],
  "privacies": ['P R AY1 V AH0 S IY0 Z'],
  "privacy": ['P R AY1 V AH0 S IY0'],
  "private": ['P R AY1 V AH0 T'],
  "private-eye": ['P R AY1 V AH0 T AY1'],
  "privately": ['P R AY1 V AH0 T L IY0'],
  "privates": ['P R AY1 V AH0 T S'],
  "privation": ['P R AY0 V EY1 SH AH0 N'],
  "privations": ['P R AY0 V EY1 SH AH0 N Z'],
  "privatisation": ['P R AY1 V AH0 T AH0 Z EY1 SH AH0 N'],
  "privatization": ['P R AY1 V AH0 T AH0 Z EY1 SH AH0 N'],
  "privatizations": ['P R AY1 V AH0 T AH0 Z EY1 SH AH0 N Z'],
  "privatize": ['P R IH1 V AH0 T AY2 Z'],
  "privatized": ['P R AY1 V AH0 T AY2 Z D'],
  "privatizing": ['P R AY1 V AH0 T AY2 Z IH0 NG'],
  "privett": ['P R IH1 V IH0 T'],
  "privette": ['P R IH0 V EH1 T'],
  "privilege": ['P R IH1 V L AH0 JH', 'P R IH1 V L IH0 JH', 'P R IH1 V IH0 L AH0 JH', 'P R IH1 V IH0 L IH0 JH'],
  "privileged": ['P R IH1 V L AH0 JH D', 'P R IH1 V L IH0 JH D', 'P R IH1 V IH0 L AH0 JH D', 'P R IH1 V IH0 L IH0 JH D'],
  "privileges": ['P R IH1 V L AH0 JH AH0 Z', 'P R IH1 V L IH0 JH IH0 Z', 'P R IH1 V IH0 L AH0 JH AH0 Z', 'P R IH1 V IH0 L IH0 JH IH0 Z'],
  "privitera": ['P R IY0 V IY0 T EH1 R AH0'],
  "privott": ['P R IH1 V AH0 T'],
  "privy": ['P R IH1 V IY0'],
  "prix": ['P R IY1'],
  "priyam": ['P R IY1 AH0 M'],
  "prizant": ['P R IY1 Z AA0 N T'],
  "prize": ['P R AY1 Z'],
  "prized": ['P R AY1 Z D'],
  "prizes": ['P R AY1 Z AH0 Z', 'P R AY1 Z IH0 Z'],
  "prizm": ['P R IH1 Z AH0 M'],
  "pro": ['P R OW1'],
  "pro's": ['P R OW1 Z'],
  "proactive": ['P R OW2 AE1 K T IH0 V'],
  "proactively": ['P R OW2 AE1 K T IH0 V L IY2'],
  "prob": ['P R AA1 B', 'P R OW1 B'],
  "proba": ['P R OW1 B AA0'],
  "probabilistic": ['P R AA2 B AH0 B IH0 L IH1 S T AH0 K'],
  "probabilistically": ['P R AA2 B AH0 B IH0 L IH1 S T AH0 K L IY0'],
  "probabilities": ['P R AA2 B AH0 B IH1 L AH0 T IY2 Z'],
  "probability": ['P R AA2 B AH0 B IH1 L AH0 T IY2'],
  "probable": ['P R AA1 B AH0 B AH0 L'],
  "probably": ['P R AA1 B AH0 B L IY2', 'P R AA1 B L IY0'],
  "probasco": ['P R OW0 B AA1 S K OW2'],
  "probate": ['P R OW1 B EY2 T'],
  "probation": ['P R OW0 B EY1 SH AH0 N'],
  "probationary": ['P R OW0 B EY1 SH AH0 N EH2 R IY2'],
  "probationer": ['P R OW0 B EY1 SH AH0 N ER0'],
  "probationers": ['P R OW0 B EY1 SH AH0 N ER0 Z'],
  "probative": ['P R OW0 B EY1 T IH0 V'],
  "probe": ['P R OW1 B'],
  "probe's": ['P R OW1 B Z'],
  "probed": ['P R OW1 B D'],
  "probenecid": ['P R AA2 B EH1 N AH0 S IH0 D'],
  "probert": ['P R AA1 B ER0 T'],
  "probes": ['P R OW1 B Z'],
  "probing": ['P R OW1 B IH0 NG'],
  "probity": ['P R OW1 B AH0 T IY0'],
  "problem": ['P R AA1 B L AH0 M'],
  "problem's": ['P R AA1 B L AH0 M Z'],
  "problematic": ['P R AA2 B L AH0 M AE1 T IH0 K'],
  "problematical": ['P R AA2 B L AH0 M AE1 T IH0 K AH0 L'],
  "problems": ['P R AA1 B L AH0 M Z'],
  "proboscis": ['P R OW0 B AA1 S AH0 S'],
  "probst": ['P R AA1 B S T'],
  "probursa": ['P R OW2 B ER1 S AH0'],
  "probus": ['P R OW1 B IH0 S'],
  "procaccini": ['P R OW0 K AA0 CH IY1 N IY0'],
  "procaine": ['P R OW1 K EY2 N'],
  "procardia": ['P R OW0 K AA1 R D IY0 AH0'],
  "procassini": ['P R AA2 K AH0 S IY1 N IY0'],
  "procedural": ['P R AH0 S IY1 JH ER0 AH0 L'],
  "procedurally": ['P R AH0 S IY1 JH ER0 AH0 L IY0', 'P R AH0 S IY1 JH R AH0 L IY0'],
  "procedure": ['P R AH0 S IY1 JH ER0', 'P R OW0 S IY1 JH ER0'],
  "procedures": ['P R AH0 S IY1 JH ER0 Z', 'P R OW0 S IY1 JH ER0 Z'],
  "proceed": ['P R AH0 S IY1 D', 'P R OW0 S IY1 D', 'P ER0 S IY1 D'],
  "proceeded": ['P R AH0 S IY1 D IH0 D', 'P R OW0 S IY1 D IH0 D'],
  "proceeding": ['P R AH0 S IY1 D IH0 NG', 'P R OW0 S IY1 D IH0 NG'],
  "proceedings": ['P R OW0 S IY1 D IH0 NG Z', 'P R AH0 S IY1 D IH0 NG Z'],
  "proceeds": ['P R AH0 S IY1 D Z', 'P R OW0 S IY1 D Z'],
  "procell": ['P R OW0 S EH1 L'],
  "proceso": ['P R OW2 S EH1 S OW0'],
  "process": ['P R AA1 S EH2 S', 'P R AO1 S EH2 S'],
  "processed": ['P R AA1 S EH0 S T'],
  "processes": ['P R AA1 S EH0 S AH0 Z'],
  "processing": ['P R AA1 S EH0 S IH0 NG'],
  "procession": ['P R AH0 S EH1 SH AH0 N', 'P R OW0 S EH1 SH AH0 N'],
  "processional": ['P R AH0 S EH1 SH AH0 N AH0 L', 'P R OW0 S EH1 SH AH0 N AH0 L'],
  "processions": ['P R OW0 S EH1 SH AH0 N Z'],
  "processor": ['P R AA1 S EH2 S ER0'],
  "processor's": ['P R AA1 S EH2 S ER0 Z'],
  "processors": ['P R AA1 S EH2 S ER0 Z'],
  "proch": ['P R AA1 K'],
  "prochaska": ['P R AH0 HH AA1 S K AH0'],
  "prochazka": ['P R AH0 HH AA1 Z K AH0'],
  "prochnow": ['P R AA1 N AW0'],
  "procida": ['P R OW0 CH IY1 D AH0'],
  "prock": ['P R AA1 K'],
  "prockter": ['P R AA1 K T ER0'],
  "proclaim": ['P R OW0 K L EY1 M'],
  "proclaimed": ['P R OW0 K L EY1 M D'],
  "proclaiming": ['P R OW0 K L EY1 M IH0 NG'],
  "proclaims": ['P R OW0 K L EY1 M Z'],
  "proclamation": ['P R AA2 K L AH0 M EY1 SH AH0 N'],
  "proclamations": ['P R AA2 K L AH0 M EY1 SH AH0 N Z'],
  "proclivities": ['P R OW0 K L IH1 V AH0 T IY0 Z'],
  "proclivity": ['P R OW0 K L IH1 V AH0 T IY0'],
  "proconsul": ['P R OW0 K AA1 N S AH0 L'],
  "procopio": ['P R OW0 K OW1 P IY0 OW0'],
  "procordia": ['P R AA0 K AO1 R D IY0 AH0'],
  "procrastinate": ['P R AH0 K R AE1 S T AH0 N EY2 T'],
  "procrastinated": ['P R AH0 K R AE1 S T AH0 N EY2 T IH0 D'],
  "procrastinates": ['P R AH0 K R AE1 S T AH0 N EY2 T S'],
  "procrastinating": ['P R AH0 K R AE1 S T AH0 N EY2 T IH0 NG'],
  "procrastination": ['P R AH0 K R AE2 S T AH0 N EY1 SH AH0 N'],
  "procrastinator": ['P R AH0 K R AE1 S T AH0 N EY2 T ER0'],
  "procrastinators": ['P R AH0 K R AE1 S T AH0 N EY2 T ER0 Z'],
  "procreate": ['P R OW1 K R IY0 EY1 T'],
  "procreated": ['P R OW1 K R IY0 EY1 T IH0 D'],
  "procreates": ['P R OW1 K R IY0 EY1 T S'],
  "procreating": ['P R OW1 K R IY0 EY1 T IH0 NG'],
  "procreation": ['P R OW1 K R IY0 EY1 SH AH0 N'],
  "procter": ['P R AA1 K T ER0'],
  "proctologist": ['P R AO2 K T AA1 L AH0 G IH2 S T'],
  "proctor": ['P R AA1 K T ER0'],
  "proctor's": ['P R AA1 K T ER0 Z'],
  "proctors": ['P R AA1 K T ER0 Z'],
  "proctorsilex": ['P R AO2 K T ER0 S AY1 L EH0 K S'],
  "proctoscope": ['P R AO1 K T AA0 S K OW2 P'],
  "proctoscopy": ['P R AO2 K T AA1 S K OW0 P IY2'],
  "procure": ['P R OW0 K Y UH1 R'],
  "procured": ['P R OW0 K Y UH1 R D'],
  "procurement": ['P R OW0 K Y UH1 R M AH0 N T'],
  "procurements": ['P R OW0 K Y UH1 R M AH0 N T S'],
  "procurer": ['P R OW0 K Y UH1 R ER0'],
  "procuring": ['P R OW0 K Y UH1 R IH0 NG'],
  "procyte": ['P R OW1 S AY2 T'],
  "prod": ['P R AA1 D'],
  "prodded": ['P R AA1 D IH0 D'],
  "prodding": ['P R AA1 D IH0 NG'],
  "prodi": ['P R OW1 D IY0'],
  "prodi's": ['P R OW1 D IY0 Z'],
  "prodigal": ['P R AA1 D IH0 G AH0 L'],
  "prodigies": ['P R AA1 D AH0 JH IY0 Z'],
  "prodigious": ['P R AH0 D IH1 JH AH0 S'],
  "prodigiously": ['P R OW0 D IH1 JH IH0 S L IY0'],
  "prodigy": ['P R AA1 D AH0 JH IY0'],
  "prodigy's": ['P R AA1 D AH0 JH IY0 Z'],
  "prods": ['P R AA1 D Z'],
  "produc": ['P R OW0 D UW1 K'],
  "produce": ['P R AH0 D UW1 S', 'P R OW1 D UW0 S'],
  "produced": ['P R AH0 D UW1 S T'],
  "producer": ['P R AH0 D UW1 S ER0'],
  "producer's": ['P R AH0 D UW1 S ER0 Z'],
  "producers": ['P R AH0 D UW1 S ER0 Z'],
  "producers'": ['P R AH0 D UW1 S ER0 Z'],
  "produces": ['P R AH0 D UW1 S AH0 Z', 'P R AH0 D UW1 S IH0 Z'],
  "producing": ['P R AH0 D UW1 S IH0 NG'],
  "product": ['P R AA1 D AH0 K T'],
  "product's": ['P R AA1 D AH0 K T S', 'P R AA1 D AH0 K S'],
  "production": ['P R AH0 D AH1 K SH AH0 N', 'P R OW0 D AH1 K SH AH0 N', 'P ER0 D AH1 K SH AH0 N'],
  "production's": ['P R OW0 D AH1 K SH AH0 N Z', 'P R AH0 D AH1 K SH AH0 N Z', 'P ER0 D AH1 K SH AH0 N Z'],
  "productions": ['P R AH0 D AH1 K SH AH0 N Z', 'P R OW0 D AH1 K SH AH0 N Z', 'P ER0 D AH1 K SH AH0 N Z'],
  "productions'": ['P R AH0 D AH1 K SH AH0 N Z', 'P R OW0 D AH1 K SH AH0 N Z', 'P ER0 D AH1 K SH AH0 N Z'],
  "productive": ['P R AH0 D AH1 K T IH0 V', 'P R OW0 D AH1 K T IH0 V', 'P ER0 D AH1 K T IH0 V'],
  "productively": ['P R AH0 D AH1 K T IH0 V L IY0', 'P R OW0 D AH1 K T IH0 V L IY0', 'P ER0 D AH1 K T IH0 V L IY0'],
  "productivity": ['P R OW2 D AH0 K T IH1 V AH0 T IY0', 'P R OW2 D AH0 K T IH1 V IH0 T IY0'],
  "products": ['P R AA1 D AH0 K T S', 'P R AA1 D AH0 K S'],
  "products'": ['P R AO1 D AH0 K T S', 'P R AO1 D AH0 K S'],
  "prody": ['P OW1 D IY0'],
  "proehl": ['P R OW1 L'],
  "prof.": ['P R AO1 F', 'P R AH0 F EH1 S ER0'],
  "profanation": ['P R AO2 F AH0 N EY1 SH AH0 N'],
  "profane": ['P R OW0 F EY1 N'],
  "profanity": ['P R OW0 F AE1 N AH0 T IY0'],
  "profess": ['P R AH0 F EH1 S'],
  "professed": ['P R AH0 F EH1 S T'],
  "professes": ['P R AH0 F EH1 S IH0 Z'],
  "professing": ['P R AH0 F EH1 S IH0 NG'],
  "profession": ['P R AH0 F EH1 SH AH0 N'],
  "profession's": ['P R AH0 F EH1 SH AH0 N Z'],
  "professional": ['P R AH0 F EH1 SH AH0 N AH0 L'],
  "professionalism": ['P R AH0 F EH1 SH AH0 N AH0 L IH2 Z AH0 M'],
  "professionalize": ['P R AH0 F EH1 SH AH0 N AH0 L AY2 Z'],
  "professionalized": ['P R AH0 F EH1 SH AH0 N AH0 L AY2 Z D'],
  "professionally": ['P R AH0 F EH1 SH AH0 N AH0 L IY0', 'P R AH0 F EH1 SH N AH0 L IY0'],
  "professionals": ['P R AH0 F EH1 SH AH0 N AH0 L Z'],
  "professionals'": ['P R AH0 F EH1 SH AH0 N AH0 L Z'],
  "professions": ['P R AH0 F EH1 SH AH0 N Z'],
  "professor": ['P R AH0 F EH1 S ER0'],
  "professor's": ['P R AH0 F EH1 S ER0 Z'],
  "professorial": ['P R OW2 F AH0 S AO1 R IY0 AH0 L'],
  "professors": ['P R AH0 F EH1 S ER0 Z'],
  "professors'": ['P R AH0 F EH1 S ER0 Z'],
  "professorship": ['P R AH0 F EH1 S ER0 SH IH2 P'],
  "professorships": ['P R AH0 F EH1 S ER0 SH IH2 P S'],
  "profeta": ['P R OW0 F EH1 T AH0'],
  "proffer": ['P R AA1 F ER0'],
  "proffered": ['P R AA1 F ER0 D'],
  "proffering": ['P R AA1 F ER0 IH0 NG'],
  "proffit": ['P R AA1 F IH0 T'],
  "proffitt": ['P R AA1 F IH0 T'],
  "proficiency": ['P R AH0 F IH1 SH AH0 N S IY0'],
  "proficient": ['P R AA0 F IH1 SH AH0 N T'],
  "profile": ['P R OW1 F AY2 L'],
  "profiled": ['P R OW1 F AY2 L D'],
  "profiles": ['P R OW1 F AY2 L Z'],
  "profiling": ['P R OW1 F AY2 L IH0 NG'],
  "profit": ['P R AA1 F AH0 T', 'P R AA1 F IH0 T'],
  "profitability": ['P R AA2 F IH0 T AH0 B IH1 L IH0 T IY0'],
  "profitable": ['P R AA1 F AH0 T AH0 B AH0 L'],
  "profitably": ['P R AA1 F AH0 T AH0 B L IY0'],
  "profited": ['P R AA1 F AH0 T AH0 D'],
  "profiteer": ['P R AA2 F AH0 T IH1 R'],
  "profiteering": ['P R AA2 F AH0 T IH1 R IH0 NG'],
  "profiteers": ['P R AA2 F AH0 T IH1 R Z'],
  "profiting": ['P R AA1 F AH0 T IH0 NG'],
  "profits": ['P R AA1 F IH0 T S'],
  "profitt": ['P R AA1 F IH0 T'],
  "profittaking": ['P R AA1 F IH0 T T EY2 K IH0 NG'],
  "profligacy": ['P R AO1 F L IH0 G AE2 S IY0'],
  "profligate": ['P R AO1 F L IH0 G EY2 T'],
  "profound": ['P R OW0 F AW1 N D'],
  "profoundly": ['P R OW0 F AW1 N D L IY0'],
  "profs": ['P R AA1 F S'],
  "profuse": ['P R AH0 F Y UW1 S'],
  "profusely": ['P R AH0 F Y UW1 S L IY0'],
  "profusion": ['P R AH0 F Y UW1 ZH AH0 N'],
  "progenitor": ['P R OW0 JH EH1 N IH0 T ER0'],
  "progeny": ['P R AA1 JH AH0 N IY0'],
  "progesterone": ['P R OW0 JH EH1 S T ER0 OW2 N'],
  "progestin": ['P R OW0 JH EH1 S T IH0 N'],
  "proglacial": ['P R OW0 G L EY1 SH AH0 L'],
  "prognoses": ['P R AA0 G N OW1 S IY0 Z'],
  "prognosis": ['P R AA0 G N OW1 S AH0 S'],
  "prognosticate": ['P R AA2 G N AA1 S T AH0 K EY2 T'],
  "prognosticated": ['P R AA2 G N AA1 S T AH0 K EY2 T IH0 D'],
  "prognosticater": ['P R AA2 G N AA1 S T AH0 K EY2 T ER0'],
  "prognosticates": ['P R AA2 G N AA1 S T AH0 K EY2 T S'],
  "prognosticating": ['P R AA2 G N AA1 S T AH0 K EY2 T IH0 NG'],
  "prognostication": ['P R AA0 G N AA2 S T AH0 K EY1 SH AH0 N'],
  "prognostications": ['P R AA0 G N AA2 S T AH0 K EY1 SH AH0 N Z'],
  "prognosticative": ['P R AA2 G N AA1 S T AH0 K EY2 T IH0 V'],
  "program": ['P R OW1 G R AE2 M'],
  "program's": ['P R OW1 G R AE2 M Z'],
  "programing": ['P R OW1 G R AE2 M IH0 NG'],
  "programmable": ['P R OW1 G R AE2 M AH0 B AH0 L'],
  "programmatic": ['P R AA2 G R AH0 M AE1 T IH0 K'],
  "programme": ['P R OW1 G R AE2 M'],
  "programmed": ['P R OW1 G R AE2 M D'],
  "programmer": ['P R OW1 G R AE2 M ER0'],
  "programmers": ['P R OW1 G R AE2 M ER0 Z'],
  "programming": ['P R OW1 G R AE2 M IH0 NG'],
  "programs": ['P R OW1 G R AE2 M Z'],
  "programs'": ['P R OW1 G R AE2 M Z'],
  "progress": ['P R AA1 G R EH2 S', 'P R AH0 G R EH1 S', 'P R OW0 G R EH1 S'],
  "progressed": ['P R AH0 G R EH1 S T'],
  "progresses": ['P R AA1 G R EH2 S AH0 Z', 'P R OW0 G R EH1 S AH0 Z'],
  "progressing": ['P R AH0 G R EH1 S IH0 NG'],
  "progression": ['P R AH0 G R EH1 SH AH0 N'],
  "progressive": ['P R AH0 G R EH1 S IH0 V'],
  "progressively": ['P R AA0 G R EH1 S IH0 V L IY0'],
  "progressives": ['P R AA0 G R EH1 S IH0 V Z'],
  "progressivity": ['P R AA2 G R EH0 S IH1 V AH0 T IY0'],
  "prohaska": ['P R AH0 HH AA1 S K AH0'],
  "prohibit": ['P R OW0 HH IH1 B AH0 T'],
  "prohibited": ['P R OW0 HH IH1 B AH0 T AH0 D'],
  "prohibiting": ['P R OW0 HH IH1 B AH0 T IH0 NG'],
  "prohibition": ['P R OW2 AH0 B IH1 SH AH0 N'],
  "prohibition's": ['P R OW2 AH0 B IH1 SH AH0 N Z'],
  "prohibitions": ['P R OW2 AH0 B IH1 SH AH0 N Z'],
  "prohibitive": ['P R OW0 HH IH1 B AH0 T IH0 V'],
  "prohibitively": ['P R OW0 HH IH1 B AH0 T IH0 V L IY0'],
  "prohibitory": ['P R OW0 HH IH1 B AH0 T AO2 R IY0'],
  "prohibits": ['P R OW0 HH IH1 B AH0 T S'],
  "proia": ['P R OW1 Y AH0'],
  "proietti": ['P R OY0 EH1 T IY0'],
  "project": ['P R AA1 JH EH0 K T', 'P R AH0 JH EH1 K T'],
  "project's": ['P R AA1 JH EH0 K T S', 'P R AA1 JH EH0 K S'],
  "projected": ['P R AH0 JH EH1 K T AH0 D'],
  "projectile": ['P R AH0 JH EH1 K T AH0 L', 'P R AH0 JH EH1 K T AY0 L'],
  "projectiles": ['P R AH0 JH EH1 K T AH0 L Z', 'P R AH0 JH EH1 K T AY0 L Z'],
  "projecting": ['P R AH0 JH EH1 K T IH0 NG'],
  "projection": ['P R AH0 JH EH1 K SH AH0 N'],
  "projections": ['P R AH0 JH EH1 K SH AH0 N Z'],
  "projective": ['P R AH0 JH EH1 K T IH0 V'],
  "projector": ['P R AH0 JH EH1 K T ER0'],
  "projectors": ['P R AH0 JH EH1 K T ER0 Z'],
  "projects": ['P R AA1 JH EH0 K T S', 'P R AH0 JH EH1 K T S', 'P R AA1 JH EH0 K S', 'P R AH0 JH EH1 K S'],
  "projects'": ['P R AO1 JH EH0 K T S', 'P R AO1 JH EH0 K S'],
  "prokofiev": ['P R AA1 K OW0 F IY2 V'],
  "prokop": ['P R OW1 K AH0 P'],
  "prolactin": ['P R OW0 L AE1 K T AH0 N'],
  "proler": ['P R OW1 L ER0'],
  "proletarian": ['P R OW2 L AH0 T EH1 R IY0 AH0 N'],
  "proletariat": ['P R OW2 L AH0 T EH1 R IY0 AH0 T'],
  "proleukin": ['P R OW1 L UW0 K IH0 N'],
  "proliferate": ['P R OW0 L IH1 F ER0 EY2 T'],
  "proliferated": ['P R AH0 L IH1 F ER0 EY2 T IH0 D'],
  "proliferates": ['P R OW0 L IH1 F ER0 EY2 T S'],
  "proliferating": ['P R OW0 L IH1 F ER0 EY2 T IH0 NG'],
  "proliferation": ['P R OW2 L IH0 F ER0 EY1 SH AH0 N'],
  "prolific": ['P R OW0 L IH1 F IH0 K'],
  "prolifically": ['P R OW0 L IH1 F IH0 K L IY0'],
  "prolinea": ['P R OW2 L IH1 N IY0 AH0'],
  "prolog": ['P R OW0 L AA1 G'],
  "prologue": ['P R OW1 L AA0 G'],
  "prolong": ['P R AH0 L AO1 NG'],
  "prolonged": ['P R AH0 L AO1 NG D'],
  "prolonging": ['P R OW0 L AO1 NG IH0 NG'],
  "prolongs": ['P R AH0 L AO1 NG Z'],
  "prom": ['P R AA1 M'],
  "promenade": ['P R AA2 M AH0 N EY1 D'],
  "promenades": ['P R AA2 M AH0 N EY1 D Z'],
  "prometheus": ['P R AH0 M IY1 TH IY2 AH0 S'],
  "promethium": ['P R AH0 M IY1 TH IY2 AH0 M'],
  "promilacidic": ['P R OW0 M IH0 L AH0 S IY1 D IH0 K'],
  "prominence": ['P R AA1 M AH0 N AH0 N S'],
  "prominences": ['P R AA1 M AH0 N AH0 N S AH0 Z'],
  "prominent": ['P R AA1 M AH0 N AH0 N T'],
  "prominently": ['P R AA1 M AH0 N AH0 N T L IY0'],
  "promiscuity": ['P R OW2 M IH0 S K Y UW1 AH0 T IY0', 'P R AA2 M IH0 S K Y UW1 AH0 T IY0'],
  "promiscuous": ['P R OW1 M IH0 S K W AH0 S', 'P R AA0 M IH1 S K Y UW0 AH0 S'],
  "promise": ['P R AA1 M AH0 S'],
  "promised": ['P R AA1 M AH0 S T'],
  "promises": ['P R AA1 M AH0 S AH0 Z'],
  "promising": ['P R AA1 M AH0 S IH0 NG'],
  "promissory": ['P R AA1 M AH0 S AO2 R IY0'],
  "promo": ['P R OW1 M OW2'],
  "promos": ['P R OW1 M OW2 Z'],
  "promote": ['P R AH0 M OW1 T'],
  "promoted": ['P R AH0 M OW1 T IH0 D'],
  "promoter": ['P R AH0 M OW1 T ER0'],
  "promoters": ['P R AH0 M OW1 T ER0 Z'],
  "promotes": ['P R AH0 M OW1 T S'],
  "promoting": ['P R AH0 M OW1 T IH0 NG'],
  "promotion": ['P R AH0 M OW1 SH AH0 N', 'P ER0 M OW1 SH AH0 N'],
  "promotional": ['P R AH0 M OW1 SH AH0 N AH0 L', 'P ER0 M OW1 SH AH0 N AH0 L'],
  "promotions": ['P R AH0 M OW1 SH AH0 N Z', 'P ER0 M OW1 SH AH0 N Z'],
  "prompt": ['P R AA1 M P T'],
  "prompted": ['P R AA1 M P T IH0 D'],
  "prompting": ['P R AA1 M P T IH0 NG'],
  "promptly": ['P R AA1 M P T L IY0', 'P R AA1 M P L IY0'],
  "prompts": ['P R AA1 M P T S', 'P R AA1 M P S'],
  "proms": ['P R AA1 M Z'],
  "promster": ['P R AA1 M S T ER0'],
  "promsters": ['P R AA1 M S T ER0 Z'],
  "promulgate": ['P R OW0 M AH1 L G EY0 T'],
  "promulgated": ['P R AA1 M AH0 L G EY2 T IH0 D'],
  "promulgates": ['P R OW0 M AH1 L G EY0 T S'],
  "promulgating": ['P R AA1 M AH0 L G EY2 T IH0 NG'],
  "promus": ['P R OW1 M AH0 S'],
  "prone": ['P R OW1 N'],
  "prong": ['P R AO1 NG'],
  "pronged": ['P R AO1 NG D'],
  "pronger": ['P R AO1 N G ER0'],
  "pronghorn": ['P R AO1 NG HH AO2 R N'],
  "prongs": ['P R AO1 NG Z'],
  "pronoun": ['P R OW1 N AW0 N'],
  "pronounce": ['P R AH0 N AW1 N S'],
  "pronounced": ['P R AH0 N AW1 N S T'],
  "pronouncement": ['P R AH0 N AW1 N S M AH0 N T'],
  "pronouncements": ['P R AH0 N AW1 N S M AH0 N T S'],
  "pronounces": ['P R AH0 N AW1 N S IH0 Z'],
  "pronouncing": ['P R AH0 N AW1 N S IH0 NG'],
  "pronouns": ['P R OW1 N AW0 N Z'],
  "pronovost": ['P R OW0 N OW1 V OW0 S T'],
  "pronto": ['P R AA1 N T OW0'],
  "pronunciation": ['P R OW0 N AH2 N S IY0 EY1 SH AH0 N', 'P R AH0 N AH2 N S IY0 EY1 SH AH0 N'],
  "pronunciations": ['P R OW0 N AH2 N S IY0 EY1 SH AH0 N Z', 'P R AH0 N AH2 N S IY0 EY1 SH AH0 N Z'],
  "proof": ['P R UW1 F'],
  "proofed": ['P R UW1 F T'],
  "proofing": ['P R UW1 F IH0 NG'],
  "proofread": ['P R UW1 F R IY2 D'],
  "proofreading": ['P R UW1 F R IY2 D IH0 NG'],
  "proofs": ['P R UW1 F S'],
  "prop": ['P R AA1 P'],
  "propaganda": ['P R AA2 P AH0 G AE1 N D AH0'],
  "propagandist": ['P R AA2 P AH0 G AE1 N D AH0 S T'],
  "propagandistic": ['P R AA2 P AH0 G AH0 N D IH1 S T IH0 K'],
  "propagandists": ['P R AA2 P AH0 G AE1 N D AH0 S T S'],
  "propagandize": ['P R AA2 P AH0 G AE1 N D AY2 Z'],
  "propagate": ['P R AA1 P AH0 G EY2 T'],
  "propagated": ['P R AA1 P AH0 G EY2 T IH0 D'],
  "propagating": ['P R AA1 P AH0 G EY2 T IH0 NG'],
  "propagation": ['P R AA2 P AH0 G EY1 SH AH0 N'],
  "propane": ['P R OW1 P EY2 N'],
  "propel": ['P R AH0 P EH1 L'],
  "propellant": ['P R AH0 P EH1 L AH0 N T'],
  "propellants": ['P R OW0 P EH1 L AH0 N T S'],
  "propelled": ['P R AH0 P EH1 L D'],
  "propeller": ['P R AH0 P EH1 L ER0'],
  "propellers": ['P R AH0 P EH1 L ER0 Z'],
  "propelling": ['P R AH0 P EH1 L IH0 NG'],
  "propels": ['P R AH0 P EH1 L Z'],
  "propensities": ['P R AH0 P EH1 N S AH0 T IY0 Z'],
  "propensity": ['P R AH0 P EH1 N S IH0 T IY0'],
  "proper": ['P R AA1 P ER0'],
  "properly": ['P R AA1 P ER0 L IY0'],
  "properties": ['P R AA1 P ER0 T IY0 Z'],
  "properties'": ['P R OW1 P ER0 T IY0 Z'],
  "property": ['P R AA1 P ER0 T IY0'],
  "property's": ['P R AA1 P ER0 T IY0 Z'],
  "propes": ['P R OW1 P S'],
  "propfan": ['P R AA1 P F AE2 N'],
  "prophecies": ['P R AA1 F AH0 S IY0 Z'],
  "prophecy": ['P R AA1 F AH0 S IY0'],
  "prophesied": ['P R AA1 F AH0 S AY2 D', 'P R AA1 F AH0 S IY2 D'],
  "prophesies": ['P R AA1 F AH0 S AY0 Z'],
  "prophet": ['P R AA1 F AH0 T'],
  "prophetess": ['P R AA1 F AH0 T AH0 S'],
  "prophetic": ['P R AH0 F EH1 T IH0 K'],
  "prophets": ['P R AA1 F AH0 T S'],
  "prophylactic": ['P R AA2 F IH0 L AE1 K T IH0 K', 'P R OW2 F IH0 L AE1 K T IH0 K'],
  "propionic": ['P R OW2 P IY0 AA1 N IH0 K'],
  "propitious": ['P R AH0 P IH1 SH AH0 S'],
  "proponent": ['P R AH0 P OW1 N AH0 N T'],
  "proponents": ['P R AH0 P OW1 N AH0 N T S'],
  "proportion": ['P R AH0 P AO1 R SH AH0 N'],
  "proportional": ['P R AH0 P AO1 R SH AH0 N AH0 L'],
  "proportionality": ['P R AH0 P AO2 R SH AH0 N AE1 L IH0 T IY0'],
  "proportionally": ['P R AH0 P AO1 R SH AH0 N AH0 L IY0', 'P R AH0 P AO1 R SH N AH0 L IY0'],
  "proportionate": ['P R AH0 P AO1 R SH AH0 N AH0 T'],
  "proportionately": ['P R AH0 P AO1 R SH AH0 N AH0 T L IY0'],
  "proportioned": ['P R AH0 P AO1 R SH AH0 N D'],
  "proportions": ['P R AH0 P AO1 R SH AH0 N Z'],
  "proposal": ['P R AH0 P OW1 Z AH0 L'],
  "proposal's": ['P R AH0 P OW1 Z AH0 L Z'],
  "proposals": ['P R AH0 P OW1 Z AH0 L Z'],
  "propose": ['P R AH0 P OW1 Z'],
  "proposed": ['P R AH0 P OW1 Z D'],
  "proposes": ['P R AH0 P OW1 Z IH0 Z'],
  "proposing": ['P R AH0 P OW1 Z IH0 NG'],
  "proposition": ['P R AA2 P AH0 Z IH1 SH AH0 N'],
  "propositioned": ['P R AA2 P AH0 Z IH1 SH AH0 N D'],
  "propositions": ['P R AA2 P AH0 Z IH1 SH AH0 N Z'],
  "propound": ['P R AH0 P AW1 N D'],
  "propounded": ['P R AH0 P AW1 N D IH0 D'],
  "propp": ['P R AA1 P'],
  "propped": ['P R AA1 P T'],
  "propper": ['P R AA1 P ER0'],
  "propping": ['P R AA1 P IH0 NG'],
  "propps": ['P R AA1 P S'],
  "proprietaries": ['P R AH0 P R AY1 AH0 T EH2 R IY0 Z'],
  "proprietary": ['P R AH0 P R AY1 AH0 T EH2 R IY0'],
  "proprietor": ['P R AH0 P R AY1 AH0 T ER0'],
  "proprietor's": ['P R AH0 P R AY1 AH0 T ER0 Z'],
  "proprietors": ['P R AH0 P R AY1 AH0 T ER0 Z'],
  "proprietorship": ['P R AH0 P R AY1 AH0 T ER0 SH IH2 P'],
  "proprietorships": ['P R AH0 P R AY1 AH0 T ER0 SH IH2 P S'],
  "propriety": ['P R AH0 P R AY1 AH0 T IY0'],
  "props": ['P R AA1 P S'],
  "propst": ['P R AA1 P S T'],
  "propulsion": ['P R AH0 P AH1 L SH AH0 N'],
  "propylene": ['P R OW0 P AH0 L IY1 N'],
  "prorate": ['P R OW1 R EY1 T'],
  "prorated": ['P R OW1 R EY2 T IH0 D'],
  "proration": ['P R OW2 R EY1 SH AH0 N'],
  "prorok": ['P R AO1 R AH0 K'],
  "pros": ['P R OW1 Z'],
  "pros'": ['P R OW1 Z'],
  "prosaic": ['P R OW0 Z EY1 IH0 K'],
  "proscar": ['P R AO1 S K AA2 R'],
  "prosch": ['P R AO1 SH'],
  "proscia": ['P R OW1 S CH AH0'],
  "prosciutto": ['P R AH0 SH UW1 T OW2'],
  "proscribe": ['P R OW0 S K R AY1 B'],
  "proscribed": ['P R OW0 S K R AY1 B D'],
  "proscribes": ['P R OW0 S K R AY1 B Z'],
  "proscription": ['P R OW0 S K R IH1 P SH AH0 N'],
  "prose": ['P R OW1 Z'],
  "prosecutable": ['P R AA1 S IH0 K Y UW2 T AH0 B AH0 L'],
  "prosecute": ['P R AA1 S AH0 K Y UW2 T'],
  "prosecuted": ['P R AA1 S IH0 K Y UW2 T IH0 D'],
  "prosecutes": ['P R AA1 S IH0 K Y UW2 T S'],
  "prosecuting": ['P R AA1 S IH0 K Y UW2 T IH0 NG'],
  "prosecution": ['P R AA2 S AH0 K Y UW1 SH AH0 N'],
  "prosecution's": ['P R AA2 S AH0 K Y UW1 SH AH0 N Z'],
  "prosecutions": ['P R AA2 S AH0 K Y UW1 SH AH0 N Z'],
  "prosecutor": ['P R AA1 S IH0 K Y UW2 T ER0'],
  "prosecutor's": ['P R AA1 S IH0 K Y UW2 T ER0 Z'],
  "prosecutorial": ['P R AA2 S IH0 K Y UW0 T AO1 R IY0 AH0 L'],
  "prosecutors": ['P R AA1 S IH0 K Y UW2 T ER0 Z'],
  "prosecutors'": ['P R AA1 S AH0 K Y UW0 T ER0 Z'],
  "prosek": ['P R OW1 S EH0 K'],
  "prosekey": ['P R OW0 S EH1 K IY0'],
  "proselytize": ['P R AA1 S AH0 L AH0 T AY2 Z'],
  "proselytized": ['P R AA1 S AH0 L AH0 T AY2 Z D'],
  "proselytizing": ['P R AA1 S AH0 L AH0 T AY2 Z IH0 NG'],
  "proserpina": ['P R OW0 S ER1 P AH0 N AH0', 'P R AA0 S ER0 P IY1 N AH0'],
  "proserv": ['P R OW1 S ER2 V'],
  "proshare": ['P R OW1 SH EH2 R'],
  "prosise": ['P R OW1 S AY0 Z'],
  "proskauer": ['P R AO1 S K AW0 R'],
  "proske": ['P R OW1 S K'],
  "prosody": ['P R AA1 S AH0 D IY0'],
  "prosorba": ['P R AA0 S AO1 R B AH0'],
  "prospect": ['P R AA1 S P EH0 K T'],
  "prospecting": ['P R AO2 S P EH1 K T IH0 NG'],
  "prospective": ['P R AH0 S P EH1 K T IH0 V'],
  "prospectively": ['P R AH0 S P EH1 K T IH0 V L IY0'],
  "prospector": ['P R AO1 S P EH2 K T ER0'],
  "prospectors": ['P R AO1 S P EH2 K T ER0 Z'],
  "prospects": ['P R AA1 S P EH0 K T S', 'P R AA1 S P EH0 K S'],
  "prospectus": ['P R AH0 S P EH1 K T AH0 S'],
  "prospectuses": ['P R AH0 S P EH1 K T AH0 S IH0 Z'],
  "prospekt": ['P R AA1 S P EH0 K T'],
  "prosper": ['P R AA1 S P ER0'],
  "prospera": ['P R OW0 S P EH1 R AH0'],
  "prospered": ['P R AA1 S P ER0 D'],
  "prosperi": ['P R OW0 S P EH1 R IY0'],
  "prospering": ['P R AA1 S P ER0 IH0 NG'],
  "prosperity": ['P R AA0 S P EH1 R AH0 T IY0'],
  "prosperous": ['P R AA1 S P ER0 AH0 S'],
  "prospers": ['P R AA1 S P ER0 Z'],
  "pross": ['P R AO1 S'],
  "prosser": ['P R AO1 S ER0'],
  "prost": ['P R AA1 S T'],
  "prostaglandin": ['P R OW0 S T AE0 G L AE1 N D IH0 N'],
  "prostaglandins": ['P R OW0 S T AE0 G L AE1 N D IH0 N Z'],
  "prostate": ['P R AA1 S T EY2 T'],
  "prostatectomy": ['P R AA2 S T EY0 T EH1 K T AH0 M IY0'],
  "prostatic": ['P R OW0 S T AE1 T IH0 K'],
  "prostheses": ['P R AA0 S TH IY1 S IY0 Z'],
  "prosthesis": ['P R AO2 S TH EH1 S IH0 S', 'P R AO2 S TH IY1 S AH0 S'],
  "prosthetic": ['P R AA0 S TH EH1 T IH0 K'],
  "prosthetics": ['P R AA0 S TH EH1 T IH0 K S'],
  "prostitute": ['P R AA1 S T AH0 T UW2 T'],
  "prostitutes": ['P R AA1 S T AH0 T UW2 T S'],
  "prostitution": ['P R AA2 S T AH0 T UW1 SH AH0 N'],
  "prostrate": ['P R AA1 S T R EY0 T'],
  "prostration": ['P R AA0 S T R EY1 SH AH0 N'],
  "protagonist": ['P R OW0 T AE1 G AH0 N AH0 S T'],
  "protagonists": ['P R OW0 T AE1 G AH0 N AH0 S T S'],
  "protean": ['P R OW0 T IY1 AH0 N', 'P R OW1 T IY0 AH0 N'],
  "protease": ['P R OW1 T IY0 EY2 Z'],
  "protect": ['P R AH0 T EH1 K T'],
  "protected": ['P R AH0 T EH1 K T IH0 D'],
  "protectee": ['P R AH0 T EH1 K T IY0'],
  "protectees": ['P R AH0 T EH1 K T IY0 Z'],
  "protecting": ['P R AH0 T EH1 K T IH0 NG'],
  "protection": ['P R AH0 T EH1 K SH AH0 N'],
  "protectionism": ['P R AH0 T EH1 K SH AH0 N IH2 Z AH0 M'],
  "protectionist": ['P R AH0 T EH1 K SH AH0 N IH0 S T'],
  "protectionists": ['P R AH0 T EH1 K SH AH0 N IH0 S T S'],
  "protections": ['P R AH0 T EH1 K SH AH0 N Z'],
  "protective": ['P R AH0 T EH1 K T IH0 V'],
  "protectively": ['P R AH0 T EH1 K T IH0 V L IY0'],
  "protector": ['P R AH0 T EH1 K T ER0'],
  "protectorate": ['P R AH0 T EH1 K T ER0 AH0 T'],
  "protectors": ['P R AH0 T EH1 K T ER0 Z'],
  "protects": ['P R AH0 T EH1 K T S'],
  "protege": ['P R OW1 T AH0 ZH EY2'],
  "proteges": ['P R OW1 T IH0 ZH EY2 Z'],
  "protein": ['P R OW1 T IY2 N'],
  "protein's": ['P R OW1 T IY2 N Z'],
  "proteinaceous": ['P R OW2 T IH0 N EY1 SH AH0 S'],
  "proteins": ['P R OW1 T IY2 N Z'],
  "proteome": ['P R OW1 T IY2 OW1 M'],
  "protest": ['P R OW1 T EH2 S T', 'P R AH0 T EH1 S T'],
  "protestant": ['P R AA1 T AH0 S T AH0 N T'],
  "protestantism": ['P R AA1 T AH0 S T AH0 N T IH2 Z AH0 M'],
  "protestants": ['P R AA1 T AH0 S T AH0 N T S'],
  "protestation": ['P R OW2 T EH2 S T EY1 SH AH0 N'],
  "protestations": ['P R OW2 T EH2 S T EY1 SH AH0 N Z'],
  "protested": ['P R AH0 T EH1 S T IH0 D', 'P R OW1 T EH2 S T IH0 D'],
  "protester": ['P R OW1 T EH2 S T ER0'],
  "protesters": ['P R OW1 T EH2 S T ER0 Z'],
  "protesters'": ['P R OW1 T EH2 S T ER0 Z'],
  "protesting": ['P R AH0 T EH1 S T IH0 NG', 'P R OW1 T EH2 S T IH0 NG'],
  "protestors": ['P R OW1 T EH2 S T ER0 Z'],
  "protests": ['P R OW1 T EH2 S T S', 'P R OW1 T EH2 S', 'P R AH0 T EH1 S T S', 'P R AH0 T EH1 S'],
  "prothallus": ['P R OW2 TH AE1 L AH0 S'],
  "prothero": ['P R AA1 DH ER0 OW2'],
  "prothorax": ['P R OW0 TH AO1 R AE0 K S'],
  "prothro": ['P R OW1 TH R OW0'],
  "protigal": ['P R AA1 T IH0 G AH0 L'],
  "protium": ['P R OW1 T IY0 AH0 M'],
  "proto": ['P R OW1 T AH0'],
  "proto-stirrup": ['P R OW2 T OW1 S T ER1 AH0 P'],
  "protocol": ['P R OW1 T AH0 K AA2 L', 'P R OW1 T AH0 K AO2 L'],
  "protocols": ['P R OW1 T AH0 K AO2 L Z'],
  "protohistory": ['P R OW2 T OW0 HH IH1 S T ER0 IY0'],
  "proton": ['P R OW1 T AA2 N'],
  "proton's": ['P R OW1 T AA2 N Z'],
  "protons": ['P R OW1 T AA2 N Z'],
  "prototype": ['P R OW1 T AH0 T AY2 P'],
  "prototypes": ['P R OW1 T AH0 T AY2 P S'],
  "prototypical": ['P R OW2 T AH0 T IH1 P IH0 K AH0 L'],
  "prototyping": ['P R OW1 T OW0 T AY2 P IH0 NG'],
  "protozoa": ['P R OW2 T AH0 Z OW1 AH0'],
  "protozoan": ['P R OW2 T AH0 Z OW1 AH0 N'],
  "protozoans": ['P R OW2 T AH0 Z OW1 AH0 N Z'],
  "protract": ['P R OW0 T R AE1 K T', 'P R OW1 T R AE0 K T'],
  "protracted": ['P R OW0 T R AE1 K T IH0 D'],
  "protropin": ['P R AA1 T R AH0 P IH0 N', 'P R OW0 T R OW1 P IH0 N'],
  "protrude": ['P R OW0 T R UW1 D'],
  "protruding": ['P R OW0 T R UW1 D IH0 NG'],
  "protuberance": ['P R OW0 T UW1 B ER0 AH0 N S', 'P R AH0 T UW1 B ER0 AH0 N S'],
  "protuberances": ['P R OW0 T UW1 B ER0 AH0 N S AH0 Z', 'P R AH0 T UW1 B ER0 AH0 N S AH0 Z'],
  "protz": ['P R AA1 T S'],
  "protzman": ['P R AA1 T S M AH0 N'],
  "proud": ['P R AW1 D'],
  "prouder": ['P R AW1 D ER0'],
  "proudest": ['P R AW1 D AH0 S T'],
  "proudfit": ['P R AW1 D F IH2 T'],
  "proudfoot": ['P R AW1 D F UH2 T'],
  "proudfoot's": ['P R AW1 D F UH2 T S'],
  "proudly": ['P R AW1 D L IY0'],
  "prough": ['P R AW1'],
  "proulx": ['P R AW1 L K S'],
  "prouse": ['P R AW1 S'],
  "proust": ['P R AW1 S T', 'P R UW1 S T'],
  "prout": ['P R AW1 T'],
  "prouty": ['P R AW1 T IY0'],
  "provable": ['P R UW1 V AH0 B AH0 L'],
  "provance": ['P R OW1 V AH0 N S'],
  "provant": ['P R OW1 V AH0 N T'],
  "prove": ['P R UW1 V'],
  "proved": ['P R UW1 V D'],
  "proven": ['P R UW1 V AH0 N'],
  "provenance": ['P R AA1 V AH0 N AH0 N S'],
  "provence": ['P R OW1 V AH0 N S'],
  "provencher": ['P R AA1 V IH0 N CH ER0'],
  "provencio": ['P R OW2 V EH1 N S IY0 OW0'],
  "provenience": ['P R AH0 V EH1 N IY0 AH0 N S'],
  "proventus": ['P R OW2 V EH1 N T AH0 S'],
  "provenza": ['P R OW2 V EH1 N Z AH0'],
  "provenzano": ['P R OW2 V EH0 N Z AA1 N OW0'],
  "provera": ['P R OW2 V EH1 R AH0'],
  "proverb": ['P R AA1 V ER0 B'],
  "proverbial": ['P R AH0 V ER1 B IY0 AH0 L'],
  "proverbs": ['P R AA1 V ER0 B Z'],
  "proves": ['P R UW1 V Z'],
  "provide": ['P R AH0 V AY1 D'],
  "provided": ['P R AH0 V AY1 D IH0 D'],
  "providence": ['P R AA1 V AH0 D AH0 N S'],
  "providence's": ['P R AA1 V AH0 D AH0 N S IH0 Z'],
  "provideniya": ['P R OW2 V IH0 D EH1 N IH0 Y AH0'],
  "provident": ['P R AA1 V IH0 D AH0 N T'],
  "providential": ['P R AA2 V AH0 D EH1 N SH AH0 L'],
  "provider": ['P R AH0 V AY1 D ER0'],
  "providers": ['P R AH0 V AY1 D ER0 Z'],
  "provides": ['P R AH0 V AY1 D Z'],
  "providian": ['P R OW2 V IH1 D IY0 AH0 N'],
  "providing": ['P R AH0 V AY1 D IH0 NG'],
  "provigo": ['P R OW2 V IH1 G OW0'],
  "provigo's": ['P R OW0 V IY1 G OW0 Z'],
  "province": ['P R AA1 V AH0 N S', 'P R AA1 V IH0 N S'],
  "province's": ['P R AA1 V AH0 N S IH0 Z'],
  "provinces": ['P R AA1 V AH0 N S AH0 Z'],
  "provincetown": ['P R AA1 V AH0 N S T AW2 N'],
  "provincial": ['P R AH0 V IH1 N SH AH0 L'],
  "provincialism": ['P R AH0 V IH1 N SH AH0 L IH2 Z AH0 M'],
  "provincially": ['P R AH0 V IH1 N SH AH0 L IY0'],
  "provine": ['P R OW0 V IY1 N IY0'],
  "proving": ['P R UW1 V IH0 NG'],
  "provision": ['P R AH0 V IH1 ZH AH0 N'],
  "provisional": ['P R AH0 V IH1 ZH AH0 N AH0 L'],
  "provisionally": ['P R AH0 V IH1 ZH AH0 N AH0 L IY0', 'P R AH0 V IH1 ZH N AH0 L IY0'],
  "provisioning": ['P R AH0 V IH1 ZH AH0 N IH0 NG'],
  "provisions": ['P R AH0 V IH1 ZH AH0 N Z'],
  "proviso": ['P R AH0 V AY1 Z OW0'],
  "provisos": ['P R AH0 V AY1 Z OW0 Z'],
  "provo": ['P R OW1 V OW0'],
  "provocation": ['P R AA2 V AH0 K EY1 SH AH0 N'],
  "provocations": ['P R AA2 V AH0 K EY1 SH AH0 N Z'],
  "provocative": ['P R OW0 V AA1 K AH0 T IH0 V'],
  "provocatively": ['P R OW0 V AA1 K AH0 T IH0 V L IY0'],
  "provoke": ['P R AH0 V OW1 K'],
  "provoked": ['P R AH0 V OW1 K T'],
  "provokes": ['P R AH0 V OW1 K S'],
  "provoking": ['P R AH0 V OW1 K IH0 NG'],
  "provolone": ['P R OW1 V AH0 L OW0 N'],
  "provost": ['P R OW1 V OW0 S T'],
  "provosts": ['P R OW1 V OW0 S T S'],
  "provote": ['P R OW1 V OW0 T'],
  "provus": ['P R OW1 V AH0 S'],
  "prow": ['P R AW1'],
  "prowant": ['P R AW1 AH0 N T'],
  "prowell": ['P R AA1 W EH0 L'],
  "prowess": ['P R AW1 AH0 S'],
  "prowl": ['P R AW1 L'],
  "prowler": ['P R AW1 L ER0'],
  "prowling": ['P R AW1 L IH0 NG'],
  "prows": ['P R AW1 Z'],
  "prowse": ['P R AW1 Z'],
  "proxies": ['P R AA1 K S IY0 Z'],
  "proximal": ['P R AA1 K S AH0 M AH0 L'],
  "proximate": ['P R AA1 K S AH0 M AH0 T'],
  "proximity": ['P R AA0 K S IH1 M AH0 T IY0'],
  "proxmire": ['P R AA1 K S M AY2 R'],
  "proxy": ['P R AA1 K S IY0'],
  "prozac": ['P R OW1 Z AE2 K'],
  "pru": ['P R UW1'],
  "pru's": ['P R UW1 Z'],
  "prucapital": ['P R UW2 K AE1 P IH0 T AH0 L'],
  "prucha": ['P R AH1 CH AH0'],
  "prude": ['P R UW1 D'],
  "pruden": ['P R UW1 D AH0 N'],
  "prudence": ['P R UW1 D AH0 N S'],
  "prudency": ['P R UW1 D AH0 N S IY0'],
  "prudent": ['P R UW1 D AH0 N T'],
  "prudente": ['P R AH1 D IH0 N T'],
  "prudential": ['P R UW0 D EH1 N SH AH0 L'],
  "prudential's": ['P R UW0 D EH1 N SH AH0 L Z'],
  "prudentialbache": ['P R UW0 D EH2 N T IY0 AE1 L B AE0 CH', 'P R UW0 D EH1 N SH AH0 L B AE0 CH'],
  "prudently": ['P R UW1 D AH0 N T L IY0'],
  "prudentrust": ['P R UW1 D AH0 N T R AH2 S T'],
  "prudery": ['P R UW1 D ER0 IY0'],
  "prudhoe": ['P R AH1 D HH OW2'],
  "prudhomme": ['P R AH1 D HH AH0 M'],
  "prudish": ['P R UW1 D IH0 SH'],
  "prudit": ['P R UW1 D IH0 T'],
  "prue": ['P R UW1'],
  "pruer": ['P R UW1 ER0'],
  "pruess": ['P R UW1 S'],
  "prueter": ['P R UH1 T ER0'],
  "pruett": ['P R UW1 T'],
  "pruette": ['P R UW0 EH1 T'],
  "prugh": ['P R UW1'],
  "pruiett": ['P R UW1 IH0 T'],
  "pruitt": ['P R UW1 IH0 T'],
  "prune": ['P R UW1 N'],
  "pruneau": ['P R AH0 N OW1'],
  "pruned": ['P R UW1 N D'],
  "pruneda": ['P R UW0 N EH1 D AH0'],
  "prunedale": ['P R UW1 N D EY2 L'],
  "prunella": ['P R UW0 N EH1 L AH0'],
  "pruner": ['P R UW1 N ER0'],
  "prunes": ['P R UW1 N Z'],
  "prunier": ['P R UW1 N IY0 ER0'],
  "pruning": ['P R UW1 N IH0 NG'],
  "prunskiene": ['P R UW1 N S K IY2 N'],
  "prunty": ['P R AH1 N T IY0'],
  "prurient": ['P R UH1 R IY0 AH0 N T'],
  "prus": ['P R AH1 S'],
  "prusak": ['P R UW1 S AH0 K'],
  "prusha": ['P R AH1 SH AH0'],
  "prusinski": ['P R AH0 S IH1 N S K IY0'],
  "pruski": ['P R AH1 S K IY0'],
  "pruss": ['P R AH1 S'],
  "prussak": ['P R AH1 S AH0 K'],
  "prussia": ['P R AH1 SH AH0'],
  "prussian": ['P R AH1 SH AH0 N'],
  "prust": ['P R AH1 S T'],
  "prutzman": ['P R AH1 T S M AH0 N'],
  "pruyn": ['P R AY1 N'],
  "pry": ['P R AY1'],
  "pryce": ['P R AY1 S'],
  "pryde": ['P R AY1 D'],
  "pryer": ['P R AY1 ER0'],
  "prying": ['P R AY1 IH0 NG'],
  "prynne": ['P R IH1 N'],
  "pryor": ['P R AY1 ER0'],
  "pryor's": ['P R AY1 ER0 Z'],
  "przybocki": ['P R AH0 Z B AA1 K IY0'],
  "przybyl": ['P ER0 Z IH1 B AH0 L'],
  "przybyla": ['P ER2 Z AH0 B IH1 L AH0'],
  "przybylski": ['P ER2 Z AH0 B IH1 L S K IY0'],
  "przybysz": ['P ER0 Z IH1 B IH0 SH'],
  "przywara": ['P ER0 Z AH0 V AA1 R AH0'],
  "psalm": ['S AA1 L M', 'S AA1 M'],
  "psalms": ['S AA1 L M Z', 'S AA1 M Z'],
  "psalter": ['S AO1 L T ER0'],
  "psalters": ['S AO1 L T ER0 Z'],
  "psarouthakis": ['S EH2 R UW0 TH AA1 K IH0 S'],
  "psencik": ['S EH1 N S IH0 K'],
  "pseudo": ['S UW1 D OW0'],
  "pseudomonas": ['S UW2 D AH0 M OW1 N AH0 Z'],
  "pseudonym": ['S UW1 D AH0 N IH2 M'],
  "pseudonyms": ['S UW1 D AH0 N IH2 M Z'],
  "pseudopodial": ['S UW2 D AH0 P OW1 D IY0 AH0 L'],
  "pseudoscience": ['S UW2 D OW0 S AY1 AH0 N S'],
  "pseudoscientific": ['S UW2 D OW0 S AY2 AH0 N T IH1 F IH0 K'],
  "pshaw": ['P SH AW1'],
  "pshew": ['P SH UW1'],
  "psi": ['S AY1'],
  "psilocybin": ['S AY2 L AH0 S AY1 B AH0 N'],
  "psoriasis": ['S ER0 AY1 AH0 S AH0 S'],
  "psych": ['S AY1 K'],
  "psyche": ['S AY1 K IY0'],
  "psyched": ['S AY1 K T'],
  "psychedelic": ['S AY2 K AH0 D EH1 L IH0 K'],
  "psyches": ['S AY1 K IY0 Z'],
  "psychiatric": ['S AY2 K IY0 AE1 T R IH0 K'],
  "psychiatrist": ['S AH0 K AY1 AH0 T R AH0 S T'],
  "psychiatrist's": ['S AH0 K AY1 AH0 T R AH0 S T S'],
  "psychiatrists": ['S AH0 K AY1 AH0 T R AH0 S T S'],
  "psychiatry": ['S AY0 K AY1 AH0 T R IY0', 'S IH0 K AY1 AH0 T R IY0'],
  "psychic": ['S AY1 K IH0 K'],
  "psychical": ['S AY1 K IH0 K AH0 L'],
  "psychics": ['S AY1 K IH0 K S'],
  "psycho": ['S AY1 K OW0'],
  "psycho-social": ['S AY1 K OW0 S OW2 SH AH0 L'],
  "psychoanalysis": ['S AY2 K OW0 AH0 N AE1 L AH0 S AH0 S', 'S AY2 K OW0 AH0 N AE1 L IH0 S IH0 S'],
  "psychoanalyst": ['S AY2 K OW0 AE1 N AH0 L AH0 S T'],
  "psychoanalytic": ['S AY2 K OW0 AE2 N AH0 L IH1 T IH0 K'],
  "psychobabble": ['S AY2 K OW0 B AE1 B AH0 L'],
  "psychodynamic": ['S AY2 K OW0 D AY0 N AE1 M IH0 K'],
  "psychogenic": ['S AY2 K OW0 JH EH1 N IH0 K'],
  "psychokinesis": ['S AY2 K OW0 K AH0 N IY1 S AH0 S'],
  "psychological": ['S AY2 K AH0 L AA1 JH IH0 K AH0 L'],
  "psychologically": ['S AY2 K AH0 L AA1 JH IH0 K L IY0'],
  "psychologist": ['S AY0 K AA1 L AH0 JH AH0 S T'],
  "psychologists": ['S AY0 K AA1 L AH0 JH AH0 S T S'],
  "psychology": ['S AY0 K AA1 L AH0 JH IY0'],
  "psychopath": ['S AY1 K OW0 P AE2 TH'],
  "psychopathic": ['S AY2 K AH0 P AE1 TH IH0 K'],
  "psychopathologies": ['S AY2 K AH0 P AE0 TH AO1 L AH0 G IY2 Z'],
  "psychopathology": ['S AY2 K AH0 P AE0 TH AO1 L AH0 G IY2'],
  "psychopaths": ['S AY1 K OW0 P AE2 TH S'],
  "psychopathy": ['S AY0 K AA1 P AH0 TH IY0'],
  "psychosis": ['S AY0 K OW1 S AH0 S'],
  "psychosocial": ['S AY1 K OW0 S OW2 SH AH0 L'],
  "psychosomatic": ['S AY0 K OW2 S AH0 M AE1 T IH0 K'],
  "psychotherapeutic": ['S AY2 K OW0 TH EH2 R AH0 P Y UW1 T IH0 K'],
  "psychotherapist": ['S AY2 K AH0 TH EH1 R AH0 P IH0 S T'],
  "psychotherapists": ['S AY2 K AH0 TH EH1 R AH0 P IH0 S T S'],
  "psychotherapy": ['S AY2 K OW0 TH EH1 R AH0 P IY0'],
  "psychotic": ['S AY2 K AA1 T IH0 K'],
  "psyllium": ['S IH1 L IY0 AH0 M'],
  "ptacek": ['T AA1 CH EH2 K'],
  "ptak": ['T AE1 K'],
  "ptolemaic": ['T AA2 L AH0 M EY1 IH0 K'],
  "ptolemy": ['T AA1 L AH0 M IY0'],
  "ptomaine": ['T OW1 M EY0 N'],
  "ptomaines": ['T OW1 M EY0 N Z'],
  "ptovsky": ['P AH0 T AO1 V S K IY0'],
  "ptsd": ['P IY2 T IY1 EH2 S D IY1'],
  "pty": ['T AY1'],
  "ptyon": ['T AY1 AO0 N'],
  "pu": ['P UW1'],
  "puat": ['P Y UW1 AE0 T'],
  "pub": ['P AH1 B'],
  "pubco": ['P AH1 B K OW0'],
  "puberty": ['P Y UW1 B ER0 T IY0'],
  "pubic": ['P Y UW1 B IH0 K'],
  "public": ['P AH1 B L IH0 K'],
  "public's": ['P AH1 B L IH0 K S'],
  "public-spirit": ['P AH1 B L IH0 K S P IH1 R IH0 T'],
  "public-spirited": ['P AH1 B L IH0 K S P IH1 R IH0 T IH0 D'],
  "public-spiritedness": ['P AH2 B L IH0 K S P IH1 R IH0 T IH2 D N AH0 S'],
  "publically": ['P AH1 B L IH0 K L IY0'],
  "publication": ['P AH2 B L IH0 K EY1 SH AH0 N'],
  "publication's": ['P AH2 B L IH0 K EY1 SH AH0 N Z'],
  "publications": ['P AH2 B L IH0 K EY1 SH AH0 N Z'],
  "publications'": ['P AH2 B L IH0 K EY1 SH AH0 N Z'],
  "publicis": ['P AH0 B L IY1 S IH0 S'],
  "publicist": ['P AH1 B L IH0 S IH0 S T'],
  "publicists": ['P AH1 B L IH0 S IH0 S T S'],
  "publicity": ['P AH0 B L IH1 S AH0 T IY0', 'P AH0 B L IH1 S IH0 T IY0'],
  "publicize": ['P AH1 B L IH0 S AY2 Z'],
  "publicized": ['P AH1 B L IH0 S AY2 Z D'],
  "publicizing": ['P AH1 B L AH0 S AY2 Z IH0 NG'],
  "publicker": ['P AH1 B L IH0 K ER0'],
  "publicly": ['P AH1 B L IH0 K L IY0'],
  "publics": ['P AH1 B L IH0 K S'],
  "publish": ['P AH1 B L IH0 SH'],
  "published": ['P AH1 B L IH0 SH T'],
  "publisher": ['P AH1 B L IH0 SH ER0'],
  "publisher's": ['P AH1 B L IH0 SH ER0 Z'],
  "publishers": ['P AH1 B L IH0 SH ER0 Z'],
  "publishers'": ['P AH1 B L IH0 SH ER0 Z'],
  "publishes": ['P AH1 B L IH0 SH AH0 Z', 'P AH1 B L IH0 SH IH0 Z'],
  "publishing": ['P AH1 B L IH0 SH IH0 NG'],
  "publishing's": ['P AH1 B L IH0 SH IH0 NG Z'],
  "publishings": ['P AH1 B L IH0 SH IH0 NG Z'],
  "publitech": ['P AH1 B L IH0 T EH2 K'],
  "publix": ['P AH1 B L IH0 K S'],
  "pubs": ['P AH1 B Z'],
  "pucci": ['P UW1 CH IY0'],
  "pucciarelli": ['P UW0 CH ER0 EH1 L IY0'],
  "puccinelli": ['P UW0 CH IY0 N EH1 L IY0'],
  "puccini": ['P UW0 CH IY1 N IY0'],
  "puccio": ['P UW1 CH IY0 OW0'],
  "puchalski": ['P AH0 HH AA1 L S K IY0'],
  "puche": ['P UW1 CH'],
  "pucillo": ['P UW0 CH IH1 L OW0'],
  "puck": ['P AH1 K'],
  "pucker": ['P AH1 K ER0'],
  "puckett": ['P AH1 K IH0 T'],
  "puckish": ['P AH1 K IH0 SH'],
  "pudding": ['P UH1 D IH0 NG'],
  "puddings": ['P UH1 D IH0 NG Z'],
  "puddle": ['P AH1 D AH0 L'],
  "puddles": ['P AH1 D AH0 L Z'],
  "puder": ['P Y UW1 D ER0'],
  "pudgie": ['P AH1 JH IY0'],
  "pudgie's": ['P AH1 JH IY0 Z'],
  "pudgies": ['P AH1 JH IY0 Z'],
  "pudgy": ['P AH1 JH IY0'],
  "pudlo": ['P AH1 D L OW0'],
  "pudong": ['P Y UW1 D AO1 NG', 'P UW1 D AO1 NG'],
  "pudwill": ['P AH0 D W IH1 L'],
  "puebla": ['P W EY1 B L AH0'],
  "pueblo": ['P W EH1 B L OW0'],
  "puente": ['P UW1 N T'],
  "puentes": ['P W EH1 N T EH0 S'],
  "puerile": ['P Y UW0 R IY1 L'],
  "puerto": ['P W EH1 R T OW2', 'P AO1 R T AH0', 'P AO1 R T ER0'],
  "puerto-rican": ['P W EH1 R T OW2 R IY1 K AH0 N', 'P AO1 R T AH0 R IY1 K AH0 N'],
  "puerto-ricans": ['P W EH1 R T OW2 R IY1 K AH0 N Z', 'P AO1 R T AH0 R IY1 K AH0 N Z'],
  "puerto-rico": ['P W EH1 R T OW2 R IY1 K OW0', 'P AO1 R T AH0 R IY1 K OW0', 'P AO1 R T ER0 R IY1 K OW0'],
  "puett": ['P UW1 T'],
  "puetz": ['P UW1 T S'],
  "pufahl": ['P Y UW1 F AA0 L'],
  "puff": ['P AH1 F'],
  "puffed": ['P AH1 F T'],
  "puffenbarger": ['P AH1 F IH0 N B AA0 R G ER0'],
  "puffer": ['P AH1 F ER0'],
  "puffer's": ['P AH1 F ER0 Z'],
  "puffery": ['P AH1 F ER0 IY0'],
  "puffin": ['P AH1 F IH2 N'],
  "puffing": ['P AH1 F IH0 NG'],
  "puffins": ['P AH1 F IH2 N Z'],
  "puffs": ['P AH1 F S'],
  "puffy": ['P AH1 F IY0'],
  "pug": ['P AH1 G'],
  "puga": ['P Y UW1 G AH0'],
  "puget": ['P Y UW1 JH IH0 T'],
  "pugh": ['P Y UW1'],
  "pugh's": ['P Y UW1 Z'],
  "pughs": ['P Y UW1 Z'],
  "pugilism": ['P Y UW1 JH AH0 L IH0 Z AH0 M'],
  "pugilist": ['P Y UW1 JH AH0 L IH0 S T'],
  "pugilistic": ['P Y UW2 JH AH0 L IH1 S T IH0 K'],
  "puglia": ['P AH1 G L IY0 AH0'],
  "pugliese": ['P UW0 G L IY0 EY1 Z IY0'],
  "puglisi": ['P UW0 G L IY1 S IY0'],
  "pugmire": ['P AH1 G M AY2 R'],
  "pugnacious": ['P AH0 G N AE1 SH IH0 S'],
  "pugsley": ['P AH1 G S L IY0'],
  "pugwash": ['P AH1 G W AA2 SH'],
  "puhl": ['P AH1 L'],
  "puig": ['P UW1 G'],
  "puipoe": ['P UW1 IY0 P OW2', 'P W IY1 P OW0'],
  "pujol": ['P Y UW1 JH AH0 L'],
  "puke": ['P Y UW1 K'],
  "pukes": ['P Y UW1 K S'],
  "puking": ['P Y UW1 K IH2 NG'],
  "pulaski": ['P Y UW0 L AE1 S K IY2'],
  "pulcini": ['P UW0 L CH IY1 N IY2'],
  "puleo": ['P UW1 L IY0 OW0'],
  "pulford": ['P UH1 L F ER0 D'],
  "pulice": ['P UW1 L IH0 S'],
  "pulido": ['P UW0 L IY1 D OW0'],
  "pulitzer": ['P UH1 L AH0 T S ER0'],
  "pulkrabek": ['P AH1 L K R AH0 B IH0 K'],
  "pull": ['P UH1 L'],
  "pullam": ['P UH1 L AH0 M'],
  "pullan": ['P UH1 L AH0 N'],
  "pullano": ['P UW0 L AA1 N OW0'],
  "pullara": ['P UW0 L AA1 R AH0'],
  "pullback": ['P UH1 L B AE2 K'],
  "pullbacks": ['P UH1 L B AE2 K S'],
  "pulled": ['P UH1 L D'],
  "pullen": ['P UH1 L AH0 N'],
  "puller": ['P UH1 L ER0'],
  "pulley": ['P UH1 L IY0'],
  "pulleys": ['P UH1 L IY0 Z'],
  "pulliam": ['P UH1 L IY0 AH0 M'],
  "pullin": ['P UH1 L IH0 N'],
  "pulling": ['P UH1 L IH0 NG'],
  "pullins": ['P UH1 L IH0 N Z'],
  "pullman": ['P UH1 L M AH0 N'],
  "pullman's": ['P UH1 L M AH0 N Z'],
  "pullout": ['P UH1 L AW2 T'],
  "pullouts": ['P UH1 L AW2 T S'],
  "pulls": ['P UH1 L Z'],
  "pullum": ['P UH1 L AH0 M'],
  "pulmazyme": ['P UH1 L M AH0 Z AY2 M'],
  "pulmazyme's": ['P UH1 L M AH0 Z AY2 M Z'],
  "pulmonary": ['P UH1 L M AH0 N EH2 R IY0'],
  "pulmozyme": ['P UH1 L M OW0 Z AY2 M'],
  "pulos": ['P UW1 L OW0 Z'],
  "pulp": ['P AH1 L P'],
  "pulping": ['P AH1 L P IH0 NG'],
  "pulpit": ['P UH1 L P IH0 T'],
  "pulpits": ['P UH1 L P IH0 T S'],
  "pulpwood": ['P AH1 L P W UH2 D'],
  "puls": ['P UH1 L Z'],
  "pulsar": ['P UH1 L S ER0'],
  "pulsar's": ['P UH1 L S ER0 Z'],
  "pulsating": ['P AH1 L S EY2 T IH0 NG'],
  "pulse": ['P AH1 L S'],
  "pulsed": ['P AH1 L S T'],
  "pulses": ['P AH1 L S IH0 Z'],
  "pulsifer": ['P AH1 L S IH0 F ER0'],
  "pulsing": ['P AH1 L S IH0 NG'],
  "pulsipher": ['P UH1 L S IH0 F ER0'],
  "pulte": ['P UH1 L T IY0'],
  "pultz": ['P UH1 L T S'],
  "pulver": ['P UH1 L V ER0'],
  "pulverize": ['P AH1 L V ER0 AY2 Z'],
  "pulverized": ['P AH1 L V ER0 AY2 Z D'],
  "pulvermacher": ['P AH1 L V ER0 M AH0 K ER0'],
  "puma": ['P UW1 M AH0'],
  "puma's": ['P Y UW1 M AH0 Z'],
  "pumice": ['P AH1 M AH0 S'],
  "pumilia": ['P UW0 M IY1 L IY0 AH0'],
  "puming": ['P UW1 M IH0 NG'],
  "pummel": ['P AH1 M AH0 L'],
  "pummeled": ['P AH1 M AH0 L D'],
  "pummeling": ['P AH1 M AH0 L IH0 NG'],
  "pummill": ['P AH1 M IH0 L'],
  "pump": ['P AH1 M P'],
  "pumped": ['P AH1 M P T'],
  "pumpernickel": ['P AH1 M P ER0 N IH2 K AH0 L'],
  "pumphrey": ['P AH1 M F R IY0'],
  "pumping": ['P AH1 M P IH0 NG'],
  "pumpkin": ['P AH1 M P K IH0 N', 'P AH1 M K IH0 N'],
  "pumpkins": ['P AH1 M P K AH0 N Z', 'P AH1 M K AH0 N Z'],
  "pumps": ['P AH1 M P S'],
  "pumsie": ['P AH1 M Z IY0'],
  "pun": ['P AH1 N'],
  "punch": ['P AH1 N CH'],
  "punched": ['P AH1 N CH T'],
  "punches": ['P AH1 N CH IH0 Z'],
  "punching": ['P AH1 N CH IH0 NG'],
  "punchline": ['P AH1 N CH L AY2 N'],
  "punchy": ['P AH1 N CH IY0'],
  "punctate": ['P AH1 N K T EY2 T'],
  "punctilious": ['P AH0 NG K T IH1 L IY0 AH0 S'],
  "punctual": ['P AH1 NG K CH UW0 AH0 L'],
  "punctuality": ['P AH2 NG K CH UW0 AE1 L IH0 T IY0'],
  "punctuate": ['P AH1 NG K CH UW0 EY2 T'],
  "punctuated": ['P AH1 NG K CH UW0 EY2 T IH0 D'],
  "punctuating": ['P AH1 NG K CH UW0 EY2 D IH0 NG'],
  "punctuation": ['P AH2 NG K CH UW0 EY1 SH AH0 N'],
  "puncture": ['P AH1 NG K CH ER0', 'P AH1 NG K SH ER0'],
  "punctured": ['P AH1 NG K CH ER0 D', 'P AH1 NG K SH ER0 D'],
  "punctures": ['P AH1 NG K CH ER0 Z', 'P AH1 NG K SH ER0 Z'],
  "puncturing": ['P AH1 NG K CH ER0 IH0 NG', 'P AH1 NG K SH ER0 IH0 NG'],
  "pundit": ['P AH1 N D AH0 T'],
  "punditry": ['P AH1 N D AH0 T R IY0'],
  "pundits": ['P AH1 N D IH0 T S'],
  "pundt": ['P AH1 N T'],
  "puneet": ['P UW2 N IY1 T'],
  "pung": ['P AH1 NG'],
  "pungent": ['P AH1 N JH AH0 N T'],
  "pungently": ['P AH1 N JH AH0 N T L IY0'],
  "punic": ['P Y UW1 N IH0 K'],
  "punish": ['P AH1 N IH0 SH'],
  "punishable": ['P AH1 N IH0 SH AH0 B AH0 L'],
  "punished": ['P AH1 N IH0 SH T'],
  "punisher": ['P AH1 N IH0 SH ER0'],
  "punishes": ['P AH1 N IH0 SH AH0 Z'],
  "punishing": ['P AH1 N IH0 SH IH0 NG'],
  "punishingly": ['P AH1 N IH0 SH IH0 NG L IY0'],
  "punishment": ['P AH1 N IH0 SH M AH0 N T'],
  "punishments": ['P AH1 N IH0 SH M AH0 N T S'],
  "punit": ['P AH1 N IH0 T'],
  "punitive": ['P Y UW1 N AH0 T IH0 V'],
  "punjab": ['P AH0 N JH AA1 B', 'P AH1 N JH AE2 B'],
  "punjab's": ['P AH0 N JH AA1 B Z', 'P AH1 N JH AE2 B Z'],
  "punk": ['P AH1 NG K'],
  "punkin": ['P AH1 NG K IH0 N'],
  "punks": ['P AH1 NG K S'],
  "punky": ['P AH1 NG K IY0'],
  "puns": ['P AH1 N Z'],
  "punsalan": ['P AH0 N S AA1 L AH0 N'],
  "punt": ['P AH1 N T'],
  "punta": ['P UH1 N T ER0'],
  "punter": ['P AH1 N T ER0'],
  "punto": ['P UW1 N T OW2'],
  "punts": ['P AH1 N T S'],
  "punxatauney": ['P AH2 NG K S AH0 T AA1 N IY0'],
  "punxsutawney": ['P AH2 NG K S AH0 T AW1 N IY2'],
  "puny": ['P Y UW1 N IY0'],
  "puopolo": ['P W OW0 P OW1 L OW0'],
  "pup": ['P AH1 P'],
  "pupa": ['P Y UW1 P AH0'],
  "pupil": ['P Y UW1 P AH0 L'],
  "pupils": ['P Y UW1 P AH0 L Z'],
  "pupo": ['P UW1 P OW0'],
  "puppet": ['P AH1 P AH0 T', 'P AH1 P IH0 T'],
  "puppeteer": ['P AH0 P AH0 T IY1 R'],
  "puppeteers": ['P AH0 P AH0 T IY1 R Z'],
  "puppetry": ['P AH1 P AH0 T R IY0'],
  "puppets": ['P AH1 P AH0 T S', 'P AH1 P IH0 T S'],
  "puppies": ['P AH1 P IY0 Z'],
  "puppy": ['P AH1 P IY0'],
  "pups": ['P AH1 P S'],
  "pur": ['P ER1'],
  "pura": ['P UH1 R AH0', 'P Y UH1 R AH0'],
  "purcell": ['P ER0 S EH1 L'],
  "purchase": ['P ER1 CH AH0 S'],
  "purchased": ['P ER1 CH AH0 S T'],
  "purchaser": ['P ER1 CH AH0 S ER0'],
  "purchaser's": ['P ER1 CH AH0 S ER0 Z'],
  "purchasers": ['P ER1 CH AH0 S ER0 Z'],
  "purchasers'": ['P ER1 CH AH0 S ER0 Z'],
  "purchases": ['P ER1 CH AH0 S AH0 Z', 'P ER1 CH AH0 S IH0 Z'],
  "purchasing": ['P ER1 CH AH0 S IH0 NG'],
  "purdham": ['P ER1 D AH0 M'],
  "purdie": ['P ER1 D IY0'],
  "purdin": ['P ER1 D IH0 N'],
  "purdom": ['P ER1 D AH0 M'],
  "purdon": ['P ER1 D AH0 N'],
  "purdue": ['P ER0 D UW1'],
  "purdum": ['P ER1 D AH0 M'],
  "purdy": ['P ER1 D IY0'],
  "pure": ['P Y UH1 R'],
  "purebred": ['P Y UH1 R B R EH1 D'],
  "purebreds": ['P Y UH1 R B R EH1 D Z'],
  "puree": ['P Y UH0 R EY1'],
  "purely": ['P Y UH1 R L IY0'],
  "purer": ['P Y UH1 R ER0'],
  "purest": ['P Y UH1 R AH0 S T'],
  "purex": ['P Y UH1 R EH0 K S'],
  "purgason": ['P ER1 G AH0 S AH0 N'],
  "purgatory": ['P ER1 G AH0 T AO2 R IY0'],
  "purge": ['P ER1 JH'],
  "purged": ['P ER1 JH D'],
  "purges": ['P ER1 JH IH0 Z'],
  "purging": ['P ER1 JH IH0 NG'],
  "puri": ['P UH1 R IY0'],
  "puricelli": ['P UH0 R IY0 CH EH1 L IY0'],
  "purification": ['P Y UH2 R AH0 F AH0 K EY1 SH AH0 N'],
  "purified": ['P Y UH1 R AH0 F AY2 D'],
  "purifier": ['P Y UH1 R AH0 F AY2 ER0'],
  "purifiers": ['P Y UH1 R AH0 F AY2 ER0 Z'],
  "purifoy": ['P Y UW1 R IH0 F OY0'],
  "purify": ['P Y UH1 R AH0 F AY2'],
  "purifying": ['P Y UH1 R AH0 F AY2 IH0 NG'],
  "purile": ['P Y UW1 R AH0 L'],
  "purim": ['P UH1 R AH0 M', 'P UH1 R IY0 M'],
  "purina": ['P Y UH0 R IH1 N AH0', 'P ER0 IY1 N AH0', 'P Y UH0 R IY1 N AH0'],
  "purington": ['P Y UH1 R IH0 NG T AH0 N'],
  "purinton": ['P Y UW1 R IH0 N T AH0 N'],
  "puris": ['P Y UH1 R IH0 S'],
  "purist": ['P Y UH1 R IH0 S T'],
  "puristic": ['P Y UH0 R IH1 S T IH0 K'],
  "purists": ['P Y UH1 R IH0 S T S'],
  "puritan": ['P Y UH1 R AH0 T AH0 N'],
  "puritanical": ['P Y UH2 R AH0 T AE1 N IH0 K AH0 L'],
  "puritanism": ['P Y UH1 R AH0 T AH0 N IH2 Z AH0 M'],
  "puritanisms": ['P Y UH1 R AH0 T AH0 N IH2 Z AH0 M Z'],
  "puritans": ['P Y UH1 R AH0 T AH0 N Z'],
  "purity": ['P Y UH1 R AH0 T IY0', 'P Y UH1 R IH0 T IY0'],
  "purk": ['P ER1 K'],
  "purkey": ['P ER1 K IY0'],
  "purloin": ['P ER0 L OY1 N'],
  "purloined": ['P ER0 L OY1 N D'],
  "purnell": ['P ER1 N AH0 L'],
  "purolator": ['P Y UH1 R AH0 L EY0 T ER0'],
  "purolator's": ['P Y UH1 R AH0 L EY0 T ER0 Z'],
  "purpa": ['P ER1 P AH0'],
  "purple": ['P ER1 P AH0 L'],
  "purples": ['P ER1 P AH0 L Z'],
  "purplish": ['P ER1 P L IH0 SH'],
  "purport": ['P ER1 P AO2 R T', 'P ER0 P AO1 R T'],
  "purported": ['P ER0 P AO1 R T IH0 D'],
  "purportedly": ['P ER0 P AO1 R T IH0 D L IY2'],
  "purporting": ['P ER0 P AO1 R T IH0 NG'],
  "purports": ['P ER0 P AO1 R T S'],
  "purpose": ['P ER1 P AH0 S'],
  "purposeful": ['P ER1 P AH0 S F AH0 L'],
  "purposefully": ['P ER1 P AH0 S F AH0 L IY0'],
  "purposeless": ['P ER1 P AH0 S L AH0 S'],
  "purposely": ['P ER1 P AH0 S L IY0'],
  "purposes": ['P ER1 P AH0 S AH0 Z', 'P ER1 P AH0 S IH0 Z'],
  "purr": ['P ER1'],
  "purring": ['P ER1 IH0 NG'],
  "purrington": ['P ER1 IH0 NG T AH0 N'],
  "purrs": ['P ER1 Z'],
  "purse": ['P ER1 S'],
  "pursed": ['P ER1 S T'],
  "pursel": ['P ER1 S AH0 L'],
  "pursell": ['P ER1 S AH0 L'],
  "purser": ['P ER1 S ER0'],
  "purses": ['P ER1 S IH0 Z'],
  "pursifull": ['P ER1 S IH0 F AH0 L'],
  "pursley": ['P ER1 S L IY0'],
  "pursuant": ['P ER0 S UW1 AH0 N T'],
  "pursue": ['P ER0 S UW1'],
  "pursued": ['P ER0 S UW1 D'],
  "pursuer": ['P ER0 S UW1 ER0'],
  "pursuers": ['P ER0 S UW1 ER0 Z'],
  "pursues": ['P ER0 S UW1 Z'],
  "pursueth": ['P ER0 S UW1 AH0 TH'],
  "pursuing": ['P ER0 S UW1 IH0 NG'],
  "pursuit": ['P ER0 S UW1 T'],
  "pursuits": ['P ER0 S UW1 T S'],
  "purt": ['P ER1 T'],
  "purtee": ['P ER1 T IY0'],
  "purtell": ['P ER1 T AH0 L'],
  "purtle": ['P ER1 T AH0 L'],
  "purulent": ['P Y UH1 R UW0 L AH0 N T'],
  "purves": ['P ER1 V Z'],
  "purvey": ['P ER0 V EY1'],
  "purveyed": ['P ER0 V EY1 D'],
  "purveying": ['P ER0 V EY1 IH0 NG'],
  "purveyor": ['P ER0 V EY1 ER0'],
  "purveyors": ['P ER0 V EY1 ER0 Z'],
  "purveys": ['P ER0 V EY1 Z'],
  "purviance": ['P UH0 R V IY1 AH0 N S'],
  "purview": ['P ER1 V Y UW2'],
  "purvin": ['P ER1 V IH0 N'],
  "purvis": ['P ER1 V IH0 S'],
  "puryear": ['P ER2 Y IH1 R'],
  "pus": ['P AH1 S'],
  "pusan": ['P UW1 S AA0 N'],
  "pusant": ['P Y UW1 S AA0 N T'],
  "pusateri": ['P UW0 S AA0 T EH1 R IY0'],
  "pusch": ['P AH1 SH'],
  "pusey": ['P Y UW1 Z IY0'],
  "push": ['P UH1 SH'],
  "push-up": ['P UH1 SH AH2 P'],
  "push-ups": ['P UH1 SH AH2 P S'],
  "pushed": ['P UH1 SH T'],
  "pusher": ['P UH1 SH ER0'],
  "pushers": ['P UH1 SH ER0 Z'],
  "pushes": ['P UH1 SH AH0 Z', 'P UH1 SH IH0 Z'],
  "pushing": ['P UH1 SH IH0 NG'],
  "pushkin": ['P UH1 SH K IH0 N'],
  "pushover": ['P UH1 SH OW2 V ER0'],
  "pushup": ['P UH1 SH AH2 P'],
  "pushups": ['P UH1 SH AH2 P S'],
  "pushy": ['P UH1 SH IY0'],
  "pusillanimous": ['P Y UW0 S AH0 L AE1 N AH0 M AH0 S'],
  "puskar": ['P AH1 S K ER0'],
  "puskarich": ['P AH1 S K ER0 IH0 K'],
  "puskas": ['P AH1 S K AH0 Z'],
  "puss": ['P UH1 S', 'P AH1 S'],
  "pusses": ['P UH1 S IH0 Z', 'P AH1 S IH0 Z'],
  "pussies": ['P UH1 S IY0 Z'],
  "pussy": ['P UH1 S IY0'],
  "pussycat": ['P UH1 S IY0 K AE2 T'],
  "pussycats": ['P UH1 S IY0 K AE2 T S'],
  "pussyfoot": ['P UH1 S IY0 F UH1 T'],
  "pussyfooted": ['P UH1 S IY0 F UH1 T IH0 D'],
  "pussyfooting": ['P UH1 S IY0 F UH1 T IH0 NG'],
  "pussyfoots": ['P UH1 S IY0 F UH1 T S'],
  "pustejovsky": ['P AH0 S T EY0 AA1 V S K IY0'],
  "put": ['P UH1 T'],
  "put-on": ['P UH1 T AA1 N'],
  "put-ons": ['P UH1 T AA1 N Z'],
  "putative": ['P Y UW1 T AH0 T IH0 V'],
  "putco": ['P AH1 T K OW0', 'P UH1 T K OW0'],
  "putdown": ['P UH1 T D AW2 N'],
  "putdowns": ['P UH1 T D AW2 N Z'],
  "pute": ['P Y UW1 T'],
  "puterbaugh": ['P Y UW0 T ER1 B AO0'],
  "putes": ['P Y UW1 T S'],
  "puth": ['P UW1 TH'],
  "puthoff": ['P AH1 T HH AO2 F'],
  "putin": ['P UW1 T IH0 N'],
  "putman": ['P AH1 T M AH0 N'],
  "putnam": ['P AH1 T N AH0 M'],
  "putnam's": ['P AH1 T N AH0 M Z'],
  "putney": ['P AH1 T N IY0'],
  "putrid": ['P Y UW1 T R IH0 D'],
  "puts": ['P UH1 T S'],
  "putsch": ['P UH1 CH'],
  "putt": ['P AH1 T'],
  "puttable": ['P AH1 T AH0 B AH0 L'],
  "putted": ['P AH1 T IH0 D'],
  "puttenbay": ['P AH1 T IH0 N B EY2'],
  "putter": ['P AH1 T ER0'],
  "putterer": ['P AH1 T ER0 ER0'],
  "puttering": ['P AH1 T ER0 IH0 NG'],
  "putterman": ['P AH1 T ER0 M AH0 N'],
  "putters": ['P AH1 T ER0 Z'],
  "putting": ['P AH1 T IH0 NG', 'P UH1 T IH0 NG'],
  "puttnam": ['P AH1 T N AH0 M'],
  "puttnam's": ['P AH1 T N AH0 M Z'],
  "putts": ['P AH1 T S'],
  "putty": ['P AH1 T IY0'],
  "putz": ['P AH1 T S'],
  "putzier": ['P AH1 T Z IY0 ER0'],
  "puusepp": ['P UW1 Z AH0 P'],
  "puza": ['P UW1 Z AH0'],
  "puzio": ['P UW1 Z IY0 OW0'],
  "puzo": ['P UW1 Z OW0'],
  "puzzle": ['P AH1 Z AH0 L'],
  "puzzled": ['P AH1 Z AH0 L D'],
  "puzzlemaster": ['P AH1 Z AH0 L M AE2 S T ER0'],
  "puzzlement": ['P AH1 Z AH0 L M AH0 N T'],
  "puzzler": ['P AH1 Z L ER0'],
  "puzzles": ['P AH1 Z AH0 L Z'],
  "puzzling": ['P AH1 Z AH0 L IH0 NG', 'P AH1 Z L IH0 NG'],
  "puzzo": ['P UW1 Z OW0'],
  "pvc": ['P IY2 V IY2 S IY1'],
  "pyatt": ['P AY1 AH0 T'],
  "pyburn": ['P IH1 B ER0 N'],
  "pye": ['P AY1'],
  "pyeatt": ['P AY1 AH0 T'],
  "pygmalion": ['P IH2 G M EY1 L Y AH0 N'],
  "pygmies": ['P IH1 G M IY0 Z'],
  "pygmy": ['P IH1 G M IY0'],
  "pyhrric": ['P IH1 R IH0 K'],
  "pyka": ['P IH1 K AH0'],
  "pyke": ['P AY1 K'],
  "pyland": ['P AY1 L AH0 N D'],
  "pylant": ['P IH0 L AO1 N T', 'P IH0 L AE1 N T'],
  "pyle": ['P AY1 L'],
  "pyle's": ['P AY1 L Z'],
  "pyles": ['P AY1 L Z'],
  "pylon": ['P AY1 L AA2 N'],
  "pylons": ['P AY1 L AA2 N Z'],
  "pylori": ['P AY2 L AO1 R IY0'],
  "pylos": ['P AY1 L OW0 S'],
  "pymm": ['P IH1 M'],
  "pyne": ['P AY1 N'],
  "pynes": ['P AY1 N Z'],
  "pyongyang": ['P Y AO1 NG Y AE1 NG'],
  "pyongyang's": ['P Y AO1 NG Y AE1 NG Z'],
  "pyper": ['P AY1 P ER0'],
  "pyramid": ['P IH1 R AH0 M IH0 D'],
  "pyramidal": ['P ER0 AE1 M AH0 D AH0 L'],
  "pyramids": ['P IH1 R AH0 M IH0 D Z'],
  "pyre": ['P AY1 ER0'],
  "pyrena": ['P IH0 R IY1 N AH0'],
  "pyrenees": ['P IH1 R AH0 IY0 Z'],
  "pyrenees'": ['P IH1 R AH0 IY0 Z'],
  "pyres": ['P AY1 ER0 Z'],
  "pyrite": ['P AY1 R AY0 T'],
  "pyro": ['P AY1 R OW0'],
  "pyron": ['P IH1 R AH0 N'],
  "pyrotechnic": ['P AY2 R OW0 T EH1 K N IH0 K'],
  "pyrotechnics": ['P AY2 R OW0 T EH1 K N IH0 K S'],
  "pyroxene": ['P AY0 R AA1 K S IY0 N', 'P AY1 R AA0 K S IY2 N'],
  "pyrrhic": ['P IH1 R IH0 K'],
  "pysher": ['P IH1 SH ER0'],
  "pytel": ['P IH1 T AH0 L'],
  "pythagoras": ['P IH2 TH AH0 G AO1 R AA0 S'],
  "pythagorean": ['P IH2 TH AH0 G AO1 R IY0 AH0 N'],
  "pythagorus": ['P IH0 TH AE1 G AH0 R AH0 S'],
  "pythia": ['P IH1 TH IY0 AH0'],
  "python": ['P AY1 TH AA0 N'],
  "python's": ['P AY1 TH AA0 N Z'],
  "pyxis": ['P IH1 K S IH0 S'],
  "q": ['K Y UW1'],
  "q's": ['K Y UW1 Z'],
  "q.": ['K Y UW1'],
  "q.'s": ['K Y UW1 Z'],
  "q.s": ['K Y UW1 Z'],
  "qaeda": ['K AY1 D AA0'],
  "qana": ['K AA1 N AH0'],
  "qantas": ['K AE1 N T AH0 S', 'K AA1 N T AH0 S'],
  "qasr": ['K EY1 Z ER0', 'K Y UW1 EY1 EH1 S AA1 R'],
  "qatar": ['K AH2 T AA1 R'],
  "qawi": ['K AA1 W IY0'],
  "qi": ['K IY1'],
  "qian": ['K IY1 AA2 N', 'JH IH1 N'],
  "qiao": ['K IY2 AW1'],
  "qiaotou": ['K IY2 AW1 T UW2'],
  "qichen": ['K IY1 CH EH2 N'],
  "qin": ['K IH1 N'],
  "qing": ['K IH1 NG'],
  "qingdao": ['CH IH1 NG D AW1'],
  "qingming": ['K IH1 NG M IH1 NG'],
  "qintex": ['K IH1 N T EH2 K S'],
  "qiryat": ['K IH0 R Y AA1 T'],
  "qmax": ['K Y UW1 M AE2 K S'],
  "qom": ['K AA1 M'],
  "qu": ['K UW1'],
  "qua": ['K W AA1'],
  "quach": ['K W AA1 CH'],
  "quack": ['K W AE1 K'],
  "quackenbush": ['K W AE1 K AH0 N B UH2 SH'],
  "quackery": ['K W AE1 K ER0 IY0'],
  "quacks": ['K W AE1 K S'],
  "quad": ['K W AA1 D'],
  "quade": ['K W EY1 D'],
  "quadra": ['K W AE1 D R AH0'],
  "quadrant": ['K W AA1 D R AH0 N T'],
  "quadratic": ['K W AA0 D R AA1 T IH0 K'],
  "quadrennial": ['K W AA0 D R EH1 N IY0 AH0 L'],
  "quadrex": ['K W AA1 D R EH0 K S'],
  "quadriceps": ['K W AA1 D R AH0 S EH2 P S'],
  "quadriplegic": ['K W AA2 D R AH0 P L IY1 JH IH0 K'],
  "quadruple": ['K W AA0 D R UW1 P AH0 L'],
  "quadrupled": ['K W AA0 D R UW1 P AH0 L D'],
  "quadrupling": ['K W AA0 D R UW1 P AH0 L IH0 NG', 'K W AA0 D R UW1 P L IH0 NG'],
  "quads": ['K W AA1 D Z'],
  "quaeda": ['K EY2 D AA0'],
  "quaff": ['K W AA1 F'],
  "quaffed": ['K W AA1 F AH0 D'],
  "quaglia": ['K W AE1 G L IY0 AH0'],
  "quagmire": ['K W AE1 G M AY2 ER0'],
  "quahog": ['K W AA1 HH AO2 G'],
  "quai": ['K IY1', 'K EY1'],
  "quaid": ['K W EY1 D'],
  "quail": ['K W EY1 L'],
  "quails": ['K W EY1 L Z'],
  "quain": ['K W EY1 N'],
  "quaint": ['K W EY1 N T'],
  "quaintance": ['K W EY1 N T AH0 N S'],
  "quaintly": ['K W EY1 N T L IY0'],
  "quake": ['K W EY1 K'],
  "quake's": ['K W EY1 K S'],
  "quakenbush": ['K W AH0 K EH1 N B UH0 SH'],
  "quaker": ['K W EY1 K ER0'],
  "quaker's": ['K W EY1 K ER0 Z'],
  "quakers": ['K W EY1 K ER0 Z'],
  "quakes": ['K W EY1 K S'],
  "quaking": ['K W EY1 K IH0 NG'],
  "qual": ['K W AA1 L'],
  "qualcast": ['K W AA1 L K AE2 S T'],
  "qualcomm": ['K W AA1 L K AA2 M'],
  "qualcomm's": ['K W AA1 L K AA2 M Z'],
  "quale": ['K W EY1 L'],
  "qualex": ['K W AA1 L EH0 K S'],
  "qualey": ['K W EY1 L IY0'],
  "qualification": ['K W AA2 L AH0 F AH0 K EY1 SH AH0 N'],
  "qualifications": ['K W AA2 L AH0 F AH0 K EY1 SH AH0 N Z'],
  "qualified": ['K W AA1 L AH0 F AY2 D'],
  "qualifier": ['K W AA1 L AH0 F AY2 ER0'],
  "qualifiers": ['K W AA1 L AH0 F AY2 ER0 Z'],
  "qualifies": ['K W AA1 L AH0 F AY2 Z'],
  "qualify": ['K W AA1 L AH0 F AY2'],
  "qualifying": ['K W AA1 L AH0 F AY2 IH0 NG'],
  "qualitative": ['K W AA1 L AH0 T EY2 T IH0 V'],
  "qualitatively": ['K W AA2 L AH0 T EY1 T IH0 V L IY0'],
  "qualities": ['K W AA1 L AH0 T IY0 Z'],
  "quality": ['K W AA1 L AH0 T IY0'],
  "qualley": ['K W AO1 L IY0'],
  "qualls": ['K W AA1 L Z', 'K W EY1 L Z'],
  "qualms": ['K W AA1 M Z', 'K W AA1 L M Z'],
  "quam": ['K W AA1 M'],
  "quamme": ['K W AE1 M'],
  "quan": ['K W AO1 N'],
  "quandaries": ['K W AA1 N D ER0 IY0 Z'],
  "quandary": ['K W AA1 N D ER0 IY0', 'K W AA1 N D R IY0'],
  "quandt": ['K W AO1 N D T'],
  "quanex": ['K W AA1 N EH0 K S'],
  "quang": ['K W AO1 NG'],
  "quant": ['K W AE1 N T'],
  "quantico": ['K W AA1 N T IH0 K OW0'],
  "quantifiable": ['K W AA2 N T IH0 F AY1 AH0 B EH0 L'],
  "quantification": ['K W AA2 N T IH0 F AH0 K EY1 SH AH0 N'],
  "quantified": ['K W AA1 N T IH0 F AY2 D'],
  "quantify": ['K W AA1 N T IH0 F AY2'],
  "quantifying": ['K W AA1 N T IH0 F AY2 IH0 NG'],
  "quantitative": ['K W AA1 N T IH0 T EY2 T IH0 V'],
  "quantitatively": ['K W AA2 N T IH0 T EY1 T AH0 V L IY0'],
  "quantities": ['K W AA1 N T AH0 T IY0 Z'],
  "quantity": ['K W AA1 N T AH0 T IY0'],
  "quantum": ['K W AA1 N T AH0 M'],
  "quantum's": ['K W AA1 N T AH0 M Z'],
  "quaquil": ['K W AE1 K W IH0 L'],
  "quaranta": ['K W ER0 AE1 N T AH0'],
  "quarante": ['K W ER0 AA1 N T EY0'],
  "quarantine": ['K W AO1 R AH0 N T IY2 N'],
  "quarantined": ['K W AO1 R AH0 N T IY2 N D', 'K W AO1 R AH0 N T AY2 N D'],
  "quarantines": ['K W AO1 R AH0 N T IY2 N Z', 'K W AO1 R AH0 N T AY2 N Z'],
  "quarantining": ['K W AO1 R AH0 N T IY2 N IH0 NG', 'K W AO1 R AH0 N T AY2 N IH0 NG'],
  "quark": ['K W AA1 R K'],
  "quarks": ['K W AA1 R K S'],
  "quarles": ['K W AO1 R L Z'],
  "quarnstrom": ['K W AO1 R N S T R AH0 M'],
  "quarre": ['K W AA1 R'],
  "quarrel": ['K W AO1 R AH0 L'],
  "quarreled": ['K W AO1 R AH0 L D'],
  "quarreling": ['K W AA1 R AH0 L IH0 NG', 'K W AA1 R L IH0 NG'],
  "quarrels": ['K W AO1 R AH0 L Z'],
  "quarrelsome": ['K W AO1 R AH0 L S AH0 M'],
  "quarries": ['K W AO1 R IY0 Z'],
  "quarry": ['K W AO1 R IY0'],
  "quarrying": ['K W AO1 R IY0 IH0 NG'],
  "quart": ['K W AO1 R T'],
  "quartararo": ['K W AA0 R T AA0 R AA1 R OW0'],
  "quarter": ['K W AO1 R T ER0'],
  "quarter's": ['K W AO1 R T ER0 Z'],
  "quarterback": ['K W AO1 R T ER0 B AE2 K'],
  "quarterbacking": ['K W AO1 R T ER0 B AE2 K IH0 NG'],
  "quarterbacks": ['K W AO1 R T ER0 B AE2 K S'],
  "quarterdeck": ['K W AO1 R T ER0 D EH2 K'],
  "quarterly": ['K W AO1 R T ER0 L IY0'],
  "quarterman": ['K W AO1 R T ER0 M AH0 N'],
  "quartermaster": ['K W AO1 R T ER0 M AE2 S T ER0'],
  "quarters": ['K W AO1 R T ER0 Z'],
  "quarters'": ['K W AO1 R T ER0 Z'],
  "quarters's": ['K W AO1 R T ER0 Z'],
  "quartet": ['K W AO0 R T EH1 T'],
  "quartet's": ['K W AO0 R T EH1 T S'],
  "quartets": ['K W AO0 R T EH1 T S'],
  "quarteurlanc": ['K W AA2 R T ER0 L AE1 NG K'],
  "quartile": ['K W AO1 R T IH0 L'],
  "quarto": ['K W AO1 R T OW0'],
  "quarts": ['K W AO1 R T S'],
  "quartz": ['K W AO1 R T S'],
  "quasar": ['K W EY1 Z AA0 R'],
  "quash": ['K W AO1 SH'],
  "quashed": ['K W AO1 SH T'],
  "quashing": ['K W AO1 SH IH0 NG'],
  "quasi": ['K W AA1 S IY0'],
  "quasimodo": ['K AE0 Z IY0 M OW1 D OW0'],
  "quassia": ['K W AA1 SH IY0 AH0'],
  "quast": ['K W AA1 S T'],
  "quaternary": ['K W AA1 T ER0 N EH2 R IY0'],
  "quattlebaum": ['K W AO1 T AH0 L B AW0 M'],
  "quattro": ['K W AO1 T R OW0'],
  "quattrocchi": ['K W AA0 T R OW1 K IY0'],
  "quattrochi": ['K W AA0 T R OW1 K IY0'],
  "quattrone": ['K W AA0 T R OW1 N IY0'],
  "quave": ['K W EY1 V'],
  "quaver": ['K W EY1 V ER0'],
  "quavered": ['K W EY1 V ER0 D'],
  "quavering": ['K W EY1 V ER0 IH0 NG'],
  "quavers": ['K W EY1 V ER0 Z'],
  "quay": ['K IY1', 'K EY1'],
  "quay's": ['K IY1 Z', 'K EY1 Z'],
  "quaye": ['K EY1', 'K W EY1'],
  "quayle": ['K W EY1 L'],
  "quayle's": ['K W EY1 L Z'],
  "quayles": ['K W EY1 L Z'],
  "quays": ['K IY1 Z', 'K EY1 Z'],
  "quazulu": ['K W AA2 Z UW1 L UW0'],
  "quazulu's": ['K W AA2 Z UW1 L UW0 Z'],
  "qubilah": ['K UW1 B IH0 L AH0', 'K W AH1 B IH0 L AH0'],
  "quddus": ['K UW1 D UW2 S'],
  "que": ['K Y UW1'],
  "quealy": ['K W IY1 L IY0'],
  "queasiness": ['K W IY1 Z IY0 N AH0 S'],
  "queasy": ['K W IY1 Z IY0'],
  "queau": ['K W OW1'],
  "quebec": ['K W AH0 B EH1 K'],
  "quebec's": ['K W IH0 B EH1 K S'],
  "quebecker": ['K W AH0 B EH1 K ER0', 'K W EH1 B EH2 K ER0'],
  "quebeckers": ['K W EH1 B EH2 K ER0 Z', 'K W AH0 B EH1 K ER0 Z'],
  "quebecois": ['K W EH1 B AH0 S W AA2', 'K W EH1 B EH0 K W AA2'],
  "quebecoise": ['K EH1 B EH0 K W AA2'],
  "quebecor": ['K W EH1 B IH0 K AO0 R'],
  "quebedeaux": ['K W EH1 B IH0 D OW0'],
  "quechee": ['K W EH1 CH IY0'],
  "queen": ['K W IY1 N'],
  "queen's": ['K W IY1 N Z'],
  "queena": ['K W IY1 N AH0'],
  "queenan": ['K W IY1 N AH0 N'],
  "queener": ['K W IY1 N ER0'],
  "queenfish": ['K W IY1 N F IH2 SH'],
  "queenfish's": ['K W IY1 N F IH2 SH IH0 Z'],
  "queenie": ['K W IY1 N IY0'],
  "queens": ['K W IY1 N Z'],
  "queensberry": ['K W IY1 N Z B EH2 R IY0'],
  "queensland": ['K W IY1 N Z L AE2 N D'],
  "queensway": ['K W IY1 N Z W EY2'],
  "queer": ['K W IH1 R'],
  "queerer": ['K W IH1 R ER0'],
  "queers": ['K W IH1 R Z'],
  "quek": ['K W EH1 K'],
  "quel": ['K W EH1 L'],
  "quell": ['K W EH1 L'],
  "quelled": ['K W EH1 L D'],
  "quellette": ['K W IH0 L EH1 T'],
  "quelling": ['K W EH1 L IH0 NG'],
  "quello": ['K W EH1 L OW0'],
  "quemoy": ['K IY1 M OY2'],
  "quenby": ['K W EH1 N B IY0'],
  "quench": ['K W EH1 N CH'],
  "quencher": ['K W EH1 N CH ER0'],
  "quenchers": ['K W EH1 N CH ER0 Z'],
  "quenches": ['K W EH1 N CH AH0 Z'],
  "quenching": ['K W EH1 N CH IH0 NG'],
  "quennel": ['K W EH1 N AH0 L'],
  "quenneville": ['K W EY0 N EY0 V IH1 L'],
  "quentin": ['K W EH1 N T IH0 N'],
  "quenzer": ['K W EH1 N Z ER0'],
  "querida": ['K W EH0 R IY1 D AH0'],
  "queried": ['K W IH1 R IY0 D'],
  "queries": ['K W IH1 R IY0 Z'],
  "querns": ['K W ER1 N Z'],
  "querry": ['K W EH1 R IY0'],
  "querulous": ['K W EH1 R AH0 L AH0 S'],
  "query": ['K W IY1 R IY0'],
  "ques": ['K Y UW1 Z', 'K W EH1 S'],
  "quesada": ['K W EY0 S AA1 D AH0'],
  "quesenberry": ['K W IY1 Z AH0 N B EH0 R IY0'],
  "quesinberry": ['K W EH1 S IH0 N B EH0 R IY0'],
  "quesnel": ['K W EY0 S N EH1 L'],
  "quesnell": ['K W EY0 S N EY1 L'],
  "quest": ['K W EH1 S T'],
  "questar": ['K W EH1 S T ER0'],
  "questech": ['K W EH1 S T EH2 K'],
  "question": ['K W EH1 S CH AH0 N', 'K W EH1 SH AH0 N'],
  "question's": ['K W EH1 S CH AH0 N Z', 'K W EH1 SH AH0 N Z'],
  "question-mark": ['K W EH1 S CH AH0 N M AA1 R K'],
  "questionable": ['K W EH1 S CH AH0 N AH0 B AH0 L'],
  "questioned": ['K W EH1 S CH AH0 N D'],
  "questioner": ['K W EH1 S CH AH0 N ER0'],
  "questioners": ['K W EH1 S CH AH0 N ER0 Z'],
  "questioning": ['K W EH1 S CH AH0 N IH0 NG'],
  "questionings": ['K W EH1 S CH AH0 N IH0 NG Z'],
  "questionnaire": ['K W EH2 S CH AH0 N EH1 R'],
  "questionnaires": ['K W EH2 S CH AH0 N EH1 R Z'],
  "questions": ['K W EH1 S CH AH0 N Z'],
  "questions'": ['K W EH1 S CH AH0 N Z'],
  "questran": ['K W EH1 S T R AE2 N'],
  "questrom": ['K W EH1 S T R AH0 M'],
  "quests": ['K W EH1 S T S'],
  "quetzalcoatl": ['K EH1 T S AA0 L K W AO2 T L'],
  "queue": ['K Y UW1'],
  "queues": ['K Y UW1 Z'],
  "queuing": ['K Y UW1 IH0 NG'],
  "quevedo": ['K W EY0 V EY1 D OW0'],
  "quezada": ['K W EY0 Z AA1 D AH0'],
  "qui": ['K IY1'],
  "quibble": ['K W IH1 B AH0 L'],
  "quibbles": ['K W IH1 B AH0 L Z'],
  "quibbling": ['K W IH1 B AH0 L IH0 NG', 'K W IH1 B L IH0 NG'],
  "quiche": ['K IY1 SH'],
  "quick": ['K W IH1 K'],
  "quickel": ['K W IH1 K AH0 L'],
  "quicken": ['K W IH1 K AH0 N'],
  "quickened": ['K W IH1 K AH0 N D'],
  "quickening": ['K W IH1 K AH0 N IH0 NG'],
  "quickens": ['K W IH1 K AH0 N Z'],
  "quicker": ['K W IH1 K ER0'],
  "quickest": ['K W IH1 K IH0 S T'],
  "quickie": ['K W IH1 K IY0'],
  "quickly": ['K W IH1 K L IY0'],
  "quickness": ['K W IH1 K N AH0 S'],
  "quicksand": ['K W IH1 K S AE2 N D'],
  "quicksands": ['K W IH1 K S AE2 N D Z'],
  "quicksilver": ['K W IH1 K S IH1 L V ER0'],
  "quicktime": ['K W IH1 K T AY2 M'],
  "quid": ['K W IH1 D'],
  "quiescent": ['K W AY0 EH1 S AH0 N T'],
  "quiet": ['K W AY1 AH0 T'],
  "quieted": ['K W AY1 AH0 T IH0 D'],
  "quieter": ['K W AY1 AH0 T ER0'],
  "quietest": ['K W AY1 AH0 T AH0 S T'],
  "quieting": ['K W AY1 AH0 T IH0 NG'],
  "quietist": ['K W AY1 AH0 T AH0 S T'],
  "quietly": ['K W AY1 AH0 T L IY0'],
  "quietness": ['K W AY1 AH0 T N AH0 S'],
  "quiets": ['K W AY1 AH0 T S'],
  "quiett": ['K W IY1 T'],
  "quievoni": ['K W IH0 V AO1 N IY0'],
  "quigg": ['K W IH1 G'],
  "quiggle": ['K W IH1 G AH0 L'],
  "quigley": ['K W IH1 G L IY0'],
  "quijada": ['K W IY0 Y AA1 D AH0'],
  "quijano": ['K W IY0 Y AA1 N OW0'],
  "quik": ['K W IH1 K'],
  "quiksilver": ['K W IH1 K S IH1 L V ER0'],
  "quilali": ['K W IH0 L AA1 L IY0'],
  "quiles": ['K W IY1 L EH0 S'],
  "quilici": ['K W IY0 L IY1 CH IY0'],
  "quill": ['K W IH1 L'],
  "quillan": ['K W IH1 L AH0 N'],
  "quillen": ['K W IH1 L AH0 N'],
  "quillian": ['K W IH1 L Y AH0 N'],
  "quillin": ['K W IH1 L IH0 N'],
  "quilling": ['K W IH1 L IH0 NG'],
  "quillman": ['K W IY0 L M AE1 N'],
  "quillon": ['K W IH1 L AH0 N'],
  "quilmes": ['K W IH1 L M Z'],
  "quilt": ['K W IH1 L T'],
  "quilted": ['K W IH1 L T IH0 D'],
  "quilter": ['K W IH1 L T ER0'],
  "quilters": ['K W IH1 L T ER0 Z'],
  "quilting": ['K W IH1 L T IH0 NG'],
  "quilts": ['K W IH1 L T S'],
  "quilty": ['K W IH1 L T IY0'],
  "quimby": ['K W IH1 M B IY0'],
  "quin": ['K W IH1 N'],
  "quina": ['K W IY1 N AH0'],
  "quina's": ['K W IY1 N AH0 Z'],
  "quinby": ['K W IH1 N B IY0'],
  "quince": ['K W IH1 N S'],
  "quincey": ['K W IH1 N S IY0'],
  "quincy": ['K W IH1 N S IY0'],
  "quincy's": ['K W IH1 N S IY0 Z'],
  "quindlen": ['K W IH1 N D L AH0 N'],
  "quine": ['K W AY1 N'],
  "quinine": ['K W AY1 N AY2 N'],
  "quinlan": ['K W IH1 N L AH0 N'],
  "quinley": ['K W IH1 N L IY0'],
  "quinlin": ['K W IH1 N L IH0 N'],
  "quinlivan": ['K W IH1 N L IH0 V AH0 N'],
  "quinn": ['K W IH1 N'],
  "quinn's": ['K W IH1 N Z'],
  "quinnell": ['K W IH1 N AH0 L'],
  "quinney": ['K W IH1 N IY0'],
  "quinoa": ['K IY2 N OW1 AH0'],
  "quinobequin": ['K W IH2 N OW1 B IH0 K W IH0 N'],
  "quinoco": ['K W IH0 N OW1 K OW0'],
  "quinon": ['K W IH1 N AH0 N'],
  "quinones": ['K W IH0 N OW1 N Z'],
  "quinonez": ['K W IY0 N OW1 N EH0 Z'],
  "quint": ['K W IH1 N T'],
  "quinta": ['K W IH1 N T AH0'],
  "quintal": ['K W IH1 N T AH0 L'],
  "quintana": ['K W IY0 N T AE1 N AH0'],
  "quintanar": ['K W IH1 N T AH0 N ER0'],
  "quintanilla": ['K W IH2 N T AH0 N IH1 L AH0'],
  "quintela": ['K W IY0 N T EY1 L AH0'],
  "quinter": ['K W IH1 N T ER0'],
  "quintero": ['K W IY0 N T EH1 R OW0'],
  "quinteros": ['K W IY0 N T EH1 R OW0 Z'],
  "quintessence": ['K W IH0 N T EH1 S AH0 N S'],
  "quintessential": ['K W IH2 N T IH0 S EH1 N SH AH0 L'],
  "quintessentially": ['K W IH2 N T AH0 S EH1 N SH AH0 L IY0'],
  "quintet": ['K W IH0 N T EH1 T'],
  "quintile": ['K W IH1 N T IH0 L', 'K W IH1 N T AY2 L'],
  "quintin": ['K W IH1 N T IH0 N'],
  "quintina": ['K W IY0 N T IY1 N AH0'],
  "quinto": ['K W IH1 N T OW0'],
  "quinton": ['K W IH0 N T AO1 N'],
  "quints": ['K W IH1 N T S'],
  "quintuple": ['K W IH1 N T UW0 P AH0 L', 'K W IH2 N T AH1 P AH0 L'],
  "quintupled": ['K W IH0 N T Y UW1 P AH0 L D', 'K W IH2 N T AH1 P AH0 L D'],
  "quintuplet": ['K W IH1 N T AH1 P L AH0 T'],
  "quintuplets": ['K W IH1 N T AH1 P L AH0 T S'],
  "quip": ['K W IH1 P'],
  "quipp": ['K W IH1 P'],
  "quipped": ['K W IH1 P T'],
  "quips": ['K W IH1 P S'],
  "quipster": ['K W IH1 P S T ER0'],
  "quiram": ['K W IY1 R AH0 M'],
  "quire": ['K W AY1 R'],
  "quirin": ['K W IH1 R IH0 N'],
  "quiring": ['K W AY1 R IH0 NG'],
  "quirion": ['K W IH1 R IY0 AH0 N'],
  "quirk": ['K W ER1 K'],
  "quirke": ['K W ER1 K'],
  "quirks": ['K W ER1 K S'],
  "quirky": ['K W ER1 K IY0'],
  "quiroga": ['K W IH0 R OW1 G AH0'],
  "quiros": ['K W IH1 R OW0 Z'],
  "quiroz": ['K W IH1 R OW0 Z'],
  "quiry": ['K W AY1 R IY0'],
  "quisenberry": ['K W AY1 Z AH0 N B EH0 R IY0'],
  "quisling": ['K W IH1 Z L IH0 NG'],
  "quist": ['K W IH1 S T'],
  "quist's": ['K W IH1 S T S'],
  "quit": ['K W IH1 T'],
  "quite": ['K W AY1 T'],
  "quito": ['K W IY1 T OW0', 'K IY1 T OW0', 'K IY1 T OW2'],
  "quito's": ['K W IY1 T OW0 Z', 'K IY1 T OW0 Z', 'K IY1 T OW2 Z'],
  "quits": ['K W IH1 T S'],
  "quitter": ['K W IH1 T ER0'],
  "quitters": ['K W IH1 T ER0 Z'],
  "quitting": ['K W IH1 T IH0 NG'],
  "quiver": ['K W IH1 V ER0'],
  "quivering": ['K W IH1 V ER0 IH0 NG'],
  "quixote": ['K IY0 HH OW1 T IY0'],
  "quixotic": ['K W IH0 K S AA1 T IH0 K'],
  "quiz": ['K W IH1 Z'],
  "quizard": ['K W IH1 Z ER0 D'],
  "quizzed": ['K W IH1 Z D'],
  "quizzes": ['K W IH1 Z IH0 Z'],
  "quizzical": ['K W IH1 Z AH0 K AH0 L'],
  "quizzing": ['K W IH1 Z IH0 NG'],
  "quo": ['K W OW1'],
  "quod": ['K W AO1 D'],
  "quoin": ['K W OY1 N'],
  "quon": ['K W AA1 N'],
  "quora": ['K W AO1 R AH0'],
  "quorum": ['K W AO1 R AH0 M'],
  "quorums": ['K W AO1 R AH0 M Z'],
  "quota": ['K W OW1 T AH0'],
  "quotable": ['K W OW1 T AH0 B AH0 L'],
  "quotas": ['K W OW1 T AH0 Z'],
  "quotation": ['K W OW0 T EY1 SH AH0 N'],
  "quotations": ['K W OW0 T EY1 SH AH0 N Z'],
  "quote": ['K W OW1 T'],
  "quoted": ['K W OW1 T IH0 D'],
  "quotes": ['K W OW1 T S'],
  "quoth": ['K W OW1 TH'],
  "quotient": ['K W OW1 SH AH0 N T'],
  "quoting": ['K W OW1 T IH0 NG'],
  "quotron": ['K W OW1 T R AA0 N'],
  "quotron's": ['K W OW1 T R AA0 N Z'],
  "qureshey": ['K UH0 R EY1 SH EY0'],
  "qureshi": ['K UH0 R EY1 SH IY0'],
  "r": ['AA1 R'],
  "r's": ['AA1 R Z'],
  "r.": ['AA1 R'],
  "r.'s": ['AA1 R Z'],
  "r.s": ['AA1 R Z'],
  "ra": ['R AA1'],
  "raab": ['R AA1 B'],
  "raab's": ['R AA1 B Z'],
  "raabe": ['R AA1 B'],
  "raad": ['R AA1 D'],
  "raap": ['R AA1 P'],
  "raasch": ['R AA1 SH'],
  "raatz": ['R AA1 T S'],
  "rab": ['R AE1 B'],
  "rabago": ['R AA0 B AA1 G OW0'],
  "rabalais": ['R AE1 B AH0 L EY2'],
  "rabb": ['R AE1 B'],
  "rabbani": ['R AH0 B AE1 N IY0', 'R AH0 B AA1 N IY0'],
  "rabbi": ['R AE1 B AY2'],
  "rabbinic": ['R AH0 B IH1 N IH0 K'],
  "rabbinical": ['R AH0 B IH1 N IH0 K AH0 L'],
  "rabbis": ['R AE1 B AY2 Z'],
  "rabbit": ['R AE1 B AH0 T', 'R AE1 B IH0 T'],
  "rabbitears": ['R AE1 B IH0 T IY1 R Z'],
  "rabbitlike": ['R AE1 B AH0 T L AY2 K'],
  "rabbits": ['R AE1 B AH0 T S'],
  "rabbitt": ['R AE1 B IH0 T'],
  "rabble": ['R AE1 B AH0 L'],
  "rabe": ['R EY1 B'],
  "rabel": ['R AE1 B AH0 L'],
  "raben": ['R AE1 B AH0 N'],
  "rabenold": ['R AE1 B IH0 N OW2 L D'],
  "raber": ['R EY1 B ER0'],
  "rabes": ['R EY1 B IY0 Z'],
  "rabey": ['R EY1 B IY0'],
  "rabi": ['R AA1 B IY0'],
  "rabid": ['R AE1 B IH0 D', 'R EY1 B IH0 D'],
  "rabideau": ['R AE1 B IH0 D OW2'],
  "rabies": ['R EY1 B IY0 Z'],
  "rabin": ['R AA2 B IY1 N'],
  "rabin's": ['R AA2 B IY1 N Z'],
  "rabine": ['R AH0 B IY1 N'],
  "rabiner": ['R AH0 B IY1 N ER0'],
  "rabinovich": ['R AH0 B IH1 N AH0 V IH0 CH'],
  "rabinovitz": ['R AH0 B IH1 N AH0 V IH0 T S'],
  "rabinowitz": ['R AH0 B IH1 N AH0 W IH0 T S'],
  "rabkin": ['R AE1 B K IH0 N'],
  "rabobank": ['R AA1 B OW0 B AE2 NG K'],
  "raboin": ['R AH0 B OY1 N'],
  "rabold": ['R AE1 B OW0 L D'],
  "rabon": ['R AA0 B AO1 N'],
  "raborn": ['R AE1 B ER0 N'],
  "rabourn": ['R AH0 B UH1 R N'],
  "raboy": ['R AE1 B OY0'],
  "rabuck": ['R AE1 B AH0 K'],
  "rabuka": ['R AH0 B UW1 K AH0'],
  "rabun": ['R AE1 B AH0 N'],
  "raburn": ['R AE1 B ER0 N'],
  "raby": ['R EY1 B IY0'],
  "racal": ['R AE1 K AH0 L'],
  "racamier": ['R AE1 K AH0 M AY2 R', 'R AH0 K EY1 M Y ER0'],
  "racanelli": ['R AA0 K AA0 N EH1 L IY0'],
  "racca": ['R AE1 K AH0'],
  "raccoon": ['R AE0 K UW1 N'],
  "raccoons": ['R AE0 K UW1 N Z'],
  "race": ['R EY1 S'],
  "race's": ['R EY1 S IH0 Z'],
  "raced": ['R EY1 S T'],
  "racehorse": ['R EY1 S HH AO2 R S'],
  "racehorses": ['R AE1 S HH AO2 R S IH0 Z'],
  "raceman": ['R EY1 S M AH0 N'],
  "racemes": ['R EY0 S IY1 M Z'],
  "racer": ['R EY1 S ER0'],
  "racers": ['R EY1 S ER0 Z'],
  "races": ['R EY1 S AH0 Z', 'R EY1 S IH0 Z'],
  "racetrack": ['R EY1 S T R AE2 K'],
  "racetracks": ['R EY1 S T R AE2 K S'],
  "racette": ['R AH0 S EH1 T'],
  "raceway": ['R EY1 S W EY2'],
  "racey": ['R EY1 S IY0'],
  "rach": ['R AE1 CH'],
  "rachad": ['R AH0 SH AA1 D'],
  "rachael": ['R EY1 CH AH0 L'],
  "rachal": ['R AE1 K AH0 L'],
  "rachel": ['R EY1 CH AH0 L'],
  "rachel's": ['R EY1 CH AH0 L Z'],
  "rachelle": ['R AH0 SH EH1 L'],
  "rachels": ['R EY1 CH IH0 L Z'],
  "rachelvolt": ['R AH0 SH EH1 L V AO2 L T'],
  "rachford": ['R AE1 CH F ER0 D'],
  "rachi": ['R AH1 SH IY0'],
  "rachlin": ['R AE1 K L IH0 N'],
  "rachmaninoff": ['R AE0 K M AE1 N IH0 N AO0 F'],
  "racial": ['R EY1 SH AH0 L'],
  "racialism": ['R EY1 SH AH0 L IH2 Z AH0 M'],
  "racially": ['R EY1 SH AH0 L IY0'],
  "racicot": ['R AE1 S IH0 K AA0 T'],
  "racier": ['R EY1 S IY0 ER0'],
  "raciest": ['R EY1 S IY0 AH0 S T'],
  "racine": ['R AH0 S IY1 N'],
  "raciness": ['R EY1 S IY0 N AH0 S'],
  "racing": ['R EY1 S IH0 NG'],
  "racing's": ['R EY1 S IH0 NG Z'],
  "racioppi": ['R AA0 CH OW1 P IY0'],
  "racism": ['R EY1 S IH2 Z AH0 M'],
  "racist": ['R EY1 S IH0 S T'],
  "racists": ['R EY1 S IH0 S T S'],
  "rack": ['R AE1 K'],
  "racked": ['R AE1 K T'],
  "racker": ['R AE1 K ER0'],
  "rackers": ['R AE1 K ER0 Z'],
  "racket": ['R AE1 K IH0 T'],
  "racketeer": ['R AE2 K IH0 T IH1 R'],
  "racketeering": ['R AE2 K IH0 T IH1 R IH0 NG'],
  "racketeers": ['R AE2 K AH0 T IH1 R Z'],
  "rackets": ['R AE1 K AH0 T S'],
  "racking": ['R AE1 K IH0 NG'],
  "rackley": ['R AE1 K L IY0'],
  "rackliff": ['R AE1 K L IH0 F'],
  "rackliffe": ['R AE1 K L IH0 F'],
  "rackmil": ['R AE1 K M IH0 L'],
  "rackow": ['R AA1 S K OW0'],
  "racks": ['R AE1 K S'],
  "raconteur": ['R AE2 K AA0 N T UW1 R'],
  "racquet": ['R AE1 K IH0 T'],
  "racquetball": ['R AE1 K AH0 T B AO2 L'],
  "racquets": ['R AE1 K IH0 T S'],
  "racy": ['R EY1 S IY0'],
  "racz": ['R AA1 CH'],
  "raczka": ['R AA1 CH K AH0'],
  "raczkowski": ['R AH0 CH K AO1 F S K IY0'],
  "raczynski": ['R AH0 CH IH1 N S K IY0'],
  "rad": ['R AE1 D'],
  "rada": ['R AA1 D AH0'],
  "radabaugh": ['R AE1 D AH0 B AO0'],
  "radakovich": ['R AH0 D AE1 K AH0 V IH0 CH'],
  "radant": ['R AA1 D AH0 N T'],
  "radar": ['R EY1 D AA2 R'],
  "radars": ['R EY1 D AA2 R Z'],
  "radatz": ['R AE1 D AH0 T S'],
  "radavan": ['R AA1 D AH0 V AH0 N'],
  "radbert": ['R AE1 D B ER0 T'],
  "radborne": ['R AH0 D B AO1 R N'],
  "radbourn": ['R AH0 D B UH1 R N'],
  "radbourne": ['R AH0 D B UH1 R N'],
  "radburn": ['R AE1 D B ER0 N'],
  "radcliff": ['R AE1 D K L IH0 F'],
  "radcliffe": ['R AE1 D K L IH0 F'],
  "raddatz": ['R AE1 D AH0 T S'],
  "radde": ['R AE1 D'],
  "radder": ['R AE1 D ER0'],
  "radditz": ['R AE1 D IH0 T S'],
  "radebaugh": ['R AE1 D IH0 B AO0'],
  "radecki": ['R AH0 D EH1 K IY0'],
  "radel": ['R AE1 D AH0 L'],
  "radell": ['R AA0 D EY1 L'],
  "radella": ['R AH0 D EH1 L AH0'],
  "rademacher": ['R AE1 D IH0 M AH0 K ER0'],
  "rademaker": ['R EY1 D M EY0 K ER0'],
  "raden": ['R EY1 D AH0 N'],
  "rader": ['R EY1 D ER0'],
  "radermacher": ['R AE1 D ER0 M AH0 K ER0'],
  "radford": ['R AE1 D F ER0 D'],
  "radha": ['R AA1 D AH0'],
  "radi": ['R AA1 D IY0'],
  "radial": ['R EY1 D IY0 AH0 L'],
  "radially": ['R EY1 D IY0 AH0 L IY0'],
  "radials": ['R EY1 D IY0 AH0 L Z'],
  "radiance": ['R EY1 D IY2 AH0 N S', 'R EY1 D Y AH0 N S'],
  "radiant": ['R EY1 D IY2 AH0 N T', 'R EY1 D Y AH0 N T'],
  "radiate": ['R EY1 D IY0 AH0 T', 'R EY1 D IY0 EY2 T'],
  "radiated": ['R EY1 D IY0 EY2 T IH0 D'],
  "radiates": ['R EY1 D IY0 EY2 T S'],
  "radiating": ['R EY1 D IY0 EY2 T IH0 NG'],
  "radiation": ['R EY2 D IY0 EY1 SH AH0 N'],
  "radiation's": ['R EY2 D IY0 EY1 SH AH0 N Z'],
  "radiator": ['R EY1 D IY0 EY2 T ER0'],
  "radiators": ['R EY1 D IY0 EY2 T ER0 Z'],
  "radical": ['R AE1 D AH0 K AH0 L', 'R AE1 D IH0 K AH0 L'],
  "radicalism": ['R AE1 D IH0 K AH0 L IH2 Z AH0 M'],
  "radicalization": ['R AE2 D IH0 K AH0 L IH0 Z EY1 SH AH0 N'],
  "radicalize": ['R AE1 D IH0 K AH0 L AY2 Z'],
  "radicalized": ['R AE1 D IH0 K AH0 L AY2 Z D'],
  "radically": ['R AE1 D IH0 K L IY0'],
  "radicals": ['R AE1 D AH0 K AH0 L Z', 'R AE1 D IH0 K AH0 L Z'],
  "radice": ['R AE1 D IH0 S'],
  "radich": ['R AE1 D IH0 K'],
  "radick": ['R AE1 D IH0 K'],
  "radin": ['R AE1 D IH0 N'],
  "radinka": ['R AH0 D IH1 NG K AH0'],
  "radio": ['R EY1 D IY0 OW2'],
  "radio's": ['R EY1 D IY0 OW2 Z'],
  "radioactive": ['R EY2 D IY0 OW0 AE1 K T IH0 V'],
  "radioactivity": ['R EY1 D IY0 OW0 AE2 K T IH2 V AH0 T IY2', 'R EY2 D IY0 OW0 AE2 K T IH1 V AH0 T IY2'],
  "radioed": ['R EY1 D IY0 OW2 D'],
  "radiographic": ['R EY2 D IY0 OW2 G R AA1 F IH0 K'],
  "radiography": ['R EY2 D IY0 AA1 G R AH0 F IY0'],
  "radiological": ['R EY2 D IY0 AH0 L AA1 JH IH0 K AH0 L'],
  "radiologist": ['R EY2 D IY0 AA1 L AH0 JH IH0 S T'],
  "radiologists": ['R EY2 D IY0 AA1 L AH0 JH IH0 S T S'],
  "radiology": ['R EY2 D IY0 AA1 L AH0 JH IY0'],
  "radioman": ['R EY1 D IY0 OW0 M AE2 N'],
  "radios": ['R EY1 D IY0 OW2 Z'],
  "radish": ['R AE1 D IH0 SH'],
  "radishes": ['R AE1 D IH0 SH IH0 Z'],
  "radisson": ['R AE1 D AH0 S AH0 N'],
  "raditz": ['R EY1 D IH0 T S'],
  "radium": ['R EY1 D IY0 AH0 M'],
  "radius": ['R EY1 D IY0 AH0 S'],
  "radke": ['R EY1 D K IY0', 'R AE1 D K IY0'],
  "radko": ['R AE1 D K OW0'],
  "radle": ['R EY1 D AH0 L'],
  "radler": ['R EY1 D AH0 L ER0', 'R AE1 D L ER0'],
  "radley": ['R AE1 D L IY0'],
  "radliff": ['R AE1 D L IH0 F'],
  "radloff": ['R AE1 D L AO0 F'],
  "radman": ['R AE1 D M AH0 N'],
  "radmilla": ['R AE2 D M IH1 L AH0'],
  "radmund": ['R AE1 D M AH0 N D'],
  "radner": ['R AE1 D N ER0'],
  "radney": ['R AE1 D N IY0'],
  "radnor": ['R AE1 D N ER0'],
  "rado": ['R AA1 D OW0'],
  "radolf": ['R AE1 D OW0 L F'],
  "radomski": ['R AH0 D AA1 M S K IY0'],
  "radon": ['R EY1 D AA2 N'],
  "rados": ['R AA1 D OW0 Z'],
  "radosevich": ['R AH0 D AA1 S IH0 V IH0 CH'],
  "radosh": ['R AH0 D AO1 SH'],
  "radovan": ['R AA1 D OW2 V AA2 N'],
  "radowski": ['R AH0 D OW1 S K IY0'],
  "radowski's": ['R AH0 D OW1 S K IY0 Z'],
  "radtke": ['R AE1 D K IY0'],
  "radu": ['R AA1 D UW0'],
  "radwan": ['R AE1 D W AH0 N'],
  "rady": ['R EY1 D IY0'],
  "radziewicz": ['R AA1 JH AH0 V IH0 CH'],
  "radzik": ['R AE1 D Z IH0 K'],
  "rae": ['R EY1'],
  "raeburn": ['R EY1 B ER0 N'],
  "raeder": ['R EH1 D ER0'],
  "raedler": ['R EH1 D L ER0'],
  "rael": ['R EY1 L'],
  "raether": ['R EH1 DH ER0'],
  "raetz": ['R IY1 T S'],
  "raf": ['R AE1 F'],
  "rafael": ['R AA2 F AY0 EH1 L'],
  "rafale": ['R AH0 F EY1 L'],
  "rafalski": ['R AH0 F AA1 L S K IY0'],
  "rafe": ['R EY1 F'],
  "rafelghem": ['R AH0 F EH1 L G AH0 M'],
  "raff": ['R AE1 F'],
  "raffa": ['R AE1 F AH0'],
  "raffaele": ['R AA0 F AY0 EH1 L EY0'],
  "raffaelli": ['R AA0 F AA0 EH1 L IY0'],
  "raffel": ['R AE1 F AH0 L'],
  "raffensperger": ['R AE1 F IH0 N S P ER0 G ER0'],
  "rafferty": ['R AE1 F ER0 T IY0'],
  "raffetto": ['R AA0 F EH1 T OW0'],
  "raffety": ['R AE1 F T IY0'],
  "raffi": ['R AE1 F IY0'],
  "raffi's": ['R AE1 F IY0 Z'],
  "raffield": ['R AE1 F IY2 L D'],
  "raffish": ['R AE1 F IH0 SH'],
  "raffle": ['R AE1 F AH0 L'],
  "raffles": ['R AE1 F AH0 L Z'],
  "raffo": ['R AE1 F OW0'],
  "rafi": ['R AE1 F IY0', 'R AA1 F IY0'],
  "rafik": ['R AE1 F IH0 K'],
  "rafsanjani": ['R AE2 F S AH0 N JH AA1 N IY0'],
  "raft": ['R AE1 F T'],
  "rafted": ['R AE1 F T IH0 D'],
  "rafter": ['R AE1 F T ER0'],
  "rafters": ['R AE1 F T ER0 Z'],
  "raftery": ['R AE1 F T ER0 IY0'],
  "rafting": ['R AE1 F T IH0 NG'],
  "rafts": ['R AE1 F T S'],
  "rafuse": ['R AA0 F UW1 S IY0'],
  "rag": ['R AE1 G'],
  "ragain": ['R AE1 G AH0 N'],
  "ragan": ['R EY1 G AH0 N'],
  "ragas": ['R AA1 G AH0 Z'],
  "ragavan": ['R AA1 G AH0 V AA2 N'],
  "ragavan's": ['R AA1 G AH0 V AA2 N Z'],
  "rage": ['R EY1 JH'],
  "raged": ['R EY1 JH D'],
  "ragel": ['R EY1 G AH0 L'],
  "ragen": ['R AE1 G AH0 N'],
  "rager": ['R EY1 G ER0'],
  "rages": ['R EY1 JH IH0 Z'],
  "ragged": ['R AE1 G AH0 D'],
  "raggedy": ['R AE1 G AH0 D IY0'],
  "raggio": ['R AA1 JH IY0 OW0'],
  "raghida": ['R AH0 G IY1 D AH0'],
  "ragin": ['R AE1 JH IH0 N'],
  "raging": ['R EY1 JH IH0 NG'],
  "ragland": ['R AE1 G L AH0 N D'],
  "ragle": ['R EY1 G AH0 L'],
  "raglin": ['R AE1 G L IH0 N'],
  "ragmen": ['R AE1 G M AH0 N'],
  "rago": ['R AA1 G OW0'],
  "ragon": ['R AA0 G AO1 N'],
  "ragona": ['R AA0 G OW1 N AH0'],
  "ragone": ['R AA0 G OW1 N IY0'],
  "ragonese": ['R AA0 G OW0 N EY1 Z IY0'],
  "rags": ['R AE1 G Z'],
  "ragsdale": ['R AE1 G Z D EY2 L'],
  "ragtag": ['R AE1 G T AE2 G'],
  "ragtime": ['R AE1 G T AY2 M'],
  "ragtop": ['R AE1 G T AO0 P'],
  "ragu": ['R AE0 G UW1'],
  "ragucci": ['R AA0 G UW1 CH IY0'],
  "rague": ['R EY1 G', 'R AE1 G'],
  "ragunathan": ['R AA0 G UW1 N AH0 TH AA0 N'],
  "ragusa": ['R AA0 G UW1 S AH0'],
  "ragweed": ['R AE1 G W IY2 D'],
  "rah": ['R AA1'],
  "rahal": ['R AH0 HH AA1 L'],
  "rahe": ['R EY1 HH IY0'],
  "raheem": ['R AH0 HH IY1 M'],
  "rahill": ['R AA1 HH IH0 L'],
  "rahilly": ['R AE1 HH AH0 L IY0'],
  "rahim": ['R AH0 HH IY1 M'],
  "rahl": ['R AA1 L'],
  "rahm": ['R AE1 M', 'R AA1 M'],
  "rahman": ['R AA1 M AH0 N'],
  "rahman's": ['R AA1 M AH0 N Z'],
  "rahming": ['R AA1 M IH0 NG'],
  "rahn": ['R AE1 N'],
  "rahrig": ['R AE1 R IH0 G'],
  "rahul": ['R AH0 HH UW1 L'],
  "rahway": ['R AA1 W EY2'],
  "rai": ['R AA1 IY0'],
  "raia": ['R AA1 Y AH0'],
  "raible": ['R EY1 B AH0 L'],
  "raiche": ['R EY1 CH'],
  "raid": ['R EY1 D'],
  "raided": ['R EY1 D IH0 D'],
  "raider": ['R EY1 D ER0'],
  "raider's": ['R EY1 D ER0 Z'],
  "raiders": ['R EY1 D ER0 Z'],
  "raiders'": ['R EY1 D ER0 Z'],
  "raiding": ['R EY1 D IH0 NG'],
  "raids": ['R EY1 D Z'],
  "raiff": ['R EY1 F'],
  "raiford": ['R EY1 F ER0 D'],
  "raikes": ['R EY1 K S'],
  "raiko": ['R EY1 K OW0'],
  "rail": ['R EY1 L'],
  "rail's": ['R EY1 L Z'],
  "railbike": ['R EY1 L B AY2 K'],
  "railbiker": ['R EY1 L B AY0 K ER0'],
  "railbikers": ['R EY1 L B IH0 K ER0 Z'],
  "railcar": ['R EY1 L K AA2 R'],
  "railcars": ['R EY1 L K AA2 R Z'],
  "raile": ['R EY1 L'],
  "railed": ['R EY1 L D'],
  "railey": ['R EY1 L IY0'],
  "railing": ['R EY1 L IH0 NG'],
  "railings": ['R EY1 L IH0 NG Z'],
  "railroad": ['R EY1 L R OW2 D'],
  "railroad's": ['R EY1 L R OW2 D Z'],
  "railroaded": ['R EY1 L R OW2 D IH0 D'],
  "railroading": ['R EY1 L R OW2 D IH0 NG'],
  "railroads": ['R EY1 L R OW2 D Z'],
  "railroads'": ['R EY1 L R OW2 D Z'],
  "rails": ['R EY1 L Z'],
  "railsback": ['R EY1 L Z B AE2 K'],
  "railtex": ['R EY1 L T EH2 K S'],
  "railway": ['R EY1 L W EY2'],
  "railway's": ['R EY1 L W EY2 Z'],
  "railways": ['R EY1 L W EY2 Z'],
  "raimer": ['R EY1 M ER0'],
  "raimo": ['R EY1 M OW0'],
  "raimond": ['R EY1 M AH0 N D'],
  "raimondi": ['R AH0 M OW1 N D IY0'],
  "raimondo": ['R EY2 M AA1 N D OW0'],
  "rain": ['R EY1 N'],
  "raina": ['R EY1 N AH0'],
  "rainbolt": ['R EY1 N B OW2 L T'],
  "rainbow": ['R EY1 N B OW2'],
  "rainbows": ['R EY1 N B OW2 Z'],
  "raincoat": ['R EY1 N K OW2 T'],
  "raincoat's": ['R EY1 N K OW2 T S'],
  "raincoats": ['R EY1 N K OW2 T S'],
  "raindancer": ['R EY1 N D AE2 N S ER0'],
  "raindrop": ['R EY1 N D R AA2 P'],
  "raindrops": ['R EY1 N D R AA2 P S'],
  "raine": ['R EY1 N'],
  "rained": ['R EY1 N D'],
  "rainer": ['R EY1 N ER0'],
  "raineri": ['R AH0 N EH1 R IY0'],
  "raines": ['R EY1 N Z'],
  "rainey": ['R EY1 N IY0'],
  "rainfall": ['R EY1 N F AO2 L'],
  "rainfalls": ['R EY1 N F AO2 L Z'],
  "rainford": ['R AY1 N F ER0 D'],
  "rainforest": ['R AY1 N F AO2 R AH0 S T'],
  "rainforests": ['R AY1 N F AO2 R AH0 S T S'],
  "rainger": ['R AA1 IH0 NG ER0'],
  "rainie": ['R EY1 N IY0'],
  "rainier": ['R EY0 N IH1 R'],
  "rainiest": ['R EY1 N IY0 AH0 S T'],
  "raining": ['R EY1 N IH0 NG'],
  "rainley": ['R EY1 N L IY0'],
  "rainley's": ['R EY1 N L IY0 Z'],
  "rainmaker": ['R EY1 N M EY2 K ER0'],
  "rainman": ['R EY1 N M AH0 N'],
  "rainone": ['R EY1 N OW2 N'],
  "rains": ['R EY1 N Z'],
  "rainstorm": ['R EY1 N S T AO2 R M'],
  "rainstorms": ['R EY1 N S T AO2 R M Z'],
  "rainville": ['R EY1 N V IH2 L'],
  "rainwater": ['R EY1 N W AO2 T ER0'],
  "rainy": ['R EY1 N IY0'],
  "rais": ['R EY1 S'],
  "raisa": ['R EY1 S AH0', 'R AA2 IY1 S AH0'],
  "raisanen": ['R AY1 S AH0 N AH0 N'],
  "raisbeck": ['R EY1 Z B EH2 K'],
  "raisch": ['R AY1 SH'],
  "raise": ['R EY1 Z'],
  "raised": ['R EY1 Z D'],
  "raiser": ['R EY1 Z ER0'],
  "raisers": ['R EY1 Z ER0 Z'],
  "raises": ['R EY1 Z AH0 Z', 'R EY1 Z IH0 Z'],
  "raisin": ['R EY1 Z IH0 N'],
  "raising": ['R EY1 Z IH0 NG'],
  "raisins": ['R EY1 Z AH0 N Z', 'R EY1 Z IH0 N Z'],
  "raisler": ['R EY1 Z L ER0'],
  "raison": ['R EY1 S AA0 N'],
  "raisor": ['R EY1 Z ER0'],
  "raissa": ['R EY1 S AH0'],
  "rait": ['R EY1 T'],
  "raith": ['R EY1 TH'],
  "raithel": ['R EY1 TH AH0 L'],
  "raitt": ['R EY1 T'],
  "raj": ['R AA1 ZH', 'R AA1 JH'],
  "rajala": ['R AA0 Y AA1 L AH0', 'R AA0 JH AA1 L AH0'],
  "rajaratnam": ['R AA0 JH ER0 AA1 T N AA0 M'],
  "rajewski": ['R AY0 EH1 F S K IY0'],
  "rajiv": ['R AA0 JH IY1 V', 'R AA0 ZH IY1 V'],
  "rajko": ['R AA1 ZH K OW0'],
  "rajkumar": ['R AA1 ZH K UW0 M AA1 R'],
  "rajneesh": ['R AA0 JH N IY1 SH'],
  "raju": ['R AA1 Y UW0'],
  "rak": ['R AE1 K'],
  "rake": ['R EY1 K'],
  "raked": ['R EY1 K T'],
  "raker": ['R EY1 K ER0'],
  "rakers": ['R EY1 K ER0 Z'],
  "rakes": ['R EY1 K S'],
  "rakestraw": ['R EY1 K S T R AO2'],
  "rakich": ['R AE1 K IH0 CH'],
  "raking": ['R EY1 K IH0 NG'],
  "rakish": ['R EY1 K IH0 SH'],
  "rakiya": ['R AH0 K IY1 AH0'],
  "rakocy": ['R AH0 K OW1 CH IY0'],
  "rakoczy": ['R AH0 K OW1 CH IY0'],
  "rakoff": ['R AE1 K AO2 F'],
  "rakolta": ['R AH0 K AA1 L T AH0'],
  "rakovica": ['R AH0 K OW1 V IH0 K AH0'],
  "rakow": ['R AE1 K AW0'],
  "rakowski": ['R AH0 K AW1 S K IY0'],
  "raleigh": ['R AO1 L IY0'],
  "raleigh's": ['R AO1 L IY0 Z'],
  "rales": ['R EY1 L Z'],
  "rales'": ['R EY1 L Z'],
  "rales's": ['R EY1 L Z IH0 Z'],
  "raleses": ['R AH0 L IY1 S IH0 Z'],
  "raley": ['R AE1 L IY0'],
  "ralf": ['R AA1 L F'],
  "rall": ['R AO1 L'],
  "rallied": ['R AE1 L IY0 D'],
  "rallies": ['R AE1 L IY0 Z'],
  "rallis": ['R AE1 L IH0 S'],
  "rallo": ['R AE1 L OW0'],
  "ralls": ['R AO1 L Z'],
  "rally": ['R AE1 L IY0'],
  "rally's": ['R AE1 L IY0 Z'],
  "rallying": ['R AE1 L IY0 IH0 NG'],
  "ralph": ['R AE1 L F'],
  "ralph's": ['R AE1 L F S'],
  "ralphs": ['R AE1 L F S'],
  "ralston": ['R AO1 L S T AH0 N'],
  "ralston's": ['R AA1 L S T AH0 N Z'],
  "ram": ['R AE1 M'],
  "rama": ['R AA1 M AH0'],
  "ramada": ['R AH0 M AA1 D AH0'],
  "ramada's": ['R AH0 M AA1 D AH0 Z'],
  "ramadan": ['R AE1 M AH0 D AH0 N', 'R AA1 M AH0 D AA2 N'],
  "ramage": ['R AE1 M IH0 JH'],
  "ramaker": ['R AA1 M EY0 K ER0'],
  "ramala": ['R AH0 M AA1 L AH0'],
  "ramallah": ['R AH0 M AE1 L AH0', 'R AH0 M AA1 L AH0'],
  "raman": ['R EY1 M AH0 N', 'R AA1 M AH0 N'],
  "ramaphosa": ['R AE2 M AH0 F OW1 S AH0'],
  "ramaswami": ['R AA2 M AH0 S W AA1 M IY0'],
  "ramat": ['R AE1 M AE0 T', 'R AA1 M AH0 T'],
  "rambeau": ['R AH0 M B OW1', 'R AE1 M B OW2'],
  "ramberg": ['R AE1 M B ER0 G'],
  "rambert": ['R AE1 M B ER0 T'],
  "rambin": ['R AE1 M B IH0 N'],
  "ramble": ['R AE1 M B AH0 L'],
  "rambled": ['R AE1 M B AH0 L D'],
  "rambler": ['R AE1 M B L ER0'],
  "ramblers": ['R AE1 M B L ER0 Z'],
  "rambling": ['R AE1 M B L IH0 NG', 'R AE1 M B AH0 L IH0 NG'],
  "rambo": ['R AE1 M B OW0'],
  "rambow": ['R AE1 M B OW0'],
  "rambunctious": ['R AE0 M B AH1 NG K SH AH0 S'],
  "ramel": ['R AE1 M AH0 L'],
  "ramella": ['R AH0 M EH1 L AH0'],
  "ramer": ['R EY1 M ER0'],
  "rameriz": ['R AA0 M EH1 R IY0 Z'],
  "rameses": ['R AE1 M AH0 S IY2 Z'],
  "ramesh": ['R AA1 M EH2 SH'],
  "ramesses": ['R AE1 M AH0 S IH0 Z', 'R AE1 M S IY2 Z'],
  "ramey": ['R AE1 M IY0'],
  "rami": ['R AA1 M IY0'],
  "ramification": ['R AE2 M AH0 F AH0 K EY1 SH AH0 N'],
  "ramifications": ['R AE2 M AH0 F AH0 K EY1 SH AH0 N Z'],
  "ramify": ['R AE1 M AH0 F AY2'],
  "ramires": ['R AA0 M IH1 R EH0 S'],
  "ramirez": ['R AH0 M IH1 R EH0 Z'],
  "ramiro": ['R AH0 M IH1 R OW0'],
  "ramlawi": ['R AE2 M L AW1 IY0'],
  "ramler": ['R AE1 M L ER0'],
  "ramlow": ['R AE1 M L OW2'],
  "ramm": ['R AE1 M'],
  "rammed": ['R AE1 M D'],
  "rammel": ['R AE1 M AH0 L'],
  "rammer": ['R AE1 M ER0'],
  "ramming": ['R AE1 M IH0 NG'],
  "ramo": ['R EY1 M OW0'],
  "ramon": ['R AH0 M OW1 N'],
  "ramona": ['R AH0 M OW1 N AH0'],
  "ramonda": ['R AH0 M AA1 N D AH0'],
  "ramone": ['R AH0 M OW1 N'],
  "ramos": ['R AA1 M OW0 S', 'R EY1 M OW0 S'],
  "ramp": ['R AE1 M P'],
  "rampage": ['R AE1 M P EY2 JH'],
  "rampaged": ['R AE0 M P EY1 JH D', 'R AE1 M P EY2 JH D'],
  "rampager": ['R AE1 M P EY2 JH ER0'],
  "rampages": ['R AE1 M P EY2 JH IH0 Z'],
  "rampaging": ['R AE1 M P EY2 JH IH0 NG', 'R AE1 M P AH0 JH IH0 NG'],
  "rampant": ['R AE1 M P AH0 N T'],
  "rampart": ['R AE1 M P AA2 R T'],
  "ramparts": ['R AE1 M P AA2 R T S'],
  "rampell": ['R AE0 M P EH1 L'],
  "rampey": ['R AE1 M P IY0'],
  "ramping": ['R AE1 M P IH0 NG'],
  "rampley": ['R AE1 M P L IY0'],
  "ramps": ['R AE1 M P S'],
  "rampy": ['R AE1 M P IY0'],
  "ramqvist": ['R AE1 M K V IH2 S T', 'R AE1 M K W IH2 S T'],
  "ramrod": ['R AE1 M R AA2 D'],
  "rams": ['R AE1 M Z'],
  "ramsay": ['R AE1 M Z IY0', 'R AE1 M S EY2'],
  "ramsburg": ['R AE1 M S B ER0 G'],
  "ramsdell": ['R AE1 M S D AH0 L'],
  "ramsden": ['R AE1 M S D AH0 N'],
  "ramser": ['R AE1 M Z ER0'],
  "ramses": ['R AE1 M S IY2 Z'],
  "ramseur": ['R AH0 M S ER1'],
  "ramsey": ['R AE1 M Z IY0'],
  "ramsey's": ['R AE1 M Z IY0 Z'],
  "ramseyer": ['R AE1 M Z IY0 ER0'],
  "ramseys": ['R AE1 M Z IY0 Z', 'R AE1 M S IY0 Z'],
  "ramshackle": ['R AE1 M SH AE2 K AH0 L'],
  "ramstad": ['R AE1 M S T AH0 D'],
  "ramstein": ['R AE1 M S T AY2 N', 'R AE1 M S T IY2 N'],
  "ramtane": ['R AE1 M T EY0 N'],
  "ramtek": ['R AE1 M T EH2 K'],
  "ramtha": ['R AE1 M TH AH0'],
  "ramthun": ['R AE1 M TH AH0 N'],
  "ramu": ['R AA2 M UW1'],
  "ramune": ['R AE1 M Y UW2 N'],
  "ramus": ['R EY1 M AH0 S'],
  "ramzi": ['R AE1 M Z IY0'],
  "ran": ['R AE1 N'],
  "rana": ['R AE1 N AH0'],
  "ranalli": ['R AH0 N AE1 L IY0'],
  "ranallo": ['R AH0 N AE1 L OW0'],
  "rance": ['R AE1 N S'],
  "rance's": ['R AE1 N S IH0 Z'],
  "ranch": ['R AE1 N CH'],
  "ranch's": ['R AE1 N CH IH0 Z'],
  "rancher": ['R AE1 N CH ER0'],
  "ranchera": ['R AA0 N CH EH1 R AH0'],
  "ranchero": ['R AA0 N CH EH1 R OW0', 'R AE0 N CH EH1 R OW0'],
  "ranchers": ['R AE1 N CH ER0 Z'],
  "ranches": ['R AE1 N CH AH0 Z'],
  "ranching": ['R AE1 N CH IH0 NG'],
  "ranchland": ['R AE1 N CH L AH0 N D'],
  "rancho": ['R AE1 N CH OW0'],
  "rancid": ['R AE1 N S IH0 D'],
  "rancidity": ['R AE0 N S IH1 D IH0 T IY0'],
  "ranck": ['R AE1 NG K'],
  "ranco": ['R AE1 NG K OW0'],
  "rancor": ['R AE1 NG K ER0'],
  "rancorous": ['R AE1 NG K ER0 AH0 S'],
  "rancorousness": ['R AE1 NG K ER0 AH0 S N IH0 S'],
  "rancourt": ['R AH0 N K AO1 R T'],
  "rand": ['R AE1 N D'],
  "rand's": ['R AE1 N D Z'],
  "randa": ['R AA1 N D AH0'],
  "randa's": ['R AA1 N D AH0 Z'],
  "randal": ['R AE1 N D AH0 L'],
  "randall": ['R AE1 N D AH0 L'],
  "randazzo": ['R AA0 N D AA1 Z OW0'],
  "randel": ['R AE1 N D AH0 L'],
  "randell": ['R AE1 N D EH1 L'],
  "rander": ['R AE1 N D ER0'],
  "randi": ['R AE1 N D IY0'],
  "randle": ['R AE1 N D AH0 L'],
  "randleman": ['R AE1 N D AH0 L M AH0 N'],
  "randles": ['R AE1 N D AH0 L Z'],
  "randlett": ['R AE1 N D L IH0 T'],
  "rando": ['R AA1 N D OW0'],
  "randol": ['R AE1 N D AH0 L'],
  "randolf": ['R AE1 N D OW2 L F'],
  "randolph": ['R AE1 N D AA0 L F'],
  "random": ['R AE1 N D AH0 M'],
  "randomize": ['R AE1 N D AH0 M AY2 Z'],
  "randomized": ['R AE1 N D AH0 M AY2 Z D'],
  "randomly": ['R AE1 N D AH0 M L IY0'],
  "randomness": ['R AE1 N D AH0 M N AH0 S'],
  "rands": ['R AE1 N D Z'],
  "randy": ['R AE1 N D IY0'],
  "randy's": ['R AE1 N D IY0 Z'],
  "ranee": ['R AE1 N IY1'],
  "raneri": ['R AA0 N EH1 R IY0'],
  "ranes": ['R EY1 N Z'],
  "raney": ['R EY1 N IY0'],
  "ranft": ['R AE1 N F T'],
  "rang": ['R AE1 NG'],
  "range": ['R EY1 N JH'],
  "ranged": ['R EY1 N JH D'],
  "rangel": ['R AE1 N JH EH1 L'],
  "ranger": ['R EY1 N JH ER0'],
  "ranger's": ['R EY1 N JH ER0 Z'],
  "rangers": ['R EY1 N JH ER0 Z'],
  "rangers'": ['R EY1 N JH ER0 Z'],
  "ranges": ['R EY1 N JH AH0 Z', 'R EY1 N JH IH0 Z'],
  "ranging": ['R EY1 N JH IH0 NG'],
  "rangoon": ['R AE0 NG G UW1 N'],
  "rangoon's": ['R AE0 NG G UW1 N Z'],
  "rangy": ['R EY1 N JH IY0'],
  "ranh": ['R AE1 N'],
  "rani": ['R AA1 N IY0'],
  "rania": ['R AA1 N IY0 AH0'],
  "ranieri": ['R AE2 N IY0 EH1 R IY0', 'R AH0 N IY0 EH1 R IY0'],
  "rank": ['R AE1 NG K'],
  "ranke": ['R AE1 NG K'],
  "ranked": ['R AE1 NG K T'],
  "ranker": ['R AE1 NG K ER0'],
  "rankers": ['R AE1 NG K ER0 Z'],
  "rankin": ['R AE1 NG K IH0 N'],
  "rankine": ['R AE1 NG K AY2 N'],
  "ranking": ['R AE1 NG K IH0 NG'],
  "rankings": ['R AE1 NG K IH0 NG Z'],
  "rankins": ['R AE1 NG K IH0 N Z'],
  "rankle": ['R AE1 NG K AH0 L'],
  "rankled": ['R AE1 NG K AH0 L D'],
  "rankles": ['R AE1 NG K AH0 L Z'],
  "rankling": ['R AE1 NG K L IH0 NG'],
  "ranks": ['R AE1 NG K S'],
  "ranley": ['R AE1 N L IY0'],
  "rann": ['R AE1 N'],
  "ranney": ['R AE1 N IY0'],
  "rannow": ['R AE1 N OW0'],
  "rans": ['R AE1 N Z'],
  "ransack": ['R AE1 N S AE2 K'],
  "ransacked": ['R AE1 N S AE2 K T'],
  "ransacking": ['R AE1 N S AE2 K IH0 NG'],
  "ransall": ['R AE1 N S AA0 L'],
  "ransbottom": ['R AE1 N S B AA2 T AH0 M'],
  "ransburg": ['R AE1 N Z B ER0 G'],
  "ransburg's": ['R AE1 N Z B ER0 G Z'],
  "ransdell": ['R AE1 N Z D EH1 L'],
  "ransford": ['R AE1 N S F ER0 D'],
  "ransier": ['R AE1 N S IY0 ER0'],
  "ransley": ['R AE1 N S L IY0'],
  "ransom": ['R AE1 N S AH0 M'],
  "ransom's": ['R AE1 N S AH0 M Z'],
  "ransome": ['R AE1 N S AH0 M'],
  "ransoms": ['R AE1 N S AH0 M Z'],
  "ranson": ['R AE1 N S AH0 N'],
  "ransone": ['R AE1 N S AH0 N'],
  "rant": ['R AE1 N T'],
  "ranta": ['R AE1 N T AH0'],
  "rantala": ['R AA0 N T AA1 L AH0'],
  "ranted": ['R AE1 N T IH0 D'],
  "ranting": ['R AE1 N T IH0 NG'],
  "rantoul": ['R AE2 N T UW1 L'],
  "rantz": ['R AE1 N T S'],
  "ranum": ['R AE1 N AH0 M'],
  "ranz": ['R AE1 N Z'],
  "rao": ['R AW1'],
  "rao's": ['R AW1 Z'],
  "raoul": ['R AA0 UW1 L'],
  "raoux": ['R AE0 UW1'],
  "rap": ['R AE1 P'],
  "rapacious": ['R AH0 P AE1 SH IH0 S', 'R AH0 P EY1 SH IH0 S'],
  "rapacz": ['R AA1 P AH0 CH'],
  "rapanelli": ['R AE2 P AH0 N EH1 L IY0'],
  "rapaport": ['R AE1 P AH0 P AO2 R T'],
  "rape": ['R EY1 P'],
  "raped": ['R EY1 P T'],
  "raper": ['R EY1 P ER0'],
  "rapers": ['R EY1 P ER0 Z'],
  "rapes": ['R EY1 P S'],
  "rapeseed": ['R EY1 P S IY2 D'],
  "raphael": ['R AA2 F AY0 EH1 L', 'R AA2 F Y EH1 L', 'R EY1 F IY0 AH0 L'],
  "raphaela": ['R AE1 F AY0 EH1 L AH0'],
  "raphaelite": ['R AE0 F Y EH1 L AY0 T'],
  "raphalian": ['R AH0 F EY1 L IY0 AH0 N'],
  "raphel": ['R AE1 F AH0 L'],
  "rapid": ['R AE1 P AH0 D', 'R AE1 P IH0 D'],
  "rapidity": ['R AH0 P IH1 D AH0 T IY0'],
  "rapidly": ['R AE1 P AH0 D L IY0'],
  "rapids": ['R AE1 P AH0 D Z', 'R AE1 P IH0 D Z'],
  "rapier": ['R EY1 P IY0 ER0'],
  "raping": ['R EY1 P IH0 NG'],
  "rapist": ['R EY1 P IH0 S T'],
  "rapist's": ['R EY1 P IH0 S T S'],
  "rapists": ['R EY1 P IH0 S T S'],
  "rapkin": ['R AE1 P K IH0 N'],
  "rapley": ['R AE1 P L IY0'],
  "rapoca": ['R AH0 P OW1 K ER0'],
  "rapoport": ['R AH0 P AA1 P AO0 R T', 'R AE1 P AH0 P AO0 R T'],
  "raposa": ['R AA0 P OW1 S AH0'],
  "raposo": ['R AA0 P OW1 S OW0'],
  "rapoza": ['R AA0 P OW1 Z AH0'],
  "rapozo": ['R AA0 P OW1 Z OW0'],
  "rapp": ['R AE1 P'],
  "rappa": ['R AE1 P AH0'],
  "rappahannock": ['R AE2 P AH0 HH AE1 N AH0 K'],
  "rappaport": ['R AE1 P AH0 P AO0 R T'],
  "rappe": ['R AE1 P'],
  "rapped": ['R AE1 P T'],
  "rappelling": ['R AH0 P EH1 L IH0 NG'],
  "rapper": ['R AE1 P ER0'],
  "rappers": ['R AE1 P ER0 Z'],
  "rapping": ['R AE1 P IH0 NG'],
  "rappold": ['R AE1 P OW2 L D'],
  "rappoport": ['R AE1 P AH0 P AO0 R T'],
  "rapport": ['R AE0 P AO1 R'],
  "rapprochement": ['R AE2 P R OW2 SH M AA1 N'],
  "raps": ['R AE1 P S'],
  "rapson": ['R AE1 P S AH0 N'],
  "rapt": ['R AE1 P T'],
  "raptis": ['R AH0 P T IY1 S'],
  "raptly": ['R AE1 P T L IY0'],
  "raptopoulos": ['R AE0 P T AA1 P OW0 L AH0 S'],
  "raptor": ['R AE1 P T ER0'],
  "raptorial": ['R AE2 P T AO1 R IY0 AH0 L'],
  "raptors": ['R AE1 P T ER0 Z'],
  "rapture": ['R AE1 P CH ER0'],
  "rapturous": ['R AE1 P CH ER0 AH0 S'],
  "rapuano": ['R AA0 P UW0 AA1 N OW0'],
  "rapunzel": ['R AA2 P UH1 Z AH0 L'],
  "raquel": ['R AH0 K EH1 L'],
  "rara": ['R AA1 R AH0'],
  "rardon": ['R AA1 R D AH0 N'],
  "rare": ['R EH1 R'],
  "rarefied": ['R EH1 R AH0 F AY0 D'],
  "rarefy": ['R EH1 R AH0 F AY0'],
  "rarely": ['R EH1 R L IY0'],
  "rareness": ['R EH1 R N IH0 S'],
  "rarer": ['R EH1 R ER0'],
  "rarest": ['R EH1 R AH0 S T'],
  "rarick": ['R AE1 R IH0 K'],
  "raritan": ['R EH1 R IH0 T AH0 N'],
  "rarities": ['R EH1 R IH0 T IY0 Z'],
  "rarity": ['R EH1 R AH0 T IY0', 'R EH1 R IH0 T IY0'],
  "ras": ['R AE1 S'],
  "rasbury": ['R AE1 S B EH0 R IY0'],
  "rascal": ['R AE1 S K AH0 L'],
  "rascals": ['R AE1 S K AH0 L Z'],
  "rasch": ['R AE1 SH'],
  "rasche": ['R AE1 SH'],
  "raschke": ['R AE1 SH K'],
  "rasco": ['R AA1 S K OW0'],
  "rascoe": ['R AE1 S K OW0'],
  "rascon": ['R AE1 S K AH0 N'],
  "rase": ['R EY1 Z'],
  "raser": ['R EY1 Z ER0'],
  "rasey": ['R AE1 S IY0'],
  "rash": ['R AE1 SH'],
  "rashad": ['R AH0 SH AA1 D'],
  "rasheed": ['R AH0 SH IY1 D'],
  "rashes": ['R AE1 SH IH0 Z'],
  "rashid": ['R AH0 SH IY1 D', 'R AA0 SH IY1 D'],
  "rasia": ['R AA1 S IY0 AH0'],
  "rask": ['R AE1 S K'],
  "raska": ['R AA1 S K AH0'],
  "raske": ['R EY1 S K'],
  "raskin": ['R AE1 S K IH0 N'],
  "raskyn": ['R AE1 S K IH0 N'],
  "rasmin": ['R AE1 S M AH0 N', 'R AE1 Z M IH0 N'],
  "rasmus": ['R AE1 Z M IH0 S'],
  "rasmuson": ['R AE1 Z M AH0 S AH0 N'],
  "rasmussen": ['R AE1 S M AH0 S AH0 N'],
  "rasmusson": ['R AE1 Z M AH0 S AH0 N'],
  "rasnake": ['R AE1 S N AH0 K'],
  "rasner": ['R AE1 S N ER0'],
  "rasnick": ['R AE1 S N IH0 K'],
  "raso": ['R AA1 S OW0'],
  "rasor": ['R EY1 Z ER0'],
  "rasorite": ['R AE1 S ER0 AY2 T'],
  "rasp": ['R AE1 S P'],
  "raspberries": ['R AE1 Z B EH2 R IY0 Z'],
  "raspberry": ['R AE1 Z B EH2 R IY0'],
  "rasped": ['R AE1 S P T'],
  "rasps": ['R AE1 S P S'],
  "rasputin": ['R AH0 S P Y UW1 T IH0 N'],
  "rasputin's": ['R AE0 S P Y UW1 T AH0 N Z'],
  "raspy": ['R AE1 S P IY0'],
  "rast": ['R AE1 S T'],
  "raster": ['R AE1 S T ER0'],
  "rasterop": ['R AE1 S T ER0 AA2 P'],
  "rasterops": ['R AE1 S T ER0 AA2 P S'],
  "rastetter": ['R EY1 S T IH0 T ER0'],
  "rastus": ['R AE1 S T AH0 S'],
  "rat": ['R AE1 T'],
  "rat's": ['R AE1 T S'],
  "rat-a-tat": ['R AE1 T AH0 T AE1 T'],
  "rata": ['R AE1 T AH0'],
  "ratajczak": ['R AE1 T AH0 CH EH0 K'],
  "ratatisement": ['R AE1 T AH0 T AY2 Z M AH0 N T'],
  "ratatisements": ['R AE1 T AH0 T AY2 Z M AH0 N T S'],
  "ratatouille": ['R AE2 T AH0 T UW1 Y'],
  "ratay": ['R AE1 T EY0'],
  "ratchet": ['R AE1 CH AH0 T', 'R AE0 T CH AH0 T'],
  "ratcheted": ['R AE1 CH AH0 T IH0 D'],
  "ratcheting": ['R AE1 CH AH0 T IH0 NG'],
  "ratchford": ['R AE1 CH F ER0 D'],
  "ratcliff": ['R AE1 T K L IH0 F'],
  "ratcliffe": ['R AE1 T K L IH0 F'],
  "rate": ['R EY1 T'],
  "rate's": ['R EY1 T S'],
  "rated": ['R EY1 T IH0 D'],
  "rateliff": ['R AE1 T L IH0 F'],
  "ratepayer": ['R EY1 T P EY2 ER0'],
  "ratepayers": ['R EY1 T P EY2 ER0 Z'],
  "ratepayers'": ['R EY1 T P EY2 ER0 Z'],
  "rater": ['R EY1 T ER0'],
  "raters": ['R EY1 T ER0 Z'],
  "rates": ['R EY1 T S'],
  "rath": ['R AE1 TH'],
  "rathbone": ['R AE1 TH B OW2 N'],
  "rathbun": ['R AE1 TH B AH0 N'],
  "rathburn": ['R AE1 TH B ER0 N'],
  "rathburne": ['R AE1 TH B ER0 N'],
  "rathburne's": ['R AE1 TH B ER0 N Z'],
  "rathe": ['R EY1 DH'],
  "rathel": ['R AE1 TH AH0 L'],
  "rather": ['R AE1 DH ER0', 'R AH1 DH ER0'],
  "rather's": ['R AE1 DH ER0 Z'],
  "rathert": ['R AE1 TH ER0 T'],
  "rathfarnham": ['R AH2 DH F AA1 R N AH0 M'],
  "rathgeber": ['R AE1 TH G IH0 B ER0'],
  "rathje": ['R AE1 TH JH AH0'],
  "rathjen": ['R AE1 TH JH AH0 N'],
  "rathke": ['R AE1 TH K AH0'],
  "rathman": ['R AE1 TH M AH0 N'],
  "rathmann": ['R AE1 TH M AH0 N'],
  "rathskeller": ['R AE1 TH S K EH2 L ER0'],
  "ratican": ['R AE1 T IH0 K AH0 N'],
  "ratieni": ['R AH0 T IY1 N IY0'],
  "ratier": ['R EY1 T Y ER0'],
  "ratification": ['R AE2 T AH0 F AH0 K EY1 SH AH0 N'],
  "ratified": ['R AE1 T AH0 F AY2 D'],
  "ratifies": ['R AE1 T AH0 F AY2 Z'],
  "ratify": ['R AE1 T AH0 F AY2'],
  "ratifying": ['R AE1 T AH0 F AY2 IH0 NG'],
  "rating": ['R EY1 T IH0 NG'],
  "ratings": ['R EY1 T IH0 NG Z'],
  "ratio": ['R EY1 SH IY0 OW2'],
  "ration": ['R AE1 SH AH0 N', 'R EY1 SH AH0 N'],
  "rational": ['R AE1 SH AH0 N AH0 L', 'R AE1 SH N AH0 L'],
  "rationale": ['R AE2 SH AH0 N AE1 L'],
  "rationales": ['R AE2 SH AH0 N AE1 L Z'],
  "rationality": ['R AE2 SH AH0 N AE1 L IH0 T IY0'],
  "rationalization": ['R AE2 SH AH0 N AH0 L IH0 Z EY1 SH AH0 N', 'R AE2 SH N AH0 L IH0 Z EY1 SH AH0 N'],
  "rationalizations": ['R AE2 SH AH0 N AH0 L IH0 Z EY1 SH AH0 N Z', 'R AE2 SH N AH0 L IH0 Z EY1 SH AH0 N Z'],
  "rationalize": ['R AE1 SH AH0 N AH0 L AY2 Z'],
  "rationalized": ['R AE1 SH AH0 N AH0 L AY2 Z D'],
  "rationalizing": ['R AE1 SH AH0 N AH0 L AY2 Z IH0 NG'],
  "rationally": ['R AE1 SH AH0 N AH0 L IY0', 'R AE1 SH N AH0 L IY0'],
  "rationed": ['R AE1 SH AH0 N D', 'R EY1 SH AH0 N D'],
  "rationing": ['R AE1 SH AH0 N IH0 NG', 'R AE1 SH N IH0 NG', 'R EY1 SH AH0 N IH0 NG'],
  "rations": ['R AE1 SH AH0 N Z'],
  "ratios": ['R EY1 SH IY0 OW2 Z'],
  "ratko": ['R AE1 T K OW0'],
  "ratkovich": ['R AA1 T K AH0 V IH0 CH'],
  "ratkowski": ['R AH0 T K AO1 F S K IY0'],
  "ratledge": ['R AE1 T L IH0 JH'],
  "ratley": ['R AE1 T L IY0'],
  "ratliff": ['R AE1 T L IH0 F'],
  "ratliffe": ['R AE1 T L IH0 F'],
  "ratliffe's": ['R AE1 T L IH0 F S'],
  "ratner": ['R AE1 T N ER0'],
  "ratners": ['R AE1 T N ER0 Z'],
  "raton": ['R AH0 T OW1 N'],
  "rats": ['R AE1 T S'],
  "rattan": ['R AE0 T AE1 N'],
  "rattatouille": ['R AE0 T AH0 T UW1 IY0'],
  "ratte": ['R AE1 T'],
  "ratterman": ['R AE1 T ER0 M AH0 N'],
  "ratterree": ['R AE1 T ER0 IY1'],
  "rattigan": ['R AE1 T IH0 G AH0 N'],
  "rattigan's": ['R AE1 T IH0 G AH0 N Z'],
  "rattle": ['R AE1 T AH0 L'],
  "rattled": ['R AE1 T AH0 L D'],
  "rattler": ['R AE1 T AH0 L ER0', 'R AE1 T L ER0'],
  "rattles": ['R AE1 T AH0 L Z'],
  "rattlesnake": ['R AE1 T AH0 L S N EY2 K'],
  "rattlesnakes": ['R AE1 T AH0 L S N EY2 K S'],
  "rattling": ['R AE1 T L IH0 NG', 'R AE1 T AH0 L IH0 NG'],
  "rattner": ['R AE1 T N ER0'],
  "rattray": ['R AE1 T R EY0'],
  "ratts": ['R AE1 T S'],
  "ratty": ['R AE1 T IY0'],
  "ratu": ['R AA0 T UW1'],
  "ratz": ['R AE1 T S'],
  "ratzinger": ['R AE1 T S IH2 NG ER0'],
  "ratzlaff": ['R AE1 T Z L AH0 F'],
  "rau": ['R AW1'],
  "raub": ['R AO1 B'],
  "rauber": ['R AW1 B ER0'],
  "raucci": ['R AO1 CH IY0'],
  "rauch": ['R AO1 CH'],
  "raucher": ['R AO1 CH ER0'],
  "raucher's": ['R AO1 CH ER0 Z'],
  "raucous": ['R AO1 K AH0 S'],
  "raudabaugh": ['R AO1 D AH0 B AO0'],
  "raudenbush": ['R AW1 D IH0 N B UH0 SH'],
  "rauen": ['R AW1 AH0 N'],
  "rauer": ['R AW1 ER0'],
  "rauh": ['R AO1'],
  "raul": ['R AO1 L', 'R AA0 UW1 L', 'R AW1 L'],
  "raulerson": ['R AO1 L ER0 S AH0 N'],
  "rauls": ['R AA0 UW1 L Z'],
  "raulston": ['R AO1 L S T AH0 N'],
  "raum": ['R AO1 M'],
  "raun": ['R AO1 N'],
  "raunchy": ['R AO1 N CH IY0'],
  "raup": ['R AO1 P'],
  "raupp": ['R AO1 P'],
  "rausch": ['R AW1 SH'],
  "rauschenberg": ['R AW1 SH AH0 N B ER0 G'],
  "rauscher": ['R AW1 SH ER0'],
  "rauser": ['R AW1 S ER0'],
  "raut": ['R AW1 T'],
  "rautenberg": ['R AW1 T AH0 N B ER0 G'],
  "rauth": ['R AO1 TH'],
  "rautio": ['R AW1 T IY0 OW0'],
  "ravage": ['R AE1 V IH0 JH'],
  "ravaged": ['R AE1 V IH0 JH D'],
  "ravages": ['R AE1 V IH0 JH IH0 Z'],
  "ravaging": ['R AE1 V IH0 JH IH0 NG'],
  "ravan": ['R EY1 V AH0 N'],
  "rave": ['R EY1 V'],
  "raved": ['R EY1 V D'],
  "ravel": ['R AE1 V AH0 L', 'R AH0 V EH1 L'],
  "ravel's": ['R AH0 V EH1 L Z'],
  "raveled": ['R AE1 V AH0 L D'],
  "raveling": ['R AE1 V AH0 L IH0 NG', 'R AE1 V L IH0 NG'],
  "ravelo": ['R AA0 V EH1 L OW0'],
  "raven": ['R EY1 V AH0 N'],
  "ravenel": ['R AE1 V IH0 N EH0 L'],
  "ravenell": ['R AE1 V IH0 N EH0 L'],
  "ravenna": ['R AH0 V EH1 N AH0'],
  "ravenous": ['R AE1 V AH0 N AH0 S'],
  "ravens": ['R EY1 V AH0 N Z'],
  "ravenscraft": ['R EY1 V AH0 N Z K R AE2 F T'],
  "ravenscroft": ['R EY1 V AH0 N Z K R AO2 F T'],
  "ravenswood": ['R EY1 V AH0 N Z W UH2 D'],
  "raver": ['R EY1 V ER0'],
  "raves": ['R EY1 V Z'],
  "ravi": ['R AA2 V IY1'],
  "ravi's": ['R AA2 V IY1 S'],
  "ravin": ['R AE1 V IH0 N'],
  "ravin's": ['R AE1 V IH0 N Z'],
  "ravindran": ['R AA1 V IH2 N D R AH0 N'],
  "ravine": ['R AH0 V IY1 N'],
  "ravines": ['R AH0 V IY1 N Z'],
  "raving": ['R EY1 V IH0 NG'],
  "ravinous": ['R EY1 V IH0 N IH0 S'],
  "ravinus": ['R EY1 V AH0 N IH0 S'],
  "ravioli": ['R AE2 V IY0 OW1 L IY0'],
  "ravishankar": ['R AA2 V IY0 SH AA1 NG K AA2 R'],
  "ravishing": ['R AE1 V IH0 SH IH0 NG'],
  "ravitch": ['R AE1 V IH0 CH'],
  "ravitz": ['R AE1 V IH0 T S'],
  "raw": ['R AA1'],
  "rawalpindi": ['R AE2 W AO0 L P IH1 N D IY0'],
  "rawding": ['R AO1 D IH0 NG'],
  "rawdon": ['R AO1 D AH0 N'],
  "rawe": ['R AO1'],
  "rawest": ['R AA1 AH0 S T'],
  "rawhide": ['R AO1 HH AY2 D'],
  "rawl": ['R AO1 L'],
  "rawles": ['R AO1 L Z'],
  "rawley": ['R AO1 L IY0'],
  "rawling": ['R AO1 L IH0 NG'],
  "rawlings": ['R AO1 L IH0 NG Z'],
  "rawlins": ['R AO1 L IH0 N Z'],
  "rawlinson": ['R AO1 L IH0 N S AH0 N'],
  "rawls": ['R AO1 L Z'],
  "rawness": ['R AO1 N IH0 S'],
  "rawski": ['R AO1 S K IY2'],
  "rawson": ['R AO1 S AH0 N'],
  "rax": ['R AE1 K S'],
  "raxter": ['R AE1 K S T ER0'],
  "ray": ['R EY1'],
  "ray's": ['R EY1 Z'],
  "raya": ['R EY1 AH0'],
  "raybon": ['R EY1 B AH0 N'],
  "rayborn": ['R EY1 B ER0 N'],
  "raybould": ['R EY1 B OW2 L D'],
  "raybourn": ['R EY0 B UH1 R N'],
  "raybuck": ['R EY1 B AH2 K'],
  "rayburn": ['R EY1 B ER0 N'],
  "rayburn's": ['R EY1 B ER2 N Z'],
  "raychem": ['R EY1 K EH2 M'],
  "raycon": ['R EY1 K AH0 N'],
  "raycraft": ['R EY1 K R AE2 F T'],
  "raye": ['R EY1'],
  "rayed": ['R EY1 D'],
  "rayfield": ['R EY1 F IY2 L D'],
  "rayford": ['R EY1 F ER0 D'],
  "raygoza": ['R EY2 G OW1 Z AH0'],
  "rayl": ['R EY1 L'],
  "rayle": ['R EY1 L'],
  "rayman": ['R EY1 M AH0 N'],
  "raymark": ['R EY1 M AA2 R K'],
  "raymark's": ['R EY1 M AA2 R K S'],
  "rayment": ['R EY1 M AH0 N T'],
  "raymer": ['R EY1 M ER0'],
  "raymo": ['R EY1 M OW0'],
  "raymond": ['R EY1 M AH0 N D'],
  "raymonda": ['R EY2 M AA1 N D AH0'],
  "raymund": ['R EY1 M AH0 N D'],
  "raymundo": ['R EY2 M AH1 N D OW0'],
  "raynard": ['R EY1 N ER0 D'],
  "rayne": ['R EY1 N'],
  "rayner": ['R EY1 N ER0'],
  "raynes": ['R EY1 N Z'],
  "raynet": ['R EY1 N EH2 T'],
  "rayno": ['R EY1 N OW0'],
  "raynor": ['R EY1 N ER0'],
  "rayon": ['R EY1 AH0 N'],
  "rayonier": ['R EY2 OW0 N IH1 R'],
  "rayos": ['R EY1 OW0 Z'],
  "rayovac": ['R EY1 AH0 V AE0 K'],
  "rayrock": ['R EY1 R AA2 K'],
  "rays": ['R EY1 Z'],
  "rayson": ['R EY1 Z AH0 N'],
  "raysor": ['R EY1 S ER0'],
  "raytech": ['R EY1 T EH2 K'],
  "raytheon": ['R EY1 TH IY0 AA0 N'],
  "raytheon's": ['R EY1 TH IY0 AA0 N Z'],
  "raz": ['R AE1 Z'],
  "raza": ['R AE1 Z AH0'],
  "razaleigh": ['R AE1 Z AH0 L IY2'],
  "raze": ['R EY1 Z'],
  "razed": ['R EY1 Z D'],
  "razing": ['R EY1 Z IH0 NG'],
  "razo": ['R AA1 Z OW0'],
  "razor": ['R EY1 Z ER0'],
  "razor's": ['R EY1 Z ER0 Z'],
  "razorback": ['R EY1 Z ER0 B AE2 K'],
  "razorback's": ['R EY1 Z ER0 B AE2 K S'],
  "razorbacks": ['R EY1 Z ER0 B AE2 K S'],
  "razors": ['R EY1 Z ER0 Z'],
  "razr": ['R AE1 Z R'],
  "razzano": ['R AA0 T S AA1 N OW0'],
  "razzle": ['R AE1 Z AH0 L'],
  "razzmatazz": ['R AE1 Z M AH0 T AE1 Z'],
  "rca": ['AA2 R S IY2 EY1'],
  "re": ['R EY1', 'R IY1'],
  "re's": ['R EY1 Z'],
  "re-entered": ['R IY2 EH1 N T ER0 D'],
  "rea": ['R EY1'],
  "rea's": ['R EY1 Z'],
  "reabsorb": ['R IY2 AH0 B Z AO1 R B'],
  "reabsorbed": ['R IY2 AH0 B Z AO1 R B D'],
  "reach": ['R IY1 CH'],
  "reachable": ['R IY1 CH AH0 B AH0 L'],
  "reached": ['R IY1 CH T'],
  "reaches": ['R IY1 CH AH0 Z', 'R IY1 CH IH0 Z'],
  "reaching": ['R IY1 CH IH0 NG'],
  "reacquire": ['R IY0 AH0 K W AY1 R'],
  "reacquired": ['R IY0 AH0 K W AY1 R D'],
  "react": ['R IY0 AE1 K T'],
  "reacted": ['R IY0 AE1 K T IH0 D'],
  "reacting": ['R IY0 AE1 K T IH0 NG'],
  "reaction": ['R IY0 AE1 K SH AH0 N'],
  "reactionaries": ['R IY0 AE1 K SH AH0 N EH2 R IY0 Z'],
  "reactionary": ['R IY0 AE1 K SH AH0 N EH2 R IY0'],
  "reactions": ['R IY0 AE1 K SH AH0 N Z'],
  "reactivate": ['R IY1 AE1 K T AH0 V EY2 T'],
  "reactivated": ['R IY1 AE1 K T AH0 V EY2 T IH0 D'],
  "reactivating": ['R IY1 AE1 K T IH0 V EY2 T IH0 NG'],
  "reactive": ['R IY0 AE1 K T IH0 V'],
  "reactivity": ['R IY2 AE2 K T IH1 V AH0 T IY0'],
  "reactor": ['R IY0 AE1 K T ER0'],
  "reactor's": ['R IY0 AE1 K T ER0 Z'],
  "reactors": ['R IY0 AE1 K T ER0 Z'],
  "reacts": ['R IY0 AE1 K T S'],
  "read": ['R EH1 D', 'R IY1 D'],
  "read's": ['R IY1 D Z'],
  "readability": ['R IY2 D AH0 B IH1 L IH0 T IY0'],
  "readable": ['R IY1 D AH0 B AH0 L'],
  "reade": ['R EH1 D'],
  "reader": ['R IY1 D ER0'],
  "reader's": ['R IY1 D ER0 Z'],
  "readerman": ['R IY1 D ER0 M AH0 N'],
  "readers": ['R IY1 D ER0 Z'],
  "readers'": ['R IY1 D ER0 Z'],
  "readership": ['R IY1 D ER0 SH IH2 P'],
  "readied": ['R EH1 D IY0 D'],
  "readier": ['R EH1 D IY0 ER0'],
  "readies": ['R EH1 D IY0 Z'],
  "readily": ['R EH1 D AH0 L IY0'],
  "readiness": ['R EH1 D IY0 N AH0 S'],
  "reading": ['R IY1 D IH0 NG', 'R EH1 D IH0 NG'],
  "readinger": ['R EH1 D IH0 NG ER0'],
  "readings": ['R IY1 D IH0 NG Z'],
  "readjust": ['R IY2 AH0 JH AH1 S T'],
  "readjusted": ['R IY2 AH0 JH AH1 S T IH0 D'],
  "readjusting": ['R IY2 AH0 JH AH1 S T IH0 NG'],
  "readjustment": ['R IY0 AH0 JH AH1 S T M AH0 N T'],
  "readjustments": ['R IY0 AH0 JH AH1 S T M AH0 N T S'],
  "readmission": ['R IY2 AE0 D M IH1 SH AH0 N'],
  "readmit": ['R IY2 AH0 D M IH1 T'],
  "readmitted": ['R IY2 AH0 D M IH1 T IH0 D'],
  "readout": ['R IY1 D AW2 T'],
  "reads": ['R IY1 D Z'],
  "readus": ['R EH1 D IH0 S'],
  "ready": ['R EH1 D IY0'],
  "readying": ['R EH1 D IY0 IH0 NG'],
  "reaffiliation": ['R IY0 AH0 F IH2 L IY0 EY1 SH AH0 N'],
  "reaffirm": ['R IY2 AH0 F ER1 M'],
  "reaffirmation": ['R IY2 AE0 F ER0 M EY1 SH AH0 N'],
  "reaffirmed": ['R IY2 AH0 F ER1 M D'],
  "reaffirming": ['R IY2 AH0 F ER1 M IH0 NG'],
  "reaffirms": ['R IY2 AH0 F ER1 M Z'],
  "reagan": ['R EY1 G AH0 N', 'R IY1 G AH0 N'],
  "reagan's": ['R EY1 G AH0 N Z', 'R IY1 G AH0 N Z'],
  "reaganesque": ['R EY1 G AH0 N EH1 S K', 'R IY1 G AH0 N EH1 S K'],
  "reaganism": ['R EY1 G AH0 N IH2 Z AH0 M', 'R IY1 G AH0 N IH2 Z AH0 M'],
  "reaganite": ['R EY1 G AH0 N AY2 T', 'R IY1 G AH0 N AY2 T'],
  "reaganites": ['R EY1 G AH0 N AY2 T S'],
  "reaganomics": ['R EY2 G AH0 N AA1 M IH0 K S'],
  "reagans": ['R EY1 G AH0 N Z'],
  "reagans'": ['R EY1 G AH0 N Z', 'R IY1 G AH0 N Z'],
  "reagen": ['R IY1 G AH0 N'],
  "reagent": ['R IY0 EY1 JH AH0 N T', 'R IY1 JH AH0 N T'],
  "reagents": ['R IY0 EY1 JH AH0 N T S', 'R IY1 JH AH0 N T S'],
  "reagle": ['R IY1 G AH0 L'],
  "reagor": ['R IY1 G ER0'],
  "real": ['R IY1 L'],
  "real-estate": ['R IY1 L IH0 S T EY2 T'],
  "real-life": ['R IY1 L L AY2 F'],
  "real-time": ['R IY1 L T AY2 M'],
  "real-world": ['R IY1 L W ER2 L D'],
  "realamerica": ['R IY2 L AH0 M EH1 R IH0 K AH0'],
  "realcap": ['R IY1 L K AE2 P'],
  "reale": ['R IY1 AE2 L'],
  "realestate": ['R IY1 L IH0 S T EY2 T'],
  "reali": ['R IY1 L IY0'],
  "realign": ['R IY0 AH0 L AY1 N'],
  "realigned": ['R IY0 AH0 L AY1 N D'],
  "realigning": ['R IY0 AH0 L AY1 N IH0 NG'],
  "realignment": ['R IY0 AH0 L AY1 N M AH0 N T'],
  "realignments": ['R IY0 AH0 L AY1 N M AH0 N T S'],
  "realisable": ['R IY2 AH0 L AY1 Z AH0 B AH0 L'],
  "realisation": ['R IY1 L AH0 Z EY1 SH AH0 N'],
  "realisations": ['R IY1 L AH0 Z EY1 SH AH0 N Z'],
  "realise": ['R IY1 AH0 L AY2 Z'],
  "realised": ['R IY1 AH0 L AY2 Z D'],
  "realises": ['R IY1 AH0 L AY2 Z IH0 Z'],
  "realism": ['R IY1 AH0 L IH0 Z M'],
  "realist": ['R IY1 AH0 L IH0 S T'],
  "realistic": ['R IY2 AH0 L IH1 S T IH0 K'],
  "realistically": ['R IY2 AH0 L IH1 S T IH0 K L IY0'],
  "realists": ['R IY1 AH0 L IH0 S T S'],
  "realities": ['R IY2 AE1 L AH0 T IY2 Z'],
  "reality": ['R IY2 AE1 L AH0 T IY2'],
  "realizable": ['R IY2 AH0 L AY1 Z AH0 B AH0 L'],
  "realization": ['R IY1 L AH0 Z EY1 SH AH0 N'],
  "realizations": ['R IY1 L AH0 Z EY1 SH AH0 N Z'],
  "realize": ['R IY1 AH0 L AY2 Z'],
  "realized": ['R IY1 AH0 L AY2 Z D'],
  "realizes": ['R IY1 AH0 L AY2 Z IH0 Z'],
  "realizing": ['R IY1 AH0 L AY2 Z IH0 NG'],
  "reallocate": ['R IY0 AE1 L AH0 K EY0 T'],
  "reallocated": ['R IY2 AE1 L AH0 K EY2 T IH0 D'],
  "reallocating": ['R IY0 AE1 L AH0 K EY2 T IH0 NG'],
  "reallocation": ['R IY0 AE2 L AH0 K EY1 SH AH0 N'],
  "reallowance": ['R IY1 AH0 L AW1 AH0 N S'],
  "really": ['R IH1 L IY0', 'R IY1 L IY0'],
  "realm": ['R EH1 L M'],
  "realms": ['R EH1 L M Z'],
  "realmuto": ['R AH0 L M UW1 T OW0'],
  "realpolitik": ['R IY2 L P AO2 L IH0 T IH1 K'],
  "realtime": ['R IY1 AH0 L T AY2 M'],
  "realtor": ['R IY1 AH0 L T ER0', 'R IY1 L T ER0'],
  "realtor's": ['R IY1 AH0 L T ER0 Z', 'R IY1 L T ER0 Z'],
  "realtors": ['R IY1 AH0 L T ER0 Z', 'R IY1 L T ER0 Z'],
  "realty": ['R IY1 AH0 L T IY2', 'R IH1 L IH0 T IY2', 'R IY1 L T IY0'],
  "realty's": ['R IY1 AH0 L T IY2 Z', 'R IY1 L T IY0 Z'],
  "ream": ['R IY1 M'],
  "reamer": ['R IY1 M ER0'],
  "reamer's": ['R IY1 M ER0 Z'],
  "reamers": ['R IY1 M ER0 Z'],
  "reames": ['R IY1 M Z'],
  "reams": ['R IY1 M Z'],
  "reamy": ['R IY1 M IY0'],
  "reanalyze": ['R IY0 AE1 N AH0 L AY2 Z'],
  "reanalyzed": ['R IY0 AE1 N AH0 L AY2 Z D'],
  "reanalyzes": ['R IY0 AE1 N AH0 L AY2 Z AH0 Z'],
  "reanalyzing": ['R IY0 AE1 N AH0 L AY2 Z IH0 NG'],
  "reaney": ['R IY1 N IY0'],
  "reap": ['R IY1 P'],
  "reaped": ['R IY1 P T'],
  "reaper": ['R IY1 P ER0'],
  "reaping": ['R IY1 P IH0 NG'],
  "reappear": ['R IY2 AH0 P IH1 R'],
  "reappearance": ['R IY2 AH0 P IH1 R AH0 N S'],
  "reappeared": ['R IY2 AH0 P IH1 R D'],
  "reappears": ['R IY2 AH0 P IH1 R Z'],
  "reapply": ['R IY2 AH0 P L AY0'],
  "reappoint": ['R IY2 AH0 P OY1 N T'],
  "reappointed": ['R IY2 AH0 P OY1 N T IH0 D', 'R IY2 AH0 P OY1 N IH0 D'],
  "reappointment": ['R IY2 AH0 P OY1 N T M AH0 N T'],
  "reapportionment": ['R IY2 AH0 P AO1 R SH AH0 N M AH0 N T'],
  "reappraisal": ['R IY2 AH0 P R EY1 Z AH0 L'],
  "reappraise": ['R IY2 AH0 P R EY1 Z'],
  "reappraised": ['R IY0 AH0 P R EY1 Z D'],
  "reaps": ['R IY1 P S'],
  "rear": ['R IH1 R'],
  "rearden": ['R IH1 R D AH0 N'],
  "reardon": ['R IH1 R D AH0 N'],
  "reared": ['R IH1 R D'],
  "rearick": ['R IH1 R IH0 K'],
  "rearing": ['R IH1 R IH0 NG'],
  "rearm": ['R IY1 AA1 R M'],
  "rearmament": ['R IY0 AA1 R M AH0 M AH0 N T'],
  "rearming": ['R IY1 AA1 R M IH0 NG'],
  "rearrange": ['R IY2 ER0 EY1 N JH'],
  "rearranged": ['R IY2 ER0 EY1 N JH D'],
  "rearrangement": ['R IY0 ER0 EY1 N JH M AH0 N T'],
  "rearranging": ['R IY2 ER0 EY1 N JH IH0 NG'],
  "rearrest": ['R IY0 ER0 EH1 S T'],
  "rearrested": ['R IY0 ER0 EH1 S T IH0 D'],
  "rears": ['R IH1 R Z'],
  "rearview": ['R IH1 R V Y UW0', 'R IY1 R V Y UW0'],
  "reas": ['R IY1 Z'],
  "rease": ['R IY1 S'],
  "reaser": ['R IY1 S ER0'],
  "reasner": ['R IY1 S N ER0'],
  "reason": ['R IY1 Z AH0 N'],
  "reasonable": ['R IY1 Z AH0 N AH0 B AH0 L', 'R IY1 Z N AH0 B AH0 L'],
  "reasonableness": ['R IY1 Z AH0 N AH0 B AH2 L N AH0 S', 'R IY1 Z N AH0 B AH2 L N AH0 S'],
  "reasonably": ['R IY1 Z AH0 N AH0 B L IY0'],
  "reasoned": ['R IY1 Z AH0 N D'],
  "reasoner": ['R IY1 Z AH0 N ER0'],
  "reasoner's": ['R IY1 Z AH0 N ER0 Z'],
  "reasoning": ['R IY1 Z AH0 N IH0 NG'],
  "reasonover": ['R IY1 Z AH0 N OW2 V ER0'],
  "reasons": ['R IY1 Z AH0 N Z'],
  "reasor": ['R IY1 S ER0'],
  "reassemble": ['R IY2 AH0 S EH1 M B AH0 L'],
  "reassembled": ['R IY2 AH0 S EH1 M B AH0 L D'],
  "reassembly": ['R IY2 AH0 S EH1 M B L IY0'],
  "reassert": ['R IY2 AH0 S ER1 T'],
  "reasserted": ['R IY2 AH0 S ER1 T IH0 D'],
  "reasserting": ['R IY2 AH0 S ER1 T IH0 NG'],
  "reassertion": ['R IY2 AH0 S ER1 SH AH0 N'],
  "reasserts": ['R IY2 AH0 S ER1 T S'],
  "reassess": ['R IY0 AH0 S EH1 S'],
  "reassessed": ['R IY0 AH0 S EH1 S T'],
  "reassessing": ['R IY0 AH0 S EH1 S IH0 NG'],
  "reassessment": ['R IY2 AH0 S EH1 S M AH0 N T'],
  "reassign": ['R IY2 AH0 S AY1 N'],
  "reassigned": ['R IY2 AH0 S AY1 N D'],
  "reassigning": ['R IY2 AH0 S AY1 N IH0 NG'],
  "reassignment": ['R IY0 AH0 S AY1 N M AH0 N T'],
  "reassignments": ['R IY2 AH0 S AY1 N M AH0 N T S'],
  "reassume": ['R IY0 AH0 S UW1 M'],
  "reassumed": ['R IY0 AH0 S UW1 M D'],
  "reassurance": ['R IY2 AH0 SH UH1 R AH0 N S'],
  "reassurances": ['R IY2 AH0 SH ER1 AH0 N S IH0 Z', 'R IY2 AH0 SH UH1 R AH0 N S IH0 Z'],
  "reassure": ['R IY2 AH0 SH UH1 R'],
  "reassured": ['R IY2 AH0 SH UH1 R D'],
  "reassures": ['R IY2 AH0 SH UH1 R Z'],
  "reassuring": ['R IY2 AH0 SH UH1 R IH0 NG'],
  "reassuringly": ['R IY0 AH0 SH ER1 IH0 NG L IY0', 'R IY0 AH0 SH UH1 R IH0 NG L IY0'],
  "reatta": ['R IY2 AA1 T AH0'],
  "reattach": ['R IY1 AH0 T AE1 CH'],
  "reattached": ['R IY1 AH0 T AE1 CH T'],
  "reaume": ['R UW1 M'],
  "reauthorization": ['R IY0 AO2 TH ER0 IH0 Z EY1 SH AH0 N'],
  "reauthorize": ['R IY0 AO1 TH ER0 AY2 Z'],
  "reauthorized": ['R IY0 AO1 TH ER0 AY2 Z D'],
  "reauthorizing": ['R IY0 AO1 TH ER0 AY2 Z IH0 NG'],
  "reaux": ['R OW1'],
  "reave": ['R IY1 V'],
  "reaver": ['R IY1 V ER0'],
  "reaver's": ['R IY1 V ER0 Z'],
  "reaves": ['R IY1 V Z'],
  "reavis": ['R IY1 V IH0 S'],
  "reawaken": ['R IY2 AH0 W EY1 K AH0 N'],
  "reawakened": ['R IY2 AH0 W EY1 K AH0 N D'],
  "reawakening": ['R IY2 AH0 W EY1 K AH0 N IH0 NG'],
  "reay": ['R EY1'],
  "reba": ['R IY1 B AH0'],
  "reback": ['R IY1 B AE0 K'],
  "rebalancing": ['R IY0 B AE1 L AH0 N S IH0 NG'],
  "rebar": ['R IY1 B AA2 R'],
  "rebate": ['R IY1 B EY2 T'],
  "rebated": ['R IY1 B EY2 T IH0 D'],
  "rebates": ['R IY1 B EY2 T S'],
  "rebbe": ['R IY1 B AH0'],
  "rebbe's": ['R IY1 B AH0 Z'],
  "rebecca": ['R AH0 B EH1 K AH0'],
  "rebecca's": ['R AH0 B EH1 K AH0 Z'],
  "rebeck": ['R IY1 B EH0 K'],
  "rebeka": ['R IH0 B IY1 K AH0'],
  "rebekka": ['R IH0 B EH1 K AH0'],
  "rebel": ['R EH1 B AH0 L', 'R IH0 B EH1 L'],
  "rebel's": ['R EH1 B AH0 L Z'],
  "rebelled": ['R IH0 B EH1 L D'],
  "rebelling": ['R IH0 B EH1 L IH0 NG'],
  "rebellion": ['R IH0 B EH1 L Y AH0 N'],
  "rebellion's": ['R IH0 B EH1 L Y AH0 N Z'],
  "rebellions": ['R IH0 B EH1 L Y AH0 N Z'],
  "rebellious": ['R IH0 B EH1 L Y AH0 S', 'R AH0 B EH1 L Y AH0 S'],
  "rebelliousness": ['R AH0 B EH1 L IY0 AH0 S N AH0 S'],
  "rebello": ['R EH0 B EH1 L OW0'],
  "rebelo": ['R EH0 B EH1 L OW0'],
  "rebels": ['R EH1 B AH0 L Z', 'R IH0 B EH1 L Z'],
  "rebels'": ['R EH1 B AH0 L Z'],
  "reber": ['R EH1 B ER0'],
  "rebert": ['R EH1 B ER0 T'],
  "rebholz": ['R EH1 B HH OW0 L Z'],
  "rebid": ['R IY0 B IH1 D'],
  "rebirth": ['R IY0 B ER1 TH'],
  "rebirths": ['R IY0 B ER1 TH S'],
  "rebman": ['R EH1 B M AH0 N'],
  "rebmann": ['R EH1 B M AH0 N'],
  "rebo": ['R IY1 B OW0'],
  "reboard": ['R IY0 B AO1 R D'],
  "rebook": ['R IY0 B UH1 K'],
  "rebooked": ['R IY0 B UH1 K T'],
  "reboot": ['R IY0 B UW1 T'],
  "reboots": ['R IY0 B UW1 T S'],
  "reborn": ['R IY1 B AO1 R N'],
  "rebound": ['R IY0 B AW1 N D'],
  "rebounded": ['R IY0 B AW1 N D IH0 D'],
  "rebounding": ['R IY0 B AW1 N D IH0 NG'],
  "rebounds": ['R IY1 B AW2 N D Z'],
  "rebroadcast": ['R IY0 B R AO1 D K AE2 S T'],
  "rebstock": ['R EH1 B S T AA2 K'],
  "rebuck": ['R EH1 B AH0 K'],
  "rebuff": ['R IH0 B AH1 F', 'R IY0 B AH1 F'],
  "rebuffed": ['R IH0 B AH1 F T', 'R IY0 B AH1 F T'],
  "rebuffing": ['R IY0 B AH1 F IH0 NG'],
  "rebuffs": ['R IY0 B AH1 F S'],
  "rebuild": ['R IY0 B IH1 L D'],
  "rebuilder": ['R IY0 B IH1 L D ER0'],
  "rebuilders": ['R IY0 B IH1 L D ER0 Z'],
  "rebuilding": ['R IY0 B IH1 L D IH0 NG'],
  "rebuilds": ['R IY0 B IH1 L D Z'],
  "rebuilt": ['R IY0 B IH1 L T'],
  "rebuke": ['R IY0 B Y UW1 K', 'R IH0 B Y UW1 K'],
  "rebuked": ['R IH0 B Y UW1 K T', 'R IY0 B Y UW1 K T'],
  "rebukes": ['R IY0 B Y UW1 K S'],
  "rebuking": ['R IY0 B Y UW1 K IH0 NG'],
  "rebus": ['R IY1 B AH0 S'],
  "rebut": ['R IH0 B AH1 T', 'R IY0 B AH1 T'],
  "rebuttal": ['R IH0 B AH1 T AH0 L', 'R IY0 B AH1 T AH0 L'],
  "rebuttals": ['R IH0 B AH1 T AH0 L Z'],
  "rebutted": ['R IH0 B AH1 T IH0 D'],
  "rebutting": ['R IH0 B AH1 T IH0 NG', 'R IY0 B AH1 T IH0 NG'],
  "rec": ['R EH1 K'],
  "recadi": ['R IH0 K AE1 D IY0'],
  "recalcitrance": ['R IH0 K AE1 L S AH0 T R AH0 N S'],
  "recalcitrant": ['R IH0 K AE1 L S IH0 T R AH0 N T'],
  "recalculate": ['R IY0 K AE1 L K Y AH0 L EY2 T'],
  "recalculated": ['R IY0 K AE1 L K Y AH0 L EY2 T IH0 D'],
  "recalculating": ['R IY0 K AE1 L K Y AH0 L EY2 T IH0 NG'],
  "recalculation": ['R IY0 K AE2 L K Y AH0 L EY1 SH AH0 N'],
  "recall": ['R IY1 K AO2 L', 'R IH0 K AO1 L'],
  "recalled": ['R IH0 K AO1 L D'],
  "recalling": ['R IH0 K AO1 L IH0 NG'],
  "recalls": ['R IY1 K AO2 L Z', 'R IH0 K AO1 L Z'],
  "recant": ['R IY0 K AE1 N T'],
  "recantation": ['R EH2 K AH0 N T EY1 SH AH0 N', 'R IY0 K AE0 N T EY1 SH AH0 N'],
  "recanted": ['R IY0 K AE1 N T IH0 D'],
  "recanting": ['R AH0 K AE1 N T IH0 NG'],
  "recap": ['R IY1 K AE2 P', 'R IH0 K AE1 P'],
  "recapitalization": ['R IY2 K AE2 P IH0 T AH0 L IH0 Z EY1 SH AH0 N'],
  "recapitalizations": ['R IY0 K AE2 P AH0 T AH0 L AH0 Z EY1 SH AH0 N Z'],
  "recapitalize": ['R IY2 K AE1 P IH0 T AH0 L AY2 Z'],
  "recapitalized": ['R IY2 K AE1 P IH0 T AH0 L AY2 Z D'],
  "recapitalizing": ['R IY2 K AE1 P IH0 T AH0 L AY2 Z IH0 NG'],
  "recapitulate": ['R IY2 K AH0 P IH1 CH AH0 L EY2 T'],
  "recapitulates": ['R IY2 K AH0 P IH1 CH AH0 L EY2 T S'],
  "recapped": ['R IY0 K AE1 P T'],
  "recapping": ['R IY0 K AE1 P IH0 NG'],
  "recaps": ['R IY1 K AE2 P S'],
  "recapture": ['R IY0 K AE1 P CH ER0'],
  "recaptured": ['R IY0 K AE1 P CH ER0 D'],
  "recapturing": ['R IY0 K AE1 P CH ER0 IH0 NG'],
  "recarey": ['R IY0 K EH1 R IY0'],
  "recast": ['R IY0 K AE1 S T'],
  "recasting": ['R IY0 K AE1 S T IH0 NG'],
  "recchia": ['R EH1 K IY0 AH0'],
  "recede": ['R IH0 S IY1 D'],
  "receded": ['R AH0 S IY1 D IH0 D', 'R IH0 S IY1 D IH0 D', 'R IY0 S IY1 D IH0 D'],
  "recedes": ['R IY0 S IY1 D Z'],
  "receding": ['R IH0 S IY1 D IH0 NG', 'R IY0 S IY1 D IH0 NG'],
  "receipt": ['R IH0 S IY1 T', 'R IY0 S IY1 T'],
  "receipts": ['R IH0 S IY1 T S', 'R IY0 S IY1 T S'],
  "receivable": ['R IH0 S IY1 V AH0 B AH0 L'],
  "receivables": ['R IH0 S IY1 V AH0 B AH0 L Z'],
  "receive": ['R AH0 S IY1 V', 'R IH0 S IY1 V', 'R IY0 S IY1 V'],
  "received": ['R AH0 S IY1 V D', 'R IH0 S IY1 V D', 'R IY0 S IY1 V D'],
  "receiver": ['R AH0 S IY1 V ER0', 'R IH0 S IY1 V ER0', 'R IY0 S IY1 V ER0'],
  "receivers": ['R AH0 S IY1 V ER0 Z', 'R IH0 S IY1 V ER0 Z', 'R IY0 S IY1 V ER0 Z'],
  "receivership": ['R IH0 S IY1 V ER0 SH IH2 P', 'R IY0 S IY1 V ER0 SH IH2 P'],
  "receiverships": ['R IH0 S IY1 V ER0 SH IH2 P S'],
  "receives": ['R AH0 S IY1 V Z', 'R IH0 S IY1 V Z', 'R IY0 S IY1 V Z'],
  "receiving": ['R AH0 S IY1 V IH0 NG', 'R IH0 S IY1 V IH0 NG', 'R IY0 S IY1 V IH0 NG'],
  "recent": ['R IY1 S AH0 N T'],
  "recently": ['R IY1 S AH0 N T L IY0', 'R IY1 S AH0 N L IY0'],
  "receptacle": ['R AH0 S EH1 P T AH0 K AH0 L'],
  "receptacles": ['R IH0 S EH1 P T IH0 K AH0 L Z'],
  "receptech": ['R IY1 S EH2 P T EH1 K'],
  "reception": ['R IH0 S EH1 P SH AH0 N', 'R IY0 S EH1 P SH AH0 N'],
  "receptionist": ['R IH0 S EH1 P SH AH0 N IH0 S T', 'R IY0 S EH1 P SH AH0 N IH0 S T'],
  "receptionists": ['R IH0 S EH1 P SH AH0 N IH0 S T S', 'R IY0 S EH1 P SH AH0 N IH0 S T S', 'R IH0 S EH1 P SH AH0 N IH0 S', 'R IY0 S EH1 P SH AH0 N IH0 S'],
  "receptions": ['R IH0 S EH1 P SH AH0 N Z'],
  "receptive": ['R IH0 S EH1 P T IH0 V', 'R IY0 S EH1 P T IH0 V'],
  "receptivity": ['R IY1 S EH2 P T IH1 V IH0 T IY0'],
  "receptor": ['R IY0 S EH1 P T ER0'],
  "receptors": ['R AH0 S EH1 P T ER0 Z'],
  "recertification": ['R IY2 S ER0 T AH0 F AH0 K EY1 SH AH0 N'],
  "recertified": ['R IY0 S ER1 T IH0 F AY0 D'],
  "recertify": ['R IY0 S ER1 T AH0 F AY0'],
  "recertifying": ['R IY0 S ER1 T AH0 F AY0 IH0 NG'],
  "recess": ['R IH0 S EH1 S', 'R IY1 S EH0 S'],
  "recessed": ['R IH0 S EH1 S T', 'R IY1 S EH1 S T'],
  "recesses": ['R IY1 S EH0 S AH0 Z'],
  "recessing": ['R IY2 S EH1 S IH0 NG'],
  "recession": ['R IH0 S EH1 SH AH0 N', 'R IY2 S EH1 SH AH0 N'],
  "recession's": ['R IY2 S EH1 SH AH0 N Z'],
  "recessionary": ['R IY0 S EH1 SH AH0 N EH2 R IY0'],
  "recessions": ['R IH0 S EH1 SH AH0 N Z'],
  "recessive": ['R AH0 S EH1 S IH0 V'],
  "rech": ['R EH1 K'],
  "recharge": ['R IY0 CH AA1 R JH'],
  "rechargeable": ['R IY0 CH AA1 R JH AH0 B AH0 L'],
  "recharged": ['R IY0 CH AA1 R JH D'],
  "recharging": ['R IY0 CH AA1 R JH IH0 NG'],
  "recheck": ['R IY1 CH EH1 K'],
  "rechecked": ['R IY0 CH EH1 K T'],
  "rechristen": ['R IY1 K R IH1 S AH0 N'],
  "rechristened": ['R IY1 K R IH1 S AH0 N D'],
  "recht": ['R EH1 K T'],
  "recidivism": ['R AH0 S IH1 D IH0 V IH2 Z AH0 M'],
  "recidivist": ['R AH0 S IH1 D IH0 V IH2 S T'],
  "recidivists": ['R AH0 S IH1 D IH0 V IH2 S T S'],
  "recine": ['R EH0 CH IY1 N IY0'],
  "recio": ['R EH1 CH IY0 OW0'],
  "recipe": ['R EH1 S AH0 P IY0'],
  "recipe's": ['R EH1 S AH0 P IY0 Z'],
  "recipes": ['R EH1 S AH0 P IY0 Z'],
  "recipient": ['R AH0 S IH1 P IY0 AH0 N T', 'R IH0 S IH1 P IY0 AH0 N T'],
  "recipient's": ['R IH0 S IH1 P IY0 AH0 N T S'],
  "recipients": ['R IH0 S IH1 P IY0 AH0 N T S'],
  "recipients'": ['R IH0 S IH1 P IY0 AH0 N T S'],
  "reciprocal": ['R IH0 S IH1 P R AH0 K AH0 L'],
  "reciprocant": ['R IY0 S IH1 P R AH0 K AH0 N T'],
  "reciprocants": ['R IY0 S IH1 P R AH0 K AH0 N T S'],
  "reciprocate": ['R IH0 S IH1 P R AH0 K EY2 T'],
  "reciprocated": ['R IH0 S IH1 P R AH0 K EY2 T IH0 D'],
  "reciprocates": ['R IH0 S IH1 P R AH0 K EY2 T S'],
  "reciprocating": ['R IH0 S IH1 P R AH0 K EY2 T IH0 NG'],
  "reciprocity": ['R EH2 S IH0 P R AA1 S IH0 T IY0'],
  "recision": ['R IH0 S IH1 ZH AH0 N'],
  "recisions": ['R IH0 S IH1 ZH AH0 N Z'],
  "recission": ['R AH0 S IH1 SH AH0 N'],
  "recital": ['R AH0 S AY1 T AH0 L'],
  "recitals": ['R IH0 S AY1 T AH0 L Z'],
  "recitation": ['R EH2 S AH0 T EY1 SH AH0 N'],
  "recitations": ['R EH2 S IH0 T EY1 SH AH0 N Z'],
  "recitatives": ['R EH2 S AH0 T AH0 T IY1 V Z'],
  "recite": ['R AH0 S AY1 T'],
  "recited": ['R AH0 S AY1 T IH0 D'],
  "recites": ['R IY0 S AY1 T S'],
  "reciting": ['R IY0 S AY1 T IH0 NG'],
  "reck": ['R EH1 K'],
  "reckard": ['R EH1 K ER0 D'],
  "recker": ['R EH1 K ER0'],
  "reckitt": ['R EH1 K IH0 T'],
  "reckless": ['R EH1 K L AH0 S'],
  "recklessly": ['R EH1 K L AH0 S L IY0'],
  "recklessness": ['R EH1 K L AH0 S N AH0 S'],
  "reckner": ['R EH1 K N ER0'],
  "reckon": ['R EH1 K AH0 N'],
  "reckoned": ['R EH1 K AH0 N D'],
  "reckoning": ['R EH1 K AH0 N IH0 NG', 'R EH1 K N IH0 NG'],
  "reckons": ['R EH1 K AH0 N Z'],
  "recktenwald": ['R IH2 K T EH1 N W AH0 L D'],
  "reclaim": ['R IY0 K L EY1 M'],
  "reclaimed": ['R IY0 K L EY1 M D'],
  "reclaimer": ['R IY0 K L EY1 M ER0'],
  "reclaimer's": ['R IY0 K L EY1 M ER0 Z'],
  "reclaiming": ['R IY0 K L EY1 M IH0 NG'],
  "reclamation": ['R EH2 K L AH0 M EY1 SH AH0 N'],
  "reclassification": ['R IY0 K L AE2 S AH0 F AH0 K EY1 SH AH0 N'],
  "reclassified": ['R IY0 K L AE1 S AH0 F AY2 D'],
  "reclassify": ['R IY0 K L AE1 S IH0 F AY2'],
  "reclassifying": ['R IY0 K L AE1 S IH0 F AY2 IH0 NG'],
  "recline": ['R IH0 K L AY1 N'],
  "reclined": ['R IH0 K L AY1 N D'],
  "recliner": ['R IH0 K L AY1 N ER0'],
  "reclines": ['R IH0 K L AY1 N Z'],
  "reclining": ['R IH0 K L AY1 N IH0 NG', 'R IY0 K L AY1 N IH0 NG'],
  "recluse": ['R IH0 K L UW1 S'],
  "reclusive": ['R IH0 K L UW1 S IH0 V', 'R IY0 K L UW1 S IH0 V'],
  "reco": ['R EH1 K OW0'],
  "recognise": ['R EH1 K AH0 G N AY2 Z'],
  "recognised": ['R EH1 K AH0 G N AY2 Z D'],
  "recognises": ['R EH1 K AH0 G N AY2 Z IH0 Z'],
  "recognising": ['R EH1 K AH0 G N AY2 Z IH0 NG'],
  "recognition": ['R EH2 K AH0 G N IH1 SH AH0 N', 'R EH2 K IH0 G N IH1 SH AH0 N'],
  "recognition's": ['R EH2 K IH0 G N IH1 SH AH0 N Z'],
  "recognizable": ['R EH2 K AH0 G N AY1 Z AH0 B AH0 L'],
  "recognizably": ['R EH1 K AH0 G N AY2 Z AH0 B L IY0'],
  "recognizance": ['R IH0 K AA1 N AH0 Z AH0 N S'],
  "recognize": ['R EH1 K AH0 G N AY2 Z'],
  "recognized": ['R EH1 K AH0 G N AY2 Z D'],
  "recognizes": ['R EH1 K AH0 G N AY2 Z AH0 Z', 'R EH1 K AH0 G N AY2 Z IH0 Z'],
  "recognizing": ['R EH1 K AH0 G N AY2 Z IH0 NG'],
  "recoil": ['R IY0 K OY1 L'],
  "recoiled": ['R IY0 K OY1 L D'],
  "recoils": ['R IY0 K OY1 L Z'],
  "recollect": ['R EH2 K AH0 L EH1 K T', 'R IY2 K AH0 L EH1 K T'],
  "recollected": ['R EH2 K AH0 L EH1 K T IH0 D', 'R IY2 K AH0 L EH1 K T IH0 D'],
  "recollecting": ['R EH2 K AH0 L EH1 K T IH0 NG', 'R IY2 K AH0 L EH1 K T IH0 NG'],
  "recollection": ['R EH2 K AH0 L EH1 K SH AH0 N'],
  "recollections": ['R EH2 K AH0 L EH1 K SH AH0 N Z'],
  "recollects": ['R EH2 K AH0 L EH1 K T S', 'R IY2 K AH0 L EH1 K T S'],
  "recombinant": ['R IH0 K AA1 M B IH0 N AH0 N T'],
  "recombination": ['R IY0 K AA2 M B IH0 N EY1 SH AH0 N'],
  "recombine": ['R IY2 K AH0 M B AY1 N'],
  "recommend": ['R EH2 K AH0 M EH1 N D'],
  "recommendation": ['R EH2 K AH0 M AH0 N D EY1 SH AH0 N'],
  "recommendations": ['R EH2 K AH0 M AH0 N D EY1 SH AH0 N Z'],
  "recommended": ['R EH2 K AH0 M EH1 N D IH0 D'],
  "recommending": ['R EH2 K AH0 M EH1 N D IH0 NG'],
  "recommends": ['R EH2 K AH0 M EH1 N D Z'],
  "recommit": ['R IH0 K AA1 M IH0 T', 'R IY2 K AH0 M IH1 T'],
  "recommited": ['R IH0 K AA1 M IH2 T IH0 D', 'R IY2 K AH0 M IH1 T IH0 D'],
  "recommits": ['R IH0 K AA1 M IH0 T S'],
  "recommitted": ['R IY2 K AH0 M IH1 T IH0 D'],
  "recompense": ['R EH1 K AH0 M P EH2 N S'],
  "recon": ['R IY1 K AO0 N'],
  "reconcile": ['R EH1 K AH0 N S AY2 L'],
  "reconciled": ['R EH1 K AH0 N S AY2 L D'],
  "reconciles": ['R EH1 K AH0 N S AY2 L Z'],
  "reconciliation": ['R EH2 K AH0 N S IH2 L IY0 EY1 SH AH0 N'],
  "reconciliations": ['R EH2 K AH0 N S IH2 L IY0 EY1 SH AH0 N Z'],
  "reconciling": ['R EH1 K AH0 N S AY2 L IH0 NG'],
  "recondite": ['R EH1 K AH0 N D AY2 T'],
  "recondition": ['R IY2 K AH0 N D IH1 SH AH0 N'],
  "reconditioned": ['R IY2 K AH0 N D IH1 SH AH0 N D'],
  "reconditioning": ['R IY2 K AH0 N D IH1 SH AH0 N IH0 NG', 'R IY2 K AH0 N D IH1 SH N IH0 NG'],
  "reconfiguration": ['R IY0 K AH0 N F IH2 G Y ER0 EY1 SH AH0 N'],
  "reconfigure": ['R IY2 K AH0 N F IH1 G Y ER0'],
  "reconfigured": ['R IY2 K AH0 N F IH1 G Y ER0 D'],
  "reconfirm": ['R IY2 K AH0 N F ER1 M'],
  "reconfirmation": ['R IY2 K AA2 N F ER0 M EY1 SH AH0 N'],
  "reconfirmed": ['R IY2 K AH0 N F ER1 M D'],
  "reconnaissance": ['R IY0 K AA1 N AH0 S AH0 N S'],
  "reconnect": ['R IY0 K AH0 N EH1 K T'],
  "reconnected": ['R IY0 K AH0 N EH1 K T IH0 D'],
  "reconnecting": ['R IY0 K AH0 N EH1 K T IH0 NG'],
  "reconnects": ['R IY0 K AH0 N EH1 K T S'],
  "reconnoiter": ['R IY2 K AH0 N OY1 T ER0'],
  "reconnoitre": ['R IY2 K AH0 N OY1 T ER0'],
  "reconquer": ['R IY0 K AO1 NG K ER0'],
  "reconquered": ['R IY0 K AO1 NG K ER0 D'],
  "reconsider": ['R IY2 K AH0 N S IH1 D ER0'],
  "reconsideration": ['R IY0 K AH0 N S IH2 D ER0 EY1 SH AH0 N'],
  "reconsidered": ['R IY2 K AH0 N S IH1 D ER0 D'],
  "reconsidering": ['R IY2 K AH0 N S IH1 D ER0 IH0 NG'],
  "reconstitute": ['R IY0 K AA1 N S T AH0 T UW2 T'],
  "reconstituted": ['R IY0 K AA1 N S T AH0 T UW2 T IH0 D'],
  "reconstituting": ['R IY0 K AA1 N S T IH0 T UW2 T IH0 NG'],
  "reconstruct": ['R IY2 K AH0 N S T R AH1 K T'],
  "reconstructed": ['R IY2 K AH0 N S T R AH1 K T IH0 D'],
  "reconstructing": ['R IY2 K AH0 N S T R AH1 K T IH0 NG'],
  "reconstruction": ['R IY2 K AH0 N S T R AH1 K SH AH0 N'],
  "reconstructions": ['R IY2 K AH0 N S T R AH1 K SH AH0 N Z'],
  "reconstructive": ['R IY2 K AH0 N S T R AH1 K T IH0 V'],
  "reconvene": ['R IY0 K AH0 N V IY1 N'],
  "reconvened": ['R IY0 K AH0 N V IY1 N D'],
  "reconvenes": ['R IY0 K AH0 N V IY1 N Z'],
  "record": ['R AH0 K AO1 R D', 'R EH1 K ER0 D', 'R IH0 K AO1 R D'],
  "record's": ['R EH1 K ER0 D Z'],
  "recordable": ['R IH0 K AO1 R D AH0 B AH0 L'],
  "recorded": ['R AH0 K AO1 R D IH0 D', 'R IH0 K AO1 R D IH0 D'],
  "recorder": ['R IH0 K AO1 R D ER0', 'R IY0 K AO1 R D ER0'],
  "recorders": ['R IH0 K AO1 R D ER0 Z', 'R IY0 K AO1 R D ER0 Z'],
  "recording": ['R AH0 K AO1 R D IH0 NG', 'R IH0 K AO1 R D IH0 NG'],
  "recordings": ['R IH0 K AO1 R D IH0 NG Z'],
  "recordkeeping": ['R EH1 K ER0 D K IY2 P IH0 NG'],
  "records": ['R AH0 K AO1 R D Z', 'R EH1 K ER0 D Z', 'R IH0 K AO1 R D Z'],
  "records'": ['R EH1 K ER0 D Z'],
  "recore": ['R EH0 K AO1 R IY0'],
  "recoton": ['R IH0 K AO1 T IH0 N'],
  "recount": ['R IH0 K AW1 N T', 'R IY2 K AW1 N T'],
  "recounted": ['R IH0 K AW1 N T IH0 D', 'R IY2 K AW1 N T IH0 D'],
  "recounting": ['R IH0 K AW1 N T IH0 NG', 'R IY2 K AW1 N T IH0 NG'],
  "recounts": ['R IY2 K AW1 N T S', 'R IH0 K AW1 N T S'],
  "recoup": ['R IH0 K UW1 P'],
  "recouped": ['R IH0 K UW1 P T'],
  "recouping": ['R IH0 K UW1 P IH0 NG'],
  "recoups": ['R IH0 K UW1 P S'],
  "recourse": ['R IY1 K AO0 R S'],
  "recover": ['R IH0 K AH1 V ER0'],
  "recoverable": ['R IH0 K AH1 V ER0 AH0 B AH0 L'],
  "recovered": ['R AH0 K AH1 V ER0 D', 'R IH0 K AH1 V ER0 D'],
  "recoveries": ['R IH0 K AH1 V ER0 IY0 Z'],
  "recovering": ['R AH0 K AH1 V ER0 IH0 NG', 'R IH0 K AH1 V ER0 IH0 NG'],
  "recovers": ['R IH0 K AH1 V ER0 Z'],
  "recovery": ['R IH0 K AH1 V R IY0', 'R IH0 K AH1 V ER0 IY0'],
  "recovery's": ['R IH0 K AH1 V ER0 IY0 Z', 'R IH0 K AH1 V R IY0 Z'],
  "recreate": ['R EH1 K R IY0 EY2 T', 'R IY0 K R IY0 EY1 T'],
  "recreated": ['R EH1 K R IY0 EY2 T IH0 D', 'R IY0 K R IY0 EY1 T IH0 D'],
  "recreates": ['R EH1 K R IY0 EY2 T S', 'R IY0 K R IY0 EY1 T S'],
  "recreating": ['R EH1 K R IY0 EY2 T IH0 NG', 'R IY0 K R IY0 EY1 T IH0 NG'],
  "recreation": ['R EH2 K R IY0 EY1 SH AH0 N'],
  "recreational": ['R EH2 K R IY0 EY1 SH AH0 N AH0 L'],
  "recriminate": ['R IH0 K R IH2 M IH0 N EY1 T'],
  "recrimination": ['R IH0 K R IH2 M IH0 N EY1 SH AH0 N'],
  "recriminations": ['R IH0 K R IH2 M IH0 N EY1 SH AH0 N Z'],
  "recross": ['R IY0 K R AO1 S'],
  "recruit": ['R AH0 K R UW1 T', 'R IH0 K R UW1 T', 'R IY0 K R UW1 T'],
  "recruit's": ['R IY0 K R UW1 T S'],
  "recruited": ['R IH0 K R UW1 T IH0 D', 'R IY0 K R UW1 T IH0 D'],
  "recruiter": ['R IH0 K R UW1 T ER0'],
  "recruiters": ['R IH0 K R UW1 T ER0 Z'],
  "recruiting": ['R AH0 K R UW1 T IH0 NG', 'R IH0 K R UW1 T IH0 NG', 'R IY0 K R UW1 T IH0 NG'],
  "recruitment": ['R AH0 K R UW1 T M AH0 N T', 'R IH0 K R UW1 T M AH0 N T', 'R IY0 K R UW1 T M AH0 N T'],
  "recruits": ['R AH0 K R UW1 T S', 'R IH0 K R UW1 T S', 'R IY0 K R UW1 T S'],
  "rectal": ['R EH1 K T AH0 L'],
  "rectangle": ['R EH1 K T AE0 NG G AH0 L'],
  "rectangles": ['R EH1 K T AE2 NG G AH0 L Z'],
  "rectangular": ['R EH0 K T AE1 NG G Y AH0 L ER0'],
  "rectenwald": ['R EH1 K T IH0 N W AO0 L D'],
  "rectification": ['R EH2 K T AH0 F AH0 K EY1 SH AH0 N'],
  "rectified": ['R EH1 K T AH0 F AY2 D'],
  "rectifier": ['R EH1 K T AH0 F AY2 ER0'],
  "rectifiers": ['R EH1 K T AH0 F AY2 ER0 Z'],
  "rectifies": ['R EH1 K T AH0 F AY2 Z'],
  "rectify": ['R EH1 K T AH0 F AY2'],
  "rectifying": ['R EH1 K T AH0 F AY2 IH0 NG'],
  "rectitude": ['R EH1 K T IH0 T UW2 D'],
  "rector": ['R EH1 K T ER0'],
  "rectory": ['R EH1 K T ER0 IY0'],
  "rectum": ['R EH1 K T AH0 M'],
  "recuperate": ['R IH0 K UW1 P ER0 EY2 T'],
  "recuperated": ['R IH0 K UW1 P ER0 EY2 T IH0 D'],
  "recuperater": ['R IH0 K UW1 P ER0 EY2 T ER0'],
  "recuperates": ['R IH0 K UW1 P ER0 EY2 T S'],
  "recuperating": ['R IH0 K UW1 P ER0 EY2 T IH0 NG', 'R IY0 K UW1 P ER0 EY2 T IH0 NG'],
  "recuperation": ['R IH0 K UW2 P ER0 EY1 SH AH0 N'],
  "recuperative": ['R IH0 K UW1 P ER0 AH0 T IH0 V'],
  "recupero": ['R EY0 K UW0 P EH1 R OW0'],
  "recur": ['R IH0 K ER1', 'R IY0 K ER1'],
  "recurred": ['R IY0 K ER1 D'],
  "recurrence": ['R IH0 K ER1 AH0 N S', 'R IY0 K ER1 AH0 N S'],
  "recurrent": ['R IH0 K ER1 AH0 N T', 'R IY0 K ER1 AH0 N T'],
  "recurring": ['R IH0 K ER1 IH0 NG', 'R IY0 K ER1 IH0 NG'],
  "recursive": ['R IY2 K ER1 S IH0 V'],
  "recusal": ['R IH0 K Y UW1 Z AH0 L'],
  "recuse": ['R IH2 K Y UW1 Z'],
  "recused": ['R IH0 K Y UW1 Z D'],
  "recusing": ['R IH2 K Y UW1 Z IH0 NG'],
  "recyclable": ['R IY0 S AY1 K L AH0 B AH0 L'],
  "recyclables": ['R IY0 S AY1 K L AH0 B AH0 L Z'],
  "recycle": ['R IY0 S AY1 K AH0 L'],
  "recycled": ['R IY0 S AY1 K AH0 L D'],
  "recycler": ['R IY0 S AY1 K L ER0'],
  "recyclers": ['R IY0 S AY1 K L ER0 Z'],
  "recycles": ['R IY0 S AY1 K AH0 L Z'],
  "recycling": ['R IY0 S AY1 K AH0 L IH0 NG', 'R IY0 S AY1 K L IH0 NG'],
  "reczek": ['R EH1 CH EH0 K'],
  "red": ['R EH1 D'],
  "red-eye": ['R EH1 D AY2'],
  "red-eye's": ['R EH1 D AY2 Z'],
  "red-eyes": ['R EH1 D AY2 Z'],
  "red-handed": ['R EH1 D HH AE1 N D IH0 D'],
  "red-herring": ['R EH1 D HH EH1 R IH0 NG'],
  "red-herrings": ['R EH1 D HH EH1 R IH0 NG Z'],
  "reda": ['R EY1 D AH0'],
  "redact": ['R IH0 D AE1 K T'],
  "redacted": ['R IH0 D AE1 K T IH0 D'],
  "redactes": ['R IH0 D AE1 K T S'],
  "redacting": ['R IH0 D AE1 K T IH0 NG'],
  "redbird": ['R EH1 D B ER2 D'],
  "redbook": ['R EH1 D B UH2 K'],
  "redbud": ['R EH1 D B AH2 D'],
  "redburn": ['R EH1 D B ER2 N'],
  "redcay": ['R EH1 D K EY0'],
  "redco": ['R EH1 D K OW0'],
  "redcoat": ['R EH1 D K OW2 T'],
  "redcoats": ['R EH1 D K OW2 T S'],
  "redd": ['R EH1 D'],
  "reddell": ['R EH1 D AH0 L'],
  "redden": ['R EH1 D AH0 N'],
  "redder": ['R EH1 D ER0'],
  "reddest": ['R EH1 D IH0 S T'],
  "reddick": ['R EH1 D IH0 K'],
  "reddig": ['R EH1 D IH0 G'],
  "reddin": ['R EH1 D IH0 N'],
  "redding": ['R EH1 D IH0 NG'],
  "reddinger": ['R EH1 D IH0 NG ER0'],
  "reddington": ['R EH1 D IH0 NG T AH0 N'],
  "reddish": ['R EH1 D IH0 SH'],
  "reddit": ['R EH1 D IH0 T'],
  "redditt": ['R EH1 D IH0 T'],
  "reddoch": ['R EH1 D AH0 K'],
  "reddy": ['R EH1 D IY0'],
  "redecorate": ['R IY0 D EH1 K ER0 EY2 T'],
  "redecorated": ['R IY0 D EH1 K ER0 EY2 T IH0 D'],
  "redecorating": ['R IY0 D EH1 K ER0 EY2 T IH0 NG'],
  "redecoration": ['R IY2 D EH0 K ER0 EY1 SH AH0 N'],
  "rededicate": ['R IY2 D EH1 D AH0 K EY2 T'],
  "rededication": ['R IY0 D EH2 D AH0 K EY1 SH AH0 N'],
  "redeem": ['R IH0 D IY1 M'],
  "redeemable": ['R IH0 D IY1 M AH0 B AH0 L'],
  "redeemed": ['R IH0 D IY1 M D'],
  "redeeming": ['R IH0 D IY1 M IH0 NG'],
  "redeems": ['R AH0 D IY1 M Z'],
  "redefine": ['R IY0 D IH0 F AY1 N'],
  "redefined": ['R IY0 D IH0 F AY1 N D'],
  "redefines": ['R IY0 D IH0 F AY1 N Z'],
  "redefining": ['R IY0 D IH0 F AY1 N IH0 NG'],
  "redefinition": ['R IY0 D EH2 F AH0 N IH1 SH AH0 N'],
  "redeker": ['R EH1 D IH0 K ER0'],
  "redel": ['R EH1 D AH0 L'],
  "redell": ['R EH1 D AH0 L'],
  "redemption": ['R IH0 D EH1 M P SH AH0 N', 'R IH0 D EH1 M SH AH0 N'],
  "redemptions": ['R IH0 D EH1 M P SH AH0 N Z', 'R IH0 D EH1 M SH AH0 N Z'],
  "redemptive": ['R IH0 D EH1 M P T IH0 V', 'R IH0 D EH1 M T IH0 V'],
  "redenbaugh": ['R EH1 D IH0 N B AW0'],
  "redenius": ['R EH1 D IY0 N IY0 IH0 S'],
  "redeploy": ['R IY2 D IH0 P L OY1'],
  "redeployed": ['R IY2 D IH0 P L OY1 D'],
  "redeploying": ['R IY2 D IH0 P L OY1 IH0 NG'],
  "redeployment": ['R IY2 D IH0 P L OY1 M AH0 N T'],
  "redeployments": ['R IY2 D IH0 P L OY1 M AH0 N T S'],
  "redeposition": ['R IY0 D EH2 P AH0 Z IH1 SH AH0 N'],
  "reder": ['R EH1 D ER0'],
  "redesign": ['R IY2 D IH0 Z AY1 N'],
  "redesigned": ['R IY2 D IH0 Z AY1 N D'],
  "redesigning": ['R IY2 D IH0 Z AY1 N IH0 NG'],
  "redevelop": ['R IY0 D IH0 V EH1 L AH0 P'],
  "redevelopment": ['R IY2 D IH0 V EH1 L AH0 P M AH0 N T'],
  "redeye": ['R EH1 D AY0'],
  "redeyes": ['R EH1 D AY0 Z'],
  "redfearn": ['R EH1 D F ER0 N'],
  "redfern": ['R EH1 D F ER2 N'],
  "redfield": ['R EH1 D F IY2 L D'],
  "redfish": ['R EH1 D F IH2 SH'],
  "redford": ['R EH1 D F ER0 D'],
  "redford's": ['R EH1 D F ER0 D Z'],
  "redgrave": ['R EH1 D G R EY2 V'],
  "redhanded": ['R EH1 D HH AE2 N D IH0 D'],
  "redhead": ['R EH1 D HH EH2 D'],
  "redheaded": ['R EH1 D HH EH2 D IH0 D'],
  "redheads": ['R EH1 D HH EH1 D Z'],
  "redi": ['R EH1 D IY0'],
  "redial": ['R IY0 D AY1 AH0 L'],
  "redic": ['R EH1 D IH0 K'],
  "redick": ['R EH1 D IH0 K'],
  "redicker": ['R IY0 D IH1 K ER0'],
  "redid": ['R IY0 D IH1 D'],
  "redifer": ['R EH1 D IH0 F ER0', 'R IY0 D AH0 F ER1'],
  "rediffusion": ['R IY0 D IH0 F Y UW1 ZH AH0 N'],
  "rediger": ['R EH1 D IH0 G ER0'],
  "rediker": ['R EH1 D IH0 K ER0'],
  "reding": ['R IY1 D IH0 NG'],
  "redinger": ['R EH1 D IH0 NG ER0'],
  "redington": ['R EH1 D IH0 NG T AH0 N'],
  "redirect": ['R IY2 D ER0 EH1 K T'],
  "redirected": ['R IY2 D ER0 EH1 K T IH0 D'],
  "redirecting": ['R IY2 D ER0 EH1 K T IH0 NG'],
  "redirection": ['R IY2 D ER0 EH1 K SH AH0 N'],
  "rediscover": ['R IY0 D IH0 S K AH1 V ER0'],
  "rediscovered": ['R IY0 D IH0 S K AH1 V ER0 D'],
  "rediscovering": ['R IY0 D IH0 S K AH1 V ER0 IH0 NG'],
  "rediscovery": ['R IY0 D IH0 S K AH1 V R IY0'],
  "redish": ['R EH1 D IH0 SH'],
  "redisplay": ['R IY0 D IH0 S P L EY1'],
  "redistribute": ['R IY2 D IH0 S T R IH1 B Y UW0 T'],
  "redistributed": ['R IY2 D IH0 S T R IH1 B Y UW0 T IH0 D', 'R IY2 D IH0 S T R IH1 B Y AH0 T IH0 D'],
  "redistributing": ['R IY2 D IH0 S T R IH1 B Y UW0 T IH0 NG', 'R IY2 D IH0 S T R IH1 B Y AH0 T IH0 NG'],
  "redistribution": ['R IY2 D IH0 S T R AH0 B Y UW1 SH AH0 N'],
  "redistributive": ['R IY0 D IH0 S T R IH1 B Y UW0 T IH0 V', 'R IY0 D IH0 S T R IH1 B Y AH0 T IH0 V'],
  "redistricting": ['R IY0 D IH1 S T R IH0 K T IH0 NG'],
  "redken": ['R EH1 D K AH0 N'],
  "redland": ['R EH1 D L AH0 N D'],
  "redler": ['R EH1 D L ER0'],
  "redley": ['R EH1 D L IY0'],
  "redlich": ['R EH1 D L IH0 K'],
  "redlin": ['R EH1 D L IH0 N'],
  "redlinger": ['R EH1 D AH0 L IH0 NG ER0', 'R EH1 D L IH0 NG ER0'],
  "redlining": ['R EH1 D L AY2 N IH0 NG'],
  "redman": ['R EH1 D M AE2 N'],
  "redmann": ['R EH1 D M AH0 N'],
  "redmon": ['R EH1 D M AH0 N'],
  "redmond": ['R EH1 D M AH0 N D'],
  "redmund": ['R EH1 D M AH0 N D'],
  "redneck": ['R EH1 D N EH2 K'],
  "rednecks": ['R EH1 D N EH2 K S'],
  "redner": ['R EH1 D N ER0'],
  "redness": ['R EH1 D N AH0 S'],
  "rednour": ['R EH0 D N UH1 R'],
  "redo": ['R IY0 D UW1'],
  "redoglia": ['R AH0 D AA1 G L IY0 AH0'],
  "redoing": ['R IY0 D UW1 IH0 NG'],
  "redolent": ['R EH1 D AH0 L AH0 N T'],
  "redondo": ['R IH0 D AA1 N D OW0'],
  "redone": ['R IY0 D AH1 N'],
  "redouble": ['R IY0 D AH1 B AH0 L'],
  "redoubled": ['R IY0 D AH1 B AH0 L D'],
  "redoubling": ['R IY0 D AH1 B AH0 L IH0 NG', 'R IY0 D AH1 B L IH0 NG'],
  "redoubt": ['R IY0 D AW1 T'],
  "redoubtable": ['R IY0 D AW1 T AH0 B AH0 L'],
  "redound": ['R IY0 D AW1 N D'],
  "redpath": ['R EH1 D P AE2 TH'],
  "redpoll": ['R EH1 D P OW2 L'],
  "redpolls": ['R EH1 D P OW2 L Z'],
  "redraft": ['R IY0 D R AE1 F T'],
  "redrafted": ['R IY0 D R AE1 F T IH0 D'],
  "redraw": ['R IY0 D R AO1'],
  "redrawing": ['R IY0 D R AO1 IH0 NG'],
  "redrawn": ['R IY0 D R AO1 N'],
  "redress": ['R IH0 D R EH1 S', 'R IY1 D R EH0 S'],
  "redressing": ['R IY0 D R EH1 S IH0 NG'],
  "reds": ['R EH1 D Z'],
  "reds'": ['R EH1 D Z'],
  "redshaw": ['R EH1 D SH AO2'],
  "redskin": ['R EH1 D S K IH2 N'],
  "redskins": ['R EH1 D S K IH2 N Z'],
  "redskins'": ['R EH1 D S K IH2 N Z'],
  "redstone": ['R EH1 D S T OW2 N'],
  "redstone's": ['R EH1 D S T OW2 N Z'],
  "reduce": ['R AH0 D UW1 S', 'R IH0 D UW1 S', 'R IY0 D UW1 S'],
  "reduced": ['R AH0 D UW1 S T', 'R IH0 D UW1 S T', 'R IY0 D UW1 S T'],
  "reduces": ['R AH0 D UW1 S AH0 Z', 'R IH0 D UW1 S IH0 Z', 'R IY0 D UW1 S IH0 Z'],
  "reducible": ['R AH0 D UW1 S AH0 B AH0 L'],
  "reducing": ['R AH0 D UW1 S IH0 NG', 'R IH0 D UW1 S IH0 NG', 'R IY0 D UW1 S IH0 NG'],
  "reduction": ['R AH0 D AH1 K SH AH0 N', 'R IY0 D AH1 K SH AH0 N'],
  "reductions": ['R IY0 D AH1 K SH AH0 N Z'],
  "redundancies": ['R IH0 D AH1 N D AH0 N S IY0 Z'],
  "redundancy": ['R IH0 D AH1 N D AH0 N S IY0'],
  "redundant": ['R IH0 D AH1 N D AH0 N T'],
  "redus": ['R IY1 D AH0 S'],
  "redux": ['R IY1 D AH2 K S'],
  "redwald": ['R EH1 D W AH0 L D'],
  "redwine": ['R EH1 D W AY2 N'],
  "redwood": ['R EH1 D W UH2 D'],
  "redwoods": ['R EH1 D W UH2 D Z'],
  "ree": ['R IY1'],
  "reeb": ['R IY1 B'],
  "reeb's": ['R IY1 B Z'],
  "reebok": ['R IY1 B AA0 K'],
  "reebok's": ['R IY1 B AA0 K S'],
  "reeboks": ['R IY1 B AA0 K S'],
  "reebs": ['R IY1 B Z'],
  "reebs'": ['R IY1 B Z'],
  "reece": ['R IY1 S'],
  "reeche": ['R IY1 CH'],
  "reeck": ['R IY1 K'],
  "reed": ['R IY1 D'],
  "reed's": ['R IY1 D Z'],
  "reeder": ['R IY1 D ER0'],
  "reeders": ['R IY1 D ER0 Z'],
  "reeds": ['R IY1 D Z'],
  "reeducate": ['R IY1 EH2 JH AH0 K EY2 T'],
  "reeducation": ['R IY0 EH2 D Y AH0 K EY2 SH AH0 N', 'R IY0 EH2 JH AH0 K EY2 SH AH0 N'],
  "reedy": ['R IY1 D IY0'],
  "reef": ['R IY1 F'],
  "reefer": ['R IY1 F ER0'],
  "reefers": ['R IY1 F ER0 Z'],
  "reefs": ['R IY1 F S'],
  "reeg": ['R IY1 G'],
  "reeh": ['R IY1'],
  "reek": ['R IY1 K'],
  "reeking": ['R IY1 K IH0 NG'],
  "reeks": ['R IY1 K S'],
  "reel": ['R IY1 L'],
  "reelect": ['R IY0 IH0 L EH1 K T'],
  "reelected": ['R IY0 IH0 L EH1 K T IH0 D'],
  "reelecting": ['R IY0 IH0 L EH1 K T IH0 NG'],
  "reelection": ['R IY0 IH0 L EH1 K SH AH0 N'],
  "reeled": ['R IY1 L D'],
  "reeling": ['R IY1 L IH0 NG'],
  "reels": ['R IY1 L Z'],
  "reemerge": ['R IY0 IH0 M ER1 JH'],
  "reemerged": ['R IY0 IH0 M ER1 JH D'],
  "reemergence": ['R IY0 IH0 M ER1 JH AH0 N S'],
  "reemphasize": ['R IY0 EH1 M F AH0 S AY2 Z'],
  "reemployment": ['R IY0 IH0 M P L OY1 M AH0 N T'],
  "reen": ['R IY1 N'],
  "reenact": ['R IY0 IH0 N AE1 K T'],
  "reenacted": ['R IY0 IH0 N AE1 K T IH0 D'],
  "reenactment": ['R IY0 IH0 N AE1 K T M AH0 N T'],
  "reenactments": ['R IY0 IH0 N AE1 K T M AH0 N T S'],
  "reenacts": ['R IY0 IH0 N AE1 K T S'],
  "reengineer": ['R IY0 EH2 N JH AH0 N IH1 R'],
  "reengineering": ['R IY0 EH2 N JH AH0 N IH1 R IH0 NG'],
  "reenter": ['R IY0 IH1 N T ER0'],
  "reentered": ['R IY0 IH1 N T ER0 D'],
  "reentering": ['R IY0 IH1 N T ER0 IH0 NG'],
  "reentry": ['R IY0 IH1 N T R IY0'],
  "reents": ['R IY1 N T S'],
  "reep": ['R IY1 P'],
  "rees": ['R IY1 Z'],
  "reese": ['R IY1 S'],
  "reese's": ['R IY1 S IH0 Z'],
  "reeser": ['R IY1 S ER0'],
  "reesjones": ['R IY2 S JH OW1 N Z'],
  "reesjones'": ['R IY2 S JH OW1 N Z', 'R IY2 S JH OW1 N Z AH0 Z'],
  "reesman": ['R IY1 S M AH0 N'],
  "reesor": ['R IY1 S ER0'],
  "reestablish": ['R IY0 IH0 S T AE1 B L IH0 SH'],
  "reestablished": ['R IY2 IH0 S T AE1 B L IH0 SH T'],
  "reestablishing": ['R IY0 IH0 S T AE1 B L IH0 SH IH0 NG'],
  "reetz": ['R IY1 T S'],
  "reevaluate": ['R IY2 IH0 V AE1 L UW0 EY2 T'],
  "reevaluated": ['R IY0 IH0 V AE1 L Y UW0 EY2 T IH0 D'],
  "reevaluating": ['R IY0 IH0 V AE1 L Y UW0 EY2 T IH0 NG'],
  "reevaluation": ['R IY0 IH0 V AE2 L Y UW0 EY1 SH AH0 N'],
  "reeve": ['R IY1 V'],
  "reever": ['R IY1 V ER0'],
  "reeves": ['R IY1 V Z'],
  "reeves'": ['R IY1 V Z'],
  "reeves's": ['R IY1 V Z IH0 Z'],
  "reexamination": ['R IY0 IH0 G Z AE2 M AH0 N EY1 SH AH0 N'],
  "reexamine": ['R IY0 EH0 G Z AE1 M AH0 N'],
  "reexamined": ['R IY0 IH0 G Z AE1 M AH0 N D'],
  "reexamining": ['R IY0 IH0 G Z AE1 M AH0 N IH0 NG'],
  "reexport": ['R IY0 EH1 K S P AO2 R T'],
  "reexports": ['R IY0 EH1 K S P AO2 R T S'],
  "ref": ['R EH1 F'],
  "refaat": ['R AH0 F AA1 T'],
  "refah": ['R AH0 F AA1'],
  "refco": ['R EH1 F K OW0'],
  "refcorp": ['R EH1 F K AO0 R P'],
  "refenes": ['R EH1 F IH0 N EH2 S', 'R IH0 F IY1 N Z'],
  "refer": ['R AH0 F ER1', 'R IH0 F ER1'],
  "referee": ['R EH2 F ER0 IY1'],
  "referee's": ['R EH2 F ER0 IY1 Z'],
  "referees": ['R EH2 F ER0 IY1 Z'],
  "reference": ['R EH1 F ER0 AH0 N S', 'R EH1 F R AH0 N S'],
  "referenced": ['R EH1 F ER0 AH0 N S T', 'R EH1 F R AH0 N S T'],
  "references": ['R EH1 F ER0 AH0 N S IH0 Z', 'R EH1 F R AH0 N S IH0 Z'],
  "referencing": ['R EH1 F ER0 AH0 N S IH0 NG', 'R EH1 F R AH0 N S IH0 NG'],
  "referenda": ['R EH2 F ER0 EH1 N D AH0'],
  "referendum": ['R EH2 F ER0 EH1 N D AH0 M'],
  "referendums": ['R EH2 F ER0 EH1 N D AH0 M Z'],
  "refering": ['R IH0 F ER1 IH0 NG'],
  "referral": ['R IH0 F ER1 AH0 L'],
  "referrals": ['R IH0 F ER1 AH0 L Z'],
  "referred": ['R AH0 F ER1 D', 'R IH0 F ER1 D'],
  "referring": ['R IH0 F ER1 IH0 NG'],
  "refers": ['R AH0 F ER1 Z', 'R IH0 F ER1 Z'],
  "reff": ['R EH1 F'],
  "reffett": ['R EH1 F IH0 T'],
  "reffitt": ['R EH1 F IH0 T'],
  "reffner": ['R EH1 F N ER0'],
  "refile": ['R IY0 F AY1 L'],
  "refiled": ['R IY0 F AY1 L D'],
  "refill": ['R IY1 F IH0 L', 'R IY0 F IH1 L'],
  "refilled": ['R IY0 F IH1 L D'],
  "refills": ['R IY0 F IH1 L Z', 'R IY1 F IH0 L Z'],
  "refinance": ['R IY2 F AH0 N AE1 N S', 'R IY1 F AY1 N AE2 N S'],
  "refinanced": ['R IY2 F AH0 N AE1 N S T', 'R IY1 F AY1 N AE2 N S T'],
  "refinances": ['R IY2 F AH0 N AE1 N S IH0 Z', 'R IY1 F AY1 N AE2 N S IH0 Z'],
  "refinancing": ['R IY2 F AH0 N AE1 N S IH0 NG', 'R IY1 F AY1 N AE2 N S IH0 NG'],
  "refinancings": ['R IY2 F AH0 N AE1 N S IH0 NG Z', 'R IY1 F AY1 N AE2 N S IH0 NG Z'],
  "refine": ['R AH0 F AY1 N', 'R IH0 F AY1 N'],
  "refined": ['R AH0 F AY1 N D', 'R IH0 F AY1 N D'],
  "refinement": ['R AH0 F AY1 N M AH0 N T'],
  "refinements": ['R IH0 F AY1 N M AH0 N T S'],
  "refiner": ['R IH0 F AY1 N ER0'],
  "refiner's": ['R IH0 F AY1 N ER0 Z'],
  "refineries": ['R IH0 F AY1 N ER0 IY0 Z'],
  "refiners": ['R IH0 F AY1 N ER0 Z'],
  "refiners'": ['R IH0 F AY1 N ER0 Z'],
  "refinery": ['R IH0 F AY1 N ER0 IY0'],
  "refinery's": ['R IH0 F AY1 N ER0 IY0 Z'],
  "refines": ['R IH0 F AY1 N Z'],
  "refining": ['R AH0 F AY1 N IH0 NG', 'R IH0 F AY1 N IH0 NG'],
  "refinish": ['R IY0 F IH1 N IH0 SH'],
  "refinished": ['R IY0 F IH1 N IH0 SH T'],
  "refinishing": ['R IY0 F IH1 N IH0 SH IH0 NG'],
  "refit": ['R IY0 F IH1 T'],
  "refitted": ['R IY0 F IH1 T IH0 D'],
  "refitting": ['R IY0 F IH1 T IH0 NG'],
  "reflag": ['R IY0 F L AE1 G'],
  "reflagged": ['R IY0 F L AE1 G D'],
  "reflagging": ['R IY0 F L AE1 G IH0 NG'],
  "reflate": ['R IY0 F L EY1 T'],
  "reflation": ['R IY0 F L EY1 SH AH0 N'],
  "reflect": ['R IH0 F L EH1 K T'],
  "reflected": ['R IH0 F L EH1 K T IH0 D'],
  "reflecting": ['R IH0 F L EH1 K T IH0 NG'],
  "reflection": ['R IH0 F L EH1 K SH AH0 N'],
  "reflections": ['R IH0 F L EH1 K SH AH0 N Z'],
  "reflective": ['R IH0 F L EH1 K T IH0 V'],
  "reflectone": ['R IY0 F L EH1 K T OW2 N'],
  "reflectone's": ['R IY0 F L EH1 K T OW2 N Z'],
  "reflector": ['R IH0 F L EH1 K T ER0'],
  "reflectors": ['R IH0 F L EH1 K T ER0 Z'],
  "reflects": ['R IH0 F L EH1 K T S'],
  "reflex": ['R IY1 F L EH0 K S'],
  "reflexes": ['R IY1 F L EH0 K S AH0 Z'],
  "reflexive": ['R AH0 F L EH1 K S IH0 V'],
  "reflexively": ['R IY0 F L EH1 K S IH0 V L IY0'],
  "reflexivity": ['R IY2 F L EH2 K S IH1 V IH0 T IY0'],
  "reflux": ['R IY1 F L AH2 K S'],
  "refocus": ['R IY0 F OW1 K AH0 S'],
  "refocused": ['R IY0 F OW1 K AH0 S T'],
  "refocuses": ['R IY0 F OW1 K AH0 S IH0 Z'],
  "refocusing": ['R IY0 F OW1 K AH0 S IH0 NG'],
  "reforest": ['R IY0 F AO1 R AH0 S T'],
  "reforestation": ['R IY2 F AO0 R AH0 S T EY1 SH AH0 N'],
  "reform": ['R AH0 F AO1 R M', 'R IH0 F AO1 R M'],
  "reforma": ['R IH0 F AO1 R M AH0'],
  "reformation": ['R EH2 F ER0 M EY1 SH AH0 N'],
  "reformatories": ['R IH0 F AO1 R M AH0 T AO2 R IY0 Z'],
  "reformatory": ['R IH0 F AO1 R M AH0 T AO2 R IY0'],
  "reformed": ['R IH0 F AO1 R M D'],
  "reformer": ['R IH0 F AO1 R M ER0'],
  "reformers": ['R IH0 F AO1 R M ER0 Z'],
  "reformers'": ['R IH0 F AO1 R M ER0 Z'],
  "reforming": ['R IH0 F AO1 R M IH0 NG'],
  "reformist": ['R IH0 F AO1 R M IH0 S T'],
  "reformists": ['R IH0 F AO1 R M IH0 S T S'],
  "reforms": ['R AH0 F AO1 R M Z', 'R IH0 F AO1 R M Z'],
  "reformulate": ['R IY0 F AO1 R M Y AH0 L EY2 T'],
  "reformulated": ['R IY0 F AO1 R M Y AH0 L EY2 T IH0 D'],
  "refractive": ['R AH0 F R AE1 K T IH0 V'],
  "refractor": ['R AH0 F R AE1 K T ER0'],
  "refractories": ['R IH0 F R AE1 K T ER0 IY0 Z'],
  "refractors": ['R AH0 F R AE1 K T ER0 Z'],
  "refractory": ['R AH0 F R AE1 K T ER0 IY0'],
  "refrain": ['R IH0 F R EY1 N'],
  "refrained": ['R IH0 F R EY1 N D'],
  "refraining": ['R IH0 F R EY1 N IH0 NG'],
  "refrains": ['R IH0 F R EY1 N Z'],
  "refresh": ['R IH0 F R EH1 SH'],
  "refreshed": ['R IY0 F R EH1 SH T'],
  "refresher": ['R IH0 F R EH1 SH ER0'],
  "refreshes": ['R IH0 F R EH1 SH IH0 Z'],
  "refreshing": ['R IH0 F R EH1 SH IH0 NG'],
  "refreshingly": ['R IY0 F R EH1 SH IH0 NG L IY0'],
  "refreshment": ['R AH0 F R EH1 SH M AH0 N T'],
  "refreshments": ['R AH0 F R EH1 SH M AH0 N T S'],
  "refrigerant": ['R IH0 F R IH1 JH ER0 AH0 N T'],
  "refrigerants": ['R IH0 F R IH1 JH ER0 AH0 N T S'],
  "refrigerate": ['R IH0 F R IH1 JH ER0 EY2 T'],
  "refrigerated": ['R IH0 F R IH1 JH ER0 EY2 T IH0 D'],
  "refrigeration": ['R IH0 F R IH2 JH ER0 EY1 SH AH0 N'],
  "refrigerator": ['R AH0 F R IH1 JH ER0 EY2 T ER0', 'R IH0 F R IH1 JH ER0 EY2 T ER0'],
  "refrigerators": ['R IH0 F R IH1 JH ER0 EY2 T ER0 Z'],
  "refsnes": ['R EH1 F S N IY0 Z'],
  "refuel": ['R IY0 F Y UW1 AH0 L'],
  "refueled": ['R IY0 F Y UW1 AH0 L D'],
  "refueling": ['R IY0 F Y UW1 AH0 L IH0 NG', 'R IY0 F Y UW1 L IH0 NG'],
  "refuge": ['R EH1 F Y UW0 JH'],
  "refuge's": ['R EH1 F Y UW0 JH IH0 Z'],
  "refugee": ['R EH1 F Y UW0 JH IY0'],
  "refugees": ['R EH1 F Y UW2 JH IY0 Z'],
  "refugees'": ['R EH1 F Y UW2 JH IY0 Z'],
  "refuges": ['R EH0 F Y UW1 JH IH0 Z'],
  "refugio": ['R AH0 F Y UW1 JH IY0 OW0'],
  "refund": ['R IH0 F AH1 N D', 'R IY1 F AH2 N D'],
  "refundable": ['R IH0 F AH1 N D AH0 B AH0 L'],
  "refunded": ['R IH0 F AH1 N D IH0 D'],
  "refunders": ['R IH0 F AH1 N D ER0 Z'],
  "refunding": ['R IH0 F AH1 N D IH0 NG'],
  "refundings": ['R IY1 F AH2 N D IH0 NG Z'],
  "refunds": ['R IH0 F AH1 N D Z', 'R IY1 F AH2 N D Z'],
  "refurbish": ['R IY0 F ER1 B IH0 SH'],
  "refurbished": ['R IY0 F ER1 B IH0 SH T'],
  "refurbishing": ['R IY0 F ER1 B IH0 SH IH0 NG'],
  "refurbishment": ['R IY0 F ER1 B IH0 SH M AH0 N T'],
  "refusal": ['R AH0 F Y UW1 Z AH0 L', 'R IH0 F Y UW1 Z AH0 L'],
  "refusals": ['R IH0 F Y UW1 Z AH0 L Z'],
  "refuse": ['R AH0 F Y UW1 Z', 'R EH1 F Y UW2 Z', 'R IH0 F Y UW1 Z'],
  "refused": ['R AH0 F Y UW1 Z D', 'R IH0 F Y UW1 Z D'],
  "refusenik": ['R IH0 F Y UW1 Z N IH0 K'],
  "refuseniks": ['R IH0 F Y UW1 Z N IH0 K S'],
  "refuses": ['R AH0 F Y UW1 Z AH0 Z', 'R IH0 F Y UW1 Z IH0 Z'],
  "refusing": ['R AH0 F Y UW1 Z IH0 NG', 'R IH0 F Y UW1 Z IH0 NG'],
  "refutation": ['R EH2 F Y UW0 T EY1 SH AH0 N'],
  "refute": ['R IH0 F Y UW1 T'],
  "refuted": ['R IH0 F Y UW1 T IH0 D'],
  "refutes": ['R IH0 F Y UW1 T S'],
  "refuting": ['R IH0 F Y UW1 T IH0 NG'],
  "reg": ['R EH1 G'],
  "rega": ['R IY1 G AH0'],
  "regain": ['R IH0 G EY1 N'],
  "regained": ['R IY0 G EY1 N D'],
  "regaining": ['R IH0 G EY1 N IH0 NG'],
  "regains": ['R IY0 G EY1 N Z'],
  "regal": ['R IY1 G AH0 L'],
  "regalado": ['R EY0 G AA0 L AA1 D OW0'],
  "regale": ['R IH0 G EY1 L'],
  "regaled": ['R IH0 G EY1 L D'],
  "regales": ['R IH0 G EY1 L Z'],
  "regalia": ['R IH0 G EY1 L Y AH0'],
  "regaling": ['R IH0 G EY1 L IH0 NG'],
  "regally": ['R IY1 G AH0 L IY0'],
  "regan": ['R IY1 G AH0 N'],
  "regan's": ['R IY1 G AH0 N Z'],
  "regard": ['R IH0 G AA1 R D'],
  "regarded": ['R IH0 G AA1 R D IH0 D'],
  "regarding": ['R IH0 G AA1 R D IH0 NG'],
  "regardless": ['R AH0 G AA1 R D L AH0 S'],
  "regards": ['R IH0 G AA1 R D Z'],
  "regas": ['R IY1 G AH0 Z'],
  "regatta": ['R IH0 G AA1 T AH0'],
  "regattas": ['R AH0 G AA1 T AH0 Z'],
  "regehr": ['R EH1 G IH0 R'],
  "regel": ['R EH1 G AH0 L'],
  "regen": ['R EH1 G AH0 N'],
  "regency": ['R IY1 JH AH0 N S IY0'],
  "regency's": ['R IY1 JH AH0 N S IY0 Z'],
  "regenerate": ['R IY0 JH EH1 N ER0 EY2 T'],
  "regenerated": ['R IY0 JH EH1 N ER0 EY2 T IH0 D'],
  "regenerating": ['R IY0 JH EH1 N ER0 EY2 T IH0 NG'],
  "regeneration": ['R IY0 JH EH1 N ER0 EY1 SH AH0 N'],
  "regeneron": ['R IY0 JH EH1 N ER0 AO2 N'],
  "regensburg": ['R IY1 G AH0 N Z B ER0 G'],
  "regensteiner": ['R EH1 G AH0 N S T AY2 N ER0'],
  "regent": ['R IY1 JH AH0 N T'],
  "regents": ['R IY1 JH AH0 N T S'],
  "reger": ['R IY1 G ER0'],
  "regester": ['R EH1 JH IH0 S T ER0'],
  "reggae": ['R EH1 G EY2'],
  "reggaeton": ['R EH2 G EY0 T OW1 N'],
  "reggie": ['R EH1 JH IY0'],
  "reggio": ['R EH1 JH IY0 OW0'],
  "reggy": ['R EH1 G IY0'],
  "regie": ['R EH1 G IY0'],
  "regier": ['R IY1 G IY0 ER0'],
  "regime": ['R AH0 ZH IY1 M', 'R EY0 ZH IY1 M'],
  "regime's": ['R EY0 ZH IY1 M Z'],
  "regimen": ['R EH1 JH AH0 M AH0 N'],
  "regimens": ['R EH1 JH AH0 M AH0 N Z'],
  "regiment": ['R EH1 JH AH0 M AH0 N T'],
  "regimental": ['R EH2 JH AH0 M EH1 N T AH0 L'],
  "regimentation": ['R EH2 JH AH0 M EH0 N T EY1 SH AH0 N'],
  "regimented": ['R EH1 JH AH0 M EH2 N T IH0 D'],
  "regiments": ['R EH1 JH AH0 M AH0 N T S'],
  "regimes": ['R AH0 ZH IY1 M Z', 'R EY0 ZH IY1 M Z'],
  "regina": ['R IH0 JH IY1 N AH0'],
  "reginald": ['R EH1 JH AH0 N AH0 L D'],
  "reginald's": ['R EH1 JH AH0 N AH0 L D Z'],
  "region": ['R IY1 JH AH0 N'],
  "region's": ['R IY1 JH AH0 N Z'],
  "regional": ['R IY1 JH AH0 N AH0 L'],
  "regionalize": ['R IY1 JH AH0 N AH0 L AY2 Z'],
  "regionalized": ['R IY1 JH AH0 N AH0 L AY2 Z D'],
  "regionally": ['R IY1 JH AH0 N AH0 L IY0'],
  "regionals": ['R IY1 JH AH0 N AH0 L Z'],
  "regions": ['R IY1 JH AH0 N Z'],
  "regis": ['R IY1 JH IH0 S'],
  "register": ['R EH1 JH IH0 S T ER0'],
  "register's": ['R EH1 JH IH0 S T ER0 Z'],
  "registered": ['R EH1 JH IH0 S T ER0 D'],
  "registering": ['R EH1 JH IH0 S T ER0 IH0 NG', 'R EH1 JH IH0 S T R IH0 NG'],
  "registers": ['R EH1 JH AH0 S T ER0 Z', 'R EH1 JH IH0 S T ER0 Z'],
  "registrant": ['R EH1 JH AH0 S T R AH0 N T'],
  "registrants": ['R EH1 JH AH0 S T R AH0 N T S'],
  "registrar": ['R EH1 JH IH0 S T R AA2 R'],
  "registrars": ['R EH1 JH IH0 S T R AA2 R Z'],
  "registration": ['R EH2 JH IH0 S T R EY1 SH AH0 N'],
  "registrations": ['R EH2 JH IH0 S T R EY1 SH AH0 N Z'],
  "registries": ['R EH1 JH IH0 S T R IY0 Z'],
  "registry": ['R EH1 JH IH0 S T R IY0'],
  "regner": ['R EH1 G N ER0'],
  "regnery": ['R EH1 G N ER0 IY0'],
  "regnier": ['R EH1 G N IY0 ER0'],
  "rego": ['R EH1 G OW0'],
  "regress": ['R IY1 G R EH0 S', 'R IH0 G R EH1 S'],
  "regression": ['R AH0 G R EH1 SH AH0 N'],
  "regressions": ['R AH0 G R EH1 SH AH0 N Z'],
  "regressive": ['R AH0 G R EH1 S IH0 V'],
  "regret": ['R AH0 G R EH1 T', 'R IH0 G R EH1 T'],
  "regretful": ['R IH0 G R EH1 T F AH0 L'],
  "regretfully": ['R IH0 G R EH1 T F AH0 L IY0'],
  "regrets": ['R IH0 G R EH1 T S'],
  "regrettable": ['R IH0 G R EH1 T AH0 B AH0 L'],
  "regrettably": ['R IH0 G R EH1 T AH0 B L IY0'],
  "regretted": ['R IH0 G R EH1 T IH0 D'],
  "regretting": ['R IH0 G R EH1 T IH0 NG'],
  "regroup": ['R IY0 G R UW1 P'],
  "regrouped": ['R IY0 G R UW1 P T'],
  "regrouping": ['R IY0 G R UW1 P IH0 NG'],
  "regs": ['R EH1 G Z'],
  "regula": ['R EH0 G UW1 L AH0'],
  "regular": ['R EH1 G Y AH0 L ER0', 'R EY1 G Y AH0 L ER0'],
  "regularity": ['R EH2 G Y AH0 L EH1 R AH0 T IY0'],
  "regularly": ['R EH1 G Y AH0 L ER0 L IY0'],
  "regulars": ['R EH1 G Y AH0 L ER0 Z'],
  "regulate": ['R EH1 G Y AH0 L EY2 T'],
  "regulated": ['R EH1 G Y AH0 L EY2 T IH0 D'],
  "regulates": ['R EH1 G Y AH0 L EY2 T S'],
  "regulating": ['R EH1 G Y AH0 L EY2 T IH0 NG'],
  "regulatingly": ['R EH1 G Y AH0 L EY2 T IH0 NG L IY0'],
  "regulation": ['R EH2 G Y AH0 L EY1 SH AH0 N'],
  "regulations": ['R EH2 G Y AH0 L EY1 SH AH0 N Z'],
  "regulative": ['R EH1 G Y AH0 L EY2 T IH0 V'],
  "regulator": ['R EH1 G Y AH0 L EY2 T ER0'],
  "regulator's": ['R EH1 G Y AH0 L EY2 T ER0 Z'],
  "regulators": ['R EH1 G Y AH0 L EY2 T ER0 Z'],
  "regulators'": ['R EH1 G Y AH0 L EY2 T ER0 Z'],
  "regulatory": ['R EH1 G Y AH0 L AH0 T AO2 R IY0'],
  "regurgitate": ['R IH2 G ER1 JH AH0 T EY2 T'],
  "regwan": ['R EH1 G W AA0 N'],
  "reh": ['R EH1'],
  "reha": ['R IY1 HH AH0'],
  "rehab": ['R IY0 HH AE0 B'],
  "rehabilitate": ['R IY2 HH AH0 B IH1 L AH0 T EY2 T', 'R IY2 AH0 B IH1 L AH0 T EY2 T'],
  "rehabilitated": ['R IY2 HH AH0 B IH1 L AH0 T EY2 T IH0 D', 'R IY2 AH0 B IH1 L AH0 T EY2 T IH0 D'],
  "rehabilitating": ['R IY2 HH AH0 B IH1 L AH0 T EY2 T IH0 NG', 'R IY2 AH0 B IH1 L AH0 T EY2 T IH0 NG'],
  "rehabilitation": ['R IY2 HH AH0 B IH2 L AH0 T EY1 SH AH0 N', 'R IY2 AH0 B IH2 L AH0 T EY1 SH AH0 N'],
  "rehabilitations": ['R IY2 HH AH0 B IH2 L AH0 T EY1 SH AH0 N Z', 'R IY2 AH0 B IH2 L AH0 T EY1 SH AH0 N Z'],
  "rehabilitative": ['R IY2 HH AH0 B IH1 L AH0 T EY2 T IH0 V', 'R IY2 AH0 B IH1 L AH0 T EY2 T IH0 V'],
  "rehabilitator": ['R IY2 HH AH0 B IH1 L AH0 T EY0 T ER0'],
  "rehabilitators": ['R IY2 HH AH0 B IH1 L AH0 T EY0 T ER0 Z'],
  "rehability": ['R IY2 HH AH0 B IH1 L AH0 T IY0', 'R IY2 AH0 B IH1 L AH0 T IY0'],
  "rehak": ['R EH1 HH AH0 K'],
  "rehash": ['R IY0 HH AE1 SH'],
  "rehashing": ['R IY0 HH AE1 SH IH0 NG'],
  "rehbein": ['R EH1 B AY0 N', 'R EH1 B IY0 N'],
  "rehberg": ['R EH1 B ER0 G'],
  "rehberger": ['R EH1 B ER0 G ER0'],
  "rehder": ['R EH1 D ER0'],
  "rehear": ['R IY0 HH IY1 R'],
  "rehearing": ['R IY0 HH IY1 R IH0 NG'],
  "rehearings": ['R IY0 HH IY1 R IH0 NG Z'],
  "rehearsal": ['R IH0 HH ER1 S AH0 L', 'R IY0 HH ER1 S AH0 L'],
  "rehearsals": ['R IH0 HH ER1 S AH0 L Z'],
  "rehearse": ['R IY0 HH ER1 S'],
  "rehearsed": ['R IY0 HH ER1 S T'],
  "rehearses": ['R IY0 HH ER1 S IH0 Z'],
  "rehearsing": ['R IH0 HH ER1 S IH0 NG'],
  "reher": ['R EH1 R'],
  "rehfeld": ['R EH1 F EH2 L D'],
  "rehfeldt": ['R EH1 F EH2 L T'],
  "rehg": ['R EH1 JH'],
  "rehire": ['R IY0 HH AY1 R'],
  "rehired": ['R IY0 HH AY1 ER0 D'],
  "rehiring": ['R IY0 HH AY1 R IH0 NG'],
  "rehkopf": ['R EH1 K AO0 P F', 'R EH1 K AO0 F'],
  "rehling": ['R EH1 L IH0 NG'],
  "rehm": ['R EH1 M'],
  "rehman": ['R EH1 M AH0 N'],
  "rehmann": ['R EH1 M AH0 N'],
  "rehmer": ['R EH1 M ER0'],
  "rehn": ['R EH1 N'],
  "rehnquist": ['R EH1 N K W IH2 S T'],
  "rehor": ['R EH1 HH ER0'],
  "rehrig": ['R EH1 R IH0 G'],
  "rehydrate": ['R IY0 HH AY1 D R EY0 T'],
  "rehydration": ['R IY2 HH AY0 D R EY1 SH AH0 N'],
  "reibel": ['R AY1 B AH0 L'],
  "reiber": ['R AY1 B ER0'],
  "reich": ['R AY1 K'],
  "reich's": ['R AY1 K S'],
  "reichard": ['R AY1 K ER0 D'],
  "reichardt": ['R AY1 K AA0 R T'],
  "reichart": ['R IY1 IH0 K HH AA0 R T'],
  "reiche": ['R AY1 K'],
  "reichel": ['R AY1 K AH0 L'],
  "reichelderfer": ['R AY1 K IH0 L D ER0 F ER0'],
  "reichelt": ['R AY1 K IH0 L T'],
  "reichenbach": ['R AY1 K AH0 N B AA2 K'],
  "reichenberg": ['R AY1 K AH0 N B ER0 G'],
  "reichenberger": ['R AY1 K AH0 N B ER0 G ER0'],
  "reicher": ['R AY1 K ER0'],
  "reichert": ['R AY1 K ER0 T'],
  "reichhold": ['R AY1 K HH OW2 L D', 'R AY1 K OW2 L D'],
  "reichhold's": ['R AY1 K HH OW2 L D Z', 'R AY1 K OW2 L D Z'],
  "reichl": ['R AY1 K AH0 L'],
  "reichle": ['R AY1 K AH0 L'],
  "reichler": ['R AY1 K L ER0'],
  "reichley": ['R AY1 K L IY0'],
  "reichlin": ['R AY1 K L IH0 N'],
  "reichling": ['R AY1 K AH0 L IH0 NG', 'R AY1 K L IH0 NG'],
  "reichman": ['R AY1 K M AH0 N'],
  "reichmann": ['R AY1 K M AH0 N'],
  "reichmanns": ['R AY1 K M AH0 N Z'],
  "reichmuth": ['R AY1 K M UW2 TH'],
  "reichow": ['R AY1 K OW0'],
  "reichstuhl": ['R AY1 K S T UW0 L'],
  "reichwein": ['R AY1 K W AY2 N'],
  "reick": ['R IY1 K'],
  "reicks": ['R AY1 K S'],
  "reid": ['R IY1 D'],
  "reid's": ['R IY1 D Z'],
  "reid-miller": ['R IY1 D M IH1 L ER0'],
  "reidel": ['R AY1 D AH0 L'],
  "reidenbach": ['R AY1 D IH0 N B AA0 K'],
  "reider": ['R IY1 D ER0'],
  "reiders": ['R IY1 D ER0 Z'],
  "reiders'": ['R IY1 D ER0 Z'],
  "reidinger": ['R AY1 D IH0 NG ER0'],
  "reidt": ['R IY1 D T'],
  "reidy": ['R IY1 D IY0'],
  "reier": ['R EY1 ER0'],
  "reierson": ['R AY1 ER0 S AH0 N'],
  "reif": ['R IY1 F'],
  "reifel": ['R AY1 F AH0 L'],
  "reiff": ['R IY1 F'],
  "reifschneider": ['R AY1 F SH N AY0 D ER0'],
  "reifsnyder": ['R AY1 F S N AY0 D ER0'],
  "reifsteck": ['R AY1 F S T IH0 K'],
  "reigel": ['R AY1 G AH0 L'],
  "reiger": ['R AY1 G ER0'],
  "reighard": ['R AY1 G HH ER0 D'],
  "reigle": ['R IY1 G AH0 L'],
  "reign": ['R EY1 N'],
  "reigned": ['R EY1 N D'],
  "reigning": ['R EY1 N IH0 NG'],
  "reignite": ['R IY2 IH0 G N AY1 T'],
  "reignited": ['R IY2 IH0 G N AY1 T IH0 D'],
  "reigniting": ['R IY2 IH0 G N AY1 T IH0 NG'],
  "reigns": ['R EY1 N Z'],
  "reiher": ['R AY1 HH ER0'],
  "reihl": ['R AY1 L'],
  "reiki": ['R EY1 K IY0'],
  "reil": ['R EY1 L'],
  "reiland": ['R AY1 L AH0 N D'],
  "reiley": ['R EY1 L IY0'],
  "reiling": ['R AY1 L IH0 NG'],
  "reilley": ['R EY1 L IY0'],
  "reilly": ['R AY1 L IY0'],
  "reilly's": ['R AY1 L IY0 Z'],
  "reily": ['R IY1 L IY0'],
  "reim": ['R IY1 M'],
  "reiman": ['R AY1 M AH0 N'],
  "reimann": ['R AY1 M AH0 N'],
  "reimburse": ['R IY2 IH0 M B ER1 S'],
  "reimbursed": ['R IY2 IH0 M B ER1 S T'],
  "reimbursement": ['R IY2 IH0 M B ER1 S M AH0 N T'],
  "reimbursements": ['R IY2 IH0 M B ER1 S M AH0 N T S'],
  "reimburses": ['R IY2 IH0 M B ER1 S IH0 Z'],
  "reimbursing": ['R IY2 IH0 M B ER1 S IH0 NG'],
  "reimer": ['R AY1 M ER0'],
  "reimers": ['R AY1 M ER0 Z'],
  "reimpose": ['R IY2 IH0 M P OW1 Z'],
  "reimposed": ['R IY2 IH0 M P OW1 Z D'],
  "reimposing": ['R IY2 IH0 M P OW1 Z IH0 NG'],
  "reimposition": ['R IY2 IH0 M P AH0 Z IH1 SH AH0 N'],
  "reims": ['R IY1 M Z'],
  "rein": ['R EY1 N'],
  "reina": ['R EY0 IY1 N AH0'],
  "reinaldo": ['R EY2 N AA1 L D OW0'],
  "reinard": ['R AY1 N ER0 D'],
  "reinbold": ['R AY1 N B OW2 L D'],
  "reinbolt": ['R AY1 N B OW2 L T'],
  "reincarnate": ['R IY2 IH0 N K AA1 R N EY2 T'],
  "reincarnated": ['R IY2 IH0 N K AA1 R N EY2 T IH0 D'],
  "reincarnation": ['R IY2 IH0 N K AA0 R N EY1 SH AH0 N'],
  "reincke": ['R AY1 NG K IY0'],
  "reincorporate": ['R IY2 IH0 N K AO1 R P ER0 EY2 T'],
  "reincorporating": ['R IY2 IH0 N K AO1 R P ER0 EY2 T IH0 NG'],
  "reincorporation": ['R IY2 IH0 N K AO2 R P ER0 EY1 SH AH0 N'],
  "reindeer": ['R EY1 N D IH2 R'],
  "reindel": ['R AY1 N D AH0 L'],
  "reinders": ['R AY1 N D ER0 Z'],
  "reindl": ['R AY1 N D AH0 L'],
  "reindustrialize": ['R IY0 IH0 N D AH1 S T R IY0 AH0 L AY0 Z'],
  "reine": ['R EY1 N'],
  "reineck": ['R AY1 N EH0 K'],
  "reinecke": ['R AY1 N IH0 K IY0'],
  "reined": ['R EY1 N D'],
  "reineke": ['R AY1 N IH0 K'],
  "reiner": ['R AY1 N ER0'],
  "reiner's": ['R AY1 N ER0 Z'],
  "reiners": ['R AY1 N ER0 Z'],
  "reinert": ['R AY1 N ER0 T'],
  "reinertsen": ['R AY1 N ER0 T S AH0 N'],
  "reinertson": ['R AY1 N ER0 T S AH0 N'],
  "reines": ['R EY1 N Z'],
  "reiney": ['R EY1 N IY0'],
  "reinforce": ['R IY2 IH0 N F AO1 R S'],
  "reinforced": ['R IY2 IH0 N F AO1 R S T'],
  "reinforcement": ['R IY2 IH0 N F AO1 R S M AH0 N T'],
  "reinforcements": ['R IY2 IH0 N F AO1 R S M AH0 N T S'],
  "reinforces": ['R IY2 IH0 N F AO1 R S IH0 Z'],
  "reinforcing": ['R IY2 IH0 N F AO1 R S IH0 NG'],
  "reingold": ['R AY1 NG G OW0 L D'],
  "reinhard": ['R AY1 N HH AA2 R D'],
  "reinhardt": ['R AY1 N HH AA2 R T'],
  "reinhart": ['R AY1 N HH AA2 R T'],
  "reinhart's": ['R AY1 N HH AA0 R T S'],
  "reinheimer": ['R AY1 N HH AY2 M ER0'],
  "reinhold": ['R AY1 N HH OW0 L T'],
  "reinholz": ['R AY1 N HH OW0 L Z'],
  "reinig": ['R AY1 N IH0 G'],
  "reining": ['R EY1 N IH0 NG'],
  "reininger": ['R AY1 N IH0 NG ER0'],
  "reinitz": ['R AY1 N IH0 T S'],
  "reinke": ['R EY1 NG K'],
  "reinking": ['R AY1 NG K IH0 NG'],
  "reinoehl": ['R AY1 N OW0 L'],
  "reins": ['R EY1 N Z'],
  "reinsch": ['R AY1 N SH'],
  "reinsdorf": ['R AY1 N Z D AO2 R F'],
  "reinsel": ['R AY1 N S AH0 L'],
  "reinspect": ['R IY2 IH0 N S P EH1 K T'],
  "reinspection": ['R IY2 IH0 N S P EH1 K SH AH0 N'],
  "reinspections": ['R IY2 IH0 N S P EH1 K SH AH0 N S'],
  "reinstall": ['R IY2 IH0 N S T AA1 L'],
  "reinstalled": ['R IY2 IH0 N S T AA1 L D'],
  "reinstalls": ['R IY2 IH0 N S T AA1 L Z'],
  "reinstate": ['R IY2 IH0 N S T EY1 T'],
  "reinstated": ['R IY2 IH0 N S T EY1 T IH0 D'],
  "reinstatement": ['R IY2 IH0 N S T EY1 T M AH0 N T'],
  "reinstating": ['R IY2 IH0 N S T EY1 T IH0 NG'],
  "reinstein": ['R AY1 N S T AY2 N'],
  "reinstitute": ['R IY2 IH1 N S T IH0 T UW0 T'],
  "reinstituted": ['R IY2 IH1 N S T IH0 T UW0 T IH0 D'],
  "reinstituting": ['R IY2 IH1 N S T AH0 T UW2 T IH0 NG'],
  "reinsurance": ['R IY2 IH0 N SH UH1 R AH0 N S'],
  "reinsure": ['R IY2 IH0 N SH UH1 R'],
  "reinsured": ['R IY2 IH0 N SH UH1 R D'],
  "reinsurer": ['R IY2 IH0 N SH UH1 R ER0'],
  "reinsurers": ['R IY2 IH0 N SH UH1 R ER0 Z'],
  "reintegrate": ['R IY0 IH1 N T AH0 G R EY2 T'],
  "reintegrated": ['R IY0 IH1 N T AH0 G R EY2 T IH0 D'],
  "reintegration": ['R IY0 IH1 N T AH0 G R EY2 SH AH0 N'],
  "reinterpret": ['R IY2 IH0 N T ER1 P R AH0 T'],
  "reinterpretation": ['R IY0 IH0 N T ER2 P R AH0 T EY1 SH AH0 N'],
  "reinterpreted": ['R IY0 IH0 N T ER1 P R AH0 T IH0 D'],
  "reinterpreting": ['R IY0 IH0 N T ER1 P R AH0 T IH0 NG'],
  "reintroduce": ['R IY2 IH0 N T R AH0 D UW1 S'],
  "reintroduced": ['R IY2 IH0 N T R AH0 D UW1 S T'],
  "reintroduces": ['R IY2 IH0 N T R AH0 D UW1 S IH0 Z'],
  "reintroducing": ['R IY2 IH0 N T R AH0 D UW1 S IH0 NG'],
  "reintroduction": ['R IY2 IH0 N T R AH0 D AH1 K SH AH0 N'],
  "reints": ['R AY1 N T S'],
  "reinvent": ['R IY2 IH0 N V EH1 N T'],
  "reinvented": ['R IY2 IH0 N V EH1 N T IH0 D'],
  "reinventing": ['R IY2 IH0 N V EH1 N T IH0 NG'],
  "reinvention": ['R IY2 IH0 N V EH1 N SH AH0 N'],
  "reinvest": ['R IY2 IH0 N V EH1 S T'],
  "reinvested": ['R IY2 IH0 N V EH1 S T IH0 D'],
  "reinvesting": ['R IY2 IH0 N V EH1 S T IH0 NG'],
  "reinvestment": ['R IY2 IH0 N V EH1 S T M AH0 N T', 'R IY2 IH0 N V EH1 S M AH0 N T'],
  "reinvests": ['R IY2 IH0 N V EH1 S T S', 'R IY2 IH0 N V EH1 S S', 'R IY2 IH0 N V EH1 S'],
  "reinvigorate": ['R IY2 IH0 N V IH1 G ER0 EY2 T'],
  "reinvigorated": ['R IY2 IH0 N V IH1 G ER0 EY2 T IH0 D'],
  "reinvigorating": ['R IY2 IH0 N V IH1 G ER0 EY2 T IH0 NG'],
  "reinvigoration": ['R IY2 IH0 N V IH2 G ER0 EY1 SH AH0 N'],
  "reinvite": ['R IY2 IH0 N V AY1 T'],
  "reinvited": ['R IY2 IH0 N V AY1 T IH0 D'],
  "reinwald": ['R AY1 N W AO2 L D'],
  "reis": ['R IY1 Z'],
  "reisch": ['R AY1 SH'],
  "reischauer": ['R AY1 SH AW0 R'],
  "reischman": ['R AY1 SH M AH0 N'],
  "reisdorf": ['R AY1 S D AO0 R F'],
  "reise": ['R IY1 S'],
  "reisen": ['R AY1 S AH0 N'],
  "reisenauer": ['R AY1 S IH0 N AW0 ER0'],
  "reiser": ['R AY1 S ER0'],
  "reisert": ['R AY1 S ER0 T'],
  "reish": ['R IY1 IH0 SH'],
  "reisig": ['R AY1 S IH0 G'],
  "reising": ['R AY1 S IH0 NG'],
  "reisinger": ['R AY1 S IH0 N JH ER0'],
  "reisman": ['R AY1 S M AH0 N'],
  "reisner": ['R AY1 S N ER0'],
  "reiss": ['R AY1 S'],
  "reissig": ['R AY1 S IH0 G'],
  "reissman": ['R AY1 S M AH0 N'],
  "reissue": ['R IY0 IH1 SH UW0'],
  "reissued": ['R IY0 IH1 SH UW0 D'],
  "reissuing": ['R IY0 IH1 SH UW0 IH0 NG'],
  "reist": ['R IY1 IH0 S T'],
  "reister": ['R IY1 IH0 S T ER0'],
  "reiswig": ['R AY1 S W IH0 G'],
  "reisz": ['R AY1 SH'],
  "reit": ['R AY1 T'],
  "reitan": ['R AY1 T AH0 N'],
  "reitano": ['R EY0 IY0 T AA1 N OW0'],
  "reiten": ['R AY1 T AH0 N'],
  "reiter": ['R AY1 T ER0'],
  "reitera": ['R IY0 IH1 T ER0 AH0'],
  "reiterate": ['R IY0 IH1 T ER0 EY2 T'],
  "reiterated": ['R IY0 IH1 T ER0 EY2 T IH0 D'],
  "reiterates": ['R IY0 IH1 T ER0 EY2 S'],
  "reiterating": ['R IY0 IH1 T ER0 EY2 T IH0 NG'],
  "reiteration": ['R IY0 IH2 T ER0 EY1 SH AH0 N'],
  "reith": ['R IY1 TH'],
  "reither": ['R AY1 DH ER0'],
  "reitman": ['R AY1 T M AH0 N'],
  "reitmeier": ['R AY1 T M AY0 ER0'],
  "reitmeyer": ['R AY1 T M AY0 ER0'],
  "reits": ['R AY1 T S'],
  "reitsma": ['R AY1 T S M AH0'],
  "reitter": ['R AY1 T ER0'],
  "reitz": ['R AY1 T S'],
  "reitzel": ['R AY1 T S AH0 L'],
  "reitzes": ['R AY1 T S IH0 Z'],
  "reitzfeld": ['R IY1 T S F EH2 L D', 'R AY2 T S F EH0 L D'],
  "reject": ['R IH0 JH EH1 K T', 'R IY1 JH EH0 K T'],
  "rejected": ['R IH0 JH EH1 K T IH0 D', 'R IY0 JH EH1 K T IH0 D'],
  "rejecting": ['R IH0 JH EH1 K T IH0 NG', 'R IY0 JH EH1 K T IH0 NG'],
  "rejection": ['R IH0 JH EH1 K SH AH0 N', 'R IY0 JH EH1 K SH AH0 N'],
  "rejectionist": ['R IH0 JH EH1 K SH AH0 N IH0 S T', 'R IY0 JH EH1 K SH AH0 N IH0 S T'],
  "rejectionists": ['R IH0 JH EH1 K SH AH0 N IH0 S T S', 'R IY0 JH EH1 K SH AH0 N IH0 S T S'],
  "rejections": ['R IH0 JH EH1 K SH AH0 N Z', 'R IY0 JH EH1 K SH AH0 N Z'],
  "rejects": ['R IH0 JH EH1 K T S', 'R IY1 JH EH0 K T S', 'R IH0 JH EH1 K S', 'R IY1 JH EH0 K S'],
  "rejiggering": ['R IY0 JH IH1 G ER0 IH0 NG'],
  "rejoice": ['R IH0 JH OY1 S'],
  "rejoiced": ['R IH0 JH OY1 S T'],
  "rejoicing": ['R IH0 JH OY1 S IH0 NG'],
  "rejoin": ['R IY0 JH OY1 N'],
  "rejoinder": ['R IH0 JH OY1 N D ER0', 'R IY0 JH OY1 N D ER0'],
  "rejoined": ['R IY0 JH OY1 N D'],
  "rejoining": ['R IY0 JH OY1 N IH0 NG'],
  "rejoins": ['R IY0 JH OY1 N Z'],
  "rejuvenate": ['R IH0 JH UW1 V AH0 N EY2 T'],
  "rejuvenated": ['R IH0 JH UW1 V AH0 N EY2 T IH0 D'],
  "rejuvenates": ['R IH0 JH UW1 V IH0 N EY2 T S'],
  "rejuvenating": ['R IY0 JH UW1 V AH0 N EY2 T IH0 NG'],
  "rejuvenation": ['R IH0 JH UW2 V AH0 N EY1 SH AH0 N'],
  "rekenthaler": ['R EH1 K AH0 N T AA2 L ER0', 'R EH1 K AH0 N TH AA2 L ER0'],
  "reker": ['R IY1 K ER0'],
  "rekindle": ['R IY0 K IH1 N D AH0 L'],
  "rekindled": ['R IY0 K IH1 N D AH0 L D'],
  "rekindling": ['R IY0 K IH1 N D L IH0 NG'],
  "relabel": ['R IY0 L EY1 B AH0 L'],
  "relapse": ['R IY0 L AE1 P S'],
  "relapsed": ['R IY0 L AE1 P S T'],
  "relapses": ['R IY0 L AE1 P S IH0 Z', 'R IH0 L AE1 P S IH0 Z'],
  "relapsing": ['R IH0 L AE1 P S IH0 NG'],
  "relate": ['R IH0 L EY1 T', 'R IY0 L EY1 T'],
  "related": ['R IH0 L EY1 T IH0 D', 'R IY2 L EY1 T IH0 D', 'R IY0 L EY1 T IH0 D'],
  "relates": ['R IH0 L EY1 T S', 'R IY0 L EY1 T S'],
  "relating": ['R IH0 L EY1 T IH0 NG', 'R IY0 L EY1 T IH0 NG'],
  "relation": ['R IY0 L EY1 SH AH0 N'],
  "relational": ['R IY0 L EY1 SH AH0 N AH0 L'],
  "relations": ['R IY0 L EY1 SH AH0 N Z'],
  "relationship": ['R IY0 L EY1 SH AH0 N SH IH2 P'],
  "relationships": ['R IY0 L EY1 SH AH0 N SH IH2 P S'],
  "relative": ['R EH1 L AH0 T IH0 V'],
  "relative's": ['R EH1 L AH0 T IH0 V Z'],
  "relatively": ['R EH1 L AH0 T IH0 V L IY0'],
  "relatives": ['R EH1 L AH0 T IH0 V Z'],
  "relativism": ['R EH1 L AH0 T IH0 V IH2 Z AH0 M'],
  "relativistic": ['R EH2 L AH0 T IH0 V IH1 S T IH0 K'],
  "relativity": ['R EH2 L AH0 T IH1 V AH0 T IY0'],
  "relaunch": ['R IY0 L AO1 N CH'],
  "relaunched": ['R IY0 L AO1 N CH T'],
  "relax": ['R IH0 L AE1 K S', 'R IY0 L AE1 K S'],
  "relaxation": ['R IY2 L AE0 K S EY1 SH AH0 N'],
  "relaxed": ['R IH0 L AE1 K S T', 'R IY0 L AE1 K S T'],
  "relaxes": ['R IH0 L AE1 K S IH0 Z'],
  "relaxing": ['R IH0 L AE1 K S IH0 NG', 'R IY0 L AE1 K S IH0 NG'],
  "relay": ['R IY1 L EY2'],
  "relayed": ['R IY1 L EY2 D'],
  "relaying": ['R IY1 L EY2 IH0 NG'],
  "relays": ['R IY1 L EY2 Z'],
  "relearn": ['R IY0 L EH1 R N'],
  "relearning": ['R IY0 L EH1 R N IH0 NG'],
  "release": ['R IY0 L IY1 S'],
  "released": ['R IY0 L IY1 S T'],
  "releases": ['R IH0 L IY1 S IH0 Z'],
  "releasing": ['R IY0 L IY1 S IH0 NG'],
  "releford": ['R EH1 L IH0 F ER0 D'],
  "relegate": ['R EH1 L AH0 G EY2 T'],
  "relegated": ['R EH1 L AH0 G EY2 T IH0 D'],
  "relegating": ['R EH1 L AH0 G EY2 T IH0 NG'],
  "relend": ['R IY0 L EH1 N D'],
  "relending": ['R IY0 L EH1 N D IH0 NG'],
  "relent": ['R IH0 L EH1 N T'],
  "relented": ['R IH0 L EH1 N T IH0 D', 'R IY0 L EH1 N T IH0 D', 'R AH0 L EH1 N AH0 D', 'R IY0 L EH1 N AH0 D'],
  "relenting": ['R IH0 L EH1 N T IH0 NG'],
  "relentless": ['R IH0 L EH1 N T L IH0 S'],
  "relentlessly": ['R IH0 L EH1 N T L AH0 S L IY0'],
  "relevance": ['R EH1 L AH0 V AH0 N S'],
  "relevancy": ['R EH1 L AH0 V AH0 N S IY0'],
  "relevant": ['R EH1 L AH0 V AH0 N T'],
  "relf": ['R EH1 L F'],
  "relford": ['R EH1 L F ER0 D'],
  "reli": ['R EH1 L IY0'],
  "reliability": ['R IY0 L AY2 AH0 B IH1 L AH0 T IY0'],
  "reliable": ['R IH0 L AY1 AH0 B AH0 L', 'R IY0 L AY1 AH0 B AH0 L'],
  "reliably": ['R IH0 L AY1 AH0 B L IY0', 'R IY0 L AY1 AH0 B L IY0'],
  "reliance": ['R IH0 L AY1 AH0 N S', 'R IY0 L AY1 AH0 N S'],
  "reliance's": ['R IH0 L AY1 AH0 N S IH0 Z', 'R IY0 L AY1 AH0 N S IH0 Z'],
  "reliant": ['R IH0 L AY1 AH0 N T', 'R IY0 L AY1 AH0 N T'],
  "relic": ['R EH1 L IH0 K'],
  "relics": ['R EH1 L IH0 K S'],
  "relied": ['R IH0 L AY1 D', 'R IY0 L AY1 D'],
  "relief": ['R IH0 L IY1 F', 'R IY0 L IY1 F'],
  "reliefs": ['R IY0 L IY1 F S'],
  "relies": ['R IH0 L AY1 Z', 'R IY0 L AY1 Z'],
  "relieve": ['R IH0 L IY1 V', 'R IY0 L IY1 V'],
  "relieved": ['R IH0 L IY1 V D', 'R IY0 L IY1 V D'],
  "reliever": ['R IY0 L IY1 V ER0'],
  "relievers": ['R IY0 L IY1 V ER0 Z'],
  "relieves": ['R IY0 L IY1 V Z'],
  "relieving": ['R IH0 L IY1 V IH0 NG', 'R IY0 L IY1 V IH0 NG'],
  "reliford": ['R EH1 L IH0 F AO0 R D'],
  "relig": ['R IH0 L IH1 JH'],
  "religion": ['R IH0 L IH1 JH AH0 N', 'R IY0 L IH1 JH AH0 N'],
  "religion's": ['R IH0 L IH1 JH AH0 N Z'],
  "religione": ['R IH0 L IH2 JH IY0 OW1 N IY0'],
  "religionist": ['R IY0 L IH1 JH AH0 N IH0 S T'],
  "religions": ['R IY0 L IH1 JH AH0 N Z'],
  "religiosity": ['R IH0 L IH2 JH IY0 AA1 S AH0 T IY0'],
  "religious": ['R IH0 L IH1 JH AH0 S', 'R IY0 L IH1 JH AH0 S'],
  "religiously": ['R IH0 L IH1 JH AH0 S L IY0'],
  "relinquish": ['R IH0 L IH1 NG K W IH0 SH', 'R IY0 L IH1 NG K W IH0 SH'],
  "relinquished": ['R IH0 L IH1 NG K W IH0 SH T', 'R IY0 L IH1 NG K W IH0 SH T'],
  "relinquishes": ['R IH0 L IH1 NG K W IH0 SH IH0 Z'],
  "relinquishing": ['R IY0 L IH1 NG K W IH0 SH IH0 NG'],
  "relish": ['R EH1 L IH0 SH'],
  "relished": ['R EH1 L IH0 SH T'],
  "relishes": ['R EH1 L IH0 SH AH0 Z', 'R EH1 L IH0 SH IH0 Z'],
  "relishing": ['R EH1 L IH0 SH IH0 NG'],
  "relive": ['R IY0 L IH1 V'],
  "relived": ['R IY0 L IH1 V D'],
  "reliving": ['R IY0 L IH1 V IH0 NG'],
  "rella": ['R EH1 L AH0'],
  "reller": ['R EH1 L ER0'],
  "relman": ['R EH1 L M AH0 N'],
  "reload": ['R IY0 L OW1 D'],
  "reloadable": ['R IY2 L OW1 D AH0 B AH0 L'],
  "reloaded": ['R IY2 L OW1 D IH0 D'],
  "reloads": ['R IY2 L OW1 D Z'],
  "relocate": ['R IY2 L OW1 K EY0 T'],
  "relocated": ['R IY2 L OW1 K EY0 T IH0 D'],
  "relocating": ['R IY2 L OW1 K EY0 T IH0 NG'],
  "relocation": ['R IY2 L OW1 K EY1 SH AH0 N'],
  "relocations": ['R IY2 L OW1 K EY1 SH AH0 N Z'],
  "relondo": ['R IH0 L AO1 N D OW0'],
  "reloral": ['R IY0 L AO1 R AH0 L'],
  "relph": ['R EH1 L F'],
  "reluctance": ['R IH0 L AH1 K T AH0 N S', 'R IY0 L AH1 K T AH0 N S'],
  "reluctant": ['R IH0 L AH1 K T AH0 N T', 'R IY0 L AH1 K T AH0 N T'],
  "reluctantly": ['R IH0 L AH1 K T AH0 N T L IY0'],
  "rely": ['R IH0 L AY1', 'R IY0 L AY1'],
  "relyea": ['R EH1 L Y EY2'],
  "relying": ['R IY0 L AY1 IH0 NG'],
  "rem": ['R EH1 M'],
  "remade": ['R IY0 M EY1 D'],
  "remain": ['R IH0 M EY1 N', 'R IY0 M EY1 N'],
  "remainder": ['R IH0 M EY1 N D ER0', 'R IY0 M EY1 N D ER0'],
  "remained": ['R IH0 M EY1 N D', 'R IY0 M EY1 N D'],
  "remaining": ['R IH0 M EY1 N IH0 NG', 'R IY0 M EY1 N IH0 NG'],
  "remains": ['R IH0 M EY1 N Z', 'R IY0 M EY1 N Z'],
  "remake": ['R IY1 M EY1 K'],
  "remakes": ['R IY1 M EY1 K S'],
  "remaking": ['R IY1 M EY1 K IH0 NG'],
  "remaley": ['R EH1 M AH0 L IY0'],
  "remaly": ['R IY1 M AH0 L IY0'],
  "remand": ['R IH0 M AE1 N D'],
  "remanded": ['R IH0 M AE1 N D IH0 D'],
  "remanufacture": ['R IY2 M AE2 N Y UW0 F AE1 K CH ER0', 'R IY2 M AE2 N Y AH0 F AE1 K CH ER0'],
  "remanufactured": ['R IY2 M AE2 N Y UW0 F AE1 K CH ER0 D', 'R IY2 M AE2 N Y AH0 F AE1 K CH ER0 D'],
  "remark": ['R IH0 M AA1 R K', 'R IY0 M AA1 R K'],
  "remarkable": ['R IH0 M AA1 R K AH0 B AH0 L', 'R IY0 M AA1 R K AH0 B AH0 L'],
  "remarkably": ['R IH0 M AA1 R K AH0 B L IY0', 'R IY0 M AA1 R K AH0 B L IY0'],
  "remarked": ['R IH0 M AA1 R K T', 'R IY0 M AA1 R K T'],
  "remarket": ['R IY0 M AA1 R K AH0 T'],
  "remarketed": ['R IY0 M AA1 R K AH0 T IH0 D'],
  "remarketing": ['R IY0 M AA1 R K AH0 T IH0 NG'],
  "remarking": ['R IH0 M AA1 R K IH0 NG'],
  "remarks": ['R IH0 M AA1 R K S', 'R IY0 M AA1 R K S'],
  "remarriage": ['R IY0 M EH1 R IH0 JH'],
  "remarried": ['R IY0 M EH1 R IY0 D'],
  "remarry": ['R IY0 M EH1 R IY0'],
  "remarrying": ['R IY0 M EH1 R IY0 IH0 NG'],
  "rematch": ['R IY1 M AE1 CH'],
  "rembert": ['R EH1 M B ER0 T'],
  "rembold": ['R EH1 M B OW2 L D'],
  "rembrandt": ['R EH1 M B R AE2 N T'],
  "rembrandt's": ['R EH1 M B R AE2 N T S'],
  "rembrandts": ['R EH1 M B R AE2 N T S'],
  "remco": ['R EH1 M K OW0'],
  "remedial": ['R IH0 M IY1 D IY0 AH0 L'],
  "remediate": ['R IY0 M IY1 D IY0 AH0 T', 'R IY0 M IY1 D IY0 EY2 T'],
  "remediation": ['R IH0 M IY2 D IY0 EY1 SH AH0 N'],
  "remedied": ['R EH1 M AH0 D IY0 D'],
  "remedies": ['R EH1 M AH0 D IY0 Z'],
  "remedy": ['R EH1 M AH0 D IY0'],
  "remedying": ['R EH1 M AH0 D IY0 IH0 NG'],
  "remember": ['R IH0 M EH1 M B ER0', 'R IY0 M EH1 M B ER0'],
  "remembered": ['R IH0 M EH1 M B ER0 D', 'R IY0 M EH1 M B ER0 D'],
  "remembering": ['R IH0 M EH1 M B ER0 IH0 NG', 'R IY0 M EH1 M B ER0 IH0 NG', 'R IH0 M EH1 M B R IH0 NG', 'R IY0 M EH1 M B R IH0 NG'],
  "remembers": ['R IH0 M EH1 M B ER0 Z', 'R IY0 M EH1 M B ER0 Z'],
  "remembrance": ['R IY0 M EH1 M B R AH0 N S'],
  "remembrances": ['R IH0 M EH1 M B R AH0 N S IH0 Z'],
  "remer": ['R IY1 M ER0'],
  "remerchandise": ['R IY0 M ER1 CH AH0 N D AY2 Z'],
  "remerchandised": ['R IY0 M ER1 CH AH0 N D AY2 Z D'],
  "remerci": ['R IH0 M ER1 S IY0'],
  "remi": ['R EH1 M IY0'],
  "remic": ['R EH1 M IH0 K'],
  "remick": ['R EH1 M IH0 K'],
  "remics": ['R EH1 M IH0 K S'],
  "remigio": ['R IH0 M IH1 JH IY0 OW0'],
  "remillard": ['R EH1 M IH0 L ER0 D'],
  "remind": ['R IY0 M AY1 N D'],
  "reminded": ['R IY0 M AY1 N D IH0 D'],
  "reminder": ['R IY0 M AY1 N D ER0'],
  "reminders": ['R IY0 M AY1 N D ER0 Z'],
  "reminding": ['R IY0 M AY1 N D IH0 NG'],
  "reminds": ['R IY0 M AY1 N D Z'],
  "remington": ['R EH1 M IH0 NG T AH0 N'],
  "remingtons": ['R EH1 M IH0 NG T AH0 N Z'],
  "reminisce": ['R EH2 M AH0 N IH1 S'],
  "reminisced": ['R EH2 M AH0 N IH1 S T'],
  "reminiscence": ['R EH2 M AH0 N IH1 S AH0 N S'],
  "reminiscences": ['R EH2 M AH0 N IH1 S AH0 N S IH0 Z'],
  "reminiscent": ['R EH2 M AH0 N IH1 S AH0 N T'],
  "reminisces": ['R EH2 M AH0 N IH1 S IH0 Z'],
  "reminiscing": ['R EH2 M AH0 N IH1 S IH0 NG'],
  "remis": ['R EY1 M IY0 Z'],
  "remiss": ['R IY0 M IH1 S'],
  "remisses": ['R IY0 M IH1 S IH0 Z'],
  "remission": ['R IY0 M IH1 SH AH0 N'],
  "remissions": ['R IY0 M IH1 SH AH0 N Z'],
  "remit": ['R IY0 M IH1 T'],
  "remittance": ['R IY0 M IH1 T AH0 N S', 'R AH0 M IH1 T AH0 N S'],
  "remittances": ['R IY0 M IH1 T AH0 N S IH0 Z', 'R AH0 M IH1 T AH0 N S IH0 Z'],
  "remitted": ['R IY0 M IH1 T IH0 D'],
  "remley": ['R EH1 M L IY0'],
  "remlinger": ['R EH1 M AH0 L IH0 NG ER0', 'R EH1 M L IH0 NG ER0'],
  "remme": ['R EH1 M'],
  "remmel": ['R EH1 M AH0 L'],
  "remmers": ['R EH1 M ER0 Z'],
  "remmert": ['R EH1 M ER0 T'],
  "remmington": ['R EH1 M IH0 NG T IH0 N'],
  "remmy": ['R EH1 M IY0'],
  "remnant": ['R EH1 M N AH0 N T'],
  "remnants": ['R EH1 M N AH0 N T S'],
  "remnick": ['R EH1 M N IH0 K'],
  "remo": ['R EH1 M OW0'],
  "remodel": ['R IY0 M AA1 D AH0 L'],
  "remodeled": ['R IY0 M AA1 D AH0 L D'],
  "remodeling": ['R IH0 M AA1 D AH0 L IH0 NG', 'R IH0 M AA1 D L IH0 NG'],
  "remold": ['R IY0 M OW1 L D'],
  "remolded": ['R IY0 M OW1 L D IH0 D'],
  "remorse": ['R IH0 M AO1 R S'],
  "remorseful": ['R IH0 M AO1 R S F AH0 L'],
  "remorseless": ['R IH0 M AO1 R S L AH0 S'],
  "remote": ['R IH0 M OW1 T', 'R IY0 M OW1 T'],
  "remotely": ['R IY0 M OW1 T L IY0'],
  "remoteness": ['R IY0 M OW1 T N AH0 S'],
  "remotest": ['R IH0 M OW1 T AH0 S T'],
  "remoulded": ['R IY0 M OW1 L D IH0 D'],
  "removable": ['R IH0 M UW1 V AH0 B AH0 L'],
  "removal": ['R IH0 M UW1 V AH0 L'],
  "removals": ['R IH0 M UW1 V AH0 L Z'],
  "remove": ['R IY0 M UW1 V'],
  "removed": ['R IY0 M UW1 V D'],
  "remover": ['R IH0 M UW1 V ER0'],
  "removes": ['R IY0 M UW1 V Z'],
  "removing": ['R IY0 M UW1 V IH0 NG'],
  "rempac": ['R EH1 M P AE2 K'],
  "rempe": ['R EH1 M P'],
  "rempel": ['R EH1 M P AH0 L'],
  "rempfer": ['R EH1 M P F ER0'],
  "remsberg": ['R EH1 M S B ER0 G'],
  "remsburg": ['R EH1 M S B ER0 G'],
  "remsen": ['R EH1 M S AH0 N'],
  "remson": ['R EH1 M S AH0 N'],
  "remunerate": ['R IH0 M Y UW2 N ER0 EY1 T'],
  "remuneration": ['R IH0 M Y UW2 N ER0 EY1 SH AH0 N'],
  "remunerative": ['R IY0 M Y UW1 N ER0 AH0 T IH0 V'],
  "remus": ['R IY1 M AH0 S'],
  "remy": ['R EH1 M IY0'],
  "ren": ['R EH1 N'],
  "rena": ['R IY1 N AH0'],
  "renaissance": ['R EH2 N AH0 S AA1 N S'],
  "renaissance's": ['R EH2 N AH0 S AA1 N S IH0 Z'],
  "renal": ['R IY1 N AH0 L'],
  "renaldo": ['R EH0 N AA1 L D OW0'],
  "rename": ['R IY0 N EY1 M'],
  "renamed": ['R IY0 N EY1 M D'],
  "renaming": ['R IY0 N EY1 M IH0 NG'],
  "renamo": ['R EH0 N AA1 M OW0'],
  "renard": ['R IH0 N AA1 R D'],
  "renata": ['R AH0 N AA1 T AH0'],
  "renate": ['R AH0 N AA1 T AH0'],
  "renationalization": ['R IY0 N AE2 SH AH0 N AH0 L IH0 Z EY1 SH AH0 N'],
  "renationalize": ['R IY0 N AE1 SH AH0 N AH0 L AY2 Z'],
  "renato": ['R EH0 N AA1 T OW0'],
  "renaud": ['R IH0 N OW1'],
  "renault": ['R AH0 N OW1', 'R IH0 N AO1 L T'],
  "renault's": ['R IH0 N AO1 L T S', 'R AH0 N OW1 Z'],
  "renbarger": ['R EH1 N B AA2 R G ER0'],
  "rencen": ['R EH1 N S AH0 N'],
  "rench": ['R EH1 N CH'],
  "rencher": ['R EH1 N CH ER0'],
  "renco": ['R EH1 N K OW0'],
  "renda": ['R EH1 N D AH0'],
  "rendall": ['R EH1 N D AH0 L'],
  "rende": ['R EH1 N D'],
  "rendell": ['R EH1 N D AH0 L'],
  "rendell's": ['R EH1 N D AH0 L Z'],
  "render": ['R EH1 N D ER0'],
  "rendered": ['R EH1 N D ER0 D'],
  "rendering": ['R EH1 N D ER0 IH0 NG'],
  "renderings": ['R EH1 N D ER0 IH0 NG Z'],
  "renders": ['R EH1 N D ER0 Z'],
  "rendezvous": ['R AA1 N D IH0 V UW2'],
  "rendina": ['R EH0 N D IY1 N AH0'],
  "rending": ['R EH1 N D IH0 NG'],
  "rendition": ['R EH0 N D IH1 SH AH0 N'],
  "renditions": ['R EH0 N D IH1 SH AH0 N Z'],
  "rendleman": ['R EH1 N D AH0 L M AH0 N'],
  "rendon": ['R EH1 N D OW0 N'],
  "rene": ['R AH0 N EY1'],
  "reneau": ['R IH0 N OW1'],
  "renee": ['R AH0 N EY1'],
  "renegade": ['R EH1 N AH0 G EY2 D'],
  "renegades": ['R EH1 N AH0 G EY2 D Z'],
  "renegar": ['R EH1 N IH0 G ER0'],
  "renege": ['R IH0 N IH1 G'],
  "reneged": ['R IH0 N IH1 G D'],
  "reneging": ['R IH0 N IH1 G IH0 NG'],
  "renegotiate": ['R IY2 N IH0 G OW1 SH IY0 EY2 T'],
  "renegotiated": ['R IY2 N IH0 G OW1 SH IY0 EY2 T IH0 D'],
  "renegotiating": ['R IY2 N IH0 G OW1 SH IY0 EY2 T IH0 NG'],
  "renegotiation": ['R IY2 N IH0 G OW2 SH IY0 EY1 SH AH0 N'],
  "renegotiations": ['R IY2 N IH0 G OW2 SH IY0 EY1 SH AH0 N Z'],
  "renehan": ['R EH1 N IH0 HH AE0 N'],
  "renew": ['R IH0 N UW1'],
  "renewable": ['R IY0 N UW1 AH0 B AH0 L'],
  "renewal": ['R IH0 N UW1 AH0 L'],
  "renewals": ['R IH0 N UW1 AH0 L Z'],
  "renewed": ['R IH0 N UW1 D', 'R IY0 N UW1 D'],
  "renewing": ['R IH0 N UW1 IH0 NG'],
  "renews": ['R IH0 N UW1 Z'],
  "renfred": ['R EH1 N F ER0 D'],
  "renfrew": ['R EH1 N F R UW0'],
  "renfro": ['R EH1 N F R OW0'],
  "renfroe": ['R EH1 N F R OW0'],
  "renfrow": ['R EH1 N F R AW0'],
  "rengel": ['R EH1 NG G AH0 L'],
  "rengo": ['R EH1 NG G OW0'],
  "rengo's": ['R EH1 NG G OW0 Z'],
  "renick": ['R EH1 N IH0 K'],
  "renier": ['R IY1 N IY0 ER0'],
  "renin": ['R IY1 N AH0 N'],
  "renison": ['R EH1 N IH0 S AH0 N'],
  "renita": ['R EH0 N IY1 T AH0'],
  "renk": ['R EH1 NG K'],
  "renken": ['R EH1 NG K AH0 N'],
  "renko": ['R EH1 NG K OW0'],
  "renminbi": ['R EH0 N M IH1 N B IY0'],
  "renn": ['R EH1 N'],
  "renna": ['R EH1 N AH0'],
  "rennard": ['R IH0 N AA1 R D'],
  "renne": ['R EH1 N'],
  "rennels": ['R EH1 N AH0 L Z'],
  "renner": ['R EH1 N ER0'],
  "rennert": ['R EH1 N ER0 T'],
  "rennet": ['R EH1 N AH0 T'],
  "rennick": ['R EH1 N IH0 K'],
  "rennie": ['R EH1 N IY0'],
  "renninger": ['R EH1 N IH0 NG ER0'],
  "renno": ['R EH1 N OW0'],
  "renny": ['R EH1 N IY0'],
  "reno": ['R IY1 N OW0'],
  "reno's": ['R IY1 N OW0 Z'],
  "renoir": ['R EH0 N W AA1 R'],
  "renoirs": ['R EH0 N W AA1 R Z'],
  "renominate": ['R IY0 N AO1 M IH0 N EY2 T'],
  "renominated": ['R IY0 N AA1 M AH0 N EY2 T IH0 D'],
  "renomination": ['R IY1 N AA2 M AH0 N EY1 SH AH0 N'],
  "renouf": ['R AH0 N UW1 F'],
  "renounce": ['R IH0 N AW1 N S'],
  "renounced": ['R IH0 N AW1 N S T'],
  "renounces": ['R IH0 N AW1 N S IH0 Z'],
  "renouncing": ['R IH0 N AW1 N S IH0 NG'],
  "renovatable": ['R EH1 N AH0 V EY2 T AH0 B AH0 L'],
  "renovate": ['R EH1 N AH0 V EY2 T'],
  "renovated": ['R EH1 N AH0 V EY2 T IH0 D'],
  "renovating": ['R EH1 N AH0 V EY2 T IH0 NG'],
  "renovation": ['R EH2 N AH0 V EY1 SH AH0 N'],
  "renovations": ['R EH1 N AH0 V EY2 SH AH0 N Z'],
  "renovator": ['R EH1 N AH0 V EY2 T ER0'],
  "renovators": ['R EH1 N AH0 V EY2 T ER0 Z'],
  "renown": ['R IH0 N AW1 N'],
  "renowned": ['R IH0 N AW1 N D'],
  "renquist": ['R EH1 N K W IH0 S T'],
  "rens": ['R EH1 N Z'],
  "rensberger": ['R EH1 N S B ER0 G ER0'],
  "rensch": ['R EH1 N SH'],
  "renschler": ['R EH1 N SH AH0 L ER0', 'R EH1 N SH L ER0'],
  "renshaw": ['R EH1 N SH AO2'],
  "rensing": ['R EH1 N S IH0 NG'],
  "rensselaer": ['R EH2 N S AH0 L IH1 R'],
  "renstrom": ['R EH1 N S T R AH0 M'],
  "rent": ['R EH1 N T'],
  "renta": ['R EH1 N T AH0'],
  "rentable": ['R EH1 N T AH0 B AH0 L'],
  "rental": ['R EH1 N T AH0 L'],
  "rentals": ['R EH1 N T AH0 L Z'],
  "rentas": ['R EH1 N T AH0 Z'],
  "rented": ['R EH1 N T IH0 D'],
  "renter": ['R EH1 N T ER0'],
  "renteria": ['R EH0 N T EH1 R IY0 AH0'],
  "renters": ['R EH1 N T ER0 Z'],
  "rentfro": ['R EH1 N T F R OW0'],
  "rentfrow": ['R EH1 N T F R AW0'],
  "rentier": ['R EH1 T IY2 ER0'],
  "renting": ['R EH1 N T IH0 NG'],
  "rentmeester": ['R EH1 N T M IY2 S T ER0'],
  "renton": ['R EH1 N T AH0 N'],
  "rents": ['R EH1 N T S'],
  "rentsch": ['R EH1 N CH'],
  "rentschler": ['R EH1 N CH L ER0'],
  "rentz": ['R EH1 N T S'],
  "renunciate": ['R IH0 N AH1 N S IY0 EY2 T'],
  "renunciation": ['R IH0 N AH2 N S IY0 EY1 SH AH0 N'],
  "renuzit": ['R IY0 N UW1 Z IH0 T'],
  "renwick": ['R EH1 N W IH0 K'],
  "reny": ['R IY1 N IY0'],
  "renz": ['R EH1 N Z'],
  "renzi": ['R EH1 N Z IY0'],
  "renzulli": ['R EH0 N Z UW1 L IY0'],
  "reo": ['R IY1 OW0'],
  "reoccupy": ['R IY0 AO1 K Y UW2 P AY0'],
  "reoccur": ['R IY0 AH0 K ER1'],
  "reoffend": ['R IY0 AO0 F EH1 N D'],
  "reoffended": ['R IY0 AO0 F EH1 N D IH0 D'],
  "reoffer": ['R IY0 AO1 F ER0'],
  "reoffered": ['R IY0 AO1 F ER0 D'],
  "reoffering": ['R IY0 AO1 F ER0 IH0 NG'],
  "reopen": ['R IY0 OW1 P AH0 N'],
  "reopened": ['R IY0 OW1 P AH0 N D'],
  "reopening": ['R IY0 OW1 P AH0 N IH0 NG'],
  "reopens": ['R IY0 OW1 P AH0 N Z'],
  "reorder": ['R IY0 AO1 R D ER0'],
  "reordering": ['R IY0 AO1 R D ER0 IH0 NG'],
  "reorganization": ['R IY2 AO0 R G AH0 N AH0 Z EY1 SH AH0 N'],
  "reorganizations": ['R IY0 AO2 R G AH0 N IH0 Z EY1 SH AH0 N Z'],
  "reorganize": ['R IY0 AO1 R G AH0 N AY2 Z'],
  "reorganized": ['R IY0 AO1 R G AH0 N AY2 Z D'],
  "reorganizes": ['R IY0 AO1 R G AH0 N AY2 Z IH0 Z'],
  "reorganizing": ['R IY0 AO1 R G AH0 N AY2 Z IH0 NG'],
  "reorient": ['R IY0 AO1 R IY0 EH0 N T'],
  "reorientate": ['R IY0 AO1 R IY0 EH0 N T EY0 T'],
  "rep": ['R EH1 P', 'R EH0 P R IY0 Z EH1 T AH0 T IH0 V'],
  "rep's": ['R EH1 P S'],
  "rep.": ['R EH0 P R IY0 Z EH1 T AH0 T IH0 V'],
  "repack": ['R IY0 P AE1 K'],
  "repackage": ['R IY2 P AE1 K IH0 JH'],
  "repackaged": ['R IY1 P AE0 K IH0 JH D'],
  "repackages": ['R IY2 P AE1 K IH0 JH IH0 Z'],
  "repackaging": ['R IY1 P AE0 K IH0 JH IH0 NG'],
  "repacked": ['R IY0 P AE1 K T'],
  "repaid": ['R IY0 P EY1 D'],
  "repaint": ['R IY0 P EY1 N T'],
  "repainted": ['R IY0 P EY1 N T IH0 D'],
  "repainting": ['R IY0 P EY1 N T IH0 NG'],
  "repair": ['R IH0 P EH1 R'],
  "repairable": ['R IH0 P EH1 R AH0 B AH0 L'],
  "repaired": ['R IH0 P EH1 R D', 'R IY0 P EH1 R D'],
  "repairing": ['R IH0 P EH1 R IH0 NG', 'R IY0 P EH1 R IH0 NG'],
  "repairman": ['R IH0 P EH1 R M AE2 N', 'R IY0 P EH1 R M AE2 N'],
  "repairmen": ['R IH0 P EH1 R M EH2 N'],
  "repairs": ['R IH0 P EH1 R Z', 'R IY0 P EH1 R Z'],
  "repap": ['R IY1 P AE0 P'],
  "reparation": ['R EH2 P ER0 EY1 SH AH0 N'],
  "reparations": ['R EH2 P ER0 EY1 SH AH0 N Z'],
  "repartee": ['R EH2 P ER0 T IY1'],
  "repasky": ['R IH0 P AA1 S K IY0'],
  "repass": ['R IY0 P AE1 S'],
  "repatriate": ['R IY0 P EY1 T R IY0 EY2 T'],
  "repatriated": ['R IY0 P EY1 T R IY0 EY2 T IH0 D'],
  "repatriating": ['R IY0 P EY1 T R IY0 EY2 T IH0 NG'],
  "repatriation": ['R IY0 P EY2 T R IY0 EY1 SH AH0 N'],
  "repay": ['R IY0 P EY1'],
  "repayable": ['R IY0 P EY1 AH0 B AH0 L'],
  "repaying": ['R IY0 P EY1 IH0 NG'],
  "repayment": ['R IY0 P EY1 M AH0 N T'],
  "repayments": ['R IY0 P EY1 M AH0 N T S'],
  "repays": ['R IY2 P EY1 Z'],
  "repeal": ['R IH0 P IY1 L', 'R IY0 P IY1 L'],
  "repealed": ['R IH0 P IY1 L D', 'R IY0 P IY1 L D'],
  "repealing": ['R IY0 P IY1 L IH0 NG'],
  "repeals": ['R IY0 P IY1 L Z'],
  "repeat": ['R IH0 P IY1 T', 'R IY0 P IY1 T'],
  "repeatable": ['R IH0 P IY1 T AH0 B AH0 L'],
  "repeated": ['R IH0 P IY1 T IH0 D', 'R IY0 P IY1 T IH0 D'],
  "repeatedly": ['R IH0 P IY1 T IH0 D L IY0'],
  "repeater": ['R IH0 P IY1 T ER0'],
  "repeaters": ['R IH0 P IY1 T ER0 Z'],
  "repeating": ['R IH0 P IY1 T IH0 NG', 'R IY0 P IY1 T IH0 NG'],
  "repeats": ['R IH0 P IY1 T S', 'R IY0 P IY1 T S'],
  "repel": ['R IH0 P EH1 L'],
  "repelled": ['R AH0 P EH1 L D', 'R IH0 P EH1 L D'],
  "repellent": ['R IH0 P EH1 L AH0 N T'],
  "repellents": ['R IH0 P EH1 L AH0 N T S'],
  "repelling": ['R AH0 P EH1 L IH0 NG'],
  "repels": ['R IH0 P EH1 L Z'],
  "repent": ['R IH0 P EH1 N T'],
  "repentance": ['R IH0 P EH1 N T AH0 N S'],
  "repentant": ['R IH0 P EH1 N T AH0 N T'],
  "repented": ['R IH0 P EH1 N T IH0 D'],
  "repenting": ['R IH0 P EH1 N T IH0 NG'],
  "repents": ['R IH0 P EH1 N T S'],
  "repercussion": ['R IY2 P ER0 K AH1 SH AH0 N', 'R IY2 P R AH0 K AH1 SH AH0 N'],
  "repercussions": ['R IY2 P ER0 K AH1 SH AH0 N Z', 'R IY2 P R AH0 K AH1 SH AH0 N Z'],
  "reperfusion": ['R EH2 P ER0 F Y UW1 ZH AH0 N'],
  "repertoire": ['R EH1 P ER0 T W AA2 R'],
  "repertory": ['R EH1 P ER0 T AO2 R IY0'],
  "repetition": ['R EH2 P AH0 T IH1 SH AH0 N'],
  "repetitions": ['R EH2 P AH0 T IH1 SH AH0 N Z'],
  "repetitious": ['R EH2 P AH0 T IH1 SH AH0 S'],
  "repetitive": ['R IH0 P EH1 T IH0 T IH0 V'],
  "repetti": ['R EH0 P EH1 T IY0'],
  "repetto": ['R EH0 P EH1 T OW0'],
  "reph": ['R EH1 F'],
  "rephrase": ['R IY0 F R EY1 Z'],
  "repinski": ['R IH0 P IH1 N S K IY0'],
  "repka": ['R EH1 P K AH0'],
  "repko": ['R EH1 P K OW0'],
  "replace": ['R IY2 P L EY1 S', 'ER0 P L EY1 S'],
  "replaceable": ['R IY2 P L EY1 S AH0 B AH0 L'],
  "replaced": ['R IY2 P L EY1 S T'],
  "replacement": ['R IH0 P L EY1 S M AH0 N T'],
  "replacements": ['R IY0 P L EY1 S M AH0 N T S'],
  "replaces": ['R IH0 P L EY1 S IH0 Z'],
  "replacing": ['R IH0 P L EY1 S IH0 NG'],
  "replant": ['R IY0 P L AE1 N T'],
  "replanted": ['R IY0 P L AE1 N T IH0 D', 'R IY0 P L AE1 N IH0 D'],
  "replanting": ['R IY0 P L AE1 N T IH0 NG'],
  "replay": ['R IY0 P L EY1'],
  "replayed": ['R IY0 P L EY1 D'],
  "replaying": ['R IY0 P L EY1 IH0 NG'],
  "replays": ['R IY0 P L EY1 Z'],
  "replenish": ['R IY0 P L EH1 N IH0 SH'],
  "replenished": ['R IY0 P L EH1 N IH0 SH T'],
  "replenishing": ['R IY0 P L EH1 N IH0 SH IH0 NG'],
  "replenishment": ['R IH0 P L EH1 N IH0 SH M AH0 N T'],
  "replete": ['R IY0 P L IY1 T'],
  "replica": ['R EH1 P L IH0 K AH0'],
  "replicas": ['R EH1 P L AH0 K AH0 Z'],
  "replicase": ['R EH1 P L IH0 K EY2 Z'],
  "replicate": ['R EH1 P L AH0 K EY2 T'],
  "replicated": ['R EH1 P L IH0 K EY2 T IH0 D'],
  "replicates": ['R EH1 P L IH0 K EY2 T S'],
  "replicating": ['R EH1 P L IH0 K EY2 T IH0 NG'],
  "replication": ['R EH2 P L AH0 K EY1 SH AH0 N'],
  "replicator": ['R EH1 P L IH0 K EY2 T ER0'],
  "replicators": ['R EH1 P L IH0 K EY2 T ER0 S'],
  "replied": ['R IH0 P L AY1 D', 'R IY0 P L AY1 D'],
  "replies": ['R IH0 P L AY1 Z', 'R IY0 P L AY1 Z'],
  "repligen": ['R IH2 P L AY1 JH IH0 N'],
  "repligen's": ['R IH2 P L AY1 JH IH0 N Z'],
  "replogle": ['R EH1 P L OW0 G AH0 L'],
  "reply": ['R IH0 P L AY1', 'R IY0 P L AY1'],
  "replying": ['R IH0 P L AY1 IH0 NG', 'R IY0 P L AY1 IH0 NG'],
  "repo": ['R IY1 P OW0'],
  "reponse": ['R IH0 P AA1 N S'],
  "report": ['R IY0 P AO1 R T', 'R IH0 P AO1 R T'],
  "report's": ['R IY0 P AO1 R T S', 'R IH0 P AO1 R T S'],
  "reportable": ['R IH0 P AO1 R T AH0 B AH0 L'],
  "reportage": ['R IH0 P AO1 R T IH0 JH'],
  "reported": ['R IY2 P AO1 R T IH0 D', 'R IH0 P AO1 R T IH0 D'],
  "reportedly": ['R IH0 P AO1 R T AH0 D L IY0', 'R IY0 P AO1 R T AH0 D L IY0'],
  "reporter": ['R IH0 P AO1 R T ER0'],
  "reporter's": ['R IH0 P AO1 R T ER0 Z'],
  "reporters": ['R IH0 P AO1 R T ER0 Z'],
  "reporters'": ['R IH0 P AO1 R T ER0 Z'],
  "reporting": ['R IY0 P AO1 R T IH0 NG', 'R IH0 P AO1 R T IH0 NG'],
  "reportorial": ['R EH2 P ER0 T AO1 R IY0 AH0 L'],
  "reports": ['R IH0 P AO1 R T S', 'R IY0 P AO1 R T S'],
  "reports'": ['R IH0 P AO1 R T S', 'R IY0 P AO1 R T S'],
  "repos": ['R IY1 P OW2 Z'],
  "reposa": ['R EH0 P OW1 S AH0'],
  "repose": ['R IY0 P OW1 Z'],
  "reposition": ['R IY2 P AH0 Z IH1 SH AH0 N'],
  "repositioned": ['R IY2 P AH0 Z IH1 SH AH0 N D'],
  "repositioning": ['R IY2 P AH0 Z IH1 SH AH0 N IH0 NG'],
  "repositories": ['R IY0 P AA1 Z AH0 T AO2 R IY0 Z'],
  "repository": ['R IY0 P AA1 Z AH0 T AO2 R IY0'],
  "repossess": ['R IY2 P AH0 Z EH1 S'],
  "repossessed": ['R IY2 P AH0 Z EH1 S T'],
  "repossession": ['R IY2 P AH0 Z EH1 SH AH0 N'],
  "repossessions": ['R IY2 P AH0 Z EH1 SH AH0 N Z'],
  "repost": ['R IY0 P OW1 S T'],
  "reposted": ['R IY0 P OW1 S T IH0 D'],
  "repp": ['R EH1 P'],
  "reppert": ['R EH1 P ER0 T'],
  "reppond": ['R EH1 P AH0 N D'],
  "reppucci": ['R EH0 P UW1 CH IY0'],
  "repr": ['R EH1 P ER0'],
  "reprehensible": ['R EH2 P R IH0 HH EH1 N S AH0 B AH0 L'],
  "represent": ['R EH2 P R IH0 Z EH1 N T'],
  "representation": ['R EH2 P R AH0 Z EH0 N T EY1 SH AH0 N'],
  "representational": ['R EH2 P R AH0 Z AH0 N T EY1 SH AH0 N AH0 L'],
  "representations": ['R EH2 P R AH0 Z AH0 N T EY1 SH AH0 N Z'],
  "representative": ['R EH2 P R AH0 Z EH1 N T AH0 T IH0 V', 'R EH2 P R IH0 Z EH1 N T AH0 T IH0 V', 'R EH2 P R AH0 Z EH1 N AH0 T IH0 V', 'R EH2 P R IH0 Z EH1 N AH0 T IH0 V'],
  "representative's": ['R EH2 P R IH0 Z EH1 N T AH0 T IH0 V Z', 'R EH2 P R IH0 Z EH1 N AH0 T IH0 V Z'],
  "representatives": ['R EH2 P R AH0 Z EH1 N T AH0 T IH0 V Z', 'R EH2 P R IH0 Z EH1 N T AH0 T IH0 V Z', 'R EH2 P R AH0 Z EH1 N AH0 T IH0 V Z', 'R EH2 P R IH0 Z EH1 N AH0 T IH0 V Z'],
  "representatives'": ['R EH2 P R AH0 S EH1 N T AH0 T IH0 V Z', 'R EH2 P R AH0 S EH1 N AH0 T IH0 V Z'],
  "represented": ['R EH2 P R IH0 Z EH1 N T IH0 D'],
  "representing": ['R EH2 P R IH0 Z EH1 N T IH0 NG'],
  "represents": ['R EH2 P R IH0 Z EH1 N T S'],
  "repress": ['R IY0 P R EH1 S'],
  "repressed": ['R IY0 P R EH1 S T'],
  "repressing": ['R IY0 P R EH1 S IH0 NG'],
  "repression": ['R IY0 P R EH1 SH AH0 N'],
  "repressions": ['R IY0 P R EH1 SH AH0 N Z'],
  "repressive": ['R IY0 P R EH1 S IH0 V'],
  "reprice": ['R IY0 P R AY1 S'],
  "repriced": ['R IY0 P R AY1 S T'],
  "repricing": ['R IY0 P R AY1 S IH0 NG'],
  "reprieve": ['R IY0 P R IY1 V'],
  "reprimand": ['R EH1 P R AH0 M AE2 N D'],
  "reprimanded": ['R EH1 P R AH0 M AE2 N D IH0 D'],
  "reprimands": ['R EH1 P R AH0 M AE2 N D Z'],
  "reprint": ['R IY0 P R IH1 N T'],
  "reprinted": ['R IY0 P R IH1 N T IH0 D'],
  "reprinting": ['R IY0 P R IH1 N T IH0 NG'],
  "reprints": ['R IY0 P R IH1 N T S'],
  "reprisal": ['R IY0 P R AY1 Z AH0 L'],
  "reprisals": ['R IH0 P R AY1 Z AH0 L Z', 'R IY0 P R AY1 Z AH0 L Z'],
  "reprise": ['R IH0 P R AY1 Z', 'R IH0 P R IY1 Z'],
  "reproach": ['R IY0 P R OW1 CH'],
  "reprobate": ['R EH1 P R AO0 B EY0 T'],
  "reprocess": ['R IY0 P R AO1 S EH0 S'],
  "reprocessed": ['R IY0 P R AO1 S EH0 S T'],
  "reprocessing": ['R IY0 P R AO1 S EH0 S IH0 NG'],
  "reproduce": ['R IY2 P R AH0 D UW1 S'],
  "reproduced": ['R IY2 P R AH0 D UW1 S T'],
  "reproduces": ['R IY2 P R AH0 D UW1 S IH0 Z'],
  "reproducing": ['R IY2 P R AH0 D UW1 S IH0 NG'],
  "reproduction": ['R IY2 P R AH0 D AH1 K SH AH0 N'],
  "reproductions": ['R IY2 P R AH0 D AH1 K SH AH0 N Z'],
  "reproductive": ['R IY2 P R AH0 D AH1 K T IH0 V'],
  "reprogenetics": ['R IY1 P R AO2 JH AH0 N EH2 T IH0 K S'],
  "reprogram": ['R IY0 P R OW1 G R AE2 M'],
  "reprogrammed": ['R IY0 P R OW1 G R AE2 M D'],
  "reprogramming": ['R IY0 P R OW1 G R AE2 M IH0 NG'],
  "reprograms": ['R IY0 P R OW1 G R AE2 M Z'],
  "reprograph": ['R EH1 P R OW0 G R AE2 F'],
  "reprographic": ['R EH2 P R OW0 G R AE1 F IH0 K'],
  "reprographics": ['R EH2 P R OW0 G R AE1 F IH0 K S'],
  "reps": ['R EH1 P S'],
  "repsher": ['R EH1 P SH ER0'],
  "repsol": ['R EH1 P S AA0 L'],
  "reptile": ['R EH1 P T AY0 L'],
  "reptiles": ['R EH1 P T AY0 L Z'],
  "reptilian": ['R EH0 P T IH1 L Y AH0 N'],
  "reptilians": ['R EH0 P T IH1 L Y AH0 N Z'],
  "republic": ['R IY0 P AH1 B L AH0 K', 'R IY0 P AH1 B L IH0 K'],
  "republic's": ['R IY0 P AH1 B L IH0 K S'],
  "republica": ['R IH0 P AH1 B L IH0 K AH0'],
  "republican": ['R IH0 P AH1 B L IH0 K AH0 N', 'R IY0 P AH1 B L AH0 K AH0 N', 'R IY0 P AH1 B L IH0 K AH0 N'],
  "republican's": ['R IY0 P AH1 B L IH0 K AH0 N Z'],
  "republicanism": ['R IH0 P AH1 B L IH0 K AH0 N IH2 Z AH0 M'],
  "republicans": ['R IH0 P AH1 B L IH0 K AH0 N Z', 'R IY0 P AH1 B L AH0 K AH0 N Z', 'R IY0 P AH1 B L IH0 K AH0 N Z'],
  "republicans'": ['R IH0 P AH1 B L IH0 K AH0 N Z', 'R IY0 P AH1 B L IH0 K AH0 N Z'],
  "republicbank": ['R IY0 P AH1 B L IH0 K B AE2 NG K'],
  "republicbank's": ['R IY0 P AH1 B L IH0 K B AE2 NG K S'],
  "republics": ['R IY0 P AH1 B L IH0 K S'],
  "republika": ['R IH0 P AH1 B L IH0 K AH0'],
  "repudiate": ['R IY0 P Y UW1 D IY0 EY2 T'],
  "repudiated": ['R IY0 P Y UW1 D IY0 EY2 T IH0 D'],
  "repudiates": ['R IY0 P Y UW1 D IY0 EY2 T S'],
  "repudiating": ['R IY0 P Y UW1 D IY0 EY2 T IH0 NG'],
  "repudiation": ['R IH0 P Y UW2 D IY0 EY1 SH AH0 N'],
  "repugnant": ['R IH0 P AH1 G N AH0 N T', 'R IY0 P AH1 G N AH0 N T'],
  "repulse": ['R IY0 P AH1 L S'],
  "repulsed": ['R IY0 P AH1 L S T'],
  "repulsing": ['R IY0 P AH1 L S IH0 NG'],
  "repulsive": ['R IY0 P AH1 L S IH0 V'],
  "repurchase": ['R IY0 P ER1 CH AH0 S'],
  "repurchased": ['R IY0 P ER1 CH AH0 S T'],
  "repurchases": ['R IY0 P ER1 CH AH0 S IH0 Z'],
  "repurchasing": ['R IY0 P ER1 CH AH0 S IH0 NG'],
  "reputable": ['R EH1 P Y AH0 T AH0 B AH0 L'],
  "reputation": ['R EH2 P Y AH0 T EY1 SH AH0 N'],
  "reputations": ['R EH2 P Y AH0 T EY1 SH AH0 N Z'],
  "repute": ['R IY0 P Y UW1 T'],
  "reputed": ['R IH0 P Y UW1 T IH0 D', 'R IY0 P Y UW1 T IH0 D'],
  "reputedly": ['R IH0 P Y UW1 T IH0 D L IY0'],
  "requa": ['R EY1 K W AH0'],
  "requalify": ['R IY2 K W AA1 L AH0 F AY2'],
  "request": ['R IH0 K W EH1 S T', 'R IY0 K W EH1 S T'],
  "requested": ['R IH0 K W EH1 S T IH0 D', 'R IY0 K W EH1 S T IH0 D'],
  "requester": ['R IH0 K W EH1 S T ER0'],
  "requesting": ['R IH0 K W EH1 S T IH0 NG', 'R IY0 K W EH1 S T IH0 NG'],
  "requests": ['R IH0 K W EH1 S T S', 'R IY0 K W EH1 S T S', 'R IH0 K W EH1 S', 'R IY0 K W EH1 S'],
  "requiem": ['R EH1 K W IY0 AH0 M'],
  "require": ['R IY2 K W AY1 ER0', 'R IY0 K W AY1 R', 'R IH0 K W AY1 ER0'],
  "required": ['R IY0 K W AY1 ER0 D', 'R IY0 K W AY1 R D'],
  "requirement": ['R IH0 K W AY1 R M AH0 N T'],
  "requirements": ['R IH0 K W AY1 R M AH0 N T S'],
  "requires": ['R IY0 K W AY1 ER0 Z', 'R IY0 K W AY1 R Z'],
  "requiring": ['R IY0 K W AY1 ER0 IH0 NG', 'R IY0 K W AY1 R IH0 NG'],
  "requisite": ['R EH1 K W AH0 Z AH0 T'],
  "requisites": ['R EH1 K W AH0 Z AH0 T S'],
  "requisition": ['R EH2 K W AH0 Z IH1 SH AH0 N'],
  "requisitioned": ['R EH2 K W AH0 Z IH1 SH AH0 N D'],
  "reread": ['R IY1 R IY1 D'],
  "rereading": ['R IY1 R IY1 D IH0 NG'],
  "reregulate": ['R IY0 R EH1 G Y AH0 L EY2 T'],
  "reregulation": ['R IY0 R EH0 G Y AH0 L EY1 SH AH0 N'],
  "reroute": ['R IY0 R UW1 T', 'R IY0 R AW1 T'],
  "rerouted": ['R IY0 R UW1 T IH0 D', 'R IY0 R AW1 T IH0 D'],
  "rerouting": ['R IY0 R UW1 T IH0 NG', 'R IY0 R AW1 T IH0 NG'],
  "rerun": ['R IY1 R AH1 N'],
  "rerunning": ['R IY1 R AH1 N IH0 NG'],
  "reruns": ['R IY1 R AH1 N Z'],
  "res": ['R EY1 Z'],
  "resale": ['R IY1 S EY2 L'],
  "resales": ['R IY1 S EY2 L Z'],
  "resch": ['R EH1 SH'],
  "reschedule": ['R IY0 S K EH1 JH UW0 L'],
  "rescheduled": ['R IY0 S K EH1 JH UW0 L D'],
  "rescheduling": ['R IY0 S K EH1 JH UW0 L IH0 NG'],
  "reschedulings": ['R IY0 SH K EH1 JH UW0 L IH0 NG Z'],
  "reschke": ['R EH1 SH K'],
  "rescigno": ['R EH0 S CH IY1 G N OW0'],
  "rescind": ['R IH0 S IH1 N D', 'R IY0 S IH1 N D'],
  "rescinded": ['R IH0 S IH1 N D IH0 D', 'R IY0 S IH1 N D IH0 D'],
  "rescinding": ['R IH0 S IH1 N D IH0 NG'],
  "rescinds": ['R IH0 S IH1 N D Z', 'R IY0 S IH1 N D Z'],
  "rescission": ['R IH0 S IH1 ZH AH0 N'],
  "rescissions": ['R IH0 S IH1 ZH AH0 N Z'],
  "rescue": ['R EH1 S K Y UW0'],
  "rescued": ['R EH1 S K Y UW0 D'],
  "rescuer": ['R EH1 S K Y UW2 ER0'],
  "rescuers": ['R EH1 S K Y UW2 ER0 Z'],
  "rescues": ['R EH1 S K Y UW2 Z'],
  "rescuing": ['R EH1 S K Y UW0 IH0 NG'],
  "resdel": ['R EH1 Z D EH2 L'],
  "reseachers": ['R IY0 S ER1 CH ER0 Z'],
  "reseal": ['R IY0 S IY1 L'],
  "resealable": ['R IY0 S IY1 L AH0 B AH0 L'],
  "resealed": ['R IY0 S IY1 L D'],
  "reseals": ['R IY0 S IY1 L Z'],
  "research": ['R IY0 S ER1 CH', 'R IY1 S ER0 CH'],
  "research's": ['R IY0 S ER1 CH IH0 Z'],
  "researched": ['R IY0 S ER1 CH T'],
  "researcher": ['R IY1 S ER0 CH ER0'],
  "researchers": ['R IY1 S ER0 CH ER0 Z'],
  "researchers'": ['R IY1 S ER0 CH ER0 Z'],
  "researches": ['R IY0 S ER1 CH IH0 Z'],
  "researching": ['R IY0 S ER1 CH IH0 NG'],
  "resection": ['R IY2 S EH1 K SH AH0 N'],
  "reseda": ['R EH0 S EY1 D AH0'],
  "reseed": ['R IY0 S IY1 D'],
  "resell": ['R IY0 S EH1 L'],
  "reseller": ['R IY0 S EH1 L ER0'],
  "resellers": ['R IY0 S EH1 L ER0 Z'],
  "reselling": ['R IY0 S EH1 L IH0 NG'],
  "resells": ['R IY0 S EH1 L Z'],
  "resemblance": ['R IH0 Z EH1 M B L AH0 N S', 'R IY0 Z EH1 M B L AH0 N S'],
  "resemblances": ['R IY0 Z EH1 M B L AH0 N S AH0 Z'],
  "resemble": ['R IH0 Z EH1 M B AH0 L', 'R IY0 Z EH1 M B AH0 L'],
  "resembled": ['R IH0 Z EH1 M B AH0 L D', 'R IY0 Z EH1 M B AH0 L D'],
  "resembles": ['R IH0 Z EH1 M B AH0 L Z', 'R IY0 Z EH1 M B AH0 L Z'],
  "resembling": ['R IH0 Z EH1 M B AH0 L IH0 NG', 'R IY0 Z EH1 M B AH0 L IH0 NG', 'R IY0 Z EH1 M B L IH0 NG'],
  "resend": ['R IH0 Z EH1 N D'],
  "resende": ['R IH0 S EH1 N D EY0'],
  "resendes": ['R EH1 S IH0 N D Z'],
  "resendez": ['R EY0 S EY1 N D EH0 Z'],
  "resendiz": ['R IH0 S EH1 N D IH0 Z'],
  "resent": ['R IH0 Z EH1 N T', 'R IY0 Z EH1 N T'],
  "resented": ['R IY0 Z EH1 N T IH0 D', 'R IY0 Z EH1 N AH0 D'],
  "resentful": ['R IH0 Z EH1 N T F AH0 L'],
  "resenting": ['R IH0 Z EH1 N T IH0 NG', 'R IY0 Z EH1 N T IH0 NG', 'R IH0 Z EH1 N IH0 NG', 'R IY0 Z EH1 N IH0 NG'],
  "resentment": ['R IH0 Z EH1 N T M AH0 N T', 'R IH0 Z EH1 N M AH0 N T'],
  "resentments": ['R IH0 Z EH1 N T M AH0 N T S', 'R IH0 Z EH1 N M AH0 N T S'],
  "resents": ['R IH0 Z EH1 N T S'],
  "reser": ['R IY1 Z ER0'],
  "reservation": ['R EH2 Z ER0 V EY1 SH AH0 N'],
  "reservationist": ['R EH2 Z ER0 V EY1 SH AH0 N IH0 S T'],
  "reservationists": ['R EH2 Z ER0 V EY1 SH AH0 N IH0 S T S'],
  "reservations": ['R EH2 Z ER0 V EY1 SH AH0 N Z'],
  "reserve": ['R IH0 Z ER1 V', 'R IY0 Z ER1 V'],
  "reserve's": ['R IH0 Z ER1 V Z', 'R IY0 Z ER1 V Z'],
  "reserved": ['R IH0 Z ER1 V D', 'R IY0 Z ER1 V D'],
  "reserveese": ['R EH2 Z ER0 V IY1 S'],
  "reserves": ['R IH0 Z ER1 V Z', 'R IY0 Z ER1 V Z'],
  "reserving": ['R IH0 Z ER1 V IH0 NG', 'R IY0 Z ER1 V IH0 NG'],
  "reservist": ['R IH0 Z ER1 V IH0 S T'],
  "reservists": ['R IH0 Z ER1 V IH0 S T S'],
  "reservoir": ['R EH1 Z AH0 V W AA2 R', 'R EH1 Z ER0 V W AA2 R'],
  "reservoirs": ['R EH1 Z ER0 V W AA2 R Z'],
  "reset": ['R IY0 S EH1 T', 'R IY1 S EH0 T'],
  "resetar": ['R EH1 S IH0 T ER0'],
  "reseting": ['R IY0 S EH1 T IH0 NG', 'R IY1 S EH0 T IH0 NG'],
  "resets": ['R IY0 S EH1 T S', 'R IY1 S EH0 T S'],
  "resettable": ['R IY0 S EH1 T AH0 B AH0 L'],
  "resettle": ['R IY0 S EH1 T AH0 L'],
  "resettled": ['R IY0 S EH1 T AH0 L D'],
  "resettlement": ['R IY0 S EH1 T AH0 L M AH0 N T'],
  "resh": ['R EH1 SH'],
  "reshape": ['R IY0 SH EY1 P'],
  "reshaped": ['R IY0 SH EY1 P T'],
  "reshaping": ['R IY0 SH EY1 P IH0 NG'],
  "reshot": ['R IY0 SH AO1 T'],
  "reshuffle": ['R IY0 SH AH1 F AH0 L'],
  "reshuffled": ['R IY0 SH AH1 F AH0 L D'],
  "reshuffling": ['R IY0 SH AH1 F AH0 L IH0 NG', 'R IY0 SH AH1 F L IH0 NG'],
  "reside": ['R IH0 Z AY1 D', 'R IY0 Z AY1 D'],
  "resided": ['R IH0 Z AY1 D IH0 D'],
  "residence": ['R EH1 Z IH0 D AH0 N S'],
  "residences": ['R EH1 Z IH0 D AH0 N S IH0 Z'],
  "residencies": ['R EH1 Z IH0 D EH2 N S IY0 Z'],
  "residency": ['R EH1 Z IH0 D AH0 N S IY0'],
  "resident": ['R EH1 Z IH0 D AH0 N T'],
  "resident's": ['R EH1 Z IH0 D AH0 N T S'],
  "residential": ['R EH2 Z IH0 D EH1 N SH AH0 L'],
  "residents": ['R EH1 Z IH0 D AH0 N T S'],
  "residents'": ['R EH1 Z IH0 D AH0 N T S'],
  "resides": ['R IH0 Z AY1 D Z', 'R IY0 Z AY1 D Z'],
  "residing": ['R IH0 Z AY1 D IH0 NG', 'R IY0 Z AY1 D IH0 NG'],
  "residual": ['R IH0 Z IH1 JH UW0 AH0 L'],
  "residuals": ['R IH0 Z IH1 JH UW0 AH0 L Z'],
  "residue": ['R EH1 Z AH0 D UW2'],
  "residues": ['R EH1 Z AH0 D UW2 Z'],
  "resign": ['R IH0 Z AY1 N', 'R IY0 Z AY1 N', 'R IY0 S AY1 N'],
  "resignation": ['R EH2 Z AH0 G N EY1 SH AH0 N', 'R EH2 Z IH0 G N EY1 SH AH0 N'],
  "resignations": ['R EH2 Z IH0 G N EY1 SH AH0 N Z'],
  "resigned": ['R IH0 Z AY1 N D', 'R IY0 Z AY1 N D', 'R IY0 S AY1 N D'],
  "resignee": ['R EH2 Z IH0 G N IY1'],
  "resignees": ['R EH2 Z IH0 G N IY1 Z'],
  "resigning": ['R IH0 Z AY1 N IH0 NG', 'R IY0 Z AY1 N IH0 NG', 'R IY0 S AY1 N IH0 NG'],
  "resigns": ['R IH0 Z AY1 N Z', 'R IY0 Z AY1 N Z', 'R IY0 S AY1 N Z'],
  "resilience": ['R IH0 Z IH1 L IY0 AH0 N S', 'R IH0 Z IH1 L Y AH0 N S'],
  "resiliency": ['R IH0 Z IH1 L Y AH0 N S IY0'],
  "resilient": ['R IH0 Z IH1 L Y AH0 N T'],
  "resin": ['R EH1 Z AH0 N', 'R EH1 Z IH0 N'],
  "resins": ['R EH1 Z AH0 N Z', 'R EH1 Z IH0 N Z'],
  "resist": ['R IH0 Z IH1 S T', 'R IY0 Z IH1 S T'],
  "resistance": ['R IH0 Z IH1 S T AH0 N S', 'R IY0 Z IH1 S T AH0 N S'],
  "resistant": ['R IH0 Z IH1 S T AH0 N T', 'R IY0 Z IH1 S T AH0 N T'],
  "resisted": ['R IH0 Z IH1 S T IH0 D', 'R IY0 Z IH1 S T IH0 D'],
  "resistence": ['R IH0 Z IH1 S T AH0 N S'],
  "resisting": ['R IH0 Z IH1 S T IH0 NG', 'R IY0 Z IH1 S T IH0 NG'],
  "resistiveness": ['R IH2 Z IH1 S T IH2 V N AH0 S'],
  "resistor": ['R IH0 Z IH1 S T ER0'],
  "resistors": ['R IH0 Z IH1 S T ER0 Z'],
  "resists": ['R IH0 Z IH1 S T S', 'R IY0 Z IH1 S T S', 'R IH0 Z IH1 S', 'R IY0 Z IH1 S'],
  "reske": ['R EH1 S K'],
  "resler": ['R EH1 Z L ER0'],
  "resnais": ['R EH0 S N EY1'],
  "resner": ['R EH1 S N ER0'],
  "resnick": ['R EH1 Z N IH0 K'],
  "resnick's": ['R EH1 Z N IH0 K S'],
  "resnik": ['R EH1 S N IH0 K'],
  "reso": ['R IH1 Z OW0', 'R IY1 Z OW0', 'R IH1 S OW0', 'R IY1 S OW0'],
  "reso's": ['R IY1 S OW0 Z', 'R IY1 Z OW0 Z', 'R IH1 S OW0 Z', 'R IH1 Z OW0 Z'],
  "resold": ['R IY0 S OW1 L D'],
  "resolute": ['R EH1 Z AH0 L UW2 T'],
  "resolutely": ['R EH1 S AH0 L UW2 T L IY0', 'R EH1 Z AH0 L UW2 T L IY0'],
  "resolution": ['R EH2 Z AH0 L UW1 SH AH0 N'],
  "resolution's": ['R EH2 Z AH0 L UW1 SH AH0 N Z'],
  "resolutions": ['R EH2 Z AH0 L UW1 SH AH0 N Z'],
  "resolve": ['R IY0 Z AA1 L V'],
  "resolved": ['R IY0 Z AA1 L V D'],
  "resolves": ['R IY0 Z AA1 L V Z'],
  "resolving": ['R IY0 Z AA1 L V IH0 NG'],
  "resonance": ['R EH1 Z AH0 N AH0 N S'],
  "resonant": ['R EH1 Z AH0 N AH0 N T'],
  "resonate": ['R EH1 Z AH0 N EY2 T'],
  "resonated": ['R EH1 Z AH0 N EY2 T IH0 D'],
  "resonates": ['R EH1 Z AH0 N EY2 T S'],
  "resonating": ['R EH1 Z AH0 N EY2 T IH0 NG'],
  "resort": ['R IH0 Z AO1 R T', 'R IY0 Z AO1 R T', 'R IY0 S AO1 R T'],
  "resort's": ['R IH0 Z AO1 R T S'],
  "resorted": ['R IH0 Z AO1 R T IH0 D', 'R IY0 Z AO1 R T IH0 D', 'R IY0 S AO1 R T IH0 D'],
  "resorting": ['R IH0 Z AO1 R T IH0 NG', 'R IY0 Z AO1 R T IH0 NG', 'R IY0 S AO1 R T IH0 NG'],
  "resorts": ['R IH0 Z AO1 R T S', 'R IY0 Z AO1 R T S', 'R IY0 S AO1 R T S'],
  "resorts'": ['R IH0 Z AO1 R T S'],
  "resound": ['R IY2 S AW1 N D', 'R IY2 Z AW1 N D'],
  "resounding": ['R IY0 S AW1 N D IH0 NG'],
  "resoundingly": ['R IH0 Z AW1 N D IH0 NG L IY0'],
  "resource": ['R IY1 S AO0 R S'],
  "resourceful": ['R IY0 S AO1 R S F AH0 L'],
  "resourcefulness": ['R IY0 S AO1 R S F AH0 L N AH0 S'],
  "resources": ['R IY1 S AO0 R S IH0 Z'],
  "resources'": ['R IY1 S AO0 R S IH0 Z'],
  "respawn": ['R IY2 S P AA1 N'],
  "respeak": ['R IY2 S P IY1 K'],
  "respect": ['R IH0 S P EH1 K T', 'R IY0 S P EH1 K T'],
  "respectability": ['R IY0 S P EH2 K T AH0 B IH1 L IH0 T IY0'],
  "respectable": ['R IH0 S P EH1 K T AH0 B AH0 L', 'R IY0 S P EH1 K T AH0 B AH0 L'],
  "respectably": ['R IY0 S P EH1 K T AH0 B L IY0'],
  "respected": ['R IH0 S P EH1 K T IH0 D', 'R IY0 S P EH1 K T IH0 D'],
  "respectful": ['R IH0 S P EH1 K T F AH0 L'],
  "respectfully": ['R IH0 S P EH1 K T F AH0 L IY0'],
  "respecting": ['R IY0 S P EH1 K T IH0 NG'],
  "respective": ['R IH0 S P EH1 K T IH0 V', 'R IY0 S P EH1 K T IH0 V'],
  "respectively": ['R IH0 S P EH1 K T IH0 V L IY0'],
  "respects": ['R IH0 S P EH1 K T S', 'R IY0 S P EH1 K T S', 'R AH0 S P EH1 K S', 'R IY0 S P EH1 K S'],
  "respess": ['R EY1 S P IH0 S'],
  "respiration": ['R EH2 S P ER0 EY1 SH AH0 N'],
  "respirator": ['R EH1 S P ER0 EY2 T ER0'],
  "respirators": ['R EH1 S P ER0 EY2 T ER0 Z'],
  "respiratory": ['R EH1 S P ER0 AH0 T AO2 R IY0'],
  "respironic": ['R EH2 S P ER0 AA1 N IH0 K'],
  "respironics": ['R EH2 S ER0 AA1 N IH0 K S'],
  "respite": ['R EH1 S P IH0 T'],
  "resplendent": ['R IY0 S P L EH1 N D AH0 N T'],
  "respond": ['R IH0 S P AA1 N D', 'R IY0 S P AA1 N D'],
  "responded": ['R IH0 S P AA1 N D IH0 D', 'R IY0 S P AA1 N D AH0 D', 'R IY0 S P AA1 N D IH0 D'],
  "respondent": ['R IH0 S P AA1 N D AH0 N T'],
  "respondents": ['R IH0 S P AA1 N D AH0 N T S'],
  "respondents'": ['R IH0 S P AA1 N D AH0 N T S'],
  "responder": ['R IH0 S P AA1 N D ER0'],
  "responders": ['R IH0 S P AA1 N D ER0 Z'],
  "responding": ['R IH0 S P AA1 N D IH0 NG', 'R IY0 S P AA1 N D IH0 NG'],
  "responds": ['R IH0 S P AA1 N D Z', 'R IY0 S P AA1 N D Z'],
  "response": ['R IH0 S P AA1 N S', 'R IY0 S P AA1 N S'],
  "responses": ['R IH0 S P AA1 N S IH0 Z', 'R IY0 S P AA1 N S AH0 Z', 'R IY0 S P AA1 N S IH0 Z'],
  "responsibilities": ['R IY0 S P AA2 N S AH0 B IH1 L AH0 T IY0 Z'],
  "responsibility": ['R IY0 S P AA2 N S AH0 B IH1 L AH0 T IY0'],
  "responsible": ['R IY0 S P AA1 N S AH0 B AH0 L'],
  "responsibly": ['R IH0 S P AA1 N S AH0 B L IY0'],
  "responsive": ['R IH0 S P AA1 N S IH0 V'],
  "responsiveness": ['R IH0 S P AA1 N S IH0 V N AH0 S'],
  "respress": ['R EH1 S P R IH0 S'],
  "ress": ['R EH1 S'],
  "ressa": ['R EH1 S AH0'],
  "ressa's": ['R EH1 S AH0 Z'],
  "resseguie": ['R EH1 S IH0 G W IY0'],
  "ressel": ['R EH1 S AH0 L'],
  "ressler": ['R EH1 S L ER0'],
  "rest": ['R EH1 S T'],
  "restage": ['R IY0 S T EY1 JH'],
  "restaged": ['R IY0 S T EY1 JH D'],
  "restaino": ['R EH0 S T AA0 IY1 N OW0'],
  "restart": ['R IY0 S T AA1 R T'],
  "restarted": ['R IY0 S T AA1 R T IH0 D'],
  "restarting": ['R IY0 S T AA1 R T IH0 NG'],
  "restate": ['R IY0 S T EY1 T'],
  "restated": ['R IY0 S T EY1 T IH0 D'],
  "restatement": ['R IY0 S T EY1 T M AH0 N T'],
  "restatements": ['R IY0 S T EY1 T M AH0 N T S'],
  "restates": ['R IY0 S T EY1 T S'],
  "restating": ['R IY0 S T EY1 T IH0 NG'],
  "restaurant": ['R EH1 S T ER0 AA2 N T', 'R EH1 S T R AA2 N T'],
  "restaurant's": ['R EH1 S T ER0 AA2 N T S', 'R EH1 S T R AA2 N T S'],
  "restauranteur": ['R EH0 S T R AA0 N T ER1'],
  "restauranteurs": ['R EH0 S T R AA0 N T ER1 Z'],
  "restaurants": ['R EH1 S T ER0 AA2 N T S', 'R EH1 S T R AA2 N T S'],
  "restaurants'": ['R EH1 S T ER0 AA2 N T S', 'R EH1 S T R AA2 N T S'],
  "restaurateur": ['R EH2 S T ER0 AH0 T ER1', 'R EH2 S T R AH0 T ER1'],
  "restaurateurs": ['R EH2 S T ER0 AH0 T ER1 Z', 'R EH2 S T R AH0 T ER1 Z'],
  "rested": ['R EH1 S T AH0 D', 'R EH1 S T IH0 D'],
  "rester": ['R EH1 S T ER0'],
  "restful": ['R EH1 S T F AH0 L'],
  "resting": ['R EH1 S T IH0 NG'],
  "restitute": ['R EH1 S T IH0 T UW2 T'],
  "restitution": ['R EH2 S T IH0 T UW1 SH AH0 N'],
  "restive": ['R EH1 S T IH0 V'],
  "restiveness": ['R EH1 S T IH0 V N AH0 S'],
  "restivo": ['R EH0 S T IY1 V OW0'],
  "restless": ['R EH1 S T L AH0 S'],
  "restlessly": ['R EH1 S T L AH0 S L IY0'],
  "restlessness": ['R EH1 S T L AH0 S N AH0 S'],
  "resto": ['R EH1 S T OW0'],
  "restock": ['R IY0 S T AA1 K'],
  "restocked": ['R IY0 S T AA1 K T'],
  "restocking": ['R IY0 S T AA1 K IH0 NG'],
  "reston": ['R EH1 S T AH0 N'],
  "restoration": ['R EH2 S T ER0 EY1 SH AH0 N'],
  "restorations": ['R EH2 S T ER0 EY1 SH AH0 N Z'],
  "restorative": ['R AH0 S T AO1 R AH0 T IH0 V'],
  "restore": ['R IH0 S T AO1 R'],
  "restored": ['R IH0 S T AO1 R D'],
  "restorer": ['R IH0 S T AO1 R ER0'],
  "restores": ['R IH0 S T AO1 R Z'],
  "restoring": ['R IH0 S T AO1 R IH0 NG'],
  "restrain": ['R IY0 S T R EY1 N'],
  "restrained": ['R IY0 S T R EY1 N D'],
  "restraining": ['R IY0 S T R EY1 N IH0 NG'],
  "restrains": ['R IY0 S T R EY1 N Z'],
  "restraint": ['R IH0 S T R EY1 N T', 'R IY0 S T R EY1 N T'],
  "restraints": ['R IH0 S T R EY1 N T S', 'R IY0 S T R EY1 N T S'],
  "restrepo": ['R EH0 S T R EH1 P OW0'],
  "restrict": ['R IY0 S T R IH1 K T'],
  "restricted": ['R IY0 S T R IH1 K T AH0 D', 'R IY0 S T R IH1 K T IH0 D'],
  "restricting": ['R IY0 S T R IH1 K T IH0 NG'],
  "restriction": ['R IY0 S T R IH1 K SH AH0 N'],
  "restrictions": ['R IY0 S T R IH1 K SH AH0 N Z'],
  "restrictive": ['R IY0 S T R IH1 K T IH0 V'],
  "restrictiveness": ['R AH0 S T R IH1 K T IH0 V N AH0 S'],
  "restricts": ['R IY0 S T R IH1 K T S'],
  "restroom": ['R EH1 S T R UW2 M'],
  "restrooms": ['R EH1 S T R UW2 M Z'],
  "restructure": ['R IY0 S T R AH1 K CH ER0'],
  "restructured": ['R IY0 S T R AH1 K CH ER0 D'],
  "restructures": ['R IY0 S T R AH1 K CH ER0 Z'],
  "restructuring": ['R IY0 S T R AH1 K CH ER0 IH0 NG'],
  "restructurings": ['R IY0 S T R AH1 K CH ER0 IH0 NG Z'],
  "rests": ['R EH1 S T S'],
  "restyle": ['R IY0 S T AY1 L'],
  "restyled": ['R IY0 S T AY1 L D'],
  "resubmit": ['R IY2 S AH0 B M IH1 T'],
  "resubmitted": ['R IY2 S AH0 B M IH1 T IH0 D'],
  "resubmitting": ['R IY2 S AH0 B M IH1 T IH0 NG'],
  "result": ['R IH0 Z AH1 L T', 'R IY0 Z AH1 L T'],
  "resultant": ['R IY0 Z AH1 L T AH0 N T'],
  "resulted": ['R IH0 Z AH1 L T IH0 D', 'R IY0 Z AH1 L T AH0 D', 'R IY0 Z AH1 L T IH0 D'],
  "resulting": ['R IH0 Z AH1 L T IH0 NG', 'R IY0 Z AH1 L T IH0 NG'],
  "results": ['R IH0 Z AH1 L T S', 'R IY0 Z AH1 L T S'],
  "resume": ['R IH0 Z UW1 M', 'R IY0 Z UW1 M', 'R EH1 Z AH0 M EY2'],
  "resumed": ['R IH0 Z UW1 M D', 'R IY0 Z UW1 M D'],
  "resumes": ['R IH0 Z UW1 M Z', 'R IY0 Z UW1 M Z', 'R EH1 Z AH0 M EY2 Z'],
  "resuming": ['R IH0 Z UW1 M IH0 NG', 'R IY0 Z UW1 M IH0 NG'],
  "resumption": ['R IH0 Z AH1 M P SH AH0 N', 'R IY0 Z AH1 M P SH AH0 N', 'R IH0 Z AH1 M SH AH0 N', 'R IY0 Z AH1 M SH AH0 N'],
  "resupply": ['R IY0 S AH0 P L AY1'],
  "resurface": ['R IY0 S ER1 F AH0 S'],
  "resurfaced": ['R IY0 S ER1 F AH0 S T'],
  "resurfacing": ['R IY0 S ER1 F AH0 S IH0 NG'],
  "resurgence": ['R IY0 S ER1 JH AH0 N S'],
  "resurgency": ['R IH0 S ER1 JH AH0 N S IY0', 'R IY0 S ER1 JH AH0 N S IY0'],
  "resurgent": ['R IH0 S ER1 JH AH0 N T', 'R IY0 S ER1 JH AH0 N T'],
  "resurging": ['R IY0 S ER1 JH IH0 NG'],
  "resurrect": ['R EH2 Z ER0 EH1 K T'],
  "resurrected": ['R EH2 Z ER0 EH1 K T IH0 D'],
  "resurrecting": ['R EH2 Z ER0 EH1 K T IH0 NG'],
  "resurrection": ['R EH2 Z ER0 EH1 K SH AH0 N'],
  "resuscitate": ['R IH0 S AH1 S IH0 T EY2 T', 'R IY0 S AH1 S IH0 T EY2 T'],
  "resuscitated": ['R IH0 S AH1 S IH0 T EY2 T IH0 D'],
  "resuscitating": ['R IH0 S AH1 S IH0 T EY2 T IH0 NG'],
  "resuscitation": ['R IH0 S AH2 S IH0 T EY1 SH AH0 N'],
  "resveratrol": ['R EH0 S V ER1 AH0 T R AO0 L'],
  "ret": ['R EH1 T'],
  "reta": ['R EH1 T AH0'],
  "retablos": ['R IY0 T AE1 B L OW0 S'],
  "retail": ['R IY1 T EY2 L'],
  "retailed": ['R IY1 T EY2 L D'],
  "retailer": ['R IY1 T EY2 L ER0'],
  "retailer's": ['R IY1 T EY2 L ER0 Z'],
  "retailers": ['R IY1 T EY2 L ER0 Z'],
  "retailers'": ['R IY1 T EY2 L ER0 Z'],
  "retailing": ['R IY1 T EY2 L IH0 NG'],
  "retailing's": ['R IY1 T EY2 L IH0 NG Z'],
  "retails": ['R IY1 T EY2 L Z'],
  "retain": ['R IH0 T EY1 N', 'R IY0 T EY1 N'],
  "retained": ['R IH0 T EY1 N D', 'R IY0 T EY1 N D'],
  "retainer": ['R IH0 T EY1 N ER0', 'R IY0 T EY1 N ER0'],
  "retainers": ['R IH0 T EY1 N ER0 Z'],
  "retaining": ['R IH0 T EY1 N IH0 NG', 'R IY0 T EY1 N IH0 NG'],
  "retains": ['R IH0 T EY1 N Z', 'R IY0 T EY1 N Z'],
  "retake": ['R IY1 T EY1 K', 'R IY0 T EY1 K'],
  "retaken": ['R IY0 T EY1 K AH0 N'],
  "retaking": ['R IY0 T EY1 K IH0 NG'],
  "retaliate": ['R IH0 T AE1 L IY0 EY2 T', 'R IY0 T AE1 L IY0 EY2 T'],
  "retaliated": ['R IH0 T AE1 L IY0 EY2 T IH0 D', 'R IY0 T AE1 L IY0 EY2 T IH0 D'],
  "retaliating": ['R IH0 T AE1 L IY0 EY2 T IH0 NG'],
  "retaliation": ['R IY0 T AE2 L IY0 EY1 SH AH0 N'],
  "retaliatory": ['R IY0 T AE1 L Y AH0 T AO2 R IY0'],
  "retana": ['R EH0 T AE1 N AH0'],
  "retard": ['R IH0 T AA1 R D', 'R IY0 T AA1 R D'],
  "retardant": ['R IY0 T AA1 R D AH0 N T'],
  "retardation": ['R IY0 T AA0 R D EY1 SH AH0 N'],
  "retarded": ['R IH0 T AA1 R D IH0 D', 'R IY0 T AA1 R D AH0 D', 'R IY0 T AA1 R D IH0 D'],
  "retarding": ['R IH0 T AA1 R D IH0 NG', 'R IY0 T AA1 R D IH0 NG'],
  "retards": ['R IH0 T AA1 R D Z', 'R IY0 T AA1 R D Z'],
  "retarget": ['R IY0 T AA1 R G AH0 T'],
  "retargeter": ['R IY0 T AA1 R G AH0 T ER0'],
  "retargeters": ['R IY0 T AA1 R G AH0 T ER0 Z'],
  "retargeting": ['R IY0 T AA1 R G AH0 T IH0 NG'],
  "retell": ['R IY0 T EH1 L'],
  "retelling": ['R IY0 T EH1 L IH0 NG'],
  "retemeyer": ['R EH1 T AH0 M AY2 R'],
  "retention": ['R IY0 T EH1 N SH AH0 N'],
  "retest": ['R IY1 T EH1 S T'],
  "retested": ['R IY0 T EH1 S T IH0 D'],
  "retesting": ['R IY0 T EH1 S T IH0 NG'],
  "retests": ['R IY1 T EH1 S T S'],
  "retherford": ['R IH0 TH ER1 F ER0 D'],
  "rethink": ['R IY0 TH IH1 NG K'],
  "rethinking": ['R IY0 TH IH1 NG K IH0 NG'],
  "rethought": ['R IY0 TH AO1 T'],
  "reti": ['R EH1 T IY0'],
  "reticence": ['R EH1 T IH0 S AH0 N S'],
  "reticent": ['R EH1 T IH0 S AH0 N T'],
  "retin": ['R EH1 T IH0 N'],
  "retina": ['R EH1 T AH0 N AH0'],
  "retinal": ['R EH1 T AH0 N AH0 L'],
  "retinoblastoma": ['R EH2 T IH0 N OW2 B L AE2 S T OW1 M AH0'],
  "retinoid": ['R EH1 T IH0 N OY0 D'],
  "retinoids": ['R EH1 T IH0 N OY0 D Z'],
  "retinol": ['R EH1 T IH0 N AO2 L'],
  "retinue": ['R EH1 T AH0 N UW2'],
  "retinyl": ['R EH1 T IH0 N AH0 L'],
  "retire": ['R IH0 T AY1 R', 'R IY0 T AY1 R', 'R IY2 T AY1 ER0'],
  "retired": ['R IH0 T AY1 R D', 'R IY0 T AY1 ER0 D', 'R IY0 T AY1 R D'],
  "retiree": ['R IY0 T AY1 R IY1'],
  "retiree's": ['R IH0 T AY2 R IY1 Z'],
  "retirees": ['R IY0 T AY1 R IY1 Z'],
  "retirees'": ['R IH0 T AY2 R IY1 Z'],
  "retirement": ['R IY0 T AY1 ER0 M AH0 N T', 'R IH0 T AY1 ER0 M AH0 N T'],
  "retirement's": ['R IH0 T AY1 R M AH0 N T S', 'R IY0 T AY1 R M AH0 N T S'],
  "retirements": ['R IH0 T AY1 R M AH0 N T S', 'R IY0 T AY1 R M AH0 N T S'],
  "retires": ['R IH0 T AY1 R Z', 'R IY0 T AY1 ER0 Z', 'R IY0 T AY1 R Z'],
  "retiring": ['R IH0 T AY1 R IH0 NG', 'R IY0 T AY1 ER0 IH0 NG', 'R IY0 T AY1 R IH0 NG'],
  "retlin": ['R EH1 T L IH0 N'],
  "reto": ['R IY1 T UW1'],
  "retook": ['R IY0 T UH1 K'],
  "retool": ['R IY0 T UW1 L'],
  "retooled": ['R IY0 T UW1 L D'],
  "retooling": ['R IY0 T UW1 L IH0 NG'],
  "retort": ['R IY1 T AO2 R T'],
  "retorted": ['R IY0 T AO1 R T IH0 D'],
  "retorts": ['R IH0 T AO1 R T S', 'R IY0 T AO1 R T S'],
  "retouching": ['R IY0 T AH1 CH IH0 NG'],
  "retrace": ['R IY0 T R EY1 S'],
  "retraced": ['R IY0 T R EY1 S T'],
  "retracement": ['R IY0 T R EY1 S M AH0 N T'],
  "retracing": ['R IY0 T R EY1 S IH0 NG'],
  "retract": ['R IY0 T R AE1 K T'],
  "retractable": ['R IY0 T R AE1 K T AH0 B AH0 L'],
  "retracted": ['R IY0 T R AE1 K T AH0 D'],
  "retracting": ['R IY0 T R AE1 K T IH0 NG'],
  "retraction": ['R IY0 T R AE1 K SH AH0 N'],
  "retracts": ['R IY0 T R AE1 K T S'],
  "retrain": ['R IY0 T R EY1 N'],
  "retrained": ['R IY0 T R EY1 N D'],
  "retraining": ['R IY0 T R EY1 N IH0 NG'],
  "retransmission": ['R IY2 T R AE0 N Z M IH1 SH AH0 N'],
  "retread": ['R IY0 T R EH1 D'],
  "retreading": ['R IY0 T R EH1 D IH0 NG'],
  "retreads": ['R IY0 T R EH1 D Z'],
  "retreat": ['R IY0 T R IY1 T'],
  "retreated": ['R IY0 T R IY1 T AH0 D', 'R IY0 T R IY1 T IH0 D'],
  "retreating": ['R IY0 T R IY1 T IH0 NG'],
  "retreats": ['R IY0 T R IY1 T S'],
  "retrench": ['R IY0 T R EH1 N CH'],
  "retrenched": ['R IY0 T R EH1 N CH T'],
  "retrenching": ['R IY0 T R EH1 N CH IH0 NG'],
  "retrenchment": ['R IY0 T R EH1 N CH M AH0 N T'],
  "retrenchments": ['R IY0 T R EH1 N CH M AH0 N T S'],
  "retrial": ['R IY0 T R AY1 AH0 L'],
  "retribution": ['R EH2 T R AH0 B Y UW1 SH AH0 N'],
  "retried": ['R IY0 T R AY1 D'],
  "retrieval": ['R IH0 T R IY1 V AH0 L', 'R IY0 T R IY1 V AH0 L'],
  "retrieve": ['R IH0 T R IY1 V', 'R IY0 T R IY1 V'],
  "retrieved": ['R IY0 T R IY1 V D'],
  "retriever": ['R IY0 T R IY1 V ER0'],
  "retrievers": ['R IY0 T R IY1 V ER0 Z'],
  "retrieves": ['R IH0 T R IY1 V Z', 'R IY0 T R IY1 V Z'],
  "retrieving": ['R IY0 T R IY1 V IH0 NG'],
  "retro": ['R EH1 T R OW0'],
  "retroactive": ['R EH2 T R OW0 AE1 K T IH0 V'],
  "retroactively": ['R EH2 T R OW0 AE1 K T IH0 V L IY0'],
  "retroactivity": ['R EH2 T R OW0 AE0 K T IH1 V IH0 T IY0'],
  "retrocession": ['R EH2 T R OW0 S EH1 SH AH0 N'],
  "retrocessionaries": ['R EH2 T R OW0 S EH1 SH AH0 N EH0 R IY0 Z'],
  "retrocessionary": ['R EH2 T R OW0 S EH1 SH AH0 N EH0 R IY0'],
  "retrofit": ['R EH1 T R OW0 F IH2 T'],
  "retrofits": ['R EH1 T R OW0 F IH2 T S'],
  "retrofitted": ['R EH1 T R OW2 F IH2 T IH0 D'],
  "retrofitter": ['R EH1 T R OW2 F IH2 T ER0'],
  "retrofitting": ['R EH1 T R OW0 F IH2 T IH0 NG'],
  "retrograde": ['R EH1 T R AH0 G R EY2 D'],
  "retrospect": ['R EH1 T R AH0 S P EH2 K T'],
  "retrospective": ['R EH2 T R AH0 S P EH1 K T IH0 V'],
  "retrospectively": ['R EH2 T R OW0 S P EH1 K T IH0 V L IY0'],
  "retrovir": ['R EH1 T R OW0 V IH2 R'],
  "retrovirus": ['R EH2 T R OW0 V AY1 R AH0 S'],
  "retroviruses": ['R EH2 T R OW0 V AY1 R AH0 S IH0 Z'],
  "retry": ['R IY0 T R AY1'],
  "retrying": ['R IY0 T R AY1 IH0 NG'],
  "rettberg": ['R EH1 T B ER0 G'],
  "retter": ['R EH1 T ER0'],
  "rettew": ['R EH1 CH UW0'],
  "rettig": ['R EH1 T IH0 G'],
  "rettinger": ['R EH1 T IH0 N JH ER0', 'R EH1 T IH0 NG ER0'],
  "rettke": ['R EH1 T K IY0'],
  "retton": ['R EH1 T AH0 N'],
  "return": ['R IH0 T ER1 N', 'R IY0 T ER1 N'],
  "returnable": ['R IY0 T ER1 N AH0 B AH0 L'],
  "returned": ['R IH0 T ER1 N D', 'R IY0 T ER1 N D'],
  "returnee": ['R IH0 T ER0 N IY1'],
  "returnees": ['R IH0 T ER0 N IY1 Z'],
  "returning": ['R IH0 T ER1 N IH0 NG', 'R IY0 T ER1 N IH0 NG'],
  "returns": ['R IH0 T ER1 N Z', 'R IY0 T ER1 N Z'],
  "returns'": ['R AH0 T ER1 N Z', 'R IY0 T ER1 N Z'],
  "retz": ['R EH1 T S'],
  "retzer": ['R EH1 T Z ER0'],
  "retzlaff": ['R EH1 T Z L AH0 F'],
  "retzloff": ['R EH1 T Z L AO0 F'],
  "reuben": ['R UW1 B AH0 N'],
  "reuber": ['R OY1 B ER0'],
  "reul": ['R UW1 L'],
  "reuland": ['R OY1 L AH0 N D'],
  "reule": ['R UW1 L'],
  "reum": ['R IY1 AH0 M'],
  "reunification": ['R IY0 UW2 N AH0 F AH0 K EY1 SH AH0 N'],
  "reunified": ['R IY0 UW1 N AH0 F AY2 D'],
  "reunify": ['R IY0 UW1 N AH0 F AY2'],
  "reunion": ['R IY0 UW1 N Y AH0 N'],
  "reunions": ['R IY0 UW1 N Y AH0 N Z'],
  "reunite": ['R IY2 UW0 N AY1 T'],
  "reunited": ['R IY2 UW0 N AY1 T IH0 D'],
  "reunites": ['R IY2 UW0 N AY1 T S'],
  "reuniting": ['R IY2 UW0 N AY1 T IH0 NG'],
  "reusable": ['R IY0 UW1 Z AH0 B AH0 L'],
  "reusch": ['R OY1 SH'],
  "reuse": ['R IY0 Y UW1 S', 'R IY0 Y UW1 Z'],
  "reused": ['R IY0 UW1 Z D'],
  "reusing": ['R IY0 Y UW1 Z IH0 NG'],
  "reuss": ['R UW1 S'],
  "reusser": ['R OY1 S ER0'],
  "reust": ['R UW1 S T'],
  "reuter": ['R OY1 T ER0'],
  "reuter's": ['R OY1 T ER0 Z'],
  "reuters": ['R OY1 T ER0 Z'],
  "reuters'": ['R OY1 T ER0 Z'],
  "reuters's": ['R OY1 T ER0 Z IH0 Z', 'R OY1 T ER0 Z'],
  "reutgers": ['R OY1 T G ER0 Z'],
  "reuther": ['R OY1 DH ER0'],
  "reutter": ['R OY1 T ER0'],
  "reutzel": ['R OY1 T Z AH0 L'],
  "rev": ['R EH1 V'],
  "reva": ['R EY1 V AH0'],
  "revak": ['R EH1 V AH0 K'],
  "revaluation": ['R IY0 V AE1 L Y UW0 EY1 SH AH0 N'],
  "revaluations": ['R IY0 IH0 V AE2 L Y UW0 EY1 SH AH0 N Z'],
  "revalue": ['R IY0 V AE1 L Y UW2'],
  "revalued": ['R IY0 V AE1 L Y UW0 D'],
  "revaluing": ['R IY0 V AE1 L Y UW0 IH0 NG'],
  "revamp": ['R IY0 V AE1 M P'],
  "revamped": ['R IY0 V AE1 M P T'],
  "revamping": ['R IY0 V AE1 M P IH0 NG'],
  "revamps": ['R IY0 V AE1 M P S'],
  "revard": ['R IH0 V AA1 R D'],
  "revco": ['R EH1 V K OW0'],
  "revco's": ['R EH1 V K OW0 Z'],
  "reveal": ['R IH0 V IY1 L', 'R IY0 V IY1 L'],
  "revealed": ['R IH0 V IY1 L D', 'R IY0 V IY1 L D'],
  "revealing": ['R IH0 V IY1 L IH0 NG', 'R IY0 V IY1 L IH0 NG'],
  "reveals": ['R IH0 V IY1 L Z', 'R IY0 V IY1 L Z'],
  "reveille": ['R EH1 V AH0 L IY2'],
  "revel": ['R EH1 V AH0 L'],
  "revelation": ['R EH2 V AH0 L EY1 SH AH0 N'],
  "revelations": ['R EH2 V AH0 L EY1 SH AH0 N Z'],
  "revelatory": ['R IH0 V EH1 L AH0 T AO2 R IY0'],
  "reveled": ['R EH1 V AH0 L D'],
  "reveler": ['R EH1 V AH0 L ER0'],
  "revelers": ['R EH1 V AH0 L ER0 Z'],
  "reveles": ['R EY0 V EY1 L EH0 S'],
  "reveling": ['R EH1 V AH0 L IH0 NG'],
  "revell": ['R EY0 V EY1 L'],
  "revelle": ['R IH0 V EH1 L'],
  "reveller": ['R EH1 V AH0 L ER0'],
  "revellers": ['R EH1 V AH0 L ER0 Z'],
  "revello": ['R EH0 V EH1 L OW0'],
  "revelry": ['R EH1 V AH0 L R IY0'],
  "revels": ['R EH1 V AH0 L Z'],
  "revenge": ['R IY0 V EH1 N JH'],
  "revenue": ['R EH1 V AH0 N UW2', 'R EH1 V AH0 N Y UW2'],
  "revenuer": ['R EH1 V AH0 N UW2 ER0', 'R EH1 V AH0 N Y UW2 ER0'],
  "revenuers": ['R EH1 V AH0 N UW2 ER0 Z', 'R EH1 V AH0 N Y UW2 ER0 Z'],
  "revenues": ['R EH1 V AH0 N UW2 Z', 'R EH1 V AH0 N Y UW2 Z'],
  "reverb": ['R IY0 V ER1 B'],
  "reverbed": ['R IY0 V ER1 B D'],
  "reverberate": ['R IH0 V ER1 B ER0 AH0 T', 'R IH0 V ER1 B ER0 EY0 T'],
  "reverberated": ['R IH0 V ER1 B ER0 EY2 T IH0 D', 'R IY0 V ER1 B ER0 EY2 T AH0 D', 'R IY0 V ER1 B ER0 EY2 T IH0 D'],
  "reverberates": ['R IH0 V ER1 B ER0 EY2 T S'],
  "reverberating": ['R IH0 V ER1 B ER0 EY2 T IH0 NG', 'R IY0 V ER1 B ER0 EY2 T IH0 NG'],
  "reverberation": ['R IY0 V ER2 B ER0 EY1 SH AH0 N'],
  "reverberations": ['R IH0 V ER2 B ER0 EY1 SH AH0 N Z'],
  "revercomb": ['R EH1 V ER0 K AH0 M'],
  "revere": ['R IH0 V IH1 R'],
  "revered": ['R IH0 V IH1 R D'],
  "reverence": ['R EH1 V ER0 AH0 N S'],
  "reverend": ['R EH1 V ER0 AH0 N D', 'R EH1 V R AH0 N D'],
  "reverent": ['R EH1 V ER0 AH0 N T'],
  "reverential": ['R EH2 V ER0 EH1 N SH AH0 L'],
  "reverently": ['R EH1 V ER0 AH0 N T L IY0'],
  "reveres": ['R IH0 V IH1 R Z'],
  "reverie": ['R EH1 V ER0 IY0'],
  "reveries": ['R EH1 V ER0 IY0 Z'],
  "revering": ['R IH0 V IH1 R IH0 NG'],
  "reveron": ['R EH1 V ER0 AA2 N'],
  "reveron's": ['R EH1 V ER0 AA2 N Z'],
  "reversal": ['R IH0 V ER1 S AH0 L', 'R IY0 V ER1 S AH0 L'],
  "reversals": ['R IH0 V ER1 S AH0 L Z', 'R IY0 V ER1 S AH0 L Z'],
  "reverse": ['R IH0 V ER1 S', 'R IY0 V ER1 S'],
  "reversed": ['R IH0 V ER1 S T', 'R IY0 V ER1 S T'],
  "reverser": ['R IH0 V ER1 S ER0'],
  "reversers": ['R IH0 V ER1 S ER0 Z'],
  "reverses": ['R IH0 V ER1 S IH0 Z', 'R IY0 V ER1 S AH0 Z', 'R IY0 V ER1 S IH0 Z'],
  "reversible": ['R IH0 V ER1 S AH0 B AH0 L', 'R IY0 V ER1 S AH0 B AH0 L'],
  "reversing": ['R IH0 V ER1 S IH0 NG', 'R IY0 V ER1 S IH0 NG'],
  "reversion": ['R IH0 V ER1 ZH AH0 N'],
  "revert": ['R IH0 V ER1 T', 'R IY0 V ER1 T'],
  "reverted": ['R IH0 V ER1 T IH0 D', 'R IY0 V ER1 T IH0 D'],
  "reverting": ['R IH0 V ER1 T IH0 NG'],
  "reverts": ['R IH0 V ER1 T S'],
  "reves": ['R IY1 V Z'],
  "revette": ['R IH0 V EH1 T'],
  "revier": ['R IY1 V IY0 ER0'],
  "review": ['R IY2 V Y UW1'],
  "review's": ['R IY0 V Y UW1 Z'],
  "reviewed": ['R IY0 V Y UW1 D'],
  "reviewer": ['R IY0 V Y UW1 ER0'],
  "reviewer's": ['R IY0 V Y UW1 ER0 Z'],
  "reviewers": ['R IY0 V Y UW1 ER0 Z'],
  "reviewing": ['R IY0 V Y UW1 IH0 NG'],
  "reviews": ['R IY0 V Y UW1 Z', 'R AH0 V Y UW1 Z'],
  "reviglio": ['R AH0 V IH1 G L IY0 OW0'],
  "revile": ['R IY0 V AY1 L'],
  "reviled": ['R IY0 V AY1 L D'],
  "revilla": ['R EH0 V IH1 L AH0'],
  "reville": ['R EH1 V IH2 L'],
  "revis": ['R EH1 V IH0 S'],
  "revise": ['R IH0 V AY1 Z', 'R IY0 V AY1 Z'],
  "revised": ['R IH0 V AY1 Z D', 'R IY0 V AY1 Z D'],
  "revises": ['R IH0 V AY1 Z IH0 Z', 'R IY0 V AY1 Z IH0 Z'],
  "revising": ['R IH0 V AY1 Z IH0 NG', 'R IY0 V AY1 Z IH0 NG'],
  "revision": ['R IY0 V IH1 ZH AH0 N'],
  "revisionism": ['R IY0 V IH1 ZH AH0 N IH2 Z AH0 M'],
  "revisionist": ['R IY0 V IH1 ZH AH0 N IH0 S T'],
  "revisionists": ['R IY0 V IH1 ZH AH0 N IH0 S T S'],
  "revisions": ['R IY0 V IH1 ZH AH0 N Z'],
  "revisit": ['R IY0 V IH1 Z IH0 T'],
  "revisited": ['R IY0 V IH1 Z AH0 T IH0 D'],
  "revisiting": ['R IY0 V IH1 Z IH0 T IH0 NG'],
  "revisits": ['R IY0 V IH1 Z IH0 T S'],
  "revitalization": ['R IY2 V AY2 T AH0 L AH0 Z EY1 SH AH0 N'],
  "revitalize": ['R IY0 V AY1 T AH0 L AY2 Z'],
  "revitalized": ['R IY0 V AY1 T AH0 L AY2 Z D'],
  "revitalizing": ['R IY0 V AY1 T AH0 L AY2 Z IH0 NG'],
  "revival": ['R IH0 V AY1 V AH0 L', 'R IY0 V AY1 V AH0 L'],
  "revivalist": ['R IY0 V AY1 V AH0 L IH0 S T'],
  "revivals": ['R IY0 V AY1 V AH0 L Z'],
  "revive": ['R IH0 V AY1 V', 'R IY0 V AY1 V'],
  "revived": ['R IH0 V AY1 V D', 'R IY0 V AY1 V D'],
  "revives": ['R IY0 V AY1 V Z'],
  "reviving": ['R IH0 V AY1 V IH0 NG', 'R IY0 V AY1 V IH0 NG'],
  "revlon": ['R EH1 V L AA0 N'],
  "revlon's": ['R EH1 V L AA0 N Z'],
  "revocable": ['R EH1 V AH0 K AH0 B AH0 L'],
  "revocation": ['R EH2 V AH0 K EY1 SH AH0 N'],
  "revocations": ['R EH2 V AH0 K EY1 SH AH0 N Z'],
  "revoir": ['R IH0 V W AA1 R'],
  "revoke": ['R IH0 V OW1 K', 'R IY0 V OW1 K'],
  "revoked": ['R IH0 V OW1 K T', 'R IY0 V OW1 K T'],
  "revoking": ['R IH0 V OW1 K IH0 NG', 'R IY0 V OW1 K IH0 NG'],
  "revolt": ['R IH0 V OW1 L T', 'R IY0 V OW1 L T'],
  "revolted": ['R IH0 V OW1 L T IH0 D', 'R IY0 V OW1 L T AH0 D', 'R IY0 V OW1 L T IH0 D'],
  "revolting": ['R IY0 V OW1 L T IH0 NG'],
  "revolts": ['R IY0 V OW1 L T S'],
  "revolucion": ['R EH2 V OW0 L UW2 S IY0 OW1 N'],
  "revolucionario": ['R EH2 V OW0 L UW2 S IY0 AH0 N EH1 R IY0 OW0'],
  "revolution": ['R EH2 V AH0 L UW1 SH AH0 N'],
  "revolution's": ['R EH2 V AH0 L UW1 SH AH0 N Z'],
  "revolutionaries": ['R EH2 V AH0 L UW1 SH AH0 N EH2 R IY0 Z'],
  "revolutionary": ['R EH2 V AH0 L UW1 SH AH0 N EH2 R IY0'],
  "revolutionist": ['R EH2 V AH0 L UW1 SH AH0 N IH0 S T'],
  "revolutionists": ['R EH2 V AH0 L UW1 SH AH0 N IH0 S T S'],
  "revolutionize": ['R EH2 V AH0 L UW1 SH AH0 N AY2 Z'],
  "revolutionized": ['R EH2 V AH0 L UW1 SH AH0 N AY2 Z D'],
  "revolutionizing": ['R EH2 V AH0 L UW1 SH AH0 N AY2 Z IH0 NG'],
  "revolutions": ['R EH2 V AH0 L UW1 SH AH0 N Z'],
  "revolve": ['R IY0 V AA1 L V'],
  "revolved": ['R IY0 V AA1 L V D'],
  "revolver": ['R IH0 V AA1 L V ER0'],
  "revolvers": ['R IH0 V AA1 L V ER0 Z'],
  "revolves": ['R IY0 V AA1 L V Z'],
  "revolving": ['R IY0 V AA1 L V IH0 NG'],
  "revord": ['R IH0 V AO1 R D'],
  "revs": ['R EH1 V Z'],
  "revson": ['R EH1 V S AH0 N'],
  "revue": ['R IH0 V Y UW1'],
  "revuelta": ['R AH0 V W EH1 L T AH0'],
  "revues": ['R IH0 V Y UW1 Z'],
  "revulsion": ['R IH0 V AH1 L SH AH0 N', 'R IY0 V AH1 L SH AH0 N'],
  "revved": ['R EH1 V D'],
  "revving": ['R EH1 V IH0 NG'],
  "revzin": ['R EH1 V Z IH0 N'],
  "rew": ['R UW1'],
  "reward": ['R IH0 W AO1 R D', 'R IY0 W AO1 R D'],
  "rewarded": ['R IH0 W AO1 R D IH0 D', 'R IY0 W AO1 R D AH0 D', 'R IY0 W AO1 R D IH0 D'],
  "rewarding": ['R IH0 W AO1 R D IH0 NG', 'R IY0 W AO1 R D IH0 NG'],
  "rewards": ['R IH0 W AO1 R D Z', 'R IY0 W AO1 R D Z'],
  "rewatching": ['R IY0 W AA1 CH IH0 NG'],
  "rewerts": ['R UW1 ER0 T S'],
  "rewey": ['R UW1 IY0'],
  "rewind": ['R IY1 W AY0 N D'],
  "rewire": ['R IY0 W AY1 ER0'],
  "rewired": ['R IY0 W AY1 ER0 D'],
  "rewiring": ['R IY0 W AY1 ER0 IH0 NG'],
  "rewis": ['R UW1 IH0 S'],
  "reword": ['R IY0 W ER1 D'],
  "rework": ['R IY0 W ER1 K'],
  "reworked": ['R IY0 W ER1 K T'],
  "reworking": ['R IY0 W ER1 K IH0 NG'],
  "rewrite": ['R IY0 R AY1 T', 'R IY1 R AY2 T'],
  "rewrites": ['R IY0 R AY1 T S'],
  "rewriting": ['R IY0 R AY1 T IH0 NG'],
  "rewritten": ['R IY0 R IH1 T AH0 N'],
  "rewrote": ['R IY0 R OW1 T'],
  "rex": ['R EH1 K S'],
  "rexall": ['R EH1 K S AO2 L'],
  "rexana": ['R IH0 G Z AE1 N AH0'],
  "rexene": ['R EH1 K S IY0 N'],
  "rexford": ['R EH1 K S F ER0 D'],
  "rexham": ['R EH1 K S AH0 M'],
  "rexnord": ['R EH1 K S N AO0 R D'],
  "rexon": ['R EH1 K S AH0 N'],
  "rexroad": ['R EH1 K S R OW2 D'],
  "rexroat": ['R EH1 K S R OW0 T'],
  "rexrode": ['R EH1 K S R OW0 D'],
  "rexrodt": ['R EH1 K S R OW0 D'],
  "rexroth": ['R EH1 K S R AA0 TH'],
  "rey": ['R EY1'],
  "reyburn": ['R EY1 B ER0 N'],
  "reycraft": ['R EY1 K R AE2 F T'],
  "reyer": ['R EY1 ER0'],
  "reyes": ['R EY1 IH0 S'],
  "reykjavik": ['R EH1 K Y AH0 V IH0 K', 'R EY1 K AH0 V IH0 K'],
  "reyman": ['R EY1 M AH0 N'],
  "reyna": ['R EY1 N AH0'],
  "reynaga": ['R IY0 N AA1 G AH0'],
  "reynaldo": ['R EY2 N AA1 L D OW0'],
  "reynard": ['R EY1 N ER0 D'],
  "reynaud": ['R EY1 N AO0 D'],
  "reynold": ['R EH1 N AH0 L D'],
  "reynolds": ['R EH1 N AH0 L D Z'],
  "reynolds'": ['R EH1 N AH0 L D Z'],
  "reynolds's": ['R EH1 N AH0 L D Z IH0 Z'],
  "reynoldson": ['R EY1 N OW0 L D S AH0 N'],
  "reynosa": ['R IY0 N OW1 S AH0'],
  "reynoso": ['R IY0 N OW1 S OW0'],
  "reza": ['R EH1 Z AH0'],
  "rezabek": ['R IH0 Z AA1 B EH0 K'],
  "rezac": ['R EH1 Z AH0 K'],
  "rezek": ['R EH1 Z EH0 K'],
  "rezendes": ['R EY0 Z EY1 N D EH0 S'],
  "reznicek": ['R EH1 Z N IH0 CH EH2 K'],
  "reznick": ['R EH1 Z N IH0 K'],
  "reznik": ['R EH1 Z N IH0 K'],
  "rezoning": ['R IY0 Z OW1 N IH0 NG'],
  "rha": ['R AA1'],
  "rhame": ['R EY1 M'],
  "rhames": ['R EY1 M Z'],
  "rhapsodic": ['R AE0 P S AA1 D IH0 K'],
  "rhapsodize": ['R AE1 P S AH0 D AY2 Z'],
  "rhapsodizes": ['R AE1 P S AH0 D AY2 Z IH0 Z'],
  "rhapsody": ['R AE1 P S AH0 D IY0'],
  "rhea": ['R IY1 AH0'],
  "rheault": ['R OW1'],
  "rheaume": ['R UW1 M'],
  "rhee": ['R IY1'],
  "rheem": ['R IY1 M'],
  "rhein": ['R AY1 N'],
  "rheinberger": ['R AY1 N B ER2 G ER0'],
  "rheingold": ['R AY1 N G OW2 L D'],
  "rheinisch": ['R AY1 N IH0 SH'],
  "rheinstein": ['R AY1 N S T AY2 N'],
  "rhem": ['R EH1 M'],
  "rheology": ['R IY0 AO1 L AH0 JH IY0'],
  "rhesus": ['R IY1 S AH0 S'],
  "rheta": ['R IY1 T AH0'],
  "rhetoric": ['R EH1 T ER0 IH0 K'],
  "rhetorical": ['R IH0 T AO1 R IH0 K AH0 L'],
  "rhetorically": ['R AH0 T AO1 R IH0 K L IY0'],
  "rhetorician": ['R EH2 T ER0 IH1 SH AH0 N'],
  "rhetoricians": ['R EH2 T ER0 IH1 SH AH0 N Z'],
  "rhett": ['R EH1 T'],
  "rheumatic": ['R UW0 M AE1 T IH0 K'],
  "rheumatism": ['R UW1 M AH0 T IH2 Z AH0 M'],
  "rheumatoid": ['R UW1 M AH0 T OY2 D'],
  "rheumatologist": ['R UW2 M AH0 T AA1 L AH0 JH AH0 S T'],
  "rheumatology": ['R UW2 M AH0 T AA1 L AH0 JH IY0'],
  "rhew": ['R UW1'],
  "rhin": ['R IH1 N'],
  "rhine": ['R AY1 N'],
  "rhinehardt": ['R AY1 N HH AA2 R T'],
  "rhinehart": ['R AY1 N HH AA2 R T'],
  "rhineland": ['R AY1 N L AE2 N D'],
  "rhineman": ['R AY1 N M AH0 N'],
  "rhineman's": ['R AY1 N M AH0 N Z'],
  "rhines": ['R AY1 N Z'],
  "rhinesmith": ['R AY1 N S M IH2 TH'],
  "rhinestone": ['R AY1 N S T OW2 N'],
  "rhinestones": ['R AY1 N S T OW2 N Z'],
  "rhino": ['R AY1 N OW2'],
  "rhinoceros": ['R AY0 N AA1 S ER0 AH0 S'],
  "rhinoplasties": ['R AY2 N OW0 P L AE1 S T IY2 Z'],
  "rhinoplasty": ['R AY2 N OW0 P L AE1 S T IY2', 'R IH2 N OW0 P L AE1 S T IY2'],
  "rhinos": ['R AY1 N OW2 Z'],
  "rhizoid": ['R AY1 Z OY2 D'],
  "rhizoidal": ['R AY2 Z OY1 D AH0 L'],
  "rhizome": ['R AY1 Z OW2 M'],
  "rhizomes": ['R AY1 Z OW2 M Z'],
  "rho": ['R OW1'],
  "rhoad": ['R OW1 D'],
  "rhoades": ['R OW1 D Z'],
  "rhoads": ['R OW1 D Z'],
  "rhoda": ['R OW1 D AH0'],
  "rhodanthe": ['R OW2 D AE1 N TH'],
  "rhode": ['R OW1 D'],
  "rhode-island": ['R OW1 D AY1 L AH0 N D'],
  "rhode-island's": ['R OW1 D AY1 L AH0 N D Z'],
  "rhode-islander": ['R OW1 D AY1 L AH0 N D ER0'],
  "rhode-islanders": ['R OW1 D AY1 L AH0 N D ER0 Z'],
  "rhoden": ['R OW1 D AH0 N'],
  "rhodes": ['R OW1 D Z'],
  "rhodesia": ['R OW0 D IY1 ZH AH0'],
  "rhodesian": ['R OW0 D IY1 ZH AH0 N'],
  "rhodia": ['R OW1 D IY0 AH0'],
  "rhodies": ['R OW1 D IY0 Z'],
  "rhodium": ['R OW1 D IY0 AH0 M'],
  "rhododendron": ['R OW2 D AH0 D EH1 N D R AH0 N'],
  "rhodopsin": ['R OW0 D AA1 P S AH0 N'],
  "rhodus": ['R OW1 D AH0 S'],
  "rhody": ['R OW1 D IY0'],
  "rhome": ['R OW1 M'],
  "rhonda": ['R AA1 N D AH0'],
  "rhone": ['R OW1 N'],
  "rhoney": ['R OW1 N IY0'],
  "rhorer": ['R AO1 R ER0'],
  "rhoten": ['R OW1 T AH0 N'],
  "rhoto": ['R OW1 T OW0'],
  "rhoton": ['R OW1 T AH0 N'],
  "rhubarb": ['R UW1 B AA2 R B'],
  "rhude": ['R UW1 D'],
  "rhudy": ['R UW1 D IY0'],
  "rhue": ['R UW1'],
  "rhule": ['R UW1 L'],
  "rhyme": ['R AY1 M'],
  "rhymed": ['R AY1 M D'],
  "rhymer": ['R AY1 M ER0'],
  "rhymes": ['R AY1 M Z'],
  "rhyming": ['R AY1 M IH0 NG'],
  "rhyne": ['R AY1 N'],
  "rhyner": ['R AY1 N ER0'],
  "rhynes": ['R AY1 N Z'],
  "rhys": ['R IY1 S'],
  "rhys-jones": ['R IY1 S JH OW1 N Z'],
  "rhythm": ['R IH1 DH AH0 M'],
  "rhythmic": ['R IH1 DH M IH0 K'],
  "rhythmically": ['R IH1 DH M IH0 K L IY0'],
  "rhythms": ['R IH1 DH AH0 M Z'],
  "ria": ['R IY1 AH0'],
  "riad": ['R IY0 AA1 D'],
  "riady": ['R IY0 AA1 D IY0'],
  "riadys": ['R IY0 AA1 D IY0 Z'],
  "rial": ['R AY1 AH0 L'],
  "rials": ['R AY1 L Z'],
  "rib": ['R IH1 B'],
  "riba": ['R IY1 B AH0'],
  "ribald": ['R AY1 B AA0 L D'],
  "ribaldry": ['R AY1 B AA0 L D R IY0'],
  "ribar": ['R IH0 B AA1 R'],
  "ribas": ['R AY1 B AH0 Z'],
  "ribaudo": ['R IY0 B AO1 D OW0'],
  "ribavirin": ['R IH0 B AE1 V IH0 R IH0 N'],
  "ribbed": ['R IH1 B D'],
  "ribbing": ['R IH1 B IH0 NG'],
  "ribbit": ['R IH1 B IH0 T'],
  "ribble": ['R IH1 B AH0 L'],
  "ribbon": ['R IH1 B AH0 N'],
  "ribbons": ['R IH1 B AH0 N Z'],
  "ribeiro": ['R IY0 B EH1 R OW0'],
  "ribera": ['R IY0 B EH1 R AH0'],
  "ribi": ['R IY1 B IY0'],
  "ribicoff": ['R IH1 B IH0 K AO2 F'],
  "ribka": ['R IH1 B K AH0'],
  "ribonucleic": ['R AY2 B OW0 N UW0 K L EY1 IH0 K'],
  "ribs": ['R IH1 B Z'],
  "ric": ['R IH1 K'],
  "rica": ['R IY1 K AH0'],
  "rica's": ['R IY1 K AH0 Z'],
  "ricadonna": ['R IY0 K AA0 D OW1 N AH0'],
  "rican": ['R IY1 K AH0 N'],
  "ricans": ['R IH1 K AH0 N Z'],
  "ricard": ['R IH0 K AA1 R D'],
  "ricardo": ['R IH0 K AA1 R D OW0'],
  "ricaurte": ['R AH0 K AA1 R T EY2'],
  "ricca": ['R IY1 K AH0'],
  "riccardi": ['R IY0 K AA1 R D IY0'],
  "riccardo": ['R IH0 K AA1 R D OW0'],
  "ricci": ['R IY1 CH IY0'],
  "ricciardelli": ['R IY0 CH ER0 D EH1 L IY0'],
  "ricciardi": ['R IY0 CH AA1 R D IY0'],
  "riccio": ['R IY1 CH IY0 OW0'],
  "riccitelli": ['R IY0 CH IY0 T EH1 L IY0'],
  "ricciuti": ['R IY0 K CH UW1 T IY0'],
  "ricco": ['R IH1 K OW0'],
  "riccobono": ['R IY0 K OW0 B OW1 N OW0'],
  "rice": ['R AY1 S'],
  "rice's": ['R AY1 S IH0 Z'],
  "riceville": ['R AY1 S V IH0 L'],
  "rich": ['R IH1 CH'],
  "rich's": ['R IH1 CH IH0 Z'],
  "richa": ['R IH1 CH AH0'],
  "richard": ['R IH1 CH ER0 D'],
  "richard's": ['R IH1 CH ER0 D Z'],
  "richards": ['R IH1 CH ER0 D Z'],
  "richards'": ['R IH1 CH ER0 D Z'],
  "richards's": ['R IH1 CH ER0 D Z IH0 Z'],
  "richardson": ['R IH1 CH ER0 D S AH0 N'],
  "richardson's": ['R IH1 CH ER0 D S AH0 N Z'],
  "richardsonsmith": ['R IH1 CH ER0 D S AH0 N S M IH1 TH'],
  "richardt": ['R IH1 K AA0 R T'],
  "richarson": ['R IH1 CH AA2 R S AH0 N'],
  "richart": ['R IH1 K HH AA2 R T'],
  "richaud": ['R IH0 SH OW2 D'],
  "richbourg": ['R IH1 CH B AO2 R G'],
  "richburg": ['R IH1 CH B ER0 G'],
  "richco": ['R IH1 K OW0'],
  "richcreek": ['R IH1 CH K R IY2 K'],
  "riche": ['R IH1 CH'],
  "richelieu": ['R IH1 SH AH0 L UW2'],
  "richelson": ['R IH1 CH AH0 L S AH0 N'],
  "richemont": ['R IH1 CH AH0 M AA2 N T'],
  "richenthal": ['R IH1 K AH0 N TH AO2 L'],
  "richer": ['R IH1 CH ER0'],
  "richerd": ['R IH1 CH ER0 D'],
  "richerson": ['R IH1 CH ER0 S AH0 N'],
  "richert": ['R IH1 K ER0 T'],
  "riches": ['R IH1 CH AH0 Z', 'R IH1 CH IH0 Z'],
  "richesin": ['R IH1 K IH0 S IH0 N'],
  "richeson": ['R IH1 K IH0 S AH0 N'],
  "richest": ['R IH1 CH AH0 S T'],
  "richey": ['R IH1 CH IY0'],
  "richfield": ['R IH1 CH F IY2 L D'],
  "richfield's": ['R IH1 CH F IY2 L D Z'],
  "richford": ['R IH1 CH F ER0 D'],
  "richie": ['R IH1 CH IY0'],
  "richins": ['R IH1 K IH0 N Z'],
  "richison": ['R IH1 CH IH0 S AH0 N'],
  "richland": ['R IH1 CH L AH0 N D', 'R IH1 CH L AE0 N'],
  "richly": ['R IH1 CH L IY0'],
  "richman": ['R IH1 CH M AE2 N'],
  "richmond": ['R IH1 CH M AH0 N D'],
  "richmond's": ['R IH1 CH M AH0 N D Z'],
  "richner": ['R IH1 K N ER0'],
  "richness": ['R IH1 CH N AH0 S'],
  "richoux": ['R IH0 SH UW1'],
  "richstone": ['R IH1 CH S T OW2 N'],
  "richter": ['R IH1 K T ER0'],
  "richton": ['R IH1 CH T AH0 N'],
  "richts": ['R IH1 K T S'],
  "richwhite": ['R IH1 CH W AY2 T'],
  "richwine": ['R IH1 CH W AY2 N'],
  "ricigliano": ['R IH0 S IH2 G L IY0 AA1 N OW0'],
  "rick": ['R IH1 K'],
  "rick's": ['R IH1 K S'],
  "rickabaugh": ['R IH1 K AH0 B AO2'],
  "rickard": ['R IH1 K ER0 D'],
  "rickards": ['R IH1 K ER0 D Z'],
  "ricke": ['R IH1 K'],
  "rickel": ['R IH1 K AH0 L'],
  "rickels": ['R IH1 K AH0 L Z'],
  "ricken": ['R IH1 K AH0 N'],
  "rickenbach": ['R IH1 K IH0 N B AA0 K'],
  "rickenbaker": ['R IH1 K IH0 N B AH0 K ER0'],
  "ricker": ['R IH1 K ER0'],
  "rickers": ['R IH1 K ER0 Z'],
  "rickerson": ['R IH1 K ER0 S AH0 N'],
  "rickert": ['R IH1 K ER0 T'],
  "rickets": ['R IH1 K IH0 T S'],
  "ricketson": ['R IH1 K IH0 T S AH0 N'],
  "rickett": ['R IH1 K IH0 T'],
  "ricketts": ['R IH1 K IH0 T S'],
  "rickety": ['R IH1 K AH0 T IY0'],
  "rickey": ['R IH1 K IY0'],
  "ricki": ['R IH1 K IY0'],
  "rickie": ['R IH1 K IY0'],
  "ricklefs": ['R IH1 K L IH0 F S'],
  "rickles": ['R IH1 K AH0 L Z'],
  "rickles'": ['R IH1 K AH0 L Z', 'R IH1 K AH0 L Z IH0 Z'],
  "rickman": ['R IH1 K M AH0 N'],
  "rickner": ['R IH1 K N ER0'],
  "ricks": ['R IH1 K S'],
  "rickson": ['R IH1 K S AH0 N'],
  "rickward": ['R IH1 K W ER0 D'],
  "rickwood": ['R IH1 K W UH2 D'],
  "ricky": ['R IH1 K IY2'],
  "ricky's": ['R IH1 K IY2 Z'],
  "rico": ['R IY1 K OW0'],
  "rico's": ['R IY1 K OW0 Z'],
  "ricochet": ['R IH1 K AH0 SH EY2'],
  "ricocheted": ['R IH1 K AH0 SH EY2 D'],
  "ricocheting": ['R IH1 K AH0 SH EY2 IH0 NG'],
  "ricoh": ['R IY1 K OW0'],
  "ricoh's": ['R IY1 K OW0 Z'],
  "ricostruzione": ['R IY0 K OW0 S T R UW0 Z IY0 OW1 N IY0'],
  "ricotta": ['R IY0 K AA1 T AH0'],
  "rid": ['R IH1 D'],
  "ridable": ['R AY1 D AH0 B AH0 L'],
  "riddance": ['R IH1 D AH0 N S'],
  "riddell": ['R IH1 D AH0 L'],
  "ridden": ['R IH1 D AH0 N'],
  "ridder": ['R IH1 D ER0'],
  "ridder's": ['R IH1 D ER0 Z'],
  "riddick": ['R IH1 D IH0 K'],
  "ridding": ['R IH1 D IH0 NG'],
  "riddle": ['R IH1 D AH0 L'],
  "riddled": ['R IH1 D AH0 L D'],
  "riddler": ['R IH1 D L ER0'],
  "riddles": ['R IH1 D AH0 L Z'],
  "riddock": ['R IH1 D AH0 K'],
  "ride": ['R AY1 D'],
  "rideau": ['R IH0 D OW1'],
  "rideaux": ['R IH0 D OW1'],
  "ridell": ['R IH0 D EH1 L'],
  "riden": ['R AY1 D AH0 N'],
  "ridener": ['R IH1 D IY0 N ER0'],
  "ridenhour": ['R IH1 D IH0 N AW0 R'],
  "ridenour": ['R IH1 D IH0 N UH0 R'],
  "rideout": ['R AY1 D AW2 T'],
  "rider": ['R AY1 D ER0'],
  "rider's": ['R AY1 D ER0 Z'],
  "riders": ['R AY1 D ER0 Z'],
  "ridership": ['R AY1 D ER0 SH IH2 P'],
  "rides": ['R AY1 D Z'],
  "ridge": ['R IH1 JH'],
  "ridge's": ['R IH1 JH IH0 S'],
  "ridgecrest": ['R IH1 JH K R EH2 S T'],
  "ridged": ['R IH1 JH D'],
  "ridgefield": ['R IH1 JH F IY2 L D'],
  "ridgell": ['R IH1 JH AH0 L'],
  "ridgely": ['R IH1 JH L IY0'],
  "ridges": ['R IH1 JH AH0 Z', 'R IH1 JH IH0 Z'],
  "ridgeway": ['R IH1 JH W EY2'],
  "ridgewood": ['R IH1 JH W UH2 D'],
  "ridgley": ['R IH1 JH L IY0'],
  "ridgway": ['R IH1 JH W EY2'],
  "ridicule": ['R IH1 D AH0 K Y UW2 L'],
  "ridiculed": ['R IH1 D AH0 K Y UW2 L D'],
  "ridicules": ['R IH1 D AH0 K Y UW2 L Z'],
  "ridiculing": ['R IH1 D AH0 K Y UW2 L IH0 NG'],
  "ridiculous": ['R IH0 D IH1 K Y AH0 L AH0 S'],
  "ridiculously": ['R AH0 D IH1 K Y AH0 L AH0 S L IY0'],
  "riding": ['R AY1 D IH0 NG'],
  "ridinger": ['R AY1 D IH0 NG ER0'],
  "ridings": ['R AY1 D IH0 NG Z'],
  "ridley": ['R IH1 D L IY0'],
  "ridling": ['R IH1 D L IH0 NG'],
  "ridlon": ['R IH1 D L AH0 N'],
  "ridner": ['R IH1 D N ER0'],
  "ridolfi": ['R IY0 D OW1 L F IY0'],
  "ridout": ['R IH1 D AW2 T'],
  "ridpath": ['R IH1 D P AE2 TH'],
  "rids": ['R IH1 D Z'],
  "riebe": ['R IY1 B'],
  "riebel": ['R IY1 B AH0 L'],
  "rieben": ['R IY1 B AH0 N'],
  "rieber": ['R IY1 B ER0'],
  "riebman": ['R IY1 B M AH0 N'],
  "riechers": ['R IY1 K ER0 Z'],
  "rieck": ['R IY1 K'],
  "riecke": ['R IY1 K'],
  "ried": ['R IY1 D'],
  "riede": ['R IY1 D'],
  "riedel": ['R IY1 D AH0 L'],
  "rieder": ['R IY1 D ER0'],
  "riederer": ['R IY1 D ER0 ER0'],
  "rieders": ['R IY1 D ER0 Z'],
  "rieders'": ['R IY1 D ER0 Z'],
  "riedesel": ['R IY1 D IH0 S AH0 L'],
  "riedl": ['R IY1 D AH0 L'],
  "riedlinger": ['R IY1 D AH0 L IH0 NG ER0', 'R IY1 D L IH0 NG ER0'],
  "riedy": ['R IY1 D IY0'],
  "rief": ['R IY1 F'],
  "rieg": ['R IY1 G'],
  "riege": ['R IY1 JH'],
  "riegel": ['R IY1 G AH0 L'],
  "rieger": ['R IY1 G ER0'],
  "riegert": ['R IY1 G ER0 T'],
  "riegle": ['R IY1 G AH0 L'],
  "riegler": ['R IY1 G L ER0'],
  "riehl": ['R IY1 L'],
  "riehle": ['R IY1 HH AH0 L'],
  "riehm": ['R IY1 M'],
  "riek": ['R IY1 K'],
  "rieke": ['R IY1 K'],
  "rieken": ['R IY1 K AH0 N'],
  "rieker": ['R IY1 K ER0'],
  "riel": ['R IY1 L'],
  "rielly": ['R IY1 L IY0'],
  "riely": ['R IY1 L IY0'],
  "rieman": ['R IY1 M AH0 N'],
  "riemann": ['R IY1 M AH0 N'],
  "riemenschneider": ['R IY1 M IH0 N SH N AY0 D ER0'],
  "riemer": ['R IY1 M ER0'],
  "riemersma": ['R IY0 M EH1 R S M AH0'],
  "riendeau": ['R AH0 N D OW1'],
  "rienstra": ['R IY1 N S T R AH0'],
  "riepe": ['R IY1 P'],
  "riera": ['R IY1 R AH0'],
  "rierson": ['R IY1 R S AH0 N'],
  "ries": ['R AY1 Z'],
  "riesberg": ['R IY1 S B ER0 G'],
  "riese": ['R IY1 Z'],
  "riesen": ['R IY1 S AH0 N'],
  "riesenberg": ['R IY1 S AH0 N B ER0 G'],
  "rieser": ['R IY1 S ER0'],
  "riesh": ['R AY1 SH', 'R IY1 SH'],
  "riesling": ['R IY1 Z L IH0 NG'],
  "riess": ['R IY1 S'],
  "riester": ['R AY1 IH0 S T ER0'],
  "riesterer": ['R IY1 S T ER0 ER0'],
  "rieth": ['R AY1 IH0 TH'],
  "rietman": ['R AY1 T M AH0 N'],
  "rietveld": ['R IY1 T V IH0 L D'],
  "rietz": ['R IY1 T S'],
  "rieves": ['R IY1 V Z'],
  "rife": ['R AY1 F'],
  "rifenburgh": ['R IH1 F AH0 N B ER0 G'],
  "riff": ['R IH1 F'],
  "riffe": ['R IH1 F'],
  "riffed": ['R IH1 F T'],
  "riffel": ['R IH1 F AH0 L'],
  "riffey": ['R IH1 F IY0'],
  "riffing": ['R IH1 F IH0 NG'],
  "riffle": ['R IH1 F AH0 L'],
  "riffraff": ['R IH1 F R AE2 F'],
  "riffs": ['R IH1 F S'],
  "rifkin": ['R IH1 F K IH0 N'],
  "rifkin's": ['R IH1 F K IH0 N Z'],
  "rifkind": ['R IH1 F K IH0 N D'],
  "rifle": ['R AY1 F AH0 L'],
  "rifle-shot": ['R AY1 F AH0 L SH AA2 T'],
  "rifled": ['R AY1 F AH0 L D'],
  "rifleman": ['R AY1 F AH0 L M AH0 N'],
  "riflery": ['R AY1 F AH0 L R IY0'],
  "rifles": ['R AY1 F AH0 L Z'],
  "rifling": ['R AY1 F L IH0 NG'],
  "rift": ['R IH1 F T'],
  "rifts": ['R IH1 F T S'],
  "rig": ['R IH1 G'],
  "riga": ['R IY1 G AH0'],
  "rigas": ['R AY1 G AH0 Z'],
  "rigatoni": ['R IH0 G AH0 T OW1 N IY0'],
  "rigby": ['R IH1 G B IY0'],
  "rigdon": ['R IH1 G D AH0 N'],
  "rigel": ['R AY1 JH AH0 L'],
  "rigg": ['R IH1 G'],
  "riggan": ['R IH1 G AH0 N'],
  "rigged": ['R IH1 G D'],
  "riggen": ['R IH1 G AH0 N'],
  "riggenbach": ['R IH1 G IH0 N B AA0 K'],
  "riggi": ['R IH1 JH IY0'],
  "riggin": ['R IH1 G IH0 N'],
  "rigging": ['R IH1 G IH0 NG'],
  "riggings": ['R IH1 G IH0 NG Z'],
  "riggins": ['R IH1 G IH0 N Z'],
  "riggio": ['R IY1 JH IY0 OW0'],
  "riggle": ['R IH1 G AH0 L'],
  "riggleman": ['R IH1 G AH0 L M AH0 N'],
  "riggs": ['R IH1 G Z'],
  "riggsbee": ['R IH1 G Z B IY0'],
  "righetti": ['R IY0 G EH1 T IY0'],
  "righi": ['R IY1 G IY0'],
  "right": ['R AY1 T'],
  "right's": ['R AY1 T S'],
  "right-brace": ['R AY1 T B R EY1 S'],
  "right-hand": ['R AY0 T HH AE1 N D'],
  "right-handed": ['R AY0 T HH AE1 N D IH0 D'],
  "right-winger": ['R AY2 T W IH1 NG ER0'],
  "right-wingers": ['R AY2 T W IH1 NG ER0 Z'],
  "righted": ['R AY1 T IH0 D'],
  "righteous": ['R AY1 CH AH0 S'],
  "righteously": ['R AY1 CH AH0 S L IY0'],
  "righteousness": ['R AY1 CH AH0 S N AH0 S'],
  "righter": ['R AY1 T ER0'],
  "rightful": ['R AY1 T F AH0 L'],
  "rightfully": ['R AY1 T F AH0 L IY0'],
  "righthand": ['R AY0 T HH AE1 N D'],
  "righthanded": ['R AY0 T HH AE1 N D IH0 D'],
  "rightish": ['R AY1 T IH0 SH'],
  "rightist": ['R AY1 T IH0 S T'],
  "rightists": ['R AY1 T IH0 S T S'],
  "rightly": ['R AY1 T L IY0'],
  "rightmire": ['R AY1 T M AY2 R'],
  "rightmyer": ['R AY1 T M IY0 ER0'],
  "rightness": ['R AY1 T N AH0 S'],
  "righto": ['R AY1 T OW0'],
  "rights": ['R AY1 T S'],
  "rights'": ['R AY1 T S'],
  "rightward": ['R AY1 T W ER0 D'],
  "rightwing": ['R AY1 T W IH2 NG'],
  "righty": ['R AY1 T IY0'],
  "rigid": ['R IH1 JH AH0 D', 'R IH1 JH IH0 D'],
  "rigidities": ['R IH0 JH IH1 D IH0 T IY0 Z'],
  "rigidity": ['R IH0 JH IH1 D AH0 T IY0'],
  "rigidly": ['R IH1 JH IH0 D L IY0'],
  "rigler": ['R AY1 G AH0 L ER0', 'R IH1 G L ER0'],
  "rigney": ['R IH1 G N IY0'],
  "rigo": ['R IY1 G OW0'],
  "rigoletto": ['R IH2 G AH0 L EH1 T OW0'],
  "rigoni": ['R IY0 G OW1 N IY0'],
  "rigor": ['R IH1 G ER0'],
  "rigorous": ['R IH1 G ER0 AH0 S'],
  "rigorously": ['R IH1 G ER0 AH0 S L IY0'],
  "rigors": ['R IH1 G ER0 Z'],
  "rigs": ['R IH1 G Z'],
  "rigsbee": ['R IH1 G Z B IY2'],
  "rigsby": ['R IH1 G Z B IY0'],
  "rigueur": ['R IH0 G Y UW1 R', 'R IH1 G ER0'],
  "riha": ['R AY1 HH AH0'],
  "rihanna": ['R IY2 HH AA1 N AH0'],
  "rihn": ['R IH1 N'],
  "rijn": ['R IY1 AH0 N'],
  "rikard": ['R IH1 K ER0 D'],
  "rike": ['R AY1 K'],
  "rikeld": ['R AY1 K EH0 L D', 'R IH1 K EH0 L D'],
  "riker": ['R AY1 K ER0'],
  "riker's": ['R AY1 K ER0 Z'],
  "rikers": ['R AY1 K ER0 Z'],
  "rikki": ['R IH1 K IY0'],
  "riklis": ['R IH1 K L IH0 S'],
  "riksbank": ['R IH1 K S B AE2 NG K'],
  "riksdag": ['R IH1 K S D AE0 G'],
  "rile": ['R AY1 L'],
  "riled": ['R AY1 L D'],
  "riles": ['R AY1 L Z'],
  "riley": ['R AY1 L IY0'],
  "riley's": ['R AY1 L IY0 Z'],
  "rill": ['R IH1 L'],
  "rill's": ['R IH1 L Z'],
  "rilla": ['R IH1 L AH0'],
  "rillette": ['R IH0 L EH1 T'],
  "rilling": ['R IH1 L IH0 NG'],
  "rilwanu": ['R IH0 L W AA1 N UW0'],
  "rim": ['R IH1 M'],
  "rima": ['R IY1 M AH0'],
  "rimbey": ['R IH1 M B IY0'],
  "rime": ['R AY1 M'],
  "rimel": ['R IH1 M AH0 L'],
  "rimer": ['R AY1 M ER0'],
  "rimes": ['R AY1 M Z'],
  "rimkus": ['R IH1 M K AH0 S'],
  "rimmed": ['R IH1 M D'],
  "rimmer": ['R IH1 M ER0'],
  "rimpochet": ['R IH0 P OW1 CH IH0 T', 'R IH2 M P OW1 CH AH0 T'],
  "rimpochets": ['R IH2 P OW1 CH IH0 T S', 'R IH2 M P OW1 CH IH0 T S'],
  "rims": ['R IH1 M Z'],
  "rimson": ['R IH1 M S AH0 N'],
  "rina": ['R IY1 N AH0'],
  "rinaldi": ['R IY0 N AA1 L D IY0'],
  "rinaldo": ['R IH0 N AA1 L D OW0'],
  "rinard": ['R IH1 N ER0 D'],
  "rinas": ['R AY1 N AH0 Z'],
  "rinaudo": ['R IY0 N AO1 D OW0'],
  "rinck": ['R IH1 NG K'],
  "rincon": ['R IH1 NG K AH0 N'],
  "rind": ['R AY1 N D'],
  "rindahl": ['R IH1 N D AA2 L'],
  "rinderer": ['R IH1 N D ER0 ER0'],
  "rinderknecht": ['R IH1 N D ER0 K N IH0 K T'],
  "rindfleisch": ['R IH1 N D F L AY2 SH'],
  "rinds": ['R AY1 N D Z'],
  "rindt": ['R IH1 N T'],
  "rine": ['R AY1 N'],
  "rineer": ['R IH1 N IH0 R'],
  "rinehardt": ['R AY1 N HH AA2 R T'],
  "rinehart": ['R AY1 N HH AA2 R T'],
  "rinehimer": ['R AY1 N HH AY2 M ER0'],
  "rinella": ['R IH0 N EH1 L AH0'],
  "riner": ['R AY1 N ER0'],
  "rines": ['R AY1 N Z'],
  "riney": ['R AY1 N IY0'],
  "ring": ['R IH1 NG'],
  "ring's": ['R IH1 NG Z'],
  "ring-around-the-rosy": ['R IH1 NG ER0 AW1 N DH ER0 OW1 Z IY0'],
  "ringberg": ['R IH1 NG B ER0 G'],
  "ringdahl": ['R IH1 NG D AA0 L'],
  "ringe": ['R IH1 NG'],
  "ringed": ['R IH1 NG D'],
  "ringeisen": ['R IH1 NG G AY0 S AH0 N'],
  "ringel": ['R IH1 NG G AH0 L'],
  "ringen": ['R IH1 NG AH0 N'],
  "ringenberg": ['R IH1 NG AH0 N B ER0 G'],
  "ringer": ['R IH1 NG ER0'],
  "ringers": ['R IH1 NG ER0 Z'],
  "ringgenberg": ['R IH1 NG G AH0 N B ER0 G'],
  "ringgit": ['R IH1 NG IH0 T'],
  "ringgold": ['R IH1 NG G OW2 L D'],
  "ringing": ['R IH1 NG IH0 NG'],
  "ringland": ['R IH1 NG G L AH0 N D'],
  "ringle": ['R IH1 NG G AH0 L'],
  "ringleader": ['R IH1 NG L IY2 D ER0'],
  "ringleaders": ['R IH1 NG L IY2 D ER0 Z'],
  "ringler": ['R IH1 NG G AH0 L ER0', 'R IH1 NG G L ER0'],
  "ringley": ['R IH1 NG G L IY0'],
  "ringling": ['R IH1 NG L IH0 NG'],
  "ringmaster": ['R IH1 NG M AE2 S T ER0'],
  "ringneck": ['R IH1 NG N EH2 K'],
  "ringo": ['R IY1 NG G OW0'],
  "ringold": ['R IH1 NG G OW0 L D'],
  "ringquist": ['R IH1 NG K W IH0 S T'],
  "rings": ['R IH1 NG Z'],
  "ringside": ['R IH1 NG S AY2 D'],
  "ringstad": ['R IH1 NG S T AH0 D'],
  "ringuette": ['R IH0 NG EH1 T'],
  "ringwald": ['R IH1 NG W AO0 L D'],
  "ringwood": ['R IH1 NG W UH2 D'],
  "rini": ['R IY1 N IY0'],
  "rininger": ['R IH1 N IH0 NG ER0'],
  "rink": ['R IH1 NG K'],
  "rinke": ['R IH1 NG K'],
  "rinkenberger": ['R IH1 NG K AH0 N B ER0 G ER0'],
  "rinker": ['R IH1 NG K ER0'],
  "rinko": ['R IH1 NG K OW0'],
  "rinks": ['R IH1 NG K S'],
  "rinn": ['R IH1 N'],
  "rinne": ['R IH1 N'],
  "rinse": ['R IH1 N S'],
  "rinsing": ['R IH1 N S IH0 NG'],
  "rintala": ['R IY0 N T AA1 L AH0'],
  "rintel": ['R IH0 N T EH1 L'],
  "rintel's": ['R IH0 N T EH1 L Z'],
  "rintels": ['R IH0 N T EH1 L Z'],
  "rintels'": ['R IH0 N T EH1 L Z'],
  "rio": ['R IY1 OW0'],
  "rio's": ['R IY1 OW0 Z'],
  "rio-de-janeiro": ['R IY1 OW0 D IY1 JH AH0 N EH1 R OW2'],
  "riojas": ['R IY0 OW1 Y AA0 Z'],
  "riolo": ['R IY0 OW1 L OW0'],
  "rion": ['R AY1 AH0 N'],
  "riopel": ['R IY0 AH0 P EH1 L'],
  "riopelle": ['R IY0 AH0 P EH1 L IY0'],
  "riordan": ['R IH1 R D AH0 N'],
  "riordan's": ['R IH1 R D AH0 N Z'],
  "rios": ['R IY1 OW0 S'],
  "riot": ['R AY1 AH0 T'],
  "rioted": ['R AY1 AH0 T IH0 D'],
  "rioter": ['R AY1 AH0 T ER0'],
  "rioters": ['R AY1 AH0 T ER0 Z'],
  "rioting": ['R AY1 AH0 T IH0 NG'],
  "riotous": ['R AY1 AH0 T AH0 S'],
  "riots": ['R AY1 AH0 T S'],
  "riots'": ['R AY1 AH0 T S'],
  "rioux": ['R UW1'],
  "rip": ['R IH1 P'],
  "ripa": ['R IY1 P AH0'],
  "ripe": ['R AY1 P'],
  "ripen": ['R AY1 P AH0 N'],
  "ripened": ['R AY1 P AH0 N D'],
  "ripening": ['R AY1 P AH0 N IH0 NG', 'R AY1 P N IH0 NG'],
  "riphagen": ['R IH1 P AA2 G AH0 N'],
  "ripka": ['R IY1 P K AH0'],
  "ripke": ['R IH1 P K'],
  "ripken": ['R IH1 P K AH0 N'],
  "ripken's": ['R IH1 P K AH0 N Z'],
  "ripkin": ['R IH1 P K IH0 N'],
  "ripley": ['R IH1 P L IY0'],
  "ripoff": ['R IH1 P AO2 F'],
  "ripoffs": ['R IH1 P AO2 F S'],
  "riposte": ['R IH0 P OW1 S T'],
  "ripostes": ['R IH0 P AO1 S T S'],
  "ripp": ['R IH1 P'],
  "rippe": ['R IH1 P'],
  "ripped": ['R IH1 P T'],
  "rippee": ['R IH1 P IY1'],
  "rippel": ['R IH1 P AH0 L'],
  "rippeon": ['R IH1 P IY0 AH0 N'],
  "ripper": ['R IH1 P ER0'],
  "ripperger": ['R IH1 P ER0 G ER0'],
  "rippetoe": ['R IH1 P IH0 T OW0'],
  "rippey": ['R IH1 P IY0'],
  "ripping": ['R IH1 P IH0 NG'],
  "ripple": ['R IH1 P AH0 L'],
  "rippled": ['R IH1 P AH0 L D'],
  "ripples": ['R IH1 P AH0 L Z'],
  "rippling": ['R IH1 P AH0 L IH0 NG', 'R IH1 P L IH0 NG'],
  "ripplinger": ['R IH1 P AH0 L IH0 NG ER0', 'R IH1 P L IH0 NG ER0'],
  "rippon": ['R IH1 P AH0 N'],
  "rippy": ['R IH1 P IY0'],
  "rips": ['R IH1 P S'],
  "risa": ['R IY1 S AH0'],
  "risberg": ['R IH1 S B ER0 G'],
  "risc": ['R IH1 S K'],
  "risch": ['R IH1 SH'],
  "risden": ['R IH1 S D AH0 N'],
  "risdon": ['R IH1 S D AH0 N'],
  "rise": ['R AY1 Z'],
  "risen": ['R IH1 Z AH0 N'],
  "risenhoover": ['R IH1 S IH0 N HH UW0 V ER0', 'R AY1 Z IH0 N HH UW0 V ER0'],
  "riser": ['R AY1 Z ER0'],
  "rises": ['R AY1 Z AH0 Z', 'R AY1 Z IH0 Z'],
  "rish": ['R IH1 SH'],
  "rishel": ['R IH1 SH AH0 L'],
  "rishell": ['R IH1 SH AH0 L'],
  "risher": ['R IH1 SH ER0'],
  "risi": ['R IY1 S IY0'],
  "risin": ['R AY1 Z IH0 N'],
  "rising": ['R AY1 Z IH0 NG'],
  "risinger": ['R AY1 Z IH0 NG ER0'],
  "risk": ['R IH1 S K'],
  "riske": ['R IH1 S K'],
  "riske's": ['R IH1 S K S'],
  "risked": ['R IH1 S K T'],
  "riskier": ['R IH1 S K IY0 ER0'],
  "riskiest": ['R IH1 S K IY0 AH0 S T'],
  "riskin": ['R IH1 S K IH0 N'],
  "riskiness": ['R IH1 S K IY0 N AH0 S'],
  "risking": ['R IH1 S K IH0 NG'],
  "riskless": ['R IH1 S K L AH0 S'],
  "risko": ['R IH1 S K OW0'],
  "risks": ['R IH1 S K S'],
  "risky": ['R IH1 S K IY0'],
  "risley": ['R IH1 Z L IY0'],
  "risner": ['R IH1 S N ER0'],
  "riso": ['R IY1 S OW0'],
  "rison": ['R IH1 S AH0 N'],
  "risotto": ['R IY2 S AO1 T OW2'],
  "rispoli": ['R IY0 S P OW1 L IY0'],
  "risque": ['R IH2 S K EY1'],
  "riss": ['R IH1 S'],
  "risse": ['R IH1 S'],
  "risser": ['R IH1 S ER0'],
  "rissler": ['R IH1 S L ER0'],
  "rissman": ['R IH1 S M AH0 N'],
  "rissmiller": ['R IH1 S M IH0 L ER0'],
  "rissmiller's": ['R IH1 S M IH0 L ER0 Z'],
  "risso": ['R IH1 S OW0'],
  "risso's": ['R IH1 S OW0 Z'],
  "rissoli": ['R IH0 S OW1 L IY0'],
  "rissoli's": ['R IH0 S OW1 L IY0 Z'],
  "rist": ['R IH1 S T'],
  "ristau": ['R IH1 S T AW0'],
  "rister": ['R IH1 S T ER0'],
  "riston": ['R IH1 S T AH0 N'],
  "ristorante": ['R IH0 S T ER0 AA1 N T EY0'],
  "ristorantes": ['R IH0 S T ER0 AA1 N T EY0 Z'],
  "ristow": ['R IH1 S T OW0'],
  "rita": ['R IY1 T AH0'],
  "rita's": ['R IY1 T AH0 Z'],
  "ritacco": ['R IY0 T AA1 K OW0'],
  "ritalin": ['R IH1 T AH0 L IH0 N'],
  "ritcey": ['R IH1 T S IY0'],
  "ritcey's": ['R IH1 T S IY0 Z'],
  "ritch": ['R IH1 CH'],
  "ritchey": ['R IH1 CH IY0'],
  "ritchey's": ['R IH1 CH IY0 Z'],
  "ritchie": ['R IH1 CH IY0'],
  "ritchie's": ['R IH1 CH IY0 Z'],
  "rite": ['R AY1 T'],
  "rite's": ['R AY1 T S'],
  "ritenour": ['R IH1 T AH0 N AW0 R', 'R AY1 T AH0 N AW0 R'],
  "ritenour's": ['R IH1 T AH0 N AW0 R Z', 'R AY1 T AH0 N AW0 R Z'],
  "riter": ['R AY1 T ER0'],
  "rites": ['R AY1 T S'],
  "ritesh": ['R IH2 T EH1 SH'],
  "ritger": ['R IH1 T G ER0'],
  "ritner": ['R IH1 T N ER0'],
  "ritsema": ['R IH0 T S IY1 M AH0'],
  "ritson": ['R IH1 T S AH0 N'],
  "ritt": ['R IH1 T'],
  "ritt's": ['R IH1 T S'],
  "rittenberg": ['R IH1 T AH0 N B ER0 G'],
  "rittenberg's": ['R IH1 T AH0 N B ER0 G Z'],
  "rittenberry": ['R IH1 T AH0 N B EH0 R IY0'],
  "rittenberry's": ['R IH1 T AH0 N B EH0 R IY0 Z'],
  "rittenhouse": ['R IH1 T AH0 N HH AW2 S'],
  "rittenhouse's": ['R IH1 T AH0 N HH AW2 S IH0 Z'],
  "rittenour": ['R IH1 T AH0 N AW0 R'],
  "rittenour's": ['R IH1 T AH0 N AW0 R Z'],
  "ritter": ['R IH1 T ER0'],
  "ritter's": ['R IH1 T ER0 Z'],
  "ritterbusch": ['R IH1 T ER0 B UH2 SH'],
  "rittereiser": ['R IH1 T ER0 AY2 Z ER0'],
  "rittereiser's": ['R IH1 T ER0 AY2 Z ER0 Z'],
  "rittgers": ['R IH1 T G ER0 Z'],
  "ritthaler": ['R IH1 TH AH0 L ER0'],
  "rittle": ['R IH1 T AH0 L'],
  "rittman": ['R IH1 T M AH0 N'],
  "rittner": ['R IH1 T N ER0'],
  "ritts": ['R IH1 T S'],
  "ritual": ['R IH1 CH UW0 AH0 L'],
  "ritualistic": ['R IH2 CH UW0 AH0 L IH1 S T IH0 K'],
  "ritualistically": ['R IH2 CH UW0 AH0 L IH1 S T IH0 K AH0 L IY0', 'R IH2 CH UW0 AH0 L IH1 S T IH0 K L IY0'],
  "rituals": ['R IH1 CH UW0 AH0 L Z'],
  "ritz": ['R IH1 T S'],
  "ritzel": ['R IH1 T Z AH0 L'],
  "ritzenthaler": ['R IH1 T Z IH0 N TH AH0 L ER0', 'R IH1 T S IH0 N TH AA2 L ER0'],
  "ritzer": ['R IH1 T Z ER0'],
  "ritzert": ['R IH1 T Z ER0 T'],
  "ritziest": ['R IH1 T S IY0 AH0 S T'],
  "ritzman": ['R IH1 T S M AH0 N'],
  "ritzy": ['R IH1 T S IY0'],
  "riunite": ['R IY0 UW0 N AY1 T', 'R IY0 UW0 N AY1 T IY0'],
  "riva": ['R IY1 V AH0'],
  "rival": ['R AY1 V AH0 L'],
  "rival's": ['R AY1 V AH0 L Z'],
  "rivaled": ['R AY1 V AH0 L D'],
  "rivaling": ['R AY1 V AH0 L IH0 NG', 'R AY1 V L IH0 NG'],
  "rivalries": ['R AY1 V AH0 L R IY0 Z'],
  "rivalry": ['R AY1 V AH0 L R IY0'],
  "rivals": ['R AY1 V AH0 L Z'],
  "rivals'": ['R AY1 V AH0 L Z'],
  "rivard": ['R IH1 V ER0 D'],
  "rivas": ['R IY1 V AA0 Z'],
  "riven": ['R IH1 V AH0 N'],
  "rivenbark": ['R IH1 V IH0 N B AA0 R K'],
  "rivenburg": ['R IH1 V AH0 N B ER0 G'],
  "rivenburgh": ['R IH1 V IH0 N B ER0 G'],
  "river": ['R IH1 V ER0'],
  "river's": ['R IH1 V ER0 Z'],
  "rivera": ['R IH0 V EH1 R AH0'],
  "riverbank": ['R IH1 V ER0 B AE2 NG K'],
  "riverbed": ['R IH1 V ER0 B EH2 D'],
  "riverbend": ['R IH1 V ER0 B EH2 N D'],
  "riverboat": ['R IH1 V ER0 B OW2 T'],
  "riverboat's": ['R IH1 V ER0 B OW2 T S'],
  "riverboats": ['R IH1 V ER0 B OW2 T S'],
  "riverdale": ['R IH1 V ER0 D EY2 L'],
  "riverfront": ['R IH1 V ER0 F R AH2 N T'],
  "riverhead": ['R IH1 V ER0 HH EH2 D'],
  "rivero": ['R IY0 V EH1 R OW0'],
  "rivers": ['R IH1 V ER0 Z'],
  "riverside": ['R IH1 V ER0 S AY2 D'],
  "riverso": ['R IH1 V ER0 S OW0'],
  "riverway": ['R IH1 V ER0 W EY2'],
  "riverwood": ['R IH1 V ER0 W UH2 D'],
  "rives": ['R AY1 V Z'],
  "rivest": ['R IH1 V IH0 S T'],
  "rivet": ['R IH1 V AH0 T'],
  "riveted": ['R IH1 V AH0 T IH0 D'],
  "riveting": ['R IH1 V AH0 T IH0 NG'],
  "rivets": ['R IH1 V AH0 T S'],
  "rivett": ['R IH1 V IH0 T'],
  "rivette": ['R IH0 V EH1 T'],
  "riviello": ['R IY2 V IY0 EH1 L OW0'],
  "riviera": ['R IH2 V IY0 EH1 R AH0'],
  "riviere": ['R IH1 V IY0 EH0 R'],
  "rivieres": ['R IH0 V Y IH1 R EH0 Z'],
  "rivkin": ['R IH1 V K IH0 N'],
  "rivkind": ['R IH1 V K IH0 N D'],
  "rivlin": ['R IH1 V L IH0 N'],
  "rix": ['R IH1 K S'],
  "riyad": ['R IY0 AA1 D', 'R IY0 AE1 D'],
  "riyadh": ['R IY0 AE1 D', 'R IY0 AA1 D'],
  "riyals": ['R IY0 Y AA1 L Z'],
  "riz": ['R IH1 Z'],
  "rizer": ['R AY1 Z ER0'],
  "rizk": ['R IH1 Z K'],
  "rizo": ['R IY1 Z OW0'],
  "rizor": ['R AY1 Z ER0'],
  "rizza": ['R IH1 Z AH0'],
  "rizzi": ['R IH1 Z IY0'],
  "rizzo": ['R IH1 Z OW0'],
  "rizzoli": ['R IH0 Z OW1 L IY0'],
  "rizzolo": ['R IY0 T S OW1 L OW0'],
  "rizzuti": ['R IY0 T S UW1 T IY0'],
  "rizzuto": ['R IY0 T S UW1 T OW0'],
  "rna": ['AA2 R EH2 N EY1'],
  "ro": ['R OW1'],
  "roa": ['R OW1 AH0'],
  "roach": ['R OW1 CH'],
  "roache": ['R OW1 CH'],
  "roaches": ['R OW1 CH IH0 Z'],
  "road": ['R OW1 D'],
  "road's": ['R OW1 D Z'],
  "roadblock": ['R OW1 D B L AA2 K'],
  "roadblocks": ['R OW1 D B L AA2 K S'],
  "roadcap": ['R OW1 D K AE2 P'],
  "roades": ['R OW1 D Z'],
  "roadhouse": ['R OW1 D HH AW2 S'],
  "roadie": ['R OW1 D IY0'],
  "roadmap": ['R OW1 D M AE2 P'],
  "roadmaster": ['R OW1 D M AE2 S T ER0'],
  "roadrailer": ['R OW1 D R EY2 L ER0'],
  "roadrunner": ['R OW1 D R AH2 N ER0'],
  "roads": ['R OW1 D Z'],
  "roadshow": ['R OW1 D SH OW2'],
  "roadside": ['R OW1 D S AY2 D'],
  "roadsides": ['R OW1 D S AY2 D Z'],
  "roadster": ['R OW1 D S T ER0'],
  "roadway": ['R OW1 D W EY2'],
  "roadways": ['R OW1 D W EY2 Z'],
  "roadwork": ['R OW1 D W ER2 K'],
  "roady": ['R OW1 D IY0'],
  "roald": ['R OW1 L D'],
  "roam": ['R OW1 M'],
  "roamed": ['R OW1 M D'],
  "roaming": ['R OW1 M IH0 NG'],
  "roams": ['R OW1 M Z'],
  "roan": ['R OW1 N'],
  "roane": ['R OW1 N'],
  "roanna": ['R OW1 N AH0'],
  "roanoke": ['R OW1 AH0 N OW2 K'],
  "roar": ['R AO1 R'],
  "roared": ['R AO1 R D'],
  "roaring": ['R AO1 R IH0 NG'],
  "roark": ['R AO1 R K'],
  "roarke": ['R AO1 R K'],
  "roars": ['R AO1 R Z'],
  "roarty": ['R AO1 R T IY0'],
  "roast": ['R OW1 S T'],
  "roasted": ['R OW1 S T AH0 D', 'R OW1 S T IH0 D'],
  "roaster": ['R OW1 S T ER0'],
  "roasters": ['R OW1 S T ER0 Z'],
  "roasting": ['R OW1 S T IH0 NG'],
  "roasts": ['R OW1 S T S'],
  "roat": ['R OW1 T'],
  "roath": ['R OW1 TH'],
  "rob": ['R AA1 B'],
  "rob's": ['R AA1 B Z'],
  "roback": ['R OW1 B AE2 K'],
  "robaina": ['R OW0 B EY1 N AH0'],
  "robak": ['R OW1 B AH0 K'],
  "robar": ['R AH0 B AA1 R'],
  "robards": ['R AA1 B ER0 D Z'],
  "robare": ['R OW0 B AA1 R EY0'],
  "robarge": ['R AA1 B AA0 R G'],
  "robart": ['R AA1 B AA2 R T'],
  "robarts": ['R AA1 B AA2 R T S'],
  "robb": ['R AA1 B'],
  "robb's": ['R AA1 B Z'],
  "robbe": ['R AA1 B'],
  "robbed": ['R AA1 B D'],
  "robben": ['R AA1 B AH0 N'],
  "robber": ['R AA1 B ER0'],
  "robberies": ['R AA1 B ER0 IY0 Z'],
  "robbers": ['R AA1 B ER0 Z'],
  "robberson": ['R AA1 B ER0 S AH0 N'],
  "robbery": ['R AA1 B ER0 IY0'],
  "robbie": ['R AA1 B IY0'],
  "robbie's": ['R AA1 B IY0 Z'],
  "robbin": ['R AA1 B IH0 N'],
  "robbing": ['R AA1 B IH0 NG'],
  "robbins": ['R AA1 B IH0 N Z'],
  "robbs": ['R AA1 B Z'],
  "robby": ['R AA1 B IY0'],
  "robby's": ['R AA1 B IY0 Z'],
  "robe": ['R OW1 B'],
  "robeck": ['R OW1 B EH2 K'],
  "robed": ['R OW1 B D'],
  "robel": ['R OW1 B AH0 L'],
  "robello": ['R OW0 B EH1 L OW0'],
  "robelo": ['R AA2 B EH1 L OW0'],
  "rober": ['R OW1 B ER0'],
  "roberds": ['R AA1 B ER0 D Z'],
  "roberg": ['R OW1 B ER0 G'],
  "roberge": ['R AA1 B ER0 JH'],
  "robers": ['R OW1 B ER0 Z'],
  "roberson": ['R AA1 B ER0 S AH0 N'],
  "robert": ['R AA1 B ER0 T'],
  "robert's": ['R AA1 B ER0 T S'],
  "roberta": ['R AH0 B ER1 T AH0'],
  "roberti": ['R AH0 B ER1 T IY0'],
  "roberto": ['R AH0 B EH1 R T OW0', 'R OW0 B EH1 R T OW0'],
  "roberts": ['R AA1 B ER0 T S'],
  "roberts'": ['R AA1 B ER0 T S'],
  "roberts's": ['R AA1 B ER0 T S IH0 Z'],
  "robertshaw": ['R AA1 B ER1 T SH AO1'],
  "robertson": ['R AA1 B ER0 T S AH0 N'],
  "robertson's": ['R AA1 B ER0 T S AH0 N Z'],
  "robes": ['R OW1 B Z'],
  "robeson": ['R OW1 B IH0 S AH0 N'],
  "robey": ['R OW1 B IY0'],
  "robia": ['R OW1 B IY0 AH0'],
  "robichaud": ['R AA1 B IH0 SH OW2'],
  "robichaux": ['R AA1 B IH0 SH OW2'],
  "robicheaux": ['R AA1 B IH0 SH OW2'],
  "robideau": ['R AA1 B IH0 D OW2'],
  "robidoux": ['R AA1 B IH0 D UW0'],
  "robie": ['R OW1 B IY0'],
  "robillard": ['R AA1 B IH0 L ER0 D'],
  "robin": ['R AA1 B AH0 N', 'R AA1 B IH0 N'],
  "robin's": ['R AA1 B AH0 N Z', 'R AA1 B IH0 N Z'],
  "robina": ['R AH0 B IY1 N AH0'],
  "robinett": ['R AA1 B IH0 N EH0 T'],
  "robinette": ['R AA1 B IH0 N EH2 T'],
  "robinhood": ['R AA1 B IH0 N HH UH2 D'],
  "robinia": ['R OW0 B IY1 N IY0 AH0'],
  "robins": ['R AA1 B AH0 N Z'],
  "robins'": ['R AA1 B AH0 N Z'],
  "robins's": ['R AA1 B AH0 N Z IH0 Z'],
  "robinson": ['R AA1 B AH0 N S AH0 N'],
  "robinson's": ['R AA1 B AH0 N S AH0 N Z'],
  "robinsons": ['R AA1 B AH0 N S AH0 N Z'],
  "robinsons'": ['R AA1 B AH0 N S AH0 N Z'],
  "robishaw": ['R AA1 B IH0 SH AO2'],
  "robison": ['R AA1 B IH0 S AH0 N'],
  "robitaille": ['R AA1 B IH0 T EY2 L'],
  "robitussin": ['R AA2 B IH0 T AH1 S IH0 N', 'R OW2 B AH0 T AH1 S IH0 N'],
  "robl": ['R AA1 B AH0 L'],
  "roble": ['R OW1 B AH0 L'],
  "robledo": ['R OW0 B L EY1 D OW0'],
  "roblee": ['R AA1 B L IY2'],
  "robles": ['R OW1 B AH0 L Z'],
  "robley": ['R AA1 B L IY0'],
  "roblin": ['R AA1 B L IH0 N'],
  "robling": ['R OW1 B AH0 L IH0 NG', 'R OW1 B L IH0 NG'],
  "robnett": ['R AA1 B N IH0 T'],
  "robo": ['R OW1 B OW0'],
  "robocop": ['R OW1 B OW0 K AA2 P'],
  "robot": ['R OW1 B AA2 T', 'R OW1 B AH2 T'],
  "robot's": ['R OW1 B AA2 T S', 'R OW1 B AH2 T S'],
  "robotic": ['R OW2 B AA1 T IH0 K'],
  "robotics": ['R OW2 B AA1 T IH0 K S'],
  "robots": ['R OW1 B AA2 T S', 'R OW1 B AH2 T S'],
  "robs": ['R AA1 B Z'],
  "robson": ['R AA1 B S AH0 N'],
  "robuck": ['R OW1 B AH0 K'],
  "robust": ['R OW0 B AH1 S T'],
  "robusta": ['R AO2 B UH1 S T AH0'],
  "robustas": ['R AO2 B UH1 S T AH0 S'],
  "robustelli": ['R OW2 B AH0 S T EH1 L IY0'],
  "robustelli's": ['R OW2 B AH0 S T EH1 L IY0 Z'],
  "robustly": ['R OW2 B AH1 S T L IY0'],
  "robustness": ['R OW0 B AH1 S T N AH0 S'],
  "roby": ['R OW1 B IY0'],
  "robyn": ['R AA1 B IH0 N'],
  "roc": ['R AA1 K'],
  "roca": ['R OW1 K AH0'],
  "rocap": ['R OW1 K AE2 P'],
  "rocard": ['R OW1 K ER0 D', 'R OW1 K AA2 R D', 'R OW2 SH AA1 R D', 'R AH0 K AA1 R D'],
  "rocca": ['R AA1 K AH0'],
  "roccaforte": ['R OW0 K AH0 F AO1 R T IY0'],
  "roccas": ['R AA1 K AH0 S'],
  "rocchi": ['R OW1 K IY0'],
  "rocchio": ['R OW1 K IY0 OW0'],
  "rocco": ['R AA1 K OW0'],
  "rocco's": ['R AA1 K OW0 Z'],
  "roch": ['R AA1 K'],
  "rocha": ['R OW1 K AH0'],
  "rochat": ['R AA1 CH AH0 T'],
  "rochberg": ['R OW1 CH B ER0 G'],
  "roche": ['R OW1 CH', 'R OW1 SH'],
  "roche's": ['R OW1 SH IH0 Z'],
  "rochefort": ['R AA1 K IH0 F ER0 T', 'R AA1 SH F ER0 T', 'R AA1 K F ER0 T'],
  "rocheleau": ['R AA1 SH IH0 L OW0'],
  "rochella": ['R AH0 CH EH1 L AH0'],
  "rochelle": ['R OW0 SH EH1 L', 'R AH2 SH EH1 L'],
  "rochelle's": ['R OW0 SH EH1 L Z', 'R AH2 SH EH1 L Z'],
  "rocher": ['R OW1 CH ER0', 'R OW1 SH ER0'],
  "rochester": ['R AA1 CH EH2 S T ER0'],
  "rochester's": ['R AA1 CH EH2 S T ER0 Z'],
  "rochette": ['R AH0 SH EH1 T'],
  "rochford": ['R AA1 CH F ER0 D'],
  "rochlin": ['R AA1 K L IH0 N'],
  "rochon": ['R AA1 CH AH0 N'],
  "rock": ['R AA1 K'],
  "rock'n'roll": ['R AA1 K AH0 N R OW1 L'],
  "rock's": ['R AA1 K S'],
  "rock-and-roll": ['R AA1 K AE1 N D R OW1 L'],
  "rockabilly": ['R AA1 K AH0 B IH2 L IY0'],
  "rockafellow": ['R AA1 K AH0 F EH2 L OW0'],
  "rockaway": ['R AA1 K AH0 W EY2'],
  "rocke": ['R AA1 K'],
  "rocked": ['R AA1 K T'],
  "rockefeller": ['R AA1 K AH0 F EH2 L ER0'],
  "rockefeller's": ['R AA1 K AH0 F EH2 L ER0 Z'],
  "rockefellers": ['R AA1 K AH0 F EH2 L ER0 Z'],
  "rockel": ['R AA1 K AH0 L'],
  "rockenbach": ['R AA1 K IH0 N B AA0 K'],
  "rocker": ['R AA1 K ER0'],
  "rockers": ['R AA1 K ER0 Z'],
  "rocket": ['R AA1 K AH0 T'],
  "rocket's": ['R AA1 K AH0 T S'],
  "rocketdyne": ['R AA1 K IH0 T D AY2 N'],
  "rocketed": ['R AA1 K AH0 T IH0 D'],
  "rocketing": ['R AA1 K AH0 T IH0 NG'],
  "rocketry": ['R AA1 K AH0 T R IY0'],
  "rockets": ['R AA1 K AH0 T S'],
  "rockett": ['R AA1 K IH0 T'],
  "rockette": ['R AA0 K EH1 T'],
  "rockettes": ['R AA0 K EH1 T S'],
  "rockey": ['R AA1 K IY0'],
  "rockford": ['R AA1 K F ER0 D'],
  "rockhill": ['R AA1 K HH IH2 L'],
  "rockhold": ['R AA1 K HH OW2 L D'],
  "rockholt": ['R AA1 K HH OW2 L T'],
  "rockies": ['R AA1 K IY0 Z'],
  "rockin'": ['R AA1 K IH0 N'],
  "rocking": ['R AA1 K IH0 NG'],
  "rockingham": ['R AA1 K IH0 NG HH AE2 M'],
  "rockland": ['R AA1 K L AH0 N D'],
  "rockley": ['R AA1 K L IY0'],
  "rocklin": ['R AA1 K L IH0 N'],
  "rockman": ['R AA1 K M AH0 N'],
  "rockmore": ['R AA1 K M AO0 R'],
  "rockne": ['R AA1 K N IY0'],
  "rockoff": ['R AA1 K AO2 F'],
  "rockow": ['R AA1 S K OW0'],
  "rockport": ['R AA1 K P AO2 R T'],
  "rockresort": ['R AA1 K R IH0 Z AO2 R T'],
  "rockresorts": ['R AA1 K R IH0 Z AO2 R T S'],
  "rockrose": ['R AA1 K R OW2 Z'],
  "rocks": ['R AA1 K S'],
  "rockstar": ['R AA1 K S T AA2 R'],
  "rockville": ['R AA1 K V IH2 L'],
  "rockwell": ['R AA1 K W EH2 L'],
  "rockwell's": ['R AA1 K W EH2 L Z'],
  "rockwood": ['R AA1 K W UH2 D'],
  "rocky": ['R AA1 K IY0'],
  "rococo": ['R AH0 K OW1 K OW2'],
  "rocque": ['R AA1 K'],
  "rod": ['R AA1 D'],
  "roda": ['R OW1 D AH0'],
  "rodabaugh": ['R AA1 D AH0 B AO0'],
  "rodak": ['R OW1 D AH0 K'],
  "rodale": ['R OW1 D EY2 L'],
  "rodarte": ['R AA1 D AA0 R T'],
  "rodas": ['R OW1 D AH0 Z'],
  "rodd": ['R AA1 D'],
  "rodden": ['R AA1 D AH0 N'],
  "roddenberry": ['R AA1 D AH0 N B EH0 R IY0'],
  "roddey": ['R AA1 D IY0'],
  "roddick": ['R AA1 D IH2 K'],
  "roddie": ['R AA1 D IY0'],
  "rodding": ['R AA1 D IH0 NG'],
  "roddy": ['R AA1 D IY0'],
  "rode": ['R OW1 D'],
  "rodebaugh": ['R AA1 D IH0 B AO0'],
  "rodefer": ['R AA1 D IH0 F ER0'],
  "rodeffer": ['R AA1 D IH0 F ER0'],
  "rodeheaver": ['R AA1 D IH0 HH IY0 V ER0'],
  "rodela": ['R OW0 D EH1 L AH0'],
  "rodell": ['R AA1 D AH0 L'],
  "roden": ['R OW1 D AH0 N'],
  "rodenbaugh": ['R AA1 D IH0 N B AW0'],
  "rodenbeck": ['R OW1 D AH0 N B EH0 K'],
  "rodenberg": ['R OW1 D AH0 N B ER0 G'],
  "rodenberger": ['R OW1 D AH0 N B ER0 G ER0'],
  "rodenburg": ['R OW1 D AH0 N B ER0 G'],
  "rodent": ['R OW1 D AH0 N T'],
  "rodenticide": ['R OW0 D EH1 N T IH0 S AY0 D'],
  "rodents": ['R OW1 D AH0 N T S'],
  "rodeo": ['R OW1 D IY0 OW2'],
  "rodeos": ['R OW1 D IY0 OW2 Z'],
  "roder": ['R OW1 D ER0'],
  "roderica": ['R OW0 D ER0 IY1 K AH0'],
  "roderick": ['R AA1 D R IH0 K'],
  "roderick's": ['R AA1 D R IH0 K S'],
  "rodes": ['R OW1 D Z'],
  "rodewald": ['R AA1 D UW0 AO0 L D'],
  "rodeway": ['R OW1 D W EY2'],
  "rodge": ['R AA1 JH'],
  "rodger": ['R AA1 JH ER0'],
  "rodgers": ['R AA1 JH ER0 Z'],
  "rodgin": ['R AA1 JH IH0 N'],
  "rodham": ['R AO1 D AH0 M'],
  "rodi": ['R OW1 D IY0'],
  "rodick": ['R AA1 D IH0 K'],
  "rodier": ['R OW1 D IY0 ER0'],
  "rodime": ['R OW0 D IY1 M'],
  "rodin": ['R OW1 D IH0 N', 'R OW2 D AE1 N'],
  "rodine": ['R OW0 D IY1 N IY0'],
  "rodino": ['R OW0 D IY1 N OW0'],
  "rodkey": ['R AA1 D K IY2'],
  "rodman": ['R AA1 D M AH0 N'],
  "rodman's": ['R AA1 D M AH0 N Z'],
  "rodmond": ['R AA1 D M AH0 N D'],
  "rodmund": ['R AA1 D M AH0 N D'],
  "rodney": ['R AA1 D N IY0'],
  "rodocker": ['R AA1 D AH0 K ER0'],
  "rodolf": ['R AA1 D OW0 L F'],
  "rodolfo": ['R OW0 D AA1 L F OW0'],
  "rodolph": ['R OW1 D AA2 L F'],
  "rodrick": ['R AA1 D R IH0 K'],
  "rodrigeuz": ['R OW0 D R IY1 JH UW0 Z'],
  "rodrigo": ['R AH0 D R IY1 G OW0'],
  "rodrigue": ['R OW1 D R IY0 G'],
  "rodrigues": ['R AA0 D R IY1 G IH0 S'],
  "rodriguez": ['R AA0 D R IY1 G EH0 Z'],
  "rodriguez's": ['R AA0 D R IY1 G EH0 Z IH0 Z'],
  "rodriques": ['R AA0 D R IY1 G EH0 S'],
  "rodriquez": ['R AA0 D R IY1 K EH0 Z'],
  "rods": ['R AA1 D Z'],
  "rodwell": ['R AA1 D W EH2 L'],
  "rody": ['R OW1 D IY0'],
  "roe": ['R OW1'],
  "roeber": ['R OW1 B ER0'],
  "roebke": ['R OW1 B K'],
  "roebling": ['R OW1 B L IH0 NG'],
  "roebuck": ['R OW1 B AH2 K'],
  "roebuck's": ['R OW1 B AH2 K S'],
  "roecker": ['R OW1 K ER0'],
  "roed": ['R OW1 D'],
  "roedel": ['R OW1 D AH0 L'],
  "roeder": ['R OW1 D ER0'],
  "roederer": ['R OW1 D ER0 ER0'],
  "roediger": ['R OW1 D IH0 G ER0'],
  "roedl": ['R OW1 D AH0 L'],
  "roegner": ['R OW1 G N ER0'],
  "roehi": ['R OW1 IY0'],
  "roehl": ['R OW1 L'],
  "roehling": ['R OW1 L IH0 NG'],
  "roehm": ['R OW1 M'],
  "roehr": ['R AO1 R'],
  "roehrich": ['R AO1 R IH0 K'],
  "roehrig": ['R AO1 R IH0 G'],
  "roehrs": ['R AO1 R Z'],
  "roeland": ['R OW1 L AH0 N D'],
  "roelke": ['R OW1 L K'],
  "roell": ['R OW1 L'],
  "roelle": ['R OW1 L'],
  "roeller": ['R OW1 L ER0'],
  "roelofs": ['R OW1 L AH0 F S'],
  "roemer": ['R OW1 M ER0'],
  "roemmich": ['R OW1 M IH0 K'],
  "roen": ['R OW1 N'],
  "roeper": ['R OW1 P ER0'],
  "roepke": ['R OW1 P K'],
  "roes": ['R OW1 Z'],
  "roesch": ['R OW1 SH'],
  "roese": ['R OW1 S'],
  "roesel": ['R OW1 S AH0 L'],
  "roeser": ['R OW1 Z ER0'],
  "roeske": ['R OW1 S K'],
  "roesler": ['R OW1 S AH0 L ER0', 'R OW1 S L ER0'],
  "roesner": ['R OW1 S N ER0'],
  "roessler": ['R OW1 S AH0 L ER0', 'R OW1 S L ER0'],
  "roessner": ['R OW1 S N ER0'],
  "roether": ['R OW1 DH ER0'],
  "roethler": ['R OW1 TH AH0 L ER0', 'R OW1 TH L ER0'],
  "roettger": ['R OW1 T G ER0'],
  "roever": ['R AA1 EH0 V ER0'],
  "roff": ['R AO1 F'],
  "roffe": ['R AA1 F'],
  "roffman": ['R AO1 F M AH0 N'],
  "rofin": ['R OW1 F IH0 N'],
  "rog": ['R AA1 G'],
  "rogachev": ['R OW1 G AH0 CH AH0 V'],
  "rogacki": ['R AH0 G AA1 T S K IY0'],
  "rogaine": ['R OW0 G EY1 N'],
  "rogal": ['R OW1 G AH0 L'],
  "rogala": ['R OW0 G AA1 L AH0'],
  "rogalla": ['R OW0 G AA1 L AH0'],
  "rogalski": ['R AH0 G AA1 L S K IY0'],
  "rogan": ['R OW1 G AH0 N'],
  "rogel": ['R OW1 G AH0 L'],
  "rogelio": ['R OW0 G IY1 L IY0 OW0'],
  "roger": ['R AA1 JH ER0'],
  "roger's": ['R AA1 JH ER0 Z'],
  "rogernomics": ['R OW2 G ER0 N AA1 M IH0 K S'],
  "rogers": ['R AA1 JH ER0 Z'],
  "rogers'": ['R AA1 JH ER0 Z'],
  "rogers's": ['R AA1 JH ER0 Z IH0 Z'],
  "rogerson": ['R AA1 G ER0 S AH0 N'],
  "roget": ['R OW0 ZH EY1'],
  "roget's": ['R OW0 ZH EY1 Z'],
  "rogge": ['R AA1 G'],
  "roggenbuck": ['R AA1 G IH0 N B AH0 K'],
  "roggenkamp": ['R AA1 G IH0 N K AE0 M P'],
  "roggio": ['R AA1 JH IY0 OW2'],
  "roggow": ['R AA1 G OW0'],
  "rogier": ['R OW1 ZH Y ER0'],
  "rogin": ['R OW1 G IH0 N'],
  "roginski": ['R AH0 G IH1 N S K IY0'],
  "rogness": ['R AA1 G N IH0 S'],
  "rogoff": ['R AA1 G AO0 F'],
  "rogowski": ['R AH0 G AO1 F S K IY0'],
  "rogozinski": ['R AH0 G AH0 Z IH1 N S K IY0'],
  "rogstad": ['R AA1 G S T AH0 D'],
  "rogue": ['R OW1 G'],
  "rogues": ['R OW1 G Z'],
  "rogus": ['R OW1 G AH0 S'],
  "roh": ['R OW1'],
  "roh's": ['R OW1 Z'],
  "rohan": ['R OW1 AH0 N'],
  "rohana": ['R AH0 HH AE1 N AH0'],
  "rohatyn": ['R AA1 HH AH0 T IH0 N', 'R OW0 HH AE1 T AH0 N'],
  "rohatyn's": ['R AA1 HH AH0 T IH0 N Z', 'R OW0 HH AE1 T AH0 N Z'],
  "rohde": ['R OW1 D', 'R OW1 D AH0'],
  "rohe": ['R OW1'],
  "roher": ['R OW1 ER0'],
  "rohl": ['R OW1 L'],
  "rohland": ['R OW1 L AH0 N D'],
  "rohleder": ['R OW1 L IH0 D ER0'],
  "rohlf": ['R OW1 L F'],
  "rohlfing": ['R OW1 L F IH0 NG'],
  "rohlfs": ['R OW1 L F S'],
  "rohlicek": ['R AA1 L AH0 CH EH0 K'],
  "rohling": ['R OW1 L IH0 NG'],
  "rohlman": ['R OW1 L M AH0 N'],
  "rohloff": ['R OW1 L AO0 F'],
  "rohm": ['R OW1 M'],
  "rohman": ['R OW1 M AH0 N'],
  "rohmer": ['R OW1 M ER0'],
  "rohn": ['R AA1 N'],
  "rohner": ['R OW1 N ER0'],
  "rohr": ['R AO1 R'],
  "rohrbach": ['R AO1 R B AA0 K'],
  "rohrbacher": ['R AO1 R B AA0 K ER0'],
  "rohrback": ['R AO1 R B AE0 K'],
  "rohrbaugh": ['R AO1 R B AW0'],
  "rohrbough": ['R AO1 R B AW0'],
  "rohrer": ['R AO1 R ER0'],
  "rohrich": ['R AO1 R IH0 K'],
  "rohrig": ['R AO1 R IH0 G'],
  "rohrman": ['R AO1 R M AH0 N'],
  "rohrs": ['R AO1 R Z'],
  "rohs": ['R OW1 Z'],
  "rohstoff": ['R OW1 S T AO0 F'],
  "rohwedder": ['R OW1 W IH0 D ER0'],
  "rohweder": ['R OW1 W IH0 D ER0'],
  "rohwer": ['R OW1 W ER0'],
  "rohypnol": ['R OW2 HH AY1 P N AO2 L'],
  "roi": ['R OY1'],
  "roig": ['R OY1 G'],
  "roil": ['R OY1 L'],
  "roiled": ['R OY1 L D'],
  "roiling": ['R OY1 L IH0 NG'],
  "roister": ['R OY1 S T ER0'],
  "roister's": ['R OY1 S T ER0 Z'],
  "rojas": ['R OW1 HH AA0 S'],
  "rojek": ['R OW1 Y EH0 K'],
  "rojo": ['R OW1 JH OW0'],
  "roka": ['R OW1 K AH0'],
  "rokahr": ['R OW1 K AA2 R'],
  "rokar": ['R OW1 K AA2 R'],
  "rokicki": ['R AH0 K IH1 K IY0'],
  "rokos": ['R OW1 K OW0 Z'],
  "rokosz": ['R AA1 K AH0 SH'],
  "rol": ['R OW1 L'],
  "rolan": ['R OW1 L AH0 N'],
  "roland": ['R OW1 L AH0 N D'],
  "rolanda": ['R OW0 L AA1 N D AH0'],
  "rolando": ['R OW0 L AA1 N D OW0'],
  "roldan": ['R OW1 L D AH0 N'],
  "role": ['R OW1 L'],
  "rolemodel": ['R OW1 L M AA2 D AH0 L'],
  "rolemodels": ['R OW1 L M AA2 D AH0 L Z'],
  "rolen": ['R OW1 L AH0 N'],
  "roleplaying": ['R OW1 L P L EY2 IH0 NG'],
  "roles": ['R OW1 L Z'],
  "rolette": ['R OW0 L EH1 T'],
  "rolex": ['R OW1 L EH0 K S'],
  "roley": ['R OW1 L IY0'],
  "rolf": ['R OW1 L F'],
  "rolfe": ['R OW1 L F'],
  "rolfes": ['R OW1 L F S'],
  "rolfs": ['R OW1 L F S'],
  "rolfson": ['R OW1 L F S AH0 N'],
  "rolin": ['R OW1 L IH0 N'],
  "roling": ['R OW1 L IH0 NG'],
  "rolison": ['R AA1 L IH0 S AH0 N'],
  "roll": ['R OW1 L'],
  "roll's": ['R OW1 L Z'],
  "rolla": ['R AA1 L AH0'],
  "rolland": ['R AA1 L AH0 N D'],
  "rolland's": ['R OW1 L AH0 N D Z'],
  "rollback": ['R OW1 L B AE2 K'],
  "rollbacks": ['R OW1 L B AE2 K S'],
  "rolle": ['R OW1 L'],
  "rolled": ['R OW1 L D'],
  "rollei": ['R OW1 L EY0'],
  "rollen": ['R AO1 L AH0 N'],
  "roller": ['R OW1 L ER0'],
  "rollerblade": ['R OW1 L ER0 B L EY2 D'],
  "rollerblades": ['R OW1 L ER0 B L EY2 D Z'],
  "rollerblading": ['R OW1 L ER0 B L EY2 D IH0 NG'],
  "rollercoaster": ['R OW1 L ER0 K OW2 S T ER0'],
  "rollers": ['R OW1 L ER0 Z'],
  "rollet": ['R OW0 L EH1 T'],
  "rolley": ['R AA1 L IY0'],
  "rolli": ['R AA1 L IY0'],
  "rollicking": ['R AA1 L IH0 K IH0 NG'],
  "rollie": ['R OW1 L IY0', 'R AO1 L IY0'],
  "rollin": ['R AA1 L IH0 N'],
  "rollin'": ['R OW1 L IH0 N'],
  "rolling": ['R OW1 L IH0 NG'],
  "rollinger": ['R OW1 L IH0 NG ER0'],
  "rollings": ['R OW1 L IH0 NG Z'],
  "rollins": ['R AA1 L IH0 N Z'],
  "rollins'": ['R AA1 L IH0 N Z'],
  "rollins's": ['R AA1 L IH0 N Z IH0 Z'],
  "rollinson": ['R AA1 L IH0 N S AH0 N'],
  "rollison": ['R AA1 L IH0 S AH0 N'],
  "rollman": ['R OW1 L M AH0 N'],
  "rollo": ['R AA1 L OW0'],
  "rollout": ['R OW1 L AW2 T'],
  "rollover": ['R OW1 L OW2 V ER0'],
  "rollovers": ['R OW1 L OW2 V ER0 Z'],
  "rolls": ['R OW1 L Z'],
  "rolls's": ['R OW1 L Z IH0 Z'],
  "rolltop": ['R OW1 L T AA2 P'],
  "rollwagen": ['R OW1 L W AE2 G AH0 N'],
  "rolly": ['R OW1 L IY0'],
  "rollyson": ['R AA1 L IH0 S AH0 N'],
  "rolm": ['R OW1 M'],
  "rolnick": ['R OW1 L N IH0 K'],
  "rolodex": ['R OW1 L AH0 D EH2 K S'],
  "roloff": ['R AA1 L AO0 F'],
  "rolon": ['R OW1 L AH0 N'],
  "rolph": ['R OW1 L F'],
  "rolston": ['R OW1 L S T AH0 N'],
  "rolt": ['R OW1 L T'],
  "rom": ['R AA1 M'],
  "rom's": ['R AA1 M Z'],
  "roma": ['R OW1 M AA0'],
  "roma's": ['R OW1 M AA0 Z'],
  "romack": ['R AA1 M AH0 K'],
  "romagnoli": ['R OW0 M AA0 G N OW1 L IY0'],
  "romagnolo": ['R OW2 M AA0 N Y OW1 L OW0'],
  "romain": ['R OW0 M EY1 N'],
  "romaine": ['R OW0 M EY1 N'],
  "roman": ['R OW1 M AH0 N'],
  "roman's": ['R OW1 M AH0 N Z'],
  "romance": ['R OW0 M AE1 N S', 'R OW1 M AE0 N S'],
  "romances": ['R OW0 M AE1 N S IH0 Z'],
  "romancing": ['R OW0 M AE1 N S IH0 NG'],
  "romanek": ['R AA1 M AH0 N IH0 K'],
  "romanelli": ['R OW0 M AA0 N EH1 L IY0'],
  "romanello": ['R OW0 M AA0 N EH1 L OW0'],
  "romanesque": ['R OW2 M AH0 N EH1 S K'],
  "romani": ['R OW0 M AA1 N IY0'],
  "romania": ['R OW0 M EY1 N IY0 AH0'],
  "romania's": ['R OW0 M EY1 N IY0 AH0 Z'],
  "romanian": ['R OW0 M EY1 N IY0 AH0 N'],
  "romanians": ['R OW0 M EY1 N IY0 AH0 N Z'],
  "romaniello": ['R OW0 M AA0 N IY0 EH1 L OW0'],
  "romanik": ['R AH0 M AE1 N IH0 K'],
  "romann": ['R OW1 M AH0 N'],
  "romano": ['R OW0 M AA1 N OW2'],
  "romano's": ['R OW0 M AA1 N OW2 Z'],
  "romanoff": ['R OW1 M AH0 N AO2 F'],
  "romanone": ['R OW0 M AA0 N OW1 N'],
  "romanones": ['R OW0 M AA0 N OW1 N Z'],
  "romanoski": ['R AH0 M AH0 N AW1 S K IY0'],
  "romanov": ['R OW1 M AH0 N AO2 V', 'R OW1 M AH0 N AO2 F'],
  "romanow": ['R AH0 M AE1 N OW0'],
  "romanowski": ['R AH0 M AH0 N AO1 F S K IY0'],
  "romans": ['R OW1 M AH0 N Z'],
  "romanski": ['R AH0 M AE1 N S K IY0'],
  "romantic": ['R OW0 M AE1 N T IH0 K'],
  "romantically": ['R OW0 M AE1 N T IH0 K AH0 L IY0', 'R OW0 M AE1 N T IH0 K L IY0'],
  "romanticism": ['R OW0 M AE1 N T AH0 S IH2 Z AH0 M'],
  "romanticize": ['R OW0 M AE1 N T AH0 S AY2 Z'],
  "romanticized": ['R OW0 M AE1 N T AH0 S AY2 Z D'],
  "romanticizing": ['R OW0 M AE1 N T AH0 S AY2 Z IH0 NG'],
  "romantics": ['R OW0 M AE1 N T IH0 K S'],
  "romany": ['R AA1 M AH0 N IY0', 'R OW1 M AH0 N IY0'],
  "romas": ['R OW1 M AH0 Z'],
  "rombach": ['R AA1 M B AA2 K'],
  "romberg": ['R AA1 M B ER0 G'],
  "romberger": ['R AA1 M B ER0 G ER0'],
  "rome": ['R OW1 M'],
  "rome's": ['R OW1 M Z'],
  "romel": ['R OW1 M AH0 L'],
  "romella": ['R OW0 M EH1 L AH0'],
  "romelle": ['R AH0 M EH1 L'],
  "romeo": ['R OW1 M IY0 OW2'],
  "romeo's": ['R OW1 M IY0 OW2 Z'],
  "romeos": ['R OW1 M IY0 OW2 Z'],
  "romer": ['R OW1 M ER0'],
  "romero": ['R OW0 M EH1 R OW0'],
  "romes": ['R OW1 M Z'],
  "romesburg": ['R OW1 M Z B ER0 G'],
  "romey": ['R OW1 M IY0'],
  "romick": ['R AA1 M IH0 K'],
  "romig": ['R OW1 M IH0 G'],
  "romilda": ['R AH0 M IY1 L D AH0'],
  "romina": ['R AH0 M IY1 N AH0'],
  "romine": ['R AH0 M IY1 N IY0', 'R AA1 M IH0 N'],
  "romines": ['R AA1 M IH0 N Z', 'R AH0 M IY1 N IY0 Z'],
  "rominger": ['R OW1 M IH0 NG ER0'],
  "romito": ['R OW0 M IY1 T OW0'],
  "romley": ['R AA1 M L IY0'],
  "romm": ['R AA1 M'],
  "rommel": ['R AA1 M AH0 L'],
  "romney": ['R AA1 M N IY0'],
  "romney's": ['R AA1 M N IY0 Z'],
  "romo": ['R OW1 M OW0'],
  "romola": ['R AA1 M AH0 L AH0'],
  "romp": ['R AA1 M P'],
  "romping": ['R AA1 M P IH0 NG'],
  "roms": ['R AA1 M Z'],
  "romuald": ['R OW2 M Y UW0 AA1 L D'],
  "romulus": ['R AA1 M Y AH0 L AH0 S'],
  "ron": ['R AA1 N'],
  "ron's": ['R AA1 N Z'],
  "rona": ['R OW1 N AH0'],
  "ronald": ['R AA1 N AH0 L D'],
  "ronalda": ['R OW0 N AA1 L D AH0'],
  "ronaldo": ['R OW0 N AA1 L D OW0'],
  "ronan": ['R OW1 N AH0 N'],
  "ronan's": ['R OW1 N AH0 N Z'],
  "ronayne": ['R AA1 N EY2 N'],
  "ronca": ['R OW1 N K AH0'],
  "ronco": ['R OW1 N K OW0'],
  "ronda": ['R AA1 N D AH0'],
  "rondeau": ['R AA0 N D OW1'],
  "rondinelli": ['R OW0 N D IY0 N EH1 L IY0'],
  "rondon": ['R AA1 N D AH0 N'],
  "rondonia": ['R AA0 N D OW1 N IY0 AH0'],
  "rone": ['R OW1 N'],
  "ronen": ['R OW1 N AH0 N'],
  "roney": ['R OW1 N IY0'],
  "rong": ['R AO1 NG'],
  "rongji": ['R AO1 NG JH IY0'],
  "roni": ['R OW1 N IY0'],
  "ronin": ['R OW1 N IH0 N'],
  "ronk": ['R AA1 NG K'],
  "ronko": ['R AA1 NG K OW0'],
  "ronna": ['R AA1 N AH0'],
  "ronne": ['R AA1 N'],
  "ronnie": ['R AA1 N IY0'],
  "ronning": ['R AA1 N IH0 NG'],
  "ronny": ['R AA1 N IY0'],
  "ronquillo": ['R OW0 N K W IH1 L OW0'],
  "ronson": ['R AA1 N S AH0 N'],
  "ronstadt": ['R AA1 N S T AE2 T'],
  "rood": ['R UW1 D'],
  "roode": ['R UW1 D'],
  "roof": ['R UW1 F', 'R UH1 F'],
  "roofed": ['R UW1 F T'],
  "roofer": ['R UW1 F ER0'],
  "roofers": ['R UW1 F ER0 Z'],
  "roofing": ['R UW1 F IH0 NG'],
  "roofs": ['R UW1 F S'],
  "rooftop": ['R UW1 F T AA2 P'],
  "rooftops": ['R UW1 F T AA2 P S'],
  "rooftree": ['R UW1 F T R IY2'],
  "rook": ['R UH1 K'],
  "rookard": ['R UH1 K ER0 D'],
  "rooke": ['R UH1 K'],
  "rooker": ['R UH1 K ER0'],
  "rookie": ['R UH1 K IY0'],
  "rookies": ['R UH1 K IY0 Z'],
  "rooks": ['R UH1 K S'],
  "rookstool": ['R UH1 K S T UW2 L'],
  "room": ['R UW1 M'],
  "room's": ['R UW1 M Z'],
  "roome": ['R UW1 M'],
  "roomful": ['R UW1 M F UH2 L'],
  "roomier": ['R UW1 M IY0 ER0'],
  "roominess": ['R UW1 M IY0 N AH0 S'],
  "rooming": ['R UW1 M IH0 NG'],
  "roommate": ['R UW1 M EY2 T'],
  "roommates": ['R UW1 M EY2 T S'],
  "rooms": ['R UW1 M Z'],
  "roomy": ['R UW1 M IY0'],
  "roone": ['R UW1 N'],
  "rooney": ['R UW1 N IY0'],
  "rooney's": ['R UW1 N IY0 Z'],
  "roop": ['R UW1 P'],
  "roope": ['R UW1 P'],
  "roorda": ['R UH1 R D AH0'],
  "roos": ['R UW1 Z'],
  "roosa": ['R UW1 S AH0'],
  "roose": ['R UW1 Z'],
  "roosevelt": ['R OW1 Z AH0 V EH2 L T', 'R UW1 Z AH0 V EH2 L T'],
  "roosevelt's": ['R OW1 Z AH0 V EH2 L T S', 'R UW1 Z AH0 V EH2 L T S'],
  "roosevelts": ['R OW1 Z AH0 V EH2 L T S', 'R UW1 Z AH0 V EH2 L T S'],
  "roost": ['R UW1 S T'],
  "roosted": ['R UW1 S T IH0 D'],
  "rooster": ['R UW1 S T ER0'],
  "roosters": ['R UW1 S T ER0 Z'],
  "roosting": ['R UW1 S T IH0 NG'],
  "root": ['R UW1 T'],
  "rooted": ['R UW1 T AH0 D', 'R UW1 T IH0 D'],
  "rooter": ['R UW1 T ER0'],
  "rooters": ['R UW1 T ER0 Z'],
  "rooting": ['R UW1 T IH0 NG'],
  "rootkit": ['R UW1 T K IH2 T'],
  "rootless": ['R UW1 T L AH0 S'],
  "roots": ['R UW1 T S'],
  "rootstein": ['R UW1 T S T AY2 N'],
  "ropak": ['R OW1 P AE0 K'],
  "rope": ['R OW1 P'],
  "roped": ['R OW1 P T'],
  "roper": ['R OW1 P ER0'],
  "roper's": ['R OW1 P ER0 Z'],
  "ropers": ['R OW1 P ER0 Z'],
  "ropes": ['R OW1 P S'],
  "roping": ['R OW1 P IH0 NG'],
  "ropp": ['R AA1 P'],
  "roppolo": ['R OW0 P OW1 L OW0'],
  "rops": ['R AA1 P S'],
  "roque": ['R OW1 K'],
  "roquemore": ['R OW0 K M AO1 R'],
  "rorabaugh": ['R AO1 R AH0 B AO0'],
  "rorer": ['R AO1 R ER0'],
  "rorer's": ['R AO1 R ER0 Z'],
  "rorex": ['R AO1 R EH0 K S'],
  "rorick": ['R AO1 R IH0 K'],
  "rorie": ['R AO1 R IY0'],
  "rork": ['R AO1 R K'],
  "rorke": ['R AO1 R K'],
  "rorrer": ['R AO1 ER0 R'],
  "rorschach": ['R AO1 R SH AA2', 'R AO1 R SH AA2 CH'],
  "rory": ['R AO1 R IY0'],
  "ros": ['R AO1 S'],
  "rosa": ['R OW1 Z AH0'],
  "rosa's": ['R OW1 Z AH0 Z'],
  "rosabel": ['R OW1 Z AH0 B EH2 L'],
  "rosabelle": ['R AA1 S AH0 B AH0 L'],
  "rosado": ['R AH0 S AA1 D OW0'],
  "rosalee": ['R OW2 S AH0 L IY1', 'R OW2 Z AH0 L IY1'],
  "rosales": ['R OW1 S EY0 L Z'],
  "rosalez": ['R OW0 S AA1 L EH0 Z'],
  "rosalia": ['R OW0 Z AH0 L IY1 AH0'],
  "rosalie": ['R OW1 Z AH0 L IY0'],
  "rosalind": ['R AA1 Z AH0 L IH0 N D'],
  "rosalinda": ['R OW0 S AA0 L IY1 N D AH0'],
  "rosalyn": ['R AA1 Z AH0 L IH0 N'],
  "rosalyn's": ['R AA1 Z AH0 L IH0 N Z'],
  "rosalynd": ['R AA1 Z AH0 L IH0 N D'],
  "rosalynn": ['R OW1 Z AH0 L IH2 N'],
  "rosamilia": ['R OW0 Z AA0 M IY1 L IY0 AH0'],
  "rosamond": ['R OW1 Z AH0 M AH0 N D'],
  "rosamund": ['R OW0 Z AH0 M UH1 N D'],
  "rosan": ['R OW1 Z AH0 N'],
  "rosander": ['R OW0 Z AE1 N D ER0'],
  "rosanna": ['R OW0 Z AE1 N AH0'],
  "rosanne": ['R OW0 Z AE1 N'],
  "rosano": ['R OW0 Z AA1 N OW0'],
  "rosaria": ['R OW0 Z AA1 R IY0 AH0'],
  "rosaria's": ['R OW0 Z AA1 R IY0 AH0 Z'],
  "rosaries": ['R OW1 Z ER0 IY0 Z'],
  "rosario": ['R OW0 Z AA1 R IY0 OW0'],
  "rosary": ['R OW1 Z ER0 IY0'],
  "rosas": ['R OW1 Z AH0 Z'],
  "rosasco": ['R OW0 Z AA1 S K OW0'],
  "rosati": ['R OW0 Z AA1 T IY0'],
  "rosato": ['R OW0 Z AA1 T OW0'],
  "rosberg": ['R AA1 S B ER0 G'],
  "rosborough": ['R AA1 S B ER0 OW0'],
  "rosch": ['R AO1 SH'],
  "roscher": ['R AO1 SH ER0'],
  "roscoe": ['R AA1 S K OW0'],
  "rose": ['R OW1 Z'],
  "rose's": ['R OW1 Z IH0 Z'],
  "roseanne": ['R OW2 Z AE1 N'],
  "roseanne's": ['R OW2 Z AE1 N Z'],
  "roseate": ['R OW1 Z IY0 AH0 T'],
  "roseberry": ['R OW1 Z B EH2 R IY0'],
  "roseboom": ['R OW1 Z B UW2 M'],
  "roseboro": ['R OW0 Z B ER1 OW0'],
  "roseborough": ['R OW1 Z B ER2 OW0'],
  "rosebrock": ['R OW1 Z B R AH0 K'],
  "rosebrook": ['R OW1 Z B R UH2 K'],
  "rosebrough": ['R OW1 Z B R AW0'],
  "rosebud": ['R OW1 Z B AH0 D'],
  "rosebush": ['R OW1 Z B UH2 SH'],
  "rosecrans": ['R OW1 Z K R AE2 N Z'],
  "rosekrans": ['R OW1 Z K R AH0 N Z'],
  "rosel": ['R OW1 Z AH0 L'],
  "roseland": ['R OW1 Z L AH0 N D'],
  "roselawn": ['R OW1 Z L AO2 N'],
  "roselawn's": ['R OW1 Z L AO2 N Z'],
  "roseline": ['R OW1 Z L AY2 N'],
  "rosell": ['R OW1 Z AH0 L'],
  "rosella": ['R OW0 Z EH1 L AH0'],
  "rosellen": ['R OW1 Z AH0 L AH0 N'],
  "roselli": ['R OW0 S EH1 L IY0'],
  "rosello": ['R OW0 S EH1 L OW0'],
  "roseman": ['R OW1 Z M AH0 N'],
  "rosemarie": ['R OW2 Z M ER0 IY1'],
  "rosemary": ['R OW1 Z M EH2 R IY0'],
  "rosemead": ['R OW1 Z M IY2 D'],
  "rosemeyer": ['R OW1 Z M AY0 ER0'],
  "rosemond": ['R OW1 Z M AH0 N D'],
  "rosemont": ['R OW1 Z M AA2 N T'],
  "rosen": ['R OW1 Z AH0 N'],
  "rosen's": ['R OW1 Z AH0 N Z'],
  "rosenau": ['R OW1 Z N AW0'],
  "rosenbach": ['R OW1 Z AH0 N B AA2 K'],
  "rosenbalm": ['R OW1 Z AH0 N B AA2 M'],
  "rosenbaum": ['R OW1 Z AH0 N B AW2 M'],
  "rosenbeck": ['R OW1 Z AH0 N B EH2 K'],
  "rosenberg": ['R OW1 Z AH0 N B ER0 G'],
  "rosenberg's": ['R OW1 Z AH0 N B ER0 G Z'],
  "rosenberger": ['R OW1 Z AH0 N B ER0 G ER0'],
  "rosenbergs": ['R OW1 Z AH0 N B ER0 G Z'],
  "rosenberry": ['R OW1 Z AH0 N B EH2 R IY0'],
  "rosenblatt": ['R OW1 Z AH0 N B L AE2 T'],
  "rosenblatts": ['R OW1 Z AH0 N B L AE2 T S'],
  "rosenbloom": ['R OW1 Z AH0 N B L UW2 M'],
  "rosenblum": ['R OW1 Z AH0 N B L UW2 M'],
  "rosenbluth": ['R OW1 Z AH0 N B L UW0 TH'],
  "rosenboom": ['R OW1 Z AH0 N B UW2 M'],
  "rosenburg": ['R OW1 Z AH0 N B ER0 G'],
  "rosencrans": ['R OW1 Z AH0 N K R AE0 N Z'],
  "rosendahl": ['R OW1 Z AH0 N D AA2 L'],
  "rosendale": ['R OW1 Z AH0 N D EY2 L'],
  "rosene": ['R AA1 S IY0 N'],
  "rosener": ['R OW1 Z AH0 N ER0'],
  "rosenfeld": ['R OW1 Z AH0 N F EH2 L D'],
  "rosenfield": ['R OW1 Z AH0 N F IY2 L D'],
  "rosengarten": ['R OW1 Z AH0 N G AA2 R T AH0 N'],
  "rosengrant": ['R OW1 Z AH0 N G R AE2 N T'],
  "rosengren": ['R OW1 Z AH0 N G R EH0 N'],
  "rosenhaus": ['R OW1 Z AH0 N HH AW2 S'],
  "rosenkrans": ['R OW1 Z AH0 N K R AE2 N Z'],
  "rosenkrantz": ['R OW1 Z AH0 N K R AE2 N T S'],
  "rosenkranz": ['R OW1 Z AH0 N K R AE2 N T S'],
  "rosenlund": ['R OW1 Z AH0 N L AH0 N D'],
  "rosenman": ['R OW1 Z AH0 N M AH0 N'],
  "rosenow": ['R OW1 Z AH0 N AW0'],
  "rosenquist": ['R OW1 Z AH0 N K W IH0 S T'],
  "rosenshine": ['R OW1 Z AH0 N SH AY2 N'],
  "rosensteel": ['R OW1 Z AH0 N S T IY2 L'],
  "rosenstein": ['R OW1 Z AH0 N S T AY2 N', 'R OW1 Z AH0 N S T IY2 N'],
  "rosenstiel": ['R OW1 Z AH0 N S T IY2 L'],
  "rosenstock": ['R OW1 Z AH0 N S T AA2 K'],
  "rosensweig": ['R OW1 Z AH0 N S W AY0 G'],
  "rosenthal": ['R OW1 Z AH0 N TH AO2 L'],
  "rosentreter": ['R OW1 Z AH0 N T R EH2 T ER0'],
  "rosenwald": ['R OW1 Z AH0 N W AO2 L D'],
  "rosenwasser": ['R OW1 Z AH0 N W AO0 S ER0'],
  "rosenzweig": ['R OW1 Z AH0 N Z W AY0 G'],
  "roser": ['R OW1 Z ER0'],
  "roses": ['R OW1 Z IH0 Z'],
  "rosete": ['R AA1 S IY0 T'],
  "rosett": ['R OW1 Z AH0 T'],
  "rosetta": ['R OW0 Z EH1 T AH0'],
  "rosette": ['R AH0 S EH1 T'],
  "rosettes": ['R OW1 Z EH1 T S'],
  "rosetti": ['R OW0 S EH1 T IY0'],
  "rosevear": ['R OW0 Z V IH1 R'],
  "roseville": ['R OW1 Z V IH2 L'],
  "rosewicz": ['R OW1 Z W IH2 T S'],
  "rosewood": ['R OW1 Z W UH2 D'],
  "rosewood's": ['R OW1 Z W UH2 D Z'],
  "rosey": ['R OW1 Z IY0'],
  "rosh": ['R AO1 SH'],
  "roshier": ['R OW1 SH Y ER0'],
  "rosiak": ['R AA1 S IY0 AE0 K'],
  "rosie": ['R OW1 Z IY0'],
  "rosie's": ['R OW1 Z IY0 Z'],
  "rosier": ['R OW1 Z IY0 ER0'],
  "rosin": ['R AA1 Z AH0 N'],
  "rosina": ['R OW0 Z IY1 N AH0'],
  "rosine": ['R OW0 S IY1 N IY0'],
  "rosing": ['R OW1 Z IH0 NG'],
  "rosinski": ['R AH0 S IH1 N S K IY0'],
  "rosinsky": ['R AH0 S IH1 N S K IY0'],
  "rosita": ['R OW0 Z IY1 T AH0'],
  "roskam": ['R AA1 S K AH0 M'],
  "roske": ['R OW1 S K'],
  "rosko": ['R OW1 S K OW0'],
  "roskos": ['R OW1 S K OW0 Z'],
  "roslin": ['R AA1 S L IH0 N'],
  "roslund": ['R AO1 Z L AH0 N D'],
  "roslyn": ['R AA1 Z L IH0 N'],
  "rosman": ['R AA1 S M AH0 N'],
  "rosmunda": ['R OW0 S M UW1 N D AH0'],
  "rosneft": ['R AA1 Z N IH0 F T'],
  "rosner": ['R AA1 S N ER0'],
  "rosoff": ['R AA1 S AO0 F'],
  "rosol": ['R OW1 S AO0 L'],
  "rospatch": ['R AO1 S P AE0 CH'],
  "ross": ['R AA1 S', 'R AO1 S'],
  "ross'": ['R AA1 S'],
  "ross's": ['R AA1 S IH0 Z'],
  "rossa": ['R OW1 S AH0'],
  "rossano": ['R OW0 S AA1 N OW0'],
  "rossbach": ['R AA1 S B AA0 K'],
  "rossborough": ['R AO1 S B ER0 OW0'],
  "rosse": ['R AA1 S'],
  "rosselin": ['R AA1 S IH0 L IH0 N'],
  "rossell": ['R AA1 S AH0 L'],
  "rosselli": ['R OW0 S EH1 L IY0'],
  "rosselot": ['R AA1 S IH0 L AH0 T'],
  "rossen": ['R AO1 S AH0 N'],
  "rosser": ['R AO1 S ER0'],
  "rossetti": ['R OW0 S EH1 T IY0'],
  "rossetto": ['R OW0 S EH1 T OW0'],
  "rossi": ['R AO1 S IY0'],
  "rossie": ['R AO1 S IY0'],
  "rossignol": ['R AA1 S IH0 G N AO0 L'],
  "rossin": ['R AA1 S IH0 N'],
  "rossing": ['R AO1 S IH0 NG'],
  "rossini": ['R OW0 S IY1 N IY0'],
  "rossiter": ['R AO1 S IH0 T ER0'],
  "rossiya": ['R AO1 S IY0 AH0'],
  "rossler": ['R AA1 S AH0 L ER0', 'R AA1 S L ER0'],
  "rosslyn": ['R AA1 S L IH0 N'],
  "rossman": ['R AO1 S M AH0 N'],
  "rossmann": ['R AO1 S M AH0 N'],
  "rossmiller": ['R AA1 S M IH0 L ER0'],
  "rossmore": ['R AA1 S M AO2 R'],
  "rossner": ['R AA1 S N ER0'],
  "rosso": ['R OW1 S OW0'],
  "rosson": ['R AA1 S AH0 N'],
  "rossow": ['R AA1 S OW0'],
  "rost": ['R AA1 S T'],
  "rostad": ['R AA1 S T AH0 D'],
  "rosten": ['R AA1 S AH0 N'],
  "rostenkowski": ['R AO2 S T EH0 NG K AW1 S K IY0'],
  "rostenkowski's": ['R AO2 S T EH0 NG K AW1 S K IY0 Z'],
  "roster": ['R AA1 S T ER0'],
  "rosters": ['R AA1 S T ER0 Z'],
  "roston": ['R AA1 S T AH0 N'],
  "rostov": ['R AA1 S T AA0 V'],
  "rostovondon": ['R AA0 S T AA1 V AH0 D AA0 N'],
  "rostron": ['R AA1 S T R AH0 N'],
  "rostropovich": ['R AO2 S T R AH0 P OW1 V IH0 CH', 'R AH0 S T R AA1 P AH0 V IH0 CH'],
  "rostrum": ['R AA1 S T R AH0 M'],
  "rostuca": ['R AO2 S T UW1 K AH0'],
  "rosty": ['R AO1 S T IY0'],
  "roswald": ['R AA1 S W AH0 L D'],
  "roswell": ['R AA1 S W EH0 L'],
  "rosy": ['R OW1 Z IY0'],
  "roszak": ['R AA1 SH AH0 K'],
  "roszell": ['R AA1 SH AH0 L'],
  "roszkowski": ['R AH0 SH K AO1 F S K IY0'],
  "rot": ['R AA1 T'],
  "rota": ['R OW1 T AH0'],
  "rotan": ['R OW1 T AH0 N'],
  "rotary": ['R OW1 T ER0 IY0'],
  "rotate": ['R OW1 T EY2 T'],
  "rotated": ['R OW1 T EY2 T IH0 D'],
  "rotates": ['R OW1 T EY2 T S'],
  "rotating": ['R OW1 T EY2 T IH0 NG'],
  "rotation": ['R OW0 T EY1 SH AH0 N'],
  "rotational": ['R OW0 T EY1 SH AH0 N AH0 L'],
  "rotationally": ['R OW0 T EY1 SH AH0 N AH0 L IY0'],
  "rotations": ['R OW0 T EY1 SH AH0 N Z'],
  "rotberg": ['R AA1 T B ER0 G'],
  "rotblat": ['R AA1 T B L AE0 T'],
  "rote": ['R OW1 T'],
  "rotea": ['R OW0 T IY1 AH0'],
  "rotella": ['R OW0 T EH1 L AH0'],
  "roten": ['R OW1 T AH0 N'],
  "rotenberg": ['R OW1 T AH0 N B ER0 G'],
  "rotenberry": ['R OW1 T AH0 N B EH2 R IY0'],
  "rotert": ['R AA1 T ER0 T'],
  "roth": ['R AO1 TH'],
  "roth's": ['R AA1 TH S'],
  "rothacker": ['R AO1 TH AH0 K ER0'],
  "rothbard": ['R AO1 TH B ER0 D'],
  "rothbart": ['R AO1 TH B AA2 R T'],
  "rothbauer": ['R AO1 TH B AW0 ER0'],
  "rothberg": ['R AO1 TH B ER0 G'],
  "rothchild": ['R AO1 TH CH AY2 L D'],
  "rothe": ['R OW1 DH'],
  "rothenberg": ['R AO1 TH AH0 N B ER0 G'],
  "rothenberger": ['R AO1 TH AH0 N B ER0 G ER0'],
  "rother": ['R AO1 TH ER0'],
  "rothermel": ['R AA1 TH ER0 M AH0 L'],
  "rothert": ['R AA1 TH ER0 T'],
  "rothery": ['R AA1 TH ER0 IY0'],
  "rothfuss": ['R AO1 TH F AH0 S'],
  "rothgeb": ['R AO1 TH G IH0 B'],
  "rothkopf": ['R AO1 TH K AO0 P F', 'R AO1 TH K AO0 F'],
  "rothlisberger": ['R AO1 TH L IH0 S B ER0 G ER0'],
  "rothman": ['R AO1 TH M AH0 N'],
  "rothmans": ['R AO1 TH M AH0 N Z'],
  "rothmans's": ['R AO1 TH M AH0 N Z IH0 Z'],
  "rothmeier": ['R AO1 TH M AY2 R'],
  "rothrock": ['R AO1 TH R AH0 K'],
  "roths": ['R AO1 TH S'],
  "rothschild": ['R AO1 TH S CH AY2 L D'],
  "rothschild's": ['R AO1 TH CH AY2 L D Z'],
  "rothschilds": ['R AO1 TH CH AY2 L D Z'],
  "rothstein": ['R AO1 TH S T AY0 N', 'R AO1 TH S T IY0 N'],
  "rothwax": ['R AO1 TH W AE2 K S'],
  "rothweiler": ['R AO1 TH W AY2 L ER0'],
  "rothwell": ['R AO1 TH W EH2 L'],
  "rothwells": ['R AO1 TH W EH2 L Z'],
  "roti": ['R OW1 T IY0'],
  "rotisserie": ['R OW2 T IH1 S ER0 IY0'],
  "rotman": ['R AA1 T M AH0 N'],
  "roto": ['R OW1 T OW0'],
  "rotolo": ['R OW0 T OW1 L OW0'],
  "rotondi": ['R OW0 T OW1 N D IY0'],
  "rotondo": ['R OW0 T OW1 N D OW0'],
  "rotor": ['R OW1 T ER0'],
  "rotors": ['R OW1 T ER0 Z'],
  "rototill": ['R OW1 T AH0 T IH2 L', 'R OW1 T OW0 T IH2 L'],
  "rototilled": ['R OW1 T AH0 T IH2 L D', 'R OW1 T OW0 T IH2 L D'],
  "rototiller": ['R OW1 T AH0 T IH2 L ER0', 'R OW1 T OW0 T IH2 L ER0'],
  "rototilles": ['R OW1 T AH0 T IH2 L Z', 'R OW1 T OW0 T IH2 L Z'],
  "rototilling": ['R OW1 T AH0 T IH2 L IH0 NG', 'R OW1 T OW0 T IH2 L IH0 NG'],
  "rotramel": ['R AA1 T R AH0 M AH0 L'],
  "rotruck": ['R AA1 T R AH0 K'],
  "rots": ['R AA1 T S'],
  "rott": ['R AA1 T'],
  "rotted": ['R AA1 T IH0 D'],
  "rotten": ['R AA1 T AH0 N'],
  "rottenberg": ['R AA1 T AH0 N B ER0 G'],
  "rotter": ['R AA1 T ER0'],
  "rotterdam": ['R AA1 T ER0 D AE2 M'],
  "rotting": ['R AA1 T IH0 NG'],
  "rottinghaus": ['R AA1 T IH0 NG HH AW2 S'],
  "rottler": ['R AA1 T L ER0'],
  "rottman": ['R AA1 T M AH0 N'],
  "rottmann": ['R AA1 T M AH0 N'],
  "rottweiler": ['R AA1 T W AY0 L ER0'],
  "rottweilers": ['R AA1 T W AY0 L ER0 Z'],
  "rotund": ['R OW0 T AH1 N D'],
  "rotunda": ['R OW0 T AH1 N D AH0'],
  "rotundo": ['R OW0 T UW1 N D OW0'],
  "rotunno": ['R OW0 T UW1 N OW0'],
  "rotz": ['R AA1 T S'],
  "roubal": ['R UW0 B AE1 L'],
  "rouble": ['R UW1 B AH0 L'],
  "rouch": ['R AW1 CH'],
  "roudabush": ['R AW1 D AH0 B UH0 SH'],
  "roudebush": ['R AW1 D IH0 B UH0 SH'],
  "rouge": ['R UW1 ZH'],
  "rougeau": ['R UW0 ZH OW1'],
  "rough": ['R AH1 F'],
  "rough's": ['R AH1 F S'],
  "roughed": ['R AH1 F T'],
  "rougher": ['R AH1 F ER0'],
  "roughest": ['R AH1 F AH0 S T'],
  "roughing": ['R AH1 F IH0 NG'],
  "roughly": ['R AH1 F L IY0'],
  "roughneck": ['R AH1 F N EH2 K'],
  "roughnecks": ['R AH1 F N EH2 K S'],
  "roughness": ['R AH1 F N AH0 S'],
  "roughshod": ['R AH1 F SH AA1 D'],
  "rought": ['R AO1 T'],
  "roughton": ['R AH1 F T AH0 N'],
  "rouillard": ['R W IY0 L AA1 R D'],
  "roukema": ['R UW1 K M AA0'],
  "roukos": ['R UW1 K OW0 S'],
  "roulac": ['R UW1 L AE0 K'],
  "rouleau": ['R UW0 L OW1'],
  "roulette": ['R UW0 L EH1 T'],
  "roulette's": ['R UW0 L EH1 T S'],
  "roulettes": ['R UW0 L EH1 T S'],
  "roulhac": ['R UW1 L HH AH0 K'],
  "roulston": ['R AW1 L S T AH0 N'],
  "round": ['R AW1 N D'],
  "round's": ['R AW1 N D Z'],
  "roundabout": ['R AW1 N D AH0 B AW2 T'],
  "rounded": ['R AW1 N D AH0 D', 'R AW1 N D IH0 D'],
  "rounder": ['R AW1 N D ER0'],
  "roundhead": ['R AW1 N D HH EH2 D'],
  "roundhouse": ['R AW1 N D HH AW2 S'],
  "rounding": ['R AW1 N D IH0 NG'],
  "roundly": ['R AW1 N D L IY0'],
  "rounds": ['R AW1 N D Z', 'R AW1 N Z'],
  "roundtable": ['R AW1 N D T EY2 B AH0 L'],
  "roundtree": ['R AW1 N D T R IY2'],
  "roundtrip": ['R AW2 N D T R IH1 P'],
  "roundup": ['R AW1 N D AH2 P'],
  "roundworm": ['R AW1 N D W ER0 M'],
  "roundworms": ['R AW1 N D W ER0 M Z'],
  "roundy": ['R AW1 N D IY0'],
  "rounsaville": ['R UW1 N S AH0 V IH0 L'],
  "rountree": ['R AW0 N T R IY1'],
  "roupe": ['R UW1 P'],
  "rourk": ['R AO1 R K'],
  "rourke": ['R AO1 R K'],
  "rouse": ['R AW1 S', 'R AW1 Z'],
  "rouse's": ['R AW1 Z IH0 Z'],
  "roused": ['R AW1 Z D'],
  "rouser": ['R AW1 Z ER0'],
  "rousey": ['R AW1 S IY0'],
  "roush": ['R AW1 SH'],
  "rousing": ['R AW1 Z IH0 NG'],
  "rousse": ['R AW1 S'],
  "rousseau": ['R UW0 S OW1'],
  "roussel": ['R UW0 S EH1 L'],
  "rousselet": ['R UW1 S AH0 L EH0 T'],
  "roussell": ['R UW0 S EH1 L'],
  "rousselle": ['R UW2 S EH1 L'],
  "roussin": ['R UW0 S AE1 N'],
  "roussos": ['R UW0 S OW1 Z'],
  "rout": ['R AW1 T'],
  "route": ['R UW1 T', 'R AW1 T'],
  "routed": ['R UW1 T IH0 D', 'R AW1 T IH0 D'],
  "router": ['R UW1 T ER0', 'R AW1 T ER0'],
  "routers": ['R UW1 T ER0 Z', 'R AW1 T ER0 Z'],
  "routes": ['R UW1 T S', 'R AW1 T S', 'R UH1 T S'],
  "routh": ['R AW1 TH'],
  "routhier": ['R AW1 TH IY0 ER0'],
  "routier": ['R UW1 T IY2 ER0'],
  "routine": ['R UW0 T IY1 N'],
  "routinely": ['R UW0 T IY1 N L IY0'],
  "routines": ['R UW0 T IY1 N Z'],
  "routing": ['R AW1 T IH0 NG', 'R UW1 T IH0 NG'],
  "routledge": ['R AW1 T L IH0 JH'],
  "routon": ['R AW1 T AH0 N'],
  "routson": ['R AW1 T S AH0 N'],
  "routt": ['R AW1 T'],
  "routzahn": ['R AW1 T Z AH0 N'],
  "roux": ['R UW1'],
  "rouyn": ['R UW1 IH0 N'],
  "rouzer": ['R AW1 Z ER0'],
  "rovaniemi": ['R OW0 V AE2 N IY0 EH1 M IY0'],
  "rove": ['R OW1 V'],
  "roven": ['R OW1 V AH0 N'],
  "rover": ['R OW1 V ER0'],
  "rover's": ['R OW1 V ER0 Z'],
  "rovers": ['R OW1 V ER0 Z'],
  "roving": ['R OW1 V IH0 NG'],
  "rovings": ['R OW1 V IH0 NG Z'],
  "rovira": ['R OW0 V IH1 R AH0'],
  "rovito": ['R OW0 V IY1 T OW0'],
  "rovner": ['R AA1 V N ER0'],
  "row": ['R OW1'],
  "row's": ['R OW1 Z'],
  "rowaine": ['R OW0 EY1 N'],
  "rowaine's": ['R OW0 EY1 N Z'],
  "rowan": ['R OW1 AH0 N'],
  "rowan's": ['R OW1 AH0 N Z'],
  "rowand": ['R OW1 AH0 N D'],
  "rowboat": ['R OW1 B OW2 T'],
  "rowboats": ['R OW1 B OW2 T S'],
  "rowbotham": ['R OW1 B AH0 TH AE0 M'],
  "rowden": ['R OW1 D AH0 N'],
  "rowdies": ['R AW1 D IY0 Z'],
  "rowdiness": ['R AW1 D IY0 N AH0 S'],
  "rowdy": ['R AW1 D IY0'],
  "rowe": ['R OW1'],
  "rowe's": ['R OW1 Z'],
  "rowed": ['R OW1 D'],
  "rowell": ['R OW1 IH0 L'],
  "rowen": ['R OW1 AH0 N'],
  "rowena": ['R OW0 IY1 N AH0'],
  "rowenta": ['R OW2 EH1 N T AH0'],
  "rower": ['R OW1 ER0'],
  "rowes": ['R OW1 Z'],
  "rowin": ['R OW1 IH0 N'],
  "rowing": ['R OW1 IH0 NG'],
  "rowinski": ['R OW0 IH1 N S K IY0'],
  "rowland": ['R OW1 L AH0 N D'],
  "rowland's": ['R OW1 L AH0 N D Z'],
  "rowlands": ['R OW1 L AH0 N D Z'],
  "rowles": ['R OW1 L Z'],
  "rowlett": ['R OW1 L IH0 T'],
  "rowlette": ['R OW2 L EH1 T'],
  "rowley": ['R OW1 L IY0'],
  "rowlie": ['R OW1 L IY0'],
  "rowly": ['R OW1 L IY0'],
  "rowney": ['R OW1 N IY0'],
  "rowntree": ['R OW1 N T R IY2'],
  "rowntree's": ['R OW1 N T R IY2 Z'],
  "rowny": ['R OW1 N IY0'],
  "rows": ['R OW1 Z'],
  "rowse": ['R OW1 S'],
  "rowser": ['R OW1 Z ER0'],
  "rowsey": ['R OW1 S IY0'],
  "rowson": ['R OW1 S AH0 N'],
  "rowton": ['R OW1 T AH0 N'],
  "rox": ['R AA1 K S'],
  "rox's": ['R AA1 K S IH0 S'],
  "roxana": ['R AA0 K S AE1 N AH0'],
  "roxani": ['R AA0 K S AA1 N IY0'],
  "roxanna": ['R AA2 K S AE1 N AH0'],
  "roxanne": ['R AA1 K S IH0 N', 'R AA2 K S AE1 N'],
  "roxborough": ['R AA1 K S B AH0 R OW0'],
  "roxbury": ['R AA1 K S B EH2 R IY0'],
  "roxie": ['R AA1 K S IY0'],
  "roxine": ['R AA1 K S AY0 N'],
  "roxy": ['R AA1 K S IY0'],
  "roy": ['R OY1'],
  "roy's": ['R OY1 Z'],
  "royal": ['R OY1 AH0 L'],
  "royal's": ['R OY1 AH0 L Z'],
  "royale": ['R OY0 AE1 L'],
  "royalist": ['R OY1 AH0 L IH0 S T'],
  "royall": ['R OY1 AH0 L'],
  "royally": ['R OY1 AH0 L IY0'],
  "royals": ['R OY1 AH0 L Z'],
  "royals'": ['R OY1 AH0 L Z'],
  "royalties": ['R OY1 AH0 L T IY0 Z'],
  "royalty": ['R OY1 AH0 L T IY0'],
  "roybal": ['R OY1 B AH0 L'],
  "royce": ['R OY1 S'],
  "royce's": ['R OY1 S IH0 Z'],
  "royces": ['R OY1 S IH0 Z'],
  "roycroft": ['R OY1 K R AH0 F T'],
  "royd": ['R OY1 D'],
  "royden": ['R OY1 D AH0 N'],
  "roydon": ['R OY1 D AH0 N'],
  "roye": ['R OY1'],
  "royer": ['R OY1 ER0'],
  "royex": ['R OY1 EH0 K S'],
  "royex's": ['R OY1 EH0 K S IH0 Z'],
  "royko": ['R OY1 K OW0'],
  "roylance": ['R OY1 L AH0 N S'],
  "royle": ['R OY1 L'],
  "roys": ['R OY1 Z'],
  "royse": ['R OY1 S'],
  "royster": ['R OY1 S T ER0'],
  "royster's": ['R OY1 S T ER0 Z'],
  "royston": ['R OY1 S T AH0 N'],
  "roz": ['R AA1 Z'],
  "roza": ['R OW1 Z AH0'],
  "rozak": ['R OW1 Z AH0 K'],
  "rozanski": ['R AH0 Z AE1 N S K IY0'],
  "rozar": ['R OW1 Z ER0'],
  "rozas": ['R OW1 Z AA0 Z'],
  "rozeboom": ['R AA1 Z IH0 B UW2 M'],
  "rozek": ['R OW1 Z EH0 K'],
  "rozell": ['R AA1 Z AH0 L'],
  "rozella": ['R OW0 Z EH1 L AH0'],
  "rozelle": ['R AH0 Z EH1 L'],
  "rozema": ['R AH0 Z IY1 M AH0'],
  "rozen": ['R AA1 Z AH0 N'],
  "rozier": ['R OW1 Z IY0 ER0'],
  "rozman": ['R AA1 Z M AH0 N'],
  "roznowski": ['R AH0 Z N AO1 F S K IY0'],
  "rozsa": ['R OW1 Z S AH0'],
  "rozycki": ['R AH0 Z IH1 T S K IY0'],
  "rozzell": ['R AA1 Z AH0 L'],
  "rozzi": ['R AA1 Z IY0'],
  "rpf": ['AA1 R P IY1 EH1 F'],
  "rpm": ['AA1 R P IY1 EH1 M'],
  "rsvp": ['AA1 R EH1 S V IY1 P IY1'],
  "rte": ['AA1 R T IY1 IY1'],
  "ru": ['R UW1', 'AA1 R Y UW1'],
  "rua": ['R UW1 AH0'],
  "ruam": ['R UW1 AH0 M'],
  "ruane": ['R UW0 EY1 N'],
  "ruano": ['R UW0 AA1 N OW0'],
  "ruark": ['R UW1 AA0 R K'],
  "rub": ['R AH1 B'],
  "ruback": ['R UW1 B AE2 K'],
  "rubalcaba": ['R UW0 B AA0 L K AA1 B AH0'],
  "rubalcava": ['R UW0 B AA0 L K AA1 V AH0'],
  "rubano": ['R UW0 B AA1 N OW0'],
  "rubbed": ['R AH1 B D'],
  "rubber": ['R AH1 B ER0'],
  "rubberize": ['R AH1 B ER0 AY2 Z'],
  "rubberized": ['R AH1 B ER0 AY2 Z D'],
  "rubbermaid": ['R AH1 B ER0 M EY2 D'],
  "rubbermaid's": ['R AH1 B ER0 M EY2 D Z'],
  "rubbers": ['R AH1 B ER0 Z'],
  "rubbery": ['R AH1 B ER0 IY0'],
  "rubbia": ['R AH1 B IY0 AH0'],
  "rubbing": ['R AH1 B IH0 NG'],
  "rubbish": ['R AH1 B IH0 SH'],
  "rubble": ['R AH1 B AH0 L'],
  "rubbo": ['R UW1 B OW0'],
  "rube": ['R UW1 B'],
  "rubeck": ['R UW1 B EH2 K'],
  "rubel": ['R UW1 B AH0 L'],
  "rubell": ['R UW1 B AH0 L'],
  "rubella": ['R UW0 B EH1 L AH0'],
  "ruben": ['R UW1 B AH0 N'],
  "rubendall": ['R AH1 B IH0 N D AH0 L'],
  "rubenfeld": ['R AH1 B IH0 N F EH0 L D'],
  "rubens": ['R UW1 B AH0 N Z'],
  "rubenstein": ['R UW1 B AH0 N S T AY0 N', 'R UW1 B AH0 N S T IY0 N'],
  "ruberg": ['R UW1 B ER0 G'],
  "rubert": ['R UW1 B ER0 T'],
  "ruberto": ['R UW0 B EH1 R T OW0'],
  "rubey": ['R UW1 B IY0'],
  "rubi": ['R UW1 B IY0'],
  "rubia": ['R UW1 B IY0 AH0'],
  "rubicam": ['R UW1 B IH0 K AH0 M'],
  "rubicam's": ['R UW1 B IH0 K AH0 M Z'],
  "rubicon": ['R UW1 B IH0 K AO0 N'],
  "rubie": ['R UW1 B IY0'],
  "rubies": ['R UW1 B IY0 Z'],
  "rubik": ['R UW1 B IH0 K'],
  "rubik's": ['R UW1 B IH0 K S'],
  "rubin": ['R UW1 B IH0 N'],
  "rubin's": ['R UW1 B IH0 N Z'],
  "rubina": ['R UW0 B IY1 N AH0'],
  "rubino": ['R UW0 B IY1 N OW0'],
  "rubinson": ['R AH1 B IH0 N S AH0 N'],
  "rubinstein": ['R UW1 B IH0 N S T IY2 N', 'R UW1 B IH0 N S T AY2 N'],
  "rubio": ['R UW1 B IY0 OW0'],
  "rubios": ['R UW1 B IY0 OW0 Z'],
  "rubis": ['R UW1 B IH0 S'],
  "ruble": ['R UW1 B AH0 L'],
  "ruble's": ['R UW1 B AH0 L Z'],
  "rubles": ['R UW1 B AH0 L Z'],
  "rubley": ['R AH1 B L IY0'],
  "rubloff": ['R AH1 B L AO0 F'],
  "rubottom": ['R AH1 B AH0 T AA0 M'],
  "rubout": ['R AH1 B AW2 T'],
  "rubric": ['R UW1 B R IH0 K'],
  "rubright": ['R AH1 B R AY2 T'],
  "rubs": ['R AH1 B Z'],
  "ruby": ['R UW1 B IY0'],
  "ruby's": ['R UW1 B IY0 Z'],
  "rucci": ['R UW1 CH IY0'],
  "ruch": ['R AH1 CH'],
  "ruchlamer": ['R UW1 K L AH0 M ER0'],
  "ruchti": ['R UW1 K T IY0'],
  "rucinski": ['R AH0 CH IH1 N S K IY0'],
  "ruck": ['R AH1 K'],
  "ruckel": ['R AH1 K AH0 L'],
  "ruckelshaus": ['R AH1 K AH0 L Z HH AW2 S'],
  "rucker": ['R AH1 K ER0'],
  "ruckert": ['R AH1 K ER0 T'],
  "rucki": ['R AH1 K IY0'],
  "ruckle": ['R AH1 K AH0 L'],
  "ruckman": ['R AH1 K M AH0 N'],
  "rucks": ['R AH1 K S'],
  "rucksack": ['R AH1 K S AE2 K'],
  "ruckus": ['R AH1 K AH0 S'],
  "rud": ['R AH1 D'],
  "ruda": ['R UW1 D AH0'],
  "rudani": ['R UW0 D AA1 N IY0'],
  "rudasill": ['R AH1 D AH0 S IH0 L'],
  "rudd": ['R AH1 D'],
  "ruddell": ['R AH1 D AH0 L'],
  "rudden": ['R AH1 D AH0 N'],
  "rudder": ['R AH1 D ER0'],
  "rudderless": ['R AH1 D ER0 L AH0 S'],
  "ruddick": ['R AH1 D IH0 K'],
  "ruddle": ['R AH1 D AH0 L'],
  "ruddock": ['R AH1 D AH0 K'],
  "ruddy": ['R AH1 D IY0'],
  "rude": ['R UW1 D'],
  "rudeen": ['R AH1 D IY0 N'],
  "rudel": ['R UW1 D AH0 L'],
  "rudell": ['R AH1 D AH0 L'],
  "rudelle": ['R AH0 D EH1 L'],
  "rudely": ['R UW1 D L IY0'],
  "ruden": ['R UW1 D AH0 N'],
  "rudeness": ['R UW1 D N AH0 S'],
  "rudenstein": ['R UW1 D IH0 N S T IY0 N', 'R UW1 D IH0 N S T AY0 N'],
  "rudenstine": ['R UW1 D AH0 N Z T AY2 N'],
  "ruder": ['R UW1 D ER0'],
  "ruder's": ['R UW1 D ER0 Z'],
  "ruderman": ['R UW1 D ER0 M AH0 N'],
  "rudesill": ['R AH1 D IH0 S AH0 L'],
  "rudest": ['R UW1 D AH0 S T'],
  "rudge": ['R AH1 JH'],
  "rudi": ['R UW1 D IY0'],
  "rudi's": ['R UW1 D IY0 Z'],
  "rudich": ['R AH1 D IH0 K', 'R UW1 D IH0 K'],
  "rudicker": ['R AH1 D IH0 K ER0'],
  "rudie": ['R UW1 D IY0'],
  "rudiger": ['R AH1 D IH0 G ER0'],
  "rudiment": ['R UW1 D IH0 M AH0 N T'],
  "rudimentary": ['R UW2 D AH0 M EH1 N T ER0 IY0'],
  "rudiments": ['R UW1 D IH0 M AH0 N T S'],
  "rudin": ['R UW1 D IH0 N'],
  "ruding": ['R UW1 D IH0 NG'],
  "rudis": ['R UW1 D IY0 Z'],
  "rudisill": ['R AH1 D IH0 S IH0 L'],
  "rudkin": ['R AH1 D K IH0 N'],
  "rudloff": ['R AH1 D L AO0 F'],
  "rudman": ['R AH1 D M AH0 N'],
  "rudman's": ['R AH1 D M AH0 N Z'],
  "rudner": ['R AH1 D N ER0'],
  "rudnet": ['R AH1 D N EH2 T'],
  "rudnick": ['R AH1 D N IH0 K'],
  "rudnicki": ['R AH0 D N IH1 K IY0'],
  "rudnicky": ['R AH0 D N IH1 K IY0'],
  "rudnik": ['R AH1 D N IH0 K'],
  "rudolf": ['R UW1 D AA2 L F', 'R UW1 D AA2 F'],
  "rudolph": ['R UW1 D AO0 L F', 'R UW1 D AO0 F'],
  "rudow": ['R UW1 D OW0'],
  "rudy": ['R UW1 D IY0'],
  "rudy's": ['R UW1 D IY0 Z'],
  "rudyard": ['R AH1 D Y ER0 D'],
  "rudzinski": ['R AH0 JH IH1 N S K IY0'],
  "rue": ['R UW1'],
  "rueb": ['R UW1 B'],
  "ruebel": ['R UW1 B AH0 L'],
  "rueckert": ['R UW1 K ER0 T'],
  "rueda": ['R UW0 EH1 D AH0'],
  "ruediger": ['R UW1 D IH0 G ER0'],
  "ruedlinger": ['R UW1 D L IH0 NG ER0'],
  "ruedy": ['R UW1 D IY0'],
  "ruef": ['R UW1 F'],
  "rueff": ['R UW1 F'],
  "rueful": ['R UW1 F AH0 L'],
  "ruefully": ['R UW1 F AH0 L IY0'],
  "rueger": ['R UH1 G ER0'],
  "ruegg": ['R UW1 G'],
  "ruegsegger": ['R UH1 G S IH0 G ER0'],
  "ruehl": ['R UH1 L'],
  "ruehle": ['R UW1 HH AH0 L'],
  "ruel": ['R UW1 L'],
  "ruelas": ['R UW1 L AH0 Z'],
  "ruella": ['R UW2 EH1 L AH0'],
  "ruelle": ['R UW2 EH1 L'],
  "rues": ['R UW1 Z'],
  "ruesch": ['R UW1 SH'],
  "ruess": ['R UW1 S'],
  "ruest": ['R UW1 S T'],
  "rueter": ['R UW1 T ER0'],
  "rueth": ['R UW1 TH'],
  "ruether": ['R UW1 TH ER0'],
  "ruetz": ['R UW1 T S'],
  "ruf": ['R AH1 F'],
  "rufe": ['R UW1 F'],
  "rufenacht": ['R UW1 F AH0 N AA0 K T'],
  "rufener": ['R AH1 F IY0 N ER0'],
  "rufer": ['R UW1 F ER0'],
  "ruff": ['R AH1 F'],
  "ruffalo": ['R UW0 F AA1 L OW0'],
  "ruffcorn": ['R AH1 F K ER0 N'],
  "ruffer": ['R AH1 F ER0'],
  "ruffin": ['R AH1 F IH0 N'],
  "ruffing": ['R AH1 F IH0 NG'],
  "ruffini": ['R UW0 F IY1 N IY0'],
  "ruffino": ['R UW0 F IY1 N OW0'],
  "ruffle": ['R AH1 F AH0 L'],
  "ruffled": ['R AH1 F AH0 L D'],
  "ruffles": ['R AH1 F AH0 L Z'],
  "ruffling": ['R AH1 F AH0 L IH0 NG', 'R AH1 F L IH0 NG'],
  "ruffner": ['R AH1 F N ER0'],
  "ruffo": ['R AH1 F OW0'],
  "ruffolo": ['R UW0 F OW1 L OW0'],
  "rufford": ['R AH1 F ER0 D'],
  "rufina": ['R UW0 F IY1 N AH0'],
  "rufo": ['R UW1 F OW0'],
  "ruford": ['R UW1 F ER0 D'],
  "rufow": ['R UW1 F OW0'],
  "rufty": ['R AH1 F T IY0'],
  "rufus": ['R UW1 F AH0 S'],
  "rug": ['R AH1 G'],
  "rugby": ['R AH1 G B IY0'],
  "ruge": ['R UW1 JH'],
  "rugen": ['R AH1 G AH0 N'],
  "ruger": ['R UW1 G ER0'],
  "rugeroni": ['R UW2 JH EH0 R OW1 N IY0'],
  "rugg": ['R AH1 G'],
  "rugged": ['R AH1 G AH0 D'],
  "ruggedly": ['R AH1 G AH0 D L IY0'],
  "ruggeri": ['R UW0 JH EH1 R IY0'],
  "ruggerio": ['R UW0 JH EH1 R IY0 OW0'],
  "ruggieri": ['R UW0 JH IH1 R IY0'],
  "ruggiero": ['R UW0 JH IH1 R OW0'],
  "ruggirello": ['R UW0 JH IH0 R EH1 L OW0'],
  "ruggles": ['R AH1 G AH0 L Z'],
  "rugh": ['R AH1'],
  "rugs": ['R AH1 G Z'],
  "ruh": ['R AH1'],
  "ruhe": ['R UW1 HH'],
  "ruhl": ['R AH1 L'],
  "ruhland": ['R AH1 L AH0 N D'],
  "ruhle": ['R UW1 AH0 L'],
  "ruhlman": ['R UW1 L M AH0 N'],
  "ruhnau": ['R UW1 N AW0'],
  "ruhnke": ['R AH1 NG K'],
  "ruhollah": ['R UW0 HH AA1 L AH0'],
  "ruhr": ['R UH1 R'],
  "ruhrgas": ['R UH1 R G AH0 S'],
  "ruhul": ['R UW2 HH UW1 L'],
  "ruin": ['R UW1 AH0 N', 'R UW1 IH0 N'],
  "ruined": ['R UW1 AH0 N D', 'R UW1 IH0 N D'],
  "ruining": ['R UW1 IH0 N IH0 NG'],
  "ruinous": ['R UW1 AH0 N AH0 S'],
  "ruins": ['R UW1 AH0 N Z', 'R UW1 IH0 N Z'],
  "ruis": ['R UW1 IH0 Z'],
  "ruiter": ['R UW1 T ER0'],
  "ruiz": ['R UW0 IY1 Z'],
  "rujith": ['R UW2 JH IY1 TH'],
  "rukavina": ['R AH0 K AH0 V AY1 N AH0'],
  "rukeyser": ['R UW2 K EY1 Z ER0'],
  "rula": ['R UW1 L AH0'],
  "ruland": ['R UW1 L AH0 N D'],
  "rule": ['R UW1 L'],
  "rule's": ['R UW1 L Z'],
  "rulebook": ['R UW1 L B UH2 K'],
  "ruled": ['R UW1 L D'],
  "rulemaking": ['R UW1 L M EY2 K IH0 NG'],
  "ruler": ['R UW1 L ER0'],
  "rulers": ['R UW1 L ER0 Z'],
  "rules": ['R UW1 L Z'],
  "ruley": ['R UW1 L IY0'],
  "ruling": ['R UW1 L IH0 NG'],
  "ruling's": ['R UW1 L IH0 NG Z'],
  "rulings": ['R UW1 L IH0 NG Z'],
  "rulison": ['R AH1 L IH0 S AH0 N'],
  "rulli": ['R UW1 L IY0'],
  "rullman": ['R AH1 L M AH0 N'],
  "rullo": ['R UW1 L OW0'],
  "rulon": ['R UW1 L AH0 N'],
  "rum": ['R AH1 M'],
  "rumack": ['R UW1 M AE0 K'],
  "rumage": ['R AH1 M IH0 JH'],
  "ruman": ['R UW1 M AH0 N'],
  "rumania": ['R UW0 M EY1 N IY0 AH0'],
  "rumanian": ['R UW0 M EY1 N IY0 AH0 N', 'R UW0 M EY1 N Y AH0 N'],
  "rumbaugh": ['R AH1 M B AO2'],
  "rumberger": ['R AH1 M B ER0 G ER0'],
  "rumble": ['R AH1 M B AH0 L'],
  "rumbled": ['R AH1 M B AH0 L D'],
  "rumbles": ['R AH1 M B AH0 L Z'],
  "rumbley": ['R AH1 M B L IY0'],
  "rumbling": ['R AH1 M B AH0 L IH0 NG', 'R AH1 M B L IH0 NG'],
  "rumbling's": ['R AH1 M B AH0 L IH0 NG Z', 'R AH1 M B L IH0 NG Z'],
  "rumblings": ['R AH1 M B AH0 L IH0 NG Z', 'R AH1 M B L IH0 NG Z'],
  "rumbold": ['R AH1 M B OW2 L D'],
  "rumen": ['R UW1 M AH0 N'],
  "rumer": ['R UW1 M ER0'],
  "rumery": ['R AH1 M ER0 IY0'],
  "rumfelt": ['R AH1 M F EH2 L T'],
  "rumford": ['R AH1 M F ER0 D'],
  "rumina": ['R UW1 M IH0 N AH0'],
  "ruminant": ['R UW1 M AH0 N AH0 N T'],
  "ruminate": ['R UW1 M IH0 N EY2 T'],
  "ruminated": ['R UW1 M IH0 N EY2 T IH0 D'],
  "rumination": ['R UW2 M IH0 N EY1 SH AH0 N'],
  "ruminations": ['R UW2 M IH0 N EY1 SH AH0 N Z'],
  "ruminski": ['R AH0 M IH1 N S K IY0'],
  "rumler": ['R AH1 M L ER0'],
  "rumley": ['R AH1 M L IY0'],
  "rummage": ['R AH1 M IH0 JH'],
  "rummaged": ['R AH1 M IH0 JH D'],
  "rummages": ['R AH1 M IH0 JH AH0 Z'],
  "rummaging": ['R AH1 M IH0 JH IH0 NG'],
  "rummel": ['R AH1 M AH0 L'],
  "rummell": ['R AH1 M AH0 L'],
  "rummy": ['R AH1 M IY0'],
  "rumney": ['R AH1 M N IY0'],
  "rumor": ['R UW1 M ER0'],
  "rumore": ['R UW0 M AO1 R'],
  "rumored": ['R UW1 M ER0 D'],
  "rumors": ['R UW1 M ER0 Z'],
  "rumour": ['R UW1 M ER0'],
  "rumours": ['R UW1 M ER0 Z'],
  "rump": ['R AH1 M P'],
  "rumpel": ['R AH1 M P AH0 L'],
  "rumpf": ['R AH1 M P F'],
  "rumph": ['R AH1 M F'],
  "rumple": ['R AH1 M P AH0 L'],
  "rumpled": ['R AH1 M P AH0 L D'],
  "rumrill": ['R AH1 M R IH0 L'],
  "rums": ['R AH1 M Z'],
  "rumsey": ['R AH1 M Z IY0'],
  "rumsfeld": ['R AH1 M Z F EH2 L D'],
  "rumson": ['R AH1 M S AH0 N'],
  "run": ['R AH1 N'],
  "run's": ['R AH1 N Z'],
  "run-down": ['R AH1 N D AW1 N'],
  "run-on": ['R AH1 N AA1 N'],
  "run-ons": ['R AH1 N AA1 N Z'],
  "runabout": ['R AH1 N AH0 B AW2 T'],
  "runaround": ['R AH1 N AH0 R AW2 N D', 'R AH1 N AH0 R AW2 N'],
  "runaway": ['R AH1 N AH0 W EY2'],
  "runaways": ['R AH1 N AH0 W EY2 Z'],
  "runcie": ['R AH1 N S IY0'],
  "runck": ['R AH1 NG K'],
  "runckel": ['R AH1 NG K AH0 L'],
  "runco": ['R AH1 NG K OW0'],
  "rund": ['R AH1 N D'],
  "rundall": ['R AH1 N D AH0 L'],
  "runde": ['R AH1 N D'],
  "rundell": ['R AH1 N D AH0 L'],
  "rundle": ['R AH1 N D AH0 L'],
  "rundown": ['R AH1 N D AW2 N'],
  "rundquist": ['R AH1 N D K W IH2 S T'],
  "rune": ['R UW1 N'],
  "runes": ['R UW1 N Z'],
  "rung": ['R AH1 NG'],
  "runge": ['R AH1 N JH'],
  "rungs": ['R AH1 NG Z'],
  "runion": ['R UW1 N Y AH0 N'],
  "runions": ['R UW1 N Y AH0 N Z'],
  "runk": ['R AH1 NG K'],
  "runkel": ['R AH1 NG K AH0 L'],
  "runkle": ['R AH1 NG K AH0 L'],
  "runkles": ['R AH1 NG K AH0 L Z'],
  "runko": ['R AH1 N K OW0'],
  "runnells": ['R AH1 N AH0 L Z'],
  "runnels": ['R AH1 N AH0 L Z'],
  "runner": ['R AH1 N ER0'],
  "runner's": ['R AH1 N ER0 Z'],
  "runner-up": ['R AH1 N ER0 AH2 P'],
  "runners": ['R AH1 N ER0 Z'],
  "runnin'": ['R AH1 N IH0 N'],
  "running": ['R AH1 N IH0 NG'],
  "runnings": ['R AH1 N IH0 NG Z'],
  "runnion": ['R AH1 N Y AH0 N'],
  "runny": ['R AH1 N IY0'],
  "runoff": ['R AH1 N AO2 F'],
  "runquist": ['R AH1 N K W IH2 S T'],
  "runs": ['R AH1 N Z'],
  "runte": ['R AH1 N T'],
  "runup": ['R AH1 N AH2 P'],
  "runups": ['R AH1 N AH2 P S'],
  "runway": ['R AH1 N W EY2'],
  "runways": ['R AH1 N W EY2 Z'],
  "runyan": ['R AH1 N Y AH0 N'],
  "runyon": ['R AH1 N Y AH0 N'],
  "runzheimer": ['R AH1 N Z HH AY2 M ER0'],
  "ruocco": ['R UW0 OW1 K OW0'],
  "ruoff": ['R UW1 AO0 F'],
  "ruotolo": ['R UW0 OW0 T OW1 L OW0'],
  "rupard": ['R UW1 P ER0 D'],
  "rupaul": ['R UW0 P AA1 L'],
  "rupe": ['R UW1 P'],
  "rupee": ['R UW0 P IY1'],
  "rupees": ['R UW0 P IY1 Z', 'R UW1 P IY0 Z'],
  "rupert": ['R UW1 P ER0 T'],
  "ruperta": ['R UW0 P EH1 R T AH0'],
  "ruphy": ['R UW1 F IY0'],
  "rupiah": ['R UW0 P AY1 AH0'],
  "ruple": ['R UW1 P AH0 L'],
  "rupley": ['R AH1 P L IY0'],
  "rupnow": ['R AH1 P N OW0'],
  "ruposi": ['R UW0 P OW1 S IY0'],
  "rupp": ['R AH1 P'],
  "ruppe": ['R AH1 P'],
  "ruppel": ['R AH1 P AH0 L'],
  "ruppenthal": ['R AH1 P IH0 N TH AH0 L'],
  "ruppert": ['R AH1 P ER0 T'],
  "rupprecht": ['R UW1 P R EH2 K T'],
  "ruprecht": ['R AH1 P R IH0 K T'],
  "rupture": ['R AH1 P CH ER0'],
  "ruptured": ['R AH1 P CH ER0 D'],
  "ruptures": ['R AH1 P CH ER0 Z'],
  "rupturing": ['R AH1 P CH ER0 IH0 NG'],
  "rural": ['R UH1 R AH0 L'],
  "rurik": ['R UH1 R IH0 K'],
  "ruritania": ['R UH2 R AH0 T EY1 N IY0 AH0'],
  "rus": ['R AH1 S'],
  "rusalka": ['R UW2 S AE1 L K AH0'],
  "rusch": ['R AH1 SH'],
  "rusche": ['R AH1 SH'],
  "ruscitti": ['R UW0 S CH IY1 T IY0'],
  "rusconi": ['R UW0 S K OW1 N IY0'],
  "ruse": ['R UW1 Z'],
  "rused": ['R UW1 Z D'],
  "ruseler": ['R UW1 S IH0 L ER0'],
  "ruses": ['R UW1 Z IH0 Z'],
  "rush": ['R AH1 SH'],
  "rush's": ['R AH1 SH IH0 Z'],
  "rushdie": ['R AH1 SH D IY0'],
  "rushdie's": ['R AH1 SH D IY0 Z'],
  "rushed": ['R AH1 SH T'],
  "rusher": ['R AH1 SH ER0'],
  "rushes": ['R AH1 SH AH0 Z', 'R AH1 SH IH0 Z'],
  "rushford": ['R AH1 SH F ER0 D'],
  "rushin": ['R AH1 SH IH0 N'],
  "rushing": ['R AH1 SH IH0 NG'],
  "rushlow": ['R AH1 SH L OW2'],
  "rushmore": ['R AH1 SH M AO0 R'],
  "rushton": ['R AH1 SH T AH0 N'],
  "rusin": ['R UW1 S IH0 N'],
  "rusinko": ['R UW2 S IH1 NG K OW0'],
  "rusk": ['R AH1 S K'],
  "rusk's": ['R AH1 S K S'],
  "ruskin": ['R AH1 S K IH0 N'],
  "ruslan": ['R AH1 S L AH0 N'],
  "rusnak": ['R AH1 S N AH0 K'],
  "rusnock": ['R AH1 S N AH0 K'],
  "russ": ['R AH1 S'],
  "russa": ['R UW1 S AH0'],
  "russak": ['R AH1 S AH0 K'],
  "russe": ['R AH1 S'],
  "russel": ['R AH1 S AH0 L'],
  "russel's": ['R AH1 S AH0 L Z'],
  "russell": ['R AH1 S AH0 L'],
  "russell's": ['R AH1 S AH0 L Z'],
  "russellville": ['R AH1 S AH0 L V IH2 L'],
  "russert": ['R AH1 S ER0 T'],
  "russet": ['R AH1 S IH0 T'],
  "russets": ['R AH1 S IH0 T S'],
  "russett": ['R AH1 S IH0 T'],
  "russey": ['R AH1 S IY0'],
  "russi": ['R UW1 S IY0'],
  "russia": ['R AH1 SH AH0'],
  "russia's": ['R AH1 SH AH0 Z'],
  "russian": ['R AH1 SH AH0 N'],
  "russian's": ['R AH1 SH AH0 N Z'],
  "russians": ['R AH1 SH AH0 N Z'],
  "russians'": ['R AH1 SH AH0 N Z'],
  "russification": ['R AH2 S AH0 F AH0 K EY1 SH AH0 N', 'R UW2 S AH0 F AH0 K EY1 SH AH0 N'],
  "russified": ['R AH1 S AH0 F AY0 D'],
  "russin": ['R AH1 S IH0 N'],
  "russler": ['R AH1 S L ER0'],
  "russman": ['R AH1 S M AH0 N'],
  "russo": ['R UW1 S OW0'],
  "russolillo": ['R UW2 S OW0 L IH1 L OW0', 'R UW2 S OW0 L IY1 OW0'],
  "russom": ['R AH1 S AH0 M'],
  "russomanno": ['R AH1 S AH0 M IH0 N OW0'],
  "russow": ['R AH1 S OW0'],
  "russum": ['R AH1 S AH0 M'],
  "rust": ['R AH1 S T'],
  "rust's": ['R AH1 S T S'],
  "rustad": ['R AH1 S T AH0 D'],
  "rusted": ['R AH1 S T IH0 D'],
  "rustic": ['R AH1 S T IH0 K'],
  "rustier": ['R AH1 S T IY0 ER0'],
  "rustiest": ['R AH1 S T IY0 AH0 S T'],
  "rustigan": ['R AH1 S T IH0 G AH0 N'],
  "rustin": ['R AH1 S T IH0 N'],
  "rusting": ['R AH1 S T IH0 NG'],
  "rustle": ['R AH1 S AH0 L'],
  "rustler": ['R AH1 S L ER0'],
  "rustlers": ['R AH1 S AH0 L ER0 Z', 'R AH1 S L ER0 Z'],
  "rustling": ['R AH1 S L IH0 NG'],
  "ruston": ['R AH1 S T AH0 N'],
  "rustproof": ['R AH1 S T P R UW1 F'],
  "rustproofing": ['R AH1 S T P R UW1 F IH0 NG'],
  "rusts": ['R AH1 S T S'],
  "rusty": ['R AH1 S T IY0'],
  "rusty's": ['R AH1 S T IY0 Z'],
  "rustys": ['R AH1 S T IY0 Z'],
  "ruszkowski": ['R AH0 SH K AO1 F S K IY0'],
  "rut": ['R AH1 T'],
  "ruta": ['R UW1 T AH0'],
  "rutabaga": ['R UW1 T AH0 B EY0 G AH0'],
  "rutabagas": ['R UW1 T AH0 B EY0 G AH0 Z'],
  "rutan": ['R UW1 T AH0 N'],
  "rutan's": ['R UW1 T AH0 N Z'],
  "ruter": ['R UW1 T ER0'],
  "rutgard": ['R AH1 T G AA2 R D'],
  "rutger": ['R AH1 T G ER0'],
  "rutger's": ['R AH1 T G ER0 Z'],
  "rutgers": ['R AH1 T G ER0 Z'],
  "rutgers'": ['R AH1 T G ER0 Z'],
  "ruth": ['R UW1 TH'],
  "ruth's": ['R UW1 TH S'],
  "ruth-ann": ['R UW2 TH AE1 N'],
  "ruth-anne": ['R UW2 TH AE1 N'],
  "ruthann": ['R UW2 TH AE1 N'],
  "ruthenberg": ['R AH1 TH AH0 N B ER0 G'],
  "ruther": ['R UW1 TH ER0'],
  "rutherford": ['R AH1 TH ER0 F ER0 D'],
  "ruthie": ['R UW1 TH IY0'],
  "ruthless": ['R UW1 TH L AH0 S'],
  "ruthlessly": ['R UW1 TH L AH0 S L IY0'],
  "ruthlessness": ['R UW1 TH L AH0 S N AH0 S'],
  "ruthven": ['R AH1 TH V AH0 N'],
  "rutigliano": ['R UW0 T IY0 G L IY0 AA1 N OW0'],
  "rutile": ['R UW1 T IY0 L'],
  "rutkoski": ['R AH0 T K AW1 S K IY0'],
  "rutkowski": ['R AH0 T K AO1 F S K IY0'],
  "rutland": ['R AH1 T L AH0 N D'],
  "rutledge": ['R AH1 T L IH0 JH'],
  "rutley": ['R AH1 T L IY0'],
  "rutman": ['R AH1 T M AH0 N'],
  "ruts": ['R AH1 T S'],
  "rutskoi": ['R UW1 T S K OY0'],
  "rutskoi's": ['R UW1 T S K OY0 Z'],
  "rutstein": ['R AH1 T S T AY2 N', 'R AH1 T S T IY2 N'],
  "rutt": ['R AH1 T'],
  "ruttan": ['R AH1 T AH0 N'],
  "rutted": ['R AH1 T IH0 D'],
  "rutten": ['R AH1 T AH0 N'],
  "ruttenberg": ['R AH1 T AH0 N B ER0 G'],
  "rutter": ['R AH1 T ER0'],
  "ruttle": ['R AH1 T AH0 L'],
  "ruttles": ['R AH1 T AH0 L Z'],
  "rutts": ['R AH1 T S'],
  "rutz": ['R AH1 T S'],
  "rutzen": ['R AH1 T Z AH0 N'],
  "ruud": ['R UW1 D'],
  "ruvalcaba": ['R UW0 V AA0 L K AA1 B AH0'],
  "ruvolo": ['R UW0 V OW1 L OW0'],
  "ruwe": ['R UW1 W'],
  "rux": ['R AH1 K S'],
  "ruxpin": ['R AH1 K S P IH0 N'],
  "ruxpin's": ['R AH1 K S P IH0 N Z'],
  "ruybal": ['R AY1 B AH0 L'],
  "ruyle": ['R AY1 L'],
  "ruzek": ['R UW1 Z EH0 K'],
  "ruzich": ['R AH1 Z IH0 HH'],
  "ruzicka": ['R UW2 Z IH1 K AH0'],
  "ruzzo": ['R UW1 Z OW0'],
  "rwanda": ['R AH0 W AA1 N D AH0', 'R UW2 AA1 N D AH0'],
  "rwanda's": ['R AH0 W AA1 N D AH0 Z', 'R UW2 AA1 N D AH0 Z'],
  "rwandan": ['R AH0 W AA1 N D AH0 N', 'R UW2 AA1 N D AH0 N'],
  "rwandan's": ['R AH0 W AA1 N D AH0 N Z', 'R UW2 AA1 N D AH0 N Z'],
  "rwandans": ['R AH0 W AA1 N D AH0 N Z', 'R UW2 AA1 N D AH0 N Z'],
  "rwandese": ['R AH0 W AA2 D IY1 Z', 'R UW0 AA2 D IY1 Z'],
  "ryal": ['R IY0 AA1 L'],
  "ryall": ['R IY0 AA1 L'],
  "ryals": ['R IY0 AA1 L Z'],
  "ryan": ['R AY1 AH0 N'],
  "ryan's": ['R AY1 AH0 N Z'],
  "ryanair": ['R AY1 AH0 N EH2 R'],
  "ryanodine": ['R AY0 AE1 N AH0 D IY0 N'],
  "ryans": ['R AY1 AH0 N Z'],
  "ryava": ['R AY0 AA1 V AH0'],
  "ryave": ['R AY1 AH0 V'],
  "ryba": ['R IH1 B AH0'],
  "ryback": ['R AY1 B AE2 K'],
  "rybacki": ['R IH0 B AA1 T S K IY0', 'R IH2 B AA1 K IY0'],
  "rybak": ['R IH1 B AH0 K'],
  "rybakov": ['R AY1 B AH0 K AA2 V'],
  "rybakov's": ['R AY1 B AH0 K AA2 V Z'],
  "rybarczyk": ['R IH1 B ER0 CH IH0 K'],
  "ryberg": ['R AY1 B ER0 G'],
  "rybicki": ['R IH0 B IH1 T S K IY0'],
  "rybinski": ['R IH0 B IH1 N S K IY0'],
  "rybka": ['R IH1 B K AH0'],
  "rybolt": ['R IH1 B OW0 L T'],
  "ryburn": ['R IH1 B ER0 N'],
  "rychlik": ['R IH1 CH L IH0 K', 'R IH1 K L IH0 K'],
  "ryckman": ['R IH1 K M AH0 N'],
  "rycroft": ['R IH1 K R AH0 F T'],
  "ryczek": ['R IH1 CH EH0 K'],
  "rydberg": ['R IH1 D B ER0 G'],
  "rydell": ['R IH1 D AH0 L'],
  "ryden": ['R AY1 D AH0 N'],
  "ryder": ['R AY1 D ER0'],
  "ryder's": ['R AY1 D ER0 Z'],
  "rydman": ['R IH1 D M AH0 N'],
  "rydzewski": ['R IH0 JH EH1 F S K IY0'],
  "rye": ['R AY1'],
  "ryen": ['R AY1 AH0 N'],
  "ryer": ['R AY1 ER0'],
  "ryerson": ['R AY1 R S AH0 N'],
  "rygel": ['R IH1 G EH0 L'],
  "rygiel": ['R IH1 G IY0 L'],
  "ryka": ['R AY1 K AH0'],
  "ryker": ['R AY1 K ER0'],
  "rykiel": ['R IY1 K Y EH0 L', 'R IH1 K Y EH0 L'],
  "rykoff": ['R AY1 K AO2 F'],
  "rykowski": ['R IH0 K AO1 F S K IY0'],
  "rylan": ['R AY1 L AH0 N'],
  "ryland": ['R IH1 L AH0 N D'],
  "rylander": ['R IH1 L AH0 N D ER0'],
  "ryle": ['R AY1 L'],
  "rylee": ['R IH1 L IY0'],
  "ryles": ['R AY1 L Z'],
  "ryley": ['R AY1 L IY0'],
  "ryman": ['R AY1 M AH0 N'],
  "rymer": ['R AY1 M ER0'],
  "rynders": ['R IH1 N D ER0 Z'],
  "rynearson": ['R IH1 N ER0 S AH0 N'],
  "ryner": ['R AY1 N ER0'],
  "rynes": ['R AY1 N Z'],
  "ryno": ['R IH1 N OW0'],
  "ryobi": ['R AY2 OW1 B IY0'],
  "ryohei": ['R AY1 OW0 HH EY2'],
  "ryon": ['R AY1 AH0 N'],
  "ryrie": ['R AY1 R IY0'],
  "rys": ['R IH1 S'],
  "rysavy": ['R IH0 S AA1 V IY0'],
  "ryser": ['R AY1 S ER0'],
  "ryskamp": ['R AY1 S K AE2 M P'],
  "ryther": ['R IH1 DH ER0'],
  "ryton": ['R IH1 T AH0 N'],
  "ryutaro": ['R Y UW0 T AA1 R OW0'],
  "ryuzo": ['R AY2 UW1 Z OW0'],
  "ryzhkov": ['R AY1 Z K AA0 V'],
  "rzasa": ['ER0 Z AA1 S AH0'],
  "rzepka": ['ER0 Z EH1 P K AH0'],
  "s": ['EH1 S'],
  "s's": ['EH1 S IH0 Z'],
  "s.": ['EH1 S'],
  "s.'s": ['EH1 S IH0 Z'],
  "sa": ['S AA1'],
  "saab": ['S AA1 B'],
  "saab's": ['S AA1 B Z'],
  "saad": ['S AA1 D'],
  "saal": ['S AA1 L'],
  "saam": ['S AA1 M'],
  "saamstaan": ['S AA1 M S T AA2 N'],
  "saar": ['S AA1 R'],
  "saari": ['S AA1 R IY0'],
  "saarinen": ['S AA1 R IH0 N AH0 N', 'S AH0 R IY1 N AH0 N'],
  "saas": ['S AA1 S'],
  "saatchi": ['S AA1 CH IY0'],
  "saatchi's": ['S AA1 CH IY0 Z'],
  "saathoff": ['S AA1 T HH AO2 F'],
  "saavedra": ['S AA0 V EY1 D R AH0'],
  "sab's": ['S AE1 B Z'],
  "saba": ['S AA1 B AH0'],
  "sabah": ['S AA1 B AH0'],
  "sabala": ['S AA0 B AA1 L AH0'],
  "sabalan": ['S AE1 B AH0 L AH0 N'],
  "saban": ['S EY1 B AH0 N'],
  "sabas": ['S AA1 B AH0 Z'],
  "sabat": ['S AA1 B AA0 T'],
  "sabatine": ['S AA0 B AA0 T IY1 N IY0'],
  "sabatini": ['S AE2 B AH0 T IY1 N IY0'],
  "sabatino": ['S AA0 B AA0 T IY1 N OW0'],
  "sabatista": ['S AA2 B AH0 T IY1 S T AH0'],
  "sabatista's": ['S AA2 B AH0 T IY1 S T AH0 Z'],
  "sabatistas": ['S AA2 B AH0 T IY1 S T AH0 Z'],
  "sabatistas'": ['S AA2 B AH0 T IY1 S T AH0 Z'],
  "sabatka": ['S AA0 B AA1 T K AH0'],
  "sabato": ['S AA0 B AA1 T OW0'],
  "sabaudia": ['S AH0 B AO1 D IY0 AH0'],
  "sabb": ['S AE1 B'],
  "sabbagh": ['S AE1 B AH0 G'],
  "sabbath": ['S AE1 B AH0 TH'],
  "sabbaths": ['S AE1 B AH0 TH S'],
  "sabbatical": ['S AH0 B AE1 T IH0 K AH0 L'],
  "sabbaticals": ['S AH0 B AE1 T IH0 K AH0 L Z'],
  "sabedra": ['S AH0 B EH1 D R AH0'],
  "sabel": ['S AA0 B EH1 L'],
  "sabella": ['S AH0 B EH1 L AH0'],
  "sabena": ['S AH0 B IY1 N AH0'],
  "saber": ['S EY1 B ER0'],
  "saberbein": ['S EY1 B ER0 B AY2 N'],
  "saberbein's": ['S EY1 B ER0 B AY2 N Z'],
  "sabers": ['S EY1 B ER0 Z'],
  "sabertech": ['S EY1 B ER0 T EH2 K'],
  "sabey": ['S EY1 B IY0'],
  "sabia": ['S AA1 B IY0 AH0'],
  "sabin": ['S EY1 B IH0 N'],
  "sabina": ['S AH0 B IY1 N AH0'],
  "sabine": ['S AH0 B IY1 N'],
  "sabine's": ['S AH0 B IY1 N Z'],
  "sabino": ['S AA0 B IY1 N OW0'],
  "sabins": ['S EY1 B IH0 N Z'],
  "sabir": ['S EY1 B ER0', 'S AH0 B IH1 R'],
  "sablan": ['S AE1 B L AH0 N'],
  "sable": ['S EY1 B AH0 L'],
  "sables": ['S EY1 B AH0 L Z'],
  "sabo": ['S AA1 B OW0'],
  "sabol": ['S AA0 B AO1 L'],
  "sabol's": ['S AA0 B AO1 L Z'],
  "sabortech": ['S EY1 B ER0 T EH2 K'],
  "sabotage": ['S AE1 B AH0 T AA2 ZH'],
  "sabotaged": ['S AE1 B AH0 T AA2 ZH D'],
  "sabotaging": ['S AE1 B AH0 T AA2 ZH IH0 NG'],
  "saboteur": ['S AE2 B AH0 T ER1'],
  "saboteurs": ['S AE2 B AH0 T ER1 Z', 'S AE2 B IH0 T AO1 R Z'],
  "sabourin": ['S AE1 B UH0 R AE0 N'],
  "sabra": ['S AA1 B R AH0'],
  "sabre": ['S EY1 B ER0'],
  "sabres": ['S EY1 B ER0 Z'],
  "sabretech": ['S EY1 B ER0 T EH2 K'],
  "sabrina": ['S AH0 B R IY1 N AH0'],
  "sabry": ['S AA1 B R IY0'],
  "sabta": ['S AE1 B T AH0'],
  "sabunuma": ['S AE0 B UW1 N AH0 M AH0'],
  "sac": ['S AE1 K'],
  "sacca": ['S AE1 K AH0'],
  "saccharin": ['S AE1 K ER0 AH0 N'],
  "saccharine": ['S AE1 K ER0 AY2 N'],
  "sacco": ['S AE1 K OW0'],
  "sacco's": ['S AE1 K OW0 Z'],
  "saccoccio": ['S AA0 K OW1 CH IY0 OW0'],
  "saccomanno": ['S AE1 K AH0 M AE2 N OW0'],
  "saccone": ['S AA0 K OW1 N IY0'],
  "saccule": ['S AE1 K Y UW2 L'],
  "sacha": ['S AE1 CH AH0'],
  "sachdev": ['S AE1 CH D EH2 V'],
  "sacher": ['S AE1 K ER0', 'S AA1 SH ER0'],
  "sachet": ['S AE0 SH EY1'],
  "sachi": ['S AE1 CH IY0'],
  "sachin": ['S AE1 CH AH0 N'],
  "sachs": ['S AE1 K S', 'S AA1 K S'],
  "sachs'": ['S AE1 K S'],
  "sachs's": ['S AE1 K S IH0 Z'],
  "sachse": ['S AE1 CH S'],
  "sacilor": ['S AE1 S AH0 L ER0'],
  "sacirbey": ['S AA1 K ER0 B IY0'],
  "sacirbey's": ['S AA1 K ER0 B IY0 Z'],
  "sack": ['S AE1 K'],
  "sacked": ['S AE1 K T'],
  "sackett": ['S AE1 K IH0 T'],
  "sacking": ['S AE1 K IH0 NG'],
  "sackler": ['S AE1 K L ER0'],
  "sackman": ['S AE1 K M AH0 N'],
  "sackmann": ['S AE1 K M AH0 N'],
  "sackrider": ['S AE1 K R AY2 D ER0'],
  "sacks": ['S AE1 K S'],
  "sacksteder": ['S AE1 K S T EH2 D ER0'],
  "sackville": ['S AE1 K V IH2 L'],
  "saco": ['S EY1 K OW0'],
  "sacra": ['S AE1 K R AH0'],
  "sacrament": ['S AE1 K R AH0 M AH0 N T'],
  "sacramental": ['S AE1 K R AH0 M AH0 N T AH0 L'],
  "sacramento": ['S AE2 K R AH0 M EH1 N T OW0'],
  "sacramento's": ['S AE2 K R AH0 M EH1 N T OW0 Z'],
  "sacraments": ['S AE1 K R AH0 M AH0 N T S'],
  "sacred": ['S EY1 K R AH0 D', 'S EY1 K R IH0 D'],
  "sacredness": ['S EY1 K R IH0 D N IH0 S'],
  "sacrifice": ['S AE1 K R AH0 F AY2 S'],
  "sacrificed": ['S AE1 K R AH0 F AY2 S T'],
  "sacrifices": ['S AE1 K R AH0 F AY2 S IH0 Z'],
  "sacrificial": ['S AE2 K R AH0 F IH1 SH AH0 L'],
  "sacrificing": ['S AE1 K R AH0 F AY2 S IH0 NG'],
  "sacrilege": ['S AE1 K R AH0 L AH0 JH'],
  "sacrilegious": ['S AE2 K R AH0 L EH1 JH IH0 S'],
  "sacrosanct": ['S AE1 K R OW0 S AE0 NG K T'],
  "sacs": ['S AE1 K S'],
  "sad": ['S AE1 D'],
  "sada": ['S AA1 D AH0'],
  "sadaka": ['S AH0 D AA1 K AH0'],
  "sadako": ['S AA0 D AA1 K OW0'],
  "sadao": ['S AH0 D AW1'],
  "sadat": ['S AA0 D AA1 T', 'S AH0 D AE1 T'],
  "sadat's": ['S AA0 D AA1 T S', 'S AH0 D AE1 T S'],
  "saddam": ['S AA1 D AH0 M', 'S AH0 D AA1 M'],
  "saddam's": ['S AA1 D AH0 M Z', 'S AH0 D AA1 M Z'],
  "sadden": ['S AE1 D AH0 N'],
  "saddened": ['S AE1 D AH0 N D'],
  "saddening": ['S AE1 D AH0 N IH0 NG', 'S AE1 D N IH0 NG'],
  "saddens": ['S AE1 D AH0 N Z'],
  "sadder": ['S AE1 D ER0'],
  "saddest": ['S AE1 D AH0 S T'],
  "saddle": ['S AE1 D AH0 L'],
  "saddlebag": ['S AE1 D AH0 L B AE2 G'],
  "saddlebags": ['S AE1 D AH0 L B AE2 G Z'],
  "saddled": ['S AE1 D AH0 L D'],
  "saddler": ['S AE1 D AH0 L ER0', 'S AE1 D L ER0'],
  "saddles": ['S AE1 D AH0 L Z'],
  "saddling": ['S AE1 D AH0 L IH0 NG', 'S AE1 D L IH0 NG'],
  "saddoris": ['S AE1 D ER0 IH0 S'],
  "sadeghi": ['S AA0 D EH1 G IY0'],
  "sadeh": ['S AA1 D EH2'],
  "sadeh-koniecpol": ['S AA1 D EH2 K OW2 N IY0 EH1 K P AO2 L'],
  "sadek": ['S AE1 D IH0 K'],
  "sadella": ['S AH0 D EH1 L AH0'],
  "sader": ['S EY1 D ER0'],
  "sadie": ['S AE1 D IY0'],
  "sadie's": ['S EY1 D IY0 Z'],
  "sadira": ['S AA0 D IH1 R AH0'],
  "sadism": ['S EY1 D IH0 Z AH0 M'],
  "sadist": ['S EY1 D AH0 S T'],
  "sadistic": ['S AH0 D IH1 S T IH0 K'],
  "sadler": ['S AE1 D L ER0'],
  "sadlier": ['S AE1 D L IY0 ER0'],
  "sadlon": ['S AE1 D L AH0 N'],
  "sadlowski": ['S AE0 D L AW1 S K IY0'],
  "sadly": ['S AE1 D L IY0'],
  "sadness": ['S AE1 D N AH0 S'],
  "sado": ['S EY1 D OW0'],
  "sadoff": ['S AE1 D AO2 F'],
  "sadowski": ['S AH0 D AO1 F S K IY0'],
  "sadowsky": ['S AH0 D AW1 S K IY0'],
  "sadye": ['S AE1 D AY0'],
  "saechao": ['S IY1 CH AW0'],
  "saeed": ['S AA0 IY1 D'],
  "saeger": ['S EH1 G ER0'],
  "saehan": ['S EY1 AH0 N'],
  "saelens": ['S EH1 L AH0 N Z'],
  "saemaul": ['S EY1 M AO0 L'],
  "saenger": ['S EH1 N JH ER0'],
  "saens's": ['S EY1 N Z IH0 Z'],
  "saenz": ['S IY1 N Z'],
  "saez": ['S IY1 Z'],
  "safari": ['S AH0 F AA1 R IY0'],
  "safarik": ['S AE1 F ER0 IH0 K'],
  "safaris": ['S AH0 F AA1 R IY0 Z'],
  "safdie": ['S AE1 F D IY0'],
  "safe": ['S EY1 F'],
  "safecard": ['S EY1 F K AA2 R D'],
  "safecard's": ['S EY1 F K AA2 R D Z'],
  "safeco": ['S EY1 F K OW0'],
  "safeguard": ['S EY1 F G AA2 R D'],
  "safeguarded": ['S EY1 F G AA2 R D IH0 D'],
  "safeguarding": ['S EY1 F G AA2 R D IH0 NG'],
  "safeguards": ['S EY1 F G AA2 R D Z'],
  "safehouse": ['S EY1 F HH AW2 S'],
  "safekeeping": ['S EY1 F K IY1 P IH0 NG'],
  "safely": ['S EY1 F L IY0'],
  "safer": ['S EY1 F ER0'],
  "safes": ['S EY1 F S'],
  "safest": ['S EY1 F AH0 S T'],
  "safeties": ['S EY1 F T IY0 Z'],
  "safety": ['S EY1 F T IY0'],
  "safety's": ['S EY1 F T IY0 Z'],
  "safeway": ['S EY1 F W EY2'],
  "safeway's": ['S EY1 F W EY2 Z'],
  "saffell": ['S AE1 F AH0 L'],
  "saffer": ['S AE1 F ER0'],
  "safferstein": ['S AE1 F ER0 S T IY2 N', 'S AE1 F ER0 S T AY2 N'],
  "saffir": ['S AH0 F IY1 R'],
  "saffle": ['S AE1 F AH0 L'],
  "safflower": ['S AE1 F L AW2 ER0'],
  "saffold": ['S AE1 F OW2 L D'],
  "safford": ['S AE1 F ER0 D'],
  "saffran": ['S AE1 F R AH0 N'],
  "saffron": ['S AE1 F R AH0 N'],
  "safian": ['S EY1 F IY0 AH0 N'],
  "safier": ['S AH0 F IH1 R'],
  "safing": ['S EY1 F IH0 NG'],
  "safir": ['S AE1 F ER0'],
  "safire": ['S AH0 F AY1 R'],
  "safire's": ['S AH0 F AY1 R Z'],
  "safko": ['S AA1 F K OW0'],
  "safley": ['S AE1 F L IY0'],
  "safra": ['S AE1 F R AH0'],
  "safra's": ['S AE1 F R AH0 Z'],
  "safran": ['S AE1 F R AH0 N'],
  "safranek": ['S AE1 F R AH0 N IH0 K'],
  "safrit": ['S AE1 F R IH0 T'],
  "sag": ['S AE1 G'],
  "saga": ['S AA1 G AH0'],
  "sagan": ['S EY1 G AH0 N'],
  "sagansky": ['S AH0 G AE1 N S K IY0'],
  "sagar": ['S AA0 G AA1 R'],
  "sagas": ['S AA1 G AH0 Z'],
  "sage": ['S EY1 JH'],
  "sage's": ['S EY1 JH AH0 Z'],
  "sagebrush": ['S EY1 JH B R AH2 SH'],
  "sagen": ['S AE1 G AH0 N'],
  "sager": ['S EY1 G ER0'],
  "sagers": ['S EY1 G ER0 Z'],
  "sages": ['S EY1 JH IH0 Z'],
  "sagged": ['S AE1 G D'],
  "saggese": ['S AE1 G IY0 Z'],
  "sagging": ['S AE1 G IH0 NG'],
  "saginaw": ['S AE1 G IH0 N AO2'],
  "sagittal": ['S AE1 JH AH0 T AH0 L'],
  "sago": ['S EY1 G OW0'],
  "sagona": ['S AA0 G OW1 N AH0'],
  "sagraves": ['S AA0 G R AA1 V EH0 S'],
  "sags": ['S AE1 G Z'],
  "saguaro": ['S AH0 G W AO1 R OW0'],
  "saguenay": ['S AE1 G AH0 N EY0'],
  "saha": ['S AA1 HH AH0'],
  "sahagian": ['S AH0 HH IY1 JH IY0 AH0 N'],
  "sahagun": ['S AE1 HH AH0 G AH0 N'],
  "sahara": ['S AH0 HH EH1 R AH0'],
  "saharan": ['S AE1 HH ER0 AH0 N'],
  "sahgal": ['S AA1 G AH0 L'],
  "sahl": ['S AA1 L'],
  "sahlen": ['S AA1 L AH0 N'],
  "sahli": ['S AA1 L IY0'],
  "sahlin": ['S AA1 L IH0 N'],
  "sahlman": ['S AA1 L M AH0 N'],
  "sahm": ['S AE1 M'],
  "sahr": ['S AE1 R'],
  "sai": ['S AY1', 'EH1 S EY1 AY1'],
  "saia": ['S AA1 Y AH0'],
  "said": ['S EH1 D'],
  "saidan": ['S EY1 D AH0 N'],
  "saidiner": ['S EY1 D IH0 N ER0'],
  "saif": ['S AA2 IY1 F', 'S AY1 F'],
  "saigon": ['S AY2 G AA1 N'],
  "saiki": ['S EY1 K IY0'],
  "sail": ['S EY1 L'],
  "sailboat": ['S EY1 L B OW2 T'],
  "sailboats": ['S EY1 L B OW2 T S'],
  "sailed": ['S EY1 L D'],
  "sailer": ['S EY1 L ER0'],
  "sailfish": ['S EY1 L F IH2 SH'],
  "sailing": ['S EY1 L IH0 NG'],
  "sailor": ['S EY1 L ER0'],
  "sailor's": ['S EY1 L ER0 Z'],
  "sailors": ['S EY1 L ER0 Z'],
  "sailors'": ['S EY1 L ER0 Z'],
  "sails": ['S EY1 L Z'],
  "sain": ['S EY1 N'],
  "sainato": ['S AH0 N AA1 T OW0'],
  "saindon": ['S EY1 N D AH0 N'],
  "saine": ['S EY1 N'],
  "sainsbury": ['S EY1 N S B EH0 R IY0'],
  "saint": ['S EY1 N T'],
  "saint's": ['S EY1 N T S'],
  "saint-saens": ['S EY1 N T S AA1 N Z'],
  "sainted": ['S EY1 N T IH0 D'],
  "sainthood": ['S EY1 N T HH UH2 D'],
  "saintly": ['S EY1 N T L IY0'],
  "saints": ['S EY1 N T S'],
  "saints'": ['S EY1 N T S'],
  "sainz": ['S EY1 N Z'],
  "saipan": ['S EY1 P AH0 N'],
  "saipan's": ['S EY1 P AH0 N Z'],
  "sais": ['S EY1 Z'],
  "saison": ['S EY1 S AA0 N'],
  "saitama": ['S AA2 IH0 T AA1 M AH0'],
  "saito": ['S EY1 T OW2'],
  "saitta": ['S EY1 T AH0'],
  "saiz": ['S EY1 Z'],
  "sajak": ['S AE1 JH AE0 K'],
  "sajdak": ['S AY1 D AH0 K'],
  "sak": ['S AE1 K'],
  "sakaguchi": ['S AA0 K AA0 G UW1 CH IY0'],
  "sakai": ['S AA0 K AA1 IY0'],
  "sakal": ['S EY1 K AH0 L'],
  "sakamoto": ['S AA0 K AA0 M OW1 T OW0'],
  "sakarov": ['S AA0 K AA1 R AO2 V'],
  "sakata": ['S AA0 K AA1 T AH0'],
  "sakau": ['S AA0 K AA1 UW0'],
  "sake": ['S EY1 K'],
  "sake's": ['S EY1 K S'],
  "saker": ['S EY1 K ER0'],
  "sakes": ['S EY1 K S'],
  "sakhalin": ['S AE1 K AH0 L IH0 N'],
  "sakhalinsk": ['S AE2 K AH0 L IH1 N S K'],
  "sakharov": ['S AA1 K ER0 AA0 V'],
  "saki": ['S AA1 K IY0'],
  "sakigake": ['S AA2 K IY0 G AA1 K IY0'],
  "sakina": ['S AH0 K IY1 N AH0'],
  "sako": ['S AA1 K OW0'],
  "sakowitz": ['S AE1 K AH0 W IH0 T S'],
  "sakowski": ['S AH0 K AO1 F S K IY2'],
  "saks": ['S AE1 K S'],
  "saks's": ['S AE1 K S IH0 Z'],
  "saksa": ['S AE1 K S AH0'],
  "saksida": ['S AA2 K S IY1 D AA2'],
  "sakuma": ['S AA2 K UW1 M AA2'],
  "sakura": ['S AE0 K UH1 R AA2'],
  "sakurai": ['S AA0 K UH0 R AA1 IY0'],
  "sal": ['S AE1 L'],
  "sal's": ['S AE1 L Z'],
  "sala": ['S AA1 L AH0'],
  "salaam": ['S AH0 L AA1 M'],
  "salable": ['S EY1 L AH0 B AH0 L'],
  "salables": ['S EY1 L AH0 B AH0 L Z'],
  "salacious": ['S AH0 L EY1 SH AH0 S'],
  "salad": ['S AE1 L AH0 D'],
  "salada": ['S AH0 L AA1 D AH0'],
  "saladin": ['S AE1 L AH0 D IH0 N'],
  "saladino": ['S AA0 L AA0 D IY1 N OW0'],
  "salads": ['S AE1 L AH0 D Z'],
  "salafia": ['S AH0 L EY1 F IY0 AH0'],
  "salah": ['S AE1 L AH0'],
  "salak": ['S AE1 L AH0 K'],
  "salam": ['S AA0 L AA1 M'],
  "salama": ['S AA0 L AA1 M AH0'],
  "salamanca": ['S AE2 L AH0 M AE1 NG K AH0'],
  "salamander": ['S AE2 L AH0 M AE1 N D ER0'],
  "salamanders": ['S AE2 L AH0 M AE1 N D ER0 Z'],
  "salameh": ['S AA1 L AA0 M EH0'],
  "salameh's": ['S AA1 L AA0 M EH0 Z'],
  "salami": ['S AH0 L AA1 M IY0'],
  "salamis": ['S AH0 L AA1 M IY0 Z'],
  "salamon": ['S AE1 L AH0 M AH0 N'],
  "salamone": ['S AE1 L AH0 M OW2 N'],
  "salant": ['S AE1 L AH0 N T'],
  "salaried": ['S AE1 L ER0 IY0 D'],
  "salaries": ['S AE1 L ER0 IY0 Z'],
  "salary": ['S AE1 L ER0 IY0'],
  "salarymen": ['S AE1 L ER0 IY0 M AH0 N'],
  "salas": ['S AA1 L AA0 Z'],
  "salata": ['S AA0 L AA1 T AH0'],
  "salatino": ['S AA0 L AA0 T IY1 N OW0'],
  "salawah": ['S AE1 L AH0 W AA0'],
  "salay": ['S AE1 L EY0'],
  "salaz": ['S AA1 L AA0 Z'],
  "salazar": ['S AE1 L AH0 Z AA0 R'],
  "salberg": ['S AE1 L B ER0 G'],
  "salce": ['S EY1 L S'],
  "salcedo": ['S AA0 L CH EY1 D OW0'],
  "salcido": ['S AA0 L CH IY1 D OW0'],
  "saldana": ['S AA0 L D AE1 N AH0'],
  "saldivar": ['S AA0 L D IY0 V AA1 R'],
  "saldovar": ['S AA0 L D AH0 V AA1 R'],
  "sale": ['S EY1 L'],
  "sale's": ['S EY1 L Z'],
  "saleable": ['S EY1 L AH0 B AH0 L'],
  "saleeby": ['S AE1 L IY0 B IY0'],
  "saleem": ['S AE1 L IY0 M'],
  "saleh": ['S AA1 L EH0 HH'],
  "salek": ['S AA1 L EH0 K'],
  "salem": ['S EY1 L AH0 M'],
  "salem's": ['S EY1 L AH0 M Z'],
  "salema": ['S AH0 L IY1 M AH0'],
  "salemi": ['S AA0 L EH1 M IY0'],
  "salemme": ['S AE1 L IH0 M'],
  "salerno": ['S AH0 L EH1 R N OW0'],
  "sales": ['S EY1 L Z'],
  "sales'": ['S EY1 L Z'],
  "salesforce": ['S EY1 L Z F AO2 R S'],
  "salesman": ['S EY1 L Z M AH0 N'],
  "salesman's": ['S EY1 L Z M AH0 N Z'],
  "salesmanship": ['S EY1 L Z M AH0 N SH IH2 P'],
  "salesmen": ['S EY1 L Z M IH0 N'],
  "salesmen's": ['S EY1 L Z M IH0 N Z'],
  "salespeople": ['S EY1 L Z P IY2 P AH0 L'],
  "salesperson": ['S EY1 L Z P ER2 S AH0 N'],
  "saleswoman": ['S EY1 L Z W UH2 M AH0 N'],
  "saleswomen": ['S EY1 L Z W IH2 M AH0 N'],
  "salgado": ['S AA0 L G AA1 D OW0'],
  "salguero": ['S AA0 L G EH1 R OW0'],
  "salhany": ['S AE2 L HH EY1 N IY0'],
  "saliba": ['S AA0 L IY1 B AH0'],
  "salick": ['S AE1 L IH0 K'],
  "salient": ['S EY1 L IY0 AH0 N T', 'S EY1 L Y AH0 N T'],
  "salif": ['S AH0 L IY1 F'],
  "saligman": ['S AE1 L IH0 G M AH0 N'],
  "salim": ['S AE1 L IH0 M', 'S AA0 L IY0 M'],
  "salin": ['S AA0 L IY1 N'],
  "salina": ['S AH0 L IY1 N AH0'],
  "salinas": ['S AH0 L IY1 N AH0 S', 'S AH0 L IY1 N AH0 Z'],
  "salinas'": ['S AH0 L IY1 N AH0 S', 'S AH0 L IY1 N AH0 Z'],
  "salinas's": ['S AH0 L IY1 N AH0 S IH0 Z'],
  "saline": ['S AH0 L IY1 N'],
  "saling": ['S EY1 L IH0 NG'],
  "salinger": ['S AE1 L IH0 N JH ER0'],
  "salinger's": ['S AE1 L IH0 N JH ER0 Z'],
  "salingers": ['S AE1 L IH0 N JH ER0 Z'],
  "salinity": ['S AH0 L IH1 N AH0 T IY2'],
  "salis": ['S AA1 L IH0 S'],
  "salisbury": ['S AE1 L Z B ER0 IY0'],
  "saliva": ['S AH0 L AY1 V AH0'],
  "salivate": ['S AE1 L AH0 V EY2 T'],
  "salivated": ['S AE1 L AH0 V EY2 T IH0 D'],
  "salivating": ['S AE1 L AH0 V EY2 T IH0 NG'],
  "salizzoni": ['S AE2 L IH0 Z OW1 N IY0'],
  "salk": ['S AO1 K'],
  "salk's": ['S AO1 K S'],
  "salkeld": ['S AE1 L K IH0 L D'],
  "salkin": ['S AE1 L K IH0 N'],
  "sall": ['S AO1 L'],
  "sallade": ['S AE1 L EY2 D'],
  "sallas": ['S AA1 L AA0 Z'],
  "salle": ['S EY1 L'],
  "sallee": ['S AE1 L IY0'],
  "salleh": ['S AA1 L EH0'],
  "saller": ['S AO1 L ER0'],
  "salles": ['S AA1 L EH0 S'],
  "salley": ['S AE1 L IY0'],
  "sallie": ['S AE1 L IY0'],
  "sallies": ['S AE1 L IY0 Z'],
  "salling": ['S AO1 L IH0 NG'],
  "sallis": ['S AE1 L IH0 S'],
  "salls": ['S AO1 L Z'],
  "sally": ['S AE1 L IY0'],
  "sally's": ['S AE1 L IY0 Z'],
  "salm": ['S AA1 M'],
  "salman": ['S AE1 M AH0 N'],
  "salmans": ['S AE1 M AH0 N Z'],
  "salmela": ['S AA0 L M EY1 L AH0'],
  "salmen": ['S AE0 L M EH1 N'],
  "salmeron": ['S AA0 L M EH0 R AO1 N'],
  "salmi": ['S AA1 L M IY0'],
  "salminen": ['S AE1 L M IH0 N AH0 N'],
  "salmon": ['S AE1 M AH0 N'],
  "salmond": ['S AE1 L M AH0 N D'],
  "salmonella": ['S AE2 L M AH0 N EH1 L AH0'],
  "salmons": ['S AE1 M AH0 N Z'],
  "salmonson": ['S AA0 L M OW1 N S AH0 N'],
  "salo": ['S AA1 L OW0'],
  "salois": ['S AH0 L W AA1'],
  "saloma": ['S AA0 L OW1 M AH0'],
  "salome": ['S AH0 L OW1 M IY0'],
  "salomi": ['S AA0 L OW1 M IY0'],
  "salomon": ['S AE1 L AH0 M AH0 N'],
  "salomon's": ['S AE1 L AH0 M AH0 N Z'],
  "salomone": ['S AA0 L OW0 M OW1 N IY0'],
  "salon": ['S AH0 L AA1 N'],
  "salone": ['S AH0 L OW1 N'],
  "salonga": ['S AH0 L AO1 NG G AH0'],
  "salons": ['S AH0 L AA1 N Z'],
  "saloojee": ['S AH0 L UW1 JH IY0'],
  "saloon": ['S AH0 L UW1 N'],
  "saloons": ['S AH0 L UW1 N Z'],
  "salopek": ['S AE1 L AH0 P IH0 K'],
  "salow": ['S AE1 L OW0'],
  "salpetriere": ['S AE1 L P EH0 T R IY0 EH2 R'],
  "salsa": ['S AO1 L S AH0'],
  "salsas": ['S AA1 L S AH0 Z'],
  "salsberry": ['S AO1 L S B EH0 R IY0'],
  "salsbery": ['S AE1 L S B ER0 IY0'],
  "salsbury": ['S AE1 L Z B ER0 IY0'],
  "salser": ['S EY1 L S ER0'],
  "salsgiver": ['S AE1 L S G IH0 V ER0'],
  "salsman": ['S AO1 L S M AH0 N'],
  "salt": ['S AO1 L T'],
  "saltarelli": ['S AO0 L T AA0 R EH1 L IY0'],
  "saltbox": ['S AO1 L T B AA2 K S'],
  "salted": ['S AO1 L T IH0 D'],
  "salter": ['S AO1 L T ER0'],
  "salter's": ['S AO1 L T ER0 Z'],
  "salters": ['S AO1 L T ER0 Z'],
  "saltier": ['S AO1 L T IY0 ER0'],
  "salting": ['S AO1 L T IH0 NG'],
  "saltlike": ['S AO1 L T L AY2 K'],
  "saltmarsh": ['S AO1 L T M AA2 R SH'],
  "salton": ['S AO1 L T AH0 N'],
  "salts": ['S AO1 L T S'],
  "saltsman": ['S AO1 L T S M AH0 N'],
  "saltwater": ['S AO2 L T W AA1 T ER0'],
  "salty": ['S AO1 L T IY0'],
  "saltz": ['S AE1 L T S'],
  "saltzburg": ['S AO1 L T S B ER0 G'],
  "saltzman": ['S AO1 L T S M AH0 N'],
  "salus": ['S AE1 L IH0 S'],
  "salutary": ['S AE1 L Y AH0 T EH2 R IY0'],
  "salutatorian": ['S AH0 L UW2 T AH0 T AO1 R IY0 AH0 N'],
  "salute": ['S AH0 L UW1 T'],
  "saluted": ['S AH0 L UW1 T IH0 D'],
  "salutes": ['S AH0 L UW1 T S'],
  "saluting": ['S AH0 L UW1 T IH0 NG'],
  "salva": ['S AA1 L V AH0'],
  "salvador": ['S AE1 L V AH0 D AO2 R'],
  "salvador's": ['S AE1 L V AH0 D AO2 R Z'],
  "salvadoran": ['S AE1 L V AH0 D AO2 R AH0 N'],
  "salvadorans": ['S AE0 L V AH0 D AO1 R AH0 N Z'],
  "salvadore": ['S AA0 L V AA0 D AO1 R EY0'],
  "salvage": ['S AE1 L V AH0 JH', 'S AE1 L V IH0 JH'],
  "salvageable": ['S AE1 L V IH0 JH AH0 B AH0 L'],
  "salvaged": ['S AE1 L V IH0 JH D'],
  "salvager": ['S AE1 L V IH0 JH ER0'],
  "salvagers": ['S AE1 L V IH0 JH ER0 Z'],
  "salvaggio": ['S AA0 L V AA1 JH IY0 OW0'],
  "salvaging": ['S AE1 L V IH0 JH IH0 NG'],
  "salvas": ['S AA1 L V AA0 Z'],
  "salvati": ['S AA0 L V AA1 T IY0'],
  "salvatierra": ['S AA0 L V AA0 T IH1 R AH0'],
  "salvation": ['S AE0 L V EY1 SH AH0 N'],
  "salvato": ['S AA0 L V AA1 T OW0'],
  "salvatore": ['S AE0 L V AH0 T AO1 R IY0', 'S AE1 L V AH0 D AO2 R'],
  "salvatori": ['S AA0 L V AA0 T AO1 R IY0'],
  "salve": ['S AA1 V'],
  "salves": ['S AA1 V Z'],
  "salvesen": ['S AA0 L V IY1 Z AH0 N'],
  "salveson": ['S AA0 L V EY1 S AH0 N'],
  "salvetti": ['S AA0 L V EH1 T IY0'],
  "salvi": ['S AA1 L V IY0'],
  "salvi's": ['S AA1 L V IY0 Z'],
  "salvia": ['S AE1 L V IY0 AH0'],
  "salvigsen": ['S AE1 L V IH0 G S AH0 N'],
  "salvigsten": ['S AE1 L V IH0 G S T AH0 N'],
  "salvina": ['S AA0 L V IY1 N AH0'],
  "salvino": ['S AE0 L V IY1 N OW0'],
  "salvo": ['S AE1 L V OW0'],
  "salvos": ['S AE1 L V OW0 Z'],
  "salvucci": ['S AA0 L V UW1 CH IY0'],
  "salway": ['S AE1 L W EY0'],
  "salwen": ['S AE1 L W AH0 N'],
  "salyard": ['S AO1 L Y ER0 D'],
  "salyards": ['S AE1 L Y AA0 R D Z'],
  "salyer": ['S AA1 L IY0 ER0'],
  "salyers": ['S AA1 L IY0 ER0 Z'],
  "salz": ['S AO1 L Z'],
  "salzano": ['S AA0 L Z AA1 N OW0'],
  "salzberg": ['S AO1 L Z B ER0 G', 'S AO1 L T S B ER0 G'],
  "salzburg": ['S AO1 L Z B ER0 G', 'S AO1 L T S B ER0 G'],
  "salzer": ['S EY1 L Z ER0'],
  "salzgitter": ['S AO1 L T S G IH2 T ER0'],
  "salzhauer": ['S AO1 L T S HH AW2 R', 'S AO1 L T S AW2 R'],
  "salzman": ['S AO1 L Z M AH0 N'],
  "salzmann": ['S AO1 L Z M AH0 N'],
  "salzwedel": ['S AE1 L Z W IH0 D AH0 L'],
  "sam": ['S AE1 M'],
  "sam's": ['S AE1 M Z'],
  "sam-joo": ['S AA1 M JH UW2'],
  "sama": ['S AA1 M AH0'],
  "samaha": ['S AE1 M AH0 HH AH0'],
  "samaniego": ['S AA0 M AA0 N IY1 G OW0'],
  "samano": ['S AA0 M AA1 N OW0'],
  "samantha": ['S AH0 M AE1 N TH AH0'],
  "samar": ['S AE1 M AA0 R'],
  "samara": ['S AE1 M ER0 AH0'],
  "samaranch": ['S AE1 M ER0 AE0 N CH'],
  "samaras": ['S AE1 M ER0 AH0 Z'],
  "samaria": ['S EY2 M ER0 IY1 AH0'],
  "samarin": ['S AA0 M AA1 R IY0 N'],
  "samaritan": ['S AH0 M EH1 R IH0 T AH0 N'],
  "samaritans": ['S AH0 M EH1 R IH0 T AH0 N Z'],
  "samarkand": ['S AE1 M AA0 R K AE2 N D'],
  "samatar": ['S AE1 M AH0 T AA2 R'],
  "samba": ['S AA1 M B AH0'],
  "samberg": ['S AE1 M B ER0 G'],
  "sambo": ['S AE1 M B OW0'],
  "samborski": ['S AH0 M B AO1 R S K IY0'],
  "sambrano": ['S AA0 M B R AA1 N OW0'],
  "sambre": ['S AE1 M B R AH0'],
  "sambuca": ['S AE2 M B Y UW1 K AH0'],
  "samcor": ['S AE1 M K AO2 R'],
  "same": ['S EY1 M'],
  "same's": ['S EY1 M Z'],
  "same-sex": ['S EY2 M S EH1 K S'],
  "samedan": ['S AE1 M AH0 D AH0 N'],
  "sameer": ['S AA2 M IH1 R'],
  "samek": ['S AE1 M IH0 K'],
  "samella": ['S AH0 M EH1 L AH0'],
  "samelle": ['S AH0 M EH1 L'],
  "samelson": ['S AE1 M IH0 L S AH0 N'],
  "sameness": ['S EY1 M N AH0 S'],
  "samerol": ['S AE1 M ER0 AO2 L'],
  "sames": ['S EY1 M Z'],
  "samet": ['S AE1 M IH0 T'],
  "samford": ['S AE1 M F ER0 D'],
  "sami": ['S AE1 M IY0'],
  "sami's": ['S AE1 M IY0 Z'],
  "samina": ['S AH0 M IH1 N AH0'],
  "samir": ['S AH0 M IH1 R', 'S AA0 M IH1 R'],
  "sammarco": ['S AA0 M AA1 R K OW0'],
  "sammartino": ['S AA0 M AA0 R T IY1 N OW0'],
  "sammet": ['S AE1 M IH0 T'],
  "sammie": ['S AE1 M IY0'],
  "sammis": ['S AE1 M IH0 S'],
  "sammon": ['S AE1 M AH0 N'],
  "sammons": ['S AE1 M AH0 N Z'],
  "samms": ['S AE1 M Z'],
  "sammut": ['S AE1 M AH0 T'],
  "sammy": ['S AE1 M IY0'],
  "samoa": ['S AH0 M OW1 AH0'],
  "samoan": ['S AH0 M OW1 AH0 N'],
  "samojlik": ['S AH0 M OY1 L IH0 K'],
  "samons": ['S AA1 M OW0 N Z'],
  "samora": ['S AA0 M AO1 R AH0'],
  "samos": ['S EY1 M AA0 S'],
  "samosa": ['S AH0 M OW1 S AH0'],
  "samoth": ['S AE1 M AH0 TH'],
  "samp": ['S AE1 M P'],
  "sampan": ['S AE1 M P AE0 N'],
  "sampans": ['S AE1 M P AE0 N Z'],
  "samper": ['S AE1 M P ER0'],
  "samper's": ['S AE1 M P ER0 Z'],
  "sampere": ['S AE0 M P IY1 R'],
  "sampey": ['S AE1 M P IY0'],
  "sample": ['S AE1 M P AH0 L'],
  "sample's": ['S AE1 M P AH0 L Z'],
  "sampled": ['S AE1 M P AH0 L D'],
  "sampler": ['S AE1 M P L ER0'],
  "samplers": ['S AE1 M P L ER0 Z'],
  "samples": ['S AE1 M P AH0 L Z'],
  "sampley": ['S AE1 M P L IY0'],
  "sampling": ['S AE1 M P L IH0 NG'],
  "samplings": ['S AE1 M P L IH0 NG Z'],
  "sampras": ['S AE1 M P R AH0 S'],
  "sampras'": ['S AE1 M P R AH0 S'],
  "sampras's": ['S AE1 M P R AH0 S IH0 Z'],
  "sampre": ['S AE1 M P R IY0', 'S AE1 M P ER0'],
  "sampsel": ['S AE1 M P S AH0 L'],
  "sampsell": ['S AE1 M P S AH0 L'],
  "sampson": ['S AE1 M P S AH0 N'],
  "samra": ['S AE1 M R AH0'],
  "sams": ['S AE1 M Z'],
  "samsara": ['S AH0 M S AA1 R AH0'],
  "samsel": ['S AE1 M S AH0 L'],
  "samsom": ['S AE1 M S AH0 M'],
  "samson": ['S AE1 M S AH0 N'],
  "samsonite": ['S AE1 M S AH0 N AY2 T'],
  "samsung": ['S AE1 M S AH2 NG'],
  "samsung's": ['S AE1 M S AH2 NG Z'],
  "samudio": ['S AA0 M UW1 D IY0 OW0'],
  "samuel": ['S AE1 M Y UW0 L'],
  "samuel's": ['S AE1 M Y UW0 L Z'],
  "samuela": ['S AE2 M Y UW0 EH1 L AH0'],
  "samuelle": ['S AE1 M Y UW0 EH2 L'],
  "samuels": ['S AE1 M Y UW0 AH0 L Z'],
  "samuelsen": ['S AE1 M UH0 L S AH0 N'],
  "samuelson": ['S AE1 M Y UW0 AH0 L S AH0 N'],
  "samurai": ['S AE1 M UH0 R AY2', 'S AE1 M ER0 AY2'],
  "samurai's": ['S AE1 M ER0 AY2 Z'],
  "samurais": ['S AE1 M ER0 AY2 Z', 'S AE1 M ER0 IH0 Z'],
  "san": ['S AE1 N'],
  "san-andreas": ['S AE2 N AA0 N D R EY1 AH0 S'],
  "san-diego": ['S AE1 N D IY0 EY1 G OW0'],
  "san-fran": ['S AE1 N F R AE1 N'],
  "san-francisco": ['S AE1 N F R AE0 N S IH1 S K OW0'],
  "san-jose": ['S AE2 N HH OW0 Z EY1'],
  "san-juan": ['S AE1 N W AA1 N'],
  "san-salvador": ['S AE1 N S AE1 L V AH0 D AO2 R'],
  "sana": ['S AA1 N AH0'],
  "sanaa": ['S AH0 N AA1'],
  "sanabria": ['S AH0 N AE1 B R IY0 AH0'],
  "sanada": ['S AA0 N AA1 D AH0'],
  "sanborn": ['S AE1 N B AO2 R N'],
  "sanches": ['S AA1 N CH EH0 S'],
  "sanchez": ['S AE1 N CH EH0 Z'],
  "sanchez's": ['S AE1 N CH EH0 Z IH0 Z'],
  "sancho": ['S AA1 N K OW0'],
  "sancia": ['S AA1 N CH AH0'],
  "sanctification": ['S AE2 NG K T AH0 F AH0 K EY1 SH AH0 N'],
  "sanctify": ['S AE1 NG K T AH0 F AY0'],
  "sanctimonious": ['S AE2 NG K T AH0 M OW1 N IY0 AH0 S'],
  "sanctimony": ['S AE1 NG K T IH0 M OW2 N IY0'],
  "sanction": ['S AE1 NG K SH AH0 N'],
  "sanction's": ['S AE1 NG K SH AH0 N Z'],
  "sanctioned": ['S AE1 NG K SH AH0 N D'],
  "sanctioning": ['S AE1 NG K SH AH0 N IH0 NG'],
  "sanctions": ['S AE1 NG K SH AH0 N Z'],
  "sanctions'": ['S AE1 NG K SH AH0 N Z'],
  "sanctity": ['S AE1 NG K T IH0 T IY0'],
  "sanctuaries": ['S AE1 NG K CH UW0 EH2 R IY0 Z'],
  "sanctuary": ['S AE1 NG K CH UW0 EH2 R IY0'],
  "sanctum": ['S AE1 NG K T AH0 M'],
  "sand": ['S AE1 N D'],
  "sand's": ['S AE1 N D Z'],
  "sanda": ['S AE1 N D AH0'],
  "sandage": ['S AE1 N D IH0 JH'],
  "sandahl": ['S AE1 N D AA2 L'],
  "sandal": ['S AE1 N D AH0 L'],
  "sandall": ['S AE1 N D AA0 L'],
  "sandalo": ['S AE2 N D AE1 L OW0'],
  "sandals": ['S AE1 N D AH0 L Z'],
  "sandau": ['S AE1 N D AW0'],
  "sandbag": ['S AE1 N D B AE2 G'],
  "sandbagged": ['S AE1 N D B AE2 G D'],
  "sandbagger": ['S AE1 N D B AE2 G ER0'],
  "sandbaggers": ['S AE1 N D B AE2 G ER0 Z'],
  "sandbagging": ['S AE1 N D B AE2 G IH0 NG'],
  "sandbags": ['S AE1 N D B AE2 G Z'],
  "sandbar": ['S AE1 N D B AA2 R'],
  "sandberg": ['S AE1 N D B ER0 G'],
  "sandblast": ['S AE1 N D B L AE2 S T'],
  "sandblasted": ['S AE1 N D B L AE2 S T IH0 D'],
  "sandbox": ['S AE1 N D B AA2 K S'],
  "sandburg": ['S AE1 N D B ER0 G'],
  "sande": ['S AE1 N D'],
  "sanded": ['S AE1 N D IH0 D'],
  "sandeen": ['S AE1 N D IY0 N'],
  "sandefer": ['S AE1 N D IY0 F ER0'],
  "sandefur": ['S AE1 N D EH0 F ER0'],
  "sandel": ['S AE1 N D EH0 L'],
  "sandell": ['S AE1 N D EH0 L'],
  "sanden": ['S AE1 N D AH0 N'],
  "sander": ['S AE1 N D ER0'],
  "sanderfer": ['S AE1 N D ER0 F ER0'],
  "sanderford": ['S AE1 N D ER0 F AO0 R D'],
  "sanderlin": ['S AE1 N D ER0 L IH0 N'],
  "sanders": ['S AE1 N D ER0 Z'],
  "sanders's": ['S AE1 N D ER0 Z IH0 Z'],
  "sanderson": ['S AE1 N D ER0 S AH0 N'],
  "sandford": ['S AE1 N D F ER0 D'],
  "sandgren": ['S AE1 N D G R EH0 N'],
  "sandhill": ['S AE1 N D HH IH2 L'],
  "sandhog": ['S AE1 N D HH AO2 G'],
  "sandhogs": ['S AE1 N D HH AO2 G Z'],
  "sandhu": ['S AE1 N D HH UW0'],
  "sandi": ['S AE1 N D IY0'],
  "sandia": ['S AE1 N D IY0 AH0'],
  "sandidge": ['S AE1 N D IH0 JH'],
  "sandie": ['S AE1 N D IY0'],
  "sandifer": ['S AE1 N D AY0 F ER0'],
  "sandiford": ['S AE1 N D IH0 F ER0 D'],
  "sandin": ['S AE1 N D IH2 N'],
  "sanding": ['S AE1 N D IH0 NG'],
  "sandinista": ['S AE2 N D IH0 N IH1 S T AH0', 'S AE2 N D IH0 N IY1 S T AH0'],
  "sandinistas": ['S AE2 N D IH0 N IY1 S T AH0 Z'],
  "sandinistas'": ['S AE2 N D IH0 N IY1 S T AH0 Z'],
  "sandino": ['S AE0 N D IY1 N OW0'],
  "sandino's": ['S AE0 N D IY1 N OW0 Z'],
  "sandler": ['S AE1 N D L ER0'],
  "sandlin": ['S AE1 N D L IH0 N'],
  "sandling": ['S AE1 D L IH0 NG'],
  "sandman": ['S AE1 N D M AE2 N'],
  "sandmann": ['S AE1 N D M AH0 N'],
  "sandmeyer": ['S AE1 N D M AY0 ER0'],
  "sandner": ['S AE1 N D N ER0'],
  "sandness": ['S AE1 N D N AH0 S'],
  "sando": ['S AE1 N D OW0'],
  "sandom": ['S AE1 N D AH0 M'],
  "sandon": ['S AE1 N D AO2 N'],
  "sandor": ['S AE1 N D ER0'],
  "sandos": ['S AE1 N D OW0 Z', 'S AE1 N D OW0 S'],
  "sandose": ['S AE1 N D OW0 Z'],
  "sandoval": ['S AE1 N D OW2 V AH0 L'],
  "sandow": ['S AE1 N D OW0'],
  "sandoz": ['S AE1 N D OW0 Z'],
  "sandoz's": ['S AE1 N D AH0 Z IH0 Z'],
  "sandpaper": ['S AE1 N D P EY2 P ER0'],
  "sandpoint": ['S AE1 N D P OY2 N T'],
  "sandquist": ['S AE1 N D K W IH2 S T'],
  "sandra": ['S AE1 N D R AH0'],
  "sandridge": ['S AE1 N D R IH2 JH'],
  "sandro": ['S AE1 N D R OW0'],
  "sandrock": ['S AE1 N D R AA2 K'],
  "sands": ['S AE1 N D Z'],
  "sandstone": ['S AE1 N D S T OW2 N', 'S AE1 N S T OW2 N'],
  "sandstorm": ['S AE1 N D S T AO2 R M'],
  "sandstorms": ['S AE1 N D S T AO2 R M Z'],
  "sandstrom": ['S AE1 N D S T R AH0 M'],
  "sandt": ['S AE1 N T'],
  "sandtown": ['S AE1 N D T AW2 N', 'S AE1 N T AW2 N'],
  "sandusky": ['S AE0 N D AH1 S K IY0'],
  "sandvig": ['S AE1 N D V IH2 G'],
  "sandvik": ['S AE1 N D V IH0 K'],
  "sandwich": ['S AE1 N D W IH0 CH', 'S AE1 N W IH0 CH', 'S AE1 M W IH0 CH'],
  "sandwiched": ['S AE1 N D W IH2 CH T', 'S AE1 N W IH2 CH T', 'S AE1 M W IH2 CH T'],
  "sandwiches": ['S AE1 N D W IH0 CH IH0 Z', 'S AE1 N W IH0 CH IH0 Z', 'S AE1 M W IH0 CH IH0 Z'],
  "sandwick": ['S AE1 N D W IH2 K'],
  "sandy": ['S AE1 N D IY0'],
  "sandy's": ['S AE1 N D IY0 Z'],
  "sane": ['S EY1 N'],
  "saner": ['S EY1 N ER0'],
  "sanfilippo": ['S AE2 N F IH0 L IH1 P OW0'],
  "sanford": ['S AE1 N F ER0 D'],
  "sanford's": ['S AE1 N F ER0 D Z'],
  "sang": ['S AE1 NG'],
  "sang-gon": ['S AA1 NG G AO1 N'],
  "sanger": ['S AE1 NG ER0'],
  "sangfroid": ['S AA2 N F R AW1'],
  "sangiovese": ['S AE2 N JH IY1 OW0 V IY2 S'],
  "sangster": ['S AE1 NG S T ER0'],
  "sanguine": ['S AE1 NG G W IH0 N'],
  "sanguinetti": ['S AA0 NG IY0 N EH1 T IY0'],
  "sangyo": ['S AE1 N JH Y OW0'],
  "sanhedrin": ['S AE2 N HH IY1 D R IH0 N'],
  "sani": ['S AE1 N IY0', 'S AE1 N IH0'],
  "sanitary": ['S AE1 N IH0 T EH2 R IY0'],
  "sanitation": ['S AE2 N AH0 T EY1 SH AH0 N', 'S AE2 N IH0 T EY1 SH AH0 N'],
  "sanitize": ['S AE1 N IH0 T AY2 Z'],
  "sanitized": ['S AE1 N IH0 T AY2 Z D'],
  "sanitizing": ['S AE1 N AH0 T AY2 Z IH0 NG'],
  "sanity": ['S AE1 N AH0 T IY0'],
  "sanjay": ['S AE1 N JH EY0'],
  "sanjiv": ['S AA2 N JH IY1 V'],
  "sanjuan": ['S AA0 N Y UW0 AA1 N'],
  "sank": ['S AE1 NG K'],
  "sanka": ['S AE1 NG K AH0'],
  "sankara": ['S AA0 N K AA1 R AH0'],
  "sankara's": ['S AA0 N K AA1 R AH0 Z'],
  "sankei": ['S AE1 NG K IY0'],
  "sanker": ['S AE1 NG K ER0'],
  "sankey": ['S AE1 NG K IY0'],
  "sanko": ['S AE1 NG K OW0'],
  "sanks": ['S AE1 NG K S'],
  "sankyo": ['S AE1 NG K Y OW0'],
  "sanmark": ['S AE1 N M AA2 R K'],
  "sanmartin": ['S AE1 N M AA0 R T IH0 N'],
  "sanmiguel": ['S AA0 N M IY0 G EH1 L'],
  "sanna": ['S AE1 N AH0'],
  "sanner": ['S AE1 N ER0'],
  "sannes": ['S AE1 N Z'],
  "sano": ['S AA1 N OW0'],
  "sanofi": ['S AH0 N OW1 F IY0'],
  "sanrio": ['S AE1 N R IY0 OW0'],
  "sans": ['S AE1 N Z'],
  "sans-culottes": ['S AA1 N K UW0 L AO1 T'],
  "sansbury": ['S AE1 N S B EH0 R IY0'],
  "sanseverino": ['S AA0 N S EH0 V ER0 IY1 N OW0'],
  "sansing": ['S AE1 N S IH0 NG'],
  "sanskrit": ['S AE1 N S K R IH0 T'],
  "sanso": ['S AE1 N S OW0'],
  "sansom": ['S AE1 N S AH0 M'],
  "sanson": ['S AE1 N S AH0 N'],
  "sansone": ['S AE1 N S AH0 N'],
  "sansoucie": ['S AE1 N S OW0 K IY0'],
  "sansui": ['S AE0 N S UW1 IY0'],
  "sansui's": ['S AE0 N S UW1 IY0 Z'],
  "sant": ['S AE1 N T'],
  "santa": ['S AE1 N T AH0', 'S AE1 N AH0'],
  "santa's": ['S AE1 N T AH0 Z'],
  "santa-croce": ['S AE1 N T AH0 K R OW1 CH IY0'],
  "santa-cruz": ['S AE1 N T AH0 K R UW1 Z'],
  "santa-fe": ['S AE1 N T AH0 F EY1'],
  "santa-fe's": ['S AE1 N T AH0 F EY1 Z'],
  "santa-maria": ['S AE1 N T AH0 M ER0 IY1 AH0'],
  "santagata": ['S AA0 N T AA0 G AA1 T AH0'],
  "santala": ['S AE0 N T AA1 L AH0'],
  "santana": ['S AE0 N T AE1 N AH0'],
  "santander": ['S AE2 N T AE1 N D ER0'],
  "santangelo": ['S AA0 N T AA0 NG G EH1 L OW0'],
  "santaniello": ['S AA0 N T AA0 N IY0 EH1 L OW0'],
  "santarelli": ['S AA0 N T AA0 R EH1 L IY0'],
  "santarsiero": ['S AA0 N T AA0 R S IH1 R OW0'],
  "santas": ['S AE1 N T AH0 S'],
  "santayana": ['S AE0 N T AY2 AA1 N AH0'],
  "sante": ['S AE1 N T AH0'],
  "santella": ['S AA0 N T EH1 L AH0'],
  "santelli": ['S AA0 N T EH1 L IY0'],
  "santer": ['S AE1 N T ER0'],
  "santeria": ['S AE2 N T EH1 R IY0 AH0'],
  "santerre": ['S AE0 N T EH1 R'],
  "santi": ['S AE1 N T IY0'],
  "santia": ['S AA1 N T Y AH0'],
  "santiago": ['S AE2 N T IY0 AA1 G OW0'],
  "santidad": ['S AA1 N T IH0 D AE0 D'],
  "santiesteban": ['S AE1 N T IY0 S T IH0 B AH0 N'],
  "santillan": ['S AE2 N T IH1 L AH0 N'],
  "santillanes": ['S AE1 N T IH0 L EY2 N Z'],
  "santilli": ['S AA0 N T IY1 L IY0'],
  "santillo": ['S AE2 N T IH1 L OW0'],
  "santin": ['S AE1 N T IH0 N'],
  "santini": ['S AE0 N T IY1 N IY0'],
  "santino": ['S AA0 N T IY1 N OW0'],
  "santistevan": ['S AA2 N T IY0 S T EY0 V AA1 N'],
  "santo": ['S AE1 N T OW0'],
  "santon": ['S AE1 N T AH0 N'],
  "santone": ['S AA0 N T OW1 N IY0'],
  "santoni": ['S AA0 N T OW1 N IY0'],
  "santopietro": ['S AA0 N T OW0 P IY1 T R OW0'],
  "santor": ['S AE1 N T ER0'],
  "santora": ['S AA0 N T AO1 R AH0'],
  "santore": ['S AA0 N T AO1 R IY0'],
  "santorelli": ['S AA0 N T AO0 R EH1 L IY0'],
  "santoro": ['S AE0 N T AO1 R OW0'],
  "santorum": ['S AE0 N T AO1 R AH0 M'],
  "santorum's": ['S AE0 N T AO1 R AH0 M Z'],
  "santos": ['S AE1 N T OW0 S'],
  "santosh": ['S AA1 N T AA2 SH'],
  "santosuosso": ['S AA0 N T OW0 S W OW1 S OW0'],
  "santow": ['S AE1 N T OW0'],
  "santoyo": ['S AA0 N T OW1 Y OW0'],
  "santry": ['S AE1 N T R IY0'],
  "santucci": ['S AA0 N T UW1 CH IY0'],
  "santulli": ['S AA0 N T UW1 L IY0'],
  "santy": ['S AE1 N T IY0'],
  "sanville": ['S AA1 N V IH0 L'],
  "sanwa": ['S AE1 N W AH0'],
  "sanwa's": ['S AE1 N W AH0 Z'],
  "sanyo": ['S AA1 N Y OW0'],
  "sanz": ['S AE1 N Z'],
  "sanzo": ['S AE1 N Z OW0'],
  "sanzone": ['S AA0 N Z OW1 N IY0'],
  "sao": ['S AW1'],
  "saone": ['S EY1 OW2 N'],
  "saouma": ['S AW2 Y UW1 M AH0'],
  "sap": ['S AE1 P'],
  "sapanski": ['S AH0 P AE1 N S K IY0'],
  "saperstein": ['S EY1 P ER0 S T AY0 N', 'S EY1 P ER0 S T IY0 N'],
  "saphira": ['S AA0 F IH1 R AH0'],
  "sapia": ['S AA1 P IY0 AH0'],
  "sapien": ['S AE1 P IY0 N'],
  "sapiens": ['S EY1 P IY0 AH0 N Z'],
  "sapienza": ['S AA0 P IY1 N Z AH0'],
  "sapiro": ['S AH0 P IH1 R OW0'],
  "sapling": ['S AE1 P L IH0 NG'],
  "saplings": ['S AE1 P L IH0 NG Z'],
  "sapoa": ['S AH0 P OW1 AH0'],
  "sapone": ['S AA0 P OW1 N IY0'],
  "saponification": ['S AH0 P AA2 N AH0 F AH0 K EY1 SH AH0 N'],
  "saporito": ['S AA0 P AO0 R IY1 T OW0'],
  "sapp": ['S AE1 P'],
  "sapped": ['S AE1 P T'],
  "sappenfield": ['S AH0 P EH1 N F IY0 L D'],
  "sapper": ['S AE1 P ER0'],
  "sapphire": ['S AE1 F AY0 ER0'],
  "sapphires": ['S AE1 F AY0 ER0 Z'],
  "sapping": ['S AE1 P IH0 NG'],
  "sappington": ['S AE1 P IH0 NG T AH0 N'],
  "sapporo": ['S AH0 P AO1 R OW0'],
  "saps": ['S AE1 P S'],
  "saputo": ['S AA0 P UW1 T OW0'],
  "sar": ['S AA1 R'],
  "sara": ['S EH1 R AH0'],
  "sara's": ['S EH1 R AH0 Z'],
  "sara-lee": ['S EH1 R AH0 L IY1'],
  "sarabia": ['S ER0 EY1 B IY0 AH0'],
  "saracco": ['S AA0 R AA1 K OW0'],
  "saraceni": ['S AA0 R AA0 CH EH1 N IY0'],
  "saraceno": ['S AA0 R AA0 CH EH1 N OW0'],
  "saracens": ['S EH1 R AH0 S AH0 N Z'],
  "saracino": ['S AA0 R AA0 CH IY1 N OW0'],
  "sarafian": ['S ER0 EY1 F IY0 AH0 N'],
  "sarafin": ['S AE1 R AH0 F IH0 N'],
  "sarafina": ['S AE2 R AH0 F IY1 N AH0'],
  "sarage": ['S AE1 R AH0 JH'],
  "sarage's": ['S AE1 R AH0 JH IH0 Z'],
  "sarah": ['S EH1 R AH0'],
  "sarah's": ['S EH1 R AH0 Z'],
  "sarajevan": ['S AA2 R AH0 Y EY1 V AH0 N'],
  "sarajevan's": ['S AA2 R AH0 Y EY1 V AH0 N Z'],
  "sarajevans": ['S AA2 R AH0 Y EY1 V AH0 N Z'],
  "sarajevo": ['S AA2 R AH0 Y EY1 V OW0'],
  "sarajevo's": ['S AA2 R AH0 Y EY1 V OW0 Z'],
  "saran": ['S ER0 AE1 N'],
  "sarandon": ['S EH1 R AH0 N D IH0 N', 'S EH2 R AE1 N D IH0 N'],
  "sarant": ['S AE1 R AH0 N T'],
  "sarasin": ['S EH1 R IH0 S IH0 N'],
  "sarasota": ['S EH2 R AH0 S OW1 T AH0'],
  "saratoga": ['S EH2 R AH0 T OW1 G AH0'],
  "saratov": ['S EH1 R AH0 T AO2 V', 'S EH1 R AH0 T AO2 F'],
  "saravia": ['S ER0 EY1 V IY0 AH0'],
  "sarawak": ['S EH1 R AH0 W AA0 K'],
  "sarazen": ['S EH1 R AH0 Z AH0 N'],
  "sarazin": ['S AA0 R AA0 Z IY1 N'],
  "sarbane": ['S AA1 R B EY2 N'],
  "sarbanes": ['S AA1 R B EY2 N Z'],
  "sarber": ['S AA1 R B ER0'],
  "sarcasm": ['S AA1 R K AE2 Z AH0 M'],
  "sarcastic": ['S AA0 R K AE1 S T IH0 K'],
  "sarcastically": ['S AA0 R K AE1 S T IH0 K L IY0'],
  "sarchet": ['S AA0 R SH EH1 T'],
  "sarcoma": ['S AA0 R K OW1 M ER0'],
  "sarcophagus": ['S AA0 R K AA1 F AH0 G AH0 S'],
  "sardas": ['S AA1 R D AH0 S'],
  "sardella": ['S AA2 R D EH1 L AH0'],
  "sardina": ['S AA0 R D IY1 N AH0'],
  "sardinas": ['S AA1 R D IH0 N AH0 Z'],
  "sardine": ['S AA0 R D IY1 N'],
  "sardines": ['S AA0 R D IY1 N Z'],
  "sardinha": ['S AA2 R D IH1 N HH AH0'],
  "sardinia": ['S AA0 R D IY1 N IY0 AH0'],
  "sardo": ['S AA1 R D OW0'],
  "sardonic": ['S AA0 R D AA1 N IH0 K'],
  "sardonically": ['S AA0 R D AA1 N IH0 K AH0 L IY0', 'S AA0 R D AA1 N IH0 K L IY0'],
  "sardoodledom": ['S AA2 R D UW1 D AH0 L D AH0 M'],
  "sare": ['S EH1 R'],
  "sarejevo": ['S AA2 R AH0 Y EY1 V OW0'],
  "sarejevo's": ['S AA2 R AH0 Y EY1 V OW0 Z'],
  "sarene": ['S ER0 IY1 N'],
  "sarette": ['S ER0 EH1 T'],
  "sarff": ['S AA1 R F'],
  "sargasso": ['S AA0 R G AE1 S OW0'],
  "sarge": ['S AA1 R JH'],
  "sargeant": ['S AA1 R JH AH0 N T'],
  "sargen": ['S AA1 R G AH0 N'],
  "sargent": ['S AA1 R JH AH0 N T'],
  "sargis": ['S AA1 R G IH0 S'],
  "sari": ['S AA1 R IY0'],
  "sari's": ['S AA1 R IY0 Z'],
  "sarich": ['S AE1 R IH0 K'],
  "sarin": ['S AA0 R IH0 N'],
  "sarine": ['S AA0 R IY1 N IY0'],
  "saris": ['S AA1 R IY0 Z'],
  "sarisi": ['S AH0 R IY1 S IY0'],
  "sark": ['S AA1 R K'],
  "sarka": ['S AA1 R K AH0'],
  "sarkar": ['S AA1 R K AA2 R'],
  "sarkis": ['S AA1 R K IH0 S'],
  "sarkisian": ['S AA0 R K IH1 ZH IH0 N'],
  "sarkissian": ['S AA0 R K IH1 S ZH IH0 N'],
  "sarles": ['S AA1 R L Z'],
  "sarli": ['S AA1 R L IY0'],
  "sarlo": ['S AA1 R L OW0'],
  "sarlos": ['S AA1 R L OW0 S'],
  "sarmatian": ['S AA0 R M EY1 SH AH0 N'],
  "sarmento": ['S AA0 R M EH1 N T OW0'],
  "sarmiento": ['S AA0 R M IY1 N T OW0'],
  "sarna": ['S AA1 R N AH0'],
  "sarney": ['S AA1 R N IY0'],
  "sarney's": ['S AA1 R N IY0 Z'],
  "sarni": ['S AA1 R N IY0'],
  "sarno": ['S AA1 R N OW0'],
  "sarnoff": ['S AA1 R N AO0 F'],
  "sarnowski": ['S ER0 N AO1 F S K IY0'],
  "sarofim": ['S EH2 R AH0 F IY1 M'],
  "sarokin": ['S EH1 R AH0 K IH0 N'],
  "sarong": ['S ER0 AO1 NG'],
  "sarongs": ['S ER0 AO1 NG Z'],
  "saros": ['S AA1 R OW0 S'],
  "sarowiwa": ['S AE2 R OW0 W IY1 W AH0'],
  "sarra": ['S AA1 R AA2'],
  "sarraj": ['S AA2 R AY1'],
  "sarratt": ['S ER2 AE1 T'],
  "sarrazin": ['S AA1 R AH0 Z IH2 N'],
  "sarrett": ['S AE1 R IH0 T'],
  "sarris": ['S AE1 R IH0 S'],
  "sarro": ['S AA1 R OW0'],
  "sars": ['S AA1 R Z'],
  "sarsfield": ['S AA1 R S F IY0 L D'],
  "sartain": ['S AA0 R T EY1 N'],
  "sarti": ['S AA1 R T IY0'],
  "sartin": ['S AA1 R T IH0 N'],
  "sartor": ['S AA1 R T ER0'],
  "sartori": ['S AA0 R T AO1 R IY0'],
  "sartorial": ['S AA0 R T AO1 R IY0 AH0 L'],
  "sartorius": ['S AA0 R T AO1 R IY0 IH0 S'],
  "sartrain": ['S AA1 R T R EY2 N'],
  "sartre": ['S AA1 R T R AH0'],
  "sartwell": ['S AA1 R T W EH2 L'],
  "sarum": ['S AE1 R AH0 M'],
  "sarver": ['S AA1 R V ER0'],
  "sarvis": ['S AA1 R V IH0 S'],
  "sas": ['S AE1 S'],
  "sasaki": ['S AA0 S AA1 K IY0'],
  "sasayama": ['S AE2 S AH0 Y AA1 M AH0'],
  "sasek": ['S AE1 S IH0 K'],
  "sash": ['S AE1 SH'],
  "sasha": ['S AE1 SH AH0'],
  "sashes": ['S AE1 SH IH0 Z'],
  "saskatchewan": ['S AE0 S K AE1 CH AH0 W AO2 N'],
  "saskatoon": ['S AE1 S K AH0 T UW2 N'],
  "saslow": ['S AE1 S L OW0'],
  "sasnett": ['S AE1 S N IH0 T'],
  "sass": ['S AE1 S'],
  "sassafras": ['S AE1 S AH0 F R AE2 S'],
  "sassafras's": ['S AE1 S AH0 F R AE2 S AH0 Z', 'S AE1 S AH0 F R AE2 S IH0 Z'],
  "sassaman": ['S AE1 S AH0 M AH0 N'],
  "sassano": ['S AA0 S AA1 N OW0'],
  "sasse": ['S AE1 S'],
  "sasseen": ['S AE1 S IY0 N'],
  "sasser": ['S AE1 S ER0'],
  "sasser's": ['S AE1 S ER0 Z'],
  "sassi": ['S AE1 S IY0'],
  "sassman": ['S AE1 S M AH0 N'],
  "sassnet": ['S AE1 S N EH2 T'],
  "sassnet's": ['S AE1 S N EH2 T S'],
  "sassnets": ['S AE1 S N EH2 T S'],
  "sasso": ['S AE1 S OW0'],
  "sasson": ['S AE1 S AH0 N'],
  "sassone": ['S AA0 S OW1 N IY0'],
  "sassoon": ['S AH0 S UW1 N'],
  "sassou": ['S AE1 S UW0'],
  "sassounguesso": ['S AE2 S UW0 NG G EH1 S OW0'],
  "sassulo": ['S AH0 S UW1 L OW0'],
  "sassy": ['S AE1 S IY0'],
  "sassy's": ['S AE1 S IY0 Z'],
  "sat": ['S AE1 T'],
  "satagaj": ['S AE1 T AH0 G AY2'],
  "satan": ['S EY1 T AH0 N'],
  "satan's": ['S EY1 T AH0 N Z'],
  "satanic": ['S AH0 T AE1 N IH0 K'],
  "satanism": ['S EY1 T AH0 N IH2 Z AH0 M'],
  "satanist": ['S EY1 T AH0 N IH0 S T'],
  "satanjeev": ['S AA2 T AA0 N JH IY1 V'],
  "satans": ['S EY1 T AH0 N Z'],
  "satay": ['S AE0 T EY1', 'S AA0 T EY1'],
  "satchell": ['S AE1 CH AH0 L'],
  "satcher": ['S AE1 CH ER0'],
  "sate": ['S EY1 T'],
  "sated": ['S EY1 T IH0 D'],
  "satellite": ['S AE1 T AH0 L AY2 T'],
  "satellite's": ['S AE1 T AH0 L AY2 T S'],
  "satellites": ['S AE1 T AH0 L AY2 T S'],
  "sater": ['S AE1 T ER0'],
  "sates": ['S EY1 T S'],
  "sather": ['S AE1 DH ER0'],
  "sathre": ['S AE1 TH ER0'],
  "sathyavagiswaran": ['S AE0 TH Y AH0 V AA2 G IH0 S W AA2 R AH0 N'],
  "satiated": ['S EY1 SH IY2 EY2 T IH0 D'],
  "satiety": ['S AA0 T AY1 AH0 T IY2'],
  "satin": ['S AE1 T AH0 N'],
  "satins": ['S AE1 T AH0 N Z'],
  "satire": ['S AE1 T AY2 ER0'],
  "satires": ['S AE1 T AY2 ER0 Z'],
  "satiric": ['S AH0 T IH1 R IH0 K'],
  "satirical": ['S AH0 T IH1 R AH0 K AH0 L', 'S AH0 T IH1 R IH0 K AH0 L'],
  "satirist": ['S AE1 T ER0 AH0 S T'],
  "satirists": ['S AE1 T ER0 AH0 S T S'],
  "satirize": ['S AE1 T ER0 AY2 Z'],
  "satirizes": ['S AE1 T ER0 AY2 Z IH0 Z'],
  "satirizing": ['S AE1 T ER0 AY2 Z IH0 NG'],
  "satisfaction": ['S AE2 T AH0 S F AE1 K SH AH0 N', 'S AE2 T IH0 S F AE1 K SH AH0 N'],
  "satisfactions": ['S AE2 T AH0 S F AE1 K SH AH0 N Z'],
  "satisfactorily": ['S AE2 T IH0 S F AE1 K T R AH0 L IY0'],
  "satisfactory": ['S AE2 T AH0 S F AE1 K T R IY0', 'S AE2 T IH0 S F AE1 K T ER0 IY0'],
  "satisfied": ['S AE1 T AH0 S F AY2 D', 'S AE1 T IH0 S F AY2 D'],
  "satisfies": ['S AE1 T IH0 S F AY2 Z'],
  "satisfy": ['S AE1 T AH0 S F AY2', 'S AE1 T IH0 S F AY2'],
  "satisfying": ['S AE1 T IH0 S F AY2 IH0 NG'],
  "satloff": ['S AE1 T L AO2 F'],
  "sato": ['S AA1 T OW0'],
  "satoh": ['S AA1 T OW0'],
  "satoshi": ['S AA0 T OW1 SH IY0'],
  "satre": ['S EY1 T ER0'],
  "satriani": ['S AE2 T R IY0 AA1 N IY0'],
  "satsuma": ['S AE2 T S UW1 M AH0'],
  "satsumas": ['S AE2 T S UW1 M AH0 Z'],
  "satter": ['S AE1 T ER0'],
  "satterfield": ['S AE1 T ER0 F IY1 L D'],
  "satterlee": ['S AE1 T ER0 L IY1'],
  "satterly": ['S AE1 T ER0 L IY0'],
  "satterwhite": ['S AE1 T ER0 W AY1 T'],
  "sattler": ['S AE1 T L ER0'],
  "saturate": ['S AE1 CH ER0 EY2 T'],
  "saturated": ['S AE1 CH ER0 EY2 T IH0 D'],
  "saturating": ['S AE1 CH ER0 EY2 T IH0 NG'],
  "saturation": ['S AE2 CH ER0 EY1 SH AH0 N'],
  "saturday": ['S AE1 T ER0 D IY0', 'S AE1 T IH2 D EY2'],
  "saturday's": ['S AE1 T ER0 D IY0 Z', 'S AE1 T ER0 D EY0 Z'],
  "saturdays": ['S AE1 T ER0 D IY0 Z', 'S AE1 T ER0 D EY0 Z'],
  "saturn": ['S AE1 T ER0 N'],
  "saturn's": ['S AE1 T ER0 N Z'],
  "saturns": ['S AE1 T ER0 N Z'],
  "satya": ['S AA1 T Y AH0'],
  "satyandra": ['S AA2 T Y AA1 N D R AH0'],
  "satz": ['S AE1 T S'],
  "sauber": ['S AO1 B ER0'],
  "sauce": ['S AO1 S'],
  "sauceda": ['S AW0 S EY1 D AH0'],
  "saucedo": ['S AW0 S EY1 D OW0'],
  "saucepan": ['S AO1 S P AE2 N'],
  "saucer": ['S AO1 S ER0'],
  "saucers": ['S AO1 S ER0 Z'],
  "sauces": ['S AO1 S AH0 Z', 'S AO1 S IH0 Z'],
  "saucier": ['S AO1 S IY0 ER0'],
  "saucony": ['S AO1 K AH0 N IY0'],
  "saucy": ['S AO1 S IY0'],
  "saud": ['S AO1 D'],
  "sauder": ['S AO1 D ER0'],
  "saudi": ['S AO1 D IY0', 'S AW1 D IY0'],
  "saudia": ['S AO1 D IY0 AH0', 'S AW1 D IY0 AH0'],
  "saudiization": ['S AW2 IY0 IH0 Z EY1 SH AH0 N'],
  "saudis": ['S AO1 D IY0 Z', 'S AW1 D IY0 Z'],
  "saudis'": ['S AO1 D IY0 Z', 'S AW1 D IY0 Z'],
  "sauer": ['S AW1 ER0'],
  "sauerkraut": ['S AW1 ER0 K R AW2 T'],
  "sauers": ['S AW1 ER0 Z'],
  "sauerteig": ['S AW1 ER0 T EY2 G'],
  "sauerwein": ['S AW1 ER0 W AY0 N'],
  "sauey": ['S AO1 IY0'],
  "saugerties": ['S AO1 G ER0 T IY0 Z'],
  "sauk": ['S AO1 K'],
  "saul": ['S AO1 L'],
  "saulnier": ['S AW1 L N IY0 ER0'],
  "sauls": ['S AO1 L Z'],
  "saulsberry": ['S AO1 L S B EH2 R IY0'],
  "saulsbury": ['S AO1 L S B EH0 R IY0'],
  "sault": ['S AO1 L T'],
  "saulter": ['S AO1 L T ER0'],
  "saulters": ['S AW1 L T ER0 Z'],
  "saum": ['S AO1 M'],
  "sauna": ['S AO1 N AH0'],
  "saunas": ['S AO1 N AH0 Z'],
  "saunders": ['S AO1 N D ER0 Z'],
  "saunders'": ['S AO1 N D ER0 Z'],
  "saunders's": ['S AO1 N D ER0 Z IH0 Z'],
  "saunderson": ['S AO1 N D ER0 S AH0 N'],
  "saundra": ['S AO1 N D R AH0'],
  "saundra's": ['S AO1 N D R AH0 Z'],
  "saunier": ['S AO1 N IY0 ER0'],
  "saunter": ['S AO1 N T ER0'],
  "saur": ['S AO1 R'],
  "saurer": ['S AW1 ER0 R'],
  "sauro": ['S AO1 R OW0'],
  "saus": ['S AO1 Z'],
  "sausage": ['S AO1 S AH0 JH', 'S AO1 S IH0 JH'],
  "sausages": ['S AO1 S IH0 JH IH0 Z'],
  "sausalito": ['S AO2 S AH0 L IY1 T OW0'],
  "sause": ['S AO1 Z'],
  "sauseda": ['S AW0 S EY1 D AH0'],
  "sauser": ['S AW1 S ER0'],
  "sausser": ['S AO1 S ER0'],
  "saute": ['S AO0 T EY1'],
  "sauteed": ['S AO0 T EY1 D'],
  "sauter": ['S AO0 T EY1 ER0'],
  "sauterne": ['S OW0 T ER1 N'],
  "sauternes": ['S OW0 T ER1 N Z'],
  "sautter": ['S AO1 T ER0'],
  "sauvage": ['S AO1 V IH0 JH'],
  "sauvageau": ['S OW1 V AH0 ZH OW0'],
  "sauve": ['S AO1 V'],
  "sauvignon": ['S AO2 V IH1 N Y AA0 N'],
  "sava": ['S AA1 V AH0'],
  "savage": ['S AE1 V AH0 JH', 'S AE1 V IH0 JH'],
  "savaged": ['S AE1 V IH0 JH D'],
  "savagely": ['S AE1 V IH0 JH L IY0'],
  "savagery": ['S AE1 V IH0 JH EH2 R IY0'],
  "savages": ['S AE1 V AH0 JH AH0 Z', 'S AE1 V IH0 JH IH0 Z'],
  "savaging": ['S AE1 V IH0 JH IH0 NG'],
  "savaiko": ['S AH0 V EY1 K OW0'],
  "savala": ['S AA0 V AA1 L AH0'],
  "savalas": ['S AH0 V AA1 L AH0 S'],
  "savanna": ['S AH0 V AE1 N AH0'],
  "savannah": ['S AH0 V AE1 N AH0'],
  "savannas": ['S AH0 V AE1 N AH0 Z'],
  "savant": ['S AH0 V AA1 N T'],
  "savants": ['S AE1 V AH0 N T S'],
  "savard": ['S AE1 V ER0 D'],
  "savarese": ['S AA0 V AA0 R EY1 Z IY0'],
  "savarino": ['S AA0 V AA0 R IY1 N OW0'],
  "savary": ['S AE1 V EH0 R IY0'],
  "savas": ['S AA1 V AA0 Z'],
  "savasta": ['S AH0 V AE1 S T AH0'],
  "savastano": ['S AA0 V AA0 S T AA1 N OW0'],
  "save": ['S EY1 V'],
  "save's": ['S EY1 V Z'],
  "saved": ['S EY1 V D'],
  "savedra": ['S AH0 V EH1 D R AH0'],
  "savel": ['S AA0 V EH1 L'],
  "savell": ['S AA0 V EY1 L'],
  "savely": ['S EY1 V L IY0'],
  "saver": ['S EY1 V ER0'],
  "savers": ['S EY1 V ER0 Z'],
  "savery": ['S EY1 V ER0 IY0'],
  "saves": ['S EY1 V Z'],
  "saviano": ['S AA0 V IY0 AA1 N OW0'],
  "savich": ['S AE1 V IH0 CH'],
  "savickas": ['S AE1 V IH0 K AH0 Z'],
  "savidge": ['S AE1 V IH0 JH'],
  "savier": ['S EY1 V Y ER0'],
  "savikas": ['S AH0 V IY1 K AH0 S'],
  "savill": ['S AA0 V IY1 L'],
  "saville": ['S AA1 V IH0 L'],
  "savimbi": ['S AH0 V IH1 M B IY0'],
  "savin": ['S AE1 V IH0 N'],
  "savin's": ['S AE1 V IH0 N Z'],
  "savina": ['S AH0 V IY1 N AH0'],
  "saving": ['S EY1 V IH0 NG'],
  "savings": ['S EY1 V IH0 NG Z'],
  "savings'": ['S EY1 V IH0 NG Z'],
  "savings's": ['S EY1 V IH0 NG Z', 'S EY1 V IH0 NG Z IH0 Z'],
  "savini": ['S AA0 V IY1 N IY0'],
  "savino": ['S AA0 V IY1 N OW0'],
  "savio": ['S AA1 V IY0 OW0'],
  "savion": ['S AE1 V IY0 AO0 N', 'S AE1 V Y AO0 N'],
  "savior": ['S EY1 V Y ER0'],
  "savior's": ['S EY1 V Y ER0 Z'],
  "saviors": ['S EY1 V Y ER0 Z'],
  "savir": ['S EY1 V ER0', 'S AH0 V IY1 R'],
  "savitch": ['S AE1 V IH0 CH'],
  "savitsky": ['S AH0 V IH1 T S K IY0'],
  "savitt": ['S AH0 V IH1 T'],
  "savitz": ['S AE1 V IH0 T S'],
  "savko": ['S AE1 V K OW0'],
  "savo": ['S AA1 V OW0'],
  "savoca": ['S AA0 V OW1 K AH0'],
  "savoia": ['S AA0 V OW1 Y AH0'],
  "savoie": ['S AA1 V W AA0'],
  "savon": ['S EY1 V AO0 N'],
  "savona": ['S AA0 V OW1 N AH0'],
  "savor": ['S EY1 V ER0'],
  "savored": ['S EY1 V ER0 D'],
  "savoring": ['S EY1 V ER0 IH0 NG'],
  "savors": ['S EY1 V ER0 Z'],
  "savory": ['S EY1 V ER0 IY0'],
  "savoy": ['S AH0 V OY1'],
  "savr": ['S EY1 V ER0'],
  "savviest": ['S AE1 V IY0 IH0 S T'],
  "savvy": ['S AE1 V IY0'],
  "saw": ['S AO1'],
  "sawa": ['S AO1 W AH0'],
  "sawall": ['S AO1 W AO0 L'],
  "sawasdee": ['S AH0 W AA1 Z D IY0'],
  "sawatzky": ['S AH0 W AA1 T S K IY0'],
  "sawaya": ['S AO0 W AA1 Y AH0'],
  "sawchuk": ['S AO1 CH AH0 K'],
  "sawdey": ['S AO1 D IY0'],
  "sawdon": ['S AO1 D AH0 N'],
  "sawdust": ['S AO1 D AH2 S T'],
  "sawdy": ['S AO1 D IY0'],
  "sawed": ['S AO1 D'],
  "sawhill": ['S AO1 HH IH2 L'],
  "sawicki": ['S AO0 IH1 T S K IY0'],
  "sawicz": ['S AW1 IH0 T S'],
  "sawin": ['S AO1 IY0 N'],
  "sawing": ['S AO1 IH0 NG'],
  "sawka": ['S AO1 K AH0'],
  "sawmill": ['S AO1 M IH2 L'],
  "sawmills": ['S AO1 M IH2 L Z'],
  "saws": ['S AO1 Z'],
  "sawshank": ['S AO1 SH AE2 N K'],
  "sawtell": ['S AO1 T EH2 L'],
  "sawtelle": ['S AO2 T EH1 L'],
  "sawyer": ['S AO1 Y ER0', 'S OY1 ER0'],
  "sawyer's": ['S AO1 Y ER0 Z'],
  "sawyers": ['S AO1 Y ER0 Z'],
  "sax": ['S AE1 K S'],
  "saxby": ['S AE1 K S B IY0'],
  "saxe": ['S AE1 K S'],
  "saxena": ['S AE1 K S IH0 N AH0'],
  "saxer": ['S AE1 K S ER0'],
  "saxman": ['S AE1 K S M AH0 N'],
  "saxon": ['S AE1 K S AH0 N'],
  "saxon's": ['S AE1 K S AH0 N Z'],
  "saxona": ['S AE1 K S AH0 N AH0'],
  "saxons": ['S AE1 K S AH0 N Z'],
  "saxony": ['S AE1 K S AH0 N IY0'],
  "saxophone": ['S AE1 K S AH0 F OW2 N'],
  "saxophones": ['S AE1 K S AH0 F OW2 N Z'],
  "saxophonist": ['S AE1 K S AH0 F OW2 N IH0 S T'],
  "saxton": ['S AE1 K S T AH0 N'],
  "say": ['S EY1'],
  "sayad": ['S AY1 AE0 D'],
  "sayad's": ['S AY1 AE0 D Z'],
  "saybrook": ['S EY1 B R UH2 K'],
  "saye": ['S EY1'],
  "sayed": ['S AA2 Y EH1 D', 'S EY2 Y IH1 D', 'S EY1 D'],
  "sayegh": ['S EY1 IH0 G'],
  "sayer": ['S EY1 ER0'],
  "sayers": ['S EY1 ER0 Z'],
  "sayiid": ['S AY1 IH0 D', 'S AY0 IY1 D'],
  "sayin'": ['S EY1 IH0 N'],
  "saying": ['S EY1 IH0 NG'],
  "sayings": ['S EY1 IH0 NG Z'],
  "sayito": ['S AY0 IY1 T OW0'],
  "sayle": ['S EY1 L'],
  "sayler": ['S EY1 L ER0'],
  "sayles": ['S EY1 L Z'],
  "saylor": ['S EY1 L ER0'],
  "saylors": ['S EY1 L ER0 Z'],
  "sayre": ['S EH1 R'],
  "sayres": ['S EH1 R Z'],
  "says": ['S EH1 Z', 'S IH1 Z'],
  "sayyid": ['S AY1 IH0 D'],
  "sazama": ['S AA0 Z AA1 M AH0'],
  "sba": ['EH2 S B IY2 EY1'],
  "sbarro": ['S B AA1 R OW0'],
  "sbf": ['EH2 S B IY2 EH1 F'],
  "scab": ['S K AE1 B'],
  "scabbard": ['S K AE1 B ER0 D'],
  "scabs": ['S K AE1 B Z'],
  "scaccia": ['S K AA1 CH AH0'],
  "scad": ['S K AE1 D'],
  "scadden": ['S K AE1 D AH0 N'],
  "scads": ['S K AE1 D Z'],
  "scaduto": ['S K AA0 D UW1 T OW0'],
  "scaff": ['S K AE1 F'],
  "scaffidi": ['S K AA0 F IY1 D IY0'],
  "scaffold": ['S K AE1 F AH0 L D'],
  "scaffolding": ['S K AE1 F AH0 L D IH0 NG'],
  "scaffolds": ['S K AE1 F AH0 L D Z'],
  "scafidi": ['S K AA0 F IY1 D IY0'],
  "scaggs": ['S K AE1 G Z'],
  "scaglione": ['S K AE2 G L IY0 OW1 N IY0'],
  "scags": ['S K AE1 G Z'],
  "scahill": ['S K EY1 HH IH2 L'],
  "scaife": ['S K EY1 F'],
  "scala": ['S K AA1 L AH0'],
  "scalability": ['S K EY1 L AH0 B IH1 L IH0 T IY0'],
  "scalable": ['S K EY1 L AH0 B AH0 L'],
  "scalamandre": ['S K AA1 L AH0 M AA2 N D ER0', 'S K AE1 L AH0 M AE2 N D ER0'],
  "scalar": ['S K EY1 L ER0'],
  "scald": ['S K AO1 L D'],
  "scalded": ['S K AO1 L D IH0 D'],
  "scalding": ['S K AO1 L D IH0 NG'],
  "scalds": ['S K AO1 L D Z'],
  "scale": ['S K EY1 L'],
  "scaleatron": ['S K EY1 L IY0 AH0 T R AA0 N'],
  "scaled": ['S K EY1 L D'],
  "scalera": ['S K AA0 L EH1 R AH0'],
  "scales": ['S K EY1 L Z'],
  "scalese": ['S K AA0 L EY1 Z IY0'],
  "scalf": ['S K AE1 L F'],
  "scalfaro": ['S K AE2 L F AA1 R OW0'],
  "scali": ['S K AA1 L IY0', 'S K EY1 L IY0'],
  "scalia": ['S K AA1 L IY0 AH0', 'S K AA1 L Y AH0'],
  "scalia's": ['S K AA1 L IY0 AH0 Z', 'S K AA1 L Y AH0 Z'],
  "scaling": ['S K EY1 L IH0 NG'],
  "scalise": ['S K AA1 L AY0 Z'],
  "scalisi": ['S K AA0 L IY1 S IY0'],
  "scallan": ['S K AE1 L AH0 N'],
  "scallion": ['S K AE1 L Y AH0 N'],
  "scallions": ['S K AE1 L Y AH0 N Z'],
  "scallon": ['S K AE1 L AH0 N'],
  "scallop": ['S K AE1 L AH0 P'],
  "scalloped": ['S K AA1 L AH0 P T'],
  "scallops": ['S K AE1 L AH0 P S'],
  "scally": ['S K AE1 L IY0'],
  "scalp": ['S K AE1 L P'],
  "scalped": ['S K AE1 L P T'],
  "scalpel": ['S K AE1 L P AH0 L'],
  "scalpels": ['S K AE1 L P AH0 L Z'],
  "scalper": ['S K AE1 L P ER0'],
  "scalpers": ['S K AE1 L P ER0 Z'],
  "scalping": ['S K AE1 L P IH0 NG'],
  "scalps": ['S K AE1 L P S'],
  "scalzi": ['S K AA1 L Z IY0'],
  "scalzitti": ['S K AA0 L Z IY1 T IY0'],
  "scalzo": ['S K AA1 L Z OW0'],
  "scam": ['S K AE1 M'],
  "scammed": ['S K AE1 M D'],
  "scammell": ['S K AE1 M AH0 L'],
  "scammer": ['S K AE1 M ER0'],
  "scammers": ['S K AE1 M ER0 Z'],
  "scammon": ['S K AE1 M AH0 N'],
  "scamper": ['S K AE1 M P ER0'],
  "scampered": ['S K AE1 M P ER0 D'],
  "scampering": ['S K AE1 M P ER0 IH0 NG'],
  "scams": ['S K AE1 M Z'],
  "scan": ['S K AE1 N'],
  "scandal": ['S K AE1 N D AH0 L'],
  "scandal's": ['S K AE1 N D AH0 L Z'],
  "scandalize": ['S K AE1 N D AH0 L AY2 Z'],
  "scandalized": ['S K AE1 N D AH0 L AY2 Z D'],
  "scandalous": ['S K AE1 N D AH0 L AH0 S'],
  "scandals": ['S K AE1 N D AH0 L Z'],
  "scandia": ['S K AE1 N D IY0 AH0'],
  "scandinavia": ['S K AE2 N D IH0 N EY1 V IY0 AH0'],
  "scandinavia's": ['S K AE2 N D IH0 N EY1 V IY0 AH0 Z'],
  "scandinavian": ['S K AE2 N D IH0 N EY1 V IY0 AH0 N'],
  "scandinavians": ['S K AE2 N D IH0 N EY1 V IY0 AH0 N Z'],
  "scania": ['S K AA1 N IY0 AH0'],
  "scanlan": ['S K AE1 N L AH0 N'],
  "scanland": ['S K AE1 N L AH0 N D'],
  "scanlin": ['S K AE1 N L IH0 N'],
  "scanlon": ['S K AE1 N L AH0 N'],
  "scanlon's": ['S K AE1 N L AH0 N Z'],
  "scanned": ['S K AE1 N D'],
  "scannell": ['S K AE1 N AH0 L'],
  "scanner": ['S K AE1 N ER0'],
  "scanners": ['S K AE1 N ER0 Z'],
  "scanning": ['S K AE1 N IH0 NG'],
  "scans": ['S K AE1 N Z'],
  "scant": ['S K AE1 N T'],
  "scantily": ['S K AE1 N T AH0 L IY0'],
  "scantiness": ['S K AE1 N T IY0 N AH0 S'],
  "scantlin": ['S K AE1 N T L IH0 N'],
  "scanty": ['S K AE1 N T IY0'],
  "scapa": ['S K AA1 P AH0'],
  "scape": ['S K EY1 P'],
  "scapegoat": ['S K EY1 P G OW2 T'],
  "scapegoated": ['S K EY1 P G OW2 T IH0 D'],
  "scapegoating": ['S K EY1 P G OW2 T IH0 NG'],
  "scapegoats": ['S K EY1 P G OW2 T S'],
  "scapula": ['S K AE1 P Y AH0 L AH0'],
  "scar": ['S K AA1 R'],
  "scaramouch": ['S K AE1 R AH0 M AW2 CH'],
  "scarano": ['S K AA0 R AA1 N OW0'],
  "scarberry": ['S K AA1 R B EH2 R IY0'],
  "scarborough": ['S K AA1 R B ER2 OW0'],
  "scarbro": ['S K AA1 R B R OW0'],
  "scarbrough": ['S K AA1 R B R AW0'],
  "scarce": ['S K EH1 R S'],
  "scarcella": ['S K AA2 R S EH1 L AH0'],
  "scarcely": ['S K EH1 R S L IY0'],
  "scarcer": ['S K EH1 R S ER0'],
  "scarcity": ['S K EH1 R S IH0 T IY0'],
  "scardina": ['S K AA0 R D IY1 N AH0'],
  "scardino": ['S K AA0 R D IY1 N OW0'],
  "scare": ['S K EH1 R'],
  "scarecrow": ['S K AE1 R K R OW2'],
  "scarecrow's": ['S K AE1 R K R OW2 Z'],
  "scarecrows": ['S K AE1 R K R OW2 Z'],
  "scared": ['S K EH1 R D'],
  "scares": ['S K EH1 R Z'],
  "scarf": ['S K AA1 R F'],
  "scarface": ['S K AA1 R F EY2 S'],
  "scarff": ['S K AA1 R F'],
  "scarfo": ['S K AA1 R F OW0'],
  "scarfs": ['S K AA1 R F S'],
  "scargill": ['S K AA1 R G IH2 L'],
  "scarier": ['S K EH1 R IY0 ER0'],
  "scariest": ['S K EH1 R IY0 AH0 S T'],
  "scaring": ['S K EH1 R IH0 NG'],
  "scarlata": ['S K AA0 R L AA1 T AH0'],
  "scarlet": ['S K AA1 R L AH0 T'],
  "scarlett": ['S K AA1 R L IH0 T'],
  "scarola": ['S K AA0 R OW1 L AH0'],
  "scarp": ['S K AA1 R P'],
  "scarpa": ['S K AA1 R P AH0'],
  "scarpati": ['S K AA0 R P AA1 T IY0'],
  "scarpelli": ['S K AA0 R P EH1 L IY0'],
  "scarpello": ['S K AA2 R P EH1 L OW0'],
  "scarpinatto": ['S K AA2 R P IH0 N AA1 T OW0'],
  "scarpino": ['S K AA0 R P IY1 N OW0'],
  "scarpone": ['S K AA0 R P OW1 N IY0'],
  "scarpulla": ['S K AA2 R P UH1 L AH0'],
  "scarred": ['S K AA1 R D'],
  "scarring": ['S K AA1 R IH0 NG'],
  "scarry": ['S K AE1 R IY0'],
  "scars": ['S K AA1 R Z'],
  "scarsdale": ['S K AA1 R Z D EY2 L'],
  "scarsella": ['S K AA2 R S EH1 L AH0'],
  "scarves": ['S K AA1 R V Z'],
  "scary": ['S K EH1 R IY0'],
  "scat": ['S K AE1 T'],
  "scatena": ['S K AA0 T EH1 N AH0'],
  "scates": ['S K EY1 T S'],
  "scathing": ['S K EY1 DH IH0 NG'],
  "scatological": ['S K AE0 T AH0 L AO1 JH AH0 K AH0 L'],
  "scatology": ['S K AE0 T AO1 L AH0 JH IY0'],
  "scatter": ['S K AE1 T ER0'],
  "scattered": ['S K AE1 T ER0 D'],
  "scattergood": ['S K AE1 T ER0 G UH2 D'],
  "scattergories": ['S K AE1 T ER0 G AO2 R IY0 Z'],
  "scattergory": ['S K AE1 T ER0 G AO2 R IY0'],
  "scattering": ['S K AE1 T ER0 IH0 NG'],
  "scattershot": ['S K AE1 T ER0 SH AA2 T'],
  "scaturro": ['S K AA0 T UH1 R OW0'],
  "scavenge": ['S K AE1 V AH0 N JH'],
  "scavenger": ['S K AE1 V AH0 N JH ER0'],
  "scavengers": ['S K AE1 V AH0 N JH ER0 Z'],
  "scavenging": ['S K AE1 V AH0 N JH IH0 NG'],
  "scavo": ['S K AA1 V OW0'],
  "scavone": ['S K AH0 V OW1 N'],
  "scavuzzo": ['S K AA0 V UW1 Z OW0'],
  "scearce": ['S ER1 S'],
  "scenario": ['S IH0 N EH1 R IY0 OW0'],
  "scenarios": ['S IH0 N EH1 R IY0 OW0 Z'],
  "scene": ['S IY1 N'],
  "sceneries": ['S IY1 N ER0 IY0 Z'],
  "scenery": ['S IY1 N ER0 IY0'],
  "scenes": ['S IY1 N Z'],
  "scenic": ['S IY1 N IH0 K'],
  "scent": ['S EH1 N T'],
  "scented": ['S EH1 N T IH0 D'],
  "scents": ['S EH1 N T S'],
  "sceptic": ['S K EH1 P T IH0 K'],
  "sceptics": ['S K EH1 P T IH0 K S'],
  "sceptre": ['S EH1 P T ER0', 'S K EH1 P T ER0'],
  "scerbo": ['S K EH1 R B OW0'],
  "scerzusky": ['S ER0 Z UW1 S K IY0'],
  "schaab": ['SH AA1 B'],
  "schaack": ['SH AA1 K'],
  "schaad": ['SH AA1 D'],
  "schaadt": ['SH AA1 T'],
  "schaaf": ['SH AA1 F'],
  "schaafsma": ['SH AA1 F S M AH0'],
  "schaal": ['SH AA1 L'],
  "schaap": ['SH AA1 P'],
  "schaar": ['SH AA1 R'],
  "schab": ['SH AE1 B'],
  "schabacker": ['SH AA1 B AE2 K ER0'],
  "schabel": ['SH AE1 B AH0 L'],
  "schaben": ['SH AE1 B AH0 N'],
  "schaber": ['SH EY1 B ER0'],
  "schaberg": ['SH AA1 B ER0 G'],
  "schabes": ['SH EY1 B Z'],
  "schacher": ['SH AE1 K ER0'],
  "schachner": ['SH AE1 K N ER0'],
  "schacht": ['SH AE1 K T'],
  "schachter": ['SH AE1 K T ER0'],
  "schack": ['SH AE1 K'],
  "schad": ['SH AE1 D'],
  "schade": ['SH EY1 D'],
  "schadel": ['SH AE1 D AH0 L'],
  "schaden": ['SH AE1 D AH0 N'],
  "schadenfreude": ['SH AE1 D IH0 N F R OY2 D'],
  "schader": ['SH EY1 D ER0'],
  "schadler": ['SH EY1 D AH0 L ER0', 'SH EY1 D L ER0'],
  "schadt": ['SH AE1 T'],
  "schaecher": ['SH EH1 K ER0'],
  "schaedel": ['SH EH1 D AH0 L'],
  "schaedler": ['SH EH1 D AH0 L ER0', 'SH EH1 D L ER0'],
  "schaefer": ['SH EY1 F ER0'],
  "schaefers": ['SH EY1 F ER0 Z'],
  "schaeffer": ['SH EH1 F ER0'],
  "schaeffler": ['SH AE1 F L ER0'],
  "schaer": ['SH AA1 ER0'],
  "schafer": ['SH EY1 F ER0'],
  "schaff": ['SH AE1 F'],
  "schaffer": ['SH EY1 F ER0'],
  "schaffert": ['SH AE1 F ER0 T'],
  "schaffler": ['SH AE1 F L ER0'],
  "schaffner": ['SH AE1 F N ER0'],
  "schaible": ['S K EY1 B AH0 L'],
  "schairer": ['SH AY1 ER0 R'],
  "schaja": ['SH AA1 JH AH0'],
  "schake": ['SH EY1 K'],
  "schalk": ['SH AO1 K'],
  "schall": ['SH AO1 L'],
  "schaller": ['SH AO1 L ER0'],
  "schallock": ['SH AE1 L AH0 K'],
  "schalow": ['SH AE1 L OW0'],
  "schama": ['SH AA1 M AH0'],
  "schamber": ['SH AE1 M B ER0'],
  "schamberger": ['SH AE1 M B ER0 G ER0'],
  "schamel": ['SH AE1 M AH0 L'],
  "schamp": ['SH AE1 M P'],
  "schanbacher": ['SH AE1 N B AA2 K ER0'],
  "schanck": ['SH AE1 NG K'],
  "schaner": ['SH EY1 N ER0'],
  "schank": ['SH AE1 NG K'],
  "schantz": ['SH AE1 N T S'],
  "schanz": ['SH AE1 N S'],
  "schaper": ['SH EY1 P ER0'],
  "schapiro": ['SH AE1 P AY0 R OW0'],
  "schapp": ['SH AE1 P'],
  "schappell": ['SH AE1 P AH0 L'],
  "schappert": ['SH AE1 P ER0 T'],
  "schar": ['SH AA1 R'],
  "schara": ['S K AE1 R AH0'],
  "schardt": ['SH AA1 R T'],
  "scharenberg": ['SH EH1 R AH0 N B ER0 G'],
  "scharer": ['SH EH1 R ER0'],
  "scharf": ['SH AA1 R F'],
  "scharfe": ['S K AA1 R F'],
  "scharfenberg": ['SH AA1 R F AH0 N B ER0 G'],
  "scharff": ['SH AA1 R F'],
  "scharffenberger": ['SH AA1 R F AH0 N B ER0 G ER0'],
  "scharlau": ['SH AA1 R L AW0'],
  "scharnhorst": ['SH AA1 R N HH AO0 R S T'],
  "scharp": ['SH AA1 R P'],
  "scharpf": ['SH AA1 R P F'],
  "scharr": ['SH AA1 R'],
  "scharrer": ['SH AA1 R ER0'],
  "schartz": ['SH AA1 R T S'],
  "schattner": ['SH AE1 T N ER0'],
  "schatz": ['SH AE1 T S'],
  "schatzberg": ['SH AE1 T S B ER0 G'],
  "schatzel": ['SH AE1 T Z AH0 L'],
  "schatzman": ['SH AE1 T Z M AH0 N'],
  "schau": ['SH OW1'],
  "schaub": ['SH AO1 B'],
  "schauble": ['SH OW1 B AH0 L'],
  "schauer": ['SH AW1 ER0'],
  "schauf": ['SH AW1 F'],
  "schaufler": ['SH AW1 F AH0 L ER0', 'SH AW1 F L ER0'],
  "schaul": ['SH OW1 L'],
  "schaum": ['SH OW1 M'],
  "schaumberg": ['SH OW1 M B ER0 G'],
  "schaumburg": ['SH AW1 M B ER0 G'],
  "schaus": ['S K AW1 S'],
  "schaut": ['SH OW1 T'],
  "schave": ['SH EY1 V'],
  "schear": ['SH IH1 R'],
  "schechter": ['SH EH1 K T ER0'],
  "schechtman": ['SH EH1 K T M AH0 N'],
  "scheck": ['SH EH1 K'],
  "scheckel": ['SH EH1 K AH0 L'],
  "schecter": ['SH EH1 K T ER0'],
  "schedler": ['SH EH1 D AH0 L ER0', 'SH EH1 D L ER0'],
  "schedule": ['S K EH1 JH UH0 L', 'S K EH1 JH UW0 L'],
  "scheduled": ['S K EH1 JH UH0 L D', 'S K EH1 JH UW0 L D'],
  "scheduler": ['S K EH1 JH UH0 L ER0', 'S K EH1 JH UW0 L ER0'],
  "schedulers": ['S K EH1 JH UH0 L ER0 Z', 'S K EH1 JH UW0 L ER0 Z'],
  "schedules": ['S K EH1 JH UH0 L Z', 'S K EH1 JH UW0 L Z'],
  "scheduling": ['S K EH1 JH UH0 L IH0 NG', 'S K EH1 JH UW0 L IH0 NG'],
  "scheel": ['SH IY1 L'],
  "scheele": ['SH IY1 L'],
  "scheeler": ['SH IY1 L ER0'],
  "scheer": ['SH IH1 R'],
  "scheerer": ['SH IH1 R ER0'],
  "scheets": ['SH IY1 T S'],
  "scheetz": ['SH IY1 T S'],
  "scheff": ['SH EH1 F'],
  "scheffel": ['SH EH1 F AH0 L'],
  "scheffer": ['SH EH1 F ER0'],
  "scheffler": ['SH EH1 F AH0 L ER0', 'SH EH1 F L ER0'],
  "schehr": ['SH EH1 R'],
  "scheib": ['SH AY1 B'],
  "scheibe": ['SH AY1 B'],
  "scheibel": ['SH AY1 B AH0 L'],
  "scheiber": ['SH AY1 B ER0'],
  "scheible": ['S K AY1 B AH0 L'],
  "scheibner": ['SH AY1 B N ER0'],
  "scheid": ['SH AY1 D'],
  "scheidecker": ['SH AY1 D IH0 K ER0'],
  "scheidegger": ['SH AY1 D IH0 G ER0'],
  "scheidel": ['SH AY1 D AH0 L'],
  "scheider": ['SH AY1 D ER0'],
  "scheiderer": ['SH AY1 D ER0 ER0'],
  "scheidler": ['SH AY1 D AH0 L ER0', 'SH AY1 D L ER0'],
  "scheidt": ['SH AY1 T'],
  "scheier": ['SH AY1 ER0'],
  "scheiman": ['SH AY1 M AH0 N'],
  "schein": ['SH AY1 N'],
  "scheinberg": ['SH AY1 N B ER0 G'],
  "scheiner": ['SH AY1 N ER0'],
  "scheirer": ['SH AY1 ER0 R'],
  "scheler": ['SH IY1 L ER0'],
  "schelin": ['SH EH1 L IH0 N'],
  "schell": ['S K EH1 L'],
  "schellenberg": ['SH EH1 L AH0 N B ER0 G'],
  "schellenberger": ['SH EH1 L AH0 N B ER0 G ER0'],
  "schellenger": ['SH EH1 L IH0 N JH ER0'],
  "scheller": ['S K EH1 L ER0'],
  "schellhammer": ['SH EH1 L HH AH0 M ER0'],
  "schellhase": ['SH EH1 L HH AH0 S'],
  "schellhorn": ['SH EH1 L HH ER0 N'],
  "schelling": ['S K EH1 L IH0 NG'],
  "schellinger": ['SH EH1 L IH0 NG ER0'],
  "schema": ['S K IY1 M AH0'],
  "schemas": ['S K IY1 M AH0 Z'],
  "schemata": ['S K IH0 M AE1 T AH0'],
  "schematic": ['S K IH0 M AE1 T IH0 K'],
  "schematics": ['S K IH0 M AE1 T IH0 K S'],
  "schembri": ['SH EH1 M B R IY0'],
  "scheme": ['S K IY1 M'],
  "schemed": ['S K IY1 M D'],
  "schemel": ['SH EH1 M AH0 L'],
  "schemer": ['S K IY1 M ER0'],
  "schemes": ['S K IY1 M Z'],
  "scheming": ['S K IY1 M IH0 NG'],
  "schemm": ['SH EH1 M'],
  "schemmel": ['SH EH1 M AH0 L'],
  "schempf": ['SH EH1 M F'],
  "schempp": ['SH EH1 M P'],
  "schena": ['SH IY1 N AH0'],
  "schenck": ['SH EH1 NG K'],
  "schendel": ['SH EH1 N D AH0 L'],
  "schenectady": ['S K AH0 N EH1 K T AH0 D IY0'],
  "schenectady's": ['S K AH0 N EH1 K T AH0 D IY0 Z'],
  "schenk": ['SH EH1 NG K'],
  "schenkel": ['SH EH1 NG K AH0 L'],
  "schenken": ['SH EH1 NG K AH0 N'],
  "schenker": ['SH EH1 NG K ER0'],
  "schenley": ['SH EH1 N L IY0'],
  "scheper": ['SH IY1 P ER0'],
  "schepers": ['SH IY1 P ER0 Z'],
  "schepis": ['SH EH1 P IH0 S'],
  "schepp": ['SH EH1 P'],
  "scher": ['SH ER1'],
  "scherb": ['SH ER1 B'],
  "scherbarth": ['SH ER1 B AA0 R TH'],
  "scherer": ['SH IH1 R ER0'],
  "scherer's": ['SH EH1 R ER0 Z'],
  "scherf": ['SH ER1 F'],
  "scherff": ['SH ER1 F'],
  "scherger": ['SH ER1 G ER0'],
  "schering": ['SH ER1 IH0 NG', 'SH EH1 R IH0 NG'],
  "schering's": ['SH EH1 R IH0 NG Z'],
  "scherlis": ['SH ER1 L IH0 S'],
  "scherman": ['SH ER1 M AH0 N'],
  "schermer": ['SH ER1 M ER0'],
  "schermerhorn": ['SH ER1 M ER0 HH ER0 N'],
  "scherr": ['SH EH1 R'],
  "scherrer": ['SH EH1 R ER0'],
  "schertz": ['SH ER1 T S'],
  "schertzer": ['SH ER1 T S ER0'],
  "scherz": ['SH ER1 Z'],
  "scherzer": ['SH ER1 Z ER0'],
  "scherzinger": ['SH ER1 Z IH0 NG ER0'],
  "schetter": ['SH EH1 T ER0'],
  "schettino": ['SH EH1 T IY0 N OW0'],
  "schettler": ['SH EH1 T AH0 L ER0', 'SH EH1 T L ER0'],
  "scheu": ['SH OY1'],
  "scheuer": ['SH OY1 ER0'],
  "scheuerman": ['SH OY1 ER0 M AH0 N'],
  "scheuermann": ['SH OY1 ER0 M AH0 N'],
  "scheufler": ['SH OY1 F AH0 L ER0', 'SH OY1 F L ER0'],
  "scheunemann": ['SH OY1 N M AH0 N'],
  "scheurer": ['SH ER1 ER0'],
  "scheurich": ['SH OY1 R IH0 K'],
  "scheuring": ['SH ER1 IH0 NG'],
  "scheve": ['SH IY1 V'],
  "schewe": ['SH Y UW1'],
  "schexnayder": ['SH EH1 K S N EY0 D ER0'],
  "schexnider": ['SH EH1 K S N AY0 D ER0'],
  "schey": ['SH EY1'],
  "schiano": ['S K IY0 AA1 N OW0'],
  "schiappa": ['S K IY0 AA1 P AH0'],
  "schiavi": ['S K IY0 AA1 V IY0'],
  "schiavo": ['S K IY0 AA1 V OW0'],
  "schiavo's": ['S K IY0 AA1 V OW0 Z'],
  "schiavone": ['S K IY0 AA0 V OW1 N IY0'],
  "schiavoni": ['S K IY0 AA0 V OW1 N IY0'],
  "schick": ['SH IH1 K'],
  "schicker": ['SH IH1 K ER0'],
  "schickler": ['SH IH1 K AH0 L ER0', 'SH IH1 K L ER0'],
  "schickling": ['SH IH1 K AH0 L IH0 NG', 'SH IH1 K L IH0 NG'],
  "schiebel": ['SH IY1 B AH0 L'],
  "schieber": ['SH IY1 B ER0'],
  "schiefelbein": ['SH IY1 F IH0 L B AY0 N'],
  "schiefer": ['SH IY1 F ER0'],
  "schieffelin": ['SH IY1 F AH0 L IH0 N'],
  "schieffer": ['SH IY1 F ER0'],
  "schiel": ['SH IY1 L'],
  "schield": ['SH IY1 L D'],
  "schiele": ['SH IY1 L'],
  "schielke": ['SH IY1 L K'],
  "schiemann": ['SH IY1 M AH0 N'],
  "schier": ['SH AY1 ER0'],
  "schieren": ['SH IH1 R AH0 N'],
  "schierl": ['SH IH1 R L'],
  "schiess": ['SH IY1 S'],
  "schiesser": ['SH IY1 S ER0'],
  "schiewe": ['SH IY1 W IY0'],
  "schifano": ['S K IY0 F AA1 N OW0'],
  "schiferon": ['SH IH1 F ER0 AO2 N', 'SH IH1 F R AO2 N'],
  "schiferon's": ['SH IH1 F ER0 AO2 N Z', 'SH IH1 F R AO2 N Z'],
  "schiff": ['SH IH1 F'],
  "schiffbauer": ['SH IH1 F B AW0 ER0'],
  "schiffer": ['SH IH1 F ER0'],
  "schiffler": ['SH IH1 F AH0 L ER0', 'SH IH1 F L ER0'],
  "schiffman": ['SH IH1 F M AH0 N'],
  "schiffner": ['SH IH1 F N ER0'],
  "schild": ['SH AY1 L D'],
  "schilder": ['SH AY1 L D ER0'],
  "schildknecht": ['SH AY1 L D K AH0 N EH2 K T'],
  "schildt": ['SH IH1 L T'],
  "schilke": ['SH IH1 L K'],
  "schill": ['SH IH1 L'],
  "schillaci": ['S K IY0 L AA1 CH IY0'],
  "schiller": ['SH IH1 L ER0'],
  "schiller's": ['SH IH1 L ER0 Z'],
  "schilling": ['SH IH1 L IH0 NG'],
  "schilling's": ['SH IH1 L IH0 NG Z'],
  "schillinger": ['SH IH1 L IH0 NG ER0'],
  "schillings": ['SH IH1 L IH0 NG Z'],
  "schillo": ['S K IH1 L OW0'],
  "schilt": ['SH IH1 L T'],
  "schiltknecht": ['SH IH1 L T N EH2 K T'],
  "schiltz": ['SH IH1 L T S'],
  "schilz": ['SH IH1 L Z'],
  "schimberni": ['SH IH0 M B ER1 N IY0'],
  "schimek": ['SH IH1 M IH0 K'],
  "schimke": ['S K IH1 M K'],
  "schimmel": ['SH IH1 M AH0 L'],
  "schimmelbusch": ['SH IH1 M AH0 L B UH2 SH'],
  "schimming": ['SH IH1 M IH0 NG'],
  "schimpf": ['SH IH1 M P F'],
  "schindel": ['SH IH1 N D AH0 L'],
  "schindler": ['SH IH1 N D L ER0'],
  "schindler's": ['SH IH1 N D L ER0 Z'],
  "schinke": ['S K IH1 NG K'],
  "schinkel": ['SH IH1 NG K AH0 L'],
  "schipani": ['S K IY0 P AA1 N IY0'],
  "schiphol": ['S K IH1 P AH0 L'],
  "schipke": ['SH IH1 P K IY0'],
  "schipper": ['SH IH1 P ER0'],
  "schippers": ['SH IH1 P ER0 Z'],
  "schiraldi": ['S K IH0 R AA1 L D IY0'],
  "schirm": ['SH ER1 M'],
  "schirmer": ['SH ER1 M ER0'],
  "schiro": ['S K IH1 R OW0'],
  "schirtzinger": ['SH ER1 T Z IH0 NG ER0'],
  "schisler": ['SH IH1 S AH0 L ER0', 'SH IH1 S L ER0'],
  "schism": ['S K IH1 Z AH0 M'],
  "schisms": ['S K IH1 Z AH0 M Z'],
  "schissel": ['SH IH1 S AH0 L'],
  "schissler": ['SH IH1 S AH0 L ER0', 'SH IH1 S L ER0'],
  "schist": ['SH IH1 S T'],
  "schists": ['SH IH1 S T S'],
  "schizophrenia": ['S K IH2 T S AH0 F R IY1 N IY0 AH0'],
  "schizophrenic": ['SH IH2 Z AH0 F R EH1 N IH0 K'],
  "schlabach": ['SH L AE1 B AA0 K'],
  "schlachter": ['SH L AE1 K T ER0'],
  "schlack": ['SH L AE1 K'],
  "schlafer": ['SH L EY1 F ER0'],
  "schlafly": ['SH L AE1 F L IY0'],
  "schlag": ['SH L AE1 G'],
  "schlagel": ['SH L AE1 G AH0 L'],
  "schlager": ['SH L EY1 G ER0'],
  "schlageter": ['SH L AE1 G IY0 T ER0'],
  "schlake": ['SH L EY1 K'],
  "schlang": ['SH L AE1 NG'],
  "schlangen": ['SH L AE1 NG AH0 N'],
  "schlanger": ['SH L AE1 NG ER0'],
  "schlarb": ['SH L AA1 R B'],
  "schlater": ['SH L EY1 T ER0'],
  "schlatter": ['SH L AE1 T ER0'],
  "schlauch": ['SH L AW1 K'],
  "schlecht": ['SH L EH1 K T'],
  "schlechter": ['SH L EH1 K T ER0'],
  "schlee": ['SH L IY1'],
  "schleeter": ['SH L IY1 T ER0'],
  "schlegel": ['SH L EY1 G AH0 L'],
  "schlegelmilch": ['SH L EH1 G IH0 L M IH0 L K'],
  "schleich": ['SH L AY1 K'],
  "schleicher": ['SH L AY1 K ER0'],
  "schleif": ['SH L AY1 F'],
  "schleifer": ['SH L AY1 F ER0'],
  "schleiger": ['SH L AY1 G ER0'],
  "schleimer": ['SH L AY1 M ER0'],
  "schlein": ['SH L AY1 N'],
  "schleis": ['SH L AY1 Z'],
  "schlemmer": ['SH L EH1 M ER0'],
  "schlender": ['SH L EH1 N D ER0'],
  "schlenker": ['SH L EH1 NG K ER0'],
  "schlepp": ['SH L EH1 P'],
  "schlereth": ['SH L EH1 R IH0 TH'],
  "schlesinger": ['SH L EH1 S IH0 N JH ER0'],
  "schlesser": ['SH L EH1 S ER0'],
  "schlessinger": ['SH L EH1 S IH0 N JH ER0'],
  "schleswig": ['SH L EH1 S W IH0 G'],
  "schley": ['SH L EY1'],
  "schleyer": ['SH L EY1 ER0'],
  "schlicher": ['SH L IH1 K ER0'],
  "schlicht": ['SH L IH1 K T'],
  "schlichter": ['SH L IH1 K T ER0'],
  "schlichting": ['SH L IH1 K T IH0 NG'],
  "schlick": ['SH L IH1 K'],
  "schlicker": ['SH L IH1 K ER0'],
  "schlict": ['SH L IH1 K T'],
  "schlie": ['SH L IY1'],
  "schliep": ['SH L IY1 P'],
  "schlieper": ['SH L IY1 P ER0'],
  "schlink": ['SH L IH1 NG K'],
  "schlitt": ['SH L IH1 T'],
  "schlitter": ['SH L IH1 T ER0'],
  "schlitz": ['SH L IH1 T S'],
  "schlobohm": ['SH L AA1 B OW0 M'],
  "schlock": ['SH L AA1 K'],
  "schloemer": ['SH L OW1 M ER0'],
  "schloesser": ['SH L AA1 IH0 S ER0', 'SH L AA1 S ER0'],
  "schlosberg": ['SH L AA1 S B ER0 G'],
  "schloss": ['SH L AO1 S'],
  "schlossberg": ['SH L AO1 S B ER0 G'],
  "schlosser": ['SH L AO1 S ER0'],
  "schlossman": ['SH L AO1 S M AH0 N'],
  "schlott": ['SH L AA1 T'],
  "schlotter": ['SH L AA1 T ER0'],
  "schlotterbeck": ['SH L AA1 T ER0 B EH0 K'],
  "schlottman": ['SH L AA1 T M AH0 N'],
  "schlotzhauer": ['SH L AA1 T S HH AW0 ER0'],
  "schlough": ['SH L AW1'],
  "schlueter": ['SH L UH1 T ER0'],
  "schlumberger": ['SH L AH1 M B ER0 ZH EY2', 'SH L AH1 M B ER0 G ER0'],
  "schlund": ['SH L AH1 N D'],
  "schlup": ['SH L AH1 P'],
  "schluter": ['SH L UW1 T ER0'],
  "schmader": ['SH M EY1 D ER0'],
  "schmahl": ['SH M AA1 L'],
  "schmal": ['SH M AE1 L'],
  "schmale": ['SH M EY1 L'],
  "schmall": ['SH M AO1 L'],
  "schmaltz": ['SH M AA1 L T S'],
  "schmalz": ['SH M AO1 L Z'],
  "schmancy": ['SH M AE1 N S IY0'],
  "schmaus": ['SH M AW1 Z'],
  "schmead": ['SH M IY1 D'],
  "schmechel": ['SH M EH1 K AH0 L'],
  "schmeck": ['SH M EH1 K'],
  "schmehl": ['SH M EH1 L'],
  "schmeichel": ['SH M AY1 K AH0 L'],
  "schmeiser": ['SH M AY1 S ER0'],
  "schmeling": ['SH M EH1 L IH0 NG'],
  "schmelter": ['SH M EH1 L T ER0'],
  "schmeltz": ['SH M EH1 L T S'],
  "schmeltzer": ['SH M EH1 L T Z ER0'],
  "schmelz": ['SH M EH1 L Z'],
  "schmelzer": ['SH M EH1 L Z ER0'],
  "schmelzle": ['SH M EH1 L Z AH0 L'],
  "schmergel": ['SH M ER1 G AH0 L'],
  "schmertz": ['SH M ER1 T S'],
  "schmetterer": ['SH M EH1 T ER0 ER0'],
  "schmick": ['SH M IH1 K'],
  "schmid": ['SH M IH1 D'],
  "schmiddy": ['SH M IH1 D IY0'],
  "schmidgall": ['SH M IH1 JH AH0 L'],
  "schmidl": ['SH M IH1 D AH0 L'],
  "schmidlin": ['SH M IH1 D L IH0 N'],
  "schmidt": ['SH M IH1 T'],
  "schmidt's": ['SH M IH1 T S'],
  "schmidtke": ['SH M IH1 T K IY0'],
  "schmied": ['SH M AY1 D'],
  "schmieder": ['SH M AY1 D ER0'],
  "schmieg": ['SH M IY1 G'],
  "schmierer": ['SH M AY1 ER0 ER0'],
  "schmiesing": ['SH M IY1 S IH0 NG'],
  "schmit": ['SH M IH1 T'],
  "schmitt": ['SH M IH1 T'],
  "schmitter": ['SH M IH1 T ER0'],
  "schmittou": ['SH M IH1 CH UW0'],
  "schmitz": ['SH M IH1 T S'],
  "schmitzer": ['SH M IH1 T S ER0'],
  "schmoke": ['SH M OW1 K'],
  "schmoker": ['SH M OW1 K ER0'],
  "schmoldt": ['SH M OW1 L T'],
  "schmoll": ['SH M AA1 L'],
  "schmooze": ['SH M UW1 Z'],
  "schmoozing": ['SH M UW1 Z IH0 NG'],
  "schmotzer": ['SH M OW1 T Z ER0'],
  "schmoyer": ['SH M OY1 ER0'],
  "schmuck": ['SH M AH1 K'],
  "schmucker": ['SH M AH1 K ER0'],
  "schmuckler": ['SH M AH1 K L ER0'],
  "schmuhl": ['SH M AH1 L'],
  "schmults": ['SH M AH1 L T S'],
  "schmunk": ['SH M AH1 NG K'],
  "schmutz": ['SH M AH1 T S'],
  "schmutzler": ['SH M AH1 T Z AH0 L ER0', 'SH M AH1 T Z L ER0'],
  "schnabel": ['SH N AE1 B AH0 L'],
  "schnack": ['SH N AE1 K'],
  "schnackenberg": ['SH N AE1 K AH0 N B ER0 G'],
  "schnake": ['SH N EY1 K'],
  "schnakenberg": ['SH N EY1 K AH0 N B ER0 G'],
  "schnall": ['SH N AO1 L'],
  "schnapp": ['SH N AE1 P'],
  "schnapps": ['SH N AE1 P S'],
  "schnarr": ['SH N AE1 R'],
  "schnauzer": ['SH N AW1 Z ER0'],
  "schneberger": ['SH N IY1 B ER0 G ER0'],
  "schnebly": ['SH N EH1 B L IY0'],
  "schneck": ['SH N EH1 K'],
  "schneckloth": ['SH N EH1 K L AH0 TH'],
  "schnee": ['SH N IY1'],
  "schneeberger": ['SH N IY1 B ER0 G ER0'],
  "schneeman": ['SH N IY1 M AH0 N'],
  "schneerson": ['SH N IH1 R S AH0 N'],
  "schneerson's": ['SH N IH1 R S AH0 N Z'],
  "schneid": ['SH N AY1 D'],
  "schneider": ['SH N AY1 D ER0'],
  "schneider's": ['SH N AY1 D ER0 Z'],
  "schneiderman": ['SH N AY1 D ER0 M AH0 N'],
  "schneiderman's": ['SH N AY1 D ER0 M AH0 N Z'],
  "schneiders": ['SH N AY1 D ER0 Z'],
  "schneiders'": ['SH N AY1 D ER0 Z'],
  "schneidewind": ['SH N AY1 D AH0 W IH2 N D'],
  "schneidewind's": ['SH N AY1 D AH0 W IH2 N D Z'],
  "schneier": ['SH N AY1 ER0'],
  "schneiter": ['SH N AY1 T ER0'],
  "schnell": ['SH N EH1 L'],
  "schnelle": ['SH N EH1 L'],
  "schneller": ['SH N EH1 L ER0'],
  "schnepf": ['SH N EH1 P F'],
  "schnepp": ['SH N EH1 P'],
  "schnettler": ['SH N EH1 T AH0 L ER0', 'SH N EH1 T L ER0'],
  "schnetzer": ['SH N EH1 T Z ER0'],
  "schnick": ['SH N IH1 K'],
  "schnider": ['SH N AY1 D ER0'],
  "schnieder": ['SH N AY1 D ER0'],
  "schnieders": ['SH N AY1 D ER0 Z'],
  "schnier": ['SH N AY1 ER0'],
  "schnitker": ['SH N IH1 T K ER0'],
  "schnittke": ['SH N IH1 T K IY0'],
  "schnitz": ['SH N IH1 T S'],
  "schnitzer": ['SH N IH1 T Z ER0'],
  "schnitzler": ['SH N IH1 T S L ER0'],
  "schnoebelen": ['SH N OW1 B AH0 L AH0 N'],
  "schnook": ['SH N UH1 K'],
  "schnooks": ['SH N UH1 K S'],
  "schnoor": ['SH N UH1 R'],
  "schnorr": ['SH N AO1 R'],
  "schnur": ['SH N ER1'],
  "schnurr": ['SH N ER1'],
  "schnyder": ['SH N AY1 D ER0'],
  "schnyder's": ['SH N AY1 D ER0 Z'],
  "schobel": ['SH OW1 B AH0 L'],
  "schober": ['SH OW1 B ER0'],
  "schobert": ['SH AA1 B ER0 T'],
  "schoch": ['SH AA1 K'],
  "schock": ['SH AA1 K'],
  "schoeck": ['SH OW1 K'],
  "schoeffler": ['SH OW1 F AH0 L ER0', 'SH OW1 F L ER0'],
  "schoeller": ['SH OW1 L ER0'],
  "schoellhorn": ['SH OW1 L HH AO2 R N'],
  "schoemaker": ['SH OW1 M EY2 K ER0'],
  "schoen": ['SH OW1 N'],
  "schoenbaum": ['SH OW1 N B AW2 M'],
  "schoenbeck": ['SH OW1 N B EH2 K'],
  "schoenberg": ['SH OW1 N B ER0 G'],
  "schoenberger": ['SH OW1 N B ER0 G ER0'],
  "schoenborn": ['SH OW1 N B ER0 N'],
  "schoendorf": ['SH OW1 N D AO0 R F'],
  "schoene": ['SH AA1 IY0 N'],
  "schoeneck": ['SH OW1 N EH0 K'],
  "schoenecker": ['SH OW1 N EH0 K ER0'],
  "schoeneman": ['SH AA1 IY0 N M AH0 N'],
  "schoenemann": ['SH AA1 IY0 N M AH0 N'],
  "schoener": ['SH OW1 N ER0'],
  "schoenfeld": ['SH OW1 N F EH2 L D'],
  "schoenfelder": ['SH OW1 N F EH0 L D ER0'],
  "schoenfeldt": ['SH OW1 N F EH2 L T'],
  "schoenhals": ['SH OW1 N HH AH0 L Z'],
  "schoenherr": ['SH OW1 N HH ER0'],
  "schoenhof": ['SH OW1 N HH AA2 F'],
  "schoenhof's": ['SH OW1 N HH AA2 F S'],
  "schoenholtz": ['SH OW1 N HH OW2 L T S'],
  "schoenig": ['SH OW1 N IH0 G'],
  "schoening": ['SH AA1 AH0 N IH0 NG'],
  "schoenrock": ['SH OW1 N R AH0 K'],
  "schoenthal": ['SH OW1 N TH AO2 L'],
  "schoenwald": ['SH OW1 N W AO2 L D'],
  "schoepe": ['SH OW1 P'],
  "schoepf": ['SH OW1 P'],
  "schoepke": ['SH OW1 P K IY0'],
  "schoepp": ['SH OW1 P'],
  "schoeppner": ['SH OW1 P N ER0'],
  "schoettle": ['SH OW1 T AH0 L'],
  "schoff": ['SH AO1 F'],
  "schoffstall": ['SH AO1 F S T AH0 L'],
  "schofield": ['S K OW1 F IY0 L D'],
  "scholar": ['S K AA1 L ER0'],
  "scholar's": ['S K AA1 L ER0 Z'],
  "scholarly": ['S K AA1 L ER0 L IY0'],
  "scholars": ['S K AA1 L ER0 Z'],
  "scholarship": ['S K AA1 L ER0 SH IH2 P'],
  "scholarships": ['S K AA1 L ER0 SH IH2 P S'],
  "scholastic": ['S K AH0 L AE1 S T IH0 K'],
  "scholberg": ['SH OW1 L B ER0 G'],
  "scholer": ['SH OW1 L ER0'],
  "scholes": ['S K OW1 L Z'],
  "scholey": ['SH OW1 L IY0'],
  "scholfield": ['S K OW1 L F IY2 L D'],
  "scholl": ['SH AA1 L'],
  "scholle": ['SH OW1 L'],
  "scholler": ['SH AA1 L ER0'],
  "schollmeyer": ['SH AA1 L M AY0 ER0'],
  "scholten": ['SH OW1 L T AH0 N'],
  "scholtes": ['SH OW1 L T S'],
  "scholtz": ['SH OW1 L T S'],
  "scholz": ['SH OW1 L Z'],
  "scholze": ['SH OW1 L Z'],
  "schomaker": ['SH OW1 M EY2 K ER0'],
  "schomberg": ['SH AA1 M B ER0 G'],
  "schomburg": ['SH AA1 M B ER0 G'],
  "schomer": ['SH OW1 M ER0'],
  "schommer": ['SH AA1 M ER0'],
  "schon": ['SH AA1 N'],
  "schonberg": ['SH AA1 N B ER0 G'],
  "schonberger": ['SH AA1 N B ER0 G ER0'],
  "schone": ['SH OW1 N'],
  "schoneman": ['SH OW1 N M AH0 N'],
  "schonfeld": ['SH AA1 N F EH2 L D'],
  "schons": ['SH AA1 N Z'],
  "schoo": ['SH UW1'],
  "schoof": ['SH UH1 F'],
  "schook": ['SH UH1 K'],
  "school": ['S K UW1 L'],
  "school's": ['S K UW1 L Z'],
  "schoolbook": ['S K UW1 L B UH2 K'],
  "schoolbooks": ['S K UW1 L B UH2 K S'],
  "schoolboy": ['S K UW1 L B OY2'],
  "schoolboys": ['S K UW1 L B OY2 Z'],
  "schoolbus": ['S K UW1 L B AH2 S'],
  "schoolchild": ['S K UW1 L CH AY2 L D'],
  "schoolchildren": ['S K UW1 L CH IH2 L D R AH0 N'],
  "schoolcraft": ['S K UW1 L K R AE2 F T'],
  "schooled": ['S K UW1 L D'],
  "schooler": ['S K UW1 L ER0'],
  "schoolers": ['S K UW1 L ER0 Z'],
  "schooley": ['S K UW1 L IY0'],
  "schoolfield": ['S K UW1 L F IY2 L D'],
  "schoolgirl": ['S K UW1 L G ER2 L'],
  "schoolgirls": ['S K UW1 L G ER2 L Z'],
  "schoolhouse": ['S K UW1 L HH AW2 S'],
  "schooling": ['S K UW1 L IH0 NG'],
  "schoolmaster": ['S K UW1 L M AE2 S T ER0'],
  "schoolmate": ['S K UW1 L M EY2 T'],
  "schoolmates": ['S K UW1 L M EY2 T S'],
  "schoolroom": ['S K UW1 L R UW2 M'],
  "schools": ['S K UW1 L Z'],
  "schools'": ['S K UW1 L Z'],
  "schoolteacher": ['S K UW1 L T IY2 CH ER0'],
  "schoolteachers": ['S K UW1 L T IY2 CH ER0 Z'],
  "schooltime": ['S K UW1 L T AY2 M'],
  "schoolwork": ['S K UW1 L W ER2 K'],
  "schoolyard": ['S K UW1 L Y AA2 R D'],
  "schoon": ['S K UW1 N'],
  "schooner": ['S K UW1 N ER0'],
  "schooners": ['S K UW1 N ER0 Z'],
  "schoonmaker": ['SH UW1 N M EY0 K ER0'],
  "schoonover": ['SH UW1 N AH0 V ER0'],
  "schopf": ['SH AA1 P F'],
  "schopfer": ['SH AA1 P F ER0'],
  "schopp": ['SH AA1 P'],
  "schoppe": ['SH AA1 P'],
  "schor": ['SH AO1 R'],
  "schork": ['SH AO1 R K'],
  "schorn": ['SH AO1 R N'],
  "schorr": ['SH AO1 R'],
  "schorr's": ['SH AO1 R Z'],
  "schorsch": ['SH AO1 R SH'],
  "schott": ['SH AA1 T'],
  "schott's": ['SH AA1 T S'],
  "schottenstein": ['SH AA1 T AH0 N S T IY2 N', 'SH AA1 T AH0 N S T AY2 N'],
  "schou": ['SH UW1'],
  "schouten": ['SH AA1 UW0 T AH0 N'],
  "schow": ['SH AW1'],
  "schowalter": ['SH AW1 AH0 L T ER0'],
  "schrack": ['SH R AE1 K'],
  "schrade": ['SH R EY1 D'],
  "schrader": ['SH R EY1 D ER0'],
  "schraeder": ['SH R EH1 D ER0'],
  "schrag": ['SH R AE1 G'],
  "schrage": ['SH R EY1 JH'],
  "schrager": ['SH R EY1 G ER0'],
  "schram": ['SH R AE1 M'],
  "schramm": ['SH R AE1 M'],
  "schrandt": ['SH R AE1 N T'],
  "schrank": ['SH R AE1 NG K'],
  "schrantz": ['SH R AE1 N T S'],
  "schranz": ['SH R AE1 N S'],
  "schraufnagel": ['SH R AW1 F N AH0 G AH0 L'],
  "schrecengost": ['SH R EH1 S IH0 NG G AH0 S T'],
  "schreck": ['SH R EH1 K'],
  "schreckengost": ['SH R EH1 K IH0 NG G AH0 S T'],
  "schrecongost": ['SH R EH1 K AH0 NG G AH0 S T'],
  "schreder": ['SH R IY1 D ER0'],
  "schreffler": ['SH R EH1 F AH0 L ER0', 'SH R EH1 F L ER0'],
  "schreger": ['SH R EH1 G ER0'],
  "schreiber": ['SH R AY1 B ER0'],
  "schreibman": ['SH R AY1 B M AH0 N'],
  "schreier": ['SH R AY1 ER0'],
  "schreifels": ['SH R AY1 F AH0 L Z'],
  "schreiner": ['SH R AY1 N ER0'],
  "schreiter": ['SH R AY1 T ER0'],
  "schremp": ['SH R EH1 M P'],
  "schrempf": ['SH R EH1 M P F'],
  "schrempp": ['SH R EH1 M P'],
  "schrenk": ['SH R EH1 NG K'],
  "schreur": ['SH R ER1'],
  "schreurs": ['SH R ER1 Z'],
  "schreyer": ['SH R AY1 R'],
  "schriber": ['SH R AY1 B ER0'],
  "schrick": ['SH R IH1 K'],
  "schricker": ['SH R IH1 K ER0'],
  "schrieber": ['SH R IY1 B ER0'],
  "schriefer": ['SH R IY1 F ER0'],
  "schrier": ['SH R AY1 ER0'],
  "schriever": ['SH R IY1 V ER0'],
  "schrimpf": ['SH R IH1 M P F'],
  "schrimsher": ['SH R IH1 M SH ER0'],
  "schriner": ['SH R AY1 N ER0'],
  "schriver": ['SH R AY1 V ER0'],
  "schroader": ['SH R OW1 D ER0'],
  "schrock": ['SH R AA1 K'],
  "schroder": ['SH R OW1 D ER0'],
  "schroders": ['SH R OW1 D ER0 Z'],
  "schrodt": ['SH R AA1 T'],
  "schroeck": ['SH R OW1 K'],
  "schroeder": ['SH R OW1 D ER0'],
  "schroeder's": ['SH R OW1 D ER0 Z'],
  "schroedl": ['SH R OW1 D AH0 L'],
  "schroepfer": ['SH R OW1 P F ER0'],
  "schroer": ['SH R OW1 ER0'],
  "schroeter": ['SH R OW1 T ER0'],
  "schroff": ['SH R AO1 F'],
  "schroll": ['SH R OW1 L'],
  "schrom": ['SH R AA1 M'],
  "schronce": ['SH R AA1 N S'],
  "schropp": ['SH R AA1 P'],
  "schroth": ['SH R AO1 TH'],
  "schroyer": ['SH R OY1 ER0'],
  "schrum": ['SH R AH1 M'],
  "schrumpf": ['SH R AH1 M P F'],
  "schrupp": ['SH R AH1 P'],
  "schryer": ['SH R AY1 ER0'],
  "schryver": ['SH R AY1 V ER0'],
  "schtick": ['SH T IH1 K'],
  "schubach": ['SH AH1 B AA0 K'],
  "schubel": ['SH UW1 B AH0 L'],
  "schubert": ['SH UW1 B ER0 T'],
  "schubert's": ['SH UW1 B ER0 T S'],
  "schubring": ['SH AH1 B ER0 IH0 NG'],
  "schuch": ['SH AH1 K'],
  "schuchard": ['SH AH1 K ER0 D'],
  "schuchardt": ['SH AH1 K AA0 R T'],
  "schuchart": ['SH AH1 K HH AA0 R T'],
  "schuchert": ['S K AH1 CH ER0 T'],
  "schuchman": ['SH AH1 K M AH0 N'],
  "schuchmann": ['SH AH1 K M AH0 N'],
  "schuck": ['SH AH1 K'],
  "schucker": ['SH AH1 K ER0'],
  "schuckman": ['SH AH1 K M AH0 N'],
  "schue": ['SH UW1'],
  "schuele": ['SH UW1 L'],
  "schueler": ['SH UW1 L ER0'],
  "schuelke": ['SH UW1 L K'],
  "schueller": ['SH UW1 L ER0'],
  "schueneman": ['SH UW1 N AH0 M AH0 N'],
  "schuenemann": ['SH UW1 N AH0 M AH0 N'],
  "schuerman": ['SH UW1 ER0 M AH0 N'],
  "schuermann": ['SH UW1 ER0 M AH0 N'],
  "schuessler": ['SH UW1 S L ER0'],
  "schuett": ['S K UW1 T'],
  "schuette": ['S K UW1 T'],
  "schuetz": ['SH UW1 T S'],
  "schuetze": ['SH UW1 T S'],
  "schuff": ['SH AH1 F'],
  "schug": ['SH AH1 G'],
  "schuh": ['SH UW1'],
  "schuhmacher": ['SH UW1 M AA0 K ER0'],
  "schuhmann": ['SH UW1 M AH0 N'],
  "schuitema": ['SH UW1 T IH0 M AH0'],
  "schuknecht": ['SH AH1 K N IH0 K T'],
  "schul": ['SH UH1 L'],
  "schuld": ['SH UH1 D'],
  "schuldener": ['SH UW1 L D AH0 N ER0'],
  "schuldener's": ['SH UW1 L D AH0 N ER0 Z'],
  "schulder": ['SH UW1 L D ER0'],
  "schuldt": ['SH UH1 L T'],
  "schulenberg": ['SH UW1 L AH0 N B ER0 G'],
  "schulenburg": ['SH UW1 L AH0 N B ER0 G'],
  "schuler": ['SH UW1 L ER0'],
  "schulhauf": ['SH UW1 L HH AA0 F'],
  "schulhaufs": ['SH UW1 L HH AA0 F S'],
  "schulhof": ['SH UW1 L HH AO0 F'],
  "schulke": ['SH UH1 L K IY0'],
  "schull": ['SH UH1 L'],
  "schuller": ['SH UW1 L ER0'],
  "schulman": ['SH UW1 L M AH0 N'],
  "schulof": ['SH UW1 L AO0 F'],
  "schult": ['SH AH1 L T'],
  "schulte": ['SH AH1 L T'],
  "schulten": ['SH AH1 L T AH0 N'],
  "schultes": ['SH AH1 L T S'],
  "schultheis": ['SH AH1 L DH AY0 Z'],
  "schultheiss": ['SH AH1 L TH AY0 S'],
  "schultz": ['SH UH1 L T S'],
  "schultze": ['SH AH1 L T Z'],
  "schulz": ['SH UH1 L T S'],
  "schulze": ['SH UH1 L T S'],
  "schum": ['SH AH1 M'],
  "schumacher": ['SH UW1 M AA2 K ER0'],
  "schumacker": ['SH UW1 M AA2 K ER0'],
  "schumaker": ['SH UW1 M EY2 K ER0'],
  "schuman": ['SH UW1 M AH0 N'],
  "schumann": ['SH UW1 M AH0 N'],
  "schumann's": ['SH UW1 M AH0 N Z'],
  "schumer": ['SH UW1 M ER0'],
  "schumer's": ['SH UW1 M ER0 Z'],
  "schumm": ['SH AH1 M'],
  "schumpert": ['SH AH1 M P ER0 T'],
  "schundler": ['SH AH1 N D L ER0'],
  "schuneman": ['SH UW1 N M AH0 N'],
  "schunk": ['SH AH1 NG K'],
  "schupak": ['SH UW1 P AE2 K'],
  "schupbach": ['SH AH1 P B AA2 K'],
  "schupp": ['SH AH1 P'],
  "schur": ['SH ER1'],
  "schuring": ['SH ER1 IH0 NG'],
  "schurman": ['SH ER1 M AH0 N'],
  "schurr": ['SH ER1'],
  "schurrenberg": ['SH ER1 AH0 N B ER0 G'],
  "schussler": ['SH AH1 S L ER0'],
  "schuster": ['SH UW1 S T ER0'],
  "schut": ['SH AH1 T'],
  "schuth": ['SH UW1 TH'],
  "schutt": ['SH AH1 T'],
  "schutte": ['S K AH1 T'],
  "schutter": ['SH AH1 T ER0'],
  "schuttler": ['SH AH1 T L ER0'],
  "schutz": ['SH AH1 T S'],
  "schutz's": ['SH AH1 T S IH0 Z'],
  "schutzman": ['SH AH1 T Z M AH0 N'],
  "schuur": ['SH UH1 R'],
  "schuyler": ['S K AY1 L ER0'],
  "schuylkill": ['S K Y UW1 L K IH2 L'],
  "schwa": ['SH W AA1'],
  "schwab": ['SH W AA1 B'],
  "schwab's": ['SH W AA1 B Z'],
  "schwabe": ['SH W AO1 B'],
  "schwaderer": ['SH W AO1 D ER0 ER0'],
  "schwager": ['SH W EY1 G ER0'],
  "schwahn": ['SH W AO1 N'],
  "schwaiger": ['SH W AY1 G ER0'],
  "schwake": ['SH W EY1 K'],
  "schwalb": ['SH W AO1 L B'],
  "schwalbach": ['SH W AO1 L B AA2 K'],
  "schwalbe": ['SH W AO1 L B'],
  "schwalier": ['SH W AA1 L Y ER0'],
  "schwall": ['SH W AO1 L'],
  "schwaller": ['SH W AO1 L ER0'],
  "schwalm": ['SH W AA1 L M'],
  "schwam": ['SH W AO1 M'],
  "schwan": ['SH W AO1 N'],
  "schwandt": ['SH W AO1 N T'],
  "schwanke": ['SH W AO1 NG K'],
  "schwantes": ['SH W AO1 N T S'],
  "schwanz": ['SH W AO1 N S'],
  "schwark": ['SH W AO1 R K'],
  "schwarm": ['SH W AO1 R M'],
  "schwartz": ['SH W AO1 R T S'],
  "schwartzberg": ['SH W AO1 R T S B ER0 G'],
  "schwartzberg's": ['SH W AO1 R T S B ER0 G Z'],
  "schwartzchild": ['SH W AO1 R T S CH AY2 L D'],
  "schwartzkopf": ['SH W AO1 R T S K AO0 P F', 'SH W AO1 R T S K AO0 F'],
  "schwartzman": ['SH W AO1 R T S M AH0 N'],
  "schwarz": ['SH W AO1 R T S'],
  "schwarze": ['SH W AO1 R T S'],
  "schwarzenegger": ['SH W AO1 R Z AH0 N EY2 G ER0'],
  "schwarzenegger's": ['SH W AO1 R Z AH0 N EY2 G ER0 Z'],
  "schwarzer": ['SH W AO1 R T S ER0'],
  "schwarzkopf": ['SH W AO1 R T S K AO0 P F', 'SH W AO1 R T S K AO0 F'],
  "schwarzkopf's": ['SH W AO1 R T S K AO0 P F S', 'SH W AO1 R T S K AO0 F S'],
  "schwarzman": ['SH W AO1 R T S M AH0 N'],
  "schwebach": ['SH W EH1 B AA2 K'],
  "schwebel": ['SH W EH1 B AH0 L'],
  "schwebke": ['SH W EH1 B K'],
  "schwed": ['SH W EH1 D'],
  "schweda": ['SH W IY1 D AH0'],
  "schwede": ['SH W IY1 D'],
  "schweder": ['SH W IY1 D ER0'],
  "schweer": ['SH W IH1 R'],
  "schweers": ['SH W IH1 R Z'],
  "schwegel": ['SH W EH1 G AH0 L'],
  "schwegler": ['SH W EH1 G AH0 L ER0', 'SH W EH1 G L ER0'],
  "schwegman": ['SH W EH1 G M AH0 N'],
  "schweich": ['SH W AY1 K'],
  "schweickert": ['SH W AY1 K ER0 T'],
  "schweiger": ['SH W AY1 G ER0'],
  "schweigert": ['SH W AY1 G ER0 T'],
  "schweighardt": ['SH W AY1 G HH AA2 R T'],
  "schweikert": ['SH W AY1 K ER0 T'],
  "schweinsberg": ['SH W AY1 N S B ER0 G'],
  "schweiss": ['SH W AY1 S'],
  "schweitzer": ['SH W AY1 T S ER0'],
  "schweitzer's": ['SH W AY1 T S ER0 Z'],
  "schweizer": ['SH W AY1 Z ER0'],
  "schweizerische": ['SH W AY1 Z ER0 IH0 SH'],
  "schwemm": ['SH W EH1 M'],
  "schwemmer": ['SH W EH1 M ER0'],
  "schwendeman": ['SH W EH1 N D M AH0 N'],
  "schwenk": ['SH W EH1 NG K'],
  "schwenke": ['SH W EH1 NG K'],
  "schwenker": ['SH W EH1 NG K ER0'],
  "schwenn": ['SH W EH1 N'],
  "schwent": ['SH W EH1 N T'],
  "schweppe": ['SH W EH1 P'],
  "schweppes": ['SH W EH1 P S'],
  "schwer": ['SH W ER1'],
  "schwerdloff": ['SH W ER1 D L AO0 F'],
  "schwerdt": ['SH W ER1 T'],
  "schwerdtfeger": ['SH W ER1 T F EY2 G ER0'],
  "schwerin": ['SH W EH1 R IH0 N'],
  "schwering": ['SH W IH1 R IH0 NG'],
  "schwerner": ['SH W ER1 N ER0'],
  "schwerner's": ['SH W ER1 N ER0 Z'],
  "schwertfeger": ['SH W ER1 T F IH0 G ER0'],
  "schwertner": ['SH W ER1 T N ER0'],
  "schwichtenberg": ['SH W IH1 K T AH0 N B ER0 G'],
  "schwieger": ['SH W IY1 G ER0'],
  "schwier": ['SH W AY1 ER0'],
  "schwiesow": ['SH W IY1 S OW0'],
  "schwieterman": ['SH W IY1 T ER0 M AH0 N'],
  "schwimmer": ['SH W IH1 M ER0'],
  "schwind": ['SH W IH1 N D'],
  "schwindt": ['SH W IH1 N T'],
  "schwing": ['SH W IH1 NG'],
  "schwinger": ['SH W IH1 NG ER0'],
  "schwinn": ['SH W IH1 N'],
  "schwisow": ['SH W IH1 S OW0'],
  "schwoerer": ['SH W OW1 ER0 ER0'],
  "sci": ['S IY1', 'EH1 S S IY1 AY1'],
  "sci-fi": ['S AY2 F AY1'],
  "sciacca": ['S K AO1 K AA2'],
  "scialabba": ['S K AO1 L AA0 B AH2'],
  "scialdone": ['SH AO1 L D OW0 N IY0'],
  "sciandra": ['SH AO1 N D R AA0'],
  "scianna": ['SH AO1 N AA0'],
  "sciara": ['SH AA1 R AA0'],
  "sciaroni": ['S IY2 ER0 OW1 N IY0'],
  "sciarra": ['S IY0 AA1 R AA0'],
  "sciarrino": ['SH ER0 IY1 N OW0'],
  "sciascia": ['SH AO1 S CH AA0'],
  "scibelli": ['S IH0 B EH1 L IY2'],
  "scibilia": ['S IH0 B IY1 L IY2 AH0'],
  "scicchitano": ['S IH2 K IH0 T AA1 N OW2'],
  "sciclone": ['S IH2 K L OW1 N IY0'],
  "scicom": ['S IH1 K AA2 M'],
  "science": ['S AY1 AH0 N S'],
  "science's": ['S AY1 AH0 N S IH0 Z'],
  "science-fiction": ['S AY1 AH0 N S F IH2 K SH AH0 N'],
  "sciences": ['S AY1 AH0 N S AH0 Z', 'S AY1 AH0 N S IH0 Z'],
  "sciences'": ['S AY1 AH0 N S IH0 Z'],
  "scientific": ['S AY2 AH0 N T IH1 F IH0 K'],
  "scientific's": ['S AY2 AH0 N T IH1 F IH0 K S'],
  "scientifically": ['S AY2 AH0 N T IH1 F IH0 K AH0 L IY0', 'S AY2 AH0 N T IH1 F IH0 K L IY0'],
  "scientifics": ['S AY2 AH0 N T IH1 F IH0 K S'],
  "scientist": ['S AY1 AH0 N T IH0 S T'],
  "scientist's": ['S AY1 AH0 N T IH0 S T S'],
  "scientists": ['S AY1 AH0 N T IH0 S T S', 'S AY1 N T IH0 S T S', 'S AY1 N T IH0 S', 'S AY1 AH0 N T IH0 S'],
  "scientists'": ['S AY1 AH0 N T IH0 S T S'],
  "scientologist": ['S AY2 AH0 N T AA1 L AH0 JH AH0 S T'],
  "scientologists": ['S AY2 AH0 N T AA1 L AH0 JH AH0 S T S'],
  "scientology": ['S AY2 AH0 N T AA1 L AH0 JH IY0'],
  "scifres": ['S AY1 F ER0 Z'],
  "scilla": ['S IH1 L AH0'],
  "scillas": ['S IH1 L AH0 Z'],
  "scimeca": ['S IH0 M EH1 K AH0', 'S AY2 M EH1 K AH0'],
  "scimed": ['S AY1 M EH2 D'],
  "scimone": ['S IH0 M OW1 N'],
  "scinta": ['S IH1 N T AH0'],
  "scintilla": ['S IH0 N T IH1 L AH0'],
  "scintilore": ['S IH1 N T AH0 L AO2 R IY0'],
  "scinto": ['S IH1 N T OW0'],
  "scioli": ['S IY0 OW1 L IY0'],
  "scion": ['S AY1 AH0 N'],
  "sciortino": ['S IY0 AO0 R T IY1 N OW0'],
  "scios": ['S K AY1 OW0 S'],
  "scioto": ['S K IY0 OW1 T OW0'],
  "scipio": ['S IH1 P IY0 OW0'],
  "scipione": ['S IH0 P IY0 OW1 N IY0'],
  "scire": ['S AY1 R'],
  "scism": ['S K IH1 Z AH0 M'],
  "scissor": ['S IH1 Z ER0'],
  "scissors": ['S IH1 Z ER0 Z'],
  "scitex": ['S IY1 T EH2 K S'],
  "sciulli": ['S IY0 UW1 L IY0'],
  "sciullo": ['S IY0 UW1 L OW0'],
  "sciuto": ['S IY0 UW1 T OW0'],
  "sclafani": ['S K L AA0 F AA1 N IY0'],
  "scleroderma": ['S K L IH2 R AH0 D ER1 M AH0'],
  "sclerosis": ['S K L ER0 OW1 S AH0 S'],
  "scobee": ['S K AA1 B IY0'],
  "scobey": ['S K OW1 B IY0'],
  "scobie": ['S K AA1 B IY0'],
  "scoby": ['S K OW1 B IY0'],
  "scocozza": ['S K AA0 K AA1 Z AH0'],
  "scoff": ['S K AO1 F'],
  "scoffed": ['S K AO1 F T'],
  "scoffield": ['S K AO1 F IY2 L D'],
  "scofflaws": ['S K AA1 F L AO2 Z'],
  "scoffs": ['S K AO1 F S'],
  "scofield": ['S K OW1 F IY2 L D'],
  "scoggin": ['S K AA1 G IH0 N'],
  "scoggins": ['S K AA1 G IH0 N Z'],
  "scogin": ['S K OW1 G IH0 N'],
  "scohier": ['S K OW1 Y ER0'],
  "scola": ['S K OW1 L AH0'],
  "scolari": ['S K OW0 L AA1 R IY0'],
  "scolaro": ['S K OW0 L AA1 R OW0'],
  "scold": ['S K OW1 L D'],
  "scolded": ['S K OW1 L D IH0 D'],
  "scolding": ['S K OW1 L D IH0 NG'],
  "scolds": ['S K OW1 L D Z'],
  "scoles": ['S K OW1 L Z'],
  "scolia": ['S K OW1 L Y AH0'],
  "scoma": ['S K OW1 M AH0'],
  "sconc": ['S K AA1 N S'],
  "sconce": ['S K AA1 N S'],
  "sconces": ['S K AA1 N S IH0 Z'],
  "scone": ['S K OW1 N'],
  "scones": ['S K OW1 N Z'],
  "sconiers": ['S K AO1 N IY0 ER0 Z'],
  "sconnix": ['S K AA1 N IH0 K S'],
  "sconyers": ['S K AO1 N IY0 ER0 Z'],
  "scooby": ['S K UW1 B IY0'],
  "scoop": ['S K UW1 P'],
  "scooped": ['S K UW1 P T'],
  "scooper": ['S K UW1 P ER0'],
  "scooping": ['S K UW1 P IH0 NG'],
  "scoops": ['S K UW1 P S'],
  "scoot": ['S K UW1 T'],
  "scooter": ['S K UW1 T ER0'],
  "scooters": ['S K UW1 T ER0 Z'],
  "scoots": ['S K UW1 T S'],
  "scope": ['S K OW1 P'],
  "scopes": ['S K OW1 P S'],
  "scopic": ['S K AA1 P IH0 K'],
  "scoping": ['S K OW1 P IH0 NG'],
  "scopolamine": ['S K AO1 P AH0 L AH0 M IY2 N', 'S K OW1 P L AH0 M IY2 N'],
  "scor": ['S K AO1 R'],
  "scorch": ['S K AO1 R CH'],
  "scorched": ['S K AO1 R CH T'],
  "scorcher": ['S K AO1 R CH ER0'],
  "scorching": ['S K AO1 R CH IH0 NG'],
  "score": ['S K AO1 R'],
  "scoreboard": ['S K AO1 R B AO2 R D'],
  "scorecard": ['S K AO1 R K AA2 R D'],
  "scorecards": ['S K AO1 R K AA2 R D Z'],
  "scored": ['S K AO1 R D'],
  "scorekeeper": ['S K AO1 R K IY2 P ER0'],
  "scorekeepers": ['S K AO1 R K IY2 P ER0 Z'],
  "scorekeeping": ['S K AO1 R K IY2 P IH0 NG'],
  "scoreless": ['S K AO1 R L AH0 S'],
  "scorer": ['S K AO1 R ER0'],
  "scorers": ['S K AO1 R ER0 Z'],
  "scores": ['S K AO1 R Z'],
  "scoring": ['S K AO1 R IH0 NG'],
  "scorn": ['S K AO1 R N'],
  "scorned": ['S K AO1 R N D'],
  "scornful": ['S K AO1 R N F AH0 L'],
  "scorns": ['S K AO1 R N Z'],
  "scorpio": ['S K AO1 R P IY0 OW2'],
  "scorpion": ['S K AO1 R P IY0 AH0 N'],
  "scorpions": ['S K AO1 R P IY0 AH0 N Z'],
  "scorpius": ['S K AO1 R P IY2 AH0 S'],
  "scorsese": ['S K AO2 R S IY1 Z', 'S K AO2 R S EY1 Z IY0'],
  "scorsese's": ['S K AO2 R S IY1 Z IH0 Z', 'S K AO2 R S EY1 Z IY0 Z'],
  "scorsone": ['S K AO1 R S AH0 N'],
  "scortese": ['S K AO0 R T IY1 Z', 'S K AO0 R T IY1 Z IY0'],
  "scorza": ['S K AO1 R Z AH0'],
  "scot": ['S K AA1 T'],
  "scotch": ['S K AA1 CH'],
  "scotched": ['S K AA1 CH T'],
  "scotches": ['S K AA1 CH IH0 Z'],
  "scotchgard": ['S K AA1 CH G AA2 R D'],
  "scotia": ['S K OW1 SH AH0'],
  "scotia's": ['S K OW1 SH AH0 Z'],
  "scotland": ['S K AA1 T L AH0 N D'],
  "scotland's": ['S K AA1 T L AH0 N D Z'],
  "scots": ['S K AA1 T S'],
  "scotsman": ['S K AA1 T S M AH0 N'],
  "scott": ['S K AA1 T'],
  "scott's": ['S K AA1 T S'],
  "scotten": ['S K AA1 T AH0 N'],
  "scottie": ['S K AA1 T IY0'],
  "scottish": ['S K AA1 T IH0 SH'],
  "scotto": ['S K OW1 T OW0', 'S K AA1 T OW0'],
  "scotton": ['S K AA1 T AH0 N'],
  "scotts": ['S K AA1 T S'],
  "scottsbluff": ['S K AA1 T S B L AH2 F'],
  "scottsdale": ['S K AA1 T S D EY2 L'],
  "scotty": ['S K AA1 T IY0'],
  "scotty's": ['S K AA1 T IY0 Z'],
  "scoundrel": ['S K AW1 N D R AH0 L'],
  "scoundrels": ['S K AW1 N D R AH0 L Z'],
  "scour": ['S K AW1 ER0', 'S K AW1 R'],
  "scoured": ['S K AW1 ER0 D'],
  "scourge": ['S K ER1 JH'],
  "scourged": ['S K ER1 JH D'],
  "scourges": ['S K ER1 JH IH0 Z'],
  "scourging": ['S K ER1 JH IH0 NG'],
  "scouring": ['S K AW1 ER0 IH0 NG'],
  "scours": ['S K AW1 ER0 Z'],
  "scout": ['S K AW1 T'],
  "scout's": ['S K AW1 T S'],
  "scouted": ['S K AW1 T IH0 D'],
  "scouten": ['S K AW1 T AH0 N'],
  "scouter": ['S K AW1 T ER0'],
  "scouters": ['S K AW1 T ER0 Z'],
  "scouting": ['S K AW1 T IH0 NG'],
  "scoutmaster": ['S K AW1 T M AE2 S T ER0'],
  "scouts": ['S K AW1 T S'],
  "scovel": ['S K OW1 V AH0 L'],
  "scovell": ['S K AA1 V AH0 L'],
  "scovil": ['S K OW1 V AH0 L'],
  "scovill": ['S K AA1 V AH0 L'],
  "scoville": ['S K OW1 V IH2 L'],
  "scow": ['S K AW1'],
  "scowcroft": ['S K OW1 K R AO2 F T'],
  "scowden": ['S K AW1 D AH0 N'],
  "scowl": ['S K AW1 L'],
  "scowled": ['S K AW1 L D'],
  "scowling": ['S K AW1 L IH0 NG'],
  "scozzafava": ['S K OW0 T S AA0 F AA1 V AH0'],
  "scrabble": ['S K R AE1 B AH0 L'],
  "scragg": ['S K R AE1 G'],
  "scraggle": ['S K R AE1 G AH0 L'],
  "scraggly": ['S K R AE1 G L IY0'],
  "scram": ['S K R AE1 M'],
  "scramble": ['S K R AE1 M B AH0 L'],
  "scrambled": ['S K R AE1 M B AH0 L D'],
  "scrambles": ['S K R AE1 M B AH0 L Z'],
  "scrambling": ['S K R AE1 M B AH0 L IH0 NG', 'S K R AE1 M B L IH0 NG'],
  "scrams": ['S K R AE1 M Z'],
  "scranton": ['S K R AE1 N T AH0 N'],
  "scrap": ['S K R AE1 P'],
  "scrapbook": ['S K R AE1 P B UH2 K'],
  "scrapbooks": ['S K R AE1 P B UH2 K S'],
  "scrape": ['S K R EY1 P'],
  "scraped": ['S K R EY1 P T'],
  "scraper": ['S K R EY1 P ER0'],
  "scrapers": ['S K R EY1 P ER0 Z'],
  "scrapes": ['S K R EY1 P S'],
  "scraping": ['S K R EY1 P IH0 NG'],
  "scrapings": ['S K R EY1 P IH0 NG Z'],
  "scrapiron": ['S K R AE1 P AY2 ER0 N'],
  "scrapped": ['S K R AE1 P T'],
  "scrapping": ['S K R AE1 P IH0 NG'],
  "scrappy": ['S K R AE1 P IY0'],
  "scraps": ['S K R AE1 P S'],
  "scratch": ['S K R AE1 CH'],
  "scratched": ['S K R AE1 CH T'],
  "scratches": ['S K R AE1 CH AH0 Z', 'S K R AE1 CH IH0 Z'],
  "scratching": ['S K R AE1 CH IH0 NG'],
  "scratchy": ['S K R AE1 CH IY0'],
  "scrawl": ['S K R AO1 L'],
  "scrawled": ['S K R AO1 L D'],
  "scrawny": ['S K R AO1 N IY0'],
  "scream": ['S K R IY1 M'],
  "screamed": ['S K R IY1 M D'],
  "screamer": ['S K R IY1 M ER0'],
  "screamers": ['S K R IY1 M ER0 Z'],
  "screaming": ['S K R IY1 M IH0 NG'],
  "screamingly": ['S K R IY1 M IH0 NG L IY0'],
  "screams": ['S K R IY1 M Z'],
  "screech": ['S K R IY1 CH'],
  "screeched": ['S K R IY1 CH T'],
  "screeches": ['S K R IY1 CH IH0 Z'],
  "screeching": ['S K R IY1 CH IH0 NG'],
  "screed": ['S K R IY1 D'],
  "screen": ['S K R IY1 N'],
  "screened": ['S K R IY1 N D'],
  "screener": ['S K R IY1 N ER0'],
  "screeners": ['S K R IY1 N ER0 Z'],
  "screening": ['S K R IY1 N IH0 NG'],
  "screenings": ['S K R IY1 N IH0 NG Z'],
  "screenplay": ['S K R IY1 N P L EY2'],
  "screenplays": ['S K R IY1 N P L EY2 Z'],
  "screens": ['S K R IY1 N Z'],
  "screenshot": ['S K R IY1 N SH AA2 T'],
  "screenshots": ['S K R IY1 N SH AA2 T S'],
  "screenwriter": ['S K R IY1 N R AY2 T ER0'],
  "screenwriters": ['S K R IY1 N R AY2 T ER0 Z'],
  "screenwriting": ['S K R IY1 N R AY2 T IH0 NG'],
  "screw": ['S K R UW1'],
  "screw-up": ['S K R UW1 AH2 P'],
  "screwball": ['S K R UW1 B AO2 L'],
  "screwdriver": ['S K R UW1 D R AY2 V ER0'],
  "screwdrivers": ['S K R UW1 D R AY2 V ER0 Z'],
  "screwed": ['S K R UW1 D'],
  "screwing": ['S K R UW1 IH0 NG'],
  "screws": ['S K R UW1 Z'],
  "screws-up": ['S K R UW1 Z AH2 P'],
  "screwy": ['S K R UW1 IY0'],
  "scribble": ['S K R IH1 B AH0 L'],
  "scribbled": ['S K R IH1 B AH0 L D'],
  "scribbler": ['S K R IH1 B L ER0'],
  "scribblers": ['S K R IH1 B L ER0 Z'],
  "scribbles": ['S K R IH1 B AH0 L Z'],
  "scribbling": ['S K R IH1 B AH0 L IH0 NG', 'S K R IH1 B L IH0 NG'],
  "scribe": ['S K R AY1 B'],
  "scriber": ['S K R AY1 B ER0'],
  "scribes": ['S K R AY1 B Z'],
  "scribner": ['S K R IH1 B N ER0'],
  "scribner's": ['S K R IH1 B N ER0 Z'],
  "scribners": ['S K R IH1 B N ER0 Z'],
  "scrimgeour": ['S K R IH1 M G AO0 R'],
  "scrimmage": ['S K R IH1 M IH0 JH'],
  "scrimp": ['S K R IH1 M P'],
  "scrimping": ['S K R IH1 M P IH0 NG'],
  "scrimshaw": ['S K R IH1 M SH AO2'],
  "scrip": ['S K R IH1 P'],
  "scripp": ['S K R IH1 P'],
  "scripp's": ['S K R IH1 P S'],
  "scripps": ['S K R IH1 P S'],
  "script": ['S K R IH1 P T'],
  "scripted": ['S K R IH1 P T IH0 D'],
  "scripts": ['S K R IH1 P T S', 'S K R IH1 P S'],
  "scriptural": ['S K R IH1 P CH ER0 AH0 L'],
  "scripture": ['S K R IH1 P CH ER0'],
  "scriptures": ['S K R IH1 P CH ER0 Z'],
  "scriptwriter": ['S K R IH1 P T R AY2 T ER0'],
  "scriptwriters": ['S K R IH1 P T R AY2 T ER0 Z'],
  "scriptwriting": ['S K R IH1 P T R AY2 T IH0 NG'],
  "scritchfield": ['S K R IH1 CH F IY0 L D'],
  "scriven": ['S K R IH1 V IH0 N'],
  "scrivener": ['S K R IH1 V N ER0'],
  "scrivens": ['S K R AY1 V AH0 N Z'],
  "scriver": ['S K R AY1 V ER0'],
  "scrivner": ['S K R IH1 V N ER0'],
  "scrod": ['S K R AA1 D'],
  "scroggin": ['S K R AA1 G IH0 N'],
  "scroggins": ['S K R AA1 G IH0 N Z'],
  "scroggs": ['S K R AA1 G Z'],
  "scrogham": ['S K R AA1 G HH AH0 M'],
  "scroll": ['S K R OW1 L'],
  "scrolling": ['S K R OW1 L IH0 NG'],
  "scrolls": ['S K R OW1 L Z'],
  "scronce": ['S K R AA1 N S'],
  "scrooge": ['S K R UW1 JH'],
  "scrotten": ['S K R AO1 T IH0 N'],
  "scrounge": ['S K R AW1 N JH'],
  "scrounged": ['S K R AW1 N JH D'],
  "scrounging": ['S K R AW1 N JH IH0 NG'],
  "scrub": ['S K R AH1 B'],
  "scrubbed": ['S K R AH1 B D'],
  "scrubber": ['S K R AH1 B ER0'],
  "scrubbers": ['S K R AH1 B ER0 Z'],
  "scrubbing": ['S K R AH1 B IH0 NG'],
  "scrubby": ['S K R AH1 B IY0'],
  "scruffy": ['S K R AH1 F IY0'],
  "scruggs": ['S K R AH1 G Z'],
  "scrunch": ['S K R AH1 N CH'],
  "scrunched": ['S K R AH1 N CH T'],
  "scruple": ['S K R UW1 P AH0 L'],
  "scruples": ['S K R UW1 P AH0 L Z'],
  "scrupulous": ['S K R UW1 P Y AH0 L AH0 S'],
  "scrupulously": ['S K R UW1 P Y AH0 L AH0 S L IY0'],
  "scrutinize": ['S K R UW1 T AH0 N AY2 Z'],
  "scrutinized": ['S K R UW1 T AH0 N AY2 Z D'],
  "scrutinizes": ['S K R UW1 T AH0 N AY2 Z IH0 Z'],
  "scrutinizing": ['S K R UW1 T AH0 N AY2 Z IH0 NG'],
  "scrutiny": ['S K R UW1 T AH0 N IY0'],
  "scruton": ['S K R UW1 T AH0 N'],
  "scrutton": ['S K R AH1 T AH0 N'],
  "scrutton's": ['S K R AH1 T AH0 N Z'],
  "scs": ['EH2 S S IY2 EH1 S'],
  "scsi": ['S K UH1 Z IY2'],
  "scuba": ['S K UW1 B AH0'],
  "scud": ['S K AH1 D'],
  "scudder": ['S K AH1 D ER0'],
  "scudder's": ['S K AH1 D ER0 Z'],
  "scuderi": ['S K UW0 D EH1 R IY0'],
  "scuds": ['S K AH1 D Z'],
  "scuff": ['S K AH1 F'],
  "scuffed": ['S K AH1 F T'],
  "scuffle": ['S K AH1 F AH0 L'],
  "scuffled": ['S K AH1 F AH0 L D'],
  "scuffles": ['S K AH1 F AH0 L Z'],
  "scuffling": ['S K AH1 F AH0 L IH0 NG'],
  "scull": ['S K AH1 L'],
  "sculley": ['S K AH1 L IY0'],
  "sculley's": ['S K AH1 L IY0 Z'],
  "scullin": ['S K AH1 L IH0 N'],
  "scullion": ['S K AH1 L Y AH0 N'],
  "scully": ['S K AH1 L IY0'],
  "sculpt": ['S K AH1 L P T'],
  "sculpted": ['S K AH1 L P T IH0 D'],
  "sculpting": ['S K AH1 L P T IH0 NG'],
  "sculptor": ['S K AH1 L P T ER0'],
  "sculptors": ['S K AH1 L P T ER0 Z'],
  "sculpts": ['S K AH1 L P T S'],
  "sculptural": ['S K AH1 L P CH ER0 AH0 L'],
  "sculpture": ['S K AH1 L P CH ER0'],
  "sculptured": ['S K AH1 L P CH ER0 D'],
  "sculptures": ['S K AH1 L P CH ER0 Z'],
  "sculpturing": ['S K AH1 L P T ER0 IH0 NG', 'S K AH1 L P CH ER0 IH0 NG'],
  "scum": ['S K AH1 M'],
  "scumbag": ['S K AH1 M B AE2 G'],
  "scumbags": ['S K AH1 M B AE2 G Z'],
  "scupper": ['S K AH1 P ER0'],
  "scuppernong": ['S K AH1 P ER0 N AO2 NG'],
  "scuppers": ['S K AH1 P ER0 Z'],
  "scurdall": ['S K ER1 D AA0 L'],
  "scurdell": ['S K ER1 D EH0 L', 'S K ER0 D EH1 L'],
  "scurlock": ['S K ER1 L AH0 K'],
  "scurried": ['S K ER1 IY0 D'],
  "scurrilous": ['S K ER1 AH0 L AH0 S'],
  "scurry": ['S K ER1 IY0'],
  "scurrying": ['S K ER1 IY0 IH0 NG'],
  "scutt": ['S K AH1 T'],
  "scuttle": ['S K AH1 T AH0 L'],
  "scuttlebutt": ['S K AH1 T AH0 L B AH2 T'],
  "scuttled": ['S K AH1 T AH0 L D'],
  "scuttling": ['S K AH1 T AH0 L IH0 NG', 'S K AH1 T L IH0 NG'],
  "scylla": ['S IH1 L AH0'],
  "scythe": ['S IH1 TH', 'S AY1 TH'],
  "scythian": ['S IH1 TH IY0 AH0 N'],
  "sczechuan": ['S EH1 CH UW0 AA0 N'],
  "sczepanski": ['S IH0 P AE1 N S K IY0'],
  "sdn": ['EH1 S D IY1 EH1 N'],
  "se": ['S EY1'],
  "sea": ['S IY1'],
  "sea's": ['S IY1 Z'],
  "seabaugh": ['S IY1 B AO2'],
  "seabeach": ['S IY1 B IY2 CH'],
  "seabed": ['S IY1 B EH2 D'],
  "seabee": ['S IY1 B IY0'],
  "seabees": ['S IY1 B IY0 Z'],
  "seaberg": ['S IY1 B ER0 G'],
  "seaberry": ['S IY1 B EH2 R IY0'],
  "seabert": ['S IY1 B ER0 T'],
  "seaboard": ['S IY1 B AO2 R D'],
  "seabold": ['S IY1 B OW2 L D'],
  "seabolt": ['S IY1 B OW2 L T'],
  "seaborn": ['S IY1 B ER0 N'],
  "seaborne": ['S IY1 B AO2 R N'],
  "seabright": ['S IY1 B R AY2 T'],
  "seabrook": ['S IY1 B R UH2 K'],
  "seabrook's": ['S IY1 B R UH2 K S'],
  "seabrooks": ['S IY1 B R UH2 K S'],
  "seaburg": ['S IY1 B ER0 G'],
  "seabury": ['S IY1 B EH2 R IY0'],
  "seaco": ['S IY1 K OW0'],
  "seacoast": ['S IY1 K OW2 S T'],
  "seader": ['S IY1 D ER0'],
  "seadrift": ['S IY1 D R IH2 F T'],
  "seafarer": ['S IY1 F EH2 R ER0'],
  "seafarers": ['S IY1 F EH2 R ER0 Z'],
  "seafirst": ['S IY1 F ER2 S T'],
  "seafood": ['S IY1 F UW2 D'],
  "seafoods": ['S IY1 F UW2 D Z'],
  "seaford": ['S IY1 F ER0 D'],
  "seafront": ['S IY1 F R AH0 N T'],
  "seaga": ['S IY1 G AH0'],
  "seagal": ['S IH0 G AA1 L'],
  "seagate": ['S IY1 G EY2 T'],
  "seagate's": ['S IY1 G EY2 T S'],
  "seager": ['S IY1 G ER0'],
  "seagle": ['S IY1 G AH0 L'],
  "seago": ['S IY1 G OW2'],
  "seagoing": ['S IY1 G OW2 IH0 NG'],
  "seagram": ['S IY1 G R AH0 M'],
  "seagram's": ['S IY1 G R AH0 M Z'],
  "seagrams": ['S IY1 G R AH0 M Z'],
  "seagrams'": ['S IY1 G R AH0 M Z'],
  "seagrave": ['S IY1 G R EY2 V'],
  "seagraves": ['S IY1 G R EY2 V Z'],
  "seagren": ['S IY1 G R EH0 N'],
  "seagrove": ['S IY1 G R OW2 V'],
  "seagroves": ['S IY1 G R OW2 V Z'],
  "seagull": ['S IY1 G AH2 L'],
  "seagull's": ['S IY1 G AH2 L Z'],
  "seagulls": ['S IY1 G AH2 L Z'],
  "seahawk": ['S IY1 HH AO2 K'],
  "seahawks": ['S IY1 HH AO2 K S'],
  "seaholm": ['S IY1 HH OW2 M'],
  "seal": ['S IY1 L'],
  "seal's": ['S IY1 L Z'],
  "sealand": ['S IY1 L AE2 N D'],
  "sealand's": ['S IY1 L AE2 N D Z'],
  "sealant": ['S IY1 L AH0 N T'],
  "sealants": ['S IY1 L AH0 N T S'],
  "seale": ['S IY1 L'],
  "sealed": ['S IY1 L D'],
  "sealer": ['S IY1 L ER0'],
  "seales": ['S IY1 L Z'],
  "sealey": ['S IY1 L IY0'],
  "sealey's": ['S IY1 L IY0 Z'],
  "sealift": ['S IY1 L IH2 F T'],
  "sealing": ['S IY1 L IH0 NG'],
  "sealock": ['S IY1 L AA2 K'],
  "seals": ['S IY1 L Z'],
  "sealtest": ['S IY1 L T EH2 S T'],
  "sealy": ['S IY1 L IY0'],
  "seam": ['S IY1 M'],
  "seaman": ['S IY1 M AH0 N'],
  "seaman's": ['S IY1 M AH0 N Z'],
  "seamans": ['S IY1 M AH0 N Z'],
  "seamen": ['S IY1 M AH0 N'],
  "seamen's": ['S IY1 M AH0 N Z'],
  "seamless": ['S IY1 M L AH0 S'],
  "seamlessly": ['S IY1 M L AH0 S L IY0'],
  "seamon": ['S IY1 M AH0 N'],
  "seamons": ['S IY1 M AH0 N Z'],
  "seams": ['S IY1 M Z'],
  "seamster": ['S IY1 M S T ER0'],
  "seamstress": ['S IY1 M S T R IH0 S'],
  "seamstresses": ['S IY1 M S T R AH0 S AH0 Z'],
  "seamus": ['SH EY1 M AH0 S'],
  "seamy": ['S IY1 M IY0'],
  "sean": ['SH AO1 N'],
  "sean's": ['SH AO1 N Z'],
  "seanang": ['SH AA1 N AH0 NG'],
  "seanangs": ['SH AA1 N AH0 NG Z'],
  "seance": ['S EY1 AA0 N S'],
  "seanor": ['S IY1 N ER0'],
  "seaport": ['S IY1 P AO2 R T'],
  "seaports": ['S IY1 P AO2 R T S'],
  "seapower": ['S IY1 P AW2 ER0'],
  "seaq": ['S IY1 K'],
  "seaquest": ['S IY1 K W EH2 S T'],
  "seaquist": ['S IY1 K W IH2 S T'],
  "sear": ['S IH1 R'],
  "search": ['S ER1 CH'],
  "searched": ['S ER1 CH T'],
  "searcher": ['S ER1 CH ER0'],
  "searchers": ['S ER1 CH ER0 Z'],
  "searches": ['S ER1 CH IH0 Z'],
  "searching": ['S ER1 CH IH0 NG'],
  "searchlight": ['S ER1 CH L AY2 T'],
  "searchlights": ['S ER1 CH L AY2 T S'],
  "searcy": ['S ER1 S IY0'],
  "seared": ['S IH1 R D'],
  "searfoss": ['S ER1 F AH0 S'],
  "searight": ['S IH1 R AY0 T'],
  "searing": ['S IH1 R IH0 NG'],
  "searl": ['S ER1 L'],
  "searle": ['S ER1 L'],
  "searle's": ['S ER1 L Z'],
  "searles": ['S ER1 L Z'],
  "searls": ['S ER1 L Z'],
  "sears": ['S IH1 R Z'],
  "sears'": ['S IH1 R Z'],
  "sears's": ['S IH1 R Z IH0 Z', 'S IH1 R Z'],
  "searson": ['S ER1 S AH0 N'],
  "seas": ['S IY1 Z'],
  "sease": ['S IY1 Z'],
  "seashell": ['S IY1 SH EH2 L'],
  "seashells": ['S IY1 SH EH2 L Z'],
  "seashore": ['S IY1 SH AO2 R'],
  "seasick": ['S IY1 S IH2 K'],
  "seaside": ['S IY1 S AY2 D'],
  "season": ['S IY1 Z AH0 N'],
  "season's": ['S IY1 Z AH0 N Z'],
  "seasonable": ['S IY1 Z AH0 N AH0 B AH0 L'],
  "seasonal": ['S IY1 Z AH0 N AH0 L'],
  "seasonality": ['S IY1 Z AH0 N AE2 L IH0 T IY0', 'S IY0 Z AH0 N AE1 L IH0 T IY0'],
  "seasonally": ['S IY1 Z AH0 N AH0 L IY0', 'S IY1 Z N AH0 L IY0'],
  "seasoned": ['S IY1 Z AH0 N D'],
  "seasoning": ['S IY1 Z AH0 N IH0 NG'],
  "seasonings": ['S IY1 Z AH0 N IH0 NG Z'],
  "seasons": ['S IY1 Z AH0 N Z'],
  "seastrand": ['S IY1 S T R AE2 N D'],
  "seastrom": ['S IY1 S T R AH0 M'],
  "seat": ['S IY1 T'],
  "seat's": ['S IY1 T S'],
  "seatbelt": ['S IY1 T B EH2 L T'],
  "seatbelts": ['S IY1 T B EH2 L T S'],
  "seated": ['S IY1 T IH0 D'],
  "seater": ['S IY1 T ER0'],
  "seating": ['S IY1 T IH0 NG'],
  "seatings": ['S IY1 T IH0 NG Z'],
  "seato": ['S IY1 T OW0'],
  "seaton": ['S IY1 T AH0 N'],
  "seatrain": ['S IY1 T R EY2 N'],
  "seats": ['S IY1 T S'],
  "seattle": ['S IY0 AE1 T AH0 L'],
  "seattle's": ['S IY0 AE1 T AH0 L Z'],
  "seaver": ['S IY1 V ER0'],
  "seavers": ['S IY1 V ER0 Z'],
  "seavey": ['S IY1 V IY0'],
  "seaward": ['S IY1 W ER0 D'],
  "seawater": ['S IY1 W AA2 T ER0', 'S IY1 W AO2 T ER0'],
  "seaway": ['S IY1 W EY2'],
  "seaweed": ['S IY1 W IY2 D'],
  "seaweeds": ['S IY1 W IY2 D Z'],
  "seawell": ['S IY1 W EH2 L'],
  "seawolf": ['S IY1 W UH2 L F'],
  "seawolf's": ['S IY1 W UH2 L F S'],
  "seaworthy": ['S IY1 W AO2 R DH IY0'],
  "seawright": ['S IY1 R AY2 T'],
  "seay": ['S EY1'],
  "sebaceous": ['S AH0 B EY1 SH AH0 S'],
  "sebald": ['S IY1 B AO0 L D'],
  "sebastian": ['S AH0 B AE1 S CH AH0 N'],
  "sebastian's": ['S AH0 B AE1 S CH AH0 N Z'],
  "sebastiana": ['S AH0 B AE2 S T IY0 AA1 N AH0'],
  "sebastiane": ['S AH0 B AE2 S T IY0 EH1 N'],
  "sebastiani": ['S AH0 B AE2 S T IY0 AA1 N IY0', 'S AH0 B AE2 S T Y AA1 N IY0'],
  "sebastianis": ['S AH0 B AE2 S T IY0 AA1 N IY0 Z', 'S AH0 B AE2 S T Y AA1 N IY0 Z'],
  "sebastopol": ['S EH0 B AA1 S T AH0 P AO2 L'],
  "sebby": ['S EH1 B IY0'],
  "sebek": ['S EH1 B IH0 K'],
  "seber": ['S IY1 B ER0'],
  "sebert": ['S EH1 B ER0 T'],
  "sebesta": ['S EH0 B EH1 S T AH0'],
  "sebi": ['S EH1 B IY0'],
  "sebo": ['S EH1 B OW0'],
  "sebold": ['S EH1 B OW0 L D'],
  "sebree": ['S IH0 B R IY1'],
  "sebring": ['S IY1 B R IH0 NG'],
  "sec": ['S EH1 K'],
  "secada": ['S AH0 K AA1 D AH0'],
  "secateur": ['S EY1 K AH0 T ER0'],
  "secateurs": ['S EY1 K AH0 T ER0 Z'],
  "secaucus": ['S IH0 K AO1 K AH0 S'],
  "secchia": ['S EH1 K IY0 AH0'],
  "secede": ['S IH0 S IY1 D'],
  "seceded": ['S IH0 S IY1 D IH0 D'],
  "seceding": ['S IH0 S IY1 D IH0 NG'],
  "secession": ['S IH0 S EH1 SH AH0 N'],
  "secessionist": ['S IH0 S EH1 SH AH0 N IH0 S T'],
  "secessionists": ['S IH0 S EH1 SH AH0 N IH0 S T S'],
  "sechler": ['S EH1 K L ER0'],
  "sechrest": ['S EH1 K ER0 IH0 S T'],
  "sechrist": ['S EH1 K ER0 IH0 S T', 'S IY1 K R IH2 S T'],
  "seck": ['S EH1 K'],
  "seckel": ['S EH1 K AH0 L'],
  "seckinger": ['S EH1 K IH0 NG ER0'],
  "seckler": ['S EH1 K L ER0'],
  "seckman": ['S EH1 K M AH0 N'],
  "seclude": ['S AH0 K L UW1 D'],
  "secluded": ['S IH0 K L UW1 D IH0 D'],
  "seclusion": ['S IH0 K L UW1 ZH AH0 N'],
  "seco": ['S EH1 K OW0'],
  "secom": ['S EH1 K AA0 M'],
  "secomerica": ['S EH2 K OW0 M EH1 R IH0 K AH0'],
  "second": ['S EH1 K AH0 N D'],
  "second's": ['S EH1 K AH0 N D Z'],
  "secondaries": ['S EH1 K AH0 N D EH2 R IY0 Z'],
  "secondarily": ['S EH2 K AH0 N D EH1 R AH0 L IY0'],
  "secondary": ['S EH1 K AH0 N D EH2 R IY0'],
  "seconded": ['S EH1 K AH0 N D IH0 D'],
  "secondhand": ['S EH1 K AH0 N D HH AE2 N D'],
  "secondly": ['S EH1 K AH0 N D L IY0'],
  "seconds": ['S EH1 K AH0 N D Z'],
  "secor": ['S EH1 K ER0'],
  "secord": ['S IY1 K AO0 R D'],
  "secord's": ['S IY1 K AO0 R D Z'],
  "secoy": ['S EH1 K OY0'],
  "secrecy": ['S IY1 K R AH0 S IY0'],
  "secrest": ['S EH1 K ER0 IH0 S T'],
  "secret": ['S IY1 K R AH0 T', 'S IY1 K R IH0 T'],
  "secret's": ['S IY1 K R AH0 T S'],
  "secretarial": ['S EH2 K R AH0 T EH1 R IY0 AH0 L'],
  "secretariat": ['S EH2 K R IH0 T EH1 R IY0 AH0 T'],
  "secretaries": ['S EH1 K R AH0 T EH2 R IY0 Z'],
  "secretaries'": ['S EH1 K R IH0 T EH2 R IY0 Z'],
  "secretary": ['S EH1 K R AH0 T EH2 R IY0'],
  "secretary's": ['S EH1 K R AH0 T EH2 R IY0 Z'],
  "secrete": ['S IH0 K R IY1 T'],
  "secreted": ['S AH0 K R IY1 T IH0 D'],
  "secretion": ['S AH0 K R IY1 SH AH0 N'],
  "secretions": ['S AH0 K R IY1 SH AH0 N Z'],
  "secretive": ['S IY1 K R AH0 T IH0 V'],
  "secretiveness": ['S IY1 K R AH0 T IH0 V N AH0 S'],
  "secretly": ['S IY1 K R IH0 T L IY0'],
  "secrets": ['S IY1 K R AH0 T S', 'S IY1 K R IH0 T S'],
  "secrist": ['S EH1 K ER0 IH0 S T'],
  "sect": ['S EH1 K T'],
  "sect's": ['S EH1 K T S'],
  "sectarian": ['S EH0 K T EH1 R IY0 AH0 N'],
  "sectarianism": ['S EH0 K T EH1 R IY0 AH0 N IH2 Z AH0 M'],
  "secteur": ['S EH0 K T UW1 R'],
  "section": ['S EH1 K SH AH0 N'],
  "section's": ['S EH1 K SH AH0 N Z'],
  "sectional": ['S EH1 K SH AH0 N AH0 L'],
  "sectioned": ['S EH1 K SH AH0 N D'],
  "sectioning": ['S EH1 K SH AH0 N IH0 NG'],
  "sections": ['S EH1 K SH AH0 N Z'],
  "sector": ['S EH1 K T ER0'],
  "sector's": ['S EH1 K T ER0 Z'],
  "sectoral": ['S EH1 K T ER0 AH0 L'],
  "sectors": ['S EH1 K T ER0 Z'],
  "sects": ['S EH1 K T S'],
  "secular": ['S EH1 K Y AH0 L ER0'],
  "secularism": ['S EH1 K Y AH0 L ER0 IH2 Z AH0 M'],
  "secularist": ['S EH1 K Y AH0 L ER0 IH0 S T'],
  "secularists": ['S EH1 K Y AH0 L ER0 IH0 S T S'],
  "secularized": ['S EH1 K Y AH0 L ER0 AY0 Z D'],
  "seculow": ['S EH1 K Y AH0 L OW0'],
  "secunda": ['S IH0 K AH1 N D AH0'],
  "secure": ['S IH0 K Y UH1 R'],
  "secured": ['S IH0 K Y UH1 R D'],
  "securely": ['S IH0 K Y UH1 R L IY0'],
  "secures": ['S IH0 K Y UH1 R Z'],
  "securing": ['S IH0 K Y UH1 R IH0 NG'],
  "securities": ['S IH0 K Y UH1 R AH0 T IY0 Z'],
  "securities'": ['S IH0 K Y UH1 R AH0 T IY0 Z'],
  "securitization": ['S IH0 K Y UH2 R AH0 T AH0 Z EY1 SH AH0 N'],
  "securitize": ['S IH0 K Y UH1 R AH0 T AY2 Z'],
  "securitized": ['S IH0 K Y UH1 R AH0 T AY2 Z D'],
  "securitizing": ['S IH0 K Y UH1 R AH0 T AY2 Z IH0 NG'],
  "security": ['S IH0 K Y UH1 R AH0 T IY0'],
  "security's": ['S IH0 K Y UH1 R AH0 T IY0 Z'],
  "seda": ['S EY1 D AH0'],
  "sedalia": ['S AH0 D EY1 L Y AH0'],
  "sedam": ['S EH1 D AH0 M'],
  "sedan": ['S AH0 D AE1 N'],
  "sedano": ['S EY0 D AA1 N OW0'],
  "sedans": ['S IH0 D AE1 N Z'],
  "sedate": ['S IH0 D EY1 T'],
  "sedated": ['S IH0 D EY1 T IH0 D'],
  "sedately": ['S IH0 D EY1 T L IY0'],
  "sedates": ['S IH0 D EY1 T S'],
  "sedating": ['S AH0 D EY1 T IH0 NG'],
  "sedation": ['S AH0 D EY1 SH AH0 N'],
  "sedative": ['S EH1 D AH0 T IH0 V'],
  "sedberry": ['S EH1 D B EH2 R IY0'],
  "sedco": ['S EH1 D K OW0'],
  "seddon": ['S EH1 D AH0 N'],
  "sedentary": ['S EH1 D AH0 N T EH2 R IY0'],
  "seder": ['S EY1 D ER0'],
  "sedge": ['S EH1 JH'],
  "sedgewick": ['S EH1 JH W IH0 K'],
  "sedgley": ['S EH1 JH L IY0'],
  "sedgwick": ['S EH1 JH W IH0 K'],
  "sedillo": ['S EH0 D IH1 L OW0'],
  "sediment": ['S EH1 D AH0 M AH0 N T'],
  "sedimentary": ['S EH2 D AH0 M EH1 N T ER0 IY0'],
  "sedimentation": ['S EH2 D AH0 M AH0 N T EY1 SH AH0 N'],
  "sediments": ['S EH1 D AH0 M AH0 N T S'],
  "sedita": ['S EH0 D IY1 T AH0'],
  "sedition": ['S IH0 D IH1 SH AH0 N'],
  "seditious": ['S IH0 D IH1 SH AH0 S'],
  "sedivy": ['S EH1 D IH0 V IY0'],
  "sedlacek": ['S EH1 D L AH0 S IH0 K'],
  "sedlack": ['S EH1 D L AH0 K'],
  "sedlak": ['S EH1 D L AH0 K'],
  "sedlar": ['S EH1 D L ER0'],
  "sedler": ['S EH1 D L ER0'],
  "sedlock": ['S EH1 D L AH0 K'],
  "sedona": ['S AH0 D OW1 N AH0'],
  "sedor": ['S EH0 D AO1 R'],
  "sedore": ['S EH0 D AO1 R IY0'],
  "seduce": ['S IH0 D UW1 S'],
  "seduced": ['S IH0 D UW1 S T'],
  "seducer": ['S IH0 D UW1 S ER0'],
  "seducing": ['S IH0 D UW1 S IH0 NG'],
  "seduction": ['S IH0 D AH1 K SH AH0 N'],
  "seductive": ['S IH0 D AH1 K T IH0 V'],
  "seductively": ['S AH0 D AH1 K T IH0 V L IY0'],
  "sedum": ['S EH1 D AH0 M'],
  "sedums": ['S EH1 D AH0 M Z'],
  "sedwick": ['S EH1 D W IH0 K'],
  "see": ['S IY1'],
  "see-kiong": ['S IY1 K Y AO1 NG'],
  "seebach": ['S IY1 B AA2 K'],
  "seebeck": ['S IY1 B EH2 K'],
  "seeber": ['S IY1 B ER0'],
  "seeberger": ['S IY1 B ER0 G ER0'],
  "seebold": ['S IY1 B OW2 L D'],
  "seeburger": ['S IY1 B ER0 G ER0'],
  "seed": ['S IY1 D'],
  "seed's": ['S IY1 D Z'],
  "seedeater": ['S IY1 D IY2 T ER0'],
  "seedeaters": ['S IY1 D IY2 T ER0 Z'],
  "seeded": ['S IY1 D IH0 D'],
  "seeding": ['S IY1 D IH0 NG'],
  "seedling": ['S IY1 D L IH0 NG'],
  "seedlings": ['S IY1 D L IH0 NG Z'],
  "seedorf": ['S IY1 D AO0 R F'],
  "seedpod": ['S IY1 D P AA2 D'],
  "seeds": ['S IY1 D Z'],
  "seedsman": ['S IY1 D Z M AH0 N'],
  "seedy": ['S IY1 D IY0'],
  "seefeld": ['S IY1 F EH2 L D'],
  "seefeldt": ['S IY1 F IH0 L T'],
  "seegars": ['S IY1 G ER0 Z'],
  "seeger": ['S IY1 G ER0'],
  "seegers": ['S IY1 G ER0 Z'],
  "seegert": ['S IY1 G ER0 T'],
  "seegmiller": ['S IY1 G M IH0 L ER0'],
  "seehafer": ['S IY1 HH AH0 F ER0'],
  "seehusen": ['S IY1 HH UW0 S AH0 N'],
  "seeing": ['S IY1 IH0 NG'],
  "seek": ['S IY1 K'],
  "seekamp": ['S IY1 K AE2 M P'],
  "seeker": ['S IY1 K ER0'],
  "seeker's": ['S IY1 K ER0 Z'],
  "seekers": ['S IY1 K ER0 Z'],
  "seeking": ['S IY1 K IH0 NG'],
  "seekins": ['S IY1 K IH0 N Z'],
  "seekonk": ['S IY1 K AA0 NG K'],
  "seeks": ['S IY1 K S'],
  "seel": ['S IY1 L'],
  "seelbach": ['S IY1 L B AA2 K'],
  "seelert": ['S IY1 L ER0 T'],
  "seeley": ['S IY1 L IY0'],
  "seelig": ['S IY1 L IH0 G'],
  "seelig's": ['S IY1 L IH0 G Z'],
  "seeling": ['S IY1 L IH0 NG'],
  "seelinger": ['S IY1 L IH0 NG ER0'],
  "seelman": ['S IY1 L M AH0 N'],
  "seely": ['S IY1 L IY0'],
  "seelye": ['S IY1 L AY2'],
  "seem": ['S IY1 M'],
  "seema": ['S IY1 M AA0'],
  "seemala": ['S IY1 M AH0 L AH0'],
  "seeman": ['S IY1 M AH0 N'],
  "seemann": ['S IY1 M AH0 N'],
  "seemed": ['S IY1 M D'],
  "seeming": ['S IY1 M IH0 NG'],
  "seemingly": ['S IY1 M IH0 NG L IY0'],
  "seems": ['S IY1 M Z'],
  "seen": ['S IY1 N'],
  "seep": ['S IY1 P'],
  "seepage": ['S IY1 P IH0 JH'],
  "seeped": ['S IY1 P T'],
  "seeping": ['S IY1 P IH0 NG'],
  "seeps": ['S IY1 P S'],
  "seer": ['S IY1 R'],
  "seers": ['S IY1 R Z'],
  "seery": ['S IY1 R IY0'],
  "sees": ['S IY1 Z'],
  "seesaw": ['S IY1 S AO2'],
  "seesawed": ['S IY1 S AO2 D'],
  "seesawing": ['S IY1 S AO2 IH0 NG'],
  "seese": ['S IY1 Z'],
  "seethe": ['S IY1 DH'],
  "seething": ['S IY1 DH IH0 NG'],
  "seetin": ['S IY1 T IH0 N'],
  "seeton": ['S IY1 T AH0 N'],
  "seever": ['S IY1 V ER0'],
  "seevers": ['S IY1 V ER0 Z'],
  "seewald": ['S IY1 W AO2 L D'],
  "sefcik": ['S EH1 F S IH0 K'],
  "seff": ['S EH1 F'],
  "sefton": ['S EH1 F T AH0 N'],
  "sega": ['S IY1 G AH0', 'S EY1 G AH0'],
  "sega's": ['S EY1 G AH0 Z'],
  "segal": ['S IY1 G AH0 L'],
  "segalas": ['S EH1 G AH0 L AH0 S'],
  "segall": ['S EY0 G AA1 L'],
  "segar": ['S IY1 G ER0'],
  "segarra": ['S EH0 G AA1 R AH0'],
  "segars": ['S EH1 G ER0 Z'],
  "sege": ['S EH1 JH'],
  "segel": ['S IY1 G AH0 L'],
  "seger": ['S IY1 G ER0'],
  "segers": ['S IY1 G ER0 Z'],
  "segerstrom": ['S EH1 G ER0 S T R AH0 M'],
  "segler": ['S EH1 G L ER0'],
  "segment": ['S EH1 G M AH0 N T', 'S EH2 G M EH1 N T'],
  "segment's": ['S EH1 G M AH0 N T S'],
  "segmentation": ['S EH2 G M AH0 N T EY1 SH AH0 N'],
  "segmented": ['S EH1 G M EH2 N T IH0 D', 'S EH2 G M EH1 N T IH0 D', 'S EH1 G M EH2 N IH0 D', 'S EH2 G M EH1 N IH0 D'],
  "segmenting": ['S EH2 G M EH1 N T IH0 NG'],
  "segments": ['S EH1 G M AH0 N T S', 'S EH2 G M EH1 N T S'],
  "segner": ['S EH1 G N ER0'],
  "sego": ['S IY1 G OW2'],
  "segovia": ['S EH0 G OW1 V IY0 AH0'],
  "segraves": ['S EY0 G R AA1 V EH0 S'],
  "segregate": ['S EH1 G R AH0 G EY2 T'],
  "segregated": ['S EH1 G R AH0 G EY2 T IH0 D'],
  "segregating": ['S EH1 G R IH0 G EY2 T IH0 NG'],
  "segregation": ['S EH2 G R AH0 G EY1 SH AH0 N'],
  "segregationist": ['S EH2 G R AH0 G EY1 SH AH0 N IH0 S T'],
  "segregationists": ['S EH2 G R AH0 G EY1 SH AH0 N IH0 S T S'],
  "segrest": ['S EH1 G ER0 IH0 S T', 'S IY1 G R EH2 S T'],
  "segreto": ['S EH0 G R EH1 T OW0'],
  "segue": ['S EH1 G', 'S EH1 G W EY0'],
  "seguin": ['S AH0 G IY1 N', 'S IY1 G AH0 N'],
  "segundo": ['S EH2 G UH1 N D OW2'],
  "segur": ['S EY0 G UH1 R'],
  "segura": ['S EY0 G UH1 R AH0'],
  "seguros": ['S EY2 G Y ER1 OW0 Z'],
  "segway": ['S EH1 G W EY2'],
  "segways": ['S EH1 G W EY2 Z'],
  "seher": ['S EH1 HH ER0'],
  "sehnert": ['S EH1 N ER0 T'],
  "sehorn": ['S EH1 HH ER0 N'],
  "sehr": ['S EH1 R'],
  "seib": ['S IY1 B'],
  "seibel": ['S AY1 B AH0 L'],
  "seiber": ['S AY1 B ER0'],
  "seiberlich": ['S AY1 B ER0 L IH0 K'],
  "seiberling": ['S AY1 B ER0 L IH0 NG'],
  "seibert": ['S AY1 B ER0 T'],
  "seibold": ['S AY1 B OW2 L D'],
  "seibu": ['S AY1 B UW0'],
  "seid": ['S AY1 D'],
  "seide": ['S AY1 D'],
  "seidel": ['S AY1 D AH0 L'],
  "seidell": ['S AY1 D AH0 L'],
  "seidelman": ['S AY1 D AH0 L M AH0 N'],
  "seiden": ['S AY1 D AH0 N'],
  "seidenberg": ['S AY1 D AH0 N B ER0 G'],
  "seider": ['S AY1 D ER0'],
  "seiders": ['S AY1 D ER0 Z'],
  "seidl": ['S AY1 D AH0 L'],
  "seidler": ['S AY1 D L ER0'],
  "seidman": ['S AY1 D M AH0 N'],
  "seidman's": ['S AY1 D M AH0 N Z'],
  "seidner": ['S AY1 D N ER0'],
  "seier": ['S AY1 ER0'],
  "seif": ['S IY1 F'],
  "seife": ['S IY1 F'],
  "seifer": ['S AY1 F ER0'],
  "seifert": ['S AY1 F ER0 T'],
  "seiffert": ['S AY1 F ER0 T'],
  "seifried": ['S AY1 F ER0 IY0 D'],
  "seigal": ['S IY1 G AH0 L'],
  "seige": ['S IY1 JH'],
  "seigel": ['S AY1 G AH0 L', 'S IY1 G AH0 L'],
  "seiger": ['S AY1 G ER0'],
  "seigle": ['S IY1 G AH0 L'],
  "seigler": ['S AY1 G AH0 L ER0', 'S IY1 G AH0 L ER0', 'S IY1 G L ER0'],
  "seigniorage": ['S IY2 G N IY1 ER0 IH0 JH'],
  "seiji": ['S EY1 JH IY0'],
  "seiki": ['S EY1 K IY0'],
  "seiko": ['S EY1 K OW0'],
  "seil": ['S AY1 L'],
  "seiler": ['S AY1 L ER0'],
  "seils": ['S AY1 L Z'],
  "seim": ['S AY1 M'],
  "sein": ['S AY1 N'],
  "seine": ['S EY1 N'],
  "seinfeld": ['S AY1 N F EH0 L D'],
  "seinfeld's": ['S AY1 N F EH0 L D Z'],
  "seip": ['S IY1 P'],
  "seipel": ['S AY1 P AH0 L'],
  "seiple": ['S IY1 P AH0 L'],
  "seipp": ['S IY1 P'],
  "seis": ['S IY1 S'],
  "seiser": ['S AY1 S ER0'],
  "seismic": ['S AY1 Z M IH0 K'],
  "seismological": ['S AY2 Z M AH0 L AO1 JH AH0 K AH0 L'],
  "seismologist": ['S AY2 Z M AA1 L AH0 JH AH0 S T'],
  "seismologists": ['S AY2 Z M AA1 L AH0 JH AH0 S T S'],
  "seismology": ['S AY2 Z M AA1 L AH0 JH IY0'],
  "seita": ['S EY1 T AH0'],
  "seitel": ['S IY1 T EH2 L'],
  "seiter": ['S AY1 T ER0'],
  "seith": ['S IY1 TH'],
  "seither": ['S AY1 DH ER0'],
  "seitman": ['S IY1 T M AH0 N'],
  "seitter": ['S AY1 T ER0'],
  "seitz": ['S AY1 T S'],
  "seitzinger": ['S AY1 T Z IH0 NG ER0'],
  "seivert": ['S AY1 V ER0 T'],
  "seiwert": ['S AY1 W ER0 T'],
  "seix": ['S IY1 K S'],
  "seixas": ['S IY1 K S AH0 S'],
  "seiyaku": ['S EY2 Y AA1 K UW2'],
  "seiyu": ['S IY1 Y UW0'],
  "seize": ['S IY1 Z'],
  "seized": ['S IY1 Z D'],
  "seizes": ['S IY1 Z IH0 Z'],
  "seizing": ['S IY1 Z IH0 NG'],
  "seizure": ['S IY1 ZH ER0'],
  "seizures": ['S IY1 ZH ER0 Z'],
  "sejm": ['S EY1 M'],
  "sekerak": ['S EH1 K ER0 AH0 K'],
  "seki": ['S EY1 K IY0'],
  "sekisui": ['S EY2 K IH0 S UW1 IY0'],
  "seko": ['S EH1 K OW0'],
  "seko's": ['S EH1 K OW0 Z'],
  "sekula": ['S IH0 K UW1 L AH0'],
  "sekulow": ['S EH1 K UW0 L OW0'],
  "sel": ['S EH1 L'],
  "sela": ['S EH1 L AH0'],
  "selander": ['S EH1 L AH0 N D ER0'],
  "selas": ['S EH1 L AH0 S'],
  "selassie": ['S AH0 L AE1 S IY0'],
  "selbe": ['S EH1 L B'],
  "selberg": ['S EH1 L B ER0 G'],
  "selby": ['S EH1 L B IY0'],
  "selchow": ['S EH1 L CH AW0'],
  "selda": ['S EH1 L D AH0'],
  "seldane": ['S EH1 L D EY2 N'],
  "selden": ['S EH1 L D AH0 N'],
  "selders": ['S EH1 L D ER0 Z'],
  "seldin": ['S EH1 L D IH0 N'],
  "seldom": ['S EH1 L D AH0 M'],
  "seldon": ['S EH1 L D AH0 N'],
  "select": ['S AH0 L EH1 K T'],
  "selected": ['S AH0 L EH1 K T IH0 D'],
  "selecting": ['S AH0 L EH1 K T IH0 NG'],
  "selection": ['S AH0 L EH1 K SH AH0 N'],
  "selections": ['S AH0 L EH1 K SH AH0 N Z'],
  "selective": ['S AH0 L EH1 K T IH0 V'],
  "selectively": ['S AH0 L EH1 K T IH0 V L IY0'],
  "selectivity": ['S IH0 L EH2 K T IH1 V AH0 T IY0'],
  "selectnet": ['S AH0 L EH1 K T N EH2 T'],
  "selects": ['S AH0 L EH1 K T S'],
  "selena": ['S AH0 L IY1 N AH0'],
  "selena's": ['S AH0 L IY1 N AH0 Z'],
  "selene": ['S AH0 L IY1 N'],
  "selenite": ['S EH1 L IH0 N AY2 T'],
  "selenium": ['S AH0 L IY1 N IY0 AH0 M'],
  "seles": ['S EH1 L EH0 S'],
  "seles'": ['S EH1 L EH0 S'],
  "seley": ['S IY1 L IY0'],
  "self": ['S EH1 L F'],
  "self's": ['S EH1 L F S'],
  "self-aggrandizement": ['S EH1 L F AE1 G R AH0 N D AY2 Z M AH0 N T'],
  "self-aggrandizing": ['S EH1 L F AH0 G R AE1 N D AY2 Z IH0 NG'],
  "self-centered": ['S EH1 L F S EH1 N T ER0 D'],
  "self-confidence": ['S EH2 L F K AA1 N F AH0 D AH0 N S'],
  "self-confident": ['S EH2 L F K AA1 N F AH0 D AH0 N T'],
  "self-congratulation": ['S EH2 L F K AH0 N G R AE2 CH AH0 L EY1 SH AH0 N'],
  "self-conscious": ['S EH2 L F K AA1 N SH AH0 S'],
  "self-consciousness": ['S EH2 L F K AA1 N SH AH0 S N AH0 S'],
  "self-consistent": ['S EH2 L F K AH0 N S IH1 S T AH0 N T'],
  "self-contained": ['S EH1 L F K AH0 N T EY1 N D'],
  "self-control": ['S EH2 L F K AH0 N T R OW1 L'],
  "self-deceiving": ['S EH2 L F D IY0 S IY1 V IH0 NG'],
  "self-deception": ['S EH2 L F D AH0 S EH1 P SH AH0 N'],
  "self-defence": ['S EH2 L F D IH0 F EH1 N S'],
  "self-defense": ['S EH2 L F D IH0 F EH1 N S'],
  "self-deliverance": ['S EH2 L F D IH0 L IH1 V ER0 AH0 N S'],
  "self-deprecating": ['S EH2 L F D EH1 P R AH0 K EY2 T IH0 NG'],
  "self-destruct": ['S EH1 L F D IH0 S T R AH1 K T'],
  "self-destructing": ['S EH1 L F D IH0 S T R AH1 K T IH0 NG'],
  "self-destruction": ['S EH1 L F D IH0 S T R AH1 K T S'],
  "self-destructs": ['S EH1 L F D IH0 S T R AH1 K T S'],
  "self-determination": ['S EH1 L F D IH0 T ER2 M AH0 N EY1 SH AH0 N'],
  "self-dormant": ['S EH1 L F D AO1 R M AH0 N T'],
  "self-energizing": ['S EH1 L F EH1 N ER0 JH AY2 Z IH0 NG'],
  "self-enrichment": ['S EH2 L F AH0 N R IH1 CH M AH0 N T'],
  "self-expression": ['S EH2 L F IH0 K S P R EH1 SH AH0 N'],
  "self-expressions": ['S EH2 L F IH0 K S P R EH1 SH AH0 N Z'],
  "self-fertilizing": ['S EH1 L F F ER1 T AH0 L AY2 Z IH0 NG'],
  "self-fruitful": ['S EH1 L F F R UW1 T F AH0 L'],
  "self-govern": ['S EH1 L F G AH1 V ER0 N'],
  "self-governing": ['S EH1 L F G AH1 V ER0 N IH0 NG'],
  "self-government": ['S EH1 L F G AH1 V ER0 N M AH0 N T'],
  "self-help": ['S EH1 L F HH EH1 L P'],
  "self-improvement": ['S EH1 L F IH0 M P R UW1 V M AH0 N T'],
  "self-interest": ['S EH1 L F IH1 N T R AH0 S T'],
  "self-organisation": ['S EH2 L F AO2 R G AH0 N IH0 Z EY1 SH AH0 N'],
  "self-organising": ['S EH2 L F AO2 R G AH0 N IH0 Z IH0 NG'],
  "self-organization": ['S EH1 L F AO2 R G AH0 N AH0 Z EY1 SH AH0 N'],
  "self-organizing": ['S EH1 L F AO1 R G AH0 N AY2 Z IH0 NG'],
  "self-perpetuate": ['S EH2 L F P ER0 P EH1 CH UW0 EY2 T'],
  "self-perpetuating": ['S EH2 L F P ER0 P EH1 CH UW0 EY2 T IH0 NG'],
  "self-perpetuation": ['S EH2 L F P ER0 P EH2 CH UW0 EY1 SH AH0 N'],
  "self-pollinate": ['S EH2 L F P AA1 L AH0 N EY2 T'],
  "self-portrait": ['S EH2 L F P AO1 R T R AH0 T'],
  "self-professed": ['S EH2 L F P R AH0 F EH1 S T'],
  "self-replicate": ['S EH2 L F R EH1 P L IH0 K EY2 T'],
  "self-replicates": ['S EH2 L F R EH1 P L IH0 K EY2 T S'],
  "self-replicating": ['S EH2 L F R EH1 P L IH0 K EY2 T IH0 NG'],
  "self-replication": ['S EH2 L F R EH2 P L IH0 K EY1 SH AH0 N'],
  "self-respect": ['S EH2 L F R IH0 S P EH1 K T'],
  "self-respecting": ['S EH2 L F R IH0 S P EH1 K T IH0 NG'],
  "self-righteous": ['S EH2 L F R AY1 CH AH0 S'],
  "self-subsistence": ['S EH2 L F S AH0 B S IH1 S T AH0 N S'],
  "self-sufficience": ['S EH2 L F S AH0 F IH1 SH AH0 N S'],
  "self-sufficiency": ['S EH2 L F S AH0 F IH1 SH AH0 N S IY2'],
  "self-sufficient": ['S EH2 L F S AH0 F IH1 SH AH0 N T', 'S EH2 L F S AH0 F IH1 SH IH0 N T'],
  "self-torment": ['S EH2 L F T AO1 R M EH2 N T'],
  "self-torments": ['S EH2 L F T AO1 R M EH2 N T S'],
  "selfie": ['S EH1 L F IY2'],
  "selfies": ['S EH1 L F IY2 Z'],
  "selfish": ['S EH1 L F IH0 SH'],
  "selfishness": ['S EH1 L F IH0 SH N AH0 S'],
  "selfless": ['S EH1 L F L AH0 S'],
  "selflessness": ['S EH1 L F L AH0 S N IH0 S'],
  "selfridge": ['S EH1 L F R IH0 JH'],
  "selfs": ['S EH1 L F S'],
  "selia": ['S EH1 L IY0 AH0'],
  "selie": ['S EH1 L IY0'],
  "selies": ['S EH1 L IY0 Z'],
  "selies'": ['S EH1 L IY0 Z'],
  "selig": ['S EH1 L IH0 G'],
  "seliga": ['S EH1 L IH0 G AH0'],
  "seliger": ['S EH1 L IH0 G ER0'],
  "seligman": ['S EH1 L IH0 G M AH0 N'],
  "seligmann": ['S EH1 L IH0 G M AH0 N'],
  "seligson": ['S EH1 L IH0 G S AH0 N'],
  "selikoff": ['S EH1 L IH0 K AO2 F'],
  "selin": ['S EH1 L IH0 N'],
  "selina": ['S AH0 L IY1 N AH0'],
  "selinas": ['S AH0 L IY1 N AH0 Z', 'S AH0 L IY1 N AH0 S'],
  "selinas's": ['S AH0 L IY1 N AH0 S IH0 Z'],
  "selinda": ['S EH0 L IY1 N D AH0'],
  "selinger": ['S EH1 L IH0 NG ER0'],
  "seljuk": ['S EH1 L JH AH0 K'],
  "selk": ['S EH1 L K'],
  "selke": ['S EH1 L K'],
  "selkin": ['S EH1 L K IH0 N'],
  "selkirk": ['S EH1 L K ER0 K'],
  "selkirk's": ['S EH1 L K ER0 K S'],
  "sell": ['S EH1 L'],
  "sell-out": ['S EH1 L AW2 T'],
  "sella": ['S EH1 L AH0'],
  "selland": ['S EH1 L AH0 N D'],
  "sellards": ['S EH1 L ER0 D Z'],
  "sellars": ['S EH1 L ER0 Z'],
  "sellars's": ['S EH1 L ER0 Z IH0 Z'],
  "selle": ['S EH1 L'],
  "selleck": ['S EH1 L IH0 K'],
  "sellen": ['S EH1 L AH0 N'],
  "seller": ['S EH1 L ER0'],
  "seller's": ['S EH1 L ER0 Z'],
  "sellers": ['S EH1 L ER0 Z'],
  "sellers'": ['S EH1 L ER0 Z'],
  "selley": ['S EH1 L IY0'],
  "sellick": ['S EH1 L IH0 K'],
  "sellier": ['S EH1 L Y ER0'],
  "sellin": ['S EH1 L IH0 N'],
  "selling": ['S EH1 L IH0 NG'],
  "sellinger": ['S EH1 L IH0 NG ER0'],
  "sellings": ['S EH1 L IH0 NG Z'],
  "sellman": ['S EH1 L M AH0 N'],
  "sellmeyer": ['S EH1 L M AY0 ER0'],
  "sellner": ['S EH1 L N ER0'],
  "selloff": ['S EH1 L AO2 F'],
  "selloffs": ['S EH1 L AO2 F S'],
  "sellon": ['S EH1 L AH0 N'],
  "sellout": ['S EH1 L AW2 T'],
  "sellouts": ['S EH1 L AW2 T S'],
  "sells": ['S EH1 L Z'],
  "selma": ['S EH1 L M AH0'],
  "selman": ['S EH1 L M AH0 N'],
  "selmer": ['S EH1 L M ER0'],
  "selmon": ['S EH1 L M AH0 N'],
  "selner": ['S EH1 L N ER0'],
  "selover": ['S EH1 L AH0 V ER0'],
  "selowsky": ['S EH0 L AW1 S K IY0'],
  "selph": ['S EH1 L F'],
  "selsor": ['S EH1 L S ER0'],
  "seltz": ['S EH1 L T S'],
  "seltzer": ['S EH1 L T S ER0'],
  "selva": ['S EY1 L V AH0'],
  "selvage": ['S EH1 L V IH0 JH'],
  "selvaggio": ['S EH0 L V AA1 JH IY0 OW0'],
  "selves": ['S EH1 L V Z'],
  "selvey": ['S EH1 L V IY0'],
  "selvidge": ['S EH1 L V IH0 JH'],
  "selvig": ['S EH1 L V IH0 G'],
  "selway": ['S EH1 L W EY2'],
  "selwin": ['S EH1 L W IH0 N'],
  "selwitz": ['S EH1 L W IH0 T S'],
  "selwyn": ['S EH1 L W IH0 N'],
  "selz": ['S EH1 L Z'],
  "selzer": ['S EH1 L Z ER0'],
  "selzer's": ['S EH1 L Z ER0 Z'],
  "sema": ['S IY1 M AH0'],
  "seman": ['S IY1 M AH0 N'],
  "semans": ['S IY1 M AH0 N Z'],
  "semantic": ['S IH0 M AE1 N T IH0 K'],
  "semantics": ['S IH0 M AE1 N T IH0 K S'],
  "sematech": ['S EH1 M AH0 T EH2 K'],
  "sember": ['S EH1 M B ER0'],
  "semblance": ['S EH1 M B L AH0 N S'],
  "semegran": ['S EH1 M AH0 G R AH0 N'],
  "semel": ['S EH1 M AH0 L'],
  "semele": ['S EH1 M AH0 L IY2'],
  "semen": ['S IY1 M AH0 N'],
  "semenza": ['S EH0 M EH1 N Z AH0'],
  "semerad": ['S EH1 M ER0 AE0 D'],
  "semester": ['S AH0 M EH1 S T ER0'],
  "semesters": ['S AH0 M EH1 S T ER0 Z'],
  "semi": ['S EH1 M IY0', 'S EH1 M AY0'],
  "semi-colon": ['S EH1 M IY0 K OW1 L AH0 N', 'S EH1 M AH0 K OW1 L AH0 N'],
  "semi-height": ['S EH1 M IY0 HH AY1 T', 'S EH1 M IH0 HH AY1 T', 'S EH1 M AY0 HH AY1 T'],
  "semi-heights": ['S EH1 M IY0 HH AY1 T S', 'S EH1 M AY0 HH AY1 T S', 'S EH1 M IH0 HH AY1 T S'],
  "semi-human": ['S EH2 M IY0 HH Y UW1 M AH0 N'],
  "semiannual": ['S EH2 M IY0 AE1 N Y AH0 W AH0 L', 'S EH2 M AY0 AE1 N Y AH0 W AH0 L', 'S EH2 M AH0 AE1 N Y AH0 W AH0 L'],
  "semiannually": ['S EH2 M IY0 AE1 N UW0 AH0 L IY0', 'S EH2 M AY0 AE1 N UW0 AH0 L IY0', 'S EH2 M IY0 AE1 N UW0 L IY0', 'S EH2 M AY0 AE1 N UW0 L IY0'],
  "semiautomatic": ['S EH2 M IY0 AO2 T AH0 M AE1 T IH0 K', 'S EH2 M AY0 AO2 T AH0 M AE1 T IH0 K', 'S EH2 M IH0 AO2 T AH0 M AE1 T IH0 K'],
  "semicircular": ['S EH2 M IY0 S ER1 K Y AH0 L ER0', 'S EH2 M AY0 S ER1 K Y AH0 L ER0', 'S EH2 M AH0 S ER1 K Y AH0 L ER0'],
  "semiclad": ['S EH1 M IY0 K L AE2 D', 'S EH1 M AY0 K L AE2 D', 'S EH1 M AH0 K L AE2 D'],
  "semiclassical": ['S EH2 M IY0 K L AE1 S IH0 K AH0 L', 'S EH2 M IH0 K L AE1 S IH0 K AH0 L', 'S EH2 M AY0 K L AE1 S IH0 K AH0 L'],
  "semicon": ['S EH1 M IH0 K AA2 N'],
  "semiconductor": ['S EH2 M IY0 K AH0 N D AH1 K T ER0', 'S EH2 M IH0 K AH0 N D AH1 K T ER0', 'S EH2 M AY0 K AH0 N D AH1 K T ER0'],
  "semiconductor's": ['S EH2 M IY0 K AH0 N D AH1 K T ER0 Z', 'S EH2 M IH0 K AH0 N D AH1 K T ER0 Z', 'S EH2 M AY0 K AH0 N D AH1 K T ER0 Z'],
  "semiconductors": ['S EH2 M IY0 K AH0 N D AH1 K T ER0 Z', 'S EH2 M IH0 K AH0 N D AH1 K T ER0 Z', 'S EH2 M AY0 K AH0 N D AH1 K T ER0 Z'],
  "semicylindrical": ['S EH2 M IY0 S AH0 L IH1 N D R IH0 K AH0 L', 'S EH2 M IH0 S AH0 L IH1 N D R IH0 K AH0 L', 'S EH2 M AY0 S AH0 L IH1 N D R IH0 K AH0 L'],
  "semidry": ['S EH2 M IY0 D R AY1', 'S EH2 M IH0 D R AY1', 'S EH2 M AY0 D R AY1'],
  "semidrying": ['S EH2 M IY0 D R AY1 IH0 NG', 'S EH2 M IH0 D R AY1 IH0 NG', 'S EH2 M AY0 D R AY1 IH0 NG'],
  "semien": ['S EH1 M IY0 N'],
  "semifinal": ['S EH2 M IY0 F AY1 N AH0 L', 'S EH2 M IH0 F AY1 N AH0 L', 'S EH2 M AY0 F AY1 N AH0 L'],
  "semifinalist": ['S EH2 M IY0 F AY1 N AH0 L IH0 S T', 'S EH2 M IH0 F AY1 N AH0 L IH0 S T', 'S EH2 M AY0 F AY1 N AH0 L IH0 S T'],
  "semifinalists": ['S EH2 M IY0 F AY1 N AH0 L IH0 S T S', 'S EH2 M IY0 F AY1 N AH0 L IH0 S', 'S EH2 M AY0 F AY1 N AH0 L IH0 S T S', 'S EH2 M AY0 F AY1 N AH0 L IH0 S'],
  "semifinals": ['S EH2 M IY0 F AY1 N AH0 L Z', 'S EH2 M IH0 F AY1 N AH0 L Z', 'S EH2 M AY0 F AY1 N AH0 L Z'],
  "semifinish": ['S EH2 M IY0 F IH1 N IH0 SH', 'S EH2 M AY0 F IH1 N IH0 SH', 'S EH2 M IH0 F IH1 N IH0 SH'],
  "semifinished": ['S EH2 M IY0 F IH1 N IH0 SH T', 'S EH2 M AY0 F IH1 N IH0 SH T', 'S EH2 M IH0 F IH1 N IH0 SH T'],
  "semigloss": ['S EH2 M IY0 G L AA1 S', 'S EH2 M IH0 G L AA1 S'],
  "semilegendary": ['S EH2 M IY0 L EH1 JH AH0 N D EH2 R IY0', 'S EH2 M IH0 L EH1 JH AH0 N D EH2 R IY0'],
  "seminal": ['S EH1 M AH0 N AH0 L'],
  "seminar": ['S EH1 M AH0 N AA2 R'],
  "seminara": ['S EH2 M IH0 N AA1 R AH0'],
  "seminarian": ['S EH2 M AH0 N EH1 R IY0 AH0 N'],
  "seminarians": ['S EH2 M AH0 N EH1 R IY0 AH0 N Z'],
  "seminaries": ['S EH1 M AH0 N EH2 R IY0 Z'],
  "seminario": ['S EH2 M IH0 N EH1 R IY0 OW0'],
  "seminars": ['S EH1 M AH0 N AA2 R Z'],
  "seminary": ['S EH1 M AH0 N EH2 R IY0'],
  "seminole": ['S EH1 M IH0 N OW2 L'],
  "seminoles": ['S EH1 M IH0 N OW2 L Z'],
  "semionenkov": ['S EH2 M IY0 OW0 N EH1 NG K AA0 V'],
  "semiotic": ['S EH2 M IY0 AA1 T IH0 K'],
  "semiotics": ['S EH2 M IY0 AA1 T IH0 K S'],
  "semipermanent": ['S EH2 M IY0 P ER1 M AH0 N AH0 N T', 'S EH2 M IH0 P ER1 M AH0 N AH0 N T', 'S EH2 M AY0 P ER1 M AH0 N AH0 N T'],
  "semiprecious": ['S EH2 M IY0 P R EH1 SH AH0 S', 'S EH2 M IH0 P R EH1 SH AH0 S', 'S EH2 M AY0 P R EH1 SH AH0 S'],
  "semira": ['S EH0 M IH1 R AH0'],
  "semireligious": ['S EH2 M IY0 R IH0 L IH1 JH AH0 S', 'S EH2 M IH0 R IH0 L IH1 JH AH0 S', 'S EH2 M AY0 R IH0 L IH1 JH AH0 S'],
  "semis": ['S EH1 M AY0 Z'],
  "semisecrecy": ['S EH2 M IY0 S IY1 K R AH0 S IY0', 'S EH2 M IH0 S IY1 K R AH0 S IY0'],
  "semite": ['S EH1 M AY0 T'],
  "semitic": ['S AH0 M IH1 T IH0 K'],
  "semitism": ['S EH1 M IH0 T IH2 Z AH0 M'],
  "semitrailer": ['S EH2 M IY0 T R EY1 L ER0', 'S EH2 M IH0 T R EY1 L ER0'],
  "semitropical": ['S EH2 M IY0 T R AA1 P IH0 K AH0 L', 'S EH2 M IH0 T R AA1 P IH0 K AH0 L', 'S EH2 M AY0 T R AA1 P IH0 K AH0 L'],
  "semler": ['S EH1 M L ER0'],
  "semmel": ['S EH1 M AH0 L'],
  "semmens": ['S EH1 M AH0 N Z'],
  "semmes": ['S EH1 M Z'],
  "semmler": ['S EH1 M L ER0'],
  "semolina": ['S EH2 M AH0 L IY1 N AH0'],
  "semon": ['S EH1 M AH0 N'],
  "semones": ['S EY0 M OW1 N EH0 S'],
  "semple": ['S EH1 M P AH0 L'],
  "semrad": ['S EH1 M R AH0 D'],
  "semrau": ['S EH1 M R AW0'],
  "semrow": ['S EH1 M R OW0'],
  "semtex": ['S EH1 M T EH0 K S'],
  "sen": ['S EH1 N'],
  "sena": ['S EH1 N AH0'],
  "senate": ['S EH1 N AH0 T', 'S EH1 N IH0 T'],
  "senate's": ['S EH1 N IH0 T S'],
  "senator": ['S EH1 N AH0 T ER0'],
  "senator's": ['S EH1 N AH0 T ER0 Z'],
  "senatore": ['S EH0 N AA0 T AO1 R IY0'],
  "senatorial": ['S EH2 N AH0 T AO1 R IY0 AH0 L'],
  "senators": ['S EH1 N AH0 T ER0 Z'],
  "senators'": ['S EH1 N AH0 T ER0 Z'],
  "send": ['S EH1 N D'],
  "sendak": ['S EH1 N D AE0 K'],
  "sendawal": ['S EH0 N D AA1 W AH0 L'],
  "sendelbach": ['S EH1 N D IH0 L B AA0 K'],
  "sender": ['S EH1 N D ER0'],
  "sendero": ['S EH0 N D EH1 R OW0'],
  "senders": ['S EH1 N D ER0 Z'],
  "sending": ['S EH1 N D IH0 NG'],
  "sendo": ['S EH1 N D OW0'],
  "sends": ['S EH1 N D Z'],
  "seneca": ['S EH1 N AH0 K AH0'],
  "senecal": ['S EH1 N IH0 K AH0 L'],
  "senechal": ['S EH1 N IH0 K AH0 L'],
  "seneff": ['S EH1 N AH0 F'],
  "senegal": ['S EH2 N AH0 G AO1 L'],
  "senegalese": ['S EH2 N AH0 G AH0 L IY1 Z'],
  "senegambia": ['S EH2 N AH0 G AE1 M B IY0 AH0'],
  "seneker": ['S EH1 N AH0 K ER0'],
  "senesac": ['S EH1 N IH0 S AE0 K'],
  "senescence": ['S AH0 N EH1 S AH0 N S'],
  "senese": ['S EH1 N IY0 Z'],
  "seney": ['S EH1 N IY0'],
  "senf": ['S EH1 N F'],
  "senff": ['S EH1 N F'],
  "senft": ['S EH1 N F T'],
  "seng": ['S EH1 NG'],
  "senger": ['S EH1 NG ER0'],
  "sengers": ['S EH1 NG ER0 Z'],
  "senile": ['S IY1 N AY2 L'],
  "senility": ['S AH0 N IH1 L AH0 T IY0'],
  "senior": ['S IY1 N Y ER0'],
  "seniority": ['S IY0 N Y AO1 R IH0 T IY0'],
  "seniornet": ['S IY1 N Y ER0 N EH2 T'],
  "seniors": ['S IY1 N Y ER0 Z'],
  "senk": ['S EH1 NG K'],
  "senkbeil": ['S EH1 NG K B AY2 L'],
  "senko": ['S EH1 NG K OW0'],
  "senn": ['S EH1 N'],
  "senna": ['S EH1 N AH0'],
  "senne": ['S EH1 N'],
  "senner": ['S EH1 N ER0'],
  "sennet": ['S EH1 N AH0 T'],
  "sennett": ['S EH1 N IH0 T'],
  "sennott": ['S EH1 N AH0 T'],
  "senor": ['S IY2 N Y AO1 R'],
  "senora": ['S IY2 N Y AO1 R AH0'],
  "sens": ['S EH1 N Z'],
  "sensabaugh": ['S EH1 N S AH0 B AO2'],
  "sensation": ['S EH0 N S EY1 SH AH0 N'],
  "sensational": ['S EH0 N S EY1 SH AH0 N AH0 L'],
  "sensationalism": ['S EH0 N S EY1 SH AH0 N AH0 L IH2 Z AH0 M'],
  "sensationalist": ['S EH0 N S EY1 SH AH0 N AH0 L IH0 S T'],
  "sensationalistic": ['S EH0 N S EY2 SH AH0 N AH0 L IH1 S T IH0 K'],
  "sensationalize": ['S EH0 N S EY2 SH AH0 N AH0 L AY1 Z'],
  "sensationalized": ['S EH0 N S EY2 SH AH0 N AH0 L AY1 Z D'],
  "sensationalizes": ['S EH0 N S EY2 SH AH0 N AH0 L AY1 Z IH0 Z'],
  "sensationalizing": ['S EH0 N S EY2 SH AH0 N AH0 L AY1 Z IH0 NG'],
  "sensations": ['S EH0 N S EY1 SH AH0 N Z'],
  "sense": ['S EH1 N S'],
  "sense-data": ['S EH1 N S D EY2 T AH0', 'S EH1 N S D AE2 T AH0'],
  "sense-datas": ['S EH1 N S D EY1 T AH0 Z', 'S EH1 N S D AE1 T AH0 Z'],
  "sense-datum": ['S EH1 N S D AE1 T AH0 M', 'S EH1 N S D EY1 T AH0 M'],
  "sensed": ['S EH1 N S T'],
  "sensei": ['S EH1 N S EY2'],
  "senseless": ['S EH1 N S L AH0 S'],
  "sensenbrenner": ['S EH1 N S AH0 N B R EH2 N ER0'],
  "senseney": ['S EH1 N S IH0 N IY0'],
  "sensenig": ['S EH1 N S IH0 N IH0 G'],
  "senses": ['S EH1 N S IH0 Z'],
  "sensibilities": ['S EH2 N S IH0 B IH1 L IH0 T IY0 Z'],
  "sensibility": ['S EH2 N S IH0 B IH1 L IH0 T IY0'],
  "sensible": ['S EH1 N S AH0 B AH0 L'],
  "sensibly": ['S EH1 N S AH0 B L IY0'],
  "sensing": ['S EH1 N S IH0 NG'],
  "sensitive": ['S EH1 N S AH0 T IH0 V', 'S EH1 N S IH0 T IH0 V'],
  "sensitively": ['S EH1 N S IH0 T IH0 V L IY0'],
  "sensitiveness": ['S EH1 N S AH0 T IH0 V N AH0 S'],
  "sensitivities": ['S EH2 N S IH0 T IH1 V IH0 T IY0 Z'],
  "sensitivity": ['S EH2 N S IH0 T IH1 V IH0 T IY0'],
  "sensitize": ['S EH1 N S AH0 T AY2 Z'],
  "sensitized": ['S EH1 N S AH0 T AY2 Z D'],
  "senske": ['S EH1 N S K'],
  "sensor": ['S EH1 N S ER0'],
  "sensor's": ['S EH1 N S ER0 Z'],
  "sensormatic": ['S EH2 N S ER0 M AE1 T IH0 K'],
  "sensors": ['S EH1 N S ER0 Z'],
  "sensory": ['S EH1 N S ER0 IY0'],
  "sensual": ['S EH1 N SH AH0 W AH0 L'],
  "sensuality": ['S EH2 N SH UW2 AE1 L AH0 T IY2'],
  "sensuous": ['S EH1 N SH AH0 W AH0 S'],
  "sent": ['S EH1 N T'],
  "sentell": ['S EH1 N T AH0 L'],
  "sentelle": ['S EH0 N T EH1 L'],
  "sentence": ['S EH1 N T AH0 N S'],
  "sentenced": ['S EH1 N T AH0 N S T'],
  "sentences": ['S EH1 N T AH0 N S AH0 Z', 'S EH1 N T AH0 N S IH0 Z'],
  "sentencing": ['S EH1 N T AH0 N S IH0 NG'],
  "senter": ['S EH1 N T ER0'],
  "senters": ['S EH1 N T ER0 Z'],
  "sentiment": ['S EH1 N T AH0 M AH0 N T', 'S EH1 N AH0 M AH0 N T'],
  "sentimental": ['S EH2 N T AH0 M EH1 N T AH0 L', 'S EH2 N AH0 M EH1 N T AH0 L', 'S EH2 N T AH0 M EH1 N AH0 L', 'S EH2 N AH0 M EH1 N AH0 L'],
  "sentimentality": ['S EH2 N T AH0 M EH0 N T AE1 L IH0 T IY0', 'S EH2 N AH0 M EH0 N T AE1 L IH0 T IY0'],
  "sentiments": ['S EH1 N T AH0 M AH0 N T S', 'S EH1 N AH0 M AH0 N T S'],
  "sentinel": ['S EH1 N T AH0 N AH0 L'],
  "sentinels": ['S EH1 N T AH0 N AH0 L Z'],
  "sentman": ['S EH1 N T M AH0 N'],
  "sentra": ['S EH1 N T R AH0'],
  "sentras": ['S EH1 N T R AH0 S'],
  "sentries": ['S EH1 N T R IY0 Z'],
  "sentry": ['S EH1 N T R IY0'],
  "senty": ['S EH1 N T IY0'],
  "sentz": ['S EH1 N T S'],
  "senzaki": ['S EY0 N Z AA1 K IY0'],
  "seo": ['S IY1 OW0', 'S EY1 OW0'],
  "seoul": ['S OW1 L'],
  "seoul's": ['S OW1 L Z'],
  "seow": ['S IY1 OW0', 'S IY1 AW0'],
  "seow's": ['S IY1 OW2 Z'],
  "sep": ['S EH1 P', 'EH1 S IY1 P IY1'],
  "separate": ['S EH1 P ER0 EY2 T', 'S EH1 P ER0 IH0 T', 'S EH1 P R AH0 T'],
  "separated": ['S EH1 P ER0 EY2 T IH0 D'],
  "separately": ['S EH1 P ER0 AH0 T L IY0', 'S EH1 P R AH0 T L IY0'],
  "separateness": ['S EH1 P ER0 AH0 T N AH0 S'],
  "separates": ['S EH1 P ER0 EY2 T S', 'S EH1 P ER0 IH0 T S'],
  "separating": ['S EH1 P ER0 EY2 T IH0 NG'],
  "separation": ['S EH2 P ER0 EY1 SH AH0 N'],
  "separations": ['S EH2 P ER0 EY1 SH AH0 N Z'],
  "separatism": ['S EH1 P ER0 AH0 T IH2 Z AH0 M'],
  "separatist": ['S EH1 P ER0 AH0 T IH0 S T'],
  "separatists": ['S EH1 P ER0 AH0 T IH0 S T S', 'S EH1 P R AH0 T IH0 S T S'],
  "separator": ['S EH1 P AH0 R EY2 T ER0'],
  "separators": ['S EH1 P AH0 R EY2 T ER0 Z'],
  "sepe": ['S IY1 P'],
  "sepeda": ['S EY0 P EY1 D AH0'],
  "sephardic": ['S AH0 F AA1 R D IH0 K'],
  "sephardim": ['S IH0 F AA1 R D IH0 M', 'S EH0 F AA2 R D IY1 M'],
  "sephlon": ['S EH1 F L AO0 N'],
  "sephlon's": ['S EH1 F L AO0 N Z'],
  "sephora": ['S EH0 F AO1 R AH0'],
  "sepia": ['S IY1 P IY0 AH0'],
  "sepich": ['S EH1 P IH0 CH'],
  "sepik": ['S EH1 P IH0 K'],
  "sepp": ['S EH1 P'],
  "seppala": ['S EH0 P AA1 L AH0'],
  "seppi": ['S EH1 P IY0'],
  "sepsis": ['S EH1 P S IH0 S'],
  "sept": ['S EH1 P T'],
  "septa": ['S EH1 P T AH0'],
  "september": ['S EH0 P T EH1 M B ER0'],
  "september's": ['S EH0 P T EH1 M B ER0 Z'],
  "septer": ['S EH1 P T ER0'],
  "septic": ['S EH1 P T IH0 K'],
  "septima": ['S EH0 P T IY1 M AH0'],
  "septimus": ['S EH1 P T IH0 M IH0 S'],
  "septuagenarian": ['S EH0 P CH UW2 AH0 JH AH0 N EH1 R IY0 AH0 N'],
  "sepulvado": ['S EY0 P UW0 L V AA1 D OW0'],
  "sepulveda": ['S EY0 P UW0 L V EY1 D AH0'],
  "sepultura": ['S EH1 P UH0 L T UH1 R UH0'],
  "sepultura's": ['S EH1 P UH0 L T UH1 R UH0 Z'],
  "sequa": ['S EH1 K W AH0'],
  "sequa's": ['S EH1 K W AH0 Z'],
  "sequeira": ['S EY0 K W EH1 R AH0'],
  "sequel": ['S IY1 K W AH0 L'],
  "sequels": ['S IY1 K W AH0 L Z'],
  "sequence": ['S IY1 K W AH0 N S'],
  "sequences": ['S IY1 K W AH0 N S AH0 Z', 'S IY1 K W AH0 N S IH0 Z'],
  "sequencing": ['S IY1 K W AH0 N S IH0 NG'],
  "sequent": ['S IY1 K W AH0 N T'],
  "sequential": ['S AH0 K W EH1 N SH AH0 L'],
  "sequentially": ['S AH0 K W EH1 N SH AH0 L IY0'],
  "sequester": ['S IH0 K W EH1 S T ER0'],
  "sequestered": ['S IH0 K W EH1 S T ER0 D'],
  "sequestering": ['S IH0 K W EH1 S T ER0 IH0 NG'],
  "sequesters": ['S IH0 K W EH1 S T ER0 Z'],
  "sequestration": ['S EH2 K W AH0 S T R EY1 SH AH0 N'],
  "sequin": ['S IY1 K W AH0 N', 'S IY1 K W IH0 N'],
  "sequined": ['S IY1 K W AH0 N D'],
  "sequins": ['S IY1 K W AH0 N Z', 'S IY1 K W IH0 N Z'],
  "sequitur": ['S EH1 K W IH0 T ER0'],
  "sequiturs": ['S EH1 K W IH0 T ER0 Z'],
  "sequoia": ['S IH0 K W OY1 AH0'],
  "sequoias": ['S IH0 K W OY1 AH0 Z'],
  "sequoyah": ['S AH0 K W OY1 AH0'],
  "sera": ['S IH1 R AH0'],
  "serafin": ['S EH1 R AH0 F IH0 N'],
  "serafina": ['S ER0 AA0 F IY1 N AH0'],
  "serafine": ['S ER0 AA0 F IY1 N IY0'],
  "serafini": ['S ER0 AA0 F IY1 N IY0'],
  "serafino": ['S ER0 AA0 F IY1 N OW0'],
  "seragut": ['S EH1 R AH0 G AH2 T'],
  "seraphim": ['S ER2 AA0 F IY1 M'],
  "seraphina": ['S ER0 AA0 F IY1 N AH0'],
  "seraphine": ['S ER0 AA0 F IY1 N IY0'],
  "seratonin": ['S ER0 AA0 T OW1 N IH0 N'],
  "seratti": ['S EH0 R AA1 T IY0'],
  "seraw": ['S ER0 AA1'],
  "serb": ['S ER1 B'],
  "serb's": ['S ER1 B Z'],
  "serbaine": ['S ER0 B EY1 N'],
  "serbia": ['S ER1 B IY0 AH0'],
  "serbia's": ['S ER1 B IY0 AH0 Z'],
  "serbian": ['S ER1 B IY0 AH0 N'],
  "serbian's": ['S ER1 B IY0 AH0 N Z'],
  "serbians": ['S ER1 B IY0 AH0 N Z'],
  "serbias": ['S ER1 B IY0 AH0 Z'],
  "serbin": ['S ER1 B IH0 N'],
  "serbo-croatia": ['S ER1 B OW0 K R OW2 EY0 SH AH0'],
  "serbo-croatian": ['S ER1 B OW0 K R OW2 EY0 SH AH0 N'],
  "serbs": ['S ER1 B Z'],
  "serbs'": ['S ER1 B Z'],
  "serbska": ['S ER1 B S K AH0'],
  "serco": ['S ER1 K OW0'],
  "serda": ['S EH1 R D AH0'],
  "sere": ['S IH1 R'],
  "serena": ['S AH0 R IY1 N AH0'],
  "serena's": ['S AH0 R IY1 N AH0 Z'],
  "serenade": ['S EH2 R AH0 N EY1 D'],
  "serenaded": ['S EH2 R AH0 N EY1 D IH0 D'],
  "serenading": ['S EH2 R AH0 N EY1 D IH0 NG'],
  "serendipitous": ['S EH2 R AH0 N D IH1 P IH0 T AH0 S'],
  "serendipity": ['S EH2 R AH0 N D IH1 P IH0 T IY0'],
  "serene": ['S ER0 IY1 N'],
  "serenely": ['S ER0 IY1 N AH0 L IY0', 'S ER0 IY1 N L IY0'],
  "serengeti": ['S EH2 R AH0 N G EH1 T IY0'],
  "serenity": ['S ER0 EH1 N AH0 T IY0'],
  "sereno": ['S EH0 R EY1 N OW0'],
  "seres": ['S IY1 R Z'],
  "serex": ['S EH1 R AH0 K S'],
  "serf": ['S ER1 F'],
  "serfass": ['S ER1 F AE2 S'],
  "serfin": ['S ER1 F IH0 N', 'S EH1 R F IH0 N'],
  "serfling": ['S ER1 F L IH0 NG'],
  "serfs": ['S ER1 F S'],
  "serge": ['S ER1 JH'],
  "sergeant": ['S AA1 R JH AH0 N T'],
  "sergeants": ['S AA1 R JH AH0 N T S'],
  "sergei": ['S ER0 G EY1', 'S EH1 R G EY2'],
  "sergent": ['S ER1 JH AH0 N T'],
  "sergey": ['S EH1 R JH IY0', 'S EH1 R G EY0'],
  "sergi": ['S EH1 R JH IY0'],
  "sergio": ['S ER1 JH Y OW2'],
  "sergius": ['S ER1 JH Y AH0 S'],
  "seria": ['S IH1 R IY0 AH0'],
  "serial": ['S IH1 R IY2 AH0 L'],
  "serialize": ['S IH1 R IY2 AH0 L AY2 Z'],
  "serialized": ['S IH1 R IY2 AH0 L AY2 Z D'],
  "serials": ['S IH1 R IY0 AH0 L Z'],
  "series": ['S IH1 R IY0 Z'],
  "series'": ['S IH1 R IY0 Z'],
  "serilda": ['S ER0 IY1 L D AH0'],
  "serino": ['S ER0 IY1 N OW0'],
  "serio": ['S IY1 R IY0 OW0'],
  "serious": ['S IH1 R IY0 AH0 S'],
  "seriously": ['S IH1 R IY0 AH0 S L IY0'],
  "seriousness": ['S IH1 R IY0 AH0 S N AH0 S'],
  "serle": ['S ER1 AH0 L'],
  "serlin": ['S ER1 L IH0 N'],
  "serling": ['S ER1 L IH0 NG'],
  "serlo": ['S EH1 R L OW0'],
  "sermersheim": ['S ER1 M ER0 S HH AY0 M'],
  "sermon": ['S ER1 M AH0 N'],
  "sermons": ['S ER1 M AH0 N Z'],
  "serna": ['S EH1 R N AH0'],
  "seroka": ['S EH0 R OW1 K AH0'],
  "serological": ['S EH0 R AH0 L AO1 JH IH0 K AH0 L'],
  "serologist": ['S EH0 R AA1 L AH0 JH IH0 S T'],
  "serology": ['S EH0 R AA1 L AH0 JH IY0'],
  "serono": ['S EH2 R AA1 N OW0'],
  "serota": ['S ER0 OW1 T AH0'],
  "serotonin": ['S ER0 AH0 T OW1 N IH0 N'],
  "serowski": ['S ER0 AW1 S K IY0'],
  "serpa": ['S EH1 R P AH0'],
  "serpas": ['S ER1 P AH0 Z'],
  "serpe": ['S ER1 P'],
  "serpent": ['S ER1 P AH0 N T'],
  "serpent's": ['S ER1 P AH0 N T S'],
  "serpentine": ['S ER1 P AH0 N T AY2 N'],
  "serpents": ['S ER1 P AH0 N T S'],
  "serpico": ['S ER0 P IY1 K OW0'],
  "serr": ['S EH1 R'],
  "serra": ['S EH1 R AH0'],
  "serra's": ['S EH1 R AH0 Z'],
  "serrano": ['S ER0 AA1 N OW0', 'S ER0 AE1 N OW0'],
  "serrano's": ['S ER0 AA1 N OW0 Z', 'S ER0 AE1 N OW0 Z'],
  "serranto": ['S ER0 AA1 N T OW0'],
  "serrao": ['S EH1 R AW0'],
  "serrate": ['S EH1 R EY2 T'],
  "serrated": ['S EH1 R EY2 T IH0 D'],
  "serrato": ['S ER0 AA1 T OW0'],
  "serratore": ['S ER0 AA0 T AO1 R IY0'],
  "serres": ['S EH1 R EH0 S'],
  "serritella": ['S EH2 R IH0 T EH1 L AH0'],
  "sertich": ['S ER1 T IH0 K'],
  "serum": ['S IH1 R AH0 M'],
  "serv": ['S ER1 V'],
  "servaas": ['S ER1 V AA0 S'],
  "servais": ['S ER0 V EY1'],
  "servam": ['S ER1 V AH0 M'],
  "servan": ['S ER1 V AH0 N'],
  "servant": ['S ER1 V AH0 N T'],
  "servants": ['S ER1 V AH0 N T S'],
  "servants'": ['S ER1 V AH0 N T S'],
  "serve": ['S ER1 V'],
  "served": ['S ER1 V D'],
  "servello": ['S ER0 V EH1 L OW0'],
  "server": ['S ER1 V ER0'],
  "servers": ['S ER1 V ER0 Z'],
  "serves": ['S ER1 V Z'],
  "service": ['S ER1 V AH0 S', 'S ER1 V IH0 S'],
  "service's": ['S ER1 V IH0 S IH0 Z'],
  "serviceable": ['S ER1 V AH0 S AH0 B AH0 L'],
  "serviced": ['S ER1 V IH0 S T'],
  "serviceman": ['S ER1 V AH0 S M AE2 N'],
  "servicemaster": ['S ER1 V IH0 S M AE2 S T ER0'],
  "servicemen": ['S ER1 V AH0 S M EH0 N'],
  "servicemen's": ['S ER1 V IH0 S M EH0 N Z'],
  "servicer": ['S ER1 V IH0 S ER0'],
  "services": ['S ER1 V AH0 S AH0 Z', 'S ER1 V IH0 S IH0 Z'],
  "services'": ['S ER1 V IH0 S IH0 Z', 'S ER1 V AH0 S AH0 Z'],
  "servicing": ['S ER1 V IH0 S IH0 NG'],
  "servico": ['S ER1 V IH0 K OW2'],
  "servidio": ['S ER0 V IY1 D IY0 OW0'],
  "servile": ['S ER1 V AH0 L'],
  "servility": ['S ER1 V IH0 L AH0 T IY2'],
  "servin": ['S ER1 V IH0 N'],
  "serving": ['S ER1 V IH0 NG'],
  "servings": ['S ER1 V IH0 NG Z'],
  "serviou": ['S ER1 V IY0 UW0'],
  "servis": ['S ER1 V IH0 S'],
  "serviss": ['S ER1 V IH0 S'],
  "servitude": ['S ER1 V AH0 T UW2 D'],
  "servo": ['S ER1 V OW0'],
  "sesame": ['S EH1 S AH0 M IY0'],
  "sesdaq": ['S EH1 S D AE0 K'],
  "sese": ['S IY1 S IY0'],
  "seshadri": ['S EH0 SH AA1 D R IY0'],
  "sesit": ['S EH1 Z IH0 T'],
  "seskar": ['S EH1 S K AA2 R'],
  "seskar's": ['S EH1 S K AA2 R Z'],
  "sesler": ['S EH1 S AH0 L ER0', 'S EH1 S L ER0'],
  "sesno": ['S EH1 S N OW2'],
  "sesno's": ['S EH1 S N OW2 Z'],
  "sessa": ['S EH1 S AH0'],
  "session": ['S EH1 SH AH0 N'],
  "session's": ['S EH1 SH AH0 N Z'],
  "sessions": ['S EH1 SH AH0 N Z'],
  "sessions'": ['S EH1 SH AH0 N Z'],
  "sessler": ['S EH1 S L ER0'],
  "sessoms": ['S EH1 S AH0 M Z'],
  "sessums": ['S EH1 S AH0 M Z'],
  "sestak": ['S EH1 S T AH0 K'],
  "sestanovich": ['S EH0 S T AE1 N AH0 V IH0 CH'],
  "sestito": ['S EH0 S T IY1 T OW0'],
  "set": ['S EH1 T'],
  "set-up": ['S EH1 T AH2 P'],
  "set-ups": ['S EH1 T AH2 P S'],
  "setaro": ['S EH0 T AA1 R OW0'],
  "setback": ['S EH1 T B AE2 K'],
  "setbacks": ['S EH1 T B AE2 K S'],
  "seth": ['S EH1 TH'],
  "seth's": ['S EH1 TH S'],
  "sether": ['S EH1 DH ER0'],
  "sethi": ['S EH1 TH IY0'],
  "sethness": ['S EH1 TH N AH0 S'],
  "sethuraman": ['S EH2 TH ER0 AA1 M AA0 N'],
  "seti": ['S EH1 T IY2'],
  "setliff": ['S EH1 T L IH0 F'],
  "seto": ['S EH1 T OW0'],
  "seton": ['S IY1 T AH0 N'],
  "sets": ['S EH1 T S'],
  "setser": ['S EH1 T S ER0'],
  "settanni": ['S EH0 T AA1 N IY0'],
  "sette": ['S EH1 T'],
  "setter": ['S EH1 T ER0'],
  "setterlund": ['S EH1 T ER0 L AH0 N D'],
  "setters": ['S EH1 T ER0 Z'],
  "setting": ['S EH1 T IH0 NG'],
  "settings": ['S EH1 T IH0 NG Z'],
  "settle": ['S EH1 T AH0 L'],
  "settled": ['S EH1 T AH0 L D'],
  "settlement": ['S EH1 T AH0 L M AH0 N T'],
  "settlement's": ['S EH1 T AH0 L M AH0 N T S'],
  "settlements": ['S EH1 T AH0 L M AH0 N T S'],
  "settlemire": ['S EH1 T AH0 L M AY2 R'],
  "settlemyre": ['S EH1 T AH0 L M AY2 R'],
  "settler": ['S EH1 T AH0 L ER0', 'S EH1 T L ER0'],
  "settlers": ['S EH1 T L ER0 Z', 'S EH1 T AH0 L ER0 Z'],
  "settlers'": ['S EH1 T L ER0 Z'],
  "settles": ['S EH1 T AH0 L Z'],
  "settling": ['S EH1 T AH0 L IH0 NG', 'S EH1 T L IH0 NG'],
  "settsu": ['S EH1 T S UW0'],
  "setty": ['S EH1 T IY0'],
  "setup": ['S EH1 T AH2 P'],
  "setups": ['S EH1 T AH2 P S'],
  "setzer": ['S EH1 T Z ER0'],
  "setzler": ['S EH1 T S L ER0'],
  "seubert": ['S UW1 B ER0 T'],
  "seufert": ['S UW1 F ER0 T'],
  "seuss": ['S UW1 S'],
  "sevareid": ['S EH1 V AH0 R AY2 D'],
  "sevastopol": ['S EH2 V AH0 S T AO1 P AH0 L'],
  "sevcik": ['S EH1 V S IH0 K'],
  "seve": ['S EH1 V EY0'],
  "seven": ['S EH1 V AH0 N'],
  "seven's": ['S EH1 V AH0 N Z'],
  "sevenfold": ['S EH1 V AH0 N F OW2 L D'],
  "sevens": ['S EH1 V AH0 N Z'],
  "seventeen": ['S EH1 V AH0 N T IY1 N'],
  "seventeens": ['S EH1 V AH0 N T IY2 N Z'],
  "seventeenth": ['S EH1 V AH0 N T IY1 N TH'],
  "seventh": ['S EH1 V AH0 N TH'],
  "sevenths": ['S EH1 V AH0 N TH S'],
  "seventies": ['S EH1 V AH0 N T IY0 Z', 'S EH1 V AH0 N IY0 Z'],
  "seventieth": ['S EH1 V AH0 N T IY0 IH0 TH', 'S EH1 V AH0 N IY0 IH0 TH'],
  "seventy": ['S EH1 V AH0 N T IY0', 'S EH1 V AH0 N IY0'],
  "seventy's": ['S EH1 V AH0 N T IY0 Z'],
  "seventy-five": ['S EH1 V AH0 N T IY0 F AY2 V'],
  "sever": ['S EH1 V ER0'],
  "severa": ['S EY0 V EH1 R AH0'],
  "several": ['S EH1 V R AH0 L', 'S EH1 V ER0 AH0 L'],
  "severally": ['S EH1 V R AH0 L IY0'],
  "severance": ['S EH1 V ER2 AH0 N S', 'S EH1 V R AH0 N S'],
  "severe": ['S AH0 V IH1 R'],
  "severed": ['S EH1 V ER0 D'],
  "severely": ['S AH0 V IH1 R L IY0'],
  "severer": ['S EH1 V IH2 R ER0'],
  "severest": ['S AH0 V IH1 R AH0 S T'],
  "severeville": ['S AH0 V IH1 R V IH2 L'],
  "severin": ['S EH1 V ER0 IH0 N'],
  "severing": ['S EH1 V ER0 IH0 NG'],
  "severini": ['S EH0 V ER0 IY1 N IY2'],
  "severino": ['S EH0 V ER0 IY1 N OW0'],
  "severity": ['S IH0 V EH1 R IH0 T IY0'],
  "severn": ['S EH1 V ER0 N'],
  "severns": ['S EH1 V ER0 N Z'],
  "severovic": ['S EH0 V ER1 OW0 V IH0 K'],
  "severs": ['S EH1 V ER0 Z'],
  "severson": ['S EH1 V ER0 S AH0 N'],
  "severt": ['S EH1 V ER0 T'],
  "severtson": ['S EH1 V ER0 T S AH0 N'],
  "sevey": ['S IH0 V EY1'],
  "sevick": ['S EH1 V IH0 K'],
  "sevier": ['S EH1 V Y ER0'],
  "sevigny": ['S IH0 V IH1 G N IY0'],
  "sevilla": ['S EH0 V IH1 L AH0'],
  "seville": ['S AH0 V IH1 L'],
  "sevin": ['S EH1 V IH0 N'],
  "sevruga": ['S EH2 V R UW1 G AH0'],
  "sevy": ['S IY1 V IY0'],
  "sew": ['S OW1'],
  "sewage": ['S UW1 AH0 JH', 'S UW1 IH0 JH'],
  "sewald": ['S UW1 AH0 L D'],
  "sewall": ['S UW1 AH0 L'],
  "seward": ['S UW1 ER0 D'],
  "seward's": ['S UW1 ER0 D Z'],
  "sewed": ['S OW1 D'],
  "sewell": ['S UW1 AH0 L', 'S UW1 IH0 L'],
  "sewer": ['S UW1 ER0'],
  "sewerage": ['S UW1 ER0 IH0 JH'],
  "sewers": ['S UW1 ER0 Z'],
  "sewing": ['S OW1 IH0 NG'],
  "sewn": ['S OW1 N'],
  "sex": ['S EH1 K S'],
  "sexauer": ['S EH1 K S AW0 ER0'],
  "sexed": ['S EH1 K S T'],
  "sexes": ['S EH1 K S AH0 Z', 'S EH1 K S IH0 Z'],
  "sexier": ['S EH1 K S IY0 ER0'],
  "sexiest": ['S EH1 K S IY0 AH0 S T'],
  "sexiness": ['S EH1 K S IY0 N AH0 S'],
  "sexism": ['S EH1 K S IH0 Z AH0 M'],
  "sexist": ['S EH1 K S IH0 S T'],
  "sexless": ['S EH1 K S L IH0 S'],
  "sexson": ['S EH1 K S AH0 N'],
  "sextet": ['S EH2 K S T EH1 T'],
  "sexting": ['S EH1 K S T IH2 NG'],
  "sexton": ['S EH1 K S T AH0 N'],
  "sextuplet": ['S EH2 K S T AH1 P L EH0 T'],
  "sextuplets": ['S EH2 K S T AH1 P L EH0 T S'],
  "sextus": ['S EH1 K S T AH0 S'],
  "sexual": ['S EH1 K SH UW0 AH0 L'],
  "sexuality": ['S EH2 K SH UW0 AE1 L AH0 T IY0'],
  "sexually": ['S EH1 K SH UW0 AH0 L IY0'],
  "sexy": ['S EH1 K S IY0'],
  "seybert": ['S EY1 B ER0 T'],
  "seybold": ['S EY1 B OW2 L D'],
  "seychelles": ['S EY1 SH EH0 L Z'],
  "seydel": ['S EY1 D AH0 L'],
  "seydoux": ['S EY2 D UW1'],
  "seyer": ['S EY1 ER0'],
  "seyfarth": ['S EY1 F AA2 R TH'],
  "seyfert": ['S EY1 F ER0 T'],
  "seyfried": ['S EY1 F ER0 IY0 D'],
  "seyi": ['S EY1 IY0'],
  "seyler": ['S EY1 L ER0'],
  "seyller": ['S EY1 L ER0'],
  "seymore": ['S IY1 M AO0 R'],
  "seymour": ['S IY1 M AO0 R'],
  "seymour's": ['S IY1 M AO0 R Z'],
  "sfernice": ['S F ER1 N IH0 S', 'S AH0 F ER1 N IH0 S'],
  "sferra": ['S F EH1 R AH0'],
  "sferrazza": ['S F ER0 AA1 T S AH0'],
  "sffed": ['S F EH1 D', 'EH1 S EH2 F EH1 D', 'EH1 S EH1 F EH1 F IY1 D IY1'],
  "sforza": ['S F AO1 R Z AH0'],
  "sfuzzi": ['S F UW1 T Z IY0'],
  "sgambati": ['S K AA0 M B AA1 T IY0'],
  "sgro": ['S K R OW1'],
  "sgroi": ['S K R OY1'],
  "sgt": ['S AA1 R JH AH0 N T'],
  "sh": ['SH'],
  "sha": ['SH AA1'],
  "sha'ath": ['SH AA1 TH'],
  "shaak": ['SH AA1 K'],
  "shaanxi": ['SH AA1 NG K S IY0'],
  "shaath": ['SH AA1 TH'],
  "shaath's": ['SH AA1 TH S'],
  "shabad": ['SH AH0 B AE1 D'],
  "shabazz": ['SH AA1 B AA0 Z'],
  "shabazz's": ['SH AA1 B AA0 Z IH0 Z'],
  "shabby": ['SH AE1 B IY0'],
  "shaber": ['SH EY1 B ER0'],
  "shack": ['SH AE1 K'],
  "shackelford": ['SH AE1 K IH0 L F ER0 D'],
  "shackelton": ['SH AH0 K EH1 L T AH0 N'],
  "shackett": ['SH AE1 K IH0 T'],
  "shackle": ['SH AE1 K AH0 L'],
  "shackled": ['SH AE1 K AH0 L D'],
  "shackleford": ['SH AE1 K AH0 L F ER0 D'],
  "shackles": ['SH AE1 K AH0 L Z'],
  "shackleton": ['SH AE1 K AH0 L T AH0 N'],
  "shackleton's": ['SH AE1 K AH0 L T AH0 N Z'],
  "shackletons": ['SH AE1 K AH0 L T AH0 N Z'],
  "shacklett": ['SH AE1 K L IH0 T'],
  "shacks": ['SH AE1 K S'],
  "shad": ['SH AE1 D'],
  "shad's": ['SH AE1 D Z'],
  "shadbush": ['SH AE1 D B UH2 SH'],
  "shadd": ['SH AE1 D'],
  "shadden": ['SH AE1 D AH0 N'],
  "shaddix": ['SH AE1 D IH0 K S'],
  "shaddock": ['SH AE1 D AH0 K'],
  "shadduck": ['SH AE1 D AH0 K'],
  "shade": ['SH EY1 D'],
  "shaded": ['SH EY1 D IH0 D'],
  "shadegg": ['SH AE1 D EH0 G'],
  "shadel": ['SH AE1 D AH0 L'],
  "shader": ['SH EY1 D ER0'],
  "shades": ['SH EY1 D Z'],
  "shadid": ['SH AE1 D IH0 D'],
  "shading": ['SH EY1 D IH0 NG'],
  "shadings": ['SH EY1 D IH0 NG Z'],
  "shadle": ['SH EY1 D AH0 L'],
  "shadley": ['SH AE1 D L IY0'],
  "shadoan": ['SH AE1 D OW0 N'],
  "shadow": ['SH AE1 D OW2'],
  "shadowed": ['SH AE1 D OW0 D'],
  "shadowens": ['SH AE1 D OW0 AH0 N Z'],
  "shadowing": ['SH AE1 D OW0 IH0 NG'],
  "shadowland": ['SH AE1 D OW0 L AE2 N D'],
  "shadowlands": ['SH AE1 D OW0 L AE2 N D Z'],
  "shadows": ['SH AE1 D OW2 Z'],
  "shadowy": ['SH AE1 D OW0 IY0'],
  "shadrick": ['SH AE1 D R IH0 K'],
  "shadur": ['SH AE1 D ER0'],
  "shadwell": ['SH AE1 D W EH0 L'],
  "shadwick": ['SH AE1 D W IH0 K'],
  "shady": ['SH EY1 D IY0'],
  "shaefer": ['SH EY1 F ER0'],
  "shaeffer": ['SH EH1 F ER0'],
  "shaer": ['SH EY1 ER0'],
  "shafer": ['SH EY1 F ER0'],
  "shaff": ['SH AE1 F'],
  "shaffer": ['SH EY1 F ER0'],
  "shaffner": ['SH AE1 F N ER0'],
  "shafran": ['SH AE1 F R AH0 N'],
  "shaft": ['SH AE1 F T'],
  "shafted": ['SH AE1 F T IH0 D'],
  "shafting": ['SH AE1 F T IH0 NG'],
  "shafts": ['SH AE1 F T S'],
  "shag": ['SH AE1 G'],
  "shagging": ['SH AE1 G IH0 NG'],
  "shaggy": ['SH AE1 G IY0'],
  "shags": ['SH AE1 G Z'],
  "shah": ['SH AA1'],
  "shah's": ['SH AA1 Z'],
  "shahan": ['SH AE1 HH AH0 N'],
  "shahar": ['SH AH0 HH AA1 R'],
  "shahar's": ['SH AH0 HH AA1 R Z'],
  "shaheed": ['SH AH0 HH IY1 D'],
  "shaheen": ['SH AH0 HH IY1 N'],
  "shahian": ['SH AH0 HH IY1 AH0 N'],
  "shahidi": ['SH AH0 HH IY1 D IY0'],
  "shahin": ['SH AE1 HH IH0 N'],
  "shahinian": ['SH AH0 HH IH1 N IY0 AH0 N'],
  "shahrokh": ['SH AA1 R AA0 K'],
  "shahrzad": ['SH AA2 R Z AE1 D'],
  "shai": ['SH AY1'],
  "shaik": ['SH EY1 K'],
  "shaiken": ['SH EY1 K AH0 N'],
  "shaikh": ['SH EY1 K'],
  "shain": ['SH EY1 N'],
  "shaine": ['SH EY1 N'],
  "shaka": ['SH AA1 K AH0'],
  "shake": ['SH EY1 K'],
  "shakedown": ['SH EY1 K D AW2 N'],
  "shakedowns": ['SH EY1 K D AW2 N Z'],
  "shaken": ['SH EY1 K AH0 N'],
  "shakeout": ['SH EY1 K AW2 T'],
  "shaker": ['SH EY1 K ER0'],
  "shakers": ['SH EY1 K ER0 Z'],
  "shakes": ['SH EY1 K S'],
  "shakespeare": ['SH EY1 K S P IY2 R'],
  "shakespeare's": ['SH EY1 K S P IY2 R Z'],
  "shakespearean": ['SH EY2 K S P IH1 R IY0 AH0 N'],
  "shakeup": ['SH EY1 K AH2 P'],
  "shakeups": ['SH EY1 K AH2 P S'],
  "shakier": ['SH EY1 K IY0 ER0'],
  "shakiest": ['SH EY1 K IY0 IH0 S T'],
  "shakiness": ['SH EY1 K IY0 N AH0 S'],
  "shaking": ['SH EY1 K IH0 NG'],
  "shakir": ['SH AE1 K IH0 R'],
  "shakira": ['SH AE2 K IY1 R AH0'],
  "shaklee": ['SH AE1 K L IY0'],
  "shakunaga": ['SH AA2 K UW0 N AA1 G AH0'],
  "shakur": ['SH AA1 K ER0'],
  "shaky": ['SH EY1 K IY0'],
  "shalala": ['SH AH0 L EY1 L AH0'],
  "shalala's": ['SH AH0 L EY1 L AH0 Z'],
  "shale": ['SH EY1 L'],
  "shaler": ['SH EY1 L ER0'],
  "shales": ['SH EY1 L Z'],
  "shalev": ['SH AA1 L EH2 V'],
  "shali": ['SH AE1 L IY0'],
  "shalikashvili": ['SH AE2 L IY0 K AA0 SH V IY1 L IY0'],
  "shalikashvili's": ['SH AE2 L IY0 K AA0 SH V IY1 L IY0 Z'],
  "shall": ['SH AE1 L'],
  "shall's": ['SH AE1 L Z'],
  "shallcross": ['SH AE1 L K R AA2 S'],
  "shallenberger": ['SH AO1 L AH0 N B ER0 G ER0'],
  "shallow": ['SH AE1 L OW0'],
  "shallower": ['SH AE1 L OW0 ER0'],
  "shallowness": ['SH AE1 L OW0 N AH0 S'],
  "shalnev": ['SH AA1 L N EH0 V', 'SH AA1 L N EH0 F'],
  "shalom": ['SH AH0 L OW1 M'],
  "shalt": ['SH AE1 L T'],
  "sham": ['SH AE1 M'],
  "shamalia": ['SH AH0 M AA1 L Y AH0', 'SH AH0 M EY1 L Y AH0'],
  "shaman": ['SH EY1 M AH0 N'],
  "shamanism": ['SH EY1 M AH0 N IH2 Z M'],
  "shamanists": ['SH EY1 M AH0 N IH2 S T S'],
  "shamans": ['SH EY1 M AH0 N Z'],
  "shamas": ['SH AE1 M AH0 S'],
  "shambaugh": ['SH AE1 M B AO2'],
  "shamberger": ['SH AE1 M B ER0 G ER0'],
  "shamble": ['SH AE1 M B AH0 L'],
  "shambles": ['SH AE1 M B AH0 L Z'],
  "shambley": ['SH AE1 M B L IY0'],
  "shamblin": ['SH AE1 M B L IH0 N'],
  "shambo": ['SH AE1 M B OW0'],
  "shamburg": ['SH AE1 M B ER0 G'],
  "shamburger": ['SH AE1 M B ER0 G ER0'],
  "shame": ['SH EY1 M'],
  "shamed": ['SH EY1 M D'],
  "shameful": ['SH EY1 M F AH0 L'],
  "shamel": ['SH AE1 M AH0 L'],
  "shameless": ['SH EY1 M L AH0 S'],
  "shamelessly": ['SH EY1 M L AH0 S L IY0'],
  "shamelle": ['SH AH0 M EH1 L'],
  "shames": ['SH EY1 M Z'],
  "shaming": ['SH EY1 M IH0 NG'],
  "shamir": ['SH AH0 M IH1 R'],
  "shamir's": ['SH AH0 M IH1 R Z'],
  "shammond": ['SH AE1 M AH0 N D'],
  "shamp": ['SH AE1 M P'],
  "shampine": ['SH AE1 M P AY2 N'],
  "shampoo": ['SH AE0 M P UW1'],
  "shampooed": ['SH AE0 M P UW1 D'],
  "shampoos": ['SH AE0 M P UW1 Z'],
  "shamrock": ['SH AE1 M R AA2 K'],
  "shamrock's": ['SH AE1 M R AA2 K S'],
  "shamu": ['SH AA0 M UW1'],
  "shamus": ['SH EY1 M AH0 S'],
  "shan": ['SH AE1 N'],
  "shana": ['SH AE1 N AH0', 'SH EY1 N AH0'],
  "shanafelt": ['SH AE1 N AH0 F EH2 L T'],
  "shanahan": ['SH AE1 N AH0 HH AE0 N'],
  "shanansky": ['SH AH0 N AE1 N S K IY0'],
  "shand": ['SH AE1 N D'],
  "shandling": ['SH AE1 N D L IH0 NG'],
  "shandong": ['SH AA2 N D OW1 NG'],
  "shands": ['SH AE1 N D Z'],
  "shandwick": ['SH AE1 N D W IH2 K'],
  "shandwick's": ['SH AE1 N D W IH2 K S'],
  "shandy": ['SH AE1 N D IY0'],
  "shane": ['SH EY1 N'],
  "shaner": ['SH EY1 N ER0'],
  "shaneyfelt": ['SH AE1 N IY0 F EH0 L T'],
  "shanghai": ['SH AE1 NG HH AY1'],
  "shanghai's": ['SH AE1 NG HH AY1 Z'],
  "shangkun": ['SH AE1 NG K AH0 N'],
  "shangri": ['SH AE1 NG G R IY0'],
  "shanholtz": ['SH AE1 N HH OW2 L T S'],
  "shanholtzer": ['SH AE1 N HH OW0 L T Z ER0'],
  "shank": ['SH AE1 NG K'],
  "shankar": ['SH AE1 NG K AA0 R'],
  "shankel": ['SH AE1 NG K AH0 L'],
  "shanken": ['SH AE1 NG K AH0 N'],
  "shanker": ['SH AE1 NG K ER0'],
  "shankland": ['SH AE1 NG K L AH0 N D'],
  "shankle": ['SH AE1 NG K AH0 L'],
  "shankles": ['SH AE1 NG K AH0 L Z'],
  "shanklin": ['SH AE1 NG K L IH0 N'],
  "shankman": ['SH AE1 NG K M AH0 N'],
  "shanks": ['SH AE1 NG K S'],
  "shanley": ['SH AE1 N L IY0'],
  "shanna": ['SH AE1 N AH0'],
  "shannahan": ['SH AE1 N AH0 HH AE0 N'],
  "shannon": ['SH AE1 N AH0 N'],
  "shannon's": ['SH AE1 N AH0 N Z'],
  "shansev": ['SH AE1 N S EH0 V', 'SH AE1 N S EH0 F'],
  "shant": ['SH AA1 N T'],
  "shanti": ['SH AE1 N T IY0'],
  "shanties": ['SH AE1 N T IY0 Z'],
  "shanty": ['SH AE1 N T IY0'],
  "shantytown": ['SH AE1 N T IY0 T AW2 N'],
  "shantytowns": ['SH AE1 N T IY0 T AW2 N Z'],
  "shantz": ['SH AE1 N T S'],
  "shao": ['SH AW1'],
  "shaolin": ['SH AA1 OW0 L IH2 N'],
  "shap": ['SH AE1 P'],
  "shape": ['SH EY1 P'],
  "shaped": ['SH EY1 P T'],
  "shapeless": ['SH EY1 P L AH0 S'],
  "shapely": ['SH EY1 P L IY0'],
  "shapero": ['SH AH0 P IH1 R OW0'],
  "shapes": ['SH EY1 P S'],
  "shaping": ['SH EY1 P IH0 NG'],
  "shapira": ['SH AH0 P IH1 R AH0'],
  "shapiro": ['SH AH0 P IH1 R OW0'],
  "shapiro's": ['SH AH0 P IH1 R OW0 Z'],
  "shapiros": ['SH AH0 P IH1 R OW0 Z'],
  "shapley": ['SH AE1 P L IY0'],
  "shapp": ['SH AE1 P'],
  "shappell": ['SH AE1 P AH0 L'],
  "shaq": ['SH AE1 K'],
  "shaquille": ['SH AH0 K IY1 L'],
  "shara": ['SH AA1 R AH0'],
  "sharaa": ['SH AA1 R AH0'],
  "sharansky": ['SH ER0 AE1 N S K IY0'],
  "sharar": ['SH ER0 AA1 R'],
  "sharber": ['SH AA1 R B ER0'],
  "sharbono": ['SH AA0 R B OW1 N OW0'],
  "shard": ['SH AA1 R D'],
  "shards": ['SH AA1 R D Z'],
  "share": ['SH EH1 R'],
  "share's": ['SH EH1 R Z'],
  "sharecrop": ['SH EH1 R K R AA2 P'],
  "sharecropper": ['SH EH1 R K R AA2 P ER0'],
  "sharecroppers": ['SH EH1 R K R AA2 P ER0 Z'],
  "shared": ['SH EH1 R D'],
  "shareholder": ['SH EH1 R HH OW2 L D ER0'],
  "shareholder's": ['SH EH1 R HH OW2 L D ER0 Z'],
  "shareholders": ['SH EH1 R HH OW2 L D ER0 Z'],
  "shareholders'": ['SH EH1 R HH OW2 L D ER0 Z'],
  "shareholding": ['SH EH1 R HH OW2 L D IH0 NG'],
  "shareholdings": ['SH EH1 R HH OW2 L D IH0 NG Z'],
  "sharell": ['SH AH0 R EH1 L'],
  "shareowner": ['SH EH1 R OW2 N ER0'],
  "shareowners": ['SH EH1 R OW2 N ER0 Z'],
  "sharer": ['SH EH1 R ER0'],
  "shares": ['SH EH1 R Z'],
  "shares'": ['SH EH1 R Z'],
  "shareware": ['SH EH1 R W EH2 R'],
  "sharf": ['SH AA1 R F'],
  "sharfman": ['SH AA1 R F M AH0 N'],
  "shari": ['SH AA1 R IY0'],
  "sharia": ['SH AA1 R IY0 AH0'],
  "sharif": ['SH ER0 IY1 F'],
  "sharing": ['SH EH1 R IH0 NG'],
  "shark": ['SH AA1 R K'],
  "sharkey": ['SH AA1 R K IY0'],
  "sharking": ['SH AA1 R K IH0 NG'],
  "sharklike": ['SH AA1 R K L AY2 K'],
  "sharks": ['SH AA1 R K S'],
  "sharla": ['SH AA1 R L AH0'],
  "sharleen": ['SH AA0 R L IY1 N'],
  "sharlene": ['SH AA1 R L IY2 N'],
  "sharline": ['SH AA1 R L AY2 N'],
  "sharlow": ['SH AA1 R L OW0'],
  "sharm": ['SH AA1 R M'],
  "sharma": ['SH AA1 R M AH0'],
  "sharma's": ['SH AA1 R M AH0 Z'],
  "sharman": ['SH AA1 R M AH0 N'],
  "sharon": ['SH AE1 R AH0 N', 'SH EH1 R AH0 N', 'SH AH0 R OW1 N'],
  "sharon's": ['SH EH1 R AH0 N Z', 'SH AE1 R AH0 N Z', 'SH AH0 R OW1 N Z'],
  "sharp": ['SH AA1 R P'],
  "sharp's": ['SH AA1 R P S'],
  "sharp-sign": ['SH AA1 R P S AY1 N'],
  "sharpe": ['SH AA1 R P'],
  "sharpen": ['SH AA1 R P AH0 N'],
  "sharpened": ['SH AA1 R P AH0 N D'],
  "sharpening": ['SH AA1 R P AH0 N IH0 NG', 'SH AA1 R P N IH0 NG'],
  "sharpens": ['SH AA1 R P AH0 N Z'],
  "sharper": ['SH AA1 R P ER0'],
  "sharpest": ['SH AA1 R P AH0 S T'],
  "sharpeville": ['SH AA1 R P AH0 V IH2 L'],
  "sharpie": ['SH AA1 R P IY0'],
  "sharpies": ['SH AA1 R P IY0 Z'],
  "sharples": ['SH AA1 R P AH0 L Z'],
  "sharpless": ['SH AA1 R P L AH0 S'],
  "sharpley": ['SH AA1 R P L IY0'],
  "sharply": ['SH AA1 R P L IY0'],
  "sharpnack": ['SH AA1 R P N AH0 K'],
  "sharpness": ['SH AA1 R P N AH0 S'],
  "sharps": ['SH AA1 R P S'],
  "sharpshooter": ['SH AA1 R P SH UW0 T ER0', 'SH AA1 R P SH UW2 T ER0'],
  "sharpshooters": ['SH AA1 R P SH UW0 T ER0 Z'],
  "sharpton": ['SH AA1 R P T AH0 N'],
  "sharpy": ['SH AA1 R P IY0'],
  "sharpy's": ['SH AA1 R P IY0 Z'],
  "sharrar": ['SH ER0 AA1 R'],
  "sharrer": ['SH AA1 R ER0'],
  "sharrett": ['SH AE1 R IH0 T'],
  "sharrock": ['SH AE1 R AH0 K'],
  "sharron": ['SH AE1 R AH0 N'],
  "sharrow": ['SH AE1 R OW0'],
  "sharry": ['SH AA1 R IY0'],
  "shartzer": ['SH AA1 R T Z ER0'],
  "sharum": ['SH AA2 R AH1 M'],
  "sharyl": ['SH AA1 R AH0 L'],
  "shas": ['SH AA1 S'],
  "shashlik": ['SH AA1 SH L IH0 K'],
  "shashoua": ['SH AH0 SH UW1 AH0'],
  "shasta": ['SH AE1 S T AH0'],
  "shasta's": ['SH AE1 S T AH0 Z'],
  "shasteen": ['SH AE0 S T IY1 N'],
  "shatkin": ['SH AE1 T K IH0 N'],
  "shatley": ['SH AE1 T L IY0'],
  "shatman": ['SH AE1 T M AH0 N'],
  "shatner": ['SH AE1 T N ER0'],
  "shatrov": ['SH AE1 T R AA0 V'],
  "shatt": ['SH AE1 T'],
  "shatter": ['SH AE1 T ER0'],
  "shattered": ['SH AE1 T ER0 D'],
  "shattering": ['SH AE1 T ER0 IH0 NG'],
  "shatterproof": ['SH AE1 T ER0 P R UW2 F'],
  "shatters": ['SH AE1 T ER0 Z'],
  "shattuck": ['SH AE1 T AH0 K'],
  "shatz": ['SH AE1 T S', 'SH AA1 T S'],
  "shatzer": ['SH EY1 T Z ER0'],
  "shaub": ['SH AO1 B'],
  "shauger": ['SH AW1 G ER0'],
  "shaughnessy": ['SH AO1 N IH0 S IY0'],
  "shaul": ['SH AO1 L'],
  "shaulis": ['SH AW1 L IH0 S'],
  "shaull": ['SH AO1 L'],
  "shaun": ['SH AO1 N'],
  "shauna": ['SH AO1 N AH0'],
  "shave": ['SH EY1 V'],
  "shaved": ['SH EY1 V D'],
  "shavelson": ['SH EY1 V AH0 L S IH0 N', 'SH AE1 V AH0 L S IH0 N'],
  "shaven": ['SH EY1 V AH0 N'],
  "shaver": ['SH EY1 V ER0'],
  "shavers": ['SH EY1 V ER0 Z'],
  "shaves": ['SH EY1 V Z'],
  "shaving": ['SH EY1 V IH0 NG'],
  "shavings": ['SH EY1 V IH0 NG Z'],
  "shavord": ['SH AH0 V AO1 R D'],
  "shaw": ['SH AO1'],
  "shaw's": ['SH AO1 Z'],
  "shawcross": ['SH AO1 K R AO2 S'],
  "shawgo": ['SH AO1 G OW2'],
  "shawhan": ['SH AE1 W AH0 N'],
  "shawinigan": ['SH AH0 W IH1 N IH0 G AH0 N'],
  "shawl": ['SH AO1 L'],
  "shawler": ['SH AO1 L ER0'],
  "shawley": ['SH AO1 L IY0'],
  "shawls": ['SH AO1 L Z'],
  "shawmut": ['SH AO1 M AH0 T'],
  "shawmut's": ['SH AO1 M AH0 T S'],
  "shawn": ['SH AO1 N'],
  "shawn's": ['SH AO1 N Z'],
  "shawna": ['SH AO1 N AH0'],
  "shawnee": ['SH AO1 N IY0'],
  "shawnut": ['SH AO1 N AH0 T'],
  "shawshank": ['SH AO1 SH AE2 N K'],
  "shawver": ['SH AO1 V ER0'],
  "shay": ['SH EY1'],
  "shaykin": ['SH EY1 K IH0 N'],
  "shaykin's": ['SH EY1 K IH0 N Z'],
  "shayne": ['SH EY1 N'],
  "shays": ['SH EY1 Z'],
  "shcharansky": ['SH ER0 AE1 N S K IY0'],
  "shchedrin": ['SH EH1 D R IH0 N'],
  "shcherbitsky": ['SH ER0 B IH1 T S K IY0'],
  "she": ['SH IY1'],
  "she'd": ['SH IY1 D'],
  "she'll": ['SH IY1 L'],
  "she's": ['SH IY1 Z'],
  "shea": ['SH EY1'],
  "shea's": ['SH EY1 Z'],
  "shead": ['S HH EH1 D'],
  "sheaf": ['SH IY1 F'],
  "sheaffer": ['SH IY1 F ER0'],
  "sheahan": ['SH IY1 AH0 N'],
  "shealey": ['SH IY1 L IY0'],
  "shealy": ['SH IY1 L IY0'],
  "shean": ['SH IY1 N'],
  "shear": ['SH IH1 R'],
  "shear's": ['SH IY1 R Z'],
  "sheard": ['SH IH1 R D'],
  "sheared": ['SH IH1 R D'],
  "shearer": ['SH IH1 R ER0'],
  "shearin": ['SH IH1 R IH0 N'],
  "shearing": ['SH IH1 R IH0 NG'],
  "shearman": ['SH IY1 R M AH0 N'],
  "shearn": ['SH IH1 R N'],
  "shearon": ['SH IH1 R AH0 N'],
  "shearouse": ['SH IH1 R AW0 S'],
  "shears": ['SH IY1 R Z'],
  "shearson": ['SH IH1 R S AH0 N'],
  "shearson's": ['SH IH1 R S AH0 N Z'],
  "sheath": ['SH IY1 TH'],
  "sheath-knife": ['SH IY1 TH N AY2 F'],
  "sheathbill": ['SH IY1 TH B IH0 L'],
  "sheathbills": ['SH IY1 TH B IH0 L Z'],
  "sheathe": ['SH IY1 DH'],
  "sheathed": ['SH IY1 DH D'],
  "sheathing": ['SH IY1 DH IH0 NG'],
  "sheats": ['SH IY1 T S'],
  "sheaves": ['SH IY1 V Z'],
  "sheba": ['SH IY1 B AH0'],
  "shebek": ['SH EH1 B AH0 K'],
  "sheboygan": ['SH AH0 B OY1 G AH0 N'],
  "sheck": ['SH EH1 K'],
  "sheck's": ['SH EH1 K S'],
  "sheckler": ['SH EH1 K L ER0'],
  "shed": ['SH EH1 D'],
  "shedd": ['SH EH1 D'],
  "shedden": ['SH EH1 D AH0 N'],
  "shedding": ['SH EH1 D IH0 NG'],
  "shedlock": ['SH EH1 D L AA2 K'],
  "shedrick": ['SH EH1 D R IH0 K'],
  "sheds": ['SH EH1 D Z'],
  "sheeder": ['SH IY1 D ER0'],
  "sheedy": ['SH IY1 D IY0'],
  "sheehan": ['SH IY1 AH0 N', 'SH IY1 HH AH0 N'],
  "sheehan's": ['SH IY1 AH0 N Z', 'SH IY1 HH AH0 N Z'],
  "sheehy": ['SH IY1 HH IY0', 'SH IY1 IY0'],
  "sheek": ['SH IY1 K'],
  "sheeks": ['SH IY1 K S'],
  "sheela": ['SH IY1 L AH0'],
  "sheelah": ['SH IY1 L AH0'],
  "sheelen": ['SH IY1 L AH0 N'],
  "sheeler": ['SH IY1 L ER0'],
  "sheeley": ['SH IY1 L IY0'],
  "sheely": ['SH IY1 L IY0'],
  "sheen": ['SH IY1 N'],
  "sheen's": ['SH IY1 N Z'],
  "sheena": ['SH IY1 N AH0'],
  "sheens": ['SH IY1 N Z'],
  "sheep": ['SH IY1 P'],
  "sheep's": ['SH IY1 P S'],
  "sheepish": ['SH IY1 P IH0 SH'],
  "sheepishly": ['SH IY1 P IH0 SH L IY0'],
  "sheeps": ['SH IY1 P S'],
  "sheepskin": ['SH IY1 P S K IH2 N'],
  "sheer": ['SH IH1 R'],
  "sheeran": ['SH IH1 R AH0 N'],
  "sheeran's": ['SH IH1 R AH0 N Z'],
  "sheerer": ['SH IY1 R ER0'],
  "sheerin": ['SH IH1 R IH0 N'],
  "sheesh": ['SH IY1 SH'],
  "sheesley": ['SH IY1 Z L IY0'],
  "sheet": ['SH IY1 T'],
  "sheeting": ['SH IY1 T IH0 NG'],
  "sheets": ['SH IY1 T S'],
  "sheetz": ['SH IY1 T S'],
  "sheff": ['SH EH1 F'],
  "sheffer": ['SH EH1 F ER0'],
  "sheffey": ['SH EH1 F IY0'],
  "sheffield": ['SH EH1 F IY0 L D'],
  "sheffler": ['SH EH1 F L ER0'],
  "sheftel": ['SH EH2 F T EH1 L'],
  "sheftel's": ['SH EH2 F T EH1 L Z'],
  "shegog": ['SH EH1 G AA0 G'],
  "shehan": ['SH EH1 HH AH0 N'],
  "shehane": ['SH EH1 HH AH0 N'],
  "shehorn": ['SH EH1 HH ER0 N'],
  "sheik": ['SH IY1 K'],
  "sheik's": ['SH IY1 K S'],
  "sheikdom": ['SH IY1 K D AH0 M'],
  "sheikdoms": ['SH IY1 K D AH0 M Z'],
  "sheikh": ['SH IY1 K'],
  "sheiks": ['SH IY1 K S'],
  "sheil": ['SH AY1 L'],
  "sheila": ['SH IY1 L AH0'],
  "sheila's": ['SH IY1 L AH0 Z'],
  "sheilah": ['SH IY1 L AH0'],
  "sheilds": ['SH AY1 L D Z'],
  "sheils": ['SH AY1 L Z'],
  "shein": ['SH AY1 N'],
  "sheinberg": ['SH AY1 N B ER0 G'],
  "shek": ['SH EH1 K'],
  "shek's": ['SH EH1 K S'],
  "shekel": ['SH EH1 K AH0 L'],
  "shekels": ['SH EH1 K AH0 L Z'],
  "shelburne": ['SH EH1 L B ER0 N'],
  "shelby": ['SH EH1 L B IY0'],
  "shelby's": ['SH EH1 L B IY0 Z'],
  "shelbyville": ['SH EH1 L B IY0 V IH2 L'],
  "sheldahl": ['SH EH1 L D AA2 L'],
  "shelden": ['SH EH1 L D AH0 N'],
  "sheldon": ['SH EH1 L D AH0 N'],
  "shelev": ['SH EH1 L IH0 V'],
  "sheley": ['SH IY1 L IY0'],
  "shelf": ['SH EH1 L F'],
  "shelhah": ['SH EH1 L HH AH0'],
  "shelhamer": ['SH EH1 L HH AH0 M ER0'],
  "shelia": ['SH IH1 L Y AH0'],
  "sheline": ['SH EH1 L AY0 N'],
  "shell": ['SH EH1 L'],
  "shell's": ['SH EH1 L Z'],
  "shellabarger": ['SH EH1 L AH0 B AA2 R G ER0'],
  "shellac": ['SH EH1 L AE2 K'],
  "shellack": ['SH EH2 L AE1 K'],
  "shellacked": ['SH EH2 L AE1 K D'],
  "shellacking": ['SH EH2 L AE1 K IH0 NG'],
  "shelled": ['SH EH1 L D'],
  "shellenbarger": ['SH EH1 L IH0 N B AA0 R G ER0'],
  "shellenberger": ['SH EH1 L AH0 N B ER0 G ER0'],
  "sheller": ['SH EH1 L ER0'],
  "shelley": ['SH EH1 L IY0'],
  "shelley's": ['SH EH1 L IY0 Z'],
  "shellfish": ['SH EH1 L F IH2 SH'],
  "shellhammer": ['SH EH1 L HH AE2 M ER0'],
  "shellhorn": ['SH EH1 L HH ER0 N'],
  "shelling": ['SH EH1 L IH0 NG'],
  "shellings": ['SH EH1 L IH0 NG Z'],
  "shellito": ['SH EY0 L IY1 T OW0'],
  "shellman": ['SH EH1 L M AH0 N'],
  "shells": ['SH EH1 L Z'],
  "shellshock": ['SH EH1 L SH AA2 K'],
  "shellshocked": ['SH EH1 L SH AA2 K T'],
  "shelly": ['SH EH1 L IY0'],
  "shelman": ['SH EH1 L M AH0 N'],
  "shelnutt": ['SH EH1 L N AH0 T'],
  "shelor": ['SH EH1 L ER0'],
  "shelp": ['SH EH1 L P'],
  "shelstad": ['SH EH1 L S T AH0 D'],
  "shelter": ['SH EH1 L T ER0'],
  "sheltered": ['SH EH1 L T ER0 D'],
  "sheltering": ['SH EH1 L T ER0 IH0 NG'],
  "shelters": ['SH EH1 L T ER0 Z'],
  "shelton": ['SH EH1 L T AH0 N'],
  "shelton's": ['SH EH1 L T AH0 N Z'],
  "shelve": ['SH EH1 L V'],
  "shelved": ['SH EH1 L V D'],
  "shelves": ['SH EH1 L V Z'],
  "shelving": ['SH EH1 L V IH0 NG'],
  "shema": ['SH IH0 M AH1'],
  "shemanski": ['SH IH0 M AE1 N S K IY0'],
  "shemona": ['SH IH0 M OW1 N AH0'],
  "shemwell": ['SH EH1 M W EH2 L'],
  "shen": ['SH EH1 N'],
  "shena": ['SH IY1 N AH0'],
  "shenandoah": ['SH EH2 N AH0 N D OW1 AH0'],
  "shenanigan": ['SH AH0 N AE1 N IH0 G AH0 N'],
  "shenanigans": ['SH AH0 N AE1 N IH0 G AH0 N Z'],
  "shenberger": ['SH EH1 N B ER0 G ER0'],
  "shenefield": ['SH EH1 N IH0 F IY2 L D'],
  "sheneman": ['SH IY1 N M AH0 N'],
  "sheng": ['SH EH1 NG'],
  "sheng-fen": ['SH EH1 NG F EH1 N'],
  "shenice": ['SH AH0 N IY1 S'],
  "shenk": ['SH EH1 NG K'],
  "shenker": ['SH EH1 NG K ER0'],
  "shenkman": ['SH EH1 NG K M AH0 N'],
  "shenton": ['SH EH1 N T AH0 N'],
  "shenyang": ['SH EH0 N Y AE1 NG'],
  "shenzhen": ['SH EH1 N ZH EH2 N'],
  "shep": ['SH EH1 P'],
  "shepard": ['SH EH1 P ER0 D'],
  "shepard's": ['SH EH1 P ER0 D Z'],
  "shepardson": ['SH EH1 P AA0 R D S AH0 N'],
  "sheperd": ['SH EH1 P ER0 D'],
  "shephard": ['SH EH1 P ER0 D'],
  "shepheard": ['SH EH1 P ER0 D'],
  "shepherd": ['SH EH1 P ER0 D'],
  "shepherd's": ['SH EH1 P ER0 D Z'],
  "shepherded": ['SH EH1 P ER0 D IH0 D'],
  "shepherding": ['SH EH1 P ER0 D IH0 NG'],
  "shepherds": ['SH EH1 P ER0 D Z'],
  "shepler": ['SH EH1 P L ER0'],
  "shepley": ['SH EH1 P L IY0'],
  "shepp": ['SH EH1 P'],
  "sheppard": ['SH EH1 P ER0 D'],
  "sheppard's": ['SH EH1 P ER0 D Z'],
  "sheppards": ['SH EH1 P ER0 D Z'],
  "shepper": ['SH EH1 P ER0'],
  "shepperd": ['SH EH1 P ER0 D'],
  "shepperson": ['SH EH1 P ER0 S AH0 N'],
  "sheppy": ['SH EH1 P IY0'],
  "sher": ['SH ER1'],
  "sherak": ['SH EH1 R AE0 K'],
  "sherard": ['SH EH1 R ER0 D'],
  "sheraton": ['SH EH1 R AH0 T AH0 N'],
  "sheraton's": ['SH EH1 R AH0 T AH0 N Z'],
  "sherbert": ['SH ER1 B ER0 T'],
  "sherbet": ['SH ER1 B AH0 T'],
  "sherbondy": ['SH ER0 B AA1 N D IY0'],
  "sherborne": ['SH ER1 B ER0 N'],
  "sherbourn": ['SH ER0 B UH1 R N'],
  "sherbourne": ['SH ER0 B UH1 R N'],
  "sherburn": ['SH ER1 B ER0 N'],
  "sherburne": ['SH ER1 B ER0 N'],
  "shere": ['SH IH1 R'],
  "sheree": ['SH ER0 IY1'],
  "sherer": ['SH IH1 R ER0'],
  "sherfey": ['SH ER1 F IY0'],
  "sherfield": ['SH ER1 F IY0 L D'],
  "sheri": ['SH EH1 R IY0'],
  "sherick": ['SH EH1 R IH0 K'],
  "sheridan": ['SH EH1 R IH0 D AH0 N'],
  "sheriff": ['SH EH1 R AH0 F', 'SH EH1 R IH0 F'],
  "sheriff's": ['SH EH1 R AH0 F S', 'SH EH1 R IH0 F S'],
  "sheriffs": ['SH EH1 R AH0 F S'],
  "sherin": ['SH EH1 R IH0 N'],
  "sherk": ['SH ER1 K'],
  "sherley": ['SH ER1 L IY0'],
  "sherlin": ['SH ER1 L IH0 N'],
  "sherlock": ['SH ER1 L AA2 K'],
  "sherlock's": ['SH ER1 L AA2 K S'],
  "sherlund": ['SH ER1 L AH0 N D'],
  "sherman": ['SH ER1 M AH0 N'],
  "sherman's": ['SH ER1 M AH0 N Z'],
  "shermer": ['SH ER1 M ER0'],
  "shernoff": ['SH ER1 N AO2 F'],
  "sherod": ['SH EH1 R AH0 D'],
  "sherr": ['SH EH1 R'],
  "sherr's": ['SH EH1 R Z'],
  "sherrard": ['SH EH1 R ER0 D'],
  "sherrell": ['SH EH1 R AH0 L'],
  "sherrer": ['SH EH1 R ER0'],
  "sherrgold": ['SH EH1 R G OW2 L D'],
  "sherri": ['SH EH1 R IY0'],
  "sherri's": ['SH EH1 R IY0 Z'],
  "sherrick": ['SH EH1 R IH0 K'],
  "sherrie": ['SH EH1 R IY0'],
  "sherriff": ['SH EH1 R IH0 F'],
  "sherrill": ['SH EH1 R IH0 L'],
  "sherrin": ['SH EH1 R IH0 N'],
  "sherrit": ['SH EH1 R IH0 T'],
  "sherritt": ['SH EH1 R IH0 T'],
  "sherrod": ['SH EH1 R AH0 D'],
  "sherron": ['SH EH1 R AH0 N'],
  "sherrow": ['SH EH1 R OW0'],
  "sherry": ['SH EH1 R IY0'],
  "sherry's": ['SH EH1 R IY0 Z'],
  "shertzer": ['SH ER1 T Z ER0'],
  "sherva": ['SH ER1 V AH0'],
  "sherwin": ['SH ER1 W IH0 N'],
  "sherwood": ['SH ER1 W UH2 D'],
  "sherwood's": ['SH ER1 W UH2 D Z'],
  "sheryl": ['SH EH1 R AH0 L'],
  "sheshunoff": ['SH EH1 SH UW0 N AO0 F'],
  "sheth": ['SH EH1 TH'],
  "shetland": ['SH EH1 T L AH0 N D'],
  "shetler": ['SH EH1 T L ER0'],
  "shetley": ['SH EH1 T L IY0'],
  "shetter": ['SH EH1 T ER0'],
  "shetterly": ['SH EH1 T ER0 L IY0'],
  "shevardnadze": ['SH EH2 V ER0 D N AA1 D Z IY0'],
  "shevardnadze's": ['SH EH2 V ER0 D N AA1 D Z IY0 Z'],
  "shevlin": ['SH EH1 V L IH0 N'],
  "shevtl": ['SH EH1 V T IH0 L'],
  "shew": ['SH UW1'],
  "sheward": ['SH UW1 ER0 D'],
  "shewchuk": ['SH EH1 CH AH0 K'],
  "shewell": ['SH EH1 W EH0 L'],
  "shewmake": ['SH UW1 M EY2 K'],
  "shewmaker": ['SH UW1 M EY0 K ER0'],
  "shh": ['SH'],
  "shi": ['SH IY1'],
  "shia": ['SH IY1 AH0'],
  "shiah": ['SH IY1 AH0'],
  "shias": ['SH IY1 AH0 Z'],
  "shibanna": ['SH IH0 B AE1 N AH0'],
  "shibata": ['SH IY0 B AA1 T AH0'],
  "shibboleth": ['SH IH1 B AH0 L EH2 TH'],
  "shibley": ['SH IH1 B L IY0'],
  "shick": ['SH IH1 K'],
  "shicoff": ['SH IH1 K AO2 F'],
  "shideler": ['SH IH1 D AH0 L ER0'],
  "shidler": ['SH AY1 D AH0 L ER0', 'SH AY1 D L ER0'],
  "shied": ['SH AY1 D'],
  "shieh": ['SH IY1'],
  "shiel": ['SH IY1 L'],
  "shiela": ['SH AY1 L AH0'],
  "shield": ['SH IY1 L D'],
  "shield's": ['SH IY1 L D Z'],
  "shielded": ['SH IY1 L D IH0 D'],
  "shielding": ['SH IY1 L D IH0 NG'],
  "shields": ['SH IY1 L D Z'],
  "shiels": ['SH IY1 L Z'],
  "shier": ['SH AY1 ER0'],
  "shies": ['SH AY1 Z'],
  "shiff": ['SH IH1 F'],
  "shiffer": ['SH IH1 F ER0'],
  "shiffler": ['SH IH1 F L ER0'],
  "shifflet": ['SH IH1 F L IH0 T'],
  "shifflett": ['SH IH1 F L IH0 T'],
  "shiffman": ['SH IH1 F M AH0 N'],
  "shiflet": ['SH IH1 F L IH0 T'],
  "shiflett": ['SH IH1 F L IH0 T'],
  "shifrin": ['SH IH1 F R IH0 N'],
  "shift": ['SH IH1 F T'],
  "shifted": ['SH IH1 F T IH0 D'],
  "shifter": ['SH IH1 F T ER0'],
  "shifting": ['SH IH1 F T IH0 NG'],
  "shiftless": ['SH IH1 F T L IH0 S'],
  "shifts": ['SH IH1 F T S'],
  "shifty": ['SH IH1 F T IY0'],
  "shigeki": ['SH IH0 G EY1 K IY2'],
  "shigekuni": ['SH IY2 G IH0 K UW1 N IY2'],
  "shigeo": ['SH IH0 G EY1 OW2'],
  "shigeru": ['SH IH0 G EY1 R UW2'],
  "shigley": ['SH IH1 G L IY0'],
  "shih": ['SH IY1'],
  "shihan": ['SH IY1 HH AA2 N'],
  "shiina": ['SH IY1 N AH0'],
  "shiite": ['SH IY1 AY2 T'],
  "shiites": ['SH IY1 AY2 T S'],
  "shiley": ['SH IH1 L IY0'],
  "shill": ['SH IH1 L'],
  "shiller": ['SH IH1 L ER0'],
  "shilling": ['SH IH1 L IH0 NG'],
  "shillingburg": ['SH IH1 L IH0 NG B ER0 G'],
  "shillinger": ['SH IH1 L IH0 N JH ER0'],
  "shillinglaw": ['SH IH1 L IH0 NG L AO2'],
  "shillings": ['SH IH1 L IH0 NG Z'],
  "shillington": ['SH IH1 L IH0 NG T AH0 N'],
  "shiloh": ['SH AY1 L OW0'],
  "shilts": ['SH IH1 L T S'],
  "shim": ['SH IH1 M'],
  "shima": ['SH IY1 M AH0'],
  "shimabukuro": ['SH IY0 M AA0 B UW0 K UH1 R OW0'],
  "shimada": ['SH IY0 M AA1 D AH0'],
  "shimbun": ['SH IH1 M B AH2 N', 'SH IH1 M B UW2 N'],
  "shimek": ['SH IH1 M IH0 K'],
  "shimel": ['SH IH1 M AH0 L'],
  "shimer": ['SH AY1 M ER0'],
  "shimizu": ['SH IH0 M IY1 Z UW0'],
  "shimko": ['SH IH1 M K OW0'],
  "shimkus": ['SH IH1 M K AH0 S'],
  "shimmel": ['SH IH1 M AH0 L'],
  "shimmer": ['SH IH1 M ER0'],
  "shimmered": ['SH IH1 M ER0 D'],
  "shimmering": ['SH IH1 M ER0 IH0 NG'],
  "shimmerlik": ['SH IH1 M ER0 L IH0 K'],
  "shimmers": ['SH IH1 M ER0 Z'],
  "shimmin": ['SH IH1 M IH0 N'],
  "shimmy": ['SH IH1 M IY0'],
  "shimoda": ['SH IH0 M OW1 D AH0'],
  "shimoga": ['SH IH0 M OW1 G AH0'],
  "shimokawa": ['SH IH2 M OW0 K AA1 W AH0'],
  "shimon": ['SH IY1 M OW0 N', 'SH IY1 M AH0 N'],
  "shimone": ['SH IY1 M OW0 N'],
  "shimp": ['SH IH1 M P'],
  "shin": ['SH IH1 N'],
  "shinall": ['SH IH1 N AH0 L'],
  "shinault": ['SH IH1 N AW0 L T'],
  "shinbein": ['SH IH1 N B AY2 N'],
  "shinbone": ['SH IH1 N B OW2 N'],
  "shindig": ['SH IH1 N D IH0 G'],
  "shindle": ['SH IH1 N D AH0 L'],
  "shindledecker": ['SH IH1 N D AH0 L D IH0 K ER0'],
  "shindler": ['SH IH1 N D L ER0'],
  "shindler's": ['SH IH1 N D L ER0 Z'],
  "shindou": ['SH IH1 N D OW2'],
  "shine": ['SH AY1 N'],
  "shined": ['SH AY1 N D'],
  "shiner": ['SH AY1 N ER0'],
  "shines": ['SH AY1 N Z'],
  "shing": ['SH IH1 NG'],
  "shingle": ['SH IH1 NG G AH0 L'],
  "shingledecker": ['SH IH1 NG G AH0 L D IH0 K ER0'],
  "shingler": ['SH IH1 NG L ER0'],
  "shingles": ['SH IH1 NG G AH0 L Z'],
  "shingleton": ['SH IH1 NG G AH0 L T AH0 N'],
  "shinichi": ['SH IH0 N IY1 CH IY0'],
  "shining": ['SH AY1 N IH0 NG'],
  "shinkle": ['SH IH1 NG K AH0 L'],
  "shinko": ['SH IH1 NG K OW0'],
  "shinn": ['SH IH1 N'],
  "shinners": ['SH IH1 N ER0 Z'],
  "shinnick": ['SH IH1 N IH0 K'],
  "shinri": ['SH IH1 N R IY0'],
  "shinrikyo": ['SH IH0 N R IY1 K Y OW0'],
  "shinsei": ['SH IH0 N S EY1'],
  "shinsky": ['SH IH1 N S K IY0'],
  "shintaro": ['SH IH0 N T AA1 R OW0'],
  "shinto": ['SH IH1 N T OW2'],
  "shinwa": ['SH IH1 N W AH0'],
  "shinxiaku": ['SH IH2 N CH Y AA1 K UW0'],
  "shiny": ['SH AY1 N IY0'],
  "shinyuka": ['SH IH2 N Y UW1 K AH0'],
  "shiong": ['SH IY1 AO0 NG'],
  "shionogi": ['SH IY2 AH0 N OW1 G IY0'],
  "ship": ['SH IH1 P'],
  "ship's": ['SH IH1 P S'],
  "shipboard": ['SH IH1 P B AO2 R D'],
  "shipbuilder": ['SH IH1 P B IH2 L D ER0'],
  "shipbuilders": ['SH IH1 P B IH2 L D ER0 Z'],
  "shipbuilding": ['SH IH1 P B IH2 L D IH0 NG'],
  "shipbuildings": ['SH IH1 P B IH2 L D IH0 NG Z'],
  "shipe": ['SH AY1 P'],
  "shipes": ['SH AY1 P S'],
  "shipholding": ['SH IH1 P HH OW2 L D IH0 NG'],
  "shiplett": ['SH IH1 P L IH0 T'],
  "shipley": ['SH IH1 P L IY0'],
  "shipload": ['SH IH1 P L OW2 D'],
  "shiploads": ['SH IH1 P L OW2 D Z'],
  "shipman": ['SH IH1 P M AH0 N'],
  "shipmate": ['SH IH1 P M EY2 T'],
  "shipmates": ['SH IH1 P M EY2 T S'],
  "shipment": ['SH IH1 P M AH0 N T'],
  "shipments": ['SH IH1 P M AH0 N T S'],
  "shipowner": ['SH IH1 P OW2 N ER0'],
  "shipowners": ['SH IH1 P OW2 N ER0 Z'],
  "shipp": ['SH IH1 P'],
  "shipp's": ['SH IH1 P S'],
  "shipped": ['SH IH1 P T'],
  "shippee": ['SH IH1 P IY1'],
  "shipper": ['SH IH1 P ER0'],
  "shipper's": ['SH IH1 P ER0 Z'],
  "shippers": ['SH IH1 P ER0 Z'],
  "shippey": ['SH IH1 P IY0'],
  "shipping": ['SH IH1 P IH0 NG'],
  "shippingport": ['SH IH1 P IH0 NG P AO2 R T'],
  "shipps": ['SH IH1 P S'],
  "shippy": ['SH IH1 P IY0'],
  "ships": ['SH IH1 P S'],
  "ships'": ['SH IH1 P S'],
  "shipshape": ['SH IH1 P SH EY2 P'],
  "shipton": ['SH IH1 P T AH0 N'],
  "shipwash": ['SH IH1 P W AA2 SH'],
  "shipwreck": ['SH IH1 P R EH0 K'],
  "shipwright": ['SH IH1 P R AY2 T'],
  "shipwrights": ['SH IH1 P R AY2 T S'],
  "shipyard": ['SH IH1 P Y AA2 R D'],
  "shipyard's": ['SH IH1 P Y AA2 R D Z'],
  "shipyards": ['SH IH1 P Y AA2 R D Z'],
  "shir": ['SH ER1'],
  "shira": ['SH IH1 R AH0'],
  "shirah": ['SH IH1 R AH0'],
  "shiraishi": ['SH IH0 R AA0 IY1 SH IY0'],
  "shirazi": ['SH IH0 R AA1 Z IY0'],
  "shire": ['SH AY1 R'],
  "shirell": ['SH IH0 R EH1 L'],
  "shirelle": ['SH IH0 R EH1 L'],
  "shireman": ['SH IH0 R EY1 M AH0 N'],
  "shiremanstown": ['SH AY1 R M AH0 N Z T AW2 N'],
  "shirer": ['SH AY1 ER0 R'],
  "shires": ['SH AY1 R Z'],
  "shirey": ['SH AY1 R IY0'],
  "shirin": ['SH IH1 R AH0 N'],
  "shirk": ['SH ER1 K'],
  "shirked": ['SH ER1 K T'],
  "shirkey": ['SH ER1 K IY0'],
  "shirking": ['SH ER1 K IH0 NG'],
  "shirl": ['SH ER1 L'],
  "shirlee": ['SH ER1 L IY0'],
  "shirleen": ['SH ER0 L IY1 N'],
  "shirlene": ['SH ER1 L IY0 N'],
  "shirley": ['SH ER1 L IY0'],
  "shirley's": ['SH ER1 L IY0 Z'],
  "shirlie": ['SH ER1 L IY0'],
  "shiroma": ['SH IH0 R OW1 M AH0'],
  "shirone": ['SH IH0 R OW1 N'],
  "shirr": ['SH ER1'],
  "shirrell": ['SH ER2 EH1 L'],
  "shirt": ['SH ER1 T'],
  "shirted": ['SH ER1 T IH0 D'],
  "shirting": ['SH ER1 T IH0 NG'],
  "shirts": ['SH ER1 T S'],
  "shirtsleeve": ['SH ER1 T S L IY2 V'],
  "shiseido": ['SH IH0 S EY1 D OW0'],
  "shishido": ['SH IY0 SH IY1 D OW0'],
  "shisler": ['SH IH1 S AH0 L ER0', 'SH IH1 S L ER0'],
  "shissler": ['SH IH1 S L ER0'],
  "shit": ['SH IH1 T'],
  "shit's": ['SH IH1 T S'],
  "shithead": ['SH IH1 T HH EH2 D'],
  "shithole": ['SH IH1 T HH OW2 L'],
  "shitila": ['SH AH0 T IH1 L AH0'],
  "shitload": ['SH IH1 T L OW2 D'],
  "shits": ['SH IH1 T S'],
  "shitstorm": ['SH IH1 T S T AO2 R M'],
  "shitting": ['SH IH1 T IH0 NG'],
  "shitty": ['SH IH1 T IY2'],
  "shiu": ['SH UW1'],
  "shiva": ['SH IY1 V AH0'],
  "shive": ['SH AY1 V'],
  "shiveley": ['SH IH1 V IH0 L IY0', 'SH IH1 V L IY0'],
  "shively": ['SH AY1 V L IY0'],
  "shiver": ['SH IH1 V ER0'],
  "shiverdecker": ['SH IH1 V ER0 D IH0 K ER0'],
  "shivered": ['SH IH1 V ER0 D'],
  "shivering": ['SH IH1 V ER0 IH0 NG'],
  "shivers": ['SH IH1 V ER0 Z'],
  "shives": ['SH AY1 V Z'],
  "shivley": ['SH IH1 V L IY0'],
  "shiyuan": ['SH IY1 UW0 AA0 N'],
  "shizuka": ['SH IH0 Z UW1 K AH0'],
  "shizuoka": ['SH IY0 Z W OW1 K AH0'],
  "shlaes": ['SH L EY1 Z'],
  "shlenker": ['SH L EH1 NG K ER0'],
  "shlobidan": ['SH L OW0 B IH1 D AH0 N'],
  "shmooze": ['SH M UW1 Z'],
  "shmoozer": ['SH M UW1 Z ER0'],
  "shmoozing": ['SH M UW1 Z IH0 NG'],
  "shmyrev": ['SH M IY1 R EH2 V'],
  "shoaf": ['SH OW1 F'],
  "shoaff": ['SH OW1 F'],
  "shoals": ['SH OW1 L Z'],
  "shobana": ['SH OW2 B AA1 N AH0'],
  "shobana's": ['SH OW2 B AA1 N AH0 Z'],
  "shobanna": ['SH OW2 B AA1 N AH0'],
  "shobanna's": ['SH OW2 B AA1 N AH0 Z'],
  "shobe": ['SH OW1 B'],
  "shober": ['SH OW1 B ER0'],
  "shoberg": ['SH OW1 B ER0 G'],
  "shobert": ['SH AA1 B ER0 T'],
  "shock": ['SH AA1 K'],
  "shocked": ['SH AA1 K T'],
  "shocker": ['SH AA1 K ER0'],
  "shockey": ['SH AA1 K IY0'],
  "shocking": ['SH AA1 K IH0 NG'],
  "shockingly": ['SH AA1 K IH0 NG L IY0'],
  "shockley": ['SH AA1 K L IY0'],
  "shocks": ['SH AA1 K S'],
  "shockwave": ['SH AA1 K W EY2 V'],
  "shockwaves": ['SH AA1 K W EY2 V Z'],
  "shod": ['SH AA1 D'],
  "shoddy": ['SH AA1 D IY0'],
  "shoe": ['SH UW1'],
  "shoe's": ['SH UW1 Z'],
  "shoebox": ['SH OW1 B AA0 K S'],
  "shoehorn": ['SH UW1 HH AO2 R N'],
  "shoehorned": ['SH UW1 HH AO0 R N D'],
  "shoelace": ['SH UW1 L EY2 S'],
  "shoelaces": ['SH UW1 L EY2 S AH0 Z'],
  "shoemake": ['SH UW1 M EY2 K'],
  "shoemaker": ['SH UW1 M EY2 K ER0'],
  "shoemakers": ['SH UW1 M EY2 K ER0 Z'],
  "shoemate": ['SH UW1 M EY2 T'],
  "shoen": ['SH UW1 N'],
  "shoeprint": ['SH UW1 P R IH2 N T'],
  "shoeprints": ['SH UW1 P R IH2 N T S'],
  "shoes": ['SH UW1 Z'],
  "shoeshine": ['SH UW1 SH AY2 N'],
  "shoestring": ['SH UW1 S T R IH2 NG'],
  "shoff": ['SH AO1 F'],
  "shoffner": ['SH AO1 F N ER0'],
  "shofner": ['SH AA1 F N ER0'],
  "shogren": ['SH AA1 G R EH0 N'],
  "shogun": ['SH OW1 G AH0 N'],
  "shohat": ['SH OW1 HH AE0 T'],
  "shoichi": ['SH OW0 IY1 CH IY0'],
  "shoichiro": ['SH OW2 IH0 CH IH1 R OW0'],
  "shoji": ['SH OW1 JH IY0'],
  "shokhin": ['SH OW1 K IH2 N'],
  "shoko": ['SH OW1 K OW0'],
  "sholar": ['SH OW1 L ER0'],
  "sholem": ['SH OW1 L AH0 M'],
  "sholes": ['SH OW1 L Z'],
  "sholl": ['SH AA1 L'],
  "shollenberger": ['SH AA1 L AH0 N B ER0 G ER0'],
  "sholly": ['SH AA1 L IY0'],
  "sholtis": ['SH OW1 L T IH0 S'],
  "sholto": ['SH OW1 L T OW0'],
  "shomaker": ['SH OW1 M EY2 K ER0'],
  "shomo": ['SH OW1 M OW0'],
  "shon": ['SH AA1 N'],
  "shona": ['SH OW1 N AH0'],
  "shone": ['SH OW1 N'],
  "shoney": ['SH OW1 N IY0'],
  "shoney's": ['SH OW1 N IY0 Z'],
  "shonk": ['SH AA1 NG K'],
  "shonka": ['SH AA1 NG K AH0'],
  "shonkwiler": ['SH AA1 NG K W AY2 L ER0'],
  "shontz": ['SH AA1 N T S'],
  "shoo": ['SH UW1'],
  "shoob": ['SH UW1 B'],
  "shooed": ['SH UW1 D'],
  "shoofly": ['SH UW1 F L AY2'],
  "shook": ['SH UH1 K'],
  "shoop": ['SH UW1 P'],
  "shoopman": ['SH UW1 P M AH0 N'],
  "shooshan": ['SH UW1 SH AH0 N'],
  "shoot": ['SH UW1 T'],
  "shootdown": ['SH UW1 T D AW2 N'],
  "shooter": ['SH UW1 T ER0'],
  "shooter's": ['SH UW1 T ER0 Z'],
  "shooters": ['SH UW1 T ER0 Z'],
  "shootin'": ['SH UW1 T IH0 N'],
  "shooting": ['SH UW1 T IH0 NG'],
  "shootings": ['SH UW1 T IH0 NG Z'],
  "shootout": ['SH UW1 T AW0 T'],
  "shootouts": ['SH UW1 T AW0 T S'],
  "shoots": ['SH UW1 T S'],
  "shop": ['SH AA1 P'],
  "shop's": ['SH AA1 P S'],
  "shope": ['SH OW1 P'],
  "shopkeeper": ['SH AA1 P K IY2 P ER0'],
  "shopkeeper's": ['SH AA1 P K IY2 P ER0 Z'],
  "shopkeepers": ['SH AA1 P K IY2 P ER0 Z'],
  "shopko": ['SH AA1 P K OW0'],
  "shopkorn": ['SH AA1 P K AO2 R N'],
  "shoplift": ['SH AA1 P L IH2 F T'],
  "shoplifter": ['SH AA1 P L IH2 F T ER0'],
  "shoplifters": ['SH AA1 P L IH2 F T ER0 Z'],
  "shoplifting": ['SH AA1 P L IH2 F T IH0 NG'],
  "shoplifts": ['SH AA1 P L IH2 F T S'],
  "shoppe": ['SH AA1 P'],
  "shopped": ['SH AA1 P T'],
  "shopper": ['SH AA1 P ER0'],
  "shopper's": ['SH AA1 P ER0 Z'],
  "shoppers": ['SH AA1 P ER0 Z'],
  "shoppers'": ['SH AA1 P ER0 Z'],
  "shoppes": ['SH AA1 P S'],
  "shopping": ['SH AA1 P IH0 NG'],
  "shopping's": ['SH AA1 P IH0 NG Z'],
  "shops": ['SH AA1 P S'],
  "shoptaw": ['SH AA1 P T AO0'],
  "shopwell": ['SH AA1 P W EH2 L'],
  "shopworn": ['SH AA1 P W AO2 R N'],
  "shor": ['SH AO1 R'],
  "shorb": ['SH AO1 R B'],
  "shore": ['SH AO1 R'],
  "shore's": ['SH AO1 R Z'],
  "shorebird": ['SH AO1 R B ER2 D'],
  "shored": ['SH AO1 R D'],
  "shoreham": ['SH AO1 R AH0 M', 'SH AO1 R HH AE2 M'],
  "shoreline": ['SH AO1 R L AY2 N'],
  "shorenstein": ['SH AO1 R AH0 N S T IY0 N', 'SH AO1 R AH0 N S T AY0 N'],
  "shores": ['SH AO1 R Z'],
  "shoreward": ['SH AO1 R W ER0 D'],
  "shorey": ['SH AO1 R IY0'],
  "shorin": ['SH AO1 R IH0 N'],
  "shoring": ['SH AO1 R IH0 NG'],
  "shorkey": ['SH AO1 R K IY0'],
  "shorn": ['SH AO1 R N'],
  "shorr": ['SH AO1 R'],
  "short": ['SH AO1 R T'],
  "short-lived": ['SH AO1 R T L AY1 V D'],
  "short-sighted": ['SH AO2 R T S AY1 T IH0 D'],
  "short-term": ['SH AO2 R T T ER1 M'],
  "short-winded": ['SH AO1 R T W IH1 N D IH0 D'],
  "shortage": ['SH AO1 R T AH0 JH', 'SH AO1 R T IH0 JH'],
  "shortages": ['SH AO1 R T AH0 JH AH0 Z', 'SH AO1 R T IH0 JH IH0 Z'],
  "shortall": ['SH AO1 R T AH0 L'],
  "shortcake": ['SH AO1 R T K EY2 K'],
  "shortchange": ['SH AO2 R T CH EY1 N JH'],
  "shortchanged": ['SH AO2 R T CH EY1 N JH D'],
  "shortchanging": ['SH AO2 R T CH EY1 N JH IH0 NG'],
  "shortcoming": ['SH AO1 R T K AH2 M IH0 NG'],
  "shortcomings": ['SH AO1 R T K AH2 M IH0 NG Z'],
  "shortcrust": ['SH AO1 R T K R AH0 S T'],
  "shortcut": ['SH AO1 R T K AH2 T'],
  "shortcuts": ['SH AO1 R T K AH2 T S'],
  "shorted": ['SH AO1 R T IH0 D'],
  "shortell": ['SH AO0 R T EH1 L'],
  "shorten": ['SH AO1 R T AH0 N'],
  "shortened": ['SH AO1 R T AH0 N D'],
  "shortening": ['SH AO1 R T AH0 N IH0 NG', 'SH AO1 R T N IH0 NG'],
  "shortens": ['SH AO1 R T AH0 N Z'],
  "shorter": ['SH AO1 R T ER0'],
  "shorter's": ['SH AO1 R T ER0 Z'],
  "shortest": ['SH AO1 R T IH0 S T'],
  "shortfall": ['SH AO1 R T F AO2 L'],
  "shortfalls": ['SH AO1 R T F AO2 L Z'],
  "shortgrass": ['SH AO1 R T G R AE2 S'],
  "shorthair": ['SH AO1 R T HH EH2 R'],
  "shorthaired": ['SH AO1 R T HH EH2 R D'],
  "shorthand": ['SH AO1 R T HH AE2 N D'],
  "shorting": ['SH AO1 R T IH0 NG'],
  "shortlived": ['SH AO1 R T L IH1 V D', 'SH AO1 R T L AY1 V D'],
  "shortly": ['SH AO1 R T L IY0'],
  "shortness": ['SH AO1 R T N AH0 S'],
  "shortridge": ['SH AO1 R T R IH0 JH'],
  "shorts": ['SH AO1 R T S'],
  "shorts'": ['SH AO1 R T S'],
  "shortsighted": ['SH AO1 R T S AY1 T IH0 D'],
  "shortsightedness": ['SH AO1 R T S AY1 T IH0 D N IH0 S'],
  "shortstop": ['SH AO1 R T S T AA2 P'],
  "shortt": ['SH AO1 R T'],
  "shortterm": ['SH AO1 R T T ER2 M'],
  "shortwave": ['SH AO1 R T W EY1 V'],
  "shorty": ['SH AO1 R T IY0'],
  "shortz": ['SH AO1 R T S'],
  "shortz'": ['SH AO1 R T S'],
  "shortz's": ['SH AO1 R T S IH0 Z'],
  "shosh": ['SH OW1 SH'],
  "shoshone": ['SH OW0 SH OW1 N IY0'],
  "shostak": ['SH AA1 S T AH0 K'],
  "shostakovich": ['SH AO2 S T AH0 K OW1 V IH0 CH'],
  "shot": ['SH AA1 T'],
  "shotgun": ['SH AA1 T G AH2 N'],
  "shotguns": ['SH AA1 T G AH2 N Z'],
  "shots": ['SH AA1 T S'],
  "shott": ['SH AA1 T'],
  "shotts": ['SH AA1 T S'],
  "shotwell": ['SH AA1 T W EH2 L'],
  "shougang": ['SH AW1 G AA1 NG'],
  "shough": ['SH AW1'],
  "should": ['SH UH1 D'],
  "should've": ['SH UH1 D AH0 V'],
  "shoulder": ['SH OW1 L D ER0'],
  "shouldered": ['SH OW1 L D ER0 D'],
  "shouldering": ['SH OW1 L D ER0 IH0 NG'],
  "shoulders": ['SH OW1 L D ER0 Z'],
  "shouldn't": ['SH UH1 D AH0 N T'],
  "shouli": ['SH UW1 L IY0'],
  "shoults": ['SH OW1 L T S'],
  "shoultz": ['SH OW1 L T S'],
  "shoumaker": ['SH UW1 M EY2 K ER0'],
  "shoun": ['SH AW1 N'],
  "shoup": ['SH UW1 P'],
  "shoupe": ['SH UW1 P'],
  "shouse": ['S HH AW1 S'],
  "shout": ['SH AW1 T'],
  "shouted": ['SH AW1 T IH0 D'],
  "shouting": ['SH AW1 T IH0 NG'],
  "shouts": ['SH AW1 T S'],
  "shoval": ['SH OW1 V AE0 L'],
  "shove": ['SH AH1 V'],
  "shoved": ['SH AH1 V D'],
  "shovel": ['SH AH1 V AH0 L'],
  "shoveled": ['SH AH1 V AH0 L D'],
  "shoveling": ['SH AH1 V L IH0 NG'],
  "shovels": ['SH AH1 V AH0 L Z'],
  "shover": ['SH AH1 V ER0'],
  "shoves": ['SH AH1 V Z'],
  "shoving": ['SH AH1 V IH0 NG'],
  "shovlin": ['SH AA1 V L IH0 N'],
  "show": ['SH OW1'],
  "show's": ['SH OW1 Z'],
  "showa": ['SH OW1 AH0'],
  "showalter": ['SH OW1 AH0 L T ER0'],
  "showbiz": ['SH OW1 B IH0 Z'],
  "showboat": ['SH OW1 B OW2 T'],
  "showboat's": ['SH OW1 B OW2 T S'],
  "showbuzz": ['SH OW1 B AH2 Z'],
  "showcase": ['SH OW1 K EY2 S'],
  "showcased": ['SH OW1 K EY2 S T'],
  "showcases": ['SH OW1 K EY2 S IH0 Z'],
  "showcasing": ['SH OW1 K EY0 S IH0 NG'],
  "showdown": ['SH OW1 D AW2 N'],
  "showdowns": ['SH OW1 D AW2 N Z'],
  "showed": ['SH OW1 D'],
  "showell": ['SH AA1 W EH0 L'],
  "shower": ['SH AW1 ER0'],
  "showered": ['SH AW1 ER0 D'],
  "showering": ['SH AW1 ER0 IH0 NG'],
  "showers": ['SH AW1 ER0 Z'],
  "showgirl": ['SH OW1 G ER2 L'],
  "showgirls": ['SH OW1 G ER2 L Z'],
  "showiest": ['SH OW1 IY0 AH0 S T'],
  "showing": ['SH OW1 IH0 NG'],
  "showings": ['SH OW1 IH0 NG Z'],
  "showman": ['SH OW1 M AH0 N'],
  "showmanship": ['SH OW1 M AH0 N SH IH2 P'],
  "shown": ['SH OW1 N'],
  "showpiece": ['SH OW1 P IY2 S'],
  "showplace": ['SH OW1 P L EY2 S'],
  "showroom": ['SH OW1 R UH2 M', 'SH OW1 R UW2 M'],
  "showrooms": ['SH OW1 R UW2 M Z'],
  "shows": ['SH OW1 Z'],
  "shows'": ['SH OW1 Z'],
  "showscan": ['SH OW1 S K AE2 N'],
  "showtime": ['SH OW1 T AY2 M'],
  "showtime's": ['SH OW1 T AY2 M Z'],
  "showy": ['SH OW1 IY0'],
  "shrader": ['SH R EY1 D ER0'],
  "shrake": ['SH R EY1 K'],
  "shramek": ['SH R AE1 M IH0 K'],
  "shrank": ['SH R AE1 NG K'],
  "shrapnel": ['SH R AE1 P N AH0 L'],
  "shreck": ['SH R EH1 K'],
  "shred": ['SH R EH1 D'],
  "shredded": ['SH R EH1 D IH0 D'],
  "shredder": ['SH R EH1 D ER0'],
  "shredders": ['SH R EH1 D ER0 Z'],
  "shredding": ['SH R EH1 D IH0 NG'],
  "shreds": ['SH R EH1 D Z'],
  "shreeve": ['SH R IY1 V'],
  "shreffler": ['SH R EH1 F L ER0'],
  "shreiber": ['SH R AY1 B ER0'],
  "shreiner": ['SH R AY1 N ER0'],
  "shrek": ['SH R EH1 K'],
  "shreve": ['SH R IY1 V'],
  "shreveport": ['SH R IY1 V P AO2 R T'],
  "shreves": ['SH R IY1 V Z'],
  "shrewd": ['SH R UW1 D'],
  "shrewdest": ['SH R UW1 D AH0 S T'],
  "shrewdly": ['SH R UW1 D L IY0'],
  "shrewdness": ['SH R UW1 D N AH0 S'],
  "shrewsberry": ['SH R UW1 Z B EH2 R IY0'],
  "shrewsbury": ['SH R UW1 Z B EH2 R IY0'],
  "shri": ['SH R IY1'],
  "shriber": ['SH R AY1 B ER0'],
  "shribman": ['SH R IH1 B M AH0 N'],
  "shrider": ['SH R AY1 D ER0'],
  "shriek": ['SH R IY1 K'],
  "shrieked": ['SH R IY1 K T'],
  "shrieking": ['SH R IY1 K IH0 NG'],
  "shrieks": ['SH R IY1 K S'],
  "shrift": ['SH R IH1 F T'],
  "shrikant": ['SH R IY2 K AA1 N T'],
  "shrikelike": ['SH R AY1 K L AY2 K'],
  "shrill": ['SH R IH1 L'],
  "shrimp": ['SH R IH1 M P'],
  "shrimper": ['SH R IH1 M P ER0'],
  "shrimpers": ['SH R IH1 M P ER0 Z'],
  "shrine": ['SH R AY1 N'],
  "shriner": ['SH R AY1 N ER0'],
  "shriners": ['SH R AY1 N ER0 Z'],
  "shrines": ['SH R AY1 N Z'],
  "shrink": ['SH R IH1 NG K'],
  "shrinkage": ['SH R IH1 NG K IH0 JH'],
  "shrinking": ['SH R IH1 NG K IH0 NG'],
  "shrinks": ['SH R IH1 NG K S'],
  "shrivel": ['SH R IH1 V AH0 L'],
  "shriveled": ['SH R IH1 V AH0 L D'],
  "shriveling": ['SH R IH1 V AH0 L IH0 NG', 'SH R IH1 V L IH0 NG'],
  "shriver": ['SH R AY1 V ER0'],
  "shrock": ['SH R AA1 K'],
  "shrode": ['SH R OW1 D'],
  "shroff": ['SH R AO1 F'],
  "shrontz": ['SH R AA1 N T S'],
  "shropshire": ['SH R AA1 P SH AY2 R'],
  "shroud": ['SH R AW1 D'],
  "shrouded": ['SH R AW1 D IH0 D'],
  "shrouding": ['SH R AW1 D IH0 NG'],
  "shrouds": ['SH R AW1 D Z'],
  "shrout": ['SH R AW1 T'],
  "shroyer": ['SH R OY1 ER0'],
  "shrub": ['SH R AH1 B'],
  "shrubbery": ['SH R AH1 B ER0 IY0'],
  "shrubby": ['SH R AH1 B IY0'],
  "shrublike": ['SH R AH1 B L AY2 K'],
  "shrubs": ['SH R AH1 B Z'],
  "shrug": ['SH R AH1 G'],
  "shrugged": ['SH R AH1 G D'],
  "shrugging": ['SH R AH1 G IH0 NG'],
  "shrugs": ['SH R AH1 G Z'],
  "shrum": ['SH R AH1 M'],
  "shrunk": ['SH R AH1 NG K'],
  "shrunken": ['SH R AH1 NG K AH0 N'],
  "shryock": ['SH R AY1 AA0 K'],
  "shtick": ['SH T IH1 K'],
  "shtyrov": ['SH T IY1 R AO0 V'],
  "shu": ['SH UW1'],
  "shuart": ['SH UW1 ER0 T'],
  "shubert": ['SH UW1 B ER0 T'],
  "shubin": ['SH UW1 B IH0 N'],
  "shuchman": ['SH AH1 K M AH0 N'],
  "shuchman's": ['SH AH1 K M AH0 N Z'],
  "shuck": ['SH AH1 K'],
  "shucked": ['SH AH1 K T'],
  "shucking": ['SH AH1 K IH0 NG'],
  "shucks": ['SH AH1 K S'],
  "shuda": ['SH UW1 D AH0'],
  "shudder": ['SH AH1 D ER0'],
  "shuddered": ['SH AH1 D ER0 D'],
  "shuddering": ['SH AH1 D ER0 IH0 NG'],
  "shudders": ['SH AH1 D ER0 Z'],
  "shue": ['SH UW1'],
  "shuey": ['SH UW1 IY0'],
  "shufelt": ['SH UW1 F EH0 L T'],
  "shuff": ['SH AH1 F'],
  "shuffield": ['SH AH1 F IY2 L D'],
  "shuffle": ['SH AH1 F AH0 L'],
  "shuffled": ['SH AH1 F AH0 L D'],
  "shuffler": ['SH AH1 F AH0 L ER0', 'SH AH1 F L ER0'],
  "shufflers": ['SH AH1 F AH0 L ER0 Z', 'SH AH1 F L ER0 Z'],
  "shuffles": ['SH AH1 F AH0 L Z'],
  "shuffling": ['SH AH1 F L IH0 NG', 'SH AH1 F UH1 L IH0 NG'],
  "shuford": ['SH UW1 F ER0 D'],
  "shufro": ['SH AH1 F R OW0'],
  "shugars": ['SH UW1 G ER0 Z'],
  "shugart": ['SH AH1 G AA0 R T'],
  "shughart": ['SH AH1 G AA2 R T'],
  "shugrue": ['SH AH1 G R UW0'],
  "shui": ['SH UW1 IY0'],
  "shukla": ['SH AH1 K L AH0'],
  "shukri": ['SH UW1 K R IY0'],
  "shula": ['SH UW1 L AH0'],
  "shular": ['SH UW1 L ER0'],
  "shuldiner": ['SH UW1 L D IH0 N ER2'],
  "shuler": ['SH UW1 L ER0'],
  "shull": ['SH AH1 L'],
  "shulman": ['SH UH1 L M AH0 N'],
  "shultis": ['SH AH1 L T IH0 S'],
  "shults": ['SH AH1 L T S'],
  "shultz": ['SH UH1 L T S'],
  "shultz's": ['SH UH1 L T S IH1 Z'],
  "shum": ['SH AH1 M'],
  "shumake": ['SH UW1 M EY2 K'],
  "shumaker": ['SH UW1 M EY2 K ER0'],
  "shuman": ['SH UW1 M AH0 N'],
  "shumard": ['SH UW1 M ER0 D'],
  "shumate": ['SH UW1 M EY2 T'],
  "shumeet": ['SH UW0 M IY1 T'],
  "shumer": ['SH UW1 M ER0'],
  "shumpert": ['SH AH1 M P ER0 T'],
  "shumsky": ['SH AH1 M S K IY0'],
  "shumway": ['SH AH1 M W EY2'],
  "shun": ['SH AH1 N'],
  "shunk": ['SH AH1 NG K'],
  "shunned": ['SH AH1 N D'],
  "shunning": ['SH AH1 N IH0 NG'],
  "shuns": ['SH AH1 N Z'],
  "shunt": ['SH AH1 N T'],
  "shunted": ['SH AH1 N T IH0 D'],
  "shunting": ['SH AH1 N T IH0 NG'],
  "shunto": ['SH AH1 N T OW0'],
  "shunts": ['SH AH1 N T S'],
  "shupe": ['SH UW1 P'],
  "shupert": ['SH UW1 P ER0 T'],
  "shuping": ['SH UW1 P IH0 NG'],
  "shupp": ['SH AH1 P'],
  "shur": ['SH ER1'],
  "shure": ['SH UH1 R'],
  "shurgard": ['SH UH1 R G AA2 R D'],
  "shurley": ['SH ER1 L IY0'],
  "shurr": ['SH ER1'],
  "shurtleff": ['SH ER1 T L IH0 F'],
  "shurtliff": ['SH ER1 T L IH0 F'],
  "shurtz": ['SH ER1 T S'],
  "shuster": ['SH AH1 S T ER0'],
  "shusterman": ['SH AH1 S T ER0 M AH0 N'],
  "shut": ['SH AH1 T'],
  "shutdown": ['SH AH1 T D AW2 N'],
  "shutdowns": ['SH AH1 T D AW2 N Z'],
  "shute": ['SH UW1 T'],
  "shutes": ['SH UW1 T S'],
  "shutout": ['SH AH1 T AW2 T'],
  "shuts": ['SH AH1 T S'],
  "shutt": ['SH AH1 T'],
  "shutter": ['SH AH1 T ER0'],
  "shuttered": ['SH AH1 T ER0 D'],
  "shuttering": ['SH AH1 T ER0 IH0 NG'],
  "shutters": ['SH AH1 T ER0 Z'],
  "shutting": ['SH AH1 T IH0 NG'],
  "shuttle": ['SH AH1 T AH0 L'],
  "shuttle's": ['SH AH1 T AH0 L Z'],
  "shuttled": ['SH AH1 T AH0 L D'],
  "shuttles": ['SH AH1 T AH0 L Z'],
  "shuttlesworth": ['SH AH1 T AH0 L Z W ER2 TH'],
  "shuttleworth": ['SH AH1 T AH0 L W ER2 TH'],
  "shuttling": ['SH AH1 T AH0 L IH0 NG', 'SH AH1 T L IH0 NG'],
  "shutts": ['SH AH1 T S'],
  "shuwa": ['SH UW1 W AH0'],
  "shuwa's": ['SH UW1 W AH0 Z'],
  "shy": ['SH AY1'],
  "shying": ['SH AY1 IH0 NG'],
  "shyjan": ['SH AY1 JH AE2 N'],
  "shylock": ['SH AY1 L AA2 K'],
  "shyly": ['SH AY1 L IY0'],
  "shymanski": ['SH AH0 M AE1 N S K IY0'],
  "shyne": ['SH AY1 N'],
  "shyness": ['SH AY1 N AH0 S'],
  "shyster": ['SH AY1 S T ER0'],
  "shysters": ['SH AY1 S T ER0 Z'],
  "si": ['S IY1'],
  "siad": ['S AY1 AE0 D'],
  "siam": ['S AY0 AE1 M', 'S AY1 AE0 M'],
  "siamese": ['S AY2 AH0 M IY1 Z'],
  "sian": ['S IY2 AA1 N'],
  "siang": ['S Y AE1 NG', 'SH AE1 NG'],
  "siano": ['S IY0 AA1 N OW2'],
  "sias": ['ZH AO1 Z'],
  "sib": ['S IH1 B'],
  "sibbie": ['S IH1 B IY0'],
  "sibby": ['S IH1 B IY0'],
  "sibelle": ['S IH0 B EH1 L'],
  "siberia": ['S AY0 B IH1 R IY0 AH0'],
  "siberian": ['S AY0 B IH1 R IY0 AH0 N'],
  "sibert": ['S IH1 B ER0 T'],
  "sibil": ['S IH1 B AH0 L'],
  "sibilia": ['S IY0 B IY1 L IY0 AH0'],
  "sibilla": ['S IH0 B IH1 L AH0'],
  "sibille": ['S IH1 B IH0 L'],
  "sible": ['S AY1 B AH0 L'],
  "sibley": ['S IH1 B L IY0'],
  "sibling": ['S IH1 B L IH0 NG'],
  "siblings": ['S IH1 B L IH0 NG Z'],
  "sibson": ['S IH1 B S AH0 N'],
  "sibyl": ['S IH1 B AH0 L'],
  "sibyll": ['S IH1 B IH0 L'],
  "sic": ['S IH1 K'],
  "sica": ['S IY1 K AH0'],
  "sicard": ['S IH1 K ER0 D'],
  "sichel": ['S IH1 K AH0 L'],
  "sichuan": ['S IH2 CH W AA1 N'],
  "sicilia": ['S IH0 S IH1 L Y AH0'],
  "sicilian": ['S IH0 S IH1 L IY0 AH0 N'],
  "siciliano": ['S IY0 CH IY0 L IY0 AA1 N OW0'],
  "sicily": ['S IH1 S AH0 L IY0'],
  "sick": ['S IH1 K'],
  "sickel": ['S IH1 K AH0 L'],
  "sickels": ['S IH1 K AH0 L Z'],
  "sicken": ['S IH1 K AH0 N'],
  "sickened": ['S IH1 K AH0 N D'],
  "sickening": ['S IH1 K AH0 N IH0 NG', 'S IH1 K N IH0 NG'],
  "sicker": ['S IH1 K ER0'],
  "sickest": ['S IH1 K AH0 S T'],
  "sickinger": ['S IH1 K IH0 NG ER0'],
  "sickle": ['S IH1 K AH0 L'],
  "sickler": ['S IH1 K L ER0'],
  "sickles": ['S IH1 K AH0 L Z'],
  "sickly": ['S IH1 K L IY0'],
  "sickman": ['S IH1 K M AH0 N'],
  "sickness": ['S IH1 K N AH0 S'],
  "siconolfi": ['S IY0 K OW0 N OW1 L F IY0'],
  "sicotte": ['S IH0 K AO1 T'],
  "sid": ['S IH1 D'],
  "sid's": ['S IH1 D Z'],
  "sidak": ['S IH1 D AE0 K'],
  "siddall": ['S IH1 D AH0 L'],
  "siddeley": ['S IH1 D AH0 L IY0'],
  "siddell": ['S IH1 D AH0 L'],
  "sidden": ['S IH1 D AH0 N'],
  "siddens": ['S IH1 D AH0 N Z'],
  "siddhartha": ['S IH0 D AA1 R T AH2'],
  "siddig": ['S IH1 D IH0 G'],
  "siddiqi": ['S IY0 D IY1 K IY0'],
  "siddiqui": ['S IY0 D IY1 K W IY0'],
  "siddle": ['S IH1 D AH0 L'],
  "siddons": ['S IH1 D AH0 N Z'],
  "side": ['S AY1 D'],
  "side's": ['S AY1 D Z'],
  "side-by-side": ['S AY1 D B AY0 S AY1 D'],
  "sidearm": ['S AY1 D AA0 R M'],
  "sidearms": ['S AY1 D AA0 R M Z'],
  "sidebar": ['S AY1 D B AA2 R'],
  "sidebars": ['S AY1 D B AA2 R Z'],
  "sided": ['S AY1 D IH0 D'],
  "sidek": ['S AY1 D EH2 K'],
  "sidekick": ['S AY1 D K IH2 K'],
  "sideline": ['S AY1 D L AY2 N'],
  "sidelined": ['S AY1 D L AY2 N D'],
  "sidelines": ['S AY1 D L AY2 N Z'],
  "sidell": ['S AY1 D AH0 L'],
  "sideman": ['S AY1 D M AE2 N'],
  "sidener": ['S IH1 D IY0 N ER0'],
  "sider": ['S AY1 D ER0'],
  "siderca": ['S IH0 D ER1 K AH0'],
  "sideris": ['S IH1 D ER0 IH0 S'],
  "siderographer": ['S AY2 D ER0 AO1 G R AH0 F ER0'],
  "siderographers": ['S AY2 D ER0 AO1 G R AH0 F ER0 Z'],
  "siders": ['S AY1 D ER0 Z'],
  "siders'": ['S AY1 D ER0 Z'],
  "sides": ['S AY1 D Z'],
  "sides'": ['S AY1 D Z'],
  "sideshow": ['S AY1 D SH OW2'],
  "sideshows": ['S AY1 D SH OW2 Z'],
  "sidestep": ['S AY1 D S T EH2 P'],
  "sidestepped": ['S AY1 D S T EH2 P T'],
  "sidestepping": ['S AY1 D S T EH2 P IH0 NG'],
  "sidesteps": ['S AY1 D S T EH2 P S'],
  "sidestream": ['S AY1 D S T R IY2 M'],
  "sidetrack": ['S AY1 D T R AE2 K'],
  "sidetracked": ['S AY1 D T R AE2 K T'],
  "sidewalk": ['S AY1 D W AO2 K'],
  "sidewalks": ['S AY1 D W AO2 K S'],
  "sidewater": ['S AY1 D W AO2 T ER0'],
  "sideways": ['S AY1 D W EY2 Z'],
  "sidewinder": ['S AY1 D W AY2 N D ER0'],
  "sidewise": ['S AY1 D W AY2 Z'],
  "sidey": ['S AY1 D IY0'],
  "sidgraph": ['S IH1 D G R AE0 F'],
  "sidhu": ['S IH1 D HH UW0'],
  "sidi": ['S IH1 D IY0'],
  "siding": ['S AY1 D IH0 NG'],
  "sidle": ['S AY1 D AH0 L'],
  "sidled": ['S AY1 D AH0 L D'],
  "sidler": ['S AY1 D AH0 L ER0', 'S AY1 D L ER0'],
  "sidles": ['S AY1 D AH0 L Z'],
  "sidley": ['S IH1 D L IY0'],
  "sidling": ['S AY1 D AH0 L IH0 NG', 'S AY1 D L IH0 NG'],
  "sidman": ['S IH1 D M AH0 N'],
  "sidney": ['S IH1 D N IY0'],
  "sidon": ['S AY1 D AH0 N'],
  "sidonia": ['S IY0 D OW1 N IY0 AH0'],
  "sidor": ['S IH1 D ER0'],
  "sidoti": ['S IY0 D OW1 T IY0'],
  "sidra": ['S IH1 D R AH0'],
  "sids": ['S IH1 D Z'],
  "sidwell": ['S IH1 D W EH2 L'],
  "sie": ['S IY1'],
  "siebe": ['S IY1 B'],
  "siebel": ['S IY1 B AH0 L'],
  "siebels": ['S IY1 B AH0 L Z'],
  "sieben": ['S IY1 B AH0 N'],
  "siebenaler": ['S IY1 B IH0 N AH0 L ER0', 'S IY1 B IH0 N AA2 L ER0'],
  "sieber": ['S IY1 B ER0'],
  "siebers": ['S IY1 B ER0 Z'],
  "siebert": ['S IY1 B ER0 T'],
  "siebold": ['S IY1 B OW2 L D'],
  "siebrecht": ['S IY1 B R IH0 K T'],
  "sieck": ['S IY1 K'],
  "siecle": ['S IY1 K AH0 L'],
  "siedenburg": ['S IY1 D AH0 N B ER0 G'],
  "siedlecki": ['S IY0 D L EH1 T S K IY0'],
  "siedschlag": ['S IY1 D SH L AH0 G'],
  "siefert": ['S IY1 F ER0 T'],
  "siefert's": ['S IY1 F ER0 T S'],
  "siefken": ['S IY1 F K AH0 N'],
  "siefker": ['S IY1 F K ER0'],
  "sieg": ['S IY1 G'],
  "siegal": ['S IY1 G AH0 L'],
  "siegan": ['S IY1 G AH0 N'],
  "siegan's": ['S IY1 G AH0 N Z'],
  "siege": ['S IY1 JH'],
  "siegecraft": ['S IY1 JH K R AE2 F T'],
  "siegel": ['S IY1 G AH0 L'],
  "siegel's": ['S IY1 G AH0 L Z'],
  "siegell": ['S IY1 G AH0 L'],
  "siegelman": ['S IY1 G AH0 L M AH0 N'],
  "siegenthaler": ['S IY1 G IH0 N TH AH0 L ER0'],
  "sieger": ['S IY1 G ER0'],
  "siegert": ['S IY1 G ER0 T'],
  "sieges": ['S IY1 JH IH0 Z'],
  "siegfried": ['S IY1 G F R IY2 D'],
  "siegle": ['S IY1 G AH0 L'],
  "siegler": ['S IY1 G L ER0'],
  "siegman": ['S IY1 G M AH0 N'],
  "siegmann": ['S IY1 G M AH0 N'],
  "siegmund": ['S IY1 G M AH0 N D'],
  "siegrist": ['S IY1 G R IH0 S T'],
  "sieh": ['S IY1'],
  "sieja": ['S EY1 AH0', 'S EY1 JH AH0'],
  "siek": ['S IY1 K'],
  "siekierski": ['S IY0 K IH1 R S K IY0'],
  "siekman": ['S IY1 K M AH0 N'],
  "sielaff": ['S IY0 L AE1 F'],
  "sieler": ['S IY1 L ER0'],
  "sieling": ['S IY1 L IH0 NG'],
  "sieloff": ['S IY1 L AO0 F'],
  "sielski": ['S IY1 L S K IY0'],
  "siem": ['S IY1 M'],
  "siemel": ['S IY1 M AH0 L'],
  "siemens": ['S IY1 M AH0 N Z'],
  "siemens's": ['S IY1 M AH0 N Z', 'S IY1 M AH0 N Z IH0 Z'],
  "siemer": ['S IY1 M ER0'],
  "siemers": ['S IY1 M ER0 Z'],
  "sieminski": ['S IY0 M IH1 N S K IY0'],
  "siemon": ['S IY1 M AH0 N'],
  "siems": ['S IY1 M Z'],
  "siemsen": ['S IY1 M S AH0 N'],
  "sienko": ['S IY0 EH1 NG K OW0'],
  "sienna": ['S IY0 EH1 N AH0'],
  "sienna's": ['S IY0 EH1 N AH0 Z'],
  "sieracki": ['S IH0 R AA1 T S K IY0'],
  "sierchio": ['S IY1 R CH IY0 OW0'],
  "sierra": ['S IY0 EH1 R AH0'],
  "sierra's": ['S IY0 EH1 R AH0 Z'],
  "sierracin": ['S IY0 EH1 R AH0 S IH0 N'],
  "sierras": ['S IY0 EH1 R AH0 Z'],
  "siers": ['S IY1 R Z'],
  "sies": ['S IY1 Z'],
  "siese": ['S IY0 EH1 S'],
  "siess": ['S IY1 S'],
  "siesta": ['S IY0 EH1 S T AH0'],
  "sietsema": ['S IY0 T S IY1 M AH0'],
  "sieve": ['S IH1 V'],
  "siever": ['S IY1 V ER0'],
  "sievers": ['S IY1 V ER0 Z'],
  "sievert": ['S IY1 V ER0 T'],
  "sieves": ['S IH1 V Z'],
  "sieving": ['S IH1 V IH0 NG'],
  "siew": ['S UW1'],
  "siewert": ['S IY1 W ER0 T'],
  "siewiorek": ['S AH0 W AO1 R IH0 K'],
  "sifco": ['S IH1 F K OW0'],
  "sifers": ['S AY1 F ER0 Z'],
  "sifford": ['S IH1 F ER0 D'],
  "sift": ['S IH1 F T'],
  "sifted": ['S IH1 F T IH0 D'],
  "sifting": ['S IH1 F T IH0 NG'],
  "sifts": ['S IH1 F T S'],
  "sifuentes": ['S IY0 F W EH1 N T EH0 S'],
  "sig": ['S IH1 G'],
  "sigafoos": ['S IH1 G AH0 F UW2 Z'],
  "sigal": ['S AY1 JH AH0 L'],
  "sigala": ['S IY0 G AA1 L AH0'],
  "sigel": ['S IH1 G AH0 L'],
  "sigfreda": ['S IY0 G F R EH1 D AH0'],
  "sigg": ['S IH1 G'],
  "siggers": ['S IH1 G ER0 Z'],
  "siggins": ['S IH1 G IH0 N Z'],
  "sigh": ['S AY1'],
  "sighed": ['S AY1 D'],
  "sighing": ['S AY1 IH0 NG'],
  "sighs": ['S AY1 Z'],
  "sight": ['S AY1 T'],
  "sight-seer": ['S AY1 T S IY1 R'],
  "sighted": ['S AY1 T IH0 D'],
  "sighting": ['S AY1 T IH0 NG'],
  "sightings": ['S AY1 T IH0 NG Z'],
  "sights": ['S AY1 T S'],
  "sightsee": ['S AY1 T S IY1'],
  "sightseeing": ['S AY1 T S IY1 IH0 NG'],
  "sightseer": ['S AY1 T S IY1 ER0', 'S AY1 T S IY1 R'],
  "sightseers": ['S AY1 T S IY1 ER0 Z', 'S AY1 T S IY1 R Z'],
  "sigl": ['S IH1 G AH0 L'],
  "sigle": ['S AY1 G AH0 L'],
  "sigler": ['S AY1 G AH0 L ER0', 'S AY1 G L ER0', 'S IH1 G L ER0'],
  "sigley": ['S IH1 G L IY0'],
  "siglin": ['S IH1 G L IH0 N'],
  "sigma": ['S IH1 G M AH0'],
  "sigmaform": ['S IH1 G M AH0 F AO0 R M'],
  "sigman": ['S IH1 G M AH0 N'],
  "sigmoid": ['S IH1 G M OY2 D'],
  "sigmoidal": ['S IH2 G M OY1 D AH0 L'],
  "sigmon": ['S IH1 G M AH0 N'],
  "sigmund": ['S IH1 G M AH0 N D'],
  "sign": ['S AY1 N'],
  "sign's": ['S AY1 N Z'],
  "signa": ['S IH1 G N AH0'],
  "signage": ['S AY1 N IH0 JH'],
  "signal": ['S IH1 G N AH0 L'],
  "signal's": ['S IH1 G N AH0 L Z'],
  "signaled": ['S IH1 G N AH0 L D'],
  "signaling": ['S IH1 G N AH0 L IH0 NG'],
  "signalled": ['S IH1 G N AH0 L D'],
  "signalling": ['S IH1 G N AH0 L IH0 NG'],
  "signals": ['S IH1 G N AH0 L Z'],
  "signatories": ['S IH1 G N AH0 T AO2 R IY0 Z'],
  "signatory": ['S IH1 G N AH0 T AO2 R IY0'],
  "signature": ['S IH1 G N AH0 CH ER0'],
  "signatures": ['S IH1 G N AH0 CH ER0 Z'],
  "signed": ['S AY1 N D'],
  "signer": ['S AY1 N ER0'],
  "signers": ['S AY1 N ER0 Z'],
  "signet": ['S IH1 G N IH0 T'],
  "significance": ['S AH0 G N IH1 F IH0 K AH0 N S', 'S IH0 G N IH1 F IH0 K AH0 N S'],
  "significant": ['S AH0 G N IH1 F IH0 K AH0 N T', 'S IH0 G N IH1 F IH0 K AH0 N T'],
  "significantly": ['S IH0 G N IH1 F IH0 K AH0 N T L IY0'],
  "signified": ['S IH1 G N AH0 F AY2 D'],
  "signifies": ['S IH1 G N AH0 F AY2 Z'],
  "signify": ['S IH1 G N AH0 F AY2'],
  "signifying": ['S IH1 G N AH0 F AY2 IH0 NG'],
  "signing": ['S AY1 N IH0 NG'],
  "signings": ['S AY1 N IH0 NG Z'],
  "signor": ['S IY1 N Y AO0 R'],
  "signore": ['S IY0 N Y AO1 R EY0'],
  "signorelli": ['S IY0 G N AO0 R EH1 L IY0'],
  "signori": ['S IH0 G N AO1 R IY0', 'S IY0 N Y AO1 R IY1'],
  "signpost": ['S AY1 N P OW2 S T'],
  "signposts": ['S AY1 N P OW2 S T S'],
  "signs": ['S AY1 N Z'],
  "sigoloff": ['S IH1 G AH0 L AO0 F'],
  "sigourney": ['S IH0 G AO1 R N IY0'],
  "sigrid": ['S IH1 G R IH0 D'],
  "sigrist": ['S IH1 G R IH0 S T'],
  "sigur": ['S IH1 G ER0'],
  "sigurd": ['S IH1 G ER0 D'],
  "sigurdson": ['S IH1 G ER0 D S AH0 N'],
  "sigwald": ['S IH1 G W AH0 L D'],
  "sihanouk": ['S IY1 AH0 N UH2 K'],
  "sikes": ['S AY1 K S'],
  "sikh": ['S IY1 K'],
  "sikhism": ['S IY1 K IH0 Z M'],
  "sikhs": ['S IY1 K S'],
  "sikkema": ['S IH0 K IY1 M AH0'],
  "siklie": ['S IH1 K L IY0'],
  "sikora": ['S IH0 K AO1 R AH0'],
  "sikorski": ['S IH0 K AO1 R S K IY0'],
  "sikorsky": ['S IH0 K AO1 R S K IY0'],
  "sil": ['S IH1 L'],
  "silajdzic": ['S IH2 L AA1 JH IH0 K'],
  "silajdzic's": ['S IH2 L AA1 JH IH0 K S'],
  "silas": ['S AY1 L AH0 S'],
  "silbaugh": ['S IH1 L B AO2'],
  "silber": ['S IH1 L B ER0'],
  "silberberg": ['S IH1 L B ER0 B ER0 G'],
  "silberg": ['S IH1 L B ER0 G'],
  "silbergeld": ['S IH1 L B ER0 G EH2 L D'],
  "silberman": ['S IH1 L B ER0 M AH0 N'],
  "silbernagel": ['S IH1 L B ER0 N AH0 G AH0 L'],
  "silberner": ['S IH0 L B ER1 N ER0'],
  "silberner's": ['S IH0 L B ER1 N ER0 Z'],
  "silberstein": ['S IH1 L B ER0 S T IY2 N', 'S IH1 L B ER0 S T AY2 N'],
  "silbert": ['S IH1 L B ER0 T'],
  "silcott": ['S IH1 L K AH0 T'],
  "silcox": ['S IH1 L K AA0 K S'],
  "silence": ['S AY1 L AH0 N S'],
  "silenced": ['S AY1 L AH0 N S T'],
  "silencer": ['S AY1 L AH0 N S ER2'],
  "silences": ['S AY1 L AH0 N S IH0 Z'],
  "silencing": ['S AY1 L AH0 N S IH0 NG'],
  "silent": ['S AY1 L AH0 N T'],
  "silently": ['S AY1 L AH0 N T L IY0'],
  "sileo": ['S IH1 L IY0 OW0'],
  "siler": ['S AY1 L ER0'],
  "silesia": ['S IH0 L IY1 Z AH0'],
  "silex": ['S IH1 L AH0 K S', 'S AY1 L EH0 K S'],
  "silfies": ['S IH1 L F IY0 Z'],
  "silga": ['S IH1 L G AH0'],
  "silguero": ['S IY0 L G EH1 R OW0'],
  "silhouette": ['S IH2 L AH0 W EH1 T'],
  "silhouetted": ['S IH2 L AH0 W EH1 T IH0 D'],
  "silhouettes": ['S IH2 L AH0 W EH1 T S'],
  "silica": ['S IH1 L AH0 K AH0', 'S IH1 L IH0 K AH0'],
  "silicate": ['S IH1 L AH0 K AH0 T', 'S IH1 L AH0 K EY2 T'],
  "silicates": ['S IH1 L AH0 K AH0 T S', 'S IH1 L AH0 K EY2 T S'],
  "silicon": ['S IH1 L AH0 K AH0 N'],
  "silicone": ['S IH1 L AH0 K OW2 N'],
  "silicones": ['S IH1 L AH0 K OW2 N Z'],
  "siliconix": ['S IH2 L IH0 K AA1 N IH0 K S'],
  "silk": ['S IH1 L K'],
  "silken": ['S IH1 L K AH0 N'],
  "silkey": ['S IH1 L K IY0'],
  "silks": ['S IH1 L K S'],
  "silkwood": ['S IH1 L K W UH2 D'],
  "silkworm": ['S IH1 L K W ER0 M'],
  "silkworms": ['S IH1 L K W ER2 M Z'],
  "silky": ['S IH1 L K IY0'],
  "sill": ['S IH1 L'],
  "siller": ['S IH1 L ER0'],
  "sillerman": ['S IH1 L ER0 M AH0 N'],
  "sillier": ['S IH1 L IY0 ER0'],
  "silliest": ['S IH1 L IY0 AH0 S T'],
  "silliman": ['S IH1 L IH0 M AH0 N'],
  "silliness": ['S IH1 L IY0 N AH0 S'],
  "sillman": ['S IH1 L M AH0 N'],
  "sills": ['S IH1 L Z'],
  "silly": ['S IH1 L IY0'],
  "silman": ['S IH1 L M AH0 N'],
  "silmon": ['S IY0 L M AO1 N'],
  "silo": ['S AY1 L OW0'],
  "silos": ['S AY1 L OW2 Z'],
  "silsbee": ['S IH1 L Z B IY0'],
  "silsby": ['S IH1 L S B IY0'],
  "silt": ['S IH1 L T'],
  "siltec": ['S IH1 L T EH2 K'],
  "silva": ['S IH1 L V AH0', 'S EH1 L V AH0'],
  "silva's": ['S IH1 L V AH0 Z'],
  "silvadio": ['S IH0 L V AA1 D IY0 OW0'],
  "silvadio's": ['S IH0 L V AA1 D IY0 OW0 Z'],
  "silvana": ['S IH0 L V AA1 N AH0'],
  "silvar": ['S IH1 L V AA0 R'],
  "silvas": ['S IH1 L V AH0 Z'],
  "silveira": ['S IY0 L V EH1 R AH0'],
  "silver": ['S IH1 L V ER0'],
  "silver's": ['S IH1 L V ER0 Z'],
  "silvera": ['S IY0 L V EH1 R AH0'],
  "silverado": ['S IH2 L V ER0 AA1 D OW0'],
  "silverberg": ['S IH1 L V ER0 B ER0 G'],
  "silvercrest": ['S IH1 L V ER0 K R EH2 S T'],
  "silverdome": ['S IH1 L V ER0 D OW2 M'],
  "silveri": ['S IY0 L V EH1 R IY0'],
  "silveria": ['S IY0 L V EH1 R IY0 AH0'],
  "silverio": ['S IY0 L V EH1 R IY0 OW0'],
  "silverlake": ['S IH1 L V ER0 L EY2 K'],
  "silverman": ['S IH1 L V ER0 M AE2 N'],
  "silvernail": ['S IH1 L V ER0 N EY2 L'],
  "silverplate": ['S IH1 L V ER0 P L EY1 T'],
  "silvers": ['S IH1 L V ER0 Z'],
  "silvershoe": ['S IH1 L V ER0 SH UW2'],
  "silverside": ['S IH1 L V ER0 S AY2 D'],
  "silversides": ['S IH1 L V ER0 S AY2 D Z'],
  "silverstein": ['S IH1 L V ER0 S T IY2 N', 'S IH1 L V ER0 S T AY2 N'],
  "silverstone": ['S IH1 L V ER0 S T OW2 N'],
  "silverthorn": ['S IH1 L V ER0 TH AO0 R N'],
  "silverthorne": ['S IH1 L V ER0 TH AO0 R N'],
  "silverware": ['S IH1 L V ER0 W EH2 R'],
  "silvery": ['S IH1 L V ER0 IY0'],
  "silvester": ['S IH0 L V EH1 S T ER0'],
  "silvestre": ['S IY0 L V EY1 S T R EY0'],
  "silvestri": ['S IY0 L V EH1 S T R IY0'],
  "silvestro": ['S IY0 L V EY1 S T R OW0'],
  "silvey": ['S IH1 L V IY0'],
  "silvi": ['S IH1 L V IY0'],
  "silvia": ['S IH1 L V IY0 AH0'],
  "silvie": ['S IH1 L V IY0'],
  "silvio": ['S IH1 L V IY0 OW0'],
  "silvis": ['S IH1 L V IH0 S'],
  "silvius": ['S IH1 L V IY0 IH0 S'],
  "silwan": ['S IH2 L W AA0 N'],
  "sim": ['S IH1 M'],
  "sima": ['S IY1 M AH0'],
  "simard": ['S IH1 M ER0 D'],
  "simas": ['S AY1 M AH0 Z'],
  "simba": ['S IH1 M B AH0'],
  "simba's": ['S IH1 M B AH0 Z'],
  "simcha": ['S IH1 M HH AH0'],
  "simco": ['S IH1 M K OW0'],
  "simcoe": ['S IH1 M K OW2'],
  "simcox": ['S IH1 M K AA2 K S'],
  "sime": ['S AY1 M'],
  "simek": ['S IH1 M IH0 K'],
  "simenson": ['S IH1 M IH0 N S AH0 N'],
  "simental": ['S IH0 M EH1 N T AH0 L'],
  "simeon": ['S IH1 M IY0 AH0 N'],
  "simeone": ['S IH1 M IY0 AH1 N'],
  "simer": ['S AY1 M ER0'],
  "simerly": ['S AY1 M ER0 L IY0'],
  "simerson": ['S IH1 M ER0 S AH0 N'],
  "simes": ['S AY1 M Z'],
  "simex": ['S IH1 M EH0 K S', 'S AY1 M EH0 K S'],
  "simi": ['S IY1 M IY0'],
  "simian": ['S IH1 M IY0 AH0 N'],
  "simich": ['S IH1 M IH0 CH'],
  "simien": ['S IH1 M IY0 N'],
  "similac": ['S IH1 M AH0 L AE2 K'],
  "similack": ['S IH1 M AH0 L AE2 K'],
  "similar": ['S IH1 M AH0 L ER0'],
  "similarities": ['S IH2 M AH0 L EH1 R AH0 T IY0 Z'],
  "similarity": ['S IH2 M AH0 L EH1 R AH0 T IY0'],
  "similarly": ['S IH1 M AH0 L ER0 L IY0'],
  "similiar": ['S IH0 M IH1 L AA0 R'],
  "simington": ['S IH1 M IH0 NG T AH0 N'],
  "siminski": ['S IH0 M IH1 N S K IY0'],
  "simion": ['S IH1 M IY0 AA2 N'],
  "simione": ['S IY2 M IY0 OW1 N IY0'],
  "simison": ['S IH1 M IH0 S AH0 N'],
  "simkin": ['S IH1 M K IH0 N'],
  "simkins": ['S IH1 M K IH0 N Z'],
  "simko": ['S IH1 M K OW0'],
  "simler": ['S IH1 M AH0 L ER0', 'S IH1 M L ER0'],
  "simm": ['S IH1 M'],
  "simmer": ['S IH1 M ER0'],
  "simmered": ['S IH1 M ER0 D'],
  "simmering": ['S IH1 M ER0 IH0 NG'],
  "simmerman": ['S IH1 M ER0 M AH0 N'],
  "simmers": ['S IH1 M ER0 Z'],
  "simmert": ['S IH1 M ER0 T'],
  "simmon": ['S IH1 M AH0 N'],
  "simmon's": ['S IH1 M AH0 N Z'],
  "simmonds": ['S IH1 M AH0 N D Z'],
  "simmons": ['S IH1 M AH0 N Z'],
  "simmons'": ['S IH1 M AH0 N Z'],
  "simmons's": ['S IH1 M AH0 N Z IH0 Z'],
  "simms": ['S IH1 M Z'],
  "simo": ['S IY1 M OW0'],
  "simoes": ['S AY1 M OW0 Z'],
  "simon": ['S AY1 M AH0 N'],
  "simon's": ['S AY1 M AH0 N Z'],
  "simona": ['S IH0 M OW1 N AH0'],
  "simonds": ['S AY1 M AH0 N D Z'],
  "simone": ['S IH0 M OW1 N'],
  "simone's": ['S IH0 M OW1 N Z'],
  "simoneau": ['S IH1 M AH0 N OW0'],
  "simoneaux": ['S IH1 M AH0 N OW0'],
  "simonelli": ['S IY2 M OW0 N EH1 L IY0'],
  "simonet": ['S IH1 M AH0 N EH0 T'],
  "simonetta": ['S IY0 M AH0 N EH1 T AH0'],
  "simonette": ['S IH1 M AH0 N EH0 T'],
  "simonetti": ['S IY0 M OW0 N EH1 T IY0'],
  "simoni": ['S IY0 M OW1 N IY0'],
  "simonian": ['S IH0 M OW1 N IY0 AH0 N'],
  "simonich": ['S IH1 M AH0 N IH0 K'],
  "simonin": ['S IH1 M AH0 N IH0 N'],
  "simonis": ['S IH1 M AH0 N IH0 S'],
  "simons": ['S AY1 M AH0 N Z'],
  "simonsen": ['S IH1 M AH0 N S AH0 N'],
  "simonson": ['S IH1 M AH0 N S AH0 N'],
  "simonsson": ['S AY1 M AH0 N S AH0 N'],
  "simonton": ['S AY1 M AH0 N T AH0 N', 'S IH0 M AA1 N T AH0 N'],
  "simony": ['S AY1 M AH0 N IY0'],
  "simper": ['S IH1 M P ER0'],
  "simpkins": ['S IH1 M P K IH0 N Z'],
  "simple": ['S IH1 M P AH0 L'],
  "simpler": ['S IH1 M P AH0 L ER0', 'S IH1 M P L ER0'],
  "simplesse": ['S IH0 M P L EH1 S'],
  "simplest": ['S IH1 M P L AH0 S T'],
  "simplex": ['S IH1 M P L EH2 K S'],
  "simplicity": ['S IH0 M P L IH1 S AH0 T IY0', 'S IH0 M P L IH1 S IH0 T IY0'],
  "simplification": ['S IH2 M P L AH0 F IH0 K EY1 SH AH0 N'],
  "simplified": ['S IH1 M P L AH0 F AY2 D'],
  "simplifies": ['S IH1 M P L AH0 F AY2 Z'],
  "simplify": ['S IH1 M P L AH0 F AY2'],
  "simplifying": ['S IH1 M P L AH0 F AY2 IH0 NG'],
  "simplistic": ['S IH0 M P L IH1 S T IH0 K'],
  "simplot": ['S IH1 M P L AA0 T'],
  "simply": ['S IH1 M P L IY0'],
  "simpson": ['S IH1 M P S AH0 N'],
  "simpson's": ['S IH1 M P S AH0 N Z'],
  "simpsons": ['S IH1 M P S AH0 N Z'],
  "simpsons'": ['S IH1 M P S AH0 N Z'],
  "sims": ['S IH1 M Z'],
  "sims'": ['S IH1 M Z'],
  "simsbury": ['S IH1 M Z B EH2 R IY0'],
  "simson": ['S IH1 M S AH0 N'],
  "simulac": ['S IH1 M Y AH0 L AE2 K'],
  "simulate": ['S IH1 M Y AH0 L AH0 T', 'S IH1 M Y AH0 L EY2 T'],
  "simulated": ['S IH1 M Y AH0 L EY2 T IH0 D'],
  "simulates": ['S IH1 M Y AH0 L EY2 T S'],
  "simulating": ['S IH1 M Y AH0 L EY2 T IH0 NG'],
  "simulation": ['S IH2 M Y AH0 L EY1 SH AH0 N'],
  "simulations": ['S IH2 M Y UW0 L EY1 SH AH0 N Z', 'S IH2 M Y AH0 L EY1 SH AH0 N Z'],
  "simulator": ['S IH1 M Y AH0 L EY2 T ER0'],
  "simulators": ['S IH1 M Y AH0 L EY2 T ER0 Z'],
  "simulcast": ['S AY1 M Y AH0 L K AE2 S T', 'S IH1 M Y AH0 L K AE2 S T'],
  "simultaneous": ['S AY2 M AH0 L T EY1 N IY0 AH0 S'],
  "simultaneously": ['S AY2 M AH0 L T EY1 N IY0 AH0 S L IY0'],
  "sin": ['S IH1 N'],
  "sinagra": ['S IH0 N AE1 G R AH0'],
  "sinai": ['S AY1 N AY2'],
  "sinar": ['S AY1 N ER0'],
  "sinatra": ['S AH0 N AA1 T R AH0'],
  "sinatra's": ['S AH0 N AA1 T R AH0 Z'],
  "sinay": ['S IH0 N EY1'],
  "sinbad": ['S IH1 N B AE2 D'],
  "sincavage": ['S IY0 N K AA1 V IH0 JH'],
  "since": ['S IH1 N S'],
  "sincere": ['S IH0 N S IH1 R'],
  "sincerely": ['S IH0 N S IH1 R L IY0'],
  "sincerity": ['S IH0 N S EH1 R AH0 T IY0'],
  "sinclair": ['S IH0 N K L EH1 R', 'S IH1 N K L EH0 R'],
  "sind": ['S IH1 N D'],
  "sindelar": ['S IH1 N D IH0 L ER0'],
  "sindlinger": ['S IH1 N D L IH2 NG ER0'],
  "sindona": ['S IH0 N D OW1 N AH0'],
  "sindoni": ['S IY0 N D OW1 N IY0'],
  "sindt": ['S IH1 N T'],
  "sine": ['S AY1 N'],
  "sinead": ['S IH0 N EY1 AE0 D', 'SH IH0 N IY1 D'],
  "sineath": ['S IH1 N EH0 TH'],
  "sinegal": ['S IH1 N IH0 G AH0 L'],
  "siner": ['S AY1 N ER0'],
  "sines": ['S AY1 N Z'],
  "sinewy": ['S IH1 N Y UW0 IY0'],
  "sinfonia": ['S IH0 N F OW1 N IY0 AH0'],
  "sinful": ['S IH1 N F AH0 L'],
  "sing": ['S IH1 NG'],
  "sing's": ['S IH1 NG Z'],
  "singapore": ['S IH1 NG AH0 P AO2 R'],
  "singapore's": ['S IH1 NG AH0 P AO0 R Z'],
  "singaporean": ['S IH0 NG G AH0 P AO1 R IY0 AH0 N'],
  "singaporeans": ['S IH0 NG G AH0 P AO1 R IY0 AH0 N Z'],
  "singe": ['S IH1 N JH'],
  "singed": ['S IH1 N JH D'],
  "singel": ['S IH1 NG G AH0 L'],
  "singer": ['S IH1 NG ER0'],
  "singer's": ['S IH1 NG ER0 Z'],
  "singerman": ['S IH1 N JH ER2 M AH0 N'],
  "singers": ['S IH1 NG ER0 Z'],
  "singh": ['S IH1 NG'],
  "singh's": ['S IH1 NG Z'],
  "singin'": ['S IH1 NG IH0 N'],
  "singing": ['S IH1 NG IH0 NG'],
  "singlaub": ['S IH1 NG L AW2 B'],
  "single": ['S IH1 NG G AH0 L'],
  "single-handed": ['S IH1 NG G AH0 L HH AE1 N D IH0 D'],
  "singled": ['S IH1 NG G AH0 L D'],
  "singlehandedly": ['S IH2 NG G AH0 L HH AE1 N D IH0 D L IY0'],
  "singler": ['S IH1 NG G AH0 L ER0', 'S IH1 NG G L ER0'],
  "singles": ['S IH1 NG G AH0 L Z'],
  "singletary": ['S IH1 NG G AH0 L T EH0 R IY0'],
  "singleterry": ['S IH1 NG G AH0 L T EH0 R IY0'],
  "singleton": ['S IH1 NG G AH0 L T AH0 N'],
  "singleton's": ['S IH1 NG G AH0 L T AH0 N Z'],
  "singley": ['S IH1 NG G L IY0'],
  "singling": ['S IH1 NG G AH0 L IH0 NG', 'S IH1 NG G L IH0 NG'],
  "singly": ['S IH1 NG G L IY0'],
  "sings": ['S IH1 NG Z'],
  "singular": ['S IH1 NG G Y AH0 L ER0'],
  "singularity": ['S IH1 NG G Y AH0 L EH1 R AH0 T IY0'],
  "singularization": ['S IH2 NG G Y AH0 L ER0 IH0 Z EY1 SH AH0 N'],
  "singularly": ['S IH1 NG G Y AH0 L ER0 L IY0'],
  "sinha": ['S IH1 N HH AH0'],
  "sinhalese": ['S IH2 N AH0 L IY1 Z'],
  "siniard": ['S IH1 N IY0 ER0 D'],
  "sinibaldi": ['S IH0 N IH0 B AA1 L D IY0'],
  "siniscalchi": ['S IH0 N IH0 S K AA1 L K IY0'],
  "sinise": ['S IH0 N IY1 Z'],
  "sinisi": ['S IH0 N IY1 S IY0'],
  "sinister": ['S IH1 N IH0 S T ER0'],
  "sink": ['S IH1 NG K'],
  "sinker": ['S IH1 NG K ER0'],
  "sinkfield": ['S IH1 NG K F IY2 L D'],
  "sinkhole": ['S IH1 NG K HH OW2 L'],
  "sinkhorn": ['S IH1 NG K HH ER0 N'],
  "sinking": ['S IH1 NG K IH0 NG'],
  "sinkler": ['S IH1 NG K L ER0'],
  "sinko": ['S IH1 NG K OW0'],
  "sinks": ['S IH1 NG K S'],
  "sinn": ['S IH1 N', 'SH IH1 N'],
  "sinned": ['S IH1 N D'],
  "sinner": ['S IH1 N ER0'],
  "sinners": ['S IH1 N ER0 Z'],
  "sinnett": ['S IH1 N IH0 T'],
  "sinning": ['S IH1 N IH0 NG'],
  "sinnott": ['S IH1 N AH0 T'],
  "sino": ['S AY1 N OW0'],
  "sinopec": ['S AY1 N OW0 P EH2 K'],
  "sinopoli": ['S IY0 N OW0 P OW1 L IY0', 'S IY0 N AA1 P OW0 L IY0'],
  "sinor": ['S AY1 N ER0'],
  "sinquefield": ['S IH1 N K W IH0 F IY0 L D'],
  "sins": ['S IH1 N Z'],
  "sinsabaugh": ['S IH1 N S AH0 B AO2'],
  "sinsel": ['S IH1 N S AH0 L'],
  "sinton": ['S IH1 N T AH0 N'],
  "sinuous": ['S IH1 N W AH0 S'],
  "sinus": ['S AY1 N AH0 S'],
  "sinuses": ['S AY1 N AH0 S AH0 Z'],
  "sinusoid": ['S AY1 N AH0 S OY2 D'],
  "sinusoidal": ['S AY1 N AH0 S OY2 D AH0 L'],
  "sinusoidally": ['S AY2 N AH0 S OY1 D AH0 L IY0'],
  "sinyard": ['S IH1 N Y AA2 R D'],
  "siobhan": ['SH AW1 B AA2 N', 'SH AH0 V AO1 N'],
  "sioux": ['S UW1'],
  "sip": ['S IH1 P'],
  "sipa": ['S IY1 P AH0'],
  "sipe": ['S AY1 P'],
  "sipelstein": ['S IH1 P AH0 L S T AY0 N', 'S IH1 P AH0 L S T IY0 N'],
  "sipes": ['S AY1 P S'],
  "siphon": ['S AY1 F AH0 N'],
  "siphoned": ['S AY1 F AH0 N D'],
  "siphoning": ['S AY1 F AH0 N IH0 NG'],
  "siphons": ['S AY1 F AH0 N Z'],
  "siple": ['S AY1 P AH0 L'],
  "sipos": ['S AY1 P OW0 Z'],
  "sipp": ['S IH1 P'],
  "sipped": ['S IH1 P T'],
  "sippel": ['S IH1 P AH0 L'],
  "sippican": ['S IH1 P IH0 K AH0 N'],
  "sipping": ['S IH1 P IH0 NG'],
  "sipple": ['S IH1 P AH0 L'],
  "sips": ['S IH1 P S'],
  "sir": ['S ER1'],
  "siracusa": ['S IH0 R AA0 K UW1 S AH0'],
  "siracuse": ['S IH0 R AA0 K UW1 S IY0'],
  "siragusa": ['S IH0 R AA0 G UW1 S AH0'],
  "siravo": ['S IH0 R AA1 V OW0'],
  "sircy": ['S ER1 K IY0'],
  "sire": ['S AY1 ER0'],
  "sirek": ['S AY1 R IH0 K'],
  "siren": ['S AY1 R AH0 N'],
  "sirena": ['S IH0 R EH1 N AH0'],
  "sirens": ['S AY1 R AH0 N Z'],
  "sires": ['S AY1 R Z'],
  "sirhan": ['S IH1 R HH AA2 N', 'S IH1 R HH AE2 N'],
  "siri": ['S IH1 R IY0'],
  "sirianni": ['S IH0 R IY0 AA1 N IY0'],
  "sirignano": ['S IH2 R IY0 N Y AA1 N OW0'],
  "siris": ['S AY1 R IH0 S', 'S IH1 R IH0 S'],
  "sirius": ['S IH1 R IH2 AH0 S'],
  "sirk": ['S ER1 K'],
  "sirkin": ['S ER1 K IH0 N'],
  "sirko": ['S ER1 K OW0'],
  "sirles": ['S ER1 L Z'],
  "sirman": ['S ER1 M AH0 N'],
  "sirmans": ['S ER1 M AH0 N Z'],
  "sirmon": ['S ER1 M AH0 N'],
  "sirmons": ['S ER1 M AH0 N Z'],
  "sirna": ['S ER1 N AH0'],
  "sirohi": ['S ER0 OW1 HH IY0'],
  "sirois": ['S AY0 R W AA1'],
  "siroky": ['S IH1 R AH0 K IY0'],
  "siron": ['S IH1 R AH0 N'],
  "sirota": ['S IH0 R OW1 T AH0'],
  "sirowitz": ['S IH1 R AH0 W IH0 T S'],
  "sirri": ['S IH1 R IY0'],
  "sirrine": ['S IH0 R IY1 N IY0'],
  "sirs": ['S ER1 R Z'],
  "sis": ['S IH1 S'],
  "sisco": ['S IY1 S K OW0'],
  "siscoe": ['S IH1 S K OW0'],
  "sisemore": ['S AY1 Z M AO0 R'],
  "sisk": ['S IH1 S K'],
  "siska": ['S IY1 S K AH0'],
  "siskel": ['S IH1 S K AH0 L'],
  "siskel's": ['S IH1 S K AH0 L Z'],
  "siskin": ['S IH1 S K IH0 N'],
  "siskind": ['S IH1 S K IH0 N D'],
  "sisko": ['S IH1 S K OW0'],
  "sisler": ['S IH1 S AH0 L ER0', 'S IH1 S L ER0'],
  "sisley": ['S IH1 Z L IY0'],
  "sisneros": ['S IH1 S N ER0 OW0 Z'],
  "sisney": ['S IH1 Z N IY0'],
  "sison": ['S IH1 S AH0 N'],
  "sissel": ['S IH1 S AH0 L'],
  "sissie": ['S IH1 S IY0'],
  "sissom": ['S IH1 S AH0 M'],
  "sisson": ['S IH1 S AH0 N'],
  "sissy": ['S IH1 S IY0'],
  "sistare": ['S IY0 S T AA1 R IY0'],
  "sistek": ['S IH1 S T IH0 K'],
  "sister": ['S IH1 S T ER0'],
  "sister's": ['S IH1 S T ER0 Z'],
  "sister-in-law": ['S IH1 S T ER0 IH0 N L AO2'],
  "sisterhood": ['S IH1 S T ER0 HH UH0 D'],
  "sisters": ['S IH1 S T ER0 Z'],
  "sisters-in-law": ['S IH1 S T ER0 Z IH0 N L AO2'],
  "sisti": ['S IH1 S T IY0'],
  "sistine": ['S IH0 S T IY1 N', 'S IH1 S T IY0 N'],
  "sisto": ['S IH1 S T OW0'],
  "sistrunk": ['S IH1 S T R AH0 NG K'],
  "sisulu": ['S IH0 S UW1 L UW0'],
  "sit": ['S IH1 T'],
  "sitar": ['S IH0 T AA1 R'],
  "sitars": ['S IH0 T AA1 R Z'],
  "sitcom": ['S IH1 T K AA2 M'],
  "sitcoms": ['S IH1 T K AA0 M Z'],
  "site": ['S AY1 T'],
  "site's": ['S AY1 T S'],
  "sited": ['S AY1 T IH0 D'],
  "sitek": ['S IH1 T EH0 K'],
  "sitenski": ['S IH0 T EH1 N S K IY0'],
  "sitenski's": ['S IH0 T EH1 N S K IY0 Z'],
  "sitenskis": ['S IH0 T EH1 N S K IY0 Z'],
  "sites": ['S AY1 T S'],
  "sithe": ['S AY1 DH'],
  "siting": ['S AY1 T IH0 NG'],
  "sitiveni": ['S IY2 T IH0 V IY1 N IY0'],
  "sitka": ['S IH1 T K AH0'],
  "sitko": ['S IH1 T K OW0'],
  "sitler": ['S AY1 T AH0 L ER0', 'S AY1 T L ER0', 'S IH1 T L ER0'],
  "sitmar": ['S IH1 T M AA2 R'],
  "sito": ['S IY1 T OW0'],
  "sito's": ['S IY1 T OW0 Z'],
  "sitrick": ['S IH1 T R IH0 K'],
  "sits": ['S IH1 T S'],
  "sitter": ['S IH1 T ER0'],
  "sitterly": ['S IH1 T ER0 L IY0'],
  "sitters": ['S IH1 T ER0 Z'],
  "sittig": ['S IH1 T IH0 G'],
  "sittin'": ['S IH1 T AH0 N'],
  "sitting": ['S IH1 T IH0 NG'],
  "sittler": ['S IH1 T L ER0'],
  "sittner": ['S IH1 T N ER0'],
  "sitton": ['S IH1 T AH0 N'],
  "sitts": ['S IH1 T S'],
  "situate": ['S IH1 CH UW0 EY2 T'],
  "situated": ['S IH1 CH UW0 EY2 T IH0 D'],
  "situation": ['S IH2 CH UW0 EY1 SH AH0 N'],
  "situation's": ['S IH2 CH UW0 EY1 SH AH0 N Z'],
  "situational": ['S IH2 CH UW0 EY1 SH AH0 N AH0 L'],
  "situations": ['S IH2 CH UW0 EY1 SH AH0 N Z'],
  "situs": ['S AY1 T AH0 S'],
  "sitz": ['S IH1 T S'],
  "sitze": ['S IH1 T Z'],
  "sitzer": ['S IH1 T Z ER0'],
  "sitzes": ['S IH1 T S IH0 Z'],
  "sitzman": ['S IH1 T S M AH0 N'],
  "sitzmann": ['S IH1 T S M AH0 N'],
  "siu": ['S IY1 UW0'],
  "siuda": ['S IY0 UW1 D AH0'],
  "sivak": ['S IH1 V AH0 K'],
  "siver": ['S AY1 V ER0'],
  "siverling": ['S IH1 V ER0 L IH0 NG'],
  "siverson": ['S IH1 V ER0 S AH0 N'],
  "sivertsen": ['S IH1 V ER0 T S AH0 N'],
  "sivertson": ['S IH1 V ER0 T S AH0 N'],
  "sivils": ['S IH1 V AH0 L Z'],
  "sivley": ['S IH1 V L IY0'],
  "sivy": ['S IH1 V IY0'],
  "siwek": ['S IH1 W IH0 K'],
  "six": ['S IH1 K S'],
  "six's": ['S IH1 K S IH0 Z'],
  "six-pack": ['S IH1 K S P AE2 K'],
  "sixed": ['S IH1 K S T'],
  "sixer": ['S IH1 K S ER0'],
  "sixers": ['S IH1 K S ER0 Z'],
  "sixes": ['S IH1 K S IH0 Z'],
  "sixfold": ['S IH1 K S F OW2 L D'],
  "sixteen": ['S IH0 K S T IY1 N', 'S IH1 K S T IY1 N'],
  "sixteen's": ['S IH2 K S T IY1 N Z'],
  "sixteens": ['S IH1 K S T IY1 N Z'],
  "sixteenth": ['S IH0 K S T IY1 N TH', 'S IH1 K S T IY1 N TH'],
  "sixteenths": ['S IH1 K S T IY1 N TH S'],
  "sixth": ['S IH1 K S TH'],
  "sixths": ['S IH1 K S TH S'],
  "sixties": ['S IH1 K S T IY0 Z'],
  "sixtieth": ['S IH1 K S T IY0 IH0 TH'],
  "sixty": ['S IH1 K S T IY0'],
  "sixty's": ['S IH1 K S T IY0 Z'],
  "sizable": ['S AY1 Z AH0 B AH0 L'],
  "size": ['S AY1 Z'],
  "sizeable": ['S AY1 Z AH0 B AH0 L'],
  "sized": ['S AY1 Z D'],
  "sizeler": ['S AY1 Z L ER0'],
  "sizelove": ['S AY1 Z L AH2 V'],
  "sizemore": ['S AY1 Z M AO0 R'],
  "sizer": ['S AY1 Z ER0'],
  "sizes": ['S AY1 Z AH0 Z', 'S AY1 Z IH0 Z'],
  "sizing": ['S AY1 Z IH0 NG'],
  "sizzle": ['S IH1 Z AH0 L'],
  "sizzled": ['S IH1 Z AH0 L D'],
  "sizzler": ['S IH1 Z L ER0'],
  "sizzlers": ['S IH1 Z L ER0 Z'],
  "sizzlin": ['S IH1 Z L IH0 N'],
  "sizzling": ['S IH1 Z AH0 L IH0 NG', 'S IH1 Z L IH0 NG'],
  "sjoberg": ['SH OW1 B ER0 G'],
  "sjoblom": ['SH OW1 B L AA0 M'],
  "sjodin": ['SH OW1 D IH0 N'],
  "sjogren": ['SH OW1 G R AH0 N'],
  "sjolander": ['SH OW1 L AE2 N D ER0'],
  "sjoquist": ['SH OW1 K W IH0 S T'],
  "sjostrom": ['SH OW1 S T R AH0 M'],
  "ska": ['S K AA1'],
  "skaar": ['S K AA1 R'],
  "skadden": ['S K AE1 D IH0 N'],
  "skaff": ['S K AE1 F'],
  "skaggs": ['S K AE1 G Z'],
  "skains": ['S K EY1 N Z'],
  "skala": ['S K AA1 L AH0'],
  "skalicky": ['S K AH0 L IH1 K IY0'],
  "skalla": ['S K AE1 L AH0'],
  "skalski": ['S K AA1 L S K IY0'],
  "skalsky": ['S K AA1 L S K IY0'],
  "skandia": ['S K AE1 N D IY0 ER0', 'S K AE1 N D IY0 AH0', 'S K AE1 N D Y AH0'],
  "skandinaviska": ['S K AE2 N D IH0 N AH0 V IH1 S K AH0'],
  "skanska": ['S K AE1 N S K AH0'],
  "skarda": ['S K AA1 R D AH0'],
  "skare": ['S K EH1 R'],
  "skase": ['S K EY1 Z'],
  "skate": ['S K EY1 T'],
  "skateboard": ['S K EY1 T B AO2 R D'],
  "skateboarding": ['S K EY1 T B AO2 R D IH0 NG'],
  "skated": ['S K EY1 T IH0 D'],
  "skater": ['S K EY1 T ER0'],
  "skaters": ['S K EY1 T ER0 Z'],
  "skates": ['S K EY1 T S'],
  "skating": ['S K EY1 T IH0 NG'],
  "skeat": ['S K IY1 T'],
  "skeel": ['S K IY1 L'],
  "skeels": ['S K IY1 L Z'],
  "skeen": ['S K IY1 N'],
  "skeens": ['S K IY1 N Z'],
  "skees": ['S K IY1 Z'],
  "skeet": ['S K IY1 T'],
  "skeete": ['S K IY1 T'],
  "skeeter": ['S K IY1 T ER0'],
  "skeeters": ['S K IY1 T ER0 Z'],
  "skeets": ['S K IY1 T S'],
  "skeffington": ['S K EH1 F IH0 NG T AH0 N'],
  "skehan": ['S K EY1 AH0 N'],
  "skein": ['S K EY1 N'],
  "skeins": ['S K EY1 N Z'],
  "skeletal": ['S K EH1 L AH0 T AH0 L'],
  "skeleton": ['S K EH1 L AH0 T AH0 N'],
  "skeletons": ['S K EH1 L AH0 T AH0 N Z'],
  "skellenger": ['S K EH1 L IH0 N JH ER0'],
  "skelley": ['S K EH1 L IY0'],
  "skelly": ['S K EH1 L IY0'],
  "skelter": ['S K EH1 L T ER0'],
  "skelton": ['S K EH1 L T AH0 N'],
  "skenandore": ['S K IH0 N AE1 N D ER0'],
  "skender": ['S K EH1 N D ER0'],
  "skenderian": ['S K EH2 N D EH1 R IY0 AH0 N'],
  "skene": ['S K IY1 N'],
  "skeoch": ['S K IY1 AA0 CH'],
  "skeptic": ['S K EH1 P T IH0 K'],
  "skeptical": ['S K EH1 P T AH0 K AH0 L', 'S K EH1 P T IH0 K AH0 L'],
  "skeptically": ['S K EH1 P T IH0 K AH0 L IY0', 'S K EH1 P T IH0 K L IY0'],
  "skepticism": ['S K EH1 P T IH0 S IH2 Z AH0 M'],
  "skeptics": ['S K EH1 P T IH0 K S'],
  "skerdal": ['S K EH1 R D AA2 L'],
  "skerry": ['S K EH1 R IY0'],
  "sketch": ['S K EH1 CH'],
  "sketchbook": ['S K EH1 CH B UH2 K'],
  "sketchbooks": ['S K EH1 CH B UH2 K S'],
  "sketched": ['S K EH1 CH T'],
  "sketches": ['S K EH1 CH AH0 Z', 'S K EH1 CH IH0 Z'],
  "sketching": ['S K EH1 CH IH0 NG'],
  "sketchy": ['S K EH1 CH IY0'],
  "skew": ['S K Y UW1'],
  "skewed": ['S K Y UW1 D'],
  "skewer": ['S K Y UW1 ER0'],
  "skewered": ['S K Y UW1 ER0 D'],
  "skewers": ['S K Y UW1 ER0 Z'],
  "skewes": ['S K Y UW1 Z'],
  "skewing": ['S K Y UW1 IH0 NG'],
  "skews": ['S K Y UW1 Z'],
  "ski": ['S K IY1'],
  "skiba": ['S K AY1 B AH0'],
  "skibbe": ['S K IH1 B'],
  "skibicki": ['S K IH0 B IH1 K IY0'],
  "skibinski": ['S K IH0 B IH1 N S K IY0'],
  "skibo": ['S K AY1 B OW0', 'S K IY1 B OW0'],
  "skid": ['S K IH1 D'],
  "skidded": ['S K IH1 D IH0 D'],
  "skidding": ['S K IH1 D IH0 NG'],
  "skidgel": ['S K IH1 JH AH0 L'],
  "skidmore": ['S K IH1 D M AO0 R'],
  "skids": ['S K IH1 D Z'],
  "skied": ['S K IY1 D'],
  "skier": ['S K AY1 R', 'S K IY1 ER0'],
  "skiers": ['S K IY1 ER0 Z'],
  "skies": ['S K AY1 Z'],
  "skiff": ['S K IH1 F'],
  "skiffington": ['S K IH1 F IH0 NG T AH0 N'],
  "skiffs": ['S K IH1 F S'],
  "skiing": ['S K IY1 IH0 NG'],
  "skiles": ['S K AY1 L Z'],
  "skill": ['S K IH1 L'],
  "skilled": ['S K IH1 L D'],
  "skillen": ['S K IH1 L AH0 N'],
  "skillern": ['S K IH1 L ER0 N'],
  "skillet": ['S K IH1 L AH0 T'],
  "skillful": ['S K IH1 L F AH0 L'],
  "skillfully": ['S K IH1 L F AH0 L IY0'],
  "skillin": ['S K IH1 L IH0 N'],
  "skilling": ['S K IH1 L IH0 NG'],
  "skillings": ['S K IH1 L IH0 NG Z'],
  "skillman": ['S K IH1 L M AH0 N'],
  "skills": ['S K IH1 L Z'],
  "skilton": ['S K IH1 L T AH0 N'],
  "skim": ['S K IH1 M'],
  "skimmed": ['S K IH1 M D'],
  "skimmer": ['S K IH1 M ER0'],
  "skimmers": ['S K IH1 M ER0 Z'],
  "skimming": ['S K IH1 M IH0 NG'],
  "skimp": ['S K IH1 M P'],
  "skimpier": ['S K IH1 M P IY0 ER0'],
  "skimpiest": ['S K IH1 M P IY0 AH0 S T'],
  "skimping": ['S K IH1 M P IH0 NG'],
  "skimpy": ['S K IH1 M P IY0'],
  "skin": ['S K IH1 N'],
  "skin's": ['S K IH1 N Z'],
  "skinflint": ['S K IH1 N F L IH0 N T'],
  "skinhead": ['S K IH1 N HH EH2 D'],
  "skinheads": ['S K IH1 N HH EH2 D Z'],
  "skinks": ['S K IH1 NG K S'],
  "skinless": ['S K IH1 N L AH0 S'],
  "skinned": ['S K IH1 N D'],
  "skinner": ['S K IH1 N ER0'],
  "skinner's": ['S K IH1 N ER0 Z'],
  "skinnier": ['S K IH1 N IY0 ER0'],
  "skinniest": ['S K IH1 N IY0 AH0 S T'],
  "skinny": ['S K IH1 N IY0'],
  "skins": ['S K IH1 N Z'],
  "skins'": ['S K IH1 N Z'],
  "skip": ['S K IH1 P'],
  "skipjack": ['S K IH1 P JH AE2 K'],
  "skipjack's": ['S K IH1 P JH AE2 K S'],
  "skipjacks": ['S K IH1 P JH AE2 K S'],
  "skipp": ['S K IH1 P'],
  "skippa": ['S K IH1 P AH0'],
  "skippa's": ['S K IH1 P AH0 Z'],
  "skipped": ['S K IH1 P T'],
  "skipper": ['S K IH1 P ER0'],
  "skipper's": ['S K IH1 P ER0 Z'],
  "skippers": ['S K IH1 P ER0 Z'],
  "skipping": ['S K IH1 P IH0 NG'],
  "skippy": ['S K IH1 P IY0'],
  "skips": ['S K IH1 P S'],
  "skipton": ['S K IH1 P T AH0 N'],
  "skipwith": ['S K IH1 P W IH2 TH'],
  "skipworth": ['S K IH1 P W ER2 TH'],
  "skirdall": ['S K ER1 D AA2 L'],
  "skirmish": ['S K ER1 M IH0 SH'],
  "skirmishes": ['S K ER1 M IH0 SH IH0 Z'],
  "skirmishing": ['S K ER1 M IH0 SH IH0 NG'],
  "skirt": ['S K ER1 T'],
  "skirted": ['S K ER1 T IH0 D'],
  "skirting": ['S K ER1 T IH0 NG'],
  "skirts": ['S K ER1 T S'],
  "skirvin": ['S K ER1 V IH0 N'],
  "skis": ['S K IY1 Z'],
  "skit": ['S K IH1 T'],
  "skits": ['S K IH1 T S'],
  "skittish": ['S K IH1 T IH0 SH'],
  "skittishness": ['S K IH1 T IH0 SH N AH0 S'],
  "skittle": ['S K IH1 T AH0 L'],
  "skittles": ['S K IH1 T AH0 L Z'],
  "skiver": ['S K AY1 V ER0'],
  "sklar": ['S K L AA1 R'],
  "sklenar": ['S K L EH1 N ER0'],
  "skoal": ['S K OW1 L'],
  "skoczylas": ['S K AH0 CH IH1 L AH0 Z'],
  "skoda": ['S K OW1 D AA2'],
  "skoff": ['S K AO1 F'],
  "skog": ['S K AA1 G'],
  "skogen": ['S K AA1 G AH0 N'],
  "skoglund": ['S K AA1 G L AH0 N D'],
  "skok": ['S K AA1 K'],
  "skokie": ['S K OW1 K IY0'],
  "skoler": ['S K OW1 L ER0'],
  "skolnick": ['S K OW1 L N IH0 K'],
  "skolnik": ['S K OW1 L N IH0 K'],
  "skolniks": ['S K OW1 L N IH0 K S'],
  "skonieczny": ['S K AH0 N IY1 CH N IY2'],
  "skoog": ['S K UW1 G'],
  "skop": ['S K AA1 P'],
  "skopje": ['S K AO1 P Y EH2'],
  "skora": ['S K AO1 R AH0'],
  "skorupa": ['S K ER0 UW1 P AA2'],
  "skorupski": ['S K ER0 AH1 P S K IY2'],
  "skousen": ['S K UW1 S AH0 N'],
  "skov": ['S K AA1 V'],
  "skow": ['S K AW1'],
  "skowhegan": ['S K OW1 HH IY2 G AH0 N'],
  "skowron": ['S K AW1 R AH0 N'],
  "skowronek": ['S K AW0 R OW1 N EH0 K'],
  "skowronski": ['S K AW0 R AA1 N S K IY2'],
  "skramstad": ['S K R AE1 M S T AE0 D'],
  "skrew-up": ['S K R UW1 AH2 P'],
  "skrew-ups": ['S K R UW1 AH2 P S'],
  "skroch": ['S K R AA1 K'],
  "skrocki": ['S K R AA1 K IY0'],
  "skrzypek": ['S K ER0 Z IH1 P EH0 K'],
  "skudra": ['S K UW1 D R AA0'],
  "skufca": ['S K AH1 F K AA0'],
  "skulduggery": ['S K AH0 L D AH1 G ER0 IY2'],
  "skulk": ['S K AH1 L K'],
  "skulking": ['S K AH1 L K IH0 NG'],
  "skulks": ['S K AH1 L K S'],
  "skull": ['S K AH1 L'],
  "skullcap": ['S K AH1 L K AE2 P'],
  "skullcaps": ['S K AH1 L K AE2 P S'],
  "skulls": ['S K AH1 L Z'],
  "skunk": ['S K AH1 NG K'],
  "skunks": ['S K AH1 NG K S'],
  "skura": ['S K UH1 R AA0'],
  "skurdal": ['S K ER1 D AH0 L'],
  "skurka": ['S K ER1 K AA0'],
  "skutt": ['S K AH1 T'],
  "sky": ['S K AY1'],
  "sky's": ['S K AY1 Z'],
  "skybox": ['S K AY1 B AA2 K S'],
  "skyboxes": ['S K AY1 B AA2 K S IH0 Z'],
  "skycap": ['S K AY1 K AE2 P'],
  "skydive": ['S K AY1 D AY0 V'],
  "skydiving": ['S K AY1 D AY0 V IH0 NG'],
  "skydome": ['S K AY1 D OW2 M'],
  "skye": ['S K AY1'],
  "skyhawk": ['S K AY1 HH AO2 K'],
  "skyhawks": ['S K AY1 HH AO2 K S'],
  "skyhigh": ['S K AY1 HH AY2'],
  "skyhopping": ['S K AY1 HH AO0 P IH0 NG'],
  "skylark": ['S K AY1 L AA2 R K'],
  "skylarks": ['S K AY1 L AA2 R K S'],
  "skylawn": ['S K AY1 L AO2 N'],
  "skyles": ['S K AY1 L Z'],
  "skylight": ['S K AY1 L AY2 T'],
  "skylights": ['S K AY1 L AY2 T S'],
  "skyline": ['S K AY1 L AY2 N'],
  "skylines": ['S K AY1 L AY2 N Z'],
  "skylite": ['S K AY1 L AY2 T'],
  "skylites": ['S K AY1 L AY2 T S'],
  "skynet": ['S K AY1 N EH2 T'],
  "skype": ['S K AY1 P'],
  "skyrocket": ['S K AY1 R AA2 K AH0 T'],
  "skyrocketed": ['S K AY1 R AA2 K AH0 T IH0 D'],
  "skyrocketing": ['S K AY1 R AA2 K AH0 T IH0 NG'],
  "skyrockets": ['S K AY1 R AA2 K AH0 T S'],
  "skyscraper": ['S K AY1 S K R EY2 P ER0'],
  "skyscrapers": ['S K AY1 S K R EY2 P ER0 Z'],
  "skytel": ['S K AY1 T EH2 L'],
  "skywalker": ['S K AY1 W AO2 K ER0'],
  "skyward": ['S K AY1 W ER0 D'],
  "skywave": ['S K AY1 W EY2 V'],
  "skyway": ['S K AY1 W EY2'],
  "skywest": ['S K AY1 W EH2 S T'],
  "skywriter": ['S K AY1 R AY2 T ER0'],
  "skywriters": ['S K AY1 R AY2 T ER0 Z'],
  "skywriting": ['S K AY1 R AY2 T IH0 NG'],
  "slab": ['S L AE1 B'],
  "slabaugh": ['S L AE1 B AO0'],
  "slabs": ['S L AE1 B Z'],
  "slaby": ['S L EY1 B IY0'],
  "slack": ['S L AE1 K'],
  "slacked": ['S L AE1 K T'],
  "slacken": ['S L AE1 K AH0 N'],
  "slackened": ['S L AE1 K AH0 N D'],
  "slackening": ['S L AE1 K AH0 N IH0 NG'],
  "slackens": ['S L AE1 K AH0 N Z'],
  "slacker": ['S L AE1 K ER0'],
  "slackers": ['S L AE1 K ER0 Z'],
  "slackness": ['S L AE1 K N AH0 S'],
  "slacks": ['S L AE1 K S'],
  "slade": ['S L EY1 D'],
  "sladek": ['S L AE1 D IH0 K'],
  "sladky": ['S L AE1 D K IY0'],
  "slag": ['S L AE1 G'],
  "slagel": ['S L AE1 G AH0 L'],
  "slager": ['S L EY1 G ER0'],
  "slagging": ['S L AE1 G IH0 NG'],
  "slaght": ['S L AE1 T'],
  "slagle": ['S L EY1 G AH0 L'],
  "slagter": ['S L AE1 G T ER0'],
  "slain": ['S L EY1 N'],
  "slaine": ['S L EY1 N'],
  "slalom": ['S L AA1 L AH0 M'],
  "slam": ['S L AE1 M'],
  "slama": ['S L AA1 M AH0'],
  "slammed": ['S L AE1 M D'],
  "slammer": ['S L AE1 M ER0'],
  "slamming": ['S L AE1 M IH0 NG'],
  "slams": ['S L AE1 M Z'],
  "slander": ['S L AE1 N D ER0'],
  "slandered": ['S L AE1 N D ER0 D'],
  "slanderer": ['S L AE1 N D ER0 ER0'],
  "slandering": ['S L AE1 N D ER0 IH0 NG'],
  "slanderous": ['S L AE1 N D ER0 AH0 S'],
  "slanders": ['S L AE1 N D ER0 Z'],
  "slane": ['S L EY1 N'],
  "slaney": ['S L EY1 N IY0'],
  "slang": ['S L AE1 NG'],
  "slanina": ['S L AA0 N IY1 N AH0'],
  "slankard": ['S L AE1 NG K ER0 D'],
  "slant": ['S L AE1 N T'],
  "slanted": ['S L AE1 N T IH0 D', 'S L AE1 N AH0 D', 'S L AE1 N IH0 D'],
  "slanting": ['S L AE1 N T IH0 NG'],
  "slap": ['S L AE1 P'],
  "slapdash": ['S L AE1 P D AE2 SH'],
  "slape": ['S L EY1 P'],
  "slaphappy": ['S L AE1 P HH AE2 P IY0'],
  "slapped": ['S L AE1 P T'],
  "slappey": ['S L AE1 P IY0'],
  "slapping": ['S L AE1 P IH0 NG'],
  "slaps": ['S L AE1 P S'],
  "slapstick": ['S L AE1 P S T IH2 K'],
  "slash": ['S L AE1 SH'],
  "slashed": ['S L AE1 SH T'],
  "slasher": ['S L AE1 SH ER0'],
  "slashes": ['S L AE1 SH IH0 Z'],
  "slashing": ['S L AE1 SH IH0 NG'],
  "slat": ['S L AE1 T'],
  "slate": ['S L EY1 T'],
  "slated": ['S L EY1 T IH0 D'],
  "slaten": ['S L EY1 T AH0 N'],
  "slater": ['S L EY1 T ER0'],
  "slater's": ['S L EY1 T ER0 Z'],
  "slaters": ['S L EY1 T ER0 Z'],
  "slates": ['S L EY1 T S'],
  "slather": ['S L AE1 DH ER0'],
  "slathered": ['S L AE1 DH ER0 D'],
  "slathering": ['S L AE1 DH ER0 IH0 NG'],
  "slathers": ['S L AE1 DH ER0 Z'],
  "slating": ['S L EY1 T IH0 NG'],
  "slatkin": ['S L AE1 T K IH0 N'],
  "slaton": ['S L AE1 T AH0 N'],
  "slats": ['S L AE1 T S'],
  "slatten": ['S L AE1 T AH0 N'],
  "slatter": ['S L AE1 T ER0'],
  "slattery": ['S L AE1 T ER0 IY0'],
  "slatton": ['S L AE1 T AH0 N'],
  "slaugh": ['S L AO1'],
  "slaughter": ['S L AO1 T ER0'],
  "slaughtered": ['S L AO1 T ER0 D'],
  "slaughterhouse": ['S L AO1 T ER0 HH AW2 S'],
  "slaughterhouses": ['S L AO1 T ER0 HH AW2 S IH0 Z'],
  "slaughtering": ['S L AO1 T ER0 IH0 NG'],
  "slaughterings": ['S L AO1 T ER0 IH0 NG Z'],
  "slaughters": ['S L AO1 T ER0 Z'],
  "slauson": ['S L AW1 Z AH0 N'],
  "slav": ['S L AA1 V'],
  "slava": ['S L AA1 V AH0'],
  "slave": ['S L EY1 V'],
  "slaven": ['S L EY1 V AH0 N'],
  "slavens": ['S L EY1 V AH0 N Z'],
  "slavery": ['S L EY1 V ER0 IY0'],
  "slaves": ['S L EY1 V Z'],
  "slavic": ['S L AA1 V IH0 K'],
  "slavick": ['S L AE1 V IH0 K'],
  "slavik": ['S L AA1 V IH0 K'],
  "slavin": ['S L AE1 V IH0 N'],
  "slavinski": ['S L AH0 V IH1 N S K IY0'],
  "slavish": ['S L EY1 V IH0 SH'],
  "slavishly": ['S L AE1 V IH0 SH L IY0'],
  "slavonia": ['S L AH0 V OW1 N IY0 AH0', 'S L AH0 V OW1 N Y AH0'],
  "slavs": ['S L AA1 V Z'],
  "slaw": ['S L AO1'],
  "slawinski": ['S L AA0 V IH1 N S K IY0'],
  "slawson": ['S L AO1 S AH0 N'],
  "slay": ['S L EY1'],
  "slayback": ['S L EY1 B AE2 K'],
  "slaybaugh": ['S L EY1 B AO2'],
  "slayden": ['S L EY1 D AH0 N'],
  "slaydon": ['S L EY1 D AH0 N'],
  "slayer": ['S L EY1 ER0'],
  "slaying": ['S L EY1 IH0 NG'],
  "slayings": ['S L EY1 IH0 NG Z'],
  "slaymaker": ['S L EY1 M EY2 K ER0'],
  "slayter": ['S L EY1 T ER0'],
  "slayton": ['S L EY1 T AH0 N'],
  "sleasman": ['S L IY1 Z M AH0 N'],
  "sleator": ['S L EY1 T ER0'],
  "sleaze": ['S L IY1 Z'],
  "sleazy": ['S L IY1 Z IY0'],
  "slechta": ['S L EH1 CH T AH0'],
  "sled": ['S L EH1 D'],
  "sledd": ['S L EH1 D'],
  "sledding": ['S L EH1 D IH0 NG'],
  "sledge": ['S L EH1 JH'],
  "sledgehammer": ['S L EH1 JH HH AE2 M ER0'],
  "sleds": ['S L EH1 D Z'],
  "sledz": ['S L EH1 D Z'],
  "slee": ['S L IY1'],
  "sleek": ['S L IY1 K'],
  "sleeker": ['S L IY1 K ER0'],
  "sleekest": ['S L IY1 K AH0 S T'],
  "sleekly": ['S L IY1 K L IY0'],
  "sleeman": ['S L IY1 M AH0 N'],
  "sleep": ['S L IY1 P'],
  "sleeper": ['S L IY1 P ER0'],
  "sleepers": ['S L IY1 P ER0 Z'],
  "sleepily": ['S L IY1 P AH0 L IY0'],
  "sleepiness": ['S L IY1 P IY0 N AH0 S'],
  "sleeping": ['S L IY1 P IH0 NG'],
  "sleepless": ['S L IY1 P L AH0 S'],
  "sleepover": ['S L IY1 P OW2 V ER0'],
  "sleepovers": ['S L IY1 P OW2 V ER0 Z'],
  "sleeps": ['S L IY1 P S'],
  "sleepwalk": ['S L IY1 P W AO2 K'],
  "sleepwalker": ['S L IY1 P W AO2 K ER0'],
  "sleepwalkers": ['S L IY1 P W AO2 K ER0 Z'],
  "sleepwalking": ['S L IY1 P W AO2 K IH0 NG'],
  "sleepwear": ['S L IY1 P W EH2 R'],
  "sleepy": ['S L IY1 P IY0'],
  "sleet": ['S L IY1 T'],
  "sleeter": ['S L IY1 T ER0'],
  "sleeth": ['S L IY1 TH'],
  "sleeve": ['S L IY1 V'],
  "sleeved": ['S L IY1 V D'],
  "sleeveless": ['S L IY1 V L IH0 S'],
  "sleeves": ['S L IY1 V Z'],
  "sleigh": ['S L EY1'],
  "sleight": ['S L AY1 T'],
  "sleipner": ['S L AY1 P N ER0'],
  "slemmer": ['S L EH1 M ER0'],
  "slemp": ['S L EH1 M P'],
  "slender": ['S L EH1 N D ER0'],
  "slentz": ['S L EH1 N T S'],
  "slepian": ['S L IY1 P IY0 AH0 N', 'S L IY1 P Y AH0 N'],
  "slept": ['S L EH1 P T'],
  "slessenger": ['S L EH1 S EH0 N G ER0'],
  "sletten": ['S L EH1 T AH0 N'],
  "sleuth": ['S L UW1 TH'],
  "sleuthing": ['S L UW1 TH IH0 NG'],
  "sleuths": ['S L UW1 TH S'],
  "sleven": ['S L IY1 V AH0 N'],
  "slevin": ['S L EH1 V IH0 N'],
  "slew": ['S L UW1'],
  "slezak": ['S L EH1 Z AH0 K'],
  "slice": ['S L AY1 S'],
  "sliced": ['S L AY1 S T'],
  "slicer": ['S L AY1 S ER0'],
  "slices": ['S L AY1 S AH0 Z', 'S L AY1 S IH0 Z'],
  "slicing": ['S L AY1 S IH0 NG'],
  "slick": ['S L IH1 K'],
  "slicked": ['S L IH1 K T'],
  "slicker": ['S L IH1 K ER0'],
  "slickers": ['S L IH1 K ER0 Z'],
  "slickest": ['S L IH1 K AH0 S T'],
  "slickly": ['S L IH1 K L IY0'],
  "slicks": ['S L IH1 K S'],
  "slid": ['S L IH1 D'],
  "slide": ['S L AY1 D'],
  "slider": ['S L AY1 D ER0'],
  "slides": ['S L AY1 D Z'],
  "sliding": ['S L AY1 D IH0 NG'],
  "slife": ['S L AY1 F'],
  "slifer": ['S L AY1 F ER0'],
  "slifka": ['S L IH1 F K AH0'],
  "slifko": ['S L IH1 F K OW0'],
  "sligar": ['S L IH1 G ER0'],
  "sliger": ['S L AY1 G ER0'],
  "sligh": ['S L AY1'],
  "slight": ['S L AY1 T'],
  "slighted": ['S L AY1 T IH0 D'],
  "slightest": ['S L AY1 T AH0 S T'],
  "slighting": ['S L AY1 T IH0 NG'],
  "slightly": ['S L AY1 T L IY0'],
  "slights": ['S L AY1 T S'],
  "sliker": ['S L AY1 K ER0'],
  "slim": ['S L IH1 M'],
  "slimak": ['S L IH1 M AH0 K'],
  "slime": ['S L AY1 M'],
  "slimmed": ['S L IH1 M D'],
  "slimmer": ['S L IH1 M ER0'],
  "slimmest": ['S L IH1 M AH0 S T'],
  "slimming": ['S L IH1 M IH0 NG'],
  "slimp": ['S L IH1 M P'],
  "slims": ['S L IH1 M Z'],
  "slimy": ['S L AY1 M IY0'],
  "sliney": ['S L IH1 N IY0'],
  "sling": ['S L IH1 NG'],
  "slinger": ['S L IH1 NG ER0'],
  "slingerland": ['S L IH1 NG G ER0 L AH0 N D'],
  "slinging": ['S L IH1 NG IH0 NG'],
  "slings": ['S L IH1 NG Z'],
  "slingshot": ['S L IH1 NG SH AA2 T'],
  "slingshots": ['S L IH1 NG SH AA2 T S'],
  "slinkard": ['S L IH1 NG K ER0 D'],
  "slinker": ['S L IH1 NG K ER0'],
  "slinky": ['S L IH1 NG K IY0'],
  "slip": ['S L IH1 P'],
  "slip-on": ['S L IH1 P AA2 N'],
  "slip-ons": ['S L IH1 P AA2 N Z'],
  "slippage": ['S L IH1 P IH0 JH'],
  "slipped": ['S L IH1 P T'],
  "slipper": ['S L IH1 P ER0'],
  "slippers": ['S L IH1 P ER0 Z'],
  "slippery": ['S L IH1 P ER0 IY0', 'S L IH1 P R IY0'],
  "slipping": ['S L IH1 P IH0 NG'],
  "slips": ['S L IH1 P S'],
  "slipshod": ['S L IH1 P SH AA2 D'],
  "slit": ['S L IH1 T'],
  "sliter": ['S L IY1 T ER0'],
  "slither": ['S L IH1 DH ER0'],
  "slithering": ['S L IH1 DH ER0 IH0 NG'],
  "slits": ['S L IH1 T S'],
  "slitting": ['S L IH1 T IH0 NG'],
  "sliva": ['S L IY1 V AH0'],
  "sliver": ['S L IH1 V ER0'],
  "slivers": ['S L IH1 V ER0 Z'],
  "slivinski": ['S L IH0 V IH1 N S K IY0'],
  "slivka": ['S L IH1 V K AH0'],
  "slivovitz": ['S L IH1 V AH0 V IH0 T S'],
  "sliwa": ['S L AY1 V AH0'],
  "sliwinski": ['S L IH0 V IH1 N S K IY0'],
  "sloan": ['S L OW1 N'],
  "sloan's": ['S L OW1 N Z'],
  "sloane": ['S L OW1 N'],
  "sloat": ['S L OW1 T'],
  "sloate": ['S L OW1 T'],
  "slob": ['S L AA1 B'],
  "slobber": ['S L AA1 B ER0'],
  "slobbering": ['S L AA1 B ER0 IH0 NG'],
  "sloboda": ['S L AH0 B OW1 D AH0'],
  "slobodan": ['S L OW1 B OW0 D AA2 N'],
  "slobogin": ['S L AH0 B OW1 G AH0 N'],
  "slobogin's": ['S L AH0 B OW1 G AH0 N Z'],
  "slobs": ['S L AA1 B Z'],
  "slocan": ['S L OW1 K AH0 N'],
  "slocan's": ['S L OW1 K AH0 N Z'],
  "slocomb": ['S L OW1 K AH0 M'],
  "slocum": ['S L OW1 K AH0 M'],
  "slocumb": ['S L OW1 K AH0 M'],
  "sloe": ['S L OW1'],
  "slog": ['S L AA1 G'],
  "slogan": ['S L OW1 G AH0 N'],
  "slogans": ['S L OW1 G AH0 N Z'],
  "slogged": ['S L AA1 G D'],
  "slogging": ['S L AA1 G IH0 NG'],
  "slokum": ['S L OW1 K AH0 M'],
  "sloma": ['S L OW1 M AH0'],
  "sloman": ['S L OW1 M AH0 N'],
  "slominski": ['S L AH0 M IH1 N S K IY0'],
  "slomski": ['S L AA1 M S K IY0'],
  "slonaker": ['S L AA1 N AH0 K ER0'],
  "slone": ['S L OW1 N'],
  "sloniker": ['S L AA1 N IH0 K ER0'],
  "sloop": ['S L UW1 P'],
  "slop": ['S L AA1 P'],
  "slope": ['S L OW1 P'],
  "sloper": ['S L OW1 P ER0'],
  "slopes": ['S L OW1 P S'],
  "sloping": ['S L OW1 P IH0 NG'],
  "sloppier": ['S L AA1 P IY0 ER0'],
  "sloppiest": ['S L AA1 P IY0 AH0 S T'],
  "sloppily": ['S L AA1 P AH0 L IY0'],
  "sloppiness": ['S L AA1 P IY0 N AH0 S'],
  "sloppy": ['S L AA1 P IY0'],
  "slorc": ['S L AO1 R K'],
  "slosh": ['S L AA1 SH'],
  "sloshing": ['S L AA1 SH IH0 NG'],
  "sloss": ['S L AO1 S'],
  "slot": ['S L AA1 T'],
  "slota": ['S L OW1 T AH0'],
  "sloth": ['S L OW1 TH'],
  "slothower": ['S L AA1 TH OW0 ER0'],
  "slotnick": ['S L AA1 T N IH0 K'],
  "slots": ['S L AA1 T S'],
  "slott": ['S L AA1 T'],
  "slotted": ['S L AA1 T IH0 D'],
  "slotting": ['S L AA1 T IH0 NG'],
  "slouch": ['S L AW1 CH'],
  "slouches": ['S L AW1 CH IH0 Z'],
  "slouching": ['S L AW1 CH IH0 NG'],
  "slough": ['S L AH1 F'],
  "sloughs": ['S L AH1 F S'],
  "slovacek": ['S L AA1 V AH0 CH EH0 K'],
  "slovak": ['S L OW1 V AE0 K', 'S L OW1 V AA0 K'],
  "slovakia": ['S L OW2 V AA1 K IY2 AH0'],
  "slovakia's": ['S L OW0 V AA1 K IY2 AH0 Z', 'S L OW0 V AE1 K IY0 AH0 Z'],
  "slovakian": ['S L OW1 V AA0 K IY2 AH0 N'],
  "slovaks": ['S L OW1 V AE0 K S', 'S L OW1 V AA0 K S'],
  "slovene": ['S L OW2 V IY1 N'],
  "slovenes": ['S L OW2 V IY1 N Z'],
  "slovenia": ['S L OW2 V IY1 N IY0 AH0'],
  "slovenian": ['S L OW2 V IY1 N IY0 AH0 N'],
  "slovenliness": ['S L AH1 V AH0 N L IY0 N AH0 S'],
  "slovenly": ['S L AH1 V AH0 N L IY0'],
  "slover": ['S L OW1 V ER0'],
  "sloves": ['S L OW1 V Z'],
  "slovo": ['S L OW1 V OW0'],
  "slovonia": ['S L AH0 V OW1 N IY0 AH0', 'S L AH0 V OW1 N Y AH0'],
  "slow": ['S L OW1'],
  "slowdown": ['S L OW1 D AW2 N'],
  "slowdowns": ['S L OW1 D AW2 N Z'],
  "slowed": ['S L OW1 D'],
  "slower": ['S L OW1 ER0'],
  "slowest": ['S L OW1 AH0 S T'],
  "slowey": ['S L OW1 IY0'],
  "slowik": ['S L OW1 IH0 K'],
  "slowing": ['S L OW1 IH0 NG'],
  "slowinski": ['S L OW0 IH1 N S K IY0'],
  "slowly": ['S L OW1 L IY0'],
  "slowness": ['S L OW1 N AH0 S'],
  "slowpoke": ['S L OW1 P OW2 K'],
  "slows": ['S L OW1 Z'],
  "sluder": ['S L UW1 D ER0'],
  "sludge": ['S L AH1 JH'],
  "sludgy": ['S L AH1 JH IY0'],
  "slug": ['S L AH1 G'],
  "sluga": ['S L UW1 G AH0'],
  "slugfest": ['S L AH1 G F EH2 S T'],
  "slugged": ['S L AH1 G D'],
  "slugger": ['S L AH1 G ER0'],
  "sluggers": ['S L AH1 G ER0 Z'],
  "slugging": ['S L AH1 G IH0 NG'],
  "sluggish": ['S L AH1 G IH0 SH'],
  "sluggishly": ['S L AH1 G IH0 SH L IY0'],
  "sluggishness": ['S L AH1 G IH0 SH N AH0 S'],
  "slugs": ['S L AH1 G Z'],
  "sluice": ['S L UW1 S'],
  "sluicing": ['S L UW1 S IH0 NG'],
  "sluiter": ['S L UW1 T ER0'],
  "sluka": ['S L UW1 K AH0'],
  "slum": ['S L AH1 M'],
  "slumber": ['S L AH1 M B ER0'],
  "slumbering": ['S L AH1 M B ER0 IH0 NG'],
  "slump": ['S L AH1 M P'],
  "slumped": ['S L AH1 M P T'],
  "slumping": ['S L AH1 M P IH0 NG'],
  "slumps": ['S L AH1 M P S'],
  "slums": ['S L AH1 M Z'],
  "slung": ['S L AH1 NG'],
  "slupic": ['S L UW1 P IH0 K'],
  "slur": ['S L ER1'],
  "slurp": ['S L ER1 P'],
  "slurred": ['S L ER1 D'],
  "slurring": ['S L ER1 IH0 NG'],
  "slurry": ['S L ER1 IY0'],
  "slurs": ['S L ER1 Z'],
  "slush": ['S L AH1 SH'],
  "slushayete": ['S L UH0 SH EY0 EH1 T'],
  "slusher": ['S L AH1 SH ER0'],
  "sluss": ['S L AH1 S'],
  "slusser": ['S L AH1 S ER0'],
  "slut": ['S L AH1 T'],
  "sluts": ['S L AH1 T S'],
  "slutsky": ['S L AH1 T S K IY0'],
  "slutty": ['S L AH1 T IY2'],
  "slutz": ['S L AH1 T S'],
  "slutzky": ['S L AH1 T S K IY0'],
  "sluyter": ['S L AY1 T ER0'],
  "sly": ['S L AY1'],
  "slye": ['S L AY1'],
  "slyly": ['S L AY1 L IY0'],
  "slyness": ['S L AY1 N AH0 S'],
  "slyter": ['S L AY1 T ER0'],
  "smaby": ['S M EY1 B IY0'],
  "smack": ['S M AE1 K'],
  "smacked": ['S M AE1 K T'],
  "smacking": ['S M AE1 K IH0 NG'],
  "smacks": ['S M AE1 K S'],
  "smail": ['S M EY1 L'],
  "smaldone": ['S M AE1 L D AH0 N'],
  "smale": ['S M EY1 L'],
  "small": ['S M AO1 L'],
  "smallcap": ['S M AO1 L K AE2 P'],
  "smallen": ['S M AO1 L AH0 N'],
  "smaller": ['S M AO1 L ER0'],
  "smallest": ['S M AO1 L AH0 S T'],
  "smalley": ['S M AA1 L IY0'],
  "smalling": ['S M AO1 L IH0 NG'],
  "smallish": ['S M AO1 L IH0 SH'],
  "smallman": ['S M AO1 L M AH0 N'],
  "smallness": ['S M AO1 L N AH0 S'],
  "smallpox": ['S M AO1 L P AA2 K S'],
  "smallridge": ['S M AO1 L R IH2 JH'],
  "smalls": ['S M AO1 L Z'],
  "smalltalk": ['S M AO1 L T AO2 K'],
  "smalltown": ['S M AO1 L T AW2 N'],
  "smallwood": ['S M AO1 L W UH2 D'],
  "smaltz": ['S M AE1 L T S'],
  "smarmy": ['S M AA1 R M IY0'],
  "smarr": ['S M AE1 R'],
  "smart": ['S M AA1 R T'],
  "smart-ass": ['S M AA1 R T AE2 S'],
  "smartaleck": ['S M AA1 R T EH0 L AH0 K'],
  "smartass": ['S M AA1 R T AE1 S'],
  "smartcard": ['S M AA1 R T K AA2 R D'],
  "smarted": ['S M AA1 R T IH0 D'],
  "smarten": ['S M AA1 R T AH0 N'],
  "smartened": ['S M AA1 R T AH0 N D'],
  "smartening": ['S M AA1 R T AH0 N IH0 NG'],
  "smarter": ['S M AA1 R T ER0'],
  "smartest": ['S M AA1 R T AH0 S T'],
  "smarties": ['S M AA1 R T IY0 Z'],
  "smarting": ['S M AA1 R T IH0 NG'],
  "smartish": ['S M AA1 R T IH0 SH'],
  "smartly": ['S M AA1 R T L IY0'],
  "smartmoney": ['S M AA1 R T M AH2 N IY0'],
  "smartness": ['S M AA1 R T N IH0 S'],
  "smartphone": ['S M AA1 R T F OW2 N'],
  "smartphones": ['S M AA1 R T F OW2 N Z'],
  "smarts": ['S M AA1 R T S'],
  "smartt": ['S M AA1 R T'],
  "smarty": ['S M AA1 R T IY0'],
  "smash": ['S M AE1 SH'],
  "smashed": ['S M AE1 SH T'],
  "smasher": ['S M AE1 SH ER0'],
  "smashes": ['S M AE1 SH IH0 Z'],
  "smashing": ['S M AE1 SH IH0 NG'],
  "smather": ['S M AE1 DH ER0'],
  "smathers": ['S M AE1 DH ER0 Z'],
  "smatter": ['S M AE1 T ER0'],
  "smattering": ['S M AE1 T ER0 IH0 NG'],
  "smay": ['S M EY1'],
  "smead": ['S M IY1 D'],
  "smeal": ['S M IY1 L'],
  "smear": ['S M IH1 R'],
  "smeared": ['S M IH1 R D'],
  "smearing": ['S M IH1 R IH0 NG'],
  "smears": ['S M IH1 R Z'],
  "smeby": ['S M IY1 B IY0'],
  "smedberg": ['S M EH1 D B ER0 G'],
  "smedley": ['S M EH1 D L IY0'],
  "smee": ['S M IY1'],
  "smeeting": ['S M IY1 T IH0 NG'],
  "smejkal": ['S M EH1 JH K AH0 L'],
  "smelcer": ['S M EH1 L S ER0'],
  "smell": ['S M EH1 L'],
  "smelled": ['S M EH1 L D'],
  "smelley": ['S M EH1 L IY0'],
  "smelling": ['S M EH1 L IH0 NG'],
  "smells": ['S M EH1 L Z'],
  "smelly": ['S M EH1 L IY0'],
  "smelser": ['S M EH1 L S ER0'],
  "smelt": ['S M EH1 L T'],
  "smelter": ['S M EH1 L T ER0'],
  "smelter's": ['S M EH1 L T ER0 Z'],
  "smelters": ['S M EH1 L T ER0 Z'],
  "smelting": ['S M EH1 L T IH0 NG'],
  "smeltz": ['S M EH1 L T S'],
  "smeltzer": ['S M EH1 L T Z ER0'],
  "smestad": ['S M EH1 S T AH0 D'],
  "smet": ['S M EH1 T'],
  "smetana": ['S M EH1 T AH0 N AH0'],
  "smethers": ['S M EH1 DH ER0 Z'],
  "smethurst": ['S M EH1 TH ER0 S T'],
  "smetzer": ['S M EH1 T Z ER0'],
  "smick": ['S M IH1 K'],
  "smid": ['S M IH1 D'],
  "smiddy": ['S M IH1 D IY0'],
  "smidgen": ['S M IH1 JH AH0 N'],
  "smidt": ['S M IH1 D T'],
  "smigel": ['S M IH1 JH AH0 L'],
  "smigelski": ['S M IH0 G EH1 L S K IY0'],
  "smigiel": ['S M IH1 JH IY0 L'],
  "smigielski": ['S M IH0 G IY1 L S K IY0'],
  "smile": ['S M AY1 L'],
  "smiled": ['S M AY1 L D'],
  "smiles": ['S M AY1 L Z'],
  "smiley": ['S M AY1 L IY0'],
  "smilgis": ['S M IH1 L G IH0 Z', 'S M IH1 L JH IH0 Z'],
  "smilie": ['S M IH1 L IY0'],
  "smiling": ['S M AY1 L IH0 NG'],
  "smilingly": ['S M AY1 L IH0 NG L IY0'],
  "smillie": ['S M IH1 L IY0'],
  "smilow": ['S M IH1 L OW0'],
  "smink": ['S M IH1 NG K'],
  "smirk": ['S M ER1 K'],
  "smirking": ['S M ER1 K IH0 NG'],
  "smirks": ['S M ER1 K S'],
  "smirnoff": ['S M ER1 N AO0 F'],
  "smirnov": ['S M ER1 N AA0 F'],
  "smirnova": ['S M ER2 N OW1 V AH0'],
  "smisek": ['S M IH1 S EH0 K'],
  "smit": ['S M IH1 T'],
  "smith": ['S M IH1 TH'],
  "smith's": ['S M IH1 TH S'],
  "smithburg": ['S M IH1 TH B ER0 G'],
  "smithee": ['S M IH1 TH IY1'],
  "smither": ['S M IH1 DH ER0'],
  "smithereen": ['S M IH2 DH ER0 IY1 N'],
  "smithereens": ['S M IH2 DH ER0 IY1 N Z'],
  "smitherman": ['S M IH1 DH ER0 M AH0 N'],
  "smithers": ['S M IH1 DH ER0 Z'],
  "smithey": ['S M IH1 TH IY0'],
  "smithfield": ['S M IH1 TH F IY0 L D'],
  "smithhart": ['S M IH1 TH HH AA2 R T'],
  "smithkline": ['S M IH1 TH K L AY2 N'],
  "smithkline's": ['S M IH1 TH K L AY2 N Z'],
  "smiths": ['S M IH1 TH S'],
  "smithson": ['S M IH1 TH S AH0 N'],
  "smithson's": ['S M IH1 TH S AH0 N Z'],
  "smithsonian": ['S M IH0 TH S OW1 N IY0 AH0 N', 'S M IH0 S OW1 N IY0 AH0 N'],
  "smithsonian's": ['S M IH2 TH S OW1 N IY0 AH0 N Z', 'S M IH2 S OW1 N IY0 AH0 N Z'],
  "smithtown": ['S M IH1 TH T AW2 N'],
  "smithwick": ['S M IH1 TH W IH2 K'],
  "smitley": ['S M IH1 T L IY0'],
  "smits": ['S M IH1 T S'],
  "smitten": ['S M IH1 T AH0 N'],
  "smittle": ['S M IH1 T AH0 L'],
  "smitty": ['S M IH1 T IY0'],
  "smoak": ['S M OW1 K'],
  "smock": ['S M AA1 K'],
  "smog": ['S M AA1 G'],
  "smoggiest": ['S M AA1 G IY0 AH0 S T'],
  "smoggy": ['S M AO1 G IY0'],
  "smoke": ['S M OW1 K'],
  "smoked": ['S M OW1 K T'],
  "smokejumper": ['S M OW1 K JH AH2 M P ER0'],
  "smokejumpers": ['S M OW1 K JH AH2 M P ER0 Z'],
  "smokeless": ['S M OW1 K L AH0 S'],
  "smoker": ['S M OW1 K ER0'],
  "smoker's": ['S M OW1 K ER0 Z'],
  "smokers": ['S M OW1 K ER0 Z'],
  "smokers'": ['S M OW1 K ER0 Z'],
  "smokes": ['S M OW1 K S'],
  "smokescreen": ['S M OW1 K S K R IY2 N'],
  "smokestack": ['S M OW1 K S T AE2 K'],
  "smokestacks": ['S M OW1 K S T AE2 K S'],
  "smokey": ['S M OW1 K IY0'],
  "smoking": ['S M OW1 K IH0 NG'],
  "smoking's": ['S M OW1 K IH0 NG Z'],
  "smoky": ['S M OW1 K IY0'],
  "smola": ['S M OW1 L AA0'],
  "smolak": ['S M OW1 L AH0 K'],
  "smolder": ['S M OW1 L D ER0'],
  "smoldering": ['S M OW1 L D ER0 IH0 NG'],
  "smolen": ['S M AA1 L AH0 N'],
  "smolensk": ['S M OW0 L EH1 N S K'],
  "smolenski": ['S M AH0 L EH1 N S K IY0'],
  "smoley": ['S M OW1 L IY0'],
  "smolik": ['S M OW1 L IH0 K'],
  "smolin": ['S M OW1 L IH0 N'],
  "smolinski": ['S M AH0 L IH1 N S K IY0'],
  "smolinsky": ['S M AH0 L IH1 N S K IY0'],
  "smolka": ['S M OW1 L K AH0'],
  "smooch": ['S M UW1 CH'],
  "smooches": ['S M UW1 CH IH0 Z'],
  "smoot": ['S M UW1 T'],
  "smooth": ['S M UW1 DH'],
  "smooth-shaven": ['S M UW2 TH SH EY1 V AH0 N'],
  "smoothed": ['S M UW1 DH D'],
  "smoothen": ['S M UW1 DH IH0 N'],
  "smoothens": ['S M UW1 DH IH0 N Z'],
  "smoother": ['S M UW1 DH ER0'],
  "smoothest": ['S M UW1 DH AH0 S T'],
  "smoothing": ['S M UW1 DH IH0 NG'],
  "smoothly": ['S M UW1 DH L IY0'],
  "smoothness": ['S M UW1 DH N AH0 S'],
  "smooths": ['S M UW1 DH Z'],
  "smoots": ['S M UW1 T S'],
  "smorgasbord": ['S M AO1 R G AH0 S B AO2 R D'],
  "smosh": ['S M UH1 SH'],
  "smoshed": ['S M UH1 SH T'],
  "smoshes": ['S M UH1 SH AH0 Z'],
  "smoshing": ['S M UH1 SH IH0 NG'],
  "smother": ['S M AH1 DH ER0'],
  "smothered": ['S M AH1 DH ER0 D'],
  "smothering": ['S M AH1 DH ER0 IH0 NG'],
  "smotherman": ['S M AH1 DH ER0 M AH0 N'],
  "smothermon": ['S M AA1 TH ER0 M OW0 N'],
  "smothers": ['S M AH1 DH ER0 Z'],
  "smotrich": ['S M AA1 T R IH0 K'],
  "smouse": ['S M AW1 S'],
  "smoyer": ['S M OY1 ER0'],
  "smrekar": ['S M R EH1 K ER0', 'S M ER1 IH0 K ER0'],
  "sms": ['EH2 S EH2 M EH1 S'],
  "smuck": ['S M AH1 K'],
  "smucker": ['S M AH1 K ER0'],
  "smudge": ['S M AH1 JH'],
  "smudged": ['S M AH1 JH D'],
  "smug": ['S M AH1 G'],
  "smuggle": ['S M AH1 G AH0 L'],
  "smuggled": ['S M AH1 G AH0 L D'],
  "smuggler": ['S M AH1 G L ER0', 'S M AH1 G AH0 L ER0'],
  "smuggler's": ['S M AH1 G L ER0 Z'],
  "smugglers": ['S M AH1 G L ER0 Z', 'S M AH1 G AH0 L ER0 Z'],
  "smugglers'": ['S M AH1 G L ER0 Z', 'S M AH1 G AH0 L ER0 Z'],
  "smuggling": ['S M AH1 G L IH0 NG', 'S M AH1 G AH0 L IH0 NG'],
  "smugly": ['S M AH1 G L IY0'],
  "smugness": ['S M AH1 G N AH0 S'],
  "smull": ['S M AH1 L'],
  "smullen": ['S M AH1 L AH0 N'],
  "smurf": ['S M ER1 F'],
  "smurfit": ['S M ER1 F IH0 T'],
  "smurfs": ['S M ER1 F S'],
  "smut": ['S M AH1 T'],
  "smutty": ['S M AH1 T IY2'],
  "smyers": ['S M AY1 ER0 Z'],
  "smylie": ['S M IH1 L IY0'],
  "smyly": ['S M IH1 L IY0'],
  "smyre": ['S M AY1 R'],
  "smyrna": ['S M ER1 N AH0'],
  "smyser": ['S M AY1 S ER0'],
  "smyth": ['S M AY1 TH', 'S M IH1 TH'],
  "smythe": ['S M AY1 DH'],
  "snabel": ['S N AA1 B AH0 L'],
  "snack": ['S N AE1 K'],
  "snackbar": ['S N AE1 K B AA0 R'],
  "snacked": ['S N AE1 K T'],
  "snacking": ['S N AE1 K IH0 NG'],
  "snacks": ['S N AE1 K S'],
  "snackwell": ['S N AE1 K W EH2 L'],
  "snackwell's": ['S N AE1 K W EH2 L Z'],
  "snackwells": ['S N AE1 K W EH2 L Z'],
  "snader": ['S N EY1 D ER0'],
  "snafu": ['S N AE1 F UW0'],
  "snafus": ['S N AE1 F AH0 S'],
  "snag": ['S N AE1 G'],
  "snagged": ['S N AE1 G D'],
  "snagger": ['S N AE1 G ER0'],
  "snaggers": ['S N AE1 G ER0 Z'],
  "snagging": ['S N AE1 G IH0 NG'],
  "snags": ['S N AE1 G Z'],
  "snail": ['S N EY1 L'],
  "snail's": ['S N EY1 L Z'],
  "snails": ['S N EY1 L Z'],
  "snake": ['S N EY1 K'],
  "snake's": ['S N EY1 K S'],
  "snakebite": ['S N EY1 K B AY2 T'],
  "snakebites": ['S N EY1 K B AY2 T S'],
  "snakelike": ['S N EY1 K L AY2 K'],
  "snakes": ['S N EY1 K S'],
  "snaking": ['S N EY1 K IH0 NG'],
  "snap": ['S N AE1 P'],
  "snapchat": ['S N AE1 P CH AE2 T'],
  "snapdragon": ['S N AE1 P D R AE2 G AH0 N'],
  "snapdragons": ['S N AE1 P D R AE2 G AH0 N Z'],
  "snape": ['S N EY1 P'],
  "snapp": ['S N AE1 P'],
  "snapped": ['S N AE1 P T'],
  "snapper": ['S N AE1 P ER0'],
  "snappiest": ['S N AE1 P IH0 EH2 S T'],
  "snapping": ['S N AE1 P IH0 NG'],
  "snapple": ['S N AE1 P AH0 L'],
  "snapple's": ['S N AE1 P AH0 L Z'],
  "snapples": ['S N AE1 P AH0 L Z'],
  "snappy": ['S N AE1 P IY0'],
  "snaps": ['S N AE1 P S'],
  "snapshot": ['S N AE1 P SH AA2 T'],
  "snapshots": ['S N AE1 P SH AA2 T S'],
  "snare": ['S N EH1 R'],
  "snared": ['S N EH1 R D'],
  "snares": ['S N EH1 R Z'],
  "snaring": ['S N EH1 R IH0 NG'],
  "snarl": ['S N AA1 R L'],
  "snarled": ['S N AA1 R AH0 L D'],
  "snarling": ['S N AA1 R L IH0 NG'],
  "snarls": ['S N AA1 R L Z'],
  "snarr": ['S N AE1 R'],
  "snarski": ['S N AA1 R S K IY0'],
  "snatch": ['S N AE1 CH'],
  "snatched": ['S N AE1 CH T'],
  "snatcher": ['S N AE1 CH ER0'],
  "snatchers": ['S N AE1 CH ER0 Z'],
  "snatches": ['S N AE1 CH IH0 Z'],
  "snatching": ['S N AE1 CH IH0 NG'],
  "snavely": ['S N EY1 V L IY0'],
  "snay": ['S N EY1'],
  "snazzier": ['S N AE1 Z IY0 ER0'],
  "snazzy": ['S N AE1 Z IY0'],
  "snead": ['S N IY1 D'],
  "sneak": ['S N IY1 K'],
  "sneaked": ['S N IY1 K T'],
  "sneaker": ['S N IY1 K ER0'],
  "sneakers": ['S N IY1 K ER0 Z'],
  "sneaking": ['S N IY1 K IH0 NG'],
  "sneaks": ['S N IY1 K S'],
  "sneaky": ['S N IY1 K IY0'],
  "sneary": ['S N IH1 R IY0'],
  "sneath": ['S N EH1 TH'],
  "snecma": ['S N EH1 K M AA0'],
  "snedaker": ['S N EH1 D AH0 K ER0'],
  "snedden": ['S N EH1 D AH0 N'],
  "sneddon": ['S N EH1 D AH0 N'],
  "snedegar": ['S N EH1 D IH0 G ER0'],
  "snedeker": ['S N EH1 D IH0 K ER0'],
  "snee": ['S N IY1'],
  "sneed": ['S N IY1 D'],
  "sneer": ['S N IH1 R'],
  "sneered": ['S N IH1 R D'],
  "sneering": ['S N IH1 R IH0 NG'],
  "sneeringer": ['S N IH1 R IH0 N JH ER0'],
  "sneers": ['S N IH1 R Z'],
  "sneeze": ['S N IY1 Z'],
  "sneezed": ['S N IY1 Z D'],
  "sneezes": ['S N IY1 Z IH0 Z'],
  "sneezing": ['S N IY1 Z IH0 NG'],
  "sneh": ['S N EH1'],
  "sneider": ['S N AY1 D ER0'],
  "snelgrove": ['S N EH1 L G R OW2 V'],
  "snell": ['S N EH1 L'],
  "snellen": ['S N EH1 L AH0 N'],
  "snellenberger": ['S N EH1 L AH0 N B ER0 G ER0'],
  "sneller": ['S N EH1 L ER0'],
  "snellgrove": ['S N EH1 L G R OW2 V'],
  "snelling": ['S N EH1 L IH0 NG'],
  "snellings": ['S N EH1 L IH0 NG Z'],
  "snelson": ['S N EH1 L S AH0 N'],
  "snethen": ['S N EH1 TH AH0 N'],
  "snia": ['S N IY1 AH0'],
  "snicker": ['S N IH1 K ER0'],
  "snickered": ['S N IH1 K ER0 D'],
  "snickering": ['S N IH1 K ER0 IH0 NG'],
  "snickers": ['S N IH1 K ER0 Z'],
  "snide": ['S N AY1 D'],
  "snider": ['S N AY1 D ER0'],
  "snidow": ['S N IH1 D OW0'],
  "sniegowski": ['S N IY0 G AO1 F S K IY0'],
  "sniff": ['S N IH1 F'],
  "sniffed": ['S N IH1 F T'],
  "sniffen": ['S N IH1 F AH0 N'],
  "sniffer": ['S N IH1 F ER0'],
  "sniffier": ['S N IH1 F IY0 ER0'],
  "sniffiest": ['S N IH1 F IY0 AH0 S T'],
  "sniffily": ['S N IH1 F AH0 L IY0'],
  "sniffing": ['S N IH1 F IH0 NG'],
  "sniffle": ['S N IH1 F AH0 L'],
  "sniffler": ['S N IH1 F L ER0'],
  "sniffles": ['S N IH1 F AH0 L Z'],
  "sniffling": ['S N IH1 F L IH0 NG'],
  "sniffs": ['S N IH1 F S'],
  "sniffy": ['S N IH1 F IY0'],
  "snifter": ['S N IH1 F T ER0'],
  "snifters": ['S N IH1 F T ER0 Z'],
  "snip": ['S N IH1 P'],
  "snipe": ['S N AY1 P'],
  "sniped": ['S N AY1 P T'],
  "sniper": ['S N AY1 P ER0'],
  "sniper's": ['S N AY1 P ER0 Z'],
  "snipers": ['S N AY1 P ER0 Z'],
  "snipers'": ['S N AY1 P ER0 Z'],
  "snipes": ['S N AY1 P S'],
  "sniping": ['S N AY1 P IH0 NG'],
  "snippet": ['S N IH1 P AH0 T'],
  "snippets": ['S N IH1 P AH0 T S'],
  "snips": ['S N IH1 P S'],
  "snitch": ['S N IH1 CH'],
  "snitching": ['S N IH1 CH IH0 NG'],
  "snitker": ['S N IH1 T K ER0'],
  "snitzer": ['S N IH1 T Z ER0'],
  "snively": ['S N AY1 V L IY0'],
  "snob": ['S N AA1 B'],
  "snobbery": ['S N AA1 B ER0 IY0'],
  "snobbish": ['S N AA1 B IH0 SH'],
  "snobby": ['S N AA1 B IY0'],
  "snobs": ['S N AA1 B Z'],
  "snodderly": ['S N AA1 D ER0 L IY0'],
  "snoddy": ['S N AA1 D IY0'],
  "snodgrass": ['S N AA1 D G R AE2 S'],
  "snog": ['S N AO1 G'],
  "snohomish": ['S N AA1 HH AH0 M IH0 SH'],
  "snoke": ['S N OW1 K'],
  "snook": ['S N UH1 K'],
  "snooker": ['S N UH1 K ER0'],
  "snookered": ['S N UH1 K ER0 D'],
  "snooks": ['S N UH1 K S'],
  "snoop": ['S N UW1 P'],
  "snooping": ['S N UW1 P IH0 NG'],
  "snoops": ['S N UW1 P S'],
  "snoopy": ['S N UW1 P IY0'],
  "snooty": ['S N UW1 T IY0'],
  "snooze": ['S N UW1 Z'],
  "snoozing": ['S N UW1 Z IH0 NG'],
  "snore": ['S N AO1 R'],
  "snorer": ['S N AO1 R ER0'],
  "snorers": ['S N AO1 R ER0 Z'],
  "snores": ['S N AO1 R Z'],
  "snoring": ['S N AO1 R IH0 NG'],
  "snorkel": ['S N AO1 R K AH0 L'],
  "snorkeling": ['S N AO1 R K AH0 L IH2 NG'],
  "snort": ['S N AO1 R T'],
  "snorted": ['S N AO1 R T IH0 D'],
  "snorter": ['S N AO1 R T ER0'],
  "snorters": ['S N AO1 R T ER0 Z'],
  "snorting": ['S N AO1 R T IH0 NG'],
  "snorts": ['S N AO1 R T S'],
  "snot": ['S N AO1 T'],
  "snot-face": ['S N AO1 T F EY2 S'],
  "snot-faced": ['S N AO1 T F EY2 S T'],
  "snotty": ['S N AO1 T IY2'],
  "snouffer": ['S N OW1 F ER0'],
  "snout": ['S N AW1 T'],
  "snout's": ['S N AW1 T S'],
  "snouts": ['S N AW1 T S'],
  "snover": ['S N OW1 V ER0'],
  "snow": ['S N OW1'],
  "snow's": ['S N OW1 Z'],
  "snowball": ['S N OW1 B AO2 L'],
  "snowball's": ['S N OW1 B AO2 L Z'],
  "snowballed": ['S N OW1 B AO2 L D'],
  "snowballing": ['S N OW1 B AO2 L IH0 NG'],
  "snowballs": ['S N OW1 B AO2 L Z'],
  "snowberger": ['S N OW1 B ER0 G ER0'],
  "snowbird": ['S N OW1 B ER2 D'],
  "snowbirds": ['S N OW1 B ER2 D Z'],
  "snowboard": ['S N OW1 B AO2 R D'],
  "snowboarder": ['S N OW1 B AO2 R D ER0'],
  "snowboarders": ['S N OW1 B AO2 R D ER0 Z'],
  "snowboards": ['S N OW1 B AO2 R D Z'],
  "snowbound": ['S N OW1 B AW2 N D'],
  "snowden": ['S N OW1 D AH0 N'],
  "snowdon": ['S N OW1 D AH0 N'],
  "snowe": ['S N OW1'],
  "snowed": ['S N OW1 D'],
  "snowfall": ['S N OW1 F AA0 L'],
  "snowfalls": ['S N OW1 F AA0 L Z'],
  "snowflake": ['S N OW1 F L EY2 K'],
  "snowflakes": ['S N OW1 F L EY2 K S'],
  "snowing": ['S N OW1 IH0 NG'],
  "snowman": ['S N OW1 M AE2 N'],
  "snowmobile": ['S N OW1 M OW0 B IY2 L'],
  "snowmobiles": ['S N OW1 M OW0 B IY2 L Z'],
  "snowplow": ['S N OW1 P L AW2'],
  "snowplows": ['S N OW1 P L AW2 Z'],
  "snows": ['S N OW1 Z'],
  "snowshoe": ['S N OW1 SH UW2'],
  "snowshoes": ['S N OW1 SH UW2 Z'],
  "snowstorm": ['S N OW1 S T AO2 R M'],
  "snowstorms": ['S N OW1 S T AO2 R M Z'],
  "snowy": ['S N OW1 IY0'],
  "snub": ['S N AH1 B'],
  "snubbed": ['S N AH1 B D'],
  "snubbing": ['S N AH1 B IH0 NG'],
  "snubs": ['S N AH1 B Z'],
  "snuck": ['S N AH1 K'],
  "snuff": ['S N AH1 F'],
  "snuffed": ['S N AH1 F T'],
  "snuffer": ['S N AH1 F ER0'],
  "snuffing": ['S N AH1 F IH0 NG'],
  "snuffs": ['S N AH1 F S'],
  "snug": ['S N AH1 G'],
  "snugging": ['S N AH1 G IH0 NG'],
  "snuggle": ['S N AH1 G AH0 L'],
  "snuggled": ['S N AH1 G AH0 L D'],
  "snuggs": ['S N AH1 G Z'],
  "snugly": ['S N AH1 G L IY0'],
  "snyder": ['S N AY1 D ER0'],
  "snyder's": ['S N AY1 D ER0 Z'],
  "snydergeneral": ['S N AY2 D ER0 JH EH1 N ER0 AH0 L'],
  "so": ['S OW1'],
  "so's": ['S OW1 Z'],
  "so-called": ['S OW1 K AO1 L D'],
  "so-so": ['S OW1 S OW1'],
  "soady": ['S OW1 D IY0'],
  "soak": ['S OW1 K'],
  "soaked": ['S OW1 K T'],
  "soaker": ['S OW1 K ER0'],
  "soaking": ['S OW1 K IH0 NG'],
  "soaks": ['S OW1 K S'],
  "soap": ['S OW1 P'],
  "soapbox": ['S OW1 P B AA2 K S'],
  "soaps": ['S OW1 P S'],
  "soapy": ['S OW1 P IY0'],
  "soar": ['S AO1 R'],
  "soard": ['S AO1 R D'],
  "soared": ['S AO1 R D'],
  "soares": ['S AO1 R EH0 S'],
  "soaring": ['S AO1 R IH0 NG'],
  "soars": ['S AO1 R Z'],
  "soave": ['S OW1 V'],
  "sob": ['S AA1 B'],
  "sobbed": ['S AA1 B D'],
  "sobbing": ['S AA1 B IH0 NG'],
  "sobbingly": ['S AA1 B IH0 NG L IY0'],
  "sobczak": ['S AA1 B CH AE0 K'],
  "sobczyk": ['S AA1 B CH IH0 K'],
  "sobeck": ['S OW1 B EH2 K'],
  "sobecki": ['S AH0 B EH1 T S K IY0'],
  "sobek": ['S OW1 B IH0 K'],
  "sobel": ['S OW1 B AH0 L'],
  "sobelman": ['S OW1 B AH0 L M AH0 N'],
  "sober": ['S OW1 B ER0'],
  "sobered": ['S OW1 B ER0 D'],
  "sobering": ['S OW1 B ER0 IH0 NG'],
  "soberly": ['S OW1 B ER0 L IY0'],
  "sobers": ['S OW1 B ER0 Z'],
  "sobey": ['S OW1 B IY0'],
  "sobibor": ['S OW0 B IY1 B AO0 R', 'S OW0 B IH1 B AO0 R'],
  "sobiech": ['S AA1 B IY0 HH'],
  "sobieski": ['S OW2 B IY0 EH1 S K IY0'],
  "sobil": ['S OW1 B AH0 L'],
  "sobil's": ['S OW1 B AH0 L Z'],
  "sobils": ['S OW1 B AH0 L Z'],
  "sobin": ['S OW1 B IH0 N'],
  "sobkowiak": ['S AH0 B K AW1 IY0 AE0 K'],
  "soble": ['S OW1 B AH0 L'],
  "sobocinski": ['S AH0 B AH0 CH IH1 N S K IY0'],
  "sobol": ['S OW1 B AH0 L'],
  "soboleski": ['S AH0 B AH0 L EH1 S K IY0'],
  "sobolewski": ['S AH0 B AH0 L EH1 F S K IY0'],
  "sobolik": ['S AH0 B OW1 L IH0 K'],
  "sobon": ['S OW0 B AO1 N'],
  "sobota": ['S AH0 B OW1 T AH0'],
  "sobotka": ['S AH0 B OW1 T K AH0'],
  "sobotta": ['S OW0 B OW1 T AH0'],
  "sobriety": ['S AH0 B R AY1 AH0 T IY0'],
  "sobriquet": ['S OW1 B R AH0 K EY2', 'S AH2 B R AH0 K EH1 T'],
  "sobs": ['S AA1 B Z'],
  "socal": ['S OW1 K AH0 L'],
  "socalled": ['S OW1 K AA2 L D'],
  "socanav": ['S AA1 K AH0 N AA0 V'],
  "socarras": ['S OW0 K AA1 R AA0 Z'],
  "soccer": ['S AA1 K ER0'],
  "soccer's": ['S AA1 K ER0 Z'],
  "socci": ['S OW1 CH IY0'],
  "socha": ['S OW1 CH AH0'],
  "sochacki": ['S AH0 CH AE1 K IY0'],
  "sochet": ['S OW0 SH EY1'],
  "sochi": ['S OW1 CH IY0'],
  "sochor": ['S AA1 K ER0'],
  "socia": ['S OW1 CH AH0'],
  "sociable": ['S OW1 SH AH0 B AH0 L'],
  "social": ['S OW1 SH AH0 L'],
  "socialism": ['S OW1 SH AH0 L IH2 Z AH0 M'],
  "socialist": ['S OW1 SH AH0 L AH0 S T', 'S OW1 SH AH0 L IH0 S T'],
  "socialistic": ['S OW2 SH AH0 L IH1 S T IH0 K'],
  "socialists": ['S OW1 SH AH0 L AH0 S T S', 'S OW1 SH AH0 L IH0 S T S'],
  "socialists'": ['S OW1 SH AH0 L IH0 S T S'],
  "socialite": ['S OW1 SH AH0 L AY2 T'],
  "socialites": ['S OW1 SH AH0 L AY2 T S'],
  "socialization": ['S OW2 SH AH0 L IH0 Z EY1 SH AH0 N'],
  "socialize": ['S OW1 SH AH0 L AY2 Z'],
  "socialized": ['S OW1 SH AH0 L AY2 Z D'],
  "socializing": ['S OW1 SH AH0 L AY2 Z IH0 NG'],
  "socially": ['S OW1 SH AH0 L IY0'],
  "sociedad": ['S OW2 S IY0 D AE1 D'],
  "societa": ['S OW2 S IY0 EH1 T AH0'],
  "societal": ['S AH0 S AY1 IH0 T AH0 L'],
  "societe": ['S OW2 S IY0 EH0 T EY1'],
  "societies": ['S AH0 S AY1 AH0 T IY0 Z'],
  "society": ['S AH0 S AY1 AH0 T IY0'],
  "society's": ['S AH0 S AY1 AH0 T IY0 Z'],
  "socio": ['S OW1 S IY0 OW0'],
  "socioeconomic": ['S OW0 S IY2 OW2 EH2 K AH0 N AA1 M IH0 K'],
  "sociological": ['S OW2 S IY0 AH0 L AA1 JH IH0 K AH0 L'],
  "sociologist": ['S OW2 S IY0 AA1 L AH0 JH IH0 S T'],
  "sociologists": ['S OW2 S IY0 AA1 L AH0 JH IH0 S T S'],
  "sociology": ['S OW2 S IY0 AA1 L AH0 JH IY0'],
  "sociopath": ['S OW1 S IY0 OW0 P AE2 TH'],
  "sociopaths": ['S OW1 S IY0 OW0 P AE2 DH Z'],
  "sock": ['S AA1 K'],
  "socked": ['S AA1 K T'],
  "socket": ['S AA1 K AH0 T'],
  "sockets": ['S AA1 K AH0 T S'],
  "socking": ['S AA1 K IH0 NG'],
  "socks": ['S AA1 K S'],
  "sockwell": ['S AA1 K W EH2 L'],
  "soco": ['S OW1 K OW1', 'S AA1 K OW0'],
  "soco's": ['S OW1 K OW1 Z', 'S AA1 K OW0 Z'],
  "socol": ['S OW1 K AA2 L'],
  "socrates": ['S AA1 K R AH0 T IY2 Z'],
  "socratic": ['S AH0 K R AE1 T IH0 K'],
  "sod": ['S AA1 D'],
  "soda": ['S OW1 D AH0'],
  "soda's": ['S OW1 D AH0 Z'],
  "sodano": ['S OW0 D AA1 N OW0'],
  "sodaro": ['S OW0 D AA1 R OW0'],
  "sodas": ['S OW1 D AH0 Z'],
  "sodden": ['S AA1 D AH0 N'],
  "sodders": ['S AA1 D ER0 Z'],
  "sodecom": ['S OW1 D AH0 K AA0 M'],
  "soden": ['S OW1 D AH0 N'],
  "soder": ['S OW1 D ER0'],
  "soderberg": ['S OW1 D ER0 B ER0 G'],
  "soderbergh": ['S OW1 D ER0 B ER0 G'],
  "soderblom": ['S OW1 D ER0 B L AA2 M'],
  "sodergren": ['S AA1 D ER0 G R EH0 N'],
  "soderholm": ['S OW1 D ER0 HH OW0 L M'],
  "soderlund": ['S AA1 D ER0 L AH0 N D'],
  "soderman": ['S OW1 D ER0 M AH0 N'],
  "soderquist": ['S AA1 D ER0 K W IH0 S T'],
  "soderstrom": ['S AA1 D ER0 S T R AH0 M'],
  "soditic": ['S OW0 D IH1 T IH0 K'],
  "sodium": ['S OW1 D IY0 AH0 M'],
  "sodom": ['S AO1 D AH0 M'],
  "sodomize": ['S AO1 D AH0 M AY2 Z'],
  "sodomized": ['S AO1 D AH0 M AY2 Z D'],
  "sodomizer": ['S AO1 D AH0 M AY2 Z ER0'],
  "sodomizes": ['S AO1 D AH0 M AY2 Z AH0 Z'],
  "sodomizing": ['S AO1 D AH0 M AY2 Z IH0 NG'],
  "sodomy": ['S AA1 D AH0 M IY0'],
  "sodus": ['S OW1 D AH0 S'],
  "soeder": ['S OW1 D ER0'],
  "soens": ['S OW1 N Z'],
  "soerensen": ['S AO1 R AH0 N S AH0 N'],
  "sofa": ['S OW1 F AH0'],
  "sofaer": ['S OW0 F EY1 R'],
  "sofamor": ['S OW1 F AH0 M AO2 R'],
  "sofas": ['S OW1 F AH0 Z'],
  "sofer": ['S OW1 F ER0'],
  "sofer's": ['S OW1 F ER0 Z'],
  "soffel": ['S AO1 F AH0 L'],
  "soffer": ['S AO1 F ER0'],
  "soffit": ['S AO1 F IH0 T'],
  "sofia": ['S OW0 F IY1 AH0'],
  "sofia's": ['S OW0 F IY1 AH0 Z'],
  "sofian": ['S OW0 F IY1 AH0 N'],
  "sofians": ['S OW0 F IY1 AH0 N Z'],
  "sofie": ['S AA1 F IY0'],
  "sofitel": ['S AA1 F AH0 T EH2 L'],
  "sofranko": ['S AH0 F R AE1 NG K OW0'],
  "soft": ['S AA1 F T', 'S AO1 F T'],
  "softball": ['S AO1 F T B AO2 L', 'S AO1 F B AO2 L'],
  "softballs": ['S AO1 F T B AO2 L Z', 'S AO1 F B AO2 L Z'],
  "softbank": ['S AO1 F T B AE2 NG K'],
  "softdrink": ['S AO1 F T D R IH2 NG K'],
  "softech": ['S AO1 F T EH2 K'],
  "soften": ['S AA1 F AH0 N', 'S AO1 F AH0 N'],
  "softened": ['S AO1 F AH0 N D'],
  "softener": ['S AO1 F AH0 N ER0'],
  "softening": ['S AO1 F AH0 N IH0 NG', 'S AO1 F N IH0 NG'],
  "softens": ['S AO1 F AH0 N Z'],
  "softer": ['S AA1 F T ER0', 'S AO1 F T ER0'],
  "softest": ['S AO1 F T AH0 S T'],
  "softimage": ['S AO1 F T IH2 M IH0 JH'],
  "softkey": ['S AA1 F T K EY2'],
  "softletter": ['S AO1 F T L EH2 T ER0'],
  "softly": ['S AO1 F T L IY0', 'S AO1 F L IY0'],
  "softness": ['S AO1 F T N AH0 S', 'S AO1 F N AH0 S'],
  "softsoap": ['S AO1 F T S OW2 P', 'S AO1 F S OW2 P'],
  "softspoken": ['S AO1 F T S P OW1 K AH0 N', 'S AO1 F S P OW1 K AH0 N'],
  "software": ['S AO1 F T W EH2 R', 'S AO1 F W EH2 R'],
  "software's": ['S AO1 F T W EH2 R Z', 'S AO1 F W EH2 R Z'],
  "softwood": ['S AO1 F T W UH2 D'],
  "sogang": ['S OW1 G AE0 NG'],
  "soggy": ['S AA1 G IY0'],
  "sogo": ['S OW1 G OW0'],
  "sohio": ['S OW0 HH AY1 OW0'],
  "sohl": ['S OW1 L'],
  "sohm": ['S OW1 M'],
  "sohmer": ['S OW1 M ER0'],
  "sohn": ['S AA1 N'],
  "sohns": ['S AA1 N Z'],
  "soho": ['S OW1 HH OW0'],
  "soifer": ['S OY1 F ER0'],
  "soiffer": ['S OY1 F ER0'],
  "soil": ['S OY1 L'],
  "soil's": ['S OY1 L Z'],
  "soileau": ['S OY2 L OW1'],
  "soiled": ['S OY1 L D'],
  "soils": ['S OY1 L Z'],
  "soir": ['S OY1 R'],
  "soiree": ['S W AA0 R EY1'],
  "soisson": ['S OY1 Z S AH0 N'],
  "soja": ['S OW1 JH AH0'],
  "sojka": ['S OY1 K AH0'],
  "sojourn": ['S OW1 JH ER0 N'],
  "sojourner": ['S OW1 JH ER0 N ER0'],
  "sojourners": ['S OW1 JH ER0 N ER0 Z'],
  "sojourning": ['S OW1 JH ER0 N IH0 NG'],
  "sok": ['S AA1 K'],
  "sokaiya": ['S AH0 K AY1 Y AH0'],
  "sokol": ['S OW1 K AH0 L'],
  "sokolik": ['S AH0 K OW1 L IH0 K'],
  "sokolin": ['S AA1 K AH0 L IH0 N'],
  "sokoloff": ['S AA1 K AH0 L AO0 F'],
  "sokoloski": ['S AH0 K AH0 L AW1 S K IY0'],
  "sokolov": ['S AA1 K AH0 L AA0 V'],
  "sokolow": ['S AA1 K AH0 L OW0'],
  "sokolow's": ['S AA1 K AH0 L OW2 Z'],
  "sokolowski": ['S AH0 K AH0 L AO1 F S K IY0'],
  "sol": ['S AA1 L', 'S OW1 L'],
  "sola": ['S OW1 L AH0'],
  "solace": ['S AA1 L AH0 S', 'S OW1 L IH0 S'],
  "solak": ['S OW1 L AH0 K'],
  "solaman": ['S AA1 L AH0 M AH0 N'],
  "solan": ['S OW1 L AH0 N'],
  "solana": ['S OW0 L AE1 N AH0'],
  "soland": ['S AA1 L AH0 N D'],
  "solangi": ['S OW0 L AA1 N JH IY0'],
  "solano": ['S OW0 L AA1 N OW0'],
  "solar": ['S OW1 L ER0'],
  "solares": ['S OW0 L AA1 R EH0 S'],
  "solari": ['S OW0 L AA1 R IY0'],
  "solaris": ['S OW0 L EH1 R IH0 S'],
  "solarz": ['S OW1 L AA0 R Z'],
  "solazzo": ['S OW0 L AA1 Z OW0'],
  "solberg": ['S OW1 L B ER0 G'],
  "solchaga": ['S OW0 L CH AA1 G AH0'],
  "sold": ['S OW1 L D'],
  "soldan": ['S OW1 L D AH0 N'],
  "soldano": ['S OW0 L D AA1 N OW0'],
  "solder": ['S AA1 D ER0'],
  "soldering": ['S AA1 D ER0 IH0 NG'],
  "soldier": ['S OW1 L JH ER0'],
  "soldier's": ['S OW1 L JH ER0 Z'],
  "soldiering": ['S OW1 L JH ER0 IH0 NG'],
  "soldiers": ['S OW1 L JH ER0 Z'],
  "soldiers'": ['S OW1 L JH ER0 Z'],
  "soldner": ['S OW1 L D N ER0'],
  "soldo": ['S OW1 L D OW0'],
  "sole": ['S OW1 L'],
  "solecki": ['S AH0 L EH1 T S K IY0'],
  "soledad": ['S OW1 L D AE2 D'],
  "soleil": ['S OW0 L EY1 L'],
  "solely": ['S OW1 AH0 L IY0'],
  "solem": ['S OW1 L IH0 M'],
  "solemin": ['S OW0 L EH1 M IH0 N'],
  "solemn": ['S AA1 L AH0 M'],
  "solemnity": ['S AH0 L EH1 M N AH0 T IY0'],
  "solemnly": ['S AO1 L AH0 M L IY0'],
  "soler": ['S OW1 L ER0'],
  "soleri": ['S OW0 L EH1 R IY0'],
  "soles": ['S OW1 L Z'],
  "solesbee": ['S OW1 L Z B IY2'],
  "soley": ['S OW1 L IY0'],
  "solheim": ['S OW1 L HH AY2 M'],
  "soli": ['S OW1 L IY2'],
  "solicit": ['S AH0 L IH1 S IH0 T'],
  "solicitation": ['S AH0 L IH2 S IH0 T EY1 SH AH0 N'],
  "solicitations": ['S AH0 L IH2 S IH0 T EY1 SH AH0 N Z'],
  "solicited": ['S AH0 L IH1 S IH0 T IH0 D'],
  "soliciting": ['S AH0 L IH1 S AH0 T IH0 NG'],
  "solicitor": ['S AH0 L IH1 S AH0 T ER0'],
  "solicitors": ['S AH0 L IH1 S AH0 T ER0 Z'],
  "solicitous": ['S AH0 L IH1 S AH0 T AH0 S'],
  "solicits": ['S AH0 L IH1 S AH0 T S'],
  "solicitude": ['S AH0 L IH1 S IH0 T UW2 D'],
  "solid": ['S AA1 L AH0 D'],
  "solid-state": ['S AA1 L AH0 D S T EY1 T'],
  "solidarity": ['S AA2 L AH0 D EH1 R AH0 T IY0'],
  "solidarity's": ['S AA2 L AH0 D EH1 R AH0 T IY0 Z'],
  "soliday": ['S OW1 L IY0 D EY0'],
  "soliders": ['S AA1 L IH0 D ER0 Z'],
  "solidified": ['S AH0 L IH1 D AH0 F AY2 D'],
  "solidifies": ['S AH0 L IH1 D AH0 F AY2 Z'],
  "solidify": ['S AH0 L IH1 D AH0 F AY2'],
  "solidifying": ['S AH0 L IH1 D AH0 F AY2 IH0 NG'],
  "solidity": ['S AH0 L IH1 D AH0 T IY0'],
  "solidly": ['S AA1 L AH0 D L IY0'],
  "solids": ['S AA1 L AH0 D Z'],
  "solie": ['S OW1 L IY0'],
  "soliloquize": ['S AH0 L IH1 L AH0 K W AY2 Z'],
  "soliloquy": ['S AH0 L IH1 L AH0 K W IY0'],
  "soliman": ['S AA1 L IH0 M AH0 N'],
  "soliman's": ['S AA1 L IH0 M AH0 N Z'],
  "solimine": ['S OW0 L IY0 M IY1 N IY0'],
  "solimon": ['S OW1 L IH0 M AH0 N'],
  "solin": ['S OW1 L IH0 N'],
  "solingen": ['S OW1 L IH0 NG G EH0 N', 'S AA1 L IH0 NG G EH0 N'],
  "solinger": ['S OW1 L IH0 NG ER0'],
  "solis": ['S OW1 L IH0 S'],
  "solita": ['S OW0 L IY1 T AH0'],
  "solitaire": ['S AA2 L AH0 T EH1 R'],
  "solitariness": ['S AA0 L AH0 T EH1 R IY0 N IH0 S'],
  "solitary": ['S AA1 L AH0 T EH2 R IY0'],
  "solitec": ['S AA1 L IH0 T EH2 K'],
  "solitron": ['S OW1 L IH0 T R AA0 N'],
  "solitude": ['S AA1 L AH0 T UW2 D'],
  "soliz": ['S OW1 L IY0 Z'],
  "soll": ['S AA1 L'],
  "sollars": ['S AA1 L ER0 Z'],
  "sollenberger": ['S AA1 L AH0 N B ER0 G ER0'],
  "soller": ['S AA1 L ER0'],
  "sollers": ['S AA1 L ER0 Z'],
  "solley": ['S AA1 L IY0'],
  "solliday": ['S AA1 L IY0 D EY0'],
  "sollie": ['S AA1 L IY0'],
  "sollinger": ['S AA1 L IH0 NG ER0'],
  "sollish": ['S AA1 L IH0 SH'],
  "solloway": ['S AA1 L OW0 W EY2'],
  "solly": ['S AA1 L IY0'],
  "solo": ['S OW1 L OW2'],
  "solodar": ['S AA1 L AH0 D ER0'],
  "soloff": ['S AA1 L AO0 F'],
  "soloist": ['S OW1 L OW2 AH0 S T', 'S OW1 L OW2 IH0 S T'],
  "soloists": ['S OW1 L OW2 AH0 S T S'],
  "soloman": ['S OW0 L OW0 M AE1 N'],
  "solomon": ['S AA1 L AH0 M AH0 N'],
  "solomon's": ['S AA1 L AH0 M AH0 N Z'],
  "solomos": ['S AA1 L AH0 M OW0 S'],
  "solon": ['S OW1 L AH0 N'],
  "solorio": ['S OW0 L AO1 R IY0 OW0'],
  "solorzano": ['S OW0 L AO0 R Z AA1 N OW0'],
  "solos": ['S OW1 L OW0 Z'],
  "soloviev": ['S AA1 L OW0 V IY2 V'],
  "solow": ['S AA1 L OW0'],
  "soloway": ['S OW1 L OW0 W EY2'],
  "solstice": ['S AO1 L S T IH0 S'],
  "solstices": ['S AO1 L S T IH0 S IH0 S'],
  "solt": ['S OW1 L T'],
  "soltau": ['S OW1 L T AW0'],
  "soltero": ['S OW0 L T EH1 R OW0'],
  "soltes": ['S OW1 L T S'],
  "soltesz": ['S OW1 L T IH0 SH'],
  "solti": ['S OW1 L T IY0'],
  "soltis": ['S OW1 L T IH0 S'],
  "soltys": ['S OW1 L T IY0 Z'],
  "soltysiak": ['S OW0 L T IH1 S IY0 AE0 K'],
  "solubility": ['S AA2 L Y AH0 B IH1 L AH0 T IY2'],
  "soluble": ['S AA1 L Y AH0 B AH0 L'],
  "solubles": ['S AA1 L Y AH0 B AH0 L Z'],
  "solum": ['S OW1 L AH0 M'],
  "solute": ['S AA1 L Y UW0 T'],
  "solutes": ['S AA1 L Y UW0 T S'],
  "solution": ['S AH0 L UW1 SH AH0 N'],
  "solutions": ['S AH0 L UW1 SH AH0 N Z'],
  "solvable": ['S AA1 L V AH0 B AH0 L'],
  "solvay": ['S OW1 L V EY0'],
  "solve": ['S AA1 L V'],
  "solved": ['S AA1 L V D'],
  "solvency": ['S AO1 L V AH0 N S IY0'],
  "solvent": ['S AA1 L V AH0 N T'],
  "solvents": ['S AO1 L V AH0 N T S'],
  "solver": ['S AA1 L V ER0'],
  "solvers": ['S AA1 L V ER0 Z'],
  "solves": ['S AA1 L V Z'],
  "solvig": ['S OW1 L V IH0 G'],
  "solving": ['S AA1 L V IH0 NG'],
  "solwin": ['S OW1 L W IH2 N'],
  "solwin's": ['S OW1 L W IH2 N Z'],
  "solzhenitsyn": ['S OW2 L Z AH0 N IH1 T S IH2 N'],
  "som": ['S AA1 M'],
  "soma": ['S OW1 M AA0'],
  "somali": ['S AH0 M AA1 L IY0'],
  "somalia": ['S AH0 M AA1 L IY0 AH0', 'S AH0 M AA1 L Y AH0'],
  "somalia's": ['S AH0 M AA1 L IY0 AH0 Z', 'S AH0 M AA1 L Y AH0 Z'],
  "somalian": ['S AH0 M AA1 L Y AH0 N'],
  "somalians": ['S AH0 M AA1 L Y AH0 N Z'],
  "somalias": ['S AH0 M AA1 L IY0 AH0 Z', 'S AH0 M AA1 L Y AH0 Z'],
  "somaliland": ['S AH0 M AA1 L IY0 L AE2 N D'],
  "somalis": ['S AH0 M AA1 L IY0 Z'],
  "somatic": ['S OW2 M AA1 T IH0 K'],
  "somatogen": ['S OW2 M AE1 T AH0 JH EH0 N'],
  "somatotropin": ['S OW2 M AH0 T AA1 T R AH0 P IH0 N'],
  "somber": ['S AA1 M B ER0'],
  "somberly": ['S AA1 M B ER0 L IY0'],
  "sombrero": ['S AA0 M B R EH1 R OW0'],
  "some": ['S AH1 M'],
  "somebody": ['S AH1 M B AA2 D IY0', 'S AH1 M B AH0 D IY2'],
  "somebody's": ['S AH1 M B AA2 D IY0 Z'],
  "someday": ['S AH1 M D EY2'],
  "somehow": ['S AH1 M HH AW2'],
  "someone": ['S AH1 M W AH2 N'],
  "someone's": ['S AH1 M W AH2 N Z'],
  "someplace": ['S AH1 M P L EY2 S'],
  "somer": ['S AH1 M ER0'],
  "somers": ['S AH1 M ER0 Z'],
  "somersault": ['S AH1 M ER0 S AO2 L T'],
  "somersaulting": ['S AH1 M ER0 S AO2 L T IH0 NG'],
  "somersaults": ['S AH1 M ER0 S AO2 L T S'],
  "somerset": ['S AH1 M ER0 S EH2 T'],
  "somerton": ['S AH1 M ER0 T AH0 N'],
  "somerville": ['S AH1 M ER0 V IH2 L'],
  "somes": ['S AH1 M Z'],
  "somesh": ['S OW2 M EH1 SH'],
  "somethin'": ['S AH1 M TH IH0 N'],
  "something": ['S AH1 M TH IH0 NG'],
  "something's": ['S AH1 M TH IH0 NG Z'],
  "somethings": ['S AH1 M TH IH2 NG Z'],
  "sometime": ['S AH1 M T AY2 M'],
  "sometimes": ['S AH0 M T AY1 M Z', 'S AH1 M T AY2 M Z'],
  "somewhat": ['S AH1 M W AH1 T', 'S AH1 M HH W AH1 T'],
  "somewhere": ['S AH1 M W EH2 R'],
  "somewheres": ['S AH1 M W EH2 R Z'],
  "somma": ['S AA1 M AH0'],
  "somme": ['S AO1 M'],
  "sommer": ['S AH1 M ER0'],
  "sommerfeld": ['S AA1 M ER0 F EH0 L D'],
  "sommerfeldt": ['S AA1 M ER0 F IH0 L T'],
  "sommerfield": ['S AH0 M ER1 F IY0 L D'],
  "sommers": ['S AH1 M ER0 R Z'],
  "sommersby": ['S AH1 M ER0 R Z B IY0'],
  "sommerville": ['S AA1 M ER0 V IH0 L'],
  "somnolence": ['S AA1 M N AH0 L AH0 N S'],
  "somnolent": ['S AA1 M N AH0 L AH0 N T'],
  "somogyi": ['S OW0 M OW1 G Y IY0'],
  "somoza": ['S AH0 M OW1 Z AH0'],
  "son": ['S AH1 N'],
  "son's": ['S AH1 N Z'],
  "son-in-law": ['S AH1 N IH0 N L AO2'],
  "son-of-a-bitch": ['S AH1 N AH0 V AH0 B IH2 CH'],
  "sonar": ['S OW1 N AA0 R'],
  "sonat": ['S AA1 N AH0 T'],
  "sonata": ['S AH0 N AA1 T AH0'],
  "sonatas": ['S AA2 N AA1 T AH0 Z'],
  "sonatrach": ['S AA1 N AH0 T R AE0 K'],
  "sonchez": ['S AA1 N CH EH0 Z'],
  "sondag": ['S AA1 N D AH0 G'],
  "sonderman": ['S AA1 N D ER0 M AH0 N'],
  "sondgeroth": ['S AA1 N JH ER0 AA0 TH'],
  "sondheim": ['S AA1 N D HH AY2 M'],
  "sondheim's": ['S AA1 N D HH AY2 M Z'],
  "sondheimer": ['S AA1 N D HH AY2 M ER0'],
  "sondra": ['S AA1 N D R AH0'],
  "sones": ['S OW1 N Z'],
  "sonesta": ['S AH0 N EH1 S T AH0'],
  "sonet": ['S OW1 N AH0 T'],
  "sonex": ['S OW1 N AH0 K S'],
  "song": ['S AO1 NG'],
  "song's": ['S AO1 NG Z'],
  "songbird": ['S AO1 NG B ER2 D'],
  "songbirds": ['S AO1 NG B ER2 D Z'],
  "songbook": ['S AO1 N G B UH2 K'],
  "songbooks": ['S AO1 N G B UH2 K S'],
  "songer": ['S AO1 NG ER0'],
  "songs": ['S AO1 NG Z'],
  "songwriter": ['S AO1 NG R AY2 T ER0'],
  "songwriters": ['S AO1 NG R AY2 T ER0 Z'],
  "songwriting": ['S AO1 NG R AY2 T IH0 NG'],
  "songy": ['S AA1 N JH IY0'],
  "soni": ['S OW1 N IY2'],
  "sonia": ['S OW1 N Y AA2'],
  "sonic": ['S AA1 N IH0 K'],
  "sonics": ['S AA1 N IH0 K S'],
  "sonier": ['S OW1 N IY0 ER0'],
  "sonja": ['S OW1 N Y AA2'],
  "sonji": ['S AO1 N JH IY2'],
  "sonn": ['S AA1 N'],
  "sonne": ['S AA1 N'],
  "sonneborn": ['S AA1 N IH0 B AO0 R N'],
  "sonnen": ['S AA1 N AH0 N'],
  "sonnenberg": ['S AA1 N AH0 N B ER0 G'],
  "sonnenblick": ['S AH0 N EH1 N B L IH0 K'],
  "sonnenburg": ['S AA1 N AH0 N B ER0 G'],
  "sonnenfeld": ['S AA1 N IH0 N F EH0 L D'],
  "sonnenschein": ['S AA1 N IH0 N SH AY0 N'],
  "sonner": ['S AA1 N ER0'],
  "sonnet": ['S AA1 N IH0 T'],
  "sonnets": ['S AA1 N IH0 T S'],
  "sonnett": ['S AA1 N AH0 T'],
  "sonnier": ['S AH1 N IY0 ER0'],
  "sonntag": ['S AA1 N T AH0 G'],
  "sonny": ['S AH1 N IY0'],
  "sonny's": ['S AH1 N IY0 Z'],
  "sonoco": ['S AH0 N OW1 K OW0'],
  "sonoda": ['S OW0 N OW1 D AH0'],
  "sonogram": ['S AO1 N AH0 G R AE2 M'],
  "sonograms": ['S AO1 N AH0 G R AE2 M Z'],
  "sonoma": ['S AH0 N OW1 M AH0'],
  "sonora": ['S AH0 N AO1 R AH0'],
  "sonorous": ['S AA1 N ER0 AH0 S'],
  "sonrise": ['S AH1 N R AY2 Z'],
  "sons": ['S AH1 N Z'],
  "sons'": ['S AA1 N Z'],
  "sons-in-law": ['S AH1 N IH0 N L AO2'],
  "sontag": ['S AA1 N T AE2 G'],
  "sonum": ['S AA1 N AH0 M'],
  "sony": ['S OW1 N IY0'],
  "sony's": ['S OW1 N IY0 Z'],
  "sonya": ['S OW1 N Y AH0'],
  "soo": ['S UW1'],
  "sood": ['S UW1 D'],
  "soohoo": ['S UW1 HH UW2'],
  "soon": ['S UW1 N'],
  "sooner": ['S UW1 N ER0'],
  "sooner's": ['S UW1 N ER0 Z'],
  "sooners": ['S UW1 N ER0 Z'],
  "soonest": ['S UW1 N AH0 S T'],
  "soong": ['S UW1 NG'],
  "soonyi": ['S UW2 N Y IY1'],
  "soonyi's": ['S UW2 N Y IY1 Z'],
  "soos": ['S UW1 Z'],
  "soot": ['S UH1 T'],
  "sooted": ['S UH1 T IH0 D'],
  "sooter": ['S UH1 T ER0'],
  "soothe": ['S UW1 DH'],
  "soothed": ['S UW1 DH D'],
  "soothes": ['S UW1 DH Z'],
  "soothing": ['S UW1 DH IH0 NG'],
  "soothingly": ['S UW1 DH IH0 NG L IY0'],
  "soothsayer": ['S UW2 TH S EY1 ER0'],
  "soothsayers": ['S UW2 TH S EY1 ER0 Z'],
  "soots": ['S UH1 T S'],
  "sooty": ['S UW1 T IY0'],
  "sooy": ['S UW1 IY0'],
  "sop": ['S AA1 P'],
  "sope": ['S OW1 P'],
  "soper": ['S OW1 P ER0'],
  "sopher": ['S AA1 F ER0'],
  "sophia": ['S OW0 F IY1 AH0', 'S OW1 F IY0 AH0'],
  "sophie": ['S OW1 F IY0'],
  "sophie's": ['S OW1 F IY0 Z'],
  "sophisticate": ['S AH0 F IH1 S T AH0 K EY2 T', 'S AH0 F IH1 S T AH0 K AH0 T'],
  "sophisticated": ['S AH0 F IH1 S T AH0 K EY2 T IH0 D', 'S AH0 F IH1 S T IH0 K EY2 T AH0 D'],
  "sophisticates": ['S AH0 F IH1 S T AH0 K IH2 T S'],
  "sophistication": ['S AH0 F IH2 S T AH0 K EY1 SH AH0 N'],
  "sophistry": ['S AO1 F IH0 S T R IY2'],
  "sophocles": ['S AA1 F AH0 K L IY0 Z'],
  "sophomore": ['S AA1 F M AO2 R'],
  "sophomores": ['S AA1 F M AO2 R Z'],
  "sophomoric": ['S AA2 F OW0 M AA1 R IH0 K'],
  "sophronia": ['S OW0 F R OW1 N IY0 AH0'],
  "sophy": ['S OW1 F IY0'],
  "sopko": ['S OW1 P K OW0'],
  "sopp": ['S AA1 P'],
  "sopping": ['S AA1 P IH0 NG'],
  "soppy": ['S AA1 P IY0'],
  "soprano": ['S AH0 P R AA1 N OW0', 'S AH0 P R AE1 N OW0'],
  "sopranos": ['S AH0 P R AE1 N OW0 Z'],
  "sops": ['S AA1 P S'],
  "soquip": ['S OW1 K W IH0 P'],
  "sor": ['S AO1 R'],
  "soranno": ['S AO0 R AA1 N OW0'],
  "sorbello": ['S AO2 R B EH1 L OW0'],
  "sorber": ['S AO1 R B ER0'],
  "sorbet": ['S AO2 R B EY1', 'S AO1 R B EH0 T'],
  "sorbo": ['S AO1 R B OW0'],
  "sorbonne": ['S AO0 R B AA1 N'],
  "sorbus": ['S AO1 R B AH0 S'],
  "sorce": ['S AO1 R S'],
  "sorcerer": ['S AO1 R S ER0 ER0'],
  "sorcerers": ['S AO1 R S ER0 ER0 Z'],
  "sorcery": ['S AO1 R S ER0 IY0'],
  "sorcha": ['S AO1 R K AH0', 'S AH1 R AH0 K AH0'],
  "sorci": ['S AO1 R CH IY0'],
  "sordid": ['S AO1 R D AH0 D'],
  "sordoni": ['S AO0 R D OW1 N IY0'],
  "sore": ['S AO1 R'],
  "sorel": ['S AO1 R AH0 L'],
  "sorell": ['S AO1 R AH0 L'],
  "sorely": ['S AO1 R L IY0'],
  "sorensen": ['S AO1 R IH0 N S AH0 N'],
  "sorenson": ['S AO1 R AH0 N S AH0 N'],
  "sorento": ['S ER0 EH1 N T OW0'],
  "sorento's": ['S ER0 EH1 N T OW0 Z'],
  "sores": ['S AO1 R Z'],
  "sorey": ['S AO1 R IY0'],
  "sorg": ['S AO1 R G'],
  "sorge": ['S AO1 R JH'],
  "sorgen": ['S AO1 R G AH0 N'],
  "sorghum": ['S AO1 R G AH0 M'],
  "soria": ['S AO1 R IY0 AH0'],
  "soriano": ['S AO0 R IY0 AA1 N OW0'],
  "sorice": ['S AO1 R IH0 S'],
  "sorin": ['S AO1 R IH0 N'],
  "sorkin": ['S AO1 R K IH0 N'],
  "sorkow": ['S AO1 R K AW2'],
  "sorlie": ['S AO1 R L IY0'],
  "soroka": ['S AO0 R OW1 K AH0'],
  "sororities": ['S ER0 AO1 R AH0 T IY0 Z'],
  "sorority": ['S ER0 AO1 R AH0 T IY0'],
  "soros": ['S AO1 R OW0 S'],
  "soros's": ['S AO1 R OW0 S IH0 Z'],
  "sorovski": ['S AO0 R AO1 V S K IY0'],
  "sorovski's": ['S AO0 R AO1 V S K IY0 Z'],
  "sorpasso": ['S AO0 R P AA1 S OW0'],
  "sorrel": ['S AO1 R AH0 L'],
  "sorrell": ['S AO1 R AH0 L'],
  "sorrell's": ['S AO1 R AH0 L Z'],
  "sorrells": ['S AO1 R AH0 L Z'],
  "sorrels": ['S AO1 R AH0 L Z'],
  "sorrenti": ['S AO0 R EH1 N T IY0'],
  "sorrow": ['S AA1 R OW0'],
  "sorrowful": ['S AA1 R OW0 F AH0 L'],
  "sorrows": ['S AA1 R OW0 Z'],
  "sorry": ['S AA1 R IY0'],
  "sort": ['S AO1 R T'],
  "sorted": ['S AO1 R T IH0 D'],
  "sorter": ['S AO1 R T ER0'],
  "sorters": ['S AO1 R T ER0 Z'],
  "sortie": ['S AO1 R T IY0'],
  "sorties": ['S AO1 R T IY0 Z'],
  "sorting": ['S AO1 R T IH0 NG'],
  "sortino": ['S AO0 R T IY1 N OW0'],
  "sortor": ['S AO1 R T ER0'],
  "sorts": ['S AO1 R T S'],
  "sorum": ['S AO1 R AH0 M'],
  "sorus": ['S AO1 R AH0 S'],
  "sorvino": ['S AO0 R V IY1 N OW2'],
  "sos": ['EH2 OW2 EH1 S'],
  "sosa": ['S OW1 S AH0'],
  "sosebee": ['S AA1 S IH0 B IY0'],
  "soshi": ['S OW1 SH IY2'],
  "sosin": ['S OW1 S IH2 N'],
  "sosinski": ['S AH0 S IH1 N S K IY2'],
  "soskin": ['S AA1 S K IH0 N'],
  "sosna": ['S OW1 S N AH0'],
  "sosnoff": ['S AO1 S N AO0 F'],
  "sosnoff's": ['S AO1 S N AO0 F S'],
  "sosnowski": ['S AH0 S N AO1 F S K IY2'],
  "soss": ['S AO1 S'],
  "sossamon": ['S OW0 S AA0 M AO1 N'],
  "sosuke": ['S OW0 S UW1 K EY2'],
  "sot": ['S AO1 T'],
  "sotak": ['S OW1 T AH0 K'],
  "sotello": ['S OW0 T EH1 L OW2'],
  "sotelo": ['S OW0 T EH1 L OW2'],
  "soter": ['S OW1 T ER0'],
  "sotheby": ['S AA1 TH AH0 B IY2'],
  "sotheby's": ['S AA1 TH AH0 B IY0 Z'],
  "soto": ['S OW1 T OW0'],
  "sotolongo": ['S OW2 T OW0 L OW1 NG G OW2'],
  "sotomayor": ['S OW2 T OW0 M EY0 AO1 R'],
  "sotti": ['S OW1 T IY2'],
  "sottile": ['S OW1 T AH0 L'],
  "souccar": ['S UW1 K AA2 R'],
  "soucek": ['S OW1 S IH0 K'],
  "soucie": ['S OW1 K IY0'],
  "soucy": ['S OW1 S IY0'],
  "souder": ['S AW1 D ER0'],
  "souders": ['S AW1 D ER0 Z'],
  "souers": ['S AW1 ER0 Z'],
  "souffle": ['S UW0 F L EY1'],
  "sough": ['S AW1 F', 'S OW1'],
  "sought": ['S AO1 T'],
  "souk": ['S UW1 K'],
  "soukup": ['S AW1 K AH0 P'],
  "soul": ['S OW1 L'],
  "soule": ['S AW1 L'],
  "soules": ['S UW1 L Z'],
  "soulful": ['S OW1 L F AH0 L'],
  "soulier": ['S UW1 L IY2 ER0'],
  "souljah": ['S UW1 L JH AA2'],
  "soulless": ['S OW1 L L AH0 S'],
  "soulliere": ['S UW1 L IY0 EH0 R'],
  "souls": ['S OW1 L Z'],
  "sound": ['S AW1 N D'],
  "sound's": ['S AW1 N D Z'],
  "soundbite": ['S AW1 N D B AY2 T'],
  "soundbites": ['S AW1 N D B AY2 T S'],
  "sounded": ['S AW1 N D IH0 D'],
  "sounder": ['S AW1 N D ER0'],
  "soundest": ['S AW1 N D AH0 S T'],
  "sounding": ['S AW1 N D IH0 NG'],
  "soundings": ['S AW1 N D IH0 NG Z'],
  "soundly": ['S AW1 N D L IY0'],
  "soundness": ['S AW1 N D N AH0 S'],
  "sounds": ['S AW1 N D Z', 'S AW1 N Z'],
  "soundscan": ['S AW1 N D S K AE2 N'],
  "soundtrack": ['S AW1 N D T R AE2 K', 'S AW1 N T R AE2 K'],
  "soundtracks": ['S AW1 N D T R AE2 K S', 'S AW1 N T R AE2 K S'],
  "soundview": ['S AW1 N D V Y UW2'],
  "soup": ['S UW1 P'],
  "soup's": ['S UW1 P S'],
  "souped": ['S UW1 P T'],
  "soups": ['S UW1 P S'],
  "soupy": ['S UW1 P IY2'],
  "sour": ['S AW1 ER0', 'S AW1 R'],
  "sourby": ['S AW1 R B IY0'],
  "source": ['S AO1 R S'],
  "source's": ['S AO1 R S IH0 Z'],
  "sourcebook": ['S AO1 R S B UH2 K'],
  "sources": ['S AO1 R S AH0 Z'],
  "sources'": ['S AO1 R S AH0 Z'],
  "sourcing": ['S AO1 R S IH0 NG'],
  "soured": ['S AW1 ER0 D'],
  "souring": ['S AW1 ER0 IH0 NG'],
  "souris": ['S UW1 R IH0 S'],
  "sourrouille": ['S AO0 R UW1 IY0'],
  "sours": ['S AW1 ER0 Z', 'S AW1 R Z'],
  "sous": ['S UW1 Z'],
  "sousa": ['S UW1 Z AH0'],
  "sousa's": ['S UW1 Z AH0 Z'],
  "sousaphone": ['S UW1 Z AH0 F OW2 N'],
  "sousley": ['S AW1 S L IY0'],
  "soutar": ['S UW0 T AA1 R'],
  "souter": ['S UW1 T ER0', 'S AW1 T ER0'],
  "south": ['S AW1 TH'],
  "south's": ['S AW1 TH S'],
  "southall": ['S AW1 TH AH0 L'],
  "southam": ['S AW1 TH AH0 M'],
  "southampton": ['S AW2 TH HH AE1 M P T AH0 N'],
  "southard": ['S AW1 TH ER0 D'],
  "southbound": ['S AW1 TH B AW2 N D'],
  "southdown": ['S AW1 TH D AW2 N'],
  "southdown's": ['S AW1 TH D AW2 N Z'],
  "southeast": ['S AW2 TH IY1 S T'],
  "southeast's": ['S AW2 TH IY1 S T S'],
  "southeastern": ['S AW2 TH IY1 S T ER0 N'],
  "southeastern's": ['S AW2 TH IY1 S T ER0 N Z'],
  "souther": ['S AH1 DH ER0'],
  "southerland": ['S AH1 DH ER0 L AH0 N D'],
  "southerly": ['S AH1 DH ER0 L IY0'],
  "southern": ['S AH1 DH ER0 N'],
  "southern's": ['S AH1 DH ER0 N Z'],
  "southerner": ['S AH1 DH ER0 N ER0'],
  "southerners": ['S AH1 DH ER0 N ER0 Z'],
  "southernmost": ['S AH1 DH ER0 N M OW2 S T'],
  "southernnet": ['S AH1 DH ER0 N EH0 T'],
  "southernnet's": ['S AH1 DH ER0 N EH0 T S'],
  "southers": ['S AH1 DH ER0 Z'],
  "southfield": ['S AW1 TH F IY2 L D'],
  "southgate": ['S AW1 TH G EY2 T'],
  "southin": ['S AW1 TH IH2 N'],
  "southland": ['S AW1 TH L AE2 N D'],
  "southland's": ['S AW1 TH L AE2 N D Z'],
  "southlife": ['S AW1 TH L AY2 F'],
  "southmark": ['S AW1 TH M AA2 R K'],
  "southmark's": ['S AW1 TH M AA2 R K S'],
  "southold": ['S AW1 TH OW2 L D'],
  "southpaw": ['S AW1 TH P AW2'],
  "southport": ['S AW1 TH P AO2 R T'],
  "souths": ['S AW1 TH S'],
  "southside": ['S AW1 TH S AY2 D'],
  "southstate": ['S AW1 TH S T EY2 T'],
  "southwall": ['S AW1 TH W AO2 L'],
  "southward": ['S AW1 TH W ER0 D'],
  "southway": ['S AW1 TH W EY2'],
  "southwell": ['S AW1 TH W EH2 L'],
  "southwest": ['S AW2 TH W EH1 S T'],
  "southwest's": ['S AW2 TH W EH1 S T S'],
  "southwestern": ['S AW2 TH W EH1 S T ER0 N'],
  "southwick": ['S AW1 TH W IH0 K'],
  "southwood": ['S AW1 TH W UH2 D'],
  "southworth": ['S AW1 TH W ER0 TH'],
  "souto": ['S UW1 T OW0'],
  "souvenir": ['S UW2 V AH0 N IH1 R'],
  "souvenirs": ['S UW2 V AH0 N IH1 R Z'],
  "souveroff": ['S UW1 V ER0 AO0 F'],
  "souza": ['S UW1 Z AH0'],
  "souzas": ['S UW1 Z AH0 Z'],
  "sova": ['S OW1 V AH0'],
  "sovereign": ['S AA1 V R AH0 N'],
  "sovereigns": ['S AA1 V R AH0 N Z'],
  "sovereignty": ['S AA1 V R AH0 N T IY0'],
  "sovetskaya": ['S OW2 V EH0 T S K AY1 AA0'],
  "sovexportfilm": ['S OW2 V EH2 K S P AO0 R T F IH1 L M'],
  "sovey": ['S OW1 V IY0'],
  "soviet": ['S OW1 V IY0 AH0 T', 'S OW1 V IY0 EH2 T'],
  "soviet's": ['S OW1 V IY0 EH2 T S'],
  "soviet-union": ['S OW1 V IY0 EH2 T Y UW1 N Y AH0 N'],
  "sovietologist": ['S OW2 V IY0 AH0 T AA1 L AH0 JH IH0 S T'],
  "sovietologists": ['S OW2 V IY0 AH0 T AA1 L AH0 JH IH0 S T S'],
  "soviets": ['S OW1 V IY0 EH2 T S'],
  "soviets'": ['S OW1 V IY0 EH2 T S'],
  "sovine": ['S AA1 V AY0 N'],
  "sovran": ['S AA1 V R AH0 N'],
  "sovran's": ['S AA1 V R AH0 N Z'],
  "sovrans": ['S AA1 V R AH0 N Z'],
  "sovyetsky": ['S OW0 V Y EH1 T S K IY2'],
  "sow": ['S AW1', 'S OW1'],
  "sowa": ['S OW1 AH0'],
  "soward": ['S OW1 ER0 D'],
  "sowards": ['S OW1 ER0 D Z'],
  "sowash": ['S OW1 AH0 SH'],
  "sowata": ['S OW2 AA1 T AH0'],
  "sowata's": ['S OW2 AA1 T AH0 Z'],
  "sowden": ['S OW1 D AH0 N'],
  "sowder": ['S OW1 D ER0'],
  "sowders": ['S OW1 D ER0 Z'],
  "sowed": ['S AW1 D', 'S OW1 D'],
  "sowell": ['S AA1 W EH0 L'],
  "sower": ['S OW1 ER0'],
  "sowers": ['S OW1 ER0 Z'],
  "soweto": ['S OW0 EY1 T OW0'],
  "sowing": ['S OW1 IH0 NG'],
  "sowinski": ['S OW0 IH1 N S K IY0'],
  "sowle": ['S OW1 L'],
  "sowles": ['S OW1 L Z'],
  "sown": ['S OW1 N'],
  "sows": ['S OW1 Z'],
  "sox": ['S AA1 K S'],
  "sox's": ['S AA1 K S IH0 Z'],
  "soy": ['S OY1'],
  "soya": ['S OY1 AH0'],
  "soyars": ['S OY1 ER0 Z'],
  "soybean": ['S OY1 B IY2 N'],
  "soybeans": ['S OY1 B IY2 N Z'],
  "soyka": ['S OY1 K AH0'],
  "soysauce": ['S OY1 S AO2 S'],
  "soyuz": ['S OY1 AH0 Z', 'S OY1 UW2 Z'],
  "soyuz's": ['S OY1 AH0 Z IH0 Z', 'S OY1 UW2 Z IH0 Z'],
  "soza": ['S OW1 Z AH0'],
  "sozio": ['S OW1 Z IY0 OW0'],
  "spa": ['S P AA1'],
  "space": ['S P EY1 S'],
  "space-time": ['S P EY1 S T AY2 M'],
  "spaceball": ['S P EY1 S B AO2 L'],
  "spaceballs": ['S P EY1 S B AO2 L Z'],
  "spaceband": ['S P EY1 S B AE2 N D'],
  "spacebands": ['S P EY1 S B AE2 N D Z'],
  "spacecraft": ['S P EY1 S K R AE2 F T'],
  "spacecraft's": ['S P EY1 S K R AE2 F T S'],
  "spaced": ['S P EY1 S T'],
  "spacehab": ['S P EY1 S HH AE2 B'],
  "spacek": ['S P AA1 CH EH2 K', 'S P AA1 S EH2 K'],
  "spacelink": ['S P EY1 S L IH2 NG K'],
  "spacenet": ['S P EY1 S N EH2 T'],
  "spaceport": ['S P EY1 S P AO2 R T'],
  "spaceports": ['S P EY1 S P AO2 R T S'],
  "spacer": ['S P EY1 S ER0'],
  "spacers": ['S P EY1 S ER0 Z'],
  "spaces": ['S P EY1 S AH0 Z', 'S P EY1 S IH0 Z'],
  "spaceship": ['S P EY1 S SH IH2 P'],
  "spaceships": ['S P EY1 S SH IH2 P S'],
  "spacesuit": ['S P EY1 S UW2 T'],
  "spacesuits": ['S P EY1 S UW2 T S'],
  "spacewalk": ['S P EY1 S W AA2 K'],
  "spacewalking": ['S P EY1 S W AA2 K IH0 NG'],
  "spacewalks": ['S P EY1 S W AA2 K S'],
  "spacex": ['S P EY1 S AH0 K S'],
  "spacey": ['S P EY1 S IY0'],
  "spacial": ['S P EY1 SH AH0 L'],
  "spacing": ['S P EY1 S IH0 NG'],
  "spacious": ['S P EY1 SH AH0 S'],
  "spack": ['S P AE1 K'],
  "spackle": ['S P AE1 K AH0 L'],
  "spackman": ['S P AE1 K M AH0 N'],
  "spada": ['S P AA1 D AH0'],
  "spadaccini": ['S P AA0 D AA0 CH IY1 N IY0'],
  "spadafora": ['S P AA0 D AA0 F AO1 R AH0'],
  "spadafore": ['S P AA0 D AO1 F AO0 R'],
  "spadaro": ['S P AA0 D AA1 R OW0'],
  "spade": ['S P EY1 D'],
  "spader": ['S P EY1 D ER0'],
  "spades": ['S P EY1 D Z'],
  "spadework": ['S P EY1 D W ER2 K'],
  "spadoni": ['S P AA0 D OW1 N IY0'],
  "spady": ['S P EY1 D IY0'],
  "spaeth": ['S P IY1 TH'],
  "spafford": ['S P AE1 F ER0 D'],
  "spaghetti": ['S P AH0 G EH1 T IY0'],
  "spagna": ['S P AE1 G N AH0'],
  "spagnola": ['S P AA0 G N OW1 L AH0'],
  "spagnoli": ['S P AA0 G N OW1 L IY0'],
  "spagnolo": ['S P AA0 G N OW1 L OW0'],
  "spagnuolo": ['S P AA0 G N Y UW0 OW1 L OW0'],
  "spago": ['S P EY1 G OW0'],
  "spahn": ['S P AA1 N'],
  "spahr": ['S P AA1 R'],
  "spaid": ['S P EY1 D'],
  "spain": ['S P EY1 N'],
  "spain's": ['S P EY1 N Z'],
  "spainhour": ['S P AY1 N AW0 R'],
  "spainhower": ['S P AY1 N HH OW0 ER0'],
  "spak": ['S P AE1 K'],
  "spake": ['S P EY1 K'],
  "spalding": ['S P AO1 L D IH0 NG'],
  "spalink": ['S P EY1 L IH2 NG K', 'S P AA1 L IH2 NG K'],
  "spall": ['S P AO1 L'],
  "spalla": ['S P AE1 L AH0'],
  "spallone": ['S P AE1 L OW2 N'],
  "spalvins": ['S P AE1 L V IH0 N Z'],
  "spam": ['S P AE1 M'],
  "spamming": ['S P AE1 M IH0 NG'],
  "spampinato": ['S P AA0 M P IY0 N AA1 T OW0'],
  "span": ['S P AE1 N'],
  "span's": ['S P AE1 N Z'],
  "spanbauer": ['S P AE1 N B AW0 ER0'],
  "spandex": ['S P AE1 N D AH0 K S'],
  "spang": ['S P AE1 NG'],
  "spangenberg": ['S P AE1 NG AH0 N B ER0 G'],
  "spangle": ['S P AE1 NG G AH0 L'],
  "spangled": ['S P AE1 NG G AH0 L D'],
  "spangler": ['S P AE1 NG G AH0 L ER0', 'S P AE1 NG G L ER0'],
  "spaniard": ['S P AE1 N Y ER0 D'],
  "spaniards": ['S P AE1 N Y ER0 D Z'],
  "spaniel": ['S P AE1 N Y AH0 L'],
  "spanier": ['S P AE1 N Y ER0'],
  "spaniol": ['S P AE1 N Y AH0 L'],
  "spanish": ['S P AE1 N IH0 SH'],
  "spank": ['S P AE1 NG K'],
  "spanked": ['S P AE1 NG K T'],
  "spanking": ['S P AE1 NG K IH0 NG'],
  "spanky": ['S P AE1 N K IY2'],
  "spann": ['S P AE1 N'],
  "spanned": ['S P AE1 N D'],
  "spanner": ['S P AE1 N ER0'],
  "spanning": ['S P AE1 N IH0 NG'],
  "spanninger": ['S P AE1 N IH0 NG ER0'],
  "spano": ['S P AA1 N OW0'],
  "spanos": ['S P EY1 N OW0 Z'],
  "spans": ['S P AE1 N Z'],
  "spanton": ['S P AE1 N T AH0 N'],
  "spar": ['S P AA1 R'],
  "sparacino": ['S P ER0 AH0 CH IY1 N OW0'],
  "sparacio": ['S P ER0 EY1 S IY0 OW0'],
  "sparaco": ['S P ER0 AE1 K OW0'],
  "sparano": ['S P ER0 AE1 N OW0'],
  "sparc": ['S P AA1 R K'],
  "spare": ['S P EH1 R'],
  "spared": ['S P EH1 R D'],
  "spares": ['S P EH1 R Z'],
  "sparger": ['S P AA1 R JH ER0'],
  "spargo": ['S P AA1 R G OW0'],
  "spargur": ['S P AA1 R G ER0'],
  "sparing": ['S P EH1 R IH0 NG'],
  "sparingly": ['S P EH1 R IH0 NG L IY0'],
  "spark": ['S P AA1 R K'],
  "sparked": ['S P AA1 R K T'],
  "sparkes": ['S P AA1 R K S'],
  "sparking": ['S P AA1 R K IH0 NG'],
  "sparkle": ['S P AA1 R K AH0 L'],
  "sparkled": ['S P AA1 R K AH0 L D'],
  "sparkles": ['S P AA1 R K AH0 L Z'],
  "sparkling": ['S P AA1 R K L IH0 NG', 'S P AA1 R K AH0 L IH0 NG'],
  "sparkly": ['S P AA1 R K L IY0'],
  "sparkman": ['S P AA1 R K M AH0 N'],
  "sparks": ['S P AA1 R K S'],
  "sparky": ['S P AA1 R K IY0'],
  "sparlin": ['S P AA1 R L IH0 N'],
  "sparling": ['S P AA1 R L IH0 NG'],
  "sparr": ['S P AE1 R'],
  "sparred": ['S P AA1 R D'],
  "sparring": ['S P AA1 R IH0 NG'],
  "sparrow": ['S P EH1 R OW0'],
  "sparrows": ['S P EH1 R OW0 Z'],
  "sparse": ['S P AA1 R S'],
  "sparsely": ['S P AA1 R S L IY0'],
  "sparta": ['S P AA1 R T AA0'],
  "spartacus": ['S P AA1 R T AH0 K AH0 S'],
  "spartan": ['S P AA1 R T AH0 N'],
  "spartanburg": ['S P AA1 R T AH0 N B ER2 G'],
  "spartans": ['S P AA1 R T AH0 N Z'],
  "spartech": ['S P AA1 R T EH2 K'],
  "spartz": ['S P AA1 R T S'],
  "spas": ['S P AA1 Z'],
  "spasm": ['S P AE1 Z AH0 M'],
  "spasmodic": ['S P AE0 Z M AO1 D IH0 K'],
  "spasmodically": ['S P AE0 Z M AO1 D IH0 K L IY0'],
  "spasms": ['S P AE1 Z AH0 M Z'],
  "spasso": ['S P AE1 S OW2'],
  "spastic": ['S P AE1 S T IH0 K'],
  "spat": ['S P AE1 T'],
  "spatafora": ['S P AA0 T AA0 F AO1 R AA2'],
  "spatafore": ['S P AE1 T AH0 F AO2 R'],
  "spataro": ['S P AA0 T AA1 R OW2'],
  "spate": ['S P EY1 T'],
  "spates": ['S P EY1 T S'],
  "spath": ['S P AE1 TH'],
  "spatial": ['S P EY1 SH AH0 L'],
  "spatola": ['S P AA0 T OW1 L AA0'],
  "spats": ['S P AE1 T S'],
  "spatter": ['S P AE1 T ER0'],
  "spattered": ['S P AE1 T ER0 D'],
  "spattering": ['S P AE1 T ER0 IH0 NG'],
  "spatters": ['S P AE1 T ER0 Z'],
  "spatula": ['S P AE1 CH UH2 L AH0'],
  "spatulas": ['S P AE1 CH UH2 L AH0 Z'],
  "spatz": ['S P AE1 T S'],
  "spaugh": ['S P AO1'],
  "spaulding": ['S P AO1 L D IH0 NG'],
  "spaur": ['S P AO1 R'],
  "spavo": ['S P AA1 V OW2'],
  "spaw": ['S P AO1'],
  "spawn": ['S P AA1 N', 'S P AO1 N'],
  "spawned": ['S P AO1 N D'],
  "spawning": ['S P AA1 N IH0 NG', 'S P AO1 N IH0 NG'],
  "spawns": ['S P AA1 N Z', 'S P AO1 N Z'],
  "spay": ['S P EY1'],
  "spayd": ['S P EY1 D'],
  "spayde": ['S P EY1 D'],
  "spayed": ['S P EY1 D'],
  "spaz": ['S P AE1 Z'],
  "spazes": ['S P AE1 Z EH0 Z'],
  "spaziani": ['S P AA0 Z IY0 AA1 N IY2'],
  "speagle": ['S P IY1 G AH0 L'],
  "speak": ['S P IY1 K'],
  "speak-easy": ['S P IY1 K IY2 Z IY2'],
  "speake": ['S P IY1 K'],
  "speaker": ['S P IY1 K ER0'],
  "speaker's": ['S P IY1 K ER0 Z'],
  "speakerphone": ['S P IY1 K ER0 F OW2 N'],
  "speakers": ['S P IY1 K ER0 Z'],
  "speakership": ['S P IY1 K ER0 SH IH2 P'],
  "speakes": ['S P IY1 K S'],
  "speakes's": ['S P IY1 K S IH0 Z'],
  "speaking": ['S P IY1 K IH0 NG'],
  "speakman": ['S P IY1 K M AH0 N'],
  "speaks": ['S P IY1 K S'],
  "spear": ['S P IH1 R'],
  "speare": ['S P IY1 R'],
  "spearhead": ['S P IH1 R HH EH2 D'],
  "spearheaded": ['S P IH1 R HH EH2 D IH0 D'],
  "spearheading": ['S P IH1 R HH EH2 D IH0 NG'],
  "spearing": ['S P IH1 R IH0 NG'],
  "spearman": ['S P IH1 R M AH0 N'],
  "spears": ['S P IH1 R Z'],
  "speas": ['S P IY1 Z'],
  "spease": ['S P IY1 Z'],
  "spec": ['S P EH1 K'],
  "specht": ['S P EH1 K T'],
  "special": ['S P EH1 SH AH0 L'],
  "special's": ['S P EH1 SH AH0 L Z'],
  "speciale": ['S P EH1 CH AH0 L IY0'],
  "specialist": ['S P EH1 SH AH0 L AH0 S T', 'S P EH1 SH AH0 L IH0 S T'],
  "specialists": ['S P EH1 SH AH0 L AH0 S T S', 'S P EH1 SH AH0 L IH0 S T S'],
  "specialists'": ['S P EH1 SH AH0 L IH0 S T S'],
  "specialities": ['S P EH1 SH AH0 L T IY0 Z'],
  "speciality": ['S P EH2 SH IY0 AE1 L IH0 T IY0'],
  "specialization": ['S P EH2 SH AH0 L AH0 Z EY1 SH AH0 N'],
  "specialize": ['S P EH1 SH AH0 L AY2 Z'],
  "specialized": ['S P EH1 SH AH0 L AY2 Z D'],
  "specializes": ['S P EH1 SH AH0 L AY2 Z AH0 Z', 'S P EH1 SH AH0 L AY2 Z IH0 Z'],
  "specializing": ['S P EH1 SH AH0 L AY2 Z IH0 NG'],
  "specially": ['S P EH1 SH AH0 L IY0', 'S P EH1 SH L IY0'],
  "specials": ['S P EH1 SH AH0 L Z'],
  "specialties": ['S P EH1 SH AH0 L T IY0 Z'],
  "specialty": ['S P EH1 SH AH0 L T IY0', 'S P EY1 SH AH0 L T IY0'],
  "species": ['S P IY1 SH IY0 Z'],
  "species'": ['S P IY1 SH IY0 Z'],
  "specific": ['S P AH0 S IH1 F IH0 K', 'S P IH0 S IH1 F IH0 K'],
  "specifically": ['S P AH0 S IH1 F IH0 K L IY0'],
  "specification": ['S P EH2 S IH0 F IH0 K EY1 SH AH0 N'],
  "specifications": ['S P EH2 S AH0 F AH0 K EY1 SH AH0 N Z'],
  "specificity": ['S P EH2 S AH0 F IH1 S AH0 T IY0'],
  "specifics": ['S P IH0 S IH1 F IH0 K S'],
  "specified": ['S P EH1 S AH0 F AY2 D'],
  "specifies": ['S P EH1 S AH0 F AY2 Z'],
  "specify": ['S P EH1 S AH0 F AY2'],
  "specifying": ['S P EH1 S AH0 F AY2 IH0 NG'],
  "specimen": ['S P EH1 S AH0 M AH0 N'],
  "specimens": ['S P EH1 S AH0 M AH0 N Z'],
  "specious": ['S P IY1 SH AH0 S'],
  "speck": ['S P EH1 K'],
  "specker": ['S P EH1 K ER0'],
  "speckle": ['S P EH1 K AH0 L'],
  "speckled": ['S P EH1 K AH0 L D'],
  "speckman": ['S P EH1 K M AH0 N'],
  "specks": ['S P EH1 K S'],
  "specs": ['S P EH1 K S'],
  "spectacle": ['S P EH1 K T AH0 K AH0 L'],
  "spectacles": ['S P EH1 K T AH0 K AH0 L Z'],
  "spectacular": ['S P EH0 K T AE1 K Y AH0 L ER0'],
  "spectacularly": ['S P EH0 K T AE1 K Y AH0 L ER0 L IY0'],
  "spectator": ['S P EH1 K T EY0 T ER0'],
  "spectators": ['S P EH1 K T EY0 T ER0 Z'],
  "specter": ['S P EH1 K T ER0'],
  "specter's": ['S P EH1 K T ER0 Z'],
  "specthrie": ['S P EH1 K TH R IY0'],
  "spector": ['S P EH1 K T ER0'],
  "spectra": ['S P EH1 K T R AH0'],
  "spectra's": ['S P EH1 K T R AH0 Z'],
  "spectradyne": ['S P EH1 K T R AH0 D AY2 N'],
  "spectral": ['S P EH1 K T R AH0 L'],
  "spectramed": ['S P EH1 K T R AH0 M D', 'S P EH1 K T R AH0 M EH2 D'],
  "spectran": ['S P EH1 K T R AE2 N'],
  "spectravision": ['S P EH1 K T R AH0 V IH2 ZH AH0 N'],
  "spectre": ['S P EH1 K T ER0'],
  "spectrograph": ['S P EH1 K T R AH0 G R AE2 F'],
  "spectrometer": ['S P EH0 K T R AA1 M AH0 T ER0'],
  "spectrometry": ['S P EH0 K T R AA1 M AH0 T R IY0'],
  "spectroscopy": ['S P EH0 K T R AA1 S K AH0 P IY0'],
  "spectrum": ['S P EH1 K T R AH0 M'],
  "spectrum's": ['S P EH1 K T R AH0 M Z'],
  "spectrums": ['S P EH1 K T R AH0 M Z'],
  "speculate": ['S P EH1 K Y AH0 L EY2 T'],
  "speculated": ['S P EH1 K Y AH0 L EY2 T IH0 D'],
  "speculates": ['S P EH1 K Y AH0 L EY2 T S'],
  "speculating": ['S P EH1 K Y AH0 L EY2 T IH0 NG'],
  "speculation": ['S P EH2 K Y AH0 L EY1 SH AH0 N'],
  "speculations": ['S P EH2 K Y AH0 L EY1 SH AH0 N Z'],
  "speculative": ['S P EH1 K Y AH0 L AH0 T IH0 V'],
  "speculator": ['S P EH1 K Y AH0 L EY2 T ER0'],
  "speculators": ['S P EH1 K Y AH0 L EY2 T ER0 Z'],
  "speculators'": ['S P EH1 K Y AH0 L ER0 T EY2 Z'],
  "sped": ['S P EH1 D'],
  "speece": ['S P IY1 S'],
  "speech": ['S P IY1 CH'],
  "speeches": ['S P IY1 CH AH0 Z', 'S P IY1 CH IH0 Z'],
  "speechify": ['S P IY1 CH AH0 F AY2'],
  "speechifying": ['S P IY1 CH AH0 F AY2 IH0 NG'],
  "speechless": ['S P IY1 CH L AH0 S'],
  "speechwriter": ['S P IY1 CH R AY2 T ER0'],
  "speechwriters": ['S P IY1 CH R AY2 T ER0 Z'],
  "speed": ['S P IY1 D'],
  "speedboat": ['S P IY1 D B OW2 T'],
  "speedboats": ['S P IY1 D B OW2 T S'],
  "speeded": ['S P IY1 D IH0 D'],
  "speeder": ['S P IY1 D ER0'],
  "speeders": ['S P IY1 D ER0 Z'],
  "speedier": ['S P IY1 D IY0 ER0'],
  "speedily": ['S P IY1 D AH0 L IY0'],
  "speeding": ['S P IY1 D IH0 NG'],
  "speedometer": ['S P IY0 D AA1 M AH0 T ER0'],
  "speedring": ['S P IY1 D R IH2 NG'],
  "speeds": ['S P IY1 D Z'],
  "speedskate": ['S P IY1 D S K EY2 T'],
  "speedskating": ['S P IY1 D S K EY2 T IH0 NG'],
  "speedup": ['S P IY1 D AH2 P'],
  "speedway": ['S P IY1 D W EY2'],
  "speedy": ['S P IY1 D IY2'],
  "speegle": ['S P IY1 G AH0 L'],
  "speelman": ['S P IY1 L M AH0 N'],
  "speer": ['S P IH1 R'],
  "spees": ['S P IY1 Z'],
  "spegal": ['S P IY1 G AH0 L'],
  "spehar": ['S P EH1 HH ER0'],
  "speich": ['S P AY1 K'],
  "speicher": ['S P AY1 K ER0'],
  "speidel": ['S P AY1 D AH0 L'],
  "speier": ['S P AY1 ER0'],
  "speight": ['S P EY1 T'],
  "speights": ['S P EY1 T S'],
  "speigner": ['S P AY1 G N ER0'],
  "speir": ['S P IH1 R'],
  "speirs": ['S P IH1 R Z'],
  "speiser": ['S P AY1 Z ER0'],
  "spektr": ['S P EH1 K T ER0'],
  "spektr's": ['S P EH1 K T ER0 Z'],
  "spell": ['S P EH1 L'],
  "spellacy": ['S P EH1 L AH0 S IY0'],
  "spellbinding": ['S P EH1 L B AY2 N D IH0 NG'],
  "spellbound": ['S P EH1 L B AW2 N D'],
  "spelled": ['S P EH1 L D'],
  "speller": ['S P EH1 L ER0'],
  "spellers": ['S P EH1 L ER0 Z'],
  "spelling": ['S P EH1 L IH0 NG'],
  "spelling's": ['S P EH1 L IH0 NG Z'],
  "spellings": ['S P EH1 L IH0 NG Z'],
  "spellman": ['S P EH1 L M AH0 N'],
  "spellman's": ['S P EH1 L M AH0 N Z'],
  "spellmeyer": ['S P EH1 L M AY0 ER0'],
  "spellmeyer's": ['S P EH1 L M AY0 ER0 Z'],
  "spells": ['S P EH1 L Z'],
  "spelman": ['S P EH1 L M AH0 N'],
  "speltz": ['S P EH1 L T S'],
  "spelunk": ['S P AH0 L AH1 NG K'],
  "spelunked": ['S P AH0 L AH1 NG K T'],
  "spelunker": ['S P AH0 L AH1 NG K ER0'],
  "spelunkers": ['S P AH0 L AH1 NG K ER0 Z'],
  "spelunking": ['S P AH0 L AH1 NG K IH0 NG'],
  "spelunks": ['S P AH0 L AH1 NG K S'],
  "spence": ['S P EH1 N S'],
  "spencer": ['S P EH1 N S ER0'],
  "spencer's": ['S P EH1 N S ER0 Z'],
  "spencers": ['S P EH1 N S ER0 Z'],
  "spend": ['S P EH1 N D'],
  "spendable": ['S P EH1 N D AH0 B AH0 L'],
  "spender": ['S P EH1 N D ER0'],
  "spenders": ['S P EH1 N D ER0 Z'],
  "spending": ['S P EH1 N D IH0 NG'],
  "spendley": ['S P EH1 N D L IY0'],
  "spendlove": ['S P EH1 N D L AH2 V'],
  "spends": ['S P EH1 N D Z', 'S P EH1 N Z'],
  "spendthrift": ['S P EH1 N D TH R IH2 F T'],
  "spengler": ['S P IH1 NG AH0 L ER0', 'S P IH1 NG L ER0'],
  "spenner": ['S P EH1 N ER0'],
  "speno": ['S P EH1 N OW0'],
  "spens": ['S P EH1 N S'],
  "spenser": ['S P EH1 N S ER0'],
  "spenser's": ['S P EH1 N S ER0 Z'],
  "spensers": ['S P EH1 N S ER0 Z'],
  "spent": ['S P EH1 N T'],
  "spera": ['S P EH1 R AH0'],
  "speranza": ['S P ER0 AA1 N Z AH0'],
  "sperbeck": ['S P ER1 B EH0 K'],
  "sperber": ['S P ER1 B ER0'],
  "sperduto": ['S P ER0 D UW1 T OW0'],
  "sperl": ['S P ER1 L'],
  "sperle": ['S P ER1 L'],
  "sperlich": ['S P ER1 L IH0 K'],
  "sperling": ['S P ER1 L IH0 NG'],
  "sperm": ['S P ER1 M'],
  "sperms": ['S P ER1 M Z'],
  "spero": ['S P EH1 R OW0'],
  "speros": ['S P EH1 R OW0 Z'],
  "sperrazza": ['S P ER0 AA1 T S AH0'],
  "sperrfrist": ['S P EH1 R F R IH0 S T'],
  "sperry": ['S P EH1 R IY0'],
  "spessard": ['S P EH1 S ER0 D'],
  "speth": ['S P EH1 TH'],
  "spethmann": ['S P EH1 TH M AH0 N'],
  "spetsnaz": ['S P EH1 T S N AE0 Z'],
  "spevak": ['S P EH1 V AH0 K'],
  "spew": ['S P Y UW1'],
  "spewed": ['S P Y UW1 D'],
  "spewing": ['S P Y UW1 IH0 NG'],
  "spews": ['S P Y UW1 Z'],
  "spey": ['S P EY1'],
  "speyer": ['S P EY1 ER0'],
  "speziale": ['S P EH0 Z IY0 AA1 L IY0'],
  "spezzano": ['S P EH0 T S AA1 N OW0'],
  "sphar": ['S F AA1 R'],
  "sphere": ['S F IH1 R'],
  "spheres": ['S F IH1 R Z'],
  "spherical": ['S F EH1 R IH0 K AH0 L'],
  "spheroid": ['S F IH1 R OY2 D'],
  "sphincter": ['S F IH1 NG K T ER0'],
  "sphincters": ['S F IH1 NG K T ER0 Z'],
  "sphinx": ['S F IH1 NG K S'],
  "spic": ['S P IH1 K'],
  "spice": ['S P AY1 S'],
  "spiced": ['S P AY1 S T'],
  "spiceland": ['S P AY1 S L AE2 N D'],
  "spicer": ['S P AY1 S ER0'],
  "spices": ['S P AY1 S AH0 Z', 'S P AY1 S IH0 Z'],
  "spicey": ['S P AY1 S IY0'],
  "spicher": ['S P IH1 CH ER0'],
  "spicing": ['S P AY1 S IH0 NG'],
  "spickard": ['S P IH1 K ER0 D'],
  "spickler": ['S P IH1 K L ER0'],
  "spicuzza": ['S P IY0 K UW1 T S AH0'],
  "spicy": ['S P AY1 S IY0'],
  "spidel": ['S P IH1 D AH0 L'],
  "spidell": ['S P IH1 D AH0 L'],
  "spider": ['S P AY1 D ER0'],
  "spider-man": ['S P AY1 D ER0 M AE0 N'],
  "spiderman": ['S P AY1 D ER0 M AE0 N'],
  "spiders": ['S P AY1 D ER0 Z'],
  "spidle": ['S P AY1 D AH0 L'],
  "spie": ['S P IY1'],
  "spied": ['S P AY1 D'],
  "spiegel": ['S P IY1 G AH0 L'],
  "spiegelberg": ['S P IY1 G AH0 L B ER0 G'],
  "spiegelman": ['S P IY1 G AH0 L M AH0 N'],
  "spiegler": ['S P IY1 G L ER0'],
  "spieker": ['S P IY1 K ER0'],
  "spiel": ['S P IY1 L'],
  "spielberg": ['S P IY1 L B ER0 G'],
  "spielberg's": ['S P IY1 L B ER0 G Z'],
  "spielberger": ['S P IY1 L B ER0 G ER0'],
  "spieler": ['S P IY1 L ER0'],
  "spielman": ['S P IY1 L M AH0 N'],
  "spielmann": ['S P IY1 L M AH0 N'],
  "spielvogel": ['S P IY1 L V OW2 G AH0 L'],
  "spier": ['S P AY1 ER0'],
  "spiering": ['S P AY1 ER0 IH0 NG'],
  "spiers": ['S P AY1 ER0 Z'],
  "spies": ['S P AY1 Z'],
  "spiess": ['S P IY1 Z'],
  "spieth": ['S P IY1 TH', 'S P AY1 AH0 TH'],
  "spieth's": ['S P IY1 TH S'],
  "spiewak": ['S P IY1 W AE2 K'],
  "spiffing": ['S P IH1 F IH0 NG'],
  "spiffy": ['S P IH1 F IY0'],
  "spight": ['S P AY1 T'],
  "spigner": ['S P AY1 G N ER0'],
  "spigot": ['S P IH1 G AH0 T'],
  "spigots": ['S P IH1 G AH0 T S'],
  "spike": ['S P AY1 K'],
  "spiked": ['S P AY1 K T'],
  "spiker": ['S P AY1 K ER0'],
  "spikes": ['S P AY1 K S'],
  "spiking": ['S P AY1 K IH0 NG'],
  "spiky": ['S P AY1 K IY0'],
  "spilde": ['S P IH1 L D'],
  "spilker": ['S P IH1 L K ER0'],
  "spill": ['S P IH1 L'],
  "spill's": ['S P IH1 L Z'],
  "spillage": ['S P IH1 L IH0 JH'],
  "spillane": ['S P IH1 L AH0 N'],
  "spilled": ['S P IH1 L D'],
  "spiller": ['S P IH1 L ER0'],
  "spillers": ['S P IH1 L ER0 Z'],
  "spilling": ['S P IH1 L IH0 NG'],
  "spillman": ['S P IH1 L M AH0 N'],
  "spillover": ['S P IH1 L OW2 V ER0'],
  "spills": ['S P IH1 L Z'],
  "spillway": ['S P IH1 L W EY2'],
  "spilman": ['S P IH1 L M AH0 N'],
  "spilotro": ['S P IH0 L AA1 T R OW0'],
  "spilt": ['S P IH1 L T'],
  "spin": ['S P IH1 N'],
  "spina": ['S P IY1 N AH0'],
  "spinach": ['S P IH1 N AH0 CH'],
  "spinal": ['S P AY1 N AH0 L'],
  "spinale": ['S P IY0 N AA1 L IY0'],
  "spindel": ['S P IH1 N D AH0 L'],
  "spindle": ['S P IH1 N D AH0 L'],
  "spindler": ['S P IH1 N D AH0 L ER0', 'S P IH1 N D L ER0'],
  "spine": ['S P AY1 N'],
  "spineless": ['S P AY1 N L AH0 S'],
  "spinella": ['S P IH0 N EH1 L AH0'],
  "spinelli": ['S P IH0 N EH1 L IY0'],
  "spinello": ['S P IH0 N EH1 L OW0'],
  "spines": ['S P AY1 N Z'],
  "spinfizz": ['S P IH1 N F IH0 Z'],
  "spinifex": ['S P IH1 N IH0 F EH0 K S'],
  "spink": ['S P IH1 NG K'],
  "spinks": ['S P IH1 NG K S'],
  "spinks's": ['S P IH1 NG K S IH0 Z'],
  "spinner": ['S P IH1 N ER0'],
  "spinner's": ['S P IH1 N ER0 Z'],
  "spinners": ['S P IH1 N ER0 Z'],
  "spinney": ['S P IH1 N IY0'],
  "spinning": ['S P IH1 N IH0 NG'],
  "spino": ['S P IY1 N OW0'],
  "spinoff": ['S P IH1 N AO2 F'],
  "spinoffs": ['S P IH1 N AO2 F S'],
  "spinola": ['S P IY0 N OW1 L AH0'],
  "spinosa": ['S P IY0 N OW1 S AH0'],
  "spinoza": ['S P IH0 N OW1 Z AH0'],
  "spins": ['S P IH1 N Z'],
  "spinster": ['S P IH1 N S T ER0'],
  "spiny": ['S P AY1 N IY0'],
  "spira": ['S P IH1 R AH0'],
  "spiral": ['S P AY1 R AH0 L'],
  "spiraled": ['S P AY1 R AH0 L D'],
  "spiraling": ['S P AY1 R AH0 L IH0 NG'],
  "spiralling": ['S P AY1 R AH0 L IH0 NG'],
  "spirals": ['S P AY1 R AH0 L Z'],
  "spire": ['S P AY1 R'],
  "spires": ['S P AY1 R Z'],
  "spirit": ['S P IH1 R AH0 T', 'S P IH1 R IH0 T'],
  "spirit's": ['S P IH1 R IH0 T S'],
  "spirited": ['S P IH1 R IH0 T IH0 D'],
  "spiritedness": ['S P IH1 R IH0 T IH0 D N AH0 S'],
  "spirito": ['S P IH0 R IY1 T OW2'],
  "spirits": ['S P IH1 R IH0 T S'],
  "spiritual": ['S P IH1 R IH0 CH UW2 AH0 L'],
  "spiritualism": ['S P IH1 R IH0 CH UW2 AH0 L IH2 Z M'],
  "spiritualist": ['S P IH1 R IH0 CH UW2 AH0 L IH2 S T'],
  "spiritualists": ['S P IH1 R IH0 CH UW2 AH0 L IH2 S T S'],
  "spirituality": ['S P IH2 R IH0 CH UW2 AE1 L AH0 T IY2'],
  "spiritually": ['S P IH1 R IH0 CH UW2 AH0 L IY2'],
  "spirituals": ['S P IH1 R IH0 CH UW2 AH0 L Z'],
  "spiro": ['S P IH1 R OW2'],
  "spiro's": ['S P IH1 R OW2 Z'],
  "spiros": ['S P IH1 R OW2 Z'],
  "spisak": ['S P IH1 S AH0 K'],
  "spit": ['S P IH1 T'],
  "spitale": ['S P IY0 T AA1 L IY0'],
  "spitball": ['S P IH1 T B AO2 L'],
  "spitball's": ['S P IH1 T B AO2 L Z'],
  "spite": ['S P AY1 T'],
  "spiteful": ['S P AY1 T F AH0 L'],
  "spiteri": ['S P IY0 T EH1 R IY0'],
  "spitler": ['S P IH1 T L ER0'],
  "spits": ['S P IH1 T S'],
  "spitting": ['S P IH1 T IH0 NG'],
  "spittle": ['S P IH1 T AH0 L'],
  "spittler": ['S P IH1 T L ER0'],
  "spitz": ['S P IH1 T S'],
  "spitzer": ['S P IH1 T Z ER0'],
  "spitzley": ['S P IH1 T S L IY0'],
  "spitznagel": ['S P IH1 T S N EY2 G AH0 L'],
  "spiva": ['S P IY1 V AH0'],
  "spivack": ['S P IH1 V AH0 K'],
  "spivak": ['S P IH1 V AH0 K'],
  "spivey": ['S P IH1 V IY0'],
  "spiwak": ['S P IH1 V AH0 K'],
  "spizzirri": ['S P IY0 T S IH1 R IY0'],
  "splain": ['S P L EY1 N'],
  "splaine": ['S P L EY1 N'],
  "splash": ['S P L AE1 SH'],
  "splashed": ['S P L AE1 SH T'],
  "splashes": ['S P L AE1 SH IH0 Z'],
  "splashing": ['S P L AE1 SH IH0 NG'],
  "splashy": ['S P L AE1 SH IY0'],
  "splat": ['S P L AE1 T'],
  "splatter": ['S P L AE1 T ER0'],
  "splattered": ['S P L AE1 T ER0 D'],
  "splattering": ['S P L AE1 T AH0 R IH2 NG'],
  "splatters": ['S P L AE1 T ER0 Z'],
  "splawn": ['S P L AO1 N'],
  "spleen": ['S P L IY1 N'],
  "spleisson": ['S P EY1 S AH0 N'],
  "splendid": ['S P L EH1 N D IH0 D'],
  "splendidly": ['S P L EH1 N D AH0 D L IY0'],
  "splendor": ['S P L EH1 N D ER0'],
  "splenic": ['S P L EH1 N IH0 K', 'S P L IY1 N IH0 K'],
  "splice": ['S P L AY1 S'],
  "spliced": ['S P L AY1 S T'],
  "splices": ['S P L AY1 S IH0 Z'],
  "splichal": ['S P L IH1 CH AH0 L'],
  "splicing": ['S P L AY1 S IH0 NG'],
  "splint": ['S P L IH1 N T'],
  "splinter": ['S P L IH1 N T ER0'],
  "splintered": ['S P L IH1 N T ER0 D'],
  "splintering": ['S P L IH1 N T ER0 IH0 NG'],
  "splinters": ['S P L IH1 N T ER0 Z'],
  "splintery": ['S P L IH1 N T ER0 IY0'],
  "splints": ['S P L IH1 N T S'],
  "split": ['S P L IH1 T'],
  "splits": ['S P L IH1 T S'],
  "splitt": ['S P L IH1 T'],
  "splitter": ['S P L IH1 T ER0'],
  "splitting": ['S P L IH1 T IH0 NG'],
  "splurge": ['S P L ER1 JH'],
  "splurged": ['S P L ER1 JH D'],
  "splurges": ['S P L ER1 JH IH0 S'],
  "splurging": ['S P L ER1 JH IH0 NG'],
  "spoarer": ['S P AO1 R ER0'],
  "spock": ['S P AA1 K'],
  "spoden": ['S P OW1 D AH0 N'],
  "spoelstra": ['S P OW1 L S T R AA0'],
  "spoerl": ['S P AO1 R L'],
  "spofford": ['S P AA1 F ER0 D'],
  "spogli": ['S P AA1 G L IY0'],
  "spohn": ['S P AA1 N'],
  "spohr": ['S P AA1 R'],
  "spoil": ['S P OY1 L'],
  "spoilage": ['S P OY1 L AH0 JH', 'S P OY1 L IH0 JH'],
  "spoiled": ['S P OY1 L D'],
  "spoiler": ['S P OY1 L ER0'],
  "spoilers": ['S P OY1 L ER0 Z'],
  "spoiling": ['S P OY1 L IH0 NG'],
  "spoils": ['S P OY1 L Z'],
  "spokane": ['S P OW0 K AE1 N', 'S P OW0 K EY1 N'],
  "spoke": ['S P OW1 K'],
  "spoken": ['S P OW1 K AH0 N'],
  "spokenly": ['S P OW1 K AH0 N L IY0'],
  "spokes": ['S P OW1 K S'],
  "spokesman": ['S P OW1 K S M AH0 N'],
  "spokesmen": ['S P OW1 K S M IH0 N'],
  "spokespeople": ['S P OW1 K S P IY2 P AH0 L'],
  "spokesperson": ['S P OW1 K S P ER0 S AH0 N'],
  "spokespersons": ['S P OW1 K S P ER0 S AH0 N Z'],
  "spokeswoman": ['S P OW1 K S W UH2 M AH0 N'],
  "spokeswomen": ['S P OW1 K S W IH2 M AH0 N'],
  "spoleto": ['S P OW0 L EY1 T OW0'],
  "spoletto": ['S P OW0 L EY1 T OW0'],
  "spoletto's": ['S P OW0 L EY1 T OW0 Z'],
  "spomer": ['S P OW1 M ER0'],
  "sponaugle": ['S P AA1 N AO0 G AH0 L'],
  "spong": ['S P AO1 NG'],
  "sponge": ['S P AH1 N JH'],
  "spongebob": ['S P AH1 N JH B AA2 B'],
  "sponged": ['S P AH1 N JH D'],
  "spongeform": ['S P AH1 N JH F AO0 R M'],
  "sponges": ['S P AH1 N JH AH0 Z'],
  "spongiform": ['S P AH1 N JH IH0 F AO2 R M'],
  "spongy": ['S P AH1 N JH IY0'],
  "sponsel": ['S P AA1 N S AH0 L'],
  "sponseller": ['S P AA1 N S AH0 L ER0'],
  "sponsler": ['S P AA1 N S AH0 L ER0', 'S P AA1 N S L ER0'],
  "sponsor": ['S P AA1 N S ER0'],
  "sponsor's": ['S P AA1 N S ER0 Z'],
  "sponsored": ['S P AA1 N S ER0 D'],
  "sponsoring": ['S P AA1 N S ER0 IH0 NG'],
  "sponsors": ['S P AA1 N S ER0 Z'],
  "sponsorship": ['S P AA1 N S ER0 SH IH2 P'],
  "sponsorships": ['S P AA1 N S ER0 SH IH2 P S'],
  "spontaneity": ['S P AA2 N T AH0 N IY1 AH0 T IY0'],
  "spontaneous": ['S P AA0 N T EY1 N IY0 AH0 S'],
  "spontaneously": ['S P AA0 N T EY1 N IY0 AH0 S L IY0'],
  "spoof": ['S P UW1 F'],
  "spoofed": ['S P UW1 F T'],
  "spoofing": ['S P UW1 F IH0 NG'],
  "spoofs": ['S P UW1 F S'],
  "spook": ['S P UW1 K'],
  "spooked": ['S P UW1 K T'],
  "spooks": ['S P UW1 K S'],
  "spooky": ['S P UW1 K IY0'],
  "spool": ['S P UW1 L'],
  "spoon": ['S P UW1 N'],
  "spoonemore": ['S P UW1 N M AO0 R'],
  "spooner": ['S P UW1 N ER0'],
  "spoonful": ['S P UW1 N F UH2 L'],
  "spoonfuls": ['S P UW1 N F UH2 L Z'],
  "spoons": ['S P UW1 N Z'],
  "spoor": ['S P UH1 R'],
  "spoor's": ['S P UH1 R Z'],
  "sporadic": ['S P ER0 AE1 D IH0 K'],
  "sporadically": ['S P ER0 AE1 D IH0 K L IY0'],
  "sporck": ['S P AO1 R K'],
  "spore": ['S P AO1 R'],
  "sporer": ['S P AO1 R ER0'],
  "spores": ['S P AO1 R Z'],
  "sporkin": ['S P AO1 R K IH0 N'],
  "sporkin's": ['S P AO1 R K IH0 N Z'],
  "sporleder": ['S P AO1 R L IH0 D ER0'],
  "sporn": ['S P AO1 R N'],
  "sporophyte": ['S P AO1 R F AY2 T'],
  "sporophytes": ['S P AO1 R F AY2 T S'],
  "sporrer": ['S P AO1 ER0 R'],
  "sport": ['S P AO1 R T'],
  "sport's": ['S P AO1 R T S'],
  "sported": ['S P AO1 R T IH0 D'],
  "sportier": ['S P AO1 R T IY0 ER0'],
  "sporting": ['S P AO1 R T IH0 NG'],
  "sportingly": ['S P AO1 R T IH0 NG L IY0'],
  "sports": ['S P AO1 R T S'],
  "sports'": ['S P AO1 R T S'],
  "sportsbar": ['S P AO1 R T S B AA2 R'],
  "sportscaster": ['S P AO1 R T S K AE2 S T ER0'],
  "sportscasters": ['S P AO1 R T S K AE2 S T ER0 Z'],
  "sportschannel": ['S P AO1 R T S CH AE1 N AH0 L'],
  "sportsclub": ['S P AO1 R T S K L AH2 B'],
  "sportsman": ['S P AO1 R T S M AH0 N'],
  "sportsmanship": ['S P AO1 R T S M AH0 N SH IH2 P'],
  "sportsmen": ['S P AO1 R T S M IH0 N'],
  "sportster": ['S P AO1 R T S T ER0'],
  "sportswear": ['S P AO1 R T S W EH2 R'],
  "sportswriter": ['S P AO1 R T S R AY2 T ER0'],
  "sportswriters": ['S P AO1 R T S R AY2 T ER0 Z'],
  "sporty": ['S P AO1 R T IY0'],
  "sposato": ['S P OW0 S AA1 T OW0'],
  "sposito": ['S P OW0 S IY1 T OW0'],
  "spot": ['S P AA1 T'],
  "spotify": ['S P AO1 T AH0 F AY2'],
  "spotless": ['S P AA1 T L AH0 S'],
  "spotlight": ['S P AA1 T L AY2 T'],
  "spotlighted": ['S P AA1 T L AY2 T IH0 D'],
  "spotlighting": ['S P AA1 T L AY2 T IH0 NG'],
  "spotlights": ['S P AA1 T L AY2 T S'],
  "spoto": ['S P OW1 T OW0'],
  "spots": ['S P AA1 T S'],
  "spotted": ['S P AA1 T IH0 D'],
  "spotter": ['S P AA1 T ER0'],
  "spotters": ['S P AA1 T ER0 Z'],
  "spotting": ['S P AA1 T IH0 NG'],
  "spotts": ['S P AA1 T S'],
  "spotty": ['S P AA1 T IY0'],
  "spousal": ['S P AW1 Z AH0 L'],
  "spouse": ['S P AW1 S'],
  "spouse's": ['S P AW1 S IH0 Z'],
  "spouses": ['S P AW1 S IH0 Z'],
  "spout": ['S P AW1 T'],
  "spouted": ['S P AW1 T IH0 D'],
  "spouting": ['S P AW1 T IH0 NG'],
  "spouts": ['S P AW1 T S'],
  "spracklen": ['S P R AE1 K AH0 L AH0 N'],
  "spradley": ['S P R AE1 D L IY0'],
  "spradlin": ['S P R AE1 D L IH0 N'],
  "spradling": ['S P R AE1 D L IH0 NG'],
  "spragg": ['S P R AE1 G'],
  "spraggins": ['S P R AE1 G IH0 N Z'],
  "sprague": ['S P R EY1 G'],
  "sprague's": ['S P R EY1 G Z'],
  "sprain": ['S P R EY1 N'],
  "sprained": ['S P R EY1 N D'],
  "spraining": ['S P R EY1 N IH0 NG'],
  "sprains": ['S P R EY1 N Z'],
  "spraker": ['S P R EY1 K ER0'],
  "sprang": ['S P R AE1 NG'],
  "spranger": ['S P R AE1 NG ER0'],
  "sprankle": ['S P R AE1 NG K AH0 L'],
  "spratley": ['S P R AE1 T L IY0'],
  "spratlin": ['S P R AE1 T L IH0 N'],
  "spratling": ['S P R AE1 T L IH0 NG'],
  "spratt": ['S P R AE1 T'],
  "sprawl": ['S P R AO1 L'],
  "sprawled": ['S P R AO1 L D'],
  "sprawling": ['S P R AO1 L IH0 NG'],
  "sprawls": ['S P R AO1 L Z'],
  "spray": ['S P R EY1'],
  "sprayberry": ['S P R EY1 B EH2 R IY0'],
  "sprayed": ['S P R EY1 D'],
  "sprayer": ['S P R EY1 ER0'],
  "sprayers": ['S P R EY1 ER0 Z'],
  "spraying": ['S P R EY1 IH0 NG'],
  "sprays": ['S P R EY1 Z'],
  "spread": ['S P R EH1 D'],
  "spreader": ['S P R EH1 D ER0'],
  "spreading": ['S P R EH1 D IH0 NG'],
  "spreads": ['S P R EH1 D Z'],
  "spreadsheet": ['S P R EH1 D SH IY2 T'],
  "spreadsheets": ['S P R EH1 D SH IY2 T S'],
  "sprecher": ['S P R EH1 K ER0'],
  "spreckels": ['S P R EH1 K AH0 L Z'],
  "spree": ['S P R IY1'],
  "spreeman": ['S P R IY1 M AH0 N'],
  "spreen": ['S P R IY1 N'],
  "sprees": ['S P R IY1 Z'],
  "sprehe": ['S P R IY1 HH'],
  "spreitzer": ['S P R AY1 T Z ER0'],
  "spreng": ['S P R EH1 NG'],
  "sprenger": ['S P R EH1 N JH ER0'],
  "sprenkle": ['S P R EH1 NG K AH0 L'],
  "sprick": ['S P R IH1 K'],
  "sprigg": ['S P R IH1 G'],
  "sprigged": ['S P R IH1 G D'],
  "spriggs": ['S P R IH1 G Z'],
  "sprightly": ['S P R AY1 T L IY0'],
  "spring": ['S P R IH1 NG', 'S P ER0 IH1 NG'],
  "spring's": ['S P R IH1 NG Z'],
  "springboard": ['S P R IH1 NG B AO2 R D'],
  "springborn": ['S P R IH1 NG G B ER0 N'],
  "springdale": ['S P R IH1 NG D EY2 L'],
  "springer": ['S P R IH1 NG ER0'],
  "springerville": ['S P R IH1 NG ER0 V IH2 L'],
  "springfield": ['S P R IH1 NG F IY2 L D'],
  "springfield's": ['S P R IH1 NG F IY2 L D Z'],
  "springing": ['S P R IH1 NG IH0 NG'],
  "springman": ['S P R IH1 NG M AH0 N'],
  "springs": ['S P R IH1 NG Z', 'S P ER0 IH1 NG Z'],
  "springstead": ['S P R IH1 NG S T EH2 D'],
  "springsteen": ['S P R IH1 NG S T IY2 N'],
  "springsteen's": ['S P R IH1 NG S T IY2 N Z'],
  "springston": ['S P R IH1 NG S T AH0 N'],
  "springtime": ['S P R IH1 NG T AY2 M'],
  "sprinkel": ['S P R IH1 NG K AH0 L'],
  "sprinkle": ['S P R IH1 NG K AH0 L'],
  "sprinkled": ['S P R IH1 NG K AH0 L D'],
  "sprinkler": ['S P R IH1 NG K L ER0', 'S P R IH1 NG K AH0 L ER0'],
  "sprinklers": ['S P R IH1 NG K L ER0 Z', 'S P R IH1 NG K AH0 L ER0 Z'],
  "sprinkles": ['S P R IH1 NG K AH0 L Z'],
  "sprinkling": ['S P R IH1 NG K L IH0 NG', 'S P R IH1 NG K AH0 L IH0 NG'],
  "sprint": ['S P R IH1 N T'],
  "sprint's": ['S P R IH1 N T S'],
  "sprinted": ['S P R IH1 N T IH0 D'],
  "sprinter": ['S P R IH1 N T ER0'],
  "sprinters": ['S P R IH1 N T ER0 Z'],
  "sprinting": ['S P R IH1 N T IH0 NG'],
  "sprints": ['S P R IH1 N T S'],
  "sprite": ['S P R AY1 T'],
  "sprizzo": ['S P R IH1 Z OW0'],
  "sproat": ['S P R OW1 T'],
  "sprock": ['S P R AA1 K'],
  "sprocket": ['S P R AA1 K AH0 T'],
  "sprogus": ['S P R OW1 G AH0 S'],
  "sprogus's": ['S P R OW1 G AH0 S IH0 Z'],
  "sproles": ['S P R OW1 L Z'],
  "sprong": ['S P R AO1 NG'],
  "sprott": ['S P R AA1 T'],
  "sproul": ['S P R AW1 L'],
  "sproule": ['S P R UW1 L'],
  "sproull": ['S P R UW1 L'],
  "sprouls": ['S P R AW1 L Z'],
  "sprouse": ['S P R AW1 S'],
  "sprout": ['S P R AW1 T'],
  "sproutaphile": ['S P R AW1 T AH0 F AY0 L'],
  "sproutaphiles": ['S P R AW1 T AH0 F AY0 L Z'],
  "sprouted": ['S P R AW1 T IH0 D'],
  "sprouting": ['S P R AW1 T IH0 NG'],
  "sprouts": ['S P R AW1 T S'],
  "sprow": ['S P R AW1'],
  "sprowl": ['S P R AW1 L'],
  "sprowle": ['S P R AW1 AH0 L'],
  "sprowls": ['S P R AW1 L Z'],
  "spruce": ['S P R UW1 S'],
  "spruced": ['S P R UW1 S T'],
  "sprucing": ['S P R UW1 S IH0 NG'],
  "spruell": ['S P R UW1 L'],
  "spruiell": ['S P R UW1 L'],
  "spruill": ['S P R UW1 L'],
  "sprung": ['S P R AH1 NG'],
  "sprunger": ['S P R AH1 NG ER0'],
  "spry": ['S P R AY1'],
  "spud": ['S P AH1 D'],
  "spud's": ['S P AH1 D Z'],
  "spuds": ['S P AH1 D Z'],
  "spuhler": ['S P UW1 L ER0'],
  "spumoni": ['S P UW0 M OW1 N IY0'],
  "spun": ['S P AH1 N'],
  "spunk": ['S P AH1 NG K'],
  "spunky": ['S P AH1 NG K IY0'],
  "spur": ['S P ER1'],
  "spurdle": ['S P ER1 D AH0 L'],
  "spurge": ['S P ER1 JH'],
  "spurgeon": ['S P ER1 JH IH0 N'],
  "spurgin": ['S P ER1 JH IH0 N'],
  "spurious": ['S P Y UH1 R IY0 AH0 S', 'S P ER1 IY0 AH0 S'],
  "spurlin": ['S P ER1 L IH0 N'],
  "spurling": ['S P ER1 L IH0 NG'],
  "spurlock": ['S P ER1 L AA2 K'],
  "spurn": ['S P ER1 N'],
  "spurned": ['S P ER1 N D'],
  "spurning": ['S P ER1 N IH0 NG'],
  "spurns": ['S P ER1 N Z'],
  "spurr": ['S P ER1'],
  "spurred": ['S P ER1 D'],
  "spurrier": ['S P ER1 IY0 ER0'],
  "spurring": ['S P ER1 IH0 NG'],
  "spurs": ['S P ER1 Z'],
  "spurt": ['S P ER1 T'],
  "spurted": ['S P ER1 T IH0 D'],
  "spurting": ['S P ER1 T IH0 NG'],
  "spurts": ['S P ER1 T S'],
  "sputnik": ['S P AH1 T N IH0 K'],
  "sputniks": ['S P AH1 T N IH0 K S'],
  "sputter": ['S P AH1 T ER0'],
  "sputtered": ['S P AH1 T ER0 D'],
  "sputtering": ['S P AH1 T ER0 IH0 NG'],
  "sputters": ['S P AH1 T ER0 Z'],
  "sputum": ['S P Y UW1 T AH0 M'],
  "spy": ['S P AY1'],
  "spy's": ['S P AY1 Z'],
  "spycatcher": ['S P AY1 K AE2 CH ER0'],
  "spychalski": ['S P IH0 HH AA1 L S K IY0'],
  "spyglass": ['S P AY1 G L AE2 S'],
  "spying": ['S P AY1 IH0 NG'],
  "spyker": ['S P AY1 K ER0'],
  "spyware": ['S P AY1 W EH2 R'],
  "sql": ['EH2 S K Y UW2 EH1 L', 'S IY1 K W UH0 L'],
  "squabble": ['S K W AA1 B AH0 L'],
  "squabbled": ['S K W AA1 B AH0 L D'],
  "squabbles": ['S K W AA1 B AH0 L Z'],
  "squabbling": ['S K W AA1 B AH0 L IH0 NG', 'S K W AA1 B L IH0 NG'],
  "squad": ['S K W AA1 D'],
  "squad's": ['S K W AA1 D Z'],
  "squadron": ['S K W AA1 D R AH0 N'],
  "squadrons": ['S K W AA1 D R AH0 N Z'],
  "squads": ['S K W AA1 D Z'],
  "squalid": ['S K W AA1 L AH0 D'],
  "squall": ['S K W AO1 L'],
  "squalls": ['S K W AO1 L Z'],
  "squalor": ['S K W AA1 L ER0'],
  "squamous": ['S K W EY1 M AH0 S'],
  "squander": ['S K W AA1 N D ER0'],
  "squandered": ['S K W AA1 N D ER0 D'],
  "squandering": ['S K W AA1 N D ER0 IH0 NG'],
  "squanders": ['S K W AA1 N D ER0 Z'],
  "square": ['S K W EH1 R'],
  "squared": ['S K W EH1 R D'],
  "squarely": ['S K W EH1 R L IY0'],
  "squares": ['S K W EH1 R Z'],
  "squaring": ['S K W EH1 R IH0 NG'],
  "squash": ['S K W AA1 SH'],
  "squashed": ['S K W AA1 SH T'],
  "squashing": ['S K W AA1 SH IH0 NG'],
  "squashy": ['S K W AA1 SH IY0'],
  "squat": ['S K W AA1 T'],
  "squats": ['S K W AA1 T S'],
  "squatter": ['S K W AA1 T ER0'],
  "squatters": ['S K W AA1 T ER0 Z'],
  "squatting": ['S K W AA1 T IH0 NG'],
  "squatty": ['S K W AA1 T IY0'],
  "squawk": ['S K W AO1 K'],
  "squawking": ['S K W AO1 K IH0 NG'],
  "squawks": ['S K W AO1 K S'],
  "squeak": ['S K W IY1 K'],
  "squeaked": ['S K W IY1 K T'],
  "squeaker": ['S K W IY1 K ER0'],
  "squeakers": ['S K W IY1 K ER0 Z'],
  "squeaking": ['S K W IY1 K IH0 NG'],
  "squeaks": ['S K W IY1 K S'],
  "squeaky": ['S K W IY1 K IY0'],
  "squeal": ['S K W IY1 L'],
  "squealing": ['S K W IY1 L IH0 NG'],
  "squeals": ['S K W IY1 L Z'],
  "squeamish": ['S K W IY1 M IH0 SH'],
  "squeamishness": ['S K W IY1 M IH0 SH N AH0 S'],
  "squeegee": ['S K W IY1 JH IY0'],
  "squeeze": ['S K W IY1 Z'],
  "squeezed": ['S K W IY1 Z D'],
  "squeezes": ['S K W IY1 Z IH0 Z'],
  "squeezing": ['S K W IY1 Z IH0 NG'],
  "squelch": ['S K W EH1 L CH'],
  "squelched": ['S K W EH1 L CH T'],
  "squelching": ['S K W EH1 L CH IH0 NG'],
  "squibb": ['S K W IH1 B'],
  "squibb's": ['S K W IH1 B Z'],
  "squid": ['S K W IH1 D'],
  "squier": ['S K W AY1 ER0'],
  "squiers": ['S K W AY1 ER0 Z'],
  "squiggle": ['S K W IH1 G AH0 L'],
  "squiggles": ['S K W IH1 G AH0 L Z'],
  "squillace": ['S K W IH1 L AH0 S'],
  "squillacote": ['S K W IH1 L AH0 K OW0 T'],
  "squillacote's": ['S K W IH1 L AH0 K OW0 T S'],
  "squillante": ['S K W IY0 L AA1 N T EY0'],
  "squint": ['S K W IH1 N T'],
  "squinting": ['S K W IH1 N T IH0 NG'],
  "squire": ['S K W AY1 R'],
  "squires": ['S K W AY1 ER0 Z'],
  "squirm": ['S K W ER1 M'],
  "squirming": ['S K W ER1 M IH0 NG'],
  "squirms": ['S K W ER1 M Z'],
  "squirrel": ['S K W ER1 AH0 L'],
  "squirreled": ['S K W ER1 AH0 L D'],
  "squirrels": ['S K W ER1 AH0 L Z'],
  "squirt": ['S K W ER1 T'],
  "squirted": ['S K W ER1 T IH0 D'],
  "squirting": ['S K W ER1 T IH0 NG'],
  "squirts": ['S K W ER1 T S'],
  "squishy": ['S K W IH1 SH IY0'],
  "squitieri": ['S K W IY0 T IH1 R IY0'],
  "squyres": ['S K W AY1 R Z'],
  "sr": ['S IY1 N Y ER0', 'S IH1 S T ER0'],
  "srader": ['SH R EY1 D ER0'],
  "sram": ['SH R AE1 M', 'EH1 S R AE1 M'],
  "sramek": ['SH R AE1 M IH0 K'],
  "srdan": ['S ER1 D AE2 N'],
  "srebrenica": ['S R EY2 B R EH0 N IY1 T S AH0', 'SH R EY2 B R AH0 N IY1 T S AH0'],
  "srebrenica's": ['S R EY2 B R EH0 N IY1 T S AH0 Z', 'SH R EY2 B R AH0 N IY1 T S AH0 Z'],
  "sremac": ['S R IY1 M AE2 K'],
  "sri": ['SH R IY1', 'EH1 S AA1 R AY1', 'S R IY1'],
  "sri-lanka": ['S R IY1 L AE1 NG K AH0', 'SH R IY1 L AE1 NG K AH0'],
  "srinagar": ['S R IY1 N AH0 G AA2 R', 'SH R IY1 N AH0 G AA2 R'],
  "srinagarb": ['S R IY1 N AH0 G AA2 R B', 'SH R IY1 N AH0 G AA2 R B'],
  "srinivas": ['S R IY1 N IH0 V AA2 S'],
  "srinivasan": ['S R IY0 N IY2 V AA0 S AA1 N', 'SH R IY0 N IY2 V AA0 S AA1 N'],
  "sriram": ['S R IY1 R AA2 M', 'SH R IY1 R AA2 M'],
  "srivastava": ['S R IY0 V AA0 S T AA1 V AH0', 'SH R IY0 V AA0 S T AA1 V AH0'],
  "srock": ['SH R AA1 K'],
  "sroge": ['SH R OW1 G'],
  "sroka": ['SH R OW1 K AH0'],
  "sroufe": ['SH R OW1 F'],
  "srpska": ['S R AH1 P S K AH0'],
  "ss": ['EH1 S EH1 S'],
  "ssangyong": ['S AE1 NG Y AO2 NG'],
  "ssn": ['EH1 S EH1 S EH1 N'],
  "st": ['S T R IY1 T', 'S EY1 N T'],
  "st-charles": ['S EY1 N T CH AA1 R AH0 L Z'],
  "st-clair": ['S EY1 N T K L EH1 R'],
  "st-claire": ['S EY1 N T K L EH1 R'],
  "st-cyr": ['S EY1 N T K IH1 R', 'S EY1 N T S IH1 R'],
  "st-denis": ['S EY1 N T D EH1 N IH0 S'],
  "st-dennis": ['S EY1 N T D EH1 N IH0 S'],
  "st-george": ['S EY1 N T JH AO1 R JH'],
  "st-germain": ['S EY1 N T JH ER2 M EY1 N'],
  "st-germaine": ['S EY1 N T JH ER2 M EY1 N', 'S AA1 N ZH ER2 M EY1 N'],
  "st-hilaire": ['S EY1 N T HH IH0 L EY1 R'],
  "st-jacques": ['S EY1 N T JH AA1 K S', 'S AA1 N ZH AA1 K S'],
  "st-james": ['S EY1 N T JH EY1 M Z'],
  "st-jean": ['S EY1 N T JH IY1 N'],
  "st-john": ['S EY1 N T JH AA1 N'],
  "st-johns": ['S EY1 N T JH AA1 N Z'],
  "st-julien": ['S EY1 N T JH UW1 L IY0 AH0 N'],
  "st-laurent": ['S EY1 N T L AO1 R AH0 N T', 'S AA1 N L AO2 R AO1 N T'],
  "st-lawrence": ['S EY1 N T L AO1 R AH0 N S'],
  "st-louis": ['S EY1 N T L UW1 AH0 S', 'S EY1 N T L UW1 IY0'],
  "st-lucia": ['S EY1 N T L UW1 SH AH0', 'S EY1 N T L UW2 S IY1 AH0'],
  "st-marie": ['S EY1 N T M ER0 IY1'],
  "st-martin": ['S EY1 N T M AA1 R T IH0 N'],
  "st-mary": ['S EY1 N T M EH1 R IY0'],
  "st-peter": ['S EY1 N T P IY1 T ER0'],
  "st-petersburg": ['S EY1 N T P IY1 T ER0 Z B ER0 G'],
  "st-pierre": ['S EY1 N T P Y EH1 R'],
  "st-thomas": ['S EY1 N T AA1 M AH0 S'],
  "sta": ['S T AH1', 'EH1 S T IY1 EY1'],
  "staab": ['S T AA1 B'],
  "staack": ['S T AA1 K'],
  "staal": ['S T AA1 L'],
  "staar": ['S T AA1 R'],
  "staar's": ['S T AA1 R Z'],
  "staas": ['S T AA1 Z'],
  "staat": ['S T AA1 T'],
  "staats": ['S T AA1 T S'],
  "stab": ['S T AE1 B'],
  "stabbed": ['S T AE1 B D'],
  "stabbing": ['S T AE1 B IH0 NG'],
  "stabbings": ['S T AE1 B IH0 NG Z'],
  "stabenow": ['S T AE1 B IH0 N OW0'],
  "stabile": ['S T EY1 B IH0 L'],
  "stability": ['S T AH0 B IH1 L IH0 T IY0'],
  "stabilization": ['S T EY2 B AH0 L AH0 Z EY1 SH AH0 N'],
  "stabilize": ['S T EY1 B AH0 L AY2 Z'],
  "stabilized": ['S T EY1 B AH0 L AY2 Z D'],
  "stabilizer": ['S T EY1 B AH0 L AY2 Z ER0'],
  "stabilizers": ['S T EY1 B AH0 L AY2 Z ER0 Z'],
  "stabilizes": ['S T EY1 B AH0 L AY2 Z AH0 Z', 'S T EY1 B AH0 L AY2 Z IH0 Z'],
  "stabilizing": ['S T EY1 B AH0 L AY2 Z IH0 NG'],
  "stable": ['S T EY1 B AH0 L'],
  "stabler": ['S T EY1 B AH0 L ER0', 'S T EY1 B L ER0'],
  "stables": ['S T EY1 B AH0 L Z'],
  "stablest": ['S T EY1 B AH0 L AH0 S T'],
  "stabley": ['S T AE1 B L IY0'],
  "stabs": ['S T AE1 B Z'],
  "stac": ['S T AE1 K'],
  "stac's": ['S T AE1 K S'],
  "staccato": ['S T AH0 K AA1 T OW2'],
  "stacey": ['S T EY1 S IY0'],
  "stach": ['S T AE1 CH'],
  "stachnik": ['S T AE1 K N IH0 K'],
  "stachowiak": ['S T AH0 CH AW1 IY0 AE0 K'],
  "stachowicz": ['S T AA1 CH AH0 V IH0 CH'],
  "stachowski": ['S T AH0 CH AO1 F S K IY0'],
  "stachura": ['S T AA0 CH UH1 R AH0'],
  "stachurski": ['S T AH0 CH ER1 S K IY0'],
  "stacia": ['S T AA1 CH AH0'],
  "stacie": ['S T EY1 S IY0'],
  "stack": ['S T AE1 K'],
  "stacked": ['S T AE1 K T'],
  "stacker": ['S T AE1 K ER0'],
  "stackhouse": ['S T AE1 K HH AW2 S'],
  "stacking": ['S T AE1 K IH0 NG'],
  "stackpole": ['S T AE1 K P OW2 L'],
  "stacks": ['S T AE1 K S'],
  "stacy": ['S T EY1 S IY0'],
  "stade": ['S T EY1 D'],
  "stadel": ['S T AE1 D AH0 L'],
  "stadelman": ['S T AE1 D AH0 L M AH0 N'],
  "stader": ['S T EY1 D ER0'],
  "stadia": ['S T EY1 D IY0 AA0'],
  "stadium": ['S T EY1 D IY0 AH0 M'],
  "stadium's": ['S T EY1 D IY0 AH0 M Z'],
  "stadiums": ['S T EY1 D IY0 AH0 M Z'],
  "stadler": ['S T AE1 D L ER0'],
  "stadnik": ['S T AE1 D N IH0 K'],
  "stadt": ['S T AE1 D T'],
  "stadtlander": ['SH T AE1 T L AE2 N D ER0'],
  "stadtmiller": ['SH T AE1 T M IH1 L ER0'],
  "staebell": ['S T EH1 B EH0 L'],
  "staebler": ['S T EY1 L ER0'],
  "staehle": ['S T EY1 L'],
  "staff": ['S T AE1 F'],
  "staff's": ['S T AE1 F S'],
  "staffa": ['S T AA1 F AA2'],
  "staffed": ['S T AE1 F T'],
  "staffer": ['S T AE1 F ER0'],
  "staffers": ['S T AE1 F ER0 Z'],
  "staffieri": ['S T AA0 F IH1 R IY0'],
  "staffing": ['S T AE1 F IH0 NG'],
  "stafford": ['S T AE1 F ER0 D'],
  "stafford's": ['S T AE1 F ER0 D Z'],
  "staffordshire": ['S T AE1 F ER0 D SH ER0', 'S T AE1 F ER0 D SH AY2 ER0'],
  "staffordshire's": ['S T AE1 F ER0 D SH ER0 Z', 'S T AE1 F ER0 D SH AY2 ER0 Z'],
  "staffs": ['S T AE1 F S'],
  "stag": ['S T AE1 G'],
  "stag-party": ['S T AE1 G P AE2 R T IY0'],
  "stage": ['S T EY1 JH'],
  "stagecoach": ['S T EY1 JH K OW2 CH'],
  "stagecraft": ['S T EY1 JH K R AE2 F T'],
  "staged": ['S T EY1 JH D'],
  "stagehand": ['S T EY1 JH HH AE2 N D'],
  "stagehands": ['S T EY1 JH HH AE2 N D Z'],
  "stager": ['S T EY1 JH ER0'],
  "stages": ['S T EY1 JH AH0 Z', 'S T EY1 JH IH0 Z'],
  "stagflate": ['S T AE2 G F L EY1 T'],
  "stagflation": ['S T AE0 G F L EY1 SH AH0 N'],
  "stagg": ['S T AE1 G'],
  "stagger": ['S T AE1 G ER0'],
  "staggered": ['S T AE1 G ER0 D'],
  "staggering": ['S T AE1 G ER0 IH2 NG'],
  "staggeringly": ['S T AE1 G ER0 IH2 NG L IY2'],
  "staggers": ['S T AE1 G ER0 Z'],
  "staggs": ['S T AE1 G Z'],
  "staging": ['S T EY1 JH IH0 NG'],
  "stagliano": ['S T AA0 G L IY0 AA1 N OW2'],
  "stagnant": ['S T AE1 G N AH0 N T'],
  "stagnaro": ['S T AA0 G N AA1 R OW2'],
  "stagnate": ['S T AE1 G N EY2 T'],
  "stagnated": ['S T AE1 G N EY2 T IH0 D'],
  "stagnating": ['S T AE1 G N EY2 T IH0 NG'],
  "stagnation": ['S T AE0 G N EY1 SH AH0 N'],
  "stagner": ['S T AE1 G N ER0'],
  "stahl": ['S T AA1 L'],
  "stahlberg": ['S T AA1 L B ER0 G'],
  "stahle": ['S T EY1 HH AH0 L'],
  "stahlecker": ['S T AA1 L IH0 K ER0'],
  "stahler": ['S T AA1 L ER0'],
  "stahley": ['S T AE1 L IY0'],
  "stahlhut": ['S T AA1 L HH AH0 T'],
  "stahlman": ['S T AA1 L M AH0 N'],
  "stahly": ['S T AE1 L IY0'],
  "stahmer": ['S T AA1 M ER0'],
  "stahnke": ['S T AE1 NG K'],
  "stahr": ['S T AE1 R'],
  "stai": ['S T AA1 IY0'],
  "staib": ['S T EY1 B'],
  "staid": ['S T EY1 D'],
  "staiger": ['S T AY1 G ER0'],
  "stailey": ['S T EY1 L IY0'],
  "stain": ['S T EY1 N'],
  "stainback": ['S T EY1 N B AE2 K'],
  "stainbrook": ['S T EY1 N B R UH2 K'],
  "stained": ['S T EY1 N D'],
  "staines": ['S T EY1 N Z'],
  "staining": ['S T EY1 N IH0 NG'],
  "stainless": ['S T EY1 N L AH0 S'],
  "stainmaster": ['S T EY1 N M AE2 S T ER0'],
  "stains": ['S T EY1 N Z'],
  "stair": ['S T EH1 R'],
  "staircase": ['S T EH1 R K EY2 S'],
  "staircases": ['S T EH1 R K EY2 S IH0 Z'],
  "stairs": ['S T EH1 R Z'],
  "stairway": ['S T EH1 R W EY2'],
  "stairways": ['S T EH1 R W EY2 Z'],
  "stairwell": ['S T EH1 R W EH2 L'],
  "stairwells": ['S T EH1 R W EH2 L Z'],
  "stake": ['S T EY1 K'],
  "stake's": ['S T EY1 K S'],
  "stake-out": ['S T EY1 K AW1 T'],
  "staked": ['S T EY1 K T'],
  "stakeholder": ['S T EY1 K HH OW2 L D ER0'],
  "stakeholders": ['S T EY1 K HH OW2 L D ER0 Z'],
  "stakeout": ['S T EY1 K AW2 T'],
  "stakeouts": ['S T EY1 K AW2 T S'],
  "staker": ['S T EY1 K ER0'],
  "stakes": ['S T EY1 K S'],
  "staking": ['S T EY1 K IH0 NG'],
  "stalag": ['S T AE1 L AE2 G'],
  "stalagmite": ['S T AE1 L AH0 G M AY2 T'],
  "stalcup": ['S T AO1 L K AH2 P'],
  "stalder": ['S T AO1 L D ER0'],
  "stale": ['S T EY1 L'],
  "stalemate": ['S T EY1 L M EY2 T'],
  "stalemated": ['S T EY1 L M EY2 T IH0 D'],
  "staley": ['S T EY1 L IY0'],
  "staley's": ['S T EY1 L IY0 Z'],
  "stalin": ['S T AA1 L AH0 N'],
  "stalin's": ['S T AA1 L IH0 N Z'],
  "stalingrad": ['S T AE1 L IH0 N G R AE2 D'],
  "stalinism": ['S T AE1 L IH0 N IH2 Z AH0 M'],
  "stalinist": ['S T AA1 L IH0 N IH0 S T'],
  "stalinistic": ['S T AA2 L IH0 N IH1 S T IH0 K'],
  "stalinists": ['S T AE1 L IH0 N IH0 S T S'],
  "stalinization": ['S T AE2 L IH0 N IH0 Z EY1 SH AH0 N'],
  "stalinize": ['S T AE1 L IH0 N AY2 Z'],
  "stalk": ['S T AO1 K'],
  "stalked": ['S T AO1 K T'],
  "stalker": ['S T AO1 K ER0'],
  "stalkers": ['S T AO1 K ER0 Z'],
  "stalking": ['S T AO1 K IH0 NG'],
  "stalks": ['S T AO1 K S'],
  "stalky": ['S T AO1 K IY0'],
  "stall": ['S T AO1 L'],
  "stallard": ['S T AE1 L ER0 D'],
  "stallcup": ['S T AO1 L K AH2 P'],
  "stalled": ['S T AO1 L D'],
  "staller": ['S T AO1 L ER0'],
  "stallibrass": ['S T AE1 L IH0 B R AE0 S'],
  "stalling": ['S T AO1 L IH0 NG'],
  "stallings": ['S T AO1 L IH0 NG Z'],
  "stallion": ['S T AE1 L Y AH0 N'],
  "stallions": ['S T AE1 L Y AH0 N Z'],
  "stallkamp": ['S T AO1 L K AE2 M P'],
  "stallman": ['S T AO1 L M AH0 N'],
  "stallone": ['S T AH0 L OW1 N'],
  "stallone's": ['S T AH0 L OW1 N Z'],
  "stalls": ['S T AO1 L Z'],
  "stallsmith": ['S T AO1 L S M IH2 TH'],
  "stallworth": ['S T AO1 L W ER2 TH'],
  "stalnaker": ['S T AE1 L N AH0 K ER0'],
  "staloff": ['S T AE1 L AO0 F'],
  "stalon": ['S T EY1 L AH0 N'],
  "stalowa": ['S T AH0 L OW1 AH0'],
  "stalter": ['S T AO1 L T ER0'],
  "stalvey": ['S T AE1 L V IY0'],
  "stalwart": ['S T AO1 L W ER0 T'],
  "stalwarts": ['S T AO1 L W ER0 T S'],
  "stalzer": ['S T EY1 L Z ER0'],
  "stam": ['S T AE1 M'],
  "stamand": ['S T AE1 M AH0 N D'],
  "stamant": ['S T AE1 M AH0 N T'],
  "stamas": ['S T AA1 M AH0 Z'],
  "stamaty": ['S T AE1 M AH0 T IY0'],
  "stambaugh": ['S T AE1 M B AO0'],
  "stamberg": ['S T AE1 M B ER0 G'],
  "stamenson": ['S T EY1 M AH0 N S AH0 N'],
  "stamer": ['S T EY1 M ER0'],
  "stamey": ['S T EY1 M IY0'],
  "stamford": ['S T AE1 M F ER0 D'],
  "stamina": ['S T AE1 M AH0 N AH0'],
  "stamler": ['S T AE1 M L ER0'],
  "stamm": ['S T AE1 M'],
  "stammen": ['S T AE1 M AH0 N'],
  "stammer": ['S T AE1 M ER0'],
  "stamos": ['S T EY1 M OW0 Z'],
  "stamour": ['S T AH0 M UH1 R'],
  "stamp": ['S T AE1 M P'],
  "stamped": ['S T AE1 M P T'],
  "stampede": ['S T AE0 M P IY1 D'],
  "stampeded": ['S T AE0 M P IY1 D IH0 D'],
  "stampeding": ['S T AE0 M P IY1 D IH0 NG'],
  "stamper": ['S T AE1 M P ER0'],
  "stampfli": ['S T AE1 M P F L IY0'],
  "stamping": ['S T AE1 M P IH0 NG'],
  "stampings": ['S T AE1 M P IH0 NG Z'],
  "stampley": ['S T AE1 M P L IY0'],
  "stamps": ['S T AE1 M P S'],
  "stan": ['S T AE1 N'],
  "stan's": ['S T AE1 N Z'],
  "stanadyne": ['S T AE1 N AH0 D AY2 N'],
  "stanaland": ['S T AE1 N AH0 L AH0 N D'],
  "stanard": ['S T AE1 N ER0 D'],
  "stanaway": ['S T AE1 N AH0 W EY0'],
  "stanback": ['S T AE1 N B AE2 K'],
  "stanberry": ['S T AE1 N B EH2 R IY0'],
  "stanbery": ['S T AE1 N B ER0 IY0'],
  "stanbic": ['S T AE1 N B IH0 K'],
  "stanbrough": ['S T AE1 N B R AW0'],
  "stanbury": ['S T AE1 N B EH2 R IY0'],
  "stance": ['S T AE1 N S'],
  "stances": ['S T AE1 N S IH0 Z'],
  "stanch": ['S T AE1 N CH'],
  "stanched": ['S T AE1 N CH T'],
  "stanchfield": ['S T AE1 N CH F IY0 L D'],
  "stanching": ['S T AE1 N CH IH0 NG'],
  "stancik": ['S T AE1 N S IH0 K'],
  "stancil": ['S T AE1 N S IH0 L'],
  "stancill": ['S T AE1 N S IH0 L'],
  "stancliff": ['S T AE1 N K L IH0 F'],
  "stanco": ['S T AE1 NG K OW0'],
  "stanczak": ['S T AE1 N CH AE0 K'],
  "stanczyk": ['S T AE1 N CH IH0 K'],
  "stand": ['S T AE1 N D'],
  "stand-by": ['S T AE1 N D B AY2'],
  "stand-up": ['S T AE1 N D AH2 P'],
  "standa": ['S T AE1 N D AH0'],
  "standalone": ['S T AE1 N D AH0 L OW2 N'],
  "standard": ['S T AE1 N D ER0 D'],
  "standard's": ['S T AE1 N D ER0 D Z'],
  "standard-bearer": ['S T AE1 N D ER0 D B EH1 R ER0'],
  "standard-bearers": ['S T AE1 N D ER0 D B EH1 R ER0 Z'],
  "standardization": ['S T AE0 N D ER0 D IH0 Z EY1 SH AH0 N'],
  "standardize": ['S T AE1 N D ER0 D AY2 Z'],
  "standardized": ['S T AE1 N D ER0 D AY2 Z D'],
  "standardizing": ['S T AE1 N D ER0 D AY2 Z IH0 NG'],
  "standards": ['S T AE1 N D ER0 D Z'],
  "standby": ['S T AE1 N D B AY1'],
  "standbys": ['S T AE1 N D B AY2 Z'],
  "standefer": ['S T AE1 N D IH0 F ER0'],
  "standen": ['S T AE1 N D AH0 N'],
  "stander": ['S T AE1 N D ER0'],
  "standerfer": ['S T AE1 N D ER0 F ER0'],
  "standerford": ['S T AE1 N D ER0 F ER0 D'],
  "standex": ['S T AE1 N D AH0 K S'],
  "standfield": ['S T AE1 N D F IY2 L D'],
  "standifer": ['S T AE1 N D IH0 F ER0'],
  "standiford": ['S T AE1 N D IH0 F ER0 D'],
  "standin'": ['S T AE1 N D IH0 N'],
  "standing": ['S T AE1 N D IH0 NG'],
  "standings": ['S T AE1 N D IH0 NG Z'],
  "standish": ['S T AE1 N D IH0 SH'],
  "standlee": ['S T AE1 N D L IY2'],
  "standley": ['S T AE1 N D L IY0'],
  "standoff": ['S T AE1 N D AO2 F'],
  "standoffs": ['S T AE1 N D AO2 F S'],
  "standout": ['S T AE1 N D AW2 T'],
  "standouts": ['S T AE1 N D AW2 T S'],
  "standpoint": ['S T AE1 N D P OY2 N T'],
  "standpoints": ['S T AE1 N D P OY2 N T S'],
  "standre": ['S T AE1 N D ER0'],
  "standridge": ['S T AE1 N D R IH0 JH'],
  "stands": ['S T AE1 N D Z'],
  "standstill": ['S T AE1 N D S T IH2 L'],
  "standup": ['S T AE1 N D AH2 P'],
  "stanek": ['S T AE1 N IH0 K'],
  "stanfield": ['S T AE1 N F IY2 L D'],
  "stanfill": ['S T AE1 N F AH0 L'],
  "stanford": ['S T AE1 N F ER0 D'],
  "stanford's": ['S T AE1 N F ER0 D Z'],
  "stanforth": ['S T AE1 N F ER0 TH'],
  "stang": ['S T AE1 NG'],
  "stanga": ['S T AA1 NG G AH0'],
  "stange": ['S T AE1 N JH'],
  "stangel": ['S T EY1 NG G AH0 L'],
  "stangeland": ['S T EY1 NG G IH0 L AH0 N D', 'S T EY1 NG G L AH0 N D'],
  "stangelo": ['S T AA0 NG G EH1 L OW0'],
  "stanger": ['S T AE1 NG ER0'],
  "stangl": ['S T AE1 NG G AH0 L'],
  "stangland": ['S T AE1 NG G L AH0 N D'],
  "stangle": ['S T AE1 NG G AH0 L'],
  "stangler": ['S T AE1 NG G AH0 L ER0', 'S T AE1 NG G L ER0'],
  "stango": ['S T AA1 NG G OW0'],
  "stanhope": ['S T AE1 N AH0 P'],
  "staniar": ['S T EY1 N IY0 AA0 R'],
  "stanich": ['S T AE1 N IH0 CH'],
  "stanifer": ['S T AE1 N IH0 F ER0'],
  "stanis": ['S T AE1 N IH0 S'],
  "stanish": ['S T AE1 N IH0 SH'],
  "stanislas": ['S T AH0 N IH1 S L AH0 S'],
  "stanislav": ['S T AE1 N IH0 S L AA0 V'],
  "stanislaw": ['S T AE1 N IH0 S L AO2'],
  "stanislawski": ['S T AH0 N IH0 S L AA1 F S K IY0'],
  "staniszewski": ['S T AH0 N IH0 SH EH1 F S K IY0'],
  "stank": ['S T AE1 NG K'],
  "stanke": ['S T AE1 NG K'],
  "stankevich": ['S T AE1 NG K AH0 V IH2 CH'],
  "stankey": ['S T AE1 NG K IY0'],
  "stankiewicz": ['S T AE1 N K AH0 V IH0 CH'],
  "stanko": ['S T AE1 NG K OW0'],
  "stankovich": ['S T AE1 NG K AH0 V IH0 CH'],
  "stankowski": ['S T AH0 NG K AO1 F S K IY0'],
  "stankus": ['S T AE1 NG K AH0 S'],
  "stanleigh": ['S T AE1 N L AH0'],
  "stanley": ['S T AE1 N L IY0'],
  "stanley's": ['S T AE1 N L IY0 Z'],
  "stanleytown": ['S T AE1 N L IY0 T AW2 N'],
  "stanline": ['S T AE1 N L AY2 N'],
  "stanly": ['S T AE1 N L IY0'],
  "stanmore": ['S T AE1 N M AO0 R'],
  "stann": ['S T AE1 N'],
  "stannard": ['S T AE1 N ER0 D'],
  "stannie": ['S T AE1 N IY0'],
  "stano": ['S T AA1 N OW0'],
  "stanphill": ['S T AE1 N P HH IH2 L'],
  "stansberry": ['S T AE1 N S B EH2 R IY0'],
  "stansbury": ['S T AE1 N S B EH0 R IY0'],
  "stansel": ['S T AE1 N S AH0 L'],
  "stansell": ['S T AE1 N S AH0 L'],
  "stansfield": ['S T AE1 N S F IY0 L D'],
  "stansky": ['S T AE1 N S K IY0'],
  "stant": ['S T AE1 N T'],
  "stanton": ['S T AE1 N T AH0 N'],
  "stanway": ['S T AE1 N W EY2'],
  "stanwick": ['S T AE1 N W IH0 K'],
  "stanwood": ['S T AE1 N W UH2 D'],
  "stanza": ['S T AE1 N Z AH0'],
  "stanzas": ['S T AE1 N Z AH0 Z'],
  "stanzione": ['S T AA0 N Z IY0 OW1 N IY0'],
  "stapel": ['S T AE1 P AH0 L'],
  "stapf": ['S T AE1 P F'],
  "staph": ['S T AE1 F'],
  "staphylococcus": ['S T AE2 F AH0 L AH0 K AO1 K AH0 S'],
  "staple": ['S T EY1 P AH0 L'],
  "stapled": ['S T EY1 P AH0 L D'],
  "stapler": ['S T EY1 P AH0 L ER0', 'S T EY1 P L ER0'],
  "staplers": ['S T EY1 P AH0 L ER0 Z', 'S T EY1 P L ER0 Z'],
  "staples": ['S T EY1 P AH0 L Z'],
  "stapleton": ['S T EY1 P AH0 L T AH0 N'],
  "stapley": ['S T AE1 P L IY0'],
  "stapling": ['S T EY1 P AH0 L IH0 NG', 'S T EY1 P L IH0 NG'],
  "stapp": ['S T AE1 P'],
  "star": ['S T AA1 R'],
  "star's": ['S T AA1 R Z'],
  "starace": ['S T AA0 R AA1 CH IY0'],
  "starbird": ['S T AA1 R B ER2 D'],
  "starboard": ['S T AA1 R B ER0 D'],
  "starboards": ['S T AA1 R B ER0 R D Z', 'S T AA1 R B AO2 R D Z'],
  "starbuck": ['S T AA1 R B AH2 K'],
  "starbuck's": ['S T AA1 R B AH2 K S'],
  "starbucks": ['S T AA1 R B AH2 K S'],
  "starcevich": ['S T AA1 R S IH0 V IH0 CH'],
  "starch": ['S T AA1 R CH'],
  "starched": ['S T AA1 R CH T'],
  "starcher": ['S T AA1 R CH ER0'],
  "starches": ['S T AA1 R CH IH0 Z'],
  "starchlike": ['S T AA1 R CH L AY2 K'],
  "starchy": ['S T AA1 R CH IY0'],
  "starck": ['S T AA1 R K'],
  "starckmann": ['S T AA1 R K M AH0 N'],
  "starcraft": ['S T AA1 R K R AE2 F T'],
  "starcross": ['S T AA1 R K R AO2 S'],
  "stardel": ['S T AA1 R D EH2 L'],
  "stardent": ['S T AA1 R D EH2 N T'],
  "stardom": ['S T AA1 R D AH0 M'],
  "stardust": ['S T AA1 R D AH2 S T'],
  "stare": ['S T EH1 R'],
  "stared": ['S T EH1 R D'],
  "starer": ['S T EH1 R ER0'],
  "stares": ['S T EH1 R Z'],
  "starfish": ['S T AA1 R F IH2 SH'],
  "starfleet": ['S T AA1 R F IY2 T'],
  "starfleet's": ['S T AA1 R F IY2 T S'],
  "stargate": ['S T AA1 R G EY2 T'],
  "stargates": ['S T AA1 R G EY2 T S'],
  "stargazer": ['S T AA1 R G EY2 Z ER0'],
  "stargazers": ['S T AA1 R G EY2 Z ER0 Z'],
  "starin": ['S T AE1 R IH0 N'],
  "starin'": ['S T EH1 R IH0 N'],
  "staring": ['S T EH1 R IH0 NG'],
  "stark": ['S T AA1 R K'],
  "stark's": ['S T AA1 R K S'],
  "starke": ['S T AA1 R K'],
  "starkel": ['S T AA1 R K AH0 L'],
  "starker": ['S T AA1 R K ER0'],
  "starkers": ['S T AA1 R K ER0 Z'],
  "starkes": ['S T AA1 R K S'],
  "starkey": ['S T AA1 R K IY2'],
  "starkist": ['S T AA1 R K IH0 S T'],
  "starkly": ['S T AA1 R K L IY0'],
  "starkman": ['S T AA1 R K M AH0 N'],
  "starkness": ['S T AA1 R K N IH0 S'],
  "starkovich": ['S T AA1 R K AH0 V IH0 CH'],
  "starks": ['S T AA1 R K S'],
  "starkweather": ['S T AA1 R K W EH2 DH ER0'],
  "starlet": ['S T AA1 R L AH0 T'],
  "starlets": ['S T AA1 R L AH0 T S'],
  "starlight": ['S T AA1 R L AY2 T'],
  "starlike": ['S T AA1 R L AY2 K'],
  "starlin": ['S T AA1 R L IH0 N'],
  "starling": ['S T AA1 R L IH0 NG'],
  "starliper": ['S T AA1 R L IH0 P ER0'],
  "starlit": ['S T AA1 R L IH0 T'],
  "starman": ['S T AA1 R M AH0 N'],
  "starmer": ['S T AA1 R M ER0'],
  "starn": ['S T AA1 R N'],
  "starner": ['S T AA1 R N ER0'],
  "starnes": ['S T AA1 R N Z'],
  "starns": ['S T AA1 R N Z'],
  "starodubstev": ['S T AA2 R OW0 D AH1 B S T EH2 V'],
  "staron": ['S T AE1 R AH0 N'],
  "starplex": ['S T AA1 R P L EH2 K S'],
  "starpointe": ['S T AA1 R P OY2 N T'],
  "starr": ['S T AA1 R'],
  "starr's": ['S T AA1 R Z'],
  "starred": ['S T AA1 R D'],
  "starrett": ['S T AE1 R IH0 T'],
  "starring": ['S T AA1 R IH0 NG'],
  "starrs": ['S T AA1 R Z'],
  "starry": ['S T AA1 R IY2'],
  "stars": ['S T AA1 R Z'],
  "stars'": ['S T AA1 R Z'],
  "starship": ['S T AA1 R SH IH2 P'],
  "starsight": ['S T AA1 R S AY2 T'],
  "starstream": ['S T AA1 R S T R IY2 M'],
  "starstruck": ['S T AA1 R S T R AH2 K'],
  "start": ['S T AA1 R T'],
  "start-up": ['S T AA1 R T AH2 P'],
  "start-ups": ['S T AA1 R T AH1 P S'],
  "started": ['S T AA1 R T IH0 D'],
  "starter": ['S T AA1 R T ER0'],
  "starters": ['S T AA1 R T ER0 Z'],
  "starting": ['S T AA1 R T IH0 NG'],
  "startle": ['S T AA1 R T AH0 L'],
  "startled": ['S T AA1 R T AH0 L D'],
  "startles": ['S T AA1 R T AH0 L Z'],
  "startling": ['S T AA1 R T L IH0 NG'],
  "startlingly": ['S T AA1 R T L IH0 NG L IY2'],
  "starts": ['S T AA1 R T S'],
  "startup": ['S T AA1 R T AH2 P'],
  "startups": ['S T AA1 R T AH2 P S'],
  "starvation": ['S T AA0 R V EY1 SH AH0 N'],
  "starve": ['S T AA1 R V'],
  "starved": ['S T AA1 R V D'],
  "starves": ['S T AA1 R V Z'],
  "starving": ['S T AA1 R V IH0 NG'],
  "starwalt": ['S T AA1 R W AH0 L T'],
  "starwave": ['S T AA1 R W EY2 V'],
  "stary": ['S T EH1 R IY0'],
  "starzyk": ['S T AA1 R Z IH0 K'],
  "stash": ['S T AE1 SH'],
  "stashed": ['S T AE1 SH T'],
  "stashing": ['S T AE1 SH IH0 NG'],
  "stasi": ['S T AA1 S IY0'],
  "stasiak": ['S T AA1 S IY0 AE0 K'],
  "stasik": ['S T AA1 S IH0 K'],
  "stasio": ['S T AA1 S IY0 OW0'],
  "stasko": ['S T AA1 S K OW0'],
  "stasney": ['S T AE1 S N IY0'],
  "stasny": ['S T AE1 S N IY0'],
  "stassen": ['S T AE1 S AH0 N'],
  "stassi": ['S T AE1 S IY0'],
  "stastny": ['S T AE1 S T N IY2'],
  "staszak": ['S T AA1 SH AH0 K'],
  "staszewski": ['S T AH0 SH EH1 F S K IY2'],
  "stat": ['S T AE1 T'],
  "state": ['S T EY1 T'],
  "state's": ['S T EY1 T S'],
  "state-of-the-art": ['S T EY2 T AH0 V DH IH0 AA1 R T'],
  "statecraft": ['S T EY1 T K R AE2 F T'],
  "stated": ['S T EY1 T IH0 D'],
  "statehood": ['S T EY1 T HH UH2 D'],
  "statehouse": ['S T EY1 T HH AW2 S'],
  "statehouses": ['S T EY1 T HH AW2 S IH0 Z'],
  "stateless": ['S T EY1 T L IH0 S'],
  "stately": ['S T EY1 T L IY0'],
  "statement": ['S T EY1 T M AH0 N T'],
  "statement's": ['S T EY1 T M AH0 N T S'],
  "statements": ['S T EY1 T M AH0 N T S'],
  "staten": ['S T AE1 T AH0 N'],
  "stater": ['S T EY1 T ER0'],
  "stater's": ['S T EY1 T ER0 Z'],
  "staters": ['S T EY1 T ER0 Z'],
  "states": ['S T EY1 T S'],
  "states'": ['S T EY1 T S'],
  "statesborough": ['S T EY1 T S B AH0 R OW0'],
  "stateside": ['S T EY1 T S AY1 D'],
  "statesman": ['S T EY1 T S M AH0 N'],
  "statesman's": ['S T EY1 T S M AH0 N Z'],
  "statesmanlike": ['S T AH0 T EH1 S M AH0 N L AY2 K'],
  "statesmanship": ['S T EY1 T S M AH0 N SH IH2 P'],
  "statesmen": ['S T EY1 T S M IH0 N'],
  "stateswest": ['S T EY2 T S W EH1 S T'],
  "statewide": ['S T EY1 T W AY2 D'],
  "statham": ['S T AE1 TH AH0 M'],
  "stathis": ['S T AE1 TH IH0 S'],
  "stathopoulos": ['S T AH0 TH AA1 P AH0 L IH0 S'],
  "static": ['S T AE1 T IH0 K'],
  "statics": ['S T AE1 T IH0 K S'],
  "statin": ['S T EY1 T IH0 N'],
  "statin's": ['S T EY1 T IH0 N Z'],
  "stating": ['S T EY1 T IH0 NG'],
  "statins": ['S T EY1 T IH0 N Z'],
  "station": ['S T EY1 SH AH0 N'],
  "station's": ['S T EY1 SH AH0 N Z'],
  "stationary": ['S T EY1 SH AH0 N EH2 R IY0'],
  "stationed": ['S T EY1 SH AH0 N D'],
  "stationer": ['S T EY1 SH AH0 N ER0'],
  "stationers": ['S T EY1 SH AH0 N ER0 Z'],
  "stationery": ['S T EY1 SH AH0 N EH2 R IY0'],
  "stationing": ['S T EY1 SH AH0 N IH0 NG'],
  "stations": ['S T EY1 SH AH0 N Z'],
  "stations'": ['S T EY1 SH AH0 N Z'],
  "statism": ['S T EY1 T IH2 Z AH0 M'],
  "statist": ['S T EY1 T IH0 S T'],
  "statistic": ['S T AH0 T IH1 S T IH0 K'],
  "statistical": ['S T AH0 T IH1 S T IH0 K AH0 L'],
  "statistically": ['S T AH0 T IH1 S T IH0 K AH0 L IY0', 'S T AH0 T IH1 S T IH0 K L IY0'],
  "statistician": ['S T AE2 T AH0 S T IH1 SH AH0 N'],
  "statisticians": ['S T AE2 T IH0 S T IH1 SH AH0 N Z'],
  "statistics": ['S T AH0 T IH1 S T IH0 K S'],
  "statistics'": ['S T AH0 T IH1 S T IH0 K S'],
  "statists": ['S T EY1 T IH0 S T S'],
  "statler": ['S T AE1 T L ER0'],
  "statoil": ['S T AH0 T OY1 L'],
  "staton": ['S T AE1 T AH0 N'],
  "stats": ['S T AE1 T S'],
  "stattin": ['S T AE1 T IH0 N'],
  "statton": ['S T AE1 T AH0 N'],
  "statuary": ['S T AE1 CH UW0 EH2 R IY0'],
  "statue": ['S T AE1 CH UW2'],
  "statues": ['S T AE1 CH UW2 Z'],
  "statuesque": ['S T AE2 CH UW0 EH1 S K'],
  "statuette": ['S T AE2 CH UW0 EH1 T'],
  "statuettes": ['S T AE2 CH UW0 EH1 T S'],
  "statum": ['S T AE1 T AH0 M'],
  "stature": ['S T AE1 CH ER0'],
  "status": ['S T AE1 T AH0 S', 'S T EY1 T AH0 S'],
  "statute": ['S T AE1 CH UW0 T'],
  "statute's": ['S T AE1 CH UW0 T S'],
  "statutes": ['S T AE1 CH UW0 T S'],
  "statutorily": ['S T AE1 CH AH0 T AO2 R AH0 L IY0', 'S T AE1 CH Y UW0 T AO2 R AH0 L IY0'],
  "statutory": ['S T AE1 CH AH0 T AO2 R IY0'],
  "statz": ['S T AE1 T S'],
  "statzer": ['S T AE1 T Z ER0', 'S T EY1 T Z ER0'],
  "staub": ['S T AO1 B'],
  "stauber": ['S T AW1 B ER0'],
  "staubin": ['S T AW1 B IH0 N'],
  "staubs": ['S T AO1 B Z'],
  "stauch": ['S T AO1 CH'],
  "staudacher": ['S T AW1 D AH0 K ER0'],
  "staude": ['S T AO1 D'],
  "stauder": ['S T AW1 D ER0'],
  "staudinger": ['S T AW1 D IH0 NG ER0'],
  "staudt": ['S T AO1 D T'],
  "stauff": ['S T AO1 F'],
  "stauffacher": ['S T AW1 F AH0 K ER0'],
  "stauffer": ['S T AO1 F ER0'],
  "stauffer's": ['S T AO1 F ER0 Z'],
  "staunch": ['S T AO1 N CH'],
  "staunchest": ['S T AO1 N CH AH0 S T'],
  "staunchly": ['S T AO1 N CH L IY0'],
  "staunton": ['S T AO1 N T AH0 N'],
  "staup": ['S T AO1 P'],
  "stauss": ['S T AO1 S'],
  "stauter": ['S T AW1 T ER0'],
  "stave": ['S T EY1 V'],
  "staved": ['S T EY1 V D'],
  "stavely": ['S T EY1 V L IY0'],
  "staver": ['S T EY1 V ER0'],
  "staves": ['S T EY1 V Z'],
  "staving": ['S T EY1 V IH0 NG'],
  "stavinoha": ['S T AE0 V IH0 N OW1 HH AH0'],
  "stavola": ['S T AA0 V OW1 L AH0'],
  "stavropoulos": ['S T AH0 V R AA1 P AH0 L IH0 S'],
  "stavros": ['S T AH0 V R OW1 Z'],
  "stawicki": ['S T AA0 V IH1 T S K IY0'],
  "stawski": ['S T AA1 F S K IY0'],
  "stay": ['S T EY1'],
  "stayed": ['S T EY1 D'],
  "stayer": ['S T EY1 ER0'],
  "staying": ['S T EY1 IH0 NG'],
  "stayner": ['S T EY1 N ER0'],
  "stayover": ['S T EY1 OW2 V ER0'],
  "stayovers": ['S T EY1 OW2 V ER0 Z'],
  "stays": ['S T EY1 Z'],
  "stayton": ['S T EY1 T AH0 N'],
  "stds": ['EH1 S T IY1 D IY1 Z', 'EH1 S T IY1 D IY1 EH1 S'],
  "stead": ['S T EH1 D'],
  "steadfast": ['S T EH1 D F AE2 S T'],
  "steadfastly": ['S T EH1 D F AE2 S T L IY0'],
  "steadfastness": ['S T EH1 D F AE2 S T N AH0 S'],
  "steadham": ['S T EH1 D HH AH0 M'],
  "steadied": ['S T EH1 D IY0 D'],
  "steadier": ['S T EH1 D IY0 ER0'],
  "steadily": ['S T EH1 D AH0 L IY0'],
  "steadiness": ['S T EH1 D IY0 N IH0 S'],
  "steadman": ['S T EH1 D M AH0 N'],
  "steady": ['S T EH1 D IY0'],
  "steagall": ['S T IY1 G AH0 L'],
  "steagle": ['S T IY1 G AH0 L'],
  "steak": ['S T EY1 K'],
  "steakhouse": ['S T EY1 K HH AW2 S'],
  "steakhouses": ['S T EY1 K HH AW2 S IH0 Z'],
  "steakley": ['S T IY1 K L IY0'],
  "steaks": ['S T EY1 K S'],
  "steal": ['S T IY1 L'],
  "stealer": ['S T IY1 L ER0'],
  "stealey": ['S T IY1 L IY0'],
  "stealing": ['S T IY1 L IH0 NG'],
  "steals": ['S T IY1 L Z'],
  "stealth": ['S T EH1 L TH'],
  "stealthier": ['S T EH1 L TH IY0 ER0'],
  "stealthies": ['S T EH1 L TH IY0 Z'],
  "stealthiest": ['S T EH1 L TH IY0 AH0 S T'],
  "stealthy": ['S T EH1 L TH IY0'],
  "steam": ['S T IY1 M'],
  "steamboat": ['S T IY1 M B OW2 T'],
  "steamboats": ['S T IY1 M B OW2 T S'],
  "steamed": ['S T IY1 M D'],
  "steamer": ['S T IY1 M ER0'],
  "steamers": ['S T IY1 M ER0 Z'],
  "steamier": ['S T IY1 M IY0 ER0'],
  "steamiest": ['S T IY1 M IY0 AH0 S T'],
  "steamily": ['S T IY1 M AH0 L IY0'],
  "steaming": ['S T IY1 M IH0 NG'],
  "steamroller": ['S T IY1 M R OW2 L ER0'],
  "steamrollered": ['S T IY1 M R OW2 L ER0 D'],
  "steams": ['S T IY1 M Z'],
  "steamship": ['S T IY1 M SH IH2 P'],
  "steamy": ['S T IY1 M IY0'],
  "stear": ['S T IH1 R'],
  "stearic": ['S T IY1 R IH0 K'],
  "stearman": ['S T IH1 R M AH0 N'],
  "stearn": ['S T ER1 N'],
  "stearne": ['S T ER1 N'],
  "stearns": ['S T ER1 N Z'],
  "stearns's": ['S T ER1 N Z IH0 Z'],
  "stears": ['S T IY1 R Z'],
  "stebbins": ['S T EH1 B IH0 N Z'],
  "steber": ['S T IY1 B ER0'],
  "stebner": ['S T EH1 B N ER0'],
  "stec": ['S T EH1 K'],
  "stech": ['S T EH1 K'],
  "stecher": ['S T EH1 K ER0'],
  "stechler": ['S T EH1 K L ER0'],
  "stechschulte": ['S T EH1 K SH AH0 L T'],
  "steck": ['S T EH1 K'],
  "steckel": ['S T EH1 K AH0 L'],
  "steckelberg": ['S T EH1 K AH0 L B ER0 G'],
  "stecker": ['S T EH1 K ER0'],
  "stecklein": ['S T EH1 K L AY2 N'],
  "steckler": ['S T EH1 K L ER0'],
  "steckley": ['S T EH1 K L IY0'],
  "steckman": ['S T EH1 K M AH0 N'],
  "stedman": ['S T EH1 D M AH0 N'],
  "steeb": ['S T IY1 B'],
  "steeber": ['S T IY1 B ER0'],
  "steed": ['S T IY1 D'],
  "steed's": ['S T IY1 D Z'],
  "steedley": ['S T IY1 D L IY0'],
  "steedman": ['S T IY1 D M AH0 N'],
  "steeds": ['S T IY1 D Z'],
  "steeg": ['S T IY1 G'],
  "steege": ['S T IY1 JH'],
  "steego": ['S T IY1 G OW0'],
  "steel": ['S T IY1 L'],
  "steel's": ['S T IY1 L Z'],
  "steelcase": ['S T IY1 L K EY2 S'],
  "steele": ['S T IY1 L'],
  "steeled": ['S T IY1 L D'],
  "steeler": ['S T IY1 L ER0'],
  "steelers": ['S T IY1 L ER0 Z'],
  "steeley": ['S T IY1 L IY0'],
  "steelhead": ['S T IY1 L HH EH2 D'],
  "steelmaker": ['S T IY1 L M EY2 K ER0'],
  "steelmaker's": ['S T IY1 L M EY2 K ER0 Z'],
  "steelmakers": ['S T IY1 L M EY2 K ER0 Z'],
  "steelmakers'": ['S T IY1 L M AH0 K ER0 Z'],
  "steelmaking": ['S T IY1 L M EY2 K IH0 NG'],
  "steelman": ['S T IY1 L M AH0 N'],
  "steels": ['S T IY1 L Z'],
  "steelworker": ['S T IY1 L W ER2 K ER0'],
  "steelworkers": ['S T IY1 L W ER2 K ER0 Z'],
  "steelworkers'": ['S T IY1 L W ER0 K ER0 Z'],
  "steelworks": ['S T IY1 L W ER2 K S'],
  "steely": ['S T IY1 L IY0'],
  "steen": ['S T IY1 N'],
  "steenbergen": ['S T IY1 N B ER0 G AH0 N'],
  "steenburgen": ['S T IY1 N B ER0 G AH0 N'],
  "steenkamp": ['S T IY1 N K AE2 M P'],
  "steenkiste": ['S T IY1 N K IH2 S T'],
  "steenrod": ['S T IY1 N R AH0 D'],
  "steensma": ['S T IY1 N Z M AH0'],
  "steenson": ['S T IY1 N S AH0 N'],
  "steep": ['S T IY1 P'],
  "steeped": ['S T IY1 P T'],
  "steepened": ['S T IY1 P AH0 N D'],
  "steeper": ['S T IY1 P ER0'],
  "steepest": ['S T IY1 P AH0 S T'],
  "steeple": ['S T IY1 P AH0 L'],
  "steeplechase": ['S T IY1 P AH0 L CH EY2 S'],
  "steeples": ['S T IY1 P AH0 L Z'],
  "steeply": ['S T IY1 P L IY0'],
  "steepness": ['S T IY1 P N AH0 S'],
  "steer": ['S T IH1 R'],
  "steere": ['S T IH1 R'],
  "steered": ['S T IH1 R D'],
  "steering": ['S T IH1 R IH0 NG'],
  "steers": ['S T IH1 R Z'],
  "stees": ['S T IY1 Z'],
  "steeton": ['S T IY1 T AH0 N'],
  "steever": ['S T IY1 V ER0'],
  "steeves": ['S T IY1 V Z'],
  "stefa": ['S T EH1 F AH0'],
  "stefan": ['S T EH1 F AA0 N'],
  "stefanelli": ['S T EH0 F AA0 N EH1 L IY0'],
  "stefani": ['S T EH1 F AH0 N IY0'],
  "stefani's": ['S T EH1 F AH0 N IY0 Z'],
  "stefaniak": ['S T IH0 F AE1 N IY0 AE0 K'],
  "stefanic": ['S T IH0 F AE1 N IH0 K'],
  "stefanich": ['S T EH1 F AH0 N IH0 CH'],
  "stefanick": ['S T EH1 F AH0 N IH0 K'],
  "stefanie": ['S T EH1 F AH0 N IY0'],
  "stefanik": ['S T IH0 F AE1 N IH0 K'],
  "stefanko": ['S T IH0 F AE1 NG K OW0'],
  "stefano": ['S T EH1 F AH0 N OW0'],
  "stefanopolis": ['S T EH2 F AH0 N AO1 P AH0 L AH0 S'],
  "stefanowicz": ['S T IH0 F AE1 N AH0 V IH0 CH'],
  "stefanski": ['S T IH0 F AE1 N S K IY0'],
  "steffan": ['S T EH1 F AH0 N'],
  "steffanci": ['S T EH2 F AA1 N S IY0'],
  "steffe": ['S T EH1 F'],
  "steffek": ['S T EH1 F IH0 K'],
  "steffel": ['S T EH1 F AH0 L'],
  "steffen": ['S T EH1 F AH0 N'],
  "steffenhagen": ['S T EH1 F IH0 N HH AH0 G AH0 N'],
  "steffens": ['S T EH1 F AH0 N Z'],
  "steffensen": ['S T EH1 F IH0 N S AH0 N'],
  "steffensmeier": ['S T EH1 F IH0 N S M AY0 ER0'],
  "steffenson": ['S T EH1 F IH0 N S AH0 N'],
  "steffes": ['S T EH1 F S'],
  "steffey": ['S T EH1 F IY0'],
  "steffi": ['S T EH1 F IY0'],
  "steffie": ['S T EH1 F IY0'],
  "steffler": ['S T EH1 F L ER0'],
  "steffy": ['S T EH1 F IY0'],
  "stefko": ['S T EH1 F K OW0'],
  "stefl": ['S T EH1 F AH0 L'],
  "stegall": ['S T EH1 G AH0 L'],
  "stegar": ['S T IY1 G ER0'],
  "stegar's": ['S T IY1 G ER0 Z'],
  "stege": ['S T IY1 JH'],
  "stegeman": ['S T IY1 G M AH0 N'],
  "stegemann": ['S T IY1 G M AH0 N'],
  "stegemeier": ['S T EH1 G M AY2 R'],
  "stegenga": ['S T EH0 JH EH1 NG G AH0'],
  "steger": ['S T IY1 G ER0'],
  "stegmaier": ['S T EH1 G M AY0 ER0'],
  "stegman": ['S T EH1 G M AH0 N'],
  "stegmann": ['S T EH1 G M AH0 N'],
  "stegner": ['S T EH1 G N ER0'],
  "stegosaurus": ['S T EH2 G AH0 S AO1 R AH0 S'],
  "stehle": ['S T EH1 HH AH0 L'],
  "stehlik": ['S T EH1 L IH0 K'],
  "stehlin": ['S T EH1 L IH0 N'],
  "stehling": ['S T EH1 L IH0 NG'],
  "stehman": ['S T EH1 M AH0 N'],
  "stehr": ['S T EH1 R'],
  "steib": ['S T IY1 B'],
  "steichen": ['S T AY1 K AH0 N'],
  "steidel": ['S T AY1 D AH0 L'],
  "steidinger": ['S T AY1 D IH0 NG ER0'],
  "steidl": ['S T IY1 D AH0 L'],
  "steidle": ['S T IY1 D AH0 L'],
  "steidtmann": ['S T AY1 T M AH0 N'],
  "steier": ['S T AY1 ER0'],
  "steig": ['S T IY1 G'],
  "steiger": ['S T AY1 G ER0'],
  "steigerwald": ['S T AY1 G ER0 W AO2 L D'],
  "steigerwalt": ['S T AY1 G ER0 W AH0 L T'],
  "steil": ['S T IY1 L'],
  "steiman": ['S T AY1 M AH0 N'],
  "steimel": ['S T AY1 M AH0 L'],
  "steimer": ['S T AY1 M ER0'],
  "steimle": ['S T IY1 M AH0 L'],
  "stein": ['S T AY1 N'],
  "stein's": ['S T AY1 N Z'],
  "steinacker": ['S T AY1 N AE0 K ER0'],
  "steinbach": ['S T AY1 N B AA2 K'],
  "steinbacher": ['S T AY1 N B AA2 K ER0'],
  "steinback": ['S T AY1 N B AE2 K'],
  "steinbauer": ['S T AY1 N B AW0 ER0'],
  "steinbaugh": ['S T AY1 N B AW0'],
  "steinbeck": ['S T AY1 N B EH2 K'],
  "steinbeck's": ['S T AY1 N B EH2 K S'],
  "steinberg": ['S T AY1 N B ER0 G'],
  "steinberg's": ['S T AY1 N B ER0 G Z'],
  "steinbergen": ['S T AY1 N B AH0 R G AH0 N'],
  "steinberger": ['S T AY1 N B ER0 G ER0'],
  "steinbock": ['S T AY1 N B AA2 K'],
  "steinborn": ['S T AY1 N B AO1 R N'],
  "steinbrecher": ['S T AY1 N B R EH2 K ER0'],
  "steinbrenner": ['S T AY1 N B R EH2 N ER0'],
  "steinbrink": ['S T AY1 N B R IH2 NG K'],
  "steinem": ['S T AY1 N AH0 M'],
  "steiner": ['S T AY1 N ER0'],
  "steiner's": ['S T AY1 N ER0 Z'],
  "steinert": ['S T AY1 N ER0 T'],
  "steines": ['S T AY1 N Z'],
  "steinfeld": ['S T AY1 N F EH2 L D'],
  "steinfeldt": ['S T AY1 N F EH2 L T'],
  "steingut": ['S T AY1 N G AH2 T'],
  "steinhagen": ['S T AY1 N HH AE0 G AH0 N'],
  "steinhardt": ['S T AY1 N HH AA2 R T'],
  "steinhardt's": ['S T AY1 N HH AA0 R T S'],
  "steinhart": ['S T AY1 N HH AA2 R T'],
  "steinhauer": ['S T AY1 N HH AW0 ER0'],
  "steinhaus": ['S T AY1 N HH AW2 S'],
  "steinhauser": ['S T AY1 N HH AW2 Z ER0'],
  "steinhilber": ['S T AY1 N HH IH2 L B ER0'],
  "steinhoff": ['S T AY1 N HH AO2 F'],
  "steinhorst": ['S T AY1 N HH AO0 R S T'],
  "steininger": ['S T AY1 N IH0 NG ER0'],
  "steinkamp": ['S T AY1 NG K AE0 M P'],
  "steinke": ['S T AY1 NG K'],
  "steinkraus": ['S T AY1 NG K R AW0 Z'],
  "steinkrauss": ['S T AY1 N K R AW2 S'],
  "steinkuehler": ['S T AY1 N K Y UW2 L ER0'],
  "steinle": ['S T AY1 N AH0 L'],
  "steinman": ['S T AY1 N M AH0 N'],
  "steinmann": ['S T AY1 N M AH0 N'],
  "steinmetz": ['S T AY1 N M EH0 T S'],
  "steinmeyer": ['S T AY1 N M AY0 ER0'],
  "steinmiller": ['S T AY1 N M IH2 L ER0'],
  "steinroe": ['S T AY1 N R OW2'],
  "steinway": ['S T AY1 N W EY2'],
  "steinway's": ['S T AY1 N W EY2 Z'],
  "steitz": ['S T IY1 T S'],
  "stejskal": ['S T EH1 JH S K AH0 L'],
  "steketee": ['S T EH1 K IH0 T IY0'],
  "stekly": ['S T EH1 K L IY0'],
  "stelco": ['S T EH1 L K OW0'],
  "stelian": ['S T IY1 L IY0 AH0 N'],
  "stell": ['S T EH1 L'],
  "stella": ['S T EH1 L AH0'],
  "stella's": ['S T EH1 L AH0 Z'],
  "stellar": ['S T EH1 L ER0'],
  "stellarton": ['S T EH1 L ER0 T AH0 N'],
  "stellato": ['S T EH0 L AA1 T OW0'],
  "stelle": ['S T EH1 L'],
  "stellenbosch": ['S T EH1 L AH0 N B AO2 SH'],
  "steller": ['S T EH1 L ER0'],
  "stellhorn": ['S T EH1 L HH ER0 N'],
  "stelling": ['S T EH1 L IH0 NG'],
  "stelljes": ['S T EY1 L Y EH0 S'],
  "stellmach": ['S T EH1 L M AH0 K'],
  "stellmacher": ['S T EH1 L M AH0 K ER0'],
  "stellman": ['S T EH1 L M AH0 N'],
  "stello": ['S T EH1 L OW0'],
  "stelly": ['S T EH1 L IY0'],
  "stelmach": ['S T EH1 L M AH0 K'],
  "stelmack": ['S T EH1 L M AH0 K'],
  "steloff": ['S T EH1 L AO0 F'],
  "stelter": ['S T EH1 L T ER0'],
  "steltz": ['S T EH1 L T S'],
  "stelzer": ['S T EH1 L Z ER0'],
  "stelzner": ['S T EH1 L Z N ER0'],
  "stem": ['S T EH1 M'],
  "stemberg": ['S T EH1 M B ER0 G'],
  "stembridge": ['S T EH1 M B R IH2 JH'],
  "stemen": ['S T EH1 M AH0 N'],
  "stemler": ['S T EH1 M L ER0'],
  "stemlike": ['S T EH1 M L AY2 K'],
  "stemm": ['S T EH1 M'],
  "stemmed": ['S T EH1 M D'],
  "stemmer": ['S T EH1 M ER0'],
  "stemming": ['S T EH1 M IH0 NG'],
  "stemmler": ['S T EH1 M L ER0'],
  "stempel": ['S T EH1 M P AH0 L'],
  "stempel's": ['S T EH1 M P AH0 L Z'],
  "stemper": ['S T EH1 M P ER0'],
  "stempien": ['S T EH1 M P IY0 N'],
  "stemple": ['S T EH1 M P AH0 L'],
  "stempler": ['S T EH1 M P L ER0'],
  "stempler's": ['S T EH1 M P L ER0 Z'],
  "stemposts": ['S T EH1 M P OW2 S T S'],
  "stems": ['S T EH1 M Z'],
  "sten": ['S T EH1 N'],
  "stena": ['S T IY1 N AH0', 'S T EH1 N AH0', 'S T EY1 N AH0'],
  "stenberg": ['S T EH1 N B ER0 G'],
  "stencel": ['S T EH1 N S AH0 L'],
  "stench": ['S T EH1 N CH'],
  "stencil": ['S T EH1 N S IH0 L'],
  "stenciled": ['S T EH1 N S IH0 L D'],
  "stenciling": ['S T EH1 N S IH0 L IH0 NG', 'S T EH1 N S L IH0 NG'],
  "stendal": ['S T EH1 N D AH0 L'],
  "stender": ['S T EH1 N D ER0'],
  "stendhal": ['S T EH1 N D AA0 L'],
  "stendig": ['S T EH1 N D IH0 G'],
  "stene": ['S T IY1 N'],
  "stenerson": ['S T EH1 N ER0 S AH0 N'],
  "stengel": ['S T EH1 NG G AH0 L'],
  "stenger": ['S T EH1 N JH ER0'],
  "stenglein": ['S T IH1 NG L AY0 N'],
  "stenholm": ['S T EH1 N HH OW2 L M'],
  "stenhouse": ['S T EH1 N HH AW2 S'],
  "stenner": ['S T EH1 N ER0'],
  "stennett": ['S T EH1 N IH0 T'],
  "stennis": ['S T EH1 N IH0 S'],
  "steno": ['S T EH1 N OW0'],
  "stenographer": ['S T EH0 N AH1 G R AH0 F ER0'],
  "stenographic": ['S T EH2 N AH0 G R AE1 F IH0 K'],
  "stenosis": ['S T EH2 N OW1 S IH0 S'],
  "stenquist": ['S T EH1 N K W IH2 S T'],
  "stenseth": ['S T EH1 N S IH0 TH'],
  "stensland": ['S T EH1 N S L AH0 N D'],
  "stenson": ['S T EH1 N S AH0 N'],
  "stensrud": ['S T EH1 N S R AH0 D'],
  "stenstrom": ['S T EH1 N S T R AH0 M'],
  "stent": ['S T EH1 N T'],
  "stentor": ['S T EH1 N T ER0'],
  "stentors": ['S T EH1 N T ER0 Z'],
  "stentz": ['S T EH1 N T S'],
  "steny": ['S T EH1 N IY0'],
  "stenz": ['S T EH1 N Z'],
  "stenzel": ['S T EH1 N Z AH0 L'],
  "step": ['S T EH1 P'],
  "step-by-step": ['S T EH1 P B IY0 S T EH2 P'],
  "stepan": ['S T EH1 P AH0 N'],
  "stepanakert": ['S T AH0 P AE1 N AH0 K ER0 T'],
  "stepanek": ['S T EH1 P AH0 N IH0 K'],
  "stepanian": ['S T IH0 P EY1 N IY0 AH0 N'],
  "stepanian's": ['S T IH0 P EY1 N IY0 AH0 N Z'],
  "stepanski": ['S T IH0 P AE1 N S K IY0'],
  "stepchild": ['S T EH1 P CH AY2 L D'],
  "stepchildren": ['S T EH1 P CH IH1 L D R AH0 N'],
  "stepdaughter": ['S T EH1 P D AO2 T ER0'],
  "stepdaughters": ['S T EH1 P D AO2 T ER0 Z'],
  "stepfather": ['S T EH1 P F AA2 DH ER0'],
  "steph": ['S T EH1 F'],
  "stepha": ['S T EH1 F AH0'],
  "stephan": ['S T EH1 F AH0 N'],
  "stephana": ['S T EH0 F AA1 N AH0'],
  "stephane": ['S T EH0 F AA1 N'],
  "stephani": ['S T EH1 F AH0 N IY0'],
  "stephania": ['S T IH0 F AE1 N IY0 AH0'],
  "stephanie": ['S T EH1 F AH0 N IY0'],
  "stephanopoulos": ['S T EH2 F AH0 N AA1 P AH0 L AH0 S'],
  "stephanopoulos'": ['S T EH2 F AH0 N AA1 P AH0 L AH0 S'],
  "stephanopoulos's": ['S T EH2 F AH0 N AA1 P AH0 L AH0 S IH0 S'],
  "stephans": ['S T EH1 F AH0 N Z'],
  "stephany": ['S T EH1 F AH0 N IY0'],
  "stephen": ['S T IY1 V AH0 N'],
  "stephen's": ['S T IY1 V AH0 N Z'],
  "stephens": ['S T IY1 V AH0 N Z', 'S T EH1 F AH0 N Z'],
  "stephens's": ['S T IY1 V IH0 N Z IH0 Z'],
  "stephenson": ['S T IY1 V AH0 N S AH0 N'],
  "stephenville": ['S T IY1 V IH0 N V IH2 L'],
  "stepien": ['S T EH1 P IY0 AH0 N'],
  "stepka": ['S T EH1 P K AA2'],
  "stepladder": ['S T EH1 P L AE2 D ER0'],
  "stepladders": ['S T EH1 P L AE2 D ER0 Z'],
  "stepmom": ['S T EH1 P M AA2 M'],
  "stepmother": ['S T EH1 P M AH2 DH ER0'],
  "stepney": ['S T EH1 P N IY0'],
  "stepp": ['S T EH1 P'],
  "steppe": ['S T EH1 P'],
  "stepped": ['S T EH1 P T'],
  "steppel": ['S T EH1 P AH0 L'],
  "steppenwolf": ['S T EH1 P AH0 N W UH2 L F'],
  "stepper": ['S T EH1 P ER0'],
  "steppers": ['S T EH1 P ER0 Z'],
  "steppes": ['S T EH1 P S'],
  "steppin'": ['S T EH1 P IH0 N'],
  "stepping": ['S T EH1 P IH0 NG'],
  "steppingstone": ['S T EH1 P IH0 NG S T OW2 N'],
  "steps": ['S T EH1 P S'],
  "stepsister": ['S T EH1 P S IH2 S T ER0'],
  "stepsisters": ['S T EH1 P S IH2 S T ER0 Z'],
  "stepson": ['S T EH1 P S AH2 N'],
  "steptoe": ['S T EH1 P T OW2'],
  "sterba": ['S T EH1 R B AH0'],
  "sterbenz": ['S T ER1 B IH0 N S'],
  "sterchi": ['S T EH1 R K IY0'],
  "stereo": ['S T EH1 R IY0 OW2'],
  "stereographic": ['S T EH2 R IY0 AH0 G R AE1 F IH0 K'],
  "stereolab": ['S T EH1 IY0 OW0 L AE2 B'],
  "stereomicroscope": ['S T EH2 R IY2 OW0 M AY1 K R AH0 S K OW2 P'],
  "stereos": ['S T EH1 R IY0 OW2 Z'],
  "stereotactic": ['S T EH2 R IY0 OW2 T AE1 K T IH0 K'],
  "stereotype": ['S T EH1 R IY0 AH0 T AY2 P', 'S T EH1 R IY0 OW0 T AY2 P'],
  "stereotyped": ['S T EH1 R IY0 AH0 T AY2 P T', 'S T EH1 R IY0 OW0 T AY2 P T'],
  "stereotypes": ['S T EH1 R IY0 AH0 T AY2 P S', 'S T EH1 R IY0 OW0 T AY2 P S'],
  "stereotypical": ['S T EH2 R IY0 OW0 T IH1 P IH0 K AH0 L', 'S T EH2 R IY0 AH0 T IH1 P IH0 K AH0 L'],
  "stereotypically": ['S T EH2 R IY0 OW0 T IH1 P IH0 K AH0 L IY2'],
  "stereotyping": ['S T EH1 R IY0 AH0 T AY2 P IH0 NG', 'S T EH1 R IY0 OW0 T AY2 P IH0 NG'],
  "sterett": ['S T EH1 R IH0 T'],
  "sterett's": ['S T EH1 R AH0 T S'],
  "sterile": ['S T EH1 R AH0 L'],
  "sterility": ['S T ER0 IH1 L IH0 T IY0'],
  "sterilization": ['S T EH2 R AH0 L AH0 Z EY1 SH AH0 N', 'S T EH2 R AH0 L IH0 Z EY1 SH AH0 N'],
  "sterilizations": ['S T EH2 R AH0 L AH0 Z EY1 SH AH0 N Z', 'S T EH2 R AH0 L IH0 Z EY1 SH AH0 N Z'],
  "sterilize": ['S T EH1 R AH0 L AY2 Z'],
  "sterilized": ['S T EH1 R AH0 L AY2 Z D'],
  "sterilizer": ['S T EH1 R AH0 L AY2 Z ER0'],
  "sterilizers": ['S T EH1 R AH0 L AY2 Z ER0 Z'],
  "sterilizes": ['S T EH1 R AH0 L AY2 Z IH0 Z'],
  "sterilizing": ['S T EH1 R AH0 L AY2 Z IH0 NG'],
  "sterk": ['S T ER1 K'],
  "sterkel": ['S T ER1 K AH0 L'],
  "sterle": ['S T ER1 AH0 L'],
  "sterling": ['S T ER1 L IH0 NG'],
  "sterling's": ['S T ER1 L IH0 NG Z'],
  "sterman": ['S T ER1 M AH0 N'],
  "stermer": ['S T ER1 M ER0'],
  "stern": ['S T ER1 N'],
  "stern's": ['S T ER1 N Z'],
  "sternbach": ['S T ER1 N B AA0 K'],
  "sternberg": ['S T ER1 N B ER0 G'],
  "sternberger": ['S T ER1 N B ER0 G ER0'],
  "sterne": ['S T ER1 N'],
  "sternem": ['S T ER1 N EH0 M'],
  "sterner": ['S T ER1 N ER0'],
  "sternest": ['S T ER1 N AH0 S T'],
  "sternfeld": ['S T ER1 N F EH0 L D'],
  "sternhagen": ['S T ER1 N HH AH0 G AH0 N'],
  "sternly": ['S T ER1 N L IY0'],
  "sternness": ['S T ER1 N N AH0 S', 'S T ER1 N AH0 S'],
  "sternpost": ['S T ER1 N P OW2 S T'],
  "sternposts": ['S T ER1 N P OW2 S T S'],
  "sterns": ['S T ER1 N Z'],
  "steroid": ['S T ER0 OY1 D'],
  "steroids": ['S T EH1 R OY0 D Z'],
  "sterr": ['S T EH1 R'],
  "sterrett": ['S T EH1 R IH0 T'],
  "sterry": ['S T EH1 R IY0'],
  "stet": ['S T EH1 T'],
  "stethem": ['S T EH1 TH AH0 M', 'S T EH1 T AH0 M'],
  "stethoscope": ['S T EH1 TH AH0 S K OW2 P'],
  "stethoscopes": ['S T EH1 TH AH0 S K OW2 P S'],
  "stetler": ['S T EH1 T L ER0'],
  "stetson": ['S T EH1 T S AH0 N'],
  "stetter": ['S T EH1 T ER0'],
  "stettler": ['S T EH1 T L ER0'],
  "stettner": ['S T EH1 T N ER0'],
  "stetz": ['S T EH1 T S'],
  "stetzel": ['S T EH1 T Z AH0 L'],
  "stetzer": ['S T EH1 T Z ER0'],
  "steuart": ['S T OY1 AA0 R T'],
  "steuben": ['S T Y UW1 B IH0 N', 'S T UW1 B IH0 N'],
  "steubenville": ['S T UW1 B AH0 N V IH2 L'],
  "steuber": ['S T OY1 B ER0'],
  "steuck": ['S T UW1 K'],
  "steuer": ['S T OY1 ER0'],
  "steuerwald": ['S T OY1 ER0 W AO0 L D'],
  "steurer": ['S T ER1 ER0'],
  "steuver": ['S T UW1 V ER0'],
  "stevana": ['S T IH0 V AE1 N AH0'],
  "steve": ['S T IY1 V'],
  "steve's": ['S T IY1 V Z'],
  "stevedore": ['S T IY1 V AH0 D AO2 R'],
  "stevedoring": ['S T IY1 V AH0 D AO2 R IH0 NG'],
  "steven": ['S T IY1 V AH0 N'],
  "steven's": ['S T IY1 V AH0 N Z'],
  "stevena": ['S T EH1 V IH0 N AH0'],
  "stevens": ['S T IY1 V AH0 N Z'],
  "stevens'": ['S T IY1 V AH0 N Z'],
  "stevens's": ['S T IY1 V AH0 N Z AH0 Z', 'S T IY1 V AH0 N Z IH0 Z'],
  "stevenson": ['S T IY1 V AH0 N S AH0 N'],
  "stever": ['S T IY1 V ER0'],
  "steverson": ['S T EH1 V ER0 S AH0 N'],
  "steves": ['S T IY1 V Z'],
  "steveson": ['S T EH1 V IH0 S AH0 N'],
  "stevick": ['S T EH1 V IH0 K'],
  "stevie": ['S T IY1 V IY0'],
  "stevison": ['S T EH1 V IH0 S AH0 N'],
  "stew": ['S T UW1'],
  "stew's": ['S T UW1 Z'],
  "steward": ['S T UW1 ER0 D'],
  "stewardess": ['S T UW1 ER0 D AH0 S'],
  "stewardesses": ['S T UW1 ER0 D AH0 S IH0 Z'],
  "stewards": ['S T UW1 ER0 D Z'],
  "stewardship": ['S T UW1 ER0 D SH IH2 P'],
  "stewart": ['S T UW1 ER0 T'],
  "stewart's": ['S T UW1 ER0 T S'],
  "stewed": ['S T UW1 D'],
  "stewing": ['S T UW1 IH0 NG'],
  "stews": ['S T UW1 Z'],
  "steyer": ['S T EY1 ER0'],
  "stibel": ['S T IH1 B AH0 L'],
  "stice": ['S T AY1 S'],
  "stich": ['S T IH1 CH'],
  "sticha": ['S T IH1 CH AH0'],
  "stichnoth": ['S T IH1 K N AA2 TH'],
  "sticht": ['S T IH1 K T'],
  "stichter": ['S T IH1 K T ER0'],
  "stick": ['S T IH1 K'],
  "sticka": ['S T IH1 K AH0'],
  "stickball": ['S T IH1 K B AO2 L'],
  "stickel": ['S T IH1 K AH0 L'],
  "stickels": ['S T IH1 K AH0 L Z'],
  "sticker": ['S T IH1 K ER0'],
  "stickers": ['S T IH1 K ER0 Z'],
  "stickier": ['S T IH1 K IY0 ER0'],
  "stickiest": ['S T IH1 K IY0 AH0 S T'],
  "sticking": ['S T IH1 K IH0 NG'],
  "stickland": ['S T IH1 K L AH0 N D'],
  "stickle": ['S T IH1 K AH0 L'],
  "stickler": ['S T IH1 K AH0 L ER0', 'S T IH1 K L ER0'],
  "stickles": ['S T IH1 K AH0 L Z'],
  "stickley": ['S T IH1 K L IY0'],
  "stickney": ['S T IH1 K N IY0'],
  "sticks": ['S T IH1 K S'],
  "sticktight": ['S T IH1 K T AY2 T'],
  "sticktights": ['S T IH1 K T AY2 T S'],
  "sticky": ['S T IH1 K IY0'],
  "stidd": ['S T IH1 D'],
  "stidham": ['S T IH1 D HH AH0 M'],
  "stiebel": ['S T IY1 B AH0 L'],
  "stieber": ['S T IY1 B ER0'],
  "stief": ['S T IY1 F'],
  "stiefel": ['S T IY1 F AH0 L'],
  "stiefelhagen": ['S T IY1 F AH0 L HH EY2 G AH0 N', 'S T AY1 F AH0 L HH EY2 G AH0 N'],
  "stiefvater": ['S T IY1 F V AH0 T ER0'],
  "stieg": ['S T IY1 G'],
  "stiegemeier": ['S T IY1 JH AH0 M AY2 R'],
  "stiegler": ['S T IY1 G L ER0'],
  "stieglitz": ['S T IY1 G L IH0 T S'],
  "stiehl": ['S T IY1 L'],
  "stiens": ['S T IY1 N Z'],
  "stier": ['S T AY1 ER0'],
  "stiers": ['S T AY1 ER0 Z'],
  "stierwalt": ['S T IH1 R W AH0 L T'],
  "stieve": ['S T IY1 V'],
  "stifel": ['S T IH1 F AH0 L'],
  "stiff": ['S T IH1 F'],
  "stiffed": ['S T IH1 F T'],
  "stiffel": ['S T IH1 F AH0 L'],
  "stiffen": ['S T IH1 F AH0 N'],
  "stiffened": ['S T IH1 F AH0 N D'],
  "stiffener": ['S T IH1 F AH0 N ER0'],
  "stiffening": ['S T IH1 F AH0 N IH0 NG', 'S T IH1 F N IH0 NG'],
  "stiffens": ['S T IH1 F AH0 N Z'],
  "stiffer": ['S T IH1 F ER0'],
  "stiffest": ['S T IH1 F AH0 S T'],
  "stiffler": ['S T IH1 F L ER0'],
  "stiffly": ['S T IH1 F L IY0'],
  "stiffness": ['S T IH1 F N AH0 S'],
  "stiffs": ['S T IH1 F S'],
  "stifle": ['S T AY1 F AH0 L'],
  "stifled": ['S T AY1 F AH0 L D'],
  "stifler": ['S T AY1 F L ER0'],
  "stifles": ['S T AY1 F AH0 L Z'],
  "stifling": ['S T AY1 F L IH0 NG', 'S T AY1 F AH0 L IH0 NG'],
  "stifter": ['S T IH1 F T ER0'],
  "stig": ['S T IH1 G'],
  "stigall": ['S T IH1 G AH0 L'],
  "stiger": ['S T AY1 G ER0'],
  "stigers": ['S T AY1 G ER0 Z'],
  "stigler": ['S T IH1 G L ER0'],
  "stiglitz": ['S T IH1 G L IH0 T S'],
  "stigma": ['S T IH1 G M AH0'],
  "stigmata": ['S T IH0 G M AA1 T AH0'],
  "stigmatism": ['S T IH1 G M AH0 T IH2 Z AH0 M'],
  "stigmatize": ['S T IH1 G M AH0 T AY2 Z'],
  "stigmatized": ['S T IH1 G M AH0 T AY2 Z D'],
  "stigmatizing": ['S T IH1 G M AH0 T AY2 Z IH0 NG'],
  "stihl": ['S T IH1 L'],
  "stika": ['S T IH1 K AH0'],
  "stikeleather": ['S T IH1 K IH0 L EH0 DH ER0', 'S T IH1 K L EH0 DH ER0', 'S T AY1 K L EH0 DH ER0'],
  "stil": ['S T IH1 L'],
  "stile": ['S T AY1 L'],
  "stiles": ['S T AY1 L Z'],
  "stiletto": ['S T AH0 L EH1 T OW0'],
  "stilettos": ['S T AH0 L EH1 T OW0 Z'],
  "still": ['S T IH1 L'],
  "stillborn": ['S T IH1 L B AO1 R N'],
  "stille": ['S T IH1 L'],
  "stilled": ['S T IH1 L D'],
  "stiller": ['S T IH1 L ER0'],
  "stiller's": ['S T IH1 L ER0 Z'],
  "stilley": ['S T IH1 L IY0'],
  "stillinger": ['S T IH1 L IH0 NG ER0'],
  "stillings": ['S T IH1 L IH0 NG Z'],
  "stillion": ['S T IH1 L Y AH0 N'],
  "stillman": ['S T IH1 L M AH0 N'],
  "stillness": ['S T IH1 L N AH0 S'],
  "stills": ['S T IH1 L Z'],
  "stillson": ['S T IH1 L S AH0 N'],
  "stillwagon": ['S T IH1 L W AE2 G AH0 N'],
  "stillwater": ['S T IH1 L W AO2 T ER0'],
  "stillwell": ['S T IH1 L W EH2 L'],
  "stillwell's": ['S T IH1 L W EH2 L Z'],
  "stils": ['S T IH1 L Z'],
  "stilson": ['S T IH1 L S AH0 N'],
  "stilt": ['S T IH1 L T'],
  "stilton": ['S T IH1 L T AH0 N'],
  "stilted": ['S T IH1 L T IH0 D'],
  "stiltner": ['S T IH1 L T N ER0'],
  "stilts": ['S T IH1 L T S'],
  "stilwell": ['S T IH1 L W EH2 L'],
  "stimac": ['S T IH1 M AH0 K'],
  "stimmel": ['S T IH1 M AH0 L'],
  "stimpert": ['S T IH1 M P ER0 T'],
  "stimpson": ['S T IH1 M P S AH0 N'],
  "stimpy": ['S T IH1 M P IY0'],
  "stimson": ['S T IH1 M S AH0 N'],
  "stimulant": ['S T IH1 M Y AH0 L AH0 N T'],
  "stimulants": ['S T IH1 M Y AH0 L AH0 N T S'],
  "stimulate": ['S T IH1 M Y AH0 L EY2 T'],
  "stimulated": ['S T IH1 M Y AH0 L EY2 T IH0 D'],
  "stimulates": ['S T IH1 M Y AH0 L EY2 T S'],
  "stimulating": ['S T IH1 M Y AH0 L EY2 T IH0 NG'],
  "stimulation": ['S T IH2 M Y AH0 L EY1 SH AH0 N'],
  "stimulative": ['S T IH1 M Y AH0 L EY2 T IH0 V'],
  "stimulator": ['S T IH1 M Y AH0 L EY2 T ER0'],
  "stimulators": ['S T IH1 M Y AH0 L EY2 T ER0 Z'],
  "stimuli": ['S T IH1 M Y AH0 L AY2'],
  "stimulus": ['S T IH1 M Y AH0 L AH0 S'],
  "stinar": ['S T AY1 N AA0 R'],
  "stinchcomb": ['S T IH1 N CH K AH0 M'],
  "stinchfield": ['S T IH1 N CH F IY0 L D'],
  "stine": ['S T AY1 N'],
  "stinebaugh": ['S T IH1 N IH0 B AO0'],
  "stinel": ['S T IH1 N AH0 L', 'S T IH2 N EH1 L'],
  "stinel's": ['S T IH1 N AH0 L Z', 'S T IH2 N EH1 L Z'],
  "stineman": ['S T AY1 N M AH0 N'],
  "stiner": ['S T AY1 N ER0'],
  "stines": ['S T AY1 N Z'],
  "sting": ['S T IH1 NG'],
  "sting's": ['S T IH1 NG Z'],
  "stinger": ['S T IH1 NG ER0'],
  "stingers": ['S T IH1 NG ER0 Z'],
  "stingier": ['S T IH1 N JH IY0 ER0'],
  "stinginess": ['S T IH1 N JH IY0 N IH0 S'],
  "stinging": ['S T IH1 NG IH0 NG'],
  "stingley": ['S T IH1 NG G L IY0'],
  "stings": ['S T IH1 NG Z'],
  "stingy": ['S T IH1 N JH IY0'],
  "stink": ['S T IH1 NG K'],
  "stinkbug": ['S T IH1 NG K B AH0 G'],
  "stinkbugs": ['S T IH1 NG K B AH0 G Z'],
  "stinkers": ['S T IH1 NG K ER0 Z'],
  "stinking": ['S T IH1 NG K IH0 NG'],
  "stinks": ['S T IH1 NG K S'],
  "stinky": ['S T IH1 NG K IY0'],
  "stinnett": ['S T IH1 N IH0 T'],
  "stinnette": ['S T IH0 N EH1 T'],
  "stinson": ['S T IH1 N S AH0 N'],
  "stint": ['S T IH1 N T'],
  "stints": ['S T IH1 N T S'],
  "stipanovich": ['S T IH0 P AE1 N AH0 V IH0 CH'],
  "stipe": ['S T AY1 P'],
  "stipend": ['S T AY1 P AH0 N D'],
  "stipends": ['S T AY1 P AH0 N D Z'],
  "stipes": ['S T AY1 P S'],
  "stipp": ['S T IH1 P'],
  "stipulate": ['S T IH1 P Y AH0 L EY2 T'],
  "stipulated": ['S T IH1 P Y AH0 L EY2 T IH0 D'],
  "stipulates": ['S T IH1 P Y AH0 L EY2 T S'],
  "stipulating": ['S T IH1 P Y AH0 L EY2 T IH0 NG'],
  "stipulation": ['S T IH2 P Y AH0 L EY1 SH AH0 N'],
  "stipulations": ['S T IH2 P Y AH0 L EY1 SH AH0 N Z'],
  "stir": ['S T ER1'],
  "stires": ['S T AY1 R Z'],
  "stirewalt": ['S T ER1 W AA2 L T'],
  "stirling": ['S T ER1 L IH0 NG'],
  "stirling's": ['S T ER1 L IH0 NG Z'],
  "stirn": ['S T ER1 N'],
  "stirred": ['S T ER1 D'],
  "stirring": ['S T ER1 IH0 NG'],
  "stirrings": ['S T ER1 IH0 NG Z'],
  "stirrup": ['S T ER1 AH0 P'],
  "stirrups": ['S T ER1 AH0 P S'],
  "stirs": ['S T ER1 Z'],
  "stitch": ['S T IH1 CH'],
  "stitched": ['S T IH1 CH T'],
  "stitches": ['S T IH1 CH IH0 Z'],
  "stitching": ['S T IH1 CH IH0 NG'],
  "stiteler": ['S T AY1 T L ER0'],
  "stitely": ['S T AY1 T L IY0'],
  "stites": ['S T AY1 T S'],
  "stith": ['S T IH1 TH'],
  "stitt": ['S T IH1 T'],
  "stitz": ['S T IH1 T S'],
  "stitzel": ['S T IH1 T Z AH0 L'],
  "stitzer": ['S T IH1 T Z ER0'],
  "stiver": ['S T AY1 V ER0'],
  "stivers": ['S T AY1 V ER0 Z'],
  "stiverson": ['S T IH1 V ER0 S AH0 N'],
  "stivoric": ['S T IH0 V AO1 R IH0 K'],
  "stjohn": ['S EY1 N T JH AA1 N'],
  "stobaugh": ['S T AA1 B AO0'],
  "stobbe": ['S T AA1 B'],
  "stober": ['S T OW1 B ER0'],
  "stobie": ['S T AA1 B IY0'],
  "stochastic": ['S T OW0 K AE1 S T IH0 K'],
  "stochastically": ['S T OW0 K AE1 S T IH0 K AH0 L IY2'],
  "stochastics": ['S T OW0 K AE1 S T IH0 K S'],
  "stock": ['S T AA1 K'],
  "stock's": ['S T AA1 K S'],
  "stockade": ['S T AA1 K EY2 D'],
  "stockade's": ['S T AA1 K EY2 D Z'],
  "stockades": ['S T AA1 K EY2 D Z'],
  "stockard": ['S T AA1 K ER0 D'],
  "stockbridge": ['S T AA1 K B R IH0 JH'],
  "stockbroker": ['S T AA1 K B R OW2 K ER0'],
  "stockbroker's": ['S T AA1 K B R OW2 K ER0 Z'],
  "stockbrokerage": ['S T AA1 K B R OW2 K ER0 IH0 JH'],
  "stockbrokerages": ['S T AA1 K B R OW2 K ER0 IH0 JH IH0 Z'],
  "stockbrokers": ['S T AA1 K B R OW2 K ER0 Z'],
  "stockbrokers'": ['S T AA1 K B R OW2 K ER0 Z'],
  "stockbroking": ['S T AA1 K B R OW2 K IH0 NG'],
  "stockburger": ['S T AA1 K B ER0 G ER0'],
  "stockdale": ['S T AA1 K D EY2 L'],
  "stockdale's": ['S T AA1 K D EY2 L Z'],
  "stocke": ['S T AA1 K'],
  "stocked": ['S T AA1 K T'],
  "stockel": ['S T AA1 K AH0 L'],
  "stocker": ['S T AA1 K ER0'],
  "stockert": ['S T AA1 K ER0 T'],
  "stockett": ['S T AA1 K IH0 T'],
  "stockford": ['S T AA1 K F ER0 D'],
  "stockham": ['S T AA1 K HH AH0 M'],
  "stockhausen": ['S T AA1 K HH AW2 Z AH0 N'],
  "stockholder": ['S T AA1 K HH OW2 L D ER0'],
  "stockholders": ['S T AA1 K HH OW2 L D ER0 Z'],
  "stockholders'": ['S T AA1 K HH OW2 L D ER0 Z'],
  "stockholding": ['S T AA1 K HH OW2 L D IH0 NG'],
  "stockholdings": ['S T AA1 K HH OW2 L D IH0 NG Z'],
  "stockholm": ['S T AA1 K HH OW2 L M'],
  "stockholm's": ['S T AA1 K HH OW2 L M Z'],
  "stocking": ['S T AA1 K IH0 NG'],
  "stockinger": ['S T AA1 K IH0 NG ER0'],
  "stockings": ['S T AA1 K IH0 NG Z'],
  "stockley": ['S T AA1 K L IY0'],
  "stockman": ['S T AA1 K M AH0 N'],
  "stockman's": ['S T AA1 K M AE2 N Z'],
  "stockmarket": ['S T AA1 K M AA2 R K IH0 T'],
  "stockpile": ['S T AA1 K P AY2 L'],
  "stockpiled": ['S T AA1 K P AY2 L D'],
  "stockpiles": ['S T AA1 K P AY2 L Z'],
  "stockpiling": ['S T AA1 K P AY2 L IH0 NG'],
  "stockroom": ['S T AA1 K R UW2 M'],
  "stocks": ['S T AA1 K S'],
  "stocks'": ['S T AA1 K S'],
  "stocksdale": ['S T AA1 K S D EY2 L'],
  "stockslager": ['S T AA1 K S L EY0 G ER0'],
  "stockstill": ['S T AA1 K S T IH2 L'],
  "stockton": ['S T AA1 K T AH0 N'],
  "stockwell": ['S T AA1 K W EH2 L'],
  "stocky": ['S T AA1 K IY0'],
  "stockyard": ['S T AA1 K Y AA2 R D'],
  "stockyards": ['S T AA1 K Y AA2 R D Z'],
  "stocum": ['S T OW1 K AH0 M'],
  "stoddard": ['S T AA1 D ER0 D'],
  "stoddard's": ['S T AA1 D ER0 D Z'],
  "stodghill": ['S T AA1 JH HH IH2 L'],
  "stodgy": ['S T AA1 JH IY0'],
  "stodola": ['S T OW0 D OW1 L AA2'],
  "stodolsky": ['S T AH0 D AO1 L S K IY2'],
  "stoeber": ['S T OW1 B ER0'],
  "stoeckel": ['S T OW1 K AH0 L'],
  "stoecker": ['S T OW1 K ER0'],
  "stoeckle": ['S T OW1 K AH0 L'],
  "stoecklein": ['S T OW1 K L AY2 N'],
  "stoeger": ['S T OW1 G ER0'],
  "stoehr": ['S T AO1 R'],
  "stoelting": ['S T OW1 L T IH0 NG'],
  "stoeltze": ['S T OW1 L T S'],
  "stoermer": ['S T AO1 R M ER0'],
  "stoessel": ['S T OW1 S AH0 L'],
  "stoever": ['S T AA1 EH0 V ER0'],
  "stofer": ['S T OW1 F ER0'],
  "stoff": ['S T AO1 F'],
  "stoffel": ['S T AA1 F AH0 L'],
  "stoffels": ['S T AA1 F AH0 L Z'],
  "stoffer": ['S T AO1 F ER0'],
  "stoffers": ['S T AO1 F ER0 Z'],
  "stoffregen": ['S T AA1 F R IH0 G AH0 N'],
  "stofko": ['S T OW1 F K OW2'],
  "stoga": ['S T OW1 G AH0'],
  "stogdill": ['S T AA1 G D IH0 L'],
  "stogie": ['S T OW1 G IY2'],
  "stogies": ['S T OW1 G IY2 Z'],
  "stogner": ['S T AA1 G N ER0'],
  "stogsdill": ['S T AA1 G Z D IH0 L'],
  "stohl": ['S T OW1 L'],
  "stohler": ['S T OW1 L ER0'],
  "stohr": ['S T AO1 R'],
  "stoia": ['S T OW1 Y AA2'],
  "stoiber": ['S T OY1 B ER0'],
  "stoic": ['S T OW1 IH0 K'],
  "stoically": ['S T OW1 IH0 K L IY0'],
  "stoicism": ['S T OW1 AH0 S IH2 Z AH0 M'],
  "stoics": ['S T OW1 IH0 K S'],
  "stoke": ['S T OW1 K'],
  "stoked": ['S T OW1 K T'],
  "stokely": ['S T OW1 K L IY0'],
  "stoker": ['S T OW1 K ER0'],
  "stokers": ['S T OW1 K ER0 Z'],
  "stokes": ['S T OW1 K S'],
  "stoking": ['S T OW1 K IH0 NG'],
  "stokke": ['S T AA1 K'],
  "stokley": ['S T AA1 K L IY0'],
  "stoklosa": ['S T AH0 K L OW1 S AA2'],
  "stol": ['S T OW1 L'],
  "stolar": ['S T OW1 L ER0'],
  "stolarski": ['S T AH0 L AA1 R S K IY2'],
  "stolarz": ['S T OW1 L AA0 R Z'],
  "stolberg": ['S T OW1 L B ER0 G'],
  "stoldt": ['S T OW1 L T'],
  "stole": ['S T OW1 L'],
  "stolen": ['S T OW1 L AH0 N'],
  "stoler": ['S T OW1 L ER0'],
  "stolfi": ['S T OW1 L F IY0'],
  "stolichnaya": ['S T OW2 L IH0 K N AY1 AH0'],
  "stolid": ['S T AA1 L AH0 D'],
  "stoll": ['S T OW1 L'],
  "stollar": ['S T AA1 L ER0'],
  "stolle": ['S T AA1 L'],
  "stollen": ['S T AA1 L AH0 N'],
  "stoller": ['S T OW1 L ER0'],
  "stolley": ['S T AA1 L IY0'],
  "stollings": ['S T OW1 L IH0 NG Z'],
  "stolp": ['S T OW1 L P'],
  "stolpe": ['S T OW1 L P'],
  "stolper": ['S T OW1 L P ER0'],
  "stolt": ['S T OW1 L T'],
  "stolte": ['S T OW1 L T'],
  "stoltenberg": ['S T OW1 L T AH0 N B ER0 G'],
  "stoltman": ['S T OW1 L T M AH0 N'],
  "stoltz": ['S T OW1 L T S'],
  "stoltzfus": ['S T OW1 L T S F AH0 S'],
  "stoltzman": ['S T OW1 L T S M AH0 N'],
  "stolz": ['S T OW1 L Z'],
  "stolze": ['S T OW1 L Z'],
  "stoma": ['S T OW1 M AH0'],
  "stomach": ['S T AH1 M AH0 K'],
  "stomachs": ['S T AH1 M AH0 K S'],
  "stomata": ['S T OW1 M AH0 T AH0'],
  "stombaugh": ['S T AA1 M B AO2'],
  "stomp": ['S T AA1 M P'],
  "stomped": ['S T AA1 M P T'],
  "stomping": ['S T AA1 M P IH0 NG'],
  "stone": ['S T OW1 N'],
  "stone's": ['S T OW1 N Z'],
  "stoneback": ['S T OW1 N B AE2 K'],
  "stoneberg": ['S T OW1 N B ER0 G'],
  "stoneberger": ['S T OW1 N B ER0 G ER0'],
  "stonebraker": ['S T AA1 N IH0 B R AH0 K ER0', 'S T OW1 N B R EY0 K ER0'],
  "stoneburner": ['S T OW1 N B ER2 N ER0'],
  "stonecipher": ['S T OW1 N S AY2 F ER0'],
  "stonecutter": ['S T OW1 N K AH2 T ER0'],
  "stonecutters": ['S T OW1 N K AH2 T ER0 Z'],
  "stonecypher": ['S T AA1 N IH0 S IH0 F ER0', 'S T OW1 N S AY0 F ER0'],
  "stoned": ['S T OW1 N D'],
  "stoneham": ['S T OW1 N AH0 M'],
  "stonehenge": ['S T OW1 N HH EH2 N JH'],
  "stonehill": ['S T OW1 N HH IH2 L'],
  "stonehocker": ['S T OW1 N HH AA2 K ER0'],
  "stonehouse": ['S T OW1 N HH AW2 S'],
  "stoneking": ['S T OW1 N K IH2 NG'],
  "stoneman": ['S T OW1 N M AH0 N'],
  "stoner": ['S T OW1 N ER0'],
  "stoneridge": ['S T OW1 N R IH2 JH'],
  "stonerock": ['S T OW1 N R AA2 K'],
  "stones": ['S T OW1 N Z'],
  "stones'": ['S T OW1 N Z'],
  "stonesifer": ['S T OW1 N S AY2 F ER0'],
  "stonestreet": ['S T OW1 N S T R IY2 T'],
  "stonewall": ['S T OW1 N W AO2 L'],
  "stonewalled": ['S T OW1 N W AO2 L D'],
  "stonewalling": ['S T OW1 N W AO2 L IH0 NG'],
  "stoneware": ['S T OW1 N W EH2 R'],
  "stoney": ['S T OW1 N IY0'],
  "stong": ['S T AO1 NG'],
  "stonge": ['S T AA1 N JH'],
  "stoning": ['S T OW1 N IH0 NG'],
  "stony": ['S T OW1 N IY0'],
  "stonyfield": ['S T OW1 N IY0 F IY2 L D'],
  "stood": ['S T UH1 D'],
  "stooge": ['S T UW1 JH'],
  "stooges": ['S T UW1 JH IH0 Z'],
  "stookey": ['S T UW1 K IY0'],
  "stooksbury": ['S T UW1 K S B EH0 R IY0'],
  "stool": ['S T UW1 L'],
  "stools": ['S T UW1 L Z'],
  "stoop": ['S T UW1 P'],
  "stooped": ['S T UW1 P T'],
  "stooping": ['S T UW1 P IH0 NG'],
  "stoops": ['S T UW1 P S'],
  "stoots": ['S T UW1 T S'],
  "stop": ['S T AA1 P'],
  "stop-gap": ['S T AA1 P G AE2 P'],
  "stopa": ['S T OW1 P AH0'],
  "stopgap": ['S T AA1 P G AE2 P'],
  "stopher": ['S T AA1 F ER0'],
  "stopka": ['S T OW1 P K AH0'],
  "stoplight": ['S T AA1 P L AY2 T'],
  "stoplights": ['S T AA1 P L AY2 T S'],
  "stopover": ['S T AA1 P OW2 V ER0'],
  "stopovers": ['S T AA1 P OW2 V ER0 Z'],
  "stoppage": ['S T AA1 P IH0 JH'],
  "stoppages": ['S T AA1 P IH0 JH IH0 Z'],
  "stopped": ['S T AA1 P T'],
  "stoppel": ['S T AA1 P AH0 L'],
  "stopper": ['S T AA1 P ER0'],
  "stoppers": ['S T AA1 P ER0 Z'],
  "stopping": ['S T AA1 P IH0 NG'],
  "stops": ['S T AA1 P S'],
  "stopwatch": ['S T AA1 P W AA2 CH'],
  "stopwatches": ['S T AA1 P W AA2 CH IH0 Z'],
  "stora": ['S T AO1 R AH0'],
  "stora's": ['S T AO1 R AH0 Z'],
  "storage": ['S T AO1 R AH0 JH', 'S T AO1 R IH0 JH'],
  "storagetek": ['S T AO1 R AH0 JH T EH2 K'],
  "storaska": ['S T AO2 R AE1 S AH0'],
  "storbeck": ['S T AO1 R B EH0 K'],
  "storch": ['S T AO1 R K'],
  "storck": ['S T AO1 R K'],
  "stordahl": ['S T AO1 R D AA0 L'],
  "store": ['S T AO1 R'],
  "store's": ['S T AO1 R Z'],
  "stored": ['S T AO1 R D'],
  "storefront": ['S T AO1 R F R AA2 N T'],
  "storefronts": ['S T AO1 R F R AA2 N T S'],
  "storehouse": ['S T AO1 R HH AW2 S'],
  "storehouse's": ['S T AO1 R HH AW2 S IH0 Z'],
  "storehouses": ['S T AO1 R HH AW2 Z AH0 Z'],
  "storekeeper": ['S T AO1 R K IY2 P ER0'],
  "storekeepers": ['S T AO1 R K IY2 P ER0 Z'],
  "storer": ['S T AO1 R ER0'],
  "storer's": ['S T AO1 R ER0 Z'],
  "storeroom": ['S T AO1 R R UW2 M'],
  "stores": ['S T AO1 R Z'],
  "stores'": ['S T AO1 R Z'],
  "storey": ['S T AO1 R IY0'],
  "storfer": ['S T AO1 R F ER0'],
  "storie": ['S T AO1 R IY0'],
  "storied": ['S T AO1 R IY0 D'],
  "stories": ['S T AO1 R IY0 Z'],
  "storing": ['S T AO1 R IH0 NG'],
  "stork": ['S T AO1 R K'],
  "storlie": ['S T AO1 R L IY0'],
  "storm": ['S T AO1 R M'],
  "storm's": ['S T AO1 R M Z'],
  "stormed": ['S T AO1 R M D'],
  "storment": ['S T AO1 R M AH0 N T'],
  "stormer": ['S T AO1 R M ER0'],
  "stormes": ['S T AO1 R M Z'],
  "stormiest": ['S T AO1 R M IY0 IH0 S T'],
  "storminess": ['S T AO1 R M IY0 N IH0 S'],
  "storming": ['S T AO1 R M IH0 NG'],
  "stormont": ['S T AO1 R M OW0 N T'],
  "storms": ['S T AO1 R M Z'],
  "stormy": ['S T AO1 R M IY0'],
  "storr": ['S T AO1 R'],
  "storrow": ['S T AA1 R OW0'],
  "storrs": ['S T AO1 R Z'],
  "storti": ['S T AO1 R T IY0'],
  "storting": ['S T AO1 R T IH0 NG'],
  "storts": ['S T AO1 R T S'],
  "stortz": ['S T AO1 R T S'],
  "story": ['S T AO1 R IY0'],
  "story's": ['S T AO1 R IY0 Z'],
  "storyboard": ['S T AO1 R IY0 B AO2 R D'],
  "storybook": ['S T AO1 R IY0 B UH2 K'],
  "storybooks": ['S T AO1 R IY0 B UH2 K S'],
  "storyline": ['S T AO1 R IY0 L AY2 N'],
  "storylines": ['S T AO1 R IY0 L AY2 N Z'],
  "storyteller": ['S T AO1 R IY0 T EH2 L ER0'],
  "storytellers": ['S T AO1 R IY0 T EH2 L ER0 Z'],
  "storytelling": ['S T AO1 R IY0 T EH2 L IH0 NG'],
  "storz": ['S T AO1 R Z'],
  "stossel": ['S T AA1 S AH0 L'],
  "stossel's": ['S T AA1 S AH0 L Z'],
  "stotler": ['S T AA1 T L ER0'],
  "stott": ['S T AA1 T'],
  "stottlemyer": ['S T AA1 T AH0 L M AY0 ER0'],
  "stotts": ['S T AA1 T S'],
  "stotz": ['S T AA1 T S'],
  "stoudemire": ['S T UW1 D AH0 M AY2 R'],
  "stoudenmire": ['S T UW1 D AH0 N M AY2 R'],
  "stouder": ['S T AH1 D ER0'],
  "stoudt": ['S T AH1 D T'],
  "stouffer": ['S T AH1 F ER0', 'S T OW1 F ER0'],
  "stouffs": ['S T AH1 F S', 'S T OW1 F S'],
  "stough": ['S T AH1 F'],
  "stoughton": ['S T OW1 T AH0 N'],
  "stoup": ['S T UW1 P'],
  "stout": ['S T AW1 T'],
  "stoute": ['S T AW1 T'],
  "stoutenburg": ['S T AW1 T AH0 N B ER0 G'],
  "stoutly": ['S T AW1 T L IY0'],
  "stoutness": ['S T AW1 T N AH0 S'],
  "stovall": ['S T OW1 V AA0 L'],
  "stove": ['S T OW1 V'],
  "stover": ['S T OW1 V ER0'],
  "stoves": ['S T OW1 V Z'],
  "stow": ['S T OW1'],
  "stowaway": ['S T OW1 AH0 W EY2'],
  "stowaway's": ['S T OW1 AH0 W EY2 Z'],
  "stowaways": ['S T OW1 AH0 W EY2 Z'],
  "stowe": ['S T OW1'],
  "stowed": ['S T OW1 D'],
  "stowell": ['S T AA1 W EH0 L'],
  "stower": ['S T OW1 ER0'],
  "stowers": ['S T OW1 ER0 Z'],
  "stowing": ['S T OW1 W IH0 NG'],
  "stoy": ['S T OY1'],
  "stoyer": ['S T OY1 ER0'],
  "stracener": ['S T R AE1 S IY0 N ER0'],
  "strachan": ['S T R AE1 CH AH0 N'],
  "strack": ['S T R AE1 K'],
  "stracke": ['S T R AE1 K'],
  "strada": ['S T R AA1 D AH0'],
  "straddle": ['S T R AE1 D AH0 L'],
  "straddled": ['S T R AE1 D AH0 L D'],
  "straddles": ['S T R AE1 D AH0 L Z'],
  "straddling": ['S T R AE1 D L IH0 NG'],
  "strader": ['S T R EY1 D ER0'],
  "stradford": ['S T R AE1 D F ER0 D'],
  "stradivarius": ['S T R AE2 D IH0 V EH1 R IY0 AH0 S'],
  "stradley": ['S T R AE1 D L IY0'],
  "stradling": ['S T R AE1 D L IH0 NG'],
  "strafe": ['S T R EY1 F'],
  "strafing": ['S T R EY1 F IH0 NG'],
  "straggle": ['S T R AE1 G AH0 L'],
  "straggled": ['S T R AE1 G AH0 L D'],
  "straggler": ['S T R AE1 G L ER0'],
  "stragglers": ['S T R AE1 G L ER0 Z'],
  "strahan": ['S T R AE1 HH AH0 N'],
  "strahl": ['S T R AA1 L'],
  "strahle": ['S T R EY1 HH AH0 L'],
  "strahler": ['S T R AA1 L ER0'],
  "strahm": ['S T R AA1 M'],
  "straight": ['S T R EY1 T'],
  "straightaway": ['S T R EY1 T AH0 W EY2'],
  "straightaways": ['S T R EY1 T AH0 W EY2 Z'],
  "straightedge": ['S T R EY1 T EH2 JH'],
  "straighten": ['S T R EY1 T AH0 N'],
  "straightened": ['S T R EY1 T AH0 N D'],
  "straightening": ['S T R EY1 T AH0 N IH0 NG', 'S T R EY1 T N IH0 NG'],
  "straightens": ['S T R EY1 T AH0 N Z'],
  "straighter": ['S T R EY1 T ER0'],
  "straightforward": ['S T R EY1 T F AO1 R W ER0 D'],
  "straightforwardly": ['S T R EY2 T F AO1 R W ER0 D L IY0'],
  "straights": ['S T R EY1 T S'],
  "strain": ['S T R EY1 N'],
  "strained": ['S T R EY1 N D'],
  "straining": ['S T R EY1 N IH0 NG'],
  "strains": ['S T R EY1 N Z'],
  "strait": ['S T R EY1 T'],
  "straitjacket": ['S T R EY1 T JH AE2 K AH0 T'],
  "straits": ['S T R EY1 T S'],
  "straka": ['S T R AA1 K AH0'],
  "straker": ['S T R EY1 K ER0'],
  "straley": ['S T R AE1 L IY0'],
  "stram": ['S T R AE1 M'],
  "stranahan": ['S T R AE1 N AH0 HH AE0 N'],
  "strand": ['S T R AE1 N D'],
  "strandberg": ['S T R AE1 N D B ER0 G'],
  "strande": ['S T R AE1 N D'],
  "stranded": ['S T R AE1 N D IH0 D'],
  "stranding": ['S T R AE1 N D IH0 NG'],
  "strandline": ['S T R AE1 N D L AY2 N', 'S T R AE1 N L AY2 N'],
  "strandlines": ['S T R AE1 N D L AY2 N Z', 'S T R AE1 N L AY2 N Z'],
  "strands": ['S T R AE1 N D Z'],
  "strang": ['S T R AE1 NG'],
  "strange": ['S T R EY1 N JH'],
  "strangelove": ['S T R EY1 N JH L AH2 V'],
  "strangely": ['S T R EY1 N JH L IY0'],
  "strangeness": ['S T R EY1 N JH N AH0 S'],
  "stranger": ['S T R EY1 N JH ER0'],
  "stranger's": ['S T R EY1 N JH ER0 Z'],
  "strangers": ['S T R EY1 N JH ER0 Z'],
  "strangest": ['S T R EY1 N JH IH0 S T'],
  "strangis": ['S T R AE1 N JH IH0 S'],
  "strangle": ['S T R AE1 NG G AH0 L'],
  "strangled": ['S T R AE1 NG G AH0 L D'],
  "stranglehold": ['S T R AE1 NG G AH0 L HH OW2 L D'],
  "strangler": ['S T R AE1 NG G L ER0'],
  "strangling": ['S T R AE1 NG G AH0 L IH0 NG', 'S T R AE1 NG G L IH0 NG'],
  "strangulate": ['S T R AE1 NG G Y AH0 L EY2 T'],
  "strangulation": ['S T R AE2 NG G Y AH0 L EY1 SH AH0 N'],
  "strangulations": ['S T R AE2 NG G Y AH0 L EY1 SH AH0 N Z'],
  "strangwayes": ['S T R AE1 NG W EY2 Z'],
  "strano": ['S T R AA1 N OW0'],
  "stransky": ['S T R AE1 N S K IY0'],
  "strap": ['S T R AE1 P'],
  "strapped": ['S T R AE1 P T'],
  "strapping": ['S T R AE1 P IH0 NG'],
  "straps": ['S T R AE1 P S'],
  "strasberg": ['S T R AE1 S B ER0 G'],
  "strasbourg": ['S T R AE1 S B AO2 R G'],
  "strasburg": ['S T R AE1 S B ER0 G'],
  "strasburger": ['S T R AE1 S B ER0 G ER0'],
  "strassburg": ['S T R AE1 S B ER0 G'],
  "strassburger": ['S T R AE1 S B ER0 G ER0'],
  "strasser": ['S T R AE1 S ER0'],
  "strassman": ['S T R AE1 S M AH0 N'],
  "strassner": ['S T R AE1 S N ER0'],
  "straszheim": ['S T R AE1 S HH AY2 M'],
  "strata": ['S T R AE1 T AH0'],
  "stratacom": ['S T R AE1 T AH0 K AA0 M'],
  "stratagem": ['S T R AE1 T AH0 JH AH0 M'],
  "stratagems": ['S T R AE1 T AH0 JH AH0 M Z'],
  "stratas": ['S T R AE1 T AH0 Z'],
  "strate": ['S T R EY1 T'],
  "strategem": ['S T R AE1 T IH0 JH EH0 M'],
  "strategic": ['S T R AH0 T IY1 JH IH0 K'],
  "strategical": ['S T R AH0 T IY1 JH IH0 K AH0 L'],
  "strategically": ['S T R AH0 T IY1 JH IH0 K L IY0'],
  "strategies": ['S T R AE1 T AH0 JH IY0 Z'],
  "strategist": ['S T R AE1 T IH0 JH IH0 S T'],
  "strategists": ['S T R AE1 T IH0 JH IH0 S T S'],
  "strategize": ['S T R AE1 T AH0 JH AY0 Z'],
  "strategizing": ['S T R AE1 T AH0 JH AY0 Z IH0 NG'],
  "strategy": ['S T R AE1 T AH0 JH IY0'],
  "strategy's": ['S T R AE1 T AH0 JH IY0 Z'],
  "strater": ['S T R EY1 T ER0'],
  "stratford": ['S T R AE1 T F ER0 D'],
  "strathman": ['S T R AE1 TH M AH0 N'],
  "stratification": ['S T R AE2 T AH0 F IH0 K EY1 SH AH0 N'],
  "stratified": ['S T R AE1 T AH0 F AY2 D'],
  "stratify": ['S T R AE1 T AH0 F AY2'],
  "stratigraphic": ['S T R AE2 T AH0 G R AE1 F IH0 K'],
  "stratman": ['S T R AE1 T M AH0 N'],
  "stratmann": ['S T R AE1 T M AH0 N'],
  "stratocaster": ['S T R AE1 T OW0 K AE2 S T ER0'],
  "stratoflex": ['S T R AE1 T OW0 F L EH2 K S'],
  "stratosphere": ['S T R AE1 T AH0 S F IH2 R'],
  "stratospheric": ['S T R AE2 T AH0 S F IH1 R IH0 K'],
  "stratton": ['S T R AE1 T AH0 N'],
  "stratum": ['S T R AE1 T AH0 M'],
  "stratus": ['S T R AE1 T AH0 S'],
  "stratus's": ['S T R AE1 T AH0 S IH0 Z'],
  "straub": ['S T R AW1 B'],
  "straube": ['S T R AW1 B'],
  "strauch": ['S T R AW1 K'],
  "straughan": ['S T R AO1 AH0 N'],
  "straughn": ['S T R AO1 N'],
  "straughter": ['S T R AO1 T ER0'],
  "straum": ['S T R AW1 M', 'S T R AA1 M'],
  "straus": ['S T R AW1 S'],
  "strausbaugh": ['S T R AW1 S B AW0'],
  "strause": ['S T R AW1 S'],
  "strauser": ['S T R AW1 S ER0'],
  "strauss": ['S T R AW1 S'],
  "strauss's": ['S T R AW1 S IH0 Z'],
  "strausser": ['S T R AW1 S ER0'],
  "stravinsky": ['S T R AH0 V IH1 N S K IY0'],
  "stravinsky's": ['S T R AH0 V IH1 N S K IY0 Z'],
  "straw": ['S T R AO1'],
  "strawberries": ['S T R AO1 B EH2 R IY0 Z'],
  "strawberry": ['S T R AO1 B EH2 R IY0'],
  "strawbridge": ['S T R AO1 B R IH2 JH'],
  "strawder": ['S T R AO1 D ER0'],
  "strawderman": ['S T R AO1 D ER0 M AH0 N'],
  "strawn": ['S T R AO1 N'],
  "straws": ['S T R AO1 Z'],
  "strawser": ['S T R AO1 Z ER0'],
  "stray": ['S T R EY1'],
  "strayed": ['S T R EY1 D'],
  "strayer": ['S T R EY1 ER0'],
  "strayhorn": ['S T R EY1 HH ER0 N'],
  "straying": ['S T R EY1 IH0 NG'],
  "strays": ['S T R EY1 Z'],
  "streak": ['S T R IY1 K'],
  "streaked": ['S T R IY1 K T'],
  "streaker": ['S T R IY1 K ER0'],
  "streaking": ['S T R IY1 K IH0 NG'],
  "streaks": ['S T R IY1 K S'],
  "stream": ['S T R IY1 M'],
  "streamed": ['S T R IY1 M D'],
  "streamer": ['S T R IY1 M ER0'],
  "streamers": ['S T R IY1 M ER0 Z'],
  "streaming": ['S T R IY1 M IH0 NG'],
  "streamline": ['S T R IY1 M L AY2 N'],
  "streamlined": ['S T R IY1 M L AY2 N D'],
  "streamlining": ['S T R IY1 M L AY2 N IH0 NG'],
  "streams": ['S T R IY1 M Z'],
  "streater": ['S T R IY1 T ER0'],
  "streb": ['S T R EH1 B'],
  "strebe": ['S T R IY1 B'],
  "strebeck": ['S T R IY1 B EH0 K'],
  "strebel": ['S T R EH1 B AH0 L'],
  "streck": ['S T R EH1 K'],
  "strecker": ['S T R EH1 K ER0'],
  "streed": ['S T R IY1 D'],
  "streep": ['S T R IY1 P'],
  "streeper": ['S T R IY1 P ER0'],
  "street": ['S T R IY1 T'],
  "street's": ['S T R IY1 T S'],
  "streetcar": ['S T R IY1 T K AA2 R'],
  "streeter": ['S T R IY1 T ER0'],
  "streeters": ['S T R IY1 T ER0 Z'],
  "streetman": ['S T R IY1 T M AH0 N'],
  "streets": ['S T R IY1 T S'],
  "streett": ['S T R IY1 T'],
  "streetwise": ['S T R IY1 T W AY2 Z'],
  "streety": ['S T R IY1 T IY0'],
  "streff": ['S T R EH1 F'],
  "strege": ['S T R IY1 JH'],
  "strehl": ['S T R EH1 L'],
  "strehle": ['S T R EH1 L'],
  "strehlow": ['S T R EH1 L OW0'],
  "streib": ['S T R AY1 B'],
  "streiber": ['S T R AY1 B ER0'],
  "streich": ['S T R AY1 K'],
  "streicher": ['S T R AY1 K ER0'],
  "streiff": ['S T R AY1 F'],
  "streight": ['S T R EY1 T'],
  "streiker": ['S T R AY1 K ER0'],
  "streisand": ['S T R AY1 Z AH0 N D', 'S T R AY1 S AE2 N D'],
  "streisand's": ['S T R AY1 Z AH0 N D Z', 'S T R AY1 S AE2 N D Z'],
  "streit": ['S T R AY1 T'],
  "streitmatter": ['S T R AY1 T M AH0 T ER0'],
  "strelow": ['S T R EH1 L OW0'],
  "streng": ['S T R EH1 NG'],
  "strenger": ['S T R EH1 NG G ER0'],
  "strength": ['S T R EH1 NG K TH', 'S T R EH1 NG TH'],
  "strengthen": ['S T R EH1 NG TH AH0 N'],
  "strengthened": ['S T R EH1 NG TH AH0 N D'],
  "strengthening": ['S T R EH1 NG TH AH0 N IH0 NG'],
  "strengthens": ['S T R EH1 NG TH AH0 N Z'],
  "strengths": ['S T R EH1 NG K TH S', 'S T R EH1 NG TH S'],
  "strenio": ['S T R IY1 N IY0 OW0'],
  "strenuous": ['S T R EH1 N Y UW0 AH0 S'],
  "strenuously": ['S T R EH1 N Y UW0 AH0 S L IY0'],
  "strep": ['S T R EH1 P'],
  "streps": ['S T R EH1 P S'],
  "streptococcus": ['S T R EH2 P T AH0 K AO1 K AH0 S'],
  "streptokinase": ['S T R EH2 P T AH0 K AY1 N EY2 S', 'S T R EH2 P T OW0 K AY1 N EY2 Z'],
  "stress": ['S T R EH1 S'],
  "stressed": ['S T R EH1 S T'],
  "stresses": ['S T R EH1 S AH0 Z', 'S T R EH1 S IH0 Z'],
  "stressful": ['S T R EH1 S F AH0 L'],
  "stressing": ['S T R EH1 S IH0 NG'],
  "stressor": ['S T R EH1 S ER0'],
  "stressors": ['S T R EH1 S ER0 Z'],
  "stretch": ['S T R EH1 CH'],
  "stretched": ['S T R EH1 CH T'],
  "stretcher": ['S T R EH1 CH ER0'],
  "stretcher-bearer": ['S T R EH1 CH ER0 B EH1 R ER0'],
  "stretcher-bearers": ['S T R EH1 CH ER0 B EH1 R ER0 Z'],
  "stretchers": ['S T R EH1 CH ER0 Z'],
  "stretches": ['S T R EH1 CH AH0 Z', 'S T R EH1 CH IH0 Z'],
  "stretching": ['S T R EH1 CH IH0 NG'],
  "stretto": ['S T R EH1 T OW2'],
  "strevig": ['S T R EH1 V IH0 G'],
  "strew": ['S T R UW1'],
  "strewn": ['S T R UW1 N'],
  "strey": ['S T R EY1'],
  "striar": ['S T R AY1 R'],
  "stribling": ['S T ER1 AH0 B AH0 L IH0 NG', 'S T R IH1 B L IH0 NG'],
  "strick": ['S T R IH1 K'],
  "stricken": ['S T R IH1 K AH0 N'],
  "stricker": ['S T R IH1 K ER0'],
  "strickland": ['S T R IH1 K L AH0 N D'],
  "stricklen": ['S T R IH1 K AH0 L AH0 N'],
  "strickler": ['S T R IH1 K L ER0'],
  "stricklin": ['S T R IH1 K L IH0 N'],
  "strickling": ['S T R IH1 K L IH0 NG'],
  "strict": ['S T R IH1 K T'],
  "stricter": ['S T R IH1 K T ER0'],
  "strictest": ['S T R IH1 K T AH0 S T'],
  "strictly": ['S T R IH1 K T L IY0'],
  "stricture": ['S T R IH1 K CH ER0'],
  "strictures": ['S T R IH1 K CH ER0 Z'],
  "stride": ['S T R AY1 D'],
  "stridency": ['S T R AY1 D AH0 N S IY0'],
  "strident": ['S T R AY1 D AH0 N T'],
  "stridently": ['S T R AY1 D AH0 N T L IY0'],
  "strider": ['S T R AY1 D ER0'],
  "strides": ['S T R AY1 D Z'],
  "striding": ['S T R AY1 D IH0 NG'],
  "strieber": ['S T R AY1 B ER0'],
  "striegel": ['S T R IY1 G AH0 L'],
  "strieker": ['S T R IY1 K ER0'],
  "strieker's": ['S T R IY1 K ER0 Z'],
  "strieter": ['S T R IY1 T ER0'],
  "strife": ['S T R AY1 F'],
  "strifes": ['S T R AY1 F S'],
  "strike": ['S T R AY1 K'],
  "strike's": ['S T R AY1 K S'],
  "strikebreaker": ['S T R AY1 K B R EY2 K ER0'],
  "strikebreakers": ['S T R AY1 K B R EY2 K ER0 Z'],
  "strikeout": ['S T R AY1 K AW2 T'],
  "strikeouts": ['S T R AY1 K AW2 T S'],
  "striker": ['S T R AY1 K ER0'],
  "striker's": ['S T R AY1 K ER0 Z'],
  "strikers": ['S T R AY1 K ER0 Z'],
  "strikers'": ['S T R AY1 K ER0 Z'],
  "strikes": ['S T R AY1 K S'],
  "strikes'": ['S T R AY1 K S'],
  "striking": ['S T R AY1 K IH0 NG'],
  "strikingly": ['S T R AY1 K IH0 NG L IY0'],
  "strimple": ['S T R IH1 M P AH0 L'],
  "strinden": ['S T R IH1 N D AH0 N'],
  "strine": ['S T R AY1 N'],
  "string": ['S T R IH1 NG'],
  "stringed": ['S T R IH1 NG D'],
  "stringency": ['S T R IH1 N JH AH0 N S IY0'],
  "stringent": ['S T R IH1 N JH AH0 N T'],
  "stringently": ['S T R IH1 N JH AH0 N T L IY0'],
  "stringer": ['S T R IH1 NG ER0'],
  "stringers": ['S T R IH1 NG ER0 Z'],
  "stringfellow": ['S T R IH1 NG F EH2 L OW0'],
  "stringfield": ['S T R IH1 NG F IY2 L D'],
  "stringham": ['S T R IH1 NG HH AE2 M'],
  "stringing": ['S T R IH1 NG IH0 NG'],
  "strings": ['S T R IH1 NG Z'],
  "stringy": ['S T R IH1 NG IY0'],
  "strip": ['S T R IH1 P'],
  "strip's": ['S T R IH1 P S'],
  "stripe": ['S T R AY1 P'],
  "striped": ['S T R AY1 P T'],
  "striper": ['S T R AY1 P ER0'],
  "stripers": ['S T R AY1 P ER0 Z'],
  "stripes": ['S T R AY1 P S'],
  "striplin": ['S T R IH1 P L IH0 N'],
  "stripling": ['S T R IH1 P L IH0 NG'],
  "stripped": ['S T R IH1 P T'],
  "stripper": ['S T R IH1 P ER0'],
  "strippers": ['S T R IH1 P ER0 Z'],
  "stripping": ['S T R IH1 P IH0 NG'],
  "strips": ['S T R IH1 P S'],
  "striptease": ['S T R IH1 P T IY2 Z'],
  "strite": ['S T R AY1 T'],
  "strittmatter": ['S T R IH1 T M AH0 T ER0'],
  "strive": ['S T R AY1 V'],
  "striven": ['S T R IH1 V AH0 N'],
  "strives": ['S T R AY1 V Z'],
  "striving": ['S T R AY1 V IH0 NG'],
  "strivings": ['S T R AY1 V IH0 NG Z'],
  "strnad": ['S T ER1 N AE0 D'],
  "strobe": ['S T R OW1 B'],
  "strobel": ['S T R OW1 B AH0 L'],
  "strobel's": ['S T R OW1 B AH0 L Z'],
  "strober": ['S T R OW1 B ER0'],
  "strobl": ['S T R AA1 B AH0 L'],
  "stroble": ['S T R OW1 B AH0 L'],
  "strobridge": ['S T R AA1 B R IH0 JH'],
  "strock": ['S T R AA1 K'],
  "strode": ['S T R OW1 D'],
  "stroder": ['S T R OW1 D ER0'],
  "stroebel": ['S T R OW1 B AH0 L'],
  "stroessner": ['S T R OW1 S N ER0'],
  "stroganoff": ['S T R OW1 G AH0 N AO2 F'],
  "stroh": ['S T R OW1'],
  "strohecker": ['S T R OW1 IH0 K ER0'],
  "strohl": ['S T R OW1 L'],
  "strohm": ['S T R OW1 M'],
  "strohmaier": ['S T R OW1 M AY0 ER0'],
  "strohman": ['S T R OW1 M AH0 N'],
  "strohmeier": ['S T R OW1 M AY0 ER0'],
  "strohmeyer": ['S T R OW1 M AY0 ER0'],
  "stroik": ['S T R OY1 K'],
  "strojny": ['S T R OW1 Y N IY0'],
  "stroke": ['S T R OW1 K'],
  "stroked": ['S T R OW1 K T'],
  "strokes": ['S T R OW1 K S'],
  "stroking": ['S T R OW1 K IH0 NG'],
  "strole": ['S T R OW1 L'],
  "stroll": ['S T R OW1 L'],
  "strolled": ['S T R OW1 L D'],
  "stroller": ['S T R OW1 L ER0'],
  "strollers": ['S T R OW1 L ER0 Z'],
  "strolling": ['S T R OW1 L IH0 NG'],
  "strollo": ['S T R AA1 L OW0'],
  "strolls": ['S T R OW1 L Z'],
  "strom": ['S T R AA1 M'],
  "stroma": ['S T R OW1 M AH0'],
  "stromain": ['S T R AA1 M AY0 N'],
  "stroman": ['S T R OW1 M AH0 N'],
  "strombeck": ['S T R AA1 M B EH2 K'],
  "stromberg": ['S T R AA1 M B ER0 G'],
  "stromboli": ['S T R AA2 M B OW1 L IY0'],
  "stromboli's": ['S T R AA2 M B OW1 L IY0 Z'],
  "strome": ['S T R OW1 M'],
  "stromer": ['S T R OW1 M ER0'],
  "stromgren": ['S T R AA1 M G R EH0 N'],
  "stromme": ['S T R AA1 M'],
  "strommen": ['S T R AA1 M AH0 N'],
  "stromquist": ['S T R AA1 M K W IH2 S T'],
  "stronach": ['S T R AA1 N AH0 K'],
  "strong": ['S T R AO1 NG'],
  "stronger": ['S T R AO1 NG ER0', 'S T R AO1 NG G ER0'],
  "strongest": ['S T R AO1 NG G AH0 S T'],
  "stronghold": ['S T R AO1 NG HH OW2 L D'],
  "strongholds": ['S T R AO1 NG HH OW2 L D Z'],
  "strongly": ['S T R AO1 NG L IY0'],
  "strongman": ['S T R AO1 NG M AE2 N'],
  "strontium": ['S T R AA1 N T IY0 AH0 M'],
  "stroock": ['S T R UH1 K'],
  "stroop": ['S T R UW1 P'],
  "stroope": ['S T R UW1 P'],
  "stroot": ['S T R UW1 T'],
  "strope": ['S T R OW1 P'],
  "stroschein": ['S T R AO1 SH AY0 N'],
  "strosnider": ['S T R AA1 S N AY0 D ER0'],
  "strossen": ['S T R AO1 S EH0 N', 'S T R AO1 S IH0 N'],
  "strother": ['S T R AA1 DH ER0'],
  "strothers": ['S T R AH1 DH ER0 Z'],
  "strothman": ['S T R AA1 TH M AH0 N'],
  "stroud": ['S T R AW1 D'],
  "strough": ['S T R AW1'],
  "stroup": ['S T R UW1 P'],
  "stroupe": ['S T R UW1 P'],
  "strouse": ['S T R AW1 S'],
  "strout": ['S T R AW1 T'],
  "strouth": ['S T R AW1 TH'],
  "strove": ['S T R OW1 V'],
  "strow": ['S T R OW1'],
  "strozier": ['S T R OW1 Z IY0 ER0'],
  "strub": ['S T R AH1 B'],
  "strubbe": ['S T R AH1 B'],
  "strube": ['S T R UW1 B'],
  "strubel": ['S T R UW1 B AH0 L'],
  "struble": ['S T R UW1 B AH0 L'],
  "struchen": ['S T R AH1 K AH0 N'],
  "struck": ['S T R AH1 K'],
  "struckman": ['S T R AH1 K M AH0 N'],
  "structural": ['S T R AH1 K CH ER0 AH0 L'],
  "structurally": ['S T R AH1 K CH ER0 AH0 L IY0'],
  "structure": ['S T R AH1 K CH ER0'],
  "structure's": ['S T R AH1 K CH ER0 Z'],
  "structured": ['S T R AH1 K CH ER0 D'],
  "structures": ['S T R AH1 K CH ER0 Z'],
  "structuring": ['S T R AH1 K CH ER0 IH0 NG'],
  "strudel": ['S T R UW1 D AH0 L'],
  "struebing": ['S T R UH1 B IH0 NG'],
  "struggle": ['S T R AH1 G AH0 L'],
  "struggle's": ['S T R AH1 G AH0 L Z'],
  "struggled": ['S T R AH1 G AH0 L D'],
  "struggles": ['S T R AH1 G AH0 L Z'],
  "struggling": ['S T R AH1 G AH0 L IH0 NG', 'S T R AH1 G L IH0 NG'],
  "strum": ['S T R AH1 M'],
  "strumming": ['S T R AH1 M IH0 NG'],
  "strums": ['S T R AH1 M Z'],
  "strung": ['S T R AH1 NG'],
  "strunk": ['S T R AH1 NG K'],
  "strunk's": ['S T R AH1 NG K S'],
  "strupp": ['S T R AH1 P'],
  "struss": ['S T R AH1 S'],
  "strut": ['S T R AH1 T'],
  "struthers": ['S T R AH1 DH ER0 Z'],
  "struts": ['S T R AH1 T S'],
  "strutting": ['S T R AH1 T IH0 NG'],
  "strutton": ['S T R AH1 T AH0 N'],
  "strutz": ['S T R AH1 T S'],
  "struve": ['S T R UW1 V'],
  "strycharz": ['S T R IH1 HH ER0 Z'],
  "strychnine": ['S T R IH1 K N AY2 N'],
  "stryker": ['S T R AY1 K ER0'],
  "strzelecki": ['S T R EH2 Z IH0 L EH1 T S K IY0', 'S T ER2 Z IH0 L EH1 T S K IY0'],
  "stu": ['S T UW1'],
  "stuard": ['S T UW1 ER0 D'],
  "stuart": ['S T UW1 ER0 T', 'S T Y UW1 ER0 T', 'S T AO1 R T'],
  "stuart's": ['S T UW1 ER0 T S', 'S T Y UW1 ER0 T S', 'S T AO1 R T S'],
  "stuarts": ['S T UW1 ER0 T S'],
  "stub": ['S T AH1 B'],
  "stubbe": ['S T AH1 B'],
  "stubbed": ['S T AH1 B D'],
  "stubbins": ['S T AH1 B IH0 N Z'],
  "stubble": ['S T AH1 B AH0 L'],
  "stubblefield": ['S T AH1 B AH0 L F IY2 L D'],
  "stubborn": ['S T AH1 B ER0 N'],
  "stubbornly": ['S T AH1 B ER0 N L IY0'],
  "stubbornness": ['S T AH1 B ER0 N N AH0 S', 'S T AH1 B ER0 N AH0 S'],
  "stubbs": ['S T AH1 B Z'],
  "stubby": ['S T AH1 B IY0'],
  "stuber": ['S T UW1 B ER0'],
  "stubs": ['S T AH1 B Z'],
  "stucco": ['S T AH1 K OW0'],
  "stuccoed": ['S T AH1 K OW0 D'],
  "stuchell": ['S T AH1 K AH0 L'],
  "stuck": ['S T AH1 K'],
  "stucke": ['S T AH1 K'],
  "stucker": ['S T AH1 K ER0'],
  "stuckert": ['S T AH1 K ER0 T'],
  "stuckey": ['S T AH1 K IY0'],
  "stucki": ['S T AH1 K IY0'],
  "stuckman": ['S T AH1 K M AH0 N'],
  "stucky": ['S T AH1 K IY0'],
  "stud": ['S T AH1 D'],
  "studdard": ['S T AH1 D ER0 D'],
  "studded": ['S T AH1 D IH0 D'],
  "studds": ['S T AH1 D Z'],
  "stude": ['S T UW1 D'],
  "studebaker": ['S T UW1 D AH0 B EY2 K ER0'],
  "studeman": ['S T UW1 D M AH0 N', 'S T UW1 D AH0 M AH0 N'],
  "student": ['S T UW1 D AH0 N T'],
  "student's": ['S T UW1 D AH0 N T S'],
  "students": ['S T UW1 D AH0 N T S'],
  "students'": ['S T UW1 D AH0 N T S'],
  "studer": ['S T UW1 D ER0'],
  "studied": ['S T AH1 D IY0 D'],
  "studies": ['S T AH1 D IY0 Z'],
  "studio": ['S T UW1 D IY0 OW2'],
  "studio's": ['S T UW1 D IY0 OW2 Z'],
  "studios": ['S T UW1 D IY0 OW2 Z'],
  "studios'": ['S T UW1 D IY0 OW2 Z'],
  "studious": ['S T UW1 D IY0 AH0 S'],
  "studiously": ['S T UW1 D IY0 AH0 S L IY0'],
  "studley": ['S T AH1 D L IY0'],
  "studnicka": ['S T AH0 D N IH1 S K AH0'],
  "studs": ['S T AH1 D Z'],
  "studstill": ['S T AH1 D S T IH2 L'],
  "studt": ['S T AH1 D T'],
  "studtgard": ['S T AH1 T G AA2 R D'],
  "study": ['S T AH1 D IY0'],
  "study's": ['S T AH1 D IY0 Z'],
  "studying": ['S T AH1 D IY0 IH0 NG'],
  "studzinski": ['S T AH0 JH IH1 N S K IY0'],
  "stuebe": ['S T UW1 B'],
  "stueber": ['S T UH1 B ER0'],
  "stueck": ['S T UW1 K'],
  "stueve": ['S T UW1 V'],
  "stuewe": ['S T UW1'],
  "stuff": ['S T AH1 F'],
  "stuff's": ['S T AH1 F S'],
  "stuff-it": ['S T AH1 F IH0 T'],
  "stuff-it's": ['S T AH1 F IH0 T S'],
  "stuffed": ['S T AH1 F T'],
  "stuffer": ['S T AH1 F ER0'],
  "stuffing": ['S T AH1 F IH0 NG'],
  "stufflebeam": ['S T AH1 F AH0 L B IY2 M'],
  "stufflebean": ['S T AH1 F AH0 L B IY2 N'],
  "stuffs": ['S T AH1 F S'],
  "stuffy": ['S T AH1 F IY0'],
  "stuhler": ['S T UW1 L ER0'],
  "stuhr": ['S T ER1', 'S T UH1 R'],
  "stuka": ['S T UW1 K AH0'],
  "stukel": ['S T UW1 K AH0 L'],
  "stukes": ['S T UW1 K S'],
  "stukey": ['S T AH1 K IY0'],
  "stull": ['S T AH1 L'],
  "stuller": ['S T AH1 L ER0'],
  "stultify": ['S T AH1 L T AH0 F AY2'],
  "stultifying": ['S T AH1 L T AH0 F AY2 IH0 NG'],
  "stults": ['S T AH1 L T S'],
  "stultz": ['S T AH1 L T S'],
  "stum": ['S T AH1 M'],
  "stumbaugh": ['S T AH1 M B AO2'],
  "stumble": ['S T AH1 M B AH0 L'],
  "stumbled": ['S T AH1 M B AH0 L D'],
  "stumbles": ['S T AH1 M B AH0 L Z'],
  "stumbling": ['S T AH1 M B AH0 L IH0 NG', 'S T AH1 M B L IH0 NG'],
  "stumbo": ['S T AH1 M B OW2'],
  "stumm": ['S T AH1 M'],
  "stump": ['S T AH1 M P'],
  "stumpage": ['S T AH1 M P IH0 JH'],
  "stumpe": ['S T AH1 M P'],
  "stumped": ['S T AH1 M P T'],
  "stumper": ['S T AH1 M P ER0'],
  "stumpf": ['S T AH1 M P F'],
  "stumpff": ['S T AH1 M P F'],
  "stumph": ['S T AH1 M F'],
  "stumping": ['S T AH1 M P IH0 NG'],
  "stumpo": ['S T AH1 M P OW2'],
  "stumpp": ['S T AH1 M P'],
  "stumps": ['S T AH1 M P S'],
  "stun": ['S T AH1 N'],
  "stung": ['S T AH1 NG'],
  "stunk": ['S T AH1 NG K'],
  "stunned": ['S T AH1 N D'],
  "stunner": ['S T AH1 N ER0'],
  "stunning": ['S T AH1 N IH0 NG'],
  "stunningly": ['S T AH1 N IH0 NG L IY0'],
  "stuns": ['S T AH1 N Z'],
  "stunt": ['S T AH1 N T'],
  "stunted": ['S T AH1 N T IH0 D'],
  "stunts": ['S T AH1 N T S'],
  "stuntz": ['S T AH1 N T S'],
  "stupa": ['S T UW1 P AA2'],
  "stupak": ['S T UW1 P AH0 K'],
  "stupar": ['S T UW1 P ER0'],
  "stupay": ['S T UW0 P EY1'],
  "stupendous": ['S T UW0 P EH1 N D AH0 S'],
  "stupid": ['S T UW1 P AH0 D', 'S T UW1 P IH0 D'],
  "stupider": ['S T UW1 P IH0 D ER0'],
  "stupidest": ['S T UW1 P IH0 D AH0 S T'],
  "stupidity": ['S T UW0 P IH1 D IH0 T IY2'],
  "stupidly": ['S T UW1 P AH0 D L IY0'],
  "stupka": ['S T AH1 P K AA2'],
  "stupor": ['S T UW1 P ER0'],
  "stupp": ['S T AH1 P'],
  "stupski": ['S T AH1 P S K IY2'],
  "sturbridge": ['S T ER1 B R IH2 JH'],
  "sturc": ['S T ER1 K'],
  "sturdevant": ['S T ER1 D IH0 V AH0 N T'],
  "sturdhal": ['S T ER1 D AA0 L'],
  "sturdier": ['S T ER1 D IY0 ER0'],
  "sturdiness": ['S T ER1 D IY0 N AH0 S'],
  "sturdivant": ['S T ER1 D IH0 V AH0 N T'],
  "sturdy": ['S T ER1 D IY0'],
  "sturgell": ['S T ER1 G AH0 L'],
  "sturgeon": ['S T ER1 JH AH0 N', 'S T ER1 JH IH0 N'],
  "sturgeons": ['S T ER1 JH AH0 N Z'],
  "sturges": ['S T ER1 JH IH0 Z'],
  "sturgess": ['S T ER1 G IH0 S'],
  "sturgill": ['S T ER1 G AH0 L'],
  "sturgis": ['S T ER1 JH IH0 S'],
  "sturkie": ['S T ER1 K IY0'],
  "sturm": ['S T ER1 M'],
  "sturman": ['S T ER1 M AH0 N'],
  "sturmans": ['S T ER1 M AH0 N Z'],
  "sturmer": ['S T ER1 M ER0'],
  "sturn": ['S T ER1 N'],
  "sturrock": ['S T AO1 R AH0 K'],
  "sturtevant": ['S T ER1 T AH0 V AH0 N T'],
  "sturtz": ['S T ER1 T S'],
  "sturza": ['S T ER1 Z AH0'],
  "stutes": ['S T UW1 T S'],
  "stutesman": ['S T UW1 T S M AH0 N'],
  "stuteville": ['S T UW1 T V IH2 L'],
  "stutheit": ['S T AH1 TH AY0 T'],
  "stutler": ['S T AH1 T L ER0'],
  "stutner": ['S T AH1 T N ER0'],
  "stutsman": ['S T AH1 T S M AH0 N'],
  "stutter": ['S T AH1 T ER0'],
  "stuttering": ['S T AH1 T ER0 IH0 NG'],
  "stutters": ['S T AH1 T ER0 Z'],
  "stuttgart": ['S T AH1 T G ER0 T', 'S T UW1 T G AA2 R T', 'SH T UW1 T G AA2 R T'],
  "stutts": ['S T AH1 T S'],
  "stutz": ['S T AH1 T S'],
  "stutzman": ['S T AH1 T S M AH0 N'],
  "stuve": ['S T UW1 V'],
  "stuver": ['S T UW1 V ER0'],
  "stuyvesant": ['S T AY1 V AH0 S AH0 N T'],
  "sty": ['S T AY1'],
  "styer": ['S T AY1 ER0'],
  "styers": ['S T AY1 ER0 Z'],
  "stygian": ['S T IH1 JH IY0 AH0 N'],
  "style": ['S T AY1 L'],
  "styled": ['S T AY1 L D'],
  "styles": ['S T AY1 L Z'],
  "styling": ['S T AY1 L IH0 NG'],
  "stylish": ['S T AY1 L IH0 SH'],
  "stylishly": ['S T AY1 L IH0 SH L IY0'],
  "stylist": ['S T AY1 L IH0 S T'],
  "stylistic": ['S T AY0 L IH1 S T IH0 K'],
  "stylistically": ['S T AY0 L IH1 S T IH0 K L IY0'],
  "stylists": ['S T AY1 L IH0 S T S'],
  "stylites": ['S T IH0 L AY1 T S'],
  "stylize": ['S T AY1 L AY2 Z', 'S T AY1 AH0 L AY2 Z'],
  "stylized": ['S T AY1 L AY2 Z D'],
  "stylus": ['S T AY1 L AH0 S'],
  "stymie": ['S T AY1 M IY0'],
  "stymied": ['S T AY1 M IY0 D'],
  "stymies": ['S T AY1 M IY0 Z'],
  "styne": ['S T AY1 N'],
  "styrene": ['S T AY1 R IY2 N'],
  "styrofoam": ['S T AY1 R AH0 F OW2 M'],
  "styron": ['S T AY1 R AO0 N'],
  "stys": ['S T IH1 S'],
  "styx": ['S T IH1 K S'],
  "su": ['S UW1'],
  "suard": ['S UW1 AA0 R D', 'S W AA1 R D'],
  "suarez": ['S W AA0 R EH1 Z'],
  "suasion": ['S W EY1 ZH AH0 N'],
  "suata": ['S UW0 AA1 T AH0'],
  "suata's": ['S UW0 AA1 T AH0 Z'],
  "suave": ['S W AA1 V'],
  "suazo": ['S W AA1 Z OW0'],
  "sub": ['S AH1 B'],
  "sub's": ['S AH1 B Z'],
  "sub-foreman": ['S AH0 B F AO1 R M AH0 N'],
  "sub-way": ['S AH1 B W EY2'],
  "suba": ['S UW1 B AH0'],
  "subacute": ['S AH1 B AH0 K Y UW1 T'],
  "subandrio": ['S AH0 B AE1 N D R IY0 OW0'],
  "subaru": ['S UW1 B ER0 UW0'],
  "subassemblies": ['S AH2 B AH0 S EH1 M B L IY0 Z'],
  "subassembly": ['S AH2 B AH0 S EH1 M B L IY0'],
  "subatomic": ['S AH2 B AH0 T AA1 M IH0 K'],
  "subbed": ['S AH1 B D'],
  "subbing": ['S AH1 B IH0 NG'],
  "subchapter": ['S AH1 B CH AE1 P T ER0'],
  "subcommander": ['S AH1 B K AH0 M AE2 N D ER0'],
  "subcommander's": ['S AH1 B K AH0 M AE2 N D ER0 Z'],
  "subcommanders": ['S AH1 B K AH0 M AE2 N D ER0 Z'],
  "subcommittee": ['S AH1 B K AH0 M IH1 T IY0'],
  "subcommittee's": ['S AH1 B K AH0 M IH1 T IY0 Z'],
  "subcommittees": ['S AH1 B K AH0 M IH1 T IY0 Z'],
  "subcompact": ['S AH0 B K AA1 M P AE0 K T'],
  "subcompacts": ['S AH0 B K AA1 M P AE0 K T S'],
  "subconscious": ['S AH0 B K AA1 N SH AH0 S'],
  "subconsciously": ['S AH0 B K AA1 N SH AH0 S L IY0'],
  "subcontinent": ['S AH0 B K AA1 N T IH0 N AH0 N T'],
  "subcontinents": ['S AH0 B K AA1 N T IH0 N AH0 N T S'],
  "subcontract": ['S AH0 B K AA1 N T R AE2 K T'],
  "subcontracted": ['S AH0 B K AA1 N T R AE0 K T IH0 D'],
  "subcontracting": ['S AH2 B K AH0 N T R AE1 K T IH0 NG'],
  "subcontractor": ['S AH0 B K AA1 N T R AE2 K T ER0'],
  "subcontractors": ['S AH0 B K AA1 N T R AE0 K T ER0 Z'],
  "subcontracts": ['S AH0 B K AA1 N T R AE2 K T S'],
  "subculture": ['S AH1 B K AH2 L CH ER0'],
  "subcultures": ['S AH1 B K AH2 L CH ER0 Z'],
  "subcutaneous": ['S AH0 B K Y UW2 T EY1 N IY0 AH0 S'],
  "subdivide": ['S AH2 B D AH0 V AY1 D'],
  "subdivided": ['S AH2 B D IH0 V AY1 D IH0 D'],
  "subdivision": ['S AH1 B D IH0 V IH2 ZH AH0 N'],
  "subdivisions": ['S AH1 B D IH0 V IH2 ZH AH0 N Z'],
  "subdue": ['S AH0 B D UW1'],
  "subdued": ['S AH0 B D UW1 D'],
  "subduing": ['S AH0 B D UW1 IH0 NG'],
  "suber": ['S UW1 B ER0'],
  "subfamilies": ['S AH1 B F AE2 M AH0 L IY0 Z'],
  "subfamily": ['S AH1 B F AE2 M AH0 L IY0'],
  "subgroup": ['S AH1 B G R UW2 P'],
  "subgroups": ['S AH1 B G R UW2 P S'],
  "subhlok": ['S AH1 B L AA2 K'],
  "subhuman": ['S AH2 B HH Y UW1 M AH0 N'],
  "subia": ['S UW0 B IY1 AH0'],
  "subic": ['S UW1 B IH0 K'],
  "subject": ['S AH0 B JH EH1 K T', 'S AH1 B JH IH0 K T'],
  "subject's": ['S AH1 B JH IH0 K T S'],
  "subjected": ['S AH0 B JH EH1 K T IH0 D'],
  "subjecting": ['S AH0 B JH EH1 K T IH0 NG'],
  "subjective": ['S AH0 B JH EH1 K T IH0 V'],
  "subjectivity": ['S AH0 B JH EH0 K T IH1 V IH0 T IY0'],
  "subjects": ['S AH1 B JH IH0 K T S', 'S AH0 B JH EH1 K T S', 'S AH0 B JH EH1 K S'],
  "subjects'": ['S AH1 B JH EH0 K T S', 'S AH1 B JH EH0 K S'],
  "subjugate": ['S AH1 B JH AH0 G EY2 T'],
  "subjugated": ['S AH1 B JH AH0 G EY2 T IH0 D'],
  "subkingdom": ['S AH0 B K IH1 NG D AH0 M'],
  "sublease": ['S AH1 B L IY2 S'],
  "subleasing": ['S AH0 B L IY1 S IH0 NG'],
  "sublet": ['S AH1 B L EH2 T'],
  "sublett": ['S UW1 B L IH0 T'],
  "sublime": ['S AH0 B L AY1 M'],
  "subliminal": ['S AH0 B L IH1 M IH0 N AH0 L'],
  "subliminally": ['S AH0 B L IH1 M IH0 N AH0 L IY0'],
  "subluxation": ['S AH0 B L AH0 K S EY1 SH AH0 N'],
  "subluxations": ['S AH0 B L AH0 K S EY1 SH AH0 N Z'],
  "submachine": ['S AH2 B M AH0 SH IY1 N'],
  "submarine": ['S AH1 B M ER0 IY2 N', 'S AH2 B M ER0 IY1 N'],
  "submarine's": ['S AH1 B M ER0 IY2 N Z', 'S AH0 B M ER0 IY1 N Z'],
  "submarines": ['S AH1 B M ER0 IY2 N Z', 'S AH0 B M ER0 IY1 N Z'],
  "submarines's": ['S AH1 B M ER0 IY2 N Z IH0 Z'],
  "submerge": ['S AH0 B M ER1 JH'],
  "submerged": ['S AH0 B M ER1 JH D'],
  "submergence": ['S AH0 B M ER1 JH AH0 N S'],
  "submerse": ['S AH0 B M ER1 S'],
  "submersed": ['S AH0 B M ER1 S T'],
  "submersible": ['S AH0 B M ER1 S IH0 B AH0 L'],
  "submersion": ['S AH0 B M ER1 ZH AH0 N'],
  "subminimum": ['S AH0 B M IH1 N IH0 M AH0 M'],
  "submission": ['S AH0 B M IH1 SH AH0 N'],
  "submissions": ['S AH0 B M IH1 SH AH0 N Z'],
  "submissive": ['S AH0 B M IH1 S IH0 V'],
  "submit": ['S AH0 B M IH1 T'],
  "submits": ['S AH2 B M IH1 T S'],
  "submitted": ['S AH0 B M IH1 T IH0 D'],
  "submitting": ['S AH0 B M IH1 T IH0 NG'],
  "subnotebook": ['S AH1 B N OW1 T B UH2 K'],
  "subordinate": ['S AH0 B AO1 R D AH0 N EY2 T', 'S AH0 B AO1 R D AH0 N AH0 T'],
  "subordinated": ['S AH0 B AO1 R D AH0 N EY2 T IH0 D'],
  "subordinates": ['S AH0 B AO1 R D AH0 N EY2 T S', 'S AH0 B AO1 R D AH0 N AH0 T S'],
  "subordinating": ['S AH0 B AO1 R D AH0 N EY2 T IH0 NG'],
  "subordination": ['S AH0 B AO2 R D AH0 N EY1 SH AH0 N'],
  "subotnick": ['S AH0 B AA1 T N IH0 K'],
  "subpar": ['S AH0 B P AA1 R'],
  "subplot": ['S AH1 B P L AA0 T'],
  "subplots": ['S AH1 B P L AA0 T S'],
  "subpoena": ['S AH0 P IY1 N AH0'],
  "subpoenaed": ['S AH0 P IY1 N AH0 D'],
  "subpoenaing": ['S AH0 P IY1 N AH0 IH0 NG'],
  "subpoenas": ['S AH0 P IY1 N AH0 Z'],
  "subprincipal": ['S AH0 B P R IH1 N S AH0 P AH0 L'],
  "subprincipals": ['S AH0 B P R IH1 N S AH0 P AH0 L Z'],
  "subra": ['S UW1 B R AH0'],
  "subramanian": ['S UW2 B R AH0 M AA1 N IY0 AH0 N'],
  "subroto": ['S UW0 B R OW1 T OW0'],
  "subroutine": ['S AH1 B R UW0 T IY2 N'],
  "subs": ['S AH1 B Z'],
  "subs's": ['S AH1 B Z IH0 Z'],
  "subsaharan": ['S AH2 B S AH0 HH EH1 R AH0 N'],
  "subscribe": ['S AH0 B S K R AY1 B'],
  "subscribed": ['S AH0 B S K R AY1 B D'],
  "subscriber": ['S AH0 B S K R AY1 B ER0'],
  "subscriber's": ['S AH0 B S K R AY1 B ER0 Z'],
  "subscribers": ['S AH0 B S K R AY1 B ER0 Z'],
  "subscribers'": ['S AH0 B S K R AY1 B ER0 Z'],
  "subscribes": ['S AH0 B S K R AY1 B Z'],
  "subscribing": ['S AH0 B S K R AY1 B IH0 NG'],
  "subscription": ['S AH0 B S K R IH1 P SH AH0 N'],
  "subscriptions": ['S AH0 B S K R IH1 P SH AH0 N Z'],
  "subsection": ['S AH1 B S EH0 K SH AH0 N'],
  "subsequence": ['S AH0 B S IY1 K W AH0 N S'],
  "subsequent": ['S AH1 B S AH0 K W AH0 N T'],
  "subsequently": ['S AH1 B S AH0 K W AH0 N T L IY0'],
  "subservience": ['S AH0 B S ER1 V IY0 AH0 N S'],
  "subservient": ['S AH0 B S ER1 V IY0 AH0 N T'],
  "subset": ['S AH1 B S EH2 T'],
  "subsets": ['S AH1 B S EH2 T S'],
  "subside": ['S AH0 B S AY1 D'],
  "subsided": ['S AH0 B S AY1 D IH0 D'],
  "subsidence": ['S AH0 B S AY1 D AH0 N S'],
  "subsides": ['S AH0 B S AY1 D Z'],
  "subsidiaries": ['S AH0 B S IH1 D IY0 EH2 R IY0 Z'],
  "subsidiaries'": ['S AH0 B S IH1 D IY0 EH2 R IY0 Z'],
  "subsidiary": ['S AH0 B S IH1 D IY0 EH2 R IY0'],
  "subsidiary's": ['S AH0 B S IH1 D IY0 EH2 R IY0 Z'],
  "subsidies": ['S AH1 B S AH0 D IY0 Z', 'S AH1 B S IH0 D IY0 Z'],
  "subsiding": ['S AH0 B S AY1 D IH0 NG'],
  "subsidization": ['S AH2 B S IH0 D IH0 Z EY1 SH AH0 N'],
  "subsidize": ['S AH1 B S IH0 D AY2 Z'],
  "subsidized": ['S AH1 B S IH0 D AY2 Z D'],
  "subsidizes": ['S AH1 B S IH0 D AY2 Z IH0 Z'],
  "subsidizing": ['S AH1 B S IH0 D AY2 Z IH0 NG'],
  "subsidy": ['S AH1 B S IH0 D IY0'],
  "subsist": ['S AH0 B S IH1 S T'],
  "subsistence": ['S AH0 B S IH1 S T AH0 N S'],
  "subsoil": ['S AH1 B S OY2 L'],
  "subspace": ['S AH1 B S P EY2 S'],
  "subspaces": ['S AH1 B S P EY2 S IH0 S'],
  "subspacing": ['S AH1 B S P EY2 S IH0 NG'],
  "substance": ['S AH1 B S T AH0 N S'],
  "substances": ['S AH1 B S T AH0 N S AH0 Z', 'S AH1 B S T AH0 N S IH0 Z'],
  "substandard": ['S AH0 B S T AE1 N D ER0 D'],
  "substantial": ['S AH0 B S T AE1 N SH AH0 L'],
  "substantially": ['S AH0 B S T AE1 N SH AH0 L IY0'],
  "substantiate": ['S AH0 B S T AE1 N CH IY0 EY2 T', 'S AH0 B S T AE1 N SH IY0 EY2 T'],
  "substantiated": ['S AH0 B S T AE1 N SH IY0 EY2 T IH0 D', 'S AH0 B S T AE1 N CH IY0 EY2 T IH0 D'],
  "substantiates": ['S AH0 B S T AE1 N CH IY0 EY2 T S', 'S AH0 B S T AE1 N SH IY0 EY2 T S'],
  "substantiation": ['S AH0 B S T AE2 N CH IY0 EY1 SH AH0 N', 'S AH0 B S T AE2 N SH IY0 EY1 SH AH0 N'],
  "substantive": ['S AH1 B S T AH0 N T IH0 V'],
  "substantively": ['S AH1 B S T AH0 N T IH0 V L IY0'],
  "substation": ['S AH1 B S T EY2 SH AH0 N'],
  "substitute": ['S AH1 B S T AH0 T UW2 T'],
  "substituted": ['S AH1 B S T AH0 T UW2 T IH0 D'],
  "substitutes": ['S AH1 B S T AH0 T UW2 T S'],
  "substituting": ['S AH1 B S T IH0 T UW2 T IH0 NG'],
  "substitution": ['S AH2 B S T IH0 T UW1 SH AH0 N'],
  "substitutions": ['S AH2 B S T IH0 T Y UW1 SH AH0 N Z'],
  "substrate": ['S AH1 B S T R EY2 T'],
  "substrates": ['S AH1 B S T R EY2 T S'],
  "subsurface": ['S AH1 B S ER2 F AH0 S'],
  "subsystem": ['S AH1 B S IH2 S T AH0 M'],
  "subsystems": ['S AH1 B S IH2 S T AH0 M Z'],
  "subterfuge": ['S AH1 B T ER0 F Y UW2 JH'],
  "subterranean": ['S AH0 B T ER0 EY1 N IY0 AH0 N'],
  "subtext": ['S AH1 B T EH2 K S T'],
  "subtitle": ['S AH1 B T AY2 T AH0 L'],
  "subtitled": ['S AH1 B T AY2 T AH0 L D'],
  "subtitles": ['S AH1 B T AY2 T AH0 L Z'],
  "subtle": ['S AH1 T AH0 L'],
  "subtler": ['S AH1 T AH0 L ER0', 'S AH1 T L ER0'],
  "subtleties": ['S AH1 T AH0 L T IY0 Z'],
  "subtlety": ['S AH1 T AH0 L T IY0'],
  "subtly": ['S AH1 T AH0 L IY0'],
  "subtract": ['S AH0 B T R AE1 K T'],
  "subtracted": ['S AH0 B T R AE1 K T IH0 D'],
  "subtracting": ['S AH0 B T R AE1 K T IH0 NG'],
  "subtraction": ['S AH0 B T R AE1 K SH AH0 N'],
  "subtype": ['S AH1 B T AY2 P'],
  "subtyping": ['S AH1 B T AY2 P IH0 NG'],
  "subunit": ['S AH2 B Y UW1 N IH0 T'],
  "subunits": ['S AH2 B Y UW1 N IH0 T S'],
  "suburb": ['S AH1 B ER0 B'],
  "suburb's": ['S AH1 B ER0 B Z'],
  "suburban": ['S AH0 B ER1 B AH0 N'],
  "suburbanite": ['S AH0 B ER1 B AH0 N AY2 T'],
  "suburbanites": ['S AH0 B ER1 B AH0 N AY2 T S'],
  "suburbanization": ['S AH0 B ER2 B AH0 N IH0 Z EY1 SH AH0 N'],
  "suburbanize": ['S AH0 B ER1 B AH0 N AY2 Z'],
  "suburbans": ['S AH0 B ER1 B AH0 N Z'],
  "suburbia": ['S AH0 B ER1 B IY0 AH0'],
  "suburbs": ['S AH1 B ER0 B Z'],
  "subversion": ['S AH0 B V ER1 ZH AH0 N'],
  "subversive": ['S AH0 B V ER1 S IH0 V'],
  "subversives": ['S AH0 B V ER1 S IH0 V Z'],
  "subvert": ['S AH0 B V ER1 T'],
  "subverted": ['S AH0 B V ER1 T IH0 D'],
  "subverting": ['S AH0 B V ER1 T IH0 NG'],
  "subverts": ['S AH0 B V ER1 T S'],
  "subvolcanic": ['S AH2 B V AA0 L K AE1 N IH0 K'],
  "subway": ['S AH1 B W EY2'],
  "subway's": ['S AH1 B W EY2 Z'],
  "subways": ['S AH1 B W EY2 Z'],
  "subzero": ['S AH2 B Z IH1 R OW0', 'S AH2 B Z IY1 R OW0'],
  "suc": ['S AH1 K'],
  "succeed": ['S AH0 K S IY1 D'],
  "succeeded": ['S AH0 K S IY1 D IH0 D'],
  "succeeding": ['S AH0 K S IY1 D IH0 NG'],
  "succeeds": ['S AH0 K S IY1 D Z'],
  "success": ['S AH0 K S EH1 S'],
  "successes": ['S AH0 K S EH1 S AH0 Z', 'S AH0 K S EH1 S IH0 Z'],
  "successful": ['S AH0 K S EH1 S F AH0 L'],
  "successfully": ['S AH0 K S EH1 S F AH0 L IY0'],
  "succession": ['S AH0 K S EH1 SH AH0 N'],
  "successive": ['S AH0 K S EH1 S IH0 V'],
  "successively": ['S AH0 K S EH1 S IH0 V L IY0'],
  "successor": ['S AH0 K S EH1 S ER0'],
  "successors": ['S AH0 K S EH1 S ER0 Z'],
  "succinct": ['S AH0 K S IH1 NG K T'],
  "succinctly": ['S AH0 K S IH1 NG K T L IY0'],
  "succor": ['S AH1 K ER0'],
  "succulent": ['S AH1 K Y AH0 L IH0 N T'],
  "succulents": ['S AH1 K Y AH0 L AH0 N T S'],
  "succumb": ['S AH0 K AH1 M'],
  "succumbed": ['S AH0 K AH1 M D'],
  "succumbing": ['S AH0 K AH1 M IH0 NG'],
  "succumbs": ['S AH0 K AH1 M Z'],
  "such": ['S AH1 CH'],
  "suchan": ['S AH1 CH AH0 N'],
  "suchanek": ['S AH1 K AH0 N IH0 K'],
  "suchard": ['S UW0 SH AA1 R D'],
  "sucharski": ['S AH0 K AA1 R S K IY0'],
  "suchecki": ['S AH0 K EH1 K IY0'],
  "sucher": ['S AH1 CH ER0'],
  "suchinda": ['S UW2 CH IH1 N D AH0'],
  "suchocki": ['S AH0 K AA1 K IY0'],
  "suchomel": ['S AH1 K OW0 M EH2 L'],
  "suchy": ['S AH1 CH IY0'],
  "suck": ['S AH1 K'],
  "sucked": ['S AH1 K T'],
  "sucker": ['S AH1 K ER0'],
  "suckered": ['S AH1 K ER0 D'],
  "suckers": ['S AH1 K ER0 Z'],
  "sucking": ['S AH1 K IH0 NG'],
  "suckle": ['S AH1 K AH0 L'],
  "suckling": ['S AH1 K L IH0 NG'],
  "suckow": ['S AH1 K AW0'],
  "suckrow": ['S AH1 K R OW0'],
  "sucks": ['S AH1 K S'],
  "sucralose": ['S UW1 K R AH0 L OW2 S'],
  "sucre": ['S UW1 K ER0'],
  "sucrose": ['S UW1 K R OW0 S'],
  "suction": ['S AH1 K SH AH0 N'],
  "suda": ['S UW1 D AH0'],
  "sudafed": ['S UW1 D AH0 F EH2 D'],
  "sudan": ['S UW0 D AE1 N'],
  "sudan's": ['S UW0 D AE1 N Z'],
  "sudanese": ['S UW2 D AH0 N IY1 Z'],
  "sudano": ['S UW0 D AA1 N OW0'],
  "sudbeck": ['S AH1 D B EH2 K'],
  "sudberry": ['S AH1 D B EH2 R IY0'],
  "sudbury": ['S AH1 D B EH2 R IY0'],
  "sudbury's": ['S AH1 D B EH2 R IY0 Z'],
  "sudd": ['S AH1 D'],
  "suddam": ['S AH2 D AA1 M'],
  "suddam's": ['S AH2 D AA1 M Z'],
  "suddarth": ['S AH1 D AA0 R TH'],
  "suddath": ['S AH1 D AH0 TH'],
  "sudden": ['S AH1 D AH0 N'],
  "suddenly": ['S AH1 D AH0 N L IY0'],
  "suddenness": ['S AH1 D AH0 N N AH0 S'],
  "sudderth": ['S AH1 D ER0 TH'],
  "suddeth": ['S AH1 D IH0 TH'],
  "suddreth": ['S AH1 D R IH0 TH'],
  "sudduth": ['S AH1 D AH0 TH'],
  "suder": ['S UW1 D ER0'],
  "suderman": ['S UW1 D ER0 M AH0 N'],
  "sudler": ['S UW1 D AH0 L ER0', 'S UW1 D L ER0'],
  "sudol": ['S UW1 D AH0 L'],
  "suds": ['S AH1 D Z'],
  "sue": ['S UW1'],
  "sued": ['S UW1 D'],
  "suede": ['S W EY1 D'],
  "suedes": ['S W EY1 D Z'],
  "sueker": ['S UW1 K ER0'],
  "suen": ['S UW1 N'],
  "sues": ['S UW1 Z'],
  "suess": ['S W IH1 S'],
  "suey": ['S UW1 IY0'],
  "suez": ['S UW1 EH0 Z'],
  "suez's": ['S UW1 EH0 Z IH0 Z'],
  "suffer": ['S AH1 F ER0'],
  "suffered": ['S AH1 F ER0 D'],
  "sufferer": ['S AH1 F ER0 ER0'],
  "sufferers": ['S AH1 F ER0 ER0 Z'],
  "suffering": ['S AH1 F ER0 IH0 NG', 'S AH1 F R IH0 NG'],
  "sufferings": ['S AH1 F ER0 IH0 NG Z', 'S AH1 F R IH0 NG Z'],
  "suffern": ['S AH1 F ER0 N'],
  "suffers": ['S AH1 F ER0 Z'],
  "suffice": ['S AH0 F AY1 S'],
  "sufficed": ['S AH0 F AY1 S T'],
  "suffices": ['S AH0 F AY1 S IH0 Z'],
  "sufficiency": ['S AH0 F IH1 SH AH0 N S IY0'],
  "sufficient": ['S AH0 F IH1 SH AH0 N T'],
  "sufficiently": ['S AH0 F IH1 SH AH0 N T L IY0'],
  "suffield": ['S AH1 F IY0 L D'],
  "suffield's": ['S AH1 F IY0 L D Z'],
  "suffix": ['S AH1 F IH0 K S'],
  "suffocate": ['S AH1 F AH0 K EY2 T'],
  "suffocated": ['S AH1 F AH0 K EY2 T IH0 D'],
  "suffocating": ['S AH1 F AH0 K EY2 T IH0 NG'],
  "suffocation": ['S AH2 F AH0 K EY1 SH AH0 N'],
  "suffolk": ['S AH1 F AH0 K'],
  "suffrage": ['S AH1 F R IH0 JH'],
  "suffragette": ['S AH2 F R AH0 JH EH1 T'],
  "suffragettes": ['S AH2 F R AH0 JH EH1 T S'],
  "suffragist": ['S AH1 F R AH0 JH IH0 S T'],
  "suffragists": ['S AH1 F R AH0 JH IH0 S T S'],
  "suffuse": ['S AH0 F Y UW1 Z'],
  "suffused": ['S AH0 F Y UW1 Z D'],
  "suffuses": ['S AH0 F Y UW1 Z IH0 Z'],
  "suffusing": ['S AH0 F Y UW1 Z IH0 NG'],
  "sugar": ['SH UH1 G ER0'],
  "sugar's": ['SH UH1 G ER0 Z'],
  "sugarcane": ['SH UH1 G ER0 K EY2 N'],
  "sugared": ['SH UH1 G ER0 D'],
  "sugarman": ['SH UH1 G ER0 M AH0 N'],
  "sugars": ['SH UH1 G ER0 Z'],
  "sugary": ['SH UH1 G ER0 IY0'],
  "sugden": ['S AH1 G D AH0 N'],
  "sugerman": ['S UW1 G ER0 M AH0 N'],
  "sugg": ['S AH1 G'],
  "suggest": ['S AH0 JH EH1 S T'],
  "suggested": ['S AH0 JH EH1 S T IH0 D'],
  "suggestible": ['S AH0 JH EH1 S T AH0 B AH0 L'],
  "suggesting": ['S AH0 JH EH1 S T IH0 NG'],
  "suggestion": ['S AH0 JH EH1 S CH AH0 N'],
  "suggestions": ['S AH0 JH EH1 S CH AH0 N Z'],
  "suggestive": ['S AH0 JH EH1 S T IH0 V'],
  "suggestiveness": ['S AH0 JH EH1 S T IH0 V N AH0 S'],
  "suggests": ['S AH0 JH EH1 S T S'],
  "suggs": ['S AH1 G Z'],
  "sugihara": ['S UW0 G IY0 HH AA1 R AH0'],
  "sugimoto": ['S UW0 G IY0 M OW1 T OW0'],
  "sugiyama": ['S UW0 G IY0 Y AA1 M AH0'],
  "sugrue": ['S AH1 G R UW0'],
  "suh": ['S AH1'],
  "suharto": ['S UW0 HH AA1 R T OW0'],
  "suharto's": ['S UW0 HH AA1 R T OW0 Z'],
  "suhler": ['S UW1 L ER0'],
  "suhm": ['S UW1 M'],
  "suhr": ['S UH1 R'],
  "suhre": ['S UH1 R'],
  "suhua": ['S UW1 HH W AA1'],
  "suhud": ['S UW0 HH UH1 D'],
  "sui": ['S UW1 IY0', 'S W IY0'],
  "suicidal": ['S UW2 AH0 S AY1 D AH0 L'],
  "suicide": ['S UW1 AH0 S AY2 D', 'S UW1 IH0 S AY2 D'],
  "suicides": ['S UW1 AH0 S AY2 D Z'],
  "suing": ['S UW1 IH0 NG'],
  "suire": ['S UH1 R'],
  "suisse": ['S W IH1 S', 'S W IY1 S'],
  "suisse's": ['S W IH1 S IH0 Z', 'S W IY1 S IH0 Z'],
  "suit": ['S UW1 T'],
  "suit's": ['S UW1 T S'],
  "suitability": ['S UW2 T AH0 B IH1 L IH0 T IY0'],
  "suitable": ['S UW1 T AH0 B AH0 L'],
  "suitably": ['S UW1 T AH0 B L IY0'],
  "suitcase": ['S UW1 T K EY2 S'],
  "suitcases": ['S UW1 T K EY2 S IH0 Z'],
  "suite": ['S W IY1 T'],
  "suited": ['S UW1 T IH0 D'],
  "suiter": ['S UW1 T ER0'],
  "suites": ['S W IY1 T S'],
  "suiting": ['S UW1 T IH0 NG'],
  "suitor": ['S UW1 T ER0'],
  "suitor's": ['S UW1 T ER0 Z'],
  "suitors": ['S UW1 T ER0 Z'],
  "suits": ['S UW1 T S'],
  "suitt": ['S UW1 T'],
  "suk": ['S AH1 K'],
  "sukarno": ['S UW0 K AA1 R N OW0'],
  "sukarno's": ['S UW0 K AA1 R N OW0 Z'],
  "sukey": ['S UW1 K IY0'],
  "sukharev": ['S AH1 K ER0 AH0 V'],
  "sukhumi": ['S UW2 K UW1 M IY0'],
  "suki": ['S UW1 K IY0'],
  "sukiyaki": ['S UW0 K IY0 AA1 K IY0'],
  "sukru": ['S UH1 K R UW0', 'S UW1 K R UW0'],
  "sukthankar": ['S UW0 K T AA1 NG K AA2 R'],
  "sukup": ['S UW1 K AH0 P'],
  "sul": ['S AH1 L'],
  "sulak": ['S UW1 L AH0 K'],
  "sulawesi": ['S UW2 L AH0 W EH1 S IY0'],
  "sulcer": ['S AH1 L S ER0'],
  "sulek": ['S UW1 L IH0 K'],
  "suleski": ['S Y UW0 L EH1 S K IY0'],
  "sulewski": ['S Y UW0 L EH1 F S K IY0'],
  "suleyman": ['S UW1 L IY0 M AH0 N'],
  "sulfa": ['S AH1 L F AH0'],
  "sulfate": ['S AH1 L F EY2 T'],
  "sulfide": ['S AH1 L F AY2 D'],
  "sulfite": ['S AH1 L F AY2 T'],
  "sulfites": ['S AH1 L F AY2 T S'],
  "sulfur": ['S AH1 L F ER0'],
  "sulfuric": ['S AH0 L F Y UH1 R IH0 K'],
  "sulfurophane": ['S AH1 L F ER0 AH0 F EY2 N'],
  "sulgrave": ['S AH1 L G R EY2 V'],
  "sulik": ['S UW1 L IH0 K'],
  "sulk": ['S AH1 L K'],
  "sulked": ['S AH1 L K T'],
  "sulking": ['S AH1 L K IH0 NG'],
  "sulkowski": ['S AH0 L K AO1 F S K IY0'],
  "sullen": ['S AH1 L AH0 N'],
  "sullenberger": ['S AH1 L AH0 N B ER0 G ER0'],
  "sullenger": ['S UW1 L IH0 N JH ER0'],
  "sullens": ['S AH1 L AH0 N Z'],
  "sullie": ['S AH1 L IY0'],
  "sullied": ['S AH1 L IY0 D'],
  "sullinger": ['S AH1 L IH0 NG ER0'],
  "sullins": ['S AH1 L IH0 N Z'],
  "sullivan": ['S AH1 L AH0 V AH0 N', 'S AH1 L IH0 V AH0 N'],
  "sullivan's": ['S AH1 L IH0 V AH0 N Z'],
  "sullivans": ['S AH1 L IH0 V AH0 N Z'],
  "sullivant": ['S AH1 L IH0 V AH0 N T'],
  "sullo": ['S UW1 L OW0'],
  "sully": ['S AH1 L IY0'],
  "sulpetro": ['S UW0 L P EH1 T R OW0'],
  "sulphate": ['S AH1 L F EY2 T'],
  "sulphates": ['S AH1 L F EY2 T S'],
  "sulphur": ['S AH1 L F ER0'],
  "sulser": ['S AH1 L S ER0'],
  "sult": ['S AH1 L T'],
  "sultan": ['S AH1 L T AH0 N'],
  "sultan's": ['S AH1 L T AH0 N Z'],
  "sultanate": ['S AH1 L T AH0 N AH0 T'],
  "sultans": ['S AH1 L T AH0 N Z'],
  "sulton": ['S AH1 L T AH0 N'],
  "sultry": ['S AH1 L T R IY0'],
  "sulya": ['S UW1 L Y AH0'],
  "sulzbach": ['S AH1 L Z B AA0 K'],
  "sulzberger": ['S AH1 L T S B ER0 G ER0'],
  "sulzer": ['S AH1 L Z ER0'],
  "sum": ['S AH1 M'],
  "suma": ['S UW1 M AH0'],
  "sumac": ['S UW1 M AE0 K'],
  "suman": ['S UW1 M AH0 N'],
  "sumarlin": ['S UW2 M AA1 R L IH0 N'],
  "sumatoma": ['S UW2 M AH0 T OW1 M AH0'],
  "sumatomo": ['S UW2 M AH0 T OW1 M OW0'],
  "sumatra": ['S UW2 M AA1 T R AH0'],
  "sumatran": ['S UW2 M AA1 T R AH0 N'],
  "sumeria": ['S AH0 M ER1 IY0 AH0'],
  "sumerlin": ['S AH1 M ER0 L IH0 N'],
  "sumgait": ['S AH0 M G EY1 T'],
  "sumida": ['S UW0 M IY1 D AH0'],
  "suminski": ['S AH0 M IH1 N S K IY0'],
  "sumita": ['S UW0 M IY1 T AH0'],
  "sumita's": ['S UW0 M IY1 T AH0 Z'],
  "sumitomo": ['S UW2 M IH0 T OW1 M OW0'],
  "sumitomo's": ['S UW2 M IH0 T OW1 M OW0 Z'],
  "sumler": ['S AH1 M L ER0'],
  "sumlin": ['S AH1 M L IH0 N'],
  "summa": ['S UW1 M AH0'],
  "summagraphic": ['S AH2 M AH0 G R AE1 F IH0 K'],
  "summagraphics": ['S AH2 M AH0 G R AE1 F IH0 K S'],
  "summar": ['S AH1 M ER0'],
  "summaries": ['S AH1 M ER0 IY0 Z'],
  "summarily": ['S AH0 M EH1 R IH0 L IY0'],
  "summarize": ['S AH1 M ER0 AY2 Z'],
  "summarized": ['S AH1 M ER0 AY2 Z D'],
  "summarizes": ['S AH1 M ER0 AY2 Z IH0 Z'],
  "summarizing": ['S AH1 M ER0 AY2 Z IH0 NG'],
  "summary": ['S AH1 M ER0 IY0'],
  "summation": ['S AH0 M EY1 SH AH0 N'],
  "summations": ['S AH0 M EY1 SH AH0 N Z'],
  "summcorp": ['S AH1 M K AO0 R P'],
  "summed": ['S AH1 M D'],
  "summer": ['S AH1 M ER0'],
  "summer's": ['S AH1 M ER0 Z'],
  "summerall": ['S AH1 M ER0 AO2 L'],
  "summerfield": ['S AH1 M ER0 F IY2 L D'],
  "summerford": ['S AH1 M ER0 F ER0 D'],
  "summerhill": ['S AH1 M ER0 HH IH2 L'],
  "summerlin": ['S AH1 M ER0 L IH0 N'],
  "summerour": ['S AH1 M ER0 ER0'],
  "summers": ['S AH1 M ER0 Z'],
  "summers's": ['S AH1 M ER0 Z IH0 Z'],
  "summerson": ['S AH1 M ER0 S AH0 N'],
  "summertime": ['S AH1 M ER0 T AY2 M'],
  "summerville": ['S AH1 M ER0 V IH2 L'],
  "summey": ['S AH1 M IY0'],
  "summing": ['S AH1 M IH0 NG'],
  "summit": ['S AH1 M AH0 T', 'S AH1 M IH0 T'],
  "summit's": ['S AH1 M AH0 T S'],
  "summiteer": ['S AH2 M IH0 T IH1 R'],
  "summiteers": ['S AH2 M IH0 T IH1 R Z'],
  "summitry": ['S AH1 M IH0 T R IY0'],
  "summits": ['S AH1 M IH0 T S'],
  "summitt": ['S AH1 M IH0 T'],
  "summitville": ['S AH1 M IH0 T V IH0 L'],
  "summon": ['S AH1 M AH0 N'],
  "summoned": ['S AH1 M AH0 N D'],
  "summoning": ['S AH1 M AH0 N IH0 NG'],
  "summons": ['S AH1 M AH0 N Z'],
  "summonsed": ['S AH1 M AH0 N Z D'],
  "summonses": ['S AH1 M AH0 N Z IH0 Z'],
  "summor's": ['S AH1 M ER0 Z'],
  "summum": ['S AH1 M AH0 M'],
  "summy": ['S AH1 M IY0'],
  "sumner": ['S AH1 M N ER0'],
  "sumners": ['S AH1 M N ER0 Z'],
  "sumo": ['S UW1 M OW0'],
  "sump": ['S AH1 M P'],
  "sumpter": ['S AH1 M P T ER0'],
  "sumptuous": ['S AH1 M P CH W AH0 S', 'S AH1 M P CH UW0 AH0 S'],
  "sumrall": ['S AH1 M R AH0 L'],
  "sumrell": ['S UW0 M R EY1 L'],
  "sumrow": ['S AH1 M R OW2'],
  "sums": ['S AH1 M Z'],
  "sumter": ['S AH1 M T ER0'],
  "sun": ['S AH1 N'],
  "sun's": ['S AH1 N Z'],
  "sunamerica": ['S AH1 N AH0 M EH1 R AH0 K AH0'],
  "sunau": ['S UW1 N AW0'],
  "sunbath": ['S AH1 N B AE2 TH'],
  "sunbathe": ['S AH1 N B EY2 DH'],
  "sunbathing": ['S AH1 N B EY2 DH IH0 NG'],
  "sunbeam": ['S AH1 N B IY2 M'],
  "sunbeam's": ['S AH1 N B IY2 M Z'],
  "sunbelt": ['S AH1 N B EH2 L T'],
  "sunbelt's": ['S AH1 N B EH2 L T S'],
  "sunbird": ['S AH1 N B ER2 D'],
  "sunbirds": ['S AH1 N B ER2 D Z'],
  "sunburn": ['S AH1 N B ER2 N'],
  "sunburned": ['S AH1 N B ER2 N D'],
  "suncoast": ['S AH1 N K OW2 S T'],
  "suncook": ['S AH1 N K UH2 K'],
  "suncor": ['S AH1 N K AO2 R'],
  "sund": ['S AH1 N D'],
  "sundae": ['S AH1 N D EY0'],
  "sundae's": ['S AH1 N D EY2 Z'],
  "sundahl": ['S AH1 N D AA2 L'],
  "sundai": ['S AH0 N D AY1'],
  "sundance": ['S AH1 N D AE2 N S'],
  "sundar": ['S UW1 N D AA0 R'],
  "sundararajan": ['S UW0 N D AA2 R AH0 R AA1 JH AH0 N'],
  "sunday": ['S AH1 N D EY2', 'S AH1 N D IY2'],
  "sunday's": ['S AH1 N D EY2 Z', 'S AH1 N D IY2 Z'],
  "sundays": ['S AH1 N D EY2 Z', 'S AH1 N D IY2 Z'],
  "sundberg": ['S AH1 N D B ER0 G'],
  "sundby": ['S AH1 N D B IY0'],
  "sunde": ['S AH1 N D'],
  "sundeen": ['S AH1 N D IY0 N'],
  "sundell": ['S AH1 N D AH0 L'],
  "sunder": ['S AH1 N D ER0'],
  "sunderland": ['S AH1 N D ER0 L AH0 N D'],
  "sunderlin": ['S AH1 N D ER0 L IH0 N'],
  "sunderman": ['S AH1 N D ER0 M AH0 N'],
  "sundermeyer": ['S AH1 N D ER0 M AY0 ER0'],
  "sundews": ['S AH1 N D UW2 Z'],
  "sundheim": ['S AH1 N D HH AY2 M'],
  "sundial": ['S AH1 N D AY2 L'],
  "sundin": ['S AH1 N D AH0 N'],
  "sundlun": ['S AH1 N D L AH0 N'],
  "sundown": ['S AH1 N D AW2 N'],
  "sundquist": ['S AH1 N D K W IH2 S T'],
  "sundry": ['S AH1 N D R IY0'],
  "sundstrand": ['S AH1 N D S T R AE2 N D'],
  "sundstrom": ['S AH1 N D S T R AH0 M'],
  "sundt": ['S AH1 N T'],
  "sundy": ['S AH1 N D IY0'],
  "sunfire": ['S AH1 N F AY2 R'],
  "sunfish": ['S AH1 N F IH2 SH'],
  "sunflower": ['S AH1 N F L AW2 ER0'],
  "sunflowers": ['S AH1 N F L AW2 ER0 Z'],
  "sung": ['S AH1 NG'],
  "sung's": ['S AH1 NG Z'],
  "sungard": ['S AH1 N G AA2 R D'],
  "sungard's": ['S AH1 N G AA2 R D Z'],
  "sunglass": ['S AH1 N G L AE2 S'],
  "sunglasses": ['S AH1 N G L AE2 S IH0 Z'],
  "sungroup": ['S AH1 N G R UW2 P'],
  "suni": ['S UW1 N IY0'],
  "sunia": ['S UW1 N IY0 AH0'],
  "suniga": ['S UW0 N IY1 G AH0'],
  "sunil": ['S UW0 N IH1 L'],
  "sunk": ['S AH1 NG K'],
  "sunken": ['S AH1 NG K AH0 N'],
  "sunkist": ['S AH1 N K IH2 S T'],
  "sunland": ['S AH1 N L AE2 N D'],
  "sunlight": ['S AH1 N L AY2 T'],
  "sunlit": ['S AH1 N L IH2 T'],
  "sunlite": ['S AH1 N L AY2 T'],
  "sunni": ['S UW1 N IY0'],
  "sunning": ['S AH1 N IH0 NG'],
  "sunnis": ['S UH1 N IY2 Z'],
  "sunny": ['S AH1 N IY0'],
  "sunnyside": ['S AH1 N IY0 S AY2 D'],
  "sunnyvale": ['S AH1 N IY0 V EY2 L'],
  "sunobe": ['S UW0 N OW1 B IY0'],
  "sunobe's": ['S UW0 N OW1 B IY0 Z'],
  "sunoco": ['S IH0 N OW1 K OW0'],
  "sunpoint": ['S AH1 N P OY2 N T'],
  "sunrise": ['S AH1 N R AY2 Z'],
  "sunrise's": ['S AH1 N R AY2 Z IH0 Z'],
  "sunroof": ['S AH1 N R UW2 F'],
  "sunroom": ['S AH1 N R UW2 M'],
  "suns": ['S AH1 N Z'],
  "sunscreen": ['S AH0 N S K R IY1 N', 'S AH1 N S K R IY0 N'],
  "sunscreens": ['S AH0 N S K R IY1 N Z', 'S AH1 N S K R IY0 N Z'],
  "sunseri": ['S AH0 N S EH1 R IY0'],
  "sunset": ['S AH1 N S EH2 T'],
  "sunsets": ['S AH1 N S EH2 T S'],
  "sunshine": ['S AH1 N SH AY2 N'],
  "sunshine's": ['S AH1 N SH AY2 N Z'],
  "sunshiny": ['S AH1 N SH AY2 N IY0'],
  "sunspot": ['S AH1 N S P AA2 T'],
  "sunspots": ['S AH1 N S P AA2 T S'],
  "sunstar": ['S AH1 N S T AA2 R'],
  "sunstate": ['S AH1 N S T EY2 T'],
  "sunstates": ['S AH1 N S T EY2 T S'],
  "sunsweet": ['S AH1 N S W IY2 T'],
  "sunsweet's": ['S AH1 N S W IY2 T S'],
  "suntan": ['S AH1 N T AE2 N'],
  "sunter": ['S AH1 N T ER0'],
  "suntory": ['S AH1 N T AO1 R IY0'],
  "suntrust": ['S AH1 N T R AH2 S T'],
  "suntrust's": ['S AH1 N T R AH2 S T S'],
  "sununu": ['S AH0 N UW1 N UW0'],
  "sununu's": ['S AH0 N UW1 N UW0 Z'],
  "sunup": ['S AH1 N AH0 P'],
  "sunward": ['S AH1 N W ER0 D'],
  "sunworld": ['S AH1 N W ER2 L D'],
  "sunworld's": ['S AH1 N W ER2 L D Z'],
  "suny": ['S UW1 N IY2'],
  "suon": ['S UW1 AO0 N'],
  "sup": ['S AH1 P'],
  "supak": ['S UW1 P AH0 K'],
  "supan": ['S UW1 P AH0 N'],
  "super": ['S UW1 P ER0'],
  "super-imposition": ['S UW2 P ER0 IH2 M P AH0 Z IH1 SH AH0 N'],
  "super-position": ['S UW2 P ER0 P AH0 Z IH1 SH AH0 N'],
  "superabrasive": ['S UW2 P ER0 AH0 B R EY1 S IH0 V'],
  "superabrasives": ['S UW2 P ER0 AH0 B R EY1 S IH0 V Z'],
  "superamerica": ['S UW2 P ER0 AH0 M EH1 R IH0 K AH0'],
  "superb": ['S UH0 P ER1 B'],
  "superbar": ['S UW1 P ER0 B AA2 R'],
  "superbly": ['S UW1 P ER0 B L IY0'],
  "superbowl": ['S UW1 P ER0 B OW2 L'],
  "superbowl's": ['S UW1 P ER0 B OW2 L Z'],
  "superbowls": ['S UW1 P ER0 B OW2 L Z'],
  "supercalifragilistic": ['S UW2 P ER0 K AE2 L AH0 F R AE1 JH AH0 L IH2 S T IH0 K'],
  "supercenter": ['S UW1 P ER0 S EH2 N T ER0'],
  "supercenters": ['S UW1 P ER0 S EH2 N T ER0 S'],
  "supercharge": ['S UW2 P ER0 CH AA1 R JH'],
  "supercharged": ['S UW2 P ER0 CH AA1 R JH D'],
  "supercilious": ['S UW2 P ER0 S IH1 L IY0 AH0 S'],
  "supercollider": ['S UW0 P ER0 K AH0 L AY1 D ER0'],
  "supercomputer": ['S UW2 P ER0 K AH0 M P Y UW1 T ER0'],
  "supercomputers": ['S UW2 P ER0 K AH0 M P Y UW1 T ER0 Z'],
  "supercomputing": ['S UW0 P ER0 K AH0 M P Y UW1 T IH0 NG'],
  "superconducting": ['S UW1 P ER0 K AH0 N D AH2 K T IH0 NG'],
  "superconductive": ['S UW0 P ER0 K AH0 N D AH1 K T IH0 V'],
  "superconductivity": ['S UW2 P ER0 K AA2 N D AH2 K T IH1 V AH0 T IY0'],
  "superconductor": ['S UW1 P ER0 K AH0 N D AH2 K T ER0'],
  "superconductors": ['S UW1 P ER0 K AH0 N D AH2 K T ER0 Z'],
  "supercool": ['S UW2 P ER0 K UW1 L'],
  "supercooled": ['S UW2 P ER0 K UW1 L D'],
  "supercut": ['S UW1 P ER0 K AH2 T'],
  "supercuts": ['S UW1 P ER0 K AH2 T S'],
  "superdelegate": ['S UW0 P ER0 D EH1 L AH0 G AH0 T'],
  "superdelegates": ['S UW0 P ER0 D EH1 L AH0 G AH0 T S'],
  "superdome": ['S UW2 P ER0 D OW1 M'],
  "superdot": ['S UW1 P ER0 D AA2 T'],
  "superdrug": ['S UW1 P ER0 D R AH2 G'],
  "superfamily": ['S UW1 P ER0 F AE2 M L IY0'],
  "superfan": ['S UW1 P ER0 F AE2 N'],
  "superfast": ['S UW1 P ER0 F AE2 S T'],
  "superficial": ['S UW2 P ER0 F IH1 SH AH0 L'],
  "superficially": ['S UW1 P ER0 F IH2 SH AH0 L IY0'],
  "superfluidity": ['S UW2 P ER0 F L UW2 IH1 D AH0 T IY0'],
  "superfluous": ['S UW1 P ER0 F L W AH2 S'],
  "superfon": ['S UW1 P ER0 F IH0 N'],
  "superfreighter": ['S UW1 P ER0 F R EY2 T ER0'],
  "superfreighters": ['S UW1 P ER0 F R EY2 T ER0 Z'],
  "superfund": ['S UW2 P ER0 F AH1 N D'],
  "supergiant": ['S UW1 P ER0 JH AY1 AH0 N T'],
  "supergiants": ['S UW2 P ER0 JH AY1 AH0 N T S'],
  "superheated": ['S UW2 P ER0 HH IY1 T IH0 D'],
  "superhero": ['S UW2 P ER0 HH IY1 R OW0'],
  "superheroes": ['S UW2 P ER0 HH IY1 R OW0 Z'],
  "superheterodyne": ['S UW2 P ER0 HH EH1 T ER0 AH0 D AY2 N'],
  "superhighway": ['S UW2 P ER0 HH AY1 W EY2'],
  "superhighways": ['S UW2 P ER0 HH AY1 W EY2 Z'],
  "superhuman": ['S UW2 P ER0 HH Y UW1 M AH0 N'],
  "superimpose": ['S UW2 P ER0 AH0 M P OW1 Z'],
  "superimposed": ['S UW2 P ER0 AH0 M P OW1 Z D'],
  "superintendant": ['S UW2 P ER0 AH0 N T EH1 N D AH0 N T', 'S UW2 P ER0 IH0 N T EH1 N D AH0 N T'],
  "superintendent": ['S UW2 P ER0 AH0 N T EH1 N D AH0 N T', 'S UW2 P ER0 IH0 N T EH1 N D AH0 N T'],
  "superintendent's": ['S UW2 P ER0 IH0 N T EH1 N D AH0 N T S'],
  "superintendents": ['S UW2 P ER0 AH0 N T EH1 N D AH0 N T S', 'S UW2 P ER0 IH0 N T EH1 N D AH0 N T S'],
  "superior": ['S UW0 P IH1 R IY0 ER0'],
  "superior's": ['S UW0 P IY1 R IY0 ER0 Z'],
  "superiority": ['S UW2 P IH0 R IY0 AO1 R IH0 T IY0'],
  "superiors": ['S UW0 P IH1 R IY0 ER0 Z'],
  "superlative": ['S UH0 P ER1 L AH0 T IH0 V'],
  "superlatives": ['S UH0 P ER1 L AH0 T IH0 V Z'],
  "supermac": ['S UW1 P ER0 M AE2 K'],
  "supermajority": ['S UW1 P ER0 M AH0 JH AO2 R IH0 T IY0'],
  "superman": ['S UW1 P ER0 M AH0 N', 'S UW1 P ER0 M AE2 N'],
  "superman's": ['S UW1 P ER0 M AE0 N Z'],
  "supermarket": ['S UW1 P ER0 M AA2 R K IH0 T'],
  "supermarket's": ['S UW1 P ER0 M AA2 R K AH0 T S'],
  "supermarkets": ['S UW1 P ER0 M AA2 R K IH0 T S'],
  "supermassive": ['S UW2 P ER0 M AE1 S IH0 V'],
  "superminicomputer": ['S UW1 P ER0 M IH2 N IY0 K AH0 M P Y UW2 T ER0'],
  "superminicomputers": ['S UW1 P ER0 M IH2 N IY0 K AH0 M P Y UW2 T ER0 Z'],
  "supermodel": ['S UW1 P ER0 M AA2 D AH0 L'],
  "supermodels": ['S UW1 P ER0 M AA2 D AH0 L Z'],
  "supernatural": ['S UW2 P ER0 N AE1 CH ER0 AH0 L'],
  "supernaturalism": ['S UW2 P ER0 N AE1 CH ER0 AH0 L IH2 Z AH0 M'],
  "supernova": ['S UW2 P ER0 N OW1 V AH0'],
  "superoxide": ['S UW2 P ER0 AA1 K S AY2 D'],
  "superpower": ['S UW2 P ER0 P AW1 ER0'],
  "superpowers": ['S UW2 P ER0 P AW1 ER0 Z'],
  "superpowers'": ['S UW1 P ER0 P AW2 R Z'],
  "superpremium": ['S UW2 P ER0 P R IY1 M IY0 AH0 M', 'S UW2 P ER0 P R IY1 M Y AH0 M'],
  "superregional": ['S UW2 P ER0 R IY1 JH AH0 N AH0 L'],
  "superregionals": ['S UW2 P ER0 R IY1 JH AH0 N AH0 L Z'],
  "superregionals'": ['S UW0 P ER0 R IY1 JH AH0 N AH0 L Z'],
  "supers": ['S UW1 P ER0 Z'],
  "supersaver": ['S UW1 P ER0 S EY2 V ER0'],
  "supersecret": ['S UW1 P ER0 S IY2 K R IH0 T'],
  "supersede": ['S UW2 P ER0 S IY1 D'],
  "superseded": ['S UW2 P ER0 S IY1 D IH0 D'],
  "supersedes": ['S UW2 P ER0 S IY1 D Z'],
  "superseding": ['S UW2 P ER0 S IY1 D IH0 NG'],
  "supersonic": ['S UW2 P ER0 S AA1 N IH0 K'],
  "supersonics": ['S UW2 P ER0 S AA1 N IH0 K S'],
  "superstar": ['S UW2 P ER0 S T AA1 R'],
  "superstars": ['S UW2 P ER0 S T AA1 R Z'],
  "superstation": ['S UW2 P ER0 S T EY1 SH AH0 N'],
  "superstition": ['S UW2 P ER0 S T IH1 SH AH0 N'],
  "superstitions": ['S UW2 P ER0 S T IH1 SH AH0 N Z'],
  "superstitious": ['S UW2 P ER0 S T IH1 SH AH0 S'],
  "superstore": ['S UW1 P ER0 S T AO2 R'],
  "superstores": ['S UW1 P ER0 S T AO2 R Z'],
  "superstructure": ['S UW1 P ER0 S T R AH2 K CH ER0'],
  "superstructures": ['S UW1 P ER0 S T R AH2 K CH ER0 Z'],
  "supertanker": ['S UW1 P ER0 T AE2 NG K ER0'],
  "supertankers": ['S UW1 P ER0 T AE2 NG K ER0 Z'],
  "supervalu": ['S UW2 P ER0 V AE1 L Y UW0'],
  "supervalue": ['S UW2 P ER0 V AE1 L Y UW0'],
  "supervise": ['S UW1 P ER0 V AY2 Z'],
  "supervised": ['S UW1 P ER0 V AY2 Z D'],
  "supervises": ['S UW1 P ER0 V AY2 Z IH0 Z'],
  "supervising": ['S UW1 P ER0 V AY2 Z IH0 NG'],
  "supervision": ['S UW2 P ER0 V IH1 ZH AH0 N'],
  "supervisor": ['S UW1 P ER0 V AY2 Z ER0'],
  "supervisor's": ['S UW2 P ER0 V AY1 Z ER0 Z'],
  "supervisors": ['S UW2 P ER0 V AY1 Z ER0 Z'],
  "supervisors'": ['S UW1 P ER0 V AY2 Z ER0 Z'],
  "supervisory": ['S UW2 P ER0 V AY1 Z ER0 IY0'],
  "supervoting": ['S UW2 P ER0 V OW1 T IH0 NG'],
  "superwoman": ['S UW1 P ER0 W UH2 M AH0 N'],
  "superwomen": ['S UW1 P ER0 W IH2 M IH0 N'],
  "superx": ['S UW1 P ER0 EH2 K S'],
  "supine": ['S AH0 P AY1 N', 'S UW1 P AY0 N'],
  "supinski": ['S AH0 P IH1 N S K IY2'],
  "suppa": ['S UW1 P AH0'],
  "supper": ['S AH1 P ER0'],
  "suppers": ['S AH1 P ER0 Z'],
  "suppes": ['S AH1 P S'],
  "supplant": ['S AH0 P L AE1 N T'],
  "supplanted": ['S AH0 P L AE1 N T IH0 D'],
  "supplanting": ['S AH0 P L AE1 N T IH0 NG'],
  "supplants": ['S AH0 P L AE1 N T S'],
  "supple": ['S AH1 P AH0 L'],
  "supplee": ['S AH1 P L IY2'],
  "supplement": ['S AH1 P L AH0 M AH0 N T', 'S AH2 P L AH0 M EH1 N T'],
  "supplemental": ['S AH2 P L AH0 M EH1 N T AH0 L', 'S AH2 P L AH0 M EH1 N AH0 L'],
  "supplementary": ['S AH2 P L AH0 M EH1 N T ER0 IY0', 'S AH2 P L AH0 M EH1 N ER0 IY0', 'S AH2 P L AH0 M EH1 N T R IY0', 'S AH2 P L AH0 M EH1 N CH R IY0'],
  "supplemented": ['S AH2 P L AH0 M EH2 N T IH0 D'],
  "supplementing": ['S AH1 P L AH0 M AH0 N T IH0 NG', 'S AH1 P L AH0 M EH1 N T IH0 NG'],
  "supplements": ['S AH1 P L AH0 M AH0 N T S', 'S AH1 P L AH0 M EH1 N T S'],
  "supplicant": ['S AH1 P L AH0 K AH0 N T'],
  "supplied": ['S AH0 P L AY1 D'],
  "supplier": ['S AH0 P L AY1 ER0'],
  "supplier's": ['S AH0 P L AY1 ER0 Z'],
  "suppliers": ['S AH0 P L AY1 ER0 Z'],
  "suppliers'": ['S AH0 P L AY1 ER0 Z'],
  "supplies": ['S AH0 P L AY1 Z'],
  "supply": ['S AH0 P L AY1'],
  "supply's": ['S AH0 P L AY1 Z'],
  "supplying": ['S AH0 P L AY1 IH0 NG'],
  "support": ['S AH0 P AO1 R T'],
  "supportable": ['S AH0 P AO1 R T AH0 B AH0 L'],
  "supported": ['S AH0 P AO1 R T IH0 D'],
  "supporter": ['S AH0 P AO1 R T ER0'],
  "supporters": ['S AH0 P AO1 R T ER0 Z'],
  "supporting": ['S AH0 P AO1 R T IH0 NG'],
  "supportive": ['S AH0 P AO1 R T IH0 V'],
  "supports": ['S AH0 P AO1 R T S'],
  "suppose": ['S AH0 P OW1 Z'],
  "supposed": ['S AH0 P OW1 Z D'],
  "supposedly": ['S AH0 P OW1 Z AH0 D L IY0'],
  "supposes": ['S AH0 P OW1 Z IH0 Z'],
  "supposing": ['S AH0 P OW1 Z IH0 NG'],
  "supposition": ['S AH2 P AH0 Z IH1 SH AH0 N'],
  "suppositions": ['S AH2 P AH0 Z IH1 SH AH0 N Z'],
  "suppress": ['S AH0 P R EH1 S'],
  "suppressant": ['S AH0 P R EH1 S AH0 N T'],
  "suppressants": ['S AH0 P R EH1 S AH0 N T S'],
  "suppressed": ['S AH0 P R EH1 S T'],
  "suppresses": ['S AH0 P R EH1 S IH0 Z'],
  "suppressing": ['S AH0 P R EH1 S IH0 NG'],
  "suppression": ['S AH0 P R EH1 SH AH0 N'],
  "suppressor": ['S AH0 P R EH1 S ER0'],
  "supra": ['S UW1 P R AH0'],
  "supranational": ['S UW2 P R AH0 N AE1 SH AH0 N AH0 L'],
  "supremacist": ['S UW0 P R EH1 M AH0 S IH0 S T'],
  "supremacists": ['S UW0 P R EH1 M AH0 S IH0 S T S'],
  "supremacy": ['S AH0 P R EH1 M AH0 S IY0'],
  "supreme": ['S AH0 P R IY1 M', 'S ER0 P R IY1 M'],
  "supremely": ['S UW0 P R IY1 M AH0 L IY0'],
  "supremes": ['S UW0 P R IY1 M Z'],
  "suprenant": ['S UW0 P R EY1 N AH0 N T'],
  "suprisingly": ['S UW2 P R AY1 Z IH0 NG L IY0', 'S ER2 P R AY1 Z IH0 NG L IY0'],
  "sur": ['S ER1'],
  "sura": ['S UH1 R AH0'],
  "surace": ['S UH0 R AA1 S EY0'],
  "surat": ['S ER0 AA1 T'],
  "surat's": ['S ER0 AA1 T S'],
  "surbaugh": ['S ER1 B AO0'],
  "surber": ['S ER1 B ER0'],
  "surcease": ['S ER2 S IY1 S'],
  "surcharge": ['S ER0 CH AA1 R JH', 'S ER1 CH AA2 R JH'],
  "surcharges": ['S ER1 CH AA2 R JH IH0 Z'],
  "sure": ['SH UH1 R'],
  "surely": ['SH UH1 R L IY0'],
  "suren": ['S UH1 R AH0 N'],
  "surer": ['SH UH1 R ER0'],
  "sures": ['SH UH1 R Z'],
  "suresh": ['S ER0 EH1 SH'],
  "surest": ['SH UH1 R IH0 S T'],
  "surette": ['S ER0 EH1 T'],
  "surety": ['SH UH1 R AH0 T IY0'],
  "surf": ['S ER1 F'],
  "surf's": ['S ER1 F S'],
  "surface": ['S ER1 F AH0 S'],
  "surfaced": ['S ER1 F IH0 S T'],
  "surfaceness": ['S ER1 F AH0 S N AH0 S'],
  "surfaces": ['S ER1 F AH0 S AH0 Z', 'S ER1 F AH0 S IH0 Z'],
  "surfacing": ['S ER1 F AH0 S IH0 NG'],
  "surfactant": ['S ER0 F AE1 K T AH0 N T'],
  "surfboard": ['S ER1 F B AO2 R D'],
  "surfboards": ['S ER1 F B AO2 R D Z'],
  "surfed": ['S ER1 F T'],
  "surfeit": ['S ER1 F AH0 T'],
  "surfer": ['S ER1 F ER0'],
  "surfers": ['S ER1 F ER0 Z'],
  "surfing": ['S ER1 F IH0 NG'],
  "surge": ['S ER1 JH'],
  "surged": ['S ER1 JH D'],
  "surgeon": ['S ER1 JH AH0 N', 'S ER1 JH IH0 N'],
  "surgeon's": ['S ER1 JH AH0 N Z'],
  "surgeons": ['S ER1 JH AH0 N Z'],
  "surgeons'": ['S ER1 JH IH0 N Z'],
  "surgeries": ['S ER1 JH ER0 IY0 Z'],
  "surgery": ['S ER1 JH ER0 IY0'],
  "surges": ['S ER1 JH AH0 Z', 'S ER1 JH IH0 Z'],
  "surgical": ['S ER1 JH IH0 K AH0 L'],
  "surgical's": ['S ER1 JH IH0 K AH0 L Z'],
  "surgically": ['S ER1 JH IH0 K AH0 L IY0', 'S ER1 JH IH0 K L IY0'],
  "surging": ['S ER1 JH IH0 NG'],
  "suriano": ['S UH2 R IY0 AA1 N OW0'],
  "surinam": ['S UH2 R AH0 N AA1 M'],
  "suriname": ['S ER1 IH0 N AA0 M'],
  "surles": ['S AO1 R AH0 L Z'],
  "surly": ['S ER1 L IY0'],
  "surma": ['S ER1 M AH0'],
  "surman": ['S ER1 M AH0 N'],
  "surmise": ['S ER0 M AY1 Z'],
  "surmised": ['S ER0 M AY1 Z D'],
  "surmises": ['S ER0 M AY1 Z IH0 Z'],
  "surmount": ['S ER0 M AW1 N T'],
  "surmounted": ['S ER0 M AW1 N T IH0 D'],
  "surmounting": ['S ER0 M AW1 N T IH0 NG'],
  "surname": ['S ER1 N EY2 M'],
  "surnames": ['S ER1 N EY2 M Z'],
  "surowiec": ['S ER0 AW1 IY0 K'],
  "surpass": ['S ER0 P AE1 S'],
  "surpassed": ['S ER0 P AE1 S T'],
  "surpasses": ['S ER0 P AE1 S IH0 Z'],
  "surpassing": ['S ER0 P AE1 S IH0 NG'],
  "surplus": ['S ER1 P L AH0 S'],
  "surpluses": ['S ER1 P L AH0 S IH0 Z'],
  "surprenant": ['S ER1 P R IH0 N AH0 N T'],
  "surprise": ['S ER0 P R AY1 Z', 'S AH0 P R AY1 Z'],
  "surprised": ['S ER0 P R AY1 Z D', 'S AH0 P R AY1 Z D'],
  "surprises": ['S ER0 P R AY1 Z IH0 Z', 'S AH0 P R AY1 Z IH0 Z'],
  "surprising": ['S ER0 P R AY1 Z IH0 NG', 'S AH0 P R AY1 Z IH0 NG'],
  "surprisingly": ['S ER0 P R AY1 Z IH0 NG L IY0', 'S AH0 P R AY1 Z IH0 NG L IY0'],
  "surratt": ['S AO1 R AH0 T'],
  "surreal": ['S ER0 IY1 L'],
  "surrealism": ['S ER0 IY1 L IH0 Z AH0 M', 'S ER0 IY1 AH0 L IH2 Z AH0 M'],
  "surrealism's": ['S ER0 IY1 L IH0 Z AH0 M Z', 'S ER0 IY1 AH0 L IH2 Z AH0 M Z'],
  "surrealisms": ['S ER0 IY1 L IH0 Z AH0 M Z', 'S ER0 IY1 AH0 L IH2 Z AH0 M Z'],
  "surrealistic": ['S ER0 IY2 L IH1 S T IH0 K', 'S ER0 IY2 AH0 L IH1 S T IH0 K'],
  "surrebuttal": ['S ER1 IH0 B AH0 T AH0 L', 'S ER1 IY0 B AH0 T AH0 L'],
  "surrell": ['S AO1 R AH0 L'],
  "surrency": ['S AO1 R AH0 N S IY0'],
  "surrender": ['S ER0 EH1 N D ER0'],
  "surrendered": ['S ER0 EH1 N D ER0 D'],
  "surrendering": ['S ER0 EH1 N D ER0 IH0 NG'],
  "surrenders": ['S ER0 EH1 N D ER0 Z'],
  "surreptitious": ['S ER2 AH0 P T IH1 SH AH0 S'],
  "surreptitiously": ['S ER2 AH0 P T IH1 SH AH0 S L IY0'],
  "surrett": ['S AO1 R IH0 T'],
  "surrette": ['S ER0 EH1 T'],
  "surrey": ['S ER1 IY0'],
  "surrogacy": ['S ER1 AH0 G AH0 S IY0'],
  "surrogate": ['S ER1 AH0 G AH0 T', 'S ER1 AH0 G EY2 T'],
  "surrogates": ['S ER1 AH0 G AH0 T S', 'S ER1 AH0 G EY2 T S'],
  "surrogation": ['S ER0 AH0 G EY1 SH AH0 N'],
  "surround": ['S ER0 AW1 N D'],
  "surrounded": ['S ER0 AW1 N D IH0 D'],
  "surrounding": ['S ER0 AW1 N D IH0 NG'],
  "surroundings": ['S ER0 AW1 N D IH0 NG Z'],
  "surrounds": ['S ER0 AW1 N D Z'],
  "surry": ['S ER1 IY0'],
  "surtax": ['S ER1 T AE2 K S'],
  "surtaxes": ['S ER1 T AE2 K S IH0 Z'],
  "surveil": ['S ER0 V EY1 L'],
  "surveillance": ['S ER0 V EY1 L AH0 N S'],
  "surveilling": ['S ER0 V EY1 L IH0 NG'],
  "survey": ['S ER0 V EY1', 'S ER1 V EY2'],
  "survey's": ['S ER0 V EY1 Z', 'S ER1 V EY2 Z'],
  "surveyed": ['S ER0 V EY1 D', 'S ER1 V EY2 D'],
  "surveying": ['S ER0 V EY1 IH0 NG', 'S ER1 V EY2 IH0 NG'],
  "surveymonkey": ['S ER1 V EY2 M AH1 NG K IY0'],
  "surveyor": ['S ER0 V EY1 ER0', 'S ER1 V EY2 ER0'],
  "surveyor's": ['S ER0 V EY1 ER0 Z', 'S ER2 V EY2 ER0 Z'],
  "surveyors": ['S ER0 V EY1 ER0 Z', 'S ER2 V EY2 ER0 Z'],
  "surveys": ['S ER0 V EY1 Z', 'S ER1 V EY2 Z'],
  "survivability": ['S ER0 V AY2 V AH0 B IH1 L IH0 T IY0'],
  "survivable": ['S ER0 V AY1 V AH0 B AH0 L'],
  "survival": ['S ER0 V AY1 V AH0 L'],
  "survivalist": ['S ER0 V AY1 V AH0 L IH0 S T'],
  "survivalists": ['S ER0 V AY1 V AH0 L IH0 S T S'],
  "survive": ['S ER0 V AY1 V'],
  "survived": ['S ER0 V AY1 V D'],
  "survives": ['S ER0 V AY1 V Z'],
  "surviving": ['S ER0 V AY1 V IH0 NG'],
  "survivor": ['S ER0 V AY1 V ER0'],
  "survivor's": ['S ER0 V AY1 V ER0 Z'],
  "survivors": ['S ER0 V AY1 V ER0 Z'],
  "sus": ['S AH1 S'],
  "susa": ['S UW1 S AH0'],
  "susan": ['S UW1 Z AH0 N'],
  "susan's": ['S UW1 Z AH0 N Z'],
  "susana": ['S UW0 S AA1 N AH0'],
  "susann": ['S UW2 Z AE1 N'],
  "susanna": ['S UW0 Z AE1 N AH0'],
  "susannah": ['S UW2 S AE1 N AH0'],
  "susanne": ['S UW2 Z AE1 N'],
  "susceptibility": ['S AH0 S EH2 P T AH0 B IH1 L AH0 T IY0'],
  "susceptible": ['S AH0 S EH1 P T AH0 B AH0 L'],
  "susette": ['S UW2 Z EH1 T'],
  "sushi": ['S UW1 SH IY0'],
  "susi": ['S UW1 S IY0'],
  "susie": ['S UW1 Z IY0'],
  "susie's": ['S UW1 Z IY0 Z'],
  "suski": ['S AH1 S K IY0'],
  "suskind": ['S AH1 S K IH0 N D'],
  "susko": ['S AH1 S K OW0'],
  "susman": ['S AH1 S M AH0 N'],
  "susong": ['S AH1 S AO0 NG'],
  "suspect": ['S AH0 S P EH1 K T', 'S AH1 S P EH2 K T'],
  "suspect's": ['S AH0 S P EH1 K T S'],
  "suspected": ['S AH0 S P EH1 K T IH0 D'],
  "suspecting": ['S AH0 S P EH1 K T IH0 NG'],
  "suspects": ['S AH0 S P EH1 K T S', 'S AH1 S P EH2 K T S'],
  "suspects'": ['S AH1 S P EH2 K T S'],
  "suspend": ['S AH0 S P EH1 N D'],
  "suspended": ['S AH0 S P EH1 N D IH0 D'],
  "suspender": ['S AH0 S P EH1 N D ER0'],
  "suspenders": ['S AH0 S P EH1 N D ER0 Z'],
  "suspending": ['S AH0 S P EH1 N D IH0 NG'],
  "suspends": ['S AH0 S P EH1 N D Z'],
  "suspense": ['S AH0 S P EH1 N S'],
  "suspenseful": ['S AH0 S P EH1 N S F AH0 L'],
  "suspension": ['S AH0 S P EH1 N SH AH0 N'],
  "suspensions": ['S AH0 S P EH1 N SH AH0 N Z'],
  "suspicion": ['S AH0 S P IH1 SH AH0 N'],
  "suspicions": ['S AH0 S P IH1 SH AH0 N Z'],
  "suspicious": ['S AH0 S P IH1 SH AH0 S'],
  "suspiciously": ['S AH0 S P IH1 SH AH0 S L IY0'],
  "susquehanna": ['S UW2 S K W EH0 HH AE1 N AH0'],
  "susquehanna's": ['S UW2 S K W EH0 HH AE1 N AH0 Z'],
  "suss": ['S AH1 S'],
  "susser": ['S AH1 S ER0'],
  "sussex": ['S AH1 S IH0 K S'],
  "susskind": ['S AH1 S K IH0 N D'],
  "sussman": ['S AH1 S M AH0 N'],
  "susswein": ['S AH1 S W IY2 N', 'S AH1 S W AY2 N'],
  "sustain": ['S AH0 S T EY1 N'],
  "sustainability": ['S AH0 S T EY2 N AH0 B IH1 L IH0 T IY0'],
  "sustainable": ['S AH0 S T EY1 N AH0 B AH0 L'],
  "sustained": ['S AH0 S T EY1 N D'],
  "sustaining": ['S AH0 S T EY1 N IH0 NG'],
  "sustains": ['S AH0 S T EY1 N Z'],
  "sustaita": ['S UW0 S T EY1 T AH0'],
  "sustenance": ['S AH1 S T AH0 N AH0 N S'],
  "susteren": ['S AH0 S T EH1 R AH0 N'],
  "susumu": ['S UW2 S UW1 M UW0'],
  "susy": ['S UW1 Z IY0'],
  "sutch": ['S AH1 CH'],
  "sutcliff": ['S AH1 T K L IH0 F'],
  "sutcliffe": ['S AH1 T K L IH0 F'],
  "suter": ['S UW1 T ER0'],
  "sutera": ['S UW0 T EH1 R AH0'],
  "sutfin": ['S AH1 T F IH0 N'],
  "suthard": ['S AH1 TH ER0 D'],
  "suther": ['S AH1 DH ER0'],
  "sutherland": ['S AH1 DH ER0 L AH0 N D'],
  "sutherland's": ['S AH1 DH ER0 L AH0 N D Z'],
  "sutherlin": ['S AH1 TH ER0 L IH0 N', 'S AH1 DH ER0 L IH0 N'],
  "suthers": ['S AH1 DH ER0 Z'],
  "sutley": ['S AH1 T L IY0'],
  "sutliff": ['S AH1 T L IH0 F'],
  "sutner": ['S AH1 T N ER0'],
  "sutnick": ['S AH1 T N IH0 K'],
  "suto": ['S UW1 T OW0'],
  "sutor": ['S UW1 T ER0'],
  "sutphen": ['S AH1 T F AH0 N'],
  "sutphin": ['S AH1 T F IH0 N'],
  "sutro": ['S UW1 T R OW0'],
  "sutro's": ['S UW1 T R OW0 Z'],
  "sutter": ['S AH1 T ER0'],
  "suttle": ['S AH1 T AH0 L'],
  "suttles": ['S AH1 T AH0 L Z'],
  "suttmeier": ['S AH1 T M AY2 R'],
  "sutton": ['S AH1 T AH0 N'],
  "sutton's": ['S AH1 T AH0 N Z'],
  "suture": ['S UW1 CH ER0'],
  "sutured": ['S UW1 CH ER0 D'],
  "sutures": ['S UW1 CH ER0 Z'],
  "suturing": ['S UW1 CH ER0 IH0 NG'],
  "suu": ['EH1 S Y UW1 Y UW1', 'S UW1'],
  "suv": ['EH2 S Y UW2 V IY1'],
  "suv's": ['EH2 S Y UW2 V IY1 Z'],
  "suva": ['S UW1 V AH0'],
  "suvs": ['EH2 S Y UW2 V IY1 Z'],
  "suydam": ['S AY1 D AH0 M'],
  "suzanna": ['S UW2 Z AE1 N AH0'],
  "suzanne": ['S UW0 Z AE1 N'],
  "suzette": ['S UW2 Z EH1 T'],
  "suzhou": ['S UW1 ZH UW2'],
  "suzie": ['S AH1 Z IY0'],
  "suzman": ['S UW1 Z M AH0 N'],
  "suzuana": ['S UW0 Z UW0 AA1 N AH0'],
  "suzuki": ['S AH0 Z UW1 K IY0'],
  "suzy": ['S UW1 Z IY0'],
  "suzy's": ['S UW1 Z IY0 Z'],
  "svec": ['S V EH1 K'],
  "svehla": ['S V EH1 L AH0'],
  "svelte": ['S V EH1 L T'],
  "sven": ['S V EH1 N'],
  "svendsen": ['S V EH1 N D S AH0 N'],
  "svensk": ['S V EH1 N S K'],
  "svenska": ['S V EH1 N S K AH0'],
  "svenson": ['S V EH1 N S AH0 N'],
  "svensson": ['S V EH1 N S AH0 N'],
  "sverdlovsk": ['S V ER1 D L AA0 V S K'],
  "sverige": ['S V EH1 R IH0 JH'],
  "svetlana": ['S V EH2 T L AA1 N AH0'],
  "svetlik": ['S V EH1 T L IH0 K'],
  "svitak": ['S V IH1 T AH0 K'],
  "svizzera": ['S V IH0 Z EH1 R AH0'],
  "svoboda": ['S V OW0 B OW1 D AH0'],
  "svoray": ['S V AO1 R EY2'],
  "svp": ['S V IY1 P IY1'],
  "swab": ['S W AA1 B'],
  "swabs": ['S W AA1 B Z'],
  "swaby": ['S W AA1 B IY0'],
  "swackhamer": ['S W AO1 K AE2 M ER0'],
  "swader": ['S W EY1 D ER0'],
  "swadley": ['S W AA1 D L IY0'],
  "swafford": ['S W AA1 F ER0 D'],
  "swager": ['S W EY1 G ER0'],
  "swagerty": ['S W AE1 JH ER0 T IY0'],
  "swaggart": ['S W AE1 G ER0 T'],
  "swagger": ['S W AE1 G ER0'],
  "swaggering": ['S W AE1 G ER0 IH0 NG'],
  "swaggerty": ['S W AE1 G ER0 T IY0'],
  "swahili": ['S W AA0 HH IY1 L IY0'],
  "swailes": ['S W EY1 L Z'],
  "swails": ['S W EY1 L Z'],
  "swaim": ['S W EY1 M'],
  "swain": ['S W EY1 N'],
  "swaine": ['S W EY1 N'],
  "swainston": ['S W EY1 N S T AH0 N'],
  "swales": ['S W EY1 L Z'],
  "swalley": ['S W AO1 L IY0'],
  "swallow": ['S W AA1 L OW0', 'S W AO1 L OW0'],
  "swallowed": ['S W AA1 L OW0 D'],
  "swallowing": ['S W AA1 L OW0 IH0 NG'],
  "swallows": ['S W AA1 L OW0 Z'],
  "swam": ['S W AE1 M'],
  "swami": ['S W AA1 M IY0'],
  "swaminathan": ['S W AA2 M IH0 N AA1 TH AH0 N'],
  "swamp": ['S W AA1 M P', 'S W AO1 M P'],
  "swampbuster": ['S W AA1 M P B AH2 S T ER0'],
  "swamped": ['S W AO1 M P T'],
  "swamper": ['S W AA1 M P ER0'],
  "swampers": ['S W AA1 M P ER0 Z'],
  "swamping": ['S W AA1 M P IH0 NG'],
  "swamps": ['S W AA1 M P S', 'S W AO1 M P S'],
  "swampy": ['S W AA1 M P IY0'],
  "swan": ['S W AA1 N', 'S W AO1 N'],
  "swanberg": ['S W AA1 N B ER0 G'],
  "swanda": ['S W AA1 N D AH0'],
  "swander": ['S W AA1 N D ER0'],
  "swanee": ['S W AA1 N IY1'],
  "swaner": ['S W AO1 N ER0'],
  "swaney": ['S W AO1 N IY0'],
  "swanger": ['S W AO1 NG ER0'],
  "swango": ['S W AA1 NG G OW0'],
  "swanigan": ['S W AA1 N IH0 G AH0 N'],
  "swank": ['S W AE1 NG K'],
  "swanke": ['S W AO1 NG K'],
  "swanky": ['S W AA1 NG K IY0'],
  "swann": ['S W AA1 N'],
  "swann's": ['S W AA1 N Z'],
  "swanner": ['S W AA1 N ER0'],
  "swans": ['S W AA1 N Z', 'S W AO1 N Z'],
  "swanson": ['S W AA1 N S AH0 N'],
  "swanstrom": ['S W AA1 N S T R AH0 M'],
  "swantek": ['S W AO1 N T IH0 K'],
  "swanton": ['S W AA1 N T AH0 N'],
  "swanzy": ['S W AA1 N Z IY0'],
  "swap": ['S W AA1 P'],
  "swape": ['S W EY1 P'],
  "swapes": ['S W EY1 P S'],
  "swapo": ['S W AA1 P OW0'],
  "swapo's": ['S W AA1 P OW0 Z'],
  "swapp": ['S W AA1 P'],
  "swapped": ['S W AA1 P T', 'S W AO1 P T'],
  "swapping": ['S W AA1 P IH0 NG'],
  "swaps": ['S W AA1 P S'],
  "sward": ['S W AO1 R D'],
  "swaringen": ['S W EH1 R IH0 NG AH0 N'],
  "swarm": ['S W AO1 R M'],
  "swarmed": ['S W AO1 R M D'],
  "swarming": ['S W AO1 R M IH0 NG'],
  "swarms": ['S W AO1 R M Z'],
  "swarner": ['S W AO1 R N ER0'],
  "swarovski": ['S W AA0 R AA1 V S K IY0'],
  "swart": ['S W AO1 R T'],
  "swarthmore": ['S W AO1 R TH M AO2 R'],
  "swarthout": ['S W AO1 R TH AW2 T'],
  "swarthy": ['S W AO1 R DH IY0', 'S W AO1 R TH IY0'],
  "swartley": ['S W AO1 R T L IY0'],
  "swartout": ['S W AO1 R T AH0 T'],
  "swarts": ['S W AO1 R T S'],
  "swartwood": ['S W AO1 R T W UH2 D'],
  "swartwout": ['S W AO1 R T W AW0 T'],
  "swartz": ['S W AO1 R T S', 'SH W AO1 R T S'],
  "swartzbaugh": ['S W AO1 R T S B AA0'],
  "swartzendruber": ['S W AO1 R T S AH0 N D R UW0 B ER0'],
  "swartzentruber": ['S W AO1 R T S AH0 N T R UW0 B ER0'],
  "swartzlander": ['S W AO1 R T S L AE2 N D ER0'],
  "swartzwelder": ['S W AO1 R T S W EH2 L D ER0'],
  "swasey": ['S W AA1 Z IY0'],
  "swashbuckling": ['S W AA1 SH B AH2 K L IH0 NG'],
  "swastika": ['S W AA1 S T IH0 K AH0'],
  "swastikas": ['S W AA1 S T IH0 K AH0 Z'],
  "swat": ['S W AA1 T'],
  "swatch": ['S W AA1 CH'],
  "swatched": ['S W AA1 CH T'],
  "swatches": ['S W AA1 CH AH0 Z', 'S W AA1 CH IH0 Z'],
  "swatching": ['S W AA1 CH IH0 NG'],
  "swatek": ['S W AO1 T IH0 K'],
  "swath": ['S W AA1 TH'],
  "swathe": ['S W AA1 DH', 'S W EY1 DH'],
  "swathed": ['S W AA1 DH D'],
  "swatow": ['S W AA1 T OW2'],
  "swatted": ['S W AA1 T IH0 D'],
  "swatzell": ['S W AO1 T Z AH0 L'],
  "swauger": ['S W AW1 G ER0'],
  "swavely": ['S W EY1 V L IY0'],
  "sway": ['S W EY1'],
  "swayed": ['S W EY1 D'],
  "swaying": ['S W EY1 IH0 NG'],
  "swayne": ['S W EY1 N'],
  "sways": ['S W EY1 Z'],
  "swayze": ['S W EY1 Z', 'S W EY1 Z IY0'],
  "swazi": ['S W AA1 Z IY0'],
  "swaziland": ['S W AA1 Z IH0 L AH0 N D'],
  "sweaney": ['S W IY1 N IY0'],
  "sweany": ['S W IY1 N IY0'],
  "swear": ['S W EH1 R'],
  "swearengen": ['S W IH1 R IH0 NG AH0 N'],
  "swearengin": ['S W EH1 R IH0 NG AH0 N'],
  "swearing": ['S W EH1 R IH0 NG'],
  "swearingen": ['S W EH1 R IH0 NG AH0 N'],
  "swearingin": ['S W EH1 R IH0 NG AH0 N'],
  "swears": ['S W EH1 R Z'],
  "sweat": ['S W EH1 T'],
  "sweated": ['S W EH1 T IH0 D'],
  "sweater": ['S W EH1 T ER0'],
  "sweaters": ['S W EH1 T ER0 Z'],
  "sweatin'": ['S W EH1 T IH0 N'],
  "sweating": ['S W EH1 T IH0 NG'],
  "sweatman": ['S W IY1 T M AH0 N'],
  "sweatpants": ['S W EH1 T P AE2 N T S'],
  "sweats": ['S W EH1 T S'],
  "sweatshirt": ['S W EH1 T SH ER2 T'],
  "sweatshirts": ['S W EH1 T SH ER2 T S'],
  "sweatshop": ['S W EH1 T SH AA2 P'],
  "sweatshops": ['S W EH1 T SH AA2 P S'],
  "sweatsuit": ['S W EH1 T S UW2 T'],
  "sweatt": ['S W IY1 T'],
  "sweaty": ['S W EH1 T IY0'],
  "sweazy": ['S W IY1 Z IY0'],
  "swecker": ['S W EH1 K ER0'],
  "swed": ['S W EH1 D'],
  "sweda": ['S W IY1 D AH0'],
  "swedberg": ['S W EH1 D B ER0 G'],
  "swede": ['S W IY1 D'],
  "sweden": ['S W IY1 D AH0 N'],
  "sweden's": ['S W IY1 D AH0 N Z'],
  "swedes": ['S W IY1 D Z'],
  "swedish": ['S W IY1 D IH0 SH'],
  "swedlund": ['S W EH1 D L AH0 N D'],
  "sweeden": ['S W IY1 D AH0 N'],
  "sween": ['S W IY1 N'],
  "sweeney": ['S W IY1 N IY0'],
  "sweeny": ['S W IY1 N IY0'],
  "sweep": ['S W IY1 P'],
  "sweeper": ['S W IY1 P ER0'],
  "sweepers": ['S W IY1 P ER0 Z'],
  "sweeping": ['S W IY1 P IH0 NG'],
  "sweeps": ['S W IY1 P S'],
  "sweepstake": ['S W IY1 P S T EY2 K'],
  "sweepstakes": ['S W IY1 P S T EY2 K S'],
  "sweers": ['S W IH1 R Z'],
  "sweet": ['S W IY1 T'],
  "sweeten": ['S W IY1 T AH0 N'],
  "sweetened": ['S W IY1 T AH0 N D'],
  "sweetener": ['S W IY1 T AH0 N ER0', 'S W IY1 T N ER0'],
  "sweeteners": ['S W IY1 T AH0 N ER0 Z', 'S W IY1 T N ER0 Z'],
  "sweetening": ['S W IY1 T AH0 N IH0 NG', 'S W IY1 T N IH0 NG'],
  "sweetens": ['S W IY1 T AH0 N Z'],
  "sweeter": ['S W IY1 T ER0'],
  "sweetest": ['S W IY1 T AH0 S T'],
  "sweetheart": ['S W IY1 T HH AA2 R T'],
  "sweethearts": ['S W IY1 T HH AA2 R T S'],
  "sweetie": ['S W IY1 T IY0'],
  "sweeting": ['S W IY1 T IH0 NG'],
  "sweetland": ['S W IY1 T L AH0 N D'],
  "sweetly": ['S W IY1 T L IY0'],
  "sweetman": ['S W IY1 T M AH0 N'],
  "sweetness": ['S W IY1 T N AH0 S'],
  "sweeton": ['S W IY1 T AH0 N'],
  "sweets": ['S W IY1 T S'],
  "sweetser": ['S W IY1 T S ER0'],
  "sweetwater": ['S W IY1 T W AA2 T ER0'],
  "sweezey": ['S W IY1 Z IY0'],
  "sweezy": ['S W IY1 Z IY0'],
  "sweger": ['S W IY1 G ER0'],
  "swehla": ['S W EH1 L AH0'],
  "sweig": ['S W AY1 G'],
  "sweigard": ['S W AY1 G ER0 D'],
  "sweigart": ['S W IY1 G AA0 R T'],
  "sweigert": ['S W AY1 G ER0 T'],
  "sweitzer": ['S W AY1 T Z ER0'],
  "swell": ['S W EH1 L'],
  "swelled": ['S W EH1 L D'],
  "swelling": ['S W EH1 L IH0 NG'],
  "swells": ['S W EH1 L Z'],
  "swelter": ['S W EH1 L T ER0'],
  "sweltered": ['S W EH1 L T ER0 D'],
  "sweltering": ['S W EH1 L T ER0 IH0 NG'],
  "swelters": ['S W EH1 L T ER0 Z'],
  "sweltry": ['S W EH1 L T R IY0'],
  "swem": ['S W EH1 M'],
  "swendsen": ['S W EH1 N D S AH0 N'],
  "swenet": ['S W EH1 N AH0 T'],
  "sweney": ['S W EH1 N IY0'],
  "swensen": ['S W EH1 N S AH0 N'],
  "swensen's": ['S W EH1 N S AH0 N Z'],
  "swenson": ['S W EH1 N S AH0 N'],
  "swept": ['S W EH1 P T'],
  "swerdlow": ['S W ER1 D L AW0'],
  "swergold": ['S W ER1 G OW2 L D'],
  "swerling": ['S W ER1 L IH0 NG'],
  "swerve": ['S W ER1 V'],
  "swerved": ['S W ER1 V D'],
  "swete": ['S W IY1 T'],
  "swetland": ['S W EH1 T L AH0 N D'],
  "swetnam": ['S W EH1 T N AH0 M'],
  "swett": ['S W EH1 T'],
  "swetz": ['S W EH1 T S'],
  "swezey": ['S W EH1 Z IY0'],
  "swiatek": ['S V IY0 AA1 T EH0 K'],
  "swiatkowski": ['S V IY0 AH0 T K AO1 F S K IY0'],
  "swicegood": ['S W IH1 S IH0 G UH0 D'],
  "swick": ['S W IH1 K'],
  "swickard": ['S W IH1 K ER0 D'],
  "swider": ['S W AY1 D ER0'],
  "swiderski": ['S V IH0 D ER1 S K IY0', 'S W IH0 D ER1 S K IY0'],
  "swiech": ['S W IY1 CH'],
  "swier": ['S W AY1 ER0'],
  "swift": ['S W IH1 F T'],
  "swift's": ['S W IH1 F T S'],
  "swifter": ['S W IH1 F T ER0'],
  "swiftest": ['S W IH1 F T AH0 S T'],
  "swiftly": ['S W IH1 F T L IY0'],
  "swiftness": ['S W IH1 F T N AH0 S'],
  "swifts": ['S W IH1 F T S'],
  "swig": ['S W IH1 G'],
  "swigart": ['S W IH1 G AA2 R T'],
  "swiger": ['S W AY1 G ER0'],
  "swigert": ['S W IH1 G ER0 T'],
  "swiggum": ['S W IH1 G AH0 M'],
  "swihart": ['S W IH1 HH AA0 R T'],
  "swill": ['S W IH1 L'],
  "swilley": ['S W IH1 L IY0'],
  "swilling": ['S W IH1 L IH0 NG'],
  "swillinger": ['S W IH1 L IH0 NG ER0'],
  "swim": ['S W IH1 M'],
  "swimmer": ['S W IH1 M ER0'],
  "swimmers": ['S W IH1 M ER0 Z'],
  "swimming": ['S W IH1 M IH0 NG'],
  "swims": ['S W IH1 M Z'],
  "swimsuit": ['S W IH1 M S UW2 T'],
  "swimsuits": ['S W IH1 M S UW2 T S'],
  "swimwear": ['S W IH1 M W EH2 R'],
  "swinburne's": ['S W IH1 N B ER0 N Z'],
  "swinburnes": ['S W IH1 N B ER0 N Z'],
  "swindall": ['S W IH1 N D AH0 L'],
  "swindell": ['S W IH1 N D AH0 L'],
  "swindle": ['S W IH1 N D AH0 L'],
  "swindled": ['S W IH1 N D AH0 L D'],
  "swindlehurst": ['S W IH1 N D AH0 L HH ER0 S T'],
  "swindler": ['S W IH1 N D AH0 L ER0', 'S W IH1 N D L ER0'],
  "swindlers": ['S W IH1 N D L ER0 Z'],
  "swindles": ['S W IH1 N D AH0 L Z'],
  "swindling": ['S W IH1 N D L IH0 NG'],
  "swine": ['S W AY1 N'],
  "swineford": ['S W IH1 N IH0 F ER0 D', 'S W AY1 N IH0 F ER0 D'],
  "swinehart": ['S W AY1 N HH AA2 R T'],
  "swiney": ['S W AY1 N IY0'],
  "swinford": ['S W IH1 N F ER0 D'],
  "swing": ['S W IH1 NG'],
  "swinger": ['S W IH1 NG ER0'],
  "swingers": ['S W IH1 NG ER0 Z'],
  "swinging": ['S W IH1 NG IH0 NG'],
  "swingle": ['S W IH1 NG G AH0 L'],
  "swingler": ['S W IH1 NG G AH0 L ER0', 'S W IH1 NG G L ER0'],
  "swingley": ['S W IH1 NG G L IY0'],
  "swings": ['S W IH1 NG Z'],
  "swink": ['S W IH1 NG K'],
  "swinney": ['S W IH1 N IY0'],
  "swinson": ['S W IH1 N S AH0 N'],
  "swint": ['S W IH1 N T'],
  "swinton": ['S W IH1 N T AH0 N'],
  "swipe": ['S W AY1 P'],
  "swiped": ['S W AY1 P T'],
  "swipes": ['S W AY1 P S'],
  "swiping": ['S W AY1 P IH0 NG'],
  "swire": ['S W AY1 R'],
  "swirl": ['S W ER1 L'],
  "swirled": ['S W ER1 L D'],
  "swirling": ['S W ER1 L IH0 NG'],
  "swirls": ['S W ER1 L Z'],
  "swirsky": ['S W ER1 S K IY0'],
  "swish": ['S W IH1 SH'],
  "swished": ['S W IH1 SH T'],
  "swisher": ['S W IH1 SH ER0'],
  "swishes": ['S W IH1 SH IH0 Z'],
  "swiss": ['S W IH1 S'],
  "swissair": ['S W IH0 S EH1 R'],
  "swisshelm": ['S W IH1 SH IH0 L M', 'S W IH1 S HH EH0 L M'],
  "swistak": ['S W IH1 S T AH0 K'],
  "switaj": ['S W IH0 T AY1'],
  "switala": ['S W IH0 T AA1 L AH0'],
  "switalski": ['S W IH0 T AA1 L S K IY0'],
  "switch": ['S W IH1 CH'],
  "switchblade": ['S W IH1 CH B L EY2 D'],
  "switchblades": ['S W IH1 CH B L EY2 D Z'],
  "switchboard": ['S W IH1 CH B AO2 R D'],
  "switchboards": ['S W IH1 CH B AO2 R D Z'],
  "switched": ['S W IH1 CH T'],
  "switcher": ['S W IH1 CH ER0'],
  "switchers": ['S W IH1 CH ER0 Z'],
  "switches": ['S W IH1 CH AH0 Z', 'S W IH1 CH IH0 Z'],
  "switching": ['S W IH1 CH IH0 NG'],
  "switzer": ['S W IH1 T S ER0'],
  "switzerland": ['S W IH1 T S ER0 L AH0 N D'],
  "switzerland's": ['S W IH1 T S ER0 L AH0 N D Z'],
  "swivel": ['S W IH1 V AH0 L'],
  "swiveled": ['S W IH1 V AH0 L D'],
  "swiveling": ['S W IH1 V AH0 L IH0 NG', 'S W IH1 V L IH0 NG'],
  "swivels": ['S W IH1 V AH0 L Z'],
  "swoboda": ['S W OW0 B OW1 D AH0'],
  "swofford": ['S W AA1 F ER0 D'],
  "swogger": ['S W AA1 G ER0'],
  "swollen": ['S W OW1 L AH0 N'],
  "swonger": ['S W AO1 NG ER0'],
  "swoon": ['S W UW1 N'],
  "swooned": ['S W UW1 N D'],
  "swooning": ['S W UW1 N IH0 NG'],
  "swoop": ['S W UW1 P'],
  "swoope": ['S W UW1 P'],
  "swooped": ['S W UW1 P T'],
  "swooping": ['S W UW1 P IH0 NG'],
  "swoops": ['S W UW1 P S'],
  "swoosh": ['S W UW1 SH'],
  "swooshed": ['S W UW1 SH T'],
  "swope": ['S W OW1 P'],
  "swopes": ['S W OW1 P S'],
  "swor": ['S W ER1'],
  "sword": ['S AO1 R D'],
  "swordfish": ['S AO1 R D F IH2 SH'],
  "swordfish's": ['S AO1 R D F IH2 SH IH0 Z'],
  "swordlike": ['S AO1 R D L AY2 K'],
  "swordplay": ['S AO1 R D P L EY2'],
  "swordplays": ['S AO1 R D P L EY2 Z'],
  "swords": ['S AO1 R D Z'],
  "swore": ['S W AO1 R'],
  "sworn": ['S W AO1 R N'],
  "swoveland": ['S W OW1 V L AH0 N D'],
  "swoyer": ['S W OY1 ER0'],
  "swum": ['S W AH1 M'],
  "swung": ['S W AH1 NG'],
  "swyers": ['S W AY1 ER0 Z'],
  "swygert": ['S W IH1 G ER0 T'],
  "swyto": ['S W IY1 T OW0'],
  "sy": ['S AY1'],
  "sybase": ['S AY1 B EY2 S'],
  "sybase's": ['S AY1 B EY1 S IH0 Z'],
  "sybert": ['S IH1 B ER0 T'],
  "sybil": ['S IH1 B IH0 L'],
  "sybilla": ['S IH0 B IH1 L AH0'],
  "sybille": ['S IH1 B IH0 L'],
  "sybron": ['S IH1 B R AH0 N'],
  "sycamore": ['S IH1 K AH0 M AO2 R'],
  "sycara": ['S IH0 K AA1 R AH0'],
  "sycophantic": ['S IH2 K AH0 F AE1 N T IH0 K'],
  "syd": ['S IH1 D'],
  "sydell": ['S AY2 D EH1 L'],
  "syders": ['S AY1 D ER0 Z'],
  "syders'": ['S AY1 D ER0 Z'],
  "sydney": ['S IH1 D N IY0'],
  "sydney's": ['S IH1 D N IY0 Z'],
  "sydnor": ['S IH1 D N ER0'],
  "sydow": ['S IH1 D OW0'],
  "syed": ['S AY1 D'],
  "syers": ['S AY1 ER0 Z'],
  "syfert": ['S IH1 F ER0 T'],
  "sygma": ['S IH1 G M AH0'],
  "sykes": ['S AY1 K S'],
  "sykora": ['S IH0 K AO1 R AH0'],
  "syler": ['S AY1 L ER0'],
  "sylla": ['S IH1 L AH0'],
  "syllabic": ['S AH0 L AE1 B IH0 K'],
  "syllable": ['S IH1 L AH0 B AH0 L'],
  "syllables": ['S IH1 L AH0 B AH0 L Z'],
  "syllabus": ['S IH1 L AH0 B AH0 S'],
  "sylmar": ['S IH1 L M AA0 R'],
  "sylph": ['S IH1 L F'],
  "sylphide": ['S IH1 L F IH0 D'],
  "sylva": ['S IH1 L V AH0'],
  "sylvain": ['S IH0 L V EY1 N'],
  "sylvan": ['S IH1 L V AH0 N'],
  "sylvana": ['S IH0 L V AE1 N AH0'],
  "sylvania": ['S IH0 L V EY1 N IY0 AH0'],
  "sylvanus": ['S IH1 L V AH0 N IH0 S'],
  "sylvest": ['S IH0 L V EH1 S T'],
  "sylvester": ['S IH0 L V EH1 S T ER0'],
  "sylvestre": ['S IH0 L V EH1 S T ER0'],
  "sylvia": ['S IH1 L V IY0 AH0'],
  "sylvia's": ['S IH1 L V IY0 AH0 Z'],
  "sylvie": ['S IH1 L V IY0'],
  "sylvio": ['S IH1 L V IY0 OW0'],
  "sylvio's": ['S IH1 L V IY0 OW0 Z'],
  "sylvite": ['S IH1 L V AY2 T'],
  "sym": ['S IH1 M'],
  "symanski": ['S IH0 M AE1 N S K IY0'],
  "symantec": ['S IH0 M AE1 N T EH2 K'],
  "symantec's": ['S IH0 M AE1 N T EH2 K S'],
  "symbion": ['S IH1 M B IY0 AH0 N'],
  "symbion's": ['S IH1 M B IY0 AH0 N Z'],
  "symbiosis": ['S IH2 M B AY0 OW1 S AH0 S'],
  "symbiotic": ['S IH2 M B IY0 AA1 T IH0 K'],
  "symbol": ['S IH1 M B AH0 L'],
  "symbol's": ['S IH1 M B AH0 L Z'],
  "symbolic": ['S IH0 M B AA1 L IH0 K'],
  "symbolically": ['S IH0 M B AA1 L IH0 K AH0 L IY0', 'S IH0 M B AA1 L IH0 K L IY0'],
  "symbolics": ['S IH0 M B AA1 L IH0 K S'],
  "symbolism": ['S IH1 M B AH0 L IH2 Z AH0 M'],
  "symbolists": ['S IH1 M B AH0 L AH0 S T S', 'S IH1 M B AH0 L IH0 S T S'],
  "symbolize": ['S IH1 M B AH0 L AY2 Z'],
  "symbolized": ['S IH1 M B AH0 L AY2 Z D'],
  "symbolizes": ['S IH1 M B AH0 L AY2 Z IH0 Z'],
  "symbolizing": ['S IH1 M B AH0 L AY2 Z IH0 NG'],
  "symbols": ['S IH1 M B AH0 L Z'],
  "syme": ['S AY1 M'],
  "symes": ['S AY1 M Z'],
  "symington": ['S IH1 M IH0 NG T AH0 N'],
  "symmes": ['S IH1 M Z'],
  "symmetric": ['S AH0 M EH1 T R IH0 K'],
  "symmetrical": ['S AH0 M EH1 T R IH0 K AH0 L'],
  "symmetrically": ['S AH0 M EH1 T R IH0 K L IY0'],
  "symmetry": ['S IH1 M AH0 T R IY0'],
  "symmonds": ['S IH1 M AH0 N D Z'],
  "symms": ['S IH1 M Z'],
  "symon": ['S IH1 M AH0 N'],
  "symonds": ['S IH1 M AH0 N D Z'],
  "symons": ['S IH1 M AH0 N Z'],
  "sympathetic": ['S IH2 M P AH0 TH EH1 T IH0 K'],
  "sympathetically": ['S IH2 M P AH0 TH EH1 T IH0 K AH0 L IY0', 'S IH2 M P AH0 TH EH1 T IH0 K L IY0'],
  "sympathies": ['S IH1 M P AH0 TH IY0 Z'],
  "sympathize": ['S IH1 M P AH0 TH AY2 Z'],
  "sympathized": ['S IH1 M P AH0 TH AY2 Z D'],
  "sympathizer": ['S IH1 M P AH0 TH AY2 Z ER0'],
  "sympathizers": ['S IH1 M P AH0 TH AY2 Z ER0 Z'],
  "sympathizes": ['S IH1 M P AH0 TH AY2 Z IH0 Z'],
  "sympathizing": ['S IH1 M P AH0 TH AY2 Z IH0 NG'],
  "sympathy": ['S IH1 M P AH0 TH IY0'],
  "symphonic": ['S IH0 M F AA1 N IH0 K'],
  "symphonies": ['S IH1 M F AH0 N IY0 Z'],
  "symphony": ['S IH1 M F AH0 N IY0'],
  "symphony's": ['S IH1 M F AH0 N IY0 Z'],
  "symposium": ['S IH0 M P OW1 Z IY0 AH0 M'],
  "symposiums": ['S IH0 M P OW1 Z IY0 AH0 M Z'],
  "sympson": ['S IH1 M P S AH0 N'],
  "symptom": ['S IH1 M P T AH0 M'],
  "symptomatic": ['S IH2 M P T AH0 M AE1 T IH0 K'],
  "symptoms": ['S IH1 M P T AH0 M Z'],
  "syms": ['S IH1 M Z'],
  "symtron": ['S IH1 M T R AA2 N'],
  "syn": ['S IH1 N'],
  "syna": ['S IH1 N AH0'],
  "synagogue": ['S IH1 N AH0 G AO2 G'],
  "synagogues": ['S IH1 N AH0 G AO2 G Z'],
  "synalloy": ['S IH0 N AE1 L OY0'],
  "synalloy's": ['S IH0 N AE1 L OY0 Z'],
  "synan": ['S AY1 N AH0 N'],
  "synapse": ['S IH1 AE0 P S'],
  "synapses": ['S IH1 AE0 P S IH0 Z'],
  "synaptic": ['S IH2 N AE1 P T IH0 K'],
  "synar": ['S IH1 N AA0 R', 'S AY1 N AA0 R'],
  "synbiotics": ['S IH2 N B IY0 AA1 T IH0 K S', 'S IH2 M B IY0 AA1 T IH0 K S'],
  "sync": ['S IH1 NG K'],
  "synch": ['S IH1 N CH'],
  "synchro": ['S IH1 NG K R OW0'],
  "synchronic": ['S IH0 NG K R AA1 N IH0 K'],
  "synchronicity": ['S IH0 NG K R AH0 N IH1 S IH0 T IY0'],
  "synchronization": ['S IH2 NG K R AH0 N AH0 Z EY1 SH AH0 N'],
  "synchronize": ['S IH1 NG K R AH0 N AY2 Z'],
  "synchronized": ['S IH1 NG K R AH0 N AY2 Z D'],
  "synchronizes": ['S IH1 NG K R AH0 N AY2 Z IH0 Z'],
  "synchronizing": ['S IH1 NG K R AH0 N AY2 Z IH0 NG'],
  "synchronous": ['S IH1 NG K R AH0 N AH0 S'],
  "syncom": ['S IH1 NG K AA0 M'],
  "syncopal": ['S IH1 N K AH0 P AH2'],
  "syncopate": ['S IH1 NG K AH0 P EY2 T'],
  "syncopated": ['S IH1 NG K AH0 P EY2 T IH0 D'],
  "syncopation": ['S IH1 NG K AH0 P EY2 SH AH0 N'],
  "syncope": ['S IH1 N K AH0 P IY2'],
  "syncor": ['S IH1 N K AO2 R'],
  "synder": ['S IH1 N D ER0'],
  "syndicate": ['S IH1 N D IH0 K AH0 T', 'S IH1 N D AH0 K EY2 T'],
  "syndicate's": ['S IH1 N D IH0 K AH0 T S'],
  "syndicated": ['S IH1 N D IH0 K EY2 T IH0 D'],
  "syndicates": ['S IH1 N D IH0 K EY2 T S', 'S IH1 N D IH0 K AH0 T S'],
  "syndicating": ['S IH1 N D IH0 K EY2 T IH0 NG'],
  "syndication": ['S IH2 N D IH0 K EY1 SH AH0 N'],
  "syndications": ['S IH2 N D IH0 K EY1 SH AH0 N Z'],
  "syndicator": ['S IH1 N D IH0 K EY2 T ER0'],
  "syndicators": ['S IH1 N D IH0 K EY2 T ER0 Z'],
  "syndicats": ['S IH1 N D IH0 K IH2 T S'],
  "syndrich": ['S IH1 D R IH0 CH'],
  "syndrome": ['S IH1 N D R OW2 M'],
  "syndromes": ['S IH1 N D R OW2 M Z'],
  "synergen": ['S IH1 N ER0 JH EH2 N'],
  "synergen's": ['S IH1 N ER0 JH EH2 N Z'],
  "synergetic": ['S IH0 N ER0 JH EH1 T IH0 K'],
  "synergies": ['S IH1 N ER0 JH IY0 Z'],
  "synergism": ['S IH1 N ER0 JH IH2 Z AH0 M'],
  "synergistic": ['S IH2 N ER0 JH IH1 S T IH0 K'],
  "synergy": ['S IH1 N ER0 JH IY0'],
  "synergy's": ['S IH1 N ER0 JH IY0 Z'],
  "syngman": ['S IH1 NG M AH0 N'],
  "synhorst": ['S IH1 N HH AO2 R S T'],
  "synnott": ['S IH1 N AH0 T'],
  "synod": ['S IH1 N AH0 D'],
  "synod's": ['S IH1 N AH0 D Z'],
  "synonym": ['S IH1 N AH0 N IH2 M'],
  "synonymous": ['S AH0 N AA1 N AH0 M AH0 S'],
  "synonymously": ['S AH0 N AA1 N AH0 M AH0 S L IY0'],
  "synonyms": ['S IH1 N AH0 N IH2 M S'],
  "synopsis": ['S IH0 N AA1 P S IH0 S'],
  "synoptic": ['S IH0 N AA1 P T IH0 K'],
  "synoptics": ['S IH0 N AA1 P T IH0 K S'],
  "synovus": ['S AH0 N OW1 V AH0 S', 'S AY2 N OW1 V AH0 S'],
  "syntactic": ['S IH2 N T AE1 K T IH0 K'],
  "syntax": ['S IH1 N T AE2 K S'],
  "syntech": ['S IH1 N T EH2 K'],
  "syntex": ['S IH1 N T EH2 K S'],
  "syntex's": ['S IH1 N T EH0 K S IH0 Z'],
  "syntheses": ['S IH1 N TH AH0 S IY2 S'],
  "synthesis": ['S IH1 N TH AH0 S AH0 S'],
  "synthesize": ['S IH1 N TH AH0 S AY2 Z'],
  "synthesized": ['S IH1 N TH IH0 S AY2 Z D'],
  "synthesizer": ['S IH1 N TH AH0 S AY2 Z ER0'],
  "synthesizers": ['S IH1 N TH AH0 S AY2 Z ER0 Z'],
  "synthesizing": ['S IH1 N TH AH0 S AY2 Z IH0 NG'],
  "synthetic": ['S IH0 N TH EH1 T IH0 K'],
  "synthetically": ['S IH0 N TH EH1 T IH0 K L IY0'],
  "synthetics": ['S IH0 N TH EH1 T IH0 K S'],
  "syntrex": ['S IH1 N T R AH0 K S'],
  "syp": ['S AY1 P', 'EH1 S W AY1 P IY1'],
  "sypher": ['S IH1 F ER0'],
  "syphers": ['S IH1 F ER0 Z'],
  "syphilis": ['S IH1 F AH0 L IH0 S'],
  "syphon": ['S AY1 F AH0 N'],
  "sypniewski": ['S IH0 P N IY0 EH1 F S K IY0', 'S IH0 P N UW1 S K IY0'],
  "sypolt": ['S IH1 P OW0 L T'],
  "syracuse": ['S IH1 R AH0 K Y UW2 Z'],
  "syrek": ['S IH1 R IH0 K'],
  "syria": ['S IH1 R IY0 AH0'],
  "syria's": ['S IH1 R IY0 AH0 Z'],
  "syrian": ['S IH1 R IY0 AH0 N'],
  "syrians": ['S IH1 R IY0 AH0 N Z'],
  "syring": ['S AY1 R IH0 NG'],
  "syringe": ['S ER0 IH1 N JH', 'S IH1 R IH0 N JH'],
  "syringes": ['S ER0 IH1 N JH AH0 Z'],
  "syron": ['S AY1 R AH0 N'],
  "syrup": ['S ER1 AH0 P', 'S IH1 R AH0 P'],
  "syrups": ['S ER1 AH0 P S'],
  "sysco": ['S IH1 S K OW0'],
  "syscon": ['S AY1 S K AH0 N'],
  "syscon's": ['S AY1 S K AH0 N Z'],
  "system": ['S IH1 S T AH0 M'],
  "system's": ['S IH1 S T AH0 M Z'],
  "systemat": ['S IH1 S T AH0 M AE0 T'],
  "systematic": ['S IH2 S T AH0 M AE1 T IH0 K'],
  "systematically": ['S IH2 S T AH0 M AE1 T IH0 K L IY0'],
  "systematics": ['S IH2 S T AH0 M AE1 T IH0 K S'],
  "systemhouse": ['S IH1 S T AH0 M HH AW2 S'],
  "systemhouse's": ['S IH1 S T AH0 M HH AW2 S IH0 Z'],
  "systemic": ['S IH0 S T EH1 M IH0 K'],
  "systemically": ['S AH0 S T EH1 M IH0 K L IY0'],
  "systemix": ['S IH1 S T IH0 M IH0 K S', 'S IH2 S T EH1 M IH0 K S'],
  "systemone": ['S IH1 S T AH0 M OW2 N'],
  "systems": ['S IH1 S T AH0 M Z'],
  "systems'": ['S IH1 S T AH0 M Z'],
  "systemwide": ['S IH1 S T AH0 M W AY2 D'],
  "systolic": ['S IH0 S T AO1 L IH0 K'],
  "systran": ['S AY1 S T R AE2 N', 'S IH1 S T R AE2 N'],
  "sytsma": ['S IH1 T S M AH0'],
  "syverson": ['S IH1 V ER0 S AH0 N'],
  "syvertsen": ['S IH1 V ER0 T S AH0 N'],
  "szabo": ['SH AA1 B OW0'],
  "szafran": ['SH AA1 F R AH0 N'],
  "szafranski": ['SH AH0 F R AE1 N S K IY0'],
  "szalay": ['SH AA1 L AY0'],
  "szalkowski": ['SH AH0 L K AO1 F S K IY0'],
  "szanto": ['SH AE1 N T OW0'],
  "szarek": ['SH AA1 R EH0 K'],
  "szatkowski": ['SH AH0 T K AO1 F S K IY0'],
  "szczech": ['SH EH1 K'],
  "szczepaniak": ['SH IH0 P AE1 N IY0 AE0 K'],
  "szczepanik": ['SH IH0 P AE1 N IH0 K'],
  "szczepanski": ['SH IH0 P AE1 N S K IY0'],
  "szczerba": ['SH ER1 B AH0'],
  "szczesniak": ['SH EH1 Z N IY0 AE0 K'],
  "szczesny": ['SH EH1 Z N IY0'],
  "szczygiel": ['SH IH0 G IY1 L'],
  "sze": ['SH IY1', 'SH EY1'],
  "sze-di": ['SH EY1 D IY1'],
  "szekely": ['SH IY1 K L IY0'],
  "szeliga": ['SH IH0 L AY1 G AH0'],
  "szeto": ['SH IY1 T OW0'],
  "szewczyk": ['SH UW1 CH IH0 K'],
  "szilagyi": ['SH IH0 L AA1 G IY0'],
  "szilard": ['S IH1 L ER0 D', 'Z IH1 L ER0 D'],
  "szoke": ['SH OW1 K'],
  "szostak": ['SH AA1 S T AH0 K'],
  "szostek": ['SH AA1 S T EH0 K'],
  "szot": ['SH AA1 T'],
  "szott": ['SH AA1 T'],
  "szuba": ['SH UW1 B AH0'],
  "szuch": ['SH AH1 CH'],
  "szucs": ['SH AH1 K S'],
  "szumski": ['SH AH1 M S K IY0'],
  "szwed": ['SH V EH1 D'],
  "szydlowski": ['SH IH0 D L AO1 F S K IY0'],
  "szymanowski": ['SH IH0 M AH0 N AO1 F S K IY0'],
  "szymanski": ['SH IH0 M AE1 N S K IY0'],
  "szymborski": ['SH IH0 M B AO1 R S K IY0'],
  "szymczak": ['SH IH1 M CH AE0 K'],
  "t": ['T IY1'],
  "t'ang": ['T AE1 NG'],
  "t's": ['T IY1 Z'],
  "t-bone": ['T IY1 B OW2 N'],
  "t-lam": ['T IY1 L AE1 M'],
  "t-mobile": ['T IY1 M OW1 B IY0 L'],
  "t-shirt": ['T IY1 S ER2 T'],
  "t-shirts": ['T IY1 S ER2 T S'],
  "t.": ['T IY1'],
  "t.'s": ['T IY1 Z'],
  "t.s": ['T IY1 Z'],
  "ta": ['T AA1'],
  "taaffe": ['T AA1 F'],
  "tab": ['T AE1 B'],
  "tabacalera": ['T AH0 B AE2 K AH0 L EH1 R AH0'],
  "tabachneck": ['T AH0 B AA1 CH N EH2 K'],
  "tabak": ['T AE1 B AE0 K', 'T AH0 B AE1 K'],
  "tabaka": ['T AA0 B AA1 K AH0'],
  "tabar": ['T AA0 B AA1 R'],
  "tabares": ['T AA0 B AA1 R EH0 S'],
  "tabasco": ['T AH0 B AE1 S K OW0'],
  "tabb": ['T AE1 B'],
  "tabbert": ['T AE1 B ER0 T'],
  "tabbing": ['T AE1 B IH0 NG'],
  "tabbouleh": ['T AH0 B UW1 L EY0', 'T AH0 B UW1 L IY0'],
  "tabby": ['T AE1 B IY0'],
  "taber": ['T EY1 B ER0'],
  "tabernacle": ['T AE1 B ER0 N AE2 K AH0 L'],
  "tabitha": ['T AE1 B IH0 TH AH0'],
  "tablature": ['T AE1 B L AH0 CH ER0'],
  "table": ['T EY1 B AH0 L'],
  "table's": ['T EY1 B AH0 L Z'],
  "tableau": ['T AH0 B L OW1'],
  "tableaux": ['T AH0 B L OW1'],
  "tablecloth": ['T EY1 B AH0 L K L AO2 TH'],
  "tablecloths": ['T EY1 B AH0 L K L AO2 TH S'],
  "tabled": ['T EY1 B AH0 L D'],
  "tabler": ['T EY1 B AH0 L ER0', 'T EY1 B L ER0'],
  "tables": ['T EY1 B AH0 L Z'],
  "tablespoon": ['T EY1 B AH0 L S P UW2 N'],
  "tablespoons": ['T EY1 B AH0 L S P UW2 N Z'],
  "tablet": ['T AE1 B L AH0 T'],
  "tabletop": ['T EY1 B AH0 L T AA2 P'],
  "tablets": ['T AE1 B L AH0 T S'],
  "tableware": ['T EY1 B AH0 L W EH2 R'],
  "tabling": ['T EY1 B AH0 L IH0 NG', 'T EY1 B L IH0 NG'],
  "tabloid": ['T AE1 B L OY0 D'],
  "tabloidization": ['T AE2 B L OY0 D AH0 Z EY1 SH AH0 N'],
  "tabloids": ['T AE1 B L OY0 D Z'],
  "tabone": ['T AA1 B OW0 N'],
  "taboo": ['T AE0 B UW1'],
  "taboos": ['T AE0 B UW1 Z'],
  "tabor": ['T EY1 B ER0'],
  "tabor's": ['T EY1 B ER0 Z'],
  "taborn": ['T AE1 B ER0 N'],
  "tabron": ['T AE1 B R AH0 N'],
  "tabs": ['T AE1 B Z'],
  "tabuchi": ['T AA2 B UW1 CH IY0'],
  "tabulate": ['T AE1 B Y AH0 L EY2 T'],
  "tabulated": ['T AE1 B Y AH0 L EY2 T IH0 D'],
  "tabulating": ['T AE1 B Y AH0 L EY2 T IH0 NG'],
  "tabulation": ['T AE2 B Y AH0 L EY1 SH AH0 N'],
  "tabulations": ['T AE2 B Y AH0 L EY1 SH AH0 N Z'],
  "tabulature": ['T AE1 B Y AH0 L AH0 CH ER0'],
  "tac": ['T IY1 EY1 S IY1', 'T AE1 K'],
  "tacey": ['T EY1 S IY0'],
  "tachycardia": ['T AA2 K IY0 K AA1 R D IY2 AH0'],
  "tacit": ['T AE1 S IH0 T'],
  "tacita": ['T AA0 CH IY1 T AH0'],
  "tacitly": ['T AE1 S IH0 T L IY0'],
  "taciturn": ['T AE1 S IH0 T ER2 N'],
  "tack": ['T AE1 K'],
  "tacke": ['T AE1 K'],
  "tacked": ['T AE1 K T'],
  "tacker": ['T AE1 K ER0'],
  "tackett": ['T AE1 K IH0 T'],
  "tacking": ['T AE1 K IH0 NG'],
  "tackitt": ['T AE1 K IH0 T'],
  "tackle": ['T AE1 K AH0 L'],
  "tackled": ['T AE1 K AH0 L D'],
  "tackles": ['T AE1 K AH0 L Z'],
  "tackling": ['T AE1 K L IH0 NG', 'T AE1 K AH0 L IH0 NG'],
  "tacks": ['T AE1 K S'],
  "tacky": ['T AE1 K IY0'],
  "taco": ['T AA1 K OW0'],
  "tacoma": ['T AH0 K OW1 M AA0'],
  "tacoma's": ['T AH0 K OW1 M AH0 Z'],
  "tacos": ['T AA1 K OW0 Z'],
  "tact": ['T AE1 K T'],
  "tactful": ['T AE1 K T F AH0 L'],
  "tactfully": ['T AE1 K T F AH0 L IY0'],
  "tactic": ['T AE1 K T IH0 K'],
  "tactical": ['T AE1 K T IH0 K AH0 L'],
  "tactically": ['T AE1 K T IH0 K AH0 L IY0'],
  "tactician": ['T AE0 K T IH1 SH AH0 N'],
  "tacticians": ['T AE0 K T IH1 SH AH0 N Z'],
  "tactics": ['T AE1 K T IH0 K S'],
  "tactile": ['T AE1 K T IH0 L', 'T AE1 K T AY2 L'],
  "tactless": ['T AE1 K T L AH0 S'],
  "tacy": ['T EY1 S IY0'],
  "tad": ['T AE1 D'],
  "tada": ['T AA1 D AH0'],
  "tadashi": ['T AA2 D AA1 SH IY0'],
  "tadd": ['T AE1 D'],
  "taddei": ['T AE1 D AY0'],
  "taddeo": ['T AA1 D IY0 OW0'],
  "taddy": ['T AE1 D IY0'],
  "tadeusz": ['T AE1 D IY0 UW0 Z'],
  "tadic": ['T AE1 D IH0 K'],
  "tadlock": ['T AE1 D L AH0 K'],
  "tadpole": ['T AE1 D P OW2 L'],
  "tadpoles": ['T AE1 D P OW2 L Z'],
  "tadros": ['T EY1 D R OW0 Z'],
  "tadych": ['T AA1 D IH0 HH'],
  "tae": ['T EY1', 'T AY1'],
  "taegu": ['T EY1 G UW0'],
  "taekwondo": ['T AE1 K W AA2 N D OW1', 'T AY0 K W AA1 N D OW0'],
  "taewoo": ['T AY1 W UW0'],
  "tafaro": ['T AH0 F AA1 R OW0'],
  "tafel": ['T AE1 F AH0 L'],
  "taff": ['T AE1 F'],
  "taffe": ['T AE1 F'],
  "taffel": ['T AE1 F AH0 L'],
  "taffeta": ['T AE1 F AH0 T AH0'],
  "taffy": ['T AE1 F IY0'],
  "taflinger": ['T EY1 F AH0 L IH0 NG ER0', 'T EY1 F L IH0 NG ER0'],
  "tafolla": ['T AH0 F AA1 L AH0'],
  "tafoya": ['T AA0 F OY1 AH0'],
  "taft": ['T AE1 F T'],
  "taft's": ['T AE1 F T S'],
  "tag": ['T AE1 G'],
  "tagalog": ['T AE1 G AH0 L AA2 G'],
  "tagamet": ['T AE1 G AH0 M EH1 T'],
  "taganka": ['T AH0 G AA1 NG K AH0'],
  "tager": ['T EY1 G ER0'],
  "tagg": ['T AE1 G'],
  "taggart": ['T AE1 G ER0 T'],
  "taggart's": ['T AE1 G ER0 T S'],
  "tagge": ['T AE1 G'],
  "tagged": ['T AE1 G D'],
  "taggert": ['T AE1 G ER0 T'],
  "tagging": ['T AE1 G IH0 NG'],
  "tagle": ['T EY1 G AH0 L'],
  "tagliaferri": ['T AA0 G L Y AA0 F EH1 R IY0'],
  "taglieri": ['T AA0 G L IH1 R IY0'],
  "tagline": ['T AE1 G L AY2 N'],
  "tagout": ['T AE1 G AW2 T'],
  "tags": ['T AE1 G Z'],
  "tague": ['T AA1 G'],
  "taher": ['T EY1 ER0'],
  "tahiti": ['T AH0 HH IY1 T IY0'],
  "tahmassebi": ['T AA2 M AH0 S IY1 B IY0'],
  "tahoe": ['T AE1 HH OW0'],
  "tai": ['T AY1'],
  "taibi": ['T EY1 B IY0'],
  "taiko": ['T EY1 K OW0'],
  "tail": ['T EY1 L'],
  "tailed": ['T EY1 L D'],
  "tailgate": ['T EY1 L G EY2 T'],
  "tailhook": ['T EY1 L HH UH2 K'],
  "tailing": ['T EY1 L IH0 NG'],
  "tailings": ['T EY1 L IH0 NG Z'],
  "taillon": ['T EY1 L AH0 N'],
  "tailor": ['T EY1 L ER0'],
  "tailored": ['T EY1 L ER0 D'],
  "tailoring": ['T EY1 L ER0 IH0 NG'],
  "tailors": ['T EY1 L ER0 Z'],
  "tailpipe": ['T EY1 L P AY2 P'],
  "tails": ['T EY1 L Z'],
  "tailspin": ['T EY1 L S P IH2 N'],
  "taima": ['T AY1 M AH0'],
  "tainer": ['T EY1 N ER0'],
  "taing": ['T AA1 IH0 NG'],
  "taint": ['T EY1 N T'],
  "tainted": ['T EY1 N T IH0 D'],
  "tainter": ['T EY1 N T ER0'],
  "tainting": ['T EY1 N T IH0 NG'],
  "taints": ['T EY1 N T S'],
  "taipei": ['T AY1 P EY2'],
  "taipei's": ['T AY1 P EY2 Z'],
  "taira": ['T AA0 IH1 R AH0'],
  "taisei": ['T EY1 S EY2'],
  "taisho": ['T EY1 SH OW0'],
  "tait": ['T EY1 T'],
  "taite": ['T EY1 T'],
  "taitt": ['T EY1 T'],
  "taiwan": ['T AY1 W AA1 N'],
  "taiwan's": ['T AY1 W AA1 N Z'],
  "taiwanese": ['T AY1 W AA0 N IY1 Z'],
  "taiyo": ['T AY1 Y OW0'],
  "taj": ['T AA1 ZH'],
  "tajik": ['T AA1 JH IH0 K'],
  "tajikistan": ['T AA2 JH IY1 K IH0 S T AE2 N'],
  "tajikistan's": ['T AA2 JH IY1 K IH0 S T AE2 N Z'],
  "tajima": ['T AA2 JH IY1 M AH0'],
  "tak": ['T AE1 K'],
  "takach": ['T AE1 K AH0 K'],
  "takacs": ['T AE1 K AH0 K S'],
  "takagi": ['T AA0 K AA1 G IY0'],
  "takahashi": ['T AA0 K AA0 HH AA1 SH IY0'],
  "takaki": ['T AA0 K AA1 K IY0'],
  "takako": ['T AA2 K AA1 K OW0'],
  "takanashi": ['T AA2 K AA2 N AA1 SH IY0'],
  "takao": ['T AA2 K AA1 OW0'],
  "takara": ['T AA0 K AA1 R AH0'],
  "takasago": ['T AA2 K AA0 S AA1 G OW0'],
  "takashi": ['T AA2 K AA1 SH IY0'],
  "takashima": ['T AA2 K AA0 SH IY1 M AH0'],
  "takashimaya": ['T AA2 K AA2 SH IH0 M AA1 Y AH0'],
  "takata": ['T AA0 K AA1 T AH0'],
  "takayama": ['T AA0 K AA0 Y AA1 M AH0'],
  "take": ['T EY1 K'],
  "takeaway": ['T EY1 K AH0 W EY2'],
  "takeaways": ['T EY1 K AH0 W EY2 Z'],
  "takecare": ['T EY1 K EH1 R'],
  "takeda": ['T AA0 K EY1 D AH0'],
  "takei": ['T AA1 K EY2'],
  "takemoto": ['T AA0 K EY0 M OW1 T OW2'],
  "takemura": ['T AA2 K EY0 M UH1 R AA2'],
  "taken": ['T EY1 K AH0 N'],
  "takeo": ['T AA2 K EY1 OW0'],
  "takeoff": ['T EY1 K AO2 F'],
  "takeoffs": ['T EY1 K AO2 F S'],
  "takeout": ['T EY1 K AW2 T'],
  "takeover": ['T EY1 K OW2 V ER0'],
  "takeovers": ['T EY1 K OW2 V ER0 Z'],
  "taker": ['T EY1 K ER0'],
  "takers": ['T EY1 K ER0 Z'],
  "takes": ['T EY1 K S'],
  "takeshi": ['T AH0 K EH1 SH IY0'],
  "takeshima": ['T AE2 K IH0 SH IY1 M AA2'],
  "takeshita": ['T AA2 K AH0 SH IY1 T AA2'],
  "takeshita's": ['T AA2 K AH0 SH IY1 T AH0 Z'],
  "taketa": ['T AA0 K EY1 T AA2'],
  "taketh": ['T EY1 K AH0 TH'],
  "taketomi": ['T AA2 K IH0 T OW1 M IY2'],
  "takeuchi": ['T AA2 K EY0 UW1 CH IY2'],
  "taki": ['T AE1 K IY2'],
  "taki's": ['T AE1 K IY2 Z'],
  "takihyo": ['T AH0 K IY1 Y OW0'],
  "takin'": ['T EY1 K IH0 N'],
  "taking": ['T EY1 K IH0 NG'],
  "takings": ['T EY1 K IH0 NG Z'],
  "takla-makan": ['T AE1 K L AH0 M EY1 K AH0 N'],
  "takoma": ['T AH0 K OW1 M AH0'],
  "takoma's": ['T AH0 K OW1 M AH0 Z'],
  "takomas": ['T AH0 K OW1 M AH0 Z'],
  "taku": ['T AA1 K UW2'],
  "takushoku": ['T AA2 K AH0 SH OW1 K UW2'],
  "tal": ['T AA1 L'],
  "talaga": ['T AA0 L AA1 G AA2'],
  "talal": ['T AH0 L AA1 L'],
  "talamantes": ['T AA0 L AA0 M AA1 N T EH0 S'],
  "talamantez": ['T AA0 L AA0 M AA1 N T EH0 Z'],
  "talamo": ['T AA0 L AA1 M OW2'],
  "talarico": ['T AA0 L AA0 R IY1 K OW2'],
  "talavera": ['T AA0 L AA0 V EH1 R AA2'],
  "talbert": ['T AE1 L B ER0 T'],
  "talbot": ['T AE1 L B AH0 T'],
  "talbot's": ['T AE1 L B AH0 T S'],
  "talbots": ['T AE1 L B AH0 T S'],
  "talbott": ['T AE1 L B AH0 T'],
  "talbott's": ['T AE1 L B AH0 T S'],
  "talc": ['T AE1 L K'],
  "talcott": ['T AE1 L K AH0 T'],
  "talcs": ['T AE1 L K S'],
  "talcum": ['T AE1 L K AH0 M'],
  "tale": ['T EY1 L'],
  "talent": ['T AE1 L AH0 N T'],
  "talented": ['T AE1 L AH0 N T IH0 D'],
  "talents": ['T AE1 L AH0 N T S'],
  "talerico": ['T AA0 L ER0 IY1 K OW0'],
  "tales": ['T EY1 L Z'],
  "talese": ['T AH0 L IY1 Z', 'T AH0 L IY1 Z IY0'],
  "talford": ['T AE1 L F ER0 D'],
  "taliban": ['T AE1 L IH0 B AE2 N'],
  "taliban's": ['T AE1 L IH0 B AE2 N Z'],
  "talibans": ['T AE1 L IH0 B AE2 N Z'],
  "taligent": ['T AE1 L IH0 JH EH0 N T'],
  "talisman": ['T AE1 L IH0 S M AH0 N'],
  "talitha": ['T AE1 L IH0 DH AH0'],
  "talk": ['T AO1 K'],
  "talk's": ['T AO1 K S'],
  "talkable": ['T AO1 K AH0 B AH0 L'],
  "talkative": ['T AO1 K AH0 T IH0 V'],
  "talkback": ['T AO1 K B AE2 K'],
  "talked": ['T AO1 K T'],
  "talker": ['T AO1 K ER0'],
  "talkers": ['T AO1 K ER0 Z'],
  "talkie": ['T AO1 K IY0'],
  "talkies": ['T AO1 K IY0 Z'],
  "talkin": ['T AA1 K AH0 N'],
  "talkin'": ['T AO1 K IH0 N'],
  "talking": ['T AO1 K IH0 NG'],
  "talking's": ['T AO1 K IH0 NG Z'],
  "talkington": ['T AO1 K IH0 NG T AH0 N'],
  "talks": ['T AO1 K S'],
  "talky": ['T AO1 K IY0'],
  "tall": ['T AO1 L'],
  "talladega": ['T AE2 L AH0 D EY1 G AH0'],
  "tallahassean": ['T AE2 L AH0 HH AE1 S IY0 AH0 N'],
  "tallahasseans": ['T AE2 L AH0 HH AE1 S IY0 AH0 N Z'],
  "tallahassee": ['T AE2 L AH0 HH AE1 S IY0'],
  "tallahassee's": ['T AE2 L AH0 HH AE1 S IY0 Z'],
  "tallant": ['T AA1 L AH0 N T'],
  "tallarico": ['T AA0 L AA0 R IY1 K OW0'],
  "tallent": ['T AA1 L AH0 N T'],
  "taller": ['T AO1 L ER0'],
  "tallerico": ['T AA0 L ER0 IY1 K OW0'],
  "tallest": ['T AO1 L IH0 S T'],
  "talley": ['T AE1 L IY0'],
  "tallgrass": ['T AA1 L G R AE2 S'],
  "tallie": ['T AO1 L IY0'],
  "tallied": ['T AE1 L IY0 D'],
  "tallies": ['T AE1 L IY0 Z'],
  "tallil": ['T AH0 L IH1 L'],
  "tallmadge": ['T AE1 L M AE0 JH'],
  "tallman": ['T AO1 L M AH0 N'],
  "tallo": ['T AE1 L OW0'],
  "tallon": ['T AE1 L AH0 N'],
  "tallow": ['T AE1 L OW0'],
  "tallula": ['T AA0 L UW1 L AH0'],
  "tallulah": ['T AE2 L UW1 L AH0'],
  "tally": ['T AE1 L IY0'],
  "tallyho": ['T AE2 L IY0 HH OW1'],
  "tallying": ['T AE1 L IY0 IH0 NG'],
  "talmadge": ['T AE1 L M AE0 JH'],
  "talmage": ['T AE1 L M IH0 JH'],
  "talman": ['T AE1 L M AH0 N'],
  "talmor": ['T AE1 L M AO2 R'],
  "talmud": ['T AE1 L M AH0 D'],
  "talon": ['T AE1 L AH0 N'],
  "talons": ['T AE1 L AH0 N Z'],
  "talsma": ['T AA1 L S M AH0'],
  "talton": ['T AE1 L T AH0 N'],
  "taltos": ['T AA1 L T OW0 Z'],
  "talty": ['T AO1 L T IY0'],
  "tam": ['T AE1 M'],
  "tama": ['T AA1 M AH0'],
  "tamales": ['T AH0 M AA1 L IY0 Z'],
  "tamanaha": ['T AA0 M AA0 N AA1 HH AH0'],
  "tamara": ['T AH0 M AA1 R AH0'],
  "tamarac": ['T AE1 M ER0 AE0 K'],
  "tamargo": ['T AA0 M AA1 R G OW0'],
  "tamarin": ['T AE1 M ER0 AH0 N'],
  "tamarins": ['T AE1 M ER0 AH0 N Z'],
  "tamarisk": ['T AE1 M ER0 IH0 S K'],
  "tamarisks": ['T AE1 M ER0 IH0 S K S'],
  "tamas": ['T AA1 M AH0 Z'],
  "tamashiro": ['T AA0 M AA0 SH IH1 R OW0'],
  "tamayo": ['T AA0 M AA1 Y OW0'],
  "tamblyn": ['T AE1 M B L IH0 N'],
  "tambo": ['T AE1 M B OW0'],
  "tambocor": ['T AE1 M B OW0 K AO2 R'],
  "tambourine": ['T AE2 M B ER0 IY1 N'],
  "tambrands": ['T AE1 M B R AE2 N D Z'],
  "tambs": ['T AE1 M Z'],
  "tamburello": ['T AA0 M B UH0 R EH1 L OW0'],
  "tamburo": ['T AA0 M B UH1 R OW0'],
  "tamburri": ['T AA0 M B UH1 R IY0'],
  "tamburrino": ['T AA0 M B UH0 R IY1 N OW0'],
  "tamburro": ['T AA0 M B UH1 R OW0'],
  "tame": ['T EY1 M'],
  "tamed": ['T EY1 M D'],
  "tamer": ['T EY1 M ER0'],
  "tames": ['T EY1 M Z'],
  "tamez": ['T AA0 M EH1 Z'],
  "tami": ['T AE1 M IY0'],
  "tamiami": ['T AE2 M IY0 AE1 M IY0'],
  "tamil": ['T AH0 M IH1 L', 'T AE1 M IH0 L'],
  "tamils": ['T AH0 M IH1 L Z', 'T AE1 M IH0 L Z'],
  "taming": ['T EY1 M IH0 NG'],
  "tamke": ['T AE1 M K IY0'],
  "tamm": ['T AE1 M'],
  "tammany": ['T AE1 M AH0 N IY0'],
  "tammaro": ['T AA0 M AA1 R OW0'],
  "tammen": ['T AE1 M AH0 N'],
  "tammie": ['T AE1 M IY0'],
  "tamminga": ['T AA0 M IY1 NG G AH0'],
  "tammo": ['T AE1 M OW0'],
  "tammy": ['T AE1 M IY0'],
  "tamoxifen": ['T AE2 M AA1 K S AH0 F AH0 N'],
  "tamp": ['T AE1 M P'],
  "tampa": ['T AE1 M P AH0'],
  "tampa's": ['T AE1 M P AH0 Z'],
  "tampax": ['T AE1 M P AE2 K S'],
  "tamper": ['T AE1 M P ER0'],
  "tampered": ['T AE1 M P ER0 D'],
  "tampering": ['T AE1 M P ER0 IH0 NG'],
  "tamping": ['T AE1 M P IH0 NG'],
  "tamplin": ['T AE1 M P L IH0 N'],
  "tampon": ['T AE1 M P AA0 N'],
  "tampons": ['T AE1 M P AA0 N Z'],
  "tampopo": ['T AE2 M P OW1 P OW0'],
  "tamposi": ['T AE2 M P OW1 S IY0'],
  "tamps": ['T AE1 M P S'],
  "tamraz": ['T AE1 M R AE0 Z'],
  "tamraz's": ['T AE1 M R AE0 Z AH0 Z'],
  "tamres": ['T AE1 M R EH0 Z'],
  "tamura": ['T AA0 M UH1 R AH0'],
  "tan": ['T AE1 N'],
  "tanabe": ['T AA0 N AA1 B EY0'],
  "tanaka": ['T AA0 N AA1 K AH0'],
  "tanartkit": ['T AE2 N AA1 R T K IH2 T'],
  "tancredi": ['T AA0 N K R EH1 D IY0'],
  "tancredo": ['T AE2 N K R EY1 D OW0'],
  "tandem": ['T AE1 N D AH0 M'],
  "tandem's": ['T AE1 N D AH0 M Z'],
  "tandon": ['T AE1 N D AH0 N'],
  "tandoor": ['T AE0 N D UW1 R'],
  "tandoori": ['T AE2 N D UW1 R IY0'],
  "tandy": ['T AE1 N D IY0'],
  "tandy's": ['T AE1 N D IY0 Z'],
  "tandycraft": ['T AE1 N D IY0 K R AE2 F T'],
  "tandycrafts": ['T AE1 N D IY0 K R AE2 F T S'],
  "taneja": ['T AH0 N EY1 HH AH0'],
  "tanen": ['T AE1 N AH0 N'],
  "tanenbaum": ['T AE1 N AH0 N B AW2 M'],
  "taney": ['T EY1 N IY0'],
  "tang": ['T AE1 NG', 'T AA1 NG'],
  "tange": ['T AE1 N JH'],
  "tangelo": ['T AE1 N JH EH2 L OW0'],
  "tangeman": ['T EY1 N JH M AH0 N'],
  "tangen": ['T AE1 NG AH0 N'],
  "tangent": ['T AE1 N JH AH0 N T'],
  "tangential": ['T AE0 N JH EH1 N SH AH0 L'],
  "tangentially": ['T AE0 N JH EH1 N SH AH0 L IY0'],
  "tangents": ['T AE1 N JH AH0 N T S'],
  "tanger": ['T AE1 NG ER0'],
  "tangerine": ['T AE2 N JH ER0 IY1 N'],
  "tangerines": ['T AE1 N JH ER0 IY2 N Z'],
  "tangible": ['T AE1 N JH AH0 B AH0 L'],
  "tangibles": ['T AE1 N JH AH0 B AH0 L Z'],
  "tangibly": ['T AE1 N JH AH0 B L IY0'],
  "tangiers": ['T AE2 N JH IH1 R Z'],
  "tangle": ['T AE1 NG G AH0 L'],
  "tangled": ['T AE1 NG G AH0 L D'],
  "tangles": ['T AE1 NG G AH0 L Z'],
  "tanglewood": ['T AE1 NG G AH0 L W UH2 D'],
  "tangling": ['T AE1 NG G L IH0 NG'],
  "tangney": ['T AE1 NG G N IY0'],
  "tango": ['T AE1 NG G OW0'],
  "tangos": ['T AE1 NG G OW0 Z'],
  "tangqui": ['T AE1 NG K W IY1'],
  "tangredi": ['T AA0 NG G R EH1 D IY0'],
  "tangs": ['T AE1 NG Z'],
  "tanguay": ['T AE1 N G EY0'],
  "tanguma": ['T AA0 NG G UW1 M AH0'],
  "tani": ['T AA1 N IY0'],
  "tania": ['T AA1 N Y AH0'],
  "taniguchi": ['T AA2 N IH0 G UW1 CH IY0'],
  "tanimoto": ['T AA0 N IY0 M OW1 T OW0'],
  "tanimura": ['T AA2 N IH0 M UW1 R AH0'],
  "tanis": ['T AE1 N IH0 S'],
  "tanjug": ['T AE1 N JH AH2 G'],
  "tank": ['T AE1 NG K'],
  "tank's": ['T AE1 NG K S'],
  "tankan": ['T AE1 NG K AH0 N'],
  "tankard": ['T AE1 NG K ER0 D'],
  "tanke": ['T AE1 NG K'],
  "tanked": ['T AE1 NG K T'],
  "tanker": ['T AE1 NG K ER0'],
  "tankers": ['T AE1 NG K ER0 Z'],
  "tankersley": ['T AE1 NG K ER0 S L IY0'],
  "tanking": ['T AE1 NG K IH0 NG'],
  "tanks": ['T AE1 NG K S'],
  "tanksley": ['T AE1 NG K S L IY0'],
  "tann": ['T AE1 N'],
  "tannahill": ['T AE1 N AH0 HH IH2 L'],
  "tanned": ['T AE1 N D'],
  "tannehill": ['T AE1 N IH0 HH IH0 L'],
  "tannen": ['T AE1 N AH0 N'],
  "tannenbaum": ['T AE1 N AH0 N B AW2 M'],
  "tanner": ['T AE1 N ER0'],
  "tannest": ['T AE1 N IH0 S T'],
  "tanney": ['T AE1 N IY0'],
  "tannhauser": ['T AE1 N HH AW2 Z ER0'],
  "tanning": ['T AE1 N IH0 NG'],
  "tanqueray": ['T AE1 NG K W ER0 EY2'],
  "tans": ['T AE1 N Z'],
  "tansey": ['T AE1 N Z IY0'],
  "tanski": ['T AE1 N S K IY2'],
  "tansu": ['T AE1 N S UW2'],
  "tansy": ['T AE1 N Z IY0'],
  "tant": ['T AE1 N T'],
  "tantalize": ['T AE1 N T AH0 L AY2 Z', 'T AE1 N AH0 L AY2 Z'],
  "tantalized": ['T AE1 N T AH0 L AY2 Z D', 'T AE1 N AH0 L AY2 Z D'],
  "tantalizes": ['T AE1 N T AH0 L AY2 Z IH0 Z', 'T AE1 N AH0 L AY2 Z AH0 Z'],
  "tantalizing": ['T AE1 N T AH0 L AY2 Z IH0 NG', 'T AE1 N AH0 L AY2 Z IH0 NG'],
  "tantalizingly": ['T AE1 N T AH0 L AY2 Z IH0 NG L IY0', 'T AE1 N AH0 L AY2 Z IH0 NG L IY0'],
  "tantalum": ['T AE1 N T AH0 L AH0 M'],
  "tantalus": ['T AE1 N T AH0 L AH0 S'],
  "tantamount": ['T AE1 N T AH0 M AW2 N T'],
  "tantillo": ['T AA0 N T IH1 L OW0'],
  "tanton": ['T AE1 N T AH0 N'],
  "tantric": ['T AE1 N T R IH0 K'],
  "tantrum": ['T AE1 N T R AH0 M'],
  "tantrums": ['T AE1 N T R AH0 M Z'],
  "tanu": ['T AA1 N UW0'],
  "tanya": ['T AA1 N Y AH0'],
  "tanzania": ['T AE0 N Z EY1 N IY0 AH0', 'T AE2 N Z AH0 N IY1 AH0', 'T AE2 N Z EY1 N Y AH0'],
  "tanzania's": ['T AE0 N Z EY1 N IY0 AH0 Z', 'T AE2 N Z AH0 N IY1 AH0 Z', 'T AE2 N Z EY1 N Y AH0 Z'],
  "tanzanian": ['T AE2 N Z EY1 N IY0 AH0 N', 'T AE2 N Z EY1 N Y AH0 N'],
  "tanzer": ['T AE1 N Z ER0'],
  "tanzi": ['T AE1 N Z IY0'],
  "tao": ['T AW1', 'D AW1'],
  "taoiseach": ['T IY1 SH AH0 K'],
  "taoiseach's": ['T IY1 SH AH0 K S'],
  "taoism": ['D AW1 IH0 Z AH0 M'],
  "taoist": ['D AW1 IH0 S T'],
  "taoists": ['D AW1 AH0 S T S'],
  "taormina": ['T AA0 AO0 R M IY1 N AH0'],
  "taos": ['T AW1 Z'],
  "tap": ['T AE1 P'],
  "tapas": ['T AH1 P AH0 S'],
  "tape": ['T EY1 P'],
  "tape's": ['T EY1 P S'],
  "taped": ['T EY1 P T'],
  "tapeie": ['T EY1 P IY0 EY0'],
  "tapeie's": ['T EY1 P IY0 EY0 Z'],
  "taper": ['T EY1 P ER0'],
  "tapered": ['T EY1 P ER0 D'],
  "tapering": ['T EY1 P ER0 IH0 NG'],
  "tapers": ['T EY1 P ER0 Z'],
  "tapes": ['T EY1 P S'],
  "tapestries": ['T AE1 P AH0 S T R IY0 Z'],
  "tapestry": ['T AE1 P AH0 S T R IY0'],
  "tapia": ['T AA1 P IY0 AH0'],
  "tapias": ['T AH0 P IY1 AH0 S'],
  "tapie": ['T AE1 P IY0'],
  "taping": ['T EY1 P IH0 NG'],
  "tapings": ['T EY1 P IH0 NG Z'],
  "tapley": ['T AE1 P L IY0'],
  "taplin": ['T AE1 P L IH0 N'],
  "tapp": ['T AE1 P'],
  "tappan": ['T AE1 P AH0 N'],
  "tappe": ['T AE1 P'],
  "tapped": ['T AE1 P T'],
  "tappen": ['T AE1 P AH0 N'],
  "tapper": ['T AE1 P ER0'],
  "tappet": ['T AE1 P AH0 T'],
  "tappin": ['T AE1 P IH0 N'],
  "tapping": ['T AE1 P IH0 NG'],
  "taps": ['T AE1 P S'],
  "tapscott": ['T AE1 P S K AH0 T'],
  "tar": ['T AA1 R'],
  "tara": ['T EH1 R AH0', 'T AA1 R AH0'],
  "tara's": ['T EH1 R AH0 Z', 'T AA1 R AH0 Z'],
  "tarahumara": ['T AA0 R AH0 HH UW1 M AA0 R AH0'],
  "tarallo": ['T ER0 AE1 L OW0'],
  "tarango": ['T AA0 R AA1 NG G OW0'],
  "tarantino": ['T AA0 R AA0 N T IY1 N OW0'],
  "taranto": ['T ER0 AE1 N T OW0'],
  "taranto's": ['T ER0 AE1 N T OW0 Z'],
  "tarantula": ['T AH0 R AE1 N CH UW0 L AH0', 'T AH0 R AE1 N CH AH0 L AH0'],
  "tarantulas": ['T AH0 R AE1 N CH UW0 L AH0 Z', 'T AH0 R AE1 N CH AH0 L AH0 Z'],
  "taras": ['T AA1 R AH0 Z'],
  "tarasoff": ['T EH1 R IH0 S AO0 F'],
  "taravella": ['T AE2 R AH0 V EH1 L AH0'],
  "tarbell": ['T AA1 R B EH2 L'],
  "tarbert": ['T AA1 R B ER0 T'],
  "tarbet": ['T AA1 R B IH0 T'],
  "tarbox": ['T AA1 R B AA2 K S'],
  "tarbutton": ['T AA1 R B AH2 T AH0 N'],
  "tardif": ['T AA1 R D IH0 F'],
  "tardiff": ['T AA1 R D IH0 F'],
  "tardiness": ['T AA1 R D IY0 N AH0 S'],
  "tarditi": ['T AA2 R D IH1 T IY0'],
  "tardy": ['T AA1 R D IY0'],
  "target": ['T AA1 R G AH0 T', 'T ER1 G AH0 T'],
  "target's": ['T AA1 R G AH0 T S'],
  "targeted": ['T AA1 R G AH0 T IH0 D'],
  "targeting": ['T AA1 R G AH0 T IH0 NG'],
  "targets": ['T AA1 R G AH0 T S'],
  "tarheel": ['T AA1 R HH IY2 L'],
  "tarheels": ['T AA1 R HH IY2 L Z'],
  "tariff": ['T EH1 R AH0 F'],
  "tariffs": ['T EH1 R AH0 F S'],
  "tarin": ['T AA1 R IY0 N'],
  "tariq": ['T AA2 R IY1 K'],
  "tarkington": ['T AA1 R K IH0 NG T AH0 N'],
  "tarkowski": ['T ER0 K AO1 F S K IY0'],
  "tarleton": ['T AA1 R L T AH0 N'],
  "tarlow": ['T AA1 R L OW2'],
  "tarlton": ['T AA1 R L T AH0 N'],
  "tarmac": ['T AA1 R M AE2 K'],
  "tarmacs": ['T AA1 R M AE2 K S'],
  "tarman": ['T AA1 R M AH0 N'],
  "tarnish": ['T AA1 R N IH0 SH'],
  "tarnished": ['T AA1 R N IH0 SH T'],
  "tarnishing": ['T AA1 R N IH0 SH IH0 NG'],
  "tarnoff": ['T AA1 R N AO2 F'],
  "tarnoff's": ['T AA1 R N AO2 F S'],
  "tarnow": ['T AA1 R N OW0'],
  "tarnower": ['T AA1 R N AW0 ER0'],
  "tarnowski": ['T ER0 N AO1 F S K IY0'],
  "taro": ['T EH1 R OW0'],
  "tarom": ['T EH1 R AH0 M'],
  "tarot": ['T AE2 R OW1'],
  "tarots": ['T AE2 R OW1 Z'],
  "tarp": ['T AA1 R P'],
  "tarpey": ['T AA1 R P IY0'],
  "tarpley": ['T AA1 R P L IY0'],
  "tarpon": ['T AA1 R P AH0 N'],
  "tarps": ['T AA1 R P S'],
  "tarpy": ['T AA1 R P IY0'],
  "tarquinio": ['T AA0 R K W IY1 N IY0 OW0'],
  "tarr": ['T AA1 R'],
  "tarrance": ['T AA1 R AH0 N S'],
  "tarrant": ['T AA1 R AH0 N T'],
  "tarred": ['T AA1 R D'],
  "tarring": ['T AA1 R IH0 NG'],
  "tarry": ['T EH1 R IY0'],
  "tarrytown": ['T EH1 R IY0 T AW2 N'],
  "tarses": ['T AA1 R S IH0 S'],
  "tarsus": ['T AA1 R S AH0 S'],
  "tart": ['T AA1 R T'],
  "tartaglia": ['T AA0 R T AE1 G L IY0 AH0'],
  "tartaglione": ['T AA0 R T AE0 G L IY0 OW1 N IY0'],
  "tartan": ['T AA1 R T AH0 N'],
  "tartar": ['T AA1 R T ER0'],
  "tartars": ['T AA1 R T ER0 Z'],
  "tarte": ['T AA1 R T'],
  "tarter": ['T AA1 R T ER0'],
  "tartikoff": ['T AA1 R T IH0 K AO2 F'],
  "tartikoff's": ['T AA1 R T IH0 K AO2 F S'],
  "tartikoffs": ['T AA1 R T IH0 K AO2 F S'],
  "tartly": ['T AA1 R T L IY0'],
  "tartness": ['T AA1 R T N AH0 S'],
  "tarts": ['T AA1 R T S'],
  "tartt": ['T AA1 R T'],
  "tartu": ['T AA2 R T UW1'],
  "tarver": ['T AA1 R V ER0'],
  "tarvin": ['T AA1 R V IH0 N'],
  "tarzan": ['T AA1 R Z AE2 N', 'T AA1 R Z AH0 N'],
  "tarzan's": ['T AA1 R Z AE2 N Z', 'T AA1 R Z AH0 N Z'],
  "tasaday": ['T AE1 S AH0 D EY0'],
  "tasca": ['T AA1 S K AH0'],
  "tasch": ['T AE1 SH'],
  "taschner": ['T AE1 SH N ER0'],
  "taser": ['T EY1 Z ER0'],
  "tash": ['T AE1 SH'],
  "tasha": ['T AE1 SH AH0'],
  "tashin": ['T AE1 SH IH0 N'],
  "tashiro": ['T AA0 SH IH1 R OW0'],
  "tashjian": ['T AE1 SH JH IY0 AH0 N'],
  "tashkent": ['T AE2 SH K EH1 N T'],
  "task": ['T AE1 S K'],
  "tasked": ['T AE1 S K T'],
  "tasker": ['T AE1 S K ER0'],
  "taskforce": ['T AE1 S K F AO2 R S'],
  "tasking": ['T AE1 S K IH0 NG'],
  "taskmaster": ['T AE1 S K M AE2 S T ER0'],
  "tasks": ['T AE1 S K S'],
  "tasm": ['T AE1 S AH0 M'],
  "tasman": ['T AE1 Z M AH0 N'],
  "tasmania": ['T AE2 Z M EY1 N IY0 AH0'],
  "tasmanian": ['T AE2 Z M EY1 N IY0 AH0 N'],
  "tass": ['T AE1 S'],
  "tassel": ['T AE1 S AH0 L'],
  "tasseled": ['T AE1 S AH0 L D'],
  "tassi": ['T AE1 S IY0'],
  "tassin": ['T AE1 S IH0 N'],
  "tassinari": ['T AA0 S IY0 N AA1 R IY2'],
  "tasso": ['T AE1 S OW2'],
  "tassone": ['T AA0 S OW1 N IY2'],
  "taste": ['T EY1 S T'],
  "tasted": ['T EY1 S T IH0 D'],
  "tasteful": ['T EY1 S T F AH0 L'],
  "tastefully": ['T EY1 S T F AH0 L IY0'],
  "tasteless": ['T EY1 S T L AH0 S'],
  "taster": ['T EY1 S T ER0'],
  "taster's": ['T EY1 S T ER0 Z'],
  "tasters": ['T EY1 S T ER0 Z'],
  "tastes": ['T EY1 S T S'],
  "tastier": ['T EY1 S T IY0 ER0'],
  "tastiness": ['T EY1 S T IY0 N AH0 S'],
  "tasting": ['T EY1 S T IH0 NG'],
  "tastings": ['T EY1 S T IH0 NG Z'],
  "tasty": ['T EY1 S T IY0'],
  "tat": ['T AE1 T'],
  "tata": ['T AA1 T AA0'],
  "tatar": ['T AA2 T AA1 R'],
  "tatars": ['T AA2 T AA1 R Z'],
  "tatarstan": ['T AA2 T AA1 R S T AA2 N'],
  "tate": ['T EY1 T'],
  "tate's": ['T EY1 T S'],
  "tateho": ['T AH0 T EY1 HH OW0'],
  "tateho's": ['T AH0 T EY1 HH OW0 Z'],
  "tatem": ['T AE1 T IH0 M'],
  "tater": ['T EY1 T ER0'],
  "taters": ['T EY1 T ER0 Z'],
  "tatge": ['T EY1 T JH'],
  "tatham": ['T AE1 TH AH0 M'],
  "tatiana": ['T AE2 T IY2 AE1 N AA2', 'T AE2 T Y AA1 N AA2'],
  "tatjana": ['T AE2 T Y AA1 N AA2'],
  "tatlock": ['T AE1 T L AA2 K'],
  "tatman": ['T AE1 T M AH0 N'],
  "tatom": ['T AE1 T AH0 M'],
  "tatra": ['T AE1 T R AA2'],
  "tatro": ['T AE1 T R OW2'],
  "tatsch": ['T AE1 CH'],
  "tatsukichi": ['T AH0 T S UW0 K IY1 CH IY2'],
  "tatsuno": ['T AE2 T S UW1 N OW2'],
  "tatter": ['T AE1 T ER0'],
  "tattered": ['T AE1 T ER0 D'],
  "tatters": ['T AE1 T ER0 Z'],
  "tattersall": ['T AE1 T ER0 S AH0 L'],
  "tattle": ['T AE1 T AH0 L'],
  "tattled": ['T AE1 T AH0 L D'],
  "tattler": ['T AE1 T L ER0'],
  "tattoo": ['T AE2 T UW1'],
  "tattooed": ['T AE2 T UW1 D'],
  "tattooing": ['T AE2 T UW1 IH0 NG'],
  "tattoos": ['T AE2 T UW1 Z'],
  "tatty": ['T AE1 T IY2'],
  "tatu": ['T AA2 T UW1'],
  "tatum": ['T EY1 T AH0 M'],
  "tatyana": ['T AA0 T Y AA1 N AA0'],
  "taub": ['T AW1 B'],
  "taube": ['T AO1 B'],
  "tauber": ['T AW1 B ER0'],
  "taubert": ['T AW1 B ER0 T'],
  "taubes": ['T AW1 B Z'],
  "taubman": ['T AW1 B M AH0 N'],
  "taucher": ['T AW1 K ER0'],
  "tauer": ['T AW1 ER0'],
  "taught": ['T AO1 T'],
  "tauke": ['T AW1 K'],
  "taul": ['T AO1 L'],
  "taulbee": ['T AO1 L B IY2'],
  "taunt": ['T AO1 N T'],
  "taunted": ['T AO1 N T IH0 D'],
  "taunting": ['T AO1 N T IH0 NG'],
  "taunton": ['T AO1 N T AH0 N'],
  "taunts": ['T AO1 N T S'],
  "taurus": ['T AO1 R AH0 S'],
  "tauruses": ['T AO1 R AH0 S AH0 Z'],
  "tausch": ['T AW1 SH'],
  "tauscher": ['T AW1 SH ER0'],
  "taussig": ['T AW1 S IH0 G'],
  "taut": ['T AO1 T'],
  "tauzin": ['T AW1 Z IH0 N'],
  "tavano": ['T AA0 V AA1 N OW0'],
  "tavares": ['T AA0 V AA1 R EH0 S'],
  "tavarez": ['T AA0 V AA1 R EH0 Z'],
  "tavel": ['T AA0 V EH1 L'],
  "tavenner": ['T AE1 V IH0 N ER0'],
  "tavera": ['T AA0 V EH1 R AH0'],
  "taveras": ['T AA0 V EH1 R AA0 Z'],
  "tavern": ['T AE1 V ER0 N'],
  "taverna": ['T AA0 V EH1 R N AH0'],
  "taverna's": ['T AA0 V EH1 R N AH0 Z'],
  "tavernas": ['T AA0 V EH1 R N AH0 Z'],
  "tavernier": ['T AE1 V ER0 N IY0 ER0'],
  "taverns": ['T AE1 V ER0 N Z'],
  "taves": ['T EY1 V Z'],
  "tavie": ['T EY1 V IY0'],
  "tavis": ['T AA1 V IH0 S'],
  "tavish": ['T EY1 V IH0 SH'],
  "tavist": ['T AE1 V IH0 S T'],
  "tavlin": ['T AE1 V L IH0 N'],
  "tavoulareas": ['T AA0 V UW0 L EH1 R IY0 AH0 S'],
  "tavy": ['T EY1 V IY0'],
  "tawana": ['T AA2 W AA1 N AH0'],
  "tawdry": ['T AO1 D R IY0'],
  "tawes": ['T AO1 Z'],
  "tawfiq": ['T AA1 F IH0 K'],
  "tawil": ['T AO1 AH0 L'],
  "tawney": ['T AO1 N IY0'],
  "tawny": ['T AA1 N IY0'],
  "tax": ['T AE1 K S'],
  "tax's": ['T AE1 K S IH0 Z'],
  "taxability": ['T AE2 K S AH0 B IH1 L IH0 T IY0'],
  "taxable": ['T AE1 K S AH0 B AH0 L'],
  "taxables": ['T AE1 K S AH0 B AH0 L Z'],
  "taxation": ['T AE0 K S EY1 SH AH0 N'],
  "taxcut": ['T AE1 K S K AH2 T'],
  "taxed": ['T AE1 K S T'],
  "taxer": ['T AE1 K S ER0'],
  "taxers": ['T AE1 K S ER0 Z'],
  "taxes": ['T AE1 K S AH0 Z', 'T AE1 K S IH0 Z'],
  "taxes'": ['T AE1 K S IH0 Z'],
  "taxi": ['T AE1 K S IY0'],
  "taxi's": ['T AE1 K S IY0 Z'],
  "taxicab": ['T AE1 K S IY0 K AE2 B'],
  "taxicabs": ['T AE1 K S IY0 K AE2 B Z'],
  "taxidermist": ['T AE1 K S AH0 D ER2 M AH0 S T'],
  "taxidermists": ['T AE1 K S AH0 D ER2 M AH0 S T S', 'T AE1 K S AH0 D ER2 M AH0 S'],
  "taxidermy": ['T AE1 K S AH0 D ER2 M IY0'],
  "taxied": ['T AE1 K S IY0 D'],
  "taxiing": ['T AE1 K S IY0 IH0 NG'],
  "taxing": ['T AE1 K S IH0 NG'],
  "taxis": ['T AE1 K S IY0 Z'],
  "taxiway": ['T AE1 K S IY0 W EY0'],
  "taxol": ['T AE1 K S AA2 L'],
  "taxonomies": ['T AE2 K S AO1 N AH0 M IY2 Z'],
  "taxonomy": ['T AE2 K S AO1 N AH0 M IY2'],
  "taxpayer": ['T AE1 K S P EY2 ER0'],
  "taxpayer's": ['T AE1 K S P EY2 ER0 Z'],
  "taxpayers": ['T AE1 K S P EY2 ER0 Z'],
  "taxpayers'": ['T AE1 K S P EY2 ER0 Z'],
  "taxpaying": ['T AE1 K S P EY2 IH0 NG'],
  "tay": ['T EY1'],
  "tayler": ['T EY1 L ER0'],
  "tayloe": ['T EY1 L OW0'],
  "taylor": ['T EY1 L ER0'],
  "taylor's": ['T EY1 L ER0 Z'],
  "taylors": ['T EY1 L ER0 Z'],
  "tayman": ['T EY1 M AH0 N'],
  "tays": ['T EY1 Z'],
  "tb": ['T IY1 B IY1'],
  "tbilisi": ['T AH0 B IH0 L IY1 S IY0', 'T AH0 B L IY1 S IY0'],
  "tcas": ['T IY1 S IY1 EY1 EH1 S'],
  "tchaikovsky": ['CH EY2 K AA1 V S K IY0', 'CH AY2 K AA1 V S K IY0', 'CH AY2 K AA1 F S K IY0'],
  "tchaikovsky's": ['CH EY2 K AA1 V S K IY0 Z', 'CH AY2 K AA1 V S K IY0 Z', 'CH AY2 K AA1 F S K IY0 Z'],
  "tchuruk": ['CH UH1 R IH0 K'],
  "te": ['T IY1'],
  "tea": ['T IY1'],
  "teac": ['T IY1 K', 'T IY1 AE0 K'],
  "teach": ['T IY1 CH'],
  "teachable": ['T IY1 CH AH0 B AH0 L'],
  "teacher": ['T IY1 CH ER0'],
  "teacher's": ['T IY1 CH ER0 Z'],
  "teachers": ['T IY1 CH ER0 Z'],
  "teachers'": ['T IY1 CH ER0 Z'],
  "teaches": ['T IY1 CH AH0 Z', 'T IY1 CH IH0 Z'],
  "teachey": ['T IY1 CH IY0'],
  "teaching": ['T IY1 CH IH0 NG'],
  "teachings": ['T IY1 CH IH0 NG Z'],
  "teachout": ['T IY1 CH AW2 T'],
  "teacup": ['T IY1 K AH2 P'],
  "teaford": ['T IY1 F AO2 R D', 'T IY1 F ER0 D'],
  "teagarden": ['T IY1 G AA2 R D AH0 N'],
  "teagle": ['T IY1 G AH0 L'],
  "teague": ['T IY1 G'],
  "teahan": ['T IY1 AH0 N'],
  "teak": ['T IY1 K'],
  "teakamp": ['T IY1 K AE0 M P'],
  "teakwood": ['T IY1 K W UH2 D'],
  "teal": ['T IY1 L'],
  "teale": ['T IY1 L'],
  "teall": ['T IY1 L'],
  "team": ['T IY1 M'],
  "team's": ['T IY1 M Z'],
  "teamed": ['T IY1 M D'],
  "teamer": ['T IY1 M ER0'],
  "teaming": ['T IY1 M IH0 NG'],
  "teammate": ['T IY1 M EY2 T'],
  "teammates": ['T IY1 M M EY2 T S'],
  "teams": ['T IY1 M Z'],
  "teams'": ['T IY1 M Z'],
  "teamster": ['T IY1 M S T ER0'],
  "teamster's": ['T IY1 M S T ER0 Z'],
  "teamsters": ['T IY1 M S T ER0 Z'],
  "teamsters'": ['T IY1 M S T ER0 Z'],
  "teamwork": ['T IY1 M W ER2 K'],
  "teaneck": ['T IY1 N EH2 K'],
  "teaney": ['T IY1 N IY0'],
  "teapot": ['T IY1 P AA2 T'],
  "tear": ['T EH1 R', 'T IH1 R'],
  "teare": ['T IY1 R'],
  "tearful": ['T IH1 R F AH0 L'],
  "tearfully": ['T IH1 R F AH0 L IY0'],
  "tearing": ['T EH1 R IH0 NG', 'T IH1 R IH0 NG'],
  "tearle": ['T AO1 R AH0 L'],
  "tears": ['T EH1 R Z', 'T IH1 R Z'],
  "teary": ['T IH1 R IY0'],
  "teas": ['T IY1 Z'],
  "teasdale": ['T IY1 Z D EY2 L'],
  "tease": ['T IY1 Z'],
  "teased": ['T IY1 Z D'],
  "teaser": ['T IY1 Z ER0'],
  "teases": ['T IY1 Z IH0 Z'],
  "teasing": ['T IY1 Z IH0 NG'],
  "teasley": ['T IY1 Z L IY0'],
  "teason": ['T IY1 S AO0 N'],
  "teaspoon": ['T IY1 S P UW2 N'],
  "teaspoonful": ['T IY1 S P UW2 N F AH0 L'],
  "teaspoons": ['T IY1 S P UW2 N Z'],
  "teast": ['T IY1 S T'],
  "teaster": ['T IY1 S T ER0'],
  "teat": ['T IY1 T'],
  "teate": ['T IY1 EY2 T'],
  "teater": ['T IY1 T ER0'],
  "teatro": ['T IY1 T R OW0'],
  "teats": ['T IY1 T S'],
  "tebbe": ['T EH1 B'],
  "tebben": ['T EH1 B AH0 N'],
  "tebbetts": ['T EH1 B IH0 T S'],
  "tebbit": ['T EH1 B IH0 T'],
  "tebbs": ['T EH1 B Z'],
  "tebeau": ['T IH0 B OW1'],
  "tebelskis": ['T AH0 B EH1 L S K IY0 S'],
  "tebo": ['T EH1 B OW0'],
  "tebuthiuron": ['T EH2 B AH0 TH Y UW1 R AA0 N'],
  "tec": ['T EH1 K'],
  "tech": ['T EH1 K'],
  "tech's": ['T EH1 K S'],
  "techcrunch": ['T EH1 CH K R AH1 N CH'],
  "techie": ['T EH1 K IY0'],
  "techies": ['T EH1 K IY0 Z'],
  "techint": ['T EH1 CH IH0 N T', 'T EH1 K IH2 N T'],
  "technic": ['T EH1 K N IH0 K'],
  "technical": ['T EH1 K N IH0 K AH0 L'],
  "technical's": ['T EH1 K N IH0 K AH0 L Z'],
  "technicalities": ['T EH2 K N IH0 K AE1 L AH0 T IY0 Z'],
  "technicality": ['T EH2 K N IH0 K AE1 L IH0 T IY0'],
  "technically": ['T EH1 K N IH0 K AH0 L IY0', 'T EH1 K N IH0 K L IY0'],
  "technicals": ['T EH1 K N IH0 K AH0 L Z'],
  "technician": ['T EH0 K N IH1 SH AH0 N'],
  "technician's": ['T EH0 K N IH1 SH AH0 N Z'],
  "technicians": ['T EH0 K N IH1 SH AH0 N Z'],
  "technicolor": ['T EH1 K N IH0 K AH2 L ER0'],
  "technicon": ['T EH1 K N IH0 K AA2 N'],
  "technics": ['T EH1 K N IH0 K S'],
  "technique": ['T EH0 K N IY1 K'],
  "techniques": ['T EH0 K N IY1 K S'],
  "technitrol": ['T EH1 K N IH0 T R OW2 L'],
  "techno": ['T EH1 K N OW0'],
  "technocrat": ['T EH1 K N AH0 K R AE2 T'],
  "technocratic": ['T EH2 K N AH0 K R AE1 T IH0 K'],
  "technocrats": ['T EH1 K N AH0 K R AE2 T S'],
  "technodyne": ['T EH1 K N OW0 D AY2 N'],
  "technologic": ['T EH2 K N AH0 L AA1 JH IH0 K'],
  "technological": ['T EH2 K N AH0 L AA1 JH IH0 K AH0 L'],
  "technologically": ['T EH2 K N AH0 L AA1 JH IH0 K AH0 L IY0', 'T EH2 K N AH0 L AA1 JH IH0 K L IY0'],
  "technologies": ['T EH0 K N AA1 L AH0 JH IY0 Z'],
  "technologies'": ['T EH2 K N AA1 L AH0 JH IY0 Z'],
  "technologist": ['T EH2 K N AA1 L AH0 JH IH0 S T'],
  "technologists": ['T EH2 K N AA1 L AH0 JH IH0 S T S'],
  "technology": ['T EH0 K N AA1 L AH0 JH IY0'],
  "technology's": ['T EH0 K N AA1 L AH0 JH IY0 Z'],
  "technomic": ['T EH2 K N AA1 M IH0 K'],
  "technophobe": ['T EH1 K N OW0 F OW2 B'],
  "technophobe's": ['T EH1 K N OW0 F OW2 B Z'],
  "technophobes": ['T EH1 K N OW0 F OW2 B Z'],
  "techs": ['T EH1 K S'],
  "techsystem": ['T EH1 K S IH2 S T AH0 M'],
  "techsystems": ['T EH1 K S IH2 S T AH0 M Z'],
  "techy": ['T EH1 CH IY0'],
  "teck": ['T EH1 K'],
  "teck's": ['T EH1 K S'],
  "tecla": ['T EH1 K L AH0'],
  "teco": ['T IY1 K OW0'],
  "tecogen": ['T EH1 K OW0 G AH0 N', 'T EH1 K OW0 JH EH0 N'],
  "tecos": ['T IY1 K OW0 S'],
  "tectonic": ['T EH0 K T AA1 N IH0 K'],
  "tectonics": ['T EH0 K T AA1 N IH0 K S'],
  "tecumseh": ['T AH0 K AH1 M S AH0'],
  "ted": ['T EH1 D'],
  "ted's": ['T EH1 D Z'],
  "tedder": ['T EH1 D ER0'],
  "teddie": ['T EH1 D IY0'],
  "teddy": ['T EH1 D IY0'],
  "teddy's": ['T EH1 D IY0 Z'],
  "tedeschi": ['T EH0 D EH1 S K IY0'],
  "tedesco": ['T EH0 D EH1 S K OW0'],
  "tedesco's": ['T EH0 D EH1 S K OW0 Z'],
  "tedford": ['T EH1 D F ER0 D'],
  "tedious": ['T IY1 D IY0 AH0 S'],
  "tediously": ['T IY1 D IY0 AH0 S L IY0'],
  "tedium": ['T IY1 D IY0 AH0 M'],
  "tedman": ['T EH1 D M AH0 N'],
  "tedmond": ['T EH1 D M AH0 N D'],
  "tedmund": ['T EH1 D M AH0 N D'],
  "tedrick": ['T EH1 D R IH0 K'],
  "tedrow": ['T EH1 D R OW2'],
  "tee": ['T IY1'],
  "teed": ['T IY1 D'],
  "teegarden": ['T IY1 G AA2 R D AH0 N'],
  "teegardin": ['T IY0 G AA1 R D IH0 N'],
  "teehan": ['T IY1 AH0 N'],
  "teeing": ['T IY1 IH0 NG'],
  "teel": ['T IY1 L'],
  "teele": ['T IY1 L'],
  "teeley": ['T IY1 L IY0'],
  "teeling": ['T IY1 L IH0 NG'],
  "teem": ['T IY1 M'],
  "teeming": ['T IY1 M IH0 NG'],
  "teems": ['T IY1 M Z'],
  "teen": ['T IY1 N'],
  "teena": ['T IY1 N AH0'],
  "teenage": ['T IY1 N EY2 JH'],
  "teenaged": ['T IY1 N EY2 JH D'],
  "teenager": ['T IY1 N EY2 JH ER0'],
  "teenager's": ['T IY1 N EY2 JH ER0 Z'],
  "teenagers": ['T IY1 N EY2 JH ER0 Z'],
  "teenie": ['T IY1 N IY0'],
  "teens": ['T IY1 N Z'],
  "teensy": ['T IY1 N S IY0'],
  "teeny": ['T IY1 N IY0'],
  "teeple": ['T IY1 P AH0 L'],
  "teeples": ['T IY1 P AH0 L Z'],
  "teer": ['T IH1 R'],
  "tees": ['T IY1 Z'],
  "teet": ['T IY1 T'],
  "teeter": ['T IY1 T ER0'],
  "teeter's": ['T IY1 T ER0 Z'],
  "teetered": ['T IY1 T ER0 D'],
  "teetering": ['T IY1 T ER0 IH0 NG'],
  "teeters": ['T IY1 T ER0 Z'],
  "teeth": ['T IY1 TH'],
  "teethe": ['T IY1 DH'],
  "teething": ['T IY1 DH IH0 NG'],
  "teetotaler": ['T IY2 T OW2 T EY1 L ER0'],
  "teets": ['T IY1 T S'],
  "teffeteller": ['T EH1 F IH0 T EH2 L ER0'],
  "tefft": ['T EH1 F T'],
  "teflon": ['T EH1 F L AH0 N'],
  "tefra": ['T EH1 F R AH0'],
  "tegeler": ['T EH1 G AH0 L ER0'],
  "tegethoff": ['T EH1 G IH0 T AO2 F'],
  "tegge": ['T EH1 G'],
  "tegtmeier": ['T EH1 T M AY0 ER0'],
  "tegtmeyer": ['T EH1 T M AY0 ER0'],
  "tegucigalpa": ['T EH0 G UW0 S IY0 G AE1 L P AH0', 'T EH0 G UW0 CH IY0 G AA1 L P AH0'],
  "tehan": ['T EY1 AH0 N'],
  "teheran": ['T EH2 HH ER0 AA1 N'],
  "teheran's": ['T EH2 HH ER0 AA1 N Z'],
  "teherani": ['T EH2 HH ER0 AA1 N IY0'],
  "teherani's": ['T EH2 HH ER0 AA1 N IY0 Z'],
  "teheranis": ['T EH2 HH ER0 AA1 N IY0 Z'],
  "teherans": ['T EH2 HH ER0 AA1 N Z'],
  "tehran": ['T EY2 R AA1 N'],
  "tehran's": ['T EY2 R AA1 N Z'],
  "tehrani": ['T EY2 R AA1 N IY0'],
  "tehrani's": ['T EY2 R AA1 N IY0 Z'],
  "tehranis": ['T EY2 R AA1 N IY0 Z'],
  "tehrans": ['T EY2 R AA1 N Z'],
  "teich": ['T AY1 K'],
  "teicher": ['T AY1 K ER0'],
  "teichert": ['T AY1 K ER0 T'],
  "teichman": ['T AY1 K M AH0 N'],
  "teichmann": ['T AY1 K M AH0 N'],
  "teicholz": ['T AY1 K HH AO2 L T S'],
  "teig": ['T IY1 G'],
  "teige": ['T AY1 JH'],
  "teigen": ['T AY1 G AH0 N'],
  "teikoku": ['T EY2 K OW1 K UW2'],
  "teitel": ['T AY1 T AH0 L'],
  "teitelbaum": ['T AY1 T AH0 L B AW0 M'],
  "teitell": ['T AY1 T EH2 L'],
  "teixeira": ['T AH0 K S EH1 R AH0'],
  "tejada": ['T EY0 Y AA1 D AH0'],
  "tejan": ['T EY0 JH AH0 N'],
  "tejano": ['T EY0 Y AA1 N OW0'],
  "tejas": ['T IY1 JH AH0 S'],
  "tejeda": ['T EY0 Y EY1 D AH0'],
  "tejera": ['T EY0 IH1 R AH0'],
  "tejon": ['T EH1 JH AA0 N'],
  "tek": ['T EH1 K'],
  "teknowledge": ['T EH2 K N AA1 L EH0 JH'],
  "tektronix": ['T EH2 K T R AA1 N IH0 K S'],
  "tel": ['T EH1 L'],
  "tel-aviv": ['T EH1 L AA0 V IY1 V'],
  "telaction": ['T EH0 L AE1 K SH AH0 N'],
  "telamon": ['T EH1 L AH0 M AH0 N'],
  "telander": ['T IY1 L AE0 N D ER0', 'T IH0 L AE1 N D ER0'],
  "telco": ['T EH1 L K OW0'],
  "tele": ['T EH1 L IY0', 'T EH1 L AH0'],
  "telecable": ['T EH1 L AH0 K EY2 B AH0 L'],
  "telecard": ['T EH1 L AH0 K AA2 R D'],
  "telecast": ['T EH1 L AH0 K AE2 S T'],
  "telecasts": ['T EH1 L AH0 K AE2 S T S'],
  "telecharge": ['T EH1 L AH0 CH AA1 R JH'],
  "telecheck": ['T EH1 L AH0 CH EH2 K'],
  "telecom": ['T EH1 L AH0 K AA0 M'],
  "telecom's": ['T EH1 L AH0 K AA0 M Z'],
  "telecommunication": ['T EH2 L AH0 K AH0 M Y UW2 N IH0 K EY1 SH AH0 N'],
  "telecommunications": ['T EH2 L AH0 K AH0 M Y UW2 N AH0 K EY1 SH AH0 N Z'],
  "telecommunications'": ['T EH2 L AH0 K AH0 M Y UW2 N AH0 K EY1 SH AH0 N Z'],
  "telecommute": ['T EH1 L AH0 K AH0 M Y UW2 T'],
  "telecommuter": ['T EH1 L AH0 K AH0 M Y UW2 T ER0'],
  "telecommuter's": ['T EH1 L AH0 K AH0 M Y UW2 T ER0 Z'],
  "telecommuters": ['T EH1 L AH0 K AH0 M Y UW2 T ER0 Z'],
  "telecommuting": ['T EH0 L AH0 K AH0 M Y UW1 T IH0 NG'],
  "telecoms": ['T EH1 L AH0 K AA0 M Z'],
  "teleconference": ['T EH2 L AH0 K AA1 N F R AH0 N S'],
  "teleconferencing": ['T EH2 L AH0 K AA1 N F R AH0 N S IH0 NG'],
  "teleconnect": ['T EH2 L AH0 K AH0 N EH1 K T'],
  "telecrafter": ['T EH1 L AH0 K R AE2 F T ER0'],
  "telecredit": ['T EH1 L AH0 K R EH2 D IH0 T'],
  "telectron": ['T EH1 L AH0 K T R AA0 N'],
  "telectronic": ['T EH2 L AH0 K T R AA1 N IH0 K'],
  "telectronics": ['T EH2 L AH0 K T R AA1 N IH0 K S'],
  "teledesic": ['T EH2 L AH0 D EH1 S IH0 K'],
  "teledyne": ['T EH1 L AH0 D AY2 N'],
  "teledyne's": ['T EH1 L AH0 D AY2 N Z'],
  "teleflex": ['T EH1 L AH0 F L EH2 K S'],
  "telefon": ['T EH1 L AH0 F AA2 N'],
  "telefonica": ['T EH2 L AH0 F AA1 N IH0 K AH0'],
  "telefonos": ['T EH2 L EH0 F OW1 N OW0 S'],
  "telefunken": ['T EH2 L AH0 F AH1 NG K AH0 N'],
  "telegenic": ['T EH2 L AH0 JH EH1 N IH0 K'],
  "teleglobe": ['T EH1 L AH0 G L OW2 B'],
  "telegram": ['T EH1 L AH0 G R AE2 M'],
  "telegrams": ['T EH1 L AH0 G R AE2 M Z'],
  "telegraph": ['T EH1 L AH0 G R AE2 F'],
  "telegraph's": ['T EH1 L AH0 G R AE2 F S'],
  "telegraphed": ['T EH1 L AH0 G R AE2 F T'],
  "telekom": ['T EH1 L AH0 K AA0 M'],
  "telekom's": ['T EH1 L AH0 K AA0 M Z'],
  "telemacho": ['T EH1 L AH0 M AA2 CH OW0'],
  "telemanagement": ['T EH1 L AH0 M AE2 N IH0 JH M AH0 N T'],
  "telemarket": ['T EH2 L AH0 M AA1 R K IH0 T'],
  "telemarketer": ['T EH2 L AH0 M AA1 R K IH0 T ER0'],
  "telemarketers": ['T EH2 L AH0 M AA1 R K IH0 T ER0 Z'],
  "telemarketing": ['T EH2 L AH0 M AA1 R K AH0 T IH0 NG'],
  "telematic": ['T EH2 L AH0 M AE1 T IH0 K'],
  "telematics": ['T EH2 L AH0 M AE1 T IH0 K S'],
  "telemecanique": ['T EH2 L AH0 M AH0 K AE1 N IH0 K'],
  "telemedia": ['T EH2 L AH0 M IY1 D IY0 AH0'],
  "telemedicine": ['T EH2 L IH0 M EH1 D IH0 S AH0 N'],
  "telemetry": ['T AH0 L EH1 M AH0 T R IY0'],
  "telemundo": ['T EH2 L AH0 M UW1 N D OW0'],
  "telenet": ['T EH1 L AH0 N EH2 T'],
  "teleological": ['T IY2 L IY0 AH0 L AO1 JH IH0 K AH0 L'],
  "teleology": ['T IY1 L IY0 AO2 L AH0 JH IY2'],
  "telepath": ['T EH2 L AH0 P AE1 TH'],
  "telepathic": ['T EH2 L AH0 P AE1 TH AH0 K'],
  "telepathy": ['T AH0 L EH1 P AH0 TH IY0'],
  "telephone": ['T EH1 L AH0 F OW2 N'],
  "telephone's": ['T EH1 L AH0 F OW2 N Z'],
  "telephoned": ['T EH1 L AH0 F OW2 N D'],
  "telephones": ['T EH1 L AH0 F OW2 N Z'],
  "telephonic": ['T EH2 L AH0 F AA1 N IH0 K'],
  "telephonics": ['T EH2 L AH0 F AA1 N IH0 K S'],
  "telephoning": ['T EH1 L AH0 F OW2 N IH0 NG'],
  "telephoniques": ['T EH2 L AH0 F AA0 N IY1 K S'],
  "telephony": ['T EH1 L AH0 F OW2 N IY0'],
  "telephoto": ['T EH1 L AH0 F OW2 T OW0'],
  "telepicture": ['T EH1 L AH0 P IH1 K CH ER0'],
  "telepictures": ['T EH1 L AH0 P IH1 K CH ER0 Z'],
  "teleport": ['T EH1 L AH0 P AO1 R T'],
  "teleport's": ['T EH1 L AH0 P AO1 R T S'],
  "teleprobe": ['T EH1 L AH0 P R OW1 B'],
  "teleprompter": ['T EH1 L AH0 P R AA2 M P T ER0'],
  "telequest": ['T EH1 L AH0 K W EH1 S T'],
  "telerate": ['T EH1 L ER0 EY1 T'],
  "telesat": ['T EH1 L AH0 S AE0 T'],
  "telescience": ['T EH1 L AH0 S AY2 AH0 N S'],
  "telesciences": ['T EH1 L AH0 S AY2 AH0 N S IH0 Z'],
  "telesco": ['T EH0 L EH1 S K OW0'],
  "telescope": ['T EH1 L AH0 S K OW2 P'],
  "telescope's": ['T EH1 L AH0 S K OW2 P S'],
  "telescopes": ['T EH1 L AH0 S K OW2 P S'],
  "telescopic": ['T EH2 L AH0 S K AO1 P IH0 K'],
  "telescript": ['T EH1 L AH0 S K R IH2 P T'],
  "telesis": ['T EH1 L AH0 S IH0 S'],
  "telesis'": ['T EH1 L AH0 S IH2 S'],
  "telesis's": ['T EH1 L AH0 S IH0 S IH0 Z'],
  "telesphere": ['T EH1 L AH0 S F IH2 R'],
  "telestrator": ['T EH1 L AH0 S T R EY2 T ER0'],
  "telesystem": ['T EH1 L AH0 S IH2 S T AH0 M'],
  "teletext": ['T EH1 L AH0 T EH1 K S T'],
  "telethon": ['T EH1 L AH0 TH AA0 N'],
  "teletron": ['T EH1 L AH0 T R AA2 N'],
  "telettra": ['T EH1 L EH0 T R AH0'],
  "teletype": ['T EH1 L AH0 T AY2 P'],
  "teletypes": ['T EH1 L AH0 T AY2 P S'],
  "televangelist": ['T EH2 L AH0 V AE1 N JH AH0 L AH0 S T'],
  "televangelists": ['T EH2 L AH0 V AE1 N JH AH0 L AH0 S T S'],
  "televideo": ['T EH2 L AH0 V IH1 D IY0 OW0'],
  "televisa": ['T EH1 L AH0 V IY1 Z AH0'],
  "televisa's": ['T EH2 L AH0 V IY1 Z AH0 Z'],
  "televise": ['T EH1 L AH0 V AY2 Z'],
  "televised": ['T EH1 L AH0 V AY2 Z D'],
  "televising": ['T EH1 L AH0 V AY2 Z IH0 NG'],
  "television": ['T EH1 L AH0 V IH2 ZH AH0 N'],
  "television's": ['T EH1 L AH0 V IH2 ZH AH0 N Z'],
  "televisions": ['T EH1 L AH0 V IH2 ZH AH0 N Z'],
  "televison": ['T EH1 L IH0 V IH0 ZH AH0 N'],
  "telewest": ['T EH1 L AH0 W EH1 S T'],
  "telex": ['T EH1 L EH2 K S'],
  "telex's": ['T EH1 L EH2 K S IH0 Z'],
  "telexed": ['T EH1 L EH2 K S T'],
  "telexes": ['T EH1 L EH2 K S IH0 S'],
  "telfair": ['T EH1 L F EH2 R'],
  "telfer": ['T EH1 L F ER0'],
  "telfor": ['T EH1 L F ER0'],
  "telford": ['T EH1 L F ER0 D'],
  "telfour": ['T EH1 L F ER0'],
  "telit": ['T EH1 L IH2 T'],
  "tell": ['T EH1 L'],
  "tellabs": ['T EH1 L AE2 B Z'],
  "tellefsen": ['T EH1 L IH0 F S AH0 N'],
  "tellep": ['T EH1 L AH0 P'],
  "teller": ['T EH1 L ER0'],
  "teller's": ['T EH1 L ER0 Z'],
  "tellers": ['T EH1 L ER0 Z'],
  "telles": ['T EH1 L Z'],
  "tellez": ['T EY0 L EH1 Z'],
  "tellier": ['T EH1 L IY0 ER0'],
  "tellin'": ['T EH1 L IH0 N'],
  "telling": ['T EH1 L IH0 NG'],
  "tellingly": ['T EH1 L IH0 NG L IY0'],
  "tellis": ['T EH1 L IH0 S'],
  "tello": ['T EH1 L OW0'],
  "tells": ['T EH1 L Z'],
  "telltale": ['T EH1 L T EY2 L'],
  "telltales": ['T EH1 L T EY2 L Z'],
  "telluride": ['T EH1 L Y ER0 AY2 D'],
  "tellurides": ['T EH1 L Y ER0 AY2 D Z'],
  "tellurium": ['T EH0 L UH1 R IY0 AH0 M'],
  "telly": ['T EH1 L IY0'],
  "telmex": ['T EH1 L M EH2 K S'],
  "telmex's": ['T EH1 L M EH2 K S IH0 Z'],
  "telomerase": ['T AH0 L OW1 M ER0 EY2 Z'],
  "telomere": ['T EH1 L AH0 M IY0 R'],
  "telomeres": ['T EH1 L AH0 M IY0 R Z'],
  "telos": ['T EH1 L OW0 S'],
  "telsource": ['T EH1 L S AO2 R S'],
  "telstar": ['T EH1 L S T AA2 R'],
  "telstra": ['T EH1 L S T R AH0'],
  "teltschik": ['T EH1 L CH IH0 K'],
  "telugu": ['T EH1 L UH0 G UW0'],
  "telustrator": ['T EH1 L AH0 S T R EY2 T ER0', 'T EH1 L Y AH0 S T R EY2 T ER0'],
  "telxon": ['T EH1 L Z AA0 N'],
  "telxon's": ['T EH1 L Z AH0 N Z'],
  "telzrow": ['T EH1 L Z R OW0'],
  "temblor": ['T EH1 M B L ER0'],
  "temblors": ['T EH1 M B L ER0 Z'],
  "temco": ['T EH1 M K OW0'],
  "temecula": ['T AH0 M EH1 K Y AH0 L AH0'],
  "temerity": ['T AH0 M EH1 R AH0 T IY0'],
  "temerlin": ['T EH1 M ER0 L IH0 N'],
  "temerous": ['T EH1 M ER0 AH0 S'],
  "temkin": ['T EH1 M K IH0 N'],
  "temme": ['T EH1 M'],
  "temp": ['T EH1 M P'],
  "tempe": ['T EH1 M P IY0'],
  "tempel": ['T EH1 M P AH0 L'],
  "tempelsman": ['T EH1 M P AH0 L Z M AH0 N'],
  "temper": ['T EH1 M P ER0'],
  "tempera": ['T EH1 M P ER0 AH0'],
  "temperament": ['T EH1 M P R AH0 M AH0 N T', 'T EH1 M P ER0 M AH0 N T'],
  "temperamental": ['T EH2 M P R AH0 M EH1 N T AH0 L', 'T EH2 M P ER0 M EH1 N T AH0 L'],
  "temperamentally": ['T EH2 M P R AH0 M EH1 N T AH0 L IY0', 'T EH2 M P ER0 M EH1 N T AH0 L IY0', 'T EH2 M P R AH0 M EH1 N AH0 L IY0', 'T EH2 M P ER0 M EH1 N AH0 L IY0'],
  "temperaments": ['T EH1 M P R AH0 M AH0 N T S', 'T EH1 M P ER0 M AH0 N T S'],
  "temperance": ['T EH1 M P ER0 AH0 N S', 'T EH1 M P R AH0 N S'],
  "temperate": ['T EH1 M P R AH0 T', 'T EH1 M P ER0 AH0 T'],
  "temperature": ['T EH1 M P R AH0 CH ER0', 'T EH1 M P ER0 AH0 CH ER0'],
  "temperatures": ['T EH1 M P R AH0 CH ER0 Z', 'T EH1 M P ER0 AH0 CH ER0 Z'],
  "tempered": ['T EH1 M P ER0 D'],
  "tempering": ['T EH1 M P ER0 IH0 NG'],
  "tempers": ['T EH1 M P ER0 Z'],
  "tempest": ['T EH1 M P AH0 S T'],
  "tempesta": ['T EH2 M P EH1 S T AH0'],
  "tempestuous": ['T EH2 M P EH1 S CH UW0 AH0 S'],
  "templar": ['T EH1 M P L ER0'],
  "templars": ['T EH1 M P L ER0 Z'],
  "template": ['T EH1 M P L AH0 T', 'T EH1 M P L EY0 T'],
  "templates": ['T EH1 M P L AH0 T S'],
  "temple": ['T EH1 M P AH0 L'],
  "temple's": ['T EH1 M P AH0 L Z'],
  "templeman": ['T EH1 M P AH0 L M AH0 N'],
  "templer": ['T EH1 M P AH0 L ER0', 'T EH1 M P L ER0'],
  "templers": ['T EH1 M P AH0 L ER0 Z', 'T EH1 M P L ER0 Z'],
  "temples": ['T EH1 M P AH0 L Z'],
  "templet": ['T EH1 M P L IH0 T'],
  "templeton": ['T EH1 M P AH0 L T AH0 N'],
  "templeton's": ['T EH1 M P AH0 L T AH0 N Z'],
  "templin": ['T EH1 M P L IH0 N'],
  "tempo": ['T EH1 M P OW2'],
  "temporal": ['T EH1 M P ER0 AH0 L'],
  "temporally": ['T EH1 M P ER0 AH0 L IY0'],
  "temporaries": ['T EH1 M P ER0 EH2 R IY0 Z'],
  "temporarily": ['T EH2 M P ER0 EH1 R AH0 L IY0'],
  "temporary": ['T EH1 M P ER0 EH2 R IY0'],
  "tempore": ['T EH1 M P AO0 R', 'T EH2 M P AO1 R IY0'],
  "temporize": ['T EH1 M P ER0 AY2 Z'],
  "temporizing": ['T EH1 M P ER0 AY2 Z IH0 NG'],
  "tempos": ['T EH1 M P OW2 Z'],
  "temps": ['T EH1 M P S'],
  "tempt": ['T EH1 M P T'],
  "temptation": ['T EH0 M T EY1 SH AH0 N'],
  "temptations": ['T EH0 M T EY1 SH AH0 N Z'],
  "tempted": ['T EH1 M P T IH0 D'],
  "tempting": ['T EH1 M P T IH0 NG'],
  "temptress": ['T EH1 M P T R IH0 S'],
  "tempts": ['T EH1 M P T S'],
  "ten": ['T EH1 N'],
  "ten's": ['T EH1 N Z'],
  "tena": ['T EH1 N AH0'],
  "tenable": ['T EH1 N AH0 B AH0 L'],
  "tenacious": ['T AH0 N EY1 SH AH0 S'],
  "tenaciously": ['T AH0 N EY1 SH AH0 S L IY0'],
  "tenacity": ['T AH0 N AE1 S IH0 T IY0'],
  "tenaglia": ['T EH0 N AA1 G L IY0 AH0'],
  "tenancy": ['T EH1 N AH0 N S IY0'],
  "tenant": ['T EH1 N AH0 N T'],
  "tenant's": ['T EH1 N AH0 N T S'],
  "tenants": ['T EH1 N AH0 N T S'],
  "tenants'": ['T EH1 N AH0 N T S'],
  "tenbrink": ['T EH1 N B R IH2 NG K'],
  "tench": ['T EH1 N CH'],
  "tencza": ['T EH1 N CH AH0'],
  "tend": ['T EH1 N D'],
  "tended": ['T EH1 N D IH0 D'],
  "tendencies": ['T EH1 N D AH0 N S IY0 Z'],
  "tendency": ['T EH1 N D AH0 N S IY0'],
  "tendentious": ['T EH2 N D EH1 N SH AH0 S'],
  "tender": ['T EH1 N D ER0'],
  "tendered": ['T EH1 N D ER0 D'],
  "tendering": ['T EH1 N D ER0 IH0 NG'],
  "tenderloin": ['T EH1 N D ER0 L OY2 N'],
  "tenderly": ['T EH1 N D ER0 L IY0'],
  "tenderness": ['T EH1 N D ER0 N AH0 S'],
  "tenders": ['T EH1 N D ER0 Z'],
  "tending": ['T EH1 N D IH0 NG'],
  "tendler": ['T EH1 N D L ER0'],
  "tendon": ['T EH1 N D AH0 N'],
  "tendons": ['T EH1 N D AH0 N Z'],
  "tends": ['T EH1 N D Z'],
  "tenebrous": ['T EH1 N AH0 B R AH0 S'],
  "tenement": ['T EH1 N AH0 M AH0 N T'],
  "tenements": ['T EH1 N AH0 M AH0 N T S'],
  "tenenbaum": ['T EH1 N AH0 N B AW2 M'],
  "tener": ['T EH1 N ER0'],
  "tenera": ['T EH2 N EH1 R AH0'],
  "tenet": ['T EH1 N AH0 T'],
  "tenets": ['T EH1 N AH0 T S'],
  "teneyck": ['T EH1 N IY0 K'],
  "tenfold": ['T EH1 N F OW2 L D'],
  "teng": ['T EH1 NG'],
  "teng-wen": ['T EH1 NG W EH1 N'],
  "tengelmann": ['T EH1 NG G AH0 L M AH0 N'],
  "tengiz": ['T EH1 NG G IH0 Z'],
  "tenglemann": ['T EH1 NG G AH0 L M AH0 N'],
  "tenn": ['T EH1 N'],
  "tennant": ['T EH1 N AH0 N T'],
  "tenncare": ['T EH1 N K EH2 R'],
  "tenncare's": ['T EH1 N K EH2 R Z'],
  "tenneco": ['T EH1 N AH0 K OW0'],
  "tenneco's": ['T EH1 N AH0 K OW0 Z'],
  "tennell": ['T EH1 N AH0 L'],
  "tennenbaum": ['T EH1 N AH0 N B AW2 M'],
  "tennent": ['T EH1 N AH0 N T'],
  "tenner": ['T EH1 N ER0'],
  "tennessean": ['T EH2 N IH0 S IY1 AH0 N'],
  "tennesseans": ['T EH2 N IH0 S IY1 AH0 N Z'],
  "tennessee": ['T EH2 N AH0 S IY1'],
  "tennessee's": ['T EH2 N AH0 S IY1 Z'],
  "tenney": ['T EH1 N IY0'],
  "tennis": ['T EH1 N AH0 S', 'T EH1 N IH0 S'],
  "tennis's": ['T EH1 N AH0 S IH0 Z'],
  "tennison": ['T EH1 N IH0 S AH0 N'],
  "tenny": ['T EH1 N IY0'],
  "tennyson": ['T EH1 N IH0 S AH0 N'],
  "tenor": ['T EH1 N ER0'],
  "tenore": ['T EH1 N AO2 R'],
  "tenorio": ['T EH0 N AO1 R IY0 OW0'],
  "tenors": ['T EH1 N ER0 Z'],
  "tenpas": ['T EH1 N P AH0 Z'],
  "tenpenny": ['T EH1 N P EH2 N IY0'],
  "tenrecs": ['T EH1 N R EH2 K S'],
  "tens": ['T EH1 N Z'],
  "tense": ['T EH1 N S'],
  "tensely": ['T EH1 N S L IY0'],
  "tensile": ['T EH1 N S AH0 L'],
  "tensiometer": ['T EH2 N S IY0 AA1 M IH0 T ER0'],
  "tension": ['T EH1 N SH AH0 N'],
  "tensions": ['T EH1 N CH AH0 N Z'],
  "tensor": ['T EH1 N S ER0'],
  "tensors": ['T EH1 N S ER0 Z'],
  "tent": ['T EH1 N T'],
  "tentacle": ['T EH1 N T AH0 K AH0 L'],
  "tentacles": ['T EH1 N T AH0 K AH0 L Z'],
  "tentative": ['T EH1 N T AH0 T IH0 V', 'T EH1 N AH0 T IH0 V'],
  "tentatively": ['T EH1 N T AH0 T IH0 V L IY0', 'T EH1 N T AH0 V L IY0'],
  "tenth": ['T EH1 N TH'],
  "tenths": ['T EH1 N TH S'],
  "tenting": ['T EH1 N T IH0 NG'],
  "tents": ['T EH1 N T S'],
  "tenuous": ['T EH1 N Y AH0 W AH0 S'],
  "tenure": ['T EH1 N Y ER0'],
  "tenured": ['T EH1 N Y ER0 D'],
  "tenures": ['T EH1 N Y ER0 Z'],
  "tenuta": ['T EH0 N UW1 T AH0'],
  "tenzer": ['T EH1 N Z ER0'],
  "teo": ['T EY1 OW0'],
  "teodoro": ['T IY2 OW0 D AO1 R OW0'],
  "tepe": ['T IY1 P'],
  "teper": ['T IY1 P ER0'],
  "tepid": ['T EH1 P IH0 D'],
  "teply": ['T EH1 P L IY0'],
  "tepoztlan": ['T IH0 P AO1 S T L AH0 N'],
  "tepper": ['T EH1 P ER0'],
  "tepperman": ['T EH1 P ER0 M AH0 N'],
  "tequila": ['T AH0 K IY1 L AH0'],
  "tequiliu": ['T AH0 K IY1 L Y UW0'],
  "ter": ['T ER1'],
  "terabyte": ['T EH2 R AH0 B AY1 T'],
  "terabytes": ['T EH2 R AH0 B AY1 T S'],
  "terada": ['T ER0 AA1 D AH0'],
  "teradata": ['T EH2 R AH0 D AA1 T AH0'],
  "teradyne": ['T EH1 R AH0 D AY2 N'],
  "teradyne's": ['T EH1 R AH0 D AY2 N Z'],
  "teran": ['T EH1 R AH0 N'],
  "terasawa": ['T EH2 R AH0 S AA1 W AH0'],
  "terbush": ['T ER1 B AH0 SH', 'T ER1 B UH0 SH'],
  "tercel": ['T ER1 S AH0 L'],
  "tercero": ['T ER0 CH EH1 R OW0'],
  "terence": ['T EH1 R AH0 N S'],
  "terence's": ['T EH1 R AH0 N S IH0 Z'],
  "terentia": ['T ER0 EH1 N SH AH0'],
  "teresa": ['T ER0 IY1 S AH0', 'T ER0 EY1 S AH0'],
  "teresa's": ['T ER0 IY1 S AH0 Z', 'T ER0 EY1 S AH0 Z'],
  "terese": ['T EH1 R IY0 Z'],
  "teresi": ['T ER0 EH1 S IY0'],
  "teresita": ['T ER0 EH0 S IY1 T AH0'],
  "teressa": ['T ER0 EH1 S AH0'],
  "terex": ['T EH1 R AH0 K S'],
  "tereza": ['T ER0 EY1 Z AH0'],
  "tereza's": ['T ER0 EY1 Z AH0 Z'],
  "terhaar": ['T ER1 HH AA0 R'],
  "terhorst": ['T ER1 HH AO0 R S T'],
  "terhune": ['T ER0 HH Y UW1 N'],
  "teri": ['T EH1 R IY0'],
  "teriyaki": ['T EH2 R IH0 Y AA1 K IY0'],
  "terkel": ['T ER1 K AH0 L'],
  "terkhorn": ['T ER1 K AO2 R N'],
  "terlecki": ['T ER0 L EH1 T S K IY0'],
  "terlizzi": ['T ER0 L IY1 T S IY0'],
  "term": ['T ER1 M'],
  "term's": ['T ER1 M Z'],
  "terman": ['T ER1 M AH0 N'],
  "termed": ['T ER1 M D'],
  "termeer": ['T ER0 M IH1 R'],
  "termer": ['T ER1 M ER0'],
  "termers": ['T ER1 M ER0 Z'],
  "terminable": ['T ER1 M AH0 N AH0 B AH0 L'],
  "terminal": ['T ER1 M AH0 N AH0 L'],
  "terminally": ['T ER1 M AH0 N AH0 L IY0'],
  "terminals": ['T ER1 M AH0 N AH0 L Z'],
  "terminate": ['T ER1 M AH0 N EY2 T'],
  "terminated": ['T ER1 M AH0 N EY2 T IH0 D'],
  "terminates": ['T ER1 M IH0 N EY2 T S'],
  "terminating": ['T ER1 M AH0 N EY2 T IH0 NG'],
  "termination": ['T ER0 M AH0 N EY1 SH AH0 N'],
  "terminations": ['T ER2 M AH0 N EY1 SH AH0 N Z'],
  "terminator": ['T ER1 M AH0 N EY2 T ER0'],
  "termine": ['T ER1 M IH0 N'],
  "terming": ['T ER1 M IH0 NG'],
  "termini": ['T ER1 M IH0 N AY2'],
  "terminology": ['T ER2 M IH0 N AA1 L AH0 JH IY0'],
  "terminus": ['T ER1 M IH0 N AH0 S'],
  "termite": ['T ER1 M AY0 T'],
  "termites": ['T ER1 M AY0 T S'],
  "terms": ['T ER1 M Z'],
  "tern": ['T ER1 N Z'],
  "ternes": ['T ER1 N Z'],
  "terns": ['T ER1 N Z'],
  "terpening": ['T ER1 P AH0 N IH0 NG'],
  "terpsichorde": ['T ER1 P S IH0 K AO2 R D'],
  "terpstra": ['T EH1 R P S T R AA2'],
  "terra": ['T EH1 R AA2'],
  "terra's": ['T EH1 R AH0 Z'],
  "terracciano": ['T ER0 AA0 CH IY0 AA1 N OW0'],
  "terrace": ['T EH1 R AH0 S'],
  "terraced": ['T EH1 R AH0 S T'],
  "terraces": ['T EH1 R AH0 S AH0 Z', 'T EH1 R AH0 S IH0 Z'],
  "terracotta": ['T ER2 AH0 K AA1 T AH0'],
  "terrain": ['T ER0 EY1 N'],
  "terrains": ['T ER0 EY1 N Z'],
  "terral": ['T EH1 R AH0 L'],
  "terrana": ['T ER0 AE1 N AA2'],
  "terrance": ['T EH1 R AH0 N S'],
  "terrano": ['T ER0 AA1 N OW2'],
  "terranova": ['T ER0 AA0 N OW1 V AH0'],
  "terrasi": ['T ER0 AA1 S IY2'],
  "terrazas": ['T EH0 R AA1 Z AA2 Z'],
  "terre": ['T EH1 R AH0', 'T EH1 R'],
  "terre-haute": ['T EH1 R AH0 HH OW1 T', 'T EH1 R AH0 HH AH1 T'],
  "terrebonne": ['T ER0 EH0 B OW1 N IY0', 'T ER0 AH0 B AH1 N'],
  "terrel": ['T EH1 R AH0 L'],
  "terrell": ['T EH1 R AH0 L'],
  "terrence": ['T EH1 R AH0 N S'],
  "terrestrial": ['T ER0 EH1 S T R IY0 AH0 L'],
  "terri": ['T EH1 R IY0'],
  "terrible": ['T EH1 R AH0 B AH0 L'],
  "terribly": ['T EH1 R AH0 B L IY0'],
  "terrie": ['T EH1 R IY0'],
  "terrien": ['T EH1 R IY0 AH0 N'],
  "terrier": ['T EH1 R IY0 ER0'],
  "terriers": ['T EH1 R IY0 ER0 Z'],
  "terrific": ['T ER0 IH1 F IH0 K'],
  "terrifically": ['T ER0 IH1 F IH0 K L IY0'],
  "terrified": ['T EH1 R AH0 F AY2 D'],
  "terrifies": ['T EH1 R AH0 F AY2 Z'],
  "terrify": ['T EH1 R AH0 F AY2'],
  "terrifying": ['T EH1 R AH0 F AY2 IH0 NG'],
  "terrile": ['T EH2 R IY1 L'],
  "terrill": ['T EH1 R AH0 L'],
  "terrington": ['T EH1 R IH0 NG T AH0 N'],
  "terrio": ['T EH1 R IY0 OW0'],
  "terris": ['T EH1 R IH0 S'],
  "territo": ['T ER0 IY1 T OW0'],
  "territorial": ['T EH2 R IH0 T AO1 R IY0 AH0 L'],
  "territorialism": ['T EH2 R AH0 T AO1 R IY0 AH0 L IH2 Z AH0 M'],
  "territorially": ['T EH2 R IH0 T AO1 R IY0 AH0 L IY0'],
  "territories": ['T EH1 R AH0 T AO2 R IY0 Z'],
  "territory": ['T EH1 R IH0 T AO2 R IY0'],
  "territory's": ['T EH1 R IH0 T AO2 R IY0 Z'],
  "terrizzi": ['T EH2 R IH1 T S IY0'],
  "terrones": ['T EH1 R AH0 N Z'],
  "terror": ['T EH1 R ER0'],
  "terrorism": ['T EH1 R ER0 IH2 Z AH0 M'],
  "terrorist": ['T EH1 R ER0 IH0 S T'],
  "terroristic": ['T EH2 R ER0 IH1 S T IH0 K'],
  "terrorists": ['T EH1 R ER0 AH0 S T S', 'T EH1 R ER0 IH0 S T S'],
  "terrorists'": ['T EH1 R ER0 IH0 S T S'],
  "terrorize": ['T EH1 R ER0 AY2 Z'],
  "terrorized": ['T EH1 R ER0 AY2 Z D'],
  "terrorizes": ['T EH1 R ER0 AY2 Z IH0 Z'],
  "terrorizing": ['T EH1 R ER0 AY2 Z IH0 NG'],
  "terrors": ['T EH1 R ER0 Z'],
  "terry": ['T EH1 R IY0'],
  "terry's": ['T EH1 R IY0 Z'],
  "terse": ['T ER1 S'],
  "tersely": ['T ER1 S L IY0'],
  "terseness": ['T ER1 S N AH0 S'],
  "tertia": ['T EH1 R SH AH0'],
  "tertiary": ['T ER1 SH ER0 IY0', 'T ER1 SH IY0 EH2 R IY0'],
  "tertius": ['T ER1 T IY0 IH0 S', 'T ER1 SH Y IH0 S'],
  "teruko": ['T EH1 R UW0 K OW0'],
  "teruya": ['T EH0 R UW1 Y AH0'],
  "tervo": ['T EH1 R V OW0'],
  "terwilliger": ['T ER1 W IH0 L IH0 G ER0'],
  "terzian": ['T ER1 Z IY0 AH0 N'],
  "tesar": ['T IH0 S AA1 R'],
  "tesch": ['T EH1 SH'],
  "teschner": ['T EH1 SH N ER0'],
  "tesco": ['T EH1 S K OW0'],
  "tese": ['T IY1 S'],
  "tesh": ['T EH1 SH'],
  "teska": ['T EH1 S K AH0'],
  "teske": ['T EH1 S K'],
  "tesla": ['T EH1 S L AH0', 'T EH1 Z L AH0'],
  "tesler": ['T EH1 S AH0 L ER0', 'T EH1 S L ER0'],
  "teslik": ['T EH1 S L IH0 K'],
  "tesmer": ['T EH1 S AH0 M ER0'],
  "tesobono": ['T EH2 S AH0 B OW1 N OW0'],
  "tesobonos": ['T EH2 S AH0 B OW1 N OW0 S', 'T EH2 S AH0 B OW1 N OW0 Z'],
  "tesol": ['T IY1 S AO0 L'],
  "tesoriero": ['T EH0 S AO0 R IH1 R OW0'],
  "tesoro": ['T EH0 S AO1 R OW0'],
  "tesoro's": ['T EH0 S AO1 R OW0 Z'],
  "tess": ['T EH1 S'],
  "tessa": ['T EH1 S AH0'],
  "tesselate": ['T EH1 S AH0 L EY2 T'],
  "tesselated": ['T EH1 S AH0 L EY2 T IH0 D'],
  "tessick": ['T EH1 S IH0 K'],
  "tessie": ['T EH1 S IY0'],
  "tessier": ['T EH1 S IY0 ER0'],
  "tessitore": ['T EH0 S IY0 T AO1 R IY0'],
  "tessler": ['T EH1 S L ER0'],
  "tessman": ['T EH1 S M AH0 N'],
  "tessmer": ['T EH1 S M ER0'],
  "tessy": ['T EH1 S IY0'],
  "test": ['T EH1 S T'],
  "test's": ['T EH1 S T S'],
  "testa": ['T EH1 S T AH0'],
  "testament": ['T EH1 S T AH0 M AH0 N T'],
  "testamentary": ['T EH2 S T AH0 M EH1 N T ER0 IY0'],
  "testaments": ['T EH1 S T AH0 M AH0 N T S'],
  "tested": ['T EH1 S T IH0 D'],
  "tester": ['T EH1 S T ER0'],
  "testerman": ['T EH1 S T ER0 M AH0 N'],
  "testers": ['T EH1 S T ER0 Z', 'T EH1 S T AH0 Z'],
  "testes": ['T EH1 S T IY2 Z', 'T EH1 S T S'],
  "testicle": ['T EH1 S T IH2 K AH0 L'],
  "testicles": ['T EH1 S T IH2 K AH0 L Z'],
  "testicular": ['T EH2 S T IH1 K Y AH0 L ER0'],
  "testified": ['T EH1 S T AH0 F AY2 D'],
  "testifies": ['T EH1 S T AH0 F AY2 Z'],
  "testify": ['T EH1 S T AH0 F AY2'],
  "testifying": ['T EH1 S T AH0 F AY2 IH0 NG'],
  "testily": ['T EH1 S T AH0 L IY0'],
  "testimonial": ['T EH2 S T AH0 M OW1 N IY0 AH0 L'],
  "testimonials": ['T EH2 S T AH0 M OW1 N IY0 AH0 L Z'],
  "testimonies": ['T EH1 S T AH0 M OW2 N IY0 Z'],
  "testimony": ['T EH1 S T AH0 M OW2 N IY0'],
  "testing": ['T EH1 S T IH0 NG'],
  "teston": ['T EH1 S T AH0 N'],
  "testor": ['T EH1 S T ER0'],
  "testosterone": ['T EH2 S T AA1 S T ER0 OW2 N'],
  "testrake": ['T EH1 S T R EY2 K'],
  "tests": ['T EH1 S T S'],
  "tests'": ['T EH1 S T S'],
  "testuro": ['T EH2 S T UH1 R OW0'],
  "testy": ['T EH1 S T IY0'],
  "tet": ['T EH1 T'],
  "teta": ['T EH1 T AH0'],
  "tetanus": ['T EH1 T AH0 N AH0 S'],
  "tete": ['T EH1 T'],
  "teter": ['T IY1 T ER0'],
  "teters": ['T IY1 T ER0 Z'],
  "tether": ['T EH1 DH ER0'],
  "tethered": ['T EH1 DH ER0 D'],
  "tethers": ['T EH1 DH ER0 Z'],
  "teti": ['T EH1 T IY0'],
  "tetley": ['T EH1 T L IY0'],
  "tetlow": ['T EH1 T L OW0'],
  "teton": ['T IY1 T AH0 N', 'T IY1 T AA0 N'],
  "tetons": ['T IY1 T AH0 N Z', 'T IY1 T AA0 N Z'],
  "tetra": ['T EH1 T R AH0'],
  "tetrads": ['T EH1 T R AE2 D Z'],
  "tetrahedral": ['T EH2 T R AH0 HH IY1 D R AH0 L'],
  "tetrahedron": ['T EH2 T R AH0 HH IY1 D R AH0 N'],
  "tetramerous": ['T EH2 T R AE1 M ER0 AH0 S'],
  "tetrault": ['T EH1 T R AW0 L T'],
  "tetravalent": ['T EH2 T R AH0 V EY1 L AH0 N T'],
  "tetreault": ['T IH0 T R OW1'],
  "tetrick": ['T EH1 T R IH0 K'],
  "tetro": ['T EH1 T R OW0'],
  "tetsuji": ['T EH2 T S UW1 JH IY0'],
  "tetsuo": ['T EH1 T S UW2 OW0'],
  "tettamanti": ['T EH2 T AH0 M AE1 N T IY0'],
  "tetterton": ['T EH1 T ER0 T AH0 N'],
  "tetzlaff": ['T EH1 T Z L AH0 F'],
  "tetzloff": ['T EH1 T Z L AO0 F'],
  "teuber": ['T OY1 B ER0'],
  "teubner": ['T OY1 B N ER0'],
  "teufel": ['T OY1 F AH0 L'],
  "teuscher": ['T OY1 SH ER0'],
  "teutonic": ['T UW0 T AO1 N IH0 K'],
  "teutsch": ['T OY1 CH'],
  "teva": ['T EY1 V AH0'],
  "tevatron": ['T EH1 V AH0 T R AA0 N'],
  "teves": ['T IY1 V Z'],
  "tevis": ['T EH1 V IH0 S'],
  "tevlin": ['T EH1 V L IH0 N'],
  "tevye": ['T EH1 V IY0'],
  "tew": ['CH UW1', 'T UW1', 'T IY1 IY1 D AH1 B AH0 L Y UW2'],
  "tewell": ['T EH1 W EH0 L'],
  "tewes": ['CH UW1 Z'],
  "tewksbury": ['T UW1 K S B ER0 IY0'],
  "tews": ['CH UW1 Z'],
  "tex": ['T EH1 K S'],
  "texaco": ['T EH1 K S AH0 K OW0'],
  "texaco's": ['T EH1 K S AH0 K OW0 Z'],
  "texan": ['T EH1 K S AH0 N'],
  "texan's": ['T EH1 K S AH0 N Z'],
  "texans": ['T EH1 K S AH0 N Z'],
  "texarkana": ['T EH2 K S AA0 R K AE1 N AH0'],
  "texas": ['T EH1 K S AH0 S'],
  "texas'": ['T EH1 K S AH0 S IH0 Z', 'T EH1 K S AH0 S'],
  "texas's": ['T EH1 K S AH0 S AH0 Z', 'T EH1 K S AH0 S IH0 Z'],
  "texasgulf": ['T EH1 K S AH0 S G AH2 L F'],
  "texeira": ['T EY0 K S EH1 R AH0'],
  "texfi": ['T EH1 K S F IY0'],
  "texpool": ['T EH1 K S P UW2 L'],
  "texscan": ['T EH1 K S K AE2 N'],
  "texstar": ['T EH1 K S T AA2 R'],
  "texstyrene": ['T EH1 K S T AY1 R IY2 N'],
  "text": ['T EH1 K S T'],
  "textbook": ['T EH1 K S T B UH2 K'],
  "textbooks": ['T EH1 K S T B UH2 K S'],
  "texted": ['T EH1 K S T IH0 D'],
  "texter": ['T EH1 K S T ER0'],
  "texters": ['T EH1 K S T ER0 Z'],
  "textile": ['T EH1 K S T AY2 L'],
  "textiles": ['T EH1 K S T AY2 L Z'],
  "texting": ['T EH1 K S T IH2 NG'],
  "textor": ['T EH1 K S T ER0'],
  "textron": ['T EH1 K S T R AA0 N'],
  "textron's": ['T EH1 K S T R AA0 N Z'],
  "texts": ['T EH1 K S T S'],
  "textual": ['T EH1 K S CH UW2 AH0 L'],
  "texture": ['T EH1 K S CH ER0'],
  "textured": ['T EH1 K S CH ER0 D'],
  "textures": ['T EH1 K S CH ER0 Z'],
  "tezak": ['T EH1 Z AH0 K'],
  "tezeno": ['T EY0 Z EY1 N OW0'],
  "th": ['T IY1 EY1 CH'],
  "thabo": ['TH EY1 B OW0'],
  "thach": ['TH AE1 CH'],
  "thacher": ['TH AE1 K ER0'],
  "thacker": ['TH AE1 K ER0'],
  "thackeray": ['TH AE1 K ER0 IY0'],
  "thackery's": ['TH AE1 K ER0 IY0 Z'],
  "thackston": ['TH AE1 K S T AH0 N'],
  "thad": ['TH AE1 D'],
  "thada": ['TH AA1 D AH0'],
  "thadda": ['TH AE1 D AH0'],
  "thaddea": ['TH AE1 D IY0 AH0'],
  "thaddeus": ['TH AE1 D IY0 AH0 S'],
  "thaden": ['TH EY1 D AH0 N'],
  "thagard": ['TH AE1 G ER0 D'],
  "thaggard": ['TH AE1 G ER0 D'],
  "thai": ['T AY1'],
  "thai's": ['T AY1 Z'],
  "thailand": ['T AY1 L AE2 N D'],
  "thailand's": ['T AY1 L AE2 N D Z'],
  "thain": ['TH EY1 N'],
  "thaine": ['TH EY1 N'],
  "thais": ['T AY1 Z'],
  "thakkar": ['TH AE1 K ER0'],
  "thal": ['TH AE1 L'],
  "thalacker": ['TH AE1 L AH0 K ER0'],
  "thalamus": ['TH AE1 L AH0 M AH0 S'],
  "thalassa": ['TH AA0 L AA1 S AH0'],
  "thaler": ['TH EY1 L ER0'],
  "thalheimer": ['TH AE1 L HH AY0 M ER0'],
  "thalia": ['TH EY1 L Y AH0'],
  "thalia's": ['TH EY1 L Y AH0 Z'],
  "thalidomide": ['TH AH0 L IH1 D AH0 M AY2 D'],
  "thalidomide's": ['TH AH0 L IH1 D AH0 M AY2 D Z'],
  "thall": ['TH AO1 L'],
  "thallium": ['TH AE1 L IY0 AH0 M'],
  "thalman": ['TH AE1 L M AH0 N'],
  "thalmann": ['TH AO1 L M AH0 N'],
  "tham": ['TH AE1 M'],
  "thames": ['T EH1 M Z'],
  "than": ['DH AE1 N', 'DH AH0 N'],
  "thanatology": ['TH AE0 N AH0 T AO1 L AH0 JH IY0'],
  "thane": ['TH EY1 N'],
  "thang": ['TH AE1 NG'],
  "thanh": ['TH AE1 N'],
  "thank": ['TH AE1 NG K'],
  "thank's": ['TH AE1 NG K S'],
  "thanked": ['TH AE1 NG K T'],
  "thankful": ['TH AE1 NG K F AH0 L'],
  "thankfully": ['TH AE1 NG K F AH0 L IY0'],
  "thankfulness": ['TH AE1 NG K F AH0 L N EH0 S'],
  "thanking": ['TH AE1 NG K IH0 NG'],
  "thankless": ['TH AE1 NG K L AH0 S'],
  "thanks": ['TH AE1 NG K S'],
  "thanksgiving": ['TH AE2 NG K S G IH1 V IH0 NG'],
  "thankyou": ['TH AE1 NG K Y UW1'],
  "thano": ['TH AA1 N OW0'],
  "thanos": ['TH AA1 N OW0 S'],
  "thao": ['DH AW1'],
  "thar": ['TH AA1 R'],
  "tharp": ['TH AA1 R P'],
  "tharpe": ['TH AA1 R P'],
  "tharpe's": ['TH AA1 R P S'],
  "tharrington": ['TH AE1 R IH0 NG T AH0 N'],
  "thaser": ['TH EY1 Z ER0'],
  "thassos": ['TH AE1 S OW0 S'],
  "that": ['DH AE1 T', 'DH AH0 T'],
  "that'd": ['DH AE1 T IH0 D'],
  "that'll": ['DH AE1 T AH0 L'],
  "that's": ['DH AE1 T S'],
  "that've": ['DH AE1 T AH0 V'],
  "thatch": ['TH AE1 CH'],
  "thatched": ['TH AE1 CH T'],
  "thatcher": ['TH AE1 CH ER0'],
  "thatcher's": ['TH AE1 CH ER0 Z'],
  "thatcherism": ['TH AE1 CH ER0 IH2 Z AH0 M'],
  "thatcherite": ['TH AE1 CH ER0 AY2 T'],
  "thatchers": ['TH AE1 CH ER0 Z'],
  "thatches": ['TH AE1 CH IH0 Z'],
  "thau": ['DH AW1'],
  "thaw": ['TH AO1'],
  "thawed": ['TH AO1 D'],
  "thawing": ['TH AO1 IH0 NG'],
  "thaws": ['TH AO1 Z'],
  "thaxter": ['TH AE1 K S T ER0'],
  "thaxter's": ['TH AE1 K S T ER0 Z'],
  "thaxton": ['TH AE1 K S T AH0 N'],
  "thaxton's": ['TH AE1 K S T AH0 N Z'],
  "thayer": ['TH EY1 ER0'],
  "thayer's": ['TH EY1 ER0 Z'],
  "thayers": ['TH EY1 ER0 Z'],
  "thayne": ['TH EY1 N'],
  "the": ['DH AH0', 'DH AH1', 'DH IY0'],
  "thea": ['TH IY1 AH0'],
  "theall": ['TH IY1 L'],
  "theano": ['TH IY1 N OW2'],
  "theater": ['TH IY1 AH0 T ER0'],
  "theater's": ['TH IY1 AH0 T ER0 Z'],
  "theatergoer": ['TH IY1 T ER0 G OW0 ER0', 'TH IY1 IH0 T ER0 G OW0 ER0'],
  "theatergoers": ['TH IY1 T ER0 G OW0 ER0 Z', 'TH IY1 IH0 T ER0 G OW0 ER0 Z'],
  "theaters": ['TH IY1 AH0 T ER0 Z'],
  "theatre": ['TH IY1 AH0 T ER0'],
  "theatre's": ['TH IY1 AH0 T ER0 Z'],
  "theatres": ['TH IY1 AH0 T ER0 Z'],
  "theatric": ['TH IY1 T R IH0 K'],
  "theatrical": ['TH IY0 AE1 T R IH0 K AH0 L'],
  "theatricality": ['TH IY0 AE2 T R AH0 K AE1 L AH0 T IY2'],
  "theatrically": ['TH IY0 AE1 T R IH0 K AH0 L IY2', 'TH IY0 AE1 T R IH0 K L IY0'],
  "theatrics": ['TH IY1 T R IH0 K S'],
  "thebeau": ['TH IH0 B OW1'],
  "theberge": ['TH EH1 B ER0 JH'],
  "thebes": ['TH IY1 B Z'],
  "thecla": ['TH EH1 K L AA2'],
  "theda": ['TH IY1 D AA2'],
  "theda's": ['TH IY1 D AH0 Z'],
  "thede": ['TH IY1 D'],
  "thedford": ['TH EH1 D F ER0 D'],
  "thee": ['DH IY1'],
  "thee's": ['DH IY1 Z'],
  "theel": ['TH IY1 L'],
  "theft": ['TH EH1 F T'],
  "thefts": ['TH EH1 F T S'],
  "theil": ['TH AY1 L'],
  "theile": ['TH AY1 L'],
  "theilen": ['TH AY1 L AH0 N'],
  "theiler": ['TH AY1 L ER0'],
  "thein": ['TH AY1 N'],
  "their": ['DH EH1 R'],
  "theirs": ['DH EH1 R Z'],
  "theirself": ['DH EH2 R S EH1 L F'],
  "theirselves": ['DH EH2 R S EH1 L V Z'],
  "theis": ['DH AY1 Z'],
  "theisen": ['TH AY1 S AH0 N'],
  "theism": ['TH IY1 IH0 Z AH0 M'],
  "theiss": ['TH AY1 S'],
  "theissen": ['TH AY1 S AH0 N'],
  "thekla": ['TH EH1 K L AH0'],
  "thelander": ['TH EH1 L AH0 N D ER0'],
  "thelen": ['TH EH1 L AH0 N'],
  "thelin": ['TH EH1 L IH0 N'],
  "thelma": ['TH EH1 L M AH0'],
  "thelma's": ['TH EH1 L M AH0 Z'],
  "thelonius": ['TH IH0 L OW1 N IY0 AH0 S'],
  "them": ['DH EH1 M', 'DH AH0 M'],
  "thematic": ['TH IY0 M AE1 T IH0 K'],
  "thematically": ['TH AH0 M AE1 T IH0 K L IY0'],
  "theme": ['TH IY1 M'],
  "themed": ['TH IY1 M D'],
  "themes": ['TH IY1 M Z'],
  "theming": ['TH IY1 M IH0 NG'],
  "themself": ['DH EH0 M S EH1 L F', 'DH AH0 M S EH1 L F'],
  "themselves": ['DH EH0 M S EH1 L V Z', 'DH AH0 M S EH1 L V Z'],
  "then": ['DH EH1 N'],
  "thence": ['DH EH1 N S'],
  "thenceforth": ['DH EH2 N S F AO1 R TH'],
  "theo": ['TH IY1 OW0'],
  "theo's": ['TH IY1 OW0 Z'],
  "theobald": ['TH IY1 AH0 B AA2 L D'],
  "theocracy": ['TH IY0 AA1 K R AH0 S IY0'],
  "theocratic": ['TH IY2 AH0 K R AE1 T IH0 K'],
  "theodor": ['TH IY1 AH0 D ER0'],
  "theodora": ['TH IY2 AH0 D AO1 R AH0'],
  "theodore": ['TH IY1 AH0 D AO2 R'],
  "theodorou": ['TH IY1 AH0 D ER0 UW0'],
  "theodric": ['TH IY1 AH0 D R IH0 K'],
  "theola": ['TH IY1 AH0 L AH0'],
  "theologian": ['TH IY2 AH0 L OW1 JH IY0 AH0 N'],
  "theologians": ['TH IY2 AH0 L OW1 JH AH0 N Z'],
  "theological": ['TH IY2 AH0 L AA1 JH IH0 K AH0 L'],
  "theologically": ['TH IY2 AH0 L AA1 JH IH0 K L IY0'],
  "theology": ['TH IY0 AA1 L AH0 JH IY0'],
  "theon": ['TH IY1 AH0 N'],
  "theone": ['TH IY1 AA0 N'],
  "theophania": ['TH IY0 AH0 F AE1 N IY0 AH0'],
  "theophila": ['TH EY0 AH0 F IY1 L AH0'],
  "theora": ['TH IY1 ER0 AH0'],
  "theorem": ['TH IH1 R AH0 M'],
  "theorems": ['TH IH1 R AH0 M Z'],
  "theoretical": ['TH IY2 ER0 EH1 T IH0 K AH0 L'],
  "theoretically": ['TH IY2 ER0 EH1 T IH0 K AH0 L IY0', 'TH IY2 ER0 EH1 T IH0 K L IY0'],
  "theoretician": ['TH IY2 ER0 AH0 T IH1 SH AH0 N'],
  "theoreticians": ['TH IY2 ER0 AH0 T IH1 SH AH0 N Z'],
  "theories": ['TH IH1 R IY0 Z', 'TH IY1 ER0 IY0 Z'],
  "theorist": ['TH IY1 ER0 IH0 S T'],
  "theorists": ['TH IY1 ER0 IH0 S T S'],
  "theorize": ['TH IY1 ER0 AY2 Z'],
  "theorized": ['TH IY1 ER0 AY2 Z D'],
  "theorizes": ['TH IY1 ER0 AY2 Z IH0 Z'],
  "theorizing": ['TH IY1 ER0 AY2 Z IH0 NG'],
  "theory": ['TH IH1 R IY0', 'TH IY1 ER0 IY0'],
  "theory's": ['TH IH1 R IY0 Z', 'TH IY1 ER0 IY0 Z'],
  "thera": ['TH EH1 R AH0'],
  "therafectin": ['TH EH2 R AH0 F EH1 K T IH0 N'],
  "therani": ['T EH2 R AA1 N IY0'],
  "therapeutic": ['TH EH2 R AH0 P Y UW1 T IH0 K'],
  "therapeutically": ['TH EH2 R AH0 P Y UW1 T IH0 K AH0 L IY0', 'TH EH2 R AH0 P Y UW1 T IH0 K L IY0'],
  "therapeutics": ['TH EH2 R AH0 P Y UW1 T IH0 K S'],
  "therapies": ['TH EH1 R AH0 P IY0 Z'],
  "therapist": ['TH EH1 R AH0 P AH0 S T', 'TH EH1 R AH0 P IH0 S T'],
  "therapist's": ['TH EH1 R AH0 P AH0 S T S', 'TH EH1 R AH0 P IH0 S T S'],
  "therapists": ['TH EH1 R AH0 P IH0 S T S'],
  "therapists'": ['TH EH1 R AH0 P IH0 S T S'],
  "therapists's": ['TH EH1 R AH0 P IH0 S T S'],
  "therapy": ['TH EH1 R AH0 P IY0'],
  "therat": ['DH EH1 R AE2 T'],
  "there": ['DH EH1 R'],
  "there'd": ['DH EH1 R D'],
  "there'll": ['DH EH1 R AH0 L'],
  "there're": ['DH EH1 R ER0'],
  "there's": ['DH EH1 R Z'],
  "there've": ['DH EH1 R V'],
  "thereabouts": ['DH EH2 R AH0 B AW1 T S'],
  "thereafter": ['DH EH0 R AE1 F T ER0'],
  "thereby": ['DH EH1 R B AY1'],
  "therefore": ['DH EH1 R F AO2 R'],
  "therein": ['DH EH0 R IH1 N'],
  "thereof": ['DH EH2 R AH1 V'],
  "thereon": ['DH EH0 R AO1 N'],
  "theresa": ['T ER0 IY1 S AH0', 'T ER0 EY1 S AH0'],
  "theresa's": ['T ER0 IY1 S AH0 Z', 'T ER0 EY1 S AH0 Z'],
  "therese": ['TH EH1 R IY0 S'],
  "thereto": ['DH EH1 R T UW2'],
  "thereupon": ['DH EH2 R AH0 P AA1 N'],
  "theriault": ['TH EH2 R IY0 OW1'],
  "therien": ['TH IH1 R IY0 N'],
  "theriot": ['TH IH1 R IY0 AA0 T'],
  "therm": ['TH ER1 M'],
  "thermae": ['TH ER1 M IY2'],
  "thermal": ['TH ER1 M AH0 L'],
  "thermco": ['TH ER1 M K OW0'],
  "thermedics": ['TH ER0 M EH1 D IH0 K S'],
  "thermits": ['TH ER1 M IH0 T S'],
  "thermo": ['TH ER1 M OW0'],
  "thermodynamic": ['TH ER2 M OW0 D AY2 N AE1 M IH0 K'],
  "thermodynamics": ['TH ER2 M OW0 D AY2 N AE1 M IH0 K S'],
  "thermometer": ['TH ER0 M AA1 M AH0 T ER0'],
  "thermometers": ['TH ER0 M AA1 M AH0 T ER0 Z'],
  "thermonuclear": ['TH ER2 M OW0 N UW1 K L IY0 ER0'],
  "thermopatch": ['TH ER1 M OW0 P AE2 CH'],
  "thermoplastic": ['TH ER2 M AH0 P L AE1 S T IH0 K'],
  "thermoplastics": ['TH ER2 M AH0 P L AE1 S T IH0 K S'],
  "thermos": ['TH ER1 M AH0 S'],
  "thermosetting": ['TH ER1 M OW0 S EH2 T IH0 NG'],
  "thermostat": ['TH ER1 M AH0 S T AE2 T'],
  "thermostats": ['TH ER1 M AH0 S T AE2 T S'],
  "theron": ['TH EH1 R AH0 N'],
  "theroux": ['TH ER0 UW1'],
  "therrell": ['TH EH1 R AH0 L'],
  "therriault": ['TH EH1 R IY0 OW1'],
  "therrien": ['TH EH1 R IY0 N'],
  "thesauri": ['TH AH0 S AO1 R IY2'],
  "thesaurus": ['TH AH0 S AO1 R AH0 S'],
  "these": ['DH IY1 Z'],
  "theses": ['TH IY1 S IY0 Z'],
  "theseus": ['TH IY1 Z IY2 AH0 S'],
  "thesing": ['DH IY1 Z IH0 NG', 'TH IY1 S IH0 NG'],
  "thesis": ['TH IY1 S AH0 S'],
  "thespian": ['TH EH1 S P IY0 AH0 N'],
  "theta": ['TH EY1 T AH0'],
  "thetford": ['TH EH1 T F ER0 D'],
  "thetis": ['TH IY1 T AH0 S'],
  "theurer": ['TH ER1 ER0'],
  "theus": ['DH Y UW1 Z'],
  "thew": ['TH UW1'],
  "thewlis": ['TH Y UW1 L IH0 S'],
  "they": ['DH EY1'],
  "they'd": ['DH EY1 D'],
  "they'll": ['DH EY1 L'],
  "they're": ['DH EH1 R'],
  "they've": ['DH EY1 V'],
  "thi": ['TH IY1'],
  "thiamin": ['TH AY1 AH0 M AH0 N'],
  "thibadeau": ['T IH1 B AH0 D OW0'],
  "thibault": ['TH IH0 B OW1'],
  "thibeau": ['TH IH0 B OW1'],
  "thibeault": ['T IY1 B OW0'],
  "thibeaux": ['TH IH0 B OW1'],
  "thibedeau": ['TH IH1 B IH0 D OW0'],
  "thibert": ['TH IH0 B ER1 T', 'TH IH1 B ER0 T'],
  "thibodeau": ['TH IH1 B AH0 D OW0'],
  "thibodeaux": ['TH IH1 B AH0 D OW0'],
  "thick": ['TH IH1 K'],
  "thicken": ['TH IH1 K AH0 N'],
  "thickened": ['TH IH1 K AH0 N D'],
  "thickener": ['TH IH1 K AH0 N ER0'],
  "thickening": ['TH IH1 K AH0 N IH0 NG', 'TH IH1 K N IH0 NG'],
  "thickens": ['TH IH1 K AH0 N Z'],
  "thicker": ['TH IH1 K ER0'],
  "thickest": ['TH IH1 K IH0 S T'],
  "thicket": ['TH IH1 K IH0 T'],
  "thickets": ['TH IH1 K AH0 T S'],
  "thickhead": ['TH IH1 K HH EH2 D'],
  "thickly": ['TH IH1 K L IY0'],
  "thickness": ['TH IH1 K N AH0 S'],
  "thiebaud": ['TH IY2 B OW1'],
  "thiede": ['TH IY1 D'],
  "thief": ['TH IY1 F'],
  "thief's": ['TH IY1 F S'],
  "thiel": ['TH IY1 L'],
  "thiele": ['TH IY1 L'],
  "thielemann": ['TH IY1 L M AH0 N'],
  "thielen": ['TH IY1 L AH0 N'],
  "thielke": ['TH IY1 L K'],
  "thielman": ['TH IY1 L M AH0 N'],
  "thielsch": ['TH IY1 L SH'],
  "thiem": ['TH IY1 M'],
  "thieman": ['TH IY1 M AH0 N'],
  "thiemann": ['TH IY1 M AH0 N'],
  "thieme": ['TH IY1 M'],
  "thien": ['TH IY1 N'],
  "thier": ['TH IY1 ER0'],
  "thierry": ['TH IH0 R IY1'],
  "thiery": ['TH IH1 R IY0'],
  "thies": ['TH IY1 Z'],
  "thiesen": ['TH IY1 S AH0 N'],
  "thiessen": ['TH IY1 S AH0 N'],
  "thieve": ['TH IY1 V'],
  "thievery": ['TH IY1 V ER0 IY0'],
  "thieves": ['TH IY1 V Z'],
  "thieves'": ['TH IY1 V Z'],
  "thieving": ['TH IY1 V IH0 NG'],
  "thigh": ['TH AY1'],
  "thighs": ['TH AY1 Z'],
  "thigpen": ['TH IH1 G P AH0 N'],
  "thill": ['TH IH1 L'],
  "thima": ['TH IY1 M AH0'],
  "thimble": ['TH IH1 M B AH0 L'],
  "thin": ['TH IH1 N'],
  "thine": ['DH AY1 N'],
  "thing": ['TH IH1 NG'],
  "thing's": ['TH IH1 NG Z'],
  "thingamabob": ['TH IH1 NG AH0 M AH0 B AA2 B'],
  "things": ['TH IH1 NG Z'],
  "things'": ['TH IH1 NG Z'],
  "thingy": ['TH IH1 NG IY0'],
  "think": ['TH IH1 NG K'],
  "think's": ['TH IH1 NG K S'],
  "thinker": ['TH IH1 NG K ER0'],
  "thinkers": ['TH IH1 NG K ER0 Z'],
  "thinkin": ['TH IH1 NG K IH0 N'],
  "thinkin'": ['TH IH1 NG K IH0 N'],
  "thinking": ['TH IH1 NG K IH0 NG'],
  "thinkpad": ['TH IH1 NG K P AE2 D'],
  "thinks": ['TH IH1 NG K S'],
  "thinly": ['TH IH1 N L IY0'],
  "thinned": ['TH IH1 N D'],
  "thinner": ['TH IH1 N ER0'],
  "thinnes": ['TH IH1 N Z', 'TH IH1 N IH0 Z'],
  "thinness": ['TH IH1 N N IH0 S', 'TH IH1 N IH0 S'],
  "thinnest": ['TH IH1 N IH0 S T'],
  "thinning": ['TH IH1 N IH0 NG'],
  "thins": ['TH IH1 N Z'],
  "thiodiglycol": ['TH AY2 OW0 D IH1 G L IH0 K AA2 L', 'TH AY2 OW0 D IH1 G L AY0 K AA2 L'],
  "thiokol": ['TH AY1 AH0 K AO2 L'],
  "thiokol's": ['TH AY1 AH0 K AO2 L Z'],
  "third": ['TH ER1 D'],
  "third's": ['TH ER1 D Z'],
  "thirdly": ['TH ER1 D L IY0'],
  "thirdquarter": ['TH ER2 D K W AO1 R T ER0', 'TH ER2 D K AO1 R T ER0'],
  "thirds": ['TH ER1 D Z'],
  "thirst": ['TH ER1 S T'],
  "thirsting": ['TH ER1 S T IH0 NG'],
  "thirsts": ['TH ER1 S T S'],
  "thirsty": ['TH ER1 S T IY0'],
  "thirteen": ['TH ER1 T IY1 N'],
  "thirteen's": ['TH ER1 T IY2 N Z'],
  "thirteenth": ['TH ER1 T IY1 N TH'],
  "thirteenths": ['TH ER1 T IY1 N TH S'],
  "thirties": ['TH ER1 T IY0 Z'],
  "thirtieth": ['TH ER1 T IY0 AH0 TH', 'TH ER1 T IY0 IH0 TH'],
  "thirty": ['TH ER1 D IY2', 'TH ER1 T IY2'],
  "thirty's": ['TH ER1 D IY2 Z', 'TH ER1 T IY2 Z'],
  "thirty-five": ['TH ER1 D IY0 F AY2 V'],
  "thirtysomething": ['TH ER1 T IY0 S AH2 M TH IH0 NG'],
  "thiry": ['TH IH1 R IY0'],
  "thirza": ['TH ER1 Z AH0'],
  "this": ['DH IH1 S', 'DH IH0 S'],
  "this'": ['DH IH1 S'],
  "this'll": ['DH IH1 S AH0 L', 'DH IH0 S AH0 L'],
  "thissen": ['TH IH1 S AH0 N'],
  "thistle": ['TH IH1 S AH0 L'],
  "thistles": ['TH IH1 S AH0 L Z'],
  "thither": ['DH IH1 TH ER2'],
  "thivierge": ['TH IH0 V Y EH1 R ZH'],
  "tho": ['DH OW1'],
  "thobe": ['TH OW1 B'],
  "thode": ['TH OW1 D'],
  "thoele": ['TH OW1 L'],
  "thoen": ['TH OW1 N'],
  "thoene": ['TH AA1 IY0 N'],
  "thoennes": ['TH OW1 N Z'],
  "thole": ['TH OW1 L'],
  "tholen": ['TH AA1 L AH0 N'],
  "tholl": ['TH AA1 L'],
  "thom": ['T AA1 M'],
  "thoma": ['TH OW1 M AH0', 'T OW1 M AH0'],
  "thoman": ['TH OW1 M AH0 N'],
  "thomann": ['DH OW1 M AH0 N'],
  "thomas": ['T AA1 M AH0 S'],
  "thomas'": ['T AA1 M AH0 S IH0 Z', 'T AA1 M AH0 S'],
  "thomas's": ['T AA1 M AH0 S IH0 Z'],
  "thomasas": ['T AA1 M AH0 S AH0 S'],
  "thomases": ['T AA1 M AH0 S IH0 Z'],
  "thomasina": ['TH OW0 M AA0 S IY1 N AH0', 'T AA0 M AA0 S IY1 N AH0'],
  "thomasine": ['TH OW0 M AA0 S IY1 N IY0', 'T AA0 M AA0 S IY1 N IY0', 'T AA0 M AA0 S IY1 N'],
  "thomason": ['TH AA1 M AH0 S AH0 N', 'T AA1 M AH0 S AH0 N'],
  "thomassen": ['TH AA1 M AH0 S AH0 N', 'T AA1 M AH0 S AH0 N'],
  "thomasson": ['TH AA1 M AH0 S AH0 N', 'T AA1 M AH0 S AH0 N'],
  "thomasville": ['T AA1 M AH0 S V IH2 L'],
  "thome": ['TH OW1 M'],
  "thomes": ['TH OW1 M Z'],
  "thometz": ['TH OW1 M EH0 T S'],
  "thomison": ['TH AA1 M IH0 S AH0 N'],
  "thomley": ['TH AA1 M L IY0'],
  "thommen": ['TH AA1 M AH0 N'],
  "thomp": ['TH AA1 M P'],
  "thompkins": ['T AA1 M P K IH0 N Z', 'T AA1 M K IH0 N Z'],
  "thompsen": ['T AA1 M P S AH0 N', 'T AA1 M S AH0 N'],
  "thompson": ['T AA1 M P S AH0 N', 'T AA1 M S AH0 N'],
  "thompson's": ['T AA1 M P S AH0 N Z', 'T AA1 M S AH0 N Z'],
  "thompsons": ['T AA1 M P S AH0 N Z', 'T AA1 M S AH0 N Z'],
  "thoms": ['TH AA1 M Z'],
  "thomsen": ['TH AA1 M S AH0 N'],
  "thomson": ['T AA1 M S AH0 N'],
  "thomson's": ['T AA1 M S AH0 N Z'],
  "thomspon": ['TH AA1 M S P AH0 N'],
  "thomure": ['TH AA1 M Y UW0 R'],
  "thon": ['TH AA1 N'],
  "thone": ['TH OW1 N'],
  "thong": ['TH AO1 NG'],
  "thor": ['TH AO1 R'],
  "thora": ['TH AO1 R AH0'],
  "thoracic": ['TH AO0 R AE1 S IH0 K'],
  "thorald": ['TH AO1 R AH0 L D'],
  "thorax": ['TH AO1 R AE0 K S'],
  "thorbert": ['TH AO1 R B ER0 T'],
  "thorberta": ['TH AO0 R B EH1 R T AH0'],
  "thorburn": ['TH AO1 R B ER0 N'],
  "thordia": ['TH AO1 R D IY0 AH0'],
  "thordis": ['TH AO1 R D IH0 S'],
  "thoreau": ['TH ER0 OW1'],
  "thorell": ['TH AO1 R AH0 L'],
  "thoren": ['TH AO1 R AH0 N'],
  "thoresen": ['TH AO1 R IY0 Z AH0 N'],
  "thoreson": ['TH AO1 R IH0 S AH0 N'],
  "thorington": ['TH AO1 R IH0 NG T AH0 N'],
  "thorium": ['TH AA1 R IY0 AH0 M'],
  "thorley": ['TH AO1 R L IY0'],
  "thorman": ['TH AO1 R M AH0 N'],
  "thormond": ['TH AO1 R M AH0 N D'],
  "thormund": ['TH AO1 R M AH0 N D'],
  "thorn": ['TH AO1 R N'],
  "thorn's": ['TH AO1 R N Z'],
  "thornberg": ['TH AO1 R N B ER0 G'],
  "thornberry": ['TH AO1 R N B EH2 R IY0'],
  "thornberry's": ['TH AO1 R N B EH2 R IY0 Z'],
  "thornberrys": ['TH AO1 R N B EH2 R IY0 Z'],
  "thornburg": ['TH AO1 R N B ER0 G'],
  "thornburg's": ['TH AO1 R N B ER0 G Z'],
  "thornburgh": ['TH AO1 R N B ER0 G'],
  "thornburgh's": ['TH AO1 R N B ER0 G Z'],
  "thornburghs": ['TH AO1 R N B ER0 G Z'],
  "thornburgs": ['TH AO1 R N B ER0 G Z'],
  "thornbury": ['TH AO1 R N B EH2 R IY0'],
  "thornbury's": ['TH AO1 R N B EH2 R IY0 Z'],
  "thornburys": ['TH AO1 R N B EH2 R IY0 Z'],
  "thorndike": ['TH AO1 R N D IH0 K'],
  "thorndike's": ['TH AO1 R N D IH0 K S'],
  "thorndikes": ['TH AO1 R N D IH0 K S'],
  "thorndyke": ['TH AO1 R N D AY2 K'],
  "thorne": ['TH AO1 R N'],
  "thornell": ['TH AO1 R N AH0 L'],
  "thorner": ['TH AO1 R N ER0'],
  "thornhill": ['TH AO1 R N HH IH2 L'],
  "thorniest": ['TH AO1 R N IY0 AH0 S T'],
  "thornley": ['TH AO1 R N L IY0'],
  "thornock": ['TH AO1 R N AH0 K'],
  "thorns": ['TH AO1 R N Z'],
  "thornsberry": ['TH AO1 R N Z B EH0 R IY0'],
  "thornsbury": ['TH AO1 R N Z B EH0 R IY0'],
  "thornton": ['TH AO1 R N T AH0 N'],
  "thorny": ['TH AO1 R N IY0'],
  "thoro": ['TH AO1 R OW0'],
  "thorough": ['TH ER1 OW0', 'TH AO1 R OW0'],
  "thoroughbred": ['TH ER1 OW0 B R EH1 D'],
  "thoroughbreds": ['TH ER1 OW0 B R EH1 D Z'],
  "thoroughfare": ['TH ER1 OW0 F EH2 R'],
  "thoroughfares": ['TH ER1 OW0 F EH2 R Z'],
  "thoroughly": ['TH ER1 OW0 L IY0'],
  "thoroughness": ['TH ER1 OW0 N AH0 S'],
  "thorp": ['TH AO1 R P'],
  "thorpe": ['TH AO1 R P'],
  "thorsell": ['TH AO1 R S AH0 L'],
  "thorsen": ['TH AO1 R S AH0 N'],
  "thorson": ['TH AO1 R S AH0 N'],
  "thorstad": ['TH AO1 R S T AH0 D'],
  "thorsten": ['T AO1 R S T AH0 N'],
  "thorstenson": ['TH AO1 R S T AH0 N S AH0 N'],
  "thortec": ['TH AO1 R T EH2 K'],
  "thorton": ['TH AO1 R T AH0 N'],
  "thorton's": ['TH AO1 R T AH0 N Z'],
  "thorup": ['TH AO1 R AH0 P'],
  "thorvald": ['TH AO1 R V AA1 L D'],
  "those": ['DH OW1 Z'],
  "thou": ['DH AW1'],
  "though": ['DH OW1'],
  "thought": ['TH AO1 T'],
  "thoughtful": ['TH AO1 T F AH0 L'],
  "thoughtfully": ['TH AO1 T F AH0 L IY0'],
  "thoughtfulness": ['TH AO1 T F AH0 L N IH0 S'],
  "thoughtless": ['TH AO1 T L AH0 S'],
  "thoughts": ['TH AO1 T S'],
  "thous": ['DH AW1 Z'],
  "thousand": ['TH AW1 Z AH0 N D', 'TH AW1 Z AH0 N'],
  "thousand-years-long": ['TH AW1 Z AH0 N D Y IH2 R Z L AO1 NG'],
  "thousands": ['TH AW1 Z AH0 N D Z', 'TH AW1 Z AH0 N Z'],
  "thousandth": ['TH AW1 Z AH0 N D TH', 'TH AW1 Z AH0 N TH'],
  "thousandths": ['TH AW1 Z AH0 N D TH S', 'TH AW1 Z AH0 N TH S'],
  "thraco-illyrian": ['TH R EY2 K OW2 IH0 L IH1 R IY0 AH0 N'],
  "thrailkill": ['TH R EY1 L K IH2 L'],
  "thrall": ['TH R AO1 L'],
  "thrash": ['TH R AE1 SH'],
  "thrashed": ['TH R AE1 SH T'],
  "thrasher": ['TH R AE1 SH ER0'],
  "thrashes": ['TH R AE1 SH IH0 Z'],
  "thrashing": ['TH R AE1 SH IH0 NG'],
  "thread": ['TH R EH1 D'],
  "threadbare": ['TH R EH1 D B EH2 R'],
  "threaded": ['TH R EH1 D IH0 D'],
  "threadfin": ['TH R EH1 D F IH0 N'],
  "threadgill": ['TH R EH1 D G IH2 L'],
  "threading": ['TH R EH1 D IH0 NG'],
  "threads": ['TH R EH1 D Z'],
  "threat": ['TH R EH1 T'],
  "threaten": ['TH R EH1 T AH0 N'],
  "threatened": ['TH R EH1 T AH0 N D'],
  "threatening": ['TH R EH1 T AH0 N IH0 NG', 'TH R EH1 T N IH0 NG'],
  "threateningly": ['TH R EH1 T AH0 N IH0 NG L IY0', 'TH R EH1 T N IH0 NG L IY0'],
  "threatens": ['TH R EH1 T AH0 N Z'],
  "threats": ['TH R EH1 T S'],
  "threatt": ['TH R IY1 T'],
  "three": ['TH R IY1'],
  "three's": ['TH R IY1 Z'],
  "three-dimensional": ['TH R IY2 Z D IH0 M EH1 N SH AH0 N AH0 L'],
  "three-hour": ['TH R IY2 AW1 ER0'],
  "three-quarters": ['TH R IY2 K W AO1 R T ER0 Z'],
  "three-way": ['TH R IY1 W EY2'],
  "three-year": ['TH R IY1 Y IH2 R'],
  "threefold": ['TH R IY1 F OW2 L D'],
  "threemonth": ['TH R IY1 M AH0 N TH'],
  "threes": ['TH R IY1 Z'],
  "threesome": ['TH R IY1 S AH0 M'],
  "threet": ['TH R IY1 T'],
  "threlkeld": ['TH R EH1 L K EH2 L D'],
  "thresh": ['TH R EH1 SH'],
  "thresher": ['TH R EH1 SH ER0'],
  "threshold": ['TH R EH1 SH OW2 L D'],
  "thresholds": ['TH R EH1 SH OW2 L D Z'],
  "threw": ['TH R UW1'],
  "thrice": ['TH R AY1 S'],
  "thrift": ['TH R IH1 F T'],
  "thrift's": ['TH R IH1 F T S'],
  "thriftier": ['TH R IH1 F T IY0 ER0'],
  "thrifts": ['TH R IH1 F T S', 'TH R IH1 F S'],
  "thrifts'": ['TH R IH1 F T S', 'TH R IH1 F S'],
  "thrifty": ['TH R IH1 F T IY0'],
  "thrill": ['TH R IH1 L'],
  "thrilled": ['TH R IH1 L D'],
  "thriller": ['TH R IH1 L ER0'],
  "thrillers": ['TH R IH1 L ER0 Z'],
  "thrilling": ['TH R IH1 L IH0 NG'],
  "thrills": ['TH R IH1 L Z'],
  "thrips": ['TH R IH1 P S'],
  "thristing": ['TH R IH1 S T IH0 NG'],
  "thrive": ['TH R AY1 V'],
  "thrived": ['TH R AY1 V D'],
  "thrives": ['TH R AY1 V Z'],
  "thriving": ['TH R AY1 V IH0 NG'],
  "throat": ['TH R OW1 T'],
  "throated": ['TH R OW1 T IH0 D'],
  "throats": ['TH R OW1 T S'],
  "throaty": ['TH R OW1 T IY0'],
  "throb": ['TH R AA1 B'],
  "throbbing": ['TH R AA1 B IH0 NG'],
  "throes": ['TH R OW1 Z'],
  "throgmorton": ['TH R AH0 G M AO1 R T AH0 N'],
  "throm": ['TH R AA1 M'],
  "thrombolysis": ['TH R AA0 M B OW1 L IH0 S IH0 S', 'TH R AA0 M B AA1 L IH0 S IH0 S'],
  "thrombolytic": ['TH R AA2 M B OW0 L IH1 T IH0 K'],
  "thrombosis": ['TH R AA0 M B OW1 S AH0 S'],
  "throne": ['TH R OW1 N'],
  "throneberry": ['TH R OW1 N B EH2 R IY0'],
  "throng": ['TH R AO1 NG'],
  "thronged": ['TH R AO1 NG D'],
  "throngs": ['TH R AO1 NG Z'],
  "thronson": ['TH R AA1 N S AH0 N'],
  "throop": ['TH R UW1 P'],
  "throttle": ['TH R AA1 T AH0 L'],
  "throttled": ['TH R AA1 T AH0 L D'],
  "throttles": ['TH R AA1 T AH0 L Z'],
  "throttling": ['TH R AA1 T AH0 L IH0 NG', 'TH R AA1 T L IH0 NG'],
  "through": ['TH R UW1'],
  "throughout": ['TH R UW0 AW1 T'],
  "throughput": ['TH R UW1 P UH2 T'],
  "throughs": ['TH R UW1 Z'],
  "throughway": ['TH R UW1 W EY2'],
  "throve": ['TH R OW1 V'],
  "throw": ['TH R OW1'],
  "throwaway": ['TH R OW1 AH0 W EY2'],
  "throwback": ['TH R OW1 B AE2 K'],
  "thrower": ['TH R OW1 ER0'],
  "throwers": ['TH R OW1 ER0 Z'],
  "throwing": ['TH R OW1 IH0 NG'],
  "thrown": ['TH R OW1 N'],
  "throws": ['TH R OW1 Z'],
  "thru": ['TH R UW1'],
  "thrun": ['TH R AH1 N'],
  "thrush": ['TH R AH1 SH'],
  "thrushes": ['TH R AH1 SH AH0 Z', 'TH R AH1 SH IH0 Z'],
  "thrust": ['TH R AH1 S T'],
  "thruster": ['TH R AH1 S T ER0'],
  "thrusters": ['TH R AH1 S T ER0 Z'],
  "thrusting": ['TH R AH1 S T IH0 NG'],
  "thrusts": ['TH R AH1 S T S'],
  "thruway": ['TH R UW1 W EY2'],
  "ths": ['TH S'],
  "thud": ['TH AH1 D'],
  "thug": ['TH AH1 G'],
  "thuggery": ['TH AH1 G ER0 IY0'],
  "thugs": ['TH AH1 G Z'],
  "thul": ['TH AH1 L'],
  "thulin": ['TH UW1 L IH0 N'],
  "thull": ['TH AH1 L'],
  "thum": ['TH AH1 M'],
  "thuma": ['TH UW1 M AH0'],
  "thuman": ['TH UW1 M AH0 N'],
  "thumann": ['TH UW1 M AH0 N'],
  "thumb": ['TH AH1 M'],
  "thumbed": ['TH AH1 M D'],
  "thumbing": ['TH AH1 M IH0 NG'],
  "thumbnail": ['TH AH1 M N EY2 L'],
  "thumbs": ['TH AH1 M Z'],
  "thumm": ['TH AH1 M'],
  "thumma": ['TH AH1 M AH0'],
  "thump": ['TH AH1 M P'],
  "thumped": ['TH AH1 M P T'],
  "thumper": ['TH AH1 M P ER0'],
  "thumping": ['TH AH1 M P IH0 NG'],
  "thumps": ['TH AH1 M P S'],
  "thun": ['TH AH1 N'],
  "thunberg": ['TH AH1 N B ER0 G'],
  "thunder": ['TH AH1 N D ER0'],
  "thunderbird": ['TH AH1 N D ER0 B ER2 D'],
  "thunderbirds": ['TH AH1 N D ER0 B ER2 D Z'],
  "thunderbolt": ['TH AH1 N D ER0 B AO2 L T'],
  "thundercat": ['TH AH1 N D ER0 K AE2 T'],
  "thundercats": ['TH AH1 N D ER0 K AE2 T S'],
  "thundered": ['TH AH1 N D ER0 D'],
  "thundering": ['TH AH1 N D ER0 IH0 NG'],
  "thunderous": ['TH AH1 N D ER0 AH0 S'],
  "thunders": ['TH AH1 N D ER0 Z'],
  "thundershower": ['TH AH1 N D ER0 SH AW2 W ER0'],
  "thundershowers": ['TH AH1 N D ER0 SH AW2 W ER0 Z'],
  "thunderstorm": ['TH AH1 N D ER0 S T AO2 R M'],
  "thunderstorms": ['TH AH1 N D ER0 S T AO2 R M Z'],
  "thunderstruck": ['TH AH1 N D ER0 S T R AH2 K'],
  "thune": ['TH UW1 N'],
  "thunk": ['TH AH1 N K'],
  "thuot": ['TH AW1 T', 'TH UW1 T'],
  "thur": ['DH ER1'],
  "thurber": ['TH ER1 B ER0'],
  "thurgood": ['TH ER1 G UH0 D'],
  "thurlow": ['TH ER1 L OW0'],
  "thurm": ['TH ER1 M'],
  "thurman": ['TH ER1 M AH0 N'],
  "thurmon": ['TH ER1 M AH0 N'],
  "thurmond": ['TH ER1 M AH0 N D'],
  "thurmond's": ['TH ER1 M AH0 N D Z'],
  "thurn": ['TH ER1 N'],
  "thurnau": ['TH ER0 N OW1'],
  "thurner": ['TH ER1 N ER0'],
  "thurnher": ['TH ER1 N ER0'],
  "thurow": ['TH UH1 R OW0'],
  "thursby": ['TH ER1 S B IY0'],
  "thursday": ['TH ER1 Z D EY2', 'TH ER1 Z D IY0'],
  "thursday's": ['TH ER1 Z D IY0 Z', 'TH ER1 Z D EY2 Z'],
  "thursdays": ['TH ER1 Z D EY0 Z', 'TH ER1 Z D IY0 Z'],
  "thurstan": ['TH ER1 S T AH0 N'],
  "thurston": ['TH ER1 S T AH0 N'],
  "thus": ['DH AH1 S'],
  "thusfar": ['DH AH1 S F AA2 R'],
  "thusly": ['DH AH1 S L IY0'],
  "thuy": ['T UW1'],
  "thwart": ['TH W AO1 R T'],
  "thwarted": ['TH W AO1 R T IH0 D'],
  "thwarting": ['TH W AO1 R T IH0 NG'],
  "thwarts": ['TH W AO1 R T S'],
  "thweatt": ['TH W IY1 T'],
  "thwing": ['TH W IH1 NG'],
  "thy": ['DH AY1'],
  "thygerson": ['TH AY1 G ER0 S AH0 N'],
  "thyme": ['TH AY1 M'],
  "thymidine": ['TH IH1 M IH0 D IY2 N'],
  "thyra": ['TH AY1 R AH0'],
  "thyroid": ['TH AY1 R OY0 D'],
  "thyself": ['DH AY2 S EH1 L F'],
  "thyssen": ['T AY1 S AH0 N'],
  "thyssen's": ['T AY1 S AH0 N Z'],
  "ti": ['T IY1'],
  "tia": ['T IY1 AH0'],
  "tiaacref": ['T IY1 AH0 K R EH2 F'],
  "tiaacref's": ['T IY1 AH0 K R EH2 F S'],
  "tian": ['T Y AA1 N'],
  "tiananmen": ['T IY0 EH1 N AE0 N M EH2 N'],
  "tianjin": ['T IY0 AE1 N JH IH0 N'],
  "tiano": ['T IY0 AA1 N OW0'],
  "tiara": ['T IY0 AA1 R AH0'],
  "tiaras": ['T IY0 AA1 R AH0 Z'],
  "tibbals": ['T IH1 B AH0 L Z'],
  "tibbets": ['T IH1 B IH0 T S'],
  "tibbett": ['T IH1 B IH0 T'],
  "tibbetts": ['T IH1 B IH0 T S'],
  "tibbie": ['T IH1 B IY0'],
  "tibbits": ['T IH1 B IH0 T S'],
  "tibbitts": ['T IH1 B IH0 T S'],
  "tibbs": ['T IH1 B Z'],
  "tibby": ['T IH1 B IY0'],
  "tiber": ['T AY1 B ER0'],
  "tiberi": ['T IY0 B EH1 R IY0'],
  "tiberia": ['T IH0 B IY1 R IY0 AH0'],
  "tiberio": ['T IH0 B IY1 R IY0 OW0'],
  "tiberon": ['T IH1 B ER0 AH0 N'],
  "tibet": ['T AH0 B EH1 T'],
  "tibetan": ['T IH0 B EH1 T AH0 N'],
  "tibetans": ['T AH0 B EH1 T AH0 N Z'],
  "tibi": ['T IH1 B IY2'],
  "tibia": ['T IH1 B IY0 AH0', 'T IH1 B Y AH0'],
  "tibidow": ['T IH1 B IY0 D OW0'],
  "tiburon": ['T IY1 B AH0 R AO2 N'],
  "tic": ['T IH1 K'],
  "tice": ['T AY1 S'],
  "ticer": ['T AY1 S ER0'],
  "tichenor": ['T IH1 K AH0 N ER0'],
  "tichy": ['T IH1 CH IY0'],
  "ticinus": ['T IH0 S IY1 N AH0 S'],
  "tick": ['T IH1 K'],
  "ticked": ['T IH1 K T'],
  "ticker": ['T IH1 K ER0'],
  "ticket": ['T IH1 K AH0 T', 'T IH1 K IH0 T'],
  "ticket's": ['T IH1 K AH0 T S'],
  "ticketed": ['T IH1 K AH0 T IH0 D'],
  "ticketing": ['T IH1 K AH0 T IH0 NG'],
  "ticketless": ['T IH1 K AH0 T L AH0 S'],
  "ticketmaster": ['T IH1 K IH0 T M AE2 S T ER0'],
  "ticketmaster's": ['T IH1 K AH0 T M AE2 S T ER0 Z'],
  "ticketron": ['T IH1 K AH0 T R AA0 N'],
  "tickets": ['T IH1 K AH0 T S', 'T IH1 K IH0 T S'],
  "ticking": ['T IH1 K IH0 NG'],
  "tickle": ['T IH1 K AH0 L'],
  "tickled": ['T IH1 K AH0 L D'],
  "tickles": ['T IH1 K AH0 L Z'],
  "ticklish": ['T IH1 K AH0 L IH0 SH'],
  "tickner": ['T IH1 K N ER0'],
  "ticknor": ['T IH1 K N ER0'],
  "ticks": ['T IH1 K S'],
  "ticonderoga": ['T AY0 K AA2 N D ER0 OW1 G AH0'],
  "ticonderoga's": ['T AY0 K AA2 N D ER0 OW1 G AH0 Z'],
  "ticor": ['T AY1 K AO2 R'],
  "tics": ['T IH1 K S'],
  "tidal": ['T AY1 D AH0 L'],
  "tidball": ['T IH1 D B AO2 L'],
  "tidbit": ['T IH1 D B IH2 T'],
  "tidbits": ['T IH1 D B IH0 T S'],
  "tidd": ['T IH1 D'],
  "tiddly": ['T IH1 D L IY0'],
  "tiddlywinks": ['T IH1 D L IH0 W IH0 NG K S', 'T IH1 D L IY0 W IH0 NG K S'],
  "tide": ['T AY1 D'],
  "tide's": ['T AY1 D Z'],
  "tides": ['T AY1 D Z'],
  "tidewater": ['T AY1 D W AO2 T ER0'],
  "tiding": ['T AY1 D IH0 NG'],
  "tidings": ['T AY1 D IH0 NG Z'],
  "tidmore": ['T IH1 D M AO0 R'],
  "tidrick": ['T IH1 D R IH0 K'],
  "tidwell": ['T IH1 D W AH0 L'],
  "tidy": ['T AY1 D IY0'],
  "tie": ['T AY1'],
  "tied": ['T AY1 D'],
  "tiede": ['T IY1 D'],
  "tiedeman": ['T IY1 D M AH0 N'],
  "tiedemann": ['T IY1 D M AH0 N'],
  "tiedt": ['T IY1 D T'],
  "tiegs": ['T IY1 G Z'],
  "tiein": ['T AY1 IH0 N'],
  "tieing": ['T AY1 IH0 NG'],
  "tieken": ['T IY1 K AH0 N'],
  "tieless": ['T AY1 L AH0 S'],
  "tieman": ['T IY1 M AH0 N'],
  "tiemann": ['T IY1 M AH0 N'],
  "tiemeyer": ['T IY1 M AY0 ER0'],
  "tien": ['T Y EH1 N'],
  "tien-fu": ['T Y EH1 N F UW1'],
  "tienanmen": ['T Y EH0 N AH0 N M EH1 N'],
  "tier": ['T IY1 R'],
  "tierce": ['T IY1 R S'],
  "tierco": ['T IY1 R K OW0'],
  "tiered": ['T IY1 R D'],
  "tiernan": ['T IH1 R N AH0 N', 'T IY1 R N AH0 N'],
  "tierney": ['T IH1 R N IY0', 'T IY1 R N IY0'],
  "tierno": ['T IH1 R N OW0'],
  "tiers": ['T IY1 R Z'],
  "ties": ['T AY1 Z'],
  "tieszen": ['T IY1 SH AH0 N'],
  "tietje": ['T IY1 T JH'],
  "tietjen": ['T IY1 T JH AH0 N'],
  "tietmeyer": ['T IY1 T M AY2 R', 'T AY1 T M AY2 R'],
  "tietz": ['T IY1 T S'],
  "tietze": ['T IY1 T Z', 'T IY1 T Z IY0'],
  "tieu": ['T IY0 UW1'],
  "tiff": ['T IH1 F'],
  "tiffany": ['T IH1 F AH0 N IY0'],
  "tiffany's": ['T IH1 F AH0 N IY0 Z'],
  "tiffin": ['T IH1 F IH0 N'],
  "tifft": ['T IH1 F T'],
  "tift": ['T IH1 F T'],
  "tifton": ['T IH1 F T AH0 N'],
  "tig": ['T IH1 G'],
  "tigar": ['T AY1 G AA2 R'],
  "tigar's": ['T AY1 G AA2 R Z'],
  "tiger": ['T AY1 G ER0'],
  "tiger's": ['T AY1 G ER0 Z'],
  "tigera": ['T IH0 JH EH1 R AH0'],
  "tigers": ['T AY1 G ER0 Z'],
  "tigers'": ['T AY1 G ER0 Z'],
  "tigert": ['T AY1 G ER0 T'],
  "tigges": ['T IH1 G Z'],
  "tiggs": ['T IH1 G Z'],
  "tighe": ['T AY1 G'],
  "tight": ['T AY1 T'],
  "tighten": ['T AY1 T AH0 N'],
  "tightened": ['T AY1 T AH0 N D'],
  "tightening": ['T AY1 T AH0 N IH0 NG', 'T AY1 T N IH0 NG'],
  "tightenings": ['T AY1 T AH0 N IH0 NG Z', 'T AY1 T N IH0 NG Z'],
  "tightens": ['T AY1 T AH0 N Z'],
  "tighter": ['T AY1 T ER0'],
  "tightest": ['T AY1 T AH0 S T'],
  "tightfisted": ['T AY1 T F IH1 S T IH0 D'],
  "tightly": ['T AY1 T L IY0'],
  "tightness": ['T AY1 T N AH0 S'],
  "tightrope": ['T AY1 T R OW2 P'],
  "tights": ['T AY1 T S'],
  "tightwad": ['T AY1 T W AA2 D'],
  "tigner": ['T AY1 G N ER0'],
  "tignor": ['T IH1 G N ER0'],
  "tigon": ['T IH1 G AH0 N'],
  "tigrean": ['T IH0 G R IY1 N'],
  "tigris": ['T AY1 G R AH0 S'],
  "tigue": ['T IY1 G'],
  "tijerina": ['T IY0 Y EH0 R IY1 N AH0'],
  "tijuana": ['T IH0 W AA1 N AH0'],
  "tike": ['T AY1 K'],
  "tikes": ['T AY1 K S'],
  "tiki": ['T IY1 K IY1'],
  "til": ['T IH1 L'],
  "tilbury": ['T IH1 L B EH2 R IY0'],
  "tilda": ['T IH1 L D AH0'],
  "tilde": ['T IH1 L D AH0'],
  "tilden": ['T IH1 L D AH0 N'],
  "tile": ['T AY1 L'],
  "tiled": ['T AY1 L D'],
  "tiles": ['T AY1 L Z'],
  "tiley": ['T AY1 L IY0'],
  "tilford": ['T IH1 L F ER0 D'],
  "tilghman": ['T IH1 L M AH0 N'],
  "till": ['T IH1 L'],
  "tillage": ['T IH1 L AA0 JH'],
  "tiller": ['T IH1 L ER0'],
  "tillery": ['T IH1 L ER0 IY0'],
  "tilles": ['T AY1 L Z'],
  "tillett": ['T IH1 L IH0 T'],
  "tilley": ['T IH1 L IY0'],
  "tillie": ['T IH1 L IY0'],
  "tilling": ['T IH1 L IH0 NG'],
  "tillinghast": ['T IH1 L IH0 NG HH AE2 S T'],
  "tillis": ['T IH1 L IH0 S'],
  "tillison": ['T IH1 L IH0 S AH0 N'],
  "tillman": ['T IH1 L M AH0 N'],
  "tillmon": ['T IH1 L M AH0 N'],
  "tillotson": ['T IH1 L AH0 T S AH0 N'],
  "tillson": ['T IH1 L S AH0 N'],
  "tilly": ['T IH1 L IY0'],
  "tilly's": ['T IH1 L IY0 Z'],
  "tilman": ['T IH1 L M AH0 N'],
  "tilney": ['T IH1 L N IY0'],
  "tilson": ['T IH1 L S AH0 N'],
  "tilt": ['T IH1 L T'],
  "tilted": ['T IH1 L T IH0 D'],
  "tilth": ['T IH1 L TH'],
  "tilting": ['T IH1 L T IH0 NG'],
  "tilton": ['T IH1 L T AH0 N'],
  "tilts": ['T IH1 L T S'],
  "tim": ['T IH1 M'],
  "tim's": ['T IH1 M Z'],
  "timan": ['T AY1 M AH0 N'],
  "timber": ['T IH1 M B ER0'],
  "timberlake": ['T IH1 M B ER0 L EY2 K'],
  "timberland": ['T IH1 M B ER0 L AE2 N D'],
  "timberlands": ['T IH1 M B ER0 L AE2 N D Z'],
  "timberline": ['T IH1 M B ER0 L AY2 N'],
  "timberman": ['T IH1 M B ER0 M AH0 N'],
  "timbers": ['T IH1 M B ER0 Z'],
  "timblin": ['T IH1 M B L IH0 N'],
  "timbre": ['T IH1 M B ER0'],
  "timbrook": ['T IH1 M B R UH2 K'],
  "timbs": ['T IH1 M Z'],
  "timbuktu": ['T IH2 M B AH0 K T UW1'],
  "time": ['T AY1 M'],
  "time's": ['T AY1 M Z'],
  "time-out": ['T AY1 M AW2 T'],
  "timed": ['T AY1 M D'],
  "timeframe": ['T AY1 M F R EY2 M'],
  "timeless": ['T AY1 M L AH0 S'],
  "timeline": ['T AY1 M L AY0 N'],
  "timelines": ['T AY1 M L AY0 N Z'],
  "timeliness": ['T AY1 M L IY0 N AH0 S'],
  "timely": ['T AY1 M L IY0'],
  "timeout": ['T AY1 M AW2 T'],
  "timepiece": ['T AY1 M P IY2 S'],
  "timeplex": ['T AY1 M P L EH2 K S'],
  "timer": ['T AY1 M ER0'],
  "timers": ['T AY1 M ER0 Z'],
  "times": ['T AY1 M Z'],
  "times'": ['T AY1 M Z'],
  "times's": ['T AY1 M Z IH0 Z'],
  "timeshare": ['T AY1 M SH EH2 R'],
  "timetable": ['T AY1 M T EY2 B AH0 L'],
  "timetables": ['T AY1 M T EY2 B AH0 L Z'],
  "timewise": ['T AY1 M W AY2 Z'],
  "timex": ['T AY1 M EH0 K S'],
  "timezone": ['T AY1 M Z OW1 N'],
  "timid": ['T IH1 M IH0 D'],
  "timidity": ['T AH0 M IH1 D AH0 T IY0'],
  "timidly": ['T IH1 M AH0 D L IY0'],
  "timing": ['T AY1 M IH0 NG'],
  "timisoara": ['T IH2 M AH0 S OW0 AA1 R AH0', 'T IH2 M AH0 S W AA1 R AH0'],
  "timken": ['T IH1 M K AH0 N'],
  "timko": ['T IH1 M K OW0'],
  "timlen": ['T IH1 M L AH0 N'],
  "timlin": ['T IH1 M L IH0 N'],
  "timm": ['T IH1 M'],
  "timme": ['T IH1 M'],
  "timmeny": ['T IH1 M AH0 N IY0'],
  "timmer": ['T IH1 M ER0'],
  "timmerman": ['T IH1 M ER0 M AH0 N'],
  "timmermann": ['T IH1 M ER0 M AH0 N'],
  "timmers": ['T IH1 M ER0 Z'],
  "timmie": ['T IH1 M IY0'],
  "timmins": ['T IH1 M IH0 N Z'],
  "timmons": ['T IH1 M AH0 N Z'],
  "timms": ['T IH1 M Z'],
  "timmy": ['T IH1 M IY0'],
  "timon": ['T AY1 M AH0 N'],
  "timoney": ['T IH1 M AH0 N IY0'],
  "timor": ['T IY0 M AO1 R'],
  "timor's": ['T IY0 M AO1 R Z'],
  "timorese": ['T IY2 M AO0 R IY1 Z'],
  "timorous": ['T IH1 M ER0 AH0 S'],
  "timothea": ['T IH0 M AH0 DH IY1 AH0'],
  "timothy": ['T IH1 M AH0 TH IY0'],
  "timpani": ['T IH1 M P AH0 N IY2'],
  "timpe": ['T IH1 M P'],
  "timpone": ['T IY0 M P OW1 N IY0'],
  "timpson": ['T IH1 M P S AH0 N'],
  "tims": ['T IH1 M Z'],
  "timson": ['T IH1 M S AH0 N'],
  "timur": ['T IH1 M ER0'],
  "timurian": ['T IH0 M UH1 R IY0 AH0 N'],
  "tin": ['T IH1 N'],
  "tina": ['T IY1 N AH0'],
  "tina's": ['T IY1 N AH0 Z'],
  "tinajero": ['T IY0 N AA0 Y EH1 R OW0'],
  "tinch": ['T IH1 N CH'],
  "tincher": ['T IH1 N CH ER0'],
  "tincture": ['T IH1 NG K CH ER0'],
  "tinctures": ['T IH1 NG K CH ER0 Z'],
  "tindal": ['T IH1 N D AH0 L'],
  "tindall": ['T IH1 N D AH0 L'],
  "tindel": ['T IH1 N D AH0 L'],
  "tindell": ['T IH1 N D AH0 L'],
  "tinder": ['T IH1 N D ER0'],
  "tinderbox": ['T IH1 N D ER0 B AA2 K S'],
  "tindle": ['T IH1 N D AH0 L'],
  "tindol": ['T IH1 N D AH0 L'],
  "tine": ['T AY1 N'],
  "tiner": ['T AY1 N ER0'],
  "tines": ['T AY1 N Z'],
  "ting": ['T IH1 NG'],
  "tinge": ['T IH1 N JH'],
  "tinged": ['T IH1 NG D'],
  "tingen": ['T IH1 NG AH0 N'],
  "tingey": ['T IH1 NG G IY0'],
  "tingle": ['T IH1 NG G AH0 L'],
  "tingler": ['T IH1 NG G AH0 L ER0', 'T IH1 NG G L ER0'],
  "tingley": ['T IH1 NG G L IY0'],
  "tingling": ['T IH1 NG G AH0 L IH0 NG', 'T IH1 NG G L IH0 NG'],
  "tinier": ['T AY1 N IY0 ER0'],
  "tiniest": ['T AY1 N IY0 AH0 S T'],
  "tinker": ['T IH1 NG K ER0'],
  "tinker's": ['T IH1 NG K ER0 Z'],
  "tinkered": ['T IH1 NG K ER0 D'],
  "tinkerer": ['T IH1 NG K ER0 ER0'],
  "tinkerers": ['T IH1 NG K ER0 ER0 Z'],
  "tinkering": ['T IH1 NG K ER0 IH0 NG', 'T IH1 NG K R IH0 NG'],
  "tinkey": ['T IH1 N K IY2'],
  "tinkham": ['T IH1 NG K AH0 M'],
  "tinkle": ['T IH1 NG K AH0 L'],
  "tinkled": ['T IH1 NG K AH0 L D'],
  "tinkler": ['T IH1 NG K AH0 L ER0', 'T IH1 NG K L ER0'],
  "tinkling": ['T IH1 NG K AH0 L IH0 NG', 'T IH1 NG K L IH0 NG'],
  "tinley": ['T IH1 N L IY0'],
  "tinman": ['T IH1 N M AE2 N'],
  "tinnell": ['T IH1 N AH0 L'],
  "tinney": ['T IH1 N IY0'],
  "tinnin": ['T IH1 N IH0 N'],
  "tinnitus": ['T IH1 N IH0 T AH0 S'],
  "tinnon": ['T IH1 N AH0 N'],
  "tinny": ['T IH1 N IY0'],
  "tino": ['T IY1 N OW0'],
  "tinoco": ['T IY0 N OW1 K OW0'],
  "tins": ['T IH1 N Z'],
  "tinsel": ['T IH1 N S AH0 L'],
  "tinseltown": ['T IH1 N S AH0 L T AW2 N'],
  "tinsley": ['T IH1 N S L IY0'],
  "tinsman": ['T IH1 N S M AH0 N'],
  "tint": ['T IH1 N T'],
  "tinted": ['T IH1 N T IH0 D'],
  "tintin": ['T IH1 N T IH2 N'],
  "tinto": ['T IH1 N T OW0'],
  "tinton": ['T IH1 N T AH0 N'],
  "tints": ['T IH1 N T S'],
  "tintype": ['T IH1 N T AY2 P'],
  "tiny": ['T AY1 N IY0'],
  "tip": ['T IH1 P'],
  "tipa": ['T IH1 P AH0'],
  "tiphook": ['T IH1 P HH UH2 K'],
  "tipler": ['T AY1 P AH0 L ER0', 'T AY1 P L ER0'],
  "tipo": ['T IY1 P OW0'],
  "tipoff": ['T IH1 P AO2 F'],
  "tipp": ['T IH1 P'],
  "tipped": ['T IH1 P T'],
  "tippen": ['T IH1 P AH0 N'],
  "tippens": ['T IH1 P AH0 N Z'],
  "tipper": ['T IH1 P ER0'],
  "tipperary": ['T IH1 P ER0 EH2 R IY0'],
  "tippers": ['T IH1 P ER0 Z'],
  "tippet": ['T IH1 P AH0 T'],
  "tippets": ['T IH1 P IH0 T S'],
  "tippett": ['T IH1 P IH0 T'],
  "tippetts": ['T IH1 P IH0 T S'],
  "tippie": ['T IH1 P IY0'],
  "tippin": ['T IH1 P IH0 N'],
  "tipping": ['T IH1 P IH0 NG'],
  "tippins": ['T IH1 P IH0 N Z'],
  "tippit": ['T IH1 P IH0 T'],
  "tippitt": ['T IH1 P IH0 T'],
  "tipple": ['T IH1 P AH0 L'],
  "tipps": ['T IH1 P S'],
  "tippy": ['T IH1 P IY0'],
  "tippy's": ['T IH1 P IY0 Z'],
  "tips": ['T IH1 P S'],
  "tipster": ['T IH1 P S T ER0'],
  "tipsword": ['T IH1 P S AO2 R D'],
  "tipsy": ['T IH1 P S IY0'],
  "tiptoe": ['T IH1 P T OW2'],
  "tiptoed": ['T IH1 P T OW2 D'],
  "tiptoeing": ['T IH1 P T OW2 IH0 NG'],
  "tipton": ['T IH1 P T AH0 N'],
  "tirade": ['T AY0 R EY1 D'],
  "tirades": ['T AY0 R EY1 D Z'],
  "tirado": ['T IH0 R AA1 D OW0'],
  "tiramisu": ['T IH2 R AH0 M IH1 S UW2'],
  "tirana": ['T IH1 R AA0 N AH0'],
  "tirane": ['T IH0 R EY1 N'],
  "tire": ['T AY1 ER0'],
  "tired": ['T AY1 ER0 D'],
  "tiredness": ['T AY1 ER0 D N IH0 S'],
  "tireless": ['T AY1 ER0 L AH0 S'],
  "tirelessly": ['T AY1 R L AH0 S L IY0'],
  "tirello": ['T IH0 R EH1 L OW0'],
  "tiremaker": ['T AY1 R M EY2 K ER0'],
  "tires": ['T AY1 ER0 Z'],
  "tiresome": ['T AY1 ER0 S AH0 M'],
  "tirey": ['T AY1 R IY0'],
  "tiring": ['T AY1 R IH0 NG'],
  "tirone": ['T IH0 R OW1 N'],
  "tiros": ['T AY1 R OW0 Z'],
  "tirpak": ['T ER1 P AH0 K'],
  "tirrell": ['T IH0 R EY1 L'],
  "tis": ['T IH1 Z'],
  "tisch": ['T IH1 SH'],
  "tisch's": ['T IH1 SH IH0 Z'],
  "tischer": ['T IH1 SH ER0'],
  "tischler": ['T IH1 SH AH0 L ER0', 'T IH1 SH L ER0'],
  "tisdale": ['T IH1 S D EY0 L'],
  "tisdel": ['T IH1 S D AH0 L'],
  "tisdell": ['T IH1 S D AH0 L'],
  "tish": ['T IH1 SH'],
  "tish's": ['T IH1 SH IH0 Z'],
  "tisher": ['T IH1 SH ER0'],
  "tishler": ['T IH1 SH L ER0'],
  "tishman": ['T IH1 SH M AH0 N'],
  "tison": ['T IH1 S AH0 N'],
  "tissue": ['T IH1 S Y UW2', 'T IH1 SH UW0'],
  "tissues": ['T IH1 S Y UW2 Z', 'T IH1 SH UW0 Z'],
  "tit": ['T IH1 T'],
  "tit-for-tat": ['T IH1 T F AO2 R T AE1 T'],
  "tita": ['T IY1 T AH0'],
  "titan": ['T AY1 T AH0 N'],
  "titanate": ['T AY1 T AH0 N EY2 T'],
  "titania": ['T AH0 T AA1 N Y AH0'],
  "titanic": ['T AY0 T AE1 N IH0 K'],
  "titanium": ['T AY0 T EY1 N IY0 AH0 M'],
  "titans": ['T AY1 T AH0 N Z'],
  "titcomb": ['T IH1 T K AH0 M'],
  "tithe": ['T AY1 DH'],
  "tithing": ['T AY1 DH IH0 NG'],
  "titian": ['T IH1 SH AH0 N'],
  "titillate": ['T IH1 T AH0 L EY2 T'],
  "titillated": ['T IH1 T AH0 L EY2 T IH0 D'],
  "titillating": ['T IH1 T AH0 L EY2 T IH0 NG'],
  "titillation": ['T IH2 T IH0 L EY1 SH AH0 N'],
  "titinus": ['T IH1 T AH0 N AH0 S'],
  "title": ['T AY1 T AH0 L'],
  "titled": ['T AY1 T AH0 L D'],
  "titleholder": ['T AY1 T AH0 L HH OW2 L D ER0'],
  "titles": ['T AY1 T AH0 L Z'],
  "titlow": ['T IH1 T L OW2'],
  "titman": ['T IH1 T M AH0 N'],
  "tito": ['T IY1 T OW0'],
  "tito's": ['T IY1 T OW0 Z'],
  "titone": ['T IH1 T AH0 N'],
  "tits": ['T IH1 T S'],
  "titsworth": ['T IH1 T S W ER2 TH'],
  "tittel": ['T IH1 T AH0 L'],
  "titter": ['T IH1 T ER0'],
  "titterington": ['T IH1 T ER0 IH0 NG T AH0 N'],
  "tittle": ['T IH1 T AH0 L'],
  "tittsworth": ['T IH1 T S W ER0 TH'],
  "titular": ['T IH1 CH AH0 L ER0'],
  "titus": ['T AY1 T AH0 S'],
  "titusville": ['T AY1 T AH0 S V IH2 L'],
  "titzer": ['T IH1 T Z ER0'],
  "tivo": ['T IH1 V OW2'],
  "tivoli": ['T IH1 V AH0 L IY0'],
  "tizzy": ['T IH1 Z IY0'],
  "tjaden": ['JH EY1 D AH0 N'],
  "tjarks": ['JH AA1 R K S'],
  "tkach": ['K AE1 CH'],
  "tkacz": ['K AA1 CH'],
  "tlateloco": ['T L AE2 T IH0 L OW1 K OW0'],
  "tlc": ['T IY2 EH2 L S IY1'],
  "tlingit": ['T L IY1 NG G IH0 T'],
  "to": ['T UW1', 'T IH0', 'T AH0'],
  "to-do": ['T UW2 D UW1'],
  "to-dos": ['T UW2 D UW1 Z'],
  "toa": ['T OW1 AA0'],
  "toad": ['T OW1 D'],
  "toads": ['T OW1 D Z'],
  "toal": ['T OW1 L'],
  "toalson": ['T OW1 L S AH0 N'],
  "toalster": ['T OW1 L S T ER0'],
  "toast": ['T OW1 S T'],
  "toasted": ['T OW1 S T IH0 D'],
  "toaster": ['T OW1 S T ER0'],
  "toasters": ['T OW1 S T ER0 Z'],
  "toasting": ['T OW1 S T IH0 NG'],
  "toastmaster": ['T OW1 S T M AE2 S T ER0'],
  "toasts": ['T OW1 S T S'],
  "tobacco": ['T AH0 B AE1 K OW2'],
  "tobacco's": ['T AH0 B AE1 K OW2 Z'],
  "tobaccos": ['T AH0 B AE1 K OW2 Z'],
  "toback": ['CH UW1 B AE0 K'],
  "tobago": ['T AH0 B EY1 G OW0'],
  "tobar": ['T OW1 B ER0'],
  "tobe": ['T OW1 B'],
  "toben": ['T OW1 B AH0 N'],
  "tober": ['T OW1 B ER0'],
  "tobey": ['T OW1 B IY0'],
  "tobia": ['T OW1 B IY0 AH0'],
  "tobias": ['T AH0 B AY1 AH0 S'],
  "tobiason": ['T AH0 B AY1 AH0 S AH0 N'],
  "tobie": ['T OW1 B IY0'],
  "tobin": ['T OW1 B IH0 N'],
  "tobler": ['T OW1 B AH0 L ER0', 'T OW1 B L ER0'],
  "toboggan": ['T AH0 B AA1 G AH0 N'],
  "toboggans": ['T AH0 B AA1 G AH0 N Z'],
  "tobolski": ['T AH0 B OW1 L S K IY0'],
  "tobruk": ['T OW1 B R UH0 K'],
  "tobu": ['T OW0 B UW1'],
  "toby": ['T OW1 B IY0'],
  "toby's": ['T OW1 B IY0 Z'],
  "tocci": ['T OW1 CH IY0'],
  "tocco": ['T AA1 K OW0'],
  "tock": ['T AA1 K'],
  "tocqueville": ['T OW1 K V IH0 L'],
  "tocqueville's": ['T OW1 K V IH0 L Z'],
  "toczek": ['T AA1 CH EH0 K'],
  "tod": ['T AA1 D'],
  "toda": ['T OW1 D AH0'],
  "todaro": ['T OW0 D AA1 R OW0'],
  "today": ['T AH0 D EY1', 'T UW0 D EY1'],
  "today'll": ['T AH0 D EY1 L', 'T UW0 D EY1 L'],
  "today's": ['T AH0 D EY1 Z', 'T UW1 D EY0 Z'],
  "todays": ['T AH0 D EY1 Z', 'T UW1 D EY0 Z'],
  "todd": ['T AA1 D'],
  "todd's": ['T AA1 D Z'],
  "toddle": ['T AA1 D AH0 L'],
  "toddler": ['T AA1 D L ER0'],
  "toddlers": ['T AA1 D L ER0 Z'],
  "toddling": ['T AA1 D L IH0 NG'],
  "todhunter": ['T AA1 D HH AH2 N T ER0'],
  "todi's": ['T OW1 D IY0 S'],
  "todisco": ['T OW0 D IY1 S K OW0'],
  "todman": ['T AA1 D M AH0 N'],
  "todo": ['T UW2 D UW1'],
  "todoroff": ['T AA1 D ER0 AO0 F'],
  "todos": ['T UW2 D UW1 Z'],
  "todt": ['T AA1 D T'],
  "toe": ['T OW1'],
  "toed": ['T OW1 D'],
  "toehold": ['T OW1 HH OW2 L D'],
  "toeing": ['T OW1 IH0 NG'],
  "toelle": ['T OW1 L'],
  "toenail": ['T OW1 N EY2 L'],
  "toenails": ['T OW1 N EY2 L Z'],
  "toenjes": ['T OW1 N JH IH0 Z'],
  "toensing": ['T OW1 N S IH0 NG'],
  "toepfer": ['T OW1 P F ER0'],
  "toes": ['T OW1 Z'],
  "toews": ['T AA1 UW0 Z'],
  "toff": ['T AO1 F'],
  "toffee": ['T AA1 F IY0'],
  "toffler": ['T AO1 F L ER0'],
  "toft": ['T AA1 F T'],
  "tofte": ['T OW1 F T'],
  "tofu": ['T OW1 F UW0'],
  "toga": ['T OW1 G AH0'],
  "together": ['T AH0 G EH1 DH ER0'],
  "togetherness": ['T AH0 G EH1 DH ER0 N AH0 S'],
  "togethers": ['T AH0 G EH1 DH ER0 Z'],
  "toggle": ['T AA1 G AH0 L'],
  "toggled": ['T AA1 G AH0 L D'],
  "toggling": ['T AA1 G L IH0 NG'],
  "tognino": ['T AA2 G N IY1 N OW0'],
  "togo": ['T OW1 G OW0'],
  "togs": ['T AA1 G Z'],
  "toibin": ['T OY1 B IH0 N'],
  "toil": ['T OY1 L'],
  "toiled": ['T OY1 L D'],
  "toilet": ['T OY1 L AH0 T'],
  "toilet's": ['T OY1 L AH0 T S'],
  "toiletries": ['T OY1 L AH0 T R IY0 Z'],
  "toiletry": ['T OY1 L AH0 T R IY0'],
  "toilets": ['T OY1 L AH0 T S'],
  "toiling": ['T OY1 L IH0 NG'],
  "toils": ['T OY1 L Z'],
  "toity": ['T OY1 T IY0'],
  "toivonen": ['T OY1 V AH0 N AH0 N'],
  "tokai": ['T OW0 K AY1'],
  "tokar": ['T OW0 K AA1 R'],
  "tokarczyk": ['T AA1 K ER0 CH IH0 K'],
  "tokars": ['T OW0 K AA1 R Z'],
  "tokarski": ['T AH0 K AA1 R S K IY0'],
  "tokarz": ['T OW1 K AA0 R Z'],
  "token": ['T OW1 K AH0 N'],
  "tokenism": ['T OW1 K AH0 N IH2 Z AH0 M'],
  "tokens": ['T OW1 K AH0 N Z'],
  "tokio": ['T OW0 K IY1 OW0'],
  "tokkin": ['T AA1 K IH0 N'],
  "tokos": ['T OW1 K OW0 S'],
  "tokunaga": ['T OW0 K UW0 N AA1 G AH0'],
  "tokuo": ['T AA1 K Y UW0 OW0'],
  "tokuyama": ['T OW2 K UW2 Y AA1 M AH0'],
  "tokyo": ['T OW1 K IY0 OW2'],
  "tokyo's": ['T OW1 K IY0 OW2 Z'],
  "tokyu": ['T OW1 K Y UW0'],
  "tol": ['T AO1 L'],
  "tolan": ['T OW1 L AH0 N'],
  "toland": ['T OW1 L AH0 N D'],
  "tolanthe": ['T OW0 L AA1 N DH IY0'],
  "tolar": ['T OW1 L ER0'],
  "tolbert": ['T OW1 L B ER0 T'],
  "told": ['T OW1 L D'],
  "toldrian": ['T OW1 L D R IY0 AH0 N'],
  "tole": ['T OW1 L'],
  "toledo": ['T AH0 L IY1 D OW0'],
  "toledo's": ['T AH0 L IY1 D OW0 Z'],
  "tolen": ['T OW1 L AH0 N'],
  "tolentino": ['T OW2 L EH0 N T IY1 N OW0'],
  "toler": ['T OW1 L ER0'],
  "tolerable": ['T AA1 L ER0 AH0 B AH0 L'],
  "tolerance": ['T AA1 L ER0 AH0 N S'],
  "tolerances": ['T AA1 L ER0 AH0 N S IH0 Z'],
  "tolerant": ['T AA1 L ER0 AH0 N T'],
  "tolerants": ['T AA1 L ER0 AH0 N T S'],
  "tolerate": ['T AA1 L ER0 EY2 T'],
  "tolerated": ['T AA1 L ER0 EY2 T IH0 D'],
  "tolerates": ['T AO1 L ER0 EY2 T S'],
  "tolerating": ['T AA1 L ER0 EY2 T IH0 NG'],
  "toleration": ['T AA2 L ER0 EY1 SH AH0 N'],
  "toles": ['T OW1 L Z'],
  "tolhurst": ['T OW1 L HH ER0 S T'],
  "tolin": ['T OW1 L IH0 N'],
  "toliver": ['T OW1 L IH0 V ER0'],
  "tolkien": ['T AO1 L K IY0 EH2 N'],
  "toll": ['T OW1 L'],
  "tolland": ['T AA1 L AH0 N D'],
  "tolland's": ['T AA1 L AH0 N D Z'],
  "tollbooth": ['T OW1 L B UW2 TH'],
  "tolle": ['T AA1 L'],
  "tolled": ['T OW1 L D'],
  "tollefsen": ['T AA1 L IH0 F S AH0 N'],
  "tollefson": ['T AA1 L IH0 F S AH0 N'],
  "toller": ['T OW1 L ER0'],
  "tolles": ['T OW1 L Z'],
  "tolleson": ['T AA1 L IH0 S AH0 N'],
  "tollett": ['T AA1 L IH0 T'],
  "tolley": ['T AA1 L IY0'],
  "tolling": ['T OW1 L IH0 NG'],
  "tollison": ['T AA1 L IH0 S AH0 N'],
  "tolliver": ['T OW1 L IH0 V ER0'],
  "tolls": ['T OW1 L Z'],
  "tolly": ['T OW1 L IY0'],
  "tolman": ['T AA1 L M AH0 N'],
  "tolsma": ['T OW1 L S M AH0'],
  "tolson": ['T OW1 L S AH0 N'],
  "tolstoy": ['T OW1 L S T OY2'],
  "tolstoy's": ['T OW1 L S T OY2 Z'],
  "toluene": ['T AO1 L UW0 IY2 N'],
  "tom": ['T AA1 M'],
  "tom's": ['T AA1 M Z'],
  "toma": ['T OW1 M AH0'],
  "tomahawk": ['T AA1 M AH0 HH AO2 K'],
  "tomahawks": ['T AA1 M AH0 HH AO2 K S'],
  "tomaino": ['T OW0 M AA0 IY1 N OW0'],
  "tomako": ['T OW0 M AA1 K OW0'],
  "tomako's": ['T OW0 M AA1 K OW0 Z'],
  "toman": ['T OW1 M AH0 N'],
  "tomanek": ['T AA1 M AH0 N IH0 K'],
  "tomaro": ['T OW0 M AA1 R OW0'],
  "tomas": ['T OW0 M AA1 S'],
  "tomasek": ['T AH0 M AA1 S EH0 K'],
  "tomaselli": ['T OW0 M AA0 S EH1 L IY0'],
  "tomasello": ['T OW0 M AA0 S EH1 L OW0'],
  "tomasetti": ['T OW0 M AA0 S EH1 T IY0'],
  "tomasi": ['T OW0 M AA1 S IY0'],
  "tomasic": ['T AH0 M AA1 S IH0 K'],
  "tomasik": ['T AH0 M AA1 S IH0 K'],
  "tomasina": ['T AO2 M AH0 S IY1 N AH0'],
  "tomasine": ['T OW0 M AA0 S IY1 N IY0'],
  "tomasini": ['T OW0 M AA0 S IY1 N IY0'],
  "tomasino": ['T OW0 M AA0 S IY1 N OW0'],
  "tomasko": ['T AH0 M AA1 S K OW0'],
  "tomaso": ['T OW0 M AA1 S OW0'],
  "tomassetti": ['T OW0 M AA0 S EH1 T IY0'],
  "tomassi": ['T OW0 M AA1 S IY0'],
  "tomasso": ['T OW0 M AA1 S OW0'],
  "tomasulo": ['T OW0 M AA0 S UW1 L OW0'],
  "tomaszewski": ['T AH0 M AH0 SH EH1 F S K IY0'],
  "tomatino": ['T AA2 M AH0 T IY1 N OW0'],
  "tomatino's": ['T AA2 M AH0 T IY1 N OW0 Z'],
  "tomato": ['T AH0 M EY1 T OW2', 'T AH0 M AA1 T OW2'],
  "tomatoe": ['T AH0 M EY1 T OW0', 'T AH0 M AA1 T OW0'],
  "tomatoes": ['T AH0 M EY1 T OW0 Z', 'T AH0 M AA1 T OW0 Z'],
  "tomatos": ['T AH0 M EY1 T OW2 Z', 'T AH0 M AA1 T OW2 Z'],
  "tomayko": ['T AH0 M AY1 K OW0'],
  "tomb": ['T UW1 M'],
  "tomberlin": ['T AA1 M B ER0 L IH0 N'],
  "tomblike": ['T UW1 M L AY2 K'],
  "tomblin": ['T AA1 M B L IH0 N'],
  "tomboy": ['T AA1 M B OY2'],
  "tombs": ['T UW1 M Z'],
  "tombstone": ['T UW1 M S T OW2 N'],
  "tombstones": ['T UW1 M S T OW2 N Z'],
  "tomcat": ['T AA1 M K AE2 T'],
  "tomczak": ['T AA1 M CH AE0 K'],
  "tomczyk": ['T AA1 M CH IH0 K'],
  "tome": ['T OW1 M'],
  "tomei": ['T AA1 M AY0'],
  "tomek": ['T OW1 M EH0 K'],
  "tomeo": ['T OW1 M IY0 OW0'],
  "tomer": ['T OW1 M ER0'],
  "tomerlin": ['T AA1 M ER0 L IH0 N'],
  "tomes": ['T OW1 M Z'],
  "tomey": ['T OW1 M IY0'],
  "tomich": ['T AA1 M IH0 K'],
  "tomiichi": ['T OW2 M IY0 IY1 CH IY0'],
  "tomilson": ['T AA1 M AH0 L S AH0 N'],
  "tomita": ['T OW0 M IY1 T AH0'],
  "tomkiewicz": ['T AA1 M K AH0 V IH0 CH'],
  "tomkin": ['T AA1 M K IH0 N'],
  "tomkins": ['T AA1 M K IH0 N Z'],
  "tomkinson": ['T AA1 M K IH0 N S AH0 N'],
  "tomko": ['T AA1 M K OW0'],
  "tomlin": ['T AA1 M L IH0 N'],
  "tomlinson": ['T AA1 M L IH0 N S AH0 N'],
  "tommie": ['T AA1 M IY0'],
  "tommy": ['T AA1 M IY0'],
  "tommy's": ['T AA1 M IY0 Z'],
  "tommy-lee": ['T AA1 M IY0 L IY1'],
  "tomographic": ['T OW0 M OW0 G R AE1 F IH0 K'],
  "tomography": ['T OW0 M OW1 G R AE0 F IY2'],
  "tomorrow": ['T AH0 M AA1 R OW2', 'T UW0 M AA1 R OW2'],
  "tomorrow's": ['T AH0 M AA1 R OW2 Z', 'T UW0 M AA1 R OW2 Z'],
  "tomorrows": ['T AH0 M AA1 R OW2 Z', 'T UW0 M AA1 R OW2 Z'],
  "tompane": ['T AA1 M P EY2 N'],
  "tompkins": ['T AA1 M P K IH0 N Z'],
  "tompkinses": ['T AA1 M P K IH0 N S IH0 Z'],
  "tompson": ['T AA1 M P S AH0 N'],
  "toms": ['T AA1 M Z'],
  "tomshich": ['T AA1 M SH IH0 K'],
  "tomsic": ['T AA1 M S IH0 K'],
  "tomson": ['T AA1 M S AH0 N'],
  "ton": ['T AH1 N'],
  "tonal": ['T OW1 N AH0 L'],
  "tonalities": ['T OW0 N AE1 L AH0 T IY0 Z'],
  "tonality": ['T OW0 N AE1 L AH0 T IY0'],
  "tonawanda": ['T AA2 N AH0 W AA1 N D AH0'],
  "tonda": ['T AA1 N D AH0'],
  "tondreau": ['T AH0 N D R OW1'],
  "tone": ['T OW1 N'],
  "toned": ['T OW1 N D'],
  "tonegawa": ['T OW2 N IH0 G AA1 W AH0'],
  "tonelli": ['T OW0 N EH1 L IY0'],
  "toner": ['T OW1 N ER0'],
  "tones": ['T OW1 N Z'],
  "toney": ['T OW1 N IY0'],
  "tong": ['T AO1 NG'],
  "tonga": ['T AA1 N G AH0'],
  "tonga's": ['T AA1 N G AH0 Z'],
  "tonge": ['T AA1 N JH'],
  "tongs": ['T AA1 NG Z', 'T AO1 NG Z'],
  "tongue": ['T AH1 NG'],
  "tongued": ['T AH1 NG D'],
  "tongues": ['T AH1 NG Z'],
  "toni": ['T OW1 N IY0'],
  "tonia": ['T OW1 N IY0 AH0'],
  "tonic": ['T AA1 N IH0 K'],
  "tonics": ['T AA1 N IH0 K S'],
  "tonie": ['T OW1 N IY0'],
  "toniest": ['T OW0 N IY1 S T'],
  "tonight": ['T AH0 N AY1 T', 'T UH0 N AY1 T'],
  "tonight's": ['T AH0 N AY1 T S', 'T UH0 N AY1 T S'],
  "toning": ['T OW1 N IH0 NG'],
  "tonini": ['T OW0 N IY1 N IY0'],
  "tonite": ['T AH0 N AY1 T'],
  "tonjes": ['T OW1 N Y EH0 S'],
  "tonk": ['T AO1 NG K'],
  "tonka": ['T AA1 NG K AH0'],
  "tonka's": ['T AA1 NG K AH0 Z'],
  "tonkin": ['T AA1 NG K IH0 N'],
  "tonkovich": ['T AA1 NG K AH0 V IH0 CH'],
  "tonks": ['T AA1 NG K S'],
  "tonn": ['T AA1 N'],
  "tonnage": ['T AH1 N AH0 JH', 'T AH1 N IH0 JH'],
  "tonnages": ['T AH1 N AH0 JH AH0 Z'],
  "tonne": ['T AH1 N'],
  "tonner": ['T AH1 N ER0'],
  "tonnes": ['T AH1 N Z'],
  "tonnesen": ['T AH1 N S AH0 N'],
  "tons": ['T AH1 N Z'],
  "tonsil": ['T AA2 N S AH0 L'],
  "tonsillectomies": ['T AA2 N S IH0 L EH1 K T AH0 M IY2 Z'],
  "tonsillectomy": ['T AA2 N S IH0 L EH1 K T AH0 M IY2'],
  "tonsils": ['T AA1 N S AH0 L Z'],
  "tonti": ['T AA1 N T IY0'],
  "tonto": ['T AA1 N T OW2'],
  "tonto's": ['T AA1 N T OW2 Z'],
  "tonton": ['T AA1 N T AH0 N'],
  "tontons": ['T AA1 N T AH0 N Z'],
  "tony": ['T OW1 N IY0'],
  "tony's": ['T OW1 N IY0 Z'],
  "tonya": ['T AA1 N Y AH0'],
  "tonya's": ['T AA1 N Y AH0 Z'],
  "tonyes": ['T OW1 N Y AH0 Z'],
  "tonys": ['T OW1 N IY0 Z'],
  "tonysk": ['T OW1 N IY0 S K'],
  "too": ['T UW1'],
  "toobin": ['T UW1 B AH0 N'],
  "toobin's": ['T UW1 B AH0 N Z'],
  "toogood": ['T UW1 G UH2 D'],
  "toohey": ['T UW1 IY0'],
  "took": ['T UH1 K'],
  "tooke": ['T UH1 K'],
  "tooker": ['T UH1 K ER0'],
  "tookes": ['T UH1 K S'],
  "tool": ['T UW1 L'],
  "toolan": ['T UW1 L AH0 N'],
  "toolbar": ['T UW1 L B AA2 R'],
  "toolbox": ['T UW1 L B AO2 K S'],
  "toole": ['T UW1 L'],
  "tooled": ['T UW1 L D'],
  "tooley": ['T UW1 L IY0'],
  "tooling": ['T UW1 L IH0 NG'],
  "toolmaker": ['T UW1 L M EY2 K ER0'],
  "toolmakers": ['T UW1 L M EY2 K ER0 Z'],
  "toolroom": ['T UW1 L R UW2 M'],
  "tools": ['T UW1 L Z'],
  "toolworks": ['T UW1 L W ER2 K S'],
  "tooman": ['T UW1 M AH0 N'],
  "toombs": ['T UW1 M Z'],
  "toomer": ['T UW1 M ER0'],
  "toomey": ['T UW1 M IY0'],
  "toon": ['T UW1 N'],
  "toone": ['T UW1 N'],
  "toops": ['T UW1 P S'],
  "toot": ['T UW1 T'],
  "tootal": ['T UW1 T AH0 L'],
  "toote": ['T UW1 T'],
  "tooth": ['T UW1 TH'],
  "toothaker": ['T UW1 TH AH0 K ER0'],
  "toothbrush": ['T UW1 TH B R AH0 SH'],
  "toothbrushes": ['T UW1 TH B R AH2 SH IH0 Z'],
  "toothed": ['T UW1 TH T', 'T UW1 DH D'],
  "toothless": ['T UW1 TH L AH0 S'],
  "toothlike": ['T UW1 TH L AY2 K'],
  "toothman": ['T UW1 TH M AH0 N'],
  "toothpaste": ['T UW1 TH P EY2 S T'],
  "toothpastes": ['T UW1 TH P EY2 S T S'],
  "toothpick": ['T UW1 TH P IH2 K'],
  "toothpicks": ['T UW1 TH P IH2 K S'],
  "toothy": ['T UW1 TH IY0'],
  "tootle": ['T UW1 T AH0 L'],
  "toots": ['T UW1 T S', 'T UH1 T S'],
  "tootsie": ['T UW1 T S IY1', 'T UH1 T S IY1'],
  "top": ['T AA1 P', 'T AO1 P'],
  "top-down": ['T AA1 P D AW1 N'],
  "top-secret": ['T AA1 P S IY1 K R AH0 T'],
  "top-up": ['T AA1 P AH2 P'],
  "topalian": ['T AH0 P EY1 L IY0 AH0 N'],
  "topanga": ['T OW0 P AE1 NG G AA2', 'T AH0 P AE1 NG G AH0'],
  "topaz": ['T OW1 P AE2 Z'],
  "tope": ['T OW1 P'],
  "topeka": ['T AH0 P IY1 K AA0', 'T OW0 P IY1 K AA0'],
  "topeka's": ['T AH0 P IY1 K AH0 Z'],
  "topekan": ['T AH0 P IY1 K AH0 N'],
  "topekans": ['T AH0 P IY1 K AH0 N Z'],
  "topel": ['T OW1 P AH0 L'],
  "topete": ['T AA1 P IY0 T'],
  "topham": ['T AA1 F AH0 M'],
  "topiary": ['T OW1 P IY0 EH2 R IY0'],
  "topic": ['T AA1 P IH0 K'],
  "topical": ['T AA1 P AH0 K AH0 L', 'T AA1 P IH0 K AH0 L'],
  "topics": ['T AA1 P IH0 K S'],
  "topix": ['T OW1 P IH2 K S', 'T AA1 P IH2 K S'],
  "topkapi": ['T AA2 P K AA1 P IY0'],
  "topknot": ['T AA1 P N AA2 T'],
  "topless": ['T AA1 P L AH0 S'],
  "topliff": ['T AA1 P L IH0 F'],
  "topographic": ['T AA2 P AH0 G R AE1 F IH0 K'],
  "topography": ['T AH0 P AA1 G R AH0 F IY0'],
  "topol": ['T OW1 P AA0 L'],
  "topological": ['T AH0 P AH0 L AA1 JH IH0 K AH0 L'],
  "topology": ['T AH0 P AO1 L AH0 JH IY0'],
  "topolski": ['T AH0 P OW1 L S K IY0'],
  "topor": ['T AA1 P ER0'],
  "topp": ['T AA1 P'],
  "topped": ['T AA1 P T'],
  "topper": ['T AA1 P ER0'],
  "toppers": ['T AA1 P ER0 Z'],
  "toppin": ['T AA1 P IH0 N'],
  "topping": ['T AA1 P IH0 NG'],
  "toppings": ['T AA1 P IH0 NG Z'],
  "toppins": ['T AA1 P IH0 N Z'],
  "topple": ['T AA1 P AH0 L'],
  "toppled": ['T AA1 P AH0 L D'],
  "topples": ['T AA1 P AH0 L Z'],
  "toppling": ['T AA1 P AH0 L IH0 NG', 'T AA1 P L IH0 NG'],
  "topps": ['T AA1 P S'],
  "tops": ['T AA1 P S'],
  "topsoil": ['T AA1 P S OY2 L'],
  "topsy": ['T AA1 P S IY0'],
  "topsy-turvy": ['T AA1 P S IY0 T ER1 V IY0'],
  "toquepala": ['T AA2 K W EH0 P AA1 L AH0'],
  "tor": ['T AO1 R'],
  "torah": ['T AO1 R AH0'],
  "torain": ['T ER0 EY1 N'],
  "torald": ['T AO1 R AH0 L D'],
  "toran": ['T AO0 R AA1 N'],
  "toray": ['T AO0 R EY1'],
  "torbeck": ['T AO1 R B EH0 K'],
  "torbert": ['T AO1 R B ER0 T'],
  "torbett": ['T AO1 R B IH0 T'],
  "torch": ['T AO1 R CH'],
  "torched": ['T AO1 R CH T'],
  "torches": ['T AO1 R CH IH0 Z'],
  "torchia": ['T AO1 R K IY0 AH0'],
  "torching": ['T AO1 R CH IH0 NG'],
  "torchmark": ['T AO1 R CH M AA2 R K'],
  "tordella": ['T AO2 R D EH1 L AH0'],
  "tore": ['T AO1 R'],
  "torell": ['T AO0 R EH1 L'],
  "torelli": ['T AO0 R EH1 L IY0'],
  "torello": ['T AO0 R EH1 L OW0'],
  "toren": ['T AO1 R AH0 N'],
  "torey": ['T AO1 R IY0'],
  "torgersen": ['T AO1 R G ER0 S AH0 N'],
  "torgerson": ['T AO1 R G ER0 S AH0 N'],
  "torgeson": ['T AO1 R G IH0 S AH0 N'],
  "tori": ['T AO1 R IY0'],
  "toria": ['T AO1 R IY2 AH0'],
  "torian": ['T AO1 R IY0 AH0 N'],
  "toribio": ['T AO0 R IY1 B IY0 OW0'],
  "torie": ['T AO1 R IY2'],
  "toriente": ['T AO2 R IY0 EH1 N T EY0'],
  "tories": ['T AO1 R IY2 Z'],
  "tories'": ['T AO1 R IY2 Z'],
  "torino": ['T AO0 R IY1 N OW0'],
  "torkelson": ['T AO1 R K IH0 L S AH0 N'],
  "torley": ['T AO1 R L IY0'],
  "torma": ['T AO1 R M AH0'],
  "torme": ['T AO1 R M', 'T AO2 R M EY1'],
  "torment": ['T AO1 R M EH2 N T', 'T AO0 R M EH1 N T'],
  "tormenta": ['T AO2 R M EH1 N T AH0'],
  "tormented": ['T AO1 R M EH2 N T IH0 D'],
  "tormenting": ['T AO1 R M EH2 N T IH0 NG'],
  "tormentor": ['T AO1 R M EH2 N T ER0'],
  "tormentors": ['T AO1 R M EH2 N T ER0 Z'],
  "torments": ['T AO1 R M EH2 N T S'],
  "tormey": ['T AO1 R M IY0'],
  "torn": ['T AO1 R N'],
  "tornabene": ['T AO0 R N AA0 B EH1 N AH0'],
  "tornadic": ['T AO0 R N EY1 D IH0 K'],
  "tornado": ['T AO0 R N EY1 D OW2'],
  "tornado's": ['T AO0 R N EY1 D OW2 Z'],
  "tornadoes": ['T AO0 R N EY1 D OW0 Z'],
  "tornados": ['T AO0 R N EY1 D OW2 Z'],
  "tornatore": ['T AO0 R N AA0 T AO1 R IY0'],
  "torney": ['T AO1 R N IY0'],
  "torno": ['T AO1 R N OW0'],
  "tornow": ['T AO1 R N OW0'],
  "tornquist": ['T AO1 R N K W IH0 S T'],
  "toro": ['T AO1 R OW0'],
  "torok": ['T AO1 R AH0 K'],
  "toronado": ['T AO2 R AH0 N AA1 D OW0'],
  "toronto": ['T ER0 AA1 N T OW0', 'T AO0 R AA1 N T OW0'],
  "toronto's": ['T ER0 AA1 N T OW0 Z', 'T AO0 R AA1 N T OW0 Z'],
  "torosian": ['T ER0 AA1 ZH IH0 N'],
  "torp": ['T AO1 R P'],
  "torpedo": ['T AO0 R P IY1 D OW2'],
  "torpedoed": ['T AO0 R P IY1 D OW2 D'],
  "torpedoes": ['T AO0 R P IY1 D OW0 Z'],
  "torpedoing": ['T AO0 R P IY1 D OW2 IH0 NG'],
  "torpedos": ['T AO0 R P IY1 D OW2 Z'],
  "torpey": ['T AO1 R P IY0'],
  "torpid": ['T AO1 R P AH0 D'],
  "torpor": ['T AO1 R P ER0'],
  "torque": ['T AO1 R K'],
  "torr": ['T AO1 R'],
  "torrance": ['T AO1 R AH0 N S'],
  "torras": ['T AO1 R AH0 S'],
  "torray": ['T AO1 R EY0'],
  "torre": ['T AO1 R'],
  "torregrossa": ['T AO0 R EH0 G R OW1 S AH0'],
  "torrejon": ['T AO1 R AH0 JH AA0 N'],
  "torrence": ['T AO1 R AH0 N S'],
  "torrens": ['T AO1 R AH0 N Z'],
  "torrent": ['T AO1 R AH0 N T'],
  "torrential": ['T AO0 R EH1 N SH AH0 L'],
  "torrents": ['T AO1 R AH0 N T S'],
  "torrenzano": ['T AO2 R EH0 N Z AA1 N OW0'],
  "torreon": ['T AO1 R IY0 AH0 N'],
  "torres": ['T AO1 R EH2 Z'],
  "torrey": ['T AO1 R IY0'],
  "torrez": ['T AO0 R EH1 Z'],
  "torricelli": ['T AO2 R IH0 S EH1 L IY0'],
  "torrid": ['T AO1 R AH0 D'],
  "torrijos": ['T AO0 R IY1 OW0 S'],
  "torrington": ['T AO1 R IH0 NG T AH0 N'],
  "torrisi": ['T AO0 R IY1 S IY0'],
  "torry": ['T AO1 R IY0'],
  "torsiello": ['T AO0 R S IY0 EH1 L OW0'],
  "torsion": ['T AO1 R SH AH0 N'],
  "torso": ['T AO1 R S OW2'],
  "torsos": ['T AO1 R S OW2 Z'],
  "torstar": ['T AO1 R S T AA2 R'],
  "torsten": ['T AO1 R S T AH0 N'],
  "tort": ['T AO1 R T'],
  "torte": ['T AO1 R T'],
  "torti": ['T AO1 R T IY0'],
  "tortilla": ['T AO0 R T IY1 AH0'],
  "tortillas": ['T AO2 R T IY1 AH0 Z'],
  "tortoise": ['T AO1 R T AH0 S'],
  "tortoises": ['T AO1 R T AH0 S AH0 Z'],
  "tortora": ['T AO0 R T AO1 R AH0'],
  "tortorella": ['T AO0 R T AO0 R EH1 L AH0'],
  "tortorelli": ['T AO0 R T AO0 R EH1 L IY0'],
  "tortorello": ['T AO0 R T AO0 R EH1 L OW0'],
  "tortorice": ['T AO0 R T AO1 R IH0 S'],
  "tortorici": ['T AO0 R T AO0 R IY1 CH IY0'],
  "tortoriello": ['T AO0 R T AO0 R IY0 EH1 L OW0'],
  "torts": ['T AO1 R T S'],
  "tortuous": ['T AO1 R CH AH0 W AH0 S'],
  "torture": ['T AO1 R CH ER0'],
  "tortured": ['T AO1 R CH ER0 D'],
  "torturer": ['T AO1 R CH ER0 ER0'],
  "torturers": ['T AO1 R CH ER0 ER0 Z'],
  "tortures": ['T AO1 R CH ER0 Z'],
  "torturing": ['T AO1 R CH ER0 IH0 NG'],
  "torturous": ['T AO1 R CH UW2 AH0 S'],
  "toru": ['T AO1 R UW0'],
  "torumi": ['T AO2 R UW1 M IY0'],
  "torumi's": ['T AO2 R UW1 M IY0 Z'],
  "tory": ['T AO1 R IY0'],
  "tosca": ['T AO1 S K AH0'],
  "toscanini": ['T AO2 S K AH0 N IY1 N IY0'],
  "toscanini's": ['T AH2 S K AH0 N IY1 N IY0 Z'],
  "toscano": ['T OW0 S K AA1 N OW0'],
  "tosch": ['T AO1 SH'],
  "tosco": ['T AO1 S K OW0'],
  "tosh": ['T AA1 SH'],
  "toshiba": ['T OW0 SH IY1 B AH0'],
  "toshiba's": ['T OW0 SH IY1 B AH0 Z'],
  "toshiharu": ['T OW2 SH IH0 HH AA1 R UW0'],
  "toshihiko": ['T OW2 SH IH0 HH IY1 K OW0'],
  "toshiki": ['T OW0 SH IY1 K IY0'],
  "toshimitsu": ['T OW0 SH IY0 M IY1 T S UW0'],
  "toshio": ['T OW0 SH IY1 OW0'],
  "toshiyuki": ['T OW2 SH IH0 Y UW1 K IY0'],
  "tosi": ['T OW1 S IY0'],
  "toso": ['T OW1 S OW0'],
  "toss": ['T AO1 S'],
  "tossed": ['T AO1 S T'],
  "tosses": ['T AO1 S IH0 Z'],
  "tossing": ['T AO1 S IH0 NG'],
  "tostado": ['T OW0 S T AA1 D OW0'],
  "toste": ['T OW1 S T'],
  "tostenson": ['T AA1 S T IH0 N S AH0 N'],
  "tosti": ['T AO1 S T IY0'],
  "tosto": ['T OW1 S T OW0'],
  "tot": ['T AA1 T'],
  "tota": ['T OW1 T AH0'],
  "total": ['T OW1 T AH0 L'],
  "total's": ['T OW1 T AH0 L Z'],
  "totaled": ['T OW1 T AH0 L D'],
  "totaling": ['T OW1 T AH0 L IH0 NG'],
  "totalitarian": ['T OW2 T AE2 L IH0 T EH1 R IY0 AH0 N'],
  "totalitarianism": ['T OW2 T AE2 L AH0 T EH1 R IY0 AH0 N IH2 Z AH0 M'],
  "totalitarians": ['T OW0 T AE2 L AH0 T EH1 R IY0 AH0 N Z'],
  "totality": ['T OW0 T AE1 L AH0 T IY0'],
  "totalled": ['T OW1 T AH0 L D'],
  "totalling": ['T OW1 T AH0 L IH0 NG'],
  "totally": ['T OW1 T AH0 L IY0'],
  "totals": ['T OW1 T AH0 L Z'],
  "totaro": ['T OW0 T AA1 R OW0'],
  "tote": ['T OW1 T'],
  "toted": ['T OW1 T IH0 D'],
  "totem": ['T OW1 T AH0 M'],
  "totems": ['T OW1 T AH0 M Z'],
  "totenberg": ['T OW1 T AH0 N B ER0 G'],
  "totes": ['T OW1 T S'],
  "toth": ['T AA1 TH'],
  "totherow": ['T AH1 DH ER0 OW0'],
  "toti": ['T OW1 T IY0'],
  "toting": ['T OW1 T IH0 NG'],
  "totino's": ['T AH0 T IY1 N OW0 Z'],
  "totman": ['T AA1 T M AH0 N'],
  "toto": ['T OW1 T OW0'],
  "tots": ['T AA1 T S'],
  "totten": ['T AA1 T AH0 N'],
  "tottenberg": ['T AA1 T AH0 N B ER0 G'],
  "tottenham": ['T AA1 T AH0 N AH0 M', 'T AA1 T AH0 N HH AE2 M'],
  "totter": ['T AA1 T ER0'],
  "tottering": ['T AA1 T ER0 IH0 NG'],
  "totton": ['T AA1 T AH0 N'],
  "totty": ['T AA1 T IY0'],
  "totzke": ['T AA1 T S K IY0'],
  "touareg": ['T UW1 ER0 AH0 G'],
  "touch": ['T AH1 CH'],
  "touchable": ['T AH1 CH AH0 B AH0 L'],
  "touchdown": ['T AH1 CH D AW2 N'],
  "touchdowns": ['T AH1 CH D AW2 N Z'],
  "touche": ['T UW1 SH'],
  "touched": ['T AH1 CH T'],
  "touches": ['T AH1 CH AH0 Z', 'T AH1 CH IH0 Z'],
  "touchet": ['T UW0 SH EH1 T'],
  "touchette": ['T UW2 SH EH1 T'],
  "touching": ['T AH1 CH IH0 NG'],
  "touchstone": ['T AH1 CH S T OW2 N'],
  "touchton": ['T AH1 CH T AH0 N'],
  "touchy": ['T AH1 CH IY0'],
  "toufexis": ['T UW2 F EH1 K S IH0 S'],
  "tougaloo": ['T UW1 G AH0 L UW2'],
  "tougas": ['T AH1 G AH0 Z'],
  "tough": ['T AH1 F'],
  "toughed": ['T AH1 F T'],
  "toughen": ['T AH1 F AH0 N'],
  "toughened": ['T AH1 F AH0 N D'],
  "toughening": ['T AH1 F AH0 N IH0 NG'],
  "toughens": ['T AH1 F AH0 N Z'],
  "tougher": ['T AH1 F ER0'],
  "toughest": ['T AH1 F AH0 S T'],
  "toughie": ['T AH1 F IY0'],
  "toughness": ['T AH1 F N AH0 S'],
  "toughs": ['T AH1 F S'],
  "touhey": ['T AH1 HH IY0'],
  "toulouse": ['T UW0 L UW1 Z'],
  "toupee": ['T UW2 P EY1'],
  "toupin": ['T UW1 P IH0 N'],
  "toups": ['T UW1 P S'],
  "tour": ['T UH1 R'],
  "tour's": ['T UH1 R Z'],
  "tourangeau": ['T UH1 R EY0 NG G OW0'],
  "toured": ['T UH1 R D'],
  "tourette": ['T ER0 EH1 T'],
  "tourette's": ['T ER0 EH1 T S'],
  "touretzky": ['T ER0 EH1 T S K IY0'],
  "tourigny": ['T UH1 R AY0 N IY0'],
  "touring": ['T UH1 R IH0 NG'],
  "tourism": ['T UH1 R IH2 Z AH0 M'],
  "tourist": ['T UH1 R AH0 S T', 'T UH1 R IH0 S T'],
  "tourists": ['T UH1 R AH0 S T S', 'T UH1 R IH0 S T S'],
  "tourmaline": ['T UH1 R M AH0 L IY2 N'],
  "tournament": ['T UH1 R N AH0 M AH0 N T'],
  "tournament's": ['T UH1 R N AH0 M AH0 N T S'],
  "tournaments": ['T ER1 N AH0 M AH0 N T S'],
  "tournedos": ['T UH1 R N AH0 D OW0'],
  "tourney": ['T ER1 N IY0'],
  "tourneys": ['T UW1 R N IY0 Z'],
  "tourniquet": ['T ER1 N IH2 K IH0 T'],
  "tournquist": ['T UW1 R N K W IH0 S T'],
  "touro": ['T UW1 R OW0'],
  "tours": ['T UH1 R Z', 'T AO1 R Z'],
  "tourtelot": ['T UH1 R T AH0 L AA0 T', 'T UH2 R T AH0 L OW1'],
  "tourville": ['T UH1 R V IH0 L'],
  "tousey": ['T AH1 S IY0'],
  "tousignant": ['T UW1 S IH0 G N AH0 N T'],
  "tousley": ['T AH1 S L IY0'],
  "toussaint": ['T UW0 S AE1 N'],
  "toussaud": ['T UW1 S AA2 D'],
  "toussaud's": ['T UW1 S AA2 D Z'],
  "toussie": ['T UW1 S IY0'],
  "tout": ['T AW1 T'],
  "toutant": ['T UW0 T AO1 N T'],
  "touted": ['T AW1 T IH0 D'],
  "touting": ['T AW1 T IH0 NG'],
  "touts": ['T AW1 T S'],
  "touvier": ['T UW2 V IY0 EY1'],
  "touvier's": ['T UW2 V IY0 EY1 Z'],
  "tov": ['T OW1 V'],
  "tovar": ['T OW0 V AA1 R'],
  "tovaz": ['T OW1 V AA2 Z'],
  "tovey": ['T OW1 V IY0'],
  "tovia": ['T OW1 V IY0 AH0', 'T OW1 V Y AH0'],
  "tow": ['T OW1'],
  "toward": ['T AH0 W AO1 R D', 'T AO1 R D'],
  "towards": ['T AH0 W AO1 R D Z', 'T AO1 R D Z'],
  "towbin": ['T OW1 B IH2 N'],
  "towboat": ['T OW1 B OW2 T'],
  "towe": ['T OW1'],
  "towed": ['T OW1 D'],
  "towel": ['T AW1 AH0 L', 'T AW1 L'],
  "toweling": ['T AW1 AH0 L IH0 NG', 'T AW1 L IH0 NG'],
  "towell": ['T AA1 W EH0 L'],
  "towels": ['T AW1 AH0 L Z', 'T AW1 L Z'],
  "tower": ['T AW1 ER0'],
  "tower's": ['T AW1 ER0 Z'],
  "towered": ['T AW1 ER0 D'],
  "towering": ['T AW1 ER0 IH0 NG', 'T AW1 R IH0 NG'],
  "towers": ['T AW1 ER0 Z'],
  "towers'": ['T AW1 ER0 Z'],
  "towery": ['T OW0 ER1 IY0'],
  "towey": ['T OW1 IY0'],
  "towhee": ['T OW1 HH IY2'],
  "towing": ['T OW1 IH0 NG'],
  "towle": ['T AW1 L'],
  "towle's": ['T AW1 L Z'],
  "towler": ['T OW1 L ER0'],
  "towles": ['T OW1 AH0 L Z'],
  "town": ['T AW1 N'],
  "town's": ['T AW1 N Z'],
  "towne": ['T AW1 N'],
  "towner": ['T AW1 N ER0'],
  "towners": ['T AW1 N ER0 Z'],
  "townes": ['T AW1 N Z'],
  "townhouse": ['T AW1 N HH AW2 S'],
  "townhouses": ['T AW1 N HH AW2 S IH0 Z'],
  "townie": ['T AW1 N IY0'],
  "townley": ['T AW1 N L IY0'],
  "towns": ['T AW1 N Z'],
  "townsel": ['T AW1 N S AH0 L'],
  "townsell": ['T AW1 N S AH0 L'],
  "townsend": ['T AW1 N Z AH0 N D'],
  "townsend's": ['T AW1 N Z AH0 N D Z'],
  "townsfolk": ['T AW1 N Z F OW2 K'],
  "townshend": ['T AW1 N SH EH2 N D'],
  "township": ['T AW1 N SH IH0 P'],
  "township's": ['T AW1 N SH IH2 P S'],
  "townships": ['T AW1 N SH IH0 P S'],
  "townsley": ['T AW1 N S L IY0'],
  "townsman": ['T AW1 N Z M AH0 N'],
  "townson": ['T AW1 N S AH0 N'],
  "townspeople": ['T AW1 N Z P IY2 P AH0 L'],
  "towry": ['T AO1 R IY0'],
  "tows": ['T OW1 Z'],
  "towsley": ['T OW1 S L IY0'],
  "towson": ['T OW1 S AH0 N'],
  "toxic": ['T AA1 K S IH0 K'],
  "toxicity": ['T AA0 K S IH1 S AH0 T IY0'],
  "toxicological": ['T AA2 K S AH0 K AH0 L AA1 JH IH0 K AH0 L'],
  "toxicologist": ['T AA2 K S IH0 K AA1 L AH0 JH IH0 S T'],
  "toxicologists": ['T AA2 K S IH0 K AA1 L AH0 JH IH0 S T S'],
  "toxicology": ['T AA2 K S IH0 K AA1 L AH0 JH IY0'],
  "toxics": ['T AA1 K S IH0 K S'],
  "toxin": ['T AA1 K S AH0 N'],
  "toxins": ['T AA1 K S AH0 N Z'],
  "toy": ['T OY1'],
  "toy's": ['T OY1 Z'],
  "toya": ['T OY1 AH0'],
  "toyama": ['T OW0 Y AA1 M AA2'],
  "toyboy": ['T OY1 B OY2'],
  "toye": ['T OY1'],
  "toyed": ['T OY1 D'],
  "toying": ['T OY1 IH0 NG'],
  "toyko": ['T OY1 K OW0'],
  "toymaker": ['T OY1 M EY2 K ER0'],
  "toymakers": ['T OY1 M EY2 K ER0 Z'],
  "toyo": ['T OW1 Y OW0'],
  "toyobo": ['T OW0 Y OW1 B OW0'],
  "toyoda": ['T OW0 Y OW1 D AH0'],
  "toyoo": ['T OY0 UW1'],
  "toyota": ['T OW0 Y OW1 T AH0'],
  "toyota's": ['T OW0 Y OW1 T AH0 Z'],
  "toyotas": ['T OY2 OW1 T AH0 Z'],
  "toys": ['T OY1 Z'],
  "toys'": ['T OY1 Z'],
  "toystore": ['T OY1 S T AO2 R'],
  "toystores": ['T OY1 S T AO2 R Z'],
  "tozer": ['T OW1 Z ER0'],
  "tozier": ['T OW1 Z IY0 ER0'],
  "tozzi": ['T AA1 Z IY0'],
  "traber": ['T R EY1 B ER0'],
  "trabert": ['T R AE1 B ER0 T'],
  "trabucco": ['T R AA0 B UW1 K OW0'],
  "trabue": ['T R AA1 B W EH0'],
  "trac": ['T R AE1 K'],
  "trace": ['T R EY1 S'],
  "traceabilities": ['T R EY2 S AH0 B IH1 L IH0 T IY0 Z'],
  "traceability": ['T R EY2 S AH0 B IH1 L IH0 T IY0'],
  "traceable": ['T R EY1 S AH0 B AH0 L'],
  "traced": ['T R EY1 S T'],
  "tracer": ['T R EY1 S ER0'],
  "tracers": ['T R EY1 S ER0 Z'],
  "traces": ['T R EY1 S AH0 Z', 'T R EY1 S IH0 Z'],
  "tracey": ['T R EY1 S IY0'],
  "trachea": ['T R EY1 K IY0 AH0'],
  "tracheal": ['T R EY1 K IY0 AH0 L'],
  "tracheophyte": ['T R EY1 K IY0 AH0 F AY0 T'],
  "tracheophytes": ['T R EY1 K IY0 AH0 F AY0 T S'],
  "trachsel": ['T R AE1 K S AH0 L'],
  "tracht": ['T R AE1 K T'],
  "trachtenberg": ['T R AE1 K T AH0 N B ER0 G'],
  "traci": ['T R EY1 S IY0'],
  "tracie": ['T R EY1 S IY0'],
  "tracinda": ['T R AH0 S IH1 N D AH0'],
  "tracinda's": ['T R AH0 S IH1 N D AH0 Z'],
  "tracing": ['T R EY1 S IH0 NG'],
  "track": ['T R AE1 K'],
  "track's": ['T R AE1 K S'],
  "trackage": ['T R AE1 K IH0 JH'],
  "trackball": ['T R AE1 K B AO2 L'],
  "trackballs": ['T R AE1 K B AO2 L Z'],
  "tracked": ['T R AE1 K T'],
  "tracker": ['T R AE1 K ER0'],
  "trackers": ['T R AE1 K ER0 Z'],
  "tracking": ['T R AE1 K IH0 NG'],
  "tracks": ['T R AE1 K S'],
  "tracor": ['T R EY1 S ER0', 'T R EH1 K ER0', 'T R EY1 K AO2 R', 'T R AE1 K AO2 R'],
  "tract": ['T R AE1 K T'],
  "tractable": ['T R AE1 K T AH0 B AH0 L'],
  "tractebel": ['T R AE1 K T AH0 B AH0 L'],
  "traction": ['T R AE1 K SH AH0 N'],
  "tractor": ['T R AE1 K T ER0'],
  "tractors": ['T R AE1 K T ER0 Z'],
  "tracts": ['T R AE1 K T S'],
  "tracy": ['T R EY1 S IY0'],
  "tracy's": ['T R EY1 S IY0 Z'],
  "tracz": ['T R AA1 CH'],
  "traczyk": ['T R AA1 CH IH2 K'],
  "tradable": ['T R EY1 D AH0 B AH0 L'],
  "trade": ['T R EY1 D'],
  "trade's": ['T R EY1 D Z'],
  "trade-off": ['T R EY1 D AO2 F'],
  "trade-offs": ['T R EY1 D AO2 F S'],
  "tradeable": ['T R EY1 D AH0 B AH0 L'],
  "traded": ['T R EY1 D IH0 D'],
  "trademark": ['T R EY1 D M AA2 R K'],
  "trademarked": ['T R EY1 D M AA2 R K T'],
  "trademarks": ['T R EY1 D M AA2 R K S'],
  "tradeoff": ['T R EY1 D AO2 F'],
  "tradeoffs": ['T R EY1 D AO2 F S'],
  "trader": ['T R EY1 D ER0'],
  "trader's": ['T R EY1 D ER0 Z'],
  "traders": ['T R EY1 D ER0 Z'],
  "traders'": ['T R EY1 D ER0 Z'],
  "trades": ['T R EY1 D Z'],
  "tradesmen": ['T R EY1 D Z M AH0 N'],
  "trading": ['T R EY1 D IH0 NG'],
  "trading's": ['T R EY1 D IH0 NG Z'],
  "tradings": ['T R EY1 D IH0 NG Z'],
  "tradition": ['T R AH0 D IH1 SH AH0 N'],
  "traditional": ['T R AH0 D IH1 SH AH0 N AH0 L'],
  "traditionalist": ['T R AH0 D IH1 SH AH0 N AH0 L IH0 S T'],
  "traditionalists": ['T R AH0 D IH1 SH N AH0 L AH0 S T S'],
  "traditionally": ['T R AH0 D IH1 SH AH0 N AH0 L IY0', 'T R AH0 D IH1 SH N AH0 L IY0'],
  "traditions": ['T R AH0 D IH1 SH AH0 N Z'],
  "traeger": ['T R EH1 G ER0'],
  "traer": ['T R EH1 R'],
  "trafalgar": ['T R AH0 F AE1 L G ER0'],
  "traffic": ['T R AE1 F IH0 K'],
  "traffic's": ['T R AE1 F IH0 K S'],
  "trafficker": ['T R AE1 F IH0 K ER0'],
  "traffickers": ['T R AE1 F IH0 K ER0 Z'],
  "trafficking": ['T R AE1 F IH0 K IH0 NG'],
  "trafford": ['T R AE1 F ER0 D'],
  "traficant": ['T R AE1 F IH0 K AH0 N T'],
  "traficante": ['T R AA0 F IY0 K AA1 N T IY0'],
  "traficants": ['T R AE1 F IH0 K AH0 N T S'],
  "trafillio": ['T R AH0 F IY1 L IY0 OW0'],
  "trafillio's": ['T R AH0 F IY1 L IY0 OW0 Z'],
  "trafton": ['T R AE1 F T AH0 N'],
  "tragedies": ['T R AE1 JH AH0 D IY0 Z'],
  "tragedy": ['T R AE1 JH AH0 D IY0'],
  "trager": ['T R EY1 G ER0'],
  "trageser": ['T R AE1 G IY0 Z ER0'],
  "tragic": ['T R AE1 JH IH0 K'],
  "tragically": ['T R AE1 JH IH0 K L IY0'],
  "tragicomic": ['T R AE2 JH IH0 K AA1 M IH0 K'],
  "tragos": ['T R AE1 G OW0 Z'],
  "trahan": ['T R AE1 HH AH0 N'],
  "trahern": ['T R AE1 HH ER0 N'],
  "trail": ['T R EY1 L'],
  "trailblazer": ['T R EY1 L B L EY2 Z ER0'],
  "trailblazers": ['T R EY1 L B L EY2 Z ER0 Z'],
  "trailed": ['T R EY1 L D'],
  "trailer": ['T R EY1 L ER0'],
  "trailers": ['T R EY1 L ER0 Z'],
  "trailhead": ['T R EY1 L HH EH2 D'],
  "trailing": ['T R EY1 L IH0 NG'],
  "trails": ['T R EY1 L Z'],
  "trails'": ['T R EY1 L Z'],
  "trailways": ['T R EY1 L W EY2 Z'],
  "trailways'": ['T R EY1 L W EY2 Z'],
  "train": ['T R EY1 N'],
  "train's": ['T R EY1 N Z'],
  "traina": ['T R EY1 N AH0'],
  "trainable": ['T R EY1 N AH0 B AH0 L'],
  "trained": ['T R EY1 N D'],
  "trainee": ['T R EY1 N IY1'],
  "trainees": ['T R EY1 N IY1 Z'],
  "trainer": ['T R EY1 N ER0'],
  "trainers": ['T R EY1 N ER0 Z'],
  "training": ['T R EY1 N IH0 NG'],
  "trainings": ['T R EY1 N IH0 NG Z'],
  "trainmen": ['T R EY1 N M AH0 N'],
  "trainor": ['T R EY1 N ER0'],
  "trains": ['T R EY1 N Z'],
  "traipse": ['T R EY1 P S'],
  "traipsing": ['T R EY1 P S IH0 NG'],
  "traister": ['T R EY1 S T ER0'],
  "trait": ['T R EY1 T'],
  "traitor": ['T R EY1 T ER0'],
  "traitorous": ['T R EY1 T ER0 AH0 S'],
  "traitors": ['T R EY1 T ER0 Z'],
  "traits": ['T R EY1 T S'],
  "trajan": ['T R EY1 JH AH0 N'],
  "trajan's": ['T R EY1 JH AH0 N Z'],
  "trajectory": ['T R AH0 JH EH1 K T ER0 IY0'],
  "trak": ['T R AE1 K'],
  "trakas": ['T R AA1 K AH0 Z'],
  "tram": ['T R AE1 M'],
  "tramble": ['T R AE1 M B AH0 L'],
  "tramco": ['T R AE1 M K OW0'],
  "tramel": ['T R AE1 M AH0 L'],
  "tramell": ['T R AA0 M EY1 L'],
  "tramiel": ['T R AE1 M IY0 AH0 L'],
  "trammel": ['T R AE1 M AH0 L'],
  "trammell": ['T R AE1 M AH0 L'],
  "tramontana": ['T R AA0 M OW0 N T AE1 N AH0'],
  "tramontano": ['T R AA0 M OW0 N T AA1 N OW0'],
  "tramonte": ['T R AA0 M OW1 N T IY0'],
  "tramontin": ['T R AH0 M AA1 N T IH0 N'],
  "tramp": ['T R AE1 M P'],
  "trampe": ['T R AE1 M P'],
  "tramped": ['T R AE1 M P T'],
  "tramping": ['T R AE1 M P IH0 NG'],
  "trample": ['T R AE1 M P AH0 L'],
  "trampled": ['T R AE1 M P AH0 L D'],
  "tramples": ['T R AE1 M P AH0 L Z'],
  "trampling": ['T R AE1 M P L IH0 NG'],
  "trampoline": ['T R AE2 M P AH0 L IY1 N'],
  "tramps": ['T R AE1 M P Z'],
  "trams": ['T R AE1 M Z'],
  "tran": ['T R AE1 N'],
  "tranberg": ['T R AE1 N B ER0 G'],
  "trance": ['T R AE1 N S'],
  "tranche": ['T R AE1 N CH'],
  "tranches": ['T R AE1 N CH EH0 Z'],
  "tranchina": ['T R AA0 N K IY1 N AH0'],
  "trane": ['T R EY1 N'],
  "trang": ['T R AE1 NG'],
  "trani": ['T R AA1 N IY0'],
  "tranquil": ['T R AE1 NG K W AH0 L', 'T R AE1 NG K W IH0 L'],
  "tranquility": ['T R AE0 NG K W IH1 L IH0 T IY0'],
  "tranquilize": ['T R AE1 NG K W AH0 L AY2 Z'],
  "tranquilizer": ['T R AE1 NG K W AH0 L AY2 Z ER0'],
  "tranquilizers": ['T R AE1 NG K W AH0 L AY2 Z ER0 Z'],
  "tranquilizing": ['T R AE1 NG K W AH0 L AY2 Z IH0 NG'],
  "tranquillity": ['T R AE0 NG K W IH1 L IH0 T IY0'],
  "trans": ['T R AE1 N Z'],
  "transact": ['T R AE0 N Z AE1 K T'],
  "transacted": ['T R AE0 N S AE1 K T IH0 D', 'T R AE0 N Z AE1 K T IH0 D'],
  "transaction": ['T R AE0 N Z AE1 K SH AH0 N'],
  "transaction's": ['T R AE0 N Z AE1 K SH AH0 N Z'],
  "transactions": ['T R AE0 N Z AE1 K SH AH0 N Z'],
  "transafrica": ['T R AE2 N Z AE1 F R IH0 K AH0'],
  "transalaska": ['T R AE2 N Z AH0 L AE1 S K AH0'],
  "transalta": ['T R AE2 N Z AO1 L T AH0'],
  "transamerica": ['T R AE2 N S AH0 M EH1 R IH0 K AH0'],
  "transamerica's": ['T R AE2 N Z AH0 M EH1 R IH0 K AH0 Z'],
  "transamerican": ['T R AE2 N Z AH0 M EH1 R IH0 K AH0 N'],
  "transamerican's": ['T R AE2 N Z AH0 M EH1 R IH0 K AH0 N Z'],
  "transatlantic": ['T R AE2 N Z AH0 T L AE1 N T IH0 K', 'T R AE2 N Z AH0 T L AE1 N IH0 K'],
  "transcanada": ['T R AE2 N Z K AE1 N AH0 T AH0'],
  "transcanada's": ['T R AE2 N Z K AE1 N AH0 D AH0 Z'],
  "transcapital": ['T R AE2 N Z K AE1 P IH0 T AH0 L'],
  "transcend": ['T R AE0 N S EH1 N D'],
  "transcended": ['T R AE0 N S EH1 N D IH0 D'],
  "transcendence": ['T R AE0 N S EH1 N D AH0 N S'],
  "transcendent": ['T R AE0 N S EH1 N D AH0 N T'],
  "transcendental": ['T R AE2 N S AH0 N D EH1 N T AH0 L', 'T R AE2 N S AH0 N D EH1 N AH0 L'],
  "transcending": ['T R AE0 N S EH1 N D IH0 NG'],
  "transcends": ['T R AE0 N S EH1 N D Z'],
  "transchannel": ['T R AH1 N S CH AE1 N AH0 L'],
  "transchannel's": ['T R AH1 N S CH AE1 N AH0 L Z'],
  "transcisco": ['T R AE2 N S IH1 S K OW0'],
  "transco": ['T R AE1 N S K OW0'],
  "transcon": ['T R AE1 N Z K AA0 N'],
  "transcontinental": ['T R AE2 N Z K AA2 N T IH0 N EH1 N T AH0 L', 'T R AE2 N Z K AA2 N IH0 N EH1 N T AH0 L', 'T R AE2 N Z K AA2 N T IH0 N EH1 N AH0 L', 'T R AE2 N Z K AA2 N IH0 N EH1 N AH0 L'],
  "transcontinental's": ['T R AE2 N Z K AA2 N T IH0 N EH1 N T AH0 L Z', 'T R AE2 N Z K AA2 N IH0 N EH1 N T AH0 L Z', 'T R AE2 N Z K AA2 N T IH0 N EH1 N AH0 L Z', 'T R AE2 N Z K AA2 N IH0 N EH1 N AH0 L Z'],
  "transcribe": ['T R AE0 N S K R AY1 B'],
  "transcribed": ['T R AE0 N S K R AY1 B D'],
  "transcriber": ['T R AE0 N S K R AY1 B ER0'],
  "transcribers": ['T R AE0 N S K R AY1 B ER0 Z'],
  "transcribes": ['T R AE0 N S K R AY1 B Z'],
  "transcribing": ['T R AE0 N S K R AY1 B IH0 NG'],
  "transcript": ['T R AE1 N S K R IH2 P T'],
  "transcription": ['T R AE2 N S K R IH1 P SH AH0 N'],
  "transcriptions": ['T R AE2 N S K R IH1 P SH AH0 N Z'],
  "transcripts": ['T R AE1 N S K R IH2 P T S'],
  "transducer": ['T R AE0 N S D UW1 S ER0'],
  "transducers": ['T R AE0 N S D UW1 S ER0 Z'],
  "transect": ['T R AE1 N S EH2 K T'],
  "transected": ['T R AE1 N S EH2 K T IH0 D'],
  "transection": ['T R AE1 N S EH2 K SH AH0 N'],
  "transfer": ['T R AE0 N S F ER1', 'T R AE1 N S F ER0'],
  "transferability": ['T R AE2 N S F ER0 AH0 B IH1 L IH0 T IY0'],
  "transferable": ['T R AE0 N S F ER1 AH0 B AH0 L'],
  "transfered": ['T R AE0 N S F ER1 D'],
  "transference": ['T R AE0 N S F ER1 AH0 N S'],
  "transfering": ['T R AE0 N S F ER1 IH0 NG'],
  "transferrable": ['T R AE2 N S F ER1 AH0 B AH0 L'],
  "transferred": ['T R AE0 N S F ER1 D', 'T R AE1 N S F ER0 D'],
  "transferring": ['T R AE0 N S F ER1 IH0 NG'],
  "transfers": ['T R AE0 N S F ER1 Z', 'T R AE1 N S F ER0 Z'],
  "transfix": ['T R AE0 N S F IH1 K S'],
  "transfixed": ['T R AE0 N S F IH1 K S T'],
  "transform": ['T R AE0 N S F AO1 R M', 'T R AE1 N S F AO0 R M'],
  "transformation": ['T R AE2 N S F ER0 M EY1 SH AH0 N'],
  "transformational": ['T R AE2 N S F ER0 M EY1 SH AH0 N AH0 L'],
  "transformations": ['T R AE2 N S F ER0 M EY1 SH AH0 N Z'],
  "transformative": ['T R AE2 N S F AO1 R M AA0 T IH2 V'],
  "transformed": ['T R AE0 N S F AO1 R M D'],
  "transformer": ['T R AE0 N S F AO1 R M ER0'],
  "transformers": ['T R AE0 N S F AO1 R M ER0 Z'],
  "transforming": ['T R AE0 N S F AO1 R M IH0 NG'],
  "transforms": ['T R AE0 N S F AO1 R M Z'],
  "transfuse": ['T R AE0 N S F Y UW1 Z'],
  "transfused": ['T R AE0 N S F Y UW1 Z D'],
  "transfusion": ['T R AE0 N S F Y UW1 ZH AH0 N'],
  "transfusions": ['T R AE0 N S F Y UW1 ZH AH0 N Z'],
  "transgender": ['T R AE1 N Z JH EH1 N D ER0'],
  "transgenic": ['T R AE2 N Z JH EH1 N IH0 K'],
  "transgress": ['T R AE0 N Z G R EH1 S'],
  "transgressed": ['T R AE0 N Z G R EH1 S T'],
  "transgresses": ['T R AE0 N Z G R EH1 S IH0 S'],
  "transgressing": ['T R AE0 N Z G R EH1 S IH0 NG'],
  "transgression": ['T R AE0 N Z G R EH1 SH AH0 N'],
  "transgressions": ['T R AE0 N Z G R EH1 SH AH0 N Z'],
  "transgressor": ['T R AE0 N Z G R EH1 S ER0'],
  "transience": ['T R AE1 N Z IY0 AH0 N S'],
  "transient": ['T R AE1 N ZH AH0 N T'],
  "transients": ['T R AE1 N Z IY0 AH0 N T S'],
  "transillumination": ['T R AE2 N Z AH0 L UW2 M AH0 N EY1 SH AH0 N'],
  "transimage": ['T R AE2 N Z IH1 M IH0 JH'],
  "transistor": ['T R AE0 N Z IH1 S T ER0'],
  "transistors": ['T R AE0 N Z IH1 S T ER0 Z'],
  "transit": ['T R AE1 N Z IH0 T'],
  "transited": ['T R AE1 N Z IH0 T IH0 D'],
  "transiting": ['T R AE1 N Z IH0 T IH0 NG'],
  "transition": ['T R AE0 N Z IH1 SH AH0 N'],
  "transitional": ['T R AE0 N S IH1 SH AH0 N AH0 L', 'T R AE0 N Z IH1 SH AH0 N AH0 L'],
  "transitioning": ['T R AE0 N Z IH1 SH AH0 N IH0 NG'],
  "transitions": ['T R AE0 N Z IH1 SH AH0 N Z'],
  "transitory": ['T R AE1 N Z AH0 T AO2 R IY0'],
  "transits": ['T R AE1 N Z IH0 T S'],
  "transkei": ['T R AE1 N Z K EY2'],
  "translate": ['T R AE0 N Z L EY1 T', 'T R AE0 N S L EY1 T'],
  "translated": ['T R AE0 N Z L EY1 T IH0 D', 'T R AE0 N S L EY1 T IH0 D'],
  "translates": ['T R AE0 N Z L EY1 T S', 'T R AE1 N S L EY2 T S'],
  "translating": ['T R AE0 N Z L EY1 T IH0 NG', 'T R AE1 N S L EY2 T IH0 NG'],
  "translation": ['T R AE0 N Z L EY1 SH AH0 N', 'T R AE0 N S L EY1 SH AH0 N'],
  "translations": ['T R AE0 N Z L EY1 SH AH0 N Z', 'T R AE0 N S L EY1 SH AH0 N Z'],
  "translator": ['T R AE0 N S L EY1 T ER0', 'T R AE0 N Z L EY1 T ER0'],
  "translators": ['T R AE0 N S L EY1 T ER0 Z', 'T R AE0 N Z L EY1 T ER0 Z'],
  "translogic": ['T R AE2 N Z L AA1 JH IH0 K'],
  "translucent": ['T R AE0 N S L UW1 S AH0 N T'],
  "transmark": ['T R AE1 N Z M AA2 R K'],
  "transmedia": ['T R AE2 N Z M IY1 D IY0 AH0'],
  "transmission": ['T R AE0 N S M IH1 SH AH0 N', 'T R AE0 N Z M IH1 SH AH0 N'],
  "transmissions": ['T R AE0 N Z M IH1 SH AH0 N Z'],
  "transmit": ['T R AE0 N Z M IH1 T'],
  "transmits": ['T R AE0 N Z M IH1 T S'],
  "transmittable": ['T R AE0 N Z M IH1 T AH0 B AH0 L'],
  "transmittal": ['T R AE0 N S M IH1 T AH0 L'],
  "transmitted": ['T R AE0 N S M IH1 T IH0 D', 'T R AE0 N Z M IH1 T AH0 D'],
  "transmitter": ['T R AE0 N S M IH1 T ER0'],
  "transmitter's": ['T R AE0 N S M IH1 T ER0 Z'],
  "transmitters": ['T R AE0 N S M IH1 T ER0 Z'],
  "transmitting": ['T R AE0 N S M IH1 T IH0 NG'],
  "transnational": ['T R AE0 N S N AE1 SH AH0 N AH0 L'],
  "transoceanic": ['T R AE2 N Z OW0 SH IY0 AE1 N IH0 K'],
  "transohio": ['T R AE2 N Z OW0 HH AY1 OW0'],
  "transom": ['T R AE1 N S AH0 M'],
  "transoms": ['T R AE1 N S AH0 M Z'],
  "transou": ['T R AE1 N Z UW2'],
  "transpac": ['T R AE1 N Z P AE2 K'],
  "transpacific": ['T R AE2 N S P AH0 S IH1 F IH0 K'],
  "transparencies": ['T R AE0 N S P EH1 R AH0 N S IY0 Z'],
  "transparency": ['T R AE0 N S P EH1 R AH0 N S IY0'],
  "transparent": ['T R AE0 N S P EH1 R AH0 N T'],
  "transparently": ['T R AE0 N S P EH1 R AH0 N T L IY0'],
  "transpark": ['T R AE1 N S P AA1 R K'],
  "transpire": ['T R AE0 N S P AY1 ER0'],
  "transpired": ['T R AE0 N S P AY1 ER0 D'],
  "transpires": ['T R AE0 N S P AY1 ER0 Z'],
  "transpiring": ['T R AE0 N S P AY1 ER0 IH0 NG'],
  "transplant": ['T R AE0 N S P L AE1 N T'],
  "transplantation": ['T R AE2 N Z P L AE0 N T EY1 SH AH0 N'],
  "transplanted": ['T R AE0 N S P L AE1 N T IH0 D'],
  "transplanting": ['T R AE0 N S P L AE1 N T IH0 NG'],
  "transplants": ['T R AE0 N S P L AE1 N T S'],
  "transponder": ['T R AE0 N S P AA1 N D ER0'],
  "transponders": ['T R AE0 N S P AA1 N D ER0 Z'],
  "transport": ['T R AE0 N S P AO1 R T', 'T R AE1 N S P AO0 R T'],
  "transportable": ['T R AE0 N S P AO1 R T AH0 B AH0 L'],
  "transportation": ['T R AE2 N S P ER0 T EY1 SH AH0 N'],
  "transportation's": ['T R AE2 N S P ER0 T EY1 SH AH0 N Z'],
  "transported": ['T R AE0 N S P AO1 R T IH0 D'],
  "transporter": ['T R AE0 N S P AO1 R T ER0'],
  "transporters": ['T R AE0 N S P AO1 R T ER0 Z'],
  "transporting": ['T R AE0 N S P AO1 R T IH0 NG'],
  "transports": ['T R AE0 N S P AO1 R T S', 'T R AE1 N S P AO0 R T S'],
  "transpose": ['T R AE0 N S P OW1 Z'],
  "transposed": ['T R AE0 N S P OW1 Z D'],
  "transracial": ['T R AE2 N Z R EY1 SH AH0 L'],
  "transrapid": ['T R AE1 N Z R AE1 P IH0 D'],
  "transsexual": ['T R AE0 N S EH1 K SH Y UW0 AH0 L'],
  "transsexuals": ['T R AE0 N S EH1 K SH Y UW0 AH0 L Z'],
  "transtar": ['T R AE0 N S T AA1 R'],
  "transtechnology": ['T R AE2 N Z T AH0 K N AA1 L AH0 JH IY0'],
  "transtector": ['T R AE2 N Z T EH1 K T ER0'],
  "transue": ['T R AE1 N Z UW0'],
  "transvaal": ['T R AE0 N Z V AA1 L'],
  "transverse": ['T R AE0 N Z V ER1 S'],
  "transvestite": ['T R AE0 N Z V EH1 S T AY0 T'],
  "transvestites": ['T R AE0 N Z V EH1 S T AY0 T S'],
  "transway": ['T R AE1 N Z W EY2'],
  "transwestern": ['T R AE2 N Z W EH1 S T ER0 N'],
  "transworld": ['T R AE0 N S W ER1 L D'],
  "transylvania": ['T R AE2 N Z IY0 L V EY1 N IY0 AH0', 'T R AE2 N S IH0 L V EY1 N Y AH0'],
  "trant": ['T R AE1 N T'],
  "tranter": ['T R AE1 N T ER0'],
  "trantham": ['T R AE1 N TH AH0 M'],
  "tranum": ['T R AE1 N AH0 M'],
  "tranzonic": ['T R AE0 N Z AA1 N IH0 K'],
  "trap": ['T R AE1 P'],
  "trapani": ['T R AA0 P AA1 N IY0'],
  "trapasso": ['T R AA0 P AA1 S OW0'],
  "trapelo": ['T R AH0 P EH1 L OW0'],
  "trapeze": ['T R AH0 P IY1 Z'],
  "traphagen": ['T R AE1 F AH0 G AH0 N'],
  "trapnell": ['T R AE1 P N AH0 L'],
  "trapp": ['T R AE1 P'],
  "trappe": ['T R AE1 P'],
  "trapped": ['T R AE1 P T'],
  "trapper": ['T R AE1 P ER0'],
  "trappers": ['T R AE1 P ER0 Z'],
  "trapping": ['T R AE1 P IH0 NG'],
  "trappings": ['T R AE1 P IH0 NG Z'],
  "trappist": ['T R AE1 P IH0 S T'],
  "traps": ['T R AE1 P S'],
  "trash": ['T R AE1 SH'],
  "trashed": ['T R AE1 SH T'],
  "trashes": ['T R AE1 SH IH0 Z'],
  "trashing": ['T R AE1 SH IH0 NG'],
  "trashy": ['T R AE1 SH IY0'],
  "trask": ['T R AE1 S K'],
  "traub": ['T R AO1 B'],
  "traudt": ['T R AO1 D T'],
  "trauger": ['T R AW1 G ER0'],
  "traugh": ['T R AO1'],
  "traughber": ['T R AO1 B ER0'],
  "traugott": ['T R AW1 G AH0 T'],
  "traum": ['T R AO1 M'],
  "trauma": ['T R AO1 M AH0'],
  "traumas": ['T R AO1 M AH0 Z'],
  "traumatic": ['T R AO0 M AE1 T IH0 K'],
  "traumatize": ['T R AO1 M AH0 T AY2 Z'],
  "traumatized": ['T R AO1 M AH0 T AY2 Z D'],
  "trausch": ['T R AW1 SH'],
  "traut": ['T R AO1 T'],
  "trauth": ['T R AO1 TH'],
  "trautman": ['T R AW1 T M AH0 N'],
  "trautmann": ['T R AW1 T M AH0 N'],
  "trautner": ['T R AW1 T N ER0'],
  "trautwein": ['T R AW1 T W AY2 N'],
  "travaglini": ['T R AA0 V AA0 G L IY1 N IY0'],
  "travail": ['T R AH0 V EY1 L'],
  "travails": ['T R AH0 V EY1 L Z'],
  "travel": ['T R AE1 V AH0 L'],
  "travelday": ['T R AE1 V AH0 L D EY2'],
  "traveldays": ['T R AE1 V AH0 L D EY2 Z'],
  "traveled": ['T R AE1 V AH0 L D'],
  "traveler": ['T R AE1 V AH0 L ER0', 'T R AE1 V L ER0'],
  "traveler's": ['T R AE1 V AH0 L ER0 Z'],
  "travelers": ['T R AE1 V AH0 L ER0 Z', 'T R AE1 V L ER0 Z'],
  "travelers'": ['T R AE1 V AH0 L ER0 Z'],
  "travelgate": ['T R AE1 V AH0 L G EY2 T'],
  "traveling": ['T R AE1 V AH0 L IH0 NG', 'T R AE1 V L IH0 NG'],
  "travelled": ['T R AE1 V AH0 L D'],
  "traveller": ['T R AE1 V AH0 L ER0'],
  "travellers": ['T R AE1 V AH0 L ER0 Z'],
  "travelling": ['T R AE1 V AH0 L IH0 NG', 'T R AE1 V L IH0 NG'],
  "travelocity": ['T R AE1 V AH0 L AA1 S AH0 T IY0'],
  "travelodge": ['T R AE1 V AH0 L AA1 JH'],
  "travelogue": ['T R AE1 V AH0 L AO2 G'],
  "travels": ['T R AE1 V AH0 L Z'],
  "travelstead": ['T R AE1 V AH0 L S T EH2 D'],
  "travenol": ['T R AE1 V AH0 N AH0 L'],
  "traver": ['T R EY1 V ER0'],
  "travers": ['T R AE1 V ER0 Z'],
  "traverse": ['T R AE1 V ER0 S', 'T R AH0 V ER1 S'],
  "traversed": ['T R AE1 V ER0 S T', 'T R AH0 V ER1 S T'],
  "traversing": ['T R AH0 V ER1 S IH0 NG'],
  "traverso": ['T R AA0 V EH1 R S OW0'],
  "travesties": ['T R AE1 V AH0 S T IY0 Z'],
  "travesty": ['T R AE1 V AH0 S T IY0'],
  "traviata": ['T R AA0 V IY0 AA1 T AH0'],
  "travieso": ['T R AA0 V IY1 S OW0'],
  "travis": ['T R AE1 V IH0 S'],
  "travisano": ['T R AE2 V IH0 S AA1 N OW0'],
  "travnik": ['T R AE1 V N IH0 K'],
  "travolta": ['T R AH0 V OW1 L T AH0'],
  "travolta's": ['T R AH0 V OW1 L T AH0 Z'],
  "travoltas": ['T R AH0 V OW1 L T AH0 Z'],
  "traweek": ['T R AO1 IY2 K'],
  "trawick": ['T R AO1 IH0 K'],
  "trawler": ['T R AO1 L ER0'],
  "trawlers": ['T R AO1 L ER0 Z'],
  "trax": ['T R AE1 K S'],
  "traxler": ['T R AE1 K S L ER0'],
  "tray": ['T R EY1'],
  "trayer": ['T R EY1 ER0'],
  "traylor": ['T R EY1 L ER0'],
  "traynham": ['T R EY1 N HH AH0 M'],
  "traynor": ['T R EY1 N ER0'],
  "trays": ['T R EY1 Z'],
  "traywick": ['T R EY1 W IH2 K'],
  "trbovich": ['T ER0 B AA1 V IH0 CH'],
  "tre": ['T R EY1'],
  "treacherous": ['T R EH1 CH ER0 AH0 S'],
  "treachery": ['T R EH1 CH ER0 IY0'],
  "treacy": ['T R EY1 S IY0'],
  "tread": ['T R EH1 D'],
  "treadaway": ['T R EH1 D AH0 W EY2'],
  "treading": ['T R EH1 D IH0 NG'],
  "treadmill": ['T R EH1 D M IH2 L'],
  "treadmills": ['T R EH1 D M IH2 L Z'],
  "treads": ['T R EH1 D Z'],
  "treadstone": ['T R EH1 D S T OW2 N'],
  "treadway": ['T R EH1 D W EY2'],
  "treadwell": ['T R EH1 D W EH2 L'],
  "treadwheel": ['T R EH1 D W IY2 L'],
  "treanor": ['T R IY1 N ER0'],
  "trease": ['T R IY1 Z'],
  "treason": ['T R IY1 Z AH0 N'],
  "treasonous": ['T R IY1 Z AH0 N AH2 S'],
  "treasons": ['T R IY1 Z AH0 N S'],
  "treaster": ['T R IY1 S T ER0'],
  "treasure": ['T R EH1 ZH ER0'],
  "treasured": ['T R EH1 ZH ER0 D'],
  "treasurer": ['T R EH1 ZH ER0 ER0'],
  "treasurer's": ['T R EH1 ZH ER0 ER0 Z'],
  "treasurers": ['T R EH1 ZH ER0 ER0 Z'],
  "treasures": ['T R EH1 ZH ER0 Z'],
  "treasuries": ['T R EH1 ZH ER0 IY0 Z'],
  "treasury": ['T R EH1 ZH ER0 IY0'],
  "treasury's": ['T R EH1 ZH ER0 IY0 Z'],
  "treasurys": ['T R EH1 ZH ER0 IY0 Z'],
  "treat": ['T R IY1 T'],
  "treatable": ['T R IY1 T AH0 B AH0 L'],
  "treated": ['T R IY1 T IH0 D'],
  "treater": ['T R IY1 T ER0'],
  "treaters": ['T R IY1 T ER0 Z'],
  "treaties": ['T R IY1 T IY0 Z'],
  "treating": ['T R IY1 T IH0 NG'],
  "treatise": ['T R IY1 T AH0 S'],
  "treatises": ['T R IY1 T AH0 S AH0 Z'],
  "treatment": ['T R IY1 T M AH0 N T'],
  "treatments": ['T R IY1 T M AH0 N T S'],
  "treats": ['T R IY1 T S'],
  "treaty": ['T R IY1 T IY0'],
  "treaty's": ['T R IY1 T IY0 Z'],
  "trebilcock": ['T R IH0 B IH1 L K AH0 K'],
  "treble": ['T R EH1 B AH0 L'],
  "trebled": ['T R EH1 B AH0 L D'],
  "treblinka": ['T R EH0 B L IH1 NG K ER0', 'T R EH0 B L IH1 NG K AH0'],
  "trecker": ['T R EH1 K ER0'],
  "treder": ['T R IY1 D ER0'],
  "tredway": ['T R EH1 D W EY2'],
  "tree": ['T R IY1'],
  "treece": ['T R IY1 S'],
  "treeless": ['T R IY1 L AH0 S'],
  "treen": ['T R IY1 N'],
  "trees": ['T R IY1 Z'],
  "treese": ['T R IY1 Z'],
  "treesh": ['T R IY1 SH'],
  "treesweet": ['T R IY1 S W IY2 T'],
  "treetop": ['T R IY1 T AO2 P'],
  "treetops": ['T R IY1 T AO2 P S'],
  "trefethen": ['T R EH1 F IH0 TH AH0 N'],
  "trefgarne": ['T R EH1 F G AA0 R N'],
  "trefry": ['T R EH1 F R IY0'],
  "trefz": ['T R EH1 F Z'],
  "treglia": ['T R EH1 G L IY0 AH0'],
  "trego": ['T R EH1 G OW0'],
  "tregoning": ['T R EH1 G AH0 N IH0 NG'],
  "tregre": ['T R EH1 G ER0'],
  "tregurtha": ['T R EH0 G ER1 TH AH0'],
  "treharne": ['T R EH1 HH AA0 R N'],
  "treiber": ['T R AY1 B ER0'],
  "treichel": ['T R AY1 K AH0'],
  "treichler": ['T R AY1 K AH0 L ER0', 'T R AY1 K L ER0'],
  "treinen": ['T R AY1 N AH0 N'],
  "trejo": ['T R EY1 Y OW0'],
  "trek": ['T R EH1 K'],
  "trekked": ['T R EH1 K T'],
  "trekking": ['T R EH1 K IH0 NG'],
  "treks": ['T R EH1 K S'],
  "trela": ['T R EH1 L AH0'],
  "trella": ['T R EH1 L AH0'],
  "trelleborg": ['T R EH1 L AH0 B AO0 R G'],
  "trelleborg's": ['T R EH1 L AH0 B AO0 R G Z'],
  "trellis": ['T R EH1 L AH0 S'],
  "treloar": ['T R EH1 L AO0 R'],
  "tremain": ['T R EH1 M AY0 N'],
  "tremaine": ['T R IH0 M EY1 N'],
  "tremayne": ['T R EH1 M EY0 N'],
  "trembath": ['T R EH1 M B AH0 TH'],
  "tremblay": ['T R EH1 M B L EY0'],
  "tremble": ['T R EH1 M B AH0 L'],
  "trembled": ['T R EH1 M B AH0 L D'],
  "trembley": ['T R EH1 M B L IY0'],
  "trembling": ['T R EH1 M B AH0 L IH0 NG', 'T R EH1 M B L IH0 NG'],
  "trembly": ['T R EH1 M B L IY0'],
  "tremel": ['T R EH1 M AH0 L'],
  "tremendous": ['T R AH0 M EH1 N D AH0 S', 'T R IH0 M EH1 N D AH0 S'],
  "tremendously": ['T R AH0 M EH1 N D AH0 S L IY0', 'T R IH0 M EH1 N D AH0 S L IY0'],
  "tremens": ['T R EH1 M AH0 N Z'],
  "treml": ['T R EH1 M AH0 L'],
  "tremmel": ['T R EH1 M AH0 L'],
  "tremont": ['T R EH1 M AH0 N T'],
  "tremor": ['T R EH1 M ER0'],
  "tremors": ['T R EH1 M ER0 Z'],
  "tremper": ['T R EH1 M P ER0'],
  "tremulous": ['T R EH1 M Y AH0 L AH0 S'],
  "tremulously": ['T R EH1 M Y AH0 L AH0 S L IY0'],
  "trenary": ['T R EH1 N EH0 R IY0'],
  "trench": ['T R EH1 N CH'],
  "trenchant": ['T R EH1 N CH AH0 N T'],
  "trenchard": ['T R EH1 NG K ER0 D'],
  "trencher": ['T R EH1 N CH ER0'],
  "trenches": ['T R EH1 N CH IH0 Z'],
  "trend": ['T R EH1 N D'],
  "trended": ['T R EH1 N D IH0 D'],
  "trendier": ['T R EH1 N D Y ER0', 'T R EH1 N D IY0 ER0'],
  "trendiest": ['T R EH0 N D IY1 S T', 'T R EH1 N D IY0 AH0 S T'],
  "trending": ['T R EH1 N D IH0 NG'],
  "trendless": ['T R EH1 N D L AH0 S'],
  "trendline": ['T R EH1 N D L AY2 N'],
  "trends": ['T R EH1 N D Z', 'T R EH1 N Z'],
  "trendsetter": ['T R EH1 N D S EH2 T ER0'],
  "trendy": ['T R EH1 N D IY0'],
  "trenholm": ['T R EH1 N HH OW2 L M'],
  "trenkamp": ['T R EH1 N K AE2 M P'],
  "trenkle": ['T R EH1 NG K AH0 L'],
  "trent": ['T R EH1 N T'],
  "trent's": ['T R EH1 N T S'],
  "trente-et-quarante": ['T R EY1 N T EY0 K W AA2 R EH1 N T EY0'],
  "trentham": ['T R EH1 N TH AH0 M'],
  "trentman": ['T R EH1 N T M AH0 N'],
  "trenton": ['T R EH1 N T AH0 N'],
  "trenton's": ['T R EH1 N T AH0 N Z'],
  "treon": ['T R IY1 AH0 N'],
  "trepagnier": ['T R EH1 P AH0 G N IY2 ER0'],
  "trepanier": ['T R EH1 P AH0 N IY2 ER0'],
  "trepidation": ['T R EH2 P IH0 D EY1 SH AH0 N'],
  "treppel": ['T R EH1 P AH0 L'],
  "treptow": ['T R EH1 P T OW0'],
  "tresch": ['T R EH1 SH'],
  "trescott": ['T R EH1 S K AH0 T'],
  "trespass": ['T R EH1 S P AE2 S', 'T R EH1 S P AH0 S'],
  "trespassing": ['T R EH1 S P AE2 S IH0 NG', 'T R EH1 S P AH0 S IH0 NG'],
  "tress": ['T R EH1 S'],
  "tressel": ['T R EH1 S AH0 L'],
  "tresses": ['T R EH1 S IH0 Z'],
  "tressler": ['T R EH1 S L ER0'],
  "trest": ['T R EH1 S T'],
  "trester": ['T R EH1 S T ER0'],
  "trestle": ['T R EH1 S AH0 L'],
  "trethewey": ['T R EH1 TH Y UW0 IY0'],
  "tretinoin": ['T R EH1 T IH0 N OY2 N'],
  "trettel": ['T R EH1 T AH0 L'],
  "tretter": ['T R EH1 T ER0'],
  "trettin": ['T R EH1 T IH0 N'],
  "treu": ['T R UW1'],
  "treuhand": ['T R UW1 HH AE2 N D'],
  "treuhandanstalt": ['T R UW2 HH AE1 N D AH0 N S T AA2 L T'],
  "treurnicht": ['T R UW1 R N IH0 K T'],
  "trevathan": ['T R EH1 V AH0 TH AH0 N'],
  "trevelyan": ['T R AH0 V EH1 L Y AH0 N'],
  "trevino": ['T R AH0 V IY1 N OW0'],
  "treviso": ['T R EH0 V IY1 S OW0'],
  "trevizo": ['T R EH0 V IY1 Z OW0'],
  "trevor": ['T R EH1 V ER0'],
  "trew": ['T R UW1'],
  "trexler": ['T R EH1 K S L ER0'],
  "trey": ['T R EY1'],
  "treybig": ['T R EY1 B IH0 G'],
  "trezise": ['T R EH1 Z AY0 Z'],
  "trezza": ['T R EH1 Z AH0'],
  "tri": ['T R AY1'],
  "tri-state": ['T R AY1 S T EY1 T'],
  "triad": ['T R AY1 AE2 D'],
  "triad's": ['T R AY1 AE2 D Z'],
  "triads": ['T R AY1 AE2 D Z'],
  "triage": ['T R AY1 IH0 JH'],
  "trial": ['T R AY1 AH0 L', 'T R AY1 L'],
  "trial's": ['T R AY1 AH0 L Z'],
  "trials": ['T R AY1 AH0 L Z', 'T R AY1 L Z'],
  "triana": ['T R IY0 AE1 N AH0'],
  "triangle": ['T R AY1 AE2 NG G AH0 L'],
  "triangle's": ['T R AY1 AE2 NG G AH0 L Z'],
  "triangles": ['T R AY1 AE2 NG G AH0 L Z'],
  "triangular": ['T R AY0 AE1 NG G Y AH0 L ER0'],
  "triangulation": ['T R AY0 AE2 NG G Y UW0 L EY1 SH AH0 N'],
  "triano": ['T R IY0 AA1 N OW0'],
  "triarc": ['T R AY1 AA2 R K'],
  "triassic": ['T R AY2 AE1 S IH2 K'],
  "triathlon": ['T R AY2 AE1 TH L AH0 N'],
  "trib": ['T R IH1 B'],
  "tribal": ['T R AY1 B AH0 L'],
  "tribalism": ['T R AY1 B AH0 L IH0 Z M', 'T R AY1 B AH0 L IH0 Z AH0 M'],
  "tribasa": ['T R AY1 B AE1 S AH0'],
  "tribbett": ['T R IH1 B IH0 T'],
  "tribbey": ['T R IH1 B IY0'],
  "tribble": ['T R IH1 B AH0 L'],
  "tribbles": ['T R IH1 B AH0 L Z'],
  "tribby": ['T R IH1 B IY0'],
  "tribe": ['T R AY1 B'],
  "tribe's": ['T R AY1 B Z'],
  "tribeca": ['T R IH0 B EH1 K AH0'],
  "tribes": ['T R AY1 B Z'],
  "tribesman": ['T R AY1 B Z M AE0 N'],
  "tribesmen": ['T R AY1 B Z M IH0 N'],
  "trible": ['T R IH1 B AH0 L'],
  "triborough": ['T R AY1 B ER0 OW0'],
  "tribulation": ['T R IH2 B Y AH0 L EY1 SH AH0 N'],
  "tribulations": ['T R IH2 B Y AH0 L EY1 SH AH0 N Z'],
  "tribull": ['T R IH1 B AH0 L'],
  "tribunal": ['T R AH0 B Y UW1 N AH0 L'],
  "tribunals": ['T R AY2 B Y UW1 N AH0 L Z'],
  "tribune": ['T R IH1 B Y UW0 N'],
  "tribune's": ['T R IH1 B Y UW0 N Z'],
  "tributaries": ['T R IH1 B Y AH0 T EH2 R IY0 Z'],
  "tributary": ['T R IH1 B Y AH0 T EH2 R IY0'],
  "tribute": ['T R IH1 B Y UW0 T'],
  "tribute's": ['T R IH1 B Y UW0 T S'],
  "tributes": ['T R IH1 B Y UW0 T S'],
  "tricarico": ['T R IY0 K AA0 R IY1 K OW0'],
  "trice": ['T R AY1 S'],
  "tricentrol": ['T R AY2 S EH1 N T R AA0 L'],
  "tricentrol's": ['T R AY2 S EH1 N T R AA0 L Z'],
  "triceps": ['T R AY1 S EH2 P S'],
  "triceratops": ['T R AY2 S EH1 R AH0 T AO2 P S'],
  "triche": ['T R IH1 CH'],
  "trichet": ['T R IH1 CH IH0 T'],
  "tricia": ['T R IH1 SH AH0'],
  "tricia's": ['T R IH1 SH AH0 Z'],
  "tricil": ['T R IH1 S IH0 L'],
  "trick": ['T R IH1 K'],
  "tricked": ['T R IH1 K T'],
  "trickel": ['T R IH1 K AH0 L'],
  "trickery": ['T R IH1 K ER0 IY0'],
  "trickett": ['T R IH1 K IH0 T'],
  "trickey": ['T R IH1 K IY0'],
  "trickier": ['T R IH1 K IY0 ER0'],
  "trickiest": ['T R IH1 K IY0 AH0 S T'],
  "tricking": ['T R IH1 K IH0 NG'],
  "trickle": ['T R IH1 K AH0 L'],
  "trickled": ['T R IH1 K AH0 L D'],
  "trickles": ['T R IH1 K AH0 L Z'],
  "trickling": ['T R IH1 K L IH0 NG'],
  "tricks": ['T R IH1 K S'],
  "trickster": ['T R IH1 K S T ER0'],
  "tricksters": ['T R IH1 K S T ER0 Z'],
  "tricky": ['T R IH1 K IY0'],
  "trico": ['T R IY1 K OW0'],
  "tricuspid": ['T R AY0 K AH1 S P AH0 D'],
  "tricycle": ['T R IH1 S IH0 K AH0 L'],
  "trident": ['T R AY1 D AH0 N T'],
  "tridex": ['T R IH1 D EH2 K S'],
  "trie": ['T R AY1'],
  "tried": ['T R AY1 D'],
  "triennial": ['T R AY0 EH1 N IY0 AH0 L'],
  "trier": ['T R AY1 ER0'],
  "trierweiler": ['T R IH1 R W AY0 L ER0'],
  "tries": ['T R AY1 Z'],
  "trieste": ['T R IY1 S T'],
  "trieu": ['T R UW1'],
  "trifari": ['T R IH0 F AA1 R IY0'],
  "trifecta": ['T R AY0 F EH1 K T AH0'],
  "trifle": ['T R AY1 F AH0 L'],
  "trifles": ['T R AY1 F AH0 L Z'],
  "trifling": ['T R AY1 F L IH0 NG'],
  "trig": ['T R IH1 G'],
  "trigg": ['T R IH1 G'],
  "trigger": ['T R IH1 G ER0'],
  "triggered": ['T R IH1 G ER0 D'],
  "triggering": ['T R IH1 G ER0 IH0 NG'],
  "triggers": ['T R IH1 G ER0 Z'],
  "triggs": ['T R IH1 G Z'],
  "triglyceride": ['T R AY0 G L IH1 S ER0 AY2 D'],
  "triglycerides": ['T R AY0 G L IH1 S ER0 AY2 D Z'],
  "trigo": ['T R IY1 G OW0'],
  "trilateral": ['T R AY0 L AE1 T ER0 AH0 L'],
  "trilby": ['T R IH1 L B IY0'],
  "trill": ['T R IH1 L'],
  "trillin": ['T R IH1 L IH0 N'],
  "trilling": ['T R IH1 L IH0 NG'],
  "trillion": ['T R IH1 L Y AH0 N'],
  "trillions": ['T R IH1 L Y AH0 N Z'],
  "trillo": ['T R IH1 L OW0'],
  "trilogy": ['T R IH1 L AH0 JH IY0'],
  "trilon": ['T R IH1 L AH0 N'],
  "trim": ['T R IH1 M'],
  "trimac": ['T R IH1 M AE0 K'],
  "trimarchi": ['T R IY0 M AA1 R K IY0'],
  "trimarco": ['T R IH0 M AA1 R K OW0'],
  "trimas": ['T R IY1 M AH0 S'],
  "trimble": ['T R IH1 M B AH0 L'],
  "trimboli": ['T R IY0 M B OW1 L IY0'],
  "trimedyne": ['T R AY1 M D AY2 N'],
  "trimester": ['T R AY0 M EH1 S T ER0'],
  "trimetrexate": ['T R IH0 M EH1 T R AH0 K S EY2 T'],
  "trimm": ['T R IH1 M'],
  "trimmed": ['T R IH1 M D'],
  "trimmer": ['T R IH1 M ER0'],
  "trimmers": ['T R IH1 M ER0 Z'],
  "trimming": ['T R IH1 M IH0 NG'],
  "trimmings": ['T R IH1 M IH0 NG Z'],
  "trimpe": ['T R IH1 M P'],
  "trims": ['T R IH1 M Z'],
  "trincomalee": ['T R IH0 NG K OW1 M AH0 L IY0'],
  "trine": ['T R AY1 N'],
  "tringali": ['T R IH0 NG G AA1 L IY0'],
  "trinh": ['T R IH1 N'],
  "trinidad": ['T R IH1 N IH0 D AE2 D'],
  "trinity": ['T R IH1 N AH0 T IY0', 'T R IH1 N IH0 T IY0'],
  "trinity's": ['T R IH1 N IH0 T IY0 Z'],
  "trinka": ['T R IH1 NG K AH0'],
  "trinket": ['T R IH1 NG K AH0 T'],
  "trinkets": ['T R IH1 NG K AH0 T S'],
  "trinkle": ['T R IH1 NG K AH0 L'],
  "trinova": ['T R AY2 N OW1 V AH0'],
  "trintex": ['T R IH1 N T EH2 K S'],
  "trio": ['T R IY1 OW2'],
  "trio's": ['T R IY1 OW2 Z'],
  "triola": ['T R IY0 OW1 L AH0'],
  "triolo": ['T R IY0 OW1 L OW0'],
  "triomphe": ['T R IY0 OW1 M F'],
  "trip": ['T R IH1 P'],
  "trip's": ['T R IH1 P S'],
  "tripartite": ['T R AY0 P AA1 R T AY2 T'],
  "tripe": ['T R AY1 P'],
  "tripi": ['T R IY1 P IY0'],
  "triple": ['T R IH1 P AH0 L'],
  "triplecast": ['T R IH1 P AH0 L K AE2 S T'],
  "tripled": ['T R IH1 P AH0 L D'],
  "triples": ['T R IH1 P AH0 L Z'],
  "triplet": ['T R IH1 P L AH0 T'],
  "triplets": ['T R IH1 P L AH0 T S'],
  "triplett": ['T R IH1 P L IH0 T'],
  "triplex": ['T R IH0 P L EH1 K S'],
  "triplicate": ['T R IH1 P L IH0 K AH0 T'],
  "tripling": ['T R IH1 P AH0 L IH0 NG', 'T R IH1 P L IH0 NG'],
  "tripod": ['T R AY1 P AA2 D'],
  "tripodi": ['T R IY0 P OW1 D IY0'],
  "tripods": ['T R AY1 P AA2 D Z'],
  "tripoli": ['T R IH1 P AH0 L IY0'],
  "tripoli's": ['T R IH1 P AH0 L IY0 Z'],
  "tripp": ['T R IH1 P'],
  "tripp's": ['T R IH1 P S'],
  "trippe": ['T R IH1 P'],
  "tripped": ['T R IH1 P T'],
  "trippel": ['T R IH1 P AH0 L'],
  "tripper": ['T R IH1 P ER0'],
  "trippers": ['T R IH1 P ER0 Z'],
  "trippett": ['T R IH1 P IH0 T'],
  "trippie": ['T R IH1 P IY0'],
  "tripping": ['T R IH1 P IH0 NG'],
  "tripple": ['T R IH1 P AH0 L'],
  "trippy": ['T R IH1 P IY2'],
  "trips": ['T R IH1 P S'],
  "triptych": ['T R IH1 P T IH0 K'],
  "triptychs": ['T R IH1 P T IH0 K S'],
  "trireme": ['T R AY1 R IY2 M'],
  "triremes": ['T R AY1 R IY2 M Z'],
  "trish": ['T R IH1 SH'],
  "trisha": ['T R IH1 SH AH0'],
  "trisha's": ['T R IH1 SH AH0 Z'],
  "triska": ['T R IH1 S K AH0'],
  "trisler": ['T R IH1 S AH0 L ER0', 'T R IH1 S L ER0'],
  "trism": ['T R IH1 Z AH0 M'],
  "trista": ['T R IH1 S T AH0'],
  "tristan": ['T R IH1 S T AE2 N'],
  "tristar": ['T R AY1 S T AA2 R'],
  "tristate": ['T R AY1 S T EY2 T'],
  "tristram": ['T R IH1 S T R AH0 M'],
  "tritch": ['T R IH1 CH'],
  "trite": ['T R AY1 T'],
  "trites": ['T R AY1 T S'],
  "tritium": ['T R IH1 T IY0 AH0 M'],
  "triton": ['T R AY1 T AH0 N'],
  "triton's": ['T R AY1 T AH0 N Z'],
  "tritsch": ['T R IH1 CH'],
  "tritschler": ['T R IH1 CH L ER0'],
  "tritt": ['T R IH1 T'],
  "tritz": ['T R IH1 T S'],
  "triumph": ['T R AY1 AH0 M F'],
  "triumphal": ['T R AY0 AH1 M F AH0 L'],
  "triumphant": ['T R AY0 AH1 M F AH0 N T'],
  "triumphantly": ['T R AY0 AH1 M F AH0 N T L IY0'],
  "triumphed": ['T R AY1 AH0 M F T'],
  "triumphs": ['T R AY1 AH0 M F S'],
  "triumvirate": ['T R AY0 AH1 M V ER0 AH0 T'],
  "trivedi": ['T R IY0 V EH1 D IY0'],
  "trivest": ['T R IH1 V AH0 S T', 'T R AY1 V EH2 S T'],
  "trivett": ['T R IH1 V IH0 T'],
  "trivette": ['T R IH0 V EH1 T'],
  "trivia": ['T R IH1 V IY0 AH0'],
  "trivial": ['T R IH1 V IY0 AH0 L'],
  "triviality": ['T R IH2 V IY0 AE1 L AH0 T IY2'],
  "trivialize": ['T R IH1 V IY0 AH0 L AY2 Z'],
  "trivialized": ['T R IH1 V IY0 AH0 L AY2 Z D'],
  "trivializes": ['T R IH1 V IY0 AH0 L AY2 Z IH0 Z'],
  "trivializing": ['T R IH1 V IY0 AH0 L AY2 Z IH0 NG'],
  "trixie": ['T R IH1 K S IY0'],
  "trixy": ['T R IH1 K S IY0'],
  "trizec": ['T R IH1 Z AH0 K'],
  "trizec's": ['T R IH1 Z EH0 K S'],
  "trnka": ['T R IH1 NG K AH0'],
  "trnopolje": ['T EH2 R N AH0 P AO1 L Y EH0'],
  "trobaugh": ['T R AA1 B AO0'],
  "troche": ['T R AA1 CH'],
  "trochmann": ['T R AA1 CH M AE0 N', 'T R AA1 K M AE0 N'],
  "trocki": ['T R AA1 K IY0'],
  "trod": ['T R AA1 D'],
  "trodden": ['T R AA1 D AH0 N'],
  "troeger": ['T R OW1 G ER0'],
  "troendle": ['T R OW1 N D AH0 L'],
  "troester": ['T R OW1 S T ER0'],
  "trogdon": ['T R AA1 G D AH0 N'],
  "troha": ['T R OW1 HH AH0'],
  "troia": ['T R OW1 Y AH0'],
  "troiani": ['T R OW0 Y AA1 N IY0'],
  "troiano": ['T R OW0 IY0 AA1 N OW0'],
  "troika": ['T R OY1 K AH0'],
  "trois": ['T W AA1'],
  "troise": ['T R OY1 Z'],
  "troisi": ['T R OY1 S IY0'],
  "trojan": ['T R OW1 JH AH0 N'],
  "trojanowski": ['T R AH0 Y AH0 N AO1 F S K IY0'],
  "trojans": ['T R OW1 JH AH0 N Z'],
  "trokel": ['T R OW1 K AH0 L'],
  "trolinger": ['T R OW1 L IH0 NG ER0'],
  "troll": ['T R OW1 L'],
  "troll's": ['T R OW1 L Z'],
  "trolley": ['T R AA1 L IY0'],
  "trolleys": ['T R AA1 L IY0 Z'],
  "trolling": ['T R OW1 L IH0 NG'],
  "trollinger": ['T R OW1 L IH0 NG ER0'],
  "trolls": ['T R OW1 L Z'],
  "troma": ['T R OW1 M AH0'],
  "trombino": ['T R OW0 M B IY1 N OW0'],
  "tromble": ['T R AA1 M B AH0 L'],
  "trombley": ['T R AA1 M B L IY0'],
  "trombly": ['T R AA1 M B L IY0'],
  "trombone": ['T R AA0 M B OW1 N'],
  "trombones": ['T R AA0 M B OW1 N Z'],
  "trombonist": ['T R AA2 M B OW1 N IH0 S T'],
  "tromp": ['T R AA1 M P'],
  "trompeter": ['T R AA1 M P IY0 T ER0'],
  "tron": ['T R AA1 N'],
  "troncoso": ['T R OW0 N K OW1 S OW0'],
  "trone": ['T R OW1 N'],
  "tronic": ['T R AA1 N IH0 K'],
  "troon": ['T R UW1 N'],
  "troop": ['T R UW1 P'],
  "troop's": ['T R UW1 P S'],
  "trooped": ['T R UW1 P T'],
  "trooper": ['T R UW1 P ER0'],
  "trooper's": ['T R UW1 P ER0 Z'],
  "troopers": ['T R UW1 P ER0 Z'],
  "troopers'": ['T R UW1 P ER0 Z'],
  "trooping": ['T R UW1 P IH0 NG'],
  "troops": ['T R UW1 P S'],
  "troops'": ['T R UW1 P S'],
  "troost": ['T R UW1 S T'],
  "tropea": ['T R OW1 P IY0 AH0'],
  "tropeano": ['T R OW2 P IY1 N OW0'],
  "trophies": ['T R OW1 F IY0 Z'],
  "trophy": ['T R OW1 F IY0'],
  "tropic": ['T R AA1 P IH0 K'],
  "tropical": ['T R AA1 P IH0 K AH0 L'],
  "tropicana": ['T R AA2 P IH0 K AE1 N AH0'],
  "tropicana's": ['T R AA2 P IH0 K AE1 N AH0 Z'],
  "tropics": ['T R AA1 P IH0 K S'],
  "tropopause": ['T R AA1 P AH0 P AO2 Z'],
  "tropp": ['T R AA1 P'],
  "tropworld": ['T R AA1 P W ER0 L D'],
  "trosch": ['T R AA1 SH'],
  "trosclair": ['T R AH0 S K L EH1 R'],
  "trosper": ['T R AA1 S P ER0'],
  "trost": ['T R AA1 S T'],
  "trostel": ['T R AA1 S T AH0 L'],
  "trostle": ['T R AA1 S AH0 L'],
  "trot": ['T R AA1 T'],
  "troth": ['T R OW1 TH', 'T R AO1 TH'],
  "trotman": ['T R AA1 T M AH0 N'],
  "trots": ['T R AA1 T S'],
  "trotsky": ['T R AA1 T S K IY2'],
  "trott": ['T R AA1 T'],
  "trotted": ['T R AA1 T IH0 D'],
  "trotten": ['T R AA1 T AH0 N'],
  "trotter": ['T R AA1 T ER0'],
  "trottier": ['T R AA1 T IY0 ER0'],
  "trotting": ['T R AA1 T IH0 NG'],
  "troubadour": ['T R UW1 B AH0 D AO2 R'],
  "troubh": ['T R UW1 B'],
  "trouble": ['T R AH1 B AH0 L'],
  "troubled": ['T R AH1 B AH0 L D'],
  "troublefield": ['T R AH1 B AH0 L F IY2 L D'],
  "troublemaker": ['T R AH1 B AH0 L M EY2 K ER0'],
  "troublemakers": ['T R AH1 B AH0 L M EY2 K ER0 Z'],
  "troubles": ['T R AH1 B AH0 L Z'],
  "troubleshoot": ['T R AH1 B AH0 L SH UW2 T'],
  "troubleshooter": ['T R AH1 B AH0 L SH UW2 T ER0'],
  "troubleshooting": ['T R AH1 B AH0 L SH UW2 T IH0 NG'],
  "troubleshoots": ['T R AH1 B AH0 L SH UW2 T S'],
  "troublesome": ['T R AH1 B AH0 L S AH0 M'],
  "troubling": ['T R AH1 B AH0 L IH0 NG', 'T R AH1 B L IH0 NG'],
  "troudt": ['T R AW1 D T'],
  "trough": ['T R AO1 F'],
  "troughs": ['T R AO1 F S'],
  "trounce": ['T R AW1 N S'],
  "trounced": ['T R AW1 N S T'],
  "trouncing": ['T R AW1 N S IH0 NG'],
  "troung": ['T R AW1 NG'],
  "troup": ['T R UW1 P'],
  "troupe": ['T R UW1 P'],
  "troupe's": ['T R UW1 P S'],
  "troupes": ['T R UW1 P S'],
  "trousdale": ['T ER1 AH0 S D EY0 L'],
  "trouser": ['T R AW1 Z ER0'],
  "trousers": ['T R AW1 Z ER0 Z'],
  "trout": ['T R AW1 T'],
  "trout's": ['T R AW1 T S'],
  "troutman": ['T R AW1 T M AH0 N'],
  "troutner": ['T R AW1 T N ER0'],
  "troutt": ['T R AW1 T'],
  "troutwine": ['T R AW1 T W AY2 N'],
  "trovato": ['T R OW0 V AA1 T OW0'],
  "trovatore": ['T R OW1 V AH0 T AO2 R'],
  "trove": ['T R OW1 V'],
  "trow": ['T R OW1'],
  "trowbridge": ['T R OW1 B R IH0 JH'],
  "trowel": ['T R AW1 W EH0 L'],
  "trowell": ['T R AA1 W EH0 L'],
  "trower": ['T R AW1 ER0'],
  "troxel": ['T R AA1 K S AH0 L'],
  "troxell": ['T R AA1 K S AH0 L'],
  "troxler": ['T R AA1 K S L ER0'],
  "troy": ['T R OY1'],
  "troyan": ['T R OY1 AH0 N'],
  "troyanos": ['T R OY2 AA1 N OW0 S'],
  "troyat": ['T R OY1 AE0 T'],
  "troyer": ['T R OY1 ER0'],
  "troyka": ['T R OY1 K AH0'],
  "troyu": ['T R OY0 UW1'],
  "truancy": ['T R UW1 AH0 N S IY0'],
  "truant": ['T R UW1 AH0 N T'],
  "truax": ['T R UW1 AE0 K S'],
  "trubey": ['T R UW1 B IY0'],
  "truby": ['T R UW1 B IY0'],
  "trucco": ['T R UW1 K OW0'],
  "truce": ['T R UW1 S'],
  "truchan": ['T R AH1 CH AH0 N'],
  "truck": ['T R AH1 K'],
  "truck's": ['T R AH1 K S'],
  "trucked": ['T R AH1 K T'],
  "truckee": ['T R AH1 K IY0'],
  "trucker": ['T R AH1 K ER0'],
  "truckers": ['T R AH1 K ER0 Z'],
  "truckers'": ['T R AH1 K ER0 Z'],
  "trucking": ['T R AH1 K IH0 NG'],
  "truckload": ['T R AH1 K L OW2 D'],
  "truckloads": ['T R AH1 K L OW2 D Z'],
  "trucks": ['T R AH1 K S'],
  "trucks'": ['T R AH1 K S'],
  "truculent": ['T R AH1 K Y AH0 L AH0 N T'],
  "truda": ['T R UW1 D AH0'],
  "trude": ['T R UW1 D'],
  "trudeau": ['T R UW0 D OW1'],
  "trudel": ['T R UW1 D AH0 L'],
  "trudell": ['T R AH1 D AH0 L'],
  "trudge": ['T R AH1 JH'],
  "trudged": ['T R AH1 JH D'],
  "trudgen": ['T R AH1 JH AH0 N'],
  "trudges": ['T R AH1 JH IH0 Z'],
  "trudging": ['T R AH1 JH IH0 NG'],
  "trudia": ['T R UW1 D IY0 AH0'],
  "trudie": ['T R UW1 D IY0'],
  "trudo": ['T R UW1 D OW0'],
  "trudy": ['T R UW1 D IY0'],
  "true": ['T R UW1'],
  "true-view": ['T R UW1 V Y UW1'],
  "trueblood": ['T R UW1 B L AH2 D'],
  "truell": ['T R UW1 AH0 L'],
  "truelove": ['T R UW1 L AH2 V'],
  "trueman": ['T R UH1 M AH0 N'],
  "truer": ['T R UW1 ER0'],
  "truesdale": ['T R UW1 Z D EY2 L'],
  "truesdell": ['T R UW1 Z D EH2 L'],
  "truest": ['T R UW1 IH0 S T'],
  "truett": ['T R UW1 T'],
  "truex": ['T R UW1 EH2 K S'],
  "truffaut": ['T R UW0 F OW1'],
  "truffaut's": ['T R UW0 F OW1 Z'],
  "truffle": ['T R AH1 F AH0 L'],
  "truffles": ['T R AH1 F AH0 L Z'],
  "truglio": ['T R AH1 G L IY0 OW0'],
  "truicko": ['T R UW1 K OW0', 'T R UW2 IY1 K OW0'],
  "truism": ['T R UW1 IH0 Z AH0 M'],
  "truitt": ['T R UW1 T'],
  "trujillo": ['T R UW0 HH IY1 OW0'],
  "trull": ['T R AH1 L'],
  "trullinger": ['T R AH1 L IH0 NG ER0'],
  "trulock": ['T R AH1 L AH0 K'],
  "trulove": ['T R UW0 L OW1 V IY0'],
  "trulson": ['T R AH1 L S AH0 N'],
  "truluck": ['T R AH1 L AH0 K'],
  "truly": ['T R UW1 L IY0'],
  "truman": ['T R UW1 M AH0 N'],
  "truman's": ['T R UW1 M AH0 N Z'],
  "trumble": ['T R AH1 M B AH0 L'],
  "trumbo": ['T R AH1 M B OW0'],
  "trumbull": ['T R AH1 M B AH0 L'],
  "trumka": ['T R AH1 M K AH0'],
  "trump": ['T R AH1 M P'],
  "trump's": ['T R AH1 M P S'],
  "trumped": ['T R AH1 M P T'],
  "trumpet": ['T R AH1 M P AH0 T'],
  "trumpeted": ['T R AH1 M P AH0 T IH0 D'],
  "trumpeter": ['T R AH1 M P AH0 T ER0'],
  "trumpeting": ['T R AH1 M P AH0 T IH0 NG'],
  "trumpets": ['T R AH1 M P AH0 T S'],
  "trumpower": ['T R AH1 M P OW2 ER0'],
  "trumps": ['T R AH1 M P S'],
  "truncate": ['T R AH1 NG K EY2 T'],
  "truncated": ['T R AH1 NG K EY2 T IH0 D'],
  "truncates": ['T R AH1 NG K EY2 T S'],
  "truncating": ['T R AH1 NG K EY2 T IH0 NG'],
  "truncation": ['T R AH1 NG K EY2 SH AH0 N'],
  "truncheon": ['T R AH1 N CH IH0 N'],
  "truncheons": ['T R AH1 N CH IH0 N Z'],
  "trundle": ['T R AH1 N D AH0 L'],
  "trundled": ['T R AH1 N D AH0 L D'],
  "trunk": ['T R AH1 NG K'],
  "trunkline": ['T R AH1 NG K L AY2 N'],
  "trunks": ['T R AH1 NG K S'],
  "trunnell": ['T R AH1 N AH0 L'],
  "trunzo": ['T R AH1 N Z OW0'],
  "truong": ['T R AO1 NG'],
  "trupiano": ['T R UW0 P IY0 AA1 N OW0'],
  "trupin": ['T R UW1 P IH0 N'],
  "trupin's": ['T R UW1 P IH0 N Z'],
  "trupp": ['T R AH1 P'],
  "truro": ['T R UW1 R OW0'],
  "trus": ['T R AH1 S'],
  "truscott": ['T R AH1 S K AH0 T'],
  "truskowski": ['T R AH0 S K AO1 F S K IY0'],
  "trusler": ['T R AH1 S AH0 L ER0', 'T R AH1 S L ER0'],
  "truslow": ['T R AH1 S L OW0'],
  "truss": ['T R AH1 S'],
  "trussell": ['T R AH1 S AH0 L'],
  "trusses": ['T R AH1 S IH0 Z'],
  "trust": ['T R AH1 S T'],
  "trust's": ['T R AH1 S T S'],
  "trustco": ['T R AH1 S T K OW0'],
  "trustcorp": ['T R AH1 S T K AO0 R P'],
  "trusted": ['T R AH1 S T IH0 D'],
  "trustee": ['T R AH2 S T IY1'],
  "trustee's": ['T R AH2 S T IY1 Z'],
  "trustees": ['T R AH2 S T IY1 Z'],
  "trustees'": ['T R AH2 S T IY1 Z'],
  "trusteeship": ['T R AH2 S T IY1 SH IH2 P'],
  "trusthouse": ['T R AH1 S T HH AW2 S'],
  "trusting": ['T R AH1 S T IH0 NG'],
  "trusts": ['T R AH1 S T S'],
  "trusts'": ['T R AH1 S T S'],
  "trustworthiness": ['T R AH1 S T W ER2 DH IY0 N AH0 S'],
  "trustworthy": ['T R AH1 S T W ER2 DH IY0'],
  "trusty": ['T R AH1 S T IY0'],
  "truszkowski": ['T R AH0 SH K AO1 F S K IY0'],
  "truth": ['T R UW1 TH'],
  "truthful": ['T R UW1 TH F AH0 L'],
  "truthfully": ['T R UW1 TH F AH0 L IY0'],
  "truthfulness": ['T R UW1 TH F AH0 L N AH0 S'],
  "truthiness": ['T R UW1 TH IY2 N AH0 S'],
  "truths": ['T R UW1 TH S'],
  "truxal": ['T R AH1 K S AH0 L'],
  "truxillo": ['T R AH2 K S IH1 L OW0'],
  "try": ['T R AY1'],
  "try-on": ['T R AY1 AA2 N'],
  "try-ons": ['T R AY1 AA2 N Z'],
  "tryart": ['T R AY1 AA2 R T'],
  "tryba": ['T R IH1 B AH0'],
  "trybus": ['T R IH1 B IH0 S'],
  "trygg": ['T R IH1 G'],
  "trygstad": ['T R IH1 G S T AH0 D'],
  "trygve": ['T R IH1 G V IY0'],
  "tryin'": ['T R AY1 IH0 N'],
  "trying": ['T R AY1 IH0 NG', 'T R AY1 NG'],
  "tryon": ['T R AY1 AH0 N'],
  "tryout": ['T R AY1 AW2 T'],
  "tryouts": ['T R AY1 AW2 T S'],
  "tryphena": ['T R IH1 F IH0 N AH0'],
  "tryst": ['T R IH1 S T', 'T R AY1 S T'],
  "trzaska": ['T ER0 Z AA1 S K AH0'],
  "trzcinski": ['T ER0 ZH IH1 N S K IY0'],
  "trzeciak": ['T ER0 Z EH1 CH IY0 AE0 K'],
  "ts": ['T IY1 EH1 S'],
  "tsai": ['T S AY1', 'S AY1'],
  "tsai's": ['T S AY1 Z', 'S AY1 Z'],
  "tsakos": ['T S AA1 K OW0 S'],
  "tsang": ['T S AE1 NG', 'S AE1 NG'],
  "tsao": ['T S AW1', 'S AW1'],
  "tsar": ['Z AA1 R', 'T S AA1 R'],
  "tschantz": ['CH AE1 N T S'],
  "tschetter": ['CH EH1 T ER0'],
  "tschida": ['CH AY1 D AH0'],
  "tschirhart": ['CH ER1 HH AA0 R T'],
  "tschopp": ['CH AA1 P'],
  "tschudy": ['CH UW1 D IY0'],
  "tschumi": ['CH UW1 M IY0'],
  "tse": ['T S IY1', 'S IY1'],
  "tsemel": ['T S EH1 M AH0 L'],
  "tseng": ['T S EH1 NG', 'S EH1 NG'],
  "tsetse": ['T S IY1 T S IY0', 'T IY1 T S IY0'],
  "tshisekedi": ['SH IH0 S EH1 K AH0 D IY0'],
  "tsiang": ['T S Y AE1 NG'],
  "tsingtao": ['S IH1 NG T AW2', 'T S IH1 NG D AW2'],
  "tso": ['T S OW1', 'S OW1'],
  "tsongas": ['T S AO1 NG G AH0 S', 'S AO1 NG G AH0 S'],
  "tsongas'": ['T S AO1 NG G AH0 S', 'S AO1 NG G AH0 S'],
  "tsongas's": ['T S AO1 NG G AH0 S AH0 Z', 'S AO1 NG G AH0 S AH0 Z'],
  "tsu": ['T S UW1'],
  "tsuda": ['T S UW1 D AH0', 'S UW1 D AH0'],
  "tsui": ['T S UW1 IY0', 'S UW1 IY0'],
  "tsuji": ['T S UW1 JH IY0', 'S UW1 JH IY0'],
  "tsukamoto": ['T S UW0 K AA0 M OW1 T OW0', 'S UW0 K AA0 M OW1 T OW0'],
  "tsukuba": ['T S UW2 K Y UW1 B AH0', 'S UW2 K Y UW1 B AH0'],
  "tsunami": ['T S UW0 N AA1 M IY0', 'S UW0 N AA1 M IY0'],
  "tsuneo": ['T S UW1 N IY0 OW0', 'S UW1 N IY0 OW0'],
  "tsurumi": ['T S UW0 R UW1 M IY0', 'S UW0 R UW1 M IY0'],
  "tsutomu": ['T S UW0 T OW1 M UW0'],
  "tt's": ['T IY1 T IY1 Z'],
  "tu": ['T UW1'],
  "tub": ['T AH1 B'],
  "tuba": ['T UW1 B AH0'],
  "tubal": ['T UW1 B AH0 L'],
  "tubb": ['T AH1 B'],
  "tubbercurry": ['T AH2 B ER0 K AH1 R IY0'],
  "tubbs": ['T AH1 B Z'],
  "tubby": ['T AH1 B IY0'],
  "tube": ['T UW1 B', 'T Y UW1 B'],
  "tuber": ['T UW1 B ER0'],
  "tuberculosis": ['T AH0 B ER2 K Y AH0 L OW1 S IH0 S', 'T UW0 B ER2 K Y AH0 L OW1 S AH0 S', 'T UW0 B ER2 K Y UW0 L OW1 S AH0 S'],
  "tubers": ['T UW1 B ER0 S'],
  "tuberville": ['T UW1 B ER0 V IH2 L'],
  "tubes": ['T UW1 B Z'],
  "tubing": ['T UW1 B IH0 NG'],
  "tubman": ['T AH1 B M AH0 N'],
  "tubs": ['T AH1 B Z'],
  "tubular": ['T UW1 B Y AH0 L ER0'],
  "tubule": ['T UW1 B Y UW0 L'],
  "tucci": ['T UW1 CH IY0'],
  "tucci's": ['T UW1 CH IY0 S'],
  "tucciarone": ['T UW0 CH ER0 OW1 N IY0'],
  "tuccillo": ['T UW0 CH IH1 L OW0'],
  "tucek": ['T UW1 CH EH2 K'],
  "tuch": ['T AH1 CH'],
  "tuchman": ['T AH1 K M AH0 N'],
  "tucholski": ['T AH0 HH OW1 L S K IY0'],
  "tuck": ['T AH1 K'],
  "tucked": ['T AH1 K T'],
  "tucker": ['T AH1 K ER0'],
  "tucker's": ['T AH1 K ER0 Z'],
  "tuckerman": ['T AH1 K ER0 M AH0 N'],
  "tuckett": ['T AH1 K IH0 T'],
  "tuckey": ['T AH1 K IY0'],
  "tucking": ['T AH1 K IH0 NG'],
  "tuckman": ['T AH1 K M AH0 N'],
  "tucks": ['T AH1 K S'],
  "tucson": ['T UW1 S AA2 N'],
  "tucuman": ['T UW1 K Y UW0 M AH0 N'],
  "tude": ['T UW1 D'],
  "tudisco": ['T UW0 D IY1 S K OW0'],
  "tudjman": ['T UH1 JH M AH0 N'],
  "tudjman's": ['T UH1 JH M AH0 N Z'],
  "tudor": ['T UW1 D ER0', 'T Y UW1 D ER0'],
  "tudor's": ['T Y UW1 D ER0 Z'],
  "tue": ['T UW1'],
  "tuel": ['T UW1 L'],
  "tuell": ['T UW1 L'],
  "tuesday": ['T UW1 Z D IY0', 'T UW1 Z D EY2', 'T Y UW1 Z D EY2'],
  "tuesday's": ['T UW1 Z D IY0 Z', 'T UW1 Z D EY2 Z', 'T Y UW1 Z D EY2 Z'],
  "tuesdays": ['T UW1 Z D EY2 Z', 'T UW1 Z D IY0 Z', 'T Y UW1 Z D EY2 Z'],
  "tufa": ['T UW1 F AH0'],
  "tufano": ['T UW0 F AA1 N OW0'],
  "tuff": ['T AH1 F'],
  "tufnel": ['T AH1 F N IH0 L'],
  "tufo": ['T UW1 F OW0'],
  "tuft": ['T AH1 F T'],
  "tufte": ['T AH1 F T'],
  "tufted": ['T AH1 F T IH0 D'],
  "tuftlike": ['T AH1 F T L AY2 K'],
  "tufts": ['T AH1 F T S'],
  "tug": ['T AH1 G'],
  "tugboat": ['T AH1 G B OW2 T'],
  "tugboats": ['T AH1 G B OW2 T S'],
  "tugged": ['T AH1 G D'],
  "tugging": ['T AH1 G IH0 NG'],
  "tuggle": ['T AH1 G AH0 L'],
  "tugman": ['T AH1 G M AH0 N'],
  "tugs": ['T AH1 G Z'],
  "tugwell": ['T AH1 G W EH2 L'],
  "tuhan": ['T UW0 HH AH0 N'],
  "tuinstra": ['T UW0 IH1 N S T R AH0'],
  "tuite": ['T UW1 T'],
  "tuition": ['T Y UW0 IH1 SH AH0 N'],
  "tuitions": ['T UW0 IH1 SH AH0 N Z'],
  "tuk": ['T UW1 K'],
  "tuks": ['T AH1 K S'],
  "tula": ['T UW1 L AH0'],
  "tulane": ['T UW0 L EY1 N'],
  "tuley": ['T Y UW1 L IY0'],
  "tulip": ['T UW1 L AH0 P'],
  "tulip's": ['T UW1 L IH0 P S'],
  "tulips": ['T UW1 L AH0 P S'],
  "tull": ['T AH1 L'],
  "tullar": ['T AH1 L ER0'],
  "tuller": ['T AH1 L ER0'],
  "tulley": ['T UW1 L IY0'],
  "tullia": ['T AH1 L IY2 AH0'],
  "tullier": ['T AH1 L IY2 ER0'],
  "tullio": ['T UW1 L IY2 OW0'],
  "tullis": ['T AH1 L IH0 S'],
  "tullius": ['T AH1 L IY2 IH0 S'],
  "tullo": ['T UW1 L OW0'],
  "tulloch": ['T AH1 L AH0 K'],
  "tullock": ['T AH1 L AH0 K'],
  "tullos": ['T AH1 L OW0 Z'],
  "tully": ['T AH1 L IY0'],
  "tulsa": ['T AH1 L S AH0'],
  "tuma": ['T UW1 M AH0'],
  "tumacoceri": ['T UW2 M AH0 K OW1 CH ER0 IY0'],
  "tuman": ['CH UW1 M AH0 N'],
  "tumazos": ['T UW0 M AA1 Z OW0 S'],
  "tumble": ['T AH1 M B AH0 L'],
  "tumbled": ['T AH1 M B AH0 L D'],
  "tumbler": ['T AH1 M B L ER0'],
  "tumbler's": ['T AH1 M B L ER0 Z'],
  "tumblers": ['T AH1 M B L ER0 Z'],
  "tumbles": ['T AH1 M B AH0 L Z'],
  "tumbleson": ['T AH1 M B AH0 L S AH0 N'],
  "tumblin": ['T AH1 M B L IH0 N'],
  "tumbling": ['T AH1 M B AH0 L IH0 NG', 'T AH1 M B L IH0 NG'],
  "tumblr": ['T AH1 M B L ER0'],
  "tumblr's": ['T AH1 M B L ER0 Z'],
  "tumeric": ['T UW0 M EH1 R IH0 K'],
  "tumescent": ['T UW0 M EH1 S IH0 N T'],
  "tumey": ['T AH1 M IY0'],
  "tuminello": ['T UW0 M IY0 N EH1 L OW0'],
  "tumlin": ['T AH1 M L IH0 N'],
  "tumlinson": ['T AH1 M L IH0 N S AH0 N'],
  "tummies": ['T AH1 M IY0 Z'],
  "tumminello": ['T UW0 M IY0 N EH1 L OW0'],
  "tummond": ['T AH1 M AH0 N D'],
  "tummy": ['T AH1 M IY0'],
  "tumolo": ['T UW0 M OW1 L OW0'],
  "tumor": ['T UW1 M ER0'],
  "tumors": ['T UW1 M ER0 Z'],
  "tums": ['T AH1 M Z'],
  "tums'": ['T AH1 M Z'],
  "tumult": ['T UW1 M AH0 L T'],
  "tumultuous": ['T UW2 M AH1 L CH UW2 AH0 S'],
  "tumulty": ['T UW1 M AH0 L T IY0'],
  "tuna": ['T UW1 N AH0', 'T Y UW1 N AH0'],
  "tunable": ['T UW1 N AH0 B AH0 L'],
  "tunafish": ['T UW1 N AH0 F IH0 SH'],
  "tunas": ['T UW1 N AH0 Z'],
  "tunde": ['T AH1 N D'],
  "tundra": ['T AH1 N D R AH0'],
  "tune": ['T UW1 N'],
  "tuned": ['T UW1 N D'],
  "tuneful": ['T UW1 N F AH0 L'],
  "tuner": ['T UW1 N ER0'],
  "tunes": ['T UW1 N Z'],
  "tuneup": ['T UW1 N AH2 P'],
  "tung": ['T AH1 NG'],
  "tung's": ['T AH1 NG Z'],
  "tungate": ['T UW1 NG EY0 T'],
  "tungsram": ['T AH1 NG Z R AE2 M'],
  "tungsrom": ['T AH1 NG Z R AH0 M'],
  "tungsrom's": ['T AH1 NG Z R AH0 M Z'],
  "tungsten": ['T AH1 NG S T AH0 N'],
  "tunic": ['T UW1 N IH0 K'],
  "tunica": ['T UW1 N IH0 K AH0'],
  "tunica's": ['T UW1 N IH0 K AH0 Z'],
  "tunicate": ['T UW1 N IH0 K AH0 T'],
  "tunick": ['T AH1 N IH0 K'],
  "tuning": ['T UW1 N IH0 NG'],
  "tunis": ['T UW1 N IH0 S', 'T UW2 N IY1 S'],
  "tunisia": ['T UW2 N IY1 ZH AH0'],
  "tunisian": ['T UW2 N IY1 ZH AH0 N'],
  "tunison": ['T AH1 N IH0 S AH0 N', 'T UW1 N IH0 S AH0 N'],
  "tunkelang": ['T AH2 NG K L AE1 NG'],
  "tunks": ['T AH1 NG K S'],
  "tunku": ['T AH1 NG K UW0'],
  "tunnel": ['T AH1 N AH0 L'],
  "tunnel's": ['T AH1 N AH0 L Z'],
  "tunneling": ['T AH1 N AH0 L IH0 NG'],
  "tunnell": ['T AH1 N AH0 L'],
  "tunnels": ['T AH1 N AH0 L Z'],
  "tunney": ['T AH1 N IY0'],
  "tunny": ['T AH1 N IY0'],
  "tunstall": ['T AH1 N S T AH0 L'],
  "tuohey": ['T UW1 HH EY0'],
  "tuohy": ['T UW1 IY0'],
  "tuolumne": ['T W AO1 L UW0 M'],
  "tuomi": ['T W OW1 M IY0'],
  "tupa": ['T UW1 P AH0'],
  "tupac": ['T UW1 P AO0 K'],
  "tupelo": ['T Y UW1 P AH0 L OW2', 'T UW1 P AH0 L OW2'],
  "tupelov": ['T UW1 P AH0 L AA0 V'],
  "tupper": ['T AH1 P ER0'],
  "tupperware": ['T AH1 P ER0 W EH2 R'],
  "tupy": ['T UW1 P IY0'],
  "turandot": ['T ER0 AE1 N D AH0 T'],
  "turano": ['T UH0 R AA1 N OW0'],
  "turban": ['T ER1 B AH0 N'],
  "turbans": ['T ER1 B AH0 N Z'],
  "turbas": ['T ER1 B AH0 S'],
  "turben": ['T ER1 B AH0 N'],
  "turberville": ['T ER1 B ER0 V IH2 L'],
  "turbett": ['T ER1 B IH0 T'],
  "turbeville": ['T ER1 B V IH0 L'],
  "turbid": ['T ER1 B IH0 D'],
  "turbidity": ['T ER0 B IH1 D AH0 T IY0'],
  "turbin": ['T ER1 B IH0 N'],
  "turbine": ['T ER1 B AY0 N'],
  "turbinen": ['T ER1 B IH0 N AH0 N'],
  "turbines": ['T ER1 B AY0 N Z'],
  "turbo": ['T ER1 B OW0'],
  "turbocharge": ['T ER1 B OW0 CH AA2 R JH'],
  "turbocharged": ['T ER1 B OW0 CH AA2 R JH D'],
  "turbocharges": ['T ER1 B OW0 CH AA2 R JH IH0 Z'],
  "turbocharging": ['T ER1 B OW0 CH AA2 R JH IH0 NG'],
  "turbofan": ['T ER1 B OW0 F AE2 N'],
  "turbojet": ['T ER1 B OW0 JH EH2 T'],
  "turboprop": ['T ER1 B OW0 P R AA2 P'],
  "turboprops": ['T ER1 B OW0 P R AA2 P S'],
  "turbos": ['T ER1 B OW0 Z'],
  "turbot": ['T ER2 B OW1', 'T ER1 B AH2 T'],
  "turbotax": ['T ER1 B OW0 T AE2 K S'],
  "turbulence": ['T ER1 B Y AH0 L AH0 N S'],
  "turbulent": ['T ER1 B Y AH0 L AH0 N T'],
  "turbyfill": ['T ER1 B IH0 F IH0 L'],
  "turchi": ['T UH1 R K IY0'],
  "turchin": ['T ER1 CH IH0 N'],
  "turchyn": ['T ER1 CH IH0 N'],
  "turck": ['T ER1 K'],
  "turco": ['T ER1 K OW0'],
  "turcott": ['T ER1 K AH0 T'],
  "turcotte": ['T ER0 K AO1 T'],
  "turcotte's": ['T ER0 K AO1 T S'],
  "ture": ['T UH1 R'],
  "turek": ['T Y UW1 R IH0 K'],
  "turenne": ['T Y UW1 R IH0 N'],
  "turf": ['T ER1 F'],
  "turgeon": ['T ER1 JH IH0 N'],
  "turgid": ['T ER1 JH IH0 D'],
  "turgut": ['T ER1 G AH0 T'],
  "turi": ['T UH1 R IY0'],
  "turin": ['T Y UH1 R IH0 N', 'T UH1 R IH0 N'],
  "turing": ['T UH1 R IH0 NG'],
  "turismo": ['T UH1 R IH0 S M OW0'],
  "turk": ['T ER1 K'],
  "turkel": ['T ER1 K AH0 L'],
  "turkey": ['T ER1 K IY0'],
  "turkey's": ['T ER1 K IY0 Z'],
  "turkeys": ['T ER1 K IY0 Z'],
  "turkic": ['T ER1 K IH0 K'],
  "turkington": ['T ER1 K IH0 NG T AH0 N'],
  "turkish": ['T ER1 K IH0 SH'],
  "turkmen": ['T ER1 K M EH2 N'],
  "turkmenistan": ['T ER2 K M EH1 N IH0 S T AE2 N'],
  "turko": ['T ER1 K OW0'],
  "turkovich": ['T ER1 K AH0 V IH0 CH'],
  "turks": ['T ER1 K S'],
  "turley": ['T ER1 L IY0'],
  "turlington": ['T ER1 L IH0 NG T AH0 N'],
  "turlough": ['T ER1 L OW0', 'T ER1 L AW0'],
  "turman": ['T ER1 M AH0 N'],
  "turmel": ['T ER1 M AH0 L'],
  "turmeric": ['T ER1 M AH0 R IH2 K'],
  "turmoil": ['T ER1 M OY2 L'],
  "turn": ['T ER1 N'],
  "turn-out": ['T ER1 N AW2 T'],
  "turnabout": ['T ER1 N AH0 B AW2 T'],
  "turnage": ['T ER1 N IH0 JH'],
  "turnaround": ['T ER1 N ER0 AW2 N D'],
  "turnarounds": ['T ER1 N ER0 AW2 N D Z'],
  "turnbaugh": ['T ER1 N B AO0'],
  "turnbo": ['T UH1 R N B OW0'],
  "turnbough": ['T ER1 N B AW2'],
  "turnbow": ['T ER1 N B OW0'],
  "turnbridge": ['T ER1 N B R IH2 JH'],
  "turnbull": ['T ER1 N B UH2 L'],
  "turncoat": ['T ER1 N K OW2 T'],
  "turndown": ['T ER1 N D AW2 N'],
  "turned": ['T ER1 N D'],
  "turner": ['T ER1 N ER0'],
  "turner's": ['T ER1 N ER0 Z'],
  "turney": ['T ER1 N IY0'],
  "turnham": ['T ER1 N HH AH0 M'],
  "turning": ['T ER1 N IH0 NG'],
  "turnip": ['T ER1 N AH0 P'],
  "turnips": ['T ER1 N AH0 P S'],
  "turnipseed": ['T ER0 N IH1 P S IY0 D'],
  "turnkey": ['T ER1 N K IY2'],
  "turnley": ['T ER1 N L IY0'],
  "turnmire": ['T ER1 N M AY0 R'],
  "turnoff": ['T ER1 N AO2 F'],
  "turnout": ['T ER1 N AW2 T'],
  "turnouts": ['T ER1 N AW2 T S'],
  "turnover": ['T ER1 N OW2 V ER0'],
  "turnovers": ['T ER1 N OW2 V ER0 Z'],
  "turnpike": ['T ER1 N P AY2 K'],
  "turnpikes": ['T ER1 N P AY2 K S'],
  "turnquest": ['T ER1 N K W EH0 S T'],
  "turnquist": ['T ER1 N K W IH0 S T'],
  "turns": ['T ER1 N Z'],
  "turnstile": ['T ER1 N S T AY2 L'],
  "turnstiles": ['T ER1 N S T AY2 L Z'],
  "turntable": ['T ER1 N T EY2 B AH0 L'],
  "turntables": ['T ER1 N T EY2 B AH0 L Z'],
  "turo": ['T UH1 R OW0'],
  "turow": ['T UH1 R OW0'],
  "turowski": ['T ER0 AO1 F S K IY0'],
  "turpen": ['T ER1 P AH0 N'],
  "turpentine": ['T ER1 P AH0 N T AY2 N'],
  "turpin": ['T ER1 P IH0 N'],
  "turpitude": ['T ER1 P IH0 T UW2 D'],
  "turquoise": ['T ER1 K W OY0 Z'],
  "turrell": ['T AO1 R AH0 L'],
  "turrentine": ['T UH0 R EH0 N T IY1 N IY0'],
  "turret": ['T ER1 AH0 T', 'T ER1 T'],
  "turrets": ['T ER1 AH0 T S'],
  "turri": ['T UH1 R IY0'],
  "turrill": ['T AO1 R AH0 L'],
  "tursi": ['T UH1 R S IY0'],
  "turski": ['T ER1 S K IY0'],
  "turtle": ['T ER1 T AH0 L'],
  "turtle's": ['T ER1 T AH0 L Z'],
  "turtleneck": ['T ER1 T AH0 L N EH2 K'],
  "turtlenecks": ['T ER1 T AH0 L N EH2 K S'],
  "turtles": ['T ER1 T AH0 L Z'],
  "turton": ['T ER1 T AH0 N'],
  "turvey": ['T ER0 V EY1'],
  "turville": ['T ER1 V IH2 L'],
  "turvy": ['T ER1 V IY0'],
  "tusa": ['T UW1 S AH0'],
  "tuscaloosa": ['T AH2 S K AH0 L UW1 S AH0'],
  "tuscaloosa's": ['T AH2 S K AH0 L UW1 S AH0 Z'],
  "tuscan": ['T AH1 S K AH0 N'],
  "tuscany": ['T AH1 S K AH0 N IY0'],
  "tush": ['T UH1 SH'],
  "tushes": ['T UH1 SH IH0 Z'],
  "tusing": ['T UW1 S IH0 NG'],
  "tusk": ['T AH1 S K'],
  "tuskegee": ['T AH1 S K AH0 G IY2', 'T AH0 S K IY1 G IY2'],
  "tusks": ['T AH1 S K S'],
  "tusla": ['T UW1 Z L AH0'],
  "tussaud's": ['T UW0 S OW1 Z', 'T AH0 S OW1 Z'],
  "tussey": ['T AH1 S IY0'],
  "tussing": ['T AH1 S IH0 NG'],
  "tussle": ['T AH1 S AH0 L'],
  "tussled": ['T AH1 S AH0 L D'],
  "tussles": ['T AH1 S AH0 L Z'],
  "tustin": ['T AH1 S T IH0 N'],
  "tut": ['T AH1 T'],
  "tut's": ['T AH1 T S'],
  "tutelage": ['T Y UW1 T IH0 L IH0 JH'],
  "tuten": ['T Y UW1 T AH0 N'],
  "tuthill": ['T AH1 T HH IH2 L'],
  "tutino": ['T UW0 T IY1 N OW0'],
  "tutko": ['T AH1 T K OW0'],
  "tutor": ['T UW1 T ER0'],
  "tutored": ['T UW1 T ER0 D'],
  "tutorial": ['T UW0 T AO1 R IY0 AH0 L'],
  "tutorials": ['T UW0 T AO1 R IY0 AH0 L Z'],
  "tutoring": ['T UW1 T ER0 IH0 NG'],
  "tutors": ['T UW1 T ER0 Z'],
  "tutsi": ['T UW1 T S IY0'],
  "tutsi's": ['T UW1 T S IY0 Z'],
  "tutsis": ['T UW1 T S IY0 Z'],
  "tutt": ['T AH1 T'],
  "tutterow": ['T AH1 T ER0 OW0'],
  "tutti": ['T UW1 T IY0'],
  "tuttle": ['T AH1 T AH0 L'],
  "tutton": ['T AH1 T AH0 N'],
  "tutu": ['T UW1 T UW2'],
  "tutwiler": ['T AH1 T W AY2 L ER0'],
  "tuvalu": ['T UW1 V AA0 L UW2'],
  "tux": ['T AH1 K S'],
  "tuxedo": ['T AH2 K S IY1 D OW0'],
  "tuxedos": ['T AH0 K S IY1 D OW2 Z'],
  "tuxford": ['T AH1 K S F ER0 D'],
  "tuxhorn": ['T AH1 K S HH ER0 N'],
  "tuyle": ['T UW1 L'],
  "tuzla": ['T UW1 Z L AH0'],
  "tuzla's": ['T UW1 Z L AH0 Z'],
  "tuzzolino": ['T UW0 T S OW0 L IY1 N OW0'],
  "tv": ['T IY1 V IY1', 'T EH2 L AH0 V IH1 ZH AH0 N'],
  "tv's": ['T IY1 V IY1 Z'],
  "tvedt": ['T V EH1 D T'],
  "tveit": ['T V IY1 T'],
  "tvs": ['T IY1 V IY1 Z'],
  "tvsat": ['T AH0 V S AE1 T'],
  "twaddell": ['T W AA0 D EH1 L'],
  "twaddle": ['T W AA1 D AH0 L'],
  "twain": ['T W EY1 N'],
  "twain's": ['T W EY1 N Z'],
  "twang": ['T W AA1 NG'],
  "twangy": ['T W AA1 N JH IY0'],
  "twardowski": ['T W ER0 D AW1 S K IY0'],
  "twardy": ['T W AO1 R D IY0'],
  "twarog": ['T W AO1 R AO0 G'],
  "twas": ['T W AH1 Z'],
  "twat": ['T W AA1 T'],
  "twats": ['T W AA1 T S'],
  "tweak": ['T W IY1 K'],
  "tweaked": ['T W IY1 K T'],
  "tweaking": ['T W IY1 K IH0 NG'],
  "tweaks": ['T W IY1 K S'],
  "twedt": ['T W EH1 D T'],
  "tweed": ['T W IY1 D'],
  "tweedie": ['T W IY1 D IY0'],
  "tweedle": ['T W IY1 D AH0 L'],
  "tweedy": ['T W IY1 D IY0'],
  "tween": ['T W IY1 N'],
  "tweet": ['T W IY1 T'],
  "tweeted": ['T W IY1 T IH0 D'],
  "tweets": ['T W IY1 T S'],
  "tweezer": ['T W IY1 Z ER0'],
  "tweezerman": ['T W IY1 Z ER0 M AE2 N'],
  "tweezers": ['T W IY1 Z ER0 Z'],
  "twelfth": ['T W EH1 L F TH'],
  "twelve": ['T W EH1 L V'],
  "twelvth": ['T W EH1 L V TH'],
  "twenties": ['T W EH1 N T IY0 Z', 'T W EH1 N IY0 Z'],
  "twentieth": ['T W EH1 N T IY0 AH0 TH', 'T W EH1 N T IY0 IH0 TH', 'T W EH1 N IY0 AH0 TH', 'T W EH1 N IY0 IH0 TH'],
  "twenty": ['T W EH1 N T IY0', 'T W EH1 N IY0'],
  "twenty's": ['T W EH1 N T IY0 Z', 'T W EH1 N IY0 Z'],
  "twenty-first": ['T W EH1 N T IY0 F ER2 S T'],
  "twenty-five": ['T W EH1 N T IY0 F AY2 V'],
  "twenty-four": ['T W EH1 N T IY0 F AO2 R'],
  "twenty-one": ['T W EH1 N T IY0 W AO2 N'],
  "twenty-three": ['T W EH1 N T IY0 TH R IY2'],
  "twenty-two": ['T W EH1 N T IY0 T UW2'],
  "twentysomething": ['T W EH2 N T IY0 S AH1 M TH IH0 NG', 'T W EH2 N IY0 S AH1 M TH IH0 NG'],
  "twentysomethings": ['T W EH2 N T IY0 S AH1 M TH IH0 NG Z', 'T W EH2 N IY0 S AH1 M TH IH0 NG Z'],
  "twersky": ['T W ER1 S K IY0'],
  "tweten": ['T W IY1 T AH0 N'],
  "twice": ['T W AY1 S'],
  "twichell": ['T W IH1 CH AH0 L'],
  "twiddle": ['T W IH1 D AH0 L'],
  "twiddling": ['T W IH1 D L IH0 NG'],
  "twiddy": ['T W IH1 D IY0'],
  "twiford": ['T W IH1 F ER0 D'],
  "twig": ['T W IH1 G'],
  "twigg": ['T W IH1 G'],
  "twigged": ['T W IH1 G D'],
  "twiggs": ['T W IH1 G Z'],
  "twiggy": ['T W IH1 G IY0'],
  "twigs": ['T W IH1 G Z'],
  "twilight": ['T W AY1 L AY2 T'],
  "twill": ['T W IH1 L'],
  "twilley": ['T W IH1 L IY0'],
  "twin": ['T W IH1 N'],
  "twine": ['T W AY1 N'],
  "twined": ['T W AY1 N D'],
  "twinge": ['T W IH1 N JH'],
  "twining": ['T W AY1 N IH0 NG'],
  "twinjet": ['T W IH1 N JH EH2 T'],
  "twinjets": ['T W IH1 N JH EH2 T S'],
  "twink": ['T W IH1 NG K'],
  "twinkie": ['T W IH1 NG K IY0'],
  "twinkies": ['T W IH1 NG K IY0 Z'],
  "twinkle": ['T W IH1 NG K AH0 L'],
  "twinkled": ['T W IH1 NG K AH0 L D'],
  "twinkles": ['T W IH1 NG K AH0 L Z'],
  "twinkling": ['T W IH1 NG K AH0 L IH0 NG', 'T W IH1 NG K L IH0 NG'],
  "twinkly": ['T W IH1 NG K AH0 L IY2'],
  "twins": ['T W IH1 N Z'],
  "twins'": ['T W IH1 N Z'],
  "twinsburg": ['T W IH1 N Z B ER0 G'],
  "twirl": ['T W ER1 L'],
  "twirled": ['T W ER1 L D'],
  "twirler": ['T W ER1 L ER0'],
  "twirling": ['T W ER1 L IH0 NG'],
  "twirls": ['T W ER1 L Z'],
  "twiss": ['T W IH1 S'],
  "twist": ['T W IH1 S T'],
  "twisted": ['T W IH1 S T IH0 D'],
  "twister": ['T W IH1 S T ER0'],
  "twister's": ['T W IH1 S T ER0 Z'],
  "twisters": ['T W IH1 S T ER0 Z'],
  "twisting": ['T W IH1 S T IH0 NG'],
  "twists": ['T W IH1 S T S'],
  "twisty": ['T W IH1 S T IY0'],
  "twitch": ['T W IH1 CH'],
  "twitched": ['T W IH1 CH T'],
  "twitchell": ['T W IH1 CH AH0 L'],
  "twitches": ['T W IH1 CH IH0 Z'],
  "twitching": ['T W IH1 CH IH0 NG'],
  "twitchy": ['T W IH1 CH IY2'],
  "twite": ['T W AY1 T'],
  "twitter": ['T W IH1 T ER0'],
  "twitty": ['T W IH1 T IY0'],
  "two": ['T UW1'],
  "two's": ['T UW1 Z'],
  "two-day": ['T UW1 D EY2'],
  "two-dimensional": ['T UW2 D IH0 M EH1 N SH AH0 N AH0 L'],
  "two-thirds": ['T UW2 TH ER1 D Z'],
  "two-way": ['T UW1 W EY2'],
  "twofold": ['T UW1 F OW1 L D'],
  "twohig": ['T W OW1 HH IH0 G'],
  "twombly": ['T W UW1 M B L IY0'],
  "twomey": ['T W AA1 M IY0'],
  "twonshein": ['T W AA1 N SH AY2 N'],
  "twonshein's": ['T W AA1 N SH AY2 N Z'],
  "tworek": ['T W ER1 IH0 K'],
  "twos": ['T UW1 Z'],
  "twosome": ['T UW1 S AH0 M'],
  "twothirds": ['T UW1 TH ER1 D Z'],
  "twyford": ['T W AY1 F ER0 D'],
  "twyla": ['T W AY1 L AH0'],
  "twyman": ['T W AY1 M AH0 N'],
  "ty": ['T AY1'],
  "ty-sachs": ['T AY1 S AE1 K S'],
  "tybalt": ['T IH1 B AH0 L T'],
  "tyburski": ['T AY0 B ER1 S K IY0'],
  "tyce": ['T AY1 S'],
  "tyco": ['T AY1 K OW0'],
  "tyco's": ['T AY1 K OW0 Z'],
  "tycoon": ['T AY0 K UW1 N'],
  "tycoon's": ['T AY0 K UW1 N Z'],
  "tycoons": ['T AY0 K UW1 N Z'],
  "tydings": ['T AY1 D IH0 NG Z'],
  "tye": ['T AY1'],
  "tyer": ['T AY1 ER0'],
  "tyers": ['T AY1 ER0 Z'],
  "tygar": ['T AY1 G AA2 R'],
  "tygart": ['T AY1 G AA2 R T'],
  "tyger": ['T AY1 G ER0'],
  "tying": ['T AY1 IH0 NG'],
  "tyke": ['T AY1 K'],
  "tylan": ['T AY1 L AH0 N'],
  "tylee": ['T AY1 L IY0'],
  "tylenol": ['T AY1 L AH0 N AO2 L'],
  "tylenol's": ['T AY1 L AH0 N AO2 L Z'],
  "tyler": ['T AY1 L ER0'],
  "tyler's": ['T AY1 L ER0 Z'],
  "tylka": ['T IH1 L K AH0'],
  "tyminski": ['T IH0 M IH1 N S K IY0'],
  "tymnet": ['T AY1 M N EH2 T'],
  "tympanic": ['T IH0 M P AE1 N IH0 K'],
  "tympanum": ['T IH1 M P AH0 N AH0 M'],
  "tynan": ['T AY1 N AH0 N'],
  "tyndall": ['T IH1 N D AH0 L'],
  "tyne": ['T AY1 N'],
  "tyner": ['T AY1 N ER0'],
  "tynes": ['T AY1 N Z'],
  "tyo": ['T AY1 OW0', 'T IY1 W AY1 OW1'],
  "type": ['T AY1 P'],
  "typeable": ['T AY1 P AH0 B AH0 L'],
  "typecast": ['T AY1 P K AE2 S T'],
  "typecasting": ['T AY1 P K AE2 S T IH0 NG'],
  "typed": ['T AY1 P T'],
  "typeface": ['T AY1 P F EY2 S'],
  "typefaces": ['T AY1 P F EY2 S AH0 Z'],
  "types": ['T AY1 P S'],
  "typeset": ['T AY1 P S EH2 T'],
  "typesetting": ['T AY1 P S EH2 T IH0 NG'],
  "typewriter": ['T AY1 P R AY2 T ER0'],
  "typewriters": ['T AY1 P R AY2 T ER0 Z'],
  "typewriting": ['T AY1 P R AY2 T IH0 NG'],
  "typewritten": ['T AY1 P R IH2 T AH0 N'],
  "typhoid": ['T AY1 F OY0 D'],
  "typhoon": ['T AY2 F UW1 N'],
  "typhoons": ['T AY2 F UW1 N Z'],
  "typhus": ['T AY1 F AH0 S'],
  "typical": ['T IH1 P AH0 K AH0 L', 'T IH1 P IH0 K AH0 L'],
  "typically": ['T IH1 P IH0 K L IY0', 'T IH1 P IH0 K AH0 L IY0'],
  "typified": ['T IH1 P AH0 F AY2 D'],
  "typifies": ['T IH1 P AH0 F AY2 Z'],
  "typify": ['T IH1 P AH0 F AY2'],
  "typing": ['T AY1 P IH0 NG'],
  "typings": ['T AY1 P IH0 NG Z'],
  "typist": ['T AY1 P IH0 S T'],
  "typists": ['T AY1 P IH0 S T S'],
  "typo": ['T AY1 P OW0'],
  "typographical": ['T AY2 P AH0 G R AE1 F IH0 K AH0 L'],
  "typography": ['T AH0 P AA1 G R AH0 F IY0'],
  "typologies": ['T AY2 P OW1 L AH0 G IH2 Z'],
  "typology": ['T AY2 P OW1 L AH0 G IH2'],
  "tyra": ['T AY1 R AH0'],
  "tyrannical": ['T ER0 AE1 N IH0 K AH0 L'],
  "tyrannies": ['T IH1 R AH0 N IY0 Z'],
  "tyrannized": ['T IH1 R AH0 N AY2 Z D'],
  "tyrannosaurus": ['T IH0 R AE0 N AH0 S AO1 R AH0 S'],
  "tyranny": ['T IH1 R AH0 N IY0'],
  "tyrant": ['T AY1 R AH0 N T'],
  "tyrants": ['T AY1 R AH0 N T S'],
  "tyre": ['T AY1 R'],
  "tyree": ['T AY0 R IY1'],
  "tyrell": ['T AY1 R AH0 L'],
  "tyres": ['T AY1 ER0 Z'],
  "tyrone": ['T AY2 R OW1 N'],
  "tyrrell": ['T AY2 R EH1 L'],
  "tyrrhenian": ['T ER0 IY1 N IY0 AH0 N'],
  "tysinger": ['T AY1 S IH0 N JH ER0'],
  "tyson": ['T AY1 S AH0 N'],
  "tyson's": ['T AY1 S AH0 N Z'],
  "tysons": ['T AY1 S AH0 N Z'],
  "tyus": ['T Y UW1 S'],
  "u": ['Y UW1'],
  "u's": ['Y UW1 Z'],
  "u-turn": ['Y UW1 T ER2 N'],
  "u-turns": ['Y UW1 T ER2 N Z'],
  "u.": ['Y UW1'],
  "u.'s": ['Y UW1 Z'],
  "u.k.": ['Y UW2 K EY1'],
  "u.n.": ['Y UW2 EH1 N'],
  "u.s": ['Y UW1 Z'],
  "u.s.": ['Y UW2 EH1 S'],
  "u.s.a.": ['Y UW2 EH2 S EY1'],
  "u.s.c.": ['Y UW2 EH2 S IY1'],
  "uarco": ['Y UW2 AA1 R K OW0'],
  "uart": ['Y UW1 AA0 R T'],
  "ubben": ['AH1 B AH0 N'],
  "uber": ['Y UW1 B ER0'],
  "ubiquitous": ['Y UW0 B IH1 K W IH0 T AH0 S'],
  "ubiquity": ['Y UW0 B IH1 K W IH0 T IY0'],
  "ubuntu": ['UW2 B UW1 T UW2'],
  "uccel": ['UW1 CH AH0 L'],
  "uccel's": ['UW1 CH AH0 L Z'],
  "ucci": ['UW1 CH IY0'],
  "uchida": ['UW0 K IY1 D AH0'],
  "uclaf": ['AH1 K L AE0 F', 'Y UW1 K L AE0 F'],
  "udagawa": ['UW2 D AA0 G AA1 W AH0'],
  "udale": ['Y UW1 D EY2 L'],
  "udall": ['Y UW1 D AH0 L'],
  "uday": ['UW1 D EY0'],
  "uddin": ['AH1 D IH0 N'],
  "ude": ['UW1 D'],
  "udell": ['AH1 D AH0 L'],
  "udelle": ['AH0 D EH1 L'],
  "uden": ['UW1 D AH0 N'],
  "udo": ['UW1 D OW2'],
  "udolf": ['AH1 D OW0 L F'],
  "udvar": ['AH1 D V AA0 R'],
  "udverhye": ['UW2 D V EH1 R HH IY0', 'Y UW2 D V EH1 R HH IY0'],
  "udverhye's": ['UW2 D V EH1 R HH IY0 Z', 'Y UW2 D V EH1 R HH IY0 Z'],
  "udy": ['Y UW1 D IY0'],
  "uebel": ['UH1 B AH0 L'],
  "ueberroth": ['Y UW1 B ER0 R AO2 TH'],
  "uecker": ['UH1 K ER0'],
  "ueckert": ['UH1 K ER0 T'],
  "ueda": ['W EH1 D AH0', 'UW0 EY1 D AH0'],
  "uehara": ['UW0 EY0 HH AA1 R AH0'],
  "uehling": ['UH1 L IH0 NG'],
  "ueki": ['Y UW2 EH1 K IY0'],
  "ueland": ['UH1 L AH0 N D'],
  "uelman": ['UH1 L M AH0 N'],
  "uelmen": ['UH1 L M EH0 N'],
  "uelmen's": ['UH1 L M EH0 N Z'],
  "uemura": ['UW2 IH0 M UW1 R AH0'],
  "ueno": ['W EH1 N OW0', 'UW0 EY1 N OW0'],
  "uenohara": ['W EH2 N OW0 HH AA1 R AH0', 'UW0 EY2 N OW0 HH AA1 R AH0'],
  "uffelman": ['AH1 F AH0 L M AH0 N'],
  "ufo": ['Y UW2 EH2 F OW1'],
  "ufo's": ['Y UW2 EH2 F OW1 Z'],
  "ufos": ['Y UW2 EH2 F OW1 Z'],
  "ugalde": ['UW0 G AA1 L D IY0'],
  "uganda": ['Y UW0 G AE1 N D AA0', 'Y UW2 G AA1 N D AA0'],
  "uganda's": ['Y UW0 G AE1 N D AH0 Z'],
  "ugandan": ['Y UW2 G AE1 N D AH0 N', 'Y UW2 G AA1 N D AH0 N'],
  "ugarte": ['UW0 G AA1 R T IY0'],
  "ugh": ['AH1 G'],
  "ugity": ['AH1 G IH0 T IY0'],
  "uglier": ['AH1 G L IY0 ER0'],
  "ugliest": ['AH1 G L IY0 AH0 S T'],
  "ugliness": ['AH1 G L IY0 N AH0 S'],
  "ugly": ['AH1 G L IY0'],
  "ugolin": ['Y UW1 G AH0 L IH0 N'],
  "ugolini": ['UW0 G OW0 L IY1 N IY0'],
  "ugric": ['Y UW1 G R IH0 K'],
  "uh": ['AH1'],
  "uh-huh": ['AH1 HH AH1'],
  "uh-oh": ['AH1 OW2'],
  "uhde": ['AH1 D', 'UW1 D AH0'],
  "uher": ['UW1 ER0'],
  "uhh": ['AH1'],
  "uhl": ['AH1 L'],
  "uhland": ['UW1 L AH0 N D'],
  "uhle": ['UW1 AH0 L'],
  "uhler": ['UW1 L ER0'],
  "uhlich": ['UW1 L IH0 K'],
  "uhlig": ['UW1 L IH0 G'],
  "uhlir": ['UW1 L ER0'],
  "uhlman": ['UW1 L M AH0 N'],
  "uhlmann": ['UW1 L M AH0 N'],
  "uhls": ['UW1 L Z'],
  "uhr": ['UH1 R'],
  "uhrich": ['UH1 R IH0 K'],
  "uhrig": ['UH1 R IH0 G'],
  "uhrin": ['UH1 R IH0 N'],
  "ui": ['Y UW1 AY1'],
  "uinta": ['Y UW0 IH1 N T AH0'],
  "uk": ['Y UW2 K EY1'],
  "ukase": ['Y UW1 K EY2 Z'],
  "ukman": ['AH1 K M AH0 N'],
  "ukraine": ['Y UW0 K R EY1 N'],
  "ukraine's": ['Y UW0 K R EY1 N Z'],
  "ukrainian": ['Y UW0 K R EY1 N IY0 AH0 N'],
  "ukrainian's": ['Y UW0 K R EY1 N IY0 AH0 N Z'],
  "ukrainians": ['Y UW0 K R EY1 N IY0 AH0 N Z'],
  "ukulele": ['Y UW2 K AH0 L EY1 L IY0'],
  "ul": ['Y UW1 EH1 L', 'AH1 L'],
  "ula": ['Y UW1 L AH0'],
  "ulaanbaatar": ['UW2 L AA1 N B AH0 T AA1 R', 'Y UW2 L AA1 N B AH0 T AA1 R'],
  "ulam": ['Y UW1 L AE0 M'],
  "uland": ['Y UW1 L AH0 N D'],
  "ulatowski": ['AH0 L AH0 T AO1 F S K IY0'],
  "ulbrich": ['AH1 L B R IH0 K'],
  "ulbricht": ['AH1 L B R IH0 K T'],
  "ulcer": ['AH1 L S ER0'],
  "ulceration": ['AH2 L S ER0 EY1 SH AH0 N'],
  "ulcerations": ['AH2 L S ER0 EY1 SH AH0 N Z'],
  "ulcers": ['AH1 L S ER0 Z'],
  "ulch": ['AH1 L CH'],
  "ulee": ['Y UW0 L IY1'],
  "ulee's": ['Y UW0 L IY1 Z'],
  "ulery": ['AH0 L ER1 IY0'],
  "ulf": ['AH1 L F'],
  "ulfred": ['AH1 L F ER0 D'],
  "ulger": ['AH1 L G ER0'],
  "uli": ['Y UW1 L IY0'],
  "uliano": ['UW0 L IY0 AA1 N OW0'],
  "uliaski": ['Y UW0 L Y AE1 S K IY0'],
  "uliassi": ['Y UW0 L Y AE1 S IY0'],
  "uliassi's": ['Y UW0 L Y AE1 S IY0 Z'],
  "ulibarri": ['UW0 L IY0 B AA1 R IY0'],
  "ulicny": ['AH0 L IH1 K N IY0'],
  "ulima": ['UW0 L IY1 M AH0'],
  "ulin": ['Y UW1 L IH0 N'],
  "ulitsa": ['Y UW0 L IH1 T S AH0'],
  "ulland": ['AH1 L AH0 N D'],
  "ullenberg": ['Y UW1 L AH0 N B ER0 G'],
  "ullery": ['UW1 L ER0 IY0'],
  "ullman": ['AH1 L M AH0 N'],
  "ullmann": ['AH1 L M AH0 N'],
  "ullmer": ['AH1 L M ER0'],
  "ulloa": ['UW0 L OW1 AH0'],
  "ullock": ['AH1 L AH0 K'],
  "ullom": ['AH1 L AH0 M'],
  "ullrich": ['UH1 L R IH0 K'],
  "ullyses": ['Y UW0 L IH1 S IY0 Z'],
  "ulm": ['AH1 L M'],
  "ulman": ['AH1 L M AH0 N'],
  "ulmar": ['AH1 L M ER0'],
  "ulmer": ['AH1 L M ER0'],
  "ulmus": ['AH1 L M AH0 S'],
  "ulrey": ['AH1 L R IY0'],
  "ulric": ['AH1 L R IH0 K'],
  "ulrica": ['AH1 L R IH0 K AH0'],
  "ulrich": ['AH1 L R IH0 CH'],
  "ulrick": ['AH1 L R IH0 K'],
  "ulsan": ['AH1 L S AH0 N'],
  "ulsh": ['AH1 L SH'],
  "ulshafer": ['AH1 L SH AH0 F ER0'],
  "ulster": ['AH1 L S T ER0'],
  "ulterior": ['AH0 L T IH1 R IY0 ER0'],
  "ultima": ['AH1 L T IH0 M AH0'],
  "ultimate": ['AH1 L T AH0 M AH0 T'],
  "ultimately": ['AH1 L T AH0 M AH0 T L IY0'],
  "ultimatum": ['AH2 L T AH0 M EY1 T AH0 M'],
  "ultimatums": ['AH2 L T AH0 M EY1 T AH0 M Z'],
  "ultra": ['AH1 L T R AH0'],
  "ultra's": ['AH1 L T R AH0 Z'],
  "ultraconservative": ['AH2 L T R AH0 K AH0 N S ER1 V AH0 T IH0 V'],
  "ultrair": ['AH1 L T R EH2 R'],
  "ultramar": ['AH1 L T R AH0 M AA0 R'],
  "ultramodern": ['AH2 L T R AH0 M AA1 D ER0 N'],
  "ultranationalist": ['AH2 L T R AH0 N AE1 SH AH0 N AH0 L AH0 S T'],
  "ultranationalist's": ['AH2 L T R AH0 N AE1 SH AH0 N AH0 L AH0 S T S'],
  "ultranationalists": ['AH2 L T R AH0 N AE1 SH AH0 N AH0 L AH0 S T S'],
  "ultrasonic": ['AH2 L T R AH0 S AA1 N IH0 K'],
  "ultrasound": ['AH2 L T R AH0 S AW1 N D'],
  "ultrasystem": ['AH1 L T R AH0 S IH2 S T AH0 M'],
  "ultrasystems": ['AH1 L T R AH0 S IH2 S T AH0 M Z'],
  "ultraviolet": ['AH2 L T R AH0 V AY1 AH0 L IH0 T'],
  "ulundi": ['Y UW0 L AH1 N D IY0'],
  "ulva": ['UW1 L V AH0'],
  "ulvert": ['AH1 L V ER0 T'],
  "ulysses": ['Y UW0 L IH1 S IY0 Z'],
  "um": ['AH1 M'],
  "uma": ['Y UW1 M AH0'],
  "umana": ['UW0 M AA1 N AH0'],
  "umansky": ['AH0 M AE1 N S K IY0'],
  "umass": ['Y UW1 M AE1 S'],
  "umbach": ['AH1 M B AA2 K'],
  "umbarger": ['AH1 M B AA2 R G ER0'],
  "umbaugh": ['AH1 M B AW0'],
  "umbel": ['AH1 M B AH0 L'],
  "umberger": ['AH1 M B ER0 G ER0'],
  "umberto": ['AH0 M B EH1 R T OW2'],
  "umbilical": ['AH0 M B IH1 L IH0 K AH0 L'],
  "umble": ['AH1 M B AH0 L'],
  "umbra": ['AH1 M B R AH0'],
  "umbrage": ['AH1 M B R IH0 JH'],
  "umbrella": ['AH0 M B R EH1 L AH0', 'AH1 M B R EH2 L AH0'],
  "umbrellas": ['AH0 M B R EH1 L AH0 Z'],
  "umbria": ['AH1 M B R IY0 AH0'],
  "umetsu": ['Y UW2 M EH1 T S UW0'],
  "umfleet": ['AH1 M F L IY2 T'],
  "umholtz": ['AH1 M HH OW2 L T S'],
  "umland": ['AH1 M L AH0 N D'],
  "umlasi": ['UW2 M L AA1 S IY0'],
  "umlauf": ['AH1 M L AW0 F'],
  "umm": ['AH1 M'],
  "ummel": ['AH1 M AH0 L'],
  "umphlett": ['AH0 M F L EH1 T'],
  "umphress": ['AH1 M F R IH0 S'],
  "umphrey": ['AH1 M F R IY0'],
  "umpire": ['AH1 M P AY2 ER0'],
  "umpires": ['AH1 M P AY2 ER0 Z'],
  "umpteen": ['AH1 M P T IY1 N'],
  "umpteenth": ['AH1 M P T IY2 N TH'],
  "umscheid": ['AH1 M SH AY2 D'],
  "umstead": ['AH1 M S T EH2 D'],
  "un": ['AH1 N', 'Y UW1 EH1 N'],
  "una": ['UW1 N AH0'],
  "unabashed": ['AH2 N AH0 B AE1 SH T'],
  "unabashedly": ['AH2 N AH0 B AE1 SH IH0 D L IY0'],
  "unabated": ['AH2 N AH0 B EY1 T IH0 D'],
  "unable": ['AH0 N EY1 B AH0 L'],
  "unabom": ['Y UW1 N AH0 B AO2 M'],
  "unabomb": ['Y UW1 N AH0 B AO2 M'],
  "unabomb's": ['Y UW1 N AH0 B AO2 M Z'],
  "unabomber": ['Y UW1 N AH0 B AO2 M ER0'],
  "unabomber's": ['Y UW1 N AH0 B AO2 M ER0 Z'],
  "unabombers": ['Y UW1 N AH0 B AO2 M ER0 Z'],
  "unabombing": ['Y UW1 N AH0 B AO2 M IH0 NG'],
  "unabombings": ['Y UW1 N AH0 B AO2 M IH0 NG Z'],
  "unabridged": ['AH2 N AH0 B R IH1 JH D'],
  "unacceptable": ['AH2 N AE0 K S EH1 P T AH0 B AH0 L'],
  "unacceptably": ['AH2 N AE0 K S EH1 P T AH0 B L IY0'],
  "unaccompanied": ['AH2 N AH0 K AH1 M P AH0 N IY0 D'],
  "unaccountable": ['AH2 N AH0 K AW1 N T AH0 B AH0 L'],
  "unaccountably": ['AH2 N AH0 K AW1 N T AH0 B L IY0'],
  "unaccounted": ['AH2 N AH0 K AW1 N T IH0 D'],
  "unaccredited": ['AH2 N AH0 K R EH1 D IH2 T IH0 D'],
  "unaccustomed": ['AH2 N AH0 K AH1 S T AH0 M D'],
  "unaddressed": ['AH2 N AH0 D R EH1 S T'],
  "unadjust": ['AH2 N AH0 JH AH1 S T'],
  "unadjustable": ['AH2 N AH0 JH AH1 S T IH0 B AH0 L'],
  "unadjusted": ['AH2 N AH0 JH AH1 S T IH0 D'],
  "unadorn": ['AH2 N AH0 D AO1 R N'],
  "unadorned": ['AH2 N AH0 D AO1 R N D'],
  "unadulterated": ['AH2 N AH0 D AH1 L T ER0 EY2 T IH0 D'],
  "unaffected": ['AH2 N AH0 F EH1 K T IH0 D'],
  "unaffiliate": ['AH0 N AH0 F IH1 L IY0 EY2 T'],
  "unaffiliated": ['AH0 N AH0 F IH1 L IY0 EY2 T IH0 D'],
  "unaffordable": ['AH0 N AH0 F AO1 R D AH0 B AH0 L'],
  "unafraid": ['AH2 N AH0 F R EY1 D'],
  "unaided": ['AH0 N EY1 D IH0 D'],
  "unalienable": ['AH0 N EY1 L IY0 EH2 N AH0 B AH0 L', 'AH0 N EY1 L Y EH0 N AH0 B AH0 L'],
  "unallocate": ['AH0 N AE1 L AH0 K EY2 T'],
  "unallocated": ['AH0 N AE1 L AH0 K EY2 T IH0 D'],
  "unallowable": ['AH2 N AH0 L AW1 AH0 B AH0 L'],
  "unalloyed": ['AH0 N AE1 L OY2 D'],
  "unalterable": ['AH0 N AO1 L T ER0 AH0 B AH0 L'],
  "unalterably": ['AH0 N AO1 L T ER0 AH0 B L IY0'],
  "unaltered": ['AH0 N AO1 L T ER0 D'],
  "unambiguous": ['AH2 N AE0 M B IH1 G Y AH0 W AH0 S'],
  "unambiguously": ['AH0 N AE0 M B IH1 G Y UW0 AH0 S L IY0'],
  "unamended": ['AH0 N AH0 M EH1 N D IH0 D'],
  "unamerican": ['AH0 N AH0 M EH1 R IH0 K AH0 N'],
  "unamortized": ['AH0 N AE1 M ER0 T AY2 Z D'],
  "unangst": ['AO1 N AH0 NG S T'],
  "unanimity": ['Y UW2 N AH0 N IH1 M AH0 T IY0', 'Y UW2 N AH0 N IH1 M IH0 T IY0'],
  "unanimous": ['Y UW0 N AE1 N AH0 M AH0 S'],
  "unanimously": ['Y UW0 N AE1 N AH0 M AH0 S L IY0'],
  "unannounced": ['AH2 N AH0 N AW1 N S T'],
  "unanswerable": ['AH2 N AE1 N S ER0 AH0 B AH0 L'],
  "unanswered": ['AH2 N AE1 N S ER0 D'],
  "unanticipated": ['AH2 N AE0 N T IH1 S AH0 P EY2 T IH0 D'],
  "unapologetic": ['AH2 N AH0 P AA2 L AH0 JH EH1 T IH0 K'],
  "unappealing": ['AH2 N AH0 P IY1 L IH0 NG'],
  "unappetizing": ['AH2 N AE1 P AH0 T AY2 Z IH0 NG'],
  "unappreciated": ['AH2 N AH0 P R IY1 SH IY0 EY2 T IH0 D'],
  "unapproachable": ['AH2 N AH0 P R OW1 CH AH0 B AH0 L'],
  "unapproved": ['AH2 N AH0 P R UW1 V D'],
  "unarm": ['AH0 N AA1 R M'],
  "unarmed": ['AH0 N AA1 R M D'],
  "unashamed": ['AH2 N AH0 SH EY1 M D'],
  "unashamedly": ['AH2 N AH0 SH EY1 M AH0 D L IY0'],
  "unassailable": ['AH2 N AH0 S EY1 L AH0 B AH0 L'],
  "unassisted": ['AH2 N AH0 S IH1 S T IH0 D'],
  "unassuming": ['AH2 N AH0 S UW1 M IH0 NG'],
  "unattach": ['AH2 N AH0 T AE1 CH'],
  "unattached": ['AH2 N AH0 T AE1 CH T'],
  "unattainable": ['AH2 N AH0 T EY1 N AH0 B AH0 L'],
  "unattended": ['AH0 N AH0 T EH1 N D IH0 D'],
  "unattractive": ['AH2 N AH0 T R AE1 K T IH0 V'],
  "unaudited": ['AH0 N AO1 D AH0 T IH0 D'],
  "unauthentic": ['AH2 N AO0 TH EH1 N T IH0 K'],
  "unauthorized": ['AH0 N AO1 TH ER0 AY2 Z D'],
  "unavailability": ['AH0 N AH0 V EY2 L AH0 B IH1 L IH0 T IY0'],
  "unavailable": ['AH2 N AH0 V EY1 L AH0 B AH0 L'],
  "unavailing": ['AH2 N AH0 V EY1 L IH0 NG'],
  "unavoidable": ['AH2 N AH0 V OY1 D AH0 B AH0 L'],
  "unavoidably": ['AH2 N AH0 V OY1 D AH0 B L IY0'],
  "unaware": ['AH2 N AH0 W EH1 R'],
  "unawares": ['AH2 N AH0 W EH1 R Z'],
  "unbalance": ['AH0 N B AE1 L AH0 N S'],
  "unbalanced": ['AH0 N B AE1 L AH0 N S T'],
  "unbearable": ['AH0 N B EH1 R AH0 B AH0 L'],
  "unbearably": ['AH0 N B EH1 R AH0 B L IY0'],
  "unbeatable": ['AH2 N B IY1 T AH0 B AH0 L'],
  "unbeaten": ['AH0 N B IY1 T AH0 N'],
  "unbecoming": ['AH2 N B IH0 K AH1 M IH0 NG', 'AH2 N B IY0 K AH1 M IH0 NG'],
  "unbeknown": ['AH2 N B IH0 N OW1 N', 'AH2 N B IY0 N OW1 N'],
  "unbeknownst": ['AH0 N B IY0 N OW1 N S T'],
  "unbelievable": ['AH2 N B AH0 L IY1 V AH0 B AH0 L'],
  "unbelievably": ['AH2 N B AH0 L IY1 V AH0 B L IY0'],
  "unbelieving": ['AH2 N B AH0 L IY1 V IH0 NG'],
  "unbend": ['AH2 N B EH1 N D'],
  "unbending": ['AH2 N B EH1 N D IH0 NG'],
  "unbiased": ['AH2 N B AY1 AH0 S T'],
  "unblemished": ['AH0 N B L EH1 M IH0 SH T'],
  "unbolt": ['AH1 N B OW2 L T'],
  "unbolted": ['AH1 N B OW2 L T IH0 D'],
  "unbook": ['AH0 N B UH1 K'],
  "unborn": ['AH1 N B AO1 R N'],
  "unbound": ['AH0 N B AW1 N D'],
  "unbounded": ['AH0 N B AW1 N D IH0 D'],
  "unbowed": ['AH0 N B OW1 D', 'AH0 N B AW1 D'],
  "unbox": ['AH0 N B AO1 K S'],
  "unboxing": ['AH0 N B AO1 K S IH2 NG'],
  "unbranded": ['AH0 N B R AE1 N D IH0 D'],
  "unbreakability": ['AH0 N B R EY2 K AH0 B IH1 L IH0 T IY0'],
  "unbreakable": ['AH0 N B R EY1 K AH0 B AH0 L'],
  "unbridled": ['AH0 N B R AY1 D AH0 L D'],
  "unbroken": ['AH0 N B R OW1 K AH0 N'],
  "unbuildable": ['AH0 N B IH1 L D AH0 B AH0 L'],
  "unbuilt": ['AH2 N B IH1 L T'],
  "unbundle": ['AH0 N B AH1 N D AH0 L'],
  "unbundled": ['AH0 N B AH1 N D AH0 L D'],
  "unbundling": ['AH0 N B AH1 N D L IH0 NG'],
  "unburden": ['AH0 N B ER1 D AH0 N'],
  "unburdened": ['AH0 N B ER1 D AH0 N D'],
  "unburned": ['AH0 N B ER1 N D'],
  "unbutton": ['AH0 N B AH1 T AH0 N'],
  "unbuttoned": ['AH0 N B AH1 T AH0 N D'],
  "uncalled": ['AH0 N K AO1 L D'],
  "uncannily": ['AH0 N K AE1 N AH0 L IY0'],
  "uncanny": ['AH0 N K AE1 N IY0'],
  "uncapher": ['AH1 N K AH0 F ER0'],
  "uncapitalized": ['AH0 N K AE1 P IH0 T AH0 L AY0 Z D'],
  "uncaring": ['AH0 N K EH1 R IH0 NG'],
  "unceasing": ['AH0 N S IY1 S IH0 NG'],
  "uncensored": ['AH0 N S EH1 N S ER0 D'],
  "unceremonious": ['AH2 N S EH2 R AH0 M OW1 N IY0 AH0 S'],
  "unceremoniously": ['AH2 N S EH2 R AH0 M OW1 N IY0 AH0 S L IY0'],
  "uncertain": ['AH0 N S ER1 T AH0 N'],
  "uncertainly": ['AH0 N S ER1 T AH0 N L IY0'],
  "uncertainties": ['AH0 N S ER1 T AH0 N T IY0 Z'],
  "uncertainty": ['AH0 N S ER1 T AH0 N T IY0'],
  "unchallenged": ['AH0 N CH AE1 L IH0 N JH D'],
  "unchanged": ['AH0 N CH EY1 N JH D'],
  "unchanging": ['AH0 N CH EY1 N JH IH0 NG'],
  "uncharacteristic": ['AH2 N K EH2 R IH0 K T ER0 IH1 S T IH0 K'],
  "uncharacteristically": ['AH2 N K EH2 R IH0 K T ER0 IH1 S T IH0 K L IY0'],
  "uncharted": ['AH0 N CH AA1 R T IH0 D'],
  "unchartered": ['AH0 N CH AA1 R T ER0 D'],
  "unchecked": ['AH0 N CH EH1 K T'],
  "uncivil": ['AH0 N S IH1 V AH0 L'],
  "uncivilized": ['AH0 N S IH1 V AH0 L AY0 Z D'],
  "unclaimed": ['AH0 N K L EY1 M D'],
  "unclamp": ['AH0 N K L AE1 M P'],
  "unclamps": ['AH0 N K L AE1 M P S'],
  "unclassified": ['AH0 N K L AE1 S IH0 F AY2 D'],
  "unclassify": ['AH0 N K L AE1 S IH0 F AY2'],
  "uncle": ['AH1 NG K AH0 L'],
  "uncle's": ['AH1 NG K AH0 L Z'],
  "unclean": ['AH0 N K L IY1 N'],
  "unclear": ['AH0 N K L IH1 R'],
  "uncles": ['AH1 NG K AH0 L Z'],
  "uncluttered": ['AH0 N K L AH1 T ER0 D'],
  "uncoat": ['AH0 N K OW1 T'],
  "uncoated": ['AH0 N K OW1 T IH0 D'],
  "uncoil": ['AH2 N K OY1 L'],
  "uncoiled": ['AH2 N K OY1 L D'],
  "uncollectable": ['AH0 N K AH0 L EH1 K T AH0 B AH0 L'],
  "uncollected": ['AH0 N K AH0 L EH1 K T IH0 D'],
  "uncollectible": ['AH0 N K AH0 L EH1 K T IH0 B AH0 L'],
  "uncomfortable": ['AH0 N K AH1 M F ER0 T AH0 B AH0 L'],
  "uncomfortably": ['AH0 N K AH1 M F ER0 T AH0 B L IY0'],
  "uncommitted": ['AH2 N K AH0 M IH1 T IH0 D'],
  "uncommon": ['AH0 N K AA1 M AH0 N'],
  "uncommonly": ['AH2 N K AA1 M AH0 N L IY0'],
  "uncompensate": ['AH0 N K AA1 M P AH0 N S EY2 T'],
  "uncompensated": ['AH0 N K AA1 M P AH0 N S EY2 T IH0 D'],
  "uncompetitive": ['AH0 N K AH0 M P EH1 T AH0 T IH0 V'],
  "uncomplete": ['AH2 N K AH0 M P L IY1 T'],
  "uncompleted": ['AH2 N K AH0 M P L IY1 T IH0 D'],
  "uncomplicate": ['AH0 N K AA1 M P L AH0 K EY2 T'],
  "uncomplicated": ['AH0 N K AA1 M P L AH0 K EY2 T IH0 D'],
  "uncompromising": ['AH0 N K AA1 M P R AH0 M AY0 Z IH0 NG'],
  "unconcealed": ['AH2 N K AH0 N S IY1 L D'],
  "unconcern": ['AH2 N K AH0 N S ER1 N'],
  "unconcerned": ['AH2 N K AH0 N S ER1 N D'],
  "unconditional": ['AH2 N K AH0 N D IH1 SH AH0 N AH0 L'],
  "unconditionally": ['AH2 N K AH0 N D IH1 SH AH0 N AH0 L IY0', 'AH2 N K AH0 N D IH1 SH N AH0 L IY0'],
  "unconfined": ['AH2 N K AH0 N F AY1 N D'],
  "unconfirmed": ['AH2 N K AH0 N F ER1 M D'],
  "unconnected": ['AH2 N K AH0 N EH1 K T IH0 D'],
  "unconscionable": ['AH0 N K AA1 N SH AH0 N AH0 B AH0 L'],
  "unconscious": ['AH2 N K AA1 N SH AH0 S'],
  "unconsciously": ['AH2 N K AA1 N SH AH0 S L IY0'],
  "unconsciousness": ['AH2 N K AA1 N SH AH0 S N IH0 S'],
  "unconsolidated": ['AH0 N K AH0 N S AA1 L AH0 D EY2 T IH0 D'],
  "unconstitutional": ['AH2 N K AA2 N S T AH0 T UW1 SH AH0 N AH0 L'],
  "unconstitutionally": ['AH2 N K AA2 N S T AH0 T UW1 SH AH0 N AH0 L IY0', 'AH2 N K AA2 N S T AH0 T UW1 SH N AH0 L IY0'],
  "unconstrained": ['AH2 N K AH0 N S T R EY1 N D'],
  "uncontaminated": ['AH2 N K AH0 N T AE1 M AH0 N EY2 T IH0 D'],
  "uncontested": ['AH2 N K AH0 N T EH1 S T IH0 D'],
  "uncontradicted": ['AH2 N K AO0 N T R AH0 D IH1 K T IH0 D'],
  "uncontrollable": ['AH2 N K AH0 N T R OW1 L AH0 B AH0 L'],
  "uncontrollably": ['AH2 N K AH0 N T R OW1 L AH0 B L IY0'],
  "uncontrolled": ['AH2 N K AH0 N T R OW1 L D'],
  "uncontroversial": ['AH2 N K AA2 N T R AH0 V ER1 SH AH0 L'],
  "unconventional": ['AH2 N K AH0 N V EH1 N SH AH0 N AH0 L'],
  "unconverted": ['AH2 N K AH0 N V ER1 T IH0 D'],
  "unconvinced": ['AH2 N K AH0 N V IH1 N S T'],
  "unconvincing": ['AH2 N K AH0 N V IH1 N S IH0 NG'],
  "uncool": ['AH2 N K UW1 L'],
  "uncooperative": ['AH0 N K OW0 AA1 P ER0 AH0 T IH0 V'],
  "uncoordinated": ['AH0 N K OW0 AO1 R D AH0 N EY0 T IH0 D'],
  "uncork": ['AH0 N K AO1 R K'],
  "uncorked": ['AH0 N K AO1 R K T'],
  "uncorks": ['AH0 N K AO1 R K S'],
  "uncorrected": ['AH2 N K ER0 EH1 K T IH0 D'],
  "uncorroborated": ['AH2 N K ER0 AA1 B ER0 EY0 T IH0 D'],
  "uncounted": ['AH2 N K AW1 N T IH0 D'],
  "uncouple": ['AH0 N K AH1 P AH0 L'],
  "uncouth": ['AH1 N K UW1 TH'],
  "uncover": ['AH0 N K AH1 V ER0'],
  "uncovered": ['AH0 N K AH1 V ER0 D'],
  "uncovering": ['AH0 N K AH1 V ER0 IH0 NG'],
  "uncovers": ['AH2 N K AH1 V ER0 Z'],
  "uncritical": ['AH0 N K R IH1 T IH0 K AH0 L'],
  "uncritically": ['AH0 N K R IH1 T IH0 K AH0 L IY0', 'AH0 N K R IH1 T IH0 K L IY0'],
  "unctad": ['AH1 N K T AE2 D'],
  "unctuous": ['AH1 NG CH W AH0 S'],
  "uncured": ['AH2 N K Y ER1 D'],
  "uncut": ['AH2 N K AH1 T'],
  "und": ['AH1 N D'],
  "undamaged": ['AH2 N D AE1 M AH0 JH D'],
  "undated": ['AH2 N D EY1 T IH0 D'],
  "undaunted": ['AH0 N D AO1 N T IH0 D'],
  "undecided": ['AH2 N D IH0 S AY1 D IH0 D'],
  "undecideds": ['AH2 N D IH0 S AY1 D IH0 D Z'],
  "undeclared": ['AH0 N D IH0 K L EH1 R D'],
  "undefeated": ['AH2 N D IH0 F IY1 T IH0 D'],
  "undefined": ['AH2 N D IH0 F AY1 N D'],
  "undelivered": ['AH2 N D IH0 L IH1 V ER0 D'],
  "undemocratic": ['AH2 N D EH0 M AH0 K R AE1 T IH0 K'],
  "undeniable": ['AH2 N D IH0 N AY1 AH0 B AH0 L'],
  "undeniably": ['AH2 N D IH0 N AY1 AH0 B L IY0'],
  "under": ['AH1 N D ER0'],
  "under-age": ['AH1 N D ER0 EY1 JH'],
  "underachiever": ['AH1 N D ER0 AH0 CH IY2 V ER0'],
  "underachievers": ['AH1 N D ER0 AH0 CH IY2 V ER0 Z'],
  "underage": ['AH1 N D ER0 IH0 JH'],
  "underarm": ['AH2 N D ER0 AA1 R M'],
  "underbelly": ['AH1 N D ER0 B EH2 L IY0'],
  "underberg": ['AH1 N D ER0 B ER0 G'],
  "underbid": ['AH1 N D ER0 B IH2 D'],
  "underbrush": ['AH1 N D ER0 B R AH2 SH'],
  "undercapitalize": ['AH0 N D ER0 K AE1 P AH0 T AH0 L AY2 Z'],
  "undercapitalized": ['AH0 N D ER0 K AE1 P AH0 T AH0 L AY2 Z D'],
  "undercarriage": ['AH1 N D ER0 K AE2 R IH0 JH'],
  "underclass": ['AH1 N D ER0 K L AE2 S'],
  "underclassmen": ['AH2 N D ER0 K L AE1 S M EH0 N'],
  "undercoat": ['AH1 N D ER0 K OW2 T'],
  "undercook": ['AH0 N D ER0 K UH1 K'],
  "undercooked": ['AH0 N D ER0 K UH1 K T'],
  "undercount": ['AH1 N D ER0 K AW2 N T'],
  "undercounted": ['AH1 N D ER0 K AW2 N T IH0 D'],
  "undercover": ['AH2 N D ER0 K AH1 V ER0'],
  "undercurrent": ['AH1 N D ER0 K ER2 AH0 N T'],
  "undercurrents": ['AH1 N D ER0 K ER2 AH0 N T S'],
  "undercut": ['AH1 N D ER0 K AH2 T'],
  "undercuts": ['AH1 N D ER0 K AH2 T S'],
  "undercutting": ['AH1 N D ER0 K AH2 T IH0 NG'],
  "underdahl": ['AH1 N D ER0 D AA2 L'],
  "underdevelop": ['AH2 N D ER0 D IH0 V EH1 L AH0 P'],
  "underdeveloped": ['AH2 N D ER0 D IH0 V EH1 L AH0 P T'],
  "underdevelopment": ['AH2 N D ER0 D AH0 V EH1 L AH0 P M AH0 N T'],
  "underdog": ['AH1 N D ER0 D AO2 G'],
  "underdogs": ['AH1 N D ER0 D AO2 G Z'],
  "underdown": ['AH1 N D ER0 D AW2 N'],
  "underemploy": ['AH1 N D ER0 IH0 M P L OY1'],
  "underemployed": ['AH1 N D ER0 IH0 M P L OY1 D'],
  "underemployment": ['AH2 N D ER0 IH0 M P L OY1 M AH0 N T'],
  "underestimate": ['AH1 N D ER0 EH1 S T AH0 M EY2 T', 'AH1 N D ER0 EH1 S T AH0 M AH0 T'],
  "underestimated": ['AH1 N D ER0 EH1 S T AH0 M EY2 T IH0 D'],
  "underestimates": ['AH2 N D ER0 EH1 S T IH0 M IH0 T S', 'AH2 N D ER0 EH1 S T IH0 M EY0 T S'],
  "underestimating": ['AH2 N D ER0 EH1 S T IH0 M EY2 T IH0 NG'],
  "underfinance": ['AH2 N D ER0 F IH0 N AE1 N S'],
  "underfinanced": ['AH2 N D ER0 F IH0 N AE1 N S T'],
  "underfoot": ['AH2 N D ER0 F UH1 T'],
  "underfund": ['AH1 N D ER0 F AH2 N D'],
  "underfunded": ['AH1 N D ER0 F AH2 N D IH0 D'],
  "underfunding": ['AH1 N D ER0 F AH2 N D IH0 NG'],
  "undergarment": ['AH1 N D ER0 G AA2 R M AH0 N T'],
  "undergarments": ['AH1 N D ER0 G AA2 R M AH0 N T S'],
  "undergo": ['AH2 N D ER0 G OW1'],
  "undergoes": ['AH1 N D ER0 G OW2 Z'],
  "undergoing": ['AH2 N D ER0 G OW1 IH0 NG'],
  "undergone": ['AH2 N D ER0 G AO1 N'],
  "undergrad": ['AH1 N D ER0 G R AE2 D'],
  "undergraduate": ['AH2 N D ER0 G R AE1 JH AH0 W AH0 T'],
  "undergraduates": ['AH2 N D ER0 G R AE1 JH AH0 W AH0 T S'],
  "underground": ['AH1 N D ER0 G R AW2 N D'],
  "undergrowth": ['AH1 N D ER0 G R OW2 TH'],
  "underhanded": ['AH1 N D ER0 HH AE1 N D IH0 D'],
  "underhill": ['AH1 N D ER0 HH IH2 L'],
  "underinsure": ['AH2 N D ER0 IH0 N SH AO1 R'],
  "underinsured": ['AH2 N D ER0 IH0 N SH AO1 R D'],
  "underkoffler": ['AH1 N D ER0 K AH0 F AH0 L ER0'],
  "underlie": ['AH2 N D ER0 L AY1'],
  "underlies": ['AH2 N D ER0 L AY1 Z'],
  "underline": ['AH1 N D ER0 L AY2 N'],
  "underlined": ['AH1 N D ER0 L AY2 N D'],
  "underlines": ['AH1 N D ER0 L AY2 N Z'],
  "underling": ['AH1 N D ER0 L IH0 NG'],
  "underlings": ['AH1 N D ER0 L IH0 NG Z'],
  "underlining": ['AH1 N D ER0 L AY2 N IH0 NG'],
  "underly": ['AH2 N D ER0 L AY1'],
  "underlying": ['AH2 N D ER0 L AY1 IH0 NG'],
  "underman": ['AH1 N D ER0 M AE2 N'],
  "undermanned": ['AH1 N D ER0 M AE2 N D'],
  "undermine": ['AH1 N D ER0 M AY2 N'],
  "undermined": ['AH2 N D ER0 M AY1 N D'],
  "undermines": ['AH2 N D ER0 M AY1 N Z'],
  "undermining": ['AH1 N D ER0 M AY2 N IH0 NG'],
  "underneath": ['AH2 N D ER0 N IY1 TH'],
  "undernourish": ['AH2 N D ER0 N ER1 IH0 SH'],
  "undernourished": ['AH2 N D ER0 N ER1 IH0 SH T'],
  "underpaid": ['AH1 N D ER0 P EY1 D'],
  "underpants": ['AH1 N D ER0 P AE2 N T S'],
  "underpass": ['AH1 N D ER0 P AE2 S'],
  "underpay": ['AH2 N D ER0 P EY1'],
  "underpaying": ['AH1 N D ER0 P EY2 IH0 NG'],
  "underpayment": ['AH1 N D ER0 P EY2 M AH0 N T'],
  "underpayments": ['AH1 N D ER0 P EY2 M AH0 N T S'],
  "underperform": ['AH1 N D ER0 P ER0 F AO2 R M'],
  "underperformance": ['AH0 N D ER0 P ER0 F AO1 R M AH0 N S'],
  "underperformed": ['AH1 N D ER0 P ER0 F AO2 R M D'],
  "underperformer": ['AH1 N D ER0 P ER0 F AO2 R M ER0'],
  "underperforming": ['AH1 N D ER0 P ER0 F AO2 R M IH0 NG'],
  "underpin": ['AH1 N D ER0 P IH2 N'],
  "underpinned": ['AH1 N D ER0 P IH2 N D'],
  "underpinning": ['AH1 N D ER0 P IH2 N IH0 NG'],
  "underpinnings": ['AH1 N D ER0 P IH2 N IH0 NG Z'],
  "underplay": ['AH2 N D ER0 P L EY1'],
  "underplayed": ['AH2 N D ER0 P L EY1 D'],
  "underpower": ['AH2 N D ER0 P AW1 ER0'],
  "underpowered": ['AH2 N D ER0 P AW1 ER0 D'],
  "underprice": ['AH1 N D ER0 P R AY2 S'],
  "underpriced": ['AH1 N D ER0 P R AY2 S T'],
  "underpricing": ['AH1 N D ER0 P R AY2 S IH0 NG'],
  "underprivileged": ['AH1 N D ER0 P R IH1 V L AH0 JH D'],
  "underrate": ['AH2 N D ER0 R EY1 T'],
  "underrated": ['AH2 N D ER0 R EY1 T IH0 D'],
  "underreport": ['AH2 N D ER0 R IH0 P AO1 R T'],
  "underreported": ['AH2 N D ER0 R IH0 P AO1 R T IH0 D'],
  "underreporting": ['AH1 N D ER0 R IH0 P AO1 R T IH0 NG'],
  "underrepresent": ['AH2 N D ER0 R EH2 P R IH0 Z EH1 N T'],
  "underrepresented": ['AH0 N D ER0 R EH2 P R IH0 Z EH1 N T IH0 D'],
  "underscore": ['AH2 N D ER0 S K AO1 R'],
  "underscored": ['AH2 N D ER0 S K AO1 R D'],
  "underscores": ['AH2 N D ER0 S K AO1 R Z'],
  "underscoring": ['AH2 N D ER0 S K AO1 R IH0 NG'],
  "undersea": ['AH2 N D ER0 S IY1'],
  "underseas": ['AH2 N D ER0 S IY1 Z'],
  "undersecretary": ['AH2 N D ER0 S EH1 K R IH0 T EH2 R IY0'],
  "undersell": ['AH1 N D ER0 S EH2 L'],
  "underselling": ['AH1 N D ER0 S EH2 L IH0 NG'],
  "underserve": ['AH2 N D ER0 S ER1 V'],
  "underserved": ['AH2 N D ER0 S ER1 V D'],
  "undershirt": ['AH1 N D ER0 SH ER2 T'],
  "undershirts": ['AH1 N D ER0 SH ER2 T S'],
  "underside": ['AH1 N D ER0 S AY2 D'],
  "undersized": ['AH1 N D ER0 S AY2 Z D'],
  "undersold": ['AH2 N D ER0 S OW1 L D'],
  "underspin": ['AH1 N D ER0 S P IH2 N'],
  "understaff": ['AH1 N D ER0 S T AE2 F'],
  "understaffed": ['AH1 N D ER0 S T AE2 F T'],
  "understand": ['AH2 N D ER0 S T AE1 N D'],
  "understandable": ['AH2 N D ER0 S T AE1 N D AH0 B AH0 L'],
  "understandably": ['AH2 N D ER0 S T AE1 N D AH0 B L IY0'],
  "understanding": ['AH2 N D ER0 S T AE1 N D IH0 NG'],
  "understandings": ['AH2 N D ER0 S T AE1 N D IH0 NG Z'],
  "understands": ['AH2 N D ER0 S T AE1 N D Z'],
  "understate": ['AH1 N D ER0 S T EY2 T'],
  "understated": ['AH1 N D ER0 S T EY2 T IH0 D'],
  "understatement": ['AH1 N D ER0 S T EY2 T M AH0 N T'],
  "understates": ['AH1 N D ER0 S T EY2 T S'],
  "understating": ['AH1 N D ER0 S T EY2 T IH0 NG'],
  "understood": ['AH2 N D ER0 S T UH1 D'],
  "understory": ['AH1 N D ER0 S T AO2 R IY0'],
  "understudy": ['AH1 N D ER0 S T AH2 D IY0'],
  "undersubscribed": ['AH2 N D ER0 S AH0 B S K R AY1 B D'],
  "undertake": ['AH1 N D ER0 T EY2 K'],
  "undertaken": ['AH1 N D ER0 T EY2 K AH0 N'],
  "undertaker": ['AH1 N D ER0 T EY2 K ER0'],
  "undertakes": ['AH1 N D ER0 T EY2 K S'],
  "undertaking": ['AH1 N D ER0 T EY2 K IH0 NG'],
  "undertakings": ['AH1 N D ER0 T EY2 K IH0 NG Z'],
  "undertone": ['AH1 N D ER0 T OW2 N'],
  "undertones": ['AH1 N D ER0 T OW2 N Z'],
  "undertook": ['AH2 N D ER0 T UH1 K'],
  "undertow": ['AH1 N D ER0 T OW2'],
  "undertows": ['AH1 N D ER0 T OW2 Z'],
  "underuse": ['AH2 N D ER0 Y UW1 Z'],
  "underused": ['AH2 N D ER0 Y UW1 Z D'],
  "underutilize": ['AH1 N D ER0 Y UW2 T AH0 L AY2 Z'],
  "underutilized": ['AH1 N D ER0 Y UW2 T AH0 L AY2 Z D'],
  "undervaluation": ['AH1 N D ER0 V AE2 L Y UW0 EY1 SH AH0 N'],
  "undervalue": ['AH1 N D ER0 V AE2 L Y UW0'],
  "undervalued": ['AH1 N D ER0 V AE2 L Y UW0 D'],
  "undervalues": ['AH1 N D ER0 V AE2 L Y UW0 Z'],
  "undervaluing": ['AH0 N D ER0 V AE1 L Y UW0 IH0 NG'],
  "underwater": ['AH1 N D ER0 W AO2 T ER0'],
  "underway": ['AH2 N D ER0 W EY1'],
  "underwear": ['AH1 N D ER0 W EH2 R'],
  "underweight": ['AH1 N D ER0 W EY2 T'],
  "underweighted": ['AH0 N D ER0 W EY1 T IH0 D'],
  "underwent": ['AH2 N D ER0 W EH1 N T'],
  "underwhelm": ['AH2 N D ER0 W EH1 L M'],
  "underwhelmed": ['AH2 N D ER0 W EH1 L M D'],
  "underwithheld": ['AH1 N D ER0 W IH2 TH HH EH1 L D'],
  "underwithhold": ['AH1 N D ER0 W IH2 TH HH OW2 L D'],
  "underwithholding": ['AH1 N D ER0 W IH2 TH HH OW2 L D IH0 NG'],
  "underwood": ['AH1 N D ER0 W UH2 D'],
  "underwoods": ['AH1 N D ER0 W UH2 D Z'],
  "underworld": ['AH1 N D ER0 W ER2 L D'],
  "underwrite": ['AH1 N D ER0 R AY2 T'],
  "underwriter": ['AH1 N D ER0 R AY2 T ER0'],
  "underwriter's": ['AH1 N D ER0 R AY2 T ER0 Z'],
  "underwriters": ['AH1 N D ER0 R AY2 T ER0 Z'],
  "underwriters'": ['AH1 N D ER0 R AY2 T ER0 Z'],
  "underwrites": ['AH1 N D ER0 R AY2 T S'],
  "underwriting": ['AH1 N D ER0 R AY1 T IH0 NG'],
  "underwritings": ['AH1 N D ER0 R AY2 T IH0 NG Z'],
  "underwritten": ['AH1 N D ER0 R IH2 T AH0 N'],
  "underwrote": ['AH2 N D ER0 R OW1 T'],
  "undeserved": ['AH2 N D IH0 Z ER1 V D'],
  "undeserving": ['AH2 N D IH0 Z ER1 V IH0 NG'],
  "undesirable": ['AH2 N D IH0 Z AY1 R AH0 B AH0 L'],
  "undetectable": ['AH0 N D IH0 T EH1 K T AH0 B AH0 L'],
  "undetected": ['AH2 N D IH0 T EH1 K T IH0 D'],
  "undetermined": ['AH2 N D IH0 T ER1 M IH0 N D', 'AH2 N D IY0 T ER1 M AH0 N D'],
  "undetermining": ['AH2 N D IH0 T ER1 M IH0 N IH0 NG', 'AH2 N D IY0 T ER1 M AH0 N IH0 NG'],
  "undeterred": ['AH2 N D IH0 T ER1 D'],
  "undeveloped": ['AH2 N D IH0 V EH1 L AH0 P T'],
  "undiagnosed": ['AH0 N D AY1 AE0 G N OW0 S T'],
  "undid": ['AH2 N D IH1 D'],
  "undifferentiated": ['AH0 N D IH0 F ER0 EH1 N SH IY0 EY2 T IH0 D'],
  "undignified": ['AH0 N D IH1 G N IH0 F AY2 D'],
  "undilute": ['AH2 N D AH0 L UW1 T'],
  "undiluted": ['AH2 N D AH0 L UW1 T IH0 D'],
  "undiminished": ['AH2 N D AH0 M IH1 N IH0 SH T'],
  "undine": ['AH1 N D IY2 N'],
  "undiplomatic": ['AH0 N D IH2 P L AH0 M AE1 T IH0 K'],
  "undiplomatically": ['AH0 N D IH2 P L AH0 M AE1 T IH0 K L IY0'],
  "undisciplined": ['AH0 N D IH1 S IH0 P L IH0 N D'],
  "undisclosed": ['AH2 N D IH0 S K L OW1 Z D'],
  "undiscovered": ['AH2 N D IH0 S K AH1 V ER0 D'],
  "undisguised": ['AH2 N D IH0 S G AY1 Z D'],
  "undisputed": ['AH2 N D IH0 S P Y UW1 T IH0 D'],
  "undistinguished": ['AH2 N D IH0 S T IH1 NG G W IH0 SH T'],
  "undistributed": ['AH2 N D IH0 S T R IH1 B Y UW0 T IH0 D'],
  "undisturbed": ['AH2 N D IH0 S T ER1 B D'],
  "undiversified": ['AH2 N D IH0 V ER1 S IH0 F AY2 D'],
  "undivided": ['AH2 N D AH0 V AY1 D IH0 D'],
  "undo": ['AH0 N D UW1'],
  "undock": ['AH0 N D AA1 K'],
  "undocking": ['AH0 N D AA1 K IH0 NG'],
  "undocumented": ['AH0 N D AA1 K Y AH0 M EH0 N T IH0 D', 'AH0 N D AA1 K Y AH0 M EH0 N IH0 D'],
  "undoing": ['AH0 N D UW1 IH0 NG'],
  "undone": ['AH0 N D AH1 N'],
  "undoubted": ['AH0 N D AW1 T IH0 D'],
  "undoubtedly": ['AH0 N D AW1 T IH0 D L IY0'],
  "undreamed": ['AH0 N D R IY1 M D'],
  "undress": ['AH0 N D R EH1 S'],
  "undressed": ['AH0 N D R EH1 S T'],
  "undue": ['AH0 N D UW1'],
  "undulate": ['AH1 N JH AH0 L EY2 T'],
  "undulated": ['AH1 N JH AH0 L EY2 T AH0 D'],
  "undulates": ['AH1 N JH AH0 L EY2 T S'],
  "undulating": ['AH1 N JH AH0 L EY2 T IH0 NG'],
  "unduly": ['AH0 N D UW1 L IY0'],
  "undying": ['AH0 N D AY1 IH0 NG'],
  "unearned": ['AH0 N ER1 N D'],
  "unearth": ['AH0 N ER1 TH'],
  "unearthed": ['AH0 N ER1 TH T'],
  "unearthing": ['AH0 N ER1 TH IH0 NG'],
  "unearthly": ['AH0 N ER1 TH L IY0'],
  "unease": ['AH0 N IY1 Z'],
  "uneasily": ['AH0 N IY1 Z AH0 L IY0'],
  "uneasiness": ['AH2 N IY1 Z IY0 N AH0 S'],
  "uneasy": ['AH0 N IY1 Z IY0'],
  "uneconomic": ['AH0 N EH2 K AH0 N AA1 M IH0 K'],
  "uneconomical": ['AH0 N EH2 K AH0 N AA1 M IH0 K AH0 L'],
  "unedited": ['AH0 N EH1 D AH0 T IH0 D'],
  "uneducated": ['AH0 N EH1 JH UH0 K EY2 T IH0 D'],
  "unelectable": ['AH2 N IH0 L EH1 K T AH0 B AH0 L'],
  "unelected": ['AH2 N IH0 L EH1 K T IH0 D'],
  "unemotional": ['AH2 N IY0 M OW1 SH AH0 N AH0 L', 'AH2 N AH0 M OW1 SH AH0 N AH0 L'],
  "unemploy": ['AH0 N IH0 M P L OY1'],
  "unemployable": ['AH0 N IH0 M P L OY1 AH0 B AH0 L'],
  "unemployed": ['AH2 N EH0 M P L OY1 D'],
  "unemployment": ['AH2 N IH0 M P L OY1 M AH0 N T'],
  "unencumber": ['AH2 N EH0 N K AH1 M B ER0'],
  "unencumbered": ['AH2 N EH0 N K AH1 M B ER0 D'],
  "unending": ['AH0 N EH1 N D IH0 NG'],
  "unenforceable": ['AH2 N EH0 N F AO1 R S AH0 B AH0 L'],
  "unenforced": ['AH2 N EH0 N F AO1 R S T'],
  "unenlightened": ['AH2 N EH0 N L AY1 T AH0 N D'],
  "unenthusiastic": ['AH0 N IH0 N TH UW2 Z IY0 AE1 S T IH0 K'],
  "unenumerated": ['AH0 N IH0 N UW1 M ER0 EY2 T IH0 D'],
  "unenviable": ['AH0 N EH1 N V IY2 AH0 B AH0 L'],
  "uneo": ['Y UW1 N IY0 OW0'],
  "unequal": ['AH0 N IY1 K W AH0 L'],
  "unequaled": ['AH0 N IY1 K W AH2 L D'],
  "unequivocably": ['AH0 N IH0 K W IH1 V AH0 K AH0 B L IY0'],
  "unequivocal": ['AH2 N IH0 K W IH1 V AH0 K AH0 L', 'AH2 N IY0 K W IH1 V AH0 K AH0 L'],
  "unequivocally": ['AH2 N IY0 K W IH1 V AH0 K AH0 L IY0', 'AH2 N IY0 K W IH1 V AH0 K L IY0'],
  "unesco": ['Y UW0 N EH1 S K OW2'],
  "unethical": ['AH0 N EH1 TH IH0 K AH0 L'],
  "unethically": ['AH0 N EH1 TH IH0 K L IY0'],
  "uneven": ['AH0 N IY1 V AH0 N'],
  "unevenly": ['AH0 N IY1 V AH0 N L IY0'],
  "uneventful": ['AH2 N IY0 V EH1 N T F AH0 L'],
  "unexciting": ['AH0 N IH0 K S AY1 T IH0 NG'],
  "unexercised": ['AH0 N EH1 K S ER0 S AY2 Z D'],
  "unexpected": ['AH2 N IH0 K S P EH1 K T IH0 D'],
  "unexpectedly": ['AH2 N IH0 K S P EH1 K T IH0 D L IY0'],
  "unexpired": ['AH2 N IH0 K S P AY1 ER0 D'],
  "unexplainable": ['AH2 N IH0 K S P L EY1 N AH0 B AH0 L'],
  "unexplained": ['AH2 N IH0 K S P L EY1 N D'],
  "unexploded": ['AH2 N IH0 K S P L OW1 D IH0 D'],
  "unexploited": ['AH0 N IH0 K S P L OY1 T IH0 D'],
  "unexplored": ['AH2 N IH0 K S P L AO1 R D'],
  "unfailing": ['AH0 N F EY1 L IH0 NG'],
  "unfailingly": ['AH0 N F EY1 L IH0 NG L IY0'],
  "unfair": ['AH0 N F EH1 R', 'AH1 N F EH1 R', 'AO2 N F EH1 R'],
  "unfairly": ['AH0 N F EH1 R L IY0'],
  "unfairness": ['AH0 N F EH1 R N AH0 S'],
  "unfaithful": ['AH0 N F EY1 TH F AH0 L'],
  "unfamiliar": ['AH2 N F AH0 M IH1 L Y ER0'],
  "unfamiliarities": ['AH2 N F AH0 M IH2 L Y EH1 R AH0 T IY0 Z'],
  "unfamiliarity": ['AH2 N F AH0 M IH2 L Y EH1 R AH0 T IY0'],
  "unfashionable": ['AH0 N F AE1 SH AH0 N AH0 B AH0 L'],
  "unfathomable": ['AH0 N F AE1 DH AH0 M AH0 B AH0 L'],
  "unfav": ['AH0 N F EY1 V'],
  "unfavorable": ['AH2 N F EY1 V ER0 AH0 B AH0 L'],
  "unfavorably": ['AH0 N F EY1 V ER0 AH0 B L IY0'],
  "unfazed": ['AH0 N F EY1 Z D'],
  "unfeasible": ['AH0 N F IY1 Z AH0 B AH0 L'],
  "unfeeling": ['AH0 N F IY1 L IH0 NG'],
  "unfenced": ['AH0 N F EH1 N S T'],
  "unfettered": ['AH0 N F EH1 T ER0 D'],
  "unfilled": ['AH0 N F IH1 L D'],
  "unfiltered": ['AH0 N F IH1 L T ER0 D'],
  "unfinished": ['AH0 N F IH1 N IH0 SH T'],
  "unfit": ['AH0 N F IH1 T'],
  "unflagging": ['AH0 N F L AE1 G IH0 NG'],
  "unflappable": ['AH0 N F L AE1 P AH0 B AH0 L'],
  "unflattering": ['AH0 N F L AE1 T ER0 IH0 NG'],
  "unflinching": ['AH0 N F L IH1 N CH IH0 NG'],
  "unfocused": ['AH0 N F OW1 K AH0 S T'],
  "unfold": ['AH0 N F OW1 L D'],
  "unfolded": ['AH0 N F OW1 L D IH0 D'],
  "unfolding": ['AH0 N F OW1 L D IH0 NG'],
  "unfolds": ['AH0 N F OW1 L D Z'],
  "unforeseeable": ['AH2 N F AO0 R S IY1 AH0 B AH0 L'],
  "unforeseen": ['AH2 N F AO0 R S IY1 N'],
  "unforgettable": ['AH2 N F ER0 G EH1 T AH0 B AH0 L'],
  "unforgivable": ['AH2 N F AO0 R G IH1 V AH0 B AH0 L'],
  "unforgiven": ['AH2 N F AO0 R G IH1 V AH0 N', 'AH2 N F ER0 G IH1 V AH0 N'],
  "unforgiving": ['AH0 N F ER0 G IH1 V IH0 NG'],
  "unformed": ['AH0 N F AO1 R M D'],
  "unfortunate": ['AH0 N F AO1 R CH AH0 N AH0 T', 'AH0 N F AO1 R CH UW0 N AH0 T'],
  "unfortunately": ['AH0 N F AO1 R CH AH0 N AH0 T L IY0', 'AH0 N F AO1 R CH UW0 N AH0 T L IY0'],
  "unfortunates": ['AH0 N F AO1 R CH AH0 N AH0 T S', 'AH0 N F AO1 R CH UW0 N AH0 T S'],
  "unfounded": ['AH0 N F AW1 N D IH0 D'],
  "unfriendly": ['AH0 N F R EH1 N D L IY0'],
  "unfrozen": ['AH0 N F R OW1 Z IH0 N'],
  "unfulfilled": ['AH2 N F UH0 L F IH1 L D'],
  "unfunded": ['AH0 N F AH1 N D IH0 D'],
  "unfunnily": ['AH0 N F AH1 N AH0 L IY0'],
  "unfurl": ['AH0 N F ER1 L'],
  "unfurled": ['AH0 N F ER1 L D'],
  "unfurling": ['AH0 N F ER1 L IH0 NG'],
  "ung": ['AH1 NG'],
  "ungainly": ['AH0 N G EY1 N L IY0'],
  "ungar": ['AH1 NG G ER0'],
  "ungaro": ['UW0 NG G AA1 R OW0'],
  "unger": ['AH1 NG G ER0'],
  "ungerer": ['AH1 NG ER0 ER0'],
  "ungermann": ['AH0 N JH ER1 M AH0 N'],
  "unglamorous": ['AH0 N G L AE1 M ER0 AH0 S'],
  "unglue": ['AH0 N G L UW1'],
  "unglued": ['AH0 N G L UW1 D'],
  "ungo": ['AH0 N G OW1'],
  "ungodly": ['AH0 N G AO1 D L IY0'],
  "ungovernable": ['AH0 N G AH1 V ER0 N AH0 B AH0 L'],
  "ungrateful": ['AH0 N G R EY1 T F AH0 L'],
  "unguarded": ['AH0 N G AA1 R D IH0 D'],
  "unhampered": ['AH0 N HH AE1 M P ER0 D'],
  "unhappily": ['AH0 N HH AE1 P AH0 L IY0'],
  "unhappiness": ['AH0 N HH AE1 P IY0 N IH0 S'],
  "unhappy": ['AH0 N HH AE1 P IY0'],
  "unharmed": ['AH0 N HH AA1 R M D'],
  "unhealthful": ['AH0 N HH EH1 L TH F AH0 L'],
  "unhealthy": ['AH0 N HH EH1 L TH IY0'],
  "unheard": ['AH0 N HH ER1 D'],
  "unhedged": ['AH0 N HH EH1 JH D'],
  "unheeded": ['AH0 N HH IY1 D IH0 D'],
  "unhelpful": ['AH0 N HH EH1 L P F AH0 L'],
  "unheralded": ['AH0 N HH EH1 R AH0 L D IH0 D'],
  "unhesitatingly": ['AH0 N HH EH1 Z IH0 T EY2 T IH0 NG L IY0'],
  "unhindered": ['AH0 N HH IH1 N D ER0 D'],
  "unhinge": ['AH0 N HH IH1 N JH'],
  "unhinged": ['AH0 N HH IH1 N JH D'],
  "unhitch": ['AH0 N HH IH1 CH'],
  "unholy": ['AH0 N HH OW1 L IY0'],
  "unhook": ['AH0 N HH UH1 K'],
  "unhorsed": ['AH0 N HH AO1 R S T'],
  "unhurried": ['AH0 N HH ER1 IY0 D'],
  "unhurt": ['AH0 N HH ER1 T'],
  "uni": ['Y UW1 N IY0'],
  "unibancorp": ['Y UW1 N IH0 B AE2 N K AO2 R P'],
  "unicef": ['Y UW1 N AH0 S EH2 F'],
  "unicef's": ['Y UW1 N AH0 S EH2 F S'],
  "unicellular": ['Y UW2 N IH0 S EH1 L Y AH0 L ER0'],
  "unicenter": ['Y UW2 N IH0 S EH1 N T ER0'],
  "unicom": ['Y UW1 N IH0 K AO0 M'],
  "unicorn": ['Y UW1 N IH0 K AO2 R N'],
  "unicorp": ['Y UW1 N IH0 K AO2 R P'],
  "unicorp's": ['Y UW1 N IH0 K AO2 R P S'],
  "unicycle": ['Y UW1 N AH0 S AY2 K AH0 L'],
  "unicycles": ['Y UW1 N AH0 S AY2 K AH0 L Z'],
  "uniden": ['Y UW1 N IH0 D EH2 N'],
  "uniden's": ['Y UW1 N IH0 D EH2 N Z'],
  "unidentified": ['AH2 N AY0 D EH1 N T AH0 F AY2 D'],
  "unification": ['Y UW2 N AH0 F AH0 K EY1 SH AH0 N'],
  "unified": ['Y UW1 N AH0 F AY2 D'],
  "unifil": ['Y UW1 N IH0 F IH0 L'],
  "uniforce": ['Y UW1 N IH0 F AO2 R S'],
  "uniform": ['Y UW1 N AH0 F AO2 R M'],
  "uniformed": ['Y UW1 N AH0 F AO2 R M D'],
  "uniformity": ['Y UW2 N AH0 F AO1 R M AH0 T IY0'],
  "uniformly": ['Y UW1 N AH0 F AO2 R M L IY0'],
  "uniforms": ['Y UW1 N AH0 F AO2 R M Z'],
  "unify": ['Y UW1 N AH0 F AY2'],
  "unifying": ['Y UW1 N AH0 F AY2 IH0 NG'],
  "unigesco": ['Y UW1 N IH0 G EH2 S K OW0'],
  "unikom": ['Y UW1 N IH0 K AO0 M'],
  "unilab": ['Y UW1 N IH0 L AE2 B'],
  "unilateral": ['Y UW2 N AH0 L AE1 T ER0 AH0 L'],
  "unilateralism": ['Y UW2 N IH0 L AE1 T ER0 AH0 L IH2 Z AH0 M'],
  "unilaterally": ['Y UW2 N AH0 L AE1 T ER0 AH0 L IY0', 'Y UW2 N AH0 L AE1 T R AH0 L IY0'],
  "unilever": ['Y UW2 N IH0 L IY1 V ER0'],
  "unilever's": ['Y UW2 N IH0 L IY1 V ER0 Z'],
  "unimaginable": ['AH2 N IH0 M AE1 JH IH0 N AH0 B AH0 L'],
  "unimaginative": ['AH0 N IH0 M AE1 JH IH2 N AH0 T IH0 V'],
  "unimagined": ['AH2 N IH0 M AE1 JH AH0 N D'],
  "unimate": ['Y UW1 N IH0 M EY2 T'],
  "unimation": ['Y UW2 N IH0 M EY1 SH AH0 N'],
  "unimedia": ['Y UW2 N IH0 M IY1 D IY0 AH0'],
  "unimpaired": ['AH2 N IH0 M P EH1 R D'],
  "unimpeachable": ['AH2 N IH0 M P IY1 CH AH0 B AH0 L'],
  "unimpeded": ['AH2 N IH0 M P IY1 D IH0 D'],
  "unimportant": ['AH0 N IH0 M P AO1 R T AH0 N T'],
  "unimpressed": ['AH2 N IH0 M P R EH1 S T'],
  "unimpressive": ['AH2 N IH0 M P R EH1 S IH0 V'],
  "unimproved": ['AH2 N IH0 M P R UW1 V D'],
  "unincorporated": ['AH2 N IH0 N K AO1 R P ER0 EY2 T IH0 D'],
  "unindicted": ['AH2 N IH0 N D AY1 T IH0 D'],
  "uninfected": ['AH2 N IH0 N F EH1 K T IH0 D'],
  "uninformative": ['AH0 N IH0 N F AO1 R M AH0 T IH0 V'],
  "uninformed": ['AH2 N IH0 N F AO1 R M D'],
  "uninhabitable": ['AH2 N IH0 N HH AE1 B AH0 T AH0 B AH0 L'],
  "uninhabited": ['AH2 N IH0 N HH AE1 B AH0 T IH0 D'],
  "uninhibited": ['AH2 N IH0 N HH IH1 B IH0 T IH0 D'],
  "uninitiated": ['AH2 N IH0 N IH1 SH IY0 EY2 T IH0 D', 'AH2 N IY0 N IH1 SH IY0 EY2 T IH0 D'],
  "uninjured": ['AH0 N IH1 N JH ER0 D'],
  "uninspired": ['AH2 N IH0 N S P AY1 ER0 D'],
  "uninspiring": ['AH0 N IH0 N S P AY1 R IH0 NG'],
  "uninstall": ['AH2 N IH0 N S T AO1 L'],
  "uninsurable": ['AH0 N IH0 N SH UH1 R AH0 B AH0 L'],
  "uninsured": ['AH2 N IH0 N SH UH1 R D'],
  "unintelligible": ['AH2 N IH0 N T EH1 L AH0 JH AH0 B AH0 L'],
  "unintended": ['AH2 N IH0 N T EH1 N D IH0 D'],
  "unintentional": ['AH2 N IH0 N T EH1 N SH AH0 N AH0 L'],
  "unintentionally": ['AH2 N IH0 N T EH1 N SH AH0 N AH0 L IY0', 'AH2 N IH0 N T EH1 N SH N AH0 L IY0'],
  "uninterested": ['AH0 N IH1 N T R AH0 S T IH0 D'],
  "uninteresting": ['AH0 N IH1 N T R AH0 S T IH0 NG'],
  "uninterrupted": ['AH2 N IH0 N T ER0 AH1 P T IH0 D', 'AH2 N IH0 N ER0 AH1 P T IH0 D'],
  "uninterruptible": ['AH0 N IH0 N T ER0 AH1 P T AH0 B AH0 L', 'AH0 N IH0 N ER0 AH1 P T AH0 B AH0 L'],
  "uninvited": ['AH2 N IH0 N V AY1 T IH0 D'],
  "uninviting": ['AH2 N IH0 N V AY1 T IH0 NG'],
  "uninvolved": ['AH0 N IH0 N V AO1 L V D'],
  "union": ['Y UW1 N Y AH0 N'],
  "union's": ['Y UW1 N Y AH0 N Z'],
  "uniondale": ['Y UW1 N Y AH0 N D EY2 L'],
  "unionfed": ['Y UW1 N Y AH0 N F EH2 D'],
  "unionism": ['Y UW1 N Y AH0 N IH2 Z AH0 M'],
  "unionist": ['Y UW1 N Y AH0 N AH0 S T'],
  "unionists": ['Y UW1 N Y AH0 N IH0 S T S'],
  "unionization": ['Y UW2 N Y AH0 N AH0 Z EY1 SH AH0 N'],
  "unionize": ['Y UW1 N Y AH0 N AY2 Z'],
  "unionized": ['Y UW1 N Y AH0 N AY2 Z D'],
  "unionizing": ['Y UW1 N Y AH0 N AY2 Z IH0 NG'],
  "unions": ['Y UW1 N Y AH0 N Z'],
  "unions'": ['Y UW1 N Y AH0 N Z'],
  "unique": ['Y UW0 N IY1 K'],
  "uniquely": ['Y UW0 N IY1 K L IY0'],
  "uniqueness": ['Y UW0 N IY1 K N AH0 S'],
  "uniroyal": ['Y UW1 N IH0 R OY2 AH0 L'],
  "unisex": ['Y UW1 N IH0 S EH2 K S'],
  "unisom": ['Y UW1 N AH0 S AA2 M'],
  "unison": ['Y UW1 N AH0 S AH0 N', 'Y UW1 N IH0 S AH0 N'],
  "unisons": ['Y UW1 N AH0 S AH0 N Z', 'Y UW1 N IH0 S AH0 N Z'],
  "unisource": ['Y UW1 N IH0 S AO2 R S'],
  "unissued": ['AH0 N IH1 SH UW2 D'],
  "unisys": ['Y UW1 N IH0 S IH2 S'],
  "unisys'": ['Y UW1 N IH0 S IH2 S'],
  "unisys's": ['Y UW1 N IH0 S IH2 S IH0 Z'],
  "unit": ['Y UW1 N AH0 T', 'Y UW1 N IH0 T'],
  "unit's": ['Y UW1 N IH0 T S'],
  "unita": ['Y UW2 N IY1 T AH0'],
  "unitaf": ['Y UW2 N IH0 T AE1 F'],
  "unitarian": ['Y UW2 N AH0 T EH1 R IY0 AH0 N'],
  "unitary": ['Y UW1 N IH0 T EH2 R IY0'],
  "unitas": ['Y UW0 N AY1 T AH0 S'],
  "unite": ['Y UW1 N AY2 T'],
  "united": ['Y UW0 N AY1 T IH0 D'],
  "united's": ['Y UW0 N AY1 T AH0 D Z'],
  "unitedbank": ['Y UW2 N AY1 T IH0 D B AE1 NG K'],
  "unitek": ['Y UW1 N IH0 T EH2 K'],
  "unitel": ['Y UW1 N IH0 T EH2 L'],
  "unites": ['Y UW0 N AY1 T S'],
  "unitholder": ['Y UW1 N IH0 T HH OW2 L D ER0'],
  "unitholders": ['Y UW1 N IH0 T HH OW2 L D ER0 Z'],
  "uniting": ['Y UW0 N AY1 T IH0 NG'],
  "unitrin": ['Y UW1 N IH0 T R IH2 N'],
  "unitrin's": ['Y UW1 N IH0 T R IH2 N Z'],
  "unitrode": ['Y UW1 N IH0 T R OW2 D'],
  "unitrode's": ['Y UW1 N IH0 T R OW2 D Z'],
  "units": ['Y UW1 N AH0 T S', 'Y UW1 N IH0 T S'],
  "units'": ['Y UW1 N IH0 T S'],
  "unity": ['Y UW1 N AH0 T IY0', 'Y UW1 N IH0 T IY0'],
  "univa": ['Y UW1 N IH0 V AH0'],
  "univar": ['Y UW1 N IH0 V AA0 R'],
  "univation": ['Y UW2 N IH0 V EY1 SH AH0 N'],
  "universal": ['Y UW2 N AH0 V ER1 S AH0 L'],
  "universal's": ['Y UW2 N AH0 V ER1 S AH0 L Z'],
  "universality": ['Y UW2 N AH0 V ER0 S AE1 L AH0 T IY0'],
  "universally": ['Y UW2 N AH0 V ER1 S AH0 L IY0'],
  "universe": ['Y UW1 N AH0 V ER2 S'],
  "universes": ['Y UW1 N AH0 V ER2 S IH0 Z'],
  "universities": ['Y UW2 N AH0 V ER1 S AH0 T IY0 Z'],
  "universities'": ['Y UW2 N IH0 V ER1 S IH0 T IY0 Z'],
  "university": ['Y UW2 N AH0 V ER1 S AH0 T IY0'],
  "university's": ['Y UW2 N AH0 V ER1 S AH0 T IY0 Z'],
  "univisa": ['Y UW2 N IH0 V IY1 S AH0'],
  "univision": ['Y UW2 N IH0 V IH1 ZH AH0 N'],
  "unix": ['Y UW1 N IH0 K S'],
  "unjust": ['AH0 N JH AH1 S T'],
  "unjustifiable": ['AH2 N JH AH2 S T IH0 F AY1 AH0 B AH0 L'],
  "unjustifiably": ['AH2 N JH AH2 S T IH0 F AY1 AH0 B L IY0'],
  "unjustified": ['AH0 N JH AH1 S T AH0 F AY2 D'],
  "unjustly": ['AH0 N JH AH1 S T L IY0'],
  "unkefer": ['AH1 NG K IH0 F ER0'],
  "unkempt": ['AH0 N K EH1 M P T'],
  "unkind": ['AH0 N K AY1 N D'],
  "unkindest": ['AH0 N K AY1 N D IH0 S T'],
  "unknowable": ['AH0 N N OW1 AH0 B AH0 L'],
  "unknowing": ['AH0 N N OW1 IH0 NG'],
  "unknowingly": ['AH0 N N OW1 IH0 NG L IY0'],
  "unknown": ['AH0 N N OW1 N'],
  "unknowns": ['AH0 N OW1 N Z'],
  "unlabeled": ['AH0 N L EY1 B AH0 L D'],
  "unland": ['AH1 N L AH0 N D'],
  "unlawful": ['AH0 N L AO1 F AH0 L'],
  "unlawfully": ['AH0 N L AO1 F AH0 L IY0'],
  "unleaded": ['AH0 N L EH1 D IH0 D'],
  "unleash": ['AH0 N L IY1 SH'],
  "unleashed": ['AH0 N L IY1 SH T'],
  "unleashes": ['AH0 N L IY1 SH IH0 Z'],
  "unleashing": ['AH0 N L IY1 SH IH0 NG'],
  "unless": ['AH0 N L EH1 S'],
  "unleveraged": ['AH0 N L EH1 V R IH0 JH D'],
  "unlicensed": ['AH0 N L AY1 S AH0 N S T'],
  "unlike": ['AH0 N L AY1 K'],
  "unlikely": ['AH0 N L AY1 K L IY0'],
  "unlimited": ['AH0 N L IH1 M AH0 T IH0 D', 'AH0 N L IH1 M IH0 T IH0 D'],
  "unlined": ['AH0 N L AY1 N D'],
  "unlisted": ['AH0 N L IH1 S T IH0 D'],
  "unlivable": ['AH0 N L IH1 V AH0 B AH0 L'],
  "unload": ['AH0 N L OW1 D'],
  "unloaded": ['AH0 N L OW1 D IH0 D'],
  "unloading": ['AH0 N L OW1 D IH0 NG'],
  "unloads": ['AH0 N L OW1 D Z'],
  "unlock": ['AH0 N L AA1 K'],
  "unlocked": ['AH0 N L AA1 K T'],
  "unlocking": ['AH0 N L AA1 K IH0 NG'],
  "unloved": ['AH0 N L AH1 V D'],
  "unlucky": ['AH0 N L AH1 K IY0'],
  "unmade": ['AH0 N M EY1 D'],
  "unmanage": ['AH0 N M AE1 N IH0 JH'],
  "unmanageable": ['AH0 N M AE1 N IH0 JH AH0 B AH0 L'],
  "unmanaged": ['AH0 N M AE1 N IH0 JH D'],
  "unmanned": ['AH0 N M AE1 N D'],
  "unmarked": ['AH0 N M AA1 R K T'],
  "unmarried": ['AH0 N M EH1 R IY0 D'],
  "unmask": ['AH0 N M AE1 S K'],
  "unmasked": ['AH0 N M AE1 S K T'],
  "unmatched": ['AH0 N M AE1 CH T'],
  "unmentionable": ['AH0 N M EH1 N SH AH0 N AH0 B AH0 L'],
  "unmentioned": ['AH0 N M EH1 N CH AH0 N D'],
  "unmerciful": ['AH0 N M ER1 S IH0 F AH0 L'],
  "unmet": ['AH0 N M EH1 T'],
  "unmistakable": ['AH2 N M IH0 S T EY1 K AH0 B AH0 L'],
  "unmistakably": ['AH2 N M IH0 S T EY1 K AH0 B L IY0'],
  "unmitigated": ['AH0 N M IH1 T AH0 G EY2 T IH0 D'],
  "unmixed": ['AH0 N M IH1 K S T'],
  "unmolested": ['AH2 N M AH0 L EH1 S T IH0 D'],
  "unmoved": ['AH0 N M UW1 V D'],
  "unnamed": ['AH0 N N EY1 M D'],
  "unnatural": ['AH0 N N AE1 CH ER0 AH0 L'],
  "unnaturally": ['AH0 N N AE1 CH ER0 AH0 L IY0', 'AH0 N N AE1 CH ER0 L IY0', 'AH0 N AE1 CH ER0 L IY0', 'AH0 N N AE1 CH R AH0 L IY0'],
  "unnecessarily": ['AH0 N N EH1 S AH0 S EH2 R AH0 L IY0'],
  "unnecessary": ['AH0 N N EH1 S AH0 S EH2 R IY0'],
  "unneeded": ['AH0 N N IY1 D IH0 D'],
  "unnerve": ['AH0 N ER1 V'],
  "unnerved": ['AH0 N N ER1 V D'],
  "unnerving": ['AH0 N ER1 V IH0 NG'],
  "unnoticed": ['AH0 N N OW1 T IH0 S T'],
  "uno": ['AH0 N OW1', 'UW1 N OW2'],
  "unobstructed": ['AH2 N AH0 B S T R AH1 K T IH0 D'],
  "unobtainable": ['AH2 N AH0 B T EY1 N AH0 B AH0 L'],
  "unobtrusive": ['AH2 N AH0 B T R UW1 S IH0 V'],
  "unocal": ['Y UW1 N AH0 K AE2 L', 'Y UW1 N AH0 K AO2 L'],
  "unocal's": ['Y UW1 N AH0 K AE2 L Z', 'Y UW1 N AH0 K AO2 L Z'],
  "unoccupied": ['AH0 N AA1 K Y AH0 P AY2 D'],
  "unofficial": ['AH2 N AH0 F IH1 SH AH0 L'],
  "unofficially": ['AH0 N AH0 F IH1 SH AH0 L IY0'],
  "unopened": ['AH0 N OW1 P AH0 N D'],
  "unopposed": ['AH2 N AH0 P OW1 Z D'],
  "unorganized": ['AH0 N AO1 R G AH0 N AY2 Z D'],
  "unorthodox": ['AH0 N AO1 R TH AH0 D AA2 K S'],
  "unos": ['UW1 N OW0 Z'],
  "unosom": ['Y UW1 N OW0 S AO2 M', 'Y UW1 N OW0 S AH0 M'],
  "unpack": ['AH0 N P AE1 K'],
  "unpacked": ['AH0 N P AE1 K T'],
  "unpacking": ['AH0 N P AE1 K IH0 NG'],
  "unpaid": ['AH0 N P EY1 D'],
  "unpainted": ['AH0 N P EY1 N T IH0 D'],
  "unpalatable": ['AH0 N P AE1 L AH0 T AH0 B AH0 L'],
  "unparalleled": ['AH0 N P EH1 R AH0 L EH2 L D'],
  "unpatriotic": ['AH0 N P EY2 T R IY0 AA1 T IH0 K'],
  "unpaved": ['AH2 P EY2 V D'],
  "unpayable": ['AH0 N P EY1 AH0 B AH0 L'],
  "unperturbed": ['AH2 N P ER0 T ER1 B D'],
  "unplaced": ['AH0 N P L EY1 S T'],
  "unplanned": ['AH0 N P L AE1 N D'],
  "unpleasant": ['AH0 N P L EH1 Z AH0 N T'],
  "unpleasantly": ['AH0 N P L EH1 Z AH0 N T L IY0'],
  "unpleasantness": ['AH0 N P L EH1 Z AH0 N T N AH0 S'],
  "unplug": ['AH0 N P L AH1 G'],
  "unplugged": ['AH0 N P L AH1 G D', 'AH1 N P L AH1 G D'],
  "unpopular": ['AH2 N P AA1 P Y AH0 L ER0'],
  "unpopularity": ['AH0 N P AA2 P Y AH0 L EH1 R IH0 T IY0'],
  "unprecedented": ['AH0 N P R EH1 S IH0 D EH2 N T IH0 D'],
  "unprecedentedly": ['AH0 N P R EH1 S AH0 D EH2 N T IH0 D L IY0'],
  "unpredictability": ['AH2 N P R AH0 D IH2 K T AH0 B IH1 L IH0 T IY0'],
  "unpredictable": ['AH2 N P R IH0 D IH1 K T AH0 B AH0 L'],
  "unpredictably": ['AH2 N P R IH0 D IH1 K T AH0 B L IY0'],
  "unprepared": ['AH2 N P R IY0 P EH1 R D'],
  "unpreserved": ['AH0 N P R AH0 Z ER1 V D', 'AH0 N P R IH0 Z ER1 V D', 'AH0 N P R IY0 Z ER1 V D'],
  "unpressurized": ['AH0 N P R EH1 SH ER0 AY0 Z D'],
  "unpretentious": ['AH2 N P R IY0 T EH1 N SH AH0 S'],
  "unprincipled": ['AH0 N P R IH1 N S AH0 P AH0 L D'],
  "unprintable": ['AH0 N P R IH1 N T AH0 B AH0 L'],
  "unprocessed": ['AH0 N P R AO1 S EH2 S T'],
  "unproductive": ['AH2 N P R AH0 D AH1 K T IH0 V'],
  "unprofessional": ['AH2 N P R AH0 F EH1 SH AH0 N AH0 L'],
  "unprofitability": ['AH0 N P R AA2 F IH0 T AH0 B IH1 L IH0 T IY0'],
  "unprofitable": ['AH0 N P R AA1 F IH0 T AH0 B AH0 L'],
  "unprofor": ['AH1 P R OW0 F AO2 R'],
  "unprofor's": ['AH1 P R OW0 F AO2 R Z'],
  "unpromising": ['AH0 N P R AO1 M IH0 S IH0 NG'],
  "unprotected": ['AH2 N P R AH0 T EH1 K T IH0 D'],
  "unproved": ['AH0 N P R UW1 V D'],
  "unproven": ['AH0 N P R UW1 V AH0 N'],
  "unprovoked": ['AH2 N P R AH0 V OW1 K T'],
  "unpublicized": ['AH0 N P AH1 B L IH0 S AY0 Z D'],
  "unpublished": ['AH0 N P AH1 B L IH0 SH T'],
  "unpunished": ['AH0 N P AH1 N IH0 SH T'],
  "unqualified": ['AH0 N K W AA1 L IH0 F AY2 D'],
  "unquestionable": ['AH0 N K W EH1 S CH AH0 N AH0 B AH0 L'],
  "unquestionably": ['AH0 N K W EH1 S CH AH0 N AH0 B L IY0'],
  "unquestioned": ['AH0 N K W EH1 S CH AH0 N D'],
  "unquestioning": ['AH0 N K W EH1 S CH AH0 N IH0 NG'],
  "unquote": ['AH1 N K W OW1 T'],
  "unrated": ['AH0 N R EY1 T IH0 D'],
  "unrath": ['AH1 N R AH0 TH'],
  "unratified": ['AH0 N R AE1 T IH0 F AY2 D'],
  "unravel": ['AH0 N R AE1 V AH0 L'],
  "unraveled": ['AH0 N R AE1 V AH0 L D'],
  "unraveling": ['AH0 N R AE1 V AH0 L IH0 NG', 'AH0 N R AE1 V L IH0 NG'],
  "unravels": ['AH0 N R AE1 V AH0 L Z'],
  "unreachable": ['AH0 N R IY1 CH AH0 B AH0 L'],
  "unread": ['AH0 N R EH1 D'],
  "unreadable": ['AH0 N R IY1 D AH0 B AH0 L', 'AH1 N R IY1 D AH0 B AH0 L'],
  "unreal": ['AH0 N R IY1 L'],
  "unrealistic": ['AH0 N R IY2 L IH1 S T IH0 K'],
  "unrealistically": ['AH0 N R IY2 AH0 L IH1 S T IH0 K L IY0'],
  "unreality": ['AH2 N R IY0 AE1 L AH0 T IY0'],
  "unrealized": ['AH0 N R IY1 AH0 L AY2 Z D'],
  "unreasonable": ['AH0 N R IY1 Z N AH0 B AH0 L'],
  "unreasonably": ['AH0 N R IY1 Z AH0 N AH0 B L IY0'],
  "unreasoning": ['AH0 N R IY1 Z N IH0 NG'],
  "unreceptive": ['AH0 N R IH0 S EH1 P T IH0 V'],
  "unrecognizable": ['AH0 N R EH2 K AH0 G N AY1 Z AH0 B AH0 L'],
  "unrecognized": ['AH0 N R EH1 K AH0 G N AY2 Z D'],
  "unreconciled": ['AH0 N R EH1 K AH0 N S AY2 L D'],
  "unreconstructed": ['AH2 N R IY0 K AH0 N S T R AH1 K T IH0 D'],
  "unrecorded": ['AH2 N R IH0 K AO1 R D IH0 D'],
  "unredeemed": ['AH2 N R IY0 D IY1 M D'],
  "unrefined": ['AH2 N R IY0 F AY1 N D'],
  "unrefuted": ['AH2 N R IY0 F Y UW1 T IH0 D'],
  "unregistered": ['AH0 N R EH1 JH IH0 S T ER0 D'],
  "unregulated": ['AH0 N R EH1 G Y AH0 L EY2 T IH0 D'],
  "unrehearsed": ['AH0 N R IY2 HH ER2 S T'],
  "unreimbursed": ['AH0 N R IY2 IH0 M B ER2 S T'],
  "unrein": ['AO1 N R AY0 N'],
  "unrelated": ['AH2 N R IH0 L EY1 T IH0 D', 'AH2 N R IY0 L EY1 T IH0 D'],
  "unreleased": ['AH0 N R IH0 L IY1 S T'],
  "unrelenting": ['AH2 N R IY0 L EH1 N T IH0 NG'],
  "unreliability": ['AH2 N R IY0 L AY2 AH0 B IH1 L IH0 T IY0'],
  "unreliable": ['AH2 N R IH0 L AY1 AH0 B AH0 L', 'AH2 N R IY0 L AY1 AH0 B AH0 L'],
  "unrelieved": ['AH2 N R IY0 L IY1 V D'],
  "unremarkable": ['AH0 N R IH0 M AA1 R K AH0 B AH0 L'],
  "unremarked": ['AH0 N R IH0 M AA1 R K T'],
  "unremitted": ['AH0 N R IH0 M IH1 T IH0 D'],
  "unremitting": ['AH2 N R IH0 M IH1 T IH0 NG', 'AH2 N R IY0 M IH1 T IH0 NG'],
  "unrepentant": ['AH2 N R IH0 P EH1 N T AH0 N T', 'AH2 N R IY0 P EH1 N T AH0 N T'],
  "unreported": ['AH2 N R IY0 P AO1 R T IH0 D'],
  "unrepresentative": ['AH0 N R EH2 P R IH0 Z EH1 N T AH0 T IH0 V'],
  "unrepresented": ['AH0 N R EH2 P R IH0 Z EH1 N T IH0 D'],
  "unrequited": ['AH2 N R IY0 K W AY1 T IH0 D'],
  "unresolved": ['AH0 N R IH0 Z AA1 L V D'],
  "unresponsive": ['AH2 N R IY0 S P AA1 N S IH0 V'],
  "unrest": ['AH0 N R EH1 S T'],
  "unrestrained": ['AH2 N R IY0 S T R EY1 N D'],
  "unrestricted": ['AH2 N R IY0 S T R IH1 K T IH0 D'],
  "unrevised": ['AH0 N R IY0 V AY1 Z D'],
  "unring": ['AH0 N R IH1 NG'],
  "unrivaled": ['AH0 N R AY1 V AH0 L D'],
  "unroll": ['AH2 N R OW1 L'],
  "unrolled": ['AH2 N R OW1 L D'],
  "unrolling": ['AH2 N R OW1 L IH0 NG'],
  "unrolls": ['AH2 N R OW1 L Z'],
  "unrue": ['AH1 N R UW0'],
  "unruffled": ['AH0 N R AH1 F AH0 L D'],
  "unruh": ['AH1 N R UW0'],
  "unruly": ['AH0 N R UW1 L IY0'],
  "unsafe": ['AH0 N S EY1 F'],
  "unsaid": ['AH0 N S EH1 D'],
  "unsalable": ['AH0 N S EY1 L AH0 B AH0 L'],
  "unsaleable": ['AH0 N S EY1 L AH0 B AH0 L'],
  "unsanctioned": ['AH0 N S AE1 NG K SH AH0 N D'],
  "unsanitary": ['AH0 N S AE1 N AH0 T EH2 R IY0'],
  "unsatisfactory": ['AH2 N S AH0 T IH0 S F AE1 K T ER0 IY0'],
  "unsatisfied": ['AH0 N S AE1 T IH0 S F AY2 D'],
  "unsatisfying": ['AH0 N S AE1 T IH0 S F AY2 IH0 NG'],
  "unsaturated": ['AH0 N S AE1 CH ER0 EY2 T IH0 D'],
  "unsavory": ['AH0 N S EY1 V ER0 IY0'],
  "unscathed": ['AH0 N S K EY1 DH D'],
  "unscheduled": ['AH0 N S K EH1 JH UW0 L D'],
  "unscientific": ['AH0 N S AY2 AH0 N T IH1 F IH0 K'],
  "unscom": ['AH1 N S K AO2 M'],
  "unscramble": ['AH0 N S K R AE1 M B AH0 L'],
  "unscripted": ['AH0 N S K R IH1 P T IH0 D'],
  "unscrupulous": ['AH0 N S K R UW1 P Y AH0 L AH0 S'],
  "unseal": ['AH0 N S IY1 L'],
  "unsealed": ['AH0 N S IY1 L D'],
  "unseasonably": ['AH0 N S IY1 Z AH0 N AH0 B L IY0'],
  "unseat": ['AH0 N S IY1 T'],
  "unseated": ['AH0 N S IY1 T IH0 D'],
  "unseating": ['AH0 N S IY1 T IH0 NG'],
  "unsecured": ['AH2 N S IH0 K Y UH1 R D', 'AH2 N S IY0 K Y UH1 R D'],
  "unseemly": ['AH0 N S IY1 M L IY0'],
  "unseen": ['AH0 N S IY1 N'],
  "unsell": ['AH0 N S EH1 L'],
  "unsentimental": ['AH0 N S EH2 N T IH0 M EH1 N T AH0 L'],
  "unser": ['AH1 N S ER0'],
  "unserviceable": ['AH0 N S ER1 V AH0 S AH0 B AH0 L', 'AH1 N S ER1 V AH0 S AH0 B AH0 L'],
  "unset": ['AH0 N S EH1 T', 'AH1 N S EH1 T'],
  "unsettle": ['AH0 N S EH1 T AH0 L'],
  "unsettled": ['AH0 N S EH1 T AH0 L D'],
  "unsettling": ['AH0 N S EH1 T AH0 L IH0 NG', 'AH0 N S EH1 T L IH0 NG'],
  "unshackle": ['AH0 N SH AE1 K AH0 L'],
  "unshackled": ['AH0 N SH AE1 K AH0 L D'],
  "unshakable": ['AH0 N SH EY1 K AH0 B AH0 L'],
  "unshakeable": ['AH0 N SH EY1 K AH0 B AH0 L'],
  "unshaken": ['AH0 N SH EY1 K AH0 N'],
  "unsharpened": ['AH0 N SH AA1 R P AH0 N D'],
  "unshaven": ['AH0 N SH EY1 V AH0 N'],
  "unsightly": ['AH0 N S AY1 T L IY0'],
  "unsigned": ['AH0 N S AY1 N D'],
  "unsinkable": ['AH0 N S IH1 NG K AH0 B AH0 L'],
  "unskilled": ['AH0 N S K IH1 L D'],
  "unsmiling": ['AH0 N S M AY1 L IH0 NG'],
  "unsold": ['AH0 N S OW1 L D'],
  "unsolicited": ['AH2 N S AH0 L IH1 S IH0 T IH0 D'],
  "unsolvable": ['AH0 N S AA1 L V AH0 B AH0 L'],
  "unsolved": ['AH0 N S AA1 L V D'],
  "unsophisticated": ['AH2 N S AH0 F IH1 S T IH0 K EY2 T IH0 D'],
  "unsound": ['AH0 N S AW1 N D'],
  "unsparing": ['AH0 N S P EH1 R IH0 NG'],
  "unspeakable": ['AH0 N S P IY1 K AH0 B AH0 L'],
  "unspecified": ['AH0 N S P EH1 S AH0 F AY2 D'],
  "unspectacular": ['AH2 N S P EH0 K T AE1 K Y AH0 L ER0'],
  "unspent": ['AH0 N S P EH1 N T'],
  "unspoiled": ['AH0 N S P OY1 L D'],
  "unspoken": ['AH0 N S P OW1 K AH0 N'],
  "unsportsmanlike": ['AH0 N S P AO1 R T S M AH0 N L AY2 K'],
  "unstable": ['AH0 N S T EY1 B AH0 L'],
  "unstained": ['AH0 N S T EY1 N D'],
  "unstated": ['AH0 N S T EY1 T IH0 D'],
  "unsteady": ['AH0 N S T EH1 D IY0'],
  "unstinting": ['AH0 N S T IH1 N T IH0 NG'],
  "unstoppable": ['AH0 N S T AA1 P AH0 B AH0 L'],
  "unstressed": ['AH0 N S T R EH1 S T'],
  "unstructured": ['AH0 N S T R AH1 K SH ER0 D'],
  "unstuck": ['AH0 N S T AH1 K'],
  "unsubscribe": ['AH0 N S AH0 B S K R AY1 B'],
  "unsubscribed": ['AH0 N S AH0 B S K R AY1 B D'],
  "unsubsidized": ['AH0 N S AH1 B S AH0 D AY2 Z D'],
  "unsubstantiated": ['AH2 N S AH0 B S T AE1 N SH IY0 EY2 T IH0 D'],
  "unsubtle": ['AH0 N S AH1 T AH0 L'],
  "unsuccessful": ['AH2 N S AH0 K S EH1 S F AH0 L'],
  "unsuccessfully": ['AH2 N S AH0 K S EH1 S F AH0 L IY0'],
  "unsuitable": ['AH0 N S UW1 T AH0 B AH0 L'],
  "unsuited": ['AH0 N S UW1 T IH0 D'],
  "unsullied": ['AH0 N S AH1 L IY0 D'],
  "unsung": ['AH0 N S AH1 NG'],
  "unsupervised": ['AH0 N S UW1 P ER0 V AY2 Z D'],
  "unsupportable": ['AH2 N S AH0 P AO1 R T AH0 B AH0 L'],
  "unsupported": ['AH0 N S AH0 P AO1 R T IH0 D'],
  "unsure": ['AH0 N SH UH1 R'],
  "unsurpassed": ['AH2 N S ER0 P AE1 S T'],
  "unsurprising": ['AH0 N S ER0 P R AY1 Z IH0 NG'],
  "unsurprisingly": ['AH2 N S ER0 P R AY1 Z IH0 NG L IY0'],
  "unsuspected": ['AH2 N S AH0 S P EH1 K T IH0 D'],
  "unsuspecting": ['AH2 N S AH0 S P EH1 K T IH0 NG'],
  "unsustainable": ['AH2 N S AH0 S T EY1 N AH0 B AH0 L'],
  "unsustainably": ['AH2 N S AH0 S T EY1 N AH0 B L IY0'],
  "unswayed": ['AH0 N S W EY1 D'],
  "unswerving": ['AH0 N S W ER1 V IH0 NG'],
  "unsworth": ['AH1 N S W ER0 TH'],
  "unsympathetic": ['AH0 N S IH2 M P AH0 TH EH1 T IH0 K'],
  "untainted": ['AH0 N T EY1 N T IH0 D'],
  "untangle": ['AH0 N T AE1 NG G AH0 L'],
  "untangling": ['AH0 N T AE1 NG L IH0 NG'],
  "untapped": ['AH0 N T AE1 P T'],
  "untaxed": ['AH0 N T AE1 K S T'],
  "unted": ['AH0 N T EH1 D'],
  "untenable": ['AH0 N T EH1 N AH0 B AH0 L'],
  "untendered": ['AH0 N T EH1 N D ER0 D'],
  "unterberg": ['AH1 N T ER0 B ER0 G'],
  "unterman": ['AH1 N T ER0 M AH0 N'],
  "untermeyer": ['AH1 N T ER0 M AY2 R'],
  "unterreiner": ['AO1 N T ER0 AY0 N ER0'],
  "untested": ['AH0 N T EH1 S T IH0 D'],
  "unthinkable": ['AH0 N TH IH1 NG K AH0 B AH0 L'],
  "unthinking": ['AH0 N TH IH1 NG K IH0 NG'],
  "unthinkingly": ['AH0 N TH IH1 NG K IH0 NG L IY0'],
  "untidy": ['AH0 N T AY1 D IY0'],
  "untie": ['AH0 N T AY1'],
  "untied": ['AH0 N T AY1 D'],
  "untiedt": ['AO1 N T IY0 T'],
  "until": ['AH0 N T IH1 L'],
  "untimely": ['AH0 N T AY1 M L IY0'],
  "unto": ['AH1 N T UW0'],
  "untold": ['AH0 N T OW1 L D'],
  "untouchable": ['AH0 N T AH1 CH AH0 B AH0 L'],
  "untouchables": ['AH0 N T AH1 CH AH0 B AH0 L Z'],
  "untouched": ['AH0 N T AH1 CH T'],
  "untoward": ['AH0 N T UW0 AO1 R D', 'AH0 N T AH0 W AO1 R D'],
  "untraceable": ['AH0 N T R EY1 S AH0 B AH0 L'],
  "untraditional": ['AH2 N T R AH0 D IH1 SH AH0 N AH0 L'],
  "untrained": ['AH0 N T R EY1 N D'],
  "untrammeled": ['AH0 N T R AE1 M AH0 L D'],
  "untreatable": ['AH0 N T R IY1 T AH0 B AH0 L'],
  "untreated": ['AH0 N T R IY1 T IH0 D'],
  "untried": ['AH0 N T R AY1 D'],
  "untroubled": ['AH0 N T R AH1 B AH0 L D'],
  "untrue": ['AH0 N T R UW1'],
  "untrustworthy": ['AH0 N T R AH1 S T W ER2 DH IY0'],
  "untruth": ['AH0 N T R UW1 TH'],
  "untruthful": ['AH0 N T R UW1 TH F AH0 L'],
  "untruths": ['AH0 N T R UW1 TH S', 'AH0 N T R UW1 DH S'],
  "unturned": ['AH0 N T ER1 N D'],
  "untypical": ['AH0 N T IH1 P IH0 K AH0 L'],
  "unum": ['Y UW1 N AH0 M', 'UW1 N AH0 M'],
  "unusable": ['AH0 N Y UW1 Z AH0 B AH0 L'],
  "unused": ['AH0 N Y UW1 Z D'],
  "unusual": ['AH0 N Y UW1 ZH UW2 AH0 L', 'AH0 N Y UW1 ZH W AH0 L'],
  "unusually": ['AH0 N Y UW1 ZH UW2 AH0 L IY0', 'AH0 N Y UW1 ZH W AH0 L IY0'],
  "unvarnished": ['AH0 N V AA1 R N IH0 SH T'],
  "unveil": ['AH0 N V EY1 L'],
  "unveiled": ['AH0 N V EY1 L D'],
  "unveiling": ['AH0 N V EY1 L IH0 NG'],
  "unveils": ['AH0 N V EY1 L Z'],
  "unverifiable": ['AH0 N V EH2 R IH0 F AY1 AH0 B AH0 L'],
  "unverified": ['AH0 N V EH1 R IH0 F AY2 D'],
  "unverzagt": ['AO1 N V ER0 Z AO0 G T'],
  "unwanted": ['AH0 N W AO1 N T IH0 D'],
  "unwarranted": ['AH0 N W AO1 R AH0 N T IH0 D'],
  "unwary": ['AH0 N W EH1 R IY0'],
  "unwashed": ['AH0 N W AA1 SH T'],
  "unwavering": ['AH0 N W EY1 V ER0 IH0 NG'],
  "unwed": ['AH0 N W EH1 D'],
  "unwelcome": ['AH0 N W EH1 L K AH0 M'],
  "unwelcomed": ['AH0 N W EH1 L K AH0 M D'],
  "unwell": ['AH0 N W EH1 L'],
  "unwieldiness": ['AH0 N W IY1 L D IY0 N AH0 S'],
  "unwielding": ['AH0 N W IY1 L D IH0 NG'],
  "unwieldy": ['AH0 N W IY1 L D IY0'],
  "unwilling": ['AH0 N W IH1 L IH0 NG'],
  "unwillingly": ['AH0 N W IH1 L IH0 NG L IY0'],
  "unwillingness": ['AH0 N W IH1 L IH0 NG N IH0 S'],
  "unwin": ['AO1 N W IH0 N'],
  "unwind": ['AH0 N W AY1 N D'],
  "unwinding": ['AH0 N W AY1 N D IH0 NG'],
  "unwinnable": ['AH0 N W IH1 N AH0 B AH0 L'],
  "unwise": ['AH0 N W AY1 Z'],
  "unwisely": ['AH0 N W AY1 Z L IY0'],
  "unwitting": ['AH0 N W IH1 T IH0 NG'],
  "unwittingly": ['AH0 N W IH1 T IH0 NG L IY0'],
  "unworkable": ['AH0 N W ER1 K AH0 B AH0 L'],
  "unworried": ['AH0 N W ER1 IY0 D'],
  "unworthy": ['AH0 N W ER1 DH IY0'],
  "unwound": ['AH0 N W AW1 N D'],
  "unwrap": ['AH0 N R AE1 P'],
  "unwrapped": ['AH0 N R AE1 P T'],
  "unwrapping": ['AH0 N R AE1 P IH0 NG'],
  "unwritten": ['AH0 N R IH1 T AH0 N'],
  "unyielding": ['AH0 N Y IY1 L D IH0 NG'],
  "unz": ['AH1 N Z'],
  "unzicker": ['AO1 N Z IH0 K ER0'],
  "unzip": ['AH0 N Z IH1 P'],
  "unzipped": ['AH0 N Z IH1 P T'],
  "up": ['AH1 P'],
  "up's": ['AH1 P S'],
  "up-front": ['AH1 P F R AH2 N T'],
  "upbeat": ['AH1 P B IY2 T'],
  "upbraid": ['AH1 P B R EY2 D'],
  "upbraided": ['AH0 P B R EY1 D IH0 D'],
  "upbringing": ['AH1 P B R IH2 NG IH0 NG'],
  "upchurch": ['AH1 P CH ER2 CH'],
  "upcoming": ['AH1 P K AH2 M IH0 NG'],
  "update": ['AH0 P D EY1 T', 'AH1 P D EY2 T'],
  "updated": ['AH0 P D EY1 T IH0 D', 'AH1 P D EY2 T IH0 D'],
  "updates": ['AH0 P D EY1 T S', 'AH1 P D EY2 T S'],
  "updating": ['AH0 P D EY1 T IH0 NG', 'AH1 P D EY2 T IH0 NG'],
  "updegraff": ['AH1 P D IH0 G R AH0 F'],
  "updegrove": ['UW0 P D EH0 G R OW1 V IY0'],
  "updike": ['AH1 P D AY2 K'],
  "updike's": ['AH1 P D AY2 K Z'],
  "updraft": ['AH1 P D R AE2 F T'],
  "updrafts": ['AH1 P D R AE2 F T S'],
  "updyke": ['AH1 P D AY2 K'],
  "upfront": ['AH1 P F R AH2 N T'],
  "upgrade": ['AH0 P G R EY1 D', 'AH1 P G R EY1 D'],
  "upgraded": ['AH0 P G R EY1 D IH0 D', 'AH1 P G R EY2 D IH0 D'],
  "upgrader": ['AH1 P G R EY2 D ER0'],
  "upgrades": ['AH0 P G R EY1 D Z', 'AH1 P G R EY2 D Z'],
  "upgrading": ['AH0 P G R EY1 D IH0 NG', 'AH1 P G R EY2 D IH0 NG'],
  "upham": ['AH1 P AH0 M'],
  "upheaval": ['AH0 P HH IY1 V AH0 L'],
  "upheavals": ['AH0 P HH IY1 V AH0 L Z'],
  "upheld": ['AH0 P HH EH1 L D'],
  "uphill": ['AH1 P HH IH1 L'],
  "uphoff": ['AH1 P HH AO2 F'],
  "uphold": ['AH0 P HH OW1 L D'],
  "upholding": ['AH0 P HH OW1 L D IH0 NG'],
  "upholds": ['AH0 P HH OW1 L D Z'],
  "upholster": ['AH0 P OW1 L S T ER0'],
  "upholstered": ['AH0 P OW1 L S T ER0 D'],
  "upholstery": ['AH0 P OW1 L S T ER0 IY0'],
  "upjohn": ['AH1 P JH AA2 N'],
  "upjohn's": ['AH1 P JH AA2 N Z'],
  "upkeep": ['AH1 P K IY2 P'],
  "upland": ['AH1 P L AH0 N D'],
  "uplands": ['AH1 P L AH0 N D Z'],
  "uplift": ['AH1 P L IH0 F T'],
  "uplifted": ['AH1 P L IH0 F T IH0 D'],
  "uplifting": ['AH1 P L IH2 F T IH0 NG'],
  "uplinger": ['UW1 P AH0 L IH0 NG ER0', 'UW1 P L IH0 NG ER0'],
  "uplink": ['AH1 P L IH2 NG K'],
  "uplinked": ['UW1 P L IH2 NG K D'],
  "uplinks": ['UW1 P L IH2 NG K S'],
  "upload": ['AH1 P L OW2 D'],
  "uploaded": ['AH1 P L OW2 D IH0 D'],
  "uploading": ['AH1 P L OW2 D IH0 NG'],
  "uploads": ['AH1 P L OW2 D Z'],
  "upmanship": ['AH1 P M AH0 N SH IH2 P'],
  "upmarket": ['AH1 P M AA2 R K AH0 T'],
  "upon": ['AH0 P AA1 N'],
  "upp": ['AH1 P'],
  "upped": ['AH1 P T'],
  "upper": ['AH1 P ER0'],
  "upperclass": ['AH1 P ER0 K L AE2 S'],
  "upperman": ['AH1 P ER0 M AH0 N'],
  "uppermost": ['AH1 P ER0 M OW2 S T'],
  "uppers": ['AH1 P ER0 Z'],
  "upping": ['AH1 P IH0 NG'],
  "uppity": ['AH1 P AH0 T IY0'],
  "upright": ['AH0 P R AY1 T', 'AH1 P R AY2 T'],
  "uprights": ['AH1 P R AY2 T S'],
  "uprise": ['AH1 P R AY0 Z'],
  "uprising": ['AH0 P R AY1 Z IH0 NG', 'AH1 P R AY2 Z IH0 NG'],
  "uprisings": ['AH1 P R AY2 Z IH0 NG Z'],
  "upriver": ['AH2 P R IH1 V ER0'],
  "uproar": ['AH1 P R AO2 R'],
  "uproot": ['AH0 P R UW1 T'],
  "uprooted": ['AH0 P R UW1 T IH0 D'],
  "uprooting": ['AH0 P R UW1 T IH0 NG'],
  "ups": ['AH1 P S'],
  "upscale": ['AH1 P S K EY2 L'],
  "upset": ['AH0 P S EH1 T', 'AH1 P S EH2 T'],
  "upsets": ['AH0 P S EH1 T S', 'AH1 P S EH2 T S'],
  "upsetting": ['AH0 P S EH1 T IH0 NG'],
  "upshaw": ['AH1 P SH AO2'],
  "upshot": ['AH1 P SH AA2 T'],
  "upshur": ['AH1 P SH ER0'],
  "upside": ['AH1 P S AY1 D'],
  "upside-down": ['AH1 P S AY0 D D AW2 N'],
  "upsize": ['AH1 P S AY1 Z'],
  "upsizing": ['AH1 P S AY1 Z IH0 NG'],
  "upson": ['AH1 P S AH0 N'],
  "upstage": ['AH0 P S T EY1 JH'],
  "upstaged": ['AH1 P S T EY1 JH D'],
  "upstairs": ['AH0 P S T EH1 R Z'],
  "upstanding": ['AH1 P S T AE2 N D IH0 NG'],
  "upstart": ['AH1 P S T AA2 R T'],
  "upstarting": ['AH1 P S T AA2 R T IH0 NG'],
  "upstarts": ['AH0 P S T AA1 R T S'],
  "upstate": ['AH1 P S T EY1 T'],
  "upstream": ['AH1 P S T R IY1 M'],
  "upsurge": ['AH1 P S ER2 JH'],
  "upswing": ['AH0 P S W IH1 NG', 'AH1 P S W IH2 NG'],
  "upswings": ['AH1 P S W IH2 NG Z'],
  "uptain": ['AH0 P T EY1 N'],
  "uptake": ['AH1 P T EY2 K'],
  "uptegrove": ['AH1 P T IH0 G R AH0 V'],
  "uptick": ['AH1 P T IH2 K'],
  "upticks": ['AH1 P T IH2 K S'],
  "uptight": ['AH0 P T AY1 T'],
  "upton": ['AH1 P T AH0 N'],
  "uptown": ['AH1 P T AW1 N'],
  "uptrend": ['AH1 P T R EH2 N D'],
  "upturn": ['AH0 P T ER1 N', 'AH1 P T ER2 N'],
  "upturns": ['AH1 P T ER2 N Z'],
  "upvote": ['AH1 P V OW1 T'],
  "upvoted": ['AH1 P V OW1 T IH0 D'],
  "upvotes": ['AH1 P V OW1 T S'],
  "upvoting": ['AH1 P V OW1 T IH0 NG'],
  "upward": ['AH1 P W ER0 D'],
  "upwardly": ['AH1 P W ER0 D L IY0'],
  "upwards": ['AH1 P W ER0 D Z'],
  "upwood": ['AH1 P W UH2 D'],
  "ur": ['ER1'],
  "ural": ['Y UH1 R AH0 L'],
  "urals": ['Y UH1 R AH0 L Z'],
  "uram": ['Y UW1 R AH0 M'],
  "uranga": ['ER0 AA1 NG G AH0'],
  "uranium": ['Y ER0 EY1 N IY0 AH0 M'],
  "uranus": ['Y UW1 R AH0 N AH0 S', 'Y ER1 AH0 N AH0 S'],
  "urbach": ['ER1 B AA0 K'],
  "urbain": ['ER0 B EY1 N'],
  "urban": ['ER1 B AH0 N'],
  "urban's": ['ER1 B AH0 N Z'],
  "urbana": ['ER0 B AE1 N AH0'],
  "urbanchuk": ['ER0 B AH1 N CH AH0 K'],
  "urbanczyk": ['ER0 B AH1 N CH IH0 K'],
  "urbane": ['ER0 B EY1 N'],
  "urbanek": ['ER0 B AE1 N EH0 K'],
  "urbani": ['ER0 B AA1 N IY0'],
  "urbaniak": ['ER0 B AE1 N IY0 AE0 K'],
  "urbanik": ['ER0 B AE1 N IH0 K'],
  "urbanism": ['ER1 B AH0 N IH0 Z M'],
  "urbanite": ['ER1 B AH0 N AY2 T'],
  "urbanites": ['ER1 B AH0 N AY2 T S'],
  "urbanization": ['ER2 B AH0 N AH0 Z EY1 SH AH0 N', 'ER2 B AH0 N IH0 Z EY1 SH AH0 N'],
  "urbanize": ['ER1 B AH0 N AY2 Z'],
  "urbanized": ['ER1 B AH0 N AY2 Z D'],
  "urbano": ['ER0 B AA1 N OW0'],
  "urbanowicz": ['ER0 B AE1 N AH0 V IH0 CH'],
  "urbanski": ['ER0 B AE1 N S K IY0'],
  "urbas": ['ER1 B AH0 Z'],
  "urbaszewski": ['ER2 B AH0 S UW1 S K IY0'],
  "urbina": ['ER0 B IY1 N AH0'],
  "urchin": ['ER1 CH AH0 N'],
  "urchins": ['ER1 CH AH0 N Z'],
  "urda": ['ER1 D AH0'],
  "urdangarin": ['ER0 D AE1 NG AA0 R IH0 N'],
  "urdiales": ['ER1 D IY0 EY0 L Z'],
  "urdu": ['ER0 D UW1'],
  "ure": ['Y UW1 R'],
  "urea": ['Y ER0 IY1 AH0'],
  "uremia": ['Y ER0 IY1 M IY0 AH0'],
  "uren": ['ER0 EH1 N'],
  "urena": ['ER0 EY1 N AH0'],
  "uresti": ['ER0 EH1 S T IY0'],
  "urethane": ['Y ER1 AH0 TH EY2 N'],
  "urethra": ['Y ER2 IY1 TH R AH0'],
  "urey": ['Y ER1 IY0'],
  "urfer": ['ER1 F ER0'],
  "urge": ['ER1 JH'],
  "urged": ['ER1 JH D'],
  "urgen": ['ER1 JH EH2 N'],
  "urgen's": ['ER1 JH EH2 N Z'],
  "urgency": ['ER1 JH AH0 N S IY0'],
  "urgent": ['ER1 JH AH0 N T'],
  "urgently": ['ER1 JH AH0 N T L IY0'],
  "urges": ['ER1 JH AH0 Z', 'ER1 JH IH0 Z'],
  "urging": ['ER1 JH IH0 NG'],
  "urgings": ['ER1 JH IH0 NG Z'],
  "urgo": ['ER1 G OW0'],
  "uri": ['Y ER1 IY0'],
  "uri's": ['Y ER1 IY0 Z'],
  "urian": ['Y ER1 IY0 AH0 N'],
  "uriarte": ['Y ER1 IY0 AA1 R T IY0'],
  "urias": ['Y ER0 IY1 AH0 Z'],
  "uribe": ['Y ER0 IY1 B IY0'],
  "uric": ['Y ER1 IH0 K'],
  "urich": ['Y ER1 IH0 K'],
  "urick": ['Y ER1 R IH0 K'],
  "urie": ['Y ER1 IY0'],
  "uriegas": ['Y ER0 IY1 G AH0 Z'],
  "urinalysis": ['Y ER2 AH0 N AE1 L AH0 S IH0 S'],
  "urinary": ['Y ER1 AH0 N EH2 R IY0'],
  "urinate": ['Y ER1 AH0 N EY2 T'],
  "urinating": ['Y ER1 AH0 N EY2 T IH0 NG'],
  "urine": ['Y ER1 AH0 N', 'Y UH1 R AH0 N'],
  "urioste": ['Y ER0 IY0 OW1 S T IY0'],
  "urkel": ['ER1 K AH0 L'],
  "url": ['Y UW2 AA2 R EH1 L', 'UH1 R L'],
  "urls": ['Y UW1 AA1 R EH1 L Z'],
  "urman": ['ER1 M AH0 N'],
  "urn": ['ER1 N'],
  "urness": ['ER1 N AH0 S'],
  "urokinase": ['Y ER2 AH0 K AH0 N EY1 Z'],
  "urologist": ['Y ER0 AA1 L AH0 JH AH0 S T'],
  "urologists": ['Y ER0 AA1 L AH0 JH AH0 S T S'],
  "urology": ['Y ER0 AA1 L AH0 JH IY0'],
  "urquhart": ['ER1 K AA2 R T'],
  "urquidez": ['ER0 K W IY1 D EH0 Z'],
  "urquidi": ['ER0 K W IY1 D IY0'],
  "urquiza": ['ER0 K W IY1 Z AH0'],
  "urrea": ['ER0 IY1 AH0'],
  "urrutia": ['ER0 UW1 SH AH0'],
  "ursa": ['ER1 S AH0'],
  "urschel": ['ER1 SH AH0 L'],
  "ursery": ['ER1 S ER0 IY0'],
  "ursie": ['ER1 S IY0'],
  "ursin": ['ER1 S IH0 N'],
  "ursini": ['ER0 S IY1 N IY0'],
  "urso": ['ER1 S OW0'],
  "ursola": ['ER0 S OW1 L AH0'],
  "urson": ['ER1 S AH0 N'],
  "urstadt": ['ER1 S T AE2 T'],
  "ursula": ['ER1 S AH0 L AH0'],
  "ursus": ['ER1 S AH0 S'],
  "ursy": ['ER1 S IY0'],
  "urton": ['ER1 T AH0 N'],
  "uruguay": ['Y ER1 AH0 G W EY2', 'Y ER1 AH0 G W AY2'],
  "uruguay's": ['Y ER1 AH0 G W EY2 Z', 'Y ER1 AH0 G W AY2 Z'],
  "uruguayan": ['Y ER2 AH0 G W EY1 AH0 N', 'Y ER2 AH0 G W AY1 AH0 N'],
  "urwin": ['ER1 W AH0 N'],
  "ury": ['Y UW1 R IY0', 'Y ER1 IY0'],
  "us": ['AH1 S', 'Y UW2 EH1 S'],
  "usa": ['Y UW2 EH2 S EY1'],
  "usa's": ['Y UW1 EH1 S EY1 Z'],
  "usable": ['Y UW1 Z AH0 B AH0 L'],
  "usafe": ['Y UW1 S EY0 F'],
  "usage": ['Y UW1 S AH0 JH', 'Y UW1 S IH0 JH'],
  "usages": ['Y UW1 S AH0 JH AH0 Z', 'Y UW1 S IH0 JH IH0 Z'],
  "usaid": ['Y UW2 EH1 S EY1 D'],
  "usair": ['Y UW2 EH2 S EH1 R'],
  "usair's": ['Y UW2 EH2 S EH1 R Z'],
  "usairways": ['Y UW2 EH2 S EH1 R W EY2 Z'],
  "usameribancs": ['Y UW2 EH2 S AH0 M EH1 R IH0 B AE2 N K S'],
  "usb": ['Y UW1 EH1 S B IY1'],
  "usbancorp": ['Y UW2 EH2 S B AE1 NG K AO2 R P'],
  "usda": ['Y UW2 EH2 S D IY2 EY1'],
  "use": ['Y UW1 S', 'Y UW1 Z'],
  "usec": ['Y UW1 S EH0 K'],
  "used": ['Y UW1 Z D'],
  "useful": ['Y UW1 S F AH0 L'],
  "useful's": ['Y UW1 S F AH0 L Z'],
  "usefully": ['Y UW1 S F AH0 L IY0'],
  "usefulness": ['Y UW1 S F AH0 L N AH0 S'],
  "useless": ['Y UW1 S L AH0 S'],
  "uselman": ['Y UW1 S AH0 L M AH0 N'],
  "uselton": ['Y UW1 S AH0 L T AH0 N'],
  "usenet": ['Y UW1 Z N EH2 T'],
  "user": ['Y UW1 Z ER0'],
  "user's": ['Y UW1 Z ER0 Z'],
  "username": ['Y UW1 Z ER0 N EY2 M'],
  "usernames": ['Y UW1 Z ER0 N EY2 M Z'],
  "users": ['Y UW1 Z ER0 Z'],
  "users'": ['Y UW1 Z ER0 Z'],
  "usery": ['Y UW1 Z ER0 IY0'],
  "uses": ['Y UW1 S AH0 Z', 'Y UW1 S IH0 Z', 'Y UW1 Z AH0 Z', 'Y UW1 Z IH0 Z'],
  "usher": ['AH1 SH ER0'],
  "ushered": ['AH1 SH ER0 D'],
  "ushering": ['AH1 SH ER0 IH0 NG'],
  "ushers": ['AH1 SH ER0 Z'],
  "usines": ['Y UW2 Z IY1 N Z'],
  "using": ['Y UW1 Z IH0 NG'],
  "usinor": ['Y UW1 S AH0 N ER0'],
  "usmc": ['Y UW1 EH1 S EH1 M S IY1'],
  "usoniam": ['Y UW0 S OW1 N IY0 AH0 M'],
  "usrey": ['AH1 S R IY0'],
  "usry": ['AH1 S R IY0'],
  "uss": ['Y UW2 EH2 S EH1 S'],
  "ussery": ['AH1 S ER0 IY0'],
  "ussr": ['Y UW2 EH2 S EH2 S AA1 R'],
  "ustasha": ['Y UW0 S T AA1 SH AH0'],
  "ustinov": ['UW1 S T IH0 N AA0 V', 'Y UW1 S T IH0 N AA0 V'],
  "ustrust": ['Y UW1 EH1 S T R AH1 S T'],
  "usual": ['Y UW1 ZH AH0 W AH0 L', 'Y UW1 ZH UW0 AH0 L'],
  "usually": ['Y UW1 ZH AH0 W AH0 L IY0', 'Y UW1 ZH AH0 L IY0'],
  "usurp": ['Y UW2 S ER1 P'],
  "usurpation": ['Y UW2 S ER0 P EY1 SH AH0 N'],
  "usurped": ['Y UW2 S ER1 P T'],
  "usurping": ['Y UW2 S ER1 P IH0 NG'],
  "usurps": ['Y UW2 S ER1 P S'],
  "usury": ['Y UW1 ZH ER0 IY0', 'Y UW1 Z ER0 IY0'],
  "utah": ['Y UW1 T AO2'],
  "utah's": ['Y UW1 T AA0 Z'],
  "utamaro": ['Y UW2 T AH0 M AA1 R OW0'],
  "utech": ['Y UW1 T EH2 K'],
  "utecht": ['Y UW1 T EH2 K T'],
  "utensil": ['Y UW0 T EH1 N S AH0 L'],
  "utensils": ['Y UW0 T EH1 N S AH0 L Z'],
  "uterine": ['Y UW1 T ER0 AH0 N'],
  "utero": ['Y UW1 T ER0 OW0'],
  "uterus": ['Y UW1 T ER0 AH0 S'],
  "utgoff": ['AH1 T G AO0 F'],
  "uthe": ['AH1 DH', 'AH1 TH'],
  "uther": ['Y UW1 TH ER0', 'AH1 DH ER0'],
  "utica": ['Y UW1 T AH0 K AH0', 'Y UW1 T IY0 K AH0'],
  "utilicorp": ['Y UW0 T IH1 L AH0 K AO0 R P', 'Y UW0 T IH1 L AH0 K AO0 R'],
  "utilitarian": ['Y UW0 T IH2 L AH0 T EH1 R IY0 AH0 N'],
  "utilities": ['Y UW0 T IH1 L AH0 T IY0 Z'],
  "utilities'": ['Y UW0 T IH1 L AH0 T IY0 Z'],
  "utility": ['Y UW0 T IH1 L AH0 T IY0'],
  "utility's": ['Y UW0 T IH1 L AH0 T IY0 Z'],
  "utilization": ['Y UW2 T AH0 L AH0 Z EY1 SH AH0 N'],
  "utilize": ['Y UW1 T AH0 L AY2 Z'],
  "utilized": ['Y UW1 T AH0 L AY2 Z D'],
  "utilizes": ['Y UW1 T AH0 L AY2 Z IH0 Z'],
  "utilizing": ['Y UW1 T AH0 L AY2 Z IH0 NG'],
  "utke": ['AH1 T K IY0'],
  "utley": ['AH1 T L IY0'],
  "utmost": ['AH1 T M OW2 S T'],
  "utopia": ['Y UW0 T OW1 P IY0 AH0'],
  "utopian": ['Y UW0 T OW1 P IY0 AH0 N'],
  "utopians": ['Y UW0 T OW1 P IY0 AH0 N Z'],
  "utopias": ['Y UW0 T OW1 P IY0 AH0 Z'],
  "utsey": ['AH1 T S IY0'],
  "utsumi": ['UW0 T S UW1 M IY0'],
  "utt": ['AH1 T'],
  "uttech": ['AH1 T EH2 K'],
  "utter": ['AH1 T ER0'],
  "utterance": ['AH1 T ER0 AH0 N S'],
  "utterances": ['AH1 T ER0 AH0 N S IH0 Z'],
  "uttered": ['AH1 T ER0 D'],
  "uttering": ['AH1 T ER0 IH0 NG'],
  "utterly": ['AH1 T ER0 L IY0'],
  "utters": ['AH1 T ER0 Z'],
  "utz": ['AH1 T S'],
  "utzinger": ['AH1 T Z IH0 NG ER0'],
  "uv": ['Y UW1 V IY1'],
  "uva": ['Y UW1 V AH0', 'Y UW1 V IY1 EY1'],
  "uwe": ['Y UW1'],
  "uy": ['UW1 IY0', 'Y UW1 W AY1'],
  "uyeda": ['AY0 IY1 D AH0'],
  "uyehara": ['UW0 Y EY0 HH AA1 R AH0'],
  "uyeno": ['AY0 EY1 N OW0'],
  "uys": ['Y UW1 IY0 Z', 'Y UW1 W AY1 EH1 S'],
  "uzbek": ['UW1 Z B EH0 K'],
  "uzbekistan": ['UW0 Z B EH1 K IH0 S T AE2 N'],
  "uzbeks": ['UW1 Z B EH0 K S'],
  "uzelac": ['AH0 Z EH1 L AH0 K'],
  "uzi": ['UW1 Z IY0'],
  "uzis": ['UW1 Z IY0 Z'],
  "uzmack": ['UW1 Z M AE2 K'],
  "uzzell": ['UW0 Z EH1 L'],
  "uzzle": ['AH1 Z AH0 L'],
  "v": ['V IY1'],
  "v's": ['V IY1 Z'],
  "v.": ['V IY1'],
  "v.'s": ['V IY1 Z'],
  "v.a.": ['V IY2 EY1'],
  "v.s": ['V IY1 Z'],
  "vaal": ['V AA1 L'],
  "vaapenfabrikk": ['V AA2 P EH1 N F AH0 B R IH0 K', 'V AA1 P AH0 N F AA2 B R IH0 K'],
  "vaca": ['V AE1 K AH0'],
  "vacancies": ['V EY1 K AH0 N S IY0 Z'],
  "vacancy": ['V EY1 K AH0 N S IY0'],
  "vacant": ['V EY1 K AH0 N T'],
  "vacanti": ['V AH0 K AE1 N T IY0'],
  "vacate": ['V EY1 K EY0 T'],
  "vacated": ['V EY0 K EY1 T IH0 D', 'V EY1 K EY0 T IH0 D'],
  "vacating": ['V EY1 K EY0 T IH0 NG'],
  "vacation": ['V EY0 K EY1 SH AH0 N'],
  "vacationed": ['V EY0 K EY1 SH AH0 N D'],
  "vacationer": ['V EY0 K EY1 SH AH0 N ER0'],
  "vacationers": ['V EY0 K EY1 SH AH0 N ER0 Z'],
  "vacationers'": ['V EY0 K EY1 SH AH0 N ER0 Z'],
  "vacationing": ['V EY0 K EY1 SH AH0 N IH0 NG', 'V EY0 K EY1 SH N IH0 NG'],
  "vacations": ['V EY0 K EY1 SH AH0 N Z'],
  "vacaville": ['V AE1 K AH0 V IH2 L'],
  "vacca": ['V AE1 K AH0'],
  "vaccarella": ['V AA0 K ER0 EH1 L AH0'],
  "vaccaro": ['V AH0 K AA1 R OW0'],
  "vaccina": ['V AE2 K S IY1 N AH0'],
  "vaccinate": ['V AE1 K S AH0 N EY0 T'],
  "vaccinated": ['V AE1 K S AH0 N EY0 T IH0 D'],
  "vaccination": ['V AE0 K S AH0 N EY1 SH AH0 N'],
  "vaccinations": ['V AE2 K S AH0 N EY1 SH AH0 N Z'],
  "vaccine": ['V AE2 K S IY1 N'],
  "vaccine's": ['V AE2 K S IY1 N Z'],
  "vaccines": ['V AE2 K S IY1 N Z'],
  "vaccinia": ['V AH0 CH IY1 N IY0 AH0'],
  "vacco": ['V AE1 K OW0'],
  "vacek": ['V AA1 CH EH2 K'],
  "vacha": ['V AE1 CH AH0'],
  "vachon": ['V AE1 CH AH0 N'],
  "vacillate": ['V AE1 S AH0 L EY2 T'],
  "vacillated": ['V AE1 S AH0 L EY0 T IH0 D'],
  "vacillates": ['V AE1 S AH0 L EY2 T S'],
  "vacillating": ['V AE1 S AH0 L EY2 T IH0 NG'],
  "vacillation": ['V AE2 S AH0 L EY1 SH AH0 N'],
  "vaclav": ['V AE1 K L AA2 V', 'V AA1 K L AA2 V'],
  "vaclavik": ['V AE1 K L AH0 V IH0 K'],
  "vacuous": ['V AE1 K Y UW0 AH0 S'],
  "vacutainer": ['V AE1 K Y UW0 T EY2 N ER0'],
  "vacuum": ['V AE1 K Y UW0 M'],
  "vacuumed": ['V AE1 K Y UW0 M D'],
  "vacuuming": ['V AE1 K Y UW0 M IH0 NG'],
  "vacuums": ['V AE1 K Y UW0 M Z'],
  "vadala": ['V AA0 D AA1 L AH0'],
  "vadas": ['V AA1 D AA0 Z'],
  "vadehra": ['V AH0 D EY1 R AH0'],
  "vaden": ['V EY1 D AH0 N'],
  "vader": ['V EY1 D ER0'],
  "vadim": ['V AA0 D IY1 M'],
  "vadnais": ['V AH0 D N EY1'],
  "vadney": ['V AE1 D N IY0'],
  "vaeth": ['V IY1 TH'],
  "vagabond": ['V AE1 G AH0 B AA0 N D'],
  "vagabonds": ['V AE1 G AH0 B AA0 N D Z'],
  "vagaries": ['V EY1 G ER0 IY0 Z'],
  "vagary": ['V EY1 G ER0 IY0'],
  "vagelos": ['V AH0 JH EH1 L OW0 S'],
  "vagina": ['V AH0 JH AY1 N AH0'],
  "vaginal": ['V AH0 JH AY1 N AH0 L'],
  "vagrancy": ['V EY1 G R AH0 N S IY0'],
  "vagrant": ['V EY1 G R AH0 N T'],
  "vagrants": ['V EY1 G R AH0 N T S'],
  "vague": ['V EY1 G'],
  "vaguely": ['V EY1 G L IY0'],
  "vagueness": ['V EY1 G N IH0 S'],
  "vaguer": ['V EY1 G ER0'],
  "vaguest": ['V EY1 G IH0 S T'],
  "vaguine": ['V AH0 G W IY1 N'],
  "vahey": ['V AE1 HH EY2'],
  "vahid": ['V AA0 HH IY1 D'],
  "vahl": ['V AA1 L'],
  "vahle": ['V EY1 HH AH0 L'],
  "vail": ['V EY1 L'],
  "vailab": ['V EY1 L AE2 B'],
  "vaile": ['V EY1 L'],
  "vaillancourt": ['V EY1 L AH0 N K AO2 R T'],
  "vain": ['V EY1 N'],
  "vainly": ['V EY1 N L IY0'],
  "vajda": ['V AY1 D AH0'],
  "vajna": ['V AY1 N AH0'],
  "vakuf": ['V AE1 K AH2 F'],
  "val": ['V AE1 L'],
  "vala": ['V AA1 L AH0'],
  "valable": ['V AE1 L AH0 B AH0 L'],
  "valade": ['V AA0 L AA1 D EY0'],
  "valadez": ['V AA0 L AA1 D EH0 Z'],
  "valasek": ['V AH0 L AA1 S EH0 K'],
  "valborga": ['V AA0 L B AO1 R G AH0'],
  "valcourt": ['V AH0 L K AO1 R T'],
  "valda": ['V AA1 L D AH0'],
  "valdemar": ['V AA0 L D EY0 M AA1 R'],
  "valderrama": ['V AA0 L D EH0 R AA1 M AH0'],
  "valdes": ['V AE0 L D EH1 Z'],
  "valdes-perez": ['V AE2 L D EH1 Z P ER0 EH1 Z'],
  "valdeshari": ['V AE2 L D EH0 SH AA1 R IY0'],
  "valdez": ['V AE0 L D EH1 Z'],
  "valdis": ['V AE1 L D IH0 S'],
  "valdivia": ['V AA0 L D IY1 V IY0 AH0'],
  "valdosta": ['V AE2 L D AO1 S T AH0'],
  "valdovinos": ['V AA0 L D OW0 V IY1 N OW0 Z'],
  "vale": ['V EY1 L'],
  "valea": ['V AH0 L IY1 AH0'],
  "valeda": ['V AA0 L EY1 D AH0'],
  "valedictorian": ['V AE2 L AH0 D IH2 K T AO1 R IY0 AH0 N'],
  "valedictory": ['V AE2 L AH0 D IH1 K T ER0 IY0'],
  "valek": ['V AA1 L EH0 K'],
  "valence": ['V EY1 L AH0 N S'],
  "valencia": ['V AH0 L EH1 N S IY0 AH0'],
  "valencia's": ['V AH0 L EH1 N S IY0 AH0 Z'],
  "valencienne": ['V AH0 L EH1 N S IY0 EH2 N'],
  "valens": ['V AE1 L AH0 N Z'],
  "valensuela": ['V AE2 L AH0 N Z W EY1 L AH0'],
  "valensuela's": ['V AE2 L AH0 N Z W EY1 L AH0 Z'],
  "valent": ['V AE1 L AH0 N T'],
  "valenta": ['V AH0 L EH1 N T AH2'],
  "valente": ['V AA0 L EH1 N T IY2'],
  "valenti": ['V AA0 L EH1 N T IY2'],
  "valentia": ['V AA0 L EH1 N SH AH0'],
  "valentin": ['V AA2 L EH1 N T IY2 N'],
  "valentina": ['V AA2 L EH0 N T IY1 N AH0'],
  "valentine": ['V AE1 L AH0 N T AY2 N'],
  "valentine's": ['V AE1 L AH0 N T AY2 N Z'],
  "valentines": ['V AE1 L AH0 N T AY2 N Z'],
  "valentini": ['V AE2 L AH0 N T IY1 N IY0'],
  "valentino": ['V AE2 L AH0 N T IY1 N OW0'],
  "valentino's": ['V AE2 L AH0 N T IY1 N OW0 Z'],
  "valenza": ['V AH0 L EH1 N Z AH0'],
  "valenzano": ['V AE2 L EH0 N Z AA1 N OW0'],
  "valenzuela": ['V AA0 L EH0 N Z UW1 L AH0'],
  "valeo": ['V AE1 L IY0 OW0'],
  "valera": ['V AH0 L EH1 R AH0'],
  "valeri": ['V AA0 L EH1 R IY0'],
  "valeria": ['V AH0 L IY1 R IY0 AH0'],
  "valerian": ['V AH0 L IH1 R IY0 AH0 N'],
  "valeriano": ['V AA0 L ER0 IY0 AA1 N OW0'],
  "valerie": ['V AE1 L ER0 IY0'],
  "valerio": ['V AH0 L IY1 R IY0 OW0'],
  "valero": ['V AH0 L EH1 R OW0'],
  "valery": ['V AE1 L ER0 IY0'],
  "vales": ['V EY1 L Z'],
  "valeska": ['V AA0 L EY1 S K AH0'],
  "valet": ['V AE0 L EY1'],
  "valhalla": ['V AE2 L HH AE1 L AH0'],
  "valhi": ['V AE1 L HH IY0'],
  "valia": ['V AE1 L Y AH0'],
  "valiant": ['V AE1 L Y AH0 N T'],
  "valiantly": ['V AE1 L Y AH0 N T L IY0'],
  "valid": ['V AE1 L IH0 D'],
  "valida": ['V AA0 L IY1 D AH0'],
  "validate": ['V AE1 L AH0 D EY0 T'],
  "validated": ['V AE1 L AH0 D EY0 T IH0 D'],
  "validates": ['V AE1 L AH0 D EY2 T S'],
  "validating": ['V AE1 L AH0 D EY2 T IH0 NG'],
  "validation": ['V AE2 L AH0 D EY1 SH AH0 N'],
  "valided": ['V AE1 L AH0 D IH0 D'],
  "validity": ['V AH0 L IH1 D AH0 T IY0', 'V AH0 L IH1 D IH0 T IY0'],
  "validly": ['V AE1 L IH0 D L IY0'],
  "valiente": ['V AA0 L IY1 N T IY0'],
  "valin": ['V AE1 L IH0 N'],
  "valiquette": ['V AE1 L IH0 K EH2 T'],
  "valis": ['V AE1 L IH0 S'],
  "valium": ['V EY1 L IY0 AH0 M'],
  "valk": ['V AO1 K'],
  "valko": ['V AE1 L K OW0'],
  "valkyrie": ['V AE2 L K IY1 R IY2 AH0'],
  "valla": ['V AE1 L AH0'],
  "valladares": ['V AA0 L AA0 D AA1 R EH0 S'],
  "vallance": ['V AE1 L AH0 N S'],
  "vallandingham": ['V AE0 L AH1 N D IH0 NG HH AE2 M'],
  "vallario": ['V AA0 L AA1 R IY0 OW0'],
  "vallarta": ['V AE2 L AA1 R T AA0'],
  "vallas": ['V AE1 L AH0 Z'],
  "valle": ['V EY1 L'],
  "valleau": ['V AH0 L OW1'],
  "vallee": ['V AE1 L IY0'],
  "vallegrande": ['V AE2 L IY0 G R AE1 N D IY0', 'V AE2 L IY0 G R AE1 N D'],
  "vallejo": ['V AA0 L EY1 Y OW0'],
  "vallejos": ['V AA0 L EY1 Y OW0 Z'],
  "vallely": ['V EY1 L IY0', 'V AE1 L IY0'],
  "vallery": ['V AE1 L ER0 IY0'],
  "valles": ['V EY1 L Z'],
  "vallette": ['V AE2 L EH1 T'],
  "valley": ['V AE1 L IY0'],
  "valley's": ['V AE1 L IY0 Z'],
  "valleys": ['V AE1 L IY0 Z'],
  "vallez": ['V AA0 L EH1 Z'],
  "valli": ['V AE1 L IY0'],
  "valliant": ['V AA0 L IY1 AH0 N T'],
  "vallie": ['V AO1 L IY0'],
  "vallier": ['V AE1 L IY0 ER0'],
  "valliere": ['V AE1 L IY0 EH0 R'],
  "vallis": ['V AE1 L IH0 S'],
  "vallo": ['V AE1 L OW0'],
  "vallone": ['V AA0 L OW1 N IY0'],
  "vallot": ['V AE1 L AH0 T'],
  "valls": ['V AO1 L Z'],
  "valmeyer": ['V AE1 L M AY0 ER0'],
  "valmont": ['V AO1 L M AA2 N T'],
  "valois": ['V AE0 L W AA1'],
  "valone": ['V AH0 L OW1 N'],
  "valonia": ['V AH0 L OW1 N Y AH0'],
  "valor": ['V AE1 L ER0'],
  "valoree": ['V AE1 L ER0 IY1'],
  "valores": ['V AH0 L AO1 R EH0 Z'],
  "valparaiso": ['V AE2 L P ER0 EY1 S OW0'],
  "valsella": ['V AO0 L S EH1 L AH0'],
  "valspar": ['V AE1 L Z P AA0 R'],
  "valtek": ['V AO1 L T EH2 K'],
  "valtierra": ['V AA0 L T IH1 R AH0'],
  "valu": ['V AE1 L Y UW0'],
  "valuable": ['V AE1 L Y AH0 B AH0 L', 'V AE1 L Y UW0 B AH0 L'],
  "valuables": ['V AE1 L Y AH0 B AH0 L Z', 'V AE1 L Y UW0 B AH0 L Z'],
  "valuation": ['V AE0 L Y UW0 EY1 SH AH0 N'],
  "valuations": ['V AE0 L Y UW0 EY1 SH AH0 N Z'],
  "value": ['V AE1 L Y UW0'],
  "valued": ['V AE1 L Y UW0 D'],
  "valueless": ['V AE1 L Y UW0 L AH0 S'],
  "valuepoint": ['V AE1 L Y UW0 P OY2 N T'],
  "values": ['V AE1 L Y UW0 Z'],
  "values'": ['V AE1 L Y UW0 Z'],
  "valuevision": ['V AE1 L Y UW0 V IH2 ZH AH0 N'],
  "valuing": ['V AE1 L Y UW0 IH0 NG'],
  "valujet": ['V AE1 L Y UW0 JH EH2 T'],
  "valujet's": ['V AE1 L Y UW0 JH EH2 T S'],
  "valukas": ['V AH0 L UW1 K AH0 S'],
  "valvano": ['V AA0 L V AA1 N OW0'],
  "valve": ['V AE1 L V'],
  "valverde": ['V AA0 L V EH1 R D EY0'],
  "valves": ['V AE1 L V Z'],
  "valvo": ['V AA1 L V OW0'],
  "valvoline": ['V AE1 L V AH0 L IY2 N'],
  "vamos": ['V AA1 M OW0 Z'],
  "vamp": ['V AE1 M P'],
  "vampire": ['V AE1 M P AY0 R'],
  "vampire's": ['V AE1 M P AY0 R Z'],
  "vampires": ['V AE1 M P AY0 R Z'],
  "vampiric": ['V AE0 M P IH1 R IH0 K'],
  "van": ['V AE1 N'],
  "van-gogh": ['V AE1 N G OW1'],
  "vana": ['V AE1 N AH0'],
  "vanacker": ['V AE0 N AE1 K ER0'],
  "vanacore": ['V AA0 N AA0 K AO1 R IY0'],
  "vanadium": ['V AH0 N EY1 D IY0 AH0 M'],
  "vanaken": ['V AE1 N AH0 K AH0 N'],
  "vanallen": ['V AH0 N AO1 L AH0 N'],
  "vanalstine": ['V AE0 N AE1 L S T IY0 N'],
  "vanalstyne": ['V AE1 N AH0 L S T AY2 N'],
  "vanaman": ['V AE0 N AO1 M AH0 N'],
  "vanamburg": ['V AE0 N AE1 M B ER0 G'],
  "vanamburgh": ['V AE0 N AE1 M B ER0 G'],
  "vanantwerp": ['V AH0 N AE1 N T W ER0 P'],
  "vanarsdale": ['V AE1 N ER0 Z D EY2 L'],
  "vanarsdall": ['V AH0 N AA1 R S D AH0 L'],
  "vanasse": ['V AE0 N AE1 S'],
  "vanatta": ['V AA0 N AA1 T AH0'],
  "vanatter": ['V AA0 N AE1 T ER0'],
  "vanauken": ['V AE0 N AW1 K AH0 N'],
  "vanausdall": ['V AE0 N AW1 S D AH0 L'],
  "vanbebber": ['V AE2 N B EH1 B ER0'],
  "vanbeek": ['V AE2 N B IY1 K'],
  "vanbenschoten": ['V AE0 N B EH1 N SH AH0 T AH0 N'],
  "vanbergen": ['V AE0 N B ER1 G AH0 N'],
  "vanbibber": ['V AE2 N B IH1 B ER0'],
  "vanbiesbrouck": ['V AE2 N B IY1 AH0 S B R UW2 K'],
  "vanblarcom": ['V AE2 N B L AA1 R K AH0 M'],
  "vanblaricom": ['V AE2 N B L AE1 R IH0 K AH0 M'],
  "vanbrocklin": ['V AE2 N B R AA1 K L IH0 N'],
  "vanbrunt": ['V AE2 N B R AH1 N T'],
  "vanburen": ['V AE0 N B Y UW1 R AH0 N'],
  "vanbuskirk": ['V AE2 N B AH1 S K ER0 K'],
  "vancamp": ['V AE2 N K AE1 M P'],
  "vancampen": ['V AE2 N K AE1 M P AH0 N'],
  "vance": ['V AE1 N S'],
  "vancil": ['V AE1 N S AH0 L'],
  "vancise": ['V AA1 N CH AY0 S'],
  "vancleave": ['V AE1 N K L AH0 V'],
  "vancleef": ['V AE2 N K L IY1 F'],
  "vancleve": ['V AE2 N K L IY1 V'],
  "vancomycin": ['V AE2 N K OW0 M AY1 S IH0 N'],
  "vancott": ['V AH0 N K AA1 T'],
  "vancourt": ['V AH0 N K AO1 R T'],
  "vancouver": ['V AE0 N K UW1 V ER0'],
  "vancouver's": ['V AE0 N K UW1 V ER0 Z'],
  "vancura": ['V AA0 N K UH1 R AH0'],
  "vancuren": ['V AE0 N K Y UW1 R AH0 N'],
  "vandaele": ['V AE0 N D EH1 L'],
  "vandagriff": ['V AE2 N D AE1 G R IH0 F', 'V AE1 N D AH0 G R IH0 F'],
  "vandal": ['V AE1 N D AH0 L'],
  "vandalen": ['V AE2 N D AE1 L AH0 N'],
  "vandalism": ['V AE1 N D AH0 L IH0 Z AH0 M'],
  "vandalize": ['V AE1 N D AH0 L AY2 Z'],
  "vandalized": ['V AE1 N D AH0 L AY2 Z D'],
  "vandalizing": ['V AE1 N D AH0 L AY2 Z IH0 NG'],
  "vandall": ['V AE2 N D AO1 L'],
  "vandals": ['V AE1 N D AH0 L Z'],
  "vandam": ['V AE2 N D AE1 M'],
  "vandamme": ['V AE2 N D AE1 M'],
  "vande": ['V AE1 N D'],
  "vandeberg": ['V AE1 N D AH0 B ER0 G'],
  "vandecar": ['V AE1 N D AH0 K AA2 R'],
  "vandegrift": ['V AE1 N D AH0 G R IH0 F T'],
  "vandehei": ['V AE1 N D AH0 HH AY0'],
  "vandehey": ['V AE1 N D IH0 HH IY0'],
  "vandella": ['V AE2 N D EH1 L AH0'],
  "vandellas": ['V AE2 N D EH1 L AH0 Z'],
  "vandeman": ['V AE1 N D AH0 M AH0 N'],
  "vandemark": ['V AE1 N D AH0 M AA2 R K'],
  "vanden": ['V AE1 N D AH0 N'],
  "vandenberg": ['V AE1 N D AH0 N B ER0 G'],
  "vandenbergh": ['V AE1 N D AH0 N B ER0 G'],
  "vandenberghe": ['V AE1 N D AH0 N B ER0 G'],
  "vandenboom": ['V AE1 N D AH0 N B UW2 M'],
  "vandenbos": ['V AE1 N D AH0 N B OW0 Z'],
  "vandenbosch": ['V AE1 N D AH0 N B AO2 SH'],
  "vandenbrink": ['V AE1 N D AH0 N B R IH0 NG K'],
  "vandenburg": ['V AE1 N D AH0 N B ER0 G'],
  "vandenburgh": ['V AE1 N D AH0 N B ER0 G'],
  "vandenheuvel": ['V AE1 N D AH0 N HH OY0 V AH0 L'],
  "vander": ['V AE1 N D ER0'],
  "vanderbeck": ['V AE1 N D ER0 B EH2 K'],
  "vanderbeek": ['V AE1 N D ER0 B IY2 K'],
  "vanderberg": ['V AE1 N D ER0 B ER0 G'],
  "vanderbilt": ['V AE1 N D ER0 B IH0 L T'],
  "vanderburg": ['V AE1 N D ER0 B ER0 G'],
  "vanderbush": ['V AE1 N D ER0 B UH0 SH'],
  "vandercook": ['V AE1 N D ER0 K UH2 K'],
  "vanderford": ['V AE1 N D ER0 F ER0 D'],
  "vandergriff": ['V AE1 N D ER0 G R IH0 F'],
  "vandergrift": ['V AE1 N D ER0 G R IH0 F T'],
  "vandergrift's": ['V AE1 N D ER0 G R IH2 F T S'],
  "vanderheide": ['V AE1 N D ER0 HH AY2 D'],
  "vanderheiden": ['V AE1 N D ER0 HH AY0 D AH0 N'],
  "vanderheyden": ['V AE1 N D ER0 HH EY0 D AH0 N'],
  "vanderhoef": ['V AE1 N D ER0 HH OW2 F'],
  "vanderhoff": ['V AE1 N D ER0 HH AO2 F'],
  "vanderhoof": ['V AE1 N D ER0 HH UH2 F'],
  "vanderhorst": ['V AE1 N D ER0 HH AO2 R S T'],
  "vanderkolk": ['V AE1 N D ER0 K OW2 K'],
  "vanderkooi": ['V AE1 N D ER0 K UW2 IY0'],
  "vanderlaan": ['V AE1 N D ER0 L AA2 N'],
  "vanderleest": ['V AE1 N D ER0 AH0 L IY2 S T'],
  "vanderlinde": ['V AE1 N D ER0 L IH2 N D'],
  "vanderlinden": ['V AE1 N D ER0 L IH2 N D AH0 N'],
  "vanderlip": ['V AE1 N D ER0 L IH2 P'],
  "vandermark": ['V AE1 N D ER0 M AA2 R K'],
  "vandermeer": ['V AE1 N D ER0 M IH2 R'],
  "vandermeulen": ['V AE1 N D ER0 M OY2 L AH0 N'],
  "vandermolen": ['V AE1 N D ER0 M AA2 L AH0 N'],
  "vanderploeg": ['V AE1 N D ER0 P L OW2 G'],
  "vanderpoel": ['V AE1 N D ER0 P OW2 L'],
  "vanderpol": ['V AE1 N D ER0 P AO2 L'],
  "vanderpool": ['V AE1 N D ER0 P UW2 L'],
  "vanderschaaf": ['V AE1 N D ER0 SH AA2 F'],
  "vanderslice": ['V AE1 N D ER0 S L AY2 S'],
  "vandersluis": ['V AE1 N D ER0 S L UW2 IH0 S', 'V AE1 N D ER0 S L UW2 S'],
  "vanderveen": ['V AE1 N D ER0 V IY2 N'],
  "vanderveer": ['V AE1 N D ER0 V IH2 R'],
  "vandervelde": ['V AE1 N D ER0 V EH2 L D'],
  "vandervelden": ['V AE1 N D ER0 V EH2 L D AH0 N'],
  "vandervliet": ['V AE1 N D ER0 V L IY2 T'],
  "vandervoort": ['V AE1 N D ER0 V UH2 R T'],
  "vandervort": ['V AE1 N D ER0 V AO2 R T'],
  "vanderwal": ['V AE1 N D ER0 W AA2 L'],
  "vanderwall": ['V AE1 N D ER0 W AA2 L'],
  "vanderweele": ['V AE1 N D ER0 W IY2 L'],
  "vanderweide": ['V AE1 N D ER0 W AY2 D'],
  "vanderwerf": ['V AE1 N D ER0 W ER2 F'],
  "vanderwerff": ['V AE1 N D ER0 W ER2 F'],
  "vanderwilt": ['V AE1 N D ER0 W IH2 L T'],
  "vanderwoude": ['V AE1 N D ER0 W AW2 D'],
  "vanderzanden": ['V AE1 N D ER0 Z AE2 N D AH0 N'],
  "vanderzee": ['V AE1 N D ER0 Z IY2'],
  "vandeusen": ['V AE0 N D OY1 S AH0 N'],
  "vandevander": ['V AE1 N D AH0 V AE2 N D ER0'],
  "vandeveer": ['V AE1 N D AH0 V IH2 R'],
  "vandevelde": ['V AE1 N D AH0 V EH2 L D'],
  "vandeven": ['V AE1 N D AH0 V AH0 N'],
  "vandevender": ['V AE1 N D AH0 V EH2 N D ER0'],
  "vandeventer": ['V AE1 N D AH0 V AH0 N T ER0'],
  "vandever": ['V AE2 N D IY1 V ER0'],
  "vandevoorde": ['V AE1 N D AH0 V UH0 R D'],
  "vandevoort": ['V AE1 N D AH0 V UH0 R T'],
  "vandewalker": ['V AE1 N D AH0 W AO2 K ER0'],
  "vandewalle": ['V AE0 N D UW1 EY0 L'],
  "vandewater": ['V AE1 N D AH0 W AO0 T ER0'],
  "vandezande": ['V AE1 N D AH0 Z IH0 N D'],
  "vandine": ['V AE2 N D AY1 N'],
  "vandiver": ['V AE1 N D AY2 V ER0'],
  "vandivier": ['V AE0 N D AY1 V IY0 ER0'],
  "vandoren": ['V AE0 N D AO1 R AH0 N'],
  "vandorn": ['V AE0 N D AO1 R N'],
  "vandriel": ['V AE2 N D R IY1 L'],
  "vandross": ['V AE2 N D R AO1 S'],
  "vandunk": ['V AE2 N D AH1 NG K'],
  "vandusen": ['V AE0 N D UW1 S AH0 N'],
  "vanduyn": ['V AE2 N D AY1 N'],
  "vanduyne": ['V AE2 N D AY1 N'],
  "vanduzer": ['V AE2 N D UW1 Z ER0'],
  "vandyck": ['V AE0 N D IH1 K'],
  "vandyk": ['V AE1 N D IH0 K'],
  "vandyke": ['V AE2 N D AY1 K'],
  "vandyken": ['V AE2 N D AY1 K AH0 N'],
  "vandyne": ['V AE1 N D AY2 N'],
  "vane": ['V EY1 N'],
  "vaneaton": ['V AE1 N AH0 T AA0 N'],
  "vanecek": ['V AE0 N EH1 S IH0 K'],
  "vaneck": ['V AA1 N EH0 K'],
  "vanegas": ['V AE0 N IY1 G AH0 Z'],
  "vanek": ['V AE0 N EH1 K'],
  "vanella": ['V AH0 N EH1 L AH0'],
  "vanengen": ['V AE0 N EH1 NG AH0 N'],
  "vanepps": ['V AE0 N EH1 P S'],
  "vanes": ['V EY1 N Z'],
  "vaness": ['V AE1 N AH0 S'],
  "vanessa": ['V AH0 N EH1 S AH0'],
  "vanessen": ['V AA1 N IH0 S AH0 N'],
  "vanetten": ['V AE1 N EH1 T AH0 N'],
  "vanevery": ['V AH0 N EH1 V R IY0'],
  "vanfleet": ['V AE2 N F L IY1 T'],
  "vanfossan": ['V AE0 N F AA1 S AH0 N'],
  "vanfossen": ['V AE0 N F AA1 S AH0 N'],
  "vang": ['V AE1 NG'],
  "vangel": ['V EY1 NG G AH0 L'],
  "vangelder": ['V EY1 NG G IH0 L D ER0'],
  "vangie": ['V AE1 NG IY0'],
  "vangieson": ['V AE1 NG G IY0 Z AH0 N'],
  "vangilder": ['V AE1 NG G IH0 L D ER0'],
  "vangorden": ['V AE1 NG G ER0 D AH0 N'],
  "vangorder": ['V AE1 NG G ER0 D ER0'],
  "vangorp": ['V AE1 NG G ER0 P'],
  "vanguard": ['V AE1 N G AA2 R D'],
  "vanguard's": ['V AE1 N G AA2 R D Z'],
  "vanguilder": ['V AE0 N G AY1 L D ER0'],
  "vangundy": ['V AH0 NG G AH1 N D IY0'],
  "vangy": ['V AE1 N JH IY0'],
  "vanhall": ['V AE2 N HH AO1 L'],
  "vanhandel": ['V AE2 N HH AE1 N D AH0 L'],
  "vanhecke": ['V AE2 N HH EH1 K'],
  "vanheel": ['V AE2 N HH IY1 L'],
  "vanhise": ['V AE0 N HH AY1 Z'],
  "vanhoesen": ['V AE2 N HH OW1 S AH0 N'],
  "vanhook": ['V AE2 N HH UH1 K'],
  "vanhoose": ['V AE2 N HH UW1 S'],
  "vanhooser": ['V AE2 N HH UW1 Z ER0'],
  "vanhoozer": ['V AE2 N HH UW1 Z ER0'],
  "vanhorn": ['V AE0 N HH AO1 R N'],
  "vanhorne": ['V AE0 N HH AO1 R N'],
  "vanhousen": ['V AE1 N HH AW2 S AH0 N'],
  "vanhouten": ['V AE0 N HH AA1 UW0 T AH0 N'],
  "vanhove": ['V AE0 N HH AH1 V'],
  "vanhoy": ['V AE1 N HH OY0'],
  "vanhuss": ['V AE2 N HH AH1 S'],
  "vanhyning": ['V AE2 N HH AY1 N IH0 NG'],
  "vania": ['V AA1 N IY0 AH0'],
  "vanier": ['V AE0 N IY1 ER0'],
  "vanik": ['V AA1 N IH0 K'],
  "vanilla": ['V AH0 N IH1 L AH0'],
  "vanilli": ['V AH0 N IH1 L IY0'],
  "vanillin": ['V AH0 N IH1 L IH0 N'],
  "vanish": ['V AE1 N IH0 SH'],
  "vanished": ['V AE1 N IH0 SH T'],
  "vanishes": ['V AE1 N IH0 SH IH0 Z'],
  "vanishing": ['V AE1 N IH0 SH IH0 NG'],
  "vanities": ['V AE1 N AH0 T IY0 Z'],
  "vanity": ['V AE1 N AH0 T IY0', 'V AE1 N IH0 T IY0'],
  "vankampen": ['V AE2 N K AE1 M P AH0 N'],
  "vankeuren": ['V AE1 NG K OY0 R AH0 N'],
  "vankirk": ['V AE1 NG K ER0 K'],
  "vankleeck": ['V AE1 NG K L IY2 K'],
  "vankuren": ['V AE1 NG K Y UW0 R AH0 N'],
  "vanlandingham": ['V AE2 N L AE1 N D IH0 NG HH AE2 M'],
  "vanlanen": ['V AE2 N L AE1 N AH0 N'],
  "vanlaningham": ['V AE2 N L AE1 N IH0 NG HH AE2 M'],
  "vanleer": ['V AE0 N L IH1 R'],
  "vanleeuwen": ['V AE0 N L UW1 AH0 N'],
  "vanleuven": ['V AE0 N L OY1 V AH0 N'],
  "vanliere": ['V AE0 N L IH1 R'],
  "vanliew": ['V AE1 N L IY0 UW0'],
  "vanloan": ['V AE1 N L OW2 N'],
  "vanloo": ['V AE1 N L UW2'],
  "vanlue": ['V AE2 N L UW1'],
  "vanluven": ['V AE2 N L UW1 V AH0 N'],
  "vanmaanen": ['V AE2 N M AA1 N AH0 N'],
  "vanmarter": ['V AE0 N M AA1 R T ER0'],
  "vanmatre": ['V AE0 N M EY1 T ER0'],
  "vanmeter": ['V AE0 N M IY1 T ER0'],
  "vanmetre": ['V AE0 N M IY1 T ER0'],
  "vann": ['V AE1 N'],
  "vanna": ['V AE1 N AH0'],
  "vanname": ['V AE1 N AH0 M'],
  "vannater": ['V AE0 N AE1 T ER0'],
  "vannatta": ['V AA0 N AA1 T AH0'],
  "vannatter": ['V AE0 N AE1 T ER0'],
  "vannatter's": ['V AE0 N AE1 T ER0 Z'],
  "vannelli": ['V AA0 N EH1 L IY0'],
  "vanness": ['V AE0 N IY1 S'],
  "vannest": ['V AE0 N IY1 S T'],
  "vannguyen": ['V AE0 N G IY1 AH0 N', 'V AE2 N UW0 Y EH1 N'],
  "vanni": ['V AE1 N IY0'],
  "vannice": ['V AE1 N IH0 S'],
  "vannie": ['V AE1 N IY0'],
  "vannorman": ['V AE0 N AO1 R M AH0 N'],
  "vannortwick": ['V AH0 N AO1 R T W IH0 K'],
  "vannostrand": ['V AE0 N AA1 S T R AH0 N D'],
  "vannote": ['V AE0 N OW1 T'],
  "vannoy": ['V AE1 N OY0'],
  "vannucci": ['V AA0 N UW1 CH IY0'],
  "vanny": ['V AE1 N IY0'],
  "vano": ['V AA1 N OW0'],
  "vanora": ['V AE1 N ER0 AH0'],
  "vanorden": ['V AE0 N AO1 R D AH0 N'],
  "vanorder": ['V AE0 N AO1 R D ER0'],
  "vanorman": ['V AE0 N AO1 R M AH0 N'],
  "vanornum": ['V AE0 N AO1 R N AH0 M'],
  "vanosdol": ['V AE0 N AA1 S D AH0 L'],
  "vanoss": ['V AE0 N AA1 S'],
  "vanostrand": ['V AE0 N AA1 S T R AH0 N D'],
  "vanous": ['V AE1 N AH0 S'],
  "vanover": ['V AE1 N OW2 V ER0'],
  "vanpatten": ['V AE0 N P AE1 T AH0 N'],
  "vanpelt": ['V AE2 N P EH1 L T'],
  "vanputten": ['V AE0 N P AH1 T AH0 N'],
  "vanquish": ['V AE1 NG K W IH0 SH'],
  "vanquished": ['V AE1 NG K W IH0 SH T'],
  "vanriper": ['V AE2 N R AY1 P ER0'],
  "vanroekel": ['V AE2 N R OW1 K AH0 L'],
  "vanrossum": ['V AE2 N R AA1 S AH0 M'],
  "vanryn": ['V AE2 N R IH1 N'],
  "vans": ['V AE1 N Z'],
  "vansandt": ['V AE2 N S AE1 N T'],
  "vansant": ['V AA1 N S AH0 N T'],
  "vanschaick": ['V AE0 N SH AY1 K'],
  "vanschoick": ['V AE2 N SH OY1 K'],
  "vansciver": ['V AE0 N S K AY1 V ER0'],
  "vanscoy": ['V AE1 N S K OY2'],
  "vanscoyoc": ['V AE2 N S K OY1 AA0 K'],
  "vanscyoc": ['V AE1 N S IY0 AA2 K'],
  "vanselow": ['V AE1 N S IH0 L OW0'],
  "vansickel": ['V AE2 N S IH1 K AH0 L'],
  "vansickle": ['V AE2 N S IH1 K AH0 L'],
  "vanskike": ['V AE2 N S K AY1 K'],
  "vanskiver": ['V AE2 N S K AY1 V ER0'],
  "vanslooten": ['V AE2 N S L UW1 T AH0 N'],
  "vanslyke": ['V AE2 N S L AY1 K'],
  "vanstone": ['V AE2 N S T OW1 N'],
  "vanstory": ['V AE2 N S T AO1 R IY0'],
  "vanstraten": ['V AE2 N S T R EY1 T AH0 N'],
  "vansyckle": ['V AE1 N S AY0 K AH0 L'],
  "vantage": ['V AE1 N T AH0 JH', 'V AE1 N T IH0 JH'],
  "vantage's": ['V AE1 N T IH0 JH IH0 Z'],
  "vantages": ['V AE1 N T IH0 JH IH0 Z', 'V AE1 N IH0 JH IH0 Z'],
  "vantassel": ['V AE2 N T AE1 S AH0 L'],
  "vantassell": ['V AE2 N T AE1 S AH0 L'],
  "vantil": ['V AA0 N T IY1 L'],
  "vantilburg": ['V AE2 N T IH1 L B ER0 G'],
  "vantine": ['V AA0 N T IY1 N IY0'],
  "vantol": ['V AE0 N T AO1 L'],
  "vantran": ['V AE2 N T R AE1 N'],
  "vantrease": ['V AH0 N T R IY1 S'],
  "vantreese": ['V AE2 N T R IY1 S'],
  "vantuyl": ['V AE0 N T AY1 L'],
  "vanuaaku": ['V AE2 N W AH0 AA1 K UW2'],
  "vanuatu": ['V AE0 N UW0 AA1 T UW0'],
  "vanunu": ['V AH0 N UW1 N UW0'],
  "vanvalkenburg": ['V AE0 N V AO1 K AH0 N B ER0 G'],
  "vanvalkenburgh": ['V AE0 N V AE1 L K IH0 N B ER0 G'],
  "vanveen": ['V AH0 N V IY1 N'],
  "vanvlack": ['V AE2 N V L AE1 K'],
  "vanvleck": ['V AE2 N V L EH1 K'],
  "vanvleet": ['V AE1 N V L IY2 T'],
  "vanvliet": ['V AE2 N V L IY1 T'],
  "vanvooren": ['V AE0 N V UH1 R AH0 N'],
  "vanvoorhis": ['V AE0 N V UH1 R HH IH0 S'],
  "vanvoorst": ['V AE0 N V UH1 R S T'],
  "vanvorst": ['V AE0 N V AO1 R S T'],
  "vanvranken": ['V AE2 N V R AE1 NG K AH0 N'],
  "vanwagenen": ['V AE0 N W AE1 G AH0 N AH0 N'],
  "vanwagner": ['V AE2 N W AE1 G N ER0'],
  "vanwagoner": ['V AE2 N W AE1 G AH0 N ER0'],
  "vanwart": ['V AE2 N W AO1 R T'],
  "vanwert": ['V AE0 N W ER1 T'],
  "vanwey": ['V AE1 N W IY0'],
  "vanwhy": ['V AE1 N W IY0'],
  "vanwie": ['V AE1 N W IY0'],
  "vanwieren": ['V AE0 N W IH1 R AH0 N'],
  "vanwinkle": ['V AE2 N W IH1 NG K AH0 L'],
  "vanwormer": ['V AE0 N W ER1 M ER0'],
  "vanwyck": ['V AE0 N W IH1 K'],
  "vanwyhe": ['V AE1 N W AY0 HH'],
  "vanwyk": ['V AE0 N W IH1 K'],
  "vanya": ['V AA1 N Y AH0'],
  "vanya's": ['V AA1 N Y AH0 Z'],
  "vanyo": ['V AA1 N Y OW0'],
  "vanzandt": ['V AE2 N Z AE1 N T'],
  "vanzant": ['V AA1 N Z AH0 N T'],
  "vanzanten": ['V AE2 N Z AE1 N T AH0 N'],
  "vanzee": ['V AA1 N Z IY0'],
  "vanzile": ['V AA1 N Z AY0 L'],
  "vape": ['V EY1 P'],
  "vapid": ['V AE1 P IH0 D'],
  "vapor": ['V EY1 P ER0'],
  "vaporization": ['V EY0 P ER0 AH0 Z EY1 SH AH0 N'],
  "vaporize": ['V EY1 P ER0 AY2 Z'],
  "vaporized": ['V EY1 P ER0 AY2 Z D'],
  "vapors": ['V EY1 P ER0 Z'],
  "vaporware": ['V EY1 P ER0 W EH2 R'],
  "vaquera": ['V AA0 K W EH1 R AH0'],
  "vara": ['V AA1 R AH0'],
  "varady": ['V ER0 AA1 D IY0'],
  "varani": ['V ER0 AA1 N IY0'],
  "varano": ['V AA0 R AA1 N OW0'],
  "varble": ['V AA1 R B AH0 L'],
  "varco": ['V AA1 R K OW0'],
  "vardeman": ['V AA1 R D M AH0 N'],
  "varden": ['V AA1 R D AH0 N'],
  "vardon": ['V AA0 R D AO1 N'],
  "varela": ['V AA0 R EY1 L AH0'],
  "vares": ['V AA1 R EH0 S', 'V EY1 R Z'],
  "varga": ['V AA1 R G AH0'],
  "vargas": ['V AA1 R G AH0 S'],
  "vargason": ['V AA1 R G AH0 S AH0 N'],
  "varghese": ['V AA1 R G IY0 Z'],
  "vargo": ['V AA1 R G OW0'],
  "vari": ['V AA1 R IY0'],
  "variability": ['V EH0 R IY0 AH0 B IH1 L IH0 T IY0'],
  "variable": ['V EH1 R IY0 AH0 B AH0 L'],
  "variables": ['V EH1 R IY0 AH0 B AH0 L Z'],
  "variably": ['V EH1 R IY0 AH0 B L IY0'],
  "varian": ['V EH1 R IY0 AH0 N'],
  "variance": ['V EH1 R IY0 AH0 N S'],
  "variances": ['V EH1 R IY0 AH0 N S IH0 Z'],
  "variant": ['V EH1 R IY0 AH0 N T'],
  "variants": ['V EH1 R IY0 AH0 N T S'],
  "variation": ['V EH2 R IY0 EY1 SH AH0 N'],
  "variations": ['V EH2 R IY0 EY1 SH AH0 N Z'],
  "varick": ['V EH1 R IH0 K'],
  "varied": ['V EH1 R IY0 D'],
  "variegate": ['V EH1 R IH0 G EY0 T'],
  "variegated": ['V EH1 R IH0 G EY0 T IH0 D'],
  "varies": ['V EH1 R IY0 Z'],
  "varietal": ['V ER0 IY1 T AH0 L'],
  "varietals": ['V ER0 AY1 AH0 T AH0 L Z'],
  "varieties": ['V ER0 AY1 AH0 T IY0 Z'],
  "variety": ['V ER0 AY1 AH0 T IY0'],
  "varig": ['V EH1 R IH0 G'],
  "varin": ['V AA0 R IY1 N'],
  "varina": ['V AA0 R IY1 N AH0'],
  "various": ['V EH1 R IY0 AH0 S'],
  "variously": ['V EH1 R IY0 AH0 S L IY0'],
  "varisco": ['V AA0 R IY1 S K OW0'],
  "varitronic": ['V EH2 R IH0 T R AA1 N IH0 K'],
  "varity": ['V EH1 R IH0 T IY0'],
  "varity's": ['V EH1 R IH0 T IY0 Z'],
  "varityper": ['V EH1 R IH0 T AY2 P ER0'],
  "varlam": ['V AA1 R L AE0 M'],
  "varlen": ['V AA1 R L AH0 N'],
  "varley": ['V AA1 R L IY0'],
  "varma": ['V AA1 R M AH0'],
  "varmint": ['V AA1 R M IH0 N T'],
  "varmus": ['V AA1 R M AH0 S'],
  "varn": ['V AA1 R N'],
  "varnado": ['V AA0 R N AA1 D OW0'],
  "varnadoe": ['V AA0 R N AA1 D OW0'],
  "varnadore": ['V AA0 R N AA0 D AO1 R EY0'],
  "varnell": ['V AA1 R N AH0 L'],
  "varner": ['V AA1 R N ER0'],
  "varnes": ['V AA1 R N Z'],
  "varney": ['V AA1 R N IY0'],
  "varni": ['V AA1 R N IY0'],
  "varnish": ['V AA1 R N IH0 SH'],
  "varnished": ['V AA1 R N IH0 SH T'],
  "varnishes": ['V AA1 R N IH0 SH AH0 Z', 'V AA1 R N IH0 SH IH0 Z'],
  "varnon": ['V AA0 R N AO1 N'],
  "varnum": ['V AA1 R N AH0 M'],
  "varo": ['V EH1 R OW0', 'V AA1 R OW0'],
  "varon": ['V AA0 R AO1 N'],
  "varona": ['V AA0 R OW1 N AH0'],
  "varone": ['V ER0 OW1 N'],
  "varricchio": ['V AA0 R IY1 K IY0 OW0'],
  "varrone": ['V AA0 R OW1 N EY0'],
  "vars": ['V AA1 R Z'],
  "varsity": ['V AA1 R S IH0 T IY0'],
  "vartanian": ['V AA0 R T EY1 N IY0 AH0 N'],
  "varvaro": ['V AA0 R V AA1 R OW0'],
  "varvel": ['V AA0 R V EH1 L'],
  "varves": ['V AA1 R V Z'],
  "vary": ['V EH1 R IY0'],
  "varying": ['V EH1 R IY0 IH0 NG'],
  "varzi": ['V AA1 R Z IY0'],
  "vasbinder": ['V AE1 S B IH0 N D ER0', 'V AE1 S B AY0 N D ER0'],
  "vasco": ['V AE1 S K OW0'],
  "vasconcellos": ['V AE0 S K AH0 N S EH1 L OW0 Z'],
  "vasconcelos": ['V AA0 S K OW0 N S EY1 L OW0 Z'],
  "vascular": ['V AE1 S K Y AH0 L ER0'],
  "vase": ['V EY1 S', 'V AA1 Z'],
  "vasectomies": ['V AE0 Z EH1 K T AH0 M IY0 Z', 'V AE0 S EH1 K T AH0 M IY0 Z'],
  "vasectomy": ['V AE0 Z EH1 K T AH0 M IY0', 'V AE0 S EH1 K T AH0 M IY0'],
  "vasek": ['V AA1 S EH0 K'],
  "vaseline": ['V AE1 S AH0 L IY2 N'],
  "vases": ['V EY1 S AH0 Z', 'V AA1 Z IH0 Z'],
  "vasey": ['V AE1 S IY0'],
  "vashinsky": ['V AH0 SH IH1 N S K IY0'],
  "vashti": ['V AE1 SH T IY0'],
  "vasicek": ['V AA1 S IH0 CH EH0 K'],
  "vasil": ['V AA0 S IY1 L'],
  "vasile": ['V AA1 S AH0 L'],
  "vasily": ['V AE1 S AH0 L IY0'],
  "vaske": ['V EY1 S K'],
  "vaskevitch": ['V AE1 S K AH0 V IH0 CH'],
  "vasko": ['V AA1 S K OW0'],
  "vaslev": ['V AA1 S L IH0 V'],
  "vaslov": ['V AE1 S L AA2 V'],
  "vaslov's": ['V AE1 S L AA2 V Z'],
  "vasotec": ['V EY1 Z OW0 T EH2 K'],
  "vasques": ['V AA1 S K W EH0 S'],
  "vasquez": ['V AE0 S K EH1 Z'],
  "vass": ['V AE1 S'],
  "vassal": ['V AE1 S AH0 L'],
  "vassallo": ['V AA0 S AA1 L OW0'],
  "vassals": ['V AE1 S AH0 L Z'],
  "vassar": ['V AE1 S ER0'],
  "vassel": ['V AE1 S AH0 L'],
  "vasser": ['V AE1 S ER0'],
  "vasseur": ['V AE1 S ER0'],
  "vassey": ['V AE1 S IY0'],
  "vassilios": ['V AH0 S IH1 L Y AH0 S'],
  "vassiliou": ['V AE2 S IH1 L IY0 UW0'],
  "vassily": ['V AH0 S IH1 L IY0'],
  "vast": ['V AE1 S T'],
  "vasta": ['V AE1 S T AH0'],
  "vastine": ['V AA0 S T IY1 N IY0'],
  "vastly": ['V AE1 S T L IY0'],
  "vastness": ['V AE1 S T N AH0 S'],
  "vastola": ['V AA0 S T OW1 L AH0'],
  "vat": ['V AE1 T'],
  "vater": ['V EY1 T ER0'],
  "vath": ['V AE1 TH'],
  "vatican": ['V AE1 T IH0 K AH0 N'],
  "vatican's": ['V AE1 T IH0 K AH0 N Z'],
  "vats": ['V AE1 T S'],
  "vatted": ['V AE1 T IH0 D'],
  "vatter": ['V AE1 T ER0'],
  "vaudeville": ['V AA1 D V IH0 L'],
  "vaudevillian": ['V AA0 D V IH1 L Y AH0 N'],
  "vaugh": ['V AO1'],
  "vaughan": ['V AO1 N'],
  "vaughan's": ['V AO1 N Z'],
  "vaughn": ['V AO1 N'],
  "vaughn's": ['V AO1 N Z'],
  "vaughns": ['V AO1 N Z'],
  "vaught": ['V AO1 T'],
  "vault": ['V AO1 L T'],
  "vaulted": ['V AO1 L T IH0 D'],
  "vaulting": ['V AO1 L T IH0 NG'],
  "vaults": ['V AO1 L T S'],
  "vaunted": ['V AO1 N T IH0 D'],
  "vaupel": ['V AW0 P EH1 L'],
  "vause": ['V AO1 S'],
  "vaux": ['V AO1 K S'],
  "vauxhall": ['V AA1 K S HH AO2 L'],
  "vavra": ['V AE1 V R AH0'],
  "vavrek": ['V AA1 V R EH0 K'],
  "vawter": ['V AO1 T ER0'],
  "vax": ['V AE1 K S'],
  "vaxes": ['V AE1 K S IH0 Z'],
  "vaxstation": ['V AE2 K S T EY1 SH AH0 N'],
  "vayda": ['V EY1 D AH0'],
  "vaz": ['V AE1 Z'],
  "vazquez": ['V AE0 S K EH1 Z'],
  "ve": ['V IY1', 'V IY1 IY1'],
  "veach": ['V IY1 CH'],
  "veal": ['V IY1 L'],
  "veale": ['V IY1 L'],
  "veals": ['V IY1 L Z'],
  "veasey": ['V IY1 Z IY0'],
  "veasley": ['V IY1 Z L IY0'],
  "veatch": ['V IY1 CH'],
  "veazey": ['V IY1 Z IY0'],
  "veazie": ['V IY1 Z IY0'],
  "veba": ['V IY1 B AH0'],
  "vecchiarelli": ['V EH0 K IY0 AA0 R EH1 L IY0'],
  "vecchio": ['V EH1 K IY0 OW0'],
  "vecchione": ['V EH2 K IY0 OW1 N IY0'],
  "vecci": ['V EH1 CH IY0'],
  "vecellio": ['V EH0 CH EH1 L IY0 OW0'],
  "vector": ['V EH1 K T ER0'],
  "vectors": ['V EH1 K T ER0 Z'],
  "vectra": ['V EH1 K T R ER0'],
  "veda": ['V EY1 D AH0'],
  "vedder": ['V EH1 D ER0'],
  "vedetta": ['V IH2 D EH1 T AH0'],
  "vedette": ['V IH0 D EH1 T'],
  "vedis": ['V EY1 D IH0 S'],
  "vedula": ['V EH2 D UW1 L AH0'],
  "vee": ['V IY1'],
  "veech": ['V IY1 CH'],
  "veeco": ['V IY1 K OW0'],
  "veeder": ['V IY1 D ER0'],
  "veegenan": ['V IY1 G AH0 N AH0 N'],
  "veen": ['V IY1 N'],
  "veenstra": ['V IY1 N S T R AH0'],
  "veep": ['V IY1 P'],
  "veer": ['V IH1 R'],
  "veered": ['V IH1 R D'],
  "veering": ['V IH1 R IH0 NG'],
  "veers": ['V IH1 R Z'],
  "vees": ['V IY1 Z'],
  "vega": ['V EY1 G AH0'],
  "vegan": ['V EH1 G AH0 N'],
  "vegans": ['V EH1 G AH0 N Z'],
  "vegas": ['V EY1 G AH0 S'],
  "vegemite": ['V EH1 JH AH0 M AY2 T'],
  "vegesna": ['V EH0 G EH1 S N AH0'],
  "vegetable": ['V EH1 JH T AH0 B AH0 L'],
  "vegetables": ['V EH1 JH T AH0 B AH0 L Z'],
  "vegetal": ['V EH1 JH AH0 T AH0 L'],
  "vegetarian": ['V EH2 JH AH0 T EH1 R IY2 AH0 N'],
  "vegetarianism": ['V EH2 JH AH0 T EH1 R IY2 AH0 N IH2 Z AH0 M'],
  "vegetarians": ['V EH2 JH AH0 T EH1 R IY2 AH0 N Z'],
  "vegetate": ['V EH1 JH AH0 T EY2 T'],
  "vegetation": ['V EH2 JH AH0 T EY1 SH AH0 N'],
  "vegetative": ['V EH2 JH AH0 T EY1 T IH0 V'],
  "veggie": ['V EH1 JH IY0'],
  "veggies": ['V EH1 JH IY0 Z'],
  "vegh": ['V EH1 G'],
  "vegisnax": ['V AH0 G IH1 S N AE0 K S'],
  "vehemence": ['V IY1 AH0 M AH0 N S', 'V AH0 HH IY1 M AH0 N S'],
  "vehement": ['V IY1 AH0 M AH0 N T', 'V AH0 HH IY1 M AH0 N T'],
  "vehemently": ['V IY1 AH0 M AH0 N T L IY0', 'V AH0 HH IY1 M AH0 N T L IY0'],
  "vehicle": ['V IY1 HH IH0 K AH0 L', 'V IY1 IH0 K AH0 L'],
  "vehicle's": ['V IY1 HH IH0 K AH0 L Z'],
  "vehicles": ['V IY1 HH IH0 K AH0 L Z', 'V IY1 IH0 K AH0 L Z'],
  "vehicles'": ['V EH1 HH IH0 K AH0 L Z'],
  "vehicular": ['V IY0 HH IH1 K Y AH0 L ER0'],
  "veiga": ['V EY1 G AH0'],
  "veigel": ['V AY1 G AH0 L'],
  "veil": ['V EY1 L'],
  "veiled": ['V EY1 L D'],
  "veiling": ['V EY1 L IH0 NG'],
  "veillette": ['V AH0 L EH1 T'],
  "veilleux": ['V AH0 L OW1', 'V EY1 L OW0'],
  "veillon": ['V EY1 L AH0 N'],
  "veils": ['V EY1 L Z'],
  "vein": ['V EY1 N'],
  "veins": ['V EY1 N Z'],
  "veira": ['V EY1 R AH0'],
  "veit": ['V IY1 T'],
  "veitch": ['V AY1 CH'],
  "veith": ['V IY1 TH'],
  "vela": ['V EH1 L AH0'],
  "velagrande": ['V EH2 L AH0 G R AA1 N D EY2'],
  "velagrande's": ['V EH2 L AH0 G R AA1 N D EY2 Z'],
  "velarde": ['V EH0 L AA1 R D IY0'],
  "velardi": ['V EH0 L AA1 R D IY0'],
  "velardo": ['V EY0 L AA1 R D OW0'],
  "velasco": ['V EH0 L AA1 S K OW0'],
  "velasquez": ['V EH0 L AE1 S K EH0 Z'],
  "velayati": ['V EH0 L AY2 AA1 T IY0'],
  "velazco": ['V AH0 L AE1 S K OW0'],
  "velazquez": ['V EH0 L AE1 S K EH0 Z'],
  "velcro": ['V EH1 L K R OW0'],
  "velda": ['V EH1 L D AH0'],
  "veldhuizen": ['V EH1 L D HH IH0 Z AH0 N'],
  "veldman": ['V EH1 L D M AH0 N'],
  "veley": ['V EH1 L IY0'],
  "velez": ['V EH0 L EH1 Z'],
  "velie": ['V EH1 L IY0'],
  "velika": ['V EH1 L IH0 K AH0'],
  "veliotis": ['V EH0 L IY0 OW1 T IH0 S'],
  "veliz": ['V EH1 L IH0 Z'],
  "vella": ['V EH1 L AH0'],
  "vellucci": ['V EH0 L UW1 CH IY0'],
  "velma": ['V EH1 L M AH0'],
  "velo": ['V EH1 L OW0'],
  "velobind": ['V EH1 L AH0 B IH0 N D', 'V EH1 L OW0 B AY2 N D'],
  "velociraptor": ['V AH0 L AO1 S AH0 R AE2 P T ER0'],
  "velocities": ['V AH0 L AA1 S AH0 T IY0 Z'],
  "velocity": ['V AH0 L AA1 S AH0 T IY0'],
  "velodrome": ['V EH2 L OW0 D R OW1 M'],
  "velodromes": ['V EH2 L OW0 D R OW1 M Z'],
  "veloso": ['V EH2 L OW1 S OW0'],
  "velotta": ['V EH0 L OW1 T AH0'],
  "velour": ['V EH2 L UW1 R'],
  "veloz": ['V EY1 L OW0 Z'],
  "velsicol": ['V EH1 L S IH0 K AA2 L'],
  "velte": ['V EH1 L T'],
  "velten": ['V EH1 L T AH0 N'],
  "veltman": ['V EH1 L T M AH0 N'],
  "veltre": ['V EH1 L T ER0'],
  "veltri": ['V EH1 L T R IY0'],
  "velveeta": ['V EH0 L V IY1 T AH0'],
  "velvet": ['V EH1 L V AH0 T'],
  "velveteen": ['V EH1 L V AH0 T IY2 N'],
  "velvety": ['V EH1 L V AH0 T IY0'],
  "vemich": ['V EH1 M IH0 CH'],
  "vempala": ['V EH2 M P AA1 L AH0'],
  "vena": ['V IY1 N AH0'],
  "venable": ['V EH1 N AH0 B AH0 L'],
  "venables": ['V EH1 N AH0 B AH0 L Z'],
  "venacuro": ['V EH0 N AH0 K Y UH1 R OW0'],
  "venal": ['V IY1 N AH0 L'],
  "venality": ['V IH0 N AE1 L IH0 T IY0'],
  "venalum": ['V EH1 N AH0 L AH0 M'],
  "venango": ['V EH0 N AE1 NG G OW0'],
  "venard": ['V EH1 N ER0 D'],
  "vencill": ['V EH1 N S IH0 L'],
  "vencor": ['V EH1 N K AO2 R'],
  "vendee": ['V EH1 N D IY1'],
  "vendela": ['V EH0 N D EH1 L AH0'],
  "vendetta": ['V EH0 N D EH1 T AH0'],
  "vendettas": ['V EH0 N D EH1 T AH0 Z'],
  "vendetti": ['V EH0 N D EH1 T IY0'],
  "vending": ['V EH1 N D IH0 NG'],
  "venditti": ['V EH0 N D IY1 T IY0'],
  "vendo": ['V EH1 N D OW0'],
  "vendome": ['V EH0 N D OW1 M', 'V AA1 N D OW2 M'],
  "vendor": ['V EH1 N D ER0'],
  "vendor's": ['V EH1 N D ER0 Z'],
  "vendors": ['V EH1 N D ER0 Z'],
  "vendors'": ['V EH1 N D ER0 Z'],
  "veneer": ['V AH0 N IH1 R'],
  "veneers": ['V AH0 N IH1 R Z'],
  "venegas": ['V EH1 N IH0 G AH0 Z'],
  "venema": ['V EH1 N IH0 M AH0'],
  "venerable": ['V EH1 N ER0 AH0 B AH0 L'],
  "venerate": ['V EH1 N ER0 EY2 T'],
  "venerated": ['V EH1 N ER0 EY2 T IH0 D'],
  "venerates": ['V EH1 N ER0 EY2 T S'],
  "venerating": ['V EH1 N ER0 EY2 T IH0 NG'],
  "veneration": ['V EH2 N ER0 EY1 SH AH0 N'],
  "venereal": ['V AH0 N IH1 R IY0 AH0 L'],
  "venetian": ['V AH0 N IY1 SH AH0 N'],
  "veney": ['V EH1 N IY0'],
  "venezia": ['V EH0 N EH1 Z IY0 AH0'],
  "veneziano": ['V EH0 N EH0 Z IY0 AA1 N OW0'],
  "venezuela": ['V EH2 N IH0 Z W EY1 L AH0'],
  "venezuela's": ['V EH2 N IH0 Z W EY1 L AH0 Z'],
  "venezuelan": ['V EH2 N IH0 Z W EY1 L AH0 N'],
  "venezuelans": ['V EH2 N IH0 Z W EY1 L AH0 N Z'],
  "vengeance": ['V EH1 N JH AH0 N S'],
  "vengeful": ['V EH1 N JH F AH0 L'],
  "venice": ['V EH1 N AH0 S', 'V EH1 N IH0 S'],
  "venice's": ['V EH1 N IH0 S IH0 Z'],
  "venier": ['V IY1 N IY0 ER0'],
  "venison": ['V EH1 N AH0 S AH0 N'],
  "venita": ['V EH0 N IY1 T AH0'],
  "venkatesh": ['V EH2 N K AA0 T EH1 SH'],
  "venn": ['V EH1 N'],
  "vennard": ['V EH1 N ER0 D'],
  "venne": ['V EH1 N'],
  "venneman": ['V EH1 N M AH0 N'],
  "venner": ['V EH1 N ER0'],
  "vennick": ['V EH1 N IH0 K'],
  "venning": ['V EH1 N IH0 NG'],
  "veno": ['V EY1 N OW0'],
  "venom": ['V EH1 N AH0 M'],
  "venomous": ['V EH1 N AH0 M AH0 S'],
  "venous": ['V IY1 N AH0 S'],
  "vensel": ['V EH1 N S AH0 L'],
  "venson": ['V EH1 N S AH0 N'],
  "vent": ['V EH1 N T'],
  "ventech": ['V EH1 N T EH2 K'],
  "vented": ['V EH1 N T IH0 D'],
  "venter": ['V EH1 N T ER0'],
  "venters": ['V EH1 N T ER0 Z'],
  "venti": ['V EH1 N T IY0'],
  "ventilate": ['V EH1 N T AH0 L EY2 T', 'V EH1 N AH0 L EY2 T'],
  "ventilated": ['V EH1 N T AH0 L EY2 T IH0 D', 'V EH1 N AH0 L EY2 T IH0 D'],
  "ventilating": ['V EH1 N T AH0 L EY2 T IH0 NG', 'V EH1 N AH0 L EY2 T IH0 NG'],
  "ventilation": ['V EH2 N T AH0 L EY1 SH AH0 N', 'V EH2 N AH0 L EY1 SH AH0 N'],
  "ventilator": ['V EH1 N T AH0 L EY2 T ER0', 'V EH1 N AH0 L EY2 T ER0'],
  "ventimiglia": ['V EH2 N T IH0 M IH1 G L IY0 AH0'],
  "venting": ['V EH1 N T IH0 NG'],
  "ventner": ['V EH1 N T N ER0'],
  "vento": ['V EH1 N T OW0'],
  "ventola": ['V EH0 N T OW1 L AH0'],
  "ventral": ['V EH1 N T R AH0 L'],
  "ventre": ['V EH1 N T ER0'],
  "ventrella": ['V EH2 N T R EH1 L AH0'],
  "ventres": ['V EH1 N T ER0 Z'],
  "ventresca": ['V EH0 N T R EH1 S K AH0'],
  "ventress": ['V EH1 N T R IH0 S'],
  "ventricle": ['V EH1 N T R IH2 K AH0 L'],
  "ventricles": ['V EH1 N T R IH2 K AH0 L Z'],
  "ventricular": ['V EH0 N T R IH1 K Y UW0 L ER0'],
  "ventritex": ['V EH1 N T R IH0 T EH2 K S'],
  "vents": ['V EH1 N T S'],
  "ventura": ['V EH0 N CH ER1 AH0', 'V EH0 N T UH1 R AH0'],
  "venture": ['V EH1 N CH ER0'],
  "venture's": ['V EH1 N CH ER0 Z'],
  "ventured": ['V EH1 N CH ER0 D'],
  "venturella": ['V EH0 N T UH0 R EH1 L AH0'],
  "ventures": ['V EH1 N CH ER0 Z'],
  "ventures'": ['V EH1 N CH ER0 Z'],
  "venturesome": ['V EH1 N CH ER0 S AH0 M'],
  "venturi": ['V EH0 N T UH1 R IY0'],
  "venturian": ['V EH0 N T UH1 R IY0 AH0 N'],
  "venturing": ['V EH1 N CH ER0 IH0 NG'],
  "venturini": ['V EH0 N T UH0 R IY1 N IY0'],
  "venturino": ['V EH0 N T UH0 R IY1 N OW0'],
  "venue": ['V EH1 N Y UW0'],
  "venues": ['V EH1 N UW0 Z'],
  "venus": ['V IY1 N AH0 S'],
  "venuti": ['V EH0 N UW1 T IY0'],
  "venuto": ['V EH0 N UW1 T OW0'],
  "venzke": ['V EH1 N Z K'],
  "ver": ['V ER1'],
  "vera": ['V EH1 R AH0'],
  "veracity": ['V ER0 AE1 S IH0 T IY0'],
  "veranda": ['V ER0 AE1 N D AH0'],
  "verandas": ['V ER0 AE1 N D AH0 Z'],
  "veras": ['V EH1 R AH0 Z'],
  "verb": ['V ER1 B'],
  "verba": ['V EH1 R B AH0'],
  "verbal": ['V ER1 B AH0 L'],
  "verbalize": ['V ER1 B AH0 L AY2 Z'],
  "verbalizing": ['V ER1 B AH0 L AY2 Z IH0 NG'],
  "verbally": ['V ER0 B AE1 L IY0'],
  "verbatim": ['V ER0 B EY1 T AH0 M'],
  "verbeck": ['V ER1 B EH0 K'],
  "verbeek": ['V ER1 B IY0 K'],
  "verbeke": ['V ER1 B IH0 K'],
  "verbena": ['V ER0 B IY1 N AH0'],
  "verbiage": ['V ER1 B IY0 IH0 JH'],
  "verble": ['V ER1 B AH0 L'],
  "verboon": ['V ER0 B UW1 N'],
  "verboons": ['V ER0 B UW1 N Z'],
  "verboten": ['V ER0 B OW1 T AH0 N'],
  "verbrugge": ['V ER1 B R AH0 G'],
  "verbs": ['V ER1 B Z'],
  "verburg": ['V ER1 B ER0 G'],
  "vercammen": ['V ER2 K AE1 M AH0 N'],
  "vercher": ['V ER1 K ER0'],
  "verda": ['V EH1 R D AH0'],
  "verdant": ['V ER1 D AH0 N T'],
  "verde": ['V ER1 D IY0'],
  "verderame": ['V ER1 D ER0 AH0 M'],
  "verderber": ['V ER1 D ER0 B ER0'],
  "verderosa": ['V ER0 D ER0 OW1 S AH0'],
  "verdes": ['V ER1 D IY0'],
  "verdi": ['V EH1 R D IY0'],
  "verdi's": ['V ER1 D IY0 Z'],
  "verdict": ['V ER1 D IH0 K T'],
  "verdicts": ['V ER1 D IH0 K T S'],
  "verdier": ['V ER1 D IY0 ER0'],
  "verdin": ['V ER1 D IH0 N'],
  "verdinsgang": ['V ER1 D IH0 N S G AE2 NG'],
  "verdon": ['V EH0 R D AO1 N', 'V ER1 D AH0 N'],
  "verdone": ['V EH0 R D OW1 N EY0'],
  "verdugo": ['V ER0 D UW1 G OW0'],
  "verduin": ['V EH0 R D UW0 IH1 N'],
  "verdun": ['V ER1 D AH0 N'],
  "verduzco": ['V ER0 D UW1 Z K OW0'],
  "vere": ['V IH1 R'],
  "vereb": ['V EH1 R IH0 B'],
  "vereen": ['V IH1 R IY0 N'],
  "vereinsbank": ['V ER0 AY1 N Z B AE2 NG K'],
  "verena": ['V ER0 EY1 N AH0'],
  "verene": ['V EH1 R IY0 N'],
  "veres": ['V IY1 R Z'],
  "verex": ['V EH1 R AH0 K S'],
  "verga": ['V EH1 R G AH0'],
  "vergara": ['V ER0 G AA1 R AH0'],
  "verge": ['V ER1 JH'],
  "verges": ['V ER1 JH IH0 Z', 'V ER1 JH IY0 Z'],
  "verges's": ['V ER1 JH IH0 Z IH0 Z', 'V ER1 JH IY0 Z IH0 Z'],
  "vergesh": ['V ER2 G EH1 SH'],
  "vergesh's": ['V ER2 G EH1 SH IH0 S'],
  "vergil": ['V ER1 JH AH0 L'],
  "verging": ['V ER1 JH IH0 NG'],
  "vergresh": ['V ER2 G R EH1 SH'],
  "vergresh's": ['V ER2 G R EH1 SH IH0 S'],
  "verhage": ['V ER1 HH IH0 JH'],
  "verhagen": ['V ER1 HH AH0 G AH0 N'],
  "verhey": ['V ER1 HH IY0'],
  "verhoef": ['V ER1 HH OW0 F'],
  "verhoeven": ['V ER1 HH OW0 V AH0 N'],
  "verhoff": ['V ER1 HH AO0 F'],
  "verhofstadt": ['V ER0 HH AO1 F S T AE2 T'],
  "verhulst": ['V ER1 HH AH0 L S T'],
  "veribanc": ['V EH1 R IH0 B AE2 NG K'],
  "verifiable": ['V EH1 R AH0 F AY2 AH0 B AH0 L'],
  "verification": ['V EH2 R AH0 F AH0 K EY1 SH AH0 N'],
  "verified": ['V EH1 R AH0 F AY2 D'],
  "verifies": ['V EH1 R AH0 F AY2 Z'],
  "verify": ['V EH1 R AH0 F AY2'],
  "verifying": ['V EH1 R AH0 F AY2 IH0 NG'],
  "verina": ['V ER0 IY1 N AH0'],
  "verine": ['V ER0 IY1 N IY0'],
  "verisimilitude": ['V EH2 R AH0 S AH0 M IH1 L AH0 T UW2 D'],
  "verit": ['V EH1 R IH0 T'],
  "veritable": ['V EH1 R IH0 T AH0 B AH0 L'],
  "veritably": ['V EH1 R IH0 T AH0 B L IY0'],
  "verities": ['V EH1 R AH0 T IY0 Z'],
  "verity": ['V EH1 R AH0 T IY0', 'V EH1 R IH0 T IY0'],
  "verity's": ['V EH1 R AH0 T IY0 Z'],
  "verizon": ['V EH2 R AY1 Z AH0 N'],
  "verizon's": ['V EH2 R AY1 Z AH0 N Z'],
  "verizons": ['V EH2 R AY1 Z AH0 N Z'],
  "verkuilen": ['V ER1 K AH0 L AH0 N'],
  "verla": ['V EH1 R L AH0'],
  "verlag": ['V ER1 L AE0 G'],
  "verleger": ['V ER1 L AH0 G ER0'],
  "verley": ['V ER1 L IY0'],
  "verma": ['V EH1 R M AH0'],
  "vermeer": ['V ER1 M IH0 R'],
  "vermette": ['V ER0 M EH1 T'],
  "vermeulen": ['V ER0 M OY1 L AH0 N'],
  "vermilion": ['V ER0 M IH1 L Y AH0 N'],
  "vermillion": ['V ER0 M IH1 L Y AH0 N'],
  "vermilya": ['V ER0 M IY1 L Y AH0'],
  "vermilyea": ['V ER0 M IY1 L Y AH0'],
  "vermin": ['V ER1 M IH0 N'],
  "vermont": ['V ER0 M AA1 N T'],
  "vermont's": ['V ER0 M AA1 N T S'],
  "vermonter": ['V ER0 M AA1 N T ER0'],
  "vermonters": ['V ER0 M AA1 N T ER0 Z'],
  "vermouth": ['V ER0 M UW1 TH'],
  "vern": ['V ER1 N'],
  "verna": ['V ER1 N AH0'],
  "vernacular": ['V ER0 N AE1 K Y AH0 L ER0'],
  "vernal": ['V ER1 N AH0 L'],
  "verne": ['V ER1 N'],
  "verner": ['V ER1 N ER0'],
  "vernes": ['V ER1 N Z'],
  "verneta": ['V ER0 N EH1 T AH0'],
  "verney": ['V ER1 N IY0'],
  "vernick": ['V ER1 N IH0 K'],
  "vernier": ['V ER1 N IY0 ER0'],
  "vernis": ['V ER1 N IH0 S'],
  "vernita": ['V ER0 N IY1 T AH0'],
  "vernitron": ['V ER1 N IH0 T R AA0 N'],
  "verno": ['V EH1 R N OW0'],
  "vernon": ['V ER1 N AH0 N'],
  "vernon's": ['V ER1 N AH0 N Z'],
  "vernonia": ['V ER0 N OW1 N IY0 AH0'],
  "vernor": ['V ER1 N ER0'],
  "vero": ['V EH1 R OW0'],
  "veroa": ['V ER0 OW1 AH0'],
  "veron": ['V EH1 R AH0 N'],
  "verona": ['V ER0 OW1 N AH0'],
  "veronda": ['V EH0 R AA1 N D AH0'],
  "veronica": ['V ER0 AA1 N IH0 K AH0'],
  "veronique": ['V EH2 R AA0 N IY1 K'],
  "veronis": ['V EH0 R OW1 N IH0 S'],
  "verrall": ['V EH1 R AH0 L'],
  "verrastro": ['V EH2 R AE1 S T R OW0'],
  "verrell": ['V EH0 R EY1 L'],
  "verret": ['V EH1 R EY0 T'],
  "verrett": ['V EH1 R IH0 T'],
  "verrette": ['V ER0 EH1 T'],
  "verri": ['V EH1 R IY0'],
  "verrier": ['V EH1 R IY0 ER0'],
  "verrill": ['V EH0 R IY1 L'],
  "verrilli": ['V ER0 IY1 L IY0'],
  "verry": ['V EH1 R IY0'],
  "vers": ['V ER1 S'],
  "versa": ['V ER1 S AH0'],
  "versace": ['V ER0 S AA1 CH EY0', 'V ER0 S AA1 CH IY0'],
  "versace's": ['V ER0 S AA1 CH EY0 Z', 'V ER0 S AA1 CH IY0 Z'],
  "versailles": ['V EH0 R S AY1', 'V EH0 R S EY1 L Z'],
  "versatile": ['V ER1 S AH0 T AH0 L', 'V ER0 S AH0 T AY1 L'],
  "versatility": ['V ER2 S AH0 T IH1 L AH0 T IY0'],
  "verse": ['V ER1 S'],
  "versed": ['V ER1 S T'],
  "verser": ['V ER1 S ER0'],
  "verses": ['V ER1 S AH0 Z', 'V ER1 S IH0 Z'],
  "versicherung": ['V ER0 S IH1 CH ER0 AH0 NG'],
  "versicherungs": ['V ER0 S IH1 CH ER0 AH0 NG Z'],
  "version": ['V ER1 ZH AH0 N'],
  "versions": ['V ER1 ZH AH0 N Z'],
  "versluis": ['V ER1 S L UW0 IH0 Z'],
  "versteeg": ['V ER1 S T IY0 G'],
  "verstraete": ['V ER1 S T R EH0 T'],
  "versus": ['V ER1 S AH0 S', 'V ER1 S AH0 Z'],
  "vert": ['V ER1 T'],
  "vertebra": ['V ER1 T AH0 B R AH0'],
  "vertebrae": ['V ER1 T AH0 B R EY2'],
  "vertebral": ['V ER1 T AH0 B R AH0 L'],
  "vertebrate": ['V ER1 T AH0 B R EY2 T'],
  "vertebrates": ['V ER1 T AH0 B R EY2 T S'],
  "vertel": ['V ER2 T EH1 L'],
  "vertel's": ['V ER2 T EH1 L Z'],
  "vertex": ['V ER1 T EH2 K S'],
  "vertibrak": ['V ER1 T AH0 B R AE0 K'],
  "vertical": ['V ER1 T IH0 K AH0 L'],
  "vertically": ['V ER1 T IH0 K L IY0'],
  "vertices": ['V ER1 T IH0 S IY2 Z'],
  "verticom": ['V ER1 T IH0 K AA0 M'],
  "vertigo": ['V ER1 T IH0 G OW2'],
  "vertol": ['V ER1 T AA0 L'],
  "vertrees": ['V ER0 T R IY1 Z'],
  "vertucci": ['V ER0 T UW1 CH IY0'],
  "vertz": ['V ER1 T S'],
  "verve": ['V ER1 V'],
  "verville": ['V ER1 V IH0 L'],
  "verwey": ['V ER1 W IY0'],
  "verwoerd": ['V ER0 W AO1 R D'],
  "very": ['V EH1 R IY0'],
  "vescinoid": ['V EH1 S IH0 N OY2 D'],
  "vescio": ['V EH1 S IY0 OW0'],
  "vesco": ['V EH1 S K OW0'],
  "vesel": ['V EH1 S AH0 L'],
  "veselka": ['V EY0 S EY1 L K AH0'],
  "vesely": ['V IY1 Z L IY0'],
  "vesey": ['V EH1 S IY0'],
  "vesicle": ['V EH1 Z IH0 K AH0 L'],
  "vesicles": ['V EH1 Z IH0 K AH0 L Z'],
  "vesmen": ['V EH1 S M AH0 N'],
  "vesna": ['V EH1 S N AH0'],
  "vespa": ['V EY1 S P AH0'],
  "vesper": ['V EH1 S P ER0'],
  "vespera": ['V EY0 S P EH1 R AH0'],
  "vess": ['V EH1 S'],
  "vessel": ['V EH1 S AH0 L'],
  "vessel's": ['V EH1 S AH0 L Z'],
  "vessell": ['V EH1 S AH0 L'],
  "vessels": ['V EH1 S AH0 L Z'],
  "vessels's": ['V EH1 S AH0 L Z IH0 Z'],
  "vesser": ['V EH1 S ER0'],
  "vessey": ['V EH1 S IY0'],
  "vest": ['V EH1 S T'],
  "vesta": ['V EH1 S T AH0'],
  "vestal": ['V EH1 S T AH0 L'],
  "vestar": ['V EH1 S T ER0'],
  "vested": ['V EH1 S T IH0 D'],
  "vester": ['V EH1 S T ER0'],
  "vestibular": ['V EH2 S T IH1 B Y UW0 L ER0'],
  "vestibule": ['V EH1 S T IH0 B Y UW2 L'],
  "vestibules": ['V EH1 S T IH0 B Y UW2 L Z'],
  "vestige": ['V EH1 S T IH0 JH'],
  "vestiges": ['V EH1 S T IH0 JH IH0 Z'],
  "vestigial": ['V AH0 S T IH1 JH IY0 AH0 L'],
  "vesting": ['V EH1 S T IH0 NG'],
  "vestment": ['V EH1 S T M EH0 N T'],
  "vestments": ['V EH1 S T M EH0 N T S'],
  "vestron": ['V EH1 S T R AH0 N'],
  "vestron's": ['V EH1 S T R AH0 N Z'],
  "vests": ['V EH1 S T S'],
  "vesuvius": ['V AH0 S UW1 V IY0 AH0 S'],
  "vet": ['V EH1 T'],
  "veta": ['V EH1 T AH0'],
  "vetco": ['V EH1 T K OW0'],
  "veteran": ['V EH1 T ER0 AH0 N', 'V EH1 T R AH0 N'],
  "veteran's": ['V EH1 T ER0 AH0 N Z'],
  "veterans": ['V EH1 T ER0 AH0 N Z', 'V EH1 T R AH0 N Z'],
  "veterans'": ['V EH1 T ER0 AH0 N Z', 'V EH1 T R AH0 N Z'],
  "vetere": ['V EH1 T ER0'],
  "veterinarian": ['V EH2 T R AH0 N EH1 R IY0 AH0 N', 'V EH2 T ER0 AH0 N EH1 R IY0 AH0 N'],
  "veterinarians": ['V EH2 T R AH0 N EH1 R IY0 AH0 N Z', 'V EH2 T ER0 AH0 N EH1 R IY0 AH0 N Z'],
  "veterinary": ['V EH1 T R AH0 N EH2 R IY0'],
  "veteto": ['V EH0 T EH1 T OW0'],
  "veto": ['V IY1 T OW2'],
  "vetoed": ['V IY1 T OW2 D'],
  "vetoes": ['V IY1 T OW2 Z'],
  "vetoing": ['V IY1 T OW2 IH0 NG'],
  "vetos": ['V IY1 T OW2 Z'],
  "vetrano": ['V EH0 T R AA1 N OW0'],
  "vets": ['V EH1 T S'],
  "vetsch": ['V EH1 CH'],
  "vette": ['V EH1 T'],
  "vetted": ['V EH1 T IH0 D'],
  "vetter": ['V EH1 T ER0'],
  "vetting": ['V EH1 T IH0 NG'],
  "veuve": ['V Y UW1 V'],
  "vevay": ['V EH1 V EY0'],
  "veverka": ['V EY0 V EH1 R K AH0'],
  "vevey": ['V EH1 V IY0'],
  "vevila": ['V EY0 V IY1 L AH0'],
  "vex": ['V EH1 K S'],
  "vexatious": ['V EH0 K S EY1 SH AH0 S'],
  "vexed": ['V EH1 K S T'],
  "vexes": ['V EH1 K S IH0 Z'],
  "vexing": ['V EH1 K S IH0 NG'],
  "vey": ['V EY1'],
  "veyne": ['V EY1 N'],
  "vezina": ['V EH0 Z IY1 N AH0'],
  "vi": ['V AY1', 'V IY1'],
  "via": ['V AY1 AH0', 'V IY1 AH0'],
  "viability": ['V AY0 AH0 B IH1 L AH0 T IY0'],
  "viable": ['V AY1 AH0 B AH0 L'],
  "viacom": ['V AY1 AH0 K AA0 M'],
  "viacom's": ['V AY1 AH0 K AA0 M Z'],
  "viaduct": ['V AY1 AH0 D AH0 K T'],
  "viaducts": ['V AY1 AH0 D AH0 K T S'],
  "viag": ['V AY1 AE0 G'],
  "viag's": ['V AY1 AE0 G Z'],
  "viagene": ['V AY1 AH0 JH IY2 N'],
  "viagra": ['V IY0 AE1 G R AH0'],
  "vial": ['V AY1 AH0 L'],
  "viall": ['V AY1 AH0 L'],
  "vialpando": ['V IY0 AA0 L P AA1 N D OW0'],
  "vials": ['V AY1 AH0 L Z'],
  "vian": ['V IY1 AH0 N'],
  "viana": ['V IY0 AE1 N AH0'],
  "viani": ['V IY0 AA1 N IY0'],
  "viano": ['V IY0 AA1 N OW0'],
  "viansa": ['V IY0 AA1 N S AH0'],
  "viar": ['V AY1 ER0'],
  "viars": ['V AY1 ER0 Z'],
  "viatical": ['V AY2 AE1 T IH0 K AH0 L'],
  "viator": ['V AY0 EY1 T ER0'],
  "viau": ['V IY0 OW1'],
  "vibbert": ['V IH1 B ER0 T'],
  "vibe": ['V AY1 B'],
  "viber": ['V AY1 B ER0'],
  "vibes": ['V AY1 B Z'],
  "vibhu": ['V IY1 B UW0'],
  "vibrancy": ['V AY1 B R AH0 N S IY0'],
  "vibrant": ['V AY1 B R AH0 N T'],
  "vibrate": ['V AY1 B R EY0 T'],
  "vibrates": ['V AY1 B R EY0 T S'],
  "vibrating": ['V AY1 B R EY0 T IH0 NG'],
  "vibration": ['V AY0 B R EY1 SH AH0 N'],
  "vibrational": ['V AY0 B R EY1 SH AH0 N AH0 L'],
  "vibrations": ['V AY0 B R EY1 SH AH0 N Z'],
  "vibrato": ['V IY0 B R AA1 T OW0'],
  "vibrator": ['V AY1 B R EY0 T ER0'],
  "vibrators": ['V AY1 B R EY0 T ER0 Z'],
  "vic": ['V IH1 K'],
  "vic's": ['V IH1 K S'],
  "vicar": ['V IH1 K ER0'],
  "vicari": ['V IY0 K AA1 R IY0'],
  "vicario": ['V IY0 K AA1 R IY0 OW0'],
  "vicarious": ['V AY0 K EH1 R IY0 AH0 S'],
  "vicariously": ['V AY0 K EH1 R IY0 AH0 S L IY0'],
  "vicars": ['V IH1 K ER0 Z'],
  "vicary": ['V IH1 K ER0 IY0'],
  "vice": ['V AY1 S'],
  "vice-president": ['V AY1 S P R EH1 Z AH0 D AH0 N T'],
  "vice-presidential": ['V AY1 S P R EH2 Z AH0 D EH1 N CH AH0 L', 'V AY1 S P R EH2 Z AH0 D EH1 N SH AH0 L'],
  "vice-presidents": ['V AY1 S P R EH1 Z AH0 D AH0 N T S'],
  "vicencio": ['V IH0 S EH1 N S IY0 OW0'],
  "vicente": ['V IY0 CH EH1 N T IY0'],
  "vicenzi": ['V IH0 S EH1 N Z IY0'],
  "viceroy": ['V AY1 S R OY0'],
  "vices": ['V AY1 S AH0 Z', 'V AY1 S IH0 Z'],
  "vichy": ['V IY1 SH IY0'],
  "vichyssoise": ['V IY2 SH IH0 S W AA1 Z'],
  "vicinity": ['V AH0 S IH1 N AH0 T IY0', 'V IH0 S IH1 N IH0 T IY0'],
  "vicino": ['V IY0 CH IY1 N OW0'],
  "vicious": ['V IH1 SH AH0 S'],
  "viciously": ['V IH1 SH AH0 S L IY0'],
  "viciousness": ['V IH1 SH AH0 S N IH0 S'],
  "vicissitude": ['V IH2 S IH1 S IH0 T UW0 D'],
  "vicissitudes": ['V IH0 S IH1 S IH0 T UW0 D Z'],
  "vick": ['V IH1 K'],
  "vicker": ['V IH1 K ER0'],
  "vickerman": ['V IH1 K ER0 M AH0 N'],
  "vickers": ['V IH1 K ER0 Z'],
  "vickery": ['V IH0 S K ER1 IY0', 'V IH0 K ER1 IY0'],
  "vickey": ['V IH1 K IY0'],
  "vicki": ['V IH1 K IY0'],
  "vickie": ['V IH1 K IY0'],
  "vickland": ['V IH1 K L AH0 N D'],
  "vicknair": ['V IH1 K N ER0'],
  "vickrey": ['V IH1 K R IY0'],
  "vickroy": ['V IH1 K R OY2'],
  "vicks": ['V IH1 K S'],
  "vicksburg": ['V IH1 K S B ER0 G'],
  "vicky": ['V IH1 K IY0'],
  "vicky's": ['V IH1 K IY0 Z'],
  "vicomte": ['V IH0 K AA1 M T IY0', 'V IH0 K AA1 M T'],
  "vicon": ['V IH1 K AH0 N'],
  "vicon's": ['V IH1 K AH0 N Z'],
  "vicorp": ['V AY1 K AO0 R P'],
  "victim": ['V IH1 K T AH0 M', 'V IH1 K T IH0 M'],
  "victim's": ['V IH1 K T AH0 M Z', 'V IH1 K T IH0 M Z'],
  "victimhood": ['V IH1 K T AH0 M HH UH2 D'],
  "victimization": ['V IH2 K T AH0 M AH0 Z EY1 SH AH0 N'],
  "victimize": ['V IH1 K T AH0 M AY0 Z'],
  "victimized": ['V IH1 K T AH0 M AY0 Z D'],
  "victimizing": ['V IH1 K T AH0 M AY0 Z IH0 NG'],
  "victimless": ['V IH1 K T AH0 M L AH0 S'],
  "victims": ['V IH1 K T AH0 M Z', 'V IH1 K T IH0 M Z'],
  "victims'": ['V IH1 K T IH0 M Z'],
  "victoire": ['V IH0 K T W AA1 R'],
  "victor": ['V IH1 K T ER0'],
  "victor's": ['V IH1 K T ER0 Z'],
  "victoria": ['V IH0 K T AO1 R IY0 AH0'],
  "victoria's": ['V IH0 K T AO1 R IY0 AH0 Z'],
  "victorian": ['V IH0 K T AO1 R IY0 AH0 N'],
  "victorians": ['V IH0 K T AO1 R IY0 AH0 N Z'],
  "victorias": ['V IH0 K T AO1 R IY0 AH0 Z'],
  "victories": ['V IH1 K T ER0 IY0 Z', 'V IH1 K T R IY0 Z'],
  "victorine": ['V IY0 K T AO0 R IY1 N IY0'],
  "victorino": ['V IY0 K T AO0 R IY1 N OW0'],
  "victorious": ['V IH0 K T AO1 R IY0 AH0 S'],
  "victors": ['V IH1 K T ER0 Z'],
  "victory": ['V IH1 K T ER0 IY0', 'V IH1 K T R IY0'],
  "vicuna": ['V IH0 K Y UW1 N AH0'],
  "vida": ['V AY1 D AH0'],
  "vidal": ['V AY0 D AE1 L'],
  "vidales": ['V IY0 D AA1 L EH0 S'],
  "vidalia": ['V IH0 D EY1 L Y AH0', 'V AY0 D EY1 L Y AH0'],
  "vidartes": ['V IH0 D AA1 R T EH0 S'],
  "vidaurri": ['V IH0 D AO1 R IY0'],
  "vide": ['V AY1 D'],
  "video": ['V IH1 D IY0 OW0'],
  "video's": ['V IH1 D IY0 OW0 Z'],
  "videocassette": ['V IH1 D IY0 OW0 K AE0 S EH2 T'],
  "videocassettes": ['V IH1 D IY0 OW0 K AE0 S EH2 T S'],
  "videoconference": ['V IY1 D IY0 OW0 K AA2 N F R AH0 N S'],
  "videoconferencing": ['V IY2 D IY0 OW0 K AA1 N F R AH0 N S IH0 NG'],
  "videodisk": ['V IH1 D IY0 OW0 D IH2 S K'],
  "videodisks": ['V IH1 D IY0 OW0 D IH2 S K S'],
  "videogame": ['V IH1 D IY0 OW0 G EY2 M'],
  "videographer": ['V IH0 D IY0 AO1 G R AH0 F ER0'],
  "videophile": ['V IH1 D IY0 OW0 F AY2 AH0 L'],
  "videophone": ['V IH1 D IY0 OW0 F OW2 N'],
  "videos": ['V IH1 D IY0 OW0 Z'],
  "videotape": ['V IH1 D IY0 OW0 T EY1 P'],
  "videotaped": ['V IH1 D IY0 OW0 T EY1 P T'],
  "videotapes": ['V IH1 D IY0 OW0 T EY1 P S'],
  "videotaping": ['V IH1 D IY0 OW0 T EY2 P IH0 NG'],
  "videotex": ['V IH1 D IY0 OW0 T EH2 K S'],
  "videotext": ['V IH1 D IY0 OW0 T EH2 K S T'],
  "videotron": ['V IH1 D IY0 OW0 T R AA2 N'],
  "vidigo": ['V IH1 D IY0 G OW2'],
  "vidmar": ['V IH1 D M ER0'],
  "vidonia": ['V IY0 D OW1 N IY0 AH0'],
  "vidor": ['V IY1 D AO0 R', 'V IH1 D AO0 R'],
  "vidrine": ['V IY0 D R IY1 N'],
  "vie": ['V AY1'],
  "vieau": ['V IY0 OW1'],
  "viebrock": ['V IY1 B R AH0 K'],
  "vied": ['V AY1 D'],
  "viedma": ['V IY1 D M AH0'],
  "viegas": ['V IY1 G AH0 Z'],
  "vieira": ['V IY0 EH1 R AH0'],
  "viejo": ['V IY0 EH1 JH OW0'],
  "viel": ['V IY1 L'],
  "viele": ['V IY1 L'],
  "vielma": ['V IY1 L M AH0'],
  "vien": ['V IY1 N'],
  "vienna": ['V IY0 EH1 N AH0'],
  "vienna's": ['V IY0 EH1 N AH0 Z'],
  "vienneau": ['V AH0 N OW1'],
  "viennese": ['V IY0 EH1 N IY2 S'],
  "vienot": ['V IY1 N AA0 T'],
  "viens": ['V IY1 N Z'],
  "viera": ['V IY1 R AH0'],
  "viereck": ['V IH1 R IH0 K'],
  "vierling": ['V IH1 R L IH0 NG'],
  "vierra": ['V IH1 R AA0'],
  "viers": ['V IH1 R Z'],
  "viertel": ['V IH1 R T EH0 L'],
  "viertel's": ['V IH1 R T EH0 L Z'],
  "viertels": ['V IH1 R T EH0 L Z'],
  "vies": ['V AY1 Z'],
  "viet": ['V IY0 EH1 T'],
  "vietcong": ['V IY0 EH1 T K AO1 NG', 'V Y EH1 T K AO1 NG'],
  "vieth": ['V AY1 IH0 TH'],
  "vietnam": ['V IY0 EH2 T N AA1 M'],
  "vietnam's": ['V IY0 EH2 T N AA1 M Z'],
  "vietnamese": ['V IY0 EH2 T N AA0 M IY1 S'],
  "vietor": ['V IY1 T ER0'],
  "viets": ['V IY1 T S'],
  "vietti": ['V IY0 EH1 T IY2'],
  "view": ['V Y UW1'],
  "viewed": ['V Y UW1 D'],
  "viewer": ['V Y UW1 ER0'],
  "viewer's": ['V Y UW1 ER0 Z'],
  "viewers": ['V Y UW1 ER0 Z'],
  "viewers'": ['V Y UW1 ER0 Z'],
  "viewership": ['V Y UW1 ER0 SH IH2 P'],
  "viewfinder": ['V Y UW1 F AY2 N D ER0'],
  "viewing": ['V Y UW1 IH0 NG'],
  "viewpoint": ['V Y UW1 P OY2 N T'],
  "viewpoints": ['V Y UW1 P OY2 N T S'],
  "views": ['V Y UW1 Z'],
  "vieyra": ['V IY0 EH1 R AH0'],
  "vig": ['V IH1 G'],
  "vigdor": ['V IH1 G D ER0'],
  "vigeant": ['V IH1 JH AH0 N T'],
  "vigeland": ['V AY1 G L AH0 N D'],
  "vigeland's": ['V AY1 G L AH0 N D Z'],
  "vigen": ['V IH1 G AH0 N'],
  "viger": ['V AY1 G ER0', 'V IH1 G ER0'],
  "viggiano": ['V IH0 JH IY0 AA1 N OW2'],
  "vigil": ['V IH1 JH AH0 L'],
  "vigilance": ['V IH1 JH AH0 L AH0 N S'],
  "vigilant": ['V IH1 JH AH0 L AH0 N T'],
  "vigilante": ['V IH2 JH AH0 L AE1 N T IY0'],
  "vigilantes": ['V IH2 JH AH0 L AE1 N T IY0 Z'],
  "vigilantism": ['V IH2 JH AH0 L AE1 N T IH0 Z AH0 M'],
  "vigilia": ['V IY0 JH IY1 L IY0 AH0'],
  "vigils": ['V IH1 JH AH0 L Z'],
  "viglione": ['V IH0 G L IY0 OW1 N IY2'],
  "vigliotti": ['V IH0 G L IY0 AO1 T IY2'],
  "viglund": ['V IH1 G L AH0 N D'],
  "viglund's": ['V IH1 G L AH0 N D Z'],
  "vigna": ['V IH1 G N AH0'],
  "vigneau": ['V IH0 G N OW1'],
  "vigneault": ['V IH0 G N OW1'],
  "vignette": ['V IH0 N Y EH1 T', 'V IY0 N Y EH1 T'],
  "vignettes": ['V IH0 N Y EH1 T S'],
  "vignola": ['V IY0 G N OW1 L AA0'],
  "vigo": ['V IY1 G OW0'],
  "vigor": ['V IH1 G ER0'],
  "vigorito": ['V IY0 G AO0 R IY1 T OW0'],
  "vigorous": ['V IH1 G ER0 AH0 S'],
  "vigorously": ['V IH1 G ER0 AH0 S L IY0'],
  "vigue": ['V IY1 G'],
  "viguerie": ['V IH1 G ER0 IY0', 'V IY2 G EH1 R IY0'],
  "vigus": ['V AY1 G AH0 S'],
  "vik": ['V IH1 K'],
  "vike": ['V AY1 K'],
  "vikes": ['V AY1 K S'],
  "viking": ['V AY1 K IH0 NG'],
  "viking's": ['V AY1 K IH0 NG Z'],
  "vikings": ['V AY1 K IH0 NG Z'],
  "viktor": ['V IH1 K T ER0'],
  "vila": ['V IY1 L AH0'],
  "vilardo": ['V IY0 L AA1 R D OW2'],
  "vilas": ['V IY1 L AA0 Z'],
  "vile": ['V AY1 L'],
  "viles": ['V AY1 L Z'],
  "vilhauer": ['V IH1 L HH AW0 ER0'],
  "vilification": ['V IH2 L AH0 F AH0 K EY1 SH AH0 N'],
  "vilified": ['V IH1 L AH0 F AY0 D'],
  "vilify": ['V IH1 L IH0 F AY2'],
  "villa": ['V IH1 L AH0'],
  "villa's": ['V IH1 L AH0 Z'],
  "villafana": ['V IY0 L AA0 F AE1 N AH0'],
  "villafane": ['V IY0 L AA0 F AA1 N EY0'],
  "villafranca": ['V IH2 L AH0 F R AE1 NG K AH0'],
  "villafuerte": ['V IY0 L AA0 F W EH1 R T EY0'],
  "village": ['V IH1 L AH0 JH', 'V IH1 L IH0 JH'],
  "village's": ['V IH1 L IH0 JH IH0 Z'],
  "villager": ['V IH1 L IH0 JH ER0'],
  "villagers": ['V IH1 L IH0 JH ER0 Z'],
  "villages": ['V IH1 L AH0 JH AH0 Z', 'V IH1 L IH0 JH IH0 Z'],
  "villagomez": ['V IY0 L AA0 G OW1 M EH0 Z'],
  "villagran": ['V IY0 L AA0 G R AA1 N'],
  "villagrana": ['V IY0 L AA0 G R AE1 N AH0'],
  "villain": ['V IH1 L AH0 N'],
  "villainous": ['V IH1 L AH0 N AH0 S'],
  "villains": ['V IH1 L AH0 N Z'],
  "villalba": ['V IY0 L AA1 L B AH0'],
  "villalobos": ['V IY0 L AA0 L OW1 B OW0 Z'],
  "villalon": ['V IY0 L AA0 L AO1 N'],
  "villalovos": ['V IY0 L AA0 L OW1 V OW0 Z'],
  "villalpando": ['V IY0 L AA0 L P AA1 N D OW0'],
  "villalva": ['V IY0 L AA1 L V AH0'],
  "villamil": ['V IY0 L AA0 M IY1 L'],
  "villani": ['V IY0 L AA1 N IY0'],
  "villano": ['V IY0 L AA1 N OW0'],
  "villanova": ['V IH2 L AH0 N OW1 V AH0'],
  "villanovan": ['V IH2 L AH0 N OW1 V AH0 N'],
  "villanueva": ['V IY0 L AA0 N W EH1 V AH0'],
  "villar": ['V IH1 L ER0'],
  "villard": ['V IH1 L ER0 D'],
  "villareal": ['V IH0 L ER0 IY0 AA1 L'],
  "villari": ['V IY0 L AA1 R IY0'],
  "villarreal": ['V IY2 L AA0 R IY1 L'],
  "villars": ['V IH1 L ER0 Z'],
  "villas": ['V IH1 L AH0 Z'],
  "villasana": ['V IY2 L AA0 S AE1 N AH0'],
  "villasenor": ['V IY2 L AA0 S EH0 N AO1 R'],
  "villaverde": ['V IY2 L AA0 V EH1 R D IY0'],
  "villavicencio": ['V IH2 L AH0 V IH0 S EH1 N S IY0 OW0'],
  "ville": ['V IH1 L'],
  "ville's": ['V IH1 L Z'],
  "villegas": ['V IH1 L IH0 G AH0 Z'],
  "villela": ['V IH1 L IH0 L AH0'],
  "villella": ['V IY0 L EH1 L AH0'],
  "villeneuve": ['V IH1 L IH0 N UW0 V', 'V IH1 L N UW0 V'],
  "villers": ['V IH1 L ER0 Z'],
  "villette": ['V IH0 L EH1 T'],
  "villi": ['V IH1 L IY0'],
  "villiers": ['V IH1 L Y ER0 Z'],
  "villines": ['V IH1 L AY2 N Z'],
  "villwock": ['V IH1 L W AH0 K'],
  "vilma": ['V IH1 L M AH0'],
  "vilnius": ['V IH1 L N IY0 AH0 S'],
  "viloria": ['V IY0 L AO1 R IY2 AH0'],
  "vim": ['V IH1 M'],
  "vin": ['V IH1 N'],
  "vina": ['V IY1 N AH0'],
  "vinal": ['V AY1 N AH0 L'],
  "vinca": ['V IH1 NG K AH0'],
  "vince": ['V IH1 N S'],
  "vince's": ['V IH1 N S IH0 S'],
  "vincelette": ['V IH1 N S IH0 L EH0 T'],
  "vincennes": ['V IH2 N S EH1 N AH0 S'],
  "vincent": ['V IH1 N S AH0 N T', 'V IH1 N S IH0 N T'],
  "vincent's": ['V IH1 N S IH0 N T S'],
  "vincente": ['V IH0 N CH EH1 N T IY0'],
  "vincenti": ['V IY0 N CH EH1 N T IY0'],
  "vincentia": ['V IY0 N CH EH1 N SH AH0'],
  "vincenzo": ['V IH0 N S EH1 N Z OW0'],
  "vinci": ['V IH1 N S IY0', 'V IH1 N CH IY0'],
  "vinci's": ['V IH1 N S IY0 Z', 'V IH1 N CH IY0 Z'],
  "vinciguerra": ['V IY0 N CH IY0 G EH1 R AH0'],
  "vindicate": ['V IH1 N D AH0 K EY0 T'],
  "vindicated": ['V IH1 N D AH0 K EY0 T IH0 D'],
  "vindicates": ['V IH1 N D IH0 K EY2 T S'],
  "vindication": ['V IH0 N D AH0 K EY1 SH AH0 N'],
  "vindicator": ['V IH0 N D AH0 K EY1 T ER0'],
  "vindictive": ['V IH0 N D IH1 K T IH0 V'],
  "vindictiveness": ['V IH0 N D IH1 K T IH0 V N AH0 S'],
  "vine": ['V AY1 N'],
  "vinegar": ['V IH1 N AH0 G ER0'],
  "vinegars": ['V IH1 N IH0 G ER0 Z'],
  "vineland": ['V AY1 N L AH0 N D'],
  "viner": ['V AY1 N ER0'],
  "vines": ['V AY1 N Z'],
  "vinet": ['V IH1 N IH0 T'],
  "viney": ['V AY1 N IY0'],
  "vineyard": ['V IH1 N Y ER0 D'],
  "vineyards": ['V IH1 N Y ER0 D Z'],
  "vingmed": ['V IH1 NG M EH0 D'],
  "vingo": ['V IH1 NG G OW0'],
  "vini": ['V IY1 N IY0'],
  "vinicio": ['V IH0 N IH1 S IY0 OW0'],
  "vinick": ['V IH1 N IH0 K'],
  "vinick's": ['V IH1 N IH0 K S'],
  "vinik": ['V IH1 N IH0 K'],
  "vinik's": ['V IH1 N IH0 K S'],
  "vining": ['V AY1 N IH0 NG'],
  "vining's": ['V AY1 N IH0 NG Z'],
  "vinita": ['V IY0 N IY1 T AH0'],
  "vinje": ['V IH1 N JH'],
  "vink": ['V IH1 NG K'],
  "vinnick": ['V IH1 N IH0 K'],
  "vinnick's": ['V IH1 N IH0 K S'],
  "vinnie": ['V IH1 N IY0'],
  "vinny": ['V IH1 N IY0'],
  "vinocur": ['V IH1 N AH0 K ER0'],
  "vinsant": ['V IY1 N S AH0 N T'],
  "vinso": ['V IH1 N S OW0'],
  "vinson": ['V IH1 N S AH0 N'],
  "vint": ['V IH1 N T'],
  "vintage": ['V IH1 N T IH0 JH'],
  "vintages": ['V IH1 N T IH0 JH IH0 Z'],
  "vintner": ['V IH1 N T N ER0'],
  "vintners": ['V IH1 N T N ER0 Z'],
  "vinton": ['V IH1 N T AH0 N'],
  "vinyard": ['V IH1 N Y ER0 D'],
  "vinyl": ['V AY1 N AH0 L'],
  "vinzant": ['V IH1 N Z AH0 N T'],
  "viola": ['V AY0 OW1 L AH0', 'V IY0 OW1 L AH0'],
  "violable": ['V AY1 AH0 L AH0 B AH0 L'],
  "violante": ['V IY0 OW0 L AA1 N T IY0'],
  "violas": ['V IY0 OW1 L AH0 Z'],
  "violate": ['V AY1 AH0 L EY0 T'],
  "violated": ['V AY1 AH0 L EY0 T IH0 D'],
  "violates": ['V AY1 AH0 L EY0 T S'],
  "violating": ['V AY1 AH0 L EY0 T IH0 NG'],
  "violation": ['V AY0 AH0 L EY1 SH AH0 N'],
  "violations": ['V AY0 AH0 L EY1 SH AH0 N Z'],
  "violator": ['V AY1 AH0 L EY2 T ER0'],
  "violators": ['V AY1 AH0 L EY0 T ER0 Z'],
  "viole": ['V AY2 OW1 L'],
  "violence": ['V AY1 AH0 L AH0 N S'],
  "violent": ['V AY1 AH0 L AH0 N T', 'V AY1 L AH0 N T'],
  "violently": ['V AY1 AH0 L AH0 N T L IY0', 'V AY1 L AH0 N T L IY0'],
  "violet": ['V AY1 AH0 L IH0 T', 'V AY1 L IH0 T'],
  "violeta": ['V IY2 OW0 L EH1 T AH0', 'V AY2 OW0 L EH1 T AH0'],
  "violets": ['V AY1 AH0 L AH0 T S', 'V AY1 L AH0 T S'],
  "violett": ['V AY1 AH0 L AH0 T', 'V AY1 L AH0 T', 'V IY2 OW0 L EH1 T'],
  "violetta": ['V IY0 OW0 L EH1 T AH0'],
  "violette": ['V IY2 OW0 L EH1 T'],
  "violin": ['V AY0 AH0 L IH1 N'],
  "violinist": ['V AY0 AH0 L IH1 N AH0 S T'],
  "violinists": ['V AY0 AH0 L IH1 N AH0 S T S'],
  "violins": ['V AY0 AH0 L IH1 N Z'],
  "violist": ['V IY2 OW1 L AH0 S T'],
  "violist's": ['V IY2 OW1 L AH0 S T S', 'V IY2 OW1 L AH0 S'],
  "violists": ['V IY2 OW1 L AH0 S T S', 'V IY2 OW1 L AH0 S'],
  "vip": ['V IY2 AY2 P IY1'],
  "vip's": ['V IY2 AY2 P IY1 Z'],
  "viper": ['V AY1 P ER0'],
  "vipers": ['V AY1 P ER0 Z'],
  "vipin": ['V IH1 P IH0 N'],
  "vipond": ['V IH1 P AH0 N D'],
  "vipont": ['V IH1 P AA2 N T'],
  "vipont's": ['V IH1 P AA2 N T S'],
  "vipperman": ['V IH1 P ER0 M AH0 N'],
  "vips": ['V IY2 AY2 P IY1 Z'],
  "vipul": ['V IY2 P UW1 L'],
  "virag": ['V IH1 R AH0 G'],
  "virago": ['V IH0 R AA1 G OW2'],
  "viral": ['V AY1 R AH0 L'],
  "viramontes": ['V IH0 R AA0 M OW1 N T EH0 S'],
  "viramune": ['V IY2 R AH0 M UW1 N EH2'],
  "viramunes": ['V IY2 R AH0 M UW1 N EH2 Z'],
  "viratek": ['V IH1 R AH0 T EH2 K'],
  "viray": ['V AY1 R EY0'],
  "virazole": ['V IH1 R AH0 Z OW2 L', 'V AY1 R AH0 Z OW2 L'],
  "virco": ['V ER1 K OW0'],
  "virden": ['V ER1 D AH0 N'],
  "viren": ['V IH1 R AH0 N'],
  "vires": ['V AY1 R Z'],
  "virga": ['V ER1 G AH0'],
  "virge": ['V ER1 JH'],
  "virgen": ['V ER1 JH AH0 N'],
  "virgie": ['V ER1 G IY0'],
  "virgil": ['V ER1 JH AH0 L'],
  "virgilio": ['V ER0 JH IH1 L IY0 OW0'],
  "virgin": ['V ER1 JH IH0 N'],
  "virgin's": ['V ER1 JH AH0 N Z'],
  "virginal": ['V ER1 JH AH0 N AH0 L'],
  "virginia": ['V ER0 JH IH1 N Y AH0'],
  "virginia's": ['V ER0 JH IH1 N Y AH0 Z'],
  "virginian": ['V ER0 JH IH1 N Y AH0 N'],
  "virginians": ['V ER0 JH IH1 N Y AH0 N Z'],
  "virginity": ['V ER0 JH IH1 N IH0 T IY0'],
  "virgins": ['V ER1 JH AH0 N Z'],
  "virgo": ['V ER1 G OW0'],
  "virgule": ['V IH1 R G Y UW2 L'],
  "virgules": ['V IH1 R G Y UW2 L Z'],
  "virgy": ['V ER1 JH IY0'],
  "viridis": ['V IH1 R IH0 D IH2 S'],
  "virile": ['V IH1 R AH0 L'],
  "virility": ['V ER0 IH1 L AH0 T IY0'],
  "virina": ['V IH0 R IY1 N AH0'],
  "virkler": ['V ER1 K AH0 L ER0', 'V ER1 K L ER0'],
  "virna": ['V ER1 N AH0'],
  "virnig": ['V ER1 N IH0 G'],
  "virological": ['V AY0 R AH0 L AA1 JH IH0 K AH0 L'],
  "virologist": ['V IH0 R AA1 L AH0 JH IH0 S T'],
  "virologists": ['V IH0 R AA1 L AH0 JH IH0 S T S'],
  "virology": ['V IH0 R AA1 L AH0 JH IY0'],
  "viromune": ['V AY2 R OW0 M Y UW1 N'],
  "virts": ['V ER1 T S'],
  "virtual": ['V ER1 CH UW0 AH0 L'],
  "virtually": ['V ER1 CH UW0 AH0 L IY0', 'V ER1 CH UW0 L IY0'],
  "virtue": ['V ER1 CH UW0'],
  "virtues": ['V ER1 CH UW0 Z'],
  "virtuosity": ['V ER0 CH UW0 AA1 S IH0 T IY0'],
  "virtuoso": ['V ER0 CH UW0 OW1 S OW0'],
  "virtuous": ['V ER1 CH UW0 AH0 S'],
  "virulence": ['V IH1 R AH0 L AH0 N S'],
  "virulent": ['V IH1 R AH0 L AH0 N T'],
  "virulently": ['V IH1 R AH0 L AH0 N T L IY0'],
  "virus": ['V AY1 R AH0 S'],
  "virus's": ['V AY1 R AH0 S IH0 Z'],
  "viruses": ['V AY1 R AH0 S AH0 Z', 'V AY1 R AH0 S IH0 Z'],
  "virzi": ['V IH1 R Z IY0'],
  "vis": ['V IH1 S'],
  "vis-a-vis": ['V IY1 Z AH0 V IY0'],
  "visa": ['V IY1 Z AH0'],
  "visa's": ['V IY1 Z AH0 Z'],
  "visage": ['V IH1 Z AH0 JH'],
  "visas": ['V IY1 Z AH0 Z'],
  "viscardi": ['V IY0 S K AA1 R D IY0'],
  "visceral": ['V IH1 S ER0 AH0 L'],
  "viscerally": ['V IH1 S ER0 AH0 L IY0'],
  "visclosky": ['V IH0 S K L AO1 S K IY0'],
  "visco": ['V IY1 S K OW0'],
  "viscomi": ['V IY0 S K OW1 M IY0'],
  "visconti": ['V IY0 S K OW1 N T IY0'],
  "viscosity": ['V IH0 S K AA1 S AH0 T IY0'],
  "viscount": ['V IH1 S K AW0 N T'],
  "viscounts": ['V IH1 S K AW0 N T S'],
  "viscous": ['V IH1 S K AH0 S'],
  "viscusi": ['V IY0 S K UW1 S IY0'],
  "viscuso": ['V IY0 S K UW1 S OW0'],
  "vise": ['V AY1 S'],
  "visegrad": ['V IH1 S AH0 G R AE2 D'],
  "visenteno": ['V IH2 S AH0 N T EH1 N OW0'],
  "vishay": ['V IH1 SH EY0'],
  "vishnu": ['V IH1 SH N UW0'],
  "visibility": ['V IH2 Z AH0 B IH1 L IH0 T IY0'],
  "visible": ['V IH1 Z AH0 B AH0 L'],
  "visibly": ['V IH1 Z AH0 B L IY0'],
  "visicalc": ['V IH1 Z IH0 K AE2 L K'],
  "visigoth": ['V IH1 Z AH0 G AA0 TH'],
  "visigoths": ['V IH1 Z AH0 G AA0 TH S'],
  "vision": ['V IH1 ZH AH0 N'],
  "vision's": ['V IH1 ZH AH0 N Z'],
  "visionaries": ['V IH1 ZH AH0 N EH0 R IY0 Z'],
  "visionary": ['V IH1 ZH AH0 N EH2 R IY0'],
  "visions": ['V IH1 ZH AH0 N Z'],
  "visit": ['V IH1 Z IH0 T'],
  "visitation": ['V IH2 Z IH0 T EY1 SH AH0 N'],
  "visitations": ['V IH2 Z IH0 T EY1 SH AH0 N Z'],
  "visited": ['V IH1 Z IH0 T IH0 D'],
  "visiting": ['V IH1 Z IH0 T IH0 NG'],
  "visitor": ['V IH1 Z IH0 T ER0'],
  "visitor's": ['V IH1 Z IH0 T ER0 Z'],
  "visitors": ['V IH1 Z IH0 T ER0 Z'],
  "visitors'": ['V IH1 Z IH0 T ER0 Z'],
  "visits": ['V IH1 Z IH0 T S'],
  "visnic": ['V IH1 S N IH0 CH'],
  "visor": ['V AY1 Z ER0'],
  "visors": ['V AY1 Z ER0 Z'],
  "visscher": ['V IH1 SH ER0'],
  "visser": ['V IH1 S ER0'],
  "vist": ['V IH1 S T'],
  "vista": ['V IH1 S T AH0'],
  "vista's": ['V IH1 S T AH0 Z'],
  "vistas": ['V IH1 S T AH0 Z'],
  "viston": ['V IH1 S T AH0 N'],
  "visual": ['V IH1 ZH AH0 W AH0 L'],
  "visualization": ['V IH2 ZH W AH0 L AH0 Z EY1 SH AH0 N'],
  "visualize": ['V IH1 ZH W AH0 L AY2 Z'],
  "visualized": ['V IH1 ZH W AH0 L AY2 Z D'],
  "visualizing": ['V IH1 ZH W AH0 L AY2 Z IH0 NG'],
  "visually": ['V IH1 ZH W AH0 L IY0'],
  "visuals": ['V IH1 ZH W AH0 L Z'],
  "visx": ['V IH1 S EH2 K S'],
  "vita": ['V AY1 T AH0'],
  "vitae": ['V AY1 T AH0'],
  "vitagliano": ['V IY0 T AA0 G L IY0 AA1 N OW0'],
  "vital": ['V AY1 T AH0 L'],
  "vitale": ['V IH0 T AE1 L IY0', 'V AY2 T AE1 L'],
  "vitali": ['V IY0 T AA1 L IY0'],
  "vitalink": ['V AY1 T AH0 L IH2 NG K'],
  "vitality": ['V AY0 T AE1 L AH0 T IY0'],
  "vitally": ['V AY1 T AH0 L IY0'],
  "vitaly": ['V AH0 T AE1 L IY0', 'V IY0 T AA1 L IY0'],
  "vitamin": ['V AY1 T AH0 M AH0 N'],
  "vitamins": ['V AY1 T AH0 M AH0 N Z'],
  "vitanza": ['V IH0 T AE1 N Z AH0'],
  "vitarine": ['V IH1 T ER0 IY2 N'],
  "vitarine's": ['V IH1 T ER0 IY2 N Z'],
  "vitek": ['V IH1 T EH0 K'],
  "vitelli": ['V IH0 T EH1 L IY0'],
  "vitello": ['V IH0 T EH1 L OW0'],
  "vitez": ['V IH1 T EH0 Z', 'V AY1 T EH0 Z'],
  "viti": ['V IY1 T IY0'],
  "vitia": ['V IY1 SH AH0'],
  "vitiate": ['V IY1 SH IH0 EY2 T'],
  "vitiello": ['V IY0 T IY0 EH1 L OW0'],
  "vitiligo": ['V IY0 T IH1 L IH0 G OW0'],
  "vitner": ['V IH1 T N ER0'],
  "vito": ['V IY1 T OW0'],
  "vitolo": ['V IY0 T OW1 L OW0'],
  "vitramon": ['V IH1 T R AH0 M AA0 N'],
  "vitrano": ['V IY0 T R AA1 N OW0'],
  "vitreous": ['V IH1 T R IY0 AH0 S'],
  "vitric": ['V IH1 T R IH0 K'],
  "vitriol": ['V IH1 T R IY0 AH0 L'],
  "vitriolic": ['V IH2 T R IY0 AA1 L IH0 K'],
  "vitro": ['V IH1 T R OW0', 'V IY1 T R OW0'],
  "vitronics": ['V AY2 T R AA1 N IH0 K S'],
  "vitruvius": ['V IH0 T R UW1 V IY0 AH0 S'],
  "vitry": ['V IH1 T R IY0'],
  "vitt": ['V IH1 T'],
  "vittetoe": ['V IH1 T IH0 T OW0'],
  "vittitow": ['V IH1 T IH0 T OW0'],
  "vittle": ['V IH1 T AH0 L'],
  "vittles": ['V IH1 T AH0 L Z'],
  "vittoria": ['V IH0 T AO1 R IY0 AH0'],
  "vittorio": ['V IY0 T AO1 R IY0 OW0'],
  "vitucci": ['V IY0 T UW1 CH IY0'],
  "vitulli": ['V IY0 T UW1 L IY0'],
  "vitullo": ['V IY0 T UW1 L OW0'],
  "vituperative": ['V AY2 T UW1 P ER0 AH0 T IH0 V', 'V AH0 T UW1 P ER0 AH0 T IH0 V'],
  "viv": ['V IH1 V'],
  "viva": ['V IY1 V AH0'],
  "vivacious": ['V AH0 V EY1 SH AH0 S'],
  "vivaldi": ['V IH0 V AA1 L D IY0'],
  "vivas": ['V IY1 V AH0 Z'],
  "vive": ['V IY1 V', 'V AY1 V'],
  "viveiros": ['V IY0 V IH1 R OW0 Z'],
  "vivendi": ['V IH0 V EH1 N D IY0'],
  "viverette": ['V IH1 V ER0 EH1 T'],
  "viveros": ['V IY0 V EH1 R OW0 Z'],
  "vives": ['V AY1 V Z'],
  "vivian": ['V IH1 V IY0 AH0 N'],
  "viviana": ['V IH0 V IY0 AE1 N AH0'],
  "viviani": ['V IY2 V IY0 AA1 N IY0'],
  "viviano": ['V IY2 V IY0 AA1 N OW0'],
  "vivid": ['V IH1 V AH0 D', 'V IH1 V IH0 D'],
  "vividly": ['V IH1 V AH0 D L IY0'],
  "vividness": ['V IH1 V AH0 D N AH0 S'],
  "vivie": ['V IH1 V IY0'],
  "vivien": ['V IH1 V IY0 AH0 N'],
  "vivienne": ['V IH1 V IY0 AH0 N'],
  "vivier": ['V AY1 V IY0 ER0'],
  "viviparous": ['V AY0 V IH1 P ER0 AH0 S'],
  "vivisepulture": ['V IH1 V IY0 S EH1 P AH0 L CH ER0'],
  "vivo": ['V IY1 V OW0'],
  "vivona": ['V IY0 V OW1 N AH0'],
  "vivra": ['V IY1 V R AH0'],
  "vivyan": ['V IY0 V Y AA1 N'],
  "vixen": ['V IH1 K S IH0 N'],
  "viyella": ['V IH0 Y EH1 L AH0'],
  "vizcaino": ['V IY0 Z K AA0 IY1 N OW0'],
  "vizcarra": ['V IY0 Z K AA1 R AH0'],
  "vizcaya": ['V IH0 Z K AY1 AH0'],
  "vizzini": ['V IY0 T S IY1 N IY0'],
  "vlach": ['V L AE1 CH'],
  "vlachos": ['V L EY1 K OW0 S'],
  "vlad": ['V L AE1 D'],
  "vlad's": ['V L AE1 D Z'],
  "vladeck": ['V L AE1 D EH0 K'],
  "vladic": ['V L AE1 D IH2 K'],
  "vladimir": ['V L AE1 D AH0 M IH0 R'],
  "vladislav": ['V L AE1 D AH0 S L AA0 V'],
  "vladivostok": ['V L AE2 D IH0 V AO1 S T AA0 K', 'V L AE2 D IH0 V OW1 S T AA0 K'],
  "vlahakis": ['V L AH2 HH AO1 K IH0 S'],
  "vlahos": ['V L AA1 HH OW0 S'],
  "vlasak": ['V L AA1 S AH0 K'],
  "vlasic": ['V L AE1 Z IH0 K'],
  "vlcek": ['V L EH1 S IH0 K'],
  "vliet": ['V L IY1 T'],
  "vlok": ['V L AA1 K'],
  "vnesheconombank": ['V AH0 N EH2 SH AH0 K AA1 N AH0 M B AE2 NG K'],
  "vo": ['V OW1'],
  "vobis": ['V OW1 B AH0 S'],
  "vocabulary": ['V OW0 K AE1 B Y AH0 L EH2 R IY0'],
  "vocal": ['V OW1 K AH0 L'],
  "vocalist": ['V OW1 K AH0 L IH0 S T'],
  "vocalists": ['V OW1 K AH0 L IH0 S T S'],
  "vocalize": ['V OW1 K AH0 L AY2 Z'],
  "vocalizes": ['V OW1 K AH0 L AY2 Z IH0 Z'],
  "vocally": ['V OW1 K AH0 L IY0'],
  "vocals": ['V OW1 K AH0 L Z'],
  "vocaltec": ['V OW1 K AH0 L T EH2 K'],
  "vocation": ['V OW0 K EY1 SH AH0 N'],
  "vocational": ['V OW0 K EY1 SH AH0 N AH0 L'],
  "vocations": ['V OW0 K EY1 SH AH0 N Z'],
  "vociferate": ['V AH0 S IH1 F ER0 EY0 T'],
  "vociferated": ['V AH0 S IH1 F ER0 EY0 T IH0 D'],
  "vociferates": ['V AH0 S IH1 F ER0 EY0 T S'],
  "vociferating": ['V AH0 S IH1 F ER0 EY0 T IH0 NG'],
  "vociferous": ['V OW0 S IH1 F ER0 AH0 S'],
  "vociferously": ['V AH0 S IH1 F ER0 AH0 S L IY0'],
  "vocke": ['V AA1 K'],
  "vocs": ['V AA1 K S'],
  "vodafone": ['V OW1 D AH0 F OW2 N'],
  "vodavi": ['V OW0 D AA1 V IY0'],
  "vodicka": ['V AA1 D IH0 K AH0'],
  "vodka": ['V AA1 D K AH0'],
  "vodkas": ['V AA1 D K AH0 Z'],
  "voegele": ['V OW1 G AH0 L'],
  "voegeli": ['V OW1 G IH0 L IY0'],
  "voelkel": ['V OW1 L K AH0 L'],
  "voelker": ['V OW1 L K ER0'],
  "voell": ['V OW1 L'],
  "voeller": ['V OW1 L ER0'],
  "voeltz": ['V OW1 L T S'],
  "voelz": ['V OW1 L Z'],
  "voest": ['V OW1 S T'],
  "vogan": ['V OW1 G AH0 N'],
  "voge": ['V OW1 JH'],
  "vogel": ['V OW1 G AH0 L'],
  "vogeler": ['V OW1 G AH0 L ER0'],
  "vogelgesang": ['V AA1 G IH0 L G IH0 S AH0 NG'],
  "vogelpohl": ['V AA1 G IH0 L P OW0 L'],
  "vogels": ['V OW1 G AH0 L Z'],
  "vogelsang": ['V AA1 G IH0 L S AH0 NG'],
  "vogelsong": ['V AA1 G IH0 L S AO0 NG'],
  "vogelstein": ['V OW1 G AH0 L S T IY2 N', 'V OW1 G AH0 L S T AY2 N'],
  "voges": ['V OW1 JH IH0 Z'],
  "voght": ['V AA1 T'],
  "vogl": ['V AA1 G AH0 L'],
  "vogler": ['V OW1 G L ER0'],
  "vogosca": ['V OW0 G OW1 S K AH0', 'V AH0 G OW1 S K AH0'],
  "vogt": ['V OW1 T'],
  "vogtle": ['V AA1 G T AH0 L'],
  "vogue": ['V OW1 G'],
  "vohs": ['V AA1 S'],
  "voice": ['V OY1 S'],
  "voice's": ['V OY1 S IH0 Z'],
  "voiced": ['V OY1 S T'],
  "voiceless": ['V OY1 S L AH0 S'],
  "voicemail": ['V OY1 S M EY2 L'],
  "voiceover": ['V OY1 S OW2 V ER0'],
  "voices": ['V OY1 S AH0 Z', 'V OY1 S IH0 Z'],
  "voicework": ['V OY1 S W ER2 K'],
  "voiceworks": ['V OY1 S W ER2 K S'],
  "voicing": ['V OY1 S IH0 NG'],
  "void": ['V OY1 D'],
  "voided": ['V OY1 D IH0 D'],
  "voiding": ['V OY1 D IH0 NG'],
  "voids": ['V OY1 D Z'],
  "voight": ['V OY1 T'],
  "voigt": ['V OY1 G T', 'V OY1 T'],
  "voigts": ['V OY1 G T S', 'V OY1 T S'],
  "voila": ['V W AA2 L AA1'],
  "voiles": ['V OY1 L Z'],
  "voinovich": ['V OY1 N AH0 V IH0 CH'],
  "voir": ['V W AA1 R'],
  "voisey": ['V W AA2 S EY1', 'V OY2 S EY1'],
  "voisin": ['V OY0 Z AE1 N'],
  "voisine": ['V OY0 Z IY1 N'],
  "voit": ['V OY1 T'],
  "vojta": ['V OY1 T AH2', 'V OY1 T AH0'],
  "vokes": ['V OW1 K S'],
  "voland": ['V AA1 L AH0 N D'],
  "volante": ['V OW0 L AA1 N T EY0'],
  "volatile": ['V AA1 L AH0 T AH0 L'],
  "volatility": ['V AA2 L AH0 T IH1 L AH0 T IY0'],
  "volberding": ['V OW1 L B ER0 D IH0 NG'],
  "volcanic": ['V AA0 L K AE1 N IH0 K'],
  "volcanically": ['V AA0 L K AE1 N IH0 K L IY0'],
  "volcano": ['V AA0 L K EY1 N OW0'],
  "volcano's": ['V AA0 L K EY1 N OW0 Z'],
  "volcanoes": ['V AA0 L K EY1 N OW0 Z'],
  "volcanologist": ['V AO2 L K AH0 N AO1 L AH0 JH IH0 S T'],
  "volcanologists": ['V AO2 L K AH0 N AO1 L AH0 JH IH0 S T S', 'V AO2 L K AH0 N AO1 L AH0 JH IH0 S'],
  "volcanos": ['V AA0 L K EY1 N OW0 Z'],
  "volcker": ['V OW1 L K ER0'],
  "volcker's": ['V OW1 L K ER0 Z'],
  "vold": ['V OW1 L D'],
  "voldemort": ['V OW1 L D AH0 M AO2 R T'],
  "voldemort's": ['V OW1 L D AH0 M AO2 R T S'],
  "volden": ['V OW1 L D AH0 N'],
  "volentine": ['V OW0 L EH0 N T IY1 N IY0'],
  "voles": ['V OW1 L Z'],
  "voleta": ['V OW0 L EH1 T AH0'],
  "volga": ['V AA1 L G AH0'],
  "volgograd": ['V OW1 L G OW0 G R AE2 D'],
  "volin": ['V OW1 L IH0 N'],
  "volio": ['V OW1 L IY0 OW0'],
  "volition": ['V OW0 L IH1 SH AH0 N'],
  "volk": ['V OW1 L K'],
  "volker": ['V OW1 L K ER0'],
  "volkers": ['V OW1 L K ER0 Z'],
  "volkert": ['V OW1 L K ER0 T'],
  "volkman": ['V OW1 L K M AH0 N'],
  "volkmann": ['V OW1 L K M AH0 N'],
  "volkmar": ['V OW1 L K M ER0'],
  "volkmer": ['V OW1 L K M ER0'],
  "volkogonov": ['V OW0 L K AO1 G AH0 N AO2 V'],
  "volksbank": ['V OW1 L K S B AE2 NG K'],
  "volksfuersorge": ['V OW0 L K S F Y UW1 R S AO0 R JH'],
  "volkswagen": ['V OW1 L K S W AE2 G AH0 N'],
  "volkswagen's": ['V OW1 L K S W AE2 G AH0 N Z'],
  "volkswagens": ['V OW1 L K S W AE2 G AH0 N Z'],
  "voll": ['V AA1 L'],
  "volland": ['V AA1 L AH0 N D'],
  "vollbrecht": ['V AA1 L B R IH0 K T'],
  "volle": ['V AA1 L'],
  "vollenweider": ['V AA1 L IH0 N W AY0 D ER0'],
  "voller": ['V AA1 L ER0'],
  "volley": ['V AA1 L IY0'],
  "volleyball": ['V AA1 L IY0 B AO2 L'],
  "volleys": ['V AA1 L IY0 Z'],
  "vollman": ['V AA1 L M AH0 N'],
  "vollmar": ['V AA1 L M ER0'],
  "vollmer": ['V AA1 L M ER0'],
  "vollrath": ['V AA1 L R AH0 TH'],
  "volmer": ['V OW1 L M ER0'],
  "volner": ['V OW1 L N ER0'],
  "volney": ['V OW1 L N IY0'],
  "volokh": ['V AA1 L AA0 K'],
  "volpe": ['V OW1 L P'],
  "volpi": ['V OW1 L P IY0'],
  "volpicella": ['V OW2 L P IH0 S EH1 L AH0'],
  "volt": ['V OW1 L T'],
  "volta": ['V OW1 L T AH0'],
  "voltage": ['V OW1 L T AH0 JH', 'V OW1 L T IH0 JH'],
  "voltage's": ['V OW1 L T IH0 JH IH0 Z'],
  "voltages": ['V OW1 L T AH0 JH AH0 Z', 'V OW1 L T IH0 JH IH0 Z'],
  "voltaire": ['V OW0 L T EH1 R'],
  "voltaren": ['V OW1 L T ER0 AH0 N'],
  "volts": ['V OW1 L T S'],
  "voltz": ['V OW1 L T S'],
  "voluble": ['V AA1 L Y AH0 B AH0 L'],
  "volume": ['V AA1 L Y UW0 M'],
  "volume's": ['V AA1 L Y AH0 M Z'],
  "volumes": ['V AA1 L Y UW0 M Z'],
  "voluminous": ['V AH0 L UW1 M AH0 N AH0 S'],
  "voluntarily": ['V AA2 L AH0 N T EH1 R AH0 L IY0'],
  "voluntarism": ['V OW0 L AH1 N T ER0 IH2 Z AH0 M'],
  "voluntary": ['V AA1 L AH0 N T EH0 R IY0'],
  "volunteer": ['V AA2 L AH0 N T IH1 R'],
  "volunteered": ['V AA2 L AH0 N T IH1 R D'],
  "volunteering": ['V AO2 L AH0 N T IH1 R IH0 NG'],
  "volunteerism": ['V AO2 L AH0 N T IH1 R IH2 Z AH0 M'],
  "volunteers": ['V AA2 L AH0 N T IH1 R Z'],
  "voluptuous": ['V AH0 L AH1 P CH AH0 W AH0 S'],
  "volvo": ['V OW1 L V OW0'],
  "volvo's": ['V OW1 L V OW0 Z'],
  "volvos": ['V AO1 L V OW0 Z'],
  "volvovitz": ['V OW1 L V AH0 V IH0 T S'],
  "volvox": ['V AA1 L V AA0 K S'],
  "volz": ['V OW1 L Z'],
  "vomit": ['V AA1 M AH0 T'],
  "vomiting": ['V AA1 M AH0 T IH0 NG'],
  "von": ['V AO1 N'],
  "von-braun": ['V AA1 N B R AO1 N'],
  "vona": ['V OW1 N AH0'],
  "vonada": ['V OW0 N AA1 D AH0'],
  "vonallmen": ['V AA1 N AH0 L M EH0 N'],
  "vonarx": ['V AH0 N AA1 R K S'],
  "vonbargen": ['V AA2 N B AA1 R G AH0 N'],
  "vonbehren": ['V AA2 N B IH1 R AH0 N'],
  "vonbergen": ['V AA2 N B ER1 G AH0 N'],
  "voncannon": ['V AA2 N K AE1 N AH0 N'],
  "vonder": ['V AA1 N D ER0'],
  "vonderhaar": ['V AA1 N D ER0 HH AA2 R'],
  "vonderheide": ['V AA1 N D ER0 HH AY2 D'],
  "vondra": ['V AA1 N D R AH0'],
  "vondracek": ['V AA1 N D R AH0 S IH0 K'],
  "vondrak": ['V AA1 N D R AH0 K'],
  "vondrasek": ['V AH0 N D R AA1 S EH0 K'],
  "vonfeldt": ['V AA1 N F IH0 L T'],
  "vong": ['V AO1 NG'],
  "vongunten": ['V AA1 NG G AH0 N T AH0 N'],
  "vonk": ['V AA1 NG K'],
  "vonnegut": ['V AA1 N AH0 G AH0 T'],
  "vonnie": ['V AA1 N IY0'],
  "vonny": ['V AA1 N IY0'],
  "vonruden": ['V AA1 N R UW0 D AH0 N'],
  "vons": ['V AA1 N Z'],
  "vons's": ['V AA1 N Z IH0 Z'],
  "vonseggern": ['V AA1 N S IH0 G ER0 N'],
  "vonstein": ['V AA1 N S T AY0 N', 'V AA1 N S T IY0 N'],
  "vontobel": ['V AA2 N T OW1 B AH0 L'],
  "voodoo": ['V UW1 D UW2'],
  "voorhees": ['V UH1 R HH IY0 Z'],
  "voorheis": ['V UH1 R HH AY0 Z'],
  "voorhies": ['V UH1 R HH IY0 Z'],
  "voorhis": ['V UH1 R HH IH0 S'],
  "vora": ['V AO1 R AH0'],
  "voracious": ['V AO0 R EY1 SH AH0 S'],
  "voracity": ['V ER0 AE1 S AH0 T IY0', 'V AO0 R AE1 S AH0 T IY0'],
  "vorce": ['V AO1 R S'],
  "vore": ['V AO1 R'],
  "vorhauer": ['V AO1 R HH AW2 R'],
  "vorhees": ['V AO1 R HH IY0 Z'],
  "vorhies": ['V AO1 R HH IY0 Z'],
  "voris": ['V AO1 R IH0 S'],
  "vornado": ['V AO2 R N AA1 D OW0', 'V AO2 R N EY1 D OW0'],
  "vorndran": ['V AO1 R N D R AH0 N'],
  "vorontsov": ['V AO0 R AA1 N T S AA2 V'],
  "voros": ['V AO1 R OW0 Z'],
  "vorpahl": ['V AO1 R P AA0 L'],
  "vortec": ['V AO1 R T EH2 K'],
  "vortex": ['V AO1 R T EH0 K S'],
  "vorwald": ['V AO1 R W AO0 L D'],
  "vorwerk": ['V AO1 R W ER0 K'],
  "vos": ['V AA1 S'],
  "vosberg": ['V AA1 S B ER0 G'],
  "vosburg": ['V AA1 S B ER0 G'],
  "vosburgh": ['V AA1 S B ER0 G'],
  "vose": ['V OW1 Z'],
  "voshell": ['V AA1 SH AH0 L'],
  "vosler": ['V AA1 S AH0 L ER0', 'V AA1 S L ER0'],
  "voss": ['V AO1 S'],
  "vossen": ['V AO1 S AH0 N'],
  "vossler": ['V AA1 S AH0 L ER0', 'V AA1 S L ER0'],
  "votava": ['V OW0 T AA1 V AH0'],
  "votaw": ['V OW1 T AO0'],
  "vote": ['V OW1 T'],
  "vote's": ['V OW1 T S'],
  "voted": ['V OW1 T IH0 D'],
  "voter": ['V OW1 T ER0'],
  "voter's": ['V OW1 T ER0 Z'],
  "voters": ['V OW1 T ER0 Z'],
  "voters'": ['V OW1 T ER0 Z'],
  "votes": ['V OW1 T S'],
  "voth": ['V AA1 TH'],
  "voting": ['V OW1 T IH0 NG'],
  "votrax": ['V AA1 T R AE0 K S'],
  "votruba": ['V AH0 T R UW1 B AH0'],
  "vouch": ['V AW1 CH'],
  "vouched": ['V AW1 CH T'],
  "voucher": ['V AW1 CH ER0'],
  "vouchers": ['V AW1 CH ER0 Z'],
  "vouching": ['V AW1 CH IH0 NG'],
  "vought": ['V AO1 T'],
  "vous": ['V UW1'],
  "voute": ['V UW1 T'],
  "vow": ['V AW1'],
  "vowed": ['V AW1 D'],
  "vowel": ['V AW1 AH0 L'],
  "vowell": ['V AA1 W EH0 L'],
  "vowels": ['V AW1 AH0 L Z', 'V AW1 L Z'],
  "vowing": ['V AW1 IH0 NG'],
  "vowles": ['V AW1 AH0 L Z'],
  "vows": ['V AW1 Z'],
  "vox": ['V AA1 K S'],
  "voxforge": ['V AA1 K S F AO2 R JH'],
  "voyage": ['V OY1 AH0 JH', 'V OY1 IH0 JH'],
  "voyaged": ['V OY1 AH0 JH D', 'V OY1 IH0 JH D'],
  "voyager": ['V OY1 AH0 JH ER0', 'V OY1 IH0 JH ER0'],
  "voyager's": ['V OY1 IH0 JH ER0 Z'],
  "voyagers": ['V OY1 IH0 JH ER0 Z'],
  "voyages": ['V OY1 AH0 JH AH0 Z', 'V OY1 IH0 JH IH0 Z'],
  "voyer": ['V OY1 ER0'],
  "voyeur": ['V OY2 Y UW1 R'],
  "voyeurism": ['V OY2 Y UW1 R IH0 Z AH0 M'],
  "voyeuristic": ['V OY2 Y AH0 R IH1 S T IH0 K'],
  "voyles": ['V OY1 L Z'],
  "voynavich": ['V OY1 N AH0 V IH0 CH'],
  "voytek": ['V OY1 T IH0 K'],
  "voytko": ['V OY1 T K OW0'],
  "vp": ['V IY2 P IY1'],
  "vrabel": ['V R AE1 B AH0 L'],
  "vradenburg": ['V R EY1 D AH0 N B ER0 G'],
  "vrain": ['V R EY1 N'],
  "vrana": ['V R AE1 N AH0'],
  "vranesevic": ['V R AA2 N AH0 S EH1 V IH0 CH'],
  "vranitzky": ['V R AH0 N IH1 T S K IY2'],
  "vranos": ['V R AA1 N OW0 S'],
  "vrba": ['V ER1 B AA1'],
  "vrdolyak": ['V ER0 D OW1 L IY0 AE0 K'],
  "vredenburg": ['V R IY1 D AH0 N B ER0 G'],
  "vreeland": ['V R IY1 L AH0 N D'],
  "vremya": ['V R EH1 M IY0 AH0'],
  "vries": ['V R IY1 Z'],
  "vroman": ['V R OW1 M AH0 N'],
  "vroom": ['V R UW1 M'],
  "vrooman": ['V R UW1 M AH0 N'],
  "vs": ['V IY1 EH1 S'],
  "vs.": ['V ER1 S AH0 Z'],
  "vsel": ['V IY1 S EH2 L'],
  "vu": ['V UW1'],
  "vue": ['V Y UW1'],
  "vuitton": ['V UH2 T AO1 N'],
  "vukelich": ['V AH0 K EH1 L IH0 HH'],
  "vukovar": ['V UW1 K AH0 V AA2 R'],
  "vukovich": ['V UW1 K AH0 V IH0 CH'],
  "vulcan": ['V AH1 L K AH0 N'],
  "vulcan's": ['V AH1 L K AH0 N Z'],
  "vulcans": ['V AH1 L K AH0 N Z'],
  "vulgamore": ['V AH1 L G AH0 M AO0 R'],
  "vulgar": ['V AH1 L G ER0'],
  "vulgarity": ['V AH0 L G EH1 R IH0 T IY0'],
  "vulgarization": ['V AH2 L G ER0 IH0 Z EY1 SH AH0 N'],
  "vullo": ['V UW1 L OW0'],
  "vulnerabilities": ['V AH2 L N ER0 AH0 B IH1 L IH0 T IY0 Z'],
  "vulnerability": ['V AH2 L N ER0 AH0 B IH1 L IH0 T IY0'],
  "vulnerable": ['V AH1 L N ER0 AH0 B AH0 L'],
  "vultaggio": ['V UW0 L T AA1 JH IY0 OW0'],
  "vulture": ['V AH1 L CH ER0'],
  "vultures": ['V AH1 L CH ER0 Z'],
  "vulva": ['V UH1 L V AH0'],
  "vuncannon": ['V AH1 N K AH0 N AA0 N', 'V AH0 N K AE1 N AH0 N'],
  "vuolo": ['V UW0 OW1 L OW0'],
  "vuong": ['V UW0 AO1 NG'],
  "vy": ['V AY1'],
  "vyacheslav": ['V Y AA1 CH AH0 S L AA0 V'],
  "vyas": ['V Y AA1 S', 'V AY1 AH0 S'],
  "vying": ['V AY1 IH0 NG'],
  "w": ['D AH1 B AH0 L Y UW0'],
  "w's": ['D AH1 B AH0 L Y UW0 Z'],
  "w.": ['D AH1 B AH0 L Y UW0'],
  "w.'s": ['D AH1 B AH0 L Y UW0 Z'],
  "w.s": ['D AH1 B AH0 L Y UW0 Z'],
  "wa": ['W AA1'],
  "waack": ['W AA1 K'],
  "waag": ['W AA1 G'],
  "waage": ['W AA1 IH0 JH'],
  "waah": ['W AA1'],
  "waal": ['W AA1 L'],
  "waara": ['W AA1 R AH0'],
  "waas": ['W AA1 Z'],
  "wabash": ['W AO1 B AE0 SH'],
  "wabash's": ['W AO1 B AE0 SH AH0 Z'],
  "wace": ['W EY1 S'],
  "wach": ['W AO1 CH'],
  "wacha": ['W AA1 CH AH0'],
  "wachholz": ['W AO1 K HH OW0 L Z'],
  "wachner": ['W AE1 K N ER0'],
  "wacho": ['W AA1 CH OW0'],
  "wachob": ['W AO1 K AH0 B'],
  "wachovia": ['W AA0 CH OW1 V IY0 AH0'],
  "wachowiak": ['V AH0 HH AW1 IY0 AE0 K'],
  "wachowski": ['V AH0 HH AO1 F S K IY0'],
  "wachs": ['W AO1 K S'],
  "wachsman": ['W AO1 K S M AH0 N'],
  "wachsmuth": ['W AO1 K S M UH0 TH'],
  "wachtel": ['W AO1 CH AH0 L'],
  "wachtell": ['W AA0 K T EH1 L'],
  "wachter": ['W AO1 K T ER0'],
  "wachtler": ['W AE1 K T L ER0'],
  "wack": ['W AE1 K'],
  "wackenhut": ['W AA1 K AH0 N HH AH2 T'],
  "wacker": ['W AE1 K ER0'],
  "wackerle": ['W AE1 K ER0 AH0 L', 'W AE1 K ER0 L IY0'],
  "wacko": ['W AE1 K OW0'],
  "wackos": ['W AE1 K OW0 Z'],
  "wacks": ['W AE1 K S'],
  "wacksman": ['W AE1 K S M AH0 N'],
  "wacksman's": ['W AE1 K S M AH0 N Z'],
  "wacky": ['W AE1 K IY0'],
  "waco": ['W EY1 K OW0'],
  "wactlar": ['W AA1 K T L ER0'],
  "wad": ['W AA1 D'],
  "wada": ['W AA1 D AH0'],
  "wadas": ['W AA1 D AH0 Z'],
  "wadded": ['W AA1 D IH0 D'],
  "waddell": ['W AA0 D EH1 L'],
  "waddie": ['W AA1 D IY0'],
  "waddill": ['W AO1 D IH1 L'],
  "waddington": ['W AA1 D IH0 NG T AH0 N'],
  "waddle": ['W AA1 D AH0 L'],
  "waddy": ['W AA1 D IY0'],
  "wade": ['W EY1 D'],
  "wade's": ['W EY1 D Z'],
  "waded": ['W EY1 D IH0 D'],
  "wadel": ['W EY1 D AH0 L'],
  "wader": ['W EY1 D ER0'],
  "waders": ['W EY1 D ER0 Z'],
  "wades": ['W EY1 D Z'],
  "wadford": ['W AO1 D F ER0 D'],
  "wadhams": ['W AO1 D AH0 M Z'],
  "wadi": ['W AA1 D IY2'],
  "wading": ['W EY1 D IH0 NG'],
  "wadkins": ['W AO1 D K IH0 N Z'],
  "wadle": ['W AO1 D AH0 L'],
  "wadleigh": ['W AO1 D L IY0'],
  "wadley": ['W AA1 D L IY0'],
  "wadlington": ['W AA1 D L IH0 NG T AH0 N'],
  "wadlow": ['W AA1 D L OW2'],
  "wadman": ['W AO1 D M AH0 N'],
  "wads": ['W AA1 D Z'],
  "wadsworth": ['W AA1 D Z W ER0 TH'],
  "wadsworth's": ['W AA1 D Z W ER0 TH S'],
  "waechter": ['W EH1 K T ER0'],
  "waeltermann": ['W AA1 L T ER0 M AH0 N', 'V AE1 L T ER0 M AH0 N'],
  "waertsilae": ['W EH1 R T S AH0 L EY2'],
  "wafer": ['W EY1 F ER0'],
  "wafers": ['W EY1 F ER0 Z'],
  "waffenschmidt": ['W AA1 F AH0 N SH M IH2 T'],
  "waffle": ['W AA1 F AH0 L'],
  "waffled": ['W AA1 F AH0 L D'],
  "waffles": ['W AA1 F AH0 L Z'],
  "waffling": ['W AA1 F L IH0 NG'],
  "wafford": ['W AA1 F ER0 D'],
  "waft": ['W AA1 F T'],
  "wafted": ['W AA1 F T IH0 D'],
  "wafting": ['W AA1 F T IH0 NG'],
  "wag": ['W AE1 G'],
  "wag's": ['W AE1 G Z'],
  "waga": ['W AA1 G AH0', 'D AH1 B AH0 L Y UW2 EY1 JH IY1 EY1', 'D AH1 B AH0 Y UW2 EY1 JH IY1 EY1'],
  "wagaman": ['W AE1 G AH1 M AH0 N'],
  "wagar": ['W AE1 G ER0'],
  "wage": ['W EY1 JH'],
  "waged": ['W EY1 JH D'],
  "wageman": ['W EY1 JH M AH0 N'],
  "wagenaar": ['W AE1 G AH0 N AA0 R'],
  "wagener": ['W AE1 G AH0 N ER0'],
  "wagenknecht": ['W AE1 G AH0 N IH0 K T'],
  "wager": ['W EY1 JH ER0'],
  "wagered": ['W EY1 JH ER0 D'],
  "wagering": ['W EY1 JH ER0 IH0 NG'],
  "wagers": ['W EY1 JH ER0 Z'],
  "wages": ['W EY1 JH AH0 Z', 'W EY1 JH IH0 Z'],
  "wagg": ['W AE1 G'],
  "wagged": ['W AE1 G D'],
  "waggener": ['W AE1 G AH0 N ER0'],
  "wagging": ['W AE1 G IH0 NG'],
  "waggling": ['W AE1 G AH0 L IH0 NG', 'W AE1 G L IH0 NG'],
  "waggoner": ['W AE1 G AH0 N ER0'],
  "waggy": ['W AE1 G IY0'],
  "waging": ['W EY1 JH IH0 NG'],
  "wagle": ['W AE1 G AH0 L'],
  "wagler": ['W AE1 G L ER0'],
  "wagley": ['W AE1 G L IY0'],
  "wagman": ['W AE1 G M AH0 N'],
  "wagner": ['W AE1 G N ER0', 'V AA1 G N ER0'],
  "wagner's": ['W AE1 G N ER0 Z', 'V AE1 G N ER0 Z'],
  "wagnerian": ['W AE2 G N EH1 R IY0 AH0 N', 'V AA2 G N EH1 R IY0 AH0 N'],
  "wagnon": ['W AE1 G N AH0 N'],
  "wagon": ['W AE1 G AH0 N'],
  "wagoneer": ['W AE2 G AH0 N IH1 R'],
  "wagoneers": ['W AE2 G AH0 N IH1 R Z'],
  "wagoner": ['W AE1 G AH0 N ER0'],
  "wagons": ['W AE1 G AH0 N Z'],
  "wags": ['W AE1 G Z'],
  "wagstaff": ['W AE1 G S T AE2 F'],
  "wagster": ['W AE1 G S T ER0'],
  "waguespack": ['W AE1 G IH0 S P AE2 K'],
  "wagy": ['W AE1 G IY0'],
  "wah": ['W AA1'],
  "wah's": ['W AA1 Z'],
  "wah-pei": ['W AA1 P EY1'],
  "wahid": ['W AA2 HH IY1 D'],
  "wahine": ['W AH0 HH IY1 N IY0'],
  "wahines": ['W AH0 HH IY1 N IY0 Z'],
  "wahl": ['W AA1 L'],
  "wahlberg": ['W AA1 L B ER0 G'],
  "wahle": ['W AO1 L'],
  "wahlen": ['W AA1 L AH0 N'],
  "wahler": ['W AA1 L ER0'],
  "wahlers": ['W AA1 L ER0 Z'],
  "wahlert": ['W AA1 L ER0 T'],
  "wahlgren": ['W AA1 L G R AH0 N'],
  "wahlquist": ['W AA1 L K W IH2 S T'],
  "wahlstrom": ['W AA1 L S T R AH0 M'],
  "wahoo": ['W AH0 HH UW1', 'W AA1 HH UW1'],
  "wai": ['W AY1'],
  "waibel": ['W EY1 B AH0 L', 'W AY1 B AH0 L'],
  "waid": ['W EY1 D'],
  "waide": ['W EY1 D'],
  "waidelich": ['W AY1 D L IH0 K'],
  "waif": ['W EY1 F'],
  "waifer": ['W EY1 F ER0'],
  "waigel": ['W AY1 G AH0 L'],
  "waikiki": ['W AY2 K IY0 K IY1'],
  "wail": ['W EY1 L'],
  "wailed": ['W EY1 L D'],
  "wailes": ['W EY1 L Z'],
  "wailing": ['W EY1 L IH0 NG'],
  "wails": ['W EY1 L Z'],
  "wain": ['W EY1 N'],
  "wainer": ['W EY1 N ER0'],
  "wainio": ['W EY1 N IY0 OW0'],
  "wainman": ['W EY1 N M AH0 N'],
  "wainoco": ['W EY2 N OW1 K OW0'],
  "wainoco's": ['W EY2 N OW1 K OW0 Z'],
  "wainright": ['W EY1 N R AY2 T'],
  "wainscot": ['W EY1 N S K AH0 T'],
  "wainscott": ['W EY1 N S K AH0 T'],
  "wainscotting": ['W EY1 N S K AO0 T IH0 NG'],
  "wainwright": ['W EY1 N R AY2 T'],
  "wais": ['W EY1 Z'],
  "waisanen": ['W AY1 S AH0 N AH0 N'],
  "waisner": ['W EY1 Z N ER0'],
  "waist": ['W EY1 S T'],
  "waistline": ['W EY1 S T L AY2 N'],
  "waists": ['W EY1 S T S'],
  "wait": ['W EY1 T'],
  "waite": ['W EY1 T'],
  "waite's": ['W EY1 T S'],
  "waited": ['W EY1 T IH0 D'],
  "waiter": ['W EY1 T ER0'],
  "waiter's": ['W EY1 T ER0 Z'],
  "waiters": ['W EY1 T ER0 Z'],
  "waites": ['W EY1 T S'],
  "waitin'": ['W EY1 T IH0 N'],
  "waiting": ['W EY1 T IH0 NG'],
  "waitkus": ['W EY1 T K AH0 S'],
  "waitman": ['W AY1 T M AH0 N'],
  "waitress": ['W EY1 T R AH0 S'],
  "waitresses": ['W EY1 T R AH0 S IH0 Z'],
  "waits": ['W EY1 T S'],
  "waitt": ['W EY1 T'],
  "waitzkin": ['W EY1 T S K IH2 N'],
  "waive": ['W EY1 V'],
  "waived": ['W EY1 V D'],
  "waiver": ['W EY1 V ER0'],
  "waivers": ['W EY1 V ER0 Z'],
  "waives": ['W EY1 V Z'],
  "waiving": ['W EY1 V IH0 NG'],
  "wajda": ['V AY1 D AH0'],
  "wakabayashi": ['W AA0 K AA2 B AA0 Y AA1 SH IY0'],
  "wake": ['W EY1 K'],
  "wake-up": ['W EY1 K AH2 P'],
  "wakefield": ['W EY1 K F IY2 L D'],
  "wakeham": ['W AE1 K AH0 M'],
  "wakeland": ['W EY1 K L AH0 N D'],
  "wakeley": ['W AE1 K L IY0'],
  "wakely": ['W EY1 K L IY0'],
  "wakeman": ['W EY1 K M AH0 N'],
  "waken": ['W EY1 K AH0 N'],
  "wakes": ['W EY1 K S'],
  "wakeup": ['W EY1 K AH2 P'],
  "wakid": ['W EY1 K AH0 D'],
  "waking": ['W EY1 K IH0 NG'],
  "wakley": ['W AE1 K L IY0'],
  "wako": ['W AE1 K OW0'],
  "wal": ['W AO1 L'],
  "wal-mart": ['W AO1 L M AA2 R T'],
  "wala": ['W AO1 L AH0'],
  "wala's": ['W AO1 L AH0 Z'],
  "walas": ['W AO1 L AH0 Z'],
  "walberg": ['W AO1 L B ER0 G'],
  "walbert": ['W AO1 L B ER0 T'],
  "walborn": ['W AO1 L B ER0 N'],
  "walbridge": ['W AO1 L B R IH0 JH'],
  "walbro": ['W AO1 L B R OW0'],
  "walburn": ['W AO1 L B ER0 N'],
  "walby": ['W AO1 L B IY0'],
  "walch": ['W AO1 L CH'],
  "walcher": ['W AO1 L CH ER0'],
  "walck": ['W AO1 L K'],
  "walcot": ['W AO1 L K AA0 T'],
  "walcott": ['W AO1 L K AA0 T'],
  "walczak": ['V AA1 L CH AE0 K'],
  "walczyk": ['V AA1 L CH IH0 K'],
  "wald": ['W AO1 L D'],
  "walda": ['V AA1 L D AH0'],
  "waldbaum": ['W AO1 L D B AW2 M'],
  "walde": ['W AO1 L D'],
  "waldeck": ['W AO1 L D EH0 K'],
  "waldecker": ['W AO1 L D EH2 K ER0'],
  "waldegard": ['W AO1 L D AH0 G AA2 R D'],
  "waldemar": ['V AA1 L D AH0 M AA0 R'],
  "walden": ['W AO1 L D AH0 N'],
  "waldenbooks": ['W AO1 L D AH0 N B UH2 K S'],
  "waldenbooks'": ['W AO1 L D AH0 N B UH2 K S'],
  "walder": ['W AO1 L D ER0'],
  "waldheim": ['W AO1 L D HH AY2 M', 'V AO1 L D HH AY2 M'],
  "waldheim's": ['W AO1 L D HH AY2 M Z', 'V AO1 L D HH AY2 M Z'],
  "waldholtz": ['W AO1 L D HH OW2 L T S'],
  "waldholtz's": ['W AO1 L D HH OW2 L T S IH0 Z'],
  "waldholz": ['W AO1 L D HH OW2 L T S'],
  "waldhorn": ['W AO1 L D HH AO2 R N'],
  "waldie": ['W AO1 L D IY0'],
  "walding": ['W AO1 L D IH0 NG'],
  "waldinger": ['W AO1 L D IH0 NG ER0'],
  "waldman": ['W AA1 L D M AH0 N'],
  "waldmann": ['W AO1 L D M AH0 N'],
  "waldner": ['W AO1 L D N ER0'],
  "waldo": ['W AA1 L D OW0', 'W AO1 L D OW0'],
  "waldoch": ['W AO1 L D AA0 K'],
  "waldock": ['W AO1 L D AA0 K'],
  "waldon": ['W AO1 L D AH0 N'],
  "waldorf": ['W AO1 L D AO0 R F'],
  "waldow": ['W AO1 L D OW0'],
  "waldren": ['W AO1 L D R AH0 N'],
  "waldrep": ['W AO1 L D R AH0 P'],
  "waldridge": ['W AO1 L D R IH0 JH'],
  "waldrip": ['W AO1 L D R AH0 P'],
  "waldron": ['W AO1 L D R AH0 N'],
  "waldroop": ['W AO1 L D R UW2 P'],
  "waldrop": ['W AO1 L D R AA0 P'],
  "waldroup": ['W AO1 L D R UW2 P'],
  "waldrum": ['W AO1 L D R AH0 M'],
  "waldrup": ['W AO1 L D R AH0 P'],
  "waldschmidt": ['W AO1 L D SH M IH2 T'],
  "waldvogel": ['W AO1 L D V OW2 G AH0 L'],
  "wale": ['W EY1 L'],
  "waleed": ['W AA2 L IY1 D'],
  "walek": ['V AA1 L EH0 K'],
  "walen": ['W EY1 L AH0 N'],
  "walenta": ['W AH0 L EH1 N T AH0', 'V AH0 L EH1 N T AH0'],
  "wales": ['W EY1 L Z'],
  "wales'": ['W EY1 L Z'],
  "walesa": ['W AH0 L EH1 S AH0', 'V AH0 L EH1 S AH0'],
  "walesa's": ['W AH0 L EH1 S AH0 Z', 'V AH0 L EH1 S AH0 Z'],
  "waleson": ['W EY1 L S AH0 N'],
  "walford": ['W AO1 L F ER0 D'],
  "walfred": ['W AO1 L F R EH0 D'],
  "walgreen": ['W AO1 L G R IY2 N'],
  "walgren": ['W AO1 L G R AH0 N'],
  "walicki": ['W AH0 L IH1 K IY0'],
  "walid": ['W AA0 L IY1 D'],
  "waligora": ['W AO0 L IH0 G AO1 R AH0'],
  "walinsky": ['W AH0 L IH1 N S K IY0'],
  "walizer": ['W AO1 L AY0 Z ER0'],
  "walk": ['W AO1 K', 'W AA1 K'],
  "walk-in": ['W AO1 K IH2 N'],
  "walk-on": ['W AO1 K AA2 N'],
  "walk-ons": ['W AO1 K AA2 N Z'],
  "walke": ['W AO1 K'],
  "walked": ['W AO1 K T'],
  "walken": ['W AO1 K AH0 N'],
  "walkenhorst": ['W AO1 K AH0 N HH AO2 R S T'],
  "walker": ['W AO1 K ER0'],
  "walker's": ['W AO1 K ER0 Z'],
  "walkers": ['W AO1 K ER0 Z'],
  "walkie": ['W AO1 K IY0'],
  "walkin'": ['W AO1 K IH0 N'],
  "walking": ['W AO1 K IH0 NG'],
  "walkington": ['W AO1 K IH0 NG T AH0 N'],
  "walkinshaw": ['W AO1 K AH0 N SH AO0'],
  "walkley": ['W AO1 K L IY0'],
  "walkman": ['W AO1 K M AE2 N', 'W AO1 K M AH0 N'],
  "walkner": ['W AO1 K N ER0'],
  "walko": ['W AO1 K OW0'],
  "walkout": ['W AO1 K AW2 T'],
  "walkouts": ['W AO1 K AW2 T S'],
  "walkowiak": ['W AO0 K AW1 IY0 AE0 K'],
  "walkowski": ['W AO0 K AO1 F S K IY0'],
  "walks": ['W AO1 K S'],
  "walkthrough": ['W AO1 K TH R AW0'],
  "walkup": ['W AO1 K AH2 P'],
  "walkure": ['W AO1 K Y ER0'],
  "walkway": ['W AO1 K W EY2'],
  "walkways": ['W AO1 K W EY2 Z'],
  "wall": ['W AO1 L'],
  "wall's": ['W AO1 L Z'],
  "wall-e": ['W AO1 L IY2'],
  "wall-tex": ['W AO1 L T EH2 K S'],
  "walla": ['W AO1 L AA0'],
  "wallabies": ['W AA1 L AH0 B IY2 Z'],
  "wallaby": ['W AA1 L AH0 B IY2'],
  "wallace": ['W AO1 L AH0 S', 'W AO1 L IH0 S'],
  "wallace's": ['W AO1 L AH0 S AH0 Z'],
  "wallach": ['W AO1 L AH0 K'],
  "wallack": ['W AO1 L AH0 K'],
  "wallander": ['W AO1 L AH0 N D ER0'],
  "wallar": ['W AO1 L ER0'],
  "wallboard": ['W AO1 L B AO2 R D'],
  "walle": ['W AO1 L'],
  "walled": ['W AO1 L D'],
  "wallen": ['W AO1 L AH0 N'],
  "wallenberg": ['W AO1 L AH0 N B ER0 G'],
  "wallenberg's": ['W AO1 L AH0 N B ER0 G Z'],
  "wallenstein": ['W AO1 L AH0 N S T AY2 N', 'W AO1 L AH0 N S T IY2 N'],
  "waller": ['W AO1 L ER0'],
  "wallerstein": ['W AO1 L ER0 S T AY2 N', 'W AO1 L ER0 S T IY2 N'],
  "walles": ['W AO1 L Z'],
  "wallet": ['W AO1 L AH0 T'],
  "wallets": ['W AO1 L AH0 T S'],
  "walley": ['W AO1 L IY0'],
  "walleye": ['W AO1 L AY2'],
  "wallflower": ['W AO1 L F L AW2 ER0'],
  "wallgren": ['W AO1 L G R AH0 N'],
  "wallich": ['W AO1 L IH0 K'],
  "wallich's": ['W AO1 L IH0 K S'],
  "wallick": ['W AO1 L IH0 K'],
  "wallie": ['W AO1 L IY0'],
  "wallin": ['W AO1 L IH0 N'],
  "walling": ['W AO1 L IH0 NG'],
  "wallingford": ['W AO1 L IH0 NG F ER0 D'],
  "wallington": ['W AO1 L IH0 NG T AH0 N'],
  "wallis": ['W AO1 L IH0 S'],
  "wallison": ['W AO1 L IH0 S AH0 N'],
  "wallman": ['W AO1 L M AH0 N'],
  "wallner": ['W AO1 L N ER0'],
  "wallo": ['W AA1 L OW0'],
  "walloch": ['W AO1 L AH0 K'],
  "wallop": ['W AA1 L AH0 P'],
  "walloping": ['W AO1 L AH0 P IH0 NG'],
  "wallops": ['W AA1 L AH0 P S'],
  "wallow": ['W AA1 L OW0'],
  "wallowed": ['W AA1 L OW0 D'],
  "wallowing": ['W AA1 L OW0 IH0 NG'],
  "wallpaper": ['W AO1 L P EY2 P ER0'],
  "wallpapered": ['W AO1 L P EY2 P ER0 D'],
  "wallpapering": ['W AO1 L P EY2 P ER0 IH0 NG'],
  "wallpapers": ['W AO1 L P EY2 P ER0 Z'],
  "walls": ['W AO1 L Z'],
  "wallsend": ['W AO1 L S EH2 N D'],
  "wallstreet": ['W AO1 L S T R IY2 T'],
  "wallwork": ['W AO1 L W ER2 K'],
  "wally": ['W AO1 L IY0', 'W EY1 L IY0'],
  "wally's": ['W AO1 L IY0 Z'],
  "walmart": ['W AO1 L M AA2 R T'],
  "walmart's": ['W AO1 L M AA2 R T S'],
  "walmarts": ['W AO1 L M AA2 R T S'],
  "walmer": ['W AO1 L M ER0'],
  "walmond": ['W AO1 L M AH0 N D'],
  "walmsley": ['W AO1 L M S L IY0'],
  "waln": ['W AO1 L N'],
  "walnut": ['W AO1 L N AH2 T'],
  "walnuts": ['W AO1 L N AH2 T S'],
  "walp": ['W AE1 L P', 'W AO1 L P'],
  "walpole": ['W AO1 L P OW2 L'],
  "walrath": ['W AO1 L R AH0 TH'],
  "walraven": ['W AO1 L R EY2 V AH0 N'],
  "walrod": ['W AO1 L R AA0 D'],
  "walrus": ['W AO1 L R AH0 S'],
  "walruses": ['W AA1 L R AH0 S IH0 Z'],
  "walser": ['W AO1 L Z ER0'],
  "walsh": ['W AO1 L SH'],
  "walsh's": ['W AO1 L SH IH0 Z'],
  "walski": ['V AA1 L S K IY0'],
  "walstad": ['W AO1 L S T AE0 D'],
  "walston": ['W AO1 L S T AH0 N'],
  "walstrom": ['W AO1 L S T R AH0 M'],
  "walsworth": ['W AO1 L S W ER0 TH'],
  "walt": ['W AO1 L T'],
  "walter": ['W AO1 L T ER0'],
  "walter's": ['W AO1 L T ER0 Z'],
  "waltermire": ['W AO1 L T ER0 M AY0 R'],
  "walters": ['W AO1 L T ER0 Z'],
  "walters'": ['W AO1 L T ER0 Z'],
  "walth": ['W AO1 L TH'],
  "walthall": ['W AO1 L TH AH0 L'],
  "waltham": ['W AO1 L TH AH0 M'],
  "walther": ['W AO1 L TH ER0'],
  "walthers": ['W AO1 L TH ER0 Z'],
  "walthose": ['W AO1 L T HH OW2 Z'],
  "walthour": ['W AO1 L T AW0 R'],
  "waltman": ['W AO1 L T M AH0 N'],
  "waltner": ['W AO1 L T N ER0'],
  "walton": ['W AO1 L T AH0 N'],
  "walton's": ['W AO1 L T AH0 N Z'],
  "waltons": ['W AO1 L T AH0 N Z'],
  "waltrip": ['W AO1 L T R IH0 P'],
  "walts": ['W AO1 L T S'],
  "waltz": ['W AO1 L T S', 'W AO1 L S'],
  "waltzer": ['W AO1 L T S ER0', 'W AO1 L S ER0'],
  "waltzes": ['W AO1 L T S AH0 Z', 'W AO1 L S AH0 Z'],
  "waltzing": ['W AO1 L T S IH0 NG', 'W AO1 L S IH0 NG'],
  "walworth": ['W AO1 L W ER0 TH'],
  "walwyn": ['W AO1 L W IH0 N'],
  "walz": ['W AO1 L Z'],
  "walzer": ['W AO1 L Z ER0'],
  "wambach": ['W AO1 M B AA0 K'],
  "wambaugh": ['W AO1 M B AA0'],
  "wamble": ['W AA1 M B AH0 L'],
  "wambold": ['W AA1 M B OW2 L D'],
  "wamboldt": ['W AA1 M B OW0 L T'],
  "wambolt": ['W AA1 M B OW2 L T'],
  "wampler": ['W AA1 M P L ER0'],
  "wampole": ['W AA1 M P OW2 L'],
  "wampum": ['W AA1 M P AH0 M'],
  "wamser": ['W AA1 M Z ER0'],
  "wamsley": ['W AA1 M Z L IY0'],
  "wan": ['W AA1 N'],
  "wanamaker": ['W AA1 N AH0 M EY2 K ER0'],
  "wanat": ['W EY1 N AH0 T'],
  "wand": ['W AA1 N D'],
  "wanda": ['W AA1 N D AH0'],
  "wandel": ['W AA1 N D AH0 L'],
  "wandell": ['W AA1 N D AH0 L'],
  "wander": ['W AA1 N D ER0'],
  "wandered": ['W AA1 N D ER0 D'],
  "wanderer": ['W AA1 N D ER0 ER0'],
  "wanderers": ['W AA1 N D ER0 ER0 Z'],
  "wandering": ['W AA1 N D ER0 IH0 NG'],
  "wanderings": ['W AA1 N D ER0 IH0 NG Z'],
  "wanderlust": ['W AA1 N D ER0 L AH2 S T'],
  "wanders": ['W AA1 N D ER0 Z'],
  "wandersee": ['W AA1 N D ER0 S IY2'],
  "wandie": ['W AA1 N D IY0'],
  "wandis": ['W AA1 N D IH0 S'],
  "wandler": ['W AA1 N D L ER0'],
  "wandling": ['W AA1 N D L IH0 NG'],
  "wandrey": ['W AA1 N D R IY0'],
  "wands": ['W AA1 N D Z'],
  "wane": ['W EY1 N'],
  "waned": ['W EY1 N D'],
  "wanek": ['W EY1 N IH0 K'],
  "waner": ['W EY1 N ER0'],
  "wanes": ['W EY1 N Z'],
  "wang": ['W AE1 NG'],
  "wang's": ['W AE1 NG Z'],
  "wangen": ['W AE1 NG AH0 N'],
  "wanger": ['W AE1 NG ER0'],
  "wangerin": ['W AO1 NG G ER0 IH0 N'],
  "wangle": ['W AE1 NG G AH0 L'],
  "wangled": ['W AE1 NG G AH0 L D'],
  "wangler": ['W AE1 NG G L ER0'],
  "waning": ['W EY1 N IH0 NG'],
  "wank": ['W AA1 NG K'],
  "wanke": ['W AA1 NG K'],
  "wanker": ['W AA1 NG K ER0'],
  "wankers": ['W AA1 NG K ER0 Z'],
  "wanko": ['W AA1 NG K OW0'],
  "wanless": ['W AA1 N L AH0 S'],
  "wann": ['W AA1 N'],
  "wanna": ['W AA1 N AH0'],
  "wannabe": ['W AA1 N AH0 B IY2'],
  "wannabee": ['W AA1 N AH0 B IY2'],
  "wannabees": ['W AA1 N AH0 B IY2 Z'],
  "wannabes": ['W AA1 N AH0 B IY2 Z'],
  "wannamaker": ['W AA1 N AH0 M EY2 K ER0'],
  "wannemacher": ['W AA1 N AH0 M AA0 K ER0'],
  "wanner": ['W AA1 N ER0'],
  "wanninger": ['W AA1 N IH0 NG ER0'],
  "wanniski": ['W AH0 N IH1 S K IY0'],
  "wanser": ['W AO1 N Z ER0'],
  "wansley": ['W AO1 N Z L IY0'],
  "want": ['W AA1 N T', 'W AO1 N T'],
  "wanta": ['W AA1 N T AH0', 'W AA1 N AH0'],
  "wantage": ['W AA1 N T IH0 JH'],
  "wantages": ['W AA1 N T IH0 JH IH0 Z'],
  "wante": ['W AA1 N T AH0'],
  "wanted": ['W AO1 N T IH0 D'],
  "wanting": ['W AA1 N T IH0 NG', 'W AA1 N IH0 NG'],
  "wantland": ['W AA1 N T L AH0 N D'],
  "wanton": ['W AO1 N T AH0 N', 'W AA1 N T AH0 N'],
  "wantonly": ['W AO1 N T AH0 N L IY0', 'W AA1 N T AH0 N L IY0'],
  "wants": ['W AA1 N T S', 'W AO1 N T S'],
  "wantz": ['W AA1 N T S'],
  "wanzer": ['W AA1 N Z ER0'],
  "wapiti": ['W AH0 P IY1 T IY0'],
  "waples": ['W AO1 P AH0 L Z'],
  "wapner": ['W AA1 P N ER0'],
  "wapping": ['W AA1 P IH0 NG'],
  "war": ['W AO1 R'],
  "war's": ['W AO1 R Z'],
  "warbington": ['W AO1 R B IH0 NG T AH0 N'],
  "warble": ['W AO1 R B AH0 L'],
  "warbled": ['W AO1 R B AH0 L D'],
  "warbler": ['W AO1 R B L ER0'],
  "warblers": ['W AO1 R B L ER0 Z'],
  "warbles": ['W AO1 R B AH0 L Z'],
  "warbling": ['W AO1 R B AH0 L IH0 NG', 'W AO1 R B L IH0 NG'],
  "warbucks": ['W AO1 R B UH2 K S'],
  "warburg": ['W AO1 R B ER0 G'],
  "warburg's": ['W AO1 R B ER0 G Z'],
  "warburton": ['W AO1 R B ER0 T AH0 N'],
  "warchol": ['W AO1 R K AO0 L'],
  "warcraft": ['W AO1 R K R AE2 F T'],
  "ward": ['W AO1 R D'],
  "ward's": ['W AO1 R D Z'],
  "warda": ['W AO1 R D AA2'],
  "wardair": ['W AO1 R D EH2 R'],
  "warde": ['W AO1 R D'],
  "warded": ['W AO1 R D IH0 D'],
  "wardell": ['W AO1 R D EH0 L'],
  "warden": ['W AO1 R D AH0 N'],
  "warden's": ['W AO1 R D AH0 N Z'],
  "wardens": ['W AO1 R D AH0 N Z'],
  "wardens'": ['W AO1 R D AH0 N Z'],
  "warder": ['W AO1 R D ER0'],
  "warders": ['W AO1 R D ER0 Z'],
  "warding": ['W AO1 R D IH0 NG'],
  "wardlaw": ['W AO1 R D L AO2'],
  "wardle": ['W AO1 R D AH0 L'],
  "wardley": ['W AO1 R D L IY0'],
  "wardlow": ['W AO1 R D L OW2'],
  "wardrip": ['W AO1 R D R IH0 P'],
  "wardrobe": ['W AO1 R D R OW2 B'],
  "wardrobes": ['W AO1 R D R OW2 B Z'],
  "wardrop": ['W AO1 R D R AA2 P'],
  "wards": ['W AO1 R D Z'],
  "wardwell": ['W AO1 R D W EH2 L'],
  "ware": ['W EH1 R'],
  "warehime": ['W EH1 R HH AY2 M'],
  "warehouse": ['W EH1 R HH AW2 S'],
  "warehoused": ['W EH1 R HH AW2 Z D'],
  "warehouses": ['W EH1 R HH AW2 Z IH0 Z', 'W EH1 R HH AW2 S IH0 Z'],
  "warehousing": ['W EH1 R HH AW2 Z IH0 NG'],
  "wareing": ['W EH1 R IH0 NG'],
  "waren": ['W EH1 R AH0 N'],
  "wares": ['W EH1 R Z'],
  "warez": ['W EH1 R Z'],
  "warf": ['W AO1 R F'],
  "warfare": ['W AO1 R F EH2 R'],
  "warfel": ['W AO1 R F AH0 L'],
  "warfield": ['W AO1 R F IY0 L D'],
  "warford": ['W AO1 R F ER0 D'],
  "warga": ['W AO1 R G AH0'],
  "wargo": ['W AO1 R G OW2'],
  "warhead": ['W AO1 R HH EH2 D'],
  "warheads": ['W AO1 R HH EH2 D Z'],
  "warhol": ['W AO1 R HH AO0 L'],
  "warhol's": ['W AO1 HH R AO2 L Z'],
  "warhurst": ['W AO1 R HH ER0 S T'],
  "warily": ['W EH1 R AH0 L IY0'],
  "wariness": ['W EH1 R IY0 N IH0 S'],
  "waring": ['W EH1 R IH0 NG'],
  "wark": ['W AO1 R K'],
  "warkentin": ['W AO0 R K EH1 N T IH0 N', 'W AO1 R K AH0 N T IH0 N'],
  "warley": ['W AO1 R L IY0'],
  "warlick": ['W AO1 R L IH0 K'],
  "warlike": ['W AO1 R L AY2 K'],
  "warling": ['W AO1 R L IH0 NG'],
  "warlock": ['W AO1 R L AO2 K'],
  "warlock's": ['W AO1 R L AO2 K S'],
  "warlocks": ['W AO1 R L AO2 K S'],
  "warloeck": ['W AO1 R L AA2 K'],
  "warlord": ['W AO1 R L AO2 R D'],
  "warlords": ['W AO1 R L AO2 R D Z'],
  "warm": ['W AO1 R M'],
  "warmack": ['W AO1 R M AE0 K'],
  "warman": ['W AO1 R M AH0 N'],
  "warmed": ['W AO1 R M D'],
  "warmer": ['W AO1 R M ER0'],
  "warmest": ['W AO1 R M AH0 S T'],
  "warming": ['W AO1 R M IH0 NG'],
  "warmington": ['W AO1 R M IH0 NG T AH0 N'],
  "warminster": ['W AO1 R M IH2 N S T ER0'],
  "warmish": ['W AO1 R M IH0 SH'],
  "warmly": ['W AO1 R M L IY0'],
  "warmond": ['W AO1 R M AH0 N D'],
  "warmonger": ['W AO1 R M AA2 NG G ER0'],
  "warmongering": ['W AO1 R M AA2 NG G ER0 IH0 NG'],
  "warmoth": ['W AO1 R M AH0 TH'],
  "warms": ['W AO1 R M Z'],
  "warmth": ['W AO1 R M TH'],
  "warmup": ['W AO1 R M AH2 P'],
  "warmus": ['W AO1 R M AH0 S'],
  "warmuth": ['W AO1 R M UH2 TH'],
  "warn": ['W AO1 R N'],
  "warnaco": ['W AO1 R N AH0 K OW2'],
  "warncke": ['W AO1 R NG K IY0'],
  "warne": ['W AO1 R N'],
  "warnecke": ['W AO1 R N IH0 K'],
  "warned": ['W AO1 R N D'],
  "warneke": ['W AO1 R N IH0 K'],
  "warnell": ['W AO1 R N AH0 L'],
  "warner": ['W AO1 R N ER0'],
  "warner's": ['W AO1 R N ER0 Z'],
  "warners": ['W AO1 R N ER0 Z'],
  "warnes": ['W AO1 R N Z'],
  "warnick": ['W AO1 R N IH0 K'],
  "warnico": ['W AO1 R N IH0 K OW0'],
  "warning": ['W AO1 R N IH0 NG'],
  "warningly": ['W AO1 R N IH0 NG L IY0'],
  "warnings": ['W AO1 R N IH0 NG Z'],
  "warnke": ['W AO1 R NG K IY0'],
  "warnken": ['W AO1 R NG K AH0 N'],
  "warno": ['W AA1 R N OW0'],
  "warnock": ['W AO1 R N AA0 K'],
  "warns": ['W AO1 R N Z'],
  "waronker": ['W AO0 R AA1 NG K ER0'],
  "warp": ['W AO1 R P'],
  "warpath": ['W AO1 R P AE2 TH'],
  "warped": ['W AO1 R P T'],
  "warping": ['W AO1 R P IH0 NG'],
  "warplane": ['W AO1 R P L EY2 N'],
  "warplanes": ['W AO1 R P L EY2 N Z'],
  "warps": ['W AO1 R P S'],
  "warr": ['W AO1 R'],
  "warrant": ['W AO1 R AH0 N T'],
  "warranted": ['W AO1 R AH0 N T IH0 D'],
  "warranties": ['W AO1 R AH0 N T IY0 Z'],
  "warrantless": ['W AO1 R AH0 N T L AH0 S'],
  "warrants": ['W AO1 R AH0 N T S'],
  "warranty": ['W AO1 R AH0 N T IY0'],
  "warrell": ['W AO2 R EH1 L'],
  "warren": ['W AO1 R AH0 N'],
  "warren's": ['W AO1 R AH0 N Z'],
  "warrens": ['W AO1 R AH0 N Z'],
  "warrenton": ['W AA1 R AH0 N T AH0 N'],
  "warrick": ['W AO1 R IH0 K'],
  "warriner": ['W AO1 R AH0 N ER0'],
  "warring": ['W AO1 R IH0 NG'],
  "warrington": ['W AO1 R IH0 NG T AH0 N'],
  "warrior": ['W AO1 R IY0 ER0', 'W AO1 R Y ER0'],
  "warriors": ['W AO1 R IY0 ER0 Z', 'W AO1 R Y ER0 Z'],
  "wars": ['W AO1 R Z'],
  "wars'": ['W AO1 R Z'],
  "warsaw": ['W AO1 R S AO2'],
  "warsaw's": ['W AO1 R S AO2 Z'],
  "warsaws": ['W AO1 R S AO2 Z'],
  "warshauer": ['W AO1 R SH AW0 ER0'],
  "warshaw": ['W AO1 R SH AO2'],
  "warshawsky": ['W AO1 R SH AH0 W S K IY0'],
  "warship": ['W AO1 R SH IH2 P'],
  "warships": ['W AO1 R SH IH2 P S'],
  "warstler": ['W AO1 R S T L ER0'],
  "warszawa": ['W AA2 R SH AA1 W AA2'],
  "wart": ['W AO1 R T'],
  "wartenberg": ['W AO1 R T AH0 N B ER0 G'],
  "warth": ['W AO1 R TH'],
  "warthen": ['W AO1 R TH AH0 N'],
  "wartime": ['W AO1 R T AY2 M'],
  "wartimes": ['W AO1 R T AY2 M Z'],
  "wartman": ['W AO1 R T M AH0 N'],
  "warton": ['W AO1 R T AH0 N'],
  "warts": ['W AO1 R T S'],
  "wartzman": ['W AO1 R T S M AH0 N'],
  "warwick": ['W AO1 R W IH0 K'],
  "warwick's": ['W AO1 R W IH2 K S'],
  "wary": ['W EH1 R IY0'],
  "warzecha": ['W AO0 R Z EH1 HH AH0'],
  "was": ['W AA1 Z', 'W AH0 Z'],
  "wasatch": ['W AA1 S AE0 CH'],
  "wasch": ['W AO1 SH'],
  "wascher": ['W AO1 SH ER0'],
  "wasco": ['W AO1 S K OW0'],
  "wascom": ['W AO1 S K AA0 M'],
  "wasden": ['W AO1 S D AH0 N'],
  "waseda": ['W AH0 S IY1 D AH0'],
  "wasem": ['W AO1 S IH0 M'],
  "wash": ['W AA1 SH'],
  "washabaugh": ['W AO1 SH AH0 B AO0'],
  "washable": ['W AA1 SH AH0 B AH0 L'],
  "washam": ['W AA1 SH AH0 M'],
  "washbasin": ['W AA1 SH B EY2 S AH0 N'],
  "washboard": ['W AA1 SH B AO2 R D'],
  "washburn": ['W AA1 SH B ER2 N'],
  "washburne": ['W AO1 SH B ER2 N'],
  "washcloth": ['W AA1 SH K L AO2 TH'],
  "washed": ['W AA1 SH T'],
  "washer": ['W AA1 SH ER0'],
  "washers": ['W AA1 SH ER0 Z'],
  "washes": ['W AA1 SH IH0 Z'],
  "washi's": ['W AA1 SH IY0 Z'],
  "washing": ['W AA1 SH IH0 NG'],
  "washington": ['W AA1 SH IH0 NG T AH0 N', 'W AO1 SH IH0 NG T AH0 N'],
  "washington's": ['W AA1 SH IH0 NG T AH0 N Z', 'W AO1 SH IH0 NG T AH0 N Z'],
  "washingtonian": ['W AA2 SH IH0 NG T OW1 N IY0 AH0 N', 'W AO2 SH IH0 NG T OW1 N IY0 AH0 N'],
  "washingtonians": ['W AA2 SH IH0 NG T OW1 N IY0 AH0 N Z', 'W AO2 SH IH0 NG T OW1 N IY0 AH0 N Z'],
  "washko": ['V AA1 SH K OW0'],
  "washoe": ['W AA1 SH OW2'],
  "washout": ['W AA1 SH AW2 T'],
  "washouts": ['W AA1 SH AW2 T S'],
  "washroom": ['W AA1 SH R UW2 M'],
  "washtub": ['W AA1 SH T AH2 B'],
  "washy": ['W AA1 SH IY0'],
  "wasielewski": ['V AH0 S IY0 L EH1 F S K IY0'],
  "wasik": ['V AA1 S IH0 K'],
  "wasil": ['W AO1 S AH0 L'],
  "wasilewski": ['V AH0 S IH0 L EH1 F S K IY0'],
  "wasinger": ['W AO1 S IH0 N JH ER0'],
  "wasiyu": ['W AH0 S IY1 UW0'],
  "waskiewicz": ['V AA1 S K AH0 V IH0 CH'],
  "wasko": ['V AA1 S K OW0'],
  "waskow": ['V AA1 S K OW0'],
  "wasley": ['W AO1 S L IY0'],
  "wasmer": ['W AO1 S AH0 M ER0'],
  "wasmund": ['W AO1 Z M AH0 N D'],
  "wasmuth": ['W AO1 Z M UW0 TH'],
  "wasn't": ['W AA1 Z AH0 N T', 'W AH1 Z AH0 N T'],
  "wason": ['W AA1 S AH0 N'],
  "wasp": ['W AA1 S P'],
  "wasp's": ['W AA1 S P S'],
  "wasps": ['W AA1 S P S'],
  "wass": ['W AH1 S', 'W AA1 S'],
  "wassel": ['W AO1 S AH0 L'],
  "wassell": ['W AO1 S AH0 L'],
  "wassenaar": ['W AO1 S IH0 N AA0 R'],
  "wasser": ['W AO1 S ER0'],
  "wasserman": ['W AA1 S ER0 M AH0 N'],
  "wasserman's": ['W AA1 S ER0 M AH0 N Z'],
  "wasserstein": ['W AA1 S ER0 S T IY2 N', 'W AA1 S ER0 S T AY2 N'],
  "wassilievitch": ['W AA0 S IH1 L Y AH0 V IH2 CH'],
  "wassily": ['V AH0 S IH1 L IY2'],
  "wassink": ['W AO1 S IH0 NG K'],
  "wassman": ['W AO1 S M AH0 N'],
  "wassmer": ['W AA1 S M ER0'],
  "wassom": ['W AA1 S AH0 M'],
  "wasson": ['W AO1 S AH0 N'],
  "wassum": ['W AA1 S AH0 M'],
  "waste": ['W EY1 S T'],
  "waste's": ['W EY1 S T S'],
  "wastebasket": ['W EY1 S T B AE2 S K AH0 T'],
  "wastebaskets": ['W EY1 S T B AE2 S K AH0 T S'],
  "wasted": ['W EY1 S T IH0 D'],
  "wasteful": ['W EY1 S T F AH0 L'],
  "wastefulness": ['W EY1 S T F AH0 L N AH0 S'],
  "wasteland": ['W EY1 S T L AE2 N D'],
  "wastepaper": ['W EY1 S T P EY2 P ER0'],
  "waster": ['W EY1 S T ER0'],
  "wasters": ['W EY1 S T ER0 Z'],
  "wastes": ['W EY1 S T S'],
  "wastewater": ['W EY1 S T W AO2 T ER0'],
  "wasting": ['W EY1 S T IH0 NG'],
  "waszak": ['V AA1 SH AH0 K'],
  "waszkiewicz": ['V AA2 SH K EH1 V IH0 CH'],
  "wat": ['W AO1 T'],
  "watanabe": ['W AA2 T AA0 N AA1 B EY0'],
  "watch": ['W AA1 CH', 'W AO1 CH'],
  "watchdog": ['W AA1 CH D AO2 G'],
  "watchdogs": ['W AA1 CH D AO2 G Z'],
  "watched": ['W AA1 CH T', 'W AO1 CH T'],
  "watcher": ['W AA1 CH ER0'],
  "watchers": ['W AA1 CH ER0 Z'],
  "watches": ['W AA1 CH AH0 Z', 'W AA1 CH IH0 Z'],
  "watchful": ['W AA1 CH F AH0 L'],
  "watchin'": ['W AA1 CH IH0 N'],
  "watching": ['W AA1 CH IH0 NG'],
  "watchman": ['W AA1 CH M AH0 N'],
  "watchmen": ['W AA1 CH M EH0 N'],
  "watchorn": ['W AO1 CH ER0 N'],
  "watchword": ['W AA1 CH W ER2 D'],
  "watchwords": ['W AA1 CH W ER2 D Z'],
  "water": ['W AO1 T ER0'],
  "water's": ['W AO1 T ER0 Z'],
  "water-repellent": ['W AO1 T ER0 R IH0 P EH1 L AH0 N T'],
  "waterbed": ['W AO1 T ER0 B EH2 D'],
  "waterbeds": ['W AO1 T ER0 B EH2 D Z'],
  "waterborne": ['W AO1 T ER0 B AO2 R N'],
  "waterbottle": ['W AO1 T ER0 B AO2 T AH0 L'],
  "waterbottles": ['W AO1 T ER0 B AO2 T AH0 L Z'],
  "waterbury": ['W AO1 T ER0 B EH2 R IY0'],
  "watercolor": ['W AO1 T ER0 K AH2 L ER0'],
  "watercolors": ['W AO1 T ER0 K AH2 L ER0 Z'],
  "watercress": ['W AO1 T ER0 K R EH2 S'],
  "watered": ['W AO1 T ER0 D'],
  "waterer": ['W AO1 T ER0 ER0'],
  "waterfall": ['W AO1 T ER0 F AO2 L'],
  "waterfalls": ['W AO1 T ER0 F AO2 L Z'],
  "waterfield": ['W AO1 T ER0 F IY0 L D'],
  "waterford": ['W AO1 T ER0 F ER0 D'],
  "waterford's": ['W AO1 T ER0 F ER0 D Z'],
  "waterfowl": ['W AO1 T ER0 F AW2 L'],
  "waterfront": ['W AO1 T ER0 F R AH2 N T'],
  "waterfronts": ['W AO1 T ER0 F R AH2 N T S'],
  "watergate": ['W AO1 T ER0 G EY2 T', 'W AA1 T ER0 G EY2 T'],
  "waterhouse": ['W AO1 T ER0 HH AW2 S'],
  "waterhouse's": ['W AO1 T ER0 HH AW2 S IH0 Z'],
  "watering": ['W AO1 T ER0 IH0 NG'],
  "waterishness": ['W AO1 T ER0 IH0 SH N AH0 S'],
  "waterlog": ['W AO1 T ER0 L AA2 G'],
  "waterlogged": ['W AO1 T ER0 L AA2 G D'],
  "waterloo": ['W AO1 T ER0 L UW2'],
  "waterman": ['W AO1 T ER0 M AH0 N'],
  "waterman's": ['W AO1 T ER0 M AH0 N Z'],
  "watermark": ['W AO1 T ER0 M AA2 R K'],
  "watermarks": ['W AO1 T ER0 M AA2 R K S'],
  "watermelon": ['W AO1 T ER0 M EH2 L AH0 N'],
  "watermelons": ['W AO1 T ER0 M EH2 L AH0 N Z'],
  "watermen": ['W AO1 T ER0 M AH0 N'],
  "waterproof": ['W AO1 T ER0 P R UW2 F'],
  "waterproofing": ['W AO1 T ER0 P R UW2 F IH0 NG'],
  "waters": ['W AO1 T ER0 Z'],
  "watershed": ['W AO1 T ER0 SH EH2 D', 'W AA1 T ER0 SH EH2 D'],
  "watersheds": ['W AO1 T ER0 SH EH2 D Z', 'W AA1 T ER0 SH EH2 D Z'],
  "waterson": ['W AO1 T ER0 S AH0 N'],
  "waterstein": ['W AO1 T ER0 S T IY0 N', 'W AO1 T ER0 S T AY0 N'],
  "waterston": ['W AO1 T ER0 S T AH0 N'],
  "waterstone": ['W AO1 T ER0 S T OW2 N'],
  "waterstone's": ['W AO1 T ER0 S T OW2 N Z'],
  "watertight": ['W AO1 T ER0 T AY2 T'],
  "watertown": ['W AO1 T ER0 T AW2 N'],
  "waterville": ['W AO1 T ER0 V IH0 L'],
  "waterway": ['W AO1 T ER0 W EY2'],
  "waterways": ['W AO1 T ER0 W EY2 Z'],
  "waterwork": ['W AO1 T ER0 W ER2 K'],
  "waterworks": ['W AO1 T ER0 W ER2 K S'],
  "waterworld": ['W AO1 T ER0 W ER2 L D'],
  "waterworth": ['W AO1 T ER0 W ER0 TH'],
  "watery": ['W AO1 T ER0 IY0'],
  "watford": ['W AO1 T F ER0 D'],
  "wathen": ['W AO1 TH AH0 N'],
  "watkin": ['W AO1 T K IH0 N'],
  "watkins": ['W AA1 T K IH0 N Z'],
  "watkins'": ['W AA1 T K IH0 N Z'],
  "watkinson": ['W AO1 T K IH0 N S AH0 N'],
  "watland": ['W AA1 T L AH0 N D'],
  "watley": ['W AA1 T L IY0'],
  "watling": ['W AO1 T AH0 L IH0 NG', 'W AO1 T L IH0 NG'],
  "watlington": ['W AO1 T AH0 L IH0 NG T AH0 N', 'W AO1 T L IH0 NG T AH0 N'],
  "watne": ['W AO1 T N'],
  "watrous": ['W AA1 T R AH0 S'],
  "watry": ['W AA1 T R IY0'],
  "watsco": ['W AA1 T S K OW2'],
  "watson": ['W AA1 T S AH0 N'],
  "watson's": ['W AA1 T S AH0 N Z'],
  "watsonville": ['W AA1 T S AH0 N V IH2 L'],
  "watt": ['W AA1 T'],
  "watt's": ['W AA1 T S'],
  "wattage": ['W AA1 T AH0 JH'],
  "wattenbarger": ['W AO1 T IH0 N B AA0 R G ER0'],
  "wattenberg": ['W AA1 T AH0 N B ER0 G'],
  "wattenburg": ['W AA1 T AH0 N B ER0 G'],
  "watters": ['W AA1 T ER0 Z'],
  "wattie": ['W AA1 T IY0'],
  "wattle": ['W AA1 T AH0 L'],
  "wattled": ['W AA1 T AH0 L D'],
  "wattles": ['W AA1 T AH0 L Z'],
  "wattleton": ['W AA1 T AH0 L T AH0 N'],
  "watton": ['W AO1 T AH0 N'],
  "watts": ['W AA1 T S'],
  "wattwil": ['W AA1 T W IH0 L'],
  "watwood": ['W AO1 T W UH0 D'],
  "watz": ['W AA1 T S'],
  "watzman": ['W AA1 T S M AH0 N'],
  "waugaman": ['W AO1 G AH0 M AH0 N'],
  "waugh": ['W AO1'],
  "waugh's": ['W AO1 Z'],
  "waukegan": ['W AO0 K IY1 G AH0 N'],
  "waukesha": ['W AO0 K IY1 SH AH0'],
  "wausau": ['W AO1 S AO0'],
  "wauters": ['W AW1 T ER0 Z'],
  "wave": ['W EY1 V'],
  "waved": ['W EY1 V D'],
  "waveform": ['W EY1 V F AO2 R M'],
  "waveforms": ['W EY1 V F AO2 R M Z'],
  "wavelength": ['W EY1 V L EH2 NG TH'],
  "wavelengths": ['W EY1 V L EH2 NG TH S'],
  "waver": ['W EY1 V ER0'],
  "wavered": ['W EY1 V ER0 D'],
  "wavering": ['W EY1 V ER0 IH0 NG'],
  "waverley": ['W EY1 V ER0 L IY0'],
  "waverly": ['W EY1 V ER0 L IY0'],
  "waves": ['W EY1 V Z'],
  "wavetek": ['W EY1 V T EH2 K'],
  "waving": ['W EY1 V IH0 NG'],
  "wavra": ['W AA1 V R AH0'],
  "wavy": ['W EY1 V IY0'],
  "wawrzyniak": ['W AA0 R Z IH1 N IY0 AE0 K'],
  "wax": ['W AE1 K S'],
  "waxed": ['W AE1 K S T'],
  "waxes": ['W AE1 K S IH0 Z'],
  "waxing": ['W AE1 K S IH0 NG'],
  "waxler": ['W AE1 K S L ER0'],
  "waxman": ['W AE1 K S M AH0 N'],
  "waxman's": ['W AE1 K S M AH0 N Z'],
  "waxy": ['W AE1 K S IY0'],
  "way": ['W EY1'],
  "way's": ['W EY1 Z'],
  "wayans": ['W EY1 AH0 N Z', 'HH W EY1 AH0 N Z'],
  "waybright": ['W EY1 B R AY2 T'],
  "waycaster": ['W EY1 K AE2 S T ER0'],
  "waye": ['W EY1'],
  "waylaid": ['W EY1 L EY2 D'],
  "wayland": ['W EY1 L AH0 N D'],
  "waylay": ['W EY1 L EY2'],
  "waylon": ['W EY1 L AA2 N'],
  "wayman": ['W EY1 M AH0 N'],
  "waymire": ['W EY1 M AY2 R'],
  "wayne": ['W EY1 N'],
  "wayne's": ['W EY1 N Z'],
  "waynesboro": ['W EY1 N Z B ER0 OW0'],
  "waynesville": ['W EY1 N Z V IH2 L'],
  "waynick": ['W EY1 N IH0 K'],
  "ways": ['W EY1 Z'],
  "wayside": ['W EY1 S AY2 D'],
  "wayson": ['W EY1 Z AH0 N'],
  "wayt": ['W EY1 T'],
  "wayward": ['W EY1 W ER0 D'],
  "wazir": ['W AA0 Z IH1 R'],
  "wazir's": ['W AA0 Z IH1 R Z'],
  "we": ['W IY1'],
  "we'd": ['W IY1 D'],
  "we'll": ['W IY1 L', 'W IH1 L'],
  "we're": ['W IY1 R', 'W IH1 R', 'W ER1'],
  "we've": ['W IY1 V'],
  "weak": ['W IY1 K'],
  "weaken": ['W IY1 K AH0 N'],
  "weakened": ['W IY1 K AH0 N D'],
  "weakening": ['W IY1 K AH0 N IH0 NG'],
  "weakens": ['W IY1 K AH0 N Z'],
  "weaker": ['W IY1 K ER0'],
  "weakest": ['W IY1 K AH0 S T'],
  "weakland": ['W IY1 K L AH0 N D'],
  "weakley": ['W IY1 K L IY0'],
  "weakling": ['W IY1 K L IH0 NG'],
  "weakly": ['W IY1 K L IY0'],
  "weakness": ['W IY1 K N AH0 S'],
  "weaknesses": ['W IY1 K N AH0 S AH0 Z', 'W IY1 K N AH0 S IH0 Z'],
  "weaks": ['W IY1 K S'],
  "wealth": ['W EH1 L TH'],
  "wealthier": ['W EH1 L TH IY0 ER0'],
  "wealthiest": ['W EH1 L TH IY0 AH0 S T'],
  "wealths": ['W EH1 L TH S'],
  "wealthy": ['W EH1 L TH IY0'],
  "wean": ['W IY1 N'],
  "weaned": ['W IY1 N D'],
  "weaning": ['W IY1 N IH0 NG'],
  "weant": ['W IY1 N T'],
  "weapon": ['W EH1 P AH0 N'],
  "weapon's": ['W EH1 P AH0 N Z'],
  "weaponry": ['W EH1 P AH0 N R IY0'],
  "weapons": ['W EH1 P AH0 N Z'],
  "weapons'": ['W EH1 P AH0 N Z'],
  "wear": ['W EH1 R'],
  "wearable": ['W EH1 R AH0 B AH0 L'],
  "wearer": ['W EH1 R ER0'],
  "wearers": ['W EH1 R ER0 Z'],
  "wearever": ['W EH1 R EH2 V ER0'],
  "wearhouse": ['W EH1 R HH AW2 S'],
  "wearied": ['W IH1 R IY0 D'],
  "wearily": ['W EH1 R AH0 L IY0'],
  "weariness": ['W IH1 R IY0 N AH0 S'],
  "wearing": ['W EH1 R IH0 NG'],
  "wearisome": ['W IH1 R IY0 S AH0 M'],
  "wears": ['W EH1 R Z'],
  "weary": ['W IH1 R IY0'],
  "wearying": ['W IY1 R IY0 IH0 NG'],
  "wease": ['W IY1 Z'],
  "weasel": ['W IY1 Z AH0 L'],
  "weasellike": ['W IY1 Z AH0 L L AY2 K'],
  "weasels": ['W IY1 Z AH0 L Z'],
  "weast": ['W IY1 S T'],
  "weather": ['W EH1 DH ER0'],
  "weather's": ['W EH1 DH ER0 Z'],
  "weatherall": ['W EH1 DH ER0 AO2 L'],
  "weatherbee": ['W EH1 DH ER0 B IY2'],
  "weatherby": ['W EH1 DH ER0 B IY0'],
  "weathered": ['W EH1 DH ER0 D'],
  "weatherford": ['W EH1 DH ER0 F ER0 D'],
  "weatherhead": ['W EH1 DH ER0 HH EH2 D'],
  "weatherholt": ['W EH1 DH ER0 HH OW2 L T'],
  "weatherholtz": ['W EH1 DH ER0 HH OW2 L T S'],
  "weathering": ['W EH1 DH ER0 IH0 NG'],
  "weatherington": ['W EH1 DH ER0 IH0 NG T AH0 N'],
  "weatherly": ['W EH1 DH ER0 L IY0'],
  "weatherman": ['W EH1 DH ER0 M AE2 N'],
  "weathermen": ['W EH1 DH ER0 M EH2 N'],
  "weatherperson": ['W EH1 TH ER0 P ER2 S AH0 N'],
  "weatherproof": ['W EH1 DH ER0 P R UW2 F'],
  "weathers": ['W EH1 DH ER0 Z'],
  "weathersbee": ['W EH1 DH ER0 Z B IY2'],
  "weathersby": ['W EH1 DH ER0 S B IY0'],
  "weatherspoon": ['W EH1 DH ER0 S P UW2 N'],
  "weatherstone": ['W EH1 DH ER0 S T OW2 N'],
  "weatherwax": ['W EH1 DH ER0 W AE2 K S'],
  "weathington": ['W EH1 TH IH0 NG T AH0 N'],
  "weave": ['W IY1 V'],
  "weaved": ['W IY1 V D'],
  "weaver": ['W IY1 V ER0'],
  "weaver's": ['W IY1 V ER0 Z'],
  "weaverling": ['W IY1 V ER0 L IH0 NG'],
  "weavers": ['W IY1 V ER0 Z'],
  "weaves": ['W IY1 V Z'],
  "weavil": ['W IY1 V AH0 L'],
  "weaving": ['W IY1 V IH0 NG'],
  "weavings": ['W IY1 V IH0 NG Z'],
  "web": ['W EH1 B'],
  "webb": ['W EH1 B'],
  "webb's": ['W EH1 B Z'],
  "webbed": ['W EH1 B D'],
  "webber": ['W EH1 B ER0'],
  "webber's": ['W EH1 B ER0 Z'],
  "webbing": ['W EH1 B IH0 NG'],
  "webbs": ['W EH1 B Z'],
  "weber": ['W EH1 B ER0'],
  "weber's": ['V EY1 B ER0 Z', 'W EH1 B ER0 Z'],
  "weberg": ['W EH1 B ER0 G'],
  "webers": ['W EH1 B ER0 Z'],
  "webinar": ['W EH1 B IH0 N ER0'],
  "webley": ['W EH1 B L IY0'],
  "webmaster": ['W EH1 B M AE2 S T ER0'],
  "webmasters": ['W EH1 B M AE2 S T ER0 Z'],
  "webpage": ['W EH1 B P IH0 JH'],
  "webre": ['W EH1 B ER0'],
  "webs": ['W EH1 B Z'],
  "website": ['W EH1 B S AY2 T'],
  "website's": ['W EH1 B S AY2 T S'],
  "websites": ['W EH1 B S AY2 T S'],
  "webster": ['W EH1 B S T ER0'],
  "webster's": ['W EH1 B S T ER0 Z'],
  "websters": ['W EH1 B S T ER0 Z'],
  "wechsel": ['W EH1 K S AH0 L'],
  "wechsler": ['W EH1 K S L ER0', 'W EH1 SH L ER0'],
  "wecht": ['W EH1 K T'],
  "wecht's": ['W EH1 K T S'],
  "wechter": ['W EH1 K T ER0'],
  "weck": ['W EH1 K'],
  "wecker": ['W EH1 K ER0'],
  "weckerly": ['W EH1 K ER0 L IY0'],
  "weckesser": ['W EH1 K IH0 S ER0'],
  "weckwerth": ['W EH1 K W ER0 TH'],
  "wed": ['W EH1 D'],
  "wedbush": ['W EH1 D B UH2 SH'],
  "wedd": ['W EH1 D'],
  "wedded": ['W EH1 D IH0 D'],
  "weddell": ['W EH1 D AH0 L'],
  "wedding": ['W EH1 D IH0 NG'],
  "wedding's": ['W EH1 D IH0 NG Z'],
  "weddings": ['W EH1 D IH0 NG Z'],
  "weddington": ['W EH1 D IH0 NG T AH0 N'],
  "weddle": ['W EH1 D AH0 L'],
  "wedekind": ['W EH1 D IH0 K IH0 N D'],
  "wedeking": ['W EH1 D IH0 K IH0 NG'],
  "wedel": ['W EH1 D AH0 L'],
  "wedell": ['W EH1 D AH0 L'],
  "wedemeyer": ['W EH1 D IH0 M AY2 ER0'],
  "wedge": ['W EH1 JH'],
  "wedged": ['W EH1 JH D'],
  "wedges": ['W EH1 JH AH0 Z', 'W EH1 JH IH0 Z'],
  "wedgestone": ['W EH1 JH S T OW2 N'],
  "wedgewood": ['W EH1 JH W UH2 D'],
  "wedgewood's": ['W EH1 JH W UH2 D Z'],
  "wedgeworth": ['W EH1 JH W ER2 TH'],
  "wedgwood": ['W EH1 JH W UH2 D'],
  "wedig": ['W EH1 D IH0 G'],
  "wedin": ['W EH1 D IH0 N'],
  "wedlock": ['W EH1 D L AA2 K'],
  "wednesday": ['W EH1 N Z D IY0', 'W EH1 N Z D EY2'],
  "wednesday's": ['W EH1 N Z D IY0 Z', 'W EH1 N Z D EY2 Z'],
  "wednesdays": ['W EH1 N Z D EY2 Z', 'W EH1 N Z D IY0 Z'],
  "wedowee": ['W EH0 D AW1 W IY2'],
  "wedowee's": ['W EH0 D AW1 W IY2 Z'],
  "wedtech": ['W EH1 D T EH2 K'],
  "wedtech's": ['W EH1 D T EH2 K S'],
  "wee": ['W IY1'],
  "weeber": ['W IY1 B ER0'],
  "weech": ['W IY1 CH'],
  "weed": ['W IY1 D'],
  "weeded": ['W IY1 D IH0 D'],
  "weeden": ['W IY1 D AH0 N'],
  "weeding": ['W IY1 D IH0 NG'],
  "weedman": ['W IY1 D M AH0 N'],
  "weedon": ['W IY1 D AH0 N'],
  "weeds": ['W IY1 D Z'],
  "weedy": ['W IY1 D IY0'],
  "weehawken": ['W IY2 HH AO1 K AH0 N'],
  "week": ['W IY1 K'],
  "week's": ['W IY1 K S'],
  "weekday": ['W IY1 K D EY2'],
  "weekdays": ['W IY1 K D EY2 Z'],
  "weekend": ['W IY1 K EH2 N D', 'W IY1 K IH2 N D'],
  "weekend's": ['W IY1 K EH2 N D Z'],
  "weekender": ['W IY1 K EH2 N D ER0'],
  "weekends": ['W IY1 K EH2 N D Z'],
  "weekes": ['W IY1 K S'],
  "weekley": ['W IY1 K L IY0'],
  "weeklies": ['W IY1 K L IY0 Z'],
  "weeklong": ['W IY1 K L AO2 NG'],
  "weekly": ['W IY1 K L IY0'],
  "weekly's": ['W IY1 K L IY0 Z'],
  "weeks": ['W IY1 K S'],
  "weeks'": ['W IY1 K S'],
  "weems": ['W IY1 M Z'],
  "weeny": ['W IY1 N IY0'],
  "weep": ['W IY1 P'],
  "weeping": ['W IY1 P IH0 NG'],
  "weeps": ['W IY1 P S'],
  "weepy": ['W IY1 P IY0'],
  "weers": ['W IY1 ER0 Z'],
  "weerts": ['W IH1 R T S'],
  "weese": ['W IY1 Z'],
  "weesner": ['W IY1 Z N ER0'],
  "weevil": ['W IY1 V AH0 L'],
  "weevils": ['W IY1 V AH0 L Z'],
  "wegbreit": ['W EH1 G B R AY2 T'],
  "wege": ['W IY1 JH'],
  "wegener": ['W EH1 G IY0 N ER0'],
  "weger": ['W IY1 G ER0'],
  "weglarz": ['W EH1 G L AA0 R Z'],
  "wegman": ['W EH1 G M AH0 N'],
  "wegmann": ['W EH1 G M AH0 N'],
  "wegner": ['W EH1 G N ER0'],
  "wegrzyn": ['W EH1 G ER0 Z IH0 N'],
  "wehde": ['W EH1 D'],
  "wehe": ['W IY1 HH'],
  "wehlan": ['W EH1 L AH0 N'],
  "wehle": ['W EH1 L IY0'],
  "wehler": ['W EH1 L ER0'],
  "wehling": ['W EH1 L IH0 NG'],
  "wehman": ['W EH1 M AH0 N'],
  "wehmeier": ['W EH1 M AY0 ER0'],
  "wehmeyer": ['W EH1 M AY0 ER0'],
  "wehner": ['W EH1 N ER0'],
  "wehr": ['W EH1 R'],
  "wehrenberg": ['W EH1 R AH0 N B ER0 G'],
  "wehrheim": ['W EH1 R HH AY0 M'],
  "wehrle": ['W EH1 R AH0 L'],
  "wehrli": ['W EH1 R L IY0'],
  "wehrly": ['W EH1 R L IY0'],
  "wehrman": ['W EH1 R M AH0 N'],
  "wehrmann": ['W EH1 R M AH0 N'],
  "wehunt": ['W EH1 HH AH0 N T'],
  "wei": ['W EY1'],
  "weiand": ['W AY1 AH0 N D'],
  "weibel": ['W AY1 B AH0 L'],
  "weible": ['W IY1 B AH0 L'],
  "weich": ['W AY1 K'],
  "weichel": ['W AY1 K AH0 L'],
  "weichert": ['W AY1 K ER0 T'],
  "weichman": ['W AY1 K M AH0 N'],
  "weicht": ['W AY1 K T'],
  "weick": ['W IY1 K'],
  "weicker": ['W AY1 K ER0'],
  "weicker's": ['W AY1 K ER0 Z'],
  "weida": ['V AY1 D AH0'],
  "weide": ['W AY1 D IY0'],
  "weidel": ['W AY1 D AH0 L'],
  "weideman": ['W AY1 D M AH0 N'],
  "weidemann": ['W AY1 D M AH0 N'],
  "weidenbach": ['W AY1 D IH0 N B AA0 K'],
  "weidenbaum": ['W AY1 D AH0 N B AW2 M'],
  "weidenfeld": ['W AY1 D AH0 N F EH2 L D'],
  "weider": ['W AY1 D ER0'],
  "weiderman": ['W AY1 D ER0 M AH0 N'],
  "weidig": ['W AY1 D IH0 G'],
  "weidinger": ['W AY1 D IH0 NG ER0'],
  "weidler": ['W AY1 D AH0 L ER0', 'W AY1 D L ER0'],
  "weidlich": ['W AY1 D L IH0 K'],
  "weidman": ['W AY1 D M AH0 N'],
  "weidmann": ['W AY1 D M AH0 N'],
  "weidner": ['W AY1 D N ER0'],
  "weier": ['W EY1 ER0'],
  "weigand": ['W AY1 G AH0 N D'],
  "weigandt": ['W AY1 G AH0 N T'],
  "weigel": ['W AY1 G AH0 L'],
  "weigelt": ['W AY1 G IH0 L T'],
  "weigert": ['W AY1 G ER0 T'],
  "weigh": ['W EY1'],
  "weighed": ['W EY1 D'],
  "weighing": ['W EY1 IH0 NG'],
  "weighs": ['W EY1 Z'],
  "weight": ['W EY1 T'],
  "weighted": ['W EY1 T IH0 D'],
  "weighting": ['W EY1 T IH0 NG'],
  "weightings": ['W EY1 T IH0 NG Z'],
  "weightless": ['W EY1 T L AH0 S'],
  "weightlessness": ['W EY1 T L AH0 S N AH0 S'],
  "weightlifter": ['W EY1 T L IH2 F T ER0'],
  "weightlifters": ['W EY1 T L IH2 F T ER0 Z'],
  "weightlifting": ['W EY1 T L IH2 F T IH0 NG'],
  "weightman": ['W AY1 T M AH0 N'],
  "weights": ['W EY1 T S'],
  "weighty": ['W EY1 T IY0', 'W EY1 T IY2'],
  "weigl": ['W IY1 G AH0 L'],
  "weigle": ['W IY1 G AH0 L'],
  "weigold": ['W AY1 G OW2 L D'],
  "weihe": ['W IY1 HH'],
  "weiher": ['W AY1 HH ER0'],
  "weik": ['W IY1 K'],
  "weikel": ['W AY1 K AH0 L'],
  "weiker": ['W AY1 K ER0'],
  "weikert": ['W AY1 K ER0 T'],
  "weikle": ['W IY1 K AH0 L'],
  "weil": ['W AY1 L', 'W IY1 L'],
  "weil's": ['W AY1 L Z'],
  "weiland": ['W AY1 L AH0 N D'],
  "weilbacher": ['W AY1 L B AA2 K ER0'],
  "weild": ['W AY1 L D', 'W IY1 L D'],
  "weiler": ['W AY1 L ER0'],
  "weill": ['W AY1 L'],
  "weill's": ['W AY1 L Z'],
  "weiman": ['W AY1 M AH0 N'],
  "weimann": ['W AY1 M AH0 N'],
  "weimar": ['V AY1 M AA0 R'],
  "weimer": ['W AY1 M ER0'],
  "weimeyer": ['W AY1 M AY0 ER0'],
  "wein": ['W IY1 N'],
  "weinand": ['W AY1 N AH0 N D'],
  "weinbach": ['W AY1 N B AA2 K'],
  "weinbaum": ['W AY1 N B AW2 M'],
  "weinberg": ['W AY1 N B ER0 G'],
  "weinberger": ['W AY1 N B ER0 G ER0'],
  "weinberger's": ['W AY1 N B ER0 G ER0 Z'],
  "weindel": ['W AY1 N D AH0 L'],
  "weinel": ['W AY1 N AH0 L'],
  "weiner": ['W AY1 N ER0'],
  "weinert": ['W AY1 N ER0 T'],
  "weinfeld": ['W AY1 N F EH2 L D'],
  "weingart": ['W AY1 NG G AA0 R T'],
  "weingarten": ['W AY1 N G AA2 R T AH0 N'],
  "weingartner": ['W AY1 NG G AA0 R T N ER0'],
  "weinger": ['W AY1 N G ER0'],
  "weinhardt": ['W AY1 N HH AA2 R T'],
  "weinheimer": ['W AY1 N HH AY2 M ER0'],
  "weinhold": ['W AY1 N HH OW2 L D'],
  "weininger": ['W AY1 N IH0 NG ER0'],
  "weinkauf": ['W AY1 NG K AW0 F'],
  "weinman": ['W AY1 N M AH0 N'],
  "weinmann": ['W AY1 N M AH0 N'],
  "weinreb": ['W AY1 N R IH0 B'],
  "weinreich": ['W AY1 N R AY2 K'],
  "weinrich": ['W AY1 N R IH0 K'],
  "weinroth": ['W AY1 N R AO2 TH'],
  "weins": ['W IY1 N Z'],
  "weinstein": ['W AY1 N S T AY2 N', 'W AY1 N S T IY2 N'],
  "weinstein's": ['W AY1 N S T AY2 N Z', 'W AY1 N S T IY2 N Z'],
  "weinstock": ['W AY1 N S T AA2 K'],
  "weintraub": ['W AY1 N T R AW2 B'],
  "weintz": ['W AY1 N T S'],
  "weintz'": ['W AY1 N T S'],
  "weintz's": ['W AY1 N T S IH0 Z'],
  "weinzierl": ['W AY1 N Z IH0 R L'],
  "weipert": ['W AY1 P ER0 T'],
  "weir": ['W IY1 R'],
  "weir's": ['W IY1 R Z'],
  "weirauch": ['W AY1 R AW0 K'],
  "weird": ['W IH1 R D'],
  "weirder": ['W IH1 R D ER0'],
  "weirdest": ['W IH1 R D AH0 S T'],
  "weirdly": ['W IH1 R D L IY0'],
  "weirdness": ['W IH1 R D N AH0 S'],
  "weirdo": ['W IH1 R D OW0'],
  "weirdos": ['W IH1 R D OW0 Z'],
  "weirich": ['W AY1 R IH0 K'],
  "weirick": ['W AY1 R IH0 K'],
  "weirton": ['W IH1 R T AH0 N'],
  "weirton's": ['W IH1 R T AH0 N Z'],
  "weis": ['W AY1 S'],
  "weisbach": ['W AY1 S B AA2 K'],
  "weisbecker": ['W AY1 S B EH2 K ER0'],
  "weisberg": ['W AY1 S B ER0 G'],
  "weisberger": ['W AY1 S B ER0 G ER0'],
  "weisbrod": ['W AY1 S B R AA2 D'],
  "weisbrot": ['W AY1 S B R AH0 T'],
  "weischedel": ['W AY1 SH AH0 D AH0 L'],
  "weise": ['W IY1 S'],
  "weisel": ['W AY1 S AH0 L', 'W AY1 Z AH0 L', 'W IY2 Z EH1 L'],
  "weiseman": ['W AY1 S AH0 M AH0 N'],
  "weisenbach": ['W AY1 S IH0 N B AA0 K', 'W AY1 Z IH0 N B AA0 K'],
  "weisenberg": ['W AY1 S AH0 N B ER0 G', 'W AY1 Z AH0 N B ER0 G'],
  "weisenberger": ['W AY1 S AH0 N B ER0 G ER0', 'W AY1 Z AH0 N B ER0 G ER0'],
  "weisenborn": ['W AY1 S IH0 N B ER0 N', 'W AY1 Z IH0 N B ER0 N'],
  "weisenburger": ['W AY1 S AH0 N B ER0 G ER0', 'W AY1 S IH0 N B ER0 G ER0'],
  "weisensel": ['W AY1 S IH0 N S AH0 L', 'W AY1 Z IH0 N S AH0 L'],
  "weisenthal": ['W AY1 S IH0 N TH AA2 L', 'W AY1 Z IH0 N TH AA2 L'],
  "weiser": ['W AY1 S ER0'],
  "weisfeld": ['W AY1 S F EH2 L D'],
  "weisfield's": ['W AY1 S F IY0 L D Z'],
  "weisgerber": ['W AY1 S G ER0 B ER0'],
  "weishaar": ['W AY1 SH AA0 R'],
  "weishaupt": ['W AY1 SH AW0 P T'],
  "weisheit": ['W AY1 SH AY0 T'],
  "weisinger": ['W AY1 S IH0 N JH ER0'],
  "weiskopf": ['W AY1 S K AO0 F'],
  "weisman": ['W AY1 S M AH0 N'],
  "weismann": ['W AY1 S M AH0 N'],
  "weisner": ['W AY1 S N ER0'],
  "weiss": ['W AY1 S'],
  "weiss's": ['W AY1 S IH0 Z'],
  "weissberg": ['W AY1 S B ER0 G'],
  "weisse": ['W AY1 S'],
  "weissenborn": ['W AY1 S IH0 N B ER0 N'],
  "weisser": ['W AY1 S ER0'],
  "weissert": ['W AY1 S ER0 T'],
  "weissinger": ['W AY1 S IH0 N JH ER0'],
  "weissman": ['W AY1 S M AH0 N'],
  "weissmann": ['W AY1 S M AH0 N'],
  "weist": ['W IY1 IH0 S T', 'W AY1 S T'],
  "weisz": ['V AY1 SH'],
  "weitek": ['W EY1 T EH2 K'],
  "weith": ['W IY1 TH'],
  "weitkamp": ['W AY1 T K AE2 M P'],
  "weitler": ['W AY1 T L ER0'],
  "weitman": ['W AY1 T M AH0 N'],
  "weitz": ['W IY1 T S'],
  "weitzel": ['W AY1 T Z AH0 L'],
  "weitzen": ['W AY1 T S AH0 N'],
  "weitzman": ['W AY1 T S M AH0 N'],
  "weitzman's": ['W AY1 T S M AH0 N Z'],
  "weitzner": ['W AY1 T S N ER0'],
  "weizman": ['W AY1 Z M AH0 N'],
  "weizsaecker": ['W AY1 S AE2 K ER0'],
  "weksel": ['W EH1 K S AH0 L'],
  "welbilt": ['W EH1 L B IH1 L T'],
  "welbilt's": ['W EH1 L B IH1 L T S'],
  "welborn": ['W EH1 L B ER0 N'],
  "welborne": ['W EH1 L B ER0 N'],
  "welby": ['W EH1 L B IY0'],
  "welch": ['W EH1 L CH'],
  "welch's": ['W EH1 L CH IH0 Z'],
  "welchel": ['W EH1 L CH AH0 L'],
  "welcher": ['W EH1 L CH ER0'],
  "welcome": ['W EH1 L K AH0 M'],
  "welcomed": ['W EH1 L K AH0 M D'],
  "welcomes": ['W EH1 L K AH0 M Z'],
  "welcoming": ['W EH1 L K AH0 M IH0 NG'],
  "weld": ['W EH1 L D'],
  "weld's": ['W EH1 L D Z'],
  "welded": ['W EH1 L D IH0 D'],
  "welden": ['W EH1 L D AH0 N'],
  "welder": ['W EH1 L D ER0'],
  "welders": ['W EH1 L D ER0 Z'],
  "weldin": ['W EH1 L D IH0 N'],
  "welding": ['W EH1 L D IH0 NG'],
  "weldon": ['W EH1 L D AH0 N'],
  "weldon's": ['W EH1 L D AH0 N Z'],
  "welds": ['W EH1 L D Z'],
  "weldwood": ['W EH1 L D W UH2 D'],
  "weldy": ['W EH1 L D IY0'],
  "welfare": ['W EH1 L F EH2 R'],
  "welford": ['W EH1 L F ER0 D'],
  "welge": ['W EH1 L JH'],
  "welinder": ['W EH1 L IH2 N D ER0'],
  "welinsk": ['W EH0 L IH1 N S K'],
  "welk": ['W EH1 L K'],
  "welke": ['W EH1 L K'],
  "welker": ['W EH1 L K ER0'],
  "well": ['W EH1 L'],
  "well-being": ['W EH2 L B IY1 IH0 NG'],
  "well-doer": ['W EH1 L D UW1 R'],
  "well-educated": ['W EH1 L EH1 JH AH0 K EY2 T IH0 D'],
  "well-known": ['W EH1 L N OW1 N'],
  "welland": ['W EH1 L AH0 N D'],
  "wellbeing": ['W EH2 L B IY1 IH0 NG'],
  "wellborn": ['W EH1 L B AO1 R N'],
  "wellbrock": ['W EH1 L B R AH0 K'],
  "wellco": ['W EH1 L K OW0'],
  "wellcome": ['W EH1 L K AH2 M'],
  "wellcome's": ['W EH1 L K AH2 M Z'],
  "welle": ['W EH1 L'],
  "wellek": ['W EH1 L EH0 K'],
  "wellen": ['W EH1 L AH0 N'],
  "wellendorf": ['W EH1 L IH0 N D AO0 R F'],
  "wellens": ['W EH1 L AH0 N Z'],
  "weller": ['W EH1 L ER0'],
  "welles": ['W EH1 L Z'],
  "welles'": ['W EH1 L Z'],
  "wellesley": ['W EH1 L Z L IY0'],
  "wellfleet": ['W EH1 L F L IY2 T'],
  "wellhead": ['W EH1 L HH EH2 D'],
  "wellies": ['W EH1 L IY0 Z'],
  "welling": ['W EH1 L IH0 NG'],
  "wellington": ['W EH1 L IH0 NG T AH0 N'],
  "wellington's": ['W EH1 L IH0 NG T AH0 N Z'],
  "wellingtons": ['W EH1 L IH0 NG T AH0 N Z'],
  "welliver": ['W EH1 L IH0 V ER0'],
  "wellman": ['W EH1 L M AH0 N'],
  "wellner": ['W EH1 L N ER0'],
  "wellness": ['W EH1 L N AH0 S'],
  "wellnitz": ['W EH1 L N IH0 T S'],
  "wellons": ['W EH1 L AH0 N Z'],
  "wellpoint": ['W EH1 L P OY2 N T'],
  "wells": ['W EH1 L Z'],
  "wells's": ['W EH1 L Z IH0 Z'],
  "wellspring": ['W EH1 L S P R IH2 NG'],
  "wellstone": ['W EH1 L S OW2 N'],
  "wellstone's": ['W EH1 L S OW2 N Z'],
  "welltech": ['W EH1 L T EH2 K'],
  "welna": ['W EH1 L N AH0'],
  "welp": ['W EH1 L P'],
  "welsch": ['W EH1 L SH'],
  "welser": ['W EH1 L S ER0'],
  "welsh": ['W EH1 L CH', 'W EH1 L SH'],
  "welshans": ['W EH1 L SH AH0 N Z'],
  "welt": ['W EH1 L T'],
  "welte": ['W EH1 L T'],
  "welter": ['W EH1 L T ER0'],
  "welterweight": ['W EH1 L T ER0 W EY2 T'],
  "weltman": ['W EH1 L T M AH0 N'],
  "welton": ['W EH1 L T AH0 N'],
  "welty": ['W EH1 L T IY0'],
  "weltz": ['W EH1 L T S'],
  "welz": ['W EH1 L Z'],
  "wembley": ['W EH1 M B L IY0'],
  "wemhoff": ['W EH1 M HH AO2 F'],
  "wemmer": ['W EH1 M ER0'],
  "wempe": ['W EH1 M P'],
  "wemple": ['W EH1 M P AH0 L'],
  "wen": ['W EH1 N'],
  "wenatchee": ['W AH0 N AE1 CH IY0'],
  "wenberg": ['W EH1 N B ER0 G'],
  "wenceslaus": ['W EH1 N S AH0 S L AO2 S'],
  "wench": ['W EH1 N CH'],
  "wenches": ['W EH1 N CH IH0 Z'],
  "wenchester": ['W EH1 N CH EH2 S T ER0'],
  "wenchester's": ['W EH1 N CH EH2 S T ER0 Z'],
  "wenck": ['W EH1 NG K'],
  "wend": ['W EH1 N D'],
  "wenda": ['W EH1 N D AH0'],
  "wende": ['W EH1 N D'],
  "wendel": ['W EH1 N D AH0 L'],
  "wendeline": ['W EH1 N D IH0 L AY2 N'],
  "wendelken": ['W EH1 N D IH0 L K AH0 N'],
  "wendell": ['W EH1 N D AH0 L'],
  "wender": ['W EH1 N D ER0'],
  "wenders": ['W EH1 N D ER0 Z'],
  "wending": ['W EH1 N D IH0 NG'],
  "wendland": ['W EH1 N D L AH0 N D'],
  "wendlandt": ['W EH1 N D L AH0 N T'],
  "wendler": ['W EH1 N D L ER0'],
  "wendling": ['W EH1 N D L IH0 NG'],
  "wendorf": ['W EH1 N D AO0 R F'],
  "wendorff": ['W EH1 N D AO0 R F'],
  "wends": ['W EH1 N D Z'],
  "wendt": ['W EH1 N T'],
  "wendy": ['W EH1 N D IY0'],
  "wendy's": ['W EH1 N D IY0 Z'],
  "wenfan": ['W EH1 N F AE1 N'],
  "weng": ['W EH1 NG'],
  "wenge": ['W EH1 N JH', 'W EH1 NG'],
  "wenger": ['W EH1 NG ER0'],
  "wengerd": ['W EH1 NG G ER0 D'],
  "wengert": ['W EH1 NG G ER0 T'],
  "wengler": ['W IH1 NG AH0 L ER0', 'W IH1 NG G L ER0'],
  "wenig": ['W EH1 N IH0 G'],
  "weniger": ['W EH1 N IH0 G ER0'],
  "weninger": ['W EH1 N IH0 NG ER0'],
  "wenk": ['W EH1 NG K'],
  "wenke": ['W EH1 NG K'],
  "wenker": ['W EH1 NG K ER0'],
  "wennberg": ['W EH1 N B ER0 G'],
  "wenner": ['W EH1 N ER0'],
  "wennerstrom": ['W EH1 N ER0 S T R AH0 M'],
  "wenning": ['W EH1 N IH0 NG'],
  "wenninger": ['W EH1 N IH0 NG ER0'],
  "wenona": ['W EH1 N AH0 N AH0'],
  "wenrich": ['W EH1 N R IH0 K'],
  "wenrick": ['W EH1 N R IH0 K'],
  "wensberg": ['W EH1 N Z B ER0 G'],
  "wensel": ['W EH1 N S AH0 L'],
  "wenstrom": ['W EH1 N S T R AH0 M'],
  "went": ['W EH1 N T'],
  "wente": ['W EH1 N T'],
  "wentland": ['W EH1 N T L AH0 N D'],
  "wentling": ['W EH1 N T L IH0 NG'],
  "wentworth": ['W EH1 N T W ER1 TH'],
  "wentz": ['W EH1 N T S'],
  "wentzel": ['W EH1 N T Z AH0 L'],
  "wentzell": ['W EH1 N T Z AH0 L'],
  "wentzville": ['W EH1 N T S V IH2 L'],
  "wenz": ['W EH1 N Z'],
  "wenzel": ['W EH1 N Z AH0 L'],
  "wenzhou": ['W EH0 N Z UW1'],
  "wenzl": ['W EH1 N Z AH0 L'],
  "wenzler": ['W EH1 N Z L ER0'],
  "wenzlick": ['W EH1 N Z L IH0 K'],
  "weppler": ['W EH1 P L ER0'],
  "wept": ['W EH1 P T'],
  "werber": ['W ER1 B ER0'],
  "werden": ['W ER1 D AH0 N'],
  "werder": ['W ER1 D ER0'],
  "werdesheim": ['W ER1 D Z HH AY2 M'],
  "were": ['W ER1'],
  "wereldhave": ['W EH1 R AH0 L D HH AA2 V EY0'],
  "weren't": ['W ER1 AH0 N T', 'W ER1 N T'],
  "werewolf": ['W EH1 R W UH2 L F'],
  "werewolves": ['W EH1 R W UH2 L V Z'],
  "wergin": ['W ER1 G IH0 N'],
  "werk": ['W ER1 K'],
  "werke": ['W ER1 K'],
  "werkheiser": ['W ER1 K HH AY0 S ER0'],
  "werking": ['W ER1 K IH0 NG'],
  "werkmeister": ['W ER1 K M AY0 S T ER0'],
  "werle": ['W ER1 L'],
  "werley": ['W ER1 L IY0'],
  "werling": ['W ER1 L IH0 NG'],
  "werman": ['W ER1 M AH0 N'],
  "wermer": ['W ER1 M ER0'],
  "wermiel": ['W ER1 M IY0 AH0 L'],
  "wermuth": ['W ER0 M UW1 TH'],
  "werne": ['W ER1 N AH0'],
  "wernecke": ['W ER1 N IH0 K'],
  "werner": ['W ER1 N ER0'],
  "werner's": ['W ER1 N ER0 Z'],
  "wernert": ['W ER1 N ER0 T'],
  "wernet": ['W ER1 N IH0 T'],
  "wernette": ['W ER0 N EH1 T'],
  "wernick": ['W ER1 N IH0 K'],
  "wernicke": ['W ER1 N IH0 K'],
  "wernimont": ['W ER1 N IH0 M AH0 N T'],
  "werning": ['W ER1 N IH0 NG'],
  "wernli": ['W ER1 N L IY0'],
  "werntz": ['W ER1 N T S'],
  "werre": ['W EH1 R'],
  "werry": ['W EH1 R IY0'],
  "werst": ['W ER1 S T'],
  "wert": ['W ER1 T'],
  "wertenberger": ['W ER1 T AH0 N B ER0 G ER0'],
  "werth": ['W ER1 TH'],
  "wertheim": ['W ER1 T HH AY0 M'],
  "wertheimer": ['W ER1 T HH AY0 M ER0'],
  "werther": ['W ER1 DH ER0'],
  "wertman": ['W ER1 T M AH0 N'],
  "werts": ['W ER1 T S'],
  "wertz": ['W ER1 T S'],
  "wery": ['W EH1 R IY0'],
  "wes": ['W EH1 S'],
  "wesat": ['W EH1 S AE2 T', 'W IY1 S AE2 T'],
  "wesch": ['W EH1 SH'],
  "wesche": ['W EH1 SH'],
  "wesco": ['W EH1 S K OW0'],
  "wescoat": ['W EH1 S K OW2 T'],
  "wescott": ['W EH1 S K AA0 T'],
  "weseloh": ['W EY0 S EY1 L OW0'],
  "wesely": ['W IY1 Z L IY0'],
  "weseman": ['W IY1 Z M AH0 N'],
  "wesemann": ['W IY1 Z M AH0 N'],
  "wesenberg": ['W IY1 Z AH0 N B ER0 G'],
  "wesker": ['W EH1 S K ER0'],
  "wesker's": ['W EH1 S K ER0 Z'],
  "wesler": ['W EH1 S AH0 L ER0', 'W EH1 S L ER0'],
  "wesley": ['W EH1 S L IY0'],
  "wesleyan": ['W EH1 Z L IY0 AH0 N'],
  "weslia": ['W EH1 S L IY0 AH0', 'HH W EH1 S L IY0 AH0'],
  "wesner": ['W EH1 S N ER0'],
  "wesolek": ['W EH1 S AH0 L IH0 K'],
  "wesolowski": ['V IH0 S AH0 L AO1 F S K IY0'],
  "wesp": ['W EH1 S P'],
  "wespac": ['W EH1 S P AE2 K'],
  "wespercorp": ['W EH1 S P ER0 K AO2 R P'],
  "wesray": ['W EH1 S R EY0'],
  "wess": ['W EH1 S'],
  "wessel": ['W EH1 S AH0 L'],
  "wessell": ['W EH1 S AH0 L'],
  "wessells": ['W EH1 S AH0 L Z'],
  "wesselman": ['W EH1 S AH0 L M AH0 N'],
  "wessels": ['W EH1 S AH0 L Z'],
  "wessex": ['W EH1 S AH0 K S'],
  "wessinger": ['W EH1 S IH0 N JH ER0'],
  "wessler": ['W EH1 S L ER0'],
  "wessling": ['W EH1 S AH0 L IH0 NG', 'W EH1 S L IH0 NG'],
  "wessman": ['W EH1 S M AH0 N'],
  "wessner": ['W EH1 S N ER0'],
  "wesson": ['W EH1 S AH0 N'],
  "west": ['W EH1 S T'],
  "west's": ['W EH1 S T S'],
  "westair": ['W EH1 S T EH1 R'],
  "westall": ['W EH1 S T AH0 L'],
  "westall's": ['W EH1 S T AH0 L Z'],
  "westamerica": ['W EH2 S T AH0 M EH1 R IH0 K AH0'],
  "westamerica's": ['W EH2 S T AH0 M EH1 R IH0 K AH0 Z'],
  "westar": ['W EH1 S T ER0'],
  "westbay": ['W EH1 S T B EY2'],
  "westberg": ['W EH1 S T B ER0 G'],
  "westberry": ['W EH1 S T B EH2 R IY0'],
  "westboro": ['W EH1 S T B ER0 OW0'],
  "westborough": ['W EH1 S T B ER0 OW0'],
  "westbound": ['W EH1 S T B AW2 N D'],
  "westbridge": ['W EH1 S T B R IH2 JH'],
  "westbrook": ['W EH1 S T B R UH2 K'],
  "westbrooks": ['W EH1 S T B R UH2 K S'],
  "westburne": ['W EH1 S T B ER0 N'],
  "westbury": ['W EH1 S T B EH2 R IY0'],
  "westby": ['W EH1 S T B IY0'],
  "westcap": ['W EH1 S T K AE2 P'],
  "westchester": ['W EH1 S T CH EH2 S T ER0'],
  "westchester's": ['W EH1 S T CH EH2 S T ER0 Z'],
  "westcoast": ['W EH1 S T K OW2 S T'],
  "westcoast's": ['W EH1 S T K OW2 S T S'],
  "westcorp": ['W EH1 S T K AO2 R P'],
  "westcott": ['W EH1 S T K AA2 T'],
  "westdeutsche": ['W EH1 S T D OY1 CH'],
  "westech": ['W EH1 S T EH0 K'],
  "westen": ['W EH1 S T AH0 N'],
  "westenberger": ['W EH1 S T AH0 N B ER0 G ER0'],
  "westendorf": ['W EH1 S T IH0 N D AO0 R F'],
  "wester": ['W EH1 S T ER0'],
  "westerbeck": ['W EH1 S T ER0 B EH2 K'],
  "westerberg": ['W EH1 S T ER0 B ER0 G'],
  "westerdam": ['W EH1 S T ER0 D AE2 M'],
  "westerdam's": ['W EH1 S T ER0 D AE2 M Z'],
  "westerfeld": ['W EH1 S T ER0 F EH2 L D'],
  "westerfield": ['W EH1 S T ER0 F IY2 L D'],
  "westergaard": ['W EH1 S T ER0 G AA2 R D'],
  "westergard": ['W EH1 S T ER0 G ER0 D'],
  "westergren": ['W EH1 S T ER0 G R EH0 N'],
  "westerhoff": ['W EH1 S T ER0 HH AO2 F'],
  "westerhold": ['W EH1 S T ER0 HH OW2 L D'],
  "westerlies": ['W EH1 S T ER0 L IY0 Z'],
  "westerlund": ['W EH1 S T ER0 L AH0 N D'],
  "westerly": ['W EH1 S T ER0 L IY0'],
  "westerman": ['W EH1 S T ER0 M AH0 N'],
  "westermann": ['W EH1 S T ER0 M AH0 N'],
  "westermeyer": ['W EH1 S T ER0 M AY0 ER0'],
  "western": ['W EH1 S T ER0 N', 'HH W EH1 S T ER0 N'],
  "western's": ['W EH1 S T ER0 N Z'],
  "westerner": ['W EH1 S T ER0 N ER0'],
  "westerners": ['W EH1 S T ER0 N ER0 Z'],
  "westernization": ['W EH2 S T ER0 N IH0 Z EY1 SH AH0 N'],
  "westernize": ['W EH1 S T ER0 N AY2 Z'],
  "westernized": ['W EH1 S T ER0 N AY2 Z D'],
  "westernmost": ['W EH1 S T ER0 N M OW2 S T'],
  "westerns": ['W EH1 S T ER0 N Z'],
  "westervelt": ['W EH1 S T ER0 V IH0 L T'],
  "westfaelisches": ['W EH1 S T F EY2 L IH0 SH IH0 Z'],
  "westfahl": ['W EH1 S T F AA2 L'],
  "westfall": ['W EH1 S T F AO2 L'],
  "westfed": ['W EH1 S T F EH2 D'],
  "westfield": ['W EH1 S T F IY0 L D'],
  "westford": ['W EH1 S T F ER0 D'],
  "westgate": ['W EH1 S T G EY2 T'],
  "westhampton": ['W EH2 S T HH AE1 M P T AH0 N'],
  "westheimer": ['W EH1 S T HH AY2 M ER0'],
  "westhoff": ['W EH1 S T HH AO2 F'],
  "westin": ['W EH1 S T IH0 N'],
  "westinghouse": ['W EH1 S T IH0 NG HH AW2 S'],
  "westinghouse's": ['W EH1 S T IH0 NG HH AW2 S IH0 Z'],
  "westlake": ['W EH1 S T L EY2 K'],
  "westland": ['W EH1 S T L AH0 N D'],
  "westleigh": ['W EH1 S T L AY0'],
  "westley": ['W EH1 S T L IY0'],
  "westlife": ['W EH1 S T L AY0 F'],
  "westlife's": ['W EH1 S T L AY0 F S'],
  "westling": ['W EH1 S T L IH0 NG'],
  "westlund": ['W EH1 S T L AH0 N D'],
  "westman": ['W EH1 S T M AH0 N'],
  "westmarc": ['W EH1 S T M AA2 R K'],
  "westmark": ['W EH1 S T M AA2 R K'],
  "westmark's": ['W EH1 S T M AA2 R K S'],
  "westmin": ['W EH1 S T M IH0 N'],
  "westminster": ['W EH2 S T M IH1 N S T ER0'],
  "westmoreland": ['W EH0 S T M AO1 R L AH0 N D'],
  "weston": ['W EH1 S T AH0 N'],
  "westover": ['W EH1 S T OW2 V ER0'],
  "westpac": ['W EH1 S T P AE2 K'],
  "westpac's": ['W EH1 S T P AE2 K S'],
  "westphal": ['W EH1 S T F AH0 L'],
  "westphalen": ['W EH1 S T F AH0 L AH0 N'],
  "westphalia": ['W EH1 S T F EY2 L IY0 AH0', 'W EH1 S T F EY2 L Y AH0'],
  "westport": ['W EH1 S T P AO2 R T'],
  "westpride": ['W EH1 S T P R AY2 D'],
  "westra": ['W EH1 S T R AH0'],
  "westrich": ['W EH1 S T R IH0 K'],
  "westrick": ['W EH1 S T R IH0 K'],
  "westridge": ['W EH1 S T R IH2 JH'],
  "westrom": ['W EH1 S T R AH0 M'],
  "westrum": ['W EH1 S T R AH0 M'],
  "westrup": ['W EH1 S T R AH0 P'],
  "westside": ['W EH1 S T S AY1 D'],
  "westtech": ['W EH1 S T EH2 K'],
  "westtech's": ['W EH1 S T EH2 K S'],
  "westvaco": ['W EH2 S T V AE1 K OW0'],
  "westward": ['W EH1 S T W ER0 D'],
  "westward-faring": ['W EH2 S T W ER0 D F EH1 R IY0 NG'],
  "westwards": ['W EH1 S T W ER0 D Z'],
  "westwood": ['W EH1 S T W UH2 D'],
  "westwood's": ['W EH1 S T W UH2 D Z'],
  "westworld": ['W EH1 S T W ER2 L D'],
  "wesun": ['W IY1 S AH2 N'],
  "wet": ['W EH1 T'],
  "wetherbee": ['W EH1 DH ER0 B IY2'],
  "wetherby": ['W EH1 TH ER0 B IY0'],
  "wetherell": ['W EH1 TH ER0 AH0 L'],
  "wetherill": ['W EH1 TH ER0 AH0 L'],
  "wetherington": ['W EH1 DH ER0 IH0 NG T AH0 N'],
  "wetherly": ['W EH1 DH ER0 L IY0'],
  "wethington": ['W EH1 TH IH0 NG T AH0 N'],
  "wetland": ['W EH1 T L AE2 N D'],
  "wetlands": ['W EH1 T L AE2 N D Z'],
  "wetlands'": ['W EH1 T L AE2 N D Z'],
  "wetmore": ['W EH1 T M AO0 R'],
  "wetness": ['W EH1 T N AH0 S'],
  "wetsel": ['W EH1 T S AH0 L'],
  "wetstein": ['W EH1 T S T IY2 N', 'W EH1 T S T AY2 N'],
  "wetsuit": ['W EH1 T S UW2 T'],
  "wettengel": ['W EH1 T IH0 NG G AH0 L'],
  "wetter": ['W EH1 T ER0'],
  "wetterau": ['W EH1 T ER0 AW0'],
  "wettergreen": ['W EH1 T ER0 G R IY2 N'],
  "wettest": ['W EH1 T AH0 S T'],
  "wetting": ['W EH1 T IH0 NG', 'HH W EH1 T IH0 NG'],
  "wettlaufer": ['W EH1 T L AW0 F ER0'],
  "wettstein": ['W EH1 T S T AY0 N', 'W EH1 T S T IY0 N'],
  "wetz": ['W EH1 T S'],
  "wetzel": ['W EH1 T Z AH0 L'],
  "wetzler": ['W EH1 T S L ER0'],
  "wetzstein": ['W EH1 T S T AY0 N', 'W EH1 T S T IY0 N'],
  "wever": ['W IY1 V ER0'],
  "wexford": ['W EH1 K S F ER0 D'],
  "wexford's": ['W EH1 K S F ER0 D Z'],
  "wexler": ['W EH1 K S L ER0'],
  "wexner": ['W EH1 K S N ER0'],
  "wey": ['W EY1'],
  "weyand": ['W EY1 AH0 N D'],
  "weyandt": ['W EY1 AH0 N T'],
  "weyant": ['W EY1 AH0 N T'],
  "weyenberg": ['W AY1 N B ER0 G'],
  "weyer": ['W EY1 ER0'],
  "weyerhaeuser": ['W EH1 R HH AW2 Z ER0'],
  "weyerhaeuser's": ['W EH2 R HH AW2 Z ER0 Z'],
  "weyers": ['W EY1 ER0 Z'],
  "weyforth": ['W EY1 F AO2 R TH'],
  "weygandt": ['W EY1 G AH0 N T'],
  "weyhrauch": ['W EY1 R AW2 K'],
  "weyl": ['W EY1 L'],
  "weyland": ['W EY1 L AH0 N D'],
  "weylin": ['W EY1 L IH0 N'],
  "weyman": ['W EY1 M AH0 N'],
  "weymer": ['W EY1 M ER0'],
  "weymouth": ['W EY1 M AH0 TH'],
  "weyrauch": ['W EH1 R AW0 K'],
  "weyrich": ['W EH1 R IH0 K'],
  "weyrick": ['W EY1 R IH0 K'],
  "whack": ['W AE1 K', 'HH W AE1 K'],
  "whacked": ['W AE1 K T', 'HH W AE1 K T'],
  "whacker": ['W AE1 K ER0', 'HH W AE1 K ER0'],
  "whacking": ['W AE1 K IH0 NG', 'HH W AE1 K IH0 NG'],
  "whacko": ['W AE1 K OW0'],
  "whackos": ['W AE1 K OW0 Z'],
  "whacks": ['W AE1 K S', 'HH W AE1 K S'],
  "whale": ['W EY1 L', 'HH W EY1 L'],
  "whale's": ['W EY1 L Z', 'HH W EY1 L Z'],
  "whalen": ['W EY1 L AH0 N', 'HH W EY1 L AH0 N'],
  "whaler": ['W EY1 L ER0', 'HH W EY1 L ER0'],
  "whalers": ['W EY1 L ER0 Z', 'HH W EY1 L ER0 Z'],
  "whales": ['W EY1 L Z', 'HH W EY1 L Z'],
  "whaley": ['W EY1 L IY0', 'HH W EY1 L IY0'],
  "whalin": ['W AE1 L IH0 N'],
  "whaling": ['W EY1 L IH0 NG', 'HH W EY1 L IH0 NG'],
  "whalley": ['W AE1 L IY0'],
  "wham": ['W AE1 M', 'HH W AE1 M'],
  "whammy": ['W AE1 M IY0', 'HH W AE1 M IY0'],
  "whampoa": ['W AE0 M P OW1 AH0'],
  "whan": ['W AE1 N', 'HH W AE1 N'],
  "whang": ['W AE1 NG', 'HH W AE1 NG'],
  "wharf": ['W AO1 R F', 'HH W AO1 R F'],
  "wharf's": ['W AO1 R F S', 'HH W AO1 R F S'],
  "wharff": ['W AA1 R F', 'HH W AA1 R F'],
  "wharfs": ['W AO1 R F S', 'HH W AO1 R F S'],
  "wharry": ['W AE1 R IY0', 'HH W AE1 R IY0'],
  "wharton": ['W AO1 R T AH0 N'],
  "wharton's": ['W AO1 R T AH0 N Z'],
  "what": ['W AH1 T', 'HH W AH1 T'],
  "what'd": ['W AH1 T IH0 D', 'HH W AH1 T IH0 D'],
  "what'll": ['W AH1 T AH0 L', 'HH W AH1 T AH0 L'],
  "what're": ['W AH1 T ER0', 'HH W AH1 T ER0'],
  "what's": ['W AH1 T S', 'HH W AH1 T S'],
  "what've": ['W AH1 T AH0 V'],
  "what-what": ['W AH1 T W AH1 T'],
  "whate": ['W EY1 T'],
  "whatever": ['W AH2 T EH1 V ER0', 'HH W AH2 T EH1 V ER0'],
  "whatever's": ['W AH2 T EH1 V ER0 Z', 'HH W AH2 T EH1 V ER0 Z'],
  "whatley": ['W AH1 T L IY0', 'HH W AH1 T L IY0'],
  "whatnot": ['W AH1 T N AA2 T', 'HH W AH1 T N AA2 T'],
  "whats": ['W AH0 T S', 'HH W AH0 T S'],
  "whatsapp": ['W AH2 T S AA1 P'],
  "whatsoever": ['W AH2 T S OW0 EH1 V ER0', 'HH W AH2 T S OW0 EH1 V ER0'],
  "whay-yu": ['W EY1 Y UW1', 'HH W EY1 Y UW1'],
  "wheat": ['W IY1 T', 'HH W IY1 T'],
  "wheaten": ['W IY1 T AH0 N', 'HH W IY1 T AH0 N'],
  "wheatie": ['W IY1 T IY0', 'HH W IY1 T IY0'],
  "wheaties": ['W IY1 T IY0 Z', 'HH W IY1 T IY0 Z'],
  "wheatley": ['W IY1 T L IY0', 'HH W IY1 T L IY0'],
  "wheatly": ['W IY1 T L IY0', 'HH W IY1 T L IY0'],
  "wheaton": ['W IY1 T AH0 N', 'HH W IY1 T AH0 N'],
  "whedbee": ['W EH1 D B IY2', 'HH W EH1 D B IY2'],
  "whedon": ['W EH1 D AH0 N', 'HH W EH1 D AH0 N'],
  "wheel": ['W IY1 L', 'HH W IY1 L'],
  "wheelabrator": ['W IY2 L AH0 B R EY1 T ER0', 'HH W IY2 L AH0 B R EY1 T ER0'],
  "wheelan": ['W IY1 L AH0 N', 'HH W IY1 L AH0 N'],
  "wheeland": ['W IY1 L AH0 N D', 'HH W IY1 L AH0 N D'],
  "wheelbarrow": ['W IY1 L B EH2 R OW0', 'HH W IY1 L B EH2 R OW0'],
  "wheelbarrows": ['W IY1 L B EH2 R OW0 Z', 'HH W IY1 L B EH2 R OW0 Z'],
  "wheelbase": ['W IY1 L B EY2 S', 'HH W IY1 L B EY2 S'],
  "wheelchair": ['W IY1 L CH EH2 R', 'HH W IY1 L CH EH2 R'],
  "wheelchairs": ['W IY1 L CH EH2 R Z', 'HH W IY1 L CH EH2 R Z'],
  "wheeldon": ['W IY1 L D AH0 N', 'HH W IY1 L D AH0 N'],
  "wheeled": ['W IY1 L D', 'HH W IY1 L D'],
  "wheeler": ['W IY1 L ER0', 'HH W IY1 L ER0'],
  "wheeler's": ['W IY1 L ER0 Z', 'HH W IY1 L ER0 Z'],
  "wheelers": ['W IY1 L ER0 Z', 'HH W IY1 L ER0 Z'],
  "wheeless": ['W IY1 L AH0 S', 'HH W IY1 L AH0 S'],
  "wheeling": ['W IY1 L IH0 NG', 'HH W IY1 L IH0 NG'],
  "wheeling's": ['W IY1 L IH0 NG Z', 'HH W IY1 L IH0 NG Z'],
  "wheelis": ['W IY1 L IH0 S', 'HH W IY1 L IH0 S'],
  "wheelock": ['W IY1 L AA2 K', 'HH W IY1 L AA2 K'],
  "wheelon": ['W IY1 L AH0 N', 'HH W IY1 L AH0 N'],
  "wheels": ['W IY1 L Z', 'HH W IY1 L Z'],
  "wheelus": ['W IY1 L AH0 S', 'HH W IY1 L AH0 S'],
  "wheelwright": ['W IY1 L R AY2 T', 'HH W IY1 L R AY2 T'],
  "wheelwriter": ['W IY1 L R AY2 T ER0', 'HH W IY1 L R AY2 T ER0'],
  "wheeze": ['W IY1 Z', 'HH W IY1 Z'],
  "wheezes": ['W IY1 Z AH0 Z', 'HH W IY1 Z AH0 Z', 'W IY1 Z IH0 Z'],
  "wheezing": ['W IY1 Z IH0 NG', 'HH W IY1 Z IH0 NG'],
  "whelan": ['W EH1 L AH0 N', 'HH W EH1 L AH0 N', 'HH W IY1 L AH0 N', 'W IY1 L AH0 N'],
  "whelchel": ['W EH1 L CH AH0 L', 'HH W EH1 L CH AH0 L'],
  "wheless": ['W IY1 L IH0 S', 'HH W IY1 L IH0 S'],
  "whelpley": ['W EH1 L P L IY0', 'HH W EH1 L P L IY0'],
  "whelton": ['W EH1 L T AH0 N', 'HH W EH1 L T AH0 N'],
  "when": ['W EH1 N', 'HH W EH1 N', 'W IH1 N', 'HH W IH1 N'],
  "when'll": ['W EH1 N AH0 L', 'HH W EH1 N AH0 L'],
  "when's": ['W EH1 N Z', 'HH W EH1 N Z'],
  "whence": ['W EH1 N S', 'HH W EH1 N S'],
  "whenever": ['W EH0 N EH1 V ER0', 'HH W EH0 N EH1 V ER0'],
  "where": ['W EH1 R', 'HH W EH1 R'],
  "where'd": ['W EH1 R D', 'HH W EH1 R D'],
  "where're": ['W EH1 R ER0'],
  "where's": ['W EH1 R Z', 'HH W EH1 R Z'],
  "where've": ['W EH1 R AH0 V'],
  "whereabouts": ['W EH1 R AH0 B AW2 T S', 'HH W EH1 R AH0 B AW2 T S'],
  "whereas": ['W EH0 R AE1 Z', 'HH W EH0 R AE1 Z'],
  "whereby": ['W EH0 R B AY1', 'HH W EH0 R B AY1'],
  "wherefore": ['W EH0 R F AO1 R'],
  "wherein": ['W EH0 R IH1 N', 'HH W EH0 R IH1 N'],
  "whereupon": ['W EH1 R AH0 P AA1 N', 'HH W EH1 R AH0 P AA1 N'],
  "wherever": ['W EH0 R EH1 V ER0', 'HH W EH0 R EH1 V ER0'],
  "wherewithal": ['W EH1 R W IH0 DH AO2 L', 'HH W EH1 R W IH0 DH AO2 L'],
  "wherley": ['W ER1 L IY0', 'HH W ER1 L IY0'],
  "wherry": ['W EH1 R IY0', 'HH W EH1 R IY0'],
  "whet": ['W EH1 T', 'HH W EH1 T'],
  "whether": ['W EH1 DH ER0', 'HH W EH1 DH ER0'],
  "whetsel": ['W EH1 T S AH0 L', 'HH W EH1 T S AH0 L'],
  "whetsell": ['W EH1 T S AH0 L', 'HH W EH1 T S AH0 L'],
  "whetstine": ['W EH1 T S T IY0 N', 'HH W EH1 T S T IY0 N'],
  "whetstone": ['W EH1 T S T OW2 N', 'HH W EH1 T S T OW2 N'],
  "whetted": ['W EH1 T IH0 D', 'HH W EH1 T IH0 D'],
  "whetzel": ['W EH1 T Z AH0 L', 'HH W EH1 T Z AH0 L'],
  "whew": ['W UW1', 'HH W UW1', 'HH Y UW1'],
  "whey": ['W EY1', 'HH W EY1'],
  "which": ['W IH1 CH', 'HH W IH1 CH'],
  "which're": ['W IH1 CH ER0', 'HH W IH1 CH ER0'],
  "which's": ['W IH1 CH IH0 Z', 'HH W IH1 CH IH0 Z'],
  "whichard": ['W IH1 CH ER0 D', 'HH W IH1 CH ER0 D'],
  "whichever": ['W IH0 CH EH1 V ER0', 'HH W IH0 CH EH1 V ER0'],
  "whicker": ['W IH1 K ER0', 'HH W IH1 K ER0'],
  "whidby": ['W IH1 D B IY0', 'HH W IH1 D B IY0'],
  "whidden": ['W IH1 D AH0 N', 'HH W IH1 D AH0 N'],
  "whiddon": ['W IH1 D AH0 N', 'HH W IH1 D AH0 N'],
  "whiff": ['W IH1 F', 'HH W IH1 F'],
  "whig": ['W IH1 G', 'HH W IH1 G'],
  "whigham": ['W IH1 G AH0 M', 'HH W IH1 G AH0 M'],
  "whigs": ['W IH1 G Z', 'HH W IH1 G Z'],
  "whilden": ['W AY1 L D AH0 N', 'HH W AY1 L D AH0 N'],
  "while": ['W AY1 L', 'HH W AY1 L'],
  "whiles": ['W AY1 L Z', 'HH W AY1 L Z'],
  "whilst": ['W AY1 L S T'],
  "whim": ['W IH1 M', 'HH W IH1 M'],
  "whimper": ['W IH1 M P ER0', 'HH W IH1 M P ER0'],
  "whimpering": ['W IH1 M P ER0 IH0 NG', 'HH W IH1 M P ER0 IH0 NG'],
  "whims": ['W IH1 M Z', 'HH W IH1 M Z'],
  "whimsical": ['W IH1 M Z IH0 K AH0 L', 'HH W IH1 M Z IH0 K AH0 L'],
  "whimsically": ['W IH1 M Z IH0 K L IY0', 'HH W IH1 M Z IH0 K L IY0'],
  "whimsy": ['W IH1 M S IY0', 'HH W IH1 M S IY0'],
  "whine": ['W AY1 N', 'HH W AY1 N'],
  "whiner": ['W AY1 N ER0', 'HH W AY1 N ER0'],
  "whiners": ['W AY1 N ER0 Z', 'HH W AY1 N ER0 Z'],
  "whinery": ['W AY1 N ER0 IY0', 'HH W AY1 N ER0 IY0'],
  "whines": ['W AY1 N Z', 'HH W AY1 N Z'],
  "whining": ['W AY1 N IH0 NG', 'HH W AY1 N IH0 NG'],
  "whinnery": ['W IH1 N ER0 IY0', 'HH W IH1 N ER0 IY0'],
  "whinney": ['W IH1 N IY0', 'HH W IH1 N IY0'],
  "whiny": ['W AY1 N IY0', 'HH W AY1 N IY0'],
  "whip": ['W IH1 P', 'HH W IH1 P'],
  "whipkey": ['W IH1 P K IY2', 'HH W IH1 P K IY2'],
  "whiplash": ['W IH1 P L AE2 SH', 'HH W IH1 P L AE2 SH'],
  "whiplashes": ['W IH1 P L AE2 SH AH0 Z', 'HH W IH1 P L AE2 SH AH0 Z'],
  "whipp": ['W IH1 P', 'HH W IH1 P'],
  "whippany": ['W IH1 P AH0 N IY0', 'HH W IH1 P AH0 N IY0'],
  "whipped": ['W IH1 P T', 'HH W IH1 P T'],
  "whipping": ['W IH1 P IH0 NG', 'HH W IH1 P IH0 NG'],
  "whipple": ['W IH1 P AH0 L', 'HH W IH1 P AH0 L'],
  "whipple's": ['W IH1 P AH0 L Z', 'HH W IH1 P AH0 L Z'],
  "whippoorwills": ['W IH1 P ER0 W IH2 L Z', 'HH W IH1 P ER0 W IH2 L Z'],
  "whipps": ['W IH1 P S', 'HH W IH1 P S'],
  "whips": ['W IH1 P S', 'HH W IH1 P S'],
  "whipsaw": ['W IH1 P S AO2', 'HH W IH1 P S AO2'],
  "whipsawed": ['W IH1 P S AO2 D', 'HH W IH1 P S AO2 D'],
  "whipsawing": ['W IH1 P S AO2 IH0 NG', 'HH W IH1 P S AO2 IH0 NG'],
  "whirl": ['W ER1 L', 'HH W ER1 L'],
  "whirled": ['W ER1 L D', 'HH W ER1 L D'],
  "whirley": ['W ER1 L IY0', 'HH W ER1 L IY0'],
  "whirling": ['W ER1 L IH0 NG', 'HH W ER1 L IH0 NG'],
  "whirlpool": ['W ER1 L P UW2 L', 'HH W ER1 L P UW2 L'],
  "whirlpool's": ['W ER1 L P UW2 L Z', 'HH W ER1 L P UW2 L Z'],
  "whirlpools": ['W ER1 L P UW2 L Z', 'HH W ER1 L P UW2 L Z'],
  "whirlwind": ['W ER1 L W IH2 N D', 'HH W ER1 L W IH2 N D'],
  "whirring": ['W ER1 IH0 NG', 'HH W ER1 IH0 NG'],
  "whisenand": ['W IH1 S IH0 N AE0 N D', 'HH W IH1 S IH0 N AE0 N D'],
  "whisenant": ['W IH1 S IH0 N AH0 N T', 'HH W IH1 S IH0 N AH0 N T'],
  "whisenhunt": ['W AY1 Z AH0 N HH AH2 N T', 'HH W AY1 Z AH0 N HH AH2 N T'],
  "whisk": ['W IH1 S K', 'HH W IH1 S K'],
  "whisked": ['W IH1 S K T', 'HH W IH1 S K T'],
  "whisker": ['W IH1 S K ER0', 'HH W IH1 S K ER0'],
  "whiskered": ['W IH1 S K ER0 D'],
  "whiskers": ['W IH1 S K ER0 Z', 'HH W IH1 S K ER0 Z'],
  "whiskey": ['W IH1 S K IY0', 'HH W IH1 S K IY0'],
  "whiskeys": ['W IH1 S K IY0 Z', 'HH W IH1 S K IY0 Z'],
  "whisks": ['W IH1 S K S', 'HH W IH1 S K S'],
  "whisky": ['W IH1 S K IY0', 'HH W IH1 S K IY0'],
  "whisler": ['W IH1 S L ER0', 'HH W IH1 S L ER0'],
  "whisman": ['W IH1 S M AH0 N', 'HH W IH1 S M AH0 N'],
  "whisnant": ['W IH1 S N AH0 N T', 'HH W IH1 S N AH0 N T'],
  "whisner": ['W IH1 S N ER0', 'HH W IH1 S N ER0'],
  "whisonant": ['W IH1 S AH0 N AH0 N T'],
  "whisper": ['W IH1 S P ER0', 'HH W IH1 S P ER0'],
  "whispered": ['W IH1 S P ER0 D', 'HH W IH1 S P ER0 D'],
  "whisperer": ['W IH1 S P AH0 R ER2'],
  "whisperer's": ['W IH1 S P AH0 R ER2 Z'],
  "whisperers": ['W IH1 S P AH0 R ER2 Z'],
  "whispering": ['W IH1 S P ER0 IH0 NG', 'HH W IH1 S P ER0 IH0 NG'],
  "whispers": ['W IH1 S P ER0 Z', 'HH W IH1 S P ER0 Z'],
  "whistle": ['W IH1 S AH0 L', 'HH W IH1 S AH0 L'],
  "whistleblower": ['W IH1 S AH0 L B L OW2 ER0', 'HH W IH1 S AH0 L B L OW2 ER0'],
  "whistleblowers": ['W IH1 S AH0 L B L OW2 ER0 Z', 'HH W IH1 S AH0 L B L OW2 ER0 Z'],
  "whistled": ['W IH1 S AH0 L D', 'HH W IH1 S AH0 L D'],
  "whistler": ['W IH1 S L ER0', 'HH W IH1 S L ER0'],
  "whistlers": ['W IH1 S L ER0 Z', 'HH W IH1 S L ER0 Z'],
  "whistles": ['W IH1 S AH0 L Z', 'HH W IH1 S AH0 L Z'],
  "whistling": ['W IH1 S L IH0 NG', 'HH W IH1 S L IH0 NG'],
  "whiston": ['W IH1 S T AH0 N', 'HH W IH1 S T AH0 N'],
  "whit": ['W IH1 T', 'HH W IH1 T'],
  "whitacre": ['W IH1 T AH0 K ER0', 'HH W IH1 T AH0 K ER0'],
  "whitaker": ['W IH1 T AH0 K ER0', 'HH W IH1 T AH0 K ER0'],
  "whitbeck": ['W IH1 T B EH2 K', 'HH W IH1 T B EH2 K'],
  "whitbread": ['W IH1 T B R EH2 D', 'HH W IH1 T B R EH2 D'],
  "whitby": ['W IH1 T B IY0', 'HH W IH1 T B IY0'],
  "whitcher": ['W IH1 CH ER0', 'HH W IH1 CH ER0'],
  "whitchurch": ['W IH1 T CH ER2 CH', 'HH W IH1 T CH ER2 CH'],
  "whitcomb": ['W IH1 T K AH0 M', 'HH W IH1 T K AH0 M'],
  "whitcraft": ['W IH1 T K R AE2 F T', 'HH W IH1 T K R AE2 F T'],
  "white": ['W AY1 T', 'HH W AY1 T'],
  "white's": ['W AY1 T S', 'HH W AY1 T S'],
  "whiteaker": ['W IH1 T AH0 K ER0', 'HH W IH1 T AH0 K ER0'],
  "whitebread": ['W AY1 T B R EH2 D', 'HH W AY1 T B R EH2 D'],
  "whitecotton": ['W AY1 T K AA2 T AH0 N', 'HH W AY1 T K AA2 T AH0 N'],
  "whited": ['W AY1 T IH0 D', 'HH W AY1 T IH0 D'],
  "whitefield": ['W AY1 T F IY2 L D', 'HH W AY1 T F IY2 L D'],
  "whitefish": ['W AY1 T F IH2 SH', 'HH W AY1 T F IH2 SH'],
  "whiteford": ['W AY1 T F AO0 R D', 'HH W AY1 T F AO0 R D'],
  "whitehair": ['W AY1 T HH EH1 R', 'HH W AY1 T HH EH1 R'],
  "whitehall": ['W AY1 T HH AO2 L', 'HH W AY1 T HH AO2 L'],
  "whitehall's": ['W AY1 T HH AO2 L Z', 'HH W AY1 T HH AO2 L Z'],
  "whitehead": ['W AY1 T HH EH2 D', 'HH W AY1 T HH EH2 D'],
  "whitehead's": ['W AY1 T HH EH2 D Z', 'HH W AY1 T HH EH2 D Z'],
  "whitehill": ['W AY1 T HH IH2 L', 'HH W AY1 T HH IH2 L'],
  "whitehorn": ['W AY1 T HH AO2 R N', 'HH W AY1 T HH AO2 R N'],
  "whitehorse": ['W AY1 T HH AO2 R S', 'HH W AY1 T HH AO2 R S'],
  "whitehouse": ['W AY1 T HH AW2 S', 'HH W AY1 T HH AW2 S'],
  "whitehurst": ['W AY1 T HH ER2 S T', 'HH W AY1 T HH ER2 S T'],
  "whitehurst's": ['W AY1 T HH ER2 S T S'],
  "whitelaw": ['W AY1 T L AO2', 'HH W AY1 T L AO2'],
  "whiteley": ['W AY1 T L IY0', 'HH W AY1 T L IY0'],
  "whitelock": ['W AY1 T L AA2 K', 'HH W AY1 T L AA2 K'],
  "whitely": ['W AY1 T L IY0', 'HH W AY1 T L IY0'],
  "whiteman": ['W AY1 T M AH0 N', 'HH W AY1 T M AH0 N'],
  "whitemont": ['W AY1 T M AA2 N T', 'HH W AY1 T M AA2 N T'],
  "whiten": ['W AY1 T AH0 N', 'HH W AY1 T AH0 N'],
  "whitenack": ['W AY1 T N AE2 K', 'HH W AY1 T N AE2 K'],
  "whiteneir": ['W AY1 T N IH2 R', 'HH W AY1 T N IH2 R'],
  "whitener": ['W AY1 T N ER0', 'HH W AY1 T N ER0'],
  "whiteness": ['W AY1 T N AH0 S', 'HH W AY1 T N AH0 S'],
  "whitenight": ['W AY1 T N AY2 T', 'HH W AY1 T N AY2 T'],
  "whitening": ['W AY1 T IH0 N IH0 NG', 'W AY1 T N IH0 NG', 'HH W AY1 T IH0 N IH0 NG', 'HH W AY1 T N IH0 NG'],
  "whiter": ['W AY1 T ER0', 'HH W AY1 T ER0'],
  "whites": ['W AY1 T S', 'HH W AY1 T S'],
  "whitescarver": ['W AY1 T S K AA2 R V ER0', 'HH W AY1 T S K AA2 R V ER0'],
  "whitesel": ['W AY1 T S EH2 L', 'HH W AY1 T S EH2 L'],
  "whitesell": ['W AY1 T S EH2 L', 'HH W AY1 T S EH2 L'],
  "whiteside": ['W AY1 T S AY2 D', 'HH W AY1 T S AY2 D'],
  "whitesides": ['W AY1 T S AY2 D Z', 'HH W AY1 T S AY2 D Z'],
  "whitest": ['W AY1 T IH0 S T', 'HH W AY1 T IH0 S T'],
  "whitestone": ['W AY1 T S T OW2 N', 'HH W AY1 T S T OW2 N'],
  "whitetail": ['W AY1 T T EY2 L', 'HH W AY1 T T EY2 L', 'HH W AY1 T EY2 L'],
  "whitewash": ['W AY1 T W AA2 SH', 'HH W AY1 T W AA2 SH'],
  "whitewashed": ['W AY1 T W AA2 SH T', 'HH W AY1 T W AA2 SH T'],
  "whitewater": ['W AY1 T W AO2 T ER0', 'HH W AY1 T W AO2 T ER0'],
  "whitewater's": ['W AY1 T W AO2 T ER0 Z', 'HH W AY1 T W AO2 T ER0 Z'],
  "whitey": ['W AY1 T IY2', 'HH W AY1 T IY2', 'HH W AY1 T IY0'],
  "whitfield": ['W IH1 T F IY0 L D', 'HH W IH1 T F IY0 L D'],
  "whitfill": ['W IH1 T F IH2 L', 'HH W IH1 T F IH2 L'],
  "whitford": ['W IH1 T F ER0 D', 'HH W IH1 T F ER0 D'],
  "whitham": ['W IH1 TH AH0 M', 'HH W IH1 TH AH0 M', 'HH W IH1 T AH0 M'],
  "whither": ['W IH1 DH ER0', 'HH W IH1 DH ER0'],
  "whiting": ['W AY1 T IH0 NG', 'HH W AY1 T IH0 NG'],
  "whitings": ['W AY1 T IH0 NG Z', 'HH W AY1 T IH0 NG Z'],
  "whitis": ['W AY1 T IH0 S', 'HH W AY1 T IH0 S'],
  "whitish": ['W AY1 T IH0 SH', 'HH W AY1 T IH0 SH'],
  "whitlatch": ['W IH1 T L AE2 CH', 'HH W IH1 T L AE2 CH'],
  "whitledge": ['W IH1 T L EH2 JH', 'HH W IH1 T L EH2 JH'],
  "whitley": ['W IH1 T L IY0', 'HH W IH1 T L IY0'],
  "whitling": ['W IH1 T L IH0 NG', 'HH W IH1 T L IH0 NG'],
  "whitlock": ['W IH1 T L AA2 K', 'HH W IH1 T L AA2 K'],
  "whitlow": ['W IH1 T L OW2', 'HH W IH1 T L OW2'],
  "whitly": ['W IH1 T L IY0', 'HH W IH1 T L IY0'],
  "whitman": ['W IH1 T M AH0 N', 'HH W IH1 T M AH0 N'],
  "whitman's": ['W IH1 T M AH0 N Z', 'HH W IH1 T M AH0 N Z'],
  "whitmarsh": ['W IH1 T M AA2 R SH', 'HH W IH1 T M AA2 R SH'],
  "whitmer": ['W IH1 T M ER0', 'HH W IH1 T M ER0'],
  "whitmill": ['W IH1 T M IH2 L', 'HH W IH1 T M IH2 L'],
  "whitmire": ['W IH1 T M AY2 R', 'HH W IH1 T M AY2 R'],
  "whitmore": ['W IH1 T M AO0 R', 'HH W IH1 T M AO0 R'],
  "whitmoyer": ['W IH1 T M OY2 ER0', 'HH W IH1 T M OY2 ER0'],
  "whitmyer": ['W IH1 T M AY2 ER0', 'HH W IH1 T M AY2 ER0'],
  "whitner": ['W IH1 T N ER0', 'HH W IH1 T N ER0'],
  "whitney": ['W IH1 T N IY0', 'HH W IH1 T N IY0'],
  "whitney's": ['W IH1 T N IY0 Z', 'HH W IH1 T N IY0 Z'],
  "whiton": ['W IH1 T AH0 N', 'HH W IH1 T AH0 N'],
  "whitrow": ['W IH1 T R OW2', 'HH W IH1 T R OW2'],
  "whitsel": ['W IH1 T S AH0 L', 'HH W IH1 T S AH0 L'],
  "whitsell": ['W IH1 T S AH0 L', 'HH W IH1 T S AH0 L'],
  "whitsett": ['W IH1 T S IH0 T', 'HH W IH1 T S IH0 T'],
  "whitsitt": ['W IH1 T S IH0 T', 'HH W IH1 T S IH0 T'],
  "whitson": ['W IH1 T S AH0 N', 'HH W IH1 T S AH0 N'],
  "whitt": ['W IH1 T', 'HH W IH1 T'],
  "whittaker": ['W IH1 T AH0 K ER0', 'HH W IH1 T AH0 K ER0'],
  "whitted": ['W IH1 T IH0 D', 'HH W IH1 T IH0 D'],
  "whittemore": ['W IH1 T M AO0 R', 'HH W IH1 T M AO0 R'],
  "whitten": ['W IH1 T AH0 N', 'HH W IH1 T AH0 N'],
  "whittenberg": ['W IH1 T AH0 N B ER0 G', 'HH W IH1 T AH0 N B ER0 G'],
  "whittenburg": ['W IH1 T AH0 N B ER0 G', 'HH W IH1 T AH0 N B ER0 G'],
  "whittet": ['W IH1 T IH0 T', 'HH W IH1 T IH0 T'],
  "whittier": ['W IH1 T IY0 ER0', 'HH W IH1 T IY0 ER0'],
  "whitting": ['W IH1 T IH0 NG', 'HH W IH1 T IH0 NG'],
  "whittingham": ['W IH1 T IH0 NG HH AE2 M', 'HH W IH1 T IH0 NG HH AE2 M'],
  "whittinghill": ['W IH1 T IH0 NG HH IH2 L', 'HH W IH1 T IH0 NG HH IH2 L'],
  "whittington": ['W IH1 T IH0 NG T AH0 N', 'HH W IH1 T IH0 NG T AH0 N'],
  "whittle": ['W IH1 T AH0 L', 'HH W IH1 T AH0 L'],
  "whittle's": ['W IH1 T AH0 L Z', 'HH W IH1 T AH0 L Z'],
  "whittled": ['W IH1 T AH0 L D', 'HH W IH1 T AH0 L D'],
  "whittlesey": ['W IH1 T AH0 L S IY0', 'HH W IH1 T AH0 L S IY0'],
  "whittling": ['W IH1 T L IH0 NG', 'HH W IH1 T L IH0 NG'],
  "whitton": ['W IH1 T AH0 N', 'HH W IH1 T AH0 N'],
  "whitty": ['W IH1 T IY0', 'HH W IH1 T IY0'],
  "whitwam": ['W IH1 T W AA0 M', 'HH W IH1 T W AA0 M'],
  "whitwell": ['W IH1 T W EH2 L', 'HH W IH1 T W EH2 L'],
  "whitworth": ['W IH1 T W ER2 TH', 'HH W IH1 T W ER2 TH'],
  "whiz": ['W IH1 Z', 'HH W IH1 Z'],
  "whiz-zip-bang": ['W IH1 Z Z IH1 P B AE1 NG'],
  "whizzed": ['W IH1 Z D', 'HH W IH1 Z D'],
  "whizzer": ['W IH1 Z ER0', 'HH W IH1 Z ER0'],
  "whizzes": ['W IH1 Z IH0 Z', 'HH W IH1 Z IH0 Z'],
  "whizzing": ['W IH1 Z IH0 NG', 'HH W IH1 Z IH0 NG'],
  "who": ['HH UW1'],
  "who'd": ['HH UW1 D'],
  "who'll": ['HH UW1 L'],
  "who're": ['HH UW1 ER0'],
  "who's": ['HH UW1 Z'],
  "who've": ['HH UW0 V'],
  "whoa": ['W OW1', 'HH W OW1', 'HH OW1'],
  "whobrey": ['W AA1 B R IY0'],
  "whodunit": ['HH UW0 D AH1 N IH0 T'],
  "whodunits": ['HH UW0 D AH1 N IH0 T S'],
  "whoever": ['HH UW0 EH1 V ER0'],
  "whoever's": ['HH UW0 EH1 V ER0 Z'],
  "whole": ['HH OW1 L'],
  "wholehearted": ['HH OW1 L HH AA2 R T IH0 D'],
  "wholeheartedly": ['HH OW1 L HH AA0 R T IH0 D L IY0'],
  "wholeness": ['HH OW1 L N IH0 S'],
  "wholes": ['HH OW1 L Z'],
  "wholesale": ['HH OW1 L S EY2 L'],
  "wholesaler": ['HH OW1 L S EY2 L ER0'],
  "wholesaler's": ['HH OW1 L S EY2 L ER0 Z'],
  "wholesalers": ['HH OW1 L S EY2 L ER0 Z'],
  "wholesalers'": ['HH OW1 L S EY2 L ER0 Z'],
  "wholesales": ['HH OW1 L S EY2 L Z'],
  "wholesaling": ['HH OW1 L S EY2 L IH0 NG'],
  "wholesome": ['HH OW1 L S AH0 M'],
  "wholesomeness": ['HH OW1 L S AH0 M N AH0 S'],
  "wholey": ['HH AA1 L IY0'],
  "wholly": ['HH OW1 L IY0'],
  "whom": ['HH UW1 M'],
  "whomever": ['HH UW0 M EH1 V ER0'],
  "whomsoever": ['HH UW2 M S OW0 EH1 V ER0'],
  "whoo": ['W UW1'],
  "whoop": ['W UW1 P', 'HH W UW1 P'],
  "whoopdedoo": ['W UH1 P D IY0 D UW0', 'HH W UH1 P D IY0 D UW0'],
  "whooped": ['W UW1 P T', 'HH W UW1 P T'],
  "whoopee": ['W UW1 P IY1', 'HH W UW1 P IY1'],
  "whoopi": ['W UW1 P IY0', 'HH W UW1 P IY0'],
  "whoopie": ['W UW1 P IY0', 'HH W UW1 P IY0'],
  "whooping": ['W UW1 P IH0 NG', 'HH W UW1 P IH0 NG'],
  "whoops": ['W UW1 P S', 'HH W UW1 P S'],
  "whoosh": ['W UW1 SH', 'HH W UW1 SH'],
  "whopper": ['W AA1 P ER0', 'HH W AA1 P ER0'],
  "whoppers": ['W AA1 P ER0 Z', 'HH W AA1 P ER0 Z'],
  "whopping": ['W AA1 P IH0 NG', 'HH W AA1 P IH0 NG'],
  "whore": ['HH AO1 R'],
  "whorehouse": ['HH AO1 R HH AW2 S'],
  "whores": ['HH AO1 R Z'],
  "whorl": ['W ER1 L', 'W AO1 R L', 'HH W AO1 R L'],
  "whorley": ['HH AO1 R L IY0', 'W AO1 R L IY0', 'HH W AO1 R L IY0'],
  "whorls": ['W ER1 L Z', 'W AO1 R L Z', 'HH W AO1 R L Z'],
  "whorton": ['HH AO1 R T AH0 N'],
  "whose": ['HH UW1 Z'],
  "whosoever": ['HH UW2 S OW0 EH1 V ER0'],
  "why": ['W AY1', 'HH W AY1'],
  "why'd": ['W AY1 D', 'HH W AY1 D'],
  "why're": ['W AY1 ER0'],
  "why's": ['W AY1 Z', 'HH W AY1 Z'],
  "whys": ['W AY1 Z', 'HH W AY1 Z'],
  "whyte": ['W AY1 T', 'HH W AY1 T'],
  "wi-fi": ['W AY1 F AY2'],
  "wiacek": ['V AY1 AH0 CH EH2 K'],
  "wian": ['W AY1 AH0 N'],
  "wiant": ['W AY1 AH0 N T'],
  "wiard": ['W AY1 AA0 R D'],
  "wiater": ['W AY1 AH0 T ER0'],
  "wiatrowski": ['V IY0 AH0 T R AO1 F S K IY2'],
  "wiatt": ['W AY1 AH0 T'],
  "wibbelsman": ['W IH1 B AH0 L Z M AH0 N'],
  "wibbenmeyer": ['W IH1 B IH0 N M AY2 ER0'],
  "wiberg": ['W AY1 B ER0 G'],
  "wible": ['W AY1 B AH0 L'],
  "wicat": ['W IH1 K AE0 T'],
  "wicca": ['W IH1 K AH0'],
  "wiccan": ['W IH1 K AH0 N'],
  "wice": ['W AY1 S'],
  "wich": ['W IH1 CH'],
  "wichern": ['W IH1 K ER0 N'],
  "wichert": ['W IH1 CH ER0 T'],
  "wichita": ['W IH1 CH AH0 T AO2'],
  "wichita's": ['W IH1 CH AH0 T AO2 Z'],
  "wichman": ['W IH1 CH M AH0 N'],
  "wichmann": ['W IH1 CH M AH0 N'],
  "wick": ['W IH1 K'],
  "wickard": ['W IH1 K ER0 D'],
  "wicke": ['W IH1 K'],
  "wicked": ['W IH1 K AH0 D'],
  "wickedly": ['W IH1 K IH0 D L IY0'],
  "wickedness": ['W IH1 K AH0 D N AH0 S'],
  "wickens": ['W IH1 K AH0 N Z'],
  "wicker": ['W IH1 K ER0'],
  "wicker's": ['W IH1 K ER0 Z'],
  "wickerham": ['W IH1 K ER0 HH AE2 M'],
  "wickers": ['W IH1 K ER0 Z'],
  "wickersham": ['W IH1 K ER0 SH AE2 M'],
  "wickert": ['W IH1 K ER0 T'],
  "wickes": ['W IH1 K S'],
  "wickes's": ['W IH1 K S IH0 Z'],
  "wicket": ['W IH1 K AH0 T', 'HH W IH1 K AH0 T'],
  "wickets": ['W IH1 K AH0 T S', 'HH W IH1 K AH0 T S'],
  "wickett": ['W IH1 K IH0 T'],
  "wickey": ['W IH1 K IY0'],
  "wickham": ['W IH1 K AH0 M'],
  "wickizer": ['W IH1 K AY2 Z ER0'],
  "wickland": ['W IH1 K L AH0 N D'],
  "wicklander": ['W IH1 K L AE2 N D ER0'],
  "wickley": ['W IH1 K L IY0'],
  "wickliff": ['W IH1 K L IH0 F'],
  "wickliffe": ['W IH1 K L IH0 F'],
  "wickline": ['W IH1 K L AY2 N'],
  "wicklund": ['W IH1 K L AH0 N D'],
  "wickman": ['W IH1 K M AH0 N'],
  "wicks": ['W IH1 K S'],
  "wickstrom": ['W IH1 K S T R AH0 M'],
  "wickware": ['W IH1 K W EH2 R'],
  "wickwire": ['V IH1 S K V AY0 R'],
  "wicomico": ['W AY2 K AA1 M IH0 K OW2'],
  "wicor": ['W AY1 K AO2 R'],
  "widder": ['W IH1 D ER0'],
  "widdle": ['W IH1 D AH0 L'],
  "widdled": ['W IH1 D AH0 L D'],
  "widdly": ['W IH1 D L IY0'],
  "widdows": ['W IH1 D OW2 Z'],
  "widdowson": ['W IH1 D AW0 S AH0 N'],
  "wide": ['W AY1 D'],
  "wide's": ['W AY1 D Z'],
  "widebody": ['W AY1 D B AA2 D IY0'],
  "widell": ['W IH1 D AH0 L'],
  "widely": ['W AY1 D L IY0'],
  "wideman": ['W AY1 D M AH0 N'],
  "widen": ['W AY1 D AH0 N'],
  "widened": ['W AY1 D AH0 N D'],
  "widener": ['W IH1 D IY0 N ER0'],
  "widening": ['W AY1 D AH0 N IH0 NG'],
  "widens": ['W AY1 D AH0 N Z'],
  "wider": ['W AY1 D ER0'],
  "widespread": ['W AY1 D S P R EH1 D'],
  "widest": ['W AY1 D AH0 S T'],
  "widger": ['W IH1 JH ER0'],
  "widget": ['W IH1 JH IH0 T'],
  "widgets": ['W IH1 JH IH0 T S'],
  "widhalm": ['W IH1 D HH AA0 M'],
  "widick": ['W IH1 D IH0 K'],
  "widing": ['W AY1 D IH0 NG'],
  "widmaier": ['W IH1 D M AY0 ER0'],
  "widman": ['W IH1 D M AH0 N'],
  "widmann": ['W IH1 D M AH0 N'],
  "widmar": ['W IH1 D M ER0'],
  "widmayer": ['W IH1 D M EY2 ER0'],
  "widmer": ['W IH1 D M ER0'],
  "widnall": ['W IH1 D N AH0 L'],
  "widner": ['W IH1 D N ER0'],
  "widow": ['W IH1 D OW0'],
  "widow's": ['W IH1 D OW0 Z'],
  "widowed": ['W IH1 D OW0 D'],
  "widower": ['W IH1 D OW0 ER0'],
  "widowers": ['W IH1 D OW0 ER0 Z'],
  "widows": ['W IH1 D OW0 Z'],
  "widrick": ['W IH1 D R IH0 K'],
  "widrig": ['W IH1 D R IH0 G'],
  "width": ['W IH1 D TH'],
  "widths": ['W IH1 D TH S'],
  "widuri": ['W IH0 D UH1 R IY0'],
  "wieand": ['W IY1 AH0 N D'],
  "wiebe": ['W IY1 B'],
  "wieber": ['W IY1 B ER0'],
  "wieberg": ['W IY1 B ER0 G'],
  "wiebke": ['W IY1 B K IY0'],
  "wiebold": ['W IY1 B OW2 L D'],
  "wiebusch": ['W IY1 B AH0 SH'],
  "wiech": ['W IY1 CH'],
  "wiechert": ['W IY1 K ER0 T'],
  "wiechman": ['W IY1 K M AH0 N'],
  "wiechmann": ['W IY1 K M AH0 N'],
  "wieck": ['W IY1 K'],
  "wieczorek": ['V IY0 CH AO1 R EH0 K'],
  "wied": ['W IY1 D'],
  "wiedel": ['W IY1 D AH0 L'],
  "wiedeman": ['W IY1 D M AH0 N'],
  "wiedemann": ['W IY1 D M AH0 N'],
  "wieden": ['W IY1 D AH0 N'],
  "wieder": ['W IY1 D ER0'],
  "wiederaufbau": ['W IY2 D ER0 AO1 F B AW0'],
  "wiederhold": ['W IY1 D ER0 HH OW0 L D'],
  "wiederholt": ['W IY1 D ER0 HH OW0 L T'],
  "wiederkehr": ['W IY1 D ER0 K IH0 R'],
  "wiedman": ['W IY1 D M AH0 N'],
  "wiedmann": ['W IY1 D M AH0 N'],
  "wiedmeyer": ['W IY1 D M AY0 ER0'],
  "wiedner": ['W IY1 D N ER0'],
  "wiedrich": ['W IY1 D R IH0 K'],
  "wiegand": ['W IY1 G AH0 N D'],
  "wiegel": ['W IY1 G AH0 L'],
  "wiegers": ['W IY1 G ER0 Z'],
  "wiegert": ['W IY1 G ER0 T'],
  "wiegman": ['W IY1 G M AH0 N'],
  "wiegmann": ['W IY1 G M AH0 N'],
  "wiehe": ['W IY1'],
  "wieland": ['W IY1 L AH0 N D'],
  "wield": ['W IY1 L D'],
  "wielded": ['W IY1 L D IH0 D'],
  "wielding": ['W IY1 L D IH0 NG'],
  "wields": ['W IY1 L D Z'],
  "wielgus": ['W IY1 L G AH0 S'],
  "wieman": ['W IY1 M AH0 N'],
  "wiemann": ['W IY1 M AH0 N'],
  "wiemer": ['W IY1 M ER0'],
  "wiemers": ['W IY1 M ER0 Z'],
  "wien": ['W IY1 N'],
  "wiencek": ['V IY1 N CH EH0 K'],
  "wienecke": ['W IY1 N IH0 K'],
  "wieneke": ['W IY1 N IH0 K'],
  "wiener": ['W IY1 N ER0', 'W AY1 N ER0'],
  "wieners": ['W IY1 N ER0 Z'],
  "wienerschnitzel": ['W IY1 N ER0 SH N IH2 T S AH0 L', 'V IY1 N ER0 SH N IH2 T S AH0 L'],
  "wienerschnitzel's": ['W IY1 N ER0 SH N IH2 T S AH0 L Z', 'V IY1 N ER0 SH N IH2 T S AH0 L Z'],
  "wienke": ['W IY1 NG K'],
  "wiens": ['W IY1 N Z'],
  "wier": ['W IH1 R'],
  "wierdin": ['W IH1 R D IH0 N'],
  "wierenga": ['V IH0 R EH1 NG G AH0'],
  "wierman": ['W IH1 R M AH0 N'],
  "wiers": ['W IY1 R Z'],
  "wiersema": ['V IH0 R S IY1 M AH0'],
  "wiersma": ['V IH1 R S M AH0'],
  "wierzba": ['V IH1 R Z B AH0'],
  "wierzbicki": ['V IH0 R Z B IH1 T S K IY0'],
  "wies": ['W AY1 Z'],
  "wiesbaden": ['W IY1 S B AA2 D AH0 N', 'W AY1 S B AA2 D AH0 N'],
  "wiese": ['W IY1 Z'],
  "wiesel": ['W IY1 S AH0 L'],
  "wieseler": ['W IY1 S AH0 L ER0'],
  "wiesemann": ['W IY1 S M AH0 N'],
  "wiesen": ['W IY1 S AH0 N'],
  "wiesenthal": ['W IY1 S AH0 N TH AA1 L', 'W IY1 Z AH0 N TH AA1 L'],
  "wieser": ['W IY1 S ER0'],
  "wiesman": ['W IY1 Z M AH0 N'],
  "wiesner": ['W IY1 Z N ER0'],
  "wiess": ['W IY1 S'],
  "wiessner": ['W IY1 S N ER0'],
  "wiest": ['W AY1 IH0 S T'],
  "wieting": ['W IY1 T IH0 NG'],
  "wife": ['W AY1 F'],
  "wife's": ['W AY1 F S'],
  "wifi": ['W AY1 F AY2', 'W IY1 F IY2'],
  "wifi's": ['W AY1 F AY2 Z'],
  "wifis": ['W AY1 F AY2 Z'],
  "wig": ['W IH1 G'],
  "wigal": ['W IH1 G AH0 L'],
  "wigand": ['W IH1 G AH0 N D'],
  "wigand's": ['W IH1 G AH0 N D Z'],
  "wigen": ['W IH1 G AH0 N'],
  "wigfall": ['W IH1 G F AO2 L'],
  "wigfield": ['W IH1 G F IY2 L D'],
  "wigg": ['W IH1 G'],
  "wiggans": ['W IH1 G AH0 N Z'],
  "wigger": ['W IH1 G ER0'],
  "wiggers": ['W IH1 G ER0 Z'],
  "wiggin": ['W IH1 G IH0 N'],
  "wiggington": ['W IH1 G IH0 NG T AH0 N'],
  "wiggins": ['W IH1 G IH0 N Z'],
  "wigginton": ['W IH1 G IH0 N T AH0 N'],
  "wiggle": ['W IH1 G AH0 L'],
  "wiggled": ['W IH1 G AH0 L D'],
  "wiggles": ['W IH1 G AH0 L Z'],
  "wigglesworth": ['W IH1 G AH0 L Z W ER2 TH'],
  "wiggling": ['W IH1 G AH0 L IH0 NG', 'W IH1 G L IH0 NG'],
  "wiggly": ['W IH1 G AH0 L IY0'],
  "wiggs": ['W IH1 G Z'],
  "wight": ['W AY1 T'],
  "wightman": ['W AY1 T M AH0 N'],
  "wigington": ['W IH1 G IH0 NG T AH0 N'],
  "wiginton": ['W IH1 JH IH0 N T AH0 N'],
  "wigle": ['W AY1 G AH0 L'],
  "wigley": ['W IH1 G L IY0'],
  "wigmore": ['W IH1 G M AO0 R'],
  "wignall": ['W IH1 G N AH0 L'],
  "wigs": ['W IH1 G Z'],
  "wigton": ['W IH1 G T AH0 N'],
  "wigton's": ['W IH1 G T AH0 N Z'],
  "wigwam": ['W IH1 G W AA0 M'],
  "wii": ['W IY1'],
  "wiitala": ['V IY0 T AA1 L AH0'],
  "wik": ['W IH1 K'],
  "wike": ['W AY1 K'],
  "wikel": ['W IH1 K AH0 L'],
  "wiker": ['W AY1 K ER0'],
  "wiki": ['W IH1 K IY2'],
  "wiki's": ['W IH1 K IY2 Z'],
  "wikipedia": ['W IH1 K IY0 P IY2 D IY2 AH0'],
  "wikipedia's": ['W IH1 K IY0 P IY2 D IY2 AH0 Z'],
  "wikis": ['W IH1 K IY2 Z'],
  "wikle": ['W AY1 K AH0 L'],
  "wiklund": ['W IH1 K L AH0 N D'],
  "wikoff": ['W IH1 K AO0 F'],
  "wikowsky": ['W IH0 K AW1 S K IY0'],
  "wikstrom": ['W IH1 K S T R AH0 M'],
  "wil": ['W IH1 L'],
  "wil's": ['W IH1 L Z'],
  "wiland": ['W AY1 L AH0 N D'],
  "wilander": ['W AY1 L AH0 N D ER0'],
  "wilbanks": ['W IH1 L B AH0 NG K S'],
  "wilber": ['W IH1 L B ER0'],
  "wilberforce": ['W IH1 L B ER0 F AO2 R S'],
  "wilberg": ['W IH1 L B ER0 G'],
  "wilbert": ['W IH1 L B ER0 T'],
  "wilbon": ['W IH1 L B AH0 N'],
  "wilborn": ['W IH1 L B ER0 N'],
  "wilbourn": ['W IH1 L B ER0 N'],
  "wilbourne": ['W IH1 L B ER0 N'],
  "wilbur": ['W IH1 L B ER0'],
  "wilburn": ['W IH1 L B ER0 N'],
  "wilcher": ['W IH1 L CH ER0'],
  "wilcock": ['W IH1 L K AA0 K'],
  "wilcox": ['W IH1 L K AA0 K S'],
  "wilcoxen": ['W IH0 L K AA1 K S AH0 N'],
  "wilcoxon": ['W IH0 L K AA1 K S AH0 N'],
  "wilcoxson": ['W IH1 L K AA0 K S AH0 N'],
  "wilczak": ['V IH1 L CH AE0 K'],
  "wilczek": ['V IH1 L CH EH0 K'],
  "wilczewski": ['V IH0 L CH EH1 F S K IY0'],
  "wilczynski": ['V IH0 L CH IH1 N S K IY0'],
  "wild": ['W AY1 L D'],
  "wildasin": ['W AY1 L D AH0 S IH2 N'],
  "wildavsky": ['W IH0 L D AE1 V S K IY0'],
  "wildcard": ['W AY1 L D K AA2 R D'],
  "wildcat": ['W AY1 L D K AE2 T'],
  "wildcats": ['W AY1 L D K AE2 T S'],
  "wildcatter": ['W AY1 L D K AE2 T ER0'],
  "wildcatters": ['W AY1 L D K AE2 T ER0 Z'],
  "wildcatting": ['W AY1 L D K AE2 T IH0 NG'],
  "wilde": ['W AY1 L D'],
  "wilde's": ['W AY1 L D Z'],
  "wildeman": ['W AY1 L D M AH0 N'],
  "wilden": ['W AY1 L D AH0 N'],
  "wilder": ['W AY1 L D ER0'],
  "wilder's": ['W AY1 L D ER0 Z'],
  "wilderman": ['W AY1 L D ER0 M AH0 N'],
  "wildermuth": ['W AY1 L D ER0 M UW0 TH'],
  "wilderness": ['W IH1 L D ER0 N AH0 S'],
  "wildes": ['W AY1 L D Z'],
  "wildest": ['W AY1 L D IH0 S T'],
  "wildey": ['W IH1 L D IY0'],
  "wildfire": ['W AY1 L D F AY2 ER0'],
  "wildfires": ['W AY1 L D F AY2 ER0 Z'],
  "wildflower": ['W AY1 L D F L AW2 ER0'],
  "wildflowers": ['W AY1 L D F L AW2 R Z'],
  "wilding": ['W AY1 L D IH0 NG'],
  "wildlife": ['W AY1 L D L AY2 F'],
  "wildly": ['W AY1 L D L IY0'],
  "wildman": ['W AY1 L D M AH0 N'],
  "wildmon": ['W AY1 L D M AH0 N'],
  "wildness": ['W AY1 L D N AH0 S'],
  "wildon": ['W AY1 L D AH0 N'],
  "wildrick": ['W AY1 L D R IH0 K'],
  "wilds": ['W AY1 L D Z'],
  "wildt": ['W IH1 L T'],
  "wildwood": ['W AY1 L D W UH2 D'],
  "wildwoods": ['W AY1 L D W UH2 D Z'],
  "wile": ['W AY1 L'],
  "wileen": ['W IH0 L IY1 N'],
  "wileman": ['W AY1 L M AH0 N'],
  "wilemon": ['W IH1 L IH0 M AA0 N'],
  "wilen": ['W AY1 L AH0 N'],
  "wilensky": ['W AH0 L IH1 N S K IY0'],
  "wilentz": ['W AH0 L EH1 N T S'],
  "wiler": ['W AY1 L ER0'],
  "wiles": ['W AY1 L Z'],
  "wiles's": ['W AY1 L Z IH0 Z'],
  "wiley": ['W AY1 L IY0'],
  "wilfert": ['W IH1 L F ER0 T'],
  "wilfong": ['W IH1 L F AO0 NG'],
  "wilford": ['W IH1 L F ER0 D'],
  "wilfred": ['W IH1 L F R IH0 D'],
  "wilfreda": ['W IH1 L F R IH0 D AA2'],
  "wilfredo": ['W IH2 L F EY1 D OW2'],
  "wilfrid": ['W IH1 L F R IH0 D'],
  "wilfried": ['W IH1 L F R IY0 D'],
  "wilfully": ['W IH1 L F AH0 L IY0'],
  "wilgus": ['W IH1 L G AH0 S'],
  "wilham": ['W IH1 L HH AH0 M'],
  "wilhelm": ['W IH1 L HH EH2 L M'],
  "wilhelma": ['W IH0 L HH EH1 L M AH0'],
  "wilhelmi": ['W IH0 L HH EH1 L M IY0'],
  "wilhelmina": ['W IH2 L HH EH0 L M IY1 N AA2'],
  "wilhelmine": ['W IH1 L HH IH0 L M IH0 N'],
  "wilhelms": ['W IH1 L HH EH2 L M Z'],
  "wilhelmsen": ['W IH1 L HH IH0 L M S AH0 N'],
  "wilhelmy": ['W IH1 L HH IH0 L M IY0'],
  "wilhemina": ['W IH1 L HH EH0 M IY1 N AA2'],
  "wilhide": ['W IH1 L HH AY2 D'],
  "wilhite": ['W IH1 L HH AY2 T'],
  "wilhoit": ['W IH1 L HH OY2 T'],
  "wilhoite": ['W IH1 L HH OY2 T'],
  "wiliams": ['W IH1 L IY0 AH0 M Z'],
  "wiling": ['W AY1 L IH0 NG'],
  "wilinski": ['V IH0 L IH1 N S K IY0'],
  "wilk": ['W IH1 L K'],
  "wilke": ['W IH1 L K'],
  "wilken": ['W IH1 L K AH0 N'],
  "wilkening": ['W IH1 L K AH0 N IH0 NG'],
  "wilkens": ['W IH1 L K AH0 N Z'],
  "wilkenson": ['W IH1 L K IH0 N S AH0 N'],
  "wilker": ['W IH1 L K ER0'],
  "wilkerson": ['W IH1 L K ER0 S AH0 N'],
  "wilkes": ['W IH1 L K S'],
  "wilkesboro": ['W IH1 L K S B ER0 OW0'],
  "wilkey": ['W IH1 L K IY0'],
  "wilkie": ['W IH1 L K IY0'],
  "wilkin": ['W IH1 L K IH0 N'],
  "wilking": ['W IH1 L K IH0 NG'],
  "wilkins": ['W IH1 L K IH0 N Z'],
  "wilkinson": ['W IH1 L K AH0 N S AH0 N', 'W IH1 L K IH0 N S AH0 N'],
  "wilkinson's": ['W IH1 L K IH0 N S AH0 N Z'],
  "wilkis": ['W IH1 L K IH0 S'],
  "wilkison": ['W IH1 L K IH0 S AH0 N'],
  "wilkowski": ['V IH0 L K AO1 F S K IY0'],
  "wilks": ['W IH1 L K S'],
  "will": ['W IH1 L', 'W AH0 L'],
  "will's": ['W IH1 L Z'],
  "willa": ['W IH1 L AH0'],
  "willabelle": ['W IH1 L AH0 B EH2 L'],
  "willadsen": ['W IH0 L AE1 D S AH0 N'],
  "willaims": ['W IH1 L AH0 M Z'],
  "willam": ['W IH1 L AH0 M'],
  "willamette": ['W AH0 L AE1 M AH0 T', 'W IH2 L AH0 M EH1 T'],
  "willamette's": ['W AH0 L AE1 M AH0 T S', 'W IH2 L AH0 M EH1 T S'],
  "willamina": ['W IH0 L AH0 M AY1 N AH0'],
  "willapa": ['W IH0 L AA1 P AH0'],
  "willapa's": ['W IH0 L AA1 P AH0 Z'],
  "willard": ['W IH1 L ER0 D'],
  "willbanks": ['W IH1 L B AE2 NG K S'],
  "willcox": ['W IH1 L K AA2 K S'],
  "willcutt": ['W IH1 L K AH0 T'],
  "willden": ['W IH1 L D AH0 N'],
  "wille": ['W IH1 L'],
  "willed": ['W IH1 L D'],
  "willeford": ['W IH1 L IH0 F ER0 D', 'W IH1 L F ER0 D'],
  "willem": ['W IH1 L AH0 M'],
  "willems": ['W IH1 L AH0 M Z'],
  "willemsen": ['W IH0 L EH1 M S AH0 N', 'W IH1 L AH0 M S AH0 N'],
  "willen": ['W IH1 L AH0 N'],
  "willenborg": ['W IH1 L IH0 N B AO0 R G'],
  "willenbring": ['W IH1 L AH0 N B R IH2 NG'],
  "willens": ['W IH1 L AH0 N Z'],
  "willer": ['W IH1 L ER0'],
  "willers": ['W IH1 L ER0 Z'],
  "willert": ['W IH1 L ER0 T'],
  "willes": ['W AY1 L Z'],
  "willet": ['W IH1 L IH0 T'],
  "willets": ['W IH1 L IH0 T S'],
  "willett": ['W IH1 L IH0 T'],
  "willette": ['W IH0 L EH1 T'],
  "willetts": ['W IH1 L IH0 T S'],
  "willey": ['W IH1 L IY0'],
  "willford": ['W IH1 L F ER0 D'],
  "willful": ['W IH1 L F AH0 L'],
  "willfully": ['W IH1 L F AH0 L IY0'],
  "willhelm": ['W IH1 L HH EH2 L M'],
  "willhite": ['W IH1 L HH AY2 T'],
  "willhoit": ['W IH1 L HH OY2 T'],
  "willhoite": ['W IH1 L HH OY2 T'],
  "willi": ['W IH1 L IY0'],
  "william": ['W IH1 L Y AH0 M'],
  "william's": ['W IH1 L Y AH0 M Z'],
  "williams": ['W IH1 L Y AH0 M Z'],
  "williams'": ['W IH1 L Y AH0 M Z'],
  "williams's": ['W IH1 L Y AH0 M Z IH0 Z'],
  "williamsburg": ['W IH1 L Y AH0 M Z B ER0 G'],
  "williamsburgh": ['W IH1 L Y AH0 M Z B ER0 G'],
  "williamsen": ['W IH1 L Y AH0 M S AH0 N'],
  "williamson": ['W IH1 L Y AH0 M S AH0 N'],
  "williamson's": ['W IH1 L Y AH0 M S AH0 N Z'],
  "williamsport": ['W IH1 L Y AH0 M S P AO2 R T'],
  "williamstown": ['W IH1 L Y AH0 M S T AW2 N'],
  "williard": ['W IH1 L Y AA0 R D'],
  "willie": ['W IH1 L IY0'],
  "willie's": ['W IH1 L IY0 Z'],
  "williford": ['W IH1 L IH0 F ER0 D'],
  "willig": ['W IH1 L IH0 G'],
  "willing": ['W IH1 L IH0 NG'],
  "willinger": ['W IH1 L IH0 NG ER0'],
  "willingham": ['W IH1 L IH0 NG HH AE2 M'],
  "willingly": ['W IH1 L IH0 NG L IY0'],
  "willingness": ['W IH1 L IH0 NG N AH0 S'],
  "willis": ['W IH1 L IH0 S'],
  "willis's": ['W IH1 L IH0 S IH0 Z'],
  "willison": ['W IH1 L IH0 S AH0 N'],
  "williston": ['W IH1 L IH0 S T AA0 N'],
  "willits": ['W IH1 L IH0 T S'],
  "willke": ['W IH1 L K IY0'],
  "willkie": ['W IH1 L K IY0'],
  "willman": ['W IH1 L M AH0 N'],
  "willmann": ['W IH1 L M AH0 N'],
  "willmon": ['W IH1 L M AH0 N'],
  "willmore": ['W IH1 L M AO0 R'],
  "willmott": ['W IH1 L M AH0 T'],
  "willms": ['W IH1 L M Z'],
  "willner": ['W IH1 L N ER0'],
  "willock": ['W IH1 L AH0 K'],
  "willoughby": ['W IH1 L AH0 B IY0'],
  "willow": ['W IH1 L OW2'],
  "willowbrook": ['W IH1 L OW0 B R UH2 K'],
  "willowes": ['W IH1 L OW2 Z'],
  "willows": ['W IH1 L OW2 Z'],
  "willowy": ['W IH1 L AH0 W IY0'],
  "willpower": ['W IH1 L P AW2 ER0'],
  "wills": ['W IH1 L Z'],
  "willse": ['W IH1 L S IY0'],
  "willsey": ['W IH1 L S IY0'],
  "willson": ['W IH1 L S AH0 N'],
  "willwerth": ['W IH1 L W ER0 TH'],
  "willy": ['W IH1 L IY0'],
  "willyard": ['W IH1 L Y AA2 R D'],
  "wilma": ['W IH1 L M AH0'],
  "wilmar": ['W IH1 L M ER0'],
  "wilmarth": ['W IH1 L M AA0 R TH'],
  "wilmer": ['W IH1 L M ER0'],
  "wilmes": ['W IH1 L M Z'],
  "wilmet": ['W IH1 L M IH0 T'],
  "wilmeth": ['W IH1 L M IH0 TH'],
  "wilmette": ['W IH0 L M EH1 T'],
  "wilmington": ['W IH1 L M IH0 NG T AH0 N'],
  "wilmore": ['W IH1 L M AO0 R'],
  "wilmot": ['W IH1 L M AH0 T'],
  "wilmoth": ['W IH1 L M AH0 TH'],
  "wilmott": ['W IH1 L M AH0 T'],
  "wilmouth": ['W IH1 L M AW0 TH'],
  "wilms": ['W IH1 L M Z'],
  "wilmut": ['W IH1 L M AH0 T'],
  "wilner": ['W IH1 L N ER0'],
  "wilpon": ['W IH1 L P AA0 N'],
  "wilsey": ['W IH1 L S IY0'],
  "wilshire": ['W IH1 L SH AY2 R'],
  "wilshusen": ['W IH1 L SH UW0 S AH0 N'],
  "wilson": ['W IH1 L S AH0 N'],
  "wilson's": ['W IH1 L S AH0 N Z'],
  "wilt": ['W IH1 L T'],
  "wilted": ['W IH1 L T IH0 D'],
  "wiltel": ['W IH1 L T EH2 L'],
  "wiltfong": ['W IH1 L T F AO0 NG'],
  "wiltgen": ['W IH1 L T G AH0 N'],
  "wilthew": ['W IH1 L TH Y UW0'],
  "wilting": ['W IH1 L T IH0 NG'],
  "wilton": ['W IH1 L T AH0 N'],
  "wiltron": ['W IH1 L T R AH0 N'],
  "wiltrout": ['W IH1 L T R AW2 T'],
  "wilts": ['W IH1 L T S'],
  "wiltse": ['W IH1 L T S'],
  "wiltsey": ['W IH1 L T S IY0'],
  "wiltshire": ['W IH1 L CH AY2 R'],
  "wiltsie": ['W IH1 L T S IY0'],
  "wiltz": ['W IH1 L T S'],
  "wily": ['W AY1 L IY0'],
  "wiman": ['W AY1 M AH0 N'],
  "wimberley": ['W IH1 M B ER0 L IY0'],
  "wimberly": ['W IH1 M B ER0 L IY0'],
  "wimbish": ['W IH1 M B IH0 SH'],
  "wimbledon": ['W IH1 M B AH0 L D AH0 N'],
  "wimbley": ['W IH1 M B L IY0'],
  "wimbush": ['W IH1 M B AH0 SH', 'W IH1 M B UH0 SH'],
  "wimer": ['W AY1 M ER0'],
  "wimmer": ['W IH1 M ER0'],
  "wimp": ['W IH1 M P'],
  "wimpee": ['W IH1 M P IY0'],
  "wimpiness": ['W IH1 M P IY0 N AH0 S'],
  "wimps": ['W IH1 M P S'],
  "wimpy": ['W IH1 M P IY0'],
  "wims": ['W IH1 M Z'],
  "wimsatt": ['W IH1 M S AH0 T'],
  "win": ['W IH1 N'],
  "win's": ['W IH1 N Z'],
  "win-win": ['W IH2 N W IH1 N'],
  "winamac": ['W IH1 N AH0 M AE0 K'],
  "winamac's": ['W IH1 N AH0 M AE0 K S'],
  "winans": ['W IH1 N AH0 N Z'],
  "winans's": ['W IH1 N AH0 N Z IH0 Z'],
  "winant": ['W AY1 N AH0 N T'],
  "winberg": ['W IH1 N B ER0 G'],
  "winberry": ['W IH1 N B EH2 R IY0'],
  "winborn": ['W IH1 N B ER0 N'],
  "winborne": ['W IH1 N B ER0 N'],
  "winburn": ['W IH1 N B ER2 N'],
  "winbush": ['W IH1 N B UH2 SH'],
  "wince": ['W IH1 N S'],
  "winced": ['W IH1 N S T'],
  "wincek": ['W IH1 N S IH0 K'],
  "winch": ['W IH1 N CH'],
  "winchel": ['W IH1 N K AH0 L'],
  "winchell": ['W IH1 N CH AH0 L'],
  "winchell's": ['W IH1 N CH AH0 L Z'],
  "winchester": ['W IH1 N CH EH2 S T ER0'],
  "wincing": ['W IH1 N S IH0 NG'],
  "winckler": ['W IH1 NG K L ER0'],
  "wind": ['W AY1 N D', 'W IH1 N D'],
  "wind's": ['W IH1 N D Z'],
  "windchill": ['W IH1 N D CH IH2 L'],
  "windchime": ['W IH1 N D CH AY2 M'],
  "windchimes": ['W IH1 N D CH AY2 M Z'],
  "windecker": ['W IH1 N D EH2 K ER0'],
  "winded": ['W IH1 N D IH0 D', 'W AY1 N D IH0 D'],
  "windell": ['W IH1 N D AH0 L'],
  "windels": ['W IH1 N D AH0 L Z'],
  "winder": ['W IH1 N D ER0', 'W AY1 N D ER0'],
  "winders": ['W IH1 N D ER0 Z', 'W AY1 N D ER0 Z'],
  "windes": ['W IH1 N D Z', 'W AY1 N D Z'],
  "windfall": ['W IH1 N D F AO2 L'],
  "windfalls": ['W IH1 N D F AO2 L Z'],
  "windham": ['W IH1 N D AH0 M'],
  "windheim": ['W IH1 N D HH AY2 M'],
  "windhoek": ['W IH1 N D HH OW2 K'],
  "windholz": ['W IH1 N D HH OW2 L Z'],
  "windhorst": ['W IH1 N D HH AO0 R S T'],
  "winding": ['W AY1 N D IH0 NG'],
  "windisch": ['W IH1 N D IH0 SH'],
  "windish": ['W IH1 N D IH0 SH'],
  "windlass": ['W IH1 N D L AH0 S'],
  "windle": ['W IH1 N D AH0 L'],
  "windler": ['W IH1 N D L ER0'],
  "windley": ['W IH1 N D L IY0'],
  "windmere": ['W IH1 N D M IH2 R'],
  "windmere's": ['W IH1 N D M IH2 R Z'],
  "windmill": ['W IH1 N D M IH2 L'],
  "windmiller": ['W IH1 N D M IH2 L ER0'],
  "windmills": ['W IH1 N D M IH2 L Z'],
  "windom": ['W IH1 N D AH0 M'],
  "windon": ['W IH1 N D AH0 N'],
  "window": ['W IH1 N D OW0'],
  "windowed": ['W IH1 N D OW0 D'],
  "windowless": ['W IH1 N D OW0 L AH0 S'],
  "windowpane": ['W IH1 N D OW0 P EY2 N'],
  "windowpanes": ['W IH1 N D OW0 P EY2 N Z'],
  "windows": ['W IH1 N D OW0 Z'],
  "winds": ['W IH1 N D Z', 'W AY1 N D Z'],
  "windshield": ['W IH1 N D SH IY2 L D'],
  "windshields": ['W IH1 N D SH IY2 L D Z'],
  "windsor": ['W IH1 N Z ER0'],
  "windsor's": ['W IH1 N Z ER0 Z'],
  "windsors": ['W IH1 N Z ER0 Z'],
  "windspeed": ['W IH1 N D S P IY2 D'],
  "windstar": ['W IH1 N D S T AA2 R'],
  "windstar's": ['W IH1 N D S T AA2 R Z'],
  "windstorm": ['W IH1 N D S T AO2 R M'],
  "windswept": ['W IH1 N S W EH2 P T'],
  "windt": ['W IH1 N T'],
  "windup": ['W AY1 N D AH2 P'],
  "windward": ['W IH1 N D W ER0 D'],
  "windy": ['W IH1 N D IY0', 'W AY1 N D IY0'],
  "wine": ['W AY1 N'],
  "wine's": ['W AY1 N Z'],
  "winebarger": ['W IH1 N IH0 B AA0 R G ER0'],
  "wineberg": ['W AY1 N B ER0 G'],
  "winebrenner": ['W IH1 N IH0 B R IH0 N ER0'],
  "winecoff": ['W IH1 N IH0 K AO0 F'],
  "wined": ['W AY1 N D'],
  "winegar": ['W IH1 N IH0 G ER0'],
  "winegarden": ['W AY1 N G AA2 R D AH0 N'],
  "winegardner": ['W IH1 N IH0 G AA0 R D N ER0'],
  "wineheim": ['W AY1 N HH AY2 M'],
  "wineinger": ['W AY1 N IH0 NG ER0'],
  "wineland": ['W AY1 N L AH0 N D', 'W IH1 N IH0 L AH0 N D'],
  "winema": ['W IH1 N IH0 M AH0'],
  "wineman": ['W AY1 N M AH0 N'],
  "winemiller": ['W AY1 N M IH2 L ER0'],
  "winer": ['W AY1 N ER0'],
  "wineries": ['W AY1 N ER0 IY0 Z'],
  "winery": ['W AY1 N ER0 IY0'],
  "winery's": ['W AY1 N ER0 IY0 Z'],
  "wines": ['W AY1 N Z'],
  "winey": ['W AY1 N IY0'],
  "winfield": ['W IH1 N F IY2 L D'],
  "winford": ['W IH1 N F ER0 D'],
  "winfred": ['W IH1 N F R IH0 D'],
  "winfree": ['W IH1 N F R IY2'],
  "winfrey": ['W IH1 N F R IY0'],
  "winfrid": ['W IH1 N F R IH0 D'],
  "wing": ['W IH1 NG'],
  "wing's": ['W IH1 NG Z'],
  "wingard": ['W IH1 NG G ER0 D'],
  "wingate": ['W IH1 N G EY2 T'],
  "wingback": ['W IH1 NG B AE2 K'],
  "winge": ['W IH1 N JH'],
  "winged": ['W IH1 NG D'],
  "winger": ['W IH1 NG ER0'],
  "wingers": ['W IH1 NG ER0 Z'],
  "wingert": ['W IH1 NG G ER0 T'],
  "wingerter": ['W IH1 NG G ER0 T ER0'],
  "winget": ['W IH1 NG G IH0 T'],
  "wingett": ['W IH1 NG G IH0 T'],
  "wingfield": ['W IH1 NG F IY2 L D'],
  "winging": ['W IH1 NG IH0 NG'],
  "wingler": ['W IH1 NG G AH0 L ER0', 'W IH1 NG G L ER0'],
  "winglike": ['W IH1 NG L AY2 K'],
  "wingman": ['W IH1 NG M AH0 N'],
  "wingo": ['W IY1 NG G OW0'],
  "wingrove": ['W IH1 N G R OW2 V'],
  "wings": ['W IH1 NG Z'],
  "wings'": ['W IH1 NG Z'],
  "wingspan": ['W IH1 NG S P AE2 N'],
  "winiarski": ['V IH0 N IY0 AA1 R S K IY0'],
  "winick": ['W IH1 N IH0 K'],
  "winiecki": ['V IH0 N IY1 T S K IY0'],
  "winifred": ['W IH1 N IH0 F R IH0 D'],
  "winik": ['W IH1 N IH0 K'],
  "wining": ['W AY1 N IH0 NG'],
  "wininger": ['W AY1 N IH0 NG ER0'],
  "winings": ['W AY1 N IH0 NG Z'],
  "wink": ['W IH1 NG K'],
  "winked": ['W IH1 NG K T'],
  "winkel": ['W IH1 NG K AH0 L'],
  "winkelman": ['W IH1 NG K AH0 L M AH0 N'],
  "winkelmann": ['W IH1 NG K AH0 L M AH0 N'],
  "winkels": ['W IH1 NG K AH0 L Z'],
  "winker": ['W IH1 NG K ER0'],
  "winkfield": ['W IH1 NG K F IY2 L D'],
  "winking": ['W IH1 NG K IH0 NG'],
  "winkle": ['W IH1 NG K AH0 L'],
  "winkleman": ['W IH1 NG K AH0 L M AH0 N'],
  "winkler": ['W IH1 NG K L ER0'],
  "winkles": ['W IH1 NG K AH0 L Z'],
  "winkley": ['W IH1 NG K L IY0'],
  "winkowski": ['V IH0 NG K AO1 F S K IY0'],
  "winks": ['W IH1 NG K S'],
  "winland": ['W IH1 N L AH0 N D'],
  "winn": ['W IH1 N'],
  "winnable": ['W IH1 N AH0 B AH0 L'],
  "winne": ['W IH1 N'],
  "winnebago": ['W IH2 N AH0 B EY1 G OW0'],
  "winnebago's": ['W IH2 N AH0 B EY1 G OW0 Z'],
  "winner": ['W IH1 N ER0'],
  "winner's": ['W IH1 N ER0 Z'],
  "winners": ['W IH1 N ER0 Z'],
  "winners'": ['W IH1 N ER0 Z'],
  "winnetka": ['W IH0 N EH1 T K AH0'],
  "winnett": ['W IH1 N IH0 T'],
  "winney": ['W IH1 N IY0'],
  "winnick": ['W IH1 N IH0 K'],
  "winnicki": ['V IH0 N IH1 T S K IY0'],
  "winnie": ['W IH1 N IY0'],
  "winning": ['W IH1 N IH0 NG'],
  "winningest": ['W IH1 N IH0 NG AH0 S T'],
  "winningham": ['W IH1 N IH0 NG HH AE2 M'],
  "winnings": ['W IH1 N IH0 NG Z'],
  "winnipeg": ['W IH1 N IH0 P AH0 G'],
  "winnow": ['W IH1 N OW2'],
  "winnowed": ['W IH1 N OW2 D'],
  "winnowing": ['W IH1 N OW2 IH0 NG'],
  "winny": ['W IH1 N IY0'],
  "wino": ['W IY1 N OW0'],
  "winograd": ['W IH1 N AH0 G R AE0 D'],
  "winokur": ['W IH1 N AH0 K ER0'],
  "winola": ['V IH0 N OW1 L AH0'],
  "winona": ['W IH1 N AH0 N AH0'],
  "winonah": ['W IH1 N AH0 N AH0'],
  "winooski": ['W IH0 N UW1 S K IY0'],
  "winos": ['W IY1 N OW0 S'],
  "winquist": ['W IH1 N K W IH2 S T'],
  "wins": ['W IH1 N Z'],
  "winsett": ['W IH1 N S IH0 T'],
  "winship": ['W IH1 N SH IH2 P'],
  "winski": ['W IH1 N S K IY2'],
  "winslet": ['W IH1 N S L IH0 T'],
  "winslet's": ['W IH1 N S L IH0 T S'],
  "winslett": ['W IH1 N S L IH0 T'],
  "winslow": ['W IH1 N Z L OW0'],
  "winsome": ['W IH1 N S AH0 M'],
  "winson": ['W IH1 N S AH0 N'],
  "winsor": ['W IH1 N Z ER0'],
  "winstanley": ['W IH1 N S T AH0 N L IY0'],
  "winstead": ['W IH1 N S T EH2 D'],
  "winston": ['W IH1 N S T AH0 N'],
  "wint": ['W IH1 N T'],
  "winter": ['W IH1 N T ER0'],
  "winter's": ['W IH1 N T ER0 Z'],
  "winterberg": ['W IH1 N T ER0 B ER0 G'],
  "winterbourne": ['W IH1 N T ER0 B AO2 R N'],
  "wintered": ['W IH1 N T ER0 D'],
  "wintergreen": ['W IH1 N T ER0 G R IY2 N'],
  "winterhalter": ['W IH1 N T ER0 HH AO2 L T ER0'],
  "winterize": ['W IH1 N T ER0 AY2 Z'],
  "winterized": ['W IH1 N T ER0 AY2 Z D'],
  "wintermute": ['W IH1 N T ER0 M Y UW2 T'],
  "winterrowd": ['W IH1 N T ER0 AW0 D'],
  "winters": ['W IH1 N T ER0 Z'],
  "wintershall": ['W IH1 N T ER0 SH AE2 L'],
  "wintersteen": ['W IH1 N T ER0 S T IY2 N'],
  "winterstein": ['W IH1 N T ER0 S T AY2 N', 'W IH1 N T ER0 S T IY2 N'],
  "winterthur": ['W IH1 N T ER0 TH ER0'],
  "wintertime": ['W IH1 N T ER0 T AY2 M'],
  "winterton": ['W IH1 N T ER0 T AH0 N'],
  "winther": ['W IH1 N TH ER0'],
  "winthrop": ['W IH1 N TH R AH0 P'],
  "winthrop's": ['W IH1 N TH R AH0 P S'],
  "wintle": ['W IH1 N T AH0 L'],
  "wintom": ['W IH1 N T AH0 M'],
  "winton": ['W IH1 N T AH0 N'],
  "wintour": ['W IH1 N T UH2 R'],
  "wintry": ['W IH1 N T R IY0'],
  "wintz": ['W IH1 N T S'],
  "wintzer": ['W IH1 N T S ER0'],
  "winward": ['W IH1 N W ER0 D'],
  "winwood": ['W IH1 N W UH2 D'],
  "winwood's": ['W IH1 N W UH2 D Z'],
  "winword": ['W IH1 N W ER0 D'],
  "winzeler": ['W IH1 N Z AH0 L ER0'],
  "winzer": ['W IH1 N Z ER0'],
  "wion": ['W AY1 AH0 N'],
  "wipe": ['W AY1 P'],
  "wiped": ['W AY1 P T'],
  "wipeout": ['W AY1 P AW2 T'],
  "wiper": ['W AY1 P ER0'],
  "wipers": ['W AY1 P ER0 Z'],
  "wipes": ['W AY1 P S'],
  "wipf": ['W IH1 P F'],
  "wiping": ['W AY1 P IH0 NG'],
  "wipperfurth": ['W IH1 P ER0 F ER0 TH'],
  "wire": ['W AY1 ER0', 'W AY1 R'],
  "wired": ['W AY1 ER0 D', 'W AY1 R D'],
  "wireless": ['W AY1 R L IH0 S'],
  "wireless's": ['W AY1 ER0 L AH0 S IH0 Z'],
  "wireline": ['W AY1 R L AY2 N'],
  "wireman": ['W AY1 R M AH0 N'],
  "wires": ['W AY1 ER0 Z', 'W AY1 R Z'],
  "wiretap": ['W AY1 ER0 T AE2 P'],
  "wiretapped": ['W AY1 ER0 T AE2 P T'],
  "wiretapping": ['W AY1 ER0 T AE2 P IH0 NG'],
  "wiretaps": ['W AY1 R T AE2 P S'],
  "wirick": ['W IH1 R IH0 K'],
  "wiring": ['W AY1 R IH0 NG'],
  "wirkkala": ['V ER0 K AA1 L AH0'],
  "wirkus": ['W ER1 K IH0 S'],
  "wirsing": ['W ER1 S IH0 NG'],
  "wirt": ['W ER1 T'],
  "wirtanen": ['W ER1 T AH0 N AH0 N'],
  "wirth": ['W ER1 TH'],
  "wirthlin": ['W ER1 TH L IH0 N'],
  "wirtz": ['W ER1 T S'],
  "wiry": ['W IH1 R IY0'],
  "wirz": ['W ER1 Z'],
  "wis": ['W IH1 S'],
  "wisby": ['W IH1 S B IY0'],
  "wisch": ['W IH1 SH'],
  "wischmeyer": ['W IH1 SH M AY0 ER0'],
  "wisconsin": ['W IH0 S K AA1 N S AH0 N'],
  "wisconsin's": ['W IH0 S K AA1 N S AH0 N Z'],
  "wisdom": ['W IH1 Z D AH0 M'],
  "wise": ['W AY1 Z'],
  "wisecarver": ['W AY1 Z K AA2 R V ER0'],
  "wisecrack": ['W AY1 Z K R AE2 K'],
  "wisecracking": ['W AY1 Z K R AE2 K IH0 NG'],
  "wisecracks": ['W AY1 Z K R AE2 K S'],
  "wisecup": ['W AY1 Z K AH2 P'],
  "wised": ['W AY1 Z D'],
  "wiseguy": ['W AY1 Z G AY2'],
  "wisehart": ['W AY1 Z HH AA2 R T'],
  "wisel": ['W AY1 Z AH0 L'],
  "wiseley": ['W IH1 S IH0 L IY0', 'W AY1 Z L IY0'],
  "wisely": ['W AY1 Z L IY0'],
  "wiseman": ['W AY1 Z M AH0 N'],
  "wisenbaker": ['W AY1 Z AH0 N B EY2 K ER0'],
  "wisener": ['W IH1 S IY0 N ER0'],
  "wiser": ['W AY1 Z ER0'],
  "wisest": ['W AY1 Z AH0 S T'],
  "wish": ['W IH1 SH'],
  "wisham": ['W IH1 SH AH0 M'],
  "wishard": ['W IH1 SH ER0 D'],
  "wishart": ['W IH1 SH AA2 R T'],
  "wishbone": ['W IH1 SH B OW2 N'],
  "wished": ['W IH1 SH T'],
  "wisher": ['W IH1 SH ER0'],
  "wishers": ['W IH1 SH ER0 Z'],
  "wishes": ['W IH1 SH IH0 Z'],
  "wishful": ['W IH1 SH F AH0 L'],
  "wishfully": ['W IH1 SH F AH0 L IY0'],
  "wishing": ['W IH1 SH IH0 NG'],
  "wishlist": ['W IH1 SH L IH0 S T'],
  "wishner": ['W IH1 SH N ER0'],
  "wishnick": ['W IH1 SH N IH0 K'],
  "wishon": ['W IH1 SH AH0 N'],
  "wishy": ['W IH1 SH IY0'],
  "wisinski": ['V IH0 S IH1 N S K IY0'],
  "wisler": ['W IH1 S AH0 L ER0', 'W IH1 S L ER0'],
  "wisley": ['W IH1 Z L IY0'],
  "wisman": ['W IH1 Z M AH0 N'],
  "wismer": ['W IH1 Z AH0 M ER0'],
  "wisner": ['W IH1 S N ER0'],
  "wisneski": ['V IH0 S N EH1 S K IY0'],
  "wisnewski": ['V IH0 S N EH1 F S K IY0'],
  "wisnieski": ['V IH0 S N IY1 S K IY0'],
  "wisniewski": ['W IH0 Z N IY0 EH1 F S K IY0'],
  "wisor": ['W AY1 Z ER0'],
  "wisp": ['W IH1 S P'],
  "wispy": ['W IH1 S P IY0'],
  "wiss": ['W IH1 S'],
  "wissa": ['W IH1 S AH0'],
  "wissel": ['W IH1 S AH0 L'],
  "wisser": ['W IH1 S ER0'],
  "wissing": ['W IH1 S IH0 NG'],
  "wissinger": ['W IH1 S IH0 NG ER0'],
  "wissink": ['W IH1 S IH0 NG K'],
  "wissler": ['W IH1 S L ER0'],
  "wissman": ['W IH1 S M AH0 N'],
  "wissmann": ['W IH1 S M AH0 N'],
  "wissner": ['W IH1 S N ER0'],
  "wist": ['W IH1 S T'],
  "wistful": ['W IH1 S T F AH0 L'],
  "wistfully": ['W IH1 S T F AH0 L IY0'],
  "wistfulness": ['W IH1 S T F AH0 L N AH0 S'],
  "wiswell": ['W IH1 S W EH0 L'],
  "wisz": ['V IH1 SH'],
  "wit": ['W IH1 T'],
  "wit's": ['W IH1 T S'],
  "witbeck": ['W IH1 T B EH2 K'],
  "witbrock": ['W IH1 T B R AO2 K'],
  "witch": ['W IH1 CH'],
  "witch's": ['W IH1 CH IH0 Z'],
  "witchcraft": ['W IH1 CH K R AE2 F T'],
  "witcher": ['W IH1 CH ER0'],
  "witches": ['W IH1 CH AH0 Z', 'W IH1 CH IH0 Z'],
  "witchey": ['W IH1 CH IY0'],
  "witchhunt": ['W IH1 CH HH AH2 N T'],
  "witching": ['W IH1 CH IH0 NG'],
  "witchy": ['W IH1 CH IY0'],
  "witco": ['W IH1 T K OW0'],
  "witczak": ['V IH1 T CH AE0 K'],
  "witek": ['V IH1 T EH0 K'],
  "with": ['W IH1 DH', 'W IH1 TH', 'W IH0 TH', 'W IH0 DH'],
  "witham": ['W IH1 TH AH0 M'],
  "withdraw": ['W IH0 DH D R AO1', 'W IH0 TH D R AO1'],
  "withdrawal": ['W IH0 DH D R AO1 AH0 L', 'W IH0 TH D R AO1 AH0 L'],
  "withdrawals": ['W IH0 TH D R AO1 AH0 L Z', 'W IH0 DH D R AO1 AH0 L Z'],
  "withdrawing": ['W IH0 TH D R AO1 IH0 NG', 'W IH0 DH D R AO1 IH0 NG'],
  "withdrawn": ['W IH0 TH D R AO1 N', 'W IH0 DH D R AO1 N'],
  "withdraws": ['W IH0 DH D R AO1 Z', 'W IH0 TH D R AO1 Z'],
  "withdrew": ['W IH0 TH D R UW1', 'W IH0 DH D R UW1'],
  "withe": ['W IH1 TH'],
  "withee": ['W IH1 TH IY1'],
  "withem": ['W IH1 TH IH0 M'],
  "wither": ['W IH1 DH ER0'],
  "withered": ['W IH1 DH ER0 D'],
  "witherell": ['W IH1 TH ER0 AH0 L'],
  "withering": ['W IH1 DH ER0 IH0 NG'],
  "witherington": ['W IH1 TH ER0 IH0 NG T AH0 N'],
  "witherow": ['W IH1 TH ER0 OW0'],
  "withers": ['W IH1 DH ER0 Z'],
  "witherspoon": ['W IH1 DH ER0 S P UW2 N'],
  "withey": ['W IH1 TH IY0'],
  "withheld": ['W IH0 TH HH EH1 L D'],
  "withhold": ['W IH0 TH HH OW1 L D'],
  "withholding": ['W IH0 TH HH OW1 L D IH0 NG'],
  "withholds": ['W IH1 TH HH OW2 L D Z'],
  "within": ['W IH0 DH IH1 N', 'W IH0 TH IH1 N'],
  "withington": ['W IH1 TH IH0 NG T AH0 N'],
  "withnail": ['W IH0 TH N EY1 L'],
  "without": ['W IH0 TH AW1 T', 'W IH0 DH AW1 T'],
  "withrow": ['W IH1 TH R OW2'],
  "withstand": ['W IH0 TH S T AE1 N D'],
  "withstanding": ['W IH0 TH S T AE1 N D IH0 NG'],
  "withstands": ['W IH0 TH S T AE1 N D Z'],
  "withstood": ['W IH0 TH S T UH1 D'],
  "witkin": ['W IH1 T K IH2 N'],
  "witkop": ['W IH1 T K AH0 P'],
  "witkowski": ['V IH0 T K AO1 F S K IY0'],
  "witless": ['W IH1 T L AH0 S'],
  "witman": ['W IH1 T M AH0 N'],
  "witmer": ['W IH1 T M ER0'],
  "witness": ['W IH1 T N AH0 S'],
  "witness'": ['W IH1 T N AH0 S'],
  "witness's": ['W IH1 T N AH0 S IH0 Z'],
  "witnessed": ['W IH1 T N AH0 S T'],
  "witnesses": ['W IH1 T N AH0 S AH0 Z', 'W IH1 T N AH0 S IH0 Z'],
  "witnesses'": ['W IH1 T N AH0 S IH0 Z'],
  "witnessing": ['W IH1 T N AH0 S IH0 NG'],
  "witowski": ['V IH0 T AO1 F S K IY0'],
  "wits": ['W IH1 T S'],
  "witt": ['W IH1 T'],
  "witte": ['W IH1 T'],
  "witted": ['W IH1 T IH0 D'],
  "wittekind": ['W IH1 T IH0 K IH0 N D'],
  "witteman": ['W IH1 T M AH0 N'],
  "witten": ['W IH1 T AH0 N'],
  "wittenauer": ['W IH1 T IH0 N AW0 ER0'],
  "wittenberg": ['W IH1 T AH0 N B ER0 G'],
  "wittenborn": ['W IH1 T IH0 N B ER0 N'],
  "wittenburg": ['W IH1 T AH0 N B ER0 G'],
  "wittenmyer": ['W IH1 T IH0 N M IY0 ER0', 'W IH1 T IH0 N M AY0 ER0'],
  "witter": ['W IH1 T ER0'],
  "witter's": ['W IH1 T ER0 Z'],
  "witters": ['W IH1 T ER0 Z'],
  "witthuhn": ['W IH1 TH AH0 N'],
  "wittig": ['W IH1 T IH0 G'],
  "wittily": ['W IH1 T AH0 L IY0'],
  "witting": ['W IH1 T IH0 NG'],
  "wittingly": ['W IH1 T IH0 NG L IY0'],
  "wittke": ['W IH1 T K IY0'],
  "wittkopp": ['W IH1 T K AH0 P'],
  "wittler": ['W IH1 T L ER0'],
  "wittman": ['W IH1 T M AH0 N'],
  "wittmann": ['W IH1 T M AH0 N'],
  "wittmer": ['W IH1 T M ER0'],
  "wittmeyer": ['W IH1 T M AY0 ER0'],
  "wittner": ['W IH1 T N ER0'],
  "witton": ['W IH1 T AH0 N'],
  "wittrock": ['W IH1 T R AH0 K'],
  "witts": ['W IH1 T S'],
  "wittwer": ['W IH1 T W ER0'],
  "witty": ['W IH1 T IY0'],
  "witucki": ['W IH0 T AH1 K IY0'],
  "witwatersrand": ['W IH1 T W AO2 T ER0 S R AE0 N D'],
  "witwer": ['W IH1 T W ER0'],
  "witz": ['W IH1 T S'],
  "witzel": ['W IH1 T Z AH0 L'],
  "witzig": ['W IH1 T Z IH0 G'],
  "witzke": ['W IH1 T S K IY0'],
  "wives": ['W AY1 V Z'],
  "wives'": ['W AY1 V Z'],
  "wix": ['W IH1 K S'],
  "wixom": ['W IH1 K S AH0 M'],
  "wixon": ['W IH1 K S AH0 N'],
  "wixson": ['W IH1 K S AH0 N'],
  "wixted": ['W IH1 K S T IH0 D'],
  "wiz": ['W IH1 Z', 'HH W IH1 Z'],
  "wizard": ['W IH1 Z ER0 D'],
  "wizardry": ['W IH1 Z ER0 D R IY0'],
  "wizards": ['W IH1 Z ER0 D Z'],
  "wizen": ['W AY1 Z AH0 N'],
  "wizened": ['W AY1 Z AH0 N D'],
  "wlodarczyk": ['W AH0 L AA1 D ER0 CH IH0 K'],
  "wlodarski": ['W AH0 L AH0 D AA1 R S K IY0'],
  "wm": ['W IH1 L Y AH0 M', 'D AH1 B AH0 Y UW0 EH1 M'],
  "wnek": ['W N EH1 K'],
  "wnuk": ['W N AH1 K'],
  "wo": ['W OW1', 'HH W OW1'],
  "wobbe": ['W AA1 B'],
  "wobble": ['W AA1 B AH0 L'],
  "wobbled": ['W AA1 B AH0 L D'],
  "wobbling": ['W AA1 B AH0 L IH0 NG', 'W AA1 B L IH0 NG'],
  "wobbly": ['W AA1 B AH0 L IY0'],
  "wobegon": ['W OW1 B AH0 G AA0 N'],
  "wobig": ['W OW1 B IH0 G'],
  "wobst": ['W AA1 B S T'],
  "woburn": ['W UW1 B ER0 N'],
  "wodehouse": ['W UH1 D HH AW0 S'],
  "wodehouse's": ['W UH1 D HH AW0 S AH0 Z'],
  "wodel": ['W AO1 D AH0 L'],
  "woe": ['W OW1'],
  "woebegone": ['W OW1 B IH0 G AO2 N'],
  "woeful": ['W OW1 F AH0 L'],
  "woefully": ['W OW1 F AH0 L IY0'],
  "woehl": ['W OW1 L'],
  "woehler": ['W OW1 L ER0'],
  "woehr": ['W AO1 R'],
  "woehrle": ['W AO1 R AH0 L'],
  "woelfel": ['W OW1 L F AH0 L'],
  "woelfle": ['W OW1 L F AH0 L'],
  "woerner": ['W AO1 R N ER0'],
  "woes": ['W OW1 Z'],
  "woessner": ['W OW1 S N ER0'],
  "woeste": ['W OW1 S T'],
  "wofford": ['W AA1 F ER0 D'],
  "wofford's": ['W AA1 F ER0 D Z'],
  "wogan": ['W OW1 G AH0 N'],
  "woggle": ['W AO1 G AH0 L'],
  "wogoman": ['W OW1 G OW0 M AH0 N'],
  "wohl": ['W OW1 L'],
  "wohler": ['W OW1 L ER0'],
  "wohlers": ['W OW1 L ER0 Z'],
  "wohlfarth": ['W OW1 L F AA2 R TH'],
  "wohlfeil": ['W OW1 L F AY2 L'],
  "wohlford": ['W OW1 L F ER0 D'],
  "wohlgemuth": ['W OW1 L G AH0 M UW0 TH'],
  "wohlstetter": ['W OW1 L S T EH2 T ER0'],
  "wohlwend": ['W OW1 L W EH0 N D'],
  "woitschatzke": ['W OY2 CH AE1 T S K IY2'],
  "wojahn": ['W OW1 HH AA0 N'],
  "wojciak": ['W OY1 CH IY0 AE0 K'],
  "wojcicki": ['W OY2 CH IH1 T S K IY0'],
  "wojciech": ['W OY1 CH EH0 K', 'V OY1 CH EH0 K'],
  "wojciechowski": ['W OY0 CH IH0 HH AO1 F S K IY0'],
  "wojcik": ['W OY1 CH IH0 K'],
  "wojdyla": ['W OY2 D IH1 L AH0'],
  "wojick": ['W OY1 CH IH0 K'],
  "wojnar": ['W OY1 N ER0'],
  "wojnarowski": ['W OY2 N ER0 AW1 S K IY0'],
  "wojnilower": ['W OY1 N AH0 L OW2 ER0'],
  "wojnowski": ['W OY2 N AW1 S K IY0'],
  "wojtas": ['W OY1 T AH0 S'],
  "wojtaszek": ['W OY2 T AA1 SH EH0 K'],
  "wojtkiewicz": ['W OY1 T K AH0 V IH0 CH'],
  "wojtkowski": ['W OY2 T K AW1 S K IY0'],
  "wojtowicz": ['W OY1 T AH0 V IH0 CH'],
  "wok": ['W AA1 K'],
  "woke": ['W OW1 K'],
  "woken": ['W OW1 K AH0 N'],
  "woks": ['W AA1 K S'],
  "wola": ['W OW1 L AH0'],
  "wolak": ['W OW1 L AH0 K'],
  "wolanin": ['W AA1 L AH0 N IH0 N'],
  "wolanski": ['V AH0 L AE1 N S K IY0'],
  "wolaver": ['W AA1 L AH0 V ER0'],
  "wolber": ['W OW1 L B ER0'],
  "wolbert": ['W OW1 L B ER0 T'],
  "wolcott": ['W OW1 L K AH0 T'],
  "wold": ['W OW1 L D'],
  "wolden": ['W OW1 L D AH0 N'],
  "woldt": ['W OW1 L T'],
  "wolenzac": ['W OW1 L AH0 N Z AE2 K'],
  "wolenzac's": ['W OW1 L AH0 N Z AE2 K S'],
  "wolf": ['W UH1 L F'],
  "wolf's": ['W UH1 L F S'],
  "wolf-dog": ['W UH1 L F D AO2 G'],
  "wolfarth": ['W UH1 L F AA0 R TH'],
  "wolfe": ['W UH1 L F'],
  "wolfe's": ['W UH1 L F S'],
  "wolfenbarger": ['W UH1 L F IH0 N B AA0 R G ER0'],
  "wolfenden": ['W UH1 L F EH2 N D AH0 N'],
  "wolfenschmidt": ['W UH1 L F AH0 N SH M IH2 T'],
  "wolfensohn": ['W UH1 L F AH0 N S AH0 N'],
  "wolfenstein": ['W UH1 L F AH0 N S T AY2 N'],
  "wolfer": ['W UH1 L F ER0'],
  "wolfert": ['W UH1 L F ER0 T'],
  "wolff": ['W UH1 L F'],
  "wolfgang": ['W UH1 L F G AE2 NG'],
  "wolfgram": ['W UH1 L F G R AE2 M'],
  "wolfinbarger": ['W UH1 L F IH0 N B AA2 R G ER0'],
  "wolfing": ['W UH1 L F IH2 NG'],
  "wolfinger": ['W UH1 L F IH0 NG ER2'],
  "wolfley": ['W UH1 L F L IY0'],
  "wolfman": ['W UH1 L F M AH0 N'],
  "wolford": ['W OW1 L F ER0 D'],
  "wolfram": ['W UH1 L F R AE2 M'],
  "wolfrey": ['W UH1 L F R IY0'],
  "wolfrom": ['W UH1 L F R AH0 M'],
  "wolfrum": ['W UH1 L F R AH0 M'],
  "wolfsburg": ['W UH1 L F S B ER0 G'],
  "wolfson": ['W UH1 L F S AH0 N'],
  "wolgamott": ['W OW1 L G AH0 M AA0 T'],
  "wolgast": ['W OW1 L G AH0 S T'],
  "wolgemuth": ['W OW1 L G IH0 M UW0 TH'],
  "wolin": ['W OW1 L IH0 N'],
  "wolinski": ['V AH0 L IH1 N S K IY0'],
  "wolinsky": ['V AH0 L IH1 N S K IY0'],
  "wolitarsky": ['W OW2 L IH0 T AA1 R S K IY0'],
  "wolk": ['W OW1 K'],
  "wolkanczik": ['W AH0 L K AE1 NG K Z IH0 K'],
  "wolkanczik's": ['W AH0 L K AE1 NG K Z IH0 K S'],
  "wolke": ['W OW1 L K'],
  "wolken": ['W AO1 L K AH0 N'],
  "wolkoff": ['W OW1 L K AO0 F'],
  "woll": ['W AA1 L'],
  "wollack": ['W AA1 L AH0 K'],
  "wollaeger": ['W AH0 L EY1 G ER0'],
  "wollam": ['W AA1 L AH0 M'],
  "wollard": ['W AA1 L ER0 D'],
  "wolle": ['W AA1 L'],
  "wollen": ['W AA1 L AH0 N'],
  "wollenberg": ['W AA1 L AH0 N B ER0 G'],
  "wollenweber": ['W AA1 L IH0 N W IH0 B ER0'],
  "woller": ['W AA1 L ER0'],
  "wollin": ['W AA1 L IH0 N'],
  "wollman": ['W AA1 L M AH0 N'],
  "wollner": ['W AA1 L N ER0'],
  "wollschlager": ['W AA1 L SH L EY0 G ER0'],
  "wolman": ['W AA1 L M AH0 N'],
  "wolohan": ['W AA1 L AH0 HH AE0 N'],
  "woloszyn": ['V AH0 L AA1 SH IH0 N'],
  "wolpe": ['W OW1 L P IY0'],
  "wolper": ['W OW1 L P ER0'],
  "wolpert": ['W OW1 L P ER0 T'],
  "wolrath": ['W OW1 L R AE2 TH'],
  "wolsey": ['W OW1 L S IY0'],
  "wolsfeld": ['W OW1 L Z F EH2 L D'],
  "wolske": ['W OW1 L S K'],
  "wolski": ['V OW1 L S K IY0'],
  "wolsky": ['V OW1 L S K IY0'],
  "wolstenholme": ['W OW1 L S T IH0 N HH OW0 L M'],
  "wolter": ['W OW1 L T ER0'],
  "wolters": ['W OW1 L T ER0 Z'],
  "woltman": ['W OW1 L T M AH0 N'],
  "woltz": ['W OW1 L T S'],
  "wolven": ['W UH1 L V AH0 N'],
  "wolverine": ['W UH2 L V ER0 IY1 N'],
  "wolverine's": ['W UH2 L V ER0 IY1 N Z'],
  "wolverton": ['W UH0 L V ER1 T AH0 N'],
  "wolves": ['W UH1 L V Z'],
  "wolz": ['W OW1 L Z'],
  "womac": ['W OW1 M AH0 K'],
  "womack": ['W OW1 M AE0 K'],
  "woman": ['W UH1 M AH0 N'],
  "woman's": ['W UH1 M AH0 N Z'],
  "womanhood": ['W UH1 M AH0 N HH UH2 D'],
  "womanize": ['W UH1 M AH0 N AY2 Z'],
  "womanizer": ['W UH1 M AH0 N AY2 Z ER0'],
  "womanizing": ['W UH1 M AH0 N AY2 Z IH0 NG'],
  "womb": ['W UW1 M'],
  "wombacher": ['W AA1 M B AA2 K ER0'],
  "wombat": ['W AA1 M B AE2 T'],
  "wombats": ['W AA1 M B AE2 T S'],
  "womble": ['W AA1 M B AH0 L'],
  "wombles": ['W AA1 M B AH0 L Z'],
  "women": ['W IH1 M AH0 N'],
  "women's": ['W IH1 M AH0 N Z'],
  "womens'": ['W IH1 M AH0 N Z'],
  "womer": ['W OW1 M ER0'],
  "wometco": ['W OW0 M EH1 T K OW0'],
  "wommack": ['W AA1 M AH0 K'],
  "won": ['W AH1 N', 'W AA1 N'],
  "won't": ['W OW1 N T'],
  "wond": ['W AO1 N D'],
  "wonda": ['W AA1 N D AH0'],
  "wonder": ['W AH1 N D ER0'],
  "wonder's": ['W AH1 N D ER0 Z'],
  "wondered": ['W AH1 N D ER0 D'],
  "wonderful": ['W AH1 N D ER0 F AH0 L'],
  "wonderfully": ['W AH1 N D ER0 F AH0 L IY0', 'W AH1 N D ER0 F L IY0'],
  "wonderfulness": ['W AH1 N D ER0 F AH0 L N AH0 S'],
  "wondering": ['W AH1 N D ER0 IH0 NG'],
  "wonderland": ['W AH1 N D ER0 L AE2 N D'],
  "wonderly": ['W AH1 N D ER0 L IY0'],
  "wonderment": ['W AH1 N D ER0 M AH0 N T'],
  "wonders": ['W AH1 N D ER0 Z'],
  "wondra": ['W AA1 N D R AH0'],
  "wondrous": ['W AH1 N D R AH0 S'],
  "wong": ['W AO1 NG'],
  "wong's": ['W AO1 NG Z'],
  "wonk": ['W AA1 N K'],
  "wonks": ['W AA1 N K S'],
  "wonksahachee": ['W AA0 N K S AH0 HH AE1 CH IY0'],
  "wonnacott": ['W AH1 N AH0 K AA0 T'],
  "wont": ['W OW1 N T'],
  "woo": ['W UW1'],
  "woo's": ['W UW1 Z'],
  "wood": ['W UH1 D'],
  "wood's": ['W UH1 D Z'],
  "woodall": ['W UH1 D AO2 L'],
  "woodard": ['W UH1 D ER0 D'],
  "woodbeck": ['W UH1 D B EH2 K'],
  "woodberry": ['W UH1 D B EH2 R IY0'],
  "woodbridge": ['W UH1 D B R IH2 JH'],
  "woodburn": ['W UH1 D B ER2 N'],
  "woodbury": ['W UH1 D B EH2 R IY0'],
  "woodby": ['W UH1 D B IY0'],
  "woodcarver": ['W UH1 D K AA2 R V ER0'],
  "woodcarvers": ['W UH1 D K AA2 R V ER0 Z'],
  "woodchip": ['W UH1 D CH IH2 P'],
  "woodchips": ['W UH1 D CH IH2 P S'],
  "woodchuck": ['W UH1 D CH AH2 K'],
  "woodchucks": ['W UH1 D CH AH2 K S'],
  "woodcliff": ['W UH1 D K L IH2 F'],
  "woodcock": ['W UH1 D K AA2 K'],
  "woodcox": ['W UH1 D K AA2 K S'],
  "wooddell": ['W UH1 D AH0 L'],
  "wooded": ['W UH1 D IH0 D'],
  "woodell": ['W UH1 D AH0 L'],
  "wooden": ['W UH1 D AH0 N'],
  "woodfield": ['W UH1 D F IY2 L D'],
  "woodfill": ['W UH1 D F IH2 L'],
  "woodfin": ['W UH1 D F IH0 N'],
  "woodford": ['W UH1 D F ER0 D'],
  "woodfork": ['W UH1 D F ER0 K'],
  "woodhall": ['W UH1 D HH AO2 L'],
  "woodham": ['W UH1 D HH AH0 M'],
  "woodhams": ['W UH1 D HH AH0 M Z'],
  "woodhead": ['W UH1 D HH EH2 D'],
  "woodhouse": ['W UH1 D HH AW2 S'],
  "woodhull": ['W UH1 D HH AH2 L'],
  "woodie": ['W UH1 D IY0'],
  "woodin": ['W UH1 D IH0 N'],
  "wooding": ['W UH1 D IH0 NG'],
  "woodington": ['W UH1 D IH0 NG T AH0 N'],
  "woodis": ['W UH1 D IH0 S'],
  "woodke": ['W UH1 D K IY0'],
  "woodland": ['W UH1 D L AE2 N D', 'W UH1 D L AH0 N D'],
  "woodland's": ['W UH1 D L AE2 N D Z'],
  "woodlands": ['W UH1 D L AE2 N D Z', 'W UH1 D L AH0 N D Z'],
  "woodle": ['W UH1 D AH0 L'],
  "woodlee": ['W UH1 D L IY2'],
  "woodley": ['W UH1 D L IY0'],
  "woodlief": ['W UH1 D L IY2 F'],
  "woodliff": ['W UH1 D L IH0 F'],
  "woodling": ['W UH1 D L IH0 NG'],
  "woodlock": ['W UH1 D L AA2 K'],
  "woodlot": ['W UH1 D L AA2 T'],
  "woodmac": ['W UH1 D M AE0 K'],
  "woodman": ['W UH1 D M AH0 N'],
  "woodmansee": ['W UH0 D M AH0 N S IY1'],
  "woodpecker": ['W UH1 D P EH2 K ER0'],
  "woodpeckers": ['W UH1 D P EH2 K ER0 Z'],
  "woodpile": ['W UH1 D P AY0 L'],
  "woodpiles": ['W UH1 D P AY0 L Z'],
  "woodrich": ['W UH1 D R IH2 CH'],
  "woodrick": ['W UH1 D R IH0 K'],
  "woodring": ['W UH1 D R IH2 NG'],
  "woodrome": ['W UH1 D R AH0 M'],
  "woodroof": ['W UH1 D R UW2 F'],
  "woodrow": ['W UH1 D R OW2'],
  "woodruff": ['W UH1 D R AH0 F'],
  "woodrum": ['W UH1 D R AH0 M'],
  "woods": ['W UH1 D Z'],
  "woods's": ['W UH1 D Z IH0 Z'],
  "woodshed": ['W UH1 D SH EH2 D'],
  "woodsheds": ['W UH1 D SH EH2 D Z'],
  "woodside": ['W UH1 D S AY2 D'],
  "woodsmall": ['W UH1 D S M AO2 L'],
  "woodsman": ['W UH1 D Z M AE0 N'],
  "woodsmen": ['W UH1 D Z M AH0 N'],
  "woodsmen's": ['W UH1 D Z M AH0 N Z'],
  "woodson": ['W UH1 D S AH0 N'],
  "woodson's": ['W UH1 D S AH0 N Z'],
  "woodstock": ['W UH1 D S T AA2 K'],
  "woodstream": ['W UH1 D S T R IY2 M'],
  "woodward": ['W UH1 D W AO2 R D', 'W UH1 D W ER0 D'],
  "woodward's": ['W UH1 D W ER0 D Z'],
  "woodwind": ['W UH1 D W IH2 N D'],
  "woodwind's": ['W UH1 D W IH2 N D Z'],
  "woodwinds": ['W UH1 D W IH2 N D Z'],
  "woodwork": ['W UH1 D W ER2 K'],
  "woodworker": ['W UH1 D W ER2 K ER0'],
  "woodworkers": ['W UH1 D W ER2 K ER0 Z'],
  "woodworking": ['W UH1 D W ER2 K IH0 NG'],
  "woodworth": ['W UH1 D W ER2 TH'],
  "woody": ['W UH1 D IY0'],
  "woody's": ['W UH1 D IY0 Z'],
  "woodyard": ['W UH1 D Y AA2 R D'],
  "wooed": ['W UW1 D'],
  "woof": ['W UW1 F'],
  "woofer": ['W UH1 F ER0'],
  "woofers": ['W UH1 F ER0 Z'],
  "woofter": ['W UW1 F T ER0'],
  "woogie": ['W UW1 G IY0', 'W UH1 G IY0'],
  "wooing": ['W UW1 IH0 NG'],
  "wool": ['W UH1 L'],
  "woolard": ['W UH1 L ER0 D'],
  "woolbright": ['W UH1 L B R AY2 T'],
  "woolco": ['W UH1 L K OW2'],
  "woolcock": ['W UH1 L K AA2 K'],
  "wooldridge": ['W UH1 L D R IH0 JH'],
  "woolen": ['W UH1 L AH0 N'],
  "woolens": ['W UH1 L AH0 N Z'],
  "woolery": ['W UH1 L ER0 IY0'],
  "woolever": ['W UH1 L EH2 V ER0'],
  "wooley": ['W UH1 L IY0'],
  "woolf": ['W UH1 L F'],
  "woolfolk": ['W UH1 L F OW2 K'],
  "woolford": ['W UH1 L F ER0 D'],
  "woolfork": ['W UH1 L F AO2 R K'],
  "woollard": ['W UH1 L ER0 D'],
  "woollcott": ['W UH1 L K AA0 T'],
  "woollen": ['W UH1 L AH0 N'],
  "woolley": ['W UH1 L IY0'],
  "woolly": ['W UH1 L IY0'],
  "woolman": ['W UH1 L M AH0 N'],
  "woolridge": ['W UH1 L R IH2 JH'],
  "wools": ['W UH1 L Z'],
  "woolsey": ['W UH1 L Z IY0'],
  "woolsey's": ['W UH1 L Z IY0 Z'],
  "woolson": ['W UH1 L S AH0 N'],
  "woolston": ['W UH1 L S T AH0 N'],
  "woolum": ['W UH1 L AH0 M'],
  "woolums": ['W UH1 L AH0 M Z'],
  "woolverton": ['W UH1 L V ER0 T AH0 N'],
  "woolwine": ['W UH1 L W AY2 N'],
  "woolworth": ['W UH1 L W ER2 TH'],
  "woolworth's": ['W UH1 L W ER2 TH S'],
  "wooly": ['W UH1 L IY0'],
  "woomer": ['W UW1 M ER0'],
  "woong": ['W UW1 NG'],
  "woos": ['W UW1 Z'],
  "woosley": ['W UW1 Z L IY0'],
  "wooster": ['W UW1 S T ER0'],
  "wootan": ['W UW1 T AH0 N'],
  "wooten": ['W UW1 T AH0 N'],
  "wooters": ['W UW1 T ER0 Z'],
  "wooton": ['W UW1 T AH0 N'],
  "wootten": ['W UW1 T AH0 N'],
  "wootton": ['W UW1 T AH0 N'],
  "woozy": ['W UW1 Z IY0'],
  "wop": ['W AA1 P'],
  "wops": ['W AA1 P S'],
  "wor": ['W AO1 R', 'D AH1 B EH0 L Y UW1 OW1 AA1 R', 'D AH1 B AH0 Y UW1 OW1 AA1 R'],
  "worcester": ['W UH1 S T ER0'],
  "worcestershire": ['W UH1 S T AH0 SH ER0'],
  "word": ['W ER1 D'],
  "word's": ['W ER1 D Z'],
  "worded": ['W ER1 D IH0 D'],
  "wordell": ['W ER1 D AH0 L'],
  "worden": ['W ER1 D AH0 N'],
  "wording": ['W ER1 D IH0 NG'],
  "wordless": ['W ER1 D L AH0 S'],
  "wordperfect": ['W ER1 D P ER1 F EH0 K T'],
  "wordpress": ['W ER1 D P R IH0 S'],
  "words": ['W ER1 D Z'],
  "words'": ['W ER1 D Z'],
  "wordsmith": ['W ER1 D S M IH2 TH'],
  "wordstar": ['W ER1 D S T AA2 R'],
  "wordsworth": ['W ER1 D Z W ER0 TH'],
  "wordy": ['W ER1 D IY0'],
  "wore": ['W AO1 R'],
  "work": ['W ER1 K'],
  "work's": ['W ER1 K S'],
  "workable": ['W ER1 K AH0 B AH0 L'],
  "workaday": ['W ER1 K AH0 D EY2'],
  "workaholic": ['W ER2 K AH0 HH AA1 L IH0 K'],
  "workaholics": ['W ER2 K AH0 HH AA1 L IH0 K S'],
  "workaround": ['W ER1 K ER0 AW1 N D'],
  "workbench": ['W ER1 K B EH2 N CH'],
  "workbook": ['W ER1 K B UH2 K'],
  "workbooks": ['W ER1 K B UH2 K S'],
  "workday": ['W ER1 K D EY2'],
  "workdays": ['W ER1 K D EY2 Z'],
  "worked": ['W ER1 K T'],
  "worker": ['W ER1 K ER0'],
  "worker's": ['W ER1 K ER0 Z'],
  "workers": ['W ER1 K ER0 Z'],
  "workers'": ['W ER1 K ER0 Z'],
  "workfare": ['W ER1 K F EH2 R'],
  "workflow": ['W ER1 K F L OW2'],
  "workflows": ['W ER1 K F L OW2 Z'],
  "workforce": ['W ER1 K F AO2 R S'],
  "workgroup": ['W ER1 K G R UW2 P'],
  "workgroups": ['W ER1 K G R UW2 P S'],
  "workhorse": ['W ER1 K HH AO2 R S'],
  "workhorses": ['W ER1 K HH AO2 R S IH0 Z'],
  "workin'": ['W ER1 K AH0 N'],
  "working": ['W ER1 K IH0 NG'],
  "workinger": ['W ER1 K IH0 NG ER0'],
  "workings": ['W ER1 K IH0 NG Z'],
  "workload": ['W ER1 K L OW2 D'],
  "workloads": ['W ER1 K L OW2 D Z'],
  "workman": ['W ER1 K M AH0 N'],
  "workman's": ['W ER1 K M AH0 N Z'],
  "workmanlike": ['W ER1 K M AE2 N L AY2 K'],
  "workmanship": ['W ER1 K M AH0 N SH IH2 P'],
  "workmen": ['W ER1 K M IH0 N'],
  "workmen's": ['W ER1 K M AH0 N Z'],
  "workout": ['W ER1 K AW2 T'],
  "workouts": ['W ER1 K AW2 T S'],
  "workplace": ['W ER1 K P L EY2 S'],
  "workplaces": ['W ER1 K P L EY2 S IH0 Z'],
  "workroom": ['W ER1 K R UW2 M'],
  "works": ['W ER1 K S'],
  "works'": ['W ER1 K S'],
  "worksheet": ['W ER1 K SH IY2 T'],
  "worksheets": ['W ER1 K SH IY2 T S'],
  "workshop": ['W ER1 K SH AA2 P'],
  "workshops": ['W ER1 K SH AA2 P S'],
  "workspace": ['W ER1 K S P EY2 S'],
  "workstation": ['W ER1 K S T EY2 SH AH0 N'],
  "workstations": ['W ER1 K S T EY2 SH AH0 N Z'],
  "workweek": ['W ER1 K W IY2 K'],
  "workweeks": ['W ER1 K W IY2 K S'],
  "worland": ['W ER1 L AH0 N D'],
  "worlco": ['W ER1 L K OW0'],
  "world": ['W ER1 L D'],
  "world's": ['W ER1 L D Z'],
  "world-class": ['W ER1 L D K L AE2 S'],
  "worldcom": ['W ER1 L D K AA2 M'],
  "worldcorp": ['W ER1 L D K AO2 R P'],
  "worlder": ['W ER1 L D ER0'],
  "worlders": ['W ER1 L D ER0 Z'],
  "worldliness": ['W ER1 L D L IY0 N AH0 S'],
  "worldly": ['W ER1 L D L IY0'],
  "worldnews": ['W ER1 L D N UW1 Z'],
  "worldpass": ['W ER1 L D P AE2 S'],
  "worlds": ['W ER1 L D Z'],
  "worldscope": ['W ER1 L D S K OW2 P'],
  "worldspan": ['W ER1 L D S P AE2 N'],
  "worldview": ['W ER1 L D V Y UW2'],
  "worldvision": ['W ER1 L D V IH2 ZH AH0 N'],
  "worldwide": ['W ER1 L D W AY1 D'],
  "worldwide's": ['W ER1 L D W AY2 D Z'],
  "worley": ['W ER1 L IY0'],
  "worm": ['W ER1 M'],
  "worm's": ['W ER1 M Z'],
  "worman": ['W ER1 M AH0 N'],
  "wormhole": ['W ER1 M HH AO2 L'],
  "wormholes": ['W ER1 M HH AO2 L Z'],
  "wormington": ['W ER1 M IH0 NG T AH0 N'],
  "wormley": ['W ER1 M L IY0'],
  "wormlike": ['W ER1 M L AY2 K'],
  "worms": ['W ER1 M Z'],
  "wormser": ['W ER1 M Z ER0'],
  "wormuth": ['W ER1 M UW0 TH'],
  "wormwood": ['W ER1 M W UH2 D'],
  "worn": ['W AO1 R N'],
  "worner": ['W AO1 R N ER0'],
  "woronoff": ['W AO1 R AH0 N AO0 F'],
  "worrall": ['W ER1 R AH0 L'],
  "worrel": ['W ER1 R AH0 L'],
  "worrell": ['W ER1 R AH0 L'],
  "worried": ['W ER1 IY0 D'],
  "worrier": ['W ER1 IY0 ER0'],
  "worriers": ['W ER1 IY0 ER0 Z'],
  "worries": ['W ER1 IY0 Z'],
  "worrisome": ['W ER1 IY0 S AH0 M'],
  "worry": ['W ER1 IY0'],
  "worrying": ['W ER1 IY0 IH0 NG'],
  "worse": ['W ER1 S'],
  "worseck": ['W ER1 S EH0 K', 'W AO1 R S EH0 K'],
  "worsen": ['W ER1 S AH0 N'],
  "worsened": ['W ER1 S AH0 N D'],
  "worsening": ['W ER1 S AH0 N IH0 NG', 'W ER1 S N IH0 NG'],
  "worsens": ['W ER1 S AH0 N Z'],
  "worsham": ['W ER1 SH AH0 M'],
  "worship": ['W ER1 SH AH0 P', 'W ER1 SH IH0 P'],
  "worshiped": ['W ER1 SH IH0 P T'],
  "worshiper": ['W ER1 SH AH0 P ER0'],
  "worshipers": ['W ER1 SH IH0 P ER0 Z'],
  "worshipful": ['W ER1 SH AH0 P F AH0 L'],
  "worshiping": ['W ER1 SH AH0 P IH2 NG'],
  "worshipped": ['W ER1 SH AH0 P T'],
  "worshippers": ['W ER1 SH IH0 P ER0 Z'],
  "worshipping": ['W ER1 SH AH0 P IH0 NG'],
  "worships": ['W ER1 SH AH0 P S'],
  "worsley": ['W ER1 S L IY0'],
  "worst": ['W ER1 S T'],
  "worst-case": ['W ER1 S T K EY1 S'],
  "worsted": ['W ER1 S T IH0 D'],
  "worstell": ['W ER1 S T AH0 L'],
  "worster": ['W ER1 S T ER0'],
  "worth": ['W ER1 TH'],
  "worth's": ['W ER1 TH S'],
  "wortham": ['W ER1 TH AH0 M'],
  "worthen": ['W ER1 TH AH0 N'],
  "worthen's": ['W ER1 TH AH0 N Z'],
  "worthey": ['W ER1 DH IY0'],
  "worthier": ['W ER1 DH IY0 ER0'],
  "worthies": ['W ER1 DH IY0 Z'],
  "worthiest": ['W ER1 DH IY0 AH0 S T'],
  "worthiness": ['W ER1 DH IY0 N IH0 S'],
  "worthing": ['W ER1 TH IH0 NG'],
  "worthington": ['W ER1 DH IH0 NG T AH0 N'],
  "worthless": ['W ER1 TH L AH0 S'],
  "worthley": ['W ER1 TH L IY0'],
  "worthman": ['W ER1 TH M AH0 N'],
  "worthog": ['W AO1 R T HH AO0 G'],
  "worths": ['W ER1 TH S'],
  "worthwhile": ['W ER1 TH W AY1 L'],
  "worthy": ['W ER1 DH IY0'],
  "wortley": ['W ER1 T L IY0'],
  "wortman": ['W ER1 T M AH0 N'],
  "wortmann": ['W ER1 T M AH0 N'],
  "worton": ['W ER1 T AH0 N'],
  "worts": ['W ER1 T S'],
  "wotan": ['W OW1 T AH0 N'],
  "wotherspoon": ['W AH0 DH ER0 S P UW1 N'],
  "wotring": ['W AA1 T ER0 IH0 NG'],
  "wotton": ['W AA1 T AH0 N'],
  "wouk": ['W UW1 K', 'W OW1 K'],
  "would": ['W UH1 D'],
  "would've": ['W UH1 D AH0 V'],
  "wouldn't": ['W UH1 D AH0 N T'],
  "woulfe": ['W AW1 L F'],
  "wound": ['W AW1 N D', 'W UW1 N D'],
  "wounded": ['W UW1 N D IH0 D'],
  "wounding": ['W UW1 N D IH0 NG'],
  "wounds": ['W UW1 N D Z'],
  "wouters": ['W AW1 T ER0 Z'],
  "wove": ['W OW1 V'],
  "woven": ['W OW1 V AH0 N'],
  "wow": ['W AW1'],
  "wow's": ['W AW1 Z'],
  "wowed": ['W AW1 D'],
  "wowie": ['W AW1 IY0'],
  "wowing": ['W AW1 IH0 NG'],
  "wows": ['W AW1 Z'],
  "woy": ['W OY1'],
  "wozniak": ['W AA1 Z N IY0 AE0 K'],
  "woznick": ['W AA1 Z N IH0 K'],
  "woznicki": ['W AH0 Z N IH1 T S K IY0'],
  "wozny": ['W AA1 Z N IY0'],
  "wrack": ['R AE1 K'],
  "wracked": ['R AE1 K T'],
  "wracking": ['R AE1 K IH0 NG'],
  "wrage": ['R EY1 JH'],
  "wragg": ['R AE1 G'],
  "wrandell": ['R AE1 NG D EH0 L'],
  "wrangell": ['R AE1 NG G AH0 L'],
  "wrangle": ['R AE1 NG G AH0 L'],
  "wrangled": ['R AE1 NG G AH0 L D'],
  "wrangler": ['R AE1 NG G AH0 L ER0', 'R AE1 NG G L ER0'],
  "wranglers": ['R AE1 NG G AH0 L ER0 Z', 'R AE1 NG G L ER0 Z'],
  "wrangles": ['R AE1 NG G AH0 L Z'],
  "wrangling": ['R AE1 NG G AH0 L IH0 NG', 'R AE1 NG G L IH0 NG'],
  "wranglings": ['R AE1 NG G AH0 L IH0 NG Z', 'R AE1 NG G L IH0 NG Z'],
  "wrap": ['R AE1 P'],
  "wrapped": ['R AE1 P T'],
  "wrapper": ['R AE1 P ER0'],
  "wrappers": ['R AE1 P ER0 Z'],
  "wrapping": ['R AE1 P IH0 NG'],
  "wraps": ['R AE1 P S'],
  "wrapup": ['R AE1 P AH0 P'],
  "wrath": ['R AE1 TH'],
  "wrather": ['R AE1 DH ER0'],
  "wray": ['R EY1'],
  "wreak": ['R IY1 K'],
  "wreaked": ['R IY1 K T'],
  "wreaking": ['R IY1 K IH0 NG'],
  "wreaks": ['R IY1 K S'],
  "wreath": ['R IY1 TH'],
  "wreaths": ['R IY1 TH S', 'R IY1 DH Z'],
  "wreck": ['R EH1 K'],
  "wreckage": ['R EH1 K AH0 JH', 'R EH1 K IH0 JH'],
  "wrecked": ['R EH1 K T'],
  "wrecker": ['R EH1 K ER0'],
  "wrecking": ['R EH1 K IH0 NG'],
  "wrecks": ['R EH1 K S'],
  "wrede": ['R IY1 D'],
  "wren": ['R EH1 N'],
  "wrench": ['R EH1 N CH'],
  "wrenched": ['R EH1 N CH T'],
  "wrenches": ['R EH1 N CH IH0 Z'],
  "wrenching": ['R EH1 N CH IH0 NG'],
  "wrenn": ['R EH1 N'],
  "wrest": ['R EH1 S T'],
  "wrested": ['R EH1 S T IH0 D'],
  "wresting": ['R EH1 S T IH0 NG'],
  "wrestle": ['R EH1 S AH0 L'],
  "wrestled": ['R EH1 S AH0 L D'],
  "wrestler": ['R EH1 S AH0 L ER0', 'R EH1 S L ER0'],
  "wrestlers": ['R EH1 S L ER0 Z', 'R EH1 S AH0 L ER0 Z'],
  "wrestles": ['R EH1 S AH0 L Z'],
  "wrestling": ['R EH1 S L IH0 NG', 'R EH1 S AH0 L IH0 NG'],
  "wretch": ['R EH1 CH'],
  "wretched": ['R EH1 CH IH0 D'],
  "wretchedly": ['R EH1 CH IH0 D L IY0'],
  "wrice": ['R AY1 S'],
  "wriggle": ['R IH1 G AH0 L'],
  "wright": ['R AY1 T'],
  "wright's": ['R AY1 T S'],
  "wrights": ['R AY1 T S'],
  "wrightsman": ['R AY1 T S M AH0 N'],
  "wrightson": ['R AY1 T S AH0 N'],
  "wrigley": ['R IH1 G L IY0'],
  "wrigley's": ['R IH1 G L IY0 Z'],
  "wring": ['R IH1 NG'],
  "wringer": ['R IH1 NG ER0'],
  "wringing": ['R IH1 NG IH0 NG'],
  "wrinkle": ['R IH1 NG K AH0 L'],
  "wrinkled": ['R IH1 NG K AH0 L D'],
  "wrinkles": ['R IH1 NG K AH0 L Z'],
  "wrinkling": ['R IH1 NG K AH0 L IH0 NG', 'R IH1 NG K L IH0 NG'],
  "wrisley": ['R IH1 Z L IY0'],
  "wrist": ['R IH1 S T'],
  "wriston": ['R IH1 S T AH0 N'],
  "wrists": ['R IH1 S T S'],
  "wristwatch": ['R IH1 S T W AA2 CH'],
  "wristwatches": ['R IH1 S T W AA2 CH IH0 Z'],
  "writ": ['R IH1 T'],
  "write": ['R AY1 T'],
  "writedown": ['R AY1 T D AW2 N'],
  "writedowns": ['R AY1 T D AW2 N Z'],
  "writeoff": ['R AY1 T AO0 F'],
  "writeoffs": ['R AY1 T AO0 F S'],
  "writer": ['R AY1 T ER0'],
  "writer's": ['R AY1 T ER0 Z'],
  "writers": ['R AY1 T ER0 Z'],
  "writers'": ['R AY1 T ER0 Z'],
  "writes": ['R AY1 T S'],
  "writhe": ['R IH1 TH'],
  "writhed": ['R IH1 TH D'],
  "writhing": ['R AY1 DH IH0 NG', 'R IH1 TH IH0 NG'],
  "writing": ['R AY1 T IH0 NG'],
  "writings": ['R AY1 T IH0 NG Z'],
  "writs": ['R IH1 T S'],
  "written": ['R IH1 T AH0 N'],
  "wrobel": ['R OW1 B AH0 L'],
  "wrobleski": ['R AH0 B L EH1 S K IY0'],
  "wroblewski": ['R AH0 B L UW1 F S K IY0'],
  "wroclaw": ['R AA1 K L AO2'],
  "wroe": ['R OW1'],
  "wrona": ['R OW1 N AH0'],
  "wrong": ['R AO1 NG'],
  "wrongdoer": ['R AO1 NG D UW1 ER0'],
  "wrongdoers": ['R AO1 NG D UW1 ER0 Z'],
  "wrongdoing": ['R AO1 NG D UW0 IH0 NG'],
  "wrongdoings": ['R AO0 NG D UW1 IH0 NG Z'],
  "wronged": ['R AO1 NG D'],
  "wrongful": ['R AO1 NG F AH0 L'],
  "wrongfully": ['R AO1 NG F AH0 L IY0'],
  "wrongheaded": ['R AO1 NG HH EH2 D IH0 D'],
  "wrongly": ['R AO1 NG L IY0'],
  "wrongs": ['R AO1 NG Z'],
  "wronski": ['R AA1 N S K IY0'],
  "wrote": ['R OW1 T'],
  "wroten": ['R OW1 T AH0 N'],
  "wrought": ['R AO1 T'],
  "wrubel": ['R UW1 B AH0 L'],
  "wruck": ['R AH1 K'],
  "wrung": ['R AH1 NG'],
  "wry": ['R AY1'],
  "wrye": ['R AY1'],
  "wryly": ['R AY1 L IY0'],
  "wryness": ['R AY1 N AH0 S'],
  "wrzesinski": ['R AH0 Z IH0 S IH1 N S K IY0'],
  "ws": ['D AH1 B AH0 L Y UW0 EH1 S', 'D AH1 B Y AH0 EH1 S'],
  "wu": ['W UW1'],
  "wu's": ['W UW1 Z'],
  "wubben": ['W AH1 B AH0 N'],
  "wuellner": ['W UH1 L N ER0'],
  "wuensch": ['W UH1 N SH'],
  "wuerth": ['W UH1 R TH'],
  "wuerttemberg": ['W ER1 T AH0 M B ER0 G'],
  "wuertz": ['W UH1 R T S'],
  "wuest": ['W UW1 IH0 S T'],
  "wuhan": ['W UW1 AH0 N'],
  "wujcik": ['W AH1 JH S IH0 K'],
  "wulf": ['W UH1 L F'],
  "wulff": ['W UH1 L F'],
  "wuli": ['W UW1 L IY0'],
  "wuliger": ['W UW1 L IH0 G ER0'],
  "wunder": ['W AH1 N D ER0'],
  "wunderkind": ['W AH1 N D ER0 K AY2 N D', 'W AH1 N D ER0 K IH2 N D'],
  "wunderle": ['W AH1 N D ER0 L IY0'],
  "wunderlich": ['W AH1 N D ER0 L IH0 K'],
  "wunderlin": ['W AH1 N D ER0 L IH0 N'],
  "wunsch": ['W AH1 N SH'],
  "wunschel": ['W AH1 N SH AH0 L'],
  "wurdeman": ['W ER1 D M AH0 N'],
  "wurl": ['W ER1 L'],
  "wurlitzer": ['W ER1 L IH0 T S ER0'],
  "wurm": ['W ER1 M'],
  "wurst": ['W ER1 S T'],
  "wurster": ['W ER1 S T ER0'],
  "wursthaus": ['W ER1 S T HH AW0 S'],
  "wurth": ['W ER1 TH'],
  "wurtman": ['W ER1 T M AH0 N'],
  "wurtz": ['W ER1 T S'],
  "wurtzel": ['W ER1 T Z AH0 L'],
  "wurz": ['W ER1 Z'],
  "wurzel": ['W ER1 Z AH0 L'],
  "wurzer": ['W ER1 Z ER0'],
  "wuss": ['W AH1 S'],
  "wussler": ['W AH1 S L ER0'],
  "wussow": ['W AH1 S OW0'],
  "wuthering": ['W AH1 DH ER0 IH0 NG'],
  "wuthrich": ['W AH1 TH R IH0 K'],
  "wuttke": ['W AH1 T K IY0'],
  "wuxi": ['W AH1 K S IY0'],
  "wuyda": ['W OY1 D AH0'],
  "wuzzy": ['W UH1 Z IY0'],
  "wy": ['W EY1', 'D AH1 B AH0 L Y UW0 W AY1'],
  "wyan": ['W AY1 AH0 N'],
  "wyand": ['W AY1 AH0 N D'],
  "wyant": ['W AY1 AH0 N T'],
  "wyatt": ['W AY1 AH0 T'],
  "wyatt's": ['W AY1 AH0 T S'],
  "wyble": ['W AY1 B AH0 L'],
  "wyborn": ['V IH1 B ER0 N'],
  "wyche": ['W AY1 CH'],
  "wyckoff": ['W IH1 K AO0 F'],
  "wycliff": ['W IH1 K L IH0 F'],
  "wycoff": ['W IH1 K AO0 F'],
  "wyden": ['W AY1 D', 'W AY1 D AH0 N'],
  "wydra": ['W AY1 D R AH0'],
  "wye": ['W AY1'],
  "wyer": ['W AY1 ER0'],
  "wyers": ['W AY1 ER0 Z'],
  "wyeth": ['W AY1 EH0 TH'],
  "wyffels": ['W IH1 F AH0 L Z'],
  "wygal": ['W AY1 G AH0 L'],
  "wygant": ['W AY1 G AH0 N T'],
  "wygod": ['W AY1 G AA2 D'],
  "wyk": ['W IH1 K'],
  "wyka": ['V IH1 K AH0'],
  "wyke": ['W AY1 K'],
  "wykes": ['W AY1 K S'],
  "wykle": ['W AY1 K AH0 L'],
  "wykoff": ['W IH1 K AO0 F'],
  "wyland": ['W AY1 L AH0 N D'],
  "wylde": ['W AY1 L D'],
  "wyle": ['W AY1 L'],
  "wyler": ['W AY1 L ER0'],
  "wyles": ['W AY1 L Z'],
  "wylie": ['W AY1 L IY0'],
  "wyllie": ['W IH1 L IY0'],
  "wylma": ['W AY1 L M AH0'],
  "wyly": ['W AY1 L IY0'],
  "wyman": ['W AY1 M AH0 N'],
  "wymer": ['W AY1 M ER0'],
  "wymore": ['W AY1 M AO0 R'],
  "wyndham": ['W IH1 N D AH0 M'],
  "wyne": ['W AY1 N'],
  "wyner": ['W AY1 N ER0'],
  "wynes": ['W AY1 N Z'],
  "wynette": ['W IH0 N EH1 T', 'HH W IH0 N EH1 T'],
  "wyngaarden": ['W IH1 N G AA2 R D AH0 N'],
  "wynia": ['W IH1 N IY0 AH0'],
  "wynkoop": ['W IH1 NG K UW2 P'],
  "wynn": ['W IH1 N'],
  "wynn's": ['W IH1 N Z'],
  "wynne": ['W IH1 N'],
  "wynnona": ['W AY0 N OW1 N AH0'],
  "wynns": ['W IH1 N Z'],
  "wynona": ['W AY0 N OW1 N AH0', 'HH W AY0 N OW1 N AH0'],
  "wynonna": ['W AY0 N OW1 N AH0'],
  "wynter": ['W IH1 N T ER0'],
  "wynton": ['W IH1 N T AH0 N'],
  "wynyard": ['W IH1 N Y ER0 D'],
  "wyoming": ['W AY2 OW1 M IH0 NG'],
  "wyoming's": ['W AY2 OW1 M IH0 NG Z'],
  "wyre": ['W AY1 R'],
  "wyrick": ['W IH1 R IH0 K'],
  "wyse": ['W AY1 Z'],
  "wyse's": ['W AY1 Z IH0 Z'],
  "wyser": ['W AY1 Z ER0'],
  "wysiwig": ['W IH1 Z IY2 W IH2 G'],
  "wysocki": ['V IH0 S OW1 T S K IY0', 'V IH0 S AA1 T S K IY0'],
  "wysong": ['W IH1 S AO0 NG'],
  "wyss": ['W IH1 S'],
  "wyszynski": ['V IH0 SH IH1 N S K IY0'],
  "wythe": ['W AY1 DH'],
  "x": ['EH1 K S'],
  "x's": ['EH1 K S IH0 Z'],
  "x-acto": ['EH0 G Z AE1 K T OW0'],
  "x-ray": ['EH1 K S R EY2'],
  "x-rays": ['EH1 K S R EY2 Z'],
  "x.": ['EH1 K S', 'AE1 K S'],
  "x.'s": ['EH1 K S IH0 Z'],
  "x.ers": ['EH1 K S ER0 Z'],
  "x.s": ['EH1 K S IH0 Z'],
  "xan": ['SH AA1 N'],
  "xanada": ['Z AH0 N AA1 D AH0'],
  "xanadu": ['Z AA1 N AH0 D UW2'],
  "xanax": ['Z AE1 N AE0 K S'],
  "xanthe": ['Z AE1 N DH'],
  "xantippe": ['Z AE1 N T IH0 P'],
  "xaver": ['Z EY1 V ER0'],
  "xavier": ['Z EY1 V Y ER0'],
  "xaviera": ['Z EY0 V Y EH1 R AH0'],
  "xbox": ['EH1 K S B AA2 K S'],
  "xebec": ['Z EH1 B AH0 K'],
  "xena": ['Z IY1 N AH0'],
  "xene": ['Z IY1 N'],
  "xenia": ['Z IY1 N IY0 AH0'],
  "xenon": ['Z IY1 N AA0 N'],
  "xenophobia": ['Z EH2 N AH0 F OW1 B IY0 AH0'],
  "xenophobic": ['Z EH2 N AH0 F AA1 B IH0 K'],
  "xenophon": ['Z EH1 N AH0 F AH0 N'],
  "xenos": ['Z IY1 N OW0 Z'],
  "xeriscape": ['Z EH1 R IH0 S K EY2 P'],
  "xero": ['Z EH1 R OW0'],
  "xerox": ['Z IH1 R AA0 K S'],
  "xerox's": ['Z IH1 R AA0 K S IH0 Z'],
  "xeroxed": ['Z IH1 R AA0 K S T'],
  "xeroxes": ['Z IH1 R AA0 K S IH0 Z'],
  "xeroxing": ['Z IH1 R AA0 K S IH0 NG'],
  "xers": ['EH1 K S EH0 R Z'],
  "xerxes": ['Z ER1 K S IY0 Z'],
  "xhosa": ['Z OW1 SH AH0'],
  "xi": ['SH IY1'],
  "xiamen": ['Z IY0 EY1 M AH0 N'],
  "xian": ['Z Y AA1 N'],
  "xiang": ['SH IY1 AA0 NG'],
  "xiao": ['ZH AW1'],
  "xiaogang": ['ZH AW1 G AE1 NG'],
  "xiaoping": ['ZH AW1 P IH1 NG'],
  "xiaoping's": ['ZH AW1 P IH1 NG Z'],
  "xiaoyun": ['SH AW1 Y UW1 N'],
  "xicor": ['Z AY1 K AO2 R'],
  "xidex": ['Z IH1 D AH0 K S'],
  "xie": ['Z IY1'],
  "xilinx": ['Z AY1 L IH2 NG K S'],
  "xing": ['SH IH1 NG'],
  "xinhua": ['Z IH1 N HH Y UW0 AH0'],
  "xinjiang": ['Z IH1 N JH IY0 AE1 NG'],
  "xio": ['ZH AO1'],
  "xiong": ['ZH AO1 NG'],
  "xml": ['EH2 K S EH2 M EH1 L'],
  "xoma": ['Z OW1 M AH0', 'IH0 G Z OW1 M AH0', 'EH0 K S OW1 M AH0'],
  "xoma's": ['Z OW1 M AH0 Z', 'IH0 G Z OW1 M AH0 Z', 'EH0 K S OW1 M AH0 Z'],
  "xscribe": ['EH0 K S K R AY1 B'],
  "xtra": ['EH1 K S T R AH0'],
  "xu": ['Z UW1'],
  "xuan": ['ZH W AA1 N'],
  "xudong": ['Z UW1 D AO1 NG', 'ZH W EY1 D AO1 NG'],
  "xue": ['Z UW1'],
  "xuedong": ['Z UW0 D AO1 NG'],
  "xuemei": ['Z UW1 M AY1'],
  "xus": ['Z AH1 S'],
  "xylia": ['Z AY1 L IY0 AH0'],
  "xylina": ['Z AY1 L IH0 N AH0'],
  "xylogic": ['Z AY2 L AA1 JH IH0 K'],
  "xylogics": ['Z AY2 L AA1 JH IH0 K S'],
  "xylon": ['Z AY1 L AH0 N'],
  "xylona": ['Z AY1 L AH0 N AH0'],
  "xylophone": ['Z AY1 L AH0 F OW2 N'],
  "xyvision": ['Z AY1 V IH1 ZH AH0 N'],
  "y": ['W AY1'],
  "y'all": ['Y AO2 L'],
  "y'know": ['Y AH0 N OW1'],
  "y's": ['W AY1 Z'],
  "y.": ['W AY1'],
  "y.'s": ['W AY1 Z'],
  "ya": ['Y AA1'],
  "ya'll": ['Y AA1 L'],
  "yaacov": ['Y AA1 K OW2 V'],
  "yabba": ['Y AE1 B AH0'],
  "yabbadabbadoo": ['Y AE1 B AH0 D AE1 B AH0 D UW1'],
  "yablon": ['Y AE1 B L AH0 N'],
  "yablonski": ['Y AH0 B L AA1 N S K IY0'],
  "yablonsky": ['Y AH0 B L AA1 N S K IY0'],
  "yacht": ['Y AA1 T'],
  "yachter": ['Y AA1 T ER0'],
  "yachters": ['Y AA1 T ER0 Z'],
  "yachting": ['Y AA1 T IH0 NG'],
  "yachting's": ['Y AA1 T IH0 NG Z'],
  "yachts": ['Y AA1 T S'],
  "yachtsman": ['Y AA1 T S M AH0 N'],
  "yack": ['Y AE1 K'],
  "yackel": ['Y AE1 K AH0 L'],
  "yackley": ['Y AE1 K L IY0'],
  "yacko": ['Y AE1 K OW0'],
  "yacktman": ['Y AE1 K T M AH0 N'],
  "yacono": ['Y AE1 K AH0 N OW0'],
  "yacos": ['Y AA1 K OW0 S'],
  "yacoub": ['Y AE1 K AW0 B'],
  "yadda": ['Y AA1 D AH0'],
  "yaden": ['Y EY1 D AH0 N'],
  "yadon": ['Y AE1 D AH0 N'],
  "yaeger": ['Y IY1 JH ER0'],
  "yaffe": ['Y AE1 F'],
  "yager": ['Y EY1 JH ER0'],
  "yagi": ['Y AA1 G IY0'],
  "yagoda": ['Y AH0 G OW1 D AH0'],
  "yah": ['Y AA1'],
  "yahi": ['Y AA1 HH IY0'],
  "yahn": ['Y AE1 N'],
  "yahnke": ['Y AE1 NG K'],
  "yahoo": ['Y AA1 HH UW2'],
  "yahoos": ['Y AA1 HH UW2 Z'],
  "yahr": ['Y AA1 R'],
  "yahweh": ['Y AA1 W EH2'],
  "yahya": ['Y AH1 Y AH0'],
  "yak": ['Y AE1 K'],
  "yake": ['Y EY1 K'],
  "yakel": ['Y AE1 K AH0 L'],
  "yakima": ['Y AE1 K IH0 M AH0'],
  "yaklin": ['Y AE1 K L IH0 N'],
  "yakov": ['Y AA1 K AO2 V'],
  "yakovlev": ['Y AH0 K AA1 V L AH0 V', 'Y AA1 K AH0 V L EH0 V'],
  "yakusa": ['Y AH0 K UW1 Z AH0'],
  "yakutakay": ['Y AA1 K UW2 T AA0 K EY1'],
  "yale": ['Y EY1 L'],
  "yale's": ['Y EY1 L Z'],
  "yalin": ['Y AA1 L IH1 N'],
  "yalta": ['Y AO1 L T AH0'],
  "yam": ['Y AE1 M'],
  "yamada": ['Y AH0 M AA1 D AH0'],
  "yamagata": ['Y AA0 M AA0 G AA1 T AH0'],
  "yamaguchi": ['Y AA2 M AA0 G UW1 CH IY0'],
  "yamaha": ['Y AA1 M AA0 HH AA2'],
  "yamaichi": ['Y AA2 M AA0 IY1 CH IY0'],
  "yamaichi's": ['Y AA2 M AA0 IY1 CH IY0 Z'],
  "yamamoto": ['Y AA0 M AA0 M OW1 T OW0'],
  "yamanaka": ['Y AA2 M AA0 N AA1 K AH0'],
  "yamane": ['Y AA0 M AA1 N EY0', 'Y AH0 M AA1 N IY0'],
  "yamani": ['Y AA0 M AA1 N IY0'],
  "yamanouchi": ['Y AA0 M AA0 N UW1 CH IY0'],
  "yamasaki": ['Y AA2 M AA0 S AA1 K IY0'],
  "yamashiro": ['Y AA0 M AA0 SH IH1 R OW0'],
  "yamashita": ['Y AA0 M AA0 SH IY1 T AH0'],
  "yamatake": ['Y AA2 M AA0 T AA1 K EY2'],
  "yamato": ['Y AA0 M AA1 T OW0'],
  "yamauchi": ['Y AA2 M AA0 UW1 CH IY0'],
  "yamauchi's": ['Y AA2 M AA0 UW1 CH IY0 Z'],
  "yamazaki": ['Y AA2 M AA0 Z AA1 K IY0'],
  "yamin": ['Y AE1 M IH0 N'],
  "yams": ['Y AE1 M Z'],
  "yan": ['Y AE1 N'],
  "yan-bin": ['Y AA1 N B IH1 N'],
  "yanbu": ['Y AE1 N B UW0'],
  "yancey": ['Y AE1 N S IY0'],
  "yancy": ['Y AE1 N S IY0'],
  "yanda": ['Y AE1 N D AH0'],
  "yandell": ['Y AE1 N D AH0 L'],
  "yandle": ['Y AE1 N D AH0 L'],
  "yandow": ['Y AE1 N D OW0'],
  "yanes": ['Y EY1 N Z'],
  "yaney": ['Y EY1 N IY0'],
  "yanez": ['Y AE1 N IH0 Z'],
  "yang": ['Y AE1 NG'],
  "yangsheng": ['Y AA1 NG SH EH1 NG'],
  "yangtze": ['Y AE1 NG K T S IY1'],
  "yani": ['Y AA1 N IY0'],
  "yanick": ['Y AE1 N IH0 K'],
  "yanik": ['Y AE1 N IH0 K'],
  "yanis": ['Y AE1 N AH0 S'],
  "yanish": ['Y AE1 N IH0 SH'],
  "yank": ['Y AE1 NG K'],
  "yankass": ['Y AE1 NG K AE2 S'],
  "yanke": ['Y AE1 NG K'],
  "yanked": ['Y AE1 NG K T'],
  "yankee": ['Y AE1 NG K IY0'],
  "yankee's": ['Y AE1 NG K IY0 Z'],
  "yankees": ['Y AE1 NG K IY0 Z'],
  "yankees'": ['Y AE1 NG K IY0 Z'],
  "yankelovich": ['Y AE2 NG K EH1 L AH0 V IH0 CH'],
  "yankey": ['Y AE1 NG K IY0'],
  "yanking": ['Y AE1 NG K IH0 NG'],
  "yanko": ['Y AE1 NG K OW0'],
  "yankovich": ['Y AE1 NG K AH0 V IH0 CH'],
  "yankowski": ['Y AH0 NG K AO1 F S K IY0'],
  "yanks": ['Y AE1 NG K S'],
  "yanni": ['Y AE1 N IY0'],
  "yannick": ['Y AE1 N IH0 K'],
  "yannone": ['Y AE1 N AH0 N'],
  "yannuzzi": ['Y AA0 N UW1 T S IY0'],
  "yano": ['Y AA1 N OW0'],
  "yanofsky": ['Y AH0 N AA1 F S K IY0'],
  "yanomami": ['Y AE2 N OW0 M AE1 M IY0', 'Y AA2 N OW0 M AA1 M IY0'],
  "yant": ['Y AE1 N T'],
  "yantis": ['Y AE1 N T IH0 S'],
  "yantz": ['Y AE1 N T S'],
  "yao": ['Y AW1'],
  "yaobang": ['Y AW1 B AE0 NG'],
  "yaohan": ['Y AW1 HH AA1 N'],
  "yap": ['Y AE1 P'],
  "yaple": ['Y EY1 P AH0 L'],
  "yapp": ['Y AE1 P'],
  "yaps": ['Y AE1 P S'],
  "yarber": ['Y AA1 R B ER0'],
  "yarberry": ['Y AA1 R B EH2 R IY0'],
  "yarboro": ['Y AA1 R B ER0 OW0'],
  "yarborough": ['Y AA1 R B ER2 OW0'],
  "yarbough": ['Y AA1 R B AW2'],
  "yarbro": ['Y AA1 R B R OW0'],
  "yarbrough": ['Y AA1 R B ER0 OW0'],
  "yarchoan": ['Y AA0 R CH OW1 N'],
  "yard": ['Y AA1 R D'],
  "yard's": ['Y AA1 R D Z'],
  "yardage": ['Y AA1 R D IH0 JH'],
  "yardbirds": ['Y AA1 R D B ER0 D Z'],
  "yarde": ['Y AA1 R D'],
  "yardeni": ['Y AA0 R D IY1 N IY0'],
  "yarder": ['Y AA1 R D ER0'],
  "yardley": ['Y AA1 R D L IY0'],
  "yards": ['Y AA1 R D Z'],
  "yardstick": ['Y AA1 R D S T IH2 K'],
  "yardsticks": ['Y AA1 R D S T IH2 K S'],
  "yarger": ['Y AA1 R JH ER0'],
  "yarling": ['Y AA1 R L IH0 NG'],
  "yarmulke": ['Y AA1 R M AH2 L K AH0', 'Y AA1 R M AH0 K AH0'],
  "yarmulkes": ['Y AA1 R M AH2 L K AH0 S', 'Y AA1 R M AH0 K AH0 S'],
  "yarn": ['Y AA1 R N'],
  "yarnall": ['Y AA1 R N AH0 L'],
  "yarnell": ['Y AA1 R N AH0 L'],
  "yarns": ['Y AA1 R N Z'],
  "yaron": ['Y EH1 R AH0 N'],
  "yaros": ['Y EH1 R OW0 Z'],
  "yarosh": ['Y AE1 R AH0 SH'],
  "yarrington": ['Y AE1 R IH0 NG T AH0 N'],
  "yarrow": ['Y AA1 R OW0'],
  "yarwood": ['Y AA1 R W UH2 D'],
  "yasin": ['Y AE1 S IH0 N'],
  "yasir": ['Y AA1 S ER0', 'Y AA2 S IH1 R'],
  "yasmin": ['Y AE1 Z M IH0 N'],
  "yasmine": ['Y AE1 Z M IH0 N'],
  "yass": ['Y AE1 S'],
  "yasser": ['Y AE1 S ER0', 'Y AA1 S ER0'],
  "yasser's": ['Y AE1 S ER0 Z', 'Y AA1 S ER0 Z'],
  "yassin": ['Y AE1 S IH0 N'],
  "yassir": ['Y AE1 S IH0 R', 'Y AA1 S IH0 R'],
  "yassir's": ['Y AE1 S IH0 R Z', 'Y AA1 S IH0 R Z'],
  "yasso": ['Y AA1 S OW0'],
  "yassukovich": ['Y AH0 S UW1 K AH0 V IH0 CH'],
  "yastrow": ['Y AE1 S T R OW0'],
  "yasuda": ['Y AA0 S UW1 D AH0'],
  "yasuhiko": ['Y AA2 S UW0 HH IY1 K OW0'],
  "yasuhiro": ['Y AA2 S UW0 HH IY1 R OW0'],
  "yasunori": ['Y AA2 S UW0 N AO1 R IY0'],
  "yasuo": ['Y AA0 S UW1 OW0'],
  "yasushi": ['Y AA0 S UW1 SH IY0'],
  "yasutaka": ['Y AA2 S UW0 T AA1 K AH0'],
  "yasuyoshi": ['Y AA2 S UW0 Y OW1 SH IY0'],
  "yater": ['Y EY1 T ER0'],
  "yates": ['Y EY1 T S'],
  "yatsko": ['Y AA1 T S K OW0'],
  "yau": ['Y OW1'],
  "yauch": ['Y AO1 CH'],
  "yauger": ['Y AO1 JH ER0'],
  "yaun": ['Y AO1 N'],
  "yaupon": ['Y AO1 P AH0 N'],
  "yaupons": ['Y AO1 P AH0 N Z'],
  "yavlinski": ['Y AE2 V L IH1 N S K IY2'],
  "yavlinski's": ['Y AE2 V L IH1 N S K IY2 Z'],
  "yavlinsky": ['Y AE2 V L IH1 N S K IY2'],
  "yavlinsky's": ['Y AE2 V L IH1 N S K IY2 Z'],
  "yaw": ['Y AO1'],
  "yawn": ['Y AO1 N'],
  "yawned": ['Y AO1 N D'],
  "yawner": ['Y AO1 N ER0'],
  "yawning": ['Y AO1 N IH0 NG'],
  "yawns": ['Y AO1 N Z'],
  "yaworski": ['Y AH0 W ER1 S K IY0'],
  "yax": ['Y AE1 K S'],
  "yay": ['Y EY1'],
  "yazoo": ['Y AA1 Z UW2'],
  "yazov": ['Y AE1 Z AA0 V', 'Y AA1 Z AO2 V'],
  "yazzie": ['Y AE1 Z IY0'],
  "ybanez": ['IY0 B AE1 N IH0 Z'],
  "ybarbo": ['IY0 B AA1 R B OW0'],
  "ybarra": ['IY0 B AA1 R AH0'],
  "ydstie": ['IH1 D S T IY0'],
  "ydstie's": ['IH1 D S T IY0 Z'],
  "ye": ['Y IY1', 'Y EH1'],
  "ye-yi": ['Y EY1 Y IY1'],
  "yea": ['Y EY1'],
  "yeadon": ['Y EH1 D AH0 N'],
  "yeager": ['Y EY1 G ER0'],
  "yeagle": ['Y IY1 G AH0 L'],
  "yeagley": ['Y IY1 G L IY0'],
  "yeah": ['Y AE1'],
  "yeakel": ['Y IY1 K AH0 L'],
  "yeakey": ['Y IY1 K IY0'],
  "yeakle": ['Y IY1 K AH0 L'],
  "yeakley": ['Y IY1 K L IY0'],
  "yeaman": ['Y IY1 M AH0 N'],
  "yeamans": ['Y IY1 M AH0 N Z'],
  "year": ['Y IH1 R'],
  "year's": ['Y IH1 R Z'],
  "yearago": ['Y IH1 R AH0 G OW0'],
  "yearbook": ['Y IH1 R B UH2 K'],
  "yearbooks": ['Y IH1 R B UH2 K S'],
  "yearby": ['Y ER1 B IY0'],
  "yearearlier": ['Y IH2 R ER1 L Y ER0'],
  "yearend": ['Y IH2 R EH1 N D'],
  "yeargain": ['Y ER1 G AH0 N', 'Y IH2 G EY1 N'],
  "yeargan": ['Y ER1 G AH0 N'],
  "yeargin": ['Y ER1 JH IH0 N'],
  "yearick": ['Y IY1 R IH0 K', 'Y IH1 R IH0 K'],
  "yearling": ['Y ER1 L IH0 NG', 'Y IH1 L IH0 NG'],
  "yearlings": ['Y ER1 L IH0 NG Z', 'Y IH1 L IH0 NG Z'],
  "yearlong": ['Y IH1 R L AO1 NG'],
  "yearly": ['Y IH1 R L IY0'],
  "yearn": ['Y ER1 N'],
  "yearned": ['Y ER1 N D'],
  "yearning": ['Y ER1 N IH0 NG'],
  "yearnings": ['Y ER1 N IH0 NG Z'],
  "yearns": ['Y ER1 N Z'],
  "yearout": ['Y IH1 R AW0 T'],
  "years": ['Y IH1 R Z', 'Y ER0 Z'],
  "years'": ['Y IH1 R Z'],
  "years-old": ['Y IH2 R Z AO1 L D'],
  "yearsley": ['Y ER1 S L IY0'],
  "yearwood": ['Y IH1 R W UH0 D'],
  "yeary": ['Y IH1 R IY0'],
  "yeas": ['Y EY1 Z', 'Y IY1 Z', 'Y IY1 S'],
  "yeast": ['Y IY1 S T'],
  "yeasts": ['Y IY1 S T S'],
  "yeater": ['Y IY1 T ER0'],
  "yeates": ['Y IY1 EY2 T S'],
  "yeatman": ['Y IY1 T M AH0 N'],
  "yeaton": ['Y IY1 T AH0 N'],
  "yeats": ['Y EY1 T S'],
  "yeatts": ['Y IY1 T S'],
  "yeazel": ['Y IY1 Z AH0 L'],
  "yeck": ['Y EH1 K'],
  "yeckley": ['Y EH1 K L IY0'],
  "yedda": ['Y EH1 D AH0'],
  "yedinak": ['Y EH1 D IH0 N AE0 K'],
  "yediyat": ['Y EH1 D IH0 Y AE2 T'],
  "yee": ['Y IY1'],
  "yegor": ['Y EY1 G ER0'],
  "yeh": ['Y EH1'],
  "yehiya": ['Y AH0 HH IY1 AH0'],
  "yehle": ['Y EH1 HH AH0 L'],
  "yehuda": ['Y AH0 HH UW1 D AH0'],
  "yehudi": ['Y EY0 HH UW1 D IY0'],
  "yeiri": ['Y EH1 R IY0'],
  "yeiser": ['Y IY1 S ER0'],
  "yeisley": ['Y IY1 S L IY0'],
  "yekaterinburg": ['Y EH0 K AE2 T ER0 IY1 N B AH0 R G'],
  "yeldell": ['Y EH1 L D AH0 L'],
  "yelena": ['Y EH1 L IH0 N AH0'],
  "yell": ['Y EH1 L'],
  "yelland": ['Y EH1 L AH0 N D'],
  "yelle": ['Y EH1 L'],
  "yelled": ['Y EH1 L D'],
  "yellen": ['Y EH1 L AH0 N'],
  "yellin": ['Y EH1 L IH0 N'],
  "yellin'": ['Y EH1 L IH0 N'],
  "yelling": ['Y EH1 L IH0 NG'],
  "yellow": ['Y EH1 L OW0'],
  "yellower": ['Y EH1 L OW0 ER0'],
  "yellowest": ['Y EH1 L OW0 IH0 S T'],
  "yellowing": ['Y EH1 L OW0 IH0 NG'],
  "yellowish": ['Y EH1 L OW0 IH0 SH'],
  "yellowknife": ['Y EH1 L OW0 N AY2 F'],
  "yellows": ['Y EH1 L OW0 Z'],
  "yellowstone": ['Y EH1 L OW0 S T OW2 N'],
  "yellowstone's": ['Y EH1 L OW0 S T OW2 N Z'],
  "yells": ['Y EH1 L Z'],
  "yelp": ['Y EH1 L P'],
  "yelped": ['Y EH1 L P D'],
  "yelping": ['Y EH1 L P IH0 NG'],
  "yelton": ['Y EH1 L T AH0 N'],
  "yeltsin": ['Y EH1 L T S AH0 N'],
  "yeltsin's": ['Y EH1 L T S AH0 N Z'],
  "yelverton": ['Y EH1 L V ER0 T AH0 N'],
  "yelvington": ['Y EH1 L V IH0 NG T AH0 N'],
  "yemen": ['Y EH1 M AH0 N'],
  "yemen's": ['Y EH1 M AH0 N Z'],
  "yemeni": ['Y EH1 M AH0 N IY0', 'Y EH0 M IY1 N IY0'],
  "yemenidjian": ['Y EH2 M AH0 N IH1 JH IY0 AH0 N'],
  "yen": ['Y EH1 N'],
  "yen's": ['Y EH1 N Z'],
  "yenching": ['Y EH1 N CH IH1 NG'],
  "yender": ['Y EH1 N D ER0'],
  "yene": ['Y EH1 N IY0', 'Y EH1 N'],
  "yenlu": ['Y EH1 N L UW0'],
  "yentl": ['Y EH1 N T AH0 L'],
  "yeo": ['Y IY1 OW0'],
  "yeoman": ['Y OW1 M AH0 N'],
  "yeoman's": ['Y OW1 M AH0 N Z'],
  "yeomans": ['Y OW1 M AH0 N Z'],
  "yeosock": ['Y OW0 S AO1 K'],
  "yep": ['Y EH1 P'],
  "yepez": ['Y EH1 P IH0 Z'],
  "yepsen": ['Y EH1 P S EH0 N'],
  "yer": ['Y ER1'],
  "yerby": ['Y ER1 B IY0'],
  "yerdon": ['Y ER1 D AH0 N'],
  "yerena": ['Y EH1 R IH0 N AH0'],
  "yerevan": ['Y EH1 R AH0 V AH0 N'],
  "yerger": ['Y ER1 JH ER0'],
  "yergin": ['Y ER1 G AH0 N'],
  "yerian": ['Y IY1 R IY0 AH0 N'],
  "yerke": ['Y ER1 K'],
  "yerkes": ['Y ER1 K IY0 Z'],
  "yerkey": ['Y ER1 K IY0'],
  "yerman": ['Y ER1 M AH0 N'],
  "yerxa": ['Y ER1 K S AH0'],
  "yes": ['Y EH1 S'],
  "yesen": ['Y EH1 S AH0 N'],
  "yeses": ['Y EH1 S IH0 Z'],
  "yeshiva": ['Y IH0 SH IY1 V AH0'],
  "yeske": ['Y EH1 S K'],
  "yessuey": ['Y EH1 S UW0 IY0'],
  "yest": ['Y EH1 S T'],
  "yesterday": ['Y EH1 S T ER0 D EY2', 'Y EH1 S T ER0 D IY0'],
  "yesterday's": ['Y EH1 S T ER0 D EY2 Z', 'Y EH1 S T ER0 D IY0 Z'],
  "yesterdays": ['Y EH1 S T ER0 D EY2 Z', 'Y EH1 S T ER0 D IY0 Z'],
  "yesteryear": ['Y EH1 S T ER0 Y IH2 R'],
  "yet": ['Y EH1 T'],
  "yeti": ['Y EH1 T IY0'],
  "yetman": ['Y EH1 T M AH0 N'],
  "yetnikoff": ['Y EH1 T N IH0 K AO0 F'],
  "yett": ['Y EH1 T'],
  "yetta": ['Y EH1 T AH0'],
  "yetter": ['Y EH1 T ER0'],
  "yeung": ['Y UW1 NG'],
  "yeutter": ['Y UW1 T ER0'],
  "yevette": ['Y IH0 V EH1 T'],
  "yevgeny": ['Y EH2 V G EH1 N IY0'],
  "yevlinsky": ['Y EH0 V L IH1 N S K IY0'],
  "yevlinsky's": ['Y EH0 V L IH1 N S K IY0 Z'],
  "yevtushenko": ['Y EH2 V T AH0 SH EH1 NG K OW0'],
  "yew": ['Y UW1'],
  "yew's": ['Y UW1 Z'],
  "yglesias": ['IY0 G L EY1 S IY0 AH0 S'],
  "yi": ['Y IY1'],
  "yiddish": ['Y IH1 D IH0 SH'],
  "yie": ['Y IY1', 'Y AY1'],
  "yield": ['Y IY1 L D'],
  "yielded": ['Y IY1 L D IH0 D'],
  "yielding": ['Y IY1 L D IH0 NG'],
  "yields": ['Y IY1 L D Z'],
  "yigal": ['Y IH0 G AE1 L', 'Y IH0 G AA1 L', 'Y IY0 G AA1 L'],
  "yikes": ['Y AY1 K S'],
  "yilin": ['Y IH1 L IH0 N'],
  "yim": ['Y IH1 M'],
  "yin": ['Y IH1 N'],
  "ying": ['Y IH1 NG'],
  "yinger": ['Y IH1 NG ER0'],
  "yingling": ['Y IH1 NG G AH0 L IH0 NG', 'Y IH1 NG G L IH0 NG'],
  "yingst": ['Y IH1 NG G S T', 'Y IH1 NG K S T'],
  "yip": ['Y IH1 P'],
  "yipee": ['Y IH2 P IY1'],
  "yippee": ['Y IH2 P IY1'],
  "yirng-an": ['Y IH1 R NG AA1 N'],
  "yitzhak": ['Y IH1 T S AA0 K', 'Y IH1 T S AE0 K'],
  "ylang-ylang": ['Y AH0 L AE1 NG Y AH0 L AE1 NG'],
  "ylvisaker": ['IH2 L V IH0 S AA1 K ER0'],
  "ynez": ['IY2 N EH1 Z'],
  "yniguez": ['IY0 N IY1 G EH0 Z'],
  "ynjiun": ['IH1 N JH UH0 N'],
  "yo": ['Y OW1'],
  "yoak": ['Y OW1 K'],
  "yoakam": ['Y OW1 K AH0 M'],
  "yoakum": ['Y OW1 K AH0 M'],
  "yoast": ['Y OW1 S T'],
  "yobbo": ['Y AA1 B OW2'],
  "yocam": ['Y OW1 K AH0 M'],
  "yocham": ['Y AA1 CH AH0 M'],
  "yochelson": ['Y OW1 K EH0 L S AH0 N', 'Y OW1 CH EH0 L S AH0 N'],
  "yochim": ['Y AA1 CH IH0 M'],
  "yochum": ['Y AA1 CH AH0 M', 'Y AA1 K AH0 M'],
  "yockey": ['Y AA1 K IY0'],
  "yocom": ['Y OW1 K AH0 M'],
  "yocum": ['Y OW1 K AH0 M'],
  "yoda": ['Y OW1 D AH0'],
  "yoda's": ['Y OW1 D AH0 Z'],
  "yodel": ['Y OW1 D AH0 L'],
  "yodeling": ['Y OW1 D AH0 L IH0 NG', 'Y OW1 D L IH0 NG'],
  "yoder": ['Y OW1 D ER0'],
  "yodice": ['Y OW1 D IH0 S'],
  "yoe": ['Y OW1'],
  "yoes": ['Y OW1 Z'],
  "yoest": ['Y EH1 S T', 'Y AO1 S T'],
  "yoffie": ['Y AO1 F IY0'],
  "yoga": ['Y OW1 G AH0'],
  "yogi": ['Y OW1 G IY0'],
  "yogiism": ['Y OW1 G IH2 Z AH0 M', 'Y OW1 G IH2 S AH0 M'],
  "yogiisms": ['Y OW0 G IY1 S AH0 M Z', 'Y OW1 G IH2 Z AH0 M Z'],
  "yogurt": ['Y OW1 G ER0 T'],
  "yoh": ['Y OW1'],
  "yohe": ['Y OW1 HH EY2'],
  "yohei": ['Y OW1 HH EY2'],
  "yohn": ['Y AA1 N'],
  "yoho": ['Y OW1 HH OW0'],
  "yoichi": ['Y OW0 IY1 CH IY0'],
  "yokado": ['Y AH0 K AA1 D OW0'],
  "yoke": ['Y OW1 K'],
  "yoked": ['Y OW1 K T'],
  "yokel": ['Y OW1 K EH0 L'],
  "yokelson": ['Y OW1 K EH0 L S AH0 N'],
  "yokes": ['Y OW1 K S'],
  "yokich": ['Y OW1 K IH0 CH'],
  "yokley": ['Y AA1 K L IY0'],
  "yoko": ['Y OW1 K OW0'],
  "yokohama": ['Y OW2 K AH0 HH AA1 M AH0'],
  "yokokohji": ['Y OW2 K OW0 K OW1 JH IY0'],
  "yokota": ['Y OW0 K OW1 T AH0'],
  "yokoyama": ['Y OW2 K OW0 Y AA1 M AH0'],
  "yokum": ['Y OW1 K AH0 M'],
  "yola": ['Y OW1 L AH0'],
  "yolanda": ['Y OW0 L AA1 N D AH0'],
  "yolande": ['Y OW1 L AH0 N D'],
  "yolk": ['Y OW1 K'],
  "yolks": ['Y OW1 K S'],
  "yolo": ['Y OW1 L OW2'],
  "yom": ['Y AA1 M', 'Y OW1 M'],
  "yom's": ['Y AA1 M Z', 'Y OW1 M Z'],
  "yomiuri": ['Y OW2 M IY1 ER0 IY0'],
  "yon": ['Y AA1 N'],
  "yona": ['Y OW1 N AH0'],
  "yonan": ['Y OW1 N AH0 N'],
  "yonce": ['Y AA1 N S'],
  "yonder": ['Y AA1 N D ER0'],
  "yoneyama": ['Y OW2 N IY0 AA1 M AH0'],
  "yong": ['Y AO1 NG'],
  "yongbyon": ['Y AO1 NG B Y AO1 N'],
  "yongbyon's": ['Y AO1 NG B Y AO1 N Z'],
  "yongchaiyudh": ['Y AO2 NG CH EY0 Y UW1 D'],
  "yongue": ['Y AA1 NG G'],
  "yonhap": ['Y AA1 N HH AE2 P'],
  "yonke": ['Y AA1 NG K'],
  "yonker": ['Y AA1 NG K ER0'],
  "yonkers": ['Y AA1 NG K ER0 Z'],
  "yonsei": ['Y AH1 N S EY2'],
  "yontef": ['Y AO1 N T EH0 F'],
  "yonts": ['Y AA1 N T S'],
  "yontz": ['Y AA1 N T S'],
  "yoo": ['Y UW1'],
  "yoon": ['Y UW1 N'],
  "yoor": ['Y UW1 R'],
  "yoor's": ['Y UW1 R Z'],
  "yoos": ['Y UW1 Z'],
  "yopp": ['Y AA1 P'],
  "yoram": ['Y AO1 R AH0 M'],
  "yorba": ['Y AO1 R B AH0'],
  "yordy": ['Y AO1 R D IY0'],
  "yore": ['Y AO1 R'],
  "yorich": ['Y AO1 R IH0 CH'],
  "yorio": ['Y AO1 R Y OW0'],
  "york": ['Y AO1 R K'],
  "york's": ['Y AO1 R K S'],
  "yorkbased": ['Y AO1 R K B EY2 S T'],
  "yorke": ['Y AO1 R K'],
  "yorker": ['Y AO1 R K ER0'],
  "yorker's": ['Y AO1 R K ER0 Z'],
  "yorkers": ['Y AO1 R K ER0 Z'],
  "yorks": ['Y AO1 R K S'],
  "yorkshire": ['Y AO1 R K SH ER0'],
  "yorktown": ['Y AO1 R K T AW2 N'],
  "yorktown's": ['Y AO1 R K T AW2 N Z'],
  "yorrick": ['Y AO1 R IH0 K'],
  "yoruba": ['Y AH0 R UW1 B AH0'],
  "yosef": ['Y OW1 S AH0 F'],
  "yosemite": ['Y OW0 S EH1 M IH0 T IY0'],
  "yoshi": ['Y OW1 SH IY0'],
  "yoshiaki": ['Y OW2 SH IY0 AA1 K IY0'],
  "yoshida": ['Y OW0 SH IY1 D AH0'],
  "yoshihara": ['Y OW2 SH IH0 HH AA1 R AH0'],
  "yoshihashi": ['Y OW2 SH IH0 HH AA1 SH IY0'],
  "yoshihiro": ['Y OW2 SH IH0 HH IY1 R OW2'],
  "yoshihiro's": ['Y OW2 SH IH0 HH IY1 R OW2 Z'],
  "yoshihisa": ['Y OW2 SH IH0 HH IY1 S AH0'],
  "yoshikawa": ['Y OW0 SH IY0 K AA1 W AH0'],
  "yoshikazu": ['Y OW2 SH IH0 K AA1 Z UW0'],
  "yoshimoto": ['Y OW0 SH IY0 M OW1 T OW0'],
  "yoshimura": ['Y OW2 SH IH0 M UW1 R AH0'],
  "yoshino": ['Y OW0 SH IY1 N OW0'],
  "yoshio": ['Y OW0 SH IY1 OW0'],
  "yoshioka": ['Y OW2 SH IY0 OW1 K AH0'],
  "yoshiro": ['Y OW0 SH IH1 R OW0'],
  "yoss": ['Y AO1 S'],
  "yossi": ['Y OW1 S IY0'],
  "yost": ['Y OW1 S T'],
  "yother": ['Y AH1 DH ER0'],
  "yott": ['Y AA1 T'],
  "you": ['Y UW1'],
  "you'd": ['Y UW1 D', 'Y UH1 D'],
  "you'll": ['Y UW1 L'],
  "you're": ['Y UH1 R', 'Y UW1 R'],
  "you've": ['Y UW1 V'],
  "youell": ['Y AA1 UW0 L'],
  "youghal": ['Y OW1 AH0 L'],
  "youker": ['Y AW1 K ER0'],
  "youman": ['Y UW1 M AH0 N'],
  "youmans": ['Y UW1 M AH0 N Z'],
  "youn": ['Y AH1 N'],
  "younan": ['Y AH1 N AH0 N'],
  "younce": ['Y AW1 N S'],
  "younes": ['Y AH1 N Z'],
  "young": ['Y AH1 NG'],
  "young's": ['Y AH1 NG Z'],
  "youngberg": ['Y AH1 NG B ER0 G'],
  "youngblood": ['Y AH1 NG B L AH2 D'],
  "youngblut": ['Y AH1 NG B L AH0 T'],
  "youngdahl": ['Y AH1 NG D AA2 L'],
  "younge": ['Y AH1 N JH'],
  "younger": ['Y AH1 NG G ER0'],
  "youngerman": ['Y AH1 NG ER0 M AH0 N'],
  "youngers": ['Y AH1 NG G ER0 Z'],
  "youngest": ['Y AH1 NG G AH0 S T'],
  "younglove": ['Y AH1 NG L AH2 V'],
  "youngman": ['Y AH1 NG M AE2 N'],
  "youngquist": ['Y AH1 NG K W IH2 S T'],
  "youngren": ['Y AH1 N G R EH0 N'],
  "youngs": ['Y AH1 NG Z'],
  "youngster": ['Y AH1 NG S T ER0'],
  "youngster's": ['Y AH1 NG S T ER0 Z'],
  "youngsters": ['Y AH1 NG S T ER0 Z'],
  "youngsters'": ['Y AH1 NG S T ER0 Z'],
  "youngstown": ['Y AH1 NG Z T AW2 N'],
  "youngstrom": ['Y AH1 NG S T R AH0 M'],
  "younis": ['Y AH1 N IH0 S'],
  "younker": ['Y AH1 NG K ER0'],
  "younkers": ['Y AH1 NG K ER0 Z'],
  "younkers'": ['Y AH1 NG K ER0 Z'],
  "younkin": ['Y AH1 NG K IH0 N'],
  "younkins": ['Y AH1 NG K IH0 N Z'],
  "yount": ['Y AW1 N T'],
  "younts": ['Y AW1 N T S'],
  "your": ['Y AO1 R', 'Y UH1 R'],
  "youree": ['Y AO0 R IY1'],
  "youri": ['Y UH1 R IY0'],
  "yours": ['Y UH1 R Z', 'Y AO1 R Z', 'Y ER0 Z'],
  "yourself": ['Y ER0 S EH1 L F', 'Y UH0 R S EH1 L F', 'Y AO1 R S EH0 L F'],
  "yourselfer": ['Y AO2 R S EH1 L F ER0'],
  "yourselfers": ['Y AO2 R S EH1 L F ER0 Z'],
  "yourselves": ['Y UH0 R S EH1 L V Z', 'Y AO1 R S EH0 L V Z'],
  "yous": ['Y UW1 S'],
  "youse": ['Y AW1 S', 'Y UW1 Z'],
  "yousef": ['Y UW1 S EH0 F'],
  "yousef's": ['Y UW1 S EH0 F S'],
  "yousif": ['Y AW1 S IH0 F', 'Y UW1 S IH0 F'],
  "youssef": ['Y UW1 S EH0 F'],
  "youth": ['Y UW1 TH'],
  "youth's": ['Y UW1 TH S'],
  "youthful": ['Y UW1 TH F AH0 L'],
  "youths": ['Y UW1 DH Z', 'Y UW1 TH S'],
  "youtsey": ['Y AW1 T S IY0'],
  "youtube": ['Y UW1 T Y UW2 B'],
  "youtube's": ['Y UW1 T UW2 B Z'],
  "youtz": ['Y AW1 T S'],
  "youville": ['Y UW1 V IH2 L'],
  "yow": ['Y AW1'],
  "yowell": ['Y AA1 W EH0 L'],
  "yoy": ['Y OY1'],
  "yoyo": ['Y OW1 Y OW0'],
  "yoyos": ['Y OW1 Y OW0 Z'],
  "ypsilanti": ['IH2 P S AH0 L AE1 N T IY0'],
  "yquem": ['IY1 K W AH0 M'],
  "ysleta": ['IY0 S L EY1 T AH0'],
  "yttrium": ['IH1 T R IY0 AH0 M'],
  "yu": ['Y UW1'],
  "yu's": ['Y UW1 Z'],
  "yuan": ['Y UW0 AA1 N'],
  "yuan's": ['Y UW0 AA1 N Z'],
  "yuba": ['Y UW1 B AH0'],
  "yucaipa": ['Y UW2 K AY1 P AH0'],
  "yucatan": ['Y UW1 K AH0 T AE2 N'],
  "yucca": ['Y AH1 K AH0'],
  "yuck": ['Y AH1 K'],
  "yucky": ['Y AH1 K IY0'],
  "yue": ['Y UW1'],
  "yuen": ['Y W EH1 N', 'Y UW0 EH1 N'],
  "yugo": ['Y UW1 G OW0'],
  "yugo's": ['Y UW1 G OW0 Z'],
  "yugos": ['Y UW1 G OW0 S'],
  "yugoslav": ['Y UW1 G OW0 S L AA2 V'],
  "yugoslavia": ['Y UW2 G OW0 S L AA1 V IY0 AH0'],
  "yugoslavia's": ['Y UW2 G OW0 S L AA1 V IY0 AH0 Z'],
  "yugoslavian": ['Y UW2 G OW0 S L AA1 V IY0 AH0 N'],
  "yugoslavs": ['Y UW1 G OW0 S L AE2 V Z'],
  "yuhas": ['Y UW1 HH AH0 Z'],
  "yuhasz": ['Y AH1 HH AH0 SH'],
  "yuichi": ['Y UW1 CH IY1', 'Y UW2 IY1 CH IY1'],
  "yuill": ['Y UW1 L'],
  "yuille": ['Y UW1 L'],
  "yuk": ['Y AH1 K'],
  "yukio": ['Y UW0 K IY1 OW0'],
  "yuko": ['Y UW1 K OW0'],
  "yukon": ['Y UW1 K AA2 N'],
  "yuks": ['Y AH1 K S'],
  "yul": ['Y AH1 L'],
  "yule": ['Y UW1 L'],
  "yuletide": ['Y UW1 L T AY2 D'],
  "yuli": ['Y UW1 L IY0'],
  "yulian": ['Y UW1 L IY2 AH0 N'],
  "yum": ['Y AH1 M'],
  "yuma": ['Y UW1 M AH0'],
  "yuma's": ['Y UW1 M AH0 Z'],
  "yummies": ['Y AH1 M IY0 Z'],
  "yummy": ['Y AH1 M IY0'],
  "yun": ['Y AH1 N'],
  "yunde": ['Y UW1 N D EY2'],
  "yundt": ['Y AH1 N D T'],
  "yung": ['Y AH1 NG'],
  "yung's": ['Y AH1 NG Z'],
  "yunich": ['Y UW1 N IH0 CH'],
  "yunis": ['Y UW1 N IH0 S'],
  "yunk": ['Y AH1 NG K'],
  "yunker": ['Y AH1 NG K ER0'],
  "yup": ['Y AH1 P'],
  "yuppie": ['Y AH1 P IY0'],
  "yuppies": ['Y AH1 P IY0 Z'],
  "yuppified": ['Y AH1 P IH0 F AY2 D'],
  "yuppify": ['Y AH1 P IH0 F AY2'],
  "yurachek": ['Y ER0 AE1 CH EH0 K'],
  "yurchak": ['Y ER1 CH AH0 K', 'Y ER1 AH0 K'],
  "yurchenko": ['Y ER0 CH EH1 NG K OW0'],
  "yurek": ['Y UH1 R EH0 K'],
  "yuri": ['Y UH1 R IY0'],
  "yurick": ['Y UH1 R IH0 K'],
  "yurko": ['Y ER1 K OW0'],
  "yurkovich": ['Y ER1 K AH0 V IH0 CH'],
  "yurman": ['Y ER1 M AH0 N'],
  "yurt": ['Y UH1 R T'],
  "yury": ['Y UH1 R IY0'],
  "yusef": ['Y UW1 S EH0 F'],
  "yusef's": ['Y UW1 S EH0 F S'],
  "yusen": ['Y UW1 S AH0 N'],
  "yusko": ['Y AH1 S K OW0'],
  "yust": ['Y AH1 S T'],
  "yustef": ['Y UW1 S T EH0 F'],
  "yutaka": ['Y UW0 T AA1 K AH0'],
  "yutzy": ['Y AH1 T Z IY0'],
  "yuval": ['Y UW1 V AH0 L'],
  "yuzong": ['Y UW2 Z AO1 NG'],
  "yves": ['IY1 V'],
  "yvette": ['IH2 V EH1 T'],
  "yvonne": ['IH2 V AA1 N'],
  "yvonne's": ['IH2 V AA1 N Z'],
  "yzaguirre": ['IY0 Z AH0 G W AY1 ER0'],
  "z": ['Z IY1'],
  "z's": ['Z IY1 Z'],
  "z.": ['Z IY1'],
  "z.'s": ['Z IY1 Z'],
  "zabala": ['Z AA0 B AA1 L AH0'],
  "zabar's": ['Z AE1 B AA0 R Z'],
  "zabawa": ['Z AA0 B AA1 W AH0'],
  "zabel": ['Z EY1 B AH0 L'],
  "zabielski": ['Z AH0 B IY1 L S K IY0'],
  "zabinski": ['Z AH0 B IH1 N S K IY0'],
  "zablocki": ['Z AH0 B L OW1 T S K IY0'],
  "zaborowski": ['Z AH0 B ER0 AO1 F S K IY0'],
  "zabows": ['Z EY1 B OW0 Z'],
  "zabowski": ['Z AH0 B AW1 S K IY0'],
  "zabriskie": ['Z AE1 B R IH0 S K IY0'],
  "zacarias": ['Z AA0 K AA0 R IY1 AH0 Z'],
  "zaccagnini": ['Z AA0 K AH0 G N IY1 N IY0'],
  "zaccagnino": ['Z AA0 K AH0 G N IY1 N OW0'],
  "zaccardi": ['Z AA0 K AA1 R D IY0'],
  "zaccaria": ['Z AA0 K AA1 R IY0 AH0'],
  "zaccaro": ['Z AA0 K AA1 R OW0'],
  "zaccheus": ['Z AE1 K OY0 Z'],
  "zaccone": ['Z AA0 K OW1 N IY0'],
  "zacek": ['Z AA1 CH EH2 K'],
  "zach": ['Z AE1 K'],
  "zachar": ['Z AH0 K AA1 R'],
  "zachariah": ['Z AE2 K ER0 AY1 AH0'],
  "zacharias": ['Z AE2 K ER0 AY1 AH0 S'],
  "zachary": ['Z AE1 K ER0 IY0'],
  "zacher": ['Z AE1 K ER0'],
  "zachery": ['Z AE1 K ER0 IY0'],
  "zachi": ['Z AA1 K IY0', 'Z AA1 CH IY0'],
  "zachman": ['Z AE1 K M AH0 N'],
  "zachmann": ['Z AA1 K M AH0 N'],
  "zachow": ['Z AA1 K OW0'],
  "zachry": ['Z AA1 K R IY0'],
  "zack": ['Z AE1 K'],
  "zack's": ['Z AE1 K S'],
  "zackery": ['Z AE1 K ER0 IY0'],
  "zacks": ['Z AE1 K S'],
  "zada": ['Z AA1 D AH0'],
  "zadar": ['Z EY1 D AA2 R', 'Z IH0 D AA1 R'],
  "zadeh": ['Z AA1 D EH2'],
  "zadillo": ['Z AH0 D IH1 L OW0'],
  "zadoc": ['Z AA1 D AH0 K'],
  "zadok": ['Z AA1 D AH0 K'],
  "zadora": ['Z AH0 D AO1 R AH0'],
  "zadrozny": ['Z AH0 D R OW1 Z N IY0'],
  "zaentz": ['Z AE1 N T S'],
  "zaffino": ['Z AA0 F IY1 N OW0'],
  "zaffuto": ['Z AH0 F UW1 T OW0'],
  "zaftig": ['Z AE1 F T IH0 G'],
  "zaftig's": ['Z AE1 F T IH0 G Z'],
  "zag": ['Z AE1 G'],
  "zagar": ['Z AA0 G AA1 R'],
  "zagat": ['Z AE1 G AH0 T'],
  "zagel": ['Z EY1 G AH0 L'],
  "zager": ['Z EY1 G ER0'],
  "zagged": ['Z AE1 G D'],
  "zagha": ['Z AA1 G AH0'],
  "zagladin": ['Z AE1 G L AH0 D IH0 N'],
  "zagorski": ['Z AH0 G AO1 R S K IY0'],
  "zagreb": ['Z AA1 G R EH2 B'],
  "zagreb's": ['Z AA1 G R EH2 B Z'],
  "zags": ['Z AE1 G Z'],
  "zagury": ['Z AE1 G Y ER0 IY0'],
  "zaher": ['Z EY1 ER0'],
  "zahir": ['Z AH0 HH IH1 R'],
  "zahler": ['Z AA1 L ER0'],
  "zahm": ['Z AE1 M'],
  "zahn": ['Z AE1 N'],
  "zahner": ['Z AA1 N ER0'],
  "zahniser": ['Z AA1 N AY0 Z ER0'],
  "zahnow": ['Z AA1 N OW0'],
  "zahradnik": ['Z ER0 AE1 D N IH0 K'],
  "zaibatsu": ['Z EY2 B AE1 T S UW2'],
  "zaid": ['Z EY1 D'],
  "zain": ['Z EY1 N'],
  "zaino": ['Z EY1 N OW0'],
  "zaire": ['Z AY0 IH1 R'],
  "zaire's": ['Z AY0 IH1 R Z'],
  "zairean": ['Z AY0 IH1 R AH0 N'],
  "zairean's": ['Z AY0 IH1 R AH0 N Z'],
  "zaireans": ['Z AY0 IH1 R AH0 N Z'],
  "zairian": ['Z AY0 IH1 R IY0 AH0 N'],
  "zairian's": ['Z AY0 IH1 R IY0 AH0 N Z'],
  "zairians": ['Z AY0 IH1 R IY0 AH0 N Z'],
  "zaiser": ['Z AY1 S ER0'],
  "zaitech": ['Z EY1 T EH2 K'],
  "zaitsev": ['Z EY1 T S EH0 V'],
  "zaitsev's": ['Z EY1 T S EH0 V Z'],
  "zaitz": ['Z EY1 T S'],
  "zajac": ['Z AY1 AH0 K'],
  "zajdel": ['Z AE1 JH D AH0 L'],
  "zajic": ['Z AE1 JH IH0 K'],
  "zajicek": ['Z AY1 IH0 CH EH0 K'],
  "zajkowski": ['Z AY0 K AO1 F S K IY0'],
  "zak": ['Z AE1 K'],
  "zakarian": ['Z AH0 K EH1 R IY0 AH0 N'],
  "zakharov": ['Z AE1 K ER0 AA0 V'],
  "zaki": ['Z AA1 K IY0'],
  "zakrajsek": ['Z AH0 K R AY1 S EH0 K'],
  "zakrzewski": ['Z AA0 K ER0 Z UW1 S K IY0'],
  "zalar": ['Z AA0 L AA1 R'],
  "zalay": ['Z AH0 L EY1'],
  "zaldivar": ['Z AA0 L D IY0 V AA1 R'],
  "zale": ['Z EY1 L'],
  "zale's": ['Z EY1 L Z'],
  "zalenski": ['Z AH0 L EH1 N S K IY0'],
  "zaleski": ['Z AH0 L EH1 S K IY0'],
  "zalesky": ['Z AH0 L EH1 S K IY0'],
  "zalewski": ['Z AH0 L EH1 F S K IY0', 'Z AH0 L UW1 S K IY0'],
  "zaley": ['Z EY1 L IY0'],
  "zaley's": ['Z EY1 L IY0 Z'],
  "zalm": ['Z AA1 M'],
  "zalman": ['Z AA1 L M AH0 N'],
  "zaloudek": ['Z AE1 L AW0 D IH0 K'],
  "zalygin": ['Z AE1 L IH0 G IH0 N'],
  "zaman": ['Z EY1 M AH0 N'],
  "zamarello": ['Z AE2 M ER0 EH1 L OW0'],
  "zamarripa": ['Z AA2 M AA0 R IY1 P AA0'],
  "zamarron": ['Z AA2 M AA0 R AO1 N'],
  "zambelli": ['Z AA2 M B EH1 L IY2'],
  "zambia": ['Z AE1 M B IY0 AH0'],
  "zambia's": ['Z AE1 M B IY0 AH0 Z'],
  "zambian": ['Z AE1 M B IY0 AH0 N'],
  "zambito": ['Z AA0 M B IY1 T OW0'],
  "zambo": ['Z AE1 M B OW0'],
  "zamboanga": ['Z AE0 M B OW1 NG G AH0'],
  "zamboni": ['Z AE0 M B OW1 N IY0'],
  "zambrana": ['Z AA0 M B R AE1 N AH0'],
  "zambrano": ['Z AA0 M B R AA1 N OW0', 'Z AE2 M B R AE1 N OW0'],
  "zamfir": ['Z AE0 M F IH1 R'],
  "zammit": ['Z AE1 M IH0 T'],
  "zamora": ['Z AH0 M AO1 R AH0'],
  "zamorano": ['Z AA0 M AO0 R AA1 N OW0'],
  "zampedri": ['Z AA0 M P EH1 D R IY0'],
  "zampino": ['Z AA0 M P IY1 N OW0'],
  "zamudio": ['Z AA0 M UW1 D IY0 OW0'],
  "zamzow": ['Z AE1 M Z OW0'],
  "zan": ['Z AE1 N'],
  "zanamivir": ['Z AE1 N AH0 M IH0 V ER0'],
  "zanca": ['Z AE1 NG K AH0'],
  "zand": ['Z AE1 N D'],
  "zander": ['Z AE1 N D ER0'],
  "zanders": ['Z AE1 N D ER0 Z'],
  "zandi": ['Z AA1 N D IY0', 'Z AE1 N D IY0'],
  "zandra": ['Z AE1 N D R AH0'],
  "zandstra": ['Z AE1 N D S T R AH0'],
  "zandt": ['Z AE1 N T'],
  "zandy": ['Z AE1 N D IY0'],
  "zandy's": ['Z AE1 N D IY0 Z'],
  "zane": ['Z EY1 N'],
  "zanella": ['Z AH0 N EH1 L AH0'],
  "zaneta": ['Z AA0 N EH1 T AH0'],
  "zang": ['Z AE1 NG'],
  "zangara": ['Z AA0 NG G AA1 R AH0'],
  "zangari": ['Z AA0 NG G AA1 R IY0'],
  "zanger": ['Z AE1 NG ER0'],
  "zanghi": ['Z AA1 N G IY0'],
  "zani": ['Z AA1 N IY0'],
  "zaniest": ['Z EY1 N IY0 AH0 S T'],
  "zaniewski": ['Z AE0 N IY0 EH1 F S K IY0', 'Z AH0 N UW1 F S K IY0'],
  "zank": ['Z AE1 NG K'],
  "zanni": ['Z AE1 N IY0'],
  "zanoni": ['Z AA0 N OW1 N IY0'],
  "zanoyan": ['Z AH0 N OY1 AH0 N'],
  "zant": ['Z AE1 N T'],
  "zantac": ['Z AE1 N T AE0 K'],
  "zanu": ['Z AA0 N UW1'],
  "zanuck": ['Z AE1 N AH0 K'],
  "zanussi": ['Z AH0 N UW1 S IY0'],
  "zany": ['Z EY1 N IY0'],
  "zanzibar": ['Z AE1 N Z AH0 B AA2 R'],
  "zap": ['Z AE1 P'],
  "zapalac": ['Z AH0 P AA1 L AH0 K'],
  "zapata": ['Z AA0 P AA1 T AH0'],
  "zapatista": ['Z AA2 P AH0 T IY1 S T AH0'],
  "zapatista's": ['Z AA2 P AH0 T IY1 S T AH0 Z'],
  "zapatistas": ['Z AA2 P AH0 T IY1 S T AH0 Z'],
  "zapf": ['Z AE1 P F'],
  "zapien": ['Z AE1 P IY0 N'],
  "zapmail": ['Z AE1 P M EY2 L'],
  "zapp": ['Z AE1 P'],
  "zappa": ['Z AE1 P AH0'],
  "zappa's": ['Z AE1 P AH0 Z'],
  "zappala": ['Z AA0 P AA1 L AH0'],
  "zapped": ['Z AE1 P T'],
  "zappia": ['Z AE1 P IY0 AH0'],
  "zapping": ['Z AE1 P IH0 NG'],
  "zappone": ['Z AA0 P OW1 N IY0'],
  "zappos": ['Z AA1 P OW0 Z'],
  "zappulla": ['Z AA0 P UW1 L AH0'],
  "zapruder": ['Z AH0 P R UW1 D ER0'],
  "zaps": ['Z AE1 P S'],
  "zara": ['Z AE1 R AH0'],
  "zaragoza": ['Z AA0 R AA0 G OW1 Z AH0'],
  "zarah": ['Z AE1 R AH0'],
  "zarate": ['Z AA1 R EY0 T'],
  "zarb": ['Z AA1 R B'],
  "zarcone": ['Z AA0 R K OW1 N IY0'],
  "zared": ['Z EH1 R D'],
  "zarek": ['Z AA1 R EH0 K'],
  "zarella": ['Z AH0 R EH1 L AH0'],
  "zaremba": ['Z AA0 R EH1 M B AH0'],
  "zaremski": ['Z ER0 EH1 S K IY0'],
  "zaretsky": ['Z ER0 EH1 T S K IY0'],
  "zarett": ['Z EH1 R AH0 T'],
  "zaria": ['Z AA1 R IY0 AH0'],
  "zaring": ['Z EH1 R IH0 NG'],
  "zarlengo": ['Z AA0 R L EY1 NG G OW0'],
  "zarling": ['Z AA1 R L IH0 NG'],
  "zaro": ['Z AE1 R OW0'],
  "zarr": ['Z AE1 R'],
  "zarre": ['Z AA1 R', 'Z AA1 R IY0'],
  "zarrella": ['Z AA2 R EH1 L AH0'],
  "zarro": ['Z AA1 R OW0'],
  "zarroli": ['Z ER0 OW1 L IY0'],
  "zarroli's": ['Z ER0 OW1 L IY0 Z'],
  "zartman": ['Z AA1 R T M AH0 N'],
  "zaruba": ['Z ER0 UW1 B AH0'],
  "zary": ['Z AA1 R IY0'],
  "zarzecki": ['Z AA0 R Z EH1 K IY0'],
  "zarzusky": ['Z AA0 R Z UW1 S K IY0'],
  "zarzycki": ['Z ER0 Z IH1 T S K IY0'],
  "zasada": ['Z AA0 S AA1 D AH0'],
  "zaske": ['Z EY1 S K'],
  "zaslavskaya": ['Z AE2 S L AH0 V S K AY1 AH0'],
  "zaslow": ['Z AA1 S L OW0'],
  "zastrow": ['Z AE1 S T R OW0'],
  "zaucha": ['Z AW1 HH AH0'],
  "zaugg": ['Z AO1 G'],
  "zaun": ['Z AO1 N'],
  "zauner": ['Z AO1 N ER0'],
  "zavada": ['Z AA0 V AA1 D AH0'],
  "zavadil": ['Z AA0 V AA0 D IY1 L'],
  "zavala": ['Z AA0 V AA1 L AH0'],
  "zavery": ['Z EY1 V ER0 IY0'],
  "zavodnik": ['Z AH0 V AA1 D N IH0 K'],
  "zavodny": ['Z AH0 V AA1 D N IY0'],
  "zawacki": ['Z AA0 V AA1 T S K IY0'],
  "zawada": ['Z AA0 W AA1 D AH0'],
  "zawadzki": ['Z AA0 V AA1 JH K IY0'],
  "zawislak": ['Z AA0 V IH1 S L AH0 K'],
  "zawistowski": ['Z AA0 V IH0 S T AO1 F S K IY0'],
  "zayac": ['Z AY1 AH0 K'],
  "zayas": ['Z EY1 AA0 Z'],
  "zayed": ['Z EY1 AH0 D'],
  "zayre": ['Z EY1 R'],
  "zayre's": ['Z EY1 ER0 Z'],
  "zazueta": ['Z AA0 Z W EH1 T AH0'],
  "zbig": ['Z B IH1 G'],
  "zbigniew": ['Z B IH1 G N UW0'],
  "zbikowski": ['Z B IH0 K AW1 S K IY0'],
  "zbinden": ['Z B AY1 N D AH0 N'],
  "zdrojewski": ['Z D R OY2 UW1 S K IY0'],
  "ze": ['Z IY1'],
  "zea": ['Z IY1'],
  "zeagler": ['Z IY1 G L ER0'],
  "zeal": ['Z IY1 L'],
  "zealand": ['Z IY1 L AH0 N D'],
  "zealand's": ['Z IY1 L AH0 N D Z'],
  "zealander": ['Z IY1 L AH0 N D ER0'],
  "zealanders": ['Z IY1 L AH0 N D ER0 Z'],
  "zealot": ['Z EH1 L AH0 T', 'Z IY1 L AH0 T'],
  "zealotry": ['Z EH1 L AH0 T R IY0'],
  "zealots": ['Z EH1 L AH0 T S', 'Z IY1 L AH0 T S'],
  "zealous": ['Z EH1 L AH0 S'],
  "zealously": ['Z IY1 L AH0 S L IY0', 'Z EH1 L AH0 S L IY0'],
  "zebell": ['Z IY1 B EH0 L'],
  "zebley": ['Z EH1 B L IY0'],
  "zebra": ['Z IY1 B R AH0'],
  "zebras": ['Z IY1 B R AH0 Z'],
  "zebrowski": ['Z AH0 B R AO1 F S K IY0'],
  "zebulon": ['Z IY1 B AH0 L AH0 N'],
  "zecca": ['Z EH1 K AH0'],
  "zech": ['Z EH1 K'],
  "zecher": ['Z EH1 K ER0'],
  "zechman": ['Z EH1 K M AH0 N'],
  "zeck": ['Z EH1 K'],
  "zeckendorf": ['Z EH1 K AH0 N D AO2 R F'],
  "zed": ['Z EH1 D'],
  "zedekiah": ['Z EH2 D AH0 K AY1 AH0'],
  "zedillo": ['Z EY2 D IY1 OW0', 'Z EH2 D IH1 L OW0'],
  "zedillo's": ['Z EY2 D IY1 OW0 Z', 'Z EH2 D IH1 L OW0 Z'],
  "zedillos": ['Z EY2 D IY1 OW0 Z', 'Z EH2 D IH1 L OW0 Z'],
  "zedong": ['Z EY2 D AO1 NG', 'Z IY2 D AH2 NG'],
  "zee": ['Z IY1'],
  "zeeb": ['Z IY1 B'],
  "zeeble": ['Z IY1 B AH0 L'],
  "zeebrugge": ['Z IY2 B R UW1 G IY0'],
  "zeek": ['Z IY1 K'],
  "zeeland": ['Z IY1 L AH0 N D'],
  "zeeman": ['Z IY1 M AH0 N'],
  "zeese": ['Z IY1 Z', 'Z IY1 S'],
  "zeff": ['Z EH1 F'],
  "zeffirelli": ['Z EH2 F IH0 R EH1 L IY0'],
  "zegeer": ['Z IH0 G IH1 R'],
  "zeger": ['Z IY1 G ER0'],
  "zegers": ['Z IY1 G ER0 Z'],
  "zeglin": ['Z EH1 G L IH0 N'],
  "zeh": ['Z EH1'],
  "zehnder": ['Z EH1 N D ER0'],
  "zehner": ['Z EH1 N ER0'],
  "zehntel": ['Z EH1 N T EH2 L'],
  "zehr": ['Z EH1 R'],
  "zehren": ['Z EH1 R AH0 N'],
  "zehring": ['Z EH1 R IH0 NG'],
  "zeibel": ['Z AY1 B AH0 L'],
  "zeibel's": ['Z AY1 B AH0 L Z'],
  "zeichner": ['Z AY1 K N ER0'],
  "zeiders": ['Z AY1 D ER0 Z'],
  "zeidler": ['Z AY1 D AH0 L ER0', 'Z AY1 D L ER0'],
  "zeidman": ['Z AY1 D M AH0 N'],
  "zeien": ['Z AY1 AH0 N'],
  "zeiger": ['Z AY1 G ER0'],
  "zeigler": ['Z AY1 G AH0 L ER0', 'Z AY1 G L ER0'],
  "zeiler": ['Z AY1 L ER0'],
  "zeilinger": ['Z AY1 L IH0 NG ER0'],
  "zeimet": ['Z AY1 M IH0 T'],
  "zeinab": ['Z AY1 N AE0 B'],
  "zeiner": ['Z AY1 N ER0'],
  "zeis": ['Z IY1 Z'],
  "zeiser": ['Z AY1 S ER0'],
  "zeisler": ['Z AY1 S AH0 L ER0', 'Z AY1 S L ER0'],
  "zeiss": ['Z AY1 S'],
  "zeit": ['Z AY1 T'],
  "zeiter": ['Z AY1 T ER0'],
  "zeitgeist": ['T S AY1 T G AY2 S T'],
  "zeitler": ['Z AY1 T AH0 L ER0', 'Z AY1 T L ER0'],
  "zeitlin": ['Z AY1 T L IH0 N'],
  "zeitung": ['Z AY1 T AH0 NG'],
  "zeitz": ['Z IY1 T S'],
  "zekauskas": ['Z AH0 K AW1 S K AH0 S'],
  "zeke": ['Z IY1 K'],
  "zel": ['Z EH1 L'],
  "zelaputon": ['Z EH2 L AH0 P Y UW1 T AH0 N'],
  "zelasko": ['Z IH0 L AA1 S K OW0'],
  "zelaya": ['Z EY0 L AY1 AH0'],
  "zelazny": ['Z IH0 L AA1 Z N IY0'],
  "zelda": ['Z EH1 L D AH0'],
  "zeldin": ['Z EH1 L D IH0 N'],
  "zele": ['Z IY1 L'],
  "zelek": ['Z EH1 L IH0 K'],
  "zelem": ['Z EH1 L AH0 M'],
  "zelenak": ['Z EH1 L IH0 N AH0 K'],
  "zelenka": ['Z IH0 L EH1 NG K AH0'],
  "zeleny": ['Z IH0 L IY1 N IY0'],
  "zelesnik": ['Z AH0 L EH1 S N IH0 K'],
  "zeleznik": ['Z IH0 L EH1 Z N IH0 K'],
  "zelia": ['Z EH1 L IY0 AH0'],
  "zelie": ['Z EH1 L IY0'],
  "zeliff": ['Z EH1 L IH0 F'],
  "zelikow": ['Z EH1 L IH0 K OW0'],
  "zelina": ['Z EH0 L IY1 N AH0'],
  "zelinka": ['Z IH0 L IH1 NG K AH0'],
  "zelinski": ['Z IH0 L IH1 N S K IY0'],
  "zelinsky": ['Z IH0 L IH1 N S K IY0'],
  "zelko": ['Z EH1 L K OW0'],
  "zell": ['Z EH1 L'],
  "zellars": ['Z EH1 L ER0 Z'],
  "zelle": ['Z EH1 L'],
  "zeller": ['Z EH1 L ER0'],
  "zellerbach": ['Z EH1 L ER0 B AA2 K'],
  "zellers": ['Z EH1 L ER0 Z'],
  "zellman": ['Z EH1 L M AH0 N'],
  "zellmer": ['Z EH1 L M ER0'],
  "zellner": ['Z EH1 L N ER0'],
  "zelma": ['Z EH1 L M AH0'],
  "zelman": ['Z EH1 L M AH0 N'],
  "zelnick": ['Z EH1 L N IH0 K'],
  "zelos": ['Z IY1 L OW0 Z'],
  "zelotes": ['Z EH1 L AH0 T S'],
  "zeltner": ['Z EH1 L T N ER0'],
  "zeltzer": ['Z EH1 L T S ER0'],
  "zelvin": ['Z EH1 L V IH0 N'],
  "zemaitis": ['Z EH2 M AY1 T AH0 S'],
  "zeman": ['Z IY1 M AH0 N'],
  "zemanek": ['Z EH1 M AH0 N IH0 K'],
  "zemba": ['Z EH1 M B AH0'],
  "zembower": ['Z EH1 M B AW2 ER0'],
  "zembriski": ['Z EH0 M B R IH1 S K IY0'],
  "zemecki": ['Z AH0 M EH1 K IY0'],
  "zemeckis": ['Z AH0 M EH1 K IY0 Z'],
  "zemel": ['Z EH1 M AH0 L'],
  "zemin": ['Z IY1 M IH0 N'],
  "zemin's": ['Z IY1 M IH0 N Z'],
  "zemke": ['Z EH1 M K'],
  "zempel": ['Z EH1 M P AH0 L'],
  "zen": ['Z EH1 N'],
  "zena": ['Z IY1 N AH0'],
  "zenaida": ['Z IH0 N EY1 D AH0'],
  "zenas": ['Z IY1 N AH0 Z'],
  "zenchu": ['Z EH1 N CH UW2'],
  "zenda": ['Z EH1 N D AH0'],
  "zendejas": ['Z IY1 N D IH0 JH AH0 Z'],
  "zender": ['Z EH1 N D ER0'],
  "zeneca": ['Z EH1 N AH0 K AH0'],
  "zeneca's": ['Z EH1 N AH0 K AH0 Z'],
  "zeng": ['Z EH1 NG'],
  "zenger": ['Z IY1 N JH ER0'],
  "zenia": ['Z EY1 N IY0 AH0'],
  "zenica": ['Z EH1 N IH0 K AH0'],
  "zenina": ['Z EH0 N IY1 N AH0'],
  "zenith": ['Z IY1 N AH0 TH', 'Z IY1 N IH0 TH'],
  "zenith's": ['Z IY1 N IH0 TH S'],
  "zenk": ['Z EH1 NG K'],
  "zenker": ['Z EH1 NG K ER0'],
  "zenna": ['Z EH1 N AH0'],
  "zenner": ['Z EH1 N ER0'],
  "zeno": ['Z EY1 N OW0'],
  "zenobia": ['Z AH0 N OW1 B IY0 AH0'],
  "zenon": ['Z IY1 N AH0 N'],
  "zenor": ['Z EH1 N ER0'],
  "zens": ['Z EH1 N Z'],
  "zent": ['Z EH1 N T'],
  "zentec": ['Z EH1 N T EH2 K'],
  "zentner": ['Z EH1 N T N ER0'],
  "zentralsparkasse": ['Z EH1 N T R AH0 L S P AA2 R K AA2 Z IH0'],
  "zentz": ['Z EH1 N T S'],
  "zenz": ['Z EH1 N Z'],
  "zeoli": ['Z IY0 AA1 L IY0'],
  "zeos": ['Z IY1 OW2 S'],
  "zepa": ['Z EH1 P AH0', 'Z IY1 P AH0', 'Z IY1 IY1 P IY1 EY1'],
  "zepeda": ['Z EY0 P EY1 D AH0'],
  "zephyr": ['Z EH1 F ER0'],
  "zephyrs": ['Z EH1 F ER0 Z'],
  "zepp": ['Z EH1 P'],
  "zeppelin": ['Z EH1 P IH0 L IH0 N'],
  "zeppenfeld": ['Z EH1 P AH0 F EH2 L D'],
  "zera": ['Z EH1 R AH0'],
  "zerbe": ['Z IH1 R B', 'Z IH1 R B IY0'],
  "zerby": ['Z IH1 R B IY0'],
  "zercomo": ['Z ER0 K OW1 M OW0'],
  "zerilli": ['Z ER0 IY1 L IY0'],
  "zeringue": ['Z EH1 R IH0 NG'],
  "zerkle": ['Z IH1 R K AH0 L'],
  "zerlina": ['Z ER0 L IY1 N AH0'],
  "zerlinda": ['Z ER0 L IY1 N D AH0'],
  "zermeno": ['Z ER0 M EH1 N OW0'],
  "zern": ['Z IH1 R N'],
  "zero": ['Z IH1 R OW0', 'Z IY1 R OW0'],
  "zero's": ['Z IH1 R OW0 Z', 'Z IY1 R OW0 Z'],
  "zero-sum": ['Z IY1 R OW0 S AH2 M'],
  "zeroed": ['Z IH1 R OW0 D', 'Z IY1 R OW0 D'],
  "zeroes": ['Z IH1 R OW0 Z', 'Z IY1 R OW0 Z'],
  "zeroing": ['Z IY1 R OW0 IH0 NG'],
  "zeroli": ['Z AH0 R OW1 L IY0'],
  "zeros": ['Z IH1 R OW0 Z'],
  "zeroual": ['Z EH2 R UW0 AA1 L'],
  "zerr": ['Z IH1 R'],
  "zertuche": ['Z IH1 R T AH0 K'],
  "zervas": ['Z IH1 R V AH0 Z'],
  "zervos": ['Z IH1 R V OW0 Z', 'Z ER1 V OW0 S'],
  "zerwhale": ['Z ER1 W EY2 L'],
  "zest": ['Z EH1 S T'],
  "zestful": ['Z EH1 S T F AH0 L'],
  "zeta": ['Z EY1 T AH0'],
  "zettel": ['Z EH1 T AH0 L'],
  "zettle": ['Z EH1 T AH0 L'],
  "zettlemoyer": ['Z EH1 T AH0 L M OY0 ER0'],
  "zettler": ['Z EH1 T L ER0'],
  "zeus": ['Z UW1 S'],
  "zev": ['Z EH1 V'],
  "zeva": ['Z EY1 V AH0'],
  "zewe": ['Z IY1 W IY2', 'Z EH1 W EY2'],
  "zeyno": ['Z EY1 N OW0'],
  "zhambu": ['Z AA0 M B UW1'],
  "zhan": ['Z AA1 N'],
  "zhang": ['ZH AE1 NG', 'ZH AA1 NG'],
  "zhao": ['ZH AW1', 'JH AW1'],
  "zhejiang": ['ZH IY1 JH AA1 NG'],
  "zheng": ['Z EH1 NG'],
  "zhengzhou": ['ZH EH1 NG G ZH UW2'],
  "zhenyu": ['ZH EH1 N Y UW2'],
  "zhirinovsky": ['ZH IH2 R AH0 N AA1 V S K IY2'],
  "zhirinovsky's": ['ZH IH2 R AH0 N AA1 V S K IY2 Z'],
  "zhivago": ['ZH IH0 V AA1 G OW0'],
  "zhivkov": ['ZH IH1 V K AA0 V'],
  "zhou": ['ZH UW1'],
  "zhu": ['ZH UW1'],
  "zhuhai": ['ZH UW1 HH AY1'],
  "zi": ['Z IY1'],
  "zia": ['Z IY1 AH0'],
  "zia's": ['Z IY1 AH0 Z'],
  "ziad": ['Z IY1 AE0 D', 'Z IY1 AY1 EY1 D IY1', 'Z AY1 AE0 D'],
  "ziahe": ['Z IY1 AA0', 'Z IY1 AA0 HH IY0'],
  "ziashivaney": ['Z IY2 AA0 SH IH0 V EY1 N IY0'],
  "zibell": ['Z AY1 B EH0 L'],
  "ziccardi": ['Z IY0 K AA1 R D IY0'],
  "zich": ['Z IH1 CH'],
  "zick": ['Z IH1 K'],
  "zickefoose": ['Z IH1 K IH0 F UW0 S'],
  "zico": ['Z IY1 K OW0'],
  "zidek": ['Z IH1 D IH0 K'],
  "zieba": ['Z IY1 B AH0'],
  "ziebarth": ['Z IY1 B AA0 R TH'],
  "ziebell": ['Z IY1 B EH0 L'],
  "zieg": ['Z IY1 G'],
  "ziegenbein": ['Z IY1 G IH0 N B AY0 N'],
  "ziegenfuss": ['Z IY1 G IH0 N F AH0 S'],
  "ziegenhorn": ['Z IY1 G IH0 N HH ER0 N'],
  "zieger": ['Z IY1 G ER0'],
  "ziegfeld": ['Z IH1 G F EH0 L D'],
  "ziegfield": ['Z IH1 G F IY0 L D'],
  "ziegler": ['Z IY1 G L ER0'],
  "ziehl": ['Z IY1 L'],
  "ziehm": ['Z IY1 M'],
  "ziel": ['Z IY1 L'],
  "zielinski": ['Z IY0 L IH1 N S K IY0'],
  "zielke": ['Z IY1 L K'],
  "zieman": ['Z IY1 M AH0 N'],
  "ziemann": ['Z IY1 M AH0 N'],
  "ziemba": ['Z IY1 M B AH0'],
  "ziemer": ['Z IY1 M ER0'],
  "ziemian": ['Z IY1 M IY0 AH0 N'],
  "ziemke": ['Z IY1 M K'],
  "zientek": ['Z IY1 N T IH0 K'],
  "zier": ['Z IH1 R'],
  "zierke": ['Z IY1 R K'],
  "ziesemer": ['Z IY1 S IY0 M ER0'],
  "zieske": ['Z IY1 S K', 'Z IY1 S K IY0'],
  "ziesmer": ['Z IY1 S AH0 M ER0'],
  "zietlow": ['Z IY1 T L OW0'],
  "zietz": ['Z IY1 T S'],
  "ziff": ['Z IH1 F'],
  "ziff's": ['Z IH1 F S'],
  "zifferero": ['Z IH2 F ER0 EH1 R OW0'],
  "zig": ['Z IH1 G'],
  "zig-zag": ['Z IH1 G Z AE2 G'],
  "zig-zagged": ['Z IH1 G Z AE2 G D'],
  "ziggurat": ['Z IH1 G ER0 AE2 T'],
  "ziggurats": ['Z IH1 G ER0 AE2 T S'],
  "ziglar": ['Z IH1 G L ER0'],
  "zigler": ['Z AY1 G AH0 L ER0', 'Z AY1 G L ER0'],
  "zigman": ['Z IH1 G M AH0 N'],
  "zigzag": ['Z IH1 G Z AE0 G'],
  "zigzagged": ['Z IH1 G Z AE0 G D'],
  "zigzagging": ['Z IH1 G Z AE0 G IH0 NG'],
  "zigzags": ['Z IH1 G Z AE0 G Z'],
  "zika": ['Z IH1 K AH0'],
  "zike": ['Z AY1 K'],
  "zil": ['Z IH1 L'],
  "zilber": ['Z IH1 L B ER0'],
  "zilch": ['Z IH1 L CH'],
  "zile": ['Z AY1 L'],
  "zilka": ['Z IH1 L K AH0'],
  "zilkha": ['Z IH1 L K AH0'],
  "zill": ['Z IH1 L'],
  "zilla": ['Z IH1 L AH0'],
  "zillah": ['Z IH1 L AH0'],
  "ziller": ['Z IH1 L ER0'],
  "zillion": ['Z IH1 L Y AH0 N'],
  "zillionaire": ['Z IH1 L Y AH0 N EH2 R'],
  "zillions": ['Z IH1 L Y AH0 N Z'],
  "zillmer": ['Z IH1 L M ER0'],
  "zilmer": ['Z IH1 L M ER0'],
  "zilvia": ['Z IH1 L V IY0 AH0'],
  "zim": ['Z IH1 M'],
  "zima": ['Z IY1 M AH0'],
  "zimbabwe": ['Z IH0 M B AA1 B W EY0'],
  "zimbabwe's": ['Z IH0 M B AA1 B W EY0 Z'],
  "zimbabwean": ['Z IH0 M B AA1 B W IY0 AH0 N'],
  "zimbalist": ['Z IH1 M B AH0 L IH0 S T'],
  "zimbelman": ['Z IH1 M B AH0 L M AH0 N'],
  "zimdars": ['Z IH1 M D ER0 Z'],
  "zimerman": ['Z AY1 M ER0 M AH0 N'],
  "zimmer": ['Z IH1 M ER0'],
  "zimmerer": ['Z IH1 M ER0 ER0'],
  "zimmerle": ['Z IH1 M ER0 AH0 L'],
  "zimmerly": ['Z IH1 M ER0 L IY0'],
  "zimmerman": ['Z IH1 M ER0 M AH0 N'],
  "zimmermann": ['Z IH1 M ER0 M AH0 N'],
  "zimmers": ['Z IH1 M ER0 Z'],
  "zimny": ['Z IH1 M N IY0'],
  "zimpfer": ['Z IH1 M P F ER0'],
  "zinc": ['Z IH1 NG K'],
  "zinck": ['Z IH1 NG K'],
  "zinda": ['Z IH1 N D AH0'],
  "zinder": ['Z IH1 N D ER0'],
  "zine": ['Z AY1 N'],
  "zines": ['Z AY1 N Z'],
  "zinfandel": ['Z IH1 N F AH0 N D EH2 L'],
  "zing": ['Z IH1 NG'],
  "zinga": ['Z IH1 NG AH0'],
  "zingale": ['Z IH0 NG G AA1 L IY0'],
  "zingaro": ['Z IH0 NG G AA1 R OW0'],
  "zinger": ['Z IH1 NG ER0'],
  "zingg": ['Z IH1 NG G'],
  "zingler": ['Z IH1 NG G AH0 L ER0', 'Z IH1 NG G L ER0'],
  "zinia": ['Z IY1 N IY0 AH0'],
  "zink": ['Z IH1 NG K'],
  "zinke": ['Z IH1 NG K'],
  "zinman": ['Z IH1 N M AH0 N'],
  "zinn": ['Z IH1 N'],
  "zinner": ['Z IH1 N ER0'],
  "zinni": ['Z IH1 N IY0'],
  "zinnia": ['Z IH1 N IY0 AH0'],
  "zinnias": ['Z IH1 N IY0 AH0 Z'],
  "zinno": ['Z IH1 N OW0'],
  "zins": ['Z IH1 N Z'],
  "zinser": ['Z IH1 N S ER0'],
  "zinsmeister": ['Z IH1 N S M AY0 S T ER0'],
  "zinter": ['Z IH1 N T ER0'],
  "ziobro": ['Z IY0 OW1 B R OW0'],
  "ziolkowski": ['Z IY0 OW0 L K AO1 F S K IY0'],
  "ziomek": ['Z IY0 OW1 M EH0 K'],
  "zion": ['Z AY1 AH0 N'],
  "zionism": ['Z AY1 AH0 N IH2 Z AH0 M'],
  "zionist": ['Z AY1 AH0 N IH0 S T'],
  "zionists": ['Z AY1 AH0 N IH0 S T S'],
  "zions": ['Z AY1 AH0 N Z'],
  "zip": ['Z IH1 P'],
  "zipcar": ['Z IH1 P K AA2 R'],
  "zipcar's": ['Z IH1 P K AA2 R Z'],
  "zipcars": ['Z IH1 P K AA2 R Z'],
  "zipf": ['Z IH1 P F'],
  "zipfel": ['Z IH1 P F AH0 L'],
  "zipkin": ['Z IH1 P K IH0 N'],
  "zipless": ['Z IH1 P L AH0 S'],
  "zipp": ['Z IH1 P'],
  "zipped": ['Z IH1 P T'],
  "zipper": ['Z IH1 P ER0'],
  "zippered": ['Z IH1 P ER0 D'],
  "zipperer": ['Z IH1 P ER0 ER0'],
  "zippering": ['Z IH1 P ER0 IH0 NG'],
  "zippers": ['Z IH1 P ER0 Z'],
  "zipping": ['Z IH1 P IH0 NG'],
  "zippo": ['Z IH1 P OW0'],
  "zippora": ['Z IY0 P AO1 R AH0'],
  "zippy": ['Z IH1 P IY0'],
  "zips": ['Z IH1 P S'],
  "zirbel": ['Z ER1 B AH0 L'],
  "zirbes": ['Z ER1 B Z'],
  "zircon": ['Z ER1 K AH0 N'],
  "zirconium": ['Z ER0 K OW1 N IY0 AH0 M'],
  "zirconiums": ['Z ER0 K OW1 N IY0 AH0 M Z'],
  "zircons": ['Z ER1 K AA0 N Z'],
  "zirella": ['Z AH0 R EH1 L AH0'],
  "zirk": ['Z ER1 K'],
  "zirkel": ['Z ER1 K AH0 L'],
  "zirkelbach": ['Z ER1 K IH0 L B AA0 K'],
  "zirkle": ['Z ER1 K AH0 L'],
  "zisa": ['Z AY1 S AH0'],
  "zises": ['Z AY1 Z IH0 Z'],
  "zisk": ['Z IH1 S K'],
  "ziska": ['Z IH1 S K AH0'],
  "zisson": ['Z IH1 S AH0 N'],
  "zita": ['Z IY1 T AH0'],
  "zitek": ['Z IH1 T IH0 K'],
  "zither": ['Z IH1 DH ER0'],
  "zithers": ['Z IH1 DH ER0 Z'],
  "zito": ['Z IY1 T OW0'],
  "zito's": ['Z IY1 T OW0 Z'],
  "zittel": ['Z IH1 T AH0 L'],
  "ziv": ['Z IH1 V'],
  "ziwei": ['Z IY1 W EY0'],
  "ziyad": ['Z IY1 AE0 D'],
  "ziyang": ['ZH IY0 AE1 NG', 'ZH IY0 AA1 NG'],
  "zizzo": ['Z IH1 Z OW0'],
  "zlata": ['Z L AA1 T AH2', 'Z L AA1 T AH0'],
  "zlata's": ['Z L AA1 T AH2 Z', 'Z L AA1 T AH0 Z'],
  "zlin": ['Z L IH1 N'],
  "zlobin": ['Z L AO1 B IH0 N'],
  "zlogar": ['Z L OW1 G ER0'],
  "zlomsowitch": ['Z L AA1 M S OW0 IH0 CH'],
  "zlotnick": ['Z L AA1 T N IH0 K'],
  "zloty": ['Z L AO1 T IY0'],
  "zlotys": ['Z L AO1 T IY0 Z'],
  "zmijewski": ['Z M IY0 UW1 S K IY0'],
  "zobel": ['Z OW1 B AH0 L'],
  "zobrist": ['Z AA1 B R IH0 S T'],
  "zoch": ['Z AA1 K'],
  "zocor": ['Z OW1 K ER0', 'Z OW1 K AO2 R'],
  "zodiac": ['Z OW1 D IY0 AE2 K'],
  "zody": ['Z OW1 D IY0'],
  "zoe": ['Z OW1 IY0'],
  "zoeller": ['Z OW1 L ER0'],
  "zoellick": ['Z OW1 L IH0 K'],
  "zoellner": ['Z OW1 L N ER0'],
  "zoete": ['Z OW1 T IY0'],
  "zoey": ['Z OW1 IY0'],
  "zofagart": ['Z OW1 F AH0 G AA0 R T'],
  "zofran": ['Z OW1 F R AE2 N'],
  "zogby": ['Z AO1 G B IY0'],
  "zogg": ['Z AA1 G'],
  "zoghby": ['Z OW1 B IY0'],
  "zoh": ['Z OW1'],
  "zohn": ['Z OW1 N'],
  "zola": ['Z OW1 L AH0'],
  "zoll": ['Z AA1 L'],
  "zollars": ['Z AA1 L ER0 Z'],
  "zoller": ['Z AA1 L ER0'],
  "zollinger": ['Z AA1 L IH0 NG ER0'],
  "zollman": ['Z AA1 L M AH0 N'],
  "zollner": ['Z AA1 L N ER0'],
  "zollo": ['Z AA1 L OW0'],
  "zolman": ['Z AA1 L M AH0 N'],
  "zoloft": ['Z AA1 L AA0 F T', 'Z OW1 L AA0 F T'],
  "zolp": ['Z OW1 L P'],
  "zoltan": ['Z OW1 L T AH0 N'],
  "zombie": ['Z AA1 M B IY0'],
  "zombies": ['Z AA1 M B IY0 Z'],
  "zon": ['Z AO1 N'],
  "zona": ['Z OW1 N AH0'],
  "zondervan": ['Z AA1 N D ER0 V AE0 N'],
  "zone": ['Z OW1 N'],
  "zoned": ['Z OW1 N D'],
  "zones": ['Z OW1 N Z'],
  "zongol": ['Z AO1 NG G AO0 L'],
  "zoning": ['Z OW1 N IH0 NG'],
  "zoo": ['Z UW1'],
  "zoo's": ['Z UW1 Z'],
  "zooey": ['Z OW1 IY0'],
  "zook": ['Z UH1 K'],
  "zoolander": ['Z UW1 L AE2 N D ER0'],
  "zoolander's": ['Z UW1 L AE2 N D ER0 Z'],
  "zoological": ['Z UW2 L AA1 JH IH0 K AH0 L'],
  "zoologist": ['Z OW0 AA1 L AH0 JH AH0 S T'],
  "zoologists": ['Z OW0 AA1 L AH0 JH AH0 S T S'],
  "zoology": ['Z OW0 AA1 L AH0 JH IY0'],
  "zoom": ['Z UW1 M'],
  "zoomed": ['Z UW1 M D'],
  "zooming": ['Z UW1 M IH0 NG'],
  "zooms": ['Z UW1 M Z'],
  "zooropa": ['Z UW0 R OW1 P AH0'],
  "zoos": ['Z UW1 Z'],
  "zoot-suit": ['Z UW1 T S UW1 T'],
  "zopfi": ['Z OW1 P F IY0'],
  "zora": ['Z AO1 R AH0'],
  "zorah": ['Z AO1 R AH0'],
  "zoran": ['Z AO1 R AH0 N'],
  "zorana": ['Z AO0 R AE1 N AH0'],
  "zorich": ['Z AO1 R IH0 K'],
  "zorina": ['Z AO0 R IY1 N AH0'],
  "zorine": ['Z AO0 R IY1 N IY0'],
  "zorinsky": ['Z AO0 R IH1 N S K IY0'],
  "zorkin": ['Z AO1 R K IH0 N'],
  "zorn": ['Z AO1 R N'],
  "zornes": ['Z AO1 R N Z'],
  "zornow": ['Z AO1 R N AW0'],
  "zorro": ['Z AO1 R OW2'],
  "zortman": ['Z AO1 R T M AH0 N'],
  "zorz": ['Z AO1 R Z'],
  "zorzi": ['Z AO1 R Z IY0'],
  "zosen": ['Z OW1 Z AH0 N'],
  "zoss": ['Z AO1 S'],
  "zoster": ['Z AA1 S T ER0'],
  "zotos": ['Z OW1 T OW0 Z'],
  "zotter": ['Z AA1 T ER0'],
  "zouabri": ['Z UW2 W AA1 B R IY2'],
  "zoucha": ['Z UW1 HH AH0'],
  "zounds": ['Z AW1 N D Z'],
  "zovirax": ['Z OW1 V IH0 R AE0 K S'],
  "zovut": ['Z OW1 V AH0 T'],
  "zsa": ['ZH AA1'],
  "zsa-zsa": ['ZH AA1 ZH AA2'],
  "zsazsa": ['ZH AA1 ZH AA2'],
  "zschau": ['ZH AW1'],
  "zuba": ['Z UW1 B AH0'],
  "zube": ['Z UW1 B'],
  "zuber": ['Z UW1 B ER0'],
  "zubia": ['Z UW1 B IY0 AH0'],
  "zubillaga": ['Z UW2 B IH0 L AA1 G AH0'],
  "zubin": ['Z UW1 B IH0 N'],
  "zuboff": ['Z UW1 B AO0 F'],
  "zubrod": ['Z AH1 B R AH0 D'],
  "zuccarelli": ['Z UW0 K ER0 EH1 L IY0'],
  "zuccaro": ['Z UW0 K AA1 R OW0'],
  "zucchini": ['Z UW0 K IY1 N IY0'],
  "zucchinis": ['Z UW0 K IY1 N IY0 Z'],
  "zucco": ['Z UW1 K OW0'],
  "zuch": ['Z AH1 CH'],
  "zuchowski": ['Z AH0 HH AO1 F S K IY0', 'Z UW0 K AO1 F S K IY0'],
  "zuck": ['Z AH1 K'],
  "zucker": ['Z AH1 K ER0', 'Z UW1 K ER0'],
  "zucker's": ['Z AH1 K ER0 Z', 'Z UW1 K ER0 Z'],
  "zuckerberg": ['Z AH1 K ER0 B ER2 G'],
  "zuckerberg's": ['Z AH1 K ER0 B ER2 G Z'],
  "zuckerman": ['Z AH1 K ER0 M AH0 N', 'Z UW1 K ER0 M AH0 N'],
  "zuckerman's": ['Z AH1 K ER0 M AH0 N Z', 'Z UW1 K ER0 M AH0 N Z'],
  "zue": ['Z UW1'],
  "zue's": ['Z UW1 Z'],
  "zuege": ['Z UW1 JH'],
  "zuehlke": ['Z UH1 L K'],
  "zuelke": ['Z UW1 L K'],
  "zuercher": ['Z UH1 R K ER0'],
  "zufall": ['Z UW1 F AH0 L'],
  "zufelt": ['Z AH1 F IH0 L T'],
  "zug": ['Z AH1 G'],
  "zug's": ['Z AH1 G Z'],
  "zuganov": ['Z UW1 G AH0 N AO2 V', 'Z UW1 G AH0 N AO2 F'],
  "zuganov's": ['Z UW1 G AH0 N AO2 V S', 'Z UW1 G AH0 N AO2 F S'],
  "zuhlke": ['Z UW1 L K'],
  "zuidema": ['Z UW0 IH0 D EH1 M AH0'],
  "zuk": ['Z AH1 K'],
  "zukas": ['Z UW1 K AH0 Z'],
  "zuker": ['Z UW1 K ER0'],
  "zukerman": ['Z UW1 K ER0 M AH0 N'],
  "zukin": ['Z UW1 K IH0 N'],
  "zukowski": ['Z AH0 K AO1 F S K IY0'],
  "zulauf": ['Z UW1 L AW0 F'],
  "zuleger": ['Z UW1 L IH0 G ER0'],
  "zulfikar": ['Z AH2 L F IH0 K AA1 R'],
  "zulli": ['Z UW1 L IY0'],
  "zullo": ['Z UW1 L OW0'],
  "zulma": ['Z UH1 L M AH0'],
  "zulu": ['Z UW1 L UW2'],
  "zuluaga": ['Z UW2 L UW0 AA1 G AH0'],
  "zulus": ['Z UW1 L UW0 Z'],
  "zumbro": ['Z AH1 M B R OW0'],
  "zumbrun": ['Z AH1 M B R AH0 N'],
  "zumel": ['Z UW2 M EH1 L'],
  "zummo": ['Z UW1 M OW0'],
  "zumpano": ['Z UW0 M P AA1 N OW0'],
  "zumstein": ['Z AH1 M S T IY2 N', 'Z AH1 M S T AY2 N'],
  "zumwalt": ['Z AH1 M W AH0 L T'],
  "zundel": ['Z AH1 N D AH0 L'],
  "zungu": ['Z AH2 NG UW1'],
  "zuni": ['Z UW1 N IY2'],
  "zuniga": ['Z UW0 N IY1 G AH0'],
  "zunino": ['Z UW0 N IY1 N OW0'],
  "zunker": ['Z AH1 NG K ER0'],
  "zupan": ['Z UW1 P AH0 N'],
  "zupancic": ['Z AH0 P AE1 NG K IH0 K'],
  "zupanja": ['Z AH0 P AE1 N JH AH0'],
  "zupko": ['Z AH1 P K OW0'],
  "zurawski": ['Z ER0 AA1 F S K IY0'],
  "zurcher": ['Z ER1 K ER0'],
  "zurek": ['Z UH1 R EH0 K'],
  "zurfluh": ['Z ER1 F L UW0'],
  "zuri": ['Z UH1 R IY0'],
  "zurich": ['Z UH1 R IH0 K'],
  "zurich's": ['Z UH1 R IH0 K S'],
  "zurita": ['Z ER0 AY1 T AH0'],
  "zurkuhlen": ['Z ER0 K Y UW1 L AH0 N'],
  "zurn": ['Z ER1 N'],
  "zurowski": ['Z UW2 R OW1 S K IY2'],
  "zurve": ['Z ER1 V'],
  "zuver": ['Z UW1 V ER0'],
  "zuzana": ['Z UW2 Z AA1 N AH0'],
  "zuzu": ['Z UW1 Z UW2'],
  "zvornik": ['Z V AO1 R N IH0 K'],
  "zwack": ['Z W AO1 K'],
  "zwahlen": ['Z W AA1 L AH0 N'],
  "zwart": ['Z W AO1 R T'],
  "zweber": ['Z W IY1 B ER0'],
  "zweibel": ['Z W AY1 B AH0 L'],
  "zweifel": ['Z W AY1 F AH0 L'],
  "zweig": ['Z W AY1 G'],
  "zwerdling": ['Z W ER1 D L IH0 NG'],
  "zwerdling's": ['Z W ER1 D L IH0 NG Z'],
  "zwetchkenbaum": ['Z W EH1 CH K AH0 N B AA0 M'],
  "zwick": ['Z W IH1 K'],
  "zwicker": ['Z W IH1 K ER0'],
  "zwicky": ['Z W IH1 K IY0'],
  "zwiebel": ['Z W IY1 B AH0 L'],
  "zwiefelhofer": ['Z W IY1 F AH0 L HH AA2 F ER0'],
  "zwieg": ['Z W IY1 G'],
  "zwilling": ['Z W IH1 L IH0 NG'],
  "zwolinski": ['Z V AH0 L IH1 N S K IY0'],
  "zycad": ['Z IH1 K AE2 D'],
  "zych": ['Z AY1 CH'],
  "zycher": ['Z IH1 K ER0'],
  "zydeco": ['Z AY1 D AH0 K OW2'],
  "zygmunt": ['Z IH1 G M AH0 N T'],
  "zygote": ['Z AY1 G OW0 T'],
  "zyla": ['Z IH1 L AH0'],
  "zylka": ['Z IH1 L K AH0'],
  "zylstra": ['Z IH1 L S T R AH0'],
  "zyman": ['Z AY1 M AH0 N'],
  "zynda": ['Z IH1 N D AH0'],
  "zysk": ['Z AY1 S K'],
  "zyskowski": ['Z IH0 S K AO1 F S K IY0'],
  "zyuganov": ['Z Y UW1 G AA0 N AA0 V', 'Z UW1 G AA0 N AA0 V'],
  "zyuganov's": ['Z Y UW1 G AA0 N AA0 V Z', 'Z UW1 G AA0 N AA0 V Z'],
  "zywicki": ['Z IH0 W IH1 K IY0'],
};

export default cmupd;

/* Copyright (C) 1993-2015 Carnegie Mellon University. All rights reserved.

Redistribution and use in source and binary forms, with or without
modification, are permitted provided that the following conditions
are met:

1. Redistributions of source code must retain the above copyright
   notice, this list of conditions and the following disclaimer.
   The contents of this file are deemed to be source code.

2. Redistributions in binary form must reproduce the above copyright
   notice, this list of conditions and the following disclaimer in
   the documentation and/or other materials provided with the
   distribution.

This work was supported in part by funding from the Defense Advanced
Research Projects Agency, the Office of Naval Research and the National
Science Foundation of the United States of America, and by member
companies of the Carnegie Mellon Sphinx Speech Consortium. We acknowledge
the contributions of many volunteers to the expansion and improvement of
this dictionary.

THIS SOFTWARE IS PROVIDED BY CARNEGIE MELLON UNIVERSITY ``AS IS'' AND
ANY EXPRESSED OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO,
THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
PURPOSE ARE DISCLAIMED.  IN NO EVENT SHALL CARNEGIE MELLON UNIVERSITY
NOR ITS EMPLOYEES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE,
DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY
THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
(INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE
OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.*/